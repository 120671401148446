/* eslint-disable */

import { AllTypesProps, ReturnTypes, Ops } from './const';
export const HOST = "https://console.uat2.timable.com/api/graphql"


export const HEADERS = {}
export const apiSubscription = (options: chainOptions) => (query: string) => {
  try {
    const queryString = options[0] + '?query=' + encodeURIComponent(query);
    const wsString = queryString.replace('http', 'ws');
    const host = (options.length > 1 && options[1]?.websocket?.[0]) || wsString;
    const webSocketOptions = options[1]?.websocket || [host];
    const ws = new WebSocket(...webSocketOptions);
    return {
      ws,
      on: (e: (args: any) => void) => {
        ws.onmessage = (event: any) => {
          if (event.data) {
            const parsed = JSON.parse(event.data);
            const data = parsed.data;
            return e(data);
          }
        };
      },
      off: (e: (args: any) => void) => {
        ws.onclose = e;
      },
      error: (e: (args: any) => void) => {
        ws.onerror = e;
      },
      open: (e: () => void) => {
        ws.onopen = e;
      },
    };
  } catch {
    throw new Error('No websockets implemented');
  }
};
const handleFetchResponse = (response: Response): Promise<GraphQLResponse> => {
  if (!response.ok) {
    return new Promise((_, reject) => {
      response
        .text()
        .then((text) => {
          try {
            reject(JSON.parse(text));
          } catch (err) {
            reject(text);
          }
        })
        .catch(reject);
    });
  }
  return response.json() as Promise<GraphQLResponse>;
};

export const apiFetch =
  (options: fetchOptions) =>
  (query: string, variables: Record<string, unknown> = {}) => {
    const fetchOptions = options[1] || {};
    if (fetchOptions.method && fetchOptions.method === 'GET') {
      return fetch(`${options[0]}?query=${encodeURIComponent(query)}`, fetchOptions)
        .then(handleFetchResponse)
        .then((response: GraphQLResponse) => {
          if (response.errors) {
            throw new GraphQLError(response);
          }
          return response.data;
        });
    }
    return fetch(`${options[0]}`, {
      body: JSON.stringify({ query, variables }),
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      ...fetchOptions,
    })
      .then(handleFetchResponse)
      .then((response: GraphQLResponse) => {
        if (response.errors) {
          throw new GraphQLError(response);
        }
        return response.data;
      });
  };

export const InternalsBuildQuery = ({
  ops,
  props,
  returns,
  options,
  scalars,
}: {
  props: AllTypesPropsType;
  returns: ReturnTypesType;
  ops: Operations;
  options?: OperationOptions;
  scalars?: ScalarDefinition;
}) => {
  const ibb = (
    k: string,
    o: InputValueType | VType,
    p = '',
    root = true,
    vars: Array<{ name: string; graphQLType: string }> = [],
  ): string => {
    const keyForPath = purifyGraphQLKey(k);
    const newPath = [p, keyForPath].join(SEPARATOR);
    if (!o) {
      return '';
    }
    if (typeof o === 'boolean' || typeof o === 'number') {
      return k;
    }
    if (typeof o === 'string') {
      return `${k} ${o}`;
    }
    if (Array.isArray(o)) {
      const args = InternalArgsBuilt({
        props,
        returns,
        ops,
        scalars,
        vars,
      })(o[0], newPath);
      return `${ibb(args ? `${k}(${args})` : k, o[1], p, false, vars)}`;
    }
    if (k === '__alias') {
      return Object.entries(o)
        .map(([alias, objectUnderAlias]) => {
          if (typeof objectUnderAlias !== 'object' || Array.isArray(objectUnderAlias)) {
            throw new Error(
              'Invalid alias it should be __alias:{ YOUR_ALIAS_NAME: { OPERATION_NAME: { ...selectors }}}',
            );
          }
          const operationName = Object.keys(objectUnderAlias)[0];
          const operation = objectUnderAlias[operationName];
          return ibb(`${alias}:${operationName}`, operation, p, false, vars);
        })
        .join('\n');
    }
    const hasOperationName = root && options?.operationName ? ' ' + options.operationName : '';
    const keyForDirectives = o.__directives ?? '';
    const query = `{${Object.entries(o)
      .filter(([k]) => k !== '__directives')
      .map((e) => ibb(...e, [p, `field<>${keyForPath}`].join(SEPARATOR), false, vars))
      .join('\n')}}`;
    if (!root) {
      return `${k} ${keyForDirectives}${hasOperationName} ${query}`;
    }
    const varsString = vars.map((v) => `${v.name}: ${v.graphQLType}`).join(', ');
    return `${k} ${keyForDirectives}${hasOperationName}${varsString ? `(${varsString})` : ''} ${query}`;
  };
  return ibb;
};

type UnionOverrideKeys<T, U> = Omit<T, keyof U> & U;

export const Thunder =
  <SCLR extends ScalarDefinition>(fn: FetchFunction, thunderGraphQLOptions?: ThunderGraphQLOptions<SCLR>) =>
  <O extends keyof typeof Ops, OVERRIDESCLR extends SCLR, R extends keyof ValueTypes = GenericOperation<O>>(
    operation: O,
    graphqlOptions?: ThunderGraphQLOptions<OVERRIDESCLR>,
  ) =>
  <Z extends ValueTypes[R]>(
    o: Z & {
      [P in keyof Z]: P extends keyof ValueTypes[R] ? Z[P] : never;
    },
    ops?: OperationOptions & { variables?: Record<string, unknown> },
  ) => {
    const options = {
      ...thunderGraphQLOptions,
      ...graphqlOptions,
    };
    return fn(
      Zeus(operation, o, {
        operationOptions: ops,
        scalars: options?.scalars,
      }),
      ops?.variables,
    ).then((data) => {
      if (options?.scalars) {
        return decodeScalarsInResponse({
          response: data,
          initialOp: operation,
          initialZeusQuery: o as VType,
          returns: ReturnTypes,
          scalars: options.scalars,
          ops: Ops,
        });
      }
      return data;
    }) as Promise<InputType<GraphQLTypes[R], Z, UnionOverrideKeys<SCLR, OVERRIDESCLR>>>;
  };

export const Chain = (...options: chainOptions) => Thunder(apiFetch(options));

export const SubscriptionThunder =
  <SCLR extends ScalarDefinition>(fn: SubscriptionFunction, thunderGraphQLOptions?: ThunderGraphQLOptions<SCLR>) =>
  <O extends keyof typeof Ops, OVERRIDESCLR extends SCLR, R extends keyof ValueTypes = GenericOperation<O>>(
    operation: O,
    graphqlOptions?: ThunderGraphQLOptions<OVERRIDESCLR>,
  ) =>
  <Z extends ValueTypes[R]>(
    o: Z & {
      [P in keyof Z]: P extends keyof ValueTypes[R] ? Z[P] : never;
    },
    ops?: OperationOptions & { variables?: ExtractVariables<Z> },
  ) => {
    const options = {
      ...thunderGraphQLOptions,
      ...graphqlOptions,
    };
    type CombinedSCLR = UnionOverrideKeys<SCLR, OVERRIDESCLR>;
    const returnedFunction = fn(
      Zeus(operation, o, {
        operationOptions: ops,
        scalars: options?.scalars,
      }),
    ) as SubscriptionToGraphQL<Z, GraphQLTypes[R], CombinedSCLR>;
    if (returnedFunction?.on && options?.scalars) {
      const wrapped = returnedFunction.on;
      returnedFunction.on = (fnToCall: (args: InputType<GraphQLTypes[R], Z, CombinedSCLR>) => void) =>
        wrapped((data: InputType<GraphQLTypes[R], Z, CombinedSCLR>) => {
          if (options?.scalars) {
            return fnToCall(
              decodeScalarsInResponse({
                response: data,
                initialOp: operation,
                initialZeusQuery: o as VType,
                returns: ReturnTypes,
                scalars: options.scalars,
                ops: Ops,
              }),
            );
          }
          return fnToCall(data);
        });
    }
    return returnedFunction;
  };

export const Subscription = (...options: chainOptions) => SubscriptionThunder(apiSubscription(options));
export const Zeus = <
  Z extends ValueTypes[R],
  O extends keyof typeof Ops,
  R extends keyof ValueTypes = GenericOperation<O>,
>(
  operation: O,
  o: Z,
  ops?: {
    operationOptions?: OperationOptions;
    scalars?: ScalarDefinition;
  },
) =>
  InternalsBuildQuery({
    props: AllTypesProps,
    returns: ReturnTypes,
    ops: Ops,
    options: ops?.operationOptions,
    scalars: ops?.scalars,
  })(operation, o as VType);

export const ZeusSelect = <T>() => ((t: unknown) => t) as SelectionFunction<T>;

export const Selector = <T extends keyof ValueTypes>(key: T) => key && ZeusSelect<ValueTypes[T]>();

export const TypeFromSelector = <T extends keyof ValueTypes>(key: T) => key && ZeusSelect<ValueTypes[T]>();
export const Gql = Chain(HOST, {
  headers: {
    'Content-Type': 'application/json',
    ...HEADERS,
  },
});

export const ZeusScalars = ZeusSelect<ScalarCoders>();

export const decodeScalarsInResponse = <O extends Operations>({
  response,
  scalars,
  returns,
  ops,
  initialZeusQuery,
  initialOp,
}: {
  ops: O;
  response: any;
  returns: ReturnTypesType;
  scalars?: Record<string, ScalarResolver | undefined>;
  initialOp: keyof O;
  initialZeusQuery: InputValueType | VType;
}) => {
  if (!scalars) {
    return response;
  }
  const builder = PrepareScalarPaths({
    ops,
    returns,
  });

  const scalarPaths = builder(initialOp as string, ops[initialOp], initialZeusQuery);
  if (scalarPaths) {
    const r = traverseResponse({ scalarPaths, resolvers: scalars })(initialOp as string, response, [ops[initialOp]]);
    return r;
  }
  return response;
};

export const traverseResponse = ({
  resolvers,
  scalarPaths,
}: {
  scalarPaths: { [x: string]: `scalar.${string}` };
  resolvers: {
    [x: string]: ScalarResolver | undefined;
  };
}) => {
  const ibb = (k: string, o: InputValueType | VType, p: string[] = []): unknown => {
    if (Array.isArray(o)) {
      return o.map((eachO) => ibb(k, eachO, p));
    }
    if (o == null) {
      return o;
    }
    const scalarPathString = p.join(SEPARATOR);
    const currentScalarString = scalarPaths[scalarPathString];
    if (currentScalarString) {
      const currentDecoder = resolvers[currentScalarString.split('.')[1]]?.decode;
      if (currentDecoder) {
        return currentDecoder(o);
      }
    }
    if (typeof o === 'boolean' || typeof o === 'number' || typeof o === 'string' || !o) {
      return o;
    }
    const entries = Object.entries(o).map(([k, v]) => [k, ibb(k, v, [...p, purifyGraphQLKey(k)])] as const);
    const objectFromEntries = entries.reduce<Record<string, unknown>>((a, [k, v]) => {
      a[k] = v;
      return a;
    }, {});
    return objectFromEntries;
  };
  return ibb;
};

export type AllTypesPropsType = {
  [x: string]:
    | undefined
    | `scalar.${string}`
    | 'enum'
    | {
        [x: string]:
          | undefined
          | string
          | {
              [x: string]: string | undefined;
            };
      };
};

export type ReturnTypesType = {
  [x: string]:
    | {
        [x: string]: string | undefined;
      }
    | `scalar.${string}`
    | undefined;
};
export type InputValueType = {
  [x: string]: undefined | boolean | string | number | [any, undefined | boolean | InputValueType] | InputValueType;
};
export type VType =
  | undefined
  | boolean
  | string
  | number
  | [any, undefined | boolean | InputValueType]
  | InputValueType;

export type PlainType = boolean | number | string | null | undefined;
export type ZeusArgsType =
  | PlainType
  | {
      [x: string]: ZeusArgsType;
    }
  | Array<ZeusArgsType>;

export type Operations = Record<string, string>;

export type VariableDefinition = {
  [x: string]: unknown;
};

export const SEPARATOR = '|';

export type fetchOptions = Parameters<typeof fetch>;
type websocketOptions = typeof WebSocket extends new (...args: infer R) => WebSocket ? R : never;
export type chainOptions = [fetchOptions[0], fetchOptions[1] & { websocket?: websocketOptions }] | [fetchOptions[0]];
export type FetchFunction = (query: string, variables?: Record<string, unknown>) => Promise<any>;
export type SubscriptionFunction = (query: string) => any;
type NotUndefined<T> = T extends undefined ? never : T;
export type ResolverType<F> = NotUndefined<F extends [infer ARGS, any] ? ARGS : undefined>;

export type OperationOptions = {
  operationName?: string;
};

export type ScalarCoder = Record<string, (s: unknown) => string>;

export interface GraphQLResponse {
  data?: Record<string, any>;
  errors?: Array<{
    message: string;
  }>;
}
export class GraphQLError extends Error {
  constructor(public response: GraphQLResponse) {
    super('');
    console.error(response);
  }
  toString() {
    return 'GraphQL Response Error';
  }
}
export type GenericOperation<O> = O extends keyof typeof Ops ? typeof Ops[O] : never;
export type ThunderGraphQLOptions<SCLR extends ScalarDefinition> = {
  scalars?: SCLR | ScalarCoders;
};

const ExtractScalar = (mappedParts: string[], returns: ReturnTypesType): `scalar.${string}` | undefined => {
  if (mappedParts.length === 0) {
    return;
  }
  const oKey = mappedParts[0];
  const returnP1 = returns[oKey];
  if (typeof returnP1 === 'object') {
    const returnP2 = returnP1[mappedParts[1]];
    if (returnP2) {
      return ExtractScalar([returnP2, ...mappedParts.slice(2)], returns);
    }
    return undefined;
  }
  return returnP1 as `scalar.${string}` | undefined;
};

export const PrepareScalarPaths = ({ ops, returns }: { returns: ReturnTypesType; ops: Operations }) => {
  const ibb = (
    k: string,
    originalKey: string,
    o: InputValueType | VType,
    p: string[] = [],
    pOriginals: string[] = [],
    root = true,
  ): { [x: string]: `scalar.${string}` } | undefined => {
    if (!o) {
      return;
    }
    if (typeof o === 'boolean' || typeof o === 'number' || typeof o === 'string') {
      const extractionArray = [...pOriginals, originalKey];
      const isScalar = ExtractScalar(extractionArray, returns);
      if (isScalar?.startsWith('scalar')) {
        const partOfTree = {
          [[...p, k].join(SEPARATOR)]: isScalar,
        };
        return partOfTree;
      }
      return {};
    }
    if (Array.isArray(o)) {
      return ibb(k, k, o[1], p, pOriginals, false);
    }
    if (k === '__alias') {
      return Object.entries(o)
        .map(([alias, objectUnderAlias]) => {
          if (typeof objectUnderAlias !== 'object' || Array.isArray(objectUnderAlias)) {
            throw new Error(
              'Invalid alias it should be __alias:{ YOUR_ALIAS_NAME: { OPERATION_NAME: { ...selectors }}}',
            );
          }
          const operationName = Object.keys(objectUnderAlias)[0];
          const operation = objectUnderAlias[operationName];
          return ibb(alias, operationName, operation, p, pOriginals, false);
        })
        .reduce((a, b) => ({
          ...a,
          ...b,
        }));
    }
    const keyName = root ? ops[k] : k;
    return Object.entries(o)
      .filter(([k]) => k !== '__directives')
      .map(([k, v]) => {
        // Inline fragments shouldn't be added to the path as they aren't a field
        const isInlineFragment = originalKey.match(/^...\s*on/) != null;
        return ibb(
          k,
          k,
          v,
          isInlineFragment ? p : [...p, purifyGraphQLKey(keyName || k)],
          isInlineFragment ? pOriginals : [...pOriginals, purifyGraphQLKey(originalKey)],
          false,
        );
      })
      .reduce((a, b) => ({
        ...a,
        ...b,
      }));
  };
  return ibb;
};

export const purifyGraphQLKey = (k: string) => k.replace(/\([^)]*\)/g, '').replace(/^[^:]*\:/g, '');

const mapPart = (p: string) => {
  const [isArg, isField] = p.split('<>');
  if (isField) {
    return {
      v: isField,
      __type: 'field',
    } as const;
  }
  return {
    v: isArg,
    __type: 'arg',
  } as const;
};

type Part = ReturnType<typeof mapPart>;

export const ResolveFromPath = (props: AllTypesPropsType, returns: ReturnTypesType, ops: Operations) => {
  const ResolvePropsType = (mappedParts: Part[]) => {
    const oKey = ops[mappedParts[0].v];
    const propsP1 = oKey ? props[oKey] : props[mappedParts[0].v];
    if (propsP1 === 'enum' && mappedParts.length === 1) {
      return 'enum';
    }
    if (typeof propsP1 === 'string' && propsP1.startsWith('scalar.') && mappedParts.length === 1) {
      return propsP1;
    }
    if (typeof propsP1 === 'object') {
      if (mappedParts.length < 2) {
        return 'not';
      }
      const propsP2 = propsP1[mappedParts[1].v];
      if (typeof propsP2 === 'string') {
        return rpp(
          `${propsP2}${SEPARATOR}${mappedParts
            .slice(2)
            .map((mp) => mp.v)
            .join(SEPARATOR)}`,
        );
      }
      if (typeof propsP2 === 'object') {
        if (mappedParts.length < 3) {
          return 'not';
        }
        const propsP3 = propsP2[mappedParts[2].v];
        if (propsP3 && mappedParts[2].__type === 'arg') {
          return rpp(
            `${propsP3}${SEPARATOR}${mappedParts
              .slice(3)
              .map((mp) => mp.v)
              .join(SEPARATOR)}`,
          );
        }
      }
    }
  };
  const ResolveReturnType = (mappedParts: Part[]) => {
    if (mappedParts.length === 0) {
      return 'not';
    }
    const oKey = ops[mappedParts[0].v];
    const returnP1 = oKey ? returns[oKey] : returns[mappedParts[0].v];
    if (typeof returnP1 === 'object') {
      if (mappedParts.length < 2) return 'not';
      const returnP2 = returnP1[mappedParts[1].v];
      if (returnP2) {
        return rpp(
          `${returnP2}${SEPARATOR}${mappedParts
            .slice(2)
            .map((mp) => mp.v)
            .join(SEPARATOR)}`,
        );
      }
    }
  };
  const rpp = (path: string): 'enum' | 'not' | `scalar.${string}` => {
    const parts = path.split(SEPARATOR).filter((l) => l.length > 0);
    const mappedParts = parts.map(mapPart);
    const propsP1 = ResolvePropsType(mappedParts);
    if (propsP1) {
      return propsP1;
    }
    const returnP1 = ResolveReturnType(mappedParts);
    if (returnP1) {
      return returnP1;
    }
    return 'not';
  };
  return rpp;
};

export const InternalArgsBuilt = ({
  props,
  ops,
  returns,
  scalars,
  vars,
}: {
  props: AllTypesPropsType;
  returns: ReturnTypesType;
  ops: Operations;
  scalars?: ScalarDefinition;
  vars: Array<{ name: string; graphQLType: string }>;
}) => {
  const arb = (a: ZeusArgsType, p = '', root = true): string => {
    if (typeof a === 'string') {
      if (a.startsWith(START_VAR_NAME)) {
        const [varName, graphQLType] = a.replace(START_VAR_NAME, '$').split(GRAPHQL_TYPE_SEPARATOR);
        const v = vars.find((v) => v.name === varName);
        if (!v) {
          vars.push({
            name: varName,
            graphQLType,
          });
        } else {
          if (v.graphQLType !== graphQLType) {
            throw new Error(
              `Invalid variable exists with two different GraphQL Types, "${v.graphQLType}" and ${graphQLType}`,
            );
          }
        }
        return varName;
      }
    }
    const checkType = ResolveFromPath(props, returns, ops)(p);
    if (checkType.startsWith('scalar.')) {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const [_, ...splittedScalar] = checkType.split('.');
      const scalarKey = splittedScalar.join('.');
      return (scalars?.[scalarKey]?.encode?.(a) as string) || JSON.stringify(a);
    }
    if (Array.isArray(a)) {
      return `[${a.map((arr) => arb(arr, p, false)).join(', ')}]`;
    }
    if (typeof a === 'string') {
      if (checkType === 'enum') {
        return a;
      }
      return `${JSON.stringify(a)}`;
    }
    if (typeof a === 'object') {
      if (a === null) {
        return `null`;
      }
      const returnedObjectString = Object.entries(a)
        .filter(([, v]) => typeof v !== 'undefined')
        .map(([k, v]) => `${k}: ${arb(v, [p, k].join(SEPARATOR), false)}`)
        .join(',\n');
      if (!root) {
        return `{${returnedObjectString}}`;
      }
      return returnedObjectString;
    }
    return `${a}`;
  };
  return arb;
};

export const resolverFor = <X, T extends keyof ResolverInputTypes, Z extends keyof ResolverInputTypes[T]>(
  type: T,
  field: Z,
  fn: (
    args: Required<ResolverInputTypes[T]>[Z] extends [infer Input, any] ? Input : any,
    source: any,
  ) => Z extends keyof ModelTypes[T] ? ModelTypes[T][Z] | Promise<ModelTypes[T][Z]> | X : never,
) => fn as (args?: any, source?: any) => ReturnType<typeof fn>;

export type UnwrapPromise<T> = T extends Promise<infer R> ? R : T;
export type ZeusState<T extends (...args: any[]) => Promise<any>> = NonNullable<UnwrapPromise<ReturnType<T>>>;
export type ZeusHook<
  T extends (...args: any[]) => Record<string, (...args: any[]) => Promise<any>>,
  N extends keyof ReturnType<T>,
> = ZeusState<ReturnType<T>[N]>;

export type WithTypeNameValue<T> = T & {
  __typename?: boolean;
  __directives?: string;
};
export type AliasType<T> = WithTypeNameValue<T> & {
  __alias?: Record<string, WithTypeNameValue<T>>;
};
type DeepAnify<T> = {
  [P in keyof T]?: any;
};
type IsPayLoad<T> = T extends [any, infer PayLoad] ? PayLoad : T;
export type ScalarDefinition = Record<string, ScalarResolver>;

type IsScalar<S, SCLR extends ScalarDefinition> = S extends 'scalar' & { name: infer T }
  ? T extends keyof SCLR
    ? SCLR[T]['decode'] extends (s: unknown) => unknown
      ? ReturnType<SCLR[T]['decode']>
      : unknown
    : unknown
  : S;
type IsArray<T, U, SCLR extends ScalarDefinition> = T extends Array<infer R>
  ? InputType<R, U, SCLR>[]
  : InputType<T, U, SCLR>;
type FlattenArray<T> = T extends Array<infer R> ? R : T;
type BaseZeusResolver = boolean | 1 | string | Variable<any, string>;

type IsInterfaced<SRC extends DeepAnify<DST>, DST, SCLR extends ScalarDefinition> = FlattenArray<SRC> extends
  | ZEUS_INTERFACES
  | ZEUS_UNIONS
  ? {
      [P in keyof SRC]: SRC[P] extends '__union' & infer R
        ? P extends keyof DST
          ? IsArray<R, '__typename' extends keyof DST ? DST[P] & { __typename: true } : DST[P], SCLR>
          : IsArray<R, '__typename' extends keyof DST ? { __typename: true } : Record<string, never>, SCLR>
        : never;
    }[keyof SRC] & {
      [P in keyof Omit<
        Pick<
          SRC,
          {
            [P in keyof DST]: SRC[P] extends '__union' & infer R ? never : P;
          }[keyof DST]
        >,
        '__typename'
      >]: IsPayLoad<DST[P]> extends BaseZeusResolver ? IsScalar<SRC[P], SCLR> : IsArray<SRC[P], DST[P], SCLR>;
    }
  : {
      [P in keyof Pick<SRC, keyof DST>]: IsPayLoad<DST[P]> extends BaseZeusResolver
        ? IsScalar<SRC[P], SCLR>
        : IsArray<SRC[P], DST[P], SCLR>;
    };

export type MapType<SRC, DST, SCLR extends ScalarDefinition> = SRC extends DeepAnify<DST>
  ? IsInterfaced<SRC, DST, SCLR>
  : never;
// eslint-disable-next-line @typescript-eslint/ban-types
export type InputType<SRC, DST, SCLR extends ScalarDefinition = {}> = IsPayLoad<DST> extends { __alias: infer R }
  ? {
      [P in keyof R]: MapType<SRC, R[P], SCLR>[keyof MapType<SRC, R[P], SCLR>];
    } & MapType<SRC, Omit<IsPayLoad<DST>, '__alias'>, SCLR>
  : MapType<SRC, IsPayLoad<DST>, SCLR>;
export type SubscriptionToGraphQL<Z, T, SCLR extends ScalarDefinition> = {
  ws: WebSocket;
  on: (fn: (args: InputType<T, Z, SCLR>) => void) => void;
  off: (fn: (e: { data?: InputType<T, Z, SCLR>; code?: number; reason?: string; message?: string }) => void) => void;
  error: (fn: (e: { data?: InputType<T, Z, SCLR>; errors?: string[] }) => void) => void;
  open: () => void;
};

// eslint-disable-next-line @typescript-eslint/ban-types
export type FromSelector<SELECTOR, NAME extends keyof GraphQLTypes, SCLR extends ScalarDefinition = {}> = InputType<
  GraphQLTypes[NAME],
  SELECTOR,
  SCLR
>;

export type ScalarResolver = {
  encode?: (s: unknown) => string;
  decode?: (s: unknown) => unknown;
};

export type SelectionFunction<V> = <Z extends V>(
  t: Z & {
    [P in keyof Z]: P extends keyof V ? Z[P] : never;
  },
) => Z;

type BuiltInVariableTypes = {
  ['String']: string;
  ['Int']: number;
  ['Float']: number;
  ['ID']: unknown;
  ['Boolean']: boolean;
};
type AllVariableTypes = keyof BuiltInVariableTypes | keyof ZEUS_VARIABLES;
type VariableRequired<T extends string> = `${T}!` | T | `[${T}]` | `[${T}]!` | `[${T}!]` | `[${T}!]!`;
type VR<T extends string> = VariableRequired<VariableRequired<T>>;

export type GraphQLVariableType = VR<AllVariableTypes>;

type ExtractVariableTypeString<T extends string> = T extends VR<infer R1>
  ? R1 extends VR<infer R2>
    ? R2 extends VR<infer R3>
      ? R3 extends VR<infer R4>
        ? R4 extends VR<infer R5>
          ? R5
          : R4
        : R3
      : R2
    : R1
  : T;

type DecomposeType<T, Type> = T extends `[${infer R}]`
  ? Array<DecomposeType<R, Type>> | undefined
  : T extends `${infer R}!`
  ? NonNullable<DecomposeType<R, Type>>
  : Type | undefined;

type ExtractTypeFromGraphQLType<T extends string> = T extends keyof ZEUS_VARIABLES
  ? ZEUS_VARIABLES[T]
  : T extends keyof BuiltInVariableTypes
  ? BuiltInVariableTypes[T]
  : any;

export type GetVariableType<T extends string> = DecomposeType<
  T,
  ExtractTypeFromGraphQLType<ExtractVariableTypeString<T>>
>;

type UndefinedKeys<T> = {
  [K in keyof T]-?: T[K] extends NonNullable<T[K]> ? never : K;
}[keyof T];

type WithNullableKeys<T> = Pick<T, UndefinedKeys<T>>;
type WithNonNullableKeys<T> = Omit<T, UndefinedKeys<T>>;

type OptionalKeys<T> = {
  [P in keyof T]?: T[P];
};

export type WithOptionalNullables<T> = OptionalKeys<WithNullableKeys<T>> & WithNonNullableKeys<T>;

export type Variable<T extends GraphQLVariableType, Name extends string> = {
  ' __zeus_name': Name;
  ' __zeus_type': T;
};

export type ExtractVariablesDeep<Query> = Query extends Variable<infer VType, infer VName>
  ? { [key in VName]: GetVariableType<VType> }
  : Query extends string | number | boolean | Array<string | number | boolean>
  ? // eslint-disable-next-line @typescript-eslint/ban-types
    {}
  : UnionToIntersection<{ [K in keyof Query]: WithOptionalNullables<ExtractVariablesDeep<Query[K]>> }[keyof Query]>;

export type ExtractVariables<Query> = Query extends Variable<infer VType, infer VName>
  ? { [key in VName]: GetVariableType<VType> }
  : Query extends [infer Inputs, infer Outputs]
  ? ExtractVariablesDeep<Inputs> & ExtractVariables<Outputs>
  : Query extends string | number | boolean | Array<string | number | boolean>
  ? // eslint-disable-next-line @typescript-eslint/ban-types
    {}
  : UnionToIntersection<{ [K in keyof Query]: WithOptionalNullables<ExtractVariables<Query[K]>> }[keyof Query]>;

type UnionToIntersection<U> = (U extends any ? (k: U) => void : never) extends (k: infer I) => void ? I : never;

export const START_VAR_NAME = `$ZEUS_VAR`;
export const GRAPHQL_TYPE_SEPARATOR = `__$GRAPHQL__`;

export const $ = <Type extends GraphQLVariableType, Name extends string>(name: Name, graphqlType: Type) => {
  return (START_VAR_NAME + name + GRAPHQL_TYPE_SEPARATOR + graphqlType) as unknown as Variable<Type, Name>;
};
type ZEUS_INTERFACES = never
export type ScalarCoders = {
	DateTime?: ScalarResolver;
	EmailAddress?: ScalarResolver;
	JSON?: ScalarResolver;
	JSONObject?: ScalarResolver;
}
type ZEUS_UNIONS = GraphQLTypes["Location_Content"] | GraphQLTypes["Event_Content"] | GraphQLTypes["Post_Content"] | GraphQLTypes["Bookmark_User"] | GraphQLTypes["Bookmark_Item"] | GraphQLTypes["WebAppBookmark_User"] | GraphQLTypes["WebAppBookmark_Event"] | GraphQLTypes["ViewRecord_User"] | GraphQLTypes["ViewRecord_Item"] | GraphQLTypes["Comment_Target"] | GraphQLTypes["Comment_Parent"] | GraphQLTypes["Subscription_User"] | GraphQLTypes["Subscription_Organiser"] | GraphQLTypes["Device_User"] | GraphQLTypes["Notification_Meta"] | GraphQLTypes["HomepageSetting_Sections"] | GraphQLTypes["Campaign_Creator"] | GraphQLTypes["Static_Content"] | GraphQLTypes["PayloadPreference_User"]

export type ValueTypes = {
    ["Query"]: AliasType<{
Event?: [{	id: string | Variable<any, string>,	fallbackLocale?: ValueTypes["FallbackLocaleInputType"] | undefined | null | Variable<any, string>,	locale?: ValueTypes["LocaleInputType"] | undefined | null | Variable<any, string>},ValueTypes["Event"]],
Events?: [{	where?: ValueTypes["Event_where"] | undefined | null | Variable<any, string>,	fallbackLocale?: ValueTypes["FallbackLocaleInputType"] | undefined | null | Variable<any, string>,	locale?: ValueTypes["LocaleInputType"] | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>,	page?: number | undefined | null | Variable<any, string>,	sort?: string | undefined | null | Variable<any, string>},ValueTypes["Events"]],
countEvents?: [{	where?: ValueTypes["Event_where"] | undefined | null | Variable<any, string>,	locale?: ValueTypes["LocaleInputType"] | undefined | null | Variable<any, string>},ValueTypes["countEvents"]],
docAccessEvent?: [{	id: string | Variable<any, string>},ValueTypes["eventsDocAccess"]],
Location?: [{	id: string | Variable<any, string>,	fallbackLocale?: ValueTypes["FallbackLocaleInputType"] | undefined | null | Variable<any, string>,	locale?: ValueTypes["LocaleInputType"] | undefined | null | Variable<any, string>},ValueTypes["Location"]],
Locations?: [{	where?: ValueTypes["Location_where"] | undefined | null | Variable<any, string>,	fallbackLocale?: ValueTypes["FallbackLocaleInputType"] | undefined | null | Variable<any, string>,	locale?: ValueTypes["LocaleInputType"] | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>,	page?: number | undefined | null | Variable<any, string>,	sort?: string | undefined | null | Variable<any, string>},ValueTypes["Locations"]],
countLocations?: [{	where?: ValueTypes["Location_where"] | undefined | null | Variable<any, string>,	locale?: ValueTypes["LocaleInputType"] | undefined | null | Variable<any, string>},ValueTypes["countLocations"]],
docAccessLocation?: [{	id: string | Variable<any, string>},ValueTypes["locationsDocAccess"]],
Post?: [{	id: string | Variable<any, string>,	fallbackLocale?: ValueTypes["FallbackLocaleInputType"] | undefined | null | Variable<any, string>,	locale?: ValueTypes["LocaleInputType"] | undefined | null | Variable<any, string>},ValueTypes["Post"]],
Posts?: [{	where?: ValueTypes["Post_where"] | undefined | null | Variable<any, string>,	fallbackLocale?: ValueTypes["FallbackLocaleInputType"] | undefined | null | Variable<any, string>,	locale?: ValueTypes["LocaleInputType"] | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>,	page?: number | undefined | null | Variable<any, string>,	sort?: string | undefined | null | Variable<any, string>},ValueTypes["Posts"]],
countPosts?: [{	where?: ValueTypes["Post_where"] | undefined | null | Variable<any, string>,	locale?: ValueTypes["LocaleInputType"] | undefined | null | Variable<any, string>},ValueTypes["countPosts"]],
docAccessPost?: [{	id: string | Variable<any, string>},ValueTypes["postsDocAccess"]],
Ticketing?: [{	id: string | Variable<any, string>,	fallbackLocale?: ValueTypes["FallbackLocaleInputType"] | undefined | null | Variable<any, string>,	locale?: ValueTypes["LocaleInputType"] | undefined | null | Variable<any, string>},ValueTypes["Ticketing"]],
Ticketings?: [{	where?: ValueTypes["Ticketing_where"] | undefined | null | Variable<any, string>,	fallbackLocale?: ValueTypes["FallbackLocaleInputType"] | undefined | null | Variable<any, string>,	locale?: ValueTypes["LocaleInputType"] | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>,	page?: number | undefined | null | Variable<any, string>,	sort?: string | undefined | null | Variable<any, string>},ValueTypes["Ticketings"]],
countTicketings?: [{	where?: ValueTypes["Ticketing_where"] | undefined | null | Variable<any, string>,	locale?: ValueTypes["LocaleInputType"] | undefined | null | Variable<any, string>},ValueTypes["countTicketings"]],
docAccessTicketing?: [{	id: string | Variable<any, string>},ValueTypes["ticketingDocAccess"]],
Product?: [{	id: string | Variable<any, string>,	fallbackLocale?: ValueTypes["FallbackLocaleInputType"] | undefined | null | Variable<any, string>,	locale?: ValueTypes["LocaleInputType"] | undefined | null | Variable<any, string>},ValueTypes["Product"]],
Products?: [{	where?: ValueTypes["Product_where"] | undefined | null | Variable<any, string>,	fallbackLocale?: ValueTypes["FallbackLocaleInputType"] | undefined | null | Variable<any, string>,	locale?: ValueTypes["LocaleInputType"] | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>,	page?: number | undefined | null | Variable<any, string>,	sort?: string | undefined | null | Variable<any, string>},ValueTypes["Products"]],
countProducts?: [{	where?: ValueTypes["Product_where"] | undefined | null | Variable<any, string>,	locale?: ValueTypes["LocaleInputType"] | undefined | null | Variable<any, string>},ValueTypes["countProducts"]],
docAccessProduct?: [{	id: string | Variable<any, string>},ValueTypes["productsDocAccess"]],
InventoryStatus?: [{	id: string | Variable<any, string>,	fallbackLocale?: ValueTypes["FallbackLocaleInputType"] | undefined | null | Variable<any, string>,	locale?: ValueTypes["LocaleInputType"] | undefined | null | Variable<any, string>},ValueTypes["InventoryStatus"]],
InventoryStatuses?: [{	where?: ValueTypes["InventoryStatus_where"] | undefined | null | Variable<any, string>,	fallbackLocale?: ValueTypes["FallbackLocaleInputType"] | undefined | null | Variable<any, string>,	locale?: ValueTypes["LocaleInputType"] | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>,	page?: number | undefined | null | Variable<any, string>,	sort?: string | undefined | null | Variable<any, string>},ValueTypes["InventoryStatuses"]],
countInventoryStatuses?: [{	where?: ValueTypes["InventoryStatus_where"] | undefined | null | Variable<any, string>,	locale?: ValueTypes["LocaleInputType"] | undefined | null | Variable<any, string>},ValueTypes["countInventoryStatuses"]],
docAccessInventoryStatus?: [{	id: string | Variable<any, string>},ValueTypes["inventory_statusDocAccess"]],
Transaction?: [{	id: string | Variable<any, string>,	fallbackLocale?: ValueTypes["FallbackLocaleInputType"] | undefined | null | Variable<any, string>,	locale?: ValueTypes["LocaleInputType"] | undefined | null | Variable<any, string>},ValueTypes["Transaction"]],
Transactions?: [{	where?: ValueTypes["Transaction_where"] | undefined | null | Variable<any, string>,	fallbackLocale?: ValueTypes["FallbackLocaleInputType"] | undefined | null | Variable<any, string>,	locale?: ValueTypes["LocaleInputType"] | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>,	page?: number | undefined | null | Variable<any, string>,	sort?: string | undefined | null | Variable<any, string>},ValueTypes["Transactions"]],
countTransactions?: [{	where?: ValueTypes["Transaction_where"] | undefined | null | Variable<any, string>,	locale?: ValueTypes["LocaleInputType"] | undefined | null | Variable<any, string>},ValueTypes["countTransactions"]],
docAccessTransaction?: [{	id: string | Variable<any, string>},ValueTypes["transactionsDocAccess"]],
RedemptionCode?: [{	id: string | Variable<any, string>,	fallbackLocale?: ValueTypes["FallbackLocaleInputType"] | undefined | null | Variable<any, string>,	locale?: ValueTypes["LocaleInputType"] | undefined | null | Variable<any, string>},ValueTypes["RedemptionCode"]],
RedemptionCodes?: [{	where?: ValueTypes["RedemptionCode_where"] | undefined | null | Variable<any, string>,	fallbackLocale?: ValueTypes["FallbackLocaleInputType"] | undefined | null | Variable<any, string>,	locale?: ValueTypes["LocaleInputType"] | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>,	page?: number | undefined | null | Variable<any, string>,	sort?: string | undefined | null | Variable<any, string>},ValueTypes["RedemptionCodes"]],
countRedemptionCodes?: [{	where?: ValueTypes["RedemptionCode_where"] | undefined | null | Variable<any, string>,	locale?: ValueTypes["LocaleInputType"] | undefined | null | Variable<any, string>},ValueTypes["countRedemptionCodes"]],
docAccessRedemptionCode?: [{	id: string | Variable<any, string>},ValueTypes["redemption_codesDocAccess"]],
RedemptionPdf?: [{	id: string | Variable<any, string>,	fallbackLocale?: ValueTypes["FallbackLocaleInputType"] | undefined | null | Variable<any, string>,	locale?: ValueTypes["LocaleInputType"] | undefined | null | Variable<any, string>},ValueTypes["RedemptionPdf"]],
RedemptionPdfs?: [{	where?: ValueTypes["RedemptionPdf_where"] | undefined | null | Variable<any, string>,	fallbackLocale?: ValueTypes["FallbackLocaleInputType"] | undefined | null | Variable<any, string>,	locale?: ValueTypes["LocaleInputType"] | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>,	page?: number | undefined | null | Variable<any, string>,	sort?: string | undefined | null | Variable<any, string>},ValueTypes["RedemptionPdfs"]],
countRedemptionPdfs?: [{	where?: ValueTypes["RedemptionPdf_where"] | undefined | null | Variable<any, string>,	locale?: ValueTypes["LocaleInputType"] | undefined | null | Variable<any, string>},ValueTypes["countRedemptionPdfs"]],
docAccessRedemptionPdf?: [{	id: string | Variable<any, string>},ValueTypes["redemption_pdfDocAccess"]],
CheckInRedemption?: [{	id: string | Variable<any, string>,	fallbackLocale?: ValueTypes["FallbackLocaleInputType"] | undefined | null | Variable<any, string>,	locale?: ValueTypes["LocaleInputType"] | undefined | null | Variable<any, string>},ValueTypes["CheckInRedemption"]],
CheckInRedemptions?: [{	where?: ValueTypes["CheckInRedemption_where"] | undefined | null | Variable<any, string>,	fallbackLocale?: ValueTypes["FallbackLocaleInputType"] | undefined | null | Variable<any, string>,	locale?: ValueTypes["LocaleInputType"] | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>,	page?: number | undefined | null | Variable<any, string>,	sort?: string | undefined | null | Variable<any, string>},ValueTypes["CheckInRedemptions"]],
countCheckInRedemptions?: [{	where?: ValueTypes["CheckInRedemption_where"] | undefined | null | Variable<any, string>,	locale?: ValueTypes["LocaleInputType"] | undefined | null | Variable<any, string>},ValueTypes["countCheckInRedemptions"]],
docAccessCheckInRedemption?: [{	id: string | Variable<any, string>},ValueTypes["check_in_redemptionDocAccess"]],
User?: [{	id: string | Variable<any, string>,	fallbackLocale?: ValueTypes["FallbackLocaleInputType"] | undefined | null | Variable<any, string>,	locale?: ValueTypes["LocaleInputType"] | undefined | null | Variable<any, string>},ValueTypes["User"]],
Users?: [{	where?: ValueTypes["User_where"] | undefined | null | Variable<any, string>,	fallbackLocale?: ValueTypes["FallbackLocaleInputType"] | undefined | null | Variable<any, string>,	locale?: ValueTypes["LocaleInputType"] | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>,	page?: number | undefined | null | Variable<any, string>,	sort?: string | undefined | null | Variable<any, string>},ValueTypes["Users"]],
countUsers?: [{	where?: ValueTypes["User_where"] | undefined | null | Variable<any, string>,	locale?: ValueTypes["LocaleInputType"] | undefined | null | Variable<any, string>},ValueTypes["countUsers"]],
docAccessUser?: [{	id: string | Variable<any, string>},ValueTypes["usersDocAccess"]],
	meUser?:ValueTypes["usersMe"],
	initializedUser?:boolean | `@${string}`,
Bookmark?: [{	id: string | Variable<any, string>,	fallbackLocale?: ValueTypes["FallbackLocaleInputType"] | undefined | null | Variable<any, string>,	locale?: ValueTypes["LocaleInputType"] | undefined | null | Variable<any, string>},ValueTypes["Bookmark"]],
Bookmarks?: [{	where?: ValueTypes["Bookmark_where"] | undefined | null | Variable<any, string>,	fallbackLocale?: ValueTypes["FallbackLocaleInputType"] | undefined | null | Variable<any, string>,	locale?: ValueTypes["LocaleInputType"] | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>,	page?: number | undefined | null | Variable<any, string>,	sort?: string | undefined | null | Variable<any, string>},ValueTypes["Bookmarks"]],
countBookmarks?: [{	where?: ValueTypes["Bookmark_where"] | undefined | null | Variable<any, string>,	locale?: ValueTypes["LocaleInputType"] | undefined | null | Variable<any, string>},ValueTypes["countBookmarks"]],
docAccessBookmark?: [{	id: string | Variable<any, string>},ValueTypes["bookmarksDocAccess"]],
WebAppBookmark?: [{	id: string | Variable<any, string>,	fallbackLocale?: ValueTypes["FallbackLocaleInputType"] | undefined | null | Variable<any, string>,	locale?: ValueTypes["LocaleInputType"] | undefined | null | Variable<any, string>},ValueTypes["WebAppBookmark"]],
WebAppBookmarks?: [{	where?: ValueTypes["WebAppBookmark_where"] | undefined | null | Variable<any, string>,	fallbackLocale?: ValueTypes["FallbackLocaleInputType"] | undefined | null | Variable<any, string>,	locale?: ValueTypes["LocaleInputType"] | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>,	page?: number | undefined | null | Variable<any, string>,	sort?: string | undefined | null | Variable<any, string>},ValueTypes["WebAppBookmarks"]],
countWebAppBookmarks?: [{	where?: ValueTypes["WebAppBookmark_where"] | undefined | null | Variable<any, string>,	locale?: ValueTypes["LocaleInputType"] | undefined | null | Variable<any, string>},ValueTypes["countWebAppBookmarks"]],
docAccessWebAppBookmark?: [{	id: string | Variable<any, string>},ValueTypes["webAppBookmarksDocAccess"]],
ViewRecord?: [{	id: string | Variable<any, string>,	fallbackLocale?: ValueTypes["FallbackLocaleInputType"] | undefined | null | Variable<any, string>,	locale?: ValueTypes["LocaleInputType"] | undefined | null | Variable<any, string>},ValueTypes["ViewRecord"]],
ViewRecords?: [{	where?: ValueTypes["ViewRecord_where"] | undefined | null | Variable<any, string>,	fallbackLocale?: ValueTypes["FallbackLocaleInputType"] | undefined | null | Variable<any, string>,	locale?: ValueTypes["LocaleInputType"] | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>,	page?: number | undefined | null | Variable<any, string>,	sort?: string | undefined | null | Variable<any, string>},ValueTypes["ViewRecords"]],
countViewRecords?: [{	where?: ValueTypes["ViewRecord_where"] | undefined | null | Variable<any, string>,	locale?: ValueTypes["LocaleInputType"] | undefined | null | Variable<any, string>},ValueTypes["countViewRecords"]],
docAccessViewRecord?: [{	id: string | Variable<any, string>},ValueTypes["view_recordsDocAccess"]],
Comment?: [{	id: string | Variable<any, string>,	fallbackLocale?: ValueTypes["FallbackLocaleInputType"] | undefined | null | Variable<any, string>,	locale?: ValueTypes["LocaleInputType"] | undefined | null | Variable<any, string>},ValueTypes["Comment"]],
Comments?: [{	where?: ValueTypes["Comment_where"] | undefined | null | Variable<any, string>,	fallbackLocale?: ValueTypes["FallbackLocaleInputType"] | undefined | null | Variable<any, string>,	locale?: ValueTypes["LocaleInputType"] | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>,	page?: number | undefined | null | Variable<any, string>,	sort?: string | undefined | null | Variable<any, string>},ValueTypes["Comments"]],
countComments?: [{	where?: ValueTypes["Comment_where"] | undefined | null | Variable<any, string>,	locale?: ValueTypes["LocaleInputType"] | undefined | null | Variable<any, string>},ValueTypes["countComments"]],
docAccessComment?: [{	id: string | Variable<any, string>},ValueTypes["commentsDocAccess"]],
Subscription?: [{	id: string | Variable<any, string>,	fallbackLocale?: ValueTypes["FallbackLocaleInputType"] | undefined | null | Variable<any, string>,	locale?: ValueTypes["LocaleInputType"] | undefined | null | Variable<any, string>},ValueTypes["Subscription"]],
Subscriptions?: [{	where?: ValueTypes["Subscription_where"] | undefined | null | Variable<any, string>,	fallbackLocale?: ValueTypes["FallbackLocaleInputType"] | undefined | null | Variable<any, string>,	locale?: ValueTypes["LocaleInputType"] | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>,	page?: number | undefined | null | Variable<any, string>,	sort?: string | undefined | null | Variable<any, string>},ValueTypes["Subscriptions"]],
countSubscriptions?: [{	where?: ValueTypes["Subscription_where"] | undefined | null | Variable<any, string>,	locale?: ValueTypes["LocaleInputType"] | undefined | null | Variable<any, string>},ValueTypes["countSubscriptions"]],
docAccessSubscription?: [{	id: string | Variable<any, string>},ValueTypes["subscriptionsDocAccess"]],
Newsletter?: [{	id: string | Variable<any, string>,	fallbackLocale?: ValueTypes["FallbackLocaleInputType"] | undefined | null | Variable<any, string>,	locale?: ValueTypes["LocaleInputType"] | undefined | null | Variable<any, string>},ValueTypes["Newsletter"]],
Newsletters?: [{	where?: ValueTypes["Newsletter_where"] | undefined | null | Variable<any, string>,	fallbackLocale?: ValueTypes["FallbackLocaleInputType"] | undefined | null | Variable<any, string>,	locale?: ValueTypes["LocaleInputType"] | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>,	page?: number | undefined | null | Variable<any, string>,	sort?: string | undefined | null | Variable<any, string>},ValueTypes["Newsletters"]],
countNewsletters?: [{	where?: ValueTypes["Newsletter_where"] | undefined | null | Variable<any, string>,	locale?: ValueTypes["LocaleInputType"] | undefined | null | Variable<any, string>},ValueTypes["countNewsletters"]],
docAccessNewsletter?: [{	id: string | Variable<any, string>},ValueTypes["newsletterDocAccess"]],
BlogCategory?: [{	id: string | Variable<any, string>,	fallbackLocale?: ValueTypes["FallbackLocaleInputType"] | undefined | null | Variable<any, string>,	locale?: ValueTypes["LocaleInputType"] | undefined | null | Variable<any, string>},ValueTypes["BlogCategory"]],
BlogCategories?: [{	where?: ValueTypes["BlogCategory_where"] | undefined | null | Variable<any, string>,	fallbackLocale?: ValueTypes["FallbackLocaleInputType"] | undefined | null | Variable<any, string>,	locale?: ValueTypes["LocaleInputType"] | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>,	page?: number | undefined | null | Variable<any, string>,	sort?: string | undefined | null | Variable<any, string>},ValueTypes["BlogCategories"]],
countBlogCategories?: [{	where?: ValueTypes["BlogCategory_where"] | undefined | null | Variable<any, string>,	locale?: ValueTypes["LocaleInputType"] | undefined | null | Variable<any, string>},ValueTypes["countBlogCategories"]],
docAccessBlogCategory?: [{	id: string | Variable<any, string>},ValueTypes["blog_categoriesDocAccess"]],
Tag?: [{	id: string | Variable<any, string>,	fallbackLocale?: ValueTypes["FallbackLocaleInputType"] | undefined | null | Variable<any, string>,	locale?: ValueTypes["LocaleInputType"] | undefined | null | Variable<any, string>},ValueTypes["Tag"]],
Tags?: [{	where?: ValueTypes["Tag_where"] | undefined | null | Variable<any, string>,	fallbackLocale?: ValueTypes["FallbackLocaleInputType"] | undefined | null | Variable<any, string>,	locale?: ValueTypes["LocaleInputType"] | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>,	page?: number | undefined | null | Variable<any, string>,	sort?: string | undefined | null | Variable<any, string>},ValueTypes["Tags"]],
countTags?: [{	where?: ValueTypes["Tag_where"] | undefined | null | Variable<any, string>,	locale?: ValueTypes["LocaleInputType"] | undefined | null | Variable<any, string>},ValueTypes["countTags"]],
docAccessTag?: [{	id: string | Variable<any, string>},ValueTypes["tagsDocAccess"]],
SpecialTag?: [{	id: string | Variable<any, string>,	fallbackLocale?: ValueTypes["FallbackLocaleInputType"] | undefined | null | Variable<any, string>,	locale?: ValueTypes["LocaleInputType"] | undefined | null | Variable<any, string>},ValueTypes["SpecialTag"]],
SpecialTags?: [{	where?: ValueTypes["SpecialTag_where"] | undefined | null | Variable<any, string>,	fallbackLocale?: ValueTypes["FallbackLocaleInputType"] | undefined | null | Variable<any, string>,	locale?: ValueTypes["LocaleInputType"] | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>,	page?: number | undefined | null | Variable<any, string>,	sort?: string | undefined | null | Variable<any, string>},ValueTypes["SpecialTags"]],
countSpecialTags?: [{	where?: ValueTypes["SpecialTag_where"] | undefined | null | Variable<any, string>,	locale?: ValueTypes["LocaleInputType"] | undefined | null | Variable<any, string>},ValueTypes["countSpecialTags"]],
docAccessSpecialTag?: [{	id: string | Variable<any, string>},ValueTypes["specialTagsDocAccess"]],
Date?: [{	id: string | Variable<any, string>,	fallbackLocale?: ValueTypes["FallbackLocaleInputType"] | undefined | null | Variable<any, string>,	locale?: ValueTypes["LocaleInputType"] | undefined | null | Variable<any, string>},ValueTypes["Date"]],
Dates?: [{	where?: ValueTypes["Date_where"] | undefined | null | Variable<any, string>,	fallbackLocale?: ValueTypes["FallbackLocaleInputType"] | undefined | null | Variable<any, string>,	locale?: ValueTypes["LocaleInputType"] | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>,	page?: number | undefined | null | Variable<any, string>,	sort?: string | undefined | null | Variable<any, string>},ValueTypes["Dates"]],
countDates?: [{	where?: ValueTypes["Date_where"] | undefined | null | Variable<any, string>,	locale?: ValueTypes["LocaleInputType"] | undefined | null | Variable<any, string>},ValueTypes["countDates"]],
docAccessDate?: [{	id: string | Variable<any, string>},ValueTypes["dateDocAccess"]],
District?: [{	id: string | Variable<any, string>,	fallbackLocale?: ValueTypes["FallbackLocaleInputType"] | undefined | null | Variable<any, string>,	locale?: ValueTypes["LocaleInputType"] | undefined | null | Variable<any, string>},ValueTypes["District"]],
Districts?: [{	where?: ValueTypes["District_where"] | undefined | null | Variable<any, string>,	fallbackLocale?: ValueTypes["FallbackLocaleInputType"] | undefined | null | Variable<any, string>,	locale?: ValueTypes["LocaleInputType"] | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>,	page?: number | undefined | null | Variable<any, string>,	sort?: string | undefined | null | Variable<any, string>},ValueTypes["Districts"]],
countDistricts?: [{	where?: ValueTypes["District_where"] | undefined | null | Variable<any, string>,	locale?: ValueTypes["LocaleInputType"] | undefined | null | Variable<any, string>},ValueTypes["countDistricts"]],
docAccessDistrict?: [{	id: string | Variable<any, string>},ValueTypes["districtsDocAccess"]],
Audience?: [{	id: string | Variable<any, string>,	fallbackLocale?: ValueTypes["FallbackLocaleInputType"] | undefined | null | Variable<any, string>,	locale?: ValueTypes["LocaleInputType"] | undefined | null | Variable<any, string>},ValueTypes["Audience"]],
Audiences?: [{	where?: ValueTypes["Audience_where"] | undefined | null | Variable<any, string>,	fallbackLocale?: ValueTypes["FallbackLocaleInputType"] | undefined | null | Variable<any, string>,	locale?: ValueTypes["LocaleInputType"] | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>,	page?: number | undefined | null | Variable<any, string>,	sort?: string | undefined | null | Variable<any, string>},ValueTypes["Audiences"]],
countAudiences?: [{	where?: ValueTypes["Audience_where"] | undefined | null | Variable<any, string>,	locale?: ValueTypes["LocaleInputType"] | undefined | null | Variable<any, string>},ValueTypes["countAudiences"]],
docAccessAudience?: [{	id: string | Variable<any, string>},ValueTypes["audienceDocAccess"]],
EventCategory?: [{	id: string | Variable<any, string>,	fallbackLocale?: ValueTypes["FallbackLocaleInputType"] | undefined | null | Variable<any, string>,	locale?: ValueTypes["LocaleInputType"] | undefined | null | Variable<any, string>},ValueTypes["EventCategory"]],
EventCategories?: [{	where?: ValueTypes["EventCategory_where"] | undefined | null | Variable<any, string>,	fallbackLocale?: ValueTypes["FallbackLocaleInputType"] | undefined | null | Variable<any, string>,	locale?: ValueTypes["LocaleInputType"] | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>,	page?: number | undefined | null | Variable<any, string>,	sort?: string | undefined | null | Variable<any, string>},ValueTypes["EventCategories"]],
countEventCategories?: [{	where?: ValueTypes["EventCategory_where"] | undefined | null | Variable<any, string>,	locale?: ValueTypes["LocaleInputType"] | undefined | null | Variable<any, string>},ValueTypes["countEventCategories"]],
docAccessEventCategory?: [{	id: string | Variable<any, string>},ValueTypes["categoryDocAccess"]],
PrivateMedia?: [{	id: string | Variable<any, string>,	fallbackLocale?: ValueTypes["FallbackLocaleInputType"] | undefined | null | Variable<any, string>,	locale?: ValueTypes["LocaleInputType"] | undefined | null | Variable<any, string>},ValueTypes["PrivateMedia"]],
PrivateMedias?: [{	where?: ValueTypes["PrivateMedia_where"] | undefined | null | Variable<any, string>,	fallbackLocale?: ValueTypes["FallbackLocaleInputType"] | undefined | null | Variable<any, string>,	locale?: ValueTypes["LocaleInputType"] | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>,	page?: number | undefined | null | Variable<any, string>,	sort?: string | undefined | null | Variable<any, string>},ValueTypes["PrivateMedias"]],
countPrivateMedias?: [{	where?: ValueTypes["PrivateMedia_where"] | undefined | null | Variable<any, string>,	locale?: ValueTypes["LocaleInputType"] | undefined | null | Variable<any, string>},ValueTypes["countPrivateMedias"]],
docAccessPrivateMedia?: [{	id: string | Variable<any, string>},ValueTypes["private_mediaDocAccess"]],
Media?: [{	id: string | Variable<any, string>,	fallbackLocale?: ValueTypes["FallbackLocaleInputType"] | undefined | null | Variable<any, string>,	locale?: ValueTypes["LocaleInputType"] | undefined | null | Variable<any, string>},ValueTypes["Media"]],
allMedia?: [{	where?: ValueTypes["Media_where"] | undefined | null | Variable<any, string>,	fallbackLocale?: ValueTypes["FallbackLocaleInputType"] | undefined | null | Variable<any, string>,	locale?: ValueTypes["LocaleInputType"] | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>,	page?: number | undefined | null | Variable<any, string>,	sort?: string | undefined | null | Variable<any, string>},ValueTypes["allMedia"]],
countallMedia?: [{	where?: ValueTypes["Media_where"] | undefined | null | Variable<any, string>,	locale?: ValueTypes["LocaleInputType"] | undefined | null | Variable<any, string>},ValueTypes["countallMedia"]],
docAccessMedia?: [{	id: string | Variable<any, string>},ValueTypes["mediaDocAccess"]],
BackupMedia?: [{	id: string | Variable<any, string>,	fallbackLocale?: ValueTypes["FallbackLocaleInputType"] | undefined | null | Variable<any, string>,	locale?: ValueTypes["LocaleInputType"] | undefined | null | Variable<any, string>},ValueTypes["BackupMedia"]],
BackupMedias?: [{	where?: ValueTypes["BackupMedia_where"] | undefined | null | Variable<any, string>,	fallbackLocale?: ValueTypes["FallbackLocaleInputType"] | undefined | null | Variable<any, string>,	locale?: ValueTypes["LocaleInputType"] | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>,	page?: number | undefined | null | Variable<any, string>,	sort?: string | undefined | null | Variable<any, string>},ValueTypes["BackupMedias"]],
countBackupMedias?: [{	where?: ValueTypes["BackupMedia_where"] | undefined | null | Variable<any, string>,	locale?: ValueTypes["LocaleInputType"] | undefined | null | Variable<any, string>},ValueTypes["countBackupMedias"]],
docAccessBackupMedia?: [{	id: string | Variable<any, string>},ValueTypes["backupMediaDocAccess"]],
AccountSuspendRequest?: [{	id: string | Variable<any, string>,	fallbackLocale?: ValueTypes["FallbackLocaleInputType"] | undefined | null | Variable<any, string>,	locale?: ValueTypes["LocaleInputType"] | undefined | null | Variable<any, string>},ValueTypes["AccountSuspendRequest"]],
AccountSuspendRequests?: [{	where?: ValueTypes["AccountSuspendRequest_where"] | undefined | null | Variable<any, string>,	fallbackLocale?: ValueTypes["FallbackLocaleInputType"] | undefined | null | Variable<any, string>,	locale?: ValueTypes["LocaleInputType"] | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>,	page?: number | undefined | null | Variable<any, string>,	sort?: string | undefined | null | Variable<any, string>},ValueTypes["AccountSuspendRequests"]],
countAccountSuspendRequests?: [{	where?: ValueTypes["AccountSuspendRequest_where"] | undefined | null | Variable<any, string>,	locale?: ValueTypes["LocaleInputType"] | undefined | null | Variable<any, string>},ValueTypes["countAccountSuspendRequests"]],
docAccessAccountSuspendRequest?: [{	id: string | Variable<any, string>},ValueTypes["account_suspend_requestsDocAccess"]],
Device?: [{	id: string | Variable<any, string>,	fallbackLocale?: ValueTypes["FallbackLocaleInputType"] | undefined | null | Variable<any, string>,	locale?: ValueTypes["LocaleInputType"] | undefined | null | Variable<any, string>},ValueTypes["Device"]],
Devices?: [{	where?: ValueTypes["Device_where"] | undefined | null | Variable<any, string>,	fallbackLocale?: ValueTypes["FallbackLocaleInputType"] | undefined | null | Variable<any, string>,	locale?: ValueTypes["LocaleInputType"] | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>,	page?: number | undefined | null | Variable<any, string>,	sort?: string | undefined | null | Variable<any, string>},ValueTypes["Devices"]],
countDevices?: [{	where?: ValueTypes["Device_where"] | undefined | null | Variable<any, string>,	locale?: ValueTypes["LocaleInputType"] | undefined | null | Variable<any, string>},ValueTypes["countDevices"]],
docAccessDevice?: [{	id: string | Variable<any, string>},ValueTypes["devicesDocAccess"]],
Notification?: [{	id: string | Variable<any, string>,	fallbackLocale?: ValueTypes["FallbackLocaleInputType"] | undefined | null | Variable<any, string>,	locale?: ValueTypes["LocaleInputType"] | undefined | null | Variable<any, string>},ValueTypes["Notification"]],
Notifications?: [{	where?: ValueTypes["Notification_where"] | undefined | null | Variable<any, string>,	fallbackLocale?: ValueTypes["FallbackLocaleInputType"] | undefined | null | Variable<any, string>,	locale?: ValueTypes["LocaleInputType"] | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>,	page?: number | undefined | null | Variable<any, string>,	sort?: string | undefined | null | Variable<any, string>},ValueTypes["Notifications"]],
countNotifications?: [{	where?: ValueTypes["Notification_where"] | undefined | null | Variable<any, string>,	locale?: ValueTypes["LocaleInputType"] | undefined | null | Variable<any, string>},ValueTypes["countNotifications"]],
docAccessNotification?: [{	id: string | Variable<any, string>},ValueTypes["notificationsDocAccess"]],
RedirectSetting?: [{	id: string | Variable<any, string>,	fallbackLocale?: ValueTypes["FallbackLocaleInputType"] | undefined | null | Variable<any, string>,	locale?: ValueTypes["LocaleInputType"] | undefined | null | Variable<any, string>},ValueTypes["RedirectSetting"]],
RedirectSettings?: [{	where?: ValueTypes["RedirectSetting_where"] | undefined | null | Variable<any, string>,	fallbackLocale?: ValueTypes["FallbackLocaleInputType"] | undefined | null | Variable<any, string>,	locale?: ValueTypes["LocaleInputType"] | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>,	page?: number | undefined | null | Variable<any, string>,	sort?: string | undefined | null | Variable<any, string>},ValueTypes["RedirectSettings"]],
countRedirectSettings?: [{	where?: ValueTypes["RedirectSetting_where"] | undefined | null | Variable<any, string>,	locale?: ValueTypes["LocaleInputType"] | undefined | null | Variable<any, string>},ValueTypes["countRedirectSettings"]],
docAccessRedirectSetting?: [{	id: string | Variable<any, string>},ValueTypes["redirectSettingDocAccess"]],
EventRanking?: [{	id: string | Variable<any, string>,	fallbackLocale?: ValueTypes["FallbackLocaleInputType"] | undefined | null | Variable<any, string>,	locale?: ValueTypes["LocaleInputType"] | undefined | null | Variable<any, string>},ValueTypes["EventRanking"]],
EventRankings?: [{	where?: ValueTypes["EventRanking_where"] | undefined | null | Variable<any, string>,	fallbackLocale?: ValueTypes["FallbackLocaleInputType"] | undefined | null | Variable<any, string>,	locale?: ValueTypes["LocaleInputType"] | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>,	page?: number | undefined | null | Variable<any, string>,	sort?: string | undefined | null | Variable<any, string>},ValueTypes["EventRankings"]],
countEventRankings?: [{	where?: ValueTypes["EventRanking_where"] | undefined | null | Variable<any, string>,	locale?: ValueTypes["LocaleInputType"] | undefined | null | Variable<any, string>},ValueTypes["countEventRankings"]],
docAccessEventRanking?: [{	id: string | Variable<any, string>},ValueTypes["ranking_eventsDocAccess"]],
LocationRanking?: [{	id: string | Variable<any, string>,	fallbackLocale?: ValueTypes["FallbackLocaleInputType"] | undefined | null | Variable<any, string>,	locale?: ValueTypes["LocaleInputType"] | undefined | null | Variable<any, string>},ValueTypes["LocationRanking"]],
LocationRankings?: [{	where?: ValueTypes["LocationRanking_where"] | undefined | null | Variable<any, string>,	fallbackLocale?: ValueTypes["FallbackLocaleInputType"] | undefined | null | Variable<any, string>,	locale?: ValueTypes["LocaleInputType"] | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>,	page?: number | undefined | null | Variable<any, string>,	sort?: string | undefined | null | Variable<any, string>},ValueTypes["LocationRankings"]],
countLocationRankings?: [{	where?: ValueTypes["LocationRanking_where"] | undefined | null | Variable<any, string>,	locale?: ValueTypes["LocaleInputType"] | undefined | null | Variable<any, string>},ValueTypes["countLocationRankings"]],
docAccessLocationRanking?: [{	id: string | Variable<any, string>},ValueTypes["ranking_locationsDocAccess"]],
EventCalendar?: [{	id: string | Variable<any, string>,	fallbackLocale?: ValueTypes["FallbackLocaleInputType"] | undefined | null | Variable<any, string>,	locale?: ValueTypes["LocaleInputType"] | undefined | null | Variable<any, string>},ValueTypes["EventCalendar"]],
EventCalendars?: [{	where?: ValueTypes["EventCalendar_where"] | undefined | null | Variable<any, string>,	fallbackLocale?: ValueTypes["FallbackLocaleInputType"] | undefined | null | Variable<any, string>,	locale?: ValueTypes["LocaleInputType"] | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>,	page?: number | undefined | null | Variable<any, string>,	sort?: string | undefined | null | Variable<any, string>},ValueTypes["EventCalendars"]],
countEventCalendars?: [{	where?: ValueTypes["EventCalendar_where"] | undefined | null | Variable<any, string>,	locale?: ValueTypes["LocaleInputType"] | undefined | null | Variable<any, string>},ValueTypes["countEventCalendars"]],
docAccessEventCalendar?: [{	id: string | Variable<any, string>},ValueTypes["event_calendarDocAccess"]],
EditorChoice?: [{	id: string | Variable<any, string>,	fallbackLocale?: ValueTypes["FallbackLocaleInputType"] | undefined | null | Variable<any, string>,	locale?: ValueTypes["LocaleInputType"] | undefined | null | Variable<any, string>},ValueTypes["EditorChoice"]],
EditorChoices?: [{	where?: ValueTypes["EditorChoice_where"] | undefined | null | Variable<any, string>,	fallbackLocale?: ValueTypes["FallbackLocaleInputType"] | undefined | null | Variable<any, string>,	locale?: ValueTypes["LocaleInputType"] | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>,	page?: number | undefined | null | Variable<any, string>,	sort?: string | undefined | null | Variable<any, string>},ValueTypes["EditorChoices"]],
countEditorChoices?: [{	where?: ValueTypes["EditorChoice_where"] | undefined | null | Variable<any, string>,	locale?: ValueTypes["LocaleInputType"] | undefined | null | Variable<any, string>},ValueTypes["countEditorChoices"]],
docAccessEditorChoice?: [{	id: string | Variable<any, string>},ValueTypes["editor_choicesDocAccess"]],
CriteriaSetting?: [{	id: string | Variable<any, string>,	fallbackLocale?: ValueTypes["FallbackLocaleInputType"] | undefined | null | Variable<any, string>,	locale?: ValueTypes["LocaleInputType"] | undefined | null | Variable<any, string>},ValueTypes["CriteriaSetting"]],
CriteriaSettings?: [{	where?: ValueTypes["CriteriaSetting_where"] | undefined | null | Variable<any, string>,	fallbackLocale?: ValueTypes["FallbackLocaleInputType"] | undefined | null | Variable<any, string>,	locale?: ValueTypes["LocaleInputType"] | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>,	page?: number | undefined | null | Variable<any, string>,	sort?: string | undefined | null | Variable<any, string>},ValueTypes["CriteriaSettings"]],
countCriteriaSettings?: [{	where?: ValueTypes["CriteriaSetting_where"] | undefined | null | Variable<any, string>,	locale?: ValueTypes["LocaleInputType"] | undefined | null | Variable<any, string>},ValueTypes["countCriteriaSettings"]],
docAccessCriteriaSetting?: [{	id: string | Variable<any, string>},ValueTypes["criteria_settingDocAccess"]],
OrganiserSetting?: [{	id: string | Variable<any, string>,	fallbackLocale?: ValueTypes["FallbackLocaleInputType"] | undefined | null | Variable<any, string>,	locale?: ValueTypes["LocaleInputType"] | undefined | null | Variable<any, string>},ValueTypes["OrganiserSetting"]],
OrganiserSettings?: [{	where?: ValueTypes["OrganiserSetting_where"] | undefined | null | Variable<any, string>,	fallbackLocale?: ValueTypes["FallbackLocaleInputType"] | undefined | null | Variable<any, string>,	locale?: ValueTypes["LocaleInputType"] | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>,	page?: number | undefined | null | Variable<any, string>,	sort?: string | undefined | null | Variable<any, string>},ValueTypes["OrganiserSettings"]],
countOrganiserSettings?: [{	where?: ValueTypes["OrganiserSetting_where"] | undefined | null | Variable<any, string>,	locale?: ValueTypes["LocaleInputType"] | undefined | null | Variable<any, string>},ValueTypes["countOrganiserSettings"]],
docAccessOrganiserSetting?: [{	id: string | Variable<any, string>},ValueTypes["organiserSettingDocAccess"]],
PostSetting?: [{	id: string | Variable<any, string>,	fallbackLocale?: ValueTypes["FallbackLocaleInputType"] | undefined | null | Variable<any, string>,	locale?: ValueTypes["LocaleInputType"] | undefined | null | Variable<any, string>},ValueTypes["PostSetting"]],
PostSettings?: [{	where?: ValueTypes["PostSetting_where"] | undefined | null | Variable<any, string>,	fallbackLocale?: ValueTypes["FallbackLocaleInputType"] | undefined | null | Variable<any, string>,	locale?: ValueTypes["LocaleInputType"] | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>,	page?: number | undefined | null | Variable<any, string>,	sort?: string | undefined | null | Variable<any, string>},ValueTypes["PostSettings"]],
countPostSettings?: [{	where?: ValueTypes["PostSetting_where"] | undefined | null | Variable<any, string>,	locale?: ValueTypes["LocaleInputType"] | undefined | null | Variable<any, string>},ValueTypes["countPostSettings"]],
docAccessPostSetting?: [{	id: string | Variable<any, string>},ValueTypes["post_settingDocAccess"]],
HomepageSetting?: [{	id: string | Variable<any, string>,	fallbackLocale?: ValueTypes["FallbackLocaleInputType"] | undefined | null | Variable<any, string>,	locale?: ValueTypes["LocaleInputType"] | undefined | null | Variable<any, string>},ValueTypes["HomepageSetting"]],
HomepageSettings?: [{	where?: ValueTypes["HomepageSetting_where"] | undefined | null | Variable<any, string>,	fallbackLocale?: ValueTypes["FallbackLocaleInputType"] | undefined | null | Variable<any, string>,	locale?: ValueTypes["LocaleInputType"] | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>,	page?: number | undefined | null | Variable<any, string>,	sort?: string | undefined | null | Variable<any, string>},ValueTypes["HomepageSettings"]],
countHomepageSettings?: [{	where?: ValueTypes["HomepageSetting_where"] | undefined | null | Variable<any, string>,	locale?: ValueTypes["LocaleInputType"] | undefined | null | Variable<any, string>},ValueTypes["countHomepageSettings"]],
docAccessHomepageSetting?: [{	id: string | Variable<any, string>},ValueTypes["homepageSettingDocAccess"]],
BannerSection?: [{	id: string | Variable<any, string>,	fallbackLocale?: ValueTypes["FallbackLocaleInputType"] | undefined | null | Variable<any, string>,	locale?: ValueTypes["LocaleInputType"] | undefined | null | Variable<any, string>},ValueTypes["BannerSection"]],
BannerSections?: [{	where?: ValueTypes["BannerSection_where"] | undefined | null | Variable<any, string>,	fallbackLocale?: ValueTypes["FallbackLocaleInputType"] | undefined | null | Variable<any, string>,	locale?: ValueTypes["LocaleInputType"] | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>,	page?: number | undefined | null | Variable<any, string>,	sort?: string | undefined | null | Variable<any, string>},ValueTypes["BannerSections"]],
countBannerSections?: [{	where?: ValueTypes["BannerSection_where"] | undefined | null | Variable<any, string>,	locale?: ValueTypes["LocaleInputType"] | undefined | null | Variable<any, string>},ValueTypes["countBannerSections"]],
docAccessBannerSection?: [{	id: string | Variable<any, string>},ValueTypes["banner_sectionDocAccess"]],
MacaoBannerSection?: [{	id: string | Variable<any, string>,	fallbackLocale?: ValueTypes["FallbackLocaleInputType"] | undefined | null | Variable<any, string>,	locale?: ValueTypes["LocaleInputType"] | undefined | null | Variable<any, string>},ValueTypes["MacaoBannerSection"]],
MacaoBannerSections?: [{	where?: ValueTypes["MacaoBannerSection_where"] | undefined | null | Variable<any, string>,	fallbackLocale?: ValueTypes["FallbackLocaleInputType"] | undefined | null | Variable<any, string>,	locale?: ValueTypes["LocaleInputType"] | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>,	page?: number | undefined | null | Variable<any, string>,	sort?: string | undefined | null | Variable<any, string>},ValueTypes["MacaoBannerSections"]],
countMacaoBannerSections?: [{	where?: ValueTypes["MacaoBannerSection_where"] | undefined | null | Variable<any, string>,	locale?: ValueTypes["LocaleInputType"] | undefined | null | Variable<any, string>},ValueTypes["countMacaoBannerSections"]],
docAccessMacaoBannerSection?: [{	id: string | Variable<any, string>},ValueTypes["macao_banner_sectionDocAccess"]],
Banner?: [{	id: string | Variable<any, string>,	fallbackLocale?: ValueTypes["FallbackLocaleInputType"] | undefined | null | Variable<any, string>,	locale?: ValueTypes["LocaleInputType"] | undefined | null | Variable<any, string>},ValueTypes["Banner"]],
Banners?: [{	where?: ValueTypes["Banner_where"] | undefined | null | Variable<any, string>,	fallbackLocale?: ValueTypes["FallbackLocaleInputType"] | undefined | null | Variable<any, string>,	locale?: ValueTypes["LocaleInputType"] | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>,	page?: number | undefined | null | Variable<any, string>,	sort?: string | undefined | null | Variable<any, string>},ValueTypes["Banners"]],
countBanners?: [{	where?: ValueTypes["Banner_where"] | undefined | null | Variable<any, string>,	locale?: ValueTypes["LocaleInputType"] | undefined | null | Variable<any, string>},ValueTypes["countBanners"]],
docAccessBanner?: [{	id: string | Variable<any, string>},ValueTypes["bannersDocAccess"]],
Campaign?: [{	id: string | Variable<any, string>,	fallbackLocale?: ValueTypes["FallbackLocaleInputType"] | undefined | null | Variable<any, string>,	locale?: ValueTypes["LocaleInputType"] | undefined | null | Variable<any, string>},ValueTypes["Campaign"]],
Campaigns?: [{	where?: ValueTypes["Campaign_where"] | undefined | null | Variable<any, string>,	fallbackLocale?: ValueTypes["FallbackLocaleInputType"] | undefined | null | Variable<any, string>,	locale?: ValueTypes["LocaleInputType"] | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>,	page?: number | undefined | null | Variable<any, string>,	sort?: string | undefined | null | Variable<any, string>},ValueTypes["Campaigns"]],
countCampaigns?: [{	where?: ValueTypes["Campaign_where"] | undefined | null | Variable<any, string>,	locale?: ValueTypes["LocaleInputType"] | undefined | null | Variable<any, string>},ValueTypes["countCampaigns"]],
docAccessCampaign?: [{	id: string | Variable<any, string>},ValueTypes["campaignsDocAccess"]],
Advertise?: [{	id: string | Variable<any, string>,	fallbackLocale?: ValueTypes["FallbackLocaleInputType"] | undefined | null | Variable<any, string>,	locale?: ValueTypes["LocaleInputType"] | undefined | null | Variable<any, string>},ValueTypes["Advertise"]],
Advertises?: [{	where?: ValueTypes["Advertise_where"] | undefined | null | Variable<any, string>,	fallbackLocale?: ValueTypes["FallbackLocaleInputType"] | undefined | null | Variable<any, string>,	locale?: ValueTypes["LocaleInputType"] | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>,	page?: number | undefined | null | Variable<any, string>,	sort?: string | undefined | null | Variable<any, string>},ValueTypes["Advertises"]],
countAdvertises?: [{	where?: ValueTypes["Advertise_where"] | undefined | null | Variable<any, string>,	locale?: ValueTypes["LocaleInputType"] | undefined | null | Variable<any, string>},ValueTypes["countAdvertises"]],
docAccessAdvertise?: [{	id: string | Variable<any, string>},ValueTypes["advertisesDocAccess"]],
Static?: [{	id: string | Variable<any, string>,	fallbackLocale?: ValueTypes["FallbackLocaleInputType"] | undefined | null | Variable<any, string>,	locale?: ValueTypes["LocaleInputType"] | undefined | null | Variable<any, string>},ValueTypes["Static"]],
Statics?: [{	where?: ValueTypes["Static_where"] | undefined | null | Variable<any, string>,	fallbackLocale?: ValueTypes["FallbackLocaleInputType"] | undefined | null | Variable<any, string>,	locale?: ValueTypes["LocaleInputType"] | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>,	page?: number | undefined | null | Variable<any, string>,	sort?: string | undefined | null | Variable<any, string>},ValueTypes["Statics"]],
countStatics?: [{	where?: ValueTypes["Static_where"] | undefined | null | Variable<any, string>,	locale?: ValueTypes["LocaleInputType"] | undefined | null | Variable<any, string>},ValueTypes["countStatics"]],
docAccessStatic?: [{	id: string | Variable<any, string>},ValueTypes["staticsDocAccess"]],
EventView?: [{	id: string | Variable<any, string>,	fallbackLocale?: ValueTypes["FallbackLocaleInputType"] | undefined | null | Variable<any, string>,	locale?: ValueTypes["LocaleInputType"] | undefined | null | Variable<any, string>},ValueTypes["EventView"]],
EventViews?: [{	where?: ValueTypes["EventView_where"] | undefined | null | Variable<any, string>,	fallbackLocale?: ValueTypes["FallbackLocaleInputType"] | undefined | null | Variable<any, string>,	locale?: ValueTypes["LocaleInputType"] | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>,	page?: number | undefined | null | Variable<any, string>,	sort?: string | undefined | null | Variable<any, string>},ValueTypes["EventViews"]],
countEventViews?: [{	where?: ValueTypes["EventView_where"] | undefined | null | Variable<any, string>,	locale?: ValueTypes["LocaleInputType"] | undefined | null | Variable<any, string>},ValueTypes["countEventViews"]],
docAccessEventView?: [{	id: string | Variable<any, string>},ValueTypes["event_viewsDocAccess"]],
PostView?: [{	id: string | Variable<any, string>,	fallbackLocale?: ValueTypes["FallbackLocaleInputType"] | undefined | null | Variable<any, string>,	locale?: ValueTypes["LocaleInputType"] | undefined | null | Variable<any, string>},ValueTypes["PostView"]],
PostViews?: [{	where?: ValueTypes["PostView_where"] | undefined | null | Variable<any, string>,	fallbackLocale?: ValueTypes["FallbackLocaleInputType"] | undefined | null | Variable<any, string>,	locale?: ValueTypes["LocaleInputType"] | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>,	page?: number | undefined | null | Variable<any, string>,	sort?: string | undefined | null | Variable<any, string>},ValueTypes["PostViews"]],
countPostViews?: [{	where?: ValueTypes["PostView_where"] | undefined | null | Variable<any, string>,	locale?: ValueTypes["LocaleInputType"] | undefined | null | Variable<any, string>},ValueTypes["countPostViews"]],
docAccessPostView?: [{	id: string | Variable<any, string>},ValueTypes["post_viewsDocAccess"]],
PayloadPreference?: [{	id: string | Variable<any, string>,	fallbackLocale?: ValueTypes["FallbackLocaleInputType"] | undefined | null | Variable<any, string>,	locale?: ValueTypes["LocaleInputType"] | undefined | null | Variable<any, string>},ValueTypes["PayloadPreference"]],
PayloadPreferences?: [{	where?: ValueTypes["PayloadPreference_where"] | undefined | null | Variable<any, string>,	fallbackLocale?: ValueTypes["FallbackLocaleInputType"] | undefined | null | Variable<any, string>,	locale?: ValueTypes["LocaleInputType"] | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>,	page?: number | undefined | null | Variable<any, string>,	sort?: string | undefined | null | Variable<any, string>},ValueTypes["PayloadPreferences"]],
countPayloadPreferences?: [{	where?: ValueTypes["PayloadPreference_where"] | undefined | null | Variable<any, string>,	locale?: ValueTypes["LocaleInputType"] | undefined | null | Variable<any, string>},ValueTypes["countPayloadPreferences"]],
docAccessPayloadPreference?: [{	id: string | Variable<any, string>},ValueTypes["payload_preferencesDocAccess"]],
	Access?:ValueTypes["Access"],
GetEvents?: [{	where?: ValueTypes["Event_where"] | undefined | null | Variable<any, string>,	fallbackLocale?: ValueTypes["FallbackLocaleInputType"] | undefined | null | Variable<any, string>,	locale?: ValueTypes["LocaleInputType"] | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>,	page?: number | undefined | null | Variable<any, string>,	sort?: string | undefined | null | Variable<any, string>},ValueTypes["Events"]],
GetFilterEvents?: [{	where?: ValueTypes["Event_where"] | undefined | null | Variable<any, string>,	fallbackLocale?: ValueTypes["FallbackLocaleInputType"] | undefined | null | Variable<any, string>,	locale?: ValueTypes["LocaleInputType"] | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>,	page?: number | undefined | null | Variable<any, string>,	sort?: string | undefined | null | Variable<any, string>},ValueTypes["Events"]],
GetFilterPastEvents?: [{	where?: ValueTypes["Event_where"] | undefined | null | Variable<any, string>,	fallbackLocale?: ValueTypes["FallbackLocaleInputType"] | undefined | null | Variable<any, string>,	locale?: ValueTypes["LocaleInputType"] | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>,	page?: number | undefined | null | Variable<any, string>,	sort?: string | undefined | null | Variable<any, string>},ValueTypes["Events"]],
GetEventMaxUsedTag?: [{	filterType?: string | undefined | null | Variable<any, string>,	where?: ValueTypes["Event_where"] | undefined | null | Variable<any, string>,	fallbackLocale?: ValueTypes["FallbackLocaleInputType"] | undefined | null | Variable<any, string>,	locale?: ValueTypes["LocaleInputType"] | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>,	page?: number | undefined | null | Variable<any, string>,	sort?: string | undefined | null | Variable<any, string>},ValueTypes["GetEventMaxUsedTag"]],
GetSortEvent?: [{	where?: ValueTypes["Event_where"] | undefined | null | Variable<any, string>,	fallbackLocale?: ValueTypes["FallbackLocaleInputType"] | undefined | null | Variable<any, string>,	locale?: ValueTypes["LocaleInputType"] | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>,	page?: number | undefined | null | Variable<any, string>,	sort?: string | undefined | null | Variable<any, string>},ValueTypes["Events"]],
BookmarkEvents?: [{	where?: ValueTypes["Event_where"] | undefined | null | Variable<any, string>,	fallbackLocale?: ValueTypes["FallbackLocaleInputType"] | undefined | null | Variable<any, string>,	locale?: ValueTypes["LocaleInputType"] | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>,	page?: number | undefined | null | Variable<any, string>,	sort?: string | undefined | null | Variable<any, string>},ValueTypes["Events"]],
GetBookmarks?: [{	where?: ValueTypes["Bookmark_where"] | undefined | null | Variable<any, string>,	fallbackLocale?: ValueTypes["FallbackLocaleInputType"] | undefined | null | Variable<any, string>,	locale?: ValueTypes["LocaleInputType"] | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>,	page?: number | undefined | null | Variable<any, string>,	sort?: string | undefined | null | Variable<any, string>},ValueTypes["Bookmarks"]],
BookmarkItems?: [{	where?: ValueTypes["Bookmark_where"] | undefined | null | Variable<any, string>,	fallbackLocale?: ValueTypes["FallbackLocaleInputType"] | undefined | null | Variable<any, string>,	locale?: ValueTypes["LocaleInputType"] | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>,	page?: number | undefined | null | Variable<any, string>,	sort?: string | undefined | null | Variable<any, string>},ValueTypes["Bookmarks"]],
ViewedEvents?: [{	where?: ValueTypes["Event_where"] | undefined | null | Variable<any, string>,	fallbackLocale?: ValueTypes["FallbackLocaleInputType"] | undefined | null | Variable<any, string>,	locale?: ValueTypes["LocaleInputType"] | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>,	page?: number | undefined | null | Variable<any, string>,	sort?: string | undefined | null | Variable<any, string>},ValueTypes["Events"]],
NonFeaturedPosts?: [{	where?: ValueTypes["Post_where"] | undefined | null | Variable<any, string>,	fallbackLocale?: ValueTypes["FallbackLocaleInputType"] | undefined | null | Variable<any, string>,	locale?: ValueTypes["LocaleInputType"] | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>,	page?: number | undefined | null | Variable<any, string>,	sort?: string | undefined | null | Variable<any, string>},ValueTypes["Posts"]],
CheckEmailUniqueness?: [{	email?: string | undefined | null | Variable<any, string>},boolean | `@${string}`],
GetOrganisers?: [{	where?: ValueTypes["User_where"] | undefined | null | Variable<any, string>,	fallbackLocale?: ValueTypes["FallbackLocaleInputType"] | undefined | null | Variable<any, string>,	locale?: ValueTypes["LocaleInputType"] | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>,	page?: number | undefined | null | Variable<any, string>,	sort?: string | undefined | null | Variable<any, string>},ValueTypes["Users"]],
GetOrganiser?: [{	id: string | Variable<any, string>,	fallbackLocale?: ValueTypes["FallbackLocaleInputType"] | undefined | null | Variable<any, string>,	locale?: ValueTypes["LocaleInputType"] | undefined | null | Variable<any, string>},ValueTypes["User"]],
GetSubscriptions?: [{	where?: ValueTypes["Subscription_where"] | undefined | null | Variable<any, string>,	fallbackLocale?: ValueTypes["FallbackLocaleInputType"] | undefined | null | Variable<any, string>,	locale?: ValueTypes["LocaleInputType"] | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>,	page?: number | undefined | null | Variable<any, string>,	sort?: string | undefined | null | Variable<any, string>},ValueTypes["Subscriptions"]],
GetFollowed?: [{	where?: ValueTypes["Subscription_where"] | undefined | null | Variable<any, string>,	fallbackLocale?: ValueTypes["FallbackLocaleInputType"] | undefined | null | Variable<any, string>,	locale?: ValueTypes["LocaleInputType"] | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>,	page?: number | undefined | null | Variable<any, string>,	sort?: string | undefined | null | Variable<any, string>},ValueTypes["Subscriptions"]],
GetComments?: [{	where?: ValueTypes["Comment_where"] | undefined | null | Variable<any, string>,	fallbackLocale?: ValueTypes["FallbackLocaleInputType"] | undefined | null | Variable<any, string>,	locale?: ValueTypes["LocaleInputType"] | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>,	page?: number | undefined | null | Variable<any, string>,	sort?: string | undefined | null | Variable<any, string>},ValueTypes["Comments"]],
CommentsCount?: [{	where?: ValueTypes["Comment_where"] | undefined | null | Variable<any, string>,	fallbackLocale?: ValueTypes["FallbackLocaleInputType"] | undefined | null | Variable<any, string>,	locale?: ValueTypes["LocaleInputType"] | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>,	page?: number | undefined | null | Variable<any, string>,	sort?: string | undefined | null | Variable<any, string>},ValueTypes["Comments"]],
GetBanners?: [{	where?: ValueTypes["Banner_where"] | undefined | null | Variable<any, string>,	fallbackLocale?: ValueTypes["FallbackLocaleInputType"] | undefined | null | Variable<any, string>,	locale?: ValueTypes["LocaleInputType"] | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>,	page?: number | undefined | null | Variable<any, string>,	sort?: string | undefined | null | Variable<any, string>},ValueTypes["Banners"]],
GetBannerSection?: [{	where?: ValueTypes["Banner_where"] | undefined | null | Variable<any, string>,	fallbackLocale?: ValueTypes["FallbackLocaleInputType"] | undefined | null | Variable<any, string>,	locale?: ValueTypes["LocaleInputType"] | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>,	page?: number | undefined | null | Variable<any, string>,	sort?: string | undefined | null | Variable<any, string>,	slug?: string | undefined | null | Variable<any, string>},ValueTypes["Banners"]],
GetNotifications?: [{	where?: ValueTypes["Notification_where"] | undefined | null | Variable<any, string>,	fallbackLocale?: ValueTypes["FallbackLocaleInputType"] | undefined | null | Variable<any, string>,	locale?: ValueTypes["LocaleInputType"] | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>,	page?: number | undefined | null | Variable<any, string>,	sort?: string | undefined | null | Variable<any, string>},ValueTypes["Notifications"]],
GetCriteriaSettings?: [{	where?: ValueTypes["CriteriaSetting_where"] | undefined | null | Variable<any, string>,	fallbackLocale?: ValueTypes["FallbackLocaleInputType"] | undefined | null | Variable<any, string>,	locale?: ValueTypes["LocaleInputType"] | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>,	page?: number | undefined | null | Variable<any, string>,	sort?: string | undefined | null | Variable<any, string>},ValueTypes["CriteriaSettings"]],
GetFilterLocations?: [{	where?: ValueTypes["Location_where"] | undefined | null | Variable<any, string>,	fallbackLocale?: ValueTypes["FallbackLocaleInputType"] | undefined | null | Variable<any, string>,	locale?: ValueTypes["LocaleInputType"] | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>,	page?: number | undefined | null | Variable<any, string>,	sort?: string | undefined | null | Variable<any, string>},ValueTypes["Locations"]],
GetContainEventTagLocations?: [{	where?: ValueTypes["Location_where"] | undefined | null | Variable<any, string>,	fallbackLocale?: ValueTypes["FallbackLocaleInputType"] | undefined | null | Variable<any, string>,	locale?: ValueTypes["LocaleInputType"] | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>,	page?: number | undefined | null | Variable<any, string>,	sort?: string | undefined | null | Variable<any, string>},ValueTypes["Locations"]],
GetEventRankings?: [{	where?: ValueTypes["EventRanking_where"] | undefined | null | Variable<any, string>,	fallbackLocale?: ValueTypes["FallbackLocaleInputType"] | undefined | null | Variable<any, string>,	locale?: ValueTypes["LocaleInputType"] | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>,	page?: number | undefined | null | Variable<any, string>,	sort?: string | undefined | null | Variable<any, string>},ValueTypes["EventRankings"]],
ChatbotStocks?: [{	locale?: ValueTypes["LocaleInputType"] | undefined | null | Variable<any, string>},ValueTypes["ChatbotStocks"]],
ChatbotProduct?: [{	productId: string | Variable<any, string>,	locale?: ValueTypes["LocaleInputType"] | undefined | null | Variable<any, string>},ValueTypes["Product"]],
ChatbotTicketing?: [{	ticketingId: string | Variable<any, string>,	locale?: ValueTypes["LocaleInputType"] | undefined | null | Variable<any, string>},ValueTypes["Ticketing"]],
ChatbotEvents?: [{	locale?: ValueTypes["LocaleInputType"] | undefined | null | Variable<any, string>},ValueTypes["ChatbotEvents"]],
FilterExpiredEvent?: [{	where?: ValueTypes["EditorChoice_where"] | undefined | null | Variable<any, string>,	fallbackLocale?: ValueTypes["FallbackLocaleInputType"] | undefined | null | Variable<any, string>,	locale?: ValueTypes["LocaleInputType"] | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>,	page?: number | undefined | null | Variable<any, string>,	sort?: string | undefined | null | Variable<any, string>},ValueTypes["EditorChoices"]],
WebAppCheckInCountGet?: [{	checkInAppSelectionId: string | Variable<any, string>,	productType: string | Variable<any, string>,	locale?: ValueTypes["LocaleInputType"] | undefined | null | Variable<any, string>},ValueTypes["WebAppCheckInCountGetResponse"]],
WebAppCheckInList?: [{	where?: ValueTypes["CheckInRedemption_where"] | undefined | null | Variable<any, string>,	fallbackLocale?: ValueTypes["FallbackLocaleInputType"] | undefined | null | Variable<any, string>,	locale?: ValueTypes["LocaleInputType"] | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>,	page?: number | undefined | null | Variable<any, string>,	sort?: string | undefined | null | Variable<any, string>},ValueTypes["CheckInRedemptions"]],
WebAppEventList?: [{	where?: ValueTypes["Event_where"] | undefined | null | Variable<any, string>,	fallbackLocale?: ValueTypes["FallbackLocaleInputType"] | undefined | null | Variable<any, string>,	locale?: ValueTypes["LocaleInputType"] | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>,	page?: number | undefined | null | Variable<any, string>,	sort?: string | undefined | null | Variable<any, string>},ValueTypes["Events"]],
WebAppProductDetail?: [{	locale?: ValueTypes["LocaleInputType"] | undefined | null | Variable<any, string>,	where?: ValueTypes["ProductWhereInput"] | undefined | null | Variable<any, string>},ValueTypes["Product"]],
WebAppProductList?: [{	eventId: string | Variable<any, string>,	locale?: ValueTypes["LocaleInputType"] | undefined | null | Variable<any, string>},ValueTypes["ProductList"]],
WebAppTicketingDetail?: [{	locale?: ValueTypes["LocaleInputType"] | undefined | null | Variable<any, string>,	where?: ValueTypes["TicketingWhereInput"] | undefined | null | Variable<any, string>},ValueTypes["Ticketing"]],
WebAppTicketingList?: [{	eventId: string | Variable<any, string>,	locale?: ValueTypes["LocaleInputType"] | undefined | null | Variable<any, string>},ValueTypes["TicketingList"]],
WebAppRedemptionCodeList?: [{	locale?: ValueTypes["LocaleInputType"] | undefined | null | Variable<any, string>},ValueTypes["RedemptionCodeList"]],
WebAppRedemptionCodeDetail?: [{	where?: ValueTypes["RedemptionCodeWhereInput"] | undefined | null | Variable<any, string>},ValueTypes["RedemptionCode"]],
WebAppBookmarkList?: [{	userId?: string | undefined | null | Variable<any, string>},ValueTypes["BookmarkList"]],
		__typename?: boolean | `@${string}`
}>;
	["Event"]: AliasType<{
	id?:boolean | `@${string}`,
	region?:boolean | `@${string}`,
creator?: [{	locale?: ValueTypes["LocaleInputType"] | undefined | null | Variable<any, string>,	fallbackLocale?: ValueTypes["FallbackLocaleInputType"] | undefined | null | Variable<any, string>},ValueTypes["User"]],
	permalink?:boolean | `@${string}`,
	status?:boolean | `@${string}`,
	level?:boolean | `@${string}`,
parent?: [{	locale?: ValueTypes["LocaleInputType"] | undefined | null | Variable<any, string>,	fallbackLocale?: ValueTypes["FallbackLocaleInputType"] | undefined | null | Variable<any, string>},ValueTypes["Event"]],
	name?:boolean | `@${string}`,
	chatbotEventTitle?:boolean | `@${string}`,
	chatbotSequence?:boolean | `@${string}`,
thumbnail?: [{	locale?: ValueTypes["LocaleInputType"] | undefined | null | Variable<any, string>,	fallbackLocale?: ValueTypes["FallbackLocaleInputType"] | undefined | null | Variable<any, string>,	where?: ValueTypes["Event_Thumbnail_where"] | undefined | null | Variable<any, string>},ValueTypes["Media"]],
banner?: [{	locale?: ValueTypes["LocaleInputType"] | undefined | null | Variable<any, string>,	fallbackLocale?: ValueTypes["FallbackLocaleInputType"] | undefined | null | Variable<any, string>,	where?: ValueTypes["Event_Banner_where"] | undefined | null | Variable<any, string>},ValueTypes["Media"]],
mobileBanner?: [{	locale?: ValueTypes["LocaleInputType"] | undefined | null | Variable<any, string>,	fallbackLocale?: ValueTypes["FallbackLocaleInputType"] | undefined | null | Variable<any, string>,	where?: ValueTypes["Event_MobileBanner_where"] | undefined | null | Variable<any, string>},ValueTypes["Media"]],
	sections?:ValueTypes["Event_Sections"],
	ticketing?:ValueTypes["Event_Ticketing"],
	content?:ValueTypes["Event_Content"],
	legacyContent?:boolean | `@${string}`,
	showLegacyContent?:boolean | `@${string}`,
	criteria?:ValueTypes["Event_Criteria"],
	walkIn?:boolean | `@${string}`,
	contact?:ValueTypes["Event_Contact"],
	views?:boolean | `@${string}`,
	free?:boolean | `@${string}`,
	fees?:ValueTypes["Event_Fees"],
	homePageInternalClick?:boolean | `@${string}`,
	homePageImpression?:boolean | `@${string}`,
	eodClickRate?:boolean | `@${string}`,
	eodImpressionRate?:boolean | `@${string}`,
	top10ClickRateHomepage?:boolean | `@${string}`,
	top10ImpressionkRateHomepage?:boolean | `@${string}`,
	otherEventRankingClickRateHomepage?:boolean | `@${string}`,
	otherEventRankingImpressionHomePage?:boolean | `@${string}`,
	userPreferenceClickRate?:boolean | `@${string}`,
	userPreferenceImpression?:boolean | `@${string}`,
	eventSearchInternalClick?:boolean | `@${string}`,
	eventListImpression?:boolean | `@${string}`,
	latestEventInternalClick?:boolean | `@${string}`,
	latestEventImpression?:boolean | `@${string}`,
	topTenEventRankingInternalClick?:boolean | `@${string}`,
	topTenImpression?:boolean | `@${string}`,
	editorChoiceInternalClick?:boolean | `@${string}`,
	editorChoiceImpression?:boolean | `@${string}`,
	otherEventRankingInternalClick?:boolean | `@${string}`,
	otherEventRankingImpression?:boolean | `@${string}`,
	bannerInternalClick?:boolean | `@${string}`,
	miscImpression?:boolean | `@${string}`,
	miscInternalClick?:boolean | `@${string}`,
	totalImpression?:boolean | `@${string}`,
	internalClick?:boolean | `@${string}`,
	outboundClick?:boolean | `@${string}`,
	externalClick?:boolean | `@${string}`,
	_viewsCountByMonth?:boolean | `@${string}`,
	products?:ValueTypes["Event_Products"],
legacyGallery?: [{	locale?: ValueTypes["LocaleInputType"] | undefined | null | Variable<any, string>,	fallbackLocale?: ValueTypes["FallbackLocaleInputType"] | undefined | null | Variable<any, string>},ValueTypes["Media"]],
	earliestStartDate?:boolean | `@${string}`,
	latestEndDate?:boolean | `@${string}`,
	latestStartDateTime?:boolean | `@${string}`,
	hasTicketing?:boolean | `@${string}`,
	hasProducts?:boolean | `@${string}`,
	updatedAt?:boolean | `@${string}`,
	createdAt?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["Event_region"]:Event_region;
	["User"]: AliasType<{
	id?:boolean | `@${string}`,
	region?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	displayEmail?:boolean | `@${string}`,
	legacyUsername?:boolean | `@${string}`,
	roles?:boolean | `@${string}`,
	member?:ValueTypes["User_Member"],
	organiser?:ValueTypes["User_Organiser"],
	emailVerified?:boolean | `@${string}`,
	sso?:ValueTypes["User_Sso"],
	oldPassword?:boolean | `@${string}`,
	status?:boolean | `@${string}`,
	updatedAt?:boolean | `@${string}`,
	createdAt?:boolean | `@${string}`,
	email?:boolean | `@${string}`,
	resetPasswordToken?:boolean | `@${string}`,
	resetPasswordExpiration?:boolean | `@${string}`,
	salt?:boolean | `@${string}`,
	hash?:boolean | `@${string}`,
	loginAttempts?:boolean | `@${string}`,
	lockUntil?:boolean | `@${string}`,
	password?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["User_region"]:User_region;
	["User_roles"]:User_roles;
	["User_Member"]: AliasType<{
	region?:boolean | `@${string}`,
	language?:boolean | `@${string}`,
	phone?:boolean | `@${string}`,
avatar?: [{	locale?: ValueTypes["LocaleInputType"] | undefined | null | Variable<any, string>,	fallbackLocale?: ValueTypes["FallbackLocaleInputType"] | undefined | null | Variable<any, string>,	where?: ValueTypes["User_Member_Avatar_where"] | undefined | null | Variable<any, string>},ValueTypes["Media"]],
	birthday?:boolean | `@${string}`,
	gender?:boolean | `@${string}`,
categories?: [{	locale?: ValueTypes["LocaleInputType"] | undefined | null | Variable<any, string>,	fallbackLocale?: ValueTypes["FallbackLocaleInputType"] | undefined | null | Variable<any, string>},ValueTypes["EventCategory"]],
		__typename?: boolean | `@${string}`
}>;
	["User_Member_region"]:User_Member_region;
	["User_Member_language"]:User_Member_language;
	["Media"]: AliasType<{
	id?:boolean | `@${string}`,
	alt?:boolean | `@${string}`,
	updatedAt?:boolean | `@${string}`,
	createdAt?:boolean | `@${string}`,
	url?:boolean | `@${string}`,
	filename?:boolean | `@${string}`,
	mimeType?:boolean | `@${string}`,
	filesize?:boolean | `@${string}`,
	width?:boolean | `@${string}`,
	height?:boolean | `@${string}`,
	focalX?:boolean | `@${string}`,
	focalY?:boolean | `@${string}`,
	sizes?:ValueTypes["Media_Sizes"],
		__typename?: boolean | `@${string}`
}>;
	/** A date-time string at UTC, such as 2007-12-03T10:15:30Z, compliant with the `date-time` format outlined in section 5.6 of the RFC 3339 profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar. */
["DateTime"]:unknown;
	["Media_Sizes"]: AliasType<{
	small?:ValueTypes["Media_Sizes_Small"],
	medium?:ValueTypes["Media_Sizes_Medium"],
	large?:ValueTypes["Media_Sizes_Large"],
	small_jpeg?:ValueTypes["Media_Sizes_Small_jpeg"],
	medium_jpeg?:ValueTypes["Media_Sizes_Medium_jpeg"],
	large_jpeg?:ValueTypes["Media_Sizes_Large_jpeg"],
		__typename?: boolean | `@${string}`
}>;
	["Media_Sizes_Small"]: AliasType<{
	url?:boolean | `@${string}`,
	width?:boolean | `@${string}`,
	height?:boolean | `@${string}`,
	mimeType?:boolean | `@${string}`,
	filesize?:boolean | `@${string}`,
	filename?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["Media_Sizes_Medium"]: AliasType<{
	url?:boolean | `@${string}`,
	width?:boolean | `@${string}`,
	height?:boolean | `@${string}`,
	mimeType?:boolean | `@${string}`,
	filesize?:boolean | `@${string}`,
	filename?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["Media_Sizes_Large"]: AliasType<{
	url?:boolean | `@${string}`,
	width?:boolean | `@${string}`,
	height?:boolean | `@${string}`,
	mimeType?:boolean | `@${string}`,
	filesize?:boolean | `@${string}`,
	filename?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["Media_Sizes_Small_jpeg"]: AliasType<{
	url?:boolean | `@${string}`,
	width?:boolean | `@${string}`,
	height?:boolean | `@${string}`,
	mimeType?:boolean | `@${string}`,
	filesize?:boolean | `@${string}`,
	filename?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["Media_Sizes_Medium_jpeg"]: AliasType<{
	url?:boolean | `@${string}`,
	width?:boolean | `@${string}`,
	height?:boolean | `@${string}`,
	mimeType?:boolean | `@${string}`,
	filesize?:boolean | `@${string}`,
	filename?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["Media_Sizes_Large_jpeg"]: AliasType<{
	url?:boolean | `@${string}`,
	width?:boolean | `@${string}`,
	height?:boolean | `@${string}`,
	mimeType?:boolean | `@${string}`,
	filesize?:boolean | `@${string}`,
	filename?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocaleInputType"]:LocaleInputType;
	["FallbackLocaleInputType"]:FallbackLocaleInputType;
	["User_Member_Avatar_where"]: {
	alt?: ValueTypes["User_Member_Avatar_alt_operator"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["User_Member_Avatar_updatedAt_operator"] | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["User_Member_Avatar_createdAt_operator"] | undefined | null | Variable<any, string>,
	url?: ValueTypes["User_Member_Avatar_url_operator"] | undefined | null | Variable<any, string>,
	filename?: ValueTypes["User_Member_Avatar_filename_operator"] | undefined | null | Variable<any, string>,
	mimeType?: ValueTypes["User_Member_Avatar_mimeType_operator"] | undefined | null | Variable<any, string>,
	filesize?: ValueTypes["User_Member_Avatar_filesize_operator"] | undefined | null | Variable<any, string>,
	width?: ValueTypes["User_Member_Avatar_width_operator"] | undefined | null | Variable<any, string>,
	height?: ValueTypes["User_Member_Avatar_height_operator"] | undefined | null | Variable<any, string>,
	focalX?: ValueTypes["User_Member_Avatar_focalX_operator"] | undefined | null | Variable<any, string>,
	focalY?: ValueTypes["User_Member_Avatar_focalY_operator"] | undefined | null | Variable<any, string>,
	sizes__small__url?: ValueTypes["User_Member_Avatar_sizes__small__url_operator"] | undefined | null | Variable<any, string>,
	sizes__small__width?: ValueTypes["User_Member_Avatar_sizes__small__width_operator"] | undefined | null | Variable<any, string>,
	sizes__small__height?: ValueTypes["User_Member_Avatar_sizes__small__height_operator"] | undefined | null | Variable<any, string>,
	sizes__small__mimeType?: ValueTypes["User_Member_Avatar_sizes__small__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__small__filesize?: ValueTypes["User_Member_Avatar_sizes__small__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__small__filename?: ValueTypes["User_Member_Avatar_sizes__small__filename_operator"] | undefined | null | Variable<any, string>,
	sizes__medium__url?: ValueTypes["User_Member_Avatar_sizes__medium__url_operator"] | undefined | null | Variable<any, string>,
	sizes__medium__width?: ValueTypes["User_Member_Avatar_sizes__medium__width_operator"] | undefined | null | Variable<any, string>,
	sizes__medium__height?: ValueTypes["User_Member_Avatar_sizes__medium__height_operator"] | undefined | null | Variable<any, string>,
	sizes__medium__mimeType?: ValueTypes["User_Member_Avatar_sizes__medium__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__medium__filesize?: ValueTypes["User_Member_Avatar_sizes__medium__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__medium__filename?: ValueTypes["User_Member_Avatar_sizes__medium__filename_operator"] | undefined | null | Variable<any, string>,
	sizes__large__url?: ValueTypes["User_Member_Avatar_sizes__large__url_operator"] | undefined | null | Variable<any, string>,
	sizes__large__width?: ValueTypes["User_Member_Avatar_sizes__large__width_operator"] | undefined | null | Variable<any, string>,
	sizes__large__height?: ValueTypes["User_Member_Avatar_sizes__large__height_operator"] | undefined | null | Variable<any, string>,
	sizes__large__mimeType?: ValueTypes["User_Member_Avatar_sizes__large__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__large__filesize?: ValueTypes["User_Member_Avatar_sizes__large__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__large__filename?: ValueTypes["User_Member_Avatar_sizes__large__filename_operator"] | undefined | null | Variable<any, string>,
	sizes__small_jpeg__url?: ValueTypes["User_Member_Avatar_sizes__small_jpeg__url_operator"] | undefined | null | Variable<any, string>,
	sizes__small_jpeg__width?: ValueTypes["User_Member_Avatar_sizes__small_jpeg__width_operator"] | undefined | null | Variable<any, string>,
	sizes__small_jpeg__height?: ValueTypes["User_Member_Avatar_sizes__small_jpeg__height_operator"] | undefined | null | Variable<any, string>,
	sizes__small_jpeg__mimeType?: ValueTypes["User_Member_Avatar_sizes__small_jpeg__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__small_jpeg__filesize?: ValueTypes["User_Member_Avatar_sizes__small_jpeg__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__small_jpeg__filename?: ValueTypes["User_Member_Avatar_sizes__small_jpeg__filename_operator"] | undefined | null | Variable<any, string>,
	sizes__medium_jpeg__url?: ValueTypes["User_Member_Avatar_sizes__medium_jpeg__url_operator"] | undefined | null | Variable<any, string>,
	sizes__medium_jpeg__width?: ValueTypes["User_Member_Avatar_sizes__medium_jpeg__width_operator"] | undefined | null | Variable<any, string>,
	sizes__medium_jpeg__height?: ValueTypes["User_Member_Avatar_sizes__medium_jpeg__height_operator"] | undefined | null | Variable<any, string>,
	sizes__medium_jpeg__mimeType?: ValueTypes["User_Member_Avatar_sizes__medium_jpeg__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__medium_jpeg__filesize?: ValueTypes["User_Member_Avatar_sizes__medium_jpeg__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__medium_jpeg__filename?: ValueTypes["User_Member_Avatar_sizes__medium_jpeg__filename_operator"] | undefined | null | Variable<any, string>,
	sizes__large_jpeg__url?: ValueTypes["User_Member_Avatar_sizes__large_jpeg__url_operator"] | undefined | null | Variable<any, string>,
	sizes__large_jpeg__width?: ValueTypes["User_Member_Avatar_sizes__large_jpeg__width_operator"] | undefined | null | Variable<any, string>,
	sizes__large_jpeg__height?: ValueTypes["User_Member_Avatar_sizes__large_jpeg__height_operator"] | undefined | null | Variable<any, string>,
	sizes__large_jpeg__mimeType?: ValueTypes["User_Member_Avatar_sizes__large_jpeg__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__large_jpeg__filesize?: ValueTypes["User_Member_Avatar_sizes__large_jpeg__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__large_jpeg__filename?: ValueTypes["User_Member_Avatar_sizes__large_jpeg__filename_operator"] | undefined | null | Variable<any, string>,
	id?: ValueTypes["User_Member_Avatar_id_operator"] | undefined | null | Variable<any, string>,
	AND?: Array<ValueTypes["User_Member_Avatar_where_and"] | undefined | null> | undefined | null | Variable<any, string>,
	OR?: Array<ValueTypes["User_Member_Avatar_where_or"] | undefined | null> | undefined | null | Variable<any, string>
};
	["User_Member_Avatar_alt_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["User_Member_Avatar_updatedAt_operator"]: {
	equals?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	not_equals?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	greater_than_equal?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	greater_than?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	less_than_equal?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	less_than?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	like?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["User_Member_Avatar_createdAt_operator"]: {
	equals?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	not_equals?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	greater_than_equal?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	greater_than?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	less_than_equal?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	less_than?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	like?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["User_Member_Avatar_url_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["User_Member_Avatar_filename_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["User_Member_Avatar_mimeType_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["User_Member_Avatar_filesize_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["User_Member_Avatar_width_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["User_Member_Avatar_height_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["User_Member_Avatar_focalX_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["User_Member_Avatar_focalY_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["User_Member_Avatar_sizes__small__url_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["User_Member_Avatar_sizes__small__width_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["User_Member_Avatar_sizes__small__height_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["User_Member_Avatar_sizes__small__mimeType_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["User_Member_Avatar_sizes__small__filesize_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["User_Member_Avatar_sizes__small__filename_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["User_Member_Avatar_sizes__medium__url_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["User_Member_Avatar_sizes__medium__width_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["User_Member_Avatar_sizes__medium__height_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["User_Member_Avatar_sizes__medium__mimeType_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["User_Member_Avatar_sizes__medium__filesize_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["User_Member_Avatar_sizes__medium__filename_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["User_Member_Avatar_sizes__large__url_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["User_Member_Avatar_sizes__large__width_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["User_Member_Avatar_sizes__large__height_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["User_Member_Avatar_sizes__large__mimeType_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["User_Member_Avatar_sizes__large__filesize_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["User_Member_Avatar_sizes__large__filename_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["User_Member_Avatar_sizes__small_jpeg__url_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["User_Member_Avatar_sizes__small_jpeg__width_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["User_Member_Avatar_sizes__small_jpeg__height_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["User_Member_Avatar_sizes__small_jpeg__mimeType_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["User_Member_Avatar_sizes__small_jpeg__filesize_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["User_Member_Avatar_sizes__small_jpeg__filename_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["User_Member_Avatar_sizes__medium_jpeg__url_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["User_Member_Avatar_sizes__medium_jpeg__width_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["User_Member_Avatar_sizes__medium_jpeg__height_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["User_Member_Avatar_sizes__medium_jpeg__mimeType_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["User_Member_Avatar_sizes__medium_jpeg__filesize_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["User_Member_Avatar_sizes__medium_jpeg__filename_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["User_Member_Avatar_sizes__large_jpeg__url_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["User_Member_Avatar_sizes__large_jpeg__width_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["User_Member_Avatar_sizes__large_jpeg__height_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["User_Member_Avatar_sizes__large_jpeg__mimeType_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["User_Member_Avatar_sizes__large_jpeg__filesize_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["User_Member_Avatar_sizes__large_jpeg__filename_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["User_Member_Avatar_id_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["User_Member_Avatar_where_and"]: {
	alt?: ValueTypes["User_Member_Avatar_alt_operator"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["User_Member_Avatar_updatedAt_operator"] | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["User_Member_Avatar_createdAt_operator"] | undefined | null | Variable<any, string>,
	url?: ValueTypes["User_Member_Avatar_url_operator"] | undefined | null | Variable<any, string>,
	filename?: ValueTypes["User_Member_Avatar_filename_operator"] | undefined | null | Variable<any, string>,
	mimeType?: ValueTypes["User_Member_Avatar_mimeType_operator"] | undefined | null | Variable<any, string>,
	filesize?: ValueTypes["User_Member_Avatar_filesize_operator"] | undefined | null | Variable<any, string>,
	width?: ValueTypes["User_Member_Avatar_width_operator"] | undefined | null | Variable<any, string>,
	height?: ValueTypes["User_Member_Avatar_height_operator"] | undefined | null | Variable<any, string>,
	focalX?: ValueTypes["User_Member_Avatar_focalX_operator"] | undefined | null | Variable<any, string>,
	focalY?: ValueTypes["User_Member_Avatar_focalY_operator"] | undefined | null | Variable<any, string>,
	sizes__small__url?: ValueTypes["User_Member_Avatar_sizes__small__url_operator"] | undefined | null | Variable<any, string>,
	sizes__small__width?: ValueTypes["User_Member_Avatar_sizes__small__width_operator"] | undefined | null | Variable<any, string>,
	sizes__small__height?: ValueTypes["User_Member_Avatar_sizes__small__height_operator"] | undefined | null | Variable<any, string>,
	sizes__small__mimeType?: ValueTypes["User_Member_Avatar_sizes__small__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__small__filesize?: ValueTypes["User_Member_Avatar_sizes__small__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__small__filename?: ValueTypes["User_Member_Avatar_sizes__small__filename_operator"] | undefined | null | Variable<any, string>,
	sizes__medium__url?: ValueTypes["User_Member_Avatar_sizes__medium__url_operator"] | undefined | null | Variable<any, string>,
	sizes__medium__width?: ValueTypes["User_Member_Avatar_sizes__medium__width_operator"] | undefined | null | Variable<any, string>,
	sizes__medium__height?: ValueTypes["User_Member_Avatar_sizes__medium__height_operator"] | undefined | null | Variable<any, string>,
	sizes__medium__mimeType?: ValueTypes["User_Member_Avatar_sizes__medium__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__medium__filesize?: ValueTypes["User_Member_Avatar_sizes__medium__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__medium__filename?: ValueTypes["User_Member_Avatar_sizes__medium__filename_operator"] | undefined | null | Variable<any, string>,
	sizes__large__url?: ValueTypes["User_Member_Avatar_sizes__large__url_operator"] | undefined | null | Variable<any, string>,
	sizes__large__width?: ValueTypes["User_Member_Avatar_sizes__large__width_operator"] | undefined | null | Variable<any, string>,
	sizes__large__height?: ValueTypes["User_Member_Avatar_sizes__large__height_operator"] | undefined | null | Variable<any, string>,
	sizes__large__mimeType?: ValueTypes["User_Member_Avatar_sizes__large__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__large__filesize?: ValueTypes["User_Member_Avatar_sizes__large__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__large__filename?: ValueTypes["User_Member_Avatar_sizes__large__filename_operator"] | undefined | null | Variable<any, string>,
	sizes__small_jpeg__url?: ValueTypes["User_Member_Avatar_sizes__small_jpeg__url_operator"] | undefined | null | Variable<any, string>,
	sizes__small_jpeg__width?: ValueTypes["User_Member_Avatar_sizes__small_jpeg__width_operator"] | undefined | null | Variable<any, string>,
	sizes__small_jpeg__height?: ValueTypes["User_Member_Avatar_sizes__small_jpeg__height_operator"] | undefined | null | Variable<any, string>,
	sizes__small_jpeg__mimeType?: ValueTypes["User_Member_Avatar_sizes__small_jpeg__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__small_jpeg__filesize?: ValueTypes["User_Member_Avatar_sizes__small_jpeg__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__small_jpeg__filename?: ValueTypes["User_Member_Avatar_sizes__small_jpeg__filename_operator"] | undefined | null | Variable<any, string>,
	sizes__medium_jpeg__url?: ValueTypes["User_Member_Avatar_sizes__medium_jpeg__url_operator"] | undefined | null | Variable<any, string>,
	sizes__medium_jpeg__width?: ValueTypes["User_Member_Avatar_sizes__medium_jpeg__width_operator"] | undefined | null | Variable<any, string>,
	sizes__medium_jpeg__height?: ValueTypes["User_Member_Avatar_sizes__medium_jpeg__height_operator"] | undefined | null | Variable<any, string>,
	sizes__medium_jpeg__mimeType?: ValueTypes["User_Member_Avatar_sizes__medium_jpeg__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__medium_jpeg__filesize?: ValueTypes["User_Member_Avatar_sizes__medium_jpeg__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__medium_jpeg__filename?: ValueTypes["User_Member_Avatar_sizes__medium_jpeg__filename_operator"] | undefined | null | Variable<any, string>,
	sizes__large_jpeg__url?: ValueTypes["User_Member_Avatar_sizes__large_jpeg__url_operator"] | undefined | null | Variable<any, string>,
	sizes__large_jpeg__width?: ValueTypes["User_Member_Avatar_sizes__large_jpeg__width_operator"] | undefined | null | Variable<any, string>,
	sizes__large_jpeg__height?: ValueTypes["User_Member_Avatar_sizes__large_jpeg__height_operator"] | undefined | null | Variable<any, string>,
	sizes__large_jpeg__mimeType?: ValueTypes["User_Member_Avatar_sizes__large_jpeg__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__large_jpeg__filesize?: ValueTypes["User_Member_Avatar_sizes__large_jpeg__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__large_jpeg__filename?: ValueTypes["User_Member_Avatar_sizes__large_jpeg__filename_operator"] | undefined | null | Variable<any, string>,
	id?: ValueTypes["User_Member_Avatar_id_operator"] | undefined | null | Variable<any, string>,
	AND?: Array<ValueTypes["User_Member_Avatar_where_and"] | undefined | null> | undefined | null | Variable<any, string>,
	OR?: Array<ValueTypes["User_Member_Avatar_where_or"] | undefined | null> | undefined | null | Variable<any, string>
};
	["User_Member_Avatar_where_or"]: {
	alt?: ValueTypes["User_Member_Avatar_alt_operator"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["User_Member_Avatar_updatedAt_operator"] | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["User_Member_Avatar_createdAt_operator"] | undefined | null | Variable<any, string>,
	url?: ValueTypes["User_Member_Avatar_url_operator"] | undefined | null | Variable<any, string>,
	filename?: ValueTypes["User_Member_Avatar_filename_operator"] | undefined | null | Variable<any, string>,
	mimeType?: ValueTypes["User_Member_Avatar_mimeType_operator"] | undefined | null | Variable<any, string>,
	filesize?: ValueTypes["User_Member_Avatar_filesize_operator"] | undefined | null | Variable<any, string>,
	width?: ValueTypes["User_Member_Avatar_width_operator"] | undefined | null | Variable<any, string>,
	height?: ValueTypes["User_Member_Avatar_height_operator"] | undefined | null | Variable<any, string>,
	focalX?: ValueTypes["User_Member_Avatar_focalX_operator"] | undefined | null | Variable<any, string>,
	focalY?: ValueTypes["User_Member_Avatar_focalY_operator"] | undefined | null | Variable<any, string>,
	sizes__small__url?: ValueTypes["User_Member_Avatar_sizes__small__url_operator"] | undefined | null | Variable<any, string>,
	sizes__small__width?: ValueTypes["User_Member_Avatar_sizes__small__width_operator"] | undefined | null | Variable<any, string>,
	sizes__small__height?: ValueTypes["User_Member_Avatar_sizes__small__height_operator"] | undefined | null | Variable<any, string>,
	sizes__small__mimeType?: ValueTypes["User_Member_Avatar_sizes__small__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__small__filesize?: ValueTypes["User_Member_Avatar_sizes__small__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__small__filename?: ValueTypes["User_Member_Avatar_sizes__small__filename_operator"] | undefined | null | Variable<any, string>,
	sizes__medium__url?: ValueTypes["User_Member_Avatar_sizes__medium__url_operator"] | undefined | null | Variable<any, string>,
	sizes__medium__width?: ValueTypes["User_Member_Avatar_sizes__medium__width_operator"] | undefined | null | Variable<any, string>,
	sizes__medium__height?: ValueTypes["User_Member_Avatar_sizes__medium__height_operator"] | undefined | null | Variable<any, string>,
	sizes__medium__mimeType?: ValueTypes["User_Member_Avatar_sizes__medium__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__medium__filesize?: ValueTypes["User_Member_Avatar_sizes__medium__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__medium__filename?: ValueTypes["User_Member_Avatar_sizes__medium__filename_operator"] | undefined | null | Variable<any, string>,
	sizes__large__url?: ValueTypes["User_Member_Avatar_sizes__large__url_operator"] | undefined | null | Variable<any, string>,
	sizes__large__width?: ValueTypes["User_Member_Avatar_sizes__large__width_operator"] | undefined | null | Variable<any, string>,
	sizes__large__height?: ValueTypes["User_Member_Avatar_sizes__large__height_operator"] | undefined | null | Variable<any, string>,
	sizes__large__mimeType?: ValueTypes["User_Member_Avatar_sizes__large__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__large__filesize?: ValueTypes["User_Member_Avatar_sizes__large__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__large__filename?: ValueTypes["User_Member_Avatar_sizes__large__filename_operator"] | undefined | null | Variable<any, string>,
	sizes__small_jpeg__url?: ValueTypes["User_Member_Avatar_sizes__small_jpeg__url_operator"] | undefined | null | Variable<any, string>,
	sizes__small_jpeg__width?: ValueTypes["User_Member_Avatar_sizes__small_jpeg__width_operator"] | undefined | null | Variable<any, string>,
	sizes__small_jpeg__height?: ValueTypes["User_Member_Avatar_sizes__small_jpeg__height_operator"] | undefined | null | Variable<any, string>,
	sizes__small_jpeg__mimeType?: ValueTypes["User_Member_Avatar_sizes__small_jpeg__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__small_jpeg__filesize?: ValueTypes["User_Member_Avatar_sizes__small_jpeg__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__small_jpeg__filename?: ValueTypes["User_Member_Avatar_sizes__small_jpeg__filename_operator"] | undefined | null | Variable<any, string>,
	sizes__medium_jpeg__url?: ValueTypes["User_Member_Avatar_sizes__medium_jpeg__url_operator"] | undefined | null | Variable<any, string>,
	sizes__medium_jpeg__width?: ValueTypes["User_Member_Avatar_sizes__medium_jpeg__width_operator"] | undefined | null | Variable<any, string>,
	sizes__medium_jpeg__height?: ValueTypes["User_Member_Avatar_sizes__medium_jpeg__height_operator"] | undefined | null | Variable<any, string>,
	sizes__medium_jpeg__mimeType?: ValueTypes["User_Member_Avatar_sizes__medium_jpeg__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__medium_jpeg__filesize?: ValueTypes["User_Member_Avatar_sizes__medium_jpeg__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__medium_jpeg__filename?: ValueTypes["User_Member_Avatar_sizes__medium_jpeg__filename_operator"] | undefined | null | Variable<any, string>,
	sizes__large_jpeg__url?: ValueTypes["User_Member_Avatar_sizes__large_jpeg__url_operator"] | undefined | null | Variable<any, string>,
	sizes__large_jpeg__width?: ValueTypes["User_Member_Avatar_sizes__large_jpeg__width_operator"] | undefined | null | Variable<any, string>,
	sizes__large_jpeg__height?: ValueTypes["User_Member_Avatar_sizes__large_jpeg__height_operator"] | undefined | null | Variable<any, string>,
	sizes__large_jpeg__mimeType?: ValueTypes["User_Member_Avatar_sizes__large_jpeg__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__large_jpeg__filesize?: ValueTypes["User_Member_Avatar_sizes__large_jpeg__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__large_jpeg__filename?: ValueTypes["User_Member_Avatar_sizes__large_jpeg__filename_operator"] | undefined | null | Variable<any, string>,
	id?: ValueTypes["User_Member_Avatar_id_operator"] | undefined | null | Variable<any, string>,
	AND?: Array<ValueTypes["User_Member_Avatar_where_and"] | undefined | null> | undefined | null | Variable<any, string>,
	OR?: Array<ValueTypes["User_Member_Avatar_where_or"] | undefined | null> | undefined | null | Variable<any, string>
};
	["User_Member_gender"]:User_Member_gender;
	["EventCategory"]: AliasType<{
	id?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	value?:boolean | `@${string}`,
tags?: [{	locale?: ValueTypes["LocaleInputType"] | undefined | null | Variable<any, string>,	fallbackLocale?: ValueTypes["FallbackLocaleInputType"] | undefined | null | Variable<any, string>},ValueTypes["Tag"]],
icon?: [{	locale?: ValueTypes["LocaleInputType"] | undefined | null | Variable<any, string>,	fallbackLocale?: ValueTypes["FallbackLocaleInputType"] | undefined | null | Variable<any, string>,	where?: ValueTypes["EventCategory_Icon_where"] | undefined | null | Variable<any, string>},ValueTypes["Media"]],
	region?:boolean | `@${string}`,
	updatedAt?:boolean | `@${string}`,
	createdAt?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["Tag"]: AliasType<{
	id?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	value?:boolean | `@${string}`,
	region?:boolean | `@${string}`,
	count?:boolean | `@${string}`,
	skipCountCheck?:boolean | `@${string}`,
	removeNextCron?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["Tag_region"]:Tag_region;
	["EventCategory_Icon_where"]: {
	alt?: ValueTypes["EventCategory_Icon_alt_operator"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["EventCategory_Icon_updatedAt_operator"] | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["EventCategory_Icon_createdAt_operator"] | undefined | null | Variable<any, string>,
	url?: ValueTypes["EventCategory_Icon_url_operator"] | undefined | null | Variable<any, string>,
	filename?: ValueTypes["EventCategory_Icon_filename_operator"] | undefined | null | Variable<any, string>,
	mimeType?: ValueTypes["EventCategory_Icon_mimeType_operator"] | undefined | null | Variable<any, string>,
	filesize?: ValueTypes["EventCategory_Icon_filesize_operator"] | undefined | null | Variable<any, string>,
	width?: ValueTypes["EventCategory_Icon_width_operator"] | undefined | null | Variable<any, string>,
	height?: ValueTypes["EventCategory_Icon_height_operator"] | undefined | null | Variable<any, string>,
	focalX?: ValueTypes["EventCategory_Icon_focalX_operator"] | undefined | null | Variable<any, string>,
	focalY?: ValueTypes["EventCategory_Icon_focalY_operator"] | undefined | null | Variable<any, string>,
	sizes__small__url?: ValueTypes["EventCategory_Icon_sizes__small__url_operator"] | undefined | null | Variable<any, string>,
	sizes__small__width?: ValueTypes["EventCategory_Icon_sizes__small__width_operator"] | undefined | null | Variable<any, string>,
	sizes__small__height?: ValueTypes["EventCategory_Icon_sizes__small__height_operator"] | undefined | null | Variable<any, string>,
	sizes__small__mimeType?: ValueTypes["EventCategory_Icon_sizes__small__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__small__filesize?: ValueTypes["EventCategory_Icon_sizes__small__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__small__filename?: ValueTypes["EventCategory_Icon_sizes__small__filename_operator"] | undefined | null | Variable<any, string>,
	sizes__medium__url?: ValueTypes["EventCategory_Icon_sizes__medium__url_operator"] | undefined | null | Variable<any, string>,
	sizes__medium__width?: ValueTypes["EventCategory_Icon_sizes__medium__width_operator"] | undefined | null | Variable<any, string>,
	sizes__medium__height?: ValueTypes["EventCategory_Icon_sizes__medium__height_operator"] | undefined | null | Variable<any, string>,
	sizes__medium__mimeType?: ValueTypes["EventCategory_Icon_sizes__medium__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__medium__filesize?: ValueTypes["EventCategory_Icon_sizes__medium__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__medium__filename?: ValueTypes["EventCategory_Icon_sizes__medium__filename_operator"] | undefined | null | Variable<any, string>,
	sizes__large__url?: ValueTypes["EventCategory_Icon_sizes__large__url_operator"] | undefined | null | Variable<any, string>,
	sizes__large__width?: ValueTypes["EventCategory_Icon_sizes__large__width_operator"] | undefined | null | Variable<any, string>,
	sizes__large__height?: ValueTypes["EventCategory_Icon_sizes__large__height_operator"] | undefined | null | Variable<any, string>,
	sizes__large__mimeType?: ValueTypes["EventCategory_Icon_sizes__large__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__large__filesize?: ValueTypes["EventCategory_Icon_sizes__large__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__large__filename?: ValueTypes["EventCategory_Icon_sizes__large__filename_operator"] | undefined | null | Variable<any, string>,
	sizes__small_jpeg__url?: ValueTypes["EventCategory_Icon_sizes__small_jpeg__url_operator"] | undefined | null | Variable<any, string>,
	sizes__small_jpeg__width?: ValueTypes["EventCategory_Icon_sizes__small_jpeg__width_operator"] | undefined | null | Variable<any, string>,
	sizes__small_jpeg__height?: ValueTypes["EventCategory_Icon_sizes__small_jpeg__height_operator"] | undefined | null | Variable<any, string>,
	sizes__small_jpeg__mimeType?: ValueTypes["EventCategory_Icon_sizes__small_jpeg__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__small_jpeg__filesize?: ValueTypes["EventCategory_Icon_sizes__small_jpeg__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__small_jpeg__filename?: ValueTypes["EventCategory_Icon_sizes__small_jpeg__filename_operator"] | undefined | null | Variable<any, string>,
	sizes__medium_jpeg__url?: ValueTypes["EventCategory_Icon_sizes__medium_jpeg__url_operator"] | undefined | null | Variable<any, string>,
	sizes__medium_jpeg__width?: ValueTypes["EventCategory_Icon_sizes__medium_jpeg__width_operator"] | undefined | null | Variable<any, string>,
	sizes__medium_jpeg__height?: ValueTypes["EventCategory_Icon_sizes__medium_jpeg__height_operator"] | undefined | null | Variable<any, string>,
	sizes__medium_jpeg__mimeType?: ValueTypes["EventCategory_Icon_sizes__medium_jpeg__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__medium_jpeg__filesize?: ValueTypes["EventCategory_Icon_sizes__medium_jpeg__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__medium_jpeg__filename?: ValueTypes["EventCategory_Icon_sizes__medium_jpeg__filename_operator"] | undefined | null | Variable<any, string>,
	sizes__large_jpeg__url?: ValueTypes["EventCategory_Icon_sizes__large_jpeg__url_operator"] | undefined | null | Variable<any, string>,
	sizes__large_jpeg__width?: ValueTypes["EventCategory_Icon_sizes__large_jpeg__width_operator"] | undefined | null | Variable<any, string>,
	sizes__large_jpeg__height?: ValueTypes["EventCategory_Icon_sizes__large_jpeg__height_operator"] | undefined | null | Variable<any, string>,
	sizes__large_jpeg__mimeType?: ValueTypes["EventCategory_Icon_sizes__large_jpeg__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__large_jpeg__filesize?: ValueTypes["EventCategory_Icon_sizes__large_jpeg__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__large_jpeg__filename?: ValueTypes["EventCategory_Icon_sizes__large_jpeg__filename_operator"] | undefined | null | Variable<any, string>,
	id?: ValueTypes["EventCategory_Icon_id_operator"] | undefined | null | Variable<any, string>,
	AND?: Array<ValueTypes["EventCategory_Icon_where_and"] | undefined | null> | undefined | null | Variable<any, string>,
	OR?: Array<ValueTypes["EventCategory_Icon_where_or"] | undefined | null> | undefined | null | Variable<any, string>
};
	["EventCategory_Icon_alt_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["EventCategory_Icon_updatedAt_operator"]: {
	equals?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	not_equals?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	greater_than_equal?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	greater_than?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	less_than_equal?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	less_than?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	like?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["EventCategory_Icon_createdAt_operator"]: {
	equals?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	not_equals?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	greater_than_equal?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	greater_than?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	less_than_equal?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	less_than?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	like?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["EventCategory_Icon_url_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["EventCategory_Icon_filename_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["EventCategory_Icon_mimeType_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["EventCategory_Icon_filesize_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["EventCategory_Icon_width_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["EventCategory_Icon_height_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["EventCategory_Icon_focalX_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["EventCategory_Icon_focalY_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["EventCategory_Icon_sizes__small__url_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["EventCategory_Icon_sizes__small__width_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["EventCategory_Icon_sizes__small__height_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["EventCategory_Icon_sizes__small__mimeType_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["EventCategory_Icon_sizes__small__filesize_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["EventCategory_Icon_sizes__small__filename_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["EventCategory_Icon_sizes__medium__url_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["EventCategory_Icon_sizes__medium__width_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["EventCategory_Icon_sizes__medium__height_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["EventCategory_Icon_sizes__medium__mimeType_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["EventCategory_Icon_sizes__medium__filesize_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["EventCategory_Icon_sizes__medium__filename_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["EventCategory_Icon_sizes__large__url_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["EventCategory_Icon_sizes__large__width_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["EventCategory_Icon_sizes__large__height_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["EventCategory_Icon_sizes__large__mimeType_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["EventCategory_Icon_sizes__large__filesize_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["EventCategory_Icon_sizes__large__filename_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["EventCategory_Icon_sizes__small_jpeg__url_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["EventCategory_Icon_sizes__small_jpeg__width_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["EventCategory_Icon_sizes__small_jpeg__height_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["EventCategory_Icon_sizes__small_jpeg__mimeType_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["EventCategory_Icon_sizes__small_jpeg__filesize_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["EventCategory_Icon_sizes__small_jpeg__filename_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["EventCategory_Icon_sizes__medium_jpeg__url_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["EventCategory_Icon_sizes__medium_jpeg__width_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["EventCategory_Icon_sizes__medium_jpeg__height_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["EventCategory_Icon_sizes__medium_jpeg__mimeType_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["EventCategory_Icon_sizes__medium_jpeg__filesize_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["EventCategory_Icon_sizes__medium_jpeg__filename_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["EventCategory_Icon_sizes__large_jpeg__url_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["EventCategory_Icon_sizes__large_jpeg__width_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["EventCategory_Icon_sizes__large_jpeg__height_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["EventCategory_Icon_sizes__large_jpeg__mimeType_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["EventCategory_Icon_sizes__large_jpeg__filesize_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["EventCategory_Icon_sizes__large_jpeg__filename_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["EventCategory_Icon_id_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["EventCategory_Icon_where_and"]: {
	alt?: ValueTypes["EventCategory_Icon_alt_operator"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["EventCategory_Icon_updatedAt_operator"] | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["EventCategory_Icon_createdAt_operator"] | undefined | null | Variable<any, string>,
	url?: ValueTypes["EventCategory_Icon_url_operator"] | undefined | null | Variable<any, string>,
	filename?: ValueTypes["EventCategory_Icon_filename_operator"] | undefined | null | Variable<any, string>,
	mimeType?: ValueTypes["EventCategory_Icon_mimeType_operator"] | undefined | null | Variable<any, string>,
	filesize?: ValueTypes["EventCategory_Icon_filesize_operator"] | undefined | null | Variable<any, string>,
	width?: ValueTypes["EventCategory_Icon_width_operator"] | undefined | null | Variable<any, string>,
	height?: ValueTypes["EventCategory_Icon_height_operator"] | undefined | null | Variable<any, string>,
	focalX?: ValueTypes["EventCategory_Icon_focalX_operator"] | undefined | null | Variable<any, string>,
	focalY?: ValueTypes["EventCategory_Icon_focalY_operator"] | undefined | null | Variable<any, string>,
	sizes__small__url?: ValueTypes["EventCategory_Icon_sizes__small__url_operator"] | undefined | null | Variable<any, string>,
	sizes__small__width?: ValueTypes["EventCategory_Icon_sizes__small__width_operator"] | undefined | null | Variable<any, string>,
	sizes__small__height?: ValueTypes["EventCategory_Icon_sizes__small__height_operator"] | undefined | null | Variable<any, string>,
	sizes__small__mimeType?: ValueTypes["EventCategory_Icon_sizes__small__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__small__filesize?: ValueTypes["EventCategory_Icon_sizes__small__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__small__filename?: ValueTypes["EventCategory_Icon_sizes__small__filename_operator"] | undefined | null | Variable<any, string>,
	sizes__medium__url?: ValueTypes["EventCategory_Icon_sizes__medium__url_operator"] | undefined | null | Variable<any, string>,
	sizes__medium__width?: ValueTypes["EventCategory_Icon_sizes__medium__width_operator"] | undefined | null | Variable<any, string>,
	sizes__medium__height?: ValueTypes["EventCategory_Icon_sizes__medium__height_operator"] | undefined | null | Variable<any, string>,
	sizes__medium__mimeType?: ValueTypes["EventCategory_Icon_sizes__medium__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__medium__filesize?: ValueTypes["EventCategory_Icon_sizes__medium__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__medium__filename?: ValueTypes["EventCategory_Icon_sizes__medium__filename_operator"] | undefined | null | Variable<any, string>,
	sizes__large__url?: ValueTypes["EventCategory_Icon_sizes__large__url_operator"] | undefined | null | Variable<any, string>,
	sizes__large__width?: ValueTypes["EventCategory_Icon_sizes__large__width_operator"] | undefined | null | Variable<any, string>,
	sizes__large__height?: ValueTypes["EventCategory_Icon_sizes__large__height_operator"] | undefined | null | Variable<any, string>,
	sizes__large__mimeType?: ValueTypes["EventCategory_Icon_sizes__large__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__large__filesize?: ValueTypes["EventCategory_Icon_sizes__large__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__large__filename?: ValueTypes["EventCategory_Icon_sizes__large__filename_operator"] | undefined | null | Variable<any, string>,
	sizes__small_jpeg__url?: ValueTypes["EventCategory_Icon_sizes__small_jpeg__url_operator"] | undefined | null | Variable<any, string>,
	sizes__small_jpeg__width?: ValueTypes["EventCategory_Icon_sizes__small_jpeg__width_operator"] | undefined | null | Variable<any, string>,
	sizes__small_jpeg__height?: ValueTypes["EventCategory_Icon_sizes__small_jpeg__height_operator"] | undefined | null | Variable<any, string>,
	sizes__small_jpeg__mimeType?: ValueTypes["EventCategory_Icon_sizes__small_jpeg__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__small_jpeg__filesize?: ValueTypes["EventCategory_Icon_sizes__small_jpeg__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__small_jpeg__filename?: ValueTypes["EventCategory_Icon_sizes__small_jpeg__filename_operator"] | undefined | null | Variable<any, string>,
	sizes__medium_jpeg__url?: ValueTypes["EventCategory_Icon_sizes__medium_jpeg__url_operator"] | undefined | null | Variable<any, string>,
	sizes__medium_jpeg__width?: ValueTypes["EventCategory_Icon_sizes__medium_jpeg__width_operator"] | undefined | null | Variable<any, string>,
	sizes__medium_jpeg__height?: ValueTypes["EventCategory_Icon_sizes__medium_jpeg__height_operator"] | undefined | null | Variable<any, string>,
	sizes__medium_jpeg__mimeType?: ValueTypes["EventCategory_Icon_sizes__medium_jpeg__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__medium_jpeg__filesize?: ValueTypes["EventCategory_Icon_sizes__medium_jpeg__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__medium_jpeg__filename?: ValueTypes["EventCategory_Icon_sizes__medium_jpeg__filename_operator"] | undefined | null | Variable<any, string>,
	sizes__large_jpeg__url?: ValueTypes["EventCategory_Icon_sizes__large_jpeg__url_operator"] | undefined | null | Variable<any, string>,
	sizes__large_jpeg__width?: ValueTypes["EventCategory_Icon_sizes__large_jpeg__width_operator"] | undefined | null | Variable<any, string>,
	sizes__large_jpeg__height?: ValueTypes["EventCategory_Icon_sizes__large_jpeg__height_operator"] | undefined | null | Variable<any, string>,
	sizes__large_jpeg__mimeType?: ValueTypes["EventCategory_Icon_sizes__large_jpeg__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__large_jpeg__filesize?: ValueTypes["EventCategory_Icon_sizes__large_jpeg__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__large_jpeg__filename?: ValueTypes["EventCategory_Icon_sizes__large_jpeg__filename_operator"] | undefined | null | Variable<any, string>,
	id?: ValueTypes["EventCategory_Icon_id_operator"] | undefined | null | Variable<any, string>,
	AND?: Array<ValueTypes["EventCategory_Icon_where_and"] | undefined | null> | undefined | null | Variable<any, string>,
	OR?: Array<ValueTypes["EventCategory_Icon_where_or"] | undefined | null> | undefined | null | Variable<any, string>
};
	["EventCategory_Icon_where_or"]: {
	alt?: ValueTypes["EventCategory_Icon_alt_operator"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["EventCategory_Icon_updatedAt_operator"] | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["EventCategory_Icon_createdAt_operator"] | undefined | null | Variable<any, string>,
	url?: ValueTypes["EventCategory_Icon_url_operator"] | undefined | null | Variable<any, string>,
	filename?: ValueTypes["EventCategory_Icon_filename_operator"] | undefined | null | Variable<any, string>,
	mimeType?: ValueTypes["EventCategory_Icon_mimeType_operator"] | undefined | null | Variable<any, string>,
	filesize?: ValueTypes["EventCategory_Icon_filesize_operator"] | undefined | null | Variable<any, string>,
	width?: ValueTypes["EventCategory_Icon_width_operator"] | undefined | null | Variable<any, string>,
	height?: ValueTypes["EventCategory_Icon_height_operator"] | undefined | null | Variable<any, string>,
	focalX?: ValueTypes["EventCategory_Icon_focalX_operator"] | undefined | null | Variable<any, string>,
	focalY?: ValueTypes["EventCategory_Icon_focalY_operator"] | undefined | null | Variable<any, string>,
	sizes__small__url?: ValueTypes["EventCategory_Icon_sizes__small__url_operator"] | undefined | null | Variable<any, string>,
	sizes__small__width?: ValueTypes["EventCategory_Icon_sizes__small__width_operator"] | undefined | null | Variable<any, string>,
	sizes__small__height?: ValueTypes["EventCategory_Icon_sizes__small__height_operator"] | undefined | null | Variable<any, string>,
	sizes__small__mimeType?: ValueTypes["EventCategory_Icon_sizes__small__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__small__filesize?: ValueTypes["EventCategory_Icon_sizes__small__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__small__filename?: ValueTypes["EventCategory_Icon_sizes__small__filename_operator"] | undefined | null | Variable<any, string>,
	sizes__medium__url?: ValueTypes["EventCategory_Icon_sizes__medium__url_operator"] | undefined | null | Variable<any, string>,
	sizes__medium__width?: ValueTypes["EventCategory_Icon_sizes__medium__width_operator"] | undefined | null | Variable<any, string>,
	sizes__medium__height?: ValueTypes["EventCategory_Icon_sizes__medium__height_operator"] | undefined | null | Variable<any, string>,
	sizes__medium__mimeType?: ValueTypes["EventCategory_Icon_sizes__medium__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__medium__filesize?: ValueTypes["EventCategory_Icon_sizes__medium__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__medium__filename?: ValueTypes["EventCategory_Icon_sizes__medium__filename_operator"] | undefined | null | Variable<any, string>,
	sizes__large__url?: ValueTypes["EventCategory_Icon_sizes__large__url_operator"] | undefined | null | Variable<any, string>,
	sizes__large__width?: ValueTypes["EventCategory_Icon_sizes__large__width_operator"] | undefined | null | Variable<any, string>,
	sizes__large__height?: ValueTypes["EventCategory_Icon_sizes__large__height_operator"] | undefined | null | Variable<any, string>,
	sizes__large__mimeType?: ValueTypes["EventCategory_Icon_sizes__large__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__large__filesize?: ValueTypes["EventCategory_Icon_sizes__large__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__large__filename?: ValueTypes["EventCategory_Icon_sizes__large__filename_operator"] | undefined | null | Variable<any, string>,
	sizes__small_jpeg__url?: ValueTypes["EventCategory_Icon_sizes__small_jpeg__url_operator"] | undefined | null | Variable<any, string>,
	sizes__small_jpeg__width?: ValueTypes["EventCategory_Icon_sizes__small_jpeg__width_operator"] | undefined | null | Variable<any, string>,
	sizes__small_jpeg__height?: ValueTypes["EventCategory_Icon_sizes__small_jpeg__height_operator"] | undefined | null | Variable<any, string>,
	sizes__small_jpeg__mimeType?: ValueTypes["EventCategory_Icon_sizes__small_jpeg__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__small_jpeg__filesize?: ValueTypes["EventCategory_Icon_sizes__small_jpeg__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__small_jpeg__filename?: ValueTypes["EventCategory_Icon_sizes__small_jpeg__filename_operator"] | undefined | null | Variable<any, string>,
	sizes__medium_jpeg__url?: ValueTypes["EventCategory_Icon_sizes__medium_jpeg__url_operator"] | undefined | null | Variable<any, string>,
	sizes__medium_jpeg__width?: ValueTypes["EventCategory_Icon_sizes__medium_jpeg__width_operator"] | undefined | null | Variable<any, string>,
	sizes__medium_jpeg__height?: ValueTypes["EventCategory_Icon_sizes__medium_jpeg__height_operator"] | undefined | null | Variable<any, string>,
	sizes__medium_jpeg__mimeType?: ValueTypes["EventCategory_Icon_sizes__medium_jpeg__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__medium_jpeg__filesize?: ValueTypes["EventCategory_Icon_sizes__medium_jpeg__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__medium_jpeg__filename?: ValueTypes["EventCategory_Icon_sizes__medium_jpeg__filename_operator"] | undefined | null | Variable<any, string>,
	sizes__large_jpeg__url?: ValueTypes["EventCategory_Icon_sizes__large_jpeg__url_operator"] | undefined | null | Variable<any, string>,
	sizes__large_jpeg__width?: ValueTypes["EventCategory_Icon_sizes__large_jpeg__width_operator"] | undefined | null | Variable<any, string>,
	sizes__large_jpeg__height?: ValueTypes["EventCategory_Icon_sizes__large_jpeg__height_operator"] | undefined | null | Variable<any, string>,
	sizes__large_jpeg__mimeType?: ValueTypes["EventCategory_Icon_sizes__large_jpeg__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__large_jpeg__filesize?: ValueTypes["EventCategory_Icon_sizes__large_jpeg__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__large_jpeg__filename?: ValueTypes["EventCategory_Icon_sizes__large_jpeg__filename_operator"] | undefined | null | Variable<any, string>,
	id?: ValueTypes["EventCategory_Icon_id_operator"] | undefined | null | Variable<any, string>,
	AND?: Array<ValueTypes["EventCategory_Icon_where_and"] | undefined | null> | undefined | null | Variable<any, string>,
	OR?: Array<ValueTypes["EventCategory_Icon_where_or"] | undefined | null> | undefined | null | Variable<any, string>
};
	["EventCategory_region"]:EventCategory_region;
	["User_Organiser"]: AliasType<{
logo?: [{	locale?: ValueTypes["LocaleInputType"] | undefined | null | Variable<any, string>,	fallbackLocale?: ValueTypes["FallbackLocaleInputType"] | undefined | null | Variable<any, string>,	where?: ValueTypes["User_Organiser_Logo_where"] | undefined | null | Variable<any, string>},ValueTypes["Media"]],
banner?: [{	locale?: ValueTypes["LocaleInputType"] | undefined | null | Variable<any, string>,	fallbackLocale?: ValueTypes["FallbackLocaleInputType"] | undefined | null | Variable<any, string>,	where?: ValueTypes["User_Organiser_Banner_where"] | undefined | null | Variable<any, string>},ValueTypes["Media"]],
	description?:boolean | `@${string}`,
	foundedIn?:boolean | `@${string}`,
	address?:boolean | `@${string}`,
	website?:boolean | `@${string}`,
	facebook?:boolean | `@${string}`,
	instagram?:boolean | `@${string}`,
	language?:boolean | `@${string}`,
	phone?:boolean | `@${string}`,
	followers?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["User_Organiser_Logo_where"]: {
	alt?: ValueTypes["User_Organiser_Logo_alt_operator"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["User_Organiser_Logo_updatedAt_operator"] | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["User_Organiser_Logo_createdAt_operator"] | undefined | null | Variable<any, string>,
	url?: ValueTypes["User_Organiser_Logo_url_operator"] | undefined | null | Variable<any, string>,
	filename?: ValueTypes["User_Organiser_Logo_filename_operator"] | undefined | null | Variable<any, string>,
	mimeType?: ValueTypes["User_Organiser_Logo_mimeType_operator"] | undefined | null | Variable<any, string>,
	filesize?: ValueTypes["User_Organiser_Logo_filesize_operator"] | undefined | null | Variable<any, string>,
	width?: ValueTypes["User_Organiser_Logo_width_operator"] | undefined | null | Variable<any, string>,
	height?: ValueTypes["User_Organiser_Logo_height_operator"] | undefined | null | Variable<any, string>,
	focalX?: ValueTypes["User_Organiser_Logo_focalX_operator"] | undefined | null | Variable<any, string>,
	focalY?: ValueTypes["User_Organiser_Logo_focalY_operator"] | undefined | null | Variable<any, string>,
	sizes__small__url?: ValueTypes["User_Organiser_Logo_sizes__small__url_operator"] | undefined | null | Variable<any, string>,
	sizes__small__width?: ValueTypes["User_Organiser_Logo_sizes__small__width_operator"] | undefined | null | Variable<any, string>,
	sizes__small__height?: ValueTypes["User_Organiser_Logo_sizes__small__height_operator"] | undefined | null | Variable<any, string>,
	sizes__small__mimeType?: ValueTypes["User_Organiser_Logo_sizes__small__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__small__filesize?: ValueTypes["User_Organiser_Logo_sizes__small__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__small__filename?: ValueTypes["User_Organiser_Logo_sizes__small__filename_operator"] | undefined | null | Variable<any, string>,
	sizes__medium__url?: ValueTypes["User_Organiser_Logo_sizes__medium__url_operator"] | undefined | null | Variable<any, string>,
	sizes__medium__width?: ValueTypes["User_Organiser_Logo_sizes__medium__width_operator"] | undefined | null | Variable<any, string>,
	sizes__medium__height?: ValueTypes["User_Organiser_Logo_sizes__medium__height_operator"] | undefined | null | Variable<any, string>,
	sizes__medium__mimeType?: ValueTypes["User_Organiser_Logo_sizes__medium__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__medium__filesize?: ValueTypes["User_Organiser_Logo_sizes__medium__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__medium__filename?: ValueTypes["User_Organiser_Logo_sizes__medium__filename_operator"] | undefined | null | Variable<any, string>,
	sizes__large__url?: ValueTypes["User_Organiser_Logo_sizes__large__url_operator"] | undefined | null | Variable<any, string>,
	sizes__large__width?: ValueTypes["User_Organiser_Logo_sizes__large__width_operator"] | undefined | null | Variable<any, string>,
	sizes__large__height?: ValueTypes["User_Organiser_Logo_sizes__large__height_operator"] | undefined | null | Variable<any, string>,
	sizes__large__mimeType?: ValueTypes["User_Organiser_Logo_sizes__large__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__large__filesize?: ValueTypes["User_Organiser_Logo_sizes__large__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__large__filename?: ValueTypes["User_Organiser_Logo_sizes__large__filename_operator"] | undefined | null | Variable<any, string>,
	sizes__small_jpeg__url?: ValueTypes["User_Organiser_Logo_sizes__small_jpeg__url_operator"] | undefined | null | Variable<any, string>,
	sizes__small_jpeg__width?: ValueTypes["User_Organiser_Logo_sizes__small_jpeg__width_operator"] | undefined | null | Variable<any, string>,
	sizes__small_jpeg__height?: ValueTypes["User_Organiser_Logo_sizes__small_jpeg__height_operator"] | undefined | null | Variable<any, string>,
	sizes__small_jpeg__mimeType?: ValueTypes["User_Organiser_Logo_sizes__small_jpeg__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__small_jpeg__filesize?: ValueTypes["User_Organiser_Logo_sizes__small_jpeg__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__small_jpeg__filename?: ValueTypes["User_Organiser_Logo_sizes__small_jpeg__filename_operator"] | undefined | null | Variable<any, string>,
	sizes__medium_jpeg__url?: ValueTypes["User_Organiser_Logo_sizes__medium_jpeg__url_operator"] | undefined | null | Variable<any, string>,
	sizes__medium_jpeg__width?: ValueTypes["User_Organiser_Logo_sizes__medium_jpeg__width_operator"] | undefined | null | Variable<any, string>,
	sizes__medium_jpeg__height?: ValueTypes["User_Organiser_Logo_sizes__medium_jpeg__height_operator"] | undefined | null | Variable<any, string>,
	sizes__medium_jpeg__mimeType?: ValueTypes["User_Organiser_Logo_sizes__medium_jpeg__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__medium_jpeg__filesize?: ValueTypes["User_Organiser_Logo_sizes__medium_jpeg__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__medium_jpeg__filename?: ValueTypes["User_Organiser_Logo_sizes__medium_jpeg__filename_operator"] | undefined | null | Variable<any, string>,
	sizes__large_jpeg__url?: ValueTypes["User_Organiser_Logo_sizes__large_jpeg__url_operator"] | undefined | null | Variable<any, string>,
	sizes__large_jpeg__width?: ValueTypes["User_Organiser_Logo_sizes__large_jpeg__width_operator"] | undefined | null | Variable<any, string>,
	sizes__large_jpeg__height?: ValueTypes["User_Organiser_Logo_sizes__large_jpeg__height_operator"] | undefined | null | Variable<any, string>,
	sizes__large_jpeg__mimeType?: ValueTypes["User_Organiser_Logo_sizes__large_jpeg__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__large_jpeg__filesize?: ValueTypes["User_Organiser_Logo_sizes__large_jpeg__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__large_jpeg__filename?: ValueTypes["User_Organiser_Logo_sizes__large_jpeg__filename_operator"] | undefined | null | Variable<any, string>,
	id?: ValueTypes["User_Organiser_Logo_id_operator"] | undefined | null | Variable<any, string>,
	AND?: Array<ValueTypes["User_Organiser_Logo_where_and"] | undefined | null> | undefined | null | Variable<any, string>,
	OR?: Array<ValueTypes["User_Organiser_Logo_where_or"] | undefined | null> | undefined | null | Variable<any, string>
};
	["User_Organiser_Logo_alt_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["User_Organiser_Logo_updatedAt_operator"]: {
	equals?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	not_equals?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	greater_than_equal?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	greater_than?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	less_than_equal?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	less_than?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	like?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["User_Organiser_Logo_createdAt_operator"]: {
	equals?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	not_equals?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	greater_than_equal?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	greater_than?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	less_than_equal?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	less_than?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	like?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["User_Organiser_Logo_url_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["User_Organiser_Logo_filename_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["User_Organiser_Logo_mimeType_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["User_Organiser_Logo_filesize_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["User_Organiser_Logo_width_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["User_Organiser_Logo_height_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["User_Organiser_Logo_focalX_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["User_Organiser_Logo_focalY_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["User_Organiser_Logo_sizes__small__url_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["User_Organiser_Logo_sizes__small__width_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["User_Organiser_Logo_sizes__small__height_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["User_Organiser_Logo_sizes__small__mimeType_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["User_Organiser_Logo_sizes__small__filesize_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["User_Organiser_Logo_sizes__small__filename_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["User_Organiser_Logo_sizes__medium__url_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["User_Organiser_Logo_sizes__medium__width_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["User_Organiser_Logo_sizes__medium__height_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["User_Organiser_Logo_sizes__medium__mimeType_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["User_Organiser_Logo_sizes__medium__filesize_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["User_Organiser_Logo_sizes__medium__filename_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["User_Organiser_Logo_sizes__large__url_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["User_Organiser_Logo_sizes__large__width_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["User_Organiser_Logo_sizes__large__height_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["User_Organiser_Logo_sizes__large__mimeType_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["User_Organiser_Logo_sizes__large__filesize_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["User_Organiser_Logo_sizes__large__filename_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["User_Organiser_Logo_sizes__small_jpeg__url_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["User_Organiser_Logo_sizes__small_jpeg__width_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["User_Organiser_Logo_sizes__small_jpeg__height_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["User_Organiser_Logo_sizes__small_jpeg__mimeType_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["User_Organiser_Logo_sizes__small_jpeg__filesize_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["User_Organiser_Logo_sizes__small_jpeg__filename_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["User_Organiser_Logo_sizes__medium_jpeg__url_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["User_Organiser_Logo_sizes__medium_jpeg__width_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["User_Organiser_Logo_sizes__medium_jpeg__height_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["User_Organiser_Logo_sizes__medium_jpeg__mimeType_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["User_Organiser_Logo_sizes__medium_jpeg__filesize_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["User_Organiser_Logo_sizes__medium_jpeg__filename_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["User_Organiser_Logo_sizes__large_jpeg__url_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["User_Organiser_Logo_sizes__large_jpeg__width_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["User_Organiser_Logo_sizes__large_jpeg__height_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["User_Organiser_Logo_sizes__large_jpeg__mimeType_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["User_Organiser_Logo_sizes__large_jpeg__filesize_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["User_Organiser_Logo_sizes__large_jpeg__filename_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["User_Organiser_Logo_id_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["User_Organiser_Logo_where_and"]: {
	alt?: ValueTypes["User_Organiser_Logo_alt_operator"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["User_Organiser_Logo_updatedAt_operator"] | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["User_Organiser_Logo_createdAt_operator"] | undefined | null | Variable<any, string>,
	url?: ValueTypes["User_Organiser_Logo_url_operator"] | undefined | null | Variable<any, string>,
	filename?: ValueTypes["User_Organiser_Logo_filename_operator"] | undefined | null | Variable<any, string>,
	mimeType?: ValueTypes["User_Organiser_Logo_mimeType_operator"] | undefined | null | Variable<any, string>,
	filesize?: ValueTypes["User_Organiser_Logo_filesize_operator"] | undefined | null | Variable<any, string>,
	width?: ValueTypes["User_Organiser_Logo_width_operator"] | undefined | null | Variable<any, string>,
	height?: ValueTypes["User_Organiser_Logo_height_operator"] | undefined | null | Variable<any, string>,
	focalX?: ValueTypes["User_Organiser_Logo_focalX_operator"] | undefined | null | Variable<any, string>,
	focalY?: ValueTypes["User_Organiser_Logo_focalY_operator"] | undefined | null | Variable<any, string>,
	sizes__small__url?: ValueTypes["User_Organiser_Logo_sizes__small__url_operator"] | undefined | null | Variable<any, string>,
	sizes__small__width?: ValueTypes["User_Organiser_Logo_sizes__small__width_operator"] | undefined | null | Variable<any, string>,
	sizes__small__height?: ValueTypes["User_Organiser_Logo_sizes__small__height_operator"] | undefined | null | Variable<any, string>,
	sizes__small__mimeType?: ValueTypes["User_Organiser_Logo_sizes__small__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__small__filesize?: ValueTypes["User_Organiser_Logo_sizes__small__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__small__filename?: ValueTypes["User_Organiser_Logo_sizes__small__filename_operator"] | undefined | null | Variable<any, string>,
	sizes__medium__url?: ValueTypes["User_Organiser_Logo_sizes__medium__url_operator"] | undefined | null | Variable<any, string>,
	sizes__medium__width?: ValueTypes["User_Organiser_Logo_sizes__medium__width_operator"] | undefined | null | Variable<any, string>,
	sizes__medium__height?: ValueTypes["User_Organiser_Logo_sizes__medium__height_operator"] | undefined | null | Variable<any, string>,
	sizes__medium__mimeType?: ValueTypes["User_Organiser_Logo_sizes__medium__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__medium__filesize?: ValueTypes["User_Organiser_Logo_sizes__medium__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__medium__filename?: ValueTypes["User_Organiser_Logo_sizes__medium__filename_operator"] | undefined | null | Variable<any, string>,
	sizes__large__url?: ValueTypes["User_Organiser_Logo_sizes__large__url_operator"] | undefined | null | Variable<any, string>,
	sizes__large__width?: ValueTypes["User_Organiser_Logo_sizes__large__width_operator"] | undefined | null | Variable<any, string>,
	sizes__large__height?: ValueTypes["User_Organiser_Logo_sizes__large__height_operator"] | undefined | null | Variable<any, string>,
	sizes__large__mimeType?: ValueTypes["User_Organiser_Logo_sizes__large__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__large__filesize?: ValueTypes["User_Organiser_Logo_sizes__large__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__large__filename?: ValueTypes["User_Organiser_Logo_sizes__large__filename_operator"] | undefined | null | Variable<any, string>,
	sizes__small_jpeg__url?: ValueTypes["User_Organiser_Logo_sizes__small_jpeg__url_operator"] | undefined | null | Variable<any, string>,
	sizes__small_jpeg__width?: ValueTypes["User_Organiser_Logo_sizes__small_jpeg__width_operator"] | undefined | null | Variable<any, string>,
	sizes__small_jpeg__height?: ValueTypes["User_Organiser_Logo_sizes__small_jpeg__height_operator"] | undefined | null | Variable<any, string>,
	sizes__small_jpeg__mimeType?: ValueTypes["User_Organiser_Logo_sizes__small_jpeg__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__small_jpeg__filesize?: ValueTypes["User_Organiser_Logo_sizes__small_jpeg__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__small_jpeg__filename?: ValueTypes["User_Organiser_Logo_sizes__small_jpeg__filename_operator"] | undefined | null | Variable<any, string>,
	sizes__medium_jpeg__url?: ValueTypes["User_Organiser_Logo_sizes__medium_jpeg__url_operator"] | undefined | null | Variable<any, string>,
	sizes__medium_jpeg__width?: ValueTypes["User_Organiser_Logo_sizes__medium_jpeg__width_operator"] | undefined | null | Variable<any, string>,
	sizes__medium_jpeg__height?: ValueTypes["User_Organiser_Logo_sizes__medium_jpeg__height_operator"] | undefined | null | Variable<any, string>,
	sizes__medium_jpeg__mimeType?: ValueTypes["User_Organiser_Logo_sizes__medium_jpeg__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__medium_jpeg__filesize?: ValueTypes["User_Organiser_Logo_sizes__medium_jpeg__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__medium_jpeg__filename?: ValueTypes["User_Organiser_Logo_sizes__medium_jpeg__filename_operator"] | undefined | null | Variable<any, string>,
	sizes__large_jpeg__url?: ValueTypes["User_Organiser_Logo_sizes__large_jpeg__url_operator"] | undefined | null | Variable<any, string>,
	sizes__large_jpeg__width?: ValueTypes["User_Organiser_Logo_sizes__large_jpeg__width_operator"] | undefined | null | Variable<any, string>,
	sizes__large_jpeg__height?: ValueTypes["User_Organiser_Logo_sizes__large_jpeg__height_operator"] | undefined | null | Variable<any, string>,
	sizes__large_jpeg__mimeType?: ValueTypes["User_Organiser_Logo_sizes__large_jpeg__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__large_jpeg__filesize?: ValueTypes["User_Organiser_Logo_sizes__large_jpeg__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__large_jpeg__filename?: ValueTypes["User_Organiser_Logo_sizes__large_jpeg__filename_operator"] | undefined | null | Variable<any, string>,
	id?: ValueTypes["User_Organiser_Logo_id_operator"] | undefined | null | Variable<any, string>,
	AND?: Array<ValueTypes["User_Organiser_Logo_where_and"] | undefined | null> | undefined | null | Variable<any, string>,
	OR?: Array<ValueTypes["User_Organiser_Logo_where_or"] | undefined | null> | undefined | null | Variable<any, string>
};
	["User_Organiser_Logo_where_or"]: {
	alt?: ValueTypes["User_Organiser_Logo_alt_operator"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["User_Organiser_Logo_updatedAt_operator"] | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["User_Organiser_Logo_createdAt_operator"] | undefined | null | Variable<any, string>,
	url?: ValueTypes["User_Organiser_Logo_url_operator"] | undefined | null | Variable<any, string>,
	filename?: ValueTypes["User_Organiser_Logo_filename_operator"] | undefined | null | Variable<any, string>,
	mimeType?: ValueTypes["User_Organiser_Logo_mimeType_operator"] | undefined | null | Variable<any, string>,
	filesize?: ValueTypes["User_Organiser_Logo_filesize_operator"] | undefined | null | Variable<any, string>,
	width?: ValueTypes["User_Organiser_Logo_width_operator"] | undefined | null | Variable<any, string>,
	height?: ValueTypes["User_Organiser_Logo_height_operator"] | undefined | null | Variable<any, string>,
	focalX?: ValueTypes["User_Organiser_Logo_focalX_operator"] | undefined | null | Variable<any, string>,
	focalY?: ValueTypes["User_Organiser_Logo_focalY_operator"] | undefined | null | Variable<any, string>,
	sizes__small__url?: ValueTypes["User_Organiser_Logo_sizes__small__url_operator"] | undefined | null | Variable<any, string>,
	sizes__small__width?: ValueTypes["User_Organiser_Logo_sizes__small__width_operator"] | undefined | null | Variable<any, string>,
	sizes__small__height?: ValueTypes["User_Organiser_Logo_sizes__small__height_operator"] | undefined | null | Variable<any, string>,
	sizes__small__mimeType?: ValueTypes["User_Organiser_Logo_sizes__small__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__small__filesize?: ValueTypes["User_Organiser_Logo_sizes__small__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__small__filename?: ValueTypes["User_Organiser_Logo_sizes__small__filename_operator"] | undefined | null | Variable<any, string>,
	sizes__medium__url?: ValueTypes["User_Organiser_Logo_sizes__medium__url_operator"] | undefined | null | Variable<any, string>,
	sizes__medium__width?: ValueTypes["User_Organiser_Logo_sizes__medium__width_operator"] | undefined | null | Variable<any, string>,
	sizes__medium__height?: ValueTypes["User_Organiser_Logo_sizes__medium__height_operator"] | undefined | null | Variable<any, string>,
	sizes__medium__mimeType?: ValueTypes["User_Organiser_Logo_sizes__medium__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__medium__filesize?: ValueTypes["User_Organiser_Logo_sizes__medium__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__medium__filename?: ValueTypes["User_Organiser_Logo_sizes__medium__filename_operator"] | undefined | null | Variable<any, string>,
	sizes__large__url?: ValueTypes["User_Organiser_Logo_sizes__large__url_operator"] | undefined | null | Variable<any, string>,
	sizes__large__width?: ValueTypes["User_Organiser_Logo_sizes__large__width_operator"] | undefined | null | Variable<any, string>,
	sizes__large__height?: ValueTypes["User_Organiser_Logo_sizes__large__height_operator"] | undefined | null | Variable<any, string>,
	sizes__large__mimeType?: ValueTypes["User_Organiser_Logo_sizes__large__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__large__filesize?: ValueTypes["User_Organiser_Logo_sizes__large__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__large__filename?: ValueTypes["User_Organiser_Logo_sizes__large__filename_operator"] | undefined | null | Variable<any, string>,
	sizes__small_jpeg__url?: ValueTypes["User_Organiser_Logo_sizes__small_jpeg__url_operator"] | undefined | null | Variable<any, string>,
	sizes__small_jpeg__width?: ValueTypes["User_Organiser_Logo_sizes__small_jpeg__width_operator"] | undefined | null | Variable<any, string>,
	sizes__small_jpeg__height?: ValueTypes["User_Organiser_Logo_sizes__small_jpeg__height_operator"] | undefined | null | Variable<any, string>,
	sizes__small_jpeg__mimeType?: ValueTypes["User_Organiser_Logo_sizes__small_jpeg__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__small_jpeg__filesize?: ValueTypes["User_Organiser_Logo_sizes__small_jpeg__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__small_jpeg__filename?: ValueTypes["User_Organiser_Logo_sizes__small_jpeg__filename_operator"] | undefined | null | Variable<any, string>,
	sizes__medium_jpeg__url?: ValueTypes["User_Organiser_Logo_sizes__medium_jpeg__url_operator"] | undefined | null | Variable<any, string>,
	sizes__medium_jpeg__width?: ValueTypes["User_Organiser_Logo_sizes__medium_jpeg__width_operator"] | undefined | null | Variable<any, string>,
	sizes__medium_jpeg__height?: ValueTypes["User_Organiser_Logo_sizes__medium_jpeg__height_operator"] | undefined | null | Variable<any, string>,
	sizes__medium_jpeg__mimeType?: ValueTypes["User_Organiser_Logo_sizes__medium_jpeg__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__medium_jpeg__filesize?: ValueTypes["User_Organiser_Logo_sizes__medium_jpeg__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__medium_jpeg__filename?: ValueTypes["User_Organiser_Logo_sizes__medium_jpeg__filename_operator"] | undefined | null | Variable<any, string>,
	sizes__large_jpeg__url?: ValueTypes["User_Organiser_Logo_sizes__large_jpeg__url_operator"] | undefined | null | Variable<any, string>,
	sizes__large_jpeg__width?: ValueTypes["User_Organiser_Logo_sizes__large_jpeg__width_operator"] | undefined | null | Variable<any, string>,
	sizes__large_jpeg__height?: ValueTypes["User_Organiser_Logo_sizes__large_jpeg__height_operator"] | undefined | null | Variable<any, string>,
	sizes__large_jpeg__mimeType?: ValueTypes["User_Organiser_Logo_sizes__large_jpeg__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__large_jpeg__filesize?: ValueTypes["User_Organiser_Logo_sizes__large_jpeg__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__large_jpeg__filename?: ValueTypes["User_Organiser_Logo_sizes__large_jpeg__filename_operator"] | undefined | null | Variable<any, string>,
	id?: ValueTypes["User_Organiser_Logo_id_operator"] | undefined | null | Variable<any, string>,
	AND?: Array<ValueTypes["User_Organiser_Logo_where_and"] | undefined | null> | undefined | null | Variable<any, string>,
	OR?: Array<ValueTypes["User_Organiser_Logo_where_or"] | undefined | null> | undefined | null | Variable<any, string>
};
	["User_Organiser_Banner_where"]: {
	alt?: ValueTypes["User_Organiser_Banner_alt_operator"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["User_Organiser_Banner_updatedAt_operator"] | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["User_Organiser_Banner_createdAt_operator"] | undefined | null | Variable<any, string>,
	url?: ValueTypes["User_Organiser_Banner_url_operator"] | undefined | null | Variable<any, string>,
	filename?: ValueTypes["User_Organiser_Banner_filename_operator"] | undefined | null | Variable<any, string>,
	mimeType?: ValueTypes["User_Organiser_Banner_mimeType_operator"] | undefined | null | Variable<any, string>,
	filesize?: ValueTypes["User_Organiser_Banner_filesize_operator"] | undefined | null | Variable<any, string>,
	width?: ValueTypes["User_Organiser_Banner_width_operator"] | undefined | null | Variable<any, string>,
	height?: ValueTypes["User_Organiser_Banner_height_operator"] | undefined | null | Variable<any, string>,
	focalX?: ValueTypes["User_Organiser_Banner_focalX_operator"] | undefined | null | Variable<any, string>,
	focalY?: ValueTypes["User_Organiser_Banner_focalY_operator"] | undefined | null | Variable<any, string>,
	sizes__small__url?: ValueTypes["User_Organiser_Banner_sizes__small__url_operator"] | undefined | null | Variable<any, string>,
	sizes__small__width?: ValueTypes["User_Organiser_Banner_sizes__small__width_operator"] | undefined | null | Variable<any, string>,
	sizes__small__height?: ValueTypes["User_Organiser_Banner_sizes__small__height_operator"] | undefined | null | Variable<any, string>,
	sizes__small__mimeType?: ValueTypes["User_Organiser_Banner_sizes__small__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__small__filesize?: ValueTypes["User_Organiser_Banner_sizes__small__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__small__filename?: ValueTypes["User_Organiser_Banner_sizes__small__filename_operator"] | undefined | null | Variable<any, string>,
	sizes__medium__url?: ValueTypes["User_Organiser_Banner_sizes__medium__url_operator"] | undefined | null | Variable<any, string>,
	sizes__medium__width?: ValueTypes["User_Organiser_Banner_sizes__medium__width_operator"] | undefined | null | Variable<any, string>,
	sizes__medium__height?: ValueTypes["User_Organiser_Banner_sizes__medium__height_operator"] | undefined | null | Variable<any, string>,
	sizes__medium__mimeType?: ValueTypes["User_Organiser_Banner_sizes__medium__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__medium__filesize?: ValueTypes["User_Organiser_Banner_sizes__medium__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__medium__filename?: ValueTypes["User_Organiser_Banner_sizes__medium__filename_operator"] | undefined | null | Variable<any, string>,
	sizes__large__url?: ValueTypes["User_Organiser_Banner_sizes__large__url_operator"] | undefined | null | Variable<any, string>,
	sizes__large__width?: ValueTypes["User_Organiser_Banner_sizes__large__width_operator"] | undefined | null | Variable<any, string>,
	sizes__large__height?: ValueTypes["User_Organiser_Banner_sizes__large__height_operator"] | undefined | null | Variable<any, string>,
	sizes__large__mimeType?: ValueTypes["User_Organiser_Banner_sizes__large__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__large__filesize?: ValueTypes["User_Organiser_Banner_sizes__large__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__large__filename?: ValueTypes["User_Organiser_Banner_sizes__large__filename_operator"] | undefined | null | Variable<any, string>,
	sizes__small_jpeg__url?: ValueTypes["User_Organiser_Banner_sizes__small_jpeg__url_operator"] | undefined | null | Variable<any, string>,
	sizes__small_jpeg__width?: ValueTypes["User_Organiser_Banner_sizes__small_jpeg__width_operator"] | undefined | null | Variable<any, string>,
	sizes__small_jpeg__height?: ValueTypes["User_Organiser_Banner_sizes__small_jpeg__height_operator"] | undefined | null | Variable<any, string>,
	sizes__small_jpeg__mimeType?: ValueTypes["User_Organiser_Banner_sizes__small_jpeg__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__small_jpeg__filesize?: ValueTypes["User_Organiser_Banner_sizes__small_jpeg__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__small_jpeg__filename?: ValueTypes["User_Organiser_Banner_sizes__small_jpeg__filename_operator"] | undefined | null | Variable<any, string>,
	sizes__medium_jpeg__url?: ValueTypes["User_Organiser_Banner_sizes__medium_jpeg__url_operator"] | undefined | null | Variable<any, string>,
	sizes__medium_jpeg__width?: ValueTypes["User_Organiser_Banner_sizes__medium_jpeg__width_operator"] | undefined | null | Variable<any, string>,
	sizes__medium_jpeg__height?: ValueTypes["User_Organiser_Banner_sizes__medium_jpeg__height_operator"] | undefined | null | Variable<any, string>,
	sizes__medium_jpeg__mimeType?: ValueTypes["User_Organiser_Banner_sizes__medium_jpeg__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__medium_jpeg__filesize?: ValueTypes["User_Organiser_Banner_sizes__medium_jpeg__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__medium_jpeg__filename?: ValueTypes["User_Organiser_Banner_sizes__medium_jpeg__filename_operator"] | undefined | null | Variable<any, string>,
	sizes__large_jpeg__url?: ValueTypes["User_Organiser_Banner_sizes__large_jpeg__url_operator"] | undefined | null | Variable<any, string>,
	sizes__large_jpeg__width?: ValueTypes["User_Organiser_Banner_sizes__large_jpeg__width_operator"] | undefined | null | Variable<any, string>,
	sizes__large_jpeg__height?: ValueTypes["User_Organiser_Banner_sizes__large_jpeg__height_operator"] | undefined | null | Variable<any, string>,
	sizes__large_jpeg__mimeType?: ValueTypes["User_Organiser_Banner_sizes__large_jpeg__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__large_jpeg__filesize?: ValueTypes["User_Organiser_Banner_sizes__large_jpeg__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__large_jpeg__filename?: ValueTypes["User_Organiser_Banner_sizes__large_jpeg__filename_operator"] | undefined | null | Variable<any, string>,
	id?: ValueTypes["User_Organiser_Banner_id_operator"] | undefined | null | Variable<any, string>,
	AND?: Array<ValueTypes["User_Organiser_Banner_where_and"] | undefined | null> | undefined | null | Variable<any, string>,
	OR?: Array<ValueTypes["User_Organiser_Banner_where_or"] | undefined | null> | undefined | null | Variable<any, string>
};
	["User_Organiser_Banner_alt_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["User_Organiser_Banner_updatedAt_operator"]: {
	equals?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	not_equals?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	greater_than_equal?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	greater_than?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	less_than_equal?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	less_than?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	like?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["User_Organiser_Banner_createdAt_operator"]: {
	equals?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	not_equals?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	greater_than_equal?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	greater_than?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	less_than_equal?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	less_than?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	like?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["User_Organiser_Banner_url_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["User_Organiser_Banner_filename_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["User_Organiser_Banner_mimeType_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["User_Organiser_Banner_filesize_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["User_Organiser_Banner_width_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["User_Organiser_Banner_height_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["User_Organiser_Banner_focalX_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["User_Organiser_Banner_focalY_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["User_Organiser_Banner_sizes__small__url_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["User_Organiser_Banner_sizes__small__width_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["User_Organiser_Banner_sizes__small__height_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["User_Organiser_Banner_sizes__small__mimeType_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["User_Organiser_Banner_sizes__small__filesize_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["User_Organiser_Banner_sizes__small__filename_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["User_Organiser_Banner_sizes__medium__url_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["User_Organiser_Banner_sizes__medium__width_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["User_Organiser_Banner_sizes__medium__height_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["User_Organiser_Banner_sizes__medium__mimeType_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["User_Organiser_Banner_sizes__medium__filesize_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["User_Organiser_Banner_sizes__medium__filename_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["User_Organiser_Banner_sizes__large__url_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["User_Organiser_Banner_sizes__large__width_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["User_Organiser_Banner_sizes__large__height_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["User_Organiser_Banner_sizes__large__mimeType_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["User_Organiser_Banner_sizes__large__filesize_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["User_Organiser_Banner_sizes__large__filename_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["User_Organiser_Banner_sizes__small_jpeg__url_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["User_Organiser_Banner_sizes__small_jpeg__width_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["User_Organiser_Banner_sizes__small_jpeg__height_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["User_Organiser_Banner_sizes__small_jpeg__mimeType_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["User_Organiser_Banner_sizes__small_jpeg__filesize_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["User_Organiser_Banner_sizes__small_jpeg__filename_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["User_Organiser_Banner_sizes__medium_jpeg__url_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["User_Organiser_Banner_sizes__medium_jpeg__width_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["User_Organiser_Banner_sizes__medium_jpeg__height_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["User_Organiser_Banner_sizes__medium_jpeg__mimeType_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["User_Organiser_Banner_sizes__medium_jpeg__filesize_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["User_Organiser_Banner_sizes__medium_jpeg__filename_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["User_Organiser_Banner_sizes__large_jpeg__url_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["User_Organiser_Banner_sizes__large_jpeg__width_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["User_Organiser_Banner_sizes__large_jpeg__height_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["User_Organiser_Banner_sizes__large_jpeg__mimeType_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["User_Organiser_Banner_sizes__large_jpeg__filesize_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["User_Organiser_Banner_sizes__large_jpeg__filename_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["User_Organiser_Banner_id_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["User_Organiser_Banner_where_and"]: {
	alt?: ValueTypes["User_Organiser_Banner_alt_operator"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["User_Organiser_Banner_updatedAt_operator"] | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["User_Organiser_Banner_createdAt_operator"] | undefined | null | Variable<any, string>,
	url?: ValueTypes["User_Organiser_Banner_url_operator"] | undefined | null | Variable<any, string>,
	filename?: ValueTypes["User_Organiser_Banner_filename_operator"] | undefined | null | Variable<any, string>,
	mimeType?: ValueTypes["User_Organiser_Banner_mimeType_operator"] | undefined | null | Variable<any, string>,
	filesize?: ValueTypes["User_Organiser_Banner_filesize_operator"] | undefined | null | Variable<any, string>,
	width?: ValueTypes["User_Organiser_Banner_width_operator"] | undefined | null | Variable<any, string>,
	height?: ValueTypes["User_Organiser_Banner_height_operator"] | undefined | null | Variable<any, string>,
	focalX?: ValueTypes["User_Organiser_Banner_focalX_operator"] | undefined | null | Variable<any, string>,
	focalY?: ValueTypes["User_Organiser_Banner_focalY_operator"] | undefined | null | Variable<any, string>,
	sizes__small__url?: ValueTypes["User_Organiser_Banner_sizes__small__url_operator"] | undefined | null | Variable<any, string>,
	sizes__small__width?: ValueTypes["User_Organiser_Banner_sizes__small__width_operator"] | undefined | null | Variable<any, string>,
	sizes__small__height?: ValueTypes["User_Organiser_Banner_sizes__small__height_operator"] | undefined | null | Variable<any, string>,
	sizes__small__mimeType?: ValueTypes["User_Organiser_Banner_sizes__small__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__small__filesize?: ValueTypes["User_Organiser_Banner_sizes__small__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__small__filename?: ValueTypes["User_Organiser_Banner_sizes__small__filename_operator"] | undefined | null | Variable<any, string>,
	sizes__medium__url?: ValueTypes["User_Organiser_Banner_sizes__medium__url_operator"] | undefined | null | Variable<any, string>,
	sizes__medium__width?: ValueTypes["User_Organiser_Banner_sizes__medium__width_operator"] | undefined | null | Variable<any, string>,
	sizes__medium__height?: ValueTypes["User_Organiser_Banner_sizes__medium__height_operator"] | undefined | null | Variable<any, string>,
	sizes__medium__mimeType?: ValueTypes["User_Organiser_Banner_sizes__medium__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__medium__filesize?: ValueTypes["User_Organiser_Banner_sizes__medium__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__medium__filename?: ValueTypes["User_Organiser_Banner_sizes__medium__filename_operator"] | undefined | null | Variable<any, string>,
	sizes__large__url?: ValueTypes["User_Organiser_Banner_sizes__large__url_operator"] | undefined | null | Variable<any, string>,
	sizes__large__width?: ValueTypes["User_Organiser_Banner_sizes__large__width_operator"] | undefined | null | Variable<any, string>,
	sizes__large__height?: ValueTypes["User_Organiser_Banner_sizes__large__height_operator"] | undefined | null | Variable<any, string>,
	sizes__large__mimeType?: ValueTypes["User_Organiser_Banner_sizes__large__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__large__filesize?: ValueTypes["User_Organiser_Banner_sizes__large__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__large__filename?: ValueTypes["User_Organiser_Banner_sizes__large__filename_operator"] | undefined | null | Variable<any, string>,
	sizes__small_jpeg__url?: ValueTypes["User_Organiser_Banner_sizes__small_jpeg__url_operator"] | undefined | null | Variable<any, string>,
	sizes__small_jpeg__width?: ValueTypes["User_Organiser_Banner_sizes__small_jpeg__width_operator"] | undefined | null | Variable<any, string>,
	sizes__small_jpeg__height?: ValueTypes["User_Organiser_Banner_sizes__small_jpeg__height_operator"] | undefined | null | Variable<any, string>,
	sizes__small_jpeg__mimeType?: ValueTypes["User_Organiser_Banner_sizes__small_jpeg__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__small_jpeg__filesize?: ValueTypes["User_Organiser_Banner_sizes__small_jpeg__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__small_jpeg__filename?: ValueTypes["User_Organiser_Banner_sizes__small_jpeg__filename_operator"] | undefined | null | Variable<any, string>,
	sizes__medium_jpeg__url?: ValueTypes["User_Organiser_Banner_sizes__medium_jpeg__url_operator"] | undefined | null | Variable<any, string>,
	sizes__medium_jpeg__width?: ValueTypes["User_Organiser_Banner_sizes__medium_jpeg__width_operator"] | undefined | null | Variable<any, string>,
	sizes__medium_jpeg__height?: ValueTypes["User_Organiser_Banner_sizes__medium_jpeg__height_operator"] | undefined | null | Variable<any, string>,
	sizes__medium_jpeg__mimeType?: ValueTypes["User_Organiser_Banner_sizes__medium_jpeg__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__medium_jpeg__filesize?: ValueTypes["User_Organiser_Banner_sizes__medium_jpeg__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__medium_jpeg__filename?: ValueTypes["User_Organiser_Banner_sizes__medium_jpeg__filename_operator"] | undefined | null | Variable<any, string>,
	sizes__large_jpeg__url?: ValueTypes["User_Organiser_Banner_sizes__large_jpeg__url_operator"] | undefined | null | Variable<any, string>,
	sizes__large_jpeg__width?: ValueTypes["User_Organiser_Banner_sizes__large_jpeg__width_operator"] | undefined | null | Variable<any, string>,
	sizes__large_jpeg__height?: ValueTypes["User_Organiser_Banner_sizes__large_jpeg__height_operator"] | undefined | null | Variable<any, string>,
	sizes__large_jpeg__mimeType?: ValueTypes["User_Organiser_Banner_sizes__large_jpeg__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__large_jpeg__filesize?: ValueTypes["User_Organiser_Banner_sizes__large_jpeg__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__large_jpeg__filename?: ValueTypes["User_Organiser_Banner_sizes__large_jpeg__filename_operator"] | undefined | null | Variable<any, string>,
	id?: ValueTypes["User_Organiser_Banner_id_operator"] | undefined | null | Variable<any, string>,
	AND?: Array<ValueTypes["User_Organiser_Banner_where_and"] | undefined | null> | undefined | null | Variable<any, string>,
	OR?: Array<ValueTypes["User_Organiser_Banner_where_or"] | undefined | null> | undefined | null | Variable<any, string>
};
	["User_Organiser_Banner_where_or"]: {
	alt?: ValueTypes["User_Organiser_Banner_alt_operator"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["User_Organiser_Banner_updatedAt_operator"] | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["User_Organiser_Banner_createdAt_operator"] | undefined | null | Variable<any, string>,
	url?: ValueTypes["User_Organiser_Banner_url_operator"] | undefined | null | Variable<any, string>,
	filename?: ValueTypes["User_Organiser_Banner_filename_operator"] | undefined | null | Variable<any, string>,
	mimeType?: ValueTypes["User_Organiser_Banner_mimeType_operator"] | undefined | null | Variable<any, string>,
	filesize?: ValueTypes["User_Organiser_Banner_filesize_operator"] | undefined | null | Variable<any, string>,
	width?: ValueTypes["User_Organiser_Banner_width_operator"] | undefined | null | Variable<any, string>,
	height?: ValueTypes["User_Organiser_Banner_height_operator"] | undefined | null | Variable<any, string>,
	focalX?: ValueTypes["User_Organiser_Banner_focalX_operator"] | undefined | null | Variable<any, string>,
	focalY?: ValueTypes["User_Organiser_Banner_focalY_operator"] | undefined | null | Variable<any, string>,
	sizes__small__url?: ValueTypes["User_Organiser_Banner_sizes__small__url_operator"] | undefined | null | Variable<any, string>,
	sizes__small__width?: ValueTypes["User_Organiser_Banner_sizes__small__width_operator"] | undefined | null | Variable<any, string>,
	sizes__small__height?: ValueTypes["User_Organiser_Banner_sizes__small__height_operator"] | undefined | null | Variable<any, string>,
	sizes__small__mimeType?: ValueTypes["User_Organiser_Banner_sizes__small__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__small__filesize?: ValueTypes["User_Organiser_Banner_sizes__small__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__small__filename?: ValueTypes["User_Organiser_Banner_sizes__small__filename_operator"] | undefined | null | Variable<any, string>,
	sizes__medium__url?: ValueTypes["User_Organiser_Banner_sizes__medium__url_operator"] | undefined | null | Variable<any, string>,
	sizes__medium__width?: ValueTypes["User_Organiser_Banner_sizes__medium__width_operator"] | undefined | null | Variable<any, string>,
	sizes__medium__height?: ValueTypes["User_Organiser_Banner_sizes__medium__height_operator"] | undefined | null | Variable<any, string>,
	sizes__medium__mimeType?: ValueTypes["User_Organiser_Banner_sizes__medium__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__medium__filesize?: ValueTypes["User_Organiser_Banner_sizes__medium__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__medium__filename?: ValueTypes["User_Organiser_Banner_sizes__medium__filename_operator"] | undefined | null | Variable<any, string>,
	sizes__large__url?: ValueTypes["User_Organiser_Banner_sizes__large__url_operator"] | undefined | null | Variable<any, string>,
	sizes__large__width?: ValueTypes["User_Organiser_Banner_sizes__large__width_operator"] | undefined | null | Variable<any, string>,
	sizes__large__height?: ValueTypes["User_Organiser_Banner_sizes__large__height_operator"] | undefined | null | Variable<any, string>,
	sizes__large__mimeType?: ValueTypes["User_Organiser_Banner_sizes__large__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__large__filesize?: ValueTypes["User_Organiser_Banner_sizes__large__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__large__filename?: ValueTypes["User_Organiser_Banner_sizes__large__filename_operator"] | undefined | null | Variable<any, string>,
	sizes__small_jpeg__url?: ValueTypes["User_Organiser_Banner_sizes__small_jpeg__url_operator"] | undefined | null | Variable<any, string>,
	sizes__small_jpeg__width?: ValueTypes["User_Organiser_Banner_sizes__small_jpeg__width_operator"] | undefined | null | Variable<any, string>,
	sizes__small_jpeg__height?: ValueTypes["User_Organiser_Banner_sizes__small_jpeg__height_operator"] | undefined | null | Variable<any, string>,
	sizes__small_jpeg__mimeType?: ValueTypes["User_Organiser_Banner_sizes__small_jpeg__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__small_jpeg__filesize?: ValueTypes["User_Organiser_Banner_sizes__small_jpeg__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__small_jpeg__filename?: ValueTypes["User_Organiser_Banner_sizes__small_jpeg__filename_operator"] | undefined | null | Variable<any, string>,
	sizes__medium_jpeg__url?: ValueTypes["User_Organiser_Banner_sizes__medium_jpeg__url_operator"] | undefined | null | Variable<any, string>,
	sizes__medium_jpeg__width?: ValueTypes["User_Organiser_Banner_sizes__medium_jpeg__width_operator"] | undefined | null | Variable<any, string>,
	sizes__medium_jpeg__height?: ValueTypes["User_Organiser_Banner_sizes__medium_jpeg__height_operator"] | undefined | null | Variable<any, string>,
	sizes__medium_jpeg__mimeType?: ValueTypes["User_Organiser_Banner_sizes__medium_jpeg__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__medium_jpeg__filesize?: ValueTypes["User_Organiser_Banner_sizes__medium_jpeg__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__medium_jpeg__filename?: ValueTypes["User_Organiser_Banner_sizes__medium_jpeg__filename_operator"] | undefined | null | Variable<any, string>,
	sizes__large_jpeg__url?: ValueTypes["User_Organiser_Banner_sizes__large_jpeg__url_operator"] | undefined | null | Variable<any, string>,
	sizes__large_jpeg__width?: ValueTypes["User_Organiser_Banner_sizes__large_jpeg__width_operator"] | undefined | null | Variable<any, string>,
	sizes__large_jpeg__height?: ValueTypes["User_Organiser_Banner_sizes__large_jpeg__height_operator"] | undefined | null | Variable<any, string>,
	sizes__large_jpeg__mimeType?: ValueTypes["User_Organiser_Banner_sizes__large_jpeg__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__large_jpeg__filesize?: ValueTypes["User_Organiser_Banner_sizes__large_jpeg__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__large_jpeg__filename?: ValueTypes["User_Organiser_Banner_sizes__large_jpeg__filename_operator"] | undefined | null | Variable<any, string>,
	id?: ValueTypes["User_Organiser_Banner_id_operator"] | undefined | null | Variable<any, string>,
	AND?: Array<ValueTypes["User_Organiser_Banner_where_and"] | undefined | null> | undefined | null | Variable<any, string>,
	OR?: Array<ValueTypes["User_Organiser_Banner_where_or"] | undefined | null> | undefined | null | Variable<any, string>
};
	["User_Organiser_language"]:User_Organiser_language;
	["User_Sso"]: AliasType<{
	googleId?:boolean | `@${string}`,
	facebookId?:boolean | `@${string}`,
	appleId?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["User_status"]:User_status;
	/** A field whose value conforms to the standard internet email address format as specified in HTML Spec: https://html.spec.whatwg.org/multipage/input.html#valid-e-mail-address. */
["EmailAddress"]:unknown;
	["Event_level"]:Event_level;
	["Event_Thumbnail_where"]: {
	alt?: ValueTypes["Event_Thumbnail_alt_operator"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["Event_Thumbnail_updatedAt_operator"] | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["Event_Thumbnail_createdAt_operator"] | undefined | null | Variable<any, string>,
	url?: ValueTypes["Event_Thumbnail_url_operator"] | undefined | null | Variable<any, string>,
	filename?: ValueTypes["Event_Thumbnail_filename_operator"] | undefined | null | Variable<any, string>,
	mimeType?: ValueTypes["Event_Thumbnail_mimeType_operator"] | undefined | null | Variable<any, string>,
	filesize?: ValueTypes["Event_Thumbnail_filesize_operator"] | undefined | null | Variable<any, string>,
	width?: ValueTypes["Event_Thumbnail_width_operator"] | undefined | null | Variable<any, string>,
	height?: ValueTypes["Event_Thumbnail_height_operator"] | undefined | null | Variable<any, string>,
	focalX?: ValueTypes["Event_Thumbnail_focalX_operator"] | undefined | null | Variable<any, string>,
	focalY?: ValueTypes["Event_Thumbnail_focalY_operator"] | undefined | null | Variable<any, string>,
	sizes__small__url?: ValueTypes["Event_Thumbnail_sizes__small__url_operator"] | undefined | null | Variable<any, string>,
	sizes__small__width?: ValueTypes["Event_Thumbnail_sizes__small__width_operator"] | undefined | null | Variable<any, string>,
	sizes__small__height?: ValueTypes["Event_Thumbnail_sizes__small__height_operator"] | undefined | null | Variable<any, string>,
	sizes__small__mimeType?: ValueTypes["Event_Thumbnail_sizes__small__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__small__filesize?: ValueTypes["Event_Thumbnail_sizes__small__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__small__filename?: ValueTypes["Event_Thumbnail_sizes__small__filename_operator"] | undefined | null | Variable<any, string>,
	sizes__medium__url?: ValueTypes["Event_Thumbnail_sizes__medium__url_operator"] | undefined | null | Variable<any, string>,
	sizes__medium__width?: ValueTypes["Event_Thumbnail_sizes__medium__width_operator"] | undefined | null | Variable<any, string>,
	sizes__medium__height?: ValueTypes["Event_Thumbnail_sizes__medium__height_operator"] | undefined | null | Variable<any, string>,
	sizes__medium__mimeType?: ValueTypes["Event_Thumbnail_sizes__medium__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__medium__filesize?: ValueTypes["Event_Thumbnail_sizes__medium__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__medium__filename?: ValueTypes["Event_Thumbnail_sizes__medium__filename_operator"] | undefined | null | Variable<any, string>,
	sizes__large__url?: ValueTypes["Event_Thumbnail_sizes__large__url_operator"] | undefined | null | Variable<any, string>,
	sizes__large__width?: ValueTypes["Event_Thumbnail_sizes__large__width_operator"] | undefined | null | Variable<any, string>,
	sizes__large__height?: ValueTypes["Event_Thumbnail_sizes__large__height_operator"] | undefined | null | Variable<any, string>,
	sizes__large__mimeType?: ValueTypes["Event_Thumbnail_sizes__large__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__large__filesize?: ValueTypes["Event_Thumbnail_sizes__large__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__large__filename?: ValueTypes["Event_Thumbnail_sizes__large__filename_operator"] | undefined | null | Variable<any, string>,
	sizes__small_jpeg__url?: ValueTypes["Event_Thumbnail_sizes__small_jpeg__url_operator"] | undefined | null | Variable<any, string>,
	sizes__small_jpeg__width?: ValueTypes["Event_Thumbnail_sizes__small_jpeg__width_operator"] | undefined | null | Variable<any, string>,
	sizes__small_jpeg__height?: ValueTypes["Event_Thumbnail_sizes__small_jpeg__height_operator"] | undefined | null | Variable<any, string>,
	sizes__small_jpeg__mimeType?: ValueTypes["Event_Thumbnail_sizes__small_jpeg__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__small_jpeg__filesize?: ValueTypes["Event_Thumbnail_sizes__small_jpeg__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__small_jpeg__filename?: ValueTypes["Event_Thumbnail_sizes__small_jpeg__filename_operator"] | undefined | null | Variable<any, string>,
	sizes__medium_jpeg__url?: ValueTypes["Event_Thumbnail_sizes__medium_jpeg__url_operator"] | undefined | null | Variable<any, string>,
	sizes__medium_jpeg__width?: ValueTypes["Event_Thumbnail_sizes__medium_jpeg__width_operator"] | undefined | null | Variable<any, string>,
	sizes__medium_jpeg__height?: ValueTypes["Event_Thumbnail_sizes__medium_jpeg__height_operator"] | undefined | null | Variable<any, string>,
	sizes__medium_jpeg__mimeType?: ValueTypes["Event_Thumbnail_sizes__medium_jpeg__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__medium_jpeg__filesize?: ValueTypes["Event_Thumbnail_sizes__medium_jpeg__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__medium_jpeg__filename?: ValueTypes["Event_Thumbnail_sizes__medium_jpeg__filename_operator"] | undefined | null | Variable<any, string>,
	sizes__large_jpeg__url?: ValueTypes["Event_Thumbnail_sizes__large_jpeg__url_operator"] | undefined | null | Variable<any, string>,
	sizes__large_jpeg__width?: ValueTypes["Event_Thumbnail_sizes__large_jpeg__width_operator"] | undefined | null | Variable<any, string>,
	sizes__large_jpeg__height?: ValueTypes["Event_Thumbnail_sizes__large_jpeg__height_operator"] | undefined | null | Variable<any, string>,
	sizes__large_jpeg__mimeType?: ValueTypes["Event_Thumbnail_sizes__large_jpeg__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__large_jpeg__filesize?: ValueTypes["Event_Thumbnail_sizes__large_jpeg__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__large_jpeg__filename?: ValueTypes["Event_Thumbnail_sizes__large_jpeg__filename_operator"] | undefined | null | Variable<any, string>,
	id?: ValueTypes["Event_Thumbnail_id_operator"] | undefined | null | Variable<any, string>,
	AND?: Array<ValueTypes["Event_Thumbnail_where_and"] | undefined | null> | undefined | null | Variable<any, string>,
	OR?: Array<ValueTypes["Event_Thumbnail_where_or"] | undefined | null> | undefined | null | Variable<any, string>
};
	["Event_Thumbnail_alt_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Event_Thumbnail_updatedAt_operator"]: {
	equals?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	not_equals?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	greater_than_equal?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	greater_than?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	less_than_equal?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	less_than?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	like?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Event_Thumbnail_createdAt_operator"]: {
	equals?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	not_equals?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	greater_than_equal?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	greater_than?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	less_than_equal?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	less_than?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	like?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Event_Thumbnail_url_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Event_Thumbnail_filename_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Event_Thumbnail_mimeType_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Event_Thumbnail_filesize_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Event_Thumbnail_width_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Event_Thumbnail_height_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Event_Thumbnail_focalX_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Event_Thumbnail_focalY_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Event_Thumbnail_sizes__small__url_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Event_Thumbnail_sizes__small__width_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Event_Thumbnail_sizes__small__height_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Event_Thumbnail_sizes__small__mimeType_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Event_Thumbnail_sizes__small__filesize_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Event_Thumbnail_sizes__small__filename_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Event_Thumbnail_sizes__medium__url_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Event_Thumbnail_sizes__medium__width_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Event_Thumbnail_sizes__medium__height_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Event_Thumbnail_sizes__medium__mimeType_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Event_Thumbnail_sizes__medium__filesize_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Event_Thumbnail_sizes__medium__filename_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Event_Thumbnail_sizes__large__url_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Event_Thumbnail_sizes__large__width_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Event_Thumbnail_sizes__large__height_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Event_Thumbnail_sizes__large__mimeType_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Event_Thumbnail_sizes__large__filesize_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Event_Thumbnail_sizes__large__filename_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Event_Thumbnail_sizes__small_jpeg__url_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Event_Thumbnail_sizes__small_jpeg__width_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Event_Thumbnail_sizes__small_jpeg__height_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Event_Thumbnail_sizes__small_jpeg__mimeType_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Event_Thumbnail_sizes__small_jpeg__filesize_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Event_Thumbnail_sizes__small_jpeg__filename_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Event_Thumbnail_sizes__medium_jpeg__url_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Event_Thumbnail_sizes__medium_jpeg__width_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Event_Thumbnail_sizes__medium_jpeg__height_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Event_Thumbnail_sizes__medium_jpeg__mimeType_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Event_Thumbnail_sizes__medium_jpeg__filesize_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Event_Thumbnail_sizes__medium_jpeg__filename_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Event_Thumbnail_sizes__large_jpeg__url_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Event_Thumbnail_sizes__large_jpeg__width_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Event_Thumbnail_sizes__large_jpeg__height_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Event_Thumbnail_sizes__large_jpeg__mimeType_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Event_Thumbnail_sizes__large_jpeg__filesize_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Event_Thumbnail_sizes__large_jpeg__filename_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Event_Thumbnail_id_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Event_Thumbnail_where_and"]: {
	alt?: ValueTypes["Event_Thumbnail_alt_operator"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["Event_Thumbnail_updatedAt_operator"] | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["Event_Thumbnail_createdAt_operator"] | undefined | null | Variable<any, string>,
	url?: ValueTypes["Event_Thumbnail_url_operator"] | undefined | null | Variable<any, string>,
	filename?: ValueTypes["Event_Thumbnail_filename_operator"] | undefined | null | Variable<any, string>,
	mimeType?: ValueTypes["Event_Thumbnail_mimeType_operator"] | undefined | null | Variable<any, string>,
	filesize?: ValueTypes["Event_Thumbnail_filesize_operator"] | undefined | null | Variable<any, string>,
	width?: ValueTypes["Event_Thumbnail_width_operator"] | undefined | null | Variable<any, string>,
	height?: ValueTypes["Event_Thumbnail_height_operator"] | undefined | null | Variable<any, string>,
	focalX?: ValueTypes["Event_Thumbnail_focalX_operator"] | undefined | null | Variable<any, string>,
	focalY?: ValueTypes["Event_Thumbnail_focalY_operator"] | undefined | null | Variable<any, string>,
	sizes__small__url?: ValueTypes["Event_Thumbnail_sizes__small__url_operator"] | undefined | null | Variable<any, string>,
	sizes__small__width?: ValueTypes["Event_Thumbnail_sizes__small__width_operator"] | undefined | null | Variable<any, string>,
	sizes__small__height?: ValueTypes["Event_Thumbnail_sizes__small__height_operator"] | undefined | null | Variable<any, string>,
	sizes__small__mimeType?: ValueTypes["Event_Thumbnail_sizes__small__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__small__filesize?: ValueTypes["Event_Thumbnail_sizes__small__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__small__filename?: ValueTypes["Event_Thumbnail_sizes__small__filename_operator"] | undefined | null | Variable<any, string>,
	sizes__medium__url?: ValueTypes["Event_Thumbnail_sizes__medium__url_operator"] | undefined | null | Variable<any, string>,
	sizes__medium__width?: ValueTypes["Event_Thumbnail_sizes__medium__width_operator"] | undefined | null | Variable<any, string>,
	sizes__medium__height?: ValueTypes["Event_Thumbnail_sizes__medium__height_operator"] | undefined | null | Variable<any, string>,
	sizes__medium__mimeType?: ValueTypes["Event_Thumbnail_sizes__medium__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__medium__filesize?: ValueTypes["Event_Thumbnail_sizes__medium__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__medium__filename?: ValueTypes["Event_Thumbnail_sizes__medium__filename_operator"] | undefined | null | Variable<any, string>,
	sizes__large__url?: ValueTypes["Event_Thumbnail_sizes__large__url_operator"] | undefined | null | Variable<any, string>,
	sizes__large__width?: ValueTypes["Event_Thumbnail_sizes__large__width_operator"] | undefined | null | Variable<any, string>,
	sizes__large__height?: ValueTypes["Event_Thumbnail_sizes__large__height_operator"] | undefined | null | Variable<any, string>,
	sizes__large__mimeType?: ValueTypes["Event_Thumbnail_sizes__large__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__large__filesize?: ValueTypes["Event_Thumbnail_sizes__large__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__large__filename?: ValueTypes["Event_Thumbnail_sizes__large__filename_operator"] | undefined | null | Variable<any, string>,
	sizes__small_jpeg__url?: ValueTypes["Event_Thumbnail_sizes__small_jpeg__url_operator"] | undefined | null | Variable<any, string>,
	sizes__small_jpeg__width?: ValueTypes["Event_Thumbnail_sizes__small_jpeg__width_operator"] | undefined | null | Variable<any, string>,
	sizes__small_jpeg__height?: ValueTypes["Event_Thumbnail_sizes__small_jpeg__height_operator"] | undefined | null | Variable<any, string>,
	sizes__small_jpeg__mimeType?: ValueTypes["Event_Thumbnail_sizes__small_jpeg__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__small_jpeg__filesize?: ValueTypes["Event_Thumbnail_sizes__small_jpeg__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__small_jpeg__filename?: ValueTypes["Event_Thumbnail_sizes__small_jpeg__filename_operator"] | undefined | null | Variable<any, string>,
	sizes__medium_jpeg__url?: ValueTypes["Event_Thumbnail_sizes__medium_jpeg__url_operator"] | undefined | null | Variable<any, string>,
	sizes__medium_jpeg__width?: ValueTypes["Event_Thumbnail_sizes__medium_jpeg__width_operator"] | undefined | null | Variable<any, string>,
	sizes__medium_jpeg__height?: ValueTypes["Event_Thumbnail_sizes__medium_jpeg__height_operator"] | undefined | null | Variable<any, string>,
	sizes__medium_jpeg__mimeType?: ValueTypes["Event_Thumbnail_sizes__medium_jpeg__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__medium_jpeg__filesize?: ValueTypes["Event_Thumbnail_sizes__medium_jpeg__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__medium_jpeg__filename?: ValueTypes["Event_Thumbnail_sizes__medium_jpeg__filename_operator"] | undefined | null | Variable<any, string>,
	sizes__large_jpeg__url?: ValueTypes["Event_Thumbnail_sizes__large_jpeg__url_operator"] | undefined | null | Variable<any, string>,
	sizes__large_jpeg__width?: ValueTypes["Event_Thumbnail_sizes__large_jpeg__width_operator"] | undefined | null | Variable<any, string>,
	sizes__large_jpeg__height?: ValueTypes["Event_Thumbnail_sizes__large_jpeg__height_operator"] | undefined | null | Variable<any, string>,
	sizes__large_jpeg__mimeType?: ValueTypes["Event_Thumbnail_sizes__large_jpeg__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__large_jpeg__filesize?: ValueTypes["Event_Thumbnail_sizes__large_jpeg__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__large_jpeg__filename?: ValueTypes["Event_Thumbnail_sizes__large_jpeg__filename_operator"] | undefined | null | Variable<any, string>,
	id?: ValueTypes["Event_Thumbnail_id_operator"] | undefined | null | Variable<any, string>,
	AND?: Array<ValueTypes["Event_Thumbnail_where_and"] | undefined | null> | undefined | null | Variable<any, string>,
	OR?: Array<ValueTypes["Event_Thumbnail_where_or"] | undefined | null> | undefined | null | Variable<any, string>
};
	["Event_Thumbnail_where_or"]: {
	alt?: ValueTypes["Event_Thumbnail_alt_operator"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["Event_Thumbnail_updatedAt_operator"] | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["Event_Thumbnail_createdAt_operator"] | undefined | null | Variable<any, string>,
	url?: ValueTypes["Event_Thumbnail_url_operator"] | undefined | null | Variable<any, string>,
	filename?: ValueTypes["Event_Thumbnail_filename_operator"] | undefined | null | Variable<any, string>,
	mimeType?: ValueTypes["Event_Thumbnail_mimeType_operator"] | undefined | null | Variable<any, string>,
	filesize?: ValueTypes["Event_Thumbnail_filesize_operator"] | undefined | null | Variable<any, string>,
	width?: ValueTypes["Event_Thumbnail_width_operator"] | undefined | null | Variable<any, string>,
	height?: ValueTypes["Event_Thumbnail_height_operator"] | undefined | null | Variable<any, string>,
	focalX?: ValueTypes["Event_Thumbnail_focalX_operator"] | undefined | null | Variable<any, string>,
	focalY?: ValueTypes["Event_Thumbnail_focalY_operator"] | undefined | null | Variable<any, string>,
	sizes__small__url?: ValueTypes["Event_Thumbnail_sizes__small__url_operator"] | undefined | null | Variable<any, string>,
	sizes__small__width?: ValueTypes["Event_Thumbnail_sizes__small__width_operator"] | undefined | null | Variable<any, string>,
	sizes__small__height?: ValueTypes["Event_Thumbnail_sizes__small__height_operator"] | undefined | null | Variable<any, string>,
	sizes__small__mimeType?: ValueTypes["Event_Thumbnail_sizes__small__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__small__filesize?: ValueTypes["Event_Thumbnail_sizes__small__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__small__filename?: ValueTypes["Event_Thumbnail_sizes__small__filename_operator"] | undefined | null | Variable<any, string>,
	sizes__medium__url?: ValueTypes["Event_Thumbnail_sizes__medium__url_operator"] | undefined | null | Variable<any, string>,
	sizes__medium__width?: ValueTypes["Event_Thumbnail_sizes__medium__width_operator"] | undefined | null | Variable<any, string>,
	sizes__medium__height?: ValueTypes["Event_Thumbnail_sizes__medium__height_operator"] | undefined | null | Variable<any, string>,
	sizes__medium__mimeType?: ValueTypes["Event_Thumbnail_sizes__medium__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__medium__filesize?: ValueTypes["Event_Thumbnail_sizes__medium__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__medium__filename?: ValueTypes["Event_Thumbnail_sizes__medium__filename_operator"] | undefined | null | Variable<any, string>,
	sizes__large__url?: ValueTypes["Event_Thumbnail_sizes__large__url_operator"] | undefined | null | Variable<any, string>,
	sizes__large__width?: ValueTypes["Event_Thumbnail_sizes__large__width_operator"] | undefined | null | Variable<any, string>,
	sizes__large__height?: ValueTypes["Event_Thumbnail_sizes__large__height_operator"] | undefined | null | Variable<any, string>,
	sizes__large__mimeType?: ValueTypes["Event_Thumbnail_sizes__large__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__large__filesize?: ValueTypes["Event_Thumbnail_sizes__large__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__large__filename?: ValueTypes["Event_Thumbnail_sizes__large__filename_operator"] | undefined | null | Variable<any, string>,
	sizes__small_jpeg__url?: ValueTypes["Event_Thumbnail_sizes__small_jpeg__url_operator"] | undefined | null | Variable<any, string>,
	sizes__small_jpeg__width?: ValueTypes["Event_Thumbnail_sizes__small_jpeg__width_operator"] | undefined | null | Variable<any, string>,
	sizes__small_jpeg__height?: ValueTypes["Event_Thumbnail_sizes__small_jpeg__height_operator"] | undefined | null | Variable<any, string>,
	sizes__small_jpeg__mimeType?: ValueTypes["Event_Thumbnail_sizes__small_jpeg__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__small_jpeg__filesize?: ValueTypes["Event_Thumbnail_sizes__small_jpeg__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__small_jpeg__filename?: ValueTypes["Event_Thumbnail_sizes__small_jpeg__filename_operator"] | undefined | null | Variable<any, string>,
	sizes__medium_jpeg__url?: ValueTypes["Event_Thumbnail_sizes__medium_jpeg__url_operator"] | undefined | null | Variable<any, string>,
	sizes__medium_jpeg__width?: ValueTypes["Event_Thumbnail_sizes__medium_jpeg__width_operator"] | undefined | null | Variable<any, string>,
	sizes__medium_jpeg__height?: ValueTypes["Event_Thumbnail_sizes__medium_jpeg__height_operator"] | undefined | null | Variable<any, string>,
	sizes__medium_jpeg__mimeType?: ValueTypes["Event_Thumbnail_sizes__medium_jpeg__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__medium_jpeg__filesize?: ValueTypes["Event_Thumbnail_sizes__medium_jpeg__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__medium_jpeg__filename?: ValueTypes["Event_Thumbnail_sizes__medium_jpeg__filename_operator"] | undefined | null | Variable<any, string>,
	sizes__large_jpeg__url?: ValueTypes["Event_Thumbnail_sizes__large_jpeg__url_operator"] | undefined | null | Variable<any, string>,
	sizes__large_jpeg__width?: ValueTypes["Event_Thumbnail_sizes__large_jpeg__width_operator"] | undefined | null | Variable<any, string>,
	sizes__large_jpeg__height?: ValueTypes["Event_Thumbnail_sizes__large_jpeg__height_operator"] | undefined | null | Variable<any, string>,
	sizes__large_jpeg__mimeType?: ValueTypes["Event_Thumbnail_sizes__large_jpeg__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__large_jpeg__filesize?: ValueTypes["Event_Thumbnail_sizes__large_jpeg__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__large_jpeg__filename?: ValueTypes["Event_Thumbnail_sizes__large_jpeg__filename_operator"] | undefined | null | Variable<any, string>,
	id?: ValueTypes["Event_Thumbnail_id_operator"] | undefined | null | Variable<any, string>,
	AND?: Array<ValueTypes["Event_Thumbnail_where_and"] | undefined | null> | undefined | null | Variable<any, string>,
	OR?: Array<ValueTypes["Event_Thumbnail_where_or"] | undefined | null> | undefined | null | Variable<any, string>
};
	["Event_Banner_where"]: {
	alt?: ValueTypes["Event_Banner_alt_operator"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["Event_Banner_updatedAt_operator"] | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["Event_Banner_createdAt_operator"] | undefined | null | Variable<any, string>,
	url?: ValueTypes["Event_Banner_url_operator"] | undefined | null | Variable<any, string>,
	filename?: ValueTypes["Event_Banner_filename_operator"] | undefined | null | Variable<any, string>,
	mimeType?: ValueTypes["Event_Banner_mimeType_operator"] | undefined | null | Variable<any, string>,
	filesize?: ValueTypes["Event_Banner_filesize_operator"] | undefined | null | Variable<any, string>,
	width?: ValueTypes["Event_Banner_width_operator"] | undefined | null | Variable<any, string>,
	height?: ValueTypes["Event_Banner_height_operator"] | undefined | null | Variable<any, string>,
	focalX?: ValueTypes["Event_Banner_focalX_operator"] | undefined | null | Variable<any, string>,
	focalY?: ValueTypes["Event_Banner_focalY_operator"] | undefined | null | Variable<any, string>,
	sizes__small__url?: ValueTypes["Event_Banner_sizes__small__url_operator"] | undefined | null | Variable<any, string>,
	sizes__small__width?: ValueTypes["Event_Banner_sizes__small__width_operator"] | undefined | null | Variable<any, string>,
	sizes__small__height?: ValueTypes["Event_Banner_sizes__small__height_operator"] | undefined | null | Variable<any, string>,
	sizes__small__mimeType?: ValueTypes["Event_Banner_sizes__small__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__small__filesize?: ValueTypes["Event_Banner_sizes__small__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__small__filename?: ValueTypes["Event_Banner_sizes__small__filename_operator"] | undefined | null | Variable<any, string>,
	sizes__medium__url?: ValueTypes["Event_Banner_sizes__medium__url_operator"] | undefined | null | Variable<any, string>,
	sizes__medium__width?: ValueTypes["Event_Banner_sizes__medium__width_operator"] | undefined | null | Variable<any, string>,
	sizes__medium__height?: ValueTypes["Event_Banner_sizes__medium__height_operator"] | undefined | null | Variable<any, string>,
	sizes__medium__mimeType?: ValueTypes["Event_Banner_sizes__medium__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__medium__filesize?: ValueTypes["Event_Banner_sizes__medium__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__medium__filename?: ValueTypes["Event_Banner_sizes__medium__filename_operator"] | undefined | null | Variable<any, string>,
	sizes__large__url?: ValueTypes["Event_Banner_sizes__large__url_operator"] | undefined | null | Variable<any, string>,
	sizes__large__width?: ValueTypes["Event_Banner_sizes__large__width_operator"] | undefined | null | Variable<any, string>,
	sizes__large__height?: ValueTypes["Event_Banner_sizes__large__height_operator"] | undefined | null | Variable<any, string>,
	sizes__large__mimeType?: ValueTypes["Event_Banner_sizes__large__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__large__filesize?: ValueTypes["Event_Banner_sizes__large__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__large__filename?: ValueTypes["Event_Banner_sizes__large__filename_operator"] | undefined | null | Variable<any, string>,
	sizes__small_jpeg__url?: ValueTypes["Event_Banner_sizes__small_jpeg__url_operator"] | undefined | null | Variable<any, string>,
	sizes__small_jpeg__width?: ValueTypes["Event_Banner_sizes__small_jpeg__width_operator"] | undefined | null | Variable<any, string>,
	sizes__small_jpeg__height?: ValueTypes["Event_Banner_sizes__small_jpeg__height_operator"] | undefined | null | Variable<any, string>,
	sizes__small_jpeg__mimeType?: ValueTypes["Event_Banner_sizes__small_jpeg__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__small_jpeg__filesize?: ValueTypes["Event_Banner_sizes__small_jpeg__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__small_jpeg__filename?: ValueTypes["Event_Banner_sizes__small_jpeg__filename_operator"] | undefined | null | Variable<any, string>,
	sizes__medium_jpeg__url?: ValueTypes["Event_Banner_sizes__medium_jpeg__url_operator"] | undefined | null | Variable<any, string>,
	sizes__medium_jpeg__width?: ValueTypes["Event_Banner_sizes__medium_jpeg__width_operator"] | undefined | null | Variable<any, string>,
	sizes__medium_jpeg__height?: ValueTypes["Event_Banner_sizes__medium_jpeg__height_operator"] | undefined | null | Variable<any, string>,
	sizes__medium_jpeg__mimeType?: ValueTypes["Event_Banner_sizes__medium_jpeg__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__medium_jpeg__filesize?: ValueTypes["Event_Banner_sizes__medium_jpeg__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__medium_jpeg__filename?: ValueTypes["Event_Banner_sizes__medium_jpeg__filename_operator"] | undefined | null | Variable<any, string>,
	sizes__large_jpeg__url?: ValueTypes["Event_Banner_sizes__large_jpeg__url_operator"] | undefined | null | Variable<any, string>,
	sizes__large_jpeg__width?: ValueTypes["Event_Banner_sizes__large_jpeg__width_operator"] | undefined | null | Variable<any, string>,
	sizes__large_jpeg__height?: ValueTypes["Event_Banner_sizes__large_jpeg__height_operator"] | undefined | null | Variable<any, string>,
	sizes__large_jpeg__mimeType?: ValueTypes["Event_Banner_sizes__large_jpeg__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__large_jpeg__filesize?: ValueTypes["Event_Banner_sizes__large_jpeg__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__large_jpeg__filename?: ValueTypes["Event_Banner_sizes__large_jpeg__filename_operator"] | undefined | null | Variable<any, string>,
	id?: ValueTypes["Event_Banner_id_operator"] | undefined | null | Variable<any, string>,
	AND?: Array<ValueTypes["Event_Banner_where_and"] | undefined | null> | undefined | null | Variable<any, string>,
	OR?: Array<ValueTypes["Event_Banner_where_or"] | undefined | null> | undefined | null | Variable<any, string>
};
	["Event_Banner_alt_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Event_Banner_updatedAt_operator"]: {
	equals?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	not_equals?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	greater_than_equal?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	greater_than?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	less_than_equal?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	less_than?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	like?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Event_Banner_createdAt_operator"]: {
	equals?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	not_equals?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	greater_than_equal?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	greater_than?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	less_than_equal?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	less_than?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	like?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Event_Banner_url_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Event_Banner_filename_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Event_Banner_mimeType_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Event_Banner_filesize_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Event_Banner_width_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Event_Banner_height_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Event_Banner_focalX_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Event_Banner_focalY_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Event_Banner_sizes__small__url_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Event_Banner_sizes__small__width_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Event_Banner_sizes__small__height_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Event_Banner_sizes__small__mimeType_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Event_Banner_sizes__small__filesize_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Event_Banner_sizes__small__filename_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Event_Banner_sizes__medium__url_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Event_Banner_sizes__medium__width_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Event_Banner_sizes__medium__height_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Event_Banner_sizes__medium__mimeType_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Event_Banner_sizes__medium__filesize_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Event_Banner_sizes__medium__filename_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Event_Banner_sizes__large__url_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Event_Banner_sizes__large__width_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Event_Banner_sizes__large__height_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Event_Banner_sizes__large__mimeType_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Event_Banner_sizes__large__filesize_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Event_Banner_sizes__large__filename_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Event_Banner_sizes__small_jpeg__url_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Event_Banner_sizes__small_jpeg__width_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Event_Banner_sizes__small_jpeg__height_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Event_Banner_sizes__small_jpeg__mimeType_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Event_Banner_sizes__small_jpeg__filesize_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Event_Banner_sizes__small_jpeg__filename_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Event_Banner_sizes__medium_jpeg__url_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Event_Banner_sizes__medium_jpeg__width_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Event_Banner_sizes__medium_jpeg__height_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Event_Banner_sizes__medium_jpeg__mimeType_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Event_Banner_sizes__medium_jpeg__filesize_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Event_Banner_sizes__medium_jpeg__filename_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Event_Banner_sizes__large_jpeg__url_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Event_Banner_sizes__large_jpeg__width_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Event_Banner_sizes__large_jpeg__height_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Event_Banner_sizes__large_jpeg__mimeType_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Event_Banner_sizes__large_jpeg__filesize_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Event_Banner_sizes__large_jpeg__filename_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Event_Banner_id_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Event_Banner_where_and"]: {
	alt?: ValueTypes["Event_Banner_alt_operator"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["Event_Banner_updatedAt_operator"] | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["Event_Banner_createdAt_operator"] | undefined | null | Variable<any, string>,
	url?: ValueTypes["Event_Banner_url_operator"] | undefined | null | Variable<any, string>,
	filename?: ValueTypes["Event_Banner_filename_operator"] | undefined | null | Variable<any, string>,
	mimeType?: ValueTypes["Event_Banner_mimeType_operator"] | undefined | null | Variable<any, string>,
	filesize?: ValueTypes["Event_Banner_filesize_operator"] | undefined | null | Variable<any, string>,
	width?: ValueTypes["Event_Banner_width_operator"] | undefined | null | Variable<any, string>,
	height?: ValueTypes["Event_Banner_height_operator"] | undefined | null | Variable<any, string>,
	focalX?: ValueTypes["Event_Banner_focalX_operator"] | undefined | null | Variable<any, string>,
	focalY?: ValueTypes["Event_Banner_focalY_operator"] | undefined | null | Variable<any, string>,
	sizes__small__url?: ValueTypes["Event_Banner_sizes__small__url_operator"] | undefined | null | Variable<any, string>,
	sizes__small__width?: ValueTypes["Event_Banner_sizes__small__width_operator"] | undefined | null | Variable<any, string>,
	sizes__small__height?: ValueTypes["Event_Banner_sizes__small__height_operator"] | undefined | null | Variable<any, string>,
	sizes__small__mimeType?: ValueTypes["Event_Banner_sizes__small__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__small__filesize?: ValueTypes["Event_Banner_sizes__small__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__small__filename?: ValueTypes["Event_Banner_sizes__small__filename_operator"] | undefined | null | Variable<any, string>,
	sizes__medium__url?: ValueTypes["Event_Banner_sizes__medium__url_operator"] | undefined | null | Variable<any, string>,
	sizes__medium__width?: ValueTypes["Event_Banner_sizes__medium__width_operator"] | undefined | null | Variable<any, string>,
	sizes__medium__height?: ValueTypes["Event_Banner_sizes__medium__height_operator"] | undefined | null | Variable<any, string>,
	sizes__medium__mimeType?: ValueTypes["Event_Banner_sizes__medium__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__medium__filesize?: ValueTypes["Event_Banner_sizes__medium__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__medium__filename?: ValueTypes["Event_Banner_sizes__medium__filename_operator"] | undefined | null | Variable<any, string>,
	sizes__large__url?: ValueTypes["Event_Banner_sizes__large__url_operator"] | undefined | null | Variable<any, string>,
	sizes__large__width?: ValueTypes["Event_Banner_sizes__large__width_operator"] | undefined | null | Variable<any, string>,
	sizes__large__height?: ValueTypes["Event_Banner_sizes__large__height_operator"] | undefined | null | Variable<any, string>,
	sizes__large__mimeType?: ValueTypes["Event_Banner_sizes__large__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__large__filesize?: ValueTypes["Event_Banner_sizes__large__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__large__filename?: ValueTypes["Event_Banner_sizes__large__filename_operator"] | undefined | null | Variable<any, string>,
	sizes__small_jpeg__url?: ValueTypes["Event_Banner_sizes__small_jpeg__url_operator"] | undefined | null | Variable<any, string>,
	sizes__small_jpeg__width?: ValueTypes["Event_Banner_sizes__small_jpeg__width_operator"] | undefined | null | Variable<any, string>,
	sizes__small_jpeg__height?: ValueTypes["Event_Banner_sizes__small_jpeg__height_operator"] | undefined | null | Variable<any, string>,
	sizes__small_jpeg__mimeType?: ValueTypes["Event_Banner_sizes__small_jpeg__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__small_jpeg__filesize?: ValueTypes["Event_Banner_sizes__small_jpeg__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__small_jpeg__filename?: ValueTypes["Event_Banner_sizes__small_jpeg__filename_operator"] | undefined | null | Variable<any, string>,
	sizes__medium_jpeg__url?: ValueTypes["Event_Banner_sizes__medium_jpeg__url_operator"] | undefined | null | Variable<any, string>,
	sizes__medium_jpeg__width?: ValueTypes["Event_Banner_sizes__medium_jpeg__width_operator"] | undefined | null | Variable<any, string>,
	sizes__medium_jpeg__height?: ValueTypes["Event_Banner_sizes__medium_jpeg__height_operator"] | undefined | null | Variable<any, string>,
	sizes__medium_jpeg__mimeType?: ValueTypes["Event_Banner_sizes__medium_jpeg__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__medium_jpeg__filesize?: ValueTypes["Event_Banner_sizes__medium_jpeg__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__medium_jpeg__filename?: ValueTypes["Event_Banner_sizes__medium_jpeg__filename_operator"] | undefined | null | Variable<any, string>,
	sizes__large_jpeg__url?: ValueTypes["Event_Banner_sizes__large_jpeg__url_operator"] | undefined | null | Variable<any, string>,
	sizes__large_jpeg__width?: ValueTypes["Event_Banner_sizes__large_jpeg__width_operator"] | undefined | null | Variable<any, string>,
	sizes__large_jpeg__height?: ValueTypes["Event_Banner_sizes__large_jpeg__height_operator"] | undefined | null | Variable<any, string>,
	sizes__large_jpeg__mimeType?: ValueTypes["Event_Banner_sizes__large_jpeg__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__large_jpeg__filesize?: ValueTypes["Event_Banner_sizes__large_jpeg__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__large_jpeg__filename?: ValueTypes["Event_Banner_sizes__large_jpeg__filename_operator"] | undefined | null | Variable<any, string>,
	id?: ValueTypes["Event_Banner_id_operator"] | undefined | null | Variable<any, string>,
	AND?: Array<ValueTypes["Event_Banner_where_and"] | undefined | null> | undefined | null | Variable<any, string>,
	OR?: Array<ValueTypes["Event_Banner_where_or"] | undefined | null> | undefined | null | Variable<any, string>
};
	["Event_Banner_where_or"]: {
	alt?: ValueTypes["Event_Banner_alt_operator"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["Event_Banner_updatedAt_operator"] | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["Event_Banner_createdAt_operator"] | undefined | null | Variable<any, string>,
	url?: ValueTypes["Event_Banner_url_operator"] | undefined | null | Variable<any, string>,
	filename?: ValueTypes["Event_Banner_filename_operator"] | undefined | null | Variable<any, string>,
	mimeType?: ValueTypes["Event_Banner_mimeType_operator"] | undefined | null | Variable<any, string>,
	filesize?: ValueTypes["Event_Banner_filesize_operator"] | undefined | null | Variable<any, string>,
	width?: ValueTypes["Event_Banner_width_operator"] | undefined | null | Variable<any, string>,
	height?: ValueTypes["Event_Banner_height_operator"] | undefined | null | Variable<any, string>,
	focalX?: ValueTypes["Event_Banner_focalX_operator"] | undefined | null | Variable<any, string>,
	focalY?: ValueTypes["Event_Banner_focalY_operator"] | undefined | null | Variable<any, string>,
	sizes__small__url?: ValueTypes["Event_Banner_sizes__small__url_operator"] | undefined | null | Variable<any, string>,
	sizes__small__width?: ValueTypes["Event_Banner_sizes__small__width_operator"] | undefined | null | Variable<any, string>,
	sizes__small__height?: ValueTypes["Event_Banner_sizes__small__height_operator"] | undefined | null | Variable<any, string>,
	sizes__small__mimeType?: ValueTypes["Event_Banner_sizes__small__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__small__filesize?: ValueTypes["Event_Banner_sizes__small__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__small__filename?: ValueTypes["Event_Banner_sizes__small__filename_operator"] | undefined | null | Variable<any, string>,
	sizes__medium__url?: ValueTypes["Event_Banner_sizes__medium__url_operator"] | undefined | null | Variable<any, string>,
	sizes__medium__width?: ValueTypes["Event_Banner_sizes__medium__width_operator"] | undefined | null | Variable<any, string>,
	sizes__medium__height?: ValueTypes["Event_Banner_sizes__medium__height_operator"] | undefined | null | Variable<any, string>,
	sizes__medium__mimeType?: ValueTypes["Event_Banner_sizes__medium__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__medium__filesize?: ValueTypes["Event_Banner_sizes__medium__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__medium__filename?: ValueTypes["Event_Banner_sizes__medium__filename_operator"] | undefined | null | Variable<any, string>,
	sizes__large__url?: ValueTypes["Event_Banner_sizes__large__url_operator"] | undefined | null | Variable<any, string>,
	sizes__large__width?: ValueTypes["Event_Banner_sizes__large__width_operator"] | undefined | null | Variable<any, string>,
	sizes__large__height?: ValueTypes["Event_Banner_sizes__large__height_operator"] | undefined | null | Variable<any, string>,
	sizes__large__mimeType?: ValueTypes["Event_Banner_sizes__large__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__large__filesize?: ValueTypes["Event_Banner_sizes__large__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__large__filename?: ValueTypes["Event_Banner_sizes__large__filename_operator"] | undefined | null | Variable<any, string>,
	sizes__small_jpeg__url?: ValueTypes["Event_Banner_sizes__small_jpeg__url_operator"] | undefined | null | Variable<any, string>,
	sizes__small_jpeg__width?: ValueTypes["Event_Banner_sizes__small_jpeg__width_operator"] | undefined | null | Variable<any, string>,
	sizes__small_jpeg__height?: ValueTypes["Event_Banner_sizes__small_jpeg__height_operator"] | undefined | null | Variable<any, string>,
	sizes__small_jpeg__mimeType?: ValueTypes["Event_Banner_sizes__small_jpeg__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__small_jpeg__filesize?: ValueTypes["Event_Banner_sizes__small_jpeg__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__small_jpeg__filename?: ValueTypes["Event_Banner_sizes__small_jpeg__filename_operator"] | undefined | null | Variable<any, string>,
	sizes__medium_jpeg__url?: ValueTypes["Event_Banner_sizes__medium_jpeg__url_operator"] | undefined | null | Variable<any, string>,
	sizes__medium_jpeg__width?: ValueTypes["Event_Banner_sizes__medium_jpeg__width_operator"] | undefined | null | Variable<any, string>,
	sizes__medium_jpeg__height?: ValueTypes["Event_Banner_sizes__medium_jpeg__height_operator"] | undefined | null | Variable<any, string>,
	sizes__medium_jpeg__mimeType?: ValueTypes["Event_Banner_sizes__medium_jpeg__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__medium_jpeg__filesize?: ValueTypes["Event_Banner_sizes__medium_jpeg__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__medium_jpeg__filename?: ValueTypes["Event_Banner_sizes__medium_jpeg__filename_operator"] | undefined | null | Variable<any, string>,
	sizes__large_jpeg__url?: ValueTypes["Event_Banner_sizes__large_jpeg__url_operator"] | undefined | null | Variable<any, string>,
	sizes__large_jpeg__width?: ValueTypes["Event_Banner_sizes__large_jpeg__width_operator"] | undefined | null | Variable<any, string>,
	sizes__large_jpeg__height?: ValueTypes["Event_Banner_sizes__large_jpeg__height_operator"] | undefined | null | Variable<any, string>,
	sizes__large_jpeg__mimeType?: ValueTypes["Event_Banner_sizes__large_jpeg__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__large_jpeg__filesize?: ValueTypes["Event_Banner_sizes__large_jpeg__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__large_jpeg__filename?: ValueTypes["Event_Banner_sizes__large_jpeg__filename_operator"] | undefined | null | Variable<any, string>,
	id?: ValueTypes["Event_Banner_id_operator"] | undefined | null | Variable<any, string>,
	AND?: Array<ValueTypes["Event_Banner_where_and"] | undefined | null> | undefined | null | Variable<any, string>,
	OR?: Array<ValueTypes["Event_Banner_where_or"] | undefined | null> | undefined | null | Variable<any, string>
};
	["Event_MobileBanner_where"]: {
	alt?: ValueTypes["Event_MobileBanner_alt_operator"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["Event_MobileBanner_updatedAt_operator"] | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["Event_MobileBanner_createdAt_operator"] | undefined | null | Variable<any, string>,
	url?: ValueTypes["Event_MobileBanner_url_operator"] | undefined | null | Variable<any, string>,
	filename?: ValueTypes["Event_MobileBanner_filename_operator"] | undefined | null | Variable<any, string>,
	mimeType?: ValueTypes["Event_MobileBanner_mimeType_operator"] | undefined | null | Variable<any, string>,
	filesize?: ValueTypes["Event_MobileBanner_filesize_operator"] | undefined | null | Variable<any, string>,
	width?: ValueTypes["Event_MobileBanner_width_operator"] | undefined | null | Variable<any, string>,
	height?: ValueTypes["Event_MobileBanner_height_operator"] | undefined | null | Variable<any, string>,
	focalX?: ValueTypes["Event_MobileBanner_focalX_operator"] | undefined | null | Variable<any, string>,
	focalY?: ValueTypes["Event_MobileBanner_focalY_operator"] | undefined | null | Variable<any, string>,
	sizes__small__url?: ValueTypes["Event_MobileBanner_sizes__small__url_operator"] | undefined | null | Variable<any, string>,
	sizes__small__width?: ValueTypes["Event_MobileBanner_sizes__small__width_operator"] | undefined | null | Variable<any, string>,
	sizes__small__height?: ValueTypes["Event_MobileBanner_sizes__small__height_operator"] | undefined | null | Variable<any, string>,
	sizes__small__mimeType?: ValueTypes["Event_MobileBanner_sizes__small__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__small__filesize?: ValueTypes["Event_MobileBanner_sizes__small__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__small__filename?: ValueTypes["Event_MobileBanner_sizes__small__filename_operator"] | undefined | null | Variable<any, string>,
	sizes__medium__url?: ValueTypes["Event_MobileBanner_sizes__medium__url_operator"] | undefined | null | Variable<any, string>,
	sizes__medium__width?: ValueTypes["Event_MobileBanner_sizes__medium__width_operator"] | undefined | null | Variable<any, string>,
	sizes__medium__height?: ValueTypes["Event_MobileBanner_sizes__medium__height_operator"] | undefined | null | Variable<any, string>,
	sizes__medium__mimeType?: ValueTypes["Event_MobileBanner_sizes__medium__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__medium__filesize?: ValueTypes["Event_MobileBanner_sizes__medium__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__medium__filename?: ValueTypes["Event_MobileBanner_sizes__medium__filename_operator"] | undefined | null | Variable<any, string>,
	sizes__large__url?: ValueTypes["Event_MobileBanner_sizes__large__url_operator"] | undefined | null | Variable<any, string>,
	sizes__large__width?: ValueTypes["Event_MobileBanner_sizes__large__width_operator"] | undefined | null | Variable<any, string>,
	sizes__large__height?: ValueTypes["Event_MobileBanner_sizes__large__height_operator"] | undefined | null | Variable<any, string>,
	sizes__large__mimeType?: ValueTypes["Event_MobileBanner_sizes__large__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__large__filesize?: ValueTypes["Event_MobileBanner_sizes__large__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__large__filename?: ValueTypes["Event_MobileBanner_sizes__large__filename_operator"] | undefined | null | Variable<any, string>,
	sizes__small_jpeg__url?: ValueTypes["Event_MobileBanner_sizes__small_jpeg__url_operator"] | undefined | null | Variable<any, string>,
	sizes__small_jpeg__width?: ValueTypes["Event_MobileBanner_sizes__small_jpeg__width_operator"] | undefined | null | Variable<any, string>,
	sizes__small_jpeg__height?: ValueTypes["Event_MobileBanner_sizes__small_jpeg__height_operator"] | undefined | null | Variable<any, string>,
	sizes__small_jpeg__mimeType?: ValueTypes["Event_MobileBanner_sizes__small_jpeg__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__small_jpeg__filesize?: ValueTypes["Event_MobileBanner_sizes__small_jpeg__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__small_jpeg__filename?: ValueTypes["Event_MobileBanner_sizes__small_jpeg__filename_operator"] | undefined | null | Variable<any, string>,
	sizes__medium_jpeg__url?: ValueTypes["Event_MobileBanner_sizes__medium_jpeg__url_operator"] | undefined | null | Variable<any, string>,
	sizes__medium_jpeg__width?: ValueTypes["Event_MobileBanner_sizes__medium_jpeg__width_operator"] | undefined | null | Variable<any, string>,
	sizes__medium_jpeg__height?: ValueTypes["Event_MobileBanner_sizes__medium_jpeg__height_operator"] | undefined | null | Variable<any, string>,
	sizes__medium_jpeg__mimeType?: ValueTypes["Event_MobileBanner_sizes__medium_jpeg__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__medium_jpeg__filesize?: ValueTypes["Event_MobileBanner_sizes__medium_jpeg__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__medium_jpeg__filename?: ValueTypes["Event_MobileBanner_sizes__medium_jpeg__filename_operator"] | undefined | null | Variable<any, string>,
	sizes__large_jpeg__url?: ValueTypes["Event_MobileBanner_sizes__large_jpeg__url_operator"] | undefined | null | Variable<any, string>,
	sizes__large_jpeg__width?: ValueTypes["Event_MobileBanner_sizes__large_jpeg__width_operator"] | undefined | null | Variable<any, string>,
	sizes__large_jpeg__height?: ValueTypes["Event_MobileBanner_sizes__large_jpeg__height_operator"] | undefined | null | Variable<any, string>,
	sizes__large_jpeg__mimeType?: ValueTypes["Event_MobileBanner_sizes__large_jpeg__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__large_jpeg__filesize?: ValueTypes["Event_MobileBanner_sizes__large_jpeg__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__large_jpeg__filename?: ValueTypes["Event_MobileBanner_sizes__large_jpeg__filename_operator"] | undefined | null | Variable<any, string>,
	id?: ValueTypes["Event_MobileBanner_id_operator"] | undefined | null | Variable<any, string>,
	AND?: Array<ValueTypes["Event_MobileBanner_where_and"] | undefined | null> | undefined | null | Variable<any, string>,
	OR?: Array<ValueTypes["Event_MobileBanner_where_or"] | undefined | null> | undefined | null | Variable<any, string>
};
	["Event_MobileBanner_alt_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Event_MobileBanner_updatedAt_operator"]: {
	equals?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	not_equals?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	greater_than_equal?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	greater_than?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	less_than_equal?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	less_than?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	like?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Event_MobileBanner_createdAt_operator"]: {
	equals?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	not_equals?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	greater_than_equal?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	greater_than?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	less_than_equal?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	less_than?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	like?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Event_MobileBanner_url_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Event_MobileBanner_filename_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Event_MobileBanner_mimeType_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Event_MobileBanner_filesize_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Event_MobileBanner_width_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Event_MobileBanner_height_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Event_MobileBanner_focalX_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Event_MobileBanner_focalY_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Event_MobileBanner_sizes__small__url_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Event_MobileBanner_sizes__small__width_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Event_MobileBanner_sizes__small__height_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Event_MobileBanner_sizes__small__mimeType_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Event_MobileBanner_sizes__small__filesize_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Event_MobileBanner_sizes__small__filename_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Event_MobileBanner_sizes__medium__url_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Event_MobileBanner_sizes__medium__width_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Event_MobileBanner_sizes__medium__height_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Event_MobileBanner_sizes__medium__mimeType_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Event_MobileBanner_sizes__medium__filesize_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Event_MobileBanner_sizes__medium__filename_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Event_MobileBanner_sizes__large__url_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Event_MobileBanner_sizes__large__width_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Event_MobileBanner_sizes__large__height_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Event_MobileBanner_sizes__large__mimeType_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Event_MobileBanner_sizes__large__filesize_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Event_MobileBanner_sizes__large__filename_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Event_MobileBanner_sizes__small_jpeg__url_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Event_MobileBanner_sizes__small_jpeg__width_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Event_MobileBanner_sizes__small_jpeg__height_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Event_MobileBanner_sizes__small_jpeg__mimeType_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Event_MobileBanner_sizes__small_jpeg__filesize_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Event_MobileBanner_sizes__small_jpeg__filename_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Event_MobileBanner_sizes__medium_jpeg__url_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Event_MobileBanner_sizes__medium_jpeg__width_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Event_MobileBanner_sizes__medium_jpeg__height_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Event_MobileBanner_sizes__medium_jpeg__mimeType_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Event_MobileBanner_sizes__medium_jpeg__filesize_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Event_MobileBanner_sizes__medium_jpeg__filename_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Event_MobileBanner_sizes__large_jpeg__url_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Event_MobileBanner_sizes__large_jpeg__width_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Event_MobileBanner_sizes__large_jpeg__height_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Event_MobileBanner_sizes__large_jpeg__mimeType_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Event_MobileBanner_sizes__large_jpeg__filesize_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Event_MobileBanner_sizes__large_jpeg__filename_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Event_MobileBanner_id_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Event_MobileBanner_where_and"]: {
	alt?: ValueTypes["Event_MobileBanner_alt_operator"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["Event_MobileBanner_updatedAt_operator"] | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["Event_MobileBanner_createdAt_operator"] | undefined | null | Variable<any, string>,
	url?: ValueTypes["Event_MobileBanner_url_operator"] | undefined | null | Variable<any, string>,
	filename?: ValueTypes["Event_MobileBanner_filename_operator"] | undefined | null | Variable<any, string>,
	mimeType?: ValueTypes["Event_MobileBanner_mimeType_operator"] | undefined | null | Variable<any, string>,
	filesize?: ValueTypes["Event_MobileBanner_filesize_operator"] | undefined | null | Variable<any, string>,
	width?: ValueTypes["Event_MobileBanner_width_operator"] | undefined | null | Variable<any, string>,
	height?: ValueTypes["Event_MobileBanner_height_operator"] | undefined | null | Variable<any, string>,
	focalX?: ValueTypes["Event_MobileBanner_focalX_operator"] | undefined | null | Variable<any, string>,
	focalY?: ValueTypes["Event_MobileBanner_focalY_operator"] | undefined | null | Variable<any, string>,
	sizes__small__url?: ValueTypes["Event_MobileBanner_sizes__small__url_operator"] | undefined | null | Variable<any, string>,
	sizes__small__width?: ValueTypes["Event_MobileBanner_sizes__small__width_operator"] | undefined | null | Variable<any, string>,
	sizes__small__height?: ValueTypes["Event_MobileBanner_sizes__small__height_operator"] | undefined | null | Variable<any, string>,
	sizes__small__mimeType?: ValueTypes["Event_MobileBanner_sizes__small__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__small__filesize?: ValueTypes["Event_MobileBanner_sizes__small__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__small__filename?: ValueTypes["Event_MobileBanner_sizes__small__filename_operator"] | undefined | null | Variable<any, string>,
	sizes__medium__url?: ValueTypes["Event_MobileBanner_sizes__medium__url_operator"] | undefined | null | Variable<any, string>,
	sizes__medium__width?: ValueTypes["Event_MobileBanner_sizes__medium__width_operator"] | undefined | null | Variable<any, string>,
	sizes__medium__height?: ValueTypes["Event_MobileBanner_sizes__medium__height_operator"] | undefined | null | Variable<any, string>,
	sizes__medium__mimeType?: ValueTypes["Event_MobileBanner_sizes__medium__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__medium__filesize?: ValueTypes["Event_MobileBanner_sizes__medium__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__medium__filename?: ValueTypes["Event_MobileBanner_sizes__medium__filename_operator"] | undefined | null | Variable<any, string>,
	sizes__large__url?: ValueTypes["Event_MobileBanner_sizes__large__url_operator"] | undefined | null | Variable<any, string>,
	sizes__large__width?: ValueTypes["Event_MobileBanner_sizes__large__width_operator"] | undefined | null | Variable<any, string>,
	sizes__large__height?: ValueTypes["Event_MobileBanner_sizes__large__height_operator"] | undefined | null | Variable<any, string>,
	sizes__large__mimeType?: ValueTypes["Event_MobileBanner_sizes__large__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__large__filesize?: ValueTypes["Event_MobileBanner_sizes__large__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__large__filename?: ValueTypes["Event_MobileBanner_sizes__large__filename_operator"] | undefined | null | Variable<any, string>,
	sizes__small_jpeg__url?: ValueTypes["Event_MobileBanner_sizes__small_jpeg__url_operator"] | undefined | null | Variable<any, string>,
	sizes__small_jpeg__width?: ValueTypes["Event_MobileBanner_sizes__small_jpeg__width_operator"] | undefined | null | Variable<any, string>,
	sizes__small_jpeg__height?: ValueTypes["Event_MobileBanner_sizes__small_jpeg__height_operator"] | undefined | null | Variable<any, string>,
	sizes__small_jpeg__mimeType?: ValueTypes["Event_MobileBanner_sizes__small_jpeg__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__small_jpeg__filesize?: ValueTypes["Event_MobileBanner_sizes__small_jpeg__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__small_jpeg__filename?: ValueTypes["Event_MobileBanner_sizes__small_jpeg__filename_operator"] | undefined | null | Variable<any, string>,
	sizes__medium_jpeg__url?: ValueTypes["Event_MobileBanner_sizes__medium_jpeg__url_operator"] | undefined | null | Variable<any, string>,
	sizes__medium_jpeg__width?: ValueTypes["Event_MobileBanner_sizes__medium_jpeg__width_operator"] | undefined | null | Variable<any, string>,
	sizes__medium_jpeg__height?: ValueTypes["Event_MobileBanner_sizes__medium_jpeg__height_operator"] | undefined | null | Variable<any, string>,
	sizes__medium_jpeg__mimeType?: ValueTypes["Event_MobileBanner_sizes__medium_jpeg__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__medium_jpeg__filesize?: ValueTypes["Event_MobileBanner_sizes__medium_jpeg__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__medium_jpeg__filename?: ValueTypes["Event_MobileBanner_sizes__medium_jpeg__filename_operator"] | undefined | null | Variable<any, string>,
	sizes__large_jpeg__url?: ValueTypes["Event_MobileBanner_sizes__large_jpeg__url_operator"] | undefined | null | Variable<any, string>,
	sizes__large_jpeg__width?: ValueTypes["Event_MobileBanner_sizes__large_jpeg__width_operator"] | undefined | null | Variable<any, string>,
	sizes__large_jpeg__height?: ValueTypes["Event_MobileBanner_sizes__large_jpeg__height_operator"] | undefined | null | Variable<any, string>,
	sizes__large_jpeg__mimeType?: ValueTypes["Event_MobileBanner_sizes__large_jpeg__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__large_jpeg__filesize?: ValueTypes["Event_MobileBanner_sizes__large_jpeg__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__large_jpeg__filename?: ValueTypes["Event_MobileBanner_sizes__large_jpeg__filename_operator"] | undefined | null | Variable<any, string>,
	id?: ValueTypes["Event_MobileBanner_id_operator"] | undefined | null | Variable<any, string>,
	AND?: Array<ValueTypes["Event_MobileBanner_where_and"] | undefined | null> | undefined | null | Variable<any, string>,
	OR?: Array<ValueTypes["Event_MobileBanner_where_or"] | undefined | null> | undefined | null | Variable<any, string>
};
	["Event_MobileBanner_where_or"]: {
	alt?: ValueTypes["Event_MobileBanner_alt_operator"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["Event_MobileBanner_updatedAt_operator"] | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["Event_MobileBanner_createdAt_operator"] | undefined | null | Variable<any, string>,
	url?: ValueTypes["Event_MobileBanner_url_operator"] | undefined | null | Variable<any, string>,
	filename?: ValueTypes["Event_MobileBanner_filename_operator"] | undefined | null | Variable<any, string>,
	mimeType?: ValueTypes["Event_MobileBanner_mimeType_operator"] | undefined | null | Variable<any, string>,
	filesize?: ValueTypes["Event_MobileBanner_filesize_operator"] | undefined | null | Variable<any, string>,
	width?: ValueTypes["Event_MobileBanner_width_operator"] | undefined | null | Variable<any, string>,
	height?: ValueTypes["Event_MobileBanner_height_operator"] | undefined | null | Variable<any, string>,
	focalX?: ValueTypes["Event_MobileBanner_focalX_operator"] | undefined | null | Variable<any, string>,
	focalY?: ValueTypes["Event_MobileBanner_focalY_operator"] | undefined | null | Variable<any, string>,
	sizes__small__url?: ValueTypes["Event_MobileBanner_sizes__small__url_operator"] | undefined | null | Variable<any, string>,
	sizes__small__width?: ValueTypes["Event_MobileBanner_sizes__small__width_operator"] | undefined | null | Variable<any, string>,
	sizes__small__height?: ValueTypes["Event_MobileBanner_sizes__small__height_operator"] | undefined | null | Variable<any, string>,
	sizes__small__mimeType?: ValueTypes["Event_MobileBanner_sizes__small__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__small__filesize?: ValueTypes["Event_MobileBanner_sizes__small__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__small__filename?: ValueTypes["Event_MobileBanner_sizes__small__filename_operator"] | undefined | null | Variable<any, string>,
	sizes__medium__url?: ValueTypes["Event_MobileBanner_sizes__medium__url_operator"] | undefined | null | Variable<any, string>,
	sizes__medium__width?: ValueTypes["Event_MobileBanner_sizes__medium__width_operator"] | undefined | null | Variable<any, string>,
	sizes__medium__height?: ValueTypes["Event_MobileBanner_sizes__medium__height_operator"] | undefined | null | Variable<any, string>,
	sizes__medium__mimeType?: ValueTypes["Event_MobileBanner_sizes__medium__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__medium__filesize?: ValueTypes["Event_MobileBanner_sizes__medium__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__medium__filename?: ValueTypes["Event_MobileBanner_sizes__medium__filename_operator"] | undefined | null | Variable<any, string>,
	sizes__large__url?: ValueTypes["Event_MobileBanner_sizes__large__url_operator"] | undefined | null | Variable<any, string>,
	sizes__large__width?: ValueTypes["Event_MobileBanner_sizes__large__width_operator"] | undefined | null | Variable<any, string>,
	sizes__large__height?: ValueTypes["Event_MobileBanner_sizes__large__height_operator"] | undefined | null | Variable<any, string>,
	sizes__large__mimeType?: ValueTypes["Event_MobileBanner_sizes__large__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__large__filesize?: ValueTypes["Event_MobileBanner_sizes__large__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__large__filename?: ValueTypes["Event_MobileBanner_sizes__large__filename_operator"] | undefined | null | Variable<any, string>,
	sizes__small_jpeg__url?: ValueTypes["Event_MobileBanner_sizes__small_jpeg__url_operator"] | undefined | null | Variable<any, string>,
	sizes__small_jpeg__width?: ValueTypes["Event_MobileBanner_sizes__small_jpeg__width_operator"] | undefined | null | Variable<any, string>,
	sizes__small_jpeg__height?: ValueTypes["Event_MobileBanner_sizes__small_jpeg__height_operator"] | undefined | null | Variable<any, string>,
	sizes__small_jpeg__mimeType?: ValueTypes["Event_MobileBanner_sizes__small_jpeg__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__small_jpeg__filesize?: ValueTypes["Event_MobileBanner_sizes__small_jpeg__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__small_jpeg__filename?: ValueTypes["Event_MobileBanner_sizes__small_jpeg__filename_operator"] | undefined | null | Variable<any, string>,
	sizes__medium_jpeg__url?: ValueTypes["Event_MobileBanner_sizes__medium_jpeg__url_operator"] | undefined | null | Variable<any, string>,
	sizes__medium_jpeg__width?: ValueTypes["Event_MobileBanner_sizes__medium_jpeg__width_operator"] | undefined | null | Variable<any, string>,
	sizes__medium_jpeg__height?: ValueTypes["Event_MobileBanner_sizes__medium_jpeg__height_operator"] | undefined | null | Variable<any, string>,
	sizes__medium_jpeg__mimeType?: ValueTypes["Event_MobileBanner_sizes__medium_jpeg__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__medium_jpeg__filesize?: ValueTypes["Event_MobileBanner_sizes__medium_jpeg__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__medium_jpeg__filename?: ValueTypes["Event_MobileBanner_sizes__medium_jpeg__filename_operator"] | undefined | null | Variable<any, string>,
	sizes__large_jpeg__url?: ValueTypes["Event_MobileBanner_sizes__large_jpeg__url_operator"] | undefined | null | Variable<any, string>,
	sizes__large_jpeg__width?: ValueTypes["Event_MobileBanner_sizes__large_jpeg__width_operator"] | undefined | null | Variable<any, string>,
	sizes__large_jpeg__height?: ValueTypes["Event_MobileBanner_sizes__large_jpeg__height_operator"] | undefined | null | Variable<any, string>,
	sizes__large_jpeg__mimeType?: ValueTypes["Event_MobileBanner_sizes__large_jpeg__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__large_jpeg__filesize?: ValueTypes["Event_MobileBanner_sizes__large_jpeg__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__large_jpeg__filename?: ValueTypes["Event_MobileBanner_sizes__large_jpeg__filename_operator"] | undefined | null | Variable<any, string>,
	id?: ValueTypes["Event_MobileBanner_id_operator"] | undefined | null | Variable<any, string>,
	AND?: Array<ValueTypes["Event_MobileBanner_where_and"] | undefined | null> | undefined | null | Variable<any, string>,
	OR?: Array<ValueTypes["Event_MobileBanner_where_or"] | undefined | null> | undefined | null | Variable<any, string>
};
	["Event_Sections"]: AliasType<{
	startDatetime?:boolean | `@${string}`,
	endDatetime?:boolean | `@${string}`,
	fullDay?:boolean | `@${string}`,
	repeat?:boolean | `@${string}`,
	toThisDay?:boolean | `@${string}`,
	recurrance?:ValueTypes["Event_Sections_Recurrance"],
	sameLocation?:boolean | `@${string}`,
location?: [{	locale?: ValueTypes["LocaleInputType"] | undefined | null | Variable<any, string>,	fallbackLocale?: ValueTypes["FallbackLocaleInputType"] | undefined | null | Variable<any, string>},ValueTypes["Location"]],
district?: [{	locale?: ValueTypes["LocaleInputType"] | undefined | null | Variable<any, string>,	fallbackLocale?: ValueTypes["FallbackLocaleInputType"] | undefined | null | Variable<any, string>},ValueTypes["District"]],
	coordinate?:boolean | `@${string}`,
	linkAddress?:boolean | `@${string}`,
	address?:boolean | `@${string}`,
	addressCoordinate?:boolean | `@${string}`,
	id?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["Event_Sections_Recurrance"]: AliasType<{
	type?:boolean | `@${string}`,
	weekday?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["Event_Sections_Recurrance_type"]:Event_Sections_Recurrance_type;
	["Event_Sections_Recurrance_weekday"]:Event_Sections_Recurrance_weekday;
	["Location"]: AliasType<{
	id?:boolean | `@${string}`,
	region?:boolean | `@${string}`,
creator?: [{	locale?: ValueTypes["LocaleInputType"] | undefined | null | Variable<any, string>,	fallbackLocale?: ValueTypes["FallbackLocaleInputType"] | undefined | null | Variable<any, string>},ValueTypes["User"]],
	permalink?:boolean | `@${string}`,
	status?:boolean | `@${string}`,
	level?:boolean | `@${string}`,
parent?: [{	locale?: ValueTypes["LocaleInputType"] | undefined | null | Variable<any, string>,	fallbackLocale?: ValueTypes["FallbackLocaleInputType"] | undefined | null | Variable<any, string>},ValueTypes["Location"]],
	name?:boolean | `@${string}`,
thumbnail?: [{	locale?: ValueTypes["LocaleInputType"] | undefined | null | Variable<any, string>,	fallbackLocale?: ValueTypes["FallbackLocaleInputType"] | undefined | null | Variable<any, string>,	where?: ValueTypes["Location_Thumbnail_where"] | undefined | null | Variable<any, string>},ValueTypes["Media"]],
banner?: [{	locale?: ValueTypes["LocaleInputType"] | undefined | null | Variable<any, string>,	fallbackLocale?: ValueTypes["FallbackLocaleInputType"] | undefined | null | Variable<any, string>,	where?: ValueTypes["Location_Banner_where"] | undefined | null | Variable<any, string>},ValueTypes["Media"]],
	type?:boolean | `@${string}`,
	category?:boolean | `@${string}`,
district?: [{	locale?: ValueTypes["LocaleInputType"] | undefined | null | Variable<any, string>,	fallbackLocale?: ValueTypes["FallbackLocaleInputType"] | undefined | null | Variable<any, string>},ValueTypes["District"]],
	coordinate?:boolean | `@${string}`,
	linkAddress?:boolean | `@${string}`,
	address?:boolean | `@${string}`,
	addressCoordinate?:boolean | `@${string}`,
	permanant?:boolean | `@${string}`,
	Details?:ValueTypes["Location_Details"],
	content?:ValueTypes["Location_Content"],
	legacyContent?:boolean | `@${string}`,
	showLegacyContent?:boolean | `@${string}`,
	criteria?:ValueTypes["Location_Criteria"],
	sections?:ValueTypes["Location_Sections"],
	views?:boolean | `@${string}`,
	_title?:boolean | `@${string}`,
legacyGallery?: [{	locale?: ValueTypes["LocaleInputType"] | undefined | null | Variable<any, string>,	fallbackLocale?: ValueTypes["FallbackLocaleInputType"] | undefined | null | Variable<any, string>},ValueTypes["Media"]],
	updatedAt?:boolean | `@${string}`,
	createdAt?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["Location_region"]:Location_region;
	["Location_level"]:Location_level;
	["Location_Thumbnail_where"]: {
	alt?: ValueTypes["Location_Thumbnail_alt_operator"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["Location_Thumbnail_updatedAt_operator"] | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["Location_Thumbnail_createdAt_operator"] | undefined | null | Variable<any, string>,
	url?: ValueTypes["Location_Thumbnail_url_operator"] | undefined | null | Variable<any, string>,
	filename?: ValueTypes["Location_Thumbnail_filename_operator"] | undefined | null | Variable<any, string>,
	mimeType?: ValueTypes["Location_Thumbnail_mimeType_operator"] | undefined | null | Variable<any, string>,
	filesize?: ValueTypes["Location_Thumbnail_filesize_operator"] | undefined | null | Variable<any, string>,
	width?: ValueTypes["Location_Thumbnail_width_operator"] | undefined | null | Variable<any, string>,
	height?: ValueTypes["Location_Thumbnail_height_operator"] | undefined | null | Variable<any, string>,
	focalX?: ValueTypes["Location_Thumbnail_focalX_operator"] | undefined | null | Variable<any, string>,
	focalY?: ValueTypes["Location_Thumbnail_focalY_operator"] | undefined | null | Variable<any, string>,
	sizes__small__url?: ValueTypes["Location_Thumbnail_sizes__small__url_operator"] | undefined | null | Variable<any, string>,
	sizes__small__width?: ValueTypes["Location_Thumbnail_sizes__small__width_operator"] | undefined | null | Variable<any, string>,
	sizes__small__height?: ValueTypes["Location_Thumbnail_sizes__small__height_operator"] | undefined | null | Variable<any, string>,
	sizes__small__mimeType?: ValueTypes["Location_Thumbnail_sizes__small__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__small__filesize?: ValueTypes["Location_Thumbnail_sizes__small__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__small__filename?: ValueTypes["Location_Thumbnail_sizes__small__filename_operator"] | undefined | null | Variable<any, string>,
	sizes__medium__url?: ValueTypes["Location_Thumbnail_sizes__medium__url_operator"] | undefined | null | Variable<any, string>,
	sizes__medium__width?: ValueTypes["Location_Thumbnail_sizes__medium__width_operator"] | undefined | null | Variable<any, string>,
	sizes__medium__height?: ValueTypes["Location_Thumbnail_sizes__medium__height_operator"] | undefined | null | Variable<any, string>,
	sizes__medium__mimeType?: ValueTypes["Location_Thumbnail_sizes__medium__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__medium__filesize?: ValueTypes["Location_Thumbnail_sizes__medium__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__medium__filename?: ValueTypes["Location_Thumbnail_sizes__medium__filename_operator"] | undefined | null | Variable<any, string>,
	sizes__large__url?: ValueTypes["Location_Thumbnail_sizes__large__url_operator"] | undefined | null | Variable<any, string>,
	sizes__large__width?: ValueTypes["Location_Thumbnail_sizes__large__width_operator"] | undefined | null | Variable<any, string>,
	sizes__large__height?: ValueTypes["Location_Thumbnail_sizes__large__height_operator"] | undefined | null | Variable<any, string>,
	sizes__large__mimeType?: ValueTypes["Location_Thumbnail_sizes__large__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__large__filesize?: ValueTypes["Location_Thumbnail_sizes__large__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__large__filename?: ValueTypes["Location_Thumbnail_sizes__large__filename_operator"] | undefined | null | Variable<any, string>,
	sizes__small_jpeg__url?: ValueTypes["Location_Thumbnail_sizes__small_jpeg__url_operator"] | undefined | null | Variable<any, string>,
	sizes__small_jpeg__width?: ValueTypes["Location_Thumbnail_sizes__small_jpeg__width_operator"] | undefined | null | Variable<any, string>,
	sizes__small_jpeg__height?: ValueTypes["Location_Thumbnail_sizes__small_jpeg__height_operator"] | undefined | null | Variable<any, string>,
	sizes__small_jpeg__mimeType?: ValueTypes["Location_Thumbnail_sizes__small_jpeg__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__small_jpeg__filesize?: ValueTypes["Location_Thumbnail_sizes__small_jpeg__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__small_jpeg__filename?: ValueTypes["Location_Thumbnail_sizes__small_jpeg__filename_operator"] | undefined | null | Variable<any, string>,
	sizes__medium_jpeg__url?: ValueTypes["Location_Thumbnail_sizes__medium_jpeg__url_operator"] | undefined | null | Variable<any, string>,
	sizes__medium_jpeg__width?: ValueTypes["Location_Thumbnail_sizes__medium_jpeg__width_operator"] | undefined | null | Variable<any, string>,
	sizes__medium_jpeg__height?: ValueTypes["Location_Thumbnail_sizes__medium_jpeg__height_operator"] | undefined | null | Variable<any, string>,
	sizes__medium_jpeg__mimeType?: ValueTypes["Location_Thumbnail_sizes__medium_jpeg__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__medium_jpeg__filesize?: ValueTypes["Location_Thumbnail_sizes__medium_jpeg__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__medium_jpeg__filename?: ValueTypes["Location_Thumbnail_sizes__medium_jpeg__filename_operator"] | undefined | null | Variable<any, string>,
	sizes__large_jpeg__url?: ValueTypes["Location_Thumbnail_sizes__large_jpeg__url_operator"] | undefined | null | Variable<any, string>,
	sizes__large_jpeg__width?: ValueTypes["Location_Thumbnail_sizes__large_jpeg__width_operator"] | undefined | null | Variable<any, string>,
	sizes__large_jpeg__height?: ValueTypes["Location_Thumbnail_sizes__large_jpeg__height_operator"] | undefined | null | Variable<any, string>,
	sizes__large_jpeg__mimeType?: ValueTypes["Location_Thumbnail_sizes__large_jpeg__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__large_jpeg__filesize?: ValueTypes["Location_Thumbnail_sizes__large_jpeg__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__large_jpeg__filename?: ValueTypes["Location_Thumbnail_sizes__large_jpeg__filename_operator"] | undefined | null | Variable<any, string>,
	id?: ValueTypes["Location_Thumbnail_id_operator"] | undefined | null | Variable<any, string>,
	AND?: Array<ValueTypes["Location_Thumbnail_where_and"] | undefined | null> | undefined | null | Variable<any, string>,
	OR?: Array<ValueTypes["Location_Thumbnail_where_or"] | undefined | null> | undefined | null | Variable<any, string>
};
	["Location_Thumbnail_alt_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Location_Thumbnail_updatedAt_operator"]: {
	equals?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	not_equals?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	greater_than_equal?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	greater_than?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	less_than_equal?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	less_than?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	like?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Location_Thumbnail_createdAt_operator"]: {
	equals?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	not_equals?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	greater_than_equal?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	greater_than?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	less_than_equal?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	less_than?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	like?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Location_Thumbnail_url_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Location_Thumbnail_filename_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Location_Thumbnail_mimeType_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Location_Thumbnail_filesize_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Location_Thumbnail_width_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Location_Thumbnail_height_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Location_Thumbnail_focalX_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Location_Thumbnail_focalY_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Location_Thumbnail_sizes__small__url_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Location_Thumbnail_sizes__small__width_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Location_Thumbnail_sizes__small__height_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Location_Thumbnail_sizes__small__mimeType_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Location_Thumbnail_sizes__small__filesize_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Location_Thumbnail_sizes__small__filename_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Location_Thumbnail_sizes__medium__url_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Location_Thumbnail_sizes__medium__width_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Location_Thumbnail_sizes__medium__height_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Location_Thumbnail_sizes__medium__mimeType_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Location_Thumbnail_sizes__medium__filesize_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Location_Thumbnail_sizes__medium__filename_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Location_Thumbnail_sizes__large__url_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Location_Thumbnail_sizes__large__width_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Location_Thumbnail_sizes__large__height_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Location_Thumbnail_sizes__large__mimeType_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Location_Thumbnail_sizes__large__filesize_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Location_Thumbnail_sizes__large__filename_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Location_Thumbnail_sizes__small_jpeg__url_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Location_Thumbnail_sizes__small_jpeg__width_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Location_Thumbnail_sizes__small_jpeg__height_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Location_Thumbnail_sizes__small_jpeg__mimeType_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Location_Thumbnail_sizes__small_jpeg__filesize_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Location_Thumbnail_sizes__small_jpeg__filename_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Location_Thumbnail_sizes__medium_jpeg__url_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Location_Thumbnail_sizes__medium_jpeg__width_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Location_Thumbnail_sizes__medium_jpeg__height_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Location_Thumbnail_sizes__medium_jpeg__mimeType_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Location_Thumbnail_sizes__medium_jpeg__filesize_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Location_Thumbnail_sizes__medium_jpeg__filename_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Location_Thumbnail_sizes__large_jpeg__url_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Location_Thumbnail_sizes__large_jpeg__width_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Location_Thumbnail_sizes__large_jpeg__height_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Location_Thumbnail_sizes__large_jpeg__mimeType_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Location_Thumbnail_sizes__large_jpeg__filesize_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Location_Thumbnail_sizes__large_jpeg__filename_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Location_Thumbnail_id_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Location_Thumbnail_where_and"]: {
	alt?: ValueTypes["Location_Thumbnail_alt_operator"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["Location_Thumbnail_updatedAt_operator"] | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["Location_Thumbnail_createdAt_operator"] | undefined | null | Variable<any, string>,
	url?: ValueTypes["Location_Thumbnail_url_operator"] | undefined | null | Variable<any, string>,
	filename?: ValueTypes["Location_Thumbnail_filename_operator"] | undefined | null | Variable<any, string>,
	mimeType?: ValueTypes["Location_Thumbnail_mimeType_operator"] | undefined | null | Variable<any, string>,
	filesize?: ValueTypes["Location_Thumbnail_filesize_operator"] | undefined | null | Variable<any, string>,
	width?: ValueTypes["Location_Thumbnail_width_operator"] | undefined | null | Variable<any, string>,
	height?: ValueTypes["Location_Thumbnail_height_operator"] | undefined | null | Variable<any, string>,
	focalX?: ValueTypes["Location_Thumbnail_focalX_operator"] | undefined | null | Variable<any, string>,
	focalY?: ValueTypes["Location_Thumbnail_focalY_operator"] | undefined | null | Variable<any, string>,
	sizes__small__url?: ValueTypes["Location_Thumbnail_sizes__small__url_operator"] | undefined | null | Variable<any, string>,
	sizes__small__width?: ValueTypes["Location_Thumbnail_sizes__small__width_operator"] | undefined | null | Variable<any, string>,
	sizes__small__height?: ValueTypes["Location_Thumbnail_sizes__small__height_operator"] | undefined | null | Variable<any, string>,
	sizes__small__mimeType?: ValueTypes["Location_Thumbnail_sizes__small__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__small__filesize?: ValueTypes["Location_Thumbnail_sizes__small__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__small__filename?: ValueTypes["Location_Thumbnail_sizes__small__filename_operator"] | undefined | null | Variable<any, string>,
	sizes__medium__url?: ValueTypes["Location_Thumbnail_sizes__medium__url_operator"] | undefined | null | Variable<any, string>,
	sizes__medium__width?: ValueTypes["Location_Thumbnail_sizes__medium__width_operator"] | undefined | null | Variable<any, string>,
	sizes__medium__height?: ValueTypes["Location_Thumbnail_sizes__medium__height_operator"] | undefined | null | Variable<any, string>,
	sizes__medium__mimeType?: ValueTypes["Location_Thumbnail_sizes__medium__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__medium__filesize?: ValueTypes["Location_Thumbnail_sizes__medium__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__medium__filename?: ValueTypes["Location_Thumbnail_sizes__medium__filename_operator"] | undefined | null | Variable<any, string>,
	sizes__large__url?: ValueTypes["Location_Thumbnail_sizes__large__url_operator"] | undefined | null | Variable<any, string>,
	sizes__large__width?: ValueTypes["Location_Thumbnail_sizes__large__width_operator"] | undefined | null | Variable<any, string>,
	sizes__large__height?: ValueTypes["Location_Thumbnail_sizes__large__height_operator"] | undefined | null | Variable<any, string>,
	sizes__large__mimeType?: ValueTypes["Location_Thumbnail_sizes__large__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__large__filesize?: ValueTypes["Location_Thumbnail_sizes__large__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__large__filename?: ValueTypes["Location_Thumbnail_sizes__large__filename_operator"] | undefined | null | Variable<any, string>,
	sizes__small_jpeg__url?: ValueTypes["Location_Thumbnail_sizes__small_jpeg__url_operator"] | undefined | null | Variable<any, string>,
	sizes__small_jpeg__width?: ValueTypes["Location_Thumbnail_sizes__small_jpeg__width_operator"] | undefined | null | Variable<any, string>,
	sizes__small_jpeg__height?: ValueTypes["Location_Thumbnail_sizes__small_jpeg__height_operator"] | undefined | null | Variable<any, string>,
	sizes__small_jpeg__mimeType?: ValueTypes["Location_Thumbnail_sizes__small_jpeg__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__small_jpeg__filesize?: ValueTypes["Location_Thumbnail_sizes__small_jpeg__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__small_jpeg__filename?: ValueTypes["Location_Thumbnail_sizes__small_jpeg__filename_operator"] | undefined | null | Variable<any, string>,
	sizes__medium_jpeg__url?: ValueTypes["Location_Thumbnail_sizes__medium_jpeg__url_operator"] | undefined | null | Variable<any, string>,
	sizes__medium_jpeg__width?: ValueTypes["Location_Thumbnail_sizes__medium_jpeg__width_operator"] | undefined | null | Variable<any, string>,
	sizes__medium_jpeg__height?: ValueTypes["Location_Thumbnail_sizes__medium_jpeg__height_operator"] | undefined | null | Variable<any, string>,
	sizes__medium_jpeg__mimeType?: ValueTypes["Location_Thumbnail_sizes__medium_jpeg__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__medium_jpeg__filesize?: ValueTypes["Location_Thumbnail_sizes__medium_jpeg__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__medium_jpeg__filename?: ValueTypes["Location_Thumbnail_sizes__medium_jpeg__filename_operator"] | undefined | null | Variable<any, string>,
	sizes__large_jpeg__url?: ValueTypes["Location_Thumbnail_sizes__large_jpeg__url_operator"] | undefined | null | Variable<any, string>,
	sizes__large_jpeg__width?: ValueTypes["Location_Thumbnail_sizes__large_jpeg__width_operator"] | undefined | null | Variable<any, string>,
	sizes__large_jpeg__height?: ValueTypes["Location_Thumbnail_sizes__large_jpeg__height_operator"] | undefined | null | Variable<any, string>,
	sizes__large_jpeg__mimeType?: ValueTypes["Location_Thumbnail_sizes__large_jpeg__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__large_jpeg__filesize?: ValueTypes["Location_Thumbnail_sizes__large_jpeg__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__large_jpeg__filename?: ValueTypes["Location_Thumbnail_sizes__large_jpeg__filename_operator"] | undefined | null | Variable<any, string>,
	id?: ValueTypes["Location_Thumbnail_id_operator"] | undefined | null | Variable<any, string>,
	AND?: Array<ValueTypes["Location_Thumbnail_where_and"] | undefined | null> | undefined | null | Variable<any, string>,
	OR?: Array<ValueTypes["Location_Thumbnail_where_or"] | undefined | null> | undefined | null | Variable<any, string>
};
	["Location_Thumbnail_where_or"]: {
	alt?: ValueTypes["Location_Thumbnail_alt_operator"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["Location_Thumbnail_updatedAt_operator"] | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["Location_Thumbnail_createdAt_operator"] | undefined | null | Variable<any, string>,
	url?: ValueTypes["Location_Thumbnail_url_operator"] | undefined | null | Variable<any, string>,
	filename?: ValueTypes["Location_Thumbnail_filename_operator"] | undefined | null | Variable<any, string>,
	mimeType?: ValueTypes["Location_Thumbnail_mimeType_operator"] | undefined | null | Variable<any, string>,
	filesize?: ValueTypes["Location_Thumbnail_filesize_operator"] | undefined | null | Variable<any, string>,
	width?: ValueTypes["Location_Thumbnail_width_operator"] | undefined | null | Variable<any, string>,
	height?: ValueTypes["Location_Thumbnail_height_operator"] | undefined | null | Variable<any, string>,
	focalX?: ValueTypes["Location_Thumbnail_focalX_operator"] | undefined | null | Variable<any, string>,
	focalY?: ValueTypes["Location_Thumbnail_focalY_operator"] | undefined | null | Variable<any, string>,
	sizes__small__url?: ValueTypes["Location_Thumbnail_sizes__small__url_operator"] | undefined | null | Variable<any, string>,
	sizes__small__width?: ValueTypes["Location_Thumbnail_sizes__small__width_operator"] | undefined | null | Variable<any, string>,
	sizes__small__height?: ValueTypes["Location_Thumbnail_sizes__small__height_operator"] | undefined | null | Variable<any, string>,
	sizes__small__mimeType?: ValueTypes["Location_Thumbnail_sizes__small__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__small__filesize?: ValueTypes["Location_Thumbnail_sizes__small__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__small__filename?: ValueTypes["Location_Thumbnail_sizes__small__filename_operator"] | undefined | null | Variable<any, string>,
	sizes__medium__url?: ValueTypes["Location_Thumbnail_sizes__medium__url_operator"] | undefined | null | Variable<any, string>,
	sizes__medium__width?: ValueTypes["Location_Thumbnail_sizes__medium__width_operator"] | undefined | null | Variable<any, string>,
	sizes__medium__height?: ValueTypes["Location_Thumbnail_sizes__medium__height_operator"] | undefined | null | Variable<any, string>,
	sizes__medium__mimeType?: ValueTypes["Location_Thumbnail_sizes__medium__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__medium__filesize?: ValueTypes["Location_Thumbnail_sizes__medium__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__medium__filename?: ValueTypes["Location_Thumbnail_sizes__medium__filename_operator"] | undefined | null | Variable<any, string>,
	sizes__large__url?: ValueTypes["Location_Thumbnail_sizes__large__url_operator"] | undefined | null | Variable<any, string>,
	sizes__large__width?: ValueTypes["Location_Thumbnail_sizes__large__width_operator"] | undefined | null | Variable<any, string>,
	sizes__large__height?: ValueTypes["Location_Thumbnail_sizes__large__height_operator"] | undefined | null | Variable<any, string>,
	sizes__large__mimeType?: ValueTypes["Location_Thumbnail_sizes__large__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__large__filesize?: ValueTypes["Location_Thumbnail_sizes__large__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__large__filename?: ValueTypes["Location_Thumbnail_sizes__large__filename_operator"] | undefined | null | Variable<any, string>,
	sizes__small_jpeg__url?: ValueTypes["Location_Thumbnail_sizes__small_jpeg__url_operator"] | undefined | null | Variable<any, string>,
	sizes__small_jpeg__width?: ValueTypes["Location_Thumbnail_sizes__small_jpeg__width_operator"] | undefined | null | Variable<any, string>,
	sizes__small_jpeg__height?: ValueTypes["Location_Thumbnail_sizes__small_jpeg__height_operator"] | undefined | null | Variable<any, string>,
	sizes__small_jpeg__mimeType?: ValueTypes["Location_Thumbnail_sizes__small_jpeg__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__small_jpeg__filesize?: ValueTypes["Location_Thumbnail_sizes__small_jpeg__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__small_jpeg__filename?: ValueTypes["Location_Thumbnail_sizes__small_jpeg__filename_operator"] | undefined | null | Variable<any, string>,
	sizes__medium_jpeg__url?: ValueTypes["Location_Thumbnail_sizes__medium_jpeg__url_operator"] | undefined | null | Variable<any, string>,
	sizes__medium_jpeg__width?: ValueTypes["Location_Thumbnail_sizes__medium_jpeg__width_operator"] | undefined | null | Variable<any, string>,
	sizes__medium_jpeg__height?: ValueTypes["Location_Thumbnail_sizes__medium_jpeg__height_operator"] | undefined | null | Variable<any, string>,
	sizes__medium_jpeg__mimeType?: ValueTypes["Location_Thumbnail_sizes__medium_jpeg__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__medium_jpeg__filesize?: ValueTypes["Location_Thumbnail_sizes__medium_jpeg__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__medium_jpeg__filename?: ValueTypes["Location_Thumbnail_sizes__medium_jpeg__filename_operator"] | undefined | null | Variable<any, string>,
	sizes__large_jpeg__url?: ValueTypes["Location_Thumbnail_sizes__large_jpeg__url_operator"] | undefined | null | Variable<any, string>,
	sizes__large_jpeg__width?: ValueTypes["Location_Thumbnail_sizes__large_jpeg__width_operator"] | undefined | null | Variable<any, string>,
	sizes__large_jpeg__height?: ValueTypes["Location_Thumbnail_sizes__large_jpeg__height_operator"] | undefined | null | Variable<any, string>,
	sizes__large_jpeg__mimeType?: ValueTypes["Location_Thumbnail_sizes__large_jpeg__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__large_jpeg__filesize?: ValueTypes["Location_Thumbnail_sizes__large_jpeg__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__large_jpeg__filename?: ValueTypes["Location_Thumbnail_sizes__large_jpeg__filename_operator"] | undefined | null | Variable<any, string>,
	id?: ValueTypes["Location_Thumbnail_id_operator"] | undefined | null | Variable<any, string>,
	AND?: Array<ValueTypes["Location_Thumbnail_where_and"] | undefined | null> | undefined | null | Variable<any, string>,
	OR?: Array<ValueTypes["Location_Thumbnail_where_or"] | undefined | null> | undefined | null | Variable<any, string>
};
	["Location_Banner_where"]: {
	alt?: ValueTypes["Location_Banner_alt_operator"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["Location_Banner_updatedAt_operator"] | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["Location_Banner_createdAt_operator"] | undefined | null | Variable<any, string>,
	url?: ValueTypes["Location_Banner_url_operator"] | undefined | null | Variable<any, string>,
	filename?: ValueTypes["Location_Banner_filename_operator"] | undefined | null | Variable<any, string>,
	mimeType?: ValueTypes["Location_Banner_mimeType_operator"] | undefined | null | Variable<any, string>,
	filesize?: ValueTypes["Location_Banner_filesize_operator"] | undefined | null | Variable<any, string>,
	width?: ValueTypes["Location_Banner_width_operator"] | undefined | null | Variable<any, string>,
	height?: ValueTypes["Location_Banner_height_operator"] | undefined | null | Variable<any, string>,
	focalX?: ValueTypes["Location_Banner_focalX_operator"] | undefined | null | Variable<any, string>,
	focalY?: ValueTypes["Location_Banner_focalY_operator"] | undefined | null | Variable<any, string>,
	sizes__small__url?: ValueTypes["Location_Banner_sizes__small__url_operator"] | undefined | null | Variable<any, string>,
	sizes__small__width?: ValueTypes["Location_Banner_sizes__small__width_operator"] | undefined | null | Variable<any, string>,
	sizes__small__height?: ValueTypes["Location_Banner_sizes__small__height_operator"] | undefined | null | Variable<any, string>,
	sizes__small__mimeType?: ValueTypes["Location_Banner_sizes__small__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__small__filesize?: ValueTypes["Location_Banner_sizes__small__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__small__filename?: ValueTypes["Location_Banner_sizes__small__filename_operator"] | undefined | null | Variable<any, string>,
	sizes__medium__url?: ValueTypes["Location_Banner_sizes__medium__url_operator"] | undefined | null | Variable<any, string>,
	sizes__medium__width?: ValueTypes["Location_Banner_sizes__medium__width_operator"] | undefined | null | Variable<any, string>,
	sizes__medium__height?: ValueTypes["Location_Banner_sizes__medium__height_operator"] | undefined | null | Variable<any, string>,
	sizes__medium__mimeType?: ValueTypes["Location_Banner_sizes__medium__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__medium__filesize?: ValueTypes["Location_Banner_sizes__medium__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__medium__filename?: ValueTypes["Location_Banner_sizes__medium__filename_operator"] | undefined | null | Variable<any, string>,
	sizes__large__url?: ValueTypes["Location_Banner_sizes__large__url_operator"] | undefined | null | Variable<any, string>,
	sizes__large__width?: ValueTypes["Location_Banner_sizes__large__width_operator"] | undefined | null | Variable<any, string>,
	sizes__large__height?: ValueTypes["Location_Banner_sizes__large__height_operator"] | undefined | null | Variable<any, string>,
	sizes__large__mimeType?: ValueTypes["Location_Banner_sizes__large__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__large__filesize?: ValueTypes["Location_Banner_sizes__large__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__large__filename?: ValueTypes["Location_Banner_sizes__large__filename_operator"] | undefined | null | Variable<any, string>,
	sizes__small_jpeg__url?: ValueTypes["Location_Banner_sizes__small_jpeg__url_operator"] | undefined | null | Variable<any, string>,
	sizes__small_jpeg__width?: ValueTypes["Location_Banner_sizes__small_jpeg__width_operator"] | undefined | null | Variable<any, string>,
	sizes__small_jpeg__height?: ValueTypes["Location_Banner_sizes__small_jpeg__height_operator"] | undefined | null | Variable<any, string>,
	sizes__small_jpeg__mimeType?: ValueTypes["Location_Banner_sizes__small_jpeg__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__small_jpeg__filesize?: ValueTypes["Location_Banner_sizes__small_jpeg__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__small_jpeg__filename?: ValueTypes["Location_Banner_sizes__small_jpeg__filename_operator"] | undefined | null | Variable<any, string>,
	sizes__medium_jpeg__url?: ValueTypes["Location_Banner_sizes__medium_jpeg__url_operator"] | undefined | null | Variable<any, string>,
	sizes__medium_jpeg__width?: ValueTypes["Location_Banner_sizes__medium_jpeg__width_operator"] | undefined | null | Variable<any, string>,
	sizes__medium_jpeg__height?: ValueTypes["Location_Banner_sizes__medium_jpeg__height_operator"] | undefined | null | Variable<any, string>,
	sizes__medium_jpeg__mimeType?: ValueTypes["Location_Banner_sizes__medium_jpeg__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__medium_jpeg__filesize?: ValueTypes["Location_Banner_sizes__medium_jpeg__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__medium_jpeg__filename?: ValueTypes["Location_Banner_sizes__medium_jpeg__filename_operator"] | undefined | null | Variable<any, string>,
	sizes__large_jpeg__url?: ValueTypes["Location_Banner_sizes__large_jpeg__url_operator"] | undefined | null | Variable<any, string>,
	sizes__large_jpeg__width?: ValueTypes["Location_Banner_sizes__large_jpeg__width_operator"] | undefined | null | Variable<any, string>,
	sizes__large_jpeg__height?: ValueTypes["Location_Banner_sizes__large_jpeg__height_operator"] | undefined | null | Variable<any, string>,
	sizes__large_jpeg__mimeType?: ValueTypes["Location_Banner_sizes__large_jpeg__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__large_jpeg__filesize?: ValueTypes["Location_Banner_sizes__large_jpeg__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__large_jpeg__filename?: ValueTypes["Location_Banner_sizes__large_jpeg__filename_operator"] | undefined | null | Variable<any, string>,
	id?: ValueTypes["Location_Banner_id_operator"] | undefined | null | Variable<any, string>,
	AND?: Array<ValueTypes["Location_Banner_where_and"] | undefined | null> | undefined | null | Variable<any, string>,
	OR?: Array<ValueTypes["Location_Banner_where_or"] | undefined | null> | undefined | null | Variable<any, string>
};
	["Location_Banner_alt_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Location_Banner_updatedAt_operator"]: {
	equals?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	not_equals?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	greater_than_equal?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	greater_than?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	less_than_equal?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	less_than?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	like?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Location_Banner_createdAt_operator"]: {
	equals?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	not_equals?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	greater_than_equal?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	greater_than?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	less_than_equal?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	less_than?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	like?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Location_Banner_url_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Location_Banner_filename_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Location_Banner_mimeType_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Location_Banner_filesize_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Location_Banner_width_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Location_Banner_height_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Location_Banner_focalX_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Location_Banner_focalY_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Location_Banner_sizes__small__url_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Location_Banner_sizes__small__width_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Location_Banner_sizes__small__height_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Location_Banner_sizes__small__mimeType_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Location_Banner_sizes__small__filesize_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Location_Banner_sizes__small__filename_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Location_Banner_sizes__medium__url_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Location_Banner_sizes__medium__width_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Location_Banner_sizes__medium__height_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Location_Banner_sizes__medium__mimeType_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Location_Banner_sizes__medium__filesize_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Location_Banner_sizes__medium__filename_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Location_Banner_sizes__large__url_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Location_Banner_sizes__large__width_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Location_Banner_sizes__large__height_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Location_Banner_sizes__large__mimeType_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Location_Banner_sizes__large__filesize_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Location_Banner_sizes__large__filename_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Location_Banner_sizes__small_jpeg__url_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Location_Banner_sizes__small_jpeg__width_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Location_Banner_sizes__small_jpeg__height_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Location_Banner_sizes__small_jpeg__mimeType_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Location_Banner_sizes__small_jpeg__filesize_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Location_Banner_sizes__small_jpeg__filename_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Location_Banner_sizes__medium_jpeg__url_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Location_Banner_sizes__medium_jpeg__width_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Location_Banner_sizes__medium_jpeg__height_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Location_Banner_sizes__medium_jpeg__mimeType_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Location_Banner_sizes__medium_jpeg__filesize_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Location_Banner_sizes__medium_jpeg__filename_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Location_Banner_sizes__large_jpeg__url_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Location_Banner_sizes__large_jpeg__width_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Location_Banner_sizes__large_jpeg__height_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Location_Banner_sizes__large_jpeg__mimeType_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Location_Banner_sizes__large_jpeg__filesize_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Location_Banner_sizes__large_jpeg__filename_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Location_Banner_id_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Location_Banner_where_and"]: {
	alt?: ValueTypes["Location_Banner_alt_operator"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["Location_Banner_updatedAt_operator"] | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["Location_Banner_createdAt_operator"] | undefined | null | Variable<any, string>,
	url?: ValueTypes["Location_Banner_url_operator"] | undefined | null | Variable<any, string>,
	filename?: ValueTypes["Location_Banner_filename_operator"] | undefined | null | Variable<any, string>,
	mimeType?: ValueTypes["Location_Banner_mimeType_operator"] | undefined | null | Variable<any, string>,
	filesize?: ValueTypes["Location_Banner_filesize_operator"] | undefined | null | Variable<any, string>,
	width?: ValueTypes["Location_Banner_width_operator"] | undefined | null | Variable<any, string>,
	height?: ValueTypes["Location_Banner_height_operator"] | undefined | null | Variable<any, string>,
	focalX?: ValueTypes["Location_Banner_focalX_operator"] | undefined | null | Variable<any, string>,
	focalY?: ValueTypes["Location_Banner_focalY_operator"] | undefined | null | Variable<any, string>,
	sizes__small__url?: ValueTypes["Location_Banner_sizes__small__url_operator"] | undefined | null | Variable<any, string>,
	sizes__small__width?: ValueTypes["Location_Banner_sizes__small__width_operator"] | undefined | null | Variable<any, string>,
	sizes__small__height?: ValueTypes["Location_Banner_sizes__small__height_operator"] | undefined | null | Variable<any, string>,
	sizes__small__mimeType?: ValueTypes["Location_Banner_sizes__small__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__small__filesize?: ValueTypes["Location_Banner_sizes__small__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__small__filename?: ValueTypes["Location_Banner_sizes__small__filename_operator"] | undefined | null | Variable<any, string>,
	sizes__medium__url?: ValueTypes["Location_Banner_sizes__medium__url_operator"] | undefined | null | Variable<any, string>,
	sizes__medium__width?: ValueTypes["Location_Banner_sizes__medium__width_operator"] | undefined | null | Variable<any, string>,
	sizes__medium__height?: ValueTypes["Location_Banner_sizes__medium__height_operator"] | undefined | null | Variable<any, string>,
	sizes__medium__mimeType?: ValueTypes["Location_Banner_sizes__medium__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__medium__filesize?: ValueTypes["Location_Banner_sizes__medium__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__medium__filename?: ValueTypes["Location_Banner_sizes__medium__filename_operator"] | undefined | null | Variable<any, string>,
	sizes__large__url?: ValueTypes["Location_Banner_sizes__large__url_operator"] | undefined | null | Variable<any, string>,
	sizes__large__width?: ValueTypes["Location_Banner_sizes__large__width_operator"] | undefined | null | Variable<any, string>,
	sizes__large__height?: ValueTypes["Location_Banner_sizes__large__height_operator"] | undefined | null | Variable<any, string>,
	sizes__large__mimeType?: ValueTypes["Location_Banner_sizes__large__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__large__filesize?: ValueTypes["Location_Banner_sizes__large__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__large__filename?: ValueTypes["Location_Banner_sizes__large__filename_operator"] | undefined | null | Variable<any, string>,
	sizes__small_jpeg__url?: ValueTypes["Location_Banner_sizes__small_jpeg__url_operator"] | undefined | null | Variable<any, string>,
	sizes__small_jpeg__width?: ValueTypes["Location_Banner_sizes__small_jpeg__width_operator"] | undefined | null | Variable<any, string>,
	sizes__small_jpeg__height?: ValueTypes["Location_Banner_sizes__small_jpeg__height_operator"] | undefined | null | Variable<any, string>,
	sizes__small_jpeg__mimeType?: ValueTypes["Location_Banner_sizes__small_jpeg__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__small_jpeg__filesize?: ValueTypes["Location_Banner_sizes__small_jpeg__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__small_jpeg__filename?: ValueTypes["Location_Banner_sizes__small_jpeg__filename_operator"] | undefined | null | Variable<any, string>,
	sizes__medium_jpeg__url?: ValueTypes["Location_Banner_sizes__medium_jpeg__url_operator"] | undefined | null | Variable<any, string>,
	sizes__medium_jpeg__width?: ValueTypes["Location_Banner_sizes__medium_jpeg__width_operator"] | undefined | null | Variable<any, string>,
	sizes__medium_jpeg__height?: ValueTypes["Location_Banner_sizes__medium_jpeg__height_operator"] | undefined | null | Variable<any, string>,
	sizes__medium_jpeg__mimeType?: ValueTypes["Location_Banner_sizes__medium_jpeg__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__medium_jpeg__filesize?: ValueTypes["Location_Banner_sizes__medium_jpeg__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__medium_jpeg__filename?: ValueTypes["Location_Banner_sizes__medium_jpeg__filename_operator"] | undefined | null | Variable<any, string>,
	sizes__large_jpeg__url?: ValueTypes["Location_Banner_sizes__large_jpeg__url_operator"] | undefined | null | Variable<any, string>,
	sizes__large_jpeg__width?: ValueTypes["Location_Banner_sizes__large_jpeg__width_operator"] | undefined | null | Variable<any, string>,
	sizes__large_jpeg__height?: ValueTypes["Location_Banner_sizes__large_jpeg__height_operator"] | undefined | null | Variable<any, string>,
	sizes__large_jpeg__mimeType?: ValueTypes["Location_Banner_sizes__large_jpeg__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__large_jpeg__filesize?: ValueTypes["Location_Banner_sizes__large_jpeg__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__large_jpeg__filename?: ValueTypes["Location_Banner_sizes__large_jpeg__filename_operator"] | undefined | null | Variable<any, string>,
	id?: ValueTypes["Location_Banner_id_operator"] | undefined | null | Variable<any, string>,
	AND?: Array<ValueTypes["Location_Banner_where_and"] | undefined | null> | undefined | null | Variable<any, string>,
	OR?: Array<ValueTypes["Location_Banner_where_or"] | undefined | null> | undefined | null | Variable<any, string>
};
	["Location_Banner_where_or"]: {
	alt?: ValueTypes["Location_Banner_alt_operator"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["Location_Banner_updatedAt_operator"] | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["Location_Banner_createdAt_operator"] | undefined | null | Variable<any, string>,
	url?: ValueTypes["Location_Banner_url_operator"] | undefined | null | Variable<any, string>,
	filename?: ValueTypes["Location_Banner_filename_operator"] | undefined | null | Variable<any, string>,
	mimeType?: ValueTypes["Location_Banner_mimeType_operator"] | undefined | null | Variable<any, string>,
	filesize?: ValueTypes["Location_Banner_filesize_operator"] | undefined | null | Variable<any, string>,
	width?: ValueTypes["Location_Banner_width_operator"] | undefined | null | Variable<any, string>,
	height?: ValueTypes["Location_Banner_height_operator"] | undefined | null | Variable<any, string>,
	focalX?: ValueTypes["Location_Banner_focalX_operator"] | undefined | null | Variable<any, string>,
	focalY?: ValueTypes["Location_Banner_focalY_operator"] | undefined | null | Variable<any, string>,
	sizes__small__url?: ValueTypes["Location_Banner_sizes__small__url_operator"] | undefined | null | Variable<any, string>,
	sizes__small__width?: ValueTypes["Location_Banner_sizes__small__width_operator"] | undefined | null | Variable<any, string>,
	sizes__small__height?: ValueTypes["Location_Banner_sizes__small__height_operator"] | undefined | null | Variable<any, string>,
	sizes__small__mimeType?: ValueTypes["Location_Banner_sizes__small__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__small__filesize?: ValueTypes["Location_Banner_sizes__small__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__small__filename?: ValueTypes["Location_Banner_sizes__small__filename_operator"] | undefined | null | Variable<any, string>,
	sizes__medium__url?: ValueTypes["Location_Banner_sizes__medium__url_operator"] | undefined | null | Variable<any, string>,
	sizes__medium__width?: ValueTypes["Location_Banner_sizes__medium__width_operator"] | undefined | null | Variable<any, string>,
	sizes__medium__height?: ValueTypes["Location_Banner_sizes__medium__height_operator"] | undefined | null | Variable<any, string>,
	sizes__medium__mimeType?: ValueTypes["Location_Banner_sizes__medium__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__medium__filesize?: ValueTypes["Location_Banner_sizes__medium__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__medium__filename?: ValueTypes["Location_Banner_sizes__medium__filename_operator"] | undefined | null | Variable<any, string>,
	sizes__large__url?: ValueTypes["Location_Banner_sizes__large__url_operator"] | undefined | null | Variable<any, string>,
	sizes__large__width?: ValueTypes["Location_Banner_sizes__large__width_operator"] | undefined | null | Variable<any, string>,
	sizes__large__height?: ValueTypes["Location_Banner_sizes__large__height_operator"] | undefined | null | Variable<any, string>,
	sizes__large__mimeType?: ValueTypes["Location_Banner_sizes__large__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__large__filesize?: ValueTypes["Location_Banner_sizes__large__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__large__filename?: ValueTypes["Location_Banner_sizes__large__filename_operator"] | undefined | null | Variable<any, string>,
	sizes__small_jpeg__url?: ValueTypes["Location_Banner_sizes__small_jpeg__url_operator"] | undefined | null | Variable<any, string>,
	sizes__small_jpeg__width?: ValueTypes["Location_Banner_sizes__small_jpeg__width_operator"] | undefined | null | Variable<any, string>,
	sizes__small_jpeg__height?: ValueTypes["Location_Banner_sizes__small_jpeg__height_operator"] | undefined | null | Variable<any, string>,
	sizes__small_jpeg__mimeType?: ValueTypes["Location_Banner_sizes__small_jpeg__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__small_jpeg__filesize?: ValueTypes["Location_Banner_sizes__small_jpeg__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__small_jpeg__filename?: ValueTypes["Location_Banner_sizes__small_jpeg__filename_operator"] | undefined | null | Variable<any, string>,
	sizes__medium_jpeg__url?: ValueTypes["Location_Banner_sizes__medium_jpeg__url_operator"] | undefined | null | Variable<any, string>,
	sizes__medium_jpeg__width?: ValueTypes["Location_Banner_sizes__medium_jpeg__width_operator"] | undefined | null | Variable<any, string>,
	sizes__medium_jpeg__height?: ValueTypes["Location_Banner_sizes__medium_jpeg__height_operator"] | undefined | null | Variable<any, string>,
	sizes__medium_jpeg__mimeType?: ValueTypes["Location_Banner_sizes__medium_jpeg__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__medium_jpeg__filesize?: ValueTypes["Location_Banner_sizes__medium_jpeg__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__medium_jpeg__filename?: ValueTypes["Location_Banner_sizes__medium_jpeg__filename_operator"] | undefined | null | Variable<any, string>,
	sizes__large_jpeg__url?: ValueTypes["Location_Banner_sizes__large_jpeg__url_operator"] | undefined | null | Variable<any, string>,
	sizes__large_jpeg__width?: ValueTypes["Location_Banner_sizes__large_jpeg__width_operator"] | undefined | null | Variable<any, string>,
	sizes__large_jpeg__height?: ValueTypes["Location_Banner_sizes__large_jpeg__height_operator"] | undefined | null | Variable<any, string>,
	sizes__large_jpeg__mimeType?: ValueTypes["Location_Banner_sizes__large_jpeg__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__large_jpeg__filesize?: ValueTypes["Location_Banner_sizes__large_jpeg__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__large_jpeg__filename?: ValueTypes["Location_Banner_sizes__large_jpeg__filename_operator"] | undefined | null | Variable<any, string>,
	id?: ValueTypes["Location_Banner_id_operator"] | undefined | null | Variable<any, string>,
	AND?: Array<ValueTypes["Location_Banner_where_and"] | undefined | null> | undefined | null | Variable<any, string>,
	OR?: Array<ValueTypes["Location_Banner_where_or"] | undefined | null> | undefined | null | Variable<any, string>
};
	["Location_type"]:Location_type;
	["Location_category"]:Location_category;
	["District"]: AliasType<{
	id?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	level?:boolean | `@${string}`,
children?: [{	locale?: ValueTypes["LocaleInputType"] | undefined | null | Variable<any, string>,	fallbackLocale?: ValueTypes["FallbackLocaleInputType"] | undefined | null | Variable<any, string>},ValueTypes["District"]],
	value?:boolean | `@${string}`,
	lat?:boolean | `@${string}`,
	long?:boolean | `@${string}`,
	radius?:boolean | `@${string}`,
	region?:boolean | `@${string}`,
	updatedAt?:boolean | `@${string}`,
	createdAt?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["District_region"]:District_region;
	["Location_Details"]: AliasType<{
	code?:boolean | `@${string}`,
	phone?:boolean | `@${string}`,
	page?:boolean | `@${string}`,
	email?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["Location_Content"]: AliasType<{		["...on RichText"]?: ValueTypes["RichText"],
		["...on Gallery"]?: ValueTypes["Gallery"],
		["...on Image"]?: ValueTypes["Image"],
		["...on Video"]?: ValueTypes["Video"]
		__typename?: boolean | `@${string}`
}>;
	["RichText"]: AliasType<{
richText?: [{	depth?: number | undefined | null | Variable<any, string>},boolean | `@${string}`],
	id?:boolean | `@${string}`,
	blockName?:boolean | `@${string}`,
	blockType?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	/** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
["JSON"]:unknown;
	["Gallery"]: AliasType<{
	gallery?:ValueTypes["Gallery_Gallery"],
	id?:boolean | `@${string}`,
	blockName?:boolean | `@${string}`,
	blockType?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["Gallery_Gallery"]: AliasType<{
item?: [{	locale?: ValueTypes["LocaleInputType"] | undefined | null | Variable<any, string>,	fallbackLocale?: ValueTypes["FallbackLocaleInputType"] | undefined | null | Variable<any, string>,	where?: ValueTypes["Gallery_Gallery_Item_where"] | undefined | null | Variable<any, string>},ValueTypes["Media"]],
	caption?:boolean | `@${string}`,
	id?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["Gallery_Gallery_Item_where"]: {
	alt?: ValueTypes["Gallery_Gallery_Item_alt_operator"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["Gallery_Gallery_Item_updatedAt_operator"] | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["Gallery_Gallery_Item_createdAt_operator"] | undefined | null | Variable<any, string>,
	url?: ValueTypes["Gallery_Gallery_Item_url_operator"] | undefined | null | Variable<any, string>,
	filename?: ValueTypes["Gallery_Gallery_Item_filename_operator"] | undefined | null | Variable<any, string>,
	mimeType?: ValueTypes["Gallery_Gallery_Item_mimeType_operator"] | undefined | null | Variable<any, string>,
	filesize?: ValueTypes["Gallery_Gallery_Item_filesize_operator"] | undefined | null | Variable<any, string>,
	width?: ValueTypes["Gallery_Gallery_Item_width_operator"] | undefined | null | Variable<any, string>,
	height?: ValueTypes["Gallery_Gallery_Item_height_operator"] | undefined | null | Variable<any, string>,
	focalX?: ValueTypes["Gallery_Gallery_Item_focalX_operator"] | undefined | null | Variable<any, string>,
	focalY?: ValueTypes["Gallery_Gallery_Item_focalY_operator"] | undefined | null | Variable<any, string>,
	sizes__small__url?: ValueTypes["Gallery_Gallery_Item_sizes__small__url_operator"] | undefined | null | Variable<any, string>,
	sizes__small__width?: ValueTypes["Gallery_Gallery_Item_sizes__small__width_operator"] | undefined | null | Variable<any, string>,
	sizes__small__height?: ValueTypes["Gallery_Gallery_Item_sizes__small__height_operator"] | undefined | null | Variable<any, string>,
	sizes__small__mimeType?: ValueTypes["Gallery_Gallery_Item_sizes__small__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__small__filesize?: ValueTypes["Gallery_Gallery_Item_sizes__small__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__small__filename?: ValueTypes["Gallery_Gallery_Item_sizes__small__filename_operator"] | undefined | null | Variable<any, string>,
	sizes__medium__url?: ValueTypes["Gallery_Gallery_Item_sizes__medium__url_operator"] | undefined | null | Variable<any, string>,
	sizes__medium__width?: ValueTypes["Gallery_Gallery_Item_sizes__medium__width_operator"] | undefined | null | Variable<any, string>,
	sizes__medium__height?: ValueTypes["Gallery_Gallery_Item_sizes__medium__height_operator"] | undefined | null | Variable<any, string>,
	sizes__medium__mimeType?: ValueTypes["Gallery_Gallery_Item_sizes__medium__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__medium__filesize?: ValueTypes["Gallery_Gallery_Item_sizes__medium__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__medium__filename?: ValueTypes["Gallery_Gallery_Item_sizes__medium__filename_operator"] | undefined | null | Variable<any, string>,
	sizes__large__url?: ValueTypes["Gallery_Gallery_Item_sizes__large__url_operator"] | undefined | null | Variable<any, string>,
	sizes__large__width?: ValueTypes["Gallery_Gallery_Item_sizes__large__width_operator"] | undefined | null | Variable<any, string>,
	sizes__large__height?: ValueTypes["Gallery_Gallery_Item_sizes__large__height_operator"] | undefined | null | Variable<any, string>,
	sizes__large__mimeType?: ValueTypes["Gallery_Gallery_Item_sizes__large__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__large__filesize?: ValueTypes["Gallery_Gallery_Item_sizes__large__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__large__filename?: ValueTypes["Gallery_Gallery_Item_sizes__large__filename_operator"] | undefined | null | Variable<any, string>,
	sizes__small_jpeg__url?: ValueTypes["Gallery_Gallery_Item_sizes__small_jpeg__url_operator"] | undefined | null | Variable<any, string>,
	sizes__small_jpeg__width?: ValueTypes["Gallery_Gallery_Item_sizes__small_jpeg__width_operator"] | undefined | null | Variable<any, string>,
	sizes__small_jpeg__height?: ValueTypes["Gallery_Gallery_Item_sizes__small_jpeg__height_operator"] | undefined | null | Variable<any, string>,
	sizes__small_jpeg__mimeType?: ValueTypes["Gallery_Gallery_Item_sizes__small_jpeg__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__small_jpeg__filesize?: ValueTypes["Gallery_Gallery_Item_sizes__small_jpeg__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__small_jpeg__filename?: ValueTypes["Gallery_Gallery_Item_sizes__small_jpeg__filename_operator"] | undefined | null | Variable<any, string>,
	sizes__medium_jpeg__url?: ValueTypes["Gallery_Gallery_Item_sizes__medium_jpeg__url_operator"] | undefined | null | Variable<any, string>,
	sizes__medium_jpeg__width?: ValueTypes["Gallery_Gallery_Item_sizes__medium_jpeg__width_operator"] | undefined | null | Variable<any, string>,
	sizes__medium_jpeg__height?: ValueTypes["Gallery_Gallery_Item_sizes__medium_jpeg__height_operator"] | undefined | null | Variable<any, string>,
	sizes__medium_jpeg__mimeType?: ValueTypes["Gallery_Gallery_Item_sizes__medium_jpeg__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__medium_jpeg__filesize?: ValueTypes["Gallery_Gallery_Item_sizes__medium_jpeg__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__medium_jpeg__filename?: ValueTypes["Gallery_Gallery_Item_sizes__medium_jpeg__filename_operator"] | undefined | null | Variable<any, string>,
	sizes__large_jpeg__url?: ValueTypes["Gallery_Gallery_Item_sizes__large_jpeg__url_operator"] | undefined | null | Variable<any, string>,
	sizes__large_jpeg__width?: ValueTypes["Gallery_Gallery_Item_sizes__large_jpeg__width_operator"] | undefined | null | Variable<any, string>,
	sizes__large_jpeg__height?: ValueTypes["Gallery_Gallery_Item_sizes__large_jpeg__height_operator"] | undefined | null | Variable<any, string>,
	sizes__large_jpeg__mimeType?: ValueTypes["Gallery_Gallery_Item_sizes__large_jpeg__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__large_jpeg__filesize?: ValueTypes["Gallery_Gallery_Item_sizes__large_jpeg__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__large_jpeg__filename?: ValueTypes["Gallery_Gallery_Item_sizes__large_jpeg__filename_operator"] | undefined | null | Variable<any, string>,
	id?: ValueTypes["Gallery_Gallery_Item_id_operator"] | undefined | null | Variable<any, string>,
	AND?: Array<ValueTypes["Gallery_Gallery_Item_where_and"] | undefined | null> | undefined | null | Variable<any, string>,
	OR?: Array<ValueTypes["Gallery_Gallery_Item_where_or"] | undefined | null> | undefined | null | Variable<any, string>
};
	["Gallery_Gallery_Item_alt_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Gallery_Gallery_Item_updatedAt_operator"]: {
	equals?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	not_equals?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	greater_than_equal?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	greater_than?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	less_than_equal?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	less_than?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	like?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Gallery_Gallery_Item_createdAt_operator"]: {
	equals?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	not_equals?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	greater_than_equal?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	greater_than?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	less_than_equal?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	less_than?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	like?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Gallery_Gallery_Item_url_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Gallery_Gallery_Item_filename_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Gallery_Gallery_Item_mimeType_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Gallery_Gallery_Item_filesize_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Gallery_Gallery_Item_width_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Gallery_Gallery_Item_height_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Gallery_Gallery_Item_focalX_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Gallery_Gallery_Item_focalY_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Gallery_Gallery_Item_sizes__small__url_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Gallery_Gallery_Item_sizes__small__width_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Gallery_Gallery_Item_sizes__small__height_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Gallery_Gallery_Item_sizes__small__mimeType_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Gallery_Gallery_Item_sizes__small__filesize_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Gallery_Gallery_Item_sizes__small__filename_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Gallery_Gallery_Item_sizes__medium__url_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Gallery_Gallery_Item_sizes__medium__width_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Gallery_Gallery_Item_sizes__medium__height_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Gallery_Gallery_Item_sizes__medium__mimeType_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Gallery_Gallery_Item_sizes__medium__filesize_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Gallery_Gallery_Item_sizes__medium__filename_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Gallery_Gallery_Item_sizes__large__url_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Gallery_Gallery_Item_sizes__large__width_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Gallery_Gallery_Item_sizes__large__height_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Gallery_Gallery_Item_sizes__large__mimeType_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Gallery_Gallery_Item_sizes__large__filesize_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Gallery_Gallery_Item_sizes__large__filename_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Gallery_Gallery_Item_sizes__small_jpeg__url_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Gallery_Gallery_Item_sizes__small_jpeg__width_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Gallery_Gallery_Item_sizes__small_jpeg__height_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Gallery_Gallery_Item_sizes__small_jpeg__mimeType_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Gallery_Gallery_Item_sizes__small_jpeg__filesize_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Gallery_Gallery_Item_sizes__small_jpeg__filename_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Gallery_Gallery_Item_sizes__medium_jpeg__url_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Gallery_Gallery_Item_sizes__medium_jpeg__width_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Gallery_Gallery_Item_sizes__medium_jpeg__height_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Gallery_Gallery_Item_sizes__medium_jpeg__mimeType_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Gallery_Gallery_Item_sizes__medium_jpeg__filesize_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Gallery_Gallery_Item_sizes__medium_jpeg__filename_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Gallery_Gallery_Item_sizes__large_jpeg__url_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Gallery_Gallery_Item_sizes__large_jpeg__width_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Gallery_Gallery_Item_sizes__large_jpeg__height_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Gallery_Gallery_Item_sizes__large_jpeg__mimeType_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Gallery_Gallery_Item_sizes__large_jpeg__filesize_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Gallery_Gallery_Item_sizes__large_jpeg__filename_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Gallery_Gallery_Item_id_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Gallery_Gallery_Item_where_and"]: {
	alt?: ValueTypes["Gallery_Gallery_Item_alt_operator"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["Gallery_Gallery_Item_updatedAt_operator"] | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["Gallery_Gallery_Item_createdAt_operator"] | undefined | null | Variable<any, string>,
	url?: ValueTypes["Gallery_Gallery_Item_url_operator"] | undefined | null | Variable<any, string>,
	filename?: ValueTypes["Gallery_Gallery_Item_filename_operator"] | undefined | null | Variable<any, string>,
	mimeType?: ValueTypes["Gallery_Gallery_Item_mimeType_operator"] | undefined | null | Variable<any, string>,
	filesize?: ValueTypes["Gallery_Gallery_Item_filesize_operator"] | undefined | null | Variable<any, string>,
	width?: ValueTypes["Gallery_Gallery_Item_width_operator"] | undefined | null | Variable<any, string>,
	height?: ValueTypes["Gallery_Gallery_Item_height_operator"] | undefined | null | Variable<any, string>,
	focalX?: ValueTypes["Gallery_Gallery_Item_focalX_operator"] | undefined | null | Variable<any, string>,
	focalY?: ValueTypes["Gallery_Gallery_Item_focalY_operator"] | undefined | null | Variable<any, string>,
	sizes__small__url?: ValueTypes["Gallery_Gallery_Item_sizes__small__url_operator"] | undefined | null | Variable<any, string>,
	sizes__small__width?: ValueTypes["Gallery_Gallery_Item_sizes__small__width_operator"] | undefined | null | Variable<any, string>,
	sizes__small__height?: ValueTypes["Gallery_Gallery_Item_sizes__small__height_operator"] | undefined | null | Variable<any, string>,
	sizes__small__mimeType?: ValueTypes["Gallery_Gallery_Item_sizes__small__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__small__filesize?: ValueTypes["Gallery_Gallery_Item_sizes__small__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__small__filename?: ValueTypes["Gallery_Gallery_Item_sizes__small__filename_operator"] | undefined | null | Variable<any, string>,
	sizes__medium__url?: ValueTypes["Gallery_Gallery_Item_sizes__medium__url_operator"] | undefined | null | Variable<any, string>,
	sizes__medium__width?: ValueTypes["Gallery_Gallery_Item_sizes__medium__width_operator"] | undefined | null | Variable<any, string>,
	sizes__medium__height?: ValueTypes["Gallery_Gallery_Item_sizes__medium__height_operator"] | undefined | null | Variable<any, string>,
	sizes__medium__mimeType?: ValueTypes["Gallery_Gallery_Item_sizes__medium__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__medium__filesize?: ValueTypes["Gallery_Gallery_Item_sizes__medium__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__medium__filename?: ValueTypes["Gallery_Gallery_Item_sizes__medium__filename_operator"] | undefined | null | Variable<any, string>,
	sizes__large__url?: ValueTypes["Gallery_Gallery_Item_sizes__large__url_operator"] | undefined | null | Variable<any, string>,
	sizes__large__width?: ValueTypes["Gallery_Gallery_Item_sizes__large__width_operator"] | undefined | null | Variable<any, string>,
	sizes__large__height?: ValueTypes["Gallery_Gallery_Item_sizes__large__height_operator"] | undefined | null | Variable<any, string>,
	sizes__large__mimeType?: ValueTypes["Gallery_Gallery_Item_sizes__large__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__large__filesize?: ValueTypes["Gallery_Gallery_Item_sizes__large__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__large__filename?: ValueTypes["Gallery_Gallery_Item_sizes__large__filename_operator"] | undefined | null | Variable<any, string>,
	sizes__small_jpeg__url?: ValueTypes["Gallery_Gallery_Item_sizes__small_jpeg__url_operator"] | undefined | null | Variable<any, string>,
	sizes__small_jpeg__width?: ValueTypes["Gallery_Gallery_Item_sizes__small_jpeg__width_operator"] | undefined | null | Variable<any, string>,
	sizes__small_jpeg__height?: ValueTypes["Gallery_Gallery_Item_sizes__small_jpeg__height_operator"] | undefined | null | Variable<any, string>,
	sizes__small_jpeg__mimeType?: ValueTypes["Gallery_Gallery_Item_sizes__small_jpeg__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__small_jpeg__filesize?: ValueTypes["Gallery_Gallery_Item_sizes__small_jpeg__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__small_jpeg__filename?: ValueTypes["Gallery_Gallery_Item_sizes__small_jpeg__filename_operator"] | undefined | null | Variable<any, string>,
	sizes__medium_jpeg__url?: ValueTypes["Gallery_Gallery_Item_sizes__medium_jpeg__url_operator"] | undefined | null | Variable<any, string>,
	sizes__medium_jpeg__width?: ValueTypes["Gallery_Gallery_Item_sizes__medium_jpeg__width_operator"] | undefined | null | Variable<any, string>,
	sizes__medium_jpeg__height?: ValueTypes["Gallery_Gallery_Item_sizes__medium_jpeg__height_operator"] | undefined | null | Variable<any, string>,
	sizes__medium_jpeg__mimeType?: ValueTypes["Gallery_Gallery_Item_sizes__medium_jpeg__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__medium_jpeg__filesize?: ValueTypes["Gallery_Gallery_Item_sizes__medium_jpeg__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__medium_jpeg__filename?: ValueTypes["Gallery_Gallery_Item_sizes__medium_jpeg__filename_operator"] | undefined | null | Variable<any, string>,
	sizes__large_jpeg__url?: ValueTypes["Gallery_Gallery_Item_sizes__large_jpeg__url_operator"] | undefined | null | Variable<any, string>,
	sizes__large_jpeg__width?: ValueTypes["Gallery_Gallery_Item_sizes__large_jpeg__width_operator"] | undefined | null | Variable<any, string>,
	sizes__large_jpeg__height?: ValueTypes["Gallery_Gallery_Item_sizes__large_jpeg__height_operator"] | undefined | null | Variable<any, string>,
	sizes__large_jpeg__mimeType?: ValueTypes["Gallery_Gallery_Item_sizes__large_jpeg__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__large_jpeg__filesize?: ValueTypes["Gallery_Gallery_Item_sizes__large_jpeg__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__large_jpeg__filename?: ValueTypes["Gallery_Gallery_Item_sizes__large_jpeg__filename_operator"] | undefined | null | Variable<any, string>,
	id?: ValueTypes["Gallery_Gallery_Item_id_operator"] | undefined | null | Variable<any, string>,
	AND?: Array<ValueTypes["Gallery_Gallery_Item_where_and"] | undefined | null> | undefined | null | Variable<any, string>,
	OR?: Array<ValueTypes["Gallery_Gallery_Item_where_or"] | undefined | null> | undefined | null | Variable<any, string>
};
	["Gallery_Gallery_Item_where_or"]: {
	alt?: ValueTypes["Gallery_Gallery_Item_alt_operator"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["Gallery_Gallery_Item_updatedAt_operator"] | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["Gallery_Gallery_Item_createdAt_operator"] | undefined | null | Variable<any, string>,
	url?: ValueTypes["Gallery_Gallery_Item_url_operator"] | undefined | null | Variable<any, string>,
	filename?: ValueTypes["Gallery_Gallery_Item_filename_operator"] | undefined | null | Variable<any, string>,
	mimeType?: ValueTypes["Gallery_Gallery_Item_mimeType_operator"] | undefined | null | Variable<any, string>,
	filesize?: ValueTypes["Gallery_Gallery_Item_filesize_operator"] | undefined | null | Variable<any, string>,
	width?: ValueTypes["Gallery_Gallery_Item_width_operator"] | undefined | null | Variable<any, string>,
	height?: ValueTypes["Gallery_Gallery_Item_height_operator"] | undefined | null | Variable<any, string>,
	focalX?: ValueTypes["Gallery_Gallery_Item_focalX_operator"] | undefined | null | Variable<any, string>,
	focalY?: ValueTypes["Gallery_Gallery_Item_focalY_operator"] | undefined | null | Variable<any, string>,
	sizes__small__url?: ValueTypes["Gallery_Gallery_Item_sizes__small__url_operator"] | undefined | null | Variable<any, string>,
	sizes__small__width?: ValueTypes["Gallery_Gallery_Item_sizes__small__width_operator"] | undefined | null | Variable<any, string>,
	sizes__small__height?: ValueTypes["Gallery_Gallery_Item_sizes__small__height_operator"] | undefined | null | Variable<any, string>,
	sizes__small__mimeType?: ValueTypes["Gallery_Gallery_Item_sizes__small__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__small__filesize?: ValueTypes["Gallery_Gallery_Item_sizes__small__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__small__filename?: ValueTypes["Gallery_Gallery_Item_sizes__small__filename_operator"] | undefined | null | Variable<any, string>,
	sizes__medium__url?: ValueTypes["Gallery_Gallery_Item_sizes__medium__url_operator"] | undefined | null | Variable<any, string>,
	sizes__medium__width?: ValueTypes["Gallery_Gallery_Item_sizes__medium__width_operator"] | undefined | null | Variable<any, string>,
	sizes__medium__height?: ValueTypes["Gallery_Gallery_Item_sizes__medium__height_operator"] | undefined | null | Variable<any, string>,
	sizes__medium__mimeType?: ValueTypes["Gallery_Gallery_Item_sizes__medium__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__medium__filesize?: ValueTypes["Gallery_Gallery_Item_sizes__medium__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__medium__filename?: ValueTypes["Gallery_Gallery_Item_sizes__medium__filename_operator"] | undefined | null | Variable<any, string>,
	sizes__large__url?: ValueTypes["Gallery_Gallery_Item_sizes__large__url_operator"] | undefined | null | Variable<any, string>,
	sizes__large__width?: ValueTypes["Gallery_Gallery_Item_sizes__large__width_operator"] | undefined | null | Variable<any, string>,
	sizes__large__height?: ValueTypes["Gallery_Gallery_Item_sizes__large__height_operator"] | undefined | null | Variable<any, string>,
	sizes__large__mimeType?: ValueTypes["Gallery_Gallery_Item_sizes__large__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__large__filesize?: ValueTypes["Gallery_Gallery_Item_sizes__large__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__large__filename?: ValueTypes["Gallery_Gallery_Item_sizes__large__filename_operator"] | undefined | null | Variable<any, string>,
	sizes__small_jpeg__url?: ValueTypes["Gallery_Gallery_Item_sizes__small_jpeg__url_operator"] | undefined | null | Variable<any, string>,
	sizes__small_jpeg__width?: ValueTypes["Gallery_Gallery_Item_sizes__small_jpeg__width_operator"] | undefined | null | Variable<any, string>,
	sizes__small_jpeg__height?: ValueTypes["Gallery_Gallery_Item_sizes__small_jpeg__height_operator"] | undefined | null | Variable<any, string>,
	sizes__small_jpeg__mimeType?: ValueTypes["Gallery_Gallery_Item_sizes__small_jpeg__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__small_jpeg__filesize?: ValueTypes["Gallery_Gallery_Item_sizes__small_jpeg__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__small_jpeg__filename?: ValueTypes["Gallery_Gallery_Item_sizes__small_jpeg__filename_operator"] | undefined | null | Variable<any, string>,
	sizes__medium_jpeg__url?: ValueTypes["Gallery_Gallery_Item_sizes__medium_jpeg__url_operator"] | undefined | null | Variable<any, string>,
	sizes__medium_jpeg__width?: ValueTypes["Gallery_Gallery_Item_sizes__medium_jpeg__width_operator"] | undefined | null | Variable<any, string>,
	sizes__medium_jpeg__height?: ValueTypes["Gallery_Gallery_Item_sizes__medium_jpeg__height_operator"] | undefined | null | Variable<any, string>,
	sizes__medium_jpeg__mimeType?: ValueTypes["Gallery_Gallery_Item_sizes__medium_jpeg__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__medium_jpeg__filesize?: ValueTypes["Gallery_Gallery_Item_sizes__medium_jpeg__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__medium_jpeg__filename?: ValueTypes["Gallery_Gallery_Item_sizes__medium_jpeg__filename_operator"] | undefined | null | Variable<any, string>,
	sizes__large_jpeg__url?: ValueTypes["Gallery_Gallery_Item_sizes__large_jpeg__url_operator"] | undefined | null | Variable<any, string>,
	sizes__large_jpeg__width?: ValueTypes["Gallery_Gallery_Item_sizes__large_jpeg__width_operator"] | undefined | null | Variable<any, string>,
	sizes__large_jpeg__height?: ValueTypes["Gallery_Gallery_Item_sizes__large_jpeg__height_operator"] | undefined | null | Variable<any, string>,
	sizes__large_jpeg__mimeType?: ValueTypes["Gallery_Gallery_Item_sizes__large_jpeg__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__large_jpeg__filesize?: ValueTypes["Gallery_Gallery_Item_sizes__large_jpeg__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__large_jpeg__filename?: ValueTypes["Gallery_Gallery_Item_sizes__large_jpeg__filename_operator"] | undefined | null | Variable<any, string>,
	id?: ValueTypes["Gallery_Gallery_Item_id_operator"] | undefined | null | Variable<any, string>,
	AND?: Array<ValueTypes["Gallery_Gallery_Item_where_and"] | undefined | null> | undefined | null | Variable<any, string>,
	OR?: Array<ValueTypes["Gallery_Gallery_Item_where_or"] | undefined | null> | undefined | null | Variable<any, string>
};
	["Image"]: AliasType<{
media?: [{	locale?: ValueTypes["LocaleInputType"] | undefined | null | Variable<any, string>,	fallbackLocale?: ValueTypes["FallbackLocaleInputType"] | undefined | null | Variable<any, string>,	where?: ValueTypes["Image_Media_where"] | undefined | null | Variable<any, string>},ValueTypes["Media"]],
	caption?:boolean | `@${string}`,
	id?:boolean | `@${string}`,
	blockName?:boolean | `@${string}`,
	blockType?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["Image_Media_where"]: {
	alt?: ValueTypes["Image_Media_alt_operator"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["Image_Media_updatedAt_operator"] | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["Image_Media_createdAt_operator"] | undefined | null | Variable<any, string>,
	url?: ValueTypes["Image_Media_url_operator"] | undefined | null | Variable<any, string>,
	filename?: ValueTypes["Image_Media_filename_operator"] | undefined | null | Variable<any, string>,
	mimeType?: ValueTypes["Image_Media_mimeType_operator"] | undefined | null | Variable<any, string>,
	filesize?: ValueTypes["Image_Media_filesize_operator"] | undefined | null | Variable<any, string>,
	width?: ValueTypes["Image_Media_width_operator"] | undefined | null | Variable<any, string>,
	height?: ValueTypes["Image_Media_height_operator"] | undefined | null | Variable<any, string>,
	focalX?: ValueTypes["Image_Media_focalX_operator"] | undefined | null | Variable<any, string>,
	focalY?: ValueTypes["Image_Media_focalY_operator"] | undefined | null | Variable<any, string>,
	sizes__small__url?: ValueTypes["Image_Media_sizes__small__url_operator"] | undefined | null | Variable<any, string>,
	sizes__small__width?: ValueTypes["Image_Media_sizes__small__width_operator"] | undefined | null | Variable<any, string>,
	sizes__small__height?: ValueTypes["Image_Media_sizes__small__height_operator"] | undefined | null | Variable<any, string>,
	sizes__small__mimeType?: ValueTypes["Image_Media_sizes__small__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__small__filesize?: ValueTypes["Image_Media_sizes__small__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__small__filename?: ValueTypes["Image_Media_sizes__small__filename_operator"] | undefined | null | Variable<any, string>,
	sizes__medium__url?: ValueTypes["Image_Media_sizes__medium__url_operator"] | undefined | null | Variable<any, string>,
	sizes__medium__width?: ValueTypes["Image_Media_sizes__medium__width_operator"] | undefined | null | Variable<any, string>,
	sizes__medium__height?: ValueTypes["Image_Media_sizes__medium__height_operator"] | undefined | null | Variable<any, string>,
	sizes__medium__mimeType?: ValueTypes["Image_Media_sizes__medium__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__medium__filesize?: ValueTypes["Image_Media_sizes__medium__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__medium__filename?: ValueTypes["Image_Media_sizes__medium__filename_operator"] | undefined | null | Variable<any, string>,
	sizes__large__url?: ValueTypes["Image_Media_sizes__large__url_operator"] | undefined | null | Variable<any, string>,
	sizes__large__width?: ValueTypes["Image_Media_sizes__large__width_operator"] | undefined | null | Variable<any, string>,
	sizes__large__height?: ValueTypes["Image_Media_sizes__large__height_operator"] | undefined | null | Variable<any, string>,
	sizes__large__mimeType?: ValueTypes["Image_Media_sizes__large__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__large__filesize?: ValueTypes["Image_Media_sizes__large__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__large__filename?: ValueTypes["Image_Media_sizes__large__filename_operator"] | undefined | null | Variable<any, string>,
	sizes__small_jpeg__url?: ValueTypes["Image_Media_sizes__small_jpeg__url_operator"] | undefined | null | Variable<any, string>,
	sizes__small_jpeg__width?: ValueTypes["Image_Media_sizes__small_jpeg__width_operator"] | undefined | null | Variable<any, string>,
	sizes__small_jpeg__height?: ValueTypes["Image_Media_sizes__small_jpeg__height_operator"] | undefined | null | Variable<any, string>,
	sizes__small_jpeg__mimeType?: ValueTypes["Image_Media_sizes__small_jpeg__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__small_jpeg__filesize?: ValueTypes["Image_Media_sizes__small_jpeg__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__small_jpeg__filename?: ValueTypes["Image_Media_sizes__small_jpeg__filename_operator"] | undefined | null | Variable<any, string>,
	sizes__medium_jpeg__url?: ValueTypes["Image_Media_sizes__medium_jpeg__url_operator"] | undefined | null | Variable<any, string>,
	sizes__medium_jpeg__width?: ValueTypes["Image_Media_sizes__medium_jpeg__width_operator"] | undefined | null | Variable<any, string>,
	sizes__medium_jpeg__height?: ValueTypes["Image_Media_sizes__medium_jpeg__height_operator"] | undefined | null | Variable<any, string>,
	sizes__medium_jpeg__mimeType?: ValueTypes["Image_Media_sizes__medium_jpeg__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__medium_jpeg__filesize?: ValueTypes["Image_Media_sizes__medium_jpeg__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__medium_jpeg__filename?: ValueTypes["Image_Media_sizes__medium_jpeg__filename_operator"] | undefined | null | Variable<any, string>,
	sizes__large_jpeg__url?: ValueTypes["Image_Media_sizes__large_jpeg__url_operator"] | undefined | null | Variable<any, string>,
	sizes__large_jpeg__width?: ValueTypes["Image_Media_sizes__large_jpeg__width_operator"] | undefined | null | Variable<any, string>,
	sizes__large_jpeg__height?: ValueTypes["Image_Media_sizes__large_jpeg__height_operator"] | undefined | null | Variable<any, string>,
	sizes__large_jpeg__mimeType?: ValueTypes["Image_Media_sizes__large_jpeg__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__large_jpeg__filesize?: ValueTypes["Image_Media_sizes__large_jpeg__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__large_jpeg__filename?: ValueTypes["Image_Media_sizes__large_jpeg__filename_operator"] | undefined | null | Variable<any, string>,
	id?: ValueTypes["Image_Media_id_operator"] | undefined | null | Variable<any, string>,
	AND?: Array<ValueTypes["Image_Media_where_and"] | undefined | null> | undefined | null | Variable<any, string>,
	OR?: Array<ValueTypes["Image_Media_where_or"] | undefined | null> | undefined | null | Variable<any, string>
};
	["Image_Media_alt_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Image_Media_updatedAt_operator"]: {
	equals?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	not_equals?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	greater_than_equal?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	greater_than?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	less_than_equal?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	less_than?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	like?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Image_Media_createdAt_operator"]: {
	equals?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	not_equals?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	greater_than_equal?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	greater_than?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	less_than_equal?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	less_than?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	like?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Image_Media_url_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Image_Media_filename_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Image_Media_mimeType_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Image_Media_filesize_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Image_Media_width_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Image_Media_height_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Image_Media_focalX_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Image_Media_focalY_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Image_Media_sizes__small__url_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Image_Media_sizes__small__width_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Image_Media_sizes__small__height_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Image_Media_sizes__small__mimeType_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Image_Media_sizes__small__filesize_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Image_Media_sizes__small__filename_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Image_Media_sizes__medium__url_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Image_Media_sizes__medium__width_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Image_Media_sizes__medium__height_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Image_Media_sizes__medium__mimeType_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Image_Media_sizes__medium__filesize_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Image_Media_sizes__medium__filename_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Image_Media_sizes__large__url_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Image_Media_sizes__large__width_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Image_Media_sizes__large__height_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Image_Media_sizes__large__mimeType_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Image_Media_sizes__large__filesize_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Image_Media_sizes__large__filename_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Image_Media_sizes__small_jpeg__url_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Image_Media_sizes__small_jpeg__width_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Image_Media_sizes__small_jpeg__height_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Image_Media_sizes__small_jpeg__mimeType_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Image_Media_sizes__small_jpeg__filesize_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Image_Media_sizes__small_jpeg__filename_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Image_Media_sizes__medium_jpeg__url_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Image_Media_sizes__medium_jpeg__width_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Image_Media_sizes__medium_jpeg__height_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Image_Media_sizes__medium_jpeg__mimeType_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Image_Media_sizes__medium_jpeg__filesize_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Image_Media_sizes__medium_jpeg__filename_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Image_Media_sizes__large_jpeg__url_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Image_Media_sizes__large_jpeg__width_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Image_Media_sizes__large_jpeg__height_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Image_Media_sizes__large_jpeg__mimeType_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Image_Media_sizes__large_jpeg__filesize_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Image_Media_sizes__large_jpeg__filename_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Image_Media_id_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Image_Media_where_and"]: {
	alt?: ValueTypes["Image_Media_alt_operator"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["Image_Media_updatedAt_operator"] | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["Image_Media_createdAt_operator"] | undefined | null | Variable<any, string>,
	url?: ValueTypes["Image_Media_url_operator"] | undefined | null | Variable<any, string>,
	filename?: ValueTypes["Image_Media_filename_operator"] | undefined | null | Variable<any, string>,
	mimeType?: ValueTypes["Image_Media_mimeType_operator"] | undefined | null | Variable<any, string>,
	filesize?: ValueTypes["Image_Media_filesize_operator"] | undefined | null | Variable<any, string>,
	width?: ValueTypes["Image_Media_width_operator"] | undefined | null | Variable<any, string>,
	height?: ValueTypes["Image_Media_height_operator"] | undefined | null | Variable<any, string>,
	focalX?: ValueTypes["Image_Media_focalX_operator"] | undefined | null | Variable<any, string>,
	focalY?: ValueTypes["Image_Media_focalY_operator"] | undefined | null | Variable<any, string>,
	sizes__small__url?: ValueTypes["Image_Media_sizes__small__url_operator"] | undefined | null | Variable<any, string>,
	sizes__small__width?: ValueTypes["Image_Media_sizes__small__width_operator"] | undefined | null | Variable<any, string>,
	sizes__small__height?: ValueTypes["Image_Media_sizes__small__height_operator"] | undefined | null | Variable<any, string>,
	sizes__small__mimeType?: ValueTypes["Image_Media_sizes__small__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__small__filesize?: ValueTypes["Image_Media_sizes__small__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__small__filename?: ValueTypes["Image_Media_sizes__small__filename_operator"] | undefined | null | Variable<any, string>,
	sizes__medium__url?: ValueTypes["Image_Media_sizes__medium__url_operator"] | undefined | null | Variable<any, string>,
	sizes__medium__width?: ValueTypes["Image_Media_sizes__medium__width_operator"] | undefined | null | Variable<any, string>,
	sizes__medium__height?: ValueTypes["Image_Media_sizes__medium__height_operator"] | undefined | null | Variable<any, string>,
	sizes__medium__mimeType?: ValueTypes["Image_Media_sizes__medium__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__medium__filesize?: ValueTypes["Image_Media_sizes__medium__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__medium__filename?: ValueTypes["Image_Media_sizes__medium__filename_operator"] | undefined | null | Variable<any, string>,
	sizes__large__url?: ValueTypes["Image_Media_sizes__large__url_operator"] | undefined | null | Variable<any, string>,
	sizes__large__width?: ValueTypes["Image_Media_sizes__large__width_operator"] | undefined | null | Variable<any, string>,
	sizes__large__height?: ValueTypes["Image_Media_sizes__large__height_operator"] | undefined | null | Variable<any, string>,
	sizes__large__mimeType?: ValueTypes["Image_Media_sizes__large__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__large__filesize?: ValueTypes["Image_Media_sizes__large__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__large__filename?: ValueTypes["Image_Media_sizes__large__filename_operator"] | undefined | null | Variable<any, string>,
	sizes__small_jpeg__url?: ValueTypes["Image_Media_sizes__small_jpeg__url_operator"] | undefined | null | Variable<any, string>,
	sizes__small_jpeg__width?: ValueTypes["Image_Media_sizes__small_jpeg__width_operator"] | undefined | null | Variable<any, string>,
	sizes__small_jpeg__height?: ValueTypes["Image_Media_sizes__small_jpeg__height_operator"] | undefined | null | Variable<any, string>,
	sizes__small_jpeg__mimeType?: ValueTypes["Image_Media_sizes__small_jpeg__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__small_jpeg__filesize?: ValueTypes["Image_Media_sizes__small_jpeg__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__small_jpeg__filename?: ValueTypes["Image_Media_sizes__small_jpeg__filename_operator"] | undefined | null | Variable<any, string>,
	sizes__medium_jpeg__url?: ValueTypes["Image_Media_sizes__medium_jpeg__url_operator"] | undefined | null | Variable<any, string>,
	sizes__medium_jpeg__width?: ValueTypes["Image_Media_sizes__medium_jpeg__width_operator"] | undefined | null | Variable<any, string>,
	sizes__medium_jpeg__height?: ValueTypes["Image_Media_sizes__medium_jpeg__height_operator"] | undefined | null | Variable<any, string>,
	sizes__medium_jpeg__mimeType?: ValueTypes["Image_Media_sizes__medium_jpeg__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__medium_jpeg__filesize?: ValueTypes["Image_Media_sizes__medium_jpeg__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__medium_jpeg__filename?: ValueTypes["Image_Media_sizes__medium_jpeg__filename_operator"] | undefined | null | Variable<any, string>,
	sizes__large_jpeg__url?: ValueTypes["Image_Media_sizes__large_jpeg__url_operator"] | undefined | null | Variable<any, string>,
	sizes__large_jpeg__width?: ValueTypes["Image_Media_sizes__large_jpeg__width_operator"] | undefined | null | Variable<any, string>,
	sizes__large_jpeg__height?: ValueTypes["Image_Media_sizes__large_jpeg__height_operator"] | undefined | null | Variable<any, string>,
	sizes__large_jpeg__mimeType?: ValueTypes["Image_Media_sizes__large_jpeg__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__large_jpeg__filesize?: ValueTypes["Image_Media_sizes__large_jpeg__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__large_jpeg__filename?: ValueTypes["Image_Media_sizes__large_jpeg__filename_operator"] | undefined | null | Variable<any, string>,
	id?: ValueTypes["Image_Media_id_operator"] | undefined | null | Variable<any, string>,
	AND?: Array<ValueTypes["Image_Media_where_and"] | undefined | null> | undefined | null | Variable<any, string>,
	OR?: Array<ValueTypes["Image_Media_where_or"] | undefined | null> | undefined | null | Variable<any, string>
};
	["Image_Media_where_or"]: {
	alt?: ValueTypes["Image_Media_alt_operator"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["Image_Media_updatedAt_operator"] | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["Image_Media_createdAt_operator"] | undefined | null | Variable<any, string>,
	url?: ValueTypes["Image_Media_url_operator"] | undefined | null | Variable<any, string>,
	filename?: ValueTypes["Image_Media_filename_operator"] | undefined | null | Variable<any, string>,
	mimeType?: ValueTypes["Image_Media_mimeType_operator"] | undefined | null | Variable<any, string>,
	filesize?: ValueTypes["Image_Media_filesize_operator"] | undefined | null | Variable<any, string>,
	width?: ValueTypes["Image_Media_width_operator"] | undefined | null | Variable<any, string>,
	height?: ValueTypes["Image_Media_height_operator"] | undefined | null | Variable<any, string>,
	focalX?: ValueTypes["Image_Media_focalX_operator"] | undefined | null | Variable<any, string>,
	focalY?: ValueTypes["Image_Media_focalY_operator"] | undefined | null | Variable<any, string>,
	sizes__small__url?: ValueTypes["Image_Media_sizes__small__url_operator"] | undefined | null | Variable<any, string>,
	sizes__small__width?: ValueTypes["Image_Media_sizes__small__width_operator"] | undefined | null | Variable<any, string>,
	sizes__small__height?: ValueTypes["Image_Media_sizes__small__height_operator"] | undefined | null | Variable<any, string>,
	sizes__small__mimeType?: ValueTypes["Image_Media_sizes__small__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__small__filesize?: ValueTypes["Image_Media_sizes__small__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__small__filename?: ValueTypes["Image_Media_sizes__small__filename_operator"] | undefined | null | Variable<any, string>,
	sizes__medium__url?: ValueTypes["Image_Media_sizes__medium__url_operator"] | undefined | null | Variable<any, string>,
	sizes__medium__width?: ValueTypes["Image_Media_sizes__medium__width_operator"] | undefined | null | Variable<any, string>,
	sizes__medium__height?: ValueTypes["Image_Media_sizes__medium__height_operator"] | undefined | null | Variable<any, string>,
	sizes__medium__mimeType?: ValueTypes["Image_Media_sizes__medium__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__medium__filesize?: ValueTypes["Image_Media_sizes__medium__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__medium__filename?: ValueTypes["Image_Media_sizes__medium__filename_operator"] | undefined | null | Variable<any, string>,
	sizes__large__url?: ValueTypes["Image_Media_sizes__large__url_operator"] | undefined | null | Variable<any, string>,
	sizes__large__width?: ValueTypes["Image_Media_sizes__large__width_operator"] | undefined | null | Variable<any, string>,
	sizes__large__height?: ValueTypes["Image_Media_sizes__large__height_operator"] | undefined | null | Variable<any, string>,
	sizes__large__mimeType?: ValueTypes["Image_Media_sizes__large__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__large__filesize?: ValueTypes["Image_Media_sizes__large__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__large__filename?: ValueTypes["Image_Media_sizes__large__filename_operator"] | undefined | null | Variable<any, string>,
	sizes__small_jpeg__url?: ValueTypes["Image_Media_sizes__small_jpeg__url_operator"] | undefined | null | Variable<any, string>,
	sizes__small_jpeg__width?: ValueTypes["Image_Media_sizes__small_jpeg__width_operator"] | undefined | null | Variable<any, string>,
	sizes__small_jpeg__height?: ValueTypes["Image_Media_sizes__small_jpeg__height_operator"] | undefined | null | Variable<any, string>,
	sizes__small_jpeg__mimeType?: ValueTypes["Image_Media_sizes__small_jpeg__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__small_jpeg__filesize?: ValueTypes["Image_Media_sizes__small_jpeg__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__small_jpeg__filename?: ValueTypes["Image_Media_sizes__small_jpeg__filename_operator"] | undefined | null | Variable<any, string>,
	sizes__medium_jpeg__url?: ValueTypes["Image_Media_sizes__medium_jpeg__url_operator"] | undefined | null | Variable<any, string>,
	sizes__medium_jpeg__width?: ValueTypes["Image_Media_sizes__medium_jpeg__width_operator"] | undefined | null | Variable<any, string>,
	sizes__medium_jpeg__height?: ValueTypes["Image_Media_sizes__medium_jpeg__height_operator"] | undefined | null | Variable<any, string>,
	sizes__medium_jpeg__mimeType?: ValueTypes["Image_Media_sizes__medium_jpeg__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__medium_jpeg__filesize?: ValueTypes["Image_Media_sizes__medium_jpeg__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__medium_jpeg__filename?: ValueTypes["Image_Media_sizes__medium_jpeg__filename_operator"] | undefined | null | Variable<any, string>,
	sizes__large_jpeg__url?: ValueTypes["Image_Media_sizes__large_jpeg__url_operator"] | undefined | null | Variable<any, string>,
	sizes__large_jpeg__width?: ValueTypes["Image_Media_sizes__large_jpeg__width_operator"] | undefined | null | Variable<any, string>,
	sizes__large_jpeg__height?: ValueTypes["Image_Media_sizes__large_jpeg__height_operator"] | undefined | null | Variable<any, string>,
	sizes__large_jpeg__mimeType?: ValueTypes["Image_Media_sizes__large_jpeg__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__large_jpeg__filesize?: ValueTypes["Image_Media_sizes__large_jpeg__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__large_jpeg__filename?: ValueTypes["Image_Media_sizes__large_jpeg__filename_operator"] | undefined | null | Variable<any, string>,
	id?: ValueTypes["Image_Media_id_operator"] | undefined | null | Variable<any, string>,
	AND?: Array<ValueTypes["Image_Media_where_and"] | undefined | null> | undefined | null | Variable<any, string>,
	OR?: Array<ValueTypes["Image_Media_where_or"] | undefined | null> | undefined | null | Variable<any, string>
};
	["Video"]: AliasType<{
	url?:boolean | `@${string}`,
	id?:boolean | `@${string}`,
	blockName?:boolean | `@${string}`,
	blockType?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["Location_Criteria"]: AliasType<{
audiences?: [{	locale?: ValueTypes["LocaleInputType"] | undefined | null | Variable<any, string>,	fallbackLocale?: ValueTypes["FallbackLocaleInputType"] | undefined | null | Variable<any, string>},ValueTypes["Audience"]],
dates?: [{	locale?: ValueTypes["LocaleInputType"] | undefined | null | Variable<any, string>,	fallbackLocale?: ValueTypes["FallbackLocaleInputType"] | undefined | null | Variable<any, string>},ValueTypes["Date"]],
categories?: [{	locale?: ValueTypes["LocaleInputType"] | undefined | null | Variable<any, string>,	fallbackLocale?: ValueTypes["FallbackLocaleInputType"] | undefined | null | Variable<any, string>},ValueTypes["EventCategory"]],
tags?: [{	locale?: ValueTypes["LocaleInputType"] | undefined | null | Variable<any, string>,	fallbackLocale?: ValueTypes["FallbackLocaleInputType"] | undefined | null | Variable<any, string>},ValueTypes["Tag"]],
		__typename?: boolean | `@${string}`
}>;
	["Audience"]: AliasType<{
	id?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	value?:boolean | `@${string}`,
icon?: [{	locale?: ValueTypes["LocaleInputType"] | undefined | null | Variable<any, string>,	fallbackLocale?: ValueTypes["FallbackLocaleInputType"] | undefined | null | Variable<any, string>,	where?: ValueTypes["Audience_Icon_where"] | undefined | null | Variable<any, string>},ValueTypes["Media"]],
	region?:boolean | `@${string}`,
	updatedAt?:boolean | `@${string}`,
	createdAt?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["Audience_Icon_where"]: {
	alt?: ValueTypes["Audience_Icon_alt_operator"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["Audience_Icon_updatedAt_operator"] | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["Audience_Icon_createdAt_operator"] | undefined | null | Variable<any, string>,
	url?: ValueTypes["Audience_Icon_url_operator"] | undefined | null | Variable<any, string>,
	filename?: ValueTypes["Audience_Icon_filename_operator"] | undefined | null | Variable<any, string>,
	mimeType?: ValueTypes["Audience_Icon_mimeType_operator"] | undefined | null | Variable<any, string>,
	filesize?: ValueTypes["Audience_Icon_filesize_operator"] | undefined | null | Variable<any, string>,
	width?: ValueTypes["Audience_Icon_width_operator"] | undefined | null | Variable<any, string>,
	height?: ValueTypes["Audience_Icon_height_operator"] | undefined | null | Variable<any, string>,
	focalX?: ValueTypes["Audience_Icon_focalX_operator"] | undefined | null | Variable<any, string>,
	focalY?: ValueTypes["Audience_Icon_focalY_operator"] | undefined | null | Variable<any, string>,
	sizes__small__url?: ValueTypes["Audience_Icon_sizes__small__url_operator"] | undefined | null | Variable<any, string>,
	sizes__small__width?: ValueTypes["Audience_Icon_sizes__small__width_operator"] | undefined | null | Variable<any, string>,
	sizes__small__height?: ValueTypes["Audience_Icon_sizes__small__height_operator"] | undefined | null | Variable<any, string>,
	sizes__small__mimeType?: ValueTypes["Audience_Icon_sizes__small__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__small__filesize?: ValueTypes["Audience_Icon_sizes__small__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__small__filename?: ValueTypes["Audience_Icon_sizes__small__filename_operator"] | undefined | null | Variable<any, string>,
	sizes__medium__url?: ValueTypes["Audience_Icon_sizes__medium__url_operator"] | undefined | null | Variable<any, string>,
	sizes__medium__width?: ValueTypes["Audience_Icon_sizes__medium__width_operator"] | undefined | null | Variable<any, string>,
	sizes__medium__height?: ValueTypes["Audience_Icon_sizes__medium__height_operator"] | undefined | null | Variable<any, string>,
	sizes__medium__mimeType?: ValueTypes["Audience_Icon_sizes__medium__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__medium__filesize?: ValueTypes["Audience_Icon_sizes__medium__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__medium__filename?: ValueTypes["Audience_Icon_sizes__medium__filename_operator"] | undefined | null | Variable<any, string>,
	sizes__large__url?: ValueTypes["Audience_Icon_sizes__large__url_operator"] | undefined | null | Variable<any, string>,
	sizes__large__width?: ValueTypes["Audience_Icon_sizes__large__width_operator"] | undefined | null | Variable<any, string>,
	sizes__large__height?: ValueTypes["Audience_Icon_sizes__large__height_operator"] | undefined | null | Variable<any, string>,
	sizes__large__mimeType?: ValueTypes["Audience_Icon_sizes__large__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__large__filesize?: ValueTypes["Audience_Icon_sizes__large__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__large__filename?: ValueTypes["Audience_Icon_sizes__large__filename_operator"] | undefined | null | Variable<any, string>,
	sizes__small_jpeg__url?: ValueTypes["Audience_Icon_sizes__small_jpeg__url_operator"] | undefined | null | Variable<any, string>,
	sizes__small_jpeg__width?: ValueTypes["Audience_Icon_sizes__small_jpeg__width_operator"] | undefined | null | Variable<any, string>,
	sizes__small_jpeg__height?: ValueTypes["Audience_Icon_sizes__small_jpeg__height_operator"] | undefined | null | Variable<any, string>,
	sizes__small_jpeg__mimeType?: ValueTypes["Audience_Icon_sizes__small_jpeg__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__small_jpeg__filesize?: ValueTypes["Audience_Icon_sizes__small_jpeg__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__small_jpeg__filename?: ValueTypes["Audience_Icon_sizes__small_jpeg__filename_operator"] | undefined | null | Variable<any, string>,
	sizes__medium_jpeg__url?: ValueTypes["Audience_Icon_sizes__medium_jpeg__url_operator"] | undefined | null | Variable<any, string>,
	sizes__medium_jpeg__width?: ValueTypes["Audience_Icon_sizes__medium_jpeg__width_operator"] | undefined | null | Variable<any, string>,
	sizes__medium_jpeg__height?: ValueTypes["Audience_Icon_sizes__medium_jpeg__height_operator"] | undefined | null | Variable<any, string>,
	sizes__medium_jpeg__mimeType?: ValueTypes["Audience_Icon_sizes__medium_jpeg__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__medium_jpeg__filesize?: ValueTypes["Audience_Icon_sizes__medium_jpeg__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__medium_jpeg__filename?: ValueTypes["Audience_Icon_sizes__medium_jpeg__filename_operator"] | undefined | null | Variable<any, string>,
	sizes__large_jpeg__url?: ValueTypes["Audience_Icon_sizes__large_jpeg__url_operator"] | undefined | null | Variable<any, string>,
	sizes__large_jpeg__width?: ValueTypes["Audience_Icon_sizes__large_jpeg__width_operator"] | undefined | null | Variable<any, string>,
	sizes__large_jpeg__height?: ValueTypes["Audience_Icon_sizes__large_jpeg__height_operator"] | undefined | null | Variable<any, string>,
	sizes__large_jpeg__mimeType?: ValueTypes["Audience_Icon_sizes__large_jpeg__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__large_jpeg__filesize?: ValueTypes["Audience_Icon_sizes__large_jpeg__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__large_jpeg__filename?: ValueTypes["Audience_Icon_sizes__large_jpeg__filename_operator"] | undefined | null | Variable<any, string>,
	id?: ValueTypes["Audience_Icon_id_operator"] | undefined | null | Variable<any, string>,
	AND?: Array<ValueTypes["Audience_Icon_where_and"] | undefined | null> | undefined | null | Variable<any, string>,
	OR?: Array<ValueTypes["Audience_Icon_where_or"] | undefined | null> | undefined | null | Variable<any, string>
};
	["Audience_Icon_alt_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Audience_Icon_updatedAt_operator"]: {
	equals?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	not_equals?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	greater_than_equal?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	greater_than?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	less_than_equal?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	less_than?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	like?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Audience_Icon_createdAt_operator"]: {
	equals?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	not_equals?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	greater_than_equal?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	greater_than?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	less_than_equal?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	less_than?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	like?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Audience_Icon_url_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Audience_Icon_filename_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Audience_Icon_mimeType_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Audience_Icon_filesize_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Audience_Icon_width_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Audience_Icon_height_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Audience_Icon_focalX_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Audience_Icon_focalY_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Audience_Icon_sizes__small__url_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Audience_Icon_sizes__small__width_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Audience_Icon_sizes__small__height_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Audience_Icon_sizes__small__mimeType_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Audience_Icon_sizes__small__filesize_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Audience_Icon_sizes__small__filename_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Audience_Icon_sizes__medium__url_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Audience_Icon_sizes__medium__width_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Audience_Icon_sizes__medium__height_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Audience_Icon_sizes__medium__mimeType_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Audience_Icon_sizes__medium__filesize_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Audience_Icon_sizes__medium__filename_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Audience_Icon_sizes__large__url_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Audience_Icon_sizes__large__width_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Audience_Icon_sizes__large__height_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Audience_Icon_sizes__large__mimeType_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Audience_Icon_sizes__large__filesize_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Audience_Icon_sizes__large__filename_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Audience_Icon_sizes__small_jpeg__url_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Audience_Icon_sizes__small_jpeg__width_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Audience_Icon_sizes__small_jpeg__height_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Audience_Icon_sizes__small_jpeg__mimeType_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Audience_Icon_sizes__small_jpeg__filesize_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Audience_Icon_sizes__small_jpeg__filename_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Audience_Icon_sizes__medium_jpeg__url_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Audience_Icon_sizes__medium_jpeg__width_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Audience_Icon_sizes__medium_jpeg__height_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Audience_Icon_sizes__medium_jpeg__mimeType_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Audience_Icon_sizes__medium_jpeg__filesize_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Audience_Icon_sizes__medium_jpeg__filename_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Audience_Icon_sizes__large_jpeg__url_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Audience_Icon_sizes__large_jpeg__width_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Audience_Icon_sizes__large_jpeg__height_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Audience_Icon_sizes__large_jpeg__mimeType_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Audience_Icon_sizes__large_jpeg__filesize_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Audience_Icon_sizes__large_jpeg__filename_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Audience_Icon_id_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Audience_Icon_where_and"]: {
	alt?: ValueTypes["Audience_Icon_alt_operator"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["Audience_Icon_updatedAt_operator"] | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["Audience_Icon_createdAt_operator"] | undefined | null | Variable<any, string>,
	url?: ValueTypes["Audience_Icon_url_operator"] | undefined | null | Variable<any, string>,
	filename?: ValueTypes["Audience_Icon_filename_operator"] | undefined | null | Variable<any, string>,
	mimeType?: ValueTypes["Audience_Icon_mimeType_operator"] | undefined | null | Variable<any, string>,
	filesize?: ValueTypes["Audience_Icon_filesize_operator"] | undefined | null | Variable<any, string>,
	width?: ValueTypes["Audience_Icon_width_operator"] | undefined | null | Variable<any, string>,
	height?: ValueTypes["Audience_Icon_height_operator"] | undefined | null | Variable<any, string>,
	focalX?: ValueTypes["Audience_Icon_focalX_operator"] | undefined | null | Variable<any, string>,
	focalY?: ValueTypes["Audience_Icon_focalY_operator"] | undefined | null | Variable<any, string>,
	sizes__small__url?: ValueTypes["Audience_Icon_sizes__small__url_operator"] | undefined | null | Variable<any, string>,
	sizes__small__width?: ValueTypes["Audience_Icon_sizes__small__width_operator"] | undefined | null | Variable<any, string>,
	sizes__small__height?: ValueTypes["Audience_Icon_sizes__small__height_operator"] | undefined | null | Variable<any, string>,
	sizes__small__mimeType?: ValueTypes["Audience_Icon_sizes__small__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__small__filesize?: ValueTypes["Audience_Icon_sizes__small__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__small__filename?: ValueTypes["Audience_Icon_sizes__small__filename_operator"] | undefined | null | Variable<any, string>,
	sizes__medium__url?: ValueTypes["Audience_Icon_sizes__medium__url_operator"] | undefined | null | Variable<any, string>,
	sizes__medium__width?: ValueTypes["Audience_Icon_sizes__medium__width_operator"] | undefined | null | Variable<any, string>,
	sizes__medium__height?: ValueTypes["Audience_Icon_sizes__medium__height_operator"] | undefined | null | Variable<any, string>,
	sizes__medium__mimeType?: ValueTypes["Audience_Icon_sizes__medium__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__medium__filesize?: ValueTypes["Audience_Icon_sizes__medium__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__medium__filename?: ValueTypes["Audience_Icon_sizes__medium__filename_operator"] | undefined | null | Variable<any, string>,
	sizes__large__url?: ValueTypes["Audience_Icon_sizes__large__url_operator"] | undefined | null | Variable<any, string>,
	sizes__large__width?: ValueTypes["Audience_Icon_sizes__large__width_operator"] | undefined | null | Variable<any, string>,
	sizes__large__height?: ValueTypes["Audience_Icon_sizes__large__height_operator"] | undefined | null | Variable<any, string>,
	sizes__large__mimeType?: ValueTypes["Audience_Icon_sizes__large__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__large__filesize?: ValueTypes["Audience_Icon_sizes__large__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__large__filename?: ValueTypes["Audience_Icon_sizes__large__filename_operator"] | undefined | null | Variable<any, string>,
	sizes__small_jpeg__url?: ValueTypes["Audience_Icon_sizes__small_jpeg__url_operator"] | undefined | null | Variable<any, string>,
	sizes__small_jpeg__width?: ValueTypes["Audience_Icon_sizes__small_jpeg__width_operator"] | undefined | null | Variable<any, string>,
	sizes__small_jpeg__height?: ValueTypes["Audience_Icon_sizes__small_jpeg__height_operator"] | undefined | null | Variable<any, string>,
	sizes__small_jpeg__mimeType?: ValueTypes["Audience_Icon_sizes__small_jpeg__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__small_jpeg__filesize?: ValueTypes["Audience_Icon_sizes__small_jpeg__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__small_jpeg__filename?: ValueTypes["Audience_Icon_sizes__small_jpeg__filename_operator"] | undefined | null | Variable<any, string>,
	sizes__medium_jpeg__url?: ValueTypes["Audience_Icon_sizes__medium_jpeg__url_operator"] | undefined | null | Variable<any, string>,
	sizes__medium_jpeg__width?: ValueTypes["Audience_Icon_sizes__medium_jpeg__width_operator"] | undefined | null | Variable<any, string>,
	sizes__medium_jpeg__height?: ValueTypes["Audience_Icon_sizes__medium_jpeg__height_operator"] | undefined | null | Variable<any, string>,
	sizes__medium_jpeg__mimeType?: ValueTypes["Audience_Icon_sizes__medium_jpeg__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__medium_jpeg__filesize?: ValueTypes["Audience_Icon_sizes__medium_jpeg__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__medium_jpeg__filename?: ValueTypes["Audience_Icon_sizes__medium_jpeg__filename_operator"] | undefined | null | Variable<any, string>,
	sizes__large_jpeg__url?: ValueTypes["Audience_Icon_sizes__large_jpeg__url_operator"] | undefined | null | Variable<any, string>,
	sizes__large_jpeg__width?: ValueTypes["Audience_Icon_sizes__large_jpeg__width_operator"] | undefined | null | Variable<any, string>,
	sizes__large_jpeg__height?: ValueTypes["Audience_Icon_sizes__large_jpeg__height_operator"] | undefined | null | Variable<any, string>,
	sizes__large_jpeg__mimeType?: ValueTypes["Audience_Icon_sizes__large_jpeg__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__large_jpeg__filesize?: ValueTypes["Audience_Icon_sizes__large_jpeg__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__large_jpeg__filename?: ValueTypes["Audience_Icon_sizes__large_jpeg__filename_operator"] | undefined | null | Variable<any, string>,
	id?: ValueTypes["Audience_Icon_id_operator"] | undefined | null | Variable<any, string>,
	AND?: Array<ValueTypes["Audience_Icon_where_and"] | undefined | null> | undefined | null | Variable<any, string>,
	OR?: Array<ValueTypes["Audience_Icon_where_or"] | undefined | null> | undefined | null | Variable<any, string>
};
	["Audience_Icon_where_or"]: {
	alt?: ValueTypes["Audience_Icon_alt_operator"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["Audience_Icon_updatedAt_operator"] | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["Audience_Icon_createdAt_operator"] | undefined | null | Variable<any, string>,
	url?: ValueTypes["Audience_Icon_url_operator"] | undefined | null | Variable<any, string>,
	filename?: ValueTypes["Audience_Icon_filename_operator"] | undefined | null | Variable<any, string>,
	mimeType?: ValueTypes["Audience_Icon_mimeType_operator"] | undefined | null | Variable<any, string>,
	filesize?: ValueTypes["Audience_Icon_filesize_operator"] | undefined | null | Variable<any, string>,
	width?: ValueTypes["Audience_Icon_width_operator"] | undefined | null | Variable<any, string>,
	height?: ValueTypes["Audience_Icon_height_operator"] | undefined | null | Variable<any, string>,
	focalX?: ValueTypes["Audience_Icon_focalX_operator"] | undefined | null | Variable<any, string>,
	focalY?: ValueTypes["Audience_Icon_focalY_operator"] | undefined | null | Variable<any, string>,
	sizes__small__url?: ValueTypes["Audience_Icon_sizes__small__url_operator"] | undefined | null | Variable<any, string>,
	sizes__small__width?: ValueTypes["Audience_Icon_sizes__small__width_operator"] | undefined | null | Variable<any, string>,
	sizes__small__height?: ValueTypes["Audience_Icon_sizes__small__height_operator"] | undefined | null | Variable<any, string>,
	sizes__small__mimeType?: ValueTypes["Audience_Icon_sizes__small__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__small__filesize?: ValueTypes["Audience_Icon_sizes__small__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__small__filename?: ValueTypes["Audience_Icon_sizes__small__filename_operator"] | undefined | null | Variable<any, string>,
	sizes__medium__url?: ValueTypes["Audience_Icon_sizes__medium__url_operator"] | undefined | null | Variable<any, string>,
	sizes__medium__width?: ValueTypes["Audience_Icon_sizes__medium__width_operator"] | undefined | null | Variable<any, string>,
	sizes__medium__height?: ValueTypes["Audience_Icon_sizes__medium__height_operator"] | undefined | null | Variable<any, string>,
	sizes__medium__mimeType?: ValueTypes["Audience_Icon_sizes__medium__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__medium__filesize?: ValueTypes["Audience_Icon_sizes__medium__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__medium__filename?: ValueTypes["Audience_Icon_sizes__medium__filename_operator"] | undefined | null | Variable<any, string>,
	sizes__large__url?: ValueTypes["Audience_Icon_sizes__large__url_operator"] | undefined | null | Variable<any, string>,
	sizes__large__width?: ValueTypes["Audience_Icon_sizes__large__width_operator"] | undefined | null | Variable<any, string>,
	sizes__large__height?: ValueTypes["Audience_Icon_sizes__large__height_operator"] | undefined | null | Variable<any, string>,
	sizes__large__mimeType?: ValueTypes["Audience_Icon_sizes__large__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__large__filesize?: ValueTypes["Audience_Icon_sizes__large__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__large__filename?: ValueTypes["Audience_Icon_sizes__large__filename_operator"] | undefined | null | Variable<any, string>,
	sizes__small_jpeg__url?: ValueTypes["Audience_Icon_sizes__small_jpeg__url_operator"] | undefined | null | Variable<any, string>,
	sizes__small_jpeg__width?: ValueTypes["Audience_Icon_sizes__small_jpeg__width_operator"] | undefined | null | Variable<any, string>,
	sizes__small_jpeg__height?: ValueTypes["Audience_Icon_sizes__small_jpeg__height_operator"] | undefined | null | Variable<any, string>,
	sizes__small_jpeg__mimeType?: ValueTypes["Audience_Icon_sizes__small_jpeg__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__small_jpeg__filesize?: ValueTypes["Audience_Icon_sizes__small_jpeg__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__small_jpeg__filename?: ValueTypes["Audience_Icon_sizes__small_jpeg__filename_operator"] | undefined | null | Variable<any, string>,
	sizes__medium_jpeg__url?: ValueTypes["Audience_Icon_sizes__medium_jpeg__url_operator"] | undefined | null | Variable<any, string>,
	sizes__medium_jpeg__width?: ValueTypes["Audience_Icon_sizes__medium_jpeg__width_operator"] | undefined | null | Variable<any, string>,
	sizes__medium_jpeg__height?: ValueTypes["Audience_Icon_sizes__medium_jpeg__height_operator"] | undefined | null | Variable<any, string>,
	sizes__medium_jpeg__mimeType?: ValueTypes["Audience_Icon_sizes__medium_jpeg__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__medium_jpeg__filesize?: ValueTypes["Audience_Icon_sizes__medium_jpeg__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__medium_jpeg__filename?: ValueTypes["Audience_Icon_sizes__medium_jpeg__filename_operator"] | undefined | null | Variable<any, string>,
	sizes__large_jpeg__url?: ValueTypes["Audience_Icon_sizes__large_jpeg__url_operator"] | undefined | null | Variable<any, string>,
	sizes__large_jpeg__width?: ValueTypes["Audience_Icon_sizes__large_jpeg__width_operator"] | undefined | null | Variable<any, string>,
	sizes__large_jpeg__height?: ValueTypes["Audience_Icon_sizes__large_jpeg__height_operator"] | undefined | null | Variable<any, string>,
	sizes__large_jpeg__mimeType?: ValueTypes["Audience_Icon_sizes__large_jpeg__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__large_jpeg__filesize?: ValueTypes["Audience_Icon_sizes__large_jpeg__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__large_jpeg__filename?: ValueTypes["Audience_Icon_sizes__large_jpeg__filename_operator"] | undefined | null | Variable<any, string>,
	id?: ValueTypes["Audience_Icon_id_operator"] | undefined | null | Variable<any, string>,
	AND?: Array<ValueTypes["Audience_Icon_where_and"] | undefined | null> | undefined | null | Variable<any, string>,
	OR?: Array<ValueTypes["Audience_Icon_where_or"] | undefined | null> | undefined | null | Variable<any, string>
};
	["Audience_region"]:Audience_region;
	["Date"]: AliasType<{
	id?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	value?:boolean | `@${string}`,
	type?:boolean | `@${string}`,
	start?:boolean | `@${string}`,
	end?:boolean | `@${string}`,
	_title?:boolean | `@${string}`,
	region?:boolean | `@${string}`,
	updatedAt?:boolean | `@${string}`,
	createdAt?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["Date_type"]:Date_type;
	["Date_region"]:Date_region;
	["Location_Sections"]: AliasType<{
	startDatetime?:boolean | `@${string}`,
	endDatetime?:boolean | `@${string}`,
	fullDay?:boolean | `@${string}`,
	repeat?:boolean | `@${string}`,
	recurrance?:ValueTypes["Location_Sections_Recurrance"],
	id?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["Location_Sections_Recurrance"]: AliasType<{
	type?:boolean | `@${string}`,
	weekday?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["Location_Sections_Recurrance_type"]:Location_Sections_Recurrance_type;
	["Location_Sections_Recurrance_weekday"]:Location_Sections_Recurrance_weekday;
	["Event_Ticketing"]: AliasType<{
	startDatetime?:boolean | `@${string}`,
	endDatetime?:boolean | `@${string}`,
	fullDay?:boolean | `@${string}`,
	type?:boolean | `@${string}`,
	ticketingType?:boolean | `@${string}`,
linkedTicketing?: [{	locale?: ValueTypes["LocaleInputType"] | undefined | null | Variable<any, string>,	fallbackLocale?: ValueTypes["FallbackLocaleInputType"] | undefined | null | Variable<any, string>},ValueTypes["Ticketing"]],
	promotionType?:boolean | `@${string}`,
location?: [{	locale?: ValueTypes["LocaleInputType"] | undefined | null | Variable<any, string>,	fallbackLocale?: ValueTypes["FallbackLocaleInputType"] | undefined | null | Variable<any, string>},ValueTypes["Location"]],
	website?:boolean | `@${string}`,
	description?:boolean | `@${string}`,
	id?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["Event_Ticketing_type"]:Event_Ticketing_type;
	["Event_Ticketing_ticketingType"]:Event_Ticketing_ticketingType;
	["Ticketing"]: AliasType<{
	id?:boolean | `@${string}`,
	status?:boolean | `@${string}`,
creator?: [{	locale?: ValueTypes["LocaleInputType"] | undefined | null | Variable<any, string>,	fallbackLocale?: ValueTypes["FallbackLocaleInputType"] | undefined | null | Variable<any, string>},ValueTypes["User"]],
	productType?:boolean | `@${string}`,
	productName?:boolean | `@${string}`,
	referenceId?:boolean | `@${string}`,
linkedEvent?: [{	locale?: ValueTypes["LocaleInputType"] | undefined | null | Variable<any, string>,	fallbackLocale?: ValueTypes["FallbackLocaleInputType"] | undefined | null | Variable<any, string>},ValueTypes["Event"]],
	CheckInLevel?:boolean | `@${string}`,
	displayPrice?:boolean | `@${string}`,
	tnc?:boolean | `@${string}`,
	cutoffHours?:boolean | `@${string}`,
	sessions?:ValueTypes["TicketingVariants"],
	updatedAt?:boolean | `@${string}`,
	createdAt?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["Ticketing_status"]:Ticketing_status;
	["Ticketing_productType"]:Ticketing_productType;
	["Ticketing_CheckInLevel"]:Ticketing_CheckInLevel;
	["TicketingVariants"]: AliasType<{
	variantId?:boolean | `@${string}`,
	productId?:boolean | `@${string}`,
	startDate?:boolean | `@${string}`,
	endDate?:boolean | `@${string}`,
	inventories?:ValueTypes["Inventories"],
	id?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["Inventories"]: AliasType<{
	name?:boolean | `@${string}`,
	quantity?:boolean | `@${string}`,
	unitPrice?:boolean | `@${string}`,
	handlingFee?:boolean | `@${string}`,
	totalCost?:boolean | `@${string}`,
	minPurchase?:boolean | `@${string}`,
	maxPurchase?:boolean | `@${string}`,
	stocks?:ValueTypes["Inventories_Stocks"],
	remarks?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["Inventories_Stocks"]: AliasType<{
	stockId?:boolean | `@${string}`,
	remaining?:boolean | `@${string}`,
	sold?:boolean | `@${string}`,
	onHold?:boolean | `@${string}`,
	checkedIn?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["Event_Ticketing_promotionType"]:Event_Ticketing_promotionType;
	["Event_Content"]: AliasType<{		["...on RichText"]?: ValueTypes["RichText"],
		["...on Gallery"]?: ValueTypes["Gallery"],
		["...on Image"]?: ValueTypes["Image"],
		["...on Video"]?: ValueTypes["Video"]
		__typename?: boolean | `@${string}`
}>;
	["Event_Criteria"]: AliasType<{
categories?: [{	locale?: ValueTypes["LocaleInputType"] | undefined | null | Variable<any, string>,	fallbackLocale?: ValueTypes["FallbackLocaleInputType"] | undefined | null | Variable<any, string>},ValueTypes["EventCategory"]],
audiences?: [{	locale?: ValueTypes["LocaleInputType"] | undefined | null | Variable<any, string>,	fallbackLocale?: ValueTypes["FallbackLocaleInputType"] | undefined | null | Variable<any, string>},ValueTypes["Audience"]],
tags?: [{	locale?: ValueTypes["LocaleInputType"] | undefined | null | Variable<any, string>,	fallbackLocale?: ValueTypes["FallbackLocaleInputType"] | undefined | null | Variable<any, string>},ValueTypes["Tag"]],
specialTags?: [{	locale?: ValueTypes["LocaleInputType"] | undefined | null | Variable<any, string>,	fallbackLocale?: ValueTypes["FallbackLocaleInputType"] | undefined | null | Variable<any, string>},ValueTypes["SpecialTag"]],
		__typename?: boolean | `@${string}`
}>;
	["SpecialTag"]: AliasType<{
	id?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	value?:boolean | `@${string}`,
	region?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["SpecialTag_region"]:SpecialTag_region;
	["Event_Contact"]: AliasType<{
	code?:boolean | `@${string}`,
	phone?:boolean | `@${string}`,
	page?:boolean | `@${string}`,
	email?:boolean | `@${string}`,
privacy?: [{	depth?: number | undefined | null | Variable<any, string>},boolean | `@${string}`],
		__typename?: boolean | `@${string}`
}>;
	["Event_Fees"]: AliasType<{
	fee?:boolean | `@${string}`,
	remark?:boolean | `@${string}`,
	id?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["Event_Products"]: AliasType<{
	startDateTime?:boolean | `@${string}`,
	endDateTime?:boolean | `@${string}`,
	fullDay?:boolean | `@${string}`,
linkedProduct?: [{	locale?: ValueTypes["LocaleInputType"] | undefined | null | Variable<any, string>,	fallbackLocale?: ValueTypes["FallbackLocaleInputType"] | undefined | null | Variable<any, string>},ValueTypes["Product"]],
	id?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["Product"]: AliasType<{
	id?:boolean | `@${string}`,
	status?:boolean | `@${string}`,
creator?: [{	locale?: ValueTypes["LocaleInputType"] | undefined | null | Variable<any, string>,	fallbackLocale?: ValueTypes["FallbackLocaleInputType"] | undefined | null | Variable<any, string>},ValueTypes["User"]],
	productType?:boolean | `@${string}`,
	productName?:boolean | `@${string}`,
	referenceId?:boolean | `@${string}`,
linkedEvent?: [{	locale?: ValueTypes["LocaleInputType"] | undefined | null | Variable<any, string>,	fallbackLocale?: ValueTypes["FallbackLocaleInputType"] | undefined | null | Variable<any, string>},ValueTypes["Event"]],
	CheckInLevel?:boolean | `@${string}`,
thumbnail?: [{	locale?: ValueTypes["LocaleInputType"] | undefined | null | Variable<any, string>,	fallbackLocale?: ValueTypes["FallbackLocaleInputType"] | undefined | null | Variable<any, string>,	where?: ValueTypes["Product_Thumbnail_where"] | undefined | null | Variable<any, string>},ValueTypes["Media"]],
	displayPrice?:boolean | `@${string}`,
	transactionType?:boolean | `@${string}`,
	website?:boolean | `@${string}`,
	tnc?:boolean | `@${string}`,
	remarks?:boolean | `@${string}`,
	sessions?:ValueTypes["ProductVariants"],
	updatedAt?:boolean | `@${string}`,
	createdAt?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["Product_status"]:Product_status;
	["Product_productType"]:Product_productType;
	["Product_CheckInLevel"]:Product_CheckInLevel;
	["Product_Thumbnail_where"]: {
	alt?: ValueTypes["Product_Thumbnail_alt_operator"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["Product_Thumbnail_updatedAt_operator"] | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["Product_Thumbnail_createdAt_operator"] | undefined | null | Variable<any, string>,
	url?: ValueTypes["Product_Thumbnail_url_operator"] | undefined | null | Variable<any, string>,
	filename?: ValueTypes["Product_Thumbnail_filename_operator"] | undefined | null | Variable<any, string>,
	mimeType?: ValueTypes["Product_Thumbnail_mimeType_operator"] | undefined | null | Variable<any, string>,
	filesize?: ValueTypes["Product_Thumbnail_filesize_operator"] | undefined | null | Variable<any, string>,
	width?: ValueTypes["Product_Thumbnail_width_operator"] | undefined | null | Variable<any, string>,
	height?: ValueTypes["Product_Thumbnail_height_operator"] | undefined | null | Variable<any, string>,
	focalX?: ValueTypes["Product_Thumbnail_focalX_operator"] | undefined | null | Variable<any, string>,
	focalY?: ValueTypes["Product_Thumbnail_focalY_operator"] | undefined | null | Variable<any, string>,
	sizes__small__url?: ValueTypes["Product_Thumbnail_sizes__small__url_operator"] | undefined | null | Variable<any, string>,
	sizes__small__width?: ValueTypes["Product_Thumbnail_sizes__small__width_operator"] | undefined | null | Variable<any, string>,
	sizes__small__height?: ValueTypes["Product_Thumbnail_sizes__small__height_operator"] | undefined | null | Variable<any, string>,
	sizes__small__mimeType?: ValueTypes["Product_Thumbnail_sizes__small__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__small__filesize?: ValueTypes["Product_Thumbnail_sizes__small__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__small__filename?: ValueTypes["Product_Thumbnail_sizes__small__filename_operator"] | undefined | null | Variable<any, string>,
	sizes__medium__url?: ValueTypes["Product_Thumbnail_sizes__medium__url_operator"] | undefined | null | Variable<any, string>,
	sizes__medium__width?: ValueTypes["Product_Thumbnail_sizes__medium__width_operator"] | undefined | null | Variable<any, string>,
	sizes__medium__height?: ValueTypes["Product_Thumbnail_sizes__medium__height_operator"] | undefined | null | Variable<any, string>,
	sizes__medium__mimeType?: ValueTypes["Product_Thumbnail_sizes__medium__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__medium__filesize?: ValueTypes["Product_Thumbnail_sizes__medium__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__medium__filename?: ValueTypes["Product_Thumbnail_sizes__medium__filename_operator"] | undefined | null | Variable<any, string>,
	sizes__large__url?: ValueTypes["Product_Thumbnail_sizes__large__url_operator"] | undefined | null | Variable<any, string>,
	sizes__large__width?: ValueTypes["Product_Thumbnail_sizes__large__width_operator"] | undefined | null | Variable<any, string>,
	sizes__large__height?: ValueTypes["Product_Thumbnail_sizes__large__height_operator"] | undefined | null | Variable<any, string>,
	sizes__large__mimeType?: ValueTypes["Product_Thumbnail_sizes__large__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__large__filesize?: ValueTypes["Product_Thumbnail_sizes__large__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__large__filename?: ValueTypes["Product_Thumbnail_sizes__large__filename_operator"] | undefined | null | Variable<any, string>,
	sizes__small_jpeg__url?: ValueTypes["Product_Thumbnail_sizes__small_jpeg__url_operator"] | undefined | null | Variable<any, string>,
	sizes__small_jpeg__width?: ValueTypes["Product_Thumbnail_sizes__small_jpeg__width_operator"] | undefined | null | Variable<any, string>,
	sizes__small_jpeg__height?: ValueTypes["Product_Thumbnail_sizes__small_jpeg__height_operator"] | undefined | null | Variable<any, string>,
	sizes__small_jpeg__mimeType?: ValueTypes["Product_Thumbnail_sizes__small_jpeg__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__small_jpeg__filesize?: ValueTypes["Product_Thumbnail_sizes__small_jpeg__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__small_jpeg__filename?: ValueTypes["Product_Thumbnail_sizes__small_jpeg__filename_operator"] | undefined | null | Variable<any, string>,
	sizes__medium_jpeg__url?: ValueTypes["Product_Thumbnail_sizes__medium_jpeg__url_operator"] | undefined | null | Variable<any, string>,
	sizes__medium_jpeg__width?: ValueTypes["Product_Thumbnail_sizes__medium_jpeg__width_operator"] | undefined | null | Variable<any, string>,
	sizes__medium_jpeg__height?: ValueTypes["Product_Thumbnail_sizes__medium_jpeg__height_operator"] | undefined | null | Variable<any, string>,
	sizes__medium_jpeg__mimeType?: ValueTypes["Product_Thumbnail_sizes__medium_jpeg__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__medium_jpeg__filesize?: ValueTypes["Product_Thumbnail_sizes__medium_jpeg__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__medium_jpeg__filename?: ValueTypes["Product_Thumbnail_sizes__medium_jpeg__filename_operator"] | undefined | null | Variable<any, string>,
	sizes__large_jpeg__url?: ValueTypes["Product_Thumbnail_sizes__large_jpeg__url_operator"] | undefined | null | Variable<any, string>,
	sizes__large_jpeg__width?: ValueTypes["Product_Thumbnail_sizes__large_jpeg__width_operator"] | undefined | null | Variable<any, string>,
	sizes__large_jpeg__height?: ValueTypes["Product_Thumbnail_sizes__large_jpeg__height_operator"] | undefined | null | Variable<any, string>,
	sizes__large_jpeg__mimeType?: ValueTypes["Product_Thumbnail_sizes__large_jpeg__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__large_jpeg__filesize?: ValueTypes["Product_Thumbnail_sizes__large_jpeg__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__large_jpeg__filename?: ValueTypes["Product_Thumbnail_sizes__large_jpeg__filename_operator"] | undefined | null | Variable<any, string>,
	id?: ValueTypes["Product_Thumbnail_id_operator"] | undefined | null | Variable<any, string>,
	AND?: Array<ValueTypes["Product_Thumbnail_where_and"] | undefined | null> | undefined | null | Variable<any, string>,
	OR?: Array<ValueTypes["Product_Thumbnail_where_or"] | undefined | null> | undefined | null | Variable<any, string>
};
	["Product_Thumbnail_alt_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Product_Thumbnail_updatedAt_operator"]: {
	equals?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	not_equals?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	greater_than_equal?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	greater_than?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	less_than_equal?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	less_than?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	like?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Product_Thumbnail_createdAt_operator"]: {
	equals?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	not_equals?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	greater_than_equal?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	greater_than?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	less_than_equal?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	less_than?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	like?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Product_Thumbnail_url_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Product_Thumbnail_filename_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Product_Thumbnail_mimeType_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Product_Thumbnail_filesize_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Product_Thumbnail_width_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Product_Thumbnail_height_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Product_Thumbnail_focalX_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Product_Thumbnail_focalY_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Product_Thumbnail_sizes__small__url_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Product_Thumbnail_sizes__small__width_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Product_Thumbnail_sizes__small__height_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Product_Thumbnail_sizes__small__mimeType_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Product_Thumbnail_sizes__small__filesize_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Product_Thumbnail_sizes__small__filename_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Product_Thumbnail_sizes__medium__url_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Product_Thumbnail_sizes__medium__width_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Product_Thumbnail_sizes__medium__height_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Product_Thumbnail_sizes__medium__mimeType_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Product_Thumbnail_sizes__medium__filesize_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Product_Thumbnail_sizes__medium__filename_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Product_Thumbnail_sizes__large__url_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Product_Thumbnail_sizes__large__width_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Product_Thumbnail_sizes__large__height_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Product_Thumbnail_sizes__large__mimeType_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Product_Thumbnail_sizes__large__filesize_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Product_Thumbnail_sizes__large__filename_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Product_Thumbnail_sizes__small_jpeg__url_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Product_Thumbnail_sizes__small_jpeg__width_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Product_Thumbnail_sizes__small_jpeg__height_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Product_Thumbnail_sizes__small_jpeg__mimeType_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Product_Thumbnail_sizes__small_jpeg__filesize_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Product_Thumbnail_sizes__small_jpeg__filename_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Product_Thumbnail_sizes__medium_jpeg__url_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Product_Thumbnail_sizes__medium_jpeg__width_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Product_Thumbnail_sizes__medium_jpeg__height_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Product_Thumbnail_sizes__medium_jpeg__mimeType_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Product_Thumbnail_sizes__medium_jpeg__filesize_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Product_Thumbnail_sizes__medium_jpeg__filename_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Product_Thumbnail_sizes__large_jpeg__url_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Product_Thumbnail_sizes__large_jpeg__width_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Product_Thumbnail_sizes__large_jpeg__height_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Product_Thumbnail_sizes__large_jpeg__mimeType_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Product_Thumbnail_sizes__large_jpeg__filesize_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Product_Thumbnail_sizes__large_jpeg__filename_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Product_Thumbnail_id_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Product_Thumbnail_where_and"]: {
	alt?: ValueTypes["Product_Thumbnail_alt_operator"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["Product_Thumbnail_updatedAt_operator"] | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["Product_Thumbnail_createdAt_operator"] | undefined | null | Variable<any, string>,
	url?: ValueTypes["Product_Thumbnail_url_operator"] | undefined | null | Variable<any, string>,
	filename?: ValueTypes["Product_Thumbnail_filename_operator"] | undefined | null | Variable<any, string>,
	mimeType?: ValueTypes["Product_Thumbnail_mimeType_operator"] | undefined | null | Variable<any, string>,
	filesize?: ValueTypes["Product_Thumbnail_filesize_operator"] | undefined | null | Variable<any, string>,
	width?: ValueTypes["Product_Thumbnail_width_operator"] | undefined | null | Variable<any, string>,
	height?: ValueTypes["Product_Thumbnail_height_operator"] | undefined | null | Variable<any, string>,
	focalX?: ValueTypes["Product_Thumbnail_focalX_operator"] | undefined | null | Variable<any, string>,
	focalY?: ValueTypes["Product_Thumbnail_focalY_operator"] | undefined | null | Variable<any, string>,
	sizes__small__url?: ValueTypes["Product_Thumbnail_sizes__small__url_operator"] | undefined | null | Variable<any, string>,
	sizes__small__width?: ValueTypes["Product_Thumbnail_sizes__small__width_operator"] | undefined | null | Variable<any, string>,
	sizes__small__height?: ValueTypes["Product_Thumbnail_sizes__small__height_operator"] | undefined | null | Variable<any, string>,
	sizes__small__mimeType?: ValueTypes["Product_Thumbnail_sizes__small__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__small__filesize?: ValueTypes["Product_Thumbnail_sizes__small__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__small__filename?: ValueTypes["Product_Thumbnail_sizes__small__filename_operator"] | undefined | null | Variable<any, string>,
	sizes__medium__url?: ValueTypes["Product_Thumbnail_sizes__medium__url_operator"] | undefined | null | Variable<any, string>,
	sizes__medium__width?: ValueTypes["Product_Thumbnail_sizes__medium__width_operator"] | undefined | null | Variable<any, string>,
	sizes__medium__height?: ValueTypes["Product_Thumbnail_sizes__medium__height_operator"] | undefined | null | Variable<any, string>,
	sizes__medium__mimeType?: ValueTypes["Product_Thumbnail_sizes__medium__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__medium__filesize?: ValueTypes["Product_Thumbnail_sizes__medium__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__medium__filename?: ValueTypes["Product_Thumbnail_sizes__medium__filename_operator"] | undefined | null | Variable<any, string>,
	sizes__large__url?: ValueTypes["Product_Thumbnail_sizes__large__url_operator"] | undefined | null | Variable<any, string>,
	sizes__large__width?: ValueTypes["Product_Thumbnail_sizes__large__width_operator"] | undefined | null | Variable<any, string>,
	sizes__large__height?: ValueTypes["Product_Thumbnail_sizes__large__height_operator"] | undefined | null | Variable<any, string>,
	sizes__large__mimeType?: ValueTypes["Product_Thumbnail_sizes__large__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__large__filesize?: ValueTypes["Product_Thumbnail_sizes__large__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__large__filename?: ValueTypes["Product_Thumbnail_sizes__large__filename_operator"] | undefined | null | Variable<any, string>,
	sizes__small_jpeg__url?: ValueTypes["Product_Thumbnail_sizes__small_jpeg__url_operator"] | undefined | null | Variable<any, string>,
	sizes__small_jpeg__width?: ValueTypes["Product_Thumbnail_sizes__small_jpeg__width_operator"] | undefined | null | Variable<any, string>,
	sizes__small_jpeg__height?: ValueTypes["Product_Thumbnail_sizes__small_jpeg__height_operator"] | undefined | null | Variable<any, string>,
	sizes__small_jpeg__mimeType?: ValueTypes["Product_Thumbnail_sizes__small_jpeg__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__small_jpeg__filesize?: ValueTypes["Product_Thumbnail_sizes__small_jpeg__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__small_jpeg__filename?: ValueTypes["Product_Thumbnail_sizes__small_jpeg__filename_operator"] | undefined | null | Variable<any, string>,
	sizes__medium_jpeg__url?: ValueTypes["Product_Thumbnail_sizes__medium_jpeg__url_operator"] | undefined | null | Variable<any, string>,
	sizes__medium_jpeg__width?: ValueTypes["Product_Thumbnail_sizes__medium_jpeg__width_operator"] | undefined | null | Variable<any, string>,
	sizes__medium_jpeg__height?: ValueTypes["Product_Thumbnail_sizes__medium_jpeg__height_operator"] | undefined | null | Variable<any, string>,
	sizes__medium_jpeg__mimeType?: ValueTypes["Product_Thumbnail_sizes__medium_jpeg__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__medium_jpeg__filesize?: ValueTypes["Product_Thumbnail_sizes__medium_jpeg__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__medium_jpeg__filename?: ValueTypes["Product_Thumbnail_sizes__medium_jpeg__filename_operator"] | undefined | null | Variable<any, string>,
	sizes__large_jpeg__url?: ValueTypes["Product_Thumbnail_sizes__large_jpeg__url_operator"] | undefined | null | Variable<any, string>,
	sizes__large_jpeg__width?: ValueTypes["Product_Thumbnail_sizes__large_jpeg__width_operator"] | undefined | null | Variable<any, string>,
	sizes__large_jpeg__height?: ValueTypes["Product_Thumbnail_sizes__large_jpeg__height_operator"] | undefined | null | Variable<any, string>,
	sizes__large_jpeg__mimeType?: ValueTypes["Product_Thumbnail_sizes__large_jpeg__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__large_jpeg__filesize?: ValueTypes["Product_Thumbnail_sizes__large_jpeg__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__large_jpeg__filename?: ValueTypes["Product_Thumbnail_sizes__large_jpeg__filename_operator"] | undefined | null | Variable<any, string>,
	id?: ValueTypes["Product_Thumbnail_id_operator"] | undefined | null | Variable<any, string>,
	AND?: Array<ValueTypes["Product_Thumbnail_where_and"] | undefined | null> | undefined | null | Variable<any, string>,
	OR?: Array<ValueTypes["Product_Thumbnail_where_or"] | undefined | null> | undefined | null | Variable<any, string>
};
	["Product_Thumbnail_where_or"]: {
	alt?: ValueTypes["Product_Thumbnail_alt_operator"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["Product_Thumbnail_updatedAt_operator"] | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["Product_Thumbnail_createdAt_operator"] | undefined | null | Variable<any, string>,
	url?: ValueTypes["Product_Thumbnail_url_operator"] | undefined | null | Variable<any, string>,
	filename?: ValueTypes["Product_Thumbnail_filename_operator"] | undefined | null | Variable<any, string>,
	mimeType?: ValueTypes["Product_Thumbnail_mimeType_operator"] | undefined | null | Variable<any, string>,
	filesize?: ValueTypes["Product_Thumbnail_filesize_operator"] | undefined | null | Variable<any, string>,
	width?: ValueTypes["Product_Thumbnail_width_operator"] | undefined | null | Variable<any, string>,
	height?: ValueTypes["Product_Thumbnail_height_operator"] | undefined | null | Variable<any, string>,
	focalX?: ValueTypes["Product_Thumbnail_focalX_operator"] | undefined | null | Variable<any, string>,
	focalY?: ValueTypes["Product_Thumbnail_focalY_operator"] | undefined | null | Variable<any, string>,
	sizes__small__url?: ValueTypes["Product_Thumbnail_sizes__small__url_operator"] | undefined | null | Variable<any, string>,
	sizes__small__width?: ValueTypes["Product_Thumbnail_sizes__small__width_operator"] | undefined | null | Variable<any, string>,
	sizes__small__height?: ValueTypes["Product_Thumbnail_sizes__small__height_operator"] | undefined | null | Variable<any, string>,
	sizes__small__mimeType?: ValueTypes["Product_Thumbnail_sizes__small__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__small__filesize?: ValueTypes["Product_Thumbnail_sizes__small__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__small__filename?: ValueTypes["Product_Thumbnail_sizes__small__filename_operator"] | undefined | null | Variable<any, string>,
	sizes__medium__url?: ValueTypes["Product_Thumbnail_sizes__medium__url_operator"] | undefined | null | Variable<any, string>,
	sizes__medium__width?: ValueTypes["Product_Thumbnail_sizes__medium__width_operator"] | undefined | null | Variable<any, string>,
	sizes__medium__height?: ValueTypes["Product_Thumbnail_sizes__medium__height_operator"] | undefined | null | Variable<any, string>,
	sizes__medium__mimeType?: ValueTypes["Product_Thumbnail_sizes__medium__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__medium__filesize?: ValueTypes["Product_Thumbnail_sizes__medium__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__medium__filename?: ValueTypes["Product_Thumbnail_sizes__medium__filename_operator"] | undefined | null | Variable<any, string>,
	sizes__large__url?: ValueTypes["Product_Thumbnail_sizes__large__url_operator"] | undefined | null | Variable<any, string>,
	sizes__large__width?: ValueTypes["Product_Thumbnail_sizes__large__width_operator"] | undefined | null | Variable<any, string>,
	sizes__large__height?: ValueTypes["Product_Thumbnail_sizes__large__height_operator"] | undefined | null | Variable<any, string>,
	sizes__large__mimeType?: ValueTypes["Product_Thumbnail_sizes__large__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__large__filesize?: ValueTypes["Product_Thumbnail_sizes__large__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__large__filename?: ValueTypes["Product_Thumbnail_sizes__large__filename_operator"] | undefined | null | Variable<any, string>,
	sizes__small_jpeg__url?: ValueTypes["Product_Thumbnail_sizes__small_jpeg__url_operator"] | undefined | null | Variable<any, string>,
	sizes__small_jpeg__width?: ValueTypes["Product_Thumbnail_sizes__small_jpeg__width_operator"] | undefined | null | Variable<any, string>,
	sizes__small_jpeg__height?: ValueTypes["Product_Thumbnail_sizes__small_jpeg__height_operator"] | undefined | null | Variable<any, string>,
	sizes__small_jpeg__mimeType?: ValueTypes["Product_Thumbnail_sizes__small_jpeg__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__small_jpeg__filesize?: ValueTypes["Product_Thumbnail_sizes__small_jpeg__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__small_jpeg__filename?: ValueTypes["Product_Thumbnail_sizes__small_jpeg__filename_operator"] | undefined | null | Variable<any, string>,
	sizes__medium_jpeg__url?: ValueTypes["Product_Thumbnail_sizes__medium_jpeg__url_operator"] | undefined | null | Variable<any, string>,
	sizes__medium_jpeg__width?: ValueTypes["Product_Thumbnail_sizes__medium_jpeg__width_operator"] | undefined | null | Variable<any, string>,
	sizes__medium_jpeg__height?: ValueTypes["Product_Thumbnail_sizes__medium_jpeg__height_operator"] | undefined | null | Variable<any, string>,
	sizes__medium_jpeg__mimeType?: ValueTypes["Product_Thumbnail_sizes__medium_jpeg__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__medium_jpeg__filesize?: ValueTypes["Product_Thumbnail_sizes__medium_jpeg__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__medium_jpeg__filename?: ValueTypes["Product_Thumbnail_sizes__medium_jpeg__filename_operator"] | undefined | null | Variable<any, string>,
	sizes__large_jpeg__url?: ValueTypes["Product_Thumbnail_sizes__large_jpeg__url_operator"] | undefined | null | Variable<any, string>,
	sizes__large_jpeg__width?: ValueTypes["Product_Thumbnail_sizes__large_jpeg__width_operator"] | undefined | null | Variable<any, string>,
	sizes__large_jpeg__height?: ValueTypes["Product_Thumbnail_sizes__large_jpeg__height_operator"] | undefined | null | Variable<any, string>,
	sizes__large_jpeg__mimeType?: ValueTypes["Product_Thumbnail_sizes__large_jpeg__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__large_jpeg__filesize?: ValueTypes["Product_Thumbnail_sizes__large_jpeg__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__large_jpeg__filename?: ValueTypes["Product_Thumbnail_sizes__large_jpeg__filename_operator"] | undefined | null | Variable<any, string>,
	id?: ValueTypes["Product_Thumbnail_id_operator"] | undefined | null | Variable<any, string>,
	AND?: Array<ValueTypes["Product_Thumbnail_where_and"] | undefined | null> | undefined | null | Variable<any, string>,
	OR?: Array<ValueTypes["Product_Thumbnail_where_or"] | undefined | null> | undefined | null | Variable<any, string>
};
	["Product_transactionType"]:Product_transactionType;
	["ProductVariants"]: AliasType<{
	variantId?:boolean | `@${string}`,
	productId?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	inventories?:ValueTypes["Inventories"],
	id?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["Events"]: AliasType<{
	docs?:ValueTypes["Event"],
	hasNextPage?:boolean | `@${string}`,
	hasPrevPage?:boolean | `@${string}`,
	limit?:boolean | `@${string}`,
	nextPage?:boolean | `@${string}`,
	offset?:boolean | `@${string}`,
	page?:boolean | `@${string}`,
	pagingCounter?:boolean | `@${string}`,
	prevPage?:boolean | `@${string}`,
	totalDocs?:boolean | `@${string}`,
	totalPages?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["Event_where"]: {
	region?: ValueTypes["Event_region_operator"] | undefined | null | Variable<any, string>,
	creator?: ValueTypes["Event_creator_operator"] | undefined | null | Variable<any, string>,
	permalink?: ValueTypes["Event_permalink_operator"] | undefined | null | Variable<any, string>,
	status?: ValueTypes["Event_status_operator"] | undefined | null | Variable<any, string>,
	level?: ValueTypes["Event_level_operator"] | undefined | null | Variable<any, string>,
	parent?: ValueTypes["Event_parent_operator"] | undefined | null | Variable<any, string>,
	name?: ValueTypes["Event_name_operator"] | undefined | null | Variable<any, string>,
	chatbotEventTitle?: ValueTypes["Event_chatbotEventTitle_operator"] | undefined | null | Variable<any, string>,
	chatbotSequence?: ValueTypes["Event_chatbotSequence_operator"] | undefined | null | Variable<any, string>,
	thumbnail?: ValueTypes["Event_thumbnail_operator"] | undefined | null | Variable<any, string>,
	banner?: ValueTypes["Event_banner_operator"] | undefined | null | Variable<any, string>,
	mobileBanner?: ValueTypes["Event_mobileBanner_operator"] | undefined | null | Variable<any, string>,
	sections__startDatetime?: ValueTypes["Event_sections__startDatetime_operator"] | undefined | null | Variable<any, string>,
	sections__endDatetime?: ValueTypes["Event_sections__endDatetime_operator"] | undefined | null | Variable<any, string>,
	sections__fullDay?: ValueTypes["Event_sections__fullDay_operator"] | undefined | null | Variable<any, string>,
	sections__repeat?: ValueTypes["Event_sections__repeat_operator"] | undefined | null | Variable<any, string>,
	sections__toThisDay?: ValueTypes["Event_sections__toThisDay_operator"] | undefined | null | Variable<any, string>,
	sections__recurrance__type?: ValueTypes["Event_sections__recurrance__type_operator"] | undefined | null | Variable<any, string>,
	sections__recurrance__weekday?: ValueTypes["Event_sections__recurrance__weekday_operator"] | undefined | null | Variable<any, string>,
	sections__sameLocation?: ValueTypes["Event_sections__sameLocation_operator"] | undefined | null | Variable<any, string>,
	sections__location?: ValueTypes["Event_sections__location_operator"] | undefined | null | Variable<any, string>,
	sections__district?: ValueTypes["Event_sections__district_operator"] | undefined | null | Variable<any, string>,
	sections__coordinate?: ValueTypes["Event_sections__coordinate_operator"] | undefined | null | Variable<any, string>,
	sections__linkAddress?: ValueTypes["Event_sections__linkAddress_operator"] | undefined | null | Variable<any, string>,
	sections__address?: ValueTypes["Event_sections__address_operator"] | undefined | null | Variable<any, string>,
	sections__addressCoordinate?: ValueTypes["Event_sections__addressCoordinate_operator"] | undefined | null | Variable<any, string>,
	sections__id?: ValueTypes["Event_sections__id_operator"] | undefined | null | Variable<any, string>,
	ticketing__startDatetime?: ValueTypes["Event_ticketing__startDatetime_operator"] | undefined | null | Variable<any, string>,
	ticketing__endDatetime?: ValueTypes["Event_ticketing__endDatetime_operator"] | undefined | null | Variable<any, string>,
	ticketing__fullDay?: ValueTypes["Event_ticketing__fullDay_operator"] | undefined | null | Variable<any, string>,
	ticketing__type?: ValueTypes["Event_ticketing__type_operator"] | undefined | null | Variable<any, string>,
	ticketing__ticketingType?: ValueTypes["Event_ticketing__ticketingType_operator"] | undefined | null | Variable<any, string>,
	ticketing__linkedTicketing?: ValueTypes["Event_ticketing__linkedTicketing_operator"] | undefined | null | Variable<any, string>,
	ticketing__promotionType?: ValueTypes["Event_ticketing__promotionType_operator"] | undefined | null | Variable<any, string>,
	ticketing__location?: ValueTypes["Event_ticketing__location_operator"] | undefined | null | Variable<any, string>,
	ticketing__website?: ValueTypes["Event_ticketing__website_operator"] | undefined | null | Variable<any, string>,
	ticketing__description?: ValueTypes["Event_ticketing__description_operator"] | undefined | null | Variable<any, string>,
	ticketing__id?: ValueTypes["Event_ticketing__id_operator"] | undefined | null | Variable<any, string>,
	legacyContent?: ValueTypes["Event_legacyContent_operator"] | undefined | null | Variable<any, string>,
	showLegacyContent?: ValueTypes["Event_showLegacyContent_operator"] | undefined | null | Variable<any, string>,
	criteria__categories?: ValueTypes["Event_criteria__categories_operator"] | undefined | null | Variable<any, string>,
	criteria__audiences?: ValueTypes["Event_criteria__audiences_operator"] | undefined | null | Variable<any, string>,
	criteria__tags?: ValueTypes["Event_criteria__tags_operator"] | undefined | null | Variable<any, string>,
	criteria__specialTags?: ValueTypes["Event_criteria__specialTags_operator"] | undefined | null | Variable<any, string>,
	walkIn?: ValueTypes["Event_walkIn_operator"] | undefined | null | Variable<any, string>,
	contact__code?: ValueTypes["Event_contact__code_operator"] | undefined | null | Variable<any, string>,
	contact__phone?: ValueTypes["Event_contact__phone_operator"] | undefined | null | Variable<any, string>,
	contact__page?: ValueTypes["Event_contact__page_operator"] | undefined | null | Variable<any, string>,
	contact__email?: ValueTypes["Event_contact__email_operator"] | undefined | null | Variable<any, string>,
	contact__privacy?: ValueTypes["Event_contact__privacy_operator"] | undefined | null | Variable<any, string>,
	views?: ValueTypes["Event_views_operator"] | undefined | null | Variable<any, string>,
	free?: ValueTypes["Event_free_operator"] | undefined | null | Variable<any, string>,
	fees__fee?: ValueTypes["Event_fees__fee_operator"] | undefined | null | Variable<any, string>,
	fees__remark?: ValueTypes["Event_fees__remark_operator"] | undefined | null | Variable<any, string>,
	fees__id?: ValueTypes["Event_fees__id_operator"] | undefined | null | Variable<any, string>,
	homePageInternalClick?: ValueTypes["Event_homePageInternalClick_operator"] | undefined | null | Variable<any, string>,
	homePageImpression?: ValueTypes["Event_homePageImpression_operator"] | undefined | null | Variable<any, string>,
	eodClickRate?: ValueTypes["Event_eodClickRate_operator"] | undefined | null | Variable<any, string>,
	eodImpressionRate?: ValueTypes["Event_eodImpressionRate_operator"] | undefined | null | Variable<any, string>,
	top10ClickRateHomepage?: ValueTypes["Event_top10ClickRateHomepage_operator"] | undefined | null | Variable<any, string>,
	top10ImpressionkRateHomepage?: ValueTypes["Event_top10ImpressionkRateHomepage_operator"] | undefined | null | Variable<any, string>,
	otherEventRankingClickRateHomepage?: ValueTypes["Event_otherEventRankingClickRateHomepage_operator"] | undefined | null | Variable<any, string>,
	otherEventRankingImpressionHomePage?: ValueTypes["Event_otherEventRankingImpressionHomePage_operator"] | undefined | null | Variable<any, string>,
	userPreferenceClickRate?: ValueTypes["Event_userPreferenceClickRate_operator"] | undefined | null | Variable<any, string>,
	userPreferenceImpression?: ValueTypes["Event_userPreferenceImpression_operator"] | undefined | null | Variable<any, string>,
	eventSearchInternalClick?: ValueTypes["Event_eventSearchInternalClick_operator"] | undefined | null | Variable<any, string>,
	eventListImpression?: ValueTypes["Event_eventListImpression_operator"] | undefined | null | Variable<any, string>,
	latestEventInternalClick?: ValueTypes["Event_latestEventInternalClick_operator"] | undefined | null | Variable<any, string>,
	latestEventImpression?: ValueTypes["Event_latestEventImpression_operator"] | undefined | null | Variable<any, string>,
	topTenEventRankingInternalClick?: ValueTypes["Event_topTenEventRankingInternalClick_operator"] | undefined | null | Variable<any, string>,
	topTenImpression?: ValueTypes["Event_topTenImpression_operator"] | undefined | null | Variable<any, string>,
	editorChoiceInternalClick?: ValueTypes["Event_editorChoiceInternalClick_operator"] | undefined | null | Variable<any, string>,
	editorChoiceImpression?: ValueTypes["Event_editorChoiceImpression_operator"] | undefined | null | Variable<any, string>,
	otherEventRankingInternalClick?: ValueTypes["Event_otherEventRankingInternalClick_operator"] | undefined | null | Variable<any, string>,
	otherEventRankingImpression?: ValueTypes["Event_otherEventRankingImpression_operator"] | undefined | null | Variable<any, string>,
	bannerInternalClick?: ValueTypes["Event_bannerInternalClick_operator"] | undefined | null | Variable<any, string>,
	miscImpression?: ValueTypes["Event_miscImpression_operator"] | undefined | null | Variable<any, string>,
	miscInternalClick?: ValueTypes["Event_miscInternalClick_operator"] | undefined | null | Variable<any, string>,
	totalImpression?: ValueTypes["Event_totalImpression_operator"] | undefined | null | Variable<any, string>,
	internalClick?: ValueTypes["Event_internalClick_operator"] | undefined | null | Variable<any, string>,
	outboundClick?: ValueTypes["Event_outboundClick_operator"] | undefined | null | Variable<any, string>,
	externalClick?: ValueTypes["Event_externalClick_operator"] | undefined | null | Variable<any, string>,
	_viewsCountByMonth?: ValueTypes["Event__viewsCountByMonth_operator"] | undefined | null | Variable<any, string>,
	products__startDateTime?: ValueTypes["Event_products__startDateTime_operator"] | undefined | null | Variable<any, string>,
	products__endDateTime?: ValueTypes["Event_products__endDateTime_operator"] | undefined | null | Variable<any, string>,
	products__fullDay?: ValueTypes["Event_products__fullDay_operator"] | undefined | null | Variable<any, string>,
	products__linkedProduct?: ValueTypes["Event_products__linkedProduct_operator"] | undefined | null | Variable<any, string>,
	products__id?: ValueTypes["Event_products__id_operator"] | undefined | null | Variable<any, string>,
	legacyGallery?: ValueTypes["Event_legacyGallery_operator"] | undefined | null | Variable<any, string>,
	earliestStartDate?: ValueTypes["Event_earliestStartDate_operator"] | undefined | null | Variable<any, string>,
	latestEndDate?: ValueTypes["Event_latestEndDate_operator"] | undefined | null | Variable<any, string>,
	latestStartDateTime?: ValueTypes["Event_latestStartDateTime_operator"] | undefined | null | Variable<any, string>,
	hasTicketing?: ValueTypes["Event_hasTicketing_operator"] | undefined | null | Variable<any, string>,
	hasProducts?: ValueTypes["Event_hasProducts_operator"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["Event_updatedAt_operator"] | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["Event_createdAt_operator"] | undefined | null | Variable<any, string>,
	id?: ValueTypes["Event_id_operator"] | undefined | null | Variable<any, string>,
	AND?: Array<ValueTypes["Event_where_and"] | undefined | null> | undefined | null | Variable<any, string>,
	OR?: Array<ValueTypes["Event_where_or"] | undefined | null> | undefined | null | Variable<any, string>
};
	["Event_region_operator"]: {
	equals?: ValueTypes["Event_region_Input"] | undefined | null | Variable<any, string>,
	not_equals?: ValueTypes["Event_region_Input"] | undefined | null | Variable<any, string>,
	in?: Array<ValueTypes["Event_region_Input"] | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<ValueTypes["Event_region_Input"] | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<ValueTypes["Event_region_Input"] | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Event_region_Input"]:Event_region_Input;
	["Event_creator_operator"]: {
	equals?: ValueTypes["JSON"] | undefined | null | Variable<any, string>,
	not_equals?: ValueTypes["JSON"] | undefined | null | Variable<any, string>,
	in?: Array<ValueTypes["JSON"] | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<ValueTypes["JSON"] | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<ValueTypes["JSON"] | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Event_permalink_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Event_status_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>
};
	["Event_level_operator"]: {
	equals?: ValueTypes["Event_level_Input"] | undefined | null | Variable<any, string>,
	not_equals?: ValueTypes["Event_level_Input"] | undefined | null | Variable<any, string>,
	in?: Array<ValueTypes["Event_level_Input"] | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<ValueTypes["Event_level_Input"] | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<ValueTypes["Event_level_Input"] | undefined | null> | undefined | null | Variable<any, string>
};
	["Event_level_Input"]:Event_level_Input;
	["Event_parent_operator"]: {
	equals?: ValueTypes["JSON"] | undefined | null | Variable<any, string>,
	not_equals?: ValueTypes["JSON"] | undefined | null | Variable<any, string>,
	in?: Array<ValueTypes["JSON"] | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<ValueTypes["JSON"] | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<ValueTypes["JSON"] | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Event_name_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>
};
	["Event_chatbotEventTitle_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>
};
	["Event_chatbotSequence_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Event_thumbnail_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Event_banner_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Event_mobileBanner_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Event_sections__startDatetime_operator"]: {
	equals?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	not_equals?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	greater_than_equal?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	greater_than?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	less_than_equal?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	less_than?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	like?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>
};
	["Event_sections__endDatetime_operator"]: {
	equals?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	not_equals?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	greater_than_equal?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	greater_than?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	less_than_equal?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	less_than?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	like?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>
};
	["Event_sections__fullDay_operator"]: {
	equals?: boolean | undefined | null | Variable<any, string>,
	not_equals?: boolean | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Event_sections__repeat_operator"]: {
	equals?: boolean | undefined | null | Variable<any, string>,
	not_equals?: boolean | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Event_sections__toThisDay_operator"]: {
	equals?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	not_equals?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	greater_than_equal?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	greater_than?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	less_than_equal?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	less_than?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	like?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>
};
	["Event_sections__recurrance__type_operator"]: {
	equals?: ValueTypes["Event_sections__recurrance__type_Input"] | undefined | null | Variable<any, string>,
	not_equals?: ValueTypes["Event_sections__recurrance__type_Input"] | undefined | null | Variable<any, string>,
	in?: Array<ValueTypes["Event_sections__recurrance__type_Input"] | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<ValueTypes["Event_sections__recurrance__type_Input"] | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<ValueTypes["Event_sections__recurrance__type_Input"] | undefined | null> | undefined | null | Variable<any, string>
};
	["Event_sections__recurrance__type_Input"]:Event_sections__recurrance__type_Input;
	["Event_sections__recurrance__weekday_operator"]: {
	equals?: ValueTypes["Event_sections__recurrance__weekday_Input"] | undefined | null | Variable<any, string>,
	not_equals?: ValueTypes["Event_sections__recurrance__weekday_Input"] | undefined | null | Variable<any, string>,
	in?: Array<ValueTypes["Event_sections__recurrance__weekday_Input"] | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<ValueTypes["Event_sections__recurrance__weekday_Input"] | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<ValueTypes["Event_sections__recurrance__weekday_Input"] | undefined | null> | undefined | null | Variable<any, string>
};
	["Event_sections__recurrance__weekday_Input"]:Event_sections__recurrance__weekday_Input;
	["Event_sections__sameLocation_operator"]: {
	equals?: boolean | undefined | null | Variable<any, string>,
	not_equals?: boolean | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Event_sections__location_operator"]: {
	equals?: ValueTypes["JSON"] | undefined | null | Variable<any, string>,
	not_equals?: ValueTypes["JSON"] | undefined | null | Variable<any, string>,
	in?: Array<ValueTypes["JSON"] | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<ValueTypes["JSON"] | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<ValueTypes["JSON"] | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Event_sections__district_operator"]: {
	equals?: ValueTypes["JSON"] | undefined | null | Variable<any, string>,
	not_equals?: ValueTypes["JSON"] | undefined | null | Variable<any, string>,
	in?: Array<ValueTypes["JSON"] | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<ValueTypes["JSON"] | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<ValueTypes["JSON"] | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Event_sections__coordinate_operator"]: {
	equals?: Array<number | undefined | null> | undefined | null | Variable<any, string>,
	not_equals?: Array<number | undefined | null> | undefined | null | Variable<any, string>,
	greater_than_equal?: Array<number | undefined | null> | undefined | null | Variable<any, string>,
	greater_than?: Array<number | undefined | null> | undefined | null | Variable<any, string>,
	less_than_equal?: Array<number | undefined | null> | undefined | null | Variable<any, string>,
	less_than?: Array<number | undefined | null> | undefined | null | Variable<any, string>,
	near?: Array<number | undefined | null> | undefined | null | Variable<any, string>,
	within?: ValueTypes["GeoJSONObject"] | undefined | null | Variable<any, string>,
	intersects?: ValueTypes["GeoJSONObject"] | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["GeoJSONObject"]: {
	coordinates?: ValueTypes["JSON"] | undefined | null | Variable<any, string>,
	type?: string | undefined | null | Variable<any, string>
};
	["Event_sections__linkAddress_operator"]: {
	equals?: boolean | undefined | null | Variable<any, string>,
	not_equals?: boolean | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Event_sections__address_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Event_sections__addressCoordinate_operator"]: {
	equals?: Array<number | undefined | null> | undefined | null | Variable<any, string>,
	not_equals?: Array<number | undefined | null> | undefined | null | Variable<any, string>,
	greater_than_equal?: Array<number | undefined | null> | undefined | null | Variable<any, string>,
	greater_than?: Array<number | undefined | null> | undefined | null | Variable<any, string>,
	less_than_equal?: Array<number | undefined | null> | undefined | null | Variable<any, string>,
	less_than?: Array<number | undefined | null> | undefined | null | Variable<any, string>,
	near?: Array<number | undefined | null> | undefined | null | Variable<any, string>,
	within?: ValueTypes["GeoJSONObject"] | undefined | null | Variable<any, string>,
	intersects?: ValueTypes["GeoJSONObject"] | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Event_sections__id_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Event_ticketing__startDatetime_operator"]: {
	equals?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	not_equals?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	greater_than_equal?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	greater_than?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	less_than_equal?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	less_than?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	like?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>
};
	["Event_ticketing__endDatetime_operator"]: {
	equals?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	not_equals?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	greater_than_equal?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	greater_than?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	less_than_equal?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	less_than?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	like?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>
};
	["Event_ticketing__fullDay_operator"]: {
	equals?: boolean | undefined | null | Variable<any, string>,
	not_equals?: boolean | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Event_ticketing__type_operator"]: {
	equals?: ValueTypes["Event_ticketing__type_Input"] | undefined | null | Variable<any, string>,
	not_equals?: ValueTypes["Event_ticketing__type_Input"] | undefined | null | Variable<any, string>,
	in?: Array<ValueTypes["Event_ticketing__type_Input"] | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<ValueTypes["Event_ticketing__type_Input"] | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<ValueTypes["Event_ticketing__type_Input"] | undefined | null> | undefined | null | Variable<any, string>
};
	["Event_ticketing__type_Input"]:Event_ticketing__type_Input;
	["Event_ticketing__ticketingType_operator"]: {
	equals?: ValueTypes["Event_ticketing__ticketingType_Input"] | undefined | null | Variable<any, string>,
	not_equals?: ValueTypes["Event_ticketing__ticketingType_Input"] | undefined | null | Variable<any, string>,
	in?: Array<ValueTypes["Event_ticketing__ticketingType_Input"] | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<ValueTypes["Event_ticketing__ticketingType_Input"] | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<ValueTypes["Event_ticketing__ticketingType_Input"] | undefined | null> | undefined | null | Variable<any, string>
};
	["Event_ticketing__ticketingType_Input"]:Event_ticketing__ticketingType_Input;
	["Event_ticketing__linkedTicketing_operator"]: {
	equals?: ValueTypes["JSON"] | undefined | null | Variable<any, string>,
	not_equals?: ValueTypes["JSON"] | undefined | null | Variable<any, string>,
	in?: Array<ValueTypes["JSON"] | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<ValueTypes["JSON"] | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<ValueTypes["JSON"] | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Event_ticketing__promotionType_operator"]: {
	equals?: ValueTypes["Event_ticketing__promotionType_Input"] | undefined | null | Variable<any, string>,
	not_equals?: ValueTypes["Event_ticketing__promotionType_Input"] | undefined | null | Variable<any, string>,
	in?: Array<ValueTypes["Event_ticketing__promotionType_Input"] | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<ValueTypes["Event_ticketing__promotionType_Input"] | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<ValueTypes["Event_ticketing__promotionType_Input"] | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Event_ticketing__promotionType_Input"]:Event_ticketing__promotionType_Input;
	["Event_ticketing__location_operator"]: {
	equals?: ValueTypes["JSON"] | undefined | null | Variable<any, string>,
	not_equals?: ValueTypes["JSON"] | undefined | null | Variable<any, string>,
	in?: Array<ValueTypes["JSON"] | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<ValueTypes["JSON"] | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<ValueTypes["JSON"] | undefined | null> | undefined | null | Variable<any, string>
};
	["Event_ticketing__website_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Event_ticketing__description_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Event_ticketing__id_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Event_legacyContent_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Event_showLegacyContent_operator"]: {
	equals?: boolean | undefined | null | Variable<any, string>,
	not_equals?: boolean | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Event_criteria__categories_operator"]: {
	equals?: ValueTypes["JSON"] | undefined | null | Variable<any, string>,
	not_equals?: ValueTypes["JSON"] | undefined | null | Variable<any, string>,
	in?: Array<ValueTypes["JSON"] | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<ValueTypes["JSON"] | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<ValueTypes["JSON"] | undefined | null> | undefined | null | Variable<any, string>
};
	["Event_criteria__audiences_operator"]: {
	equals?: ValueTypes["JSON"] | undefined | null | Variable<any, string>,
	not_equals?: ValueTypes["JSON"] | undefined | null | Variable<any, string>,
	in?: Array<ValueTypes["JSON"] | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<ValueTypes["JSON"] | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<ValueTypes["JSON"] | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Event_criteria__tags_operator"]: {
	equals?: ValueTypes["JSON"] | undefined | null | Variable<any, string>,
	not_equals?: ValueTypes["JSON"] | undefined | null | Variable<any, string>,
	in?: Array<ValueTypes["JSON"] | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<ValueTypes["JSON"] | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<ValueTypes["JSON"] | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Event_criteria__specialTags_operator"]: {
	equals?: ValueTypes["JSON"] | undefined | null | Variable<any, string>,
	not_equals?: ValueTypes["JSON"] | undefined | null | Variable<any, string>,
	in?: Array<ValueTypes["JSON"] | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<ValueTypes["JSON"] | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<ValueTypes["JSON"] | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Event_walkIn_operator"]: {
	equals?: boolean | undefined | null | Variable<any, string>,
	not_equals?: boolean | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Event_contact__code_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Event_contact__phone_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Event_contact__page_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Event_contact__email_operator"]: {
	equals?: ValueTypes["EmailAddress"] | undefined | null | Variable<any, string>,
	not_equals?: ValueTypes["EmailAddress"] | undefined | null | Variable<any, string>,
	like?: ValueTypes["EmailAddress"] | undefined | null | Variable<any, string>,
	contains?: ValueTypes["EmailAddress"] | undefined | null | Variable<any, string>,
	in?: Array<ValueTypes["EmailAddress"] | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<ValueTypes["EmailAddress"] | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<ValueTypes["EmailAddress"] | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Event_contact__privacy_operator"]: {
	equals?: ValueTypes["JSON"] | undefined | null | Variable<any, string>,
	not_equals?: ValueTypes["JSON"] | undefined | null | Variable<any, string>,
	like?: ValueTypes["JSON"] | undefined | null | Variable<any, string>,
	contains?: ValueTypes["JSON"] | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Event_views_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Event_free_operator"]: {
	equals?: boolean | undefined | null | Variable<any, string>,
	not_equals?: boolean | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Event_fees__fee_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Event_fees__remark_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Event_fees__id_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Event_homePageInternalClick_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Event_homePageImpression_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Event_eodClickRate_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Event_eodImpressionRate_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Event_top10ClickRateHomepage_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Event_top10ImpressionkRateHomepage_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Event_otherEventRankingClickRateHomepage_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Event_otherEventRankingImpressionHomePage_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Event_userPreferenceClickRate_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Event_userPreferenceImpression_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Event_eventSearchInternalClick_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Event_eventListImpression_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Event_latestEventInternalClick_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Event_latestEventImpression_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Event_topTenEventRankingInternalClick_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Event_topTenImpression_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Event_editorChoiceInternalClick_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Event_editorChoiceImpression_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Event_otherEventRankingInternalClick_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Event_otherEventRankingImpression_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Event_bannerInternalClick_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Event_miscImpression_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Event_miscInternalClick_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Event_totalImpression_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Event_internalClick_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Event_outboundClick_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Event_externalClick_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Event__viewsCountByMonth_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Event_products__startDateTime_operator"]: {
	equals?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	not_equals?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	greater_than_equal?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	greater_than?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	less_than_equal?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	less_than?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	like?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>
};
	["Event_products__endDateTime_operator"]: {
	equals?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	not_equals?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	greater_than_equal?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	greater_than?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	less_than_equal?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	less_than?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	like?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>
};
	["Event_products__fullDay_operator"]: {
	equals?: boolean | undefined | null | Variable<any, string>,
	not_equals?: boolean | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Event_products__linkedProduct_operator"]: {
	equals?: ValueTypes["JSON"] | undefined | null | Variable<any, string>,
	not_equals?: ValueTypes["JSON"] | undefined | null | Variable<any, string>,
	in?: Array<ValueTypes["JSON"] | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<ValueTypes["JSON"] | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<ValueTypes["JSON"] | undefined | null> | undefined | null | Variable<any, string>
};
	["Event_products__id_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Event_legacyGallery_operator"]: {
	equals?: ValueTypes["JSON"] | undefined | null | Variable<any, string>,
	not_equals?: ValueTypes["JSON"] | undefined | null | Variable<any, string>,
	in?: Array<ValueTypes["JSON"] | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<ValueTypes["JSON"] | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<ValueTypes["JSON"] | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Event_earliestStartDate_operator"]: {
	equals?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	not_equals?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	greater_than_equal?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	greater_than?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	less_than_equal?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	less_than?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	like?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Event_latestEndDate_operator"]: {
	equals?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	not_equals?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	greater_than_equal?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	greater_than?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	less_than_equal?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	less_than?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	like?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Event_latestStartDateTime_operator"]: {
	equals?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	not_equals?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	greater_than_equal?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	greater_than?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	less_than_equal?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	less_than?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	like?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Event_hasTicketing_operator"]: {
	equals?: boolean | undefined | null | Variable<any, string>,
	not_equals?: boolean | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Event_hasProducts_operator"]: {
	equals?: boolean | undefined | null | Variable<any, string>,
	not_equals?: boolean | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Event_updatedAt_operator"]: {
	equals?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	not_equals?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	greater_than_equal?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	greater_than?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	less_than_equal?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	less_than?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	like?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Event_createdAt_operator"]: {
	equals?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	not_equals?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	greater_than_equal?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	greater_than?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	less_than_equal?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	less_than?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	like?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Event_id_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Event_where_and"]: {
	region?: ValueTypes["Event_region_operator"] | undefined | null | Variable<any, string>,
	creator?: ValueTypes["Event_creator_operator"] | undefined | null | Variable<any, string>,
	permalink?: ValueTypes["Event_permalink_operator"] | undefined | null | Variable<any, string>,
	status?: ValueTypes["Event_status_operator"] | undefined | null | Variable<any, string>,
	level?: ValueTypes["Event_level_operator"] | undefined | null | Variable<any, string>,
	parent?: ValueTypes["Event_parent_operator"] | undefined | null | Variable<any, string>,
	name?: ValueTypes["Event_name_operator"] | undefined | null | Variable<any, string>,
	chatbotEventTitle?: ValueTypes["Event_chatbotEventTitle_operator"] | undefined | null | Variable<any, string>,
	chatbotSequence?: ValueTypes["Event_chatbotSequence_operator"] | undefined | null | Variable<any, string>,
	thumbnail?: ValueTypes["Event_thumbnail_operator"] | undefined | null | Variable<any, string>,
	banner?: ValueTypes["Event_banner_operator"] | undefined | null | Variable<any, string>,
	mobileBanner?: ValueTypes["Event_mobileBanner_operator"] | undefined | null | Variable<any, string>,
	sections__startDatetime?: ValueTypes["Event_sections__startDatetime_operator"] | undefined | null | Variable<any, string>,
	sections__endDatetime?: ValueTypes["Event_sections__endDatetime_operator"] | undefined | null | Variable<any, string>,
	sections__fullDay?: ValueTypes["Event_sections__fullDay_operator"] | undefined | null | Variable<any, string>,
	sections__repeat?: ValueTypes["Event_sections__repeat_operator"] | undefined | null | Variable<any, string>,
	sections__toThisDay?: ValueTypes["Event_sections__toThisDay_operator"] | undefined | null | Variable<any, string>,
	sections__recurrance__type?: ValueTypes["Event_sections__recurrance__type_operator"] | undefined | null | Variable<any, string>,
	sections__recurrance__weekday?: ValueTypes["Event_sections__recurrance__weekday_operator"] | undefined | null | Variable<any, string>,
	sections__sameLocation?: ValueTypes["Event_sections__sameLocation_operator"] | undefined | null | Variable<any, string>,
	sections__location?: ValueTypes["Event_sections__location_operator"] | undefined | null | Variable<any, string>,
	sections__district?: ValueTypes["Event_sections__district_operator"] | undefined | null | Variable<any, string>,
	sections__coordinate?: ValueTypes["Event_sections__coordinate_operator"] | undefined | null | Variable<any, string>,
	sections__linkAddress?: ValueTypes["Event_sections__linkAddress_operator"] | undefined | null | Variable<any, string>,
	sections__address?: ValueTypes["Event_sections__address_operator"] | undefined | null | Variable<any, string>,
	sections__addressCoordinate?: ValueTypes["Event_sections__addressCoordinate_operator"] | undefined | null | Variable<any, string>,
	sections__id?: ValueTypes["Event_sections__id_operator"] | undefined | null | Variable<any, string>,
	ticketing__startDatetime?: ValueTypes["Event_ticketing__startDatetime_operator"] | undefined | null | Variable<any, string>,
	ticketing__endDatetime?: ValueTypes["Event_ticketing__endDatetime_operator"] | undefined | null | Variable<any, string>,
	ticketing__fullDay?: ValueTypes["Event_ticketing__fullDay_operator"] | undefined | null | Variable<any, string>,
	ticketing__type?: ValueTypes["Event_ticketing__type_operator"] | undefined | null | Variable<any, string>,
	ticketing__ticketingType?: ValueTypes["Event_ticketing__ticketingType_operator"] | undefined | null | Variable<any, string>,
	ticketing__linkedTicketing?: ValueTypes["Event_ticketing__linkedTicketing_operator"] | undefined | null | Variable<any, string>,
	ticketing__promotionType?: ValueTypes["Event_ticketing__promotionType_operator"] | undefined | null | Variable<any, string>,
	ticketing__location?: ValueTypes["Event_ticketing__location_operator"] | undefined | null | Variable<any, string>,
	ticketing__website?: ValueTypes["Event_ticketing__website_operator"] | undefined | null | Variable<any, string>,
	ticketing__description?: ValueTypes["Event_ticketing__description_operator"] | undefined | null | Variable<any, string>,
	ticketing__id?: ValueTypes["Event_ticketing__id_operator"] | undefined | null | Variable<any, string>,
	legacyContent?: ValueTypes["Event_legacyContent_operator"] | undefined | null | Variable<any, string>,
	showLegacyContent?: ValueTypes["Event_showLegacyContent_operator"] | undefined | null | Variable<any, string>,
	criteria__categories?: ValueTypes["Event_criteria__categories_operator"] | undefined | null | Variable<any, string>,
	criteria__audiences?: ValueTypes["Event_criteria__audiences_operator"] | undefined | null | Variable<any, string>,
	criteria__tags?: ValueTypes["Event_criteria__tags_operator"] | undefined | null | Variable<any, string>,
	criteria__specialTags?: ValueTypes["Event_criteria__specialTags_operator"] | undefined | null | Variable<any, string>,
	walkIn?: ValueTypes["Event_walkIn_operator"] | undefined | null | Variable<any, string>,
	contact__code?: ValueTypes["Event_contact__code_operator"] | undefined | null | Variable<any, string>,
	contact__phone?: ValueTypes["Event_contact__phone_operator"] | undefined | null | Variable<any, string>,
	contact__page?: ValueTypes["Event_contact__page_operator"] | undefined | null | Variable<any, string>,
	contact__email?: ValueTypes["Event_contact__email_operator"] | undefined | null | Variable<any, string>,
	contact__privacy?: ValueTypes["Event_contact__privacy_operator"] | undefined | null | Variable<any, string>,
	views?: ValueTypes["Event_views_operator"] | undefined | null | Variable<any, string>,
	free?: ValueTypes["Event_free_operator"] | undefined | null | Variable<any, string>,
	fees__fee?: ValueTypes["Event_fees__fee_operator"] | undefined | null | Variable<any, string>,
	fees__remark?: ValueTypes["Event_fees__remark_operator"] | undefined | null | Variable<any, string>,
	fees__id?: ValueTypes["Event_fees__id_operator"] | undefined | null | Variable<any, string>,
	homePageInternalClick?: ValueTypes["Event_homePageInternalClick_operator"] | undefined | null | Variable<any, string>,
	homePageImpression?: ValueTypes["Event_homePageImpression_operator"] | undefined | null | Variable<any, string>,
	eodClickRate?: ValueTypes["Event_eodClickRate_operator"] | undefined | null | Variable<any, string>,
	eodImpressionRate?: ValueTypes["Event_eodImpressionRate_operator"] | undefined | null | Variable<any, string>,
	top10ClickRateHomepage?: ValueTypes["Event_top10ClickRateHomepage_operator"] | undefined | null | Variable<any, string>,
	top10ImpressionkRateHomepage?: ValueTypes["Event_top10ImpressionkRateHomepage_operator"] | undefined | null | Variable<any, string>,
	otherEventRankingClickRateHomepage?: ValueTypes["Event_otherEventRankingClickRateHomepage_operator"] | undefined | null | Variable<any, string>,
	otherEventRankingImpressionHomePage?: ValueTypes["Event_otherEventRankingImpressionHomePage_operator"] | undefined | null | Variable<any, string>,
	userPreferenceClickRate?: ValueTypes["Event_userPreferenceClickRate_operator"] | undefined | null | Variable<any, string>,
	userPreferenceImpression?: ValueTypes["Event_userPreferenceImpression_operator"] | undefined | null | Variable<any, string>,
	eventSearchInternalClick?: ValueTypes["Event_eventSearchInternalClick_operator"] | undefined | null | Variable<any, string>,
	eventListImpression?: ValueTypes["Event_eventListImpression_operator"] | undefined | null | Variable<any, string>,
	latestEventInternalClick?: ValueTypes["Event_latestEventInternalClick_operator"] | undefined | null | Variable<any, string>,
	latestEventImpression?: ValueTypes["Event_latestEventImpression_operator"] | undefined | null | Variable<any, string>,
	topTenEventRankingInternalClick?: ValueTypes["Event_topTenEventRankingInternalClick_operator"] | undefined | null | Variable<any, string>,
	topTenImpression?: ValueTypes["Event_topTenImpression_operator"] | undefined | null | Variable<any, string>,
	editorChoiceInternalClick?: ValueTypes["Event_editorChoiceInternalClick_operator"] | undefined | null | Variable<any, string>,
	editorChoiceImpression?: ValueTypes["Event_editorChoiceImpression_operator"] | undefined | null | Variable<any, string>,
	otherEventRankingInternalClick?: ValueTypes["Event_otherEventRankingInternalClick_operator"] | undefined | null | Variable<any, string>,
	otherEventRankingImpression?: ValueTypes["Event_otherEventRankingImpression_operator"] | undefined | null | Variable<any, string>,
	bannerInternalClick?: ValueTypes["Event_bannerInternalClick_operator"] | undefined | null | Variable<any, string>,
	miscImpression?: ValueTypes["Event_miscImpression_operator"] | undefined | null | Variable<any, string>,
	miscInternalClick?: ValueTypes["Event_miscInternalClick_operator"] | undefined | null | Variable<any, string>,
	totalImpression?: ValueTypes["Event_totalImpression_operator"] | undefined | null | Variable<any, string>,
	internalClick?: ValueTypes["Event_internalClick_operator"] | undefined | null | Variable<any, string>,
	outboundClick?: ValueTypes["Event_outboundClick_operator"] | undefined | null | Variable<any, string>,
	externalClick?: ValueTypes["Event_externalClick_operator"] | undefined | null | Variable<any, string>,
	_viewsCountByMonth?: ValueTypes["Event__viewsCountByMonth_operator"] | undefined | null | Variable<any, string>,
	products__startDateTime?: ValueTypes["Event_products__startDateTime_operator"] | undefined | null | Variable<any, string>,
	products__endDateTime?: ValueTypes["Event_products__endDateTime_operator"] | undefined | null | Variable<any, string>,
	products__fullDay?: ValueTypes["Event_products__fullDay_operator"] | undefined | null | Variable<any, string>,
	products__linkedProduct?: ValueTypes["Event_products__linkedProduct_operator"] | undefined | null | Variable<any, string>,
	products__id?: ValueTypes["Event_products__id_operator"] | undefined | null | Variable<any, string>,
	legacyGallery?: ValueTypes["Event_legacyGallery_operator"] | undefined | null | Variable<any, string>,
	earliestStartDate?: ValueTypes["Event_earliestStartDate_operator"] | undefined | null | Variable<any, string>,
	latestEndDate?: ValueTypes["Event_latestEndDate_operator"] | undefined | null | Variable<any, string>,
	latestStartDateTime?: ValueTypes["Event_latestStartDateTime_operator"] | undefined | null | Variable<any, string>,
	hasTicketing?: ValueTypes["Event_hasTicketing_operator"] | undefined | null | Variable<any, string>,
	hasProducts?: ValueTypes["Event_hasProducts_operator"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["Event_updatedAt_operator"] | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["Event_createdAt_operator"] | undefined | null | Variable<any, string>,
	id?: ValueTypes["Event_id_operator"] | undefined | null | Variable<any, string>,
	AND?: Array<ValueTypes["Event_where_and"] | undefined | null> | undefined | null | Variable<any, string>,
	OR?: Array<ValueTypes["Event_where_or"] | undefined | null> | undefined | null | Variable<any, string>
};
	["Event_where_or"]: {
	region?: ValueTypes["Event_region_operator"] | undefined | null | Variable<any, string>,
	creator?: ValueTypes["Event_creator_operator"] | undefined | null | Variable<any, string>,
	permalink?: ValueTypes["Event_permalink_operator"] | undefined | null | Variable<any, string>,
	status?: ValueTypes["Event_status_operator"] | undefined | null | Variable<any, string>,
	level?: ValueTypes["Event_level_operator"] | undefined | null | Variable<any, string>,
	parent?: ValueTypes["Event_parent_operator"] | undefined | null | Variable<any, string>,
	name?: ValueTypes["Event_name_operator"] | undefined | null | Variable<any, string>,
	chatbotEventTitle?: ValueTypes["Event_chatbotEventTitle_operator"] | undefined | null | Variable<any, string>,
	chatbotSequence?: ValueTypes["Event_chatbotSequence_operator"] | undefined | null | Variable<any, string>,
	thumbnail?: ValueTypes["Event_thumbnail_operator"] | undefined | null | Variable<any, string>,
	banner?: ValueTypes["Event_banner_operator"] | undefined | null | Variable<any, string>,
	mobileBanner?: ValueTypes["Event_mobileBanner_operator"] | undefined | null | Variable<any, string>,
	sections__startDatetime?: ValueTypes["Event_sections__startDatetime_operator"] | undefined | null | Variable<any, string>,
	sections__endDatetime?: ValueTypes["Event_sections__endDatetime_operator"] | undefined | null | Variable<any, string>,
	sections__fullDay?: ValueTypes["Event_sections__fullDay_operator"] | undefined | null | Variable<any, string>,
	sections__repeat?: ValueTypes["Event_sections__repeat_operator"] | undefined | null | Variable<any, string>,
	sections__toThisDay?: ValueTypes["Event_sections__toThisDay_operator"] | undefined | null | Variable<any, string>,
	sections__recurrance__type?: ValueTypes["Event_sections__recurrance__type_operator"] | undefined | null | Variable<any, string>,
	sections__recurrance__weekday?: ValueTypes["Event_sections__recurrance__weekday_operator"] | undefined | null | Variable<any, string>,
	sections__sameLocation?: ValueTypes["Event_sections__sameLocation_operator"] | undefined | null | Variable<any, string>,
	sections__location?: ValueTypes["Event_sections__location_operator"] | undefined | null | Variable<any, string>,
	sections__district?: ValueTypes["Event_sections__district_operator"] | undefined | null | Variable<any, string>,
	sections__coordinate?: ValueTypes["Event_sections__coordinate_operator"] | undefined | null | Variable<any, string>,
	sections__linkAddress?: ValueTypes["Event_sections__linkAddress_operator"] | undefined | null | Variable<any, string>,
	sections__address?: ValueTypes["Event_sections__address_operator"] | undefined | null | Variable<any, string>,
	sections__addressCoordinate?: ValueTypes["Event_sections__addressCoordinate_operator"] | undefined | null | Variable<any, string>,
	sections__id?: ValueTypes["Event_sections__id_operator"] | undefined | null | Variable<any, string>,
	ticketing__startDatetime?: ValueTypes["Event_ticketing__startDatetime_operator"] | undefined | null | Variable<any, string>,
	ticketing__endDatetime?: ValueTypes["Event_ticketing__endDatetime_operator"] | undefined | null | Variable<any, string>,
	ticketing__fullDay?: ValueTypes["Event_ticketing__fullDay_operator"] | undefined | null | Variable<any, string>,
	ticketing__type?: ValueTypes["Event_ticketing__type_operator"] | undefined | null | Variable<any, string>,
	ticketing__ticketingType?: ValueTypes["Event_ticketing__ticketingType_operator"] | undefined | null | Variable<any, string>,
	ticketing__linkedTicketing?: ValueTypes["Event_ticketing__linkedTicketing_operator"] | undefined | null | Variable<any, string>,
	ticketing__promotionType?: ValueTypes["Event_ticketing__promotionType_operator"] | undefined | null | Variable<any, string>,
	ticketing__location?: ValueTypes["Event_ticketing__location_operator"] | undefined | null | Variable<any, string>,
	ticketing__website?: ValueTypes["Event_ticketing__website_operator"] | undefined | null | Variable<any, string>,
	ticketing__description?: ValueTypes["Event_ticketing__description_operator"] | undefined | null | Variable<any, string>,
	ticketing__id?: ValueTypes["Event_ticketing__id_operator"] | undefined | null | Variable<any, string>,
	legacyContent?: ValueTypes["Event_legacyContent_operator"] | undefined | null | Variable<any, string>,
	showLegacyContent?: ValueTypes["Event_showLegacyContent_operator"] | undefined | null | Variable<any, string>,
	criteria__categories?: ValueTypes["Event_criteria__categories_operator"] | undefined | null | Variable<any, string>,
	criteria__audiences?: ValueTypes["Event_criteria__audiences_operator"] | undefined | null | Variable<any, string>,
	criteria__tags?: ValueTypes["Event_criteria__tags_operator"] | undefined | null | Variable<any, string>,
	criteria__specialTags?: ValueTypes["Event_criteria__specialTags_operator"] | undefined | null | Variable<any, string>,
	walkIn?: ValueTypes["Event_walkIn_operator"] | undefined | null | Variable<any, string>,
	contact__code?: ValueTypes["Event_contact__code_operator"] | undefined | null | Variable<any, string>,
	contact__phone?: ValueTypes["Event_contact__phone_operator"] | undefined | null | Variable<any, string>,
	contact__page?: ValueTypes["Event_contact__page_operator"] | undefined | null | Variable<any, string>,
	contact__email?: ValueTypes["Event_contact__email_operator"] | undefined | null | Variable<any, string>,
	contact__privacy?: ValueTypes["Event_contact__privacy_operator"] | undefined | null | Variable<any, string>,
	views?: ValueTypes["Event_views_operator"] | undefined | null | Variable<any, string>,
	free?: ValueTypes["Event_free_operator"] | undefined | null | Variable<any, string>,
	fees__fee?: ValueTypes["Event_fees__fee_operator"] | undefined | null | Variable<any, string>,
	fees__remark?: ValueTypes["Event_fees__remark_operator"] | undefined | null | Variable<any, string>,
	fees__id?: ValueTypes["Event_fees__id_operator"] | undefined | null | Variable<any, string>,
	homePageInternalClick?: ValueTypes["Event_homePageInternalClick_operator"] | undefined | null | Variable<any, string>,
	homePageImpression?: ValueTypes["Event_homePageImpression_operator"] | undefined | null | Variable<any, string>,
	eodClickRate?: ValueTypes["Event_eodClickRate_operator"] | undefined | null | Variable<any, string>,
	eodImpressionRate?: ValueTypes["Event_eodImpressionRate_operator"] | undefined | null | Variable<any, string>,
	top10ClickRateHomepage?: ValueTypes["Event_top10ClickRateHomepage_operator"] | undefined | null | Variable<any, string>,
	top10ImpressionkRateHomepage?: ValueTypes["Event_top10ImpressionkRateHomepage_operator"] | undefined | null | Variable<any, string>,
	otherEventRankingClickRateHomepage?: ValueTypes["Event_otherEventRankingClickRateHomepage_operator"] | undefined | null | Variable<any, string>,
	otherEventRankingImpressionHomePage?: ValueTypes["Event_otherEventRankingImpressionHomePage_operator"] | undefined | null | Variable<any, string>,
	userPreferenceClickRate?: ValueTypes["Event_userPreferenceClickRate_operator"] | undefined | null | Variable<any, string>,
	userPreferenceImpression?: ValueTypes["Event_userPreferenceImpression_operator"] | undefined | null | Variable<any, string>,
	eventSearchInternalClick?: ValueTypes["Event_eventSearchInternalClick_operator"] | undefined | null | Variable<any, string>,
	eventListImpression?: ValueTypes["Event_eventListImpression_operator"] | undefined | null | Variable<any, string>,
	latestEventInternalClick?: ValueTypes["Event_latestEventInternalClick_operator"] | undefined | null | Variable<any, string>,
	latestEventImpression?: ValueTypes["Event_latestEventImpression_operator"] | undefined | null | Variable<any, string>,
	topTenEventRankingInternalClick?: ValueTypes["Event_topTenEventRankingInternalClick_operator"] | undefined | null | Variable<any, string>,
	topTenImpression?: ValueTypes["Event_topTenImpression_operator"] | undefined | null | Variable<any, string>,
	editorChoiceInternalClick?: ValueTypes["Event_editorChoiceInternalClick_operator"] | undefined | null | Variable<any, string>,
	editorChoiceImpression?: ValueTypes["Event_editorChoiceImpression_operator"] | undefined | null | Variable<any, string>,
	otherEventRankingInternalClick?: ValueTypes["Event_otherEventRankingInternalClick_operator"] | undefined | null | Variable<any, string>,
	otherEventRankingImpression?: ValueTypes["Event_otherEventRankingImpression_operator"] | undefined | null | Variable<any, string>,
	bannerInternalClick?: ValueTypes["Event_bannerInternalClick_operator"] | undefined | null | Variable<any, string>,
	miscImpression?: ValueTypes["Event_miscImpression_operator"] | undefined | null | Variable<any, string>,
	miscInternalClick?: ValueTypes["Event_miscInternalClick_operator"] | undefined | null | Variable<any, string>,
	totalImpression?: ValueTypes["Event_totalImpression_operator"] | undefined | null | Variable<any, string>,
	internalClick?: ValueTypes["Event_internalClick_operator"] | undefined | null | Variable<any, string>,
	outboundClick?: ValueTypes["Event_outboundClick_operator"] | undefined | null | Variable<any, string>,
	externalClick?: ValueTypes["Event_externalClick_operator"] | undefined | null | Variable<any, string>,
	_viewsCountByMonth?: ValueTypes["Event__viewsCountByMonth_operator"] | undefined | null | Variable<any, string>,
	products__startDateTime?: ValueTypes["Event_products__startDateTime_operator"] | undefined | null | Variable<any, string>,
	products__endDateTime?: ValueTypes["Event_products__endDateTime_operator"] | undefined | null | Variable<any, string>,
	products__fullDay?: ValueTypes["Event_products__fullDay_operator"] | undefined | null | Variable<any, string>,
	products__linkedProduct?: ValueTypes["Event_products__linkedProduct_operator"] | undefined | null | Variable<any, string>,
	products__id?: ValueTypes["Event_products__id_operator"] | undefined | null | Variable<any, string>,
	legacyGallery?: ValueTypes["Event_legacyGallery_operator"] | undefined | null | Variable<any, string>,
	earliestStartDate?: ValueTypes["Event_earliestStartDate_operator"] | undefined | null | Variable<any, string>,
	latestEndDate?: ValueTypes["Event_latestEndDate_operator"] | undefined | null | Variable<any, string>,
	latestStartDateTime?: ValueTypes["Event_latestStartDateTime_operator"] | undefined | null | Variable<any, string>,
	hasTicketing?: ValueTypes["Event_hasTicketing_operator"] | undefined | null | Variable<any, string>,
	hasProducts?: ValueTypes["Event_hasProducts_operator"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["Event_updatedAt_operator"] | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["Event_createdAt_operator"] | undefined | null | Variable<any, string>,
	id?: ValueTypes["Event_id_operator"] | undefined | null | Variable<any, string>,
	AND?: Array<ValueTypes["Event_where_and"] | undefined | null> | undefined | null | Variable<any, string>,
	OR?: Array<ValueTypes["Event_where_or"] | undefined | null> | undefined | null | Variable<any, string>
};
	["countEvents"]: AliasType<{
	totalDocs?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["eventsDocAccess"]: AliasType<{
	fields?:ValueTypes["EventsDocAccessFields"],
	create?:ValueTypes["EventsCreateDocAccess"],
	read?:ValueTypes["EventsReadDocAccess"],
	update?:ValueTypes["EventsUpdateDocAccess"],
	delete?:ValueTypes["EventsDeleteDocAccess"],
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields"]: AliasType<{
	region?:ValueTypes["EventsDocAccessFields_region"],
	creator?:ValueTypes["EventsDocAccessFields_creator"],
	permalink?:ValueTypes["EventsDocAccessFields_permalink"],
	status?:ValueTypes["EventsDocAccessFields_status"],
	level?:ValueTypes["EventsDocAccessFields_level"],
	parent?:ValueTypes["EventsDocAccessFields_parent"],
	name?:ValueTypes["EventsDocAccessFields_name"],
	chatbotEventTitle?:ValueTypes["EventsDocAccessFields_chatbotEventTitle"],
	chatbotSequence?:ValueTypes["EventsDocAccessFields_chatbotSequence"],
	thumbnail?:ValueTypes["EventsDocAccessFields_thumbnail"],
	banner?:ValueTypes["EventsDocAccessFields_banner"],
	mobileBanner?:ValueTypes["EventsDocAccessFields_mobileBanner"],
	sections?:ValueTypes["EventsDocAccessFields_sections"],
	ticketing?:ValueTypes["EventsDocAccessFields_ticketing"],
	content?:ValueTypes["EventsDocAccessFields_content"],
	legacyContent?:ValueTypes["EventsDocAccessFields_legacyContent"],
	showLegacyContent?:ValueTypes["EventsDocAccessFields_showLegacyContent"],
	criteria?:ValueTypes["EventsDocAccessFields_criteria"],
	walkIn?:ValueTypes["EventsDocAccessFields_walkIn"],
	contact?:ValueTypes["EventsDocAccessFields_contact"],
	views?:ValueTypes["EventsDocAccessFields_views"],
	free?:ValueTypes["EventsDocAccessFields_free"],
	fees?:ValueTypes["EventsDocAccessFields_fees"],
	homePageInternalClick?:ValueTypes["EventsDocAccessFields_homePageInternalClick"],
	homePageImpression?:ValueTypes["EventsDocAccessFields_homePageImpression"],
	eodClickRate?:ValueTypes["EventsDocAccessFields_eodClickRate"],
	eodImpressionRate?:ValueTypes["EventsDocAccessFields_eodImpressionRate"],
	top10ClickRateHomepage?:ValueTypes["EventsDocAccessFields_top10ClickRateHomepage"],
	top10ImpressionkRateHomepage?:ValueTypes["EventsDocAccessFields_top10ImpressionkRateHomepage"],
	otherEventRankingClickRateHomepage?:ValueTypes["EventsDocAccessFields_otherEventRankingClickRateHomepage"],
	otherEventRankingImpressionHomePage?:ValueTypes["EventsDocAccessFields_otherEventRankingImpressionHomePage"],
	userPreferenceClickRate?:ValueTypes["EventsDocAccessFields_userPreferenceClickRate"],
	userPreferenceImpression?:ValueTypes["EventsDocAccessFields_userPreferenceImpression"],
	eventSearchInternalClick?:ValueTypes["EventsDocAccessFields_eventSearchInternalClick"],
	eventListImpression?:ValueTypes["EventsDocAccessFields_eventListImpression"],
	latestEventInternalClick?:ValueTypes["EventsDocAccessFields_latestEventInternalClick"],
	latestEventImpression?:ValueTypes["EventsDocAccessFields_latestEventImpression"],
	topTenEventRankingInternalClick?:ValueTypes["EventsDocAccessFields_topTenEventRankingInternalClick"],
	topTenImpression?:ValueTypes["EventsDocAccessFields_topTenImpression"],
	editorChoiceInternalClick?:ValueTypes["EventsDocAccessFields_editorChoiceInternalClick"],
	editorChoiceImpression?:ValueTypes["EventsDocAccessFields_editorChoiceImpression"],
	otherEventRankingInternalClick?:ValueTypes["EventsDocAccessFields_otherEventRankingInternalClick"],
	otherEventRankingImpression?:ValueTypes["EventsDocAccessFields_otherEventRankingImpression"],
	bannerInternalClick?:ValueTypes["EventsDocAccessFields_bannerInternalClick"],
	miscImpression?:ValueTypes["EventsDocAccessFields_miscImpression"],
	miscInternalClick?:ValueTypes["EventsDocAccessFields_miscInternalClick"],
	totalImpression?:ValueTypes["EventsDocAccessFields_totalImpression"],
	internalClick?:ValueTypes["EventsDocAccessFields_internalClick"],
	outboundClick?:ValueTypes["EventsDocAccessFields_outboundClick"],
	externalClick?:ValueTypes["EventsDocAccessFields_externalClick"],
	_viewsCountByMonth?:ValueTypes["EventsDocAccessFields__viewsCountByMonth"],
	products?:ValueTypes["EventsDocAccessFields_products"],
	legacyGallery?:ValueTypes["EventsDocAccessFields_legacyGallery"],
	earliestStartDate?:ValueTypes["EventsDocAccessFields_earliestStartDate"],
	latestEndDate?:ValueTypes["EventsDocAccessFields_latestEndDate"],
	latestStartDateTime?:ValueTypes["EventsDocAccessFields_latestStartDateTime"],
	hasTicketing?:ValueTypes["EventsDocAccessFields_hasTicketing"],
	hasProducts?:ValueTypes["EventsDocAccessFields_hasProducts"],
	updatedAt?:ValueTypes["EventsDocAccessFields_updatedAt"],
	createdAt?:ValueTypes["EventsDocAccessFields_createdAt"],
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_region"]: AliasType<{
	create?:ValueTypes["EventsDocAccessFields_region_Create"],
	read?:ValueTypes["EventsDocAccessFields_region_Read"],
	update?:ValueTypes["EventsDocAccessFields_region_Update"],
	delete?:ValueTypes["EventsDocAccessFields_region_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_region_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_region_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_region_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_region_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_creator"]: AliasType<{
	create?:ValueTypes["EventsDocAccessFields_creator_Create"],
	read?:ValueTypes["EventsDocAccessFields_creator_Read"],
	update?:ValueTypes["EventsDocAccessFields_creator_Update"],
	delete?:ValueTypes["EventsDocAccessFields_creator_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_creator_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_creator_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_creator_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_creator_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_permalink"]: AliasType<{
	create?:ValueTypes["EventsDocAccessFields_permalink_Create"],
	read?:ValueTypes["EventsDocAccessFields_permalink_Read"],
	update?:ValueTypes["EventsDocAccessFields_permalink_Update"],
	delete?:ValueTypes["EventsDocAccessFields_permalink_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_permalink_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_permalink_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_permalink_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_permalink_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_status"]: AliasType<{
	create?:ValueTypes["EventsDocAccessFields_status_Create"],
	read?:ValueTypes["EventsDocAccessFields_status_Read"],
	update?:ValueTypes["EventsDocAccessFields_status_Update"],
	delete?:ValueTypes["EventsDocAccessFields_status_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_status_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_status_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_status_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_status_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_level"]: AliasType<{
	create?:ValueTypes["EventsDocAccessFields_level_Create"],
	read?:ValueTypes["EventsDocAccessFields_level_Read"],
	update?:ValueTypes["EventsDocAccessFields_level_Update"],
	delete?:ValueTypes["EventsDocAccessFields_level_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_level_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_level_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_level_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_level_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_parent"]: AliasType<{
	create?:ValueTypes["EventsDocAccessFields_parent_Create"],
	read?:ValueTypes["EventsDocAccessFields_parent_Read"],
	update?:ValueTypes["EventsDocAccessFields_parent_Update"],
	delete?:ValueTypes["EventsDocAccessFields_parent_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_parent_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_parent_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_parent_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_parent_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_name"]: AliasType<{
	create?:ValueTypes["EventsDocAccessFields_name_Create"],
	read?:ValueTypes["EventsDocAccessFields_name_Read"],
	update?:ValueTypes["EventsDocAccessFields_name_Update"],
	delete?:ValueTypes["EventsDocAccessFields_name_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_name_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_name_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_name_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_name_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_chatbotEventTitle"]: AliasType<{
	create?:ValueTypes["EventsDocAccessFields_chatbotEventTitle_Create"],
	read?:ValueTypes["EventsDocAccessFields_chatbotEventTitle_Read"],
	update?:ValueTypes["EventsDocAccessFields_chatbotEventTitle_Update"],
	delete?:ValueTypes["EventsDocAccessFields_chatbotEventTitle_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_chatbotEventTitle_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_chatbotEventTitle_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_chatbotEventTitle_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_chatbotEventTitle_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_chatbotSequence"]: AliasType<{
	create?:ValueTypes["EventsDocAccessFields_chatbotSequence_Create"],
	read?:ValueTypes["EventsDocAccessFields_chatbotSequence_Read"],
	update?:ValueTypes["EventsDocAccessFields_chatbotSequence_Update"],
	delete?:ValueTypes["EventsDocAccessFields_chatbotSequence_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_chatbotSequence_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_chatbotSequence_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_chatbotSequence_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_chatbotSequence_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_thumbnail"]: AliasType<{
	create?:ValueTypes["EventsDocAccessFields_thumbnail_Create"],
	read?:ValueTypes["EventsDocAccessFields_thumbnail_Read"],
	update?:ValueTypes["EventsDocAccessFields_thumbnail_Update"],
	delete?:ValueTypes["EventsDocAccessFields_thumbnail_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_thumbnail_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_thumbnail_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_thumbnail_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_thumbnail_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_banner"]: AliasType<{
	create?:ValueTypes["EventsDocAccessFields_banner_Create"],
	read?:ValueTypes["EventsDocAccessFields_banner_Read"],
	update?:ValueTypes["EventsDocAccessFields_banner_Update"],
	delete?:ValueTypes["EventsDocAccessFields_banner_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_banner_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_banner_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_banner_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_banner_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_mobileBanner"]: AliasType<{
	create?:ValueTypes["EventsDocAccessFields_mobileBanner_Create"],
	read?:ValueTypes["EventsDocAccessFields_mobileBanner_Read"],
	update?:ValueTypes["EventsDocAccessFields_mobileBanner_Update"],
	delete?:ValueTypes["EventsDocAccessFields_mobileBanner_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_mobileBanner_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_mobileBanner_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_mobileBanner_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_mobileBanner_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_sections"]: AliasType<{
	create?:ValueTypes["EventsDocAccessFields_sections_Create"],
	read?:ValueTypes["EventsDocAccessFields_sections_Read"],
	update?:ValueTypes["EventsDocAccessFields_sections_Update"],
	delete?:ValueTypes["EventsDocAccessFields_sections_Delete"],
	fields?:ValueTypes["EventsDocAccessFields_sections_Fields"],
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_sections_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_sections_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_sections_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_sections_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_sections_Fields"]: AliasType<{
	startDatetime?:ValueTypes["EventsDocAccessFields_sections_startDatetime"],
	endDatetime?:ValueTypes["EventsDocAccessFields_sections_endDatetime"],
	fullDay?:ValueTypes["EventsDocAccessFields_sections_fullDay"],
	repeat?:ValueTypes["EventsDocAccessFields_sections_repeat"],
	toThisDay?:ValueTypes["EventsDocAccessFields_sections_toThisDay"],
	recurrance?:ValueTypes["EventsDocAccessFields_sections_recurrance"],
	sameLocation?:ValueTypes["EventsDocAccessFields_sections_sameLocation"],
	location?:ValueTypes["EventsDocAccessFields_sections_location"],
	district?:ValueTypes["EventsDocAccessFields_sections_district"],
	coordinate?:ValueTypes["EventsDocAccessFields_sections_coordinate"],
	linkAddress?:ValueTypes["EventsDocAccessFields_sections_linkAddress"],
	address?:ValueTypes["EventsDocAccessFields_sections_address"],
	id?:ValueTypes["EventsDocAccessFields_sections_id"],
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_sections_startDatetime"]: AliasType<{
	create?:ValueTypes["EventsDocAccessFields_sections_startDatetime_Create"],
	read?:ValueTypes["EventsDocAccessFields_sections_startDatetime_Read"],
	update?:ValueTypes["EventsDocAccessFields_sections_startDatetime_Update"],
	delete?:ValueTypes["EventsDocAccessFields_sections_startDatetime_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_sections_startDatetime_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_sections_startDatetime_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_sections_startDatetime_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_sections_startDatetime_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_sections_endDatetime"]: AliasType<{
	create?:ValueTypes["EventsDocAccessFields_sections_endDatetime_Create"],
	read?:ValueTypes["EventsDocAccessFields_sections_endDatetime_Read"],
	update?:ValueTypes["EventsDocAccessFields_sections_endDatetime_Update"],
	delete?:ValueTypes["EventsDocAccessFields_sections_endDatetime_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_sections_endDatetime_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_sections_endDatetime_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_sections_endDatetime_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_sections_endDatetime_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_sections_fullDay"]: AliasType<{
	create?:ValueTypes["EventsDocAccessFields_sections_fullDay_Create"],
	read?:ValueTypes["EventsDocAccessFields_sections_fullDay_Read"],
	update?:ValueTypes["EventsDocAccessFields_sections_fullDay_Update"],
	delete?:ValueTypes["EventsDocAccessFields_sections_fullDay_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_sections_fullDay_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_sections_fullDay_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_sections_fullDay_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_sections_fullDay_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_sections_repeat"]: AliasType<{
	create?:ValueTypes["EventsDocAccessFields_sections_repeat_Create"],
	read?:ValueTypes["EventsDocAccessFields_sections_repeat_Read"],
	update?:ValueTypes["EventsDocAccessFields_sections_repeat_Update"],
	delete?:ValueTypes["EventsDocAccessFields_sections_repeat_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_sections_repeat_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_sections_repeat_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_sections_repeat_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_sections_repeat_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_sections_toThisDay"]: AliasType<{
	create?:ValueTypes["EventsDocAccessFields_sections_toThisDay_Create"],
	read?:ValueTypes["EventsDocAccessFields_sections_toThisDay_Read"],
	update?:ValueTypes["EventsDocAccessFields_sections_toThisDay_Update"],
	delete?:ValueTypes["EventsDocAccessFields_sections_toThisDay_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_sections_toThisDay_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_sections_toThisDay_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_sections_toThisDay_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_sections_toThisDay_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_sections_recurrance"]: AliasType<{
	create?:ValueTypes["EventsDocAccessFields_sections_recurrance_Create"],
	read?:ValueTypes["EventsDocAccessFields_sections_recurrance_Read"],
	update?:ValueTypes["EventsDocAccessFields_sections_recurrance_Update"],
	delete?:ValueTypes["EventsDocAccessFields_sections_recurrance_Delete"],
	fields?:ValueTypes["EventsDocAccessFields_sections_recurrance_Fields"],
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_sections_recurrance_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_sections_recurrance_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_sections_recurrance_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_sections_recurrance_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_sections_recurrance_Fields"]: AliasType<{
	type?:ValueTypes["EventsDocAccessFields_sections_recurrance_type"],
	weekday?:ValueTypes["EventsDocAccessFields_sections_recurrance_weekday"],
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_sections_recurrance_type"]: AliasType<{
	create?:ValueTypes["EventsDocAccessFields_sections_recurrance_type_Create"],
	read?:ValueTypes["EventsDocAccessFields_sections_recurrance_type_Read"],
	update?:ValueTypes["EventsDocAccessFields_sections_recurrance_type_Update"],
	delete?:ValueTypes["EventsDocAccessFields_sections_recurrance_type_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_sections_recurrance_type_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_sections_recurrance_type_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_sections_recurrance_type_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_sections_recurrance_type_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_sections_recurrance_weekday"]: AliasType<{
	create?:ValueTypes["EventsDocAccessFields_sections_recurrance_weekday_Create"],
	read?:ValueTypes["EventsDocAccessFields_sections_recurrance_weekday_Read"],
	update?:ValueTypes["EventsDocAccessFields_sections_recurrance_weekday_Update"],
	delete?:ValueTypes["EventsDocAccessFields_sections_recurrance_weekday_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_sections_recurrance_weekday_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_sections_recurrance_weekday_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_sections_recurrance_weekday_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_sections_recurrance_weekday_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_sections_sameLocation"]: AliasType<{
	create?:ValueTypes["EventsDocAccessFields_sections_sameLocation_Create"],
	read?:ValueTypes["EventsDocAccessFields_sections_sameLocation_Read"],
	update?:ValueTypes["EventsDocAccessFields_sections_sameLocation_Update"],
	delete?:ValueTypes["EventsDocAccessFields_sections_sameLocation_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_sections_sameLocation_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_sections_sameLocation_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_sections_sameLocation_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_sections_sameLocation_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_sections_location"]: AliasType<{
	create?:ValueTypes["EventsDocAccessFields_sections_location_Create"],
	read?:ValueTypes["EventsDocAccessFields_sections_location_Read"],
	update?:ValueTypes["EventsDocAccessFields_sections_location_Update"],
	delete?:ValueTypes["EventsDocAccessFields_sections_location_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_sections_location_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_sections_location_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_sections_location_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_sections_location_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_sections_district"]: AliasType<{
	create?:ValueTypes["EventsDocAccessFields_sections_district_Create"],
	read?:ValueTypes["EventsDocAccessFields_sections_district_Read"],
	update?:ValueTypes["EventsDocAccessFields_sections_district_Update"],
	delete?:ValueTypes["EventsDocAccessFields_sections_district_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_sections_district_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_sections_district_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_sections_district_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_sections_district_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_sections_coordinate"]: AliasType<{
	create?:ValueTypes["EventsDocAccessFields_sections_coordinate_Create"],
	read?:ValueTypes["EventsDocAccessFields_sections_coordinate_Read"],
	update?:ValueTypes["EventsDocAccessFields_sections_coordinate_Update"],
	delete?:ValueTypes["EventsDocAccessFields_sections_coordinate_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_sections_coordinate_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_sections_coordinate_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_sections_coordinate_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_sections_coordinate_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_sections_linkAddress"]: AliasType<{
	create?:ValueTypes["EventsDocAccessFields_sections_linkAddress_Create"],
	read?:ValueTypes["EventsDocAccessFields_sections_linkAddress_Read"],
	update?:ValueTypes["EventsDocAccessFields_sections_linkAddress_Update"],
	delete?:ValueTypes["EventsDocAccessFields_sections_linkAddress_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_sections_linkAddress_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_sections_linkAddress_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_sections_linkAddress_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_sections_linkAddress_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_sections_address"]: AliasType<{
	create?:ValueTypes["EventsDocAccessFields_sections_address_Create"],
	read?:ValueTypes["EventsDocAccessFields_sections_address_Read"],
	update?:ValueTypes["EventsDocAccessFields_sections_address_Update"],
	delete?:ValueTypes["EventsDocAccessFields_sections_address_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_sections_address_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_sections_address_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_sections_address_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_sections_address_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_sections_id"]: AliasType<{
	create?:ValueTypes["EventsDocAccessFields_sections_id_Create"],
	read?:ValueTypes["EventsDocAccessFields_sections_id_Read"],
	update?:ValueTypes["EventsDocAccessFields_sections_id_Update"],
	delete?:ValueTypes["EventsDocAccessFields_sections_id_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_sections_id_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_sections_id_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_sections_id_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_sections_id_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_ticketing"]: AliasType<{
	create?:ValueTypes["EventsDocAccessFields_ticketing_Create"],
	read?:ValueTypes["EventsDocAccessFields_ticketing_Read"],
	update?:ValueTypes["EventsDocAccessFields_ticketing_Update"],
	delete?:ValueTypes["EventsDocAccessFields_ticketing_Delete"],
	fields?:ValueTypes["EventsDocAccessFields_ticketing_Fields"],
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_ticketing_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_ticketing_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_ticketing_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_ticketing_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_ticketing_Fields"]: AliasType<{
	startDatetime?:ValueTypes["EventsDocAccessFields_ticketing_startDatetime"],
	endDatetime?:ValueTypes["EventsDocAccessFields_ticketing_endDatetime"],
	fullDay?:ValueTypes["EventsDocAccessFields_ticketing_fullDay"],
	type?:ValueTypes["EventsDocAccessFields_ticketing_type"],
	ticketingType?:ValueTypes["EventsDocAccessFields_ticketing_ticketingType"],
	linkedTicketing?:ValueTypes["EventsDocAccessFields_ticketing_linkedTicketing"],
	promotionType?:ValueTypes["EventsDocAccessFields_ticketing_promotionType"],
	location?:ValueTypes["EventsDocAccessFields_ticketing_location"],
	website?:ValueTypes["EventsDocAccessFields_ticketing_website"],
	description?:ValueTypes["EventsDocAccessFields_ticketing_description"],
	id?:ValueTypes["EventsDocAccessFields_ticketing_id"],
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_ticketing_startDatetime"]: AliasType<{
	create?:ValueTypes["EventsDocAccessFields_ticketing_startDatetime_Create"],
	read?:ValueTypes["EventsDocAccessFields_ticketing_startDatetime_Read"],
	update?:ValueTypes["EventsDocAccessFields_ticketing_startDatetime_Update"],
	delete?:ValueTypes["EventsDocAccessFields_ticketing_startDatetime_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_ticketing_startDatetime_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_ticketing_startDatetime_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_ticketing_startDatetime_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_ticketing_startDatetime_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_ticketing_endDatetime"]: AliasType<{
	create?:ValueTypes["EventsDocAccessFields_ticketing_endDatetime_Create"],
	read?:ValueTypes["EventsDocAccessFields_ticketing_endDatetime_Read"],
	update?:ValueTypes["EventsDocAccessFields_ticketing_endDatetime_Update"],
	delete?:ValueTypes["EventsDocAccessFields_ticketing_endDatetime_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_ticketing_endDatetime_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_ticketing_endDatetime_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_ticketing_endDatetime_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_ticketing_endDatetime_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_ticketing_fullDay"]: AliasType<{
	create?:ValueTypes["EventsDocAccessFields_ticketing_fullDay_Create"],
	read?:ValueTypes["EventsDocAccessFields_ticketing_fullDay_Read"],
	update?:ValueTypes["EventsDocAccessFields_ticketing_fullDay_Update"],
	delete?:ValueTypes["EventsDocAccessFields_ticketing_fullDay_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_ticketing_fullDay_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_ticketing_fullDay_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_ticketing_fullDay_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_ticketing_fullDay_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_ticketing_type"]: AliasType<{
	create?:ValueTypes["EventsDocAccessFields_ticketing_type_Create"],
	read?:ValueTypes["EventsDocAccessFields_ticketing_type_Read"],
	update?:ValueTypes["EventsDocAccessFields_ticketing_type_Update"],
	delete?:ValueTypes["EventsDocAccessFields_ticketing_type_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_ticketing_type_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_ticketing_type_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_ticketing_type_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_ticketing_type_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_ticketing_ticketingType"]: AliasType<{
	create?:ValueTypes["EventsDocAccessFields_ticketing_ticketingType_Create"],
	read?:ValueTypes["EventsDocAccessFields_ticketing_ticketingType_Read"],
	update?:ValueTypes["EventsDocAccessFields_ticketing_ticketingType_Update"],
	delete?:ValueTypes["EventsDocAccessFields_ticketing_ticketingType_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_ticketing_ticketingType_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_ticketing_ticketingType_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_ticketing_ticketingType_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_ticketing_ticketingType_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_ticketing_linkedTicketing"]: AliasType<{
	create?:ValueTypes["EventsDocAccessFields_ticketing_linkedTicketing_Create"],
	read?:ValueTypes["EventsDocAccessFields_ticketing_linkedTicketing_Read"],
	update?:ValueTypes["EventsDocAccessFields_ticketing_linkedTicketing_Update"],
	delete?:ValueTypes["EventsDocAccessFields_ticketing_linkedTicketing_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_ticketing_linkedTicketing_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_ticketing_linkedTicketing_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_ticketing_linkedTicketing_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_ticketing_linkedTicketing_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_ticketing_promotionType"]: AliasType<{
	create?:ValueTypes["EventsDocAccessFields_ticketing_promotionType_Create"],
	read?:ValueTypes["EventsDocAccessFields_ticketing_promotionType_Read"],
	update?:ValueTypes["EventsDocAccessFields_ticketing_promotionType_Update"],
	delete?:ValueTypes["EventsDocAccessFields_ticketing_promotionType_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_ticketing_promotionType_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_ticketing_promotionType_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_ticketing_promotionType_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_ticketing_promotionType_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_ticketing_location"]: AliasType<{
	create?:ValueTypes["EventsDocAccessFields_ticketing_location_Create"],
	read?:ValueTypes["EventsDocAccessFields_ticketing_location_Read"],
	update?:ValueTypes["EventsDocAccessFields_ticketing_location_Update"],
	delete?:ValueTypes["EventsDocAccessFields_ticketing_location_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_ticketing_location_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_ticketing_location_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_ticketing_location_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_ticketing_location_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_ticketing_website"]: AliasType<{
	create?:ValueTypes["EventsDocAccessFields_ticketing_website_Create"],
	read?:ValueTypes["EventsDocAccessFields_ticketing_website_Read"],
	update?:ValueTypes["EventsDocAccessFields_ticketing_website_Update"],
	delete?:ValueTypes["EventsDocAccessFields_ticketing_website_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_ticketing_website_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_ticketing_website_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_ticketing_website_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_ticketing_website_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_ticketing_description"]: AliasType<{
	create?:ValueTypes["EventsDocAccessFields_ticketing_description_Create"],
	read?:ValueTypes["EventsDocAccessFields_ticketing_description_Read"],
	update?:ValueTypes["EventsDocAccessFields_ticketing_description_Update"],
	delete?:ValueTypes["EventsDocAccessFields_ticketing_description_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_ticketing_description_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_ticketing_description_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_ticketing_description_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_ticketing_description_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_ticketing_id"]: AliasType<{
	create?:ValueTypes["EventsDocAccessFields_ticketing_id_Create"],
	read?:ValueTypes["EventsDocAccessFields_ticketing_id_Read"],
	update?:ValueTypes["EventsDocAccessFields_ticketing_id_Update"],
	delete?:ValueTypes["EventsDocAccessFields_ticketing_id_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_ticketing_id_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_ticketing_id_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_ticketing_id_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_ticketing_id_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_content"]: AliasType<{
	create?:ValueTypes["EventsDocAccessFields_content_Create"],
	read?:ValueTypes["EventsDocAccessFields_content_Read"],
	update?:ValueTypes["EventsDocAccessFields_content_Update"],
	delete?:ValueTypes["EventsDocAccessFields_content_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_content_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_content_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_content_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_content_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_legacyContent"]: AliasType<{
	create?:ValueTypes["EventsDocAccessFields_legacyContent_Create"],
	read?:ValueTypes["EventsDocAccessFields_legacyContent_Read"],
	update?:ValueTypes["EventsDocAccessFields_legacyContent_Update"],
	delete?:ValueTypes["EventsDocAccessFields_legacyContent_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_legacyContent_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_legacyContent_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_legacyContent_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_legacyContent_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_showLegacyContent"]: AliasType<{
	create?:ValueTypes["EventsDocAccessFields_showLegacyContent_Create"],
	read?:ValueTypes["EventsDocAccessFields_showLegacyContent_Read"],
	update?:ValueTypes["EventsDocAccessFields_showLegacyContent_Update"],
	delete?:ValueTypes["EventsDocAccessFields_showLegacyContent_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_showLegacyContent_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_showLegacyContent_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_showLegacyContent_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_showLegacyContent_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_criteria"]: AliasType<{
	create?:ValueTypes["EventsDocAccessFields_criteria_Create"],
	read?:ValueTypes["EventsDocAccessFields_criteria_Read"],
	update?:ValueTypes["EventsDocAccessFields_criteria_Update"],
	delete?:ValueTypes["EventsDocAccessFields_criteria_Delete"],
	fields?:ValueTypes["EventsDocAccessFields_criteria_Fields"],
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_criteria_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_criteria_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_criteria_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_criteria_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_criteria_Fields"]: AliasType<{
	categories?:ValueTypes["EventsDocAccessFields_criteria_categories"],
	audiences?:ValueTypes["EventsDocAccessFields_criteria_audiences"],
	tags?:ValueTypes["EventsDocAccessFields_criteria_tags"],
	specialTags?:ValueTypes["EventsDocAccessFields_criteria_specialTags"],
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_criteria_categories"]: AliasType<{
	create?:ValueTypes["EventsDocAccessFields_criteria_categories_Create"],
	read?:ValueTypes["EventsDocAccessFields_criteria_categories_Read"],
	update?:ValueTypes["EventsDocAccessFields_criteria_categories_Update"],
	delete?:ValueTypes["EventsDocAccessFields_criteria_categories_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_criteria_categories_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_criteria_categories_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_criteria_categories_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_criteria_categories_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_criteria_audiences"]: AliasType<{
	create?:ValueTypes["EventsDocAccessFields_criteria_audiences_Create"],
	read?:ValueTypes["EventsDocAccessFields_criteria_audiences_Read"],
	update?:ValueTypes["EventsDocAccessFields_criteria_audiences_Update"],
	delete?:ValueTypes["EventsDocAccessFields_criteria_audiences_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_criteria_audiences_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_criteria_audiences_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_criteria_audiences_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_criteria_audiences_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_criteria_tags"]: AliasType<{
	create?:ValueTypes["EventsDocAccessFields_criteria_tags_Create"],
	read?:ValueTypes["EventsDocAccessFields_criteria_tags_Read"],
	update?:ValueTypes["EventsDocAccessFields_criteria_tags_Update"],
	delete?:ValueTypes["EventsDocAccessFields_criteria_tags_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_criteria_tags_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_criteria_tags_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_criteria_tags_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_criteria_tags_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_criteria_specialTags"]: AliasType<{
	create?:ValueTypes["EventsDocAccessFields_criteria_specialTags_Create"],
	read?:ValueTypes["EventsDocAccessFields_criteria_specialTags_Read"],
	update?:ValueTypes["EventsDocAccessFields_criteria_specialTags_Update"],
	delete?:ValueTypes["EventsDocAccessFields_criteria_specialTags_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_criteria_specialTags_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_criteria_specialTags_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_criteria_specialTags_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_criteria_specialTags_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_walkIn"]: AliasType<{
	create?:ValueTypes["EventsDocAccessFields_walkIn_Create"],
	read?:ValueTypes["EventsDocAccessFields_walkIn_Read"],
	update?:ValueTypes["EventsDocAccessFields_walkIn_Update"],
	delete?:ValueTypes["EventsDocAccessFields_walkIn_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_walkIn_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_walkIn_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_walkIn_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_walkIn_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_contact"]: AliasType<{
	create?:ValueTypes["EventsDocAccessFields_contact_Create"],
	read?:ValueTypes["EventsDocAccessFields_contact_Read"],
	update?:ValueTypes["EventsDocAccessFields_contact_Update"],
	delete?:ValueTypes["EventsDocAccessFields_contact_Delete"],
	fields?:ValueTypes["EventsDocAccessFields_contact_Fields"],
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_contact_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_contact_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_contact_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_contact_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_contact_Fields"]: AliasType<{
	code?:ValueTypes["EventsDocAccessFields_contact_code"],
	phone?:ValueTypes["EventsDocAccessFields_contact_phone"],
	page?:ValueTypes["EventsDocAccessFields_contact_page"],
	email?:ValueTypes["EventsDocAccessFields_contact_email"],
	privacy?:ValueTypes["EventsDocAccessFields_contact_privacy"],
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_contact_code"]: AliasType<{
	create?:ValueTypes["EventsDocAccessFields_contact_code_Create"],
	read?:ValueTypes["EventsDocAccessFields_contact_code_Read"],
	update?:ValueTypes["EventsDocAccessFields_contact_code_Update"],
	delete?:ValueTypes["EventsDocAccessFields_contact_code_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_contact_code_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_contact_code_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_contact_code_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_contact_code_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_contact_phone"]: AliasType<{
	create?:ValueTypes["EventsDocAccessFields_contact_phone_Create"],
	read?:ValueTypes["EventsDocAccessFields_contact_phone_Read"],
	update?:ValueTypes["EventsDocAccessFields_contact_phone_Update"],
	delete?:ValueTypes["EventsDocAccessFields_contact_phone_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_contact_phone_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_contact_phone_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_contact_phone_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_contact_phone_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_contact_page"]: AliasType<{
	create?:ValueTypes["EventsDocAccessFields_contact_page_Create"],
	read?:ValueTypes["EventsDocAccessFields_contact_page_Read"],
	update?:ValueTypes["EventsDocAccessFields_contact_page_Update"],
	delete?:ValueTypes["EventsDocAccessFields_contact_page_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_contact_page_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_contact_page_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_contact_page_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_contact_page_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_contact_email"]: AliasType<{
	create?:ValueTypes["EventsDocAccessFields_contact_email_Create"],
	read?:ValueTypes["EventsDocAccessFields_contact_email_Read"],
	update?:ValueTypes["EventsDocAccessFields_contact_email_Update"],
	delete?:ValueTypes["EventsDocAccessFields_contact_email_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_contact_email_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_contact_email_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_contact_email_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_contact_email_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_contact_privacy"]: AliasType<{
	create?:ValueTypes["EventsDocAccessFields_contact_privacy_Create"],
	read?:ValueTypes["EventsDocAccessFields_contact_privacy_Read"],
	update?:ValueTypes["EventsDocAccessFields_contact_privacy_Update"],
	delete?:ValueTypes["EventsDocAccessFields_contact_privacy_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_contact_privacy_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_contact_privacy_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_contact_privacy_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_contact_privacy_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_views"]: AliasType<{
	create?:ValueTypes["EventsDocAccessFields_views_Create"],
	read?:ValueTypes["EventsDocAccessFields_views_Read"],
	update?:ValueTypes["EventsDocAccessFields_views_Update"],
	delete?:ValueTypes["EventsDocAccessFields_views_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_views_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_views_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_views_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_views_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_free"]: AliasType<{
	create?:ValueTypes["EventsDocAccessFields_free_Create"],
	read?:ValueTypes["EventsDocAccessFields_free_Read"],
	update?:ValueTypes["EventsDocAccessFields_free_Update"],
	delete?:ValueTypes["EventsDocAccessFields_free_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_free_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_free_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_free_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_free_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_fees"]: AliasType<{
	create?:ValueTypes["EventsDocAccessFields_fees_Create"],
	read?:ValueTypes["EventsDocAccessFields_fees_Read"],
	update?:ValueTypes["EventsDocAccessFields_fees_Update"],
	delete?:ValueTypes["EventsDocAccessFields_fees_Delete"],
	fields?:ValueTypes["EventsDocAccessFields_fees_Fields"],
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_fees_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_fees_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_fees_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_fees_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_fees_Fields"]: AliasType<{
	fee?:ValueTypes["EventsDocAccessFields_fees_fee"],
	remark?:ValueTypes["EventsDocAccessFields_fees_remark"],
	id?:ValueTypes["EventsDocAccessFields_fees_id"],
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_fees_fee"]: AliasType<{
	create?:ValueTypes["EventsDocAccessFields_fees_fee_Create"],
	read?:ValueTypes["EventsDocAccessFields_fees_fee_Read"],
	update?:ValueTypes["EventsDocAccessFields_fees_fee_Update"],
	delete?:ValueTypes["EventsDocAccessFields_fees_fee_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_fees_fee_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_fees_fee_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_fees_fee_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_fees_fee_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_fees_remark"]: AliasType<{
	create?:ValueTypes["EventsDocAccessFields_fees_remark_Create"],
	read?:ValueTypes["EventsDocAccessFields_fees_remark_Read"],
	update?:ValueTypes["EventsDocAccessFields_fees_remark_Update"],
	delete?:ValueTypes["EventsDocAccessFields_fees_remark_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_fees_remark_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_fees_remark_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_fees_remark_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_fees_remark_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_fees_id"]: AliasType<{
	create?:ValueTypes["EventsDocAccessFields_fees_id_Create"],
	read?:ValueTypes["EventsDocAccessFields_fees_id_Read"],
	update?:ValueTypes["EventsDocAccessFields_fees_id_Update"],
	delete?:ValueTypes["EventsDocAccessFields_fees_id_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_fees_id_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_fees_id_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_fees_id_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_fees_id_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_homePageInternalClick"]: AliasType<{
	create?:ValueTypes["EventsDocAccessFields_homePageInternalClick_Create"],
	read?:ValueTypes["EventsDocAccessFields_homePageInternalClick_Read"],
	update?:ValueTypes["EventsDocAccessFields_homePageInternalClick_Update"],
	delete?:ValueTypes["EventsDocAccessFields_homePageInternalClick_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_homePageInternalClick_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_homePageInternalClick_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_homePageInternalClick_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_homePageInternalClick_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_homePageImpression"]: AliasType<{
	create?:ValueTypes["EventsDocAccessFields_homePageImpression_Create"],
	read?:ValueTypes["EventsDocAccessFields_homePageImpression_Read"],
	update?:ValueTypes["EventsDocAccessFields_homePageImpression_Update"],
	delete?:ValueTypes["EventsDocAccessFields_homePageImpression_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_homePageImpression_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_homePageImpression_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_homePageImpression_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_homePageImpression_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_eodClickRate"]: AliasType<{
	create?:ValueTypes["EventsDocAccessFields_eodClickRate_Create"],
	read?:ValueTypes["EventsDocAccessFields_eodClickRate_Read"],
	update?:ValueTypes["EventsDocAccessFields_eodClickRate_Update"],
	delete?:ValueTypes["EventsDocAccessFields_eodClickRate_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_eodClickRate_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_eodClickRate_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_eodClickRate_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_eodClickRate_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_eodImpressionRate"]: AliasType<{
	create?:ValueTypes["EventsDocAccessFields_eodImpressionRate_Create"],
	read?:ValueTypes["EventsDocAccessFields_eodImpressionRate_Read"],
	update?:ValueTypes["EventsDocAccessFields_eodImpressionRate_Update"],
	delete?:ValueTypes["EventsDocAccessFields_eodImpressionRate_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_eodImpressionRate_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_eodImpressionRate_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_eodImpressionRate_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_eodImpressionRate_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_top10ClickRateHomepage"]: AliasType<{
	create?:ValueTypes["EventsDocAccessFields_top10ClickRateHomepage_Create"],
	read?:ValueTypes["EventsDocAccessFields_top10ClickRateHomepage_Read"],
	update?:ValueTypes["EventsDocAccessFields_top10ClickRateHomepage_Update"],
	delete?:ValueTypes["EventsDocAccessFields_top10ClickRateHomepage_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_top10ClickRateHomepage_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_top10ClickRateHomepage_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_top10ClickRateHomepage_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_top10ClickRateHomepage_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_top10ImpressionkRateHomepage"]: AliasType<{
	create?:ValueTypes["EventsDocAccessFields_top10ImpressionkRateHomepage_Create"],
	read?:ValueTypes["EventsDocAccessFields_top10ImpressionkRateHomepage_Read"],
	update?:ValueTypes["EventsDocAccessFields_top10ImpressionkRateHomepage_Update"],
	delete?:ValueTypes["EventsDocAccessFields_top10ImpressionkRateHomepage_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_top10ImpressionkRateHomepage_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_top10ImpressionkRateHomepage_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_top10ImpressionkRateHomepage_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_top10ImpressionkRateHomepage_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_otherEventRankingClickRateHomepage"]: AliasType<{
	create?:ValueTypes["EventsDocAccessFields_otherEventRankingClickRateHomepage_Create"],
	read?:ValueTypes["EventsDocAccessFields_otherEventRankingClickRateHomepage_Read"],
	update?:ValueTypes["EventsDocAccessFields_otherEventRankingClickRateHomepage_Update"],
	delete?:ValueTypes["EventsDocAccessFields_otherEventRankingClickRateHomepage_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_otherEventRankingClickRateHomepage_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_otherEventRankingClickRateHomepage_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_otherEventRankingClickRateHomepage_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_otherEventRankingClickRateHomepage_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_otherEventRankingImpressionHomePage"]: AliasType<{
	create?:ValueTypes["EventsDocAccessFields_otherEventRankingImpressionHomePage_Create"],
	read?:ValueTypes["EventsDocAccessFields_otherEventRankingImpressionHomePage_Read"],
	update?:ValueTypes["EventsDocAccessFields_otherEventRankingImpressionHomePage_Update"],
	delete?:ValueTypes["EventsDocAccessFields_otherEventRankingImpressionHomePage_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_otherEventRankingImpressionHomePage_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_otherEventRankingImpressionHomePage_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_otherEventRankingImpressionHomePage_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_otherEventRankingImpressionHomePage_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_userPreferenceClickRate"]: AliasType<{
	create?:ValueTypes["EventsDocAccessFields_userPreferenceClickRate_Create"],
	read?:ValueTypes["EventsDocAccessFields_userPreferenceClickRate_Read"],
	update?:ValueTypes["EventsDocAccessFields_userPreferenceClickRate_Update"],
	delete?:ValueTypes["EventsDocAccessFields_userPreferenceClickRate_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_userPreferenceClickRate_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_userPreferenceClickRate_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_userPreferenceClickRate_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_userPreferenceClickRate_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_userPreferenceImpression"]: AliasType<{
	create?:ValueTypes["EventsDocAccessFields_userPreferenceImpression_Create"],
	read?:ValueTypes["EventsDocAccessFields_userPreferenceImpression_Read"],
	update?:ValueTypes["EventsDocAccessFields_userPreferenceImpression_Update"],
	delete?:ValueTypes["EventsDocAccessFields_userPreferenceImpression_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_userPreferenceImpression_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_userPreferenceImpression_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_userPreferenceImpression_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_userPreferenceImpression_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_eventSearchInternalClick"]: AliasType<{
	create?:ValueTypes["EventsDocAccessFields_eventSearchInternalClick_Create"],
	read?:ValueTypes["EventsDocAccessFields_eventSearchInternalClick_Read"],
	update?:ValueTypes["EventsDocAccessFields_eventSearchInternalClick_Update"],
	delete?:ValueTypes["EventsDocAccessFields_eventSearchInternalClick_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_eventSearchInternalClick_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_eventSearchInternalClick_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_eventSearchInternalClick_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_eventSearchInternalClick_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_eventListImpression"]: AliasType<{
	create?:ValueTypes["EventsDocAccessFields_eventListImpression_Create"],
	read?:ValueTypes["EventsDocAccessFields_eventListImpression_Read"],
	update?:ValueTypes["EventsDocAccessFields_eventListImpression_Update"],
	delete?:ValueTypes["EventsDocAccessFields_eventListImpression_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_eventListImpression_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_eventListImpression_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_eventListImpression_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_eventListImpression_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_latestEventInternalClick"]: AliasType<{
	create?:ValueTypes["EventsDocAccessFields_latestEventInternalClick_Create"],
	read?:ValueTypes["EventsDocAccessFields_latestEventInternalClick_Read"],
	update?:ValueTypes["EventsDocAccessFields_latestEventInternalClick_Update"],
	delete?:ValueTypes["EventsDocAccessFields_latestEventInternalClick_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_latestEventInternalClick_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_latestEventInternalClick_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_latestEventInternalClick_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_latestEventInternalClick_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_latestEventImpression"]: AliasType<{
	create?:ValueTypes["EventsDocAccessFields_latestEventImpression_Create"],
	read?:ValueTypes["EventsDocAccessFields_latestEventImpression_Read"],
	update?:ValueTypes["EventsDocAccessFields_latestEventImpression_Update"],
	delete?:ValueTypes["EventsDocAccessFields_latestEventImpression_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_latestEventImpression_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_latestEventImpression_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_latestEventImpression_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_latestEventImpression_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_topTenEventRankingInternalClick"]: AliasType<{
	create?:ValueTypes["EventsDocAccessFields_topTenEventRankingInternalClick_Create"],
	read?:ValueTypes["EventsDocAccessFields_topTenEventRankingInternalClick_Read"],
	update?:ValueTypes["EventsDocAccessFields_topTenEventRankingInternalClick_Update"],
	delete?:ValueTypes["EventsDocAccessFields_topTenEventRankingInternalClick_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_topTenEventRankingInternalClick_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_topTenEventRankingInternalClick_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_topTenEventRankingInternalClick_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_topTenEventRankingInternalClick_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_topTenImpression"]: AliasType<{
	create?:ValueTypes["EventsDocAccessFields_topTenImpression_Create"],
	read?:ValueTypes["EventsDocAccessFields_topTenImpression_Read"],
	update?:ValueTypes["EventsDocAccessFields_topTenImpression_Update"],
	delete?:ValueTypes["EventsDocAccessFields_topTenImpression_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_topTenImpression_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_topTenImpression_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_topTenImpression_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_topTenImpression_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_editorChoiceInternalClick"]: AliasType<{
	create?:ValueTypes["EventsDocAccessFields_editorChoiceInternalClick_Create"],
	read?:ValueTypes["EventsDocAccessFields_editorChoiceInternalClick_Read"],
	update?:ValueTypes["EventsDocAccessFields_editorChoiceInternalClick_Update"],
	delete?:ValueTypes["EventsDocAccessFields_editorChoiceInternalClick_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_editorChoiceInternalClick_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_editorChoiceInternalClick_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_editorChoiceInternalClick_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_editorChoiceInternalClick_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_editorChoiceImpression"]: AliasType<{
	create?:ValueTypes["EventsDocAccessFields_editorChoiceImpression_Create"],
	read?:ValueTypes["EventsDocAccessFields_editorChoiceImpression_Read"],
	update?:ValueTypes["EventsDocAccessFields_editorChoiceImpression_Update"],
	delete?:ValueTypes["EventsDocAccessFields_editorChoiceImpression_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_editorChoiceImpression_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_editorChoiceImpression_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_editorChoiceImpression_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_editorChoiceImpression_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_otherEventRankingInternalClick"]: AliasType<{
	create?:ValueTypes["EventsDocAccessFields_otherEventRankingInternalClick_Create"],
	read?:ValueTypes["EventsDocAccessFields_otherEventRankingInternalClick_Read"],
	update?:ValueTypes["EventsDocAccessFields_otherEventRankingInternalClick_Update"],
	delete?:ValueTypes["EventsDocAccessFields_otherEventRankingInternalClick_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_otherEventRankingInternalClick_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_otherEventRankingInternalClick_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_otherEventRankingInternalClick_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_otherEventRankingInternalClick_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_otherEventRankingImpression"]: AliasType<{
	create?:ValueTypes["EventsDocAccessFields_otherEventRankingImpression_Create"],
	read?:ValueTypes["EventsDocAccessFields_otherEventRankingImpression_Read"],
	update?:ValueTypes["EventsDocAccessFields_otherEventRankingImpression_Update"],
	delete?:ValueTypes["EventsDocAccessFields_otherEventRankingImpression_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_otherEventRankingImpression_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_otherEventRankingImpression_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_otherEventRankingImpression_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_otherEventRankingImpression_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_bannerInternalClick"]: AliasType<{
	create?:ValueTypes["EventsDocAccessFields_bannerInternalClick_Create"],
	read?:ValueTypes["EventsDocAccessFields_bannerInternalClick_Read"],
	update?:ValueTypes["EventsDocAccessFields_bannerInternalClick_Update"],
	delete?:ValueTypes["EventsDocAccessFields_bannerInternalClick_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_bannerInternalClick_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_bannerInternalClick_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_bannerInternalClick_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_bannerInternalClick_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_miscImpression"]: AliasType<{
	create?:ValueTypes["EventsDocAccessFields_miscImpression_Create"],
	read?:ValueTypes["EventsDocAccessFields_miscImpression_Read"],
	update?:ValueTypes["EventsDocAccessFields_miscImpression_Update"],
	delete?:ValueTypes["EventsDocAccessFields_miscImpression_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_miscImpression_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_miscImpression_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_miscImpression_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_miscImpression_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_miscInternalClick"]: AliasType<{
	create?:ValueTypes["EventsDocAccessFields_miscInternalClick_Create"],
	read?:ValueTypes["EventsDocAccessFields_miscInternalClick_Read"],
	update?:ValueTypes["EventsDocAccessFields_miscInternalClick_Update"],
	delete?:ValueTypes["EventsDocAccessFields_miscInternalClick_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_miscInternalClick_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_miscInternalClick_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_miscInternalClick_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_miscInternalClick_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_totalImpression"]: AliasType<{
	create?:ValueTypes["EventsDocAccessFields_totalImpression_Create"],
	read?:ValueTypes["EventsDocAccessFields_totalImpression_Read"],
	update?:ValueTypes["EventsDocAccessFields_totalImpression_Update"],
	delete?:ValueTypes["EventsDocAccessFields_totalImpression_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_totalImpression_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_totalImpression_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_totalImpression_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_totalImpression_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_internalClick"]: AliasType<{
	create?:ValueTypes["EventsDocAccessFields_internalClick_Create"],
	read?:ValueTypes["EventsDocAccessFields_internalClick_Read"],
	update?:ValueTypes["EventsDocAccessFields_internalClick_Update"],
	delete?:ValueTypes["EventsDocAccessFields_internalClick_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_internalClick_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_internalClick_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_internalClick_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_internalClick_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_outboundClick"]: AliasType<{
	create?:ValueTypes["EventsDocAccessFields_outboundClick_Create"],
	read?:ValueTypes["EventsDocAccessFields_outboundClick_Read"],
	update?:ValueTypes["EventsDocAccessFields_outboundClick_Update"],
	delete?:ValueTypes["EventsDocAccessFields_outboundClick_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_outboundClick_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_outboundClick_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_outboundClick_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_outboundClick_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_externalClick"]: AliasType<{
	create?:ValueTypes["EventsDocAccessFields_externalClick_Create"],
	read?:ValueTypes["EventsDocAccessFields_externalClick_Read"],
	update?:ValueTypes["EventsDocAccessFields_externalClick_Update"],
	delete?:ValueTypes["EventsDocAccessFields_externalClick_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_externalClick_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_externalClick_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_externalClick_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_externalClick_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields__viewsCountByMonth"]: AliasType<{
	create?:ValueTypes["EventsDocAccessFields__viewsCountByMonth_Create"],
	read?:ValueTypes["EventsDocAccessFields__viewsCountByMonth_Read"],
	update?:ValueTypes["EventsDocAccessFields__viewsCountByMonth_Update"],
	delete?:ValueTypes["EventsDocAccessFields__viewsCountByMonth_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields__viewsCountByMonth_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields__viewsCountByMonth_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields__viewsCountByMonth_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields__viewsCountByMonth_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_products"]: AliasType<{
	create?:ValueTypes["EventsDocAccessFields_products_Create"],
	read?:ValueTypes["EventsDocAccessFields_products_Read"],
	update?:ValueTypes["EventsDocAccessFields_products_Update"],
	delete?:ValueTypes["EventsDocAccessFields_products_Delete"],
	fields?:ValueTypes["EventsDocAccessFields_products_Fields"],
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_products_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_products_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_products_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_products_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_products_Fields"]: AliasType<{
	startDateTime?:ValueTypes["EventsDocAccessFields_products_startDateTime"],
	endDateTime?:ValueTypes["EventsDocAccessFields_products_endDateTime"],
	fullDay?:ValueTypes["EventsDocAccessFields_products_fullDay"],
	linkedProduct?:ValueTypes["EventsDocAccessFields_products_linkedProduct"],
	id?:ValueTypes["EventsDocAccessFields_products_id"],
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_products_startDateTime"]: AliasType<{
	create?:ValueTypes["EventsDocAccessFields_products_startDateTime_Create"],
	read?:ValueTypes["EventsDocAccessFields_products_startDateTime_Read"],
	update?:ValueTypes["EventsDocAccessFields_products_startDateTime_Update"],
	delete?:ValueTypes["EventsDocAccessFields_products_startDateTime_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_products_startDateTime_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_products_startDateTime_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_products_startDateTime_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_products_startDateTime_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_products_endDateTime"]: AliasType<{
	create?:ValueTypes["EventsDocAccessFields_products_endDateTime_Create"],
	read?:ValueTypes["EventsDocAccessFields_products_endDateTime_Read"],
	update?:ValueTypes["EventsDocAccessFields_products_endDateTime_Update"],
	delete?:ValueTypes["EventsDocAccessFields_products_endDateTime_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_products_endDateTime_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_products_endDateTime_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_products_endDateTime_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_products_endDateTime_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_products_fullDay"]: AliasType<{
	create?:ValueTypes["EventsDocAccessFields_products_fullDay_Create"],
	read?:ValueTypes["EventsDocAccessFields_products_fullDay_Read"],
	update?:ValueTypes["EventsDocAccessFields_products_fullDay_Update"],
	delete?:ValueTypes["EventsDocAccessFields_products_fullDay_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_products_fullDay_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_products_fullDay_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_products_fullDay_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_products_fullDay_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_products_linkedProduct"]: AliasType<{
	create?:ValueTypes["EventsDocAccessFields_products_linkedProduct_Create"],
	read?:ValueTypes["EventsDocAccessFields_products_linkedProduct_Read"],
	update?:ValueTypes["EventsDocAccessFields_products_linkedProduct_Update"],
	delete?:ValueTypes["EventsDocAccessFields_products_linkedProduct_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_products_linkedProduct_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_products_linkedProduct_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_products_linkedProduct_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_products_linkedProduct_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_products_id"]: AliasType<{
	create?:ValueTypes["EventsDocAccessFields_products_id_Create"],
	read?:ValueTypes["EventsDocAccessFields_products_id_Read"],
	update?:ValueTypes["EventsDocAccessFields_products_id_Update"],
	delete?:ValueTypes["EventsDocAccessFields_products_id_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_products_id_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_products_id_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_products_id_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_products_id_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_legacyGallery"]: AliasType<{
	create?:ValueTypes["EventsDocAccessFields_legacyGallery_Create"],
	read?:ValueTypes["EventsDocAccessFields_legacyGallery_Read"],
	update?:ValueTypes["EventsDocAccessFields_legacyGallery_Update"],
	delete?:ValueTypes["EventsDocAccessFields_legacyGallery_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_legacyGallery_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_legacyGallery_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_legacyGallery_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_legacyGallery_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_earliestStartDate"]: AliasType<{
	create?:ValueTypes["EventsDocAccessFields_earliestStartDate_Create"],
	read?:ValueTypes["EventsDocAccessFields_earliestStartDate_Read"],
	update?:ValueTypes["EventsDocAccessFields_earliestStartDate_Update"],
	delete?:ValueTypes["EventsDocAccessFields_earliestStartDate_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_earliestStartDate_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_earliestStartDate_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_earliestStartDate_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_earliestStartDate_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_latestEndDate"]: AliasType<{
	create?:ValueTypes["EventsDocAccessFields_latestEndDate_Create"],
	read?:ValueTypes["EventsDocAccessFields_latestEndDate_Read"],
	update?:ValueTypes["EventsDocAccessFields_latestEndDate_Update"],
	delete?:ValueTypes["EventsDocAccessFields_latestEndDate_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_latestEndDate_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_latestEndDate_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_latestEndDate_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_latestEndDate_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_latestStartDateTime"]: AliasType<{
	create?:ValueTypes["EventsDocAccessFields_latestStartDateTime_Create"],
	read?:ValueTypes["EventsDocAccessFields_latestStartDateTime_Read"],
	update?:ValueTypes["EventsDocAccessFields_latestStartDateTime_Update"],
	delete?:ValueTypes["EventsDocAccessFields_latestStartDateTime_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_latestStartDateTime_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_latestStartDateTime_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_latestStartDateTime_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_latestStartDateTime_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_hasTicketing"]: AliasType<{
	create?:ValueTypes["EventsDocAccessFields_hasTicketing_Create"],
	read?:ValueTypes["EventsDocAccessFields_hasTicketing_Read"],
	update?:ValueTypes["EventsDocAccessFields_hasTicketing_Update"],
	delete?:ValueTypes["EventsDocAccessFields_hasTicketing_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_hasTicketing_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_hasTicketing_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_hasTicketing_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_hasTicketing_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_hasProducts"]: AliasType<{
	create?:ValueTypes["EventsDocAccessFields_hasProducts_Create"],
	read?:ValueTypes["EventsDocAccessFields_hasProducts_Read"],
	update?:ValueTypes["EventsDocAccessFields_hasProducts_Update"],
	delete?:ValueTypes["EventsDocAccessFields_hasProducts_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_hasProducts_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_hasProducts_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_hasProducts_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_hasProducts_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_updatedAt"]: AliasType<{
	create?:ValueTypes["EventsDocAccessFields_updatedAt_Create"],
	read?:ValueTypes["EventsDocAccessFields_updatedAt_Read"],
	update?:ValueTypes["EventsDocAccessFields_updatedAt_Update"],
	delete?:ValueTypes["EventsDocAccessFields_updatedAt_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_updatedAt_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_updatedAt_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_updatedAt_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_updatedAt_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_createdAt"]: AliasType<{
	create?:ValueTypes["EventsDocAccessFields_createdAt_Create"],
	read?:ValueTypes["EventsDocAccessFields_createdAt_Read"],
	update?:ValueTypes["EventsDocAccessFields_createdAt_Update"],
	delete?:ValueTypes["EventsDocAccessFields_createdAt_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_createdAt_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_createdAt_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_createdAt_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_createdAt_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsCreateDocAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	/** The `JSONObject` scalar type represents JSON objects as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
["JSONObject"]:unknown;
	["EventsReadDocAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsUpdateDocAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDeleteDocAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["Locations"]: AliasType<{
	docs?:ValueTypes["Location"],
	hasNextPage?:boolean | `@${string}`,
	hasPrevPage?:boolean | `@${string}`,
	limit?:boolean | `@${string}`,
	nextPage?:boolean | `@${string}`,
	offset?:boolean | `@${string}`,
	page?:boolean | `@${string}`,
	pagingCounter?:boolean | `@${string}`,
	prevPage?:boolean | `@${string}`,
	totalDocs?:boolean | `@${string}`,
	totalPages?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["Location_where"]: {
	region?: ValueTypes["Location_region_operator"] | undefined | null | Variable<any, string>,
	creator?: ValueTypes["Location_creator_operator"] | undefined | null | Variable<any, string>,
	permalink?: ValueTypes["Location_permalink_operator"] | undefined | null | Variable<any, string>,
	status?: ValueTypes["Location_status_operator"] | undefined | null | Variable<any, string>,
	level?: ValueTypes["Location_level_operator"] | undefined | null | Variable<any, string>,
	parent?: ValueTypes["Location_parent_operator"] | undefined | null | Variable<any, string>,
	name?: ValueTypes["Location_name_operator"] | undefined | null | Variable<any, string>,
	thumbnail?: ValueTypes["Location_thumbnail_operator"] | undefined | null | Variable<any, string>,
	banner?: ValueTypes["Location_banner_operator"] | undefined | null | Variable<any, string>,
	type?: ValueTypes["Location_type_operator"] | undefined | null | Variable<any, string>,
	category?: ValueTypes["Location_category_operator"] | undefined | null | Variable<any, string>,
	district?: ValueTypes["Location_district_operator"] | undefined | null | Variable<any, string>,
	coordinate?: ValueTypes["Location_coordinate_operator"] | undefined | null | Variable<any, string>,
	linkAddress?: ValueTypes["Location_linkAddress_operator"] | undefined | null | Variable<any, string>,
	address?: ValueTypes["Location_address_operator"] | undefined | null | Variable<any, string>,
	Details__code?: ValueTypes["Location_Details__code_operator"] | undefined | null | Variable<any, string>,
	Details__phone?: ValueTypes["Location_Details__phone_operator"] | undefined | null | Variable<any, string>,
	Details__page?: ValueTypes["Location_Details__page_operator"] | undefined | null | Variable<any, string>,
	Details__email?: ValueTypes["Location_Details__email_operator"] | undefined | null | Variable<any, string>,
	legacyContent?: ValueTypes["Location_legacyContent_operator"] | undefined | null | Variable<any, string>,
	showLegacyContent?: ValueTypes["Location_showLegacyContent_operator"] | undefined | null | Variable<any, string>,
	criteria__audiences?: ValueTypes["Location_criteria__audiences_operator"] | undefined | null | Variable<any, string>,
	criteria__dates?: ValueTypes["Location_criteria__dates_operator"] | undefined | null | Variable<any, string>,
	criteria__categories?: ValueTypes["Location_criteria__categories_operator"] | undefined | null | Variable<any, string>,
	criteria__tags?: ValueTypes["Location_criteria__tags_operator"] | undefined | null | Variable<any, string>,
	sections__startDatetime?: ValueTypes["Location_sections__startDatetime_operator"] | undefined | null | Variable<any, string>,
	sections__endDatetime?: ValueTypes["Location_sections__endDatetime_operator"] | undefined | null | Variable<any, string>,
	sections__fullDay?: ValueTypes["Location_sections__fullDay_operator"] | undefined | null | Variable<any, string>,
	sections__repeat?: ValueTypes["Location_sections__repeat_operator"] | undefined | null | Variable<any, string>,
	sections__recurrance__type?: ValueTypes["Location_sections__recurrance__type_operator"] | undefined | null | Variable<any, string>,
	sections__recurrance__weekday?: ValueTypes["Location_sections__recurrance__weekday_operator"] | undefined | null | Variable<any, string>,
	sections__id?: ValueTypes["Location_sections__id_operator"] | undefined | null | Variable<any, string>,
	_title?: ValueTypes["Location__title_operator"] | undefined | null | Variable<any, string>,
	legacyGallery?: ValueTypes["Location_legacyGallery_operator"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["Location_updatedAt_operator"] | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["Location_createdAt_operator"] | undefined | null | Variable<any, string>,
	id?: ValueTypes["Location_id_operator"] | undefined | null | Variable<any, string>,
	AND?: Array<ValueTypes["Location_where_and"] | undefined | null> | undefined | null | Variable<any, string>,
	OR?: Array<ValueTypes["Location_where_or"] | undefined | null> | undefined | null | Variable<any, string>
};
	["Location_region_operator"]: {
	equals?: ValueTypes["Location_region_Input"] | undefined | null | Variable<any, string>,
	not_equals?: ValueTypes["Location_region_Input"] | undefined | null | Variable<any, string>,
	in?: Array<ValueTypes["Location_region_Input"] | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<ValueTypes["Location_region_Input"] | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<ValueTypes["Location_region_Input"] | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Location_region_Input"]:Location_region_Input;
	["Location_creator_operator"]: {
	equals?: ValueTypes["JSON"] | undefined | null | Variable<any, string>,
	not_equals?: ValueTypes["JSON"] | undefined | null | Variable<any, string>,
	in?: Array<ValueTypes["JSON"] | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<ValueTypes["JSON"] | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<ValueTypes["JSON"] | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Location_permalink_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Location_status_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>
};
	["Location_level_operator"]: {
	equals?: ValueTypes["Location_level_Input"] | undefined | null | Variable<any, string>,
	not_equals?: ValueTypes["Location_level_Input"] | undefined | null | Variable<any, string>,
	in?: Array<ValueTypes["Location_level_Input"] | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<ValueTypes["Location_level_Input"] | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<ValueTypes["Location_level_Input"] | undefined | null> | undefined | null | Variable<any, string>
};
	["Location_level_Input"]:Location_level_Input;
	["Location_parent_operator"]: {
	equals?: ValueTypes["JSON"] | undefined | null | Variable<any, string>,
	not_equals?: ValueTypes["JSON"] | undefined | null | Variable<any, string>,
	in?: Array<ValueTypes["JSON"] | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<ValueTypes["JSON"] | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<ValueTypes["JSON"] | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Location_name_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>
};
	["Location_thumbnail_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Location_banner_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Location_type_operator"]: {
	equals?: ValueTypes["Location_type_Input"] | undefined | null | Variable<any, string>,
	not_equals?: ValueTypes["Location_type_Input"] | undefined | null | Variable<any, string>,
	in?: Array<ValueTypes["Location_type_Input"] | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<ValueTypes["Location_type_Input"] | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<ValueTypes["Location_type_Input"] | undefined | null> | undefined | null | Variable<any, string>
};
	["Location_type_Input"]:Location_type_Input;
	["Location_category_operator"]: {
	equals?: ValueTypes["Location_category_Input"] | undefined | null | Variable<any, string>,
	not_equals?: ValueTypes["Location_category_Input"] | undefined | null | Variable<any, string>,
	in?: Array<ValueTypes["Location_category_Input"] | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<ValueTypes["Location_category_Input"] | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<ValueTypes["Location_category_Input"] | undefined | null> | undefined | null | Variable<any, string>
};
	["Location_category_Input"]:Location_category_Input;
	["Location_district_operator"]: {
	equals?: ValueTypes["JSON"] | undefined | null | Variable<any, string>,
	not_equals?: ValueTypes["JSON"] | undefined | null | Variable<any, string>,
	in?: Array<ValueTypes["JSON"] | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<ValueTypes["JSON"] | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<ValueTypes["JSON"] | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Location_coordinate_operator"]: {
	equals?: Array<number | undefined | null> | undefined | null | Variable<any, string>,
	not_equals?: Array<number | undefined | null> | undefined | null | Variable<any, string>,
	greater_than_equal?: Array<number | undefined | null> | undefined | null | Variable<any, string>,
	greater_than?: Array<number | undefined | null> | undefined | null | Variable<any, string>,
	less_than_equal?: Array<number | undefined | null> | undefined | null | Variable<any, string>,
	less_than?: Array<number | undefined | null> | undefined | null | Variable<any, string>,
	near?: Array<number | undefined | null> | undefined | null | Variable<any, string>,
	within?: ValueTypes["GeoJSONObject"] | undefined | null | Variable<any, string>,
	intersects?: ValueTypes["GeoJSONObject"] | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Location_linkAddress_operator"]: {
	equals?: boolean | undefined | null | Variable<any, string>,
	not_equals?: boolean | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Location_address_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Location_Details__code_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Location_Details__phone_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Location_Details__page_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Location_Details__email_operator"]: {
	equals?: ValueTypes["EmailAddress"] | undefined | null | Variable<any, string>,
	not_equals?: ValueTypes["EmailAddress"] | undefined | null | Variable<any, string>,
	like?: ValueTypes["EmailAddress"] | undefined | null | Variable<any, string>,
	contains?: ValueTypes["EmailAddress"] | undefined | null | Variable<any, string>,
	in?: Array<ValueTypes["EmailAddress"] | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<ValueTypes["EmailAddress"] | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<ValueTypes["EmailAddress"] | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Location_legacyContent_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Location_showLegacyContent_operator"]: {
	equals?: boolean | undefined | null | Variable<any, string>,
	not_equals?: boolean | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Location_criteria__audiences_operator"]: {
	equals?: ValueTypes["JSON"] | undefined | null | Variable<any, string>,
	not_equals?: ValueTypes["JSON"] | undefined | null | Variable<any, string>,
	in?: Array<ValueTypes["JSON"] | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<ValueTypes["JSON"] | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<ValueTypes["JSON"] | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Location_criteria__dates_operator"]: {
	equals?: ValueTypes["JSON"] | undefined | null | Variable<any, string>,
	not_equals?: ValueTypes["JSON"] | undefined | null | Variable<any, string>,
	in?: Array<ValueTypes["JSON"] | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<ValueTypes["JSON"] | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<ValueTypes["JSON"] | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Location_criteria__categories_operator"]: {
	equals?: ValueTypes["JSON"] | undefined | null | Variable<any, string>,
	not_equals?: ValueTypes["JSON"] | undefined | null | Variable<any, string>,
	in?: Array<ValueTypes["JSON"] | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<ValueTypes["JSON"] | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<ValueTypes["JSON"] | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Location_criteria__tags_operator"]: {
	equals?: ValueTypes["JSON"] | undefined | null | Variable<any, string>,
	not_equals?: ValueTypes["JSON"] | undefined | null | Variable<any, string>,
	in?: Array<ValueTypes["JSON"] | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<ValueTypes["JSON"] | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<ValueTypes["JSON"] | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Location_sections__startDatetime_operator"]: {
	equals?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	not_equals?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	greater_than_equal?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	greater_than?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	less_than_equal?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	less_than?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	like?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>
};
	["Location_sections__endDatetime_operator"]: {
	equals?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	not_equals?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	greater_than_equal?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	greater_than?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	less_than_equal?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	less_than?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	like?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>
};
	["Location_sections__fullDay_operator"]: {
	equals?: boolean | undefined | null | Variable<any, string>,
	not_equals?: boolean | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Location_sections__repeat_operator"]: {
	equals?: boolean | undefined | null | Variable<any, string>,
	not_equals?: boolean | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Location_sections__recurrance__type_operator"]: {
	equals?: ValueTypes["Location_sections__recurrance__type_Input"] | undefined | null | Variable<any, string>,
	not_equals?: ValueTypes["Location_sections__recurrance__type_Input"] | undefined | null | Variable<any, string>,
	in?: Array<ValueTypes["Location_sections__recurrance__type_Input"] | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<ValueTypes["Location_sections__recurrance__type_Input"] | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<ValueTypes["Location_sections__recurrance__type_Input"] | undefined | null> | undefined | null | Variable<any, string>
};
	["Location_sections__recurrance__type_Input"]:Location_sections__recurrance__type_Input;
	["Location_sections__recurrance__weekday_operator"]: {
	equals?: ValueTypes["Location_sections__recurrance__weekday_Input"] | undefined | null | Variable<any, string>,
	not_equals?: ValueTypes["Location_sections__recurrance__weekday_Input"] | undefined | null | Variable<any, string>,
	in?: Array<ValueTypes["Location_sections__recurrance__weekday_Input"] | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<ValueTypes["Location_sections__recurrance__weekday_Input"] | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<ValueTypes["Location_sections__recurrance__weekday_Input"] | undefined | null> | undefined | null | Variable<any, string>
};
	["Location_sections__recurrance__weekday_Input"]:Location_sections__recurrance__weekday_Input;
	["Location_sections__id_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Location__title_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Location_legacyGallery_operator"]: {
	equals?: ValueTypes["JSON"] | undefined | null | Variable<any, string>,
	not_equals?: ValueTypes["JSON"] | undefined | null | Variable<any, string>,
	in?: Array<ValueTypes["JSON"] | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<ValueTypes["JSON"] | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<ValueTypes["JSON"] | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Location_updatedAt_operator"]: {
	equals?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	not_equals?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	greater_than_equal?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	greater_than?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	less_than_equal?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	less_than?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	like?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Location_createdAt_operator"]: {
	equals?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	not_equals?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	greater_than_equal?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	greater_than?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	less_than_equal?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	less_than?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	like?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Location_id_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Location_where_and"]: {
	region?: ValueTypes["Location_region_operator"] | undefined | null | Variable<any, string>,
	creator?: ValueTypes["Location_creator_operator"] | undefined | null | Variable<any, string>,
	permalink?: ValueTypes["Location_permalink_operator"] | undefined | null | Variable<any, string>,
	status?: ValueTypes["Location_status_operator"] | undefined | null | Variable<any, string>,
	level?: ValueTypes["Location_level_operator"] | undefined | null | Variable<any, string>,
	parent?: ValueTypes["Location_parent_operator"] | undefined | null | Variable<any, string>,
	name?: ValueTypes["Location_name_operator"] | undefined | null | Variable<any, string>,
	thumbnail?: ValueTypes["Location_thumbnail_operator"] | undefined | null | Variable<any, string>,
	banner?: ValueTypes["Location_banner_operator"] | undefined | null | Variable<any, string>,
	type?: ValueTypes["Location_type_operator"] | undefined | null | Variable<any, string>,
	category?: ValueTypes["Location_category_operator"] | undefined | null | Variable<any, string>,
	district?: ValueTypes["Location_district_operator"] | undefined | null | Variable<any, string>,
	coordinate?: ValueTypes["Location_coordinate_operator"] | undefined | null | Variable<any, string>,
	linkAddress?: ValueTypes["Location_linkAddress_operator"] | undefined | null | Variable<any, string>,
	address?: ValueTypes["Location_address_operator"] | undefined | null | Variable<any, string>,
	Details__code?: ValueTypes["Location_Details__code_operator"] | undefined | null | Variable<any, string>,
	Details__phone?: ValueTypes["Location_Details__phone_operator"] | undefined | null | Variable<any, string>,
	Details__page?: ValueTypes["Location_Details__page_operator"] | undefined | null | Variable<any, string>,
	Details__email?: ValueTypes["Location_Details__email_operator"] | undefined | null | Variable<any, string>,
	legacyContent?: ValueTypes["Location_legacyContent_operator"] | undefined | null | Variable<any, string>,
	showLegacyContent?: ValueTypes["Location_showLegacyContent_operator"] | undefined | null | Variable<any, string>,
	criteria__audiences?: ValueTypes["Location_criteria__audiences_operator"] | undefined | null | Variable<any, string>,
	criteria__dates?: ValueTypes["Location_criteria__dates_operator"] | undefined | null | Variable<any, string>,
	criteria__categories?: ValueTypes["Location_criteria__categories_operator"] | undefined | null | Variable<any, string>,
	criteria__tags?: ValueTypes["Location_criteria__tags_operator"] | undefined | null | Variable<any, string>,
	sections__startDatetime?: ValueTypes["Location_sections__startDatetime_operator"] | undefined | null | Variable<any, string>,
	sections__endDatetime?: ValueTypes["Location_sections__endDatetime_operator"] | undefined | null | Variable<any, string>,
	sections__fullDay?: ValueTypes["Location_sections__fullDay_operator"] | undefined | null | Variable<any, string>,
	sections__repeat?: ValueTypes["Location_sections__repeat_operator"] | undefined | null | Variable<any, string>,
	sections__recurrance__type?: ValueTypes["Location_sections__recurrance__type_operator"] | undefined | null | Variable<any, string>,
	sections__recurrance__weekday?: ValueTypes["Location_sections__recurrance__weekday_operator"] | undefined | null | Variable<any, string>,
	sections__id?: ValueTypes["Location_sections__id_operator"] | undefined | null | Variable<any, string>,
	_title?: ValueTypes["Location__title_operator"] | undefined | null | Variable<any, string>,
	legacyGallery?: ValueTypes["Location_legacyGallery_operator"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["Location_updatedAt_operator"] | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["Location_createdAt_operator"] | undefined | null | Variable<any, string>,
	id?: ValueTypes["Location_id_operator"] | undefined | null | Variable<any, string>,
	AND?: Array<ValueTypes["Location_where_and"] | undefined | null> | undefined | null | Variable<any, string>,
	OR?: Array<ValueTypes["Location_where_or"] | undefined | null> | undefined | null | Variable<any, string>
};
	["Location_where_or"]: {
	region?: ValueTypes["Location_region_operator"] | undefined | null | Variable<any, string>,
	creator?: ValueTypes["Location_creator_operator"] | undefined | null | Variable<any, string>,
	permalink?: ValueTypes["Location_permalink_operator"] | undefined | null | Variable<any, string>,
	status?: ValueTypes["Location_status_operator"] | undefined | null | Variable<any, string>,
	level?: ValueTypes["Location_level_operator"] | undefined | null | Variable<any, string>,
	parent?: ValueTypes["Location_parent_operator"] | undefined | null | Variable<any, string>,
	name?: ValueTypes["Location_name_operator"] | undefined | null | Variable<any, string>,
	thumbnail?: ValueTypes["Location_thumbnail_operator"] | undefined | null | Variable<any, string>,
	banner?: ValueTypes["Location_banner_operator"] | undefined | null | Variable<any, string>,
	type?: ValueTypes["Location_type_operator"] | undefined | null | Variable<any, string>,
	category?: ValueTypes["Location_category_operator"] | undefined | null | Variable<any, string>,
	district?: ValueTypes["Location_district_operator"] | undefined | null | Variable<any, string>,
	coordinate?: ValueTypes["Location_coordinate_operator"] | undefined | null | Variable<any, string>,
	linkAddress?: ValueTypes["Location_linkAddress_operator"] | undefined | null | Variable<any, string>,
	address?: ValueTypes["Location_address_operator"] | undefined | null | Variable<any, string>,
	Details__code?: ValueTypes["Location_Details__code_operator"] | undefined | null | Variable<any, string>,
	Details__phone?: ValueTypes["Location_Details__phone_operator"] | undefined | null | Variable<any, string>,
	Details__page?: ValueTypes["Location_Details__page_operator"] | undefined | null | Variable<any, string>,
	Details__email?: ValueTypes["Location_Details__email_operator"] | undefined | null | Variable<any, string>,
	legacyContent?: ValueTypes["Location_legacyContent_operator"] | undefined | null | Variable<any, string>,
	showLegacyContent?: ValueTypes["Location_showLegacyContent_operator"] | undefined | null | Variable<any, string>,
	criteria__audiences?: ValueTypes["Location_criteria__audiences_operator"] | undefined | null | Variable<any, string>,
	criteria__dates?: ValueTypes["Location_criteria__dates_operator"] | undefined | null | Variable<any, string>,
	criteria__categories?: ValueTypes["Location_criteria__categories_operator"] | undefined | null | Variable<any, string>,
	criteria__tags?: ValueTypes["Location_criteria__tags_operator"] | undefined | null | Variable<any, string>,
	sections__startDatetime?: ValueTypes["Location_sections__startDatetime_operator"] | undefined | null | Variable<any, string>,
	sections__endDatetime?: ValueTypes["Location_sections__endDatetime_operator"] | undefined | null | Variable<any, string>,
	sections__fullDay?: ValueTypes["Location_sections__fullDay_operator"] | undefined | null | Variable<any, string>,
	sections__repeat?: ValueTypes["Location_sections__repeat_operator"] | undefined | null | Variable<any, string>,
	sections__recurrance__type?: ValueTypes["Location_sections__recurrance__type_operator"] | undefined | null | Variable<any, string>,
	sections__recurrance__weekday?: ValueTypes["Location_sections__recurrance__weekday_operator"] | undefined | null | Variable<any, string>,
	sections__id?: ValueTypes["Location_sections__id_operator"] | undefined | null | Variable<any, string>,
	_title?: ValueTypes["Location__title_operator"] | undefined | null | Variable<any, string>,
	legacyGallery?: ValueTypes["Location_legacyGallery_operator"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["Location_updatedAt_operator"] | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["Location_createdAt_operator"] | undefined | null | Variable<any, string>,
	id?: ValueTypes["Location_id_operator"] | undefined | null | Variable<any, string>,
	AND?: Array<ValueTypes["Location_where_and"] | undefined | null> | undefined | null | Variable<any, string>,
	OR?: Array<ValueTypes["Location_where_or"] | undefined | null> | undefined | null | Variable<any, string>
};
	["countLocations"]: AliasType<{
	totalDocs?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["locationsDocAccess"]: AliasType<{
	fields?:ValueTypes["LocationsDocAccessFields"],
	create?:ValueTypes["LocationsCreateDocAccess"],
	read?:ValueTypes["LocationsReadDocAccess"],
	update?:ValueTypes["LocationsUpdateDocAccess"],
	delete?:ValueTypes["LocationsDeleteDocAccess"],
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields"]: AliasType<{
	region?:ValueTypes["LocationsDocAccessFields_region"],
	creator?:ValueTypes["LocationsDocAccessFields_creator"],
	permalink?:ValueTypes["LocationsDocAccessFields_permalink"],
	status?:ValueTypes["LocationsDocAccessFields_status"],
	level?:ValueTypes["LocationsDocAccessFields_level"],
	parent?:ValueTypes["LocationsDocAccessFields_parent"],
	name?:ValueTypes["LocationsDocAccessFields_name"],
	thumbnail?:ValueTypes["LocationsDocAccessFields_thumbnail"],
	banner?:ValueTypes["LocationsDocAccessFields_banner"],
	type?:ValueTypes["LocationsDocAccessFields_type"],
	category?:ValueTypes["LocationsDocAccessFields_category"],
	district?:ValueTypes["LocationsDocAccessFields_district"],
	coordinate?:ValueTypes["LocationsDocAccessFields_coordinate"],
	linkAddress?:ValueTypes["LocationsDocAccessFields_linkAddress"],
	address?:ValueTypes["LocationsDocAccessFields_address"],
	Details?:ValueTypes["LocationsDocAccessFields_Details"],
	content?:ValueTypes["LocationsDocAccessFields_content"],
	legacyContent?:ValueTypes["LocationsDocAccessFields_legacyContent"],
	showLegacyContent?:ValueTypes["LocationsDocAccessFields_showLegacyContent"],
	criteria?:ValueTypes["LocationsDocAccessFields_criteria"],
	sections?:ValueTypes["LocationsDocAccessFields_sections"],
	_title?:ValueTypes["LocationsDocAccessFields__title"],
	legacyGallery?:ValueTypes["LocationsDocAccessFields_legacyGallery"],
	updatedAt?:ValueTypes["LocationsDocAccessFields_updatedAt"],
	createdAt?:ValueTypes["LocationsDocAccessFields_createdAt"],
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields_region"]: AliasType<{
	create?:ValueTypes["LocationsDocAccessFields_region_Create"],
	read?:ValueTypes["LocationsDocAccessFields_region_Read"],
	update?:ValueTypes["LocationsDocAccessFields_region_Update"],
	delete?:ValueTypes["LocationsDocAccessFields_region_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields_region_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields_region_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields_region_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields_region_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields_creator"]: AliasType<{
	create?:ValueTypes["LocationsDocAccessFields_creator_Create"],
	read?:ValueTypes["LocationsDocAccessFields_creator_Read"],
	update?:ValueTypes["LocationsDocAccessFields_creator_Update"],
	delete?:ValueTypes["LocationsDocAccessFields_creator_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields_creator_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields_creator_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields_creator_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields_creator_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields_permalink"]: AliasType<{
	create?:ValueTypes["LocationsDocAccessFields_permalink_Create"],
	read?:ValueTypes["LocationsDocAccessFields_permalink_Read"],
	update?:ValueTypes["LocationsDocAccessFields_permalink_Update"],
	delete?:ValueTypes["LocationsDocAccessFields_permalink_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields_permalink_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields_permalink_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields_permalink_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields_permalink_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields_status"]: AliasType<{
	create?:ValueTypes["LocationsDocAccessFields_status_Create"],
	read?:ValueTypes["LocationsDocAccessFields_status_Read"],
	update?:ValueTypes["LocationsDocAccessFields_status_Update"],
	delete?:ValueTypes["LocationsDocAccessFields_status_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields_status_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields_status_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields_status_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields_status_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields_level"]: AliasType<{
	create?:ValueTypes["LocationsDocAccessFields_level_Create"],
	read?:ValueTypes["LocationsDocAccessFields_level_Read"],
	update?:ValueTypes["LocationsDocAccessFields_level_Update"],
	delete?:ValueTypes["LocationsDocAccessFields_level_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields_level_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields_level_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields_level_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields_level_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields_parent"]: AliasType<{
	create?:ValueTypes["LocationsDocAccessFields_parent_Create"],
	read?:ValueTypes["LocationsDocAccessFields_parent_Read"],
	update?:ValueTypes["LocationsDocAccessFields_parent_Update"],
	delete?:ValueTypes["LocationsDocAccessFields_parent_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields_parent_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields_parent_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields_parent_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields_parent_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields_name"]: AliasType<{
	create?:ValueTypes["LocationsDocAccessFields_name_Create"],
	read?:ValueTypes["LocationsDocAccessFields_name_Read"],
	update?:ValueTypes["LocationsDocAccessFields_name_Update"],
	delete?:ValueTypes["LocationsDocAccessFields_name_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields_name_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields_name_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields_name_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields_name_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields_thumbnail"]: AliasType<{
	create?:ValueTypes["LocationsDocAccessFields_thumbnail_Create"],
	read?:ValueTypes["LocationsDocAccessFields_thumbnail_Read"],
	update?:ValueTypes["LocationsDocAccessFields_thumbnail_Update"],
	delete?:ValueTypes["LocationsDocAccessFields_thumbnail_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields_thumbnail_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields_thumbnail_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields_thumbnail_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields_thumbnail_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields_banner"]: AliasType<{
	create?:ValueTypes["LocationsDocAccessFields_banner_Create"],
	read?:ValueTypes["LocationsDocAccessFields_banner_Read"],
	update?:ValueTypes["LocationsDocAccessFields_banner_Update"],
	delete?:ValueTypes["LocationsDocAccessFields_banner_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields_banner_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields_banner_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields_banner_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields_banner_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields_type"]: AliasType<{
	create?:ValueTypes["LocationsDocAccessFields_type_Create"],
	read?:ValueTypes["LocationsDocAccessFields_type_Read"],
	update?:ValueTypes["LocationsDocAccessFields_type_Update"],
	delete?:ValueTypes["LocationsDocAccessFields_type_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields_type_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields_type_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields_type_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields_type_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields_category"]: AliasType<{
	create?:ValueTypes["LocationsDocAccessFields_category_Create"],
	read?:ValueTypes["LocationsDocAccessFields_category_Read"],
	update?:ValueTypes["LocationsDocAccessFields_category_Update"],
	delete?:ValueTypes["LocationsDocAccessFields_category_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields_category_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields_category_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields_category_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields_category_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields_district"]: AliasType<{
	create?:ValueTypes["LocationsDocAccessFields_district_Create"],
	read?:ValueTypes["LocationsDocAccessFields_district_Read"],
	update?:ValueTypes["LocationsDocAccessFields_district_Update"],
	delete?:ValueTypes["LocationsDocAccessFields_district_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields_district_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields_district_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields_district_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields_district_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields_coordinate"]: AliasType<{
	create?:ValueTypes["LocationsDocAccessFields_coordinate_Create"],
	read?:ValueTypes["LocationsDocAccessFields_coordinate_Read"],
	update?:ValueTypes["LocationsDocAccessFields_coordinate_Update"],
	delete?:ValueTypes["LocationsDocAccessFields_coordinate_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields_coordinate_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields_coordinate_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields_coordinate_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields_coordinate_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields_linkAddress"]: AliasType<{
	create?:ValueTypes["LocationsDocAccessFields_linkAddress_Create"],
	read?:ValueTypes["LocationsDocAccessFields_linkAddress_Read"],
	update?:ValueTypes["LocationsDocAccessFields_linkAddress_Update"],
	delete?:ValueTypes["LocationsDocAccessFields_linkAddress_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields_linkAddress_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields_linkAddress_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields_linkAddress_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields_linkAddress_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields_address"]: AliasType<{
	create?:ValueTypes["LocationsDocAccessFields_address_Create"],
	read?:ValueTypes["LocationsDocAccessFields_address_Read"],
	update?:ValueTypes["LocationsDocAccessFields_address_Update"],
	delete?:ValueTypes["LocationsDocAccessFields_address_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields_address_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields_address_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields_address_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields_address_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields_Details"]: AliasType<{
	create?:ValueTypes["LocationsDocAccessFields_Details_Create"],
	read?:ValueTypes["LocationsDocAccessFields_Details_Read"],
	update?:ValueTypes["LocationsDocAccessFields_Details_Update"],
	delete?:ValueTypes["LocationsDocAccessFields_Details_Delete"],
	fields?:ValueTypes["LocationsDocAccessFields_Details_Fields"],
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields_Details_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields_Details_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields_Details_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields_Details_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields_Details_Fields"]: AliasType<{
	code?:ValueTypes["LocationsDocAccessFields_Details_code"],
	phone?:ValueTypes["LocationsDocAccessFields_Details_phone"],
	page?:ValueTypes["LocationsDocAccessFields_Details_page"],
	email?:ValueTypes["LocationsDocAccessFields_Details_email"],
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields_Details_code"]: AliasType<{
	create?:ValueTypes["LocationsDocAccessFields_Details_code_Create"],
	read?:ValueTypes["LocationsDocAccessFields_Details_code_Read"],
	update?:ValueTypes["LocationsDocAccessFields_Details_code_Update"],
	delete?:ValueTypes["LocationsDocAccessFields_Details_code_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields_Details_code_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields_Details_code_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields_Details_code_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields_Details_code_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields_Details_phone"]: AliasType<{
	create?:ValueTypes["LocationsDocAccessFields_Details_phone_Create"],
	read?:ValueTypes["LocationsDocAccessFields_Details_phone_Read"],
	update?:ValueTypes["LocationsDocAccessFields_Details_phone_Update"],
	delete?:ValueTypes["LocationsDocAccessFields_Details_phone_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields_Details_phone_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields_Details_phone_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields_Details_phone_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields_Details_phone_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields_Details_page"]: AliasType<{
	create?:ValueTypes["LocationsDocAccessFields_Details_page_Create"],
	read?:ValueTypes["LocationsDocAccessFields_Details_page_Read"],
	update?:ValueTypes["LocationsDocAccessFields_Details_page_Update"],
	delete?:ValueTypes["LocationsDocAccessFields_Details_page_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields_Details_page_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields_Details_page_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields_Details_page_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields_Details_page_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields_Details_email"]: AliasType<{
	create?:ValueTypes["LocationsDocAccessFields_Details_email_Create"],
	read?:ValueTypes["LocationsDocAccessFields_Details_email_Read"],
	update?:ValueTypes["LocationsDocAccessFields_Details_email_Update"],
	delete?:ValueTypes["LocationsDocAccessFields_Details_email_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields_Details_email_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields_Details_email_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields_Details_email_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields_Details_email_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields_content"]: AliasType<{
	create?:ValueTypes["LocationsDocAccessFields_content_Create"],
	read?:ValueTypes["LocationsDocAccessFields_content_Read"],
	update?:ValueTypes["LocationsDocAccessFields_content_Update"],
	delete?:ValueTypes["LocationsDocAccessFields_content_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields_content_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields_content_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields_content_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields_content_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields_legacyContent"]: AliasType<{
	create?:ValueTypes["LocationsDocAccessFields_legacyContent_Create"],
	read?:ValueTypes["LocationsDocAccessFields_legacyContent_Read"],
	update?:ValueTypes["LocationsDocAccessFields_legacyContent_Update"],
	delete?:ValueTypes["LocationsDocAccessFields_legacyContent_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields_legacyContent_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields_legacyContent_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields_legacyContent_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields_legacyContent_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields_showLegacyContent"]: AliasType<{
	create?:ValueTypes["LocationsDocAccessFields_showLegacyContent_Create"],
	read?:ValueTypes["LocationsDocAccessFields_showLegacyContent_Read"],
	update?:ValueTypes["LocationsDocAccessFields_showLegacyContent_Update"],
	delete?:ValueTypes["LocationsDocAccessFields_showLegacyContent_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields_showLegacyContent_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields_showLegacyContent_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields_showLegacyContent_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields_showLegacyContent_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields_criteria"]: AliasType<{
	create?:ValueTypes["LocationsDocAccessFields_criteria_Create"],
	read?:ValueTypes["LocationsDocAccessFields_criteria_Read"],
	update?:ValueTypes["LocationsDocAccessFields_criteria_Update"],
	delete?:ValueTypes["LocationsDocAccessFields_criteria_Delete"],
	fields?:ValueTypes["LocationsDocAccessFields_criteria_Fields"],
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields_criteria_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields_criteria_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields_criteria_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields_criteria_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields_criteria_Fields"]: AliasType<{
	audiences?:ValueTypes["LocationsDocAccessFields_criteria_audiences"],
	dates?:ValueTypes["LocationsDocAccessFields_criteria_dates"],
	categories?:ValueTypes["LocationsDocAccessFields_criteria_categories"],
	tags?:ValueTypes["LocationsDocAccessFields_criteria_tags"],
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields_criteria_audiences"]: AliasType<{
	create?:ValueTypes["LocationsDocAccessFields_criteria_audiences_Create"],
	read?:ValueTypes["LocationsDocAccessFields_criteria_audiences_Read"],
	update?:ValueTypes["LocationsDocAccessFields_criteria_audiences_Update"],
	delete?:ValueTypes["LocationsDocAccessFields_criteria_audiences_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields_criteria_audiences_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields_criteria_audiences_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields_criteria_audiences_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields_criteria_audiences_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields_criteria_dates"]: AliasType<{
	create?:ValueTypes["LocationsDocAccessFields_criteria_dates_Create"],
	read?:ValueTypes["LocationsDocAccessFields_criteria_dates_Read"],
	update?:ValueTypes["LocationsDocAccessFields_criteria_dates_Update"],
	delete?:ValueTypes["LocationsDocAccessFields_criteria_dates_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields_criteria_dates_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields_criteria_dates_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields_criteria_dates_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields_criteria_dates_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields_criteria_categories"]: AliasType<{
	create?:ValueTypes["LocationsDocAccessFields_criteria_categories_Create"],
	read?:ValueTypes["LocationsDocAccessFields_criteria_categories_Read"],
	update?:ValueTypes["LocationsDocAccessFields_criteria_categories_Update"],
	delete?:ValueTypes["LocationsDocAccessFields_criteria_categories_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields_criteria_categories_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields_criteria_categories_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields_criteria_categories_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields_criteria_categories_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields_criteria_tags"]: AliasType<{
	create?:ValueTypes["LocationsDocAccessFields_criteria_tags_Create"],
	read?:ValueTypes["LocationsDocAccessFields_criteria_tags_Read"],
	update?:ValueTypes["LocationsDocAccessFields_criteria_tags_Update"],
	delete?:ValueTypes["LocationsDocAccessFields_criteria_tags_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields_criteria_tags_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields_criteria_tags_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields_criteria_tags_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields_criteria_tags_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields_sections"]: AliasType<{
	create?:ValueTypes["LocationsDocAccessFields_sections_Create"],
	read?:ValueTypes["LocationsDocAccessFields_sections_Read"],
	update?:ValueTypes["LocationsDocAccessFields_sections_Update"],
	delete?:ValueTypes["LocationsDocAccessFields_sections_Delete"],
	fields?:ValueTypes["LocationsDocAccessFields_sections_Fields"],
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields_sections_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields_sections_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields_sections_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields_sections_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields_sections_Fields"]: AliasType<{
	startDatetime?:ValueTypes["LocationsDocAccessFields_sections_startDatetime"],
	endDatetime?:ValueTypes["LocationsDocAccessFields_sections_endDatetime"],
	fullDay?:ValueTypes["LocationsDocAccessFields_sections_fullDay"],
	repeat?:ValueTypes["LocationsDocAccessFields_sections_repeat"],
	recurrance?:ValueTypes["LocationsDocAccessFields_sections_recurrance"],
	id?:ValueTypes["LocationsDocAccessFields_sections_id"],
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields_sections_startDatetime"]: AliasType<{
	create?:ValueTypes["LocationsDocAccessFields_sections_startDatetime_Create"],
	read?:ValueTypes["LocationsDocAccessFields_sections_startDatetime_Read"],
	update?:ValueTypes["LocationsDocAccessFields_sections_startDatetime_Update"],
	delete?:ValueTypes["LocationsDocAccessFields_sections_startDatetime_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields_sections_startDatetime_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields_sections_startDatetime_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields_sections_startDatetime_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields_sections_startDatetime_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields_sections_endDatetime"]: AliasType<{
	create?:ValueTypes["LocationsDocAccessFields_sections_endDatetime_Create"],
	read?:ValueTypes["LocationsDocAccessFields_sections_endDatetime_Read"],
	update?:ValueTypes["LocationsDocAccessFields_sections_endDatetime_Update"],
	delete?:ValueTypes["LocationsDocAccessFields_sections_endDatetime_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields_sections_endDatetime_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields_sections_endDatetime_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields_sections_endDatetime_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields_sections_endDatetime_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields_sections_fullDay"]: AliasType<{
	create?:ValueTypes["LocationsDocAccessFields_sections_fullDay_Create"],
	read?:ValueTypes["LocationsDocAccessFields_sections_fullDay_Read"],
	update?:ValueTypes["LocationsDocAccessFields_sections_fullDay_Update"],
	delete?:ValueTypes["LocationsDocAccessFields_sections_fullDay_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields_sections_fullDay_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields_sections_fullDay_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields_sections_fullDay_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields_sections_fullDay_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields_sections_repeat"]: AliasType<{
	create?:ValueTypes["LocationsDocAccessFields_sections_repeat_Create"],
	read?:ValueTypes["LocationsDocAccessFields_sections_repeat_Read"],
	update?:ValueTypes["LocationsDocAccessFields_sections_repeat_Update"],
	delete?:ValueTypes["LocationsDocAccessFields_sections_repeat_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields_sections_repeat_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields_sections_repeat_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields_sections_repeat_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields_sections_repeat_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields_sections_recurrance"]: AliasType<{
	create?:ValueTypes["LocationsDocAccessFields_sections_recurrance_Create"],
	read?:ValueTypes["LocationsDocAccessFields_sections_recurrance_Read"],
	update?:ValueTypes["LocationsDocAccessFields_sections_recurrance_Update"],
	delete?:ValueTypes["LocationsDocAccessFields_sections_recurrance_Delete"],
	fields?:ValueTypes["LocationsDocAccessFields_sections_recurrance_Fields"],
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields_sections_recurrance_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields_sections_recurrance_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields_sections_recurrance_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields_sections_recurrance_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields_sections_recurrance_Fields"]: AliasType<{
	type?:ValueTypes["LocationsDocAccessFields_sections_recurrance_type"],
	weekday?:ValueTypes["LocationsDocAccessFields_sections_recurrance_weekday"],
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields_sections_recurrance_type"]: AliasType<{
	create?:ValueTypes["LocationsDocAccessFields_sections_recurrance_type_Create"],
	read?:ValueTypes["LocationsDocAccessFields_sections_recurrance_type_Read"],
	update?:ValueTypes["LocationsDocAccessFields_sections_recurrance_type_Update"],
	delete?:ValueTypes["LocationsDocAccessFields_sections_recurrance_type_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields_sections_recurrance_type_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields_sections_recurrance_type_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields_sections_recurrance_type_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields_sections_recurrance_type_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields_sections_recurrance_weekday"]: AliasType<{
	create?:ValueTypes["LocationsDocAccessFields_sections_recurrance_weekday_Create"],
	read?:ValueTypes["LocationsDocAccessFields_sections_recurrance_weekday_Read"],
	update?:ValueTypes["LocationsDocAccessFields_sections_recurrance_weekday_Update"],
	delete?:ValueTypes["LocationsDocAccessFields_sections_recurrance_weekday_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields_sections_recurrance_weekday_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields_sections_recurrance_weekday_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields_sections_recurrance_weekday_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields_sections_recurrance_weekday_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields_sections_id"]: AliasType<{
	create?:ValueTypes["LocationsDocAccessFields_sections_id_Create"],
	read?:ValueTypes["LocationsDocAccessFields_sections_id_Read"],
	update?:ValueTypes["LocationsDocAccessFields_sections_id_Update"],
	delete?:ValueTypes["LocationsDocAccessFields_sections_id_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields_sections_id_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields_sections_id_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields_sections_id_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields_sections_id_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields__title"]: AliasType<{
	create?:ValueTypes["LocationsDocAccessFields__title_Create"],
	read?:ValueTypes["LocationsDocAccessFields__title_Read"],
	update?:ValueTypes["LocationsDocAccessFields__title_Update"],
	delete?:ValueTypes["LocationsDocAccessFields__title_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields__title_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields__title_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields__title_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields__title_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields_legacyGallery"]: AliasType<{
	create?:ValueTypes["LocationsDocAccessFields_legacyGallery_Create"],
	read?:ValueTypes["LocationsDocAccessFields_legacyGallery_Read"],
	update?:ValueTypes["LocationsDocAccessFields_legacyGallery_Update"],
	delete?:ValueTypes["LocationsDocAccessFields_legacyGallery_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields_legacyGallery_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields_legacyGallery_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields_legacyGallery_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields_legacyGallery_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields_updatedAt"]: AliasType<{
	create?:ValueTypes["LocationsDocAccessFields_updatedAt_Create"],
	read?:ValueTypes["LocationsDocAccessFields_updatedAt_Read"],
	update?:ValueTypes["LocationsDocAccessFields_updatedAt_Update"],
	delete?:ValueTypes["LocationsDocAccessFields_updatedAt_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields_updatedAt_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields_updatedAt_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields_updatedAt_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields_updatedAt_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields_createdAt"]: AliasType<{
	create?:ValueTypes["LocationsDocAccessFields_createdAt_Create"],
	read?:ValueTypes["LocationsDocAccessFields_createdAt_Read"],
	update?:ValueTypes["LocationsDocAccessFields_createdAt_Update"],
	delete?:ValueTypes["LocationsDocAccessFields_createdAt_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields_createdAt_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields_createdAt_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields_createdAt_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields_createdAt_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsCreateDocAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsReadDocAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsUpdateDocAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsDeleteDocAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["Post"]: AliasType<{
	id?:boolean | `@${string}`,
	title?:boolean | `@${string}`,
	region?:boolean | `@${string}`,
authur?: [{	locale?: ValueTypes["LocaleInputType"] | undefined | null | Variable<any, string>,	fallbackLocale?: ValueTypes["FallbackLocaleInputType"] | undefined | null | Variable<any, string>},ValueTypes["User"]],
	publishDateTime?:boolean | `@${string}`,
	permalink?:boolean | `@${string}`,
	status?:boolean | `@${string}`,
	content?:ValueTypes["Post_Content"],
	legacyContent?:boolean | `@${string}`,
	showLegacyContent?:boolean | `@${string}`,
blogCategories?: [{	locale?: ValueTypes["LocaleInputType"] | undefined | null | Variable<any, string>,	fallbackLocale?: ValueTypes["FallbackLocaleInputType"] | undefined | null | Variable<any, string>},ValueTypes["BlogCategory"]],
thumbnail?: [{	locale?: ValueTypes["LocaleInputType"] | undefined | null | Variable<any, string>,	fallbackLocale?: ValueTypes["FallbackLocaleInputType"] | undefined | null | Variable<any, string>,	where?: ValueTypes["Post_Thumbnail_where"] | undefined | null | Variable<any, string>},ValueTypes["Media"]],
banner?: [{	locale?: ValueTypes["LocaleInputType"] | undefined | null | Variable<any, string>,	fallbackLocale?: ValueTypes["FallbackLocaleInputType"] | undefined | null | Variable<any, string>,	where?: ValueTypes["Post_Banner_where"] | undefined | null | Variable<any, string>},ValueTypes["Media"]],
	criteria?:ValueTypes["Post_Criteria"],
location?: [{	locale?: ValueTypes["LocaleInputType"] | undefined | null | Variable<any, string>,	fallbackLocale?: ValueTypes["FallbackLocaleInputType"] | undefined | null | Variable<any, string>},ValueTypes["Location"]],
	views?:boolean | `@${string}`,
	homePageInternalClick?:boolean | `@${string}`,
	homePageImpression?:boolean | `@${string}`,
	blogListInternalClick?:boolean | `@${string}`,
	blogListImpression?:boolean | `@${string}`,
	blogDetailInternalClick?:boolean | `@${string}`,
	blogDetailImpression?:boolean | `@${string}`,
	relatedEventInternalClick?:boolean | `@${string}`,
	relatedEventImpression?:boolean | `@${string}`,
	latestEventInternalClick?:boolean | `@${string}`,
	latestEventImpression?:boolean | `@${string}`,
	internalClick?:boolean | `@${string}`,
	totalImpression?:boolean | `@${string}`,
	externalClick?:boolean | `@${string}`,
	outboundClick?:boolean | `@${string}`,
legacyGallery?: [{	locale?: ValueTypes["LocaleInputType"] | undefined | null | Variable<any, string>,	fallbackLocale?: ValueTypes["FallbackLocaleInputType"] | undefined | null | Variable<any, string>},ValueTypes["Media"]],
	updatedAt?:boolean | `@${string}`,
	createdAt?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["Post_region"]:Post_region;
	["Post_Content"]: AliasType<{		["...on RichText"]?: ValueTypes["RichText"],
		["...on Gallery"]?: ValueTypes["Gallery"],
		["...on Image"]?: ValueTypes["Image"],
		["...on Video"]?: ValueTypes["Video"]
		__typename?: boolean | `@${string}`
}>;
	["BlogCategory"]: AliasType<{
	id?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	value?:boolean | `@${string}`,
icon?: [{	locale?: ValueTypes["LocaleInputType"] | undefined | null | Variable<any, string>,	fallbackLocale?: ValueTypes["FallbackLocaleInputType"] | undefined | null | Variable<any, string>,	where?: ValueTypes["BlogCategory_Icon_where"] | undefined | null | Variable<any, string>},ValueTypes["Media"]],
	region?:boolean | `@${string}`,
	updatedAt?:boolean | `@${string}`,
	createdAt?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BlogCategory_Icon_where"]: {
	alt?: ValueTypes["BlogCategory_Icon_alt_operator"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["BlogCategory_Icon_updatedAt_operator"] | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["BlogCategory_Icon_createdAt_operator"] | undefined | null | Variable<any, string>,
	url?: ValueTypes["BlogCategory_Icon_url_operator"] | undefined | null | Variable<any, string>,
	filename?: ValueTypes["BlogCategory_Icon_filename_operator"] | undefined | null | Variable<any, string>,
	mimeType?: ValueTypes["BlogCategory_Icon_mimeType_operator"] | undefined | null | Variable<any, string>,
	filesize?: ValueTypes["BlogCategory_Icon_filesize_operator"] | undefined | null | Variable<any, string>,
	width?: ValueTypes["BlogCategory_Icon_width_operator"] | undefined | null | Variable<any, string>,
	height?: ValueTypes["BlogCategory_Icon_height_operator"] | undefined | null | Variable<any, string>,
	focalX?: ValueTypes["BlogCategory_Icon_focalX_operator"] | undefined | null | Variable<any, string>,
	focalY?: ValueTypes["BlogCategory_Icon_focalY_operator"] | undefined | null | Variable<any, string>,
	sizes__small__url?: ValueTypes["BlogCategory_Icon_sizes__small__url_operator"] | undefined | null | Variable<any, string>,
	sizes__small__width?: ValueTypes["BlogCategory_Icon_sizes__small__width_operator"] | undefined | null | Variable<any, string>,
	sizes__small__height?: ValueTypes["BlogCategory_Icon_sizes__small__height_operator"] | undefined | null | Variable<any, string>,
	sizes__small__mimeType?: ValueTypes["BlogCategory_Icon_sizes__small__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__small__filesize?: ValueTypes["BlogCategory_Icon_sizes__small__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__small__filename?: ValueTypes["BlogCategory_Icon_sizes__small__filename_operator"] | undefined | null | Variable<any, string>,
	sizes__medium__url?: ValueTypes["BlogCategory_Icon_sizes__medium__url_operator"] | undefined | null | Variable<any, string>,
	sizes__medium__width?: ValueTypes["BlogCategory_Icon_sizes__medium__width_operator"] | undefined | null | Variable<any, string>,
	sizes__medium__height?: ValueTypes["BlogCategory_Icon_sizes__medium__height_operator"] | undefined | null | Variable<any, string>,
	sizes__medium__mimeType?: ValueTypes["BlogCategory_Icon_sizes__medium__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__medium__filesize?: ValueTypes["BlogCategory_Icon_sizes__medium__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__medium__filename?: ValueTypes["BlogCategory_Icon_sizes__medium__filename_operator"] | undefined | null | Variable<any, string>,
	sizes__large__url?: ValueTypes["BlogCategory_Icon_sizes__large__url_operator"] | undefined | null | Variable<any, string>,
	sizes__large__width?: ValueTypes["BlogCategory_Icon_sizes__large__width_operator"] | undefined | null | Variable<any, string>,
	sizes__large__height?: ValueTypes["BlogCategory_Icon_sizes__large__height_operator"] | undefined | null | Variable<any, string>,
	sizes__large__mimeType?: ValueTypes["BlogCategory_Icon_sizes__large__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__large__filesize?: ValueTypes["BlogCategory_Icon_sizes__large__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__large__filename?: ValueTypes["BlogCategory_Icon_sizes__large__filename_operator"] | undefined | null | Variable<any, string>,
	sizes__small_jpeg__url?: ValueTypes["BlogCategory_Icon_sizes__small_jpeg__url_operator"] | undefined | null | Variable<any, string>,
	sizes__small_jpeg__width?: ValueTypes["BlogCategory_Icon_sizes__small_jpeg__width_operator"] | undefined | null | Variable<any, string>,
	sizes__small_jpeg__height?: ValueTypes["BlogCategory_Icon_sizes__small_jpeg__height_operator"] | undefined | null | Variable<any, string>,
	sizes__small_jpeg__mimeType?: ValueTypes["BlogCategory_Icon_sizes__small_jpeg__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__small_jpeg__filesize?: ValueTypes["BlogCategory_Icon_sizes__small_jpeg__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__small_jpeg__filename?: ValueTypes["BlogCategory_Icon_sizes__small_jpeg__filename_operator"] | undefined | null | Variable<any, string>,
	sizes__medium_jpeg__url?: ValueTypes["BlogCategory_Icon_sizes__medium_jpeg__url_operator"] | undefined | null | Variable<any, string>,
	sizes__medium_jpeg__width?: ValueTypes["BlogCategory_Icon_sizes__medium_jpeg__width_operator"] | undefined | null | Variable<any, string>,
	sizes__medium_jpeg__height?: ValueTypes["BlogCategory_Icon_sizes__medium_jpeg__height_operator"] | undefined | null | Variable<any, string>,
	sizes__medium_jpeg__mimeType?: ValueTypes["BlogCategory_Icon_sizes__medium_jpeg__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__medium_jpeg__filesize?: ValueTypes["BlogCategory_Icon_sizes__medium_jpeg__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__medium_jpeg__filename?: ValueTypes["BlogCategory_Icon_sizes__medium_jpeg__filename_operator"] | undefined | null | Variable<any, string>,
	sizes__large_jpeg__url?: ValueTypes["BlogCategory_Icon_sizes__large_jpeg__url_operator"] | undefined | null | Variable<any, string>,
	sizes__large_jpeg__width?: ValueTypes["BlogCategory_Icon_sizes__large_jpeg__width_operator"] | undefined | null | Variable<any, string>,
	sizes__large_jpeg__height?: ValueTypes["BlogCategory_Icon_sizes__large_jpeg__height_operator"] | undefined | null | Variable<any, string>,
	sizes__large_jpeg__mimeType?: ValueTypes["BlogCategory_Icon_sizes__large_jpeg__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__large_jpeg__filesize?: ValueTypes["BlogCategory_Icon_sizes__large_jpeg__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__large_jpeg__filename?: ValueTypes["BlogCategory_Icon_sizes__large_jpeg__filename_operator"] | undefined | null | Variable<any, string>,
	id?: ValueTypes["BlogCategory_Icon_id_operator"] | undefined | null | Variable<any, string>,
	AND?: Array<ValueTypes["BlogCategory_Icon_where_and"] | undefined | null> | undefined | null | Variable<any, string>,
	OR?: Array<ValueTypes["BlogCategory_Icon_where_or"] | undefined | null> | undefined | null | Variable<any, string>
};
	["BlogCategory_Icon_alt_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["BlogCategory_Icon_updatedAt_operator"]: {
	equals?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	not_equals?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	greater_than_equal?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	greater_than?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	less_than_equal?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	less_than?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	like?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["BlogCategory_Icon_createdAt_operator"]: {
	equals?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	not_equals?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	greater_than_equal?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	greater_than?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	less_than_equal?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	less_than?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	like?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["BlogCategory_Icon_url_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["BlogCategory_Icon_filename_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["BlogCategory_Icon_mimeType_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["BlogCategory_Icon_filesize_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["BlogCategory_Icon_width_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["BlogCategory_Icon_height_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["BlogCategory_Icon_focalX_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["BlogCategory_Icon_focalY_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["BlogCategory_Icon_sizes__small__url_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["BlogCategory_Icon_sizes__small__width_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["BlogCategory_Icon_sizes__small__height_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["BlogCategory_Icon_sizes__small__mimeType_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["BlogCategory_Icon_sizes__small__filesize_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["BlogCategory_Icon_sizes__small__filename_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["BlogCategory_Icon_sizes__medium__url_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["BlogCategory_Icon_sizes__medium__width_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["BlogCategory_Icon_sizes__medium__height_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["BlogCategory_Icon_sizes__medium__mimeType_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["BlogCategory_Icon_sizes__medium__filesize_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["BlogCategory_Icon_sizes__medium__filename_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["BlogCategory_Icon_sizes__large__url_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["BlogCategory_Icon_sizes__large__width_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["BlogCategory_Icon_sizes__large__height_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["BlogCategory_Icon_sizes__large__mimeType_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["BlogCategory_Icon_sizes__large__filesize_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["BlogCategory_Icon_sizes__large__filename_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["BlogCategory_Icon_sizes__small_jpeg__url_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["BlogCategory_Icon_sizes__small_jpeg__width_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["BlogCategory_Icon_sizes__small_jpeg__height_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["BlogCategory_Icon_sizes__small_jpeg__mimeType_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["BlogCategory_Icon_sizes__small_jpeg__filesize_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["BlogCategory_Icon_sizes__small_jpeg__filename_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["BlogCategory_Icon_sizes__medium_jpeg__url_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["BlogCategory_Icon_sizes__medium_jpeg__width_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["BlogCategory_Icon_sizes__medium_jpeg__height_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["BlogCategory_Icon_sizes__medium_jpeg__mimeType_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["BlogCategory_Icon_sizes__medium_jpeg__filesize_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["BlogCategory_Icon_sizes__medium_jpeg__filename_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["BlogCategory_Icon_sizes__large_jpeg__url_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["BlogCategory_Icon_sizes__large_jpeg__width_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["BlogCategory_Icon_sizes__large_jpeg__height_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["BlogCategory_Icon_sizes__large_jpeg__mimeType_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["BlogCategory_Icon_sizes__large_jpeg__filesize_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["BlogCategory_Icon_sizes__large_jpeg__filename_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["BlogCategory_Icon_id_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["BlogCategory_Icon_where_and"]: {
	alt?: ValueTypes["BlogCategory_Icon_alt_operator"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["BlogCategory_Icon_updatedAt_operator"] | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["BlogCategory_Icon_createdAt_operator"] | undefined | null | Variable<any, string>,
	url?: ValueTypes["BlogCategory_Icon_url_operator"] | undefined | null | Variable<any, string>,
	filename?: ValueTypes["BlogCategory_Icon_filename_operator"] | undefined | null | Variable<any, string>,
	mimeType?: ValueTypes["BlogCategory_Icon_mimeType_operator"] | undefined | null | Variable<any, string>,
	filesize?: ValueTypes["BlogCategory_Icon_filesize_operator"] | undefined | null | Variable<any, string>,
	width?: ValueTypes["BlogCategory_Icon_width_operator"] | undefined | null | Variable<any, string>,
	height?: ValueTypes["BlogCategory_Icon_height_operator"] | undefined | null | Variable<any, string>,
	focalX?: ValueTypes["BlogCategory_Icon_focalX_operator"] | undefined | null | Variable<any, string>,
	focalY?: ValueTypes["BlogCategory_Icon_focalY_operator"] | undefined | null | Variable<any, string>,
	sizes__small__url?: ValueTypes["BlogCategory_Icon_sizes__small__url_operator"] | undefined | null | Variable<any, string>,
	sizes__small__width?: ValueTypes["BlogCategory_Icon_sizes__small__width_operator"] | undefined | null | Variable<any, string>,
	sizes__small__height?: ValueTypes["BlogCategory_Icon_sizes__small__height_operator"] | undefined | null | Variable<any, string>,
	sizes__small__mimeType?: ValueTypes["BlogCategory_Icon_sizes__small__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__small__filesize?: ValueTypes["BlogCategory_Icon_sizes__small__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__small__filename?: ValueTypes["BlogCategory_Icon_sizes__small__filename_operator"] | undefined | null | Variable<any, string>,
	sizes__medium__url?: ValueTypes["BlogCategory_Icon_sizes__medium__url_operator"] | undefined | null | Variable<any, string>,
	sizes__medium__width?: ValueTypes["BlogCategory_Icon_sizes__medium__width_operator"] | undefined | null | Variable<any, string>,
	sizes__medium__height?: ValueTypes["BlogCategory_Icon_sizes__medium__height_operator"] | undefined | null | Variable<any, string>,
	sizes__medium__mimeType?: ValueTypes["BlogCategory_Icon_sizes__medium__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__medium__filesize?: ValueTypes["BlogCategory_Icon_sizes__medium__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__medium__filename?: ValueTypes["BlogCategory_Icon_sizes__medium__filename_operator"] | undefined | null | Variable<any, string>,
	sizes__large__url?: ValueTypes["BlogCategory_Icon_sizes__large__url_operator"] | undefined | null | Variable<any, string>,
	sizes__large__width?: ValueTypes["BlogCategory_Icon_sizes__large__width_operator"] | undefined | null | Variable<any, string>,
	sizes__large__height?: ValueTypes["BlogCategory_Icon_sizes__large__height_operator"] | undefined | null | Variable<any, string>,
	sizes__large__mimeType?: ValueTypes["BlogCategory_Icon_sizes__large__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__large__filesize?: ValueTypes["BlogCategory_Icon_sizes__large__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__large__filename?: ValueTypes["BlogCategory_Icon_sizes__large__filename_operator"] | undefined | null | Variable<any, string>,
	sizes__small_jpeg__url?: ValueTypes["BlogCategory_Icon_sizes__small_jpeg__url_operator"] | undefined | null | Variable<any, string>,
	sizes__small_jpeg__width?: ValueTypes["BlogCategory_Icon_sizes__small_jpeg__width_operator"] | undefined | null | Variable<any, string>,
	sizes__small_jpeg__height?: ValueTypes["BlogCategory_Icon_sizes__small_jpeg__height_operator"] | undefined | null | Variable<any, string>,
	sizes__small_jpeg__mimeType?: ValueTypes["BlogCategory_Icon_sizes__small_jpeg__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__small_jpeg__filesize?: ValueTypes["BlogCategory_Icon_sizes__small_jpeg__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__small_jpeg__filename?: ValueTypes["BlogCategory_Icon_sizes__small_jpeg__filename_operator"] | undefined | null | Variable<any, string>,
	sizes__medium_jpeg__url?: ValueTypes["BlogCategory_Icon_sizes__medium_jpeg__url_operator"] | undefined | null | Variable<any, string>,
	sizes__medium_jpeg__width?: ValueTypes["BlogCategory_Icon_sizes__medium_jpeg__width_operator"] | undefined | null | Variable<any, string>,
	sizes__medium_jpeg__height?: ValueTypes["BlogCategory_Icon_sizes__medium_jpeg__height_operator"] | undefined | null | Variable<any, string>,
	sizes__medium_jpeg__mimeType?: ValueTypes["BlogCategory_Icon_sizes__medium_jpeg__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__medium_jpeg__filesize?: ValueTypes["BlogCategory_Icon_sizes__medium_jpeg__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__medium_jpeg__filename?: ValueTypes["BlogCategory_Icon_sizes__medium_jpeg__filename_operator"] | undefined | null | Variable<any, string>,
	sizes__large_jpeg__url?: ValueTypes["BlogCategory_Icon_sizes__large_jpeg__url_operator"] | undefined | null | Variable<any, string>,
	sizes__large_jpeg__width?: ValueTypes["BlogCategory_Icon_sizes__large_jpeg__width_operator"] | undefined | null | Variable<any, string>,
	sizes__large_jpeg__height?: ValueTypes["BlogCategory_Icon_sizes__large_jpeg__height_operator"] | undefined | null | Variable<any, string>,
	sizes__large_jpeg__mimeType?: ValueTypes["BlogCategory_Icon_sizes__large_jpeg__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__large_jpeg__filesize?: ValueTypes["BlogCategory_Icon_sizes__large_jpeg__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__large_jpeg__filename?: ValueTypes["BlogCategory_Icon_sizes__large_jpeg__filename_operator"] | undefined | null | Variable<any, string>,
	id?: ValueTypes["BlogCategory_Icon_id_operator"] | undefined | null | Variable<any, string>,
	AND?: Array<ValueTypes["BlogCategory_Icon_where_and"] | undefined | null> | undefined | null | Variable<any, string>,
	OR?: Array<ValueTypes["BlogCategory_Icon_where_or"] | undefined | null> | undefined | null | Variable<any, string>
};
	["BlogCategory_Icon_where_or"]: {
	alt?: ValueTypes["BlogCategory_Icon_alt_operator"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["BlogCategory_Icon_updatedAt_operator"] | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["BlogCategory_Icon_createdAt_operator"] | undefined | null | Variable<any, string>,
	url?: ValueTypes["BlogCategory_Icon_url_operator"] | undefined | null | Variable<any, string>,
	filename?: ValueTypes["BlogCategory_Icon_filename_operator"] | undefined | null | Variable<any, string>,
	mimeType?: ValueTypes["BlogCategory_Icon_mimeType_operator"] | undefined | null | Variable<any, string>,
	filesize?: ValueTypes["BlogCategory_Icon_filesize_operator"] | undefined | null | Variable<any, string>,
	width?: ValueTypes["BlogCategory_Icon_width_operator"] | undefined | null | Variable<any, string>,
	height?: ValueTypes["BlogCategory_Icon_height_operator"] | undefined | null | Variable<any, string>,
	focalX?: ValueTypes["BlogCategory_Icon_focalX_operator"] | undefined | null | Variable<any, string>,
	focalY?: ValueTypes["BlogCategory_Icon_focalY_operator"] | undefined | null | Variable<any, string>,
	sizes__small__url?: ValueTypes["BlogCategory_Icon_sizes__small__url_operator"] | undefined | null | Variable<any, string>,
	sizes__small__width?: ValueTypes["BlogCategory_Icon_sizes__small__width_operator"] | undefined | null | Variable<any, string>,
	sizes__small__height?: ValueTypes["BlogCategory_Icon_sizes__small__height_operator"] | undefined | null | Variable<any, string>,
	sizes__small__mimeType?: ValueTypes["BlogCategory_Icon_sizes__small__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__small__filesize?: ValueTypes["BlogCategory_Icon_sizes__small__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__small__filename?: ValueTypes["BlogCategory_Icon_sizes__small__filename_operator"] | undefined | null | Variable<any, string>,
	sizes__medium__url?: ValueTypes["BlogCategory_Icon_sizes__medium__url_operator"] | undefined | null | Variable<any, string>,
	sizes__medium__width?: ValueTypes["BlogCategory_Icon_sizes__medium__width_operator"] | undefined | null | Variable<any, string>,
	sizes__medium__height?: ValueTypes["BlogCategory_Icon_sizes__medium__height_operator"] | undefined | null | Variable<any, string>,
	sizes__medium__mimeType?: ValueTypes["BlogCategory_Icon_sizes__medium__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__medium__filesize?: ValueTypes["BlogCategory_Icon_sizes__medium__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__medium__filename?: ValueTypes["BlogCategory_Icon_sizes__medium__filename_operator"] | undefined | null | Variable<any, string>,
	sizes__large__url?: ValueTypes["BlogCategory_Icon_sizes__large__url_operator"] | undefined | null | Variable<any, string>,
	sizes__large__width?: ValueTypes["BlogCategory_Icon_sizes__large__width_operator"] | undefined | null | Variable<any, string>,
	sizes__large__height?: ValueTypes["BlogCategory_Icon_sizes__large__height_operator"] | undefined | null | Variable<any, string>,
	sizes__large__mimeType?: ValueTypes["BlogCategory_Icon_sizes__large__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__large__filesize?: ValueTypes["BlogCategory_Icon_sizes__large__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__large__filename?: ValueTypes["BlogCategory_Icon_sizes__large__filename_operator"] | undefined | null | Variable<any, string>,
	sizes__small_jpeg__url?: ValueTypes["BlogCategory_Icon_sizes__small_jpeg__url_operator"] | undefined | null | Variable<any, string>,
	sizes__small_jpeg__width?: ValueTypes["BlogCategory_Icon_sizes__small_jpeg__width_operator"] | undefined | null | Variable<any, string>,
	sizes__small_jpeg__height?: ValueTypes["BlogCategory_Icon_sizes__small_jpeg__height_operator"] | undefined | null | Variable<any, string>,
	sizes__small_jpeg__mimeType?: ValueTypes["BlogCategory_Icon_sizes__small_jpeg__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__small_jpeg__filesize?: ValueTypes["BlogCategory_Icon_sizes__small_jpeg__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__small_jpeg__filename?: ValueTypes["BlogCategory_Icon_sizes__small_jpeg__filename_operator"] | undefined | null | Variable<any, string>,
	sizes__medium_jpeg__url?: ValueTypes["BlogCategory_Icon_sizes__medium_jpeg__url_operator"] | undefined | null | Variable<any, string>,
	sizes__medium_jpeg__width?: ValueTypes["BlogCategory_Icon_sizes__medium_jpeg__width_operator"] | undefined | null | Variable<any, string>,
	sizes__medium_jpeg__height?: ValueTypes["BlogCategory_Icon_sizes__medium_jpeg__height_operator"] | undefined | null | Variable<any, string>,
	sizes__medium_jpeg__mimeType?: ValueTypes["BlogCategory_Icon_sizes__medium_jpeg__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__medium_jpeg__filesize?: ValueTypes["BlogCategory_Icon_sizes__medium_jpeg__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__medium_jpeg__filename?: ValueTypes["BlogCategory_Icon_sizes__medium_jpeg__filename_operator"] | undefined | null | Variable<any, string>,
	sizes__large_jpeg__url?: ValueTypes["BlogCategory_Icon_sizes__large_jpeg__url_operator"] | undefined | null | Variable<any, string>,
	sizes__large_jpeg__width?: ValueTypes["BlogCategory_Icon_sizes__large_jpeg__width_operator"] | undefined | null | Variable<any, string>,
	sizes__large_jpeg__height?: ValueTypes["BlogCategory_Icon_sizes__large_jpeg__height_operator"] | undefined | null | Variable<any, string>,
	sizes__large_jpeg__mimeType?: ValueTypes["BlogCategory_Icon_sizes__large_jpeg__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__large_jpeg__filesize?: ValueTypes["BlogCategory_Icon_sizes__large_jpeg__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__large_jpeg__filename?: ValueTypes["BlogCategory_Icon_sizes__large_jpeg__filename_operator"] | undefined | null | Variable<any, string>,
	id?: ValueTypes["BlogCategory_Icon_id_operator"] | undefined | null | Variable<any, string>,
	AND?: Array<ValueTypes["BlogCategory_Icon_where_and"] | undefined | null> | undefined | null | Variable<any, string>,
	OR?: Array<ValueTypes["BlogCategory_Icon_where_or"] | undefined | null> | undefined | null | Variable<any, string>
};
	["BlogCategory_region"]:BlogCategory_region;
	["Post_Thumbnail_where"]: {
	alt?: ValueTypes["Post_Thumbnail_alt_operator"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["Post_Thumbnail_updatedAt_operator"] | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["Post_Thumbnail_createdAt_operator"] | undefined | null | Variable<any, string>,
	url?: ValueTypes["Post_Thumbnail_url_operator"] | undefined | null | Variable<any, string>,
	filename?: ValueTypes["Post_Thumbnail_filename_operator"] | undefined | null | Variable<any, string>,
	mimeType?: ValueTypes["Post_Thumbnail_mimeType_operator"] | undefined | null | Variable<any, string>,
	filesize?: ValueTypes["Post_Thumbnail_filesize_operator"] | undefined | null | Variable<any, string>,
	width?: ValueTypes["Post_Thumbnail_width_operator"] | undefined | null | Variable<any, string>,
	height?: ValueTypes["Post_Thumbnail_height_operator"] | undefined | null | Variable<any, string>,
	focalX?: ValueTypes["Post_Thumbnail_focalX_operator"] | undefined | null | Variable<any, string>,
	focalY?: ValueTypes["Post_Thumbnail_focalY_operator"] | undefined | null | Variable<any, string>,
	sizes__small__url?: ValueTypes["Post_Thumbnail_sizes__small__url_operator"] | undefined | null | Variable<any, string>,
	sizes__small__width?: ValueTypes["Post_Thumbnail_sizes__small__width_operator"] | undefined | null | Variable<any, string>,
	sizes__small__height?: ValueTypes["Post_Thumbnail_sizes__small__height_operator"] | undefined | null | Variable<any, string>,
	sizes__small__mimeType?: ValueTypes["Post_Thumbnail_sizes__small__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__small__filesize?: ValueTypes["Post_Thumbnail_sizes__small__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__small__filename?: ValueTypes["Post_Thumbnail_sizes__small__filename_operator"] | undefined | null | Variable<any, string>,
	sizes__medium__url?: ValueTypes["Post_Thumbnail_sizes__medium__url_operator"] | undefined | null | Variable<any, string>,
	sizes__medium__width?: ValueTypes["Post_Thumbnail_sizes__medium__width_operator"] | undefined | null | Variable<any, string>,
	sizes__medium__height?: ValueTypes["Post_Thumbnail_sizes__medium__height_operator"] | undefined | null | Variable<any, string>,
	sizes__medium__mimeType?: ValueTypes["Post_Thumbnail_sizes__medium__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__medium__filesize?: ValueTypes["Post_Thumbnail_sizes__medium__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__medium__filename?: ValueTypes["Post_Thumbnail_sizes__medium__filename_operator"] | undefined | null | Variable<any, string>,
	sizes__large__url?: ValueTypes["Post_Thumbnail_sizes__large__url_operator"] | undefined | null | Variable<any, string>,
	sizes__large__width?: ValueTypes["Post_Thumbnail_sizes__large__width_operator"] | undefined | null | Variable<any, string>,
	sizes__large__height?: ValueTypes["Post_Thumbnail_sizes__large__height_operator"] | undefined | null | Variable<any, string>,
	sizes__large__mimeType?: ValueTypes["Post_Thumbnail_sizes__large__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__large__filesize?: ValueTypes["Post_Thumbnail_sizes__large__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__large__filename?: ValueTypes["Post_Thumbnail_sizes__large__filename_operator"] | undefined | null | Variable<any, string>,
	sizes__small_jpeg__url?: ValueTypes["Post_Thumbnail_sizes__small_jpeg__url_operator"] | undefined | null | Variable<any, string>,
	sizes__small_jpeg__width?: ValueTypes["Post_Thumbnail_sizes__small_jpeg__width_operator"] | undefined | null | Variable<any, string>,
	sizes__small_jpeg__height?: ValueTypes["Post_Thumbnail_sizes__small_jpeg__height_operator"] | undefined | null | Variable<any, string>,
	sizes__small_jpeg__mimeType?: ValueTypes["Post_Thumbnail_sizes__small_jpeg__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__small_jpeg__filesize?: ValueTypes["Post_Thumbnail_sizes__small_jpeg__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__small_jpeg__filename?: ValueTypes["Post_Thumbnail_sizes__small_jpeg__filename_operator"] | undefined | null | Variable<any, string>,
	sizes__medium_jpeg__url?: ValueTypes["Post_Thumbnail_sizes__medium_jpeg__url_operator"] | undefined | null | Variable<any, string>,
	sizes__medium_jpeg__width?: ValueTypes["Post_Thumbnail_sizes__medium_jpeg__width_operator"] | undefined | null | Variable<any, string>,
	sizes__medium_jpeg__height?: ValueTypes["Post_Thumbnail_sizes__medium_jpeg__height_operator"] | undefined | null | Variable<any, string>,
	sizes__medium_jpeg__mimeType?: ValueTypes["Post_Thumbnail_sizes__medium_jpeg__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__medium_jpeg__filesize?: ValueTypes["Post_Thumbnail_sizes__medium_jpeg__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__medium_jpeg__filename?: ValueTypes["Post_Thumbnail_sizes__medium_jpeg__filename_operator"] | undefined | null | Variable<any, string>,
	sizes__large_jpeg__url?: ValueTypes["Post_Thumbnail_sizes__large_jpeg__url_operator"] | undefined | null | Variable<any, string>,
	sizes__large_jpeg__width?: ValueTypes["Post_Thumbnail_sizes__large_jpeg__width_operator"] | undefined | null | Variable<any, string>,
	sizes__large_jpeg__height?: ValueTypes["Post_Thumbnail_sizes__large_jpeg__height_operator"] | undefined | null | Variable<any, string>,
	sizes__large_jpeg__mimeType?: ValueTypes["Post_Thumbnail_sizes__large_jpeg__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__large_jpeg__filesize?: ValueTypes["Post_Thumbnail_sizes__large_jpeg__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__large_jpeg__filename?: ValueTypes["Post_Thumbnail_sizes__large_jpeg__filename_operator"] | undefined | null | Variable<any, string>,
	id?: ValueTypes["Post_Thumbnail_id_operator"] | undefined | null | Variable<any, string>,
	AND?: Array<ValueTypes["Post_Thumbnail_where_and"] | undefined | null> | undefined | null | Variable<any, string>,
	OR?: Array<ValueTypes["Post_Thumbnail_where_or"] | undefined | null> | undefined | null | Variable<any, string>
};
	["Post_Thumbnail_alt_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Post_Thumbnail_updatedAt_operator"]: {
	equals?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	not_equals?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	greater_than_equal?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	greater_than?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	less_than_equal?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	less_than?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	like?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Post_Thumbnail_createdAt_operator"]: {
	equals?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	not_equals?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	greater_than_equal?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	greater_than?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	less_than_equal?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	less_than?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	like?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Post_Thumbnail_url_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Post_Thumbnail_filename_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Post_Thumbnail_mimeType_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Post_Thumbnail_filesize_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Post_Thumbnail_width_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Post_Thumbnail_height_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Post_Thumbnail_focalX_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Post_Thumbnail_focalY_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Post_Thumbnail_sizes__small__url_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Post_Thumbnail_sizes__small__width_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Post_Thumbnail_sizes__small__height_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Post_Thumbnail_sizes__small__mimeType_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Post_Thumbnail_sizes__small__filesize_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Post_Thumbnail_sizes__small__filename_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Post_Thumbnail_sizes__medium__url_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Post_Thumbnail_sizes__medium__width_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Post_Thumbnail_sizes__medium__height_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Post_Thumbnail_sizes__medium__mimeType_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Post_Thumbnail_sizes__medium__filesize_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Post_Thumbnail_sizes__medium__filename_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Post_Thumbnail_sizes__large__url_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Post_Thumbnail_sizes__large__width_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Post_Thumbnail_sizes__large__height_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Post_Thumbnail_sizes__large__mimeType_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Post_Thumbnail_sizes__large__filesize_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Post_Thumbnail_sizes__large__filename_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Post_Thumbnail_sizes__small_jpeg__url_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Post_Thumbnail_sizes__small_jpeg__width_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Post_Thumbnail_sizes__small_jpeg__height_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Post_Thumbnail_sizes__small_jpeg__mimeType_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Post_Thumbnail_sizes__small_jpeg__filesize_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Post_Thumbnail_sizes__small_jpeg__filename_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Post_Thumbnail_sizes__medium_jpeg__url_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Post_Thumbnail_sizes__medium_jpeg__width_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Post_Thumbnail_sizes__medium_jpeg__height_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Post_Thumbnail_sizes__medium_jpeg__mimeType_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Post_Thumbnail_sizes__medium_jpeg__filesize_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Post_Thumbnail_sizes__medium_jpeg__filename_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Post_Thumbnail_sizes__large_jpeg__url_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Post_Thumbnail_sizes__large_jpeg__width_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Post_Thumbnail_sizes__large_jpeg__height_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Post_Thumbnail_sizes__large_jpeg__mimeType_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Post_Thumbnail_sizes__large_jpeg__filesize_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Post_Thumbnail_sizes__large_jpeg__filename_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Post_Thumbnail_id_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Post_Thumbnail_where_and"]: {
	alt?: ValueTypes["Post_Thumbnail_alt_operator"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["Post_Thumbnail_updatedAt_operator"] | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["Post_Thumbnail_createdAt_operator"] | undefined | null | Variable<any, string>,
	url?: ValueTypes["Post_Thumbnail_url_operator"] | undefined | null | Variable<any, string>,
	filename?: ValueTypes["Post_Thumbnail_filename_operator"] | undefined | null | Variable<any, string>,
	mimeType?: ValueTypes["Post_Thumbnail_mimeType_operator"] | undefined | null | Variable<any, string>,
	filesize?: ValueTypes["Post_Thumbnail_filesize_operator"] | undefined | null | Variable<any, string>,
	width?: ValueTypes["Post_Thumbnail_width_operator"] | undefined | null | Variable<any, string>,
	height?: ValueTypes["Post_Thumbnail_height_operator"] | undefined | null | Variable<any, string>,
	focalX?: ValueTypes["Post_Thumbnail_focalX_operator"] | undefined | null | Variable<any, string>,
	focalY?: ValueTypes["Post_Thumbnail_focalY_operator"] | undefined | null | Variable<any, string>,
	sizes__small__url?: ValueTypes["Post_Thumbnail_sizes__small__url_operator"] | undefined | null | Variable<any, string>,
	sizes__small__width?: ValueTypes["Post_Thumbnail_sizes__small__width_operator"] | undefined | null | Variable<any, string>,
	sizes__small__height?: ValueTypes["Post_Thumbnail_sizes__small__height_operator"] | undefined | null | Variable<any, string>,
	sizes__small__mimeType?: ValueTypes["Post_Thumbnail_sizes__small__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__small__filesize?: ValueTypes["Post_Thumbnail_sizes__small__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__small__filename?: ValueTypes["Post_Thumbnail_sizes__small__filename_operator"] | undefined | null | Variable<any, string>,
	sizes__medium__url?: ValueTypes["Post_Thumbnail_sizes__medium__url_operator"] | undefined | null | Variable<any, string>,
	sizes__medium__width?: ValueTypes["Post_Thumbnail_sizes__medium__width_operator"] | undefined | null | Variable<any, string>,
	sizes__medium__height?: ValueTypes["Post_Thumbnail_sizes__medium__height_operator"] | undefined | null | Variable<any, string>,
	sizes__medium__mimeType?: ValueTypes["Post_Thumbnail_sizes__medium__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__medium__filesize?: ValueTypes["Post_Thumbnail_sizes__medium__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__medium__filename?: ValueTypes["Post_Thumbnail_sizes__medium__filename_operator"] | undefined | null | Variable<any, string>,
	sizes__large__url?: ValueTypes["Post_Thumbnail_sizes__large__url_operator"] | undefined | null | Variable<any, string>,
	sizes__large__width?: ValueTypes["Post_Thumbnail_sizes__large__width_operator"] | undefined | null | Variable<any, string>,
	sizes__large__height?: ValueTypes["Post_Thumbnail_sizes__large__height_operator"] | undefined | null | Variable<any, string>,
	sizes__large__mimeType?: ValueTypes["Post_Thumbnail_sizes__large__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__large__filesize?: ValueTypes["Post_Thumbnail_sizes__large__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__large__filename?: ValueTypes["Post_Thumbnail_sizes__large__filename_operator"] | undefined | null | Variable<any, string>,
	sizes__small_jpeg__url?: ValueTypes["Post_Thumbnail_sizes__small_jpeg__url_operator"] | undefined | null | Variable<any, string>,
	sizes__small_jpeg__width?: ValueTypes["Post_Thumbnail_sizes__small_jpeg__width_operator"] | undefined | null | Variable<any, string>,
	sizes__small_jpeg__height?: ValueTypes["Post_Thumbnail_sizes__small_jpeg__height_operator"] | undefined | null | Variable<any, string>,
	sizes__small_jpeg__mimeType?: ValueTypes["Post_Thumbnail_sizes__small_jpeg__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__small_jpeg__filesize?: ValueTypes["Post_Thumbnail_sizes__small_jpeg__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__small_jpeg__filename?: ValueTypes["Post_Thumbnail_sizes__small_jpeg__filename_operator"] | undefined | null | Variable<any, string>,
	sizes__medium_jpeg__url?: ValueTypes["Post_Thumbnail_sizes__medium_jpeg__url_operator"] | undefined | null | Variable<any, string>,
	sizes__medium_jpeg__width?: ValueTypes["Post_Thumbnail_sizes__medium_jpeg__width_operator"] | undefined | null | Variable<any, string>,
	sizes__medium_jpeg__height?: ValueTypes["Post_Thumbnail_sizes__medium_jpeg__height_operator"] | undefined | null | Variable<any, string>,
	sizes__medium_jpeg__mimeType?: ValueTypes["Post_Thumbnail_sizes__medium_jpeg__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__medium_jpeg__filesize?: ValueTypes["Post_Thumbnail_sizes__medium_jpeg__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__medium_jpeg__filename?: ValueTypes["Post_Thumbnail_sizes__medium_jpeg__filename_operator"] | undefined | null | Variable<any, string>,
	sizes__large_jpeg__url?: ValueTypes["Post_Thumbnail_sizes__large_jpeg__url_operator"] | undefined | null | Variable<any, string>,
	sizes__large_jpeg__width?: ValueTypes["Post_Thumbnail_sizes__large_jpeg__width_operator"] | undefined | null | Variable<any, string>,
	sizes__large_jpeg__height?: ValueTypes["Post_Thumbnail_sizes__large_jpeg__height_operator"] | undefined | null | Variable<any, string>,
	sizes__large_jpeg__mimeType?: ValueTypes["Post_Thumbnail_sizes__large_jpeg__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__large_jpeg__filesize?: ValueTypes["Post_Thumbnail_sizes__large_jpeg__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__large_jpeg__filename?: ValueTypes["Post_Thumbnail_sizes__large_jpeg__filename_operator"] | undefined | null | Variable<any, string>,
	id?: ValueTypes["Post_Thumbnail_id_operator"] | undefined | null | Variable<any, string>,
	AND?: Array<ValueTypes["Post_Thumbnail_where_and"] | undefined | null> | undefined | null | Variable<any, string>,
	OR?: Array<ValueTypes["Post_Thumbnail_where_or"] | undefined | null> | undefined | null | Variable<any, string>
};
	["Post_Thumbnail_where_or"]: {
	alt?: ValueTypes["Post_Thumbnail_alt_operator"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["Post_Thumbnail_updatedAt_operator"] | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["Post_Thumbnail_createdAt_operator"] | undefined | null | Variable<any, string>,
	url?: ValueTypes["Post_Thumbnail_url_operator"] | undefined | null | Variable<any, string>,
	filename?: ValueTypes["Post_Thumbnail_filename_operator"] | undefined | null | Variable<any, string>,
	mimeType?: ValueTypes["Post_Thumbnail_mimeType_operator"] | undefined | null | Variable<any, string>,
	filesize?: ValueTypes["Post_Thumbnail_filesize_operator"] | undefined | null | Variable<any, string>,
	width?: ValueTypes["Post_Thumbnail_width_operator"] | undefined | null | Variable<any, string>,
	height?: ValueTypes["Post_Thumbnail_height_operator"] | undefined | null | Variable<any, string>,
	focalX?: ValueTypes["Post_Thumbnail_focalX_operator"] | undefined | null | Variable<any, string>,
	focalY?: ValueTypes["Post_Thumbnail_focalY_operator"] | undefined | null | Variable<any, string>,
	sizes__small__url?: ValueTypes["Post_Thumbnail_sizes__small__url_operator"] | undefined | null | Variable<any, string>,
	sizes__small__width?: ValueTypes["Post_Thumbnail_sizes__small__width_operator"] | undefined | null | Variable<any, string>,
	sizes__small__height?: ValueTypes["Post_Thumbnail_sizes__small__height_operator"] | undefined | null | Variable<any, string>,
	sizes__small__mimeType?: ValueTypes["Post_Thumbnail_sizes__small__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__small__filesize?: ValueTypes["Post_Thumbnail_sizes__small__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__small__filename?: ValueTypes["Post_Thumbnail_sizes__small__filename_operator"] | undefined | null | Variable<any, string>,
	sizes__medium__url?: ValueTypes["Post_Thumbnail_sizes__medium__url_operator"] | undefined | null | Variable<any, string>,
	sizes__medium__width?: ValueTypes["Post_Thumbnail_sizes__medium__width_operator"] | undefined | null | Variable<any, string>,
	sizes__medium__height?: ValueTypes["Post_Thumbnail_sizes__medium__height_operator"] | undefined | null | Variable<any, string>,
	sizes__medium__mimeType?: ValueTypes["Post_Thumbnail_sizes__medium__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__medium__filesize?: ValueTypes["Post_Thumbnail_sizes__medium__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__medium__filename?: ValueTypes["Post_Thumbnail_sizes__medium__filename_operator"] | undefined | null | Variable<any, string>,
	sizes__large__url?: ValueTypes["Post_Thumbnail_sizes__large__url_operator"] | undefined | null | Variable<any, string>,
	sizes__large__width?: ValueTypes["Post_Thumbnail_sizes__large__width_operator"] | undefined | null | Variable<any, string>,
	sizes__large__height?: ValueTypes["Post_Thumbnail_sizes__large__height_operator"] | undefined | null | Variable<any, string>,
	sizes__large__mimeType?: ValueTypes["Post_Thumbnail_sizes__large__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__large__filesize?: ValueTypes["Post_Thumbnail_sizes__large__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__large__filename?: ValueTypes["Post_Thumbnail_sizes__large__filename_operator"] | undefined | null | Variable<any, string>,
	sizes__small_jpeg__url?: ValueTypes["Post_Thumbnail_sizes__small_jpeg__url_operator"] | undefined | null | Variable<any, string>,
	sizes__small_jpeg__width?: ValueTypes["Post_Thumbnail_sizes__small_jpeg__width_operator"] | undefined | null | Variable<any, string>,
	sizes__small_jpeg__height?: ValueTypes["Post_Thumbnail_sizes__small_jpeg__height_operator"] | undefined | null | Variable<any, string>,
	sizes__small_jpeg__mimeType?: ValueTypes["Post_Thumbnail_sizes__small_jpeg__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__small_jpeg__filesize?: ValueTypes["Post_Thumbnail_sizes__small_jpeg__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__small_jpeg__filename?: ValueTypes["Post_Thumbnail_sizes__small_jpeg__filename_operator"] | undefined | null | Variable<any, string>,
	sizes__medium_jpeg__url?: ValueTypes["Post_Thumbnail_sizes__medium_jpeg__url_operator"] | undefined | null | Variable<any, string>,
	sizes__medium_jpeg__width?: ValueTypes["Post_Thumbnail_sizes__medium_jpeg__width_operator"] | undefined | null | Variable<any, string>,
	sizes__medium_jpeg__height?: ValueTypes["Post_Thumbnail_sizes__medium_jpeg__height_operator"] | undefined | null | Variable<any, string>,
	sizes__medium_jpeg__mimeType?: ValueTypes["Post_Thumbnail_sizes__medium_jpeg__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__medium_jpeg__filesize?: ValueTypes["Post_Thumbnail_sizes__medium_jpeg__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__medium_jpeg__filename?: ValueTypes["Post_Thumbnail_sizes__medium_jpeg__filename_operator"] | undefined | null | Variable<any, string>,
	sizes__large_jpeg__url?: ValueTypes["Post_Thumbnail_sizes__large_jpeg__url_operator"] | undefined | null | Variable<any, string>,
	sizes__large_jpeg__width?: ValueTypes["Post_Thumbnail_sizes__large_jpeg__width_operator"] | undefined | null | Variable<any, string>,
	sizes__large_jpeg__height?: ValueTypes["Post_Thumbnail_sizes__large_jpeg__height_operator"] | undefined | null | Variable<any, string>,
	sizes__large_jpeg__mimeType?: ValueTypes["Post_Thumbnail_sizes__large_jpeg__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__large_jpeg__filesize?: ValueTypes["Post_Thumbnail_sizes__large_jpeg__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__large_jpeg__filename?: ValueTypes["Post_Thumbnail_sizes__large_jpeg__filename_operator"] | undefined | null | Variable<any, string>,
	id?: ValueTypes["Post_Thumbnail_id_operator"] | undefined | null | Variable<any, string>,
	AND?: Array<ValueTypes["Post_Thumbnail_where_and"] | undefined | null> | undefined | null | Variable<any, string>,
	OR?: Array<ValueTypes["Post_Thumbnail_where_or"] | undefined | null> | undefined | null | Variable<any, string>
};
	["Post_Banner_where"]: {
	alt?: ValueTypes["Post_Banner_alt_operator"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["Post_Banner_updatedAt_operator"] | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["Post_Banner_createdAt_operator"] | undefined | null | Variable<any, string>,
	url?: ValueTypes["Post_Banner_url_operator"] | undefined | null | Variable<any, string>,
	filename?: ValueTypes["Post_Banner_filename_operator"] | undefined | null | Variable<any, string>,
	mimeType?: ValueTypes["Post_Banner_mimeType_operator"] | undefined | null | Variable<any, string>,
	filesize?: ValueTypes["Post_Banner_filesize_operator"] | undefined | null | Variable<any, string>,
	width?: ValueTypes["Post_Banner_width_operator"] | undefined | null | Variable<any, string>,
	height?: ValueTypes["Post_Banner_height_operator"] | undefined | null | Variable<any, string>,
	focalX?: ValueTypes["Post_Banner_focalX_operator"] | undefined | null | Variable<any, string>,
	focalY?: ValueTypes["Post_Banner_focalY_operator"] | undefined | null | Variable<any, string>,
	sizes__small__url?: ValueTypes["Post_Banner_sizes__small__url_operator"] | undefined | null | Variable<any, string>,
	sizes__small__width?: ValueTypes["Post_Banner_sizes__small__width_operator"] | undefined | null | Variable<any, string>,
	sizes__small__height?: ValueTypes["Post_Banner_sizes__small__height_operator"] | undefined | null | Variable<any, string>,
	sizes__small__mimeType?: ValueTypes["Post_Banner_sizes__small__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__small__filesize?: ValueTypes["Post_Banner_sizes__small__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__small__filename?: ValueTypes["Post_Banner_sizes__small__filename_operator"] | undefined | null | Variable<any, string>,
	sizes__medium__url?: ValueTypes["Post_Banner_sizes__medium__url_operator"] | undefined | null | Variable<any, string>,
	sizes__medium__width?: ValueTypes["Post_Banner_sizes__medium__width_operator"] | undefined | null | Variable<any, string>,
	sizes__medium__height?: ValueTypes["Post_Banner_sizes__medium__height_operator"] | undefined | null | Variable<any, string>,
	sizes__medium__mimeType?: ValueTypes["Post_Banner_sizes__medium__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__medium__filesize?: ValueTypes["Post_Banner_sizes__medium__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__medium__filename?: ValueTypes["Post_Banner_sizes__medium__filename_operator"] | undefined | null | Variable<any, string>,
	sizes__large__url?: ValueTypes["Post_Banner_sizes__large__url_operator"] | undefined | null | Variable<any, string>,
	sizes__large__width?: ValueTypes["Post_Banner_sizes__large__width_operator"] | undefined | null | Variable<any, string>,
	sizes__large__height?: ValueTypes["Post_Banner_sizes__large__height_operator"] | undefined | null | Variable<any, string>,
	sizes__large__mimeType?: ValueTypes["Post_Banner_sizes__large__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__large__filesize?: ValueTypes["Post_Banner_sizes__large__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__large__filename?: ValueTypes["Post_Banner_sizes__large__filename_operator"] | undefined | null | Variable<any, string>,
	sizes__small_jpeg__url?: ValueTypes["Post_Banner_sizes__small_jpeg__url_operator"] | undefined | null | Variable<any, string>,
	sizes__small_jpeg__width?: ValueTypes["Post_Banner_sizes__small_jpeg__width_operator"] | undefined | null | Variable<any, string>,
	sizes__small_jpeg__height?: ValueTypes["Post_Banner_sizes__small_jpeg__height_operator"] | undefined | null | Variable<any, string>,
	sizes__small_jpeg__mimeType?: ValueTypes["Post_Banner_sizes__small_jpeg__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__small_jpeg__filesize?: ValueTypes["Post_Banner_sizes__small_jpeg__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__small_jpeg__filename?: ValueTypes["Post_Banner_sizes__small_jpeg__filename_operator"] | undefined | null | Variable<any, string>,
	sizes__medium_jpeg__url?: ValueTypes["Post_Banner_sizes__medium_jpeg__url_operator"] | undefined | null | Variable<any, string>,
	sizes__medium_jpeg__width?: ValueTypes["Post_Banner_sizes__medium_jpeg__width_operator"] | undefined | null | Variable<any, string>,
	sizes__medium_jpeg__height?: ValueTypes["Post_Banner_sizes__medium_jpeg__height_operator"] | undefined | null | Variable<any, string>,
	sizes__medium_jpeg__mimeType?: ValueTypes["Post_Banner_sizes__medium_jpeg__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__medium_jpeg__filesize?: ValueTypes["Post_Banner_sizes__medium_jpeg__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__medium_jpeg__filename?: ValueTypes["Post_Banner_sizes__medium_jpeg__filename_operator"] | undefined | null | Variable<any, string>,
	sizes__large_jpeg__url?: ValueTypes["Post_Banner_sizes__large_jpeg__url_operator"] | undefined | null | Variable<any, string>,
	sizes__large_jpeg__width?: ValueTypes["Post_Banner_sizes__large_jpeg__width_operator"] | undefined | null | Variable<any, string>,
	sizes__large_jpeg__height?: ValueTypes["Post_Banner_sizes__large_jpeg__height_operator"] | undefined | null | Variable<any, string>,
	sizes__large_jpeg__mimeType?: ValueTypes["Post_Banner_sizes__large_jpeg__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__large_jpeg__filesize?: ValueTypes["Post_Banner_sizes__large_jpeg__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__large_jpeg__filename?: ValueTypes["Post_Banner_sizes__large_jpeg__filename_operator"] | undefined | null | Variable<any, string>,
	id?: ValueTypes["Post_Banner_id_operator"] | undefined | null | Variable<any, string>,
	AND?: Array<ValueTypes["Post_Banner_where_and"] | undefined | null> | undefined | null | Variable<any, string>,
	OR?: Array<ValueTypes["Post_Banner_where_or"] | undefined | null> | undefined | null | Variable<any, string>
};
	["Post_Banner_alt_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Post_Banner_updatedAt_operator"]: {
	equals?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	not_equals?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	greater_than_equal?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	greater_than?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	less_than_equal?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	less_than?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	like?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Post_Banner_createdAt_operator"]: {
	equals?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	not_equals?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	greater_than_equal?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	greater_than?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	less_than_equal?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	less_than?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	like?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Post_Banner_url_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Post_Banner_filename_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Post_Banner_mimeType_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Post_Banner_filesize_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Post_Banner_width_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Post_Banner_height_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Post_Banner_focalX_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Post_Banner_focalY_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Post_Banner_sizes__small__url_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Post_Banner_sizes__small__width_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Post_Banner_sizes__small__height_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Post_Banner_sizes__small__mimeType_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Post_Banner_sizes__small__filesize_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Post_Banner_sizes__small__filename_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Post_Banner_sizes__medium__url_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Post_Banner_sizes__medium__width_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Post_Banner_sizes__medium__height_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Post_Banner_sizes__medium__mimeType_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Post_Banner_sizes__medium__filesize_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Post_Banner_sizes__medium__filename_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Post_Banner_sizes__large__url_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Post_Banner_sizes__large__width_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Post_Banner_sizes__large__height_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Post_Banner_sizes__large__mimeType_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Post_Banner_sizes__large__filesize_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Post_Banner_sizes__large__filename_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Post_Banner_sizes__small_jpeg__url_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Post_Banner_sizes__small_jpeg__width_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Post_Banner_sizes__small_jpeg__height_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Post_Banner_sizes__small_jpeg__mimeType_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Post_Banner_sizes__small_jpeg__filesize_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Post_Banner_sizes__small_jpeg__filename_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Post_Banner_sizes__medium_jpeg__url_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Post_Banner_sizes__medium_jpeg__width_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Post_Banner_sizes__medium_jpeg__height_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Post_Banner_sizes__medium_jpeg__mimeType_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Post_Banner_sizes__medium_jpeg__filesize_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Post_Banner_sizes__medium_jpeg__filename_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Post_Banner_sizes__large_jpeg__url_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Post_Banner_sizes__large_jpeg__width_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Post_Banner_sizes__large_jpeg__height_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Post_Banner_sizes__large_jpeg__mimeType_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Post_Banner_sizes__large_jpeg__filesize_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Post_Banner_sizes__large_jpeg__filename_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Post_Banner_id_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Post_Banner_where_and"]: {
	alt?: ValueTypes["Post_Banner_alt_operator"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["Post_Banner_updatedAt_operator"] | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["Post_Banner_createdAt_operator"] | undefined | null | Variable<any, string>,
	url?: ValueTypes["Post_Banner_url_operator"] | undefined | null | Variable<any, string>,
	filename?: ValueTypes["Post_Banner_filename_operator"] | undefined | null | Variable<any, string>,
	mimeType?: ValueTypes["Post_Banner_mimeType_operator"] | undefined | null | Variable<any, string>,
	filesize?: ValueTypes["Post_Banner_filesize_operator"] | undefined | null | Variable<any, string>,
	width?: ValueTypes["Post_Banner_width_operator"] | undefined | null | Variable<any, string>,
	height?: ValueTypes["Post_Banner_height_operator"] | undefined | null | Variable<any, string>,
	focalX?: ValueTypes["Post_Banner_focalX_operator"] | undefined | null | Variable<any, string>,
	focalY?: ValueTypes["Post_Banner_focalY_operator"] | undefined | null | Variable<any, string>,
	sizes__small__url?: ValueTypes["Post_Banner_sizes__small__url_operator"] | undefined | null | Variable<any, string>,
	sizes__small__width?: ValueTypes["Post_Banner_sizes__small__width_operator"] | undefined | null | Variable<any, string>,
	sizes__small__height?: ValueTypes["Post_Banner_sizes__small__height_operator"] | undefined | null | Variable<any, string>,
	sizes__small__mimeType?: ValueTypes["Post_Banner_sizes__small__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__small__filesize?: ValueTypes["Post_Banner_sizes__small__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__small__filename?: ValueTypes["Post_Banner_sizes__small__filename_operator"] | undefined | null | Variable<any, string>,
	sizes__medium__url?: ValueTypes["Post_Banner_sizes__medium__url_operator"] | undefined | null | Variable<any, string>,
	sizes__medium__width?: ValueTypes["Post_Banner_sizes__medium__width_operator"] | undefined | null | Variable<any, string>,
	sizes__medium__height?: ValueTypes["Post_Banner_sizes__medium__height_operator"] | undefined | null | Variable<any, string>,
	sizes__medium__mimeType?: ValueTypes["Post_Banner_sizes__medium__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__medium__filesize?: ValueTypes["Post_Banner_sizes__medium__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__medium__filename?: ValueTypes["Post_Banner_sizes__medium__filename_operator"] | undefined | null | Variable<any, string>,
	sizes__large__url?: ValueTypes["Post_Banner_sizes__large__url_operator"] | undefined | null | Variable<any, string>,
	sizes__large__width?: ValueTypes["Post_Banner_sizes__large__width_operator"] | undefined | null | Variable<any, string>,
	sizes__large__height?: ValueTypes["Post_Banner_sizes__large__height_operator"] | undefined | null | Variable<any, string>,
	sizes__large__mimeType?: ValueTypes["Post_Banner_sizes__large__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__large__filesize?: ValueTypes["Post_Banner_sizes__large__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__large__filename?: ValueTypes["Post_Banner_sizes__large__filename_operator"] | undefined | null | Variable<any, string>,
	sizes__small_jpeg__url?: ValueTypes["Post_Banner_sizes__small_jpeg__url_operator"] | undefined | null | Variable<any, string>,
	sizes__small_jpeg__width?: ValueTypes["Post_Banner_sizes__small_jpeg__width_operator"] | undefined | null | Variable<any, string>,
	sizes__small_jpeg__height?: ValueTypes["Post_Banner_sizes__small_jpeg__height_operator"] | undefined | null | Variable<any, string>,
	sizes__small_jpeg__mimeType?: ValueTypes["Post_Banner_sizes__small_jpeg__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__small_jpeg__filesize?: ValueTypes["Post_Banner_sizes__small_jpeg__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__small_jpeg__filename?: ValueTypes["Post_Banner_sizes__small_jpeg__filename_operator"] | undefined | null | Variable<any, string>,
	sizes__medium_jpeg__url?: ValueTypes["Post_Banner_sizes__medium_jpeg__url_operator"] | undefined | null | Variable<any, string>,
	sizes__medium_jpeg__width?: ValueTypes["Post_Banner_sizes__medium_jpeg__width_operator"] | undefined | null | Variable<any, string>,
	sizes__medium_jpeg__height?: ValueTypes["Post_Banner_sizes__medium_jpeg__height_operator"] | undefined | null | Variable<any, string>,
	sizes__medium_jpeg__mimeType?: ValueTypes["Post_Banner_sizes__medium_jpeg__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__medium_jpeg__filesize?: ValueTypes["Post_Banner_sizes__medium_jpeg__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__medium_jpeg__filename?: ValueTypes["Post_Banner_sizes__medium_jpeg__filename_operator"] | undefined | null | Variable<any, string>,
	sizes__large_jpeg__url?: ValueTypes["Post_Banner_sizes__large_jpeg__url_operator"] | undefined | null | Variable<any, string>,
	sizes__large_jpeg__width?: ValueTypes["Post_Banner_sizes__large_jpeg__width_operator"] | undefined | null | Variable<any, string>,
	sizes__large_jpeg__height?: ValueTypes["Post_Banner_sizes__large_jpeg__height_operator"] | undefined | null | Variable<any, string>,
	sizes__large_jpeg__mimeType?: ValueTypes["Post_Banner_sizes__large_jpeg__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__large_jpeg__filesize?: ValueTypes["Post_Banner_sizes__large_jpeg__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__large_jpeg__filename?: ValueTypes["Post_Banner_sizes__large_jpeg__filename_operator"] | undefined | null | Variable<any, string>,
	id?: ValueTypes["Post_Banner_id_operator"] | undefined | null | Variable<any, string>,
	AND?: Array<ValueTypes["Post_Banner_where_and"] | undefined | null> | undefined | null | Variable<any, string>,
	OR?: Array<ValueTypes["Post_Banner_where_or"] | undefined | null> | undefined | null | Variable<any, string>
};
	["Post_Banner_where_or"]: {
	alt?: ValueTypes["Post_Banner_alt_operator"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["Post_Banner_updatedAt_operator"] | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["Post_Banner_createdAt_operator"] | undefined | null | Variable<any, string>,
	url?: ValueTypes["Post_Banner_url_operator"] | undefined | null | Variable<any, string>,
	filename?: ValueTypes["Post_Banner_filename_operator"] | undefined | null | Variable<any, string>,
	mimeType?: ValueTypes["Post_Banner_mimeType_operator"] | undefined | null | Variable<any, string>,
	filesize?: ValueTypes["Post_Banner_filesize_operator"] | undefined | null | Variable<any, string>,
	width?: ValueTypes["Post_Banner_width_operator"] | undefined | null | Variable<any, string>,
	height?: ValueTypes["Post_Banner_height_operator"] | undefined | null | Variable<any, string>,
	focalX?: ValueTypes["Post_Banner_focalX_operator"] | undefined | null | Variable<any, string>,
	focalY?: ValueTypes["Post_Banner_focalY_operator"] | undefined | null | Variable<any, string>,
	sizes__small__url?: ValueTypes["Post_Banner_sizes__small__url_operator"] | undefined | null | Variable<any, string>,
	sizes__small__width?: ValueTypes["Post_Banner_sizes__small__width_operator"] | undefined | null | Variable<any, string>,
	sizes__small__height?: ValueTypes["Post_Banner_sizes__small__height_operator"] | undefined | null | Variable<any, string>,
	sizes__small__mimeType?: ValueTypes["Post_Banner_sizes__small__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__small__filesize?: ValueTypes["Post_Banner_sizes__small__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__small__filename?: ValueTypes["Post_Banner_sizes__small__filename_operator"] | undefined | null | Variable<any, string>,
	sizes__medium__url?: ValueTypes["Post_Banner_sizes__medium__url_operator"] | undefined | null | Variable<any, string>,
	sizes__medium__width?: ValueTypes["Post_Banner_sizes__medium__width_operator"] | undefined | null | Variable<any, string>,
	sizes__medium__height?: ValueTypes["Post_Banner_sizes__medium__height_operator"] | undefined | null | Variable<any, string>,
	sizes__medium__mimeType?: ValueTypes["Post_Banner_sizes__medium__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__medium__filesize?: ValueTypes["Post_Banner_sizes__medium__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__medium__filename?: ValueTypes["Post_Banner_sizes__medium__filename_operator"] | undefined | null | Variable<any, string>,
	sizes__large__url?: ValueTypes["Post_Banner_sizes__large__url_operator"] | undefined | null | Variable<any, string>,
	sizes__large__width?: ValueTypes["Post_Banner_sizes__large__width_operator"] | undefined | null | Variable<any, string>,
	sizes__large__height?: ValueTypes["Post_Banner_sizes__large__height_operator"] | undefined | null | Variable<any, string>,
	sizes__large__mimeType?: ValueTypes["Post_Banner_sizes__large__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__large__filesize?: ValueTypes["Post_Banner_sizes__large__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__large__filename?: ValueTypes["Post_Banner_sizes__large__filename_operator"] | undefined | null | Variable<any, string>,
	sizes__small_jpeg__url?: ValueTypes["Post_Banner_sizes__small_jpeg__url_operator"] | undefined | null | Variable<any, string>,
	sizes__small_jpeg__width?: ValueTypes["Post_Banner_sizes__small_jpeg__width_operator"] | undefined | null | Variable<any, string>,
	sizes__small_jpeg__height?: ValueTypes["Post_Banner_sizes__small_jpeg__height_operator"] | undefined | null | Variable<any, string>,
	sizes__small_jpeg__mimeType?: ValueTypes["Post_Banner_sizes__small_jpeg__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__small_jpeg__filesize?: ValueTypes["Post_Banner_sizes__small_jpeg__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__small_jpeg__filename?: ValueTypes["Post_Banner_sizes__small_jpeg__filename_operator"] | undefined | null | Variable<any, string>,
	sizes__medium_jpeg__url?: ValueTypes["Post_Banner_sizes__medium_jpeg__url_operator"] | undefined | null | Variable<any, string>,
	sizes__medium_jpeg__width?: ValueTypes["Post_Banner_sizes__medium_jpeg__width_operator"] | undefined | null | Variable<any, string>,
	sizes__medium_jpeg__height?: ValueTypes["Post_Banner_sizes__medium_jpeg__height_operator"] | undefined | null | Variable<any, string>,
	sizes__medium_jpeg__mimeType?: ValueTypes["Post_Banner_sizes__medium_jpeg__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__medium_jpeg__filesize?: ValueTypes["Post_Banner_sizes__medium_jpeg__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__medium_jpeg__filename?: ValueTypes["Post_Banner_sizes__medium_jpeg__filename_operator"] | undefined | null | Variable<any, string>,
	sizes__large_jpeg__url?: ValueTypes["Post_Banner_sizes__large_jpeg__url_operator"] | undefined | null | Variable<any, string>,
	sizes__large_jpeg__width?: ValueTypes["Post_Banner_sizes__large_jpeg__width_operator"] | undefined | null | Variable<any, string>,
	sizes__large_jpeg__height?: ValueTypes["Post_Banner_sizes__large_jpeg__height_operator"] | undefined | null | Variable<any, string>,
	sizes__large_jpeg__mimeType?: ValueTypes["Post_Banner_sizes__large_jpeg__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__large_jpeg__filesize?: ValueTypes["Post_Banner_sizes__large_jpeg__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__large_jpeg__filename?: ValueTypes["Post_Banner_sizes__large_jpeg__filename_operator"] | undefined | null | Variable<any, string>,
	id?: ValueTypes["Post_Banner_id_operator"] | undefined | null | Variable<any, string>,
	AND?: Array<ValueTypes["Post_Banner_where_and"] | undefined | null> | undefined | null | Variable<any, string>,
	OR?: Array<ValueTypes["Post_Banner_where_or"] | undefined | null> | undefined | null | Variable<any, string>
};
	["Post_Criteria"]: AliasType<{
audiences?: [{	locale?: ValueTypes["LocaleInputType"] | undefined | null | Variable<any, string>,	fallbackLocale?: ValueTypes["FallbackLocaleInputType"] | undefined | null | Variable<any, string>},ValueTypes["Audience"]],
dates?: [{	locale?: ValueTypes["LocaleInputType"] | undefined | null | Variable<any, string>,	fallbackLocale?: ValueTypes["FallbackLocaleInputType"] | undefined | null | Variable<any, string>},ValueTypes["Date"]],
categories?: [{	locale?: ValueTypes["LocaleInputType"] | undefined | null | Variable<any, string>,	fallbackLocale?: ValueTypes["FallbackLocaleInputType"] | undefined | null | Variable<any, string>},ValueTypes["EventCategory"]],
tags?: [{	locale?: ValueTypes["LocaleInputType"] | undefined | null | Variable<any, string>,	fallbackLocale?: ValueTypes["FallbackLocaleInputType"] | undefined | null | Variable<any, string>},ValueTypes["Tag"]],
		__typename?: boolean | `@${string}`
}>;
	["Posts"]: AliasType<{
	docs?:ValueTypes["Post"],
	hasNextPage?:boolean | `@${string}`,
	hasPrevPage?:boolean | `@${string}`,
	limit?:boolean | `@${string}`,
	nextPage?:boolean | `@${string}`,
	offset?:boolean | `@${string}`,
	page?:boolean | `@${string}`,
	pagingCounter?:boolean | `@${string}`,
	prevPage?:boolean | `@${string}`,
	totalDocs?:boolean | `@${string}`,
	totalPages?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["Post_where"]: {
	title?: ValueTypes["Post_title_operator"] | undefined | null | Variable<any, string>,
	region?: ValueTypes["Post_region_operator"] | undefined | null | Variable<any, string>,
	authur?: ValueTypes["Post_authur_operator"] | undefined | null | Variable<any, string>,
	publishDateTime?: ValueTypes["Post_publishDateTime_operator"] | undefined | null | Variable<any, string>,
	permalink?: ValueTypes["Post_permalink_operator"] | undefined | null | Variable<any, string>,
	status?: ValueTypes["Post_status_operator"] | undefined | null | Variable<any, string>,
	legacyContent?: ValueTypes["Post_legacyContent_operator"] | undefined | null | Variable<any, string>,
	showLegacyContent?: ValueTypes["Post_showLegacyContent_operator"] | undefined | null | Variable<any, string>,
	blogCategories?: ValueTypes["Post_blogCategories_operator"] | undefined | null | Variable<any, string>,
	thumbnail?: ValueTypes["Post_thumbnail_operator"] | undefined | null | Variable<any, string>,
	banner?: ValueTypes["Post_banner_operator"] | undefined | null | Variable<any, string>,
	criteria__audiences?: ValueTypes["Post_criteria__audiences_operator"] | undefined | null | Variable<any, string>,
	criteria__dates?: ValueTypes["Post_criteria__dates_operator"] | undefined | null | Variable<any, string>,
	criteria__categories?: ValueTypes["Post_criteria__categories_operator"] | undefined | null | Variable<any, string>,
	criteria__tags?: ValueTypes["Post_criteria__tags_operator"] | undefined | null | Variable<any, string>,
	location?: ValueTypes["Post_location_operator"] | undefined | null | Variable<any, string>,
	views?: ValueTypes["Post_views_operator"] | undefined | null | Variable<any, string>,
	homePageInternalClick?: ValueTypes["Post_homePageInternalClick_operator"] | undefined | null | Variable<any, string>,
	homePageImpression?: ValueTypes["Post_homePageImpression_operator"] | undefined | null | Variable<any, string>,
	blogListInternalClick?: ValueTypes["Post_blogListInternalClick_operator"] | undefined | null | Variable<any, string>,
	blogListImpression?: ValueTypes["Post_blogListImpression_operator"] | undefined | null | Variable<any, string>,
	blogDetailInternalClick?: ValueTypes["Post_blogDetailInternalClick_operator"] | undefined | null | Variable<any, string>,
	blogDetailImpression?: ValueTypes["Post_blogDetailImpression_operator"] | undefined | null | Variable<any, string>,
	relatedEventInternalClick?: ValueTypes["Post_relatedEventInternalClick_operator"] | undefined | null | Variable<any, string>,
	relatedEventImpression?: ValueTypes["Post_relatedEventImpression_operator"] | undefined | null | Variable<any, string>,
	latestEventInternalClick?: ValueTypes["Post_latestEventInternalClick_operator"] | undefined | null | Variable<any, string>,
	latestEventImpression?: ValueTypes["Post_latestEventImpression_operator"] | undefined | null | Variable<any, string>,
	internalClick?: ValueTypes["Post_internalClick_operator"] | undefined | null | Variable<any, string>,
	totalImpression?: ValueTypes["Post_totalImpression_operator"] | undefined | null | Variable<any, string>,
	externalClick?: ValueTypes["Post_externalClick_operator"] | undefined | null | Variable<any, string>,
	outboundClick?: ValueTypes["Post_outboundClick_operator"] | undefined | null | Variable<any, string>,
	legacyGallery?: ValueTypes["Post_legacyGallery_operator"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["Post_updatedAt_operator"] | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["Post_createdAt_operator"] | undefined | null | Variable<any, string>,
	id?: ValueTypes["Post_id_operator"] | undefined | null | Variable<any, string>,
	AND?: Array<ValueTypes["Post_where_and"] | undefined | null> | undefined | null | Variable<any, string>,
	OR?: Array<ValueTypes["Post_where_or"] | undefined | null> | undefined | null | Variable<any, string>
};
	["Post_title_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Post_region_operator"]: {
	equals?: ValueTypes["Post_region_Input"] | undefined | null | Variable<any, string>,
	not_equals?: ValueTypes["Post_region_Input"] | undefined | null | Variable<any, string>,
	in?: Array<ValueTypes["Post_region_Input"] | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<ValueTypes["Post_region_Input"] | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<ValueTypes["Post_region_Input"] | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Post_region_Input"]:Post_region_Input;
	["Post_authur_operator"]: {
	equals?: ValueTypes["JSON"] | undefined | null | Variable<any, string>,
	not_equals?: ValueTypes["JSON"] | undefined | null | Variable<any, string>,
	in?: Array<ValueTypes["JSON"] | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<ValueTypes["JSON"] | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<ValueTypes["JSON"] | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Post_publishDateTime_operator"]: {
	equals?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	not_equals?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	greater_than_equal?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	greater_than?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	less_than_equal?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	less_than?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	like?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Post_permalink_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Post_status_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>
};
	["Post_legacyContent_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Post_showLegacyContent_operator"]: {
	equals?: boolean | undefined | null | Variable<any, string>,
	not_equals?: boolean | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Post_blogCategories_operator"]: {
	equals?: ValueTypes["JSON"] | undefined | null | Variable<any, string>,
	not_equals?: ValueTypes["JSON"] | undefined | null | Variable<any, string>,
	in?: Array<ValueTypes["JSON"] | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<ValueTypes["JSON"] | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<ValueTypes["JSON"] | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Post_thumbnail_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Post_banner_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Post_criteria__audiences_operator"]: {
	equals?: ValueTypes["JSON"] | undefined | null | Variable<any, string>,
	not_equals?: ValueTypes["JSON"] | undefined | null | Variable<any, string>,
	in?: Array<ValueTypes["JSON"] | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<ValueTypes["JSON"] | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<ValueTypes["JSON"] | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Post_criteria__dates_operator"]: {
	equals?: ValueTypes["JSON"] | undefined | null | Variable<any, string>,
	not_equals?: ValueTypes["JSON"] | undefined | null | Variable<any, string>,
	in?: Array<ValueTypes["JSON"] | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<ValueTypes["JSON"] | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<ValueTypes["JSON"] | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Post_criteria__categories_operator"]: {
	equals?: ValueTypes["JSON"] | undefined | null | Variable<any, string>,
	not_equals?: ValueTypes["JSON"] | undefined | null | Variable<any, string>,
	in?: Array<ValueTypes["JSON"] | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<ValueTypes["JSON"] | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<ValueTypes["JSON"] | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Post_criteria__tags_operator"]: {
	equals?: ValueTypes["JSON"] | undefined | null | Variable<any, string>,
	not_equals?: ValueTypes["JSON"] | undefined | null | Variable<any, string>,
	in?: Array<ValueTypes["JSON"] | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<ValueTypes["JSON"] | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<ValueTypes["JSON"] | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Post_location_operator"]: {
	equals?: ValueTypes["JSON"] | undefined | null | Variable<any, string>,
	not_equals?: ValueTypes["JSON"] | undefined | null | Variable<any, string>,
	in?: Array<ValueTypes["JSON"] | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<ValueTypes["JSON"] | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<ValueTypes["JSON"] | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Post_views_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Post_homePageInternalClick_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Post_homePageImpression_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Post_blogListInternalClick_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Post_blogListImpression_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Post_blogDetailInternalClick_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Post_blogDetailImpression_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Post_relatedEventInternalClick_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Post_relatedEventImpression_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Post_latestEventInternalClick_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Post_latestEventImpression_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Post_internalClick_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Post_totalImpression_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Post_externalClick_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Post_outboundClick_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Post_legacyGallery_operator"]: {
	equals?: ValueTypes["JSON"] | undefined | null | Variable<any, string>,
	not_equals?: ValueTypes["JSON"] | undefined | null | Variable<any, string>,
	in?: Array<ValueTypes["JSON"] | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<ValueTypes["JSON"] | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<ValueTypes["JSON"] | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Post_updatedAt_operator"]: {
	equals?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	not_equals?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	greater_than_equal?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	greater_than?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	less_than_equal?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	less_than?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	like?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Post_createdAt_operator"]: {
	equals?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	not_equals?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	greater_than_equal?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	greater_than?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	less_than_equal?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	less_than?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	like?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Post_id_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Post_where_and"]: {
	title?: ValueTypes["Post_title_operator"] | undefined | null | Variable<any, string>,
	region?: ValueTypes["Post_region_operator"] | undefined | null | Variable<any, string>,
	authur?: ValueTypes["Post_authur_operator"] | undefined | null | Variable<any, string>,
	publishDateTime?: ValueTypes["Post_publishDateTime_operator"] | undefined | null | Variable<any, string>,
	permalink?: ValueTypes["Post_permalink_operator"] | undefined | null | Variable<any, string>,
	status?: ValueTypes["Post_status_operator"] | undefined | null | Variable<any, string>,
	legacyContent?: ValueTypes["Post_legacyContent_operator"] | undefined | null | Variable<any, string>,
	showLegacyContent?: ValueTypes["Post_showLegacyContent_operator"] | undefined | null | Variable<any, string>,
	blogCategories?: ValueTypes["Post_blogCategories_operator"] | undefined | null | Variable<any, string>,
	thumbnail?: ValueTypes["Post_thumbnail_operator"] | undefined | null | Variable<any, string>,
	banner?: ValueTypes["Post_banner_operator"] | undefined | null | Variable<any, string>,
	criteria__audiences?: ValueTypes["Post_criteria__audiences_operator"] | undefined | null | Variable<any, string>,
	criteria__dates?: ValueTypes["Post_criteria__dates_operator"] | undefined | null | Variable<any, string>,
	criteria__categories?: ValueTypes["Post_criteria__categories_operator"] | undefined | null | Variable<any, string>,
	criteria__tags?: ValueTypes["Post_criteria__tags_operator"] | undefined | null | Variable<any, string>,
	location?: ValueTypes["Post_location_operator"] | undefined | null | Variable<any, string>,
	views?: ValueTypes["Post_views_operator"] | undefined | null | Variable<any, string>,
	homePageInternalClick?: ValueTypes["Post_homePageInternalClick_operator"] | undefined | null | Variable<any, string>,
	homePageImpression?: ValueTypes["Post_homePageImpression_operator"] | undefined | null | Variable<any, string>,
	blogListInternalClick?: ValueTypes["Post_blogListInternalClick_operator"] | undefined | null | Variable<any, string>,
	blogListImpression?: ValueTypes["Post_blogListImpression_operator"] | undefined | null | Variable<any, string>,
	blogDetailInternalClick?: ValueTypes["Post_blogDetailInternalClick_operator"] | undefined | null | Variable<any, string>,
	blogDetailImpression?: ValueTypes["Post_blogDetailImpression_operator"] | undefined | null | Variable<any, string>,
	relatedEventInternalClick?: ValueTypes["Post_relatedEventInternalClick_operator"] | undefined | null | Variable<any, string>,
	relatedEventImpression?: ValueTypes["Post_relatedEventImpression_operator"] | undefined | null | Variable<any, string>,
	latestEventInternalClick?: ValueTypes["Post_latestEventInternalClick_operator"] | undefined | null | Variable<any, string>,
	latestEventImpression?: ValueTypes["Post_latestEventImpression_operator"] | undefined | null | Variable<any, string>,
	internalClick?: ValueTypes["Post_internalClick_operator"] | undefined | null | Variable<any, string>,
	totalImpression?: ValueTypes["Post_totalImpression_operator"] | undefined | null | Variable<any, string>,
	externalClick?: ValueTypes["Post_externalClick_operator"] | undefined | null | Variable<any, string>,
	outboundClick?: ValueTypes["Post_outboundClick_operator"] | undefined | null | Variable<any, string>,
	legacyGallery?: ValueTypes["Post_legacyGallery_operator"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["Post_updatedAt_operator"] | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["Post_createdAt_operator"] | undefined | null | Variable<any, string>,
	id?: ValueTypes["Post_id_operator"] | undefined | null | Variable<any, string>,
	AND?: Array<ValueTypes["Post_where_and"] | undefined | null> | undefined | null | Variable<any, string>,
	OR?: Array<ValueTypes["Post_where_or"] | undefined | null> | undefined | null | Variable<any, string>
};
	["Post_where_or"]: {
	title?: ValueTypes["Post_title_operator"] | undefined | null | Variable<any, string>,
	region?: ValueTypes["Post_region_operator"] | undefined | null | Variable<any, string>,
	authur?: ValueTypes["Post_authur_operator"] | undefined | null | Variable<any, string>,
	publishDateTime?: ValueTypes["Post_publishDateTime_operator"] | undefined | null | Variable<any, string>,
	permalink?: ValueTypes["Post_permalink_operator"] | undefined | null | Variable<any, string>,
	status?: ValueTypes["Post_status_operator"] | undefined | null | Variable<any, string>,
	legacyContent?: ValueTypes["Post_legacyContent_operator"] | undefined | null | Variable<any, string>,
	showLegacyContent?: ValueTypes["Post_showLegacyContent_operator"] | undefined | null | Variable<any, string>,
	blogCategories?: ValueTypes["Post_blogCategories_operator"] | undefined | null | Variable<any, string>,
	thumbnail?: ValueTypes["Post_thumbnail_operator"] | undefined | null | Variable<any, string>,
	banner?: ValueTypes["Post_banner_operator"] | undefined | null | Variable<any, string>,
	criteria__audiences?: ValueTypes["Post_criteria__audiences_operator"] | undefined | null | Variable<any, string>,
	criteria__dates?: ValueTypes["Post_criteria__dates_operator"] | undefined | null | Variable<any, string>,
	criteria__categories?: ValueTypes["Post_criteria__categories_operator"] | undefined | null | Variable<any, string>,
	criteria__tags?: ValueTypes["Post_criteria__tags_operator"] | undefined | null | Variable<any, string>,
	location?: ValueTypes["Post_location_operator"] | undefined | null | Variable<any, string>,
	views?: ValueTypes["Post_views_operator"] | undefined | null | Variable<any, string>,
	homePageInternalClick?: ValueTypes["Post_homePageInternalClick_operator"] | undefined | null | Variable<any, string>,
	homePageImpression?: ValueTypes["Post_homePageImpression_operator"] | undefined | null | Variable<any, string>,
	blogListInternalClick?: ValueTypes["Post_blogListInternalClick_operator"] | undefined | null | Variable<any, string>,
	blogListImpression?: ValueTypes["Post_blogListImpression_operator"] | undefined | null | Variable<any, string>,
	blogDetailInternalClick?: ValueTypes["Post_blogDetailInternalClick_operator"] | undefined | null | Variable<any, string>,
	blogDetailImpression?: ValueTypes["Post_blogDetailImpression_operator"] | undefined | null | Variable<any, string>,
	relatedEventInternalClick?: ValueTypes["Post_relatedEventInternalClick_operator"] | undefined | null | Variable<any, string>,
	relatedEventImpression?: ValueTypes["Post_relatedEventImpression_operator"] | undefined | null | Variable<any, string>,
	latestEventInternalClick?: ValueTypes["Post_latestEventInternalClick_operator"] | undefined | null | Variable<any, string>,
	latestEventImpression?: ValueTypes["Post_latestEventImpression_operator"] | undefined | null | Variable<any, string>,
	internalClick?: ValueTypes["Post_internalClick_operator"] | undefined | null | Variable<any, string>,
	totalImpression?: ValueTypes["Post_totalImpression_operator"] | undefined | null | Variable<any, string>,
	externalClick?: ValueTypes["Post_externalClick_operator"] | undefined | null | Variable<any, string>,
	outboundClick?: ValueTypes["Post_outboundClick_operator"] | undefined | null | Variable<any, string>,
	legacyGallery?: ValueTypes["Post_legacyGallery_operator"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["Post_updatedAt_operator"] | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["Post_createdAt_operator"] | undefined | null | Variable<any, string>,
	id?: ValueTypes["Post_id_operator"] | undefined | null | Variable<any, string>,
	AND?: Array<ValueTypes["Post_where_and"] | undefined | null> | undefined | null | Variable<any, string>,
	OR?: Array<ValueTypes["Post_where_or"] | undefined | null> | undefined | null | Variable<any, string>
};
	["countPosts"]: AliasType<{
	totalDocs?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["postsDocAccess"]: AliasType<{
	fields?:ValueTypes["PostsDocAccessFields"],
	create?:ValueTypes["PostsCreateDocAccess"],
	read?:ValueTypes["PostsReadDocAccess"],
	update?:ValueTypes["PostsUpdateDocAccess"],
	delete?:ValueTypes["PostsDeleteDocAccess"],
		__typename?: boolean | `@${string}`
}>;
	["PostsDocAccessFields"]: AliasType<{
	title?:ValueTypes["PostsDocAccessFields_title"],
	region?:ValueTypes["PostsDocAccessFields_region"],
	authur?:ValueTypes["PostsDocAccessFields_authur"],
	publishDateTime?:ValueTypes["PostsDocAccessFields_publishDateTime"],
	permalink?:ValueTypes["PostsDocAccessFields_permalink"],
	status?:ValueTypes["PostsDocAccessFields_status"],
	content?:ValueTypes["PostsDocAccessFields_content"],
	legacyContent?:ValueTypes["PostsDocAccessFields_legacyContent"],
	showLegacyContent?:ValueTypes["PostsDocAccessFields_showLegacyContent"],
	blogCategories?:ValueTypes["PostsDocAccessFields_blogCategories"],
	thumbnail?:ValueTypes["PostsDocAccessFields_thumbnail"],
	banner?:ValueTypes["PostsDocAccessFields_banner"],
	criteria?:ValueTypes["PostsDocAccessFields_criteria"],
	location?:ValueTypes["PostsDocAccessFields_location"],
	homePageInternalClick?:ValueTypes["PostsDocAccessFields_homePageInternalClick"],
	homePageImpression?:ValueTypes["PostsDocAccessFields_homePageImpression"],
	blogListInternalClick?:ValueTypes["PostsDocAccessFields_blogListInternalClick"],
	blogListImpression?:ValueTypes["PostsDocAccessFields_blogListImpression"],
	blogDetailInternalClick?:ValueTypes["PostsDocAccessFields_blogDetailInternalClick"],
	blogDetailImpression?:ValueTypes["PostsDocAccessFields_blogDetailImpression"],
	relatedEventInternalClick?:ValueTypes["PostsDocAccessFields_relatedEventInternalClick"],
	relatedEventImpression?:ValueTypes["PostsDocAccessFields_relatedEventImpression"],
	latestEventInternalClick?:ValueTypes["PostsDocAccessFields_latestEventInternalClick"],
	latestEventImpression?:ValueTypes["PostsDocAccessFields_latestEventImpression"],
	internalClick?:ValueTypes["PostsDocAccessFields_internalClick"],
	totalImpression?:ValueTypes["PostsDocAccessFields_totalImpression"],
	externalClick?:ValueTypes["PostsDocAccessFields_externalClick"],
	outboundClick?:ValueTypes["PostsDocAccessFields_outboundClick"],
	legacyGallery?:ValueTypes["PostsDocAccessFields_legacyGallery"],
	updatedAt?:ValueTypes["PostsDocAccessFields_updatedAt"],
	createdAt?:ValueTypes["PostsDocAccessFields_createdAt"],
		__typename?: boolean | `@${string}`
}>;
	["PostsDocAccessFields_title"]: AliasType<{
	create?:ValueTypes["PostsDocAccessFields_title_Create"],
	read?:ValueTypes["PostsDocAccessFields_title_Read"],
	update?:ValueTypes["PostsDocAccessFields_title_Update"],
	delete?:ValueTypes["PostsDocAccessFields_title_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["PostsDocAccessFields_title_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsDocAccessFields_title_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsDocAccessFields_title_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsDocAccessFields_title_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsDocAccessFields_region"]: AliasType<{
	create?:ValueTypes["PostsDocAccessFields_region_Create"],
	read?:ValueTypes["PostsDocAccessFields_region_Read"],
	update?:ValueTypes["PostsDocAccessFields_region_Update"],
	delete?:ValueTypes["PostsDocAccessFields_region_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["PostsDocAccessFields_region_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsDocAccessFields_region_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsDocAccessFields_region_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsDocAccessFields_region_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsDocAccessFields_authur"]: AliasType<{
	create?:ValueTypes["PostsDocAccessFields_authur_Create"],
	read?:ValueTypes["PostsDocAccessFields_authur_Read"],
	update?:ValueTypes["PostsDocAccessFields_authur_Update"],
	delete?:ValueTypes["PostsDocAccessFields_authur_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["PostsDocAccessFields_authur_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsDocAccessFields_authur_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsDocAccessFields_authur_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsDocAccessFields_authur_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsDocAccessFields_publishDateTime"]: AliasType<{
	create?:ValueTypes["PostsDocAccessFields_publishDateTime_Create"],
	read?:ValueTypes["PostsDocAccessFields_publishDateTime_Read"],
	update?:ValueTypes["PostsDocAccessFields_publishDateTime_Update"],
	delete?:ValueTypes["PostsDocAccessFields_publishDateTime_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["PostsDocAccessFields_publishDateTime_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsDocAccessFields_publishDateTime_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsDocAccessFields_publishDateTime_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsDocAccessFields_publishDateTime_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsDocAccessFields_permalink"]: AliasType<{
	create?:ValueTypes["PostsDocAccessFields_permalink_Create"],
	read?:ValueTypes["PostsDocAccessFields_permalink_Read"],
	update?:ValueTypes["PostsDocAccessFields_permalink_Update"],
	delete?:ValueTypes["PostsDocAccessFields_permalink_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["PostsDocAccessFields_permalink_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsDocAccessFields_permalink_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsDocAccessFields_permalink_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsDocAccessFields_permalink_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsDocAccessFields_status"]: AliasType<{
	create?:ValueTypes["PostsDocAccessFields_status_Create"],
	read?:ValueTypes["PostsDocAccessFields_status_Read"],
	update?:ValueTypes["PostsDocAccessFields_status_Update"],
	delete?:ValueTypes["PostsDocAccessFields_status_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["PostsDocAccessFields_status_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsDocAccessFields_status_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsDocAccessFields_status_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsDocAccessFields_status_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsDocAccessFields_content"]: AliasType<{
	create?:ValueTypes["PostsDocAccessFields_content_Create"],
	read?:ValueTypes["PostsDocAccessFields_content_Read"],
	update?:ValueTypes["PostsDocAccessFields_content_Update"],
	delete?:ValueTypes["PostsDocAccessFields_content_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["PostsDocAccessFields_content_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsDocAccessFields_content_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsDocAccessFields_content_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsDocAccessFields_content_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsDocAccessFields_legacyContent"]: AliasType<{
	create?:ValueTypes["PostsDocAccessFields_legacyContent_Create"],
	read?:ValueTypes["PostsDocAccessFields_legacyContent_Read"],
	update?:ValueTypes["PostsDocAccessFields_legacyContent_Update"],
	delete?:ValueTypes["PostsDocAccessFields_legacyContent_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["PostsDocAccessFields_legacyContent_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsDocAccessFields_legacyContent_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsDocAccessFields_legacyContent_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsDocAccessFields_legacyContent_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsDocAccessFields_showLegacyContent"]: AliasType<{
	create?:ValueTypes["PostsDocAccessFields_showLegacyContent_Create"],
	read?:ValueTypes["PostsDocAccessFields_showLegacyContent_Read"],
	update?:ValueTypes["PostsDocAccessFields_showLegacyContent_Update"],
	delete?:ValueTypes["PostsDocAccessFields_showLegacyContent_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["PostsDocAccessFields_showLegacyContent_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsDocAccessFields_showLegacyContent_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsDocAccessFields_showLegacyContent_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsDocAccessFields_showLegacyContent_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsDocAccessFields_blogCategories"]: AliasType<{
	create?:ValueTypes["PostsDocAccessFields_blogCategories_Create"],
	read?:ValueTypes["PostsDocAccessFields_blogCategories_Read"],
	update?:ValueTypes["PostsDocAccessFields_blogCategories_Update"],
	delete?:ValueTypes["PostsDocAccessFields_blogCategories_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["PostsDocAccessFields_blogCategories_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsDocAccessFields_blogCategories_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsDocAccessFields_blogCategories_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsDocAccessFields_blogCategories_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsDocAccessFields_thumbnail"]: AliasType<{
	create?:ValueTypes["PostsDocAccessFields_thumbnail_Create"],
	read?:ValueTypes["PostsDocAccessFields_thumbnail_Read"],
	update?:ValueTypes["PostsDocAccessFields_thumbnail_Update"],
	delete?:ValueTypes["PostsDocAccessFields_thumbnail_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["PostsDocAccessFields_thumbnail_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsDocAccessFields_thumbnail_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsDocAccessFields_thumbnail_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsDocAccessFields_thumbnail_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsDocAccessFields_banner"]: AliasType<{
	create?:ValueTypes["PostsDocAccessFields_banner_Create"],
	read?:ValueTypes["PostsDocAccessFields_banner_Read"],
	update?:ValueTypes["PostsDocAccessFields_banner_Update"],
	delete?:ValueTypes["PostsDocAccessFields_banner_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["PostsDocAccessFields_banner_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsDocAccessFields_banner_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsDocAccessFields_banner_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsDocAccessFields_banner_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsDocAccessFields_criteria"]: AliasType<{
	create?:ValueTypes["PostsDocAccessFields_criteria_Create"],
	read?:ValueTypes["PostsDocAccessFields_criteria_Read"],
	update?:ValueTypes["PostsDocAccessFields_criteria_Update"],
	delete?:ValueTypes["PostsDocAccessFields_criteria_Delete"],
	fields?:ValueTypes["PostsDocAccessFields_criteria_Fields"],
		__typename?: boolean | `@${string}`
}>;
	["PostsDocAccessFields_criteria_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsDocAccessFields_criteria_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsDocAccessFields_criteria_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsDocAccessFields_criteria_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsDocAccessFields_criteria_Fields"]: AliasType<{
	audiences?:ValueTypes["PostsDocAccessFields_criteria_audiences"],
	dates?:ValueTypes["PostsDocAccessFields_criteria_dates"],
	categories?:ValueTypes["PostsDocAccessFields_criteria_categories"],
	tags?:ValueTypes["PostsDocAccessFields_criteria_tags"],
		__typename?: boolean | `@${string}`
}>;
	["PostsDocAccessFields_criteria_audiences"]: AliasType<{
	create?:ValueTypes["PostsDocAccessFields_criteria_audiences_Create"],
	read?:ValueTypes["PostsDocAccessFields_criteria_audiences_Read"],
	update?:ValueTypes["PostsDocAccessFields_criteria_audiences_Update"],
	delete?:ValueTypes["PostsDocAccessFields_criteria_audiences_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["PostsDocAccessFields_criteria_audiences_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsDocAccessFields_criteria_audiences_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsDocAccessFields_criteria_audiences_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsDocAccessFields_criteria_audiences_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsDocAccessFields_criteria_dates"]: AliasType<{
	create?:ValueTypes["PostsDocAccessFields_criteria_dates_Create"],
	read?:ValueTypes["PostsDocAccessFields_criteria_dates_Read"],
	update?:ValueTypes["PostsDocAccessFields_criteria_dates_Update"],
	delete?:ValueTypes["PostsDocAccessFields_criteria_dates_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["PostsDocAccessFields_criteria_dates_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsDocAccessFields_criteria_dates_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsDocAccessFields_criteria_dates_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsDocAccessFields_criteria_dates_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsDocAccessFields_criteria_categories"]: AliasType<{
	create?:ValueTypes["PostsDocAccessFields_criteria_categories_Create"],
	read?:ValueTypes["PostsDocAccessFields_criteria_categories_Read"],
	update?:ValueTypes["PostsDocAccessFields_criteria_categories_Update"],
	delete?:ValueTypes["PostsDocAccessFields_criteria_categories_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["PostsDocAccessFields_criteria_categories_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsDocAccessFields_criteria_categories_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsDocAccessFields_criteria_categories_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsDocAccessFields_criteria_categories_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsDocAccessFields_criteria_tags"]: AliasType<{
	create?:ValueTypes["PostsDocAccessFields_criteria_tags_Create"],
	read?:ValueTypes["PostsDocAccessFields_criteria_tags_Read"],
	update?:ValueTypes["PostsDocAccessFields_criteria_tags_Update"],
	delete?:ValueTypes["PostsDocAccessFields_criteria_tags_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["PostsDocAccessFields_criteria_tags_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsDocAccessFields_criteria_tags_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsDocAccessFields_criteria_tags_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsDocAccessFields_criteria_tags_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsDocAccessFields_location"]: AliasType<{
	create?:ValueTypes["PostsDocAccessFields_location_Create"],
	read?:ValueTypes["PostsDocAccessFields_location_Read"],
	update?:ValueTypes["PostsDocAccessFields_location_Update"],
	delete?:ValueTypes["PostsDocAccessFields_location_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["PostsDocAccessFields_location_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsDocAccessFields_location_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsDocAccessFields_location_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsDocAccessFields_location_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsDocAccessFields_homePageInternalClick"]: AliasType<{
	create?:ValueTypes["PostsDocAccessFields_homePageInternalClick_Create"],
	read?:ValueTypes["PostsDocAccessFields_homePageInternalClick_Read"],
	update?:ValueTypes["PostsDocAccessFields_homePageInternalClick_Update"],
	delete?:ValueTypes["PostsDocAccessFields_homePageInternalClick_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["PostsDocAccessFields_homePageInternalClick_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsDocAccessFields_homePageInternalClick_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsDocAccessFields_homePageInternalClick_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsDocAccessFields_homePageInternalClick_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsDocAccessFields_homePageImpression"]: AliasType<{
	create?:ValueTypes["PostsDocAccessFields_homePageImpression_Create"],
	read?:ValueTypes["PostsDocAccessFields_homePageImpression_Read"],
	update?:ValueTypes["PostsDocAccessFields_homePageImpression_Update"],
	delete?:ValueTypes["PostsDocAccessFields_homePageImpression_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["PostsDocAccessFields_homePageImpression_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsDocAccessFields_homePageImpression_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsDocAccessFields_homePageImpression_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsDocAccessFields_homePageImpression_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsDocAccessFields_blogListInternalClick"]: AliasType<{
	create?:ValueTypes["PostsDocAccessFields_blogListInternalClick_Create"],
	read?:ValueTypes["PostsDocAccessFields_blogListInternalClick_Read"],
	update?:ValueTypes["PostsDocAccessFields_blogListInternalClick_Update"],
	delete?:ValueTypes["PostsDocAccessFields_blogListInternalClick_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["PostsDocAccessFields_blogListInternalClick_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsDocAccessFields_blogListInternalClick_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsDocAccessFields_blogListInternalClick_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsDocAccessFields_blogListInternalClick_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsDocAccessFields_blogListImpression"]: AliasType<{
	create?:ValueTypes["PostsDocAccessFields_blogListImpression_Create"],
	read?:ValueTypes["PostsDocAccessFields_blogListImpression_Read"],
	update?:ValueTypes["PostsDocAccessFields_blogListImpression_Update"],
	delete?:ValueTypes["PostsDocAccessFields_blogListImpression_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["PostsDocAccessFields_blogListImpression_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsDocAccessFields_blogListImpression_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsDocAccessFields_blogListImpression_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsDocAccessFields_blogListImpression_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsDocAccessFields_blogDetailInternalClick"]: AliasType<{
	create?:ValueTypes["PostsDocAccessFields_blogDetailInternalClick_Create"],
	read?:ValueTypes["PostsDocAccessFields_blogDetailInternalClick_Read"],
	update?:ValueTypes["PostsDocAccessFields_blogDetailInternalClick_Update"],
	delete?:ValueTypes["PostsDocAccessFields_blogDetailInternalClick_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["PostsDocAccessFields_blogDetailInternalClick_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsDocAccessFields_blogDetailInternalClick_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsDocAccessFields_blogDetailInternalClick_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsDocAccessFields_blogDetailInternalClick_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsDocAccessFields_blogDetailImpression"]: AliasType<{
	create?:ValueTypes["PostsDocAccessFields_blogDetailImpression_Create"],
	read?:ValueTypes["PostsDocAccessFields_blogDetailImpression_Read"],
	update?:ValueTypes["PostsDocAccessFields_blogDetailImpression_Update"],
	delete?:ValueTypes["PostsDocAccessFields_blogDetailImpression_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["PostsDocAccessFields_blogDetailImpression_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsDocAccessFields_blogDetailImpression_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsDocAccessFields_blogDetailImpression_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsDocAccessFields_blogDetailImpression_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsDocAccessFields_relatedEventInternalClick"]: AliasType<{
	create?:ValueTypes["PostsDocAccessFields_relatedEventInternalClick_Create"],
	read?:ValueTypes["PostsDocAccessFields_relatedEventInternalClick_Read"],
	update?:ValueTypes["PostsDocAccessFields_relatedEventInternalClick_Update"],
	delete?:ValueTypes["PostsDocAccessFields_relatedEventInternalClick_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["PostsDocAccessFields_relatedEventInternalClick_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsDocAccessFields_relatedEventInternalClick_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsDocAccessFields_relatedEventInternalClick_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsDocAccessFields_relatedEventInternalClick_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsDocAccessFields_relatedEventImpression"]: AliasType<{
	create?:ValueTypes["PostsDocAccessFields_relatedEventImpression_Create"],
	read?:ValueTypes["PostsDocAccessFields_relatedEventImpression_Read"],
	update?:ValueTypes["PostsDocAccessFields_relatedEventImpression_Update"],
	delete?:ValueTypes["PostsDocAccessFields_relatedEventImpression_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["PostsDocAccessFields_relatedEventImpression_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsDocAccessFields_relatedEventImpression_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsDocAccessFields_relatedEventImpression_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsDocAccessFields_relatedEventImpression_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsDocAccessFields_latestEventInternalClick"]: AliasType<{
	create?:ValueTypes["PostsDocAccessFields_latestEventInternalClick_Create"],
	read?:ValueTypes["PostsDocAccessFields_latestEventInternalClick_Read"],
	update?:ValueTypes["PostsDocAccessFields_latestEventInternalClick_Update"],
	delete?:ValueTypes["PostsDocAccessFields_latestEventInternalClick_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["PostsDocAccessFields_latestEventInternalClick_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsDocAccessFields_latestEventInternalClick_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsDocAccessFields_latestEventInternalClick_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsDocAccessFields_latestEventInternalClick_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsDocAccessFields_latestEventImpression"]: AliasType<{
	create?:ValueTypes["PostsDocAccessFields_latestEventImpression_Create"],
	read?:ValueTypes["PostsDocAccessFields_latestEventImpression_Read"],
	update?:ValueTypes["PostsDocAccessFields_latestEventImpression_Update"],
	delete?:ValueTypes["PostsDocAccessFields_latestEventImpression_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["PostsDocAccessFields_latestEventImpression_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsDocAccessFields_latestEventImpression_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsDocAccessFields_latestEventImpression_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsDocAccessFields_latestEventImpression_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsDocAccessFields_internalClick"]: AliasType<{
	create?:ValueTypes["PostsDocAccessFields_internalClick_Create"],
	read?:ValueTypes["PostsDocAccessFields_internalClick_Read"],
	update?:ValueTypes["PostsDocAccessFields_internalClick_Update"],
	delete?:ValueTypes["PostsDocAccessFields_internalClick_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["PostsDocAccessFields_internalClick_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsDocAccessFields_internalClick_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsDocAccessFields_internalClick_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsDocAccessFields_internalClick_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsDocAccessFields_totalImpression"]: AliasType<{
	create?:ValueTypes["PostsDocAccessFields_totalImpression_Create"],
	read?:ValueTypes["PostsDocAccessFields_totalImpression_Read"],
	update?:ValueTypes["PostsDocAccessFields_totalImpression_Update"],
	delete?:ValueTypes["PostsDocAccessFields_totalImpression_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["PostsDocAccessFields_totalImpression_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsDocAccessFields_totalImpression_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsDocAccessFields_totalImpression_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsDocAccessFields_totalImpression_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsDocAccessFields_externalClick"]: AliasType<{
	create?:ValueTypes["PostsDocAccessFields_externalClick_Create"],
	read?:ValueTypes["PostsDocAccessFields_externalClick_Read"],
	update?:ValueTypes["PostsDocAccessFields_externalClick_Update"],
	delete?:ValueTypes["PostsDocAccessFields_externalClick_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["PostsDocAccessFields_externalClick_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsDocAccessFields_externalClick_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsDocAccessFields_externalClick_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsDocAccessFields_externalClick_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsDocAccessFields_outboundClick"]: AliasType<{
	create?:ValueTypes["PostsDocAccessFields_outboundClick_Create"],
	read?:ValueTypes["PostsDocAccessFields_outboundClick_Read"],
	update?:ValueTypes["PostsDocAccessFields_outboundClick_Update"],
	delete?:ValueTypes["PostsDocAccessFields_outboundClick_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["PostsDocAccessFields_outboundClick_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsDocAccessFields_outboundClick_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsDocAccessFields_outboundClick_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsDocAccessFields_outboundClick_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsDocAccessFields_legacyGallery"]: AliasType<{
	create?:ValueTypes["PostsDocAccessFields_legacyGallery_Create"],
	read?:ValueTypes["PostsDocAccessFields_legacyGallery_Read"],
	update?:ValueTypes["PostsDocAccessFields_legacyGallery_Update"],
	delete?:ValueTypes["PostsDocAccessFields_legacyGallery_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["PostsDocAccessFields_legacyGallery_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsDocAccessFields_legacyGallery_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsDocAccessFields_legacyGallery_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsDocAccessFields_legacyGallery_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsDocAccessFields_updatedAt"]: AliasType<{
	create?:ValueTypes["PostsDocAccessFields_updatedAt_Create"],
	read?:ValueTypes["PostsDocAccessFields_updatedAt_Read"],
	update?:ValueTypes["PostsDocAccessFields_updatedAt_Update"],
	delete?:ValueTypes["PostsDocAccessFields_updatedAt_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["PostsDocAccessFields_updatedAt_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsDocAccessFields_updatedAt_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsDocAccessFields_updatedAt_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsDocAccessFields_updatedAt_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsDocAccessFields_createdAt"]: AliasType<{
	create?:ValueTypes["PostsDocAccessFields_createdAt_Create"],
	read?:ValueTypes["PostsDocAccessFields_createdAt_Read"],
	update?:ValueTypes["PostsDocAccessFields_createdAt_Update"],
	delete?:ValueTypes["PostsDocAccessFields_createdAt_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["PostsDocAccessFields_createdAt_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsDocAccessFields_createdAt_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsDocAccessFields_createdAt_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsDocAccessFields_createdAt_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsCreateDocAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsReadDocAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsUpdateDocAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsDeleteDocAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["Ticketings"]: AliasType<{
	docs?:ValueTypes["Ticketing"],
	hasNextPage?:boolean | `@${string}`,
	hasPrevPage?:boolean | `@${string}`,
	limit?:boolean | `@${string}`,
	nextPage?:boolean | `@${string}`,
	offset?:boolean | `@${string}`,
	page?:boolean | `@${string}`,
	pagingCounter?:boolean | `@${string}`,
	prevPage?:boolean | `@${string}`,
	totalDocs?:boolean | `@${string}`,
	totalPages?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["Ticketing_where"]: {
	status?: ValueTypes["Ticketing_status_operator"] | undefined | null | Variable<any, string>,
	creator?: ValueTypes["Ticketing_creator_operator"] | undefined | null | Variable<any, string>,
	productType?: ValueTypes["Ticketing_productType_operator"] | undefined | null | Variable<any, string>,
	productName?: ValueTypes["Ticketing_productName_operator"] | undefined | null | Variable<any, string>,
	referenceId?: ValueTypes["Ticketing_referenceId_operator"] | undefined | null | Variable<any, string>,
	linkedEvent?: ValueTypes["Ticketing_linkedEvent_operator"] | undefined | null | Variable<any, string>,
	CheckInLevel?: ValueTypes["Ticketing_CheckInLevel_operator"] | undefined | null | Variable<any, string>,
	displayPrice?: ValueTypes["Ticketing_displayPrice_operator"] | undefined | null | Variable<any, string>,
	tnc?: ValueTypes["Ticketing_tnc_operator"] | undefined | null | Variable<any, string>,
	cutoffHours?: ValueTypes["Ticketing_cutoffHours_operator"] | undefined | null | Variable<any, string>,
	sessions__variantId?: ValueTypes["Ticketing_sessions__variantId_operator"] | undefined | null | Variable<any, string>,
	sessions__productId?: ValueTypes["Ticketing_sessions__productId_operator"] | undefined | null | Variable<any, string>,
	sessions__startDate?: ValueTypes["Ticketing_sessions__startDate_operator"] | undefined | null | Variable<any, string>,
	sessions__endDate?: ValueTypes["Ticketing_sessions__endDate_operator"] | undefined | null | Variable<any, string>,
	sessions__inventories__name?: ValueTypes["Ticketing_sessions__inventories__name_operator"] | undefined | null | Variable<any, string>,
	sessions__inventories__quantity?: ValueTypes["Ticketing_sessions__inventories__quantity_operator"] | undefined | null | Variable<any, string>,
	sessions__inventories__unitPrice?: ValueTypes["Ticketing_sessions__inventories__unitPrice_operator"] | undefined | null | Variable<any, string>,
	sessions__inventories__handlingFee?: ValueTypes["Ticketing_sessions__inventories__handlingFee_operator"] | undefined | null | Variable<any, string>,
	sessions__inventories__totalCost?: ValueTypes["Ticketing_sessions__inventories__totalCost_operator"] | undefined | null | Variable<any, string>,
	sessions__inventories__minPurchase?: ValueTypes["Ticketing_sessions__inventories__minPurchase_operator"] | undefined | null | Variable<any, string>,
	sessions__inventories__maxPurchase?: ValueTypes["Ticketing_sessions__inventories__maxPurchase_operator"] | undefined | null | Variable<any, string>,
	sessions__inventories__stocks__stockId?: ValueTypes["Ticketing_sessions__inventories__stocks__stockId_operator"] | undefined | null | Variable<any, string>,
	sessions__inventories__stocks__remaining?: ValueTypes["Ticketing_sessions__inventories__stocks__remaining_operator"] | undefined | null | Variable<any, string>,
	sessions__inventories__stocks__sold?: ValueTypes["Ticketing_sessions__inventories__stocks__sold_operator"] | undefined | null | Variable<any, string>,
	sessions__inventories__stocks__onHold?: ValueTypes["Ticketing_sessions__inventories__stocks__onHold_operator"] | undefined | null | Variable<any, string>,
	sessions__inventories__stocks__checkedIn?: ValueTypes["Ticketing_sessions__inventories__stocks__checkedIn_operator"] | undefined | null | Variable<any, string>,
	sessions__inventories__remarks?: ValueTypes["Ticketing_sessions__inventories__remarks_operator"] | undefined | null | Variable<any, string>,
	sessions__id?: ValueTypes["Ticketing_sessions__id_operator"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["Ticketing_updatedAt_operator"] | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["Ticketing_createdAt_operator"] | undefined | null | Variable<any, string>,
	id?: ValueTypes["Ticketing_id_operator"] | undefined | null | Variable<any, string>,
	AND?: Array<ValueTypes["Ticketing_where_and"] | undefined | null> | undefined | null | Variable<any, string>,
	OR?: Array<ValueTypes["Ticketing_where_or"] | undefined | null> | undefined | null | Variable<any, string>
};
	["Ticketing_status_operator"]: {
	equals?: ValueTypes["Ticketing_status_Input"] | undefined | null | Variable<any, string>,
	not_equals?: ValueTypes["Ticketing_status_Input"] | undefined | null | Variable<any, string>,
	in?: Array<ValueTypes["Ticketing_status_Input"] | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<ValueTypes["Ticketing_status_Input"] | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<ValueTypes["Ticketing_status_Input"] | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Ticketing_status_Input"]:Ticketing_status_Input;
	["Ticketing_creator_operator"]: {
	equals?: ValueTypes["JSON"] | undefined | null | Variable<any, string>,
	not_equals?: ValueTypes["JSON"] | undefined | null | Variable<any, string>,
	in?: Array<ValueTypes["JSON"] | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<ValueTypes["JSON"] | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<ValueTypes["JSON"] | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Ticketing_productType_operator"]: {
	equals?: ValueTypes["Ticketing_productType_Input"] | undefined | null | Variable<any, string>,
	not_equals?: ValueTypes["Ticketing_productType_Input"] | undefined | null | Variable<any, string>,
	like?: ValueTypes["Ticketing_productType_Input"] | undefined | null | Variable<any, string>,
	contains?: ValueTypes["Ticketing_productType_Input"] | undefined | null | Variable<any, string>
};
	["Ticketing_productType_Input"]:Ticketing_productType_Input;
	["Ticketing_productName_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>
};
	["Ticketing_referenceId_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Ticketing_linkedEvent_operator"]: {
	equals?: ValueTypes["JSON"] | undefined | null | Variable<any, string>,
	not_equals?: ValueTypes["JSON"] | undefined | null | Variable<any, string>,
	in?: Array<ValueTypes["JSON"] | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<ValueTypes["JSON"] | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<ValueTypes["JSON"] | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Ticketing_CheckInLevel_operator"]: {
	equals?: ValueTypes["Ticketing_CheckInLevel_Input"] | undefined | null | Variable<any, string>,
	not_equals?: ValueTypes["Ticketing_CheckInLevel_Input"] | undefined | null | Variable<any, string>,
	in?: Array<ValueTypes["Ticketing_CheckInLevel_Input"] | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<ValueTypes["Ticketing_CheckInLevel_Input"] | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<ValueTypes["Ticketing_CheckInLevel_Input"] | undefined | null> | undefined | null | Variable<any, string>
};
	["Ticketing_CheckInLevel_Input"]:Ticketing_CheckInLevel_Input;
	["Ticketing_displayPrice_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>
};
	["Ticketing_tnc_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>
};
	["Ticketing_cutoffHours_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>
};
	["Ticketing_sessions__variantId_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Ticketing_sessions__productId_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Ticketing_sessions__startDate_operator"]: {
	equals?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	not_equals?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	greater_than_equal?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	greater_than?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	less_than_equal?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	less_than?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	like?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>
};
	["Ticketing_sessions__endDate_operator"]: {
	equals?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	not_equals?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	greater_than_equal?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	greater_than?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	less_than_equal?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	less_than?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	like?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>
};
	["Ticketing_sessions__inventories__name_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>
};
	["Ticketing_sessions__inventories__quantity_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>
};
	["Ticketing_sessions__inventories__unitPrice_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>
};
	["Ticketing_sessions__inventories__handlingFee_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>
};
	["Ticketing_sessions__inventories__totalCost_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Ticketing_sessions__inventories__minPurchase_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>
};
	["Ticketing_sessions__inventories__maxPurchase_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>
};
	["Ticketing_sessions__inventories__stocks__stockId_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Ticketing_sessions__inventories__stocks__remaining_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Ticketing_sessions__inventories__stocks__sold_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Ticketing_sessions__inventories__stocks__onHold_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Ticketing_sessions__inventories__stocks__checkedIn_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Ticketing_sessions__inventories__remarks_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Ticketing_sessions__id_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Ticketing_updatedAt_operator"]: {
	equals?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	not_equals?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	greater_than_equal?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	greater_than?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	less_than_equal?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	less_than?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	like?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Ticketing_createdAt_operator"]: {
	equals?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	not_equals?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	greater_than_equal?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	greater_than?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	less_than_equal?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	less_than?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	like?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Ticketing_id_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Ticketing_where_and"]: {
	status?: ValueTypes["Ticketing_status_operator"] | undefined | null | Variable<any, string>,
	creator?: ValueTypes["Ticketing_creator_operator"] | undefined | null | Variable<any, string>,
	productType?: ValueTypes["Ticketing_productType_operator"] | undefined | null | Variable<any, string>,
	productName?: ValueTypes["Ticketing_productName_operator"] | undefined | null | Variable<any, string>,
	referenceId?: ValueTypes["Ticketing_referenceId_operator"] | undefined | null | Variable<any, string>,
	linkedEvent?: ValueTypes["Ticketing_linkedEvent_operator"] | undefined | null | Variable<any, string>,
	CheckInLevel?: ValueTypes["Ticketing_CheckInLevel_operator"] | undefined | null | Variable<any, string>,
	displayPrice?: ValueTypes["Ticketing_displayPrice_operator"] | undefined | null | Variable<any, string>,
	tnc?: ValueTypes["Ticketing_tnc_operator"] | undefined | null | Variable<any, string>,
	cutoffHours?: ValueTypes["Ticketing_cutoffHours_operator"] | undefined | null | Variable<any, string>,
	sessions__variantId?: ValueTypes["Ticketing_sessions__variantId_operator"] | undefined | null | Variable<any, string>,
	sessions__productId?: ValueTypes["Ticketing_sessions__productId_operator"] | undefined | null | Variable<any, string>,
	sessions__startDate?: ValueTypes["Ticketing_sessions__startDate_operator"] | undefined | null | Variable<any, string>,
	sessions__endDate?: ValueTypes["Ticketing_sessions__endDate_operator"] | undefined | null | Variable<any, string>,
	sessions__inventories__name?: ValueTypes["Ticketing_sessions__inventories__name_operator"] | undefined | null | Variable<any, string>,
	sessions__inventories__quantity?: ValueTypes["Ticketing_sessions__inventories__quantity_operator"] | undefined | null | Variable<any, string>,
	sessions__inventories__unitPrice?: ValueTypes["Ticketing_sessions__inventories__unitPrice_operator"] | undefined | null | Variable<any, string>,
	sessions__inventories__handlingFee?: ValueTypes["Ticketing_sessions__inventories__handlingFee_operator"] | undefined | null | Variable<any, string>,
	sessions__inventories__totalCost?: ValueTypes["Ticketing_sessions__inventories__totalCost_operator"] | undefined | null | Variable<any, string>,
	sessions__inventories__minPurchase?: ValueTypes["Ticketing_sessions__inventories__minPurchase_operator"] | undefined | null | Variable<any, string>,
	sessions__inventories__maxPurchase?: ValueTypes["Ticketing_sessions__inventories__maxPurchase_operator"] | undefined | null | Variable<any, string>,
	sessions__inventories__stocks__stockId?: ValueTypes["Ticketing_sessions__inventories__stocks__stockId_operator"] | undefined | null | Variable<any, string>,
	sessions__inventories__stocks__remaining?: ValueTypes["Ticketing_sessions__inventories__stocks__remaining_operator"] | undefined | null | Variable<any, string>,
	sessions__inventories__stocks__sold?: ValueTypes["Ticketing_sessions__inventories__stocks__sold_operator"] | undefined | null | Variable<any, string>,
	sessions__inventories__stocks__onHold?: ValueTypes["Ticketing_sessions__inventories__stocks__onHold_operator"] | undefined | null | Variable<any, string>,
	sessions__inventories__stocks__checkedIn?: ValueTypes["Ticketing_sessions__inventories__stocks__checkedIn_operator"] | undefined | null | Variable<any, string>,
	sessions__inventories__remarks?: ValueTypes["Ticketing_sessions__inventories__remarks_operator"] | undefined | null | Variable<any, string>,
	sessions__id?: ValueTypes["Ticketing_sessions__id_operator"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["Ticketing_updatedAt_operator"] | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["Ticketing_createdAt_operator"] | undefined | null | Variable<any, string>,
	id?: ValueTypes["Ticketing_id_operator"] | undefined | null | Variable<any, string>,
	AND?: Array<ValueTypes["Ticketing_where_and"] | undefined | null> | undefined | null | Variable<any, string>,
	OR?: Array<ValueTypes["Ticketing_where_or"] | undefined | null> | undefined | null | Variable<any, string>
};
	["Ticketing_where_or"]: {
	status?: ValueTypes["Ticketing_status_operator"] | undefined | null | Variable<any, string>,
	creator?: ValueTypes["Ticketing_creator_operator"] | undefined | null | Variable<any, string>,
	productType?: ValueTypes["Ticketing_productType_operator"] | undefined | null | Variable<any, string>,
	productName?: ValueTypes["Ticketing_productName_operator"] | undefined | null | Variable<any, string>,
	referenceId?: ValueTypes["Ticketing_referenceId_operator"] | undefined | null | Variable<any, string>,
	linkedEvent?: ValueTypes["Ticketing_linkedEvent_operator"] | undefined | null | Variable<any, string>,
	CheckInLevel?: ValueTypes["Ticketing_CheckInLevel_operator"] | undefined | null | Variable<any, string>,
	displayPrice?: ValueTypes["Ticketing_displayPrice_operator"] | undefined | null | Variable<any, string>,
	tnc?: ValueTypes["Ticketing_tnc_operator"] | undefined | null | Variable<any, string>,
	cutoffHours?: ValueTypes["Ticketing_cutoffHours_operator"] | undefined | null | Variable<any, string>,
	sessions__variantId?: ValueTypes["Ticketing_sessions__variantId_operator"] | undefined | null | Variable<any, string>,
	sessions__productId?: ValueTypes["Ticketing_sessions__productId_operator"] | undefined | null | Variable<any, string>,
	sessions__startDate?: ValueTypes["Ticketing_sessions__startDate_operator"] | undefined | null | Variable<any, string>,
	sessions__endDate?: ValueTypes["Ticketing_sessions__endDate_operator"] | undefined | null | Variable<any, string>,
	sessions__inventories__name?: ValueTypes["Ticketing_sessions__inventories__name_operator"] | undefined | null | Variable<any, string>,
	sessions__inventories__quantity?: ValueTypes["Ticketing_sessions__inventories__quantity_operator"] | undefined | null | Variable<any, string>,
	sessions__inventories__unitPrice?: ValueTypes["Ticketing_sessions__inventories__unitPrice_operator"] | undefined | null | Variable<any, string>,
	sessions__inventories__handlingFee?: ValueTypes["Ticketing_sessions__inventories__handlingFee_operator"] | undefined | null | Variable<any, string>,
	sessions__inventories__totalCost?: ValueTypes["Ticketing_sessions__inventories__totalCost_operator"] | undefined | null | Variable<any, string>,
	sessions__inventories__minPurchase?: ValueTypes["Ticketing_sessions__inventories__minPurchase_operator"] | undefined | null | Variable<any, string>,
	sessions__inventories__maxPurchase?: ValueTypes["Ticketing_sessions__inventories__maxPurchase_operator"] | undefined | null | Variable<any, string>,
	sessions__inventories__stocks__stockId?: ValueTypes["Ticketing_sessions__inventories__stocks__stockId_operator"] | undefined | null | Variable<any, string>,
	sessions__inventories__stocks__remaining?: ValueTypes["Ticketing_sessions__inventories__stocks__remaining_operator"] | undefined | null | Variable<any, string>,
	sessions__inventories__stocks__sold?: ValueTypes["Ticketing_sessions__inventories__stocks__sold_operator"] | undefined | null | Variable<any, string>,
	sessions__inventories__stocks__onHold?: ValueTypes["Ticketing_sessions__inventories__stocks__onHold_operator"] | undefined | null | Variable<any, string>,
	sessions__inventories__stocks__checkedIn?: ValueTypes["Ticketing_sessions__inventories__stocks__checkedIn_operator"] | undefined | null | Variable<any, string>,
	sessions__inventories__remarks?: ValueTypes["Ticketing_sessions__inventories__remarks_operator"] | undefined | null | Variable<any, string>,
	sessions__id?: ValueTypes["Ticketing_sessions__id_operator"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["Ticketing_updatedAt_operator"] | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["Ticketing_createdAt_operator"] | undefined | null | Variable<any, string>,
	id?: ValueTypes["Ticketing_id_operator"] | undefined | null | Variable<any, string>,
	AND?: Array<ValueTypes["Ticketing_where_and"] | undefined | null> | undefined | null | Variable<any, string>,
	OR?: Array<ValueTypes["Ticketing_where_or"] | undefined | null> | undefined | null | Variable<any, string>
};
	["countTicketings"]: AliasType<{
	totalDocs?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ticketingDocAccess"]: AliasType<{
	fields?:ValueTypes["TicketingDocAccessFields"],
	create?:ValueTypes["TicketingCreateDocAccess"],
	read?:ValueTypes["TicketingReadDocAccess"],
	update?:ValueTypes["TicketingUpdateDocAccess"],
	delete?:ValueTypes["TicketingDeleteDocAccess"],
		__typename?: boolean | `@${string}`
}>;
	["TicketingDocAccessFields"]: AliasType<{
	status?:ValueTypes["TicketingDocAccessFields_status"],
	creator?:ValueTypes["TicketingDocAccessFields_creator"],
	productType?:ValueTypes["TicketingDocAccessFields_productType"],
	productName?:ValueTypes["TicketingDocAccessFields_productName"],
	referenceId?:ValueTypes["TicketingDocAccessFields_referenceId"],
	linkedEvent?:ValueTypes["TicketingDocAccessFields_linkedEvent"],
	CheckInLevel?:ValueTypes["TicketingDocAccessFields_CheckInLevel"],
	displayPrice?:ValueTypes["TicketingDocAccessFields_displayPrice"],
	tnc?:ValueTypes["TicketingDocAccessFields_tnc"],
	cutoffHours?:ValueTypes["TicketingDocAccessFields_cutoffHours"],
	sessions?:ValueTypes["TicketingDocAccessFields_sessions"],
	updatedAt?:ValueTypes["TicketingDocAccessFields_updatedAt"],
	createdAt?:ValueTypes["TicketingDocAccessFields_createdAt"],
		__typename?: boolean | `@${string}`
}>;
	["TicketingDocAccessFields_status"]: AliasType<{
	create?:ValueTypes["TicketingDocAccessFields_status_Create"],
	read?:ValueTypes["TicketingDocAccessFields_status_Read"],
	update?:ValueTypes["TicketingDocAccessFields_status_Update"],
	delete?:ValueTypes["TicketingDocAccessFields_status_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["TicketingDocAccessFields_status_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingDocAccessFields_status_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingDocAccessFields_status_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingDocAccessFields_status_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingDocAccessFields_creator"]: AliasType<{
	create?:ValueTypes["TicketingDocAccessFields_creator_Create"],
	read?:ValueTypes["TicketingDocAccessFields_creator_Read"],
	update?:ValueTypes["TicketingDocAccessFields_creator_Update"],
	delete?:ValueTypes["TicketingDocAccessFields_creator_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["TicketingDocAccessFields_creator_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingDocAccessFields_creator_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingDocAccessFields_creator_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingDocAccessFields_creator_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingDocAccessFields_productType"]: AliasType<{
	create?:ValueTypes["TicketingDocAccessFields_productType_Create"],
	read?:ValueTypes["TicketingDocAccessFields_productType_Read"],
	update?:ValueTypes["TicketingDocAccessFields_productType_Update"],
	delete?:ValueTypes["TicketingDocAccessFields_productType_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["TicketingDocAccessFields_productType_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingDocAccessFields_productType_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingDocAccessFields_productType_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingDocAccessFields_productType_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingDocAccessFields_productName"]: AliasType<{
	create?:ValueTypes["TicketingDocAccessFields_productName_Create"],
	read?:ValueTypes["TicketingDocAccessFields_productName_Read"],
	update?:ValueTypes["TicketingDocAccessFields_productName_Update"],
	delete?:ValueTypes["TicketingDocAccessFields_productName_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["TicketingDocAccessFields_productName_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingDocAccessFields_productName_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingDocAccessFields_productName_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingDocAccessFields_productName_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingDocAccessFields_referenceId"]: AliasType<{
	create?:ValueTypes["TicketingDocAccessFields_referenceId_Create"],
	read?:ValueTypes["TicketingDocAccessFields_referenceId_Read"],
	update?:ValueTypes["TicketingDocAccessFields_referenceId_Update"],
	delete?:ValueTypes["TicketingDocAccessFields_referenceId_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["TicketingDocAccessFields_referenceId_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingDocAccessFields_referenceId_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingDocAccessFields_referenceId_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingDocAccessFields_referenceId_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingDocAccessFields_linkedEvent"]: AliasType<{
	create?:ValueTypes["TicketingDocAccessFields_linkedEvent_Create"],
	read?:ValueTypes["TicketingDocAccessFields_linkedEvent_Read"],
	update?:ValueTypes["TicketingDocAccessFields_linkedEvent_Update"],
	delete?:ValueTypes["TicketingDocAccessFields_linkedEvent_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["TicketingDocAccessFields_linkedEvent_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingDocAccessFields_linkedEvent_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingDocAccessFields_linkedEvent_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingDocAccessFields_linkedEvent_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingDocAccessFields_CheckInLevel"]: AliasType<{
	create?:ValueTypes["TicketingDocAccessFields_CheckInLevel_Create"],
	read?:ValueTypes["TicketingDocAccessFields_CheckInLevel_Read"],
	update?:ValueTypes["TicketingDocAccessFields_CheckInLevel_Update"],
	delete?:ValueTypes["TicketingDocAccessFields_CheckInLevel_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["TicketingDocAccessFields_CheckInLevel_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingDocAccessFields_CheckInLevel_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingDocAccessFields_CheckInLevel_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingDocAccessFields_CheckInLevel_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingDocAccessFields_displayPrice"]: AliasType<{
	create?:ValueTypes["TicketingDocAccessFields_displayPrice_Create"],
	read?:ValueTypes["TicketingDocAccessFields_displayPrice_Read"],
	update?:ValueTypes["TicketingDocAccessFields_displayPrice_Update"],
	delete?:ValueTypes["TicketingDocAccessFields_displayPrice_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["TicketingDocAccessFields_displayPrice_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingDocAccessFields_displayPrice_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingDocAccessFields_displayPrice_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingDocAccessFields_displayPrice_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingDocAccessFields_tnc"]: AliasType<{
	create?:ValueTypes["TicketingDocAccessFields_tnc_Create"],
	read?:ValueTypes["TicketingDocAccessFields_tnc_Read"],
	update?:ValueTypes["TicketingDocAccessFields_tnc_Update"],
	delete?:ValueTypes["TicketingDocAccessFields_tnc_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["TicketingDocAccessFields_tnc_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingDocAccessFields_tnc_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingDocAccessFields_tnc_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingDocAccessFields_tnc_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingDocAccessFields_cutoffHours"]: AliasType<{
	create?:ValueTypes["TicketingDocAccessFields_cutoffHours_Create"],
	read?:ValueTypes["TicketingDocAccessFields_cutoffHours_Read"],
	update?:ValueTypes["TicketingDocAccessFields_cutoffHours_Update"],
	delete?:ValueTypes["TicketingDocAccessFields_cutoffHours_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["TicketingDocAccessFields_cutoffHours_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingDocAccessFields_cutoffHours_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingDocAccessFields_cutoffHours_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingDocAccessFields_cutoffHours_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingDocAccessFields_sessions"]: AliasType<{
	create?:ValueTypes["TicketingDocAccessFields_sessions_Create"],
	read?:ValueTypes["TicketingDocAccessFields_sessions_Read"],
	update?:ValueTypes["TicketingDocAccessFields_sessions_Update"],
	delete?:ValueTypes["TicketingDocAccessFields_sessions_Delete"],
	fields?:ValueTypes["TicketingDocAccessFields_sessions_Fields"],
		__typename?: boolean | `@${string}`
}>;
	["TicketingDocAccessFields_sessions_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingDocAccessFields_sessions_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingDocAccessFields_sessions_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingDocAccessFields_sessions_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingDocAccessFields_sessions_Fields"]: AliasType<{
	productId?:ValueTypes["TicketingDocAccessFields_sessions_productId"],
	startDate?:ValueTypes["TicketingDocAccessFields_sessions_startDate"],
	endDate?:ValueTypes["TicketingDocAccessFields_sessions_endDate"],
	inventories?:ValueTypes["TicketingDocAccessFields_sessions_inventories"],
	id?:ValueTypes["TicketingDocAccessFields_sessions_id"],
		__typename?: boolean | `@${string}`
}>;
	["TicketingDocAccessFields_sessions_productId"]: AliasType<{
	create?:ValueTypes["TicketingDocAccessFields_sessions_productId_Create"],
	read?:ValueTypes["TicketingDocAccessFields_sessions_productId_Read"],
	update?:ValueTypes["TicketingDocAccessFields_sessions_productId_Update"],
	delete?:ValueTypes["TicketingDocAccessFields_sessions_productId_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["TicketingDocAccessFields_sessions_productId_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingDocAccessFields_sessions_productId_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingDocAccessFields_sessions_productId_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingDocAccessFields_sessions_productId_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingDocAccessFields_sessions_startDate"]: AliasType<{
	create?:ValueTypes["TicketingDocAccessFields_sessions_startDate_Create"],
	read?:ValueTypes["TicketingDocAccessFields_sessions_startDate_Read"],
	update?:ValueTypes["TicketingDocAccessFields_sessions_startDate_Update"],
	delete?:ValueTypes["TicketingDocAccessFields_sessions_startDate_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["TicketingDocAccessFields_sessions_startDate_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingDocAccessFields_sessions_startDate_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingDocAccessFields_sessions_startDate_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingDocAccessFields_sessions_startDate_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingDocAccessFields_sessions_endDate"]: AliasType<{
	create?:ValueTypes["TicketingDocAccessFields_sessions_endDate_Create"],
	read?:ValueTypes["TicketingDocAccessFields_sessions_endDate_Read"],
	update?:ValueTypes["TicketingDocAccessFields_sessions_endDate_Update"],
	delete?:ValueTypes["TicketingDocAccessFields_sessions_endDate_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["TicketingDocAccessFields_sessions_endDate_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingDocAccessFields_sessions_endDate_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingDocAccessFields_sessions_endDate_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingDocAccessFields_sessions_endDate_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingDocAccessFields_sessions_inventories"]: AliasType<{
	create?:ValueTypes["TicketingDocAccessFields_sessions_inventories_Create"],
	read?:ValueTypes["TicketingDocAccessFields_sessions_inventories_Read"],
	update?:ValueTypes["TicketingDocAccessFields_sessions_inventories_Update"],
	delete?:ValueTypes["TicketingDocAccessFields_sessions_inventories_Delete"],
	fields?:ValueTypes["TicketingDocAccessFields_sessions_inventories_Fields"],
		__typename?: boolean | `@${string}`
}>;
	["TicketingDocAccessFields_sessions_inventories_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingDocAccessFields_sessions_inventories_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingDocAccessFields_sessions_inventories_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingDocAccessFields_sessions_inventories_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingDocAccessFields_sessions_inventories_Fields"]: AliasType<{
	name?:ValueTypes["TicketingDocAccessFields_sessions_inventories_name"],
	quantity?:ValueTypes["TicketingDocAccessFields_sessions_inventories_quantity"],
	unitPrice?:ValueTypes["TicketingDocAccessFields_sessions_inventories_unitPrice"],
	handlingFee?:ValueTypes["TicketingDocAccessFields_sessions_inventories_handlingFee"],
	totalCost?:ValueTypes["TicketingDocAccessFields_sessions_inventories_totalCost"],
	minPurchase?:ValueTypes["TicketingDocAccessFields_sessions_inventories_minPurchase"],
	maxPurchase?:ValueTypes["TicketingDocAccessFields_sessions_inventories_maxPurchase"],
	stocks?:ValueTypes["TicketingDocAccessFields_sessions_inventories_stocks"],
	remarks?:ValueTypes["TicketingDocAccessFields_sessions_inventories_remarks"],
		__typename?: boolean | `@${string}`
}>;
	["TicketingDocAccessFields_sessions_inventories_name"]: AliasType<{
	create?:ValueTypes["TicketingDocAccessFields_sessions_inventories_name_Create"],
	read?:ValueTypes["TicketingDocAccessFields_sessions_inventories_name_Read"],
	update?:ValueTypes["TicketingDocAccessFields_sessions_inventories_name_Update"],
	delete?:ValueTypes["TicketingDocAccessFields_sessions_inventories_name_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["TicketingDocAccessFields_sessions_inventories_name_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingDocAccessFields_sessions_inventories_name_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingDocAccessFields_sessions_inventories_name_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingDocAccessFields_sessions_inventories_name_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingDocAccessFields_sessions_inventories_quantity"]: AliasType<{
	create?:ValueTypes["TicketingDocAccessFields_sessions_inventories_quantity_Create"],
	read?:ValueTypes["TicketingDocAccessFields_sessions_inventories_quantity_Read"],
	update?:ValueTypes["TicketingDocAccessFields_sessions_inventories_quantity_Update"],
	delete?:ValueTypes["TicketingDocAccessFields_sessions_inventories_quantity_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["TicketingDocAccessFields_sessions_inventories_quantity_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingDocAccessFields_sessions_inventories_quantity_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingDocAccessFields_sessions_inventories_quantity_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingDocAccessFields_sessions_inventories_quantity_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingDocAccessFields_sessions_inventories_unitPrice"]: AliasType<{
	create?:ValueTypes["TicketingDocAccessFields_sessions_inventories_unitPrice_Create"],
	read?:ValueTypes["TicketingDocAccessFields_sessions_inventories_unitPrice_Read"],
	update?:ValueTypes["TicketingDocAccessFields_sessions_inventories_unitPrice_Update"],
	delete?:ValueTypes["TicketingDocAccessFields_sessions_inventories_unitPrice_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["TicketingDocAccessFields_sessions_inventories_unitPrice_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingDocAccessFields_sessions_inventories_unitPrice_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingDocAccessFields_sessions_inventories_unitPrice_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingDocAccessFields_sessions_inventories_unitPrice_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingDocAccessFields_sessions_inventories_handlingFee"]: AliasType<{
	create?:ValueTypes["TicketingDocAccessFields_sessions_inventories_handlingFee_Create"],
	read?:ValueTypes["TicketingDocAccessFields_sessions_inventories_handlingFee_Read"],
	update?:ValueTypes["TicketingDocAccessFields_sessions_inventories_handlingFee_Update"],
	delete?:ValueTypes["TicketingDocAccessFields_sessions_inventories_handlingFee_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["TicketingDocAccessFields_sessions_inventories_handlingFee_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingDocAccessFields_sessions_inventories_handlingFee_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingDocAccessFields_sessions_inventories_handlingFee_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingDocAccessFields_sessions_inventories_handlingFee_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingDocAccessFields_sessions_inventories_totalCost"]: AliasType<{
	create?:ValueTypes["TicketingDocAccessFields_sessions_inventories_totalCost_Create"],
	read?:ValueTypes["TicketingDocAccessFields_sessions_inventories_totalCost_Read"],
	update?:ValueTypes["TicketingDocAccessFields_sessions_inventories_totalCost_Update"],
	delete?:ValueTypes["TicketingDocAccessFields_sessions_inventories_totalCost_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["TicketingDocAccessFields_sessions_inventories_totalCost_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingDocAccessFields_sessions_inventories_totalCost_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingDocAccessFields_sessions_inventories_totalCost_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingDocAccessFields_sessions_inventories_totalCost_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingDocAccessFields_sessions_inventories_minPurchase"]: AliasType<{
	create?:ValueTypes["TicketingDocAccessFields_sessions_inventories_minPurchase_Create"],
	read?:ValueTypes["TicketingDocAccessFields_sessions_inventories_minPurchase_Read"],
	update?:ValueTypes["TicketingDocAccessFields_sessions_inventories_minPurchase_Update"],
	delete?:ValueTypes["TicketingDocAccessFields_sessions_inventories_minPurchase_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["TicketingDocAccessFields_sessions_inventories_minPurchase_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingDocAccessFields_sessions_inventories_minPurchase_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingDocAccessFields_sessions_inventories_minPurchase_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingDocAccessFields_sessions_inventories_minPurchase_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingDocAccessFields_sessions_inventories_maxPurchase"]: AliasType<{
	create?:ValueTypes["TicketingDocAccessFields_sessions_inventories_maxPurchase_Create"],
	read?:ValueTypes["TicketingDocAccessFields_sessions_inventories_maxPurchase_Read"],
	update?:ValueTypes["TicketingDocAccessFields_sessions_inventories_maxPurchase_Update"],
	delete?:ValueTypes["TicketingDocAccessFields_sessions_inventories_maxPurchase_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["TicketingDocAccessFields_sessions_inventories_maxPurchase_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingDocAccessFields_sessions_inventories_maxPurchase_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingDocAccessFields_sessions_inventories_maxPurchase_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingDocAccessFields_sessions_inventories_maxPurchase_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingDocAccessFields_sessions_inventories_stocks"]: AliasType<{
	create?:ValueTypes["TicketingDocAccessFields_sessions_inventories_stocks_Create"],
	read?:ValueTypes["TicketingDocAccessFields_sessions_inventories_stocks_Read"],
	update?:ValueTypes["TicketingDocAccessFields_sessions_inventories_stocks_Update"],
	delete?:ValueTypes["TicketingDocAccessFields_sessions_inventories_stocks_Delete"],
	fields?:ValueTypes["TicketingDocAccessFields_sessions_inventories_stocks_Fields"],
		__typename?: boolean | `@${string}`
}>;
	["TicketingDocAccessFields_sessions_inventories_stocks_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingDocAccessFields_sessions_inventories_stocks_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingDocAccessFields_sessions_inventories_stocks_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingDocAccessFields_sessions_inventories_stocks_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingDocAccessFields_sessions_inventories_stocks_Fields"]: AliasType<{
	stockId?:ValueTypes["TicketingDocAccessFields_sessions_inventories_stocks_stockId"],
	remaining?:ValueTypes["TicketingDocAccessFields_sessions_inventories_stocks_remaining"],
	sold?:ValueTypes["TicketingDocAccessFields_sessions_inventories_stocks_sold"],
	onHold?:ValueTypes["TicketingDocAccessFields_sessions_inventories_stocks_onHold"],
	checkedIn?:ValueTypes["TicketingDocAccessFields_sessions_inventories_stocks_checkedIn"],
		__typename?: boolean | `@${string}`
}>;
	["TicketingDocAccessFields_sessions_inventories_stocks_stockId"]: AliasType<{
	create?:ValueTypes["TicketingDocAccessFields_sessions_inventories_stocks_stockId_Create"],
	read?:ValueTypes["TicketingDocAccessFields_sessions_inventories_stocks_stockId_Read"],
	update?:ValueTypes["TicketingDocAccessFields_sessions_inventories_stocks_stockId_Update"],
	delete?:ValueTypes["TicketingDocAccessFields_sessions_inventories_stocks_stockId_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["TicketingDocAccessFields_sessions_inventories_stocks_stockId_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingDocAccessFields_sessions_inventories_stocks_stockId_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingDocAccessFields_sessions_inventories_stocks_stockId_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingDocAccessFields_sessions_inventories_stocks_stockId_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingDocAccessFields_sessions_inventories_stocks_remaining"]: AliasType<{
	create?:ValueTypes["TicketingDocAccessFields_sessions_inventories_stocks_remaining_Create"],
	read?:ValueTypes["TicketingDocAccessFields_sessions_inventories_stocks_remaining_Read"],
	update?:ValueTypes["TicketingDocAccessFields_sessions_inventories_stocks_remaining_Update"],
	delete?:ValueTypes["TicketingDocAccessFields_sessions_inventories_stocks_remaining_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["TicketingDocAccessFields_sessions_inventories_stocks_remaining_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingDocAccessFields_sessions_inventories_stocks_remaining_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingDocAccessFields_sessions_inventories_stocks_remaining_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingDocAccessFields_sessions_inventories_stocks_remaining_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingDocAccessFields_sessions_inventories_stocks_sold"]: AliasType<{
	create?:ValueTypes["TicketingDocAccessFields_sessions_inventories_stocks_sold_Create"],
	read?:ValueTypes["TicketingDocAccessFields_sessions_inventories_stocks_sold_Read"],
	update?:ValueTypes["TicketingDocAccessFields_sessions_inventories_stocks_sold_Update"],
	delete?:ValueTypes["TicketingDocAccessFields_sessions_inventories_stocks_sold_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["TicketingDocAccessFields_sessions_inventories_stocks_sold_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingDocAccessFields_sessions_inventories_stocks_sold_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingDocAccessFields_sessions_inventories_stocks_sold_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingDocAccessFields_sessions_inventories_stocks_sold_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingDocAccessFields_sessions_inventories_stocks_onHold"]: AliasType<{
	create?:ValueTypes["TicketingDocAccessFields_sessions_inventories_stocks_onHold_Create"],
	read?:ValueTypes["TicketingDocAccessFields_sessions_inventories_stocks_onHold_Read"],
	update?:ValueTypes["TicketingDocAccessFields_sessions_inventories_stocks_onHold_Update"],
	delete?:ValueTypes["TicketingDocAccessFields_sessions_inventories_stocks_onHold_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["TicketingDocAccessFields_sessions_inventories_stocks_onHold_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingDocAccessFields_sessions_inventories_stocks_onHold_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingDocAccessFields_sessions_inventories_stocks_onHold_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingDocAccessFields_sessions_inventories_stocks_onHold_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingDocAccessFields_sessions_inventories_stocks_checkedIn"]: AliasType<{
	create?:ValueTypes["TicketingDocAccessFields_sessions_inventories_stocks_checkedIn_Create"],
	read?:ValueTypes["TicketingDocAccessFields_sessions_inventories_stocks_checkedIn_Read"],
	update?:ValueTypes["TicketingDocAccessFields_sessions_inventories_stocks_checkedIn_Update"],
	delete?:ValueTypes["TicketingDocAccessFields_sessions_inventories_stocks_checkedIn_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["TicketingDocAccessFields_sessions_inventories_stocks_checkedIn_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingDocAccessFields_sessions_inventories_stocks_checkedIn_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingDocAccessFields_sessions_inventories_stocks_checkedIn_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingDocAccessFields_sessions_inventories_stocks_checkedIn_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingDocAccessFields_sessions_inventories_remarks"]: AliasType<{
	create?:ValueTypes["TicketingDocAccessFields_sessions_inventories_remarks_Create"],
	read?:ValueTypes["TicketingDocAccessFields_sessions_inventories_remarks_Read"],
	update?:ValueTypes["TicketingDocAccessFields_sessions_inventories_remarks_Update"],
	delete?:ValueTypes["TicketingDocAccessFields_sessions_inventories_remarks_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["TicketingDocAccessFields_sessions_inventories_remarks_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingDocAccessFields_sessions_inventories_remarks_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingDocAccessFields_sessions_inventories_remarks_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingDocAccessFields_sessions_inventories_remarks_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingDocAccessFields_sessions_id"]: AliasType<{
	create?:ValueTypes["TicketingDocAccessFields_sessions_id_Create"],
	read?:ValueTypes["TicketingDocAccessFields_sessions_id_Read"],
	update?:ValueTypes["TicketingDocAccessFields_sessions_id_Update"],
	delete?:ValueTypes["TicketingDocAccessFields_sessions_id_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["TicketingDocAccessFields_sessions_id_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingDocAccessFields_sessions_id_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingDocAccessFields_sessions_id_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingDocAccessFields_sessions_id_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingDocAccessFields_updatedAt"]: AliasType<{
	create?:ValueTypes["TicketingDocAccessFields_updatedAt_Create"],
	read?:ValueTypes["TicketingDocAccessFields_updatedAt_Read"],
	update?:ValueTypes["TicketingDocAccessFields_updatedAt_Update"],
	delete?:ValueTypes["TicketingDocAccessFields_updatedAt_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["TicketingDocAccessFields_updatedAt_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingDocAccessFields_updatedAt_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingDocAccessFields_updatedAt_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingDocAccessFields_updatedAt_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingDocAccessFields_createdAt"]: AliasType<{
	create?:ValueTypes["TicketingDocAccessFields_createdAt_Create"],
	read?:ValueTypes["TicketingDocAccessFields_createdAt_Read"],
	update?:ValueTypes["TicketingDocAccessFields_createdAt_Update"],
	delete?:ValueTypes["TicketingDocAccessFields_createdAt_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["TicketingDocAccessFields_createdAt_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingDocAccessFields_createdAt_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingDocAccessFields_createdAt_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingDocAccessFields_createdAt_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingCreateDocAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingReadDocAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingUpdateDocAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingDeleteDocAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["Products"]: AliasType<{
	docs?:ValueTypes["Product"],
	hasNextPage?:boolean | `@${string}`,
	hasPrevPage?:boolean | `@${string}`,
	limit?:boolean | `@${string}`,
	nextPage?:boolean | `@${string}`,
	offset?:boolean | `@${string}`,
	page?:boolean | `@${string}`,
	pagingCounter?:boolean | `@${string}`,
	prevPage?:boolean | `@${string}`,
	totalDocs?:boolean | `@${string}`,
	totalPages?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["Product_where"]: {
	status?: ValueTypes["Product_status_operator"] | undefined | null | Variable<any, string>,
	creator?: ValueTypes["Product_creator_operator"] | undefined | null | Variable<any, string>,
	productType?: ValueTypes["Product_productType_operator"] | undefined | null | Variable<any, string>,
	productName?: ValueTypes["Product_productName_operator"] | undefined | null | Variable<any, string>,
	referenceId?: ValueTypes["Product_referenceId_operator"] | undefined | null | Variable<any, string>,
	linkedEvent?: ValueTypes["Product_linkedEvent_operator"] | undefined | null | Variable<any, string>,
	CheckInLevel?: ValueTypes["Product_CheckInLevel_operator"] | undefined | null | Variable<any, string>,
	thumbnail?: ValueTypes["Product_thumbnail_operator"] | undefined | null | Variable<any, string>,
	displayPrice?: ValueTypes["Product_displayPrice_operator"] | undefined | null | Variable<any, string>,
	transactionType?: ValueTypes["Product_transactionType_operator"] | undefined | null | Variable<any, string>,
	website?: ValueTypes["Product_website_operator"] | undefined | null | Variable<any, string>,
	tnc?: ValueTypes["Product_tnc_operator"] | undefined | null | Variable<any, string>,
	remarks?: ValueTypes["Product_remarks_operator"] | undefined | null | Variable<any, string>,
	sessions__variantId?: ValueTypes["Product_sessions__variantId_operator"] | undefined | null | Variable<any, string>,
	sessions__productId?: ValueTypes["Product_sessions__productId_operator"] | undefined | null | Variable<any, string>,
	sessions__name?: ValueTypes["Product_sessions__name_operator"] | undefined | null | Variable<any, string>,
	sessions__inventories__quantity?: ValueTypes["Product_sessions__inventories__quantity_operator"] | undefined | null | Variable<any, string>,
	sessions__inventories__unitPrice?: ValueTypes["Product_sessions__inventories__unitPrice_operator"] | undefined | null | Variable<any, string>,
	sessions__inventories__handlingFee?: ValueTypes["Product_sessions__inventories__handlingFee_operator"] | undefined | null | Variable<any, string>,
	sessions__inventories__totalCost?: ValueTypes["Product_sessions__inventories__totalCost_operator"] | undefined | null | Variable<any, string>,
	sessions__inventories__minPurchase?: ValueTypes["Product_sessions__inventories__minPurchase_operator"] | undefined | null | Variable<any, string>,
	sessions__inventories__maxPurchase?: ValueTypes["Product_sessions__inventories__maxPurchase_operator"] | undefined | null | Variable<any, string>,
	sessions__inventories__stocks__stockId?: ValueTypes["Product_sessions__inventories__stocks__stockId_operator"] | undefined | null | Variable<any, string>,
	sessions__inventories__stocks__remaining?: ValueTypes["Product_sessions__inventories__stocks__remaining_operator"] | undefined | null | Variable<any, string>,
	sessions__inventories__stocks__sold?: ValueTypes["Product_sessions__inventories__stocks__sold_operator"] | undefined | null | Variable<any, string>,
	sessions__inventories__stocks__onHold?: ValueTypes["Product_sessions__inventories__stocks__onHold_operator"] | undefined | null | Variable<any, string>,
	sessions__inventories__stocks__checkedIn?: ValueTypes["Product_sessions__inventories__stocks__checkedIn_operator"] | undefined | null | Variable<any, string>,
	sessions__id?: ValueTypes["Product_sessions__id_operator"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["Product_updatedAt_operator"] | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["Product_createdAt_operator"] | undefined | null | Variable<any, string>,
	id?: ValueTypes["Product_id_operator"] | undefined | null | Variable<any, string>,
	AND?: Array<ValueTypes["Product_where_and"] | undefined | null> | undefined | null | Variable<any, string>,
	OR?: Array<ValueTypes["Product_where_or"] | undefined | null> | undefined | null | Variable<any, string>
};
	["Product_status_operator"]: {
	equals?: ValueTypes["Product_status_Input"] | undefined | null | Variable<any, string>,
	not_equals?: ValueTypes["Product_status_Input"] | undefined | null | Variable<any, string>,
	in?: Array<ValueTypes["Product_status_Input"] | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<ValueTypes["Product_status_Input"] | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<ValueTypes["Product_status_Input"] | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Product_status_Input"]:Product_status_Input;
	["Product_creator_operator"]: {
	equals?: ValueTypes["JSON"] | undefined | null | Variable<any, string>,
	not_equals?: ValueTypes["JSON"] | undefined | null | Variable<any, string>,
	in?: Array<ValueTypes["JSON"] | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<ValueTypes["JSON"] | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<ValueTypes["JSON"] | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Product_productType_operator"]: {
	equals?: ValueTypes["Product_productType_Input"] | undefined | null | Variable<any, string>,
	not_equals?: ValueTypes["Product_productType_Input"] | undefined | null | Variable<any, string>,
	like?: ValueTypes["Product_productType_Input"] | undefined | null | Variable<any, string>,
	contains?: ValueTypes["Product_productType_Input"] | undefined | null | Variable<any, string>
};
	["Product_productType_Input"]:Product_productType_Input;
	["Product_productName_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>
};
	["Product_referenceId_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Product_linkedEvent_operator"]: {
	equals?: ValueTypes["JSON"] | undefined | null | Variable<any, string>,
	not_equals?: ValueTypes["JSON"] | undefined | null | Variable<any, string>,
	in?: Array<ValueTypes["JSON"] | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<ValueTypes["JSON"] | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<ValueTypes["JSON"] | undefined | null> | undefined | null | Variable<any, string>
};
	["Product_CheckInLevel_operator"]: {
	equals?: ValueTypes["Product_CheckInLevel_Input"] | undefined | null | Variable<any, string>,
	not_equals?: ValueTypes["Product_CheckInLevel_Input"] | undefined | null | Variable<any, string>,
	in?: Array<ValueTypes["Product_CheckInLevel_Input"] | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<ValueTypes["Product_CheckInLevel_Input"] | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<ValueTypes["Product_CheckInLevel_Input"] | undefined | null> | undefined | null | Variable<any, string>
};
	["Product_CheckInLevel_Input"]:Product_CheckInLevel_Input;
	["Product_thumbnail_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>
};
	["Product_displayPrice_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>
};
	["Product_transactionType_operator"]: {
	equals?: ValueTypes["Product_transactionType_Input"] | undefined | null | Variable<any, string>,
	not_equals?: ValueTypes["Product_transactionType_Input"] | undefined | null | Variable<any, string>,
	like?: ValueTypes["Product_transactionType_Input"] | undefined | null | Variable<any, string>,
	contains?: ValueTypes["Product_transactionType_Input"] | undefined | null | Variable<any, string>
};
	["Product_transactionType_Input"]:Product_transactionType_Input;
	["Product_website_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>
};
	["Product_tnc_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>
};
	["Product_remarks_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Product_sessions__variantId_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Product_sessions__productId_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Product_sessions__name_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>
};
	["Product_sessions__inventories__quantity_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>
};
	["Product_sessions__inventories__unitPrice_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>
};
	["Product_sessions__inventories__handlingFee_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>
};
	["Product_sessions__inventories__totalCost_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Product_sessions__inventories__minPurchase_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>
};
	["Product_sessions__inventories__maxPurchase_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>
};
	["Product_sessions__inventories__stocks__stockId_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Product_sessions__inventories__stocks__remaining_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Product_sessions__inventories__stocks__sold_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Product_sessions__inventories__stocks__onHold_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Product_sessions__inventories__stocks__checkedIn_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Product_sessions__id_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Product_updatedAt_operator"]: {
	equals?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	not_equals?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	greater_than_equal?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	greater_than?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	less_than_equal?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	less_than?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	like?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Product_createdAt_operator"]: {
	equals?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	not_equals?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	greater_than_equal?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	greater_than?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	less_than_equal?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	less_than?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	like?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Product_id_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Product_where_and"]: {
	status?: ValueTypes["Product_status_operator"] | undefined | null | Variable<any, string>,
	creator?: ValueTypes["Product_creator_operator"] | undefined | null | Variable<any, string>,
	productType?: ValueTypes["Product_productType_operator"] | undefined | null | Variable<any, string>,
	productName?: ValueTypes["Product_productName_operator"] | undefined | null | Variable<any, string>,
	referenceId?: ValueTypes["Product_referenceId_operator"] | undefined | null | Variable<any, string>,
	linkedEvent?: ValueTypes["Product_linkedEvent_operator"] | undefined | null | Variable<any, string>,
	CheckInLevel?: ValueTypes["Product_CheckInLevel_operator"] | undefined | null | Variable<any, string>,
	thumbnail?: ValueTypes["Product_thumbnail_operator"] | undefined | null | Variable<any, string>,
	displayPrice?: ValueTypes["Product_displayPrice_operator"] | undefined | null | Variable<any, string>,
	transactionType?: ValueTypes["Product_transactionType_operator"] | undefined | null | Variable<any, string>,
	website?: ValueTypes["Product_website_operator"] | undefined | null | Variable<any, string>,
	tnc?: ValueTypes["Product_tnc_operator"] | undefined | null | Variable<any, string>,
	remarks?: ValueTypes["Product_remarks_operator"] | undefined | null | Variable<any, string>,
	sessions__variantId?: ValueTypes["Product_sessions__variantId_operator"] | undefined | null | Variable<any, string>,
	sessions__productId?: ValueTypes["Product_sessions__productId_operator"] | undefined | null | Variable<any, string>,
	sessions__name?: ValueTypes["Product_sessions__name_operator"] | undefined | null | Variable<any, string>,
	sessions__inventories__quantity?: ValueTypes["Product_sessions__inventories__quantity_operator"] | undefined | null | Variable<any, string>,
	sessions__inventories__unitPrice?: ValueTypes["Product_sessions__inventories__unitPrice_operator"] | undefined | null | Variable<any, string>,
	sessions__inventories__handlingFee?: ValueTypes["Product_sessions__inventories__handlingFee_operator"] | undefined | null | Variable<any, string>,
	sessions__inventories__totalCost?: ValueTypes["Product_sessions__inventories__totalCost_operator"] | undefined | null | Variable<any, string>,
	sessions__inventories__minPurchase?: ValueTypes["Product_sessions__inventories__minPurchase_operator"] | undefined | null | Variable<any, string>,
	sessions__inventories__maxPurchase?: ValueTypes["Product_sessions__inventories__maxPurchase_operator"] | undefined | null | Variable<any, string>,
	sessions__inventories__stocks__stockId?: ValueTypes["Product_sessions__inventories__stocks__stockId_operator"] | undefined | null | Variable<any, string>,
	sessions__inventories__stocks__remaining?: ValueTypes["Product_sessions__inventories__stocks__remaining_operator"] | undefined | null | Variable<any, string>,
	sessions__inventories__stocks__sold?: ValueTypes["Product_sessions__inventories__stocks__sold_operator"] | undefined | null | Variable<any, string>,
	sessions__inventories__stocks__onHold?: ValueTypes["Product_sessions__inventories__stocks__onHold_operator"] | undefined | null | Variable<any, string>,
	sessions__inventories__stocks__checkedIn?: ValueTypes["Product_sessions__inventories__stocks__checkedIn_operator"] | undefined | null | Variable<any, string>,
	sessions__id?: ValueTypes["Product_sessions__id_operator"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["Product_updatedAt_operator"] | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["Product_createdAt_operator"] | undefined | null | Variable<any, string>,
	id?: ValueTypes["Product_id_operator"] | undefined | null | Variable<any, string>,
	AND?: Array<ValueTypes["Product_where_and"] | undefined | null> | undefined | null | Variable<any, string>,
	OR?: Array<ValueTypes["Product_where_or"] | undefined | null> | undefined | null | Variable<any, string>
};
	["Product_where_or"]: {
	status?: ValueTypes["Product_status_operator"] | undefined | null | Variable<any, string>,
	creator?: ValueTypes["Product_creator_operator"] | undefined | null | Variable<any, string>,
	productType?: ValueTypes["Product_productType_operator"] | undefined | null | Variable<any, string>,
	productName?: ValueTypes["Product_productName_operator"] | undefined | null | Variable<any, string>,
	referenceId?: ValueTypes["Product_referenceId_operator"] | undefined | null | Variable<any, string>,
	linkedEvent?: ValueTypes["Product_linkedEvent_operator"] | undefined | null | Variable<any, string>,
	CheckInLevel?: ValueTypes["Product_CheckInLevel_operator"] | undefined | null | Variable<any, string>,
	thumbnail?: ValueTypes["Product_thumbnail_operator"] | undefined | null | Variable<any, string>,
	displayPrice?: ValueTypes["Product_displayPrice_operator"] | undefined | null | Variable<any, string>,
	transactionType?: ValueTypes["Product_transactionType_operator"] | undefined | null | Variable<any, string>,
	website?: ValueTypes["Product_website_operator"] | undefined | null | Variable<any, string>,
	tnc?: ValueTypes["Product_tnc_operator"] | undefined | null | Variable<any, string>,
	remarks?: ValueTypes["Product_remarks_operator"] | undefined | null | Variable<any, string>,
	sessions__variantId?: ValueTypes["Product_sessions__variantId_operator"] | undefined | null | Variable<any, string>,
	sessions__productId?: ValueTypes["Product_sessions__productId_operator"] | undefined | null | Variable<any, string>,
	sessions__name?: ValueTypes["Product_sessions__name_operator"] | undefined | null | Variable<any, string>,
	sessions__inventories__quantity?: ValueTypes["Product_sessions__inventories__quantity_operator"] | undefined | null | Variable<any, string>,
	sessions__inventories__unitPrice?: ValueTypes["Product_sessions__inventories__unitPrice_operator"] | undefined | null | Variable<any, string>,
	sessions__inventories__handlingFee?: ValueTypes["Product_sessions__inventories__handlingFee_operator"] | undefined | null | Variable<any, string>,
	sessions__inventories__totalCost?: ValueTypes["Product_sessions__inventories__totalCost_operator"] | undefined | null | Variable<any, string>,
	sessions__inventories__minPurchase?: ValueTypes["Product_sessions__inventories__minPurchase_operator"] | undefined | null | Variable<any, string>,
	sessions__inventories__maxPurchase?: ValueTypes["Product_sessions__inventories__maxPurchase_operator"] | undefined | null | Variable<any, string>,
	sessions__inventories__stocks__stockId?: ValueTypes["Product_sessions__inventories__stocks__stockId_operator"] | undefined | null | Variable<any, string>,
	sessions__inventories__stocks__remaining?: ValueTypes["Product_sessions__inventories__stocks__remaining_operator"] | undefined | null | Variable<any, string>,
	sessions__inventories__stocks__sold?: ValueTypes["Product_sessions__inventories__stocks__sold_operator"] | undefined | null | Variable<any, string>,
	sessions__inventories__stocks__onHold?: ValueTypes["Product_sessions__inventories__stocks__onHold_operator"] | undefined | null | Variable<any, string>,
	sessions__inventories__stocks__checkedIn?: ValueTypes["Product_sessions__inventories__stocks__checkedIn_operator"] | undefined | null | Variable<any, string>,
	sessions__id?: ValueTypes["Product_sessions__id_operator"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["Product_updatedAt_operator"] | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["Product_createdAt_operator"] | undefined | null | Variable<any, string>,
	id?: ValueTypes["Product_id_operator"] | undefined | null | Variable<any, string>,
	AND?: Array<ValueTypes["Product_where_and"] | undefined | null> | undefined | null | Variable<any, string>,
	OR?: Array<ValueTypes["Product_where_or"] | undefined | null> | undefined | null | Variable<any, string>
};
	["countProducts"]: AliasType<{
	totalDocs?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["productsDocAccess"]: AliasType<{
	fields?:ValueTypes["ProductsDocAccessFields"],
	create?:ValueTypes["ProductsCreateDocAccess"],
	read?:ValueTypes["ProductsReadDocAccess"],
	update?:ValueTypes["ProductsUpdateDocAccess"],
	delete?:ValueTypes["ProductsDeleteDocAccess"],
		__typename?: boolean | `@${string}`
}>;
	["ProductsDocAccessFields"]: AliasType<{
	status?:ValueTypes["ProductsDocAccessFields_status"],
	creator?:ValueTypes["ProductsDocAccessFields_creator"],
	productType?:ValueTypes["ProductsDocAccessFields_productType"],
	productName?:ValueTypes["ProductsDocAccessFields_productName"],
	referenceId?:ValueTypes["ProductsDocAccessFields_referenceId"],
	linkedEvent?:ValueTypes["ProductsDocAccessFields_linkedEvent"],
	CheckInLevel?:ValueTypes["ProductsDocAccessFields_CheckInLevel"],
	thumbnail?:ValueTypes["ProductsDocAccessFields_thumbnail"],
	displayPrice?:ValueTypes["ProductsDocAccessFields_displayPrice"],
	transactionType?:ValueTypes["ProductsDocAccessFields_transactionType"],
	website?:ValueTypes["ProductsDocAccessFields_website"],
	tnc?:ValueTypes["ProductsDocAccessFields_tnc"],
	remarks?:ValueTypes["ProductsDocAccessFields_remarks"],
	sessions?:ValueTypes["ProductsDocAccessFields_sessions"],
	updatedAt?:ValueTypes["ProductsDocAccessFields_updatedAt"],
	createdAt?:ValueTypes["ProductsDocAccessFields_createdAt"],
		__typename?: boolean | `@${string}`
}>;
	["ProductsDocAccessFields_status"]: AliasType<{
	create?:ValueTypes["ProductsDocAccessFields_status_Create"],
	read?:ValueTypes["ProductsDocAccessFields_status_Read"],
	update?:ValueTypes["ProductsDocAccessFields_status_Update"],
	delete?:ValueTypes["ProductsDocAccessFields_status_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["ProductsDocAccessFields_status_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsDocAccessFields_status_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsDocAccessFields_status_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsDocAccessFields_status_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsDocAccessFields_creator"]: AliasType<{
	create?:ValueTypes["ProductsDocAccessFields_creator_Create"],
	read?:ValueTypes["ProductsDocAccessFields_creator_Read"],
	update?:ValueTypes["ProductsDocAccessFields_creator_Update"],
	delete?:ValueTypes["ProductsDocAccessFields_creator_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["ProductsDocAccessFields_creator_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsDocAccessFields_creator_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsDocAccessFields_creator_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsDocAccessFields_creator_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsDocAccessFields_productType"]: AliasType<{
	create?:ValueTypes["ProductsDocAccessFields_productType_Create"],
	read?:ValueTypes["ProductsDocAccessFields_productType_Read"],
	update?:ValueTypes["ProductsDocAccessFields_productType_Update"],
	delete?:ValueTypes["ProductsDocAccessFields_productType_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["ProductsDocAccessFields_productType_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsDocAccessFields_productType_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsDocAccessFields_productType_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsDocAccessFields_productType_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsDocAccessFields_productName"]: AliasType<{
	create?:ValueTypes["ProductsDocAccessFields_productName_Create"],
	read?:ValueTypes["ProductsDocAccessFields_productName_Read"],
	update?:ValueTypes["ProductsDocAccessFields_productName_Update"],
	delete?:ValueTypes["ProductsDocAccessFields_productName_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["ProductsDocAccessFields_productName_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsDocAccessFields_productName_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsDocAccessFields_productName_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsDocAccessFields_productName_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsDocAccessFields_referenceId"]: AliasType<{
	create?:ValueTypes["ProductsDocAccessFields_referenceId_Create"],
	read?:ValueTypes["ProductsDocAccessFields_referenceId_Read"],
	update?:ValueTypes["ProductsDocAccessFields_referenceId_Update"],
	delete?:ValueTypes["ProductsDocAccessFields_referenceId_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["ProductsDocAccessFields_referenceId_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsDocAccessFields_referenceId_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsDocAccessFields_referenceId_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsDocAccessFields_referenceId_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsDocAccessFields_linkedEvent"]: AliasType<{
	create?:ValueTypes["ProductsDocAccessFields_linkedEvent_Create"],
	read?:ValueTypes["ProductsDocAccessFields_linkedEvent_Read"],
	update?:ValueTypes["ProductsDocAccessFields_linkedEvent_Update"],
	delete?:ValueTypes["ProductsDocAccessFields_linkedEvent_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["ProductsDocAccessFields_linkedEvent_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsDocAccessFields_linkedEvent_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsDocAccessFields_linkedEvent_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsDocAccessFields_linkedEvent_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsDocAccessFields_CheckInLevel"]: AliasType<{
	create?:ValueTypes["ProductsDocAccessFields_CheckInLevel_Create"],
	read?:ValueTypes["ProductsDocAccessFields_CheckInLevel_Read"],
	update?:ValueTypes["ProductsDocAccessFields_CheckInLevel_Update"],
	delete?:ValueTypes["ProductsDocAccessFields_CheckInLevel_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["ProductsDocAccessFields_CheckInLevel_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsDocAccessFields_CheckInLevel_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsDocAccessFields_CheckInLevel_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsDocAccessFields_CheckInLevel_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsDocAccessFields_thumbnail"]: AliasType<{
	create?:ValueTypes["ProductsDocAccessFields_thumbnail_Create"],
	read?:ValueTypes["ProductsDocAccessFields_thumbnail_Read"],
	update?:ValueTypes["ProductsDocAccessFields_thumbnail_Update"],
	delete?:ValueTypes["ProductsDocAccessFields_thumbnail_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["ProductsDocAccessFields_thumbnail_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsDocAccessFields_thumbnail_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsDocAccessFields_thumbnail_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsDocAccessFields_thumbnail_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsDocAccessFields_displayPrice"]: AliasType<{
	create?:ValueTypes["ProductsDocAccessFields_displayPrice_Create"],
	read?:ValueTypes["ProductsDocAccessFields_displayPrice_Read"],
	update?:ValueTypes["ProductsDocAccessFields_displayPrice_Update"],
	delete?:ValueTypes["ProductsDocAccessFields_displayPrice_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["ProductsDocAccessFields_displayPrice_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsDocAccessFields_displayPrice_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsDocAccessFields_displayPrice_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsDocAccessFields_displayPrice_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsDocAccessFields_transactionType"]: AliasType<{
	create?:ValueTypes["ProductsDocAccessFields_transactionType_Create"],
	read?:ValueTypes["ProductsDocAccessFields_transactionType_Read"],
	update?:ValueTypes["ProductsDocAccessFields_transactionType_Update"],
	delete?:ValueTypes["ProductsDocAccessFields_transactionType_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["ProductsDocAccessFields_transactionType_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsDocAccessFields_transactionType_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsDocAccessFields_transactionType_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsDocAccessFields_transactionType_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsDocAccessFields_website"]: AliasType<{
	create?:ValueTypes["ProductsDocAccessFields_website_Create"],
	read?:ValueTypes["ProductsDocAccessFields_website_Read"],
	update?:ValueTypes["ProductsDocAccessFields_website_Update"],
	delete?:ValueTypes["ProductsDocAccessFields_website_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["ProductsDocAccessFields_website_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsDocAccessFields_website_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsDocAccessFields_website_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsDocAccessFields_website_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsDocAccessFields_tnc"]: AliasType<{
	create?:ValueTypes["ProductsDocAccessFields_tnc_Create"],
	read?:ValueTypes["ProductsDocAccessFields_tnc_Read"],
	update?:ValueTypes["ProductsDocAccessFields_tnc_Update"],
	delete?:ValueTypes["ProductsDocAccessFields_tnc_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["ProductsDocAccessFields_tnc_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsDocAccessFields_tnc_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsDocAccessFields_tnc_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsDocAccessFields_tnc_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsDocAccessFields_remarks"]: AliasType<{
	create?:ValueTypes["ProductsDocAccessFields_remarks_Create"],
	read?:ValueTypes["ProductsDocAccessFields_remarks_Read"],
	update?:ValueTypes["ProductsDocAccessFields_remarks_Update"],
	delete?:ValueTypes["ProductsDocAccessFields_remarks_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["ProductsDocAccessFields_remarks_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsDocAccessFields_remarks_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsDocAccessFields_remarks_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsDocAccessFields_remarks_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsDocAccessFields_sessions"]: AliasType<{
	create?:ValueTypes["ProductsDocAccessFields_sessions_Create"],
	read?:ValueTypes["ProductsDocAccessFields_sessions_Read"],
	update?:ValueTypes["ProductsDocAccessFields_sessions_Update"],
	delete?:ValueTypes["ProductsDocAccessFields_sessions_Delete"],
	fields?:ValueTypes["ProductsDocAccessFields_sessions_Fields"],
		__typename?: boolean | `@${string}`
}>;
	["ProductsDocAccessFields_sessions_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsDocAccessFields_sessions_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsDocAccessFields_sessions_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsDocAccessFields_sessions_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsDocAccessFields_sessions_Fields"]: AliasType<{
	productId?:ValueTypes["ProductsDocAccessFields_sessions_productId"],
	name?:ValueTypes["ProductsDocAccessFields_sessions_name"],
	inventories?:ValueTypes["ProductsDocAccessFields_sessions_inventories"],
	id?:ValueTypes["ProductsDocAccessFields_sessions_id"],
		__typename?: boolean | `@${string}`
}>;
	["ProductsDocAccessFields_sessions_productId"]: AliasType<{
	create?:ValueTypes["ProductsDocAccessFields_sessions_productId_Create"],
	read?:ValueTypes["ProductsDocAccessFields_sessions_productId_Read"],
	update?:ValueTypes["ProductsDocAccessFields_sessions_productId_Update"],
	delete?:ValueTypes["ProductsDocAccessFields_sessions_productId_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["ProductsDocAccessFields_sessions_productId_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsDocAccessFields_sessions_productId_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsDocAccessFields_sessions_productId_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsDocAccessFields_sessions_productId_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsDocAccessFields_sessions_name"]: AliasType<{
	create?:ValueTypes["ProductsDocAccessFields_sessions_name_Create"],
	read?:ValueTypes["ProductsDocAccessFields_sessions_name_Read"],
	update?:ValueTypes["ProductsDocAccessFields_sessions_name_Update"],
	delete?:ValueTypes["ProductsDocAccessFields_sessions_name_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["ProductsDocAccessFields_sessions_name_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsDocAccessFields_sessions_name_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsDocAccessFields_sessions_name_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsDocAccessFields_sessions_name_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsDocAccessFields_sessions_inventories"]: AliasType<{
	create?:ValueTypes["ProductsDocAccessFields_sessions_inventories_Create"],
	read?:ValueTypes["ProductsDocAccessFields_sessions_inventories_Read"],
	update?:ValueTypes["ProductsDocAccessFields_sessions_inventories_Update"],
	delete?:ValueTypes["ProductsDocAccessFields_sessions_inventories_Delete"],
	fields?:ValueTypes["ProductsDocAccessFields_sessions_inventories_Fields"],
		__typename?: boolean | `@${string}`
}>;
	["ProductsDocAccessFields_sessions_inventories_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsDocAccessFields_sessions_inventories_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsDocAccessFields_sessions_inventories_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsDocAccessFields_sessions_inventories_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsDocAccessFields_sessions_inventories_Fields"]: AliasType<{
	quantity?:ValueTypes["ProductsDocAccessFields_sessions_inventories_quantity"],
	unitPrice?:ValueTypes["ProductsDocAccessFields_sessions_inventories_unitPrice"],
	handlingFee?:ValueTypes["ProductsDocAccessFields_sessions_inventories_handlingFee"],
	totalCost?:ValueTypes["ProductsDocAccessFields_sessions_inventories_totalCost"],
	minPurchase?:ValueTypes["ProductsDocAccessFields_sessions_inventories_minPurchase"],
	maxPurchase?:ValueTypes["ProductsDocAccessFields_sessions_inventories_maxPurchase"],
	stocks?:ValueTypes["ProductsDocAccessFields_sessions_inventories_stocks"],
		__typename?: boolean | `@${string}`
}>;
	["ProductsDocAccessFields_sessions_inventories_quantity"]: AliasType<{
	create?:ValueTypes["ProductsDocAccessFields_sessions_inventories_quantity_Create"],
	read?:ValueTypes["ProductsDocAccessFields_sessions_inventories_quantity_Read"],
	update?:ValueTypes["ProductsDocAccessFields_sessions_inventories_quantity_Update"],
	delete?:ValueTypes["ProductsDocAccessFields_sessions_inventories_quantity_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["ProductsDocAccessFields_sessions_inventories_quantity_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsDocAccessFields_sessions_inventories_quantity_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsDocAccessFields_sessions_inventories_quantity_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsDocAccessFields_sessions_inventories_quantity_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsDocAccessFields_sessions_inventories_unitPrice"]: AliasType<{
	create?:ValueTypes["ProductsDocAccessFields_sessions_inventories_unitPrice_Create"],
	read?:ValueTypes["ProductsDocAccessFields_sessions_inventories_unitPrice_Read"],
	update?:ValueTypes["ProductsDocAccessFields_sessions_inventories_unitPrice_Update"],
	delete?:ValueTypes["ProductsDocAccessFields_sessions_inventories_unitPrice_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["ProductsDocAccessFields_sessions_inventories_unitPrice_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsDocAccessFields_sessions_inventories_unitPrice_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsDocAccessFields_sessions_inventories_unitPrice_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsDocAccessFields_sessions_inventories_unitPrice_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsDocAccessFields_sessions_inventories_handlingFee"]: AliasType<{
	create?:ValueTypes["ProductsDocAccessFields_sessions_inventories_handlingFee_Create"],
	read?:ValueTypes["ProductsDocAccessFields_sessions_inventories_handlingFee_Read"],
	update?:ValueTypes["ProductsDocAccessFields_sessions_inventories_handlingFee_Update"],
	delete?:ValueTypes["ProductsDocAccessFields_sessions_inventories_handlingFee_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["ProductsDocAccessFields_sessions_inventories_handlingFee_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsDocAccessFields_sessions_inventories_handlingFee_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsDocAccessFields_sessions_inventories_handlingFee_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsDocAccessFields_sessions_inventories_handlingFee_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsDocAccessFields_sessions_inventories_totalCost"]: AliasType<{
	create?:ValueTypes["ProductsDocAccessFields_sessions_inventories_totalCost_Create"],
	read?:ValueTypes["ProductsDocAccessFields_sessions_inventories_totalCost_Read"],
	update?:ValueTypes["ProductsDocAccessFields_sessions_inventories_totalCost_Update"],
	delete?:ValueTypes["ProductsDocAccessFields_sessions_inventories_totalCost_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["ProductsDocAccessFields_sessions_inventories_totalCost_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsDocAccessFields_sessions_inventories_totalCost_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsDocAccessFields_sessions_inventories_totalCost_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsDocAccessFields_sessions_inventories_totalCost_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsDocAccessFields_sessions_inventories_minPurchase"]: AliasType<{
	create?:ValueTypes["ProductsDocAccessFields_sessions_inventories_minPurchase_Create"],
	read?:ValueTypes["ProductsDocAccessFields_sessions_inventories_minPurchase_Read"],
	update?:ValueTypes["ProductsDocAccessFields_sessions_inventories_minPurchase_Update"],
	delete?:ValueTypes["ProductsDocAccessFields_sessions_inventories_minPurchase_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["ProductsDocAccessFields_sessions_inventories_minPurchase_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsDocAccessFields_sessions_inventories_minPurchase_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsDocAccessFields_sessions_inventories_minPurchase_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsDocAccessFields_sessions_inventories_minPurchase_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsDocAccessFields_sessions_inventories_maxPurchase"]: AliasType<{
	create?:ValueTypes["ProductsDocAccessFields_sessions_inventories_maxPurchase_Create"],
	read?:ValueTypes["ProductsDocAccessFields_sessions_inventories_maxPurchase_Read"],
	update?:ValueTypes["ProductsDocAccessFields_sessions_inventories_maxPurchase_Update"],
	delete?:ValueTypes["ProductsDocAccessFields_sessions_inventories_maxPurchase_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["ProductsDocAccessFields_sessions_inventories_maxPurchase_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsDocAccessFields_sessions_inventories_maxPurchase_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsDocAccessFields_sessions_inventories_maxPurchase_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsDocAccessFields_sessions_inventories_maxPurchase_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsDocAccessFields_sessions_inventories_stocks"]: AliasType<{
	create?:ValueTypes["ProductsDocAccessFields_sessions_inventories_stocks_Create"],
	read?:ValueTypes["ProductsDocAccessFields_sessions_inventories_stocks_Read"],
	update?:ValueTypes["ProductsDocAccessFields_sessions_inventories_stocks_Update"],
	delete?:ValueTypes["ProductsDocAccessFields_sessions_inventories_stocks_Delete"],
	fields?:ValueTypes["ProductsDocAccessFields_sessions_inventories_stocks_Fields"],
		__typename?: boolean | `@${string}`
}>;
	["ProductsDocAccessFields_sessions_inventories_stocks_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsDocAccessFields_sessions_inventories_stocks_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsDocAccessFields_sessions_inventories_stocks_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsDocAccessFields_sessions_inventories_stocks_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsDocAccessFields_sessions_inventories_stocks_Fields"]: AliasType<{
	stockId?:ValueTypes["ProductsDocAccessFields_sessions_inventories_stocks_stockId"],
	remaining?:ValueTypes["ProductsDocAccessFields_sessions_inventories_stocks_remaining"],
	sold?:ValueTypes["ProductsDocAccessFields_sessions_inventories_stocks_sold"],
	onHold?:ValueTypes["ProductsDocAccessFields_sessions_inventories_stocks_onHold"],
	checkedIn?:ValueTypes["ProductsDocAccessFields_sessions_inventories_stocks_checkedIn"],
		__typename?: boolean | `@${string}`
}>;
	["ProductsDocAccessFields_sessions_inventories_stocks_stockId"]: AliasType<{
	create?:ValueTypes["ProductsDocAccessFields_sessions_inventories_stocks_stockId_Create"],
	read?:ValueTypes["ProductsDocAccessFields_sessions_inventories_stocks_stockId_Read"],
	update?:ValueTypes["ProductsDocAccessFields_sessions_inventories_stocks_stockId_Update"],
	delete?:ValueTypes["ProductsDocAccessFields_sessions_inventories_stocks_stockId_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["ProductsDocAccessFields_sessions_inventories_stocks_stockId_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsDocAccessFields_sessions_inventories_stocks_stockId_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsDocAccessFields_sessions_inventories_stocks_stockId_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsDocAccessFields_sessions_inventories_stocks_stockId_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsDocAccessFields_sessions_inventories_stocks_remaining"]: AliasType<{
	create?:ValueTypes["ProductsDocAccessFields_sessions_inventories_stocks_remaining_Create"],
	read?:ValueTypes["ProductsDocAccessFields_sessions_inventories_stocks_remaining_Read"],
	update?:ValueTypes["ProductsDocAccessFields_sessions_inventories_stocks_remaining_Update"],
	delete?:ValueTypes["ProductsDocAccessFields_sessions_inventories_stocks_remaining_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["ProductsDocAccessFields_sessions_inventories_stocks_remaining_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsDocAccessFields_sessions_inventories_stocks_remaining_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsDocAccessFields_sessions_inventories_stocks_remaining_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsDocAccessFields_sessions_inventories_stocks_remaining_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsDocAccessFields_sessions_inventories_stocks_sold"]: AliasType<{
	create?:ValueTypes["ProductsDocAccessFields_sessions_inventories_stocks_sold_Create"],
	read?:ValueTypes["ProductsDocAccessFields_sessions_inventories_stocks_sold_Read"],
	update?:ValueTypes["ProductsDocAccessFields_sessions_inventories_stocks_sold_Update"],
	delete?:ValueTypes["ProductsDocAccessFields_sessions_inventories_stocks_sold_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["ProductsDocAccessFields_sessions_inventories_stocks_sold_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsDocAccessFields_sessions_inventories_stocks_sold_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsDocAccessFields_sessions_inventories_stocks_sold_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsDocAccessFields_sessions_inventories_stocks_sold_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsDocAccessFields_sessions_inventories_stocks_onHold"]: AliasType<{
	create?:ValueTypes["ProductsDocAccessFields_sessions_inventories_stocks_onHold_Create"],
	read?:ValueTypes["ProductsDocAccessFields_sessions_inventories_stocks_onHold_Read"],
	update?:ValueTypes["ProductsDocAccessFields_sessions_inventories_stocks_onHold_Update"],
	delete?:ValueTypes["ProductsDocAccessFields_sessions_inventories_stocks_onHold_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["ProductsDocAccessFields_sessions_inventories_stocks_onHold_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsDocAccessFields_sessions_inventories_stocks_onHold_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsDocAccessFields_sessions_inventories_stocks_onHold_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsDocAccessFields_sessions_inventories_stocks_onHold_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsDocAccessFields_sessions_inventories_stocks_checkedIn"]: AliasType<{
	create?:ValueTypes["ProductsDocAccessFields_sessions_inventories_stocks_checkedIn_Create"],
	read?:ValueTypes["ProductsDocAccessFields_sessions_inventories_stocks_checkedIn_Read"],
	update?:ValueTypes["ProductsDocAccessFields_sessions_inventories_stocks_checkedIn_Update"],
	delete?:ValueTypes["ProductsDocAccessFields_sessions_inventories_stocks_checkedIn_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["ProductsDocAccessFields_sessions_inventories_stocks_checkedIn_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsDocAccessFields_sessions_inventories_stocks_checkedIn_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsDocAccessFields_sessions_inventories_stocks_checkedIn_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsDocAccessFields_sessions_inventories_stocks_checkedIn_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsDocAccessFields_sessions_id"]: AliasType<{
	create?:ValueTypes["ProductsDocAccessFields_sessions_id_Create"],
	read?:ValueTypes["ProductsDocAccessFields_sessions_id_Read"],
	update?:ValueTypes["ProductsDocAccessFields_sessions_id_Update"],
	delete?:ValueTypes["ProductsDocAccessFields_sessions_id_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["ProductsDocAccessFields_sessions_id_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsDocAccessFields_sessions_id_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsDocAccessFields_sessions_id_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsDocAccessFields_sessions_id_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsDocAccessFields_updatedAt"]: AliasType<{
	create?:ValueTypes["ProductsDocAccessFields_updatedAt_Create"],
	read?:ValueTypes["ProductsDocAccessFields_updatedAt_Read"],
	update?:ValueTypes["ProductsDocAccessFields_updatedAt_Update"],
	delete?:ValueTypes["ProductsDocAccessFields_updatedAt_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["ProductsDocAccessFields_updatedAt_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsDocAccessFields_updatedAt_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsDocAccessFields_updatedAt_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsDocAccessFields_updatedAt_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsDocAccessFields_createdAt"]: AliasType<{
	create?:ValueTypes["ProductsDocAccessFields_createdAt_Create"],
	read?:ValueTypes["ProductsDocAccessFields_createdAt_Read"],
	update?:ValueTypes["ProductsDocAccessFields_createdAt_Update"],
	delete?:ValueTypes["ProductsDocAccessFields_createdAt_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["ProductsDocAccessFields_createdAt_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsDocAccessFields_createdAt_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsDocAccessFields_createdAt_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsDocAccessFields_createdAt_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsCreateDocAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsReadDocAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsUpdateDocAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsDeleteDocAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["InventoryStatus"]: AliasType<{
	id?:boolean | `@${string}`,
	variantId?:boolean | `@${string}`,
	variantName?:boolean | `@${string}`,
	productType?:boolean | `@${string}`,
	stockUpdateStatus?:boolean | `@${string}`,
	stockUpdateAmount?:boolean | `@${string}`,
user?: [{	locale?: ValueTypes["LocaleInputType"] | undefined | null | Variable<any, string>,	fallbackLocale?: ValueTypes["FallbackLocaleInputType"] | undefined | null | Variable<any, string>},ValueTypes["User"]],
	publicUserPhone?:boolean | `@${string}`,
	expiredAt?:boolean | `@${string}`,
	updatedAt?:boolean | `@${string}`,
	createdAt?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["InventoryStatus_productType"]:InventoryStatus_productType;
	["InventoryStatus_stockUpdateStatus"]:InventoryStatus_stockUpdateStatus;
	["InventoryStatuses"]: AliasType<{
	docs?:ValueTypes["InventoryStatus"],
	hasNextPage?:boolean | `@${string}`,
	hasPrevPage?:boolean | `@${string}`,
	limit?:boolean | `@${string}`,
	nextPage?:boolean | `@${string}`,
	offset?:boolean | `@${string}`,
	page?:boolean | `@${string}`,
	pagingCounter?:boolean | `@${string}`,
	prevPage?:boolean | `@${string}`,
	totalDocs?:boolean | `@${string}`,
	totalPages?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["InventoryStatus_where"]: {
	variantId?: ValueTypes["InventoryStatus_variantId_operator"] | undefined | null | Variable<any, string>,
	variantName?: ValueTypes["InventoryStatus_variantName_operator"] | undefined | null | Variable<any, string>,
	productType?: ValueTypes["InventoryStatus_productType_operator"] | undefined | null | Variable<any, string>,
	stockUpdateStatus?: ValueTypes["InventoryStatus_stockUpdateStatus_operator"] | undefined | null | Variable<any, string>,
	stockUpdateAmount?: ValueTypes["InventoryStatus_stockUpdateAmount_operator"] | undefined | null | Variable<any, string>,
	user?: ValueTypes["InventoryStatus_user_operator"] | undefined | null | Variable<any, string>,
	publicUserPhone?: ValueTypes["InventoryStatus_publicUserPhone_operator"] | undefined | null | Variable<any, string>,
	expiredAt?: ValueTypes["InventoryStatus_expiredAt_operator"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["InventoryStatus_updatedAt_operator"] | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["InventoryStatus_createdAt_operator"] | undefined | null | Variable<any, string>,
	id?: ValueTypes["InventoryStatus_id_operator"] | undefined | null | Variable<any, string>,
	AND?: Array<ValueTypes["InventoryStatus_where_and"] | undefined | null> | undefined | null | Variable<any, string>,
	OR?: Array<ValueTypes["InventoryStatus_where_or"] | undefined | null> | undefined | null | Variable<any, string>
};
	["InventoryStatus_variantId_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["InventoryStatus_variantName_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["InventoryStatus_productType_operator"]: {
	equals?: ValueTypes["InventoryStatus_productType_Input"] | undefined | null | Variable<any, string>,
	not_equals?: ValueTypes["InventoryStatus_productType_Input"] | undefined | null | Variable<any, string>,
	like?: ValueTypes["InventoryStatus_productType_Input"] | undefined | null | Variable<any, string>,
	contains?: ValueTypes["InventoryStatus_productType_Input"] | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["InventoryStatus_productType_Input"]:InventoryStatus_productType_Input;
	["InventoryStatus_stockUpdateStatus_operator"]: {
	equals?: ValueTypes["InventoryStatus_stockUpdateStatus_Input"] | undefined | null | Variable<any, string>,
	not_equals?: ValueTypes["InventoryStatus_stockUpdateStatus_Input"] | undefined | null | Variable<any, string>,
	in?: Array<ValueTypes["InventoryStatus_stockUpdateStatus_Input"] | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<ValueTypes["InventoryStatus_stockUpdateStatus_Input"] | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<ValueTypes["InventoryStatus_stockUpdateStatus_Input"] | undefined | null> | undefined | null | Variable<any, string>
};
	["InventoryStatus_stockUpdateStatus_Input"]:InventoryStatus_stockUpdateStatus_Input;
	["InventoryStatus_stockUpdateAmount_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>
};
	["InventoryStatus_user_operator"]: {
	equals?: ValueTypes["JSON"] | undefined | null | Variable<any, string>,
	not_equals?: ValueTypes["JSON"] | undefined | null | Variable<any, string>,
	in?: Array<ValueTypes["JSON"] | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<ValueTypes["JSON"] | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<ValueTypes["JSON"] | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["InventoryStatus_publicUserPhone_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["InventoryStatus_expiredAt_operator"]: {
	equals?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	not_equals?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	greater_than_equal?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	greater_than?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	less_than_equal?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	less_than?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	like?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["InventoryStatus_updatedAt_operator"]: {
	equals?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	not_equals?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	greater_than_equal?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	greater_than?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	less_than_equal?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	less_than?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	like?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["InventoryStatus_createdAt_operator"]: {
	equals?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	not_equals?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	greater_than_equal?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	greater_than?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	less_than_equal?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	less_than?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	like?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["InventoryStatus_id_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["InventoryStatus_where_and"]: {
	variantId?: ValueTypes["InventoryStatus_variantId_operator"] | undefined | null | Variable<any, string>,
	variantName?: ValueTypes["InventoryStatus_variantName_operator"] | undefined | null | Variable<any, string>,
	productType?: ValueTypes["InventoryStatus_productType_operator"] | undefined | null | Variable<any, string>,
	stockUpdateStatus?: ValueTypes["InventoryStatus_stockUpdateStatus_operator"] | undefined | null | Variable<any, string>,
	stockUpdateAmount?: ValueTypes["InventoryStatus_stockUpdateAmount_operator"] | undefined | null | Variable<any, string>,
	user?: ValueTypes["InventoryStatus_user_operator"] | undefined | null | Variable<any, string>,
	publicUserPhone?: ValueTypes["InventoryStatus_publicUserPhone_operator"] | undefined | null | Variable<any, string>,
	expiredAt?: ValueTypes["InventoryStatus_expiredAt_operator"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["InventoryStatus_updatedAt_operator"] | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["InventoryStatus_createdAt_operator"] | undefined | null | Variable<any, string>,
	id?: ValueTypes["InventoryStatus_id_operator"] | undefined | null | Variable<any, string>,
	AND?: Array<ValueTypes["InventoryStatus_where_and"] | undefined | null> | undefined | null | Variable<any, string>,
	OR?: Array<ValueTypes["InventoryStatus_where_or"] | undefined | null> | undefined | null | Variable<any, string>
};
	["InventoryStatus_where_or"]: {
	variantId?: ValueTypes["InventoryStatus_variantId_operator"] | undefined | null | Variable<any, string>,
	variantName?: ValueTypes["InventoryStatus_variantName_operator"] | undefined | null | Variable<any, string>,
	productType?: ValueTypes["InventoryStatus_productType_operator"] | undefined | null | Variable<any, string>,
	stockUpdateStatus?: ValueTypes["InventoryStatus_stockUpdateStatus_operator"] | undefined | null | Variable<any, string>,
	stockUpdateAmount?: ValueTypes["InventoryStatus_stockUpdateAmount_operator"] | undefined | null | Variable<any, string>,
	user?: ValueTypes["InventoryStatus_user_operator"] | undefined | null | Variable<any, string>,
	publicUserPhone?: ValueTypes["InventoryStatus_publicUserPhone_operator"] | undefined | null | Variable<any, string>,
	expiredAt?: ValueTypes["InventoryStatus_expiredAt_operator"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["InventoryStatus_updatedAt_operator"] | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["InventoryStatus_createdAt_operator"] | undefined | null | Variable<any, string>,
	id?: ValueTypes["InventoryStatus_id_operator"] | undefined | null | Variable<any, string>,
	AND?: Array<ValueTypes["InventoryStatus_where_and"] | undefined | null> | undefined | null | Variable<any, string>,
	OR?: Array<ValueTypes["InventoryStatus_where_or"] | undefined | null> | undefined | null | Variable<any, string>
};
	["countInventoryStatuses"]: AliasType<{
	totalDocs?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["inventory_statusDocAccess"]: AliasType<{
	fields?:ValueTypes["InventoryStatusDocAccessFields"],
	create?:ValueTypes["InventoryStatusCreateDocAccess"],
	read?:ValueTypes["InventoryStatusReadDocAccess"],
	update?:ValueTypes["InventoryStatusUpdateDocAccess"],
	delete?:ValueTypes["InventoryStatusDeleteDocAccess"],
		__typename?: boolean | `@${string}`
}>;
	["InventoryStatusDocAccessFields"]: AliasType<{
	variantId?:ValueTypes["InventoryStatusDocAccessFields_variantId"],
	variantName?:ValueTypes["InventoryStatusDocAccessFields_variantName"],
	productType?:ValueTypes["InventoryStatusDocAccessFields_productType"],
	stockUpdateStatus?:ValueTypes["InventoryStatusDocAccessFields_stockUpdateStatus"],
	stockUpdateAmount?:ValueTypes["InventoryStatusDocAccessFields_stockUpdateAmount"],
	user?:ValueTypes["InventoryStatusDocAccessFields_user"],
	publicUserPhone?:ValueTypes["InventoryStatusDocAccessFields_publicUserPhone"],
	expiredAt?:ValueTypes["InventoryStatusDocAccessFields_expiredAt"],
	updatedAt?:ValueTypes["InventoryStatusDocAccessFields_updatedAt"],
	createdAt?:ValueTypes["InventoryStatusDocAccessFields_createdAt"],
		__typename?: boolean | `@${string}`
}>;
	["InventoryStatusDocAccessFields_variantId"]: AliasType<{
	create?:ValueTypes["InventoryStatusDocAccessFields_variantId_Create"],
	read?:ValueTypes["InventoryStatusDocAccessFields_variantId_Read"],
	update?:ValueTypes["InventoryStatusDocAccessFields_variantId_Update"],
	delete?:ValueTypes["InventoryStatusDocAccessFields_variantId_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["InventoryStatusDocAccessFields_variantId_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["InventoryStatusDocAccessFields_variantId_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["InventoryStatusDocAccessFields_variantId_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["InventoryStatusDocAccessFields_variantId_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["InventoryStatusDocAccessFields_variantName"]: AliasType<{
	create?:ValueTypes["InventoryStatusDocAccessFields_variantName_Create"],
	read?:ValueTypes["InventoryStatusDocAccessFields_variantName_Read"],
	update?:ValueTypes["InventoryStatusDocAccessFields_variantName_Update"],
	delete?:ValueTypes["InventoryStatusDocAccessFields_variantName_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["InventoryStatusDocAccessFields_variantName_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["InventoryStatusDocAccessFields_variantName_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["InventoryStatusDocAccessFields_variantName_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["InventoryStatusDocAccessFields_variantName_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["InventoryStatusDocAccessFields_productType"]: AliasType<{
	create?:ValueTypes["InventoryStatusDocAccessFields_productType_Create"],
	read?:ValueTypes["InventoryStatusDocAccessFields_productType_Read"],
	update?:ValueTypes["InventoryStatusDocAccessFields_productType_Update"],
	delete?:ValueTypes["InventoryStatusDocAccessFields_productType_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["InventoryStatusDocAccessFields_productType_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["InventoryStatusDocAccessFields_productType_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["InventoryStatusDocAccessFields_productType_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["InventoryStatusDocAccessFields_productType_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["InventoryStatusDocAccessFields_stockUpdateStatus"]: AliasType<{
	create?:ValueTypes["InventoryStatusDocAccessFields_stockUpdateStatus_Create"],
	read?:ValueTypes["InventoryStatusDocAccessFields_stockUpdateStatus_Read"],
	update?:ValueTypes["InventoryStatusDocAccessFields_stockUpdateStatus_Update"],
	delete?:ValueTypes["InventoryStatusDocAccessFields_stockUpdateStatus_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["InventoryStatusDocAccessFields_stockUpdateStatus_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["InventoryStatusDocAccessFields_stockUpdateStatus_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["InventoryStatusDocAccessFields_stockUpdateStatus_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["InventoryStatusDocAccessFields_stockUpdateStatus_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["InventoryStatusDocAccessFields_stockUpdateAmount"]: AliasType<{
	create?:ValueTypes["InventoryStatusDocAccessFields_stockUpdateAmount_Create"],
	read?:ValueTypes["InventoryStatusDocAccessFields_stockUpdateAmount_Read"],
	update?:ValueTypes["InventoryStatusDocAccessFields_stockUpdateAmount_Update"],
	delete?:ValueTypes["InventoryStatusDocAccessFields_stockUpdateAmount_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["InventoryStatusDocAccessFields_stockUpdateAmount_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["InventoryStatusDocAccessFields_stockUpdateAmount_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["InventoryStatusDocAccessFields_stockUpdateAmount_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["InventoryStatusDocAccessFields_stockUpdateAmount_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["InventoryStatusDocAccessFields_user"]: AliasType<{
	create?:ValueTypes["InventoryStatusDocAccessFields_user_Create"],
	read?:ValueTypes["InventoryStatusDocAccessFields_user_Read"],
	update?:ValueTypes["InventoryStatusDocAccessFields_user_Update"],
	delete?:ValueTypes["InventoryStatusDocAccessFields_user_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["InventoryStatusDocAccessFields_user_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["InventoryStatusDocAccessFields_user_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["InventoryStatusDocAccessFields_user_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["InventoryStatusDocAccessFields_user_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["InventoryStatusDocAccessFields_publicUserPhone"]: AliasType<{
	create?:ValueTypes["InventoryStatusDocAccessFields_publicUserPhone_Create"],
	read?:ValueTypes["InventoryStatusDocAccessFields_publicUserPhone_Read"],
	update?:ValueTypes["InventoryStatusDocAccessFields_publicUserPhone_Update"],
	delete?:ValueTypes["InventoryStatusDocAccessFields_publicUserPhone_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["InventoryStatusDocAccessFields_publicUserPhone_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["InventoryStatusDocAccessFields_publicUserPhone_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["InventoryStatusDocAccessFields_publicUserPhone_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["InventoryStatusDocAccessFields_publicUserPhone_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["InventoryStatusDocAccessFields_expiredAt"]: AliasType<{
	create?:ValueTypes["InventoryStatusDocAccessFields_expiredAt_Create"],
	read?:ValueTypes["InventoryStatusDocAccessFields_expiredAt_Read"],
	update?:ValueTypes["InventoryStatusDocAccessFields_expiredAt_Update"],
	delete?:ValueTypes["InventoryStatusDocAccessFields_expiredAt_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["InventoryStatusDocAccessFields_expiredAt_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["InventoryStatusDocAccessFields_expiredAt_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["InventoryStatusDocAccessFields_expiredAt_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["InventoryStatusDocAccessFields_expiredAt_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["InventoryStatusDocAccessFields_updatedAt"]: AliasType<{
	create?:ValueTypes["InventoryStatusDocAccessFields_updatedAt_Create"],
	read?:ValueTypes["InventoryStatusDocAccessFields_updatedAt_Read"],
	update?:ValueTypes["InventoryStatusDocAccessFields_updatedAt_Update"],
	delete?:ValueTypes["InventoryStatusDocAccessFields_updatedAt_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["InventoryStatusDocAccessFields_updatedAt_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["InventoryStatusDocAccessFields_updatedAt_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["InventoryStatusDocAccessFields_updatedAt_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["InventoryStatusDocAccessFields_updatedAt_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["InventoryStatusDocAccessFields_createdAt"]: AliasType<{
	create?:ValueTypes["InventoryStatusDocAccessFields_createdAt_Create"],
	read?:ValueTypes["InventoryStatusDocAccessFields_createdAt_Read"],
	update?:ValueTypes["InventoryStatusDocAccessFields_createdAt_Update"],
	delete?:ValueTypes["InventoryStatusDocAccessFields_createdAt_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["InventoryStatusDocAccessFields_createdAt_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["InventoryStatusDocAccessFields_createdAt_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["InventoryStatusDocAccessFields_createdAt_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["InventoryStatusDocAccessFields_createdAt_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["InventoryStatusCreateDocAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["InventoryStatusReadDocAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["InventoryStatusUpdateDocAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["InventoryStatusDeleteDocAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["Transaction"]: AliasType<{
	id?:boolean | `@${string}`,
creator?: [{	locale?: ValueTypes["LocaleInputType"] | undefined | null | Variable<any, string>,	fallbackLocale?: ValueTypes["FallbackLocaleInputType"] | undefined | null | Variable<any, string>},ValueTypes["User"]],
	productName?:boolean | `@${string}`,
	referenceId?:boolean | `@${string}`,
linkedEvent?: [{	locale?: ValueTypes["LocaleInputType"] | undefined | null | Variable<any, string>,	fallbackLocale?: ValueTypes["FallbackLocaleInputType"] | undefined | null | Variable<any, string>},ValueTypes["Event"]],
	purchaseDateTime?:boolean | `@${string}`,
	timableOrderId?:boolean | `@${string}`,
	stripePaymentId?:boolean | `@${string}`,
	totalAmount?:boolean | `@${string}`,
	contactPersonName?:boolean | `@${string}`,
	contactPersonPhone?:boolean | `@${string}`,
	remarks?:boolean | `@${string}`,
	redemptionCodes?:ValueTypes["Transaction_RedemptionCodes"],
	updatedAt?:boolean | `@${string}`,
	createdAt?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["Transaction_RedemptionCodes"]: AliasType<{
	code?:boolean | `@${string}`,
	id?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["Transactions"]: AliasType<{
	docs?:ValueTypes["Transaction"],
	hasNextPage?:boolean | `@${string}`,
	hasPrevPage?:boolean | `@${string}`,
	limit?:boolean | `@${string}`,
	nextPage?:boolean | `@${string}`,
	offset?:boolean | `@${string}`,
	page?:boolean | `@${string}`,
	pagingCounter?:boolean | `@${string}`,
	prevPage?:boolean | `@${string}`,
	totalDocs?:boolean | `@${string}`,
	totalPages?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["Transaction_where"]: {
	creator?: ValueTypes["Transaction_creator_operator"] | undefined | null | Variable<any, string>,
	productName?: ValueTypes["Transaction_productName_operator"] | undefined | null | Variable<any, string>,
	referenceId?: ValueTypes["Transaction_referenceId_operator"] | undefined | null | Variable<any, string>,
	linkedEvent?: ValueTypes["Transaction_linkedEvent_operator"] | undefined | null | Variable<any, string>,
	purchaseDateTime?: ValueTypes["Transaction_purchaseDateTime_operator"] | undefined | null | Variable<any, string>,
	timableOrderId?: ValueTypes["Transaction_timableOrderId_operator"] | undefined | null | Variable<any, string>,
	stripePaymentId?: ValueTypes["Transaction_stripePaymentId_operator"] | undefined | null | Variable<any, string>,
	totalAmount?: ValueTypes["Transaction_totalAmount_operator"] | undefined | null | Variable<any, string>,
	contactPersonName?: ValueTypes["Transaction_contactPersonName_operator"] | undefined | null | Variable<any, string>,
	contactPersonPhone?: ValueTypes["Transaction_contactPersonPhone_operator"] | undefined | null | Variable<any, string>,
	remarks?: ValueTypes["Transaction_remarks_operator"] | undefined | null | Variable<any, string>,
	redemptionCodes__code?: ValueTypes["Transaction_redemptionCodes__code_operator"] | undefined | null | Variable<any, string>,
	redemptionCodes__id?: ValueTypes["Transaction_redemptionCodes__id_operator"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["Transaction_updatedAt_operator"] | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["Transaction_createdAt_operator"] | undefined | null | Variable<any, string>,
	id?: ValueTypes["Transaction_id_operator"] | undefined | null | Variable<any, string>,
	AND?: Array<ValueTypes["Transaction_where_and"] | undefined | null> | undefined | null | Variable<any, string>,
	OR?: Array<ValueTypes["Transaction_where_or"] | undefined | null> | undefined | null | Variable<any, string>
};
	["Transaction_creator_operator"]: {
	equals?: ValueTypes["JSON"] | undefined | null | Variable<any, string>,
	not_equals?: ValueTypes["JSON"] | undefined | null | Variable<any, string>,
	in?: Array<ValueTypes["JSON"] | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<ValueTypes["JSON"] | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<ValueTypes["JSON"] | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Transaction_productName_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Transaction_referenceId_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Transaction_linkedEvent_operator"]: {
	equals?: ValueTypes["JSON"] | undefined | null | Variable<any, string>,
	not_equals?: ValueTypes["JSON"] | undefined | null | Variable<any, string>,
	in?: Array<ValueTypes["JSON"] | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<ValueTypes["JSON"] | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<ValueTypes["JSON"] | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Transaction_purchaseDateTime_operator"]: {
	equals?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	not_equals?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	greater_than_equal?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	greater_than?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	less_than_equal?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	less_than?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	like?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Transaction_timableOrderId_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Transaction_stripePaymentId_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Transaction_totalAmount_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Transaction_contactPersonName_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Transaction_contactPersonPhone_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Transaction_remarks_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Transaction_redemptionCodes__code_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Transaction_redemptionCodes__id_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Transaction_updatedAt_operator"]: {
	equals?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	not_equals?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	greater_than_equal?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	greater_than?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	less_than_equal?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	less_than?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	like?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Transaction_createdAt_operator"]: {
	equals?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	not_equals?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	greater_than_equal?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	greater_than?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	less_than_equal?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	less_than?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	like?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Transaction_id_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Transaction_where_and"]: {
	creator?: ValueTypes["Transaction_creator_operator"] | undefined | null | Variable<any, string>,
	productName?: ValueTypes["Transaction_productName_operator"] | undefined | null | Variable<any, string>,
	referenceId?: ValueTypes["Transaction_referenceId_operator"] | undefined | null | Variable<any, string>,
	linkedEvent?: ValueTypes["Transaction_linkedEvent_operator"] | undefined | null | Variable<any, string>,
	purchaseDateTime?: ValueTypes["Transaction_purchaseDateTime_operator"] | undefined | null | Variable<any, string>,
	timableOrderId?: ValueTypes["Transaction_timableOrderId_operator"] | undefined | null | Variable<any, string>,
	stripePaymentId?: ValueTypes["Transaction_stripePaymentId_operator"] | undefined | null | Variable<any, string>,
	totalAmount?: ValueTypes["Transaction_totalAmount_operator"] | undefined | null | Variable<any, string>,
	contactPersonName?: ValueTypes["Transaction_contactPersonName_operator"] | undefined | null | Variable<any, string>,
	contactPersonPhone?: ValueTypes["Transaction_contactPersonPhone_operator"] | undefined | null | Variable<any, string>,
	remarks?: ValueTypes["Transaction_remarks_operator"] | undefined | null | Variable<any, string>,
	redemptionCodes__code?: ValueTypes["Transaction_redemptionCodes__code_operator"] | undefined | null | Variable<any, string>,
	redemptionCodes__id?: ValueTypes["Transaction_redemptionCodes__id_operator"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["Transaction_updatedAt_operator"] | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["Transaction_createdAt_operator"] | undefined | null | Variable<any, string>,
	id?: ValueTypes["Transaction_id_operator"] | undefined | null | Variable<any, string>,
	AND?: Array<ValueTypes["Transaction_where_and"] | undefined | null> | undefined | null | Variable<any, string>,
	OR?: Array<ValueTypes["Transaction_where_or"] | undefined | null> | undefined | null | Variable<any, string>
};
	["Transaction_where_or"]: {
	creator?: ValueTypes["Transaction_creator_operator"] | undefined | null | Variable<any, string>,
	productName?: ValueTypes["Transaction_productName_operator"] | undefined | null | Variable<any, string>,
	referenceId?: ValueTypes["Transaction_referenceId_operator"] | undefined | null | Variable<any, string>,
	linkedEvent?: ValueTypes["Transaction_linkedEvent_operator"] | undefined | null | Variable<any, string>,
	purchaseDateTime?: ValueTypes["Transaction_purchaseDateTime_operator"] | undefined | null | Variable<any, string>,
	timableOrderId?: ValueTypes["Transaction_timableOrderId_operator"] | undefined | null | Variable<any, string>,
	stripePaymentId?: ValueTypes["Transaction_stripePaymentId_operator"] | undefined | null | Variable<any, string>,
	totalAmount?: ValueTypes["Transaction_totalAmount_operator"] | undefined | null | Variable<any, string>,
	contactPersonName?: ValueTypes["Transaction_contactPersonName_operator"] | undefined | null | Variable<any, string>,
	contactPersonPhone?: ValueTypes["Transaction_contactPersonPhone_operator"] | undefined | null | Variable<any, string>,
	remarks?: ValueTypes["Transaction_remarks_operator"] | undefined | null | Variable<any, string>,
	redemptionCodes__code?: ValueTypes["Transaction_redemptionCodes__code_operator"] | undefined | null | Variable<any, string>,
	redemptionCodes__id?: ValueTypes["Transaction_redemptionCodes__id_operator"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["Transaction_updatedAt_operator"] | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["Transaction_createdAt_operator"] | undefined | null | Variable<any, string>,
	id?: ValueTypes["Transaction_id_operator"] | undefined | null | Variable<any, string>,
	AND?: Array<ValueTypes["Transaction_where_and"] | undefined | null> | undefined | null | Variable<any, string>,
	OR?: Array<ValueTypes["Transaction_where_or"] | undefined | null> | undefined | null | Variable<any, string>
};
	["countTransactions"]: AliasType<{
	totalDocs?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["transactionsDocAccess"]: AliasType<{
	fields?:ValueTypes["TransactionsDocAccessFields"],
	create?:ValueTypes["TransactionsCreateDocAccess"],
	read?:ValueTypes["TransactionsReadDocAccess"],
	update?:ValueTypes["TransactionsUpdateDocAccess"],
	delete?:ValueTypes["TransactionsDeleteDocAccess"],
		__typename?: boolean | `@${string}`
}>;
	["TransactionsDocAccessFields"]: AliasType<{
	creator?:ValueTypes["TransactionsDocAccessFields_creator"],
	productName?:ValueTypes["TransactionsDocAccessFields_productName"],
	referenceId?:ValueTypes["TransactionsDocAccessFields_referenceId"],
	linkedEvent?:ValueTypes["TransactionsDocAccessFields_linkedEvent"],
	purchaseDateTime?:ValueTypes["TransactionsDocAccessFields_purchaseDateTime"],
	timableOrderId?:ValueTypes["TransactionsDocAccessFields_timableOrderId"],
	stripePaymentId?:ValueTypes["TransactionsDocAccessFields_stripePaymentId"],
	totalAmount?:ValueTypes["TransactionsDocAccessFields_totalAmount"],
	contactPersonName?:ValueTypes["TransactionsDocAccessFields_contactPersonName"],
	contactPersonPhone?:ValueTypes["TransactionsDocAccessFields_contactPersonPhone"],
	remarks?:ValueTypes["TransactionsDocAccessFields_remarks"],
	redemptionCodes?:ValueTypes["TransactionsDocAccessFields_redemptionCodes"],
	updatedAt?:ValueTypes["TransactionsDocAccessFields_updatedAt"],
	createdAt?:ValueTypes["TransactionsDocAccessFields_createdAt"],
		__typename?: boolean | `@${string}`
}>;
	["TransactionsDocAccessFields_creator"]: AliasType<{
	create?:ValueTypes["TransactionsDocAccessFields_creator_Create"],
	read?:ValueTypes["TransactionsDocAccessFields_creator_Read"],
	update?:ValueTypes["TransactionsDocAccessFields_creator_Update"],
	delete?:ValueTypes["TransactionsDocAccessFields_creator_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["TransactionsDocAccessFields_creator_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TransactionsDocAccessFields_creator_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TransactionsDocAccessFields_creator_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TransactionsDocAccessFields_creator_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TransactionsDocAccessFields_productName"]: AliasType<{
	create?:ValueTypes["TransactionsDocAccessFields_productName_Create"],
	read?:ValueTypes["TransactionsDocAccessFields_productName_Read"],
	update?:ValueTypes["TransactionsDocAccessFields_productName_Update"],
	delete?:ValueTypes["TransactionsDocAccessFields_productName_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["TransactionsDocAccessFields_productName_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TransactionsDocAccessFields_productName_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TransactionsDocAccessFields_productName_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TransactionsDocAccessFields_productName_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TransactionsDocAccessFields_referenceId"]: AliasType<{
	create?:ValueTypes["TransactionsDocAccessFields_referenceId_Create"],
	read?:ValueTypes["TransactionsDocAccessFields_referenceId_Read"],
	update?:ValueTypes["TransactionsDocAccessFields_referenceId_Update"],
	delete?:ValueTypes["TransactionsDocAccessFields_referenceId_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["TransactionsDocAccessFields_referenceId_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TransactionsDocAccessFields_referenceId_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TransactionsDocAccessFields_referenceId_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TransactionsDocAccessFields_referenceId_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TransactionsDocAccessFields_linkedEvent"]: AliasType<{
	create?:ValueTypes["TransactionsDocAccessFields_linkedEvent_Create"],
	read?:ValueTypes["TransactionsDocAccessFields_linkedEvent_Read"],
	update?:ValueTypes["TransactionsDocAccessFields_linkedEvent_Update"],
	delete?:ValueTypes["TransactionsDocAccessFields_linkedEvent_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["TransactionsDocAccessFields_linkedEvent_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TransactionsDocAccessFields_linkedEvent_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TransactionsDocAccessFields_linkedEvent_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TransactionsDocAccessFields_linkedEvent_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TransactionsDocAccessFields_purchaseDateTime"]: AliasType<{
	create?:ValueTypes["TransactionsDocAccessFields_purchaseDateTime_Create"],
	read?:ValueTypes["TransactionsDocAccessFields_purchaseDateTime_Read"],
	update?:ValueTypes["TransactionsDocAccessFields_purchaseDateTime_Update"],
	delete?:ValueTypes["TransactionsDocAccessFields_purchaseDateTime_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["TransactionsDocAccessFields_purchaseDateTime_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TransactionsDocAccessFields_purchaseDateTime_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TransactionsDocAccessFields_purchaseDateTime_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TransactionsDocAccessFields_purchaseDateTime_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TransactionsDocAccessFields_timableOrderId"]: AliasType<{
	create?:ValueTypes["TransactionsDocAccessFields_timableOrderId_Create"],
	read?:ValueTypes["TransactionsDocAccessFields_timableOrderId_Read"],
	update?:ValueTypes["TransactionsDocAccessFields_timableOrderId_Update"],
	delete?:ValueTypes["TransactionsDocAccessFields_timableOrderId_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["TransactionsDocAccessFields_timableOrderId_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TransactionsDocAccessFields_timableOrderId_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TransactionsDocAccessFields_timableOrderId_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TransactionsDocAccessFields_timableOrderId_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TransactionsDocAccessFields_stripePaymentId"]: AliasType<{
	create?:ValueTypes["TransactionsDocAccessFields_stripePaymentId_Create"],
	read?:ValueTypes["TransactionsDocAccessFields_stripePaymentId_Read"],
	update?:ValueTypes["TransactionsDocAccessFields_stripePaymentId_Update"],
	delete?:ValueTypes["TransactionsDocAccessFields_stripePaymentId_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["TransactionsDocAccessFields_stripePaymentId_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TransactionsDocAccessFields_stripePaymentId_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TransactionsDocAccessFields_stripePaymentId_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TransactionsDocAccessFields_stripePaymentId_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TransactionsDocAccessFields_totalAmount"]: AliasType<{
	create?:ValueTypes["TransactionsDocAccessFields_totalAmount_Create"],
	read?:ValueTypes["TransactionsDocAccessFields_totalAmount_Read"],
	update?:ValueTypes["TransactionsDocAccessFields_totalAmount_Update"],
	delete?:ValueTypes["TransactionsDocAccessFields_totalAmount_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["TransactionsDocAccessFields_totalAmount_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TransactionsDocAccessFields_totalAmount_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TransactionsDocAccessFields_totalAmount_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TransactionsDocAccessFields_totalAmount_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TransactionsDocAccessFields_contactPersonName"]: AliasType<{
	create?:ValueTypes["TransactionsDocAccessFields_contactPersonName_Create"],
	read?:ValueTypes["TransactionsDocAccessFields_contactPersonName_Read"],
	update?:ValueTypes["TransactionsDocAccessFields_contactPersonName_Update"],
	delete?:ValueTypes["TransactionsDocAccessFields_contactPersonName_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["TransactionsDocAccessFields_contactPersonName_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TransactionsDocAccessFields_contactPersonName_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TransactionsDocAccessFields_contactPersonName_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TransactionsDocAccessFields_contactPersonName_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TransactionsDocAccessFields_contactPersonPhone"]: AliasType<{
	create?:ValueTypes["TransactionsDocAccessFields_contactPersonPhone_Create"],
	read?:ValueTypes["TransactionsDocAccessFields_contactPersonPhone_Read"],
	update?:ValueTypes["TransactionsDocAccessFields_contactPersonPhone_Update"],
	delete?:ValueTypes["TransactionsDocAccessFields_contactPersonPhone_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["TransactionsDocAccessFields_contactPersonPhone_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TransactionsDocAccessFields_contactPersonPhone_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TransactionsDocAccessFields_contactPersonPhone_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TransactionsDocAccessFields_contactPersonPhone_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TransactionsDocAccessFields_remarks"]: AliasType<{
	create?:ValueTypes["TransactionsDocAccessFields_remarks_Create"],
	read?:ValueTypes["TransactionsDocAccessFields_remarks_Read"],
	update?:ValueTypes["TransactionsDocAccessFields_remarks_Update"],
	delete?:ValueTypes["TransactionsDocAccessFields_remarks_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["TransactionsDocAccessFields_remarks_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TransactionsDocAccessFields_remarks_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TransactionsDocAccessFields_remarks_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TransactionsDocAccessFields_remarks_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TransactionsDocAccessFields_redemptionCodes"]: AliasType<{
	create?:ValueTypes["TransactionsDocAccessFields_redemptionCodes_Create"],
	read?:ValueTypes["TransactionsDocAccessFields_redemptionCodes_Read"],
	update?:ValueTypes["TransactionsDocAccessFields_redemptionCodes_Update"],
	delete?:ValueTypes["TransactionsDocAccessFields_redemptionCodes_Delete"],
	fields?:ValueTypes["TransactionsDocAccessFields_redemptionCodes_Fields"],
		__typename?: boolean | `@${string}`
}>;
	["TransactionsDocAccessFields_redemptionCodes_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TransactionsDocAccessFields_redemptionCodes_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TransactionsDocAccessFields_redemptionCodes_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TransactionsDocAccessFields_redemptionCodes_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TransactionsDocAccessFields_redemptionCodes_Fields"]: AliasType<{
	code?:ValueTypes["TransactionsDocAccessFields_redemptionCodes_code"],
	id?:ValueTypes["TransactionsDocAccessFields_redemptionCodes_id"],
		__typename?: boolean | `@${string}`
}>;
	["TransactionsDocAccessFields_redemptionCodes_code"]: AliasType<{
	create?:ValueTypes["TransactionsDocAccessFields_redemptionCodes_code_Create"],
	read?:ValueTypes["TransactionsDocAccessFields_redemptionCodes_code_Read"],
	update?:ValueTypes["TransactionsDocAccessFields_redemptionCodes_code_Update"],
	delete?:ValueTypes["TransactionsDocAccessFields_redemptionCodes_code_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["TransactionsDocAccessFields_redemptionCodes_code_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TransactionsDocAccessFields_redemptionCodes_code_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TransactionsDocAccessFields_redemptionCodes_code_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TransactionsDocAccessFields_redemptionCodes_code_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TransactionsDocAccessFields_redemptionCodes_id"]: AliasType<{
	create?:ValueTypes["TransactionsDocAccessFields_redemptionCodes_id_Create"],
	read?:ValueTypes["TransactionsDocAccessFields_redemptionCodes_id_Read"],
	update?:ValueTypes["TransactionsDocAccessFields_redemptionCodes_id_Update"],
	delete?:ValueTypes["TransactionsDocAccessFields_redemptionCodes_id_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["TransactionsDocAccessFields_redemptionCodes_id_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TransactionsDocAccessFields_redemptionCodes_id_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TransactionsDocAccessFields_redemptionCodes_id_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TransactionsDocAccessFields_redemptionCodes_id_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TransactionsDocAccessFields_updatedAt"]: AliasType<{
	create?:ValueTypes["TransactionsDocAccessFields_updatedAt_Create"],
	read?:ValueTypes["TransactionsDocAccessFields_updatedAt_Read"],
	update?:ValueTypes["TransactionsDocAccessFields_updatedAt_Update"],
	delete?:ValueTypes["TransactionsDocAccessFields_updatedAt_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["TransactionsDocAccessFields_updatedAt_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TransactionsDocAccessFields_updatedAt_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TransactionsDocAccessFields_updatedAt_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TransactionsDocAccessFields_updatedAt_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TransactionsDocAccessFields_createdAt"]: AliasType<{
	create?:ValueTypes["TransactionsDocAccessFields_createdAt_Create"],
	read?:ValueTypes["TransactionsDocAccessFields_createdAt_Read"],
	update?:ValueTypes["TransactionsDocAccessFields_createdAt_Update"],
	delete?:ValueTypes["TransactionsDocAccessFields_createdAt_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["TransactionsDocAccessFields_createdAt_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TransactionsDocAccessFields_createdAt_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TransactionsDocAccessFields_createdAt_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TransactionsDocAccessFields_createdAt_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TransactionsCreateDocAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TransactionsReadDocAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TransactionsUpdateDocAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TransactionsDeleteDocAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCode"]: AliasType<{
	id?:boolean | `@${string}`,
creator?: [{	locale?: ValueTypes["LocaleInputType"] | undefined | null | Variable<any, string>,	fallbackLocale?: ValueTypes["FallbackLocaleInputType"] | undefined | null | Variable<any, string>},ValueTypes["User"]],
	status?:boolean | `@${string}`,
	qrCodeId?:boolean | `@${string}`,
	lastCheckedInDateTime?:boolean | `@${string}`,
	voidedDateTime?:boolean | `@${string}`,
	refundedDateTime?:boolean | `@${string}`,
	eventStartTime?:boolean | `@${string}`,
	eventEndTime?:boolean | `@${string}`,
	eventPriceZone?:boolean | `@${string}`,
	ownerName?:boolean | `@${string}`,
	phone?:boolean | `@${string}`,
pdf?: [{	locale?: ValueTypes["LocaleInputType"] | undefined | null | Variable<any, string>,	fallbackLocale?: ValueTypes["FallbackLocaleInputType"] | undefined | null | Variable<any, string>},ValueTypes["RedemptionPdf"]],
	variants?:ValueTypes["RedemptionCode_Variants"],
	productId?:boolean | `@${string}`,
	referenceId?:boolean | `@${string}`,
	productType?:boolean | `@${string}`,
	productName?:boolean | `@${string}`,
linkedEvent?: [{	locale?: ValueTypes["LocaleInputType"] | undefined | null | Variable<any, string>,	fallbackLocale?: ValueTypes["FallbackLocaleInputType"] | undefined | null | Variable<any, string>},ValueTypes["Event"]],
	timableOrderId?:boolean | `@${string}`,
	purchaseDateTime?:boolean | `@${string}`,
	changeHistory?:ValueTypes["RedemptionCode_ChangeHistory"],
	updatedAt?:boolean | `@${string}`,
	createdAt?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCode_status"]:RedemptionCode_status;
	["RedemptionPdf"]: AliasType<{
	id?:boolean | `@${string}`,
	updatedAt?:boolean | `@${string}`,
	createdAt?:boolean | `@${string}`,
	url?:boolean | `@${string}`,
	filename?:boolean | `@${string}`,
	mimeType?:boolean | `@${string}`,
	filesize?:boolean | `@${string}`,
	width?:boolean | `@${string}`,
	height?:boolean | `@${string}`,
	focalX?:boolean | `@${string}`,
	focalY?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCode_Variants"]: AliasType<{
	variantId?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	quantity?:boolean | `@${string}`,
	totalCost?:boolean | `@${string}`,
	id?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCode_productType"]:RedemptionCode_productType;
	["RedemptionCode_ChangeHistory"]: AliasType<{
	timestamp?:boolean | `@${string}`,
	field?:boolean | `@${string}`,
	oldValue?:boolean | `@${string}`,
	newValue?:boolean | `@${string}`,
	id?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodes"]: AliasType<{
	docs?:ValueTypes["RedemptionCode"],
	hasNextPage?:boolean | `@${string}`,
	hasPrevPage?:boolean | `@${string}`,
	limit?:boolean | `@${string}`,
	nextPage?:boolean | `@${string}`,
	offset?:boolean | `@${string}`,
	page?:boolean | `@${string}`,
	pagingCounter?:boolean | `@${string}`,
	prevPage?:boolean | `@${string}`,
	totalDocs?:boolean | `@${string}`,
	totalPages?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCode_where"]: {
	creator?: ValueTypes["RedemptionCode_creator_operator"] | undefined | null | Variable<any, string>,
	status?: ValueTypes["RedemptionCode_status_operator"] | undefined | null | Variable<any, string>,
	qrCodeId?: ValueTypes["RedemptionCode_qrCodeId_operator"] | undefined | null | Variable<any, string>,
	lastCheckedInDateTime?: ValueTypes["RedemptionCode_lastCheckedInDateTime_operator"] | undefined | null | Variable<any, string>,
	voidedDateTime?: ValueTypes["RedemptionCode_voidedDateTime_operator"] | undefined | null | Variable<any, string>,
	refundedDateTime?: ValueTypes["RedemptionCode_refundedDateTime_operator"] | undefined | null | Variable<any, string>,
	eventStartTime?: ValueTypes["RedemptionCode_eventStartTime_operator"] | undefined | null | Variable<any, string>,
	eventEndTime?: ValueTypes["RedemptionCode_eventEndTime_operator"] | undefined | null | Variable<any, string>,
	eventPriceZone?: ValueTypes["RedemptionCode_eventPriceZone_operator"] | undefined | null | Variable<any, string>,
	ownerName?: ValueTypes["RedemptionCode_ownerName_operator"] | undefined | null | Variable<any, string>,
	phone?: ValueTypes["RedemptionCode_phone_operator"] | undefined | null | Variable<any, string>,
	pdf?: ValueTypes["RedemptionCode_pdf_operator"] | undefined | null | Variable<any, string>,
	variants__variantId?: ValueTypes["RedemptionCode_variants__variantId_operator"] | undefined | null | Variable<any, string>,
	variants__name?: ValueTypes["RedemptionCode_variants__name_operator"] | undefined | null | Variable<any, string>,
	variants__quantity?: ValueTypes["RedemptionCode_variants__quantity_operator"] | undefined | null | Variable<any, string>,
	variants__totalCost?: ValueTypes["RedemptionCode_variants__totalCost_operator"] | undefined | null | Variable<any, string>,
	variants__id?: ValueTypes["RedemptionCode_variants__id_operator"] | undefined | null | Variable<any, string>,
	productId?: ValueTypes["RedemptionCode_productId_operator"] | undefined | null | Variable<any, string>,
	referenceId?: ValueTypes["RedemptionCode_referenceId_operator"] | undefined | null | Variable<any, string>,
	productType?: ValueTypes["RedemptionCode_productType_operator"] | undefined | null | Variable<any, string>,
	productName?: ValueTypes["RedemptionCode_productName_operator"] | undefined | null | Variable<any, string>,
	linkedEvent?: ValueTypes["RedemptionCode_linkedEvent_operator"] | undefined | null | Variable<any, string>,
	timableOrderId?: ValueTypes["RedemptionCode_timableOrderId_operator"] | undefined | null | Variable<any, string>,
	purchaseDateTime?: ValueTypes["RedemptionCode_purchaseDateTime_operator"] | undefined | null | Variable<any, string>,
	changeHistory__timestamp?: ValueTypes["RedemptionCode_changeHistory__timestamp_operator"] | undefined | null | Variable<any, string>,
	changeHistory__field?: ValueTypes["RedemptionCode_changeHistory__field_operator"] | undefined | null | Variable<any, string>,
	changeHistory__oldValue?: ValueTypes["RedemptionCode_changeHistory__oldValue_operator"] | undefined | null | Variable<any, string>,
	changeHistory__newValue?: ValueTypes["RedemptionCode_changeHistory__newValue_operator"] | undefined | null | Variable<any, string>,
	changeHistory__id?: ValueTypes["RedemptionCode_changeHistory__id_operator"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["RedemptionCode_updatedAt_operator"] | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["RedemptionCode_createdAt_operator"] | undefined | null | Variable<any, string>,
	id?: ValueTypes["RedemptionCode_id_operator"] | undefined | null | Variable<any, string>,
	AND?: Array<ValueTypes["RedemptionCode_where_and"] | undefined | null> | undefined | null | Variable<any, string>,
	OR?: Array<ValueTypes["RedemptionCode_where_or"] | undefined | null> | undefined | null | Variable<any, string>
};
	["RedemptionCode_creator_operator"]: {
	equals?: ValueTypes["JSON"] | undefined | null | Variable<any, string>,
	not_equals?: ValueTypes["JSON"] | undefined | null | Variable<any, string>,
	in?: Array<ValueTypes["JSON"] | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<ValueTypes["JSON"] | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<ValueTypes["JSON"] | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["RedemptionCode_status_operator"]: {
	equals?: ValueTypes["RedemptionCode_status_Input"] | undefined | null | Variable<any, string>,
	not_equals?: ValueTypes["RedemptionCode_status_Input"] | undefined | null | Variable<any, string>,
	in?: Array<ValueTypes["RedemptionCode_status_Input"] | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<ValueTypes["RedemptionCode_status_Input"] | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<ValueTypes["RedemptionCode_status_Input"] | undefined | null> | undefined | null | Variable<any, string>
};
	["RedemptionCode_status_Input"]:RedemptionCode_status_Input;
	["RedemptionCode_qrCodeId_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["RedemptionCode_lastCheckedInDateTime_operator"]: {
	equals?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	not_equals?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	greater_than_equal?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	greater_than?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	less_than_equal?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	less_than?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	like?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["RedemptionCode_voidedDateTime_operator"]: {
	equals?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	not_equals?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	greater_than_equal?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	greater_than?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	less_than_equal?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	less_than?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	like?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["RedemptionCode_refundedDateTime_operator"]: {
	equals?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	not_equals?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	greater_than_equal?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	greater_than?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	less_than_equal?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	less_than?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	like?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["RedemptionCode_eventStartTime_operator"]: {
	equals?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	not_equals?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	greater_than_equal?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	greater_than?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	less_than_equal?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	less_than?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	like?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["RedemptionCode_eventEndTime_operator"]: {
	equals?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	not_equals?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	greater_than_equal?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	greater_than?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	less_than_equal?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	less_than?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	like?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["RedemptionCode_eventPriceZone_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["RedemptionCode_ownerName_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>
};
	["RedemptionCode_phone_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>
};
	["RedemptionCode_pdf_operator"]: {
	equals?: ValueTypes["JSON"] | undefined | null | Variable<any, string>,
	not_equals?: ValueTypes["JSON"] | undefined | null | Variable<any, string>,
	in?: Array<ValueTypes["JSON"] | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<ValueTypes["JSON"] | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<ValueTypes["JSON"] | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["RedemptionCode_variants__variantId_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["RedemptionCode_variants__name_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["RedemptionCode_variants__quantity_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["RedemptionCode_variants__totalCost_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["RedemptionCode_variants__id_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["RedemptionCode_productId_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["RedemptionCode_referenceId_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["RedemptionCode_productType_operator"]: {
	equals?: ValueTypes["RedemptionCode_productType_Input"] | undefined | null | Variable<any, string>,
	not_equals?: ValueTypes["RedemptionCode_productType_Input"] | undefined | null | Variable<any, string>,
	like?: ValueTypes["RedemptionCode_productType_Input"] | undefined | null | Variable<any, string>,
	contains?: ValueTypes["RedemptionCode_productType_Input"] | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["RedemptionCode_productType_Input"]:RedemptionCode_productType_Input;
	["RedemptionCode_productName_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["RedemptionCode_linkedEvent_operator"]: {
	equals?: ValueTypes["JSON"] | undefined | null | Variable<any, string>,
	not_equals?: ValueTypes["JSON"] | undefined | null | Variable<any, string>,
	in?: Array<ValueTypes["JSON"] | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<ValueTypes["JSON"] | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<ValueTypes["JSON"] | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["RedemptionCode_timableOrderId_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["RedemptionCode_purchaseDateTime_operator"]: {
	equals?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	not_equals?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	greater_than_equal?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	greater_than?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	less_than_equal?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	less_than?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	like?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["RedemptionCode_changeHistory__timestamp_operator"]: {
	equals?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	not_equals?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	greater_than_equal?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	greater_than?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	less_than_equal?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	less_than?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	like?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>
};
	["RedemptionCode_changeHistory__field_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>
};
	["RedemptionCode_changeHistory__oldValue_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>
};
	["RedemptionCode_changeHistory__newValue_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>
};
	["RedemptionCode_changeHistory__id_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["RedemptionCode_updatedAt_operator"]: {
	equals?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	not_equals?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	greater_than_equal?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	greater_than?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	less_than_equal?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	less_than?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	like?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["RedemptionCode_createdAt_operator"]: {
	equals?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	not_equals?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	greater_than_equal?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	greater_than?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	less_than_equal?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	less_than?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	like?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["RedemptionCode_id_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["RedemptionCode_where_and"]: {
	creator?: ValueTypes["RedemptionCode_creator_operator"] | undefined | null | Variable<any, string>,
	status?: ValueTypes["RedemptionCode_status_operator"] | undefined | null | Variable<any, string>,
	qrCodeId?: ValueTypes["RedemptionCode_qrCodeId_operator"] | undefined | null | Variable<any, string>,
	lastCheckedInDateTime?: ValueTypes["RedemptionCode_lastCheckedInDateTime_operator"] | undefined | null | Variable<any, string>,
	voidedDateTime?: ValueTypes["RedemptionCode_voidedDateTime_operator"] | undefined | null | Variable<any, string>,
	refundedDateTime?: ValueTypes["RedemptionCode_refundedDateTime_operator"] | undefined | null | Variable<any, string>,
	eventStartTime?: ValueTypes["RedemptionCode_eventStartTime_operator"] | undefined | null | Variable<any, string>,
	eventEndTime?: ValueTypes["RedemptionCode_eventEndTime_operator"] | undefined | null | Variable<any, string>,
	eventPriceZone?: ValueTypes["RedemptionCode_eventPriceZone_operator"] | undefined | null | Variable<any, string>,
	ownerName?: ValueTypes["RedemptionCode_ownerName_operator"] | undefined | null | Variable<any, string>,
	phone?: ValueTypes["RedemptionCode_phone_operator"] | undefined | null | Variable<any, string>,
	pdf?: ValueTypes["RedemptionCode_pdf_operator"] | undefined | null | Variable<any, string>,
	variants__variantId?: ValueTypes["RedemptionCode_variants__variantId_operator"] | undefined | null | Variable<any, string>,
	variants__name?: ValueTypes["RedemptionCode_variants__name_operator"] | undefined | null | Variable<any, string>,
	variants__quantity?: ValueTypes["RedemptionCode_variants__quantity_operator"] | undefined | null | Variable<any, string>,
	variants__totalCost?: ValueTypes["RedemptionCode_variants__totalCost_operator"] | undefined | null | Variable<any, string>,
	variants__id?: ValueTypes["RedemptionCode_variants__id_operator"] | undefined | null | Variable<any, string>,
	productId?: ValueTypes["RedemptionCode_productId_operator"] | undefined | null | Variable<any, string>,
	referenceId?: ValueTypes["RedemptionCode_referenceId_operator"] | undefined | null | Variable<any, string>,
	productType?: ValueTypes["RedemptionCode_productType_operator"] | undefined | null | Variable<any, string>,
	productName?: ValueTypes["RedemptionCode_productName_operator"] | undefined | null | Variable<any, string>,
	linkedEvent?: ValueTypes["RedemptionCode_linkedEvent_operator"] | undefined | null | Variable<any, string>,
	timableOrderId?: ValueTypes["RedemptionCode_timableOrderId_operator"] | undefined | null | Variable<any, string>,
	purchaseDateTime?: ValueTypes["RedemptionCode_purchaseDateTime_operator"] | undefined | null | Variable<any, string>,
	changeHistory__timestamp?: ValueTypes["RedemptionCode_changeHistory__timestamp_operator"] | undefined | null | Variable<any, string>,
	changeHistory__field?: ValueTypes["RedemptionCode_changeHistory__field_operator"] | undefined | null | Variable<any, string>,
	changeHistory__oldValue?: ValueTypes["RedemptionCode_changeHistory__oldValue_operator"] | undefined | null | Variable<any, string>,
	changeHistory__newValue?: ValueTypes["RedemptionCode_changeHistory__newValue_operator"] | undefined | null | Variable<any, string>,
	changeHistory__id?: ValueTypes["RedemptionCode_changeHistory__id_operator"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["RedemptionCode_updatedAt_operator"] | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["RedemptionCode_createdAt_operator"] | undefined | null | Variable<any, string>,
	id?: ValueTypes["RedemptionCode_id_operator"] | undefined | null | Variable<any, string>,
	AND?: Array<ValueTypes["RedemptionCode_where_and"] | undefined | null> | undefined | null | Variable<any, string>,
	OR?: Array<ValueTypes["RedemptionCode_where_or"] | undefined | null> | undefined | null | Variable<any, string>
};
	["RedemptionCode_where_or"]: {
	creator?: ValueTypes["RedemptionCode_creator_operator"] | undefined | null | Variable<any, string>,
	status?: ValueTypes["RedemptionCode_status_operator"] | undefined | null | Variable<any, string>,
	qrCodeId?: ValueTypes["RedemptionCode_qrCodeId_operator"] | undefined | null | Variable<any, string>,
	lastCheckedInDateTime?: ValueTypes["RedemptionCode_lastCheckedInDateTime_operator"] | undefined | null | Variable<any, string>,
	voidedDateTime?: ValueTypes["RedemptionCode_voidedDateTime_operator"] | undefined | null | Variable<any, string>,
	refundedDateTime?: ValueTypes["RedemptionCode_refundedDateTime_operator"] | undefined | null | Variable<any, string>,
	eventStartTime?: ValueTypes["RedemptionCode_eventStartTime_operator"] | undefined | null | Variable<any, string>,
	eventEndTime?: ValueTypes["RedemptionCode_eventEndTime_operator"] | undefined | null | Variable<any, string>,
	eventPriceZone?: ValueTypes["RedemptionCode_eventPriceZone_operator"] | undefined | null | Variable<any, string>,
	ownerName?: ValueTypes["RedemptionCode_ownerName_operator"] | undefined | null | Variable<any, string>,
	phone?: ValueTypes["RedemptionCode_phone_operator"] | undefined | null | Variable<any, string>,
	pdf?: ValueTypes["RedemptionCode_pdf_operator"] | undefined | null | Variable<any, string>,
	variants__variantId?: ValueTypes["RedemptionCode_variants__variantId_operator"] | undefined | null | Variable<any, string>,
	variants__name?: ValueTypes["RedemptionCode_variants__name_operator"] | undefined | null | Variable<any, string>,
	variants__quantity?: ValueTypes["RedemptionCode_variants__quantity_operator"] | undefined | null | Variable<any, string>,
	variants__totalCost?: ValueTypes["RedemptionCode_variants__totalCost_operator"] | undefined | null | Variable<any, string>,
	variants__id?: ValueTypes["RedemptionCode_variants__id_operator"] | undefined | null | Variable<any, string>,
	productId?: ValueTypes["RedemptionCode_productId_operator"] | undefined | null | Variable<any, string>,
	referenceId?: ValueTypes["RedemptionCode_referenceId_operator"] | undefined | null | Variable<any, string>,
	productType?: ValueTypes["RedemptionCode_productType_operator"] | undefined | null | Variable<any, string>,
	productName?: ValueTypes["RedemptionCode_productName_operator"] | undefined | null | Variable<any, string>,
	linkedEvent?: ValueTypes["RedemptionCode_linkedEvent_operator"] | undefined | null | Variable<any, string>,
	timableOrderId?: ValueTypes["RedemptionCode_timableOrderId_operator"] | undefined | null | Variable<any, string>,
	purchaseDateTime?: ValueTypes["RedemptionCode_purchaseDateTime_operator"] | undefined | null | Variable<any, string>,
	changeHistory__timestamp?: ValueTypes["RedemptionCode_changeHistory__timestamp_operator"] | undefined | null | Variable<any, string>,
	changeHistory__field?: ValueTypes["RedemptionCode_changeHistory__field_operator"] | undefined | null | Variable<any, string>,
	changeHistory__oldValue?: ValueTypes["RedemptionCode_changeHistory__oldValue_operator"] | undefined | null | Variable<any, string>,
	changeHistory__newValue?: ValueTypes["RedemptionCode_changeHistory__newValue_operator"] | undefined | null | Variable<any, string>,
	changeHistory__id?: ValueTypes["RedemptionCode_changeHistory__id_operator"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["RedemptionCode_updatedAt_operator"] | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["RedemptionCode_createdAt_operator"] | undefined | null | Variable<any, string>,
	id?: ValueTypes["RedemptionCode_id_operator"] | undefined | null | Variable<any, string>,
	AND?: Array<ValueTypes["RedemptionCode_where_and"] | undefined | null> | undefined | null | Variable<any, string>,
	OR?: Array<ValueTypes["RedemptionCode_where_or"] | undefined | null> | undefined | null | Variable<any, string>
};
	["countRedemptionCodes"]: AliasType<{
	totalDocs?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["redemption_codesDocAccess"]: AliasType<{
	fields?:ValueTypes["RedemptionCodesDocAccessFields"],
	create?:ValueTypes["RedemptionCodesCreateDocAccess"],
	read?:ValueTypes["RedemptionCodesReadDocAccess"],
	update?:ValueTypes["RedemptionCodesUpdateDocAccess"],
	delete?:ValueTypes["RedemptionCodesDeleteDocAccess"],
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesDocAccessFields"]: AliasType<{
	creator?:ValueTypes["RedemptionCodesDocAccessFields_creator"],
	status?:ValueTypes["RedemptionCodesDocAccessFields_status"],
	qrCodeId?:ValueTypes["RedemptionCodesDocAccessFields_qrCodeId"],
	lastCheckedInDateTime?:ValueTypes["RedemptionCodesDocAccessFields_lastCheckedInDateTime"],
	voidedDateTime?:ValueTypes["RedemptionCodesDocAccessFields_voidedDateTime"],
	refundedDateTime?:ValueTypes["RedemptionCodesDocAccessFields_refundedDateTime"],
	eventStartTime?:ValueTypes["RedemptionCodesDocAccessFields_eventStartTime"],
	eventEndTime?:ValueTypes["RedemptionCodesDocAccessFields_eventEndTime"],
	eventPriceZone?:ValueTypes["RedemptionCodesDocAccessFields_eventPriceZone"],
	ownerName?:ValueTypes["RedemptionCodesDocAccessFields_ownerName"],
	phone?:ValueTypes["RedemptionCodesDocAccessFields_phone"],
	pdf?:ValueTypes["RedemptionCodesDocAccessFields_pdf"],
	variants?:ValueTypes["RedemptionCodesDocAccessFields_variants"],
	productId?:ValueTypes["RedemptionCodesDocAccessFields_productId"],
	referenceId?:ValueTypes["RedemptionCodesDocAccessFields_referenceId"],
	productType?:ValueTypes["RedemptionCodesDocAccessFields_productType"],
	productName?:ValueTypes["RedemptionCodesDocAccessFields_productName"],
	linkedEvent?:ValueTypes["RedemptionCodesDocAccessFields_linkedEvent"],
	timableOrderId?:ValueTypes["RedemptionCodesDocAccessFields_timableOrderId"],
	purchaseDateTime?:ValueTypes["RedemptionCodesDocAccessFields_purchaseDateTime"],
	changeHistory?:ValueTypes["RedemptionCodesDocAccessFields_changeHistory"],
	updatedAt?:ValueTypes["RedemptionCodesDocAccessFields_updatedAt"],
	createdAt?:ValueTypes["RedemptionCodesDocAccessFields_createdAt"],
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesDocAccessFields_creator"]: AliasType<{
	create?:ValueTypes["RedemptionCodesDocAccessFields_creator_Create"],
	read?:ValueTypes["RedemptionCodesDocAccessFields_creator_Read"],
	update?:ValueTypes["RedemptionCodesDocAccessFields_creator_Update"],
	delete?:ValueTypes["RedemptionCodesDocAccessFields_creator_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesDocAccessFields_creator_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesDocAccessFields_creator_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesDocAccessFields_creator_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesDocAccessFields_creator_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesDocAccessFields_status"]: AliasType<{
	create?:ValueTypes["RedemptionCodesDocAccessFields_status_Create"],
	read?:ValueTypes["RedemptionCodesDocAccessFields_status_Read"],
	update?:ValueTypes["RedemptionCodesDocAccessFields_status_Update"],
	delete?:ValueTypes["RedemptionCodesDocAccessFields_status_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesDocAccessFields_status_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesDocAccessFields_status_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesDocAccessFields_status_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesDocAccessFields_status_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesDocAccessFields_qrCodeId"]: AliasType<{
	create?:ValueTypes["RedemptionCodesDocAccessFields_qrCodeId_Create"],
	read?:ValueTypes["RedemptionCodesDocAccessFields_qrCodeId_Read"],
	update?:ValueTypes["RedemptionCodesDocAccessFields_qrCodeId_Update"],
	delete?:ValueTypes["RedemptionCodesDocAccessFields_qrCodeId_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesDocAccessFields_qrCodeId_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesDocAccessFields_qrCodeId_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesDocAccessFields_qrCodeId_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesDocAccessFields_qrCodeId_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesDocAccessFields_lastCheckedInDateTime"]: AliasType<{
	create?:ValueTypes["RedemptionCodesDocAccessFields_lastCheckedInDateTime_Create"],
	read?:ValueTypes["RedemptionCodesDocAccessFields_lastCheckedInDateTime_Read"],
	update?:ValueTypes["RedemptionCodesDocAccessFields_lastCheckedInDateTime_Update"],
	delete?:ValueTypes["RedemptionCodesDocAccessFields_lastCheckedInDateTime_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesDocAccessFields_lastCheckedInDateTime_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesDocAccessFields_lastCheckedInDateTime_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesDocAccessFields_lastCheckedInDateTime_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesDocAccessFields_lastCheckedInDateTime_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesDocAccessFields_voidedDateTime"]: AliasType<{
	create?:ValueTypes["RedemptionCodesDocAccessFields_voidedDateTime_Create"],
	read?:ValueTypes["RedemptionCodesDocAccessFields_voidedDateTime_Read"],
	update?:ValueTypes["RedemptionCodesDocAccessFields_voidedDateTime_Update"],
	delete?:ValueTypes["RedemptionCodesDocAccessFields_voidedDateTime_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesDocAccessFields_voidedDateTime_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesDocAccessFields_voidedDateTime_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesDocAccessFields_voidedDateTime_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesDocAccessFields_voidedDateTime_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesDocAccessFields_refundedDateTime"]: AliasType<{
	create?:ValueTypes["RedemptionCodesDocAccessFields_refundedDateTime_Create"],
	read?:ValueTypes["RedemptionCodesDocAccessFields_refundedDateTime_Read"],
	update?:ValueTypes["RedemptionCodesDocAccessFields_refundedDateTime_Update"],
	delete?:ValueTypes["RedemptionCodesDocAccessFields_refundedDateTime_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesDocAccessFields_refundedDateTime_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesDocAccessFields_refundedDateTime_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesDocAccessFields_refundedDateTime_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesDocAccessFields_refundedDateTime_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesDocAccessFields_eventStartTime"]: AliasType<{
	create?:ValueTypes["RedemptionCodesDocAccessFields_eventStartTime_Create"],
	read?:ValueTypes["RedemptionCodesDocAccessFields_eventStartTime_Read"],
	update?:ValueTypes["RedemptionCodesDocAccessFields_eventStartTime_Update"],
	delete?:ValueTypes["RedemptionCodesDocAccessFields_eventStartTime_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesDocAccessFields_eventStartTime_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesDocAccessFields_eventStartTime_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesDocAccessFields_eventStartTime_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesDocAccessFields_eventStartTime_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesDocAccessFields_eventEndTime"]: AliasType<{
	create?:ValueTypes["RedemptionCodesDocAccessFields_eventEndTime_Create"],
	read?:ValueTypes["RedemptionCodesDocAccessFields_eventEndTime_Read"],
	update?:ValueTypes["RedemptionCodesDocAccessFields_eventEndTime_Update"],
	delete?:ValueTypes["RedemptionCodesDocAccessFields_eventEndTime_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesDocAccessFields_eventEndTime_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesDocAccessFields_eventEndTime_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesDocAccessFields_eventEndTime_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesDocAccessFields_eventEndTime_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesDocAccessFields_eventPriceZone"]: AliasType<{
	create?:ValueTypes["RedemptionCodesDocAccessFields_eventPriceZone_Create"],
	read?:ValueTypes["RedemptionCodesDocAccessFields_eventPriceZone_Read"],
	update?:ValueTypes["RedemptionCodesDocAccessFields_eventPriceZone_Update"],
	delete?:ValueTypes["RedemptionCodesDocAccessFields_eventPriceZone_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesDocAccessFields_eventPriceZone_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesDocAccessFields_eventPriceZone_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesDocAccessFields_eventPriceZone_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesDocAccessFields_eventPriceZone_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesDocAccessFields_ownerName"]: AliasType<{
	create?:ValueTypes["RedemptionCodesDocAccessFields_ownerName_Create"],
	read?:ValueTypes["RedemptionCodesDocAccessFields_ownerName_Read"],
	update?:ValueTypes["RedemptionCodesDocAccessFields_ownerName_Update"],
	delete?:ValueTypes["RedemptionCodesDocAccessFields_ownerName_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesDocAccessFields_ownerName_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesDocAccessFields_ownerName_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesDocAccessFields_ownerName_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesDocAccessFields_ownerName_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesDocAccessFields_phone"]: AliasType<{
	create?:ValueTypes["RedemptionCodesDocAccessFields_phone_Create"],
	read?:ValueTypes["RedemptionCodesDocAccessFields_phone_Read"],
	update?:ValueTypes["RedemptionCodesDocAccessFields_phone_Update"],
	delete?:ValueTypes["RedemptionCodesDocAccessFields_phone_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesDocAccessFields_phone_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesDocAccessFields_phone_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesDocAccessFields_phone_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesDocAccessFields_phone_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesDocAccessFields_pdf"]: AliasType<{
	create?:ValueTypes["RedemptionCodesDocAccessFields_pdf_Create"],
	read?:ValueTypes["RedemptionCodesDocAccessFields_pdf_Read"],
	update?:ValueTypes["RedemptionCodesDocAccessFields_pdf_Update"],
	delete?:ValueTypes["RedemptionCodesDocAccessFields_pdf_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesDocAccessFields_pdf_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesDocAccessFields_pdf_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesDocAccessFields_pdf_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesDocAccessFields_pdf_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesDocAccessFields_variants"]: AliasType<{
	create?:ValueTypes["RedemptionCodesDocAccessFields_variants_Create"],
	read?:ValueTypes["RedemptionCodesDocAccessFields_variants_Read"],
	update?:ValueTypes["RedemptionCodesDocAccessFields_variants_Update"],
	delete?:ValueTypes["RedemptionCodesDocAccessFields_variants_Delete"],
	fields?:ValueTypes["RedemptionCodesDocAccessFields_variants_Fields"],
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesDocAccessFields_variants_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesDocAccessFields_variants_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesDocAccessFields_variants_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesDocAccessFields_variants_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesDocAccessFields_variants_Fields"]: AliasType<{
	variantId?:ValueTypes["RedemptionCodesDocAccessFields_variants_variantId"],
	name?:ValueTypes["RedemptionCodesDocAccessFields_variants_name"],
	quantity?:ValueTypes["RedemptionCodesDocAccessFields_variants_quantity"],
	totalCost?:ValueTypes["RedemptionCodesDocAccessFields_variants_totalCost"],
	id?:ValueTypes["RedemptionCodesDocAccessFields_variants_id"],
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesDocAccessFields_variants_variantId"]: AliasType<{
	create?:ValueTypes["RedemptionCodesDocAccessFields_variants_variantId_Create"],
	read?:ValueTypes["RedemptionCodesDocAccessFields_variants_variantId_Read"],
	update?:ValueTypes["RedemptionCodesDocAccessFields_variants_variantId_Update"],
	delete?:ValueTypes["RedemptionCodesDocAccessFields_variants_variantId_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesDocAccessFields_variants_variantId_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesDocAccessFields_variants_variantId_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesDocAccessFields_variants_variantId_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesDocAccessFields_variants_variantId_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesDocAccessFields_variants_name"]: AliasType<{
	create?:ValueTypes["RedemptionCodesDocAccessFields_variants_name_Create"],
	read?:ValueTypes["RedemptionCodesDocAccessFields_variants_name_Read"],
	update?:ValueTypes["RedemptionCodesDocAccessFields_variants_name_Update"],
	delete?:ValueTypes["RedemptionCodesDocAccessFields_variants_name_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesDocAccessFields_variants_name_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesDocAccessFields_variants_name_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesDocAccessFields_variants_name_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesDocAccessFields_variants_name_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesDocAccessFields_variants_quantity"]: AliasType<{
	create?:ValueTypes["RedemptionCodesDocAccessFields_variants_quantity_Create"],
	read?:ValueTypes["RedemptionCodesDocAccessFields_variants_quantity_Read"],
	update?:ValueTypes["RedemptionCodesDocAccessFields_variants_quantity_Update"],
	delete?:ValueTypes["RedemptionCodesDocAccessFields_variants_quantity_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesDocAccessFields_variants_quantity_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesDocAccessFields_variants_quantity_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesDocAccessFields_variants_quantity_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesDocAccessFields_variants_quantity_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesDocAccessFields_variants_totalCost"]: AliasType<{
	create?:ValueTypes["RedemptionCodesDocAccessFields_variants_totalCost_Create"],
	read?:ValueTypes["RedemptionCodesDocAccessFields_variants_totalCost_Read"],
	update?:ValueTypes["RedemptionCodesDocAccessFields_variants_totalCost_Update"],
	delete?:ValueTypes["RedemptionCodesDocAccessFields_variants_totalCost_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesDocAccessFields_variants_totalCost_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesDocAccessFields_variants_totalCost_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesDocAccessFields_variants_totalCost_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesDocAccessFields_variants_totalCost_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesDocAccessFields_variants_id"]: AliasType<{
	create?:ValueTypes["RedemptionCodesDocAccessFields_variants_id_Create"],
	read?:ValueTypes["RedemptionCodesDocAccessFields_variants_id_Read"],
	update?:ValueTypes["RedemptionCodesDocAccessFields_variants_id_Update"],
	delete?:ValueTypes["RedemptionCodesDocAccessFields_variants_id_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesDocAccessFields_variants_id_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesDocAccessFields_variants_id_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesDocAccessFields_variants_id_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesDocAccessFields_variants_id_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesDocAccessFields_productId"]: AliasType<{
	create?:ValueTypes["RedemptionCodesDocAccessFields_productId_Create"],
	read?:ValueTypes["RedemptionCodesDocAccessFields_productId_Read"],
	update?:ValueTypes["RedemptionCodesDocAccessFields_productId_Update"],
	delete?:ValueTypes["RedemptionCodesDocAccessFields_productId_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesDocAccessFields_productId_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesDocAccessFields_productId_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesDocAccessFields_productId_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesDocAccessFields_productId_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesDocAccessFields_referenceId"]: AliasType<{
	create?:ValueTypes["RedemptionCodesDocAccessFields_referenceId_Create"],
	read?:ValueTypes["RedemptionCodesDocAccessFields_referenceId_Read"],
	update?:ValueTypes["RedemptionCodesDocAccessFields_referenceId_Update"],
	delete?:ValueTypes["RedemptionCodesDocAccessFields_referenceId_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesDocAccessFields_referenceId_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesDocAccessFields_referenceId_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesDocAccessFields_referenceId_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesDocAccessFields_referenceId_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesDocAccessFields_productType"]: AliasType<{
	create?:ValueTypes["RedemptionCodesDocAccessFields_productType_Create"],
	read?:ValueTypes["RedemptionCodesDocAccessFields_productType_Read"],
	update?:ValueTypes["RedemptionCodesDocAccessFields_productType_Update"],
	delete?:ValueTypes["RedemptionCodesDocAccessFields_productType_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesDocAccessFields_productType_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesDocAccessFields_productType_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesDocAccessFields_productType_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesDocAccessFields_productType_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesDocAccessFields_productName"]: AliasType<{
	create?:ValueTypes["RedemptionCodesDocAccessFields_productName_Create"],
	read?:ValueTypes["RedemptionCodesDocAccessFields_productName_Read"],
	update?:ValueTypes["RedemptionCodesDocAccessFields_productName_Update"],
	delete?:ValueTypes["RedemptionCodesDocAccessFields_productName_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesDocAccessFields_productName_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesDocAccessFields_productName_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesDocAccessFields_productName_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesDocAccessFields_productName_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesDocAccessFields_linkedEvent"]: AliasType<{
	create?:ValueTypes["RedemptionCodesDocAccessFields_linkedEvent_Create"],
	read?:ValueTypes["RedemptionCodesDocAccessFields_linkedEvent_Read"],
	update?:ValueTypes["RedemptionCodesDocAccessFields_linkedEvent_Update"],
	delete?:ValueTypes["RedemptionCodesDocAccessFields_linkedEvent_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesDocAccessFields_linkedEvent_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesDocAccessFields_linkedEvent_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesDocAccessFields_linkedEvent_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesDocAccessFields_linkedEvent_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesDocAccessFields_timableOrderId"]: AliasType<{
	create?:ValueTypes["RedemptionCodesDocAccessFields_timableOrderId_Create"],
	read?:ValueTypes["RedemptionCodesDocAccessFields_timableOrderId_Read"],
	update?:ValueTypes["RedemptionCodesDocAccessFields_timableOrderId_Update"],
	delete?:ValueTypes["RedemptionCodesDocAccessFields_timableOrderId_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesDocAccessFields_timableOrderId_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesDocAccessFields_timableOrderId_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesDocAccessFields_timableOrderId_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesDocAccessFields_timableOrderId_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesDocAccessFields_purchaseDateTime"]: AliasType<{
	create?:ValueTypes["RedemptionCodesDocAccessFields_purchaseDateTime_Create"],
	read?:ValueTypes["RedemptionCodesDocAccessFields_purchaseDateTime_Read"],
	update?:ValueTypes["RedemptionCodesDocAccessFields_purchaseDateTime_Update"],
	delete?:ValueTypes["RedemptionCodesDocAccessFields_purchaseDateTime_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesDocAccessFields_purchaseDateTime_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesDocAccessFields_purchaseDateTime_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesDocAccessFields_purchaseDateTime_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesDocAccessFields_purchaseDateTime_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesDocAccessFields_changeHistory"]: AliasType<{
	create?:ValueTypes["RedemptionCodesDocAccessFields_changeHistory_Create"],
	read?:ValueTypes["RedemptionCodesDocAccessFields_changeHistory_Read"],
	update?:ValueTypes["RedemptionCodesDocAccessFields_changeHistory_Update"],
	delete?:ValueTypes["RedemptionCodesDocAccessFields_changeHistory_Delete"],
	fields?:ValueTypes["RedemptionCodesDocAccessFields_changeHistory_Fields"],
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesDocAccessFields_changeHistory_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesDocAccessFields_changeHistory_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesDocAccessFields_changeHistory_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesDocAccessFields_changeHistory_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesDocAccessFields_changeHistory_Fields"]: AliasType<{
	timestamp?:ValueTypes["RedemptionCodesDocAccessFields_changeHistory_timestamp"],
	field?:ValueTypes["RedemptionCodesDocAccessFields_changeHistory_field"],
	oldValue?:ValueTypes["RedemptionCodesDocAccessFields_changeHistory_oldValue"],
	newValue?:ValueTypes["RedemptionCodesDocAccessFields_changeHistory_newValue"],
	id?:ValueTypes["RedemptionCodesDocAccessFields_changeHistory_id"],
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesDocAccessFields_changeHistory_timestamp"]: AliasType<{
	create?:ValueTypes["RedemptionCodesDocAccessFields_changeHistory_timestamp_Create"],
	read?:ValueTypes["RedemptionCodesDocAccessFields_changeHistory_timestamp_Read"],
	update?:ValueTypes["RedemptionCodesDocAccessFields_changeHistory_timestamp_Update"],
	delete?:ValueTypes["RedemptionCodesDocAccessFields_changeHistory_timestamp_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesDocAccessFields_changeHistory_timestamp_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesDocAccessFields_changeHistory_timestamp_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesDocAccessFields_changeHistory_timestamp_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesDocAccessFields_changeHistory_timestamp_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesDocAccessFields_changeHistory_field"]: AliasType<{
	create?:ValueTypes["RedemptionCodesDocAccessFields_changeHistory_field_Create"],
	read?:ValueTypes["RedemptionCodesDocAccessFields_changeHistory_field_Read"],
	update?:ValueTypes["RedemptionCodesDocAccessFields_changeHistory_field_Update"],
	delete?:ValueTypes["RedemptionCodesDocAccessFields_changeHistory_field_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesDocAccessFields_changeHistory_field_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesDocAccessFields_changeHistory_field_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesDocAccessFields_changeHistory_field_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesDocAccessFields_changeHistory_field_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesDocAccessFields_changeHistory_oldValue"]: AliasType<{
	create?:ValueTypes["RedemptionCodesDocAccessFields_changeHistory_oldValue_Create"],
	read?:ValueTypes["RedemptionCodesDocAccessFields_changeHistory_oldValue_Read"],
	update?:ValueTypes["RedemptionCodesDocAccessFields_changeHistory_oldValue_Update"],
	delete?:ValueTypes["RedemptionCodesDocAccessFields_changeHistory_oldValue_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesDocAccessFields_changeHistory_oldValue_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesDocAccessFields_changeHistory_oldValue_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesDocAccessFields_changeHistory_oldValue_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesDocAccessFields_changeHistory_oldValue_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesDocAccessFields_changeHistory_newValue"]: AliasType<{
	create?:ValueTypes["RedemptionCodesDocAccessFields_changeHistory_newValue_Create"],
	read?:ValueTypes["RedemptionCodesDocAccessFields_changeHistory_newValue_Read"],
	update?:ValueTypes["RedemptionCodesDocAccessFields_changeHistory_newValue_Update"],
	delete?:ValueTypes["RedemptionCodesDocAccessFields_changeHistory_newValue_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesDocAccessFields_changeHistory_newValue_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesDocAccessFields_changeHistory_newValue_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesDocAccessFields_changeHistory_newValue_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesDocAccessFields_changeHistory_newValue_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesDocAccessFields_changeHistory_id"]: AliasType<{
	create?:ValueTypes["RedemptionCodesDocAccessFields_changeHistory_id_Create"],
	read?:ValueTypes["RedemptionCodesDocAccessFields_changeHistory_id_Read"],
	update?:ValueTypes["RedemptionCodesDocAccessFields_changeHistory_id_Update"],
	delete?:ValueTypes["RedemptionCodesDocAccessFields_changeHistory_id_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesDocAccessFields_changeHistory_id_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesDocAccessFields_changeHistory_id_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesDocAccessFields_changeHistory_id_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesDocAccessFields_changeHistory_id_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesDocAccessFields_updatedAt"]: AliasType<{
	create?:ValueTypes["RedemptionCodesDocAccessFields_updatedAt_Create"],
	read?:ValueTypes["RedemptionCodesDocAccessFields_updatedAt_Read"],
	update?:ValueTypes["RedemptionCodesDocAccessFields_updatedAt_Update"],
	delete?:ValueTypes["RedemptionCodesDocAccessFields_updatedAt_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesDocAccessFields_updatedAt_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesDocAccessFields_updatedAt_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesDocAccessFields_updatedAt_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesDocAccessFields_updatedAt_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesDocAccessFields_createdAt"]: AliasType<{
	create?:ValueTypes["RedemptionCodesDocAccessFields_createdAt_Create"],
	read?:ValueTypes["RedemptionCodesDocAccessFields_createdAt_Read"],
	update?:ValueTypes["RedemptionCodesDocAccessFields_createdAt_Update"],
	delete?:ValueTypes["RedemptionCodesDocAccessFields_createdAt_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesDocAccessFields_createdAt_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesDocAccessFields_createdAt_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesDocAccessFields_createdAt_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesDocAccessFields_createdAt_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesCreateDocAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesReadDocAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesUpdateDocAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesDeleteDocAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionPdfs"]: AliasType<{
	docs?:ValueTypes["RedemptionPdf"],
	hasNextPage?:boolean | `@${string}`,
	hasPrevPage?:boolean | `@${string}`,
	limit?:boolean | `@${string}`,
	nextPage?:boolean | `@${string}`,
	offset?:boolean | `@${string}`,
	page?:boolean | `@${string}`,
	pagingCounter?:boolean | `@${string}`,
	prevPage?:boolean | `@${string}`,
	totalDocs?:boolean | `@${string}`,
	totalPages?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionPdf_where"]: {
	updatedAt?: ValueTypes["RedemptionPdf_updatedAt_operator"] | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["RedemptionPdf_createdAt_operator"] | undefined | null | Variable<any, string>,
	url?: ValueTypes["RedemptionPdf_url_operator"] | undefined | null | Variable<any, string>,
	filename?: ValueTypes["RedemptionPdf_filename_operator"] | undefined | null | Variable<any, string>,
	mimeType?: ValueTypes["RedemptionPdf_mimeType_operator"] | undefined | null | Variable<any, string>,
	filesize?: ValueTypes["RedemptionPdf_filesize_operator"] | undefined | null | Variable<any, string>,
	width?: ValueTypes["RedemptionPdf_width_operator"] | undefined | null | Variable<any, string>,
	height?: ValueTypes["RedemptionPdf_height_operator"] | undefined | null | Variable<any, string>,
	focalX?: ValueTypes["RedemptionPdf_focalX_operator"] | undefined | null | Variable<any, string>,
	focalY?: ValueTypes["RedemptionPdf_focalY_operator"] | undefined | null | Variable<any, string>,
	id?: ValueTypes["RedemptionPdf_id_operator"] | undefined | null | Variable<any, string>,
	AND?: Array<ValueTypes["RedemptionPdf_where_and"] | undefined | null> | undefined | null | Variable<any, string>,
	OR?: Array<ValueTypes["RedemptionPdf_where_or"] | undefined | null> | undefined | null | Variable<any, string>
};
	["RedemptionPdf_updatedAt_operator"]: {
	equals?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	not_equals?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	greater_than_equal?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	greater_than?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	less_than_equal?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	less_than?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	like?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["RedemptionPdf_createdAt_operator"]: {
	equals?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	not_equals?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	greater_than_equal?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	greater_than?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	less_than_equal?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	less_than?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	like?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["RedemptionPdf_url_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["RedemptionPdf_filename_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["RedemptionPdf_mimeType_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["RedemptionPdf_filesize_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["RedemptionPdf_width_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["RedemptionPdf_height_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["RedemptionPdf_focalX_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["RedemptionPdf_focalY_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["RedemptionPdf_id_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["RedemptionPdf_where_and"]: {
	updatedAt?: ValueTypes["RedemptionPdf_updatedAt_operator"] | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["RedemptionPdf_createdAt_operator"] | undefined | null | Variable<any, string>,
	url?: ValueTypes["RedemptionPdf_url_operator"] | undefined | null | Variable<any, string>,
	filename?: ValueTypes["RedemptionPdf_filename_operator"] | undefined | null | Variable<any, string>,
	mimeType?: ValueTypes["RedemptionPdf_mimeType_operator"] | undefined | null | Variable<any, string>,
	filesize?: ValueTypes["RedemptionPdf_filesize_operator"] | undefined | null | Variable<any, string>,
	width?: ValueTypes["RedemptionPdf_width_operator"] | undefined | null | Variable<any, string>,
	height?: ValueTypes["RedemptionPdf_height_operator"] | undefined | null | Variable<any, string>,
	focalX?: ValueTypes["RedemptionPdf_focalX_operator"] | undefined | null | Variable<any, string>,
	focalY?: ValueTypes["RedemptionPdf_focalY_operator"] | undefined | null | Variable<any, string>,
	id?: ValueTypes["RedemptionPdf_id_operator"] | undefined | null | Variable<any, string>,
	AND?: Array<ValueTypes["RedemptionPdf_where_and"] | undefined | null> | undefined | null | Variable<any, string>,
	OR?: Array<ValueTypes["RedemptionPdf_where_or"] | undefined | null> | undefined | null | Variable<any, string>
};
	["RedemptionPdf_where_or"]: {
	updatedAt?: ValueTypes["RedemptionPdf_updatedAt_operator"] | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["RedemptionPdf_createdAt_operator"] | undefined | null | Variable<any, string>,
	url?: ValueTypes["RedemptionPdf_url_operator"] | undefined | null | Variable<any, string>,
	filename?: ValueTypes["RedemptionPdf_filename_operator"] | undefined | null | Variable<any, string>,
	mimeType?: ValueTypes["RedemptionPdf_mimeType_operator"] | undefined | null | Variable<any, string>,
	filesize?: ValueTypes["RedemptionPdf_filesize_operator"] | undefined | null | Variable<any, string>,
	width?: ValueTypes["RedemptionPdf_width_operator"] | undefined | null | Variable<any, string>,
	height?: ValueTypes["RedemptionPdf_height_operator"] | undefined | null | Variable<any, string>,
	focalX?: ValueTypes["RedemptionPdf_focalX_operator"] | undefined | null | Variable<any, string>,
	focalY?: ValueTypes["RedemptionPdf_focalY_operator"] | undefined | null | Variable<any, string>,
	id?: ValueTypes["RedemptionPdf_id_operator"] | undefined | null | Variable<any, string>,
	AND?: Array<ValueTypes["RedemptionPdf_where_and"] | undefined | null> | undefined | null | Variable<any, string>,
	OR?: Array<ValueTypes["RedemptionPdf_where_or"] | undefined | null> | undefined | null | Variable<any, string>
};
	["countRedemptionPdfs"]: AliasType<{
	totalDocs?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["redemption_pdfDocAccess"]: AliasType<{
	fields?:ValueTypes["RedemptionPdfDocAccessFields"],
	create?:ValueTypes["RedemptionPdfCreateDocAccess"],
	read?:ValueTypes["RedemptionPdfReadDocAccess"],
	update?:ValueTypes["RedemptionPdfUpdateDocAccess"],
	delete?:ValueTypes["RedemptionPdfDeleteDocAccess"],
		__typename?: boolean | `@${string}`
}>;
	["RedemptionPdfDocAccessFields"]: AliasType<{
	updatedAt?:ValueTypes["RedemptionPdfDocAccessFields_updatedAt"],
	createdAt?:ValueTypes["RedemptionPdfDocAccessFields_createdAt"],
	url?:ValueTypes["RedemptionPdfDocAccessFields_url"],
	filename?:ValueTypes["RedemptionPdfDocAccessFields_filename"],
	mimeType?:ValueTypes["RedemptionPdfDocAccessFields_mimeType"],
	filesize?:ValueTypes["RedemptionPdfDocAccessFields_filesize"],
	width?:ValueTypes["RedemptionPdfDocAccessFields_width"],
	height?:ValueTypes["RedemptionPdfDocAccessFields_height"],
	focalX?:ValueTypes["RedemptionPdfDocAccessFields_focalX"],
	focalY?:ValueTypes["RedemptionPdfDocAccessFields_focalY"],
		__typename?: boolean | `@${string}`
}>;
	["RedemptionPdfDocAccessFields_updatedAt"]: AliasType<{
	create?:ValueTypes["RedemptionPdfDocAccessFields_updatedAt_Create"],
	read?:ValueTypes["RedemptionPdfDocAccessFields_updatedAt_Read"],
	update?:ValueTypes["RedemptionPdfDocAccessFields_updatedAt_Update"],
	delete?:ValueTypes["RedemptionPdfDocAccessFields_updatedAt_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["RedemptionPdfDocAccessFields_updatedAt_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionPdfDocAccessFields_updatedAt_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionPdfDocAccessFields_updatedAt_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionPdfDocAccessFields_updatedAt_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionPdfDocAccessFields_createdAt"]: AliasType<{
	create?:ValueTypes["RedemptionPdfDocAccessFields_createdAt_Create"],
	read?:ValueTypes["RedemptionPdfDocAccessFields_createdAt_Read"],
	update?:ValueTypes["RedemptionPdfDocAccessFields_createdAt_Update"],
	delete?:ValueTypes["RedemptionPdfDocAccessFields_createdAt_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["RedemptionPdfDocAccessFields_createdAt_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionPdfDocAccessFields_createdAt_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionPdfDocAccessFields_createdAt_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionPdfDocAccessFields_createdAt_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionPdfDocAccessFields_url"]: AliasType<{
	create?:ValueTypes["RedemptionPdfDocAccessFields_url_Create"],
	read?:ValueTypes["RedemptionPdfDocAccessFields_url_Read"],
	update?:ValueTypes["RedemptionPdfDocAccessFields_url_Update"],
	delete?:ValueTypes["RedemptionPdfDocAccessFields_url_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["RedemptionPdfDocAccessFields_url_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionPdfDocAccessFields_url_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionPdfDocAccessFields_url_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionPdfDocAccessFields_url_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionPdfDocAccessFields_filename"]: AliasType<{
	create?:ValueTypes["RedemptionPdfDocAccessFields_filename_Create"],
	read?:ValueTypes["RedemptionPdfDocAccessFields_filename_Read"],
	update?:ValueTypes["RedemptionPdfDocAccessFields_filename_Update"],
	delete?:ValueTypes["RedemptionPdfDocAccessFields_filename_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["RedemptionPdfDocAccessFields_filename_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionPdfDocAccessFields_filename_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionPdfDocAccessFields_filename_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionPdfDocAccessFields_filename_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionPdfDocAccessFields_mimeType"]: AliasType<{
	create?:ValueTypes["RedemptionPdfDocAccessFields_mimeType_Create"],
	read?:ValueTypes["RedemptionPdfDocAccessFields_mimeType_Read"],
	update?:ValueTypes["RedemptionPdfDocAccessFields_mimeType_Update"],
	delete?:ValueTypes["RedemptionPdfDocAccessFields_mimeType_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["RedemptionPdfDocAccessFields_mimeType_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionPdfDocAccessFields_mimeType_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionPdfDocAccessFields_mimeType_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionPdfDocAccessFields_mimeType_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionPdfDocAccessFields_filesize"]: AliasType<{
	create?:ValueTypes["RedemptionPdfDocAccessFields_filesize_Create"],
	read?:ValueTypes["RedemptionPdfDocAccessFields_filesize_Read"],
	update?:ValueTypes["RedemptionPdfDocAccessFields_filesize_Update"],
	delete?:ValueTypes["RedemptionPdfDocAccessFields_filesize_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["RedemptionPdfDocAccessFields_filesize_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionPdfDocAccessFields_filesize_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionPdfDocAccessFields_filesize_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionPdfDocAccessFields_filesize_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionPdfDocAccessFields_width"]: AliasType<{
	create?:ValueTypes["RedemptionPdfDocAccessFields_width_Create"],
	read?:ValueTypes["RedemptionPdfDocAccessFields_width_Read"],
	update?:ValueTypes["RedemptionPdfDocAccessFields_width_Update"],
	delete?:ValueTypes["RedemptionPdfDocAccessFields_width_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["RedemptionPdfDocAccessFields_width_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionPdfDocAccessFields_width_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionPdfDocAccessFields_width_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionPdfDocAccessFields_width_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionPdfDocAccessFields_height"]: AliasType<{
	create?:ValueTypes["RedemptionPdfDocAccessFields_height_Create"],
	read?:ValueTypes["RedemptionPdfDocAccessFields_height_Read"],
	update?:ValueTypes["RedemptionPdfDocAccessFields_height_Update"],
	delete?:ValueTypes["RedemptionPdfDocAccessFields_height_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["RedemptionPdfDocAccessFields_height_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionPdfDocAccessFields_height_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionPdfDocAccessFields_height_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionPdfDocAccessFields_height_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionPdfDocAccessFields_focalX"]: AliasType<{
	create?:ValueTypes["RedemptionPdfDocAccessFields_focalX_Create"],
	read?:ValueTypes["RedemptionPdfDocAccessFields_focalX_Read"],
	update?:ValueTypes["RedemptionPdfDocAccessFields_focalX_Update"],
	delete?:ValueTypes["RedemptionPdfDocAccessFields_focalX_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["RedemptionPdfDocAccessFields_focalX_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionPdfDocAccessFields_focalX_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionPdfDocAccessFields_focalX_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionPdfDocAccessFields_focalX_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionPdfDocAccessFields_focalY"]: AliasType<{
	create?:ValueTypes["RedemptionPdfDocAccessFields_focalY_Create"],
	read?:ValueTypes["RedemptionPdfDocAccessFields_focalY_Read"],
	update?:ValueTypes["RedemptionPdfDocAccessFields_focalY_Update"],
	delete?:ValueTypes["RedemptionPdfDocAccessFields_focalY_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["RedemptionPdfDocAccessFields_focalY_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionPdfDocAccessFields_focalY_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionPdfDocAccessFields_focalY_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionPdfDocAccessFields_focalY_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionPdfCreateDocAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionPdfReadDocAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionPdfUpdateDocAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionPdfDeleteDocAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemption"]: AliasType<{
	id?:boolean | `@${string}`,
creator?: [{	locale?: ValueTypes["LocaleInputType"] | undefined | null | Variable<any, string>,	fallbackLocale?: ValueTypes["FallbackLocaleInputType"] | undefined | null | Variable<any, string>},ValueTypes["User"]],
	qrCodeId?:boolean | `@${string}`,
	codeownerName?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	phone?:boolean | `@${string}`,
linkedEvent?: [{	locale?: ValueTypes["LocaleInputType"] | undefined | null | Variable<any, string>,	fallbackLocale?: ValueTypes["FallbackLocaleInputType"] | undefined | null | Variable<any, string>},ValueTypes["Event"]],
	entryNumber?:boolean | `@${string}`,
	scanAt?:boolean | `@${string}`,
	uploadAt?:boolean | `@${string}`,
	status?:boolean | `@${string}`,
	failReason?:boolean | `@${string}`,
	variants?:ValueTypes["CheckInRedemption_Variants"],
	checkInAppSelectionId?:boolean | `@${string}`,
	qrCodeStatus?:boolean | `@${string}`,
	productType?:boolean | `@${string}`,
	updatedAt?:boolean | `@${string}`,
	createdAt?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemption_Variants"]: AliasType<{
	variantId?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	quantity?:boolean | `@${string}`,
	totalCost?:boolean | `@${string}`,
	id?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemption_productType"]:CheckInRedemption_productType;
	["CheckInRedemptions"]: AliasType<{
	docs?:ValueTypes["CheckInRedemption"],
	hasNextPage?:boolean | `@${string}`,
	hasPrevPage?:boolean | `@${string}`,
	limit?:boolean | `@${string}`,
	nextPage?:boolean | `@${string}`,
	offset?:boolean | `@${string}`,
	page?:boolean | `@${string}`,
	pagingCounter?:boolean | `@${string}`,
	prevPage?:boolean | `@${string}`,
	totalDocs?:boolean | `@${string}`,
	totalPages?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemption_where"]: {
	creator?: ValueTypes["CheckInRedemption_creator_operator"] | undefined | null | Variable<any, string>,
	qrCodeId?: ValueTypes["CheckInRedemption_qrCodeId_operator"] | undefined | null | Variable<any, string>,
	codeownerName?: ValueTypes["CheckInRedemption_codeownerName_operator"] | undefined | null | Variable<any, string>,
	name?: ValueTypes["CheckInRedemption_name_operator"] | undefined | null | Variable<any, string>,
	phone?: ValueTypes["CheckInRedemption_phone_operator"] | undefined | null | Variable<any, string>,
	linkedEvent?: ValueTypes["CheckInRedemption_linkedEvent_operator"] | undefined | null | Variable<any, string>,
	entryNumber?: ValueTypes["CheckInRedemption_entryNumber_operator"] | undefined | null | Variable<any, string>,
	scanAt?: ValueTypes["CheckInRedemption_scanAt_operator"] | undefined | null | Variable<any, string>,
	uploadAt?: ValueTypes["CheckInRedemption_uploadAt_operator"] | undefined | null | Variable<any, string>,
	status?: ValueTypes["CheckInRedemption_status_operator"] | undefined | null | Variable<any, string>,
	failReason?: ValueTypes["CheckInRedemption_failReason_operator"] | undefined | null | Variable<any, string>,
	variants__variantId?: ValueTypes["CheckInRedemption_variants__variantId_operator"] | undefined | null | Variable<any, string>,
	variants__name?: ValueTypes["CheckInRedemption_variants__name_operator"] | undefined | null | Variable<any, string>,
	variants__quantity?: ValueTypes["CheckInRedemption_variants__quantity_operator"] | undefined | null | Variable<any, string>,
	variants__totalCost?: ValueTypes["CheckInRedemption_variants__totalCost_operator"] | undefined | null | Variable<any, string>,
	variants__id?: ValueTypes["CheckInRedemption_variants__id_operator"] | undefined | null | Variable<any, string>,
	checkInAppSelectionId?: ValueTypes["CheckInRedemption_checkInAppSelectionId_operator"] | undefined | null | Variable<any, string>,
	qrCodeStatus?: ValueTypes["CheckInRedemption_qrCodeStatus_operator"] | undefined | null | Variable<any, string>,
	productType?: ValueTypes["CheckInRedemption_productType_operator"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["CheckInRedemption_updatedAt_operator"] | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["CheckInRedemption_createdAt_operator"] | undefined | null | Variable<any, string>,
	id?: ValueTypes["CheckInRedemption_id_operator"] | undefined | null | Variable<any, string>,
	AND?: Array<ValueTypes["CheckInRedemption_where_and"] | undefined | null> | undefined | null | Variable<any, string>,
	OR?: Array<ValueTypes["CheckInRedemption_where_or"] | undefined | null> | undefined | null | Variable<any, string>
};
	["CheckInRedemption_creator_operator"]: {
	equals?: ValueTypes["JSON"] | undefined | null | Variable<any, string>,
	not_equals?: ValueTypes["JSON"] | undefined | null | Variable<any, string>,
	in?: Array<ValueTypes["JSON"] | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<ValueTypes["JSON"] | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<ValueTypes["JSON"] | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["CheckInRedemption_qrCodeId_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["CheckInRedemption_codeownerName_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["CheckInRedemption_name_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["CheckInRedemption_phone_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["CheckInRedemption_linkedEvent_operator"]: {
	equals?: ValueTypes["JSON"] | undefined | null | Variable<any, string>,
	not_equals?: ValueTypes["JSON"] | undefined | null | Variable<any, string>,
	in?: Array<ValueTypes["JSON"] | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<ValueTypes["JSON"] | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<ValueTypes["JSON"] | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["CheckInRedemption_entryNumber_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["CheckInRedemption_scanAt_operator"]: {
	equals?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	not_equals?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	greater_than_equal?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	greater_than?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	less_than_equal?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	less_than?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	like?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["CheckInRedemption_uploadAt_operator"]: {
	equals?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	not_equals?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	greater_than_equal?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	greater_than?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	less_than_equal?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	less_than?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	like?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["CheckInRedemption_status_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["CheckInRedemption_failReason_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["CheckInRedemption_variants__variantId_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["CheckInRedemption_variants__name_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["CheckInRedemption_variants__quantity_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["CheckInRedemption_variants__totalCost_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["CheckInRedemption_variants__id_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["CheckInRedemption_checkInAppSelectionId_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["CheckInRedemption_qrCodeStatus_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["CheckInRedemption_productType_operator"]: {
	equals?: ValueTypes["CheckInRedemption_productType_Input"] | undefined | null | Variable<any, string>,
	not_equals?: ValueTypes["CheckInRedemption_productType_Input"] | undefined | null | Variable<any, string>,
	like?: ValueTypes["CheckInRedemption_productType_Input"] | undefined | null | Variable<any, string>,
	contains?: ValueTypes["CheckInRedemption_productType_Input"] | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["CheckInRedemption_productType_Input"]:CheckInRedemption_productType_Input;
	["CheckInRedemption_updatedAt_operator"]: {
	equals?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	not_equals?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	greater_than_equal?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	greater_than?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	less_than_equal?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	less_than?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	like?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["CheckInRedemption_createdAt_operator"]: {
	equals?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	not_equals?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	greater_than_equal?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	greater_than?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	less_than_equal?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	less_than?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	like?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["CheckInRedemption_id_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["CheckInRedemption_where_and"]: {
	creator?: ValueTypes["CheckInRedemption_creator_operator"] | undefined | null | Variable<any, string>,
	qrCodeId?: ValueTypes["CheckInRedemption_qrCodeId_operator"] | undefined | null | Variable<any, string>,
	codeownerName?: ValueTypes["CheckInRedemption_codeownerName_operator"] | undefined | null | Variable<any, string>,
	name?: ValueTypes["CheckInRedemption_name_operator"] | undefined | null | Variable<any, string>,
	phone?: ValueTypes["CheckInRedemption_phone_operator"] | undefined | null | Variable<any, string>,
	linkedEvent?: ValueTypes["CheckInRedemption_linkedEvent_operator"] | undefined | null | Variable<any, string>,
	entryNumber?: ValueTypes["CheckInRedemption_entryNumber_operator"] | undefined | null | Variable<any, string>,
	scanAt?: ValueTypes["CheckInRedemption_scanAt_operator"] | undefined | null | Variable<any, string>,
	uploadAt?: ValueTypes["CheckInRedemption_uploadAt_operator"] | undefined | null | Variable<any, string>,
	status?: ValueTypes["CheckInRedemption_status_operator"] | undefined | null | Variable<any, string>,
	failReason?: ValueTypes["CheckInRedemption_failReason_operator"] | undefined | null | Variable<any, string>,
	variants__variantId?: ValueTypes["CheckInRedemption_variants__variantId_operator"] | undefined | null | Variable<any, string>,
	variants__name?: ValueTypes["CheckInRedemption_variants__name_operator"] | undefined | null | Variable<any, string>,
	variants__quantity?: ValueTypes["CheckInRedemption_variants__quantity_operator"] | undefined | null | Variable<any, string>,
	variants__totalCost?: ValueTypes["CheckInRedemption_variants__totalCost_operator"] | undefined | null | Variable<any, string>,
	variants__id?: ValueTypes["CheckInRedemption_variants__id_operator"] | undefined | null | Variable<any, string>,
	checkInAppSelectionId?: ValueTypes["CheckInRedemption_checkInAppSelectionId_operator"] | undefined | null | Variable<any, string>,
	qrCodeStatus?: ValueTypes["CheckInRedemption_qrCodeStatus_operator"] | undefined | null | Variable<any, string>,
	productType?: ValueTypes["CheckInRedemption_productType_operator"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["CheckInRedemption_updatedAt_operator"] | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["CheckInRedemption_createdAt_operator"] | undefined | null | Variable<any, string>,
	id?: ValueTypes["CheckInRedemption_id_operator"] | undefined | null | Variable<any, string>,
	AND?: Array<ValueTypes["CheckInRedemption_where_and"] | undefined | null> | undefined | null | Variable<any, string>,
	OR?: Array<ValueTypes["CheckInRedemption_where_or"] | undefined | null> | undefined | null | Variable<any, string>
};
	["CheckInRedemption_where_or"]: {
	creator?: ValueTypes["CheckInRedemption_creator_operator"] | undefined | null | Variable<any, string>,
	qrCodeId?: ValueTypes["CheckInRedemption_qrCodeId_operator"] | undefined | null | Variable<any, string>,
	codeownerName?: ValueTypes["CheckInRedemption_codeownerName_operator"] | undefined | null | Variable<any, string>,
	name?: ValueTypes["CheckInRedemption_name_operator"] | undefined | null | Variable<any, string>,
	phone?: ValueTypes["CheckInRedemption_phone_operator"] | undefined | null | Variable<any, string>,
	linkedEvent?: ValueTypes["CheckInRedemption_linkedEvent_operator"] | undefined | null | Variable<any, string>,
	entryNumber?: ValueTypes["CheckInRedemption_entryNumber_operator"] | undefined | null | Variable<any, string>,
	scanAt?: ValueTypes["CheckInRedemption_scanAt_operator"] | undefined | null | Variable<any, string>,
	uploadAt?: ValueTypes["CheckInRedemption_uploadAt_operator"] | undefined | null | Variable<any, string>,
	status?: ValueTypes["CheckInRedemption_status_operator"] | undefined | null | Variable<any, string>,
	failReason?: ValueTypes["CheckInRedemption_failReason_operator"] | undefined | null | Variable<any, string>,
	variants__variantId?: ValueTypes["CheckInRedemption_variants__variantId_operator"] | undefined | null | Variable<any, string>,
	variants__name?: ValueTypes["CheckInRedemption_variants__name_operator"] | undefined | null | Variable<any, string>,
	variants__quantity?: ValueTypes["CheckInRedemption_variants__quantity_operator"] | undefined | null | Variable<any, string>,
	variants__totalCost?: ValueTypes["CheckInRedemption_variants__totalCost_operator"] | undefined | null | Variable<any, string>,
	variants__id?: ValueTypes["CheckInRedemption_variants__id_operator"] | undefined | null | Variable<any, string>,
	checkInAppSelectionId?: ValueTypes["CheckInRedemption_checkInAppSelectionId_operator"] | undefined | null | Variable<any, string>,
	qrCodeStatus?: ValueTypes["CheckInRedemption_qrCodeStatus_operator"] | undefined | null | Variable<any, string>,
	productType?: ValueTypes["CheckInRedemption_productType_operator"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["CheckInRedemption_updatedAt_operator"] | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["CheckInRedemption_createdAt_operator"] | undefined | null | Variable<any, string>,
	id?: ValueTypes["CheckInRedemption_id_operator"] | undefined | null | Variable<any, string>,
	AND?: Array<ValueTypes["CheckInRedemption_where_and"] | undefined | null> | undefined | null | Variable<any, string>,
	OR?: Array<ValueTypes["CheckInRedemption_where_or"] | undefined | null> | undefined | null | Variable<any, string>
};
	["countCheckInRedemptions"]: AliasType<{
	totalDocs?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["check_in_redemptionDocAccess"]: AliasType<{
	fields?:ValueTypes["CheckInRedemptionDocAccessFields"],
	create?:ValueTypes["CheckInRedemptionCreateDocAccess"],
	read?:ValueTypes["CheckInRedemptionReadDocAccess"],
	update?:ValueTypes["CheckInRedemptionUpdateDocAccess"],
	delete?:ValueTypes["CheckInRedemptionDeleteDocAccess"],
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionDocAccessFields"]: AliasType<{
	creator?:ValueTypes["CheckInRedemptionDocAccessFields_creator"],
	qrCodeId?:ValueTypes["CheckInRedemptionDocAccessFields_qrCodeId"],
	codeownerName?:ValueTypes["CheckInRedemptionDocAccessFields_codeownerName"],
	name?:ValueTypes["CheckInRedemptionDocAccessFields_name"],
	phone?:ValueTypes["CheckInRedemptionDocAccessFields_phone"],
	linkedEvent?:ValueTypes["CheckInRedemptionDocAccessFields_linkedEvent"],
	entryNumber?:ValueTypes["CheckInRedemptionDocAccessFields_entryNumber"],
	scanAt?:ValueTypes["CheckInRedemptionDocAccessFields_scanAt"],
	uploadAt?:ValueTypes["CheckInRedemptionDocAccessFields_uploadAt"],
	status?:ValueTypes["CheckInRedemptionDocAccessFields_status"],
	failReason?:ValueTypes["CheckInRedemptionDocAccessFields_failReason"],
	variants?:ValueTypes["CheckInRedemptionDocAccessFields_variants"],
	checkInAppSelectionId?:ValueTypes["CheckInRedemptionDocAccessFields_checkInAppSelectionId"],
	qrCodeStatus?:ValueTypes["CheckInRedemptionDocAccessFields_qrCodeStatus"],
	productType?:ValueTypes["CheckInRedemptionDocAccessFields_productType"],
	updatedAt?:ValueTypes["CheckInRedemptionDocAccessFields_updatedAt"],
	createdAt?:ValueTypes["CheckInRedemptionDocAccessFields_createdAt"],
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionDocAccessFields_creator"]: AliasType<{
	create?:ValueTypes["CheckInRedemptionDocAccessFields_creator_Create"],
	read?:ValueTypes["CheckInRedemptionDocAccessFields_creator_Read"],
	update?:ValueTypes["CheckInRedemptionDocAccessFields_creator_Update"],
	delete?:ValueTypes["CheckInRedemptionDocAccessFields_creator_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionDocAccessFields_creator_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionDocAccessFields_creator_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionDocAccessFields_creator_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionDocAccessFields_creator_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionDocAccessFields_qrCodeId"]: AliasType<{
	create?:ValueTypes["CheckInRedemptionDocAccessFields_qrCodeId_Create"],
	read?:ValueTypes["CheckInRedemptionDocAccessFields_qrCodeId_Read"],
	update?:ValueTypes["CheckInRedemptionDocAccessFields_qrCodeId_Update"],
	delete?:ValueTypes["CheckInRedemptionDocAccessFields_qrCodeId_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionDocAccessFields_qrCodeId_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionDocAccessFields_qrCodeId_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionDocAccessFields_qrCodeId_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionDocAccessFields_qrCodeId_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionDocAccessFields_codeownerName"]: AliasType<{
	create?:ValueTypes["CheckInRedemptionDocAccessFields_codeownerName_Create"],
	read?:ValueTypes["CheckInRedemptionDocAccessFields_codeownerName_Read"],
	update?:ValueTypes["CheckInRedemptionDocAccessFields_codeownerName_Update"],
	delete?:ValueTypes["CheckInRedemptionDocAccessFields_codeownerName_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionDocAccessFields_codeownerName_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionDocAccessFields_codeownerName_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionDocAccessFields_codeownerName_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionDocAccessFields_codeownerName_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionDocAccessFields_name"]: AliasType<{
	create?:ValueTypes["CheckInRedemptionDocAccessFields_name_Create"],
	read?:ValueTypes["CheckInRedemptionDocAccessFields_name_Read"],
	update?:ValueTypes["CheckInRedemptionDocAccessFields_name_Update"],
	delete?:ValueTypes["CheckInRedemptionDocAccessFields_name_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionDocAccessFields_name_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionDocAccessFields_name_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionDocAccessFields_name_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionDocAccessFields_name_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionDocAccessFields_phone"]: AliasType<{
	create?:ValueTypes["CheckInRedemptionDocAccessFields_phone_Create"],
	read?:ValueTypes["CheckInRedemptionDocAccessFields_phone_Read"],
	update?:ValueTypes["CheckInRedemptionDocAccessFields_phone_Update"],
	delete?:ValueTypes["CheckInRedemptionDocAccessFields_phone_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionDocAccessFields_phone_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionDocAccessFields_phone_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionDocAccessFields_phone_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionDocAccessFields_phone_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionDocAccessFields_linkedEvent"]: AliasType<{
	create?:ValueTypes["CheckInRedemptionDocAccessFields_linkedEvent_Create"],
	read?:ValueTypes["CheckInRedemptionDocAccessFields_linkedEvent_Read"],
	update?:ValueTypes["CheckInRedemptionDocAccessFields_linkedEvent_Update"],
	delete?:ValueTypes["CheckInRedemptionDocAccessFields_linkedEvent_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionDocAccessFields_linkedEvent_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionDocAccessFields_linkedEvent_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionDocAccessFields_linkedEvent_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionDocAccessFields_linkedEvent_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionDocAccessFields_entryNumber"]: AliasType<{
	create?:ValueTypes["CheckInRedemptionDocAccessFields_entryNumber_Create"],
	read?:ValueTypes["CheckInRedemptionDocAccessFields_entryNumber_Read"],
	update?:ValueTypes["CheckInRedemptionDocAccessFields_entryNumber_Update"],
	delete?:ValueTypes["CheckInRedemptionDocAccessFields_entryNumber_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionDocAccessFields_entryNumber_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionDocAccessFields_entryNumber_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionDocAccessFields_entryNumber_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionDocAccessFields_entryNumber_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionDocAccessFields_scanAt"]: AliasType<{
	create?:ValueTypes["CheckInRedemptionDocAccessFields_scanAt_Create"],
	read?:ValueTypes["CheckInRedemptionDocAccessFields_scanAt_Read"],
	update?:ValueTypes["CheckInRedemptionDocAccessFields_scanAt_Update"],
	delete?:ValueTypes["CheckInRedemptionDocAccessFields_scanAt_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionDocAccessFields_scanAt_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionDocAccessFields_scanAt_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionDocAccessFields_scanAt_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionDocAccessFields_scanAt_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionDocAccessFields_uploadAt"]: AliasType<{
	create?:ValueTypes["CheckInRedemptionDocAccessFields_uploadAt_Create"],
	read?:ValueTypes["CheckInRedemptionDocAccessFields_uploadAt_Read"],
	update?:ValueTypes["CheckInRedemptionDocAccessFields_uploadAt_Update"],
	delete?:ValueTypes["CheckInRedemptionDocAccessFields_uploadAt_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionDocAccessFields_uploadAt_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionDocAccessFields_uploadAt_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionDocAccessFields_uploadAt_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionDocAccessFields_uploadAt_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionDocAccessFields_status"]: AliasType<{
	create?:ValueTypes["CheckInRedemptionDocAccessFields_status_Create"],
	read?:ValueTypes["CheckInRedemptionDocAccessFields_status_Read"],
	update?:ValueTypes["CheckInRedemptionDocAccessFields_status_Update"],
	delete?:ValueTypes["CheckInRedemptionDocAccessFields_status_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionDocAccessFields_status_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionDocAccessFields_status_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionDocAccessFields_status_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionDocAccessFields_status_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionDocAccessFields_failReason"]: AliasType<{
	create?:ValueTypes["CheckInRedemptionDocAccessFields_failReason_Create"],
	read?:ValueTypes["CheckInRedemptionDocAccessFields_failReason_Read"],
	update?:ValueTypes["CheckInRedemptionDocAccessFields_failReason_Update"],
	delete?:ValueTypes["CheckInRedemptionDocAccessFields_failReason_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionDocAccessFields_failReason_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionDocAccessFields_failReason_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionDocAccessFields_failReason_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionDocAccessFields_failReason_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionDocAccessFields_variants"]: AliasType<{
	create?:ValueTypes["CheckInRedemptionDocAccessFields_variants_Create"],
	read?:ValueTypes["CheckInRedemptionDocAccessFields_variants_Read"],
	update?:ValueTypes["CheckInRedemptionDocAccessFields_variants_Update"],
	delete?:ValueTypes["CheckInRedemptionDocAccessFields_variants_Delete"],
	fields?:ValueTypes["CheckInRedemptionDocAccessFields_variants_Fields"],
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionDocAccessFields_variants_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionDocAccessFields_variants_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionDocAccessFields_variants_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionDocAccessFields_variants_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionDocAccessFields_variants_Fields"]: AliasType<{
	variantId?:ValueTypes["CheckInRedemptionDocAccessFields_variants_variantId"],
	name?:ValueTypes["CheckInRedemptionDocAccessFields_variants_name"],
	quantity?:ValueTypes["CheckInRedemptionDocAccessFields_variants_quantity"],
	totalCost?:ValueTypes["CheckInRedemptionDocAccessFields_variants_totalCost"],
	id?:ValueTypes["CheckInRedemptionDocAccessFields_variants_id"],
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionDocAccessFields_variants_variantId"]: AliasType<{
	create?:ValueTypes["CheckInRedemptionDocAccessFields_variants_variantId_Create"],
	read?:ValueTypes["CheckInRedemptionDocAccessFields_variants_variantId_Read"],
	update?:ValueTypes["CheckInRedemptionDocAccessFields_variants_variantId_Update"],
	delete?:ValueTypes["CheckInRedemptionDocAccessFields_variants_variantId_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionDocAccessFields_variants_variantId_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionDocAccessFields_variants_variantId_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionDocAccessFields_variants_variantId_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionDocAccessFields_variants_variantId_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionDocAccessFields_variants_name"]: AliasType<{
	create?:ValueTypes["CheckInRedemptionDocAccessFields_variants_name_Create"],
	read?:ValueTypes["CheckInRedemptionDocAccessFields_variants_name_Read"],
	update?:ValueTypes["CheckInRedemptionDocAccessFields_variants_name_Update"],
	delete?:ValueTypes["CheckInRedemptionDocAccessFields_variants_name_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionDocAccessFields_variants_name_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionDocAccessFields_variants_name_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionDocAccessFields_variants_name_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionDocAccessFields_variants_name_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionDocAccessFields_variants_quantity"]: AliasType<{
	create?:ValueTypes["CheckInRedemptionDocAccessFields_variants_quantity_Create"],
	read?:ValueTypes["CheckInRedemptionDocAccessFields_variants_quantity_Read"],
	update?:ValueTypes["CheckInRedemptionDocAccessFields_variants_quantity_Update"],
	delete?:ValueTypes["CheckInRedemptionDocAccessFields_variants_quantity_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionDocAccessFields_variants_quantity_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionDocAccessFields_variants_quantity_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionDocAccessFields_variants_quantity_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionDocAccessFields_variants_quantity_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionDocAccessFields_variants_totalCost"]: AliasType<{
	create?:ValueTypes["CheckInRedemptionDocAccessFields_variants_totalCost_Create"],
	read?:ValueTypes["CheckInRedemptionDocAccessFields_variants_totalCost_Read"],
	update?:ValueTypes["CheckInRedemptionDocAccessFields_variants_totalCost_Update"],
	delete?:ValueTypes["CheckInRedemptionDocAccessFields_variants_totalCost_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionDocAccessFields_variants_totalCost_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionDocAccessFields_variants_totalCost_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionDocAccessFields_variants_totalCost_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionDocAccessFields_variants_totalCost_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionDocAccessFields_variants_id"]: AliasType<{
	create?:ValueTypes["CheckInRedemptionDocAccessFields_variants_id_Create"],
	read?:ValueTypes["CheckInRedemptionDocAccessFields_variants_id_Read"],
	update?:ValueTypes["CheckInRedemptionDocAccessFields_variants_id_Update"],
	delete?:ValueTypes["CheckInRedemptionDocAccessFields_variants_id_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionDocAccessFields_variants_id_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionDocAccessFields_variants_id_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionDocAccessFields_variants_id_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionDocAccessFields_variants_id_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionDocAccessFields_checkInAppSelectionId"]: AliasType<{
	create?:ValueTypes["CheckInRedemptionDocAccessFields_checkInAppSelectionId_Create"],
	read?:ValueTypes["CheckInRedemptionDocAccessFields_checkInAppSelectionId_Read"],
	update?:ValueTypes["CheckInRedemptionDocAccessFields_checkInAppSelectionId_Update"],
	delete?:ValueTypes["CheckInRedemptionDocAccessFields_checkInAppSelectionId_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionDocAccessFields_checkInAppSelectionId_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionDocAccessFields_checkInAppSelectionId_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionDocAccessFields_checkInAppSelectionId_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionDocAccessFields_checkInAppSelectionId_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionDocAccessFields_qrCodeStatus"]: AliasType<{
	create?:ValueTypes["CheckInRedemptionDocAccessFields_qrCodeStatus_Create"],
	read?:ValueTypes["CheckInRedemptionDocAccessFields_qrCodeStatus_Read"],
	update?:ValueTypes["CheckInRedemptionDocAccessFields_qrCodeStatus_Update"],
	delete?:ValueTypes["CheckInRedemptionDocAccessFields_qrCodeStatus_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionDocAccessFields_qrCodeStatus_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionDocAccessFields_qrCodeStatus_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionDocAccessFields_qrCodeStatus_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionDocAccessFields_qrCodeStatus_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionDocAccessFields_productType"]: AliasType<{
	create?:ValueTypes["CheckInRedemptionDocAccessFields_productType_Create"],
	read?:ValueTypes["CheckInRedemptionDocAccessFields_productType_Read"],
	update?:ValueTypes["CheckInRedemptionDocAccessFields_productType_Update"],
	delete?:ValueTypes["CheckInRedemptionDocAccessFields_productType_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionDocAccessFields_productType_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionDocAccessFields_productType_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionDocAccessFields_productType_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionDocAccessFields_productType_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionDocAccessFields_updatedAt"]: AliasType<{
	create?:ValueTypes["CheckInRedemptionDocAccessFields_updatedAt_Create"],
	read?:ValueTypes["CheckInRedemptionDocAccessFields_updatedAt_Read"],
	update?:ValueTypes["CheckInRedemptionDocAccessFields_updatedAt_Update"],
	delete?:ValueTypes["CheckInRedemptionDocAccessFields_updatedAt_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionDocAccessFields_updatedAt_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionDocAccessFields_updatedAt_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionDocAccessFields_updatedAt_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionDocAccessFields_updatedAt_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionDocAccessFields_createdAt"]: AliasType<{
	create?:ValueTypes["CheckInRedemptionDocAccessFields_createdAt_Create"],
	read?:ValueTypes["CheckInRedemptionDocAccessFields_createdAt_Read"],
	update?:ValueTypes["CheckInRedemptionDocAccessFields_createdAt_Update"],
	delete?:ValueTypes["CheckInRedemptionDocAccessFields_createdAt_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionDocAccessFields_createdAt_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionDocAccessFields_createdAt_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionDocAccessFields_createdAt_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionDocAccessFields_createdAt_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionCreateDocAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionReadDocAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionUpdateDocAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionDeleteDocAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["Users"]: AliasType<{
	docs?:ValueTypes["User"],
	hasNextPage?:boolean | `@${string}`,
	hasPrevPage?:boolean | `@${string}`,
	limit?:boolean | `@${string}`,
	nextPage?:boolean | `@${string}`,
	offset?:boolean | `@${string}`,
	page?:boolean | `@${string}`,
	pagingCounter?:boolean | `@${string}`,
	prevPage?:boolean | `@${string}`,
	totalDocs?:boolean | `@${string}`,
	totalPages?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["User_where"]: {
	region?: ValueTypes["User_region_operator"] | undefined | null | Variable<any, string>,
	name?: ValueTypes["User_name_operator"] | undefined | null | Variable<any, string>,
	displayEmail?: ValueTypes["User_displayEmail_operator"] | undefined | null | Variable<any, string>,
	legacyUsername?: ValueTypes["User_legacyUsername_operator"] | undefined | null | Variable<any, string>,
	roles?: ValueTypes["User_roles_operator"] | undefined | null | Variable<any, string>,
	member__region?: ValueTypes["User_member__region_operator"] | undefined | null | Variable<any, string>,
	member__language?: ValueTypes["User_member__language_operator"] | undefined | null | Variable<any, string>,
	member__phone?: ValueTypes["User_member__phone_operator"] | undefined | null | Variable<any, string>,
	member__avatar?: ValueTypes["User_member__avatar_operator"] | undefined | null | Variable<any, string>,
	member__birthday?: ValueTypes["User_member__birthday_operator"] | undefined | null | Variable<any, string>,
	member__gender?: ValueTypes["User_member__gender_operator"] | undefined | null | Variable<any, string>,
	member__categories?: ValueTypes["User_member__categories_operator"] | undefined | null | Variable<any, string>,
	organiser__logo?: ValueTypes["User_organiser__logo_operator"] | undefined | null | Variable<any, string>,
	organiser__banner?: ValueTypes["User_organiser__banner_operator"] | undefined | null | Variable<any, string>,
	organiser__description?: ValueTypes["User_organiser__description_operator"] | undefined | null | Variable<any, string>,
	organiser__foundedIn?: ValueTypes["User_organiser__foundedIn_operator"] | undefined | null | Variable<any, string>,
	organiser__address?: ValueTypes["User_organiser__address_operator"] | undefined | null | Variable<any, string>,
	organiser__website?: ValueTypes["User_organiser__website_operator"] | undefined | null | Variable<any, string>,
	organiser__facebook?: ValueTypes["User_organiser__facebook_operator"] | undefined | null | Variable<any, string>,
	organiser__instagram?: ValueTypes["User_organiser__instagram_operator"] | undefined | null | Variable<any, string>,
	organiser__language?: ValueTypes["User_organiser__language_operator"] | undefined | null | Variable<any, string>,
	organiser__phone?: ValueTypes["User_organiser__phone_operator"] | undefined | null | Variable<any, string>,
	organiser__followers?: ValueTypes["User_organiser__followers_operator"] | undefined | null | Variable<any, string>,
	emailVerified?: ValueTypes["User_emailVerified_operator"] | undefined | null | Variable<any, string>,
	sso__googleId?: ValueTypes["User_sso__googleId_operator"] | undefined | null | Variable<any, string>,
	sso__facebookId?: ValueTypes["User_sso__facebookId_operator"] | undefined | null | Variable<any, string>,
	sso__appleId?: ValueTypes["User_sso__appleId_operator"] | undefined | null | Variable<any, string>,
	oldPassword?: ValueTypes["User_oldPassword_operator"] | undefined | null | Variable<any, string>,
	status?: ValueTypes["User_status_operator"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["User_updatedAt_operator"] | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["User_createdAt_operator"] | undefined | null | Variable<any, string>,
	email?: ValueTypes["User_email_operator"] | undefined | null | Variable<any, string>,
	id?: ValueTypes["User_id_operator"] | undefined | null | Variable<any, string>,
	AND?: Array<ValueTypes["User_where_and"] | undefined | null> | undefined | null | Variable<any, string>,
	OR?: Array<ValueTypes["User_where_or"] | undefined | null> | undefined | null | Variable<any, string>
};
	["User_region_operator"]: {
	equals?: ValueTypes["User_region_Input"] | undefined | null | Variable<any, string>,
	not_equals?: ValueTypes["User_region_Input"] | undefined | null | Variable<any, string>,
	in?: Array<ValueTypes["User_region_Input"] | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<ValueTypes["User_region_Input"] | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<ValueTypes["User_region_Input"] | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["User_region_Input"]:User_region_Input;
	["User_name_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["User_displayEmail_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["User_legacyUsername_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["User_roles_operator"]: {
	equals?: ValueTypes["User_roles_Input"] | undefined | null | Variable<any, string>,
	not_equals?: ValueTypes["User_roles_Input"] | undefined | null | Variable<any, string>,
	in?: Array<ValueTypes["User_roles_Input"] | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<ValueTypes["User_roles_Input"] | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<ValueTypes["User_roles_Input"] | undefined | null> | undefined | null | Variable<any, string>
};
	["User_roles_Input"]:User_roles_Input;
	["User_member__region_operator"]: {
	equals?: ValueTypes["User_member__region_Input"] | undefined | null | Variable<any, string>,
	not_equals?: ValueTypes["User_member__region_Input"] | undefined | null | Variable<any, string>,
	in?: Array<ValueTypes["User_member__region_Input"] | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<ValueTypes["User_member__region_Input"] | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<ValueTypes["User_member__region_Input"] | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["User_member__region_Input"]:User_member__region_Input;
	["User_member__language_operator"]: {
	equals?: ValueTypes["User_member__language_Input"] | undefined | null | Variable<any, string>,
	not_equals?: ValueTypes["User_member__language_Input"] | undefined | null | Variable<any, string>,
	in?: Array<ValueTypes["User_member__language_Input"] | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<ValueTypes["User_member__language_Input"] | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<ValueTypes["User_member__language_Input"] | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["User_member__language_Input"]:User_member__language_Input;
	["User_member__phone_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["User_member__avatar_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["User_member__birthday_operator"]: {
	equals?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	not_equals?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	greater_than_equal?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	greater_than?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	less_than_equal?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	less_than?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	like?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["User_member__gender_operator"]: {
	equals?: ValueTypes["User_member__gender_Input"] | undefined | null | Variable<any, string>,
	not_equals?: ValueTypes["User_member__gender_Input"] | undefined | null | Variable<any, string>,
	in?: Array<ValueTypes["User_member__gender_Input"] | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<ValueTypes["User_member__gender_Input"] | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<ValueTypes["User_member__gender_Input"] | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["User_member__gender_Input"]:User_member__gender_Input;
	["User_member__categories_operator"]: {
	equals?: ValueTypes["JSON"] | undefined | null | Variable<any, string>,
	not_equals?: ValueTypes["JSON"] | undefined | null | Variable<any, string>,
	in?: Array<ValueTypes["JSON"] | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<ValueTypes["JSON"] | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<ValueTypes["JSON"] | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["User_organiser__logo_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["User_organiser__banner_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["User_organiser__description_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["User_organiser__foundedIn_operator"]: {
	equals?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	not_equals?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	greater_than_equal?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	greater_than?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	less_than_equal?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	less_than?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	like?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["User_organiser__address_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["User_organiser__website_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["User_organiser__facebook_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["User_organiser__instagram_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["User_organiser__language_operator"]: {
	equals?: ValueTypes["User_organiser__language_Input"] | undefined | null | Variable<any, string>,
	not_equals?: ValueTypes["User_organiser__language_Input"] | undefined | null | Variable<any, string>,
	in?: Array<ValueTypes["User_organiser__language_Input"] | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<ValueTypes["User_organiser__language_Input"] | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<ValueTypes["User_organiser__language_Input"] | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["User_organiser__language_Input"]:User_organiser__language_Input;
	["User_organiser__phone_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["User_organiser__followers_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["User_emailVerified_operator"]: {
	equals?: boolean | undefined | null | Variable<any, string>,
	not_equals?: boolean | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["User_sso__googleId_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["User_sso__facebookId_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["User_sso__appleId_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["User_oldPassword_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["User_status_operator"]: {
	equals?: ValueTypes["User_status_Input"] | undefined | null | Variable<any, string>,
	not_equals?: ValueTypes["User_status_Input"] | undefined | null | Variable<any, string>,
	in?: Array<ValueTypes["User_status_Input"] | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<ValueTypes["User_status_Input"] | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<ValueTypes["User_status_Input"] | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["User_status_Input"]:User_status_Input;
	["User_updatedAt_operator"]: {
	equals?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	not_equals?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	greater_than_equal?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	greater_than?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	less_than_equal?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	less_than?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	like?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["User_createdAt_operator"]: {
	equals?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	not_equals?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	greater_than_equal?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	greater_than?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	less_than_equal?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	less_than?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	like?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["User_email_operator"]: {
	equals?: ValueTypes["EmailAddress"] | undefined | null | Variable<any, string>,
	not_equals?: ValueTypes["EmailAddress"] | undefined | null | Variable<any, string>,
	like?: ValueTypes["EmailAddress"] | undefined | null | Variable<any, string>,
	contains?: ValueTypes["EmailAddress"] | undefined | null | Variable<any, string>,
	in?: Array<ValueTypes["EmailAddress"] | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<ValueTypes["EmailAddress"] | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<ValueTypes["EmailAddress"] | undefined | null> | undefined | null | Variable<any, string>
};
	["User_id_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["User_where_and"]: {
	region?: ValueTypes["User_region_operator"] | undefined | null | Variable<any, string>,
	name?: ValueTypes["User_name_operator"] | undefined | null | Variable<any, string>,
	displayEmail?: ValueTypes["User_displayEmail_operator"] | undefined | null | Variable<any, string>,
	legacyUsername?: ValueTypes["User_legacyUsername_operator"] | undefined | null | Variable<any, string>,
	roles?: ValueTypes["User_roles_operator"] | undefined | null | Variable<any, string>,
	member__region?: ValueTypes["User_member__region_operator"] | undefined | null | Variable<any, string>,
	member__language?: ValueTypes["User_member__language_operator"] | undefined | null | Variable<any, string>,
	member__phone?: ValueTypes["User_member__phone_operator"] | undefined | null | Variable<any, string>,
	member__avatar?: ValueTypes["User_member__avatar_operator"] | undefined | null | Variable<any, string>,
	member__birthday?: ValueTypes["User_member__birthday_operator"] | undefined | null | Variable<any, string>,
	member__gender?: ValueTypes["User_member__gender_operator"] | undefined | null | Variable<any, string>,
	member__categories?: ValueTypes["User_member__categories_operator"] | undefined | null | Variable<any, string>,
	organiser__logo?: ValueTypes["User_organiser__logo_operator"] | undefined | null | Variable<any, string>,
	organiser__banner?: ValueTypes["User_organiser__banner_operator"] | undefined | null | Variable<any, string>,
	organiser__description?: ValueTypes["User_organiser__description_operator"] | undefined | null | Variable<any, string>,
	organiser__foundedIn?: ValueTypes["User_organiser__foundedIn_operator"] | undefined | null | Variable<any, string>,
	organiser__address?: ValueTypes["User_organiser__address_operator"] | undefined | null | Variable<any, string>,
	organiser__website?: ValueTypes["User_organiser__website_operator"] | undefined | null | Variable<any, string>,
	organiser__facebook?: ValueTypes["User_organiser__facebook_operator"] | undefined | null | Variable<any, string>,
	organiser__instagram?: ValueTypes["User_organiser__instagram_operator"] | undefined | null | Variable<any, string>,
	organiser__language?: ValueTypes["User_organiser__language_operator"] | undefined | null | Variable<any, string>,
	organiser__phone?: ValueTypes["User_organiser__phone_operator"] | undefined | null | Variable<any, string>,
	organiser__followers?: ValueTypes["User_organiser__followers_operator"] | undefined | null | Variable<any, string>,
	emailVerified?: ValueTypes["User_emailVerified_operator"] | undefined | null | Variable<any, string>,
	sso__googleId?: ValueTypes["User_sso__googleId_operator"] | undefined | null | Variable<any, string>,
	sso__facebookId?: ValueTypes["User_sso__facebookId_operator"] | undefined | null | Variable<any, string>,
	sso__appleId?: ValueTypes["User_sso__appleId_operator"] | undefined | null | Variable<any, string>,
	oldPassword?: ValueTypes["User_oldPassword_operator"] | undefined | null | Variable<any, string>,
	status?: ValueTypes["User_status_operator"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["User_updatedAt_operator"] | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["User_createdAt_operator"] | undefined | null | Variable<any, string>,
	email?: ValueTypes["User_email_operator"] | undefined | null | Variable<any, string>,
	id?: ValueTypes["User_id_operator"] | undefined | null | Variable<any, string>,
	AND?: Array<ValueTypes["User_where_and"] | undefined | null> | undefined | null | Variable<any, string>,
	OR?: Array<ValueTypes["User_where_or"] | undefined | null> | undefined | null | Variable<any, string>
};
	["User_where_or"]: {
	region?: ValueTypes["User_region_operator"] | undefined | null | Variable<any, string>,
	name?: ValueTypes["User_name_operator"] | undefined | null | Variable<any, string>,
	displayEmail?: ValueTypes["User_displayEmail_operator"] | undefined | null | Variable<any, string>,
	legacyUsername?: ValueTypes["User_legacyUsername_operator"] | undefined | null | Variable<any, string>,
	roles?: ValueTypes["User_roles_operator"] | undefined | null | Variable<any, string>,
	member__region?: ValueTypes["User_member__region_operator"] | undefined | null | Variable<any, string>,
	member__language?: ValueTypes["User_member__language_operator"] | undefined | null | Variable<any, string>,
	member__phone?: ValueTypes["User_member__phone_operator"] | undefined | null | Variable<any, string>,
	member__avatar?: ValueTypes["User_member__avatar_operator"] | undefined | null | Variable<any, string>,
	member__birthday?: ValueTypes["User_member__birthday_operator"] | undefined | null | Variable<any, string>,
	member__gender?: ValueTypes["User_member__gender_operator"] | undefined | null | Variable<any, string>,
	member__categories?: ValueTypes["User_member__categories_operator"] | undefined | null | Variable<any, string>,
	organiser__logo?: ValueTypes["User_organiser__logo_operator"] | undefined | null | Variable<any, string>,
	organiser__banner?: ValueTypes["User_organiser__banner_operator"] | undefined | null | Variable<any, string>,
	organiser__description?: ValueTypes["User_organiser__description_operator"] | undefined | null | Variable<any, string>,
	organiser__foundedIn?: ValueTypes["User_organiser__foundedIn_operator"] | undefined | null | Variable<any, string>,
	organiser__address?: ValueTypes["User_organiser__address_operator"] | undefined | null | Variable<any, string>,
	organiser__website?: ValueTypes["User_organiser__website_operator"] | undefined | null | Variable<any, string>,
	organiser__facebook?: ValueTypes["User_organiser__facebook_operator"] | undefined | null | Variable<any, string>,
	organiser__instagram?: ValueTypes["User_organiser__instagram_operator"] | undefined | null | Variable<any, string>,
	organiser__language?: ValueTypes["User_organiser__language_operator"] | undefined | null | Variable<any, string>,
	organiser__phone?: ValueTypes["User_organiser__phone_operator"] | undefined | null | Variable<any, string>,
	organiser__followers?: ValueTypes["User_organiser__followers_operator"] | undefined | null | Variable<any, string>,
	emailVerified?: ValueTypes["User_emailVerified_operator"] | undefined | null | Variable<any, string>,
	sso__googleId?: ValueTypes["User_sso__googleId_operator"] | undefined | null | Variable<any, string>,
	sso__facebookId?: ValueTypes["User_sso__facebookId_operator"] | undefined | null | Variable<any, string>,
	sso__appleId?: ValueTypes["User_sso__appleId_operator"] | undefined | null | Variable<any, string>,
	oldPassword?: ValueTypes["User_oldPassword_operator"] | undefined | null | Variable<any, string>,
	status?: ValueTypes["User_status_operator"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["User_updatedAt_operator"] | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["User_createdAt_operator"] | undefined | null | Variable<any, string>,
	email?: ValueTypes["User_email_operator"] | undefined | null | Variable<any, string>,
	id?: ValueTypes["User_id_operator"] | undefined | null | Variable<any, string>,
	AND?: Array<ValueTypes["User_where_and"] | undefined | null> | undefined | null | Variable<any, string>,
	OR?: Array<ValueTypes["User_where_or"] | undefined | null> | undefined | null | Variable<any, string>
};
	["countUsers"]: AliasType<{
	totalDocs?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["usersDocAccess"]: AliasType<{
	fields?:ValueTypes["UsersDocAccessFields"],
	create?:ValueTypes["UsersCreateDocAccess"],
	read?:ValueTypes["UsersReadDocAccess"],
	update?:ValueTypes["UsersUpdateDocAccess"],
	delete?:ValueTypes["UsersDeleteDocAccess"],
	unlock?:ValueTypes["UsersUnlockDocAccess"],
		__typename?: boolean | `@${string}`
}>;
	["UsersDocAccessFields"]: AliasType<{
	region?:ValueTypes["UsersDocAccessFields_region"],
	name?:ValueTypes["UsersDocAccessFields_name"],
	displayEmail?:ValueTypes["UsersDocAccessFields_displayEmail"],
	legacyUsername?:ValueTypes["UsersDocAccessFields_legacyUsername"],
	roles?:ValueTypes["UsersDocAccessFields_roles"],
	member?:ValueTypes["UsersDocAccessFields_member"],
	organiser?:ValueTypes["UsersDocAccessFields_organiser"],
	emailVerified?:ValueTypes["UsersDocAccessFields_emailVerified"],
	sso?:ValueTypes["UsersDocAccessFields_sso"],
	oldPassword?:ValueTypes["UsersDocAccessFields_oldPassword"],
	status?:ValueTypes["UsersDocAccessFields_status"],
	updatedAt?:ValueTypes["UsersDocAccessFields_updatedAt"],
	createdAt?:ValueTypes["UsersDocAccessFields_createdAt"],
	email?:ValueTypes["UsersDocAccessFields_email"],
	password?:ValueTypes["UsersDocAccessFields_password"],
		__typename?: boolean | `@${string}`
}>;
	["UsersDocAccessFields_region"]: AliasType<{
	create?:ValueTypes["UsersDocAccessFields_region_Create"],
	read?:ValueTypes["UsersDocAccessFields_region_Read"],
	update?:ValueTypes["UsersDocAccessFields_region_Update"],
	delete?:ValueTypes["UsersDocAccessFields_region_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["UsersDocAccessFields_region_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersDocAccessFields_region_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersDocAccessFields_region_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersDocAccessFields_region_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersDocAccessFields_name"]: AliasType<{
	create?:ValueTypes["UsersDocAccessFields_name_Create"],
	read?:ValueTypes["UsersDocAccessFields_name_Read"],
	update?:ValueTypes["UsersDocAccessFields_name_Update"],
	delete?:ValueTypes["UsersDocAccessFields_name_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["UsersDocAccessFields_name_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersDocAccessFields_name_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersDocAccessFields_name_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersDocAccessFields_name_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersDocAccessFields_displayEmail"]: AliasType<{
	create?:ValueTypes["UsersDocAccessFields_displayEmail_Create"],
	read?:ValueTypes["UsersDocAccessFields_displayEmail_Read"],
	update?:ValueTypes["UsersDocAccessFields_displayEmail_Update"],
	delete?:ValueTypes["UsersDocAccessFields_displayEmail_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["UsersDocAccessFields_displayEmail_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersDocAccessFields_displayEmail_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersDocAccessFields_displayEmail_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersDocAccessFields_displayEmail_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersDocAccessFields_legacyUsername"]: AliasType<{
	create?:ValueTypes["UsersDocAccessFields_legacyUsername_Create"],
	read?:ValueTypes["UsersDocAccessFields_legacyUsername_Read"],
	update?:ValueTypes["UsersDocAccessFields_legacyUsername_Update"],
	delete?:ValueTypes["UsersDocAccessFields_legacyUsername_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["UsersDocAccessFields_legacyUsername_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersDocAccessFields_legacyUsername_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersDocAccessFields_legacyUsername_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersDocAccessFields_legacyUsername_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersDocAccessFields_roles"]: AliasType<{
	create?:ValueTypes["UsersDocAccessFields_roles_Create"],
	read?:ValueTypes["UsersDocAccessFields_roles_Read"],
	update?:ValueTypes["UsersDocAccessFields_roles_Update"],
	delete?:ValueTypes["UsersDocAccessFields_roles_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["UsersDocAccessFields_roles_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersDocAccessFields_roles_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersDocAccessFields_roles_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersDocAccessFields_roles_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersDocAccessFields_member"]: AliasType<{
	create?:ValueTypes["UsersDocAccessFields_member_Create"],
	read?:ValueTypes["UsersDocAccessFields_member_Read"],
	update?:ValueTypes["UsersDocAccessFields_member_Update"],
	delete?:ValueTypes["UsersDocAccessFields_member_Delete"],
	fields?:ValueTypes["UsersDocAccessFields_member_Fields"],
		__typename?: boolean | `@${string}`
}>;
	["UsersDocAccessFields_member_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersDocAccessFields_member_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersDocAccessFields_member_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersDocAccessFields_member_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersDocAccessFields_member_Fields"]: AliasType<{
	region?:ValueTypes["UsersDocAccessFields_member_region"],
	language?:ValueTypes["UsersDocAccessFields_member_language"],
	phone?:ValueTypes["UsersDocAccessFields_member_phone"],
	avatar?:ValueTypes["UsersDocAccessFields_member_avatar"],
	birthday?:ValueTypes["UsersDocAccessFields_member_birthday"],
	gender?:ValueTypes["UsersDocAccessFields_member_gender"],
	categories?:ValueTypes["UsersDocAccessFields_member_categories"],
		__typename?: boolean | `@${string}`
}>;
	["UsersDocAccessFields_member_region"]: AliasType<{
	create?:ValueTypes["UsersDocAccessFields_member_region_Create"],
	read?:ValueTypes["UsersDocAccessFields_member_region_Read"],
	update?:ValueTypes["UsersDocAccessFields_member_region_Update"],
	delete?:ValueTypes["UsersDocAccessFields_member_region_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["UsersDocAccessFields_member_region_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersDocAccessFields_member_region_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersDocAccessFields_member_region_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersDocAccessFields_member_region_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersDocAccessFields_member_language"]: AliasType<{
	create?:ValueTypes["UsersDocAccessFields_member_language_Create"],
	read?:ValueTypes["UsersDocAccessFields_member_language_Read"],
	update?:ValueTypes["UsersDocAccessFields_member_language_Update"],
	delete?:ValueTypes["UsersDocAccessFields_member_language_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["UsersDocAccessFields_member_language_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersDocAccessFields_member_language_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersDocAccessFields_member_language_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersDocAccessFields_member_language_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersDocAccessFields_member_phone"]: AliasType<{
	create?:ValueTypes["UsersDocAccessFields_member_phone_Create"],
	read?:ValueTypes["UsersDocAccessFields_member_phone_Read"],
	update?:ValueTypes["UsersDocAccessFields_member_phone_Update"],
	delete?:ValueTypes["UsersDocAccessFields_member_phone_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["UsersDocAccessFields_member_phone_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersDocAccessFields_member_phone_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersDocAccessFields_member_phone_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersDocAccessFields_member_phone_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersDocAccessFields_member_avatar"]: AliasType<{
	create?:ValueTypes["UsersDocAccessFields_member_avatar_Create"],
	read?:ValueTypes["UsersDocAccessFields_member_avatar_Read"],
	update?:ValueTypes["UsersDocAccessFields_member_avatar_Update"],
	delete?:ValueTypes["UsersDocAccessFields_member_avatar_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["UsersDocAccessFields_member_avatar_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersDocAccessFields_member_avatar_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersDocAccessFields_member_avatar_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersDocAccessFields_member_avatar_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersDocAccessFields_member_birthday"]: AliasType<{
	create?:ValueTypes["UsersDocAccessFields_member_birthday_Create"],
	read?:ValueTypes["UsersDocAccessFields_member_birthday_Read"],
	update?:ValueTypes["UsersDocAccessFields_member_birthday_Update"],
	delete?:ValueTypes["UsersDocAccessFields_member_birthday_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["UsersDocAccessFields_member_birthday_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersDocAccessFields_member_birthday_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersDocAccessFields_member_birthday_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersDocAccessFields_member_birthday_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersDocAccessFields_member_gender"]: AliasType<{
	create?:ValueTypes["UsersDocAccessFields_member_gender_Create"],
	read?:ValueTypes["UsersDocAccessFields_member_gender_Read"],
	update?:ValueTypes["UsersDocAccessFields_member_gender_Update"],
	delete?:ValueTypes["UsersDocAccessFields_member_gender_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["UsersDocAccessFields_member_gender_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersDocAccessFields_member_gender_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersDocAccessFields_member_gender_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersDocAccessFields_member_gender_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersDocAccessFields_member_categories"]: AliasType<{
	create?:ValueTypes["UsersDocAccessFields_member_categories_Create"],
	read?:ValueTypes["UsersDocAccessFields_member_categories_Read"],
	update?:ValueTypes["UsersDocAccessFields_member_categories_Update"],
	delete?:ValueTypes["UsersDocAccessFields_member_categories_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["UsersDocAccessFields_member_categories_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersDocAccessFields_member_categories_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersDocAccessFields_member_categories_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersDocAccessFields_member_categories_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersDocAccessFields_organiser"]: AliasType<{
	create?:ValueTypes["UsersDocAccessFields_organiser_Create"],
	read?:ValueTypes["UsersDocAccessFields_organiser_Read"],
	update?:ValueTypes["UsersDocAccessFields_organiser_Update"],
	delete?:ValueTypes["UsersDocAccessFields_organiser_Delete"],
	fields?:ValueTypes["UsersDocAccessFields_organiser_Fields"],
		__typename?: boolean | `@${string}`
}>;
	["UsersDocAccessFields_organiser_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersDocAccessFields_organiser_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersDocAccessFields_organiser_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersDocAccessFields_organiser_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersDocAccessFields_organiser_Fields"]: AliasType<{
	logo?:ValueTypes["UsersDocAccessFields_organiser_logo"],
	banner?:ValueTypes["UsersDocAccessFields_organiser_banner"],
	description?:ValueTypes["UsersDocAccessFields_organiser_description"],
	foundedIn?:ValueTypes["UsersDocAccessFields_organiser_foundedIn"],
	address?:ValueTypes["UsersDocAccessFields_organiser_address"],
	website?:ValueTypes["UsersDocAccessFields_organiser_website"],
	facebook?:ValueTypes["UsersDocAccessFields_organiser_facebook"],
	instagram?:ValueTypes["UsersDocAccessFields_organiser_instagram"],
	language?:ValueTypes["UsersDocAccessFields_organiser_language"],
	phone?:ValueTypes["UsersDocAccessFields_organiser_phone"],
	followers?:ValueTypes["UsersDocAccessFields_organiser_followers"],
		__typename?: boolean | `@${string}`
}>;
	["UsersDocAccessFields_organiser_logo"]: AliasType<{
	create?:ValueTypes["UsersDocAccessFields_organiser_logo_Create"],
	read?:ValueTypes["UsersDocAccessFields_organiser_logo_Read"],
	update?:ValueTypes["UsersDocAccessFields_organiser_logo_Update"],
	delete?:ValueTypes["UsersDocAccessFields_organiser_logo_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["UsersDocAccessFields_organiser_logo_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersDocAccessFields_organiser_logo_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersDocAccessFields_organiser_logo_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersDocAccessFields_organiser_logo_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersDocAccessFields_organiser_banner"]: AliasType<{
	create?:ValueTypes["UsersDocAccessFields_organiser_banner_Create"],
	read?:ValueTypes["UsersDocAccessFields_organiser_banner_Read"],
	update?:ValueTypes["UsersDocAccessFields_organiser_banner_Update"],
	delete?:ValueTypes["UsersDocAccessFields_organiser_banner_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["UsersDocAccessFields_organiser_banner_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersDocAccessFields_organiser_banner_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersDocAccessFields_organiser_banner_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersDocAccessFields_organiser_banner_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersDocAccessFields_organiser_description"]: AliasType<{
	create?:ValueTypes["UsersDocAccessFields_organiser_description_Create"],
	read?:ValueTypes["UsersDocAccessFields_organiser_description_Read"],
	update?:ValueTypes["UsersDocAccessFields_organiser_description_Update"],
	delete?:ValueTypes["UsersDocAccessFields_organiser_description_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["UsersDocAccessFields_organiser_description_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersDocAccessFields_organiser_description_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersDocAccessFields_organiser_description_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersDocAccessFields_organiser_description_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersDocAccessFields_organiser_foundedIn"]: AliasType<{
	create?:ValueTypes["UsersDocAccessFields_organiser_foundedIn_Create"],
	read?:ValueTypes["UsersDocAccessFields_organiser_foundedIn_Read"],
	update?:ValueTypes["UsersDocAccessFields_organiser_foundedIn_Update"],
	delete?:ValueTypes["UsersDocAccessFields_organiser_foundedIn_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["UsersDocAccessFields_organiser_foundedIn_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersDocAccessFields_organiser_foundedIn_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersDocAccessFields_organiser_foundedIn_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersDocAccessFields_organiser_foundedIn_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersDocAccessFields_organiser_address"]: AliasType<{
	create?:ValueTypes["UsersDocAccessFields_organiser_address_Create"],
	read?:ValueTypes["UsersDocAccessFields_organiser_address_Read"],
	update?:ValueTypes["UsersDocAccessFields_organiser_address_Update"],
	delete?:ValueTypes["UsersDocAccessFields_organiser_address_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["UsersDocAccessFields_organiser_address_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersDocAccessFields_organiser_address_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersDocAccessFields_organiser_address_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersDocAccessFields_organiser_address_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersDocAccessFields_organiser_website"]: AliasType<{
	create?:ValueTypes["UsersDocAccessFields_organiser_website_Create"],
	read?:ValueTypes["UsersDocAccessFields_organiser_website_Read"],
	update?:ValueTypes["UsersDocAccessFields_organiser_website_Update"],
	delete?:ValueTypes["UsersDocAccessFields_organiser_website_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["UsersDocAccessFields_organiser_website_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersDocAccessFields_organiser_website_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersDocAccessFields_organiser_website_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersDocAccessFields_organiser_website_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersDocAccessFields_organiser_facebook"]: AliasType<{
	create?:ValueTypes["UsersDocAccessFields_organiser_facebook_Create"],
	read?:ValueTypes["UsersDocAccessFields_organiser_facebook_Read"],
	update?:ValueTypes["UsersDocAccessFields_organiser_facebook_Update"],
	delete?:ValueTypes["UsersDocAccessFields_organiser_facebook_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["UsersDocAccessFields_organiser_facebook_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersDocAccessFields_organiser_facebook_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersDocAccessFields_organiser_facebook_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersDocAccessFields_organiser_facebook_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersDocAccessFields_organiser_instagram"]: AliasType<{
	create?:ValueTypes["UsersDocAccessFields_organiser_instagram_Create"],
	read?:ValueTypes["UsersDocAccessFields_organiser_instagram_Read"],
	update?:ValueTypes["UsersDocAccessFields_organiser_instagram_Update"],
	delete?:ValueTypes["UsersDocAccessFields_organiser_instagram_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["UsersDocAccessFields_organiser_instagram_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersDocAccessFields_organiser_instagram_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersDocAccessFields_organiser_instagram_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersDocAccessFields_organiser_instagram_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersDocAccessFields_organiser_language"]: AliasType<{
	create?:ValueTypes["UsersDocAccessFields_organiser_language_Create"],
	read?:ValueTypes["UsersDocAccessFields_organiser_language_Read"],
	update?:ValueTypes["UsersDocAccessFields_organiser_language_Update"],
	delete?:ValueTypes["UsersDocAccessFields_organiser_language_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["UsersDocAccessFields_organiser_language_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersDocAccessFields_organiser_language_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersDocAccessFields_organiser_language_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersDocAccessFields_organiser_language_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersDocAccessFields_organiser_phone"]: AliasType<{
	create?:ValueTypes["UsersDocAccessFields_organiser_phone_Create"],
	read?:ValueTypes["UsersDocAccessFields_organiser_phone_Read"],
	update?:ValueTypes["UsersDocAccessFields_organiser_phone_Update"],
	delete?:ValueTypes["UsersDocAccessFields_organiser_phone_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["UsersDocAccessFields_organiser_phone_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersDocAccessFields_organiser_phone_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersDocAccessFields_organiser_phone_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersDocAccessFields_organiser_phone_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersDocAccessFields_organiser_followers"]: AliasType<{
	create?:ValueTypes["UsersDocAccessFields_organiser_followers_Create"],
	read?:ValueTypes["UsersDocAccessFields_organiser_followers_Read"],
	update?:ValueTypes["UsersDocAccessFields_organiser_followers_Update"],
	delete?:ValueTypes["UsersDocAccessFields_organiser_followers_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["UsersDocAccessFields_organiser_followers_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersDocAccessFields_organiser_followers_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersDocAccessFields_organiser_followers_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersDocAccessFields_organiser_followers_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersDocAccessFields_emailVerified"]: AliasType<{
	create?:ValueTypes["UsersDocAccessFields_emailVerified_Create"],
	read?:ValueTypes["UsersDocAccessFields_emailVerified_Read"],
	update?:ValueTypes["UsersDocAccessFields_emailVerified_Update"],
	delete?:ValueTypes["UsersDocAccessFields_emailVerified_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["UsersDocAccessFields_emailVerified_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersDocAccessFields_emailVerified_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersDocAccessFields_emailVerified_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersDocAccessFields_emailVerified_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersDocAccessFields_sso"]: AliasType<{
	create?:ValueTypes["UsersDocAccessFields_sso_Create"],
	read?:ValueTypes["UsersDocAccessFields_sso_Read"],
	update?:ValueTypes["UsersDocAccessFields_sso_Update"],
	delete?:ValueTypes["UsersDocAccessFields_sso_Delete"],
	fields?:ValueTypes["UsersDocAccessFields_sso_Fields"],
		__typename?: boolean | `@${string}`
}>;
	["UsersDocAccessFields_sso_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersDocAccessFields_sso_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersDocAccessFields_sso_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersDocAccessFields_sso_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersDocAccessFields_sso_Fields"]: AliasType<{
	googleId?:ValueTypes["UsersDocAccessFields_sso_googleId"],
	facebookId?:ValueTypes["UsersDocAccessFields_sso_facebookId"],
	appleId?:ValueTypes["UsersDocAccessFields_sso_appleId"],
		__typename?: boolean | `@${string}`
}>;
	["UsersDocAccessFields_sso_googleId"]: AliasType<{
	create?:ValueTypes["UsersDocAccessFields_sso_googleId_Create"],
	read?:ValueTypes["UsersDocAccessFields_sso_googleId_Read"],
	update?:ValueTypes["UsersDocAccessFields_sso_googleId_Update"],
	delete?:ValueTypes["UsersDocAccessFields_sso_googleId_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["UsersDocAccessFields_sso_googleId_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersDocAccessFields_sso_googleId_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersDocAccessFields_sso_googleId_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersDocAccessFields_sso_googleId_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersDocAccessFields_sso_facebookId"]: AliasType<{
	create?:ValueTypes["UsersDocAccessFields_sso_facebookId_Create"],
	read?:ValueTypes["UsersDocAccessFields_sso_facebookId_Read"],
	update?:ValueTypes["UsersDocAccessFields_sso_facebookId_Update"],
	delete?:ValueTypes["UsersDocAccessFields_sso_facebookId_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["UsersDocAccessFields_sso_facebookId_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersDocAccessFields_sso_facebookId_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersDocAccessFields_sso_facebookId_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersDocAccessFields_sso_facebookId_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersDocAccessFields_sso_appleId"]: AliasType<{
	create?:ValueTypes["UsersDocAccessFields_sso_appleId_Create"],
	read?:ValueTypes["UsersDocAccessFields_sso_appleId_Read"],
	update?:ValueTypes["UsersDocAccessFields_sso_appleId_Update"],
	delete?:ValueTypes["UsersDocAccessFields_sso_appleId_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["UsersDocAccessFields_sso_appleId_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersDocAccessFields_sso_appleId_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersDocAccessFields_sso_appleId_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersDocAccessFields_sso_appleId_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersDocAccessFields_oldPassword"]: AliasType<{
	create?:ValueTypes["UsersDocAccessFields_oldPassword_Create"],
	read?:ValueTypes["UsersDocAccessFields_oldPassword_Read"],
	update?:ValueTypes["UsersDocAccessFields_oldPassword_Update"],
	delete?:ValueTypes["UsersDocAccessFields_oldPassword_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["UsersDocAccessFields_oldPassword_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersDocAccessFields_oldPassword_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersDocAccessFields_oldPassword_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersDocAccessFields_oldPassword_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersDocAccessFields_status"]: AliasType<{
	create?:ValueTypes["UsersDocAccessFields_status_Create"],
	read?:ValueTypes["UsersDocAccessFields_status_Read"],
	update?:ValueTypes["UsersDocAccessFields_status_Update"],
	delete?:ValueTypes["UsersDocAccessFields_status_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["UsersDocAccessFields_status_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersDocAccessFields_status_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersDocAccessFields_status_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersDocAccessFields_status_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersDocAccessFields_updatedAt"]: AliasType<{
	create?:ValueTypes["UsersDocAccessFields_updatedAt_Create"],
	read?:ValueTypes["UsersDocAccessFields_updatedAt_Read"],
	update?:ValueTypes["UsersDocAccessFields_updatedAt_Update"],
	delete?:ValueTypes["UsersDocAccessFields_updatedAt_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["UsersDocAccessFields_updatedAt_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersDocAccessFields_updatedAt_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersDocAccessFields_updatedAt_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersDocAccessFields_updatedAt_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersDocAccessFields_createdAt"]: AliasType<{
	create?:ValueTypes["UsersDocAccessFields_createdAt_Create"],
	read?:ValueTypes["UsersDocAccessFields_createdAt_Read"],
	update?:ValueTypes["UsersDocAccessFields_createdAt_Update"],
	delete?:ValueTypes["UsersDocAccessFields_createdAt_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["UsersDocAccessFields_createdAt_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersDocAccessFields_createdAt_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersDocAccessFields_createdAt_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersDocAccessFields_createdAt_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersDocAccessFields_email"]: AliasType<{
	create?:ValueTypes["UsersDocAccessFields_email_Create"],
	read?:ValueTypes["UsersDocAccessFields_email_Read"],
	update?:ValueTypes["UsersDocAccessFields_email_Update"],
	delete?:ValueTypes["UsersDocAccessFields_email_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["UsersDocAccessFields_email_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersDocAccessFields_email_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersDocAccessFields_email_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersDocAccessFields_email_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersDocAccessFields_password"]: AliasType<{
	create?:ValueTypes["UsersDocAccessFields_password_Create"],
	read?:ValueTypes["UsersDocAccessFields_password_Read"],
	update?:ValueTypes["UsersDocAccessFields_password_Update"],
	delete?:ValueTypes["UsersDocAccessFields_password_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["UsersDocAccessFields_password_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersDocAccessFields_password_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersDocAccessFields_password_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersDocAccessFields_password_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersCreateDocAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersReadDocAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersUpdateDocAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersDeleteDocAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersUnlockDocAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["usersMe"]: AliasType<{
	collection?:boolean | `@${string}`,
	exp?:boolean | `@${string}`,
	strategy?:boolean | `@${string}`,
	token?:boolean | `@${string}`,
	user?:ValueTypes["User"],
		__typename?: boolean | `@${string}`
}>;
	["Bookmark"]: AliasType<{
	id?:boolean | `@${string}`,
user?: [{	locale?: ValueTypes["LocaleInputType"] | undefined | null | Variable<any, string>,	fallbackLocale?: ValueTypes["FallbackLocaleInputType"] | undefined | null | Variable<any, string>},ValueTypes["Bookmark_User_Relationship"]],
item?: [{	locale?: ValueTypes["LocaleInputType"] | undefined | null | Variable<any, string>,	fallbackLocale?: ValueTypes["FallbackLocaleInputType"] | undefined | null | Variable<any, string>},ValueTypes["Bookmark_Item_Relationship"]],
	updatedAt?:boolean | `@${string}`,
	createdAt?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["Bookmark_User_Relationship"]: AliasType<{
	relationTo?:boolean | `@${string}`,
	value?:ValueTypes["Bookmark_User"],
		__typename?: boolean | `@${string}`
}>;
	["Bookmark_User_RelationTo"]:Bookmark_User_RelationTo;
	["Bookmark_User"]: AliasType<{		["...on User"]?: ValueTypes["User"]
		__typename?: boolean | `@${string}`
}>;
	["Bookmark_Item_Relationship"]: AliasType<{
	relationTo?:boolean | `@${string}`,
	value?:ValueTypes["Bookmark_Item"],
		__typename?: boolean | `@${string}`
}>;
	["Bookmark_Item_RelationTo"]:Bookmark_Item_RelationTo;
	["Bookmark_Item"]: AliasType<{		["...on Event"]?: ValueTypes["Event"],
		["...on Location"]?: ValueTypes["Location"],
		["...on Post"]?: ValueTypes["Post"]
		__typename?: boolean | `@${string}`
}>;
	["Bookmarks"]: AliasType<{
	docs?:ValueTypes["Bookmark"],
	hasNextPage?:boolean | `@${string}`,
	hasPrevPage?:boolean | `@${string}`,
	limit?:boolean | `@${string}`,
	nextPage?:boolean | `@${string}`,
	offset?:boolean | `@${string}`,
	page?:boolean | `@${string}`,
	pagingCounter?:boolean | `@${string}`,
	prevPage?:boolean | `@${string}`,
	totalDocs?:boolean | `@${string}`,
	totalPages?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["Bookmark_where"]: {
	user?: ValueTypes["Bookmark_user_Relation"] | undefined | null | Variable<any, string>,
	item?: ValueTypes["Bookmark_item_Relation"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["Bookmark_updatedAt_operator"] | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["Bookmark_createdAt_operator"] | undefined | null | Variable<any, string>,
	id?: ValueTypes["Bookmark_id_operator"] | undefined | null | Variable<any, string>,
	AND?: Array<ValueTypes["Bookmark_where_and"] | undefined | null> | undefined | null | Variable<any, string>,
	OR?: Array<ValueTypes["Bookmark_where_or"] | undefined | null> | undefined | null | Variable<any, string>
};
	["Bookmark_user_Relation"]: {
	relationTo?: ValueTypes["Bookmark_user_Relation_RelationTo"] | undefined | null | Variable<any, string>,
	value?: ValueTypes["JSON"] | undefined | null | Variable<any, string>
};
	["Bookmark_user_Relation_RelationTo"]:Bookmark_user_Relation_RelationTo;
	["Bookmark_item_Relation"]: {
	relationTo?: ValueTypes["Bookmark_item_Relation_RelationTo"] | undefined | null | Variable<any, string>,
	value?: ValueTypes["JSON"] | undefined | null | Variable<any, string>
};
	["Bookmark_item_Relation_RelationTo"]:Bookmark_item_Relation_RelationTo;
	["Bookmark_updatedAt_operator"]: {
	equals?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	not_equals?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	greater_than_equal?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	greater_than?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	less_than_equal?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	less_than?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	like?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Bookmark_createdAt_operator"]: {
	equals?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	not_equals?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	greater_than_equal?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	greater_than?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	less_than_equal?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	less_than?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	like?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Bookmark_id_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Bookmark_where_and"]: {
	user?: ValueTypes["Bookmark_user_Relation"] | undefined | null | Variable<any, string>,
	item?: ValueTypes["Bookmark_item_Relation"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["Bookmark_updatedAt_operator"] | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["Bookmark_createdAt_operator"] | undefined | null | Variable<any, string>,
	id?: ValueTypes["Bookmark_id_operator"] | undefined | null | Variable<any, string>,
	AND?: Array<ValueTypes["Bookmark_where_and"] | undefined | null> | undefined | null | Variable<any, string>,
	OR?: Array<ValueTypes["Bookmark_where_or"] | undefined | null> | undefined | null | Variable<any, string>
};
	["Bookmark_where_or"]: {
	user?: ValueTypes["Bookmark_user_Relation"] | undefined | null | Variable<any, string>,
	item?: ValueTypes["Bookmark_item_Relation"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["Bookmark_updatedAt_operator"] | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["Bookmark_createdAt_operator"] | undefined | null | Variable<any, string>,
	id?: ValueTypes["Bookmark_id_operator"] | undefined | null | Variable<any, string>,
	AND?: Array<ValueTypes["Bookmark_where_and"] | undefined | null> | undefined | null | Variable<any, string>,
	OR?: Array<ValueTypes["Bookmark_where_or"] | undefined | null> | undefined | null | Variable<any, string>
};
	["countBookmarks"]: AliasType<{
	totalDocs?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["bookmarksDocAccess"]: AliasType<{
	fields?:ValueTypes["BookmarksDocAccessFields"],
	create?:ValueTypes["BookmarksCreateDocAccess"],
	read?:ValueTypes["BookmarksReadDocAccess"],
	update?:ValueTypes["BookmarksUpdateDocAccess"],
	delete?:ValueTypes["BookmarksDeleteDocAccess"],
		__typename?: boolean | `@${string}`
}>;
	["BookmarksDocAccessFields"]: AliasType<{
	user?:ValueTypes["BookmarksDocAccessFields_user"],
	item?:ValueTypes["BookmarksDocAccessFields_item"],
	updatedAt?:ValueTypes["BookmarksDocAccessFields_updatedAt"],
	createdAt?:ValueTypes["BookmarksDocAccessFields_createdAt"],
		__typename?: boolean | `@${string}`
}>;
	["BookmarksDocAccessFields_user"]: AliasType<{
	create?:ValueTypes["BookmarksDocAccessFields_user_Create"],
	read?:ValueTypes["BookmarksDocAccessFields_user_Read"],
	update?:ValueTypes["BookmarksDocAccessFields_user_Update"],
	delete?:ValueTypes["BookmarksDocAccessFields_user_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["BookmarksDocAccessFields_user_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BookmarksDocAccessFields_user_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BookmarksDocAccessFields_user_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BookmarksDocAccessFields_user_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BookmarksDocAccessFields_item"]: AliasType<{
	create?:ValueTypes["BookmarksDocAccessFields_item_Create"],
	read?:ValueTypes["BookmarksDocAccessFields_item_Read"],
	update?:ValueTypes["BookmarksDocAccessFields_item_Update"],
	delete?:ValueTypes["BookmarksDocAccessFields_item_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["BookmarksDocAccessFields_item_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BookmarksDocAccessFields_item_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BookmarksDocAccessFields_item_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BookmarksDocAccessFields_item_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BookmarksDocAccessFields_updatedAt"]: AliasType<{
	create?:ValueTypes["BookmarksDocAccessFields_updatedAt_Create"],
	read?:ValueTypes["BookmarksDocAccessFields_updatedAt_Read"],
	update?:ValueTypes["BookmarksDocAccessFields_updatedAt_Update"],
	delete?:ValueTypes["BookmarksDocAccessFields_updatedAt_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["BookmarksDocAccessFields_updatedAt_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BookmarksDocAccessFields_updatedAt_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BookmarksDocAccessFields_updatedAt_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BookmarksDocAccessFields_updatedAt_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BookmarksDocAccessFields_createdAt"]: AliasType<{
	create?:ValueTypes["BookmarksDocAccessFields_createdAt_Create"],
	read?:ValueTypes["BookmarksDocAccessFields_createdAt_Read"],
	update?:ValueTypes["BookmarksDocAccessFields_createdAt_Update"],
	delete?:ValueTypes["BookmarksDocAccessFields_createdAt_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["BookmarksDocAccessFields_createdAt_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BookmarksDocAccessFields_createdAt_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BookmarksDocAccessFields_createdAt_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BookmarksDocAccessFields_createdAt_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BookmarksCreateDocAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BookmarksReadDocAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BookmarksUpdateDocAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BookmarksDeleteDocAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["WebAppBookmark"]: AliasType<{
	id?:boolean | `@${string}`,
user?: [{	locale?: ValueTypes["LocaleInputType"] | undefined | null | Variable<any, string>,	fallbackLocale?: ValueTypes["FallbackLocaleInputType"] | undefined | null | Variable<any, string>},ValueTypes["WebAppBookmark_User_Relationship"]],
event?: [{	locale?: ValueTypes["LocaleInputType"] | undefined | null | Variable<any, string>,	fallbackLocale?: ValueTypes["FallbackLocaleInputType"] | undefined | null | Variable<any, string>},ValueTypes["WebAppBookmark_Event_Relationship"]],
	productType?:boolean | `@${string}`,
	item?:boolean | `@${string}`,
	entryNumber?:boolean | `@${string}`,
	updatedAt?:boolean | `@${string}`,
	createdAt?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["WebAppBookmark_User_Relationship"]: AliasType<{
	relationTo?:boolean | `@${string}`,
	value?:ValueTypes["WebAppBookmark_User"],
		__typename?: boolean | `@${string}`
}>;
	["WebAppBookmark_User_RelationTo"]:WebAppBookmark_User_RelationTo;
	["WebAppBookmark_User"]: AliasType<{		["...on User"]?: ValueTypes["User"]
		__typename?: boolean | `@${string}`
}>;
	["WebAppBookmark_Event_Relationship"]: AliasType<{
	relationTo?:boolean | `@${string}`,
	value?:ValueTypes["WebAppBookmark_Event"],
		__typename?: boolean | `@${string}`
}>;
	["WebAppBookmark_Event_RelationTo"]:WebAppBookmark_Event_RelationTo;
	["WebAppBookmark_Event"]: AliasType<{		["...on Event"]?: ValueTypes["Event"]
		__typename?: boolean | `@${string}`
}>;
	["WebAppBookmark_productType"]:WebAppBookmark_productType;
	["WebAppBookmarks"]: AliasType<{
	docs?:ValueTypes["WebAppBookmark"],
	hasNextPage?:boolean | `@${string}`,
	hasPrevPage?:boolean | `@${string}`,
	limit?:boolean | `@${string}`,
	nextPage?:boolean | `@${string}`,
	offset?:boolean | `@${string}`,
	page?:boolean | `@${string}`,
	pagingCounter?:boolean | `@${string}`,
	prevPage?:boolean | `@${string}`,
	totalDocs?:boolean | `@${string}`,
	totalPages?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["WebAppBookmark_where"]: {
	user?: ValueTypes["WebAppBookmark_user_Relation"] | undefined | null | Variable<any, string>,
	event?: ValueTypes["WebAppBookmark_event_Relation"] | undefined | null | Variable<any, string>,
	productType?: ValueTypes["WebAppBookmark_productType_operator"] | undefined | null | Variable<any, string>,
	item?: ValueTypes["WebAppBookmark_item_operator"] | undefined | null | Variable<any, string>,
	entryNumber?: ValueTypes["WebAppBookmark_entryNumber_operator"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["WebAppBookmark_updatedAt_operator"] | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["WebAppBookmark_createdAt_operator"] | undefined | null | Variable<any, string>,
	id?: ValueTypes["WebAppBookmark_id_operator"] | undefined | null | Variable<any, string>,
	AND?: Array<ValueTypes["WebAppBookmark_where_and"] | undefined | null> | undefined | null | Variable<any, string>,
	OR?: Array<ValueTypes["WebAppBookmark_where_or"] | undefined | null> | undefined | null | Variable<any, string>
};
	["WebAppBookmark_user_Relation"]: {
	relationTo?: ValueTypes["WebAppBookmark_user_Relation_RelationTo"] | undefined | null | Variable<any, string>,
	value?: ValueTypes["JSON"] | undefined | null | Variable<any, string>
};
	["WebAppBookmark_user_Relation_RelationTo"]:WebAppBookmark_user_Relation_RelationTo;
	["WebAppBookmark_event_Relation"]: {
	relationTo?: ValueTypes["WebAppBookmark_event_Relation_RelationTo"] | undefined | null | Variable<any, string>,
	value?: ValueTypes["JSON"] | undefined | null | Variable<any, string>
};
	["WebAppBookmark_event_Relation_RelationTo"]:WebAppBookmark_event_Relation_RelationTo;
	["WebAppBookmark_productType_operator"]: {
	equals?: ValueTypes["WebAppBookmark_productType_Input"] | undefined | null | Variable<any, string>,
	not_equals?: ValueTypes["WebAppBookmark_productType_Input"] | undefined | null | Variable<any, string>,
	in?: Array<ValueTypes["WebAppBookmark_productType_Input"] | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<ValueTypes["WebAppBookmark_productType_Input"] | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<ValueTypes["WebAppBookmark_productType_Input"] | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["WebAppBookmark_productType_Input"]:WebAppBookmark_productType_Input;
	["WebAppBookmark_item_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["WebAppBookmark_entryNumber_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["WebAppBookmark_updatedAt_operator"]: {
	equals?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	not_equals?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	greater_than_equal?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	greater_than?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	less_than_equal?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	less_than?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	like?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["WebAppBookmark_createdAt_operator"]: {
	equals?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	not_equals?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	greater_than_equal?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	greater_than?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	less_than_equal?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	less_than?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	like?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["WebAppBookmark_id_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["WebAppBookmark_where_and"]: {
	user?: ValueTypes["WebAppBookmark_user_Relation"] | undefined | null | Variable<any, string>,
	event?: ValueTypes["WebAppBookmark_event_Relation"] | undefined | null | Variable<any, string>,
	productType?: ValueTypes["WebAppBookmark_productType_operator"] | undefined | null | Variable<any, string>,
	item?: ValueTypes["WebAppBookmark_item_operator"] | undefined | null | Variable<any, string>,
	entryNumber?: ValueTypes["WebAppBookmark_entryNumber_operator"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["WebAppBookmark_updatedAt_operator"] | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["WebAppBookmark_createdAt_operator"] | undefined | null | Variable<any, string>,
	id?: ValueTypes["WebAppBookmark_id_operator"] | undefined | null | Variable<any, string>,
	AND?: Array<ValueTypes["WebAppBookmark_where_and"] | undefined | null> | undefined | null | Variable<any, string>,
	OR?: Array<ValueTypes["WebAppBookmark_where_or"] | undefined | null> | undefined | null | Variable<any, string>
};
	["WebAppBookmark_where_or"]: {
	user?: ValueTypes["WebAppBookmark_user_Relation"] | undefined | null | Variable<any, string>,
	event?: ValueTypes["WebAppBookmark_event_Relation"] | undefined | null | Variable<any, string>,
	productType?: ValueTypes["WebAppBookmark_productType_operator"] | undefined | null | Variable<any, string>,
	item?: ValueTypes["WebAppBookmark_item_operator"] | undefined | null | Variable<any, string>,
	entryNumber?: ValueTypes["WebAppBookmark_entryNumber_operator"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["WebAppBookmark_updatedAt_operator"] | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["WebAppBookmark_createdAt_operator"] | undefined | null | Variable<any, string>,
	id?: ValueTypes["WebAppBookmark_id_operator"] | undefined | null | Variable<any, string>,
	AND?: Array<ValueTypes["WebAppBookmark_where_and"] | undefined | null> | undefined | null | Variable<any, string>,
	OR?: Array<ValueTypes["WebAppBookmark_where_or"] | undefined | null> | undefined | null | Variable<any, string>
};
	["countWebAppBookmarks"]: AliasType<{
	totalDocs?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["webAppBookmarksDocAccess"]: AliasType<{
	fields?:ValueTypes["WebAppBookmarksDocAccessFields"],
	create?:ValueTypes["WebAppBookmarksCreateDocAccess"],
	read?:ValueTypes["WebAppBookmarksReadDocAccess"],
	update?:ValueTypes["WebAppBookmarksUpdateDocAccess"],
	delete?:ValueTypes["WebAppBookmarksDeleteDocAccess"],
		__typename?: boolean | `@${string}`
}>;
	["WebAppBookmarksDocAccessFields"]: AliasType<{
	user?:ValueTypes["WebAppBookmarksDocAccessFields_user"],
	event?:ValueTypes["WebAppBookmarksDocAccessFields_event"],
	productType?:ValueTypes["WebAppBookmarksDocAccessFields_productType"],
	item?:ValueTypes["WebAppBookmarksDocAccessFields_item"],
	entryNumber?:ValueTypes["WebAppBookmarksDocAccessFields_entryNumber"],
	updatedAt?:ValueTypes["WebAppBookmarksDocAccessFields_updatedAt"],
	createdAt?:ValueTypes["WebAppBookmarksDocAccessFields_createdAt"],
		__typename?: boolean | `@${string}`
}>;
	["WebAppBookmarksDocAccessFields_user"]: AliasType<{
	create?:ValueTypes["WebAppBookmarksDocAccessFields_user_Create"],
	read?:ValueTypes["WebAppBookmarksDocAccessFields_user_Read"],
	update?:ValueTypes["WebAppBookmarksDocAccessFields_user_Update"],
	delete?:ValueTypes["WebAppBookmarksDocAccessFields_user_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["WebAppBookmarksDocAccessFields_user_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["WebAppBookmarksDocAccessFields_user_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["WebAppBookmarksDocAccessFields_user_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["WebAppBookmarksDocAccessFields_user_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["WebAppBookmarksDocAccessFields_event"]: AliasType<{
	create?:ValueTypes["WebAppBookmarksDocAccessFields_event_Create"],
	read?:ValueTypes["WebAppBookmarksDocAccessFields_event_Read"],
	update?:ValueTypes["WebAppBookmarksDocAccessFields_event_Update"],
	delete?:ValueTypes["WebAppBookmarksDocAccessFields_event_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["WebAppBookmarksDocAccessFields_event_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["WebAppBookmarksDocAccessFields_event_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["WebAppBookmarksDocAccessFields_event_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["WebAppBookmarksDocAccessFields_event_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["WebAppBookmarksDocAccessFields_productType"]: AliasType<{
	create?:ValueTypes["WebAppBookmarksDocAccessFields_productType_Create"],
	read?:ValueTypes["WebAppBookmarksDocAccessFields_productType_Read"],
	update?:ValueTypes["WebAppBookmarksDocAccessFields_productType_Update"],
	delete?:ValueTypes["WebAppBookmarksDocAccessFields_productType_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["WebAppBookmarksDocAccessFields_productType_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["WebAppBookmarksDocAccessFields_productType_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["WebAppBookmarksDocAccessFields_productType_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["WebAppBookmarksDocAccessFields_productType_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["WebAppBookmarksDocAccessFields_item"]: AliasType<{
	create?:ValueTypes["WebAppBookmarksDocAccessFields_item_Create"],
	read?:ValueTypes["WebAppBookmarksDocAccessFields_item_Read"],
	update?:ValueTypes["WebAppBookmarksDocAccessFields_item_Update"],
	delete?:ValueTypes["WebAppBookmarksDocAccessFields_item_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["WebAppBookmarksDocAccessFields_item_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["WebAppBookmarksDocAccessFields_item_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["WebAppBookmarksDocAccessFields_item_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["WebAppBookmarksDocAccessFields_item_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["WebAppBookmarksDocAccessFields_entryNumber"]: AliasType<{
	create?:ValueTypes["WebAppBookmarksDocAccessFields_entryNumber_Create"],
	read?:ValueTypes["WebAppBookmarksDocAccessFields_entryNumber_Read"],
	update?:ValueTypes["WebAppBookmarksDocAccessFields_entryNumber_Update"],
	delete?:ValueTypes["WebAppBookmarksDocAccessFields_entryNumber_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["WebAppBookmarksDocAccessFields_entryNumber_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["WebAppBookmarksDocAccessFields_entryNumber_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["WebAppBookmarksDocAccessFields_entryNumber_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["WebAppBookmarksDocAccessFields_entryNumber_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["WebAppBookmarksDocAccessFields_updatedAt"]: AliasType<{
	create?:ValueTypes["WebAppBookmarksDocAccessFields_updatedAt_Create"],
	read?:ValueTypes["WebAppBookmarksDocAccessFields_updatedAt_Read"],
	update?:ValueTypes["WebAppBookmarksDocAccessFields_updatedAt_Update"],
	delete?:ValueTypes["WebAppBookmarksDocAccessFields_updatedAt_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["WebAppBookmarksDocAccessFields_updatedAt_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["WebAppBookmarksDocAccessFields_updatedAt_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["WebAppBookmarksDocAccessFields_updatedAt_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["WebAppBookmarksDocAccessFields_updatedAt_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["WebAppBookmarksDocAccessFields_createdAt"]: AliasType<{
	create?:ValueTypes["WebAppBookmarksDocAccessFields_createdAt_Create"],
	read?:ValueTypes["WebAppBookmarksDocAccessFields_createdAt_Read"],
	update?:ValueTypes["WebAppBookmarksDocAccessFields_createdAt_Update"],
	delete?:ValueTypes["WebAppBookmarksDocAccessFields_createdAt_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["WebAppBookmarksDocAccessFields_createdAt_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["WebAppBookmarksDocAccessFields_createdAt_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["WebAppBookmarksDocAccessFields_createdAt_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["WebAppBookmarksDocAccessFields_createdAt_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["WebAppBookmarksCreateDocAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["WebAppBookmarksReadDocAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["WebAppBookmarksUpdateDocAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["WebAppBookmarksDeleteDocAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ViewRecord"]: AliasType<{
	id?:boolean | `@${string}`,
user?: [{	locale?: ValueTypes["LocaleInputType"] | undefined | null | Variable<any, string>,	fallbackLocale?: ValueTypes["FallbackLocaleInputType"] | undefined | null | Variable<any, string>},ValueTypes["ViewRecord_User_Relationship"]],
item?: [{	locale?: ValueTypes["LocaleInputType"] | undefined | null | Variable<any, string>,	fallbackLocale?: ValueTypes["FallbackLocaleInputType"] | undefined | null | Variable<any, string>},ValueTypes["ViewRecord_Item_Relationship"]],
	updatedAt?:boolean | `@${string}`,
	createdAt?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ViewRecord_User_Relationship"]: AliasType<{
	relationTo?:boolean | `@${string}`,
	value?:ValueTypes["ViewRecord_User"],
		__typename?: boolean | `@${string}`
}>;
	["ViewRecord_User_RelationTo"]:ViewRecord_User_RelationTo;
	["ViewRecord_User"]: AliasType<{		["...on User"]?: ValueTypes["User"]
		__typename?: boolean | `@${string}`
}>;
	["ViewRecord_Item_Relationship"]: AliasType<{
	relationTo?:boolean | `@${string}`,
	value?:ValueTypes["ViewRecord_Item"],
		__typename?: boolean | `@${string}`
}>;
	["ViewRecord_Item_RelationTo"]:ViewRecord_Item_RelationTo;
	["ViewRecord_Item"]: AliasType<{		["...on Event"]?: ValueTypes["Event"],
		["...on Location"]?: ValueTypes["Location"],
		["...on Post"]?: ValueTypes["Post"]
		__typename?: boolean | `@${string}`
}>;
	["ViewRecords"]: AliasType<{
	docs?:ValueTypes["ViewRecord"],
	hasNextPage?:boolean | `@${string}`,
	hasPrevPage?:boolean | `@${string}`,
	limit?:boolean | `@${string}`,
	nextPage?:boolean | `@${string}`,
	offset?:boolean | `@${string}`,
	page?:boolean | `@${string}`,
	pagingCounter?:boolean | `@${string}`,
	prevPage?:boolean | `@${string}`,
	totalDocs?:boolean | `@${string}`,
	totalPages?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ViewRecord_where"]: {
	user?: ValueTypes["ViewRecord_user_Relation"] | undefined | null | Variable<any, string>,
	item?: ValueTypes["ViewRecord_item_Relation"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["ViewRecord_updatedAt_operator"] | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["ViewRecord_createdAt_operator"] | undefined | null | Variable<any, string>,
	id?: ValueTypes["ViewRecord_id_operator"] | undefined | null | Variable<any, string>,
	AND?: Array<ValueTypes["ViewRecord_where_and"] | undefined | null> | undefined | null | Variable<any, string>,
	OR?: Array<ValueTypes["ViewRecord_where_or"] | undefined | null> | undefined | null | Variable<any, string>
};
	["ViewRecord_user_Relation"]: {
	relationTo?: ValueTypes["ViewRecord_user_Relation_RelationTo"] | undefined | null | Variable<any, string>,
	value?: ValueTypes["JSON"] | undefined | null | Variable<any, string>
};
	["ViewRecord_user_Relation_RelationTo"]:ViewRecord_user_Relation_RelationTo;
	["ViewRecord_item_Relation"]: {
	relationTo?: ValueTypes["ViewRecord_item_Relation_RelationTo"] | undefined | null | Variable<any, string>,
	value?: ValueTypes["JSON"] | undefined | null | Variable<any, string>
};
	["ViewRecord_item_Relation_RelationTo"]:ViewRecord_item_Relation_RelationTo;
	["ViewRecord_updatedAt_operator"]: {
	equals?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	not_equals?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	greater_than_equal?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	greater_than?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	less_than_equal?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	less_than?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	like?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["ViewRecord_createdAt_operator"]: {
	equals?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	not_equals?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	greater_than_equal?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	greater_than?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	less_than_equal?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	less_than?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	like?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["ViewRecord_id_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["ViewRecord_where_and"]: {
	user?: ValueTypes["ViewRecord_user_Relation"] | undefined | null | Variable<any, string>,
	item?: ValueTypes["ViewRecord_item_Relation"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["ViewRecord_updatedAt_operator"] | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["ViewRecord_createdAt_operator"] | undefined | null | Variable<any, string>,
	id?: ValueTypes["ViewRecord_id_operator"] | undefined | null | Variable<any, string>,
	AND?: Array<ValueTypes["ViewRecord_where_and"] | undefined | null> | undefined | null | Variable<any, string>,
	OR?: Array<ValueTypes["ViewRecord_where_or"] | undefined | null> | undefined | null | Variable<any, string>
};
	["ViewRecord_where_or"]: {
	user?: ValueTypes["ViewRecord_user_Relation"] | undefined | null | Variable<any, string>,
	item?: ValueTypes["ViewRecord_item_Relation"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["ViewRecord_updatedAt_operator"] | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["ViewRecord_createdAt_operator"] | undefined | null | Variable<any, string>,
	id?: ValueTypes["ViewRecord_id_operator"] | undefined | null | Variable<any, string>,
	AND?: Array<ValueTypes["ViewRecord_where_and"] | undefined | null> | undefined | null | Variable<any, string>,
	OR?: Array<ValueTypes["ViewRecord_where_or"] | undefined | null> | undefined | null | Variable<any, string>
};
	["countViewRecords"]: AliasType<{
	totalDocs?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["view_recordsDocAccess"]: AliasType<{
	fields?:ValueTypes["ViewRecordsDocAccessFields"],
	create?:ValueTypes["ViewRecordsCreateDocAccess"],
	read?:ValueTypes["ViewRecordsReadDocAccess"],
	update?:ValueTypes["ViewRecordsUpdateDocAccess"],
	delete?:ValueTypes["ViewRecordsDeleteDocAccess"],
		__typename?: boolean | `@${string}`
}>;
	["ViewRecordsDocAccessFields"]: AliasType<{
	user?:ValueTypes["ViewRecordsDocAccessFields_user"],
	item?:ValueTypes["ViewRecordsDocAccessFields_item"],
	updatedAt?:ValueTypes["ViewRecordsDocAccessFields_updatedAt"],
	createdAt?:ValueTypes["ViewRecordsDocAccessFields_createdAt"],
		__typename?: boolean | `@${string}`
}>;
	["ViewRecordsDocAccessFields_user"]: AliasType<{
	create?:ValueTypes["ViewRecordsDocAccessFields_user_Create"],
	read?:ValueTypes["ViewRecordsDocAccessFields_user_Read"],
	update?:ValueTypes["ViewRecordsDocAccessFields_user_Update"],
	delete?:ValueTypes["ViewRecordsDocAccessFields_user_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["ViewRecordsDocAccessFields_user_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ViewRecordsDocAccessFields_user_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ViewRecordsDocAccessFields_user_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ViewRecordsDocAccessFields_user_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ViewRecordsDocAccessFields_item"]: AliasType<{
	create?:ValueTypes["ViewRecordsDocAccessFields_item_Create"],
	read?:ValueTypes["ViewRecordsDocAccessFields_item_Read"],
	update?:ValueTypes["ViewRecordsDocAccessFields_item_Update"],
	delete?:ValueTypes["ViewRecordsDocAccessFields_item_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["ViewRecordsDocAccessFields_item_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ViewRecordsDocAccessFields_item_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ViewRecordsDocAccessFields_item_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ViewRecordsDocAccessFields_item_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ViewRecordsDocAccessFields_updatedAt"]: AliasType<{
	create?:ValueTypes["ViewRecordsDocAccessFields_updatedAt_Create"],
	read?:ValueTypes["ViewRecordsDocAccessFields_updatedAt_Read"],
	update?:ValueTypes["ViewRecordsDocAccessFields_updatedAt_Update"],
	delete?:ValueTypes["ViewRecordsDocAccessFields_updatedAt_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["ViewRecordsDocAccessFields_updatedAt_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ViewRecordsDocAccessFields_updatedAt_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ViewRecordsDocAccessFields_updatedAt_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ViewRecordsDocAccessFields_updatedAt_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ViewRecordsDocAccessFields_createdAt"]: AliasType<{
	create?:ValueTypes["ViewRecordsDocAccessFields_createdAt_Create"],
	read?:ValueTypes["ViewRecordsDocAccessFields_createdAt_Read"],
	update?:ValueTypes["ViewRecordsDocAccessFields_createdAt_Update"],
	delete?:ValueTypes["ViewRecordsDocAccessFields_createdAt_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["ViewRecordsDocAccessFields_createdAt_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ViewRecordsDocAccessFields_createdAt_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ViewRecordsDocAccessFields_createdAt_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ViewRecordsDocAccessFields_createdAt_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ViewRecordsCreateDocAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ViewRecordsReadDocAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ViewRecordsUpdateDocAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ViewRecordsDeleteDocAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["Comment"]: AliasType<{
	id?:boolean | `@${string}`,
commenter?: [{	locale?: ValueTypes["LocaleInputType"] | undefined | null | Variable<any, string>,	fallbackLocale?: ValueTypes["FallbackLocaleInputType"] | undefined | null | Variable<any, string>},ValueTypes["User"]],
	content?:boolean | `@${string}`,
image?: [{	locale?: ValueTypes["LocaleInputType"] | undefined | null | Variable<any, string>,	fallbackLocale?: ValueTypes["FallbackLocaleInputType"] | undefined | null | Variable<any, string>,	where?: ValueTypes["Comment_Image_where"] | undefined | null | Variable<any, string>},ValueTypes["Media"]],
target?: [{	locale?: ValueTypes["LocaleInputType"] | undefined | null | Variable<any, string>,	fallbackLocale?: ValueTypes["FallbackLocaleInputType"] | undefined | null | Variable<any, string>},ValueTypes["Comment_Target_Relationship"]],
parent?: [{	locale?: ValueTypes["LocaleInputType"] | undefined | null | Variable<any, string>,	fallbackLocale?: ValueTypes["FallbackLocaleInputType"] | undefined | null | Variable<any, string>},ValueTypes["Comment_Parent_Relationship"]],
	isReviewed?:boolean | `@${string}`,
reviewedBy?: [{	locale?: ValueTypes["LocaleInputType"] | undefined | null | Variable<any, string>,	fallbackLocale?: ValueTypes["FallbackLocaleInputType"] | undefined | null | Variable<any, string>},ValueTypes["User"]],
	banned?:boolean | `@${string}`,
	type?:boolean | `@${string}`,
	updatedAt?:boolean | `@${string}`,
	createdAt?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["Comment_Image_where"]: {
	alt?: ValueTypes["Comment_Image_alt_operator"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["Comment_Image_updatedAt_operator"] | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["Comment_Image_createdAt_operator"] | undefined | null | Variable<any, string>,
	url?: ValueTypes["Comment_Image_url_operator"] | undefined | null | Variable<any, string>,
	filename?: ValueTypes["Comment_Image_filename_operator"] | undefined | null | Variable<any, string>,
	mimeType?: ValueTypes["Comment_Image_mimeType_operator"] | undefined | null | Variable<any, string>,
	filesize?: ValueTypes["Comment_Image_filesize_operator"] | undefined | null | Variable<any, string>,
	width?: ValueTypes["Comment_Image_width_operator"] | undefined | null | Variable<any, string>,
	height?: ValueTypes["Comment_Image_height_operator"] | undefined | null | Variable<any, string>,
	focalX?: ValueTypes["Comment_Image_focalX_operator"] | undefined | null | Variable<any, string>,
	focalY?: ValueTypes["Comment_Image_focalY_operator"] | undefined | null | Variable<any, string>,
	sizes__small__url?: ValueTypes["Comment_Image_sizes__small__url_operator"] | undefined | null | Variable<any, string>,
	sizes__small__width?: ValueTypes["Comment_Image_sizes__small__width_operator"] | undefined | null | Variable<any, string>,
	sizes__small__height?: ValueTypes["Comment_Image_sizes__small__height_operator"] | undefined | null | Variable<any, string>,
	sizes__small__mimeType?: ValueTypes["Comment_Image_sizes__small__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__small__filesize?: ValueTypes["Comment_Image_sizes__small__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__small__filename?: ValueTypes["Comment_Image_sizes__small__filename_operator"] | undefined | null | Variable<any, string>,
	sizes__medium__url?: ValueTypes["Comment_Image_sizes__medium__url_operator"] | undefined | null | Variable<any, string>,
	sizes__medium__width?: ValueTypes["Comment_Image_sizes__medium__width_operator"] | undefined | null | Variable<any, string>,
	sizes__medium__height?: ValueTypes["Comment_Image_sizes__medium__height_operator"] | undefined | null | Variable<any, string>,
	sizes__medium__mimeType?: ValueTypes["Comment_Image_sizes__medium__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__medium__filesize?: ValueTypes["Comment_Image_sizes__medium__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__medium__filename?: ValueTypes["Comment_Image_sizes__medium__filename_operator"] | undefined | null | Variable<any, string>,
	sizes__large__url?: ValueTypes["Comment_Image_sizes__large__url_operator"] | undefined | null | Variable<any, string>,
	sizes__large__width?: ValueTypes["Comment_Image_sizes__large__width_operator"] | undefined | null | Variable<any, string>,
	sizes__large__height?: ValueTypes["Comment_Image_sizes__large__height_operator"] | undefined | null | Variable<any, string>,
	sizes__large__mimeType?: ValueTypes["Comment_Image_sizes__large__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__large__filesize?: ValueTypes["Comment_Image_sizes__large__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__large__filename?: ValueTypes["Comment_Image_sizes__large__filename_operator"] | undefined | null | Variable<any, string>,
	sizes__small_jpeg__url?: ValueTypes["Comment_Image_sizes__small_jpeg__url_operator"] | undefined | null | Variable<any, string>,
	sizes__small_jpeg__width?: ValueTypes["Comment_Image_sizes__small_jpeg__width_operator"] | undefined | null | Variable<any, string>,
	sizes__small_jpeg__height?: ValueTypes["Comment_Image_sizes__small_jpeg__height_operator"] | undefined | null | Variable<any, string>,
	sizes__small_jpeg__mimeType?: ValueTypes["Comment_Image_sizes__small_jpeg__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__small_jpeg__filesize?: ValueTypes["Comment_Image_sizes__small_jpeg__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__small_jpeg__filename?: ValueTypes["Comment_Image_sizes__small_jpeg__filename_operator"] | undefined | null | Variable<any, string>,
	sizes__medium_jpeg__url?: ValueTypes["Comment_Image_sizes__medium_jpeg__url_operator"] | undefined | null | Variable<any, string>,
	sizes__medium_jpeg__width?: ValueTypes["Comment_Image_sizes__medium_jpeg__width_operator"] | undefined | null | Variable<any, string>,
	sizes__medium_jpeg__height?: ValueTypes["Comment_Image_sizes__medium_jpeg__height_operator"] | undefined | null | Variable<any, string>,
	sizes__medium_jpeg__mimeType?: ValueTypes["Comment_Image_sizes__medium_jpeg__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__medium_jpeg__filesize?: ValueTypes["Comment_Image_sizes__medium_jpeg__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__medium_jpeg__filename?: ValueTypes["Comment_Image_sizes__medium_jpeg__filename_operator"] | undefined | null | Variable<any, string>,
	sizes__large_jpeg__url?: ValueTypes["Comment_Image_sizes__large_jpeg__url_operator"] | undefined | null | Variable<any, string>,
	sizes__large_jpeg__width?: ValueTypes["Comment_Image_sizes__large_jpeg__width_operator"] | undefined | null | Variable<any, string>,
	sizes__large_jpeg__height?: ValueTypes["Comment_Image_sizes__large_jpeg__height_operator"] | undefined | null | Variable<any, string>,
	sizes__large_jpeg__mimeType?: ValueTypes["Comment_Image_sizes__large_jpeg__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__large_jpeg__filesize?: ValueTypes["Comment_Image_sizes__large_jpeg__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__large_jpeg__filename?: ValueTypes["Comment_Image_sizes__large_jpeg__filename_operator"] | undefined | null | Variable<any, string>,
	id?: ValueTypes["Comment_Image_id_operator"] | undefined | null | Variable<any, string>,
	AND?: Array<ValueTypes["Comment_Image_where_and"] | undefined | null> | undefined | null | Variable<any, string>,
	OR?: Array<ValueTypes["Comment_Image_where_or"] | undefined | null> | undefined | null | Variable<any, string>
};
	["Comment_Image_alt_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Comment_Image_updatedAt_operator"]: {
	equals?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	not_equals?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	greater_than_equal?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	greater_than?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	less_than_equal?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	less_than?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	like?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Comment_Image_createdAt_operator"]: {
	equals?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	not_equals?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	greater_than_equal?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	greater_than?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	less_than_equal?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	less_than?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	like?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Comment_Image_url_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Comment_Image_filename_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Comment_Image_mimeType_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Comment_Image_filesize_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Comment_Image_width_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Comment_Image_height_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Comment_Image_focalX_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Comment_Image_focalY_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Comment_Image_sizes__small__url_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Comment_Image_sizes__small__width_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Comment_Image_sizes__small__height_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Comment_Image_sizes__small__mimeType_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Comment_Image_sizes__small__filesize_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Comment_Image_sizes__small__filename_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Comment_Image_sizes__medium__url_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Comment_Image_sizes__medium__width_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Comment_Image_sizes__medium__height_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Comment_Image_sizes__medium__mimeType_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Comment_Image_sizes__medium__filesize_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Comment_Image_sizes__medium__filename_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Comment_Image_sizes__large__url_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Comment_Image_sizes__large__width_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Comment_Image_sizes__large__height_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Comment_Image_sizes__large__mimeType_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Comment_Image_sizes__large__filesize_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Comment_Image_sizes__large__filename_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Comment_Image_sizes__small_jpeg__url_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Comment_Image_sizes__small_jpeg__width_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Comment_Image_sizes__small_jpeg__height_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Comment_Image_sizes__small_jpeg__mimeType_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Comment_Image_sizes__small_jpeg__filesize_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Comment_Image_sizes__small_jpeg__filename_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Comment_Image_sizes__medium_jpeg__url_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Comment_Image_sizes__medium_jpeg__width_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Comment_Image_sizes__medium_jpeg__height_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Comment_Image_sizes__medium_jpeg__mimeType_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Comment_Image_sizes__medium_jpeg__filesize_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Comment_Image_sizes__medium_jpeg__filename_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Comment_Image_sizes__large_jpeg__url_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Comment_Image_sizes__large_jpeg__width_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Comment_Image_sizes__large_jpeg__height_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Comment_Image_sizes__large_jpeg__mimeType_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Comment_Image_sizes__large_jpeg__filesize_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Comment_Image_sizes__large_jpeg__filename_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Comment_Image_id_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Comment_Image_where_and"]: {
	alt?: ValueTypes["Comment_Image_alt_operator"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["Comment_Image_updatedAt_operator"] | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["Comment_Image_createdAt_operator"] | undefined | null | Variable<any, string>,
	url?: ValueTypes["Comment_Image_url_operator"] | undefined | null | Variable<any, string>,
	filename?: ValueTypes["Comment_Image_filename_operator"] | undefined | null | Variable<any, string>,
	mimeType?: ValueTypes["Comment_Image_mimeType_operator"] | undefined | null | Variable<any, string>,
	filesize?: ValueTypes["Comment_Image_filesize_operator"] | undefined | null | Variable<any, string>,
	width?: ValueTypes["Comment_Image_width_operator"] | undefined | null | Variable<any, string>,
	height?: ValueTypes["Comment_Image_height_operator"] | undefined | null | Variable<any, string>,
	focalX?: ValueTypes["Comment_Image_focalX_operator"] | undefined | null | Variable<any, string>,
	focalY?: ValueTypes["Comment_Image_focalY_operator"] | undefined | null | Variable<any, string>,
	sizes__small__url?: ValueTypes["Comment_Image_sizes__small__url_operator"] | undefined | null | Variable<any, string>,
	sizes__small__width?: ValueTypes["Comment_Image_sizes__small__width_operator"] | undefined | null | Variable<any, string>,
	sizes__small__height?: ValueTypes["Comment_Image_sizes__small__height_operator"] | undefined | null | Variable<any, string>,
	sizes__small__mimeType?: ValueTypes["Comment_Image_sizes__small__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__small__filesize?: ValueTypes["Comment_Image_sizes__small__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__small__filename?: ValueTypes["Comment_Image_sizes__small__filename_operator"] | undefined | null | Variable<any, string>,
	sizes__medium__url?: ValueTypes["Comment_Image_sizes__medium__url_operator"] | undefined | null | Variable<any, string>,
	sizes__medium__width?: ValueTypes["Comment_Image_sizes__medium__width_operator"] | undefined | null | Variable<any, string>,
	sizes__medium__height?: ValueTypes["Comment_Image_sizes__medium__height_operator"] | undefined | null | Variable<any, string>,
	sizes__medium__mimeType?: ValueTypes["Comment_Image_sizes__medium__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__medium__filesize?: ValueTypes["Comment_Image_sizes__medium__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__medium__filename?: ValueTypes["Comment_Image_sizes__medium__filename_operator"] | undefined | null | Variable<any, string>,
	sizes__large__url?: ValueTypes["Comment_Image_sizes__large__url_operator"] | undefined | null | Variable<any, string>,
	sizes__large__width?: ValueTypes["Comment_Image_sizes__large__width_operator"] | undefined | null | Variable<any, string>,
	sizes__large__height?: ValueTypes["Comment_Image_sizes__large__height_operator"] | undefined | null | Variable<any, string>,
	sizes__large__mimeType?: ValueTypes["Comment_Image_sizes__large__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__large__filesize?: ValueTypes["Comment_Image_sizes__large__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__large__filename?: ValueTypes["Comment_Image_sizes__large__filename_operator"] | undefined | null | Variable<any, string>,
	sizes__small_jpeg__url?: ValueTypes["Comment_Image_sizes__small_jpeg__url_operator"] | undefined | null | Variable<any, string>,
	sizes__small_jpeg__width?: ValueTypes["Comment_Image_sizes__small_jpeg__width_operator"] | undefined | null | Variable<any, string>,
	sizes__small_jpeg__height?: ValueTypes["Comment_Image_sizes__small_jpeg__height_operator"] | undefined | null | Variable<any, string>,
	sizes__small_jpeg__mimeType?: ValueTypes["Comment_Image_sizes__small_jpeg__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__small_jpeg__filesize?: ValueTypes["Comment_Image_sizes__small_jpeg__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__small_jpeg__filename?: ValueTypes["Comment_Image_sizes__small_jpeg__filename_operator"] | undefined | null | Variable<any, string>,
	sizes__medium_jpeg__url?: ValueTypes["Comment_Image_sizes__medium_jpeg__url_operator"] | undefined | null | Variable<any, string>,
	sizes__medium_jpeg__width?: ValueTypes["Comment_Image_sizes__medium_jpeg__width_operator"] | undefined | null | Variable<any, string>,
	sizes__medium_jpeg__height?: ValueTypes["Comment_Image_sizes__medium_jpeg__height_operator"] | undefined | null | Variable<any, string>,
	sizes__medium_jpeg__mimeType?: ValueTypes["Comment_Image_sizes__medium_jpeg__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__medium_jpeg__filesize?: ValueTypes["Comment_Image_sizes__medium_jpeg__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__medium_jpeg__filename?: ValueTypes["Comment_Image_sizes__medium_jpeg__filename_operator"] | undefined | null | Variable<any, string>,
	sizes__large_jpeg__url?: ValueTypes["Comment_Image_sizes__large_jpeg__url_operator"] | undefined | null | Variable<any, string>,
	sizes__large_jpeg__width?: ValueTypes["Comment_Image_sizes__large_jpeg__width_operator"] | undefined | null | Variable<any, string>,
	sizes__large_jpeg__height?: ValueTypes["Comment_Image_sizes__large_jpeg__height_operator"] | undefined | null | Variable<any, string>,
	sizes__large_jpeg__mimeType?: ValueTypes["Comment_Image_sizes__large_jpeg__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__large_jpeg__filesize?: ValueTypes["Comment_Image_sizes__large_jpeg__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__large_jpeg__filename?: ValueTypes["Comment_Image_sizes__large_jpeg__filename_operator"] | undefined | null | Variable<any, string>,
	id?: ValueTypes["Comment_Image_id_operator"] | undefined | null | Variable<any, string>,
	AND?: Array<ValueTypes["Comment_Image_where_and"] | undefined | null> | undefined | null | Variable<any, string>,
	OR?: Array<ValueTypes["Comment_Image_where_or"] | undefined | null> | undefined | null | Variable<any, string>
};
	["Comment_Image_where_or"]: {
	alt?: ValueTypes["Comment_Image_alt_operator"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["Comment_Image_updatedAt_operator"] | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["Comment_Image_createdAt_operator"] | undefined | null | Variable<any, string>,
	url?: ValueTypes["Comment_Image_url_operator"] | undefined | null | Variable<any, string>,
	filename?: ValueTypes["Comment_Image_filename_operator"] | undefined | null | Variable<any, string>,
	mimeType?: ValueTypes["Comment_Image_mimeType_operator"] | undefined | null | Variable<any, string>,
	filesize?: ValueTypes["Comment_Image_filesize_operator"] | undefined | null | Variable<any, string>,
	width?: ValueTypes["Comment_Image_width_operator"] | undefined | null | Variable<any, string>,
	height?: ValueTypes["Comment_Image_height_operator"] | undefined | null | Variable<any, string>,
	focalX?: ValueTypes["Comment_Image_focalX_operator"] | undefined | null | Variable<any, string>,
	focalY?: ValueTypes["Comment_Image_focalY_operator"] | undefined | null | Variable<any, string>,
	sizes__small__url?: ValueTypes["Comment_Image_sizes__small__url_operator"] | undefined | null | Variable<any, string>,
	sizes__small__width?: ValueTypes["Comment_Image_sizes__small__width_operator"] | undefined | null | Variable<any, string>,
	sizes__small__height?: ValueTypes["Comment_Image_sizes__small__height_operator"] | undefined | null | Variable<any, string>,
	sizes__small__mimeType?: ValueTypes["Comment_Image_sizes__small__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__small__filesize?: ValueTypes["Comment_Image_sizes__small__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__small__filename?: ValueTypes["Comment_Image_sizes__small__filename_operator"] | undefined | null | Variable<any, string>,
	sizes__medium__url?: ValueTypes["Comment_Image_sizes__medium__url_operator"] | undefined | null | Variable<any, string>,
	sizes__medium__width?: ValueTypes["Comment_Image_sizes__medium__width_operator"] | undefined | null | Variable<any, string>,
	sizes__medium__height?: ValueTypes["Comment_Image_sizes__medium__height_operator"] | undefined | null | Variable<any, string>,
	sizes__medium__mimeType?: ValueTypes["Comment_Image_sizes__medium__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__medium__filesize?: ValueTypes["Comment_Image_sizes__medium__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__medium__filename?: ValueTypes["Comment_Image_sizes__medium__filename_operator"] | undefined | null | Variable<any, string>,
	sizes__large__url?: ValueTypes["Comment_Image_sizes__large__url_operator"] | undefined | null | Variable<any, string>,
	sizes__large__width?: ValueTypes["Comment_Image_sizes__large__width_operator"] | undefined | null | Variable<any, string>,
	sizes__large__height?: ValueTypes["Comment_Image_sizes__large__height_operator"] | undefined | null | Variable<any, string>,
	sizes__large__mimeType?: ValueTypes["Comment_Image_sizes__large__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__large__filesize?: ValueTypes["Comment_Image_sizes__large__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__large__filename?: ValueTypes["Comment_Image_sizes__large__filename_operator"] | undefined | null | Variable<any, string>,
	sizes__small_jpeg__url?: ValueTypes["Comment_Image_sizes__small_jpeg__url_operator"] | undefined | null | Variable<any, string>,
	sizes__small_jpeg__width?: ValueTypes["Comment_Image_sizes__small_jpeg__width_operator"] | undefined | null | Variable<any, string>,
	sizes__small_jpeg__height?: ValueTypes["Comment_Image_sizes__small_jpeg__height_operator"] | undefined | null | Variable<any, string>,
	sizes__small_jpeg__mimeType?: ValueTypes["Comment_Image_sizes__small_jpeg__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__small_jpeg__filesize?: ValueTypes["Comment_Image_sizes__small_jpeg__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__small_jpeg__filename?: ValueTypes["Comment_Image_sizes__small_jpeg__filename_operator"] | undefined | null | Variable<any, string>,
	sizes__medium_jpeg__url?: ValueTypes["Comment_Image_sizes__medium_jpeg__url_operator"] | undefined | null | Variable<any, string>,
	sizes__medium_jpeg__width?: ValueTypes["Comment_Image_sizes__medium_jpeg__width_operator"] | undefined | null | Variable<any, string>,
	sizes__medium_jpeg__height?: ValueTypes["Comment_Image_sizes__medium_jpeg__height_operator"] | undefined | null | Variable<any, string>,
	sizes__medium_jpeg__mimeType?: ValueTypes["Comment_Image_sizes__medium_jpeg__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__medium_jpeg__filesize?: ValueTypes["Comment_Image_sizes__medium_jpeg__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__medium_jpeg__filename?: ValueTypes["Comment_Image_sizes__medium_jpeg__filename_operator"] | undefined | null | Variable<any, string>,
	sizes__large_jpeg__url?: ValueTypes["Comment_Image_sizes__large_jpeg__url_operator"] | undefined | null | Variable<any, string>,
	sizes__large_jpeg__width?: ValueTypes["Comment_Image_sizes__large_jpeg__width_operator"] | undefined | null | Variable<any, string>,
	sizes__large_jpeg__height?: ValueTypes["Comment_Image_sizes__large_jpeg__height_operator"] | undefined | null | Variable<any, string>,
	sizes__large_jpeg__mimeType?: ValueTypes["Comment_Image_sizes__large_jpeg__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__large_jpeg__filesize?: ValueTypes["Comment_Image_sizes__large_jpeg__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__large_jpeg__filename?: ValueTypes["Comment_Image_sizes__large_jpeg__filename_operator"] | undefined | null | Variable<any, string>,
	id?: ValueTypes["Comment_Image_id_operator"] | undefined | null | Variable<any, string>,
	AND?: Array<ValueTypes["Comment_Image_where_and"] | undefined | null> | undefined | null | Variable<any, string>,
	OR?: Array<ValueTypes["Comment_Image_where_or"] | undefined | null> | undefined | null | Variable<any, string>
};
	["Comment_Target_Relationship"]: AliasType<{
	relationTo?:boolean | `@${string}`,
	value?:ValueTypes["Comment_Target"],
		__typename?: boolean | `@${string}`
}>;
	["Comment_Target_RelationTo"]:Comment_Target_RelationTo;
	["Comment_Target"]: AliasType<{		["...on Event"]?: ValueTypes["Event"],
		["...on Post"]?: ValueTypes["Post"],
		["...on Location"]?: ValueTypes["Location"]
		__typename?: boolean | `@${string}`
}>;
	["Comment_Parent_Relationship"]: AliasType<{
	relationTo?:boolean | `@${string}`,
	value?:ValueTypes["Comment_Parent"],
		__typename?: boolean | `@${string}`
}>;
	["Comment_Parent_RelationTo"]:Comment_Parent_RelationTo;
	["Comment_Parent"]: AliasType<{		["...on Comment"]?: ValueTypes["Comment"]
		__typename?: boolean | `@${string}`
}>;
	["Comments"]: AliasType<{
	docs?:ValueTypes["Comment"],
	hasNextPage?:boolean | `@${string}`,
	hasPrevPage?:boolean | `@${string}`,
	limit?:boolean | `@${string}`,
	nextPage?:boolean | `@${string}`,
	offset?:boolean | `@${string}`,
	page?:boolean | `@${string}`,
	pagingCounter?:boolean | `@${string}`,
	prevPage?:boolean | `@${string}`,
	totalDocs?:boolean | `@${string}`,
	totalPages?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["Comment_where"]: {
	commenter?: ValueTypes["Comment_commenter_operator"] | undefined | null | Variable<any, string>,
	content?: ValueTypes["Comment_content_operator"] | undefined | null | Variable<any, string>,
	image?: ValueTypes["Comment_image_operator"] | undefined | null | Variable<any, string>,
	target?: ValueTypes["Comment_target_Relation"] | undefined | null | Variable<any, string>,
	parent?: ValueTypes["Comment_parent_Relation"] | undefined | null | Variable<any, string>,
	isReviewed?: ValueTypes["Comment_isReviewed_operator"] | undefined | null | Variable<any, string>,
	reviewedBy?: ValueTypes["Comment_reviewedBy_operator"] | undefined | null | Variable<any, string>,
	banned?: ValueTypes["Comment_banned_operator"] | undefined | null | Variable<any, string>,
	type?: ValueTypes["Comment_type_operator"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["Comment_updatedAt_operator"] | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["Comment_createdAt_operator"] | undefined | null | Variable<any, string>,
	id?: ValueTypes["Comment_id_operator"] | undefined | null | Variable<any, string>,
	AND?: Array<ValueTypes["Comment_where_and"] | undefined | null> | undefined | null | Variable<any, string>,
	OR?: Array<ValueTypes["Comment_where_or"] | undefined | null> | undefined | null | Variable<any, string>
};
	["Comment_commenter_operator"]: {
	equals?: ValueTypes["JSON"] | undefined | null | Variable<any, string>,
	not_equals?: ValueTypes["JSON"] | undefined | null | Variable<any, string>,
	in?: Array<ValueTypes["JSON"] | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<ValueTypes["JSON"] | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<ValueTypes["JSON"] | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Comment_content_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Comment_image_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Comment_target_Relation"]: {
	relationTo?: ValueTypes["Comment_target_Relation_RelationTo"] | undefined | null | Variable<any, string>,
	value?: ValueTypes["JSON"] | undefined | null | Variable<any, string>
};
	["Comment_target_Relation_RelationTo"]:Comment_target_Relation_RelationTo;
	["Comment_parent_Relation"]: {
	relationTo?: ValueTypes["Comment_parent_Relation_RelationTo"] | undefined | null | Variable<any, string>,
	value?: ValueTypes["JSON"] | undefined | null | Variable<any, string>
};
	["Comment_parent_Relation_RelationTo"]:Comment_parent_Relation_RelationTo;
	["Comment_isReviewed_operator"]: {
	equals?: boolean | undefined | null | Variable<any, string>,
	not_equals?: boolean | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Comment_reviewedBy_operator"]: {
	equals?: ValueTypes["JSON"] | undefined | null | Variable<any, string>,
	not_equals?: ValueTypes["JSON"] | undefined | null | Variable<any, string>,
	in?: Array<ValueTypes["JSON"] | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<ValueTypes["JSON"] | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<ValueTypes["JSON"] | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Comment_banned_operator"]: {
	equals?: boolean | undefined | null | Variable<any, string>,
	not_equals?: boolean | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Comment_type_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Comment_updatedAt_operator"]: {
	equals?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	not_equals?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	greater_than_equal?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	greater_than?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	less_than_equal?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	less_than?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	like?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Comment_createdAt_operator"]: {
	equals?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	not_equals?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	greater_than_equal?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	greater_than?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	less_than_equal?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	less_than?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	like?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Comment_id_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Comment_where_and"]: {
	commenter?: ValueTypes["Comment_commenter_operator"] | undefined | null | Variable<any, string>,
	content?: ValueTypes["Comment_content_operator"] | undefined | null | Variable<any, string>,
	image?: ValueTypes["Comment_image_operator"] | undefined | null | Variable<any, string>,
	target?: ValueTypes["Comment_target_Relation"] | undefined | null | Variable<any, string>,
	parent?: ValueTypes["Comment_parent_Relation"] | undefined | null | Variable<any, string>,
	isReviewed?: ValueTypes["Comment_isReviewed_operator"] | undefined | null | Variable<any, string>,
	reviewedBy?: ValueTypes["Comment_reviewedBy_operator"] | undefined | null | Variable<any, string>,
	banned?: ValueTypes["Comment_banned_operator"] | undefined | null | Variable<any, string>,
	type?: ValueTypes["Comment_type_operator"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["Comment_updatedAt_operator"] | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["Comment_createdAt_operator"] | undefined | null | Variable<any, string>,
	id?: ValueTypes["Comment_id_operator"] | undefined | null | Variable<any, string>,
	AND?: Array<ValueTypes["Comment_where_and"] | undefined | null> | undefined | null | Variable<any, string>,
	OR?: Array<ValueTypes["Comment_where_or"] | undefined | null> | undefined | null | Variable<any, string>
};
	["Comment_where_or"]: {
	commenter?: ValueTypes["Comment_commenter_operator"] | undefined | null | Variable<any, string>,
	content?: ValueTypes["Comment_content_operator"] | undefined | null | Variable<any, string>,
	image?: ValueTypes["Comment_image_operator"] | undefined | null | Variable<any, string>,
	target?: ValueTypes["Comment_target_Relation"] | undefined | null | Variable<any, string>,
	parent?: ValueTypes["Comment_parent_Relation"] | undefined | null | Variable<any, string>,
	isReviewed?: ValueTypes["Comment_isReviewed_operator"] | undefined | null | Variable<any, string>,
	reviewedBy?: ValueTypes["Comment_reviewedBy_operator"] | undefined | null | Variable<any, string>,
	banned?: ValueTypes["Comment_banned_operator"] | undefined | null | Variable<any, string>,
	type?: ValueTypes["Comment_type_operator"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["Comment_updatedAt_operator"] | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["Comment_createdAt_operator"] | undefined | null | Variable<any, string>,
	id?: ValueTypes["Comment_id_operator"] | undefined | null | Variable<any, string>,
	AND?: Array<ValueTypes["Comment_where_and"] | undefined | null> | undefined | null | Variable<any, string>,
	OR?: Array<ValueTypes["Comment_where_or"] | undefined | null> | undefined | null | Variable<any, string>
};
	["countComments"]: AliasType<{
	totalDocs?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["commentsDocAccess"]: AliasType<{
	fields?:ValueTypes["CommentsDocAccessFields"],
	create?:ValueTypes["CommentsCreateDocAccess"],
	read?:ValueTypes["CommentsReadDocAccess"],
	update?:ValueTypes["CommentsUpdateDocAccess"],
	delete?:ValueTypes["CommentsDeleteDocAccess"],
		__typename?: boolean | `@${string}`
}>;
	["CommentsDocAccessFields"]: AliasType<{
	commenter?:ValueTypes["CommentsDocAccessFields_commenter"],
	content?:ValueTypes["CommentsDocAccessFields_content"],
	image?:ValueTypes["CommentsDocAccessFields_image"],
	target?:ValueTypes["CommentsDocAccessFields_target"],
	parent?:ValueTypes["CommentsDocAccessFields_parent"],
	isReviewed?:ValueTypes["CommentsDocAccessFields_isReviewed"],
	reviewedBy?:ValueTypes["CommentsDocAccessFields_reviewedBy"],
	banned?:ValueTypes["CommentsDocAccessFields_banned"],
	type?:ValueTypes["CommentsDocAccessFields_type"],
	updatedAt?:ValueTypes["CommentsDocAccessFields_updatedAt"],
	createdAt?:ValueTypes["CommentsDocAccessFields_createdAt"],
		__typename?: boolean | `@${string}`
}>;
	["CommentsDocAccessFields_commenter"]: AliasType<{
	create?:ValueTypes["CommentsDocAccessFields_commenter_Create"],
	read?:ValueTypes["CommentsDocAccessFields_commenter_Read"],
	update?:ValueTypes["CommentsDocAccessFields_commenter_Update"],
	delete?:ValueTypes["CommentsDocAccessFields_commenter_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["CommentsDocAccessFields_commenter_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CommentsDocAccessFields_commenter_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CommentsDocAccessFields_commenter_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CommentsDocAccessFields_commenter_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CommentsDocAccessFields_content"]: AliasType<{
	create?:ValueTypes["CommentsDocAccessFields_content_Create"],
	read?:ValueTypes["CommentsDocAccessFields_content_Read"],
	update?:ValueTypes["CommentsDocAccessFields_content_Update"],
	delete?:ValueTypes["CommentsDocAccessFields_content_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["CommentsDocAccessFields_content_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CommentsDocAccessFields_content_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CommentsDocAccessFields_content_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CommentsDocAccessFields_content_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CommentsDocAccessFields_image"]: AliasType<{
	create?:ValueTypes["CommentsDocAccessFields_image_Create"],
	read?:ValueTypes["CommentsDocAccessFields_image_Read"],
	update?:ValueTypes["CommentsDocAccessFields_image_Update"],
	delete?:ValueTypes["CommentsDocAccessFields_image_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["CommentsDocAccessFields_image_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CommentsDocAccessFields_image_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CommentsDocAccessFields_image_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CommentsDocAccessFields_image_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CommentsDocAccessFields_target"]: AliasType<{
	create?:ValueTypes["CommentsDocAccessFields_target_Create"],
	read?:ValueTypes["CommentsDocAccessFields_target_Read"],
	update?:ValueTypes["CommentsDocAccessFields_target_Update"],
	delete?:ValueTypes["CommentsDocAccessFields_target_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["CommentsDocAccessFields_target_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CommentsDocAccessFields_target_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CommentsDocAccessFields_target_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CommentsDocAccessFields_target_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CommentsDocAccessFields_parent"]: AliasType<{
	create?:ValueTypes["CommentsDocAccessFields_parent_Create"],
	read?:ValueTypes["CommentsDocAccessFields_parent_Read"],
	update?:ValueTypes["CommentsDocAccessFields_parent_Update"],
	delete?:ValueTypes["CommentsDocAccessFields_parent_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["CommentsDocAccessFields_parent_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CommentsDocAccessFields_parent_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CommentsDocAccessFields_parent_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CommentsDocAccessFields_parent_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CommentsDocAccessFields_isReviewed"]: AliasType<{
	create?:ValueTypes["CommentsDocAccessFields_isReviewed_Create"],
	read?:ValueTypes["CommentsDocAccessFields_isReviewed_Read"],
	update?:ValueTypes["CommentsDocAccessFields_isReviewed_Update"],
	delete?:ValueTypes["CommentsDocAccessFields_isReviewed_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["CommentsDocAccessFields_isReviewed_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CommentsDocAccessFields_isReviewed_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CommentsDocAccessFields_isReviewed_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CommentsDocAccessFields_isReviewed_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CommentsDocAccessFields_reviewedBy"]: AliasType<{
	create?:ValueTypes["CommentsDocAccessFields_reviewedBy_Create"],
	read?:ValueTypes["CommentsDocAccessFields_reviewedBy_Read"],
	update?:ValueTypes["CommentsDocAccessFields_reviewedBy_Update"],
	delete?:ValueTypes["CommentsDocAccessFields_reviewedBy_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["CommentsDocAccessFields_reviewedBy_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CommentsDocAccessFields_reviewedBy_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CommentsDocAccessFields_reviewedBy_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CommentsDocAccessFields_reviewedBy_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CommentsDocAccessFields_banned"]: AliasType<{
	create?:ValueTypes["CommentsDocAccessFields_banned_Create"],
	read?:ValueTypes["CommentsDocAccessFields_banned_Read"],
	update?:ValueTypes["CommentsDocAccessFields_banned_Update"],
	delete?:ValueTypes["CommentsDocAccessFields_banned_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["CommentsDocAccessFields_banned_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CommentsDocAccessFields_banned_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CommentsDocAccessFields_banned_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CommentsDocAccessFields_banned_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CommentsDocAccessFields_type"]: AliasType<{
	create?:ValueTypes["CommentsDocAccessFields_type_Create"],
	read?:ValueTypes["CommentsDocAccessFields_type_Read"],
	update?:ValueTypes["CommentsDocAccessFields_type_Update"],
	delete?:ValueTypes["CommentsDocAccessFields_type_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["CommentsDocAccessFields_type_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CommentsDocAccessFields_type_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CommentsDocAccessFields_type_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CommentsDocAccessFields_type_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CommentsDocAccessFields_updatedAt"]: AliasType<{
	create?:ValueTypes["CommentsDocAccessFields_updatedAt_Create"],
	read?:ValueTypes["CommentsDocAccessFields_updatedAt_Read"],
	update?:ValueTypes["CommentsDocAccessFields_updatedAt_Update"],
	delete?:ValueTypes["CommentsDocAccessFields_updatedAt_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["CommentsDocAccessFields_updatedAt_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CommentsDocAccessFields_updatedAt_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CommentsDocAccessFields_updatedAt_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CommentsDocAccessFields_updatedAt_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CommentsDocAccessFields_createdAt"]: AliasType<{
	create?:ValueTypes["CommentsDocAccessFields_createdAt_Create"],
	read?:ValueTypes["CommentsDocAccessFields_createdAt_Read"],
	update?:ValueTypes["CommentsDocAccessFields_createdAt_Update"],
	delete?:ValueTypes["CommentsDocAccessFields_createdAt_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["CommentsDocAccessFields_createdAt_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CommentsDocAccessFields_createdAt_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CommentsDocAccessFields_createdAt_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CommentsDocAccessFields_createdAt_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CommentsCreateDocAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CommentsReadDocAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CommentsUpdateDocAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CommentsDeleteDocAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["Subscription"]: AliasType<{
	id?:boolean | `@${string}`,
user?: [{	locale?: ValueTypes["LocaleInputType"] | undefined | null | Variable<any, string>,	fallbackLocale?: ValueTypes["FallbackLocaleInputType"] | undefined | null | Variable<any, string>},ValueTypes["Subscription_User_Relationship"]],
organiser?: [{	locale?: ValueTypes["LocaleInputType"] | undefined | null | Variable<any, string>,	fallbackLocale?: ValueTypes["FallbackLocaleInputType"] | undefined | null | Variable<any, string>},ValueTypes["Subscription_Organiser_Relationship"]],
	updatedAt?:boolean | `@${string}`,
	createdAt?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["Subscription_User_Relationship"]: AliasType<{
	relationTo?:boolean | `@${string}`,
	value?:ValueTypes["Subscription_User"],
		__typename?: boolean | `@${string}`
}>;
	["Subscription_User_RelationTo"]:Subscription_User_RelationTo;
	["Subscription_User"]: AliasType<{		["...on User"]?: ValueTypes["User"]
		__typename?: boolean | `@${string}`
}>;
	["Subscription_Organiser_Relationship"]: AliasType<{
	relationTo?:boolean | `@${string}`,
	value?:ValueTypes["Subscription_Organiser"],
		__typename?: boolean | `@${string}`
}>;
	["Subscription_Organiser_RelationTo"]:Subscription_Organiser_RelationTo;
	["Subscription_Organiser"]: AliasType<{		["...on User"]?: ValueTypes["User"]
		__typename?: boolean | `@${string}`
}>;
	["Subscriptions"]: AliasType<{
	docs?:ValueTypes["Subscription"],
	hasNextPage?:boolean | `@${string}`,
	hasPrevPage?:boolean | `@${string}`,
	limit?:boolean | `@${string}`,
	nextPage?:boolean | `@${string}`,
	offset?:boolean | `@${string}`,
	page?:boolean | `@${string}`,
	pagingCounter?:boolean | `@${string}`,
	prevPage?:boolean | `@${string}`,
	totalDocs?:boolean | `@${string}`,
	totalPages?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["Subscription_where"]: {
	user?: ValueTypes["Subscription_user_Relation"] | undefined | null | Variable<any, string>,
	organiser?: ValueTypes["Subscription_organiser_Relation"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["Subscription_updatedAt_operator"] | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["Subscription_createdAt_operator"] | undefined | null | Variable<any, string>,
	id?: ValueTypes["Subscription_id_operator"] | undefined | null | Variable<any, string>,
	AND?: Array<ValueTypes["Subscription_where_and"] | undefined | null> | undefined | null | Variable<any, string>,
	OR?: Array<ValueTypes["Subscription_where_or"] | undefined | null> | undefined | null | Variable<any, string>
};
	["Subscription_user_Relation"]: {
	relationTo?: ValueTypes["Subscription_user_Relation_RelationTo"] | undefined | null | Variable<any, string>,
	value?: ValueTypes["JSON"] | undefined | null | Variable<any, string>
};
	["Subscription_user_Relation_RelationTo"]:Subscription_user_Relation_RelationTo;
	["Subscription_organiser_Relation"]: {
	relationTo?: ValueTypes["Subscription_organiser_Relation_RelationTo"] | undefined | null | Variable<any, string>,
	value?: ValueTypes["JSON"] | undefined | null | Variable<any, string>
};
	["Subscription_organiser_Relation_RelationTo"]:Subscription_organiser_Relation_RelationTo;
	["Subscription_updatedAt_operator"]: {
	equals?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	not_equals?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	greater_than_equal?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	greater_than?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	less_than_equal?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	less_than?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	like?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Subscription_createdAt_operator"]: {
	equals?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	not_equals?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	greater_than_equal?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	greater_than?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	less_than_equal?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	less_than?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	like?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Subscription_id_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Subscription_where_and"]: {
	user?: ValueTypes["Subscription_user_Relation"] | undefined | null | Variable<any, string>,
	organiser?: ValueTypes["Subscription_organiser_Relation"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["Subscription_updatedAt_operator"] | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["Subscription_createdAt_operator"] | undefined | null | Variable<any, string>,
	id?: ValueTypes["Subscription_id_operator"] | undefined | null | Variable<any, string>,
	AND?: Array<ValueTypes["Subscription_where_and"] | undefined | null> | undefined | null | Variable<any, string>,
	OR?: Array<ValueTypes["Subscription_where_or"] | undefined | null> | undefined | null | Variable<any, string>
};
	["Subscription_where_or"]: {
	user?: ValueTypes["Subscription_user_Relation"] | undefined | null | Variable<any, string>,
	organiser?: ValueTypes["Subscription_organiser_Relation"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["Subscription_updatedAt_operator"] | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["Subscription_createdAt_operator"] | undefined | null | Variable<any, string>,
	id?: ValueTypes["Subscription_id_operator"] | undefined | null | Variable<any, string>,
	AND?: Array<ValueTypes["Subscription_where_and"] | undefined | null> | undefined | null | Variable<any, string>,
	OR?: Array<ValueTypes["Subscription_where_or"] | undefined | null> | undefined | null | Variable<any, string>
};
	["countSubscriptions"]: AliasType<{
	totalDocs?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["subscriptionsDocAccess"]: AliasType<{
	fields?:ValueTypes["SubscriptionsDocAccessFields"],
	create?:ValueTypes["SubscriptionsCreateDocAccess"],
	read?:ValueTypes["SubscriptionsReadDocAccess"],
	update?:ValueTypes["SubscriptionsUpdateDocAccess"],
	delete?:ValueTypes["SubscriptionsDeleteDocAccess"],
		__typename?: boolean | `@${string}`
}>;
	["SubscriptionsDocAccessFields"]: AliasType<{
	user?:ValueTypes["SubscriptionsDocAccessFields_user"],
	organiser?:ValueTypes["SubscriptionsDocAccessFields_organiser"],
	updatedAt?:ValueTypes["SubscriptionsDocAccessFields_updatedAt"],
	createdAt?:ValueTypes["SubscriptionsDocAccessFields_createdAt"],
		__typename?: boolean | `@${string}`
}>;
	["SubscriptionsDocAccessFields_user"]: AliasType<{
	create?:ValueTypes["SubscriptionsDocAccessFields_user_Create"],
	read?:ValueTypes["SubscriptionsDocAccessFields_user_Read"],
	update?:ValueTypes["SubscriptionsDocAccessFields_user_Update"],
	delete?:ValueTypes["SubscriptionsDocAccessFields_user_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["SubscriptionsDocAccessFields_user_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["SubscriptionsDocAccessFields_user_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["SubscriptionsDocAccessFields_user_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["SubscriptionsDocAccessFields_user_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["SubscriptionsDocAccessFields_organiser"]: AliasType<{
	create?:ValueTypes["SubscriptionsDocAccessFields_organiser_Create"],
	read?:ValueTypes["SubscriptionsDocAccessFields_organiser_Read"],
	update?:ValueTypes["SubscriptionsDocAccessFields_organiser_Update"],
	delete?:ValueTypes["SubscriptionsDocAccessFields_organiser_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["SubscriptionsDocAccessFields_organiser_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["SubscriptionsDocAccessFields_organiser_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["SubscriptionsDocAccessFields_organiser_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["SubscriptionsDocAccessFields_organiser_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["SubscriptionsDocAccessFields_updatedAt"]: AliasType<{
	create?:ValueTypes["SubscriptionsDocAccessFields_updatedAt_Create"],
	read?:ValueTypes["SubscriptionsDocAccessFields_updatedAt_Read"],
	update?:ValueTypes["SubscriptionsDocAccessFields_updatedAt_Update"],
	delete?:ValueTypes["SubscriptionsDocAccessFields_updatedAt_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["SubscriptionsDocAccessFields_updatedAt_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["SubscriptionsDocAccessFields_updatedAt_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["SubscriptionsDocAccessFields_updatedAt_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["SubscriptionsDocAccessFields_updatedAt_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["SubscriptionsDocAccessFields_createdAt"]: AliasType<{
	create?:ValueTypes["SubscriptionsDocAccessFields_createdAt_Create"],
	read?:ValueTypes["SubscriptionsDocAccessFields_createdAt_Read"],
	update?:ValueTypes["SubscriptionsDocAccessFields_createdAt_Update"],
	delete?:ValueTypes["SubscriptionsDocAccessFields_createdAt_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["SubscriptionsDocAccessFields_createdAt_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["SubscriptionsDocAccessFields_createdAt_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["SubscriptionsDocAccessFields_createdAt_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["SubscriptionsDocAccessFields_createdAt_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["SubscriptionsCreateDocAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["SubscriptionsReadDocAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["SubscriptionsUpdateDocAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["SubscriptionsDeleteDocAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["Newsletter"]: AliasType<{
	id?:boolean | `@${string}`,
	email?:boolean | `@${string}`,
	region?:boolean | `@${string}`,
	updatedAt?:boolean | `@${string}`,
	createdAt?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["Newsletter_region"]:Newsletter_region;
	["Newsletters"]: AliasType<{
	docs?:ValueTypes["Newsletter"],
	hasNextPage?:boolean | `@${string}`,
	hasPrevPage?:boolean | `@${string}`,
	limit?:boolean | `@${string}`,
	nextPage?:boolean | `@${string}`,
	offset?:boolean | `@${string}`,
	page?:boolean | `@${string}`,
	pagingCounter?:boolean | `@${string}`,
	prevPage?:boolean | `@${string}`,
	totalDocs?:boolean | `@${string}`,
	totalPages?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["Newsletter_where"]: {
	email?: ValueTypes["Newsletter_email_operator"] | undefined | null | Variable<any, string>,
	region?: ValueTypes["Newsletter_region_operator"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["Newsletter_updatedAt_operator"] | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["Newsletter_createdAt_operator"] | undefined | null | Variable<any, string>,
	id?: ValueTypes["Newsletter_id_operator"] | undefined | null | Variable<any, string>,
	AND?: Array<ValueTypes["Newsletter_where_and"] | undefined | null> | undefined | null | Variable<any, string>,
	OR?: Array<ValueTypes["Newsletter_where_or"] | undefined | null> | undefined | null | Variable<any, string>
};
	["Newsletter_email_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>
};
	["Newsletter_region_operator"]: {
	equals?: ValueTypes["Newsletter_region_Input"] | undefined | null | Variable<any, string>,
	not_equals?: ValueTypes["Newsletter_region_Input"] | undefined | null | Variable<any, string>,
	in?: Array<ValueTypes["Newsletter_region_Input"] | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<ValueTypes["Newsletter_region_Input"] | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<ValueTypes["Newsletter_region_Input"] | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Newsletter_region_Input"]:Newsletter_region_Input;
	["Newsletter_updatedAt_operator"]: {
	equals?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	not_equals?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	greater_than_equal?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	greater_than?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	less_than_equal?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	less_than?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	like?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Newsletter_createdAt_operator"]: {
	equals?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	not_equals?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	greater_than_equal?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	greater_than?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	less_than_equal?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	less_than?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	like?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Newsletter_id_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Newsletter_where_and"]: {
	email?: ValueTypes["Newsletter_email_operator"] | undefined | null | Variable<any, string>,
	region?: ValueTypes["Newsletter_region_operator"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["Newsletter_updatedAt_operator"] | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["Newsletter_createdAt_operator"] | undefined | null | Variable<any, string>,
	id?: ValueTypes["Newsletter_id_operator"] | undefined | null | Variable<any, string>,
	AND?: Array<ValueTypes["Newsletter_where_and"] | undefined | null> | undefined | null | Variable<any, string>,
	OR?: Array<ValueTypes["Newsletter_where_or"] | undefined | null> | undefined | null | Variable<any, string>
};
	["Newsletter_where_or"]: {
	email?: ValueTypes["Newsletter_email_operator"] | undefined | null | Variable<any, string>,
	region?: ValueTypes["Newsletter_region_operator"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["Newsletter_updatedAt_operator"] | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["Newsletter_createdAt_operator"] | undefined | null | Variable<any, string>,
	id?: ValueTypes["Newsletter_id_operator"] | undefined | null | Variable<any, string>,
	AND?: Array<ValueTypes["Newsletter_where_and"] | undefined | null> | undefined | null | Variable<any, string>,
	OR?: Array<ValueTypes["Newsletter_where_or"] | undefined | null> | undefined | null | Variable<any, string>
};
	["countNewsletters"]: AliasType<{
	totalDocs?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["newsletterDocAccess"]: AliasType<{
	fields?:ValueTypes["NewsletterDocAccessFields"],
	create?:ValueTypes["NewsletterCreateDocAccess"],
	read?:ValueTypes["NewsletterReadDocAccess"],
	update?:ValueTypes["NewsletterUpdateDocAccess"],
	delete?:ValueTypes["NewsletterDeleteDocAccess"],
		__typename?: boolean | `@${string}`
}>;
	["NewsletterDocAccessFields"]: AliasType<{
	email?:ValueTypes["NewsletterDocAccessFields_email"],
	region?:ValueTypes["NewsletterDocAccessFields_region"],
	updatedAt?:ValueTypes["NewsletterDocAccessFields_updatedAt"],
	createdAt?:ValueTypes["NewsletterDocAccessFields_createdAt"],
		__typename?: boolean | `@${string}`
}>;
	["NewsletterDocAccessFields_email"]: AliasType<{
	create?:ValueTypes["NewsletterDocAccessFields_email_Create"],
	read?:ValueTypes["NewsletterDocAccessFields_email_Read"],
	update?:ValueTypes["NewsletterDocAccessFields_email_Update"],
	delete?:ValueTypes["NewsletterDocAccessFields_email_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["NewsletterDocAccessFields_email_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["NewsletterDocAccessFields_email_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["NewsletterDocAccessFields_email_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["NewsletterDocAccessFields_email_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["NewsletterDocAccessFields_region"]: AliasType<{
	create?:ValueTypes["NewsletterDocAccessFields_region_Create"],
	read?:ValueTypes["NewsletterDocAccessFields_region_Read"],
	update?:ValueTypes["NewsletterDocAccessFields_region_Update"],
	delete?:ValueTypes["NewsletterDocAccessFields_region_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["NewsletterDocAccessFields_region_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["NewsletterDocAccessFields_region_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["NewsletterDocAccessFields_region_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["NewsletterDocAccessFields_region_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["NewsletterDocAccessFields_updatedAt"]: AliasType<{
	create?:ValueTypes["NewsletterDocAccessFields_updatedAt_Create"],
	read?:ValueTypes["NewsletterDocAccessFields_updatedAt_Read"],
	update?:ValueTypes["NewsletterDocAccessFields_updatedAt_Update"],
	delete?:ValueTypes["NewsletterDocAccessFields_updatedAt_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["NewsletterDocAccessFields_updatedAt_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["NewsletterDocAccessFields_updatedAt_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["NewsletterDocAccessFields_updatedAt_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["NewsletterDocAccessFields_updatedAt_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["NewsletterDocAccessFields_createdAt"]: AliasType<{
	create?:ValueTypes["NewsletterDocAccessFields_createdAt_Create"],
	read?:ValueTypes["NewsletterDocAccessFields_createdAt_Read"],
	update?:ValueTypes["NewsletterDocAccessFields_createdAt_Update"],
	delete?:ValueTypes["NewsletterDocAccessFields_createdAt_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["NewsletterDocAccessFields_createdAt_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["NewsletterDocAccessFields_createdAt_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["NewsletterDocAccessFields_createdAt_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["NewsletterDocAccessFields_createdAt_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["NewsletterCreateDocAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["NewsletterReadDocAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["NewsletterUpdateDocAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["NewsletterDeleteDocAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BlogCategories"]: AliasType<{
	docs?:ValueTypes["BlogCategory"],
	hasNextPage?:boolean | `@${string}`,
	hasPrevPage?:boolean | `@${string}`,
	limit?:boolean | `@${string}`,
	nextPage?:boolean | `@${string}`,
	offset?:boolean | `@${string}`,
	page?:boolean | `@${string}`,
	pagingCounter?:boolean | `@${string}`,
	prevPage?:boolean | `@${string}`,
	totalDocs?:boolean | `@${string}`,
	totalPages?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BlogCategory_where"]: {
	name?: ValueTypes["BlogCategory_name_operator"] | undefined | null | Variable<any, string>,
	value?: ValueTypes["BlogCategory_value_operator"] | undefined | null | Variable<any, string>,
	icon?: ValueTypes["BlogCategory_icon_operator"] | undefined | null | Variable<any, string>,
	region?: ValueTypes["BlogCategory_region_operator"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["BlogCategory_updatedAt_operator"] | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["BlogCategory_createdAt_operator"] | undefined | null | Variable<any, string>,
	id?: ValueTypes["BlogCategory_id_operator"] | undefined | null | Variable<any, string>,
	AND?: Array<ValueTypes["BlogCategory_where_and"] | undefined | null> | undefined | null | Variable<any, string>,
	OR?: Array<ValueTypes["BlogCategory_where_or"] | undefined | null> | undefined | null | Variable<any, string>
};
	["BlogCategory_name_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>
};
	["BlogCategory_value_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>
};
	["BlogCategory_icon_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["BlogCategory_region_operator"]: {
	equals?: ValueTypes["BlogCategory_region_Input"] | undefined | null | Variable<any, string>,
	not_equals?: ValueTypes["BlogCategory_region_Input"] | undefined | null | Variable<any, string>,
	in?: Array<ValueTypes["BlogCategory_region_Input"] | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<ValueTypes["BlogCategory_region_Input"] | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<ValueTypes["BlogCategory_region_Input"] | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["BlogCategory_region_Input"]:BlogCategory_region_Input;
	["BlogCategory_updatedAt_operator"]: {
	equals?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	not_equals?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	greater_than_equal?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	greater_than?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	less_than_equal?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	less_than?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	like?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["BlogCategory_createdAt_operator"]: {
	equals?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	not_equals?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	greater_than_equal?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	greater_than?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	less_than_equal?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	less_than?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	like?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["BlogCategory_id_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["BlogCategory_where_and"]: {
	name?: ValueTypes["BlogCategory_name_operator"] | undefined | null | Variable<any, string>,
	value?: ValueTypes["BlogCategory_value_operator"] | undefined | null | Variable<any, string>,
	icon?: ValueTypes["BlogCategory_icon_operator"] | undefined | null | Variable<any, string>,
	region?: ValueTypes["BlogCategory_region_operator"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["BlogCategory_updatedAt_operator"] | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["BlogCategory_createdAt_operator"] | undefined | null | Variable<any, string>,
	id?: ValueTypes["BlogCategory_id_operator"] | undefined | null | Variable<any, string>,
	AND?: Array<ValueTypes["BlogCategory_where_and"] | undefined | null> | undefined | null | Variable<any, string>,
	OR?: Array<ValueTypes["BlogCategory_where_or"] | undefined | null> | undefined | null | Variable<any, string>
};
	["BlogCategory_where_or"]: {
	name?: ValueTypes["BlogCategory_name_operator"] | undefined | null | Variable<any, string>,
	value?: ValueTypes["BlogCategory_value_operator"] | undefined | null | Variable<any, string>,
	icon?: ValueTypes["BlogCategory_icon_operator"] | undefined | null | Variable<any, string>,
	region?: ValueTypes["BlogCategory_region_operator"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["BlogCategory_updatedAt_operator"] | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["BlogCategory_createdAt_operator"] | undefined | null | Variable<any, string>,
	id?: ValueTypes["BlogCategory_id_operator"] | undefined | null | Variable<any, string>,
	AND?: Array<ValueTypes["BlogCategory_where_and"] | undefined | null> | undefined | null | Variable<any, string>,
	OR?: Array<ValueTypes["BlogCategory_where_or"] | undefined | null> | undefined | null | Variable<any, string>
};
	["countBlogCategories"]: AliasType<{
	totalDocs?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["blog_categoriesDocAccess"]: AliasType<{
	fields?:ValueTypes["BlogCategoriesDocAccessFields"],
	create?:ValueTypes["BlogCategoriesCreateDocAccess"],
	read?:ValueTypes["BlogCategoriesReadDocAccess"],
	update?:ValueTypes["BlogCategoriesUpdateDocAccess"],
	delete?:ValueTypes["BlogCategoriesDeleteDocAccess"],
		__typename?: boolean | `@${string}`
}>;
	["BlogCategoriesDocAccessFields"]: AliasType<{
	name?:ValueTypes["BlogCategoriesDocAccessFields_name"],
	value?:ValueTypes["BlogCategoriesDocAccessFields_value"],
	icon?:ValueTypes["BlogCategoriesDocAccessFields_icon"],
	region?:ValueTypes["BlogCategoriesDocAccessFields_region"],
	updatedAt?:ValueTypes["BlogCategoriesDocAccessFields_updatedAt"],
	createdAt?:ValueTypes["BlogCategoriesDocAccessFields_createdAt"],
		__typename?: boolean | `@${string}`
}>;
	["BlogCategoriesDocAccessFields_name"]: AliasType<{
	create?:ValueTypes["BlogCategoriesDocAccessFields_name_Create"],
	read?:ValueTypes["BlogCategoriesDocAccessFields_name_Read"],
	update?:ValueTypes["BlogCategoriesDocAccessFields_name_Update"],
	delete?:ValueTypes["BlogCategoriesDocAccessFields_name_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["BlogCategoriesDocAccessFields_name_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BlogCategoriesDocAccessFields_name_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BlogCategoriesDocAccessFields_name_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BlogCategoriesDocAccessFields_name_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BlogCategoriesDocAccessFields_value"]: AliasType<{
	create?:ValueTypes["BlogCategoriesDocAccessFields_value_Create"],
	read?:ValueTypes["BlogCategoriesDocAccessFields_value_Read"],
	update?:ValueTypes["BlogCategoriesDocAccessFields_value_Update"],
	delete?:ValueTypes["BlogCategoriesDocAccessFields_value_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["BlogCategoriesDocAccessFields_value_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BlogCategoriesDocAccessFields_value_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BlogCategoriesDocAccessFields_value_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BlogCategoriesDocAccessFields_value_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BlogCategoriesDocAccessFields_icon"]: AliasType<{
	create?:ValueTypes["BlogCategoriesDocAccessFields_icon_Create"],
	read?:ValueTypes["BlogCategoriesDocAccessFields_icon_Read"],
	update?:ValueTypes["BlogCategoriesDocAccessFields_icon_Update"],
	delete?:ValueTypes["BlogCategoriesDocAccessFields_icon_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["BlogCategoriesDocAccessFields_icon_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BlogCategoriesDocAccessFields_icon_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BlogCategoriesDocAccessFields_icon_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BlogCategoriesDocAccessFields_icon_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BlogCategoriesDocAccessFields_region"]: AliasType<{
	create?:ValueTypes["BlogCategoriesDocAccessFields_region_Create"],
	read?:ValueTypes["BlogCategoriesDocAccessFields_region_Read"],
	update?:ValueTypes["BlogCategoriesDocAccessFields_region_Update"],
	delete?:ValueTypes["BlogCategoriesDocAccessFields_region_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["BlogCategoriesDocAccessFields_region_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BlogCategoriesDocAccessFields_region_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BlogCategoriesDocAccessFields_region_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BlogCategoriesDocAccessFields_region_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BlogCategoriesDocAccessFields_updatedAt"]: AliasType<{
	create?:ValueTypes["BlogCategoriesDocAccessFields_updatedAt_Create"],
	read?:ValueTypes["BlogCategoriesDocAccessFields_updatedAt_Read"],
	update?:ValueTypes["BlogCategoriesDocAccessFields_updatedAt_Update"],
	delete?:ValueTypes["BlogCategoriesDocAccessFields_updatedAt_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["BlogCategoriesDocAccessFields_updatedAt_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BlogCategoriesDocAccessFields_updatedAt_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BlogCategoriesDocAccessFields_updatedAt_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BlogCategoriesDocAccessFields_updatedAt_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BlogCategoriesDocAccessFields_createdAt"]: AliasType<{
	create?:ValueTypes["BlogCategoriesDocAccessFields_createdAt_Create"],
	read?:ValueTypes["BlogCategoriesDocAccessFields_createdAt_Read"],
	update?:ValueTypes["BlogCategoriesDocAccessFields_createdAt_Update"],
	delete?:ValueTypes["BlogCategoriesDocAccessFields_createdAt_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["BlogCategoriesDocAccessFields_createdAt_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BlogCategoriesDocAccessFields_createdAt_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BlogCategoriesDocAccessFields_createdAt_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BlogCategoriesDocAccessFields_createdAt_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BlogCategoriesCreateDocAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BlogCategoriesReadDocAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BlogCategoriesUpdateDocAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BlogCategoriesDeleteDocAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["Tags"]: AliasType<{
	docs?:ValueTypes["Tag"],
	hasNextPage?:boolean | `@${string}`,
	hasPrevPage?:boolean | `@${string}`,
	limit?:boolean | `@${string}`,
	nextPage?:boolean | `@${string}`,
	offset?:boolean | `@${string}`,
	page?:boolean | `@${string}`,
	pagingCounter?:boolean | `@${string}`,
	prevPage?:boolean | `@${string}`,
	totalDocs?:boolean | `@${string}`,
	totalPages?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["Tag_where"]: {
	name?: ValueTypes["Tag_name_operator"] | undefined | null | Variable<any, string>,
	value?: ValueTypes["Tag_value_operator"] | undefined | null | Variable<any, string>,
	region?: ValueTypes["Tag_region_operator"] | undefined | null | Variable<any, string>,
	count?: ValueTypes["Tag_count_operator"] | undefined | null | Variable<any, string>,
	skipCountCheck?: ValueTypes["Tag_skipCountCheck_operator"] | undefined | null | Variable<any, string>,
	removeNextCron?: ValueTypes["Tag_removeNextCron_operator"] | undefined | null | Variable<any, string>,
	id?: ValueTypes["Tag_id_operator"] | undefined | null | Variable<any, string>,
	AND?: Array<ValueTypes["Tag_where_and"] | undefined | null> | undefined | null | Variable<any, string>,
	OR?: Array<ValueTypes["Tag_where_or"] | undefined | null> | undefined | null | Variable<any, string>
};
	["Tag_name_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>
};
	["Tag_value_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>
};
	["Tag_region_operator"]: {
	equals?: ValueTypes["Tag_region_Input"] | undefined | null | Variable<any, string>,
	not_equals?: ValueTypes["Tag_region_Input"] | undefined | null | Variable<any, string>,
	in?: Array<ValueTypes["Tag_region_Input"] | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<ValueTypes["Tag_region_Input"] | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<ValueTypes["Tag_region_Input"] | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Tag_region_Input"]:Tag_region_Input;
	["Tag_count_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Tag_skipCountCheck_operator"]: {
	equals?: boolean | undefined | null | Variable<any, string>,
	not_equals?: boolean | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Tag_removeNextCron_operator"]: {
	equals?: boolean | undefined | null | Variable<any, string>,
	not_equals?: boolean | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Tag_id_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Tag_where_and"]: {
	name?: ValueTypes["Tag_name_operator"] | undefined | null | Variable<any, string>,
	value?: ValueTypes["Tag_value_operator"] | undefined | null | Variable<any, string>,
	region?: ValueTypes["Tag_region_operator"] | undefined | null | Variable<any, string>,
	count?: ValueTypes["Tag_count_operator"] | undefined | null | Variable<any, string>,
	skipCountCheck?: ValueTypes["Tag_skipCountCheck_operator"] | undefined | null | Variable<any, string>,
	removeNextCron?: ValueTypes["Tag_removeNextCron_operator"] | undefined | null | Variable<any, string>,
	id?: ValueTypes["Tag_id_operator"] | undefined | null | Variable<any, string>,
	AND?: Array<ValueTypes["Tag_where_and"] | undefined | null> | undefined | null | Variable<any, string>,
	OR?: Array<ValueTypes["Tag_where_or"] | undefined | null> | undefined | null | Variable<any, string>
};
	["Tag_where_or"]: {
	name?: ValueTypes["Tag_name_operator"] | undefined | null | Variable<any, string>,
	value?: ValueTypes["Tag_value_operator"] | undefined | null | Variable<any, string>,
	region?: ValueTypes["Tag_region_operator"] | undefined | null | Variable<any, string>,
	count?: ValueTypes["Tag_count_operator"] | undefined | null | Variable<any, string>,
	skipCountCheck?: ValueTypes["Tag_skipCountCheck_operator"] | undefined | null | Variable<any, string>,
	removeNextCron?: ValueTypes["Tag_removeNextCron_operator"] | undefined | null | Variable<any, string>,
	id?: ValueTypes["Tag_id_operator"] | undefined | null | Variable<any, string>,
	AND?: Array<ValueTypes["Tag_where_and"] | undefined | null> | undefined | null | Variable<any, string>,
	OR?: Array<ValueTypes["Tag_where_or"] | undefined | null> | undefined | null | Variable<any, string>
};
	["countTags"]: AliasType<{
	totalDocs?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["tagsDocAccess"]: AliasType<{
	fields?:ValueTypes["TagsDocAccessFields"],
	create?:ValueTypes["TagsCreateDocAccess"],
	read?:ValueTypes["TagsReadDocAccess"],
	update?:ValueTypes["TagsUpdateDocAccess"],
	delete?:ValueTypes["TagsDeleteDocAccess"],
		__typename?: boolean | `@${string}`
}>;
	["TagsDocAccessFields"]: AliasType<{
	name?:ValueTypes["TagsDocAccessFields_name"],
	value?:ValueTypes["TagsDocAccessFields_value"],
	region?:ValueTypes["TagsDocAccessFields_region"],
	count?:ValueTypes["TagsDocAccessFields_count"],
	skipCountCheck?:ValueTypes["TagsDocAccessFields_skipCountCheck"],
	removeNextCron?:ValueTypes["TagsDocAccessFields_removeNextCron"],
		__typename?: boolean | `@${string}`
}>;
	["TagsDocAccessFields_name"]: AliasType<{
	create?:ValueTypes["TagsDocAccessFields_name_Create"],
	read?:ValueTypes["TagsDocAccessFields_name_Read"],
	update?:ValueTypes["TagsDocAccessFields_name_Update"],
	delete?:ValueTypes["TagsDocAccessFields_name_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["TagsDocAccessFields_name_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TagsDocAccessFields_name_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TagsDocAccessFields_name_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TagsDocAccessFields_name_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TagsDocAccessFields_value"]: AliasType<{
	create?:ValueTypes["TagsDocAccessFields_value_Create"],
	read?:ValueTypes["TagsDocAccessFields_value_Read"],
	update?:ValueTypes["TagsDocAccessFields_value_Update"],
	delete?:ValueTypes["TagsDocAccessFields_value_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["TagsDocAccessFields_value_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TagsDocAccessFields_value_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TagsDocAccessFields_value_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TagsDocAccessFields_value_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TagsDocAccessFields_region"]: AliasType<{
	create?:ValueTypes["TagsDocAccessFields_region_Create"],
	read?:ValueTypes["TagsDocAccessFields_region_Read"],
	update?:ValueTypes["TagsDocAccessFields_region_Update"],
	delete?:ValueTypes["TagsDocAccessFields_region_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["TagsDocAccessFields_region_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TagsDocAccessFields_region_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TagsDocAccessFields_region_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TagsDocAccessFields_region_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TagsDocAccessFields_count"]: AliasType<{
	create?:ValueTypes["TagsDocAccessFields_count_Create"],
	read?:ValueTypes["TagsDocAccessFields_count_Read"],
	update?:ValueTypes["TagsDocAccessFields_count_Update"],
	delete?:ValueTypes["TagsDocAccessFields_count_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["TagsDocAccessFields_count_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TagsDocAccessFields_count_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TagsDocAccessFields_count_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TagsDocAccessFields_count_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TagsDocAccessFields_skipCountCheck"]: AliasType<{
	create?:ValueTypes["TagsDocAccessFields_skipCountCheck_Create"],
	read?:ValueTypes["TagsDocAccessFields_skipCountCheck_Read"],
	update?:ValueTypes["TagsDocAccessFields_skipCountCheck_Update"],
	delete?:ValueTypes["TagsDocAccessFields_skipCountCheck_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["TagsDocAccessFields_skipCountCheck_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TagsDocAccessFields_skipCountCheck_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TagsDocAccessFields_skipCountCheck_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TagsDocAccessFields_skipCountCheck_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TagsDocAccessFields_removeNextCron"]: AliasType<{
	create?:ValueTypes["TagsDocAccessFields_removeNextCron_Create"],
	read?:ValueTypes["TagsDocAccessFields_removeNextCron_Read"],
	update?:ValueTypes["TagsDocAccessFields_removeNextCron_Update"],
	delete?:ValueTypes["TagsDocAccessFields_removeNextCron_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["TagsDocAccessFields_removeNextCron_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TagsDocAccessFields_removeNextCron_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TagsDocAccessFields_removeNextCron_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TagsDocAccessFields_removeNextCron_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TagsCreateDocAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TagsReadDocAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TagsUpdateDocAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TagsDeleteDocAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["SpecialTags"]: AliasType<{
	docs?:ValueTypes["SpecialTag"],
	hasNextPage?:boolean | `@${string}`,
	hasPrevPage?:boolean | `@${string}`,
	limit?:boolean | `@${string}`,
	nextPage?:boolean | `@${string}`,
	offset?:boolean | `@${string}`,
	page?:boolean | `@${string}`,
	pagingCounter?:boolean | `@${string}`,
	prevPage?:boolean | `@${string}`,
	totalDocs?:boolean | `@${string}`,
	totalPages?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["SpecialTag_where"]: {
	name?: ValueTypes["SpecialTag_name_operator"] | undefined | null | Variable<any, string>,
	value?: ValueTypes["SpecialTag_value_operator"] | undefined | null | Variable<any, string>,
	region?: ValueTypes["SpecialTag_region_operator"] | undefined | null | Variable<any, string>,
	id?: ValueTypes["SpecialTag_id_operator"] | undefined | null | Variable<any, string>,
	AND?: Array<ValueTypes["SpecialTag_where_and"] | undefined | null> | undefined | null | Variable<any, string>,
	OR?: Array<ValueTypes["SpecialTag_where_or"] | undefined | null> | undefined | null | Variable<any, string>
};
	["SpecialTag_name_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>
};
	["SpecialTag_value_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>
};
	["SpecialTag_region_operator"]: {
	equals?: ValueTypes["SpecialTag_region_Input"] | undefined | null | Variable<any, string>,
	not_equals?: ValueTypes["SpecialTag_region_Input"] | undefined | null | Variable<any, string>,
	in?: Array<ValueTypes["SpecialTag_region_Input"] | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<ValueTypes["SpecialTag_region_Input"] | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<ValueTypes["SpecialTag_region_Input"] | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["SpecialTag_region_Input"]:SpecialTag_region_Input;
	["SpecialTag_id_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["SpecialTag_where_and"]: {
	name?: ValueTypes["SpecialTag_name_operator"] | undefined | null | Variable<any, string>,
	value?: ValueTypes["SpecialTag_value_operator"] | undefined | null | Variable<any, string>,
	region?: ValueTypes["SpecialTag_region_operator"] | undefined | null | Variable<any, string>,
	id?: ValueTypes["SpecialTag_id_operator"] | undefined | null | Variable<any, string>,
	AND?: Array<ValueTypes["SpecialTag_where_and"] | undefined | null> | undefined | null | Variable<any, string>,
	OR?: Array<ValueTypes["SpecialTag_where_or"] | undefined | null> | undefined | null | Variable<any, string>
};
	["SpecialTag_where_or"]: {
	name?: ValueTypes["SpecialTag_name_operator"] | undefined | null | Variable<any, string>,
	value?: ValueTypes["SpecialTag_value_operator"] | undefined | null | Variable<any, string>,
	region?: ValueTypes["SpecialTag_region_operator"] | undefined | null | Variable<any, string>,
	id?: ValueTypes["SpecialTag_id_operator"] | undefined | null | Variable<any, string>,
	AND?: Array<ValueTypes["SpecialTag_where_and"] | undefined | null> | undefined | null | Variable<any, string>,
	OR?: Array<ValueTypes["SpecialTag_where_or"] | undefined | null> | undefined | null | Variable<any, string>
};
	["countSpecialTags"]: AliasType<{
	totalDocs?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["specialTagsDocAccess"]: AliasType<{
	fields?:ValueTypes["SpecialTagsDocAccessFields"],
	create?:ValueTypes["SpecialTagsCreateDocAccess"],
	read?:ValueTypes["SpecialTagsReadDocAccess"],
	update?:ValueTypes["SpecialTagsUpdateDocAccess"],
	delete?:ValueTypes["SpecialTagsDeleteDocAccess"],
		__typename?: boolean | `@${string}`
}>;
	["SpecialTagsDocAccessFields"]: AliasType<{
	name?:ValueTypes["SpecialTagsDocAccessFields_name"],
	value?:ValueTypes["SpecialTagsDocAccessFields_value"],
	region?:ValueTypes["SpecialTagsDocAccessFields_region"],
		__typename?: boolean | `@${string}`
}>;
	["SpecialTagsDocAccessFields_name"]: AliasType<{
	create?:ValueTypes["SpecialTagsDocAccessFields_name_Create"],
	read?:ValueTypes["SpecialTagsDocAccessFields_name_Read"],
	update?:ValueTypes["SpecialTagsDocAccessFields_name_Update"],
	delete?:ValueTypes["SpecialTagsDocAccessFields_name_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["SpecialTagsDocAccessFields_name_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["SpecialTagsDocAccessFields_name_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["SpecialTagsDocAccessFields_name_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["SpecialTagsDocAccessFields_name_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["SpecialTagsDocAccessFields_value"]: AliasType<{
	create?:ValueTypes["SpecialTagsDocAccessFields_value_Create"],
	read?:ValueTypes["SpecialTagsDocAccessFields_value_Read"],
	update?:ValueTypes["SpecialTagsDocAccessFields_value_Update"],
	delete?:ValueTypes["SpecialTagsDocAccessFields_value_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["SpecialTagsDocAccessFields_value_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["SpecialTagsDocAccessFields_value_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["SpecialTagsDocAccessFields_value_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["SpecialTagsDocAccessFields_value_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["SpecialTagsDocAccessFields_region"]: AliasType<{
	create?:ValueTypes["SpecialTagsDocAccessFields_region_Create"],
	read?:ValueTypes["SpecialTagsDocAccessFields_region_Read"],
	update?:ValueTypes["SpecialTagsDocAccessFields_region_Update"],
	delete?:ValueTypes["SpecialTagsDocAccessFields_region_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["SpecialTagsDocAccessFields_region_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["SpecialTagsDocAccessFields_region_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["SpecialTagsDocAccessFields_region_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["SpecialTagsDocAccessFields_region_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["SpecialTagsCreateDocAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["SpecialTagsReadDocAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["SpecialTagsUpdateDocAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["SpecialTagsDeleteDocAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["Dates"]: AliasType<{
	docs?:ValueTypes["Date"],
	hasNextPage?:boolean | `@${string}`,
	hasPrevPage?:boolean | `@${string}`,
	limit?:boolean | `@${string}`,
	nextPage?:boolean | `@${string}`,
	offset?:boolean | `@${string}`,
	page?:boolean | `@${string}`,
	pagingCounter?:boolean | `@${string}`,
	prevPage?:boolean | `@${string}`,
	totalDocs?:boolean | `@${string}`,
	totalPages?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["Date_where"]: {
	name?: ValueTypes["Date_name_operator"] | undefined | null | Variable<any, string>,
	value?: ValueTypes["Date_value_operator"] | undefined | null | Variable<any, string>,
	type?: ValueTypes["Date_type_operator"] | undefined | null | Variable<any, string>,
	start?: ValueTypes["Date_start_operator"] | undefined | null | Variable<any, string>,
	end?: ValueTypes["Date_end_operator"] | undefined | null | Variable<any, string>,
	_title?: ValueTypes["Date__title_operator"] | undefined | null | Variable<any, string>,
	region?: ValueTypes["Date_region_operator"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["Date_updatedAt_operator"] | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["Date_createdAt_operator"] | undefined | null | Variable<any, string>,
	id?: ValueTypes["Date_id_operator"] | undefined | null | Variable<any, string>,
	AND?: Array<ValueTypes["Date_where_and"] | undefined | null> | undefined | null | Variable<any, string>,
	OR?: Array<ValueTypes["Date_where_or"] | undefined | null> | undefined | null | Variable<any, string>
};
	["Date_name_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>
};
	["Date_value_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>
};
	["Date_type_operator"]: {
	equals?: ValueTypes["Date_type_Input"] | undefined | null | Variable<any, string>,
	not_equals?: ValueTypes["Date_type_Input"] | undefined | null | Variable<any, string>,
	in?: Array<ValueTypes["Date_type_Input"] | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<ValueTypes["Date_type_Input"] | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<ValueTypes["Date_type_Input"] | undefined | null> | undefined | null | Variable<any, string>
};
	["Date_type_Input"]:Date_type_Input;
	["Date_start_operator"]: {
	equals?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	not_equals?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	greater_than_equal?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	greater_than?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	less_than_equal?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	less_than?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	like?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Date_end_operator"]: {
	equals?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	not_equals?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	greater_than_equal?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	greater_than?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	less_than_equal?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	less_than?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	like?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Date__title_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Date_region_operator"]: {
	equals?: ValueTypes["Date_region_Input"] | undefined | null | Variable<any, string>,
	not_equals?: ValueTypes["Date_region_Input"] | undefined | null | Variable<any, string>,
	in?: Array<ValueTypes["Date_region_Input"] | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<ValueTypes["Date_region_Input"] | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<ValueTypes["Date_region_Input"] | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Date_region_Input"]:Date_region_Input;
	["Date_updatedAt_operator"]: {
	equals?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	not_equals?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	greater_than_equal?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	greater_than?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	less_than_equal?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	less_than?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	like?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Date_createdAt_operator"]: {
	equals?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	not_equals?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	greater_than_equal?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	greater_than?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	less_than_equal?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	less_than?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	like?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Date_id_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Date_where_and"]: {
	name?: ValueTypes["Date_name_operator"] | undefined | null | Variable<any, string>,
	value?: ValueTypes["Date_value_operator"] | undefined | null | Variable<any, string>,
	type?: ValueTypes["Date_type_operator"] | undefined | null | Variable<any, string>,
	start?: ValueTypes["Date_start_operator"] | undefined | null | Variable<any, string>,
	end?: ValueTypes["Date_end_operator"] | undefined | null | Variable<any, string>,
	_title?: ValueTypes["Date__title_operator"] | undefined | null | Variable<any, string>,
	region?: ValueTypes["Date_region_operator"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["Date_updatedAt_operator"] | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["Date_createdAt_operator"] | undefined | null | Variable<any, string>,
	id?: ValueTypes["Date_id_operator"] | undefined | null | Variable<any, string>,
	AND?: Array<ValueTypes["Date_where_and"] | undefined | null> | undefined | null | Variable<any, string>,
	OR?: Array<ValueTypes["Date_where_or"] | undefined | null> | undefined | null | Variable<any, string>
};
	["Date_where_or"]: {
	name?: ValueTypes["Date_name_operator"] | undefined | null | Variable<any, string>,
	value?: ValueTypes["Date_value_operator"] | undefined | null | Variable<any, string>,
	type?: ValueTypes["Date_type_operator"] | undefined | null | Variable<any, string>,
	start?: ValueTypes["Date_start_operator"] | undefined | null | Variable<any, string>,
	end?: ValueTypes["Date_end_operator"] | undefined | null | Variable<any, string>,
	_title?: ValueTypes["Date__title_operator"] | undefined | null | Variable<any, string>,
	region?: ValueTypes["Date_region_operator"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["Date_updatedAt_operator"] | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["Date_createdAt_operator"] | undefined | null | Variable<any, string>,
	id?: ValueTypes["Date_id_operator"] | undefined | null | Variable<any, string>,
	AND?: Array<ValueTypes["Date_where_and"] | undefined | null> | undefined | null | Variable<any, string>,
	OR?: Array<ValueTypes["Date_where_or"] | undefined | null> | undefined | null | Variable<any, string>
};
	["countDates"]: AliasType<{
	totalDocs?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["dateDocAccess"]: AliasType<{
	fields?:ValueTypes["DateDocAccessFields"],
	create?:ValueTypes["DateCreateDocAccess"],
	read?:ValueTypes["DateReadDocAccess"],
	update?:ValueTypes["DateUpdateDocAccess"],
	delete?:ValueTypes["DateDeleteDocAccess"],
		__typename?: boolean | `@${string}`
}>;
	["DateDocAccessFields"]: AliasType<{
	name?:ValueTypes["DateDocAccessFields_name"],
	value?:ValueTypes["DateDocAccessFields_value"],
	type?:ValueTypes["DateDocAccessFields_type"],
	start?:ValueTypes["DateDocAccessFields_start"],
	end?:ValueTypes["DateDocAccessFields_end"],
	_title?:ValueTypes["DateDocAccessFields__title"],
	region?:ValueTypes["DateDocAccessFields_region"],
	updatedAt?:ValueTypes["DateDocAccessFields_updatedAt"],
	createdAt?:ValueTypes["DateDocAccessFields_createdAt"],
		__typename?: boolean | `@${string}`
}>;
	["DateDocAccessFields_name"]: AliasType<{
	create?:ValueTypes["DateDocAccessFields_name_Create"],
	read?:ValueTypes["DateDocAccessFields_name_Read"],
	update?:ValueTypes["DateDocAccessFields_name_Update"],
	delete?:ValueTypes["DateDocAccessFields_name_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["DateDocAccessFields_name_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DateDocAccessFields_name_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DateDocAccessFields_name_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DateDocAccessFields_name_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DateDocAccessFields_value"]: AliasType<{
	create?:ValueTypes["DateDocAccessFields_value_Create"],
	read?:ValueTypes["DateDocAccessFields_value_Read"],
	update?:ValueTypes["DateDocAccessFields_value_Update"],
	delete?:ValueTypes["DateDocAccessFields_value_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["DateDocAccessFields_value_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DateDocAccessFields_value_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DateDocAccessFields_value_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DateDocAccessFields_value_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DateDocAccessFields_type"]: AliasType<{
	create?:ValueTypes["DateDocAccessFields_type_Create"],
	read?:ValueTypes["DateDocAccessFields_type_Read"],
	update?:ValueTypes["DateDocAccessFields_type_Update"],
	delete?:ValueTypes["DateDocAccessFields_type_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["DateDocAccessFields_type_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DateDocAccessFields_type_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DateDocAccessFields_type_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DateDocAccessFields_type_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DateDocAccessFields_start"]: AliasType<{
	create?:ValueTypes["DateDocAccessFields_start_Create"],
	read?:ValueTypes["DateDocAccessFields_start_Read"],
	update?:ValueTypes["DateDocAccessFields_start_Update"],
	delete?:ValueTypes["DateDocAccessFields_start_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["DateDocAccessFields_start_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DateDocAccessFields_start_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DateDocAccessFields_start_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DateDocAccessFields_start_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DateDocAccessFields_end"]: AliasType<{
	create?:ValueTypes["DateDocAccessFields_end_Create"],
	read?:ValueTypes["DateDocAccessFields_end_Read"],
	update?:ValueTypes["DateDocAccessFields_end_Update"],
	delete?:ValueTypes["DateDocAccessFields_end_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["DateDocAccessFields_end_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DateDocAccessFields_end_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DateDocAccessFields_end_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DateDocAccessFields_end_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DateDocAccessFields__title"]: AliasType<{
	create?:ValueTypes["DateDocAccessFields__title_Create"],
	read?:ValueTypes["DateDocAccessFields__title_Read"],
	update?:ValueTypes["DateDocAccessFields__title_Update"],
	delete?:ValueTypes["DateDocAccessFields__title_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["DateDocAccessFields__title_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DateDocAccessFields__title_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DateDocAccessFields__title_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DateDocAccessFields__title_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DateDocAccessFields_region"]: AliasType<{
	create?:ValueTypes["DateDocAccessFields_region_Create"],
	read?:ValueTypes["DateDocAccessFields_region_Read"],
	update?:ValueTypes["DateDocAccessFields_region_Update"],
	delete?:ValueTypes["DateDocAccessFields_region_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["DateDocAccessFields_region_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DateDocAccessFields_region_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DateDocAccessFields_region_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DateDocAccessFields_region_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DateDocAccessFields_updatedAt"]: AliasType<{
	create?:ValueTypes["DateDocAccessFields_updatedAt_Create"],
	read?:ValueTypes["DateDocAccessFields_updatedAt_Read"],
	update?:ValueTypes["DateDocAccessFields_updatedAt_Update"],
	delete?:ValueTypes["DateDocAccessFields_updatedAt_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["DateDocAccessFields_updatedAt_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DateDocAccessFields_updatedAt_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DateDocAccessFields_updatedAt_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DateDocAccessFields_updatedAt_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DateDocAccessFields_createdAt"]: AliasType<{
	create?:ValueTypes["DateDocAccessFields_createdAt_Create"],
	read?:ValueTypes["DateDocAccessFields_createdAt_Read"],
	update?:ValueTypes["DateDocAccessFields_createdAt_Update"],
	delete?:ValueTypes["DateDocAccessFields_createdAt_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["DateDocAccessFields_createdAt_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DateDocAccessFields_createdAt_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DateDocAccessFields_createdAt_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DateDocAccessFields_createdAt_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DateCreateDocAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DateReadDocAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DateUpdateDocAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DateDeleteDocAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["Districts"]: AliasType<{
	docs?:ValueTypes["District"],
	hasNextPage?:boolean | `@${string}`,
	hasPrevPage?:boolean | `@${string}`,
	limit?:boolean | `@${string}`,
	nextPage?:boolean | `@${string}`,
	offset?:boolean | `@${string}`,
	page?:boolean | `@${string}`,
	pagingCounter?:boolean | `@${string}`,
	prevPage?:boolean | `@${string}`,
	totalDocs?:boolean | `@${string}`,
	totalPages?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["District_where"]: {
	name?: ValueTypes["District_name_operator"] | undefined | null | Variable<any, string>,
	level?: ValueTypes["District_level_operator"] | undefined | null | Variable<any, string>,
	children?: ValueTypes["District_children_operator"] | undefined | null | Variable<any, string>,
	value?: ValueTypes["District_value_operator"] | undefined | null | Variable<any, string>,
	lat?: ValueTypes["District_lat_operator"] | undefined | null | Variable<any, string>,
	long?: ValueTypes["District_long_operator"] | undefined | null | Variable<any, string>,
	radius?: ValueTypes["District_radius_operator"] | undefined | null | Variable<any, string>,
	region?: ValueTypes["District_region_operator"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["District_updatedAt_operator"] | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["District_createdAt_operator"] | undefined | null | Variable<any, string>,
	id?: ValueTypes["District_id_operator"] | undefined | null | Variable<any, string>,
	AND?: Array<ValueTypes["District_where_and"] | undefined | null> | undefined | null | Variable<any, string>,
	OR?: Array<ValueTypes["District_where_or"] | undefined | null> | undefined | null | Variable<any, string>
};
	["District_name_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>
};
	["District_level_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>
};
	["District_children_operator"]: {
	equals?: ValueTypes["JSON"] | undefined | null | Variable<any, string>,
	not_equals?: ValueTypes["JSON"] | undefined | null | Variable<any, string>,
	in?: Array<ValueTypes["JSON"] | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<ValueTypes["JSON"] | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<ValueTypes["JSON"] | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["District_value_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>
};
	["District_lat_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["District_long_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["District_radius_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["District_region_operator"]: {
	equals?: ValueTypes["District_region_Input"] | undefined | null | Variable<any, string>,
	not_equals?: ValueTypes["District_region_Input"] | undefined | null | Variable<any, string>,
	in?: Array<ValueTypes["District_region_Input"] | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<ValueTypes["District_region_Input"] | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<ValueTypes["District_region_Input"] | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["District_region_Input"]:District_region_Input;
	["District_updatedAt_operator"]: {
	equals?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	not_equals?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	greater_than_equal?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	greater_than?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	less_than_equal?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	less_than?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	like?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["District_createdAt_operator"]: {
	equals?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	not_equals?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	greater_than_equal?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	greater_than?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	less_than_equal?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	less_than?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	like?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["District_id_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["District_where_and"]: {
	name?: ValueTypes["District_name_operator"] | undefined | null | Variable<any, string>,
	level?: ValueTypes["District_level_operator"] | undefined | null | Variable<any, string>,
	children?: ValueTypes["District_children_operator"] | undefined | null | Variable<any, string>,
	value?: ValueTypes["District_value_operator"] | undefined | null | Variable<any, string>,
	lat?: ValueTypes["District_lat_operator"] | undefined | null | Variable<any, string>,
	long?: ValueTypes["District_long_operator"] | undefined | null | Variable<any, string>,
	radius?: ValueTypes["District_radius_operator"] | undefined | null | Variable<any, string>,
	region?: ValueTypes["District_region_operator"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["District_updatedAt_operator"] | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["District_createdAt_operator"] | undefined | null | Variable<any, string>,
	id?: ValueTypes["District_id_operator"] | undefined | null | Variable<any, string>,
	AND?: Array<ValueTypes["District_where_and"] | undefined | null> | undefined | null | Variable<any, string>,
	OR?: Array<ValueTypes["District_where_or"] | undefined | null> | undefined | null | Variable<any, string>
};
	["District_where_or"]: {
	name?: ValueTypes["District_name_operator"] | undefined | null | Variable<any, string>,
	level?: ValueTypes["District_level_operator"] | undefined | null | Variable<any, string>,
	children?: ValueTypes["District_children_operator"] | undefined | null | Variable<any, string>,
	value?: ValueTypes["District_value_operator"] | undefined | null | Variable<any, string>,
	lat?: ValueTypes["District_lat_operator"] | undefined | null | Variable<any, string>,
	long?: ValueTypes["District_long_operator"] | undefined | null | Variable<any, string>,
	radius?: ValueTypes["District_radius_operator"] | undefined | null | Variable<any, string>,
	region?: ValueTypes["District_region_operator"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["District_updatedAt_operator"] | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["District_createdAt_operator"] | undefined | null | Variable<any, string>,
	id?: ValueTypes["District_id_operator"] | undefined | null | Variable<any, string>,
	AND?: Array<ValueTypes["District_where_and"] | undefined | null> | undefined | null | Variable<any, string>,
	OR?: Array<ValueTypes["District_where_or"] | undefined | null> | undefined | null | Variable<any, string>
};
	["countDistricts"]: AliasType<{
	totalDocs?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["districtsDocAccess"]: AliasType<{
	fields?:ValueTypes["DistrictsDocAccessFields"],
	create?:ValueTypes["DistrictsCreateDocAccess"],
	read?:ValueTypes["DistrictsReadDocAccess"],
	update?:ValueTypes["DistrictsUpdateDocAccess"],
	delete?:ValueTypes["DistrictsDeleteDocAccess"],
		__typename?: boolean | `@${string}`
}>;
	["DistrictsDocAccessFields"]: AliasType<{
	name?:ValueTypes["DistrictsDocAccessFields_name"],
	level?:ValueTypes["DistrictsDocAccessFields_level"],
	children?:ValueTypes["DistrictsDocAccessFields_children"],
	value?:ValueTypes["DistrictsDocAccessFields_value"],
	lat?:ValueTypes["DistrictsDocAccessFields_lat"],
	long?:ValueTypes["DistrictsDocAccessFields_long"],
	radius?:ValueTypes["DistrictsDocAccessFields_radius"],
	region?:ValueTypes["DistrictsDocAccessFields_region"],
	updatedAt?:ValueTypes["DistrictsDocAccessFields_updatedAt"],
	createdAt?:ValueTypes["DistrictsDocAccessFields_createdAt"],
		__typename?: boolean | `@${string}`
}>;
	["DistrictsDocAccessFields_name"]: AliasType<{
	create?:ValueTypes["DistrictsDocAccessFields_name_Create"],
	read?:ValueTypes["DistrictsDocAccessFields_name_Read"],
	update?:ValueTypes["DistrictsDocAccessFields_name_Update"],
	delete?:ValueTypes["DistrictsDocAccessFields_name_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["DistrictsDocAccessFields_name_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DistrictsDocAccessFields_name_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DistrictsDocAccessFields_name_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DistrictsDocAccessFields_name_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DistrictsDocAccessFields_level"]: AliasType<{
	create?:ValueTypes["DistrictsDocAccessFields_level_Create"],
	read?:ValueTypes["DistrictsDocAccessFields_level_Read"],
	update?:ValueTypes["DistrictsDocAccessFields_level_Update"],
	delete?:ValueTypes["DistrictsDocAccessFields_level_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["DistrictsDocAccessFields_level_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DistrictsDocAccessFields_level_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DistrictsDocAccessFields_level_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DistrictsDocAccessFields_level_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DistrictsDocAccessFields_children"]: AliasType<{
	create?:ValueTypes["DistrictsDocAccessFields_children_Create"],
	read?:ValueTypes["DistrictsDocAccessFields_children_Read"],
	update?:ValueTypes["DistrictsDocAccessFields_children_Update"],
	delete?:ValueTypes["DistrictsDocAccessFields_children_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["DistrictsDocAccessFields_children_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DistrictsDocAccessFields_children_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DistrictsDocAccessFields_children_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DistrictsDocAccessFields_children_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DistrictsDocAccessFields_value"]: AliasType<{
	create?:ValueTypes["DistrictsDocAccessFields_value_Create"],
	read?:ValueTypes["DistrictsDocAccessFields_value_Read"],
	update?:ValueTypes["DistrictsDocAccessFields_value_Update"],
	delete?:ValueTypes["DistrictsDocAccessFields_value_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["DistrictsDocAccessFields_value_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DistrictsDocAccessFields_value_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DistrictsDocAccessFields_value_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DistrictsDocAccessFields_value_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DistrictsDocAccessFields_lat"]: AliasType<{
	create?:ValueTypes["DistrictsDocAccessFields_lat_Create"],
	read?:ValueTypes["DistrictsDocAccessFields_lat_Read"],
	update?:ValueTypes["DistrictsDocAccessFields_lat_Update"],
	delete?:ValueTypes["DistrictsDocAccessFields_lat_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["DistrictsDocAccessFields_lat_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DistrictsDocAccessFields_lat_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DistrictsDocAccessFields_lat_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DistrictsDocAccessFields_lat_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DistrictsDocAccessFields_long"]: AliasType<{
	create?:ValueTypes["DistrictsDocAccessFields_long_Create"],
	read?:ValueTypes["DistrictsDocAccessFields_long_Read"],
	update?:ValueTypes["DistrictsDocAccessFields_long_Update"],
	delete?:ValueTypes["DistrictsDocAccessFields_long_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["DistrictsDocAccessFields_long_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DistrictsDocAccessFields_long_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DistrictsDocAccessFields_long_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DistrictsDocAccessFields_long_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DistrictsDocAccessFields_radius"]: AliasType<{
	create?:ValueTypes["DistrictsDocAccessFields_radius_Create"],
	read?:ValueTypes["DistrictsDocAccessFields_radius_Read"],
	update?:ValueTypes["DistrictsDocAccessFields_radius_Update"],
	delete?:ValueTypes["DistrictsDocAccessFields_radius_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["DistrictsDocAccessFields_radius_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DistrictsDocAccessFields_radius_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DistrictsDocAccessFields_radius_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DistrictsDocAccessFields_radius_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DistrictsDocAccessFields_region"]: AliasType<{
	create?:ValueTypes["DistrictsDocAccessFields_region_Create"],
	read?:ValueTypes["DistrictsDocAccessFields_region_Read"],
	update?:ValueTypes["DistrictsDocAccessFields_region_Update"],
	delete?:ValueTypes["DistrictsDocAccessFields_region_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["DistrictsDocAccessFields_region_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DistrictsDocAccessFields_region_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DistrictsDocAccessFields_region_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DistrictsDocAccessFields_region_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DistrictsDocAccessFields_updatedAt"]: AliasType<{
	create?:ValueTypes["DistrictsDocAccessFields_updatedAt_Create"],
	read?:ValueTypes["DistrictsDocAccessFields_updatedAt_Read"],
	update?:ValueTypes["DistrictsDocAccessFields_updatedAt_Update"],
	delete?:ValueTypes["DistrictsDocAccessFields_updatedAt_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["DistrictsDocAccessFields_updatedAt_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DistrictsDocAccessFields_updatedAt_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DistrictsDocAccessFields_updatedAt_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DistrictsDocAccessFields_updatedAt_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DistrictsDocAccessFields_createdAt"]: AliasType<{
	create?:ValueTypes["DistrictsDocAccessFields_createdAt_Create"],
	read?:ValueTypes["DistrictsDocAccessFields_createdAt_Read"],
	update?:ValueTypes["DistrictsDocAccessFields_createdAt_Update"],
	delete?:ValueTypes["DistrictsDocAccessFields_createdAt_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["DistrictsDocAccessFields_createdAt_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DistrictsDocAccessFields_createdAt_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DistrictsDocAccessFields_createdAt_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DistrictsDocAccessFields_createdAt_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DistrictsCreateDocAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DistrictsReadDocAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DistrictsUpdateDocAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DistrictsDeleteDocAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["Audiences"]: AliasType<{
	docs?:ValueTypes["Audience"],
	hasNextPage?:boolean | `@${string}`,
	hasPrevPage?:boolean | `@${string}`,
	limit?:boolean | `@${string}`,
	nextPage?:boolean | `@${string}`,
	offset?:boolean | `@${string}`,
	page?:boolean | `@${string}`,
	pagingCounter?:boolean | `@${string}`,
	prevPage?:boolean | `@${string}`,
	totalDocs?:boolean | `@${string}`,
	totalPages?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["Audience_where"]: {
	name?: ValueTypes["Audience_name_operator"] | undefined | null | Variable<any, string>,
	value?: ValueTypes["Audience_value_operator"] | undefined | null | Variable<any, string>,
	icon?: ValueTypes["Audience_icon_operator"] | undefined | null | Variable<any, string>,
	region?: ValueTypes["Audience_region_operator"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["Audience_updatedAt_operator"] | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["Audience_createdAt_operator"] | undefined | null | Variable<any, string>,
	id?: ValueTypes["Audience_id_operator"] | undefined | null | Variable<any, string>,
	AND?: Array<ValueTypes["Audience_where_and"] | undefined | null> | undefined | null | Variable<any, string>,
	OR?: Array<ValueTypes["Audience_where_or"] | undefined | null> | undefined | null | Variable<any, string>
};
	["Audience_name_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>
};
	["Audience_value_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>
};
	["Audience_icon_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Audience_region_operator"]: {
	equals?: ValueTypes["Audience_region_Input"] | undefined | null | Variable<any, string>,
	not_equals?: ValueTypes["Audience_region_Input"] | undefined | null | Variable<any, string>,
	in?: Array<ValueTypes["Audience_region_Input"] | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<ValueTypes["Audience_region_Input"] | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<ValueTypes["Audience_region_Input"] | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Audience_region_Input"]:Audience_region_Input;
	["Audience_updatedAt_operator"]: {
	equals?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	not_equals?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	greater_than_equal?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	greater_than?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	less_than_equal?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	less_than?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	like?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Audience_createdAt_operator"]: {
	equals?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	not_equals?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	greater_than_equal?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	greater_than?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	less_than_equal?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	less_than?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	like?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Audience_id_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Audience_where_and"]: {
	name?: ValueTypes["Audience_name_operator"] | undefined | null | Variable<any, string>,
	value?: ValueTypes["Audience_value_operator"] | undefined | null | Variable<any, string>,
	icon?: ValueTypes["Audience_icon_operator"] | undefined | null | Variable<any, string>,
	region?: ValueTypes["Audience_region_operator"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["Audience_updatedAt_operator"] | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["Audience_createdAt_operator"] | undefined | null | Variable<any, string>,
	id?: ValueTypes["Audience_id_operator"] | undefined | null | Variable<any, string>,
	AND?: Array<ValueTypes["Audience_where_and"] | undefined | null> | undefined | null | Variable<any, string>,
	OR?: Array<ValueTypes["Audience_where_or"] | undefined | null> | undefined | null | Variable<any, string>
};
	["Audience_where_or"]: {
	name?: ValueTypes["Audience_name_operator"] | undefined | null | Variable<any, string>,
	value?: ValueTypes["Audience_value_operator"] | undefined | null | Variable<any, string>,
	icon?: ValueTypes["Audience_icon_operator"] | undefined | null | Variable<any, string>,
	region?: ValueTypes["Audience_region_operator"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["Audience_updatedAt_operator"] | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["Audience_createdAt_operator"] | undefined | null | Variable<any, string>,
	id?: ValueTypes["Audience_id_operator"] | undefined | null | Variable<any, string>,
	AND?: Array<ValueTypes["Audience_where_and"] | undefined | null> | undefined | null | Variable<any, string>,
	OR?: Array<ValueTypes["Audience_where_or"] | undefined | null> | undefined | null | Variable<any, string>
};
	["countAudiences"]: AliasType<{
	totalDocs?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["audienceDocAccess"]: AliasType<{
	fields?:ValueTypes["AudienceDocAccessFields"],
	create?:ValueTypes["AudienceCreateDocAccess"],
	read?:ValueTypes["AudienceReadDocAccess"],
	update?:ValueTypes["AudienceUpdateDocAccess"],
	delete?:ValueTypes["AudienceDeleteDocAccess"],
		__typename?: boolean | `@${string}`
}>;
	["AudienceDocAccessFields"]: AliasType<{
	name?:ValueTypes["AudienceDocAccessFields_name"],
	value?:ValueTypes["AudienceDocAccessFields_value"],
	icon?:ValueTypes["AudienceDocAccessFields_icon"],
	region?:ValueTypes["AudienceDocAccessFields_region"],
	updatedAt?:ValueTypes["AudienceDocAccessFields_updatedAt"],
	createdAt?:ValueTypes["AudienceDocAccessFields_createdAt"],
		__typename?: boolean | `@${string}`
}>;
	["AudienceDocAccessFields_name"]: AliasType<{
	create?:ValueTypes["AudienceDocAccessFields_name_Create"],
	read?:ValueTypes["AudienceDocAccessFields_name_Read"],
	update?:ValueTypes["AudienceDocAccessFields_name_Update"],
	delete?:ValueTypes["AudienceDocAccessFields_name_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["AudienceDocAccessFields_name_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AudienceDocAccessFields_name_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AudienceDocAccessFields_name_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AudienceDocAccessFields_name_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AudienceDocAccessFields_value"]: AliasType<{
	create?:ValueTypes["AudienceDocAccessFields_value_Create"],
	read?:ValueTypes["AudienceDocAccessFields_value_Read"],
	update?:ValueTypes["AudienceDocAccessFields_value_Update"],
	delete?:ValueTypes["AudienceDocAccessFields_value_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["AudienceDocAccessFields_value_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AudienceDocAccessFields_value_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AudienceDocAccessFields_value_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AudienceDocAccessFields_value_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AudienceDocAccessFields_icon"]: AliasType<{
	create?:ValueTypes["AudienceDocAccessFields_icon_Create"],
	read?:ValueTypes["AudienceDocAccessFields_icon_Read"],
	update?:ValueTypes["AudienceDocAccessFields_icon_Update"],
	delete?:ValueTypes["AudienceDocAccessFields_icon_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["AudienceDocAccessFields_icon_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AudienceDocAccessFields_icon_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AudienceDocAccessFields_icon_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AudienceDocAccessFields_icon_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AudienceDocAccessFields_region"]: AliasType<{
	create?:ValueTypes["AudienceDocAccessFields_region_Create"],
	read?:ValueTypes["AudienceDocAccessFields_region_Read"],
	update?:ValueTypes["AudienceDocAccessFields_region_Update"],
	delete?:ValueTypes["AudienceDocAccessFields_region_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["AudienceDocAccessFields_region_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AudienceDocAccessFields_region_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AudienceDocAccessFields_region_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AudienceDocAccessFields_region_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AudienceDocAccessFields_updatedAt"]: AliasType<{
	create?:ValueTypes["AudienceDocAccessFields_updatedAt_Create"],
	read?:ValueTypes["AudienceDocAccessFields_updatedAt_Read"],
	update?:ValueTypes["AudienceDocAccessFields_updatedAt_Update"],
	delete?:ValueTypes["AudienceDocAccessFields_updatedAt_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["AudienceDocAccessFields_updatedAt_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AudienceDocAccessFields_updatedAt_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AudienceDocAccessFields_updatedAt_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AudienceDocAccessFields_updatedAt_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AudienceDocAccessFields_createdAt"]: AliasType<{
	create?:ValueTypes["AudienceDocAccessFields_createdAt_Create"],
	read?:ValueTypes["AudienceDocAccessFields_createdAt_Read"],
	update?:ValueTypes["AudienceDocAccessFields_createdAt_Update"],
	delete?:ValueTypes["AudienceDocAccessFields_createdAt_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["AudienceDocAccessFields_createdAt_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AudienceDocAccessFields_createdAt_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AudienceDocAccessFields_createdAt_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AudienceDocAccessFields_createdAt_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AudienceCreateDocAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AudienceReadDocAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AudienceUpdateDocAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AudienceDeleteDocAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventCategories"]: AliasType<{
	docs?:ValueTypes["EventCategory"],
	hasNextPage?:boolean | `@${string}`,
	hasPrevPage?:boolean | `@${string}`,
	limit?:boolean | `@${string}`,
	nextPage?:boolean | `@${string}`,
	offset?:boolean | `@${string}`,
	page?:boolean | `@${string}`,
	pagingCounter?:boolean | `@${string}`,
	prevPage?:boolean | `@${string}`,
	totalDocs?:boolean | `@${string}`,
	totalPages?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventCategory_where"]: {
	name?: ValueTypes["EventCategory_name_operator"] | undefined | null | Variable<any, string>,
	value?: ValueTypes["EventCategory_value_operator"] | undefined | null | Variable<any, string>,
	tags?: ValueTypes["EventCategory_tags_operator"] | undefined | null | Variable<any, string>,
	icon?: ValueTypes["EventCategory_icon_operator"] | undefined | null | Variable<any, string>,
	region?: ValueTypes["EventCategory_region_operator"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["EventCategory_updatedAt_operator"] | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["EventCategory_createdAt_operator"] | undefined | null | Variable<any, string>,
	id?: ValueTypes["EventCategory_id_operator"] | undefined | null | Variable<any, string>,
	AND?: Array<ValueTypes["EventCategory_where_and"] | undefined | null> | undefined | null | Variable<any, string>,
	OR?: Array<ValueTypes["EventCategory_where_or"] | undefined | null> | undefined | null | Variable<any, string>
};
	["EventCategory_name_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>
};
	["EventCategory_value_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>
};
	["EventCategory_tags_operator"]: {
	equals?: ValueTypes["JSON"] | undefined | null | Variable<any, string>,
	not_equals?: ValueTypes["JSON"] | undefined | null | Variable<any, string>,
	in?: Array<ValueTypes["JSON"] | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<ValueTypes["JSON"] | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<ValueTypes["JSON"] | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["EventCategory_icon_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["EventCategory_region_operator"]: {
	equals?: ValueTypes["EventCategory_region_Input"] | undefined | null | Variable<any, string>,
	not_equals?: ValueTypes["EventCategory_region_Input"] | undefined | null | Variable<any, string>,
	in?: Array<ValueTypes["EventCategory_region_Input"] | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<ValueTypes["EventCategory_region_Input"] | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<ValueTypes["EventCategory_region_Input"] | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["EventCategory_region_Input"]:EventCategory_region_Input;
	["EventCategory_updatedAt_operator"]: {
	equals?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	not_equals?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	greater_than_equal?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	greater_than?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	less_than_equal?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	less_than?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	like?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["EventCategory_createdAt_operator"]: {
	equals?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	not_equals?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	greater_than_equal?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	greater_than?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	less_than_equal?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	less_than?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	like?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["EventCategory_id_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["EventCategory_where_and"]: {
	name?: ValueTypes["EventCategory_name_operator"] | undefined | null | Variable<any, string>,
	value?: ValueTypes["EventCategory_value_operator"] | undefined | null | Variable<any, string>,
	tags?: ValueTypes["EventCategory_tags_operator"] | undefined | null | Variable<any, string>,
	icon?: ValueTypes["EventCategory_icon_operator"] | undefined | null | Variable<any, string>,
	region?: ValueTypes["EventCategory_region_operator"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["EventCategory_updatedAt_operator"] | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["EventCategory_createdAt_operator"] | undefined | null | Variable<any, string>,
	id?: ValueTypes["EventCategory_id_operator"] | undefined | null | Variable<any, string>,
	AND?: Array<ValueTypes["EventCategory_where_and"] | undefined | null> | undefined | null | Variable<any, string>,
	OR?: Array<ValueTypes["EventCategory_where_or"] | undefined | null> | undefined | null | Variable<any, string>
};
	["EventCategory_where_or"]: {
	name?: ValueTypes["EventCategory_name_operator"] | undefined | null | Variable<any, string>,
	value?: ValueTypes["EventCategory_value_operator"] | undefined | null | Variable<any, string>,
	tags?: ValueTypes["EventCategory_tags_operator"] | undefined | null | Variable<any, string>,
	icon?: ValueTypes["EventCategory_icon_operator"] | undefined | null | Variable<any, string>,
	region?: ValueTypes["EventCategory_region_operator"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["EventCategory_updatedAt_operator"] | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["EventCategory_createdAt_operator"] | undefined | null | Variable<any, string>,
	id?: ValueTypes["EventCategory_id_operator"] | undefined | null | Variable<any, string>,
	AND?: Array<ValueTypes["EventCategory_where_and"] | undefined | null> | undefined | null | Variable<any, string>,
	OR?: Array<ValueTypes["EventCategory_where_or"] | undefined | null> | undefined | null | Variable<any, string>
};
	["countEventCategories"]: AliasType<{
	totalDocs?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["categoryDocAccess"]: AliasType<{
	fields?:ValueTypes["CategoryDocAccessFields"],
	create?:ValueTypes["CategoryCreateDocAccess"],
	read?:ValueTypes["CategoryReadDocAccess"],
	update?:ValueTypes["CategoryUpdateDocAccess"],
	delete?:ValueTypes["CategoryDeleteDocAccess"],
		__typename?: boolean | `@${string}`
}>;
	["CategoryDocAccessFields"]: AliasType<{
	name?:ValueTypes["CategoryDocAccessFields_name"],
	value?:ValueTypes["CategoryDocAccessFields_value"],
	tags?:ValueTypes["CategoryDocAccessFields_tags"],
	icon?:ValueTypes["CategoryDocAccessFields_icon"],
	region?:ValueTypes["CategoryDocAccessFields_region"],
	updatedAt?:ValueTypes["CategoryDocAccessFields_updatedAt"],
	createdAt?:ValueTypes["CategoryDocAccessFields_createdAt"],
		__typename?: boolean | `@${string}`
}>;
	["CategoryDocAccessFields_name"]: AliasType<{
	create?:ValueTypes["CategoryDocAccessFields_name_Create"],
	read?:ValueTypes["CategoryDocAccessFields_name_Read"],
	update?:ValueTypes["CategoryDocAccessFields_name_Update"],
	delete?:ValueTypes["CategoryDocAccessFields_name_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["CategoryDocAccessFields_name_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CategoryDocAccessFields_name_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CategoryDocAccessFields_name_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CategoryDocAccessFields_name_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CategoryDocAccessFields_value"]: AliasType<{
	create?:ValueTypes["CategoryDocAccessFields_value_Create"],
	read?:ValueTypes["CategoryDocAccessFields_value_Read"],
	update?:ValueTypes["CategoryDocAccessFields_value_Update"],
	delete?:ValueTypes["CategoryDocAccessFields_value_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["CategoryDocAccessFields_value_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CategoryDocAccessFields_value_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CategoryDocAccessFields_value_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CategoryDocAccessFields_value_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CategoryDocAccessFields_tags"]: AliasType<{
	create?:ValueTypes["CategoryDocAccessFields_tags_Create"],
	read?:ValueTypes["CategoryDocAccessFields_tags_Read"],
	update?:ValueTypes["CategoryDocAccessFields_tags_Update"],
	delete?:ValueTypes["CategoryDocAccessFields_tags_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["CategoryDocAccessFields_tags_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CategoryDocAccessFields_tags_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CategoryDocAccessFields_tags_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CategoryDocAccessFields_tags_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CategoryDocAccessFields_icon"]: AliasType<{
	create?:ValueTypes["CategoryDocAccessFields_icon_Create"],
	read?:ValueTypes["CategoryDocAccessFields_icon_Read"],
	update?:ValueTypes["CategoryDocAccessFields_icon_Update"],
	delete?:ValueTypes["CategoryDocAccessFields_icon_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["CategoryDocAccessFields_icon_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CategoryDocAccessFields_icon_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CategoryDocAccessFields_icon_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CategoryDocAccessFields_icon_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CategoryDocAccessFields_region"]: AliasType<{
	create?:ValueTypes["CategoryDocAccessFields_region_Create"],
	read?:ValueTypes["CategoryDocAccessFields_region_Read"],
	update?:ValueTypes["CategoryDocAccessFields_region_Update"],
	delete?:ValueTypes["CategoryDocAccessFields_region_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["CategoryDocAccessFields_region_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CategoryDocAccessFields_region_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CategoryDocAccessFields_region_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CategoryDocAccessFields_region_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CategoryDocAccessFields_updatedAt"]: AliasType<{
	create?:ValueTypes["CategoryDocAccessFields_updatedAt_Create"],
	read?:ValueTypes["CategoryDocAccessFields_updatedAt_Read"],
	update?:ValueTypes["CategoryDocAccessFields_updatedAt_Update"],
	delete?:ValueTypes["CategoryDocAccessFields_updatedAt_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["CategoryDocAccessFields_updatedAt_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CategoryDocAccessFields_updatedAt_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CategoryDocAccessFields_updatedAt_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CategoryDocAccessFields_updatedAt_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CategoryDocAccessFields_createdAt"]: AliasType<{
	create?:ValueTypes["CategoryDocAccessFields_createdAt_Create"],
	read?:ValueTypes["CategoryDocAccessFields_createdAt_Read"],
	update?:ValueTypes["CategoryDocAccessFields_createdAt_Update"],
	delete?:ValueTypes["CategoryDocAccessFields_createdAt_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["CategoryDocAccessFields_createdAt_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CategoryDocAccessFields_createdAt_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CategoryDocAccessFields_createdAt_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CategoryDocAccessFields_createdAt_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CategoryCreateDocAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CategoryReadDocAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CategoryUpdateDocAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CategoryDeleteDocAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMedia"]: AliasType<{
	id?:boolean | `@${string}`,
	alt?:boolean | `@${string}`,
	updatedAt?:boolean | `@${string}`,
	createdAt?:boolean | `@${string}`,
	url?:boolean | `@${string}`,
	filename?:boolean | `@${string}`,
	mimeType?:boolean | `@${string}`,
	filesize?:boolean | `@${string}`,
	width?:boolean | `@${string}`,
	height?:boolean | `@${string}`,
	focalX?:boolean | `@${string}`,
	focalY?:boolean | `@${string}`,
	sizes?:ValueTypes["PrivateMedia_Sizes"],
		__typename?: boolean | `@${string}`
}>;
	["PrivateMedia_Sizes"]: AliasType<{
	thumbnail?:ValueTypes["PrivateMedia_Sizes_Thumbnail"],
	portrait?:ValueTypes["PrivateMedia_Sizes_Portrait"],
	hero?:ValueTypes["PrivateMedia_Sizes_Hero"],
	thumbnail_jpeg?:ValueTypes["PrivateMedia_Sizes_Thumbnail_jpeg"],
	portrait_jpeg?:ValueTypes["PrivateMedia_Sizes_Portrait_jpeg"],
	hero_jpeg?:ValueTypes["PrivateMedia_Sizes_Hero_jpeg"],
		__typename?: boolean | `@${string}`
}>;
	["PrivateMedia_Sizes_Thumbnail"]: AliasType<{
	url?:boolean | `@${string}`,
	width?:boolean | `@${string}`,
	height?:boolean | `@${string}`,
	mimeType?:boolean | `@${string}`,
	filesize?:boolean | `@${string}`,
	filename?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMedia_Sizes_Portrait"]: AliasType<{
	url?:boolean | `@${string}`,
	width?:boolean | `@${string}`,
	height?:boolean | `@${string}`,
	mimeType?:boolean | `@${string}`,
	filesize?:boolean | `@${string}`,
	filename?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMedia_Sizes_Hero"]: AliasType<{
	url?:boolean | `@${string}`,
	width?:boolean | `@${string}`,
	height?:boolean | `@${string}`,
	mimeType?:boolean | `@${string}`,
	filesize?:boolean | `@${string}`,
	filename?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMedia_Sizes_Thumbnail_jpeg"]: AliasType<{
	url?:boolean | `@${string}`,
	width?:boolean | `@${string}`,
	height?:boolean | `@${string}`,
	mimeType?:boolean | `@${string}`,
	filesize?:boolean | `@${string}`,
	filename?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMedia_Sizes_Portrait_jpeg"]: AliasType<{
	url?:boolean | `@${string}`,
	width?:boolean | `@${string}`,
	height?:boolean | `@${string}`,
	mimeType?:boolean | `@${string}`,
	filesize?:boolean | `@${string}`,
	filename?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMedia_Sizes_Hero_jpeg"]: AliasType<{
	url?:boolean | `@${string}`,
	width?:boolean | `@${string}`,
	height?:boolean | `@${string}`,
	mimeType?:boolean | `@${string}`,
	filesize?:boolean | `@${string}`,
	filename?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMedias"]: AliasType<{
	docs?:ValueTypes["PrivateMedia"],
	hasNextPage?:boolean | `@${string}`,
	hasPrevPage?:boolean | `@${string}`,
	limit?:boolean | `@${string}`,
	nextPage?:boolean | `@${string}`,
	offset?:boolean | `@${string}`,
	page?:boolean | `@${string}`,
	pagingCounter?:boolean | `@${string}`,
	prevPage?:boolean | `@${string}`,
	totalDocs?:boolean | `@${string}`,
	totalPages?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMedia_where"]: {
	alt?: ValueTypes["PrivateMedia_alt_operator"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["PrivateMedia_updatedAt_operator"] | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["PrivateMedia_createdAt_operator"] | undefined | null | Variable<any, string>,
	url?: ValueTypes["PrivateMedia_url_operator"] | undefined | null | Variable<any, string>,
	filename?: ValueTypes["PrivateMedia_filename_operator"] | undefined | null | Variable<any, string>,
	mimeType?: ValueTypes["PrivateMedia_mimeType_operator"] | undefined | null | Variable<any, string>,
	filesize?: ValueTypes["PrivateMedia_filesize_operator"] | undefined | null | Variable<any, string>,
	width?: ValueTypes["PrivateMedia_width_operator"] | undefined | null | Variable<any, string>,
	height?: ValueTypes["PrivateMedia_height_operator"] | undefined | null | Variable<any, string>,
	focalX?: ValueTypes["PrivateMedia_focalX_operator"] | undefined | null | Variable<any, string>,
	focalY?: ValueTypes["PrivateMedia_focalY_operator"] | undefined | null | Variable<any, string>,
	sizes__thumbnail__url?: ValueTypes["PrivateMedia_sizes__thumbnail__url_operator"] | undefined | null | Variable<any, string>,
	sizes__thumbnail__width?: ValueTypes["PrivateMedia_sizes__thumbnail__width_operator"] | undefined | null | Variable<any, string>,
	sizes__thumbnail__height?: ValueTypes["PrivateMedia_sizes__thumbnail__height_operator"] | undefined | null | Variable<any, string>,
	sizes__thumbnail__mimeType?: ValueTypes["PrivateMedia_sizes__thumbnail__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__thumbnail__filesize?: ValueTypes["PrivateMedia_sizes__thumbnail__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__thumbnail__filename?: ValueTypes["PrivateMedia_sizes__thumbnail__filename_operator"] | undefined | null | Variable<any, string>,
	sizes__portrait__url?: ValueTypes["PrivateMedia_sizes__portrait__url_operator"] | undefined | null | Variable<any, string>,
	sizes__portrait__width?: ValueTypes["PrivateMedia_sizes__portrait__width_operator"] | undefined | null | Variable<any, string>,
	sizes__portrait__height?: ValueTypes["PrivateMedia_sizes__portrait__height_operator"] | undefined | null | Variable<any, string>,
	sizes__portrait__mimeType?: ValueTypes["PrivateMedia_sizes__portrait__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__portrait__filesize?: ValueTypes["PrivateMedia_sizes__portrait__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__portrait__filename?: ValueTypes["PrivateMedia_sizes__portrait__filename_operator"] | undefined | null | Variable<any, string>,
	sizes__hero__url?: ValueTypes["PrivateMedia_sizes__hero__url_operator"] | undefined | null | Variable<any, string>,
	sizes__hero__width?: ValueTypes["PrivateMedia_sizes__hero__width_operator"] | undefined | null | Variable<any, string>,
	sizes__hero__height?: ValueTypes["PrivateMedia_sizes__hero__height_operator"] | undefined | null | Variable<any, string>,
	sizes__hero__mimeType?: ValueTypes["PrivateMedia_sizes__hero__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__hero__filesize?: ValueTypes["PrivateMedia_sizes__hero__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__hero__filename?: ValueTypes["PrivateMedia_sizes__hero__filename_operator"] | undefined | null | Variable<any, string>,
	sizes__thumbnail_jpeg__url?: ValueTypes["PrivateMedia_sizes__thumbnail_jpeg__url_operator"] | undefined | null | Variable<any, string>,
	sizes__thumbnail_jpeg__width?: ValueTypes["PrivateMedia_sizes__thumbnail_jpeg__width_operator"] | undefined | null | Variable<any, string>,
	sizes__thumbnail_jpeg__height?: ValueTypes["PrivateMedia_sizes__thumbnail_jpeg__height_operator"] | undefined | null | Variable<any, string>,
	sizes__thumbnail_jpeg__mimeType?: ValueTypes["PrivateMedia_sizes__thumbnail_jpeg__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__thumbnail_jpeg__filesize?: ValueTypes["PrivateMedia_sizes__thumbnail_jpeg__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__thumbnail_jpeg__filename?: ValueTypes["PrivateMedia_sizes__thumbnail_jpeg__filename_operator"] | undefined | null | Variable<any, string>,
	sizes__portrait_jpeg__url?: ValueTypes["PrivateMedia_sizes__portrait_jpeg__url_operator"] | undefined | null | Variable<any, string>,
	sizes__portrait_jpeg__width?: ValueTypes["PrivateMedia_sizes__portrait_jpeg__width_operator"] | undefined | null | Variable<any, string>,
	sizes__portrait_jpeg__height?: ValueTypes["PrivateMedia_sizes__portrait_jpeg__height_operator"] | undefined | null | Variable<any, string>,
	sizes__portrait_jpeg__mimeType?: ValueTypes["PrivateMedia_sizes__portrait_jpeg__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__portrait_jpeg__filesize?: ValueTypes["PrivateMedia_sizes__portrait_jpeg__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__portrait_jpeg__filename?: ValueTypes["PrivateMedia_sizes__portrait_jpeg__filename_operator"] | undefined | null | Variable<any, string>,
	sizes__hero_jpeg__url?: ValueTypes["PrivateMedia_sizes__hero_jpeg__url_operator"] | undefined | null | Variable<any, string>,
	sizes__hero_jpeg__width?: ValueTypes["PrivateMedia_sizes__hero_jpeg__width_operator"] | undefined | null | Variable<any, string>,
	sizes__hero_jpeg__height?: ValueTypes["PrivateMedia_sizes__hero_jpeg__height_operator"] | undefined | null | Variable<any, string>,
	sizes__hero_jpeg__mimeType?: ValueTypes["PrivateMedia_sizes__hero_jpeg__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__hero_jpeg__filesize?: ValueTypes["PrivateMedia_sizes__hero_jpeg__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__hero_jpeg__filename?: ValueTypes["PrivateMedia_sizes__hero_jpeg__filename_operator"] | undefined | null | Variable<any, string>,
	id?: ValueTypes["PrivateMedia_id_operator"] | undefined | null | Variable<any, string>,
	AND?: Array<ValueTypes["PrivateMedia_where_and"] | undefined | null> | undefined | null | Variable<any, string>,
	OR?: Array<ValueTypes["PrivateMedia_where_or"] | undefined | null> | undefined | null | Variable<any, string>
};
	["PrivateMedia_alt_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["PrivateMedia_updatedAt_operator"]: {
	equals?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	not_equals?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	greater_than_equal?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	greater_than?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	less_than_equal?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	less_than?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	like?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["PrivateMedia_createdAt_operator"]: {
	equals?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	not_equals?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	greater_than_equal?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	greater_than?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	less_than_equal?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	less_than?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	like?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["PrivateMedia_url_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["PrivateMedia_filename_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["PrivateMedia_mimeType_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["PrivateMedia_filesize_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["PrivateMedia_width_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["PrivateMedia_height_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["PrivateMedia_focalX_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["PrivateMedia_focalY_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["PrivateMedia_sizes__thumbnail__url_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["PrivateMedia_sizes__thumbnail__width_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["PrivateMedia_sizes__thumbnail__height_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["PrivateMedia_sizes__thumbnail__mimeType_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["PrivateMedia_sizes__thumbnail__filesize_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["PrivateMedia_sizes__thumbnail__filename_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["PrivateMedia_sizes__portrait__url_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["PrivateMedia_sizes__portrait__width_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["PrivateMedia_sizes__portrait__height_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["PrivateMedia_sizes__portrait__mimeType_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["PrivateMedia_sizes__portrait__filesize_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["PrivateMedia_sizes__portrait__filename_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["PrivateMedia_sizes__hero__url_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["PrivateMedia_sizes__hero__width_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["PrivateMedia_sizes__hero__height_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["PrivateMedia_sizes__hero__mimeType_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["PrivateMedia_sizes__hero__filesize_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["PrivateMedia_sizes__hero__filename_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["PrivateMedia_sizes__thumbnail_jpeg__url_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["PrivateMedia_sizes__thumbnail_jpeg__width_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["PrivateMedia_sizes__thumbnail_jpeg__height_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["PrivateMedia_sizes__thumbnail_jpeg__mimeType_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["PrivateMedia_sizes__thumbnail_jpeg__filesize_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["PrivateMedia_sizes__thumbnail_jpeg__filename_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["PrivateMedia_sizes__portrait_jpeg__url_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["PrivateMedia_sizes__portrait_jpeg__width_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["PrivateMedia_sizes__portrait_jpeg__height_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["PrivateMedia_sizes__portrait_jpeg__mimeType_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["PrivateMedia_sizes__portrait_jpeg__filesize_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["PrivateMedia_sizes__portrait_jpeg__filename_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["PrivateMedia_sizes__hero_jpeg__url_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["PrivateMedia_sizes__hero_jpeg__width_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["PrivateMedia_sizes__hero_jpeg__height_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["PrivateMedia_sizes__hero_jpeg__mimeType_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["PrivateMedia_sizes__hero_jpeg__filesize_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["PrivateMedia_sizes__hero_jpeg__filename_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["PrivateMedia_id_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["PrivateMedia_where_and"]: {
	alt?: ValueTypes["PrivateMedia_alt_operator"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["PrivateMedia_updatedAt_operator"] | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["PrivateMedia_createdAt_operator"] | undefined | null | Variable<any, string>,
	url?: ValueTypes["PrivateMedia_url_operator"] | undefined | null | Variable<any, string>,
	filename?: ValueTypes["PrivateMedia_filename_operator"] | undefined | null | Variable<any, string>,
	mimeType?: ValueTypes["PrivateMedia_mimeType_operator"] | undefined | null | Variable<any, string>,
	filesize?: ValueTypes["PrivateMedia_filesize_operator"] | undefined | null | Variable<any, string>,
	width?: ValueTypes["PrivateMedia_width_operator"] | undefined | null | Variable<any, string>,
	height?: ValueTypes["PrivateMedia_height_operator"] | undefined | null | Variable<any, string>,
	focalX?: ValueTypes["PrivateMedia_focalX_operator"] | undefined | null | Variable<any, string>,
	focalY?: ValueTypes["PrivateMedia_focalY_operator"] | undefined | null | Variable<any, string>,
	sizes__thumbnail__url?: ValueTypes["PrivateMedia_sizes__thumbnail__url_operator"] | undefined | null | Variable<any, string>,
	sizes__thumbnail__width?: ValueTypes["PrivateMedia_sizes__thumbnail__width_operator"] | undefined | null | Variable<any, string>,
	sizes__thumbnail__height?: ValueTypes["PrivateMedia_sizes__thumbnail__height_operator"] | undefined | null | Variable<any, string>,
	sizes__thumbnail__mimeType?: ValueTypes["PrivateMedia_sizes__thumbnail__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__thumbnail__filesize?: ValueTypes["PrivateMedia_sizes__thumbnail__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__thumbnail__filename?: ValueTypes["PrivateMedia_sizes__thumbnail__filename_operator"] | undefined | null | Variable<any, string>,
	sizes__portrait__url?: ValueTypes["PrivateMedia_sizes__portrait__url_operator"] | undefined | null | Variable<any, string>,
	sizes__portrait__width?: ValueTypes["PrivateMedia_sizes__portrait__width_operator"] | undefined | null | Variable<any, string>,
	sizes__portrait__height?: ValueTypes["PrivateMedia_sizes__portrait__height_operator"] | undefined | null | Variable<any, string>,
	sizes__portrait__mimeType?: ValueTypes["PrivateMedia_sizes__portrait__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__portrait__filesize?: ValueTypes["PrivateMedia_sizes__portrait__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__portrait__filename?: ValueTypes["PrivateMedia_sizes__portrait__filename_operator"] | undefined | null | Variable<any, string>,
	sizes__hero__url?: ValueTypes["PrivateMedia_sizes__hero__url_operator"] | undefined | null | Variable<any, string>,
	sizes__hero__width?: ValueTypes["PrivateMedia_sizes__hero__width_operator"] | undefined | null | Variable<any, string>,
	sizes__hero__height?: ValueTypes["PrivateMedia_sizes__hero__height_operator"] | undefined | null | Variable<any, string>,
	sizes__hero__mimeType?: ValueTypes["PrivateMedia_sizes__hero__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__hero__filesize?: ValueTypes["PrivateMedia_sizes__hero__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__hero__filename?: ValueTypes["PrivateMedia_sizes__hero__filename_operator"] | undefined | null | Variable<any, string>,
	sizes__thumbnail_jpeg__url?: ValueTypes["PrivateMedia_sizes__thumbnail_jpeg__url_operator"] | undefined | null | Variable<any, string>,
	sizes__thumbnail_jpeg__width?: ValueTypes["PrivateMedia_sizes__thumbnail_jpeg__width_operator"] | undefined | null | Variable<any, string>,
	sizes__thumbnail_jpeg__height?: ValueTypes["PrivateMedia_sizes__thumbnail_jpeg__height_operator"] | undefined | null | Variable<any, string>,
	sizes__thumbnail_jpeg__mimeType?: ValueTypes["PrivateMedia_sizes__thumbnail_jpeg__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__thumbnail_jpeg__filesize?: ValueTypes["PrivateMedia_sizes__thumbnail_jpeg__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__thumbnail_jpeg__filename?: ValueTypes["PrivateMedia_sizes__thumbnail_jpeg__filename_operator"] | undefined | null | Variable<any, string>,
	sizes__portrait_jpeg__url?: ValueTypes["PrivateMedia_sizes__portrait_jpeg__url_operator"] | undefined | null | Variable<any, string>,
	sizes__portrait_jpeg__width?: ValueTypes["PrivateMedia_sizes__portrait_jpeg__width_operator"] | undefined | null | Variable<any, string>,
	sizes__portrait_jpeg__height?: ValueTypes["PrivateMedia_sizes__portrait_jpeg__height_operator"] | undefined | null | Variable<any, string>,
	sizes__portrait_jpeg__mimeType?: ValueTypes["PrivateMedia_sizes__portrait_jpeg__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__portrait_jpeg__filesize?: ValueTypes["PrivateMedia_sizes__portrait_jpeg__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__portrait_jpeg__filename?: ValueTypes["PrivateMedia_sizes__portrait_jpeg__filename_operator"] | undefined | null | Variable<any, string>,
	sizes__hero_jpeg__url?: ValueTypes["PrivateMedia_sizes__hero_jpeg__url_operator"] | undefined | null | Variable<any, string>,
	sizes__hero_jpeg__width?: ValueTypes["PrivateMedia_sizes__hero_jpeg__width_operator"] | undefined | null | Variable<any, string>,
	sizes__hero_jpeg__height?: ValueTypes["PrivateMedia_sizes__hero_jpeg__height_operator"] | undefined | null | Variable<any, string>,
	sizes__hero_jpeg__mimeType?: ValueTypes["PrivateMedia_sizes__hero_jpeg__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__hero_jpeg__filesize?: ValueTypes["PrivateMedia_sizes__hero_jpeg__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__hero_jpeg__filename?: ValueTypes["PrivateMedia_sizes__hero_jpeg__filename_operator"] | undefined | null | Variable<any, string>,
	id?: ValueTypes["PrivateMedia_id_operator"] | undefined | null | Variable<any, string>,
	AND?: Array<ValueTypes["PrivateMedia_where_and"] | undefined | null> | undefined | null | Variable<any, string>,
	OR?: Array<ValueTypes["PrivateMedia_where_or"] | undefined | null> | undefined | null | Variable<any, string>
};
	["PrivateMedia_where_or"]: {
	alt?: ValueTypes["PrivateMedia_alt_operator"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["PrivateMedia_updatedAt_operator"] | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["PrivateMedia_createdAt_operator"] | undefined | null | Variable<any, string>,
	url?: ValueTypes["PrivateMedia_url_operator"] | undefined | null | Variable<any, string>,
	filename?: ValueTypes["PrivateMedia_filename_operator"] | undefined | null | Variable<any, string>,
	mimeType?: ValueTypes["PrivateMedia_mimeType_operator"] | undefined | null | Variable<any, string>,
	filesize?: ValueTypes["PrivateMedia_filesize_operator"] | undefined | null | Variable<any, string>,
	width?: ValueTypes["PrivateMedia_width_operator"] | undefined | null | Variable<any, string>,
	height?: ValueTypes["PrivateMedia_height_operator"] | undefined | null | Variable<any, string>,
	focalX?: ValueTypes["PrivateMedia_focalX_operator"] | undefined | null | Variable<any, string>,
	focalY?: ValueTypes["PrivateMedia_focalY_operator"] | undefined | null | Variable<any, string>,
	sizes__thumbnail__url?: ValueTypes["PrivateMedia_sizes__thumbnail__url_operator"] | undefined | null | Variable<any, string>,
	sizes__thumbnail__width?: ValueTypes["PrivateMedia_sizes__thumbnail__width_operator"] | undefined | null | Variable<any, string>,
	sizes__thumbnail__height?: ValueTypes["PrivateMedia_sizes__thumbnail__height_operator"] | undefined | null | Variable<any, string>,
	sizes__thumbnail__mimeType?: ValueTypes["PrivateMedia_sizes__thumbnail__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__thumbnail__filesize?: ValueTypes["PrivateMedia_sizes__thumbnail__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__thumbnail__filename?: ValueTypes["PrivateMedia_sizes__thumbnail__filename_operator"] | undefined | null | Variable<any, string>,
	sizes__portrait__url?: ValueTypes["PrivateMedia_sizes__portrait__url_operator"] | undefined | null | Variable<any, string>,
	sizes__portrait__width?: ValueTypes["PrivateMedia_sizes__portrait__width_operator"] | undefined | null | Variable<any, string>,
	sizes__portrait__height?: ValueTypes["PrivateMedia_sizes__portrait__height_operator"] | undefined | null | Variable<any, string>,
	sizes__portrait__mimeType?: ValueTypes["PrivateMedia_sizes__portrait__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__portrait__filesize?: ValueTypes["PrivateMedia_sizes__portrait__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__portrait__filename?: ValueTypes["PrivateMedia_sizes__portrait__filename_operator"] | undefined | null | Variable<any, string>,
	sizes__hero__url?: ValueTypes["PrivateMedia_sizes__hero__url_operator"] | undefined | null | Variable<any, string>,
	sizes__hero__width?: ValueTypes["PrivateMedia_sizes__hero__width_operator"] | undefined | null | Variable<any, string>,
	sizes__hero__height?: ValueTypes["PrivateMedia_sizes__hero__height_operator"] | undefined | null | Variable<any, string>,
	sizes__hero__mimeType?: ValueTypes["PrivateMedia_sizes__hero__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__hero__filesize?: ValueTypes["PrivateMedia_sizes__hero__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__hero__filename?: ValueTypes["PrivateMedia_sizes__hero__filename_operator"] | undefined | null | Variable<any, string>,
	sizes__thumbnail_jpeg__url?: ValueTypes["PrivateMedia_sizes__thumbnail_jpeg__url_operator"] | undefined | null | Variable<any, string>,
	sizes__thumbnail_jpeg__width?: ValueTypes["PrivateMedia_sizes__thumbnail_jpeg__width_operator"] | undefined | null | Variable<any, string>,
	sizes__thumbnail_jpeg__height?: ValueTypes["PrivateMedia_sizes__thumbnail_jpeg__height_operator"] | undefined | null | Variable<any, string>,
	sizes__thumbnail_jpeg__mimeType?: ValueTypes["PrivateMedia_sizes__thumbnail_jpeg__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__thumbnail_jpeg__filesize?: ValueTypes["PrivateMedia_sizes__thumbnail_jpeg__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__thumbnail_jpeg__filename?: ValueTypes["PrivateMedia_sizes__thumbnail_jpeg__filename_operator"] | undefined | null | Variable<any, string>,
	sizes__portrait_jpeg__url?: ValueTypes["PrivateMedia_sizes__portrait_jpeg__url_operator"] | undefined | null | Variable<any, string>,
	sizes__portrait_jpeg__width?: ValueTypes["PrivateMedia_sizes__portrait_jpeg__width_operator"] | undefined | null | Variable<any, string>,
	sizes__portrait_jpeg__height?: ValueTypes["PrivateMedia_sizes__portrait_jpeg__height_operator"] | undefined | null | Variable<any, string>,
	sizes__portrait_jpeg__mimeType?: ValueTypes["PrivateMedia_sizes__portrait_jpeg__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__portrait_jpeg__filesize?: ValueTypes["PrivateMedia_sizes__portrait_jpeg__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__portrait_jpeg__filename?: ValueTypes["PrivateMedia_sizes__portrait_jpeg__filename_operator"] | undefined | null | Variable<any, string>,
	sizes__hero_jpeg__url?: ValueTypes["PrivateMedia_sizes__hero_jpeg__url_operator"] | undefined | null | Variable<any, string>,
	sizes__hero_jpeg__width?: ValueTypes["PrivateMedia_sizes__hero_jpeg__width_operator"] | undefined | null | Variable<any, string>,
	sizes__hero_jpeg__height?: ValueTypes["PrivateMedia_sizes__hero_jpeg__height_operator"] | undefined | null | Variable<any, string>,
	sizes__hero_jpeg__mimeType?: ValueTypes["PrivateMedia_sizes__hero_jpeg__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__hero_jpeg__filesize?: ValueTypes["PrivateMedia_sizes__hero_jpeg__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__hero_jpeg__filename?: ValueTypes["PrivateMedia_sizes__hero_jpeg__filename_operator"] | undefined | null | Variable<any, string>,
	id?: ValueTypes["PrivateMedia_id_operator"] | undefined | null | Variable<any, string>,
	AND?: Array<ValueTypes["PrivateMedia_where_and"] | undefined | null> | undefined | null | Variable<any, string>,
	OR?: Array<ValueTypes["PrivateMedia_where_or"] | undefined | null> | undefined | null | Variable<any, string>
};
	["countPrivateMedias"]: AliasType<{
	totalDocs?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["private_mediaDocAccess"]: AliasType<{
	fields?:ValueTypes["PrivateMediaDocAccessFields"],
	create?:ValueTypes["PrivateMediaCreateDocAccess"],
	read?:ValueTypes["PrivateMediaReadDocAccess"],
	update?:ValueTypes["PrivateMediaUpdateDocAccess"],
	delete?:ValueTypes["PrivateMediaDeleteDocAccess"],
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields"]: AliasType<{
	alt?:ValueTypes["PrivateMediaDocAccessFields_alt"],
	updatedAt?:ValueTypes["PrivateMediaDocAccessFields_updatedAt"],
	createdAt?:ValueTypes["PrivateMediaDocAccessFields_createdAt"],
	url?:ValueTypes["PrivateMediaDocAccessFields_url"],
	filename?:ValueTypes["PrivateMediaDocAccessFields_filename"],
	mimeType?:ValueTypes["PrivateMediaDocAccessFields_mimeType"],
	filesize?:ValueTypes["PrivateMediaDocAccessFields_filesize"],
	width?:ValueTypes["PrivateMediaDocAccessFields_width"],
	height?:ValueTypes["PrivateMediaDocAccessFields_height"],
	focalX?:ValueTypes["PrivateMediaDocAccessFields_focalX"],
	focalY?:ValueTypes["PrivateMediaDocAccessFields_focalY"],
	sizes?:ValueTypes["PrivateMediaDocAccessFields_sizes"],
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_alt"]: AliasType<{
	create?:ValueTypes["PrivateMediaDocAccessFields_alt_Create"],
	read?:ValueTypes["PrivateMediaDocAccessFields_alt_Read"],
	update?:ValueTypes["PrivateMediaDocAccessFields_alt_Update"],
	delete?:ValueTypes["PrivateMediaDocAccessFields_alt_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_alt_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_alt_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_alt_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_alt_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_updatedAt"]: AliasType<{
	create?:ValueTypes["PrivateMediaDocAccessFields_updatedAt_Create"],
	read?:ValueTypes["PrivateMediaDocAccessFields_updatedAt_Read"],
	update?:ValueTypes["PrivateMediaDocAccessFields_updatedAt_Update"],
	delete?:ValueTypes["PrivateMediaDocAccessFields_updatedAt_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_updatedAt_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_updatedAt_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_updatedAt_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_updatedAt_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_createdAt"]: AliasType<{
	create?:ValueTypes["PrivateMediaDocAccessFields_createdAt_Create"],
	read?:ValueTypes["PrivateMediaDocAccessFields_createdAt_Read"],
	update?:ValueTypes["PrivateMediaDocAccessFields_createdAt_Update"],
	delete?:ValueTypes["PrivateMediaDocAccessFields_createdAt_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_createdAt_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_createdAt_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_createdAt_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_createdAt_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_url"]: AliasType<{
	create?:ValueTypes["PrivateMediaDocAccessFields_url_Create"],
	read?:ValueTypes["PrivateMediaDocAccessFields_url_Read"],
	update?:ValueTypes["PrivateMediaDocAccessFields_url_Update"],
	delete?:ValueTypes["PrivateMediaDocAccessFields_url_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_url_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_url_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_url_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_url_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_filename"]: AliasType<{
	create?:ValueTypes["PrivateMediaDocAccessFields_filename_Create"],
	read?:ValueTypes["PrivateMediaDocAccessFields_filename_Read"],
	update?:ValueTypes["PrivateMediaDocAccessFields_filename_Update"],
	delete?:ValueTypes["PrivateMediaDocAccessFields_filename_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_filename_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_filename_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_filename_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_filename_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_mimeType"]: AliasType<{
	create?:ValueTypes["PrivateMediaDocAccessFields_mimeType_Create"],
	read?:ValueTypes["PrivateMediaDocAccessFields_mimeType_Read"],
	update?:ValueTypes["PrivateMediaDocAccessFields_mimeType_Update"],
	delete?:ValueTypes["PrivateMediaDocAccessFields_mimeType_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_mimeType_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_mimeType_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_mimeType_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_mimeType_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_filesize"]: AliasType<{
	create?:ValueTypes["PrivateMediaDocAccessFields_filesize_Create"],
	read?:ValueTypes["PrivateMediaDocAccessFields_filesize_Read"],
	update?:ValueTypes["PrivateMediaDocAccessFields_filesize_Update"],
	delete?:ValueTypes["PrivateMediaDocAccessFields_filesize_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_filesize_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_filesize_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_filesize_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_filesize_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_width"]: AliasType<{
	create?:ValueTypes["PrivateMediaDocAccessFields_width_Create"],
	read?:ValueTypes["PrivateMediaDocAccessFields_width_Read"],
	update?:ValueTypes["PrivateMediaDocAccessFields_width_Update"],
	delete?:ValueTypes["PrivateMediaDocAccessFields_width_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_width_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_width_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_width_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_width_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_height"]: AliasType<{
	create?:ValueTypes["PrivateMediaDocAccessFields_height_Create"],
	read?:ValueTypes["PrivateMediaDocAccessFields_height_Read"],
	update?:ValueTypes["PrivateMediaDocAccessFields_height_Update"],
	delete?:ValueTypes["PrivateMediaDocAccessFields_height_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_height_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_height_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_height_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_height_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_focalX"]: AliasType<{
	create?:ValueTypes["PrivateMediaDocAccessFields_focalX_Create"],
	read?:ValueTypes["PrivateMediaDocAccessFields_focalX_Read"],
	update?:ValueTypes["PrivateMediaDocAccessFields_focalX_Update"],
	delete?:ValueTypes["PrivateMediaDocAccessFields_focalX_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_focalX_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_focalX_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_focalX_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_focalX_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_focalY"]: AliasType<{
	create?:ValueTypes["PrivateMediaDocAccessFields_focalY_Create"],
	read?:ValueTypes["PrivateMediaDocAccessFields_focalY_Read"],
	update?:ValueTypes["PrivateMediaDocAccessFields_focalY_Update"],
	delete?:ValueTypes["PrivateMediaDocAccessFields_focalY_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_focalY_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_focalY_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_focalY_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_focalY_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes"]: AliasType<{
	create?:ValueTypes["PrivateMediaDocAccessFields_sizes_Create"],
	read?:ValueTypes["PrivateMediaDocAccessFields_sizes_Read"],
	update?:ValueTypes["PrivateMediaDocAccessFields_sizes_Update"],
	delete?:ValueTypes["PrivateMediaDocAccessFields_sizes_Delete"],
	fields?:ValueTypes["PrivateMediaDocAccessFields_sizes_Fields"],
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_Fields"]: AliasType<{
	thumbnail?:ValueTypes["PrivateMediaDocAccessFields_sizes_thumbnail"],
	portrait?:ValueTypes["PrivateMediaDocAccessFields_sizes_portrait"],
	hero?:ValueTypes["PrivateMediaDocAccessFields_sizes_hero"],
	thumbnail_jpeg?:ValueTypes["PrivateMediaDocAccessFields_sizes_thumbnail_jpeg"],
	portrait_jpeg?:ValueTypes["PrivateMediaDocAccessFields_sizes_portrait_jpeg"],
	hero_jpeg?:ValueTypes["PrivateMediaDocAccessFields_sizes_hero_jpeg"],
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_thumbnail"]: AliasType<{
	create?:ValueTypes["PrivateMediaDocAccessFields_sizes_thumbnail_Create"],
	read?:ValueTypes["PrivateMediaDocAccessFields_sizes_thumbnail_Read"],
	update?:ValueTypes["PrivateMediaDocAccessFields_sizes_thumbnail_Update"],
	delete?:ValueTypes["PrivateMediaDocAccessFields_sizes_thumbnail_Delete"],
	fields?:ValueTypes["PrivateMediaDocAccessFields_sizes_thumbnail_Fields"],
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_thumbnail_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_thumbnail_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_thumbnail_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_thumbnail_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_thumbnail_Fields"]: AliasType<{
	url?:ValueTypes["PrivateMediaDocAccessFields_sizes_thumbnail_url"],
	width?:ValueTypes["PrivateMediaDocAccessFields_sizes_thumbnail_width"],
	height?:ValueTypes["PrivateMediaDocAccessFields_sizes_thumbnail_height"],
	mimeType?:ValueTypes["PrivateMediaDocAccessFields_sizes_thumbnail_mimeType"],
	filesize?:ValueTypes["PrivateMediaDocAccessFields_sizes_thumbnail_filesize"],
	filename?:ValueTypes["PrivateMediaDocAccessFields_sizes_thumbnail_filename"],
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_thumbnail_url"]: AliasType<{
	create?:ValueTypes["PrivateMediaDocAccessFields_sizes_thumbnail_url_Create"],
	read?:ValueTypes["PrivateMediaDocAccessFields_sizes_thumbnail_url_Read"],
	update?:ValueTypes["PrivateMediaDocAccessFields_sizes_thumbnail_url_Update"],
	delete?:ValueTypes["PrivateMediaDocAccessFields_sizes_thumbnail_url_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_thumbnail_url_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_thumbnail_url_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_thumbnail_url_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_thumbnail_url_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_thumbnail_width"]: AliasType<{
	create?:ValueTypes["PrivateMediaDocAccessFields_sizes_thumbnail_width_Create"],
	read?:ValueTypes["PrivateMediaDocAccessFields_sizes_thumbnail_width_Read"],
	update?:ValueTypes["PrivateMediaDocAccessFields_sizes_thumbnail_width_Update"],
	delete?:ValueTypes["PrivateMediaDocAccessFields_sizes_thumbnail_width_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_thumbnail_width_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_thumbnail_width_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_thumbnail_width_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_thumbnail_width_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_thumbnail_height"]: AliasType<{
	create?:ValueTypes["PrivateMediaDocAccessFields_sizes_thumbnail_height_Create"],
	read?:ValueTypes["PrivateMediaDocAccessFields_sizes_thumbnail_height_Read"],
	update?:ValueTypes["PrivateMediaDocAccessFields_sizes_thumbnail_height_Update"],
	delete?:ValueTypes["PrivateMediaDocAccessFields_sizes_thumbnail_height_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_thumbnail_height_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_thumbnail_height_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_thumbnail_height_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_thumbnail_height_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_thumbnail_mimeType"]: AliasType<{
	create?:ValueTypes["PrivateMediaDocAccessFields_sizes_thumbnail_mimeType_Create"],
	read?:ValueTypes["PrivateMediaDocAccessFields_sizes_thumbnail_mimeType_Read"],
	update?:ValueTypes["PrivateMediaDocAccessFields_sizes_thumbnail_mimeType_Update"],
	delete?:ValueTypes["PrivateMediaDocAccessFields_sizes_thumbnail_mimeType_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_thumbnail_mimeType_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_thumbnail_mimeType_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_thumbnail_mimeType_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_thumbnail_mimeType_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_thumbnail_filesize"]: AliasType<{
	create?:ValueTypes["PrivateMediaDocAccessFields_sizes_thumbnail_filesize_Create"],
	read?:ValueTypes["PrivateMediaDocAccessFields_sizes_thumbnail_filesize_Read"],
	update?:ValueTypes["PrivateMediaDocAccessFields_sizes_thumbnail_filesize_Update"],
	delete?:ValueTypes["PrivateMediaDocAccessFields_sizes_thumbnail_filesize_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_thumbnail_filesize_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_thumbnail_filesize_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_thumbnail_filesize_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_thumbnail_filesize_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_thumbnail_filename"]: AliasType<{
	create?:ValueTypes["PrivateMediaDocAccessFields_sizes_thumbnail_filename_Create"],
	read?:ValueTypes["PrivateMediaDocAccessFields_sizes_thumbnail_filename_Read"],
	update?:ValueTypes["PrivateMediaDocAccessFields_sizes_thumbnail_filename_Update"],
	delete?:ValueTypes["PrivateMediaDocAccessFields_sizes_thumbnail_filename_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_thumbnail_filename_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_thumbnail_filename_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_thumbnail_filename_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_thumbnail_filename_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_portrait"]: AliasType<{
	create?:ValueTypes["PrivateMediaDocAccessFields_sizes_portrait_Create"],
	read?:ValueTypes["PrivateMediaDocAccessFields_sizes_portrait_Read"],
	update?:ValueTypes["PrivateMediaDocAccessFields_sizes_portrait_Update"],
	delete?:ValueTypes["PrivateMediaDocAccessFields_sizes_portrait_Delete"],
	fields?:ValueTypes["PrivateMediaDocAccessFields_sizes_portrait_Fields"],
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_portrait_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_portrait_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_portrait_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_portrait_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_portrait_Fields"]: AliasType<{
	url?:ValueTypes["PrivateMediaDocAccessFields_sizes_portrait_url"],
	width?:ValueTypes["PrivateMediaDocAccessFields_sizes_portrait_width"],
	height?:ValueTypes["PrivateMediaDocAccessFields_sizes_portrait_height"],
	mimeType?:ValueTypes["PrivateMediaDocAccessFields_sizes_portrait_mimeType"],
	filesize?:ValueTypes["PrivateMediaDocAccessFields_sizes_portrait_filesize"],
	filename?:ValueTypes["PrivateMediaDocAccessFields_sizes_portrait_filename"],
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_portrait_url"]: AliasType<{
	create?:ValueTypes["PrivateMediaDocAccessFields_sizes_portrait_url_Create"],
	read?:ValueTypes["PrivateMediaDocAccessFields_sizes_portrait_url_Read"],
	update?:ValueTypes["PrivateMediaDocAccessFields_sizes_portrait_url_Update"],
	delete?:ValueTypes["PrivateMediaDocAccessFields_sizes_portrait_url_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_portrait_url_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_portrait_url_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_portrait_url_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_portrait_url_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_portrait_width"]: AliasType<{
	create?:ValueTypes["PrivateMediaDocAccessFields_sizes_portrait_width_Create"],
	read?:ValueTypes["PrivateMediaDocAccessFields_sizes_portrait_width_Read"],
	update?:ValueTypes["PrivateMediaDocAccessFields_sizes_portrait_width_Update"],
	delete?:ValueTypes["PrivateMediaDocAccessFields_sizes_portrait_width_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_portrait_width_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_portrait_width_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_portrait_width_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_portrait_width_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_portrait_height"]: AliasType<{
	create?:ValueTypes["PrivateMediaDocAccessFields_sizes_portrait_height_Create"],
	read?:ValueTypes["PrivateMediaDocAccessFields_sizes_portrait_height_Read"],
	update?:ValueTypes["PrivateMediaDocAccessFields_sizes_portrait_height_Update"],
	delete?:ValueTypes["PrivateMediaDocAccessFields_sizes_portrait_height_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_portrait_height_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_portrait_height_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_portrait_height_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_portrait_height_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_portrait_mimeType"]: AliasType<{
	create?:ValueTypes["PrivateMediaDocAccessFields_sizes_portrait_mimeType_Create"],
	read?:ValueTypes["PrivateMediaDocAccessFields_sizes_portrait_mimeType_Read"],
	update?:ValueTypes["PrivateMediaDocAccessFields_sizes_portrait_mimeType_Update"],
	delete?:ValueTypes["PrivateMediaDocAccessFields_sizes_portrait_mimeType_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_portrait_mimeType_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_portrait_mimeType_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_portrait_mimeType_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_portrait_mimeType_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_portrait_filesize"]: AliasType<{
	create?:ValueTypes["PrivateMediaDocAccessFields_sizes_portrait_filesize_Create"],
	read?:ValueTypes["PrivateMediaDocAccessFields_sizes_portrait_filesize_Read"],
	update?:ValueTypes["PrivateMediaDocAccessFields_sizes_portrait_filesize_Update"],
	delete?:ValueTypes["PrivateMediaDocAccessFields_sizes_portrait_filesize_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_portrait_filesize_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_portrait_filesize_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_portrait_filesize_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_portrait_filesize_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_portrait_filename"]: AliasType<{
	create?:ValueTypes["PrivateMediaDocAccessFields_sizes_portrait_filename_Create"],
	read?:ValueTypes["PrivateMediaDocAccessFields_sizes_portrait_filename_Read"],
	update?:ValueTypes["PrivateMediaDocAccessFields_sizes_portrait_filename_Update"],
	delete?:ValueTypes["PrivateMediaDocAccessFields_sizes_portrait_filename_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_portrait_filename_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_portrait_filename_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_portrait_filename_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_portrait_filename_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_hero"]: AliasType<{
	create?:ValueTypes["PrivateMediaDocAccessFields_sizes_hero_Create"],
	read?:ValueTypes["PrivateMediaDocAccessFields_sizes_hero_Read"],
	update?:ValueTypes["PrivateMediaDocAccessFields_sizes_hero_Update"],
	delete?:ValueTypes["PrivateMediaDocAccessFields_sizes_hero_Delete"],
	fields?:ValueTypes["PrivateMediaDocAccessFields_sizes_hero_Fields"],
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_hero_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_hero_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_hero_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_hero_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_hero_Fields"]: AliasType<{
	url?:ValueTypes["PrivateMediaDocAccessFields_sizes_hero_url"],
	width?:ValueTypes["PrivateMediaDocAccessFields_sizes_hero_width"],
	height?:ValueTypes["PrivateMediaDocAccessFields_sizes_hero_height"],
	mimeType?:ValueTypes["PrivateMediaDocAccessFields_sizes_hero_mimeType"],
	filesize?:ValueTypes["PrivateMediaDocAccessFields_sizes_hero_filesize"],
	filename?:ValueTypes["PrivateMediaDocAccessFields_sizes_hero_filename"],
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_hero_url"]: AliasType<{
	create?:ValueTypes["PrivateMediaDocAccessFields_sizes_hero_url_Create"],
	read?:ValueTypes["PrivateMediaDocAccessFields_sizes_hero_url_Read"],
	update?:ValueTypes["PrivateMediaDocAccessFields_sizes_hero_url_Update"],
	delete?:ValueTypes["PrivateMediaDocAccessFields_sizes_hero_url_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_hero_url_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_hero_url_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_hero_url_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_hero_url_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_hero_width"]: AliasType<{
	create?:ValueTypes["PrivateMediaDocAccessFields_sizes_hero_width_Create"],
	read?:ValueTypes["PrivateMediaDocAccessFields_sizes_hero_width_Read"],
	update?:ValueTypes["PrivateMediaDocAccessFields_sizes_hero_width_Update"],
	delete?:ValueTypes["PrivateMediaDocAccessFields_sizes_hero_width_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_hero_width_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_hero_width_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_hero_width_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_hero_width_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_hero_height"]: AliasType<{
	create?:ValueTypes["PrivateMediaDocAccessFields_sizes_hero_height_Create"],
	read?:ValueTypes["PrivateMediaDocAccessFields_sizes_hero_height_Read"],
	update?:ValueTypes["PrivateMediaDocAccessFields_sizes_hero_height_Update"],
	delete?:ValueTypes["PrivateMediaDocAccessFields_sizes_hero_height_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_hero_height_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_hero_height_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_hero_height_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_hero_height_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_hero_mimeType"]: AliasType<{
	create?:ValueTypes["PrivateMediaDocAccessFields_sizes_hero_mimeType_Create"],
	read?:ValueTypes["PrivateMediaDocAccessFields_sizes_hero_mimeType_Read"],
	update?:ValueTypes["PrivateMediaDocAccessFields_sizes_hero_mimeType_Update"],
	delete?:ValueTypes["PrivateMediaDocAccessFields_sizes_hero_mimeType_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_hero_mimeType_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_hero_mimeType_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_hero_mimeType_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_hero_mimeType_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_hero_filesize"]: AliasType<{
	create?:ValueTypes["PrivateMediaDocAccessFields_sizes_hero_filesize_Create"],
	read?:ValueTypes["PrivateMediaDocAccessFields_sizes_hero_filesize_Read"],
	update?:ValueTypes["PrivateMediaDocAccessFields_sizes_hero_filesize_Update"],
	delete?:ValueTypes["PrivateMediaDocAccessFields_sizes_hero_filesize_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_hero_filesize_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_hero_filesize_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_hero_filesize_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_hero_filesize_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_hero_filename"]: AliasType<{
	create?:ValueTypes["PrivateMediaDocAccessFields_sizes_hero_filename_Create"],
	read?:ValueTypes["PrivateMediaDocAccessFields_sizes_hero_filename_Read"],
	update?:ValueTypes["PrivateMediaDocAccessFields_sizes_hero_filename_Update"],
	delete?:ValueTypes["PrivateMediaDocAccessFields_sizes_hero_filename_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_hero_filename_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_hero_filename_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_hero_filename_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_hero_filename_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_thumbnail_jpeg"]: AliasType<{
	create?:ValueTypes["PrivateMediaDocAccessFields_sizes_thumbnail_jpeg_Create"],
	read?:ValueTypes["PrivateMediaDocAccessFields_sizes_thumbnail_jpeg_Read"],
	update?:ValueTypes["PrivateMediaDocAccessFields_sizes_thumbnail_jpeg_Update"],
	delete?:ValueTypes["PrivateMediaDocAccessFields_sizes_thumbnail_jpeg_Delete"],
	fields?:ValueTypes["PrivateMediaDocAccessFields_sizes_thumbnail_jpeg_Fields"],
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_thumbnail_jpeg_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_thumbnail_jpeg_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_thumbnail_jpeg_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_thumbnail_jpeg_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_thumbnail_jpeg_Fields"]: AliasType<{
	url?:ValueTypes["PrivateMediaDocAccessFields_sizes_thumbnail_jpeg_url"],
	width?:ValueTypes["PrivateMediaDocAccessFields_sizes_thumbnail_jpeg_width"],
	height?:ValueTypes["PrivateMediaDocAccessFields_sizes_thumbnail_jpeg_height"],
	mimeType?:ValueTypes["PrivateMediaDocAccessFields_sizes_thumbnail_jpeg_mimeType"],
	filesize?:ValueTypes["PrivateMediaDocAccessFields_sizes_thumbnail_jpeg_filesize"],
	filename?:ValueTypes["PrivateMediaDocAccessFields_sizes_thumbnail_jpeg_filename"],
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_thumbnail_jpeg_url"]: AliasType<{
	create?:ValueTypes["PrivateMediaDocAccessFields_sizes_thumbnail_jpeg_url_Create"],
	read?:ValueTypes["PrivateMediaDocAccessFields_sizes_thumbnail_jpeg_url_Read"],
	update?:ValueTypes["PrivateMediaDocAccessFields_sizes_thumbnail_jpeg_url_Update"],
	delete?:ValueTypes["PrivateMediaDocAccessFields_sizes_thumbnail_jpeg_url_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_thumbnail_jpeg_url_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_thumbnail_jpeg_url_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_thumbnail_jpeg_url_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_thumbnail_jpeg_url_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_thumbnail_jpeg_width"]: AliasType<{
	create?:ValueTypes["PrivateMediaDocAccessFields_sizes_thumbnail_jpeg_width_Create"],
	read?:ValueTypes["PrivateMediaDocAccessFields_sizes_thumbnail_jpeg_width_Read"],
	update?:ValueTypes["PrivateMediaDocAccessFields_sizes_thumbnail_jpeg_width_Update"],
	delete?:ValueTypes["PrivateMediaDocAccessFields_sizes_thumbnail_jpeg_width_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_thumbnail_jpeg_width_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_thumbnail_jpeg_width_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_thumbnail_jpeg_width_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_thumbnail_jpeg_width_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_thumbnail_jpeg_height"]: AliasType<{
	create?:ValueTypes["PrivateMediaDocAccessFields_sizes_thumbnail_jpeg_height_Create"],
	read?:ValueTypes["PrivateMediaDocAccessFields_sizes_thumbnail_jpeg_height_Read"],
	update?:ValueTypes["PrivateMediaDocAccessFields_sizes_thumbnail_jpeg_height_Update"],
	delete?:ValueTypes["PrivateMediaDocAccessFields_sizes_thumbnail_jpeg_height_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_thumbnail_jpeg_height_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_thumbnail_jpeg_height_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_thumbnail_jpeg_height_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_thumbnail_jpeg_height_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_thumbnail_jpeg_mimeType"]: AliasType<{
	create?:ValueTypes["PrivateMediaDocAccessFields_sizes_thumbnail_jpeg_mimeType_Create"],
	read?:ValueTypes["PrivateMediaDocAccessFields_sizes_thumbnail_jpeg_mimeType_Read"],
	update?:ValueTypes["PrivateMediaDocAccessFields_sizes_thumbnail_jpeg_mimeType_Update"],
	delete?:ValueTypes["PrivateMediaDocAccessFields_sizes_thumbnail_jpeg_mimeType_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_thumbnail_jpeg_mimeType_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_thumbnail_jpeg_mimeType_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_thumbnail_jpeg_mimeType_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_thumbnail_jpeg_mimeType_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_thumbnail_jpeg_filesize"]: AliasType<{
	create?:ValueTypes["PrivateMediaDocAccessFields_sizes_thumbnail_jpeg_filesize_Create"],
	read?:ValueTypes["PrivateMediaDocAccessFields_sizes_thumbnail_jpeg_filesize_Read"],
	update?:ValueTypes["PrivateMediaDocAccessFields_sizes_thumbnail_jpeg_filesize_Update"],
	delete?:ValueTypes["PrivateMediaDocAccessFields_sizes_thumbnail_jpeg_filesize_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_thumbnail_jpeg_filesize_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_thumbnail_jpeg_filesize_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_thumbnail_jpeg_filesize_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_thumbnail_jpeg_filesize_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_thumbnail_jpeg_filename"]: AliasType<{
	create?:ValueTypes["PrivateMediaDocAccessFields_sizes_thumbnail_jpeg_filename_Create"],
	read?:ValueTypes["PrivateMediaDocAccessFields_sizes_thumbnail_jpeg_filename_Read"],
	update?:ValueTypes["PrivateMediaDocAccessFields_sizes_thumbnail_jpeg_filename_Update"],
	delete?:ValueTypes["PrivateMediaDocAccessFields_sizes_thumbnail_jpeg_filename_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_thumbnail_jpeg_filename_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_thumbnail_jpeg_filename_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_thumbnail_jpeg_filename_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_thumbnail_jpeg_filename_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_portrait_jpeg"]: AliasType<{
	create?:ValueTypes["PrivateMediaDocAccessFields_sizes_portrait_jpeg_Create"],
	read?:ValueTypes["PrivateMediaDocAccessFields_sizes_portrait_jpeg_Read"],
	update?:ValueTypes["PrivateMediaDocAccessFields_sizes_portrait_jpeg_Update"],
	delete?:ValueTypes["PrivateMediaDocAccessFields_sizes_portrait_jpeg_Delete"],
	fields?:ValueTypes["PrivateMediaDocAccessFields_sizes_portrait_jpeg_Fields"],
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_portrait_jpeg_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_portrait_jpeg_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_portrait_jpeg_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_portrait_jpeg_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_portrait_jpeg_Fields"]: AliasType<{
	url?:ValueTypes["PrivateMediaDocAccessFields_sizes_portrait_jpeg_url"],
	width?:ValueTypes["PrivateMediaDocAccessFields_sizes_portrait_jpeg_width"],
	height?:ValueTypes["PrivateMediaDocAccessFields_sizes_portrait_jpeg_height"],
	mimeType?:ValueTypes["PrivateMediaDocAccessFields_sizes_portrait_jpeg_mimeType"],
	filesize?:ValueTypes["PrivateMediaDocAccessFields_sizes_portrait_jpeg_filesize"],
	filename?:ValueTypes["PrivateMediaDocAccessFields_sizes_portrait_jpeg_filename"],
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_portrait_jpeg_url"]: AliasType<{
	create?:ValueTypes["PrivateMediaDocAccessFields_sizes_portrait_jpeg_url_Create"],
	read?:ValueTypes["PrivateMediaDocAccessFields_sizes_portrait_jpeg_url_Read"],
	update?:ValueTypes["PrivateMediaDocAccessFields_sizes_portrait_jpeg_url_Update"],
	delete?:ValueTypes["PrivateMediaDocAccessFields_sizes_portrait_jpeg_url_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_portrait_jpeg_url_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_portrait_jpeg_url_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_portrait_jpeg_url_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_portrait_jpeg_url_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_portrait_jpeg_width"]: AliasType<{
	create?:ValueTypes["PrivateMediaDocAccessFields_sizes_portrait_jpeg_width_Create"],
	read?:ValueTypes["PrivateMediaDocAccessFields_sizes_portrait_jpeg_width_Read"],
	update?:ValueTypes["PrivateMediaDocAccessFields_sizes_portrait_jpeg_width_Update"],
	delete?:ValueTypes["PrivateMediaDocAccessFields_sizes_portrait_jpeg_width_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_portrait_jpeg_width_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_portrait_jpeg_width_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_portrait_jpeg_width_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_portrait_jpeg_width_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_portrait_jpeg_height"]: AliasType<{
	create?:ValueTypes["PrivateMediaDocAccessFields_sizes_portrait_jpeg_height_Create"],
	read?:ValueTypes["PrivateMediaDocAccessFields_sizes_portrait_jpeg_height_Read"],
	update?:ValueTypes["PrivateMediaDocAccessFields_sizes_portrait_jpeg_height_Update"],
	delete?:ValueTypes["PrivateMediaDocAccessFields_sizes_portrait_jpeg_height_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_portrait_jpeg_height_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_portrait_jpeg_height_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_portrait_jpeg_height_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_portrait_jpeg_height_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_portrait_jpeg_mimeType"]: AliasType<{
	create?:ValueTypes["PrivateMediaDocAccessFields_sizes_portrait_jpeg_mimeType_Create"],
	read?:ValueTypes["PrivateMediaDocAccessFields_sizes_portrait_jpeg_mimeType_Read"],
	update?:ValueTypes["PrivateMediaDocAccessFields_sizes_portrait_jpeg_mimeType_Update"],
	delete?:ValueTypes["PrivateMediaDocAccessFields_sizes_portrait_jpeg_mimeType_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_portrait_jpeg_mimeType_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_portrait_jpeg_mimeType_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_portrait_jpeg_mimeType_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_portrait_jpeg_mimeType_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_portrait_jpeg_filesize"]: AliasType<{
	create?:ValueTypes["PrivateMediaDocAccessFields_sizes_portrait_jpeg_filesize_Create"],
	read?:ValueTypes["PrivateMediaDocAccessFields_sizes_portrait_jpeg_filesize_Read"],
	update?:ValueTypes["PrivateMediaDocAccessFields_sizes_portrait_jpeg_filesize_Update"],
	delete?:ValueTypes["PrivateMediaDocAccessFields_sizes_portrait_jpeg_filesize_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_portrait_jpeg_filesize_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_portrait_jpeg_filesize_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_portrait_jpeg_filesize_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_portrait_jpeg_filesize_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_portrait_jpeg_filename"]: AliasType<{
	create?:ValueTypes["PrivateMediaDocAccessFields_sizes_portrait_jpeg_filename_Create"],
	read?:ValueTypes["PrivateMediaDocAccessFields_sizes_portrait_jpeg_filename_Read"],
	update?:ValueTypes["PrivateMediaDocAccessFields_sizes_portrait_jpeg_filename_Update"],
	delete?:ValueTypes["PrivateMediaDocAccessFields_sizes_portrait_jpeg_filename_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_portrait_jpeg_filename_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_portrait_jpeg_filename_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_portrait_jpeg_filename_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_portrait_jpeg_filename_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_hero_jpeg"]: AliasType<{
	create?:ValueTypes["PrivateMediaDocAccessFields_sizes_hero_jpeg_Create"],
	read?:ValueTypes["PrivateMediaDocAccessFields_sizes_hero_jpeg_Read"],
	update?:ValueTypes["PrivateMediaDocAccessFields_sizes_hero_jpeg_Update"],
	delete?:ValueTypes["PrivateMediaDocAccessFields_sizes_hero_jpeg_Delete"],
	fields?:ValueTypes["PrivateMediaDocAccessFields_sizes_hero_jpeg_Fields"],
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_hero_jpeg_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_hero_jpeg_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_hero_jpeg_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_hero_jpeg_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_hero_jpeg_Fields"]: AliasType<{
	url?:ValueTypes["PrivateMediaDocAccessFields_sizes_hero_jpeg_url"],
	width?:ValueTypes["PrivateMediaDocAccessFields_sizes_hero_jpeg_width"],
	height?:ValueTypes["PrivateMediaDocAccessFields_sizes_hero_jpeg_height"],
	mimeType?:ValueTypes["PrivateMediaDocAccessFields_sizes_hero_jpeg_mimeType"],
	filesize?:ValueTypes["PrivateMediaDocAccessFields_sizes_hero_jpeg_filesize"],
	filename?:ValueTypes["PrivateMediaDocAccessFields_sizes_hero_jpeg_filename"],
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_hero_jpeg_url"]: AliasType<{
	create?:ValueTypes["PrivateMediaDocAccessFields_sizes_hero_jpeg_url_Create"],
	read?:ValueTypes["PrivateMediaDocAccessFields_sizes_hero_jpeg_url_Read"],
	update?:ValueTypes["PrivateMediaDocAccessFields_sizes_hero_jpeg_url_Update"],
	delete?:ValueTypes["PrivateMediaDocAccessFields_sizes_hero_jpeg_url_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_hero_jpeg_url_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_hero_jpeg_url_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_hero_jpeg_url_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_hero_jpeg_url_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_hero_jpeg_width"]: AliasType<{
	create?:ValueTypes["PrivateMediaDocAccessFields_sizes_hero_jpeg_width_Create"],
	read?:ValueTypes["PrivateMediaDocAccessFields_sizes_hero_jpeg_width_Read"],
	update?:ValueTypes["PrivateMediaDocAccessFields_sizes_hero_jpeg_width_Update"],
	delete?:ValueTypes["PrivateMediaDocAccessFields_sizes_hero_jpeg_width_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_hero_jpeg_width_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_hero_jpeg_width_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_hero_jpeg_width_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_hero_jpeg_width_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_hero_jpeg_height"]: AliasType<{
	create?:ValueTypes["PrivateMediaDocAccessFields_sizes_hero_jpeg_height_Create"],
	read?:ValueTypes["PrivateMediaDocAccessFields_sizes_hero_jpeg_height_Read"],
	update?:ValueTypes["PrivateMediaDocAccessFields_sizes_hero_jpeg_height_Update"],
	delete?:ValueTypes["PrivateMediaDocAccessFields_sizes_hero_jpeg_height_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_hero_jpeg_height_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_hero_jpeg_height_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_hero_jpeg_height_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_hero_jpeg_height_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_hero_jpeg_mimeType"]: AliasType<{
	create?:ValueTypes["PrivateMediaDocAccessFields_sizes_hero_jpeg_mimeType_Create"],
	read?:ValueTypes["PrivateMediaDocAccessFields_sizes_hero_jpeg_mimeType_Read"],
	update?:ValueTypes["PrivateMediaDocAccessFields_sizes_hero_jpeg_mimeType_Update"],
	delete?:ValueTypes["PrivateMediaDocAccessFields_sizes_hero_jpeg_mimeType_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_hero_jpeg_mimeType_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_hero_jpeg_mimeType_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_hero_jpeg_mimeType_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_hero_jpeg_mimeType_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_hero_jpeg_filesize"]: AliasType<{
	create?:ValueTypes["PrivateMediaDocAccessFields_sizes_hero_jpeg_filesize_Create"],
	read?:ValueTypes["PrivateMediaDocAccessFields_sizes_hero_jpeg_filesize_Read"],
	update?:ValueTypes["PrivateMediaDocAccessFields_sizes_hero_jpeg_filesize_Update"],
	delete?:ValueTypes["PrivateMediaDocAccessFields_sizes_hero_jpeg_filesize_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_hero_jpeg_filesize_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_hero_jpeg_filesize_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_hero_jpeg_filesize_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_hero_jpeg_filesize_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_hero_jpeg_filename"]: AliasType<{
	create?:ValueTypes["PrivateMediaDocAccessFields_sizes_hero_jpeg_filename_Create"],
	read?:ValueTypes["PrivateMediaDocAccessFields_sizes_hero_jpeg_filename_Read"],
	update?:ValueTypes["PrivateMediaDocAccessFields_sizes_hero_jpeg_filename_Update"],
	delete?:ValueTypes["PrivateMediaDocAccessFields_sizes_hero_jpeg_filename_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_hero_jpeg_filename_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_hero_jpeg_filename_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_hero_jpeg_filename_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_hero_jpeg_filename_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaCreateDocAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaReadDocAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaUpdateDocAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDeleteDocAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["allMedia"]: AliasType<{
	docs?:ValueTypes["Media"],
	hasNextPage?:boolean | `@${string}`,
	hasPrevPage?:boolean | `@${string}`,
	limit?:boolean | `@${string}`,
	nextPage?:boolean | `@${string}`,
	offset?:boolean | `@${string}`,
	page?:boolean | `@${string}`,
	pagingCounter?:boolean | `@${string}`,
	prevPage?:boolean | `@${string}`,
	totalDocs?:boolean | `@${string}`,
	totalPages?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["Media_where"]: {
	alt?: ValueTypes["Media_alt_operator"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["Media_updatedAt_operator"] | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["Media_createdAt_operator"] | undefined | null | Variable<any, string>,
	url?: ValueTypes["Media_url_operator"] | undefined | null | Variable<any, string>,
	filename?: ValueTypes["Media_filename_operator"] | undefined | null | Variable<any, string>,
	mimeType?: ValueTypes["Media_mimeType_operator"] | undefined | null | Variable<any, string>,
	filesize?: ValueTypes["Media_filesize_operator"] | undefined | null | Variable<any, string>,
	width?: ValueTypes["Media_width_operator"] | undefined | null | Variable<any, string>,
	height?: ValueTypes["Media_height_operator"] | undefined | null | Variable<any, string>,
	focalX?: ValueTypes["Media_focalX_operator"] | undefined | null | Variable<any, string>,
	focalY?: ValueTypes["Media_focalY_operator"] | undefined | null | Variable<any, string>,
	sizes__small__url?: ValueTypes["Media_sizes__small__url_operator"] | undefined | null | Variable<any, string>,
	sizes__small__width?: ValueTypes["Media_sizes__small__width_operator"] | undefined | null | Variable<any, string>,
	sizes__small__height?: ValueTypes["Media_sizes__small__height_operator"] | undefined | null | Variable<any, string>,
	sizes__small__mimeType?: ValueTypes["Media_sizes__small__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__small__filesize?: ValueTypes["Media_sizes__small__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__small__filename?: ValueTypes["Media_sizes__small__filename_operator"] | undefined | null | Variable<any, string>,
	sizes__medium__url?: ValueTypes["Media_sizes__medium__url_operator"] | undefined | null | Variable<any, string>,
	sizes__medium__width?: ValueTypes["Media_sizes__medium__width_operator"] | undefined | null | Variable<any, string>,
	sizes__medium__height?: ValueTypes["Media_sizes__medium__height_operator"] | undefined | null | Variable<any, string>,
	sizes__medium__mimeType?: ValueTypes["Media_sizes__medium__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__medium__filesize?: ValueTypes["Media_sizes__medium__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__medium__filename?: ValueTypes["Media_sizes__medium__filename_operator"] | undefined | null | Variable<any, string>,
	sizes__large__url?: ValueTypes["Media_sizes__large__url_operator"] | undefined | null | Variable<any, string>,
	sizes__large__width?: ValueTypes["Media_sizes__large__width_operator"] | undefined | null | Variable<any, string>,
	sizes__large__height?: ValueTypes["Media_sizes__large__height_operator"] | undefined | null | Variable<any, string>,
	sizes__large__mimeType?: ValueTypes["Media_sizes__large__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__large__filesize?: ValueTypes["Media_sizes__large__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__large__filename?: ValueTypes["Media_sizes__large__filename_operator"] | undefined | null | Variable<any, string>,
	sizes__small_jpeg__url?: ValueTypes["Media_sizes__small_jpeg__url_operator"] | undefined | null | Variable<any, string>,
	sizes__small_jpeg__width?: ValueTypes["Media_sizes__small_jpeg__width_operator"] | undefined | null | Variable<any, string>,
	sizes__small_jpeg__height?: ValueTypes["Media_sizes__small_jpeg__height_operator"] | undefined | null | Variable<any, string>,
	sizes__small_jpeg__mimeType?: ValueTypes["Media_sizes__small_jpeg__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__small_jpeg__filesize?: ValueTypes["Media_sizes__small_jpeg__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__small_jpeg__filename?: ValueTypes["Media_sizes__small_jpeg__filename_operator"] | undefined | null | Variable<any, string>,
	sizes__medium_jpeg__url?: ValueTypes["Media_sizes__medium_jpeg__url_operator"] | undefined | null | Variable<any, string>,
	sizes__medium_jpeg__width?: ValueTypes["Media_sizes__medium_jpeg__width_operator"] | undefined | null | Variable<any, string>,
	sizes__medium_jpeg__height?: ValueTypes["Media_sizes__medium_jpeg__height_operator"] | undefined | null | Variable<any, string>,
	sizes__medium_jpeg__mimeType?: ValueTypes["Media_sizes__medium_jpeg__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__medium_jpeg__filesize?: ValueTypes["Media_sizes__medium_jpeg__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__medium_jpeg__filename?: ValueTypes["Media_sizes__medium_jpeg__filename_operator"] | undefined | null | Variable<any, string>,
	sizes__large_jpeg__url?: ValueTypes["Media_sizes__large_jpeg__url_operator"] | undefined | null | Variable<any, string>,
	sizes__large_jpeg__width?: ValueTypes["Media_sizes__large_jpeg__width_operator"] | undefined | null | Variable<any, string>,
	sizes__large_jpeg__height?: ValueTypes["Media_sizes__large_jpeg__height_operator"] | undefined | null | Variable<any, string>,
	sizes__large_jpeg__mimeType?: ValueTypes["Media_sizes__large_jpeg__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__large_jpeg__filesize?: ValueTypes["Media_sizes__large_jpeg__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__large_jpeg__filename?: ValueTypes["Media_sizes__large_jpeg__filename_operator"] | undefined | null | Variable<any, string>,
	id?: ValueTypes["Media_id_operator"] | undefined | null | Variable<any, string>,
	AND?: Array<ValueTypes["Media_where_and"] | undefined | null> | undefined | null | Variable<any, string>,
	OR?: Array<ValueTypes["Media_where_or"] | undefined | null> | undefined | null | Variable<any, string>
};
	["Media_alt_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Media_updatedAt_operator"]: {
	equals?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	not_equals?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	greater_than_equal?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	greater_than?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	less_than_equal?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	less_than?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	like?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Media_createdAt_operator"]: {
	equals?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	not_equals?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	greater_than_equal?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	greater_than?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	less_than_equal?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	less_than?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	like?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Media_url_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Media_filename_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Media_mimeType_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Media_filesize_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Media_width_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Media_height_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Media_focalX_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Media_focalY_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Media_sizes__small__url_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Media_sizes__small__width_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Media_sizes__small__height_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Media_sizes__small__mimeType_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Media_sizes__small__filesize_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Media_sizes__small__filename_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Media_sizes__medium__url_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Media_sizes__medium__width_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Media_sizes__medium__height_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Media_sizes__medium__mimeType_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Media_sizes__medium__filesize_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Media_sizes__medium__filename_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Media_sizes__large__url_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Media_sizes__large__width_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Media_sizes__large__height_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Media_sizes__large__mimeType_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Media_sizes__large__filesize_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Media_sizes__large__filename_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Media_sizes__small_jpeg__url_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Media_sizes__small_jpeg__width_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Media_sizes__small_jpeg__height_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Media_sizes__small_jpeg__mimeType_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Media_sizes__small_jpeg__filesize_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Media_sizes__small_jpeg__filename_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Media_sizes__medium_jpeg__url_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Media_sizes__medium_jpeg__width_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Media_sizes__medium_jpeg__height_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Media_sizes__medium_jpeg__mimeType_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Media_sizes__medium_jpeg__filesize_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Media_sizes__medium_jpeg__filename_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Media_sizes__large_jpeg__url_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Media_sizes__large_jpeg__width_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Media_sizes__large_jpeg__height_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Media_sizes__large_jpeg__mimeType_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Media_sizes__large_jpeg__filesize_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Media_sizes__large_jpeg__filename_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Media_id_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Media_where_and"]: {
	alt?: ValueTypes["Media_alt_operator"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["Media_updatedAt_operator"] | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["Media_createdAt_operator"] | undefined | null | Variable<any, string>,
	url?: ValueTypes["Media_url_operator"] | undefined | null | Variable<any, string>,
	filename?: ValueTypes["Media_filename_operator"] | undefined | null | Variable<any, string>,
	mimeType?: ValueTypes["Media_mimeType_operator"] | undefined | null | Variable<any, string>,
	filesize?: ValueTypes["Media_filesize_operator"] | undefined | null | Variable<any, string>,
	width?: ValueTypes["Media_width_operator"] | undefined | null | Variable<any, string>,
	height?: ValueTypes["Media_height_operator"] | undefined | null | Variable<any, string>,
	focalX?: ValueTypes["Media_focalX_operator"] | undefined | null | Variable<any, string>,
	focalY?: ValueTypes["Media_focalY_operator"] | undefined | null | Variable<any, string>,
	sizes__small__url?: ValueTypes["Media_sizes__small__url_operator"] | undefined | null | Variable<any, string>,
	sizes__small__width?: ValueTypes["Media_sizes__small__width_operator"] | undefined | null | Variable<any, string>,
	sizes__small__height?: ValueTypes["Media_sizes__small__height_operator"] | undefined | null | Variable<any, string>,
	sizes__small__mimeType?: ValueTypes["Media_sizes__small__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__small__filesize?: ValueTypes["Media_sizes__small__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__small__filename?: ValueTypes["Media_sizes__small__filename_operator"] | undefined | null | Variable<any, string>,
	sizes__medium__url?: ValueTypes["Media_sizes__medium__url_operator"] | undefined | null | Variable<any, string>,
	sizes__medium__width?: ValueTypes["Media_sizes__medium__width_operator"] | undefined | null | Variable<any, string>,
	sizes__medium__height?: ValueTypes["Media_sizes__medium__height_operator"] | undefined | null | Variable<any, string>,
	sizes__medium__mimeType?: ValueTypes["Media_sizes__medium__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__medium__filesize?: ValueTypes["Media_sizes__medium__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__medium__filename?: ValueTypes["Media_sizes__medium__filename_operator"] | undefined | null | Variable<any, string>,
	sizes__large__url?: ValueTypes["Media_sizes__large__url_operator"] | undefined | null | Variable<any, string>,
	sizes__large__width?: ValueTypes["Media_sizes__large__width_operator"] | undefined | null | Variable<any, string>,
	sizes__large__height?: ValueTypes["Media_sizes__large__height_operator"] | undefined | null | Variable<any, string>,
	sizes__large__mimeType?: ValueTypes["Media_sizes__large__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__large__filesize?: ValueTypes["Media_sizes__large__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__large__filename?: ValueTypes["Media_sizes__large__filename_operator"] | undefined | null | Variable<any, string>,
	sizes__small_jpeg__url?: ValueTypes["Media_sizes__small_jpeg__url_operator"] | undefined | null | Variable<any, string>,
	sizes__small_jpeg__width?: ValueTypes["Media_sizes__small_jpeg__width_operator"] | undefined | null | Variable<any, string>,
	sizes__small_jpeg__height?: ValueTypes["Media_sizes__small_jpeg__height_operator"] | undefined | null | Variable<any, string>,
	sizes__small_jpeg__mimeType?: ValueTypes["Media_sizes__small_jpeg__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__small_jpeg__filesize?: ValueTypes["Media_sizes__small_jpeg__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__small_jpeg__filename?: ValueTypes["Media_sizes__small_jpeg__filename_operator"] | undefined | null | Variable<any, string>,
	sizes__medium_jpeg__url?: ValueTypes["Media_sizes__medium_jpeg__url_operator"] | undefined | null | Variable<any, string>,
	sizes__medium_jpeg__width?: ValueTypes["Media_sizes__medium_jpeg__width_operator"] | undefined | null | Variable<any, string>,
	sizes__medium_jpeg__height?: ValueTypes["Media_sizes__medium_jpeg__height_operator"] | undefined | null | Variable<any, string>,
	sizes__medium_jpeg__mimeType?: ValueTypes["Media_sizes__medium_jpeg__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__medium_jpeg__filesize?: ValueTypes["Media_sizes__medium_jpeg__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__medium_jpeg__filename?: ValueTypes["Media_sizes__medium_jpeg__filename_operator"] | undefined | null | Variable<any, string>,
	sizes__large_jpeg__url?: ValueTypes["Media_sizes__large_jpeg__url_operator"] | undefined | null | Variable<any, string>,
	sizes__large_jpeg__width?: ValueTypes["Media_sizes__large_jpeg__width_operator"] | undefined | null | Variable<any, string>,
	sizes__large_jpeg__height?: ValueTypes["Media_sizes__large_jpeg__height_operator"] | undefined | null | Variable<any, string>,
	sizes__large_jpeg__mimeType?: ValueTypes["Media_sizes__large_jpeg__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__large_jpeg__filesize?: ValueTypes["Media_sizes__large_jpeg__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__large_jpeg__filename?: ValueTypes["Media_sizes__large_jpeg__filename_operator"] | undefined | null | Variable<any, string>,
	id?: ValueTypes["Media_id_operator"] | undefined | null | Variable<any, string>,
	AND?: Array<ValueTypes["Media_where_and"] | undefined | null> | undefined | null | Variable<any, string>,
	OR?: Array<ValueTypes["Media_where_or"] | undefined | null> | undefined | null | Variable<any, string>
};
	["Media_where_or"]: {
	alt?: ValueTypes["Media_alt_operator"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["Media_updatedAt_operator"] | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["Media_createdAt_operator"] | undefined | null | Variable<any, string>,
	url?: ValueTypes["Media_url_operator"] | undefined | null | Variable<any, string>,
	filename?: ValueTypes["Media_filename_operator"] | undefined | null | Variable<any, string>,
	mimeType?: ValueTypes["Media_mimeType_operator"] | undefined | null | Variable<any, string>,
	filesize?: ValueTypes["Media_filesize_operator"] | undefined | null | Variable<any, string>,
	width?: ValueTypes["Media_width_operator"] | undefined | null | Variable<any, string>,
	height?: ValueTypes["Media_height_operator"] | undefined | null | Variable<any, string>,
	focalX?: ValueTypes["Media_focalX_operator"] | undefined | null | Variable<any, string>,
	focalY?: ValueTypes["Media_focalY_operator"] | undefined | null | Variable<any, string>,
	sizes__small__url?: ValueTypes["Media_sizes__small__url_operator"] | undefined | null | Variable<any, string>,
	sizes__small__width?: ValueTypes["Media_sizes__small__width_operator"] | undefined | null | Variable<any, string>,
	sizes__small__height?: ValueTypes["Media_sizes__small__height_operator"] | undefined | null | Variable<any, string>,
	sizes__small__mimeType?: ValueTypes["Media_sizes__small__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__small__filesize?: ValueTypes["Media_sizes__small__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__small__filename?: ValueTypes["Media_sizes__small__filename_operator"] | undefined | null | Variable<any, string>,
	sizes__medium__url?: ValueTypes["Media_sizes__medium__url_operator"] | undefined | null | Variable<any, string>,
	sizes__medium__width?: ValueTypes["Media_sizes__medium__width_operator"] | undefined | null | Variable<any, string>,
	sizes__medium__height?: ValueTypes["Media_sizes__medium__height_operator"] | undefined | null | Variable<any, string>,
	sizes__medium__mimeType?: ValueTypes["Media_sizes__medium__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__medium__filesize?: ValueTypes["Media_sizes__medium__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__medium__filename?: ValueTypes["Media_sizes__medium__filename_operator"] | undefined | null | Variable<any, string>,
	sizes__large__url?: ValueTypes["Media_sizes__large__url_operator"] | undefined | null | Variable<any, string>,
	sizes__large__width?: ValueTypes["Media_sizes__large__width_operator"] | undefined | null | Variable<any, string>,
	sizes__large__height?: ValueTypes["Media_sizes__large__height_operator"] | undefined | null | Variable<any, string>,
	sizes__large__mimeType?: ValueTypes["Media_sizes__large__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__large__filesize?: ValueTypes["Media_sizes__large__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__large__filename?: ValueTypes["Media_sizes__large__filename_operator"] | undefined | null | Variable<any, string>,
	sizes__small_jpeg__url?: ValueTypes["Media_sizes__small_jpeg__url_operator"] | undefined | null | Variable<any, string>,
	sizes__small_jpeg__width?: ValueTypes["Media_sizes__small_jpeg__width_operator"] | undefined | null | Variable<any, string>,
	sizes__small_jpeg__height?: ValueTypes["Media_sizes__small_jpeg__height_operator"] | undefined | null | Variable<any, string>,
	sizes__small_jpeg__mimeType?: ValueTypes["Media_sizes__small_jpeg__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__small_jpeg__filesize?: ValueTypes["Media_sizes__small_jpeg__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__small_jpeg__filename?: ValueTypes["Media_sizes__small_jpeg__filename_operator"] | undefined | null | Variable<any, string>,
	sizes__medium_jpeg__url?: ValueTypes["Media_sizes__medium_jpeg__url_operator"] | undefined | null | Variable<any, string>,
	sizes__medium_jpeg__width?: ValueTypes["Media_sizes__medium_jpeg__width_operator"] | undefined | null | Variable<any, string>,
	sizes__medium_jpeg__height?: ValueTypes["Media_sizes__medium_jpeg__height_operator"] | undefined | null | Variable<any, string>,
	sizes__medium_jpeg__mimeType?: ValueTypes["Media_sizes__medium_jpeg__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__medium_jpeg__filesize?: ValueTypes["Media_sizes__medium_jpeg__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__medium_jpeg__filename?: ValueTypes["Media_sizes__medium_jpeg__filename_operator"] | undefined | null | Variable<any, string>,
	sizes__large_jpeg__url?: ValueTypes["Media_sizes__large_jpeg__url_operator"] | undefined | null | Variable<any, string>,
	sizes__large_jpeg__width?: ValueTypes["Media_sizes__large_jpeg__width_operator"] | undefined | null | Variable<any, string>,
	sizes__large_jpeg__height?: ValueTypes["Media_sizes__large_jpeg__height_operator"] | undefined | null | Variable<any, string>,
	sizes__large_jpeg__mimeType?: ValueTypes["Media_sizes__large_jpeg__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__large_jpeg__filesize?: ValueTypes["Media_sizes__large_jpeg__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__large_jpeg__filename?: ValueTypes["Media_sizes__large_jpeg__filename_operator"] | undefined | null | Variable<any, string>,
	id?: ValueTypes["Media_id_operator"] | undefined | null | Variable<any, string>,
	AND?: Array<ValueTypes["Media_where_and"] | undefined | null> | undefined | null | Variable<any, string>,
	OR?: Array<ValueTypes["Media_where_or"] | undefined | null> | undefined | null | Variable<any, string>
};
	["countallMedia"]: AliasType<{
	totalDocs?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["mediaDocAccess"]: AliasType<{
	fields?:ValueTypes["MediaDocAccessFields"],
	create?:ValueTypes["MediaCreateDocAccess"],
	read?:ValueTypes["MediaReadDocAccess"],
	update?:ValueTypes["MediaUpdateDocAccess"],
	delete?:ValueTypes["MediaDeleteDocAccess"],
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields"]: AliasType<{
	alt?:ValueTypes["MediaDocAccessFields_alt"],
	updatedAt?:ValueTypes["MediaDocAccessFields_updatedAt"],
	createdAt?:ValueTypes["MediaDocAccessFields_createdAt"],
	url?:ValueTypes["MediaDocAccessFields_url"],
	filename?:ValueTypes["MediaDocAccessFields_filename"],
	mimeType?:ValueTypes["MediaDocAccessFields_mimeType"],
	filesize?:ValueTypes["MediaDocAccessFields_filesize"],
	width?:ValueTypes["MediaDocAccessFields_width"],
	height?:ValueTypes["MediaDocAccessFields_height"],
	focalX?:ValueTypes["MediaDocAccessFields_focalX"],
	focalY?:ValueTypes["MediaDocAccessFields_focalY"],
	sizes?:ValueTypes["MediaDocAccessFields_sizes"],
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_alt"]: AliasType<{
	create?:ValueTypes["MediaDocAccessFields_alt_Create"],
	read?:ValueTypes["MediaDocAccessFields_alt_Read"],
	update?:ValueTypes["MediaDocAccessFields_alt_Update"],
	delete?:ValueTypes["MediaDocAccessFields_alt_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_alt_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_alt_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_alt_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_alt_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_updatedAt"]: AliasType<{
	create?:ValueTypes["MediaDocAccessFields_updatedAt_Create"],
	read?:ValueTypes["MediaDocAccessFields_updatedAt_Read"],
	update?:ValueTypes["MediaDocAccessFields_updatedAt_Update"],
	delete?:ValueTypes["MediaDocAccessFields_updatedAt_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_updatedAt_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_updatedAt_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_updatedAt_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_updatedAt_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_createdAt"]: AliasType<{
	create?:ValueTypes["MediaDocAccessFields_createdAt_Create"],
	read?:ValueTypes["MediaDocAccessFields_createdAt_Read"],
	update?:ValueTypes["MediaDocAccessFields_createdAt_Update"],
	delete?:ValueTypes["MediaDocAccessFields_createdAt_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_createdAt_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_createdAt_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_createdAt_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_createdAt_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_url"]: AliasType<{
	create?:ValueTypes["MediaDocAccessFields_url_Create"],
	read?:ValueTypes["MediaDocAccessFields_url_Read"],
	update?:ValueTypes["MediaDocAccessFields_url_Update"],
	delete?:ValueTypes["MediaDocAccessFields_url_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_url_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_url_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_url_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_url_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_filename"]: AliasType<{
	create?:ValueTypes["MediaDocAccessFields_filename_Create"],
	read?:ValueTypes["MediaDocAccessFields_filename_Read"],
	update?:ValueTypes["MediaDocAccessFields_filename_Update"],
	delete?:ValueTypes["MediaDocAccessFields_filename_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_filename_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_filename_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_filename_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_filename_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_mimeType"]: AliasType<{
	create?:ValueTypes["MediaDocAccessFields_mimeType_Create"],
	read?:ValueTypes["MediaDocAccessFields_mimeType_Read"],
	update?:ValueTypes["MediaDocAccessFields_mimeType_Update"],
	delete?:ValueTypes["MediaDocAccessFields_mimeType_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_mimeType_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_mimeType_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_mimeType_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_mimeType_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_filesize"]: AliasType<{
	create?:ValueTypes["MediaDocAccessFields_filesize_Create"],
	read?:ValueTypes["MediaDocAccessFields_filesize_Read"],
	update?:ValueTypes["MediaDocAccessFields_filesize_Update"],
	delete?:ValueTypes["MediaDocAccessFields_filesize_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_filesize_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_filesize_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_filesize_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_filesize_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_width"]: AliasType<{
	create?:ValueTypes["MediaDocAccessFields_width_Create"],
	read?:ValueTypes["MediaDocAccessFields_width_Read"],
	update?:ValueTypes["MediaDocAccessFields_width_Update"],
	delete?:ValueTypes["MediaDocAccessFields_width_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_width_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_width_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_width_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_width_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_height"]: AliasType<{
	create?:ValueTypes["MediaDocAccessFields_height_Create"],
	read?:ValueTypes["MediaDocAccessFields_height_Read"],
	update?:ValueTypes["MediaDocAccessFields_height_Update"],
	delete?:ValueTypes["MediaDocAccessFields_height_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_height_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_height_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_height_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_height_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_focalX"]: AliasType<{
	create?:ValueTypes["MediaDocAccessFields_focalX_Create"],
	read?:ValueTypes["MediaDocAccessFields_focalX_Read"],
	update?:ValueTypes["MediaDocAccessFields_focalX_Update"],
	delete?:ValueTypes["MediaDocAccessFields_focalX_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_focalX_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_focalX_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_focalX_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_focalX_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_focalY"]: AliasType<{
	create?:ValueTypes["MediaDocAccessFields_focalY_Create"],
	read?:ValueTypes["MediaDocAccessFields_focalY_Read"],
	update?:ValueTypes["MediaDocAccessFields_focalY_Update"],
	delete?:ValueTypes["MediaDocAccessFields_focalY_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_focalY_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_focalY_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_focalY_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_focalY_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes"]: AliasType<{
	create?:ValueTypes["MediaDocAccessFields_sizes_Create"],
	read?:ValueTypes["MediaDocAccessFields_sizes_Read"],
	update?:ValueTypes["MediaDocAccessFields_sizes_Update"],
	delete?:ValueTypes["MediaDocAccessFields_sizes_Delete"],
	fields?:ValueTypes["MediaDocAccessFields_sizes_Fields"],
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_Fields"]: AliasType<{
	small?:ValueTypes["MediaDocAccessFields_sizes_small"],
	medium?:ValueTypes["MediaDocAccessFields_sizes_medium"],
	large?:ValueTypes["MediaDocAccessFields_sizes_large"],
	small_jpeg?:ValueTypes["MediaDocAccessFields_sizes_small_jpeg"],
	medium_jpeg?:ValueTypes["MediaDocAccessFields_sizes_medium_jpeg"],
	large_jpeg?:ValueTypes["MediaDocAccessFields_sizes_large_jpeg"],
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_small"]: AliasType<{
	create?:ValueTypes["MediaDocAccessFields_sizes_small_Create"],
	read?:ValueTypes["MediaDocAccessFields_sizes_small_Read"],
	update?:ValueTypes["MediaDocAccessFields_sizes_small_Update"],
	delete?:ValueTypes["MediaDocAccessFields_sizes_small_Delete"],
	fields?:ValueTypes["MediaDocAccessFields_sizes_small_Fields"],
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_small_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_small_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_small_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_small_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_small_Fields"]: AliasType<{
	url?:ValueTypes["MediaDocAccessFields_sizes_small_url"],
	width?:ValueTypes["MediaDocAccessFields_sizes_small_width"],
	height?:ValueTypes["MediaDocAccessFields_sizes_small_height"],
	mimeType?:ValueTypes["MediaDocAccessFields_sizes_small_mimeType"],
	filesize?:ValueTypes["MediaDocAccessFields_sizes_small_filesize"],
	filename?:ValueTypes["MediaDocAccessFields_sizes_small_filename"],
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_small_url"]: AliasType<{
	create?:ValueTypes["MediaDocAccessFields_sizes_small_url_Create"],
	read?:ValueTypes["MediaDocAccessFields_sizes_small_url_Read"],
	update?:ValueTypes["MediaDocAccessFields_sizes_small_url_Update"],
	delete?:ValueTypes["MediaDocAccessFields_sizes_small_url_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_small_url_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_small_url_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_small_url_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_small_url_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_small_width"]: AliasType<{
	create?:ValueTypes["MediaDocAccessFields_sizes_small_width_Create"],
	read?:ValueTypes["MediaDocAccessFields_sizes_small_width_Read"],
	update?:ValueTypes["MediaDocAccessFields_sizes_small_width_Update"],
	delete?:ValueTypes["MediaDocAccessFields_sizes_small_width_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_small_width_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_small_width_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_small_width_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_small_width_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_small_height"]: AliasType<{
	create?:ValueTypes["MediaDocAccessFields_sizes_small_height_Create"],
	read?:ValueTypes["MediaDocAccessFields_sizes_small_height_Read"],
	update?:ValueTypes["MediaDocAccessFields_sizes_small_height_Update"],
	delete?:ValueTypes["MediaDocAccessFields_sizes_small_height_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_small_height_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_small_height_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_small_height_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_small_height_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_small_mimeType"]: AliasType<{
	create?:ValueTypes["MediaDocAccessFields_sizes_small_mimeType_Create"],
	read?:ValueTypes["MediaDocAccessFields_sizes_small_mimeType_Read"],
	update?:ValueTypes["MediaDocAccessFields_sizes_small_mimeType_Update"],
	delete?:ValueTypes["MediaDocAccessFields_sizes_small_mimeType_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_small_mimeType_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_small_mimeType_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_small_mimeType_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_small_mimeType_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_small_filesize"]: AliasType<{
	create?:ValueTypes["MediaDocAccessFields_sizes_small_filesize_Create"],
	read?:ValueTypes["MediaDocAccessFields_sizes_small_filesize_Read"],
	update?:ValueTypes["MediaDocAccessFields_sizes_small_filesize_Update"],
	delete?:ValueTypes["MediaDocAccessFields_sizes_small_filesize_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_small_filesize_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_small_filesize_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_small_filesize_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_small_filesize_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_small_filename"]: AliasType<{
	create?:ValueTypes["MediaDocAccessFields_sizes_small_filename_Create"],
	read?:ValueTypes["MediaDocAccessFields_sizes_small_filename_Read"],
	update?:ValueTypes["MediaDocAccessFields_sizes_small_filename_Update"],
	delete?:ValueTypes["MediaDocAccessFields_sizes_small_filename_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_small_filename_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_small_filename_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_small_filename_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_small_filename_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_medium"]: AliasType<{
	create?:ValueTypes["MediaDocAccessFields_sizes_medium_Create"],
	read?:ValueTypes["MediaDocAccessFields_sizes_medium_Read"],
	update?:ValueTypes["MediaDocAccessFields_sizes_medium_Update"],
	delete?:ValueTypes["MediaDocAccessFields_sizes_medium_Delete"],
	fields?:ValueTypes["MediaDocAccessFields_sizes_medium_Fields"],
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_medium_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_medium_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_medium_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_medium_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_medium_Fields"]: AliasType<{
	url?:ValueTypes["MediaDocAccessFields_sizes_medium_url"],
	width?:ValueTypes["MediaDocAccessFields_sizes_medium_width"],
	height?:ValueTypes["MediaDocAccessFields_sizes_medium_height"],
	mimeType?:ValueTypes["MediaDocAccessFields_sizes_medium_mimeType"],
	filesize?:ValueTypes["MediaDocAccessFields_sizes_medium_filesize"],
	filename?:ValueTypes["MediaDocAccessFields_sizes_medium_filename"],
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_medium_url"]: AliasType<{
	create?:ValueTypes["MediaDocAccessFields_sizes_medium_url_Create"],
	read?:ValueTypes["MediaDocAccessFields_sizes_medium_url_Read"],
	update?:ValueTypes["MediaDocAccessFields_sizes_medium_url_Update"],
	delete?:ValueTypes["MediaDocAccessFields_sizes_medium_url_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_medium_url_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_medium_url_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_medium_url_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_medium_url_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_medium_width"]: AliasType<{
	create?:ValueTypes["MediaDocAccessFields_sizes_medium_width_Create"],
	read?:ValueTypes["MediaDocAccessFields_sizes_medium_width_Read"],
	update?:ValueTypes["MediaDocAccessFields_sizes_medium_width_Update"],
	delete?:ValueTypes["MediaDocAccessFields_sizes_medium_width_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_medium_width_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_medium_width_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_medium_width_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_medium_width_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_medium_height"]: AliasType<{
	create?:ValueTypes["MediaDocAccessFields_sizes_medium_height_Create"],
	read?:ValueTypes["MediaDocAccessFields_sizes_medium_height_Read"],
	update?:ValueTypes["MediaDocAccessFields_sizes_medium_height_Update"],
	delete?:ValueTypes["MediaDocAccessFields_sizes_medium_height_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_medium_height_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_medium_height_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_medium_height_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_medium_height_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_medium_mimeType"]: AliasType<{
	create?:ValueTypes["MediaDocAccessFields_sizes_medium_mimeType_Create"],
	read?:ValueTypes["MediaDocAccessFields_sizes_medium_mimeType_Read"],
	update?:ValueTypes["MediaDocAccessFields_sizes_medium_mimeType_Update"],
	delete?:ValueTypes["MediaDocAccessFields_sizes_medium_mimeType_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_medium_mimeType_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_medium_mimeType_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_medium_mimeType_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_medium_mimeType_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_medium_filesize"]: AliasType<{
	create?:ValueTypes["MediaDocAccessFields_sizes_medium_filesize_Create"],
	read?:ValueTypes["MediaDocAccessFields_sizes_medium_filesize_Read"],
	update?:ValueTypes["MediaDocAccessFields_sizes_medium_filesize_Update"],
	delete?:ValueTypes["MediaDocAccessFields_sizes_medium_filesize_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_medium_filesize_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_medium_filesize_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_medium_filesize_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_medium_filesize_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_medium_filename"]: AliasType<{
	create?:ValueTypes["MediaDocAccessFields_sizes_medium_filename_Create"],
	read?:ValueTypes["MediaDocAccessFields_sizes_medium_filename_Read"],
	update?:ValueTypes["MediaDocAccessFields_sizes_medium_filename_Update"],
	delete?:ValueTypes["MediaDocAccessFields_sizes_medium_filename_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_medium_filename_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_medium_filename_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_medium_filename_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_medium_filename_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_large"]: AliasType<{
	create?:ValueTypes["MediaDocAccessFields_sizes_large_Create"],
	read?:ValueTypes["MediaDocAccessFields_sizes_large_Read"],
	update?:ValueTypes["MediaDocAccessFields_sizes_large_Update"],
	delete?:ValueTypes["MediaDocAccessFields_sizes_large_Delete"],
	fields?:ValueTypes["MediaDocAccessFields_sizes_large_Fields"],
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_large_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_large_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_large_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_large_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_large_Fields"]: AliasType<{
	url?:ValueTypes["MediaDocAccessFields_sizes_large_url"],
	width?:ValueTypes["MediaDocAccessFields_sizes_large_width"],
	height?:ValueTypes["MediaDocAccessFields_sizes_large_height"],
	mimeType?:ValueTypes["MediaDocAccessFields_sizes_large_mimeType"],
	filesize?:ValueTypes["MediaDocAccessFields_sizes_large_filesize"],
	filename?:ValueTypes["MediaDocAccessFields_sizes_large_filename"],
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_large_url"]: AliasType<{
	create?:ValueTypes["MediaDocAccessFields_sizes_large_url_Create"],
	read?:ValueTypes["MediaDocAccessFields_sizes_large_url_Read"],
	update?:ValueTypes["MediaDocAccessFields_sizes_large_url_Update"],
	delete?:ValueTypes["MediaDocAccessFields_sizes_large_url_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_large_url_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_large_url_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_large_url_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_large_url_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_large_width"]: AliasType<{
	create?:ValueTypes["MediaDocAccessFields_sizes_large_width_Create"],
	read?:ValueTypes["MediaDocAccessFields_sizes_large_width_Read"],
	update?:ValueTypes["MediaDocAccessFields_sizes_large_width_Update"],
	delete?:ValueTypes["MediaDocAccessFields_sizes_large_width_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_large_width_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_large_width_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_large_width_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_large_width_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_large_height"]: AliasType<{
	create?:ValueTypes["MediaDocAccessFields_sizes_large_height_Create"],
	read?:ValueTypes["MediaDocAccessFields_sizes_large_height_Read"],
	update?:ValueTypes["MediaDocAccessFields_sizes_large_height_Update"],
	delete?:ValueTypes["MediaDocAccessFields_sizes_large_height_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_large_height_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_large_height_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_large_height_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_large_height_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_large_mimeType"]: AliasType<{
	create?:ValueTypes["MediaDocAccessFields_sizes_large_mimeType_Create"],
	read?:ValueTypes["MediaDocAccessFields_sizes_large_mimeType_Read"],
	update?:ValueTypes["MediaDocAccessFields_sizes_large_mimeType_Update"],
	delete?:ValueTypes["MediaDocAccessFields_sizes_large_mimeType_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_large_mimeType_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_large_mimeType_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_large_mimeType_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_large_mimeType_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_large_filesize"]: AliasType<{
	create?:ValueTypes["MediaDocAccessFields_sizes_large_filesize_Create"],
	read?:ValueTypes["MediaDocAccessFields_sizes_large_filesize_Read"],
	update?:ValueTypes["MediaDocAccessFields_sizes_large_filesize_Update"],
	delete?:ValueTypes["MediaDocAccessFields_sizes_large_filesize_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_large_filesize_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_large_filesize_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_large_filesize_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_large_filesize_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_large_filename"]: AliasType<{
	create?:ValueTypes["MediaDocAccessFields_sizes_large_filename_Create"],
	read?:ValueTypes["MediaDocAccessFields_sizes_large_filename_Read"],
	update?:ValueTypes["MediaDocAccessFields_sizes_large_filename_Update"],
	delete?:ValueTypes["MediaDocAccessFields_sizes_large_filename_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_large_filename_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_large_filename_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_large_filename_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_large_filename_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_small_jpeg"]: AliasType<{
	create?:ValueTypes["MediaDocAccessFields_sizes_small_jpeg_Create"],
	read?:ValueTypes["MediaDocAccessFields_sizes_small_jpeg_Read"],
	update?:ValueTypes["MediaDocAccessFields_sizes_small_jpeg_Update"],
	delete?:ValueTypes["MediaDocAccessFields_sizes_small_jpeg_Delete"],
	fields?:ValueTypes["MediaDocAccessFields_sizes_small_jpeg_Fields"],
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_small_jpeg_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_small_jpeg_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_small_jpeg_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_small_jpeg_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_small_jpeg_Fields"]: AliasType<{
	url?:ValueTypes["MediaDocAccessFields_sizes_small_jpeg_url"],
	width?:ValueTypes["MediaDocAccessFields_sizes_small_jpeg_width"],
	height?:ValueTypes["MediaDocAccessFields_sizes_small_jpeg_height"],
	mimeType?:ValueTypes["MediaDocAccessFields_sizes_small_jpeg_mimeType"],
	filesize?:ValueTypes["MediaDocAccessFields_sizes_small_jpeg_filesize"],
	filename?:ValueTypes["MediaDocAccessFields_sizes_small_jpeg_filename"],
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_small_jpeg_url"]: AliasType<{
	create?:ValueTypes["MediaDocAccessFields_sizes_small_jpeg_url_Create"],
	read?:ValueTypes["MediaDocAccessFields_sizes_small_jpeg_url_Read"],
	update?:ValueTypes["MediaDocAccessFields_sizes_small_jpeg_url_Update"],
	delete?:ValueTypes["MediaDocAccessFields_sizes_small_jpeg_url_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_small_jpeg_url_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_small_jpeg_url_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_small_jpeg_url_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_small_jpeg_url_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_small_jpeg_width"]: AliasType<{
	create?:ValueTypes["MediaDocAccessFields_sizes_small_jpeg_width_Create"],
	read?:ValueTypes["MediaDocAccessFields_sizes_small_jpeg_width_Read"],
	update?:ValueTypes["MediaDocAccessFields_sizes_small_jpeg_width_Update"],
	delete?:ValueTypes["MediaDocAccessFields_sizes_small_jpeg_width_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_small_jpeg_width_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_small_jpeg_width_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_small_jpeg_width_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_small_jpeg_width_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_small_jpeg_height"]: AliasType<{
	create?:ValueTypes["MediaDocAccessFields_sizes_small_jpeg_height_Create"],
	read?:ValueTypes["MediaDocAccessFields_sizes_small_jpeg_height_Read"],
	update?:ValueTypes["MediaDocAccessFields_sizes_small_jpeg_height_Update"],
	delete?:ValueTypes["MediaDocAccessFields_sizes_small_jpeg_height_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_small_jpeg_height_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_small_jpeg_height_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_small_jpeg_height_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_small_jpeg_height_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_small_jpeg_mimeType"]: AliasType<{
	create?:ValueTypes["MediaDocAccessFields_sizes_small_jpeg_mimeType_Create"],
	read?:ValueTypes["MediaDocAccessFields_sizes_small_jpeg_mimeType_Read"],
	update?:ValueTypes["MediaDocAccessFields_sizes_small_jpeg_mimeType_Update"],
	delete?:ValueTypes["MediaDocAccessFields_sizes_small_jpeg_mimeType_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_small_jpeg_mimeType_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_small_jpeg_mimeType_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_small_jpeg_mimeType_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_small_jpeg_mimeType_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_small_jpeg_filesize"]: AliasType<{
	create?:ValueTypes["MediaDocAccessFields_sizes_small_jpeg_filesize_Create"],
	read?:ValueTypes["MediaDocAccessFields_sizes_small_jpeg_filesize_Read"],
	update?:ValueTypes["MediaDocAccessFields_sizes_small_jpeg_filesize_Update"],
	delete?:ValueTypes["MediaDocAccessFields_sizes_small_jpeg_filesize_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_small_jpeg_filesize_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_small_jpeg_filesize_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_small_jpeg_filesize_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_small_jpeg_filesize_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_small_jpeg_filename"]: AliasType<{
	create?:ValueTypes["MediaDocAccessFields_sizes_small_jpeg_filename_Create"],
	read?:ValueTypes["MediaDocAccessFields_sizes_small_jpeg_filename_Read"],
	update?:ValueTypes["MediaDocAccessFields_sizes_small_jpeg_filename_Update"],
	delete?:ValueTypes["MediaDocAccessFields_sizes_small_jpeg_filename_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_small_jpeg_filename_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_small_jpeg_filename_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_small_jpeg_filename_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_small_jpeg_filename_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_medium_jpeg"]: AliasType<{
	create?:ValueTypes["MediaDocAccessFields_sizes_medium_jpeg_Create"],
	read?:ValueTypes["MediaDocAccessFields_sizes_medium_jpeg_Read"],
	update?:ValueTypes["MediaDocAccessFields_sizes_medium_jpeg_Update"],
	delete?:ValueTypes["MediaDocAccessFields_sizes_medium_jpeg_Delete"],
	fields?:ValueTypes["MediaDocAccessFields_sizes_medium_jpeg_Fields"],
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_medium_jpeg_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_medium_jpeg_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_medium_jpeg_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_medium_jpeg_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_medium_jpeg_Fields"]: AliasType<{
	url?:ValueTypes["MediaDocAccessFields_sizes_medium_jpeg_url"],
	width?:ValueTypes["MediaDocAccessFields_sizes_medium_jpeg_width"],
	height?:ValueTypes["MediaDocAccessFields_sizes_medium_jpeg_height"],
	mimeType?:ValueTypes["MediaDocAccessFields_sizes_medium_jpeg_mimeType"],
	filesize?:ValueTypes["MediaDocAccessFields_sizes_medium_jpeg_filesize"],
	filename?:ValueTypes["MediaDocAccessFields_sizes_medium_jpeg_filename"],
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_medium_jpeg_url"]: AliasType<{
	create?:ValueTypes["MediaDocAccessFields_sizes_medium_jpeg_url_Create"],
	read?:ValueTypes["MediaDocAccessFields_sizes_medium_jpeg_url_Read"],
	update?:ValueTypes["MediaDocAccessFields_sizes_medium_jpeg_url_Update"],
	delete?:ValueTypes["MediaDocAccessFields_sizes_medium_jpeg_url_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_medium_jpeg_url_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_medium_jpeg_url_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_medium_jpeg_url_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_medium_jpeg_url_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_medium_jpeg_width"]: AliasType<{
	create?:ValueTypes["MediaDocAccessFields_sizes_medium_jpeg_width_Create"],
	read?:ValueTypes["MediaDocAccessFields_sizes_medium_jpeg_width_Read"],
	update?:ValueTypes["MediaDocAccessFields_sizes_medium_jpeg_width_Update"],
	delete?:ValueTypes["MediaDocAccessFields_sizes_medium_jpeg_width_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_medium_jpeg_width_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_medium_jpeg_width_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_medium_jpeg_width_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_medium_jpeg_width_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_medium_jpeg_height"]: AliasType<{
	create?:ValueTypes["MediaDocAccessFields_sizes_medium_jpeg_height_Create"],
	read?:ValueTypes["MediaDocAccessFields_sizes_medium_jpeg_height_Read"],
	update?:ValueTypes["MediaDocAccessFields_sizes_medium_jpeg_height_Update"],
	delete?:ValueTypes["MediaDocAccessFields_sizes_medium_jpeg_height_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_medium_jpeg_height_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_medium_jpeg_height_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_medium_jpeg_height_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_medium_jpeg_height_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_medium_jpeg_mimeType"]: AliasType<{
	create?:ValueTypes["MediaDocAccessFields_sizes_medium_jpeg_mimeType_Create"],
	read?:ValueTypes["MediaDocAccessFields_sizes_medium_jpeg_mimeType_Read"],
	update?:ValueTypes["MediaDocAccessFields_sizes_medium_jpeg_mimeType_Update"],
	delete?:ValueTypes["MediaDocAccessFields_sizes_medium_jpeg_mimeType_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_medium_jpeg_mimeType_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_medium_jpeg_mimeType_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_medium_jpeg_mimeType_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_medium_jpeg_mimeType_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_medium_jpeg_filesize"]: AliasType<{
	create?:ValueTypes["MediaDocAccessFields_sizes_medium_jpeg_filesize_Create"],
	read?:ValueTypes["MediaDocAccessFields_sizes_medium_jpeg_filesize_Read"],
	update?:ValueTypes["MediaDocAccessFields_sizes_medium_jpeg_filesize_Update"],
	delete?:ValueTypes["MediaDocAccessFields_sizes_medium_jpeg_filesize_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_medium_jpeg_filesize_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_medium_jpeg_filesize_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_medium_jpeg_filesize_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_medium_jpeg_filesize_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_medium_jpeg_filename"]: AliasType<{
	create?:ValueTypes["MediaDocAccessFields_sizes_medium_jpeg_filename_Create"],
	read?:ValueTypes["MediaDocAccessFields_sizes_medium_jpeg_filename_Read"],
	update?:ValueTypes["MediaDocAccessFields_sizes_medium_jpeg_filename_Update"],
	delete?:ValueTypes["MediaDocAccessFields_sizes_medium_jpeg_filename_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_medium_jpeg_filename_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_medium_jpeg_filename_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_medium_jpeg_filename_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_medium_jpeg_filename_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_large_jpeg"]: AliasType<{
	create?:ValueTypes["MediaDocAccessFields_sizes_large_jpeg_Create"],
	read?:ValueTypes["MediaDocAccessFields_sizes_large_jpeg_Read"],
	update?:ValueTypes["MediaDocAccessFields_sizes_large_jpeg_Update"],
	delete?:ValueTypes["MediaDocAccessFields_sizes_large_jpeg_Delete"],
	fields?:ValueTypes["MediaDocAccessFields_sizes_large_jpeg_Fields"],
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_large_jpeg_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_large_jpeg_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_large_jpeg_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_large_jpeg_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_large_jpeg_Fields"]: AliasType<{
	url?:ValueTypes["MediaDocAccessFields_sizes_large_jpeg_url"],
	width?:ValueTypes["MediaDocAccessFields_sizes_large_jpeg_width"],
	height?:ValueTypes["MediaDocAccessFields_sizes_large_jpeg_height"],
	mimeType?:ValueTypes["MediaDocAccessFields_sizes_large_jpeg_mimeType"],
	filesize?:ValueTypes["MediaDocAccessFields_sizes_large_jpeg_filesize"],
	filename?:ValueTypes["MediaDocAccessFields_sizes_large_jpeg_filename"],
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_large_jpeg_url"]: AliasType<{
	create?:ValueTypes["MediaDocAccessFields_sizes_large_jpeg_url_Create"],
	read?:ValueTypes["MediaDocAccessFields_sizes_large_jpeg_url_Read"],
	update?:ValueTypes["MediaDocAccessFields_sizes_large_jpeg_url_Update"],
	delete?:ValueTypes["MediaDocAccessFields_sizes_large_jpeg_url_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_large_jpeg_url_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_large_jpeg_url_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_large_jpeg_url_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_large_jpeg_url_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_large_jpeg_width"]: AliasType<{
	create?:ValueTypes["MediaDocAccessFields_sizes_large_jpeg_width_Create"],
	read?:ValueTypes["MediaDocAccessFields_sizes_large_jpeg_width_Read"],
	update?:ValueTypes["MediaDocAccessFields_sizes_large_jpeg_width_Update"],
	delete?:ValueTypes["MediaDocAccessFields_sizes_large_jpeg_width_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_large_jpeg_width_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_large_jpeg_width_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_large_jpeg_width_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_large_jpeg_width_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_large_jpeg_height"]: AliasType<{
	create?:ValueTypes["MediaDocAccessFields_sizes_large_jpeg_height_Create"],
	read?:ValueTypes["MediaDocAccessFields_sizes_large_jpeg_height_Read"],
	update?:ValueTypes["MediaDocAccessFields_sizes_large_jpeg_height_Update"],
	delete?:ValueTypes["MediaDocAccessFields_sizes_large_jpeg_height_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_large_jpeg_height_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_large_jpeg_height_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_large_jpeg_height_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_large_jpeg_height_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_large_jpeg_mimeType"]: AliasType<{
	create?:ValueTypes["MediaDocAccessFields_sizes_large_jpeg_mimeType_Create"],
	read?:ValueTypes["MediaDocAccessFields_sizes_large_jpeg_mimeType_Read"],
	update?:ValueTypes["MediaDocAccessFields_sizes_large_jpeg_mimeType_Update"],
	delete?:ValueTypes["MediaDocAccessFields_sizes_large_jpeg_mimeType_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_large_jpeg_mimeType_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_large_jpeg_mimeType_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_large_jpeg_mimeType_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_large_jpeg_mimeType_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_large_jpeg_filesize"]: AliasType<{
	create?:ValueTypes["MediaDocAccessFields_sizes_large_jpeg_filesize_Create"],
	read?:ValueTypes["MediaDocAccessFields_sizes_large_jpeg_filesize_Read"],
	update?:ValueTypes["MediaDocAccessFields_sizes_large_jpeg_filesize_Update"],
	delete?:ValueTypes["MediaDocAccessFields_sizes_large_jpeg_filesize_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_large_jpeg_filesize_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_large_jpeg_filesize_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_large_jpeg_filesize_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_large_jpeg_filesize_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_large_jpeg_filename"]: AliasType<{
	create?:ValueTypes["MediaDocAccessFields_sizes_large_jpeg_filename_Create"],
	read?:ValueTypes["MediaDocAccessFields_sizes_large_jpeg_filename_Read"],
	update?:ValueTypes["MediaDocAccessFields_sizes_large_jpeg_filename_Update"],
	delete?:ValueTypes["MediaDocAccessFields_sizes_large_jpeg_filename_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_large_jpeg_filename_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_large_jpeg_filename_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_large_jpeg_filename_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_large_jpeg_filename_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaCreateDocAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaReadDocAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaUpdateDocAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDeleteDocAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMedia"]: AliasType<{
	id?:boolean | `@${string}`,
	alt?:boolean | `@${string}`,
creator?: [{	locale?: ValueTypes["LocaleInputType"] | undefined | null | Variable<any, string>,	fallbackLocale?: ValueTypes["FallbackLocaleInputType"] | undefined | null | Variable<any, string>},ValueTypes["User"]],
	updatedAt?:boolean | `@${string}`,
	createdAt?:boolean | `@${string}`,
	url?:boolean | `@${string}`,
	filename?:boolean | `@${string}`,
	mimeType?:boolean | `@${string}`,
	filesize?:boolean | `@${string}`,
	width?:boolean | `@${string}`,
	height?:boolean | `@${string}`,
	focalX?:boolean | `@${string}`,
	focalY?:boolean | `@${string}`,
	sizes?:ValueTypes["BackupMedia_Sizes"],
		__typename?: boolean | `@${string}`
}>;
	["BackupMedia_Sizes"]: AliasType<{
	thumbnail?:ValueTypes["BackupMedia_Sizes_Thumbnail"],
	sixteenByNineMedium?:ValueTypes["BackupMedia_Sizes_SixteenByNineMedium"],
		__typename?: boolean | `@${string}`
}>;
	["BackupMedia_Sizes_Thumbnail"]: AliasType<{
	url?:boolean | `@${string}`,
	width?:boolean | `@${string}`,
	height?:boolean | `@${string}`,
	mimeType?:boolean | `@${string}`,
	filesize?:boolean | `@${string}`,
	filename?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMedia_Sizes_SixteenByNineMedium"]: AliasType<{
	url?:boolean | `@${string}`,
	width?:boolean | `@${string}`,
	height?:boolean | `@${string}`,
	mimeType?:boolean | `@${string}`,
	filesize?:boolean | `@${string}`,
	filename?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMedias"]: AliasType<{
	docs?:ValueTypes["BackupMedia"],
	hasNextPage?:boolean | `@${string}`,
	hasPrevPage?:boolean | `@${string}`,
	limit?:boolean | `@${string}`,
	nextPage?:boolean | `@${string}`,
	offset?:boolean | `@${string}`,
	page?:boolean | `@${string}`,
	pagingCounter?:boolean | `@${string}`,
	prevPage?:boolean | `@${string}`,
	totalDocs?:boolean | `@${string}`,
	totalPages?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMedia_where"]: {
	alt?: ValueTypes["BackupMedia_alt_operator"] | undefined | null | Variable<any, string>,
	creator?: ValueTypes["BackupMedia_creator_operator"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["BackupMedia_updatedAt_operator"] | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["BackupMedia_createdAt_operator"] | undefined | null | Variable<any, string>,
	url?: ValueTypes["BackupMedia_url_operator"] | undefined | null | Variable<any, string>,
	filename?: ValueTypes["BackupMedia_filename_operator"] | undefined | null | Variable<any, string>,
	mimeType?: ValueTypes["BackupMedia_mimeType_operator"] | undefined | null | Variable<any, string>,
	filesize?: ValueTypes["BackupMedia_filesize_operator"] | undefined | null | Variable<any, string>,
	width?: ValueTypes["BackupMedia_width_operator"] | undefined | null | Variable<any, string>,
	height?: ValueTypes["BackupMedia_height_operator"] | undefined | null | Variable<any, string>,
	focalX?: ValueTypes["BackupMedia_focalX_operator"] | undefined | null | Variable<any, string>,
	focalY?: ValueTypes["BackupMedia_focalY_operator"] | undefined | null | Variable<any, string>,
	sizes__thumbnail__url?: ValueTypes["BackupMedia_sizes__thumbnail__url_operator"] | undefined | null | Variable<any, string>,
	sizes__thumbnail__width?: ValueTypes["BackupMedia_sizes__thumbnail__width_operator"] | undefined | null | Variable<any, string>,
	sizes__thumbnail__height?: ValueTypes["BackupMedia_sizes__thumbnail__height_operator"] | undefined | null | Variable<any, string>,
	sizes__thumbnail__mimeType?: ValueTypes["BackupMedia_sizes__thumbnail__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__thumbnail__filesize?: ValueTypes["BackupMedia_sizes__thumbnail__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__thumbnail__filename?: ValueTypes["BackupMedia_sizes__thumbnail__filename_operator"] | undefined | null | Variable<any, string>,
	sizes__sixteenByNineMedium__url?: ValueTypes["BackupMedia_sizes__sixteenByNineMedium__url_operator"] | undefined | null | Variable<any, string>,
	sizes__sixteenByNineMedium__width?: ValueTypes["BackupMedia_sizes__sixteenByNineMedium__width_operator"] | undefined | null | Variable<any, string>,
	sizes__sixteenByNineMedium__height?: ValueTypes["BackupMedia_sizes__sixteenByNineMedium__height_operator"] | undefined | null | Variable<any, string>,
	sizes__sixteenByNineMedium__mimeType?: ValueTypes["BackupMedia_sizes__sixteenByNineMedium__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__sixteenByNineMedium__filesize?: ValueTypes["BackupMedia_sizes__sixteenByNineMedium__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__sixteenByNineMedium__filename?: ValueTypes["BackupMedia_sizes__sixteenByNineMedium__filename_operator"] | undefined | null | Variable<any, string>,
	id?: ValueTypes["BackupMedia_id_operator"] | undefined | null | Variable<any, string>,
	AND?: Array<ValueTypes["BackupMedia_where_and"] | undefined | null> | undefined | null | Variable<any, string>,
	OR?: Array<ValueTypes["BackupMedia_where_or"] | undefined | null> | undefined | null | Variable<any, string>
};
	["BackupMedia_alt_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["BackupMedia_creator_operator"]: {
	equals?: ValueTypes["JSON"] | undefined | null | Variable<any, string>,
	not_equals?: ValueTypes["JSON"] | undefined | null | Variable<any, string>,
	in?: Array<ValueTypes["JSON"] | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<ValueTypes["JSON"] | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<ValueTypes["JSON"] | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["BackupMedia_updatedAt_operator"]: {
	equals?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	not_equals?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	greater_than_equal?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	greater_than?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	less_than_equal?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	less_than?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	like?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["BackupMedia_createdAt_operator"]: {
	equals?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	not_equals?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	greater_than_equal?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	greater_than?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	less_than_equal?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	less_than?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	like?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["BackupMedia_url_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["BackupMedia_filename_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["BackupMedia_mimeType_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["BackupMedia_filesize_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["BackupMedia_width_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["BackupMedia_height_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["BackupMedia_focalX_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["BackupMedia_focalY_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["BackupMedia_sizes__thumbnail__url_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["BackupMedia_sizes__thumbnail__width_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["BackupMedia_sizes__thumbnail__height_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["BackupMedia_sizes__thumbnail__mimeType_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["BackupMedia_sizes__thumbnail__filesize_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["BackupMedia_sizes__thumbnail__filename_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["BackupMedia_sizes__sixteenByNineMedium__url_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["BackupMedia_sizes__sixteenByNineMedium__width_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["BackupMedia_sizes__sixteenByNineMedium__height_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["BackupMedia_sizes__sixteenByNineMedium__mimeType_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["BackupMedia_sizes__sixteenByNineMedium__filesize_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["BackupMedia_sizes__sixteenByNineMedium__filename_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["BackupMedia_id_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["BackupMedia_where_and"]: {
	alt?: ValueTypes["BackupMedia_alt_operator"] | undefined | null | Variable<any, string>,
	creator?: ValueTypes["BackupMedia_creator_operator"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["BackupMedia_updatedAt_operator"] | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["BackupMedia_createdAt_operator"] | undefined | null | Variable<any, string>,
	url?: ValueTypes["BackupMedia_url_operator"] | undefined | null | Variable<any, string>,
	filename?: ValueTypes["BackupMedia_filename_operator"] | undefined | null | Variable<any, string>,
	mimeType?: ValueTypes["BackupMedia_mimeType_operator"] | undefined | null | Variable<any, string>,
	filesize?: ValueTypes["BackupMedia_filesize_operator"] | undefined | null | Variable<any, string>,
	width?: ValueTypes["BackupMedia_width_operator"] | undefined | null | Variable<any, string>,
	height?: ValueTypes["BackupMedia_height_operator"] | undefined | null | Variable<any, string>,
	focalX?: ValueTypes["BackupMedia_focalX_operator"] | undefined | null | Variable<any, string>,
	focalY?: ValueTypes["BackupMedia_focalY_operator"] | undefined | null | Variable<any, string>,
	sizes__thumbnail__url?: ValueTypes["BackupMedia_sizes__thumbnail__url_operator"] | undefined | null | Variable<any, string>,
	sizes__thumbnail__width?: ValueTypes["BackupMedia_sizes__thumbnail__width_operator"] | undefined | null | Variable<any, string>,
	sizes__thumbnail__height?: ValueTypes["BackupMedia_sizes__thumbnail__height_operator"] | undefined | null | Variable<any, string>,
	sizes__thumbnail__mimeType?: ValueTypes["BackupMedia_sizes__thumbnail__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__thumbnail__filesize?: ValueTypes["BackupMedia_sizes__thumbnail__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__thumbnail__filename?: ValueTypes["BackupMedia_sizes__thumbnail__filename_operator"] | undefined | null | Variable<any, string>,
	sizes__sixteenByNineMedium__url?: ValueTypes["BackupMedia_sizes__sixteenByNineMedium__url_operator"] | undefined | null | Variable<any, string>,
	sizes__sixteenByNineMedium__width?: ValueTypes["BackupMedia_sizes__sixteenByNineMedium__width_operator"] | undefined | null | Variable<any, string>,
	sizes__sixteenByNineMedium__height?: ValueTypes["BackupMedia_sizes__sixteenByNineMedium__height_operator"] | undefined | null | Variable<any, string>,
	sizes__sixteenByNineMedium__mimeType?: ValueTypes["BackupMedia_sizes__sixteenByNineMedium__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__sixteenByNineMedium__filesize?: ValueTypes["BackupMedia_sizes__sixteenByNineMedium__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__sixteenByNineMedium__filename?: ValueTypes["BackupMedia_sizes__sixteenByNineMedium__filename_operator"] | undefined | null | Variable<any, string>,
	id?: ValueTypes["BackupMedia_id_operator"] | undefined | null | Variable<any, string>,
	AND?: Array<ValueTypes["BackupMedia_where_and"] | undefined | null> | undefined | null | Variable<any, string>,
	OR?: Array<ValueTypes["BackupMedia_where_or"] | undefined | null> | undefined | null | Variable<any, string>
};
	["BackupMedia_where_or"]: {
	alt?: ValueTypes["BackupMedia_alt_operator"] | undefined | null | Variable<any, string>,
	creator?: ValueTypes["BackupMedia_creator_operator"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["BackupMedia_updatedAt_operator"] | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["BackupMedia_createdAt_operator"] | undefined | null | Variable<any, string>,
	url?: ValueTypes["BackupMedia_url_operator"] | undefined | null | Variable<any, string>,
	filename?: ValueTypes["BackupMedia_filename_operator"] | undefined | null | Variable<any, string>,
	mimeType?: ValueTypes["BackupMedia_mimeType_operator"] | undefined | null | Variable<any, string>,
	filesize?: ValueTypes["BackupMedia_filesize_operator"] | undefined | null | Variable<any, string>,
	width?: ValueTypes["BackupMedia_width_operator"] | undefined | null | Variable<any, string>,
	height?: ValueTypes["BackupMedia_height_operator"] | undefined | null | Variable<any, string>,
	focalX?: ValueTypes["BackupMedia_focalX_operator"] | undefined | null | Variable<any, string>,
	focalY?: ValueTypes["BackupMedia_focalY_operator"] | undefined | null | Variable<any, string>,
	sizes__thumbnail__url?: ValueTypes["BackupMedia_sizes__thumbnail__url_operator"] | undefined | null | Variable<any, string>,
	sizes__thumbnail__width?: ValueTypes["BackupMedia_sizes__thumbnail__width_operator"] | undefined | null | Variable<any, string>,
	sizes__thumbnail__height?: ValueTypes["BackupMedia_sizes__thumbnail__height_operator"] | undefined | null | Variable<any, string>,
	sizes__thumbnail__mimeType?: ValueTypes["BackupMedia_sizes__thumbnail__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__thumbnail__filesize?: ValueTypes["BackupMedia_sizes__thumbnail__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__thumbnail__filename?: ValueTypes["BackupMedia_sizes__thumbnail__filename_operator"] | undefined | null | Variable<any, string>,
	sizes__sixteenByNineMedium__url?: ValueTypes["BackupMedia_sizes__sixteenByNineMedium__url_operator"] | undefined | null | Variable<any, string>,
	sizes__sixteenByNineMedium__width?: ValueTypes["BackupMedia_sizes__sixteenByNineMedium__width_operator"] | undefined | null | Variable<any, string>,
	sizes__sixteenByNineMedium__height?: ValueTypes["BackupMedia_sizes__sixteenByNineMedium__height_operator"] | undefined | null | Variable<any, string>,
	sizes__sixteenByNineMedium__mimeType?: ValueTypes["BackupMedia_sizes__sixteenByNineMedium__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__sixteenByNineMedium__filesize?: ValueTypes["BackupMedia_sizes__sixteenByNineMedium__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__sixteenByNineMedium__filename?: ValueTypes["BackupMedia_sizes__sixteenByNineMedium__filename_operator"] | undefined | null | Variable<any, string>,
	id?: ValueTypes["BackupMedia_id_operator"] | undefined | null | Variable<any, string>,
	AND?: Array<ValueTypes["BackupMedia_where_and"] | undefined | null> | undefined | null | Variable<any, string>,
	OR?: Array<ValueTypes["BackupMedia_where_or"] | undefined | null> | undefined | null | Variable<any, string>
};
	["countBackupMedias"]: AliasType<{
	totalDocs?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["backupMediaDocAccess"]: AliasType<{
	fields?:ValueTypes["BackupMediaDocAccessFields"],
	create?:ValueTypes["BackupMediaCreateDocAccess"],
	read?:ValueTypes["BackupMediaReadDocAccess"],
	update?:ValueTypes["BackupMediaUpdateDocAccess"],
	delete?:ValueTypes["BackupMediaDeleteDocAccess"],
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaDocAccessFields"]: AliasType<{
	alt?:ValueTypes["BackupMediaDocAccessFields_alt"],
	creator?:ValueTypes["BackupMediaDocAccessFields_creator"],
	updatedAt?:ValueTypes["BackupMediaDocAccessFields_updatedAt"],
	createdAt?:ValueTypes["BackupMediaDocAccessFields_createdAt"],
	url?:ValueTypes["BackupMediaDocAccessFields_url"],
	filename?:ValueTypes["BackupMediaDocAccessFields_filename"],
	mimeType?:ValueTypes["BackupMediaDocAccessFields_mimeType"],
	filesize?:ValueTypes["BackupMediaDocAccessFields_filesize"],
	width?:ValueTypes["BackupMediaDocAccessFields_width"],
	height?:ValueTypes["BackupMediaDocAccessFields_height"],
	focalX?:ValueTypes["BackupMediaDocAccessFields_focalX"],
	focalY?:ValueTypes["BackupMediaDocAccessFields_focalY"],
	sizes?:ValueTypes["BackupMediaDocAccessFields_sizes"],
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaDocAccessFields_alt"]: AliasType<{
	create?:ValueTypes["BackupMediaDocAccessFields_alt_Create"],
	read?:ValueTypes["BackupMediaDocAccessFields_alt_Read"],
	update?:ValueTypes["BackupMediaDocAccessFields_alt_Update"],
	delete?:ValueTypes["BackupMediaDocAccessFields_alt_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaDocAccessFields_alt_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaDocAccessFields_alt_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaDocAccessFields_alt_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaDocAccessFields_alt_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaDocAccessFields_creator"]: AliasType<{
	create?:ValueTypes["BackupMediaDocAccessFields_creator_Create"],
	read?:ValueTypes["BackupMediaDocAccessFields_creator_Read"],
	update?:ValueTypes["BackupMediaDocAccessFields_creator_Update"],
	delete?:ValueTypes["BackupMediaDocAccessFields_creator_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaDocAccessFields_creator_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaDocAccessFields_creator_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaDocAccessFields_creator_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaDocAccessFields_creator_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaDocAccessFields_updatedAt"]: AliasType<{
	create?:ValueTypes["BackupMediaDocAccessFields_updatedAt_Create"],
	read?:ValueTypes["BackupMediaDocAccessFields_updatedAt_Read"],
	update?:ValueTypes["BackupMediaDocAccessFields_updatedAt_Update"],
	delete?:ValueTypes["BackupMediaDocAccessFields_updatedAt_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaDocAccessFields_updatedAt_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaDocAccessFields_updatedAt_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaDocAccessFields_updatedAt_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaDocAccessFields_updatedAt_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaDocAccessFields_createdAt"]: AliasType<{
	create?:ValueTypes["BackupMediaDocAccessFields_createdAt_Create"],
	read?:ValueTypes["BackupMediaDocAccessFields_createdAt_Read"],
	update?:ValueTypes["BackupMediaDocAccessFields_createdAt_Update"],
	delete?:ValueTypes["BackupMediaDocAccessFields_createdAt_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaDocAccessFields_createdAt_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaDocAccessFields_createdAt_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaDocAccessFields_createdAt_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaDocAccessFields_createdAt_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaDocAccessFields_url"]: AliasType<{
	create?:ValueTypes["BackupMediaDocAccessFields_url_Create"],
	read?:ValueTypes["BackupMediaDocAccessFields_url_Read"],
	update?:ValueTypes["BackupMediaDocAccessFields_url_Update"],
	delete?:ValueTypes["BackupMediaDocAccessFields_url_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaDocAccessFields_url_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaDocAccessFields_url_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaDocAccessFields_url_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaDocAccessFields_url_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaDocAccessFields_filename"]: AliasType<{
	create?:ValueTypes["BackupMediaDocAccessFields_filename_Create"],
	read?:ValueTypes["BackupMediaDocAccessFields_filename_Read"],
	update?:ValueTypes["BackupMediaDocAccessFields_filename_Update"],
	delete?:ValueTypes["BackupMediaDocAccessFields_filename_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaDocAccessFields_filename_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaDocAccessFields_filename_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaDocAccessFields_filename_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaDocAccessFields_filename_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaDocAccessFields_mimeType"]: AliasType<{
	create?:ValueTypes["BackupMediaDocAccessFields_mimeType_Create"],
	read?:ValueTypes["BackupMediaDocAccessFields_mimeType_Read"],
	update?:ValueTypes["BackupMediaDocAccessFields_mimeType_Update"],
	delete?:ValueTypes["BackupMediaDocAccessFields_mimeType_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaDocAccessFields_mimeType_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaDocAccessFields_mimeType_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaDocAccessFields_mimeType_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaDocAccessFields_mimeType_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaDocAccessFields_filesize"]: AliasType<{
	create?:ValueTypes["BackupMediaDocAccessFields_filesize_Create"],
	read?:ValueTypes["BackupMediaDocAccessFields_filesize_Read"],
	update?:ValueTypes["BackupMediaDocAccessFields_filesize_Update"],
	delete?:ValueTypes["BackupMediaDocAccessFields_filesize_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaDocAccessFields_filesize_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaDocAccessFields_filesize_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaDocAccessFields_filesize_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaDocAccessFields_filesize_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaDocAccessFields_width"]: AliasType<{
	create?:ValueTypes["BackupMediaDocAccessFields_width_Create"],
	read?:ValueTypes["BackupMediaDocAccessFields_width_Read"],
	update?:ValueTypes["BackupMediaDocAccessFields_width_Update"],
	delete?:ValueTypes["BackupMediaDocAccessFields_width_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaDocAccessFields_width_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaDocAccessFields_width_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaDocAccessFields_width_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaDocAccessFields_width_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaDocAccessFields_height"]: AliasType<{
	create?:ValueTypes["BackupMediaDocAccessFields_height_Create"],
	read?:ValueTypes["BackupMediaDocAccessFields_height_Read"],
	update?:ValueTypes["BackupMediaDocAccessFields_height_Update"],
	delete?:ValueTypes["BackupMediaDocAccessFields_height_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaDocAccessFields_height_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaDocAccessFields_height_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaDocAccessFields_height_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaDocAccessFields_height_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaDocAccessFields_focalX"]: AliasType<{
	create?:ValueTypes["BackupMediaDocAccessFields_focalX_Create"],
	read?:ValueTypes["BackupMediaDocAccessFields_focalX_Read"],
	update?:ValueTypes["BackupMediaDocAccessFields_focalX_Update"],
	delete?:ValueTypes["BackupMediaDocAccessFields_focalX_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaDocAccessFields_focalX_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaDocAccessFields_focalX_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaDocAccessFields_focalX_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaDocAccessFields_focalX_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaDocAccessFields_focalY"]: AliasType<{
	create?:ValueTypes["BackupMediaDocAccessFields_focalY_Create"],
	read?:ValueTypes["BackupMediaDocAccessFields_focalY_Read"],
	update?:ValueTypes["BackupMediaDocAccessFields_focalY_Update"],
	delete?:ValueTypes["BackupMediaDocAccessFields_focalY_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaDocAccessFields_focalY_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaDocAccessFields_focalY_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaDocAccessFields_focalY_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaDocAccessFields_focalY_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaDocAccessFields_sizes"]: AliasType<{
	create?:ValueTypes["BackupMediaDocAccessFields_sizes_Create"],
	read?:ValueTypes["BackupMediaDocAccessFields_sizes_Read"],
	update?:ValueTypes["BackupMediaDocAccessFields_sizes_Update"],
	delete?:ValueTypes["BackupMediaDocAccessFields_sizes_Delete"],
	fields?:ValueTypes["BackupMediaDocAccessFields_sizes_Fields"],
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaDocAccessFields_sizes_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaDocAccessFields_sizes_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaDocAccessFields_sizes_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaDocAccessFields_sizes_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaDocAccessFields_sizes_Fields"]: AliasType<{
	thumbnail?:ValueTypes["BackupMediaDocAccessFields_sizes_thumbnail"],
	sixteenByNineMedium?:ValueTypes["BackupMediaDocAccessFields_sizes_sixteenByNineMedium"],
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaDocAccessFields_sizes_thumbnail"]: AliasType<{
	create?:ValueTypes["BackupMediaDocAccessFields_sizes_thumbnail_Create"],
	read?:ValueTypes["BackupMediaDocAccessFields_sizes_thumbnail_Read"],
	update?:ValueTypes["BackupMediaDocAccessFields_sizes_thumbnail_Update"],
	delete?:ValueTypes["BackupMediaDocAccessFields_sizes_thumbnail_Delete"],
	fields?:ValueTypes["BackupMediaDocAccessFields_sizes_thumbnail_Fields"],
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaDocAccessFields_sizes_thumbnail_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaDocAccessFields_sizes_thumbnail_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaDocAccessFields_sizes_thumbnail_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaDocAccessFields_sizes_thumbnail_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaDocAccessFields_sizes_thumbnail_Fields"]: AliasType<{
	url?:ValueTypes["BackupMediaDocAccessFields_sizes_thumbnail_url"],
	width?:ValueTypes["BackupMediaDocAccessFields_sizes_thumbnail_width"],
	height?:ValueTypes["BackupMediaDocAccessFields_sizes_thumbnail_height"],
	mimeType?:ValueTypes["BackupMediaDocAccessFields_sizes_thumbnail_mimeType"],
	filesize?:ValueTypes["BackupMediaDocAccessFields_sizes_thumbnail_filesize"],
	filename?:ValueTypes["BackupMediaDocAccessFields_sizes_thumbnail_filename"],
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaDocAccessFields_sizes_thumbnail_url"]: AliasType<{
	create?:ValueTypes["BackupMediaDocAccessFields_sizes_thumbnail_url_Create"],
	read?:ValueTypes["BackupMediaDocAccessFields_sizes_thumbnail_url_Read"],
	update?:ValueTypes["BackupMediaDocAccessFields_sizes_thumbnail_url_Update"],
	delete?:ValueTypes["BackupMediaDocAccessFields_sizes_thumbnail_url_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaDocAccessFields_sizes_thumbnail_url_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaDocAccessFields_sizes_thumbnail_url_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaDocAccessFields_sizes_thumbnail_url_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaDocAccessFields_sizes_thumbnail_url_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaDocAccessFields_sizes_thumbnail_width"]: AliasType<{
	create?:ValueTypes["BackupMediaDocAccessFields_sizes_thumbnail_width_Create"],
	read?:ValueTypes["BackupMediaDocAccessFields_sizes_thumbnail_width_Read"],
	update?:ValueTypes["BackupMediaDocAccessFields_sizes_thumbnail_width_Update"],
	delete?:ValueTypes["BackupMediaDocAccessFields_sizes_thumbnail_width_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaDocAccessFields_sizes_thumbnail_width_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaDocAccessFields_sizes_thumbnail_width_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaDocAccessFields_sizes_thumbnail_width_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaDocAccessFields_sizes_thumbnail_width_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaDocAccessFields_sizes_thumbnail_height"]: AliasType<{
	create?:ValueTypes["BackupMediaDocAccessFields_sizes_thumbnail_height_Create"],
	read?:ValueTypes["BackupMediaDocAccessFields_sizes_thumbnail_height_Read"],
	update?:ValueTypes["BackupMediaDocAccessFields_sizes_thumbnail_height_Update"],
	delete?:ValueTypes["BackupMediaDocAccessFields_sizes_thumbnail_height_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaDocAccessFields_sizes_thumbnail_height_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaDocAccessFields_sizes_thumbnail_height_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaDocAccessFields_sizes_thumbnail_height_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaDocAccessFields_sizes_thumbnail_height_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaDocAccessFields_sizes_thumbnail_mimeType"]: AliasType<{
	create?:ValueTypes["BackupMediaDocAccessFields_sizes_thumbnail_mimeType_Create"],
	read?:ValueTypes["BackupMediaDocAccessFields_sizes_thumbnail_mimeType_Read"],
	update?:ValueTypes["BackupMediaDocAccessFields_sizes_thumbnail_mimeType_Update"],
	delete?:ValueTypes["BackupMediaDocAccessFields_sizes_thumbnail_mimeType_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaDocAccessFields_sizes_thumbnail_mimeType_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaDocAccessFields_sizes_thumbnail_mimeType_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaDocAccessFields_sizes_thumbnail_mimeType_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaDocAccessFields_sizes_thumbnail_mimeType_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaDocAccessFields_sizes_thumbnail_filesize"]: AliasType<{
	create?:ValueTypes["BackupMediaDocAccessFields_sizes_thumbnail_filesize_Create"],
	read?:ValueTypes["BackupMediaDocAccessFields_sizes_thumbnail_filesize_Read"],
	update?:ValueTypes["BackupMediaDocAccessFields_sizes_thumbnail_filesize_Update"],
	delete?:ValueTypes["BackupMediaDocAccessFields_sizes_thumbnail_filesize_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaDocAccessFields_sizes_thumbnail_filesize_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaDocAccessFields_sizes_thumbnail_filesize_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaDocAccessFields_sizes_thumbnail_filesize_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaDocAccessFields_sizes_thumbnail_filesize_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaDocAccessFields_sizes_thumbnail_filename"]: AliasType<{
	create?:ValueTypes["BackupMediaDocAccessFields_sizes_thumbnail_filename_Create"],
	read?:ValueTypes["BackupMediaDocAccessFields_sizes_thumbnail_filename_Read"],
	update?:ValueTypes["BackupMediaDocAccessFields_sizes_thumbnail_filename_Update"],
	delete?:ValueTypes["BackupMediaDocAccessFields_sizes_thumbnail_filename_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaDocAccessFields_sizes_thumbnail_filename_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaDocAccessFields_sizes_thumbnail_filename_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaDocAccessFields_sizes_thumbnail_filename_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaDocAccessFields_sizes_thumbnail_filename_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaDocAccessFields_sizes_sixteenByNineMedium"]: AliasType<{
	create?:ValueTypes["BackupMediaDocAccessFields_sizes_sixteenByNineMedium_Create"],
	read?:ValueTypes["BackupMediaDocAccessFields_sizes_sixteenByNineMedium_Read"],
	update?:ValueTypes["BackupMediaDocAccessFields_sizes_sixteenByNineMedium_Update"],
	delete?:ValueTypes["BackupMediaDocAccessFields_sizes_sixteenByNineMedium_Delete"],
	fields?:ValueTypes["BackupMediaDocAccessFields_sizes_sixteenByNineMedium_Fields"],
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaDocAccessFields_sizes_sixteenByNineMedium_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaDocAccessFields_sizes_sixteenByNineMedium_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaDocAccessFields_sizes_sixteenByNineMedium_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaDocAccessFields_sizes_sixteenByNineMedium_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaDocAccessFields_sizes_sixteenByNineMedium_Fields"]: AliasType<{
	url?:ValueTypes["BackupMediaDocAccessFields_sizes_sixteenByNineMedium_url"],
	width?:ValueTypes["BackupMediaDocAccessFields_sizes_sixteenByNineMedium_width"],
	height?:ValueTypes["BackupMediaDocAccessFields_sizes_sixteenByNineMedium_height"],
	mimeType?:ValueTypes["BackupMediaDocAccessFields_sizes_sixteenByNineMedium_mimeType"],
	filesize?:ValueTypes["BackupMediaDocAccessFields_sizes_sixteenByNineMedium_filesize"],
	filename?:ValueTypes["BackupMediaDocAccessFields_sizes_sixteenByNineMedium_filename"],
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaDocAccessFields_sizes_sixteenByNineMedium_url"]: AliasType<{
	create?:ValueTypes["BackupMediaDocAccessFields_sizes_sixteenByNineMedium_url_Create"],
	read?:ValueTypes["BackupMediaDocAccessFields_sizes_sixteenByNineMedium_url_Read"],
	update?:ValueTypes["BackupMediaDocAccessFields_sizes_sixteenByNineMedium_url_Update"],
	delete?:ValueTypes["BackupMediaDocAccessFields_sizes_sixteenByNineMedium_url_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaDocAccessFields_sizes_sixteenByNineMedium_url_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaDocAccessFields_sizes_sixteenByNineMedium_url_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaDocAccessFields_sizes_sixteenByNineMedium_url_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaDocAccessFields_sizes_sixteenByNineMedium_url_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaDocAccessFields_sizes_sixteenByNineMedium_width"]: AliasType<{
	create?:ValueTypes["BackupMediaDocAccessFields_sizes_sixteenByNineMedium_width_Create"],
	read?:ValueTypes["BackupMediaDocAccessFields_sizes_sixteenByNineMedium_width_Read"],
	update?:ValueTypes["BackupMediaDocAccessFields_sizes_sixteenByNineMedium_width_Update"],
	delete?:ValueTypes["BackupMediaDocAccessFields_sizes_sixteenByNineMedium_width_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaDocAccessFields_sizes_sixteenByNineMedium_width_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaDocAccessFields_sizes_sixteenByNineMedium_width_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaDocAccessFields_sizes_sixteenByNineMedium_width_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaDocAccessFields_sizes_sixteenByNineMedium_width_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaDocAccessFields_sizes_sixteenByNineMedium_height"]: AliasType<{
	create?:ValueTypes["BackupMediaDocAccessFields_sizes_sixteenByNineMedium_height_Create"],
	read?:ValueTypes["BackupMediaDocAccessFields_sizes_sixteenByNineMedium_height_Read"],
	update?:ValueTypes["BackupMediaDocAccessFields_sizes_sixteenByNineMedium_height_Update"],
	delete?:ValueTypes["BackupMediaDocAccessFields_sizes_sixteenByNineMedium_height_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaDocAccessFields_sizes_sixteenByNineMedium_height_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaDocAccessFields_sizes_sixteenByNineMedium_height_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaDocAccessFields_sizes_sixteenByNineMedium_height_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaDocAccessFields_sizes_sixteenByNineMedium_height_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaDocAccessFields_sizes_sixteenByNineMedium_mimeType"]: AliasType<{
	create?:ValueTypes["BackupMediaDocAccessFields_sizes_sixteenByNineMedium_mimeType_Create"],
	read?:ValueTypes["BackupMediaDocAccessFields_sizes_sixteenByNineMedium_mimeType_Read"],
	update?:ValueTypes["BackupMediaDocAccessFields_sizes_sixteenByNineMedium_mimeType_Update"],
	delete?:ValueTypes["BackupMediaDocAccessFields_sizes_sixteenByNineMedium_mimeType_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaDocAccessFields_sizes_sixteenByNineMedium_mimeType_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaDocAccessFields_sizes_sixteenByNineMedium_mimeType_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaDocAccessFields_sizes_sixteenByNineMedium_mimeType_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaDocAccessFields_sizes_sixteenByNineMedium_mimeType_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaDocAccessFields_sizes_sixteenByNineMedium_filesize"]: AliasType<{
	create?:ValueTypes["BackupMediaDocAccessFields_sizes_sixteenByNineMedium_filesize_Create"],
	read?:ValueTypes["BackupMediaDocAccessFields_sizes_sixteenByNineMedium_filesize_Read"],
	update?:ValueTypes["BackupMediaDocAccessFields_sizes_sixteenByNineMedium_filesize_Update"],
	delete?:ValueTypes["BackupMediaDocAccessFields_sizes_sixteenByNineMedium_filesize_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaDocAccessFields_sizes_sixteenByNineMedium_filesize_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaDocAccessFields_sizes_sixteenByNineMedium_filesize_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaDocAccessFields_sizes_sixteenByNineMedium_filesize_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaDocAccessFields_sizes_sixteenByNineMedium_filesize_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaDocAccessFields_sizes_sixteenByNineMedium_filename"]: AliasType<{
	create?:ValueTypes["BackupMediaDocAccessFields_sizes_sixteenByNineMedium_filename_Create"],
	read?:ValueTypes["BackupMediaDocAccessFields_sizes_sixteenByNineMedium_filename_Read"],
	update?:ValueTypes["BackupMediaDocAccessFields_sizes_sixteenByNineMedium_filename_Update"],
	delete?:ValueTypes["BackupMediaDocAccessFields_sizes_sixteenByNineMedium_filename_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaDocAccessFields_sizes_sixteenByNineMedium_filename_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaDocAccessFields_sizes_sixteenByNineMedium_filename_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaDocAccessFields_sizes_sixteenByNineMedium_filename_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaDocAccessFields_sizes_sixteenByNineMedium_filename_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaCreateDocAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaReadDocAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaUpdateDocAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaDeleteDocAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AccountSuspendRequest"]: AliasType<{
	id?:boolean | `@${string}`,
user?: [{	locale?: ValueTypes["LocaleInputType"] | undefined | null | Variable<any, string>,	fallbackLocale?: ValueTypes["FallbackLocaleInputType"] | undefined | null | Variable<any, string>},ValueTypes["User"]],
handledBy?: [{	locale?: ValueTypes["LocaleInputType"] | undefined | null | Variable<any, string>,	fallbackLocale?: ValueTypes["FallbackLocaleInputType"] | undefined | null | Variable<any, string>},ValueTypes["User"]],
	updatedAt?:boolean | `@${string}`,
	createdAt?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AccountSuspendRequests"]: AliasType<{
	docs?:ValueTypes["AccountSuspendRequest"],
	hasNextPage?:boolean | `@${string}`,
	hasPrevPage?:boolean | `@${string}`,
	limit?:boolean | `@${string}`,
	nextPage?:boolean | `@${string}`,
	offset?:boolean | `@${string}`,
	page?:boolean | `@${string}`,
	pagingCounter?:boolean | `@${string}`,
	prevPage?:boolean | `@${string}`,
	totalDocs?:boolean | `@${string}`,
	totalPages?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AccountSuspendRequest_where"]: {
	user?: ValueTypes["AccountSuspendRequest_user_operator"] | undefined | null | Variable<any, string>,
	handledBy?: ValueTypes["AccountSuspendRequest_handledBy_operator"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["AccountSuspendRequest_updatedAt_operator"] | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["AccountSuspendRequest_createdAt_operator"] | undefined | null | Variable<any, string>,
	id?: ValueTypes["AccountSuspendRequest_id_operator"] | undefined | null | Variable<any, string>,
	AND?: Array<ValueTypes["AccountSuspendRequest_where_and"] | undefined | null> | undefined | null | Variable<any, string>,
	OR?: Array<ValueTypes["AccountSuspendRequest_where_or"] | undefined | null> | undefined | null | Variable<any, string>
};
	["AccountSuspendRequest_user_operator"]: {
	equals?: ValueTypes["JSON"] | undefined | null | Variable<any, string>,
	not_equals?: ValueTypes["JSON"] | undefined | null | Variable<any, string>,
	in?: Array<ValueTypes["JSON"] | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<ValueTypes["JSON"] | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<ValueTypes["JSON"] | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["AccountSuspendRequest_handledBy_operator"]: {
	equals?: ValueTypes["JSON"] | undefined | null | Variable<any, string>,
	not_equals?: ValueTypes["JSON"] | undefined | null | Variable<any, string>,
	in?: Array<ValueTypes["JSON"] | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<ValueTypes["JSON"] | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<ValueTypes["JSON"] | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["AccountSuspendRequest_updatedAt_operator"]: {
	equals?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	not_equals?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	greater_than_equal?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	greater_than?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	less_than_equal?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	less_than?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	like?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["AccountSuspendRequest_createdAt_operator"]: {
	equals?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	not_equals?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	greater_than_equal?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	greater_than?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	less_than_equal?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	less_than?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	like?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["AccountSuspendRequest_id_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["AccountSuspendRequest_where_and"]: {
	user?: ValueTypes["AccountSuspendRequest_user_operator"] | undefined | null | Variable<any, string>,
	handledBy?: ValueTypes["AccountSuspendRequest_handledBy_operator"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["AccountSuspendRequest_updatedAt_operator"] | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["AccountSuspendRequest_createdAt_operator"] | undefined | null | Variable<any, string>,
	id?: ValueTypes["AccountSuspendRequest_id_operator"] | undefined | null | Variable<any, string>,
	AND?: Array<ValueTypes["AccountSuspendRequest_where_and"] | undefined | null> | undefined | null | Variable<any, string>,
	OR?: Array<ValueTypes["AccountSuspendRequest_where_or"] | undefined | null> | undefined | null | Variable<any, string>
};
	["AccountSuspendRequest_where_or"]: {
	user?: ValueTypes["AccountSuspendRequest_user_operator"] | undefined | null | Variable<any, string>,
	handledBy?: ValueTypes["AccountSuspendRequest_handledBy_operator"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["AccountSuspendRequest_updatedAt_operator"] | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["AccountSuspendRequest_createdAt_operator"] | undefined | null | Variable<any, string>,
	id?: ValueTypes["AccountSuspendRequest_id_operator"] | undefined | null | Variable<any, string>,
	AND?: Array<ValueTypes["AccountSuspendRequest_where_and"] | undefined | null> | undefined | null | Variable<any, string>,
	OR?: Array<ValueTypes["AccountSuspendRequest_where_or"] | undefined | null> | undefined | null | Variable<any, string>
};
	["countAccountSuspendRequests"]: AliasType<{
	totalDocs?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["account_suspend_requestsDocAccess"]: AliasType<{
	fields?:ValueTypes["AccountSuspendRequestsDocAccessFields"],
	create?:ValueTypes["AccountSuspendRequestsCreateDocAccess"],
	read?:ValueTypes["AccountSuspendRequestsReadDocAccess"],
	update?:ValueTypes["AccountSuspendRequestsUpdateDocAccess"],
	delete?:ValueTypes["AccountSuspendRequestsDeleteDocAccess"],
		__typename?: boolean | `@${string}`
}>;
	["AccountSuspendRequestsDocAccessFields"]: AliasType<{
	user?:ValueTypes["AccountSuspendRequestsDocAccessFields_user"],
	handledBy?:ValueTypes["AccountSuspendRequestsDocAccessFields_handledBy"],
	updatedAt?:ValueTypes["AccountSuspendRequestsDocAccessFields_updatedAt"],
	createdAt?:ValueTypes["AccountSuspendRequestsDocAccessFields_createdAt"],
		__typename?: boolean | `@${string}`
}>;
	["AccountSuspendRequestsDocAccessFields_user"]: AliasType<{
	create?:ValueTypes["AccountSuspendRequestsDocAccessFields_user_Create"],
	read?:ValueTypes["AccountSuspendRequestsDocAccessFields_user_Read"],
	update?:ValueTypes["AccountSuspendRequestsDocAccessFields_user_Update"],
	delete?:ValueTypes["AccountSuspendRequestsDocAccessFields_user_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["AccountSuspendRequestsDocAccessFields_user_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AccountSuspendRequestsDocAccessFields_user_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AccountSuspendRequestsDocAccessFields_user_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AccountSuspendRequestsDocAccessFields_user_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AccountSuspendRequestsDocAccessFields_handledBy"]: AliasType<{
	create?:ValueTypes["AccountSuspendRequestsDocAccessFields_handledBy_Create"],
	read?:ValueTypes["AccountSuspendRequestsDocAccessFields_handledBy_Read"],
	update?:ValueTypes["AccountSuspendRequestsDocAccessFields_handledBy_Update"],
	delete?:ValueTypes["AccountSuspendRequestsDocAccessFields_handledBy_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["AccountSuspendRequestsDocAccessFields_handledBy_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AccountSuspendRequestsDocAccessFields_handledBy_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AccountSuspendRequestsDocAccessFields_handledBy_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AccountSuspendRequestsDocAccessFields_handledBy_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AccountSuspendRequestsDocAccessFields_updatedAt"]: AliasType<{
	create?:ValueTypes["AccountSuspendRequestsDocAccessFields_updatedAt_Create"],
	read?:ValueTypes["AccountSuspendRequestsDocAccessFields_updatedAt_Read"],
	update?:ValueTypes["AccountSuspendRequestsDocAccessFields_updatedAt_Update"],
	delete?:ValueTypes["AccountSuspendRequestsDocAccessFields_updatedAt_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["AccountSuspendRequestsDocAccessFields_updatedAt_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AccountSuspendRequestsDocAccessFields_updatedAt_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AccountSuspendRequestsDocAccessFields_updatedAt_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AccountSuspendRequestsDocAccessFields_updatedAt_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AccountSuspendRequestsDocAccessFields_createdAt"]: AliasType<{
	create?:ValueTypes["AccountSuspendRequestsDocAccessFields_createdAt_Create"],
	read?:ValueTypes["AccountSuspendRequestsDocAccessFields_createdAt_Read"],
	update?:ValueTypes["AccountSuspendRequestsDocAccessFields_createdAt_Update"],
	delete?:ValueTypes["AccountSuspendRequestsDocAccessFields_createdAt_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["AccountSuspendRequestsDocAccessFields_createdAt_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AccountSuspendRequestsDocAccessFields_createdAt_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AccountSuspendRequestsDocAccessFields_createdAt_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AccountSuspendRequestsDocAccessFields_createdAt_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AccountSuspendRequestsCreateDocAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AccountSuspendRequestsReadDocAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AccountSuspendRequestsUpdateDocAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AccountSuspendRequestsDeleteDocAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["Device"]: AliasType<{
	id?:boolean | `@${string}`,
user?: [{	locale?: ValueTypes["LocaleInputType"] | undefined | null | Variable<any, string>,	fallbackLocale?: ValueTypes["FallbackLocaleInputType"] | undefined | null | Variable<any, string>},ValueTypes["Device_User_Relationship"]],
	udid?:boolean | `@${string}`,
	pushToken?:boolean | `@${string}`,
	updatedAt?:boolean | `@${string}`,
	createdAt?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["Device_User_Relationship"]: AliasType<{
	relationTo?:boolean | `@${string}`,
	value?:ValueTypes["Device_User"],
		__typename?: boolean | `@${string}`
}>;
	["Device_User_RelationTo"]:Device_User_RelationTo;
	["Device_User"]: AliasType<{		["...on User"]?: ValueTypes["User"]
		__typename?: boolean | `@${string}`
}>;
	["Devices"]: AliasType<{
	docs?:ValueTypes["Device"],
	hasNextPage?:boolean | `@${string}`,
	hasPrevPage?:boolean | `@${string}`,
	limit?:boolean | `@${string}`,
	nextPage?:boolean | `@${string}`,
	offset?:boolean | `@${string}`,
	page?:boolean | `@${string}`,
	pagingCounter?:boolean | `@${string}`,
	prevPage?:boolean | `@${string}`,
	totalDocs?:boolean | `@${string}`,
	totalPages?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["Device_where"]: {
	user?: ValueTypes["Device_user_Relation"] | undefined | null | Variable<any, string>,
	udid?: ValueTypes["Device_udid_operator"] | undefined | null | Variable<any, string>,
	pushToken?: ValueTypes["Device_pushToken_operator"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["Device_updatedAt_operator"] | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["Device_createdAt_operator"] | undefined | null | Variable<any, string>,
	id?: ValueTypes["Device_id_operator"] | undefined | null | Variable<any, string>,
	AND?: Array<ValueTypes["Device_where_and"] | undefined | null> | undefined | null | Variable<any, string>,
	OR?: Array<ValueTypes["Device_where_or"] | undefined | null> | undefined | null | Variable<any, string>
};
	["Device_user_Relation"]: {
	relationTo?: ValueTypes["Device_user_Relation_RelationTo"] | undefined | null | Variable<any, string>,
	value?: ValueTypes["JSON"] | undefined | null | Variable<any, string>
};
	["Device_user_Relation_RelationTo"]:Device_user_Relation_RelationTo;
	["Device_udid_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Device_pushToken_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Device_updatedAt_operator"]: {
	equals?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	not_equals?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	greater_than_equal?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	greater_than?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	less_than_equal?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	less_than?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	like?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Device_createdAt_operator"]: {
	equals?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	not_equals?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	greater_than_equal?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	greater_than?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	less_than_equal?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	less_than?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	like?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Device_id_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Device_where_and"]: {
	user?: ValueTypes["Device_user_Relation"] | undefined | null | Variable<any, string>,
	udid?: ValueTypes["Device_udid_operator"] | undefined | null | Variable<any, string>,
	pushToken?: ValueTypes["Device_pushToken_operator"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["Device_updatedAt_operator"] | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["Device_createdAt_operator"] | undefined | null | Variable<any, string>,
	id?: ValueTypes["Device_id_operator"] | undefined | null | Variable<any, string>,
	AND?: Array<ValueTypes["Device_where_and"] | undefined | null> | undefined | null | Variable<any, string>,
	OR?: Array<ValueTypes["Device_where_or"] | undefined | null> | undefined | null | Variable<any, string>
};
	["Device_where_or"]: {
	user?: ValueTypes["Device_user_Relation"] | undefined | null | Variable<any, string>,
	udid?: ValueTypes["Device_udid_operator"] | undefined | null | Variable<any, string>,
	pushToken?: ValueTypes["Device_pushToken_operator"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["Device_updatedAt_operator"] | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["Device_createdAt_operator"] | undefined | null | Variable<any, string>,
	id?: ValueTypes["Device_id_operator"] | undefined | null | Variable<any, string>,
	AND?: Array<ValueTypes["Device_where_and"] | undefined | null> | undefined | null | Variable<any, string>,
	OR?: Array<ValueTypes["Device_where_or"] | undefined | null> | undefined | null | Variable<any, string>
};
	["countDevices"]: AliasType<{
	totalDocs?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["devicesDocAccess"]: AliasType<{
	fields?:ValueTypes["DevicesDocAccessFields"],
	create?:ValueTypes["DevicesCreateDocAccess"],
	read?:ValueTypes["DevicesReadDocAccess"],
	update?:ValueTypes["DevicesUpdateDocAccess"],
	delete?:ValueTypes["DevicesDeleteDocAccess"],
		__typename?: boolean | `@${string}`
}>;
	["DevicesDocAccessFields"]: AliasType<{
	user?:ValueTypes["DevicesDocAccessFields_user"],
	udid?:ValueTypes["DevicesDocAccessFields_udid"],
	pushToken?:ValueTypes["DevicesDocAccessFields_pushToken"],
	updatedAt?:ValueTypes["DevicesDocAccessFields_updatedAt"],
	createdAt?:ValueTypes["DevicesDocAccessFields_createdAt"],
		__typename?: boolean | `@${string}`
}>;
	["DevicesDocAccessFields_user"]: AliasType<{
	create?:ValueTypes["DevicesDocAccessFields_user_Create"],
	read?:ValueTypes["DevicesDocAccessFields_user_Read"],
	update?:ValueTypes["DevicesDocAccessFields_user_Update"],
	delete?:ValueTypes["DevicesDocAccessFields_user_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["DevicesDocAccessFields_user_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DevicesDocAccessFields_user_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DevicesDocAccessFields_user_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DevicesDocAccessFields_user_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DevicesDocAccessFields_udid"]: AliasType<{
	create?:ValueTypes["DevicesDocAccessFields_udid_Create"],
	read?:ValueTypes["DevicesDocAccessFields_udid_Read"],
	update?:ValueTypes["DevicesDocAccessFields_udid_Update"],
	delete?:ValueTypes["DevicesDocAccessFields_udid_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["DevicesDocAccessFields_udid_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DevicesDocAccessFields_udid_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DevicesDocAccessFields_udid_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DevicesDocAccessFields_udid_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DevicesDocAccessFields_pushToken"]: AliasType<{
	create?:ValueTypes["DevicesDocAccessFields_pushToken_Create"],
	read?:ValueTypes["DevicesDocAccessFields_pushToken_Read"],
	update?:ValueTypes["DevicesDocAccessFields_pushToken_Update"],
	delete?:ValueTypes["DevicesDocAccessFields_pushToken_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["DevicesDocAccessFields_pushToken_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DevicesDocAccessFields_pushToken_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DevicesDocAccessFields_pushToken_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DevicesDocAccessFields_pushToken_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DevicesDocAccessFields_updatedAt"]: AliasType<{
	create?:ValueTypes["DevicesDocAccessFields_updatedAt_Create"],
	read?:ValueTypes["DevicesDocAccessFields_updatedAt_Read"],
	update?:ValueTypes["DevicesDocAccessFields_updatedAt_Update"],
	delete?:ValueTypes["DevicesDocAccessFields_updatedAt_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["DevicesDocAccessFields_updatedAt_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DevicesDocAccessFields_updatedAt_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DevicesDocAccessFields_updatedAt_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DevicesDocAccessFields_updatedAt_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DevicesDocAccessFields_createdAt"]: AliasType<{
	create?:ValueTypes["DevicesDocAccessFields_createdAt_Create"],
	read?:ValueTypes["DevicesDocAccessFields_createdAt_Read"],
	update?:ValueTypes["DevicesDocAccessFields_createdAt_Update"],
	delete?:ValueTypes["DevicesDocAccessFields_createdAt_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["DevicesDocAccessFields_createdAt_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DevicesDocAccessFields_createdAt_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DevicesDocAccessFields_createdAt_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DevicesDocAccessFields_createdAt_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DevicesCreateDocAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DevicesReadDocAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DevicesUpdateDocAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DevicesDeleteDocAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["Notification"]: AliasType<{
	id?:boolean | `@${string}`,
receiver?: [{	locale?: ValueTypes["LocaleInputType"] | undefined | null | Variable<any, string>,	fallbackLocale?: ValueTypes["FallbackLocaleInputType"] | undefined | null | Variable<any, string>},ValueTypes["User"]],
	type?:boolean | `@${string}`,
meta?: [{	locale?: ValueTypes["LocaleInputType"] | undefined | null | Variable<any, string>,	fallbackLocale?: ValueTypes["FallbackLocaleInputType"] | undefined | null | Variable<any, string>},ValueTypes["Notification_Meta_Relationship"]],
	message?:boolean | `@${string}`,
sender?: [{	locale?: ValueTypes["LocaleInputType"] | undefined | null | Variable<any, string>,	fallbackLocale?: ValueTypes["FallbackLocaleInputType"] | undefined | null | Variable<any, string>},ValueTypes["User"]],
	readed?:boolean | `@${string}`,
	deletedByUser?:boolean | `@${string}`,
	updatedAt?:boolean | `@${string}`,
	createdAt?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["Notification_type"]:Notification_type;
	["Notification_Meta_Relationship"]: AliasType<{
	relationTo?:boolean | `@${string}`,
	value?:ValueTypes["Notification_Meta"],
		__typename?: boolean | `@${string}`
}>;
	["Notification_Meta_RelationTo"]:Notification_Meta_RelationTo;
	["Notification_Meta"]: AliasType<{		["...on Event"]?: ValueTypes["Event"],
		["...on Location"]?: ValueTypes["Location"],
		["...on Post"]?: ValueTypes["Post"],
		["...on Comment"]?: ValueTypes["Comment"]
		__typename?: boolean | `@${string}`
}>;
	["Notifications"]: AliasType<{
	docs?:ValueTypes["Notification"],
	hasNextPage?:boolean | `@${string}`,
	hasPrevPage?:boolean | `@${string}`,
	limit?:boolean | `@${string}`,
	nextPage?:boolean | `@${string}`,
	offset?:boolean | `@${string}`,
	page?:boolean | `@${string}`,
	pagingCounter?:boolean | `@${string}`,
	prevPage?:boolean | `@${string}`,
	totalDocs?:boolean | `@${string}`,
	totalPages?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["Notification_where"]: {
	receiver?: ValueTypes["Notification_receiver_operator"] | undefined | null | Variable<any, string>,
	type?: ValueTypes["Notification_type_operator"] | undefined | null | Variable<any, string>,
	meta?: ValueTypes["Notification_meta_Relation"] | undefined | null | Variable<any, string>,
	message?: ValueTypes["Notification_message_operator"] | undefined | null | Variable<any, string>,
	sender?: ValueTypes["Notification_sender_operator"] | undefined | null | Variable<any, string>,
	readed?: ValueTypes["Notification_readed_operator"] | undefined | null | Variable<any, string>,
	deletedByUser?: ValueTypes["Notification_deletedByUser_operator"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["Notification_updatedAt_operator"] | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["Notification_createdAt_operator"] | undefined | null | Variable<any, string>,
	id?: ValueTypes["Notification_id_operator"] | undefined | null | Variable<any, string>,
	AND?: Array<ValueTypes["Notification_where_and"] | undefined | null> | undefined | null | Variable<any, string>,
	OR?: Array<ValueTypes["Notification_where_or"] | undefined | null> | undefined | null | Variable<any, string>
};
	["Notification_receiver_operator"]: {
	equals?: ValueTypes["JSON"] | undefined | null | Variable<any, string>,
	not_equals?: ValueTypes["JSON"] | undefined | null | Variable<any, string>,
	in?: Array<ValueTypes["JSON"] | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<ValueTypes["JSON"] | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<ValueTypes["JSON"] | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Notification_type_operator"]: {
	equals?: ValueTypes["Notification_type_Input"] | undefined | null | Variable<any, string>,
	not_equals?: ValueTypes["Notification_type_Input"] | undefined | null | Variable<any, string>,
	in?: Array<ValueTypes["Notification_type_Input"] | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<ValueTypes["Notification_type_Input"] | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<ValueTypes["Notification_type_Input"] | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Notification_type_Input"]:Notification_type_Input;
	["Notification_meta_Relation"]: {
	relationTo?: ValueTypes["Notification_meta_Relation_RelationTo"] | undefined | null | Variable<any, string>,
	value?: ValueTypes["JSON"] | undefined | null | Variable<any, string>
};
	["Notification_meta_Relation_RelationTo"]:Notification_meta_Relation_RelationTo;
	["Notification_message_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Notification_sender_operator"]: {
	equals?: ValueTypes["JSON"] | undefined | null | Variable<any, string>,
	not_equals?: ValueTypes["JSON"] | undefined | null | Variable<any, string>,
	in?: Array<ValueTypes["JSON"] | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<ValueTypes["JSON"] | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<ValueTypes["JSON"] | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Notification_readed_operator"]: {
	equals?: boolean | undefined | null | Variable<any, string>,
	not_equals?: boolean | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Notification_deletedByUser_operator"]: {
	equals?: boolean | undefined | null | Variable<any, string>,
	not_equals?: boolean | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Notification_updatedAt_operator"]: {
	equals?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	not_equals?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	greater_than_equal?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	greater_than?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	less_than_equal?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	less_than?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	like?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Notification_createdAt_operator"]: {
	equals?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	not_equals?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	greater_than_equal?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	greater_than?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	less_than_equal?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	less_than?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	like?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Notification_id_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Notification_where_and"]: {
	receiver?: ValueTypes["Notification_receiver_operator"] | undefined | null | Variable<any, string>,
	type?: ValueTypes["Notification_type_operator"] | undefined | null | Variable<any, string>,
	meta?: ValueTypes["Notification_meta_Relation"] | undefined | null | Variable<any, string>,
	message?: ValueTypes["Notification_message_operator"] | undefined | null | Variable<any, string>,
	sender?: ValueTypes["Notification_sender_operator"] | undefined | null | Variable<any, string>,
	readed?: ValueTypes["Notification_readed_operator"] | undefined | null | Variable<any, string>,
	deletedByUser?: ValueTypes["Notification_deletedByUser_operator"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["Notification_updatedAt_operator"] | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["Notification_createdAt_operator"] | undefined | null | Variable<any, string>,
	id?: ValueTypes["Notification_id_operator"] | undefined | null | Variable<any, string>,
	AND?: Array<ValueTypes["Notification_where_and"] | undefined | null> | undefined | null | Variable<any, string>,
	OR?: Array<ValueTypes["Notification_where_or"] | undefined | null> | undefined | null | Variable<any, string>
};
	["Notification_where_or"]: {
	receiver?: ValueTypes["Notification_receiver_operator"] | undefined | null | Variable<any, string>,
	type?: ValueTypes["Notification_type_operator"] | undefined | null | Variable<any, string>,
	meta?: ValueTypes["Notification_meta_Relation"] | undefined | null | Variable<any, string>,
	message?: ValueTypes["Notification_message_operator"] | undefined | null | Variable<any, string>,
	sender?: ValueTypes["Notification_sender_operator"] | undefined | null | Variable<any, string>,
	readed?: ValueTypes["Notification_readed_operator"] | undefined | null | Variable<any, string>,
	deletedByUser?: ValueTypes["Notification_deletedByUser_operator"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["Notification_updatedAt_operator"] | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["Notification_createdAt_operator"] | undefined | null | Variable<any, string>,
	id?: ValueTypes["Notification_id_operator"] | undefined | null | Variable<any, string>,
	AND?: Array<ValueTypes["Notification_where_and"] | undefined | null> | undefined | null | Variable<any, string>,
	OR?: Array<ValueTypes["Notification_where_or"] | undefined | null> | undefined | null | Variable<any, string>
};
	["countNotifications"]: AliasType<{
	totalDocs?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["notificationsDocAccess"]: AliasType<{
	fields?:ValueTypes["NotificationsDocAccessFields"],
	create?:ValueTypes["NotificationsCreateDocAccess"],
	read?:ValueTypes["NotificationsReadDocAccess"],
	update?:ValueTypes["NotificationsUpdateDocAccess"],
	delete?:ValueTypes["NotificationsDeleteDocAccess"],
		__typename?: boolean | `@${string}`
}>;
	["NotificationsDocAccessFields"]: AliasType<{
	receiver?:ValueTypes["NotificationsDocAccessFields_receiver"],
	type?:ValueTypes["NotificationsDocAccessFields_type"],
	meta?:ValueTypes["NotificationsDocAccessFields_meta"],
	message?:ValueTypes["NotificationsDocAccessFields_message"],
	sender?:ValueTypes["NotificationsDocAccessFields_sender"],
	readed?:ValueTypes["NotificationsDocAccessFields_readed"],
	deletedByUser?:ValueTypes["NotificationsDocAccessFields_deletedByUser"],
	updatedAt?:ValueTypes["NotificationsDocAccessFields_updatedAt"],
	createdAt?:ValueTypes["NotificationsDocAccessFields_createdAt"],
		__typename?: boolean | `@${string}`
}>;
	["NotificationsDocAccessFields_receiver"]: AliasType<{
	create?:ValueTypes["NotificationsDocAccessFields_receiver_Create"],
	read?:ValueTypes["NotificationsDocAccessFields_receiver_Read"],
	update?:ValueTypes["NotificationsDocAccessFields_receiver_Update"],
	delete?:ValueTypes["NotificationsDocAccessFields_receiver_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["NotificationsDocAccessFields_receiver_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["NotificationsDocAccessFields_receiver_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["NotificationsDocAccessFields_receiver_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["NotificationsDocAccessFields_receiver_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["NotificationsDocAccessFields_type"]: AliasType<{
	create?:ValueTypes["NotificationsDocAccessFields_type_Create"],
	read?:ValueTypes["NotificationsDocAccessFields_type_Read"],
	update?:ValueTypes["NotificationsDocAccessFields_type_Update"],
	delete?:ValueTypes["NotificationsDocAccessFields_type_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["NotificationsDocAccessFields_type_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["NotificationsDocAccessFields_type_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["NotificationsDocAccessFields_type_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["NotificationsDocAccessFields_type_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["NotificationsDocAccessFields_meta"]: AliasType<{
	create?:ValueTypes["NotificationsDocAccessFields_meta_Create"],
	read?:ValueTypes["NotificationsDocAccessFields_meta_Read"],
	update?:ValueTypes["NotificationsDocAccessFields_meta_Update"],
	delete?:ValueTypes["NotificationsDocAccessFields_meta_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["NotificationsDocAccessFields_meta_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["NotificationsDocAccessFields_meta_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["NotificationsDocAccessFields_meta_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["NotificationsDocAccessFields_meta_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["NotificationsDocAccessFields_message"]: AliasType<{
	create?:ValueTypes["NotificationsDocAccessFields_message_Create"],
	read?:ValueTypes["NotificationsDocAccessFields_message_Read"],
	update?:ValueTypes["NotificationsDocAccessFields_message_Update"],
	delete?:ValueTypes["NotificationsDocAccessFields_message_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["NotificationsDocAccessFields_message_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["NotificationsDocAccessFields_message_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["NotificationsDocAccessFields_message_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["NotificationsDocAccessFields_message_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["NotificationsDocAccessFields_sender"]: AliasType<{
	create?:ValueTypes["NotificationsDocAccessFields_sender_Create"],
	read?:ValueTypes["NotificationsDocAccessFields_sender_Read"],
	update?:ValueTypes["NotificationsDocAccessFields_sender_Update"],
	delete?:ValueTypes["NotificationsDocAccessFields_sender_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["NotificationsDocAccessFields_sender_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["NotificationsDocAccessFields_sender_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["NotificationsDocAccessFields_sender_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["NotificationsDocAccessFields_sender_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["NotificationsDocAccessFields_readed"]: AliasType<{
	create?:ValueTypes["NotificationsDocAccessFields_readed_Create"],
	read?:ValueTypes["NotificationsDocAccessFields_readed_Read"],
	update?:ValueTypes["NotificationsDocAccessFields_readed_Update"],
	delete?:ValueTypes["NotificationsDocAccessFields_readed_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["NotificationsDocAccessFields_readed_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["NotificationsDocAccessFields_readed_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["NotificationsDocAccessFields_readed_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["NotificationsDocAccessFields_readed_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["NotificationsDocAccessFields_deletedByUser"]: AliasType<{
	create?:ValueTypes["NotificationsDocAccessFields_deletedByUser_Create"],
	read?:ValueTypes["NotificationsDocAccessFields_deletedByUser_Read"],
	update?:ValueTypes["NotificationsDocAccessFields_deletedByUser_Update"],
	delete?:ValueTypes["NotificationsDocAccessFields_deletedByUser_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["NotificationsDocAccessFields_deletedByUser_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["NotificationsDocAccessFields_deletedByUser_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["NotificationsDocAccessFields_deletedByUser_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["NotificationsDocAccessFields_deletedByUser_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["NotificationsDocAccessFields_updatedAt"]: AliasType<{
	create?:ValueTypes["NotificationsDocAccessFields_updatedAt_Create"],
	read?:ValueTypes["NotificationsDocAccessFields_updatedAt_Read"],
	update?:ValueTypes["NotificationsDocAccessFields_updatedAt_Update"],
	delete?:ValueTypes["NotificationsDocAccessFields_updatedAt_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["NotificationsDocAccessFields_updatedAt_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["NotificationsDocAccessFields_updatedAt_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["NotificationsDocAccessFields_updatedAt_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["NotificationsDocAccessFields_updatedAt_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["NotificationsDocAccessFields_createdAt"]: AliasType<{
	create?:ValueTypes["NotificationsDocAccessFields_createdAt_Create"],
	read?:ValueTypes["NotificationsDocAccessFields_createdAt_Read"],
	update?:ValueTypes["NotificationsDocAccessFields_createdAt_Update"],
	delete?:ValueTypes["NotificationsDocAccessFields_createdAt_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["NotificationsDocAccessFields_createdAt_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["NotificationsDocAccessFields_createdAt_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["NotificationsDocAccessFields_createdAt_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["NotificationsDocAccessFields_createdAt_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["NotificationsCreateDocAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["NotificationsReadDocAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["NotificationsUpdateDocAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["NotificationsDeleteDocAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedirectSetting"]: AliasType<{
	id?:boolean | `@${string}`,
	from?:boolean | `@${string}`,
	to?:boolean | `@${string}`,
	updatedAt?:boolean | `@${string}`,
	createdAt?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedirectSettings"]: AliasType<{
	docs?:ValueTypes["RedirectSetting"],
	hasNextPage?:boolean | `@${string}`,
	hasPrevPage?:boolean | `@${string}`,
	limit?:boolean | `@${string}`,
	nextPage?:boolean | `@${string}`,
	offset?:boolean | `@${string}`,
	page?:boolean | `@${string}`,
	pagingCounter?:boolean | `@${string}`,
	prevPage?:boolean | `@${string}`,
	totalDocs?:boolean | `@${string}`,
	totalPages?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedirectSetting_where"]: {
	from?: ValueTypes["RedirectSetting_from_operator"] | undefined | null | Variable<any, string>,
	to?: ValueTypes["RedirectSetting_to_operator"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["RedirectSetting_updatedAt_operator"] | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["RedirectSetting_createdAt_operator"] | undefined | null | Variable<any, string>,
	id?: ValueTypes["RedirectSetting_id_operator"] | undefined | null | Variable<any, string>,
	AND?: Array<ValueTypes["RedirectSetting_where_and"] | undefined | null> | undefined | null | Variable<any, string>,
	OR?: Array<ValueTypes["RedirectSetting_where_or"] | undefined | null> | undefined | null | Variable<any, string>
};
	["RedirectSetting_from_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["RedirectSetting_to_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["RedirectSetting_updatedAt_operator"]: {
	equals?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	not_equals?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	greater_than_equal?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	greater_than?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	less_than_equal?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	less_than?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	like?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["RedirectSetting_createdAt_operator"]: {
	equals?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	not_equals?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	greater_than_equal?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	greater_than?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	less_than_equal?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	less_than?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	like?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["RedirectSetting_id_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["RedirectSetting_where_and"]: {
	from?: ValueTypes["RedirectSetting_from_operator"] | undefined | null | Variable<any, string>,
	to?: ValueTypes["RedirectSetting_to_operator"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["RedirectSetting_updatedAt_operator"] | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["RedirectSetting_createdAt_operator"] | undefined | null | Variable<any, string>,
	id?: ValueTypes["RedirectSetting_id_operator"] | undefined | null | Variable<any, string>,
	AND?: Array<ValueTypes["RedirectSetting_where_and"] | undefined | null> | undefined | null | Variable<any, string>,
	OR?: Array<ValueTypes["RedirectSetting_where_or"] | undefined | null> | undefined | null | Variable<any, string>
};
	["RedirectSetting_where_or"]: {
	from?: ValueTypes["RedirectSetting_from_operator"] | undefined | null | Variable<any, string>,
	to?: ValueTypes["RedirectSetting_to_operator"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["RedirectSetting_updatedAt_operator"] | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["RedirectSetting_createdAt_operator"] | undefined | null | Variable<any, string>,
	id?: ValueTypes["RedirectSetting_id_operator"] | undefined | null | Variable<any, string>,
	AND?: Array<ValueTypes["RedirectSetting_where_and"] | undefined | null> | undefined | null | Variable<any, string>,
	OR?: Array<ValueTypes["RedirectSetting_where_or"] | undefined | null> | undefined | null | Variable<any, string>
};
	["countRedirectSettings"]: AliasType<{
	totalDocs?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["redirectSettingDocAccess"]: AliasType<{
	fields?:ValueTypes["RedirectSettingDocAccessFields"],
	create?:ValueTypes["RedirectSettingCreateDocAccess"],
	read?:ValueTypes["RedirectSettingReadDocAccess"],
	update?:ValueTypes["RedirectSettingUpdateDocAccess"],
	delete?:ValueTypes["RedirectSettingDeleteDocAccess"],
		__typename?: boolean | `@${string}`
}>;
	["RedirectSettingDocAccessFields"]: AliasType<{
	from?:ValueTypes["RedirectSettingDocAccessFields_from"],
	to?:ValueTypes["RedirectSettingDocAccessFields_to"],
	updatedAt?:ValueTypes["RedirectSettingDocAccessFields_updatedAt"],
	createdAt?:ValueTypes["RedirectSettingDocAccessFields_createdAt"],
		__typename?: boolean | `@${string}`
}>;
	["RedirectSettingDocAccessFields_from"]: AliasType<{
	create?:ValueTypes["RedirectSettingDocAccessFields_from_Create"],
	read?:ValueTypes["RedirectSettingDocAccessFields_from_Read"],
	update?:ValueTypes["RedirectSettingDocAccessFields_from_Update"],
	delete?:ValueTypes["RedirectSettingDocAccessFields_from_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["RedirectSettingDocAccessFields_from_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedirectSettingDocAccessFields_from_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedirectSettingDocAccessFields_from_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedirectSettingDocAccessFields_from_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedirectSettingDocAccessFields_to"]: AliasType<{
	create?:ValueTypes["RedirectSettingDocAccessFields_to_Create"],
	read?:ValueTypes["RedirectSettingDocAccessFields_to_Read"],
	update?:ValueTypes["RedirectSettingDocAccessFields_to_Update"],
	delete?:ValueTypes["RedirectSettingDocAccessFields_to_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["RedirectSettingDocAccessFields_to_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedirectSettingDocAccessFields_to_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedirectSettingDocAccessFields_to_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedirectSettingDocAccessFields_to_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedirectSettingDocAccessFields_updatedAt"]: AliasType<{
	create?:ValueTypes["RedirectSettingDocAccessFields_updatedAt_Create"],
	read?:ValueTypes["RedirectSettingDocAccessFields_updatedAt_Read"],
	update?:ValueTypes["RedirectSettingDocAccessFields_updatedAt_Update"],
	delete?:ValueTypes["RedirectSettingDocAccessFields_updatedAt_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["RedirectSettingDocAccessFields_updatedAt_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedirectSettingDocAccessFields_updatedAt_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedirectSettingDocAccessFields_updatedAt_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedirectSettingDocAccessFields_updatedAt_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedirectSettingDocAccessFields_createdAt"]: AliasType<{
	create?:ValueTypes["RedirectSettingDocAccessFields_createdAt_Create"],
	read?:ValueTypes["RedirectSettingDocAccessFields_createdAt_Read"],
	update?:ValueTypes["RedirectSettingDocAccessFields_createdAt_Update"],
	delete?:ValueTypes["RedirectSettingDocAccessFields_createdAt_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["RedirectSettingDocAccessFields_createdAt_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedirectSettingDocAccessFields_createdAt_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedirectSettingDocAccessFields_createdAt_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedirectSettingDocAccessFields_createdAt_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedirectSettingCreateDocAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedirectSettingReadDocAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedirectSettingUpdateDocAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedirectSettingDeleteDocAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventRanking"]: AliasType<{
	id?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	visible?:boolean | `@${string}`,
	maxItems?:boolean | `@${string}`,
	template?:boolean | `@${string}`,
	list?:ValueTypes["EventRanking_List"],
	region?:boolean | `@${string}`,
	updatedAt?:boolean | `@${string}`,
	createdAt?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventRanking_template"]:EventRanking_template;
	["EventRanking_List"]: AliasType<{
item?: [{	locale?: ValueTypes["LocaleInputType"] | undefined | null | Variable<any, string>,	fallbackLocale?: ValueTypes["FallbackLocaleInputType"] | undefined | null | Variable<any, string>},ValueTypes["Event"]],
	id?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventRanking_region"]:EventRanking_region;
	["EventRankings"]: AliasType<{
	docs?:ValueTypes["EventRanking"],
	hasNextPage?:boolean | `@${string}`,
	hasPrevPage?:boolean | `@${string}`,
	limit?:boolean | `@${string}`,
	nextPage?:boolean | `@${string}`,
	offset?:boolean | `@${string}`,
	page?:boolean | `@${string}`,
	pagingCounter?:boolean | `@${string}`,
	prevPage?:boolean | `@${string}`,
	totalDocs?:boolean | `@${string}`,
	totalPages?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventRanking_where"]: {
	name?: ValueTypes["EventRanking_name_operator"] | undefined | null | Variable<any, string>,
	visible?: ValueTypes["EventRanking_visible_operator"] | undefined | null | Variable<any, string>,
	maxItems?: ValueTypes["EventRanking_maxItems_operator"] | undefined | null | Variable<any, string>,
	template?: ValueTypes["EventRanking_template_operator"] | undefined | null | Variable<any, string>,
	list__item?: ValueTypes["EventRanking_list__item_operator"] | undefined | null | Variable<any, string>,
	list__id?: ValueTypes["EventRanking_list__id_operator"] | undefined | null | Variable<any, string>,
	region?: ValueTypes["EventRanking_region_operator"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["EventRanking_updatedAt_operator"] | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["EventRanking_createdAt_operator"] | undefined | null | Variable<any, string>,
	id?: ValueTypes["EventRanking_id_operator"] | undefined | null | Variable<any, string>,
	AND?: Array<ValueTypes["EventRanking_where_and"] | undefined | null> | undefined | null | Variable<any, string>,
	OR?: Array<ValueTypes["EventRanking_where_or"] | undefined | null> | undefined | null | Variable<any, string>
};
	["EventRanking_name_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>
};
	["EventRanking_visible_operator"]: {
	equals?: boolean | undefined | null | Variable<any, string>,
	not_equals?: boolean | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["EventRanking_maxItems_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>
};
	["EventRanking_template_operator"]: {
	equals?: ValueTypes["EventRanking_template_Input"] | undefined | null | Variable<any, string>,
	not_equals?: ValueTypes["EventRanking_template_Input"] | undefined | null | Variable<any, string>,
	in?: Array<ValueTypes["EventRanking_template_Input"] | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<ValueTypes["EventRanking_template_Input"] | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<ValueTypes["EventRanking_template_Input"] | undefined | null> | undefined | null | Variable<any, string>
};
	["EventRanking_template_Input"]:EventRanking_template_Input;
	["EventRanking_list__item_operator"]: {
	equals?: ValueTypes["JSON"] | undefined | null | Variable<any, string>,
	not_equals?: ValueTypes["JSON"] | undefined | null | Variable<any, string>,
	in?: Array<ValueTypes["JSON"] | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<ValueTypes["JSON"] | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<ValueTypes["JSON"] | undefined | null> | undefined | null | Variable<any, string>
};
	["EventRanking_list__id_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["EventRanking_region_operator"]: {
	equals?: ValueTypes["EventRanking_region_Input"] | undefined | null | Variable<any, string>,
	not_equals?: ValueTypes["EventRanking_region_Input"] | undefined | null | Variable<any, string>,
	in?: Array<ValueTypes["EventRanking_region_Input"] | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<ValueTypes["EventRanking_region_Input"] | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<ValueTypes["EventRanking_region_Input"] | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["EventRanking_region_Input"]:EventRanking_region_Input;
	["EventRanking_updatedAt_operator"]: {
	equals?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	not_equals?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	greater_than_equal?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	greater_than?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	less_than_equal?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	less_than?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	like?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["EventRanking_createdAt_operator"]: {
	equals?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	not_equals?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	greater_than_equal?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	greater_than?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	less_than_equal?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	less_than?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	like?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["EventRanking_id_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["EventRanking_where_and"]: {
	name?: ValueTypes["EventRanking_name_operator"] | undefined | null | Variable<any, string>,
	visible?: ValueTypes["EventRanking_visible_operator"] | undefined | null | Variable<any, string>,
	maxItems?: ValueTypes["EventRanking_maxItems_operator"] | undefined | null | Variable<any, string>,
	template?: ValueTypes["EventRanking_template_operator"] | undefined | null | Variable<any, string>,
	list__item?: ValueTypes["EventRanking_list__item_operator"] | undefined | null | Variable<any, string>,
	list__id?: ValueTypes["EventRanking_list__id_operator"] | undefined | null | Variable<any, string>,
	region?: ValueTypes["EventRanking_region_operator"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["EventRanking_updatedAt_operator"] | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["EventRanking_createdAt_operator"] | undefined | null | Variable<any, string>,
	id?: ValueTypes["EventRanking_id_operator"] | undefined | null | Variable<any, string>,
	AND?: Array<ValueTypes["EventRanking_where_and"] | undefined | null> | undefined | null | Variable<any, string>,
	OR?: Array<ValueTypes["EventRanking_where_or"] | undefined | null> | undefined | null | Variable<any, string>
};
	["EventRanking_where_or"]: {
	name?: ValueTypes["EventRanking_name_operator"] | undefined | null | Variable<any, string>,
	visible?: ValueTypes["EventRanking_visible_operator"] | undefined | null | Variable<any, string>,
	maxItems?: ValueTypes["EventRanking_maxItems_operator"] | undefined | null | Variable<any, string>,
	template?: ValueTypes["EventRanking_template_operator"] | undefined | null | Variable<any, string>,
	list__item?: ValueTypes["EventRanking_list__item_operator"] | undefined | null | Variable<any, string>,
	list__id?: ValueTypes["EventRanking_list__id_operator"] | undefined | null | Variable<any, string>,
	region?: ValueTypes["EventRanking_region_operator"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["EventRanking_updatedAt_operator"] | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["EventRanking_createdAt_operator"] | undefined | null | Variable<any, string>,
	id?: ValueTypes["EventRanking_id_operator"] | undefined | null | Variable<any, string>,
	AND?: Array<ValueTypes["EventRanking_where_and"] | undefined | null> | undefined | null | Variable<any, string>,
	OR?: Array<ValueTypes["EventRanking_where_or"] | undefined | null> | undefined | null | Variable<any, string>
};
	["countEventRankings"]: AliasType<{
	totalDocs?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ranking_eventsDocAccess"]: AliasType<{
	fields?:ValueTypes["RankingEventsDocAccessFields"],
	create?:ValueTypes["RankingEventsCreateDocAccess"],
	read?:ValueTypes["RankingEventsReadDocAccess"],
	update?:ValueTypes["RankingEventsUpdateDocAccess"],
	delete?:ValueTypes["RankingEventsDeleteDocAccess"],
		__typename?: boolean | `@${string}`
}>;
	["RankingEventsDocAccessFields"]: AliasType<{
	name?:ValueTypes["RankingEventsDocAccessFields_name"],
	visible?:ValueTypes["RankingEventsDocAccessFields_visible"],
	maxItems?:ValueTypes["RankingEventsDocAccessFields_maxItems"],
	template?:ValueTypes["RankingEventsDocAccessFields_template"],
	list?:ValueTypes["RankingEventsDocAccessFields_list"],
	region?:ValueTypes["RankingEventsDocAccessFields_region"],
	updatedAt?:ValueTypes["RankingEventsDocAccessFields_updatedAt"],
	createdAt?:ValueTypes["RankingEventsDocAccessFields_createdAt"],
		__typename?: boolean | `@${string}`
}>;
	["RankingEventsDocAccessFields_name"]: AliasType<{
	create?:ValueTypes["RankingEventsDocAccessFields_name_Create"],
	read?:ValueTypes["RankingEventsDocAccessFields_name_Read"],
	update?:ValueTypes["RankingEventsDocAccessFields_name_Update"],
	delete?:ValueTypes["RankingEventsDocAccessFields_name_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["RankingEventsDocAccessFields_name_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RankingEventsDocAccessFields_name_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RankingEventsDocAccessFields_name_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RankingEventsDocAccessFields_name_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RankingEventsDocAccessFields_visible"]: AliasType<{
	create?:ValueTypes["RankingEventsDocAccessFields_visible_Create"],
	read?:ValueTypes["RankingEventsDocAccessFields_visible_Read"],
	update?:ValueTypes["RankingEventsDocAccessFields_visible_Update"],
	delete?:ValueTypes["RankingEventsDocAccessFields_visible_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["RankingEventsDocAccessFields_visible_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RankingEventsDocAccessFields_visible_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RankingEventsDocAccessFields_visible_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RankingEventsDocAccessFields_visible_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RankingEventsDocAccessFields_maxItems"]: AliasType<{
	create?:ValueTypes["RankingEventsDocAccessFields_maxItems_Create"],
	read?:ValueTypes["RankingEventsDocAccessFields_maxItems_Read"],
	update?:ValueTypes["RankingEventsDocAccessFields_maxItems_Update"],
	delete?:ValueTypes["RankingEventsDocAccessFields_maxItems_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["RankingEventsDocAccessFields_maxItems_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RankingEventsDocAccessFields_maxItems_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RankingEventsDocAccessFields_maxItems_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RankingEventsDocAccessFields_maxItems_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RankingEventsDocAccessFields_template"]: AliasType<{
	create?:ValueTypes["RankingEventsDocAccessFields_template_Create"],
	read?:ValueTypes["RankingEventsDocAccessFields_template_Read"],
	update?:ValueTypes["RankingEventsDocAccessFields_template_Update"],
	delete?:ValueTypes["RankingEventsDocAccessFields_template_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["RankingEventsDocAccessFields_template_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RankingEventsDocAccessFields_template_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RankingEventsDocAccessFields_template_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RankingEventsDocAccessFields_template_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RankingEventsDocAccessFields_list"]: AliasType<{
	create?:ValueTypes["RankingEventsDocAccessFields_list_Create"],
	read?:ValueTypes["RankingEventsDocAccessFields_list_Read"],
	update?:ValueTypes["RankingEventsDocAccessFields_list_Update"],
	delete?:ValueTypes["RankingEventsDocAccessFields_list_Delete"],
	fields?:ValueTypes["RankingEventsDocAccessFields_list_Fields"],
		__typename?: boolean | `@${string}`
}>;
	["RankingEventsDocAccessFields_list_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RankingEventsDocAccessFields_list_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RankingEventsDocAccessFields_list_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RankingEventsDocAccessFields_list_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RankingEventsDocAccessFields_list_Fields"]: AliasType<{
	item?:ValueTypes["RankingEventsDocAccessFields_list_item"],
	id?:ValueTypes["RankingEventsDocAccessFields_list_id"],
		__typename?: boolean | `@${string}`
}>;
	["RankingEventsDocAccessFields_list_item"]: AliasType<{
	create?:ValueTypes["RankingEventsDocAccessFields_list_item_Create"],
	read?:ValueTypes["RankingEventsDocAccessFields_list_item_Read"],
	update?:ValueTypes["RankingEventsDocAccessFields_list_item_Update"],
	delete?:ValueTypes["RankingEventsDocAccessFields_list_item_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["RankingEventsDocAccessFields_list_item_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RankingEventsDocAccessFields_list_item_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RankingEventsDocAccessFields_list_item_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RankingEventsDocAccessFields_list_item_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RankingEventsDocAccessFields_list_id"]: AliasType<{
	create?:ValueTypes["RankingEventsDocAccessFields_list_id_Create"],
	read?:ValueTypes["RankingEventsDocAccessFields_list_id_Read"],
	update?:ValueTypes["RankingEventsDocAccessFields_list_id_Update"],
	delete?:ValueTypes["RankingEventsDocAccessFields_list_id_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["RankingEventsDocAccessFields_list_id_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RankingEventsDocAccessFields_list_id_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RankingEventsDocAccessFields_list_id_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RankingEventsDocAccessFields_list_id_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RankingEventsDocAccessFields_region"]: AliasType<{
	create?:ValueTypes["RankingEventsDocAccessFields_region_Create"],
	read?:ValueTypes["RankingEventsDocAccessFields_region_Read"],
	update?:ValueTypes["RankingEventsDocAccessFields_region_Update"],
	delete?:ValueTypes["RankingEventsDocAccessFields_region_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["RankingEventsDocAccessFields_region_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RankingEventsDocAccessFields_region_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RankingEventsDocAccessFields_region_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RankingEventsDocAccessFields_region_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RankingEventsDocAccessFields_updatedAt"]: AliasType<{
	create?:ValueTypes["RankingEventsDocAccessFields_updatedAt_Create"],
	read?:ValueTypes["RankingEventsDocAccessFields_updatedAt_Read"],
	update?:ValueTypes["RankingEventsDocAccessFields_updatedAt_Update"],
	delete?:ValueTypes["RankingEventsDocAccessFields_updatedAt_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["RankingEventsDocAccessFields_updatedAt_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RankingEventsDocAccessFields_updatedAt_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RankingEventsDocAccessFields_updatedAt_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RankingEventsDocAccessFields_updatedAt_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RankingEventsDocAccessFields_createdAt"]: AliasType<{
	create?:ValueTypes["RankingEventsDocAccessFields_createdAt_Create"],
	read?:ValueTypes["RankingEventsDocAccessFields_createdAt_Read"],
	update?:ValueTypes["RankingEventsDocAccessFields_createdAt_Update"],
	delete?:ValueTypes["RankingEventsDocAccessFields_createdAt_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["RankingEventsDocAccessFields_createdAt_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RankingEventsDocAccessFields_createdAt_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RankingEventsDocAccessFields_createdAt_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RankingEventsDocAccessFields_createdAt_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RankingEventsCreateDocAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RankingEventsReadDocAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RankingEventsUpdateDocAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RankingEventsDeleteDocAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationRanking"]: AliasType<{
	id?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	visible?:boolean | `@${string}`,
	maxItems?:boolean | `@${string}`,
	template?:boolean | `@${string}`,
	list?:ValueTypes["LocationRanking_List"],
	region?:boolean | `@${string}`,
	updatedAt?:boolean | `@${string}`,
	createdAt?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationRanking_template"]:LocationRanking_template;
	["LocationRanking_List"]: AliasType<{
item?: [{	locale?: ValueTypes["LocaleInputType"] | undefined | null | Variable<any, string>,	fallbackLocale?: ValueTypes["FallbackLocaleInputType"] | undefined | null | Variable<any, string>},ValueTypes["Location"]],
	id?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationRanking_region"]:LocationRanking_region;
	["LocationRankings"]: AliasType<{
	docs?:ValueTypes["LocationRanking"],
	hasNextPage?:boolean | `@${string}`,
	hasPrevPage?:boolean | `@${string}`,
	limit?:boolean | `@${string}`,
	nextPage?:boolean | `@${string}`,
	offset?:boolean | `@${string}`,
	page?:boolean | `@${string}`,
	pagingCounter?:boolean | `@${string}`,
	prevPage?:boolean | `@${string}`,
	totalDocs?:boolean | `@${string}`,
	totalPages?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationRanking_where"]: {
	name?: ValueTypes["LocationRanking_name_operator"] | undefined | null | Variable<any, string>,
	visible?: ValueTypes["LocationRanking_visible_operator"] | undefined | null | Variable<any, string>,
	maxItems?: ValueTypes["LocationRanking_maxItems_operator"] | undefined | null | Variable<any, string>,
	template?: ValueTypes["LocationRanking_template_operator"] | undefined | null | Variable<any, string>,
	list__item?: ValueTypes["LocationRanking_list__item_operator"] | undefined | null | Variable<any, string>,
	list__id?: ValueTypes["LocationRanking_list__id_operator"] | undefined | null | Variable<any, string>,
	region?: ValueTypes["LocationRanking_region_operator"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["LocationRanking_updatedAt_operator"] | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["LocationRanking_createdAt_operator"] | undefined | null | Variable<any, string>,
	id?: ValueTypes["LocationRanking_id_operator"] | undefined | null | Variable<any, string>,
	AND?: Array<ValueTypes["LocationRanking_where_and"] | undefined | null> | undefined | null | Variable<any, string>,
	OR?: Array<ValueTypes["LocationRanking_where_or"] | undefined | null> | undefined | null | Variable<any, string>
};
	["LocationRanking_name_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>
};
	["LocationRanking_visible_operator"]: {
	equals?: boolean | undefined | null | Variable<any, string>,
	not_equals?: boolean | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["LocationRanking_maxItems_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["LocationRanking_template_operator"]: {
	equals?: ValueTypes["LocationRanking_template_Input"] | undefined | null | Variable<any, string>,
	not_equals?: ValueTypes["LocationRanking_template_Input"] | undefined | null | Variable<any, string>,
	in?: Array<ValueTypes["LocationRanking_template_Input"] | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<ValueTypes["LocationRanking_template_Input"] | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<ValueTypes["LocationRanking_template_Input"] | undefined | null> | undefined | null | Variable<any, string>
};
	["LocationRanking_template_Input"]:LocationRanking_template_Input;
	["LocationRanking_list__item_operator"]: {
	equals?: ValueTypes["JSON"] | undefined | null | Variable<any, string>,
	not_equals?: ValueTypes["JSON"] | undefined | null | Variable<any, string>,
	in?: Array<ValueTypes["JSON"] | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<ValueTypes["JSON"] | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<ValueTypes["JSON"] | undefined | null> | undefined | null | Variable<any, string>
};
	["LocationRanking_list__id_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["LocationRanking_region_operator"]: {
	equals?: ValueTypes["LocationRanking_region_Input"] | undefined | null | Variable<any, string>,
	not_equals?: ValueTypes["LocationRanking_region_Input"] | undefined | null | Variable<any, string>,
	in?: Array<ValueTypes["LocationRanking_region_Input"] | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<ValueTypes["LocationRanking_region_Input"] | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<ValueTypes["LocationRanking_region_Input"] | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["LocationRanking_region_Input"]:LocationRanking_region_Input;
	["LocationRanking_updatedAt_operator"]: {
	equals?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	not_equals?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	greater_than_equal?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	greater_than?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	less_than_equal?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	less_than?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	like?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["LocationRanking_createdAt_operator"]: {
	equals?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	not_equals?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	greater_than_equal?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	greater_than?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	less_than_equal?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	less_than?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	like?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["LocationRanking_id_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["LocationRanking_where_and"]: {
	name?: ValueTypes["LocationRanking_name_operator"] | undefined | null | Variable<any, string>,
	visible?: ValueTypes["LocationRanking_visible_operator"] | undefined | null | Variable<any, string>,
	maxItems?: ValueTypes["LocationRanking_maxItems_operator"] | undefined | null | Variable<any, string>,
	template?: ValueTypes["LocationRanking_template_operator"] | undefined | null | Variable<any, string>,
	list__item?: ValueTypes["LocationRanking_list__item_operator"] | undefined | null | Variable<any, string>,
	list__id?: ValueTypes["LocationRanking_list__id_operator"] | undefined | null | Variable<any, string>,
	region?: ValueTypes["LocationRanking_region_operator"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["LocationRanking_updatedAt_operator"] | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["LocationRanking_createdAt_operator"] | undefined | null | Variable<any, string>,
	id?: ValueTypes["LocationRanking_id_operator"] | undefined | null | Variable<any, string>,
	AND?: Array<ValueTypes["LocationRanking_where_and"] | undefined | null> | undefined | null | Variable<any, string>,
	OR?: Array<ValueTypes["LocationRanking_where_or"] | undefined | null> | undefined | null | Variable<any, string>
};
	["LocationRanking_where_or"]: {
	name?: ValueTypes["LocationRanking_name_operator"] | undefined | null | Variable<any, string>,
	visible?: ValueTypes["LocationRanking_visible_operator"] | undefined | null | Variable<any, string>,
	maxItems?: ValueTypes["LocationRanking_maxItems_operator"] | undefined | null | Variable<any, string>,
	template?: ValueTypes["LocationRanking_template_operator"] | undefined | null | Variable<any, string>,
	list__item?: ValueTypes["LocationRanking_list__item_operator"] | undefined | null | Variable<any, string>,
	list__id?: ValueTypes["LocationRanking_list__id_operator"] | undefined | null | Variable<any, string>,
	region?: ValueTypes["LocationRanking_region_operator"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["LocationRanking_updatedAt_operator"] | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["LocationRanking_createdAt_operator"] | undefined | null | Variable<any, string>,
	id?: ValueTypes["LocationRanking_id_operator"] | undefined | null | Variable<any, string>,
	AND?: Array<ValueTypes["LocationRanking_where_and"] | undefined | null> | undefined | null | Variable<any, string>,
	OR?: Array<ValueTypes["LocationRanking_where_or"] | undefined | null> | undefined | null | Variable<any, string>
};
	["countLocationRankings"]: AliasType<{
	totalDocs?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ranking_locationsDocAccess"]: AliasType<{
	fields?:ValueTypes["RankingLocationsDocAccessFields"],
	create?:ValueTypes["RankingLocationsCreateDocAccess"],
	read?:ValueTypes["RankingLocationsReadDocAccess"],
	update?:ValueTypes["RankingLocationsUpdateDocAccess"],
	delete?:ValueTypes["RankingLocationsDeleteDocAccess"],
		__typename?: boolean | `@${string}`
}>;
	["RankingLocationsDocAccessFields"]: AliasType<{
	name?:ValueTypes["RankingLocationsDocAccessFields_name"],
	visible?:ValueTypes["RankingLocationsDocAccessFields_visible"],
	maxItems?:ValueTypes["RankingLocationsDocAccessFields_maxItems"],
	template?:ValueTypes["RankingLocationsDocAccessFields_template"],
	list?:ValueTypes["RankingLocationsDocAccessFields_list"],
	region?:ValueTypes["RankingLocationsDocAccessFields_region"],
	updatedAt?:ValueTypes["RankingLocationsDocAccessFields_updatedAt"],
	createdAt?:ValueTypes["RankingLocationsDocAccessFields_createdAt"],
		__typename?: boolean | `@${string}`
}>;
	["RankingLocationsDocAccessFields_name"]: AliasType<{
	create?:ValueTypes["RankingLocationsDocAccessFields_name_Create"],
	read?:ValueTypes["RankingLocationsDocAccessFields_name_Read"],
	update?:ValueTypes["RankingLocationsDocAccessFields_name_Update"],
	delete?:ValueTypes["RankingLocationsDocAccessFields_name_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["RankingLocationsDocAccessFields_name_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RankingLocationsDocAccessFields_name_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RankingLocationsDocAccessFields_name_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RankingLocationsDocAccessFields_name_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RankingLocationsDocAccessFields_visible"]: AliasType<{
	create?:ValueTypes["RankingLocationsDocAccessFields_visible_Create"],
	read?:ValueTypes["RankingLocationsDocAccessFields_visible_Read"],
	update?:ValueTypes["RankingLocationsDocAccessFields_visible_Update"],
	delete?:ValueTypes["RankingLocationsDocAccessFields_visible_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["RankingLocationsDocAccessFields_visible_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RankingLocationsDocAccessFields_visible_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RankingLocationsDocAccessFields_visible_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RankingLocationsDocAccessFields_visible_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RankingLocationsDocAccessFields_maxItems"]: AliasType<{
	create?:ValueTypes["RankingLocationsDocAccessFields_maxItems_Create"],
	read?:ValueTypes["RankingLocationsDocAccessFields_maxItems_Read"],
	update?:ValueTypes["RankingLocationsDocAccessFields_maxItems_Update"],
	delete?:ValueTypes["RankingLocationsDocAccessFields_maxItems_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["RankingLocationsDocAccessFields_maxItems_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RankingLocationsDocAccessFields_maxItems_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RankingLocationsDocAccessFields_maxItems_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RankingLocationsDocAccessFields_maxItems_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RankingLocationsDocAccessFields_template"]: AliasType<{
	create?:ValueTypes["RankingLocationsDocAccessFields_template_Create"],
	read?:ValueTypes["RankingLocationsDocAccessFields_template_Read"],
	update?:ValueTypes["RankingLocationsDocAccessFields_template_Update"],
	delete?:ValueTypes["RankingLocationsDocAccessFields_template_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["RankingLocationsDocAccessFields_template_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RankingLocationsDocAccessFields_template_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RankingLocationsDocAccessFields_template_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RankingLocationsDocAccessFields_template_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RankingLocationsDocAccessFields_list"]: AliasType<{
	create?:ValueTypes["RankingLocationsDocAccessFields_list_Create"],
	read?:ValueTypes["RankingLocationsDocAccessFields_list_Read"],
	update?:ValueTypes["RankingLocationsDocAccessFields_list_Update"],
	delete?:ValueTypes["RankingLocationsDocAccessFields_list_Delete"],
	fields?:ValueTypes["RankingLocationsDocAccessFields_list_Fields"],
		__typename?: boolean | `@${string}`
}>;
	["RankingLocationsDocAccessFields_list_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RankingLocationsDocAccessFields_list_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RankingLocationsDocAccessFields_list_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RankingLocationsDocAccessFields_list_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RankingLocationsDocAccessFields_list_Fields"]: AliasType<{
	item?:ValueTypes["RankingLocationsDocAccessFields_list_item"],
	id?:ValueTypes["RankingLocationsDocAccessFields_list_id"],
		__typename?: boolean | `@${string}`
}>;
	["RankingLocationsDocAccessFields_list_item"]: AliasType<{
	create?:ValueTypes["RankingLocationsDocAccessFields_list_item_Create"],
	read?:ValueTypes["RankingLocationsDocAccessFields_list_item_Read"],
	update?:ValueTypes["RankingLocationsDocAccessFields_list_item_Update"],
	delete?:ValueTypes["RankingLocationsDocAccessFields_list_item_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["RankingLocationsDocAccessFields_list_item_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RankingLocationsDocAccessFields_list_item_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RankingLocationsDocAccessFields_list_item_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RankingLocationsDocAccessFields_list_item_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RankingLocationsDocAccessFields_list_id"]: AliasType<{
	create?:ValueTypes["RankingLocationsDocAccessFields_list_id_Create"],
	read?:ValueTypes["RankingLocationsDocAccessFields_list_id_Read"],
	update?:ValueTypes["RankingLocationsDocAccessFields_list_id_Update"],
	delete?:ValueTypes["RankingLocationsDocAccessFields_list_id_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["RankingLocationsDocAccessFields_list_id_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RankingLocationsDocAccessFields_list_id_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RankingLocationsDocAccessFields_list_id_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RankingLocationsDocAccessFields_list_id_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RankingLocationsDocAccessFields_region"]: AliasType<{
	create?:ValueTypes["RankingLocationsDocAccessFields_region_Create"],
	read?:ValueTypes["RankingLocationsDocAccessFields_region_Read"],
	update?:ValueTypes["RankingLocationsDocAccessFields_region_Update"],
	delete?:ValueTypes["RankingLocationsDocAccessFields_region_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["RankingLocationsDocAccessFields_region_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RankingLocationsDocAccessFields_region_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RankingLocationsDocAccessFields_region_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RankingLocationsDocAccessFields_region_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RankingLocationsDocAccessFields_updatedAt"]: AliasType<{
	create?:ValueTypes["RankingLocationsDocAccessFields_updatedAt_Create"],
	read?:ValueTypes["RankingLocationsDocAccessFields_updatedAt_Read"],
	update?:ValueTypes["RankingLocationsDocAccessFields_updatedAt_Update"],
	delete?:ValueTypes["RankingLocationsDocAccessFields_updatedAt_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["RankingLocationsDocAccessFields_updatedAt_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RankingLocationsDocAccessFields_updatedAt_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RankingLocationsDocAccessFields_updatedAt_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RankingLocationsDocAccessFields_updatedAt_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RankingLocationsDocAccessFields_createdAt"]: AliasType<{
	create?:ValueTypes["RankingLocationsDocAccessFields_createdAt_Create"],
	read?:ValueTypes["RankingLocationsDocAccessFields_createdAt_Read"],
	update?:ValueTypes["RankingLocationsDocAccessFields_createdAt_Update"],
	delete?:ValueTypes["RankingLocationsDocAccessFields_createdAt_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["RankingLocationsDocAccessFields_createdAt_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RankingLocationsDocAccessFields_createdAt_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RankingLocationsDocAccessFields_createdAt_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RankingLocationsDocAccessFields_createdAt_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RankingLocationsCreateDocAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RankingLocationsReadDocAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RankingLocationsUpdateDocAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RankingLocationsDeleteDocAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventCalendar"]: AliasType<{
	id?:boolean | `@${string}`,
	date?:boolean | `@${string}`,
item?: [{	locale?: ValueTypes["LocaleInputType"] | undefined | null | Variable<any, string>,	fallbackLocale?: ValueTypes["FallbackLocaleInputType"] | undefined | null | Variable<any, string>},ValueTypes["Event"]],
	title?:boolean | `@${string}`,
	region?:boolean | `@${string}`,
	updatedAt?:boolean | `@${string}`,
	createdAt?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventCalendar_region"]:EventCalendar_region;
	["EventCalendars"]: AliasType<{
	docs?:ValueTypes["EventCalendar"],
	hasNextPage?:boolean | `@${string}`,
	hasPrevPage?:boolean | `@${string}`,
	limit?:boolean | `@${string}`,
	nextPage?:boolean | `@${string}`,
	offset?:boolean | `@${string}`,
	page?:boolean | `@${string}`,
	pagingCounter?:boolean | `@${string}`,
	prevPage?:boolean | `@${string}`,
	totalDocs?:boolean | `@${string}`,
	totalPages?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventCalendar_where"]: {
	date?: ValueTypes["EventCalendar_date_operator"] | undefined | null | Variable<any, string>,
	item?: ValueTypes["EventCalendar_item_operator"] | undefined | null | Variable<any, string>,
	title?: ValueTypes["EventCalendar_title_operator"] | undefined | null | Variable<any, string>,
	region?: ValueTypes["EventCalendar_region_operator"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["EventCalendar_updatedAt_operator"] | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["EventCalendar_createdAt_operator"] | undefined | null | Variable<any, string>,
	id?: ValueTypes["EventCalendar_id_operator"] | undefined | null | Variable<any, string>,
	AND?: Array<ValueTypes["EventCalendar_where_and"] | undefined | null> | undefined | null | Variable<any, string>,
	OR?: Array<ValueTypes["EventCalendar_where_or"] | undefined | null> | undefined | null | Variable<any, string>
};
	["EventCalendar_date_operator"]: {
	equals?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	not_equals?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	greater_than_equal?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	greater_than?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	less_than_equal?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	less_than?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	like?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>
};
	["EventCalendar_item_operator"]: {
	equals?: ValueTypes["JSON"] | undefined | null | Variable<any, string>,
	not_equals?: ValueTypes["JSON"] | undefined | null | Variable<any, string>,
	in?: Array<ValueTypes["JSON"] | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<ValueTypes["JSON"] | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<ValueTypes["JSON"] | undefined | null> | undefined | null | Variable<any, string>
};
	["EventCalendar_title_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["EventCalendar_region_operator"]: {
	equals?: ValueTypes["EventCalendar_region_Input"] | undefined | null | Variable<any, string>,
	not_equals?: ValueTypes["EventCalendar_region_Input"] | undefined | null | Variable<any, string>,
	in?: Array<ValueTypes["EventCalendar_region_Input"] | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<ValueTypes["EventCalendar_region_Input"] | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<ValueTypes["EventCalendar_region_Input"] | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["EventCalendar_region_Input"]:EventCalendar_region_Input;
	["EventCalendar_updatedAt_operator"]: {
	equals?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	not_equals?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	greater_than_equal?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	greater_than?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	less_than_equal?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	less_than?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	like?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["EventCalendar_createdAt_operator"]: {
	equals?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	not_equals?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	greater_than_equal?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	greater_than?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	less_than_equal?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	less_than?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	like?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["EventCalendar_id_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["EventCalendar_where_and"]: {
	date?: ValueTypes["EventCalendar_date_operator"] | undefined | null | Variable<any, string>,
	item?: ValueTypes["EventCalendar_item_operator"] | undefined | null | Variable<any, string>,
	title?: ValueTypes["EventCalendar_title_operator"] | undefined | null | Variable<any, string>,
	region?: ValueTypes["EventCalendar_region_operator"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["EventCalendar_updatedAt_operator"] | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["EventCalendar_createdAt_operator"] | undefined | null | Variable<any, string>,
	id?: ValueTypes["EventCalendar_id_operator"] | undefined | null | Variable<any, string>,
	AND?: Array<ValueTypes["EventCalendar_where_and"] | undefined | null> | undefined | null | Variable<any, string>,
	OR?: Array<ValueTypes["EventCalendar_where_or"] | undefined | null> | undefined | null | Variable<any, string>
};
	["EventCalendar_where_or"]: {
	date?: ValueTypes["EventCalendar_date_operator"] | undefined | null | Variable<any, string>,
	item?: ValueTypes["EventCalendar_item_operator"] | undefined | null | Variable<any, string>,
	title?: ValueTypes["EventCalendar_title_operator"] | undefined | null | Variable<any, string>,
	region?: ValueTypes["EventCalendar_region_operator"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["EventCalendar_updatedAt_operator"] | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["EventCalendar_createdAt_operator"] | undefined | null | Variable<any, string>,
	id?: ValueTypes["EventCalendar_id_operator"] | undefined | null | Variable<any, string>,
	AND?: Array<ValueTypes["EventCalendar_where_and"] | undefined | null> | undefined | null | Variable<any, string>,
	OR?: Array<ValueTypes["EventCalendar_where_or"] | undefined | null> | undefined | null | Variable<any, string>
};
	["countEventCalendars"]: AliasType<{
	totalDocs?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["event_calendarDocAccess"]: AliasType<{
	fields?:ValueTypes["EventCalendarDocAccessFields"],
	create?:ValueTypes["EventCalendarCreateDocAccess"],
	read?:ValueTypes["EventCalendarReadDocAccess"],
	update?:ValueTypes["EventCalendarUpdateDocAccess"],
	delete?:ValueTypes["EventCalendarDeleteDocAccess"],
		__typename?: boolean | `@${string}`
}>;
	["EventCalendarDocAccessFields"]: AliasType<{
	date?:ValueTypes["EventCalendarDocAccessFields_date"],
	item?:ValueTypes["EventCalendarDocAccessFields_item"],
	title?:ValueTypes["EventCalendarDocAccessFields_title"],
	region?:ValueTypes["EventCalendarDocAccessFields_region"],
	updatedAt?:ValueTypes["EventCalendarDocAccessFields_updatedAt"],
	createdAt?:ValueTypes["EventCalendarDocAccessFields_createdAt"],
		__typename?: boolean | `@${string}`
}>;
	["EventCalendarDocAccessFields_date"]: AliasType<{
	create?:ValueTypes["EventCalendarDocAccessFields_date_Create"],
	read?:ValueTypes["EventCalendarDocAccessFields_date_Read"],
	update?:ValueTypes["EventCalendarDocAccessFields_date_Update"],
	delete?:ValueTypes["EventCalendarDocAccessFields_date_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventCalendarDocAccessFields_date_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventCalendarDocAccessFields_date_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventCalendarDocAccessFields_date_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventCalendarDocAccessFields_date_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventCalendarDocAccessFields_item"]: AliasType<{
	create?:ValueTypes["EventCalendarDocAccessFields_item_Create"],
	read?:ValueTypes["EventCalendarDocAccessFields_item_Read"],
	update?:ValueTypes["EventCalendarDocAccessFields_item_Update"],
	delete?:ValueTypes["EventCalendarDocAccessFields_item_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventCalendarDocAccessFields_item_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventCalendarDocAccessFields_item_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventCalendarDocAccessFields_item_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventCalendarDocAccessFields_item_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventCalendarDocAccessFields_title"]: AliasType<{
	create?:ValueTypes["EventCalendarDocAccessFields_title_Create"],
	read?:ValueTypes["EventCalendarDocAccessFields_title_Read"],
	update?:ValueTypes["EventCalendarDocAccessFields_title_Update"],
	delete?:ValueTypes["EventCalendarDocAccessFields_title_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventCalendarDocAccessFields_title_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventCalendarDocAccessFields_title_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventCalendarDocAccessFields_title_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventCalendarDocAccessFields_title_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventCalendarDocAccessFields_region"]: AliasType<{
	create?:ValueTypes["EventCalendarDocAccessFields_region_Create"],
	read?:ValueTypes["EventCalendarDocAccessFields_region_Read"],
	update?:ValueTypes["EventCalendarDocAccessFields_region_Update"],
	delete?:ValueTypes["EventCalendarDocAccessFields_region_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventCalendarDocAccessFields_region_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventCalendarDocAccessFields_region_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventCalendarDocAccessFields_region_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventCalendarDocAccessFields_region_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventCalendarDocAccessFields_updatedAt"]: AliasType<{
	create?:ValueTypes["EventCalendarDocAccessFields_updatedAt_Create"],
	read?:ValueTypes["EventCalendarDocAccessFields_updatedAt_Read"],
	update?:ValueTypes["EventCalendarDocAccessFields_updatedAt_Update"],
	delete?:ValueTypes["EventCalendarDocAccessFields_updatedAt_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventCalendarDocAccessFields_updatedAt_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventCalendarDocAccessFields_updatedAt_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventCalendarDocAccessFields_updatedAt_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventCalendarDocAccessFields_updatedAt_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventCalendarDocAccessFields_createdAt"]: AliasType<{
	create?:ValueTypes["EventCalendarDocAccessFields_createdAt_Create"],
	read?:ValueTypes["EventCalendarDocAccessFields_createdAt_Read"],
	update?:ValueTypes["EventCalendarDocAccessFields_createdAt_Update"],
	delete?:ValueTypes["EventCalendarDocAccessFields_createdAt_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventCalendarDocAccessFields_createdAt_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventCalendarDocAccessFields_createdAt_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventCalendarDocAccessFields_createdAt_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventCalendarDocAccessFields_createdAt_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventCalendarCreateDocAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventCalendarReadDocAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventCalendarUpdateDocAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventCalendarDeleteDocAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EditorChoice"]: AliasType<{
	id?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	visible?:boolean | `@${string}`,
editorialPicks?: [{	locale?: ValueTypes["LocaleInputType"] | undefined | null | Variable<any, string>,	fallbackLocale?: ValueTypes["FallbackLocaleInputType"] | undefined | null | Variable<any, string>},ValueTypes["Event"]],
	list?:ValueTypes["EditorChoice_List"],
	region?:boolean | `@${string}`,
	updatedAt?:boolean | `@${string}`,
	createdAt?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EditorChoice_List"]: AliasType<{
item?: [{	locale?: ValueTypes["LocaleInputType"] | undefined | null | Variable<any, string>,	fallbackLocale?: ValueTypes["FallbackLocaleInputType"] | undefined | null | Variable<any, string>},ValueTypes["Event"]],
	id?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EditorChoice_region"]:EditorChoice_region;
	["EditorChoices"]: AliasType<{
	docs?:ValueTypes["EditorChoice"],
	hasNextPage?:boolean | `@${string}`,
	hasPrevPage?:boolean | `@${string}`,
	limit?:boolean | `@${string}`,
	nextPage?:boolean | `@${string}`,
	offset?:boolean | `@${string}`,
	page?:boolean | `@${string}`,
	pagingCounter?:boolean | `@${string}`,
	prevPage?:boolean | `@${string}`,
	totalDocs?:boolean | `@${string}`,
	totalPages?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EditorChoice_where"]: {
	name?: ValueTypes["EditorChoice_name_operator"] | undefined | null | Variable<any, string>,
	visible?: ValueTypes["EditorChoice_visible_operator"] | undefined | null | Variable<any, string>,
	editorialPicks?: ValueTypes["EditorChoice_editorialPicks_operator"] | undefined | null | Variable<any, string>,
	list__item?: ValueTypes["EditorChoice_list__item_operator"] | undefined | null | Variable<any, string>,
	list__id?: ValueTypes["EditorChoice_list__id_operator"] | undefined | null | Variable<any, string>,
	region?: ValueTypes["EditorChoice_region_operator"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["EditorChoice_updatedAt_operator"] | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["EditorChoice_createdAt_operator"] | undefined | null | Variable<any, string>,
	id?: ValueTypes["EditorChoice_id_operator"] | undefined | null | Variable<any, string>,
	AND?: Array<ValueTypes["EditorChoice_where_and"] | undefined | null> | undefined | null | Variable<any, string>,
	OR?: Array<ValueTypes["EditorChoice_where_or"] | undefined | null> | undefined | null | Variable<any, string>
};
	["EditorChoice_name_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>
};
	["EditorChoice_visible_operator"]: {
	equals?: boolean | undefined | null | Variable<any, string>,
	not_equals?: boolean | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["EditorChoice_editorialPicks_operator"]: {
	equals?: ValueTypes["JSON"] | undefined | null | Variable<any, string>,
	not_equals?: ValueTypes["JSON"] | undefined | null | Variable<any, string>,
	in?: Array<ValueTypes["JSON"] | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<ValueTypes["JSON"] | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<ValueTypes["JSON"] | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["EditorChoice_list__item_operator"]: {
	equals?: ValueTypes["JSON"] | undefined | null | Variable<any, string>,
	not_equals?: ValueTypes["JSON"] | undefined | null | Variable<any, string>,
	in?: Array<ValueTypes["JSON"] | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<ValueTypes["JSON"] | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<ValueTypes["JSON"] | undefined | null> | undefined | null | Variable<any, string>
};
	["EditorChoice_list__id_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["EditorChoice_region_operator"]: {
	equals?: ValueTypes["EditorChoice_region_Input"] | undefined | null | Variable<any, string>,
	not_equals?: ValueTypes["EditorChoice_region_Input"] | undefined | null | Variable<any, string>,
	in?: Array<ValueTypes["EditorChoice_region_Input"] | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<ValueTypes["EditorChoice_region_Input"] | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<ValueTypes["EditorChoice_region_Input"] | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["EditorChoice_region_Input"]:EditorChoice_region_Input;
	["EditorChoice_updatedAt_operator"]: {
	equals?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	not_equals?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	greater_than_equal?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	greater_than?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	less_than_equal?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	less_than?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	like?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["EditorChoice_createdAt_operator"]: {
	equals?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	not_equals?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	greater_than_equal?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	greater_than?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	less_than_equal?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	less_than?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	like?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["EditorChoice_id_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["EditorChoice_where_and"]: {
	name?: ValueTypes["EditorChoice_name_operator"] | undefined | null | Variable<any, string>,
	visible?: ValueTypes["EditorChoice_visible_operator"] | undefined | null | Variable<any, string>,
	editorialPicks?: ValueTypes["EditorChoice_editorialPicks_operator"] | undefined | null | Variable<any, string>,
	list__item?: ValueTypes["EditorChoice_list__item_operator"] | undefined | null | Variable<any, string>,
	list__id?: ValueTypes["EditorChoice_list__id_operator"] | undefined | null | Variable<any, string>,
	region?: ValueTypes["EditorChoice_region_operator"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["EditorChoice_updatedAt_operator"] | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["EditorChoice_createdAt_operator"] | undefined | null | Variable<any, string>,
	id?: ValueTypes["EditorChoice_id_operator"] | undefined | null | Variable<any, string>,
	AND?: Array<ValueTypes["EditorChoice_where_and"] | undefined | null> | undefined | null | Variable<any, string>,
	OR?: Array<ValueTypes["EditorChoice_where_or"] | undefined | null> | undefined | null | Variable<any, string>
};
	["EditorChoice_where_or"]: {
	name?: ValueTypes["EditorChoice_name_operator"] | undefined | null | Variable<any, string>,
	visible?: ValueTypes["EditorChoice_visible_operator"] | undefined | null | Variable<any, string>,
	editorialPicks?: ValueTypes["EditorChoice_editorialPicks_operator"] | undefined | null | Variable<any, string>,
	list__item?: ValueTypes["EditorChoice_list__item_operator"] | undefined | null | Variable<any, string>,
	list__id?: ValueTypes["EditorChoice_list__id_operator"] | undefined | null | Variable<any, string>,
	region?: ValueTypes["EditorChoice_region_operator"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["EditorChoice_updatedAt_operator"] | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["EditorChoice_createdAt_operator"] | undefined | null | Variable<any, string>,
	id?: ValueTypes["EditorChoice_id_operator"] | undefined | null | Variable<any, string>,
	AND?: Array<ValueTypes["EditorChoice_where_and"] | undefined | null> | undefined | null | Variable<any, string>,
	OR?: Array<ValueTypes["EditorChoice_where_or"] | undefined | null> | undefined | null | Variable<any, string>
};
	["countEditorChoices"]: AliasType<{
	totalDocs?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["editor_choicesDocAccess"]: AliasType<{
	fields?:ValueTypes["EditorChoicesDocAccessFields"],
	create?:ValueTypes["EditorChoicesCreateDocAccess"],
	read?:ValueTypes["EditorChoicesReadDocAccess"],
	update?:ValueTypes["EditorChoicesUpdateDocAccess"],
	delete?:ValueTypes["EditorChoicesDeleteDocAccess"],
		__typename?: boolean | `@${string}`
}>;
	["EditorChoicesDocAccessFields"]: AliasType<{
	name?:ValueTypes["EditorChoicesDocAccessFields_name"],
	visible?:ValueTypes["EditorChoicesDocAccessFields_visible"],
	editorialPicks?:ValueTypes["EditorChoicesDocAccessFields_editorialPicks"],
	list?:ValueTypes["EditorChoicesDocAccessFields_list"],
	region?:ValueTypes["EditorChoicesDocAccessFields_region"],
	updatedAt?:ValueTypes["EditorChoicesDocAccessFields_updatedAt"],
	createdAt?:ValueTypes["EditorChoicesDocAccessFields_createdAt"],
		__typename?: boolean | `@${string}`
}>;
	["EditorChoicesDocAccessFields_name"]: AliasType<{
	create?:ValueTypes["EditorChoicesDocAccessFields_name_Create"],
	read?:ValueTypes["EditorChoicesDocAccessFields_name_Read"],
	update?:ValueTypes["EditorChoicesDocAccessFields_name_Update"],
	delete?:ValueTypes["EditorChoicesDocAccessFields_name_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EditorChoicesDocAccessFields_name_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EditorChoicesDocAccessFields_name_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EditorChoicesDocAccessFields_name_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EditorChoicesDocAccessFields_name_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EditorChoicesDocAccessFields_visible"]: AliasType<{
	create?:ValueTypes["EditorChoicesDocAccessFields_visible_Create"],
	read?:ValueTypes["EditorChoicesDocAccessFields_visible_Read"],
	update?:ValueTypes["EditorChoicesDocAccessFields_visible_Update"],
	delete?:ValueTypes["EditorChoicesDocAccessFields_visible_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EditorChoicesDocAccessFields_visible_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EditorChoicesDocAccessFields_visible_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EditorChoicesDocAccessFields_visible_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EditorChoicesDocAccessFields_visible_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EditorChoicesDocAccessFields_editorialPicks"]: AliasType<{
	create?:ValueTypes["EditorChoicesDocAccessFields_editorialPicks_Create"],
	read?:ValueTypes["EditorChoicesDocAccessFields_editorialPicks_Read"],
	update?:ValueTypes["EditorChoicesDocAccessFields_editorialPicks_Update"],
	delete?:ValueTypes["EditorChoicesDocAccessFields_editorialPicks_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EditorChoicesDocAccessFields_editorialPicks_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EditorChoicesDocAccessFields_editorialPicks_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EditorChoicesDocAccessFields_editorialPicks_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EditorChoicesDocAccessFields_editorialPicks_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EditorChoicesDocAccessFields_list"]: AliasType<{
	create?:ValueTypes["EditorChoicesDocAccessFields_list_Create"],
	read?:ValueTypes["EditorChoicesDocAccessFields_list_Read"],
	update?:ValueTypes["EditorChoicesDocAccessFields_list_Update"],
	delete?:ValueTypes["EditorChoicesDocAccessFields_list_Delete"],
	fields?:ValueTypes["EditorChoicesDocAccessFields_list_Fields"],
		__typename?: boolean | `@${string}`
}>;
	["EditorChoicesDocAccessFields_list_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EditorChoicesDocAccessFields_list_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EditorChoicesDocAccessFields_list_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EditorChoicesDocAccessFields_list_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EditorChoicesDocAccessFields_list_Fields"]: AliasType<{
	item?:ValueTypes["EditorChoicesDocAccessFields_list_item"],
	id?:ValueTypes["EditorChoicesDocAccessFields_list_id"],
		__typename?: boolean | `@${string}`
}>;
	["EditorChoicesDocAccessFields_list_item"]: AliasType<{
	create?:ValueTypes["EditorChoicesDocAccessFields_list_item_Create"],
	read?:ValueTypes["EditorChoicesDocAccessFields_list_item_Read"],
	update?:ValueTypes["EditorChoicesDocAccessFields_list_item_Update"],
	delete?:ValueTypes["EditorChoicesDocAccessFields_list_item_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EditorChoicesDocAccessFields_list_item_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EditorChoicesDocAccessFields_list_item_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EditorChoicesDocAccessFields_list_item_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EditorChoicesDocAccessFields_list_item_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EditorChoicesDocAccessFields_list_id"]: AliasType<{
	create?:ValueTypes["EditorChoicesDocAccessFields_list_id_Create"],
	read?:ValueTypes["EditorChoicesDocAccessFields_list_id_Read"],
	update?:ValueTypes["EditorChoicesDocAccessFields_list_id_Update"],
	delete?:ValueTypes["EditorChoicesDocAccessFields_list_id_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EditorChoicesDocAccessFields_list_id_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EditorChoicesDocAccessFields_list_id_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EditorChoicesDocAccessFields_list_id_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EditorChoicesDocAccessFields_list_id_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EditorChoicesDocAccessFields_region"]: AliasType<{
	create?:ValueTypes["EditorChoicesDocAccessFields_region_Create"],
	read?:ValueTypes["EditorChoicesDocAccessFields_region_Read"],
	update?:ValueTypes["EditorChoicesDocAccessFields_region_Update"],
	delete?:ValueTypes["EditorChoicesDocAccessFields_region_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EditorChoicesDocAccessFields_region_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EditorChoicesDocAccessFields_region_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EditorChoicesDocAccessFields_region_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EditorChoicesDocAccessFields_region_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EditorChoicesDocAccessFields_updatedAt"]: AliasType<{
	create?:ValueTypes["EditorChoicesDocAccessFields_updatedAt_Create"],
	read?:ValueTypes["EditorChoicesDocAccessFields_updatedAt_Read"],
	update?:ValueTypes["EditorChoicesDocAccessFields_updatedAt_Update"],
	delete?:ValueTypes["EditorChoicesDocAccessFields_updatedAt_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EditorChoicesDocAccessFields_updatedAt_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EditorChoicesDocAccessFields_updatedAt_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EditorChoicesDocAccessFields_updatedAt_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EditorChoicesDocAccessFields_updatedAt_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EditorChoicesDocAccessFields_createdAt"]: AliasType<{
	create?:ValueTypes["EditorChoicesDocAccessFields_createdAt_Create"],
	read?:ValueTypes["EditorChoicesDocAccessFields_createdAt_Read"],
	update?:ValueTypes["EditorChoicesDocAccessFields_createdAt_Update"],
	delete?:ValueTypes["EditorChoicesDocAccessFields_createdAt_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EditorChoicesDocAccessFields_createdAt_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EditorChoicesDocAccessFields_createdAt_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EditorChoicesDocAccessFields_createdAt_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EditorChoicesDocAccessFields_createdAt_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EditorChoicesCreateDocAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EditorChoicesReadDocAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EditorChoicesUpdateDocAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EditorChoicesDeleteDocAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CriteriaSetting"]: AliasType<{
	id?:boolean | `@${string}`,
	criteria?:ValueTypes["CriteriaSetting_Criteria"],
	updatedAt?:boolean | `@${string}`,
	createdAt?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CriteriaSetting_Criteria"]: AliasType<{
date?: [{	locale?: ValueTypes["LocaleInputType"] | undefined | null | Variable<any, string>,	fallbackLocale?: ValueTypes["FallbackLocaleInputType"] | undefined | null | Variable<any, string>},ValueTypes["Date"]],
audience?: [{	locale?: ValueTypes["LocaleInputType"] | undefined | null | Variable<any, string>,	fallbackLocale?: ValueTypes["FallbackLocaleInputType"] | undefined | null | Variable<any, string>},ValueTypes["Audience"]],
category?: [{	locale?: ValueTypes["LocaleInputType"] | undefined | null | Variable<any, string>,	fallbackLocale?: ValueTypes["FallbackLocaleInputType"] | undefined | null | Variable<any, string>},ValueTypes["EventCategory"]],
tag?: [{	locale?: ValueTypes["LocaleInputType"] | undefined | null | Variable<any, string>,	fallbackLocale?: ValueTypes["FallbackLocaleInputType"] | undefined | null | Variable<any, string>},ValueTypes["Tag"]],
	region?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CriteriaSetting_Criteria_region"]:CriteriaSetting_Criteria_region;
	["CriteriaSettings"]: AliasType<{
	docs?:ValueTypes["CriteriaSetting"],
	hasNextPage?:boolean | `@${string}`,
	hasPrevPage?:boolean | `@${string}`,
	limit?:boolean | `@${string}`,
	nextPage?:boolean | `@${string}`,
	offset?:boolean | `@${string}`,
	page?:boolean | `@${string}`,
	pagingCounter?:boolean | `@${string}`,
	prevPage?:boolean | `@${string}`,
	totalDocs?:boolean | `@${string}`,
	totalPages?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CriteriaSetting_where"]: {
	criteria__date?: ValueTypes["CriteriaSetting_criteria__date_operator"] | undefined | null | Variable<any, string>,
	criteria__audience?: ValueTypes["CriteriaSetting_criteria__audience_operator"] | undefined | null | Variable<any, string>,
	criteria__category?: ValueTypes["CriteriaSetting_criteria__category_operator"] | undefined | null | Variable<any, string>,
	criteria__tag?: ValueTypes["CriteriaSetting_criteria__tag_operator"] | undefined | null | Variable<any, string>,
	criteria__region?: ValueTypes["CriteriaSetting_criteria__region_operator"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["CriteriaSetting_updatedAt_operator"] | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["CriteriaSetting_createdAt_operator"] | undefined | null | Variable<any, string>,
	id?: ValueTypes["CriteriaSetting_id_operator"] | undefined | null | Variable<any, string>,
	AND?: Array<ValueTypes["CriteriaSetting_where_and"] | undefined | null> | undefined | null | Variable<any, string>,
	OR?: Array<ValueTypes["CriteriaSetting_where_or"] | undefined | null> | undefined | null | Variable<any, string>
};
	["CriteriaSetting_criteria__date_operator"]: {
	equals?: ValueTypes["JSON"] | undefined | null | Variable<any, string>,
	not_equals?: ValueTypes["JSON"] | undefined | null | Variable<any, string>,
	in?: Array<ValueTypes["JSON"] | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<ValueTypes["JSON"] | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<ValueTypes["JSON"] | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["CriteriaSetting_criteria__audience_operator"]: {
	equals?: ValueTypes["JSON"] | undefined | null | Variable<any, string>,
	not_equals?: ValueTypes["JSON"] | undefined | null | Variable<any, string>,
	in?: Array<ValueTypes["JSON"] | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<ValueTypes["JSON"] | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<ValueTypes["JSON"] | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["CriteriaSetting_criteria__category_operator"]: {
	equals?: ValueTypes["JSON"] | undefined | null | Variable<any, string>,
	not_equals?: ValueTypes["JSON"] | undefined | null | Variable<any, string>,
	in?: Array<ValueTypes["JSON"] | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<ValueTypes["JSON"] | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<ValueTypes["JSON"] | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["CriteriaSetting_criteria__tag_operator"]: {
	equals?: ValueTypes["JSON"] | undefined | null | Variable<any, string>,
	not_equals?: ValueTypes["JSON"] | undefined | null | Variable<any, string>,
	in?: Array<ValueTypes["JSON"] | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<ValueTypes["JSON"] | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<ValueTypes["JSON"] | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["CriteriaSetting_criteria__region_operator"]: {
	equals?: ValueTypes["CriteriaSetting_criteria__region_Input"] | undefined | null | Variable<any, string>,
	not_equals?: ValueTypes["CriteriaSetting_criteria__region_Input"] | undefined | null | Variable<any, string>,
	in?: Array<ValueTypes["CriteriaSetting_criteria__region_Input"] | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<ValueTypes["CriteriaSetting_criteria__region_Input"] | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<ValueTypes["CriteriaSetting_criteria__region_Input"] | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["CriteriaSetting_criteria__region_Input"]:CriteriaSetting_criteria__region_Input;
	["CriteriaSetting_updatedAt_operator"]: {
	equals?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	not_equals?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	greater_than_equal?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	greater_than?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	less_than_equal?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	less_than?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	like?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["CriteriaSetting_createdAt_operator"]: {
	equals?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	not_equals?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	greater_than_equal?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	greater_than?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	less_than_equal?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	less_than?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	like?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["CriteriaSetting_id_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["CriteriaSetting_where_and"]: {
	criteria__date?: ValueTypes["CriteriaSetting_criteria__date_operator"] | undefined | null | Variable<any, string>,
	criteria__audience?: ValueTypes["CriteriaSetting_criteria__audience_operator"] | undefined | null | Variable<any, string>,
	criteria__category?: ValueTypes["CriteriaSetting_criteria__category_operator"] | undefined | null | Variable<any, string>,
	criteria__tag?: ValueTypes["CriteriaSetting_criteria__tag_operator"] | undefined | null | Variable<any, string>,
	criteria__region?: ValueTypes["CriteriaSetting_criteria__region_operator"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["CriteriaSetting_updatedAt_operator"] | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["CriteriaSetting_createdAt_operator"] | undefined | null | Variable<any, string>,
	id?: ValueTypes["CriteriaSetting_id_operator"] | undefined | null | Variable<any, string>,
	AND?: Array<ValueTypes["CriteriaSetting_where_and"] | undefined | null> | undefined | null | Variable<any, string>,
	OR?: Array<ValueTypes["CriteriaSetting_where_or"] | undefined | null> | undefined | null | Variable<any, string>
};
	["CriteriaSetting_where_or"]: {
	criteria__date?: ValueTypes["CriteriaSetting_criteria__date_operator"] | undefined | null | Variable<any, string>,
	criteria__audience?: ValueTypes["CriteriaSetting_criteria__audience_operator"] | undefined | null | Variable<any, string>,
	criteria__category?: ValueTypes["CriteriaSetting_criteria__category_operator"] | undefined | null | Variable<any, string>,
	criteria__tag?: ValueTypes["CriteriaSetting_criteria__tag_operator"] | undefined | null | Variable<any, string>,
	criteria__region?: ValueTypes["CriteriaSetting_criteria__region_operator"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["CriteriaSetting_updatedAt_operator"] | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["CriteriaSetting_createdAt_operator"] | undefined | null | Variable<any, string>,
	id?: ValueTypes["CriteriaSetting_id_operator"] | undefined | null | Variable<any, string>,
	AND?: Array<ValueTypes["CriteriaSetting_where_and"] | undefined | null> | undefined | null | Variable<any, string>,
	OR?: Array<ValueTypes["CriteriaSetting_where_or"] | undefined | null> | undefined | null | Variable<any, string>
};
	["countCriteriaSettings"]: AliasType<{
	totalDocs?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["criteria_settingDocAccess"]: AliasType<{
	fields?:ValueTypes["CriteriaSettingDocAccessFields"],
	create?:ValueTypes["CriteriaSettingCreateDocAccess"],
	read?:ValueTypes["CriteriaSettingReadDocAccess"],
	update?:ValueTypes["CriteriaSettingUpdateDocAccess"],
	delete?:ValueTypes["CriteriaSettingDeleteDocAccess"],
		__typename?: boolean | `@${string}`
}>;
	["CriteriaSettingDocAccessFields"]: AliasType<{
	criteria?:ValueTypes["CriteriaSettingDocAccessFields_criteria"],
	updatedAt?:ValueTypes["CriteriaSettingDocAccessFields_updatedAt"],
	createdAt?:ValueTypes["CriteriaSettingDocAccessFields_createdAt"],
		__typename?: boolean | `@${string}`
}>;
	["CriteriaSettingDocAccessFields_criteria"]: AliasType<{
	create?:ValueTypes["CriteriaSettingDocAccessFields_criteria_Create"],
	read?:ValueTypes["CriteriaSettingDocAccessFields_criteria_Read"],
	update?:ValueTypes["CriteriaSettingDocAccessFields_criteria_Update"],
	delete?:ValueTypes["CriteriaSettingDocAccessFields_criteria_Delete"],
	fields?:ValueTypes["CriteriaSettingDocAccessFields_criteria_Fields"],
		__typename?: boolean | `@${string}`
}>;
	["CriteriaSettingDocAccessFields_criteria_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CriteriaSettingDocAccessFields_criteria_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CriteriaSettingDocAccessFields_criteria_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CriteriaSettingDocAccessFields_criteria_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CriteriaSettingDocAccessFields_criteria_Fields"]: AliasType<{
	date?:ValueTypes["CriteriaSettingDocAccessFields_criteria_date"],
	audience?:ValueTypes["CriteriaSettingDocAccessFields_criteria_audience"],
	category?:ValueTypes["CriteriaSettingDocAccessFields_criteria_category"],
	tag?:ValueTypes["CriteriaSettingDocAccessFields_criteria_tag"],
	region?:ValueTypes["CriteriaSettingDocAccessFields_criteria_region"],
		__typename?: boolean | `@${string}`
}>;
	["CriteriaSettingDocAccessFields_criteria_date"]: AliasType<{
	create?:ValueTypes["CriteriaSettingDocAccessFields_criteria_date_Create"],
	read?:ValueTypes["CriteriaSettingDocAccessFields_criteria_date_Read"],
	update?:ValueTypes["CriteriaSettingDocAccessFields_criteria_date_Update"],
	delete?:ValueTypes["CriteriaSettingDocAccessFields_criteria_date_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["CriteriaSettingDocAccessFields_criteria_date_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CriteriaSettingDocAccessFields_criteria_date_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CriteriaSettingDocAccessFields_criteria_date_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CriteriaSettingDocAccessFields_criteria_date_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CriteriaSettingDocAccessFields_criteria_audience"]: AliasType<{
	create?:ValueTypes["CriteriaSettingDocAccessFields_criteria_audience_Create"],
	read?:ValueTypes["CriteriaSettingDocAccessFields_criteria_audience_Read"],
	update?:ValueTypes["CriteriaSettingDocAccessFields_criteria_audience_Update"],
	delete?:ValueTypes["CriteriaSettingDocAccessFields_criteria_audience_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["CriteriaSettingDocAccessFields_criteria_audience_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CriteriaSettingDocAccessFields_criteria_audience_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CriteriaSettingDocAccessFields_criteria_audience_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CriteriaSettingDocAccessFields_criteria_audience_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CriteriaSettingDocAccessFields_criteria_category"]: AliasType<{
	create?:ValueTypes["CriteriaSettingDocAccessFields_criteria_category_Create"],
	read?:ValueTypes["CriteriaSettingDocAccessFields_criteria_category_Read"],
	update?:ValueTypes["CriteriaSettingDocAccessFields_criteria_category_Update"],
	delete?:ValueTypes["CriteriaSettingDocAccessFields_criteria_category_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["CriteriaSettingDocAccessFields_criteria_category_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CriteriaSettingDocAccessFields_criteria_category_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CriteriaSettingDocAccessFields_criteria_category_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CriteriaSettingDocAccessFields_criteria_category_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CriteriaSettingDocAccessFields_criteria_tag"]: AliasType<{
	create?:ValueTypes["CriteriaSettingDocAccessFields_criteria_tag_Create"],
	read?:ValueTypes["CriteriaSettingDocAccessFields_criteria_tag_Read"],
	update?:ValueTypes["CriteriaSettingDocAccessFields_criteria_tag_Update"],
	delete?:ValueTypes["CriteriaSettingDocAccessFields_criteria_tag_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["CriteriaSettingDocAccessFields_criteria_tag_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CriteriaSettingDocAccessFields_criteria_tag_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CriteriaSettingDocAccessFields_criteria_tag_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CriteriaSettingDocAccessFields_criteria_tag_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CriteriaSettingDocAccessFields_criteria_region"]: AliasType<{
	create?:ValueTypes["CriteriaSettingDocAccessFields_criteria_region_Create"],
	read?:ValueTypes["CriteriaSettingDocAccessFields_criteria_region_Read"],
	update?:ValueTypes["CriteriaSettingDocAccessFields_criteria_region_Update"],
	delete?:ValueTypes["CriteriaSettingDocAccessFields_criteria_region_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["CriteriaSettingDocAccessFields_criteria_region_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CriteriaSettingDocAccessFields_criteria_region_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CriteriaSettingDocAccessFields_criteria_region_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CriteriaSettingDocAccessFields_criteria_region_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CriteriaSettingDocAccessFields_updatedAt"]: AliasType<{
	create?:ValueTypes["CriteriaSettingDocAccessFields_updatedAt_Create"],
	read?:ValueTypes["CriteriaSettingDocAccessFields_updatedAt_Read"],
	update?:ValueTypes["CriteriaSettingDocAccessFields_updatedAt_Update"],
	delete?:ValueTypes["CriteriaSettingDocAccessFields_updatedAt_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["CriteriaSettingDocAccessFields_updatedAt_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CriteriaSettingDocAccessFields_updatedAt_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CriteriaSettingDocAccessFields_updatedAt_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CriteriaSettingDocAccessFields_updatedAt_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CriteriaSettingDocAccessFields_createdAt"]: AliasType<{
	create?:ValueTypes["CriteriaSettingDocAccessFields_createdAt_Create"],
	read?:ValueTypes["CriteriaSettingDocAccessFields_createdAt_Read"],
	update?:ValueTypes["CriteriaSettingDocAccessFields_createdAt_Update"],
	delete?:ValueTypes["CriteriaSettingDocAccessFields_createdAt_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["CriteriaSettingDocAccessFields_createdAt_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CriteriaSettingDocAccessFields_createdAt_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CriteriaSettingDocAccessFields_createdAt_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CriteriaSettingDocAccessFields_createdAt_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CriteriaSettingCreateDocAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CriteriaSettingReadDocAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CriteriaSettingUpdateDocAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CriteriaSettingDeleteDocAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["OrganiserSetting"]: AliasType<{
	id?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
organiserList?: [{	locale?: ValueTypes["LocaleInputType"] | undefined | null | Variable<any, string>,	fallbackLocale?: ValueTypes["FallbackLocaleInputType"] | undefined | null | Variable<any, string>},ValueTypes["User"]],
	region?:boolean | `@${string}`,
	updatedAt?:boolean | `@${string}`,
	createdAt?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["OrganiserSetting_region"]:OrganiserSetting_region;
	["OrganiserSettings"]: AliasType<{
	docs?:ValueTypes["OrganiserSetting"],
	hasNextPage?:boolean | `@${string}`,
	hasPrevPage?:boolean | `@${string}`,
	limit?:boolean | `@${string}`,
	nextPage?:boolean | `@${string}`,
	offset?:boolean | `@${string}`,
	page?:boolean | `@${string}`,
	pagingCounter?:boolean | `@${string}`,
	prevPage?:boolean | `@${string}`,
	totalDocs?:boolean | `@${string}`,
	totalPages?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["OrganiserSetting_where"]: {
	name?: ValueTypes["OrganiserSetting_name_operator"] | undefined | null | Variable<any, string>,
	organiserList?: ValueTypes["OrganiserSetting_organiserList_operator"] | undefined | null | Variable<any, string>,
	region?: ValueTypes["OrganiserSetting_region_operator"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["OrganiserSetting_updatedAt_operator"] | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["OrganiserSetting_createdAt_operator"] | undefined | null | Variable<any, string>,
	id?: ValueTypes["OrganiserSetting_id_operator"] | undefined | null | Variable<any, string>,
	AND?: Array<ValueTypes["OrganiserSetting_where_and"] | undefined | null> | undefined | null | Variable<any, string>,
	OR?: Array<ValueTypes["OrganiserSetting_where_or"] | undefined | null> | undefined | null | Variable<any, string>
};
	["OrganiserSetting_name_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["OrganiserSetting_organiserList_operator"]: {
	equals?: ValueTypes["JSON"] | undefined | null | Variable<any, string>,
	not_equals?: ValueTypes["JSON"] | undefined | null | Variable<any, string>,
	in?: Array<ValueTypes["JSON"] | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<ValueTypes["JSON"] | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<ValueTypes["JSON"] | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["OrganiserSetting_region_operator"]: {
	equals?: ValueTypes["OrganiserSetting_region_Input"] | undefined | null | Variable<any, string>,
	not_equals?: ValueTypes["OrganiserSetting_region_Input"] | undefined | null | Variable<any, string>,
	in?: Array<ValueTypes["OrganiserSetting_region_Input"] | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<ValueTypes["OrganiserSetting_region_Input"] | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<ValueTypes["OrganiserSetting_region_Input"] | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["OrganiserSetting_region_Input"]:OrganiserSetting_region_Input;
	["OrganiserSetting_updatedAt_operator"]: {
	equals?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	not_equals?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	greater_than_equal?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	greater_than?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	less_than_equal?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	less_than?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	like?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["OrganiserSetting_createdAt_operator"]: {
	equals?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	not_equals?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	greater_than_equal?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	greater_than?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	less_than_equal?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	less_than?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	like?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["OrganiserSetting_id_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["OrganiserSetting_where_and"]: {
	name?: ValueTypes["OrganiserSetting_name_operator"] | undefined | null | Variable<any, string>,
	organiserList?: ValueTypes["OrganiserSetting_organiserList_operator"] | undefined | null | Variable<any, string>,
	region?: ValueTypes["OrganiserSetting_region_operator"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["OrganiserSetting_updatedAt_operator"] | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["OrganiserSetting_createdAt_operator"] | undefined | null | Variable<any, string>,
	id?: ValueTypes["OrganiserSetting_id_operator"] | undefined | null | Variable<any, string>,
	AND?: Array<ValueTypes["OrganiserSetting_where_and"] | undefined | null> | undefined | null | Variable<any, string>,
	OR?: Array<ValueTypes["OrganiserSetting_where_or"] | undefined | null> | undefined | null | Variable<any, string>
};
	["OrganiserSetting_where_or"]: {
	name?: ValueTypes["OrganiserSetting_name_operator"] | undefined | null | Variable<any, string>,
	organiserList?: ValueTypes["OrganiserSetting_organiserList_operator"] | undefined | null | Variable<any, string>,
	region?: ValueTypes["OrganiserSetting_region_operator"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["OrganiserSetting_updatedAt_operator"] | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["OrganiserSetting_createdAt_operator"] | undefined | null | Variable<any, string>,
	id?: ValueTypes["OrganiserSetting_id_operator"] | undefined | null | Variable<any, string>,
	AND?: Array<ValueTypes["OrganiserSetting_where_and"] | undefined | null> | undefined | null | Variable<any, string>,
	OR?: Array<ValueTypes["OrganiserSetting_where_or"] | undefined | null> | undefined | null | Variable<any, string>
};
	["countOrganiserSettings"]: AliasType<{
	totalDocs?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["organiserSettingDocAccess"]: AliasType<{
	fields?:ValueTypes["OrganiserSettingDocAccessFields"],
	create?:ValueTypes["OrganiserSettingCreateDocAccess"],
	read?:ValueTypes["OrganiserSettingReadDocAccess"],
	update?:ValueTypes["OrganiserSettingUpdateDocAccess"],
	delete?:ValueTypes["OrganiserSettingDeleteDocAccess"],
		__typename?: boolean | `@${string}`
}>;
	["OrganiserSettingDocAccessFields"]: AliasType<{
	name?:ValueTypes["OrganiserSettingDocAccessFields_name"],
	organiserList?:ValueTypes["OrganiserSettingDocAccessFields_organiserList"],
	region?:ValueTypes["OrganiserSettingDocAccessFields_region"],
	updatedAt?:ValueTypes["OrganiserSettingDocAccessFields_updatedAt"],
	createdAt?:ValueTypes["OrganiserSettingDocAccessFields_createdAt"],
		__typename?: boolean | `@${string}`
}>;
	["OrganiserSettingDocAccessFields_name"]: AliasType<{
	create?:ValueTypes["OrganiserSettingDocAccessFields_name_Create"],
	read?:ValueTypes["OrganiserSettingDocAccessFields_name_Read"],
	update?:ValueTypes["OrganiserSettingDocAccessFields_name_Update"],
	delete?:ValueTypes["OrganiserSettingDocAccessFields_name_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["OrganiserSettingDocAccessFields_name_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["OrganiserSettingDocAccessFields_name_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["OrganiserSettingDocAccessFields_name_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["OrganiserSettingDocAccessFields_name_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["OrganiserSettingDocAccessFields_organiserList"]: AliasType<{
	create?:ValueTypes["OrganiserSettingDocAccessFields_organiserList_Create"],
	read?:ValueTypes["OrganiserSettingDocAccessFields_organiserList_Read"],
	update?:ValueTypes["OrganiserSettingDocAccessFields_organiserList_Update"],
	delete?:ValueTypes["OrganiserSettingDocAccessFields_organiserList_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["OrganiserSettingDocAccessFields_organiserList_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["OrganiserSettingDocAccessFields_organiserList_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["OrganiserSettingDocAccessFields_organiserList_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["OrganiserSettingDocAccessFields_organiserList_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["OrganiserSettingDocAccessFields_region"]: AliasType<{
	create?:ValueTypes["OrganiserSettingDocAccessFields_region_Create"],
	read?:ValueTypes["OrganiserSettingDocAccessFields_region_Read"],
	update?:ValueTypes["OrganiserSettingDocAccessFields_region_Update"],
	delete?:ValueTypes["OrganiserSettingDocAccessFields_region_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["OrganiserSettingDocAccessFields_region_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["OrganiserSettingDocAccessFields_region_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["OrganiserSettingDocAccessFields_region_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["OrganiserSettingDocAccessFields_region_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["OrganiserSettingDocAccessFields_updatedAt"]: AliasType<{
	create?:ValueTypes["OrganiserSettingDocAccessFields_updatedAt_Create"],
	read?:ValueTypes["OrganiserSettingDocAccessFields_updatedAt_Read"],
	update?:ValueTypes["OrganiserSettingDocAccessFields_updatedAt_Update"],
	delete?:ValueTypes["OrganiserSettingDocAccessFields_updatedAt_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["OrganiserSettingDocAccessFields_updatedAt_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["OrganiserSettingDocAccessFields_updatedAt_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["OrganiserSettingDocAccessFields_updatedAt_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["OrganiserSettingDocAccessFields_updatedAt_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["OrganiserSettingDocAccessFields_createdAt"]: AliasType<{
	create?:ValueTypes["OrganiserSettingDocAccessFields_createdAt_Create"],
	read?:ValueTypes["OrganiserSettingDocAccessFields_createdAt_Read"],
	update?:ValueTypes["OrganiserSettingDocAccessFields_createdAt_Update"],
	delete?:ValueTypes["OrganiserSettingDocAccessFields_createdAt_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["OrganiserSettingDocAccessFields_createdAt_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["OrganiserSettingDocAccessFields_createdAt_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["OrganiserSettingDocAccessFields_createdAt_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["OrganiserSettingDocAccessFields_createdAt_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["OrganiserSettingCreateDocAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["OrganiserSettingReadDocAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["OrganiserSettingUpdateDocAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["OrganiserSettingDeleteDocAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostSetting"]: AliasType<{
	id?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
featuredPosts?: [{	locale?: ValueTypes["LocaleInputType"] | undefined | null | Variable<any, string>,	fallbackLocale?: ValueTypes["FallbackLocaleInputType"] | undefined | null | Variable<any, string>},ValueTypes["Post"]],
	region?:boolean | `@${string}`,
	updatedAt?:boolean | `@${string}`,
	createdAt?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostSetting_region"]:PostSetting_region;
	["PostSettings"]: AliasType<{
	docs?:ValueTypes["PostSetting"],
	hasNextPage?:boolean | `@${string}`,
	hasPrevPage?:boolean | `@${string}`,
	limit?:boolean | `@${string}`,
	nextPage?:boolean | `@${string}`,
	offset?:boolean | `@${string}`,
	page?:boolean | `@${string}`,
	pagingCounter?:boolean | `@${string}`,
	prevPage?:boolean | `@${string}`,
	totalDocs?:boolean | `@${string}`,
	totalPages?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostSetting_where"]: {
	name?: ValueTypes["PostSetting_name_operator"] | undefined | null | Variable<any, string>,
	featuredPosts?: ValueTypes["PostSetting_featuredPosts_operator"] | undefined | null | Variable<any, string>,
	region?: ValueTypes["PostSetting_region_operator"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["PostSetting_updatedAt_operator"] | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["PostSetting_createdAt_operator"] | undefined | null | Variable<any, string>,
	id?: ValueTypes["PostSetting_id_operator"] | undefined | null | Variable<any, string>,
	AND?: Array<ValueTypes["PostSetting_where_and"] | undefined | null> | undefined | null | Variable<any, string>,
	OR?: Array<ValueTypes["PostSetting_where_or"] | undefined | null> | undefined | null | Variable<any, string>
};
	["PostSetting_name_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["PostSetting_featuredPosts_operator"]: {
	equals?: ValueTypes["JSON"] | undefined | null | Variable<any, string>,
	not_equals?: ValueTypes["JSON"] | undefined | null | Variable<any, string>,
	in?: Array<ValueTypes["JSON"] | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<ValueTypes["JSON"] | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<ValueTypes["JSON"] | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["PostSetting_region_operator"]: {
	equals?: ValueTypes["PostSetting_region_Input"] | undefined | null | Variable<any, string>,
	not_equals?: ValueTypes["PostSetting_region_Input"] | undefined | null | Variable<any, string>,
	in?: Array<ValueTypes["PostSetting_region_Input"] | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<ValueTypes["PostSetting_region_Input"] | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<ValueTypes["PostSetting_region_Input"] | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["PostSetting_region_Input"]:PostSetting_region_Input;
	["PostSetting_updatedAt_operator"]: {
	equals?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	not_equals?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	greater_than_equal?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	greater_than?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	less_than_equal?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	less_than?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	like?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["PostSetting_createdAt_operator"]: {
	equals?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	not_equals?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	greater_than_equal?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	greater_than?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	less_than_equal?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	less_than?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	like?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["PostSetting_id_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["PostSetting_where_and"]: {
	name?: ValueTypes["PostSetting_name_operator"] | undefined | null | Variable<any, string>,
	featuredPosts?: ValueTypes["PostSetting_featuredPosts_operator"] | undefined | null | Variable<any, string>,
	region?: ValueTypes["PostSetting_region_operator"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["PostSetting_updatedAt_operator"] | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["PostSetting_createdAt_operator"] | undefined | null | Variable<any, string>,
	id?: ValueTypes["PostSetting_id_operator"] | undefined | null | Variable<any, string>,
	AND?: Array<ValueTypes["PostSetting_where_and"] | undefined | null> | undefined | null | Variable<any, string>,
	OR?: Array<ValueTypes["PostSetting_where_or"] | undefined | null> | undefined | null | Variable<any, string>
};
	["PostSetting_where_or"]: {
	name?: ValueTypes["PostSetting_name_operator"] | undefined | null | Variable<any, string>,
	featuredPosts?: ValueTypes["PostSetting_featuredPosts_operator"] | undefined | null | Variable<any, string>,
	region?: ValueTypes["PostSetting_region_operator"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["PostSetting_updatedAt_operator"] | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["PostSetting_createdAt_operator"] | undefined | null | Variable<any, string>,
	id?: ValueTypes["PostSetting_id_operator"] | undefined | null | Variable<any, string>,
	AND?: Array<ValueTypes["PostSetting_where_and"] | undefined | null> | undefined | null | Variable<any, string>,
	OR?: Array<ValueTypes["PostSetting_where_or"] | undefined | null> | undefined | null | Variable<any, string>
};
	["countPostSettings"]: AliasType<{
	totalDocs?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["post_settingDocAccess"]: AliasType<{
	fields?:ValueTypes["PostSettingDocAccessFields"],
	create?:ValueTypes["PostSettingCreateDocAccess"],
	read?:ValueTypes["PostSettingReadDocAccess"],
	update?:ValueTypes["PostSettingUpdateDocAccess"],
	delete?:ValueTypes["PostSettingDeleteDocAccess"],
		__typename?: boolean | `@${string}`
}>;
	["PostSettingDocAccessFields"]: AliasType<{
	name?:ValueTypes["PostSettingDocAccessFields_name"],
	featuredPosts?:ValueTypes["PostSettingDocAccessFields_featuredPosts"],
	region?:ValueTypes["PostSettingDocAccessFields_region"],
	updatedAt?:ValueTypes["PostSettingDocAccessFields_updatedAt"],
	createdAt?:ValueTypes["PostSettingDocAccessFields_createdAt"],
		__typename?: boolean | `@${string}`
}>;
	["PostSettingDocAccessFields_name"]: AliasType<{
	create?:ValueTypes["PostSettingDocAccessFields_name_Create"],
	read?:ValueTypes["PostSettingDocAccessFields_name_Read"],
	update?:ValueTypes["PostSettingDocAccessFields_name_Update"],
	delete?:ValueTypes["PostSettingDocAccessFields_name_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["PostSettingDocAccessFields_name_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostSettingDocAccessFields_name_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostSettingDocAccessFields_name_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostSettingDocAccessFields_name_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostSettingDocAccessFields_featuredPosts"]: AliasType<{
	create?:ValueTypes["PostSettingDocAccessFields_featuredPosts_Create"],
	read?:ValueTypes["PostSettingDocAccessFields_featuredPosts_Read"],
	update?:ValueTypes["PostSettingDocAccessFields_featuredPosts_Update"],
	delete?:ValueTypes["PostSettingDocAccessFields_featuredPosts_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["PostSettingDocAccessFields_featuredPosts_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostSettingDocAccessFields_featuredPosts_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostSettingDocAccessFields_featuredPosts_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostSettingDocAccessFields_featuredPosts_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostSettingDocAccessFields_region"]: AliasType<{
	create?:ValueTypes["PostSettingDocAccessFields_region_Create"],
	read?:ValueTypes["PostSettingDocAccessFields_region_Read"],
	update?:ValueTypes["PostSettingDocAccessFields_region_Update"],
	delete?:ValueTypes["PostSettingDocAccessFields_region_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["PostSettingDocAccessFields_region_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostSettingDocAccessFields_region_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostSettingDocAccessFields_region_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostSettingDocAccessFields_region_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostSettingDocAccessFields_updatedAt"]: AliasType<{
	create?:ValueTypes["PostSettingDocAccessFields_updatedAt_Create"],
	read?:ValueTypes["PostSettingDocAccessFields_updatedAt_Read"],
	update?:ValueTypes["PostSettingDocAccessFields_updatedAt_Update"],
	delete?:ValueTypes["PostSettingDocAccessFields_updatedAt_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["PostSettingDocAccessFields_updatedAt_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostSettingDocAccessFields_updatedAt_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostSettingDocAccessFields_updatedAt_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostSettingDocAccessFields_updatedAt_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostSettingDocAccessFields_createdAt"]: AliasType<{
	create?:ValueTypes["PostSettingDocAccessFields_createdAt_Create"],
	read?:ValueTypes["PostSettingDocAccessFields_createdAt_Read"],
	update?:ValueTypes["PostSettingDocAccessFields_createdAt_Update"],
	delete?:ValueTypes["PostSettingDocAccessFields_createdAt_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["PostSettingDocAccessFields_createdAt_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostSettingDocAccessFields_createdAt_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostSettingDocAccessFields_createdAt_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostSettingDocAccessFields_createdAt_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostSettingCreateDocAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostSettingReadDocAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostSettingUpdateDocAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostSettingDeleteDocAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["HomepageSetting"]: AliasType<{
	id?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	region?:boolean | `@${string}`,
	sections?:ValueTypes["HomepageSetting_Sections"],
	updatedAt?:boolean | `@${string}`,
	createdAt?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["HomepageSetting_region"]:HomepageSetting_region;
	["HomepageSetting_Sections"]: AliasType<{		["...on ComingWeekEventAndLocationRankingBlock"]?: ValueTypes["ComingWeekEventAndLocationRankingBlock"],
		["...on CategoryListBlock"]?: ValueTypes["CategoryListBlock"],
		["...on UserPreferenceBlock"]?: ValueTypes["UserPreferenceBlock"],
		["...on EventRankingBlock"]?: ValueTypes["EventRankingBlock"],
		["...on EventTrendBlock"]?: ValueTypes["EventTrendBlock"],
		["...on LocationRankingBlock"]?: ValueTypes["LocationRankingBlock"],
		["...on OrganizarBlock"]?: ValueTypes["OrganizarBlock"],
		["...on SubscriptionBlock"]?: ValueTypes["SubscriptionBlock"],
		["...on TimableRankingBlock"]?: ValueTypes["TimableRankingBlock"],
		["...on MultipleEventRankingBlock"]?: ValueTypes["MultipleEventRankingBlock"],
		["...on TicketingBlock"]?: ValueTypes["TicketingBlock"],
		["...on BlogBlock"]?: ValueTypes["BlogBlock"],
		["...on EditorChoiceBlock"]?: ValueTypes["EditorChoiceBlock"]
		__typename?: boolean | `@${string}`
}>;
	["ComingWeekEventAndLocationRankingBlock"]: AliasType<{
	title?:boolean | `@${string}`,
	locationRankingTitle?:boolean | `@${string}`,
	appearance?:boolean | `@${string}`,
	byRegion?:boolean | `@${string}`,
rankings?: [{	locale?: ValueTypes["LocaleInputType"] | undefined | null | Variable<any, string>,	fallbackLocale?: ValueTypes["FallbackLocaleInputType"] | undefined | null | Variable<any, string>},ValueTypes["LocationRanking"]],
	id?:boolean | `@${string}`,
	blockName?:boolean | `@${string}`,
	blockType?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ComingWeekEventAndLocationRankingBlock_appearance"]:ComingWeekEventAndLocationRankingBlock_appearance;
	["ComingWeekEventAndLocationRankingBlock_byRegion"]:ComingWeekEventAndLocationRankingBlock_byRegion;
	["CategoryListBlock"]: AliasType<{
	title?:boolean | `@${string}`,
	id?:boolean | `@${string}`,
	blockName?:boolean | `@${string}`,
	blockType?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UserPreferenceBlock"]: AliasType<{
	title?:boolean | `@${string}`,
	id?:boolean | `@${string}`,
	blockName?:boolean | `@${string}`,
	blockType?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventRankingBlock"]: AliasType<{
	title?:boolean | `@${string}`,
	appearance?:boolean | `@${string}`,
ranking?: [{	locale?: ValueTypes["LocaleInputType"] | undefined | null | Variable<any, string>,	fallbackLocale?: ValueTypes["FallbackLocaleInputType"] | undefined | null | Variable<any, string>},ValueTypes["EventRanking"]],
	id?:boolean | `@${string}`,
	blockName?:boolean | `@${string}`,
	blockType?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventRankingBlock_appearance"]:EventRankingBlock_appearance;
	["EventTrendBlock"]: AliasType<{
	title?:boolean | `@${string}`,
	id?:boolean | `@${string}`,
	blockName?:boolean | `@${string}`,
	blockType?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationRankingBlock"]: AliasType<{
	title?:boolean | `@${string}`,
	appearance?:boolean | `@${string}`,
ranking?: [{	locale?: ValueTypes["LocaleInputType"] | undefined | null | Variable<any, string>,	fallbackLocale?: ValueTypes["FallbackLocaleInputType"] | undefined | null | Variable<any, string>},ValueTypes["LocationRanking"]],
	id?:boolean | `@${string}`,
	blockName?:boolean | `@${string}`,
	blockType?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationRankingBlock_appearance"]:LocationRankingBlock_appearance;
	["OrganizarBlock"]: AliasType<{
	title?:boolean | `@${string}`,
	id?:boolean | `@${string}`,
	blockName?:boolean | `@${string}`,
	blockType?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["SubscriptionBlock"]: AliasType<{
	title?:boolean | `@${string}`,
	id?:boolean | `@${string}`,
	blockName?:boolean | `@${string}`,
	blockType?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TimableRankingBlock"]: AliasType<{
	title?:boolean | `@${string}`,
ranking?: [{	locale?: ValueTypes["LocaleInputType"] | undefined | null | Variable<any, string>,	fallbackLocale?: ValueTypes["FallbackLocaleInputType"] | undefined | null | Variable<any, string>},ValueTypes["LocationRanking"]],
	id?:boolean | `@${string}`,
	blockName?:boolean | `@${string}`,
	blockType?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MultipleEventRankingBlock"]: AliasType<{
	title?:boolean | `@${string}`,
ranking?: [{	locale?: ValueTypes["LocaleInputType"] | undefined | null | Variable<any, string>,	fallbackLocale?: ValueTypes["FallbackLocaleInputType"] | undefined | null | Variable<any, string>},ValueTypes["EventRanking"]],
	id?:boolean | `@${string}`,
	blockName?:boolean | `@${string}`,
	blockType?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingBlock"]: AliasType<{
	title?:boolean | `@${string}`,
	id?:boolean | `@${string}`,
	blockName?:boolean | `@${string}`,
	blockType?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BlogBlock"]: AliasType<{
	title?:boolean | `@${string}`,
	id?:boolean | `@${string}`,
	blockName?:boolean | `@${string}`,
	blockType?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EditorChoiceBlock"]: AliasType<{
	title?:boolean | `@${string}`,
	id?:boolean | `@${string}`,
	blockName?:boolean | `@${string}`,
	blockType?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["HomepageSettings"]: AliasType<{
	docs?:ValueTypes["HomepageSetting"],
	hasNextPage?:boolean | `@${string}`,
	hasPrevPage?:boolean | `@${string}`,
	limit?:boolean | `@${string}`,
	nextPage?:boolean | `@${string}`,
	offset?:boolean | `@${string}`,
	page?:boolean | `@${string}`,
	pagingCounter?:boolean | `@${string}`,
	prevPage?:boolean | `@${string}`,
	totalDocs?:boolean | `@${string}`,
	totalPages?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["HomepageSetting_where"]: {
	name?: ValueTypes["HomepageSetting_name_operator"] | undefined | null | Variable<any, string>,
	region?: ValueTypes["HomepageSetting_region_operator"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["HomepageSetting_updatedAt_operator"] | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["HomepageSetting_createdAt_operator"] | undefined | null | Variable<any, string>,
	id?: ValueTypes["HomepageSetting_id_operator"] | undefined | null | Variable<any, string>,
	AND?: Array<ValueTypes["HomepageSetting_where_and"] | undefined | null> | undefined | null | Variable<any, string>,
	OR?: Array<ValueTypes["HomepageSetting_where_or"] | undefined | null> | undefined | null | Variable<any, string>
};
	["HomepageSetting_name_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>
};
	["HomepageSetting_region_operator"]: {
	equals?: ValueTypes["HomepageSetting_region_Input"] | undefined | null | Variable<any, string>,
	not_equals?: ValueTypes["HomepageSetting_region_Input"] | undefined | null | Variable<any, string>,
	in?: Array<ValueTypes["HomepageSetting_region_Input"] | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<ValueTypes["HomepageSetting_region_Input"] | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<ValueTypes["HomepageSetting_region_Input"] | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["HomepageSetting_region_Input"]:HomepageSetting_region_Input;
	["HomepageSetting_updatedAt_operator"]: {
	equals?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	not_equals?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	greater_than_equal?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	greater_than?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	less_than_equal?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	less_than?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	like?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["HomepageSetting_createdAt_operator"]: {
	equals?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	not_equals?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	greater_than_equal?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	greater_than?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	less_than_equal?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	less_than?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	like?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["HomepageSetting_id_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["HomepageSetting_where_and"]: {
	name?: ValueTypes["HomepageSetting_name_operator"] | undefined | null | Variable<any, string>,
	region?: ValueTypes["HomepageSetting_region_operator"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["HomepageSetting_updatedAt_operator"] | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["HomepageSetting_createdAt_operator"] | undefined | null | Variable<any, string>,
	id?: ValueTypes["HomepageSetting_id_operator"] | undefined | null | Variable<any, string>,
	AND?: Array<ValueTypes["HomepageSetting_where_and"] | undefined | null> | undefined | null | Variable<any, string>,
	OR?: Array<ValueTypes["HomepageSetting_where_or"] | undefined | null> | undefined | null | Variable<any, string>
};
	["HomepageSetting_where_or"]: {
	name?: ValueTypes["HomepageSetting_name_operator"] | undefined | null | Variable<any, string>,
	region?: ValueTypes["HomepageSetting_region_operator"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["HomepageSetting_updatedAt_operator"] | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["HomepageSetting_createdAt_operator"] | undefined | null | Variable<any, string>,
	id?: ValueTypes["HomepageSetting_id_operator"] | undefined | null | Variable<any, string>,
	AND?: Array<ValueTypes["HomepageSetting_where_and"] | undefined | null> | undefined | null | Variable<any, string>,
	OR?: Array<ValueTypes["HomepageSetting_where_or"] | undefined | null> | undefined | null | Variable<any, string>
};
	["countHomepageSettings"]: AliasType<{
	totalDocs?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["homepageSettingDocAccess"]: AliasType<{
	fields?:ValueTypes["HomepageSettingDocAccessFields"],
	create?:ValueTypes["HomepageSettingCreateDocAccess"],
	read?:ValueTypes["HomepageSettingReadDocAccess"],
	update?:ValueTypes["HomepageSettingUpdateDocAccess"],
	delete?:ValueTypes["HomepageSettingDeleteDocAccess"],
		__typename?: boolean | `@${string}`
}>;
	["HomepageSettingDocAccessFields"]: AliasType<{
	name?:ValueTypes["HomepageSettingDocAccessFields_name"],
	region?:ValueTypes["HomepageSettingDocAccessFields_region"],
	sections?:ValueTypes["HomepageSettingDocAccessFields_sections"],
	updatedAt?:ValueTypes["HomepageSettingDocAccessFields_updatedAt"],
	createdAt?:ValueTypes["HomepageSettingDocAccessFields_createdAt"],
		__typename?: boolean | `@${string}`
}>;
	["HomepageSettingDocAccessFields_name"]: AliasType<{
	create?:ValueTypes["HomepageSettingDocAccessFields_name_Create"],
	read?:ValueTypes["HomepageSettingDocAccessFields_name_Read"],
	update?:ValueTypes["HomepageSettingDocAccessFields_name_Update"],
	delete?:ValueTypes["HomepageSettingDocAccessFields_name_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["HomepageSettingDocAccessFields_name_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["HomepageSettingDocAccessFields_name_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["HomepageSettingDocAccessFields_name_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["HomepageSettingDocAccessFields_name_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["HomepageSettingDocAccessFields_region"]: AliasType<{
	create?:ValueTypes["HomepageSettingDocAccessFields_region_Create"],
	read?:ValueTypes["HomepageSettingDocAccessFields_region_Read"],
	update?:ValueTypes["HomepageSettingDocAccessFields_region_Update"],
	delete?:ValueTypes["HomepageSettingDocAccessFields_region_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["HomepageSettingDocAccessFields_region_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["HomepageSettingDocAccessFields_region_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["HomepageSettingDocAccessFields_region_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["HomepageSettingDocAccessFields_region_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["HomepageSettingDocAccessFields_sections"]: AliasType<{
	create?:ValueTypes["HomepageSettingDocAccessFields_sections_Create"],
	read?:ValueTypes["HomepageSettingDocAccessFields_sections_Read"],
	update?:ValueTypes["HomepageSettingDocAccessFields_sections_Update"],
	delete?:ValueTypes["HomepageSettingDocAccessFields_sections_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["HomepageSettingDocAccessFields_sections_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["HomepageSettingDocAccessFields_sections_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["HomepageSettingDocAccessFields_sections_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["HomepageSettingDocAccessFields_sections_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["HomepageSettingDocAccessFields_updatedAt"]: AliasType<{
	create?:ValueTypes["HomepageSettingDocAccessFields_updatedAt_Create"],
	read?:ValueTypes["HomepageSettingDocAccessFields_updatedAt_Read"],
	update?:ValueTypes["HomepageSettingDocAccessFields_updatedAt_Update"],
	delete?:ValueTypes["HomepageSettingDocAccessFields_updatedAt_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["HomepageSettingDocAccessFields_updatedAt_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["HomepageSettingDocAccessFields_updatedAt_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["HomepageSettingDocAccessFields_updatedAt_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["HomepageSettingDocAccessFields_updatedAt_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["HomepageSettingDocAccessFields_createdAt"]: AliasType<{
	create?:ValueTypes["HomepageSettingDocAccessFields_createdAt_Create"],
	read?:ValueTypes["HomepageSettingDocAccessFields_createdAt_Read"],
	update?:ValueTypes["HomepageSettingDocAccessFields_createdAt_Update"],
	delete?:ValueTypes["HomepageSettingDocAccessFields_createdAt_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["HomepageSettingDocAccessFields_createdAt_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["HomepageSettingDocAccessFields_createdAt_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["HomepageSettingDocAccessFields_createdAt_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["HomepageSettingDocAccessFields_createdAt_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["HomepageSettingCreateDocAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["HomepageSettingReadDocAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["HomepageSettingUpdateDocAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["HomepageSettingDeleteDocAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannerSection"]: AliasType<{
	id?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	slug?:boolean | `@${string}`,
	banners?:ValueTypes["BannerSection_Banners"],
	updatedAt?:boolean | `@${string}`,
	createdAt?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannerSection_Banners"]: AliasType<{
	name?:boolean | `@${string}`,
	status?:boolean | `@${string}`,
desktop?: [{	locale?: ValueTypes["LocaleInputType"] | undefined | null | Variable<any, string>,	fallbackLocale?: ValueTypes["FallbackLocaleInputType"] | undefined | null | Variable<any, string>,	where?: ValueTypes["BannerSection_Banners_Desktop_where"] | undefined | null | Variable<any, string>},ValueTypes["Media"]],
	bannerStartDate?:boolean | `@${string}`,
	bannerEndDate?:boolean | `@${string}`,
	region?:boolean | `@${string}`,
	id?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannerSection_Banners_Desktop_where"]: {
	alt?: ValueTypes["BannerSection_Banners_Desktop_alt_operator"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["BannerSection_Banners_Desktop_updatedAt_operator"] | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["BannerSection_Banners_Desktop_createdAt_operator"] | undefined | null | Variable<any, string>,
	url?: ValueTypes["BannerSection_Banners_Desktop_url_operator"] | undefined | null | Variable<any, string>,
	filename?: ValueTypes["BannerSection_Banners_Desktop_filename_operator"] | undefined | null | Variable<any, string>,
	mimeType?: ValueTypes["BannerSection_Banners_Desktop_mimeType_operator"] | undefined | null | Variable<any, string>,
	filesize?: ValueTypes["BannerSection_Banners_Desktop_filesize_operator"] | undefined | null | Variable<any, string>,
	width?: ValueTypes["BannerSection_Banners_Desktop_width_operator"] | undefined | null | Variable<any, string>,
	height?: ValueTypes["BannerSection_Banners_Desktop_height_operator"] | undefined | null | Variable<any, string>,
	focalX?: ValueTypes["BannerSection_Banners_Desktop_focalX_operator"] | undefined | null | Variable<any, string>,
	focalY?: ValueTypes["BannerSection_Banners_Desktop_focalY_operator"] | undefined | null | Variable<any, string>,
	sizes__small__url?: ValueTypes["BannerSection_Banners_Desktop_sizes__small__url_operator"] | undefined | null | Variable<any, string>,
	sizes__small__width?: ValueTypes["BannerSection_Banners_Desktop_sizes__small__width_operator"] | undefined | null | Variable<any, string>,
	sizes__small__height?: ValueTypes["BannerSection_Banners_Desktop_sizes__small__height_operator"] | undefined | null | Variable<any, string>,
	sizes__small__mimeType?: ValueTypes["BannerSection_Banners_Desktop_sizes__small__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__small__filesize?: ValueTypes["BannerSection_Banners_Desktop_sizes__small__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__small__filename?: ValueTypes["BannerSection_Banners_Desktop_sizes__small__filename_operator"] | undefined | null | Variable<any, string>,
	sizes__medium__url?: ValueTypes["BannerSection_Banners_Desktop_sizes__medium__url_operator"] | undefined | null | Variable<any, string>,
	sizes__medium__width?: ValueTypes["BannerSection_Banners_Desktop_sizes__medium__width_operator"] | undefined | null | Variable<any, string>,
	sizes__medium__height?: ValueTypes["BannerSection_Banners_Desktop_sizes__medium__height_operator"] | undefined | null | Variable<any, string>,
	sizes__medium__mimeType?: ValueTypes["BannerSection_Banners_Desktop_sizes__medium__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__medium__filesize?: ValueTypes["BannerSection_Banners_Desktop_sizes__medium__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__medium__filename?: ValueTypes["BannerSection_Banners_Desktop_sizes__medium__filename_operator"] | undefined | null | Variable<any, string>,
	sizes__large__url?: ValueTypes["BannerSection_Banners_Desktop_sizes__large__url_operator"] | undefined | null | Variable<any, string>,
	sizes__large__width?: ValueTypes["BannerSection_Banners_Desktop_sizes__large__width_operator"] | undefined | null | Variable<any, string>,
	sizes__large__height?: ValueTypes["BannerSection_Banners_Desktop_sizes__large__height_operator"] | undefined | null | Variable<any, string>,
	sizes__large__mimeType?: ValueTypes["BannerSection_Banners_Desktop_sizes__large__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__large__filesize?: ValueTypes["BannerSection_Banners_Desktop_sizes__large__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__large__filename?: ValueTypes["BannerSection_Banners_Desktop_sizes__large__filename_operator"] | undefined | null | Variable<any, string>,
	sizes__small_jpeg__url?: ValueTypes["BannerSection_Banners_Desktop_sizes__small_jpeg__url_operator"] | undefined | null | Variable<any, string>,
	sizes__small_jpeg__width?: ValueTypes["BannerSection_Banners_Desktop_sizes__small_jpeg__width_operator"] | undefined | null | Variable<any, string>,
	sizes__small_jpeg__height?: ValueTypes["BannerSection_Banners_Desktop_sizes__small_jpeg__height_operator"] | undefined | null | Variable<any, string>,
	sizes__small_jpeg__mimeType?: ValueTypes["BannerSection_Banners_Desktop_sizes__small_jpeg__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__small_jpeg__filesize?: ValueTypes["BannerSection_Banners_Desktop_sizes__small_jpeg__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__small_jpeg__filename?: ValueTypes["BannerSection_Banners_Desktop_sizes__small_jpeg__filename_operator"] | undefined | null | Variable<any, string>,
	sizes__medium_jpeg__url?: ValueTypes["BannerSection_Banners_Desktop_sizes__medium_jpeg__url_operator"] | undefined | null | Variable<any, string>,
	sizes__medium_jpeg__width?: ValueTypes["BannerSection_Banners_Desktop_sizes__medium_jpeg__width_operator"] | undefined | null | Variable<any, string>,
	sizes__medium_jpeg__height?: ValueTypes["BannerSection_Banners_Desktop_sizes__medium_jpeg__height_operator"] | undefined | null | Variable<any, string>,
	sizes__medium_jpeg__mimeType?: ValueTypes["BannerSection_Banners_Desktop_sizes__medium_jpeg__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__medium_jpeg__filesize?: ValueTypes["BannerSection_Banners_Desktop_sizes__medium_jpeg__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__medium_jpeg__filename?: ValueTypes["BannerSection_Banners_Desktop_sizes__medium_jpeg__filename_operator"] | undefined | null | Variable<any, string>,
	sizes__large_jpeg__url?: ValueTypes["BannerSection_Banners_Desktop_sizes__large_jpeg__url_operator"] | undefined | null | Variable<any, string>,
	sizes__large_jpeg__width?: ValueTypes["BannerSection_Banners_Desktop_sizes__large_jpeg__width_operator"] | undefined | null | Variable<any, string>,
	sizes__large_jpeg__height?: ValueTypes["BannerSection_Banners_Desktop_sizes__large_jpeg__height_operator"] | undefined | null | Variable<any, string>,
	sizes__large_jpeg__mimeType?: ValueTypes["BannerSection_Banners_Desktop_sizes__large_jpeg__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__large_jpeg__filesize?: ValueTypes["BannerSection_Banners_Desktop_sizes__large_jpeg__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__large_jpeg__filename?: ValueTypes["BannerSection_Banners_Desktop_sizes__large_jpeg__filename_operator"] | undefined | null | Variable<any, string>,
	id?: ValueTypes["BannerSection_Banners_Desktop_id_operator"] | undefined | null | Variable<any, string>,
	AND?: Array<ValueTypes["BannerSection_Banners_Desktop_where_and"] | undefined | null> | undefined | null | Variable<any, string>,
	OR?: Array<ValueTypes["BannerSection_Banners_Desktop_where_or"] | undefined | null> | undefined | null | Variable<any, string>
};
	["BannerSection_Banners_Desktop_alt_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["BannerSection_Banners_Desktop_updatedAt_operator"]: {
	equals?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	not_equals?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	greater_than_equal?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	greater_than?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	less_than_equal?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	less_than?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	like?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["BannerSection_Banners_Desktop_createdAt_operator"]: {
	equals?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	not_equals?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	greater_than_equal?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	greater_than?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	less_than_equal?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	less_than?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	like?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["BannerSection_Banners_Desktop_url_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["BannerSection_Banners_Desktop_filename_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["BannerSection_Banners_Desktop_mimeType_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["BannerSection_Banners_Desktop_filesize_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["BannerSection_Banners_Desktop_width_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["BannerSection_Banners_Desktop_height_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["BannerSection_Banners_Desktop_focalX_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["BannerSection_Banners_Desktop_focalY_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["BannerSection_Banners_Desktop_sizes__small__url_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["BannerSection_Banners_Desktop_sizes__small__width_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["BannerSection_Banners_Desktop_sizes__small__height_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["BannerSection_Banners_Desktop_sizes__small__mimeType_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["BannerSection_Banners_Desktop_sizes__small__filesize_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["BannerSection_Banners_Desktop_sizes__small__filename_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["BannerSection_Banners_Desktop_sizes__medium__url_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["BannerSection_Banners_Desktop_sizes__medium__width_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["BannerSection_Banners_Desktop_sizes__medium__height_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["BannerSection_Banners_Desktop_sizes__medium__mimeType_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["BannerSection_Banners_Desktop_sizes__medium__filesize_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["BannerSection_Banners_Desktop_sizes__medium__filename_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["BannerSection_Banners_Desktop_sizes__large__url_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["BannerSection_Banners_Desktop_sizes__large__width_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["BannerSection_Banners_Desktop_sizes__large__height_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["BannerSection_Banners_Desktop_sizes__large__mimeType_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["BannerSection_Banners_Desktop_sizes__large__filesize_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["BannerSection_Banners_Desktop_sizes__large__filename_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["BannerSection_Banners_Desktop_sizes__small_jpeg__url_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["BannerSection_Banners_Desktop_sizes__small_jpeg__width_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["BannerSection_Banners_Desktop_sizes__small_jpeg__height_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["BannerSection_Banners_Desktop_sizes__small_jpeg__mimeType_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["BannerSection_Banners_Desktop_sizes__small_jpeg__filesize_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["BannerSection_Banners_Desktop_sizes__small_jpeg__filename_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["BannerSection_Banners_Desktop_sizes__medium_jpeg__url_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["BannerSection_Banners_Desktop_sizes__medium_jpeg__width_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["BannerSection_Banners_Desktop_sizes__medium_jpeg__height_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["BannerSection_Banners_Desktop_sizes__medium_jpeg__mimeType_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["BannerSection_Banners_Desktop_sizes__medium_jpeg__filesize_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["BannerSection_Banners_Desktop_sizes__medium_jpeg__filename_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["BannerSection_Banners_Desktop_sizes__large_jpeg__url_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["BannerSection_Banners_Desktop_sizes__large_jpeg__width_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["BannerSection_Banners_Desktop_sizes__large_jpeg__height_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["BannerSection_Banners_Desktop_sizes__large_jpeg__mimeType_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["BannerSection_Banners_Desktop_sizes__large_jpeg__filesize_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["BannerSection_Banners_Desktop_sizes__large_jpeg__filename_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["BannerSection_Banners_Desktop_id_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["BannerSection_Banners_Desktop_where_and"]: {
	alt?: ValueTypes["BannerSection_Banners_Desktop_alt_operator"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["BannerSection_Banners_Desktop_updatedAt_operator"] | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["BannerSection_Banners_Desktop_createdAt_operator"] | undefined | null | Variable<any, string>,
	url?: ValueTypes["BannerSection_Banners_Desktop_url_operator"] | undefined | null | Variable<any, string>,
	filename?: ValueTypes["BannerSection_Banners_Desktop_filename_operator"] | undefined | null | Variable<any, string>,
	mimeType?: ValueTypes["BannerSection_Banners_Desktop_mimeType_operator"] | undefined | null | Variable<any, string>,
	filesize?: ValueTypes["BannerSection_Banners_Desktop_filesize_operator"] | undefined | null | Variable<any, string>,
	width?: ValueTypes["BannerSection_Banners_Desktop_width_operator"] | undefined | null | Variable<any, string>,
	height?: ValueTypes["BannerSection_Banners_Desktop_height_operator"] | undefined | null | Variable<any, string>,
	focalX?: ValueTypes["BannerSection_Banners_Desktop_focalX_operator"] | undefined | null | Variable<any, string>,
	focalY?: ValueTypes["BannerSection_Banners_Desktop_focalY_operator"] | undefined | null | Variable<any, string>,
	sizes__small__url?: ValueTypes["BannerSection_Banners_Desktop_sizes__small__url_operator"] | undefined | null | Variable<any, string>,
	sizes__small__width?: ValueTypes["BannerSection_Banners_Desktop_sizes__small__width_operator"] | undefined | null | Variable<any, string>,
	sizes__small__height?: ValueTypes["BannerSection_Banners_Desktop_sizes__small__height_operator"] | undefined | null | Variable<any, string>,
	sizes__small__mimeType?: ValueTypes["BannerSection_Banners_Desktop_sizes__small__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__small__filesize?: ValueTypes["BannerSection_Banners_Desktop_sizes__small__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__small__filename?: ValueTypes["BannerSection_Banners_Desktop_sizes__small__filename_operator"] | undefined | null | Variable<any, string>,
	sizes__medium__url?: ValueTypes["BannerSection_Banners_Desktop_sizes__medium__url_operator"] | undefined | null | Variable<any, string>,
	sizes__medium__width?: ValueTypes["BannerSection_Banners_Desktop_sizes__medium__width_operator"] | undefined | null | Variable<any, string>,
	sizes__medium__height?: ValueTypes["BannerSection_Banners_Desktop_sizes__medium__height_operator"] | undefined | null | Variable<any, string>,
	sizes__medium__mimeType?: ValueTypes["BannerSection_Banners_Desktop_sizes__medium__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__medium__filesize?: ValueTypes["BannerSection_Banners_Desktop_sizes__medium__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__medium__filename?: ValueTypes["BannerSection_Banners_Desktop_sizes__medium__filename_operator"] | undefined | null | Variable<any, string>,
	sizes__large__url?: ValueTypes["BannerSection_Banners_Desktop_sizes__large__url_operator"] | undefined | null | Variable<any, string>,
	sizes__large__width?: ValueTypes["BannerSection_Banners_Desktop_sizes__large__width_operator"] | undefined | null | Variable<any, string>,
	sizes__large__height?: ValueTypes["BannerSection_Banners_Desktop_sizes__large__height_operator"] | undefined | null | Variable<any, string>,
	sizes__large__mimeType?: ValueTypes["BannerSection_Banners_Desktop_sizes__large__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__large__filesize?: ValueTypes["BannerSection_Banners_Desktop_sizes__large__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__large__filename?: ValueTypes["BannerSection_Banners_Desktop_sizes__large__filename_operator"] | undefined | null | Variable<any, string>,
	sizes__small_jpeg__url?: ValueTypes["BannerSection_Banners_Desktop_sizes__small_jpeg__url_operator"] | undefined | null | Variable<any, string>,
	sizes__small_jpeg__width?: ValueTypes["BannerSection_Banners_Desktop_sizes__small_jpeg__width_operator"] | undefined | null | Variable<any, string>,
	sizes__small_jpeg__height?: ValueTypes["BannerSection_Banners_Desktop_sizes__small_jpeg__height_operator"] | undefined | null | Variable<any, string>,
	sizes__small_jpeg__mimeType?: ValueTypes["BannerSection_Banners_Desktop_sizes__small_jpeg__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__small_jpeg__filesize?: ValueTypes["BannerSection_Banners_Desktop_sizes__small_jpeg__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__small_jpeg__filename?: ValueTypes["BannerSection_Banners_Desktop_sizes__small_jpeg__filename_operator"] | undefined | null | Variable<any, string>,
	sizes__medium_jpeg__url?: ValueTypes["BannerSection_Banners_Desktop_sizes__medium_jpeg__url_operator"] | undefined | null | Variable<any, string>,
	sizes__medium_jpeg__width?: ValueTypes["BannerSection_Banners_Desktop_sizes__medium_jpeg__width_operator"] | undefined | null | Variable<any, string>,
	sizes__medium_jpeg__height?: ValueTypes["BannerSection_Banners_Desktop_sizes__medium_jpeg__height_operator"] | undefined | null | Variable<any, string>,
	sizes__medium_jpeg__mimeType?: ValueTypes["BannerSection_Banners_Desktop_sizes__medium_jpeg__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__medium_jpeg__filesize?: ValueTypes["BannerSection_Banners_Desktop_sizes__medium_jpeg__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__medium_jpeg__filename?: ValueTypes["BannerSection_Banners_Desktop_sizes__medium_jpeg__filename_operator"] | undefined | null | Variable<any, string>,
	sizes__large_jpeg__url?: ValueTypes["BannerSection_Banners_Desktop_sizes__large_jpeg__url_operator"] | undefined | null | Variable<any, string>,
	sizes__large_jpeg__width?: ValueTypes["BannerSection_Banners_Desktop_sizes__large_jpeg__width_operator"] | undefined | null | Variable<any, string>,
	sizes__large_jpeg__height?: ValueTypes["BannerSection_Banners_Desktop_sizes__large_jpeg__height_operator"] | undefined | null | Variable<any, string>,
	sizes__large_jpeg__mimeType?: ValueTypes["BannerSection_Banners_Desktop_sizes__large_jpeg__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__large_jpeg__filesize?: ValueTypes["BannerSection_Banners_Desktop_sizes__large_jpeg__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__large_jpeg__filename?: ValueTypes["BannerSection_Banners_Desktop_sizes__large_jpeg__filename_operator"] | undefined | null | Variable<any, string>,
	id?: ValueTypes["BannerSection_Banners_Desktop_id_operator"] | undefined | null | Variable<any, string>,
	AND?: Array<ValueTypes["BannerSection_Banners_Desktop_where_and"] | undefined | null> | undefined | null | Variable<any, string>,
	OR?: Array<ValueTypes["BannerSection_Banners_Desktop_where_or"] | undefined | null> | undefined | null | Variable<any, string>
};
	["BannerSection_Banners_Desktop_where_or"]: {
	alt?: ValueTypes["BannerSection_Banners_Desktop_alt_operator"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["BannerSection_Banners_Desktop_updatedAt_operator"] | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["BannerSection_Banners_Desktop_createdAt_operator"] | undefined | null | Variable<any, string>,
	url?: ValueTypes["BannerSection_Banners_Desktop_url_operator"] | undefined | null | Variable<any, string>,
	filename?: ValueTypes["BannerSection_Banners_Desktop_filename_operator"] | undefined | null | Variable<any, string>,
	mimeType?: ValueTypes["BannerSection_Banners_Desktop_mimeType_operator"] | undefined | null | Variable<any, string>,
	filesize?: ValueTypes["BannerSection_Banners_Desktop_filesize_operator"] | undefined | null | Variable<any, string>,
	width?: ValueTypes["BannerSection_Banners_Desktop_width_operator"] | undefined | null | Variable<any, string>,
	height?: ValueTypes["BannerSection_Banners_Desktop_height_operator"] | undefined | null | Variable<any, string>,
	focalX?: ValueTypes["BannerSection_Banners_Desktop_focalX_operator"] | undefined | null | Variable<any, string>,
	focalY?: ValueTypes["BannerSection_Banners_Desktop_focalY_operator"] | undefined | null | Variable<any, string>,
	sizes__small__url?: ValueTypes["BannerSection_Banners_Desktop_sizes__small__url_operator"] | undefined | null | Variable<any, string>,
	sizes__small__width?: ValueTypes["BannerSection_Banners_Desktop_sizes__small__width_operator"] | undefined | null | Variable<any, string>,
	sizes__small__height?: ValueTypes["BannerSection_Banners_Desktop_sizes__small__height_operator"] | undefined | null | Variable<any, string>,
	sizes__small__mimeType?: ValueTypes["BannerSection_Banners_Desktop_sizes__small__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__small__filesize?: ValueTypes["BannerSection_Banners_Desktop_sizes__small__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__small__filename?: ValueTypes["BannerSection_Banners_Desktop_sizes__small__filename_operator"] | undefined | null | Variable<any, string>,
	sizes__medium__url?: ValueTypes["BannerSection_Banners_Desktop_sizes__medium__url_operator"] | undefined | null | Variable<any, string>,
	sizes__medium__width?: ValueTypes["BannerSection_Banners_Desktop_sizes__medium__width_operator"] | undefined | null | Variable<any, string>,
	sizes__medium__height?: ValueTypes["BannerSection_Banners_Desktop_sizes__medium__height_operator"] | undefined | null | Variable<any, string>,
	sizes__medium__mimeType?: ValueTypes["BannerSection_Banners_Desktop_sizes__medium__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__medium__filesize?: ValueTypes["BannerSection_Banners_Desktop_sizes__medium__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__medium__filename?: ValueTypes["BannerSection_Banners_Desktop_sizes__medium__filename_operator"] | undefined | null | Variable<any, string>,
	sizes__large__url?: ValueTypes["BannerSection_Banners_Desktop_sizes__large__url_operator"] | undefined | null | Variable<any, string>,
	sizes__large__width?: ValueTypes["BannerSection_Banners_Desktop_sizes__large__width_operator"] | undefined | null | Variable<any, string>,
	sizes__large__height?: ValueTypes["BannerSection_Banners_Desktop_sizes__large__height_operator"] | undefined | null | Variable<any, string>,
	sizes__large__mimeType?: ValueTypes["BannerSection_Banners_Desktop_sizes__large__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__large__filesize?: ValueTypes["BannerSection_Banners_Desktop_sizes__large__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__large__filename?: ValueTypes["BannerSection_Banners_Desktop_sizes__large__filename_operator"] | undefined | null | Variable<any, string>,
	sizes__small_jpeg__url?: ValueTypes["BannerSection_Banners_Desktop_sizes__small_jpeg__url_operator"] | undefined | null | Variable<any, string>,
	sizes__small_jpeg__width?: ValueTypes["BannerSection_Banners_Desktop_sizes__small_jpeg__width_operator"] | undefined | null | Variable<any, string>,
	sizes__small_jpeg__height?: ValueTypes["BannerSection_Banners_Desktop_sizes__small_jpeg__height_operator"] | undefined | null | Variable<any, string>,
	sizes__small_jpeg__mimeType?: ValueTypes["BannerSection_Banners_Desktop_sizes__small_jpeg__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__small_jpeg__filesize?: ValueTypes["BannerSection_Banners_Desktop_sizes__small_jpeg__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__small_jpeg__filename?: ValueTypes["BannerSection_Banners_Desktop_sizes__small_jpeg__filename_operator"] | undefined | null | Variable<any, string>,
	sizes__medium_jpeg__url?: ValueTypes["BannerSection_Banners_Desktop_sizes__medium_jpeg__url_operator"] | undefined | null | Variable<any, string>,
	sizes__medium_jpeg__width?: ValueTypes["BannerSection_Banners_Desktop_sizes__medium_jpeg__width_operator"] | undefined | null | Variable<any, string>,
	sizes__medium_jpeg__height?: ValueTypes["BannerSection_Banners_Desktop_sizes__medium_jpeg__height_operator"] | undefined | null | Variable<any, string>,
	sizes__medium_jpeg__mimeType?: ValueTypes["BannerSection_Banners_Desktop_sizes__medium_jpeg__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__medium_jpeg__filesize?: ValueTypes["BannerSection_Banners_Desktop_sizes__medium_jpeg__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__medium_jpeg__filename?: ValueTypes["BannerSection_Banners_Desktop_sizes__medium_jpeg__filename_operator"] | undefined | null | Variable<any, string>,
	sizes__large_jpeg__url?: ValueTypes["BannerSection_Banners_Desktop_sizes__large_jpeg__url_operator"] | undefined | null | Variable<any, string>,
	sizes__large_jpeg__width?: ValueTypes["BannerSection_Banners_Desktop_sizes__large_jpeg__width_operator"] | undefined | null | Variable<any, string>,
	sizes__large_jpeg__height?: ValueTypes["BannerSection_Banners_Desktop_sizes__large_jpeg__height_operator"] | undefined | null | Variable<any, string>,
	sizes__large_jpeg__mimeType?: ValueTypes["BannerSection_Banners_Desktop_sizes__large_jpeg__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__large_jpeg__filesize?: ValueTypes["BannerSection_Banners_Desktop_sizes__large_jpeg__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__large_jpeg__filename?: ValueTypes["BannerSection_Banners_Desktop_sizes__large_jpeg__filename_operator"] | undefined | null | Variable<any, string>,
	id?: ValueTypes["BannerSection_Banners_Desktop_id_operator"] | undefined | null | Variable<any, string>,
	AND?: Array<ValueTypes["BannerSection_Banners_Desktop_where_and"] | undefined | null> | undefined | null | Variable<any, string>,
	OR?: Array<ValueTypes["BannerSection_Banners_Desktop_where_or"] | undefined | null> | undefined | null | Variable<any, string>
};
	["BannerSection_Banners_region"]:BannerSection_Banners_region;
	["BannerSections"]: AliasType<{
	docs?:ValueTypes["BannerSection"],
	hasNextPage?:boolean | `@${string}`,
	hasPrevPage?:boolean | `@${string}`,
	limit?:boolean | `@${string}`,
	nextPage?:boolean | `@${string}`,
	offset?:boolean | `@${string}`,
	page?:boolean | `@${string}`,
	pagingCounter?:boolean | `@${string}`,
	prevPage?:boolean | `@${string}`,
	totalDocs?:boolean | `@${string}`,
	totalPages?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannerSection_where"]: {
	name?: ValueTypes["BannerSection_name_operator"] | undefined | null | Variable<any, string>,
	slug?: ValueTypes["BannerSection_slug_operator"] | undefined | null | Variable<any, string>,
	banners__name?: ValueTypes["BannerSection_banners__name_operator"] | undefined | null | Variable<any, string>,
	banners__status?: ValueTypes["BannerSection_banners__status_operator"] | undefined | null | Variable<any, string>,
	banners__desktop?: ValueTypes["BannerSection_banners__desktop_operator"] | undefined | null | Variable<any, string>,
	banners__bannerStartDate?: ValueTypes["BannerSection_banners__bannerStartDate_operator"] | undefined | null | Variable<any, string>,
	banners__bannerEndDate?: ValueTypes["BannerSection_banners__bannerEndDate_operator"] | undefined | null | Variable<any, string>,
	banners__region?: ValueTypes["BannerSection_banners__region_operator"] | undefined | null | Variable<any, string>,
	banners__id?: ValueTypes["BannerSection_banners__id_operator"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["BannerSection_updatedAt_operator"] | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["BannerSection_createdAt_operator"] | undefined | null | Variable<any, string>,
	id?: ValueTypes["BannerSection_id_operator"] | undefined | null | Variable<any, string>,
	AND?: Array<ValueTypes["BannerSection_where_and"] | undefined | null> | undefined | null | Variable<any, string>,
	OR?: Array<ValueTypes["BannerSection_where_or"] | undefined | null> | undefined | null | Variable<any, string>
};
	["BannerSection_name_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["BannerSection_slug_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["BannerSection_banners__name_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["BannerSection_banners__status_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["BannerSection_banners__desktop_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>
};
	["BannerSection_banners__bannerStartDate_operator"]: {
	equals?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	not_equals?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	greater_than_equal?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	greater_than?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	less_than_equal?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	less_than?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	like?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["BannerSection_banners__bannerEndDate_operator"]: {
	equals?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	not_equals?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	greater_than_equal?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	greater_than?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	less_than_equal?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	less_than?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	like?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["BannerSection_banners__region_operator"]: {
	equals?: ValueTypes["BannerSection_banners__region_Input"] | undefined | null | Variable<any, string>,
	not_equals?: ValueTypes["BannerSection_banners__region_Input"] | undefined | null | Variable<any, string>,
	in?: Array<ValueTypes["BannerSection_banners__region_Input"] | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<ValueTypes["BannerSection_banners__region_Input"] | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<ValueTypes["BannerSection_banners__region_Input"] | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["BannerSection_banners__region_Input"]:BannerSection_banners__region_Input;
	["BannerSection_banners__id_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["BannerSection_updatedAt_operator"]: {
	equals?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	not_equals?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	greater_than_equal?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	greater_than?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	less_than_equal?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	less_than?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	like?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["BannerSection_createdAt_operator"]: {
	equals?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	not_equals?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	greater_than_equal?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	greater_than?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	less_than_equal?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	less_than?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	like?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["BannerSection_id_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["BannerSection_where_and"]: {
	name?: ValueTypes["BannerSection_name_operator"] | undefined | null | Variable<any, string>,
	slug?: ValueTypes["BannerSection_slug_operator"] | undefined | null | Variable<any, string>,
	banners__name?: ValueTypes["BannerSection_banners__name_operator"] | undefined | null | Variable<any, string>,
	banners__status?: ValueTypes["BannerSection_banners__status_operator"] | undefined | null | Variable<any, string>,
	banners__desktop?: ValueTypes["BannerSection_banners__desktop_operator"] | undefined | null | Variable<any, string>,
	banners__bannerStartDate?: ValueTypes["BannerSection_banners__bannerStartDate_operator"] | undefined | null | Variable<any, string>,
	banners__bannerEndDate?: ValueTypes["BannerSection_banners__bannerEndDate_operator"] | undefined | null | Variable<any, string>,
	banners__region?: ValueTypes["BannerSection_banners__region_operator"] | undefined | null | Variable<any, string>,
	banners__id?: ValueTypes["BannerSection_banners__id_operator"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["BannerSection_updatedAt_operator"] | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["BannerSection_createdAt_operator"] | undefined | null | Variable<any, string>,
	id?: ValueTypes["BannerSection_id_operator"] | undefined | null | Variable<any, string>,
	AND?: Array<ValueTypes["BannerSection_where_and"] | undefined | null> | undefined | null | Variable<any, string>,
	OR?: Array<ValueTypes["BannerSection_where_or"] | undefined | null> | undefined | null | Variable<any, string>
};
	["BannerSection_where_or"]: {
	name?: ValueTypes["BannerSection_name_operator"] | undefined | null | Variable<any, string>,
	slug?: ValueTypes["BannerSection_slug_operator"] | undefined | null | Variable<any, string>,
	banners__name?: ValueTypes["BannerSection_banners__name_operator"] | undefined | null | Variable<any, string>,
	banners__status?: ValueTypes["BannerSection_banners__status_operator"] | undefined | null | Variable<any, string>,
	banners__desktop?: ValueTypes["BannerSection_banners__desktop_operator"] | undefined | null | Variable<any, string>,
	banners__bannerStartDate?: ValueTypes["BannerSection_banners__bannerStartDate_operator"] | undefined | null | Variable<any, string>,
	banners__bannerEndDate?: ValueTypes["BannerSection_banners__bannerEndDate_operator"] | undefined | null | Variable<any, string>,
	banners__region?: ValueTypes["BannerSection_banners__region_operator"] | undefined | null | Variable<any, string>,
	banners__id?: ValueTypes["BannerSection_banners__id_operator"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["BannerSection_updatedAt_operator"] | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["BannerSection_createdAt_operator"] | undefined | null | Variable<any, string>,
	id?: ValueTypes["BannerSection_id_operator"] | undefined | null | Variable<any, string>,
	AND?: Array<ValueTypes["BannerSection_where_and"] | undefined | null> | undefined | null | Variable<any, string>,
	OR?: Array<ValueTypes["BannerSection_where_or"] | undefined | null> | undefined | null | Variable<any, string>
};
	["countBannerSections"]: AliasType<{
	totalDocs?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["banner_sectionDocAccess"]: AliasType<{
	fields?:ValueTypes["BannerSectionDocAccessFields"],
	create?:ValueTypes["BannerSectionCreateDocAccess"],
	read?:ValueTypes["BannerSectionReadDocAccess"],
	update?:ValueTypes["BannerSectionUpdateDocAccess"],
	delete?:ValueTypes["BannerSectionDeleteDocAccess"],
		__typename?: boolean | `@${string}`
}>;
	["BannerSectionDocAccessFields"]: AliasType<{
	name?:ValueTypes["BannerSectionDocAccessFields_name"],
	slug?:ValueTypes["BannerSectionDocAccessFields_slug"],
	banners?:ValueTypes["BannerSectionDocAccessFields_banners"],
	updatedAt?:ValueTypes["BannerSectionDocAccessFields_updatedAt"],
	createdAt?:ValueTypes["BannerSectionDocAccessFields_createdAt"],
		__typename?: boolean | `@${string}`
}>;
	["BannerSectionDocAccessFields_name"]: AliasType<{
	create?:ValueTypes["BannerSectionDocAccessFields_name_Create"],
	read?:ValueTypes["BannerSectionDocAccessFields_name_Read"],
	update?:ValueTypes["BannerSectionDocAccessFields_name_Update"],
	delete?:ValueTypes["BannerSectionDocAccessFields_name_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["BannerSectionDocAccessFields_name_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannerSectionDocAccessFields_name_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannerSectionDocAccessFields_name_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannerSectionDocAccessFields_name_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannerSectionDocAccessFields_slug"]: AliasType<{
	create?:ValueTypes["BannerSectionDocAccessFields_slug_Create"],
	read?:ValueTypes["BannerSectionDocAccessFields_slug_Read"],
	update?:ValueTypes["BannerSectionDocAccessFields_slug_Update"],
	delete?:ValueTypes["BannerSectionDocAccessFields_slug_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["BannerSectionDocAccessFields_slug_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannerSectionDocAccessFields_slug_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannerSectionDocAccessFields_slug_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannerSectionDocAccessFields_slug_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannerSectionDocAccessFields_banners"]: AliasType<{
	create?:ValueTypes["BannerSectionDocAccessFields_banners_Create"],
	read?:ValueTypes["BannerSectionDocAccessFields_banners_Read"],
	update?:ValueTypes["BannerSectionDocAccessFields_banners_Update"],
	delete?:ValueTypes["BannerSectionDocAccessFields_banners_Delete"],
	fields?:ValueTypes["BannerSectionDocAccessFields_banners_Fields"],
		__typename?: boolean | `@${string}`
}>;
	["BannerSectionDocAccessFields_banners_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannerSectionDocAccessFields_banners_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannerSectionDocAccessFields_banners_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannerSectionDocAccessFields_banners_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannerSectionDocAccessFields_banners_Fields"]: AliasType<{
	name?:ValueTypes["BannerSectionDocAccessFields_banners_name"],
	status?:ValueTypes["BannerSectionDocAccessFields_banners_status"],
	desktop?:ValueTypes["BannerSectionDocAccessFields_banners_desktop"],
	bannerStartDate?:ValueTypes["BannerSectionDocAccessFields_banners_bannerStartDate"],
	bannerEndDate?:ValueTypes["BannerSectionDocAccessFields_banners_bannerEndDate"],
	region?:ValueTypes["BannerSectionDocAccessFields_banners_region"],
	id?:ValueTypes["BannerSectionDocAccessFields_banners_id"],
		__typename?: boolean | `@${string}`
}>;
	["BannerSectionDocAccessFields_banners_name"]: AliasType<{
	create?:ValueTypes["BannerSectionDocAccessFields_banners_name_Create"],
	read?:ValueTypes["BannerSectionDocAccessFields_banners_name_Read"],
	update?:ValueTypes["BannerSectionDocAccessFields_banners_name_Update"],
	delete?:ValueTypes["BannerSectionDocAccessFields_banners_name_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["BannerSectionDocAccessFields_banners_name_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannerSectionDocAccessFields_banners_name_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannerSectionDocAccessFields_banners_name_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannerSectionDocAccessFields_banners_name_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannerSectionDocAccessFields_banners_status"]: AliasType<{
	create?:ValueTypes["BannerSectionDocAccessFields_banners_status_Create"],
	read?:ValueTypes["BannerSectionDocAccessFields_banners_status_Read"],
	update?:ValueTypes["BannerSectionDocAccessFields_banners_status_Update"],
	delete?:ValueTypes["BannerSectionDocAccessFields_banners_status_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["BannerSectionDocAccessFields_banners_status_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannerSectionDocAccessFields_banners_status_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannerSectionDocAccessFields_banners_status_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannerSectionDocAccessFields_banners_status_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannerSectionDocAccessFields_banners_desktop"]: AliasType<{
	create?:ValueTypes["BannerSectionDocAccessFields_banners_desktop_Create"],
	read?:ValueTypes["BannerSectionDocAccessFields_banners_desktop_Read"],
	update?:ValueTypes["BannerSectionDocAccessFields_banners_desktop_Update"],
	delete?:ValueTypes["BannerSectionDocAccessFields_banners_desktop_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["BannerSectionDocAccessFields_banners_desktop_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannerSectionDocAccessFields_banners_desktop_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannerSectionDocAccessFields_banners_desktop_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannerSectionDocAccessFields_banners_desktop_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannerSectionDocAccessFields_banners_bannerStartDate"]: AliasType<{
	create?:ValueTypes["BannerSectionDocAccessFields_banners_bannerStartDate_Create"],
	read?:ValueTypes["BannerSectionDocAccessFields_banners_bannerStartDate_Read"],
	update?:ValueTypes["BannerSectionDocAccessFields_banners_bannerStartDate_Update"],
	delete?:ValueTypes["BannerSectionDocAccessFields_banners_bannerStartDate_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["BannerSectionDocAccessFields_banners_bannerStartDate_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannerSectionDocAccessFields_banners_bannerStartDate_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannerSectionDocAccessFields_banners_bannerStartDate_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannerSectionDocAccessFields_banners_bannerStartDate_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannerSectionDocAccessFields_banners_bannerEndDate"]: AliasType<{
	create?:ValueTypes["BannerSectionDocAccessFields_banners_bannerEndDate_Create"],
	read?:ValueTypes["BannerSectionDocAccessFields_banners_bannerEndDate_Read"],
	update?:ValueTypes["BannerSectionDocAccessFields_banners_bannerEndDate_Update"],
	delete?:ValueTypes["BannerSectionDocAccessFields_banners_bannerEndDate_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["BannerSectionDocAccessFields_banners_bannerEndDate_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannerSectionDocAccessFields_banners_bannerEndDate_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannerSectionDocAccessFields_banners_bannerEndDate_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannerSectionDocAccessFields_banners_bannerEndDate_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannerSectionDocAccessFields_banners_region"]: AliasType<{
	create?:ValueTypes["BannerSectionDocAccessFields_banners_region_Create"],
	read?:ValueTypes["BannerSectionDocAccessFields_banners_region_Read"],
	update?:ValueTypes["BannerSectionDocAccessFields_banners_region_Update"],
	delete?:ValueTypes["BannerSectionDocAccessFields_banners_region_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["BannerSectionDocAccessFields_banners_region_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannerSectionDocAccessFields_banners_region_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannerSectionDocAccessFields_banners_region_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannerSectionDocAccessFields_banners_region_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannerSectionDocAccessFields_banners_id"]: AliasType<{
	create?:ValueTypes["BannerSectionDocAccessFields_banners_id_Create"],
	read?:ValueTypes["BannerSectionDocAccessFields_banners_id_Read"],
	update?:ValueTypes["BannerSectionDocAccessFields_banners_id_Update"],
	delete?:ValueTypes["BannerSectionDocAccessFields_banners_id_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["BannerSectionDocAccessFields_banners_id_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannerSectionDocAccessFields_banners_id_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannerSectionDocAccessFields_banners_id_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannerSectionDocAccessFields_banners_id_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannerSectionDocAccessFields_updatedAt"]: AliasType<{
	create?:ValueTypes["BannerSectionDocAccessFields_updatedAt_Create"],
	read?:ValueTypes["BannerSectionDocAccessFields_updatedAt_Read"],
	update?:ValueTypes["BannerSectionDocAccessFields_updatedAt_Update"],
	delete?:ValueTypes["BannerSectionDocAccessFields_updatedAt_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["BannerSectionDocAccessFields_updatedAt_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannerSectionDocAccessFields_updatedAt_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannerSectionDocAccessFields_updatedAt_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannerSectionDocAccessFields_updatedAt_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannerSectionDocAccessFields_createdAt"]: AliasType<{
	create?:ValueTypes["BannerSectionDocAccessFields_createdAt_Create"],
	read?:ValueTypes["BannerSectionDocAccessFields_createdAt_Read"],
	update?:ValueTypes["BannerSectionDocAccessFields_createdAt_Update"],
	delete?:ValueTypes["BannerSectionDocAccessFields_createdAt_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["BannerSectionDocAccessFields_createdAt_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannerSectionDocAccessFields_createdAt_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannerSectionDocAccessFields_createdAt_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannerSectionDocAccessFields_createdAt_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannerSectionCreateDocAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannerSectionReadDocAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannerSectionUpdateDocAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannerSectionDeleteDocAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MacaoBannerSection"]: AliasType<{
	id?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	slug?:boolean | `@${string}`,
	banners?:ValueTypes["MacaoBannerSection_Banners"],
	updatedAt?:boolean | `@${string}`,
	createdAt?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MacaoBannerSection_Banners"]: AliasType<{
	name?:boolean | `@${string}`,
	status?:boolean | `@${string}`,
desktop?: [{	locale?: ValueTypes["LocaleInputType"] | undefined | null | Variable<any, string>,	fallbackLocale?: ValueTypes["FallbackLocaleInputType"] | undefined | null | Variable<any, string>,	where?: ValueTypes["MacaoBannerSection_Banners_Desktop_where"] | undefined | null | Variable<any, string>},ValueTypes["Media"]],
	bannerStartDate?:boolean | `@${string}`,
	bannerEndDate?:boolean | `@${string}`,
	region?:boolean | `@${string}`,
	id?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MacaoBannerSection_Banners_Desktop_where"]: {
	alt?: ValueTypes["MacaoBannerSection_Banners_Desktop_alt_operator"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["MacaoBannerSection_Banners_Desktop_updatedAt_operator"] | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["MacaoBannerSection_Banners_Desktop_createdAt_operator"] | undefined | null | Variable<any, string>,
	url?: ValueTypes["MacaoBannerSection_Banners_Desktop_url_operator"] | undefined | null | Variable<any, string>,
	filename?: ValueTypes["MacaoBannerSection_Banners_Desktop_filename_operator"] | undefined | null | Variable<any, string>,
	mimeType?: ValueTypes["MacaoBannerSection_Banners_Desktop_mimeType_operator"] | undefined | null | Variable<any, string>,
	filesize?: ValueTypes["MacaoBannerSection_Banners_Desktop_filesize_operator"] | undefined | null | Variable<any, string>,
	width?: ValueTypes["MacaoBannerSection_Banners_Desktop_width_operator"] | undefined | null | Variable<any, string>,
	height?: ValueTypes["MacaoBannerSection_Banners_Desktop_height_operator"] | undefined | null | Variable<any, string>,
	focalX?: ValueTypes["MacaoBannerSection_Banners_Desktop_focalX_operator"] | undefined | null | Variable<any, string>,
	focalY?: ValueTypes["MacaoBannerSection_Banners_Desktop_focalY_operator"] | undefined | null | Variable<any, string>,
	sizes__small__url?: ValueTypes["MacaoBannerSection_Banners_Desktop_sizes__small__url_operator"] | undefined | null | Variable<any, string>,
	sizes__small__width?: ValueTypes["MacaoBannerSection_Banners_Desktop_sizes__small__width_operator"] | undefined | null | Variable<any, string>,
	sizes__small__height?: ValueTypes["MacaoBannerSection_Banners_Desktop_sizes__small__height_operator"] | undefined | null | Variable<any, string>,
	sizes__small__mimeType?: ValueTypes["MacaoBannerSection_Banners_Desktop_sizes__small__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__small__filesize?: ValueTypes["MacaoBannerSection_Banners_Desktop_sizes__small__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__small__filename?: ValueTypes["MacaoBannerSection_Banners_Desktop_sizes__small__filename_operator"] | undefined | null | Variable<any, string>,
	sizes__medium__url?: ValueTypes["MacaoBannerSection_Banners_Desktop_sizes__medium__url_operator"] | undefined | null | Variable<any, string>,
	sizes__medium__width?: ValueTypes["MacaoBannerSection_Banners_Desktop_sizes__medium__width_operator"] | undefined | null | Variable<any, string>,
	sizes__medium__height?: ValueTypes["MacaoBannerSection_Banners_Desktop_sizes__medium__height_operator"] | undefined | null | Variable<any, string>,
	sizes__medium__mimeType?: ValueTypes["MacaoBannerSection_Banners_Desktop_sizes__medium__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__medium__filesize?: ValueTypes["MacaoBannerSection_Banners_Desktop_sizes__medium__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__medium__filename?: ValueTypes["MacaoBannerSection_Banners_Desktop_sizes__medium__filename_operator"] | undefined | null | Variable<any, string>,
	sizes__large__url?: ValueTypes["MacaoBannerSection_Banners_Desktop_sizes__large__url_operator"] | undefined | null | Variable<any, string>,
	sizes__large__width?: ValueTypes["MacaoBannerSection_Banners_Desktop_sizes__large__width_operator"] | undefined | null | Variable<any, string>,
	sizes__large__height?: ValueTypes["MacaoBannerSection_Banners_Desktop_sizes__large__height_operator"] | undefined | null | Variable<any, string>,
	sizes__large__mimeType?: ValueTypes["MacaoBannerSection_Banners_Desktop_sizes__large__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__large__filesize?: ValueTypes["MacaoBannerSection_Banners_Desktop_sizes__large__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__large__filename?: ValueTypes["MacaoBannerSection_Banners_Desktop_sizes__large__filename_operator"] | undefined | null | Variable<any, string>,
	sizes__small_jpeg__url?: ValueTypes["MacaoBannerSection_Banners_Desktop_sizes__small_jpeg__url_operator"] | undefined | null | Variable<any, string>,
	sizes__small_jpeg__width?: ValueTypes["MacaoBannerSection_Banners_Desktop_sizes__small_jpeg__width_operator"] | undefined | null | Variable<any, string>,
	sizes__small_jpeg__height?: ValueTypes["MacaoBannerSection_Banners_Desktop_sizes__small_jpeg__height_operator"] | undefined | null | Variable<any, string>,
	sizes__small_jpeg__mimeType?: ValueTypes["MacaoBannerSection_Banners_Desktop_sizes__small_jpeg__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__small_jpeg__filesize?: ValueTypes["MacaoBannerSection_Banners_Desktop_sizes__small_jpeg__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__small_jpeg__filename?: ValueTypes["MacaoBannerSection_Banners_Desktop_sizes__small_jpeg__filename_operator"] | undefined | null | Variable<any, string>,
	sizes__medium_jpeg__url?: ValueTypes["MacaoBannerSection_Banners_Desktop_sizes__medium_jpeg__url_operator"] | undefined | null | Variable<any, string>,
	sizes__medium_jpeg__width?: ValueTypes["MacaoBannerSection_Banners_Desktop_sizes__medium_jpeg__width_operator"] | undefined | null | Variable<any, string>,
	sizes__medium_jpeg__height?: ValueTypes["MacaoBannerSection_Banners_Desktop_sizes__medium_jpeg__height_operator"] | undefined | null | Variable<any, string>,
	sizes__medium_jpeg__mimeType?: ValueTypes["MacaoBannerSection_Banners_Desktop_sizes__medium_jpeg__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__medium_jpeg__filesize?: ValueTypes["MacaoBannerSection_Banners_Desktop_sizes__medium_jpeg__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__medium_jpeg__filename?: ValueTypes["MacaoBannerSection_Banners_Desktop_sizes__medium_jpeg__filename_operator"] | undefined | null | Variable<any, string>,
	sizes__large_jpeg__url?: ValueTypes["MacaoBannerSection_Banners_Desktop_sizes__large_jpeg__url_operator"] | undefined | null | Variable<any, string>,
	sizes__large_jpeg__width?: ValueTypes["MacaoBannerSection_Banners_Desktop_sizes__large_jpeg__width_operator"] | undefined | null | Variable<any, string>,
	sizes__large_jpeg__height?: ValueTypes["MacaoBannerSection_Banners_Desktop_sizes__large_jpeg__height_operator"] | undefined | null | Variable<any, string>,
	sizes__large_jpeg__mimeType?: ValueTypes["MacaoBannerSection_Banners_Desktop_sizes__large_jpeg__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__large_jpeg__filesize?: ValueTypes["MacaoBannerSection_Banners_Desktop_sizes__large_jpeg__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__large_jpeg__filename?: ValueTypes["MacaoBannerSection_Banners_Desktop_sizes__large_jpeg__filename_operator"] | undefined | null | Variable<any, string>,
	id?: ValueTypes["MacaoBannerSection_Banners_Desktop_id_operator"] | undefined | null | Variable<any, string>,
	AND?: Array<ValueTypes["MacaoBannerSection_Banners_Desktop_where_and"] | undefined | null> | undefined | null | Variable<any, string>,
	OR?: Array<ValueTypes["MacaoBannerSection_Banners_Desktop_where_or"] | undefined | null> | undefined | null | Variable<any, string>
};
	["MacaoBannerSection_Banners_Desktop_alt_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["MacaoBannerSection_Banners_Desktop_updatedAt_operator"]: {
	equals?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	not_equals?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	greater_than_equal?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	greater_than?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	less_than_equal?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	less_than?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	like?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["MacaoBannerSection_Banners_Desktop_createdAt_operator"]: {
	equals?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	not_equals?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	greater_than_equal?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	greater_than?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	less_than_equal?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	less_than?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	like?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["MacaoBannerSection_Banners_Desktop_url_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["MacaoBannerSection_Banners_Desktop_filename_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["MacaoBannerSection_Banners_Desktop_mimeType_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["MacaoBannerSection_Banners_Desktop_filesize_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["MacaoBannerSection_Banners_Desktop_width_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["MacaoBannerSection_Banners_Desktop_height_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["MacaoBannerSection_Banners_Desktop_focalX_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["MacaoBannerSection_Banners_Desktop_focalY_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["MacaoBannerSection_Banners_Desktop_sizes__small__url_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["MacaoBannerSection_Banners_Desktop_sizes__small__width_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["MacaoBannerSection_Banners_Desktop_sizes__small__height_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["MacaoBannerSection_Banners_Desktop_sizes__small__mimeType_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["MacaoBannerSection_Banners_Desktop_sizes__small__filesize_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["MacaoBannerSection_Banners_Desktop_sizes__small__filename_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["MacaoBannerSection_Banners_Desktop_sizes__medium__url_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["MacaoBannerSection_Banners_Desktop_sizes__medium__width_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["MacaoBannerSection_Banners_Desktop_sizes__medium__height_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["MacaoBannerSection_Banners_Desktop_sizes__medium__mimeType_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["MacaoBannerSection_Banners_Desktop_sizes__medium__filesize_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["MacaoBannerSection_Banners_Desktop_sizes__medium__filename_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["MacaoBannerSection_Banners_Desktop_sizes__large__url_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["MacaoBannerSection_Banners_Desktop_sizes__large__width_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["MacaoBannerSection_Banners_Desktop_sizes__large__height_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["MacaoBannerSection_Banners_Desktop_sizes__large__mimeType_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["MacaoBannerSection_Banners_Desktop_sizes__large__filesize_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["MacaoBannerSection_Banners_Desktop_sizes__large__filename_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["MacaoBannerSection_Banners_Desktop_sizes__small_jpeg__url_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["MacaoBannerSection_Banners_Desktop_sizes__small_jpeg__width_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["MacaoBannerSection_Banners_Desktop_sizes__small_jpeg__height_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["MacaoBannerSection_Banners_Desktop_sizes__small_jpeg__mimeType_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["MacaoBannerSection_Banners_Desktop_sizes__small_jpeg__filesize_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["MacaoBannerSection_Banners_Desktop_sizes__small_jpeg__filename_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["MacaoBannerSection_Banners_Desktop_sizes__medium_jpeg__url_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["MacaoBannerSection_Banners_Desktop_sizes__medium_jpeg__width_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["MacaoBannerSection_Banners_Desktop_sizes__medium_jpeg__height_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["MacaoBannerSection_Banners_Desktop_sizes__medium_jpeg__mimeType_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["MacaoBannerSection_Banners_Desktop_sizes__medium_jpeg__filesize_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["MacaoBannerSection_Banners_Desktop_sizes__medium_jpeg__filename_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["MacaoBannerSection_Banners_Desktop_sizes__large_jpeg__url_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["MacaoBannerSection_Banners_Desktop_sizes__large_jpeg__width_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["MacaoBannerSection_Banners_Desktop_sizes__large_jpeg__height_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["MacaoBannerSection_Banners_Desktop_sizes__large_jpeg__mimeType_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["MacaoBannerSection_Banners_Desktop_sizes__large_jpeg__filesize_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["MacaoBannerSection_Banners_Desktop_sizes__large_jpeg__filename_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["MacaoBannerSection_Banners_Desktop_id_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["MacaoBannerSection_Banners_Desktop_where_and"]: {
	alt?: ValueTypes["MacaoBannerSection_Banners_Desktop_alt_operator"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["MacaoBannerSection_Banners_Desktop_updatedAt_operator"] | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["MacaoBannerSection_Banners_Desktop_createdAt_operator"] | undefined | null | Variable<any, string>,
	url?: ValueTypes["MacaoBannerSection_Banners_Desktop_url_operator"] | undefined | null | Variable<any, string>,
	filename?: ValueTypes["MacaoBannerSection_Banners_Desktop_filename_operator"] | undefined | null | Variable<any, string>,
	mimeType?: ValueTypes["MacaoBannerSection_Banners_Desktop_mimeType_operator"] | undefined | null | Variable<any, string>,
	filesize?: ValueTypes["MacaoBannerSection_Banners_Desktop_filesize_operator"] | undefined | null | Variable<any, string>,
	width?: ValueTypes["MacaoBannerSection_Banners_Desktop_width_operator"] | undefined | null | Variable<any, string>,
	height?: ValueTypes["MacaoBannerSection_Banners_Desktop_height_operator"] | undefined | null | Variable<any, string>,
	focalX?: ValueTypes["MacaoBannerSection_Banners_Desktop_focalX_operator"] | undefined | null | Variable<any, string>,
	focalY?: ValueTypes["MacaoBannerSection_Banners_Desktop_focalY_operator"] | undefined | null | Variable<any, string>,
	sizes__small__url?: ValueTypes["MacaoBannerSection_Banners_Desktop_sizes__small__url_operator"] | undefined | null | Variable<any, string>,
	sizes__small__width?: ValueTypes["MacaoBannerSection_Banners_Desktop_sizes__small__width_operator"] | undefined | null | Variable<any, string>,
	sizes__small__height?: ValueTypes["MacaoBannerSection_Banners_Desktop_sizes__small__height_operator"] | undefined | null | Variable<any, string>,
	sizes__small__mimeType?: ValueTypes["MacaoBannerSection_Banners_Desktop_sizes__small__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__small__filesize?: ValueTypes["MacaoBannerSection_Banners_Desktop_sizes__small__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__small__filename?: ValueTypes["MacaoBannerSection_Banners_Desktop_sizes__small__filename_operator"] | undefined | null | Variable<any, string>,
	sizes__medium__url?: ValueTypes["MacaoBannerSection_Banners_Desktop_sizes__medium__url_operator"] | undefined | null | Variable<any, string>,
	sizes__medium__width?: ValueTypes["MacaoBannerSection_Banners_Desktop_sizes__medium__width_operator"] | undefined | null | Variable<any, string>,
	sizes__medium__height?: ValueTypes["MacaoBannerSection_Banners_Desktop_sizes__medium__height_operator"] | undefined | null | Variable<any, string>,
	sizes__medium__mimeType?: ValueTypes["MacaoBannerSection_Banners_Desktop_sizes__medium__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__medium__filesize?: ValueTypes["MacaoBannerSection_Banners_Desktop_sizes__medium__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__medium__filename?: ValueTypes["MacaoBannerSection_Banners_Desktop_sizes__medium__filename_operator"] | undefined | null | Variable<any, string>,
	sizes__large__url?: ValueTypes["MacaoBannerSection_Banners_Desktop_sizes__large__url_operator"] | undefined | null | Variable<any, string>,
	sizes__large__width?: ValueTypes["MacaoBannerSection_Banners_Desktop_sizes__large__width_operator"] | undefined | null | Variable<any, string>,
	sizes__large__height?: ValueTypes["MacaoBannerSection_Banners_Desktop_sizes__large__height_operator"] | undefined | null | Variable<any, string>,
	sizes__large__mimeType?: ValueTypes["MacaoBannerSection_Banners_Desktop_sizes__large__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__large__filesize?: ValueTypes["MacaoBannerSection_Banners_Desktop_sizes__large__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__large__filename?: ValueTypes["MacaoBannerSection_Banners_Desktop_sizes__large__filename_operator"] | undefined | null | Variable<any, string>,
	sizes__small_jpeg__url?: ValueTypes["MacaoBannerSection_Banners_Desktop_sizes__small_jpeg__url_operator"] | undefined | null | Variable<any, string>,
	sizes__small_jpeg__width?: ValueTypes["MacaoBannerSection_Banners_Desktop_sizes__small_jpeg__width_operator"] | undefined | null | Variable<any, string>,
	sizes__small_jpeg__height?: ValueTypes["MacaoBannerSection_Banners_Desktop_sizes__small_jpeg__height_operator"] | undefined | null | Variable<any, string>,
	sizes__small_jpeg__mimeType?: ValueTypes["MacaoBannerSection_Banners_Desktop_sizes__small_jpeg__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__small_jpeg__filesize?: ValueTypes["MacaoBannerSection_Banners_Desktop_sizes__small_jpeg__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__small_jpeg__filename?: ValueTypes["MacaoBannerSection_Banners_Desktop_sizes__small_jpeg__filename_operator"] | undefined | null | Variable<any, string>,
	sizes__medium_jpeg__url?: ValueTypes["MacaoBannerSection_Banners_Desktop_sizes__medium_jpeg__url_operator"] | undefined | null | Variable<any, string>,
	sizes__medium_jpeg__width?: ValueTypes["MacaoBannerSection_Banners_Desktop_sizes__medium_jpeg__width_operator"] | undefined | null | Variable<any, string>,
	sizes__medium_jpeg__height?: ValueTypes["MacaoBannerSection_Banners_Desktop_sizes__medium_jpeg__height_operator"] | undefined | null | Variable<any, string>,
	sizes__medium_jpeg__mimeType?: ValueTypes["MacaoBannerSection_Banners_Desktop_sizes__medium_jpeg__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__medium_jpeg__filesize?: ValueTypes["MacaoBannerSection_Banners_Desktop_sizes__medium_jpeg__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__medium_jpeg__filename?: ValueTypes["MacaoBannerSection_Banners_Desktop_sizes__medium_jpeg__filename_operator"] | undefined | null | Variable<any, string>,
	sizes__large_jpeg__url?: ValueTypes["MacaoBannerSection_Banners_Desktop_sizes__large_jpeg__url_operator"] | undefined | null | Variable<any, string>,
	sizes__large_jpeg__width?: ValueTypes["MacaoBannerSection_Banners_Desktop_sizes__large_jpeg__width_operator"] | undefined | null | Variable<any, string>,
	sizes__large_jpeg__height?: ValueTypes["MacaoBannerSection_Banners_Desktop_sizes__large_jpeg__height_operator"] | undefined | null | Variable<any, string>,
	sizes__large_jpeg__mimeType?: ValueTypes["MacaoBannerSection_Banners_Desktop_sizes__large_jpeg__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__large_jpeg__filesize?: ValueTypes["MacaoBannerSection_Banners_Desktop_sizes__large_jpeg__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__large_jpeg__filename?: ValueTypes["MacaoBannerSection_Banners_Desktop_sizes__large_jpeg__filename_operator"] | undefined | null | Variable<any, string>,
	id?: ValueTypes["MacaoBannerSection_Banners_Desktop_id_operator"] | undefined | null | Variable<any, string>,
	AND?: Array<ValueTypes["MacaoBannerSection_Banners_Desktop_where_and"] | undefined | null> | undefined | null | Variable<any, string>,
	OR?: Array<ValueTypes["MacaoBannerSection_Banners_Desktop_where_or"] | undefined | null> | undefined | null | Variable<any, string>
};
	["MacaoBannerSection_Banners_Desktop_where_or"]: {
	alt?: ValueTypes["MacaoBannerSection_Banners_Desktop_alt_operator"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["MacaoBannerSection_Banners_Desktop_updatedAt_operator"] | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["MacaoBannerSection_Banners_Desktop_createdAt_operator"] | undefined | null | Variable<any, string>,
	url?: ValueTypes["MacaoBannerSection_Banners_Desktop_url_operator"] | undefined | null | Variable<any, string>,
	filename?: ValueTypes["MacaoBannerSection_Banners_Desktop_filename_operator"] | undefined | null | Variable<any, string>,
	mimeType?: ValueTypes["MacaoBannerSection_Banners_Desktop_mimeType_operator"] | undefined | null | Variable<any, string>,
	filesize?: ValueTypes["MacaoBannerSection_Banners_Desktop_filesize_operator"] | undefined | null | Variable<any, string>,
	width?: ValueTypes["MacaoBannerSection_Banners_Desktop_width_operator"] | undefined | null | Variable<any, string>,
	height?: ValueTypes["MacaoBannerSection_Banners_Desktop_height_operator"] | undefined | null | Variable<any, string>,
	focalX?: ValueTypes["MacaoBannerSection_Banners_Desktop_focalX_operator"] | undefined | null | Variable<any, string>,
	focalY?: ValueTypes["MacaoBannerSection_Banners_Desktop_focalY_operator"] | undefined | null | Variable<any, string>,
	sizes__small__url?: ValueTypes["MacaoBannerSection_Banners_Desktop_sizes__small__url_operator"] | undefined | null | Variable<any, string>,
	sizes__small__width?: ValueTypes["MacaoBannerSection_Banners_Desktop_sizes__small__width_operator"] | undefined | null | Variable<any, string>,
	sizes__small__height?: ValueTypes["MacaoBannerSection_Banners_Desktop_sizes__small__height_operator"] | undefined | null | Variable<any, string>,
	sizes__small__mimeType?: ValueTypes["MacaoBannerSection_Banners_Desktop_sizes__small__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__small__filesize?: ValueTypes["MacaoBannerSection_Banners_Desktop_sizes__small__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__small__filename?: ValueTypes["MacaoBannerSection_Banners_Desktop_sizes__small__filename_operator"] | undefined | null | Variable<any, string>,
	sizes__medium__url?: ValueTypes["MacaoBannerSection_Banners_Desktop_sizes__medium__url_operator"] | undefined | null | Variable<any, string>,
	sizes__medium__width?: ValueTypes["MacaoBannerSection_Banners_Desktop_sizes__medium__width_operator"] | undefined | null | Variable<any, string>,
	sizes__medium__height?: ValueTypes["MacaoBannerSection_Banners_Desktop_sizes__medium__height_operator"] | undefined | null | Variable<any, string>,
	sizes__medium__mimeType?: ValueTypes["MacaoBannerSection_Banners_Desktop_sizes__medium__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__medium__filesize?: ValueTypes["MacaoBannerSection_Banners_Desktop_sizes__medium__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__medium__filename?: ValueTypes["MacaoBannerSection_Banners_Desktop_sizes__medium__filename_operator"] | undefined | null | Variable<any, string>,
	sizes__large__url?: ValueTypes["MacaoBannerSection_Banners_Desktop_sizes__large__url_operator"] | undefined | null | Variable<any, string>,
	sizes__large__width?: ValueTypes["MacaoBannerSection_Banners_Desktop_sizes__large__width_operator"] | undefined | null | Variable<any, string>,
	sizes__large__height?: ValueTypes["MacaoBannerSection_Banners_Desktop_sizes__large__height_operator"] | undefined | null | Variable<any, string>,
	sizes__large__mimeType?: ValueTypes["MacaoBannerSection_Banners_Desktop_sizes__large__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__large__filesize?: ValueTypes["MacaoBannerSection_Banners_Desktop_sizes__large__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__large__filename?: ValueTypes["MacaoBannerSection_Banners_Desktop_sizes__large__filename_operator"] | undefined | null | Variable<any, string>,
	sizes__small_jpeg__url?: ValueTypes["MacaoBannerSection_Banners_Desktop_sizes__small_jpeg__url_operator"] | undefined | null | Variable<any, string>,
	sizes__small_jpeg__width?: ValueTypes["MacaoBannerSection_Banners_Desktop_sizes__small_jpeg__width_operator"] | undefined | null | Variable<any, string>,
	sizes__small_jpeg__height?: ValueTypes["MacaoBannerSection_Banners_Desktop_sizes__small_jpeg__height_operator"] | undefined | null | Variable<any, string>,
	sizes__small_jpeg__mimeType?: ValueTypes["MacaoBannerSection_Banners_Desktop_sizes__small_jpeg__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__small_jpeg__filesize?: ValueTypes["MacaoBannerSection_Banners_Desktop_sizes__small_jpeg__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__small_jpeg__filename?: ValueTypes["MacaoBannerSection_Banners_Desktop_sizes__small_jpeg__filename_operator"] | undefined | null | Variable<any, string>,
	sizes__medium_jpeg__url?: ValueTypes["MacaoBannerSection_Banners_Desktop_sizes__medium_jpeg__url_operator"] | undefined | null | Variable<any, string>,
	sizes__medium_jpeg__width?: ValueTypes["MacaoBannerSection_Banners_Desktop_sizes__medium_jpeg__width_operator"] | undefined | null | Variable<any, string>,
	sizes__medium_jpeg__height?: ValueTypes["MacaoBannerSection_Banners_Desktop_sizes__medium_jpeg__height_operator"] | undefined | null | Variable<any, string>,
	sizes__medium_jpeg__mimeType?: ValueTypes["MacaoBannerSection_Banners_Desktop_sizes__medium_jpeg__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__medium_jpeg__filesize?: ValueTypes["MacaoBannerSection_Banners_Desktop_sizes__medium_jpeg__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__medium_jpeg__filename?: ValueTypes["MacaoBannerSection_Banners_Desktop_sizes__medium_jpeg__filename_operator"] | undefined | null | Variable<any, string>,
	sizes__large_jpeg__url?: ValueTypes["MacaoBannerSection_Banners_Desktop_sizes__large_jpeg__url_operator"] | undefined | null | Variable<any, string>,
	sizes__large_jpeg__width?: ValueTypes["MacaoBannerSection_Banners_Desktop_sizes__large_jpeg__width_operator"] | undefined | null | Variable<any, string>,
	sizes__large_jpeg__height?: ValueTypes["MacaoBannerSection_Banners_Desktop_sizes__large_jpeg__height_operator"] | undefined | null | Variable<any, string>,
	sizes__large_jpeg__mimeType?: ValueTypes["MacaoBannerSection_Banners_Desktop_sizes__large_jpeg__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__large_jpeg__filesize?: ValueTypes["MacaoBannerSection_Banners_Desktop_sizes__large_jpeg__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__large_jpeg__filename?: ValueTypes["MacaoBannerSection_Banners_Desktop_sizes__large_jpeg__filename_operator"] | undefined | null | Variable<any, string>,
	id?: ValueTypes["MacaoBannerSection_Banners_Desktop_id_operator"] | undefined | null | Variable<any, string>,
	AND?: Array<ValueTypes["MacaoBannerSection_Banners_Desktop_where_and"] | undefined | null> | undefined | null | Variable<any, string>,
	OR?: Array<ValueTypes["MacaoBannerSection_Banners_Desktop_where_or"] | undefined | null> | undefined | null | Variable<any, string>
};
	["MacaoBannerSection_Banners_region"]:MacaoBannerSection_Banners_region;
	["MacaoBannerSections"]: AliasType<{
	docs?:ValueTypes["MacaoBannerSection"],
	hasNextPage?:boolean | `@${string}`,
	hasPrevPage?:boolean | `@${string}`,
	limit?:boolean | `@${string}`,
	nextPage?:boolean | `@${string}`,
	offset?:boolean | `@${string}`,
	page?:boolean | `@${string}`,
	pagingCounter?:boolean | `@${string}`,
	prevPage?:boolean | `@${string}`,
	totalDocs?:boolean | `@${string}`,
	totalPages?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MacaoBannerSection_where"]: {
	name?: ValueTypes["MacaoBannerSection_name_operator"] | undefined | null | Variable<any, string>,
	slug?: ValueTypes["MacaoBannerSection_slug_operator"] | undefined | null | Variable<any, string>,
	banners__name?: ValueTypes["MacaoBannerSection_banners__name_operator"] | undefined | null | Variable<any, string>,
	banners__status?: ValueTypes["MacaoBannerSection_banners__status_operator"] | undefined | null | Variable<any, string>,
	banners__desktop?: ValueTypes["MacaoBannerSection_banners__desktop_operator"] | undefined | null | Variable<any, string>,
	banners__bannerStartDate?: ValueTypes["MacaoBannerSection_banners__bannerStartDate_operator"] | undefined | null | Variable<any, string>,
	banners__bannerEndDate?: ValueTypes["MacaoBannerSection_banners__bannerEndDate_operator"] | undefined | null | Variable<any, string>,
	banners__region?: ValueTypes["MacaoBannerSection_banners__region_operator"] | undefined | null | Variable<any, string>,
	banners__id?: ValueTypes["MacaoBannerSection_banners__id_operator"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["MacaoBannerSection_updatedAt_operator"] | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["MacaoBannerSection_createdAt_operator"] | undefined | null | Variable<any, string>,
	id?: ValueTypes["MacaoBannerSection_id_operator"] | undefined | null | Variable<any, string>,
	AND?: Array<ValueTypes["MacaoBannerSection_where_and"] | undefined | null> | undefined | null | Variable<any, string>,
	OR?: Array<ValueTypes["MacaoBannerSection_where_or"] | undefined | null> | undefined | null | Variable<any, string>
};
	["MacaoBannerSection_name_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["MacaoBannerSection_slug_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["MacaoBannerSection_banners__name_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["MacaoBannerSection_banners__status_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["MacaoBannerSection_banners__desktop_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>
};
	["MacaoBannerSection_banners__bannerStartDate_operator"]: {
	equals?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	not_equals?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	greater_than_equal?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	greater_than?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	less_than_equal?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	less_than?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	like?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["MacaoBannerSection_banners__bannerEndDate_operator"]: {
	equals?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	not_equals?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	greater_than_equal?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	greater_than?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	less_than_equal?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	less_than?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	like?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["MacaoBannerSection_banners__region_operator"]: {
	equals?: ValueTypes["MacaoBannerSection_banners__region_Input"] | undefined | null | Variable<any, string>,
	not_equals?: ValueTypes["MacaoBannerSection_banners__region_Input"] | undefined | null | Variable<any, string>,
	in?: Array<ValueTypes["MacaoBannerSection_banners__region_Input"] | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<ValueTypes["MacaoBannerSection_banners__region_Input"] | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<ValueTypes["MacaoBannerSection_banners__region_Input"] | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["MacaoBannerSection_banners__region_Input"]:MacaoBannerSection_banners__region_Input;
	["MacaoBannerSection_banners__id_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["MacaoBannerSection_updatedAt_operator"]: {
	equals?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	not_equals?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	greater_than_equal?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	greater_than?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	less_than_equal?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	less_than?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	like?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["MacaoBannerSection_createdAt_operator"]: {
	equals?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	not_equals?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	greater_than_equal?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	greater_than?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	less_than_equal?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	less_than?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	like?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["MacaoBannerSection_id_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["MacaoBannerSection_where_and"]: {
	name?: ValueTypes["MacaoBannerSection_name_operator"] | undefined | null | Variable<any, string>,
	slug?: ValueTypes["MacaoBannerSection_slug_operator"] | undefined | null | Variable<any, string>,
	banners__name?: ValueTypes["MacaoBannerSection_banners__name_operator"] | undefined | null | Variable<any, string>,
	banners__status?: ValueTypes["MacaoBannerSection_banners__status_operator"] | undefined | null | Variable<any, string>,
	banners__desktop?: ValueTypes["MacaoBannerSection_banners__desktop_operator"] | undefined | null | Variable<any, string>,
	banners__bannerStartDate?: ValueTypes["MacaoBannerSection_banners__bannerStartDate_operator"] | undefined | null | Variable<any, string>,
	banners__bannerEndDate?: ValueTypes["MacaoBannerSection_banners__bannerEndDate_operator"] | undefined | null | Variable<any, string>,
	banners__region?: ValueTypes["MacaoBannerSection_banners__region_operator"] | undefined | null | Variable<any, string>,
	banners__id?: ValueTypes["MacaoBannerSection_banners__id_operator"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["MacaoBannerSection_updatedAt_operator"] | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["MacaoBannerSection_createdAt_operator"] | undefined | null | Variable<any, string>,
	id?: ValueTypes["MacaoBannerSection_id_operator"] | undefined | null | Variable<any, string>,
	AND?: Array<ValueTypes["MacaoBannerSection_where_and"] | undefined | null> | undefined | null | Variable<any, string>,
	OR?: Array<ValueTypes["MacaoBannerSection_where_or"] | undefined | null> | undefined | null | Variable<any, string>
};
	["MacaoBannerSection_where_or"]: {
	name?: ValueTypes["MacaoBannerSection_name_operator"] | undefined | null | Variable<any, string>,
	slug?: ValueTypes["MacaoBannerSection_slug_operator"] | undefined | null | Variable<any, string>,
	banners__name?: ValueTypes["MacaoBannerSection_banners__name_operator"] | undefined | null | Variable<any, string>,
	banners__status?: ValueTypes["MacaoBannerSection_banners__status_operator"] | undefined | null | Variable<any, string>,
	banners__desktop?: ValueTypes["MacaoBannerSection_banners__desktop_operator"] | undefined | null | Variable<any, string>,
	banners__bannerStartDate?: ValueTypes["MacaoBannerSection_banners__bannerStartDate_operator"] | undefined | null | Variable<any, string>,
	banners__bannerEndDate?: ValueTypes["MacaoBannerSection_banners__bannerEndDate_operator"] | undefined | null | Variable<any, string>,
	banners__region?: ValueTypes["MacaoBannerSection_banners__region_operator"] | undefined | null | Variable<any, string>,
	banners__id?: ValueTypes["MacaoBannerSection_banners__id_operator"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["MacaoBannerSection_updatedAt_operator"] | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["MacaoBannerSection_createdAt_operator"] | undefined | null | Variable<any, string>,
	id?: ValueTypes["MacaoBannerSection_id_operator"] | undefined | null | Variable<any, string>,
	AND?: Array<ValueTypes["MacaoBannerSection_where_and"] | undefined | null> | undefined | null | Variable<any, string>,
	OR?: Array<ValueTypes["MacaoBannerSection_where_or"] | undefined | null> | undefined | null | Variable<any, string>
};
	["countMacaoBannerSections"]: AliasType<{
	totalDocs?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["macao_banner_sectionDocAccess"]: AliasType<{
	fields?:ValueTypes["MacaoBannerSectionDocAccessFields"],
	create?:ValueTypes["MacaoBannerSectionCreateDocAccess"],
	read?:ValueTypes["MacaoBannerSectionReadDocAccess"],
	update?:ValueTypes["MacaoBannerSectionUpdateDocAccess"],
	delete?:ValueTypes["MacaoBannerSectionDeleteDocAccess"],
		__typename?: boolean | `@${string}`
}>;
	["MacaoBannerSectionDocAccessFields"]: AliasType<{
	name?:ValueTypes["MacaoBannerSectionDocAccessFields_name"],
	slug?:ValueTypes["MacaoBannerSectionDocAccessFields_slug"],
	banners?:ValueTypes["MacaoBannerSectionDocAccessFields_banners"],
	updatedAt?:ValueTypes["MacaoBannerSectionDocAccessFields_updatedAt"],
	createdAt?:ValueTypes["MacaoBannerSectionDocAccessFields_createdAt"],
		__typename?: boolean | `@${string}`
}>;
	["MacaoBannerSectionDocAccessFields_name"]: AliasType<{
	create?:ValueTypes["MacaoBannerSectionDocAccessFields_name_Create"],
	read?:ValueTypes["MacaoBannerSectionDocAccessFields_name_Read"],
	update?:ValueTypes["MacaoBannerSectionDocAccessFields_name_Update"],
	delete?:ValueTypes["MacaoBannerSectionDocAccessFields_name_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["MacaoBannerSectionDocAccessFields_name_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MacaoBannerSectionDocAccessFields_name_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MacaoBannerSectionDocAccessFields_name_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MacaoBannerSectionDocAccessFields_name_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MacaoBannerSectionDocAccessFields_slug"]: AliasType<{
	create?:ValueTypes["MacaoBannerSectionDocAccessFields_slug_Create"],
	read?:ValueTypes["MacaoBannerSectionDocAccessFields_slug_Read"],
	update?:ValueTypes["MacaoBannerSectionDocAccessFields_slug_Update"],
	delete?:ValueTypes["MacaoBannerSectionDocAccessFields_slug_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["MacaoBannerSectionDocAccessFields_slug_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MacaoBannerSectionDocAccessFields_slug_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MacaoBannerSectionDocAccessFields_slug_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MacaoBannerSectionDocAccessFields_slug_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MacaoBannerSectionDocAccessFields_banners"]: AliasType<{
	create?:ValueTypes["MacaoBannerSectionDocAccessFields_banners_Create"],
	read?:ValueTypes["MacaoBannerSectionDocAccessFields_banners_Read"],
	update?:ValueTypes["MacaoBannerSectionDocAccessFields_banners_Update"],
	delete?:ValueTypes["MacaoBannerSectionDocAccessFields_banners_Delete"],
	fields?:ValueTypes["MacaoBannerSectionDocAccessFields_banners_Fields"],
		__typename?: boolean | `@${string}`
}>;
	["MacaoBannerSectionDocAccessFields_banners_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MacaoBannerSectionDocAccessFields_banners_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MacaoBannerSectionDocAccessFields_banners_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MacaoBannerSectionDocAccessFields_banners_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MacaoBannerSectionDocAccessFields_banners_Fields"]: AliasType<{
	name?:ValueTypes["MacaoBannerSectionDocAccessFields_banners_name"],
	status?:ValueTypes["MacaoBannerSectionDocAccessFields_banners_status"],
	desktop?:ValueTypes["MacaoBannerSectionDocAccessFields_banners_desktop"],
	bannerStartDate?:ValueTypes["MacaoBannerSectionDocAccessFields_banners_bannerStartDate"],
	bannerEndDate?:ValueTypes["MacaoBannerSectionDocAccessFields_banners_bannerEndDate"],
	region?:ValueTypes["MacaoBannerSectionDocAccessFields_banners_region"],
	id?:ValueTypes["MacaoBannerSectionDocAccessFields_banners_id"],
		__typename?: boolean | `@${string}`
}>;
	["MacaoBannerSectionDocAccessFields_banners_name"]: AliasType<{
	create?:ValueTypes["MacaoBannerSectionDocAccessFields_banners_name_Create"],
	read?:ValueTypes["MacaoBannerSectionDocAccessFields_banners_name_Read"],
	update?:ValueTypes["MacaoBannerSectionDocAccessFields_banners_name_Update"],
	delete?:ValueTypes["MacaoBannerSectionDocAccessFields_banners_name_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["MacaoBannerSectionDocAccessFields_banners_name_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MacaoBannerSectionDocAccessFields_banners_name_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MacaoBannerSectionDocAccessFields_banners_name_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MacaoBannerSectionDocAccessFields_banners_name_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MacaoBannerSectionDocAccessFields_banners_status"]: AliasType<{
	create?:ValueTypes["MacaoBannerSectionDocAccessFields_banners_status_Create"],
	read?:ValueTypes["MacaoBannerSectionDocAccessFields_banners_status_Read"],
	update?:ValueTypes["MacaoBannerSectionDocAccessFields_banners_status_Update"],
	delete?:ValueTypes["MacaoBannerSectionDocAccessFields_banners_status_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["MacaoBannerSectionDocAccessFields_banners_status_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MacaoBannerSectionDocAccessFields_banners_status_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MacaoBannerSectionDocAccessFields_banners_status_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MacaoBannerSectionDocAccessFields_banners_status_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MacaoBannerSectionDocAccessFields_banners_desktop"]: AliasType<{
	create?:ValueTypes["MacaoBannerSectionDocAccessFields_banners_desktop_Create"],
	read?:ValueTypes["MacaoBannerSectionDocAccessFields_banners_desktop_Read"],
	update?:ValueTypes["MacaoBannerSectionDocAccessFields_banners_desktop_Update"],
	delete?:ValueTypes["MacaoBannerSectionDocAccessFields_banners_desktop_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["MacaoBannerSectionDocAccessFields_banners_desktop_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MacaoBannerSectionDocAccessFields_banners_desktop_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MacaoBannerSectionDocAccessFields_banners_desktop_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MacaoBannerSectionDocAccessFields_banners_desktop_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MacaoBannerSectionDocAccessFields_banners_bannerStartDate"]: AliasType<{
	create?:ValueTypes["MacaoBannerSectionDocAccessFields_banners_bannerStartDate_Create"],
	read?:ValueTypes["MacaoBannerSectionDocAccessFields_banners_bannerStartDate_Read"],
	update?:ValueTypes["MacaoBannerSectionDocAccessFields_banners_bannerStartDate_Update"],
	delete?:ValueTypes["MacaoBannerSectionDocAccessFields_banners_bannerStartDate_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["MacaoBannerSectionDocAccessFields_banners_bannerStartDate_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MacaoBannerSectionDocAccessFields_banners_bannerStartDate_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MacaoBannerSectionDocAccessFields_banners_bannerStartDate_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MacaoBannerSectionDocAccessFields_banners_bannerStartDate_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MacaoBannerSectionDocAccessFields_banners_bannerEndDate"]: AliasType<{
	create?:ValueTypes["MacaoBannerSectionDocAccessFields_banners_bannerEndDate_Create"],
	read?:ValueTypes["MacaoBannerSectionDocAccessFields_banners_bannerEndDate_Read"],
	update?:ValueTypes["MacaoBannerSectionDocAccessFields_banners_bannerEndDate_Update"],
	delete?:ValueTypes["MacaoBannerSectionDocAccessFields_banners_bannerEndDate_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["MacaoBannerSectionDocAccessFields_banners_bannerEndDate_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MacaoBannerSectionDocAccessFields_banners_bannerEndDate_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MacaoBannerSectionDocAccessFields_banners_bannerEndDate_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MacaoBannerSectionDocAccessFields_banners_bannerEndDate_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MacaoBannerSectionDocAccessFields_banners_region"]: AliasType<{
	create?:ValueTypes["MacaoBannerSectionDocAccessFields_banners_region_Create"],
	read?:ValueTypes["MacaoBannerSectionDocAccessFields_banners_region_Read"],
	update?:ValueTypes["MacaoBannerSectionDocAccessFields_banners_region_Update"],
	delete?:ValueTypes["MacaoBannerSectionDocAccessFields_banners_region_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["MacaoBannerSectionDocAccessFields_banners_region_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MacaoBannerSectionDocAccessFields_banners_region_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MacaoBannerSectionDocAccessFields_banners_region_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MacaoBannerSectionDocAccessFields_banners_region_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MacaoBannerSectionDocAccessFields_banners_id"]: AliasType<{
	create?:ValueTypes["MacaoBannerSectionDocAccessFields_banners_id_Create"],
	read?:ValueTypes["MacaoBannerSectionDocAccessFields_banners_id_Read"],
	update?:ValueTypes["MacaoBannerSectionDocAccessFields_banners_id_Update"],
	delete?:ValueTypes["MacaoBannerSectionDocAccessFields_banners_id_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["MacaoBannerSectionDocAccessFields_banners_id_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MacaoBannerSectionDocAccessFields_banners_id_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MacaoBannerSectionDocAccessFields_banners_id_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MacaoBannerSectionDocAccessFields_banners_id_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MacaoBannerSectionDocAccessFields_updatedAt"]: AliasType<{
	create?:ValueTypes["MacaoBannerSectionDocAccessFields_updatedAt_Create"],
	read?:ValueTypes["MacaoBannerSectionDocAccessFields_updatedAt_Read"],
	update?:ValueTypes["MacaoBannerSectionDocAccessFields_updatedAt_Update"],
	delete?:ValueTypes["MacaoBannerSectionDocAccessFields_updatedAt_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["MacaoBannerSectionDocAccessFields_updatedAt_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MacaoBannerSectionDocAccessFields_updatedAt_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MacaoBannerSectionDocAccessFields_updatedAt_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MacaoBannerSectionDocAccessFields_updatedAt_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MacaoBannerSectionDocAccessFields_createdAt"]: AliasType<{
	create?:ValueTypes["MacaoBannerSectionDocAccessFields_createdAt_Create"],
	read?:ValueTypes["MacaoBannerSectionDocAccessFields_createdAt_Read"],
	update?:ValueTypes["MacaoBannerSectionDocAccessFields_createdAt_Update"],
	delete?:ValueTypes["MacaoBannerSectionDocAccessFields_createdAt_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["MacaoBannerSectionDocAccessFields_createdAt_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MacaoBannerSectionDocAccessFields_createdAt_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MacaoBannerSectionDocAccessFields_createdAt_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MacaoBannerSectionDocAccessFields_createdAt_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MacaoBannerSectionCreateDocAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MacaoBannerSectionReadDocAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MacaoBannerSectionUpdateDocAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MacaoBannerSectionDeleteDocAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["Banner"]: AliasType<{
	id?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
desktop?: [{	locale?: ValueTypes["LocaleInputType"] | undefined | null | Variable<any, string>,	fallbackLocale?: ValueTypes["FallbackLocaleInputType"] | undefined | null | Variable<any, string>,	where?: ValueTypes["Banner_Desktop_where"] | undefined | null | Variable<any, string>},ValueTypes["Media"]],
mobile?: [{	locale?: ValueTypes["LocaleInputType"] | undefined | null | Variable<any, string>,	fallbackLocale?: ValueTypes["FallbackLocaleInputType"] | undefined | null | Variable<any, string>,	where?: ValueTypes["Banner_Mobile_where"] | undefined | null | Variable<any, string>},ValueTypes["Media"]],
	bannerStartDate?:boolean | `@${string}`,
	bannerEndDate?:boolean | `@${string}`,
	location?:ValueTypes["Banner_Location"],
	link?:boolean | `@${string}`,
	external?:boolean | `@${string}`,
	type?:boolean | `@${string}`,
	status?:boolean | `@${string}`,
	advertise?:ValueTypes["Banner_Advertise"],
	_locations?:boolean | `@${string}`,
	region?:boolean | `@${string}`,
	homePageClick?:boolean | `@${string}`,
	homePageImpression?:boolean | `@${string}`,
	latestEventClick?:boolean | `@${string}`,
	latestEventImpression?:boolean | `@${string}`,
	editorChoiceClick?:boolean | `@${string}`,
	editorChoiceImpression?:boolean | `@${string}`,
	eventListClick?:boolean | `@${string}`,
	eventListImpression?:boolean | `@${string}`,
	topTenClick?:boolean | `@${string}`,
	topTenImpression?:boolean | `@${string}`,
	miscClick?:boolean | `@${string}`,
	miscImpression?:boolean | `@${string}`,
	clickTotal?:boolean | `@${string}`,
	totalImpression?:boolean | `@${string}`,
	updatedAt?:boolean | `@${string}`,
	createdAt?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["Banner_Desktop_where"]: {
	alt?: ValueTypes["Banner_Desktop_alt_operator"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["Banner_Desktop_updatedAt_operator"] | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["Banner_Desktop_createdAt_operator"] | undefined | null | Variable<any, string>,
	url?: ValueTypes["Banner_Desktop_url_operator"] | undefined | null | Variable<any, string>,
	filename?: ValueTypes["Banner_Desktop_filename_operator"] | undefined | null | Variable<any, string>,
	mimeType?: ValueTypes["Banner_Desktop_mimeType_operator"] | undefined | null | Variable<any, string>,
	filesize?: ValueTypes["Banner_Desktop_filesize_operator"] | undefined | null | Variable<any, string>,
	width?: ValueTypes["Banner_Desktop_width_operator"] | undefined | null | Variable<any, string>,
	height?: ValueTypes["Banner_Desktop_height_operator"] | undefined | null | Variable<any, string>,
	focalX?: ValueTypes["Banner_Desktop_focalX_operator"] | undefined | null | Variable<any, string>,
	focalY?: ValueTypes["Banner_Desktop_focalY_operator"] | undefined | null | Variable<any, string>,
	sizes__small__url?: ValueTypes["Banner_Desktop_sizes__small__url_operator"] | undefined | null | Variable<any, string>,
	sizes__small__width?: ValueTypes["Banner_Desktop_sizes__small__width_operator"] | undefined | null | Variable<any, string>,
	sizes__small__height?: ValueTypes["Banner_Desktop_sizes__small__height_operator"] | undefined | null | Variable<any, string>,
	sizes__small__mimeType?: ValueTypes["Banner_Desktop_sizes__small__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__small__filesize?: ValueTypes["Banner_Desktop_sizes__small__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__small__filename?: ValueTypes["Banner_Desktop_sizes__small__filename_operator"] | undefined | null | Variable<any, string>,
	sizes__medium__url?: ValueTypes["Banner_Desktop_sizes__medium__url_operator"] | undefined | null | Variable<any, string>,
	sizes__medium__width?: ValueTypes["Banner_Desktop_sizes__medium__width_operator"] | undefined | null | Variable<any, string>,
	sizes__medium__height?: ValueTypes["Banner_Desktop_sizes__medium__height_operator"] | undefined | null | Variable<any, string>,
	sizes__medium__mimeType?: ValueTypes["Banner_Desktop_sizes__medium__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__medium__filesize?: ValueTypes["Banner_Desktop_sizes__medium__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__medium__filename?: ValueTypes["Banner_Desktop_sizes__medium__filename_operator"] | undefined | null | Variable<any, string>,
	sizes__large__url?: ValueTypes["Banner_Desktop_sizes__large__url_operator"] | undefined | null | Variable<any, string>,
	sizes__large__width?: ValueTypes["Banner_Desktop_sizes__large__width_operator"] | undefined | null | Variable<any, string>,
	sizes__large__height?: ValueTypes["Banner_Desktop_sizes__large__height_operator"] | undefined | null | Variable<any, string>,
	sizes__large__mimeType?: ValueTypes["Banner_Desktop_sizes__large__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__large__filesize?: ValueTypes["Banner_Desktop_sizes__large__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__large__filename?: ValueTypes["Banner_Desktop_sizes__large__filename_operator"] | undefined | null | Variable<any, string>,
	sizes__small_jpeg__url?: ValueTypes["Banner_Desktop_sizes__small_jpeg__url_operator"] | undefined | null | Variable<any, string>,
	sizes__small_jpeg__width?: ValueTypes["Banner_Desktop_sizes__small_jpeg__width_operator"] | undefined | null | Variable<any, string>,
	sizes__small_jpeg__height?: ValueTypes["Banner_Desktop_sizes__small_jpeg__height_operator"] | undefined | null | Variable<any, string>,
	sizes__small_jpeg__mimeType?: ValueTypes["Banner_Desktop_sizes__small_jpeg__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__small_jpeg__filesize?: ValueTypes["Banner_Desktop_sizes__small_jpeg__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__small_jpeg__filename?: ValueTypes["Banner_Desktop_sizes__small_jpeg__filename_operator"] | undefined | null | Variable<any, string>,
	sizes__medium_jpeg__url?: ValueTypes["Banner_Desktop_sizes__medium_jpeg__url_operator"] | undefined | null | Variable<any, string>,
	sizes__medium_jpeg__width?: ValueTypes["Banner_Desktop_sizes__medium_jpeg__width_operator"] | undefined | null | Variable<any, string>,
	sizes__medium_jpeg__height?: ValueTypes["Banner_Desktop_sizes__medium_jpeg__height_operator"] | undefined | null | Variable<any, string>,
	sizes__medium_jpeg__mimeType?: ValueTypes["Banner_Desktop_sizes__medium_jpeg__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__medium_jpeg__filesize?: ValueTypes["Banner_Desktop_sizes__medium_jpeg__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__medium_jpeg__filename?: ValueTypes["Banner_Desktop_sizes__medium_jpeg__filename_operator"] | undefined | null | Variable<any, string>,
	sizes__large_jpeg__url?: ValueTypes["Banner_Desktop_sizes__large_jpeg__url_operator"] | undefined | null | Variable<any, string>,
	sizes__large_jpeg__width?: ValueTypes["Banner_Desktop_sizes__large_jpeg__width_operator"] | undefined | null | Variable<any, string>,
	sizes__large_jpeg__height?: ValueTypes["Banner_Desktop_sizes__large_jpeg__height_operator"] | undefined | null | Variable<any, string>,
	sizes__large_jpeg__mimeType?: ValueTypes["Banner_Desktop_sizes__large_jpeg__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__large_jpeg__filesize?: ValueTypes["Banner_Desktop_sizes__large_jpeg__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__large_jpeg__filename?: ValueTypes["Banner_Desktop_sizes__large_jpeg__filename_operator"] | undefined | null | Variable<any, string>,
	id?: ValueTypes["Banner_Desktop_id_operator"] | undefined | null | Variable<any, string>,
	AND?: Array<ValueTypes["Banner_Desktop_where_and"] | undefined | null> | undefined | null | Variable<any, string>,
	OR?: Array<ValueTypes["Banner_Desktop_where_or"] | undefined | null> | undefined | null | Variable<any, string>
};
	["Banner_Desktop_alt_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Banner_Desktop_updatedAt_operator"]: {
	equals?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	not_equals?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	greater_than_equal?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	greater_than?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	less_than_equal?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	less_than?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	like?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Banner_Desktop_createdAt_operator"]: {
	equals?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	not_equals?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	greater_than_equal?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	greater_than?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	less_than_equal?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	less_than?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	like?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Banner_Desktop_url_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Banner_Desktop_filename_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Banner_Desktop_mimeType_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Banner_Desktop_filesize_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Banner_Desktop_width_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Banner_Desktop_height_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Banner_Desktop_focalX_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Banner_Desktop_focalY_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Banner_Desktop_sizes__small__url_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Banner_Desktop_sizes__small__width_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Banner_Desktop_sizes__small__height_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Banner_Desktop_sizes__small__mimeType_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Banner_Desktop_sizes__small__filesize_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Banner_Desktop_sizes__small__filename_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Banner_Desktop_sizes__medium__url_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Banner_Desktop_sizes__medium__width_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Banner_Desktop_sizes__medium__height_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Banner_Desktop_sizes__medium__mimeType_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Banner_Desktop_sizes__medium__filesize_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Banner_Desktop_sizes__medium__filename_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Banner_Desktop_sizes__large__url_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Banner_Desktop_sizes__large__width_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Banner_Desktop_sizes__large__height_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Banner_Desktop_sizes__large__mimeType_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Banner_Desktop_sizes__large__filesize_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Banner_Desktop_sizes__large__filename_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Banner_Desktop_sizes__small_jpeg__url_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Banner_Desktop_sizes__small_jpeg__width_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Banner_Desktop_sizes__small_jpeg__height_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Banner_Desktop_sizes__small_jpeg__mimeType_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Banner_Desktop_sizes__small_jpeg__filesize_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Banner_Desktop_sizes__small_jpeg__filename_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Banner_Desktop_sizes__medium_jpeg__url_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Banner_Desktop_sizes__medium_jpeg__width_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Banner_Desktop_sizes__medium_jpeg__height_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Banner_Desktop_sizes__medium_jpeg__mimeType_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Banner_Desktop_sizes__medium_jpeg__filesize_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Banner_Desktop_sizes__medium_jpeg__filename_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Banner_Desktop_sizes__large_jpeg__url_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Banner_Desktop_sizes__large_jpeg__width_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Banner_Desktop_sizes__large_jpeg__height_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Banner_Desktop_sizes__large_jpeg__mimeType_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Banner_Desktop_sizes__large_jpeg__filesize_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Banner_Desktop_sizes__large_jpeg__filename_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Banner_Desktop_id_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Banner_Desktop_where_and"]: {
	alt?: ValueTypes["Banner_Desktop_alt_operator"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["Banner_Desktop_updatedAt_operator"] | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["Banner_Desktop_createdAt_operator"] | undefined | null | Variable<any, string>,
	url?: ValueTypes["Banner_Desktop_url_operator"] | undefined | null | Variable<any, string>,
	filename?: ValueTypes["Banner_Desktop_filename_operator"] | undefined | null | Variable<any, string>,
	mimeType?: ValueTypes["Banner_Desktop_mimeType_operator"] | undefined | null | Variable<any, string>,
	filesize?: ValueTypes["Banner_Desktop_filesize_operator"] | undefined | null | Variable<any, string>,
	width?: ValueTypes["Banner_Desktop_width_operator"] | undefined | null | Variable<any, string>,
	height?: ValueTypes["Banner_Desktop_height_operator"] | undefined | null | Variable<any, string>,
	focalX?: ValueTypes["Banner_Desktop_focalX_operator"] | undefined | null | Variable<any, string>,
	focalY?: ValueTypes["Banner_Desktop_focalY_operator"] | undefined | null | Variable<any, string>,
	sizes__small__url?: ValueTypes["Banner_Desktop_sizes__small__url_operator"] | undefined | null | Variable<any, string>,
	sizes__small__width?: ValueTypes["Banner_Desktop_sizes__small__width_operator"] | undefined | null | Variable<any, string>,
	sizes__small__height?: ValueTypes["Banner_Desktop_sizes__small__height_operator"] | undefined | null | Variable<any, string>,
	sizes__small__mimeType?: ValueTypes["Banner_Desktop_sizes__small__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__small__filesize?: ValueTypes["Banner_Desktop_sizes__small__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__small__filename?: ValueTypes["Banner_Desktop_sizes__small__filename_operator"] | undefined | null | Variable<any, string>,
	sizes__medium__url?: ValueTypes["Banner_Desktop_sizes__medium__url_operator"] | undefined | null | Variable<any, string>,
	sizes__medium__width?: ValueTypes["Banner_Desktop_sizes__medium__width_operator"] | undefined | null | Variable<any, string>,
	sizes__medium__height?: ValueTypes["Banner_Desktop_sizes__medium__height_operator"] | undefined | null | Variable<any, string>,
	sizes__medium__mimeType?: ValueTypes["Banner_Desktop_sizes__medium__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__medium__filesize?: ValueTypes["Banner_Desktop_sizes__medium__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__medium__filename?: ValueTypes["Banner_Desktop_sizes__medium__filename_operator"] | undefined | null | Variable<any, string>,
	sizes__large__url?: ValueTypes["Banner_Desktop_sizes__large__url_operator"] | undefined | null | Variable<any, string>,
	sizes__large__width?: ValueTypes["Banner_Desktop_sizes__large__width_operator"] | undefined | null | Variable<any, string>,
	sizes__large__height?: ValueTypes["Banner_Desktop_sizes__large__height_operator"] | undefined | null | Variable<any, string>,
	sizes__large__mimeType?: ValueTypes["Banner_Desktop_sizes__large__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__large__filesize?: ValueTypes["Banner_Desktop_sizes__large__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__large__filename?: ValueTypes["Banner_Desktop_sizes__large__filename_operator"] | undefined | null | Variable<any, string>,
	sizes__small_jpeg__url?: ValueTypes["Banner_Desktop_sizes__small_jpeg__url_operator"] | undefined | null | Variable<any, string>,
	sizes__small_jpeg__width?: ValueTypes["Banner_Desktop_sizes__small_jpeg__width_operator"] | undefined | null | Variable<any, string>,
	sizes__small_jpeg__height?: ValueTypes["Banner_Desktop_sizes__small_jpeg__height_operator"] | undefined | null | Variable<any, string>,
	sizes__small_jpeg__mimeType?: ValueTypes["Banner_Desktop_sizes__small_jpeg__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__small_jpeg__filesize?: ValueTypes["Banner_Desktop_sizes__small_jpeg__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__small_jpeg__filename?: ValueTypes["Banner_Desktop_sizes__small_jpeg__filename_operator"] | undefined | null | Variable<any, string>,
	sizes__medium_jpeg__url?: ValueTypes["Banner_Desktop_sizes__medium_jpeg__url_operator"] | undefined | null | Variable<any, string>,
	sizes__medium_jpeg__width?: ValueTypes["Banner_Desktop_sizes__medium_jpeg__width_operator"] | undefined | null | Variable<any, string>,
	sizes__medium_jpeg__height?: ValueTypes["Banner_Desktop_sizes__medium_jpeg__height_operator"] | undefined | null | Variable<any, string>,
	sizes__medium_jpeg__mimeType?: ValueTypes["Banner_Desktop_sizes__medium_jpeg__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__medium_jpeg__filesize?: ValueTypes["Banner_Desktop_sizes__medium_jpeg__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__medium_jpeg__filename?: ValueTypes["Banner_Desktop_sizes__medium_jpeg__filename_operator"] | undefined | null | Variable<any, string>,
	sizes__large_jpeg__url?: ValueTypes["Banner_Desktop_sizes__large_jpeg__url_operator"] | undefined | null | Variable<any, string>,
	sizes__large_jpeg__width?: ValueTypes["Banner_Desktop_sizes__large_jpeg__width_operator"] | undefined | null | Variable<any, string>,
	sizes__large_jpeg__height?: ValueTypes["Banner_Desktop_sizes__large_jpeg__height_operator"] | undefined | null | Variable<any, string>,
	sizes__large_jpeg__mimeType?: ValueTypes["Banner_Desktop_sizes__large_jpeg__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__large_jpeg__filesize?: ValueTypes["Banner_Desktop_sizes__large_jpeg__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__large_jpeg__filename?: ValueTypes["Banner_Desktop_sizes__large_jpeg__filename_operator"] | undefined | null | Variable<any, string>,
	id?: ValueTypes["Banner_Desktop_id_operator"] | undefined | null | Variable<any, string>,
	AND?: Array<ValueTypes["Banner_Desktop_where_and"] | undefined | null> | undefined | null | Variable<any, string>,
	OR?: Array<ValueTypes["Banner_Desktop_where_or"] | undefined | null> | undefined | null | Variable<any, string>
};
	["Banner_Desktop_where_or"]: {
	alt?: ValueTypes["Banner_Desktop_alt_operator"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["Banner_Desktop_updatedAt_operator"] | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["Banner_Desktop_createdAt_operator"] | undefined | null | Variable<any, string>,
	url?: ValueTypes["Banner_Desktop_url_operator"] | undefined | null | Variable<any, string>,
	filename?: ValueTypes["Banner_Desktop_filename_operator"] | undefined | null | Variable<any, string>,
	mimeType?: ValueTypes["Banner_Desktop_mimeType_operator"] | undefined | null | Variable<any, string>,
	filesize?: ValueTypes["Banner_Desktop_filesize_operator"] | undefined | null | Variable<any, string>,
	width?: ValueTypes["Banner_Desktop_width_operator"] | undefined | null | Variable<any, string>,
	height?: ValueTypes["Banner_Desktop_height_operator"] | undefined | null | Variable<any, string>,
	focalX?: ValueTypes["Banner_Desktop_focalX_operator"] | undefined | null | Variable<any, string>,
	focalY?: ValueTypes["Banner_Desktop_focalY_operator"] | undefined | null | Variable<any, string>,
	sizes__small__url?: ValueTypes["Banner_Desktop_sizes__small__url_operator"] | undefined | null | Variable<any, string>,
	sizes__small__width?: ValueTypes["Banner_Desktop_sizes__small__width_operator"] | undefined | null | Variable<any, string>,
	sizes__small__height?: ValueTypes["Banner_Desktop_sizes__small__height_operator"] | undefined | null | Variable<any, string>,
	sizes__small__mimeType?: ValueTypes["Banner_Desktop_sizes__small__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__small__filesize?: ValueTypes["Banner_Desktop_sizes__small__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__small__filename?: ValueTypes["Banner_Desktop_sizes__small__filename_operator"] | undefined | null | Variable<any, string>,
	sizes__medium__url?: ValueTypes["Banner_Desktop_sizes__medium__url_operator"] | undefined | null | Variable<any, string>,
	sizes__medium__width?: ValueTypes["Banner_Desktop_sizes__medium__width_operator"] | undefined | null | Variable<any, string>,
	sizes__medium__height?: ValueTypes["Banner_Desktop_sizes__medium__height_operator"] | undefined | null | Variable<any, string>,
	sizes__medium__mimeType?: ValueTypes["Banner_Desktop_sizes__medium__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__medium__filesize?: ValueTypes["Banner_Desktop_sizes__medium__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__medium__filename?: ValueTypes["Banner_Desktop_sizes__medium__filename_operator"] | undefined | null | Variable<any, string>,
	sizes__large__url?: ValueTypes["Banner_Desktop_sizes__large__url_operator"] | undefined | null | Variable<any, string>,
	sizes__large__width?: ValueTypes["Banner_Desktop_sizes__large__width_operator"] | undefined | null | Variable<any, string>,
	sizes__large__height?: ValueTypes["Banner_Desktop_sizes__large__height_operator"] | undefined | null | Variable<any, string>,
	sizes__large__mimeType?: ValueTypes["Banner_Desktop_sizes__large__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__large__filesize?: ValueTypes["Banner_Desktop_sizes__large__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__large__filename?: ValueTypes["Banner_Desktop_sizes__large__filename_operator"] | undefined | null | Variable<any, string>,
	sizes__small_jpeg__url?: ValueTypes["Banner_Desktop_sizes__small_jpeg__url_operator"] | undefined | null | Variable<any, string>,
	sizes__small_jpeg__width?: ValueTypes["Banner_Desktop_sizes__small_jpeg__width_operator"] | undefined | null | Variable<any, string>,
	sizes__small_jpeg__height?: ValueTypes["Banner_Desktop_sizes__small_jpeg__height_operator"] | undefined | null | Variable<any, string>,
	sizes__small_jpeg__mimeType?: ValueTypes["Banner_Desktop_sizes__small_jpeg__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__small_jpeg__filesize?: ValueTypes["Banner_Desktop_sizes__small_jpeg__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__small_jpeg__filename?: ValueTypes["Banner_Desktop_sizes__small_jpeg__filename_operator"] | undefined | null | Variable<any, string>,
	sizes__medium_jpeg__url?: ValueTypes["Banner_Desktop_sizes__medium_jpeg__url_operator"] | undefined | null | Variable<any, string>,
	sizes__medium_jpeg__width?: ValueTypes["Banner_Desktop_sizes__medium_jpeg__width_operator"] | undefined | null | Variable<any, string>,
	sizes__medium_jpeg__height?: ValueTypes["Banner_Desktop_sizes__medium_jpeg__height_operator"] | undefined | null | Variable<any, string>,
	sizes__medium_jpeg__mimeType?: ValueTypes["Banner_Desktop_sizes__medium_jpeg__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__medium_jpeg__filesize?: ValueTypes["Banner_Desktop_sizes__medium_jpeg__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__medium_jpeg__filename?: ValueTypes["Banner_Desktop_sizes__medium_jpeg__filename_operator"] | undefined | null | Variable<any, string>,
	sizes__large_jpeg__url?: ValueTypes["Banner_Desktop_sizes__large_jpeg__url_operator"] | undefined | null | Variable<any, string>,
	sizes__large_jpeg__width?: ValueTypes["Banner_Desktop_sizes__large_jpeg__width_operator"] | undefined | null | Variable<any, string>,
	sizes__large_jpeg__height?: ValueTypes["Banner_Desktop_sizes__large_jpeg__height_operator"] | undefined | null | Variable<any, string>,
	sizes__large_jpeg__mimeType?: ValueTypes["Banner_Desktop_sizes__large_jpeg__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__large_jpeg__filesize?: ValueTypes["Banner_Desktop_sizes__large_jpeg__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__large_jpeg__filename?: ValueTypes["Banner_Desktop_sizes__large_jpeg__filename_operator"] | undefined | null | Variable<any, string>,
	id?: ValueTypes["Banner_Desktop_id_operator"] | undefined | null | Variable<any, string>,
	AND?: Array<ValueTypes["Banner_Desktop_where_and"] | undefined | null> | undefined | null | Variable<any, string>,
	OR?: Array<ValueTypes["Banner_Desktop_where_or"] | undefined | null> | undefined | null | Variable<any, string>
};
	["Banner_Mobile_where"]: {
	alt?: ValueTypes["Banner_Mobile_alt_operator"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["Banner_Mobile_updatedAt_operator"] | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["Banner_Mobile_createdAt_operator"] | undefined | null | Variable<any, string>,
	url?: ValueTypes["Banner_Mobile_url_operator"] | undefined | null | Variable<any, string>,
	filename?: ValueTypes["Banner_Mobile_filename_operator"] | undefined | null | Variable<any, string>,
	mimeType?: ValueTypes["Banner_Mobile_mimeType_operator"] | undefined | null | Variable<any, string>,
	filesize?: ValueTypes["Banner_Mobile_filesize_operator"] | undefined | null | Variable<any, string>,
	width?: ValueTypes["Banner_Mobile_width_operator"] | undefined | null | Variable<any, string>,
	height?: ValueTypes["Banner_Mobile_height_operator"] | undefined | null | Variable<any, string>,
	focalX?: ValueTypes["Banner_Mobile_focalX_operator"] | undefined | null | Variable<any, string>,
	focalY?: ValueTypes["Banner_Mobile_focalY_operator"] | undefined | null | Variable<any, string>,
	sizes__small__url?: ValueTypes["Banner_Mobile_sizes__small__url_operator"] | undefined | null | Variable<any, string>,
	sizes__small__width?: ValueTypes["Banner_Mobile_sizes__small__width_operator"] | undefined | null | Variable<any, string>,
	sizes__small__height?: ValueTypes["Banner_Mobile_sizes__small__height_operator"] | undefined | null | Variable<any, string>,
	sizes__small__mimeType?: ValueTypes["Banner_Mobile_sizes__small__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__small__filesize?: ValueTypes["Banner_Mobile_sizes__small__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__small__filename?: ValueTypes["Banner_Mobile_sizes__small__filename_operator"] | undefined | null | Variable<any, string>,
	sizes__medium__url?: ValueTypes["Banner_Mobile_sizes__medium__url_operator"] | undefined | null | Variable<any, string>,
	sizes__medium__width?: ValueTypes["Banner_Mobile_sizes__medium__width_operator"] | undefined | null | Variable<any, string>,
	sizes__medium__height?: ValueTypes["Banner_Mobile_sizes__medium__height_operator"] | undefined | null | Variable<any, string>,
	sizes__medium__mimeType?: ValueTypes["Banner_Mobile_sizes__medium__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__medium__filesize?: ValueTypes["Banner_Mobile_sizes__medium__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__medium__filename?: ValueTypes["Banner_Mobile_sizes__medium__filename_operator"] | undefined | null | Variable<any, string>,
	sizes__large__url?: ValueTypes["Banner_Mobile_sizes__large__url_operator"] | undefined | null | Variable<any, string>,
	sizes__large__width?: ValueTypes["Banner_Mobile_sizes__large__width_operator"] | undefined | null | Variable<any, string>,
	sizes__large__height?: ValueTypes["Banner_Mobile_sizes__large__height_operator"] | undefined | null | Variable<any, string>,
	sizes__large__mimeType?: ValueTypes["Banner_Mobile_sizes__large__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__large__filesize?: ValueTypes["Banner_Mobile_sizes__large__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__large__filename?: ValueTypes["Banner_Mobile_sizes__large__filename_operator"] | undefined | null | Variable<any, string>,
	sizes__small_jpeg__url?: ValueTypes["Banner_Mobile_sizes__small_jpeg__url_operator"] | undefined | null | Variable<any, string>,
	sizes__small_jpeg__width?: ValueTypes["Banner_Mobile_sizes__small_jpeg__width_operator"] | undefined | null | Variable<any, string>,
	sizes__small_jpeg__height?: ValueTypes["Banner_Mobile_sizes__small_jpeg__height_operator"] | undefined | null | Variable<any, string>,
	sizes__small_jpeg__mimeType?: ValueTypes["Banner_Mobile_sizes__small_jpeg__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__small_jpeg__filesize?: ValueTypes["Banner_Mobile_sizes__small_jpeg__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__small_jpeg__filename?: ValueTypes["Banner_Mobile_sizes__small_jpeg__filename_operator"] | undefined | null | Variable<any, string>,
	sizes__medium_jpeg__url?: ValueTypes["Banner_Mobile_sizes__medium_jpeg__url_operator"] | undefined | null | Variable<any, string>,
	sizes__medium_jpeg__width?: ValueTypes["Banner_Mobile_sizes__medium_jpeg__width_operator"] | undefined | null | Variable<any, string>,
	sizes__medium_jpeg__height?: ValueTypes["Banner_Mobile_sizes__medium_jpeg__height_operator"] | undefined | null | Variable<any, string>,
	sizes__medium_jpeg__mimeType?: ValueTypes["Banner_Mobile_sizes__medium_jpeg__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__medium_jpeg__filesize?: ValueTypes["Banner_Mobile_sizes__medium_jpeg__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__medium_jpeg__filename?: ValueTypes["Banner_Mobile_sizes__medium_jpeg__filename_operator"] | undefined | null | Variable<any, string>,
	sizes__large_jpeg__url?: ValueTypes["Banner_Mobile_sizes__large_jpeg__url_operator"] | undefined | null | Variable<any, string>,
	sizes__large_jpeg__width?: ValueTypes["Banner_Mobile_sizes__large_jpeg__width_operator"] | undefined | null | Variable<any, string>,
	sizes__large_jpeg__height?: ValueTypes["Banner_Mobile_sizes__large_jpeg__height_operator"] | undefined | null | Variable<any, string>,
	sizes__large_jpeg__mimeType?: ValueTypes["Banner_Mobile_sizes__large_jpeg__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__large_jpeg__filesize?: ValueTypes["Banner_Mobile_sizes__large_jpeg__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__large_jpeg__filename?: ValueTypes["Banner_Mobile_sizes__large_jpeg__filename_operator"] | undefined | null | Variable<any, string>,
	id?: ValueTypes["Banner_Mobile_id_operator"] | undefined | null | Variable<any, string>,
	AND?: Array<ValueTypes["Banner_Mobile_where_and"] | undefined | null> | undefined | null | Variable<any, string>,
	OR?: Array<ValueTypes["Banner_Mobile_where_or"] | undefined | null> | undefined | null | Variable<any, string>
};
	["Banner_Mobile_alt_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Banner_Mobile_updatedAt_operator"]: {
	equals?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	not_equals?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	greater_than_equal?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	greater_than?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	less_than_equal?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	less_than?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	like?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Banner_Mobile_createdAt_operator"]: {
	equals?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	not_equals?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	greater_than_equal?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	greater_than?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	less_than_equal?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	less_than?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	like?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Banner_Mobile_url_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Banner_Mobile_filename_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Banner_Mobile_mimeType_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Banner_Mobile_filesize_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Banner_Mobile_width_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Banner_Mobile_height_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Banner_Mobile_focalX_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Banner_Mobile_focalY_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Banner_Mobile_sizes__small__url_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Banner_Mobile_sizes__small__width_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Banner_Mobile_sizes__small__height_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Banner_Mobile_sizes__small__mimeType_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Banner_Mobile_sizes__small__filesize_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Banner_Mobile_sizes__small__filename_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Banner_Mobile_sizes__medium__url_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Banner_Mobile_sizes__medium__width_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Banner_Mobile_sizes__medium__height_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Banner_Mobile_sizes__medium__mimeType_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Banner_Mobile_sizes__medium__filesize_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Banner_Mobile_sizes__medium__filename_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Banner_Mobile_sizes__large__url_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Banner_Mobile_sizes__large__width_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Banner_Mobile_sizes__large__height_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Banner_Mobile_sizes__large__mimeType_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Banner_Mobile_sizes__large__filesize_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Banner_Mobile_sizes__large__filename_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Banner_Mobile_sizes__small_jpeg__url_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Banner_Mobile_sizes__small_jpeg__width_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Banner_Mobile_sizes__small_jpeg__height_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Banner_Mobile_sizes__small_jpeg__mimeType_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Banner_Mobile_sizes__small_jpeg__filesize_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Banner_Mobile_sizes__small_jpeg__filename_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Banner_Mobile_sizes__medium_jpeg__url_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Banner_Mobile_sizes__medium_jpeg__width_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Banner_Mobile_sizes__medium_jpeg__height_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Banner_Mobile_sizes__medium_jpeg__mimeType_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Banner_Mobile_sizes__medium_jpeg__filesize_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Banner_Mobile_sizes__medium_jpeg__filename_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Banner_Mobile_sizes__large_jpeg__url_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Banner_Mobile_sizes__large_jpeg__width_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Banner_Mobile_sizes__large_jpeg__height_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Banner_Mobile_sizes__large_jpeg__mimeType_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Banner_Mobile_sizes__large_jpeg__filesize_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Banner_Mobile_sizes__large_jpeg__filename_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Banner_Mobile_id_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Banner_Mobile_where_and"]: {
	alt?: ValueTypes["Banner_Mobile_alt_operator"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["Banner_Mobile_updatedAt_operator"] | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["Banner_Mobile_createdAt_operator"] | undefined | null | Variable<any, string>,
	url?: ValueTypes["Banner_Mobile_url_operator"] | undefined | null | Variable<any, string>,
	filename?: ValueTypes["Banner_Mobile_filename_operator"] | undefined | null | Variable<any, string>,
	mimeType?: ValueTypes["Banner_Mobile_mimeType_operator"] | undefined | null | Variable<any, string>,
	filesize?: ValueTypes["Banner_Mobile_filesize_operator"] | undefined | null | Variable<any, string>,
	width?: ValueTypes["Banner_Mobile_width_operator"] | undefined | null | Variable<any, string>,
	height?: ValueTypes["Banner_Mobile_height_operator"] | undefined | null | Variable<any, string>,
	focalX?: ValueTypes["Banner_Mobile_focalX_operator"] | undefined | null | Variable<any, string>,
	focalY?: ValueTypes["Banner_Mobile_focalY_operator"] | undefined | null | Variable<any, string>,
	sizes__small__url?: ValueTypes["Banner_Mobile_sizes__small__url_operator"] | undefined | null | Variable<any, string>,
	sizes__small__width?: ValueTypes["Banner_Mobile_sizes__small__width_operator"] | undefined | null | Variable<any, string>,
	sizes__small__height?: ValueTypes["Banner_Mobile_sizes__small__height_operator"] | undefined | null | Variable<any, string>,
	sizes__small__mimeType?: ValueTypes["Banner_Mobile_sizes__small__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__small__filesize?: ValueTypes["Banner_Mobile_sizes__small__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__small__filename?: ValueTypes["Banner_Mobile_sizes__small__filename_operator"] | undefined | null | Variable<any, string>,
	sizes__medium__url?: ValueTypes["Banner_Mobile_sizes__medium__url_operator"] | undefined | null | Variable<any, string>,
	sizes__medium__width?: ValueTypes["Banner_Mobile_sizes__medium__width_operator"] | undefined | null | Variable<any, string>,
	sizes__medium__height?: ValueTypes["Banner_Mobile_sizes__medium__height_operator"] | undefined | null | Variable<any, string>,
	sizes__medium__mimeType?: ValueTypes["Banner_Mobile_sizes__medium__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__medium__filesize?: ValueTypes["Banner_Mobile_sizes__medium__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__medium__filename?: ValueTypes["Banner_Mobile_sizes__medium__filename_operator"] | undefined | null | Variable<any, string>,
	sizes__large__url?: ValueTypes["Banner_Mobile_sizes__large__url_operator"] | undefined | null | Variable<any, string>,
	sizes__large__width?: ValueTypes["Banner_Mobile_sizes__large__width_operator"] | undefined | null | Variable<any, string>,
	sizes__large__height?: ValueTypes["Banner_Mobile_sizes__large__height_operator"] | undefined | null | Variable<any, string>,
	sizes__large__mimeType?: ValueTypes["Banner_Mobile_sizes__large__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__large__filesize?: ValueTypes["Banner_Mobile_sizes__large__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__large__filename?: ValueTypes["Banner_Mobile_sizes__large__filename_operator"] | undefined | null | Variable<any, string>,
	sizes__small_jpeg__url?: ValueTypes["Banner_Mobile_sizes__small_jpeg__url_operator"] | undefined | null | Variable<any, string>,
	sizes__small_jpeg__width?: ValueTypes["Banner_Mobile_sizes__small_jpeg__width_operator"] | undefined | null | Variable<any, string>,
	sizes__small_jpeg__height?: ValueTypes["Banner_Mobile_sizes__small_jpeg__height_operator"] | undefined | null | Variable<any, string>,
	sizes__small_jpeg__mimeType?: ValueTypes["Banner_Mobile_sizes__small_jpeg__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__small_jpeg__filesize?: ValueTypes["Banner_Mobile_sizes__small_jpeg__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__small_jpeg__filename?: ValueTypes["Banner_Mobile_sizes__small_jpeg__filename_operator"] | undefined | null | Variable<any, string>,
	sizes__medium_jpeg__url?: ValueTypes["Banner_Mobile_sizes__medium_jpeg__url_operator"] | undefined | null | Variable<any, string>,
	sizes__medium_jpeg__width?: ValueTypes["Banner_Mobile_sizes__medium_jpeg__width_operator"] | undefined | null | Variable<any, string>,
	sizes__medium_jpeg__height?: ValueTypes["Banner_Mobile_sizes__medium_jpeg__height_operator"] | undefined | null | Variable<any, string>,
	sizes__medium_jpeg__mimeType?: ValueTypes["Banner_Mobile_sizes__medium_jpeg__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__medium_jpeg__filesize?: ValueTypes["Banner_Mobile_sizes__medium_jpeg__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__medium_jpeg__filename?: ValueTypes["Banner_Mobile_sizes__medium_jpeg__filename_operator"] | undefined | null | Variable<any, string>,
	sizes__large_jpeg__url?: ValueTypes["Banner_Mobile_sizes__large_jpeg__url_operator"] | undefined | null | Variable<any, string>,
	sizes__large_jpeg__width?: ValueTypes["Banner_Mobile_sizes__large_jpeg__width_operator"] | undefined | null | Variable<any, string>,
	sizes__large_jpeg__height?: ValueTypes["Banner_Mobile_sizes__large_jpeg__height_operator"] | undefined | null | Variable<any, string>,
	sizes__large_jpeg__mimeType?: ValueTypes["Banner_Mobile_sizes__large_jpeg__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__large_jpeg__filesize?: ValueTypes["Banner_Mobile_sizes__large_jpeg__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__large_jpeg__filename?: ValueTypes["Banner_Mobile_sizes__large_jpeg__filename_operator"] | undefined | null | Variable<any, string>,
	id?: ValueTypes["Banner_Mobile_id_operator"] | undefined | null | Variable<any, string>,
	AND?: Array<ValueTypes["Banner_Mobile_where_and"] | undefined | null> | undefined | null | Variable<any, string>,
	OR?: Array<ValueTypes["Banner_Mobile_where_or"] | undefined | null> | undefined | null | Variable<any, string>
};
	["Banner_Mobile_where_or"]: {
	alt?: ValueTypes["Banner_Mobile_alt_operator"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["Banner_Mobile_updatedAt_operator"] | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["Banner_Mobile_createdAt_operator"] | undefined | null | Variable<any, string>,
	url?: ValueTypes["Banner_Mobile_url_operator"] | undefined | null | Variable<any, string>,
	filename?: ValueTypes["Banner_Mobile_filename_operator"] | undefined | null | Variable<any, string>,
	mimeType?: ValueTypes["Banner_Mobile_mimeType_operator"] | undefined | null | Variable<any, string>,
	filesize?: ValueTypes["Banner_Mobile_filesize_operator"] | undefined | null | Variable<any, string>,
	width?: ValueTypes["Banner_Mobile_width_operator"] | undefined | null | Variable<any, string>,
	height?: ValueTypes["Banner_Mobile_height_operator"] | undefined | null | Variable<any, string>,
	focalX?: ValueTypes["Banner_Mobile_focalX_operator"] | undefined | null | Variable<any, string>,
	focalY?: ValueTypes["Banner_Mobile_focalY_operator"] | undefined | null | Variable<any, string>,
	sizes__small__url?: ValueTypes["Banner_Mobile_sizes__small__url_operator"] | undefined | null | Variable<any, string>,
	sizes__small__width?: ValueTypes["Banner_Mobile_sizes__small__width_operator"] | undefined | null | Variable<any, string>,
	sizes__small__height?: ValueTypes["Banner_Mobile_sizes__small__height_operator"] | undefined | null | Variable<any, string>,
	sizes__small__mimeType?: ValueTypes["Banner_Mobile_sizes__small__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__small__filesize?: ValueTypes["Banner_Mobile_sizes__small__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__small__filename?: ValueTypes["Banner_Mobile_sizes__small__filename_operator"] | undefined | null | Variable<any, string>,
	sizes__medium__url?: ValueTypes["Banner_Mobile_sizes__medium__url_operator"] | undefined | null | Variable<any, string>,
	sizes__medium__width?: ValueTypes["Banner_Mobile_sizes__medium__width_operator"] | undefined | null | Variable<any, string>,
	sizes__medium__height?: ValueTypes["Banner_Mobile_sizes__medium__height_operator"] | undefined | null | Variable<any, string>,
	sizes__medium__mimeType?: ValueTypes["Banner_Mobile_sizes__medium__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__medium__filesize?: ValueTypes["Banner_Mobile_sizes__medium__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__medium__filename?: ValueTypes["Banner_Mobile_sizes__medium__filename_operator"] | undefined | null | Variable<any, string>,
	sizes__large__url?: ValueTypes["Banner_Mobile_sizes__large__url_operator"] | undefined | null | Variable<any, string>,
	sizes__large__width?: ValueTypes["Banner_Mobile_sizes__large__width_operator"] | undefined | null | Variable<any, string>,
	sizes__large__height?: ValueTypes["Banner_Mobile_sizes__large__height_operator"] | undefined | null | Variable<any, string>,
	sizes__large__mimeType?: ValueTypes["Banner_Mobile_sizes__large__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__large__filesize?: ValueTypes["Banner_Mobile_sizes__large__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__large__filename?: ValueTypes["Banner_Mobile_sizes__large__filename_operator"] | undefined | null | Variable<any, string>,
	sizes__small_jpeg__url?: ValueTypes["Banner_Mobile_sizes__small_jpeg__url_operator"] | undefined | null | Variable<any, string>,
	sizes__small_jpeg__width?: ValueTypes["Banner_Mobile_sizes__small_jpeg__width_operator"] | undefined | null | Variable<any, string>,
	sizes__small_jpeg__height?: ValueTypes["Banner_Mobile_sizes__small_jpeg__height_operator"] | undefined | null | Variable<any, string>,
	sizes__small_jpeg__mimeType?: ValueTypes["Banner_Mobile_sizes__small_jpeg__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__small_jpeg__filesize?: ValueTypes["Banner_Mobile_sizes__small_jpeg__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__small_jpeg__filename?: ValueTypes["Banner_Mobile_sizes__small_jpeg__filename_operator"] | undefined | null | Variable<any, string>,
	sizes__medium_jpeg__url?: ValueTypes["Banner_Mobile_sizes__medium_jpeg__url_operator"] | undefined | null | Variable<any, string>,
	sizes__medium_jpeg__width?: ValueTypes["Banner_Mobile_sizes__medium_jpeg__width_operator"] | undefined | null | Variable<any, string>,
	sizes__medium_jpeg__height?: ValueTypes["Banner_Mobile_sizes__medium_jpeg__height_operator"] | undefined | null | Variable<any, string>,
	sizes__medium_jpeg__mimeType?: ValueTypes["Banner_Mobile_sizes__medium_jpeg__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__medium_jpeg__filesize?: ValueTypes["Banner_Mobile_sizes__medium_jpeg__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__medium_jpeg__filename?: ValueTypes["Banner_Mobile_sizes__medium_jpeg__filename_operator"] | undefined | null | Variable<any, string>,
	sizes__large_jpeg__url?: ValueTypes["Banner_Mobile_sizes__large_jpeg__url_operator"] | undefined | null | Variable<any, string>,
	sizes__large_jpeg__width?: ValueTypes["Banner_Mobile_sizes__large_jpeg__width_operator"] | undefined | null | Variable<any, string>,
	sizes__large_jpeg__height?: ValueTypes["Banner_Mobile_sizes__large_jpeg__height_operator"] | undefined | null | Variable<any, string>,
	sizes__large_jpeg__mimeType?: ValueTypes["Banner_Mobile_sizes__large_jpeg__mimeType_operator"] | undefined | null | Variable<any, string>,
	sizes__large_jpeg__filesize?: ValueTypes["Banner_Mobile_sizes__large_jpeg__filesize_operator"] | undefined | null | Variable<any, string>,
	sizes__large_jpeg__filename?: ValueTypes["Banner_Mobile_sizes__large_jpeg__filename_operator"] | undefined | null | Variable<any, string>,
	id?: ValueTypes["Banner_Mobile_id_operator"] | undefined | null | Variable<any, string>,
	AND?: Array<ValueTypes["Banner_Mobile_where_and"] | undefined | null> | undefined | null | Variable<any, string>,
	OR?: Array<ValueTypes["Banner_Mobile_where_or"] | undefined | null> | undefined | null | Variable<any, string>
};
	["Banner_Location"]: AliasType<{
	page?:boolean | `@${string}`,
eventRankingList?: [{	locale?: ValueTypes["LocaleInputType"] | undefined | null | Variable<any, string>,	fallbackLocale?: ValueTypes["FallbackLocaleInputType"] | undefined | null | Variable<any, string>},ValueTypes["EventRanking"]],
locationRankingList?: [{	locale?: ValueTypes["LocaleInputType"] | undefined | null | Variable<any, string>,	fallbackLocale?: ValueTypes["FallbackLocaleInputType"] | undefined | null | Variable<any, string>},ValueTypes["LocationRanking"]],
categories?: [{	locale?: ValueTypes["LocaleInputType"] | undefined | null | Variable<any, string>,	fallbackLocale?: ValueTypes["FallbackLocaleInputType"] | undefined | null | Variable<any, string>},ValueTypes["EventCategory"]],
	time?:boolean | `@${string}`,
	district?:boolean | `@${string}`,
	audience?:boolean | `@${string}`,
	multipleCriteriaSearch?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["Banner_Location_page"]:Banner_Location_page;
	["Banner_type"]:Banner_type;
	["Banner_status"]:Banner_status;
	["Banner_Advertise"]: AliasType<{
campaign?: [{	locale?: ValueTypes["LocaleInputType"] | undefined | null | Variable<any, string>,	fallbackLocale?: ValueTypes["FallbackLocaleInputType"] | undefined | null | Variable<any, string>},ValueTypes["Campaign"]],
	invoiceDescription?:boolean | `@${string}`,
	standardPrice?:boolean | `@${string}`,
	discount?:boolean | `@${string}`,
	timableCost?:boolean | `@${string}`,
	remark?:boolean | `@${string}`,
	public?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["Campaign"]: AliasType<{
	id?:boolean | `@${string}`,
creator?: [{	locale?: ValueTypes["LocaleInputType"] | undefined | null | Variable<any, string>,	fallbackLocale?: ValueTypes["FallbackLocaleInputType"] | undefined | null | Variable<any, string>},ValueTypes["Campaign_Creator_Relationship"]],
	invoiceNumber?:boolean | `@${string}`,
	quotationNumber?:boolean | `@${string}`,
	campaignName?:boolean | `@${string}`,
	client?:boolean | `@${string}`,
	contactPerson?:boolean | `@${string}`,
	email?:boolean | `@${string}`,
	phone?:boolean | `@${string}`,
	agentName?:boolean | `@${string}`,
	agentCommission?:boolean | `@${string}`,
	agentOrderNumber?:boolean | `@${string}`,
	_firstPublishedTime?:boolean | `@${string}`,
	updatedAt?:boolean | `@${string}`,
	createdAt?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["Campaign_Creator_Relationship"]: AliasType<{
	relationTo?:boolean | `@${string}`,
	value?:ValueTypes["Campaign_Creator"],
		__typename?: boolean | `@${string}`
}>;
	["Campaign_Creator_RelationTo"]:Campaign_Creator_RelationTo;
	["Campaign_Creator"]: AliasType<{		["...on User"]?: ValueTypes["User"]
		__typename?: boolean | `@${string}`
}>;
	["Banner_region"]:Banner_region;
	["Banners"]: AliasType<{
	docs?:ValueTypes["Banner"],
	hasNextPage?:boolean | `@${string}`,
	hasPrevPage?:boolean | `@${string}`,
	limit?:boolean | `@${string}`,
	nextPage?:boolean | `@${string}`,
	offset?:boolean | `@${string}`,
	page?:boolean | `@${string}`,
	pagingCounter?:boolean | `@${string}`,
	prevPage?:boolean | `@${string}`,
	totalDocs?:boolean | `@${string}`,
	totalPages?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["Banner_where"]: {
	name?: ValueTypes["Banner_name_operator"] | undefined | null | Variable<any, string>,
	desktop?: ValueTypes["Banner_desktop_operator"] | undefined | null | Variable<any, string>,
	mobile?: ValueTypes["Banner_mobile_operator"] | undefined | null | Variable<any, string>,
	bannerStartDate?: ValueTypes["Banner_bannerStartDate_operator"] | undefined | null | Variable<any, string>,
	bannerEndDate?: ValueTypes["Banner_bannerEndDate_operator"] | undefined | null | Variable<any, string>,
	location__page?: ValueTypes["Banner_location__page_operator"] | undefined | null | Variable<any, string>,
	location__eventRankingList?: ValueTypes["Banner_location__eventRankingList_operator"] | undefined | null | Variable<any, string>,
	location__locationRankingList?: ValueTypes["Banner_location__locationRankingList_operator"] | undefined | null | Variable<any, string>,
	location__categories?: ValueTypes["Banner_location__categories_operator"] | undefined | null | Variable<any, string>,
	location__time?: ValueTypes["Banner_location__time_operator"] | undefined | null | Variable<any, string>,
	location__district?: ValueTypes["Banner_location__district_operator"] | undefined | null | Variable<any, string>,
	location__audience?: ValueTypes["Banner_location__audience_operator"] | undefined | null | Variable<any, string>,
	location__multipleCriteriaSearch?: ValueTypes["Banner_location__multipleCriteriaSearch_operator"] | undefined | null | Variable<any, string>,
	link?: ValueTypes["Banner_link_operator"] | undefined | null | Variable<any, string>,
	external?: ValueTypes["Banner_external_operator"] | undefined | null | Variable<any, string>,
	type?: ValueTypes["Banner_type_operator"] | undefined | null | Variable<any, string>,
	status?: ValueTypes["Banner_status_operator"] | undefined | null | Variable<any, string>,
	advertise__campaign?: ValueTypes["Banner_advertise__campaign_operator"] | undefined | null | Variable<any, string>,
	advertise__invoiceDescription?: ValueTypes["Banner_advertise__invoiceDescription_operator"] | undefined | null | Variable<any, string>,
	advertise__standardPrice?: ValueTypes["Banner_advertise__standardPrice_operator"] | undefined | null | Variable<any, string>,
	advertise__discount?: ValueTypes["Banner_advertise__discount_operator"] | undefined | null | Variable<any, string>,
	advertise__timableCost?: ValueTypes["Banner_advertise__timableCost_operator"] | undefined | null | Variable<any, string>,
	advertise__remark?: ValueTypes["Banner_advertise__remark_operator"] | undefined | null | Variable<any, string>,
	advertise__public?: ValueTypes["Banner_advertise__public_operator"] | undefined | null | Variable<any, string>,
	_locations?: ValueTypes["Banner__locations_operator"] | undefined | null | Variable<any, string>,
	region?: ValueTypes["Banner_region_operator"] | undefined | null | Variable<any, string>,
	homePageClick?: ValueTypes["Banner_homePageClick_operator"] | undefined | null | Variable<any, string>,
	homePageImpression?: ValueTypes["Banner_homePageImpression_operator"] | undefined | null | Variable<any, string>,
	latestEventClick?: ValueTypes["Banner_latestEventClick_operator"] | undefined | null | Variable<any, string>,
	latestEventImpression?: ValueTypes["Banner_latestEventImpression_operator"] | undefined | null | Variable<any, string>,
	editorChoiceClick?: ValueTypes["Banner_editorChoiceClick_operator"] | undefined | null | Variable<any, string>,
	editorChoiceImpression?: ValueTypes["Banner_editorChoiceImpression_operator"] | undefined | null | Variable<any, string>,
	eventListClick?: ValueTypes["Banner_eventListClick_operator"] | undefined | null | Variable<any, string>,
	eventListImpression?: ValueTypes["Banner_eventListImpression_operator"] | undefined | null | Variable<any, string>,
	topTenClick?: ValueTypes["Banner_topTenClick_operator"] | undefined | null | Variable<any, string>,
	topTenImpression?: ValueTypes["Banner_topTenImpression_operator"] | undefined | null | Variable<any, string>,
	miscClick?: ValueTypes["Banner_miscClick_operator"] | undefined | null | Variable<any, string>,
	miscImpression?: ValueTypes["Banner_miscImpression_operator"] | undefined | null | Variable<any, string>,
	clickTotal?: ValueTypes["Banner_clickTotal_operator"] | undefined | null | Variable<any, string>,
	totalImpression?: ValueTypes["Banner_totalImpression_operator"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["Banner_updatedAt_operator"] | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["Banner_createdAt_operator"] | undefined | null | Variable<any, string>,
	id?: ValueTypes["Banner_id_operator"] | undefined | null | Variable<any, string>,
	AND?: Array<ValueTypes["Banner_where_and"] | undefined | null> | undefined | null | Variable<any, string>,
	OR?: Array<ValueTypes["Banner_where_or"] | undefined | null> | undefined | null | Variable<any, string>
};
	["Banner_name_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>
};
	["Banner_desktop_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>
};
	["Banner_mobile_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>
};
	["Banner_bannerStartDate_operator"]: {
	equals?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	not_equals?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	greater_than_equal?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	greater_than?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	less_than_equal?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	less_than?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	like?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>
};
	["Banner_bannerEndDate_operator"]: {
	equals?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	not_equals?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	greater_than_equal?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	greater_than?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	less_than_equal?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	less_than?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	like?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>
};
	["Banner_location__page_operator"]: {
	equals?: ValueTypes["Banner_location__page_Input"] | undefined | null | Variable<any, string>,
	not_equals?: ValueTypes["Banner_location__page_Input"] | undefined | null | Variable<any, string>,
	in?: Array<ValueTypes["Banner_location__page_Input"] | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<ValueTypes["Banner_location__page_Input"] | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<ValueTypes["Banner_location__page_Input"] | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Banner_location__page_Input"]:Banner_location__page_Input;
	["Banner_location__eventRankingList_operator"]: {
	equals?: ValueTypes["JSON"] | undefined | null | Variable<any, string>,
	not_equals?: ValueTypes["JSON"] | undefined | null | Variable<any, string>,
	in?: Array<ValueTypes["JSON"] | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<ValueTypes["JSON"] | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<ValueTypes["JSON"] | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Banner_location__locationRankingList_operator"]: {
	equals?: ValueTypes["JSON"] | undefined | null | Variable<any, string>,
	not_equals?: ValueTypes["JSON"] | undefined | null | Variable<any, string>,
	in?: Array<ValueTypes["JSON"] | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<ValueTypes["JSON"] | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<ValueTypes["JSON"] | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Banner_location__categories_operator"]: {
	equals?: ValueTypes["JSON"] | undefined | null | Variable<any, string>,
	not_equals?: ValueTypes["JSON"] | undefined | null | Variable<any, string>,
	in?: Array<ValueTypes["JSON"] | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<ValueTypes["JSON"] | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<ValueTypes["JSON"] | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Banner_location__time_operator"]: {
	equals?: boolean | undefined | null | Variable<any, string>,
	not_equals?: boolean | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Banner_location__district_operator"]: {
	equals?: boolean | undefined | null | Variable<any, string>,
	not_equals?: boolean | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Banner_location__audience_operator"]: {
	equals?: boolean | undefined | null | Variable<any, string>,
	not_equals?: boolean | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Banner_location__multipleCriteriaSearch_operator"]: {
	equals?: boolean | undefined | null | Variable<any, string>,
	not_equals?: boolean | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Banner_link_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Banner_external_operator"]: {
	equals?: boolean | undefined | null | Variable<any, string>,
	not_equals?: boolean | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Banner_type_operator"]: {
	equals?: ValueTypes["Banner_type_Input"] | undefined | null | Variable<any, string>,
	not_equals?: ValueTypes["Banner_type_Input"] | undefined | null | Variable<any, string>,
	in?: Array<ValueTypes["Banner_type_Input"] | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<ValueTypes["Banner_type_Input"] | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<ValueTypes["Banner_type_Input"] | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Banner_type_Input"]:Banner_type_Input;
	["Banner_status_operator"]: {
	equals?: ValueTypes["Banner_status_Input"] | undefined | null | Variable<any, string>,
	not_equals?: ValueTypes["Banner_status_Input"] | undefined | null | Variable<any, string>,
	in?: Array<ValueTypes["Banner_status_Input"] | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<ValueTypes["Banner_status_Input"] | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<ValueTypes["Banner_status_Input"] | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Banner_status_Input"]:Banner_status_Input;
	["Banner_advertise__campaign_operator"]: {
	equals?: ValueTypes["JSON"] | undefined | null | Variable<any, string>,
	not_equals?: ValueTypes["JSON"] | undefined | null | Variable<any, string>,
	in?: Array<ValueTypes["JSON"] | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<ValueTypes["JSON"] | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<ValueTypes["JSON"] | undefined | null> | undefined | null | Variable<any, string>
};
	["Banner_advertise__invoiceDescription_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Banner_advertise__standardPrice_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>
};
	["Banner_advertise__discount_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Banner_advertise__timableCost_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Banner_advertise__remark_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Banner_advertise__public_operator"]: {
	equals?: boolean | undefined | null | Variable<any, string>,
	not_equals?: boolean | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Banner__locations_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Banner_region_operator"]: {
	equals?: ValueTypes["Banner_region_Input"] | undefined | null | Variable<any, string>,
	not_equals?: ValueTypes["Banner_region_Input"] | undefined | null | Variable<any, string>,
	in?: Array<ValueTypes["Banner_region_Input"] | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<ValueTypes["Banner_region_Input"] | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<ValueTypes["Banner_region_Input"] | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Banner_region_Input"]:Banner_region_Input;
	["Banner_homePageClick_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Banner_homePageImpression_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Banner_latestEventClick_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Banner_latestEventImpression_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Banner_editorChoiceClick_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Banner_editorChoiceImpression_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Banner_eventListClick_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Banner_eventListImpression_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Banner_topTenClick_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Banner_topTenImpression_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Banner_miscClick_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Banner_miscImpression_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Banner_clickTotal_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Banner_totalImpression_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Banner_updatedAt_operator"]: {
	equals?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	not_equals?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	greater_than_equal?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	greater_than?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	less_than_equal?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	less_than?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	like?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Banner_createdAt_operator"]: {
	equals?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	not_equals?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	greater_than_equal?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	greater_than?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	less_than_equal?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	less_than?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	like?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Banner_id_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Banner_where_and"]: {
	name?: ValueTypes["Banner_name_operator"] | undefined | null | Variable<any, string>,
	desktop?: ValueTypes["Banner_desktop_operator"] | undefined | null | Variable<any, string>,
	mobile?: ValueTypes["Banner_mobile_operator"] | undefined | null | Variable<any, string>,
	bannerStartDate?: ValueTypes["Banner_bannerStartDate_operator"] | undefined | null | Variable<any, string>,
	bannerEndDate?: ValueTypes["Banner_bannerEndDate_operator"] | undefined | null | Variable<any, string>,
	location__page?: ValueTypes["Banner_location__page_operator"] | undefined | null | Variable<any, string>,
	location__eventRankingList?: ValueTypes["Banner_location__eventRankingList_operator"] | undefined | null | Variable<any, string>,
	location__locationRankingList?: ValueTypes["Banner_location__locationRankingList_operator"] | undefined | null | Variable<any, string>,
	location__categories?: ValueTypes["Banner_location__categories_operator"] | undefined | null | Variable<any, string>,
	location__time?: ValueTypes["Banner_location__time_operator"] | undefined | null | Variable<any, string>,
	location__district?: ValueTypes["Banner_location__district_operator"] | undefined | null | Variable<any, string>,
	location__audience?: ValueTypes["Banner_location__audience_operator"] | undefined | null | Variable<any, string>,
	location__multipleCriteriaSearch?: ValueTypes["Banner_location__multipleCriteriaSearch_operator"] | undefined | null | Variable<any, string>,
	link?: ValueTypes["Banner_link_operator"] | undefined | null | Variable<any, string>,
	external?: ValueTypes["Banner_external_operator"] | undefined | null | Variable<any, string>,
	type?: ValueTypes["Banner_type_operator"] | undefined | null | Variable<any, string>,
	status?: ValueTypes["Banner_status_operator"] | undefined | null | Variable<any, string>,
	advertise__campaign?: ValueTypes["Banner_advertise__campaign_operator"] | undefined | null | Variable<any, string>,
	advertise__invoiceDescription?: ValueTypes["Banner_advertise__invoiceDescription_operator"] | undefined | null | Variable<any, string>,
	advertise__standardPrice?: ValueTypes["Banner_advertise__standardPrice_operator"] | undefined | null | Variable<any, string>,
	advertise__discount?: ValueTypes["Banner_advertise__discount_operator"] | undefined | null | Variable<any, string>,
	advertise__timableCost?: ValueTypes["Banner_advertise__timableCost_operator"] | undefined | null | Variable<any, string>,
	advertise__remark?: ValueTypes["Banner_advertise__remark_operator"] | undefined | null | Variable<any, string>,
	advertise__public?: ValueTypes["Banner_advertise__public_operator"] | undefined | null | Variable<any, string>,
	_locations?: ValueTypes["Banner__locations_operator"] | undefined | null | Variable<any, string>,
	region?: ValueTypes["Banner_region_operator"] | undefined | null | Variable<any, string>,
	homePageClick?: ValueTypes["Banner_homePageClick_operator"] | undefined | null | Variable<any, string>,
	homePageImpression?: ValueTypes["Banner_homePageImpression_operator"] | undefined | null | Variable<any, string>,
	latestEventClick?: ValueTypes["Banner_latestEventClick_operator"] | undefined | null | Variable<any, string>,
	latestEventImpression?: ValueTypes["Banner_latestEventImpression_operator"] | undefined | null | Variable<any, string>,
	editorChoiceClick?: ValueTypes["Banner_editorChoiceClick_operator"] | undefined | null | Variable<any, string>,
	editorChoiceImpression?: ValueTypes["Banner_editorChoiceImpression_operator"] | undefined | null | Variable<any, string>,
	eventListClick?: ValueTypes["Banner_eventListClick_operator"] | undefined | null | Variable<any, string>,
	eventListImpression?: ValueTypes["Banner_eventListImpression_operator"] | undefined | null | Variable<any, string>,
	topTenClick?: ValueTypes["Banner_topTenClick_operator"] | undefined | null | Variable<any, string>,
	topTenImpression?: ValueTypes["Banner_topTenImpression_operator"] | undefined | null | Variable<any, string>,
	miscClick?: ValueTypes["Banner_miscClick_operator"] | undefined | null | Variable<any, string>,
	miscImpression?: ValueTypes["Banner_miscImpression_operator"] | undefined | null | Variable<any, string>,
	clickTotal?: ValueTypes["Banner_clickTotal_operator"] | undefined | null | Variable<any, string>,
	totalImpression?: ValueTypes["Banner_totalImpression_operator"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["Banner_updatedAt_operator"] | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["Banner_createdAt_operator"] | undefined | null | Variable<any, string>,
	id?: ValueTypes["Banner_id_operator"] | undefined | null | Variable<any, string>,
	AND?: Array<ValueTypes["Banner_where_and"] | undefined | null> | undefined | null | Variable<any, string>,
	OR?: Array<ValueTypes["Banner_where_or"] | undefined | null> | undefined | null | Variable<any, string>
};
	["Banner_where_or"]: {
	name?: ValueTypes["Banner_name_operator"] | undefined | null | Variable<any, string>,
	desktop?: ValueTypes["Banner_desktop_operator"] | undefined | null | Variable<any, string>,
	mobile?: ValueTypes["Banner_mobile_operator"] | undefined | null | Variable<any, string>,
	bannerStartDate?: ValueTypes["Banner_bannerStartDate_operator"] | undefined | null | Variable<any, string>,
	bannerEndDate?: ValueTypes["Banner_bannerEndDate_operator"] | undefined | null | Variable<any, string>,
	location__page?: ValueTypes["Banner_location__page_operator"] | undefined | null | Variable<any, string>,
	location__eventRankingList?: ValueTypes["Banner_location__eventRankingList_operator"] | undefined | null | Variable<any, string>,
	location__locationRankingList?: ValueTypes["Banner_location__locationRankingList_operator"] | undefined | null | Variable<any, string>,
	location__categories?: ValueTypes["Banner_location__categories_operator"] | undefined | null | Variable<any, string>,
	location__time?: ValueTypes["Banner_location__time_operator"] | undefined | null | Variable<any, string>,
	location__district?: ValueTypes["Banner_location__district_operator"] | undefined | null | Variable<any, string>,
	location__audience?: ValueTypes["Banner_location__audience_operator"] | undefined | null | Variable<any, string>,
	location__multipleCriteriaSearch?: ValueTypes["Banner_location__multipleCriteriaSearch_operator"] | undefined | null | Variable<any, string>,
	link?: ValueTypes["Banner_link_operator"] | undefined | null | Variable<any, string>,
	external?: ValueTypes["Banner_external_operator"] | undefined | null | Variable<any, string>,
	type?: ValueTypes["Banner_type_operator"] | undefined | null | Variable<any, string>,
	status?: ValueTypes["Banner_status_operator"] | undefined | null | Variable<any, string>,
	advertise__campaign?: ValueTypes["Banner_advertise__campaign_operator"] | undefined | null | Variable<any, string>,
	advertise__invoiceDescription?: ValueTypes["Banner_advertise__invoiceDescription_operator"] | undefined | null | Variable<any, string>,
	advertise__standardPrice?: ValueTypes["Banner_advertise__standardPrice_operator"] | undefined | null | Variable<any, string>,
	advertise__discount?: ValueTypes["Banner_advertise__discount_operator"] | undefined | null | Variable<any, string>,
	advertise__timableCost?: ValueTypes["Banner_advertise__timableCost_operator"] | undefined | null | Variable<any, string>,
	advertise__remark?: ValueTypes["Banner_advertise__remark_operator"] | undefined | null | Variable<any, string>,
	advertise__public?: ValueTypes["Banner_advertise__public_operator"] | undefined | null | Variable<any, string>,
	_locations?: ValueTypes["Banner__locations_operator"] | undefined | null | Variable<any, string>,
	region?: ValueTypes["Banner_region_operator"] | undefined | null | Variable<any, string>,
	homePageClick?: ValueTypes["Banner_homePageClick_operator"] | undefined | null | Variable<any, string>,
	homePageImpression?: ValueTypes["Banner_homePageImpression_operator"] | undefined | null | Variable<any, string>,
	latestEventClick?: ValueTypes["Banner_latestEventClick_operator"] | undefined | null | Variable<any, string>,
	latestEventImpression?: ValueTypes["Banner_latestEventImpression_operator"] | undefined | null | Variable<any, string>,
	editorChoiceClick?: ValueTypes["Banner_editorChoiceClick_operator"] | undefined | null | Variable<any, string>,
	editorChoiceImpression?: ValueTypes["Banner_editorChoiceImpression_operator"] | undefined | null | Variable<any, string>,
	eventListClick?: ValueTypes["Banner_eventListClick_operator"] | undefined | null | Variable<any, string>,
	eventListImpression?: ValueTypes["Banner_eventListImpression_operator"] | undefined | null | Variable<any, string>,
	topTenClick?: ValueTypes["Banner_topTenClick_operator"] | undefined | null | Variable<any, string>,
	topTenImpression?: ValueTypes["Banner_topTenImpression_operator"] | undefined | null | Variable<any, string>,
	miscClick?: ValueTypes["Banner_miscClick_operator"] | undefined | null | Variable<any, string>,
	miscImpression?: ValueTypes["Banner_miscImpression_operator"] | undefined | null | Variable<any, string>,
	clickTotal?: ValueTypes["Banner_clickTotal_operator"] | undefined | null | Variable<any, string>,
	totalImpression?: ValueTypes["Banner_totalImpression_operator"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["Banner_updatedAt_operator"] | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["Banner_createdAt_operator"] | undefined | null | Variable<any, string>,
	id?: ValueTypes["Banner_id_operator"] | undefined | null | Variable<any, string>,
	AND?: Array<ValueTypes["Banner_where_and"] | undefined | null> | undefined | null | Variable<any, string>,
	OR?: Array<ValueTypes["Banner_where_or"] | undefined | null> | undefined | null | Variable<any, string>
};
	["countBanners"]: AliasType<{
	totalDocs?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["bannersDocAccess"]: AliasType<{
	fields?:ValueTypes["BannersDocAccessFields"],
	create?:ValueTypes["BannersCreateDocAccess"],
	read?:ValueTypes["BannersReadDocAccess"],
	update?:ValueTypes["BannersUpdateDocAccess"],
	delete?:ValueTypes["BannersDeleteDocAccess"],
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields"]: AliasType<{
	name?:ValueTypes["BannersDocAccessFields_name"],
	desktop?:ValueTypes["BannersDocAccessFields_desktop"],
	mobile?:ValueTypes["BannersDocAccessFields_mobile"],
	bannerStartDate?:ValueTypes["BannersDocAccessFields_bannerStartDate"],
	bannerEndDate?:ValueTypes["BannersDocAccessFields_bannerEndDate"],
	location?:ValueTypes["BannersDocAccessFields_location"],
	link?:ValueTypes["BannersDocAccessFields_link"],
	external?:ValueTypes["BannersDocAccessFields_external"],
	type?:ValueTypes["BannersDocAccessFields_type"],
	status?:ValueTypes["BannersDocAccessFields_status"],
	advertise?:ValueTypes["BannersDocAccessFields_advertise"],
	_locations?:ValueTypes["BannersDocAccessFields__locations"],
	region?:ValueTypes["BannersDocAccessFields_region"],
	homePageClick?:ValueTypes["BannersDocAccessFields_homePageClick"],
	homePageImpression?:ValueTypes["BannersDocAccessFields_homePageImpression"],
	latestEventClick?:ValueTypes["BannersDocAccessFields_latestEventClick"],
	latestEventImpression?:ValueTypes["BannersDocAccessFields_latestEventImpression"],
	editorChoiceClick?:ValueTypes["BannersDocAccessFields_editorChoiceClick"],
	editorChoiceImpression?:ValueTypes["BannersDocAccessFields_editorChoiceImpression"],
	eventListClick?:ValueTypes["BannersDocAccessFields_eventListClick"],
	eventListImpression?:ValueTypes["BannersDocAccessFields_eventListImpression"],
	topTenClick?:ValueTypes["BannersDocAccessFields_topTenClick"],
	topTenImpression?:ValueTypes["BannersDocAccessFields_topTenImpression"],
	miscClick?:ValueTypes["BannersDocAccessFields_miscClick"],
	miscImpression?:ValueTypes["BannersDocAccessFields_miscImpression"],
	clickTotal?:ValueTypes["BannersDocAccessFields_clickTotal"],
	totalImpression?:ValueTypes["BannersDocAccessFields_totalImpression"],
	updatedAt?:ValueTypes["BannersDocAccessFields_updatedAt"],
	createdAt?:ValueTypes["BannersDocAccessFields_createdAt"],
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_name"]: AliasType<{
	create?:ValueTypes["BannersDocAccessFields_name_Create"],
	read?:ValueTypes["BannersDocAccessFields_name_Read"],
	update?:ValueTypes["BannersDocAccessFields_name_Update"],
	delete?:ValueTypes["BannersDocAccessFields_name_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_name_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_name_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_name_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_name_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_desktop"]: AliasType<{
	create?:ValueTypes["BannersDocAccessFields_desktop_Create"],
	read?:ValueTypes["BannersDocAccessFields_desktop_Read"],
	update?:ValueTypes["BannersDocAccessFields_desktop_Update"],
	delete?:ValueTypes["BannersDocAccessFields_desktop_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_desktop_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_desktop_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_desktop_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_desktop_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_mobile"]: AliasType<{
	create?:ValueTypes["BannersDocAccessFields_mobile_Create"],
	read?:ValueTypes["BannersDocAccessFields_mobile_Read"],
	update?:ValueTypes["BannersDocAccessFields_mobile_Update"],
	delete?:ValueTypes["BannersDocAccessFields_mobile_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_mobile_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_mobile_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_mobile_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_mobile_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_bannerStartDate"]: AliasType<{
	create?:ValueTypes["BannersDocAccessFields_bannerStartDate_Create"],
	read?:ValueTypes["BannersDocAccessFields_bannerStartDate_Read"],
	update?:ValueTypes["BannersDocAccessFields_bannerStartDate_Update"],
	delete?:ValueTypes["BannersDocAccessFields_bannerStartDate_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_bannerStartDate_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_bannerStartDate_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_bannerStartDate_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_bannerStartDate_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_bannerEndDate"]: AliasType<{
	create?:ValueTypes["BannersDocAccessFields_bannerEndDate_Create"],
	read?:ValueTypes["BannersDocAccessFields_bannerEndDate_Read"],
	update?:ValueTypes["BannersDocAccessFields_bannerEndDate_Update"],
	delete?:ValueTypes["BannersDocAccessFields_bannerEndDate_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_bannerEndDate_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_bannerEndDate_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_bannerEndDate_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_bannerEndDate_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_location"]: AliasType<{
	create?:ValueTypes["BannersDocAccessFields_location_Create"],
	read?:ValueTypes["BannersDocAccessFields_location_Read"],
	update?:ValueTypes["BannersDocAccessFields_location_Update"],
	delete?:ValueTypes["BannersDocAccessFields_location_Delete"],
	fields?:ValueTypes["BannersDocAccessFields_location_Fields"],
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_location_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_location_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_location_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_location_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_location_Fields"]: AliasType<{
	page?:ValueTypes["BannersDocAccessFields_location_page"],
	eventRankingList?:ValueTypes["BannersDocAccessFields_location_eventRankingList"],
	locationRankingList?:ValueTypes["BannersDocAccessFields_location_locationRankingList"],
	categories?:ValueTypes["BannersDocAccessFields_location_categories"],
	time?:ValueTypes["BannersDocAccessFields_location_time"],
	district?:ValueTypes["BannersDocAccessFields_location_district"],
	audience?:ValueTypes["BannersDocAccessFields_location_audience"],
	multipleCriteriaSearch?:ValueTypes["BannersDocAccessFields_location_multipleCriteriaSearch"],
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_location_page"]: AliasType<{
	create?:ValueTypes["BannersDocAccessFields_location_page_Create"],
	read?:ValueTypes["BannersDocAccessFields_location_page_Read"],
	update?:ValueTypes["BannersDocAccessFields_location_page_Update"],
	delete?:ValueTypes["BannersDocAccessFields_location_page_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_location_page_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_location_page_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_location_page_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_location_page_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_location_eventRankingList"]: AliasType<{
	create?:ValueTypes["BannersDocAccessFields_location_eventRankingList_Create"],
	read?:ValueTypes["BannersDocAccessFields_location_eventRankingList_Read"],
	update?:ValueTypes["BannersDocAccessFields_location_eventRankingList_Update"],
	delete?:ValueTypes["BannersDocAccessFields_location_eventRankingList_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_location_eventRankingList_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_location_eventRankingList_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_location_eventRankingList_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_location_eventRankingList_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_location_locationRankingList"]: AliasType<{
	create?:ValueTypes["BannersDocAccessFields_location_locationRankingList_Create"],
	read?:ValueTypes["BannersDocAccessFields_location_locationRankingList_Read"],
	update?:ValueTypes["BannersDocAccessFields_location_locationRankingList_Update"],
	delete?:ValueTypes["BannersDocAccessFields_location_locationRankingList_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_location_locationRankingList_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_location_locationRankingList_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_location_locationRankingList_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_location_locationRankingList_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_location_categories"]: AliasType<{
	create?:ValueTypes["BannersDocAccessFields_location_categories_Create"],
	read?:ValueTypes["BannersDocAccessFields_location_categories_Read"],
	update?:ValueTypes["BannersDocAccessFields_location_categories_Update"],
	delete?:ValueTypes["BannersDocAccessFields_location_categories_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_location_categories_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_location_categories_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_location_categories_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_location_categories_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_location_time"]: AliasType<{
	create?:ValueTypes["BannersDocAccessFields_location_time_Create"],
	read?:ValueTypes["BannersDocAccessFields_location_time_Read"],
	update?:ValueTypes["BannersDocAccessFields_location_time_Update"],
	delete?:ValueTypes["BannersDocAccessFields_location_time_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_location_time_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_location_time_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_location_time_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_location_time_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_location_district"]: AliasType<{
	create?:ValueTypes["BannersDocAccessFields_location_district_Create"],
	read?:ValueTypes["BannersDocAccessFields_location_district_Read"],
	update?:ValueTypes["BannersDocAccessFields_location_district_Update"],
	delete?:ValueTypes["BannersDocAccessFields_location_district_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_location_district_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_location_district_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_location_district_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_location_district_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_location_audience"]: AliasType<{
	create?:ValueTypes["BannersDocAccessFields_location_audience_Create"],
	read?:ValueTypes["BannersDocAccessFields_location_audience_Read"],
	update?:ValueTypes["BannersDocAccessFields_location_audience_Update"],
	delete?:ValueTypes["BannersDocAccessFields_location_audience_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_location_audience_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_location_audience_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_location_audience_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_location_audience_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_location_multipleCriteriaSearch"]: AliasType<{
	create?:ValueTypes["BannersDocAccessFields_location_multipleCriteriaSearch_Create"],
	read?:ValueTypes["BannersDocAccessFields_location_multipleCriteriaSearch_Read"],
	update?:ValueTypes["BannersDocAccessFields_location_multipleCriteriaSearch_Update"],
	delete?:ValueTypes["BannersDocAccessFields_location_multipleCriteriaSearch_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_location_multipleCriteriaSearch_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_location_multipleCriteriaSearch_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_location_multipleCriteriaSearch_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_location_multipleCriteriaSearch_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_link"]: AliasType<{
	create?:ValueTypes["BannersDocAccessFields_link_Create"],
	read?:ValueTypes["BannersDocAccessFields_link_Read"],
	update?:ValueTypes["BannersDocAccessFields_link_Update"],
	delete?:ValueTypes["BannersDocAccessFields_link_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_link_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_link_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_link_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_link_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_external"]: AliasType<{
	create?:ValueTypes["BannersDocAccessFields_external_Create"],
	read?:ValueTypes["BannersDocAccessFields_external_Read"],
	update?:ValueTypes["BannersDocAccessFields_external_Update"],
	delete?:ValueTypes["BannersDocAccessFields_external_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_external_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_external_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_external_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_external_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_type"]: AliasType<{
	create?:ValueTypes["BannersDocAccessFields_type_Create"],
	read?:ValueTypes["BannersDocAccessFields_type_Read"],
	update?:ValueTypes["BannersDocAccessFields_type_Update"],
	delete?:ValueTypes["BannersDocAccessFields_type_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_type_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_type_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_type_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_type_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_status"]: AliasType<{
	create?:ValueTypes["BannersDocAccessFields_status_Create"],
	read?:ValueTypes["BannersDocAccessFields_status_Read"],
	update?:ValueTypes["BannersDocAccessFields_status_Update"],
	delete?:ValueTypes["BannersDocAccessFields_status_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_status_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_status_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_status_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_status_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_advertise"]: AliasType<{
	create?:ValueTypes["BannersDocAccessFields_advertise_Create"],
	read?:ValueTypes["BannersDocAccessFields_advertise_Read"],
	update?:ValueTypes["BannersDocAccessFields_advertise_Update"],
	delete?:ValueTypes["BannersDocAccessFields_advertise_Delete"],
	fields?:ValueTypes["BannersDocAccessFields_advertise_Fields"],
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_advertise_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_advertise_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_advertise_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_advertise_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_advertise_Fields"]: AliasType<{
	campaign?:ValueTypes["BannersDocAccessFields_advertise_campaign"],
	invoiceDescription?:ValueTypes["BannersDocAccessFields_advertise_invoiceDescription"],
	standardPrice?:ValueTypes["BannersDocAccessFields_advertise_standardPrice"],
	discount?:ValueTypes["BannersDocAccessFields_advertise_discount"],
	timableCost?:ValueTypes["BannersDocAccessFields_advertise_timableCost"],
	remark?:ValueTypes["BannersDocAccessFields_advertise_remark"],
	public?:ValueTypes["BannersDocAccessFields_advertise_public"],
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_advertise_campaign"]: AliasType<{
	create?:ValueTypes["BannersDocAccessFields_advertise_campaign_Create"],
	read?:ValueTypes["BannersDocAccessFields_advertise_campaign_Read"],
	update?:ValueTypes["BannersDocAccessFields_advertise_campaign_Update"],
	delete?:ValueTypes["BannersDocAccessFields_advertise_campaign_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_advertise_campaign_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_advertise_campaign_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_advertise_campaign_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_advertise_campaign_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_advertise_invoiceDescription"]: AliasType<{
	create?:ValueTypes["BannersDocAccessFields_advertise_invoiceDescription_Create"],
	read?:ValueTypes["BannersDocAccessFields_advertise_invoiceDescription_Read"],
	update?:ValueTypes["BannersDocAccessFields_advertise_invoiceDescription_Update"],
	delete?:ValueTypes["BannersDocAccessFields_advertise_invoiceDescription_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_advertise_invoiceDescription_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_advertise_invoiceDescription_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_advertise_invoiceDescription_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_advertise_invoiceDescription_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_advertise_standardPrice"]: AliasType<{
	create?:ValueTypes["BannersDocAccessFields_advertise_standardPrice_Create"],
	read?:ValueTypes["BannersDocAccessFields_advertise_standardPrice_Read"],
	update?:ValueTypes["BannersDocAccessFields_advertise_standardPrice_Update"],
	delete?:ValueTypes["BannersDocAccessFields_advertise_standardPrice_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_advertise_standardPrice_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_advertise_standardPrice_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_advertise_standardPrice_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_advertise_standardPrice_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_advertise_discount"]: AliasType<{
	create?:ValueTypes["BannersDocAccessFields_advertise_discount_Create"],
	read?:ValueTypes["BannersDocAccessFields_advertise_discount_Read"],
	update?:ValueTypes["BannersDocAccessFields_advertise_discount_Update"],
	delete?:ValueTypes["BannersDocAccessFields_advertise_discount_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_advertise_discount_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_advertise_discount_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_advertise_discount_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_advertise_discount_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_advertise_timableCost"]: AliasType<{
	create?:ValueTypes["BannersDocAccessFields_advertise_timableCost_Create"],
	read?:ValueTypes["BannersDocAccessFields_advertise_timableCost_Read"],
	update?:ValueTypes["BannersDocAccessFields_advertise_timableCost_Update"],
	delete?:ValueTypes["BannersDocAccessFields_advertise_timableCost_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_advertise_timableCost_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_advertise_timableCost_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_advertise_timableCost_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_advertise_timableCost_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_advertise_remark"]: AliasType<{
	create?:ValueTypes["BannersDocAccessFields_advertise_remark_Create"],
	read?:ValueTypes["BannersDocAccessFields_advertise_remark_Read"],
	update?:ValueTypes["BannersDocAccessFields_advertise_remark_Update"],
	delete?:ValueTypes["BannersDocAccessFields_advertise_remark_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_advertise_remark_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_advertise_remark_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_advertise_remark_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_advertise_remark_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_advertise_public"]: AliasType<{
	create?:ValueTypes["BannersDocAccessFields_advertise_public_Create"],
	read?:ValueTypes["BannersDocAccessFields_advertise_public_Read"],
	update?:ValueTypes["BannersDocAccessFields_advertise_public_Update"],
	delete?:ValueTypes["BannersDocAccessFields_advertise_public_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_advertise_public_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_advertise_public_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_advertise_public_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_advertise_public_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields__locations"]: AliasType<{
	create?:ValueTypes["BannersDocAccessFields__locations_Create"],
	read?:ValueTypes["BannersDocAccessFields__locations_Read"],
	update?:ValueTypes["BannersDocAccessFields__locations_Update"],
	delete?:ValueTypes["BannersDocAccessFields__locations_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields__locations_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields__locations_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields__locations_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields__locations_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_region"]: AliasType<{
	create?:ValueTypes["BannersDocAccessFields_region_Create"],
	read?:ValueTypes["BannersDocAccessFields_region_Read"],
	update?:ValueTypes["BannersDocAccessFields_region_Update"],
	delete?:ValueTypes["BannersDocAccessFields_region_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_region_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_region_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_region_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_region_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_homePageClick"]: AliasType<{
	create?:ValueTypes["BannersDocAccessFields_homePageClick_Create"],
	read?:ValueTypes["BannersDocAccessFields_homePageClick_Read"],
	update?:ValueTypes["BannersDocAccessFields_homePageClick_Update"],
	delete?:ValueTypes["BannersDocAccessFields_homePageClick_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_homePageClick_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_homePageClick_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_homePageClick_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_homePageClick_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_homePageImpression"]: AliasType<{
	create?:ValueTypes["BannersDocAccessFields_homePageImpression_Create"],
	read?:ValueTypes["BannersDocAccessFields_homePageImpression_Read"],
	update?:ValueTypes["BannersDocAccessFields_homePageImpression_Update"],
	delete?:ValueTypes["BannersDocAccessFields_homePageImpression_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_homePageImpression_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_homePageImpression_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_homePageImpression_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_homePageImpression_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_latestEventClick"]: AliasType<{
	create?:ValueTypes["BannersDocAccessFields_latestEventClick_Create"],
	read?:ValueTypes["BannersDocAccessFields_latestEventClick_Read"],
	update?:ValueTypes["BannersDocAccessFields_latestEventClick_Update"],
	delete?:ValueTypes["BannersDocAccessFields_latestEventClick_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_latestEventClick_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_latestEventClick_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_latestEventClick_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_latestEventClick_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_latestEventImpression"]: AliasType<{
	create?:ValueTypes["BannersDocAccessFields_latestEventImpression_Create"],
	read?:ValueTypes["BannersDocAccessFields_latestEventImpression_Read"],
	update?:ValueTypes["BannersDocAccessFields_latestEventImpression_Update"],
	delete?:ValueTypes["BannersDocAccessFields_latestEventImpression_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_latestEventImpression_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_latestEventImpression_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_latestEventImpression_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_latestEventImpression_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_editorChoiceClick"]: AliasType<{
	create?:ValueTypes["BannersDocAccessFields_editorChoiceClick_Create"],
	read?:ValueTypes["BannersDocAccessFields_editorChoiceClick_Read"],
	update?:ValueTypes["BannersDocAccessFields_editorChoiceClick_Update"],
	delete?:ValueTypes["BannersDocAccessFields_editorChoiceClick_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_editorChoiceClick_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_editorChoiceClick_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_editorChoiceClick_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_editorChoiceClick_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_editorChoiceImpression"]: AliasType<{
	create?:ValueTypes["BannersDocAccessFields_editorChoiceImpression_Create"],
	read?:ValueTypes["BannersDocAccessFields_editorChoiceImpression_Read"],
	update?:ValueTypes["BannersDocAccessFields_editorChoiceImpression_Update"],
	delete?:ValueTypes["BannersDocAccessFields_editorChoiceImpression_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_editorChoiceImpression_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_editorChoiceImpression_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_editorChoiceImpression_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_editorChoiceImpression_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_eventListClick"]: AliasType<{
	create?:ValueTypes["BannersDocAccessFields_eventListClick_Create"],
	read?:ValueTypes["BannersDocAccessFields_eventListClick_Read"],
	update?:ValueTypes["BannersDocAccessFields_eventListClick_Update"],
	delete?:ValueTypes["BannersDocAccessFields_eventListClick_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_eventListClick_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_eventListClick_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_eventListClick_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_eventListClick_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_eventListImpression"]: AliasType<{
	create?:ValueTypes["BannersDocAccessFields_eventListImpression_Create"],
	read?:ValueTypes["BannersDocAccessFields_eventListImpression_Read"],
	update?:ValueTypes["BannersDocAccessFields_eventListImpression_Update"],
	delete?:ValueTypes["BannersDocAccessFields_eventListImpression_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_eventListImpression_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_eventListImpression_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_eventListImpression_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_eventListImpression_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_topTenClick"]: AliasType<{
	create?:ValueTypes["BannersDocAccessFields_topTenClick_Create"],
	read?:ValueTypes["BannersDocAccessFields_topTenClick_Read"],
	update?:ValueTypes["BannersDocAccessFields_topTenClick_Update"],
	delete?:ValueTypes["BannersDocAccessFields_topTenClick_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_topTenClick_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_topTenClick_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_topTenClick_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_topTenClick_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_topTenImpression"]: AliasType<{
	create?:ValueTypes["BannersDocAccessFields_topTenImpression_Create"],
	read?:ValueTypes["BannersDocAccessFields_topTenImpression_Read"],
	update?:ValueTypes["BannersDocAccessFields_topTenImpression_Update"],
	delete?:ValueTypes["BannersDocAccessFields_topTenImpression_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_topTenImpression_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_topTenImpression_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_topTenImpression_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_topTenImpression_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_miscClick"]: AliasType<{
	create?:ValueTypes["BannersDocAccessFields_miscClick_Create"],
	read?:ValueTypes["BannersDocAccessFields_miscClick_Read"],
	update?:ValueTypes["BannersDocAccessFields_miscClick_Update"],
	delete?:ValueTypes["BannersDocAccessFields_miscClick_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_miscClick_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_miscClick_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_miscClick_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_miscClick_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_miscImpression"]: AliasType<{
	create?:ValueTypes["BannersDocAccessFields_miscImpression_Create"],
	read?:ValueTypes["BannersDocAccessFields_miscImpression_Read"],
	update?:ValueTypes["BannersDocAccessFields_miscImpression_Update"],
	delete?:ValueTypes["BannersDocAccessFields_miscImpression_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_miscImpression_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_miscImpression_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_miscImpression_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_miscImpression_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_clickTotal"]: AliasType<{
	create?:ValueTypes["BannersDocAccessFields_clickTotal_Create"],
	read?:ValueTypes["BannersDocAccessFields_clickTotal_Read"],
	update?:ValueTypes["BannersDocAccessFields_clickTotal_Update"],
	delete?:ValueTypes["BannersDocAccessFields_clickTotal_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_clickTotal_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_clickTotal_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_clickTotal_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_clickTotal_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_totalImpression"]: AliasType<{
	create?:ValueTypes["BannersDocAccessFields_totalImpression_Create"],
	read?:ValueTypes["BannersDocAccessFields_totalImpression_Read"],
	update?:ValueTypes["BannersDocAccessFields_totalImpression_Update"],
	delete?:ValueTypes["BannersDocAccessFields_totalImpression_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_totalImpression_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_totalImpression_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_totalImpression_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_totalImpression_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_updatedAt"]: AliasType<{
	create?:ValueTypes["BannersDocAccessFields_updatedAt_Create"],
	read?:ValueTypes["BannersDocAccessFields_updatedAt_Read"],
	update?:ValueTypes["BannersDocAccessFields_updatedAt_Update"],
	delete?:ValueTypes["BannersDocAccessFields_updatedAt_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_updatedAt_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_updatedAt_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_updatedAt_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_updatedAt_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_createdAt"]: AliasType<{
	create?:ValueTypes["BannersDocAccessFields_createdAt_Create"],
	read?:ValueTypes["BannersDocAccessFields_createdAt_Read"],
	update?:ValueTypes["BannersDocAccessFields_createdAt_Update"],
	delete?:ValueTypes["BannersDocAccessFields_createdAt_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_createdAt_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_createdAt_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_createdAt_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_createdAt_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersCreateDocAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersReadDocAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersUpdateDocAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersDeleteDocAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["Campaigns"]: AliasType<{
	docs?:ValueTypes["Campaign"],
	hasNextPage?:boolean | `@${string}`,
	hasPrevPage?:boolean | `@${string}`,
	limit?:boolean | `@${string}`,
	nextPage?:boolean | `@${string}`,
	offset?:boolean | `@${string}`,
	page?:boolean | `@${string}`,
	pagingCounter?:boolean | `@${string}`,
	prevPage?:boolean | `@${string}`,
	totalDocs?:boolean | `@${string}`,
	totalPages?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["Campaign_where"]: {
	creator?: ValueTypes["Campaign_creator_Relation"] | undefined | null | Variable<any, string>,
	invoiceNumber?: ValueTypes["Campaign_invoiceNumber_operator"] | undefined | null | Variable<any, string>,
	quotationNumber?: ValueTypes["Campaign_quotationNumber_operator"] | undefined | null | Variable<any, string>,
	campaignName?: ValueTypes["Campaign_campaignName_operator"] | undefined | null | Variable<any, string>,
	client?: ValueTypes["Campaign_client_operator"] | undefined | null | Variable<any, string>,
	contactPerson?: ValueTypes["Campaign_contactPerson_operator"] | undefined | null | Variable<any, string>,
	email?: ValueTypes["Campaign_email_operator"] | undefined | null | Variable<any, string>,
	phone?: ValueTypes["Campaign_phone_operator"] | undefined | null | Variable<any, string>,
	agentName?: ValueTypes["Campaign_agentName_operator"] | undefined | null | Variable<any, string>,
	agentCommission?: ValueTypes["Campaign_agentCommission_operator"] | undefined | null | Variable<any, string>,
	agentOrderNumber?: ValueTypes["Campaign_agentOrderNumber_operator"] | undefined | null | Variable<any, string>,
	_firstPublishedTime?: ValueTypes["Campaign__firstPublishedTime_operator"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["Campaign_updatedAt_operator"] | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["Campaign_createdAt_operator"] | undefined | null | Variable<any, string>,
	id?: ValueTypes["Campaign_id_operator"] | undefined | null | Variable<any, string>,
	AND?: Array<ValueTypes["Campaign_where_and"] | undefined | null> | undefined | null | Variable<any, string>,
	OR?: Array<ValueTypes["Campaign_where_or"] | undefined | null> | undefined | null | Variable<any, string>
};
	["Campaign_creator_Relation"]: {
	relationTo?: ValueTypes["Campaign_creator_Relation_RelationTo"] | undefined | null | Variable<any, string>,
	value?: ValueTypes["JSON"] | undefined | null | Variable<any, string>
};
	["Campaign_creator_Relation_RelationTo"]:Campaign_creator_Relation_RelationTo;
	["Campaign_invoiceNumber_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Campaign_quotationNumber_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Campaign_campaignName_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>
};
	["Campaign_client_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>
};
	["Campaign_contactPerson_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>
};
	["Campaign_email_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>
};
	["Campaign_phone_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>
};
	["Campaign_agentName_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Campaign_agentCommission_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>
};
	["Campaign_agentOrderNumber_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Campaign__firstPublishedTime_operator"]: {
	equals?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	not_equals?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	greater_than_equal?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	greater_than?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	less_than_equal?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	less_than?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	like?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Campaign_updatedAt_operator"]: {
	equals?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	not_equals?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	greater_than_equal?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	greater_than?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	less_than_equal?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	less_than?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	like?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Campaign_createdAt_operator"]: {
	equals?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	not_equals?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	greater_than_equal?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	greater_than?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	less_than_equal?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	less_than?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	like?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Campaign_id_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Campaign_where_and"]: {
	creator?: ValueTypes["Campaign_creator_Relation"] | undefined | null | Variable<any, string>,
	invoiceNumber?: ValueTypes["Campaign_invoiceNumber_operator"] | undefined | null | Variable<any, string>,
	quotationNumber?: ValueTypes["Campaign_quotationNumber_operator"] | undefined | null | Variable<any, string>,
	campaignName?: ValueTypes["Campaign_campaignName_operator"] | undefined | null | Variable<any, string>,
	client?: ValueTypes["Campaign_client_operator"] | undefined | null | Variable<any, string>,
	contactPerson?: ValueTypes["Campaign_contactPerson_operator"] | undefined | null | Variable<any, string>,
	email?: ValueTypes["Campaign_email_operator"] | undefined | null | Variable<any, string>,
	phone?: ValueTypes["Campaign_phone_operator"] | undefined | null | Variable<any, string>,
	agentName?: ValueTypes["Campaign_agentName_operator"] | undefined | null | Variable<any, string>,
	agentCommission?: ValueTypes["Campaign_agentCommission_operator"] | undefined | null | Variable<any, string>,
	agentOrderNumber?: ValueTypes["Campaign_agentOrderNumber_operator"] | undefined | null | Variable<any, string>,
	_firstPublishedTime?: ValueTypes["Campaign__firstPublishedTime_operator"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["Campaign_updatedAt_operator"] | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["Campaign_createdAt_operator"] | undefined | null | Variable<any, string>,
	id?: ValueTypes["Campaign_id_operator"] | undefined | null | Variable<any, string>,
	AND?: Array<ValueTypes["Campaign_where_and"] | undefined | null> | undefined | null | Variable<any, string>,
	OR?: Array<ValueTypes["Campaign_where_or"] | undefined | null> | undefined | null | Variable<any, string>
};
	["Campaign_where_or"]: {
	creator?: ValueTypes["Campaign_creator_Relation"] | undefined | null | Variable<any, string>,
	invoiceNumber?: ValueTypes["Campaign_invoiceNumber_operator"] | undefined | null | Variable<any, string>,
	quotationNumber?: ValueTypes["Campaign_quotationNumber_operator"] | undefined | null | Variable<any, string>,
	campaignName?: ValueTypes["Campaign_campaignName_operator"] | undefined | null | Variable<any, string>,
	client?: ValueTypes["Campaign_client_operator"] | undefined | null | Variable<any, string>,
	contactPerson?: ValueTypes["Campaign_contactPerson_operator"] | undefined | null | Variable<any, string>,
	email?: ValueTypes["Campaign_email_operator"] | undefined | null | Variable<any, string>,
	phone?: ValueTypes["Campaign_phone_operator"] | undefined | null | Variable<any, string>,
	agentName?: ValueTypes["Campaign_agentName_operator"] | undefined | null | Variable<any, string>,
	agentCommission?: ValueTypes["Campaign_agentCommission_operator"] | undefined | null | Variable<any, string>,
	agentOrderNumber?: ValueTypes["Campaign_agentOrderNumber_operator"] | undefined | null | Variable<any, string>,
	_firstPublishedTime?: ValueTypes["Campaign__firstPublishedTime_operator"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["Campaign_updatedAt_operator"] | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["Campaign_createdAt_operator"] | undefined | null | Variable<any, string>,
	id?: ValueTypes["Campaign_id_operator"] | undefined | null | Variable<any, string>,
	AND?: Array<ValueTypes["Campaign_where_and"] | undefined | null> | undefined | null | Variable<any, string>,
	OR?: Array<ValueTypes["Campaign_where_or"] | undefined | null> | undefined | null | Variable<any, string>
};
	["countCampaigns"]: AliasType<{
	totalDocs?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["campaignsDocAccess"]: AliasType<{
	fields?:ValueTypes["CampaignsDocAccessFields"],
	create?:ValueTypes["CampaignsCreateDocAccess"],
	read?:ValueTypes["CampaignsReadDocAccess"],
	update?:ValueTypes["CampaignsUpdateDocAccess"],
	delete?:ValueTypes["CampaignsDeleteDocAccess"],
		__typename?: boolean | `@${string}`
}>;
	["CampaignsDocAccessFields"]: AliasType<{
	creator?:ValueTypes["CampaignsDocAccessFields_creator"],
	invoiceNumber?:ValueTypes["CampaignsDocAccessFields_invoiceNumber"],
	quotationNumber?:ValueTypes["CampaignsDocAccessFields_quotationNumber"],
	campaignName?:ValueTypes["CampaignsDocAccessFields_campaignName"],
	client?:ValueTypes["CampaignsDocAccessFields_client"],
	contactPerson?:ValueTypes["CampaignsDocAccessFields_contactPerson"],
	email?:ValueTypes["CampaignsDocAccessFields_email"],
	phone?:ValueTypes["CampaignsDocAccessFields_phone"],
	agentName?:ValueTypes["CampaignsDocAccessFields_agentName"],
	agentCommission?:ValueTypes["CampaignsDocAccessFields_agentCommission"],
	agentOrderNumber?:ValueTypes["CampaignsDocAccessFields_agentOrderNumber"],
	_firstPublishedTime?:ValueTypes["CampaignsDocAccessFields__firstPublishedTime"],
	updatedAt?:ValueTypes["CampaignsDocAccessFields_updatedAt"],
	createdAt?:ValueTypes["CampaignsDocAccessFields_createdAt"],
		__typename?: boolean | `@${string}`
}>;
	["CampaignsDocAccessFields_creator"]: AliasType<{
	create?:ValueTypes["CampaignsDocAccessFields_creator_Create"],
	read?:ValueTypes["CampaignsDocAccessFields_creator_Read"],
	update?:ValueTypes["CampaignsDocAccessFields_creator_Update"],
	delete?:ValueTypes["CampaignsDocAccessFields_creator_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["CampaignsDocAccessFields_creator_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CampaignsDocAccessFields_creator_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CampaignsDocAccessFields_creator_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CampaignsDocAccessFields_creator_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CampaignsDocAccessFields_invoiceNumber"]: AliasType<{
	create?:ValueTypes["CampaignsDocAccessFields_invoiceNumber_Create"],
	read?:ValueTypes["CampaignsDocAccessFields_invoiceNumber_Read"],
	update?:ValueTypes["CampaignsDocAccessFields_invoiceNumber_Update"],
	delete?:ValueTypes["CampaignsDocAccessFields_invoiceNumber_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["CampaignsDocAccessFields_invoiceNumber_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CampaignsDocAccessFields_invoiceNumber_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CampaignsDocAccessFields_invoiceNumber_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CampaignsDocAccessFields_invoiceNumber_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CampaignsDocAccessFields_quotationNumber"]: AliasType<{
	create?:ValueTypes["CampaignsDocAccessFields_quotationNumber_Create"],
	read?:ValueTypes["CampaignsDocAccessFields_quotationNumber_Read"],
	update?:ValueTypes["CampaignsDocAccessFields_quotationNumber_Update"],
	delete?:ValueTypes["CampaignsDocAccessFields_quotationNumber_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["CampaignsDocAccessFields_quotationNumber_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CampaignsDocAccessFields_quotationNumber_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CampaignsDocAccessFields_quotationNumber_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CampaignsDocAccessFields_quotationNumber_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CampaignsDocAccessFields_campaignName"]: AliasType<{
	create?:ValueTypes["CampaignsDocAccessFields_campaignName_Create"],
	read?:ValueTypes["CampaignsDocAccessFields_campaignName_Read"],
	update?:ValueTypes["CampaignsDocAccessFields_campaignName_Update"],
	delete?:ValueTypes["CampaignsDocAccessFields_campaignName_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["CampaignsDocAccessFields_campaignName_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CampaignsDocAccessFields_campaignName_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CampaignsDocAccessFields_campaignName_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CampaignsDocAccessFields_campaignName_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CampaignsDocAccessFields_client"]: AliasType<{
	create?:ValueTypes["CampaignsDocAccessFields_client_Create"],
	read?:ValueTypes["CampaignsDocAccessFields_client_Read"],
	update?:ValueTypes["CampaignsDocAccessFields_client_Update"],
	delete?:ValueTypes["CampaignsDocAccessFields_client_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["CampaignsDocAccessFields_client_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CampaignsDocAccessFields_client_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CampaignsDocAccessFields_client_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CampaignsDocAccessFields_client_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CampaignsDocAccessFields_contactPerson"]: AliasType<{
	create?:ValueTypes["CampaignsDocAccessFields_contactPerson_Create"],
	read?:ValueTypes["CampaignsDocAccessFields_contactPerson_Read"],
	update?:ValueTypes["CampaignsDocAccessFields_contactPerson_Update"],
	delete?:ValueTypes["CampaignsDocAccessFields_contactPerson_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["CampaignsDocAccessFields_contactPerson_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CampaignsDocAccessFields_contactPerson_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CampaignsDocAccessFields_contactPerson_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CampaignsDocAccessFields_contactPerson_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CampaignsDocAccessFields_email"]: AliasType<{
	create?:ValueTypes["CampaignsDocAccessFields_email_Create"],
	read?:ValueTypes["CampaignsDocAccessFields_email_Read"],
	update?:ValueTypes["CampaignsDocAccessFields_email_Update"],
	delete?:ValueTypes["CampaignsDocAccessFields_email_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["CampaignsDocAccessFields_email_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CampaignsDocAccessFields_email_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CampaignsDocAccessFields_email_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CampaignsDocAccessFields_email_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CampaignsDocAccessFields_phone"]: AliasType<{
	create?:ValueTypes["CampaignsDocAccessFields_phone_Create"],
	read?:ValueTypes["CampaignsDocAccessFields_phone_Read"],
	update?:ValueTypes["CampaignsDocAccessFields_phone_Update"],
	delete?:ValueTypes["CampaignsDocAccessFields_phone_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["CampaignsDocAccessFields_phone_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CampaignsDocAccessFields_phone_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CampaignsDocAccessFields_phone_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CampaignsDocAccessFields_phone_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CampaignsDocAccessFields_agentName"]: AliasType<{
	create?:ValueTypes["CampaignsDocAccessFields_agentName_Create"],
	read?:ValueTypes["CampaignsDocAccessFields_agentName_Read"],
	update?:ValueTypes["CampaignsDocAccessFields_agentName_Update"],
	delete?:ValueTypes["CampaignsDocAccessFields_agentName_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["CampaignsDocAccessFields_agentName_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CampaignsDocAccessFields_agentName_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CampaignsDocAccessFields_agentName_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CampaignsDocAccessFields_agentName_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CampaignsDocAccessFields_agentCommission"]: AliasType<{
	create?:ValueTypes["CampaignsDocAccessFields_agentCommission_Create"],
	read?:ValueTypes["CampaignsDocAccessFields_agentCommission_Read"],
	update?:ValueTypes["CampaignsDocAccessFields_agentCommission_Update"],
	delete?:ValueTypes["CampaignsDocAccessFields_agentCommission_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["CampaignsDocAccessFields_agentCommission_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CampaignsDocAccessFields_agentCommission_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CampaignsDocAccessFields_agentCommission_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CampaignsDocAccessFields_agentCommission_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CampaignsDocAccessFields_agentOrderNumber"]: AliasType<{
	create?:ValueTypes["CampaignsDocAccessFields_agentOrderNumber_Create"],
	read?:ValueTypes["CampaignsDocAccessFields_agentOrderNumber_Read"],
	update?:ValueTypes["CampaignsDocAccessFields_agentOrderNumber_Update"],
	delete?:ValueTypes["CampaignsDocAccessFields_agentOrderNumber_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["CampaignsDocAccessFields_agentOrderNumber_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CampaignsDocAccessFields_agentOrderNumber_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CampaignsDocAccessFields_agentOrderNumber_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CampaignsDocAccessFields_agentOrderNumber_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CampaignsDocAccessFields__firstPublishedTime"]: AliasType<{
	create?:ValueTypes["CampaignsDocAccessFields__firstPublishedTime_Create"],
	read?:ValueTypes["CampaignsDocAccessFields__firstPublishedTime_Read"],
	update?:ValueTypes["CampaignsDocAccessFields__firstPublishedTime_Update"],
	delete?:ValueTypes["CampaignsDocAccessFields__firstPublishedTime_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["CampaignsDocAccessFields__firstPublishedTime_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CampaignsDocAccessFields__firstPublishedTime_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CampaignsDocAccessFields__firstPublishedTime_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CampaignsDocAccessFields__firstPublishedTime_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CampaignsDocAccessFields_updatedAt"]: AliasType<{
	create?:ValueTypes["CampaignsDocAccessFields_updatedAt_Create"],
	read?:ValueTypes["CampaignsDocAccessFields_updatedAt_Read"],
	update?:ValueTypes["CampaignsDocAccessFields_updatedAt_Update"],
	delete?:ValueTypes["CampaignsDocAccessFields_updatedAt_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["CampaignsDocAccessFields_updatedAt_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CampaignsDocAccessFields_updatedAt_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CampaignsDocAccessFields_updatedAt_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CampaignsDocAccessFields_updatedAt_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CampaignsDocAccessFields_createdAt"]: AliasType<{
	create?:ValueTypes["CampaignsDocAccessFields_createdAt_Create"],
	read?:ValueTypes["CampaignsDocAccessFields_createdAt_Read"],
	update?:ValueTypes["CampaignsDocAccessFields_createdAt_Update"],
	delete?:ValueTypes["CampaignsDocAccessFields_createdAt_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["CampaignsDocAccessFields_createdAt_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CampaignsDocAccessFields_createdAt_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CampaignsDocAccessFields_createdAt_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CampaignsDocAccessFields_createdAt_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CampaignsCreateDocAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CampaignsReadDocAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CampaignsUpdateDocAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CampaignsDeleteDocAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["Advertise"]: AliasType<{
	id?:boolean | `@${string}`,
	advertise?:ValueTypes["Advertise_Advertise"],
	advertiseStartDate?:boolean | `@${string}`,
	advertiseEndDate?:boolean | `@${string}`,
	type?:boolean | `@${string}`,
	invoiceDescription?:boolean | `@${string}`,
	standardPrice?:boolean | `@${string}`,
	discount?:boolean | `@${string}`,
	discountPercentage?:boolean | `@${string}`,
	_calculateByDiscount?:boolean | `@${string}`,
	timableCost?:boolean | `@${string}`,
	remark?:boolean | `@${string}`,
	public?:boolean | `@${string}`,
	campaignName?:boolean | `@${string}`,
	campaignId?:boolean | `@${string}`,
	status?:boolean | `@${string}`,
	updatedAt?:boolean | `@${string}`,
	createdAt?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["Advertise_Advertise"]: AliasType<{
	type?:boolean | `@${string}`,
event?: [{	locale?: ValueTypes["LocaleInputType"] | undefined | null | Variable<any, string>,	fallbackLocale?: ValueTypes["FallbackLocaleInputType"] | undefined | null | Variable<any, string>},ValueTypes["Event"]],
	keyword?:boolean | `@${string}`,
location?: [{	locale?: ValueTypes["LocaleInputType"] | undefined | null | Variable<any, string>,	fallbackLocale?: ValueTypes["FallbackLocaleInputType"] | undefined | null | Variable<any, string>},ValueTypes["Location"]],
	displayPosition?:boolean | `@${string}`,
	rankingListRemark?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["Advertise_Advertise_type"]:Advertise_Advertise_type;
	["Advertise_Advertise_displayPosition"]:Advertise_Advertise_displayPosition;
	["Advertise_type"]:Advertise_type;
	["Advertises"]: AliasType<{
	docs?:ValueTypes["Advertise"],
	hasNextPage?:boolean | `@${string}`,
	hasPrevPage?:boolean | `@${string}`,
	limit?:boolean | `@${string}`,
	nextPage?:boolean | `@${string}`,
	offset?:boolean | `@${string}`,
	page?:boolean | `@${string}`,
	pagingCounter?:boolean | `@${string}`,
	prevPage?:boolean | `@${string}`,
	totalDocs?:boolean | `@${string}`,
	totalPages?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["Advertise_where"]: {
	advertise__type?: ValueTypes["Advertise_advertise__type_operator"] | undefined | null | Variable<any, string>,
	advertise__event?: ValueTypes["Advertise_advertise__event_operator"] | undefined | null | Variable<any, string>,
	advertise__keyword?: ValueTypes["Advertise_advertise__keyword_operator"] | undefined | null | Variable<any, string>,
	advertise__location?: ValueTypes["Advertise_advertise__location_operator"] | undefined | null | Variable<any, string>,
	advertise__displayPosition?: ValueTypes["Advertise_advertise__displayPosition_operator"] | undefined | null | Variable<any, string>,
	advertise__rankingListRemark?: ValueTypes["Advertise_advertise__rankingListRemark_operator"] | undefined | null | Variable<any, string>,
	advertiseStartDate?: ValueTypes["Advertise_advertiseStartDate_operator"] | undefined | null | Variable<any, string>,
	advertiseEndDate?: ValueTypes["Advertise_advertiseEndDate_operator"] | undefined | null | Variable<any, string>,
	type?: ValueTypes["Advertise_type_operator"] | undefined | null | Variable<any, string>,
	invoiceDescription?: ValueTypes["Advertise_invoiceDescription_operator"] | undefined | null | Variable<any, string>,
	standardPrice?: ValueTypes["Advertise_standardPrice_operator"] | undefined | null | Variable<any, string>,
	discount?: ValueTypes["Advertise_discount_operator"] | undefined | null | Variable<any, string>,
	discountPercentage?: ValueTypes["Advertise_discountPercentage_operator"] | undefined | null | Variable<any, string>,
	_calculateByDiscount?: ValueTypes["Advertise__calculateByDiscount_operator"] | undefined | null | Variable<any, string>,
	timableCost?: ValueTypes["Advertise_timableCost_operator"] | undefined | null | Variable<any, string>,
	remark?: ValueTypes["Advertise_remark_operator"] | undefined | null | Variable<any, string>,
	public?: ValueTypes["Advertise_public_operator"] | undefined | null | Variable<any, string>,
	campaignName?: ValueTypes["Advertise_campaignName_operator"] | undefined | null | Variable<any, string>,
	campaignId?: ValueTypes["Advertise_campaignId_operator"] | undefined | null | Variable<any, string>,
	status?: ValueTypes["Advertise_status_operator"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["Advertise_updatedAt_operator"] | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["Advertise_createdAt_operator"] | undefined | null | Variable<any, string>,
	id?: ValueTypes["Advertise_id_operator"] | undefined | null | Variable<any, string>,
	AND?: Array<ValueTypes["Advertise_where_and"] | undefined | null> | undefined | null | Variable<any, string>,
	OR?: Array<ValueTypes["Advertise_where_or"] | undefined | null> | undefined | null | Variable<any, string>
};
	["Advertise_advertise__type_operator"]: {
	equals?: ValueTypes["Advertise_advertise__type_Input"] | undefined | null | Variable<any, string>,
	not_equals?: ValueTypes["Advertise_advertise__type_Input"] | undefined | null | Variable<any, string>,
	in?: Array<ValueTypes["Advertise_advertise__type_Input"] | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<ValueTypes["Advertise_advertise__type_Input"] | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<ValueTypes["Advertise_advertise__type_Input"] | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Advertise_advertise__type_Input"]:Advertise_advertise__type_Input;
	["Advertise_advertise__event_operator"]: {
	equals?: ValueTypes["JSON"] | undefined | null | Variable<any, string>,
	not_equals?: ValueTypes["JSON"] | undefined | null | Variable<any, string>,
	in?: Array<ValueTypes["JSON"] | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<ValueTypes["JSON"] | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<ValueTypes["JSON"] | undefined | null> | undefined | null | Variable<any, string>
};
	["Advertise_advertise__keyword_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>
};
	["Advertise_advertise__location_operator"]: {
	equals?: ValueTypes["JSON"] | undefined | null | Variable<any, string>,
	not_equals?: ValueTypes["JSON"] | undefined | null | Variable<any, string>,
	in?: Array<ValueTypes["JSON"] | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<ValueTypes["JSON"] | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<ValueTypes["JSON"] | undefined | null> | undefined | null | Variable<any, string>
};
	["Advertise_advertise__displayPosition_operator"]: {
	equals?: ValueTypes["Advertise_advertise__displayPosition_Input"] | undefined | null | Variable<any, string>,
	not_equals?: ValueTypes["Advertise_advertise__displayPosition_Input"] | undefined | null | Variable<any, string>,
	like?: ValueTypes["Advertise_advertise__displayPosition_Input"] | undefined | null | Variable<any, string>,
	contains?: ValueTypes["Advertise_advertise__displayPosition_Input"] | undefined | null | Variable<any, string>
};
	["Advertise_advertise__displayPosition_Input"]:Advertise_advertise__displayPosition_Input;
	["Advertise_advertise__rankingListRemark_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Advertise_advertiseStartDate_operator"]: {
	equals?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	not_equals?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	greater_than_equal?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	greater_than?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	less_than_equal?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	less_than?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	like?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>
};
	["Advertise_advertiseEndDate_operator"]: {
	equals?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	not_equals?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	greater_than_equal?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	greater_than?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	less_than_equal?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	less_than?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	like?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>
};
	["Advertise_type_operator"]: {
	equals?: ValueTypes["Advertise_type_Input"] | undefined | null | Variable<any, string>,
	not_equals?: ValueTypes["Advertise_type_Input"] | undefined | null | Variable<any, string>,
	in?: Array<ValueTypes["Advertise_type_Input"] | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<ValueTypes["Advertise_type_Input"] | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<ValueTypes["Advertise_type_Input"] | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Advertise_type_Input"]:Advertise_type_Input;
	["Advertise_invoiceDescription_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Advertise_standardPrice_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>
};
	["Advertise_discount_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Advertise_discountPercentage_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Advertise__calculateByDiscount_operator"]: {
	equals?: boolean | undefined | null | Variable<any, string>,
	not_equals?: boolean | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Advertise_timableCost_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Advertise_remark_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Advertise_public_operator"]: {
	equals?: boolean | undefined | null | Variable<any, string>,
	not_equals?: boolean | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Advertise_campaignName_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Advertise_campaignId_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Advertise_status_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Advertise_updatedAt_operator"]: {
	equals?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	not_equals?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	greater_than_equal?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	greater_than?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	less_than_equal?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	less_than?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	like?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Advertise_createdAt_operator"]: {
	equals?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	not_equals?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	greater_than_equal?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	greater_than?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	less_than_equal?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	less_than?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	like?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Advertise_id_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Advertise_where_and"]: {
	advertise__type?: ValueTypes["Advertise_advertise__type_operator"] | undefined | null | Variable<any, string>,
	advertise__event?: ValueTypes["Advertise_advertise__event_operator"] | undefined | null | Variable<any, string>,
	advertise__keyword?: ValueTypes["Advertise_advertise__keyword_operator"] | undefined | null | Variable<any, string>,
	advertise__location?: ValueTypes["Advertise_advertise__location_operator"] | undefined | null | Variable<any, string>,
	advertise__displayPosition?: ValueTypes["Advertise_advertise__displayPosition_operator"] | undefined | null | Variable<any, string>,
	advertise__rankingListRemark?: ValueTypes["Advertise_advertise__rankingListRemark_operator"] | undefined | null | Variable<any, string>,
	advertiseStartDate?: ValueTypes["Advertise_advertiseStartDate_operator"] | undefined | null | Variable<any, string>,
	advertiseEndDate?: ValueTypes["Advertise_advertiseEndDate_operator"] | undefined | null | Variable<any, string>,
	type?: ValueTypes["Advertise_type_operator"] | undefined | null | Variable<any, string>,
	invoiceDescription?: ValueTypes["Advertise_invoiceDescription_operator"] | undefined | null | Variable<any, string>,
	standardPrice?: ValueTypes["Advertise_standardPrice_operator"] | undefined | null | Variable<any, string>,
	discount?: ValueTypes["Advertise_discount_operator"] | undefined | null | Variable<any, string>,
	discountPercentage?: ValueTypes["Advertise_discountPercentage_operator"] | undefined | null | Variable<any, string>,
	_calculateByDiscount?: ValueTypes["Advertise__calculateByDiscount_operator"] | undefined | null | Variable<any, string>,
	timableCost?: ValueTypes["Advertise_timableCost_operator"] | undefined | null | Variable<any, string>,
	remark?: ValueTypes["Advertise_remark_operator"] | undefined | null | Variable<any, string>,
	public?: ValueTypes["Advertise_public_operator"] | undefined | null | Variable<any, string>,
	campaignName?: ValueTypes["Advertise_campaignName_operator"] | undefined | null | Variable<any, string>,
	campaignId?: ValueTypes["Advertise_campaignId_operator"] | undefined | null | Variable<any, string>,
	status?: ValueTypes["Advertise_status_operator"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["Advertise_updatedAt_operator"] | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["Advertise_createdAt_operator"] | undefined | null | Variable<any, string>,
	id?: ValueTypes["Advertise_id_operator"] | undefined | null | Variable<any, string>,
	AND?: Array<ValueTypes["Advertise_where_and"] | undefined | null> | undefined | null | Variable<any, string>,
	OR?: Array<ValueTypes["Advertise_where_or"] | undefined | null> | undefined | null | Variable<any, string>
};
	["Advertise_where_or"]: {
	advertise__type?: ValueTypes["Advertise_advertise__type_operator"] | undefined | null | Variable<any, string>,
	advertise__event?: ValueTypes["Advertise_advertise__event_operator"] | undefined | null | Variable<any, string>,
	advertise__keyword?: ValueTypes["Advertise_advertise__keyword_operator"] | undefined | null | Variable<any, string>,
	advertise__location?: ValueTypes["Advertise_advertise__location_operator"] | undefined | null | Variable<any, string>,
	advertise__displayPosition?: ValueTypes["Advertise_advertise__displayPosition_operator"] | undefined | null | Variable<any, string>,
	advertise__rankingListRemark?: ValueTypes["Advertise_advertise__rankingListRemark_operator"] | undefined | null | Variable<any, string>,
	advertiseStartDate?: ValueTypes["Advertise_advertiseStartDate_operator"] | undefined | null | Variable<any, string>,
	advertiseEndDate?: ValueTypes["Advertise_advertiseEndDate_operator"] | undefined | null | Variable<any, string>,
	type?: ValueTypes["Advertise_type_operator"] | undefined | null | Variable<any, string>,
	invoiceDescription?: ValueTypes["Advertise_invoiceDescription_operator"] | undefined | null | Variable<any, string>,
	standardPrice?: ValueTypes["Advertise_standardPrice_operator"] | undefined | null | Variable<any, string>,
	discount?: ValueTypes["Advertise_discount_operator"] | undefined | null | Variable<any, string>,
	discountPercentage?: ValueTypes["Advertise_discountPercentage_operator"] | undefined | null | Variable<any, string>,
	_calculateByDiscount?: ValueTypes["Advertise__calculateByDiscount_operator"] | undefined | null | Variable<any, string>,
	timableCost?: ValueTypes["Advertise_timableCost_operator"] | undefined | null | Variable<any, string>,
	remark?: ValueTypes["Advertise_remark_operator"] | undefined | null | Variable<any, string>,
	public?: ValueTypes["Advertise_public_operator"] | undefined | null | Variable<any, string>,
	campaignName?: ValueTypes["Advertise_campaignName_operator"] | undefined | null | Variable<any, string>,
	campaignId?: ValueTypes["Advertise_campaignId_operator"] | undefined | null | Variable<any, string>,
	status?: ValueTypes["Advertise_status_operator"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["Advertise_updatedAt_operator"] | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["Advertise_createdAt_operator"] | undefined | null | Variable<any, string>,
	id?: ValueTypes["Advertise_id_operator"] | undefined | null | Variable<any, string>,
	AND?: Array<ValueTypes["Advertise_where_and"] | undefined | null> | undefined | null | Variable<any, string>,
	OR?: Array<ValueTypes["Advertise_where_or"] | undefined | null> | undefined | null | Variable<any, string>
};
	["countAdvertises"]: AliasType<{
	totalDocs?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["advertisesDocAccess"]: AliasType<{
	fields?:ValueTypes["AdvertisesDocAccessFields"],
	create?:ValueTypes["AdvertisesCreateDocAccess"],
	read?:ValueTypes["AdvertisesReadDocAccess"],
	update?:ValueTypes["AdvertisesUpdateDocAccess"],
	delete?:ValueTypes["AdvertisesDeleteDocAccess"],
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesDocAccessFields"]: AliasType<{
	advertise?:ValueTypes["AdvertisesDocAccessFields_advertise"],
	advertiseStartDate?:ValueTypes["AdvertisesDocAccessFields_advertiseStartDate"],
	advertiseEndDate?:ValueTypes["AdvertisesDocAccessFields_advertiseEndDate"],
	type?:ValueTypes["AdvertisesDocAccessFields_type"],
	invoiceDescription?:ValueTypes["AdvertisesDocAccessFields_invoiceDescription"],
	standardPrice?:ValueTypes["AdvertisesDocAccessFields_standardPrice"],
	discount?:ValueTypes["AdvertisesDocAccessFields_discount"],
	discountPercentage?:ValueTypes["AdvertisesDocAccessFields_discountPercentage"],
	_calculateByDiscount?:ValueTypes["AdvertisesDocAccessFields__calculateByDiscount"],
	timableCost?:ValueTypes["AdvertisesDocAccessFields_timableCost"],
	remark?:ValueTypes["AdvertisesDocAccessFields_remark"],
	public?:ValueTypes["AdvertisesDocAccessFields_public"],
	campaignName?:ValueTypes["AdvertisesDocAccessFields_campaignName"],
	campaignId?:ValueTypes["AdvertisesDocAccessFields_campaignId"],
	status?:ValueTypes["AdvertisesDocAccessFields_status"],
	updatedAt?:ValueTypes["AdvertisesDocAccessFields_updatedAt"],
	createdAt?:ValueTypes["AdvertisesDocAccessFields_createdAt"],
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesDocAccessFields_advertise"]: AliasType<{
	create?:ValueTypes["AdvertisesDocAccessFields_advertise_Create"],
	read?:ValueTypes["AdvertisesDocAccessFields_advertise_Read"],
	update?:ValueTypes["AdvertisesDocAccessFields_advertise_Update"],
	delete?:ValueTypes["AdvertisesDocAccessFields_advertise_Delete"],
	fields?:ValueTypes["AdvertisesDocAccessFields_advertise_Fields"],
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesDocAccessFields_advertise_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesDocAccessFields_advertise_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesDocAccessFields_advertise_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesDocAccessFields_advertise_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesDocAccessFields_advertise_Fields"]: AliasType<{
	type?:ValueTypes["AdvertisesDocAccessFields_advertise_type"],
	event?:ValueTypes["AdvertisesDocAccessFields_advertise_event"],
	keyword?:ValueTypes["AdvertisesDocAccessFields_advertise_keyword"],
	location?:ValueTypes["AdvertisesDocAccessFields_advertise_location"],
	displayPosition?:ValueTypes["AdvertisesDocAccessFields_advertise_displayPosition"],
	rankingListRemark?:ValueTypes["AdvertisesDocAccessFields_advertise_rankingListRemark"],
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesDocAccessFields_advertise_type"]: AliasType<{
	create?:ValueTypes["AdvertisesDocAccessFields_advertise_type_Create"],
	read?:ValueTypes["AdvertisesDocAccessFields_advertise_type_Read"],
	update?:ValueTypes["AdvertisesDocAccessFields_advertise_type_Update"],
	delete?:ValueTypes["AdvertisesDocAccessFields_advertise_type_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesDocAccessFields_advertise_type_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesDocAccessFields_advertise_type_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesDocAccessFields_advertise_type_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesDocAccessFields_advertise_type_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesDocAccessFields_advertise_event"]: AliasType<{
	create?:ValueTypes["AdvertisesDocAccessFields_advertise_event_Create"],
	read?:ValueTypes["AdvertisesDocAccessFields_advertise_event_Read"],
	update?:ValueTypes["AdvertisesDocAccessFields_advertise_event_Update"],
	delete?:ValueTypes["AdvertisesDocAccessFields_advertise_event_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesDocAccessFields_advertise_event_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesDocAccessFields_advertise_event_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesDocAccessFields_advertise_event_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesDocAccessFields_advertise_event_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesDocAccessFields_advertise_keyword"]: AliasType<{
	create?:ValueTypes["AdvertisesDocAccessFields_advertise_keyword_Create"],
	read?:ValueTypes["AdvertisesDocAccessFields_advertise_keyword_Read"],
	update?:ValueTypes["AdvertisesDocAccessFields_advertise_keyword_Update"],
	delete?:ValueTypes["AdvertisesDocAccessFields_advertise_keyword_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesDocAccessFields_advertise_keyword_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesDocAccessFields_advertise_keyword_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesDocAccessFields_advertise_keyword_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesDocAccessFields_advertise_keyword_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesDocAccessFields_advertise_location"]: AliasType<{
	create?:ValueTypes["AdvertisesDocAccessFields_advertise_location_Create"],
	read?:ValueTypes["AdvertisesDocAccessFields_advertise_location_Read"],
	update?:ValueTypes["AdvertisesDocAccessFields_advertise_location_Update"],
	delete?:ValueTypes["AdvertisesDocAccessFields_advertise_location_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesDocAccessFields_advertise_location_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesDocAccessFields_advertise_location_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesDocAccessFields_advertise_location_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesDocAccessFields_advertise_location_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesDocAccessFields_advertise_displayPosition"]: AliasType<{
	create?:ValueTypes["AdvertisesDocAccessFields_advertise_displayPosition_Create"],
	read?:ValueTypes["AdvertisesDocAccessFields_advertise_displayPosition_Read"],
	update?:ValueTypes["AdvertisesDocAccessFields_advertise_displayPosition_Update"],
	delete?:ValueTypes["AdvertisesDocAccessFields_advertise_displayPosition_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesDocAccessFields_advertise_displayPosition_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesDocAccessFields_advertise_displayPosition_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesDocAccessFields_advertise_displayPosition_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesDocAccessFields_advertise_displayPosition_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesDocAccessFields_advertise_rankingListRemark"]: AliasType<{
	create?:ValueTypes["AdvertisesDocAccessFields_advertise_rankingListRemark_Create"],
	read?:ValueTypes["AdvertisesDocAccessFields_advertise_rankingListRemark_Read"],
	update?:ValueTypes["AdvertisesDocAccessFields_advertise_rankingListRemark_Update"],
	delete?:ValueTypes["AdvertisesDocAccessFields_advertise_rankingListRemark_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesDocAccessFields_advertise_rankingListRemark_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesDocAccessFields_advertise_rankingListRemark_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesDocAccessFields_advertise_rankingListRemark_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesDocAccessFields_advertise_rankingListRemark_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesDocAccessFields_advertiseStartDate"]: AliasType<{
	create?:ValueTypes["AdvertisesDocAccessFields_advertiseStartDate_Create"],
	read?:ValueTypes["AdvertisesDocAccessFields_advertiseStartDate_Read"],
	update?:ValueTypes["AdvertisesDocAccessFields_advertiseStartDate_Update"],
	delete?:ValueTypes["AdvertisesDocAccessFields_advertiseStartDate_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesDocAccessFields_advertiseStartDate_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesDocAccessFields_advertiseStartDate_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesDocAccessFields_advertiseStartDate_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesDocAccessFields_advertiseStartDate_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesDocAccessFields_advertiseEndDate"]: AliasType<{
	create?:ValueTypes["AdvertisesDocAccessFields_advertiseEndDate_Create"],
	read?:ValueTypes["AdvertisesDocAccessFields_advertiseEndDate_Read"],
	update?:ValueTypes["AdvertisesDocAccessFields_advertiseEndDate_Update"],
	delete?:ValueTypes["AdvertisesDocAccessFields_advertiseEndDate_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesDocAccessFields_advertiseEndDate_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesDocAccessFields_advertiseEndDate_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesDocAccessFields_advertiseEndDate_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesDocAccessFields_advertiseEndDate_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesDocAccessFields_type"]: AliasType<{
	create?:ValueTypes["AdvertisesDocAccessFields_type_Create"],
	read?:ValueTypes["AdvertisesDocAccessFields_type_Read"],
	update?:ValueTypes["AdvertisesDocAccessFields_type_Update"],
	delete?:ValueTypes["AdvertisesDocAccessFields_type_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesDocAccessFields_type_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesDocAccessFields_type_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesDocAccessFields_type_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesDocAccessFields_type_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesDocAccessFields_invoiceDescription"]: AliasType<{
	create?:ValueTypes["AdvertisesDocAccessFields_invoiceDescription_Create"],
	read?:ValueTypes["AdvertisesDocAccessFields_invoiceDescription_Read"],
	update?:ValueTypes["AdvertisesDocAccessFields_invoiceDescription_Update"],
	delete?:ValueTypes["AdvertisesDocAccessFields_invoiceDescription_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesDocAccessFields_invoiceDescription_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesDocAccessFields_invoiceDescription_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesDocAccessFields_invoiceDescription_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesDocAccessFields_invoiceDescription_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesDocAccessFields_standardPrice"]: AliasType<{
	create?:ValueTypes["AdvertisesDocAccessFields_standardPrice_Create"],
	read?:ValueTypes["AdvertisesDocAccessFields_standardPrice_Read"],
	update?:ValueTypes["AdvertisesDocAccessFields_standardPrice_Update"],
	delete?:ValueTypes["AdvertisesDocAccessFields_standardPrice_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesDocAccessFields_standardPrice_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesDocAccessFields_standardPrice_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesDocAccessFields_standardPrice_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesDocAccessFields_standardPrice_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesDocAccessFields_discount"]: AliasType<{
	create?:ValueTypes["AdvertisesDocAccessFields_discount_Create"],
	read?:ValueTypes["AdvertisesDocAccessFields_discount_Read"],
	update?:ValueTypes["AdvertisesDocAccessFields_discount_Update"],
	delete?:ValueTypes["AdvertisesDocAccessFields_discount_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesDocAccessFields_discount_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesDocAccessFields_discount_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesDocAccessFields_discount_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesDocAccessFields_discount_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesDocAccessFields_discountPercentage"]: AliasType<{
	create?:ValueTypes["AdvertisesDocAccessFields_discountPercentage_Create"],
	read?:ValueTypes["AdvertisesDocAccessFields_discountPercentage_Read"],
	update?:ValueTypes["AdvertisesDocAccessFields_discountPercentage_Update"],
	delete?:ValueTypes["AdvertisesDocAccessFields_discountPercentage_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesDocAccessFields_discountPercentage_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesDocAccessFields_discountPercentage_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesDocAccessFields_discountPercentage_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesDocAccessFields_discountPercentage_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesDocAccessFields__calculateByDiscount"]: AliasType<{
	create?:ValueTypes["AdvertisesDocAccessFields__calculateByDiscount_Create"],
	read?:ValueTypes["AdvertisesDocAccessFields__calculateByDiscount_Read"],
	update?:ValueTypes["AdvertisesDocAccessFields__calculateByDiscount_Update"],
	delete?:ValueTypes["AdvertisesDocAccessFields__calculateByDiscount_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesDocAccessFields__calculateByDiscount_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesDocAccessFields__calculateByDiscount_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesDocAccessFields__calculateByDiscount_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesDocAccessFields__calculateByDiscount_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesDocAccessFields_timableCost"]: AliasType<{
	create?:ValueTypes["AdvertisesDocAccessFields_timableCost_Create"],
	read?:ValueTypes["AdvertisesDocAccessFields_timableCost_Read"],
	update?:ValueTypes["AdvertisesDocAccessFields_timableCost_Update"],
	delete?:ValueTypes["AdvertisesDocAccessFields_timableCost_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesDocAccessFields_timableCost_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesDocAccessFields_timableCost_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesDocAccessFields_timableCost_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesDocAccessFields_timableCost_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesDocAccessFields_remark"]: AliasType<{
	create?:ValueTypes["AdvertisesDocAccessFields_remark_Create"],
	read?:ValueTypes["AdvertisesDocAccessFields_remark_Read"],
	update?:ValueTypes["AdvertisesDocAccessFields_remark_Update"],
	delete?:ValueTypes["AdvertisesDocAccessFields_remark_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesDocAccessFields_remark_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesDocAccessFields_remark_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesDocAccessFields_remark_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesDocAccessFields_remark_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesDocAccessFields_public"]: AliasType<{
	create?:ValueTypes["AdvertisesDocAccessFields_public_Create"],
	read?:ValueTypes["AdvertisesDocAccessFields_public_Read"],
	update?:ValueTypes["AdvertisesDocAccessFields_public_Update"],
	delete?:ValueTypes["AdvertisesDocAccessFields_public_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesDocAccessFields_public_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesDocAccessFields_public_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesDocAccessFields_public_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesDocAccessFields_public_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesDocAccessFields_campaignName"]: AliasType<{
	create?:ValueTypes["AdvertisesDocAccessFields_campaignName_Create"],
	read?:ValueTypes["AdvertisesDocAccessFields_campaignName_Read"],
	update?:ValueTypes["AdvertisesDocAccessFields_campaignName_Update"],
	delete?:ValueTypes["AdvertisesDocAccessFields_campaignName_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesDocAccessFields_campaignName_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesDocAccessFields_campaignName_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesDocAccessFields_campaignName_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesDocAccessFields_campaignName_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesDocAccessFields_campaignId"]: AliasType<{
	create?:ValueTypes["AdvertisesDocAccessFields_campaignId_Create"],
	read?:ValueTypes["AdvertisesDocAccessFields_campaignId_Read"],
	update?:ValueTypes["AdvertisesDocAccessFields_campaignId_Update"],
	delete?:ValueTypes["AdvertisesDocAccessFields_campaignId_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesDocAccessFields_campaignId_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesDocAccessFields_campaignId_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesDocAccessFields_campaignId_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesDocAccessFields_campaignId_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesDocAccessFields_status"]: AliasType<{
	create?:ValueTypes["AdvertisesDocAccessFields_status_Create"],
	read?:ValueTypes["AdvertisesDocAccessFields_status_Read"],
	update?:ValueTypes["AdvertisesDocAccessFields_status_Update"],
	delete?:ValueTypes["AdvertisesDocAccessFields_status_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesDocAccessFields_status_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesDocAccessFields_status_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesDocAccessFields_status_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesDocAccessFields_status_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesDocAccessFields_updatedAt"]: AliasType<{
	create?:ValueTypes["AdvertisesDocAccessFields_updatedAt_Create"],
	read?:ValueTypes["AdvertisesDocAccessFields_updatedAt_Read"],
	update?:ValueTypes["AdvertisesDocAccessFields_updatedAt_Update"],
	delete?:ValueTypes["AdvertisesDocAccessFields_updatedAt_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesDocAccessFields_updatedAt_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesDocAccessFields_updatedAt_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesDocAccessFields_updatedAt_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesDocAccessFields_updatedAt_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesDocAccessFields_createdAt"]: AliasType<{
	create?:ValueTypes["AdvertisesDocAccessFields_createdAt_Create"],
	read?:ValueTypes["AdvertisesDocAccessFields_createdAt_Read"],
	update?:ValueTypes["AdvertisesDocAccessFields_createdAt_Update"],
	delete?:ValueTypes["AdvertisesDocAccessFields_createdAt_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesDocAccessFields_createdAt_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesDocAccessFields_createdAt_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesDocAccessFields_createdAt_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesDocAccessFields_createdAt_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesCreateDocAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesReadDocAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesUpdateDocAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesDeleteDocAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["Static"]: AliasType<{
	id?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	key?:boolean | `@${string}`,
	content?:ValueTypes["Static_Content"],
	region?:boolean | `@${string}`,
	updatedAt?:boolean | `@${string}`,
	createdAt?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["Static_Content"]: AliasType<{		["...on RichText"]?: ValueTypes["RichText"]
		__typename?: boolean | `@${string}`
}>;
	["Static_region"]:Static_region;
	["Statics"]: AliasType<{
	docs?:ValueTypes["Static"],
	hasNextPage?:boolean | `@${string}`,
	hasPrevPage?:boolean | `@${string}`,
	limit?:boolean | `@${string}`,
	nextPage?:boolean | `@${string}`,
	offset?:boolean | `@${string}`,
	page?:boolean | `@${string}`,
	pagingCounter?:boolean | `@${string}`,
	prevPage?:boolean | `@${string}`,
	totalDocs?:boolean | `@${string}`,
	totalPages?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["Static_where"]: {
	name?: ValueTypes["Static_name_operator"] | undefined | null | Variable<any, string>,
	key?: ValueTypes["Static_key_operator"] | undefined | null | Variable<any, string>,
	region?: ValueTypes["Static_region_operator"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["Static_updatedAt_operator"] | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["Static_createdAt_operator"] | undefined | null | Variable<any, string>,
	id?: ValueTypes["Static_id_operator"] | undefined | null | Variable<any, string>,
	AND?: Array<ValueTypes["Static_where_and"] | undefined | null> | undefined | null | Variable<any, string>,
	OR?: Array<ValueTypes["Static_where_or"] | undefined | null> | undefined | null | Variable<any, string>
};
	["Static_name_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Static_key_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Static_region_operator"]: {
	equals?: ValueTypes["Static_region_Input"] | undefined | null | Variable<any, string>,
	not_equals?: ValueTypes["Static_region_Input"] | undefined | null | Variable<any, string>,
	in?: Array<ValueTypes["Static_region_Input"] | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<ValueTypes["Static_region_Input"] | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<ValueTypes["Static_region_Input"] | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Static_region_Input"]:Static_region_Input;
	["Static_updatedAt_operator"]: {
	equals?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	not_equals?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	greater_than_equal?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	greater_than?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	less_than_equal?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	less_than?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	like?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Static_createdAt_operator"]: {
	equals?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	not_equals?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	greater_than_equal?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	greater_than?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	less_than_equal?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	less_than?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	like?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Static_id_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["Static_where_and"]: {
	name?: ValueTypes["Static_name_operator"] | undefined | null | Variable<any, string>,
	key?: ValueTypes["Static_key_operator"] | undefined | null | Variable<any, string>,
	region?: ValueTypes["Static_region_operator"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["Static_updatedAt_operator"] | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["Static_createdAt_operator"] | undefined | null | Variable<any, string>,
	id?: ValueTypes["Static_id_operator"] | undefined | null | Variable<any, string>,
	AND?: Array<ValueTypes["Static_where_and"] | undefined | null> | undefined | null | Variable<any, string>,
	OR?: Array<ValueTypes["Static_where_or"] | undefined | null> | undefined | null | Variable<any, string>
};
	["Static_where_or"]: {
	name?: ValueTypes["Static_name_operator"] | undefined | null | Variable<any, string>,
	key?: ValueTypes["Static_key_operator"] | undefined | null | Variable<any, string>,
	region?: ValueTypes["Static_region_operator"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["Static_updatedAt_operator"] | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["Static_createdAt_operator"] | undefined | null | Variable<any, string>,
	id?: ValueTypes["Static_id_operator"] | undefined | null | Variable<any, string>,
	AND?: Array<ValueTypes["Static_where_and"] | undefined | null> | undefined | null | Variable<any, string>,
	OR?: Array<ValueTypes["Static_where_or"] | undefined | null> | undefined | null | Variable<any, string>
};
	["countStatics"]: AliasType<{
	totalDocs?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["staticsDocAccess"]: AliasType<{
	fields?:ValueTypes["StaticsDocAccessFields"],
	create?:ValueTypes["StaticsCreateDocAccess"],
	read?:ValueTypes["StaticsReadDocAccess"],
	update?:ValueTypes["StaticsUpdateDocAccess"],
	delete?:ValueTypes["StaticsDeleteDocAccess"],
		__typename?: boolean | `@${string}`
}>;
	["StaticsDocAccessFields"]: AliasType<{
	name?:ValueTypes["StaticsDocAccessFields_name"],
	key?:ValueTypes["StaticsDocAccessFields_key"],
	content?:ValueTypes["StaticsDocAccessFields_content"],
	region?:ValueTypes["StaticsDocAccessFields_region"],
	updatedAt?:ValueTypes["StaticsDocAccessFields_updatedAt"],
	createdAt?:ValueTypes["StaticsDocAccessFields_createdAt"],
		__typename?: boolean | `@${string}`
}>;
	["StaticsDocAccessFields_name"]: AliasType<{
	create?:ValueTypes["StaticsDocAccessFields_name_Create"],
	read?:ValueTypes["StaticsDocAccessFields_name_Read"],
	update?:ValueTypes["StaticsDocAccessFields_name_Update"],
	delete?:ValueTypes["StaticsDocAccessFields_name_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["StaticsDocAccessFields_name_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["StaticsDocAccessFields_name_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["StaticsDocAccessFields_name_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["StaticsDocAccessFields_name_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["StaticsDocAccessFields_key"]: AliasType<{
	create?:ValueTypes["StaticsDocAccessFields_key_Create"],
	read?:ValueTypes["StaticsDocAccessFields_key_Read"],
	update?:ValueTypes["StaticsDocAccessFields_key_Update"],
	delete?:ValueTypes["StaticsDocAccessFields_key_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["StaticsDocAccessFields_key_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["StaticsDocAccessFields_key_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["StaticsDocAccessFields_key_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["StaticsDocAccessFields_key_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["StaticsDocAccessFields_content"]: AliasType<{
	create?:ValueTypes["StaticsDocAccessFields_content_Create"],
	read?:ValueTypes["StaticsDocAccessFields_content_Read"],
	update?:ValueTypes["StaticsDocAccessFields_content_Update"],
	delete?:ValueTypes["StaticsDocAccessFields_content_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["StaticsDocAccessFields_content_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["StaticsDocAccessFields_content_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["StaticsDocAccessFields_content_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["StaticsDocAccessFields_content_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["StaticsDocAccessFields_region"]: AliasType<{
	create?:ValueTypes["StaticsDocAccessFields_region_Create"],
	read?:ValueTypes["StaticsDocAccessFields_region_Read"],
	update?:ValueTypes["StaticsDocAccessFields_region_Update"],
	delete?:ValueTypes["StaticsDocAccessFields_region_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["StaticsDocAccessFields_region_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["StaticsDocAccessFields_region_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["StaticsDocAccessFields_region_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["StaticsDocAccessFields_region_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["StaticsDocAccessFields_updatedAt"]: AliasType<{
	create?:ValueTypes["StaticsDocAccessFields_updatedAt_Create"],
	read?:ValueTypes["StaticsDocAccessFields_updatedAt_Read"],
	update?:ValueTypes["StaticsDocAccessFields_updatedAt_Update"],
	delete?:ValueTypes["StaticsDocAccessFields_updatedAt_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["StaticsDocAccessFields_updatedAt_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["StaticsDocAccessFields_updatedAt_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["StaticsDocAccessFields_updatedAt_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["StaticsDocAccessFields_updatedAt_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["StaticsDocAccessFields_createdAt"]: AliasType<{
	create?:ValueTypes["StaticsDocAccessFields_createdAt_Create"],
	read?:ValueTypes["StaticsDocAccessFields_createdAt_Read"],
	update?:ValueTypes["StaticsDocAccessFields_createdAt_Update"],
	delete?:ValueTypes["StaticsDocAccessFields_createdAt_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["StaticsDocAccessFields_createdAt_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["StaticsDocAccessFields_createdAt_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["StaticsDocAccessFields_createdAt_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["StaticsDocAccessFields_createdAt_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["StaticsCreateDocAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["StaticsReadDocAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["StaticsUpdateDocAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["StaticsDeleteDocAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventView"]: AliasType<{
	id?:boolean | `@${string}`,
	date?:boolean | `@${string}`,
eventId?: [{	locale?: ValueTypes["LocaleInputType"] | undefined | null | Variable<any, string>,	fallbackLocale?: ValueTypes["FallbackLocaleInputType"] | undefined | null | Variable<any, string>},ValueTypes["Event"]],
	viewCount?:boolean | `@${string}`,
	updatedAt?:boolean | `@${string}`,
	createdAt?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventViews"]: AliasType<{
	docs?:ValueTypes["EventView"],
	hasNextPage?:boolean | `@${string}`,
	hasPrevPage?:boolean | `@${string}`,
	limit?:boolean | `@${string}`,
	nextPage?:boolean | `@${string}`,
	offset?:boolean | `@${string}`,
	page?:boolean | `@${string}`,
	pagingCounter?:boolean | `@${string}`,
	prevPage?:boolean | `@${string}`,
	totalDocs?:boolean | `@${string}`,
	totalPages?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventView_where"]: {
	date?: ValueTypes["EventView_date_operator"] | undefined | null | Variable<any, string>,
	eventId?: ValueTypes["EventView_eventId_operator"] | undefined | null | Variable<any, string>,
	viewCount?: ValueTypes["EventView_viewCount_operator"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["EventView_updatedAt_operator"] | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["EventView_createdAt_operator"] | undefined | null | Variable<any, string>,
	id?: ValueTypes["EventView_id_operator"] | undefined | null | Variable<any, string>,
	AND?: Array<ValueTypes["EventView_where_and"] | undefined | null> | undefined | null | Variable<any, string>,
	OR?: Array<ValueTypes["EventView_where_or"] | undefined | null> | undefined | null | Variable<any, string>
};
	["EventView_date_operator"]: {
	equals?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	not_equals?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	greater_than_equal?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	greater_than?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	less_than_equal?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	less_than?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	like?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>
};
	["EventView_eventId_operator"]: {
	equals?: ValueTypes["JSON"] | undefined | null | Variable<any, string>,
	not_equals?: ValueTypes["JSON"] | undefined | null | Variable<any, string>,
	in?: Array<ValueTypes["JSON"] | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<ValueTypes["JSON"] | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<ValueTypes["JSON"] | undefined | null> | undefined | null | Variable<any, string>
};
	["EventView_viewCount_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>
};
	["EventView_updatedAt_operator"]: {
	equals?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	not_equals?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	greater_than_equal?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	greater_than?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	less_than_equal?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	less_than?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	like?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["EventView_createdAt_operator"]: {
	equals?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	not_equals?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	greater_than_equal?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	greater_than?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	less_than_equal?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	less_than?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	like?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["EventView_id_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["EventView_where_and"]: {
	date?: ValueTypes["EventView_date_operator"] | undefined | null | Variable<any, string>,
	eventId?: ValueTypes["EventView_eventId_operator"] | undefined | null | Variable<any, string>,
	viewCount?: ValueTypes["EventView_viewCount_operator"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["EventView_updatedAt_operator"] | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["EventView_createdAt_operator"] | undefined | null | Variable<any, string>,
	id?: ValueTypes["EventView_id_operator"] | undefined | null | Variable<any, string>,
	AND?: Array<ValueTypes["EventView_where_and"] | undefined | null> | undefined | null | Variable<any, string>,
	OR?: Array<ValueTypes["EventView_where_or"] | undefined | null> | undefined | null | Variable<any, string>
};
	["EventView_where_or"]: {
	date?: ValueTypes["EventView_date_operator"] | undefined | null | Variable<any, string>,
	eventId?: ValueTypes["EventView_eventId_operator"] | undefined | null | Variable<any, string>,
	viewCount?: ValueTypes["EventView_viewCount_operator"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["EventView_updatedAt_operator"] | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["EventView_createdAt_operator"] | undefined | null | Variable<any, string>,
	id?: ValueTypes["EventView_id_operator"] | undefined | null | Variable<any, string>,
	AND?: Array<ValueTypes["EventView_where_and"] | undefined | null> | undefined | null | Variable<any, string>,
	OR?: Array<ValueTypes["EventView_where_or"] | undefined | null> | undefined | null | Variable<any, string>
};
	["countEventViews"]: AliasType<{
	totalDocs?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["event_viewsDocAccess"]: AliasType<{
	fields?:ValueTypes["EventViewsDocAccessFields"],
	create?:ValueTypes["EventViewsCreateDocAccess"],
	read?:ValueTypes["EventViewsReadDocAccess"],
	update?:ValueTypes["EventViewsUpdateDocAccess"],
	delete?:ValueTypes["EventViewsDeleteDocAccess"],
		__typename?: boolean | `@${string}`
}>;
	["EventViewsDocAccessFields"]: AliasType<{
	date?:ValueTypes["EventViewsDocAccessFields_date"],
	eventId?:ValueTypes["EventViewsDocAccessFields_eventId"],
	viewCount?:ValueTypes["EventViewsDocAccessFields_viewCount"],
	updatedAt?:ValueTypes["EventViewsDocAccessFields_updatedAt"],
	createdAt?:ValueTypes["EventViewsDocAccessFields_createdAt"],
		__typename?: boolean | `@${string}`
}>;
	["EventViewsDocAccessFields_date"]: AliasType<{
	create?:ValueTypes["EventViewsDocAccessFields_date_Create"],
	read?:ValueTypes["EventViewsDocAccessFields_date_Read"],
	update?:ValueTypes["EventViewsDocAccessFields_date_Update"],
	delete?:ValueTypes["EventViewsDocAccessFields_date_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventViewsDocAccessFields_date_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventViewsDocAccessFields_date_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventViewsDocAccessFields_date_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventViewsDocAccessFields_date_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventViewsDocAccessFields_eventId"]: AliasType<{
	create?:ValueTypes["EventViewsDocAccessFields_eventId_Create"],
	read?:ValueTypes["EventViewsDocAccessFields_eventId_Read"],
	update?:ValueTypes["EventViewsDocAccessFields_eventId_Update"],
	delete?:ValueTypes["EventViewsDocAccessFields_eventId_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventViewsDocAccessFields_eventId_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventViewsDocAccessFields_eventId_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventViewsDocAccessFields_eventId_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventViewsDocAccessFields_eventId_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventViewsDocAccessFields_viewCount"]: AliasType<{
	create?:ValueTypes["EventViewsDocAccessFields_viewCount_Create"],
	read?:ValueTypes["EventViewsDocAccessFields_viewCount_Read"],
	update?:ValueTypes["EventViewsDocAccessFields_viewCount_Update"],
	delete?:ValueTypes["EventViewsDocAccessFields_viewCount_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventViewsDocAccessFields_viewCount_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventViewsDocAccessFields_viewCount_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventViewsDocAccessFields_viewCount_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventViewsDocAccessFields_viewCount_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventViewsDocAccessFields_updatedAt"]: AliasType<{
	create?:ValueTypes["EventViewsDocAccessFields_updatedAt_Create"],
	read?:ValueTypes["EventViewsDocAccessFields_updatedAt_Read"],
	update?:ValueTypes["EventViewsDocAccessFields_updatedAt_Update"],
	delete?:ValueTypes["EventViewsDocAccessFields_updatedAt_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventViewsDocAccessFields_updatedAt_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventViewsDocAccessFields_updatedAt_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventViewsDocAccessFields_updatedAt_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventViewsDocAccessFields_updatedAt_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventViewsDocAccessFields_createdAt"]: AliasType<{
	create?:ValueTypes["EventViewsDocAccessFields_createdAt_Create"],
	read?:ValueTypes["EventViewsDocAccessFields_createdAt_Read"],
	update?:ValueTypes["EventViewsDocAccessFields_createdAt_Update"],
	delete?:ValueTypes["EventViewsDocAccessFields_createdAt_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventViewsDocAccessFields_createdAt_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventViewsDocAccessFields_createdAt_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventViewsDocAccessFields_createdAt_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventViewsDocAccessFields_createdAt_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventViewsCreateDocAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventViewsReadDocAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventViewsUpdateDocAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventViewsDeleteDocAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostView"]: AliasType<{
	id?:boolean | `@${string}`,
	date?:boolean | `@${string}`,
postId?: [{	locale?: ValueTypes["LocaleInputType"] | undefined | null | Variable<any, string>,	fallbackLocale?: ValueTypes["FallbackLocaleInputType"] | undefined | null | Variable<any, string>},ValueTypes["Post"]],
	viewCount?:boolean | `@${string}`,
	updatedAt?:boolean | `@${string}`,
	createdAt?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostViews"]: AliasType<{
	docs?:ValueTypes["PostView"],
	hasNextPage?:boolean | `@${string}`,
	hasPrevPage?:boolean | `@${string}`,
	limit?:boolean | `@${string}`,
	nextPage?:boolean | `@${string}`,
	offset?:boolean | `@${string}`,
	page?:boolean | `@${string}`,
	pagingCounter?:boolean | `@${string}`,
	prevPage?:boolean | `@${string}`,
	totalDocs?:boolean | `@${string}`,
	totalPages?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostView_where"]: {
	date?: ValueTypes["PostView_date_operator"] | undefined | null | Variable<any, string>,
	postId?: ValueTypes["PostView_postId_operator"] | undefined | null | Variable<any, string>,
	viewCount?: ValueTypes["PostView_viewCount_operator"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["PostView_updatedAt_operator"] | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["PostView_createdAt_operator"] | undefined | null | Variable<any, string>,
	id?: ValueTypes["PostView_id_operator"] | undefined | null | Variable<any, string>,
	AND?: Array<ValueTypes["PostView_where_and"] | undefined | null> | undefined | null | Variable<any, string>,
	OR?: Array<ValueTypes["PostView_where_or"] | undefined | null> | undefined | null | Variable<any, string>
};
	["PostView_date_operator"]: {
	equals?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	not_equals?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	greater_than_equal?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	greater_than?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	less_than_equal?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	less_than?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	like?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>
};
	["PostView_postId_operator"]: {
	equals?: ValueTypes["JSON"] | undefined | null | Variable<any, string>,
	not_equals?: ValueTypes["JSON"] | undefined | null | Variable<any, string>,
	in?: Array<ValueTypes["JSON"] | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<ValueTypes["JSON"] | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<ValueTypes["JSON"] | undefined | null> | undefined | null | Variable<any, string>
};
	["PostView_viewCount_operator"]: {
	equals?: number | undefined | null | Variable<any, string>,
	not_equals?: number | undefined | null | Variable<any, string>,
	greater_than_equal?: number | undefined | null | Variable<any, string>,
	greater_than?: number | undefined | null | Variable<any, string>,
	less_than_equal?: number | undefined | null | Variable<any, string>,
	less_than?: number | undefined | null | Variable<any, string>
};
	["PostView_updatedAt_operator"]: {
	equals?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	not_equals?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	greater_than_equal?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	greater_than?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	less_than_equal?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	less_than?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	like?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["PostView_createdAt_operator"]: {
	equals?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	not_equals?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	greater_than_equal?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	greater_than?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	less_than_equal?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	less_than?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	like?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["PostView_id_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["PostView_where_and"]: {
	date?: ValueTypes["PostView_date_operator"] | undefined | null | Variable<any, string>,
	postId?: ValueTypes["PostView_postId_operator"] | undefined | null | Variable<any, string>,
	viewCount?: ValueTypes["PostView_viewCount_operator"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["PostView_updatedAt_operator"] | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["PostView_createdAt_operator"] | undefined | null | Variable<any, string>,
	id?: ValueTypes["PostView_id_operator"] | undefined | null | Variable<any, string>,
	AND?: Array<ValueTypes["PostView_where_and"] | undefined | null> | undefined | null | Variable<any, string>,
	OR?: Array<ValueTypes["PostView_where_or"] | undefined | null> | undefined | null | Variable<any, string>
};
	["PostView_where_or"]: {
	date?: ValueTypes["PostView_date_operator"] | undefined | null | Variable<any, string>,
	postId?: ValueTypes["PostView_postId_operator"] | undefined | null | Variable<any, string>,
	viewCount?: ValueTypes["PostView_viewCount_operator"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["PostView_updatedAt_operator"] | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["PostView_createdAt_operator"] | undefined | null | Variable<any, string>,
	id?: ValueTypes["PostView_id_operator"] | undefined | null | Variable<any, string>,
	AND?: Array<ValueTypes["PostView_where_and"] | undefined | null> | undefined | null | Variable<any, string>,
	OR?: Array<ValueTypes["PostView_where_or"] | undefined | null> | undefined | null | Variable<any, string>
};
	["countPostViews"]: AliasType<{
	totalDocs?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["post_viewsDocAccess"]: AliasType<{
	fields?:ValueTypes["PostViewsDocAccessFields"],
	create?:ValueTypes["PostViewsCreateDocAccess"],
	read?:ValueTypes["PostViewsReadDocAccess"],
	update?:ValueTypes["PostViewsUpdateDocAccess"],
	delete?:ValueTypes["PostViewsDeleteDocAccess"],
		__typename?: boolean | `@${string}`
}>;
	["PostViewsDocAccessFields"]: AliasType<{
	date?:ValueTypes["PostViewsDocAccessFields_date"],
	postId?:ValueTypes["PostViewsDocAccessFields_postId"],
	viewCount?:ValueTypes["PostViewsDocAccessFields_viewCount"],
	updatedAt?:ValueTypes["PostViewsDocAccessFields_updatedAt"],
	createdAt?:ValueTypes["PostViewsDocAccessFields_createdAt"],
		__typename?: boolean | `@${string}`
}>;
	["PostViewsDocAccessFields_date"]: AliasType<{
	create?:ValueTypes["PostViewsDocAccessFields_date_Create"],
	read?:ValueTypes["PostViewsDocAccessFields_date_Read"],
	update?:ValueTypes["PostViewsDocAccessFields_date_Update"],
	delete?:ValueTypes["PostViewsDocAccessFields_date_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["PostViewsDocAccessFields_date_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostViewsDocAccessFields_date_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostViewsDocAccessFields_date_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostViewsDocAccessFields_date_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostViewsDocAccessFields_postId"]: AliasType<{
	create?:ValueTypes["PostViewsDocAccessFields_postId_Create"],
	read?:ValueTypes["PostViewsDocAccessFields_postId_Read"],
	update?:ValueTypes["PostViewsDocAccessFields_postId_Update"],
	delete?:ValueTypes["PostViewsDocAccessFields_postId_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["PostViewsDocAccessFields_postId_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostViewsDocAccessFields_postId_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostViewsDocAccessFields_postId_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostViewsDocAccessFields_postId_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostViewsDocAccessFields_viewCount"]: AliasType<{
	create?:ValueTypes["PostViewsDocAccessFields_viewCount_Create"],
	read?:ValueTypes["PostViewsDocAccessFields_viewCount_Read"],
	update?:ValueTypes["PostViewsDocAccessFields_viewCount_Update"],
	delete?:ValueTypes["PostViewsDocAccessFields_viewCount_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["PostViewsDocAccessFields_viewCount_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostViewsDocAccessFields_viewCount_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostViewsDocAccessFields_viewCount_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostViewsDocAccessFields_viewCount_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostViewsDocAccessFields_updatedAt"]: AliasType<{
	create?:ValueTypes["PostViewsDocAccessFields_updatedAt_Create"],
	read?:ValueTypes["PostViewsDocAccessFields_updatedAt_Read"],
	update?:ValueTypes["PostViewsDocAccessFields_updatedAt_Update"],
	delete?:ValueTypes["PostViewsDocAccessFields_updatedAt_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["PostViewsDocAccessFields_updatedAt_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostViewsDocAccessFields_updatedAt_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostViewsDocAccessFields_updatedAt_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostViewsDocAccessFields_updatedAt_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostViewsDocAccessFields_createdAt"]: AliasType<{
	create?:ValueTypes["PostViewsDocAccessFields_createdAt_Create"],
	read?:ValueTypes["PostViewsDocAccessFields_createdAt_Read"],
	update?:ValueTypes["PostViewsDocAccessFields_createdAt_Update"],
	delete?:ValueTypes["PostViewsDocAccessFields_createdAt_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["PostViewsDocAccessFields_createdAt_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostViewsDocAccessFields_createdAt_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostViewsDocAccessFields_createdAt_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostViewsDocAccessFields_createdAt_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostViewsCreateDocAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostViewsReadDocAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostViewsUpdateDocAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostViewsDeleteDocAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PayloadPreference"]: AliasType<{
	id?:boolean | `@${string}`,
user?: [{	locale?: ValueTypes["LocaleInputType"] | undefined | null | Variable<any, string>,	fallbackLocale?: ValueTypes["FallbackLocaleInputType"] | undefined | null | Variable<any, string>},ValueTypes["PayloadPreference_User_Relationship"]],
	key?:boolean | `@${string}`,
	value?:boolean | `@${string}`,
	updatedAt?:boolean | `@${string}`,
	createdAt?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PayloadPreference_User_Relationship"]: AliasType<{
	relationTo?:boolean | `@${string}`,
	value?:ValueTypes["PayloadPreference_User"],
		__typename?: boolean | `@${string}`
}>;
	["PayloadPreference_User_RelationTo"]:PayloadPreference_User_RelationTo;
	["PayloadPreference_User"]: AliasType<{		["...on User"]?: ValueTypes["User"]
		__typename?: boolean | `@${string}`
}>;
	["PayloadPreferences"]: AliasType<{
	docs?:ValueTypes["PayloadPreference"],
	hasNextPage?:boolean | `@${string}`,
	hasPrevPage?:boolean | `@${string}`,
	limit?:boolean | `@${string}`,
	nextPage?:boolean | `@${string}`,
	offset?:boolean | `@${string}`,
	page?:boolean | `@${string}`,
	pagingCounter?:boolean | `@${string}`,
	prevPage?:boolean | `@${string}`,
	totalDocs?:boolean | `@${string}`,
	totalPages?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PayloadPreference_where"]: {
	user?: ValueTypes["PayloadPreference_user_Relation"] | undefined | null | Variable<any, string>,
	key?: ValueTypes["PayloadPreference_key_operator"] | undefined | null | Variable<any, string>,
	value?: ValueTypes["PayloadPreference_value_operator"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["PayloadPreference_updatedAt_operator"] | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["PayloadPreference_createdAt_operator"] | undefined | null | Variable<any, string>,
	id?: ValueTypes["PayloadPreference_id_operator"] | undefined | null | Variable<any, string>,
	AND?: Array<ValueTypes["PayloadPreference_where_and"] | undefined | null> | undefined | null | Variable<any, string>,
	OR?: Array<ValueTypes["PayloadPreference_where_or"] | undefined | null> | undefined | null | Variable<any, string>
};
	["PayloadPreference_user_Relation"]: {
	relationTo?: ValueTypes["PayloadPreference_user_Relation_RelationTo"] | undefined | null | Variable<any, string>,
	value?: ValueTypes["JSON"] | undefined | null | Variable<any, string>
};
	["PayloadPreference_user_Relation_RelationTo"]:PayloadPreference_user_Relation_RelationTo;
	["PayloadPreference_key_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["PayloadPreference_value_operator"]: {
	equals?: ValueTypes["JSON"] | undefined | null | Variable<any, string>,
	not_equals?: ValueTypes["JSON"] | undefined | null | Variable<any, string>,
	like?: ValueTypes["JSON"] | undefined | null | Variable<any, string>,
	contains?: ValueTypes["JSON"] | undefined | null | Variable<any, string>,
	within?: ValueTypes["JSON"] | undefined | null | Variable<any, string>,
	intersects?: ValueTypes["JSON"] | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["PayloadPreference_updatedAt_operator"]: {
	equals?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	not_equals?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	greater_than_equal?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	greater_than?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	less_than_equal?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	less_than?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	like?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["PayloadPreference_createdAt_operator"]: {
	equals?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	not_equals?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	greater_than_equal?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	greater_than?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	less_than_equal?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	less_than?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	like?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["PayloadPreference_id_operator"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not_equals?: string | undefined | null | Variable<any, string>,
	like?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	not_in?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	all?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	exists?: boolean | undefined | null | Variable<any, string>
};
	["PayloadPreference_where_and"]: {
	user?: ValueTypes["PayloadPreference_user_Relation"] | undefined | null | Variable<any, string>,
	key?: ValueTypes["PayloadPreference_key_operator"] | undefined | null | Variable<any, string>,
	value?: ValueTypes["PayloadPreference_value_operator"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["PayloadPreference_updatedAt_operator"] | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["PayloadPreference_createdAt_operator"] | undefined | null | Variable<any, string>,
	id?: ValueTypes["PayloadPreference_id_operator"] | undefined | null | Variable<any, string>,
	AND?: Array<ValueTypes["PayloadPreference_where_and"] | undefined | null> | undefined | null | Variable<any, string>,
	OR?: Array<ValueTypes["PayloadPreference_where_or"] | undefined | null> | undefined | null | Variable<any, string>
};
	["PayloadPreference_where_or"]: {
	user?: ValueTypes["PayloadPreference_user_Relation"] | undefined | null | Variable<any, string>,
	key?: ValueTypes["PayloadPreference_key_operator"] | undefined | null | Variable<any, string>,
	value?: ValueTypes["PayloadPreference_value_operator"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["PayloadPreference_updatedAt_operator"] | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["PayloadPreference_createdAt_operator"] | undefined | null | Variable<any, string>,
	id?: ValueTypes["PayloadPreference_id_operator"] | undefined | null | Variable<any, string>,
	AND?: Array<ValueTypes["PayloadPreference_where_and"] | undefined | null> | undefined | null | Variable<any, string>,
	OR?: Array<ValueTypes["PayloadPreference_where_or"] | undefined | null> | undefined | null | Variable<any, string>
};
	["countPayloadPreferences"]: AliasType<{
	totalDocs?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["payload_preferencesDocAccess"]: AliasType<{
	fields?:ValueTypes["PayloadPreferencesDocAccessFields"],
	create?:ValueTypes["PayloadPreferencesCreateDocAccess"],
	read?:ValueTypes["PayloadPreferencesReadDocAccess"],
	update?:ValueTypes["PayloadPreferencesUpdateDocAccess"],
	delete?:ValueTypes["PayloadPreferencesDeleteDocAccess"],
		__typename?: boolean | `@${string}`
}>;
	["PayloadPreferencesDocAccessFields"]: AliasType<{
	user?:ValueTypes["PayloadPreferencesDocAccessFields_user"],
	key?:ValueTypes["PayloadPreferencesDocAccessFields_key"],
	value?:ValueTypes["PayloadPreferencesDocAccessFields_value"],
	updatedAt?:ValueTypes["PayloadPreferencesDocAccessFields_updatedAt"],
	createdAt?:ValueTypes["PayloadPreferencesDocAccessFields_createdAt"],
		__typename?: boolean | `@${string}`
}>;
	["PayloadPreferencesDocAccessFields_user"]: AliasType<{
	create?:ValueTypes["PayloadPreferencesDocAccessFields_user_Create"],
	read?:ValueTypes["PayloadPreferencesDocAccessFields_user_Read"],
	update?:ValueTypes["PayloadPreferencesDocAccessFields_user_Update"],
	delete?:ValueTypes["PayloadPreferencesDocAccessFields_user_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["PayloadPreferencesDocAccessFields_user_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PayloadPreferencesDocAccessFields_user_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PayloadPreferencesDocAccessFields_user_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PayloadPreferencesDocAccessFields_user_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PayloadPreferencesDocAccessFields_key"]: AliasType<{
	create?:ValueTypes["PayloadPreferencesDocAccessFields_key_Create"],
	read?:ValueTypes["PayloadPreferencesDocAccessFields_key_Read"],
	update?:ValueTypes["PayloadPreferencesDocAccessFields_key_Update"],
	delete?:ValueTypes["PayloadPreferencesDocAccessFields_key_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["PayloadPreferencesDocAccessFields_key_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PayloadPreferencesDocAccessFields_key_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PayloadPreferencesDocAccessFields_key_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PayloadPreferencesDocAccessFields_key_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PayloadPreferencesDocAccessFields_value"]: AliasType<{
	create?:ValueTypes["PayloadPreferencesDocAccessFields_value_Create"],
	read?:ValueTypes["PayloadPreferencesDocAccessFields_value_Read"],
	update?:ValueTypes["PayloadPreferencesDocAccessFields_value_Update"],
	delete?:ValueTypes["PayloadPreferencesDocAccessFields_value_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["PayloadPreferencesDocAccessFields_value_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PayloadPreferencesDocAccessFields_value_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PayloadPreferencesDocAccessFields_value_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PayloadPreferencesDocAccessFields_value_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PayloadPreferencesDocAccessFields_updatedAt"]: AliasType<{
	create?:ValueTypes["PayloadPreferencesDocAccessFields_updatedAt_Create"],
	read?:ValueTypes["PayloadPreferencesDocAccessFields_updatedAt_Read"],
	update?:ValueTypes["PayloadPreferencesDocAccessFields_updatedAt_Update"],
	delete?:ValueTypes["PayloadPreferencesDocAccessFields_updatedAt_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["PayloadPreferencesDocAccessFields_updatedAt_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PayloadPreferencesDocAccessFields_updatedAt_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PayloadPreferencesDocAccessFields_updatedAt_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PayloadPreferencesDocAccessFields_updatedAt_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PayloadPreferencesDocAccessFields_createdAt"]: AliasType<{
	create?:ValueTypes["PayloadPreferencesDocAccessFields_createdAt_Create"],
	read?:ValueTypes["PayloadPreferencesDocAccessFields_createdAt_Read"],
	update?:ValueTypes["PayloadPreferencesDocAccessFields_createdAt_Update"],
	delete?:ValueTypes["PayloadPreferencesDocAccessFields_createdAt_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["PayloadPreferencesDocAccessFields_createdAt_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PayloadPreferencesDocAccessFields_createdAt_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PayloadPreferencesDocAccessFields_createdAt_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PayloadPreferencesDocAccessFields_createdAt_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PayloadPreferencesCreateDocAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PayloadPreferencesReadDocAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PayloadPreferencesUpdateDocAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PayloadPreferencesDeleteDocAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["Access"]: AliasType<{
	canAccessAdmin?:boolean | `@${string}`,
	events?:ValueTypes["eventsAccess"],
	locations?:ValueTypes["locationsAccess"],
	posts?:ValueTypes["postsAccess"],
	ticketing?:ValueTypes["ticketingAccess"],
	products?:ValueTypes["productsAccess"],
	inventory_status?:ValueTypes["inventory_statusAccess"],
	transactions?:ValueTypes["transactionsAccess"],
	redemption_codes?:ValueTypes["redemption_codesAccess"],
	redemption_pdf?:ValueTypes["redemption_pdfAccess"],
	check_in_redemption?:ValueTypes["check_in_redemptionAccess"],
	users?:ValueTypes["usersAccess"],
	bookmarks?:ValueTypes["bookmarksAccess"],
	webAppBookmarks?:ValueTypes["webAppBookmarksAccess"],
	view_records?:ValueTypes["view_recordsAccess"],
	comments?:ValueTypes["commentsAccess"],
	subscriptions?:ValueTypes["subscriptionsAccess"],
	newsletter?:ValueTypes["newsletterAccess"],
	blog_categories?:ValueTypes["blog_categoriesAccess"],
	tags?:ValueTypes["tagsAccess"],
	specialTags?:ValueTypes["specialTagsAccess"],
	date?:ValueTypes["dateAccess"],
	districts?:ValueTypes["districtsAccess"],
	audience?:ValueTypes["audienceAccess"],
	category?:ValueTypes["categoryAccess"],
	private_media?:ValueTypes["private_mediaAccess"],
	media?:ValueTypes["mediaAccess"],
	backupMedia?:ValueTypes["backupMediaAccess"],
	account_suspend_requests?:ValueTypes["account_suspend_requestsAccess"],
	devices?:ValueTypes["devicesAccess"],
	notifications?:ValueTypes["notificationsAccess"],
	redirectSetting?:ValueTypes["redirectSettingAccess"],
	ranking_events?:ValueTypes["ranking_eventsAccess"],
	ranking_locations?:ValueTypes["ranking_locationsAccess"],
	event_calendar?:ValueTypes["event_calendarAccess"],
	editor_choices?:ValueTypes["editor_choicesAccess"],
	criteria_setting?:ValueTypes["criteria_settingAccess"],
	organiserSetting?:ValueTypes["organiserSettingAccess"],
	post_setting?:ValueTypes["post_settingAccess"],
	homepageSetting?:ValueTypes["homepageSettingAccess"],
	banner_section?:ValueTypes["banner_sectionAccess"],
	macao_banner_section?:ValueTypes["macao_banner_sectionAccess"],
	banners?:ValueTypes["bannersAccess"],
	campaigns?:ValueTypes["campaignsAccess"],
	advertises?:ValueTypes["advertisesAccess"],
	statics?:ValueTypes["staticsAccess"],
	event_views?:ValueTypes["event_viewsAccess"],
	post_views?:ValueTypes["post_viewsAccess"],
	payload_preferences?:ValueTypes["payload_preferencesAccess"],
		__typename?: boolean | `@${string}`
}>;
	["eventsAccess"]: AliasType<{
	fields?:ValueTypes["EventsFields"],
	create?:ValueTypes["EventsCreateAccess"],
	read?:ValueTypes["EventsReadAccess"],
	update?:ValueTypes["EventsUpdateAccess"],
	delete?:ValueTypes["EventsDeleteAccess"],
		__typename?: boolean | `@${string}`
}>;
	["EventsFields"]: AliasType<{
	region?:ValueTypes["EventsFields_region"],
	creator?:ValueTypes["EventsFields_creator"],
	permalink?:ValueTypes["EventsFields_permalink"],
	status?:ValueTypes["EventsFields_status"],
	level?:ValueTypes["EventsFields_level"],
	parent?:ValueTypes["EventsFields_parent"],
	name?:ValueTypes["EventsFields_name"],
	chatbotEventTitle?:ValueTypes["EventsFields_chatbotEventTitle"],
	chatbotSequence?:ValueTypes["EventsFields_chatbotSequence"],
	thumbnail?:ValueTypes["EventsFields_thumbnail"],
	banner?:ValueTypes["EventsFields_banner"],
	mobileBanner?:ValueTypes["EventsFields_mobileBanner"],
	sections?:ValueTypes["EventsFields_sections"],
	ticketing?:ValueTypes["EventsFields_ticketing"],
	content?:ValueTypes["EventsFields_content"],
	legacyContent?:ValueTypes["EventsFields_legacyContent"],
	showLegacyContent?:ValueTypes["EventsFields_showLegacyContent"],
	criteria?:ValueTypes["EventsFields_criteria"],
	walkIn?:ValueTypes["EventsFields_walkIn"],
	contact?:ValueTypes["EventsFields_contact"],
	views?:ValueTypes["EventsFields_views"],
	free?:ValueTypes["EventsFields_free"],
	fees?:ValueTypes["EventsFields_fees"],
	homePageInternalClick?:ValueTypes["EventsFields_homePageInternalClick"],
	homePageImpression?:ValueTypes["EventsFields_homePageImpression"],
	eodClickRate?:ValueTypes["EventsFields_eodClickRate"],
	eodImpressionRate?:ValueTypes["EventsFields_eodImpressionRate"],
	top10ClickRateHomepage?:ValueTypes["EventsFields_top10ClickRateHomepage"],
	top10ImpressionkRateHomepage?:ValueTypes["EventsFields_top10ImpressionkRateHomepage"],
	otherEventRankingClickRateHomepage?:ValueTypes["EventsFields_otherEventRankingClickRateHomepage"],
	otherEventRankingImpressionHomePage?:ValueTypes["EventsFields_otherEventRankingImpressionHomePage"],
	userPreferenceClickRate?:ValueTypes["EventsFields_userPreferenceClickRate"],
	userPreferenceImpression?:ValueTypes["EventsFields_userPreferenceImpression"],
	eventSearchInternalClick?:ValueTypes["EventsFields_eventSearchInternalClick"],
	eventListImpression?:ValueTypes["EventsFields_eventListImpression"],
	latestEventInternalClick?:ValueTypes["EventsFields_latestEventInternalClick"],
	latestEventImpression?:ValueTypes["EventsFields_latestEventImpression"],
	topTenEventRankingInternalClick?:ValueTypes["EventsFields_topTenEventRankingInternalClick"],
	topTenImpression?:ValueTypes["EventsFields_topTenImpression"],
	editorChoiceInternalClick?:ValueTypes["EventsFields_editorChoiceInternalClick"],
	editorChoiceImpression?:ValueTypes["EventsFields_editorChoiceImpression"],
	otherEventRankingInternalClick?:ValueTypes["EventsFields_otherEventRankingInternalClick"],
	otherEventRankingImpression?:ValueTypes["EventsFields_otherEventRankingImpression"],
	bannerInternalClick?:ValueTypes["EventsFields_bannerInternalClick"],
	miscImpression?:ValueTypes["EventsFields_miscImpression"],
	miscInternalClick?:ValueTypes["EventsFields_miscInternalClick"],
	totalImpression?:ValueTypes["EventsFields_totalImpression"],
	internalClick?:ValueTypes["EventsFields_internalClick"],
	outboundClick?:ValueTypes["EventsFields_outboundClick"],
	externalClick?:ValueTypes["EventsFields_externalClick"],
	_viewsCountByMonth?:ValueTypes["EventsFields__viewsCountByMonth"],
	products?:ValueTypes["EventsFields_products"],
	legacyGallery?:ValueTypes["EventsFields_legacyGallery"],
	earliestStartDate?:ValueTypes["EventsFields_earliestStartDate"],
	latestEndDate?:ValueTypes["EventsFields_latestEndDate"],
	latestStartDateTime?:ValueTypes["EventsFields_latestStartDateTime"],
	hasTicketing?:ValueTypes["EventsFields_hasTicketing"],
	hasProducts?:ValueTypes["EventsFields_hasProducts"],
	updatedAt?:ValueTypes["EventsFields_updatedAt"],
	createdAt?:ValueTypes["EventsFields_createdAt"],
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_region"]: AliasType<{
	create?:ValueTypes["EventsFields_region_Create"],
	read?:ValueTypes["EventsFields_region_Read"],
	update?:ValueTypes["EventsFields_region_Update"],
	delete?:ValueTypes["EventsFields_region_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_region_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_region_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_region_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_region_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_creator"]: AliasType<{
	create?:ValueTypes["EventsFields_creator_Create"],
	read?:ValueTypes["EventsFields_creator_Read"],
	update?:ValueTypes["EventsFields_creator_Update"],
	delete?:ValueTypes["EventsFields_creator_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_creator_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_creator_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_creator_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_creator_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_permalink"]: AliasType<{
	create?:ValueTypes["EventsFields_permalink_Create"],
	read?:ValueTypes["EventsFields_permalink_Read"],
	update?:ValueTypes["EventsFields_permalink_Update"],
	delete?:ValueTypes["EventsFields_permalink_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_permalink_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_permalink_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_permalink_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_permalink_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_status"]: AliasType<{
	create?:ValueTypes["EventsFields_status_Create"],
	read?:ValueTypes["EventsFields_status_Read"],
	update?:ValueTypes["EventsFields_status_Update"],
	delete?:ValueTypes["EventsFields_status_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_status_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_status_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_status_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_status_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_level"]: AliasType<{
	create?:ValueTypes["EventsFields_level_Create"],
	read?:ValueTypes["EventsFields_level_Read"],
	update?:ValueTypes["EventsFields_level_Update"],
	delete?:ValueTypes["EventsFields_level_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_level_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_level_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_level_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_level_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_parent"]: AliasType<{
	create?:ValueTypes["EventsFields_parent_Create"],
	read?:ValueTypes["EventsFields_parent_Read"],
	update?:ValueTypes["EventsFields_parent_Update"],
	delete?:ValueTypes["EventsFields_parent_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_parent_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_parent_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_parent_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_parent_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_name"]: AliasType<{
	create?:ValueTypes["EventsFields_name_Create"],
	read?:ValueTypes["EventsFields_name_Read"],
	update?:ValueTypes["EventsFields_name_Update"],
	delete?:ValueTypes["EventsFields_name_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_name_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_name_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_name_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_name_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_chatbotEventTitle"]: AliasType<{
	create?:ValueTypes["EventsFields_chatbotEventTitle_Create"],
	read?:ValueTypes["EventsFields_chatbotEventTitle_Read"],
	update?:ValueTypes["EventsFields_chatbotEventTitle_Update"],
	delete?:ValueTypes["EventsFields_chatbotEventTitle_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_chatbotEventTitle_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_chatbotEventTitle_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_chatbotEventTitle_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_chatbotEventTitle_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_chatbotSequence"]: AliasType<{
	create?:ValueTypes["EventsFields_chatbotSequence_Create"],
	read?:ValueTypes["EventsFields_chatbotSequence_Read"],
	update?:ValueTypes["EventsFields_chatbotSequence_Update"],
	delete?:ValueTypes["EventsFields_chatbotSequence_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_chatbotSequence_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_chatbotSequence_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_chatbotSequence_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_chatbotSequence_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_thumbnail"]: AliasType<{
	create?:ValueTypes["EventsFields_thumbnail_Create"],
	read?:ValueTypes["EventsFields_thumbnail_Read"],
	update?:ValueTypes["EventsFields_thumbnail_Update"],
	delete?:ValueTypes["EventsFields_thumbnail_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_thumbnail_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_thumbnail_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_thumbnail_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_thumbnail_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_banner"]: AliasType<{
	create?:ValueTypes["EventsFields_banner_Create"],
	read?:ValueTypes["EventsFields_banner_Read"],
	update?:ValueTypes["EventsFields_banner_Update"],
	delete?:ValueTypes["EventsFields_banner_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_banner_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_banner_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_banner_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_banner_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_mobileBanner"]: AliasType<{
	create?:ValueTypes["EventsFields_mobileBanner_Create"],
	read?:ValueTypes["EventsFields_mobileBanner_Read"],
	update?:ValueTypes["EventsFields_mobileBanner_Update"],
	delete?:ValueTypes["EventsFields_mobileBanner_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_mobileBanner_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_mobileBanner_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_mobileBanner_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_mobileBanner_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_sections"]: AliasType<{
	create?:ValueTypes["EventsFields_sections_Create"],
	read?:ValueTypes["EventsFields_sections_Read"],
	update?:ValueTypes["EventsFields_sections_Update"],
	delete?:ValueTypes["EventsFields_sections_Delete"],
	fields?:ValueTypes["EventsFields_sections_Fields"],
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_sections_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_sections_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_sections_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_sections_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_sections_Fields"]: AliasType<{
	startDatetime?:ValueTypes["EventsFields_sections_startDatetime"],
	endDatetime?:ValueTypes["EventsFields_sections_endDatetime"],
	fullDay?:ValueTypes["EventsFields_sections_fullDay"],
	repeat?:ValueTypes["EventsFields_sections_repeat"],
	toThisDay?:ValueTypes["EventsFields_sections_toThisDay"],
	recurrance?:ValueTypes["EventsFields_sections_recurrance"],
	sameLocation?:ValueTypes["EventsFields_sections_sameLocation"],
	location?:ValueTypes["EventsFields_sections_location"],
	district?:ValueTypes["EventsFields_sections_district"],
	coordinate?:ValueTypes["EventsFields_sections_coordinate"],
	linkAddress?:ValueTypes["EventsFields_sections_linkAddress"],
	address?:ValueTypes["EventsFields_sections_address"],
	id?:ValueTypes["EventsFields_sections_id"],
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_sections_startDatetime"]: AliasType<{
	create?:ValueTypes["EventsFields_sections_startDatetime_Create"],
	read?:ValueTypes["EventsFields_sections_startDatetime_Read"],
	update?:ValueTypes["EventsFields_sections_startDatetime_Update"],
	delete?:ValueTypes["EventsFields_sections_startDatetime_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_sections_startDatetime_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_sections_startDatetime_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_sections_startDatetime_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_sections_startDatetime_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_sections_endDatetime"]: AliasType<{
	create?:ValueTypes["EventsFields_sections_endDatetime_Create"],
	read?:ValueTypes["EventsFields_sections_endDatetime_Read"],
	update?:ValueTypes["EventsFields_sections_endDatetime_Update"],
	delete?:ValueTypes["EventsFields_sections_endDatetime_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_sections_endDatetime_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_sections_endDatetime_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_sections_endDatetime_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_sections_endDatetime_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_sections_fullDay"]: AliasType<{
	create?:ValueTypes["EventsFields_sections_fullDay_Create"],
	read?:ValueTypes["EventsFields_sections_fullDay_Read"],
	update?:ValueTypes["EventsFields_sections_fullDay_Update"],
	delete?:ValueTypes["EventsFields_sections_fullDay_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_sections_fullDay_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_sections_fullDay_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_sections_fullDay_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_sections_fullDay_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_sections_repeat"]: AliasType<{
	create?:ValueTypes["EventsFields_sections_repeat_Create"],
	read?:ValueTypes["EventsFields_sections_repeat_Read"],
	update?:ValueTypes["EventsFields_sections_repeat_Update"],
	delete?:ValueTypes["EventsFields_sections_repeat_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_sections_repeat_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_sections_repeat_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_sections_repeat_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_sections_repeat_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_sections_toThisDay"]: AliasType<{
	create?:ValueTypes["EventsFields_sections_toThisDay_Create"],
	read?:ValueTypes["EventsFields_sections_toThisDay_Read"],
	update?:ValueTypes["EventsFields_sections_toThisDay_Update"],
	delete?:ValueTypes["EventsFields_sections_toThisDay_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_sections_toThisDay_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_sections_toThisDay_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_sections_toThisDay_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_sections_toThisDay_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_sections_recurrance"]: AliasType<{
	create?:ValueTypes["EventsFields_sections_recurrance_Create"],
	read?:ValueTypes["EventsFields_sections_recurrance_Read"],
	update?:ValueTypes["EventsFields_sections_recurrance_Update"],
	delete?:ValueTypes["EventsFields_sections_recurrance_Delete"],
	fields?:ValueTypes["EventsFields_sections_recurrance_Fields"],
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_sections_recurrance_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_sections_recurrance_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_sections_recurrance_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_sections_recurrance_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_sections_recurrance_Fields"]: AliasType<{
	type?:ValueTypes["EventsFields_sections_recurrance_type"],
	weekday?:ValueTypes["EventsFields_sections_recurrance_weekday"],
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_sections_recurrance_type"]: AliasType<{
	create?:ValueTypes["EventsFields_sections_recurrance_type_Create"],
	read?:ValueTypes["EventsFields_sections_recurrance_type_Read"],
	update?:ValueTypes["EventsFields_sections_recurrance_type_Update"],
	delete?:ValueTypes["EventsFields_sections_recurrance_type_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_sections_recurrance_type_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_sections_recurrance_type_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_sections_recurrance_type_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_sections_recurrance_type_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_sections_recurrance_weekday"]: AliasType<{
	create?:ValueTypes["EventsFields_sections_recurrance_weekday_Create"],
	read?:ValueTypes["EventsFields_sections_recurrance_weekday_Read"],
	update?:ValueTypes["EventsFields_sections_recurrance_weekday_Update"],
	delete?:ValueTypes["EventsFields_sections_recurrance_weekday_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_sections_recurrance_weekday_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_sections_recurrance_weekday_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_sections_recurrance_weekday_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_sections_recurrance_weekday_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_sections_sameLocation"]: AliasType<{
	create?:ValueTypes["EventsFields_sections_sameLocation_Create"],
	read?:ValueTypes["EventsFields_sections_sameLocation_Read"],
	update?:ValueTypes["EventsFields_sections_sameLocation_Update"],
	delete?:ValueTypes["EventsFields_sections_sameLocation_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_sections_sameLocation_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_sections_sameLocation_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_sections_sameLocation_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_sections_sameLocation_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_sections_location"]: AliasType<{
	create?:ValueTypes["EventsFields_sections_location_Create"],
	read?:ValueTypes["EventsFields_sections_location_Read"],
	update?:ValueTypes["EventsFields_sections_location_Update"],
	delete?:ValueTypes["EventsFields_sections_location_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_sections_location_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_sections_location_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_sections_location_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_sections_location_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_sections_district"]: AliasType<{
	create?:ValueTypes["EventsFields_sections_district_Create"],
	read?:ValueTypes["EventsFields_sections_district_Read"],
	update?:ValueTypes["EventsFields_sections_district_Update"],
	delete?:ValueTypes["EventsFields_sections_district_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_sections_district_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_sections_district_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_sections_district_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_sections_district_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_sections_coordinate"]: AliasType<{
	create?:ValueTypes["EventsFields_sections_coordinate_Create"],
	read?:ValueTypes["EventsFields_sections_coordinate_Read"],
	update?:ValueTypes["EventsFields_sections_coordinate_Update"],
	delete?:ValueTypes["EventsFields_sections_coordinate_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_sections_coordinate_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_sections_coordinate_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_sections_coordinate_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_sections_coordinate_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_sections_linkAddress"]: AliasType<{
	create?:ValueTypes["EventsFields_sections_linkAddress_Create"],
	read?:ValueTypes["EventsFields_sections_linkAddress_Read"],
	update?:ValueTypes["EventsFields_sections_linkAddress_Update"],
	delete?:ValueTypes["EventsFields_sections_linkAddress_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_sections_linkAddress_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_sections_linkAddress_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_sections_linkAddress_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_sections_linkAddress_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_sections_address"]: AliasType<{
	create?:ValueTypes["EventsFields_sections_address_Create"],
	read?:ValueTypes["EventsFields_sections_address_Read"],
	update?:ValueTypes["EventsFields_sections_address_Update"],
	delete?:ValueTypes["EventsFields_sections_address_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_sections_address_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_sections_address_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_sections_address_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_sections_address_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_sections_id"]: AliasType<{
	create?:ValueTypes["EventsFields_sections_id_Create"],
	read?:ValueTypes["EventsFields_sections_id_Read"],
	update?:ValueTypes["EventsFields_sections_id_Update"],
	delete?:ValueTypes["EventsFields_sections_id_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_sections_id_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_sections_id_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_sections_id_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_sections_id_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_ticketing"]: AliasType<{
	create?:ValueTypes["EventsFields_ticketing_Create"],
	read?:ValueTypes["EventsFields_ticketing_Read"],
	update?:ValueTypes["EventsFields_ticketing_Update"],
	delete?:ValueTypes["EventsFields_ticketing_Delete"],
	fields?:ValueTypes["EventsFields_ticketing_Fields"],
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_ticketing_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_ticketing_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_ticketing_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_ticketing_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_ticketing_Fields"]: AliasType<{
	startDatetime?:ValueTypes["EventsFields_ticketing_startDatetime"],
	endDatetime?:ValueTypes["EventsFields_ticketing_endDatetime"],
	fullDay?:ValueTypes["EventsFields_ticketing_fullDay"],
	type?:ValueTypes["EventsFields_ticketing_type"],
	ticketingType?:ValueTypes["EventsFields_ticketing_ticketingType"],
	linkedTicketing?:ValueTypes["EventsFields_ticketing_linkedTicketing"],
	promotionType?:ValueTypes["EventsFields_ticketing_promotionType"],
	location?:ValueTypes["EventsFields_ticketing_location"],
	website?:ValueTypes["EventsFields_ticketing_website"],
	description?:ValueTypes["EventsFields_ticketing_description"],
	id?:ValueTypes["EventsFields_ticketing_id"],
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_ticketing_startDatetime"]: AliasType<{
	create?:ValueTypes["EventsFields_ticketing_startDatetime_Create"],
	read?:ValueTypes["EventsFields_ticketing_startDatetime_Read"],
	update?:ValueTypes["EventsFields_ticketing_startDatetime_Update"],
	delete?:ValueTypes["EventsFields_ticketing_startDatetime_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_ticketing_startDatetime_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_ticketing_startDatetime_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_ticketing_startDatetime_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_ticketing_startDatetime_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_ticketing_endDatetime"]: AliasType<{
	create?:ValueTypes["EventsFields_ticketing_endDatetime_Create"],
	read?:ValueTypes["EventsFields_ticketing_endDatetime_Read"],
	update?:ValueTypes["EventsFields_ticketing_endDatetime_Update"],
	delete?:ValueTypes["EventsFields_ticketing_endDatetime_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_ticketing_endDatetime_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_ticketing_endDatetime_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_ticketing_endDatetime_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_ticketing_endDatetime_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_ticketing_fullDay"]: AliasType<{
	create?:ValueTypes["EventsFields_ticketing_fullDay_Create"],
	read?:ValueTypes["EventsFields_ticketing_fullDay_Read"],
	update?:ValueTypes["EventsFields_ticketing_fullDay_Update"],
	delete?:ValueTypes["EventsFields_ticketing_fullDay_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_ticketing_fullDay_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_ticketing_fullDay_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_ticketing_fullDay_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_ticketing_fullDay_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_ticketing_type"]: AliasType<{
	create?:ValueTypes["EventsFields_ticketing_type_Create"],
	read?:ValueTypes["EventsFields_ticketing_type_Read"],
	update?:ValueTypes["EventsFields_ticketing_type_Update"],
	delete?:ValueTypes["EventsFields_ticketing_type_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_ticketing_type_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_ticketing_type_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_ticketing_type_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_ticketing_type_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_ticketing_ticketingType"]: AliasType<{
	create?:ValueTypes["EventsFields_ticketing_ticketingType_Create"],
	read?:ValueTypes["EventsFields_ticketing_ticketingType_Read"],
	update?:ValueTypes["EventsFields_ticketing_ticketingType_Update"],
	delete?:ValueTypes["EventsFields_ticketing_ticketingType_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_ticketing_ticketingType_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_ticketing_ticketingType_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_ticketing_ticketingType_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_ticketing_ticketingType_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_ticketing_linkedTicketing"]: AliasType<{
	create?:ValueTypes["EventsFields_ticketing_linkedTicketing_Create"],
	read?:ValueTypes["EventsFields_ticketing_linkedTicketing_Read"],
	update?:ValueTypes["EventsFields_ticketing_linkedTicketing_Update"],
	delete?:ValueTypes["EventsFields_ticketing_linkedTicketing_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_ticketing_linkedTicketing_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_ticketing_linkedTicketing_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_ticketing_linkedTicketing_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_ticketing_linkedTicketing_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_ticketing_promotionType"]: AliasType<{
	create?:ValueTypes["EventsFields_ticketing_promotionType_Create"],
	read?:ValueTypes["EventsFields_ticketing_promotionType_Read"],
	update?:ValueTypes["EventsFields_ticketing_promotionType_Update"],
	delete?:ValueTypes["EventsFields_ticketing_promotionType_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_ticketing_promotionType_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_ticketing_promotionType_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_ticketing_promotionType_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_ticketing_promotionType_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_ticketing_location"]: AliasType<{
	create?:ValueTypes["EventsFields_ticketing_location_Create"],
	read?:ValueTypes["EventsFields_ticketing_location_Read"],
	update?:ValueTypes["EventsFields_ticketing_location_Update"],
	delete?:ValueTypes["EventsFields_ticketing_location_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_ticketing_location_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_ticketing_location_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_ticketing_location_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_ticketing_location_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_ticketing_website"]: AliasType<{
	create?:ValueTypes["EventsFields_ticketing_website_Create"],
	read?:ValueTypes["EventsFields_ticketing_website_Read"],
	update?:ValueTypes["EventsFields_ticketing_website_Update"],
	delete?:ValueTypes["EventsFields_ticketing_website_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_ticketing_website_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_ticketing_website_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_ticketing_website_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_ticketing_website_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_ticketing_description"]: AliasType<{
	create?:ValueTypes["EventsFields_ticketing_description_Create"],
	read?:ValueTypes["EventsFields_ticketing_description_Read"],
	update?:ValueTypes["EventsFields_ticketing_description_Update"],
	delete?:ValueTypes["EventsFields_ticketing_description_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_ticketing_description_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_ticketing_description_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_ticketing_description_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_ticketing_description_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_ticketing_id"]: AliasType<{
	create?:ValueTypes["EventsFields_ticketing_id_Create"],
	read?:ValueTypes["EventsFields_ticketing_id_Read"],
	update?:ValueTypes["EventsFields_ticketing_id_Update"],
	delete?:ValueTypes["EventsFields_ticketing_id_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_ticketing_id_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_ticketing_id_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_ticketing_id_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_ticketing_id_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_content"]: AliasType<{
	create?:ValueTypes["EventsFields_content_Create"],
	read?:ValueTypes["EventsFields_content_Read"],
	update?:ValueTypes["EventsFields_content_Update"],
	delete?:ValueTypes["EventsFields_content_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_content_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_content_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_content_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_content_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_legacyContent"]: AliasType<{
	create?:ValueTypes["EventsFields_legacyContent_Create"],
	read?:ValueTypes["EventsFields_legacyContent_Read"],
	update?:ValueTypes["EventsFields_legacyContent_Update"],
	delete?:ValueTypes["EventsFields_legacyContent_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_legacyContent_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_legacyContent_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_legacyContent_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_legacyContent_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_showLegacyContent"]: AliasType<{
	create?:ValueTypes["EventsFields_showLegacyContent_Create"],
	read?:ValueTypes["EventsFields_showLegacyContent_Read"],
	update?:ValueTypes["EventsFields_showLegacyContent_Update"],
	delete?:ValueTypes["EventsFields_showLegacyContent_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_showLegacyContent_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_showLegacyContent_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_showLegacyContent_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_showLegacyContent_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_criteria"]: AliasType<{
	create?:ValueTypes["EventsFields_criteria_Create"],
	read?:ValueTypes["EventsFields_criteria_Read"],
	update?:ValueTypes["EventsFields_criteria_Update"],
	delete?:ValueTypes["EventsFields_criteria_Delete"],
	fields?:ValueTypes["EventsFields_criteria_Fields"],
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_criteria_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_criteria_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_criteria_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_criteria_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_criteria_Fields"]: AliasType<{
	categories?:ValueTypes["EventsFields_criteria_categories"],
	audiences?:ValueTypes["EventsFields_criteria_audiences"],
	tags?:ValueTypes["EventsFields_criteria_tags"],
	specialTags?:ValueTypes["EventsFields_criteria_specialTags"],
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_criteria_categories"]: AliasType<{
	create?:ValueTypes["EventsFields_criteria_categories_Create"],
	read?:ValueTypes["EventsFields_criteria_categories_Read"],
	update?:ValueTypes["EventsFields_criteria_categories_Update"],
	delete?:ValueTypes["EventsFields_criteria_categories_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_criteria_categories_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_criteria_categories_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_criteria_categories_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_criteria_categories_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_criteria_audiences"]: AliasType<{
	create?:ValueTypes["EventsFields_criteria_audiences_Create"],
	read?:ValueTypes["EventsFields_criteria_audiences_Read"],
	update?:ValueTypes["EventsFields_criteria_audiences_Update"],
	delete?:ValueTypes["EventsFields_criteria_audiences_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_criteria_audiences_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_criteria_audiences_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_criteria_audiences_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_criteria_audiences_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_criteria_tags"]: AliasType<{
	create?:ValueTypes["EventsFields_criteria_tags_Create"],
	read?:ValueTypes["EventsFields_criteria_tags_Read"],
	update?:ValueTypes["EventsFields_criteria_tags_Update"],
	delete?:ValueTypes["EventsFields_criteria_tags_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_criteria_tags_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_criteria_tags_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_criteria_tags_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_criteria_tags_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_criteria_specialTags"]: AliasType<{
	create?:ValueTypes["EventsFields_criteria_specialTags_Create"],
	read?:ValueTypes["EventsFields_criteria_specialTags_Read"],
	update?:ValueTypes["EventsFields_criteria_specialTags_Update"],
	delete?:ValueTypes["EventsFields_criteria_specialTags_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_criteria_specialTags_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_criteria_specialTags_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_criteria_specialTags_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_criteria_specialTags_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_walkIn"]: AliasType<{
	create?:ValueTypes["EventsFields_walkIn_Create"],
	read?:ValueTypes["EventsFields_walkIn_Read"],
	update?:ValueTypes["EventsFields_walkIn_Update"],
	delete?:ValueTypes["EventsFields_walkIn_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_walkIn_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_walkIn_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_walkIn_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_walkIn_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_contact"]: AliasType<{
	create?:ValueTypes["EventsFields_contact_Create"],
	read?:ValueTypes["EventsFields_contact_Read"],
	update?:ValueTypes["EventsFields_contact_Update"],
	delete?:ValueTypes["EventsFields_contact_Delete"],
	fields?:ValueTypes["EventsFields_contact_Fields"],
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_contact_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_contact_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_contact_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_contact_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_contact_Fields"]: AliasType<{
	code?:ValueTypes["EventsFields_contact_code"],
	phone?:ValueTypes["EventsFields_contact_phone"],
	page?:ValueTypes["EventsFields_contact_page"],
	email?:ValueTypes["EventsFields_contact_email"],
	privacy?:ValueTypes["EventsFields_contact_privacy"],
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_contact_code"]: AliasType<{
	create?:ValueTypes["EventsFields_contact_code_Create"],
	read?:ValueTypes["EventsFields_contact_code_Read"],
	update?:ValueTypes["EventsFields_contact_code_Update"],
	delete?:ValueTypes["EventsFields_contact_code_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_contact_code_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_contact_code_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_contact_code_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_contact_code_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_contact_phone"]: AliasType<{
	create?:ValueTypes["EventsFields_contact_phone_Create"],
	read?:ValueTypes["EventsFields_contact_phone_Read"],
	update?:ValueTypes["EventsFields_contact_phone_Update"],
	delete?:ValueTypes["EventsFields_contact_phone_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_contact_phone_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_contact_phone_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_contact_phone_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_contact_phone_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_contact_page"]: AliasType<{
	create?:ValueTypes["EventsFields_contact_page_Create"],
	read?:ValueTypes["EventsFields_contact_page_Read"],
	update?:ValueTypes["EventsFields_contact_page_Update"],
	delete?:ValueTypes["EventsFields_contact_page_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_contact_page_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_contact_page_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_contact_page_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_contact_page_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_contact_email"]: AliasType<{
	create?:ValueTypes["EventsFields_contact_email_Create"],
	read?:ValueTypes["EventsFields_contact_email_Read"],
	update?:ValueTypes["EventsFields_contact_email_Update"],
	delete?:ValueTypes["EventsFields_contact_email_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_contact_email_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_contact_email_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_contact_email_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_contact_email_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_contact_privacy"]: AliasType<{
	create?:ValueTypes["EventsFields_contact_privacy_Create"],
	read?:ValueTypes["EventsFields_contact_privacy_Read"],
	update?:ValueTypes["EventsFields_contact_privacy_Update"],
	delete?:ValueTypes["EventsFields_contact_privacy_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_contact_privacy_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_contact_privacy_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_contact_privacy_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_contact_privacy_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_views"]: AliasType<{
	create?:ValueTypes["EventsFields_views_Create"],
	read?:ValueTypes["EventsFields_views_Read"],
	update?:ValueTypes["EventsFields_views_Update"],
	delete?:ValueTypes["EventsFields_views_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_views_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_views_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_views_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_views_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_free"]: AliasType<{
	create?:ValueTypes["EventsFields_free_Create"],
	read?:ValueTypes["EventsFields_free_Read"],
	update?:ValueTypes["EventsFields_free_Update"],
	delete?:ValueTypes["EventsFields_free_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_free_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_free_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_free_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_free_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_fees"]: AliasType<{
	create?:ValueTypes["EventsFields_fees_Create"],
	read?:ValueTypes["EventsFields_fees_Read"],
	update?:ValueTypes["EventsFields_fees_Update"],
	delete?:ValueTypes["EventsFields_fees_Delete"],
	fields?:ValueTypes["EventsFields_fees_Fields"],
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_fees_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_fees_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_fees_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_fees_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_fees_Fields"]: AliasType<{
	fee?:ValueTypes["EventsFields_fees_fee"],
	remark?:ValueTypes["EventsFields_fees_remark"],
	id?:ValueTypes["EventsFields_fees_id"],
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_fees_fee"]: AliasType<{
	create?:ValueTypes["EventsFields_fees_fee_Create"],
	read?:ValueTypes["EventsFields_fees_fee_Read"],
	update?:ValueTypes["EventsFields_fees_fee_Update"],
	delete?:ValueTypes["EventsFields_fees_fee_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_fees_fee_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_fees_fee_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_fees_fee_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_fees_fee_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_fees_remark"]: AliasType<{
	create?:ValueTypes["EventsFields_fees_remark_Create"],
	read?:ValueTypes["EventsFields_fees_remark_Read"],
	update?:ValueTypes["EventsFields_fees_remark_Update"],
	delete?:ValueTypes["EventsFields_fees_remark_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_fees_remark_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_fees_remark_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_fees_remark_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_fees_remark_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_fees_id"]: AliasType<{
	create?:ValueTypes["EventsFields_fees_id_Create"],
	read?:ValueTypes["EventsFields_fees_id_Read"],
	update?:ValueTypes["EventsFields_fees_id_Update"],
	delete?:ValueTypes["EventsFields_fees_id_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_fees_id_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_fees_id_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_fees_id_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_fees_id_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_homePageInternalClick"]: AliasType<{
	create?:ValueTypes["EventsFields_homePageInternalClick_Create"],
	read?:ValueTypes["EventsFields_homePageInternalClick_Read"],
	update?:ValueTypes["EventsFields_homePageInternalClick_Update"],
	delete?:ValueTypes["EventsFields_homePageInternalClick_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_homePageInternalClick_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_homePageInternalClick_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_homePageInternalClick_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_homePageInternalClick_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_homePageImpression"]: AliasType<{
	create?:ValueTypes["EventsFields_homePageImpression_Create"],
	read?:ValueTypes["EventsFields_homePageImpression_Read"],
	update?:ValueTypes["EventsFields_homePageImpression_Update"],
	delete?:ValueTypes["EventsFields_homePageImpression_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_homePageImpression_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_homePageImpression_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_homePageImpression_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_homePageImpression_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_eodClickRate"]: AliasType<{
	create?:ValueTypes["EventsFields_eodClickRate_Create"],
	read?:ValueTypes["EventsFields_eodClickRate_Read"],
	update?:ValueTypes["EventsFields_eodClickRate_Update"],
	delete?:ValueTypes["EventsFields_eodClickRate_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_eodClickRate_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_eodClickRate_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_eodClickRate_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_eodClickRate_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_eodImpressionRate"]: AliasType<{
	create?:ValueTypes["EventsFields_eodImpressionRate_Create"],
	read?:ValueTypes["EventsFields_eodImpressionRate_Read"],
	update?:ValueTypes["EventsFields_eodImpressionRate_Update"],
	delete?:ValueTypes["EventsFields_eodImpressionRate_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_eodImpressionRate_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_eodImpressionRate_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_eodImpressionRate_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_eodImpressionRate_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_top10ClickRateHomepage"]: AliasType<{
	create?:ValueTypes["EventsFields_top10ClickRateHomepage_Create"],
	read?:ValueTypes["EventsFields_top10ClickRateHomepage_Read"],
	update?:ValueTypes["EventsFields_top10ClickRateHomepage_Update"],
	delete?:ValueTypes["EventsFields_top10ClickRateHomepage_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_top10ClickRateHomepage_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_top10ClickRateHomepage_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_top10ClickRateHomepage_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_top10ClickRateHomepage_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_top10ImpressionkRateHomepage"]: AliasType<{
	create?:ValueTypes["EventsFields_top10ImpressionkRateHomepage_Create"],
	read?:ValueTypes["EventsFields_top10ImpressionkRateHomepage_Read"],
	update?:ValueTypes["EventsFields_top10ImpressionkRateHomepage_Update"],
	delete?:ValueTypes["EventsFields_top10ImpressionkRateHomepage_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_top10ImpressionkRateHomepage_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_top10ImpressionkRateHomepage_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_top10ImpressionkRateHomepage_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_top10ImpressionkRateHomepage_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_otherEventRankingClickRateHomepage"]: AliasType<{
	create?:ValueTypes["EventsFields_otherEventRankingClickRateHomepage_Create"],
	read?:ValueTypes["EventsFields_otherEventRankingClickRateHomepage_Read"],
	update?:ValueTypes["EventsFields_otherEventRankingClickRateHomepage_Update"],
	delete?:ValueTypes["EventsFields_otherEventRankingClickRateHomepage_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_otherEventRankingClickRateHomepage_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_otherEventRankingClickRateHomepage_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_otherEventRankingClickRateHomepage_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_otherEventRankingClickRateHomepage_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_otherEventRankingImpressionHomePage"]: AliasType<{
	create?:ValueTypes["EventsFields_otherEventRankingImpressionHomePage_Create"],
	read?:ValueTypes["EventsFields_otherEventRankingImpressionHomePage_Read"],
	update?:ValueTypes["EventsFields_otherEventRankingImpressionHomePage_Update"],
	delete?:ValueTypes["EventsFields_otherEventRankingImpressionHomePage_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_otherEventRankingImpressionHomePage_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_otherEventRankingImpressionHomePage_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_otherEventRankingImpressionHomePage_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_otherEventRankingImpressionHomePage_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_userPreferenceClickRate"]: AliasType<{
	create?:ValueTypes["EventsFields_userPreferenceClickRate_Create"],
	read?:ValueTypes["EventsFields_userPreferenceClickRate_Read"],
	update?:ValueTypes["EventsFields_userPreferenceClickRate_Update"],
	delete?:ValueTypes["EventsFields_userPreferenceClickRate_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_userPreferenceClickRate_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_userPreferenceClickRate_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_userPreferenceClickRate_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_userPreferenceClickRate_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_userPreferenceImpression"]: AliasType<{
	create?:ValueTypes["EventsFields_userPreferenceImpression_Create"],
	read?:ValueTypes["EventsFields_userPreferenceImpression_Read"],
	update?:ValueTypes["EventsFields_userPreferenceImpression_Update"],
	delete?:ValueTypes["EventsFields_userPreferenceImpression_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_userPreferenceImpression_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_userPreferenceImpression_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_userPreferenceImpression_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_userPreferenceImpression_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_eventSearchInternalClick"]: AliasType<{
	create?:ValueTypes["EventsFields_eventSearchInternalClick_Create"],
	read?:ValueTypes["EventsFields_eventSearchInternalClick_Read"],
	update?:ValueTypes["EventsFields_eventSearchInternalClick_Update"],
	delete?:ValueTypes["EventsFields_eventSearchInternalClick_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_eventSearchInternalClick_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_eventSearchInternalClick_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_eventSearchInternalClick_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_eventSearchInternalClick_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_eventListImpression"]: AliasType<{
	create?:ValueTypes["EventsFields_eventListImpression_Create"],
	read?:ValueTypes["EventsFields_eventListImpression_Read"],
	update?:ValueTypes["EventsFields_eventListImpression_Update"],
	delete?:ValueTypes["EventsFields_eventListImpression_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_eventListImpression_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_eventListImpression_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_eventListImpression_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_eventListImpression_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_latestEventInternalClick"]: AliasType<{
	create?:ValueTypes["EventsFields_latestEventInternalClick_Create"],
	read?:ValueTypes["EventsFields_latestEventInternalClick_Read"],
	update?:ValueTypes["EventsFields_latestEventInternalClick_Update"],
	delete?:ValueTypes["EventsFields_latestEventInternalClick_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_latestEventInternalClick_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_latestEventInternalClick_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_latestEventInternalClick_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_latestEventInternalClick_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_latestEventImpression"]: AliasType<{
	create?:ValueTypes["EventsFields_latestEventImpression_Create"],
	read?:ValueTypes["EventsFields_latestEventImpression_Read"],
	update?:ValueTypes["EventsFields_latestEventImpression_Update"],
	delete?:ValueTypes["EventsFields_latestEventImpression_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_latestEventImpression_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_latestEventImpression_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_latestEventImpression_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_latestEventImpression_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_topTenEventRankingInternalClick"]: AliasType<{
	create?:ValueTypes["EventsFields_topTenEventRankingInternalClick_Create"],
	read?:ValueTypes["EventsFields_topTenEventRankingInternalClick_Read"],
	update?:ValueTypes["EventsFields_topTenEventRankingInternalClick_Update"],
	delete?:ValueTypes["EventsFields_topTenEventRankingInternalClick_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_topTenEventRankingInternalClick_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_topTenEventRankingInternalClick_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_topTenEventRankingInternalClick_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_topTenEventRankingInternalClick_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_topTenImpression"]: AliasType<{
	create?:ValueTypes["EventsFields_topTenImpression_Create"],
	read?:ValueTypes["EventsFields_topTenImpression_Read"],
	update?:ValueTypes["EventsFields_topTenImpression_Update"],
	delete?:ValueTypes["EventsFields_topTenImpression_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_topTenImpression_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_topTenImpression_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_topTenImpression_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_topTenImpression_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_editorChoiceInternalClick"]: AliasType<{
	create?:ValueTypes["EventsFields_editorChoiceInternalClick_Create"],
	read?:ValueTypes["EventsFields_editorChoiceInternalClick_Read"],
	update?:ValueTypes["EventsFields_editorChoiceInternalClick_Update"],
	delete?:ValueTypes["EventsFields_editorChoiceInternalClick_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_editorChoiceInternalClick_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_editorChoiceInternalClick_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_editorChoiceInternalClick_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_editorChoiceInternalClick_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_editorChoiceImpression"]: AliasType<{
	create?:ValueTypes["EventsFields_editorChoiceImpression_Create"],
	read?:ValueTypes["EventsFields_editorChoiceImpression_Read"],
	update?:ValueTypes["EventsFields_editorChoiceImpression_Update"],
	delete?:ValueTypes["EventsFields_editorChoiceImpression_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_editorChoiceImpression_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_editorChoiceImpression_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_editorChoiceImpression_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_editorChoiceImpression_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_otherEventRankingInternalClick"]: AliasType<{
	create?:ValueTypes["EventsFields_otherEventRankingInternalClick_Create"],
	read?:ValueTypes["EventsFields_otherEventRankingInternalClick_Read"],
	update?:ValueTypes["EventsFields_otherEventRankingInternalClick_Update"],
	delete?:ValueTypes["EventsFields_otherEventRankingInternalClick_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_otherEventRankingInternalClick_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_otherEventRankingInternalClick_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_otherEventRankingInternalClick_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_otherEventRankingInternalClick_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_otherEventRankingImpression"]: AliasType<{
	create?:ValueTypes["EventsFields_otherEventRankingImpression_Create"],
	read?:ValueTypes["EventsFields_otherEventRankingImpression_Read"],
	update?:ValueTypes["EventsFields_otherEventRankingImpression_Update"],
	delete?:ValueTypes["EventsFields_otherEventRankingImpression_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_otherEventRankingImpression_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_otherEventRankingImpression_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_otherEventRankingImpression_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_otherEventRankingImpression_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_bannerInternalClick"]: AliasType<{
	create?:ValueTypes["EventsFields_bannerInternalClick_Create"],
	read?:ValueTypes["EventsFields_bannerInternalClick_Read"],
	update?:ValueTypes["EventsFields_bannerInternalClick_Update"],
	delete?:ValueTypes["EventsFields_bannerInternalClick_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_bannerInternalClick_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_bannerInternalClick_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_bannerInternalClick_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_bannerInternalClick_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_miscImpression"]: AliasType<{
	create?:ValueTypes["EventsFields_miscImpression_Create"],
	read?:ValueTypes["EventsFields_miscImpression_Read"],
	update?:ValueTypes["EventsFields_miscImpression_Update"],
	delete?:ValueTypes["EventsFields_miscImpression_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_miscImpression_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_miscImpression_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_miscImpression_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_miscImpression_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_miscInternalClick"]: AliasType<{
	create?:ValueTypes["EventsFields_miscInternalClick_Create"],
	read?:ValueTypes["EventsFields_miscInternalClick_Read"],
	update?:ValueTypes["EventsFields_miscInternalClick_Update"],
	delete?:ValueTypes["EventsFields_miscInternalClick_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_miscInternalClick_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_miscInternalClick_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_miscInternalClick_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_miscInternalClick_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_totalImpression"]: AliasType<{
	create?:ValueTypes["EventsFields_totalImpression_Create"],
	read?:ValueTypes["EventsFields_totalImpression_Read"],
	update?:ValueTypes["EventsFields_totalImpression_Update"],
	delete?:ValueTypes["EventsFields_totalImpression_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_totalImpression_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_totalImpression_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_totalImpression_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_totalImpression_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_internalClick"]: AliasType<{
	create?:ValueTypes["EventsFields_internalClick_Create"],
	read?:ValueTypes["EventsFields_internalClick_Read"],
	update?:ValueTypes["EventsFields_internalClick_Update"],
	delete?:ValueTypes["EventsFields_internalClick_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_internalClick_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_internalClick_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_internalClick_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_internalClick_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_outboundClick"]: AliasType<{
	create?:ValueTypes["EventsFields_outboundClick_Create"],
	read?:ValueTypes["EventsFields_outboundClick_Read"],
	update?:ValueTypes["EventsFields_outboundClick_Update"],
	delete?:ValueTypes["EventsFields_outboundClick_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_outboundClick_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_outboundClick_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_outboundClick_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_outboundClick_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_externalClick"]: AliasType<{
	create?:ValueTypes["EventsFields_externalClick_Create"],
	read?:ValueTypes["EventsFields_externalClick_Read"],
	update?:ValueTypes["EventsFields_externalClick_Update"],
	delete?:ValueTypes["EventsFields_externalClick_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_externalClick_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_externalClick_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_externalClick_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_externalClick_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields__viewsCountByMonth"]: AliasType<{
	create?:ValueTypes["EventsFields__viewsCountByMonth_Create"],
	read?:ValueTypes["EventsFields__viewsCountByMonth_Read"],
	update?:ValueTypes["EventsFields__viewsCountByMonth_Update"],
	delete?:ValueTypes["EventsFields__viewsCountByMonth_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventsFields__viewsCountByMonth_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields__viewsCountByMonth_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields__viewsCountByMonth_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields__viewsCountByMonth_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_products"]: AliasType<{
	create?:ValueTypes["EventsFields_products_Create"],
	read?:ValueTypes["EventsFields_products_Read"],
	update?:ValueTypes["EventsFields_products_Update"],
	delete?:ValueTypes["EventsFields_products_Delete"],
	fields?:ValueTypes["EventsFields_products_Fields"],
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_products_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_products_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_products_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_products_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_products_Fields"]: AliasType<{
	startDateTime?:ValueTypes["EventsFields_products_startDateTime"],
	endDateTime?:ValueTypes["EventsFields_products_endDateTime"],
	fullDay?:ValueTypes["EventsFields_products_fullDay"],
	linkedProduct?:ValueTypes["EventsFields_products_linkedProduct"],
	id?:ValueTypes["EventsFields_products_id"],
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_products_startDateTime"]: AliasType<{
	create?:ValueTypes["EventsFields_products_startDateTime_Create"],
	read?:ValueTypes["EventsFields_products_startDateTime_Read"],
	update?:ValueTypes["EventsFields_products_startDateTime_Update"],
	delete?:ValueTypes["EventsFields_products_startDateTime_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_products_startDateTime_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_products_startDateTime_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_products_startDateTime_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_products_startDateTime_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_products_endDateTime"]: AliasType<{
	create?:ValueTypes["EventsFields_products_endDateTime_Create"],
	read?:ValueTypes["EventsFields_products_endDateTime_Read"],
	update?:ValueTypes["EventsFields_products_endDateTime_Update"],
	delete?:ValueTypes["EventsFields_products_endDateTime_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_products_endDateTime_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_products_endDateTime_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_products_endDateTime_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_products_endDateTime_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_products_fullDay"]: AliasType<{
	create?:ValueTypes["EventsFields_products_fullDay_Create"],
	read?:ValueTypes["EventsFields_products_fullDay_Read"],
	update?:ValueTypes["EventsFields_products_fullDay_Update"],
	delete?:ValueTypes["EventsFields_products_fullDay_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_products_fullDay_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_products_fullDay_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_products_fullDay_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_products_fullDay_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_products_linkedProduct"]: AliasType<{
	create?:ValueTypes["EventsFields_products_linkedProduct_Create"],
	read?:ValueTypes["EventsFields_products_linkedProduct_Read"],
	update?:ValueTypes["EventsFields_products_linkedProduct_Update"],
	delete?:ValueTypes["EventsFields_products_linkedProduct_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_products_linkedProduct_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_products_linkedProduct_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_products_linkedProduct_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_products_linkedProduct_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_products_id"]: AliasType<{
	create?:ValueTypes["EventsFields_products_id_Create"],
	read?:ValueTypes["EventsFields_products_id_Read"],
	update?:ValueTypes["EventsFields_products_id_Update"],
	delete?:ValueTypes["EventsFields_products_id_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_products_id_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_products_id_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_products_id_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_products_id_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_legacyGallery"]: AliasType<{
	create?:ValueTypes["EventsFields_legacyGallery_Create"],
	read?:ValueTypes["EventsFields_legacyGallery_Read"],
	update?:ValueTypes["EventsFields_legacyGallery_Update"],
	delete?:ValueTypes["EventsFields_legacyGallery_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_legacyGallery_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_legacyGallery_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_legacyGallery_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_legacyGallery_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_earliestStartDate"]: AliasType<{
	create?:ValueTypes["EventsFields_earliestStartDate_Create"],
	read?:ValueTypes["EventsFields_earliestStartDate_Read"],
	update?:ValueTypes["EventsFields_earliestStartDate_Update"],
	delete?:ValueTypes["EventsFields_earliestStartDate_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_earliestStartDate_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_earliestStartDate_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_earliestStartDate_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_earliestStartDate_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_latestEndDate"]: AliasType<{
	create?:ValueTypes["EventsFields_latestEndDate_Create"],
	read?:ValueTypes["EventsFields_latestEndDate_Read"],
	update?:ValueTypes["EventsFields_latestEndDate_Update"],
	delete?:ValueTypes["EventsFields_latestEndDate_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_latestEndDate_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_latestEndDate_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_latestEndDate_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_latestEndDate_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_latestStartDateTime"]: AliasType<{
	create?:ValueTypes["EventsFields_latestStartDateTime_Create"],
	read?:ValueTypes["EventsFields_latestStartDateTime_Read"],
	update?:ValueTypes["EventsFields_latestStartDateTime_Update"],
	delete?:ValueTypes["EventsFields_latestStartDateTime_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_latestStartDateTime_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_latestStartDateTime_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_latestStartDateTime_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_latestStartDateTime_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_hasTicketing"]: AliasType<{
	create?:ValueTypes["EventsFields_hasTicketing_Create"],
	read?:ValueTypes["EventsFields_hasTicketing_Read"],
	update?:ValueTypes["EventsFields_hasTicketing_Update"],
	delete?:ValueTypes["EventsFields_hasTicketing_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_hasTicketing_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_hasTicketing_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_hasTicketing_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_hasTicketing_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_hasProducts"]: AliasType<{
	create?:ValueTypes["EventsFields_hasProducts_Create"],
	read?:ValueTypes["EventsFields_hasProducts_Read"],
	update?:ValueTypes["EventsFields_hasProducts_Update"],
	delete?:ValueTypes["EventsFields_hasProducts_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_hasProducts_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_hasProducts_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_hasProducts_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_hasProducts_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_updatedAt"]: AliasType<{
	create?:ValueTypes["EventsFields_updatedAt_Create"],
	read?:ValueTypes["EventsFields_updatedAt_Read"],
	update?:ValueTypes["EventsFields_updatedAt_Update"],
	delete?:ValueTypes["EventsFields_updatedAt_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_updatedAt_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_updatedAt_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_updatedAt_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_updatedAt_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_createdAt"]: AliasType<{
	create?:ValueTypes["EventsFields_createdAt_Create"],
	read?:ValueTypes["EventsFields_createdAt_Read"],
	update?:ValueTypes["EventsFields_createdAt_Update"],
	delete?:ValueTypes["EventsFields_createdAt_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_createdAt_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_createdAt_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_createdAt_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_createdAt_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsCreateAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsReadAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsUpdateAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDeleteAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["locationsAccess"]: AliasType<{
	fields?:ValueTypes["LocationsFields"],
	create?:ValueTypes["LocationsCreateAccess"],
	read?:ValueTypes["LocationsReadAccess"],
	update?:ValueTypes["LocationsUpdateAccess"],
	delete?:ValueTypes["LocationsDeleteAccess"],
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields"]: AliasType<{
	region?:ValueTypes["LocationsFields_region"],
	creator?:ValueTypes["LocationsFields_creator"],
	permalink?:ValueTypes["LocationsFields_permalink"],
	status?:ValueTypes["LocationsFields_status"],
	level?:ValueTypes["LocationsFields_level"],
	parent?:ValueTypes["LocationsFields_parent"],
	name?:ValueTypes["LocationsFields_name"],
	thumbnail?:ValueTypes["LocationsFields_thumbnail"],
	banner?:ValueTypes["LocationsFields_banner"],
	type?:ValueTypes["LocationsFields_type"],
	category?:ValueTypes["LocationsFields_category"],
	district?:ValueTypes["LocationsFields_district"],
	coordinate?:ValueTypes["LocationsFields_coordinate"],
	linkAddress?:ValueTypes["LocationsFields_linkAddress"],
	address?:ValueTypes["LocationsFields_address"],
	Details?:ValueTypes["LocationsFields_Details"],
	content?:ValueTypes["LocationsFields_content"],
	legacyContent?:ValueTypes["LocationsFields_legacyContent"],
	showLegacyContent?:ValueTypes["LocationsFields_showLegacyContent"],
	criteria?:ValueTypes["LocationsFields_criteria"],
	sections?:ValueTypes["LocationsFields_sections"],
	_title?:ValueTypes["LocationsFields__title"],
	legacyGallery?:ValueTypes["LocationsFields_legacyGallery"],
	updatedAt?:ValueTypes["LocationsFields_updatedAt"],
	createdAt?:ValueTypes["LocationsFields_createdAt"],
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields_region"]: AliasType<{
	create?:ValueTypes["LocationsFields_region_Create"],
	read?:ValueTypes["LocationsFields_region_Read"],
	update?:ValueTypes["LocationsFields_region_Update"],
	delete?:ValueTypes["LocationsFields_region_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields_region_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields_region_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields_region_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields_region_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields_creator"]: AliasType<{
	create?:ValueTypes["LocationsFields_creator_Create"],
	read?:ValueTypes["LocationsFields_creator_Read"],
	update?:ValueTypes["LocationsFields_creator_Update"],
	delete?:ValueTypes["LocationsFields_creator_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields_creator_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields_creator_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields_creator_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields_creator_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields_permalink"]: AliasType<{
	create?:ValueTypes["LocationsFields_permalink_Create"],
	read?:ValueTypes["LocationsFields_permalink_Read"],
	update?:ValueTypes["LocationsFields_permalink_Update"],
	delete?:ValueTypes["LocationsFields_permalink_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields_permalink_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields_permalink_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields_permalink_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields_permalink_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields_status"]: AliasType<{
	create?:ValueTypes["LocationsFields_status_Create"],
	read?:ValueTypes["LocationsFields_status_Read"],
	update?:ValueTypes["LocationsFields_status_Update"],
	delete?:ValueTypes["LocationsFields_status_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields_status_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields_status_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields_status_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields_status_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields_level"]: AliasType<{
	create?:ValueTypes["LocationsFields_level_Create"],
	read?:ValueTypes["LocationsFields_level_Read"],
	update?:ValueTypes["LocationsFields_level_Update"],
	delete?:ValueTypes["LocationsFields_level_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields_level_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields_level_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields_level_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields_level_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields_parent"]: AliasType<{
	create?:ValueTypes["LocationsFields_parent_Create"],
	read?:ValueTypes["LocationsFields_parent_Read"],
	update?:ValueTypes["LocationsFields_parent_Update"],
	delete?:ValueTypes["LocationsFields_parent_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields_parent_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields_parent_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields_parent_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields_parent_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields_name"]: AliasType<{
	create?:ValueTypes["LocationsFields_name_Create"],
	read?:ValueTypes["LocationsFields_name_Read"],
	update?:ValueTypes["LocationsFields_name_Update"],
	delete?:ValueTypes["LocationsFields_name_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields_name_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields_name_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields_name_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields_name_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields_thumbnail"]: AliasType<{
	create?:ValueTypes["LocationsFields_thumbnail_Create"],
	read?:ValueTypes["LocationsFields_thumbnail_Read"],
	update?:ValueTypes["LocationsFields_thumbnail_Update"],
	delete?:ValueTypes["LocationsFields_thumbnail_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields_thumbnail_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields_thumbnail_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields_thumbnail_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields_thumbnail_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields_banner"]: AliasType<{
	create?:ValueTypes["LocationsFields_banner_Create"],
	read?:ValueTypes["LocationsFields_banner_Read"],
	update?:ValueTypes["LocationsFields_banner_Update"],
	delete?:ValueTypes["LocationsFields_banner_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields_banner_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields_banner_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields_banner_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields_banner_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields_type"]: AliasType<{
	create?:ValueTypes["LocationsFields_type_Create"],
	read?:ValueTypes["LocationsFields_type_Read"],
	update?:ValueTypes["LocationsFields_type_Update"],
	delete?:ValueTypes["LocationsFields_type_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields_type_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields_type_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields_type_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields_type_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields_category"]: AliasType<{
	create?:ValueTypes["LocationsFields_category_Create"],
	read?:ValueTypes["LocationsFields_category_Read"],
	update?:ValueTypes["LocationsFields_category_Update"],
	delete?:ValueTypes["LocationsFields_category_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields_category_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields_category_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields_category_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields_category_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields_district"]: AliasType<{
	create?:ValueTypes["LocationsFields_district_Create"],
	read?:ValueTypes["LocationsFields_district_Read"],
	update?:ValueTypes["LocationsFields_district_Update"],
	delete?:ValueTypes["LocationsFields_district_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields_district_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields_district_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields_district_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields_district_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields_coordinate"]: AliasType<{
	create?:ValueTypes["LocationsFields_coordinate_Create"],
	read?:ValueTypes["LocationsFields_coordinate_Read"],
	update?:ValueTypes["LocationsFields_coordinate_Update"],
	delete?:ValueTypes["LocationsFields_coordinate_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields_coordinate_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields_coordinate_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields_coordinate_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields_coordinate_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields_linkAddress"]: AliasType<{
	create?:ValueTypes["LocationsFields_linkAddress_Create"],
	read?:ValueTypes["LocationsFields_linkAddress_Read"],
	update?:ValueTypes["LocationsFields_linkAddress_Update"],
	delete?:ValueTypes["LocationsFields_linkAddress_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields_linkAddress_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields_linkAddress_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields_linkAddress_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields_linkAddress_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields_address"]: AliasType<{
	create?:ValueTypes["LocationsFields_address_Create"],
	read?:ValueTypes["LocationsFields_address_Read"],
	update?:ValueTypes["LocationsFields_address_Update"],
	delete?:ValueTypes["LocationsFields_address_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields_address_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields_address_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields_address_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields_address_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields_Details"]: AliasType<{
	create?:ValueTypes["LocationsFields_Details_Create"],
	read?:ValueTypes["LocationsFields_Details_Read"],
	update?:ValueTypes["LocationsFields_Details_Update"],
	delete?:ValueTypes["LocationsFields_Details_Delete"],
	fields?:ValueTypes["LocationsFields_Details_Fields"],
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields_Details_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields_Details_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields_Details_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields_Details_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields_Details_Fields"]: AliasType<{
	code?:ValueTypes["LocationsFields_Details_code"],
	phone?:ValueTypes["LocationsFields_Details_phone"],
	page?:ValueTypes["LocationsFields_Details_page"],
	email?:ValueTypes["LocationsFields_Details_email"],
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields_Details_code"]: AliasType<{
	create?:ValueTypes["LocationsFields_Details_code_Create"],
	read?:ValueTypes["LocationsFields_Details_code_Read"],
	update?:ValueTypes["LocationsFields_Details_code_Update"],
	delete?:ValueTypes["LocationsFields_Details_code_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields_Details_code_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields_Details_code_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields_Details_code_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields_Details_code_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields_Details_phone"]: AliasType<{
	create?:ValueTypes["LocationsFields_Details_phone_Create"],
	read?:ValueTypes["LocationsFields_Details_phone_Read"],
	update?:ValueTypes["LocationsFields_Details_phone_Update"],
	delete?:ValueTypes["LocationsFields_Details_phone_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields_Details_phone_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields_Details_phone_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields_Details_phone_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields_Details_phone_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields_Details_page"]: AliasType<{
	create?:ValueTypes["LocationsFields_Details_page_Create"],
	read?:ValueTypes["LocationsFields_Details_page_Read"],
	update?:ValueTypes["LocationsFields_Details_page_Update"],
	delete?:ValueTypes["LocationsFields_Details_page_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields_Details_page_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields_Details_page_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields_Details_page_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields_Details_page_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields_Details_email"]: AliasType<{
	create?:ValueTypes["LocationsFields_Details_email_Create"],
	read?:ValueTypes["LocationsFields_Details_email_Read"],
	update?:ValueTypes["LocationsFields_Details_email_Update"],
	delete?:ValueTypes["LocationsFields_Details_email_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields_Details_email_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields_Details_email_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields_Details_email_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields_Details_email_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields_content"]: AliasType<{
	create?:ValueTypes["LocationsFields_content_Create"],
	read?:ValueTypes["LocationsFields_content_Read"],
	update?:ValueTypes["LocationsFields_content_Update"],
	delete?:ValueTypes["LocationsFields_content_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields_content_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields_content_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields_content_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields_content_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields_legacyContent"]: AliasType<{
	create?:ValueTypes["LocationsFields_legacyContent_Create"],
	read?:ValueTypes["LocationsFields_legacyContent_Read"],
	update?:ValueTypes["LocationsFields_legacyContent_Update"],
	delete?:ValueTypes["LocationsFields_legacyContent_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields_legacyContent_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields_legacyContent_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields_legacyContent_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields_legacyContent_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields_showLegacyContent"]: AliasType<{
	create?:ValueTypes["LocationsFields_showLegacyContent_Create"],
	read?:ValueTypes["LocationsFields_showLegacyContent_Read"],
	update?:ValueTypes["LocationsFields_showLegacyContent_Update"],
	delete?:ValueTypes["LocationsFields_showLegacyContent_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields_showLegacyContent_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields_showLegacyContent_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields_showLegacyContent_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields_showLegacyContent_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields_criteria"]: AliasType<{
	create?:ValueTypes["LocationsFields_criteria_Create"],
	read?:ValueTypes["LocationsFields_criteria_Read"],
	update?:ValueTypes["LocationsFields_criteria_Update"],
	delete?:ValueTypes["LocationsFields_criteria_Delete"],
	fields?:ValueTypes["LocationsFields_criteria_Fields"],
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields_criteria_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields_criteria_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields_criteria_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields_criteria_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields_criteria_Fields"]: AliasType<{
	audiences?:ValueTypes["LocationsFields_criteria_audiences"],
	dates?:ValueTypes["LocationsFields_criteria_dates"],
	categories?:ValueTypes["LocationsFields_criteria_categories"],
	tags?:ValueTypes["LocationsFields_criteria_tags"],
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields_criteria_audiences"]: AliasType<{
	create?:ValueTypes["LocationsFields_criteria_audiences_Create"],
	read?:ValueTypes["LocationsFields_criteria_audiences_Read"],
	update?:ValueTypes["LocationsFields_criteria_audiences_Update"],
	delete?:ValueTypes["LocationsFields_criteria_audiences_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields_criteria_audiences_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields_criteria_audiences_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields_criteria_audiences_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields_criteria_audiences_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields_criteria_dates"]: AliasType<{
	create?:ValueTypes["LocationsFields_criteria_dates_Create"],
	read?:ValueTypes["LocationsFields_criteria_dates_Read"],
	update?:ValueTypes["LocationsFields_criteria_dates_Update"],
	delete?:ValueTypes["LocationsFields_criteria_dates_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields_criteria_dates_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields_criteria_dates_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields_criteria_dates_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields_criteria_dates_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields_criteria_categories"]: AliasType<{
	create?:ValueTypes["LocationsFields_criteria_categories_Create"],
	read?:ValueTypes["LocationsFields_criteria_categories_Read"],
	update?:ValueTypes["LocationsFields_criteria_categories_Update"],
	delete?:ValueTypes["LocationsFields_criteria_categories_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields_criteria_categories_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields_criteria_categories_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields_criteria_categories_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields_criteria_categories_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields_criteria_tags"]: AliasType<{
	create?:ValueTypes["LocationsFields_criteria_tags_Create"],
	read?:ValueTypes["LocationsFields_criteria_tags_Read"],
	update?:ValueTypes["LocationsFields_criteria_tags_Update"],
	delete?:ValueTypes["LocationsFields_criteria_tags_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields_criteria_tags_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields_criteria_tags_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields_criteria_tags_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields_criteria_tags_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields_sections"]: AliasType<{
	create?:ValueTypes["LocationsFields_sections_Create"],
	read?:ValueTypes["LocationsFields_sections_Read"],
	update?:ValueTypes["LocationsFields_sections_Update"],
	delete?:ValueTypes["LocationsFields_sections_Delete"],
	fields?:ValueTypes["LocationsFields_sections_Fields"],
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields_sections_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields_sections_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields_sections_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields_sections_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields_sections_Fields"]: AliasType<{
	startDatetime?:ValueTypes["LocationsFields_sections_startDatetime"],
	endDatetime?:ValueTypes["LocationsFields_sections_endDatetime"],
	fullDay?:ValueTypes["LocationsFields_sections_fullDay"],
	repeat?:ValueTypes["LocationsFields_sections_repeat"],
	recurrance?:ValueTypes["LocationsFields_sections_recurrance"],
	id?:ValueTypes["LocationsFields_sections_id"],
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields_sections_startDatetime"]: AliasType<{
	create?:ValueTypes["LocationsFields_sections_startDatetime_Create"],
	read?:ValueTypes["LocationsFields_sections_startDatetime_Read"],
	update?:ValueTypes["LocationsFields_sections_startDatetime_Update"],
	delete?:ValueTypes["LocationsFields_sections_startDatetime_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields_sections_startDatetime_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields_sections_startDatetime_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields_sections_startDatetime_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields_sections_startDatetime_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields_sections_endDatetime"]: AliasType<{
	create?:ValueTypes["LocationsFields_sections_endDatetime_Create"],
	read?:ValueTypes["LocationsFields_sections_endDatetime_Read"],
	update?:ValueTypes["LocationsFields_sections_endDatetime_Update"],
	delete?:ValueTypes["LocationsFields_sections_endDatetime_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields_sections_endDatetime_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields_sections_endDatetime_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields_sections_endDatetime_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields_sections_endDatetime_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields_sections_fullDay"]: AliasType<{
	create?:ValueTypes["LocationsFields_sections_fullDay_Create"],
	read?:ValueTypes["LocationsFields_sections_fullDay_Read"],
	update?:ValueTypes["LocationsFields_sections_fullDay_Update"],
	delete?:ValueTypes["LocationsFields_sections_fullDay_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields_sections_fullDay_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields_sections_fullDay_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields_sections_fullDay_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields_sections_fullDay_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields_sections_repeat"]: AliasType<{
	create?:ValueTypes["LocationsFields_sections_repeat_Create"],
	read?:ValueTypes["LocationsFields_sections_repeat_Read"],
	update?:ValueTypes["LocationsFields_sections_repeat_Update"],
	delete?:ValueTypes["LocationsFields_sections_repeat_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields_sections_repeat_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields_sections_repeat_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields_sections_repeat_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields_sections_repeat_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields_sections_recurrance"]: AliasType<{
	create?:ValueTypes["LocationsFields_sections_recurrance_Create"],
	read?:ValueTypes["LocationsFields_sections_recurrance_Read"],
	update?:ValueTypes["LocationsFields_sections_recurrance_Update"],
	delete?:ValueTypes["LocationsFields_sections_recurrance_Delete"],
	fields?:ValueTypes["LocationsFields_sections_recurrance_Fields"],
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields_sections_recurrance_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields_sections_recurrance_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields_sections_recurrance_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields_sections_recurrance_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields_sections_recurrance_Fields"]: AliasType<{
	type?:ValueTypes["LocationsFields_sections_recurrance_type"],
	weekday?:ValueTypes["LocationsFields_sections_recurrance_weekday"],
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields_sections_recurrance_type"]: AliasType<{
	create?:ValueTypes["LocationsFields_sections_recurrance_type_Create"],
	read?:ValueTypes["LocationsFields_sections_recurrance_type_Read"],
	update?:ValueTypes["LocationsFields_sections_recurrance_type_Update"],
	delete?:ValueTypes["LocationsFields_sections_recurrance_type_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields_sections_recurrance_type_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields_sections_recurrance_type_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields_sections_recurrance_type_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields_sections_recurrance_type_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields_sections_recurrance_weekday"]: AliasType<{
	create?:ValueTypes["LocationsFields_sections_recurrance_weekday_Create"],
	read?:ValueTypes["LocationsFields_sections_recurrance_weekday_Read"],
	update?:ValueTypes["LocationsFields_sections_recurrance_weekday_Update"],
	delete?:ValueTypes["LocationsFields_sections_recurrance_weekday_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields_sections_recurrance_weekday_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields_sections_recurrance_weekday_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields_sections_recurrance_weekday_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields_sections_recurrance_weekday_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields_sections_id"]: AliasType<{
	create?:ValueTypes["LocationsFields_sections_id_Create"],
	read?:ValueTypes["LocationsFields_sections_id_Read"],
	update?:ValueTypes["LocationsFields_sections_id_Update"],
	delete?:ValueTypes["LocationsFields_sections_id_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields_sections_id_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields_sections_id_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields_sections_id_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields_sections_id_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields__title"]: AliasType<{
	create?:ValueTypes["LocationsFields__title_Create"],
	read?:ValueTypes["LocationsFields__title_Read"],
	update?:ValueTypes["LocationsFields__title_Update"],
	delete?:ValueTypes["LocationsFields__title_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields__title_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields__title_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields__title_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields__title_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields_legacyGallery"]: AliasType<{
	create?:ValueTypes["LocationsFields_legacyGallery_Create"],
	read?:ValueTypes["LocationsFields_legacyGallery_Read"],
	update?:ValueTypes["LocationsFields_legacyGallery_Update"],
	delete?:ValueTypes["LocationsFields_legacyGallery_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields_legacyGallery_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields_legacyGallery_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields_legacyGallery_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields_legacyGallery_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields_updatedAt"]: AliasType<{
	create?:ValueTypes["LocationsFields_updatedAt_Create"],
	read?:ValueTypes["LocationsFields_updatedAt_Read"],
	update?:ValueTypes["LocationsFields_updatedAt_Update"],
	delete?:ValueTypes["LocationsFields_updatedAt_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields_updatedAt_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields_updatedAt_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields_updatedAt_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields_updatedAt_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields_createdAt"]: AliasType<{
	create?:ValueTypes["LocationsFields_createdAt_Create"],
	read?:ValueTypes["LocationsFields_createdAt_Read"],
	update?:ValueTypes["LocationsFields_createdAt_Update"],
	delete?:ValueTypes["LocationsFields_createdAt_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields_createdAt_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields_createdAt_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields_createdAt_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields_createdAt_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsCreateAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsReadAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsUpdateAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsDeleteAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["postsAccess"]: AliasType<{
	fields?:ValueTypes["PostsFields"],
	create?:ValueTypes["PostsCreateAccess"],
	read?:ValueTypes["PostsReadAccess"],
	update?:ValueTypes["PostsUpdateAccess"],
	delete?:ValueTypes["PostsDeleteAccess"],
		__typename?: boolean | `@${string}`
}>;
	["PostsFields"]: AliasType<{
	title?:ValueTypes["PostsFields_title"],
	region?:ValueTypes["PostsFields_region"],
	authur?:ValueTypes["PostsFields_authur"],
	publishDateTime?:ValueTypes["PostsFields_publishDateTime"],
	permalink?:ValueTypes["PostsFields_permalink"],
	status?:ValueTypes["PostsFields_status"],
	content?:ValueTypes["PostsFields_content"],
	legacyContent?:ValueTypes["PostsFields_legacyContent"],
	showLegacyContent?:ValueTypes["PostsFields_showLegacyContent"],
	blogCategories?:ValueTypes["PostsFields_blogCategories"],
	thumbnail?:ValueTypes["PostsFields_thumbnail"],
	banner?:ValueTypes["PostsFields_banner"],
	criteria?:ValueTypes["PostsFields_criteria"],
	location?:ValueTypes["PostsFields_location"],
	homePageInternalClick?:ValueTypes["PostsFields_homePageInternalClick"],
	homePageImpression?:ValueTypes["PostsFields_homePageImpression"],
	blogListInternalClick?:ValueTypes["PostsFields_blogListInternalClick"],
	blogListImpression?:ValueTypes["PostsFields_blogListImpression"],
	blogDetailInternalClick?:ValueTypes["PostsFields_blogDetailInternalClick"],
	blogDetailImpression?:ValueTypes["PostsFields_blogDetailImpression"],
	relatedEventInternalClick?:ValueTypes["PostsFields_relatedEventInternalClick"],
	relatedEventImpression?:ValueTypes["PostsFields_relatedEventImpression"],
	latestEventInternalClick?:ValueTypes["PostsFields_latestEventInternalClick"],
	latestEventImpression?:ValueTypes["PostsFields_latestEventImpression"],
	internalClick?:ValueTypes["PostsFields_internalClick"],
	totalImpression?:ValueTypes["PostsFields_totalImpression"],
	externalClick?:ValueTypes["PostsFields_externalClick"],
	outboundClick?:ValueTypes["PostsFields_outboundClick"],
	legacyGallery?:ValueTypes["PostsFields_legacyGallery"],
	updatedAt?:ValueTypes["PostsFields_updatedAt"],
	createdAt?:ValueTypes["PostsFields_createdAt"],
		__typename?: boolean | `@${string}`
}>;
	["PostsFields_title"]: AliasType<{
	create?:ValueTypes["PostsFields_title_Create"],
	read?:ValueTypes["PostsFields_title_Read"],
	update?:ValueTypes["PostsFields_title_Update"],
	delete?:ValueTypes["PostsFields_title_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["PostsFields_title_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsFields_title_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsFields_title_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsFields_title_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsFields_region"]: AliasType<{
	create?:ValueTypes["PostsFields_region_Create"],
	read?:ValueTypes["PostsFields_region_Read"],
	update?:ValueTypes["PostsFields_region_Update"],
	delete?:ValueTypes["PostsFields_region_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["PostsFields_region_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsFields_region_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsFields_region_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsFields_region_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsFields_authur"]: AliasType<{
	create?:ValueTypes["PostsFields_authur_Create"],
	read?:ValueTypes["PostsFields_authur_Read"],
	update?:ValueTypes["PostsFields_authur_Update"],
	delete?:ValueTypes["PostsFields_authur_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["PostsFields_authur_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsFields_authur_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsFields_authur_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsFields_authur_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsFields_publishDateTime"]: AliasType<{
	create?:ValueTypes["PostsFields_publishDateTime_Create"],
	read?:ValueTypes["PostsFields_publishDateTime_Read"],
	update?:ValueTypes["PostsFields_publishDateTime_Update"],
	delete?:ValueTypes["PostsFields_publishDateTime_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["PostsFields_publishDateTime_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsFields_publishDateTime_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsFields_publishDateTime_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsFields_publishDateTime_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsFields_permalink"]: AliasType<{
	create?:ValueTypes["PostsFields_permalink_Create"],
	read?:ValueTypes["PostsFields_permalink_Read"],
	update?:ValueTypes["PostsFields_permalink_Update"],
	delete?:ValueTypes["PostsFields_permalink_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["PostsFields_permalink_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsFields_permalink_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsFields_permalink_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsFields_permalink_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsFields_status"]: AliasType<{
	create?:ValueTypes["PostsFields_status_Create"],
	read?:ValueTypes["PostsFields_status_Read"],
	update?:ValueTypes["PostsFields_status_Update"],
	delete?:ValueTypes["PostsFields_status_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["PostsFields_status_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsFields_status_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsFields_status_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsFields_status_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsFields_content"]: AliasType<{
	create?:ValueTypes["PostsFields_content_Create"],
	read?:ValueTypes["PostsFields_content_Read"],
	update?:ValueTypes["PostsFields_content_Update"],
	delete?:ValueTypes["PostsFields_content_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["PostsFields_content_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsFields_content_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsFields_content_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsFields_content_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsFields_legacyContent"]: AliasType<{
	create?:ValueTypes["PostsFields_legacyContent_Create"],
	read?:ValueTypes["PostsFields_legacyContent_Read"],
	update?:ValueTypes["PostsFields_legacyContent_Update"],
	delete?:ValueTypes["PostsFields_legacyContent_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["PostsFields_legacyContent_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsFields_legacyContent_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsFields_legacyContent_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsFields_legacyContent_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsFields_showLegacyContent"]: AliasType<{
	create?:ValueTypes["PostsFields_showLegacyContent_Create"],
	read?:ValueTypes["PostsFields_showLegacyContent_Read"],
	update?:ValueTypes["PostsFields_showLegacyContent_Update"],
	delete?:ValueTypes["PostsFields_showLegacyContent_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["PostsFields_showLegacyContent_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsFields_showLegacyContent_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsFields_showLegacyContent_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsFields_showLegacyContent_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsFields_blogCategories"]: AliasType<{
	create?:ValueTypes["PostsFields_blogCategories_Create"],
	read?:ValueTypes["PostsFields_blogCategories_Read"],
	update?:ValueTypes["PostsFields_blogCategories_Update"],
	delete?:ValueTypes["PostsFields_blogCategories_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["PostsFields_blogCategories_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsFields_blogCategories_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsFields_blogCategories_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsFields_blogCategories_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsFields_thumbnail"]: AliasType<{
	create?:ValueTypes["PostsFields_thumbnail_Create"],
	read?:ValueTypes["PostsFields_thumbnail_Read"],
	update?:ValueTypes["PostsFields_thumbnail_Update"],
	delete?:ValueTypes["PostsFields_thumbnail_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["PostsFields_thumbnail_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsFields_thumbnail_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsFields_thumbnail_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsFields_thumbnail_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsFields_banner"]: AliasType<{
	create?:ValueTypes["PostsFields_banner_Create"],
	read?:ValueTypes["PostsFields_banner_Read"],
	update?:ValueTypes["PostsFields_banner_Update"],
	delete?:ValueTypes["PostsFields_banner_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["PostsFields_banner_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsFields_banner_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsFields_banner_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsFields_banner_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsFields_criteria"]: AliasType<{
	create?:ValueTypes["PostsFields_criteria_Create"],
	read?:ValueTypes["PostsFields_criteria_Read"],
	update?:ValueTypes["PostsFields_criteria_Update"],
	delete?:ValueTypes["PostsFields_criteria_Delete"],
	fields?:ValueTypes["PostsFields_criteria_Fields"],
		__typename?: boolean | `@${string}`
}>;
	["PostsFields_criteria_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsFields_criteria_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsFields_criteria_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsFields_criteria_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsFields_criteria_Fields"]: AliasType<{
	audiences?:ValueTypes["PostsFields_criteria_audiences"],
	dates?:ValueTypes["PostsFields_criteria_dates"],
	categories?:ValueTypes["PostsFields_criteria_categories"],
	tags?:ValueTypes["PostsFields_criteria_tags"],
		__typename?: boolean | `@${string}`
}>;
	["PostsFields_criteria_audiences"]: AliasType<{
	create?:ValueTypes["PostsFields_criteria_audiences_Create"],
	read?:ValueTypes["PostsFields_criteria_audiences_Read"],
	update?:ValueTypes["PostsFields_criteria_audiences_Update"],
	delete?:ValueTypes["PostsFields_criteria_audiences_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["PostsFields_criteria_audiences_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsFields_criteria_audiences_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsFields_criteria_audiences_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsFields_criteria_audiences_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsFields_criteria_dates"]: AliasType<{
	create?:ValueTypes["PostsFields_criteria_dates_Create"],
	read?:ValueTypes["PostsFields_criteria_dates_Read"],
	update?:ValueTypes["PostsFields_criteria_dates_Update"],
	delete?:ValueTypes["PostsFields_criteria_dates_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["PostsFields_criteria_dates_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsFields_criteria_dates_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsFields_criteria_dates_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsFields_criteria_dates_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsFields_criteria_categories"]: AliasType<{
	create?:ValueTypes["PostsFields_criteria_categories_Create"],
	read?:ValueTypes["PostsFields_criteria_categories_Read"],
	update?:ValueTypes["PostsFields_criteria_categories_Update"],
	delete?:ValueTypes["PostsFields_criteria_categories_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["PostsFields_criteria_categories_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsFields_criteria_categories_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsFields_criteria_categories_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsFields_criteria_categories_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsFields_criteria_tags"]: AliasType<{
	create?:ValueTypes["PostsFields_criteria_tags_Create"],
	read?:ValueTypes["PostsFields_criteria_tags_Read"],
	update?:ValueTypes["PostsFields_criteria_tags_Update"],
	delete?:ValueTypes["PostsFields_criteria_tags_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["PostsFields_criteria_tags_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsFields_criteria_tags_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsFields_criteria_tags_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsFields_criteria_tags_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsFields_location"]: AliasType<{
	create?:ValueTypes["PostsFields_location_Create"],
	read?:ValueTypes["PostsFields_location_Read"],
	update?:ValueTypes["PostsFields_location_Update"],
	delete?:ValueTypes["PostsFields_location_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["PostsFields_location_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsFields_location_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsFields_location_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsFields_location_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsFields_homePageInternalClick"]: AliasType<{
	create?:ValueTypes["PostsFields_homePageInternalClick_Create"],
	read?:ValueTypes["PostsFields_homePageInternalClick_Read"],
	update?:ValueTypes["PostsFields_homePageInternalClick_Update"],
	delete?:ValueTypes["PostsFields_homePageInternalClick_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["PostsFields_homePageInternalClick_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsFields_homePageInternalClick_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsFields_homePageInternalClick_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsFields_homePageInternalClick_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsFields_homePageImpression"]: AliasType<{
	create?:ValueTypes["PostsFields_homePageImpression_Create"],
	read?:ValueTypes["PostsFields_homePageImpression_Read"],
	update?:ValueTypes["PostsFields_homePageImpression_Update"],
	delete?:ValueTypes["PostsFields_homePageImpression_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["PostsFields_homePageImpression_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsFields_homePageImpression_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsFields_homePageImpression_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsFields_homePageImpression_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsFields_blogListInternalClick"]: AliasType<{
	create?:ValueTypes["PostsFields_blogListInternalClick_Create"],
	read?:ValueTypes["PostsFields_blogListInternalClick_Read"],
	update?:ValueTypes["PostsFields_blogListInternalClick_Update"],
	delete?:ValueTypes["PostsFields_blogListInternalClick_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["PostsFields_blogListInternalClick_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsFields_blogListInternalClick_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsFields_blogListInternalClick_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsFields_blogListInternalClick_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsFields_blogListImpression"]: AliasType<{
	create?:ValueTypes["PostsFields_blogListImpression_Create"],
	read?:ValueTypes["PostsFields_blogListImpression_Read"],
	update?:ValueTypes["PostsFields_blogListImpression_Update"],
	delete?:ValueTypes["PostsFields_blogListImpression_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["PostsFields_blogListImpression_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsFields_blogListImpression_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsFields_blogListImpression_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsFields_blogListImpression_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsFields_blogDetailInternalClick"]: AliasType<{
	create?:ValueTypes["PostsFields_blogDetailInternalClick_Create"],
	read?:ValueTypes["PostsFields_blogDetailInternalClick_Read"],
	update?:ValueTypes["PostsFields_blogDetailInternalClick_Update"],
	delete?:ValueTypes["PostsFields_blogDetailInternalClick_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["PostsFields_blogDetailInternalClick_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsFields_blogDetailInternalClick_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsFields_blogDetailInternalClick_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsFields_blogDetailInternalClick_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsFields_blogDetailImpression"]: AliasType<{
	create?:ValueTypes["PostsFields_blogDetailImpression_Create"],
	read?:ValueTypes["PostsFields_blogDetailImpression_Read"],
	update?:ValueTypes["PostsFields_blogDetailImpression_Update"],
	delete?:ValueTypes["PostsFields_blogDetailImpression_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["PostsFields_blogDetailImpression_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsFields_blogDetailImpression_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsFields_blogDetailImpression_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsFields_blogDetailImpression_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsFields_relatedEventInternalClick"]: AliasType<{
	create?:ValueTypes["PostsFields_relatedEventInternalClick_Create"],
	read?:ValueTypes["PostsFields_relatedEventInternalClick_Read"],
	update?:ValueTypes["PostsFields_relatedEventInternalClick_Update"],
	delete?:ValueTypes["PostsFields_relatedEventInternalClick_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["PostsFields_relatedEventInternalClick_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsFields_relatedEventInternalClick_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsFields_relatedEventInternalClick_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsFields_relatedEventInternalClick_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsFields_relatedEventImpression"]: AliasType<{
	create?:ValueTypes["PostsFields_relatedEventImpression_Create"],
	read?:ValueTypes["PostsFields_relatedEventImpression_Read"],
	update?:ValueTypes["PostsFields_relatedEventImpression_Update"],
	delete?:ValueTypes["PostsFields_relatedEventImpression_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["PostsFields_relatedEventImpression_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsFields_relatedEventImpression_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsFields_relatedEventImpression_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsFields_relatedEventImpression_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsFields_latestEventInternalClick"]: AliasType<{
	create?:ValueTypes["PostsFields_latestEventInternalClick_Create"],
	read?:ValueTypes["PostsFields_latestEventInternalClick_Read"],
	update?:ValueTypes["PostsFields_latestEventInternalClick_Update"],
	delete?:ValueTypes["PostsFields_latestEventInternalClick_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["PostsFields_latestEventInternalClick_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsFields_latestEventInternalClick_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsFields_latestEventInternalClick_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsFields_latestEventInternalClick_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsFields_latestEventImpression"]: AliasType<{
	create?:ValueTypes["PostsFields_latestEventImpression_Create"],
	read?:ValueTypes["PostsFields_latestEventImpression_Read"],
	update?:ValueTypes["PostsFields_latestEventImpression_Update"],
	delete?:ValueTypes["PostsFields_latestEventImpression_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["PostsFields_latestEventImpression_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsFields_latestEventImpression_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsFields_latestEventImpression_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsFields_latestEventImpression_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsFields_internalClick"]: AliasType<{
	create?:ValueTypes["PostsFields_internalClick_Create"],
	read?:ValueTypes["PostsFields_internalClick_Read"],
	update?:ValueTypes["PostsFields_internalClick_Update"],
	delete?:ValueTypes["PostsFields_internalClick_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["PostsFields_internalClick_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsFields_internalClick_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsFields_internalClick_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsFields_internalClick_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsFields_totalImpression"]: AliasType<{
	create?:ValueTypes["PostsFields_totalImpression_Create"],
	read?:ValueTypes["PostsFields_totalImpression_Read"],
	update?:ValueTypes["PostsFields_totalImpression_Update"],
	delete?:ValueTypes["PostsFields_totalImpression_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["PostsFields_totalImpression_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsFields_totalImpression_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsFields_totalImpression_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsFields_totalImpression_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsFields_externalClick"]: AliasType<{
	create?:ValueTypes["PostsFields_externalClick_Create"],
	read?:ValueTypes["PostsFields_externalClick_Read"],
	update?:ValueTypes["PostsFields_externalClick_Update"],
	delete?:ValueTypes["PostsFields_externalClick_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["PostsFields_externalClick_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsFields_externalClick_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsFields_externalClick_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsFields_externalClick_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsFields_outboundClick"]: AliasType<{
	create?:ValueTypes["PostsFields_outboundClick_Create"],
	read?:ValueTypes["PostsFields_outboundClick_Read"],
	update?:ValueTypes["PostsFields_outboundClick_Update"],
	delete?:ValueTypes["PostsFields_outboundClick_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["PostsFields_outboundClick_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsFields_outboundClick_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsFields_outboundClick_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsFields_outboundClick_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsFields_legacyGallery"]: AliasType<{
	create?:ValueTypes["PostsFields_legacyGallery_Create"],
	read?:ValueTypes["PostsFields_legacyGallery_Read"],
	update?:ValueTypes["PostsFields_legacyGallery_Update"],
	delete?:ValueTypes["PostsFields_legacyGallery_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["PostsFields_legacyGallery_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsFields_legacyGallery_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsFields_legacyGallery_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsFields_legacyGallery_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsFields_updatedAt"]: AliasType<{
	create?:ValueTypes["PostsFields_updatedAt_Create"],
	read?:ValueTypes["PostsFields_updatedAt_Read"],
	update?:ValueTypes["PostsFields_updatedAt_Update"],
	delete?:ValueTypes["PostsFields_updatedAt_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["PostsFields_updatedAt_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsFields_updatedAt_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsFields_updatedAt_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsFields_updatedAt_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsFields_createdAt"]: AliasType<{
	create?:ValueTypes["PostsFields_createdAt_Create"],
	read?:ValueTypes["PostsFields_createdAt_Read"],
	update?:ValueTypes["PostsFields_createdAt_Update"],
	delete?:ValueTypes["PostsFields_createdAt_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["PostsFields_createdAt_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsFields_createdAt_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsFields_createdAt_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsFields_createdAt_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsCreateAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsReadAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsUpdateAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsDeleteAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ticketingAccess"]: AliasType<{
	fields?:ValueTypes["TicketingFields"],
	create?:ValueTypes["TicketingCreateAccess"],
	read?:ValueTypes["TicketingReadAccess"],
	update?:ValueTypes["TicketingUpdateAccess"],
	delete?:ValueTypes["TicketingDeleteAccess"],
		__typename?: boolean | `@${string}`
}>;
	["TicketingFields"]: AliasType<{
	status?:ValueTypes["TicketingFields_status"],
	creator?:ValueTypes["TicketingFields_creator"],
	productType?:ValueTypes["TicketingFields_productType"],
	productName?:ValueTypes["TicketingFields_productName"],
	referenceId?:ValueTypes["TicketingFields_referenceId"],
	linkedEvent?:ValueTypes["TicketingFields_linkedEvent"],
	CheckInLevel?:ValueTypes["TicketingFields_CheckInLevel"],
	displayPrice?:ValueTypes["TicketingFields_displayPrice"],
	tnc?:ValueTypes["TicketingFields_tnc"],
	cutoffHours?:ValueTypes["TicketingFields_cutoffHours"],
	sessions?:ValueTypes["TicketingFields_sessions"],
	updatedAt?:ValueTypes["TicketingFields_updatedAt"],
	createdAt?:ValueTypes["TicketingFields_createdAt"],
		__typename?: boolean | `@${string}`
}>;
	["TicketingFields_status"]: AliasType<{
	create?:ValueTypes["TicketingFields_status_Create"],
	read?:ValueTypes["TicketingFields_status_Read"],
	update?:ValueTypes["TicketingFields_status_Update"],
	delete?:ValueTypes["TicketingFields_status_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["TicketingFields_status_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingFields_status_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingFields_status_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingFields_status_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingFields_creator"]: AliasType<{
	create?:ValueTypes["TicketingFields_creator_Create"],
	read?:ValueTypes["TicketingFields_creator_Read"],
	update?:ValueTypes["TicketingFields_creator_Update"],
	delete?:ValueTypes["TicketingFields_creator_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["TicketingFields_creator_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingFields_creator_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingFields_creator_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingFields_creator_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingFields_productType"]: AliasType<{
	create?:ValueTypes["TicketingFields_productType_Create"],
	read?:ValueTypes["TicketingFields_productType_Read"],
	update?:ValueTypes["TicketingFields_productType_Update"],
	delete?:ValueTypes["TicketingFields_productType_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["TicketingFields_productType_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingFields_productType_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingFields_productType_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingFields_productType_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingFields_productName"]: AliasType<{
	create?:ValueTypes["TicketingFields_productName_Create"],
	read?:ValueTypes["TicketingFields_productName_Read"],
	update?:ValueTypes["TicketingFields_productName_Update"],
	delete?:ValueTypes["TicketingFields_productName_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["TicketingFields_productName_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingFields_productName_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingFields_productName_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingFields_productName_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingFields_referenceId"]: AliasType<{
	create?:ValueTypes["TicketingFields_referenceId_Create"],
	read?:ValueTypes["TicketingFields_referenceId_Read"],
	update?:ValueTypes["TicketingFields_referenceId_Update"],
	delete?:ValueTypes["TicketingFields_referenceId_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["TicketingFields_referenceId_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingFields_referenceId_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingFields_referenceId_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingFields_referenceId_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingFields_linkedEvent"]: AliasType<{
	create?:ValueTypes["TicketingFields_linkedEvent_Create"],
	read?:ValueTypes["TicketingFields_linkedEvent_Read"],
	update?:ValueTypes["TicketingFields_linkedEvent_Update"],
	delete?:ValueTypes["TicketingFields_linkedEvent_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["TicketingFields_linkedEvent_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingFields_linkedEvent_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingFields_linkedEvent_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingFields_linkedEvent_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingFields_CheckInLevel"]: AliasType<{
	create?:ValueTypes["TicketingFields_CheckInLevel_Create"],
	read?:ValueTypes["TicketingFields_CheckInLevel_Read"],
	update?:ValueTypes["TicketingFields_CheckInLevel_Update"],
	delete?:ValueTypes["TicketingFields_CheckInLevel_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["TicketingFields_CheckInLevel_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingFields_CheckInLevel_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingFields_CheckInLevel_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingFields_CheckInLevel_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingFields_displayPrice"]: AliasType<{
	create?:ValueTypes["TicketingFields_displayPrice_Create"],
	read?:ValueTypes["TicketingFields_displayPrice_Read"],
	update?:ValueTypes["TicketingFields_displayPrice_Update"],
	delete?:ValueTypes["TicketingFields_displayPrice_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["TicketingFields_displayPrice_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingFields_displayPrice_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingFields_displayPrice_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingFields_displayPrice_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingFields_tnc"]: AliasType<{
	create?:ValueTypes["TicketingFields_tnc_Create"],
	read?:ValueTypes["TicketingFields_tnc_Read"],
	update?:ValueTypes["TicketingFields_tnc_Update"],
	delete?:ValueTypes["TicketingFields_tnc_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["TicketingFields_tnc_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingFields_tnc_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingFields_tnc_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingFields_tnc_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingFields_cutoffHours"]: AliasType<{
	create?:ValueTypes["TicketingFields_cutoffHours_Create"],
	read?:ValueTypes["TicketingFields_cutoffHours_Read"],
	update?:ValueTypes["TicketingFields_cutoffHours_Update"],
	delete?:ValueTypes["TicketingFields_cutoffHours_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["TicketingFields_cutoffHours_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingFields_cutoffHours_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingFields_cutoffHours_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingFields_cutoffHours_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingFields_sessions"]: AliasType<{
	create?:ValueTypes["TicketingFields_sessions_Create"],
	read?:ValueTypes["TicketingFields_sessions_Read"],
	update?:ValueTypes["TicketingFields_sessions_Update"],
	delete?:ValueTypes["TicketingFields_sessions_Delete"],
	fields?:ValueTypes["TicketingFields_sessions_Fields"],
		__typename?: boolean | `@${string}`
}>;
	["TicketingFields_sessions_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingFields_sessions_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingFields_sessions_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingFields_sessions_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingFields_sessions_Fields"]: AliasType<{
	productId?:ValueTypes["TicketingFields_sessions_productId"],
	startDate?:ValueTypes["TicketingFields_sessions_startDate"],
	endDate?:ValueTypes["TicketingFields_sessions_endDate"],
	inventories?:ValueTypes["TicketingFields_sessions_inventories"],
	id?:ValueTypes["TicketingFields_sessions_id"],
		__typename?: boolean | `@${string}`
}>;
	["TicketingFields_sessions_productId"]: AliasType<{
	create?:ValueTypes["TicketingFields_sessions_productId_Create"],
	read?:ValueTypes["TicketingFields_sessions_productId_Read"],
	update?:ValueTypes["TicketingFields_sessions_productId_Update"],
	delete?:ValueTypes["TicketingFields_sessions_productId_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["TicketingFields_sessions_productId_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingFields_sessions_productId_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingFields_sessions_productId_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingFields_sessions_productId_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingFields_sessions_startDate"]: AliasType<{
	create?:ValueTypes["TicketingFields_sessions_startDate_Create"],
	read?:ValueTypes["TicketingFields_sessions_startDate_Read"],
	update?:ValueTypes["TicketingFields_sessions_startDate_Update"],
	delete?:ValueTypes["TicketingFields_sessions_startDate_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["TicketingFields_sessions_startDate_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingFields_sessions_startDate_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingFields_sessions_startDate_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingFields_sessions_startDate_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingFields_sessions_endDate"]: AliasType<{
	create?:ValueTypes["TicketingFields_sessions_endDate_Create"],
	read?:ValueTypes["TicketingFields_sessions_endDate_Read"],
	update?:ValueTypes["TicketingFields_sessions_endDate_Update"],
	delete?:ValueTypes["TicketingFields_sessions_endDate_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["TicketingFields_sessions_endDate_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingFields_sessions_endDate_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingFields_sessions_endDate_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingFields_sessions_endDate_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingFields_sessions_inventories"]: AliasType<{
	create?:ValueTypes["TicketingFields_sessions_inventories_Create"],
	read?:ValueTypes["TicketingFields_sessions_inventories_Read"],
	update?:ValueTypes["TicketingFields_sessions_inventories_Update"],
	delete?:ValueTypes["TicketingFields_sessions_inventories_Delete"],
	fields?:ValueTypes["TicketingFields_sessions_inventories_Fields"],
		__typename?: boolean | `@${string}`
}>;
	["TicketingFields_sessions_inventories_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingFields_sessions_inventories_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingFields_sessions_inventories_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingFields_sessions_inventories_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingFields_sessions_inventories_Fields"]: AliasType<{
	name?:ValueTypes["TicketingFields_sessions_inventories_name"],
	quantity?:ValueTypes["TicketingFields_sessions_inventories_quantity"],
	unitPrice?:ValueTypes["TicketingFields_sessions_inventories_unitPrice"],
	handlingFee?:ValueTypes["TicketingFields_sessions_inventories_handlingFee"],
	totalCost?:ValueTypes["TicketingFields_sessions_inventories_totalCost"],
	minPurchase?:ValueTypes["TicketingFields_sessions_inventories_minPurchase"],
	maxPurchase?:ValueTypes["TicketingFields_sessions_inventories_maxPurchase"],
	stocks?:ValueTypes["TicketingFields_sessions_inventories_stocks"],
	remarks?:ValueTypes["TicketingFields_sessions_inventories_remarks"],
		__typename?: boolean | `@${string}`
}>;
	["TicketingFields_sessions_inventories_name"]: AliasType<{
	create?:ValueTypes["TicketingFields_sessions_inventories_name_Create"],
	read?:ValueTypes["TicketingFields_sessions_inventories_name_Read"],
	update?:ValueTypes["TicketingFields_sessions_inventories_name_Update"],
	delete?:ValueTypes["TicketingFields_sessions_inventories_name_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["TicketingFields_sessions_inventories_name_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingFields_sessions_inventories_name_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingFields_sessions_inventories_name_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingFields_sessions_inventories_name_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingFields_sessions_inventories_quantity"]: AliasType<{
	create?:ValueTypes["TicketingFields_sessions_inventories_quantity_Create"],
	read?:ValueTypes["TicketingFields_sessions_inventories_quantity_Read"],
	update?:ValueTypes["TicketingFields_sessions_inventories_quantity_Update"],
	delete?:ValueTypes["TicketingFields_sessions_inventories_quantity_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["TicketingFields_sessions_inventories_quantity_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingFields_sessions_inventories_quantity_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingFields_sessions_inventories_quantity_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingFields_sessions_inventories_quantity_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingFields_sessions_inventories_unitPrice"]: AliasType<{
	create?:ValueTypes["TicketingFields_sessions_inventories_unitPrice_Create"],
	read?:ValueTypes["TicketingFields_sessions_inventories_unitPrice_Read"],
	update?:ValueTypes["TicketingFields_sessions_inventories_unitPrice_Update"],
	delete?:ValueTypes["TicketingFields_sessions_inventories_unitPrice_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["TicketingFields_sessions_inventories_unitPrice_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingFields_sessions_inventories_unitPrice_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingFields_sessions_inventories_unitPrice_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingFields_sessions_inventories_unitPrice_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingFields_sessions_inventories_handlingFee"]: AliasType<{
	create?:ValueTypes["TicketingFields_sessions_inventories_handlingFee_Create"],
	read?:ValueTypes["TicketingFields_sessions_inventories_handlingFee_Read"],
	update?:ValueTypes["TicketingFields_sessions_inventories_handlingFee_Update"],
	delete?:ValueTypes["TicketingFields_sessions_inventories_handlingFee_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["TicketingFields_sessions_inventories_handlingFee_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingFields_sessions_inventories_handlingFee_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingFields_sessions_inventories_handlingFee_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingFields_sessions_inventories_handlingFee_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingFields_sessions_inventories_totalCost"]: AliasType<{
	create?:ValueTypes["TicketingFields_sessions_inventories_totalCost_Create"],
	read?:ValueTypes["TicketingFields_sessions_inventories_totalCost_Read"],
	update?:ValueTypes["TicketingFields_sessions_inventories_totalCost_Update"],
	delete?:ValueTypes["TicketingFields_sessions_inventories_totalCost_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["TicketingFields_sessions_inventories_totalCost_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingFields_sessions_inventories_totalCost_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingFields_sessions_inventories_totalCost_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingFields_sessions_inventories_totalCost_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingFields_sessions_inventories_minPurchase"]: AliasType<{
	create?:ValueTypes["TicketingFields_sessions_inventories_minPurchase_Create"],
	read?:ValueTypes["TicketingFields_sessions_inventories_minPurchase_Read"],
	update?:ValueTypes["TicketingFields_sessions_inventories_minPurchase_Update"],
	delete?:ValueTypes["TicketingFields_sessions_inventories_minPurchase_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["TicketingFields_sessions_inventories_minPurchase_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingFields_sessions_inventories_minPurchase_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingFields_sessions_inventories_minPurchase_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingFields_sessions_inventories_minPurchase_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingFields_sessions_inventories_maxPurchase"]: AliasType<{
	create?:ValueTypes["TicketingFields_sessions_inventories_maxPurchase_Create"],
	read?:ValueTypes["TicketingFields_sessions_inventories_maxPurchase_Read"],
	update?:ValueTypes["TicketingFields_sessions_inventories_maxPurchase_Update"],
	delete?:ValueTypes["TicketingFields_sessions_inventories_maxPurchase_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["TicketingFields_sessions_inventories_maxPurchase_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingFields_sessions_inventories_maxPurchase_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingFields_sessions_inventories_maxPurchase_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingFields_sessions_inventories_maxPurchase_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingFields_sessions_inventories_stocks"]: AliasType<{
	create?:ValueTypes["TicketingFields_sessions_inventories_stocks_Create"],
	read?:ValueTypes["TicketingFields_sessions_inventories_stocks_Read"],
	update?:ValueTypes["TicketingFields_sessions_inventories_stocks_Update"],
	delete?:ValueTypes["TicketingFields_sessions_inventories_stocks_Delete"],
	fields?:ValueTypes["TicketingFields_sessions_inventories_stocks_Fields"],
		__typename?: boolean | `@${string}`
}>;
	["TicketingFields_sessions_inventories_stocks_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingFields_sessions_inventories_stocks_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingFields_sessions_inventories_stocks_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingFields_sessions_inventories_stocks_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingFields_sessions_inventories_stocks_Fields"]: AliasType<{
	stockId?:ValueTypes["TicketingFields_sessions_inventories_stocks_stockId"],
	remaining?:ValueTypes["TicketingFields_sessions_inventories_stocks_remaining"],
	sold?:ValueTypes["TicketingFields_sessions_inventories_stocks_sold"],
	onHold?:ValueTypes["TicketingFields_sessions_inventories_stocks_onHold"],
	checkedIn?:ValueTypes["TicketingFields_sessions_inventories_stocks_checkedIn"],
		__typename?: boolean | `@${string}`
}>;
	["TicketingFields_sessions_inventories_stocks_stockId"]: AliasType<{
	create?:ValueTypes["TicketingFields_sessions_inventories_stocks_stockId_Create"],
	read?:ValueTypes["TicketingFields_sessions_inventories_stocks_stockId_Read"],
	update?:ValueTypes["TicketingFields_sessions_inventories_stocks_stockId_Update"],
	delete?:ValueTypes["TicketingFields_sessions_inventories_stocks_stockId_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["TicketingFields_sessions_inventories_stocks_stockId_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingFields_sessions_inventories_stocks_stockId_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingFields_sessions_inventories_stocks_stockId_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingFields_sessions_inventories_stocks_stockId_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingFields_sessions_inventories_stocks_remaining"]: AliasType<{
	create?:ValueTypes["TicketingFields_sessions_inventories_stocks_remaining_Create"],
	read?:ValueTypes["TicketingFields_sessions_inventories_stocks_remaining_Read"],
	update?:ValueTypes["TicketingFields_sessions_inventories_stocks_remaining_Update"],
	delete?:ValueTypes["TicketingFields_sessions_inventories_stocks_remaining_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["TicketingFields_sessions_inventories_stocks_remaining_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingFields_sessions_inventories_stocks_remaining_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingFields_sessions_inventories_stocks_remaining_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingFields_sessions_inventories_stocks_remaining_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingFields_sessions_inventories_stocks_sold"]: AliasType<{
	create?:ValueTypes["TicketingFields_sessions_inventories_stocks_sold_Create"],
	read?:ValueTypes["TicketingFields_sessions_inventories_stocks_sold_Read"],
	update?:ValueTypes["TicketingFields_sessions_inventories_stocks_sold_Update"],
	delete?:ValueTypes["TicketingFields_sessions_inventories_stocks_sold_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["TicketingFields_sessions_inventories_stocks_sold_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingFields_sessions_inventories_stocks_sold_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingFields_sessions_inventories_stocks_sold_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingFields_sessions_inventories_stocks_sold_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingFields_sessions_inventories_stocks_onHold"]: AliasType<{
	create?:ValueTypes["TicketingFields_sessions_inventories_stocks_onHold_Create"],
	read?:ValueTypes["TicketingFields_sessions_inventories_stocks_onHold_Read"],
	update?:ValueTypes["TicketingFields_sessions_inventories_stocks_onHold_Update"],
	delete?:ValueTypes["TicketingFields_sessions_inventories_stocks_onHold_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["TicketingFields_sessions_inventories_stocks_onHold_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingFields_sessions_inventories_stocks_onHold_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingFields_sessions_inventories_stocks_onHold_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingFields_sessions_inventories_stocks_onHold_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingFields_sessions_inventories_stocks_checkedIn"]: AliasType<{
	create?:ValueTypes["TicketingFields_sessions_inventories_stocks_checkedIn_Create"],
	read?:ValueTypes["TicketingFields_sessions_inventories_stocks_checkedIn_Read"],
	update?:ValueTypes["TicketingFields_sessions_inventories_stocks_checkedIn_Update"],
	delete?:ValueTypes["TicketingFields_sessions_inventories_stocks_checkedIn_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["TicketingFields_sessions_inventories_stocks_checkedIn_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingFields_sessions_inventories_stocks_checkedIn_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingFields_sessions_inventories_stocks_checkedIn_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingFields_sessions_inventories_stocks_checkedIn_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingFields_sessions_inventories_remarks"]: AliasType<{
	create?:ValueTypes["TicketingFields_sessions_inventories_remarks_Create"],
	read?:ValueTypes["TicketingFields_sessions_inventories_remarks_Read"],
	update?:ValueTypes["TicketingFields_sessions_inventories_remarks_Update"],
	delete?:ValueTypes["TicketingFields_sessions_inventories_remarks_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["TicketingFields_sessions_inventories_remarks_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingFields_sessions_inventories_remarks_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingFields_sessions_inventories_remarks_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingFields_sessions_inventories_remarks_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingFields_sessions_id"]: AliasType<{
	create?:ValueTypes["TicketingFields_sessions_id_Create"],
	read?:ValueTypes["TicketingFields_sessions_id_Read"],
	update?:ValueTypes["TicketingFields_sessions_id_Update"],
	delete?:ValueTypes["TicketingFields_sessions_id_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["TicketingFields_sessions_id_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingFields_sessions_id_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingFields_sessions_id_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingFields_sessions_id_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingFields_updatedAt"]: AliasType<{
	create?:ValueTypes["TicketingFields_updatedAt_Create"],
	read?:ValueTypes["TicketingFields_updatedAt_Read"],
	update?:ValueTypes["TicketingFields_updatedAt_Update"],
	delete?:ValueTypes["TicketingFields_updatedAt_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["TicketingFields_updatedAt_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingFields_updatedAt_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingFields_updatedAt_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingFields_updatedAt_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingFields_createdAt"]: AliasType<{
	create?:ValueTypes["TicketingFields_createdAt_Create"],
	read?:ValueTypes["TicketingFields_createdAt_Read"],
	update?:ValueTypes["TicketingFields_createdAt_Update"],
	delete?:ValueTypes["TicketingFields_createdAt_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["TicketingFields_createdAt_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingFields_createdAt_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingFields_createdAt_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingFields_createdAt_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingCreateAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingReadAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingUpdateAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingDeleteAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["productsAccess"]: AliasType<{
	fields?:ValueTypes["ProductsFields"],
	create?:ValueTypes["ProductsCreateAccess"],
	read?:ValueTypes["ProductsReadAccess"],
	update?:ValueTypes["ProductsUpdateAccess"],
	delete?:ValueTypes["ProductsDeleteAccess"],
		__typename?: boolean | `@${string}`
}>;
	["ProductsFields"]: AliasType<{
	status?:ValueTypes["ProductsFields_status"],
	creator?:ValueTypes["ProductsFields_creator"],
	productType?:ValueTypes["ProductsFields_productType"],
	productName?:ValueTypes["ProductsFields_productName"],
	referenceId?:ValueTypes["ProductsFields_referenceId"],
	linkedEvent?:ValueTypes["ProductsFields_linkedEvent"],
	CheckInLevel?:ValueTypes["ProductsFields_CheckInLevel"],
	thumbnail?:ValueTypes["ProductsFields_thumbnail"],
	displayPrice?:ValueTypes["ProductsFields_displayPrice"],
	transactionType?:ValueTypes["ProductsFields_transactionType"],
	website?:ValueTypes["ProductsFields_website"],
	tnc?:ValueTypes["ProductsFields_tnc"],
	remarks?:ValueTypes["ProductsFields_remarks"],
	sessions?:ValueTypes["ProductsFields_sessions"],
	updatedAt?:ValueTypes["ProductsFields_updatedAt"],
	createdAt?:ValueTypes["ProductsFields_createdAt"],
		__typename?: boolean | `@${string}`
}>;
	["ProductsFields_status"]: AliasType<{
	create?:ValueTypes["ProductsFields_status_Create"],
	read?:ValueTypes["ProductsFields_status_Read"],
	update?:ValueTypes["ProductsFields_status_Update"],
	delete?:ValueTypes["ProductsFields_status_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["ProductsFields_status_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsFields_status_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsFields_status_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsFields_status_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsFields_creator"]: AliasType<{
	create?:ValueTypes["ProductsFields_creator_Create"],
	read?:ValueTypes["ProductsFields_creator_Read"],
	update?:ValueTypes["ProductsFields_creator_Update"],
	delete?:ValueTypes["ProductsFields_creator_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["ProductsFields_creator_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsFields_creator_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsFields_creator_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsFields_creator_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsFields_productType"]: AliasType<{
	create?:ValueTypes["ProductsFields_productType_Create"],
	read?:ValueTypes["ProductsFields_productType_Read"],
	update?:ValueTypes["ProductsFields_productType_Update"],
	delete?:ValueTypes["ProductsFields_productType_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["ProductsFields_productType_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsFields_productType_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsFields_productType_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsFields_productType_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsFields_productName"]: AliasType<{
	create?:ValueTypes["ProductsFields_productName_Create"],
	read?:ValueTypes["ProductsFields_productName_Read"],
	update?:ValueTypes["ProductsFields_productName_Update"],
	delete?:ValueTypes["ProductsFields_productName_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["ProductsFields_productName_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsFields_productName_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsFields_productName_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsFields_productName_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsFields_referenceId"]: AliasType<{
	create?:ValueTypes["ProductsFields_referenceId_Create"],
	read?:ValueTypes["ProductsFields_referenceId_Read"],
	update?:ValueTypes["ProductsFields_referenceId_Update"],
	delete?:ValueTypes["ProductsFields_referenceId_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["ProductsFields_referenceId_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsFields_referenceId_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsFields_referenceId_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsFields_referenceId_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsFields_linkedEvent"]: AliasType<{
	create?:ValueTypes["ProductsFields_linkedEvent_Create"],
	read?:ValueTypes["ProductsFields_linkedEvent_Read"],
	update?:ValueTypes["ProductsFields_linkedEvent_Update"],
	delete?:ValueTypes["ProductsFields_linkedEvent_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["ProductsFields_linkedEvent_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsFields_linkedEvent_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsFields_linkedEvent_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsFields_linkedEvent_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsFields_CheckInLevel"]: AliasType<{
	create?:ValueTypes["ProductsFields_CheckInLevel_Create"],
	read?:ValueTypes["ProductsFields_CheckInLevel_Read"],
	update?:ValueTypes["ProductsFields_CheckInLevel_Update"],
	delete?:ValueTypes["ProductsFields_CheckInLevel_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["ProductsFields_CheckInLevel_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsFields_CheckInLevel_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsFields_CheckInLevel_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsFields_CheckInLevel_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsFields_thumbnail"]: AliasType<{
	create?:ValueTypes["ProductsFields_thumbnail_Create"],
	read?:ValueTypes["ProductsFields_thumbnail_Read"],
	update?:ValueTypes["ProductsFields_thumbnail_Update"],
	delete?:ValueTypes["ProductsFields_thumbnail_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["ProductsFields_thumbnail_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsFields_thumbnail_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsFields_thumbnail_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsFields_thumbnail_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsFields_displayPrice"]: AliasType<{
	create?:ValueTypes["ProductsFields_displayPrice_Create"],
	read?:ValueTypes["ProductsFields_displayPrice_Read"],
	update?:ValueTypes["ProductsFields_displayPrice_Update"],
	delete?:ValueTypes["ProductsFields_displayPrice_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["ProductsFields_displayPrice_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsFields_displayPrice_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsFields_displayPrice_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsFields_displayPrice_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsFields_transactionType"]: AliasType<{
	create?:ValueTypes["ProductsFields_transactionType_Create"],
	read?:ValueTypes["ProductsFields_transactionType_Read"],
	update?:ValueTypes["ProductsFields_transactionType_Update"],
	delete?:ValueTypes["ProductsFields_transactionType_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["ProductsFields_transactionType_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsFields_transactionType_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsFields_transactionType_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsFields_transactionType_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsFields_website"]: AliasType<{
	create?:ValueTypes["ProductsFields_website_Create"],
	read?:ValueTypes["ProductsFields_website_Read"],
	update?:ValueTypes["ProductsFields_website_Update"],
	delete?:ValueTypes["ProductsFields_website_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["ProductsFields_website_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsFields_website_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsFields_website_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsFields_website_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsFields_tnc"]: AliasType<{
	create?:ValueTypes["ProductsFields_tnc_Create"],
	read?:ValueTypes["ProductsFields_tnc_Read"],
	update?:ValueTypes["ProductsFields_tnc_Update"],
	delete?:ValueTypes["ProductsFields_tnc_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["ProductsFields_tnc_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsFields_tnc_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsFields_tnc_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsFields_tnc_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsFields_remarks"]: AliasType<{
	create?:ValueTypes["ProductsFields_remarks_Create"],
	read?:ValueTypes["ProductsFields_remarks_Read"],
	update?:ValueTypes["ProductsFields_remarks_Update"],
	delete?:ValueTypes["ProductsFields_remarks_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["ProductsFields_remarks_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsFields_remarks_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsFields_remarks_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsFields_remarks_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsFields_sessions"]: AliasType<{
	create?:ValueTypes["ProductsFields_sessions_Create"],
	read?:ValueTypes["ProductsFields_sessions_Read"],
	update?:ValueTypes["ProductsFields_sessions_Update"],
	delete?:ValueTypes["ProductsFields_sessions_Delete"],
	fields?:ValueTypes["ProductsFields_sessions_Fields"],
		__typename?: boolean | `@${string}`
}>;
	["ProductsFields_sessions_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsFields_sessions_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsFields_sessions_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsFields_sessions_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsFields_sessions_Fields"]: AliasType<{
	productId?:ValueTypes["ProductsFields_sessions_productId"],
	name?:ValueTypes["ProductsFields_sessions_name"],
	inventories?:ValueTypes["ProductsFields_sessions_inventories"],
	id?:ValueTypes["ProductsFields_sessions_id"],
		__typename?: boolean | `@${string}`
}>;
	["ProductsFields_sessions_productId"]: AliasType<{
	create?:ValueTypes["ProductsFields_sessions_productId_Create"],
	read?:ValueTypes["ProductsFields_sessions_productId_Read"],
	update?:ValueTypes["ProductsFields_sessions_productId_Update"],
	delete?:ValueTypes["ProductsFields_sessions_productId_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["ProductsFields_sessions_productId_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsFields_sessions_productId_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsFields_sessions_productId_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsFields_sessions_productId_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsFields_sessions_name"]: AliasType<{
	create?:ValueTypes["ProductsFields_sessions_name_Create"],
	read?:ValueTypes["ProductsFields_sessions_name_Read"],
	update?:ValueTypes["ProductsFields_sessions_name_Update"],
	delete?:ValueTypes["ProductsFields_sessions_name_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["ProductsFields_sessions_name_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsFields_sessions_name_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsFields_sessions_name_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsFields_sessions_name_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsFields_sessions_inventories"]: AliasType<{
	create?:ValueTypes["ProductsFields_sessions_inventories_Create"],
	read?:ValueTypes["ProductsFields_sessions_inventories_Read"],
	update?:ValueTypes["ProductsFields_sessions_inventories_Update"],
	delete?:ValueTypes["ProductsFields_sessions_inventories_Delete"],
	fields?:ValueTypes["ProductsFields_sessions_inventories_Fields"],
		__typename?: boolean | `@${string}`
}>;
	["ProductsFields_sessions_inventories_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsFields_sessions_inventories_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsFields_sessions_inventories_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsFields_sessions_inventories_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsFields_sessions_inventories_Fields"]: AliasType<{
	quantity?:ValueTypes["ProductsFields_sessions_inventories_quantity"],
	unitPrice?:ValueTypes["ProductsFields_sessions_inventories_unitPrice"],
	handlingFee?:ValueTypes["ProductsFields_sessions_inventories_handlingFee"],
	totalCost?:ValueTypes["ProductsFields_sessions_inventories_totalCost"],
	minPurchase?:ValueTypes["ProductsFields_sessions_inventories_minPurchase"],
	maxPurchase?:ValueTypes["ProductsFields_sessions_inventories_maxPurchase"],
	stocks?:ValueTypes["ProductsFields_sessions_inventories_stocks"],
		__typename?: boolean | `@${string}`
}>;
	["ProductsFields_sessions_inventories_quantity"]: AliasType<{
	create?:ValueTypes["ProductsFields_sessions_inventories_quantity_Create"],
	read?:ValueTypes["ProductsFields_sessions_inventories_quantity_Read"],
	update?:ValueTypes["ProductsFields_sessions_inventories_quantity_Update"],
	delete?:ValueTypes["ProductsFields_sessions_inventories_quantity_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["ProductsFields_sessions_inventories_quantity_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsFields_sessions_inventories_quantity_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsFields_sessions_inventories_quantity_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsFields_sessions_inventories_quantity_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsFields_sessions_inventories_unitPrice"]: AliasType<{
	create?:ValueTypes["ProductsFields_sessions_inventories_unitPrice_Create"],
	read?:ValueTypes["ProductsFields_sessions_inventories_unitPrice_Read"],
	update?:ValueTypes["ProductsFields_sessions_inventories_unitPrice_Update"],
	delete?:ValueTypes["ProductsFields_sessions_inventories_unitPrice_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["ProductsFields_sessions_inventories_unitPrice_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsFields_sessions_inventories_unitPrice_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsFields_sessions_inventories_unitPrice_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsFields_sessions_inventories_unitPrice_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsFields_sessions_inventories_handlingFee"]: AliasType<{
	create?:ValueTypes["ProductsFields_sessions_inventories_handlingFee_Create"],
	read?:ValueTypes["ProductsFields_sessions_inventories_handlingFee_Read"],
	update?:ValueTypes["ProductsFields_sessions_inventories_handlingFee_Update"],
	delete?:ValueTypes["ProductsFields_sessions_inventories_handlingFee_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["ProductsFields_sessions_inventories_handlingFee_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsFields_sessions_inventories_handlingFee_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsFields_sessions_inventories_handlingFee_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsFields_sessions_inventories_handlingFee_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsFields_sessions_inventories_totalCost"]: AliasType<{
	create?:ValueTypes["ProductsFields_sessions_inventories_totalCost_Create"],
	read?:ValueTypes["ProductsFields_sessions_inventories_totalCost_Read"],
	update?:ValueTypes["ProductsFields_sessions_inventories_totalCost_Update"],
	delete?:ValueTypes["ProductsFields_sessions_inventories_totalCost_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["ProductsFields_sessions_inventories_totalCost_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsFields_sessions_inventories_totalCost_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsFields_sessions_inventories_totalCost_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsFields_sessions_inventories_totalCost_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsFields_sessions_inventories_minPurchase"]: AliasType<{
	create?:ValueTypes["ProductsFields_sessions_inventories_minPurchase_Create"],
	read?:ValueTypes["ProductsFields_sessions_inventories_minPurchase_Read"],
	update?:ValueTypes["ProductsFields_sessions_inventories_minPurchase_Update"],
	delete?:ValueTypes["ProductsFields_sessions_inventories_minPurchase_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["ProductsFields_sessions_inventories_minPurchase_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsFields_sessions_inventories_minPurchase_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsFields_sessions_inventories_minPurchase_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsFields_sessions_inventories_minPurchase_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsFields_sessions_inventories_maxPurchase"]: AliasType<{
	create?:ValueTypes["ProductsFields_sessions_inventories_maxPurchase_Create"],
	read?:ValueTypes["ProductsFields_sessions_inventories_maxPurchase_Read"],
	update?:ValueTypes["ProductsFields_sessions_inventories_maxPurchase_Update"],
	delete?:ValueTypes["ProductsFields_sessions_inventories_maxPurchase_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["ProductsFields_sessions_inventories_maxPurchase_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsFields_sessions_inventories_maxPurchase_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsFields_sessions_inventories_maxPurchase_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsFields_sessions_inventories_maxPurchase_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsFields_sessions_inventories_stocks"]: AliasType<{
	create?:ValueTypes["ProductsFields_sessions_inventories_stocks_Create"],
	read?:ValueTypes["ProductsFields_sessions_inventories_stocks_Read"],
	update?:ValueTypes["ProductsFields_sessions_inventories_stocks_Update"],
	delete?:ValueTypes["ProductsFields_sessions_inventories_stocks_Delete"],
	fields?:ValueTypes["ProductsFields_sessions_inventories_stocks_Fields"],
		__typename?: boolean | `@${string}`
}>;
	["ProductsFields_sessions_inventories_stocks_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsFields_sessions_inventories_stocks_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsFields_sessions_inventories_stocks_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsFields_sessions_inventories_stocks_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsFields_sessions_inventories_stocks_Fields"]: AliasType<{
	stockId?:ValueTypes["ProductsFields_sessions_inventories_stocks_stockId"],
	remaining?:ValueTypes["ProductsFields_sessions_inventories_stocks_remaining"],
	sold?:ValueTypes["ProductsFields_sessions_inventories_stocks_sold"],
	onHold?:ValueTypes["ProductsFields_sessions_inventories_stocks_onHold"],
	checkedIn?:ValueTypes["ProductsFields_sessions_inventories_stocks_checkedIn"],
		__typename?: boolean | `@${string}`
}>;
	["ProductsFields_sessions_inventories_stocks_stockId"]: AliasType<{
	create?:ValueTypes["ProductsFields_sessions_inventories_stocks_stockId_Create"],
	read?:ValueTypes["ProductsFields_sessions_inventories_stocks_stockId_Read"],
	update?:ValueTypes["ProductsFields_sessions_inventories_stocks_stockId_Update"],
	delete?:ValueTypes["ProductsFields_sessions_inventories_stocks_stockId_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["ProductsFields_sessions_inventories_stocks_stockId_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsFields_sessions_inventories_stocks_stockId_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsFields_sessions_inventories_stocks_stockId_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsFields_sessions_inventories_stocks_stockId_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsFields_sessions_inventories_stocks_remaining"]: AliasType<{
	create?:ValueTypes["ProductsFields_sessions_inventories_stocks_remaining_Create"],
	read?:ValueTypes["ProductsFields_sessions_inventories_stocks_remaining_Read"],
	update?:ValueTypes["ProductsFields_sessions_inventories_stocks_remaining_Update"],
	delete?:ValueTypes["ProductsFields_sessions_inventories_stocks_remaining_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["ProductsFields_sessions_inventories_stocks_remaining_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsFields_sessions_inventories_stocks_remaining_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsFields_sessions_inventories_stocks_remaining_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsFields_sessions_inventories_stocks_remaining_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsFields_sessions_inventories_stocks_sold"]: AliasType<{
	create?:ValueTypes["ProductsFields_sessions_inventories_stocks_sold_Create"],
	read?:ValueTypes["ProductsFields_sessions_inventories_stocks_sold_Read"],
	update?:ValueTypes["ProductsFields_sessions_inventories_stocks_sold_Update"],
	delete?:ValueTypes["ProductsFields_sessions_inventories_stocks_sold_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["ProductsFields_sessions_inventories_stocks_sold_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsFields_sessions_inventories_stocks_sold_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsFields_sessions_inventories_stocks_sold_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsFields_sessions_inventories_stocks_sold_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsFields_sessions_inventories_stocks_onHold"]: AliasType<{
	create?:ValueTypes["ProductsFields_sessions_inventories_stocks_onHold_Create"],
	read?:ValueTypes["ProductsFields_sessions_inventories_stocks_onHold_Read"],
	update?:ValueTypes["ProductsFields_sessions_inventories_stocks_onHold_Update"],
	delete?:ValueTypes["ProductsFields_sessions_inventories_stocks_onHold_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["ProductsFields_sessions_inventories_stocks_onHold_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsFields_sessions_inventories_stocks_onHold_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsFields_sessions_inventories_stocks_onHold_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsFields_sessions_inventories_stocks_onHold_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsFields_sessions_inventories_stocks_checkedIn"]: AliasType<{
	create?:ValueTypes["ProductsFields_sessions_inventories_stocks_checkedIn_Create"],
	read?:ValueTypes["ProductsFields_sessions_inventories_stocks_checkedIn_Read"],
	update?:ValueTypes["ProductsFields_sessions_inventories_stocks_checkedIn_Update"],
	delete?:ValueTypes["ProductsFields_sessions_inventories_stocks_checkedIn_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["ProductsFields_sessions_inventories_stocks_checkedIn_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsFields_sessions_inventories_stocks_checkedIn_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsFields_sessions_inventories_stocks_checkedIn_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsFields_sessions_inventories_stocks_checkedIn_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsFields_sessions_id"]: AliasType<{
	create?:ValueTypes["ProductsFields_sessions_id_Create"],
	read?:ValueTypes["ProductsFields_sessions_id_Read"],
	update?:ValueTypes["ProductsFields_sessions_id_Update"],
	delete?:ValueTypes["ProductsFields_sessions_id_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["ProductsFields_sessions_id_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsFields_sessions_id_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsFields_sessions_id_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsFields_sessions_id_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsFields_updatedAt"]: AliasType<{
	create?:ValueTypes["ProductsFields_updatedAt_Create"],
	read?:ValueTypes["ProductsFields_updatedAt_Read"],
	update?:ValueTypes["ProductsFields_updatedAt_Update"],
	delete?:ValueTypes["ProductsFields_updatedAt_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["ProductsFields_updatedAt_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsFields_updatedAt_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsFields_updatedAt_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsFields_updatedAt_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsFields_createdAt"]: AliasType<{
	create?:ValueTypes["ProductsFields_createdAt_Create"],
	read?:ValueTypes["ProductsFields_createdAt_Read"],
	update?:ValueTypes["ProductsFields_createdAt_Update"],
	delete?:ValueTypes["ProductsFields_createdAt_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["ProductsFields_createdAt_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsFields_createdAt_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsFields_createdAt_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsFields_createdAt_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsCreateAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsReadAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsUpdateAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsDeleteAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["inventory_statusAccess"]: AliasType<{
	fields?:ValueTypes["InventoryStatusFields"],
	create?:ValueTypes["InventoryStatusCreateAccess"],
	read?:ValueTypes["InventoryStatusReadAccess"],
	update?:ValueTypes["InventoryStatusUpdateAccess"],
	delete?:ValueTypes["InventoryStatusDeleteAccess"],
		__typename?: boolean | `@${string}`
}>;
	["InventoryStatusFields"]: AliasType<{
	variantId?:ValueTypes["InventoryStatusFields_variantId"],
	variantName?:ValueTypes["InventoryStatusFields_variantName"],
	productType?:ValueTypes["InventoryStatusFields_productType"],
	stockUpdateStatus?:ValueTypes["InventoryStatusFields_stockUpdateStatus"],
	stockUpdateAmount?:ValueTypes["InventoryStatusFields_stockUpdateAmount"],
	user?:ValueTypes["InventoryStatusFields_user"],
	publicUserPhone?:ValueTypes["InventoryStatusFields_publicUserPhone"],
	expiredAt?:ValueTypes["InventoryStatusFields_expiredAt"],
	updatedAt?:ValueTypes["InventoryStatusFields_updatedAt"],
	createdAt?:ValueTypes["InventoryStatusFields_createdAt"],
		__typename?: boolean | `@${string}`
}>;
	["InventoryStatusFields_variantId"]: AliasType<{
	create?:ValueTypes["InventoryStatusFields_variantId_Create"],
	read?:ValueTypes["InventoryStatusFields_variantId_Read"],
	update?:ValueTypes["InventoryStatusFields_variantId_Update"],
	delete?:ValueTypes["InventoryStatusFields_variantId_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["InventoryStatusFields_variantId_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["InventoryStatusFields_variantId_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["InventoryStatusFields_variantId_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["InventoryStatusFields_variantId_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["InventoryStatusFields_variantName"]: AliasType<{
	create?:ValueTypes["InventoryStatusFields_variantName_Create"],
	read?:ValueTypes["InventoryStatusFields_variantName_Read"],
	update?:ValueTypes["InventoryStatusFields_variantName_Update"],
	delete?:ValueTypes["InventoryStatusFields_variantName_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["InventoryStatusFields_variantName_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["InventoryStatusFields_variantName_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["InventoryStatusFields_variantName_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["InventoryStatusFields_variantName_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["InventoryStatusFields_productType"]: AliasType<{
	create?:ValueTypes["InventoryStatusFields_productType_Create"],
	read?:ValueTypes["InventoryStatusFields_productType_Read"],
	update?:ValueTypes["InventoryStatusFields_productType_Update"],
	delete?:ValueTypes["InventoryStatusFields_productType_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["InventoryStatusFields_productType_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["InventoryStatusFields_productType_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["InventoryStatusFields_productType_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["InventoryStatusFields_productType_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["InventoryStatusFields_stockUpdateStatus"]: AliasType<{
	create?:ValueTypes["InventoryStatusFields_stockUpdateStatus_Create"],
	read?:ValueTypes["InventoryStatusFields_stockUpdateStatus_Read"],
	update?:ValueTypes["InventoryStatusFields_stockUpdateStatus_Update"],
	delete?:ValueTypes["InventoryStatusFields_stockUpdateStatus_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["InventoryStatusFields_stockUpdateStatus_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["InventoryStatusFields_stockUpdateStatus_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["InventoryStatusFields_stockUpdateStatus_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["InventoryStatusFields_stockUpdateStatus_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["InventoryStatusFields_stockUpdateAmount"]: AliasType<{
	create?:ValueTypes["InventoryStatusFields_stockUpdateAmount_Create"],
	read?:ValueTypes["InventoryStatusFields_stockUpdateAmount_Read"],
	update?:ValueTypes["InventoryStatusFields_stockUpdateAmount_Update"],
	delete?:ValueTypes["InventoryStatusFields_stockUpdateAmount_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["InventoryStatusFields_stockUpdateAmount_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["InventoryStatusFields_stockUpdateAmount_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["InventoryStatusFields_stockUpdateAmount_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["InventoryStatusFields_stockUpdateAmount_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["InventoryStatusFields_user"]: AliasType<{
	create?:ValueTypes["InventoryStatusFields_user_Create"],
	read?:ValueTypes["InventoryStatusFields_user_Read"],
	update?:ValueTypes["InventoryStatusFields_user_Update"],
	delete?:ValueTypes["InventoryStatusFields_user_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["InventoryStatusFields_user_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["InventoryStatusFields_user_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["InventoryStatusFields_user_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["InventoryStatusFields_user_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["InventoryStatusFields_publicUserPhone"]: AliasType<{
	create?:ValueTypes["InventoryStatusFields_publicUserPhone_Create"],
	read?:ValueTypes["InventoryStatusFields_publicUserPhone_Read"],
	update?:ValueTypes["InventoryStatusFields_publicUserPhone_Update"],
	delete?:ValueTypes["InventoryStatusFields_publicUserPhone_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["InventoryStatusFields_publicUserPhone_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["InventoryStatusFields_publicUserPhone_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["InventoryStatusFields_publicUserPhone_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["InventoryStatusFields_publicUserPhone_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["InventoryStatusFields_expiredAt"]: AliasType<{
	create?:ValueTypes["InventoryStatusFields_expiredAt_Create"],
	read?:ValueTypes["InventoryStatusFields_expiredAt_Read"],
	update?:ValueTypes["InventoryStatusFields_expiredAt_Update"],
	delete?:ValueTypes["InventoryStatusFields_expiredAt_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["InventoryStatusFields_expiredAt_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["InventoryStatusFields_expiredAt_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["InventoryStatusFields_expiredAt_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["InventoryStatusFields_expiredAt_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["InventoryStatusFields_updatedAt"]: AliasType<{
	create?:ValueTypes["InventoryStatusFields_updatedAt_Create"],
	read?:ValueTypes["InventoryStatusFields_updatedAt_Read"],
	update?:ValueTypes["InventoryStatusFields_updatedAt_Update"],
	delete?:ValueTypes["InventoryStatusFields_updatedAt_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["InventoryStatusFields_updatedAt_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["InventoryStatusFields_updatedAt_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["InventoryStatusFields_updatedAt_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["InventoryStatusFields_updatedAt_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["InventoryStatusFields_createdAt"]: AliasType<{
	create?:ValueTypes["InventoryStatusFields_createdAt_Create"],
	read?:ValueTypes["InventoryStatusFields_createdAt_Read"],
	update?:ValueTypes["InventoryStatusFields_createdAt_Update"],
	delete?:ValueTypes["InventoryStatusFields_createdAt_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["InventoryStatusFields_createdAt_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["InventoryStatusFields_createdAt_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["InventoryStatusFields_createdAt_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["InventoryStatusFields_createdAt_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["InventoryStatusCreateAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["InventoryStatusReadAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["InventoryStatusUpdateAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["InventoryStatusDeleteAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["transactionsAccess"]: AliasType<{
	fields?:ValueTypes["TransactionsFields"],
	create?:ValueTypes["TransactionsCreateAccess"],
	read?:ValueTypes["TransactionsReadAccess"],
	update?:ValueTypes["TransactionsUpdateAccess"],
	delete?:ValueTypes["TransactionsDeleteAccess"],
		__typename?: boolean | `@${string}`
}>;
	["TransactionsFields"]: AliasType<{
	creator?:ValueTypes["TransactionsFields_creator"],
	productName?:ValueTypes["TransactionsFields_productName"],
	referenceId?:ValueTypes["TransactionsFields_referenceId"],
	linkedEvent?:ValueTypes["TransactionsFields_linkedEvent"],
	purchaseDateTime?:ValueTypes["TransactionsFields_purchaseDateTime"],
	timableOrderId?:ValueTypes["TransactionsFields_timableOrderId"],
	stripePaymentId?:ValueTypes["TransactionsFields_stripePaymentId"],
	totalAmount?:ValueTypes["TransactionsFields_totalAmount"],
	contactPersonName?:ValueTypes["TransactionsFields_contactPersonName"],
	contactPersonPhone?:ValueTypes["TransactionsFields_contactPersonPhone"],
	remarks?:ValueTypes["TransactionsFields_remarks"],
	redemptionCodes?:ValueTypes["TransactionsFields_redemptionCodes"],
	updatedAt?:ValueTypes["TransactionsFields_updatedAt"],
	createdAt?:ValueTypes["TransactionsFields_createdAt"],
		__typename?: boolean | `@${string}`
}>;
	["TransactionsFields_creator"]: AliasType<{
	create?:ValueTypes["TransactionsFields_creator_Create"],
	read?:ValueTypes["TransactionsFields_creator_Read"],
	update?:ValueTypes["TransactionsFields_creator_Update"],
	delete?:ValueTypes["TransactionsFields_creator_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["TransactionsFields_creator_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TransactionsFields_creator_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TransactionsFields_creator_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TransactionsFields_creator_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TransactionsFields_productName"]: AliasType<{
	create?:ValueTypes["TransactionsFields_productName_Create"],
	read?:ValueTypes["TransactionsFields_productName_Read"],
	update?:ValueTypes["TransactionsFields_productName_Update"],
	delete?:ValueTypes["TransactionsFields_productName_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["TransactionsFields_productName_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TransactionsFields_productName_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TransactionsFields_productName_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TransactionsFields_productName_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TransactionsFields_referenceId"]: AliasType<{
	create?:ValueTypes["TransactionsFields_referenceId_Create"],
	read?:ValueTypes["TransactionsFields_referenceId_Read"],
	update?:ValueTypes["TransactionsFields_referenceId_Update"],
	delete?:ValueTypes["TransactionsFields_referenceId_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["TransactionsFields_referenceId_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TransactionsFields_referenceId_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TransactionsFields_referenceId_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TransactionsFields_referenceId_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TransactionsFields_linkedEvent"]: AliasType<{
	create?:ValueTypes["TransactionsFields_linkedEvent_Create"],
	read?:ValueTypes["TransactionsFields_linkedEvent_Read"],
	update?:ValueTypes["TransactionsFields_linkedEvent_Update"],
	delete?:ValueTypes["TransactionsFields_linkedEvent_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["TransactionsFields_linkedEvent_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TransactionsFields_linkedEvent_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TransactionsFields_linkedEvent_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TransactionsFields_linkedEvent_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TransactionsFields_purchaseDateTime"]: AliasType<{
	create?:ValueTypes["TransactionsFields_purchaseDateTime_Create"],
	read?:ValueTypes["TransactionsFields_purchaseDateTime_Read"],
	update?:ValueTypes["TransactionsFields_purchaseDateTime_Update"],
	delete?:ValueTypes["TransactionsFields_purchaseDateTime_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["TransactionsFields_purchaseDateTime_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TransactionsFields_purchaseDateTime_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TransactionsFields_purchaseDateTime_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TransactionsFields_purchaseDateTime_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TransactionsFields_timableOrderId"]: AliasType<{
	create?:ValueTypes["TransactionsFields_timableOrderId_Create"],
	read?:ValueTypes["TransactionsFields_timableOrderId_Read"],
	update?:ValueTypes["TransactionsFields_timableOrderId_Update"],
	delete?:ValueTypes["TransactionsFields_timableOrderId_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["TransactionsFields_timableOrderId_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TransactionsFields_timableOrderId_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TransactionsFields_timableOrderId_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TransactionsFields_timableOrderId_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TransactionsFields_stripePaymentId"]: AliasType<{
	create?:ValueTypes["TransactionsFields_stripePaymentId_Create"],
	read?:ValueTypes["TransactionsFields_stripePaymentId_Read"],
	update?:ValueTypes["TransactionsFields_stripePaymentId_Update"],
	delete?:ValueTypes["TransactionsFields_stripePaymentId_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["TransactionsFields_stripePaymentId_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TransactionsFields_stripePaymentId_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TransactionsFields_stripePaymentId_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TransactionsFields_stripePaymentId_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TransactionsFields_totalAmount"]: AliasType<{
	create?:ValueTypes["TransactionsFields_totalAmount_Create"],
	read?:ValueTypes["TransactionsFields_totalAmount_Read"],
	update?:ValueTypes["TransactionsFields_totalAmount_Update"],
	delete?:ValueTypes["TransactionsFields_totalAmount_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["TransactionsFields_totalAmount_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TransactionsFields_totalAmount_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TransactionsFields_totalAmount_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TransactionsFields_totalAmount_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TransactionsFields_contactPersonName"]: AliasType<{
	create?:ValueTypes["TransactionsFields_contactPersonName_Create"],
	read?:ValueTypes["TransactionsFields_contactPersonName_Read"],
	update?:ValueTypes["TransactionsFields_contactPersonName_Update"],
	delete?:ValueTypes["TransactionsFields_contactPersonName_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["TransactionsFields_contactPersonName_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TransactionsFields_contactPersonName_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TransactionsFields_contactPersonName_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TransactionsFields_contactPersonName_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TransactionsFields_contactPersonPhone"]: AliasType<{
	create?:ValueTypes["TransactionsFields_contactPersonPhone_Create"],
	read?:ValueTypes["TransactionsFields_contactPersonPhone_Read"],
	update?:ValueTypes["TransactionsFields_contactPersonPhone_Update"],
	delete?:ValueTypes["TransactionsFields_contactPersonPhone_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["TransactionsFields_contactPersonPhone_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TransactionsFields_contactPersonPhone_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TransactionsFields_contactPersonPhone_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TransactionsFields_contactPersonPhone_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TransactionsFields_remarks"]: AliasType<{
	create?:ValueTypes["TransactionsFields_remarks_Create"],
	read?:ValueTypes["TransactionsFields_remarks_Read"],
	update?:ValueTypes["TransactionsFields_remarks_Update"],
	delete?:ValueTypes["TransactionsFields_remarks_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["TransactionsFields_remarks_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TransactionsFields_remarks_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TransactionsFields_remarks_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TransactionsFields_remarks_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TransactionsFields_redemptionCodes"]: AliasType<{
	create?:ValueTypes["TransactionsFields_redemptionCodes_Create"],
	read?:ValueTypes["TransactionsFields_redemptionCodes_Read"],
	update?:ValueTypes["TransactionsFields_redemptionCodes_Update"],
	delete?:ValueTypes["TransactionsFields_redemptionCodes_Delete"],
	fields?:ValueTypes["TransactionsFields_redemptionCodes_Fields"],
		__typename?: boolean | `@${string}`
}>;
	["TransactionsFields_redemptionCodes_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TransactionsFields_redemptionCodes_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TransactionsFields_redemptionCodes_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TransactionsFields_redemptionCodes_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TransactionsFields_redemptionCodes_Fields"]: AliasType<{
	code?:ValueTypes["TransactionsFields_redemptionCodes_code"],
	id?:ValueTypes["TransactionsFields_redemptionCodes_id"],
		__typename?: boolean | `@${string}`
}>;
	["TransactionsFields_redemptionCodes_code"]: AliasType<{
	create?:ValueTypes["TransactionsFields_redemptionCodes_code_Create"],
	read?:ValueTypes["TransactionsFields_redemptionCodes_code_Read"],
	update?:ValueTypes["TransactionsFields_redemptionCodes_code_Update"],
	delete?:ValueTypes["TransactionsFields_redemptionCodes_code_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["TransactionsFields_redemptionCodes_code_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TransactionsFields_redemptionCodes_code_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TransactionsFields_redemptionCodes_code_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TransactionsFields_redemptionCodes_code_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TransactionsFields_redemptionCodes_id"]: AliasType<{
	create?:ValueTypes["TransactionsFields_redemptionCodes_id_Create"],
	read?:ValueTypes["TransactionsFields_redemptionCodes_id_Read"],
	update?:ValueTypes["TransactionsFields_redemptionCodes_id_Update"],
	delete?:ValueTypes["TransactionsFields_redemptionCodes_id_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["TransactionsFields_redemptionCodes_id_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TransactionsFields_redemptionCodes_id_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TransactionsFields_redemptionCodes_id_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TransactionsFields_redemptionCodes_id_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TransactionsFields_updatedAt"]: AliasType<{
	create?:ValueTypes["TransactionsFields_updatedAt_Create"],
	read?:ValueTypes["TransactionsFields_updatedAt_Read"],
	update?:ValueTypes["TransactionsFields_updatedAt_Update"],
	delete?:ValueTypes["TransactionsFields_updatedAt_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["TransactionsFields_updatedAt_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TransactionsFields_updatedAt_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TransactionsFields_updatedAt_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TransactionsFields_updatedAt_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TransactionsFields_createdAt"]: AliasType<{
	create?:ValueTypes["TransactionsFields_createdAt_Create"],
	read?:ValueTypes["TransactionsFields_createdAt_Read"],
	update?:ValueTypes["TransactionsFields_createdAt_Update"],
	delete?:ValueTypes["TransactionsFields_createdAt_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["TransactionsFields_createdAt_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TransactionsFields_createdAt_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TransactionsFields_createdAt_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TransactionsFields_createdAt_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TransactionsCreateAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TransactionsReadAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TransactionsUpdateAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TransactionsDeleteAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["redemption_codesAccess"]: AliasType<{
	fields?:ValueTypes["RedemptionCodesFields"],
	create?:ValueTypes["RedemptionCodesCreateAccess"],
	read?:ValueTypes["RedemptionCodesReadAccess"],
	update?:ValueTypes["RedemptionCodesUpdateAccess"],
	delete?:ValueTypes["RedemptionCodesDeleteAccess"],
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesFields"]: AliasType<{
	creator?:ValueTypes["RedemptionCodesFields_creator"],
	status?:ValueTypes["RedemptionCodesFields_status"],
	qrCodeId?:ValueTypes["RedemptionCodesFields_qrCodeId"],
	lastCheckedInDateTime?:ValueTypes["RedemptionCodesFields_lastCheckedInDateTime"],
	voidedDateTime?:ValueTypes["RedemptionCodesFields_voidedDateTime"],
	refundedDateTime?:ValueTypes["RedemptionCodesFields_refundedDateTime"],
	eventStartTime?:ValueTypes["RedemptionCodesFields_eventStartTime"],
	eventEndTime?:ValueTypes["RedemptionCodesFields_eventEndTime"],
	eventPriceZone?:ValueTypes["RedemptionCodesFields_eventPriceZone"],
	ownerName?:ValueTypes["RedemptionCodesFields_ownerName"],
	phone?:ValueTypes["RedemptionCodesFields_phone"],
	pdf?:ValueTypes["RedemptionCodesFields_pdf"],
	variants?:ValueTypes["RedemptionCodesFields_variants"],
	productId?:ValueTypes["RedemptionCodesFields_productId"],
	referenceId?:ValueTypes["RedemptionCodesFields_referenceId"],
	productType?:ValueTypes["RedemptionCodesFields_productType"],
	productName?:ValueTypes["RedemptionCodesFields_productName"],
	linkedEvent?:ValueTypes["RedemptionCodesFields_linkedEvent"],
	timableOrderId?:ValueTypes["RedemptionCodesFields_timableOrderId"],
	purchaseDateTime?:ValueTypes["RedemptionCodesFields_purchaseDateTime"],
	changeHistory?:ValueTypes["RedemptionCodesFields_changeHistory"],
	updatedAt?:ValueTypes["RedemptionCodesFields_updatedAt"],
	createdAt?:ValueTypes["RedemptionCodesFields_createdAt"],
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesFields_creator"]: AliasType<{
	create?:ValueTypes["RedemptionCodesFields_creator_Create"],
	read?:ValueTypes["RedemptionCodesFields_creator_Read"],
	update?:ValueTypes["RedemptionCodesFields_creator_Update"],
	delete?:ValueTypes["RedemptionCodesFields_creator_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesFields_creator_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesFields_creator_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesFields_creator_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesFields_creator_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesFields_status"]: AliasType<{
	create?:ValueTypes["RedemptionCodesFields_status_Create"],
	read?:ValueTypes["RedemptionCodesFields_status_Read"],
	update?:ValueTypes["RedemptionCodesFields_status_Update"],
	delete?:ValueTypes["RedemptionCodesFields_status_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesFields_status_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesFields_status_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesFields_status_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesFields_status_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesFields_qrCodeId"]: AliasType<{
	create?:ValueTypes["RedemptionCodesFields_qrCodeId_Create"],
	read?:ValueTypes["RedemptionCodesFields_qrCodeId_Read"],
	update?:ValueTypes["RedemptionCodesFields_qrCodeId_Update"],
	delete?:ValueTypes["RedemptionCodesFields_qrCodeId_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesFields_qrCodeId_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesFields_qrCodeId_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesFields_qrCodeId_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesFields_qrCodeId_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesFields_lastCheckedInDateTime"]: AliasType<{
	create?:ValueTypes["RedemptionCodesFields_lastCheckedInDateTime_Create"],
	read?:ValueTypes["RedemptionCodesFields_lastCheckedInDateTime_Read"],
	update?:ValueTypes["RedemptionCodesFields_lastCheckedInDateTime_Update"],
	delete?:ValueTypes["RedemptionCodesFields_lastCheckedInDateTime_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesFields_lastCheckedInDateTime_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesFields_lastCheckedInDateTime_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesFields_lastCheckedInDateTime_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesFields_lastCheckedInDateTime_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesFields_voidedDateTime"]: AliasType<{
	create?:ValueTypes["RedemptionCodesFields_voidedDateTime_Create"],
	read?:ValueTypes["RedemptionCodesFields_voidedDateTime_Read"],
	update?:ValueTypes["RedemptionCodesFields_voidedDateTime_Update"],
	delete?:ValueTypes["RedemptionCodesFields_voidedDateTime_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesFields_voidedDateTime_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesFields_voidedDateTime_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesFields_voidedDateTime_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesFields_voidedDateTime_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesFields_refundedDateTime"]: AliasType<{
	create?:ValueTypes["RedemptionCodesFields_refundedDateTime_Create"],
	read?:ValueTypes["RedemptionCodesFields_refundedDateTime_Read"],
	update?:ValueTypes["RedemptionCodesFields_refundedDateTime_Update"],
	delete?:ValueTypes["RedemptionCodesFields_refundedDateTime_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesFields_refundedDateTime_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesFields_refundedDateTime_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesFields_refundedDateTime_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesFields_refundedDateTime_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesFields_eventStartTime"]: AliasType<{
	create?:ValueTypes["RedemptionCodesFields_eventStartTime_Create"],
	read?:ValueTypes["RedemptionCodesFields_eventStartTime_Read"],
	update?:ValueTypes["RedemptionCodesFields_eventStartTime_Update"],
	delete?:ValueTypes["RedemptionCodesFields_eventStartTime_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesFields_eventStartTime_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesFields_eventStartTime_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesFields_eventStartTime_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesFields_eventStartTime_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesFields_eventEndTime"]: AliasType<{
	create?:ValueTypes["RedemptionCodesFields_eventEndTime_Create"],
	read?:ValueTypes["RedemptionCodesFields_eventEndTime_Read"],
	update?:ValueTypes["RedemptionCodesFields_eventEndTime_Update"],
	delete?:ValueTypes["RedemptionCodesFields_eventEndTime_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesFields_eventEndTime_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesFields_eventEndTime_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesFields_eventEndTime_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesFields_eventEndTime_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesFields_eventPriceZone"]: AliasType<{
	create?:ValueTypes["RedemptionCodesFields_eventPriceZone_Create"],
	read?:ValueTypes["RedemptionCodesFields_eventPriceZone_Read"],
	update?:ValueTypes["RedemptionCodesFields_eventPriceZone_Update"],
	delete?:ValueTypes["RedemptionCodesFields_eventPriceZone_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesFields_eventPriceZone_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesFields_eventPriceZone_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesFields_eventPriceZone_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesFields_eventPriceZone_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesFields_ownerName"]: AliasType<{
	create?:ValueTypes["RedemptionCodesFields_ownerName_Create"],
	read?:ValueTypes["RedemptionCodesFields_ownerName_Read"],
	update?:ValueTypes["RedemptionCodesFields_ownerName_Update"],
	delete?:ValueTypes["RedemptionCodesFields_ownerName_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesFields_ownerName_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesFields_ownerName_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesFields_ownerName_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesFields_ownerName_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesFields_phone"]: AliasType<{
	create?:ValueTypes["RedemptionCodesFields_phone_Create"],
	read?:ValueTypes["RedemptionCodesFields_phone_Read"],
	update?:ValueTypes["RedemptionCodesFields_phone_Update"],
	delete?:ValueTypes["RedemptionCodesFields_phone_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesFields_phone_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesFields_phone_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesFields_phone_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesFields_phone_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesFields_pdf"]: AliasType<{
	create?:ValueTypes["RedemptionCodesFields_pdf_Create"],
	read?:ValueTypes["RedemptionCodesFields_pdf_Read"],
	update?:ValueTypes["RedemptionCodesFields_pdf_Update"],
	delete?:ValueTypes["RedemptionCodesFields_pdf_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesFields_pdf_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesFields_pdf_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesFields_pdf_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesFields_pdf_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesFields_variants"]: AliasType<{
	create?:ValueTypes["RedemptionCodesFields_variants_Create"],
	read?:ValueTypes["RedemptionCodesFields_variants_Read"],
	update?:ValueTypes["RedemptionCodesFields_variants_Update"],
	delete?:ValueTypes["RedemptionCodesFields_variants_Delete"],
	fields?:ValueTypes["RedemptionCodesFields_variants_Fields"],
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesFields_variants_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesFields_variants_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesFields_variants_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesFields_variants_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesFields_variants_Fields"]: AliasType<{
	variantId?:ValueTypes["RedemptionCodesFields_variants_variantId"],
	name?:ValueTypes["RedemptionCodesFields_variants_name"],
	quantity?:ValueTypes["RedemptionCodesFields_variants_quantity"],
	totalCost?:ValueTypes["RedemptionCodesFields_variants_totalCost"],
	id?:ValueTypes["RedemptionCodesFields_variants_id"],
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesFields_variants_variantId"]: AliasType<{
	create?:ValueTypes["RedemptionCodesFields_variants_variantId_Create"],
	read?:ValueTypes["RedemptionCodesFields_variants_variantId_Read"],
	update?:ValueTypes["RedemptionCodesFields_variants_variantId_Update"],
	delete?:ValueTypes["RedemptionCodesFields_variants_variantId_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesFields_variants_variantId_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesFields_variants_variantId_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesFields_variants_variantId_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesFields_variants_variantId_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesFields_variants_name"]: AliasType<{
	create?:ValueTypes["RedemptionCodesFields_variants_name_Create"],
	read?:ValueTypes["RedemptionCodesFields_variants_name_Read"],
	update?:ValueTypes["RedemptionCodesFields_variants_name_Update"],
	delete?:ValueTypes["RedemptionCodesFields_variants_name_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesFields_variants_name_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesFields_variants_name_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesFields_variants_name_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesFields_variants_name_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesFields_variants_quantity"]: AliasType<{
	create?:ValueTypes["RedemptionCodesFields_variants_quantity_Create"],
	read?:ValueTypes["RedemptionCodesFields_variants_quantity_Read"],
	update?:ValueTypes["RedemptionCodesFields_variants_quantity_Update"],
	delete?:ValueTypes["RedemptionCodesFields_variants_quantity_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesFields_variants_quantity_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesFields_variants_quantity_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesFields_variants_quantity_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesFields_variants_quantity_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesFields_variants_totalCost"]: AliasType<{
	create?:ValueTypes["RedemptionCodesFields_variants_totalCost_Create"],
	read?:ValueTypes["RedemptionCodesFields_variants_totalCost_Read"],
	update?:ValueTypes["RedemptionCodesFields_variants_totalCost_Update"],
	delete?:ValueTypes["RedemptionCodesFields_variants_totalCost_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesFields_variants_totalCost_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesFields_variants_totalCost_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesFields_variants_totalCost_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesFields_variants_totalCost_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesFields_variants_id"]: AliasType<{
	create?:ValueTypes["RedemptionCodesFields_variants_id_Create"],
	read?:ValueTypes["RedemptionCodesFields_variants_id_Read"],
	update?:ValueTypes["RedemptionCodesFields_variants_id_Update"],
	delete?:ValueTypes["RedemptionCodesFields_variants_id_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesFields_variants_id_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesFields_variants_id_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesFields_variants_id_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesFields_variants_id_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesFields_productId"]: AliasType<{
	create?:ValueTypes["RedemptionCodesFields_productId_Create"],
	read?:ValueTypes["RedemptionCodesFields_productId_Read"],
	update?:ValueTypes["RedemptionCodesFields_productId_Update"],
	delete?:ValueTypes["RedemptionCodesFields_productId_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesFields_productId_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesFields_productId_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesFields_productId_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesFields_productId_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesFields_referenceId"]: AliasType<{
	create?:ValueTypes["RedemptionCodesFields_referenceId_Create"],
	read?:ValueTypes["RedemptionCodesFields_referenceId_Read"],
	update?:ValueTypes["RedemptionCodesFields_referenceId_Update"],
	delete?:ValueTypes["RedemptionCodesFields_referenceId_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesFields_referenceId_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesFields_referenceId_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesFields_referenceId_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesFields_referenceId_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesFields_productType"]: AliasType<{
	create?:ValueTypes["RedemptionCodesFields_productType_Create"],
	read?:ValueTypes["RedemptionCodesFields_productType_Read"],
	update?:ValueTypes["RedemptionCodesFields_productType_Update"],
	delete?:ValueTypes["RedemptionCodesFields_productType_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesFields_productType_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesFields_productType_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesFields_productType_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesFields_productType_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesFields_productName"]: AliasType<{
	create?:ValueTypes["RedemptionCodesFields_productName_Create"],
	read?:ValueTypes["RedemptionCodesFields_productName_Read"],
	update?:ValueTypes["RedemptionCodesFields_productName_Update"],
	delete?:ValueTypes["RedemptionCodesFields_productName_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesFields_productName_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesFields_productName_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesFields_productName_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesFields_productName_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesFields_linkedEvent"]: AliasType<{
	create?:ValueTypes["RedemptionCodesFields_linkedEvent_Create"],
	read?:ValueTypes["RedemptionCodesFields_linkedEvent_Read"],
	update?:ValueTypes["RedemptionCodesFields_linkedEvent_Update"],
	delete?:ValueTypes["RedemptionCodesFields_linkedEvent_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesFields_linkedEvent_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesFields_linkedEvent_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesFields_linkedEvent_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesFields_linkedEvent_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesFields_timableOrderId"]: AliasType<{
	create?:ValueTypes["RedemptionCodesFields_timableOrderId_Create"],
	read?:ValueTypes["RedemptionCodesFields_timableOrderId_Read"],
	update?:ValueTypes["RedemptionCodesFields_timableOrderId_Update"],
	delete?:ValueTypes["RedemptionCodesFields_timableOrderId_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesFields_timableOrderId_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesFields_timableOrderId_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesFields_timableOrderId_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesFields_timableOrderId_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesFields_purchaseDateTime"]: AliasType<{
	create?:ValueTypes["RedemptionCodesFields_purchaseDateTime_Create"],
	read?:ValueTypes["RedemptionCodesFields_purchaseDateTime_Read"],
	update?:ValueTypes["RedemptionCodesFields_purchaseDateTime_Update"],
	delete?:ValueTypes["RedemptionCodesFields_purchaseDateTime_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesFields_purchaseDateTime_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesFields_purchaseDateTime_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesFields_purchaseDateTime_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesFields_purchaseDateTime_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesFields_changeHistory"]: AliasType<{
	create?:ValueTypes["RedemptionCodesFields_changeHistory_Create"],
	read?:ValueTypes["RedemptionCodesFields_changeHistory_Read"],
	update?:ValueTypes["RedemptionCodesFields_changeHistory_Update"],
	delete?:ValueTypes["RedemptionCodesFields_changeHistory_Delete"],
	fields?:ValueTypes["RedemptionCodesFields_changeHistory_Fields"],
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesFields_changeHistory_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesFields_changeHistory_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesFields_changeHistory_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesFields_changeHistory_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesFields_changeHistory_Fields"]: AliasType<{
	timestamp?:ValueTypes["RedemptionCodesFields_changeHistory_timestamp"],
	field?:ValueTypes["RedemptionCodesFields_changeHistory_field"],
	oldValue?:ValueTypes["RedemptionCodesFields_changeHistory_oldValue"],
	newValue?:ValueTypes["RedemptionCodesFields_changeHistory_newValue"],
	id?:ValueTypes["RedemptionCodesFields_changeHistory_id"],
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesFields_changeHistory_timestamp"]: AliasType<{
	create?:ValueTypes["RedemptionCodesFields_changeHistory_timestamp_Create"],
	read?:ValueTypes["RedemptionCodesFields_changeHistory_timestamp_Read"],
	update?:ValueTypes["RedemptionCodesFields_changeHistory_timestamp_Update"],
	delete?:ValueTypes["RedemptionCodesFields_changeHistory_timestamp_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesFields_changeHistory_timestamp_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesFields_changeHistory_timestamp_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesFields_changeHistory_timestamp_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesFields_changeHistory_timestamp_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesFields_changeHistory_field"]: AliasType<{
	create?:ValueTypes["RedemptionCodesFields_changeHistory_field_Create"],
	read?:ValueTypes["RedemptionCodesFields_changeHistory_field_Read"],
	update?:ValueTypes["RedemptionCodesFields_changeHistory_field_Update"],
	delete?:ValueTypes["RedemptionCodesFields_changeHistory_field_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesFields_changeHistory_field_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesFields_changeHistory_field_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesFields_changeHistory_field_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesFields_changeHistory_field_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesFields_changeHistory_oldValue"]: AliasType<{
	create?:ValueTypes["RedemptionCodesFields_changeHistory_oldValue_Create"],
	read?:ValueTypes["RedemptionCodesFields_changeHistory_oldValue_Read"],
	update?:ValueTypes["RedemptionCodesFields_changeHistory_oldValue_Update"],
	delete?:ValueTypes["RedemptionCodesFields_changeHistory_oldValue_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesFields_changeHistory_oldValue_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesFields_changeHistory_oldValue_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesFields_changeHistory_oldValue_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesFields_changeHistory_oldValue_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesFields_changeHistory_newValue"]: AliasType<{
	create?:ValueTypes["RedemptionCodesFields_changeHistory_newValue_Create"],
	read?:ValueTypes["RedemptionCodesFields_changeHistory_newValue_Read"],
	update?:ValueTypes["RedemptionCodesFields_changeHistory_newValue_Update"],
	delete?:ValueTypes["RedemptionCodesFields_changeHistory_newValue_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesFields_changeHistory_newValue_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesFields_changeHistory_newValue_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesFields_changeHistory_newValue_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesFields_changeHistory_newValue_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesFields_changeHistory_id"]: AliasType<{
	create?:ValueTypes["RedemptionCodesFields_changeHistory_id_Create"],
	read?:ValueTypes["RedemptionCodesFields_changeHistory_id_Read"],
	update?:ValueTypes["RedemptionCodesFields_changeHistory_id_Update"],
	delete?:ValueTypes["RedemptionCodesFields_changeHistory_id_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesFields_changeHistory_id_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesFields_changeHistory_id_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesFields_changeHistory_id_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesFields_changeHistory_id_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesFields_updatedAt"]: AliasType<{
	create?:ValueTypes["RedemptionCodesFields_updatedAt_Create"],
	read?:ValueTypes["RedemptionCodesFields_updatedAt_Read"],
	update?:ValueTypes["RedemptionCodesFields_updatedAt_Update"],
	delete?:ValueTypes["RedemptionCodesFields_updatedAt_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesFields_updatedAt_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesFields_updatedAt_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesFields_updatedAt_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesFields_updatedAt_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesFields_createdAt"]: AliasType<{
	create?:ValueTypes["RedemptionCodesFields_createdAt_Create"],
	read?:ValueTypes["RedemptionCodesFields_createdAt_Read"],
	update?:ValueTypes["RedemptionCodesFields_createdAt_Update"],
	delete?:ValueTypes["RedemptionCodesFields_createdAt_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesFields_createdAt_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesFields_createdAt_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesFields_createdAt_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesFields_createdAt_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesCreateAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesReadAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesUpdateAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesDeleteAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["redemption_pdfAccess"]: AliasType<{
	fields?:ValueTypes["RedemptionPdfFields"],
	create?:ValueTypes["RedemptionPdfCreateAccess"],
	read?:ValueTypes["RedemptionPdfReadAccess"],
	update?:ValueTypes["RedemptionPdfUpdateAccess"],
	delete?:ValueTypes["RedemptionPdfDeleteAccess"],
		__typename?: boolean | `@${string}`
}>;
	["RedemptionPdfFields"]: AliasType<{
	updatedAt?:ValueTypes["RedemptionPdfFields_updatedAt"],
	createdAt?:ValueTypes["RedemptionPdfFields_createdAt"],
	url?:ValueTypes["RedemptionPdfFields_url"],
	filename?:ValueTypes["RedemptionPdfFields_filename"],
	mimeType?:ValueTypes["RedemptionPdfFields_mimeType"],
	filesize?:ValueTypes["RedemptionPdfFields_filesize"],
	width?:ValueTypes["RedemptionPdfFields_width"],
	height?:ValueTypes["RedemptionPdfFields_height"],
	focalX?:ValueTypes["RedemptionPdfFields_focalX"],
	focalY?:ValueTypes["RedemptionPdfFields_focalY"],
		__typename?: boolean | `@${string}`
}>;
	["RedemptionPdfFields_updatedAt"]: AliasType<{
	create?:ValueTypes["RedemptionPdfFields_updatedAt_Create"],
	read?:ValueTypes["RedemptionPdfFields_updatedAt_Read"],
	update?:ValueTypes["RedemptionPdfFields_updatedAt_Update"],
	delete?:ValueTypes["RedemptionPdfFields_updatedAt_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["RedemptionPdfFields_updatedAt_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionPdfFields_updatedAt_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionPdfFields_updatedAt_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionPdfFields_updatedAt_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionPdfFields_createdAt"]: AliasType<{
	create?:ValueTypes["RedemptionPdfFields_createdAt_Create"],
	read?:ValueTypes["RedemptionPdfFields_createdAt_Read"],
	update?:ValueTypes["RedemptionPdfFields_createdAt_Update"],
	delete?:ValueTypes["RedemptionPdfFields_createdAt_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["RedemptionPdfFields_createdAt_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionPdfFields_createdAt_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionPdfFields_createdAt_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionPdfFields_createdAt_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionPdfFields_url"]: AliasType<{
	create?:ValueTypes["RedemptionPdfFields_url_Create"],
	read?:ValueTypes["RedemptionPdfFields_url_Read"],
	update?:ValueTypes["RedemptionPdfFields_url_Update"],
	delete?:ValueTypes["RedemptionPdfFields_url_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["RedemptionPdfFields_url_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionPdfFields_url_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionPdfFields_url_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionPdfFields_url_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionPdfFields_filename"]: AliasType<{
	create?:ValueTypes["RedemptionPdfFields_filename_Create"],
	read?:ValueTypes["RedemptionPdfFields_filename_Read"],
	update?:ValueTypes["RedemptionPdfFields_filename_Update"],
	delete?:ValueTypes["RedemptionPdfFields_filename_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["RedemptionPdfFields_filename_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionPdfFields_filename_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionPdfFields_filename_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionPdfFields_filename_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionPdfFields_mimeType"]: AliasType<{
	create?:ValueTypes["RedemptionPdfFields_mimeType_Create"],
	read?:ValueTypes["RedemptionPdfFields_mimeType_Read"],
	update?:ValueTypes["RedemptionPdfFields_mimeType_Update"],
	delete?:ValueTypes["RedemptionPdfFields_mimeType_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["RedemptionPdfFields_mimeType_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionPdfFields_mimeType_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionPdfFields_mimeType_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionPdfFields_mimeType_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionPdfFields_filesize"]: AliasType<{
	create?:ValueTypes["RedemptionPdfFields_filesize_Create"],
	read?:ValueTypes["RedemptionPdfFields_filesize_Read"],
	update?:ValueTypes["RedemptionPdfFields_filesize_Update"],
	delete?:ValueTypes["RedemptionPdfFields_filesize_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["RedemptionPdfFields_filesize_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionPdfFields_filesize_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionPdfFields_filesize_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionPdfFields_filesize_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionPdfFields_width"]: AliasType<{
	create?:ValueTypes["RedemptionPdfFields_width_Create"],
	read?:ValueTypes["RedemptionPdfFields_width_Read"],
	update?:ValueTypes["RedemptionPdfFields_width_Update"],
	delete?:ValueTypes["RedemptionPdfFields_width_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["RedemptionPdfFields_width_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionPdfFields_width_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionPdfFields_width_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionPdfFields_width_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionPdfFields_height"]: AliasType<{
	create?:ValueTypes["RedemptionPdfFields_height_Create"],
	read?:ValueTypes["RedemptionPdfFields_height_Read"],
	update?:ValueTypes["RedemptionPdfFields_height_Update"],
	delete?:ValueTypes["RedemptionPdfFields_height_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["RedemptionPdfFields_height_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionPdfFields_height_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionPdfFields_height_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionPdfFields_height_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionPdfFields_focalX"]: AliasType<{
	create?:ValueTypes["RedemptionPdfFields_focalX_Create"],
	read?:ValueTypes["RedemptionPdfFields_focalX_Read"],
	update?:ValueTypes["RedemptionPdfFields_focalX_Update"],
	delete?:ValueTypes["RedemptionPdfFields_focalX_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["RedemptionPdfFields_focalX_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionPdfFields_focalX_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionPdfFields_focalX_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionPdfFields_focalX_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionPdfFields_focalY"]: AliasType<{
	create?:ValueTypes["RedemptionPdfFields_focalY_Create"],
	read?:ValueTypes["RedemptionPdfFields_focalY_Read"],
	update?:ValueTypes["RedemptionPdfFields_focalY_Update"],
	delete?:ValueTypes["RedemptionPdfFields_focalY_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["RedemptionPdfFields_focalY_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionPdfFields_focalY_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionPdfFields_focalY_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionPdfFields_focalY_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionPdfCreateAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionPdfReadAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionPdfUpdateAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionPdfDeleteAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["check_in_redemptionAccess"]: AliasType<{
	fields?:ValueTypes["CheckInRedemptionFields"],
	create?:ValueTypes["CheckInRedemptionCreateAccess"],
	read?:ValueTypes["CheckInRedemptionReadAccess"],
	update?:ValueTypes["CheckInRedemptionUpdateAccess"],
	delete?:ValueTypes["CheckInRedemptionDeleteAccess"],
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionFields"]: AliasType<{
	creator?:ValueTypes["CheckInRedemptionFields_creator"],
	qrCodeId?:ValueTypes["CheckInRedemptionFields_qrCodeId"],
	codeownerName?:ValueTypes["CheckInRedemptionFields_codeownerName"],
	name?:ValueTypes["CheckInRedemptionFields_name"],
	phone?:ValueTypes["CheckInRedemptionFields_phone"],
	linkedEvent?:ValueTypes["CheckInRedemptionFields_linkedEvent"],
	entryNumber?:ValueTypes["CheckInRedemptionFields_entryNumber"],
	scanAt?:ValueTypes["CheckInRedemptionFields_scanAt"],
	uploadAt?:ValueTypes["CheckInRedemptionFields_uploadAt"],
	status?:ValueTypes["CheckInRedemptionFields_status"],
	failReason?:ValueTypes["CheckInRedemptionFields_failReason"],
	variants?:ValueTypes["CheckInRedemptionFields_variants"],
	checkInAppSelectionId?:ValueTypes["CheckInRedemptionFields_checkInAppSelectionId"],
	qrCodeStatus?:ValueTypes["CheckInRedemptionFields_qrCodeStatus"],
	productType?:ValueTypes["CheckInRedemptionFields_productType"],
	updatedAt?:ValueTypes["CheckInRedemptionFields_updatedAt"],
	createdAt?:ValueTypes["CheckInRedemptionFields_createdAt"],
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionFields_creator"]: AliasType<{
	create?:ValueTypes["CheckInRedemptionFields_creator_Create"],
	read?:ValueTypes["CheckInRedemptionFields_creator_Read"],
	update?:ValueTypes["CheckInRedemptionFields_creator_Update"],
	delete?:ValueTypes["CheckInRedemptionFields_creator_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionFields_creator_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionFields_creator_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionFields_creator_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionFields_creator_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionFields_qrCodeId"]: AliasType<{
	create?:ValueTypes["CheckInRedemptionFields_qrCodeId_Create"],
	read?:ValueTypes["CheckInRedemptionFields_qrCodeId_Read"],
	update?:ValueTypes["CheckInRedemptionFields_qrCodeId_Update"],
	delete?:ValueTypes["CheckInRedemptionFields_qrCodeId_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionFields_qrCodeId_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionFields_qrCodeId_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionFields_qrCodeId_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionFields_qrCodeId_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionFields_codeownerName"]: AliasType<{
	create?:ValueTypes["CheckInRedemptionFields_codeownerName_Create"],
	read?:ValueTypes["CheckInRedemptionFields_codeownerName_Read"],
	update?:ValueTypes["CheckInRedemptionFields_codeownerName_Update"],
	delete?:ValueTypes["CheckInRedemptionFields_codeownerName_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionFields_codeownerName_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionFields_codeownerName_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionFields_codeownerName_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionFields_codeownerName_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionFields_name"]: AliasType<{
	create?:ValueTypes["CheckInRedemptionFields_name_Create"],
	read?:ValueTypes["CheckInRedemptionFields_name_Read"],
	update?:ValueTypes["CheckInRedemptionFields_name_Update"],
	delete?:ValueTypes["CheckInRedemptionFields_name_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionFields_name_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionFields_name_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionFields_name_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionFields_name_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionFields_phone"]: AliasType<{
	create?:ValueTypes["CheckInRedemptionFields_phone_Create"],
	read?:ValueTypes["CheckInRedemptionFields_phone_Read"],
	update?:ValueTypes["CheckInRedemptionFields_phone_Update"],
	delete?:ValueTypes["CheckInRedemptionFields_phone_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionFields_phone_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionFields_phone_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionFields_phone_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionFields_phone_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionFields_linkedEvent"]: AliasType<{
	create?:ValueTypes["CheckInRedemptionFields_linkedEvent_Create"],
	read?:ValueTypes["CheckInRedemptionFields_linkedEvent_Read"],
	update?:ValueTypes["CheckInRedemptionFields_linkedEvent_Update"],
	delete?:ValueTypes["CheckInRedemptionFields_linkedEvent_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionFields_linkedEvent_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionFields_linkedEvent_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionFields_linkedEvent_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionFields_linkedEvent_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionFields_entryNumber"]: AliasType<{
	create?:ValueTypes["CheckInRedemptionFields_entryNumber_Create"],
	read?:ValueTypes["CheckInRedemptionFields_entryNumber_Read"],
	update?:ValueTypes["CheckInRedemptionFields_entryNumber_Update"],
	delete?:ValueTypes["CheckInRedemptionFields_entryNumber_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionFields_entryNumber_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionFields_entryNumber_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionFields_entryNumber_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionFields_entryNumber_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionFields_scanAt"]: AliasType<{
	create?:ValueTypes["CheckInRedemptionFields_scanAt_Create"],
	read?:ValueTypes["CheckInRedemptionFields_scanAt_Read"],
	update?:ValueTypes["CheckInRedemptionFields_scanAt_Update"],
	delete?:ValueTypes["CheckInRedemptionFields_scanAt_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionFields_scanAt_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionFields_scanAt_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionFields_scanAt_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionFields_scanAt_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionFields_uploadAt"]: AliasType<{
	create?:ValueTypes["CheckInRedemptionFields_uploadAt_Create"],
	read?:ValueTypes["CheckInRedemptionFields_uploadAt_Read"],
	update?:ValueTypes["CheckInRedemptionFields_uploadAt_Update"],
	delete?:ValueTypes["CheckInRedemptionFields_uploadAt_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionFields_uploadAt_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionFields_uploadAt_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionFields_uploadAt_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionFields_uploadAt_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionFields_status"]: AliasType<{
	create?:ValueTypes["CheckInRedemptionFields_status_Create"],
	read?:ValueTypes["CheckInRedemptionFields_status_Read"],
	update?:ValueTypes["CheckInRedemptionFields_status_Update"],
	delete?:ValueTypes["CheckInRedemptionFields_status_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionFields_status_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionFields_status_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionFields_status_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionFields_status_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionFields_failReason"]: AliasType<{
	create?:ValueTypes["CheckInRedemptionFields_failReason_Create"],
	read?:ValueTypes["CheckInRedemptionFields_failReason_Read"],
	update?:ValueTypes["CheckInRedemptionFields_failReason_Update"],
	delete?:ValueTypes["CheckInRedemptionFields_failReason_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionFields_failReason_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionFields_failReason_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionFields_failReason_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionFields_failReason_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionFields_variants"]: AliasType<{
	create?:ValueTypes["CheckInRedemptionFields_variants_Create"],
	read?:ValueTypes["CheckInRedemptionFields_variants_Read"],
	update?:ValueTypes["CheckInRedemptionFields_variants_Update"],
	delete?:ValueTypes["CheckInRedemptionFields_variants_Delete"],
	fields?:ValueTypes["CheckInRedemptionFields_variants_Fields"],
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionFields_variants_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionFields_variants_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionFields_variants_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionFields_variants_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionFields_variants_Fields"]: AliasType<{
	variantId?:ValueTypes["CheckInRedemptionFields_variants_variantId"],
	name?:ValueTypes["CheckInRedemptionFields_variants_name"],
	quantity?:ValueTypes["CheckInRedemptionFields_variants_quantity"],
	totalCost?:ValueTypes["CheckInRedemptionFields_variants_totalCost"],
	id?:ValueTypes["CheckInRedemptionFields_variants_id"],
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionFields_variants_variantId"]: AliasType<{
	create?:ValueTypes["CheckInRedemptionFields_variants_variantId_Create"],
	read?:ValueTypes["CheckInRedemptionFields_variants_variantId_Read"],
	update?:ValueTypes["CheckInRedemptionFields_variants_variantId_Update"],
	delete?:ValueTypes["CheckInRedemptionFields_variants_variantId_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionFields_variants_variantId_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionFields_variants_variantId_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionFields_variants_variantId_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionFields_variants_variantId_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionFields_variants_name"]: AliasType<{
	create?:ValueTypes["CheckInRedemptionFields_variants_name_Create"],
	read?:ValueTypes["CheckInRedemptionFields_variants_name_Read"],
	update?:ValueTypes["CheckInRedemptionFields_variants_name_Update"],
	delete?:ValueTypes["CheckInRedemptionFields_variants_name_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionFields_variants_name_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionFields_variants_name_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionFields_variants_name_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionFields_variants_name_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionFields_variants_quantity"]: AliasType<{
	create?:ValueTypes["CheckInRedemptionFields_variants_quantity_Create"],
	read?:ValueTypes["CheckInRedemptionFields_variants_quantity_Read"],
	update?:ValueTypes["CheckInRedemptionFields_variants_quantity_Update"],
	delete?:ValueTypes["CheckInRedemptionFields_variants_quantity_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionFields_variants_quantity_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionFields_variants_quantity_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionFields_variants_quantity_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionFields_variants_quantity_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionFields_variants_totalCost"]: AliasType<{
	create?:ValueTypes["CheckInRedemptionFields_variants_totalCost_Create"],
	read?:ValueTypes["CheckInRedemptionFields_variants_totalCost_Read"],
	update?:ValueTypes["CheckInRedemptionFields_variants_totalCost_Update"],
	delete?:ValueTypes["CheckInRedemptionFields_variants_totalCost_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionFields_variants_totalCost_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionFields_variants_totalCost_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionFields_variants_totalCost_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionFields_variants_totalCost_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionFields_variants_id"]: AliasType<{
	create?:ValueTypes["CheckInRedemptionFields_variants_id_Create"],
	read?:ValueTypes["CheckInRedemptionFields_variants_id_Read"],
	update?:ValueTypes["CheckInRedemptionFields_variants_id_Update"],
	delete?:ValueTypes["CheckInRedemptionFields_variants_id_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionFields_variants_id_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionFields_variants_id_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionFields_variants_id_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionFields_variants_id_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionFields_checkInAppSelectionId"]: AliasType<{
	create?:ValueTypes["CheckInRedemptionFields_checkInAppSelectionId_Create"],
	read?:ValueTypes["CheckInRedemptionFields_checkInAppSelectionId_Read"],
	update?:ValueTypes["CheckInRedemptionFields_checkInAppSelectionId_Update"],
	delete?:ValueTypes["CheckInRedemptionFields_checkInAppSelectionId_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionFields_checkInAppSelectionId_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionFields_checkInAppSelectionId_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionFields_checkInAppSelectionId_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionFields_checkInAppSelectionId_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionFields_qrCodeStatus"]: AliasType<{
	create?:ValueTypes["CheckInRedemptionFields_qrCodeStatus_Create"],
	read?:ValueTypes["CheckInRedemptionFields_qrCodeStatus_Read"],
	update?:ValueTypes["CheckInRedemptionFields_qrCodeStatus_Update"],
	delete?:ValueTypes["CheckInRedemptionFields_qrCodeStatus_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionFields_qrCodeStatus_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionFields_qrCodeStatus_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionFields_qrCodeStatus_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionFields_qrCodeStatus_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionFields_productType"]: AliasType<{
	create?:ValueTypes["CheckInRedemptionFields_productType_Create"],
	read?:ValueTypes["CheckInRedemptionFields_productType_Read"],
	update?:ValueTypes["CheckInRedemptionFields_productType_Update"],
	delete?:ValueTypes["CheckInRedemptionFields_productType_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionFields_productType_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionFields_productType_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionFields_productType_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionFields_productType_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionFields_updatedAt"]: AliasType<{
	create?:ValueTypes["CheckInRedemptionFields_updatedAt_Create"],
	read?:ValueTypes["CheckInRedemptionFields_updatedAt_Read"],
	update?:ValueTypes["CheckInRedemptionFields_updatedAt_Update"],
	delete?:ValueTypes["CheckInRedemptionFields_updatedAt_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionFields_updatedAt_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionFields_updatedAt_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionFields_updatedAt_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionFields_updatedAt_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionFields_createdAt"]: AliasType<{
	create?:ValueTypes["CheckInRedemptionFields_createdAt_Create"],
	read?:ValueTypes["CheckInRedemptionFields_createdAt_Read"],
	update?:ValueTypes["CheckInRedemptionFields_createdAt_Update"],
	delete?:ValueTypes["CheckInRedemptionFields_createdAt_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionFields_createdAt_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionFields_createdAt_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionFields_createdAt_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionFields_createdAt_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionCreateAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionReadAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionUpdateAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionDeleteAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["usersAccess"]: AliasType<{
	fields?:ValueTypes["UsersFields"],
	create?:ValueTypes["UsersCreateAccess"],
	read?:ValueTypes["UsersReadAccess"],
	update?:ValueTypes["UsersUpdateAccess"],
	delete?:ValueTypes["UsersDeleteAccess"],
	unlock?:ValueTypes["UsersUnlockAccess"],
		__typename?: boolean | `@${string}`
}>;
	["UsersFields"]: AliasType<{
	region?:ValueTypes["UsersFields_region"],
	name?:ValueTypes["UsersFields_name"],
	displayEmail?:ValueTypes["UsersFields_displayEmail"],
	legacyUsername?:ValueTypes["UsersFields_legacyUsername"],
	roles?:ValueTypes["UsersFields_roles"],
	member?:ValueTypes["UsersFields_member"],
	organiser?:ValueTypes["UsersFields_organiser"],
	emailVerified?:ValueTypes["UsersFields_emailVerified"],
	sso?:ValueTypes["UsersFields_sso"],
	oldPassword?:ValueTypes["UsersFields_oldPassword"],
	status?:ValueTypes["UsersFields_status"],
	updatedAt?:ValueTypes["UsersFields_updatedAt"],
	createdAt?:ValueTypes["UsersFields_createdAt"],
	email?:ValueTypes["UsersFields_email"],
	password?:ValueTypes["UsersFields_password"],
		__typename?: boolean | `@${string}`
}>;
	["UsersFields_region"]: AliasType<{
	create?:ValueTypes["UsersFields_region_Create"],
	read?:ValueTypes["UsersFields_region_Read"],
	update?:ValueTypes["UsersFields_region_Update"],
	delete?:ValueTypes["UsersFields_region_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["UsersFields_region_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersFields_region_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersFields_region_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersFields_region_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersFields_name"]: AliasType<{
	create?:ValueTypes["UsersFields_name_Create"],
	read?:ValueTypes["UsersFields_name_Read"],
	update?:ValueTypes["UsersFields_name_Update"],
	delete?:ValueTypes["UsersFields_name_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["UsersFields_name_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersFields_name_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersFields_name_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersFields_name_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersFields_displayEmail"]: AliasType<{
	create?:ValueTypes["UsersFields_displayEmail_Create"],
	read?:ValueTypes["UsersFields_displayEmail_Read"],
	update?:ValueTypes["UsersFields_displayEmail_Update"],
	delete?:ValueTypes["UsersFields_displayEmail_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["UsersFields_displayEmail_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersFields_displayEmail_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersFields_displayEmail_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersFields_displayEmail_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersFields_legacyUsername"]: AliasType<{
	create?:ValueTypes["UsersFields_legacyUsername_Create"],
	read?:ValueTypes["UsersFields_legacyUsername_Read"],
	update?:ValueTypes["UsersFields_legacyUsername_Update"],
	delete?:ValueTypes["UsersFields_legacyUsername_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["UsersFields_legacyUsername_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersFields_legacyUsername_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersFields_legacyUsername_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersFields_legacyUsername_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersFields_roles"]: AliasType<{
	create?:ValueTypes["UsersFields_roles_Create"],
	read?:ValueTypes["UsersFields_roles_Read"],
	update?:ValueTypes["UsersFields_roles_Update"],
	delete?:ValueTypes["UsersFields_roles_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["UsersFields_roles_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersFields_roles_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersFields_roles_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersFields_roles_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersFields_member"]: AliasType<{
	create?:ValueTypes["UsersFields_member_Create"],
	read?:ValueTypes["UsersFields_member_Read"],
	update?:ValueTypes["UsersFields_member_Update"],
	delete?:ValueTypes["UsersFields_member_Delete"],
	fields?:ValueTypes["UsersFields_member_Fields"],
		__typename?: boolean | `@${string}`
}>;
	["UsersFields_member_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersFields_member_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersFields_member_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersFields_member_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersFields_member_Fields"]: AliasType<{
	region?:ValueTypes["UsersFields_member_region"],
	language?:ValueTypes["UsersFields_member_language"],
	phone?:ValueTypes["UsersFields_member_phone"],
	avatar?:ValueTypes["UsersFields_member_avatar"],
	birthday?:ValueTypes["UsersFields_member_birthday"],
	gender?:ValueTypes["UsersFields_member_gender"],
	categories?:ValueTypes["UsersFields_member_categories"],
		__typename?: boolean | `@${string}`
}>;
	["UsersFields_member_region"]: AliasType<{
	create?:ValueTypes["UsersFields_member_region_Create"],
	read?:ValueTypes["UsersFields_member_region_Read"],
	update?:ValueTypes["UsersFields_member_region_Update"],
	delete?:ValueTypes["UsersFields_member_region_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["UsersFields_member_region_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersFields_member_region_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersFields_member_region_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersFields_member_region_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersFields_member_language"]: AliasType<{
	create?:ValueTypes["UsersFields_member_language_Create"],
	read?:ValueTypes["UsersFields_member_language_Read"],
	update?:ValueTypes["UsersFields_member_language_Update"],
	delete?:ValueTypes["UsersFields_member_language_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["UsersFields_member_language_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersFields_member_language_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersFields_member_language_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersFields_member_language_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersFields_member_phone"]: AliasType<{
	create?:ValueTypes["UsersFields_member_phone_Create"],
	read?:ValueTypes["UsersFields_member_phone_Read"],
	update?:ValueTypes["UsersFields_member_phone_Update"],
	delete?:ValueTypes["UsersFields_member_phone_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["UsersFields_member_phone_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersFields_member_phone_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersFields_member_phone_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersFields_member_phone_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersFields_member_avatar"]: AliasType<{
	create?:ValueTypes["UsersFields_member_avatar_Create"],
	read?:ValueTypes["UsersFields_member_avatar_Read"],
	update?:ValueTypes["UsersFields_member_avatar_Update"],
	delete?:ValueTypes["UsersFields_member_avatar_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["UsersFields_member_avatar_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersFields_member_avatar_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersFields_member_avatar_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersFields_member_avatar_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersFields_member_birthday"]: AliasType<{
	create?:ValueTypes["UsersFields_member_birthday_Create"],
	read?:ValueTypes["UsersFields_member_birthday_Read"],
	update?:ValueTypes["UsersFields_member_birthday_Update"],
	delete?:ValueTypes["UsersFields_member_birthday_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["UsersFields_member_birthday_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersFields_member_birthday_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersFields_member_birthday_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersFields_member_birthday_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersFields_member_gender"]: AliasType<{
	create?:ValueTypes["UsersFields_member_gender_Create"],
	read?:ValueTypes["UsersFields_member_gender_Read"],
	update?:ValueTypes["UsersFields_member_gender_Update"],
	delete?:ValueTypes["UsersFields_member_gender_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["UsersFields_member_gender_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersFields_member_gender_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersFields_member_gender_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersFields_member_gender_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersFields_member_categories"]: AliasType<{
	create?:ValueTypes["UsersFields_member_categories_Create"],
	read?:ValueTypes["UsersFields_member_categories_Read"],
	update?:ValueTypes["UsersFields_member_categories_Update"],
	delete?:ValueTypes["UsersFields_member_categories_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["UsersFields_member_categories_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersFields_member_categories_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersFields_member_categories_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersFields_member_categories_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersFields_organiser"]: AliasType<{
	create?:ValueTypes["UsersFields_organiser_Create"],
	read?:ValueTypes["UsersFields_organiser_Read"],
	update?:ValueTypes["UsersFields_organiser_Update"],
	delete?:ValueTypes["UsersFields_organiser_Delete"],
	fields?:ValueTypes["UsersFields_organiser_Fields"],
		__typename?: boolean | `@${string}`
}>;
	["UsersFields_organiser_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersFields_organiser_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersFields_organiser_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersFields_organiser_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersFields_organiser_Fields"]: AliasType<{
	logo?:ValueTypes["UsersFields_organiser_logo"],
	banner?:ValueTypes["UsersFields_organiser_banner"],
	description?:ValueTypes["UsersFields_organiser_description"],
	foundedIn?:ValueTypes["UsersFields_organiser_foundedIn"],
	address?:ValueTypes["UsersFields_organiser_address"],
	website?:ValueTypes["UsersFields_organiser_website"],
	facebook?:ValueTypes["UsersFields_organiser_facebook"],
	instagram?:ValueTypes["UsersFields_organiser_instagram"],
	language?:ValueTypes["UsersFields_organiser_language"],
	phone?:ValueTypes["UsersFields_organiser_phone"],
	followers?:ValueTypes["UsersFields_organiser_followers"],
		__typename?: boolean | `@${string}`
}>;
	["UsersFields_organiser_logo"]: AliasType<{
	create?:ValueTypes["UsersFields_organiser_logo_Create"],
	read?:ValueTypes["UsersFields_organiser_logo_Read"],
	update?:ValueTypes["UsersFields_organiser_logo_Update"],
	delete?:ValueTypes["UsersFields_organiser_logo_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["UsersFields_organiser_logo_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersFields_organiser_logo_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersFields_organiser_logo_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersFields_organiser_logo_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersFields_organiser_banner"]: AliasType<{
	create?:ValueTypes["UsersFields_organiser_banner_Create"],
	read?:ValueTypes["UsersFields_organiser_banner_Read"],
	update?:ValueTypes["UsersFields_organiser_banner_Update"],
	delete?:ValueTypes["UsersFields_organiser_banner_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["UsersFields_organiser_banner_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersFields_organiser_banner_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersFields_organiser_banner_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersFields_organiser_banner_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersFields_organiser_description"]: AliasType<{
	create?:ValueTypes["UsersFields_organiser_description_Create"],
	read?:ValueTypes["UsersFields_organiser_description_Read"],
	update?:ValueTypes["UsersFields_organiser_description_Update"],
	delete?:ValueTypes["UsersFields_organiser_description_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["UsersFields_organiser_description_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersFields_organiser_description_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersFields_organiser_description_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersFields_organiser_description_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersFields_organiser_foundedIn"]: AliasType<{
	create?:ValueTypes["UsersFields_organiser_foundedIn_Create"],
	read?:ValueTypes["UsersFields_organiser_foundedIn_Read"],
	update?:ValueTypes["UsersFields_organiser_foundedIn_Update"],
	delete?:ValueTypes["UsersFields_organiser_foundedIn_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["UsersFields_organiser_foundedIn_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersFields_organiser_foundedIn_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersFields_organiser_foundedIn_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersFields_organiser_foundedIn_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersFields_organiser_address"]: AliasType<{
	create?:ValueTypes["UsersFields_organiser_address_Create"],
	read?:ValueTypes["UsersFields_organiser_address_Read"],
	update?:ValueTypes["UsersFields_organiser_address_Update"],
	delete?:ValueTypes["UsersFields_organiser_address_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["UsersFields_organiser_address_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersFields_organiser_address_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersFields_organiser_address_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersFields_organiser_address_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersFields_organiser_website"]: AliasType<{
	create?:ValueTypes["UsersFields_organiser_website_Create"],
	read?:ValueTypes["UsersFields_organiser_website_Read"],
	update?:ValueTypes["UsersFields_organiser_website_Update"],
	delete?:ValueTypes["UsersFields_organiser_website_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["UsersFields_organiser_website_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersFields_organiser_website_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersFields_organiser_website_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersFields_organiser_website_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersFields_organiser_facebook"]: AliasType<{
	create?:ValueTypes["UsersFields_organiser_facebook_Create"],
	read?:ValueTypes["UsersFields_organiser_facebook_Read"],
	update?:ValueTypes["UsersFields_organiser_facebook_Update"],
	delete?:ValueTypes["UsersFields_organiser_facebook_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["UsersFields_organiser_facebook_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersFields_organiser_facebook_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersFields_organiser_facebook_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersFields_organiser_facebook_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersFields_organiser_instagram"]: AliasType<{
	create?:ValueTypes["UsersFields_organiser_instagram_Create"],
	read?:ValueTypes["UsersFields_organiser_instagram_Read"],
	update?:ValueTypes["UsersFields_organiser_instagram_Update"],
	delete?:ValueTypes["UsersFields_organiser_instagram_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["UsersFields_organiser_instagram_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersFields_organiser_instagram_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersFields_organiser_instagram_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersFields_organiser_instagram_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersFields_organiser_language"]: AliasType<{
	create?:ValueTypes["UsersFields_organiser_language_Create"],
	read?:ValueTypes["UsersFields_organiser_language_Read"],
	update?:ValueTypes["UsersFields_organiser_language_Update"],
	delete?:ValueTypes["UsersFields_organiser_language_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["UsersFields_organiser_language_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersFields_organiser_language_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersFields_organiser_language_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersFields_organiser_language_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersFields_organiser_phone"]: AliasType<{
	create?:ValueTypes["UsersFields_organiser_phone_Create"],
	read?:ValueTypes["UsersFields_organiser_phone_Read"],
	update?:ValueTypes["UsersFields_organiser_phone_Update"],
	delete?:ValueTypes["UsersFields_organiser_phone_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["UsersFields_organiser_phone_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersFields_organiser_phone_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersFields_organiser_phone_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersFields_organiser_phone_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersFields_organiser_followers"]: AliasType<{
	create?:ValueTypes["UsersFields_organiser_followers_Create"],
	read?:ValueTypes["UsersFields_organiser_followers_Read"],
	update?:ValueTypes["UsersFields_organiser_followers_Update"],
	delete?:ValueTypes["UsersFields_organiser_followers_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["UsersFields_organiser_followers_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersFields_organiser_followers_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersFields_organiser_followers_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersFields_organiser_followers_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersFields_emailVerified"]: AliasType<{
	create?:ValueTypes["UsersFields_emailVerified_Create"],
	read?:ValueTypes["UsersFields_emailVerified_Read"],
	update?:ValueTypes["UsersFields_emailVerified_Update"],
	delete?:ValueTypes["UsersFields_emailVerified_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["UsersFields_emailVerified_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersFields_emailVerified_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersFields_emailVerified_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersFields_emailVerified_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersFields_sso"]: AliasType<{
	create?:ValueTypes["UsersFields_sso_Create"],
	read?:ValueTypes["UsersFields_sso_Read"],
	update?:ValueTypes["UsersFields_sso_Update"],
	delete?:ValueTypes["UsersFields_sso_Delete"],
	fields?:ValueTypes["UsersFields_sso_Fields"],
		__typename?: boolean | `@${string}`
}>;
	["UsersFields_sso_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersFields_sso_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersFields_sso_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersFields_sso_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersFields_sso_Fields"]: AliasType<{
	googleId?:ValueTypes["UsersFields_sso_googleId"],
	facebookId?:ValueTypes["UsersFields_sso_facebookId"],
	appleId?:ValueTypes["UsersFields_sso_appleId"],
		__typename?: boolean | `@${string}`
}>;
	["UsersFields_sso_googleId"]: AliasType<{
	create?:ValueTypes["UsersFields_sso_googleId_Create"],
	read?:ValueTypes["UsersFields_sso_googleId_Read"],
	update?:ValueTypes["UsersFields_sso_googleId_Update"],
	delete?:ValueTypes["UsersFields_sso_googleId_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["UsersFields_sso_googleId_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersFields_sso_googleId_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersFields_sso_googleId_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersFields_sso_googleId_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersFields_sso_facebookId"]: AliasType<{
	create?:ValueTypes["UsersFields_sso_facebookId_Create"],
	read?:ValueTypes["UsersFields_sso_facebookId_Read"],
	update?:ValueTypes["UsersFields_sso_facebookId_Update"],
	delete?:ValueTypes["UsersFields_sso_facebookId_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["UsersFields_sso_facebookId_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersFields_sso_facebookId_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersFields_sso_facebookId_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersFields_sso_facebookId_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersFields_sso_appleId"]: AliasType<{
	create?:ValueTypes["UsersFields_sso_appleId_Create"],
	read?:ValueTypes["UsersFields_sso_appleId_Read"],
	update?:ValueTypes["UsersFields_sso_appleId_Update"],
	delete?:ValueTypes["UsersFields_sso_appleId_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["UsersFields_sso_appleId_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersFields_sso_appleId_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersFields_sso_appleId_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersFields_sso_appleId_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersFields_oldPassword"]: AliasType<{
	create?:ValueTypes["UsersFields_oldPassword_Create"],
	read?:ValueTypes["UsersFields_oldPassword_Read"],
	update?:ValueTypes["UsersFields_oldPassword_Update"],
	delete?:ValueTypes["UsersFields_oldPassword_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["UsersFields_oldPassword_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersFields_oldPassword_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersFields_oldPassword_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersFields_oldPassword_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersFields_status"]: AliasType<{
	create?:ValueTypes["UsersFields_status_Create"],
	read?:ValueTypes["UsersFields_status_Read"],
	update?:ValueTypes["UsersFields_status_Update"],
	delete?:ValueTypes["UsersFields_status_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["UsersFields_status_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersFields_status_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersFields_status_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersFields_status_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersFields_updatedAt"]: AliasType<{
	create?:ValueTypes["UsersFields_updatedAt_Create"],
	read?:ValueTypes["UsersFields_updatedAt_Read"],
	update?:ValueTypes["UsersFields_updatedAt_Update"],
	delete?:ValueTypes["UsersFields_updatedAt_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["UsersFields_updatedAt_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersFields_updatedAt_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersFields_updatedAt_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersFields_updatedAt_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersFields_createdAt"]: AliasType<{
	create?:ValueTypes["UsersFields_createdAt_Create"],
	read?:ValueTypes["UsersFields_createdAt_Read"],
	update?:ValueTypes["UsersFields_createdAt_Update"],
	delete?:ValueTypes["UsersFields_createdAt_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["UsersFields_createdAt_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersFields_createdAt_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersFields_createdAt_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersFields_createdAt_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersFields_email"]: AliasType<{
	create?:ValueTypes["UsersFields_email_Create"],
	read?:ValueTypes["UsersFields_email_Read"],
	update?:ValueTypes["UsersFields_email_Update"],
	delete?:ValueTypes["UsersFields_email_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["UsersFields_email_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersFields_email_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersFields_email_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersFields_email_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersFields_password"]: AliasType<{
	create?:ValueTypes["UsersFields_password_Create"],
	read?:ValueTypes["UsersFields_password_Read"],
	update?:ValueTypes["UsersFields_password_Update"],
	delete?:ValueTypes["UsersFields_password_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["UsersFields_password_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersFields_password_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersFields_password_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersFields_password_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersCreateAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersReadAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersUpdateAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersDeleteAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersUnlockAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["bookmarksAccess"]: AliasType<{
	fields?:ValueTypes["BookmarksFields"],
	create?:ValueTypes["BookmarksCreateAccess"],
	read?:ValueTypes["BookmarksReadAccess"],
	update?:ValueTypes["BookmarksUpdateAccess"],
	delete?:ValueTypes["BookmarksDeleteAccess"],
		__typename?: boolean | `@${string}`
}>;
	["BookmarksFields"]: AliasType<{
	user?:ValueTypes["BookmarksFields_user"],
	item?:ValueTypes["BookmarksFields_item"],
	updatedAt?:ValueTypes["BookmarksFields_updatedAt"],
	createdAt?:ValueTypes["BookmarksFields_createdAt"],
		__typename?: boolean | `@${string}`
}>;
	["BookmarksFields_user"]: AliasType<{
	create?:ValueTypes["BookmarksFields_user_Create"],
	read?:ValueTypes["BookmarksFields_user_Read"],
	update?:ValueTypes["BookmarksFields_user_Update"],
	delete?:ValueTypes["BookmarksFields_user_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["BookmarksFields_user_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BookmarksFields_user_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BookmarksFields_user_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BookmarksFields_user_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BookmarksFields_item"]: AliasType<{
	create?:ValueTypes["BookmarksFields_item_Create"],
	read?:ValueTypes["BookmarksFields_item_Read"],
	update?:ValueTypes["BookmarksFields_item_Update"],
	delete?:ValueTypes["BookmarksFields_item_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["BookmarksFields_item_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BookmarksFields_item_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BookmarksFields_item_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BookmarksFields_item_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BookmarksFields_updatedAt"]: AliasType<{
	create?:ValueTypes["BookmarksFields_updatedAt_Create"],
	read?:ValueTypes["BookmarksFields_updatedAt_Read"],
	update?:ValueTypes["BookmarksFields_updatedAt_Update"],
	delete?:ValueTypes["BookmarksFields_updatedAt_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["BookmarksFields_updatedAt_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BookmarksFields_updatedAt_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BookmarksFields_updatedAt_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BookmarksFields_updatedAt_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BookmarksFields_createdAt"]: AliasType<{
	create?:ValueTypes["BookmarksFields_createdAt_Create"],
	read?:ValueTypes["BookmarksFields_createdAt_Read"],
	update?:ValueTypes["BookmarksFields_createdAt_Update"],
	delete?:ValueTypes["BookmarksFields_createdAt_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["BookmarksFields_createdAt_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BookmarksFields_createdAt_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BookmarksFields_createdAt_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BookmarksFields_createdAt_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BookmarksCreateAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BookmarksReadAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BookmarksUpdateAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BookmarksDeleteAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["webAppBookmarksAccess"]: AliasType<{
	fields?:ValueTypes["WebAppBookmarksFields"],
	create?:ValueTypes["WebAppBookmarksCreateAccess"],
	read?:ValueTypes["WebAppBookmarksReadAccess"],
	update?:ValueTypes["WebAppBookmarksUpdateAccess"],
	delete?:ValueTypes["WebAppBookmarksDeleteAccess"],
		__typename?: boolean | `@${string}`
}>;
	["WebAppBookmarksFields"]: AliasType<{
	user?:ValueTypes["WebAppBookmarksFields_user"],
	event?:ValueTypes["WebAppBookmarksFields_event"],
	productType?:ValueTypes["WebAppBookmarksFields_productType"],
	item?:ValueTypes["WebAppBookmarksFields_item"],
	entryNumber?:ValueTypes["WebAppBookmarksFields_entryNumber"],
	updatedAt?:ValueTypes["WebAppBookmarksFields_updatedAt"],
	createdAt?:ValueTypes["WebAppBookmarksFields_createdAt"],
		__typename?: boolean | `@${string}`
}>;
	["WebAppBookmarksFields_user"]: AliasType<{
	create?:ValueTypes["WebAppBookmarksFields_user_Create"],
	read?:ValueTypes["WebAppBookmarksFields_user_Read"],
	update?:ValueTypes["WebAppBookmarksFields_user_Update"],
	delete?:ValueTypes["WebAppBookmarksFields_user_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["WebAppBookmarksFields_user_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["WebAppBookmarksFields_user_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["WebAppBookmarksFields_user_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["WebAppBookmarksFields_user_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["WebAppBookmarksFields_event"]: AliasType<{
	create?:ValueTypes["WebAppBookmarksFields_event_Create"],
	read?:ValueTypes["WebAppBookmarksFields_event_Read"],
	update?:ValueTypes["WebAppBookmarksFields_event_Update"],
	delete?:ValueTypes["WebAppBookmarksFields_event_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["WebAppBookmarksFields_event_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["WebAppBookmarksFields_event_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["WebAppBookmarksFields_event_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["WebAppBookmarksFields_event_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["WebAppBookmarksFields_productType"]: AliasType<{
	create?:ValueTypes["WebAppBookmarksFields_productType_Create"],
	read?:ValueTypes["WebAppBookmarksFields_productType_Read"],
	update?:ValueTypes["WebAppBookmarksFields_productType_Update"],
	delete?:ValueTypes["WebAppBookmarksFields_productType_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["WebAppBookmarksFields_productType_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["WebAppBookmarksFields_productType_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["WebAppBookmarksFields_productType_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["WebAppBookmarksFields_productType_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["WebAppBookmarksFields_item"]: AliasType<{
	create?:ValueTypes["WebAppBookmarksFields_item_Create"],
	read?:ValueTypes["WebAppBookmarksFields_item_Read"],
	update?:ValueTypes["WebAppBookmarksFields_item_Update"],
	delete?:ValueTypes["WebAppBookmarksFields_item_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["WebAppBookmarksFields_item_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["WebAppBookmarksFields_item_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["WebAppBookmarksFields_item_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["WebAppBookmarksFields_item_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["WebAppBookmarksFields_entryNumber"]: AliasType<{
	create?:ValueTypes["WebAppBookmarksFields_entryNumber_Create"],
	read?:ValueTypes["WebAppBookmarksFields_entryNumber_Read"],
	update?:ValueTypes["WebAppBookmarksFields_entryNumber_Update"],
	delete?:ValueTypes["WebAppBookmarksFields_entryNumber_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["WebAppBookmarksFields_entryNumber_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["WebAppBookmarksFields_entryNumber_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["WebAppBookmarksFields_entryNumber_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["WebAppBookmarksFields_entryNumber_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["WebAppBookmarksFields_updatedAt"]: AliasType<{
	create?:ValueTypes["WebAppBookmarksFields_updatedAt_Create"],
	read?:ValueTypes["WebAppBookmarksFields_updatedAt_Read"],
	update?:ValueTypes["WebAppBookmarksFields_updatedAt_Update"],
	delete?:ValueTypes["WebAppBookmarksFields_updatedAt_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["WebAppBookmarksFields_updatedAt_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["WebAppBookmarksFields_updatedAt_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["WebAppBookmarksFields_updatedAt_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["WebAppBookmarksFields_updatedAt_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["WebAppBookmarksFields_createdAt"]: AliasType<{
	create?:ValueTypes["WebAppBookmarksFields_createdAt_Create"],
	read?:ValueTypes["WebAppBookmarksFields_createdAt_Read"],
	update?:ValueTypes["WebAppBookmarksFields_createdAt_Update"],
	delete?:ValueTypes["WebAppBookmarksFields_createdAt_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["WebAppBookmarksFields_createdAt_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["WebAppBookmarksFields_createdAt_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["WebAppBookmarksFields_createdAt_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["WebAppBookmarksFields_createdAt_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["WebAppBookmarksCreateAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["WebAppBookmarksReadAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["WebAppBookmarksUpdateAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["WebAppBookmarksDeleteAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["view_recordsAccess"]: AliasType<{
	fields?:ValueTypes["ViewRecordsFields"],
	create?:ValueTypes["ViewRecordsCreateAccess"],
	read?:ValueTypes["ViewRecordsReadAccess"],
	update?:ValueTypes["ViewRecordsUpdateAccess"],
	delete?:ValueTypes["ViewRecordsDeleteAccess"],
		__typename?: boolean | `@${string}`
}>;
	["ViewRecordsFields"]: AliasType<{
	user?:ValueTypes["ViewRecordsFields_user"],
	item?:ValueTypes["ViewRecordsFields_item"],
	updatedAt?:ValueTypes["ViewRecordsFields_updatedAt"],
	createdAt?:ValueTypes["ViewRecordsFields_createdAt"],
		__typename?: boolean | `@${string}`
}>;
	["ViewRecordsFields_user"]: AliasType<{
	create?:ValueTypes["ViewRecordsFields_user_Create"],
	read?:ValueTypes["ViewRecordsFields_user_Read"],
	update?:ValueTypes["ViewRecordsFields_user_Update"],
	delete?:ValueTypes["ViewRecordsFields_user_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["ViewRecordsFields_user_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ViewRecordsFields_user_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ViewRecordsFields_user_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ViewRecordsFields_user_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ViewRecordsFields_item"]: AliasType<{
	create?:ValueTypes["ViewRecordsFields_item_Create"],
	read?:ValueTypes["ViewRecordsFields_item_Read"],
	update?:ValueTypes["ViewRecordsFields_item_Update"],
	delete?:ValueTypes["ViewRecordsFields_item_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["ViewRecordsFields_item_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ViewRecordsFields_item_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ViewRecordsFields_item_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ViewRecordsFields_item_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ViewRecordsFields_updatedAt"]: AliasType<{
	create?:ValueTypes["ViewRecordsFields_updatedAt_Create"],
	read?:ValueTypes["ViewRecordsFields_updatedAt_Read"],
	update?:ValueTypes["ViewRecordsFields_updatedAt_Update"],
	delete?:ValueTypes["ViewRecordsFields_updatedAt_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["ViewRecordsFields_updatedAt_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ViewRecordsFields_updatedAt_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ViewRecordsFields_updatedAt_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ViewRecordsFields_updatedAt_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ViewRecordsFields_createdAt"]: AliasType<{
	create?:ValueTypes["ViewRecordsFields_createdAt_Create"],
	read?:ValueTypes["ViewRecordsFields_createdAt_Read"],
	update?:ValueTypes["ViewRecordsFields_createdAt_Update"],
	delete?:ValueTypes["ViewRecordsFields_createdAt_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["ViewRecordsFields_createdAt_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ViewRecordsFields_createdAt_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ViewRecordsFields_createdAt_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ViewRecordsFields_createdAt_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ViewRecordsCreateAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ViewRecordsReadAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ViewRecordsUpdateAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ViewRecordsDeleteAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["commentsAccess"]: AliasType<{
	fields?:ValueTypes["CommentsFields"],
	create?:ValueTypes["CommentsCreateAccess"],
	read?:ValueTypes["CommentsReadAccess"],
	update?:ValueTypes["CommentsUpdateAccess"],
	delete?:ValueTypes["CommentsDeleteAccess"],
		__typename?: boolean | `@${string}`
}>;
	["CommentsFields"]: AliasType<{
	commenter?:ValueTypes["CommentsFields_commenter"],
	content?:ValueTypes["CommentsFields_content"],
	image?:ValueTypes["CommentsFields_image"],
	target?:ValueTypes["CommentsFields_target"],
	parent?:ValueTypes["CommentsFields_parent"],
	isReviewed?:ValueTypes["CommentsFields_isReviewed"],
	reviewedBy?:ValueTypes["CommentsFields_reviewedBy"],
	banned?:ValueTypes["CommentsFields_banned"],
	type?:ValueTypes["CommentsFields_type"],
	updatedAt?:ValueTypes["CommentsFields_updatedAt"],
	createdAt?:ValueTypes["CommentsFields_createdAt"],
		__typename?: boolean | `@${string}`
}>;
	["CommentsFields_commenter"]: AliasType<{
	create?:ValueTypes["CommentsFields_commenter_Create"],
	read?:ValueTypes["CommentsFields_commenter_Read"],
	update?:ValueTypes["CommentsFields_commenter_Update"],
	delete?:ValueTypes["CommentsFields_commenter_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["CommentsFields_commenter_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CommentsFields_commenter_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CommentsFields_commenter_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CommentsFields_commenter_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CommentsFields_content"]: AliasType<{
	create?:ValueTypes["CommentsFields_content_Create"],
	read?:ValueTypes["CommentsFields_content_Read"],
	update?:ValueTypes["CommentsFields_content_Update"],
	delete?:ValueTypes["CommentsFields_content_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["CommentsFields_content_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CommentsFields_content_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CommentsFields_content_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CommentsFields_content_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CommentsFields_image"]: AliasType<{
	create?:ValueTypes["CommentsFields_image_Create"],
	read?:ValueTypes["CommentsFields_image_Read"],
	update?:ValueTypes["CommentsFields_image_Update"],
	delete?:ValueTypes["CommentsFields_image_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["CommentsFields_image_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CommentsFields_image_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CommentsFields_image_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CommentsFields_image_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CommentsFields_target"]: AliasType<{
	create?:ValueTypes["CommentsFields_target_Create"],
	read?:ValueTypes["CommentsFields_target_Read"],
	update?:ValueTypes["CommentsFields_target_Update"],
	delete?:ValueTypes["CommentsFields_target_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["CommentsFields_target_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CommentsFields_target_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CommentsFields_target_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CommentsFields_target_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CommentsFields_parent"]: AliasType<{
	create?:ValueTypes["CommentsFields_parent_Create"],
	read?:ValueTypes["CommentsFields_parent_Read"],
	update?:ValueTypes["CommentsFields_parent_Update"],
	delete?:ValueTypes["CommentsFields_parent_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["CommentsFields_parent_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CommentsFields_parent_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CommentsFields_parent_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CommentsFields_parent_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CommentsFields_isReviewed"]: AliasType<{
	create?:ValueTypes["CommentsFields_isReviewed_Create"],
	read?:ValueTypes["CommentsFields_isReviewed_Read"],
	update?:ValueTypes["CommentsFields_isReviewed_Update"],
	delete?:ValueTypes["CommentsFields_isReviewed_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["CommentsFields_isReviewed_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CommentsFields_isReviewed_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CommentsFields_isReviewed_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CommentsFields_isReviewed_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CommentsFields_reviewedBy"]: AliasType<{
	create?:ValueTypes["CommentsFields_reviewedBy_Create"],
	read?:ValueTypes["CommentsFields_reviewedBy_Read"],
	update?:ValueTypes["CommentsFields_reviewedBy_Update"],
	delete?:ValueTypes["CommentsFields_reviewedBy_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["CommentsFields_reviewedBy_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CommentsFields_reviewedBy_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CommentsFields_reviewedBy_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CommentsFields_reviewedBy_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CommentsFields_banned"]: AliasType<{
	create?:ValueTypes["CommentsFields_banned_Create"],
	read?:ValueTypes["CommentsFields_banned_Read"],
	update?:ValueTypes["CommentsFields_banned_Update"],
	delete?:ValueTypes["CommentsFields_banned_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["CommentsFields_banned_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CommentsFields_banned_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CommentsFields_banned_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CommentsFields_banned_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CommentsFields_type"]: AliasType<{
	create?:ValueTypes["CommentsFields_type_Create"],
	read?:ValueTypes["CommentsFields_type_Read"],
	update?:ValueTypes["CommentsFields_type_Update"],
	delete?:ValueTypes["CommentsFields_type_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["CommentsFields_type_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CommentsFields_type_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CommentsFields_type_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CommentsFields_type_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CommentsFields_updatedAt"]: AliasType<{
	create?:ValueTypes["CommentsFields_updatedAt_Create"],
	read?:ValueTypes["CommentsFields_updatedAt_Read"],
	update?:ValueTypes["CommentsFields_updatedAt_Update"],
	delete?:ValueTypes["CommentsFields_updatedAt_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["CommentsFields_updatedAt_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CommentsFields_updatedAt_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CommentsFields_updatedAt_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CommentsFields_updatedAt_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CommentsFields_createdAt"]: AliasType<{
	create?:ValueTypes["CommentsFields_createdAt_Create"],
	read?:ValueTypes["CommentsFields_createdAt_Read"],
	update?:ValueTypes["CommentsFields_createdAt_Update"],
	delete?:ValueTypes["CommentsFields_createdAt_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["CommentsFields_createdAt_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CommentsFields_createdAt_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CommentsFields_createdAt_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CommentsFields_createdAt_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CommentsCreateAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CommentsReadAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CommentsUpdateAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CommentsDeleteAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["subscriptionsAccess"]: AliasType<{
	fields?:ValueTypes["SubscriptionsFields"],
	create?:ValueTypes["SubscriptionsCreateAccess"],
	read?:ValueTypes["SubscriptionsReadAccess"],
	update?:ValueTypes["SubscriptionsUpdateAccess"],
	delete?:ValueTypes["SubscriptionsDeleteAccess"],
		__typename?: boolean | `@${string}`
}>;
	["SubscriptionsFields"]: AliasType<{
	user?:ValueTypes["SubscriptionsFields_user"],
	organiser?:ValueTypes["SubscriptionsFields_organiser"],
	updatedAt?:ValueTypes["SubscriptionsFields_updatedAt"],
	createdAt?:ValueTypes["SubscriptionsFields_createdAt"],
		__typename?: boolean | `@${string}`
}>;
	["SubscriptionsFields_user"]: AliasType<{
	create?:ValueTypes["SubscriptionsFields_user_Create"],
	read?:ValueTypes["SubscriptionsFields_user_Read"],
	update?:ValueTypes["SubscriptionsFields_user_Update"],
	delete?:ValueTypes["SubscriptionsFields_user_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["SubscriptionsFields_user_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["SubscriptionsFields_user_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["SubscriptionsFields_user_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["SubscriptionsFields_user_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["SubscriptionsFields_organiser"]: AliasType<{
	create?:ValueTypes["SubscriptionsFields_organiser_Create"],
	read?:ValueTypes["SubscriptionsFields_organiser_Read"],
	update?:ValueTypes["SubscriptionsFields_organiser_Update"],
	delete?:ValueTypes["SubscriptionsFields_organiser_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["SubscriptionsFields_organiser_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["SubscriptionsFields_organiser_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["SubscriptionsFields_organiser_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["SubscriptionsFields_organiser_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["SubscriptionsFields_updatedAt"]: AliasType<{
	create?:ValueTypes["SubscriptionsFields_updatedAt_Create"],
	read?:ValueTypes["SubscriptionsFields_updatedAt_Read"],
	update?:ValueTypes["SubscriptionsFields_updatedAt_Update"],
	delete?:ValueTypes["SubscriptionsFields_updatedAt_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["SubscriptionsFields_updatedAt_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["SubscriptionsFields_updatedAt_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["SubscriptionsFields_updatedAt_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["SubscriptionsFields_updatedAt_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["SubscriptionsFields_createdAt"]: AliasType<{
	create?:ValueTypes["SubscriptionsFields_createdAt_Create"],
	read?:ValueTypes["SubscriptionsFields_createdAt_Read"],
	update?:ValueTypes["SubscriptionsFields_createdAt_Update"],
	delete?:ValueTypes["SubscriptionsFields_createdAt_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["SubscriptionsFields_createdAt_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["SubscriptionsFields_createdAt_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["SubscriptionsFields_createdAt_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["SubscriptionsFields_createdAt_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["SubscriptionsCreateAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["SubscriptionsReadAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["SubscriptionsUpdateAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["SubscriptionsDeleteAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["newsletterAccess"]: AliasType<{
	fields?:ValueTypes["NewsletterFields"],
	create?:ValueTypes["NewsletterCreateAccess"],
	read?:ValueTypes["NewsletterReadAccess"],
	update?:ValueTypes["NewsletterUpdateAccess"],
	delete?:ValueTypes["NewsletterDeleteAccess"],
		__typename?: boolean | `@${string}`
}>;
	["NewsletterFields"]: AliasType<{
	email?:ValueTypes["NewsletterFields_email"],
	region?:ValueTypes["NewsletterFields_region"],
	updatedAt?:ValueTypes["NewsletterFields_updatedAt"],
	createdAt?:ValueTypes["NewsletterFields_createdAt"],
		__typename?: boolean | `@${string}`
}>;
	["NewsletterFields_email"]: AliasType<{
	create?:ValueTypes["NewsletterFields_email_Create"],
	read?:ValueTypes["NewsletterFields_email_Read"],
	update?:ValueTypes["NewsletterFields_email_Update"],
	delete?:ValueTypes["NewsletterFields_email_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["NewsletterFields_email_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["NewsletterFields_email_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["NewsletterFields_email_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["NewsletterFields_email_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["NewsletterFields_region"]: AliasType<{
	create?:ValueTypes["NewsletterFields_region_Create"],
	read?:ValueTypes["NewsletterFields_region_Read"],
	update?:ValueTypes["NewsletterFields_region_Update"],
	delete?:ValueTypes["NewsletterFields_region_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["NewsletterFields_region_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["NewsletterFields_region_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["NewsletterFields_region_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["NewsletterFields_region_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["NewsletterFields_updatedAt"]: AliasType<{
	create?:ValueTypes["NewsletterFields_updatedAt_Create"],
	read?:ValueTypes["NewsletterFields_updatedAt_Read"],
	update?:ValueTypes["NewsletterFields_updatedAt_Update"],
	delete?:ValueTypes["NewsletterFields_updatedAt_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["NewsletterFields_updatedAt_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["NewsletterFields_updatedAt_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["NewsletterFields_updatedAt_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["NewsletterFields_updatedAt_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["NewsletterFields_createdAt"]: AliasType<{
	create?:ValueTypes["NewsletterFields_createdAt_Create"],
	read?:ValueTypes["NewsletterFields_createdAt_Read"],
	update?:ValueTypes["NewsletterFields_createdAt_Update"],
	delete?:ValueTypes["NewsletterFields_createdAt_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["NewsletterFields_createdAt_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["NewsletterFields_createdAt_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["NewsletterFields_createdAt_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["NewsletterFields_createdAt_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["NewsletterCreateAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["NewsletterReadAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["NewsletterUpdateAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["NewsletterDeleteAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["blog_categoriesAccess"]: AliasType<{
	fields?:ValueTypes["BlogCategoriesFields"],
	create?:ValueTypes["BlogCategoriesCreateAccess"],
	read?:ValueTypes["BlogCategoriesReadAccess"],
	update?:ValueTypes["BlogCategoriesUpdateAccess"],
	delete?:ValueTypes["BlogCategoriesDeleteAccess"],
		__typename?: boolean | `@${string}`
}>;
	["BlogCategoriesFields"]: AliasType<{
	name?:ValueTypes["BlogCategoriesFields_name"],
	value?:ValueTypes["BlogCategoriesFields_value"],
	icon?:ValueTypes["BlogCategoriesFields_icon"],
	region?:ValueTypes["BlogCategoriesFields_region"],
	updatedAt?:ValueTypes["BlogCategoriesFields_updatedAt"],
	createdAt?:ValueTypes["BlogCategoriesFields_createdAt"],
		__typename?: boolean | `@${string}`
}>;
	["BlogCategoriesFields_name"]: AliasType<{
	create?:ValueTypes["BlogCategoriesFields_name_Create"],
	read?:ValueTypes["BlogCategoriesFields_name_Read"],
	update?:ValueTypes["BlogCategoriesFields_name_Update"],
	delete?:ValueTypes["BlogCategoriesFields_name_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["BlogCategoriesFields_name_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BlogCategoriesFields_name_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BlogCategoriesFields_name_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BlogCategoriesFields_name_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BlogCategoriesFields_value"]: AliasType<{
	create?:ValueTypes["BlogCategoriesFields_value_Create"],
	read?:ValueTypes["BlogCategoriesFields_value_Read"],
	update?:ValueTypes["BlogCategoriesFields_value_Update"],
	delete?:ValueTypes["BlogCategoriesFields_value_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["BlogCategoriesFields_value_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BlogCategoriesFields_value_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BlogCategoriesFields_value_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BlogCategoriesFields_value_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BlogCategoriesFields_icon"]: AliasType<{
	create?:ValueTypes["BlogCategoriesFields_icon_Create"],
	read?:ValueTypes["BlogCategoriesFields_icon_Read"],
	update?:ValueTypes["BlogCategoriesFields_icon_Update"],
	delete?:ValueTypes["BlogCategoriesFields_icon_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["BlogCategoriesFields_icon_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BlogCategoriesFields_icon_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BlogCategoriesFields_icon_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BlogCategoriesFields_icon_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BlogCategoriesFields_region"]: AliasType<{
	create?:ValueTypes["BlogCategoriesFields_region_Create"],
	read?:ValueTypes["BlogCategoriesFields_region_Read"],
	update?:ValueTypes["BlogCategoriesFields_region_Update"],
	delete?:ValueTypes["BlogCategoriesFields_region_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["BlogCategoriesFields_region_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BlogCategoriesFields_region_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BlogCategoriesFields_region_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BlogCategoriesFields_region_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BlogCategoriesFields_updatedAt"]: AliasType<{
	create?:ValueTypes["BlogCategoriesFields_updatedAt_Create"],
	read?:ValueTypes["BlogCategoriesFields_updatedAt_Read"],
	update?:ValueTypes["BlogCategoriesFields_updatedAt_Update"],
	delete?:ValueTypes["BlogCategoriesFields_updatedAt_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["BlogCategoriesFields_updatedAt_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BlogCategoriesFields_updatedAt_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BlogCategoriesFields_updatedAt_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BlogCategoriesFields_updatedAt_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BlogCategoriesFields_createdAt"]: AliasType<{
	create?:ValueTypes["BlogCategoriesFields_createdAt_Create"],
	read?:ValueTypes["BlogCategoriesFields_createdAt_Read"],
	update?:ValueTypes["BlogCategoriesFields_createdAt_Update"],
	delete?:ValueTypes["BlogCategoriesFields_createdAt_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["BlogCategoriesFields_createdAt_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BlogCategoriesFields_createdAt_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BlogCategoriesFields_createdAt_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BlogCategoriesFields_createdAt_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BlogCategoriesCreateAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BlogCategoriesReadAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BlogCategoriesUpdateAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BlogCategoriesDeleteAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["tagsAccess"]: AliasType<{
	fields?:ValueTypes["TagsFields"],
	create?:ValueTypes["TagsCreateAccess"],
	read?:ValueTypes["TagsReadAccess"],
	update?:ValueTypes["TagsUpdateAccess"],
	delete?:ValueTypes["TagsDeleteAccess"],
		__typename?: boolean | `@${string}`
}>;
	["TagsFields"]: AliasType<{
	name?:ValueTypes["TagsFields_name"],
	value?:ValueTypes["TagsFields_value"],
	region?:ValueTypes["TagsFields_region"],
	count?:ValueTypes["TagsFields_count"],
	skipCountCheck?:ValueTypes["TagsFields_skipCountCheck"],
	removeNextCron?:ValueTypes["TagsFields_removeNextCron"],
		__typename?: boolean | `@${string}`
}>;
	["TagsFields_name"]: AliasType<{
	create?:ValueTypes["TagsFields_name_Create"],
	read?:ValueTypes["TagsFields_name_Read"],
	update?:ValueTypes["TagsFields_name_Update"],
	delete?:ValueTypes["TagsFields_name_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["TagsFields_name_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TagsFields_name_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TagsFields_name_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TagsFields_name_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TagsFields_value"]: AliasType<{
	create?:ValueTypes["TagsFields_value_Create"],
	read?:ValueTypes["TagsFields_value_Read"],
	update?:ValueTypes["TagsFields_value_Update"],
	delete?:ValueTypes["TagsFields_value_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["TagsFields_value_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TagsFields_value_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TagsFields_value_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TagsFields_value_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TagsFields_region"]: AliasType<{
	create?:ValueTypes["TagsFields_region_Create"],
	read?:ValueTypes["TagsFields_region_Read"],
	update?:ValueTypes["TagsFields_region_Update"],
	delete?:ValueTypes["TagsFields_region_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["TagsFields_region_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TagsFields_region_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TagsFields_region_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TagsFields_region_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TagsFields_count"]: AliasType<{
	create?:ValueTypes["TagsFields_count_Create"],
	read?:ValueTypes["TagsFields_count_Read"],
	update?:ValueTypes["TagsFields_count_Update"],
	delete?:ValueTypes["TagsFields_count_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["TagsFields_count_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TagsFields_count_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TagsFields_count_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TagsFields_count_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TagsFields_skipCountCheck"]: AliasType<{
	create?:ValueTypes["TagsFields_skipCountCheck_Create"],
	read?:ValueTypes["TagsFields_skipCountCheck_Read"],
	update?:ValueTypes["TagsFields_skipCountCheck_Update"],
	delete?:ValueTypes["TagsFields_skipCountCheck_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["TagsFields_skipCountCheck_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TagsFields_skipCountCheck_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TagsFields_skipCountCheck_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TagsFields_skipCountCheck_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TagsFields_removeNextCron"]: AliasType<{
	create?:ValueTypes["TagsFields_removeNextCron_Create"],
	read?:ValueTypes["TagsFields_removeNextCron_Read"],
	update?:ValueTypes["TagsFields_removeNextCron_Update"],
	delete?:ValueTypes["TagsFields_removeNextCron_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["TagsFields_removeNextCron_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TagsFields_removeNextCron_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TagsFields_removeNextCron_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TagsFields_removeNextCron_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TagsCreateAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TagsReadAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TagsUpdateAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TagsDeleteAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["specialTagsAccess"]: AliasType<{
	fields?:ValueTypes["SpecialTagsFields"],
	create?:ValueTypes["SpecialTagsCreateAccess"],
	read?:ValueTypes["SpecialTagsReadAccess"],
	update?:ValueTypes["SpecialTagsUpdateAccess"],
	delete?:ValueTypes["SpecialTagsDeleteAccess"],
		__typename?: boolean | `@${string}`
}>;
	["SpecialTagsFields"]: AliasType<{
	name?:ValueTypes["SpecialTagsFields_name"],
	value?:ValueTypes["SpecialTagsFields_value"],
	region?:ValueTypes["SpecialTagsFields_region"],
		__typename?: boolean | `@${string}`
}>;
	["SpecialTagsFields_name"]: AliasType<{
	create?:ValueTypes["SpecialTagsFields_name_Create"],
	read?:ValueTypes["SpecialTagsFields_name_Read"],
	update?:ValueTypes["SpecialTagsFields_name_Update"],
	delete?:ValueTypes["SpecialTagsFields_name_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["SpecialTagsFields_name_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["SpecialTagsFields_name_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["SpecialTagsFields_name_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["SpecialTagsFields_name_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["SpecialTagsFields_value"]: AliasType<{
	create?:ValueTypes["SpecialTagsFields_value_Create"],
	read?:ValueTypes["SpecialTagsFields_value_Read"],
	update?:ValueTypes["SpecialTagsFields_value_Update"],
	delete?:ValueTypes["SpecialTagsFields_value_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["SpecialTagsFields_value_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["SpecialTagsFields_value_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["SpecialTagsFields_value_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["SpecialTagsFields_value_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["SpecialTagsFields_region"]: AliasType<{
	create?:ValueTypes["SpecialTagsFields_region_Create"],
	read?:ValueTypes["SpecialTagsFields_region_Read"],
	update?:ValueTypes["SpecialTagsFields_region_Update"],
	delete?:ValueTypes["SpecialTagsFields_region_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["SpecialTagsFields_region_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["SpecialTagsFields_region_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["SpecialTagsFields_region_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["SpecialTagsFields_region_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["SpecialTagsCreateAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["SpecialTagsReadAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["SpecialTagsUpdateAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["SpecialTagsDeleteAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["dateAccess"]: AliasType<{
	fields?:ValueTypes["DateFields"],
	create?:ValueTypes["DateCreateAccess"],
	read?:ValueTypes["DateReadAccess"],
	update?:ValueTypes["DateUpdateAccess"],
	delete?:ValueTypes["DateDeleteAccess"],
		__typename?: boolean | `@${string}`
}>;
	["DateFields"]: AliasType<{
	name?:ValueTypes["DateFields_name"],
	value?:ValueTypes["DateFields_value"],
	type?:ValueTypes["DateFields_type"],
	start?:ValueTypes["DateFields_start"],
	end?:ValueTypes["DateFields_end"],
	_title?:ValueTypes["DateFields__title"],
	region?:ValueTypes["DateFields_region"],
	updatedAt?:ValueTypes["DateFields_updatedAt"],
	createdAt?:ValueTypes["DateFields_createdAt"],
		__typename?: boolean | `@${string}`
}>;
	["DateFields_name"]: AliasType<{
	create?:ValueTypes["DateFields_name_Create"],
	read?:ValueTypes["DateFields_name_Read"],
	update?:ValueTypes["DateFields_name_Update"],
	delete?:ValueTypes["DateFields_name_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["DateFields_name_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DateFields_name_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DateFields_name_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DateFields_name_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DateFields_value"]: AliasType<{
	create?:ValueTypes["DateFields_value_Create"],
	read?:ValueTypes["DateFields_value_Read"],
	update?:ValueTypes["DateFields_value_Update"],
	delete?:ValueTypes["DateFields_value_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["DateFields_value_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DateFields_value_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DateFields_value_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DateFields_value_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DateFields_type"]: AliasType<{
	create?:ValueTypes["DateFields_type_Create"],
	read?:ValueTypes["DateFields_type_Read"],
	update?:ValueTypes["DateFields_type_Update"],
	delete?:ValueTypes["DateFields_type_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["DateFields_type_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DateFields_type_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DateFields_type_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DateFields_type_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DateFields_start"]: AliasType<{
	create?:ValueTypes["DateFields_start_Create"],
	read?:ValueTypes["DateFields_start_Read"],
	update?:ValueTypes["DateFields_start_Update"],
	delete?:ValueTypes["DateFields_start_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["DateFields_start_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DateFields_start_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DateFields_start_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DateFields_start_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DateFields_end"]: AliasType<{
	create?:ValueTypes["DateFields_end_Create"],
	read?:ValueTypes["DateFields_end_Read"],
	update?:ValueTypes["DateFields_end_Update"],
	delete?:ValueTypes["DateFields_end_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["DateFields_end_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DateFields_end_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DateFields_end_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DateFields_end_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DateFields__title"]: AliasType<{
	create?:ValueTypes["DateFields__title_Create"],
	read?:ValueTypes["DateFields__title_Read"],
	update?:ValueTypes["DateFields__title_Update"],
	delete?:ValueTypes["DateFields__title_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["DateFields__title_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DateFields__title_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DateFields__title_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DateFields__title_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DateFields_region"]: AliasType<{
	create?:ValueTypes["DateFields_region_Create"],
	read?:ValueTypes["DateFields_region_Read"],
	update?:ValueTypes["DateFields_region_Update"],
	delete?:ValueTypes["DateFields_region_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["DateFields_region_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DateFields_region_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DateFields_region_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DateFields_region_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DateFields_updatedAt"]: AliasType<{
	create?:ValueTypes["DateFields_updatedAt_Create"],
	read?:ValueTypes["DateFields_updatedAt_Read"],
	update?:ValueTypes["DateFields_updatedAt_Update"],
	delete?:ValueTypes["DateFields_updatedAt_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["DateFields_updatedAt_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DateFields_updatedAt_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DateFields_updatedAt_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DateFields_updatedAt_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DateFields_createdAt"]: AliasType<{
	create?:ValueTypes["DateFields_createdAt_Create"],
	read?:ValueTypes["DateFields_createdAt_Read"],
	update?:ValueTypes["DateFields_createdAt_Update"],
	delete?:ValueTypes["DateFields_createdAt_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["DateFields_createdAt_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DateFields_createdAt_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DateFields_createdAt_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DateFields_createdAt_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DateCreateAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DateReadAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DateUpdateAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DateDeleteAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["districtsAccess"]: AliasType<{
	fields?:ValueTypes["DistrictsFields"],
	create?:ValueTypes["DistrictsCreateAccess"],
	read?:ValueTypes["DistrictsReadAccess"],
	update?:ValueTypes["DistrictsUpdateAccess"],
	delete?:ValueTypes["DistrictsDeleteAccess"],
		__typename?: boolean | `@${string}`
}>;
	["DistrictsFields"]: AliasType<{
	name?:ValueTypes["DistrictsFields_name"],
	level?:ValueTypes["DistrictsFields_level"],
	children?:ValueTypes["DistrictsFields_children"],
	value?:ValueTypes["DistrictsFields_value"],
	lat?:ValueTypes["DistrictsFields_lat"],
	long?:ValueTypes["DistrictsFields_long"],
	radius?:ValueTypes["DistrictsFields_radius"],
	region?:ValueTypes["DistrictsFields_region"],
	updatedAt?:ValueTypes["DistrictsFields_updatedAt"],
	createdAt?:ValueTypes["DistrictsFields_createdAt"],
		__typename?: boolean | `@${string}`
}>;
	["DistrictsFields_name"]: AliasType<{
	create?:ValueTypes["DistrictsFields_name_Create"],
	read?:ValueTypes["DistrictsFields_name_Read"],
	update?:ValueTypes["DistrictsFields_name_Update"],
	delete?:ValueTypes["DistrictsFields_name_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["DistrictsFields_name_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DistrictsFields_name_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DistrictsFields_name_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DistrictsFields_name_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DistrictsFields_level"]: AliasType<{
	create?:ValueTypes["DistrictsFields_level_Create"],
	read?:ValueTypes["DistrictsFields_level_Read"],
	update?:ValueTypes["DistrictsFields_level_Update"],
	delete?:ValueTypes["DistrictsFields_level_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["DistrictsFields_level_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DistrictsFields_level_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DistrictsFields_level_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DistrictsFields_level_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DistrictsFields_children"]: AliasType<{
	create?:ValueTypes["DistrictsFields_children_Create"],
	read?:ValueTypes["DistrictsFields_children_Read"],
	update?:ValueTypes["DistrictsFields_children_Update"],
	delete?:ValueTypes["DistrictsFields_children_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["DistrictsFields_children_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DistrictsFields_children_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DistrictsFields_children_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DistrictsFields_children_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DistrictsFields_value"]: AliasType<{
	create?:ValueTypes["DistrictsFields_value_Create"],
	read?:ValueTypes["DistrictsFields_value_Read"],
	update?:ValueTypes["DistrictsFields_value_Update"],
	delete?:ValueTypes["DistrictsFields_value_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["DistrictsFields_value_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DistrictsFields_value_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DistrictsFields_value_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DistrictsFields_value_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DistrictsFields_lat"]: AliasType<{
	create?:ValueTypes["DistrictsFields_lat_Create"],
	read?:ValueTypes["DistrictsFields_lat_Read"],
	update?:ValueTypes["DistrictsFields_lat_Update"],
	delete?:ValueTypes["DistrictsFields_lat_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["DistrictsFields_lat_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DistrictsFields_lat_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DistrictsFields_lat_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DistrictsFields_lat_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DistrictsFields_long"]: AliasType<{
	create?:ValueTypes["DistrictsFields_long_Create"],
	read?:ValueTypes["DistrictsFields_long_Read"],
	update?:ValueTypes["DistrictsFields_long_Update"],
	delete?:ValueTypes["DistrictsFields_long_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["DistrictsFields_long_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DistrictsFields_long_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DistrictsFields_long_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DistrictsFields_long_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DistrictsFields_radius"]: AliasType<{
	create?:ValueTypes["DistrictsFields_radius_Create"],
	read?:ValueTypes["DistrictsFields_radius_Read"],
	update?:ValueTypes["DistrictsFields_radius_Update"],
	delete?:ValueTypes["DistrictsFields_radius_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["DistrictsFields_radius_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DistrictsFields_radius_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DistrictsFields_radius_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DistrictsFields_radius_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DistrictsFields_region"]: AliasType<{
	create?:ValueTypes["DistrictsFields_region_Create"],
	read?:ValueTypes["DistrictsFields_region_Read"],
	update?:ValueTypes["DistrictsFields_region_Update"],
	delete?:ValueTypes["DistrictsFields_region_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["DistrictsFields_region_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DistrictsFields_region_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DistrictsFields_region_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DistrictsFields_region_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DistrictsFields_updatedAt"]: AliasType<{
	create?:ValueTypes["DistrictsFields_updatedAt_Create"],
	read?:ValueTypes["DistrictsFields_updatedAt_Read"],
	update?:ValueTypes["DistrictsFields_updatedAt_Update"],
	delete?:ValueTypes["DistrictsFields_updatedAt_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["DistrictsFields_updatedAt_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DistrictsFields_updatedAt_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DistrictsFields_updatedAt_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DistrictsFields_updatedAt_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DistrictsFields_createdAt"]: AliasType<{
	create?:ValueTypes["DistrictsFields_createdAt_Create"],
	read?:ValueTypes["DistrictsFields_createdAt_Read"],
	update?:ValueTypes["DistrictsFields_createdAt_Update"],
	delete?:ValueTypes["DistrictsFields_createdAt_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["DistrictsFields_createdAt_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DistrictsFields_createdAt_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DistrictsFields_createdAt_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DistrictsFields_createdAt_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DistrictsCreateAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DistrictsReadAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DistrictsUpdateAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DistrictsDeleteAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["audienceAccess"]: AliasType<{
	fields?:ValueTypes["AudienceFields"],
	create?:ValueTypes["AudienceCreateAccess"],
	read?:ValueTypes["AudienceReadAccess"],
	update?:ValueTypes["AudienceUpdateAccess"],
	delete?:ValueTypes["AudienceDeleteAccess"],
		__typename?: boolean | `@${string}`
}>;
	["AudienceFields"]: AliasType<{
	name?:ValueTypes["AudienceFields_name"],
	value?:ValueTypes["AudienceFields_value"],
	icon?:ValueTypes["AudienceFields_icon"],
	region?:ValueTypes["AudienceFields_region"],
	updatedAt?:ValueTypes["AudienceFields_updatedAt"],
	createdAt?:ValueTypes["AudienceFields_createdAt"],
		__typename?: boolean | `@${string}`
}>;
	["AudienceFields_name"]: AliasType<{
	create?:ValueTypes["AudienceFields_name_Create"],
	read?:ValueTypes["AudienceFields_name_Read"],
	update?:ValueTypes["AudienceFields_name_Update"],
	delete?:ValueTypes["AudienceFields_name_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["AudienceFields_name_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AudienceFields_name_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AudienceFields_name_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AudienceFields_name_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AudienceFields_value"]: AliasType<{
	create?:ValueTypes["AudienceFields_value_Create"],
	read?:ValueTypes["AudienceFields_value_Read"],
	update?:ValueTypes["AudienceFields_value_Update"],
	delete?:ValueTypes["AudienceFields_value_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["AudienceFields_value_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AudienceFields_value_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AudienceFields_value_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AudienceFields_value_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AudienceFields_icon"]: AliasType<{
	create?:ValueTypes["AudienceFields_icon_Create"],
	read?:ValueTypes["AudienceFields_icon_Read"],
	update?:ValueTypes["AudienceFields_icon_Update"],
	delete?:ValueTypes["AudienceFields_icon_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["AudienceFields_icon_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AudienceFields_icon_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AudienceFields_icon_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AudienceFields_icon_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AudienceFields_region"]: AliasType<{
	create?:ValueTypes["AudienceFields_region_Create"],
	read?:ValueTypes["AudienceFields_region_Read"],
	update?:ValueTypes["AudienceFields_region_Update"],
	delete?:ValueTypes["AudienceFields_region_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["AudienceFields_region_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AudienceFields_region_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AudienceFields_region_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AudienceFields_region_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AudienceFields_updatedAt"]: AliasType<{
	create?:ValueTypes["AudienceFields_updatedAt_Create"],
	read?:ValueTypes["AudienceFields_updatedAt_Read"],
	update?:ValueTypes["AudienceFields_updatedAt_Update"],
	delete?:ValueTypes["AudienceFields_updatedAt_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["AudienceFields_updatedAt_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AudienceFields_updatedAt_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AudienceFields_updatedAt_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AudienceFields_updatedAt_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AudienceFields_createdAt"]: AliasType<{
	create?:ValueTypes["AudienceFields_createdAt_Create"],
	read?:ValueTypes["AudienceFields_createdAt_Read"],
	update?:ValueTypes["AudienceFields_createdAt_Update"],
	delete?:ValueTypes["AudienceFields_createdAt_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["AudienceFields_createdAt_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AudienceFields_createdAt_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AudienceFields_createdAt_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AudienceFields_createdAt_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AudienceCreateAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AudienceReadAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AudienceUpdateAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AudienceDeleteAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["categoryAccess"]: AliasType<{
	fields?:ValueTypes["CategoryFields"],
	create?:ValueTypes["CategoryCreateAccess"],
	read?:ValueTypes["CategoryReadAccess"],
	update?:ValueTypes["CategoryUpdateAccess"],
	delete?:ValueTypes["CategoryDeleteAccess"],
		__typename?: boolean | `@${string}`
}>;
	["CategoryFields"]: AliasType<{
	name?:ValueTypes["CategoryFields_name"],
	value?:ValueTypes["CategoryFields_value"],
	tags?:ValueTypes["CategoryFields_tags"],
	icon?:ValueTypes["CategoryFields_icon"],
	region?:ValueTypes["CategoryFields_region"],
	updatedAt?:ValueTypes["CategoryFields_updatedAt"],
	createdAt?:ValueTypes["CategoryFields_createdAt"],
		__typename?: boolean | `@${string}`
}>;
	["CategoryFields_name"]: AliasType<{
	create?:ValueTypes["CategoryFields_name_Create"],
	read?:ValueTypes["CategoryFields_name_Read"],
	update?:ValueTypes["CategoryFields_name_Update"],
	delete?:ValueTypes["CategoryFields_name_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["CategoryFields_name_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CategoryFields_name_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CategoryFields_name_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CategoryFields_name_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CategoryFields_value"]: AliasType<{
	create?:ValueTypes["CategoryFields_value_Create"],
	read?:ValueTypes["CategoryFields_value_Read"],
	update?:ValueTypes["CategoryFields_value_Update"],
	delete?:ValueTypes["CategoryFields_value_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["CategoryFields_value_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CategoryFields_value_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CategoryFields_value_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CategoryFields_value_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CategoryFields_tags"]: AliasType<{
	create?:ValueTypes["CategoryFields_tags_Create"],
	read?:ValueTypes["CategoryFields_tags_Read"],
	update?:ValueTypes["CategoryFields_tags_Update"],
	delete?:ValueTypes["CategoryFields_tags_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["CategoryFields_tags_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CategoryFields_tags_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CategoryFields_tags_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CategoryFields_tags_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CategoryFields_icon"]: AliasType<{
	create?:ValueTypes["CategoryFields_icon_Create"],
	read?:ValueTypes["CategoryFields_icon_Read"],
	update?:ValueTypes["CategoryFields_icon_Update"],
	delete?:ValueTypes["CategoryFields_icon_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["CategoryFields_icon_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CategoryFields_icon_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CategoryFields_icon_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CategoryFields_icon_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CategoryFields_region"]: AliasType<{
	create?:ValueTypes["CategoryFields_region_Create"],
	read?:ValueTypes["CategoryFields_region_Read"],
	update?:ValueTypes["CategoryFields_region_Update"],
	delete?:ValueTypes["CategoryFields_region_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["CategoryFields_region_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CategoryFields_region_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CategoryFields_region_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CategoryFields_region_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CategoryFields_updatedAt"]: AliasType<{
	create?:ValueTypes["CategoryFields_updatedAt_Create"],
	read?:ValueTypes["CategoryFields_updatedAt_Read"],
	update?:ValueTypes["CategoryFields_updatedAt_Update"],
	delete?:ValueTypes["CategoryFields_updatedAt_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["CategoryFields_updatedAt_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CategoryFields_updatedAt_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CategoryFields_updatedAt_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CategoryFields_updatedAt_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CategoryFields_createdAt"]: AliasType<{
	create?:ValueTypes["CategoryFields_createdAt_Create"],
	read?:ValueTypes["CategoryFields_createdAt_Read"],
	update?:ValueTypes["CategoryFields_createdAt_Update"],
	delete?:ValueTypes["CategoryFields_createdAt_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["CategoryFields_createdAt_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CategoryFields_createdAt_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CategoryFields_createdAt_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CategoryFields_createdAt_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CategoryCreateAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CategoryReadAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CategoryUpdateAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CategoryDeleteAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["private_mediaAccess"]: AliasType<{
	fields?:ValueTypes["PrivateMediaFields"],
	create?:ValueTypes["PrivateMediaCreateAccess"],
	read?:ValueTypes["PrivateMediaReadAccess"],
	update?:ValueTypes["PrivateMediaUpdateAccess"],
	delete?:ValueTypes["PrivateMediaDeleteAccess"],
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields"]: AliasType<{
	alt?:ValueTypes["PrivateMediaFields_alt"],
	updatedAt?:ValueTypes["PrivateMediaFields_updatedAt"],
	createdAt?:ValueTypes["PrivateMediaFields_createdAt"],
	url?:ValueTypes["PrivateMediaFields_url"],
	filename?:ValueTypes["PrivateMediaFields_filename"],
	mimeType?:ValueTypes["PrivateMediaFields_mimeType"],
	filesize?:ValueTypes["PrivateMediaFields_filesize"],
	width?:ValueTypes["PrivateMediaFields_width"],
	height?:ValueTypes["PrivateMediaFields_height"],
	focalX?:ValueTypes["PrivateMediaFields_focalX"],
	focalY?:ValueTypes["PrivateMediaFields_focalY"],
	sizes?:ValueTypes["PrivateMediaFields_sizes"],
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_alt"]: AliasType<{
	create?:ValueTypes["PrivateMediaFields_alt_Create"],
	read?:ValueTypes["PrivateMediaFields_alt_Read"],
	update?:ValueTypes["PrivateMediaFields_alt_Update"],
	delete?:ValueTypes["PrivateMediaFields_alt_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_alt_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_alt_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_alt_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_alt_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_updatedAt"]: AliasType<{
	create?:ValueTypes["PrivateMediaFields_updatedAt_Create"],
	read?:ValueTypes["PrivateMediaFields_updatedAt_Read"],
	update?:ValueTypes["PrivateMediaFields_updatedAt_Update"],
	delete?:ValueTypes["PrivateMediaFields_updatedAt_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_updatedAt_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_updatedAt_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_updatedAt_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_updatedAt_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_createdAt"]: AliasType<{
	create?:ValueTypes["PrivateMediaFields_createdAt_Create"],
	read?:ValueTypes["PrivateMediaFields_createdAt_Read"],
	update?:ValueTypes["PrivateMediaFields_createdAt_Update"],
	delete?:ValueTypes["PrivateMediaFields_createdAt_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_createdAt_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_createdAt_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_createdAt_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_createdAt_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_url"]: AliasType<{
	create?:ValueTypes["PrivateMediaFields_url_Create"],
	read?:ValueTypes["PrivateMediaFields_url_Read"],
	update?:ValueTypes["PrivateMediaFields_url_Update"],
	delete?:ValueTypes["PrivateMediaFields_url_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_url_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_url_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_url_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_url_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_filename"]: AliasType<{
	create?:ValueTypes["PrivateMediaFields_filename_Create"],
	read?:ValueTypes["PrivateMediaFields_filename_Read"],
	update?:ValueTypes["PrivateMediaFields_filename_Update"],
	delete?:ValueTypes["PrivateMediaFields_filename_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_filename_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_filename_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_filename_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_filename_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_mimeType"]: AliasType<{
	create?:ValueTypes["PrivateMediaFields_mimeType_Create"],
	read?:ValueTypes["PrivateMediaFields_mimeType_Read"],
	update?:ValueTypes["PrivateMediaFields_mimeType_Update"],
	delete?:ValueTypes["PrivateMediaFields_mimeType_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_mimeType_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_mimeType_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_mimeType_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_mimeType_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_filesize"]: AliasType<{
	create?:ValueTypes["PrivateMediaFields_filesize_Create"],
	read?:ValueTypes["PrivateMediaFields_filesize_Read"],
	update?:ValueTypes["PrivateMediaFields_filesize_Update"],
	delete?:ValueTypes["PrivateMediaFields_filesize_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_filesize_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_filesize_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_filesize_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_filesize_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_width"]: AliasType<{
	create?:ValueTypes["PrivateMediaFields_width_Create"],
	read?:ValueTypes["PrivateMediaFields_width_Read"],
	update?:ValueTypes["PrivateMediaFields_width_Update"],
	delete?:ValueTypes["PrivateMediaFields_width_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_width_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_width_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_width_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_width_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_height"]: AliasType<{
	create?:ValueTypes["PrivateMediaFields_height_Create"],
	read?:ValueTypes["PrivateMediaFields_height_Read"],
	update?:ValueTypes["PrivateMediaFields_height_Update"],
	delete?:ValueTypes["PrivateMediaFields_height_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_height_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_height_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_height_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_height_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_focalX"]: AliasType<{
	create?:ValueTypes["PrivateMediaFields_focalX_Create"],
	read?:ValueTypes["PrivateMediaFields_focalX_Read"],
	update?:ValueTypes["PrivateMediaFields_focalX_Update"],
	delete?:ValueTypes["PrivateMediaFields_focalX_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_focalX_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_focalX_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_focalX_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_focalX_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_focalY"]: AliasType<{
	create?:ValueTypes["PrivateMediaFields_focalY_Create"],
	read?:ValueTypes["PrivateMediaFields_focalY_Read"],
	update?:ValueTypes["PrivateMediaFields_focalY_Update"],
	delete?:ValueTypes["PrivateMediaFields_focalY_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_focalY_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_focalY_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_focalY_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_focalY_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes"]: AliasType<{
	create?:ValueTypes["PrivateMediaFields_sizes_Create"],
	read?:ValueTypes["PrivateMediaFields_sizes_Read"],
	update?:ValueTypes["PrivateMediaFields_sizes_Update"],
	delete?:ValueTypes["PrivateMediaFields_sizes_Delete"],
	fields?:ValueTypes["PrivateMediaFields_sizes_Fields"],
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_Fields"]: AliasType<{
	thumbnail?:ValueTypes["PrivateMediaFields_sizes_thumbnail"],
	portrait?:ValueTypes["PrivateMediaFields_sizes_portrait"],
	hero?:ValueTypes["PrivateMediaFields_sizes_hero"],
	thumbnail_jpeg?:ValueTypes["PrivateMediaFields_sizes_thumbnail_jpeg"],
	portrait_jpeg?:ValueTypes["PrivateMediaFields_sizes_portrait_jpeg"],
	hero_jpeg?:ValueTypes["PrivateMediaFields_sizes_hero_jpeg"],
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_thumbnail"]: AliasType<{
	create?:ValueTypes["PrivateMediaFields_sizes_thumbnail_Create"],
	read?:ValueTypes["PrivateMediaFields_sizes_thumbnail_Read"],
	update?:ValueTypes["PrivateMediaFields_sizes_thumbnail_Update"],
	delete?:ValueTypes["PrivateMediaFields_sizes_thumbnail_Delete"],
	fields?:ValueTypes["PrivateMediaFields_sizes_thumbnail_Fields"],
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_thumbnail_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_thumbnail_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_thumbnail_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_thumbnail_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_thumbnail_Fields"]: AliasType<{
	url?:ValueTypes["PrivateMediaFields_sizes_thumbnail_url"],
	width?:ValueTypes["PrivateMediaFields_sizes_thumbnail_width"],
	height?:ValueTypes["PrivateMediaFields_sizes_thumbnail_height"],
	mimeType?:ValueTypes["PrivateMediaFields_sizes_thumbnail_mimeType"],
	filesize?:ValueTypes["PrivateMediaFields_sizes_thumbnail_filesize"],
	filename?:ValueTypes["PrivateMediaFields_sizes_thumbnail_filename"],
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_thumbnail_url"]: AliasType<{
	create?:ValueTypes["PrivateMediaFields_sizes_thumbnail_url_Create"],
	read?:ValueTypes["PrivateMediaFields_sizes_thumbnail_url_Read"],
	update?:ValueTypes["PrivateMediaFields_sizes_thumbnail_url_Update"],
	delete?:ValueTypes["PrivateMediaFields_sizes_thumbnail_url_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_thumbnail_url_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_thumbnail_url_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_thumbnail_url_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_thumbnail_url_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_thumbnail_width"]: AliasType<{
	create?:ValueTypes["PrivateMediaFields_sizes_thumbnail_width_Create"],
	read?:ValueTypes["PrivateMediaFields_sizes_thumbnail_width_Read"],
	update?:ValueTypes["PrivateMediaFields_sizes_thumbnail_width_Update"],
	delete?:ValueTypes["PrivateMediaFields_sizes_thumbnail_width_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_thumbnail_width_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_thumbnail_width_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_thumbnail_width_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_thumbnail_width_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_thumbnail_height"]: AliasType<{
	create?:ValueTypes["PrivateMediaFields_sizes_thumbnail_height_Create"],
	read?:ValueTypes["PrivateMediaFields_sizes_thumbnail_height_Read"],
	update?:ValueTypes["PrivateMediaFields_sizes_thumbnail_height_Update"],
	delete?:ValueTypes["PrivateMediaFields_sizes_thumbnail_height_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_thumbnail_height_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_thumbnail_height_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_thumbnail_height_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_thumbnail_height_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_thumbnail_mimeType"]: AliasType<{
	create?:ValueTypes["PrivateMediaFields_sizes_thumbnail_mimeType_Create"],
	read?:ValueTypes["PrivateMediaFields_sizes_thumbnail_mimeType_Read"],
	update?:ValueTypes["PrivateMediaFields_sizes_thumbnail_mimeType_Update"],
	delete?:ValueTypes["PrivateMediaFields_sizes_thumbnail_mimeType_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_thumbnail_mimeType_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_thumbnail_mimeType_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_thumbnail_mimeType_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_thumbnail_mimeType_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_thumbnail_filesize"]: AliasType<{
	create?:ValueTypes["PrivateMediaFields_sizes_thumbnail_filesize_Create"],
	read?:ValueTypes["PrivateMediaFields_sizes_thumbnail_filesize_Read"],
	update?:ValueTypes["PrivateMediaFields_sizes_thumbnail_filesize_Update"],
	delete?:ValueTypes["PrivateMediaFields_sizes_thumbnail_filesize_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_thumbnail_filesize_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_thumbnail_filesize_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_thumbnail_filesize_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_thumbnail_filesize_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_thumbnail_filename"]: AliasType<{
	create?:ValueTypes["PrivateMediaFields_sizes_thumbnail_filename_Create"],
	read?:ValueTypes["PrivateMediaFields_sizes_thumbnail_filename_Read"],
	update?:ValueTypes["PrivateMediaFields_sizes_thumbnail_filename_Update"],
	delete?:ValueTypes["PrivateMediaFields_sizes_thumbnail_filename_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_thumbnail_filename_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_thumbnail_filename_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_thumbnail_filename_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_thumbnail_filename_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_portrait"]: AliasType<{
	create?:ValueTypes["PrivateMediaFields_sizes_portrait_Create"],
	read?:ValueTypes["PrivateMediaFields_sizes_portrait_Read"],
	update?:ValueTypes["PrivateMediaFields_sizes_portrait_Update"],
	delete?:ValueTypes["PrivateMediaFields_sizes_portrait_Delete"],
	fields?:ValueTypes["PrivateMediaFields_sizes_portrait_Fields"],
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_portrait_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_portrait_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_portrait_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_portrait_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_portrait_Fields"]: AliasType<{
	url?:ValueTypes["PrivateMediaFields_sizes_portrait_url"],
	width?:ValueTypes["PrivateMediaFields_sizes_portrait_width"],
	height?:ValueTypes["PrivateMediaFields_sizes_portrait_height"],
	mimeType?:ValueTypes["PrivateMediaFields_sizes_portrait_mimeType"],
	filesize?:ValueTypes["PrivateMediaFields_sizes_portrait_filesize"],
	filename?:ValueTypes["PrivateMediaFields_sizes_portrait_filename"],
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_portrait_url"]: AliasType<{
	create?:ValueTypes["PrivateMediaFields_sizes_portrait_url_Create"],
	read?:ValueTypes["PrivateMediaFields_sizes_portrait_url_Read"],
	update?:ValueTypes["PrivateMediaFields_sizes_portrait_url_Update"],
	delete?:ValueTypes["PrivateMediaFields_sizes_portrait_url_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_portrait_url_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_portrait_url_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_portrait_url_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_portrait_url_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_portrait_width"]: AliasType<{
	create?:ValueTypes["PrivateMediaFields_sizes_portrait_width_Create"],
	read?:ValueTypes["PrivateMediaFields_sizes_portrait_width_Read"],
	update?:ValueTypes["PrivateMediaFields_sizes_portrait_width_Update"],
	delete?:ValueTypes["PrivateMediaFields_sizes_portrait_width_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_portrait_width_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_portrait_width_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_portrait_width_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_portrait_width_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_portrait_height"]: AliasType<{
	create?:ValueTypes["PrivateMediaFields_sizes_portrait_height_Create"],
	read?:ValueTypes["PrivateMediaFields_sizes_portrait_height_Read"],
	update?:ValueTypes["PrivateMediaFields_sizes_portrait_height_Update"],
	delete?:ValueTypes["PrivateMediaFields_sizes_portrait_height_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_portrait_height_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_portrait_height_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_portrait_height_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_portrait_height_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_portrait_mimeType"]: AliasType<{
	create?:ValueTypes["PrivateMediaFields_sizes_portrait_mimeType_Create"],
	read?:ValueTypes["PrivateMediaFields_sizes_portrait_mimeType_Read"],
	update?:ValueTypes["PrivateMediaFields_sizes_portrait_mimeType_Update"],
	delete?:ValueTypes["PrivateMediaFields_sizes_portrait_mimeType_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_portrait_mimeType_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_portrait_mimeType_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_portrait_mimeType_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_portrait_mimeType_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_portrait_filesize"]: AliasType<{
	create?:ValueTypes["PrivateMediaFields_sizes_portrait_filesize_Create"],
	read?:ValueTypes["PrivateMediaFields_sizes_portrait_filesize_Read"],
	update?:ValueTypes["PrivateMediaFields_sizes_portrait_filesize_Update"],
	delete?:ValueTypes["PrivateMediaFields_sizes_portrait_filesize_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_portrait_filesize_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_portrait_filesize_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_portrait_filesize_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_portrait_filesize_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_portrait_filename"]: AliasType<{
	create?:ValueTypes["PrivateMediaFields_sizes_portrait_filename_Create"],
	read?:ValueTypes["PrivateMediaFields_sizes_portrait_filename_Read"],
	update?:ValueTypes["PrivateMediaFields_sizes_portrait_filename_Update"],
	delete?:ValueTypes["PrivateMediaFields_sizes_portrait_filename_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_portrait_filename_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_portrait_filename_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_portrait_filename_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_portrait_filename_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_hero"]: AliasType<{
	create?:ValueTypes["PrivateMediaFields_sizes_hero_Create"],
	read?:ValueTypes["PrivateMediaFields_sizes_hero_Read"],
	update?:ValueTypes["PrivateMediaFields_sizes_hero_Update"],
	delete?:ValueTypes["PrivateMediaFields_sizes_hero_Delete"],
	fields?:ValueTypes["PrivateMediaFields_sizes_hero_Fields"],
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_hero_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_hero_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_hero_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_hero_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_hero_Fields"]: AliasType<{
	url?:ValueTypes["PrivateMediaFields_sizes_hero_url"],
	width?:ValueTypes["PrivateMediaFields_sizes_hero_width"],
	height?:ValueTypes["PrivateMediaFields_sizes_hero_height"],
	mimeType?:ValueTypes["PrivateMediaFields_sizes_hero_mimeType"],
	filesize?:ValueTypes["PrivateMediaFields_sizes_hero_filesize"],
	filename?:ValueTypes["PrivateMediaFields_sizes_hero_filename"],
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_hero_url"]: AliasType<{
	create?:ValueTypes["PrivateMediaFields_sizes_hero_url_Create"],
	read?:ValueTypes["PrivateMediaFields_sizes_hero_url_Read"],
	update?:ValueTypes["PrivateMediaFields_sizes_hero_url_Update"],
	delete?:ValueTypes["PrivateMediaFields_sizes_hero_url_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_hero_url_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_hero_url_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_hero_url_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_hero_url_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_hero_width"]: AliasType<{
	create?:ValueTypes["PrivateMediaFields_sizes_hero_width_Create"],
	read?:ValueTypes["PrivateMediaFields_sizes_hero_width_Read"],
	update?:ValueTypes["PrivateMediaFields_sizes_hero_width_Update"],
	delete?:ValueTypes["PrivateMediaFields_sizes_hero_width_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_hero_width_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_hero_width_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_hero_width_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_hero_width_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_hero_height"]: AliasType<{
	create?:ValueTypes["PrivateMediaFields_sizes_hero_height_Create"],
	read?:ValueTypes["PrivateMediaFields_sizes_hero_height_Read"],
	update?:ValueTypes["PrivateMediaFields_sizes_hero_height_Update"],
	delete?:ValueTypes["PrivateMediaFields_sizes_hero_height_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_hero_height_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_hero_height_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_hero_height_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_hero_height_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_hero_mimeType"]: AliasType<{
	create?:ValueTypes["PrivateMediaFields_sizes_hero_mimeType_Create"],
	read?:ValueTypes["PrivateMediaFields_sizes_hero_mimeType_Read"],
	update?:ValueTypes["PrivateMediaFields_sizes_hero_mimeType_Update"],
	delete?:ValueTypes["PrivateMediaFields_sizes_hero_mimeType_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_hero_mimeType_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_hero_mimeType_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_hero_mimeType_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_hero_mimeType_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_hero_filesize"]: AliasType<{
	create?:ValueTypes["PrivateMediaFields_sizes_hero_filesize_Create"],
	read?:ValueTypes["PrivateMediaFields_sizes_hero_filesize_Read"],
	update?:ValueTypes["PrivateMediaFields_sizes_hero_filesize_Update"],
	delete?:ValueTypes["PrivateMediaFields_sizes_hero_filesize_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_hero_filesize_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_hero_filesize_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_hero_filesize_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_hero_filesize_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_hero_filename"]: AliasType<{
	create?:ValueTypes["PrivateMediaFields_sizes_hero_filename_Create"],
	read?:ValueTypes["PrivateMediaFields_sizes_hero_filename_Read"],
	update?:ValueTypes["PrivateMediaFields_sizes_hero_filename_Update"],
	delete?:ValueTypes["PrivateMediaFields_sizes_hero_filename_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_hero_filename_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_hero_filename_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_hero_filename_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_hero_filename_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_thumbnail_jpeg"]: AliasType<{
	create?:ValueTypes["PrivateMediaFields_sizes_thumbnail_jpeg_Create"],
	read?:ValueTypes["PrivateMediaFields_sizes_thumbnail_jpeg_Read"],
	update?:ValueTypes["PrivateMediaFields_sizes_thumbnail_jpeg_Update"],
	delete?:ValueTypes["PrivateMediaFields_sizes_thumbnail_jpeg_Delete"],
	fields?:ValueTypes["PrivateMediaFields_sizes_thumbnail_jpeg_Fields"],
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_thumbnail_jpeg_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_thumbnail_jpeg_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_thumbnail_jpeg_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_thumbnail_jpeg_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_thumbnail_jpeg_Fields"]: AliasType<{
	url?:ValueTypes["PrivateMediaFields_sizes_thumbnail_jpeg_url"],
	width?:ValueTypes["PrivateMediaFields_sizes_thumbnail_jpeg_width"],
	height?:ValueTypes["PrivateMediaFields_sizes_thumbnail_jpeg_height"],
	mimeType?:ValueTypes["PrivateMediaFields_sizes_thumbnail_jpeg_mimeType"],
	filesize?:ValueTypes["PrivateMediaFields_sizes_thumbnail_jpeg_filesize"],
	filename?:ValueTypes["PrivateMediaFields_sizes_thumbnail_jpeg_filename"],
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_thumbnail_jpeg_url"]: AliasType<{
	create?:ValueTypes["PrivateMediaFields_sizes_thumbnail_jpeg_url_Create"],
	read?:ValueTypes["PrivateMediaFields_sizes_thumbnail_jpeg_url_Read"],
	update?:ValueTypes["PrivateMediaFields_sizes_thumbnail_jpeg_url_Update"],
	delete?:ValueTypes["PrivateMediaFields_sizes_thumbnail_jpeg_url_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_thumbnail_jpeg_url_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_thumbnail_jpeg_url_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_thumbnail_jpeg_url_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_thumbnail_jpeg_url_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_thumbnail_jpeg_width"]: AliasType<{
	create?:ValueTypes["PrivateMediaFields_sizes_thumbnail_jpeg_width_Create"],
	read?:ValueTypes["PrivateMediaFields_sizes_thumbnail_jpeg_width_Read"],
	update?:ValueTypes["PrivateMediaFields_sizes_thumbnail_jpeg_width_Update"],
	delete?:ValueTypes["PrivateMediaFields_sizes_thumbnail_jpeg_width_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_thumbnail_jpeg_width_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_thumbnail_jpeg_width_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_thumbnail_jpeg_width_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_thumbnail_jpeg_width_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_thumbnail_jpeg_height"]: AliasType<{
	create?:ValueTypes["PrivateMediaFields_sizes_thumbnail_jpeg_height_Create"],
	read?:ValueTypes["PrivateMediaFields_sizes_thumbnail_jpeg_height_Read"],
	update?:ValueTypes["PrivateMediaFields_sizes_thumbnail_jpeg_height_Update"],
	delete?:ValueTypes["PrivateMediaFields_sizes_thumbnail_jpeg_height_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_thumbnail_jpeg_height_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_thumbnail_jpeg_height_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_thumbnail_jpeg_height_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_thumbnail_jpeg_height_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_thumbnail_jpeg_mimeType"]: AliasType<{
	create?:ValueTypes["PrivateMediaFields_sizes_thumbnail_jpeg_mimeType_Create"],
	read?:ValueTypes["PrivateMediaFields_sizes_thumbnail_jpeg_mimeType_Read"],
	update?:ValueTypes["PrivateMediaFields_sizes_thumbnail_jpeg_mimeType_Update"],
	delete?:ValueTypes["PrivateMediaFields_sizes_thumbnail_jpeg_mimeType_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_thumbnail_jpeg_mimeType_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_thumbnail_jpeg_mimeType_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_thumbnail_jpeg_mimeType_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_thumbnail_jpeg_mimeType_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_thumbnail_jpeg_filesize"]: AliasType<{
	create?:ValueTypes["PrivateMediaFields_sizes_thumbnail_jpeg_filesize_Create"],
	read?:ValueTypes["PrivateMediaFields_sizes_thumbnail_jpeg_filesize_Read"],
	update?:ValueTypes["PrivateMediaFields_sizes_thumbnail_jpeg_filesize_Update"],
	delete?:ValueTypes["PrivateMediaFields_sizes_thumbnail_jpeg_filesize_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_thumbnail_jpeg_filesize_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_thumbnail_jpeg_filesize_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_thumbnail_jpeg_filesize_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_thumbnail_jpeg_filesize_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_thumbnail_jpeg_filename"]: AliasType<{
	create?:ValueTypes["PrivateMediaFields_sizes_thumbnail_jpeg_filename_Create"],
	read?:ValueTypes["PrivateMediaFields_sizes_thumbnail_jpeg_filename_Read"],
	update?:ValueTypes["PrivateMediaFields_sizes_thumbnail_jpeg_filename_Update"],
	delete?:ValueTypes["PrivateMediaFields_sizes_thumbnail_jpeg_filename_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_thumbnail_jpeg_filename_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_thumbnail_jpeg_filename_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_thumbnail_jpeg_filename_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_thumbnail_jpeg_filename_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_portrait_jpeg"]: AliasType<{
	create?:ValueTypes["PrivateMediaFields_sizes_portrait_jpeg_Create"],
	read?:ValueTypes["PrivateMediaFields_sizes_portrait_jpeg_Read"],
	update?:ValueTypes["PrivateMediaFields_sizes_portrait_jpeg_Update"],
	delete?:ValueTypes["PrivateMediaFields_sizes_portrait_jpeg_Delete"],
	fields?:ValueTypes["PrivateMediaFields_sizes_portrait_jpeg_Fields"],
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_portrait_jpeg_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_portrait_jpeg_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_portrait_jpeg_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_portrait_jpeg_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_portrait_jpeg_Fields"]: AliasType<{
	url?:ValueTypes["PrivateMediaFields_sizes_portrait_jpeg_url"],
	width?:ValueTypes["PrivateMediaFields_sizes_portrait_jpeg_width"],
	height?:ValueTypes["PrivateMediaFields_sizes_portrait_jpeg_height"],
	mimeType?:ValueTypes["PrivateMediaFields_sizes_portrait_jpeg_mimeType"],
	filesize?:ValueTypes["PrivateMediaFields_sizes_portrait_jpeg_filesize"],
	filename?:ValueTypes["PrivateMediaFields_sizes_portrait_jpeg_filename"],
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_portrait_jpeg_url"]: AliasType<{
	create?:ValueTypes["PrivateMediaFields_sizes_portrait_jpeg_url_Create"],
	read?:ValueTypes["PrivateMediaFields_sizes_portrait_jpeg_url_Read"],
	update?:ValueTypes["PrivateMediaFields_sizes_portrait_jpeg_url_Update"],
	delete?:ValueTypes["PrivateMediaFields_sizes_portrait_jpeg_url_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_portrait_jpeg_url_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_portrait_jpeg_url_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_portrait_jpeg_url_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_portrait_jpeg_url_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_portrait_jpeg_width"]: AliasType<{
	create?:ValueTypes["PrivateMediaFields_sizes_portrait_jpeg_width_Create"],
	read?:ValueTypes["PrivateMediaFields_sizes_portrait_jpeg_width_Read"],
	update?:ValueTypes["PrivateMediaFields_sizes_portrait_jpeg_width_Update"],
	delete?:ValueTypes["PrivateMediaFields_sizes_portrait_jpeg_width_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_portrait_jpeg_width_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_portrait_jpeg_width_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_portrait_jpeg_width_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_portrait_jpeg_width_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_portrait_jpeg_height"]: AliasType<{
	create?:ValueTypes["PrivateMediaFields_sizes_portrait_jpeg_height_Create"],
	read?:ValueTypes["PrivateMediaFields_sizes_portrait_jpeg_height_Read"],
	update?:ValueTypes["PrivateMediaFields_sizes_portrait_jpeg_height_Update"],
	delete?:ValueTypes["PrivateMediaFields_sizes_portrait_jpeg_height_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_portrait_jpeg_height_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_portrait_jpeg_height_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_portrait_jpeg_height_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_portrait_jpeg_height_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_portrait_jpeg_mimeType"]: AliasType<{
	create?:ValueTypes["PrivateMediaFields_sizes_portrait_jpeg_mimeType_Create"],
	read?:ValueTypes["PrivateMediaFields_sizes_portrait_jpeg_mimeType_Read"],
	update?:ValueTypes["PrivateMediaFields_sizes_portrait_jpeg_mimeType_Update"],
	delete?:ValueTypes["PrivateMediaFields_sizes_portrait_jpeg_mimeType_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_portrait_jpeg_mimeType_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_portrait_jpeg_mimeType_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_portrait_jpeg_mimeType_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_portrait_jpeg_mimeType_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_portrait_jpeg_filesize"]: AliasType<{
	create?:ValueTypes["PrivateMediaFields_sizes_portrait_jpeg_filesize_Create"],
	read?:ValueTypes["PrivateMediaFields_sizes_portrait_jpeg_filesize_Read"],
	update?:ValueTypes["PrivateMediaFields_sizes_portrait_jpeg_filesize_Update"],
	delete?:ValueTypes["PrivateMediaFields_sizes_portrait_jpeg_filesize_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_portrait_jpeg_filesize_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_portrait_jpeg_filesize_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_portrait_jpeg_filesize_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_portrait_jpeg_filesize_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_portrait_jpeg_filename"]: AliasType<{
	create?:ValueTypes["PrivateMediaFields_sizes_portrait_jpeg_filename_Create"],
	read?:ValueTypes["PrivateMediaFields_sizes_portrait_jpeg_filename_Read"],
	update?:ValueTypes["PrivateMediaFields_sizes_portrait_jpeg_filename_Update"],
	delete?:ValueTypes["PrivateMediaFields_sizes_portrait_jpeg_filename_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_portrait_jpeg_filename_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_portrait_jpeg_filename_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_portrait_jpeg_filename_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_portrait_jpeg_filename_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_hero_jpeg"]: AliasType<{
	create?:ValueTypes["PrivateMediaFields_sizes_hero_jpeg_Create"],
	read?:ValueTypes["PrivateMediaFields_sizes_hero_jpeg_Read"],
	update?:ValueTypes["PrivateMediaFields_sizes_hero_jpeg_Update"],
	delete?:ValueTypes["PrivateMediaFields_sizes_hero_jpeg_Delete"],
	fields?:ValueTypes["PrivateMediaFields_sizes_hero_jpeg_Fields"],
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_hero_jpeg_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_hero_jpeg_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_hero_jpeg_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_hero_jpeg_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_hero_jpeg_Fields"]: AliasType<{
	url?:ValueTypes["PrivateMediaFields_sizes_hero_jpeg_url"],
	width?:ValueTypes["PrivateMediaFields_sizes_hero_jpeg_width"],
	height?:ValueTypes["PrivateMediaFields_sizes_hero_jpeg_height"],
	mimeType?:ValueTypes["PrivateMediaFields_sizes_hero_jpeg_mimeType"],
	filesize?:ValueTypes["PrivateMediaFields_sizes_hero_jpeg_filesize"],
	filename?:ValueTypes["PrivateMediaFields_sizes_hero_jpeg_filename"],
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_hero_jpeg_url"]: AliasType<{
	create?:ValueTypes["PrivateMediaFields_sizes_hero_jpeg_url_Create"],
	read?:ValueTypes["PrivateMediaFields_sizes_hero_jpeg_url_Read"],
	update?:ValueTypes["PrivateMediaFields_sizes_hero_jpeg_url_Update"],
	delete?:ValueTypes["PrivateMediaFields_sizes_hero_jpeg_url_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_hero_jpeg_url_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_hero_jpeg_url_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_hero_jpeg_url_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_hero_jpeg_url_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_hero_jpeg_width"]: AliasType<{
	create?:ValueTypes["PrivateMediaFields_sizes_hero_jpeg_width_Create"],
	read?:ValueTypes["PrivateMediaFields_sizes_hero_jpeg_width_Read"],
	update?:ValueTypes["PrivateMediaFields_sizes_hero_jpeg_width_Update"],
	delete?:ValueTypes["PrivateMediaFields_sizes_hero_jpeg_width_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_hero_jpeg_width_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_hero_jpeg_width_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_hero_jpeg_width_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_hero_jpeg_width_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_hero_jpeg_height"]: AliasType<{
	create?:ValueTypes["PrivateMediaFields_sizes_hero_jpeg_height_Create"],
	read?:ValueTypes["PrivateMediaFields_sizes_hero_jpeg_height_Read"],
	update?:ValueTypes["PrivateMediaFields_sizes_hero_jpeg_height_Update"],
	delete?:ValueTypes["PrivateMediaFields_sizes_hero_jpeg_height_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_hero_jpeg_height_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_hero_jpeg_height_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_hero_jpeg_height_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_hero_jpeg_height_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_hero_jpeg_mimeType"]: AliasType<{
	create?:ValueTypes["PrivateMediaFields_sizes_hero_jpeg_mimeType_Create"],
	read?:ValueTypes["PrivateMediaFields_sizes_hero_jpeg_mimeType_Read"],
	update?:ValueTypes["PrivateMediaFields_sizes_hero_jpeg_mimeType_Update"],
	delete?:ValueTypes["PrivateMediaFields_sizes_hero_jpeg_mimeType_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_hero_jpeg_mimeType_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_hero_jpeg_mimeType_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_hero_jpeg_mimeType_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_hero_jpeg_mimeType_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_hero_jpeg_filesize"]: AliasType<{
	create?:ValueTypes["PrivateMediaFields_sizes_hero_jpeg_filesize_Create"],
	read?:ValueTypes["PrivateMediaFields_sizes_hero_jpeg_filesize_Read"],
	update?:ValueTypes["PrivateMediaFields_sizes_hero_jpeg_filesize_Update"],
	delete?:ValueTypes["PrivateMediaFields_sizes_hero_jpeg_filesize_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_hero_jpeg_filesize_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_hero_jpeg_filesize_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_hero_jpeg_filesize_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_hero_jpeg_filesize_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_hero_jpeg_filename"]: AliasType<{
	create?:ValueTypes["PrivateMediaFields_sizes_hero_jpeg_filename_Create"],
	read?:ValueTypes["PrivateMediaFields_sizes_hero_jpeg_filename_Read"],
	update?:ValueTypes["PrivateMediaFields_sizes_hero_jpeg_filename_Update"],
	delete?:ValueTypes["PrivateMediaFields_sizes_hero_jpeg_filename_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_hero_jpeg_filename_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_hero_jpeg_filename_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_hero_jpeg_filename_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_hero_jpeg_filename_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaCreateAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaReadAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaUpdateAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDeleteAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["mediaAccess"]: AliasType<{
	fields?:ValueTypes["MediaFields"],
	create?:ValueTypes["MediaCreateAccess"],
	read?:ValueTypes["MediaReadAccess"],
	update?:ValueTypes["MediaUpdateAccess"],
	delete?:ValueTypes["MediaDeleteAccess"],
		__typename?: boolean | `@${string}`
}>;
	["MediaFields"]: AliasType<{
	alt?:ValueTypes["MediaFields_alt"],
	updatedAt?:ValueTypes["MediaFields_updatedAt"],
	createdAt?:ValueTypes["MediaFields_createdAt"],
	url?:ValueTypes["MediaFields_url"],
	filename?:ValueTypes["MediaFields_filename"],
	mimeType?:ValueTypes["MediaFields_mimeType"],
	filesize?:ValueTypes["MediaFields_filesize"],
	width?:ValueTypes["MediaFields_width"],
	height?:ValueTypes["MediaFields_height"],
	focalX?:ValueTypes["MediaFields_focalX"],
	focalY?:ValueTypes["MediaFields_focalY"],
	sizes?:ValueTypes["MediaFields_sizes"],
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_alt"]: AliasType<{
	create?:ValueTypes["MediaFields_alt_Create"],
	read?:ValueTypes["MediaFields_alt_Read"],
	update?:ValueTypes["MediaFields_alt_Update"],
	delete?:ValueTypes["MediaFields_alt_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_alt_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_alt_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_alt_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_alt_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_updatedAt"]: AliasType<{
	create?:ValueTypes["MediaFields_updatedAt_Create"],
	read?:ValueTypes["MediaFields_updatedAt_Read"],
	update?:ValueTypes["MediaFields_updatedAt_Update"],
	delete?:ValueTypes["MediaFields_updatedAt_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_updatedAt_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_updatedAt_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_updatedAt_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_updatedAt_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_createdAt"]: AliasType<{
	create?:ValueTypes["MediaFields_createdAt_Create"],
	read?:ValueTypes["MediaFields_createdAt_Read"],
	update?:ValueTypes["MediaFields_createdAt_Update"],
	delete?:ValueTypes["MediaFields_createdAt_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_createdAt_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_createdAt_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_createdAt_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_createdAt_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_url"]: AliasType<{
	create?:ValueTypes["MediaFields_url_Create"],
	read?:ValueTypes["MediaFields_url_Read"],
	update?:ValueTypes["MediaFields_url_Update"],
	delete?:ValueTypes["MediaFields_url_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_url_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_url_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_url_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_url_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_filename"]: AliasType<{
	create?:ValueTypes["MediaFields_filename_Create"],
	read?:ValueTypes["MediaFields_filename_Read"],
	update?:ValueTypes["MediaFields_filename_Update"],
	delete?:ValueTypes["MediaFields_filename_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_filename_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_filename_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_filename_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_filename_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_mimeType"]: AliasType<{
	create?:ValueTypes["MediaFields_mimeType_Create"],
	read?:ValueTypes["MediaFields_mimeType_Read"],
	update?:ValueTypes["MediaFields_mimeType_Update"],
	delete?:ValueTypes["MediaFields_mimeType_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_mimeType_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_mimeType_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_mimeType_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_mimeType_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_filesize"]: AliasType<{
	create?:ValueTypes["MediaFields_filesize_Create"],
	read?:ValueTypes["MediaFields_filesize_Read"],
	update?:ValueTypes["MediaFields_filesize_Update"],
	delete?:ValueTypes["MediaFields_filesize_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_filesize_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_filesize_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_filesize_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_filesize_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_width"]: AliasType<{
	create?:ValueTypes["MediaFields_width_Create"],
	read?:ValueTypes["MediaFields_width_Read"],
	update?:ValueTypes["MediaFields_width_Update"],
	delete?:ValueTypes["MediaFields_width_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_width_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_width_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_width_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_width_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_height"]: AliasType<{
	create?:ValueTypes["MediaFields_height_Create"],
	read?:ValueTypes["MediaFields_height_Read"],
	update?:ValueTypes["MediaFields_height_Update"],
	delete?:ValueTypes["MediaFields_height_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_height_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_height_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_height_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_height_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_focalX"]: AliasType<{
	create?:ValueTypes["MediaFields_focalX_Create"],
	read?:ValueTypes["MediaFields_focalX_Read"],
	update?:ValueTypes["MediaFields_focalX_Update"],
	delete?:ValueTypes["MediaFields_focalX_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_focalX_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_focalX_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_focalX_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_focalX_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_focalY"]: AliasType<{
	create?:ValueTypes["MediaFields_focalY_Create"],
	read?:ValueTypes["MediaFields_focalY_Read"],
	update?:ValueTypes["MediaFields_focalY_Update"],
	delete?:ValueTypes["MediaFields_focalY_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_focalY_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_focalY_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_focalY_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_focalY_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes"]: AliasType<{
	create?:ValueTypes["MediaFields_sizes_Create"],
	read?:ValueTypes["MediaFields_sizes_Read"],
	update?:ValueTypes["MediaFields_sizes_Update"],
	delete?:ValueTypes["MediaFields_sizes_Delete"],
	fields?:ValueTypes["MediaFields_sizes_Fields"],
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_Fields"]: AliasType<{
	small?:ValueTypes["MediaFields_sizes_small"],
	medium?:ValueTypes["MediaFields_sizes_medium"],
	large?:ValueTypes["MediaFields_sizes_large"],
	small_jpeg?:ValueTypes["MediaFields_sizes_small_jpeg"],
	medium_jpeg?:ValueTypes["MediaFields_sizes_medium_jpeg"],
	large_jpeg?:ValueTypes["MediaFields_sizes_large_jpeg"],
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_small"]: AliasType<{
	create?:ValueTypes["MediaFields_sizes_small_Create"],
	read?:ValueTypes["MediaFields_sizes_small_Read"],
	update?:ValueTypes["MediaFields_sizes_small_Update"],
	delete?:ValueTypes["MediaFields_sizes_small_Delete"],
	fields?:ValueTypes["MediaFields_sizes_small_Fields"],
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_small_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_small_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_small_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_small_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_small_Fields"]: AliasType<{
	url?:ValueTypes["MediaFields_sizes_small_url"],
	width?:ValueTypes["MediaFields_sizes_small_width"],
	height?:ValueTypes["MediaFields_sizes_small_height"],
	mimeType?:ValueTypes["MediaFields_sizes_small_mimeType"],
	filesize?:ValueTypes["MediaFields_sizes_small_filesize"],
	filename?:ValueTypes["MediaFields_sizes_small_filename"],
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_small_url"]: AliasType<{
	create?:ValueTypes["MediaFields_sizes_small_url_Create"],
	read?:ValueTypes["MediaFields_sizes_small_url_Read"],
	update?:ValueTypes["MediaFields_sizes_small_url_Update"],
	delete?:ValueTypes["MediaFields_sizes_small_url_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_small_url_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_small_url_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_small_url_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_small_url_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_small_width"]: AliasType<{
	create?:ValueTypes["MediaFields_sizes_small_width_Create"],
	read?:ValueTypes["MediaFields_sizes_small_width_Read"],
	update?:ValueTypes["MediaFields_sizes_small_width_Update"],
	delete?:ValueTypes["MediaFields_sizes_small_width_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_small_width_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_small_width_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_small_width_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_small_width_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_small_height"]: AliasType<{
	create?:ValueTypes["MediaFields_sizes_small_height_Create"],
	read?:ValueTypes["MediaFields_sizes_small_height_Read"],
	update?:ValueTypes["MediaFields_sizes_small_height_Update"],
	delete?:ValueTypes["MediaFields_sizes_small_height_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_small_height_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_small_height_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_small_height_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_small_height_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_small_mimeType"]: AliasType<{
	create?:ValueTypes["MediaFields_sizes_small_mimeType_Create"],
	read?:ValueTypes["MediaFields_sizes_small_mimeType_Read"],
	update?:ValueTypes["MediaFields_sizes_small_mimeType_Update"],
	delete?:ValueTypes["MediaFields_sizes_small_mimeType_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_small_mimeType_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_small_mimeType_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_small_mimeType_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_small_mimeType_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_small_filesize"]: AliasType<{
	create?:ValueTypes["MediaFields_sizes_small_filesize_Create"],
	read?:ValueTypes["MediaFields_sizes_small_filesize_Read"],
	update?:ValueTypes["MediaFields_sizes_small_filesize_Update"],
	delete?:ValueTypes["MediaFields_sizes_small_filesize_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_small_filesize_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_small_filesize_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_small_filesize_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_small_filesize_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_small_filename"]: AliasType<{
	create?:ValueTypes["MediaFields_sizes_small_filename_Create"],
	read?:ValueTypes["MediaFields_sizes_small_filename_Read"],
	update?:ValueTypes["MediaFields_sizes_small_filename_Update"],
	delete?:ValueTypes["MediaFields_sizes_small_filename_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_small_filename_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_small_filename_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_small_filename_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_small_filename_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_medium"]: AliasType<{
	create?:ValueTypes["MediaFields_sizes_medium_Create"],
	read?:ValueTypes["MediaFields_sizes_medium_Read"],
	update?:ValueTypes["MediaFields_sizes_medium_Update"],
	delete?:ValueTypes["MediaFields_sizes_medium_Delete"],
	fields?:ValueTypes["MediaFields_sizes_medium_Fields"],
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_medium_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_medium_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_medium_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_medium_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_medium_Fields"]: AliasType<{
	url?:ValueTypes["MediaFields_sizes_medium_url"],
	width?:ValueTypes["MediaFields_sizes_medium_width"],
	height?:ValueTypes["MediaFields_sizes_medium_height"],
	mimeType?:ValueTypes["MediaFields_sizes_medium_mimeType"],
	filesize?:ValueTypes["MediaFields_sizes_medium_filesize"],
	filename?:ValueTypes["MediaFields_sizes_medium_filename"],
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_medium_url"]: AliasType<{
	create?:ValueTypes["MediaFields_sizes_medium_url_Create"],
	read?:ValueTypes["MediaFields_sizes_medium_url_Read"],
	update?:ValueTypes["MediaFields_sizes_medium_url_Update"],
	delete?:ValueTypes["MediaFields_sizes_medium_url_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_medium_url_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_medium_url_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_medium_url_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_medium_url_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_medium_width"]: AliasType<{
	create?:ValueTypes["MediaFields_sizes_medium_width_Create"],
	read?:ValueTypes["MediaFields_sizes_medium_width_Read"],
	update?:ValueTypes["MediaFields_sizes_medium_width_Update"],
	delete?:ValueTypes["MediaFields_sizes_medium_width_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_medium_width_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_medium_width_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_medium_width_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_medium_width_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_medium_height"]: AliasType<{
	create?:ValueTypes["MediaFields_sizes_medium_height_Create"],
	read?:ValueTypes["MediaFields_sizes_medium_height_Read"],
	update?:ValueTypes["MediaFields_sizes_medium_height_Update"],
	delete?:ValueTypes["MediaFields_sizes_medium_height_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_medium_height_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_medium_height_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_medium_height_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_medium_height_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_medium_mimeType"]: AliasType<{
	create?:ValueTypes["MediaFields_sizes_medium_mimeType_Create"],
	read?:ValueTypes["MediaFields_sizes_medium_mimeType_Read"],
	update?:ValueTypes["MediaFields_sizes_medium_mimeType_Update"],
	delete?:ValueTypes["MediaFields_sizes_medium_mimeType_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_medium_mimeType_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_medium_mimeType_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_medium_mimeType_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_medium_mimeType_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_medium_filesize"]: AliasType<{
	create?:ValueTypes["MediaFields_sizes_medium_filesize_Create"],
	read?:ValueTypes["MediaFields_sizes_medium_filesize_Read"],
	update?:ValueTypes["MediaFields_sizes_medium_filesize_Update"],
	delete?:ValueTypes["MediaFields_sizes_medium_filesize_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_medium_filesize_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_medium_filesize_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_medium_filesize_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_medium_filesize_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_medium_filename"]: AliasType<{
	create?:ValueTypes["MediaFields_sizes_medium_filename_Create"],
	read?:ValueTypes["MediaFields_sizes_medium_filename_Read"],
	update?:ValueTypes["MediaFields_sizes_medium_filename_Update"],
	delete?:ValueTypes["MediaFields_sizes_medium_filename_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_medium_filename_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_medium_filename_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_medium_filename_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_medium_filename_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_large"]: AliasType<{
	create?:ValueTypes["MediaFields_sizes_large_Create"],
	read?:ValueTypes["MediaFields_sizes_large_Read"],
	update?:ValueTypes["MediaFields_sizes_large_Update"],
	delete?:ValueTypes["MediaFields_sizes_large_Delete"],
	fields?:ValueTypes["MediaFields_sizes_large_Fields"],
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_large_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_large_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_large_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_large_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_large_Fields"]: AliasType<{
	url?:ValueTypes["MediaFields_sizes_large_url"],
	width?:ValueTypes["MediaFields_sizes_large_width"],
	height?:ValueTypes["MediaFields_sizes_large_height"],
	mimeType?:ValueTypes["MediaFields_sizes_large_mimeType"],
	filesize?:ValueTypes["MediaFields_sizes_large_filesize"],
	filename?:ValueTypes["MediaFields_sizes_large_filename"],
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_large_url"]: AliasType<{
	create?:ValueTypes["MediaFields_sizes_large_url_Create"],
	read?:ValueTypes["MediaFields_sizes_large_url_Read"],
	update?:ValueTypes["MediaFields_sizes_large_url_Update"],
	delete?:ValueTypes["MediaFields_sizes_large_url_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_large_url_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_large_url_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_large_url_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_large_url_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_large_width"]: AliasType<{
	create?:ValueTypes["MediaFields_sizes_large_width_Create"],
	read?:ValueTypes["MediaFields_sizes_large_width_Read"],
	update?:ValueTypes["MediaFields_sizes_large_width_Update"],
	delete?:ValueTypes["MediaFields_sizes_large_width_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_large_width_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_large_width_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_large_width_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_large_width_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_large_height"]: AliasType<{
	create?:ValueTypes["MediaFields_sizes_large_height_Create"],
	read?:ValueTypes["MediaFields_sizes_large_height_Read"],
	update?:ValueTypes["MediaFields_sizes_large_height_Update"],
	delete?:ValueTypes["MediaFields_sizes_large_height_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_large_height_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_large_height_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_large_height_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_large_height_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_large_mimeType"]: AliasType<{
	create?:ValueTypes["MediaFields_sizes_large_mimeType_Create"],
	read?:ValueTypes["MediaFields_sizes_large_mimeType_Read"],
	update?:ValueTypes["MediaFields_sizes_large_mimeType_Update"],
	delete?:ValueTypes["MediaFields_sizes_large_mimeType_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_large_mimeType_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_large_mimeType_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_large_mimeType_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_large_mimeType_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_large_filesize"]: AliasType<{
	create?:ValueTypes["MediaFields_sizes_large_filesize_Create"],
	read?:ValueTypes["MediaFields_sizes_large_filesize_Read"],
	update?:ValueTypes["MediaFields_sizes_large_filesize_Update"],
	delete?:ValueTypes["MediaFields_sizes_large_filesize_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_large_filesize_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_large_filesize_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_large_filesize_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_large_filesize_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_large_filename"]: AliasType<{
	create?:ValueTypes["MediaFields_sizes_large_filename_Create"],
	read?:ValueTypes["MediaFields_sizes_large_filename_Read"],
	update?:ValueTypes["MediaFields_sizes_large_filename_Update"],
	delete?:ValueTypes["MediaFields_sizes_large_filename_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_large_filename_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_large_filename_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_large_filename_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_large_filename_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_small_jpeg"]: AliasType<{
	create?:ValueTypes["MediaFields_sizes_small_jpeg_Create"],
	read?:ValueTypes["MediaFields_sizes_small_jpeg_Read"],
	update?:ValueTypes["MediaFields_sizes_small_jpeg_Update"],
	delete?:ValueTypes["MediaFields_sizes_small_jpeg_Delete"],
	fields?:ValueTypes["MediaFields_sizes_small_jpeg_Fields"],
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_small_jpeg_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_small_jpeg_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_small_jpeg_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_small_jpeg_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_small_jpeg_Fields"]: AliasType<{
	url?:ValueTypes["MediaFields_sizes_small_jpeg_url"],
	width?:ValueTypes["MediaFields_sizes_small_jpeg_width"],
	height?:ValueTypes["MediaFields_sizes_small_jpeg_height"],
	mimeType?:ValueTypes["MediaFields_sizes_small_jpeg_mimeType"],
	filesize?:ValueTypes["MediaFields_sizes_small_jpeg_filesize"],
	filename?:ValueTypes["MediaFields_sizes_small_jpeg_filename"],
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_small_jpeg_url"]: AliasType<{
	create?:ValueTypes["MediaFields_sizes_small_jpeg_url_Create"],
	read?:ValueTypes["MediaFields_sizes_small_jpeg_url_Read"],
	update?:ValueTypes["MediaFields_sizes_small_jpeg_url_Update"],
	delete?:ValueTypes["MediaFields_sizes_small_jpeg_url_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_small_jpeg_url_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_small_jpeg_url_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_small_jpeg_url_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_small_jpeg_url_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_small_jpeg_width"]: AliasType<{
	create?:ValueTypes["MediaFields_sizes_small_jpeg_width_Create"],
	read?:ValueTypes["MediaFields_sizes_small_jpeg_width_Read"],
	update?:ValueTypes["MediaFields_sizes_small_jpeg_width_Update"],
	delete?:ValueTypes["MediaFields_sizes_small_jpeg_width_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_small_jpeg_width_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_small_jpeg_width_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_small_jpeg_width_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_small_jpeg_width_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_small_jpeg_height"]: AliasType<{
	create?:ValueTypes["MediaFields_sizes_small_jpeg_height_Create"],
	read?:ValueTypes["MediaFields_sizes_small_jpeg_height_Read"],
	update?:ValueTypes["MediaFields_sizes_small_jpeg_height_Update"],
	delete?:ValueTypes["MediaFields_sizes_small_jpeg_height_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_small_jpeg_height_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_small_jpeg_height_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_small_jpeg_height_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_small_jpeg_height_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_small_jpeg_mimeType"]: AliasType<{
	create?:ValueTypes["MediaFields_sizes_small_jpeg_mimeType_Create"],
	read?:ValueTypes["MediaFields_sizes_small_jpeg_mimeType_Read"],
	update?:ValueTypes["MediaFields_sizes_small_jpeg_mimeType_Update"],
	delete?:ValueTypes["MediaFields_sizes_small_jpeg_mimeType_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_small_jpeg_mimeType_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_small_jpeg_mimeType_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_small_jpeg_mimeType_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_small_jpeg_mimeType_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_small_jpeg_filesize"]: AliasType<{
	create?:ValueTypes["MediaFields_sizes_small_jpeg_filesize_Create"],
	read?:ValueTypes["MediaFields_sizes_small_jpeg_filesize_Read"],
	update?:ValueTypes["MediaFields_sizes_small_jpeg_filesize_Update"],
	delete?:ValueTypes["MediaFields_sizes_small_jpeg_filesize_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_small_jpeg_filesize_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_small_jpeg_filesize_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_small_jpeg_filesize_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_small_jpeg_filesize_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_small_jpeg_filename"]: AliasType<{
	create?:ValueTypes["MediaFields_sizes_small_jpeg_filename_Create"],
	read?:ValueTypes["MediaFields_sizes_small_jpeg_filename_Read"],
	update?:ValueTypes["MediaFields_sizes_small_jpeg_filename_Update"],
	delete?:ValueTypes["MediaFields_sizes_small_jpeg_filename_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_small_jpeg_filename_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_small_jpeg_filename_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_small_jpeg_filename_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_small_jpeg_filename_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_medium_jpeg"]: AliasType<{
	create?:ValueTypes["MediaFields_sizes_medium_jpeg_Create"],
	read?:ValueTypes["MediaFields_sizes_medium_jpeg_Read"],
	update?:ValueTypes["MediaFields_sizes_medium_jpeg_Update"],
	delete?:ValueTypes["MediaFields_sizes_medium_jpeg_Delete"],
	fields?:ValueTypes["MediaFields_sizes_medium_jpeg_Fields"],
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_medium_jpeg_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_medium_jpeg_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_medium_jpeg_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_medium_jpeg_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_medium_jpeg_Fields"]: AliasType<{
	url?:ValueTypes["MediaFields_sizes_medium_jpeg_url"],
	width?:ValueTypes["MediaFields_sizes_medium_jpeg_width"],
	height?:ValueTypes["MediaFields_sizes_medium_jpeg_height"],
	mimeType?:ValueTypes["MediaFields_sizes_medium_jpeg_mimeType"],
	filesize?:ValueTypes["MediaFields_sizes_medium_jpeg_filesize"],
	filename?:ValueTypes["MediaFields_sizes_medium_jpeg_filename"],
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_medium_jpeg_url"]: AliasType<{
	create?:ValueTypes["MediaFields_sizes_medium_jpeg_url_Create"],
	read?:ValueTypes["MediaFields_sizes_medium_jpeg_url_Read"],
	update?:ValueTypes["MediaFields_sizes_medium_jpeg_url_Update"],
	delete?:ValueTypes["MediaFields_sizes_medium_jpeg_url_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_medium_jpeg_url_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_medium_jpeg_url_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_medium_jpeg_url_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_medium_jpeg_url_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_medium_jpeg_width"]: AliasType<{
	create?:ValueTypes["MediaFields_sizes_medium_jpeg_width_Create"],
	read?:ValueTypes["MediaFields_sizes_medium_jpeg_width_Read"],
	update?:ValueTypes["MediaFields_sizes_medium_jpeg_width_Update"],
	delete?:ValueTypes["MediaFields_sizes_medium_jpeg_width_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_medium_jpeg_width_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_medium_jpeg_width_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_medium_jpeg_width_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_medium_jpeg_width_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_medium_jpeg_height"]: AliasType<{
	create?:ValueTypes["MediaFields_sizes_medium_jpeg_height_Create"],
	read?:ValueTypes["MediaFields_sizes_medium_jpeg_height_Read"],
	update?:ValueTypes["MediaFields_sizes_medium_jpeg_height_Update"],
	delete?:ValueTypes["MediaFields_sizes_medium_jpeg_height_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_medium_jpeg_height_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_medium_jpeg_height_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_medium_jpeg_height_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_medium_jpeg_height_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_medium_jpeg_mimeType"]: AliasType<{
	create?:ValueTypes["MediaFields_sizes_medium_jpeg_mimeType_Create"],
	read?:ValueTypes["MediaFields_sizes_medium_jpeg_mimeType_Read"],
	update?:ValueTypes["MediaFields_sizes_medium_jpeg_mimeType_Update"],
	delete?:ValueTypes["MediaFields_sizes_medium_jpeg_mimeType_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_medium_jpeg_mimeType_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_medium_jpeg_mimeType_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_medium_jpeg_mimeType_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_medium_jpeg_mimeType_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_medium_jpeg_filesize"]: AliasType<{
	create?:ValueTypes["MediaFields_sizes_medium_jpeg_filesize_Create"],
	read?:ValueTypes["MediaFields_sizes_medium_jpeg_filesize_Read"],
	update?:ValueTypes["MediaFields_sizes_medium_jpeg_filesize_Update"],
	delete?:ValueTypes["MediaFields_sizes_medium_jpeg_filesize_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_medium_jpeg_filesize_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_medium_jpeg_filesize_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_medium_jpeg_filesize_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_medium_jpeg_filesize_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_medium_jpeg_filename"]: AliasType<{
	create?:ValueTypes["MediaFields_sizes_medium_jpeg_filename_Create"],
	read?:ValueTypes["MediaFields_sizes_medium_jpeg_filename_Read"],
	update?:ValueTypes["MediaFields_sizes_medium_jpeg_filename_Update"],
	delete?:ValueTypes["MediaFields_sizes_medium_jpeg_filename_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_medium_jpeg_filename_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_medium_jpeg_filename_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_medium_jpeg_filename_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_medium_jpeg_filename_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_large_jpeg"]: AliasType<{
	create?:ValueTypes["MediaFields_sizes_large_jpeg_Create"],
	read?:ValueTypes["MediaFields_sizes_large_jpeg_Read"],
	update?:ValueTypes["MediaFields_sizes_large_jpeg_Update"],
	delete?:ValueTypes["MediaFields_sizes_large_jpeg_Delete"],
	fields?:ValueTypes["MediaFields_sizes_large_jpeg_Fields"],
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_large_jpeg_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_large_jpeg_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_large_jpeg_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_large_jpeg_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_large_jpeg_Fields"]: AliasType<{
	url?:ValueTypes["MediaFields_sizes_large_jpeg_url"],
	width?:ValueTypes["MediaFields_sizes_large_jpeg_width"],
	height?:ValueTypes["MediaFields_sizes_large_jpeg_height"],
	mimeType?:ValueTypes["MediaFields_sizes_large_jpeg_mimeType"],
	filesize?:ValueTypes["MediaFields_sizes_large_jpeg_filesize"],
	filename?:ValueTypes["MediaFields_sizes_large_jpeg_filename"],
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_large_jpeg_url"]: AliasType<{
	create?:ValueTypes["MediaFields_sizes_large_jpeg_url_Create"],
	read?:ValueTypes["MediaFields_sizes_large_jpeg_url_Read"],
	update?:ValueTypes["MediaFields_sizes_large_jpeg_url_Update"],
	delete?:ValueTypes["MediaFields_sizes_large_jpeg_url_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_large_jpeg_url_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_large_jpeg_url_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_large_jpeg_url_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_large_jpeg_url_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_large_jpeg_width"]: AliasType<{
	create?:ValueTypes["MediaFields_sizes_large_jpeg_width_Create"],
	read?:ValueTypes["MediaFields_sizes_large_jpeg_width_Read"],
	update?:ValueTypes["MediaFields_sizes_large_jpeg_width_Update"],
	delete?:ValueTypes["MediaFields_sizes_large_jpeg_width_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_large_jpeg_width_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_large_jpeg_width_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_large_jpeg_width_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_large_jpeg_width_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_large_jpeg_height"]: AliasType<{
	create?:ValueTypes["MediaFields_sizes_large_jpeg_height_Create"],
	read?:ValueTypes["MediaFields_sizes_large_jpeg_height_Read"],
	update?:ValueTypes["MediaFields_sizes_large_jpeg_height_Update"],
	delete?:ValueTypes["MediaFields_sizes_large_jpeg_height_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_large_jpeg_height_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_large_jpeg_height_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_large_jpeg_height_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_large_jpeg_height_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_large_jpeg_mimeType"]: AliasType<{
	create?:ValueTypes["MediaFields_sizes_large_jpeg_mimeType_Create"],
	read?:ValueTypes["MediaFields_sizes_large_jpeg_mimeType_Read"],
	update?:ValueTypes["MediaFields_sizes_large_jpeg_mimeType_Update"],
	delete?:ValueTypes["MediaFields_sizes_large_jpeg_mimeType_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_large_jpeg_mimeType_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_large_jpeg_mimeType_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_large_jpeg_mimeType_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_large_jpeg_mimeType_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_large_jpeg_filesize"]: AliasType<{
	create?:ValueTypes["MediaFields_sizes_large_jpeg_filesize_Create"],
	read?:ValueTypes["MediaFields_sizes_large_jpeg_filesize_Read"],
	update?:ValueTypes["MediaFields_sizes_large_jpeg_filesize_Update"],
	delete?:ValueTypes["MediaFields_sizes_large_jpeg_filesize_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_large_jpeg_filesize_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_large_jpeg_filesize_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_large_jpeg_filesize_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_large_jpeg_filesize_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_large_jpeg_filename"]: AliasType<{
	create?:ValueTypes["MediaFields_sizes_large_jpeg_filename_Create"],
	read?:ValueTypes["MediaFields_sizes_large_jpeg_filename_Read"],
	update?:ValueTypes["MediaFields_sizes_large_jpeg_filename_Update"],
	delete?:ValueTypes["MediaFields_sizes_large_jpeg_filename_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_large_jpeg_filename_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_large_jpeg_filename_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_large_jpeg_filename_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_large_jpeg_filename_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaCreateAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaReadAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaUpdateAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDeleteAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["backupMediaAccess"]: AliasType<{
	fields?:ValueTypes["BackupMediaFields"],
	create?:ValueTypes["BackupMediaCreateAccess"],
	read?:ValueTypes["BackupMediaReadAccess"],
	update?:ValueTypes["BackupMediaUpdateAccess"],
	delete?:ValueTypes["BackupMediaDeleteAccess"],
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaFields"]: AliasType<{
	alt?:ValueTypes["BackupMediaFields_alt"],
	creator?:ValueTypes["BackupMediaFields_creator"],
	updatedAt?:ValueTypes["BackupMediaFields_updatedAt"],
	createdAt?:ValueTypes["BackupMediaFields_createdAt"],
	url?:ValueTypes["BackupMediaFields_url"],
	filename?:ValueTypes["BackupMediaFields_filename"],
	mimeType?:ValueTypes["BackupMediaFields_mimeType"],
	filesize?:ValueTypes["BackupMediaFields_filesize"],
	width?:ValueTypes["BackupMediaFields_width"],
	height?:ValueTypes["BackupMediaFields_height"],
	focalX?:ValueTypes["BackupMediaFields_focalX"],
	focalY?:ValueTypes["BackupMediaFields_focalY"],
	sizes?:ValueTypes["BackupMediaFields_sizes"],
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaFields_alt"]: AliasType<{
	create?:ValueTypes["BackupMediaFields_alt_Create"],
	read?:ValueTypes["BackupMediaFields_alt_Read"],
	update?:ValueTypes["BackupMediaFields_alt_Update"],
	delete?:ValueTypes["BackupMediaFields_alt_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaFields_alt_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaFields_alt_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaFields_alt_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaFields_alt_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaFields_creator"]: AliasType<{
	create?:ValueTypes["BackupMediaFields_creator_Create"],
	read?:ValueTypes["BackupMediaFields_creator_Read"],
	update?:ValueTypes["BackupMediaFields_creator_Update"],
	delete?:ValueTypes["BackupMediaFields_creator_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaFields_creator_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaFields_creator_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaFields_creator_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaFields_creator_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaFields_updatedAt"]: AliasType<{
	create?:ValueTypes["BackupMediaFields_updatedAt_Create"],
	read?:ValueTypes["BackupMediaFields_updatedAt_Read"],
	update?:ValueTypes["BackupMediaFields_updatedAt_Update"],
	delete?:ValueTypes["BackupMediaFields_updatedAt_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaFields_updatedAt_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaFields_updatedAt_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaFields_updatedAt_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaFields_updatedAt_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaFields_createdAt"]: AliasType<{
	create?:ValueTypes["BackupMediaFields_createdAt_Create"],
	read?:ValueTypes["BackupMediaFields_createdAt_Read"],
	update?:ValueTypes["BackupMediaFields_createdAt_Update"],
	delete?:ValueTypes["BackupMediaFields_createdAt_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaFields_createdAt_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaFields_createdAt_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaFields_createdAt_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaFields_createdAt_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaFields_url"]: AliasType<{
	create?:ValueTypes["BackupMediaFields_url_Create"],
	read?:ValueTypes["BackupMediaFields_url_Read"],
	update?:ValueTypes["BackupMediaFields_url_Update"],
	delete?:ValueTypes["BackupMediaFields_url_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaFields_url_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaFields_url_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaFields_url_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaFields_url_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaFields_filename"]: AliasType<{
	create?:ValueTypes["BackupMediaFields_filename_Create"],
	read?:ValueTypes["BackupMediaFields_filename_Read"],
	update?:ValueTypes["BackupMediaFields_filename_Update"],
	delete?:ValueTypes["BackupMediaFields_filename_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaFields_filename_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaFields_filename_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaFields_filename_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaFields_filename_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaFields_mimeType"]: AliasType<{
	create?:ValueTypes["BackupMediaFields_mimeType_Create"],
	read?:ValueTypes["BackupMediaFields_mimeType_Read"],
	update?:ValueTypes["BackupMediaFields_mimeType_Update"],
	delete?:ValueTypes["BackupMediaFields_mimeType_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaFields_mimeType_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaFields_mimeType_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaFields_mimeType_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaFields_mimeType_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaFields_filesize"]: AliasType<{
	create?:ValueTypes["BackupMediaFields_filesize_Create"],
	read?:ValueTypes["BackupMediaFields_filesize_Read"],
	update?:ValueTypes["BackupMediaFields_filesize_Update"],
	delete?:ValueTypes["BackupMediaFields_filesize_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaFields_filesize_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaFields_filesize_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaFields_filesize_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaFields_filesize_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaFields_width"]: AliasType<{
	create?:ValueTypes["BackupMediaFields_width_Create"],
	read?:ValueTypes["BackupMediaFields_width_Read"],
	update?:ValueTypes["BackupMediaFields_width_Update"],
	delete?:ValueTypes["BackupMediaFields_width_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaFields_width_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaFields_width_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaFields_width_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaFields_width_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaFields_height"]: AliasType<{
	create?:ValueTypes["BackupMediaFields_height_Create"],
	read?:ValueTypes["BackupMediaFields_height_Read"],
	update?:ValueTypes["BackupMediaFields_height_Update"],
	delete?:ValueTypes["BackupMediaFields_height_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaFields_height_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaFields_height_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaFields_height_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaFields_height_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaFields_focalX"]: AliasType<{
	create?:ValueTypes["BackupMediaFields_focalX_Create"],
	read?:ValueTypes["BackupMediaFields_focalX_Read"],
	update?:ValueTypes["BackupMediaFields_focalX_Update"],
	delete?:ValueTypes["BackupMediaFields_focalX_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaFields_focalX_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaFields_focalX_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaFields_focalX_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaFields_focalX_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaFields_focalY"]: AliasType<{
	create?:ValueTypes["BackupMediaFields_focalY_Create"],
	read?:ValueTypes["BackupMediaFields_focalY_Read"],
	update?:ValueTypes["BackupMediaFields_focalY_Update"],
	delete?:ValueTypes["BackupMediaFields_focalY_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaFields_focalY_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaFields_focalY_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaFields_focalY_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaFields_focalY_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaFields_sizes"]: AliasType<{
	create?:ValueTypes["BackupMediaFields_sizes_Create"],
	read?:ValueTypes["BackupMediaFields_sizes_Read"],
	update?:ValueTypes["BackupMediaFields_sizes_Update"],
	delete?:ValueTypes["BackupMediaFields_sizes_Delete"],
	fields?:ValueTypes["BackupMediaFields_sizes_Fields"],
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaFields_sizes_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaFields_sizes_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaFields_sizes_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaFields_sizes_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaFields_sizes_Fields"]: AliasType<{
	thumbnail?:ValueTypes["BackupMediaFields_sizes_thumbnail"],
	sixteenByNineMedium?:ValueTypes["BackupMediaFields_sizes_sixteenByNineMedium"],
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaFields_sizes_thumbnail"]: AliasType<{
	create?:ValueTypes["BackupMediaFields_sizes_thumbnail_Create"],
	read?:ValueTypes["BackupMediaFields_sizes_thumbnail_Read"],
	update?:ValueTypes["BackupMediaFields_sizes_thumbnail_Update"],
	delete?:ValueTypes["BackupMediaFields_sizes_thumbnail_Delete"],
	fields?:ValueTypes["BackupMediaFields_sizes_thumbnail_Fields"],
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaFields_sizes_thumbnail_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaFields_sizes_thumbnail_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaFields_sizes_thumbnail_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaFields_sizes_thumbnail_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaFields_sizes_thumbnail_Fields"]: AliasType<{
	url?:ValueTypes["BackupMediaFields_sizes_thumbnail_url"],
	width?:ValueTypes["BackupMediaFields_sizes_thumbnail_width"],
	height?:ValueTypes["BackupMediaFields_sizes_thumbnail_height"],
	mimeType?:ValueTypes["BackupMediaFields_sizes_thumbnail_mimeType"],
	filesize?:ValueTypes["BackupMediaFields_sizes_thumbnail_filesize"],
	filename?:ValueTypes["BackupMediaFields_sizes_thumbnail_filename"],
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaFields_sizes_thumbnail_url"]: AliasType<{
	create?:ValueTypes["BackupMediaFields_sizes_thumbnail_url_Create"],
	read?:ValueTypes["BackupMediaFields_sizes_thumbnail_url_Read"],
	update?:ValueTypes["BackupMediaFields_sizes_thumbnail_url_Update"],
	delete?:ValueTypes["BackupMediaFields_sizes_thumbnail_url_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaFields_sizes_thumbnail_url_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaFields_sizes_thumbnail_url_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaFields_sizes_thumbnail_url_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaFields_sizes_thumbnail_url_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaFields_sizes_thumbnail_width"]: AliasType<{
	create?:ValueTypes["BackupMediaFields_sizes_thumbnail_width_Create"],
	read?:ValueTypes["BackupMediaFields_sizes_thumbnail_width_Read"],
	update?:ValueTypes["BackupMediaFields_sizes_thumbnail_width_Update"],
	delete?:ValueTypes["BackupMediaFields_sizes_thumbnail_width_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaFields_sizes_thumbnail_width_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaFields_sizes_thumbnail_width_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaFields_sizes_thumbnail_width_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaFields_sizes_thumbnail_width_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaFields_sizes_thumbnail_height"]: AliasType<{
	create?:ValueTypes["BackupMediaFields_sizes_thumbnail_height_Create"],
	read?:ValueTypes["BackupMediaFields_sizes_thumbnail_height_Read"],
	update?:ValueTypes["BackupMediaFields_sizes_thumbnail_height_Update"],
	delete?:ValueTypes["BackupMediaFields_sizes_thumbnail_height_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaFields_sizes_thumbnail_height_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaFields_sizes_thumbnail_height_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaFields_sizes_thumbnail_height_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaFields_sizes_thumbnail_height_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaFields_sizes_thumbnail_mimeType"]: AliasType<{
	create?:ValueTypes["BackupMediaFields_sizes_thumbnail_mimeType_Create"],
	read?:ValueTypes["BackupMediaFields_sizes_thumbnail_mimeType_Read"],
	update?:ValueTypes["BackupMediaFields_sizes_thumbnail_mimeType_Update"],
	delete?:ValueTypes["BackupMediaFields_sizes_thumbnail_mimeType_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaFields_sizes_thumbnail_mimeType_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaFields_sizes_thumbnail_mimeType_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaFields_sizes_thumbnail_mimeType_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaFields_sizes_thumbnail_mimeType_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaFields_sizes_thumbnail_filesize"]: AliasType<{
	create?:ValueTypes["BackupMediaFields_sizes_thumbnail_filesize_Create"],
	read?:ValueTypes["BackupMediaFields_sizes_thumbnail_filesize_Read"],
	update?:ValueTypes["BackupMediaFields_sizes_thumbnail_filesize_Update"],
	delete?:ValueTypes["BackupMediaFields_sizes_thumbnail_filesize_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaFields_sizes_thumbnail_filesize_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaFields_sizes_thumbnail_filesize_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaFields_sizes_thumbnail_filesize_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaFields_sizes_thumbnail_filesize_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaFields_sizes_thumbnail_filename"]: AliasType<{
	create?:ValueTypes["BackupMediaFields_sizes_thumbnail_filename_Create"],
	read?:ValueTypes["BackupMediaFields_sizes_thumbnail_filename_Read"],
	update?:ValueTypes["BackupMediaFields_sizes_thumbnail_filename_Update"],
	delete?:ValueTypes["BackupMediaFields_sizes_thumbnail_filename_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaFields_sizes_thumbnail_filename_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaFields_sizes_thumbnail_filename_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaFields_sizes_thumbnail_filename_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaFields_sizes_thumbnail_filename_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaFields_sizes_sixteenByNineMedium"]: AliasType<{
	create?:ValueTypes["BackupMediaFields_sizes_sixteenByNineMedium_Create"],
	read?:ValueTypes["BackupMediaFields_sizes_sixteenByNineMedium_Read"],
	update?:ValueTypes["BackupMediaFields_sizes_sixteenByNineMedium_Update"],
	delete?:ValueTypes["BackupMediaFields_sizes_sixteenByNineMedium_Delete"],
	fields?:ValueTypes["BackupMediaFields_sizes_sixteenByNineMedium_Fields"],
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaFields_sizes_sixteenByNineMedium_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaFields_sizes_sixteenByNineMedium_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaFields_sizes_sixteenByNineMedium_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaFields_sizes_sixteenByNineMedium_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaFields_sizes_sixteenByNineMedium_Fields"]: AliasType<{
	url?:ValueTypes["BackupMediaFields_sizes_sixteenByNineMedium_url"],
	width?:ValueTypes["BackupMediaFields_sizes_sixteenByNineMedium_width"],
	height?:ValueTypes["BackupMediaFields_sizes_sixteenByNineMedium_height"],
	mimeType?:ValueTypes["BackupMediaFields_sizes_sixteenByNineMedium_mimeType"],
	filesize?:ValueTypes["BackupMediaFields_sizes_sixteenByNineMedium_filesize"],
	filename?:ValueTypes["BackupMediaFields_sizes_sixteenByNineMedium_filename"],
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaFields_sizes_sixteenByNineMedium_url"]: AliasType<{
	create?:ValueTypes["BackupMediaFields_sizes_sixteenByNineMedium_url_Create"],
	read?:ValueTypes["BackupMediaFields_sizes_sixteenByNineMedium_url_Read"],
	update?:ValueTypes["BackupMediaFields_sizes_sixteenByNineMedium_url_Update"],
	delete?:ValueTypes["BackupMediaFields_sizes_sixteenByNineMedium_url_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaFields_sizes_sixteenByNineMedium_url_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaFields_sizes_sixteenByNineMedium_url_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaFields_sizes_sixteenByNineMedium_url_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaFields_sizes_sixteenByNineMedium_url_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaFields_sizes_sixteenByNineMedium_width"]: AliasType<{
	create?:ValueTypes["BackupMediaFields_sizes_sixteenByNineMedium_width_Create"],
	read?:ValueTypes["BackupMediaFields_sizes_sixteenByNineMedium_width_Read"],
	update?:ValueTypes["BackupMediaFields_sizes_sixteenByNineMedium_width_Update"],
	delete?:ValueTypes["BackupMediaFields_sizes_sixteenByNineMedium_width_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaFields_sizes_sixteenByNineMedium_width_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaFields_sizes_sixteenByNineMedium_width_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaFields_sizes_sixteenByNineMedium_width_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaFields_sizes_sixteenByNineMedium_width_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaFields_sizes_sixteenByNineMedium_height"]: AliasType<{
	create?:ValueTypes["BackupMediaFields_sizes_sixteenByNineMedium_height_Create"],
	read?:ValueTypes["BackupMediaFields_sizes_sixteenByNineMedium_height_Read"],
	update?:ValueTypes["BackupMediaFields_sizes_sixteenByNineMedium_height_Update"],
	delete?:ValueTypes["BackupMediaFields_sizes_sixteenByNineMedium_height_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaFields_sizes_sixteenByNineMedium_height_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaFields_sizes_sixteenByNineMedium_height_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaFields_sizes_sixteenByNineMedium_height_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaFields_sizes_sixteenByNineMedium_height_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaFields_sizes_sixteenByNineMedium_mimeType"]: AliasType<{
	create?:ValueTypes["BackupMediaFields_sizes_sixteenByNineMedium_mimeType_Create"],
	read?:ValueTypes["BackupMediaFields_sizes_sixteenByNineMedium_mimeType_Read"],
	update?:ValueTypes["BackupMediaFields_sizes_sixteenByNineMedium_mimeType_Update"],
	delete?:ValueTypes["BackupMediaFields_sizes_sixteenByNineMedium_mimeType_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaFields_sizes_sixteenByNineMedium_mimeType_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaFields_sizes_sixteenByNineMedium_mimeType_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaFields_sizes_sixteenByNineMedium_mimeType_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaFields_sizes_sixteenByNineMedium_mimeType_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaFields_sizes_sixteenByNineMedium_filesize"]: AliasType<{
	create?:ValueTypes["BackupMediaFields_sizes_sixteenByNineMedium_filesize_Create"],
	read?:ValueTypes["BackupMediaFields_sizes_sixteenByNineMedium_filesize_Read"],
	update?:ValueTypes["BackupMediaFields_sizes_sixteenByNineMedium_filesize_Update"],
	delete?:ValueTypes["BackupMediaFields_sizes_sixteenByNineMedium_filesize_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaFields_sizes_sixteenByNineMedium_filesize_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaFields_sizes_sixteenByNineMedium_filesize_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaFields_sizes_sixteenByNineMedium_filesize_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaFields_sizes_sixteenByNineMedium_filesize_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaFields_sizes_sixteenByNineMedium_filename"]: AliasType<{
	create?:ValueTypes["BackupMediaFields_sizes_sixteenByNineMedium_filename_Create"],
	read?:ValueTypes["BackupMediaFields_sizes_sixteenByNineMedium_filename_Read"],
	update?:ValueTypes["BackupMediaFields_sizes_sixteenByNineMedium_filename_Update"],
	delete?:ValueTypes["BackupMediaFields_sizes_sixteenByNineMedium_filename_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaFields_sizes_sixteenByNineMedium_filename_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaFields_sizes_sixteenByNineMedium_filename_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaFields_sizes_sixteenByNineMedium_filename_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaFields_sizes_sixteenByNineMedium_filename_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaCreateAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaReadAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaUpdateAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaDeleteAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["account_suspend_requestsAccess"]: AliasType<{
	fields?:ValueTypes["AccountSuspendRequestsFields"],
	create?:ValueTypes["AccountSuspendRequestsCreateAccess"],
	read?:ValueTypes["AccountSuspendRequestsReadAccess"],
	update?:ValueTypes["AccountSuspendRequestsUpdateAccess"],
	delete?:ValueTypes["AccountSuspendRequestsDeleteAccess"],
		__typename?: boolean | `@${string}`
}>;
	["AccountSuspendRequestsFields"]: AliasType<{
	user?:ValueTypes["AccountSuspendRequestsFields_user"],
	handledBy?:ValueTypes["AccountSuspendRequestsFields_handledBy"],
	updatedAt?:ValueTypes["AccountSuspendRequestsFields_updatedAt"],
	createdAt?:ValueTypes["AccountSuspendRequestsFields_createdAt"],
		__typename?: boolean | `@${string}`
}>;
	["AccountSuspendRequestsFields_user"]: AliasType<{
	create?:ValueTypes["AccountSuspendRequestsFields_user_Create"],
	read?:ValueTypes["AccountSuspendRequestsFields_user_Read"],
	update?:ValueTypes["AccountSuspendRequestsFields_user_Update"],
	delete?:ValueTypes["AccountSuspendRequestsFields_user_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["AccountSuspendRequestsFields_user_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AccountSuspendRequestsFields_user_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AccountSuspendRequestsFields_user_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AccountSuspendRequestsFields_user_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AccountSuspendRequestsFields_handledBy"]: AliasType<{
	create?:ValueTypes["AccountSuspendRequestsFields_handledBy_Create"],
	read?:ValueTypes["AccountSuspendRequestsFields_handledBy_Read"],
	update?:ValueTypes["AccountSuspendRequestsFields_handledBy_Update"],
	delete?:ValueTypes["AccountSuspendRequestsFields_handledBy_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["AccountSuspendRequestsFields_handledBy_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AccountSuspendRequestsFields_handledBy_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AccountSuspendRequestsFields_handledBy_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AccountSuspendRequestsFields_handledBy_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AccountSuspendRequestsFields_updatedAt"]: AliasType<{
	create?:ValueTypes["AccountSuspendRequestsFields_updatedAt_Create"],
	read?:ValueTypes["AccountSuspendRequestsFields_updatedAt_Read"],
	update?:ValueTypes["AccountSuspendRequestsFields_updatedAt_Update"],
	delete?:ValueTypes["AccountSuspendRequestsFields_updatedAt_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["AccountSuspendRequestsFields_updatedAt_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AccountSuspendRequestsFields_updatedAt_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AccountSuspendRequestsFields_updatedAt_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AccountSuspendRequestsFields_updatedAt_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AccountSuspendRequestsFields_createdAt"]: AliasType<{
	create?:ValueTypes["AccountSuspendRequestsFields_createdAt_Create"],
	read?:ValueTypes["AccountSuspendRequestsFields_createdAt_Read"],
	update?:ValueTypes["AccountSuspendRequestsFields_createdAt_Update"],
	delete?:ValueTypes["AccountSuspendRequestsFields_createdAt_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["AccountSuspendRequestsFields_createdAt_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AccountSuspendRequestsFields_createdAt_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AccountSuspendRequestsFields_createdAt_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AccountSuspendRequestsFields_createdAt_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AccountSuspendRequestsCreateAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AccountSuspendRequestsReadAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AccountSuspendRequestsUpdateAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AccountSuspendRequestsDeleteAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["devicesAccess"]: AliasType<{
	fields?:ValueTypes["DevicesFields"],
	create?:ValueTypes["DevicesCreateAccess"],
	read?:ValueTypes["DevicesReadAccess"],
	update?:ValueTypes["DevicesUpdateAccess"],
	delete?:ValueTypes["DevicesDeleteAccess"],
		__typename?: boolean | `@${string}`
}>;
	["DevicesFields"]: AliasType<{
	user?:ValueTypes["DevicesFields_user"],
	udid?:ValueTypes["DevicesFields_udid"],
	pushToken?:ValueTypes["DevicesFields_pushToken"],
	updatedAt?:ValueTypes["DevicesFields_updatedAt"],
	createdAt?:ValueTypes["DevicesFields_createdAt"],
		__typename?: boolean | `@${string}`
}>;
	["DevicesFields_user"]: AliasType<{
	create?:ValueTypes["DevicesFields_user_Create"],
	read?:ValueTypes["DevicesFields_user_Read"],
	update?:ValueTypes["DevicesFields_user_Update"],
	delete?:ValueTypes["DevicesFields_user_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["DevicesFields_user_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DevicesFields_user_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DevicesFields_user_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DevicesFields_user_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DevicesFields_udid"]: AliasType<{
	create?:ValueTypes["DevicesFields_udid_Create"],
	read?:ValueTypes["DevicesFields_udid_Read"],
	update?:ValueTypes["DevicesFields_udid_Update"],
	delete?:ValueTypes["DevicesFields_udid_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["DevicesFields_udid_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DevicesFields_udid_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DevicesFields_udid_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DevicesFields_udid_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DevicesFields_pushToken"]: AliasType<{
	create?:ValueTypes["DevicesFields_pushToken_Create"],
	read?:ValueTypes["DevicesFields_pushToken_Read"],
	update?:ValueTypes["DevicesFields_pushToken_Update"],
	delete?:ValueTypes["DevicesFields_pushToken_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["DevicesFields_pushToken_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DevicesFields_pushToken_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DevicesFields_pushToken_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DevicesFields_pushToken_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DevicesFields_updatedAt"]: AliasType<{
	create?:ValueTypes["DevicesFields_updatedAt_Create"],
	read?:ValueTypes["DevicesFields_updatedAt_Read"],
	update?:ValueTypes["DevicesFields_updatedAt_Update"],
	delete?:ValueTypes["DevicesFields_updatedAt_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["DevicesFields_updatedAt_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DevicesFields_updatedAt_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DevicesFields_updatedAt_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DevicesFields_updatedAt_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DevicesFields_createdAt"]: AliasType<{
	create?:ValueTypes["DevicesFields_createdAt_Create"],
	read?:ValueTypes["DevicesFields_createdAt_Read"],
	update?:ValueTypes["DevicesFields_createdAt_Update"],
	delete?:ValueTypes["DevicesFields_createdAt_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["DevicesFields_createdAt_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DevicesFields_createdAt_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DevicesFields_createdAt_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DevicesFields_createdAt_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DevicesCreateAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DevicesReadAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DevicesUpdateAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DevicesDeleteAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["notificationsAccess"]: AliasType<{
	fields?:ValueTypes["NotificationsFields"],
	create?:ValueTypes["NotificationsCreateAccess"],
	read?:ValueTypes["NotificationsReadAccess"],
	update?:ValueTypes["NotificationsUpdateAccess"],
	delete?:ValueTypes["NotificationsDeleteAccess"],
		__typename?: boolean | `@${string}`
}>;
	["NotificationsFields"]: AliasType<{
	receiver?:ValueTypes["NotificationsFields_receiver"],
	type?:ValueTypes["NotificationsFields_type"],
	meta?:ValueTypes["NotificationsFields_meta"],
	message?:ValueTypes["NotificationsFields_message"],
	sender?:ValueTypes["NotificationsFields_sender"],
	readed?:ValueTypes["NotificationsFields_readed"],
	deletedByUser?:ValueTypes["NotificationsFields_deletedByUser"],
	updatedAt?:ValueTypes["NotificationsFields_updatedAt"],
	createdAt?:ValueTypes["NotificationsFields_createdAt"],
		__typename?: boolean | `@${string}`
}>;
	["NotificationsFields_receiver"]: AliasType<{
	create?:ValueTypes["NotificationsFields_receiver_Create"],
	read?:ValueTypes["NotificationsFields_receiver_Read"],
	update?:ValueTypes["NotificationsFields_receiver_Update"],
	delete?:ValueTypes["NotificationsFields_receiver_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["NotificationsFields_receiver_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["NotificationsFields_receiver_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["NotificationsFields_receiver_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["NotificationsFields_receiver_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["NotificationsFields_type"]: AliasType<{
	create?:ValueTypes["NotificationsFields_type_Create"],
	read?:ValueTypes["NotificationsFields_type_Read"],
	update?:ValueTypes["NotificationsFields_type_Update"],
	delete?:ValueTypes["NotificationsFields_type_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["NotificationsFields_type_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["NotificationsFields_type_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["NotificationsFields_type_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["NotificationsFields_type_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["NotificationsFields_meta"]: AliasType<{
	create?:ValueTypes["NotificationsFields_meta_Create"],
	read?:ValueTypes["NotificationsFields_meta_Read"],
	update?:ValueTypes["NotificationsFields_meta_Update"],
	delete?:ValueTypes["NotificationsFields_meta_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["NotificationsFields_meta_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["NotificationsFields_meta_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["NotificationsFields_meta_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["NotificationsFields_meta_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["NotificationsFields_message"]: AliasType<{
	create?:ValueTypes["NotificationsFields_message_Create"],
	read?:ValueTypes["NotificationsFields_message_Read"],
	update?:ValueTypes["NotificationsFields_message_Update"],
	delete?:ValueTypes["NotificationsFields_message_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["NotificationsFields_message_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["NotificationsFields_message_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["NotificationsFields_message_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["NotificationsFields_message_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["NotificationsFields_sender"]: AliasType<{
	create?:ValueTypes["NotificationsFields_sender_Create"],
	read?:ValueTypes["NotificationsFields_sender_Read"],
	update?:ValueTypes["NotificationsFields_sender_Update"],
	delete?:ValueTypes["NotificationsFields_sender_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["NotificationsFields_sender_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["NotificationsFields_sender_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["NotificationsFields_sender_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["NotificationsFields_sender_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["NotificationsFields_readed"]: AliasType<{
	create?:ValueTypes["NotificationsFields_readed_Create"],
	read?:ValueTypes["NotificationsFields_readed_Read"],
	update?:ValueTypes["NotificationsFields_readed_Update"],
	delete?:ValueTypes["NotificationsFields_readed_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["NotificationsFields_readed_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["NotificationsFields_readed_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["NotificationsFields_readed_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["NotificationsFields_readed_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["NotificationsFields_deletedByUser"]: AliasType<{
	create?:ValueTypes["NotificationsFields_deletedByUser_Create"],
	read?:ValueTypes["NotificationsFields_deletedByUser_Read"],
	update?:ValueTypes["NotificationsFields_deletedByUser_Update"],
	delete?:ValueTypes["NotificationsFields_deletedByUser_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["NotificationsFields_deletedByUser_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["NotificationsFields_deletedByUser_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["NotificationsFields_deletedByUser_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["NotificationsFields_deletedByUser_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["NotificationsFields_updatedAt"]: AliasType<{
	create?:ValueTypes["NotificationsFields_updatedAt_Create"],
	read?:ValueTypes["NotificationsFields_updatedAt_Read"],
	update?:ValueTypes["NotificationsFields_updatedAt_Update"],
	delete?:ValueTypes["NotificationsFields_updatedAt_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["NotificationsFields_updatedAt_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["NotificationsFields_updatedAt_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["NotificationsFields_updatedAt_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["NotificationsFields_updatedAt_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["NotificationsFields_createdAt"]: AliasType<{
	create?:ValueTypes["NotificationsFields_createdAt_Create"],
	read?:ValueTypes["NotificationsFields_createdAt_Read"],
	update?:ValueTypes["NotificationsFields_createdAt_Update"],
	delete?:ValueTypes["NotificationsFields_createdAt_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["NotificationsFields_createdAt_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["NotificationsFields_createdAt_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["NotificationsFields_createdAt_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["NotificationsFields_createdAt_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["NotificationsCreateAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["NotificationsReadAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["NotificationsUpdateAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["NotificationsDeleteAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["redirectSettingAccess"]: AliasType<{
	fields?:ValueTypes["RedirectSettingFields"],
	create?:ValueTypes["RedirectSettingCreateAccess"],
	read?:ValueTypes["RedirectSettingReadAccess"],
	update?:ValueTypes["RedirectSettingUpdateAccess"],
	delete?:ValueTypes["RedirectSettingDeleteAccess"],
		__typename?: boolean | `@${string}`
}>;
	["RedirectSettingFields"]: AliasType<{
	from?:ValueTypes["RedirectSettingFields_from"],
	to?:ValueTypes["RedirectSettingFields_to"],
	updatedAt?:ValueTypes["RedirectSettingFields_updatedAt"],
	createdAt?:ValueTypes["RedirectSettingFields_createdAt"],
		__typename?: boolean | `@${string}`
}>;
	["RedirectSettingFields_from"]: AliasType<{
	create?:ValueTypes["RedirectSettingFields_from_Create"],
	read?:ValueTypes["RedirectSettingFields_from_Read"],
	update?:ValueTypes["RedirectSettingFields_from_Update"],
	delete?:ValueTypes["RedirectSettingFields_from_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["RedirectSettingFields_from_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedirectSettingFields_from_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedirectSettingFields_from_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedirectSettingFields_from_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedirectSettingFields_to"]: AliasType<{
	create?:ValueTypes["RedirectSettingFields_to_Create"],
	read?:ValueTypes["RedirectSettingFields_to_Read"],
	update?:ValueTypes["RedirectSettingFields_to_Update"],
	delete?:ValueTypes["RedirectSettingFields_to_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["RedirectSettingFields_to_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedirectSettingFields_to_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedirectSettingFields_to_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedirectSettingFields_to_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedirectSettingFields_updatedAt"]: AliasType<{
	create?:ValueTypes["RedirectSettingFields_updatedAt_Create"],
	read?:ValueTypes["RedirectSettingFields_updatedAt_Read"],
	update?:ValueTypes["RedirectSettingFields_updatedAt_Update"],
	delete?:ValueTypes["RedirectSettingFields_updatedAt_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["RedirectSettingFields_updatedAt_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedirectSettingFields_updatedAt_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedirectSettingFields_updatedAt_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedirectSettingFields_updatedAt_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedirectSettingFields_createdAt"]: AliasType<{
	create?:ValueTypes["RedirectSettingFields_createdAt_Create"],
	read?:ValueTypes["RedirectSettingFields_createdAt_Read"],
	update?:ValueTypes["RedirectSettingFields_createdAt_Update"],
	delete?:ValueTypes["RedirectSettingFields_createdAt_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["RedirectSettingFields_createdAt_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedirectSettingFields_createdAt_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedirectSettingFields_createdAt_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedirectSettingFields_createdAt_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedirectSettingCreateAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedirectSettingReadAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedirectSettingUpdateAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedirectSettingDeleteAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ranking_eventsAccess"]: AliasType<{
	fields?:ValueTypes["RankingEventsFields"],
	create?:ValueTypes["RankingEventsCreateAccess"],
	read?:ValueTypes["RankingEventsReadAccess"],
	update?:ValueTypes["RankingEventsUpdateAccess"],
	delete?:ValueTypes["RankingEventsDeleteAccess"],
		__typename?: boolean | `@${string}`
}>;
	["RankingEventsFields"]: AliasType<{
	name?:ValueTypes["RankingEventsFields_name"],
	visible?:ValueTypes["RankingEventsFields_visible"],
	maxItems?:ValueTypes["RankingEventsFields_maxItems"],
	template?:ValueTypes["RankingEventsFields_template"],
	list?:ValueTypes["RankingEventsFields_list"],
	region?:ValueTypes["RankingEventsFields_region"],
	updatedAt?:ValueTypes["RankingEventsFields_updatedAt"],
	createdAt?:ValueTypes["RankingEventsFields_createdAt"],
		__typename?: boolean | `@${string}`
}>;
	["RankingEventsFields_name"]: AliasType<{
	create?:ValueTypes["RankingEventsFields_name_Create"],
	read?:ValueTypes["RankingEventsFields_name_Read"],
	update?:ValueTypes["RankingEventsFields_name_Update"],
	delete?:ValueTypes["RankingEventsFields_name_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["RankingEventsFields_name_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RankingEventsFields_name_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RankingEventsFields_name_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RankingEventsFields_name_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RankingEventsFields_visible"]: AliasType<{
	create?:ValueTypes["RankingEventsFields_visible_Create"],
	read?:ValueTypes["RankingEventsFields_visible_Read"],
	update?:ValueTypes["RankingEventsFields_visible_Update"],
	delete?:ValueTypes["RankingEventsFields_visible_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["RankingEventsFields_visible_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RankingEventsFields_visible_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RankingEventsFields_visible_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RankingEventsFields_visible_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RankingEventsFields_maxItems"]: AliasType<{
	create?:ValueTypes["RankingEventsFields_maxItems_Create"],
	read?:ValueTypes["RankingEventsFields_maxItems_Read"],
	update?:ValueTypes["RankingEventsFields_maxItems_Update"],
	delete?:ValueTypes["RankingEventsFields_maxItems_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["RankingEventsFields_maxItems_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RankingEventsFields_maxItems_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RankingEventsFields_maxItems_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RankingEventsFields_maxItems_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RankingEventsFields_template"]: AliasType<{
	create?:ValueTypes["RankingEventsFields_template_Create"],
	read?:ValueTypes["RankingEventsFields_template_Read"],
	update?:ValueTypes["RankingEventsFields_template_Update"],
	delete?:ValueTypes["RankingEventsFields_template_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["RankingEventsFields_template_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RankingEventsFields_template_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RankingEventsFields_template_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RankingEventsFields_template_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RankingEventsFields_list"]: AliasType<{
	create?:ValueTypes["RankingEventsFields_list_Create"],
	read?:ValueTypes["RankingEventsFields_list_Read"],
	update?:ValueTypes["RankingEventsFields_list_Update"],
	delete?:ValueTypes["RankingEventsFields_list_Delete"],
	fields?:ValueTypes["RankingEventsFields_list_Fields"],
		__typename?: boolean | `@${string}`
}>;
	["RankingEventsFields_list_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RankingEventsFields_list_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RankingEventsFields_list_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RankingEventsFields_list_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RankingEventsFields_list_Fields"]: AliasType<{
	item?:ValueTypes["RankingEventsFields_list_item"],
	id?:ValueTypes["RankingEventsFields_list_id"],
		__typename?: boolean | `@${string}`
}>;
	["RankingEventsFields_list_item"]: AliasType<{
	create?:ValueTypes["RankingEventsFields_list_item_Create"],
	read?:ValueTypes["RankingEventsFields_list_item_Read"],
	update?:ValueTypes["RankingEventsFields_list_item_Update"],
	delete?:ValueTypes["RankingEventsFields_list_item_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["RankingEventsFields_list_item_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RankingEventsFields_list_item_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RankingEventsFields_list_item_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RankingEventsFields_list_item_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RankingEventsFields_list_id"]: AliasType<{
	create?:ValueTypes["RankingEventsFields_list_id_Create"],
	read?:ValueTypes["RankingEventsFields_list_id_Read"],
	update?:ValueTypes["RankingEventsFields_list_id_Update"],
	delete?:ValueTypes["RankingEventsFields_list_id_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["RankingEventsFields_list_id_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RankingEventsFields_list_id_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RankingEventsFields_list_id_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RankingEventsFields_list_id_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RankingEventsFields_region"]: AliasType<{
	create?:ValueTypes["RankingEventsFields_region_Create"],
	read?:ValueTypes["RankingEventsFields_region_Read"],
	update?:ValueTypes["RankingEventsFields_region_Update"],
	delete?:ValueTypes["RankingEventsFields_region_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["RankingEventsFields_region_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RankingEventsFields_region_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RankingEventsFields_region_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RankingEventsFields_region_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RankingEventsFields_updatedAt"]: AliasType<{
	create?:ValueTypes["RankingEventsFields_updatedAt_Create"],
	read?:ValueTypes["RankingEventsFields_updatedAt_Read"],
	update?:ValueTypes["RankingEventsFields_updatedAt_Update"],
	delete?:ValueTypes["RankingEventsFields_updatedAt_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["RankingEventsFields_updatedAt_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RankingEventsFields_updatedAt_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RankingEventsFields_updatedAt_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RankingEventsFields_updatedAt_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RankingEventsFields_createdAt"]: AliasType<{
	create?:ValueTypes["RankingEventsFields_createdAt_Create"],
	read?:ValueTypes["RankingEventsFields_createdAt_Read"],
	update?:ValueTypes["RankingEventsFields_createdAt_Update"],
	delete?:ValueTypes["RankingEventsFields_createdAt_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["RankingEventsFields_createdAt_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RankingEventsFields_createdAt_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RankingEventsFields_createdAt_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RankingEventsFields_createdAt_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RankingEventsCreateAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RankingEventsReadAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RankingEventsUpdateAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RankingEventsDeleteAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ranking_locationsAccess"]: AliasType<{
	fields?:ValueTypes["RankingLocationsFields"],
	create?:ValueTypes["RankingLocationsCreateAccess"],
	read?:ValueTypes["RankingLocationsReadAccess"],
	update?:ValueTypes["RankingLocationsUpdateAccess"],
	delete?:ValueTypes["RankingLocationsDeleteAccess"],
		__typename?: boolean | `@${string}`
}>;
	["RankingLocationsFields"]: AliasType<{
	name?:ValueTypes["RankingLocationsFields_name"],
	visible?:ValueTypes["RankingLocationsFields_visible"],
	maxItems?:ValueTypes["RankingLocationsFields_maxItems"],
	template?:ValueTypes["RankingLocationsFields_template"],
	list?:ValueTypes["RankingLocationsFields_list"],
	region?:ValueTypes["RankingLocationsFields_region"],
	updatedAt?:ValueTypes["RankingLocationsFields_updatedAt"],
	createdAt?:ValueTypes["RankingLocationsFields_createdAt"],
		__typename?: boolean | `@${string}`
}>;
	["RankingLocationsFields_name"]: AliasType<{
	create?:ValueTypes["RankingLocationsFields_name_Create"],
	read?:ValueTypes["RankingLocationsFields_name_Read"],
	update?:ValueTypes["RankingLocationsFields_name_Update"],
	delete?:ValueTypes["RankingLocationsFields_name_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["RankingLocationsFields_name_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RankingLocationsFields_name_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RankingLocationsFields_name_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RankingLocationsFields_name_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RankingLocationsFields_visible"]: AliasType<{
	create?:ValueTypes["RankingLocationsFields_visible_Create"],
	read?:ValueTypes["RankingLocationsFields_visible_Read"],
	update?:ValueTypes["RankingLocationsFields_visible_Update"],
	delete?:ValueTypes["RankingLocationsFields_visible_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["RankingLocationsFields_visible_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RankingLocationsFields_visible_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RankingLocationsFields_visible_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RankingLocationsFields_visible_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RankingLocationsFields_maxItems"]: AliasType<{
	create?:ValueTypes["RankingLocationsFields_maxItems_Create"],
	read?:ValueTypes["RankingLocationsFields_maxItems_Read"],
	update?:ValueTypes["RankingLocationsFields_maxItems_Update"],
	delete?:ValueTypes["RankingLocationsFields_maxItems_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["RankingLocationsFields_maxItems_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RankingLocationsFields_maxItems_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RankingLocationsFields_maxItems_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RankingLocationsFields_maxItems_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RankingLocationsFields_template"]: AliasType<{
	create?:ValueTypes["RankingLocationsFields_template_Create"],
	read?:ValueTypes["RankingLocationsFields_template_Read"],
	update?:ValueTypes["RankingLocationsFields_template_Update"],
	delete?:ValueTypes["RankingLocationsFields_template_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["RankingLocationsFields_template_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RankingLocationsFields_template_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RankingLocationsFields_template_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RankingLocationsFields_template_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RankingLocationsFields_list"]: AliasType<{
	create?:ValueTypes["RankingLocationsFields_list_Create"],
	read?:ValueTypes["RankingLocationsFields_list_Read"],
	update?:ValueTypes["RankingLocationsFields_list_Update"],
	delete?:ValueTypes["RankingLocationsFields_list_Delete"],
	fields?:ValueTypes["RankingLocationsFields_list_Fields"],
		__typename?: boolean | `@${string}`
}>;
	["RankingLocationsFields_list_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RankingLocationsFields_list_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RankingLocationsFields_list_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RankingLocationsFields_list_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RankingLocationsFields_list_Fields"]: AliasType<{
	item?:ValueTypes["RankingLocationsFields_list_item"],
	id?:ValueTypes["RankingLocationsFields_list_id"],
		__typename?: boolean | `@${string}`
}>;
	["RankingLocationsFields_list_item"]: AliasType<{
	create?:ValueTypes["RankingLocationsFields_list_item_Create"],
	read?:ValueTypes["RankingLocationsFields_list_item_Read"],
	update?:ValueTypes["RankingLocationsFields_list_item_Update"],
	delete?:ValueTypes["RankingLocationsFields_list_item_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["RankingLocationsFields_list_item_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RankingLocationsFields_list_item_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RankingLocationsFields_list_item_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RankingLocationsFields_list_item_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RankingLocationsFields_list_id"]: AliasType<{
	create?:ValueTypes["RankingLocationsFields_list_id_Create"],
	read?:ValueTypes["RankingLocationsFields_list_id_Read"],
	update?:ValueTypes["RankingLocationsFields_list_id_Update"],
	delete?:ValueTypes["RankingLocationsFields_list_id_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["RankingLocationsFields_list_id_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RankingLocationsFields_list_id_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RankingLocationsFields_list_id_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RankingLocationsFields_list_id_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RankingLocationsFields_region"]: AliasType<{
	create?:ValueTypes["RankingLocationsFields_region_Create"],
	read?:ValueTypes["RankingLocationsFields_region_Read"],
	update?:ValueTypes["RankingLocationsFields_region_Update"],
	delete?:ValueTypes["RankingLocationsFields_region_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["RankingLocationsFields_region_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RankingLocationsFields_region_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RankingLocationsFields_region_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RankingLocationsFields_region_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RankingLocationsFields_updatedAt"]: AliasType<{
	create?:ValueTypes["RankingLocationsFields_updatedAt_Create"],
	read?:ValueTypes["RankingLocationsFields_updatedAt_Read"],
	update?:ValueTypes["RankingLocationsFields_updatedAt_Update"],
	delete?:ValueTypes["RankingLocationsFields_updatedAt_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["RankingLocationsFields_updatedAt_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RankingLocationsFields_updatedAt_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RankingLocationsFields_updatedAt_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RankingLocationsFields_updatedAt_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RankingLocationsFields_createdAt"]: AliasType<{
	create?:ValueTypes["RankingLocationsFields_createdAt_Create"],
	read?:ValueTypes["RankingLocationsFields_createdAt_Read"],
	update?:ValueTypes["RankingLocationsFields_createdAt_Update"],
	delete?:ValueTypes["RankingLocationsFields_createdAt_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["RankingLocationsFields_createdAt_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RankingLocationsFields_createdAt_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RankingLocationsFields_createdAt_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RankingLocationsFields_createdAt_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RankingLocationsCreateAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RankingLocationsReadAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RankingLocationsUpdateAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RankingLocationsDeleteAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["event_calendarAccess"]: AliasType<{
	fields?:ValueTypes["EventCalendarFields"],
	create?:ValueTypes["EventCalendarCreateAccess"],
	read?:ValueTypes["EventCalendarReadAccess"],
	update?:ValueTypes["EventCalendarUpdateAccess"],
	delete?:ValueTypes["EventCalendarDeleteAccess"],
		__typename?: boolean | `@${string}`
}>;
	["EventCalendarFields"]: AliasType<{
	date?:ValueTypes["EventCalendarFields_date"],
	item?:ValueTypes["EventCalendarFields_item"],
	title?:ValueTypes["EventCalendarFields_title"],
	region?:ValueTypes["EventCalendarFields_region"],
	updatedAt?:ValueTypes["EventCalendarFields_updatedAt"],
	createdAt?:ValueTypes["EventCalendarFields_createdAt"],
		__typename?: boolean | `@${string}`
}>;
	["EventCalendarFields_date"]: AliasType<{
	create?:ValueTypes["EventCalendarFields_date_Create"],
	read?:ValueTypes["EventCalendarFields_date_Read"],
	update?:ValueTypes["EventCalendarFields_date_Update"],
	delete?:ValueTypes["EventCalendarFields_date_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventCalendarFields_date_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventCalendarFields_date_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventCalendarFields_date_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventCalendarFields_date_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventCalendarFields_item"]: AliasType<{
	create?:ValueTypes["EventCalendarFields_item_Create"],
	read?:ValueTypes["EventCalendarFields_item_Read"],
	update?:ValueTypes["EventCalendarFields_item_Update"],
	delete?:ValueTypes["EventCalendarFields_item_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventCalendarFields_item_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventCalendarFields_item_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventCalendarFields_item_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventCalendarFields_item_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventCalendarFields_title"]: AliasType<{
	create?:ValueTypes["EventCalendarFields_title_Create"],
	read?:ValueTypes["EventCalendarFields_title_Read"],
	update?:ValueTypes["EventCalendarFields_title_Update"],
	delete?:ValueTypes["EventCalendarFields_title_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventCalendarFields_title_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventCalendarFields_title_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventCalendarFields_title_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventCalendarFields_title_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventCalendarFields_region"]: AliasType<{
	create?:ValueTypes["EventCalendarFields_region_Create"],
	read?:ValueTypes["EventCalendarFields_region_Read"],
	update?:ValueTypes["EventCalendarFields_region_Update"],
	delete?:ValueTypes["EventCalendarFields_region_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventCalendarFields_region_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventCalendarFields_region_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventCalendarFields_region_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventCalendarFields_region_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventCalendarFields_updatedAt"]: AliasType<{
	create?:ValueTypes["EventCalendarFields_updatedAt_Create"],
	read?:ValueTypes["EventCalendarFields_updatedAt_Read"],
	update?:ValueTypes["EventCalendarFields_updatedAt_Update"],
	delete?:ValueTypes["EventCalendarFields_updatedAt_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventCalendarFields_updatedAt_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventCalendarFields_updatedAt_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventCalendarFields_updatedAt_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventCalendarFields_updatedAt_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventCalendarFields_createdAt"]: AliasType<{
	create?:ValueTypes["EventCalendarFields_createdAt_Create"],
	read?:ValueTypes["EventCalendarFields_createdAt_Read"],
	update?:ValueTypes["EventCalendarFields_createdAt_Update"],
	delete?:ValueTypes["EventCalendarFields_createdAt_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventCalendarFields_createdAt_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventCalendarFields_createdAt_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventCalendarFields_createdAt_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventCalendarFields_createdAt_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventCalendarCreateAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventCalendarReadAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventCalendarUpdateAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventCalendarDeleteAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["editor_choicesAccess"]: AliasType<{
	fields?:ValueTypes["EditorChoicesFields"],
	create?:ValueTypes["EditorChoicesCreateAccess"],
	read?:ValueTypes["EditorChoicesReadAccess"],
	update?:ValueTypes["EditorChoicesUpdateAccess"],
	delete?:ValueTypes["EditorChoicesDeleteAccess"],
		__typename?: boolean | `@${string}`
}>;
	["EditorChoicesFields"]: AliasType<{
	name?:ValueTypes["EditorChoicesFields_name"],
	visible?:ValueTypes["EditorChoicesFields_visible"],
	editorialPicks?:ValueTypes["EditorChoicesFields_editorialPicks"],
	list?:ValueTypes["EditorChoicesFields_list"],
	region?:ValueTypes["EditorChoicesFields_region"],
	updatedAt?:ValueTypes["EditorChoicesFields_updatedAt"],
	createdAt?:ValueTypes["EditorChoicesFields_createdAt"],
		__typename?: boolean | `@${string}`
}>;
	["EditorChoicesFields_name"]: AliasType<{
	create?:ValueTypes["EditorChoicesFields_name_Create"],
	read?:ValueTypes["EditorChoicesFields_name_Read"],
	update?:ValueTypes["EditorChoicesFields_name_Update"],
	delete?:ValueTypes["EditorChoicesFields_name_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EditorChoicesFields_name_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EditorChoicesFields_name_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EditorChoicesFields_name_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EditorChoicesFields_name_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EditorChoicesFields_visible"]: AliasType<{
	create?:ValueTypes["EditorChoicesFields_visible_Create"],
	read?:ValueTypes["EditorChoicesFields_visible_Read"],
	update?:ValueTypes["EditorChoicesFields_visible_Update"],
	delete?:ValueTypes["EditorChoicesFields_visible_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EditorChoicesFields_visible_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EditorChoicesFields_visible_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EditorChoicesFields_visible_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EditorChoicesFields_visible_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EditorChoicesFields_editorialPicks"]: AliasType<{
	create?:ValueTypes["EditorChoicesFields_editorialPicks_Create"],
	read?:ValueTypes["EditorChoicesFields_editorialPicks_Read"],
	update?:ValueTypes["EditorChoicesFields_editorialPicks_Update"],
	delete?:ValueTypes["EditorChoicesFields_editorialPicks_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EditorChoicesFields_editorialPicks_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EditorChoicesFields_editorialPicks_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EditorChoicesFields_editorialPicks_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EditorChoicesFields_editorialPicks_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EditorChoicesFields_list"]: AliasType<{
	create?:ValueTypes["EditorChoicesFields_list_Create"],
	read?:ValueTypes["EditorChoicesFields_list_Read"],
	update?:ValueTypes["EditorChoicesFields_list_Update"],
	delete?:ValueTypes["EditorChoicesFields_list_Delete"],
	fields?:ValueTypes["EditorChoicesFields_list_Fields"],
		__typename?: boolean | `@${string}`
}>;
	["EditorChoicesFields_list_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EditorChoicesFields_list_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EditorChoicesFields_list_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EditorChoicesFields_list_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EditorChoicesFields_list_Fields"]: AliasType<{
	item?:ValueTypes["EditorChoicesFields_list_item"],
	id?:ValueTypes["EditorChoicesFields_list_id"],
		__typename?: boolean | `@${string}`
}>;
	["EditorChoicesFields_list_item"]: AliasType<{
	create?:ValueTypes["EditorChoicesFields_list_item_Create"],
	read?:ValueTypes["EditorChoicesFields_list_item_Read"],
	update?:ValueTypes["EditorChoicesFields_list_item_Update"],
	delete?:ValueTypes["EditorChoicesFields_list_item_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EditorChoicesFields_list_item_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EditorChoicesFields_list_item_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EditorChoicesFields_list_item_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EditorChoicesFields_list_item_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EditorChoicesFields_list_id"]: AliasType<{
	create?:ValueTypes["EditorChoicesFields_list_id_Create"],
	read?:ValueTypes["EditorChoicesFields_list_id_Read"],
	update?:ValueTypes["EditorChoicesFields_list_id_Update"],
	delete?:ValueTypes["EditorChoicesFields_list_id_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EditorChoicesFields_list_id_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EditorChoicesFields_list_id_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EditorChoicesFields_list_id_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EditorChoicesFields_list_id_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EditorChoicesFields_region"]: AliasType<{
	create?:ValueTypes["EditorChoicesFields_region_Create"],
	read?:ValueTypes["EditorChoicesFields_region_Read"],
	update?:ValueTypes["EditorChoicesFields_region_Update"],
	delete?:ValueTypes["EditorChoicesFields_region_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EditorChoicesFields_region_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EditorChoicesFields_region_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EditorChoicesFields_region_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EditorChoicesFields_region_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EditorChoicesFields_updatedAt"]: AliasType<{
	create?:ValueTypes["EditorChoicesFields_updatedAt_Create"],
	read?:ValueTypes["EditorChoicesFields_updatedAt_Read"],
	update?:ValueTypes["EditorChoicesFields_updatedAt_Update"],
	delete?:ValueTypes["EditorChoicesFields_updatedAt_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EditorChoicesFields_updatedAt_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EditorChoicesFields_updatedAt_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EditorChoicesFields_updatedAt_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EditorChoicesFields_updatedAt_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EditorChoicesFields_createdAt"]: AliasType<{
	create?:ValueTypes["EditorChoicesFields_createdAt_Create"],
	read?:ValueTypes["EditorChoicesFields_createdAt_Read"],
	update?:ValueTypes["EditorChoicesFields_createdAt_Update"],
	delete?:ValueTypes["EditorChoicesFields_createdAt_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EditorChoicesFields_createdAt_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EditorChoicesFields_createdAt_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EditorChoicesFields_createdAt_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EditorChoicesFields_createdAt_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EditorChoicesCreateAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EditorChoicesReadAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EditorChoicesUpdateAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EditorChoicesDeleteAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["criteria_settingAccess"]: AliasType<{
	fields?:ValueTypes["CriteriaSettingFields"],
	create?:ValueTypes["CriteriaSettingCreateAccess"],
	read?:ValueTypes["CriteriaSettingReadAccess"],
	update?:ValueTypes["CriteriaSettingUpdateAccess"],
	delete?:ValueTypes["CriteriaSettingDeleteAccess"],
		__typename?: boolean | `@${string}`
}>;
	["CriteriaSettingFields"]: AliasType<{
	criteria?:ValueTypes["CriteriaSettingFields_criteria"],
	updatedAt?:ValueTypes["CriteriaSettingFields_updatedAt"],
	createdAt?:ValueTypes["CriteriaSettingFields_createdAt"],
		__typename?: boolean | `@${string}`
}>;
	["CriteriaSettingFields_criteria"]: AliasType<{
	create?:ValueTypes["CriteriaSettingFields_criteria_Create"],
	read?:ValueTypes["CriteriaSettingFields_criteria_Read"],
	update?:ValueTypes["CriteriaSettingFields_criteria_Update"],
	delete?:ValueTypes["CriteriaSettingFields_criteria_Delete"],
	fields?:ValueTypes["CriteriaSettingFields_criteria_Fields"],
		__typename?: boolean | `@${string}`
}>;
	["CriteriaSettingFields_criteria_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CriteriaSettingFields_criteria_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CriteriaSettingFields_criteria_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CriteriaSettingFields_criteria_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CriteriaSettingFields_criteria_Fields"]: AliasType<{
	date?:ValueTypes["CriteriaSettingFields_criteria_date"],
	audience?:ValueTypes["CriteriaSettingFields_criteria_audience"],
	category?:ValueTypes["CriteriaSettingFields_criteria_category"],
	tag?:ValueTypes["CriteriaSettingFields_criteria_tag"],
	region?:ValueTypes["CriteriaSettingFields_criteria_region"],
		__typename?: boolean | `@${string}`
}>;
	["CriteriaSettingFields_criteria_date"]: AliasType<{
	create?:ValueTypes["CriteriaSettingFields_criteria_date_Create"],
	read?:ValueTypes["CriteriaSettingFields_criteria_date_Read"],
	update?:ValueTypes["CriteriaSettingFields_criteria_date_Update"],
	delete?:ValueTypes["CriteriaSettingFields_criteria_date_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["CriteriaSettingFields_criteria_date_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CriteriaSettingFields_criteria_date_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CriteriaSettingFields_criteria_date_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CriteriaSettingFields_criteria_date_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CriteriaSettingFields_criteria_audience"]: AliasType<{
	create?:ValueTypes["CriteriaSettingFields_criteria_audience_Create"],
	read?:ValueTypes["CriteriaSettingFields_criteria_audience_Read"],
	update?:ValueTypes["CriteriaSettingFields_criteria_audience_Update"],
	delete?:ValueTypes["CriteriaSettingFields_criteria_audience_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["CriteriaSettingFields_criteria_audience_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CriteriaSettingFields_criteria_audience_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CriteriaSettingFields_criteria_audience_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CriteriaSettingFields_criteria_audience_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CriteriaSettingFields_criteria_category"]: AliasType<{
	create?:ValueTypes["CriteriaSettingFields_criteria_category_Create"],
	read?:ValueTypes["CriteriaSettingFields_criteria_category_Read"],
	update?:ValueTypes["CriteriaSettingFields_criteria_category_Update"],
	delete?:ValueTypes["CriteriaSettingFields_criteria_category_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["CriteriaSettingFields_criteria_category_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CriteriaSettingFields_criteria_category_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CriteriaSettingFields_criteria_category_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CriteriaSettingFields_criteria_category_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CriteriaSettingFields_criteria_tag"]: AliasType<{
	create?:ValueTypes["CriteriaSettingFields_criteria_tag_Create"],
	read?:ValueTypes["CriteriaSettingFields_criteria_tag_Read"],
	update?:ValueTypes["CriteriaSettingFields_criteria_tag_Update"],
	delete?:ValueTypes["CriteriaSettingFields_criteria_tag_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["CriteriaSettingFields_criteria_tag_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CriteriaSettingFields_criteria_tag_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CriteriaSettingFields_criteria_tag_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CriteriaSettingFields_criteria_tag_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CriteriaSettingFields_criteria_region"]: AliasType<{
	create?:ValueTypes["CriteriaSettingFields_criteria_region_Create"],
	read?:ValueTypes["CriteriaSettingFields_criteria_region_Read"],
	update?:ValueTypes["CriteriaSettingFields_criteria_region_Update"],
	delete?:ValueTypes["CriteriaSettingFields_criteria_region_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["CriteriaSettingFields_criteria_region_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CriteriaSettingFields_criteria_region_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CriteriaSettingFields_criteria_region_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CriteriaSettingFields_criteria_region_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CriteriaSettingFields_updatedAt"]: AliasType<{
	create?:ValueTypes["CriteriaSettingFields_updatedAt_Create"],
	read?:ValueTypes["CriteriaSettingFields_updatedAt_Read"],
	update?:ValueTypes["CriteriaSettingFields_updatedAt_Update"],
	delete?:ValueTypes["CriteriaSettingFields_updatedAt_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["CriteriaSettingFields_updatedAt_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CriteriaSettingFields_updatedAt_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CriteriaSettingFields_updatedAt_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CriteriaSettingFields_updatedAt_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CriteriaSettingFields_createdAt"]: AliasType<{
	create?:ValueTypes["CriteriaSettingFields_createdAt_Create"],
	read?:ValueTypes["CriteriaSettingFields_createdAt_Read"],
	update?:ValueTypes["CriteriaSettingFields_createdAt_Update"],
	delete?:ValueTypes["CriteriaSettingFields_createdAt_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["CriteriaSettingFields_createdAt_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CriteriaSettingFields_createdAt_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CriteriaSettingFields_createdAt_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CriteriaSettingFields_createdAt_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CriteriaSettingCreateAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CriteriaSettingReadAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CriteriaSettingUpdateAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CriteriaSettingDeleteAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["organiserSettingAccess"]: AliasType<{
	fields?:ValueTypes["OrganiserSettingFields"],
	create?:ValueTypes["OrganiserSettingCreateAccess"],
	read?:ValueTypes["OrganiserSettingReadAccess"],
	update?:ValueTypes["OrganiserSettingUpdateAccess"],
	delete?:ValueTypes["OrganiserSettingDeleteAccess"],
		__typename?: boolean | `@${string}`
}>;
	["OrganiserSettingFields"]: AliasType<{
	name?:ValueTypes["OrganiserSettingFields_name"],
	organiserList?:ValueTypes["OrganiserSettingFields_organiserList"],
	region?:ValueTypes["OrganiserSettingFields_region"],
	updatedAt?:ValueTypes["OrganiserSettingFields_updatedAt"],
	createdAt?:ValueTypes["OrganiserSettingFields_createdAt"],
		__typename?: boolean | `@${string}`
}>;
	["OrganiserSettingFields_name"]: AliasType<{
	create?:ValueTypes["OrganiserSettingFields_name_Create"],
	read?:ValueTypes["OrganiserSettingFields_name_Read"],
	update?:ValueTypes["OrganiserSettingFields_name_Update"],
	delete?:ValueTypes["OrganiserSettingFields_name_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["OrganiserSettingFields_name_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["OrganiserSettingFields_name_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["OrganiserSettingFields_name_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["OrganiserSettingFields_name_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["OrganiserSettingFields_organiserList"]: AliasType<{
	create?:ValueTypes["OrganiserSettingFields_organiserList_Create"],
	read?:ValueTypes["OrganiserSettingFields_organiserList_Read"],
	update?:ValueTypes["OrganiserSettingFields_organiserList_Update"],
	delete?:ValueTypes["OrganiserSettingFields_organiserList_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["OrganiserSettingFields_organiserList_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["OrganiserSettingFields_organiserList_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["OrganiserSettingFields_organiserList_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["OrganiserSettingFields_organiserList_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["OrganiserSettingFields_region"]: AliasType<{
	create?:ValueTypes["OrganiserSettingFields_region_Create"],
	read?:ValueTypes["OrganiserSettingFields_region_Read"],
	update?:ValueTypes["OrganiserSettingFields_region_Update"],
	delete?:ValueTypes["OrganiserSettingFields_region_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["OrganiserSettingFields_region_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["OrganiserSettingFields_region_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["OrganiserSettingFields_region_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["OrganiserSettingFields_region_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["OrganiserSettingFields_updatedAt"]: AliasType<{
	create?:ValueTypes["OrganiserSettingFields_updatedAt_Create"],
	read?:ValueTypes["OrganiserSettingFields_updatedAt_Read"],
	update?:ValueTypes["OrganiserSettingFields_updatedAt_Update"],
	delete?:ValueTypes["OrganiserSettingFields_updatedAt_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["OrganiserSettingFields_updatedAt_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["OrganiserSettingFields_updatedAt_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["OrganiserSettingFields_updatedAt_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["OrganiserSettingFields_updatedAt_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["OrganiserSettingFields_createdAt"]: AliasType<{
	create?:ValueTypes["OrganiserSettingFields_createdAt_Create"],
	read?:ValueTypes["OrganiserSettingFields_createdAt_Read"],
	update?:ValueTypes["OrganiserSettingFields_createdAt_Update"],
	delete?:ValueTypes["OrganiserSettingFields_createdAt_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["OrganiserSettingFields_createdAt_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["OrganiserSettingFields_createdAt_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["OrganiserSettingFields_createdAt_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["OrganiserSettingFields_createdAt_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["OrganiserSettingCreateAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["OrganiserSettingReadAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["OrganiserSettingUpdateAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["OrganiserSettingDeleteAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["post_settingAccess"]: AliasType<{
	fields?:ValueTypes["PostSettingFields"],
	create?:ValueTypes["PostSettingCreateAccess"],
	read?:ValueTypes["PostSettingReadAccess"],
	update?:ValueTypes["PostSettingUpdateAccess"],
	delete?:ValueTypes["PostSettingDeleteAccess"],
		__typename?: boolean | `@${string}`
}>;
	["PostSettingFields"]: AliasType<{
	name?:ValueTypes["PostSettingFields_name"],
	featuredPosts?:ValueTypes["PostSettingFields_featuredPosts"],
	region?:ValueTypes["PostSettingFields_region"],
	updatedAt?:ValueTypes["PostSettingFields_updatedAt"],
	createdAt?:ValueTypes["PostSettingFields_createdAt"],
		__typename?: boolean | `@${string}`
}>;
	["PostSettingFields_name"]: AliasType<{
	create?:ValueTypes["PostSettingFields_name_Create"],
	read?:ValueTypes["PostSettingFields_name_Read"],
	update?:ValueTypes["PostSettingFields_name_Update"],
	delete?:ValueTypes["PostSettingFields_name_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["PostSettingFields_name_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostSettingFields_name_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostSettingFields_name_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostSettingFields_name_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostSettingFields_featuredPosts"]: AliasType<{
	create?:ValueTypes["PostSettingFields_featuredPosts_Create"],
	read?:ValueTypes["PostSettingFields_featuredPosts_Read"],
	update?:ValueTypes["PostSettingFields_featuredPosts_Update"],
	delete?:ValueTypes["PostSettingFields_featuredPosts_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["PostSettingFields_featuredPosts_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostSettingFields_featuredPosts_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostSettingFields_featuredPosts_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostSettingFields_featuredPosts_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostSettingFields_region"]: AliasType<{
	create?:ValueTypes["PostSettingFields_region_Create"],
	read?:ValueTypes["PostSettingFields_region_Read"],
	update?:ValueTypes["PostSettingFields_region_Update"],
	delete?:ValueTypes["PostSettingFields_region_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["PostSettingFields_region_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostSettingFields_region_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostSettingFields_region_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostSettingFields_region_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostSettingFields_updatedAt"]: AliasType<{
	create?:ValueTypes["PostSettingFields_updatedAt_Create"],
	read?:ValueTypes["PostSettingFields_updatedAt_Read"],
	update?:ValueTypes["PostSettingFields_updatedAt_Update"],
	delete?:ValueTypes["PostSettingFields_updatedAt_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["PostSettingFields_updatedAt_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostSettingFields_updatedAt_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostSettingFields_updatedAt_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostSettingFields_updatedAt_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostSettingFields_createdAt"]: AliasType<{
	create?:ValueTypes["PostSettingFields_createdAt_Create"],
	read?:ValueTypes["PostSettingFields_createdAt_Read"],
	update?:ValueTypes["PostSettingFields_createdAt_Update"],
	delete?:ValueTypes["PostSettingFields_createdAt_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["PostSettingFields_createdAt_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostSettingFields_createdAt_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostSettingFields_createdAt_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostSettingFields_createdAt_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostSettingCreateAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostSettingReadAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostSettingUpdateAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostSettingDeleteAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["homepageSettingAccess"]: AliasType<{
	fields?:ValueTypes["HomepageSettingFields"],
	create?:ValueTypes["HomepageSettingCreateAccess"],
	read?:ValueTypes["HomepageSettingReadAccess"],
	update?:ValueTypes["HomepageSettingUpdateAccess"],
	delete?:ValueTypes["HomepageSettingDeleteAccess"],
		__typename?: boolean | `@${string}`
}>;
	["HomepageSettingFields"]: AliasType<{
	name?:ValueTypes["HomepageSettingFields_name"],
	region?:ValueTypes["HomepageSettingFields_region"],
	sections?:ValueTypes["HomepageSettingFields_sections"],
	updatedAt?:ValueTypes["HomepageSettingFields_updatedAt"],
	createdAt?:ValueTypes["HomepageSettingFields_createdAt"],
		__typename?: boolean | `@${string}`
}>;
	["HomepageSettingFields_name"]: AliasType<{
	create?:ValueTypes["HomepageSettingFields_name_Create"],
	read?:ValueTypes["HomepageSettingFields_name_Read"],
	update?:ValueTypes["HomepageSettingFields_name_Update"],
	delete?:ValueTypes["HomepageSettingFields_name_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["HomepageSettingFields_name_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["HomepageSettingFields_name_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["HomepageSettingFields_name_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["HomepageSettingFields_name_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["HomepageSettingFields_region"]: AliasType<{
	create?:ValueTypes["HomepageSettingFields_region_Create"],
	read?:ValueTypes["HomepageSettingFields_region_Read"],
	update?:ValueTypes["HomepageSettingFields_region_Update"],
	delete?:ValueTypes["HomepageSettingFields_region_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["HomepageSettingFields_region_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["HomepageSettingFields_region_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["HomepageSettingFields_region_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["HomepageSettingFields_region_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["HomepageSettingFields_sections"]: AliasType<{
	create?:ValueTypes["HomepageSettingFields_sections_Create"],
	read?:ValueTypes["HomepageSettingFields_sections_Read"],
	update?:ValueTypes["HomepageSettingFields_sections_Update"],
	delete?:ValueTypes["HomepageSettingFields_sections_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["HomepageSettingFields_sections_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["HomepageSettingFields_sections_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["HomepageSettingFields_sections_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["HomepageSettingFields_sections_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["HomepageSettingFields_updatedAt"]: AliasType<{
	create?:ValueTypes["HomepageSettingFields_updatedAt_Create"],
	read?:ValueTypes["HomepageSettingFields_updatedAt_Read"],
	update?:ValueTypes["HomepageSettingFields_updatedAt_Update"],
	delete?:ValueTypes["HomepageSettingFields_updatedAt_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["HomepageSettingFields_updatedAt_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["HomepageSettingFields_updatedAt_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["HomepageSettingFields_updatedAt_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["HomepageSettingFields_updatedAt_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["HomepageSettingFields_createdAt"]: AliasType<{
	create?:ValueTypes["HomepageSettingFields_createdAt_Create"],
	read?:ValueTypes["HomepageSettingFields_createdAt_Read"],
	update?:ValueTypes["HomepageSettingFields_createdAt_Update"],
	delete?:ValueTypes["HomepageSettingFields_createdAt_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["HomepageSettingFields_createdAt_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["HomepageSettingFields_createdAt_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["HomepageSettingFields_createdAt_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["HomepageSettingFields_createdAt_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["HomepageSettingCreateAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["HomepageSettingReadAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["HomepageSettingUpdateAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["HomepageSettingDeleteAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["banner_sectionAccess"]: AliasType<{
	fields?:ValueTypes["BannerSectionFields"],
	create?:ValueTypes["BannerSectionCreateAccess"],
	read?:ValueTypes["BannerSectionReadAccess"],
	update?:ValueTypes["BannerSectionUpdateAccess"],
	delete?:ValueTypes["BannerSectionDeleteAccess"],
		__typename?: boolean | `@${string}`
}>;
	["BannerSectionFields"]: AliasType<{
	name?:ValueTypes["BannerSectionFields_name"],
	slug?:ValueTypes["BannerSectionFields_slug"],
	banners?:ValueTypes["BannerSectionFields_banners"],
	updatedAt?:ValueTypes["BannerSectionFields_updatedAt"],
	createdAt?:ValueTypes["BannerSectionFields_createdAt"],
		__typename?: boolean | `@${string}`
}>;
	["BannerSectionFields_name"]: AliasType<{
	create?:ValueTypes["BannerSectionFields_name_Create"],
	read?:ValueTypes["BannerSectionFields_name_Read"],
	update?:ValueTypes["BannerSectionFields_name_Update"],
	delete?:ValueTypes["BannerSectionFields_name_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["BannerSectionFields_name_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannerSectionFields_name_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannerSectionFields_name_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannerSectionFields_name_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannerSectionFields_slug"]: AliasType<{
	create?:ValueTypes["BannerSectionFields_slug_Create"],
	read?:ValueTypes["BannerSectionFields_slug_Read"],
	update?:ValueTypes["BannerSectionFields_slug_Update"],
	delete?:ValueTypes["BannerSectionFields_slug_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["BannerSectionFields_slug_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannerSectionFields_slug_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannerSectionFields_slug_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannerSectionFields_slug_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannerSectionFields_banners"]: AliasType<{
	create?:ValueTypes["BannerSectionFields_banners_Create"],
	read?:ValueTypes["BannerSectionFields_banners_Read"],
	update?:ValueTypes["BannerSectionFields_banners_Update"],
	delete?:ValueTypes["BannerSectionFields_banners_Delete"],
	fields?:ValueTypes["BannerSectionFields_banners_Fields"],
		__typename?: boolean | `@${string}`
}>;
	["BannerSectionFields_banners_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannerSectionFields_banners_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannerSectionFields_banners_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannerSectionFields_banners_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannerSectionFields_banners_Fields"]: AliasType<{
	name?:ValueTypes["BannerSectionFields_banners_name"],
	status?:ValueTypes["BannerSectionFields_banners_status"],
	desktop?:ValueTypes["BannerSectionFields_banners_desktop"],
	bannerStartDate?:ValueTypes["BannerSectionFields_banners_bannerStartDate"],
	bannerEndDate?:ValueTypes["BannerSectionFields_banners_bannerEndDate"],
	region?:ValueTypes["BannerSectionFields_banners_region"],
	id?:ValueTypes["BannerSectionFields_banners_id"],
		__typename?: boolean | `@${string}`
}>;
	["BannerSectionFields_banners_name"]: AliasType<{
	create?:ValueTypes["BannerSectionFields_banners_name_Create"],
	read?:ValueTypes["BannerSectionFields_banners_name_Read"],
	update?:ValueTypes["BannerSectionFields_banners_name_Update"],
	delete?:ValueTypes["BannerSectionFields_banners_name_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["BannerSectionFields_banners_name_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannerSectionFields_banners_name_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannerSectionFields_banners_name_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannerSectionFields_banners_name_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannerSectionFields_banners_status"]: AliasType<{
	create?:ValueTypes["BannerSectionFields_banners_status_Create"],
	read?:ValueTypes["BannerSectionFields_banners_status_Read"],
	update?:ValueTypes["BannerSectionFields_banners_status_Update"],
	delete?:ValueTypes["BannerSectionFields_banners_status_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["BannerSectionFields_banners_status_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannerSectionFields_banners_status_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannerSectionFields_banners_status_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannerSectionFields_banners_status_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannerSectionFields_banners_desktop"]: AliasType<{
	create?:ValueTypes["BannerSectionFields_banners_desktop_Create"],
	read?:ValueTypes["BannerSectionFields_banners_desktop_Read"],
	update?:ValueTypes["BannerSectionFields_banners_desktop_Update"],
	delete?:ValueTypes["BannerSectionFields_banners_desktop_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["BannerSectionFields_banners_desktop_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannerSectionFields_banners_desktop_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannerSectionFields_banners_desktop_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannerSectionFields_banners_desktop_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannerSectionFields_banners_bannerStartDate"]: AliasType<{
	create?:ValueTypes["BannerSectionFields_banners_bannerStartDate_Create"],
	read?:ValueTypes["BannerSectionFields_banners_bannerStartDate_Read"],
	update?:ValueTypes["BannerSectionFields_banners_bannerStartDate_Update"],
	delete?:ValueTypes["BannerSectionFields_banners_bannerStartDate_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["BannerSectionFields_banners_bannerStartDate_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannerSectionFields_banners_bannerStartDate_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannerSectionFields_banners_bannerStartDate_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannerSectionFields_banners_bannerStartDate_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannerSectionFields_banners_bannerEndDate"]: AliasType<{
	create?:ValueTypes["BannerSectionFields_banners_bannerEndDate_Create"],
	read?:ValueTypes["BannerSectionFields_banners_bannerEndDate_Read"],
	update?:ValueTypes["BannerSectionFields_banners_bannerEndDate_Update"],
	delete?:ValueTypes["BannerSectionFields_banners_bannerEndDate_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["BannerSectionFields_banners_bannerEndDate_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannerSectionFields_banners_bannerEndDate_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannerSectionFields_banners_bannerEndDate_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannerSectionFields_banners_bannerEndDate_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannerSectionFields_banners_region"]: AliasType<{
	create?:ValueTypes["BannerSectionFields_banners_region_Create"],
	read?:ValueTypes["BannerSectionFields_banners_region_Read"],
	update?:ValueTypes["BannerSectionFields_banners_region_Update"],
	delete?:ValueTypes["BannerSectionFields_banners_region_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["BannerSectionFields_banners_region_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannerSectionFields_banners_region_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannerSectionFields_banners_region_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannerSectionFields_banners_region_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannerSectionFields_banners_id"]: AliasType<{
	create?:ValueTypes["BannerSectionFields_banners_id_Create"],
	read?:ValueTypes["BannerSectionFields_banners_id_Read"],
	update?:ValueTypes["BannerSectionFields_banners_id_Update"],
	delete?:ValueTypes["BannerSectionFields_banners_id_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["BannerSectionFields_banners_id_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannerSectionFields_banners_id_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannerSectionFields_banners_id_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannerSectionFields_banners_id_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannerSectionFields_updatedAt"]: AliasType<{
	create?:ValueTypes["BannerSectionFields_updatedAt_Create"],
	read?:ValueTypes["BannerSectionFields_updatedAt_Read"],
	update?:ValueTypes["BannerSectionFields_updatedAt_Update"],
	delete?:ValueTypes["BannerSectionFields_updatedAt_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["BannerSectionFields_updatedAt_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannerSectionFields_updatedAt_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannerSectionFields_updatedAt_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannerSectionFields_updatedAt_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannerSectionFields_createdAt"]: AliasType<{
	create?:ValueTypes["BannerSectionFields_createdAt_Create"],
	read?:ValueTypes["BannerSectionFields_createdAt_Read"],
	update?:ValueTypes["BannerSectionFields_createdAt_Update"],
	delete?:ValueTypes["BannerSectionFields_createdAt_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["BannerSectionFields_createdAt_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannerSectionFields_createdAt_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannerSectionFields_createdAt_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannerSectionFields_createdAt_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannerSectionCreateAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannerSectionReadAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannerSectionUpdateAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannerSectionDeleteAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["macao_banner_sectionAccess"]: AliasType<{
	fields?:ValueTypes["MacaoBannerSectionFields"],
	create?:ValueTypes["MacaoBannerSectionCreateAccess"],
	read?:ValueTypes["MacaoBannerSectionReadAccess"],
	update?:ValueTypes["MacaoBannerSectionUpdateAccess"],
	delete?:ValueTypes["MacaoBannerSectionDeleteAccess"],
		__typename?: boolean | `@${string}`
}>;
	["MacaoBannerSectionFields"]: AliasType<{
	name?:ValueTypes["MacaoBannerSectionFields_name"],
	slug?:ValueTypes["MacaoBannerSectionFields_slug"],
	banners?:ValueTypes["MacaoBannerSectionFields_banners"],
	updatedAt?:ValueTypes["MacaoBannerSectionFields_updatedAt"],
	createdAt?:ValueTypes["MacaoBannerSectionFields_createdAt"],
		__typename?: boolean | `@${string}`
}>;
	["MacaoBannerSectionFields_name"]: AliasType<{
	create?:ValueTypes["MacaoBannerSectionFields_name_Create"],
	read?:ValueTypes["MacaoBannerSectionFields_name_Read"],
	update?:ValueTypes["MacaoBannerSectionFields_name_Update"],
	delete?:ValueTypes["MacaoBannerSectionFields_name_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["MacaoBannerSectionFields_name_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MacaoBannerSectionFields_name_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MacaoBannerSectionFields_name_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MacaoBannerSectionFields_name_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MacaoBannerSectionFields_slug"]: AliasType<{
	create?:ValueTypes["MacaoBannerSectionFields_slug_Create"],
	read?:ValueTypes["MacaoBannerSectionFields_slug_Read"],
	update?:ValueTypes["MacaoBannerSectionFields_slug_Update"],
	delete?:ValueTypes["MacaoBannerSectionFields_slug_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["MacaoBannerSectionFields_slug_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MacaoBannerSectionFields_slug_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MacaoBannerSectionFields_slug_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MacaoBannerSectionFields_slug_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MacaoBannerSectionFields_banners"]: AliasType<{
	create?:ValueTypes["MacaoBannerSectionFields_banners_Create"],
	read?:ValueTypes["MacaoBannerSectionFields_banners_Read"],
	update?:ValueTypes["MacaoBannerSectionFields_banners_Update"],
	delete?:ValueTypes["MacaoBannerSectionFields_banners_Delete"],
	fields?:ValueTypes["MacaoBannerSectionFields_banners_Fields"],
		__typename?: boolean | `@${string}`
}>;
	["MacaoBannerSectionFields_banners_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MacaoBannerSectionFields_banners_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MacaoBannerSectionFields_banners_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MacaoBannerSectionFields_banners_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MacaoBannerSectionFields_banners_Fields"]: AliasType<{
	name?:ValueTypes["MacaoBannerSectionFields_banners_name"],
	status?:ValueTypes["MacaoBannerSectionFields_banners_status"],
	desktop?:ValueTypes["MacaoBannerSectionFields_banners_desktop"],
	bannerStartDate?:ValueTypes["MacaoBannerSectionFields_banners_bannerStartDate"],
	bannerEndDate?:ValueTypes["MacaoBannerSectionFields_banners_bannerEndDate"],
	region?:ValueTypes["MacaoBannerSectionFields_banners_region"],
	id?:ValueTypes["MacaoBannerSectionFields_banners_id"],
		__typename?: boolean | `@${string}`
}>;
	["MacaoBannerSectionFields_banners_name"]: AliasType<{
	create?:ValueTypes["MacaoBannerSectionFields_banners_name_Create"],
	read?:ValueTypes["MacaoBannerSectionFields_banners_name_Read"],
	update?:ValueTypes["MacaoBannerSectionFields_banners_name_Update"],
	delete?:ValueTypes["MacaoBannerSectionFields_banners_name_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["MacaoBannerSectionFields_banners_name_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MacaoBannerSectionFields_banners_name_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MacaoBannerSectionFields_banners_name_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MacaoBannerSectionFields_banners_name_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MacaoBannerSectionFields_banners_status"]: AliasType<{
	create?:ValueTypes["MacaoBannerSectionFields_banners_status_Create"],
	read?:ValueTypes["MacaoBannerSectionFields_banners_status_Read"],
	update?:ValueTypes["MacaoBannerSectionFields_banners_status_Update"],
	delete?:ValueTypes["MacaoBannerSectionFields_banners_status_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["MacaoBannerSectionFields_banners_status_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MacaoBannerSectionFields_banners_status_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MacaoBannerSectionFields_banners_status_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MacaoBannerSectionFields_banners_status_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MacaoBannerSectionFields_banners_desktop"]: AliasType<{
	create?:ValueTypes["MacaoBannerSectionFields_banners_desktop_Create"],
	read?:ValueTypes["MacaoBannerSectionFields_banners_desktop_Read"],
	update?:ValueTypes["MacaoBannerSectionFields_banners_desktop_Update"],
	delete?:ValueTypes["MacaoBannerSectionFields_banners_desktop_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["MacaoBannerSectionFields_banners_desktop_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MacaoBannerSectionFields_banners_desktop_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MacaoBannerSectionFields_banners_desktop_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MacaoBannerSectionFields_banners_desktop_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MacaoBannerSectionFields_banners_bannerStartDate"]: AliasType<{
	create?:ValueTypes["MacaoBannerSectionFields_banners_bannerStartDate_Create"],
	read?:ValueTypes["MacaoBannerSectionFields_banners_bannerStartDate_Read"],
	update?:ValueTypes["MacaoBannerSectionFields_banners_bannerStartDate_Update"],
	delete?:ValueTypes["MacaoBannerSectionFields_banners_bannerStartDate_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["MacaoBannerSectionFields_banners_bannerStartDate_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MacaoBannerSectionFields_banners_bannerStartDate_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MacaoBannerSectionFields_banners_bannerStartDate_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MacaoBannerSectionFields_banners_bannerStartDate_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MacaoBannerSectionFields_banners_bannerEndDate"]: AliasType<{
	create?:ValueTypes["MacaoBannerSectionFields_banners_bannerEndDate_Create"],
	read?:ValueTypes["MacaoBannerSectionFields_banners_bannerEndDate_Read"],
	update?:ValueTypes["MacaoBannerSectionFields_banners_bannerEndDate_Update"],
	delete?:ValueTypes["MacaoBannerSectionFields_banners_bannerEndDate_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["MacaoBannerSectionFields_banners_bannerEndDate_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MacaoBannerSectionFields_banners_bannerEndDate_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MacaoBannerSectionFields_banners_bannerEndDate_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MacaoBannerSectionFields_banners_bannerEndDate_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MacaoBannerSectionFields_banners_region"]: AliasType<{
	create?:ValueTypes["MacaoBannerSectionFields_banners_region_Create"],
	read?:ValueTypes["MacaoBannerSectionFields_banners_region_Read"],
	update?:ValueTypes["MacaoBannerSectionFields_banners_region_Update"],
	delete?:ValueTypes["MacaoBannerSectionFields_banners_region_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["MacaoBannerSectionFields_banners_region_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MacaoBannerSectionFields_banners_region_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MacaoBannerSectionFields_banners_region_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MacaoBannerSectionFields_banners_region_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MacaoBannerSectionFields_banners_id"]: AliasType<{
	create?:ValueTypes["MacaoBannerSectionFields_banners_id_Create"],
	read?:ValueTypes["MacaoBannerSectionFields_banners_id_Read"],
	update?:ValueTypes["MacaoBannerSectionFields_banners_id_Update"],
	delete?:ValueTypes["MacaoBannerSectionFields_banners_id_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["MacaoBannerSectionFields_banners_id_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MacaoBannerSectionFields_banners_id_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MacaoBannerSectionFields_banners_id_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MacaoBannerSectionFields_banners_id_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MacaoBannerSectionFields_updatedAt"]: AliasType<{
	create?:ValueTypes["MacaoBannerSectionFields_updatedAt_Create"],
	read?:ValueTypes["MacaoBannerSectionFields_updatedAt_Read"],
	update?:ValueTypes["MacaoBannerSectionFields_updatedAt_Update"],
	delete?:ValueTypes["MacaoBannerSectionFields_updatedAt_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["MacaoBannerSectionFields_updatedAt_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MacaoBannerSectionFields_updatedAt_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MacaoBannerSectionFields_updatedAt_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MacaoBannerSectionFields_updatedAt_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MacaoBannerSectionFields_createdAt"]: AliasType<{
	create?:ValueTypes["MacaoBannerSectionFields_createdAt_Create"],
	read?:ValueTypes["MacaoBannerSectionFields_createdAt_Read"],
	update?:ValueTypes["MacaoBannerSectionFields_createdAt_Update"],
	delete?:ValueTypes["MacaoBannerSectionFields_createdAt_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["MacaoBannerSectionFields_createdAt_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MacaoBannerSectionFields_createdAt_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MacaoBannerSectionFields_createdAt_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MacaoBannerSectionFields_createdAt_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MacaoBannerSectionCreateAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MacaoBannerSectionReadAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MacaoBannerSectionUpdateAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MacaoBannerSectionDeleteAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["bannersAccess"]: AliasType<{
	fields?:ValueTypes["BannersFields"],
	create?:ValueTypes["BannersCreateAccess"],
	read?:ValueTypes["BannersReadAccess"],
	update?:ValueTypes["BannersUpdateAccess"],
	delete?:ValueTypes["BannersDeleteAccess"],
		__typename?: boolean | `@${string}`
}>;
	["BannersFields"]: AliasType<{
	name?:ValueTypes["BannersFields_name"],
	desktop?:ValueTypes["BannersFields_desktop"],
	mobile?:ValueTypes["BannersFields_mobile"],
	bannerStartDate?:ValueTypes["BannersFields_bannerStartDate"],
	bannerEndDate?:ValueTypes["BannersFields_bannerEndDate"],
	location?:ValueTypes["BannersFields_location"],
	link?:ValueTypes["BannersFields_link"],
	external?:ValueTypes["BannersFields_external"],
	type?:ValueTypes["BannersFields_type"],
	status?:ValueTypes["BannersFields_status"],
	advertise?:ValueTypes["BannersFields_advertise"],
	_locations?:ValueTypes["BannersFields__locations"],
	region?:ValueTypes["BannersFields_region"],
	homePageClick?:ValueTypes["BannersFields_homePageClick"],
	homePageImpression?:ValueTypes["BannersFields_homePageImpression"],
	latestEventClick?:ValueTypes["BannersFields_latestEventClick"],
	latestEventImpression?:ValueTypes["BannersFields_latestEventImpression"],
	editorChoiceClick?:ValueTypes["BannersFields_editorChoiceClick"],
	editorChoiceImpression?:ValueTypes["BannersFields_editorChoiceImpression"],
	eventListClick?:ValueTypes["BannersFields_eventListClick"],
	eventListImpression?:ValueTypes["BannersFields_eventListImpression"],
	topTenClick?:ValueTypes["BannersFields_topTenClick"],
	topTenImpression?:ValueTypes["BannersFields_topTenImpression"],
	miscClick?:ValueTypes["BannersFields_miscClick"],
	miscImpression?:ValueTypes["BannersFields_miscImpression"],
	clickTotal?:ValueTypes["BannersFields_clickTotal"],
	totalImpression?:ValueTypes["BannersFields_totalImpression"],
	updatedAt?:ValueTypes["BannersFields_updatedAt"],
	createdAt?:ValueTypes["BannersFields_createdAt"],
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_name"]: AliasType<{
	create?:ValueTypes["BannersFields_name_Create"],
	read?:ValueTypes["BannersFields_name_Read"],
	update?:ValueTypes["BannersFields_name_Update"],
	delete?:ValueTypes["BannersFields_name_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_name_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_name_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_name_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_name_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_desktop"]: AliasType<{
	create?:ValueTypes["BannersFields_desktop_Create"],
	read?:ValueTypes["BannersFields_desktop_Read"],
	update?:ValueTypes["BannersFields_desktop_Update"],
	delete?:ValueTypes["BannersFields_desktop_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_desktop_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_desktop_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_desktop_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_desktop_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_mobile"]: AliasType<{
	create?:ValueTypes["BannersFields_mobile_Create"],
	read?:ValueTypes["BannersFields_mobile_Read"],
	update?:ValueTypes["BannersFields_mobile_Update"],
	delete?:ValueTypes["BannersFields_mobile_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_mobile_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_mobile_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_mobile_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_mobile_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_bannerStartDate"]: AliasType<{
	create?:ValueTypes["BannersFields_bannerStartDate_Create"],
	read?:ValueTypes["BannersFields_bannerStartDate_Read"],
	update?:ValueTypes["BannersFields_bannerStartDate_Update"],
	delete?:ValueTypes["BannersFields_bannerStartDate_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_bannerStartDate_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_bannerStartDate_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_bannerStartDate_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_bannerStartDate_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_bannerEndDate"]: AliasType<{
	create?:ValueTypes["BannersFields_bannerEndDate_Create"],
	read?:ValueTypes["BannersFields_bannerEndDate_Read"],
	update?:ValueTypes["BannersFields_bannerEndDate_Update"],
	delete?:ValueTypes["BannersFields_bannerEndDate_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_bannerEndDate_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_bannerEndDate_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_bannerEndDate_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_bannerEndDate_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_location"]: AliasType<{
	create?:ValueTypes["BannersFields_location_Create"],
	read?:ValueTypes["BannersFields_location_Read"],
	update?:ValueTypes["BannersFields_location_Update"],
	delete?:ValueTypes["BannersFields_location_Delete"],
	fields?:ValueTypes["BannersFields_location_Fields"],
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_location_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_location_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_location_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_location_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_location_Fields"]: AliasType<{
	page?:ValueTypes["BannersFields_location_page"],
	eventRankingList?:ValueTypes["BannersFields_location_eventRankingList"],
	locationRankingList?:ValueTypes["BannersFields_location_locationRankingList"],
	categories?:ValueTypes["BannersFields_location_categories"],
	time?:ValueTypes["BannersFields_location_time"],
	district?:ValueTypes["BannersFields_location_district"],
	audience?:ValueTypes["BannersFields_location_audience"],
	multipleCriteriaSearch?:ValueTypes["BannersFields_location_multipleCriteriaSearch"],
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_location_page"]: AliasType<{
	create?:ValueTypes["BannersFields_location_page_Create"],
	read?:ValueTypes["BannersFields_location_page_Read"],
	update?:ValueTypes["BannersFields_location_page_Update"],
	delete?:ValueTypes["BannersFields_location_page_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_location_page_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_location_page_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_location_page_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_location_page_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_location_eventRankingList"]: AliasType<{
	create?:ValueTypes["BannersFields_location_eventRankingList_Create"],
	read?:ValueTypes["BannersFields_location_eventRankingList_Read"],
	update?:ValueTypes["BannersFields_location_eventRankingList_Update"],
	delete?:ValueTypes["BannersFields_location_eventRankingList_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_location_eventRankingList_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_location_eventRankingList_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_location_eventRankingList_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_location_eventRankingList_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_location_locationRankingList"]: AliasType<{
	create?:ValueTypes["BannersFields_location_locationRankingList_Create"],
	read?:ValueTypes["BannersFields_location_locationRankingList_Read"],
	update?:ValueTypes["BannersFields_location_locationRankingList_Update"],
	delete?:ValueTypes["BannersFields_location_locationRankingList_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_location_locationRankingList_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_location_locationRankingList_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_location_locationRankingList_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_location_locationRankingList_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_location_categories"]: AliasType<{
	create?:ValueTypes["BannersFields_location_categories_Create"],
	read?:ValueTypes["BannersFields_location_categories_Read"],
	update?:ValueTypes["BannersFields_location_categories_Update"],
	delete?:ValueTypes["BannersFields_location_categories_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_location_categories_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_location_categories_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_location_categories_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_location_categories_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_location_time"]: AliasType<{
	create?:ValueTypes["BannersFields_location_time_Create"],
	read?:ValueTypes["BannersFields_location_time_Read"],
	update?:ValueTypes["BannersFields_location_time_Update"],
	delete?:ValueTypes["BannersFields_location_time_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_location_time_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_location_time_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_location_time_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_location_time_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_location_district"]: AliasType<{
	create?:ValueTypes["BannersFields_location_district_Create"],
	read?:ValueTypes["BannersFields_location_district_Read"],
	update?:ValueTypes["BannersFields_location_district_Update"],
	delete?:ValueTypes["BannersFields_location_district_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_location_district_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_location_district_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_location_district_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_location_district_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_location_audience"]: AliasType<{
	create?:ValueTypes["BannersFields_location_audience_Create"],
	read?:ValueTypes["BannersFields_location_audience_Read"],
	update?:ValueTypes["BannersFields_location_audience_Update"],
	delete?:ValueTypes["BannersFields_location_audience_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_location_audience_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_location_audience_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_location_audience_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_location_audience_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_location_multipleCriteriaSearch"]: AliasType<{
	create?:ValueTypes["BannersFields_location_multipleCriteriaSearch_Create"],
	read?:ValueTypes["BannersFields_location_multipleCriteriaSearch_Read"],
	update?:ValueTypes["BannersFields_location_multipleCriteriaSearch_Update"],
	delete?:ValueTypes["BannersFields_location_multipleCriteriaSearch_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_location_multipleCriteriaSearch_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_location_multipleCriteriaSearch_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_location_multipleCriteriaSearch_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_location_multipleCriteriaSearch_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_link"]: AliasType<{
	create?:ValueTypes["BannersFields_link_Create"],
	read?:ValueTypes["BannersFields_link_Read"],
	update?:ValueTypes["BannersFields_link_Update"],
	delete?:ValueTypes["BannersFields_link_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_link_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_link_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_link_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_link_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_external"]: AliasType<{
	create?:ValueTypes["BannersFields_external_Create"],
	read?:ValueTypes["BannersFields_external_Read"],
	update?:ValueTypes["BannersFields_external_Update"],
	delete?:ValueTypes["BannersFields_external_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_external_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_external_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_external_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_external_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_type"]: AliasType<{
	create?:ValueTypes["BannersFields_type_Create"],
	read?:ValueTypes["BannersFields_type_Read"],
	update?:ValueTypes["BannersFields_type_Update"],
	delete?:ValueTypes["BannersFields_type_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_type_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_type_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_type_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_type_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_status"]: AliasType<{
	create?:ValueTypes["BannersFields_status_Create"],
	read?:ValueTypes["BannersFields_status_Read"],
	update?:ValueTypes["BannersFields_status_Update"],
	delete?:ValueTypes["BannersFields_status_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_status_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_status_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_status_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_status_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_advertise"]: AliasType<{
	create?:ValueTypes["BannersFields_advertise_Create"],
	read?:ValueTypes["BannersFields_advertise_Read"],
	update?:ValueTypes["BannersFields_advertise_Update"],
	delete?:ValueTypes["BannersFields_advertise_Delete"],
	fields?:ValueTypes["BannersFields_advertise_Fields"],
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_advertise_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_advertise_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_advertise_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_advertise_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_advertise_Fields"]: AliasType<{
	campaign?:ValueTypes["BannersFields_advertise_campaign"],
	invoiceDescription?:ValueTypes["BannersFields_advertise_invoiceDescription"],
	standardPrice?:ValueTypes["BannersFields_advertise_standardPrice"],
	discount?:ValueTypes["BannersFields_advertise_discount"],
	timableCost?:ValueTypes["BannersFields_advertise_timableCost"],
	remark?:ValueTypes["BannersFields_advertise_remark"],
	public?:ValueTypes["BannersFields_advertise_public"],
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_advertise_campaign"]: AliasType<{
	create?:ValueTypes["BannersFields_advertise_campaign_Create"],
	read?:ValueTypes["BannersFields_advertise_campaign_Read"],
	update?:ValueTypes["BannersFields_advertise_campaign_Update"],
	delete?:ValueTypes["BannersFields_advertise_campaign_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_advertise_campaign_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_advertise_campaign_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_advertise_campaign_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_advertise_campaign_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_advertise_invoiceDescription"]: AliasType<{
	create?:ValueTypes["BannersFields_advertise_invoiceDescription_Create"],
	read?:ValueTypes["BannersFields_advertise_invoiceDescription_Read"],
	update?:ValueTypes["BannersFields_advertise_invoiceDescription_Update"],
	delete?:ValueTypes["BannersFields_advertise_invoiceDescription_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_advertise_invoiceDescription_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_advertise_invoiceDescription_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_advertise_invoiceDescription_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_advertise_invoiceDescription_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_advertise_standardPrice"]: AliasType<{
	create?:ValueTypes["BannersFields_advertise_standardPrice_Create"],
	read?:ValueTypes["BannersFields_advertise_standardPrice_Read"],
	update?:ValueTypes["BannersFields_advertise_standardPrice_Update"],
	delete?:ValueTypes["BannersFields_advertise_standardPrice_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_advertise_standardPrice_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_advertise_standardPrice_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_advertise_standardPrice_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_advertise_standardPrice_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_advertise_discount"]: AliasType<{
	create?:ValueTypes["BannersFields_advertise_discount_Create"],
	read?:ValueTypes["BannersFields_advertise_discount_Read"],
	update?:ValueTypes["BannersFields_advertise_discount_Update"],
	delete?:ValueTypes["BannersFields_advertise_discount_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_advertise_discount_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_advertise_discount_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_advertise_discount_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_advertise_discount_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_advertise_timableCost"]: AliasType<{
	create?:ValueTypes["BannersFields_advertise_timableCost_Create"],
	read?:ValueTypes["BannersFields_advertise_timableCost_Read"],
	update?:ValueTypes["BannersFields_advertise_timableCost_Update"],
	delete?:ValueTypes["BannersFields_advertise_timableCost_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_advertise_timableCost_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_advertise_timableCost_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_advertise_timableCost_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_advertise_timableCost_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_advertise_remark"]: AliasType<{
	create?:ValueTypes["BannersFields_advertise_remark_Create"],
	read?:ValueTypes["BannersFields_advertise_remark_Read"],
	update?:ValueTypes["BannersFields_advertise_remark_Update"],
	delete?:ValueTypes["BannersFields_advertise_remark_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_advertise_remark_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_advertise_remark_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_advertise_remark_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_advertise_remark_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_advertise_public"]: AliasType<{
	create?:ValueTypes["BannersFields_advertise_public_Create"],
	read?:ValueTypes["BannersFields_advertise_public_Read"],
	update?:ValueTypes["BannersFields_advertise_public_Update"],
	delete?:ValueTypes["BannersFields_advertise_public_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_advertise_public_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_advertise_public_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_advertise_public_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_advertise_public_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersFields__locations"]: AliasType<{
	create?:ValueTypes["BannersFields__locations_Create"],
	read?:ValueTypes["BannersFields__locations_Read"],
	update?:ValueTypes["BannersFields__locations_Update"],
	delete?:ValueTypes["BannersFields__locations_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["BannersFields__locations_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersFields__locations_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersFields__locations_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersFields__locations_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_region"]: AliasType<{
	create?:ValueTypes["BannersFields_region_Create"],
	read?:ValueTypes["BannersFields_region_Read"],
	update?:ValueTypes["BannersFields_region_Update"],
	delete?:ValueTypes["BannersFields_region_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_region_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_region_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_region_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_region_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_homePageClick"]: AliasType<{
	create?:ValueTypes["BannersFields_homePageClick_Create"],
	read?:ValueTypes["BannersFields_homePageClick_Read"],
	update?:ValueTypes["BannersFields_homePageClick_Update"],
	delete?:ValueTypes["BannersFields_homePageClick_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_homePageClick_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_homePageClick_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_homePageClick_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_homePageClick_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_homePageImpression"]: AliasType<{
	create?:ValueTypes["BannersFields_homePageImpression_Create"],
	read?:ValueTypes["BannersFields_homePageImpression_Read"],
	update?:ValueTypes["BannersFields_homePageImpression_Update"],
	delete?:ValueTypes["BannersFields_homePageImpression_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_homePageImpression_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_homePageImpression_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_homePageImpression_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_homePageImpression_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_latestEventClick"]: AliasType<{
	create?:ValueTypes["BannersFields_latestEventClick_Create"],
	read?:ValueTypes["BannersFields_latestEventClick_Read"],
	update?:ValueTypes["BannersFields_latestEventClick_Update"],
	delete?:ValueTypes["BannersFields_latestEventClick_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_latestEventClick_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_latestEventClick_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_latestEventClick_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_latestEventClick_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_latestEventImpression"]: AliasType<{
	create?:ValueTypes["BannersFields_latestEventImpression_Create"],
	read?:ValueTypes["BannersFields_latestEventImpression_Read"],
	update?:ValueTypes["BannersFields_latestEventImpression_Update"],
	delete?:ValueTypes["BannersFields_latestEventImpression_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_latestEventImpression_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_latestEventImpression_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_latestEventImpression_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_latestEventImpression_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_editorChoiceClick"]: AliasType<{
	create?:ValueTypes["BannersFields_editorChoiceClick_Create"],
	read?:ValueTypes["BannersFields_editorChoiceClick_Read"],
	update?:ValueTypes["BannersFields_editorChoiceClick_Update"],
	delete?:ValueTypes["BannersFields_editorChoiceClick_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_editorChoiceClick_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_editorChoiceClick_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_editorChoiceClick_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_editorChoiceClick_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_editorChoiceImpression"]: AliasType<{
	create?:ValueTypes["BannersFields_editorChoiceImpression_Create"],
	read?:ValueTypes["BannersFields_editorChoiceImpression_Read"],
	update?:ValueTypes["BannersFields_editorChoiceImpression_Update"],
	delete?:ValueTypes["BannersFields_editorChoiceImpression_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_editorChoiceImpression_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_editorChoiceImpression_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_editorChoiceImpression_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_editorChoiceImpression_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_eventListClick"]: AliasType<{
	create?:ValueTypes["BannersFields_eventListClick_Create"],
	read?:ValueTypes["BannersFields_eventListClick_Read"],
	update?:ValueTypes["BannersFields_eventListClick_Update"],
	delete?:ValueTypes["BannersFields_eventListClick_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_eventListClick_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_eventListClick_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_eventListClick_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_eventListClick_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_eventListImpression"]: AliasType<{
	create?:ValueTypes["BannersFields_eventListImpression_Create"],
	read?:ValueTypes["BannersFields_eventListImpression_Read"],
	update?:ValueTypes["BannersFields_eventListImpression_Update"],
	delete?:ValueTypes["BannersFields_eventListImpression_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_eventListImpression_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_eventListImpression_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_eventListImpression_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_eventListImpression_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_topTenClick"]: AliasType<{
	create?:ValueTypes["BannersFields_topTenClick_Create"],
	read?:ValueTypes["BannersFields_topTenClick_Read"],
	update?:ValueTypes["BannersFields_topTenClick_Update"],
	delete?:ValueTypes["BannersFields_topTenClick_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_topTenClick_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_topTenClick_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_topTenClick_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_topTenClick_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_topTenImpression"]: AliasType<{
	create?:ValueTypes["BannersFields_topTenImpression_Create"],
	read?:ValueTypes["BannersFields_topTenImpression_Read"],
	update?:ValueTypes["BannersFields_topTenImpression_Update"],
	delete?:ValueTypes["BannersFields_topTenImpression_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_topTenImpression_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_topTenImpression_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_topTenImpression_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_topTenImpression_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_miscClick"]: AliasType<{
	create?:ValueTypes["BannersFields_miscClick_Create"],
	read?:ValueTypes["BannersFields_miscClick_Read"],
	update?:ValueTypes["BannersFields_miscClick_Update"],
	delete?:ValueTypes["BannersFields_miscClick_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_miscClick_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_miscClick_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_miscClick_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_miscClick_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_miscImpression"]: AliasType<{
	create?:ValueTypes["BannersFields_miscImpression_Create"],
	read?:ValueTypes["BannersFields_miscImpression_Read"],
	update?:ValueTypes["BannersFields_miscImpression_Update"],
	delete?:ValueTypes["BannersFields_miscImpression_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_miscImpression_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_miscImpression_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_miscImpression_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_miscImpression_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_clickTotal"]: AliasType<{
	create?:ValueTypes["BannersFields_clickTotal_Create"],
	read?:ValueTypes["BannersFields_clickTotal_Read"],
	update?:ValueTypes["BannersFields_clickTotal_Update"],
	delete?:ValueTypes["BannersFields_clickTotal_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_clickTotal_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_clickTotal_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_clickTotal_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_clickTotal_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_totalImpression"]: AliasType<{
	create?:ValueTypes["BannersFields_totalImpression_Create"],
	read?:ValueTypes["BannersFields_totalImpression_Read"],
	update?:ValueTypes["BannersFields_totalImpression_Update"],
	delete?:ValueTypes["BannersFields_totalImpression_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_totalImpression_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_totalImpression_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_totalImpression_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_totalImpression_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_updatedAt"]: AliasType<{
	create?:ValueTypes["BannersFields_updatedAt_Create"],
	read?:ValueTypes["BannersFields_updatedAt_Read"],
	update?:ValueTypes["BannersFields_updatedAt_Update"],
	delete?:ValueTypes["BannersFields_updatedAt_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_updatedAt_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_updatedAt_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_updatedAt_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_updatedAt_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_createdAt"]: AliasType<{
	create?:ValueTypes["BannersFields_createdAt_Create"],
	read?:ValueTypes["BannersFields_createdAt_Read"],
	update?:ValueTypes["BannersFields_createdAt_Update"],
	delete?:ValueTypes["BannersFields_createdAt_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_createdAt_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_createdAt_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_createdAt_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_createdAt_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersCreateAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersReadAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersUpdateAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersDeleteAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["campaignsAccess"]: AliasType<{
	fields?:ValueTypes["CampaignsFields"],
	create?:ValueTypes["CampaignsCreateAccess"],
	read?:ValueTypes["CampaignsReadAccess"],
	update?:ValueTypes["CampaignsUpdateAccess"],
	delete?:ValueTypes["CampaignsDeleteAccess"],
		__typename?: boolean | `@${string}`
}>;
	["CampaignsFields"]: AliasType<{
	creator?:ValueTypes["CampaignsFields_creator"],
	invoiceNumber?:ValueTypes["CampaignsFields_invoiceNumber"],
	quotationNumber?:ValueTypes["CampaignsFields_quotationNumber"],
	campaignName?:ValueTypes["CampaignsFields_campaignName"],
	client?:ValueTypes["CampaignsFields_client"],
	contactPerson?:ValueTypes["CampaignsFields_contactPerson"],
	email?:ValueTypes["CampaignsFields_email"],
	phone?:ValueTypes["CampaignsFields_phone"],
	agentName?:ValueTypes["CampaignsFields_agentName"],
	agentCommission?:ValueTypes["CampaignsFields_agentCommission"],
	agentOrderNumber?:ValueTypes["CampaignsFields_agentOrderNumber"],
	_firstPublishedTime?:ValueTypes["CampaignsFields__firstPublishedTime"],
	updatedAt?:ValueTypes["CampaignsFields_updatedAt"],
	createdAt?:ValueTypes["CampaignsFields_createdAt"],
		__typename?: boolean | `@${string}`
}>;
	["CampaignsFields_creator"]: AliasType<{
	create?:ValueTypes["CampaignsFields_creator_Create"],
	read?:ValueTypes["CampaignsFields_creator_Read"],
	update?:ValueTypes["CampaignsFields_creator_Update"],
	delete?:ValueTypes["CampaignsFields_creator_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["CampaignsFields_creator_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CampaignsFields_creator_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CampaignsFields_creator_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CampaignsFields_creator_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CampaignsFields_invoiceNumber"]: AliasType<{
	create?:ValueTypes["CampaignsFields_invoiceNumber_Create"],
	read?:ValueTypes["CampaignsFields_invoiceNumber_Read"],
	update?:ValueTypes["CampaignsFields_invoiceNumber_Update"],
	delete?:ValueTypes["CampaignsFields_invoiceNumber_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["CampaignsFields_invoiceNumber_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CampaignsFields_invoiceNumber_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CampaignsFields_invoiceNumber_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CampaignsFields_invoiceNumber_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CampaignsFields_quotationNumber"]: AliasType<{
	create?:ValueTypes["CampaignsFields_quotationNumber_Create"],
	read?:ValueTypes["CampaignsFields_quotationNumber_Read"],
	update?:ValueTypes["CampaignsFields_quotationNumber_Update"],
	delete?:ValueTypes["CampaignsFields_quotationNumber_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["CampaignsFields_quotationNumber_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CampaignsFields_quotationNumber_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CampaignsFields_quotationNumber_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CampaignsFields_quotationNumber_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CampaignsFields_campaignName"]: AliasType<{
	create?:ValueTypes["CampaignsFields_campaignName_Create"],
	read?:ValueTypes["CampaignsFields_campaignName_Read"],
	update?:ValueTypes["CampaignsFields_campaignName_Update"],
	delete?:ValueTypes["CampaignsFields_campaignName_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["CampaignsFields_campaignName_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CampaignsFields_campaignName_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CampaignsFields_campaignName_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CampaignsFields_campaignName_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CampaignsFields_client"]: AliasType<{
	create?:ValueTypes["CampaignsFields_client_Create"],
	read?:ValueTypes["CampaignsFields_client_Read"],
	update?:ValueTypes["CampaignsFields_client_Update"],
	delete?:ValueTypes["CampaignsFields_client_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["CampaignsFields_client_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CampaignsFields_client_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CampaignsFields_client_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CampaignsFields_client_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CampaignsFields_contactPerson"]: AliasType<{
	create?:ValueTypes["CampaignsFields_contactPerson_Create"],
	read?:ValueTypes["CampaignsFields_contactPerson_Read"],
	update?:ValueTypes["CampaignsFields_contactPerson_Update"],
	delete?:ValueTypes["CampaignsFields_contactPerson_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["CampaignsFields_contactPerson_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CampaignsFields_contactPerson_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CampaignsFields_contactPerson_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CampaignsFields_contactPerson_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CampaignsFields_email"]: AliasType<{
	create?:ValueTypes["CampaignsFields_email_Create"],
	read?:ValueTypes["CampaignsFields_email_Read"],
	update?:ValueTypes["CampaignsFields_email_Update"],
	delete?:ValueTypes["CampaignsFields_email_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["CampaignsFields_email_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CampaignsFields_email_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CampaignsFields_email_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CampaignsFields_email_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CampaignsFields_phone"]: AliasType<{
	create?:ValueTypes["CampaignsFields_phone_Create"],
	read?:ValueTypes["CampaignsFields_phone_Read"],
	update?:ValueTypes["CampaignsFields_phone_Update"],
	delete?:ValueTypes["CampaignsFields_phone_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["CampaignsFields_phone_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CampaignsFields_phone_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CampaignsFields_phone_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CampaignsFields_phone_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CampaignsFields_agentName"]: AliasType<{
	create?:ValueTypes["CampaignsFields_agentName_Create"],
	read?:ValueTypes["CampaignsFields_agentName_Read"],
	update?:ValueTypes["CampaignsFields_agentName_Update"],
	delete?:ValueTypes["CampaignsFields_agentName_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["CampaignsFields_agentName_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CampaignsFields_agentName_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CampaignsFields_agentName_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CampaignsFields_agentName_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CampaignsFields_agentCommission"]: AliasType<{
	create?:ValueTypes["CampaignsFields_agentCommission_Create"],
	read?:ValueTypes["CampaignsFields_agentCommission_Read"],
	update?:ValueTypes["CampaignsFields_agentCommission_Update"],
	delete?:ValueTypes["CampaignsFields_agentCommission_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["CampaignsFields_agentCommission_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CampaignsFields_agentCommission_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CampaignsFields_agentCommission_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CampaignsFields_agentCommission_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CampaignsFields_agentOrderNumber"]: AliasType<{
	create?:ValueTypes["CampaignsFields_agentOrderNumber_Create"],
	read?:ValueTypes["CampaignsFields_agentOrderNumber_Read"],
	update?:ValueTypes["CampaignsFields_agentOrderNumber_Update"],
	delete?:ValueTypes["CampaignsFields_agentOrderNumber_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["CampaignsFields_agentOrderNumber_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CampaignsFields_agentOrderNumber_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CampaignsFields_agentOrderNumber_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CampaignsFields_agentOrderNumber_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CampaignsFields__firstPublishedTime"]: AliasType<{
	create?:ValueTypes["CampaignsFields__firstPublishedTime_Create"],
	read?:ValueTypes["CampaignsFields__firstPublishedTime_Read"],
	update?:ValueTypes["CampaignsFields__firstPublishedTime_Update"],
	delete?:ValueTypes["CampaignsFields__firstPublishedTime_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["CampaignsFields__firstPublishedTime_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CampaignsFields__firstPublishedTime_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CampaignsFields__firstPublishedTime_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CampaignsFields__firstPublishedTime_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CampaignsFields_updatedAt"]: AliasType<{
	create?:ValueTypes["CampaignsFields_updatedAt_Create"],
	read?:ValueTypes["CampaignsFields_updatedAt_Read"],
	update?:ValueTypes["CampaignsFields_updatedAt_Update"],
	delete?:ValueTypes["CampaignsFields_updatedAt_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["CampaignsFields_updatedAt_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CampaignsFields_updatedAt_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CampaignsFields_updatedAt_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CampaignsFields_updatedAt_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CampaignsFields_createdAt"]: AliasType<{
	create?:ValueTypes["CampaignsFields_createdAt_Create"],
	read?:ValueTypes["CampaignsFields_createdAt_Read"],
	update?:ValueTypes["CampaignsFields_createdAt_Update"],
	delete?:ValueTypes["CampaignsFields_createdAt_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["CampaignsFields_createdAt_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CampaignsFields_createdAt_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CampaignsFields_createdAt_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CampaignsFields_createdAt_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CampaignsCreateAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CampaignsReadAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CampaignsUpdateAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CampaignsDeleteAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["advertisesAccess"]: AliasType<{
	fields?:ValueTypes["AdvertisesFields"],
	create?:ValueTypes["AdvertisesCreateAccess"],
	read?:ValueTypes["AdvertisesReadAccess"],
	update?:ValueTypes["AdvertisesUpdateAccess"],
	delete?:ValueTypes["AdvertisesDeleteAccess"],
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesFields"]: AliasType<{
	advertise?:ValueTypes["AdvertisesFields_advertise"],
	advertiseStartDate?:ValueTypes["AdvertisesFields_advertiseStartDate"],
	advertiseEndDate?:ValueTypes["AdvertisesFields_advertiseEndDate"],
	type?:ValueTypes["AdvertisesFields_type"],
	invoiceDescription?:ValueTypes["AdvertisesFields_invoiceDescription"],
	standardPrice?:ValueTypes["AdvertisesFields_standardPrice"],
	discount?:ValueTypes["AdvertisesFields_discount"],
	discountPercentage?:ValueTypes["AdvertisesFields_discountPercentage"],
	_calculateByDiscount?:ValueTypes["AdvertisesFields__calculateByDiscount"],
	timableCost?:ValueTypes["AdvertisesFields_timableCost"],
	remark?:ValueTypes["AdvertisesFields_remark"],
	public?:ValueTypes["AdvertisesFields_public"],
	campaignName?:ValueTypes["AdvertisesFields_campaignName"],
	campaignId?:ValueTypes["AdvertisesFields_campaignId"],
	status?:ValueTypes["AdvertisesFields_status"],
	updatedAt?:ValueTypes["AdvertisesFields_updatedAt"],
	createdAt?:ValueTypes["AdvertisesFields_createdAt"],
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesFields_advertise"]: AliasType<{
	create?:ValueTypes["AdvertisesFields_advertise_Create"],
	read?:ValueTypes["AdvertisesFields_advertise_Read"],
	update?:ValueTypes["AdvertisesFields_advertise_Update"],
	delete?:ValueTypes["AdvertisesFields_advertise_Delete"],
	fields?:ValueTypes["AdvertisesFields_advertise_Fields"],
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesFields_advertise_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesFields_advertise_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesFields_advertise_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesFields_advertise_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesFields_advertise_Fields"]: AliasType<{
	type?:ValueTypes["AdvertisesFields_advertise_type"],
	event?:ValueTypes["AdvertisesFields_advertise_event"],
	keyword?:ValueTypes["AdvertisesFields_advertise_keyword"],
	location?:ValueTypes["AdvertisesFields_advertise_location"],
	displayPosition?:ValueTypes["AdvertisesFields_advertise_displayPosition"],
	rankingListRemark?:ValueTypes["AdvertisesFields_advertise_rankingListRemark"],
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesFields_advertise_type"]: AliasType<{
	create?:ValueTypes["AdvertisesFields_advertise_type_Create"],
	read?:ValueTypes["AdvertisesFields_advertise_type_Read"],
	update?:ValueTypes["AdvertisesFields_advertise_type_Update"],
	delete?:ValueTypes["AdvertisesFields_advertise_type_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesFields_advertise_type_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesFields_advertise_type_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesFields_advertise_type_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesFields_advertise_type_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesFields_advertise_event"]: AliasType<{
	create?:ValueTypes["AdvertisesFields_advertise_event_Create"],
	read?:ValueTypes["AdvertisesFields_advertise_event_Read"],
	update?:ValueTypes["AdvertisesFields_advertise_event_Update"],
	delete?:ValueTypes["AdvertisesFields_advertise_event_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesFields_advertise_event_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesFields_advertise_event_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesFields_advertise_event_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesFields_advertise_event_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesFields_advertise_keyword"]: AliasType<{
	create?:ValueTypes["AdvertisesFields_advertise_keyword_Create"],
	read?:ValueTypes["AdvertisesFields_advertise_keyword_Read"],
	update?:ValueTypes["AdvertisesFields_advertise_keyword_Update"],
	delete?:ValueTypes["AdvertisesFields_advertise_keyword_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesFields_advertise_keyword_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesFields_advertise_keyword_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesFields_advertise_keyword_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesFields_advertise_keyword_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesFields_advertise_location"]: AliasType<{
	create?:ValueTypes["AdvertisesFields_advertise_location_Create"],
	read?:ValueTypes["AdvertisesFields_advertise_location_Read"],
	update?:ValueTypes["AdvertisesFields_advertise_location_Update"],
	delete?:ValueTypes["AdvertisesFields_advertise_location_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesFields_advertise_location_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesFields_advertise_location_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesFields_advertise_location_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesFields_advertise_location_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesFields_advertise_displayPosition"]: AliasType<{
	create?:ValueTypes["AdvertisesFields_advertise_displayPosition_Create"],
	read?:ValueTypes["AdvertisesFields_advertise_displayPosition_Read"],
	update?:ValueTypes["AdvertisesFields_advertise_displayPosition_Update"],
	delete?:ValueTypes["AdvertisesFields_advertise_displayPosition_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesFields_advertise_displayPosition_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesFields_advertise_displayPosition_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesFields_advertise_displayPosition_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesFields_advertise_displayPosition_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesFields_advertise_rankingListRemark"]: AliasType<{
	create?:ValueTypes["AdvertisesFields_advertise_rankingListRemark_Create"],
	read?:ValueTypes["AdvertisesFields_advertise_rankingListRemark_Read"],
	update?:ValueTypes["AdvertisesFields_advertise_rankingListRemark_Update"],
	delete?:ValueTypes["AdvertisesFields_advertise_rankingListRemark_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesFields_advertise_rankingListRemark_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesFields_advertise_rankingListRemark_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesFields_advertise_rankingListRemark_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesFields_advertise_rankingListRemark_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesFields_advertiseStartDate"]: AliasType<{
	create?:ValueTypes["AdvertisesFields_advertiseStartDate_Create"],
	read?:ValueTypes["AdvertisesFields_advertiseStartDate_Read"],
	update?:ValueTypes["AdvertisesFields_advertiseStartDate_Update"],
	delete?:ValueTypes["AdvertisesFields_advertiseStartDate_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesFields_advertiseStartDate_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesFields_advertiseStartDate_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesFields_advertiseStartDate_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesFields_advertiseStartDate_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesFields_advertiseEndDate"]: AliasType<{
	create?:ValueTypes["AdvertisesFields_advertiseEndDate_Create"],
	read?:ValueTypes["AdvertisesFields_advertiseEndDate_Read"],
	update?:ValueTypes["AdvertisesFields_advertiseEndDate_Update"],
	delete?:ValueTypes["AdvertisesFields_advertiseEndDate_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesFields_advertiseEndDate_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesFields_advertiseEndDate_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesFields_advertiseEndDate_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesFields_advertiseEndDate_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesFields_type"]: AliasType<{
	create?:ValueTypes["AdvertisesFields_type_Create"],
	read?:ValueTypes["AdvertisesFields_type_Read"],
	update?:ValueTypes["AdvertisesFields_type_Update"],
	delete?:ValueTypes["AdvertisesFields_type_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesFields_type_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesFields_type_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesFields_type_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesFields_type_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesFields_invoiceDescription"]: AliasType<{
	create?:ValueTypes["AdvertisesFields_invoiceDescription_Create"],
	read?:ValueTypes["AdvertisesFields_invoiceDescription_Read"],
	update?:ValueTypes["AdvertisesFields_invoiceDescription_Update"],
	delete?:ValueTypes["AdvertisesFields_invoiceDescription_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesFields_invoiceDescription_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesFields_invoiceDescription_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesFields_invoiceDescription_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesFields_invoiceDescription_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesFields_standardPrice"]: AliasType<{
	create?:ValueTypes["AdvertisesFields_standardPrice_Create"],
	read?:ValueTypes["AdvertisesFields_standardPrice_Read"],
	update?:ValueTypes["AdvertisesFields_standardPrice_Update"],
	delete?:ValueTypes["AdvertisesFields_standardPrice_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesFields_standardPrice_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesFields_standardPrice_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesFields_standardPrice_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesFields_standardPrice_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesFields_discount"]: AliasType<{
	create?:ValueTypes["AdvertisesFields_discount_Create"],
	read?:ValueTypes["AdvertisesFields_discount_Read"],
	update?:ValueTypes["AdvertisesFields_discount_Update"],
	delete?:ValueTypes["AdvertisesFields_discount_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesFields_discount_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesFields_discount_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesFields_discount_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesFields_discount_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesFields_discountPercentage"]: AliasType<{
	create?:ValueTypes["AdvertisesFields_discountPercentage_Create"],
	read?:ValueTypes["AdvertisesFields_discountPercentage_Read"],
	update?:ValueTypes["AdvertisesFields_discountPercentage_Update"],
	delete?:ValueTypes["AdvertisesFields_discountPercentage_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesFields_discountPercentage_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesFields_discountPercentage_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesFields_discountPercentage_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesFields_discountPercentage_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesFields__calculateByDiscount"]: AliasType<{
	create?:ValueTypes["AdvertisesFields__calculateByDiscount_Create"],
	read?:ValueTypes["AdvertisesFields__calculateByDiscount_Read"],
	update?:ValueTypes["AdvertisesFields__calculateByDiscount_Update"],
	delete?:ValueTypes["AdvertisesFields__calculateByDiscount_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesFields__calculateByDiscount_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesFields__calculateByDiscount_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesFields__calculateByDiscount_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesFields__calculateByDiscount_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesFields_timableCost"]: AliasType<{
	create?:ValueTypes["AdvertisesFields_timableCost_Create"],
	read?:ValueTypes["AdvertisesFields_timableCost_Read"],
	update?:ValueTypes["AdvertisesFields_timableCost_Update"],
	delete?:ValueTypes["AdvertisesFields_timableCost_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesFields_timableCost_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesFields_timableCost_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesFields_timableCost_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesFields_timableCost_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesFields_remark"]: AliasType<{
	create?:ValueTypes["AdvertisesFields_remark_Create"],
	read?:ValueTypes["AdvertisesFields_remark_Read"],
	update?:ValueTypes["AdvertisesFields_remark_Update"],
	delete?:ValueTypes["AdvertisesFields_remark_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesFields_remark_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesFields_remark_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesFields_remark_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesFields_remark_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesFields_public"]: AliasType<{
	create?:ValueTypes["AdvertisesFields_public_Create"],
	read?:ValueTypes["AdvertisesFields_public_Read"],
	update?:ValueTypes["AdvertisesFields_public_Update"],
	delete?:ValueTypes["AdvertisesFields_public_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesFields_public_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesFields_public_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesFields_public_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesFields_public_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesFields_campaignName"]: AliasType<{
	create?:ValueTypes["AdvertisesFields_campaignName_Create"],
	read?:ValueTypes["AdvertisesFields_campaignName_Read"],
	update?:ValueTypes["AdvertisesFields_campaignName_Update"],
	delete?:ValueTypes["AdvertisesFields_campaignName_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesFields_campaignName_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesFields_campaignName_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesFields_campaignName_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesFields_campaignName_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesFields_campaignId"]: AliasType<{
	create?:ValueTypes["AdvertisesFields_campaignId_Create"],
	read?:ValueTypes["AdvertisesFields_campaignId_Read"],
	update?:ValueTypes["AdvertisesFields_campaignId_Update"],
	delete?:ValueTypes["AdvertisesFields_campaignId_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesFields_campaignId_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesFields_campaignId_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesFields_campaignId_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesFields_campaignId_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesFields_status"]: AliasType<{
	create?:ValueTypes["AdvertisesFields_status_Create"],
	read?:ValueTypes["AdvertisesFields_status_Read"],
	update?:ValueTypes["AdvertisesFields_status_Update"],
	delete?:ValueTypes["AdvertisesFields_status_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesFields_status_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesFields_status_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesFields_status_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesFields_status_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesFields_updatedAt"]: AliasType<{
	create?:ValueTypes["AdvertisesFields_updatedAt_Create"],
	read?:ValueTypes["AdvertisesFields_updatedAt_Read"],
	update?:ValueTypes["AdvertisesFields_updatedAt_Update"],
	delete?:ValueTypes["AdvertisesFields_updatedAt_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesFields_updatedAt_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesFields_updatedAt_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesFields_updatedAt_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesFields_updatedAt_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesFields_createdAt"]: AliasType<{
	create?:ValueTypes["AdvertisesFields_createdAt_Create"],
	read?:ValueTypes["AdvertisesFields_createdAt_Read"],
	update?:ValueTypes["AdvertisesFields_createdAt_Update"],
	delete?:ValueTypes["AdvertisesFields_createdAt_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesFields_createdAt_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesFields_createdAt_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesFields_createdAt_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesFields_createdAt_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesCreateAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesReadAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesUpdateAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesDeleteAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["staticsAccess"]: AliasType<{
	fields?:ValueTypes["StaticsFields"],
	create?:ValueTypes["StaticsCreateAccess"],
	read?:ValueTypes["StaticsReadAccess"],
	update?:ValueTypes["StaticsUpdateAccess"],
	delete?:ValueTypes["StaticsDeleteAccess"],
		__typename?: boolean | `@${string}`
}>;
	["StaticsFields"]: AliasType<{
	name?:ValueTypes["StaticsFields_name"],
	key?:ValueTypes["StaticsFields_key"],
	content?:ValueTypes["StaticsFields_content"],
	region?:ValueTypes["StaticsFields_region"],
	updatedAt?:ValueTypes["StaticsFields_updatedAt"],
	createdAt?:ValueTypes["StaticsFields_createdAt"],
		__typename?: boolean | `@${string}`
}>;
	["StaticsFields_name"]: AliasType<{
	create?:ValueTypes["StaticsFields_name_Create"],
	read?:ValueTypes["StaticsFields_name_Read"],
	update?:ValueTypes["StaticsFields_name_Update"],
	delete?:ValueTypes["StaticsFields_name_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["StaticsFields_name_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["StaticsFields_name_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["StaticsFields_name_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["StaticsFields_name_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["StaticsFields_key"]: AliasType<{
	create?:ValueTypes["StaticsFields_key_Create"],
	read?:ValueTypes["StaticsFields_key_Read"],
	update?:ValueTypes["StaticsFields_key_Update"],
	delete?:ValueTypes["StaticsFields_key_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["StaticsFields_key_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["StaticsFields_key_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["StaticsFields_key_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["StaticsFields_key_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["StaticsFields_content"]: AliasType<{
	create?:ValueTypes["StaticsFields_content_Create"],
	read?:ValueTypes["StaticsFields_content_Read"],
	update?:ValueTypes["StaticsFields_content_Update"],
	delete?:ValueTypes["StaticsFields_content_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["StaticsFields_content_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["StaticsFields_content_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["StaticsFields_content_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["StaticsFields_content_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["StaticsFields_region"]: AliasType<{
	create?:ValueTypes["StaticsFields_region_Create"],
	read?:ValueTypes["StaticsFields_region_Read"],
	update?:ValueTypes["StaticsFields_region_Update"],
	delete?:ValueTypes["StaticsFields_region_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["StaticsFields_region_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["StaticsFields_region_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["StaticsFields_region_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["StaticsFields_region_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["StaticsFields_updatedAt"]: AliasType<{
	create?:ValueTypes["StaticsFields_updatedAt_Create"],
	read?:ValueTypes["StaticsFields_updatedAt_Read"],
	update?:ValueTypes["StaticsFields_updatedAt_Update"],
	delete?:ValueTypes["StaticsFields_updatedAt_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["StaticsFields_updatedAt_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["StaticsFields_updatedAt_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["StaticsFields_updatedAt_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["StaticsFields_updatedAt_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["StaticsFields_createdAt"]: AliasType<{
	create?:ValueTypes["StaticsFields_createdAt_Create"],
	read?:ValueTypes["StaticsFields_createdAt_Read"],
	update?:ValueTypes["StaticsFields_createdAt_Update"],
	delete?:ValueTypes["StaticsFields_createdAt_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["StaticsFields_createdAt_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["StaticsFields_createdAt_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["StaticsFields_createdAt_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["StaticsFields_createdAt_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["StaticsCreateAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["StaticsReadAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["StaticsUpdateAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["StaticsDeleteAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["event_viewsAccess"]: AliasType<{
	fields?:ValueTypes["EventViewsFields"],
	create?:ValueTypes["EventViewsCreateAccess"],
	read?:ValueTypes["EventViewsReadAccess"],
	update?:ValueTypes["EventViewsUpdateAccess"],
	delete?:ValueTypes["EventViewsDeleteAccess"],
		__typename?: boolean | `@${string}`
}>;
	["EventViewsFields"]: AliasType<{
	date?:ValueTypes["EventViewsFields_date"],
	eventId?:ValueTypes["EventViewsFields_eventId"],
	viewCount?:ValueTypes["EventViewsFields_viewCount"],
	updatedAt?:ValueTypes["EventViewsFields_updatedAt"],
	createdAt?:ValueTypes["EventViewsFields_createdAt"],
		__typename?: boolean | `@${string}`
}>;
	["EventViewsFields_date"]: AliasType<{
	create?:ValueTypes["EventViewsFields_date_Create"],
	read?:ValueTypes["EventViewsFields_date_Read"],
	update?:ValueTypes["EventViewsFields_date_Update"],
	delete?:ValueTypes["EventViewsFields_date_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventViewsFields_date_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventViewsFields_date_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventViewsFields_date_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventViewsFields_date_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventViewsFields_eventId"]: AliasType<{
	create?:ValueTypes["EventViewsFields_eventId_Create"],
	read?:ValueTypes["EventViewsFields_eventId_Read"],
	update?:ValueTypes["EventViewsFields_eventId_Update"],
	delete?:ValueTypes["EventViewsFields_eventId_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventViewsFields_eventId_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventViewsFields_eventId_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventViewsFields_eventId_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventViewsFields_eventId_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventViewsFields_viewCount"]: AliasType<{
	create?:ValueTypes["EventViewsFields_viewCount_Create"],
	read?:ValueTypes["EventViewsFields_viewCount_Read"],
	update?:ValueTypes["EventViewsFields_viewCount_Update"],
	delete?:ValueTypes["EventViewsFields_viewCount_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventViewsFields_viewCount_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventViewsFields_viewCount_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventViewsFields_viewCount_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventViewsFields_viewCount_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventViewsFields_updatedAt"]: AliasType<{
	create?:ValueTypes["EventViewsFields_updatedAt_Create"],
	read?:ValueTypes["EventViewsFields_updatedAt_Read"],
	update?:ValueTypes["EventViewsFields_updatedAt_Update"],
	delete?:ValueTypes["EventViewsFields_updatedAt_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventViewsFields_updatedAt_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventViewsFields_updatedAt_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventViewsFields_updatedAt_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventViewsFields_updatedAt_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventViewsFields_createdAt"]: AliasType<{
	create?:ValueTypes["EventViewsFields_createdAt_Create"],
	read?:ValueTypes["EventViewsFields_createdAt_Read"],
	update?:ValueTypes["EventViewsFields_createdAt_Update"],
	delete?:ValueTypes["EventViewsFields_createdAt_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventViewsFields_createdAt_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventViewsFields_createdAt_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventViewsFields_createdAt_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventViewsFields_createdAt_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventViewsCreateAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventViewsReadAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventViewsUpdateAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventViewsDeleteAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["post_viewsAccess"]: AliasType<{
	fields?:ValueTypes["PostViewsFields"],
	create?:ValueTypes["PostViewsCreateAccess"],
	read?:ValueTypes["PostViewsReadAccess"],
	update?:ValueTypes["PostViewsUpdateAccess"],
	delete?:ValueTypes["PostViewsDeleteAccess"],
		__typename?: boolean | `@${string}`
}>;
	["PostViewsFields"]: AliasType<{
	date?:ValueTypes["PostViewsFields_date"],
	postId?:ValueTypes["PostViewsFields_postId"],
	viewCount?:ValueTypes["PostViewsFields_viewCount"],
	updatedAt?:ValueTypes["PostViewsFields_updatedAt"],
	createdAt?:ValueTypes["PostViewsFields_createdAt"],
		__typename?: boolean | `@${string}`
}>;
	["PostViewsFields_date"]: AliasType<{
	create?:ValueTypes["PostViewsFields_date_Create"],
	read?:ValueTypes["PostViewsFields_date_Read"],
	update?:ValueTypes["PostViewsFields_date_Update"],
	delete?:ValueTypes["PostViewsFields_date_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["PostViewsFields_date_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostViewsFields_date_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostViewsFields_date_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostViewsFields_date_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostViewsFields_postId"]: AliasType<{
	create?:ValueTypes["PostViewsFields_postId_Create"],
	read?:ValueTypes["PostViewsFields_postId_Read"],
	update?:ValueTypes["PostViewsFields_postId_Update"],
	delete?:ValueTypes["PostViewsFields_postId_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["PostViewsFields_postId_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostViewsFields_postId_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostViewsFields_postId_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostViewsFields_postId_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostViewsFields_viewCount"]: AliasType<{
	create?:ValueTypes["PostViewsFields_viewCount_Create"],
	read?:ValueTypes["PostViewsFields_viewCount_Read"],
	update?:ValueTypes["PostViewsFields_viewCount_Update"],
	delete?:ValueTypes["PostViewsFields_viewCount_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["PostViewsFields_viewCount_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostViewsFields_viewCount_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostViewsFields_viewCount_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostViewsFields_viewCount_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostViewsFields_updatedAt"]: AliasType<{
	create?:ValueTypes["PostViewsFields_updatedAt_Create"],
	read?:ValueTypes["PostViewsFields_updatedAt_Read"],
	update?:ValueTypes["PostViewsFields_updatedAt_Update"],
	delete?:ValueTypes["PostViewsFields_updatedAt_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["PostViewsFields_updatedAt_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostViewsFields_updatedAt_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostViewsFields_updatedAt_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostViewsFields_updatedAt_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostViewsFields_createdAt"]: AliasType<{
	create?:ValueTypes["PostViewsFields_createdAt_Create"],
	read?:ValueTypes["PostViewsFields_createdAt_Read"],
	update?:ValueTypes["PostViewsFields_createdAt_Update"],
	delete?:ValueTypes["PostViewsFields_createdAt_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["PostViewsFields_createdAt_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostViewsFields_createdAt_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostViewsFields_createdAt_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostViewsFields_createdAt_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostViewsCreateAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostViewsReadAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostViewsUpdateAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostViewsDeleteAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["payload_preferencesAccess"]: AliasType<{
	fields?:ValueTypes["PayloadPreferencesFields"],
	create?:ValueTypes["PayloadPreferencesCreateAccess"],
	read?:ValueTypes["PayloadPreferencesReadAccess"],
	update?:ValueTypes["PayloadPreferencesUpdateAccess"],
	delete?:ValueTypes["PayloadPreferencesDeleteAccess"],
		__typename?: boolean | `@${string}`
}>;
	["PayloadPreferencesFields"]: AliasType<{
	user?:ValueTypes["PayloadPreferencesFields_user"],
	key?:ValueTypes["PayloadPreferencesFields_key"],
	value?:ValueTypes["PayloadPreferencesFields_value"],
	updatedAt?:ValueTypes["PayloadPreferencesFields_updatedAt"],
	createdAt?:ValueTypes["PayloadPreferencesFields_createdAt"],
		__typename?: boolean | `@${string}`
}>;
	["PayloadPreferencesFields_user"]: AliasType<{
	create?:ValueTypes["PayloadPreferencesFields_user_Create"],
	read?:ValueTypes["PayloadPreferencesFields_user_Read"],
	update?:ValueTypes["PayloadPreferencesFields_user_Update"],
	delete?:ValueTypes["PayloadPreferencesFields_user_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["PayloadPreferencesFields_user_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PayloadPreferencesFields_user_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PayloadPreferencesFields_user_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PayloadPreferencesFields_user_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PayloadPreferencesFields_key"]: AliasType<{
	create?:ValueTypes["PayloadPreferencesFields_key_Create"],
	read?:ValueTypes["PayloadPreferencesFields_key_Read"],
	update?:ValueTypes["PayloadPreferencesFields_key_Update"],
	delete?:ValueTypes["PayloadPreferencesFields_key_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["PayloadPreferencesFields_key_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PayloadPreferencesFields_key_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PayloadPreferencesFields_key_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PayloadPreferencesFields_key_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PayloadPreferencesFields_value"]: AliasType<{
	create?:ValueTypes["PayloadPreferencesFields_value_Create"],
	read?:ValueTypes["PayloadPreferencesFields_value_Read"],
	update?:ValueTypes["PayloadPreferencesFields_value_Update"],
	delete?:ValueTypes["PayloadPreferencesFields_value_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["PayloadPreferencesFields_value_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PayloadPreferencesFields_value_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PayloadPreferencesFields_value_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PayloadPreferencesFields_value_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PayloadPreferencesFields_updatedAt"]: AliasType<{
	create?:ValueTypes["PayloadPreferencesFields_updatedAt_Create"],
	read?:ValueTypes["PayloadPreferencesFields_updatedAt_Read"],
	update?:ValueTypes["PayloadPreferencesFields_updatedAt_Update"],
	delete?:ValueTypes["PayloadPreferencesFields_updatedAt_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["PayloadPreferencesFields_updatedAt_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PayloadPreferencesFields_updatedAt_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PayloadPreferencesFields_updatedAt_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PayloadPreferencesFields_updatedAt_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PayloadPreferencesFields_createdAt"]: AliasType<{
	create?:ValueTypes["PayloadPreferencesFields_createdAt_Create"],
	read?:ValueTypes["PayloadPreferencesFields_createdAt_Read"],
	update?:ValueTypes["PayloadPreferencesFields_createdAt_Update"],
	delete?:ValueTypes["PayloadPreferencesFields_createdAt_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["PayloadPreferencesFields_createdAt_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PayloadPreferencesFields_createdAt_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PayloadPreferencesFields_createdAt_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PayloadPreferencesFields_createdAt_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PayloadPreferencesCreateAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PayloadPreferencesReadAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PayloadPreferencesUpdateAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PayloadPreferencesDeleteAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["GetEventMaxUsedTag"]: AliasType<{
	tag?:ValueTypes["maxUsedTag"],
		__typename?: boolean | `@${string}`
}>;
	["maxUsedTag"]: AliasType<{
	id?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ChatbotStocks"]: AliasType<{
	product?:ValueTypes["Product"],
	ticketing?:ValueTypes["Ticketing"],
		__typename?: boolean | `@${string}`
}>;
	["ChatbotEvents"]: AliasType<{
	events?:ValueTypes["Event"],
		__typename?: boolean | `@${string}`
}>;
	["WebAppCheckInCountGetResponse"]: AliasType<{
	checkInCount?:boolean | `@${string}`,
	totalQrCode?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductWhereInput"]: {
	id?: ValueTypes["ProductDetailOperator"] | undefined | null | Variable<any, string>
};
	["ProductDetailOperator"]: {
	equals?: string | undefined | null | Variable<any, string>
};
	["ProductList"]: AliasType<{
	products?:ValueTypes["Product"],
		__typename?: boolean | `@${string}`
}>;
	["TicketingWhereInput"]: {
	id?: ValueTypes["TicketingDetailOperator"] | undefined | null | Variable<any, string>
};
	["TicketingDetailOperator"]: {
	equals?: string | undefined | null | Variable<any, string>
};
	["TicketingList"]: AliasType<{
	ticketings?:ValueTypes["Ticketing"],
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodeList"]: AliasType<{
	docs?:ValueTypes["RedemptionCode"],
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodeWhereInput"]: {
	qrcodeId?: ValueTypes["RedemptionCodeDetailOperator"] | undefined | null | Variable<any, string>
};
	["RedemptionCodeDetailOperator"]: {
	equals?: string | undefined | null | Variable<any, string>
};
	["BookmarkList"]: AliasType<{
	docs?:ValueTypes["WebAppBookmark"],
		__typename?: boolean | `@${string}`
}>;
	["Mutation"]: AliasType<{
createEvent?: [{	data: ValueTypes["mutationEventInput"] | Variable<any, string>,	locale?: ValueTypes["LocaleInputType"] | undefined | null | Variable<any, string>},ValueTypes["Event"]],
updateEvent?: [{	id: string | Variable<any, string>,	autosave?: boolean | undefined | null | Variable<any, string>,	data: ValueTypes["mutationEventUpdateInput"] | Variable<any, string>,	locale?: ValueTypes["LocaleInputType"] | undefined | null | Variable<any, string>},ValueTypes["Event"]],
deleteEvent?: [{	id: string | Variable<any, string>},ValueTypes["Event"]],
createLocation?: [{	data: ValueTypes["mutationLocationInput"] | Variable<any, string>,	locale?: ValueTypes["LocaleInputType"] | undefined | null | Variable<any, string>},ValueTypes["Location"]],
updateLocation?: [{	id: string | Variable<any, string>,	autosave?: boolean | undefined | null | Variable<any, string>,	data: ValueTypes["mutationLocationUpdateInput"] | Variable<any, string>,	locale?: ValueTypes["LocaleInputType"] | undefined | null | Variable<any, string>},ValueTypes["Location"]],
deleteLocation?: [{	id: string | Variable<any, string>},ValueTypes["Location"]],
createPost?: [{	data: ValueTypes["mutationPostInput"] | Variable<any, string>,	locale?: ValueTypes["LocaleInputType"] | undefined | null | Variable<any, string>},ValueTypes["Post"]],
updatePost?: [{	id: string | Variable<any, string>,	autosave?: boolean | undefined | null | Variable<any, string>,	data: ValueTypes["mutationPostUpdateInput"] | Variable<any, string>,	locale?: ValueTypes["LocaleInputType"] | undefined | null | Variable<any, string>},ValueTypes["Post"]],
deletePost?: [{	id: string | Variable<any, string>},ValueTypes["Post"]],
createTicketing?: [{	data: ValueTypes["mutationTicketingInput"] | Variable<any, string>,	locale?: ValueTypes["LocaleInputType"] | undefined | null | Variable<any, string>},ValueTypes["Ticketing"]],
updateTicketing?: [{	id: string | Variable<any, string>,	autosave?: boolean | undefined | null | Variable<any, string>,	data: ValueTypes["mutationTicketingUpdateInput"] | Variable<any, string>,	locale?: ValueTypes["LocaleInputType"] | undefined | null | Variable<any, string>},ValueTypes["Ticketing"]],
deleteTicketing?: [{	id: string | Variable<any, string>},ValueTypes["Ticketing"]],
createProduct?: [{	data: ValueTypes["mutationProductInput"] | Variable<any, string>,	locale?: ValueTypes["LocaleInputType"] | undefined | null | Variable<any, string>},ValueTypes["Product"]],
updateProduct?: [{	id: string | Variable<any, string>,	autosave?: boolean | undefined | null | Variable<any, string>,	data: ValueTypes["mutationProductUpdateInput"] | Variable<any, string>,	locale?: ValueTypes["LocaleInputType"] | undefined | null | Variable<any, string>},ValueTypes["Product"]],
deleteProduct?: [{	id: string | Variable<any, string>},ValueTypes["Product"]],
createInventoryStatus?: [{	data: ValueTypes["mutationInventoryStatusInput"] | Variable<any, string>,	locale?: ValueTypes["LocaleInputType"] | undefined | null | Variable<any, string>},ValueTypes["InventoryStatus"]],
updateInventoryStatus?: [{	id: string | Variable<any, string>,	autosave?: boolean | undefined | null | Variable<any, string>,	data: ValueTypes["mutationInventoryStatusUpdateInput"] | Variable<any, string>,	locale?: ValueTypes["LocaleInputType"] | undefined | null | Variable<any, string>},ValueTypes["InventoryStatus"]],
deleteInventoryStatus?: [{	id: string | Variable<any, string>},ValueTypes["InventoryStatus"]],
createTransaction?: [{	data: ValueTypes["mutationTransactionInput"] | Variable<any, string>,	locale?: ValueTypes["LocaleInputType"] | undefined | null | Variable<any, string>},ValueTypes["Transaction"]],
updateTransaction?: [{	id: string | Variable<any, string>,	autosave?: boolean | undefined | null | Variable<any, string>,	data: ValueTypes["mutationTransactionUpdateInput"] | Variable<any, string>,	locale?: ValueTypes["LocaleInputType"] | undefined | null | Variable<any, string>},ValueTypes["Transaction"]],
deleteTransaction?: [{	id: string | Variable<any, string>},ValueTypes["Transaction"]],
createRedemptionCode?: [{	data: ValueTypes["mutationRedemptionCodeInput"] | Variable<any, string>,	locale?: ValueTypes["LocaleInputType"] | undefined | null | Variable<any, string>},ValueTypes["RedemptionCode"]],
updateRedemptionCode?: [{	id: string | Variable<any, string>,	autosave?: boolean | undefined | null | Variable<any, string>,	data: ValueTypes["mutationRedemptionCodeUpdateInput"] | Variable<any, string>,	locale?: ValueTypes["LocaleInputType"] | undefined | null | Variable<any, string>},ValueTypes["RedemptionCode"]],
deleteRedemptionCode?: [{	id: string | Variable<any, string>},ValueTypes["RedemptionCode"]],
createRedemptionPdf?: [{	data: ValueTypes["mutationRedemptionPdfInput"] | Variable<any, string>,	locale?: ValueTypes["LocaleInputType"] | undefined | null | Variable<any, string>},ValueTypes["RedemptionPdf"]],
updateRedemptionPdf?: [{	id: string | Variable<any, string>,	autosave?: boolean | undefined | null | Variable<any, string>,	data: ValueTypes["mutationRedemptionPdfUpdateInput"] | Variable<any, string>,	locale?: ValueTypes["LocaleInputType"] | undefined | null | Variable<any, string>},ValueTypes["RedemptionPdf"]],
deleteRedemptionPdf?: [{	id: string | Variable<any, string>},ValueTypes["RedemptionPdf"]],
createCheckInRedemption?: [{	data: ValueTypes["mutationCheckInRedemptionInput"] | Variable<any, string>,	locale?: ValueTypes["LocaleInputType"] | undefined | null | Variable<any, string>},ValueTypes["CheckInRedemption"]],
updateCheckInRedemption?: [{	id: string | Variable<any, string>,	autosave?: boolean | undefined | null | Variable<any, string>,	data: ValueTypes["mutationCheckInRedemptionUpdateInput"] | Variable<any, string>,	locale?: ValueTypes["LocaleInputType"] | undefined | null | Variable<any, string>},ValueTypes["CheckInRedemption"]],
deleteCheckInRedemption?: [{	id: string | Variable<any, string>},ValueTypes["CheckInRedemption"]],
createUser?: [{	data: ValueTypes["mutationUserInput"] | Variable<any, string>,	locale?: ValueTypes["LocaleInputType"] | undefined | null | Variable<any, string>},ValueTypes["User"]],
updateUser?: [{	id?: string | undefined | null | Variable<any, string>,	autosave?: boolean | undefined | null | Variable<any, string>,	data: ValueTypes["mutationUserUpdateInput"] | Variable<any, string>,	locale?: ValueTypes["LocaleInputType"] | undefined | null | Variable<any, string>},ValueTypes["User"]],
deleteUser?: [{	id: string | Variable<any, string>},ValueTypes["User"]],
	refreshTokenUser?:ValueTypes["usersRefreshedUser"],
	logoutUser?:boolean | `@${string}`,
unlockUser?: [{	email: string | Variable<any, string>},boolean | `@${string}`],
loginUser?: [{	email?: string | undefined | null | Variable<any, string>,	password?: string | undefined | null | Variable<any, string>},ValueTypes["usersLoginResult"]],
forgotPasswordUser?: [{	disableEmail?: boolean | undefined | null | Variable<any, string>,	email: string | Variable<any, string>,	expiration?: number | undefined | null | Variable<any, string>},boolean | `@${string}`],
resetPasswordUser?: [{	password?: string | undefined | null | Variable<any, string>,	token?: string | undefined | null | Variable<any, string>},ValueTypes["usersResetPassword"]],
verifyEmailUser?: [{	token?: string | undefined | null | Variable<any, string>},boolean | `@${string}`],
createBookmark?: [{	data: ValueTypes["mutationBookmarkInput"] | Variable<any, string>,	locale?: ValueTypes["LocaleInputType"] | undefined | null | Variable<any, string>},ValueTypes["Bookmark"]],
updateBookmark?: [{	id: string | Variable<any, string>,	autosave?: boolean | undefined | null | Variable<any, string>,	data: ValueTypes["mutationBookmarkUpdateInput"] | Variable<any, string>,	locale?: ValueTypes["LocaleInputType"] | undefined | null | Variable<any, string>},ValueTypes["Bookmark"]],
deleteBookmark?: [{	id: string | Variable<any, string>},ValueTypes["Bookmark"]],
createWebAppBookmark?: [{	data: ValueTypes["mutationWebAppBookmarkInput"] | Variable<any, string>,	locale?: ValueTypes["LocaleInputType"] | undefined | null | Variable<any, string>},ValueTypes["WebAppBookmark"]],
updateWebAppBookmark?: [{	id: string | Variable<any, string>,	autosave?: boolean | undefined | null | Variable<any, string>,	data: ValueTypes["mutationWebAppBookmarkUpdateInput"] | Variable<any, string>,	locale?: ValueTypes["LocaleInputType"] | undefined | null | Variable<any, string>},ValueTypes["WebAppBookmark"]],
deleteWebAppBookmark?: [{	id: string | Variable<any, string>},ValueTypes["WebAppBookmark"]],
createViewRecord?: [{	data: ValueTypes["mutationViewRecordInput"] | Variable<any, string>,	locale?: ValueTypes["LocaleInputType"] | undefined | null | Variable<any, string>},ValueTypes["ViewRecord"]],
updateViewRecord?: [{	id: string | Variable<any, string>,	autosave?: boolean | undefined | null | Variable<any, string>,	data: ValueTypes["mutationViewRecordUpdateInput"] | Variable<any, string>,	locale?: ValueTypes["LocaleInputType"] | undefined | null | Variable<any, string>},ValueTypes["ViewRecord"]],
deleteViewRecord?: [{	id: string | Variable<any, string>},ValueTypes["ViewRecord"]],
createComment?: [{	data: ValueTypes["mutationCommentInput"] | Variable<any, string>,	locale?: ValueTypes["LocaleInputType"] | undefined | null | Variable<any, string>},ValueTypes["Comment"]],
updateComment?: [{	id: string | Variable<any, string>,	autosave?: boolean | undefined | null | Variable<any, string>,	data: ValueTypes["mutationCommentUpdateInput"] | Variable<any, string>,	locale?: ValueTypes["LocaleInputType"] | undefined | null | Variable<any, string>},ValueTypes["Comment"]],
deleteComment?: [{	id: string | Variable<any, string>},ValueTypes["Comment"]],
createSubscription?: [{	data: ValueTypes["mutationSubscriptionInput"] | Variable<any, string>,	locale?: ValueTypes["LocaleInputType"] | undefined | null | Variable<any, string>},ValueTypes["Subscription"]],
updateSubscription?: [{	id: string | Variable<any, string>,	autosave?: boolean | undefined | null | Variable<any, string>,	data: ValueTypes["mutationSubscriptionUpdateInput"] | Variable<any, string>,	locale?: ValueTypes["LocaleInputType"] | undefined | null | Variable<any, string>},ValueTypes["Subscription"]],
deleteSubscription?: [{	id: string | Variable<any, string>},ValueTypes["Subscription"]],
createNewsletter?: [{	data: ValueTypes["mutationNewsletterInput"] | Variable<any, string>,	locale?: ValueTypes["LocaleInputType"] | undefined | null | Variable<any, string>},ValueTypes["Newsletter"]],
updateNewsletter?: [{	id: string | Variable<any, string>,	autosave?: boolean | undefined | null | Variable<any, string>,	data: ValueTypes["mutationNewsletterUpdateInput"] | Variable<any, string>,	locale?: ValueTypes["LocaleInputType"] | undefined | null | Variable<any, string>},ValueTypes["Newsletter"]],
deleteNewsletter?: [{	id: string | Variable<any, string>},ValueTypes["Newsletter"]],
createBlogCategory?: [{	data: ValueTypes["mutationBlogCategoryInput"] | Variable<any, string>,	locale?: ValueTypes["LocaleInputType"] | undefined | null | Variable<any, string>},ValueTypes["BlogCategory"]],
updateBlogCategory?: [{	id: string | Variable<any, string>,	autosave?: boolean | undefined | null | Variable<any, string>,	data: ValueTypes["mutationBlogCategoryUpdateInput"] | Variable<any, string>,	locale?: ValueTypes["LocaleInputType"] | undefined | null | Variable<any, string>},ValueTypes["BlogCategory"]],
deleteBlogCategory?: [{	id: string | Variable<any, string>},ValueTypes["BlogCategory"]],
createTag?: [{	data: ValueTypes["mutationTagInput"] | Variable<any, string>,	locale?: ValueTypes["LocaleInputType"] | undefined | null | Variable<any, string>},ValueTypes["Tag"]],
updateTag?: [{	id: string | Variable<any, string>,	autosave?: boolean | undefined | null | Variable<any, string>,	data: ValueTypes["mutationTagUpdateInput"] | Variable<any, string>,	locale?: ValueTypes["LocaleInputType"] | undefined | null | Variable<any, string>},ValueTypes["Tag"]],
deleteTag?: [{	id: string | Variable<any, string>},ValueTypes["Tag"]],
createSpecialTag?: [{	data: ValueTypes["mutationSpecialTagInput"] | Variable<any, string>,	locale?: ValueTypes["LocaleInputType"] | undefined | null | Variable<any, string>},ValueTypes["SpecialTag"]],
updateSpecialTag?: [{	id: string | Variable<any, string>,	autosave?: boolean | undefined | null | Variable<any, string>,	data: ValueTypes["mutationSpecialTagUpdateInput"] | Variable<any, string>,	locale?: ValueTypes["LocaleInputType"] | undefined | null | Variable<any, string>},ValueTypes["SpecialTag"]],
deleteSpecialTag?: [{	id: string | Variable<any, string>},ValueTypes["SpecialTag"]],
createDate?: [{	data: ValueTypes["mutationDateInput"] | Variable<any, string>,	locale?: ValueTypes["LocaleInputType"] | undefined | null | Variable<any, string>},ValueTypes["Date"]],
updateDate?: [{	id: string | Variable<any, string>,	autosave?: boolean | undefined | null | Variable<any, string>,	data: ValueTypes["mutationDateUpdateInput"] | Variable<any, string>,	locale?: ValueTypes["LocaleInputType"] | undefined | null | Variable<any, string>},ValueTypes["Date"]],
deleteDate?: [{	id: string | Variable<any, string>},ValueTypes["Date"]],
createDistrict?: [{	data: ValueTypes["mutationDistrictInput"] | Variable<any, string>,	locale?: ValueTypes["LocaleInputType"] | undefined | null | Variable<any, string>},ValueTypes["District"]],
updateDistrict?: [{	id: string | Variable<any, string>,	autosave?: boolean | undefined | null | Variable<any, string>,	data: ValueTypes["mutationDistrictUpdateInput"] | Variable<any, string>,	locale?: ValueTypes["LocaleInputType"] | undefined | null | Variable<any, string>},ValueTypes["District"]],
deleteDistrict?: [{	id: string | Variable<any, string>},ValueTypes["District"]],
createAudience?: [{	data: ValueTypes["mutationAudienceInput"] | Variable<any, string>,	locale?: ValueTypes["LocaleInputType"] | undefined | null | Variable<any, string>},ValueTypes["Audience"]],
updateAudience?: [{	id: string | Variable<any, string>,	autosave?: boolean | undefined | null | Variable<any, string>,	data: ValueTypes["mutationAudienceUpdateInput"] | Variable<any, string>,	locale?: ValueTypes["LocaleInputType"] | undefined | null | Variable<any, string>},ValueTypes["Audience"]],
deleteAudience?: [{	id: string | Variable<any, string>},ValueTypes["Audience"]],
createEventCategory?: [{	data: ValueTypes["mutationEventCategoryInput"] | Variable<any, string>,	locale?: ValueTypes["LocaleInputType"] | undefined | null | Variable<any, string>},ValueTypes["EventCategory"]],
updateEventCategory?: [{	id: string | Variable<any, string>,	autosave?: boolean | undefined | null | Variable<any, string>,	data: ValueTypes["mutationEventCategoryUpdateInput"] | Variable<any, string>,	locale?: ValueTypes["LocaleInputType"] | undefined | null | Variable<any, string>},ValueTypes["EventCategory"]],
deleteEventCategory?: [{	id: string | Variable<any, string>},ValueTypes["EventCategory"]],
createPrivateMedia?: [{	data: ValueTypes["mutationPrivateMediaInput"] | Variable<any, string>,	locale?: ValueTypes["LocaleInputType"] | undefined | null | Variable<any, string>},ValueTypes["PrivateMedia"]],
updatePrivateMedia?: [{	id: string | Variable<any, string>,	autosave?: boolean | undefined | null | Variable<any, string>,	data: ValueTypes["mutationPrivateMediaUpdateInput"] | Variable<any, string>,	locale?: ValueTypes["LocaleInputType"] | undefined | null | Variable<any, string>},ValueTypes["PrivateMedia"]],
deletePrivateMedia?: [{	id: string | Variable<any, string>},ValueTypes["PrivateMedia"]],
createMedia?: [{	data: ValueTypes["mutationMediaInput"] | Variable<any, string>,	locale?: ValueTypes["LocaleInputType"] | undefined | null | Variable<any, string>},ValueTypes["Media"]],
updateMedia?: [{	id: string | Variable<any, string>,	autosave?: boolean | undefined | null | Variable<any, string>,	data: ValueTypes["mutationMediaUpdateInput"] | Variable<any, string>,	locale?: ValueTypes["LocaleInputType"] | undefined | null | Variable<any, string>},ValueTypes["Media"]],
deleteMedia?: [{	id: string | Variable<any, string>},ValueTypes["Media"]],
createBackupMedia?: [{	data: ValueTypes["mutationBackupMediaInput"] | Variable<any, string>,	locale?: ValueTypes["LocaleInputType"] | undefined | null | Variable<any, string>},ValueTypes["BackupMedia"]],
updateBackupMedia?: [{	id: string | Variable<any, string>,	autosave?: boolean | undefined | null | Variable<any, string>,	data: ValueTypes["mutationBackupMediaUpdateInput"] | Variable<any, string>,	locale?: ValueTypes["LocaleInputType"] | undefined | null | Variable<any, string>},ValueTypes["BackupMedia"]],
deleteBackupMedia?: [{	id: string | Variable<any, string>},ValueTypes["BackupMedia"]],
createAccountSuspendRequest?: [{	data: ValueTypes["mutationAccountSuspendRequestInput"] | Variable<any, string>,	locale?: ValueTypes["LocaleInputType"] | undefined | null | Variable<any, string>},ValueTypes["AccountSuspendRequest"]],
updateAccountSuspendRequest?: [{	id: string | Variable<any, string>,	autosave?: boolean | undefined | null | Variable<any, string>,	data: ValueTypes["mutationAccountSuspendRequestUpdateInput"] | Variable<any, string>,	locale?: ValueTypes["LocaleInputType"] | undefined | null | Variable<any, string>},ValueTypes["AccountSuspendRequest"]],
deleteAccountSuspendRequest?: [{	id: string | Variable<any, string>},ValueTypes["AccountSuspendRequest"]],
createDevice?: [{	data: ValueTypes["mutationDeviceInput"] | Variable<any, string>,	locale?: ValueTypes["LocaleInputType"] | undefined | null | Variable<any, string>},ValueTypes["Device"]],
updateDevice?: [{	id: string | Variable<any, string>,	autosave?: boolean | undefined | null | Variable<any, string>,	data: ValueTypes["mutationDeviceUpdateInput"] | Variable<any, string>,	locale?: ValueTypes["LocaleInputType"] | undefined | null | Variable<any, string>},ValueTypes["Device"]],
deleteDevice?: [{	id: string | Variable<any, string>},ValueTypes["Device"]],
createNotification?: [{	data: ValueTypes["mutationNotificationInput"] | Variable<any, string>,	locale?: ValueTypes["LocaleInputType"] | undefined | null | Variable<any, string>},ValueTypes["Notification"]],
updateNotification?: [{	id: string | Variable<any, string>,	autosave?: boolean | undefined | null | Variable<any, string>,	data: ValueTypes["mutationNotificationUpdateInput"] | Variable<any, string>,	locale?: ValueTypes["LocaleInputType"] | undefined | null | Variable<any, string>},ValueTypes["Notification"]],
deleteNotification?: [{	id: string | Variable<any, string>},ValueTypes["Notification"]],
createRedirectSetting?: [{	data: ValueTypes["mutationRedirectSettingInput"] | Variable<any, string>,	locale?: ValueTypes["LocaleInputType"] | undefined | null | Variable<any, string>},ValueTypes["RedirectSetting"]],
updateRedirectSetting?: [{	id: string | Variable<any, string>,	autosave?: boolean | undefined | null | Variable<any, string>,	data: ValueTypes["mutationRedirectSettingUpdateInput"] | Variable<any, string>,	locale?: ValueTypes["LocaleInputType"] | undefined | null | Variable<any, string>},ValueTypes["RedirectSetting"]],
deleteRedirectSetting?: [{	id: string | Variable<any, string>},ValueTypes["RedirectSetting"]],
createEventRanking?: [{	data: ValueTypes["mutationEventRankingInput"] | Variable<any, string>,	locale?: ValueTypes["LocaleInputType"] | undefined | null | Variable<any, string>},ValueTypes["EventRanking"]],
updateEventRanking?: [{	id: string | Variable<any, string>,	autosave?: boolean | undefined | null | Variable<any, string>,	data: ValueTypes["mutationEventRankingUpdateInput"] | Variable<any, string>,	locale?: ValueTypes["LocaleInputType"] | undefined | null | Variable<any, string>},ValueTypes["EventRanking"]],
deleteEventRanking?: [{	id: string | Variable<any, string>},ValueTypes["EventRanking"]],
createLocationRanking?: [{	data: ValueTypes["mutationLocationRankingInput"] | Variable<any, string>,	locale?: ValueTypes["LocaleInputType"] | undefined | null | Variable<any, string>},ValueTypes["LocationRanking"]],
updateLocationRanking?: [{	id: string | Variable<any, string>,	autosave?: boolean | undefined | null | Variable<any, string>,	data: ValueTypes["mutationLocationRankingUpdateInput"] | Variable<any, string>,	locale?: ValueTypes["LocaleInputType"] | undefined | null | Variable<any, string>},ValueTypes["LocationRanking"]],
deleteLocationRanking?: [{	id: string | Variable<any, string>},ValueTypes["LocationRanking"]],
createEventCalendar?: [{	data: ValueTypes["mutationEventCalendarInput"] | Variable<any, string>,	locale?: ValueTypes["LocaleInputType"] | undefined | null | Variable<any, string>},ValueTypes["EventCalendar"]],
updateEventCalendar?: [{	id: string | Variable<any, string>,	autosave?: boolean | undefined | null | Variable<any, string>,	data: ValueTypes["mutationEventCalendarUpdateInput"] | Variable<any, string>,	locale?: ValueTypes["LocaleInputType"] | undefined | null | Variable<any, string>},ValueTypes["EventCalendar"]],
deleteEventCalendar?: [{	id: string | Variable<any, string>},ValueTypes["EventCalendar"]],
createEditorChoice?: [{	data: ValueTypes["mutationEditorChoiceInput"] | Variable<any, string>,	locale?: ValueTypes["LocaleInputType"] | undefined | null | Variable<any, string>},ValueTypes["EditorChoice"]],
updateEditorChoice?: [{	id: string | Variable<any, string>,	autosave?: boolean | undefined | null | Variable<any, string>,	data: ValueTypes["mutationEditorChoiceUpdateInput"] | Variable<any, string>,	locale?: ValueTypes["LocaleInputType"] | undefined | null | Variable<any, string>},ValueTypes["EditorChoice"]],
deleteEditorChoice?: [{	id: string | Variable<any, string>},ValueTypes["EditorChoice"]],
createCriteriaSetting?: [{	data: ValueTypes["mutationCriteriaSettingInput"] | Variable<any, string>,	locale?: ValueTypes["LocaleInputType"] | undefined | null | Variable<any, string>},ValueTypes["CriteriaSetting"]],
updateCriteriaSetting?: [{	id: string | Variable<any, string>,	autosave?: boolean | undefined | null | Variable<any, string>,	data: ValueTypes["mutationCriteriaSettingUpdateInput"] | Variable<any, string>,	locale?: ValueTypes["LocaleInputType"] | undefined | null | Variable<any, string>},ValueTypes["CriteriaSetting"]],
deleteCriteriaSetting?: [{	id: string | Variable<any, string>},ValueTypes["CriteriaSetting"]],
createOrganiserSetting?: [{	data: ValueTypes["mutationOrganiserSettingInput"] | Variable<any, string>,	locale?: ValueTypes["LocaleInputType"] | undefined | null | Variable<any, string>},ValueTypes["OrganiserSetting"]],
updateOrganiserSetting?: [{	id: string | Variable<any, string>,	autosave?: boolean | undefined | null | Variable<any, string>,	data: ValueTypes["mutationOrganiserSettingUpdateInput"] | Variable<any, string>,	locale?: ValueTypes["LocaleInputType"] | undefined | null | Variable<any, string>},ValueTypes["OrganiserSetting"]],
deleteOrganiserSetting?: [{	id: string | Variable<any, string>},ValueTypes["OrganiserSetting"]],
createPostSetting?: [{	data: ValueTypes["mutationPostSettingInput"] | Variable<any, string>,	locale?: ValueTypes["LocaleInputType"] | undefined | null | Variable<any, string>},ValueTypes["PostSetting"]],
updatePostSetting?: [{	id: string | Variable<any, string>,	autosave?: boolean | undefined | null | Variable<any, string>,	data: ValueTypes["mutationPostSettingUpdateInput"] | Variable<any, string>,	locale?: ValueTypes["LocaleInputType"] | undefined | null | Variable<any, string>},ValueTypes["PostSetting"]],
deletePostSetting?: [{	id: string | Variable<any, string>},ValueTypes["PostSetting"]],
createHomepageSetting?: [{	data: ValueTypes["mutationHomepageSettingInput"] | Variable<any, string>,	locale?: ValueTypes["LocaleInputType"] | undefined | null | Variable<any, string>},ValueTypes["HomepageSetting"]],
updateHomepageSetting?: [{	id: string | Variable<any, string>,	autosave?: boolean | undefined | null | Variable<any, string>,	data: ValueTypes["mutationHomepageSettingUpdateInput"] | Variable<any, string>,	locale?: ValueTypes["LocaleInputType"] | undefined | null | Variable<any, string>},ValueTypes["HomepageSetting"]],
deleteHomepageSetting?: [{	id: string | Variable<any, string>},ValueTypes["HomepageSetting"]],
createBannerSection?: [{	data: ValueTypes["mutationBannerSectionInput"] | Variable<any, string>,	locale?: ValueTypes["LocaleInputType"] | undefined | null | Variable<any, string>},ValueTypes["BannerSection"]],
updateBannerSection?: [{	id: string | Variable<any, string>,	autosave?: boolean | undefined | null | Variable<any, string>,	data: ValueTypes["mutationBannerSectionUpdateInput"] | Variable<any, string>,	locale?: ValueTypes["LocaleInputType"] | undefined | null | Variable<any, string>},ValueTypes["BannerSection"]],
deleteBannerSection?: [{	id: string | Variable<any, string>},ValueTypes["BannerSection"]],
createMacaoBannerSection?: [{	data: ValueTypes["mutationMacaoBannerSectionInput"] | Variable<any, string>,	locale?: ValueTypes["LocaleInputType"] | undefined | null | Variable<any, string>},ValueTypes["MacaoBannerSection"]],
updateMacaoBannerSection?: [{	id: string | Variable<any, string>,	autosave?: boolean | undefined | null | Variable<any, string>,	data: ValueTypes["mutationMacaoBannerSectionUpdateInput"] | Variable<any, string>,	locale?: ValueTypes["LocaleInputType"] | undefined | null | Variable<any, string>},ValueTypes["MacaoBannerSection"]],
deleteMacaoBannerSection?: [{	id: string | Variable<any, string>},ValueTypes["MacaoBannerSection"]],
createBanner?: [{	data: ValueTypes["mutationBannerInput"] | Variable<any, string>,	locale?: ValueTypes["LocaleInputType"] | undefined | null | Variable<any, string>},ValueTypes["Banner"]],
updateBanner?: [{	id: string | Variable<any, string>,	autosave?: boolean | undefined | null | Variable<any, string>,	data: ValueTypes["mutationBannerUpdateInput"] | Variable<any, string>,	locale?: ValueTypes["LocaleInputType"] | undefined | null | Variable<any, string>},ValueTypes["Banner"]],
deleteBanner?: [{	id: string | Variable<any, string>},ValueTypes["Banner"]],
createCampaign?: [{	data: ValueTypes["mutationCampaignInput"] | Variable<any, string>,	locale?: ValueTypes["LocaleInputType"] | undefined | null | Variable<any, string>},ValueTypes["Campaign"]],
updateCampaign?: [{	id: string | Variable<any, string>,	autosave?: boolean | undefined | null | Variable<any, string>,	data: ValueTypes["mutationCampaignUpdateInput"] | Variable<any, string>,	locale?: ValueTypes["LocaleInputType"] | undefined | null | Variable<any, string>},ValueTypes["Campaign"]],
deleteCampaign?: [{	id: string | Variable<any, string>},ValueTypes["Campaign"]],
createAdvertise?: [{	data: ValueTypes["mutationAdvertiseInput"] | Variable<any, string>,	locale?: ValueTypes["LocaleInputType"] | undefined | null | Variable<any, string>},ValueTypes["Advertise"]],
updateAdvertise?: [{	id: string | Variable<any, string>,	autosave?: boolean | undefined | null | Variable<any, string>,	data: ValueTypes["mutationAdvertiseUpdateInput"] | Variable<any, string>,	locale?: ValueTypes["LocaleInputType"] | undefined | null | Variable<any, string>},ValueTypes["Advertise"]],
deleteAdvertise?: [{	id: string | Variable<any, string>},ValueTypes["Advertise"]],
createStatic?: [{	data: ValueTypes["mutationStaticInput"] | Variable<any, string>,	locale?: ValueTypes["LocaleInputType"] | undefined | null | Variable<any, string>},ValueTypes["Static"]],
updateStatic?: [{	id: string | Variable<any, string>,	autosave?: boolean | undefined | null | Variable<any, string>,	data: ValueTypes["mutationStaticUpdateInput"] | Variable<any, string>,	locale?: ValueTypes["LocaleInputType"] | undefined | null | Variable<any, string>},ValueTypes["Static"]],
deleteStatic?: [{	id: string | Variable<any, string>},ValueTypes["Static"]],
createEventView?: [{	data: ValueTypes["mutationEventViewInput"] | Variable<any, string>,	locale?: ValueTypes["LocaleInputType"] | undefined | null | Variable<any, string>},ValueTypes["EventView"]],
updateEventView?: [{	id: string | Variable<any, string>,	autosave?: boolean | undefined | null | Variable<any, string>,	data: ValueTypes["mutationEventViewUpdateInput"] | Variable<any, string>,	locale?: ValueTypes["LocaleInputType"] | undefined | null | Variable<any, string>},ValueTypes["EventView"]],
deleteEventView?: [{	id: string | Variable<any, string>},ValueTypes["EventView"]],
createPostView?: [{	data: ValueTypes["mutationPostViewInput"] | Variable<any, string>,	locale?: ValueTypes["LocaleInputType"] | undefined | null | Variable<any, string>},ValueTypes["PostView"]],
updatePostView?: [{	id: string | Variable<any, string>,	autosave?: boolean | undefined | null | Variable<any, string>,	data: ValueTypes["mutationPostViewUpdateInput"] | Variable<any, string>,	locale?: ValueTypes["LocaleInputType"] | undefined | null | Variable<any, string>},ValueTypes["PostView"]],
deletePostView?: [{	id: string | Variable<any, string>},ValueTypes["PostView"]],
createPayloadPreference?: [{	data: ValueTypes["mutationPayloadPreferenceInput"] | Variable<any, string>,	locale?: ValueTypes["LocaleInputType"] | undefined | null | Variable<any, string>},ValueTypes["PayloadPreference"]],
updatePayloadPreference?: [{	id: string | Variable<any, string>,	autosave?: boolean | undefined | null | Variable<any, string>,	data: ValueTypes["mutationPayloadPreferenceUpdateInput"] | Variable<any, string>,	locale?: ValueTypes["LocaleInputType"] | undefined | null | Variable<any, string>},ValueTypes["PayloadPreference"]],
deletePayloadPreference?: [{	id: string | Variable<any, string>},ValueTypes["PayloadPreference"]],
AddEventView?: [{	id?: string | undefined | null | Variable<any, string>,	type?: string | undefined | null | Variable<any, string>},boolean | `@${string}`],
ItemBookmark?: [{	id?: string | undefined | null | Variable<any, string>,	relationTo?: string | undefined | null | Variable<any, string>},boolean | `@${string}`],
ItemUnbookmark?: [{	id?: string | undefined | null | Variable<any, string>,	relationTo?: string | undefined | null | Variable<any, string>},boolean | `@${string}`],
readItem?: [{	relationTo?: ValueTypes["ViewRecord_ItemRelationshipInputRelationTo"] | undefined | null | Variable<any, string>,	value?: ValueTypes["JSON"] | undefined | null | Variable<any, string>},boolean | `@${string}`],
AddPostView?: [{	id?: string | undefined | null | Variable<any, string>,	type?: string | undefined | null | Variable<any, string>},boolean | `@${string}`],
LoginUser?: [{	email?: string | undefined | null | Variable<any, string>,	password?: string | undefined | null | Variable<any, string>,	sha1Password?: string | undefined | null | Variable<any, string>},ValueTypes["usersLoginResult"]],
RegisterUser?: [{	data: ValueTypes["mutationUserInput"] | Variable<any, string>,	locale?: ValueTypes["LocaleInputType"] | undefined | null | Variable<any, string>,	subscribeNewsletter?: boolean | undefined | null | Variable<any, string>},ValueTypes["User"]],
ActiveUser?: [{	email?: string | undefined | null | Variable<any, string>},boolean | `@${string}`],
ReActivePasswordUser?: [{	username?: string | undefined | null | Variable<any, string>,	email?: string | undefined | null | Variable<any, string>},boolean | `@${string}`],
SSOLoginUser?: [{	accessToken?: string | undefined | null | Variable<any, string>,	type?: string | undefined | null | Variable<any, string>,	appleJwtClaims?: string | undefined | null | Variable<any, string>},ValueTypes["usersLoginResult"]],
	SuspendUser?:boolean | `@${string}`,
UpdateUserPassword?: [{	email?: string | undefined | null | Variable<any, string>,	password?: string | undefined | null | Variable<any, string>},boolean | `@${string}`],
SubscribeOrganiser?: [{	id?: string | undefined | null | Variable<any, string>,	relationTo?: string | undefined | null | Variable<any, string>},boolean | `@${string}`],
UnSubscribeOrganiser?: [{	id?: string | undefined | null | Variable<any, string>,	relationTo?: string | undefined | null | Variable<any, string>},boolean | `@${string}`],
PostMessage?: [{	relationTo?: string | undefined | null | Variable<any, string>,	content?: string | undefined | null | Variable<any, string>,	image?: string | undefined | null | Variable<any, string>,	parent?: string | undefined | null | Variable<any, string>,	type?: string | undefined | null | Variable<any, string>},boolean | `@${string}`],
AddBannerView?: [{	id?: string | undefined | null | Variable<any, string>,	type?: string | undefined | null | Variable<any, string>},boolean | `@${string}`],
AddDevice?: [{	id?: string | undefined | null | Variable<any, string>,	udid?: string | undefined | null | Variable<any, string>,	pushToken?: string | undefined | null | Variable<any, string>},boolean | `@${string}`],
RemoveDevice?: [{	udid?: string | undefined | null | Variable<any, string>,	pushToken?: string | undefined | null | Variable<any, string>},boolean | `@${string}`],
UpdateNotifications?: [{	userId?: string | undefined | null | Variable<any, string>},boolean | `@${string}`],
DeleteNotification?: [{	userId?: string | undefined | null | Variable<any, string>,	notificationId?: string | undefined | null | Variable<any, string>},boolean | `@${string}`],
CreateNewsletter?: [{	email?: string | undefined | null | Variable<any, string>},boolean | `@${string}`],
ChatbotInventoryHold?: [{	publicUserPhone: string | Variable<any, string>,	variantId: string | Variable<any, string>,	quantity: number | Variable<any, string>},ValueTypes["InventoryStatus"]],
ChatbotInventoryRelease?: [{	inventoryStatusIds?: Array<string | undefined | null> | undefined | null | Variable<any, string>},boolean | `@${string}`],
ChatbotInventoryPayment?: [{	inventoryStatusIds: Array<string> | Variable<any, string>},ValueTypes["ChatbotInventoryPayment"]],
ChatbotTransactionCreate?: [{	inventoryStatusIds: Array<string> | Variable<any, string>,	purchaseDateTime: string | Variable<any, string>,	stripePaymentId: string | Variable<any, string>,	totalAmount: number | Variable<any, string>,	contactPersonName: string | Variable<any, string>,	contactPersonPhone: string | Variable<any, string>,	remarks?: string | undefined | null | Variable<any, string>},ValueTypes["Transaction"]],
ChatbotRedemptionCodeUpdate?: [{	code: string | Variable<any, string>,	ownerName: string | Variable<any, string>},boolean | `@${string}`],
ChatbotTransactionPdfGet?: [{	code: string | Variable<any, string>},ValueTypes["ChatbotTransactionPdfGet"]],
WebAppQrCodeBatchScan?: [{	scans: Array<ValueTypes["WebAppQrCodeBatchScanItem"]> | Variable<any, string>},ValueTypes["WebAppQrCodeBatchScanResult"]],
WebAppQrCodeScan?: [{	checkInAppSelectionId: string | Variable<any, string>,	checkInAppSelectionProductType: string | Variable<any, string>,	qrCodeId: string | Variable<any, string>,	scanAt: string | Variable<any, string>,	uploadAt?: string | undefined | null | Variable<any, string>,	entryNumber: string | Variable<any, string>},ValueTypes["WebAppQrCodeScanResponse"]],
WebAppItemBookmark?: [{	userId: string | Variable<any, string>,	eventId: string | Variable<any, string>,	productType: string | Variable<any, string>,	purchaseItemId: string | Variable<any, string>,	entryNumber: string | Variable<any, string>},ValueTypes["WebAppBookmark"]],
WebAppItemUnBookmark?: [{	userId: string | Variable<any, string>,	eventId: string | Variable<any, string>,	productType: string | Variable<any, string>,	purchaseItemId: string | Variable<any, string>,	entryNumber: string | Variable<any, string>},ValueTypes["WebAppBookmark"]],
		__typename?: boolean | `@${string}`
}>;
	["mutationEventInput"]: {
	region?: ValueTypes["Event_region_MutationInput"] | undefined | null | Variable<any, string>,
	creator?: string | undefined | null | Variable<any, string>,
	permalink?: string | undefined | null | Variable<any, string>,
	status: string | Variable<any, string>,
	level?: ValueTypes["Event_level_MutationInput"] | undefined | null | Variable<any, string>,
	parent?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	name?: string | undefined | null | Variable<any, string>,
	chatbotEventTitle?: string | undefined | null | Variable<any, string>,
	chatbotSequence?: number | undefined | null | Variable<any, string>,
	thumbnail?: string | undefined | null | Variable<any, string>,
	banner?: string | undefined | null | Variable<any, string>,
	mobileBanner?: string | undefined | null | Variable<any, string>,
	sections?: Array<ValueTypes["mutationEvent_SectionsInput"] | undefined | null> | undefined | null | Variable<any, string>,
	ticketing?: Array<ValueTypes["mutationEvent_TicketingInput"] | undefined | null> | undefined | null | Variable<any, string>,
	content?: ValueTypes["JSON"] | undefined | null | Variable<any, string>,
	legacyContent?: string | undefined | null | Variable<any, string>,
	showLegacyContent?: boolean | undefined | null | Variable<any, string>,
	criteria: ValueTypes["mutationEvent_CriteriaInput"] | Variable<any, string>,
	walkIn?: boolean | undefined | null | Variable<any, string>,
	contact?: ValueTypes["mutationEvent_ContactInput"] | undefined | null | Variable<any, string>,
	views?: number | undefined | null | Variable<any, string>,
	free?: boolean | undefined | null | Variable<any, string>,
	fees?: Array<ValueTypes["mutationEvent_FeesInput"] | undefined | null> | undefined | null | Variable<any, string>,
	homePageInternalClick?: number | undefined | null | Variable<any, string>,
	homePageImpression?: number | undefined | null | Variable<any, string>,
	eodClickRate?: number | undefined | null | Variable<any, string>,
	eodImpressionRate?: number | undefined | null | Variable<any, string>,
	top10ClickRateHomepage?: number | undefined | null | Variable<any, string>,
	top10ImpressionkRateHomepage?: number | undefined | null | Variable<any, string>,
	otherEventRankingClickRateHomepage?: number | undefined | null | Variable<any, string>,
	otherEventRankingImpressionHomePage?: number | undefined | null | Variable<any, string>,
	userPreferenceClickRate?: number | undefined | null | Variable<any, string>,
	userPreferenceImpression?: number | undefined | null | Variable<any, string>,
	eventSearchInternalClick?: number | undefined | null | Variable<any, string>,
	eventListImpression?: number | undefined | null | Variable<any, string>,
	latestEventInternalClick?: number | undefined | null | Variable<any, string>,
	latestEventImpression?: number | undefined | null | Variable<any, string>,
	topTenEventRankingInternalClick?: number | undefined | null | Variable<any, string>,
	topTenImpression?: number | undefined | null | Variable<any, string>,
	editorChoiceInternalClick?: number | undefined | null | Variable<any, string>,
	editorChoiceImpression?: number | undefined | null | Variable<any, string>,
	otherEventRankingInternalClick?: number | undefined | null | Variable<any, string>,
	otherEventRankingImpression?: number | undefined | null | Variable<any, string>,
	bannerInternalClick?: number | undefined | null | Variable<any, string>,
	miscImpression?: number | undefined | null | Variable<any, string>,
	miscInternalClick?: number | undefined | null | Variable<any, string>,
	totalImpression?: number | undefined | null | Variable<any, string>,
	internalClick?: number | undefined | null | Variable<any, string>,
	outboundClick?: number | undefined | null | Variable<any, string>,
	externalClick?: number | undefined | null | Variable<any, string>,
	_viewsCountByMonth?: number | undefined | null | Variable<any, string>,
	products?: Array<ValueTypes["mutationEvent_ProductsInput"] | undefined | null> | undefined | null | Variable<any, string>,
	legacyGallery?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	earliestStartDate?: string | undefined | null | Variable<any, string>,
	latestEndDate?: string | undefined | null | Variable<any, string>,
	latestStartDateTime?: string | undefined | null | Variable<any, string>,
	hasTicketing?: boolean | undefined | null | Variable<any, string>,
	hasProducts?: boolean | undefined | null | Variable<any, string>,
	updatedAt?: string | undefined | null | Variable<any, string>,
	createdAt?: string | undefined | null | Variable<any, string>
};
	["Event_region_MutationInput"]:Event_region_MutationInput;
	["Event_level_MutationInput"]:Event_level_MutationInput;
	["mutationEvent_SectionsInput"]: {
	startDatetime: string | Variable<any, string>,
	endDatetime?: string | undefined | null | Variable<any, string>,
	fullDay?: boolean | undefined | null | Variable<any, string>,
	repeat?: boolean | undefined | null | Variable<any, string>,
	toThisDay?: string | undefined | null | Variable<any, string>,
	recurrance: ValueTypes["mutationEvent_Sections_RecurranceInput"] | Variable<any, string>,
	sameLocation?: boolean | undefined | null | Variable<any, string>,
	location?: string | undefined | null | Variable<any, string>,
	district?: string | undefined | null | Variable<any, string>,
	coordinate?: Array<number | undefined | null> | undefined | null | Variable<any, string>,
	linkAddress?: boolean | undefined | null | Variable<any, string>,
	address?: string | undefined | null | Variable<any, string>,
	addressCoordinate?: Array<number | undefined | null> | undefined | null | Variable<any, string>,
	id?: string | undefined | null | Variable<any, string>
};
	["mutationEvent_Sections_RecurranceInput"]: {
	type: ValueTypes["Event_Sections_Recurrance_type_MutationInput"] | Variable<any, string>,
	weekday?: Array<ValueTypes["Event_Sections_Recurrance_weekday_MutationInput"] | undefined | null> | undefined | null | Variable<any, string>
};
	["Event_Sections_Recurrance_type_MutationInput"]:Event_Sections_Recurrance_type_MutationInput;
	["Event_Sections_Recurrance_weekday_MutationInput"]:Event_Sections_Recurrance_weekday_MutationInput;
	["mutationEvent_TicketingInput"]: {
	startDatetime: string | Variable<any, string>,
	endDatetime?: string | undefined | null | Variable<any, string>,
	fullDay?: boolean | undefined | null | Variable<any, string>,
	type: ValueTypes["Event_Ticketing_type_MutationInput"] | Variable<any, string>,
	ticketingType?: ValueTypes["Event_Ticketing_ticketingType_MutationInput"] | undefined | null | Variable<any, string>,
	linkedTicketing?: string | undefined | null | Variable<any, string>,
	promotionType?: ValueTypes["Event_Ticketing_promotionType_MutationInput"] | undefined | null | Variable<any, string>,
	location?: string | undefined | null | Variable<any, string>,
	website?: string | undefined | null | Variable<any, string>,
	description?: string | undefined | null | Variable<any, string>,
	id?: string | undefined | null | Variable<any, string>
};
	["Event_Ticketing_type_MutationInput"]:Event_Ticketing_type_MutationInput;
	["Event_Ticketing_ticketingType_MutationInput"]:Event_Ticketing_ticketingType_MutationInput;
	["Event_Ticketing_promotionType_MutationInput"]:Event_Ticketing_promotionType_MutationInput;
	["mutationEvent_CriteriaInput"]: {
	categories?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	audiences?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	tags?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	specialTags?: Array<string | undefined | null> | undefined | null | Variable<any, string>
};
	["mutationEvent_ContactInput"]: {
	code?: number | undefined | null | Variable<any, string>,
	phone?: string | undefined | null | Variable<any, string>,
	page?: string | undefined | null | Variable<any, string>,
	email?: string | undefined | null | Variable<any, string>,
	privacy?: ValueTypes["JSON"] | undefined | null | Variable<any, string>
};
	["mutationEvent_FeesInput"]: {
	fee?: number | undefined | null | Variable<any, string>,
	remark?: string | undefined | null | Variable<any, string>,
	id?: string | undefined | null | Variable<any, string>
};
	["mutationEvent_ProductsInput"]: {
	startDateTime: string | Variable<any, string>,
	endDateTime?: string | undefined | null | Variable<any, string>,
	fullDay?: boolean | undefined | null | Variable<any, string>,
	linkedProduct?: string | undefined | null | Variable<any, string>,
	id?: string | undefined | null | Variable<any, string>
};
	["mutationEventUpdateInput"]: {
	region?: ValueTypes["EventUpdate_region_MutationInput"] | undefined | null | Variable<any, string>,
	creator?: string | undefined | null | Variable<any, string>,
	permalink?: string | undefined | null | Variable<any, string>,
	status?: string | undefined | null | Variable<any, string>,
	level?: ValueTypes["EventUpdate_level_MutationInput"] | undefined | null | Variable<any, string>,
	parent?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	name?: string | undefined | null | Variable<any, string>,
	chatbotEventTitle?: string | undefined | null | Variable<any, string>,
	chatbotSequence?: number | undefined | null | Variable<any, string>,
	thumbnail?: string | undefined | null | Variable<any, string>,
	banner?: string | undefined | null | Variable<any, string>,
	mobileBanner?: string | undefined | null | Variable<any, string>,
	sections?: Array<ValueTypes["mutationEventUpdate_SectionsInput"] | undefined | null> | undefined | null | Variable<any, string>,
	ticketing?: Array<ValueTypes["mutationEventUpdate_TicketingInput"] | undefined | null> | undefined | null | Variable<any, string>,
	content?: ValueTypes["JSON"] | undefined | null | Variable<any, string>,
	legacyContent?: string | undefined | null | Variable<any, string>,
	showLegacyContent?: boolean | undefined | null | Variable<any, string>,
	criteria: ValueTypes["mutationEventUpdate_CriteriaInput"] | Variable<any, string>,
	walkIn?: boolean | undefined | null | Variable<any, string>,
	contact?: ValueTypes["mutationEventUpdate_ContactInput"] | undefined | null | Variable<any, string>,
	views?: number | undefined | null | Variable<any, string>,
	free?: boolean | undefined | null | Variable<any, string>,
	fees?: Array<ValueTypes["mutationEventUpdate_FeesInput"] | undefined | null> | undefined | null | Variable<any, string>,
	homePageInternalClick?: number | undefined | null | Variable<any, string>,
	homePageImpression?: number | undefined | null | Variable<any, string>,
	eodClickRate?: number | undefined | null | Variable<any, string>,
	eodImpressionRate?: number | undefined | null | Variable<any, string>,
	top10ClickRateHomepage?: number | undefined | null | Variable<any, string>,
	top10ImpressionkRateHomepage?: number | undefined | null | Variable<any, string>,
	otherEventRankingClickRateHomepage?: number | undefined | null | Variable<any, string>,
	otherEventRankingImpressionHomePage?: number | undefined | null | Variable<any, string>,
	userPreferenceClickRate?: number | undefined | null | Variable<any, string>,
	userPreferenceImpression?: number | undefined | null | Variable<any, string>,
	eventSearchInternalClick?: number | undefined | null | Variable<any, string>,
	eventListImpression?: number | undefined | null | Variable<any, string>,
	latestEventInternalClick?: number | undefined | null | Variable<any, string>,
	latestEventImpression?: number | undefined | null | Variable<any, string>,
	topTenEventRankingInternalClick?: number | undefined | null | Variable<any, string>,
	topTenImpression?: number | undefined | null | Variable<any, string>,
	editorChoiceInternalClick?: number | undefined | null | Variable<any, string>,
	editorChoiceImpression?: number | undefined | null | Variable<any, string>,
	otherEventRankingInternalClick?: number | undefined | null | Variable<any, string>,
	otherEventRankingImpression?: number | undefined | null | Variable<any, string>,
	bannerInternalClick?: number | undefined | null | Variable<any, string>,
	miscImpression?: number | undefined | null | Variable<any, string>,
	miscInternalClick?: number | undefined | null | Variable<any, string>,
	totalImpression?: number | undefined | null | Variable<any, string>,
	internalClick?: number | undefined | null | Variable<any, string>,
	outboundClick?: number | undefined | null | Variable<any, string>,
	externalClick?: number | undefined | null | Variable<any, string>,
	_viewsCountByMonth?: number | undefined | null | Variable<any, string>,
	products?: Array<ValueTypes["mutationEventUpdate_ProductsInput"] | undefined | null> | undefined | null | Variable<any, string>,
	legacyGallery?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	earliestStartDate?: string | undefined | null | Variable<any, string>,
	latestEndDate?: string | undefined | null | Variable<any, string>,
	latestStartDateTime?: string | undefined | null | Variable<any, string>,
	hasTicketing?: boolean | undefined | null | Variable<any, string>,
	hasProducts?: boolean | undefined | null | Variable<any, string>,
	updatedAt?: string | undefined | null | Variable<any, string>,
	createdAt?: string | undefined | null | Variable<any, string>
};
	["EventUpdate_region_MutationInput"]:EventUpdate_region_MutationInput;
	["EventUpdate_level_MutationInput"]:EventUpdate_level_MutationInput;
	["mutationEventUpdate_SectionsInput"]: {
	startDatetime: string | Variable<any, string>,
	endDatetime?: string | undefined | null | Variable<any, string>,
	fullDay?: boolean | undefined | null | Variable<any, string>,
	repeat?: boolean | undefined | null | Variable<any, string>,
	toThisDay?: string | undefined | null | Variable<any, string>,
	recurrance: ValueTypes["mutationEventUpdate_Sections_RecurranceInput"] | Variable<any, string>,
	sameLocation?: boolean | undefined | null | Variable<any, string>,
	location?: string | undefined | null | Variable<any, string>,
	district?: string | undefined | null | Variable<any, string>,
	coordinate?: Array<number | undefined | null> | undefined | null | Variable<any, string>,
	linkAddress?: boolean | undefined | null | Variable<any, string>,
	address?: string | undefined | null | Variable<any, string>,
	addressCoordinate?: Array<number | undefined | null> | undefined | null | Variable<any, string>,
	id?: string | undefined | null | Variable<any, string>
};
	["mutationEventUpdate_Sections_RecurranceInput"]: {
	type: ValueTypes["EventUpdate_Sections_Recurrance_type_MutationInput"] | Variable<any, string>,
	weekday?: Array<ValueTypes["EventUpdate_Sections_Recurrance_weekday_MutationInput"] | undefined | null> | undefined | null | Variable<any, string>
};
	["EventUpdate_Sections_Recurrance_type_MutationInput"]:EventUpdate_Sections_Recurrance_type_MutationInput;
	["EventUpdate_Sections_Recurrance_weekday_MutationInput"]:EventUpdate_Sections_Recurrance_weekday_MutationInput;
	["mutationEventUpdate_TicketingInput"]: {
	startDatetime: string | Variable<any, string>,
	endDatetime?: string | undefined | null | Variable<any, string>,
	fullDay?: boolean | undefined | null | Variable<any, string>,
	type: ValueTypes["EventUpdate_Ticketing_type_MutationInput"] | Variable<any, string>,
	ticketingType?: ValueTypes["EventUpdate_Ticketing_ticketingType_MutationInput"] | undefined | null | Variable<any, string>,
	linkedTicketing?: string | undefined | null | Variable<any, string>,
	promotionType?: ValueTypes["EventUpdate_Ticketing_promotionType_MutationInput"] | undefined | null | Variable<any, string>,
	location?: string | undefined | null | Variable<any, string>,
	website?: string | undefined | null | Variable<any, string>,
	description?: string | undefined | null | Variable<any, string>,
	id?: string | undefined | null | Variable<any, string>
};
	["EventUpdate_Ticketing_type_MutationInput"]:EventUpdate_Ticketing_type_MutationInput;
	["EventUpdate_Ticketing_ticketingType_MutationInput"]:EventUpdate_Ticketing_ticketingType_MutationInput;
	["EventUpdate_Ticketing_promotionType_MutationInput"]:EventUpdate_Ticketing_promotionType_MutationInput;
	["mutationEventUpdate_CriteriaInput"]: {
	categories?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	audiences?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	tags?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	specialTags?: Array<string | undefined | null> | undefined | null | Variable<any, string>
};
	["mutationEventUpdate_ContactInput"]: {
	code?: number | undefined | null | Variable<any, string>,
	phone?: string | undefined | null | Variable<any, string>,
	page?: string | undefined | null | Variable<any, string>,
	email?: string | undefined | null | Variable<any, string>,
	privacy?: ValueTypes["JSON"] | undefined | null | Variable<any, string>
};
	["mutationEventUpdate_FeesInput"]: {
	fee?: number | undefined | null | Variable<any, string>,
	remark?: string | undefined | null | Variable<any, string>,
	id?: string | undefined | null | Variable<any, string>
};
	["mutationEventUpdate_ProductsInput"]: {
	startDateTime: string | Variable<any, string>,
	endDateTime?: string | undefined | null | Variable<any, string>,
	fullDay?: boolean | undefined | null | Variable<any, string>,
	linkedProduct?: string | undefined | null | Variable<any, string>,
	id?: string | undefined | null | Variable<any, string>
};
	["mutationLocationInput"]: {
	region?: ValueTypes["Location_region_MutationInput"] | undefined | null | Variable<any, string>,
	creator?: string | undefined | null | Variable<any, string>,
	permalink?: string | undefined | null | Variable<any, string>,
	status: string | Variable<any, string>,
	level?: ValueTypes["Location_level_MutationInput"] | undefined | null | Variable<any, string>,
	parent?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	name?: string | undefined | null | Variable<any, string>,
	thumbnail?: string | undefined | null | Variable<any, string>,
	banner?: string | undefined | null | Variable<any, string>,
	type: ValueTypes["Location_type_MutationInput"] | Variable<any, string>,
	category: ValueTypes["Location_category_MutationInput"] | Variable<any, string>,
	district?: string | undefined | null | Variable<any, string>,
	coordinate?: Array<number | undefined | null> | undefined | null | Variable<any, string>,
	linkAddress?: boolean | undefined | null | Variable<any, string>,
	address?: string | undefined | null | Variable<any, string>,
	addressCoordinate?: Array<number | undefined | null> | undefined | null | Variable<any, string>,
	permanant?: boolean | undefined | null | Variable<any, string>,
	Details?: ValueTypes["mutationLocation_DetailsInput"] | undefined | null | Variable<any, string>,
	content?: ValueTypes["JSON"] | undefined | null | Variable<any, string>,
	legacyContent?: string | undefined | null | Variable<any, string>,
	showLegacyContent?: boolean | undefined | null | Variable<any, string>,
	criteria?: ValueTypes["mutationLocation_CriteriaInput"] | undefined | null | Variable<any, string>,
	sections?: Array<ValueTypes["mutationLocation_SectionsInput"] | undefined | null> | undefined | null | Variable<any, string>,
	views?: number | undefined | null | Variable<any, string>,
	_title?: string | undefined | null | Variable<any, string>,
	legacyGallery?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	updatedAt?: string | undefined | null | Variable<any, string>,
	createdAt?: string | undefined | null | Variable<any, string>
};
	["Location_region_MutationInput"]:Location_region_MutationInput;
	["Location_level_MutationInput"]:Location_level_MutationInput;
	["Location_type_MutationInput"]:Location_type_MutationInput;
	["Location_category_MutationInput"]:Location_category_MutationInput;
	["mutationLocation_DetailsInput"]: {
	code?: number | undefined | null | Variable<any, string>,
	phone?: string | undefined | null | Variable<any, string>,
	page?: string | undefined | null | Variable<any, string>,
	email?: string | undefined | null | Variable<any, string>
};
	["mutationLocation_CriteriaInput"]: {
	audiences?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	dates?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	categories?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	tags?: Array<string | undefined | null> | undefined | null | Variable<any, string>
};
	["mutationLocation_SectionsInput"]: {
	startDatetime?: string | undefined | null | Variable<any, string>,
	endDatetime?: string | undefined | null | Variable<any, string>,
	fullDay?: boolean | undefined | null | Variable<any, string>,
	repeat?: boolean | undefined | null | Variable<any, string>,
	recurrance: ValueTypes["mutationLocation_Sections_RecurranceInput"] | Variable<any, string>,
	id?: string | undefined | null | Variable<any, string>
};
	["mutationLocation_Sections_RecurranceInput"]: {
	type: ValueTypes["Location_Sections_Recurrance_type_MutationInput"] | Variable<any, string>,
	weekday?: Array<ValueTypes["Location_Sections_Recurrance_weekday_MutationInput"] | undefined | null> | undefined | null | Variable<any, string>
};
	["Location_Sections_Recurrance_type_MutationInput"]:Location_Sections_Recurrance_type_MutationInput;
	["Location_Sections_Recurrance_weekday_MutationInput"]:Location_Sections_Recurrance_weekday_MutationInput;
	["mutationLocationUpdateInput"]: {
	region?: ValueTypes["LocationUpdate_region_MutationInput"] | undefined | null | Variable<any, string>,
	creator?: string | undefined | null | Variable<any, string>,
	permalink?: string | undefined | null | Variable<any, string>,
	status?: string | undefined | null | Variable<any, string>,
	level?: ValueTypes["LocationUpdate_level_MutationInput"] | undefined | null | Variable<any, string>,
	parent?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	name?: string | undefined | null | Variable<any, string>,
	thumbnail?: string | undefined | null | Variable<any, string>,
	banner?: string | undefined | null | Variable<any, string>,
	type?: ValueTypes["LocationUpdate_type_MutationInput"] | undefined | null | Variable<any, string>,
	category?: ValueTypes["LocationUpdate_category_MutationInput"] | undefined | null | Variable<any, string>,
	district?: string | undefined | null | Variable<any, string>,
	coordinate?: Array<number | undefined | null> | undefined | null | Variable<any, string>,
	linkAddress?: boolean | undefined | null | Variable<any, string>,
	address?: string | undefined | null | Variable<any, string>,
	addressCoordinate?: Array<number | undefined | null> | undefined | null | Variable<any, string>,
	permanant?: boolean | undefined | null | Variable<any, string>,
	Details?: ValueTypes["mutationLocationUpdate_DetailsInput"] | undefined | null | Variable<any, string>,
	content?: ValueTypes["JSON"] | undefined | null | Variable<any, string>,
	legacyContent?: string | undefined | null | Variable<any, string>,
	showLegacyContent?: boolean | undefined | null | Variable<any, string>,
	criteria?: ValueTypes["mutationLocationUpdate_CriteriaInput"] | undefined | null | Variable<any, string>,
	sections?: Array<ValueTypes["mutationLocationUpdate_SectionsInput"] | undefined | null> | undefined | null | Variable<any, string>,
	views?: number | undefined | null | Variable<any, string>,
	_title?: string | undefined | null | Variable<any, string>,
	legacyGallery?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	updatedAt?: string | undefined | null | Variable<any, string>,
	createdAt?: string | undefined | null | Variable<any, string>
};
	["LocationUpdate_region_MutationInput"]:LocationUpdate_region_MutationInput;
	["LocationUpdate_level_MutationInput"]:LocationUpdate_level_MutationInput;
	["LocationUpdate_type_MutationInput"]:LocationUpdate_type_MutationInput;
	["LocationUpdate_category_MutationInput"]:LocationUpdate_category_MutationInput;
	["mutationLocationUpdate_DetailsInput"]: {
	code?: number | undefined | null | Variable<any, string>,
	phone?: string | undefined | null | Variable<any, string>,
	page?: string | undefined | null | Variable<any, string>,
	email?: string | undefined | null | Variable<any, string>
};
	["mutationLocationUpdate_CriteriaInput"]: {
	audiences?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	dates?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	categories?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	tags?: Array<string | undefined | null> | undefined | null | Variable<any, string>
};
	["mutationLocationUpdate_SectionsInput"]: {
	startDatetime?: string | undefined | null | Variable<any, string>,
	endDatetime?: string | undefined | null | Variable<any, string>,
	fullDay?: boolean | undefined | null | Variable<any, string>,
	repeat?: boolean | undefined | null | Variable<any, string>,
	recurrance: ValueTypes["mutationLocationUpdate_Sections_RecurranceInput"] | Variable<any, string>,
	id?: string | undefined | null | Variable<any, string>
};
	["mutationLocationUpdate_Sections_RecurranceInput"]: {
	type: ValueTypes["LocationUpdate_Sections_Recurrance_type_MutationInput"] | Variable<any, string>,
	weekday?: Array<ValueTypes["LocationUpdate_Sections_Recurrance_weekday_MutationInput"] | undefined | null> | undefined | null | Variable<any, string>
};
	["LocationUpdate_Sections_Recurrance_type_MutationInput"]:LocationUpdate_Sections_Recurrance_type_MutationInput;
	["LocationUpdate_Sections_Recurrance_weekday_MutationInput"]:LocationUpdate_Sections_Recurrance_weekday_MutationInput;
	["mutationPostInput"]: {
	title?: string | undefined | null | Variable<any, string>,
	region?: ValueTypes["Post_region_MutationInput"] | undefined | null | Variable<any, string>,
	authur?: string | undefined | null | Variable<any, string>,
	publishDateTime?: string | undefined | null | Variable<any, string>,
	permalink?: string | undefined | null | Variable<any, string>,
	status: string | Variable<any, string>,
	content?: ValueTypes["JSON"] | undefined | null | Variable<any, string>,
	legacyContent?: string | undefined | null | Variable<any, string>,
	showLegacyContent?: boolean | undefined | null | Variable<any, string>,
	blogCategories?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	thumbnail?: string | undefined | null | Variable<any, string>,
	banner?: string | undefined | null | Variable<any, string>,
	criteria?: ValueTypes["mutationPost_CriteriaInput"] | undefined | null | Variable<any, string>,
	location?: string | undefined | null | Variable<any, string>,
	views?: number | undefined | null | Variable<any, string>,
	homePageInternalClick?: number | undefined | null | Variable<any, string>,
	homePageImpression?: number | undefined | null | Variable<any, string>,
	blogListInternalClick?: number | undefined | null | Variable<any, string>,
	blogListImpression?: number | undefined | null | Variable<any, string>,
	blogDetailInternalClick?: number | undefined | null | Variable<any, string>,
	blogDetailImpression?: number | undefined | null | Variable<any, string>,
	relatedEventInternalClick?: number | undefined | null | Variable<any, string>,
	relatedEventImpression?: number | undefined | null | Variable<any, string>,
	latestEventInternalClick?: number | undefined | null | Variable<any, string>,
	latestEventImpression?: number | undefined | null | Variable<any, string>,
	internalClick?: number | undefined | null | Variable<any, string>,
	totalImpression?: number | undefined | null | Variable<any, string>,
	externalClick?: number | undefined | null | Variable<any, string>,
	outboundClick?: number | undefined | null | Variable<any, string>,
	legacyGallery?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	updatedAt?: string | undefined | null | Variable<any, string>,
	createdAt?: string | undefined | null | Variable<any, string>
};
	["Post_region_MutationInput"]:Post_region_MutationInput;
	["mutationPost_CriteriaInput"]: {
	audiences?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	dates?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	categories?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	tags?: Array<string | undefined | null> | undefined | null | Variable<any, string>
};
	["mutationPostUpdateInput"]: {
	title?: string | undefined | null | Variable<any, string>,
	region?: ValueTypes["PostUpdate_region_MutationInput"] | undefined | null | Variable<any, string>,
	authur?: string | undefined | null | Variable<any, string>,
	publishDateTime?: string | undefined | null | Variable<any, string>,
	permalink?: string | undefined | null | Variable<any, string>,
	status?: string | undefined | null | Variable<any, string>,
	content?: ValueTypes["JSON"] | undefined | null | Variable<any, string>,
	legacyContent?: string | undefined | null | Variable<any, string>,
	showLegacyContent?: boolean | undefined | null | Variable<any, string>,
	blogCategories?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	thumbnail?: string | undefined | null | Variable<any, string>,
	banner?: string | undefined | null | Variable<any, string>,
	criteria?: ValueTypes["mutationPostUpdate_CriteriaInput"] | undefined | null | Variable<any, string>,
	location?: string | undefined | null | Variable<any, string>,
	views?: number | undefined | null | Variable<any, string>,
	homePageInternalClick?: number | undefined | null | Variable<any, string>,
	homePageImpression?: number | undefined | null | Variable<any, string>,
	blogListInternalClick?: number | undefined | null | Variable<any, string>,
	blogListImpression?: number | undefined | null | Variable<any, string>,
	blogDetailInternalClick?: number | undefined | null | Variable<any, string>,
	blogDetailImpression?: number | undefined | null | Variable<any, string>,
	relatedEventInternalClick?: number | undefined | null | Variable<any, string>,
	relatedEventImpression?: number | undefined | null | Variable<any, string>,
	latestEventInternalClick?: number | undefined | null | Variable<any, string>,
	latestEventImpression?: number | undefined | null | Variable<any, string>,
	internalClick?: number | undefined | null | Variable<any, string>,
	totalImpression?: number | undefined | null | Variable<any, string>,
	externalClick?: number | undefined | null | Variable<any, string>,
	outboundClick?: number | undefined | null | Variable<any, string>,
	legacyGallery?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	updatedAt?: string | undefined | null | Variable<any, string>,
	createdAt?: string | undefined | null | Variable<any, string>
};
	["PostUpdate_region_MutationInput"]:PostUpdate_region_MutationInput;
	["mutationPostUpdate_CriteriaInput"]: {
	audiences?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	dates?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	categories?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	tags?: Array<string | undefined | null> | undefined | null | Variable<any, string>
};
	["mutationTicketingInput"]: {
	status?: ValueTypes["Ticketing_status_MutationInput"] | undefined | null | Variable<any, string>,
	creator?: string | undefined | null | Variable<any, string>,
	productType: string | Variable<any, string>,
	productName?: string | undefined | null | Variable<any, string>,
	referenceId?: string | undefined | null | Variable<any, string>,
	linkedEvent?: string | undefined | null | Variable<any, string>,
	CheckInLevel: ValueTypes["Ticketing_CheckInLevel_MutationInput"] | Variable<any, string>,
	displayPrice: string | Variable<any, string>,
	tnc?: string | undefined | null | Variable<any, string>,
	cutoffHours: number | Variable<any, string>,
	sessions?: Array<ValueTypes["mutationTicketing_SessionsInput"] | undefined | null> | undefined | null | Variable<any, string>,
	updatedAt?: string | undefined | null | Variable<any, string>,
	createdAt?: string | undefined | null | Variable<any, string>
};
	["Ticketing_status_MutationInput"]:Ticketing_status_MutationInput;
	["Ticketing_CheckInLevel_MutationInput"]:Ticketing_CheckInLevel_MutationInput;
	["mutationTicketing_SessionsInput"]: {
	variantId?: string | undefined | null | Variable<any, string>,
	productId?: string | undefined | null | Variable<any, string>,
	startDate: string | Variable<any, string>,
	endDate: string | Variable<any, string>,
	inventories: ValueTypes["mutationTicketing_Sessions_InventoriesInput"] | Variable<any, string>,
	id?: string | undefined | null | Variable<any, string>
};
	["mutationTicketing_Sessions_InventoriesInput"]: {
	name?: string | undefined | null | Variable<any, string>,
	quantity: number | Variable<any, string>,
	unitPrice: number | Variable<any, string>,
	handlingFee: number | Variable<any, string>,
	totalCost?: number | undefined | null | Variable<any, string>,
	minPurchase: number | Variable<any, string>,
	maxPurchase: number | Variable<any, string>,
	stocks?: ValueTypes["mutationTicketing_Sessions_Inventories_StocksInput"] | undefined | null | Variable<any, string>,
	remarks?: string | undefined | null | Variable<any, string>
};
	["mutationTicketing_Sessions_Inventories_StocksInput"]: {
	stockId?: string | undefined | null | Variable<any, string>,
	remaining?: number | undefined | null | Variable<any, string>,
	sold?: number | undefined | null | Variable<any, string>,
	onHold?: number | undefined | null | Variable<any, string>,
	checkedIn?: number | undefined | null | Variable<any, string>
};
	["mutationTicketingUpdateInput"]: {
	status?: ValueTypes["TicketingUpdate_status_MutationInput"] | undefined | null | Variable<any, string>,
	creator?: string | undefined | null | Variable<any, string>,
	productType?: string | undefined | null | Variable<any, string>,
	productName?: string | undefined | null | Variable<any, string>,
	referenceId?: string | undefined | null | Variable<any, string>,
	linkedEvent?: string | undefined | null | Variable<any, string>,
	CheckInLevel?: ValueTypes["TicketingUpdate_CheckInLevel_MutationInput"] | undefined | null | Variable<any, string>,
	displayPrice?: string | undefined | null | Variable<any, string>,
	tnc?: string | undefined | null | Variable<any, string>,
	cutoffHours?: number | undefined | null | Variable<any, string>,
	sessions?: Array<ValueTypes["mutationTicketingUpdate_SessionsInput"] | undefined | null> | undefined | null | Variable<any, string>,
	updatedAt?: string | undefined | null | Variable<any, string>,
	createdAt?: string | undefined | null | Variable<any, string>
};
	["TicketingUpdate_status_MutationInput"]:TicketingUpdate_status_MutationInput;
	["TicketingUpdate_CheckInLevel_MutationInput"]:TicketingUpdate_CheckInLevel_MutationInput;
	["mutationTicketingUpdate_SessionsInput"]: {
	variantId?: string | undefined | null | Variable<any, string>,
	productId?: string | undefined | null | Variable<any, string>,
	startDate: string | Variable<any, string>,
	endDate: string | Variable<any, string>,
	inventories: ValueTypes["mutationTicketingUpdate_Sessions_InventoriesInput"] | Variable<any, string>,
	id?: string | undefined | null | Variable<any, string>
};
	["mutationTicketingUpdate_Sessions_InventoriesInput"]: {
	name?: string | undefined | null | Variable<any, string>,
	quantity: number | Variable<any, string>,
	unitPrice: number | Variable<any, string>,
	handlingFee: number | Variable<any, string>,
	totalCost?: number | undefined | null | Variable<any, string>,
	minPurchase: number | Variable<any, string>,
	maxPurchase: number | Variable<any, string>,
	stocks?: ValueTypes["mutationTicketingUpdate_Sessions_Inventories_StocksInput"] | undefined | null | Variable<any, string>,
	remarks?: string | undefined | null | Variable<any, string>
};
	["mutationTicketingUpdate_Sessions_Inventories_StocksInput"]: {
	stockId?: string | undefined | null | Variable<any, string>,
	remaining?: number | undefined | null | Variable<any, string>,
	sold?: number | undefined | null | Variable<any, string>,
	onHold?: number | undefined | null | Variable<any, string>,
	checkedIn?: number | undefined | null | Variable<any, string>
};
	["mutationProductInput"]: {
	status?: ValueTypes["Product_status_MutationInput"] | undefined | null | Variable<any, string>,
	creator?: string | undefined | null | Variable<any, string>,
	productType: string | Variable<any, string>,
	productName?: string | undefined | null | Variable<any, string>,
	referenceId?: string | undefined | null | Variable<any, string>,
	linkedEvent?: string | undefined | null | Variable<any, string>,
	CheckInLevel: ValueTypes["Product_CheckInLevel_MutationInput"] | Variable<any, string>,
	thumbnail: string | Variable<any, string>,
	displayPrice: string | Variable<any, string>,
	transactionType: string | Variable<any, string>,
	website?: string | undefined | null | Variable<any, string>,
	tnc?: string | undefined | null | Variable<any, string>,
	remarks?: string | undefined | null | Variable<any, string>,
	sessions?: Array<ValueTypes["mutationProduct_SessionsInput"] | undefined | null> | undefined | null | Variable<any, string>,
	updatedAt?: string | undefined | null | Variable<any, string>,
	createdAt?: string | undefined | null | Variable<any, string>
};
	["Product_status_MutationInput"]:Product_status_MutationInput;
	["Product_CheckInLevel_MutationInput"]:Product_CheckInLevel_MutationInput;
	["mutationProduct_SessionsInput"]: {
	variantId?: string | undefined | null | Variable<any, string>,
	productId?: string | undefined | null | Variable<any, string>,
	name?: string | undefined | null | Variable<any, string>,
	inventories?: ValueTypes["mutationProduct_Sessions_InventoriesInput"] | undefined | null | Variable<any, string>,
	id?: string | undefined | null | Variable<any, string>
};
	["mutationProduct_Sessions_InventoriesInput"]: {
	quantity: number | Variable<any, string>,
	unitPrice: number | Variable<any, string>,
	handlingFee: number | Variable<any, string>,
	totalCost?: number | undefined | null | Variable<any, string>,
	minPurchase: number | Variable<any, string>,
	maxPurchase: number | Variable<any, string>,
	stocks?: ValueTypes["mutationProduct_Sessions_Inventories_StocksInput"] | undefined | null | Variable<any, string>
};
	["mutationProduct_Sessions_Inventories_StocksInput"]: {
	stockId?: string | undefined | null | Variable<any, string>,
	remaining?: number | undefined | null | Variable<any, string>,
	sold?: number | undefined | null | Variable<any, string>,
	onHold?: number | undefined | null | Variable<any, string>,
	checkedIn?: number | undefined | null | Variable<any, string>
};
	["mutationProductUpdateInput"]: {
	status?: ValueTypes["ProductUpdate_status_MutationInput"] | undefined | null | Variable<any, string>,
	creator?: string | undefined | null | Variable<any, string>,
	productType?: string | undefined | null | Variable<any, string>,
	productName?: string | undefined | null | Variable<any, string>,
	referenceId?: string | undefined | null | Variable<any, string>,
	linkedEvent?: string | undefined | null | Variable<any, string>,
	CheckInLevel?: ValueTypes["ProductUpdate_CheckInLevel_MutationInput"] | undefined | null | Variable<any, string>,
	thumbnail?: string | undefined | null | Variable<any, string>,
	displayPrice?: string | undefined | null | Variable<any, string>,
	transactionType?: string | undefined | null | Variable<any, string>,
	website?: string | undefined | null | Variable<any, string>,
	tnc?: string | undefined | null | Variable<any, string>,
	remarks?: string | undefined | null | Variable<any, string>,
	sessions?: Array<ValueTypes["mutationProductUpdate_SessionsInput"] | undefined | null> | undefined | null | Variable<any, string>,
	updatedAt?: string | undefined | null | Variable<any, string>,
	createdAt?: string | undefined | null | Variable<any, string>
};
	["ProductUpdate_status_MutationInput"]:ProductUpdate_status_MutationInput;
	["ProductUpdate_CheckInLevel_MutationInput"]:ProductUpdate_CheckInLevel_MutationInput;
	["mutationProductUpdate_SessionsInput"]: {
	variantId?: string | undefined | null | Variable<any, string>,
	productId?: string | undefined | null | Variable<any, string>,
	name?: string | undefined | null | Variable<any, string>,
	inventories?: ValueTypes["mutationProductUpdate_Sessions_InventoriesInput"] | undefined | null | Variable<any, string>,
	id?: string | undefined | null | Variable<any, string>
};
	["mutationProductUpdate_Sessions_InventoriesInput"]: {
	quantity: number | Variable<any, string>,
	unitPrice: number | Variable<any, string>,
	handlingFee: number | Variable<any, string>,
	totalCost?: number | undefined | null | Variable<any, string>,
	minPurchase: number | Variable<any, string>,
	maxPurchase: number | Variable<any, string>,
	stocks?: ValueTypes["mutationProductUpdate_Sessions_Inventories_StocksInput"] | undefined | null | Variable<any, string>
};
	["mutationProductUpdate_Sessions_Inventories_StocksInput"]: {
	stockId?: string | undefined | null | Variable<any, string>,
	remaining?: number | undefined | null | Variable<any, string>,
	sold?: number | undefined | null | Variable<any, string>,
	onHold?: number | undefined | null | Variable<any, string>,
	checkedIn?: number | undefined | null | Variable<any, string>
};
	["mutationInventoryStatusInput"]: {
	variantId?: string | undefined | null | Variable<any, string>,
	variantName?: string | undefined | null | Variable<any, string>,
	productType?: string | undefined | null | Variable<any, string>,
	stockUpdateStatus: ValueTypes["InventoryStatus_stockUpdateStatus_MutationInput"] | Variable<any, string>,
	stockUpdateAmount: number | Variable<any, string>,
	user?: string | undefined | null | Variable<any, string>,
	publicUserPhone?: string | undefined | null | Variable<any, string>,
	expiredAt?: string | undefined | null | Variable<any, string>,
	updatedAt?: string | undefined | null | Variable<any, string>,
	createdAt?: string | undefined | null | Variable<any, string>
};
	["InventoryStatus_stockUpdateStatus_MutationInput"]:InventoryStatus_stockUpdateStatus_MutationInput;
	["mutationInventoryStatusUpdateInput"]: {
	variantId?: string | undefined | null | Variable<any, string>,
	variantName?: string | undefined | null | Variable<any, string>,
	productType?: string | undefined | null | Variable<any, string>,
	stockUpdateStatus?: ValueTypes["InventoryStatusUpdate_stockUpdateStatus_MutationInput"] | undefined | null | Variable<any, string>,
	stockUpdateAmount?: number | undefined | null | Variable<any, string>,
	user?: string | undefined | null | Variable<any, string>,
	publicUserPhone?: string | undefined | null | Variable<any, string>,
	expiredAt?: string | undefined | null | Variable<any, string>,
	updatedAt?: string | undefined | null | Variable<any, string>,
	createdAt?: string | undefined | null | Variable<any, string>
};
	["InventoryStatusUpdate_stockUpdateStatus_MutationInput"]:InventoryStatusUpdate_stockUpdateStatus_MutationInput;
	["mutationTransactionInput"]: {
	creator?: string | undefined | null | Variable<any, string>,
	productName?: string | undefined | null | Variable<any, string>,
	referenceId?: string | undefined | null | Variable<any, string>,
	linkedEvent?: string | undefined | null | Variable<any, string>,
	purchaseDateTime?: string | undefined | null | Variable<any, string>,
	timableOrderId?: string | undefined | null | Variable<any, string>,
	stripePaymentId?: string | undefined | null | Variable<any, string>,
	totalAmount?: number | undefined | null | Variable<any, string>,
	contactPersonName?: string | undefined | null | Variable<any, string>,
	contactPersonPhone?: string | undefined | null | Variable<any, string>,
	remarks?: string | undefined | null | Variable<any, string>,
	redemptionCodes?: Array<ValueTypes["mutationTransaction_RedemptionCodesInput"] | undefined | null> | undefined | null | Variable<any, string>,
	updatedAt?: string | undefined | null | Variable<any, string>,
	createdAt?: string | undefined | null | Variable<any, string>
};
	["mutationTransaction_RedemptionCodesInput"]: {
	code?: string | undefined | null | Variable<any, string>,
	id?: string | undefined | null | Variable<any, string>
};
	["mutationTransactionUpdateInput"]: {
	creator?: string | undefined | null | Variable<any, string>,
	productName?: string | undefined | null | Variable<any, string>,
	referenceId?: string | undefined | null | Variable<any, string>,
	linkedEvent?: string | undefined | null | Variable<any, string>,
	purchaseDateTime?: string | undefined | null | Variable<any, string>,
	timableOrderId?: string | undefined | null | Variable<any, string>,
	stripePaymentId?: string | undefined | null | Variable<any, string>,
	totalAmount?: number | undefined | null | Variable<any, string>,
	contactPersonName?: string | undefined | null | Variable<any, string>,
	contactPersonPhone?: string | undefined | null | Variable<any, string>,
	remarks?: string | undefined | null | Variable<any, string>,
	redemptionCodes?: Array<ValueTypes["mutationTransactionUpdate_RedemptionCodesInput"] | undefined | null> | undefined | null | Variable<any, string>,
	updatedAt?: string | undefined | null | Variable<any, string>,
	createdAt?: string | undefined | null | Variable<any, string>
};
	["mutationTransactionUpdate_RedemptionCodesInput"]: {
	code?: string | undefined | null | Variable<any, string>,
	id?: string | undefined | null | Variable<any, string>
};
	["mutationRedemptionCodeInput"]: {
	creator?: string | undefined | null | Variable<any, string>,
	status: ValueTypes["RedemptionCode_status_MutationInput"] | Variable<any, string>,
	qrCodeId?: string | undefined | null | Variable<any, string>,
	lastCheckedInDateTime?: string | undefined | null | Variable<any, string>,
	voidedDateTime?: string | undefined | null | Variable<any, string>,
	refundedDateTime?: string | undefined | null | Variable<any, string>,
	eventStartTime?: string | undefined | null | Variable<any, string>,
	eventEndTime?: string | undefined | null | Variable<any, string>,
	eventPriceZone?: string | undefined | null | Variable<any, string>,
	ownerName: string | Variable<any, string>,
	phone: string | Variable<any, string>,
	pdf?: string | undefined | null | Variable<any, string>,
	variants?: Array<ValueTypes["mutationRedemptionCode_VariantsInput"] | undefined | null> | undefined | null | Variable<any, string>,
	productId?: string | undefined | null | Variable<any, string>,
	referenceId?: string | undefined | null | Variable<any, string>,
	productType?: string | undefined | null | Variable<any, string>,
	productName?: string | undefined | null | Variable<any, string>,
	linkedEvent?: string | undefined | null | Variable<any, string>,
	timableOrderId?: string | undefined | null | Variable<any, string>,
	purchaseDateTime?: string | undefined | null | Variable<any, string>,
	changeHistory?: Array<ValueTypes["mutationRedemptionCode_ChangeHistoryInput"] | undefined | null> | undefined | null | Variable<any, string>,
	updatedAt?: string | undefined | null | Variable<any, string>,
	createdAt?: string | undefined | null | Variable<any, string>
};
	["RedemptionCode_status_MutationInput"]:RedemptionCode_status_MutationInput;
	["mutationRedemptionCode_VariantsInput"]: {
	variantId?: string | undefined | null | Variable<any, string>,
	name?: string | undefined | null | Variable<any, string>,
	quantity?: string | undefined | null | Variable<any, string>,
	totalCost?: string | undefined | null | Variable<any, string>,
	id?: string | undefined | null | Variable<any, string>
};
	["mutationRedemptionCode_ChangeHistoryInput"]: {
	timestamp: string | Variable<any, string>,
	field: string | Variable<any, string>,
	oldValue: string | Variable<any, string>,
	newValue: string | Variable<any, string>,
	id?: string | undefined | null | Variable<any, string>
};
	["mutationRedemptionCodeUpdateInput"]: {
	creator?: string | undefined | null | Variable<any, string>,
	status?: ValueTypes["RedemptionCodeUpdate_status_MutationInput"] | undefined | null | Variable<any, string>,
	qrCodeId?: string | undefined | null | Variable<any, string>,
	lastCheckedInDateTime?: string | undefined | null | Variable<any, string>,
	voidedDateTime?: string | undefined | null | Variable<any, string>,
	refundedDateTime?: string | undefined | null | Variable<any, string>,
	eventStartTime?: string | undefined | null | Variable<any, string>,
	eventEndTime?: string | undefined | null | Variable<any, string>,
	eventPriceZone?: string | undefined | null | Variable<any, string>,
	ownerName?: string | undefined | null | Variable<any, string>,
	phone?: string | undefined | null | Variable<any, string>,
	pdf?: string | undefined | null | Variable<any, string>,
	variants?: Array<ValueTypes["mutationRedemptionCodeUpdate_VariantsInput"] | undefined | null> | undefined | null | Variable<any, string>,
	productId?: string | undefined | null | Variable<any, string>,
	referenceId?: string | undefined | null | Variable<any, string>,
	productType?: string | undefined | null | Variable<any, string>,
	productName?: string | undefined | null | Variable<any, string>,
	linkedEvent?: string | undefined | null | Variable<any, string>,
	timableOrderId?: string | undefined | null | Variable<any, string>,
	purchaseDateTime?: string | undefined | null | Variable<any, string>,
	changeHistory?: Array<ValueTypes["mutationRedemptionCodeUpdate_ChangeHistoryInput"] | undefined | null> | undefined | null | Variable<any, string>,
	updatedAt?: string | undefined | null | Variable<any, string>,
	createdAt?: string | undefined | null | Variable<any, string>
};
	["RedemptionCodeUpdate_status_MutationInput"]:RedemptionCodeUpdate_status_MutationInput;
	["mutationRedemptionCodeUpdate_VariantsInput"]: {
	variantId?: string | undefined | null | Variable<any, string>,
	name?: string | undefined | null | Variable<any, string>,
	quantity?: string | undefined | null | Variable<any, string>,
	totalCost?: string | undefined | null | Variable<any, string>,
	id?: string | undefined | null | Variable<any, string>
};
	["mutationRedemptionCodeUpdate_ChangeHistoryInput"]: {
	timestamp: string | Variable<any, string>,
	field: string | Variable<any, string>,
	oldValue: string | Variable<any, string>,
	newValue: string | Variable<any, string>,
	id?: string | undefined | null | Variable<any, string>
};
	["mutationRedemptionPdfInput"]: {
	updatedAt?: string | undefined | null | Variable<any, string>,
	createdAt?: string | undefined | null | Variable<any, string>,
	url?: string | undefined | null | Variable<any, string>,
	filename?: string | undefined | null | Variable<any, string>,
	mimeType?: string | undefined | null | Variable<any, string>,
	filesize?: number | undefined | null | Variable<any, string>,
	width?: number | undefined | null | Variable<any, string>,
	height?: number | undefined | null | Variable<any, string>,
	focalX?: number | undefined | null | Variable<any, string>,
	focalY?: number | undefined | null | Variable<any, string>
};
	["mutationRedemptionPdfUpdateInput"]: {
	updatedAt?: string | undefined | null | Variable<any, string>,
	createdAt?: string | undefined | null | Variable<any, string>,
	url?: string | undefined | null | Variable<any, string>,
	filename?: string | undefined | null | Variable<any, string>,
	mimeType?: string | undefined | null | Variable<any, string>,
	filesize?: number | undefined | null | Variable<any, string>,
	width?: number | undefined | null | Variable<any, string>,
	height?: number | undefined | null | Variable<any, string>,
	focalX?: number | undefined | null | Variable<any, string>,
	focalY?: number | undefined | null | Variable<any, string>
};
	["mutationCheckInRedemptionInput"]: {
	creator?: string | undefined | null | Variable<any, string>,
	qrCodeId?: string | undefined | null | Variable<any, string>,
	codeownerName?: string | undefined | null | Variable<any, string>,
	name?: string | undefined | null | Variable<any, string>,
	phone?: string | undefined | null | Variable<any, string>,
	linkedEvent?: string | undefined | null | Variable<any, string>,
	entryNumber?: string | undefined | null | Variable<any, string>,
	scanAt?: string | undefined | null | Variable<any, string>,
	uploadAt?: string | undefined | null | Variable<any, string>,
	status?: string | undefined | null | Variable<any, string>,
	failReason?: string | undefined | null | Variable<any, string>,
	variants?: Array<ValueTypes["mutationCheckInRedemption_VariantsInput"] | undefined | null> | undefined | null | Variable<any, string>,
	checkInAppSelectionId?: string | undefined | null | Variable<any, string>,
	qrCodeStatus?: string | undefined | null | Variable<any, string>,
	productType?: string | undefined | null | Variable<any, string>,
	updatedAt?: string | undefined | null | Variable<any, string>,
	createdAt?: string | undefined | null | Variable<any, string>
};
	["mutationCheckInRedemption_VariantsInput"]: {
	variantId?: string | undefined | null | Variable<any, string>,
	name?: string | undefined | null | Variable<any, string>,
	quantity?: string | undefined | null | Variable<any, string>,
	totalCost?: string | undefined | null | Variable<any, string>,
	id?: string | undefined | null | Variable<any, string>
};
	["mutationCheckInRedemptionUpdateInput"]: {
	creator?: string | undefined | null | Variable<any, string>,
	qrCodeId?: string | undefined | null | Variable<any, string>,
	codeownerName?: string | undefined | null | Variable<any, string>,
	name?: string | undefined | null | Variable<any, string>,
	phone?: string | undefined | null | Variable<any, string>,
	linkedEvent?: string | undefined | null | Variable<any, string>,
	entryNumber?: string | undefined | null | Variable<any, string>,
	scanAt?: string | undefined | null | Variable<any, string>,
	uploadAt?: string | undefined | null | Variable<any, string>,
	status?: string | undefined | null | Variable<any, string>,
	failReason?: string | undefined | null | Variable<any, string>,
	variants?: Array<ValueTypes["mutationCheckInRedemptionUpdate_VariantsInput"] | undefined | null> | undefined | null | Variable<any, string>,
	checkInAppSelectionId?: string | undefined | null | Variable<any, string>,
	qrCodeStatus?: string | undefined | null | Variable<any, string>,
	productType?: string | undefined | null | Variable<any, string>,
	updatedAt?: string | undefined | null | Variable<any, string>,
	createdAt?: string | undefined | null | Variable<any, string>
};
	["mutationCheckInRedemptionUpdate_VariantsInput"]: {
	variantId?: string | undefined | null | Variable<any, string>,
	name?: string | undefined | null | Variable<any, string>,
	quantity?: string | undefined | null | Variable<any, string>,
	totalCost?: string | undefined | null | Variable<any, string>,
	id?: string | undefined | null | Variable<any, string>
};
	["mutationUserInput"]: {
	region?: ValueTypes["User_region_MutationInput"] | undefined | null | Variable<any, string>,
	name?: string | undefined | null | Variable<any, string>,
	displayEmail?: string | undefined | null | Variable<any, string>,
	legacyUsername?: string | undefined | null | Variable<any, string>,
	roles: Array<ValueTypes["User_roles_MutationInput"] | undefined | null> | Variable<any, string>,
	member?: ValueTypes["mutationUser_MemberInput"] | undefined | null | Variable<any, string>,
	organiser?: ValueTypes["mutationUser_OrganiserInput"] | undefined | null | Variable<any, string>,
	emailVerified?: boolean | undefined | null | Variable<any, string>,
	sso?: ValueTypes["mutationUser_SsoInput"] | undefined | null | Variable<any, string>,
	oldPassword?: string | undefined | null | Variable<any, string>,
	status?: ValueTypes["User_status_MutationInput"] | undefined | null | Variable<any, string>,
	updatedAt?: string | undefined | null | Variable<any, string>,
	createdAt?: string | undefined | null | Variable<any, string>,
	email: string | Variable<any, string>,
	resetPasswordToken?: string | undefined | null | Variable<any, string>,
	resetPasswordExpiration?: string | undefined | null | Variable<any, string>,
	salt?: string | undefined | null | Variable<any, string>,
	hash?: string | undefined | null | Variable<any, string>,
	loginAttempts?: number | undefined | null | Variable<any, string>,
	lockUntil?: string | undefined | null | Variable<any, string>,
	password: string | Variable<any, string>
};
	["User_region_MutationInput"]:User_region_MutationInput;
	["User_roles_MutationInput"]:User_roles_MutationInput;
	["mutationUser_MemberInput"]: {
	region?: ValueTypes["User_Member_region_MutationInput"] | undefined | null | Variable<any, string>,
	language?: ValueTypes["User_Member_language_MutationInput"] | undefined | null | Variable<any, string>,
	phone?: string | undefined | null | Variable<any, string>,
	avatar?: string | undefined | null | Variable<any, string>,
	birthday?: string | undefined | null | Variable<any, string>,
	gender?: ValueTypes["User_Member_gender_MutationInput"] | undefined | null | Variable<any, string>,
	categories?: Array<string | undefined | null> | undefined | null | Variable<any, string>
};
	["User_Member_region_MutationInput"]:User_Member_region_MutationInput;
	["User_Member_language_MutationInput"]:User_Member_language_MutationInput;
	["User_Member_gender_MutationInput"]:User_Member_gender_MutationInput;
	["mutationUser_OrganiserInput"]: {
	logo?: string | undefined | null | Variable<any, string>,
	banner?: string | undefined | null | Variable<any, string>,
	description?: string | undefined | null | Variable<any, string>,
	foundedIn?: string | undefined | null | Variable<any, string>,
	address?: string | undefined | null | Variable<any, string>,
	website?: string | undefined | null | Variable<any, string>,
	facebook?: string | undefined | null | Variable<any, string>,
	instagram?: string | undefined | null | Variable<any, string>,
	language?: ValueTypes["User_Organiser_language_MutationInput"] | undefined | null | Variable<any, string>,
	phone?: string | undefined | null | Variable<any, string>,
	followers?: number | undefined | null | Variable<any, string>
};
	["User_Organiser_language_MutationInput"]:User_Organiser_language_MutationInput;
	["mutationUser_SsoInput"]: {
	googleId?: string | undefined | null | Variable<any, string>,
	facebookId?: string | undefined | null | Variable<any, string>,
	appleId?: string | undefined | null | Variable<any, string>
};
	["User_status_MutationInput"]:User_status_MutationInput;
	["mutationUserUpdateInput"]: {
	region?: ValueTypes["UserUpdate_region_MutationInput"] | undefined | null | Variable<any, string>,
	name?: string | undefined | null | Variable<any, string>,
	displayEmail?: string | undefined | null | Variable<any, string>,
	legacyUsername?: string | undefined | null | Variable<any, string>,
	roles?: Array<ValueTypes["UserUpdate_roles_MutationInput"] | undefined | null> | undefined | null | Variable<any, string>,
	member?: ValueTypes["mutationUserUpdate_MemberInput"] | undefined | null | Variable<any, string>,
	organiser?: ValueTypes["mutationUserUpdate_OrganiserInput"] | undefined | null | Variable<any, string>,
	emailVerified?: boolean | undefined | null | Variable<any, string>,
	sso?: ValueTypes["mutationUserUpdate_SsoInput"] | undefined | null | Variable<any, string>,
	oldPassword?: string | undefined | null | Variable<any, string>,
	status?: ValueTypes["UserUpdate_status_MutationInput"] | undefined | null | Variable<any, string>,
	updatedAt?: string | undefined | null | Variable<any, string>,
	createdAt?: string | undefined | null | Variable<any, string>,
	email?: string | undefined | null | Variable<any, string>,
	resetPasswordToken?: string | undefined | null | Variable<any, string>,
	resetPasswordExpiration?: string | undefined | null | Variable<any, string>,
	salt?: string | undefined | null | Variable<any, string>,
	hash?: string | undefined | null | Variable<any, string>,
	loginAttempts?: number | undefined | null | Variable<any, string>,
	lockUntil?: string | undefined | null | Variable<any, string>,
	password?: string | undefined | null | Variable<any, string>
};
	["UserUpdate_region_MutationInput"]:UserUpdate_region_MutationInput;
	["UserUpdate_roles_MutationInput"]:UserUpdate_roles_MutationInput;
	["mutationUserUpdate_MemberInput"]: {
	region?: ValueTypes["UserUpdate_Member_region_MutationInput"] | undefined | null | Variable<any, string>,
	language?: ValueTypes["UserUpdate_Member_language_MutationInput"] | undefined | null | Variable<any, string>,
	phone?: string | undefined | null | Variable<any, string>,
	avatar?: string | undefined | null | Variable<any, string>,
	birthday?: string | undefined | null | Variable<any, string>,
	gender?: ValueTypes["UserUpdate_Member_gender_MutationInput"] | undefined | null | Variable<any, string>,
	categories?: Array<string | undefined | null> | undefined | null | Variable<any, string>
};
	["UserUpdate_Member_region_MutationInput"]:UserUpdate_Member_region_MutationInput;
	["UserUpdate_Member_language_MutationInput"]:UserUpdate_Member_language_MutationInput;
	["UserUpdate_Member_gender_MutationInput"]:UserUpdate_Member_gender_MutationInput;
	["mutationUserUpdate_OrganiserInput"]: {
	logo?: string | undefined | null | Variable<any, string>,
	banner?: string | undefined | null | Variable<any, string>,
	description?: string | undefined | null | Variable<any, string>,
	foundedIn?: string | undefined | null | Variable<any, string>,
	address?: string | undefined | null | Variable<any, string>,
	website?: string | undefined | null | Variable<any, string>,
	facebook?: string | undefined | null | Variable<any, string>,
	instagram?: string | undefined | null | Variable<any, string>,
	language?: ValueTypes["UserUpdate_Organiser_language_MutationInput"] | undefined | null | Variable<any, string>,
	phone?: string | undefined | null | Variable<any, string>,
	followers?: number | undefined | null | Variable<any, string>
};
	["UserUpdate_Organiser_language_MutationInput"]:UserUpdate_Organiser_language_MutationInput;
	["mutationUserUpdate_SsoInput"]: {
	googleId?: string | undefined | null | Variable<any, string>,
	facebookId?: string | undefined | null | Variable<any, string>,
	appleId?: string | undefined | null | Variable<any, string>
};
	["UserUpdate_status_MutationInput"]:UserUpdate_status_MutationInput;
	["usersRefreshedUser"]: AliasType<{
	exp?:boolean | `@${string}`,
	refreshedToken?:boolean | `@${string}`,
	strategy?:boolean | `@${string}`,
	user?:ValueTypes["usersJWT"],
		__typename?: boolean | `@${string}`
}>;
	["usersJWT"]: AliasType<{
	name?:boolean | `@${string}`,
	email?:boolean | `@${string}`,
	collection?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["usersLoginResult"]: AliasType<{
	exp?:boolean | `@${string}`,
	token?:boolean | `@${string}`,
	user?:ValueTypes["User"],
		__typename?: boolean | `@${string}`
}>;
	["usersResetPassword"]: AliasType<{
	token?:boolean | `@${string}`,
	user?:ValueTypes["User"],
		__typename?: boolean | `@${string}`
}>;
	["mutationBookmarkInput"]: {
	user?: ValueTypes["Bookmark_UserRelationshipInput"] | undefined | null | Variable<any, string>,
	item?: ValueTypes["Bookmark_ItemRelationshipInput"] | undefined | null | Variable<any, string>,
	updatedAt?: string | undefined | null | Variable<any, string>,
	createdAt?: string | undefined | null | Variable<any, string>
};
	["Bookmark_UserRelationshipInput"]: {
	relationTo?: ValueTypes["Bookmark_UserRelationshipInputRelationTo"] | undefined | null | Variable<any, string>,
	value?: ValueTypes["JSON"] | undefined | null | Variable<any, string>
};
	["Bookmark_UserRelationshipInputRelationTo"]:Bookmark_UserRelationshipInputRelationTo;
	["Bookmark_ItemRelationshipInput"]: {
	relationTo?: ValueTypes["Bookmark_ItemRelationshipInputRelationTo"] | undefined | null | Variable<any, string>,
	value?: ValueTypes["JSON"] | undefined | null | Variable<any, string>
};
	["Bookmark_ItemRelationshipInputRelationTo"]:Bookmark_ItemRelationshipInputRelationTo;
	["mutationBookmarkUpdateInput"]: {
	user?: ValueTypes["BookmarkUpdate_UserRelationshipInput"] | undefined | null | Variable<any, string>,
	item?: ValueTypes["BookmarkUpdate_ItemRelationshipInput"] | undefined | null | Variable<any, string>,
	updatedAt?: string | undefined | null | Variable<any, string>,
	createdAt?: string | undefined | null | Variable<any, string>
};
	["BookmarkUpdate_UserRelationshipInput"]: {
	relationTo?: ValueTypes["BookmarkUpdate_UserRelationshipInputRelationTo"] | undefined | null | Variable<any, string>,
	value?: ValueTypes["JSON"] | undefined | null | Variable<any, string>
};
	["BookmarkUpdate_UserRelationshipInputRelationTo"]:BookmarkUpdate_UserRelationshipInputRelationTo;
	["BookmarkUpdate_ItemRelationshipInput"]: {
	relationTo?: ValueTypes["BookmarkUpdate_ItemRelationshipInputRelationTo"] | undefined | null | Variable<any, string>,
	value?: ValueTypes["JSON"] | undefined | null | Variable<any, string>
};
	["BookmarkUpdate_ItemRelationshipInputRelationTo"]:BookmarkUpdate_ItemRelationshipInputRelationTo;
	["mutationWebAppBookmarkInput"]: {
	user?: ValueTypes["WebAppBookmark_UserRelationshipInput"] | undefined | null | Variable<any, string>,
	event?: ValueTypes["WebAppBookmark_EventRelationshipInput"] | undefined | null | Variable<any, string>,
	productType?: ValueTypes["WebAppBookmark_productType_MutationInput"] | undefined | null | Variable<any, string>,
	item?: string | undefined | null | Variable<any, string>,
	entryNumber?: string | undefined | null | Variable<any, string>,
	updatedAt?: string | undefined | null | Variable<any, string>,
	createdAt?: string | undefined | null | Variable<any, string>
};
	["WebAppBookmark_UserRelationshipInput"]: {
	relationTo?: ValueTypes["WebAppBookmark_UserRelationshipInputRelationTo"] | undefined | null | Variable<any, string>,
	value?: ValueTypes["JSON"] | undefined | null | Variable<any, string>
};
	["WebAppBookmark_UserRelationshipInputRelationTo"]:WebAppBookmark_UserRelationshipInputRelationTo;
	["WebAppBookmark_EventRelationshipInput"]: {
	relationTo?: ValueTypes["WebAppBookmark_EventRelationshipInputRelationTo"] | undefined | null | Variable<any, string>,
	value?: ValueTypes["JSON"] | undefined | null | Variable<any, string>
};
	["WebAppBookmark_EventRelationshipInputRelationTo"]:WebAppBookmark_EventRelationshipInputRelationTo;
	["WebAppBookmark_productType_MutationInput"]:WebAppBookmark_productType_MutationInput;
	["mutationWebAppBookmarkUpdateInput"]: {
	user?: ValueTypes["WebAppBookmarkUpdate_UserRelationshipInput"] | undefined | null | Variable<any, string>,
	event?: ValueTypes["WebAppBookmarkUpdate_EventRelationshipInput"] | undefined | null | Variable<any, string>,
	productType?: ValueTypes["WebAppBookmarkUpdate_productType_MutationInput"] | undefined | null | Variable<any, string>,
	item?: string | undefined | null | Variable<any, string>,
	entryNumber?: string | undefined | null | Variable<any, string>,
	updatedAt?: string | undefined | null | Variable<any, string>,
	createdAt?: string | undefined | null | Variable<any, string>
};
	["WebAppBookmarkUpdate_UserRelationshipInput"]: {
	relationTo?: ValueTypes["WebAppBookmarkUpdate_UserRelationshipInputRelationTo"] | undefined | null | Variable<any, string>,
	value?: ValueTypes["JSON"] | undefined | null | Variable<any, string>
};
	["WebAppBookmarkUpdate_UserRelationshipInputRelationTo"]:WebAppBookmarkUpdate_UserRelationshipInputRelationTo;
	["WebAppBookmarkUpdate_EventRelationshipInput"]: {
	relationTo?: ValueTypes["WebAppBookmarkUpdate_EventRelationshipInputRelationTo"] | undefined | null | Variable<any, string>,
	value?: ValueTypes["JSON"] | undefined | null | Variable<any, string>
};
	["WebAppBookmarkUpdate_EventRelationshipInputRelationTo"]:WebAppBookmarkUpdate_EventRelationshipInputRelationTo;
	["WebAppBookmarkUpdate_productType_MutationInput"]:WebAppBookmarkUpdate_productType_MutationInput;
	["mutationViewRecordInput"]: {
	user?: ValueTypes["ViewRecord_UserRelationshipInput"] | undefined | null | Variable<any, string>,
	item?: ValueTypes["ViewRecord_ItemRelationshipInput"] | undefined | null | Variable<any, string>,
	updatedAt?: string | undefined | null | Variable<any, string>,
	createdAt?: string | undefined | null | Variable<any, string>
};
	["ViewRecord_UserRelationshipInput"]: {
	relationTo?: ValueTypes["ViewRecord_UserRelationshipInputRelationTo"] | undefined | null | Variable<any, string>,
	value?: ValueTypes["JSON"] | undefined | null | Variable<any, string>
};
	["ViewRecord_UserRelationshipInputRelationTo"]:ViewRecord_UserRelationshipInputRelationTo;
	["ViewRecord_ItemRelationshipInput"]: {
	relationTo?: ValueTypes["ViewRecord_ItemRelationshipInputRelationTo"] | undefined | null | Variable<any, string>,
	value?: ValueTypes["JSON"] | undefined | null | Variable<any, string>
};
	["ViewRecord_ItemRelationshipInputRelationTo"]:ViewRecord_ItemRelationshipInputRelationTo;
	["mutationViewRecordUpdateInput"]: {
	user?: ValueTypes["ViewRecordUpdate_UserRelationshipInput"] | undefined | null | Variable<any, string>,
	item?: ValueTypes["ViewRecordUpdate_ItemRelationshipInput"] | undefined | null | Variable<any, string>,
	updatedAt?: string | undefined | null | Variable<any, string>,
	createdAt?: string | undefined | null | Variable<any, string>
};
	["ViewRecordUpdate_UserRelationshipInput"]: {
	relationTo?: ValueTypes["ViewRecordUpdate_UserRelationshipInputRelationTo"] | undefined | null | Variable<any, string>,
	value?: ValueTypes["JSON"] | undefined | null | Variable<any, string>
};
	["ViewRecordUpdate_UserRelationshipInputRelationTo"]:ViewRecordUpdate_UserRelationshipInputRelationTo;
	["ViewRecordUpdate_ItemRelationshipInput"]: {
	relationTo?: ValueTypes["ViewRecordUpdate_ItemRelationshipInputRelationTo"] | undefined | null | Variable<any, string>,
	value?: ValueTypes["JSON"] | undefined | null | Variable<any, string>
};
	["ViewRecordUpdate_ItemRelationshipInputRelationTo"]:ViewRecordUpdate_ItemRelationshipInputRelationTo;
	["mutationCommentInput"]: {
	commenter?: string | undefined | null | Variable<any, string>,
	content?: string | undefined | null | Variable<any, string>,
	image?: string | undefined | null | Variable<any, string>,
	target?: ValueTypes["Comment_TargetRelationshipInput"] | undefined | null | Variable<any, string>,
	parent?: ValueTypes["Comment_ParentRelationshipInput"] | undefined | null | Variable<any, string>,
	isReviewed?: boolean | undefined | null | Variable<any, string>,
	reviewedBy?: string | undefined | null | Variable<any, string>,
	banned?: boolean | undefined | null | Variable<any, string>,
	type?: string | undefined | null | Variable<any, string>,
	updatedAt?: string | undefined | null | Variable<any, string>,
	createdAt?: string | undefined | null | Variable<any, string>
};
	["Comment_TargetRelationshipInput"]: {
	relationTo?: ValueTypes["Comment_TargetRelationshipInputRelationTo"] | undefined | null | Variable<any, string>,
	value?: ValueTypes["JSON"] | undefined | null | Variable<any, string>
};
	["Comment_TargetRelationshipInputRelationTo"]:Comment_TargetRelationshipInputRelationTo;
	["Comment_ParentRelationshipInput"]: {
	relationTo?: ValueTypes["Comment_ParentRelationshipInputRelationTo"] | undefined | null | Variable<any, string>,
	value?: ValueTypes["JSON"] | undefined | null | Variable<any, string>
};
	["Comment_ParentRelationshipInputRelationTo"]:Comment_ParentRelationshipInputRelationTo;
	["mutationCommentUpdateInput"]: {
	commenter?: string | undefined | null | Variable<any, string>,
	content?: string | undefined | null | Variable<any, string>,
	image?: string | undefined | null | Variable<any, string>,
	target?: ValueTypes["CommentUpdate_TargetRelationshipInput"] | undefined | null | Variable<any, string>,
	parent?: ValueTypes["CommentUpdate_ParentRelationshipInput"] | undefined | null | Variable<any, string>,
	isReviewed?: boolean | undefined | null | Variable<any, string>,
	reviewedBy?: string | undefined | null | Variable<any, string>,
	banned?: boolean | undefined | null | Variable<any, string>,
	type?: string | undefined | null | Variable<any, string>,
	updatedAt?: string | undefined | null | Variable<any, string>,
	createdAt?: string | undefined | null | Variable<any, string>
};
	["CommentUpdate_TargetRelationshipInput"]: {
	relationTo?: ValueTypes["CommentUpdate_TargetRelationshipInputRelationTo"] | undefined | null | Variable<any, string>,
	value?: ValueTypes["JSON"] | undefined | null | Variable<any, string>
};
	["CommentUpdate_TargetRelationshipInputRelationTo"]:CommentUpdate_TargetRelationshipInputRelationTo;
	["CommentUpdate_ParentRelationshipInput"]: {
	relationTo?: ValueTypes["CommentUpdate_ParentRelationshipInputRelationTo"] | undefined | null | Variable<any, string>,
	value?: ValueTypes["JSON"] | undefined | null | Variable<any, string>
};
	["CommentUpdate_ParentRelationshipInputRelationTo"]:CommentUpdate_ParentRelationshipInputRelationTo;
	["mutationSubscriptionInput"]: {
	user?: ValueTypes["Subscription_UserRelationshipInput"] | undefined | null | Variable<any, string>,
	organiser?: ValueTypes["Subscription_OrganiserRelationshipInput"] | undefined | null | Variable<any, string>,
	updatedAt?: string | undefined | null | Variable<any, string>,
	createdAt?: string | undefined | null | Variable<any, string>
};
	["Subscription_UserRelationshipInput"]: {
	relationTo?: ValueTypes["Subscription_UserRelationshipInputRelationTo"] | undefined | null | Variable<any, string>,
	value?: ValueTypes["JSON"] | undefined | null | Variable<any, string>
};
	["Subscription_UserRelationshipInputRelationTo"]:Subscription_UserRelationshipInputRelationTo;
	["Subscription_OrganiserRelationshipInput"]: {
	relationTo?: ValueTypes["Subscription_OrganiserRelationshipInputRelationTo"] | undefined | null | Variable<any, string>,
	value?: ValueTypes["JSON"] | undefined | null | Variable<any, string>
};
	["Subscription_OrganiserRelationshipInputRelationTo"]:Subscription_OrganiserRelationshipInputRelationTo;
	["mutationSubscriptionUpdateInput"]: {
	user?: ValueTypes["SubscriptionUpdate_UserRelationshipInput"] | undefined | null | Variable<any, string>,
	organiser?: ValueTypes["SubscriptionUpdate_OrganiserRelationshipInput"] | undefined | null | Variable<any, string>,
	updatedAt?: string | undefined | null | Variable<any, string>,
	createdAt?: string | undefined | null | Variable<any, string>
};
	["SubscriptionUpdate_UserRelationshipInput"]: {
	relationTo?: ValueTypes["SubscriptionUpdate_UserRelationshipInputRelationTo"] | undefined | null | Variable<any, string>,
	value?: ValueTypes["JSON"] | undefined | null | Variable<any, string>
};
	["SubscriptionUpdate_UserRelationshipInputRelationTo"]:SubscriptionUpdate_UserRelationshipInputRelationTo;
	["SubscriptionUpdate_OrganiserRelationshipInput"]: {
	relationTo?: ValueTypes["SubscriptionUpdate_OrganiserRelationshipInputRelationTo"] | undefined | null | Variable<any, string>,
	value?: ValueTypes["JSON"] | undefined | null | Variable<any, string>
};
	["SubscriptionUpdate_OrganiserRelationshipInputRelationTo"]:SubscriptionUpdate_OrganiserRelationshipInputRelationTo;
	["mutationNewsletterInput"]: {
	email: string | Variable<any, string>,
	region?: ValueTypes["Newsletter_region_MutationInput"] | undefined | null | Variable<any, string>,
	updatedAt?: string | undefined | null | Variable<any, string>,
	createdAt?: string | undefined | null | Variable<any, string>
};
	["Newsletter_region_MutationInput"]:Newsletter_region_MutationInput;
	["mutationNewsletterUpdateInput"]: {
	email?: string | undefined | null | Variable<any, string>,
	region?: ValueTypes["NewsletterUpdate_region_MutationInput"] | undefined | null | Variable<any, string>,
	updatedAt?: string | undefined | null | Variable<any, string>,
	createdAt?: string | undefined | null | Variable<any, string>
};
	["NewsletterUpdate_region_MutationInput"]:NewsletterUpdate_region_MutationInput;
	["mutationBlogCategoryInput"]: {
	name?: string | undefined | null | Variable<any, string>,
	value: string | Variable<any, string>,
	icon?: string | undefined | null | Variable<any, string>,
	region?: ValueTypes["BlogCategory_region_MutationInput"] | undefined | null | Variable<any, string>,
	updatedAt?: string | undefined | null | Variable<any, string>,
	createdAt?: string | undefined | null | Variable<any, string>
};
	["BlogCategory_region_MutationInput"]:BlogCategory_region_MutationInput;
	["mutationBlogCategoryUpdateInput"]: {
	name?: string | undefined | null | Variable<any, string>,
	value?: string | undefined | null | Variable<any, string>,
	icon?: string | undefined | null | Variable<any, string>,
	region?: ValueTypes["BlogCategoryUpdate_region_MutationInput"] | undefined | null | Variable<any, string>,
	updatedAt?: string | undefined | null | Variable<any, string>,
	createdAt?: string | undefined | null | Variable<any, string>
};
	["BlogCategoryUpdate_region_MutationInput"]:BlogCategoryUpdate_region_MutationInput;
	["mutationTagInput"]: {
	name: string | Variable<any, string>,
	value: string | Variable<any, string>,
	region?: ValueTypes["Tag_region_MutationInput"] | undefined | null | Variable<any, string>,
	count?: number | undefined | null | Variable<any, string>,
	skipCountCheck?: boolean | undefined | null | Variable<any, string>,
	removeNextCron?: boolean | undefined | null | Variable<any, string>
};
	["Tag_region_MutationInput"]:Tag_region_MutationInput;
	["mutationTagUpdateInput"]: {
	name?: string | undefined | null | Variable<any, string>,
	value?: string | undefined | null | Variable<any, string>,
	region?: ValueTypes["TagUpdate_region_MutationInput"] | undefined | null | Variable<any, string>,
	count?: number | undefined | null | Variable<any, string>,
	skipCountCheck?: boolean | undefined | null | Variable<any, string>,
	removeNextCron?: boolean | undefined | null | Variable<any, string>
};
	["TagUpdate_region_MutationInput"]:TagUpdate_region_MutationInput;
	["mutationSpecialTagInput"]: {
	name: string | Variable<any, string>,
	value: string | Variable<any, string>,
	region?: ValueTypes["SpecialTag_region_MutationInput"] | undefined | null | Variable<any, string>
};
	["SpecialTag_region_MutationInput"]:SpecialTag_region_MutationInput;
	["mutationSpecialTagUpdateInput"]: {
	name?: string | undefined | null | Variable<any, string>,
	value?: string | undefined | null | Variable<any, string>,
	region?: ValueTypes["SpecialTagUpdate_region_MutationInput"] | undefined | null | Variable<any, string>
};
	["SpecialTagUpdate_region_MutationInput"]:SpecialTagUpdate_region_MutationInput;
	["mutationDateInput"]: {
	name?: string | undefined | null | Variable<any, string>,
	value: string | Variable<any, string>,
	type: ValueTypes["Date_type_MutationInput"] | Variable<any, string>,
	start?: string | undefined | null | Variable<any, string>,
	end?: string | undefined | null | Variable<any, string>,
	_title?: string | undefined | null | Variable<any, string>,
	region?: ValueTypes["Date_region_MutationInput"] | undefined | null | Variable<any, string>,
	updatedAt?: string | undefined | null | Variable<any, string>,
	createdAt?: string | undefined | null | Variable<any, string>
};
	["Date_type_MutationInput"]:Date_type_MutationInput;
	["Date_region_MutationInput"]:Date_region_MutationInput;
	["mutationDateUpdateInput"]: {
	name?: string | undefined | null | Variable<any, string>,
	value?: string | undefined | null | Variable<any, string>,
	type?: ValueTypes["DateUpdate_type_MutationInput"] | undefined | null | Variable<any, string>,
	start?: string | undefined | null | Variable<any, string>,
	end?: string | undefined | null | Variable<any, string>,
	_title?: string | undefined | null | Variable<any, string>,
	region?: ValueTypes["DateUpdate_region_MutationInput"] | undefined | null | Variable<any, string>,
	updatedAt?: string | undefined | null | Variable<any, string>,
	createdAt?: string | undefined | null | Variable<any, string>
};
	["DateUpdate_type_MutationInput"]:DateUpdate_type_MutationInput;
	["DateUpdate_region_MutationInput"]:DateUpdate_region_MutationInput;
	["mutationDistrictInput"]: {
	name?: string | undefined | null | Variable<any, string>,
	level: number | Variable<any, string>,
	children?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	value: string | Variable<any, string>,
	lat?: number | undefined | null | Variable<any, string>,
	long?: number | undefined | null | Variable<any, string>,
	radius?: number | undefined | null | Variable<any, string>,
	region?: ValueTypes["District_region_MutationInput"] | undefined | null | Variable<any, string>,
	updatedAt?: string | undefined | null | Variable<any, string>,
	createdAt?: string | undefined | null | Variable<any, string>
};
	["District_region_MutationInput"]:District_region_MutationInput;
	["mutationDistrictUpdateInput"]: {
	name?: string | undefined | null | Variable<any, string>,
	level?: number | undefined | null | Variable<any, string>,
	children?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	value?: string | undefined | null | Variable<any, string>,
	lat?: number | undefined | null | Variable<any, string>,
	long?: number | undefined | null | Variable<any, string>,
	radius?: number | undefined | null | Variable<any, string>,
	region?: ValueTypes["DistrictUpdate_region_MutationInput"] | undefined | null | Variable<any, string>,
	updatedAt?: string | undefined | null | Variable<any, string>,
	createdAt?: string | undefined | null | Variable<any, string>
};
	["DistrictUpdate_region_MutationInput"]:DistrictUpdate_region_MutationInput;
	["mutationAudienceInput"]: {
	name?: string | undefined | null | Variable<any, string>,
	value: string | Variable<any, string>,
	icon?: string | undefined | null | Variable<any, string>,
	region?: ValueTypes["Audience_region_MutationInput"] | undefined | null | Variable<any, string>,
	updatedAt?: string | undefined | null | Variable<any, string>,
	createdAt?: string | undefined | null | Variable<any, string>
};
	["Audience_region_MutationInput"]:Audience_region_MutationInput;
	["mutationAudienceUpdateInput"]: {
	name?: string | undefined | null | Variable<any, string>,
	value?: string | undefined | null | Variable<any, string>,
	icon?: string | undefined | null | Variable<any, string>,
	region?: ValueTypes["AudienceUpdate_region_MutationInput"] | undefined | null | Variable<any, string>,
	updatedAt?: string | undefined | null | Variable<any, string>,
	createdAt?: string | undefined | null | Variable<any, string>
};
	["AudienceUpdate_region_MutationInput"]:AudienceUpdate_region_MutationInput;
	["mutationEventCategoryInput"]: {
	name?: string | undefined | null | Variable<any, string>,
	value: string | Variable<any, string>,
	tags?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	icon?: string | undefined | null | Variable<any, string>,
	region?: ValueTypes["EventCategory_region_MutationInput"] | undefined | null | Variable<any, string>,
	updatedAt?: string | undefined | null | Variable<any, string>,
	createdAt?: string | undefined | null | Variable<any, string>
};
	["EventCategory_region_MutationInput"]:EventCategory_region_MutationInput;
	["mutationEventCategoryUpdateInput"]: {
	name?: string | undefined | null | Variable<any, string>,
	value?: string | undefined | null | Variable<any, string>,
	tags?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	icon?: string | undefined | null | Variable<any, string>,
	region?: ValueTypes["EventCategoryUpdate_region_MutationInput"] | undefined | null | Variable<any, string>,
	updatedAt?: string | undefined | null | Variable<any, string>,
	createdAt?: string | undefined | null | Variable<any, string>
};
	["EventCategoryUpdate_region_MutationInput"]:EventCategoryUpdate_region_MutationInput;
	["mutationPrivateMediaInput"]: {
	alt?: string | undefined | null | Variable<any, string>,
	updatedAt?: string | undefined | null | Variable<any, string>,
	createdAt?: string | undefined | null | Variable<any, string>,
	url?: string | undefined | null | Variable<any, string>,
	filename?: string | undefined | null | Variable<any, string>,
	mimeType?: string | undefined | null | Variable<any, string>,
	filesize?: number | undefined | null | Variable<any, string>,
	width?: number | undefined | null | Variable<any, string>,
	height?: number | undefined | null | Variable<any, string>,
	focalX?: number | undefined | null | Variable<any, string>,
	focalY?: number | undefined | null | Variable<any, string>,
	sizes?: ValueTypes["mutationPrivateMedia_SizesInput"] | undefined | null | Variable<any, string>
};
	["mutationPrivateMedia_SizesInput"]: {
	thumbnail?: ValueTypes["mutationPrivateMedia_Sizes_ThumbnailInput"] | undefined | null | Variable<any, string>,
	portrait?: ValueTypes["mutationPrivateMedia_Sizes_PortraitInput"] | undefined | null | Variable<any, string>,
	hero?: ValueTypes["mutationPrivateMedia_Sizes_HeroInput"] | undefined | null | Variable<any, string>,
	thumbnail_jpeg?: ValueTypes["mutationPrivateMedia_Sizes_Thumbnail_jpegInput"] | undefined | null | Variable<any, string>,
	portrait_jpeg?: ValueTypes["mutationPrivateMedia_Sizes_Portrait_jpegInput"] | undefined | null | Variable<any, string>,
	hero_jpeg?: ValueTypes["mutationPrivateMedia_Sizes_Hero_jpegInput"] | undefined | null | Variable<any, string>
};
	["mutationPrivateMedia_Sizes_ThumbnailInput"]: {
	url?: string | undefined | null | Variable<any, string>,
	width?: number | undefined | null | Variable<any, string>,
	height?: number | undefined | null | Variable<any, string>,
	mimeType?: string | undefined | null | Variable<any, string>,
	filesize?: number | undefined | null | Variable<any, string>,
	filename?: string | undefined | null | Variable<any, string>
};
	["mutationPrivateMedia_Sizes_PortraitInput"]: {
	url?: string | undefined | null | Variable<any, string>,
	width?: number | undefined | null | Variable<any, string>,
	height?: number | undefined | null | Variable<any, string>,
	mimeType?: string | undefined | null | Variable<any, string>,
	filesize?: number | undefined | null | Variable<any, string>,
	filename?: string | undefined | null | Variable<any, string>
};
	["mutationPrivateMedia_Sizes_HeroInput"]: {
	url?: string | undefined | null | Variable<any, string>,
	width?: number | undefined | null | Variable<any, string>,
	height?: number | undefined | null | Variable<any, string>,
	mimeType?: string | undefined | null | Variable<any, string>,
	filesize?: number | undefined | null | Variable<any, string>,
	filename?: string | undefined | null | Variable<any, string>
};
	["mutationPrivateMedia_Sizes_Thumbnail_jpegInput"]: {
	url?: string | undefined | null | Variable<any, string>,
	width?: number | undefined | null | Variable<any, string>,
	height?: number | undefined | null | Variable<any, string>,
	mimeType?: string | undefined | null | Variable<any, string>,
	filesize?: number | undefined | null | Variable<any, string>,
	filename?: string | undefined | null | Variable<any, string>
};
	["mutationPrivateMedia_Sizes_Portrait_jpegInput"]: {
	url?: string | undefined | null | Variable<any, string>,
	width?: number | undefined | null | Variable<any, string>,
	height?: number | undefined | null | Variable<any, string>,
	mimeType?: string | undefined | null | Variable<any, string>,
	filesize?: number | undefined | null | Variable<any, string>,
	filename?: string | undefined | null | Variable<any, string>
};
	["mutationPrivateMedia_Sizes_Hero_jpegInput"]: {
	url?: string | undefined | null | Variable<any, string>,
	width?: number | undefined | null | Variable<any, string>,
	height?: number | undefined | null | Variable<any, string>,
	mimeType?: string | undefined | null | Variable<any, string>,
	filesize?: number | undefined | null | Variable<any, string>,
	filename?: string | undefined | null | Variable<any, string>
};
	["mutationPrivateMediaUpdateInput"]: {
	alt?: string | undefined | null | Variable<any, string>,
	updatedAt?: string | undefined | null | Variable<any, string>,
	createdAt?: string | undefined | null | Variable<any, string>,
	url?: string | undefined | null | Variable<any, string>,
	filename?: string | undefined | null | Variable<any, string>,
	mimeType?: string | undefined | null | Variable<any, string>,
	filesize?: number | undefined | null | Variable<any, string>,
	width?: number | undefined | null | Variable<any, string>,
	height?: number | undefined | null | Variable<any, string>,
	focalX?: number | undefined | null | Variable<any, string>,
	focalY?: number | undefined | null | Variable<any, string>,
	sizes?: ValueTypes["mutationPrivateMediaUpdate_SizesInput"] | undefined | null | Variable<any, string>
};
	["mutationPrivateMediaUpdate_SizesInput"]: {
	thumbnail?: ValueTypes["mutationPrivateMediaUpdate_Sizes_ThumbnailInput"] | undefined | null | Variable<any, string>,
	portrait?: ValueTypes["mutationPrivateMediaUpdate_Sizes_PortraitInput"] | undefined | null | Variable<any, string>,
	hero?: ValueTypes["mutationPrivateMediaUpdate_Sizes_HeroInput"] | undefined | null | Variable<any, string>,
	thumbnail_jpeg?: ValueTypes["mutationPrivateMediaUpdate_Sizes_Thumbnail_jpegInput"] | undefined | null | Variable<any, string>,
	portrait_jpeg?: ValueTypes["mutationPrivateMediaUpdate_Sizes_Portrait_jpegInput"] | undefined | null | Variable<any, string>,
	hero_jpeg?: ValueTypes["mutationPrivateMediaUpdate_Sizes_Hero_jpegInput"] | undefined | null | Variable<any, string>
};
	["mutationPrivateMediaUpdate_Sizes_ThumbnailInput"]: {
	url?: string | undefined | null | Variable<any, string>,
	width?: number | undefined | null | Variable<any, string>,
	height?: number | undefined | null | Variable<any, string>,
	mimeType?: string | undefined | null | Variable<any, string>,
	filesize?: number | undefined | null | Variable<any, string>,
	filename?: string | undefined | null | Variable<any, string>
};
	["mutationPrivateMediaUpdate_Sizes_PortraitInput"]: {
	url?: string | undefined | null | Variable<any, string>,
	width?: number | undefined | null | Variable<any, string>,
	height?: number | undefined | null | Variable<any, string>,
	mimeType?: string | undefined | null | Variable<any, string>,
	filesize?: number | undefined | null | Variable<any, string>,
	filename?: string | undefined | null | Variable<any, string>
};
	["mutationPrivateMediaUpdate_Sizes_HeroInput"]: {
	url?: string | undefined | null | Variable<any, string>,
	width?: number | undefined | null | Variable<any, string>,
	height?: number | undefined | null | Variable<any, string>,
	mimeType?: string | undefined | null | Variable<any, string>,
	filesize?: number | undefined | null | Variable<any, string>,
	filename?: string | undefined | null | Variable<any, string>
};
	["mutationPrivateMediaUpdate_Sizes_Thumbnail_jpegInput"]: {
	url?: string | undefined | null | Variable<any, string>,
	width?: number | undefined | null | Variable<any, string>,
	height?: number | undefined | null | Variable<any, string>,
	mimeType?: string | undefined | null | Variable<any, string>,
	filesize?: number | undefined | null | Variable<any, string>,
	filename?: string | undefined | null | Variable<any, string>
};
	["mutationPrivateMediaUpdate_Sizes_Portrait_jpegInput"]: {
	url?: string | undefined | null | Variable<any, string>,
	width?: number | undefined | null | Variable<any, string>,
	height?: number | undefined | null | Variable<any, string>,
	mimeType?: string | undefined | null | Variable<any, string>,
	filesize?: number | undefined | null | Variable<any, string>,
	filename?: string | undefined | null | Variable<any, string>
};
	["mutationPrivateMediaUpdate_Sizes_Hero_jpegInput"]: {
	url?: string | undefined | null | Variable<any, string>,
	width?: number | undefined | null | Variable<any, string>,
	height?: number | undefined | null | Variable<any, string>,
	mimeType?: string | undefined | null | Variable<any, string>,
	filesize?: number | undefined | null | Variable<any, string>,
	filename?: string | undefined | null | Variable<any, string>
};
	["mutationMediaInput"]: {
	alt?: string | undefined | null | Variable<any, string>,
	updatedAt?: string | undefined | null | Variable<any, string>,
	createdAt?: string | undefined | null | Variable<any, string>,
	url?: string | undefined | null | Variable<any, string>,
	filename?: string | undefined | null | Variable<any, string>,
	mimeType?: string | undefined | null | Variable<any, string>,
	filesize?: number | undefined | null | Variable<any, string>,
	width?: number | undefined | null | Variable<any, string>,
	height?: number | undefined | null | Variable<any, string>,
	focalX?: number | undefined | null | Variable<any, string>,
	focalY?: number | undefined | null | Variable<any, string>,
	sizes?: ValueTypes["mutationMedia_SizesInput"] | undefined | null | Variable<any, string>
};
	["mutationMedia_SizesInput"]: {
	small?: ValueTypes["mutationMedia_Sizes_SmallInput"] | undefined | null | Variable<any, string>,
	medium?: ValueTypes["mutationMedia_Sizes_MediumInput"] | undefined | null | Variable<any, string>,
	large?: ValueTypes["mutationMedia_Sizes_LargeInput"] | undefined | null | Variable<any, string>,
	small_jpeg?: ValueTypes["mutationMedia_Sizes_Small_jpegInput"] | undefined | null | Variable<any, string>,
	medium_jpeg?: ValueTypes["mutationMedia_Sizes_Medium_jpegInput"] | undefined | null | Variable<any, string>,
	large_jpeg?: ValueTypes["mutationMedia_Sizes_Large_jpegInput"] | undefined | null | Variable<any, string>
};
	["mutationMedia_Sizes_SmallInput"]: {
	url?: string | undefined | null | Variable<any, string>,
	width?: number | undefined | null | Variable<any, string>,
	height?: number | undefined | null | Variable<any, string>,
	mimeType?: string | undefined | null | Variable<any, string>,
	filesize?: number | undefined | null | Variable<any, string>,
	filename?: string | undefined | null | Variable<any, string>
};
	["mutationMedia_Sizes_MediumInput"]: {
	url?: string | undefined | null | Variable<any, string>,
	width?: number | undefined | null | Variable<any, string>,
	height?: number | undefined | null | Variable<any, string>,
	mimeType?: string | undefined | null | Variable<any, string>,
	filesize?: number | undefined | null | Variable<any, string>,
	filename?: string | undefined | null | Variable<any, string>
};
	["mutationMedia_Sizes_LargeInput"]: {
	url?: string | undefined | null | Variable<any, string>,
	width?: number | undefined | null | Variable<any, string>,
	height?: number | undefined | null | Variable<any, string>,
	mimeType?: string | undefined | null | Variable<any, string>,
	filesize?: number | undefined | null | Variable<any, string>,
	filename?: string | undefined | null | Variable<any, string>
};
	["mutationMedia_Sizes_Small_jpegInput"]: {
	url?: string | undefined | null | Variable<any, string>,
	width?: number | undefined | null | Variable<any, string>,
	height?: number | undefined | null | Variable<any, string>,
	mimeType?: string | undefined | null | Variable<any, string>,
	filesize?: number | undefined | null | Variable<any, string>,
	filename?: string | undefined | null | Variable<any, string>
};
	["mutationMedia_Sizes_Medium_jpegInput"]: {
	url?: string | undefined | null | Variable<any, string>,
	width?: number | undefined | null | Variable<any, string>,
	height?: number | undefined | null | Variable<any, string>,
	mimeType?: string | undefined | null | Variable<any, string>,
	filesize?: number | undefined | null | Variable<any, string>,
	filename?: string | undefined | null | Variable<any, string>
};
	["mutationMedia_Sizes_Large_jpegInput"]: {
	url?: string | undefined | null | Variable<any, string>,
	width?: number | undefined | null | Variable<any, string>,
	height?: number | undefined | null | Variable<any, string>,
	mimeType?: string | undefined | null | Variable<any, string>,
	filesize?: number | undefined | null | Variable<any, string>,
	filename?: string | undefined | null | Variable<any, string>
};
	["mutationMediaUpdateInput"]: {
	alt?: string | undefined | null | Variable<any, string>,
	updatedAt?: string | undefined | null | Variable<any, string>,
	createdAt?: string | undefined | null | Variable<any, string>,
	url?: string | undefined | null | Variable<any, string>,
	filename?: string | undefined | null | Variable<any, string>,
	mimeType?: string | undefined | null | Variable<any, string>,
	filesize?: number | undefined | null | Variable<any, string>,
	width?: number | undefined | null | Variable<any, string>,
	height?: number | undefined | null | Variable<any, string>,
	focalX?: number | undefined | null | Variable<any, string>,
	focalY?: number | undefined | null | Variable<any, string>,
	sizes?: ValueTypes["mutationMediaUpdate_SizesInput"] | undefined | null | Variable<any, string>
};
	["mutationMediaUpdate_SizesInput"]: {
	small?: ValueTypes["mutationMediaUpdate_Sizes_SmallInput"] | undefined | null | Variable<any, string>,
	medium?: ValueTypes["mutationMediaUpdate_Sizes_MediumInput"] | undefined | null | Variable<any, string>,
	large?: ValueTypes["mutationMediaUpdate_Sizes_LargeInput"] | undefined | null | Variable<any, string>,
	small_jpeg?: ValueTypes["mutationMediaUpdate_Sizes_Small_jpegInput"] | undefined | null | Variable<any, string>,
	medium_jpeg?: ValueTypes["mutationMediaUpdate_Sizes_Medium_jpegInput"] | undefined | null | Variable<any, string>,
	large_jpeg?: ValueTypes["mutationMediaUpdate_Sizes_Large_jpegInput"] | undefined | null | Variable<any, string>
};
	["mutationMediaUpdate_Sizes_SmallInput"]: {
	url?: string | undefined | null | Variable<any, string>,
	width?: number | undefined | null | Variable<any, string>,
	height?: number | undefined | null | Variable<any, string>,
	mimeType?: string | undefined | null | Variable<any, string>,
	filesize?: number | undefined | null | Variable<any, string>,
	filename?: string | undefined | null | Variable<any, string>
};
	["mutationMediaUpdate_Sizes_MediumInput"]: {
	url?: string | undefined | null | Variable<any, string>,
	width?: number | undefined | null | Variable<any, string>,
	height?: number | undefined | null | Variable<any, string>,
	mimeType?: string | undefined | null | Variable<any, string>,
	filesize?: number | undefined | null | Variable<any, string>,
	filename?: string | undefined | null | Variable<any, string>
};
	["mutationMediaUpdate_Sizes_LargeInput"]: {
	url?: string | undefined | null | Variable<any, string>,
	width?: number | undefined | null | Variable<any, string>,
	height?: number | undefined | null | Variable<any, string>,
	mimeType?: string | undefined | null | Variable<any, string>,
	filesize?: number | undefined | null | Variable<any, string>,
	filename?: string | undefined | null | Variable<any, string>
};
	["mutationMediaUpdate_Sizes_Small_jpegInput"]: {
	url?: string | undefined | null | Variable<any, string>,
	width?: number | undefined | null | Variable<any, string>,
	height?: number | undefined | null | Variable<any, string>,
	mimeType?: string | undefined | null | Variable<any, string>,
	filesize?: number | undefined | null | Variable<any, string>,
	filename?: string | undefined | null | Variable<any, string>
};
	["mutationMediaUpdate_Sizes_Medium_jpegInput"]: {
	url?: string | undefined | null | Variable<any, string>,
	width?: number | undefined | null | Variable<any, string>,
	height?: number | undefined | null | Variable<any, string>,
	mimeType?: string | undefined | null | Variable<any, string>,
	filesize?: number | undefined | null | Variable<any, string>,
	filename?: string | undefined | null | Variable<any, string>
};
	["mutationMediaUpdate_Sizes_Large_jpegInput"]: {
	url?: string | undefined | null | Variable<any, string>,
	width?: number | undefined | null | Variable<any, string>,
	height?: number | undefined | null | Variable<any, string>,
	mimeType?: string | undefined | null | Variable<any, string>,
	filesize?: number | undefined | null | Variable<any, string>,
	filename?: string | undefined | null | Variable<any, string>
};
	["mutationBackupMediaInput"]: {
	alt?: string | undefined | null | Variable<any, string>,
	creator?: string | undefined | null | Variable<any, string>,
	updatedAt?: string | undefined | null | Variable<any, string>,
	createdAt?: string | undefined | null | Variable<any, string>,
	url?: string | undefined | null | Variable<any, string>,
	filename?: string | undefined | null | Variable<any, string>,
	mimeType?: string | undefined | null | Variable<any, string>,
	filesize?: number | undefined | null | Variable<any, string>,
	width?: number | undefined | null | Variable<any, string>,
	height?: number | undefined | null | Variable<any, string>,
	focalX?: number | undefined | null | Variable<any, string>,
	focalY?: number | undefined | null | Variable<any, string>,
	sizes?: ValueTypes["mutationBackupMedia_SizesInput"] | undefined | null | Variable<any, string>
};
	["mutationBackupMedia_SizesInput"]: {
	thumbnail?: ValueTypes["mutationBackupMedia_Sizes_ThumbnailInput"] | undefined | null | Variable<any, string>,
	sixteenByNineMedium?: ValueTypes["mutationBackupMedia_Sizes_SixteenByNineMediumInput"] | undefined | null | Variable<any, string>
};
	["mutationBackupMedia_Sizes_ThumbnailInput"]: {
	url?: string | undefined | null | Variable<any, string>,
	width?: number | undefined | null | Variable<any, string>,
	height?: number | undefined | null | Variable<any, string>,
	mimeType?: string | undefined | null | Variable<any, string>,
	filesize?: number | undefined | null | Variable<any, string>,
	filename?: string | undefined | null | Variable<any, string>
};
	["mutationBackupMedia_Sizes_SixteenByNineMediumInput"]: {
	url?: string | undefined | null | Variable<any, string>,
	width?: number | undefined | null | Variable<any, string>,
	height?: number | undefined | null | Variable<any, string>,
	mimeType?: string | undefined | null | Variable<any, string>,
	filesize?: number | undefined | null | Variable<any, string>,
	filename?: string | undefined | null | Variable<any, string>
};
	["mutationBackupMediaUpdateInput"]: {
	alt?: string | undefined | null | Variable<any, string>,
	creator?: string | undefined | null | Variable<any, string>,
	updatedAt?: string | undefined | null | Variable<any, string>,
	createdAt?: string | undefined | null | Variable<any, string>,
	url?: string | undefined | null | Variable<any, string>,
	filename?: string | undefined | null | Variable<any, string>,
	mimeType?: string | undefined | null | Variable<any, string>,
	filesize?: number | undefined | null | Variable<any, string>,
	width?: number | undefined | null | Variable<any, string>,
	height?: number | undefined | null | Variable<any, string>,
	focalX?: number | undefined | null | Variable<any, string>,
	focalY?: number | undefined | null | Variable<any, string>,
	sizes?: ValueTypes["mutationBackupMediaUpdate_SizesInput"] | undefined | null | Variable<any, string>
};
	["mutationBackupMediaUpdate_SizesInput"]: {
	thumbnail?: ValueTypes["mutationBackupMediaUpdate_Sizes_ThumbnailInput"] | undefined | null | Variable<any, string>,
	sixteenByNineMedium?: ValueTypes["mutationBackupMediaUpdate_Sizes_SixteenByNineMediumInput"] | undefined | null | Variable<any, string>
};
	["mutationBackupMediaUpdate_Sizes_ThumbnailInput"]: {
	url?: string | undefined | null | Variable<any, string>,
	width?: number | undefined | null | Variable<any, string>,
	height?: number | undefined | null | Variable<any, string>,
	mimeType?: string | undefined | null | Variable<any, string>,
	filesize?: number | undefined | null | Variable<any, string>,
	filename?: string | undefined | null | Variable<any, string>
};
	["mutationBackupMediaUpdate_Sizes_SixteenByNineMediumInput"]: {
	url?: string | undefined | null | Variable<any, string>,
	width?: number | undefined | null | Variable<any, string>,
	height?: number | undefined | null | Variable<any, string>,
	mimeType?: string | undefined | null | Variable<any, string>,
	filesize?: number | undefined | null | Variable<any, string>,
	filename?: string | undefined | null | Variable<any, string>
};
	["mutationAccountSuspendRequestInput"]: {
	user?: string | undefined | null | Variable<any, string>,
	handledBy?: string | undefined | null | Variable<any, string>,
	updatedAt?: string | undefined | null | Variable<any, string>,
	createdAt?: string | undefined | null | Variable<any, string>
};
	["mutationAccountSuspendRequestUpdateInput"]: {
	user?: string | undefined | null | Variable<any, string>,
	handledBy?: string | undefined | null | Variable<any, string>,
	updatedAt?: string | undefined | null | Variable<any, string>,
	createdAt?: string | undefined | null | Variable<any, string>
};
	["mutationDeviceInput"]: {
	user?: ValueTypes["Device_UserRelationshipInput"] | undefined | null | Variable<any, string>,
	udid?: string | undefined | null | Variable<any, string>,
	pushToken?: string | undefined | null | Variable<any, string>,
	updatedAt?: string | undefined | null | Variable<any, string>,
	createdAt?: string | undefined | null | Variable<any, string>
};
	["Device_UserRelationshipInput"]: {
	relationTo?: ValueTypes["Device_UserRelationshipInputRelationTo"] | undefined | null | Variable<any, string>,
	value?: ValueTypes["JSON"] | undefined | null | Variable<any, string>
};
	["Device_UserRelationshipInputRelationTo"]:Device_UserRelationshipInputRelationTo;
	["mutationDeviceUpdateInput"]: {
	user?: ValueTypes["DeviceUpdate_UserRelationshipInput"] | undefined | null | Variable<any, string>,
	udid?: string | undefined | null | Variable<any, string>,
	pushToken?: string | undefined | null | Variable<any, string>,
	updatedAt?: string | undefined | null | Variable<any, string>,
	createdAt?: string | undefined | null | Variable<any, string>
};
	["DeviceUpdate_UserRelationshipInput"]: {
	relationTo?: ValueTypes["DeviceUpdate_UserRelationshipInputRelationTo"] | undefined | null | Variable<any, string>,
	value?: ValueTypes["JSON"] | undefined | null | Variable<any, string>
};
	["DeviceUpdate_UserRelationshipInputRelationTo"]:DeviceUpdate_UserRelationshipInputRelationTo;
	["mutationNotificationInput"]: {
	receiver?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	type?: ValueTypes["Notification_type_MutationInput"] | undefined | null | Variable<any, string>,
	meta?: ValueTypes["Notification_MetaRelationshipInput"] | undefined | null | Variable<any, string>,
	message?: string | undefined | null | Variable<any, string>,
	sender?: string | undefined | null | Variable<any, string>,
	readed?: boolean | undefined | null | Variable<any, string>,
	deletedByUser?: boolean | undefined | null | Variable<any, string>,
	updatedAt?: string | undefined | null | Variable<any, string>,
	createdAt?: string | undefined | null | Variable<any, string>
};
	["Notification_type_MutationInput"]:Notification_type_MutationInput;
	["Notification_MetaRelationshipInput"]: {
	relationTo?: ValueTypes["Notification_MetaRelationshipInputRelationTo"] | undefined | null | Variable<any, string>,
	value?: ValueTypes["JSON"] | undefined | null | Variable<any, string>
};
	["Notification_MetaRelationshipInputRelationTo"]:Notification_MetaRelationshipInputRelationTo;
	["mutationNotificationUpdateInput"]: {
	receiver?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	type?: ValueTypes["NotificationUpdate_type_MutationInput"] | undefined | null | Variable<any, string>,
	meta?: ValueTypes["NotificationUpdate_MetaRelationshipInput"] | undefined | null | Variable<any, string>,
	message?: string | undefined | null | Variable<any, string>,
	sender?: string | undefined | null | Variable<any, string>,
	readed?: boolean | undefined | null | Variable<any, string>,
	deletedByUser?: boolean | undefined | null | Variable<any, string>,
	updatedAt?: string | undefined | null | Variable<any, string>,
	createdAt?: string | undefined | null | Variable<any, string>
};
	["NotificationUpdate_type_MutationInput"]:NotificationUpdate_type_MutationInput;
	["NotificationUpdate_MetaRelationshipInput"]: {
	relationTo?: ValueTypes["NotificationUpdate_MetaRelationshipInputRelationTo"] | undefined | null | Variable<any, string>,
	value?: ValueTypes["JSON"] | undefined | null | Variable<any, string>
};
	["NotificationUpdate_MetaRelationshipInputRelationTo"]:NotificationUpdate_MetaRelationshipInputRelationTo;
	["mutationRedirectSettingInput"]: {
	from?: string | undefined | null | Variable<any, string>,
	to?: string | undefined | null | Variable<any, string>,
	updatedAt?: string | undefined | null | Variable<any, string>,
	createdAt?: string | undefined | null | Variable<any, string>
};
	["mutationRedirectSettingUpdateInput"]: {
	from?: string | undefined | null | Variable<any, string>,
	to?: string | undefined | null | Variable<any, string>,
	updatedAt?: string | undefined | null | Variable<any, string>,
	createdAt?: string | undefined | null | Variable<any, string>
};
	["mutationEventRankingInput"]: {
	name?: string | undefined | null | Variable<any, string>,
	visible?: boolean | undefined | null | Variable<any, string>,
	maxItems: number | Variable<any, string>,
	template: ValueTypes["EventRanking_template_MutationInput"] | Variable<any, string>,
	list?: Array<ValueTypes["mutationEventRanking_ListInput"] | undefined | null> | undefined | null | Variable<any, string>,
	region?: ValueTypes["EventRanking_region_MutationInput"] | undefined | null | Variable<any, string>,
	updatedAt?: string | undefined | null | Variable<any, string>,
	createdAt?: string | undefined | null | Variable<any, string>
};
	["EventRanking_template_MutationInput"]:EventRanking_template_MutationInput;
	["mutationEventRanking_ListInput"]: {
	item?: string | undefined | null | Variable<any, string>,
	id?: string | undefined | null | Variable<any, string>
};
	["EventRanking_region_MutationInput"]:EventRanking_region_MutationInput;
	["mutationEventRankingUpdateInput"]: {
	name?: string | undefined | null | Variable<any, string>,
	visible?: boolean | undefined | null | Variable<any, string>,
	maxItems?: number | undefined | null | Variable<any, string>,
	template?: ValueTypes["EventRankingUpdate_template_MutationInput"] | undefined | null | Variable<any, string>,
	list?: Array<ValueTypes["mutationEventRankingUpdate_ListInput"] | undefined | null> | undefined | null | Variable<any, string>,
	region?: ValueTypes["EventRankingUpdate_region_MutationInput"] | undefined | null | Variable<any, string>,
	updatedAt?: string | undefined | null | Variable<any, string>,
	createdAt?: string | undefined | null | Variable<any, string>
};
	["EventRankingUpdate_template_MutationInput"]:EventRankingUpdate_template_MutationInput;
	["mutationEventRankingUpdate_ListInput"]: {
	item?: string | undefined | null | Variable<any, string>,
	id?: string | undefined | null | Variable<any, string>
};
	["EventRankingUpdate_region_MutationInput"]:EventRankingUpdate_region_MutationInput;
	["mutationLocationRankingInput"]: {
	name?: string | undefined | null | Variable<any, string>,
	visible?: boolean | undefined | null | Variable<any, string>,
	maxItems?: number | undefined | null | Variable<any, string>,
	template: ValueTypes["LocationRanking_template_MutationInput"] | Variable<any, string>,
	list?: Array<ValueTypes["mutationLocationRanking_ListInput"] | undefined | null> | undefined | null | Variable<any, string>,
	region?: ValueTypes["LocationRanking_region_MutationInput"] | undefined | null | Variable<any, string>,
	updatedAt?: string | undefined | null | Variable<any, string>,
	createdAt?: string | undefined | null | Variable<any, string>
};
	["LocationRanking_template_MutationInput"]:LocationRanking_template_MutationInput;
	["mutationLocationRanking_ListInput"]: {
	item?: string | undefined | null | Variable<any, string>,
	id?: string | undefined | null | Variable<any, string>
};
	["LocationRanking_region_MutationInput"]:LocationRanking_region_MutationInput;
	["mutationLocationRankingUpdateInput"]: {
	name?: string | undefined | null | Variable<any, string>,
	visible?: boolean | undefined | null | Variable<any, string>,
	maxItems?: number | undefined | null | Variable<any, string>,
	template?: ValueTypes["LocationRankingUpdate_template_MutationInput"] | undefined | null | Variable<any, string>,
	list?: Array<ValueTypes["mutationLocationRankingUpdate_ListInput"] | undefined | null> | undefined | null | Variable<any, string>,
	region?: ValueTypes["LocationRankingUpdate_region_MutationInput"] | undefined | null | Variable<any, string>,
	updatedAt?: string | undefined | null | Variable<any, string>,
	createdAt?: string | undefined | null | Variable<any, string>
};
	["LocationRankingUpdate_template_MutationInput"]:LocationRankingUpdate_template_MutationInput;
	["mutationLocationRankingUpdate_ListInput"]: {
	item?: string | undefined | null | Variable<any, string>,
	id?: string | undefined | null | Variable<any, string>
};
	["LocationRankingUpdate_region_MutationInput"]:LocationRankingUpdate_region_MutationInput;
	["mutationEventCalendarInput"]: {
	date: string | Variable<any, string>,
	item?: string | undefined | null | Variable<any, string>,
	title?: string | undefined | null | Variable<any, string>,
	region?: ValueTypes["EventCalendar_region_MutationInput"] | undefined | null | Variable<any, string>,
	updatedAt?: string | undefined | null | Variable<any, string>,
	createdAt?: string | undefined | null | Variable<any, string>
};
	["EventCalendar_region_MutationInput"]:EventCalendar_region_MutationInput;
	["mutationEventCalendarUpdateInput"]: {
	date?: string | undefined | null | Variable<any, string>,
	item?: string | undefined | null | Variable<any, string>,
	title?: string | undefined | null | Variable<any, string>,
	region?: ValueTypes["EventCalendarUpdate_region_MutationInput"] | undefined | null | Variable<any, string>,
	updatedAt?: string | undefined | null | Variable<any, string>,
	createdAt?: string | undefined | null | Variable<any, string>
};
	["EventCalendarUpdate_region_MutationInput"]:EventCalendarUpdate_region_MutationInput;
	["mutationEditorChoiceInput"]: {
	name?: string | undefined | null | Variable<any, string>,
	visible?: boolean | undefined | null | Variable<any, string>,
	editorialPicks?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	list?: Array<ValueTypes["mutationEditorChoice_ListInput"] | undefined | null> | undefined | null | Variable<any, string>,
	region?: ValueTypes["EditorChoice_region_MutationInput"] | undefined | null | Variable<any, string>,
	updatedAt?: string | undefined | null | Variable<any, string>,
	createdAt?: string | undefined | null | Variable<any, string>
};
	["mutationEditorChoice_ListInput"]: {
	item?: string | undefined | null | Variable<any, string>,
	id?: string | undefined | null | Variable<any, string>
};
	["EditorChoice_region_MutationInput"]:EditorChoice_region_MutationInput;
	["mutationEditorChoiceUpdateInput"]: {
	name?: string | undefined | null | Variable<any, string>,
	visible?: boolean | undefined | null | Variable<any, string>,
	editorialPicks?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	list?: Array<ValueTypes["mutationEditorChoiceUpdate_ListInput"] | undefined | null> | undefined | null | Variable<any, string>,
	region?: ValueTypes["EditorChoiceUpdate_region_MutationInput"] | undefined | null | Variable<any, string>,
	updatedAt?: string | undefined | null | Variable<any, string>,
	createdAt?: string | undefined | null | Variable<any, string>
};
	["mutationEditorChoiceUpdate_ListInput"]: {
	item?: string | undefined | null | Variable<any, string>,
	id?: string | undefined | null | Variable<any, string>
};
	["EditorChoiceUpdate_region_MutationInput"]:EditorChoiceUpdate_region_MutationInput;
	["mutationCriteriaSettingInput"]: {
	criteria?: ValueTypes["mutationCriteriaSetting_CriteriaInput"] | undefined | null | Variable<any, string>,
	updatedAt?: string | undefined | null | Variable<any, string>,
	createdAt?: string | undefined | null | Variable<any, string>
};
	["mutationCriteriaSetting_CriteriaInput"]: {
	date?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	audience?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	category?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	tag?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	region?: ValueTypes["CriteriaSetting_Criteria_region_MutationInput"] | undefined | null | Variable<any, string>
};
	["CriteriaSetting_Criteria_region_MutationInput"]:CriteriaSetting_Criteria_region_MutationInput;
	["mutationCriteriaSettingUpdateInput"]: {
	criteria?: ValueTypes["mutationCriteriaSettingUpdate_CriteriaInput"] | undefined | null | Variable<any, string>,
	updatedAt?: string | undefined | null | Variable<any, string>,
	createdAt?: string | undefined | null | Variable<any, string>
};
	["mutationCriteriaSettingUpdate_CriteriaInput"]: {
	date?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	audience?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	category?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	tag?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	region?: ValueTypes["CriteriaSettingUpdate_Criteria_region_MutationInput"] | undefined | null | Variable<any, string>
};
	["CriteriaSettingUpdate_Criteria_region_MutationInput"]:CriteriaSettingUpdate_Criteria_region_MutationInput;
	["mutationOrganiserSettingInput"]: {
	name?: string | undefined | null | Variable<any, string>,
	organiserList?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	region?: ValueTypes["OrganiserSetting_region_MutationInput"] | undefined | null | Variable<any, string>,
	updatedAt?: string | undefined | null | Variable<any, string>,
	createdAt?: string | undefined | null | Variable<any, string>
};
	["OrganiserSetting_region_MutationInput"]:OrganiserSetting_region_MutationInput;
	["mutationOrganiserSettingUpdateInput"]: {
	name?: string | undefined | null | Variable<any, string>,
	organiserList?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	region?: ValueTypes["OrganiserSettingUpdate_region_MutationInput"] | undefined | null | Variable<any, string>,
	updatedAt?: string | undefined | null | Variable<any, string>,
	createdAt?: string | undefined | null | Variable<any, string>
};
	["OrganiserSettingUpdate_region_MutationInput"]:OrganiserSettingUpdate_region_MutationInput;
	["mutationPostSettingInput"]: {
	name?: string | undefined | null | Variable<any, string>,
	featuredPosts?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	region?: ValueTypes["PostSetting_region_MutationInput"] | undefined | null | Variable<any, string>,
	updatedAt?: string | undefined | null | Variable<any, string>,
	createdAt?: string | undefined | null | Variable<any, string>
};
	["PostSetting_region_MutationInput"]:PostSetting_region_MutationInput;
	["mutationPostSettingUpdateInput"]: {
	name?: string | undefined | null | Variable<any, string>,
	featuredPosts?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	region?: ValueTypes["PostSettingUpdate_region_MutationInput"] | undefined | null | Variable<any, string>,
	updatedAt?: string | undefined | null | Variable<any, string>,
	createdAt?: string | undefined | null | Variable<any, string>
};
	["PostSettingUpdate_region_MutationInput"]:PostSettingUpdate_region_MutationInput;
	["mutationHomepageSettingInput"]: {
	name?: string | undefined | null | Variable<any, string>,
	region?: ValueTypes["HomepageSetting_region_MutationInput"] | undefined | null | Variable<any, string>,
	sections?: ValueTypes["JSON"] | undefined | null | Variable<any, string>,
	updatedAt?: string | undefined | null | Variable<any, string>,
	createdAt?: string | undefined | null | Variable<any, string>
};
	["HomepageSetting_region_MutationInput"]:HomepageSetting_region_MutationInput;
	["mutationHomepageSettingUpdateInput"]: {
	name?: string | undefined | null | Variable<any, string>,
	region?: ValueTypes["HomepageSettingUpdate_region_MutationInput"] | undefined | null | Variable<any, string>,
	sections?: ValueTypes["JSON"] | undefined | null | Variable<any, string>,
	updatedAt?: string | undefined | null | Variable<any, string>,
	createdAt?: string | undefined | null | Variable<any, string>
};
	["HomepageSettingUpdate_region_MutationInput"]:HomepageSettingUpdate_region_MutationInput;
	["mutationBannerSectionInput"]: {
	name?: string | undefined | null | Variable<any, string>,
	slug?: string | undefined | null | Variable<any, string>,
	banners?: Array<ValueTypes["mutationBannerSection_BannersInput"] | undefined | null> | undefined | null | Variable<any, string>,
	updatedAt?: string | undefined | null | Variable<any, string>,
	createdAt?: string | undefined | null | Variable<any, string>
};
	["mutationBannerSection_BannersInput"]: {
	name?: string | undefined | null | Variable<any, string>,
	status?: string | undefined | null | Variable<any, string>,
	desktop: string | Variable<any, string>,
	bannerStartDate?: string | undefined | null | Variable<any, string>,
	bannerEndDate?: string | undefined | null | Variable<any, string>,
	region?: ValueTypes["BannerSection_Banners_region_MutationInput"] | undefined | null | Variable<any, string>,
	id?: string | undefined | null | Variable<any, string>
};
	["BannerSection_Banners_region_MutationInput"]:BannerSection_Banners_region_MutationInput;
	["mutationBannerSectionUpdateInput"]: {
	name?: string | undefined | null | Variable<any, string>,
	slug?: string | undefined | null | Variable<any, string>,
	banners?: Array<ValueTypes["mutationBannerSectionUpdate_BannersInput"] | undefined | null> | undefined | null | Variable<any, string>,
	updatedAt?: string | undefined | null | Variable<any, string>,
	createdAt?: string | undefined | null | Variable<any, string>
};
	["mutationBannerSectionUpdate_BannersInput"]: {
	name?: string | undefined | null | Variable<any, string>,
	status?: string | undefined | null | Variable<any, string>,
	desktop: string | Variable<any, string>,
	bannerStartDate?: string | undefined | null | Variable<any, string>,
	bannerEndDate?: string | undefined | null | Variable<any, string>,
	region?: ValueTypes["BannerSectionUpdate_Banners_region_MutationInput"] | undefined | null | Variable<any, string>,
	id?: string | undefined | null | Variable<any, string>
};
	["BannerSectionUpdate_Banners_region_MutationInput"]:BannerSectionUpdate_Banners_region_MutationInput;
	["mutationMacaoBannerSectionInput"]: {
	name?: string | undefined | null | Variable<any, string>,
	slug?: string | undefined | null | Variable<any, string>,
	banners?: Array<ValueTypes["mutationMacaoBannerSection_BannersInput"] | undefined | null> | undefined | null | Variable<any, string>,
	updatedAt?: string | undefined | null | Variable<any, string>,
	createdAt?: string | undefined | null | Variable<any, string>
};
	["mutationMacaoBannerSection_BannersInput"]: {
	name?: string | undefined | null | Variable<any, string>,
	status?: string | undefined | null | Variable<any, string>,
	desktop: string | Variable<any, string>,
	bannerStartDate?: string | undefined | null | Variable<any, string>,
	bannerEndDate?: string | undefined | null | Variable<any, string>,
	region?: ValueTypes["MacaoBannerSection_Banners_region_MutationInput"] | undefined | null | Variable<any, string>,
	id?: string | undefined | null | Variable<any, string>
};
	["MacaoBannerSection_Banners_region_MutationInput"]:MacaoBannerSection_Banners_region_MutationInput;
	["mutationMacaoBannerSectionUpdateInput"]: {
	name?: string | undefined | null | Variable<any, string>,
	slug?: string | undefined | null | Variable<any, string>,
	banners?: Array<ValueTypes["mutationMacaoBannerSectionUpdate_BannersInput"] | undefined | null> | undefined | null | Variable<any, string>,
	updatedAt?: string | undefined | null | Variable<any, string>,
	createdAt?: string | undefined | null | Variable<any, string>
};
	["mutationMacaoBannerSectionUpdate_BannersInput"]: {
	name?: string | undefined | null | Variable<any, string>,
	status?: string | undefined | null | Variable<any, string>,
	desktop: string | Variable<any, string>,
	bannerStartDate?: string | undefined | null | Variable<any, string>,
	bannerEndDate?: string | undefined | null | Variable<any, string>,
	region?: ValueTypes["MacaoBannerSectionUpdate_Banners_region_MutationInput"] | undefined | null | Variable<any, string>,
	id?: string | undefined | null | Variable<any, string>
};
	["MacaoBannerSectionUpdate_Banners_region_MutationInput"]:MacaoBannerSectionUpdate_Banners_region_MutationInput;
	["mutationBannerInput"]: {
	name: string | Variable<any, string>,
	desktop: string | Variable<any, string>,
	mobile: string | Variable<any, string>,
	bannerStartDate: string | Variable<any, string>,
	bannerEndDate: string | Variable<any, string>,
	location?: ValueTypes["mutationBanner_LocationInput"] | undefined | null | Variable<any, string>,
	link?: string | undefined | null | Variable<any, string>,
	external?: boolean | undefined | null | Variable<any, string>,
	type?: ValueTypes["Banner_type_MutationInput"] | undefined | null | Variable<any, string>,
	status?: ValueTypes["Banner_status_MutationInput"] | undefined | null | Variable<any, string>,
	advertise: ValueTypes["mutationBanner_AdvertiseInput"] | Variable<any, string>,
	_locations?: string | undefined | null | Variable<any, string>,
	region?: ValueTypes["Banner_region_MutationInput"] | undefined | null | Variable<any, string>,
	homePageClick?: number | undefined | null | Variable<any, string>,
	homePageImpression?: number | undefined | null | Variable<any, string>,
	latestEventClick?: number | undefined | null | Variable<any, string>,
	latestEventImpression?: number | undefined | null | Variable<any, string>,
	editorChoiceClick?: number | undefined | null | Variable<any, string>,
	editorChoiceImpression?: number | undefined | null | Variable<any, string>,
	eventListClick?: number | undefined | null | Variable<any, string>,
	eventListImpression?: number | undefined | null | Variable<any, string>,
	topTenClick?: number | undefined | null | Variable<any, string>,
	topTenImpression?: number | undefined | null | Variable<any, string>,
	miscClick?: number | undefined | null | Variable<any, string>,
	miscImpression?: number | undefined | null | Variable<any, string>,
	clickTotal?: number | undefined | null | Variable<any, string>,
	totalImpression?: number | undefined | null | Variable<any, string>,
	updatedAt?: string | undefined | null | Variable<any, string>,
	createdAt?: string | undefined | null | Variable<any, string>
};
	["mutationBanner_LocationInput"]: {
	page?: Array<ValueTypes["Banner_Location_page_MutationInput"] | undefined | null> | undefined | null | Variable<any, string>,
	eventRankingList?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	locationRankingList?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	categories?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	time?: boolean | undefined | null | Variable<any, string>,
	district?: boolean | undefined | null | Variable<any, string>,
	audience?: boolean | undefined | null | Variable<any, string>,
	multipleCriteriaSearch?: boolean | undefined | null | Variable<any, string>
};
	["Banner_Location_page_MutationInput"]:Banner_Location_page_MutationInput;
	["Banner_type_MutationInput"]:Banner_type_MutationInput;
	["Banner_status_MutationInput"]:Banner_status_MutationInput;
	["mutationBanner_AdvertiseInput"]: {
	campaign?: string | undefined | null | Variable<any, string>,
	invoiceDescription?: string | undefined | null | Variable<any, string>,
	standardPrice: number | Variable<any, string>,
	discount?: number | undefined | null | Variable<any, string>,
	timableCost?: number | undefined | null | Variable<any, string>,
	remark?: string | undefined | null | Variable<any, string>,
	public?: boolean | undefined | null | Variable<any, string>
};
	["Banner_region_MutationInput"]:Banner_region_MutationInput;
	["mutationBannerUpdateInput"]: {
	name?: string | undefined | null | Variable<any, string>,
	desktop?: string | undefined | null | Variable<any, string>,
	mobile?: string | undefined | null | Variable<any, string>,
	bannerStartDate?: string | undefined | null | Variable<any, string>,
	bannerEndDate?: string | undefined | null | Variable<any, string>,
	location?: ValueTypes["mutationBannerUpdate_LocationInput"] | undefined | null | Variable<any, string>,
	link?: string | undefined | null | Variable<any, string>,
	external?: boolean | undefined | null | Variable<any, string>,
	type?: ValueTypes["BannerUpdate_type_MutationInput"] | undefined | null | Variable<any, string>,
	status?: ValueTypes["BannerUpdate_status_MutationInput"] | undefined | null | Variable<any, string>,
	advertise: ValueTypes["mutationBannerUpdate_AdvertiseInput"] | Variable<any, string>,
	_locations?: string | undefined | null | Variable<any, string>,
	region?: ValueTypes["BannerUpdate_region_MutationInput"] | undefined | null | Variable<any, string>,
	homePageClick?: number | undefined | null | Variable<any, string>,
	homePageImpression?: number | undefined | null | Variable<any, string>,
	latestEventClick?: number | undefined | null | Variable<any, string>,
	latestEventImpression?: number | undefined | null | Variable<any, string>,
	editorChoiceClick?: number | undefined | null | Variable<any, string>,
	editorChoiceImpression?: number | undefined | null | Variable<any, string>,
	eventListClick?: number | undefined | null | Variable<any, string>,
	eventListImpression?: number | undefined | null | Variable<any, string>,
	topTenClick?: number | undefined | null | Variable<any, string>,
	topTenImpression?: number | undefined | null | Variable<any, string>,
	miscClick?: number | undefined | null | Variable<any, string>,
	miscImpression?: number | undefined | null | Variable<any, string>,
	clickTotal?: number | undefined | null | Variable<any, string>,
	totalImpression?: number | undefined | null | Variable<any, string>,
	updatedAt?: string | undefined | null | Variable<any, string>,
	createdAt?: string | undefined | null | Variable<any, string>
};
	["mutationBannerUpdate_LocationInput"]: {
	page?: Array<ValueTypes["BannerUpdate_Location_page_MutationInput"] | undefined | null> | undefined | null | Variable<any, string>,
	eventRankingList?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	locationRankingList?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	categories?: Array<string | undefined | null> | undefined | null | Variable<any, string>,
	time?: boolean | undefined | null | Variable<any, string>,
	district?: boolean | undefined | null | Variable<any, string>,
	audience?: boolean | undefined | null | Variable<any, string>,
	multipleCriteriaSearch?: boolean | undefined | null | Variable<any, string>
};
	["BannerUpdate_Location_page_MutationInput"]:BannerUpdate_Location_page_MutationInput;
	["BannerUpdate_type_MutationInput"]:BannerUpdate_type_MutationInput;
	["BannerUpdate_status_MutationInput"]:BannerUpdate_status_MutationInput;
	["mutationBannerUpdate_AdvertiseInput"]: {
	campaign?: string | undefined | null | Variable<any, string>,
	invoiceDescription?: string | undefined | null | Variable<any, string>,
	standardPrice: number | Variable<any, string>,
	discount?: number | undefined | null | Variable<any, string>,
	timableCost?: number | undefined | null | Variable<any, string>,
	remark?: string | undefined | null | Variable<any, string>,
	public?: boolean | undefined | null | Variable<any, string>
};
	["BannerUpdate_region_MutationInput"]:BannerUpdate_region_MutationInput;
	["mutationCampaignInput"]: {
	creator?: ValueTypes["Campaign_CreatorRelationshipInput"] | undefined | null | Variable<any, string>,
	invoiceNumber?: string | undefined | null | Variable<any, string>,
	quotationNumber?: string | undefined | null | Variable<any, string>,
	campaignName: string | Variable<any, string>,
	client: string | Variable<any, string>,
	contactPerson: string | Variable<any, string>,
	email: string | Variable<any, string>,
	phone: string | Variable<any, string>,
	agentName?: string | undefined | null | Variable<any, string>,
	agentCommission?: number | undefined | null | Variable<any, string>,
	agentOrderNumber?: string | undefined | null | Variable<any, string>,
	_firstPublishedTime?: string | undefined | null | Variable<any, string>,
	updatedAt?: string | undefined | null | Variable<any, string>,
	createdAt?: string | undefined | null | Variable<any, string>
};
	["Campaign_CreatorRelationshipInput"]: {
	relationTo?: ValueTypes["Campaign_CreatorRelationshipInputRelationTo"] | undefined | null | Variable<any, string>,
	value?: ValueTypes["JSON"] | undefined | null | Variable<any, string>
};
	["Campaign_CreatorRelationshipInputRelationTo"]:Campaign_CreatorRelationshipInputRelationTo;
	["mutationCampaignUpdateInput"]: {
	creator?: ValueTypes["CampaignUpdate_CreatorRelationshipInput"] | undefined | null | Variable<any, string>,
	invoiceNumber?: string | undefined | null | Variable<any, string>,
	quotationNumber?: string | undefined | null | Variable<any, string>,
	campaignName?: string | undefined | null | Variable<any, string>,
	client?: string | undefined | null | Variable<any, string>,
	contactPerson?: string | undefined | null | Variable<any, string>,
	email?: string | undefined | null | Variable<any, string>,
	phone?: string | undefined | null | Variable<any, string>,
	agentName?: string | undefined | null | Variable<any, string>,
	agentCommission?: number | undefined | null | Variable<any, string>,
	agentOrderNumber?: string | undefined | null | Variable<any, string>,
	_firstPublishedTime?: string | undefined | null | Variable<any, string>,
	updatedAt?: string | undefined | null | Variable<any, string>,
	createdAt?: string | undefined | null | Variable<any, string>
};
	["CampaignUpdate_CreatorRelationshipInput"]: {
	relationTo?: ValueTypes["CampaignUpdate_CreatorRelationshipInputRelationTo"] | undefined | null | Variable<any, string>,
	value?: ValueTypes["JSON"] | undefined | null | Variable<any, string>
};
	["CampaignUpdate_CreatorRelationshipInputRelationTo"]:CampaignUpdate_CreatorRelationshipInputRelationTo;
	["mutationAdvertiseInput"]: {
	advertise: ValueTypes["mutationAdvertise_AdvertiseInput"] | Variable<any, string>,
	advertiseStartDate: string | Variable<any, string>,
	advertiseEndDate: string | Variable<any, string>,
	type?: ValueTypes["Advertise_type_MutationInput"] | undefined | null | Variable<any, string>,
	invoiceDescription?: string | undefined | null | Variable<any, string>,
	standardPrice: number | Variable<any, string>,
	discount?: number | undefined | null | Variable<any, string>,
	discountPercentage?: number | undefined | null | Variable<any, string>,
	_calculateByDiscount?: boolean | undefined | null | Variable<any, string>,
	timableCost?: number | undefined | null | Variable<any, string>,
	remark?: string | undefined | null | Variable<any, string>,
	public?: boolean | undefined | null | Variable<any, string>,
	campaignName?: string | undefined | null | Variable<any, string>,
	campaignId?: string | undefined | null | Variable<any, string>,
	status?: string | undefined | null | Variable<any, string>,
	updatedAt?: string | undefined | null | Variable<any, string>,
	createdAt?: string | undefined | null | Variable<any, string>
};
	["mutationAdvertise_AdvertiseInput"]: {
	type?: ValueTypes["Advertise_Advertise_type_MutationInput"] | undefined | null | Variable<any, string>,
	event?: string | undefined | null | Variable<any, string>,
	keyword?: string | undefined | null | Variable<any, string>,
	location?: string | undefined | null | Variable<any, string>,
	displayPosition?: string | undefined | null | Variable<any, string>,
	rankingListRemark?: string | undefined | null | Variable<any, string>
};
	["Advertise_Advertise_type_MutationInput"]:Advertise_Advertise_type_MutationInput;
	["Advertise_type_MutationInput"]:Advertise_type_MutationInput;
	["mutationAdvertiseUpdateInput"]: {
	advertise: ValueTypes["mutationAdvertiseUpdate_AdvertiseInput"] | Variable<any, string>,
	advertiseStartDate?: string | undefined | null | Variable<any, string>,
	advertiseEndDate?: string | undefined | null | Variable<any, string>,
	type?: ValueTypes["AdvertiseUpdate_type_MutationInput"] | undefined | null | Variable<any, string>,
	invoiceDescription?: string | undefined | null | Variable<any, string>,
	standardPrice?: number | undefined | null | Variable<any, string>,
	discount?: number | undefined | null | Variable<any, string>,
	discountPercentage?: number | undefined | null | Variable<any, string>,
	_calculateByDiscount?: boolean | undefined | null | Variable<any, string>,
	timableCost?: number | undefined | null | Variable<any, string>,
	remark?: string | undefined | null | Variable<any, string>,
	public?: boolean | undefined | null | Variable<any, string>,
	campaignName?: string | undefined | null | Variable<any, string>,
	campaignId?: string | undefined | null | Variable<any, string>,
	status?: string | undefined | null | Variable<any, string>,
	updatedAt?: string | undefined | null | Variable<any, string>,
	createdAt?: string | undefined | null | Variable<any, string>
};
	["mutationAdvertiseUpdate_AdvertiseInput"]: {
	type?: ValueTypes["AdvertiseUpdate_Advertise_type_MutationInput"] | undefined | null | Variable<any, string>,
	event?: string | undefined | null | Variable<any, string>,
	keyword?: string | undefined | null | Variable<any, string>,
	location?: string | undefined | null | Variable<any, string>,
	displayPosition?: string | undefined | null | Variable<any, string>,
	rankingListRemark?: string | undefined | null | Variable<any, string>
};
	["AdvertiseUpdate_Advertise_type_MutationInput"]:AdvertiseUpdate_Advertise_type_MutationInput;
	["AdvertiseUpdate_type_MutationInput"]:AdvertiseUpdate_type_MutationInput;
	["mutationStaticInput"]: {
	name?: string | undefined | null | Variable<any, string>,
	key?: string | undefined | null | Variable<any, string>,
	content?: ValueTypes["JSON"] | undefined | null | Variable<any, string>,
	region?: ValueTypes["Static_region_MutationInput"] | undefined | null | Variable<any, string>,
	updatedAt?: string | undefined | null | Variable<any, string>,
	createdAt?: string | undefined | null | Variable<any, string>
};
	["Static_region_MutationInput"]:Static_region_MutationInput;
	["mutationStaticUpdateInput"]: {
	name?: string | undefined | null | Variable<any, string>,
	key?: string | undefined | null | Variable<any, string>,
	content?: ValueTypes["JSON"] | undefined | null | Variable<any, string>,
	region?: ValueTypes["StaticUpdate_region_MutationInput"] | undefined | null | Variable<any, string>,
	updatedAt?: string | undefined | null | Variable<any, string>,
	createdAt?: string | undefined | null | Variable<any, string>
};
	["StaticUpdate_region_MutationInput"]:StaticUpdate_region_MutationInput;
	["mutationEventViewInput"]: {
	date: string | Variable<any, string>,
	eventId?: string | undefined | null | Variable<any, string>,
	viewCount: number | Variable<any, string>,
	updatedAt?: string | undefined | null | Variable<any, string>,
	createdAt?: string | undefined | null | Variable<any, string>
};
	["mutationEventViewUpdateInput"]: {
	date?: string | undefined | null | Variable<any, string>,
	eventId?: string | undefined | null | Variable<any, string>,
	viewCount?: number | undefined | null | Variable<any, string>,
	updatedAt?: string | undefined | null | Variable<any, string>,
	createdAt?: string | undefined | null | Variable<any, string>
};
	["mutationPostViewInput"]: {
	date: string | Variable<any, string>,
	postId?: string | undefined | null | Variable<any, string>,
	viewCount: number | Variable<any, string>,
	updatedAt?: string | undefined | null | Variable<any, string>,
	createdAt?: string | undefined | null | Variable<any, string>
};
	["mutationPostViewUpdateInput"]: {
	date?: string | undefined | null | Variable<any, string>,
	postId?: string | undefined | null | Variable<any, string>,
	viewCount?: number | undefined | null | Variable<any, string>,
	updatedAt?: string | undefined | null | Variable<any, string>,
	createdAt?: string | undefined | null | Variable<any, string>
};
	["mutationPayloadPreferenceInput"]: {
	user?: ValueTypes["PayloadPreference_UserRelationshipInput"] | undefined | null | Variable<any, string>,
	key?: string | undefined | null | Variable<any, string>,
	value?: ValueTypes["JSON"] | undefined | null | Variable<any, string>,
	updatedAt?: string | undefined | null | Variable<any, string>,
	createdAt?: string | undefined | null | Variable<any, string>
};
	["PayloadPreference_UserRelationshipInput"]: {
	relationTo?: ValueTypes["PayloadPreference_UserRelationshipInputRelationTo"] | undefined | null | Variable<any, string>,
	value?: ValueTypes["JSON"] | undefined | null | Variable<any, string>
};
	["PayloadPreference_UserRelationshipInputRelationTo"]:PayloadPreference_UserRelationshipInputRelationTo;
	["mutationPayloadPreferenceUpdateInput"]: {
	user?: ValueTypes["PayloadPreferenceUpdate_UserRelationshipInput"] | undefined | null | Variable<any, string>,
	key?: string | undefined | null | Variable<any, string>,
	value?: ValueTypes["JSON"] | undefined | null | Variable<any, string>,
	updatedAt?: string | undefined | null | Variable<any, string>,
	createdAt?: string | undefined | null | Variable<any, string>
};
	["PayloadPreferenceUpdate_UserRelationshipInput"]: {
	relationTo?: ValueTypes["PayloadPreferenceUpdate_UserRelationshipInputRelationTo"] | undefined | null | Variable<any, string>,
	value?: ValueTypes["JSON"] | undefined | null | Variable<any, string>
};
	["PayloadPreferenceUpdate_UserRelationshipInputRelationTo"]:PayloadPreferenceUpdate_UserRelationshipInputRelationTo;
	["ChatbotInventoryPayment"]: AliasType<{
	expiredAt?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ChatbotTransactionPdfGet"]: AliasType<{
	url?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["WebAppQrCodeBatchScanResult"]: AliasType<{
	result?:ValueTypes["WebAppQrCodeScanResponse"],
		__typename?: boolean | `@${string}`
}>;
	["WebAppQrCodeScanResponse"]: AliasType<{
	status?:boolean | `@${string}`,
	qrCodeId?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	phone?:boolean | `@${string}`,
	scanAt?:boolean | `@${string}`,
	uploadAt?:boolean | `@${string}`,
	failReason?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["WebAppQrCodeBatchScanItem"]: {
	checkInAppSelectionId?: string | undefined | null | Variable<any, string>,
	checkInAppSelectionProductType?: string | undefined | null | Variable<any, string>,
	qrCodeId?: string | undefined | null | Variable<any, string>,
	scanAt?: string | undefined | null | Variable<any, string>,
	entryNumber?: string | undefined | null | Variable<any, string>
}
  }

export type ResolverInputTypes = {
    ["Query"]: AliasType<{
Event?: [{	id: string,	fallbackLocale?: ResolverInputTypes["FallbackLocaleInputType"] | undefined | null,	locale?: ResolverInputTypes["LocaleInputType"] | undefined | null},ResolverInputTypes["Event"]],
Events?: [{	where?: ResolverInputTypes["Event_where"] | undefined | null,	fallbackLocale?: ResolverInputTypes["FallbackLocaleInputType"] | undefined | null,	locale?: ResolverInputTypes["LocaleInputType"] | undefined | null,	limit?: number | undefined | null,	page?: number | undefined | null,	sort?: string | undefined | null},ResolverInputTypes["Events"]],
countEvents?: [{	where?: ResolverInputTypes["Event_where"] | undefined | null,	locale?: ResolverInputTypes["LocaleInputType"] | undefined | null},ResolverInputTypes["countEvents"]],
docAccessEvent?: [{	id: string},ResolverInputTypes["eventsDocAccess"]],
Location?: [{	id: string,	fallbackLocale?: ResolverInputTypes["FallbackLocaleInputType"] | undefined | null,	locale?: ResolverInputTypes["LocaleInputType"] | undefined | null},ResolverInputTypes["Location"]],
Locations?: [{	where?: ResolverInputTypes["Location_where"] | undefined | null,	fallbackLocale?: ResolverInputTypes["FallbackLocaleInputType"] | undefined | null,	locale?: ResolverInputTypes["LocaleInputType"] | undefined | null,	limit?: number | undefined | null,	page?: number | undefined | null,	sort?: string | undefined | null},ResolverInputTypes["Locations"]],
countLocations?: [{	where?: ResolverInputTypes["Location_where"] | undefined | null,	locale?: ResolverInputTypes["LocaleInputType"] | undefined | null},ResolverInputTypes["countLocations"]],
docAccessLocation?: [{	id: string},ResolverInputTypes["locationsDocAccess"]],
Post?: [{	id: string,	fallbackLocale?: ResolverInputTypes["FallbackLocaleInputType"] | undefined | null,	locale?: ResolverInputTypes["LocaleInputType"] | undefined | null},ResolverInputTypes["Post"]],
Posts?: [{	where?: ResolverInputTypes["Post_where"] | undefined | null,	fallbackLocale?: ResolverInputTypes["FallbackLocaleInputType"] | undefined | null,	locale?: ResolverInputTypes["LocaleInputType"] | undefined | null,	limit?: number | undefined | null,	page?: number | undefined | null,	sort?: string | undefined | null},ResolverInputTypes["Posts"]],
countPosts?: [{	where?: ResolverInputTypes["Post_where"] | undefined | null,	locale?: ResolverInputTypes["LocaleInputType"] | undefined | null},ResolverInputTypes["countPosts"]],
docAccessPost?: [{	id: string},ResolverInputTypes["postsDocAccess"]],
Ticketing?: [{	id: string,	fallbackLocale?: ResolverInputTypes["FallbackLocaleInputType"] | undefined | null,	locale?: ResolverInputTypes["LocaleInputType"] | undefined | null},ResolverInputTypes["Ticketing"]],
Ticketings?: [{	where?: ResolverInputTypes["Ticketing_where"] | undefined | null,	fallbackLocale?: ResolverInputTypes["FallbackLocaleInputType"] | undefined | null,	locale?: ResolverInputTypes["LocaleInputType"] | undefined | null,	limit?: number | undefined | null,	page?: number | undefined | null,	sort?: string | undefined | null},ResolverInputTypes["Ticketings"]],
countTicketings?: [{	where?: ResolverInputTypes["Ticketing_where"] | undefined | null,	locale?: ResolverInputTypes["LocaleInputType"] | undefined | null},ResolverInputTypes["countTicketings"]],
docAccessTicketing?: [{	id: string},ResolverInputTypes["ticketingDocAccess"]],
Product?: [{	id: string,	fallbackLocale?: ResolverInputTypes["FallbackLocaleInputType"] | undefined | null,	locale?: ResolverInputTypes["LocaleInputType"] | undefined | null},ResolverInputTypes["Product"]],
Products?: [{	where?: ResolverInputTypes["Product_where"] | undefined | null,	fallbackLocale?: ResolverInputTypes["FallbackLocaleInputType"] | undefined | null,	locale?: ResolverInputTypes["LocaleInputType"] | undefined | null,	limit?: number | undefined | null,	page?: number | undefined | null,	sort?: string | undefined | null},ResolverInputTypes["Products"]],
countProducts?: [{	where?: ResolverInputTypes["Product_where"] | undefined | null,	locale?: ResolverInputTypes["LocaleInputType"] | undefined | null},ResolverInputTypes["countProducts"]],
docAccessProduct?: [{	id: string},ResolverInputTypes["productsDocAccess"]],
InventoryStatus?: [{	id: string,	fallbackLocale?: ResolverInputTypes["FallbackLocaleInputType"] | undefined | null,	locale?: ResolverInputTypes["LocaleInputType"] | undefined | null},ResolverInputTypes["InventoryStatus"]],
InventoryStatuses?: [{	where?: ResolverInputTypes["InventoryStatus_where"] | undefined | null,	fallbackLocale?: ResolverInputTypes["FallbackLocaleInputType"] | undefined | null,	locale?: ResolverInputTypes["LocaleInputType"] | undefined | null,	limit?: number | undefined | null,	page?: number | undefined | null,	sort?: string | undefined | null},ResolverInputTypes["InventoryStatuses"]],
countInventoryStatuses?: [{	where?: ResolverInputTypes["InventoryStatus_where"] | undefined | null,	locale?: ResolverInputTypes["LocaleInputType"] | undefined | null},ResolverInputTypes["countInventoryStatuses"]],
docAccessInventoryStatus?: [{	id: string},ResolverInputTypes["inventory_statusDocAccess"]],
Transaction?: [{	id: string,	fallbackLocale?: ResolverInputTypes["FallbackLocaleInputType"] | undefined | null,	locale?: ResolverInputTypes["LocaleInputType"] | undefined | null},ResolverInputTypes["Transaction"]],
Transactions?: [{	where?: ResolverInputTypes["Transaction_where"] | undefined | null,	fallbackLocale?: ResolverInputTypes["FallbackLocaleInputType"] | undefined | null,	locale?: ResolverInputTypes["LocaleInputType"] | undefined | null,	limit?: number | undefined | null,	page?: number | undefined | null,	sort?: string | undefined | null},ResolverInputTypes["Transactions"]],
countTransactions?: [{	where?: ResolverInputTypes["Transaction_where"] | undefined | null,	locale?: ResolverInputTypes["LocaleInputType"] | undefined | null},ResolverInputTypes["countTransactions"]],
docAccessTransaction?: [{	id: string},ResolverInputTypes["transactionsDocAccess"]],
RedemptionCode?: [{	id: string,	fallbackLocale?: ResolverInputTypes["FallbackLocaleInputType"] | undefined | null,	locale?: ResolverInputTypes["LocaleInputType"] | undefined | null},ResolverInputTypes["RedemptionCode"]],
RedemptionCodes?: [{	where?: ResolverInputTypes["RedemptionCode_where"] | undefined | null,	fallbackLocale?: ResolverInputTypes["FallbackLocaleInputType"] | undefined | null,	locale?: ResolverInputTypes["LocaleInputType"] | undefined | null,	limit?: number | undefined | null,	page?: number | undefined | null,	sort?: string | undefined | null},ResolverInputTypes["RedemptionCodes"]],
countRedemptionCodes?: [{	where?: ResolverInputTypes["RedemptionCode_where"] | undefined | null,	locale?: ResolverInputTypes["LocaleInputType"] | undefined | null},ResolverInputTypes["countRedemptionCodes"]],
docAccessRedemptionCode?: [{	id: string},ResolverInputTypes["redemption_codesDocAccess"]],
RedemptionPdf?: [{	id: string,	fallbackLocale?: ResolverInputTypes["FallbackLocaleInputType"] | undefined | null,	locale?: ResolverInputTypes["LocaleInputType"] | undefined | null},ResolverInputTypes["RedemptionPdf"]],
RedemptionPdfs?: [{	where?: ResolverInputTypes["RedemptionPdf_where"] | undefined | null,	fallbackLocale?: ResolverInputTypes["FallbackLocaleInputType"] | undefined | null,	locale?: ResolverInputTypes["LocaleInputType"] | undefined | null,	limit?: number | undefined | null,	page?: number | undefined | null,	sort?: string | undefined | null},ResolverInputTypes["RedemptionPdfs"]],
countRedemptionPdfs?: [{	where?: ResolverInputTypes["RedemptionPdf_where"] | undefined | null,	locale?: ResolverInputTypes["LocaleInputType"] | undefined | null},ResolverInputTypes["countRedemptionPdfs"]],
docAccessRedemptionPdf?: [{	id: string},ResolverInputTypes["redemption_pdfDocAccess"]],
CheckInRedemption?: [{	id: string,	fallbackLocale?: ResolverInputTypes["FallbackLocaleInputType"] | undefined | null,	locale?: ResolverInputTypes["LocaleInputType"] | undefined | null},ResolverInputTypes["CheckInRedemption"]],
CheckInRedemptions?: [{	where?: ResolverInputTypes["CheckInRedemption_where"] | undefined | null,	fallbackLocale?: ResolverInputTypes["FallbackLocaleInputType"] | undefined | null,	locale?: ResolverInputTypes["LocaleInputType"] | undefined | null,	limit?: number | undefined | null,	page?: number | undefined | null,	sort?: string | undefined | null},ResolverInputTypes["CheckInRedemptions"]],
countCheckInRedemptions?: [{	where?: ResolverInputTypes["CheckInRedemption_where"] | undefined | null,	locale?: ResolverInputTypes["LocaleInputType"] | undefined | null},ResolverInputTypes["countCheckInRedemptions"]],
docAccessCheckInRedemption?: [{	id: string},ResolverInputTypes["check_in_redemptionDocAccess"]],
User?: [{	id: string,	fallbackLocale?: ResolverInputTypes["FallbackLocaleInputType"] | undefined | null,	locale?: ResolverInputTypes["LocaleInputType"] | undefined | null},ResolverInputTypes["User"]],
Users?: [{	where?: ResolverInputTypes["User_where"] | undefined | null,	fallbackLocale?: ResolverInputTypes["FallbackLocaleInputType"] | undefined | null,	locale?: ResolverInputTypes["LocaleInputType"] | undefined | null,	limit?: number | undefined | null,	page?: number | undefined | null,	sort?: string | undefined | null},ResolverInputTypes["Users"]],
countUsers?: [{	where?: ResolverInputTypes["User_where"] | undefined | null,	locale?: ResolverInputTypes["LocaleInputType"] | undefined | null},ResolverInputTypes["countUsers"]],
docAccessUser?: [{	id: string},ResolverInputTypes["usersDocAccess"]],
	meUser?:ResolverInputTypes["usersMe"],
	initializedUser?:boolean | `@${string}`,
Bookmark?: [{	id: string,	fallbackLocale?: ResolverInputTypes["FallbackLocaleInputType"] | undefined | null,	locale?: ResolverInputTypes["LocaleInputType"] | undefined | null},ResolverInputTypes["Bookmark"]],
Bookmarks?: [{	where?: ResolverInputTypes["Bookmark_where"] | undefined | null,	fallbackLocale?: ResolverInputTypes["FallbackLocaleInputType"] | undefined | null,	locale?: ResolverInputTypes["LocaleInputType"] | undefined | null,	limit?: number | undefined | null,	page?: number | undefined | null,	sort?: string | undefined | null},ResolverInputTypes["Bookmarks"]],
countBookmarks?: [{	where?: ResolverInputTypes["Bookmark_where"] | undefined | null,	locale?: ResolverInputTypes["LocaleInputType"] | undefined | null},ResolverInputTypes["countBookmarks"]],
docAccessBookmark?: [{	id: string},ResolverInputTypes["bookmarksDocAccess"]],
WebAppBookmark?: [{	id: string,	fallbackLocale?: ResolverInputTypes["FallbackLocaleInputType"] | undefined | null,	locale?: ResolverInputTypes["LocaleInputType"] | undefined | null},ResolverInputTypes["WebAppBookmark"]],
WebAppBookmarks?: [{	where?: ResolverInputTypes["WebAppBookmark_where"] | undefined | null,	fallbackLocale?: ResolverInputTypes["FallbackLocaleInputType"] | undefined | null,	locale?: ResolverInputTypes["LocaleInputType"] | undefined | null,	limit?: number | undefined | null,	page?: number | undefined | null,	sort?: string | undefined | null},ResolverInputTypes["WebAppBookmarks"]],
countWebAppBookmarks?: [{	where?: ResolverInputTypes["WebAppBookmark_where"] | undefined | null,	locale?: ResolverInputTypes["LocaleInputType"] | undefined | null},ResolverInputTypes["countWebAppBookmarks"]],
docAccessWebAppBookmark?: [{	id: string},ResolverInputTypes["webAppBookmarksDocAccess"]],
ViewRecord?: [{	id: string,	fallbackLocale?: ResolverInputTypes["FallbackLocaleInputType"] | undefined | null,	locale?: ResolverInputTypes["LocaleInputType"] | undefined | null},ResolverInputTypes["ViewRecord"]],
ViewRecords?: [{	where?: ResolverInputTypes["ViewRecord_where"] | undefined | null,	fallbackLocale?: ResolverInputTypes["FallbackLocaleInputType"] | undefined | null,	locale?: ResolverInputTypes["LocaleInputType"] | undefined | null,	limit?: number | undefined | null,	page?: number | undefined | null,	sort?: string | undefined | null},ResolverInputTypes["ViewRecords"]],
countViewRecords?: [{	where?: ResolverInputTypes["ViewRecord_where"] | undefined | null,	locale?: ResolverInputTypes["LocaleInputType"] | undefined | null},ResolverInputTypes["countViewRecords"]],
docAccessViewRecord?: [{	id: string},ResolverInputTypes["view_recordsDocAccess"]],
Comment?: [{	id: string,	fallbackLocale?: ResolverInputTypes["FallbackLocaleInputType"] | undefined | null,	locale?: ResolverInputTypes["LocaleInputType"] | undefined | null},ResolverInputTypes["Comment"]],
Comments?: [{	where?: ResolverInputTypes["Comment_where"] | undefined | null,	fallbackLocale?: ResolverInputTypes["FallbackLocaleInputType"] | undefined | null,	locale?: ResolverInputTypes["LocaleInputType"] | undefined | null,	limit?: number | undefined | null,	page?: number | undefined | null,	sort?: string | undefined | null},ResolverInputTypes["Comments"]],
countComments?: [{	where?: ResolverInputTypes["Comment_where"] | undefined | null,	locale?: ResolverInputTypes["LocaleInputType"] | undefined | null},ResolverInputTypes["countComments"]],
docAccessComment?: [{	id: string},ResolverInputTypes["commentsDocAccess"]],
Subscription?: [{	id: string,	fallbackLocale?: ResolverInputTypes["FallbackLocaleInputType"] | undefined | null,	locale?: ResolverInputTypes["LocaleInputType"] | undefined | null},ResolverInputTypes["Subscription"]],
Subscriptions?: [{	where?: ResolverInputTypes["Subscription_where"] | undefined | null,	fallbackLocale?: ResolverInputTypes["FallbackLocaleInputType"] | undefined | null,	locale?: ResolverInputTypes["LocaleInputType"] | undefined | null,	limit?: number | undefined | null,	page?: number | undefined | null,	sort?: string | undefined | null},ResolverInputTypes["Subscriptions"]],
countSubscriptions?: [{	where?: ResolverInputTypes["Subscription_where"] | undefined | null,	locale?: ResolverInputTypes["LocaleInputType"] | undefined | null},ResolverInputTypes["countSubscriptions"]],
docAccessSubscription?: [{	id: string},ResolverInputTypes["subscriptionsDocAccess"]],
Newsletter?: [{	id: string,	fallbackLocale?: ResolverInputTypes["FallbackLocaleInputType"] | undefined | null,	locale?: ResolverInputTypes["LocaleInputType"] | undefined | null},ResolverInputTypes["Newsletter"]],
Newsletters?: [{	where?: ResolverInputTypes["Newsletter_where"] | undefined | null,	fallbackLocale?: ResolverInputTypes["FallbackLocaleInputType"] | undefined | null,	locale?: ResolverInputTypes["LocaleInputType"] | undefined | null,	limit?: number | undefined | null,	page?: number | undefined | null,	sort?: string | undefined | null},ResolverInputTypes["Newsletters"]],
countNewsletters?: [{	where?: ResolverInputTypes["Newsletter_where"] | undefined | null,	locale?: ResolverInputTypes["LocaleInputType"] | undefined | null},ResolverInputTypes["countNewsletters"]],
docAccessNewsletter?: [{	id: string},ResolverInputTypes["newsletterDocAccess"]],
BlogCategory?: [{	id: string,	fallbackLocale?: ResolverInputTypes["FallbackLocaleInputType"] | undefined | null,	locale?: ResolverInputTypes["LocaleInputType"] | undefined | null},ResolverInputTypes["BlogCategory"]],
BlogCategories?: [{	where?: ResolverInputTypes["BlogCategory_where"] | undefined | null,	fallbackLocale?: ResolverInputTypes["FallbackLocaleInputType"] | undefined | null,	locale?: ResolverInputTypes["LocaleInputType"] | undefined | null,	limit?: number | undefined | null,	page?: number | undefined | null,	sort?: string | undefined | null},ResolverInputTypes["BlogCategories"]],
countBlogCategories?: [{	where?: ResolverInputTypes["BlogCategory_where"] | undefined | null,	locale?: ResolverInputTypes["LocaleInputType"] | undefined | null},ResolverInputTypes["countBlogCategories"]],
docAccessBlogCategory?: [{	id: string},ResolverInputTypes["blog_categoriesDocAccess"]],
Tag?: [{	id: string,	fallbackLocale?: ResolverInputTypes["FallbackLocaleInputType"] | undefined | null,	locale?: ResolverInputTypes["LocaleInputType"] | undefined | null},ResolverInputTypes["Tag"]],
Tags?: [{	where?: ResolverInputTypes["Tag_where"] | undefined | null,	fallbackLocale?: ResolverInputTypes["FallbackLocaleInputType"] | undefined | null,	locale?: ResolverInputTypes["LocaleInputType"] | undefined | null,	limit?: number | undefined | null,	page?: number | undefined | null,	sort?: string | undefined | null},ResolverInputTypes["Tags"]],
countTags?: [{	where?: ResolverInputTypes["Tag_where"] | undefined | null,	locale?: ResolverInputTypes["LocaleInputType"] | undefined | null},ResolverInputTypes["countTags"]],
docAccessTag?: [{	id: string},ResolverInputTypes["tagsDocAccess"]],
SpecialTag?: [{	id: string,	fallbackLocale?: ResolverInputTypes["FallbackLocaleInputType"] | undefined | null,	locale?: ResolverInputTypes["LocaleInputType"] | undefined | null},ResolverInputTypes["SpecialTag"]],
SpecialTags?: [{	where?: ResolverInputTypes["SpecialTag_where"] | undefined | null,	fallbackLocale?: ResolverInputTypes["FallbackLocaleInputType"] | undefined | null,	locale?: ResolverInputTypes["LocaleInputType"] | undefined | null,	limit?: number | undefined | null,	page?: number | undefined | null,	sort?: string | undefined | null},ResolverInputTypes["SpecialTags"]],
countSpecialTags?: [{	where?: ResolverInputTypes["SpecialTag_where"] | undefined | null,	locale?: ResolverInputTypes["LocaleInputType"] | undefined | null},ResolverInputTypes["countSpecialTags"]],
docAccessSpecialTag?: [{	id: string},ResolverInputTypes["specialTagsDocAccess"]],
Date?: [{	id: string,	fallbackLocale?: ResolverInputTypes["FallbackLocaleInputType"] | undefined | null,	locale?: ResolverInputTypes["LocaleInputType"] | undefined | null},ResolverInputTypes["Date"]],
Dates?: [{	where?: ResolverInputTypes["Date_where"] | undefined | null,	fallbackLocale?: ResolverInputTypes["FallbackLocaleInputType"] | undefined | null,	locale?: ResolverInputTypes["LocaleInputType"] | undefined | null,	limit?: number | undefined | null,	page?: number | undefined | null,	sort?: string | undefined | null},ResolverInputTypes["Dates"]],
countDates?: [{	where?: ResolverInputTypes["Date_where"] | undefined | null,	locale?: ResolverInputTypes["LocaleInputType"] | undefined | null},ResolverInputTypes["countDates"]],
docAccessDate?: [{	id: string},ResolverInputTypes["dateDocAccess"]],
District?: [{	id: string,	fallbackLocale?: ResolverInputTypes["FallbackLocaleInputType"] | undefined | null,	locale?: ResolverInputTypes["LocaleInputType"] | undefined | null},ResolverInputTypes["District"]],
Districts?: [{	where?: ResolverInputTypes["District_where"] | undefined | null,	fallbackLocale?: ResolverInputTypes["FallbackLocaleInputType"] | undefined | null,	locale?: ResolverInputTypes["LocaleInputType"] | undefined | null,	limit?: number | undefined | null,	page?: number | undefined | null,	sort?: string | undefined | null},ResolverInputTypes["Districts"]],
countDistricts?: [{	where?: ResolverInputTypes["District_where"] | undefined | null,	locale?: ResolverInputTypes["LocaleInputType"] | undefined | null},ResolverInputTypes["countDistricts"]],
docAccessDistrict?: [{	id: string},ResolverInputTypes["districtsDocAccess"]],
Audience?: [{	id: string,	fallbackLocale?: ResolverInputTypes["FallbackLocaleInputType"] | undefined | null,	locale?: ResolverInputTypes["LocaleInputType"] | undefined | null},ResolverInputTypes["Audience"]],
Audiences?: [{	where?: ResolverInputTypes["Audience_where"] | undefined | null,	fallbackLocale?: ResolverInputTypes["FallbackLocaleInputType"] | undefined | null,	locale?: ResolverInputTypes["LocaleInputType"] | undefined | null,	limit?: number | undefined | null,	page?: number | undefined | null,	sort?: string | undefined | null},ResolverInputTypes["Audiences"]],
countAudiences?: [{	where?: ResolverInputTypes["Audience_where"] | undefined | null,	locale?: ResolverInputTypes["LocaleInputType"] | undefined | null},ResolverInputTypes["countAudiences"]],
docAccessAudience?: [{	id: string},ResolverInputTypes["audienceDocAccess"]],
EventCategory?: [{	id: string,	fallbackLocale?: ResolverInputTypes["FallbackLocaleInputType"] | undefined | null,	locale?: ResolverInputTypes["LocaleInputType"] | undefined | null},ResolverInputTypes["EventCategory"]],
EventCategories?: [{	where?: ResolverInputTypes["EventCategory_where"] | undefined | null,	fallbackLocale?: ResolverInputTypes["FallbackLocaleInputType"] | undefined | null,	locale?: ResolverInputTypes["LocaleInputType"] | undefined | null,	limit?: number | undefined | null,	page?: number | undefined | null,	sort?: string | undefined | null},ResolverInputTypes["EventCategories"]],
countEventCategories?: [{	where?: ResolverInputTypes["EventCategory_where"] | undefined | null,	locale?: ResolverInputTypes["LocaleInputType"] | undefined | null},ResolverInputTypes["countEventCategories"]],
docAccessEventCategory?: [{	id: string},ResolverInputTypes["categoryDocAccess"]],
PrivateMedia?: [{	id: string,	fallbackLocale?: ResolverInputTypes["FallbackLocaleInputType"] | undefined | null,	locale?: ResolverInputTypes["LocaleInputType"] | undefined | null},ResolverInputTypes["PrivateMedia"]],
PrivateMedias?: [{	where?: ResolverInputTypes["PrivateMedia_where"] | undefined | null,	fallbackLocale?: ResolverInputTypes["FallbackLocaleInputType"] | undefined | null,	locale?: ResolverInputTypes["LocaleInputType"] | undefined | null,	limit?: number | undefined | null,	page?: number | undefined | null,	sort?: string | undefined | null},ResolverInputTypes["PrivateMedias"]],
countPrivateMedias?: [{	where?: ResolverInputTypes["PrivateMedia_where"] | undefined | null,	locale?: ResolverInputTypes["LocaleInputType"] | undefined | null},ResolverInputTypes["countPrivateMedias"]],
docAccessPrivateMedia?: [{	id: string},ResolverInputTypes["private_mediaDocAccess"]],
Media?: [{	id: string,	fallbackLocale?: ResolverInputTypes["FallbackLocaleInputType"] | undefined | null,	locale?: ResolverInputTypes["LocaleInputType"] | undefined | null},ResolverInputTypes["Media"]],
allMedia?: [{	where?: ResolverInputTypes["Media_where"] | undefined | null,	fallbackLocale?: ResolverInputTypes["FallbackLocaleInputType"] | undefined | null,	locale?: ResolverInputTypes["LocaleInputType"] | undefined | null,	limit?: number | undefined | null,	page?: number | undefined | null,	sort?: string | undefined | null},ResolverInputTypes["allMedia"]],
countallMedia?: [{	where?: ResolverInputTypes["Media_where"] | undefined | null,	locale?: ResolverInputTypes["LocaleInputType"] | undefined | null},ResolverInputTypes["countallMedia"]],
docAccessMedia?: [{	id: string},ResolverInputTypes["mediaDocAccess"]],
BackupMedia?: [{	id: string,	fallbackLocale?: ResolverInputTypes["FallbackLocaleInputType"] | undefined | null,	locale?: ResolverInputTypes["LocaleInputType"] | undefined | null},ResolverInputTypes["BackupMedia"]],
BackupMedias?: [{	where?: ResolverInputTypes["BackupMedia_where"] | undefined | null,	fallbackLocale?: ResolverInputTypes["FallbackLocaleInputType"] | undefined | null,	locale?: ResolverInputTypes["LocaleInputType"] | undefined | null,	limit?: number | undefined | null,	page?: number | undefined | null,	sort?: string | undefined | null},ResolverInputTypes["BackupMedias"]],
countBackupMedias?: [{	where?: ResolverInputTypes["BackupMedia_where"] | undefined | null,	locale?: ResolverInputTypes["LocaleInputType"] | undefined | null},ResolverInputTypes["countBackupMedias"]],
docAccessBackupMedia?: [{	id: string},ResolverInputTypes["backupMediaDocAccess"]],
AccountSuspendRequest?: [{	id: string,	fallbackLocale?: ResolverInputTypes["FallbackLocaleInputType"] | undefined | null,	locale?: ResolverInputTypes["LocaleInputType"] | undefined | null},ResolverInputTypes["AccountSuspendRequest"]],
AccountSuspendRequests?: [{	where?: ResolverInputTypes["AccountSuspendRequest_where"] | undefined | null,	fallbackLocale?: ResolverInputTypes["FallbackLocaleInputType"] | undefined | null,	locale?: ResolverInputTypes["LocaleInputType"] | undefined | null,	limit?: number | undefined | null,	page?: number | undefined | null,	sort?: string | undefined | null},ResolverInputTypes["AccountSuspendRequests"]],
countAccountSuspendRequests?: [{	where?: ResolverInputTypes["AccountSuspendRequest_where"] | undefined | null,	locale?: ResolverInputTypes["LocaleInputType"] | undefined | null},ResolverInputTypes["countAccountSuspendRequests"]],
docAccessAccountSuspendRequest?: [{	id: string},ResolverInputTypes["account_suspend_requestsDocAccess"]],
Device?: [{	id: string,	fallbackLocale?: ResolverInputTypes["FallbackLocaleInputType"] | undefined | null,	locale?: ResolverInputTypes["LocaleInputType"] | undefined | null},ResolverInputTypes["Device"]],
Devices?: [{	where?: ResolverInputTypes["Device_where"] | undefined | null,	fallbackLocale?: ResolverInputTypes["FallbackLocaleInputType"] | undefined | null,	locale?: ResolverInputTypes["LocaleInputType"] | undefined | null,	limit?: number | undefined | null,	page?: number | undefined | null,	sort?: string | undefined | null},ResolverInputTypes["Devices"]],
countDevices?: [{	where?: ResolverInputTypes["Device_where"] | undefined | null,	locale?: ResolverInputTypes["LocaleInputType"] | undefined | null},ResolverInputTypes["countDevices"]],
docAccessDevice?: [{	id: string},ResolverInputTypes["devicesDocAccess"]],
Notification?: [{	id: string,	fallbackLocale?: ResolverInputTypes["FallbackLocaleInputType"] | undefined | null,	locale?: ResolverInputTypes["LocaleInputType"] | undefined | null},ResolverInputTypes["Notification"]],
Notifications?: [{	where?: ResolverInputTypes["Notification_where"] | undefined | null,	fallbackLocale?: ResolverInputTypes["FallbackLocaleInputType"] | undefined | null,	locale?: ResolverInputTypes["LocaleInputType"] | undefined | null,	limit?: number | undefined | null,	page?: number | undefined | null,	sort?: string | undefined | null},ResolverInputTypes["Notifications"]],
countNotifications?: [{	where?: ResolverInputTypes["Notification_where"] | undefined | null,	locale?: ResolverInputTypes["LocaleInputType"] | undefined | null},ResolverInputTypes["countNotifications"]],
docAccessNotification?: [{	id: string},ResolverInputTypes["notificationsDocAccess"]],
RedirectSetting?: [{	id: string,	fallbackLocale?: ResolverInputTypes["FallbackLocaleInputType"] | undefined | null,	locale?: ResolverInputTypes["LocaleInputType"] | undefined | null},ResolverInputTypes["RedirectSetting"]],
RedirectSettings?: [{	where?: ResolverInputTypes["RedirectSetting_where"] | undefined | null,	fallbackLocale?: ResolverInputTypes["FallbackLocaleInputType"] | undefined | null,	locale?: ResolverInputTypes["LocaleInputType"] | undefined | null,	limit?: number | undefined | null,	page?: number | undefined | null,	sort?: string | undefined | null},ResolverInputTypes["RedirectSettings"]],
countRedirectSettings?: [{	where?: ResolverInputTypes["RedirectSetting_where"] | undefined | null,	locale?: ResolverInputTypes["LocaleInputType"] | undefined | null},ResolverInputTypes["countRedirectSettings"]],
docAccessRedirectSetting?: [{	id: string},ResolverInputTypes["redirectSettingDocAccess"]],
EventRanking?: [{	id: string,	fallbackLocale?: ResolverInputTypes["FallbackLocaleInputType"] | undefined | null,	locale?: ResolverInputTypes["LocaleInputType"] | undefined | null},ResolverInputTypes["EventRanking"]],
EventRankings?: [{	where?: ResolverInputTypes["EventRanking_where"] | undefined | null,	fallbackLocale?: ResolverInputTypes["FallbackLocaleInputType"] | undefined | null,	locale?: ResolverInputTypes["LocaleInputType"] | undefined | null,	limit?: number | undefined | null,	page?: number | undefined | null,	sort?: string | undefined | null},ResolverInputTypes["EventRankings"]],
countEventRankings?: [{	where?: ResolverInputTypes["EventRanking_where"] | undefined | null,	locale?: ResolverInputTypes["LocaleInputType"] | undefined | null},ResolverInputTypes["countEventRankings"]],
docAccessEventRanking?: [{	id: string},ResolverInputTypes["ranking_eventsDocAccess"]],
LocationRanking?: [{	id: string,	fallbackLocale?: ResolverInputTypes["FallbackLocaleInputType"] | undefined | null,	locale?: ResolverInputTypes["LocaleInputType"] | undefined | null},ResolverInputTypes["LocationRanking"]],
LocationRankings?: [{	where?: ResolverInputTypes["LocationRanking_where"] | undefined | null,	fallbackLocale?: ResolverInputTypes["FallbackLocaleInputType"] | undefined | null,	locale?: ResolverInputTypes["LocaleInputType"] | undefined | null,	limit?: number | undefined | null,	page?: number | undefined | null,	sort?: string | undefined | null},ResolverInputTypes["LocationRankings"]],
countLocationRankings?: [{	where?: ResolverInputTypes["LocationRanking_where"] | undefined | null,	locale?: ResolverInputTypes["LocaleInputType"] | undefined | null},ResolverInputTypes["countLocationRankings"]],
docAccessLocationRanking?: [{	id: string},ResolverInputTypes["ranking_locationsDocAccess"]],
EventCalendar?: [{	id: string,	fallbackLocale?: ResolverInputTypes["FallbackLocaleInputType"] | undefined | null,	locale?: ResolverInputTypes["LocaleInputType"] | undefined | null},ResolverInputTypes["EventCalendar"]],
EventCalendars?: [{	where?: ResolverInputTypes["EventCalendar_where"] | undefined | null,	fallbackLocale?: ResolverInputTypes["FallbackLocaleInputType"] | undefined | null,	locale?: ResolverInputTypes["LocaleInputType"] | undefined | null,	limit?: number | undefined | null,	page?: number | undefined | null,	sort?: string | undefined | null},ResolverInputTypes["EventCalendars"]],
countEventCalendars?: [{	where?: ResolverInputTypes["EventCalendar_where"] | undefined | null,	locale?: ResolverInputTypes["LocaleInputType"] | undefined | null},ResolverInputTypes["countEventCalendars"]],
docAccessEventCalendar?: [{	id: string},ResolverInputTypes["event_calendarDocAccess"]],
EditorChoice?: [{	id: string,	fallbackLocale?: ResolverInputTypes["FallbackLocaleInputType"] | undefined | null,	locale?: ResolverInputTypes["LocaleInputType"] | undefined | null},ResolverInputTypes["EditorChoice"]],
EditorChoices?: [{	where?: ResolverInputTypes["EditorChoice_where"] | undefined | null,	fallbackLocale?: ResolverInputTypes["FallbackLocaleInputType"] | undefined | null,	locale?: ResolverInputTypes["LocaleInputType"] | undefined | null,	limit?: number | undefined | null,	page?: number | undefined | null,	sort?: string | undefined | null},ResolverInputTypes["EditorChoices"]],
countEditorChoices?: [{	where?: ResolverInputTypes["EditorChoice_where"] | undefined | null,	locale?: ResolverInputTypes["LocaleInputType"] | undefined | null},ResolverInputTypes["countEditorChoices"]],
docAccessEditorChoice?: [{	id: string},ResolverInputTypes["editor_choicesDocAccess"]],
CriteriaSetting?: [{	id: string,	fallbackLocale?: ResolverInputTypes["FallbackLocaleInputType"] | undefined | null,	locale?: ResolverInputTypes["LocaleInputType"] | undefined | null},ResolverInputTypes["CriteriaSetting"]],
CriteriaSettings?: [{	where?: ResolverInputTypes["CriteriaSetting_where"] | undefined | null,	fallbackLocale?: ResolverInputTypes["FallbackLocaleInputType"] | undefined | null,	locale?: ResolverInputTypes["LocaleInputType"] | undefined | null,	limit?: number | undefined | null,	page?: number | undefined | null,	sort?: string | undefined | null},ResolverInputTypes["CriteriaSettings"]],
countCriteriaSettings?: [{	where?: ResolverInputTypes["CriteriaSetting_where"] | undefined | null,	locale?: ResolverInputTypes["LocaleInputType"] | undefined | null},ResolverInputTypes["countCriteriaSettings"]],
docAccessCriteriaSetting?: [{	id: string},ResolverInputTypes["criteria_settingDocAccess"]],
OrganiserSetting?: [{	id: string,	fallbackLocale?: ResolverInputTypes["FallbackLocaleInputType"] | undefined | null,	locale?: ResolverInputTypes["LocaleInputType"] | undefined | null},ResolverInputTypes["OrganiserSetting"]],
OrganiserSettings?: [{	where?: ResolverInputTypes["OrganiserSetting_where"] | undefined | null,	fallbackLocale?: ResolverInputTypes["FallbackLocaleInputType"] | undefined | null,	locale?: ResolverInputTypes["LocaleInputType"] | undefined | null,	limit?: number | undefined | null,	page?: number | undefined | null,	sort?: string | undefined | null},ResolverInputTypes["OrganiserSettings"]],
countOrganiserSettings?: [{	where?: ResolverInputTypes["OrganiserSetting_where"] | undefined | null,	locale?: ResolverInputTypes["LocaleInputType"] | undefined | null},ResolverInputTypes["countOrganiserSettings"]],
docAccessOrganiserSetting?: [{	id: string},ResolverInputTypes["organiserSettingDocAccess"]],
PostSetting?: [{	id: string,	fallbackLocale?: ResolverInputTypes["FallbackLocaleInputType"] | undefined | null,	locale?: ResolverInputTypes["LocaleInputType"] | undefined | null},ResolverInputTypes["PostSetting"]],
PostSettings?: [{	where?: ResolverInputTypes["PostSetting_where"] | undefined | null,	fallbackLocale?: ResolverInputTypes["FallbackLocaleInputType"] | undefined | null,	locale?: ResolverInputTypes["LocaleInputType"] | undefined | null,	limit?: number | undefined | null,	page?: number | undefined | null,	sort?: string | undefined | null},ResolverInputTypes["PostSettings"]],
countPostSettings?: [{	where?: ResolverInputTypes["PostSetting_where"] | undefined | null,	locale?: ResolverInputTypes["LocaleInputType"] | undefined | null},ResolverInputTypes["countPostSettings"]],
docAccessPostSetting?: [{	id: string},ResolverInputTypes["post_settingDocAccess"]],
HomepageSetting?: [{	id: string,	fallbackLocale?: ResolverInputTypes["FallbackLocaleInputType"] | undefined | null,	locale?: ResolverInputTypes["LocaleInputType"] | undefined | null},ResolverInputTypes["HomepageSetting"]],
HomepageSettings?: [{	where?: ResolverInputTypes["HomepageSetting_where"] | undefined | null,	fallbackLocale?: ResolverInputTypes["FallbackLocaleInputType"] | undefined | null,	locale?: ResolverInputTypes["LocaleInputType"] | undefined | null,	limit?: number | undefined | null,	page?: number | undefined | null,	sort?: string | undefined | null},ResolverInputTypes["HomepageSettings"]],
countHomepageSettings?: [{	where?: ResolverInputTypes["HomepageSetting_where"] | undefined | null,	locale?: ResolverInputTypes["LocaleInputType"] | undefined | null},ResolverInputTypes["countHomepageSettings"]],
docAccessHomepageSetting?: [{	id: string},ResolverInputTypes["homepageSettingDocAccess"]],
BannerSection?: [{	id: string,	fallbackLocale?: ResolverInputTypes["FallbackLocaleInputType"] | undefined | null,	locale?: ResolverInputTypes["LocaleInputType"] | undefined | null},ResolverInputTypes["BannerSection"]],
BannerSections?: [{	where?: ResolverInputTypes["BannerSection_where"] | undefined | null,	fallbackLocale?: ResolverInputTypes["FallbackLocaleInputType"] | undefined | null,	locale?: ResolverInputTypes["LocaleInputType"] | undefined | null,	limit?: number | undefined | null,	page?: number | undefined | null,	sort?: string | undefined | null},ResolverInputTypes["BannerSections"]],
countBannerSections?: [{	where?: ResolverInputTypes["BannerSection_where"] | undefined | null,	locale?: ResolverInputTypes["LocaleInputType"] | undefined | null},ResolverInputTypes["countBannerSections"]],
docAccessBannerSection?: [{	id: string},ResolverInputTypes["banner_sectionDocAccess"]],
MacaoBannerSection?: [{	id: string,	fallbackLocale?: ResolverInputTypes["FallbackLocaleInputType"] | undefined | null,	locale?: ResolverInputTypes["LocaleInputType"] | undefined | null},ResolverInputTypes["MacaoBannerSection"]],
MacaoBannerSections?: [{	where?: ResolverInputTypes["MacaoBannerSection_where"] | undefined | null,	fallbackLocale?: ResolverInputTypes["FallbackLocaleInputType"] | undefined | null,	locale?: ResolverInputTypes["LocaleInputType"] | undefined | null,	limit?: number | undefined | null,	page?: number | undefined | null,	sort?: string | undefined | null},ResolverInputTypes["MacaoBannerSections"]],
countMacaoBannerSections?: [{	where?: ResolverInputTypes["MacaoBannerSection_where"] | undefined | null,	locale?: ResolverInputTypes["LocaleInputType"] | undefined | null},ResolverInputTypes["countMacaoBannerSections"]],
docAccessMacaoBannerSection?: [{	id: string},ResolverInputTypes["macao_banner_sectionDocAccess"]],
Banner?: [{	id: string,	fallbackLocale?: ResolverInputTypes["FallbackLocaleInputType"] | undefined | null,	locale?: ResolverInputTypes["LocaleInputType"] | undefined | null},ResolverInputTypes["Banner"]],
Banners?: [{	where?: ResolverInputTypes["Banner_where"] | undefined | null,	fallbackLocale?: ResolverInputTypes["FallbackLocaleInputType"] | undefined | null,	locale?: ResolverInputTypes["LocaleInputType"] | undefined | null,	limit?: number | undefined | null,	page?: number | undefined | null,	sort?: string | undefined | null},ResolverInputTypes["Banners"]],
countBanners?: [{	where?: ResolverInputTypes["Banner_where"] | undefined | null,	locale?: ResolverInputTypes["LocaleInputType"] | undefined | null},ResolverInputTypes["countBanners"]],
docAccessBanner?: [{	id: string},ResolverInputTypes["bannersDocAccess"]],
Campaign?: [{	id: string,	fallbackLocale?: ResolverInputTypes["FallbackLocaleInputType"] | undefined | null,	locale?: ResolverInputTypes["LocaleInputType"] | undefined | null},ResolverInputTypes["Campaign"]],
Campaigns?: [{	where?: ResolverInputTypes["Campaign_where"] | undefined | null,	fallbackLocale?: ResolverInputTypes["FallbackLocaleInputType"] | undefined | null,	locale?: ResolverInputTypes["LocaleInputType"] | undefined | null,	limit?: number | undefined | null,	page?: number | undefined | null,	sort?: string | undefined | null},ResolverInputTypes["Campaigns"]],
countCampaigns?: [{	where?: ResolverInputTypes["Campaign_where"] | undefined | null,	locale?: ResolverInputTypes["LocaleInputType"] | undefined | null},ResolverInputTypes["countCampaigns"]],
docAccessCampaign?: [{	id: string},ResolverInputTypes["campaignsDocAccess"]],
Advertise?: [{	id: string,	fallbackLocale?: ResolverInputTypes["FallbackLocaleInputType"] | undefined | null,	locale?: ResolverInputTypes["LocaleInputType"] | undefined | null},ResolverInputTypes["Advertise"]],
Advertises?: [{	where?: ResolverInputTypes["Advertise_where"] | undefined | null,	fallbackLocale?: ResolverInputTypes["FallbackLocaleInputType"] | undefined | null,	locale?: ResolverInputTypes["LocaleInputType"] | undefined | null,	limit?: number | undefined | null,	page?: number | undefined | null,	sort?: string | undefined | null},ResolverInputTypes["Advertises"]],
countAdvertises?: [{	where?: ResolverInputTypes["Advertise_where"] | undefined | null,	locale?: ResolverInputTypes["LocaleInputType"] | undefined | null},ResolverInputTypes["countAdvertises"]],
docAccessAdvertise?: [{	id: string},ResolverInputTypes["advertisesDocAccess"]],
Static?: [{	id: string,	fallbackLocale?: ResolverInputTypes["FallbackLocaleInputType"] | undefined | null,	locale?: ResolverInputTypes["LocaleInputType"] | undefined | null},ResolverInputTypes["Static"]],
Statics?: [{	where?: ResolverInputTypes["Static_where"] | undefined | null,	fallbackLocale?: ResolverInputTypes["FallbackLocaleInputType"] | undefined | null,	locale?: ResolverInputTypes["LocaleInputType"] | undefined | null,	limit?: number | undefined | null,	page?: number | undefined | null,	sort?: string | undefined | null},ResolverInputTypes["Statics"]],
countStatics?: [{	where?: ResolverInputTypes["Static_where"] | undefined | null,	locale?: ResolverInputTypes["LocaleInputType"] | undefined | null},ResolverInputTypes["countStatics"]],
docAccessStatic?: [{	id: string},ResolverInputTypes["staticsDocAccess"]],
EventView?: [{	id: string,	fallbackLocale?: ResolverInputTypes["FallbackLocaleInputType"] | undefined | null,	locale?: ResolverInputTypes["LocaleInputType"] | undefined | null},ResolverInputTypes["EventView"]],
EventViews?: [{	where?: ResolverInputTypes["EventView_where"] | undefined | null,	fallbackLocale?: ResolverInputTypes["FallbackLocaleInputType"] | undefined | null,	locale?: ResolverInputTypes["LocaleInputType"] | undefined | null,	limit?: number | undefined | null,	page?: number | undefined | null,	sort?: string | undefined | null},ResolverInputTypes["EventViews"]],
countEventViews?: [{	where?: ResolverInputTypes["EventView_where"] | undefined | null,	locale?: ResolverInputTypes["LocaleInputType"] | undefined | null},ResolverInputTypes["countEventViews"]],
docAccessEventView?: [{	id: string},ResolverInputTypes["event_viewsDocAccess"]],
PostView?: [{	id: string,	fallbackLocale?: ResolverInputTypes["FallbackLocaleInputType"] | undefined | null,	locale?: ResolverInputTypes["LocaleInputType"] | undefined | null},ResolverInputTypes["PostView"]],
PostViews?: [{	where?: ResolverInputTypes["PostView_where"] | undefined | null,	fallbackLocale?: ResolverInputTypes["FallbackLocaleInputType"] | undefined | null,	locale?: ResolverInputTypes["LocaleInputType"] | undefined | null,	limit?: number | undefined | null,	page?: number | undefined | null,	sort?: string | undefined | null},ResolverInputTypes["PostViews"]],
countPostViews?: [{	where?: ResolverInputTypes["PostView_where"] | undefined | null,	locale?: ResolverInputTypes["LocaleInputType"] | undefined | null},ResolverInputTypes["countPostViews"]],
docAccessPostView?: [{	id: string},ResolverInputTypes["post_viewsDocAccess"]],
PayloadPreference?: [{	id: string,	fallbackLocale?: ResolverInputTypes["FallbackLocaleInputType"] | undefined | null,	locale?: ResolverInputTypes["LocaleInputType"] | undefined | null},ResolverInputTypes["PayloadPreference"]],
PayloadPreferences?: [{	where?: ResolverInputTypes["PayloadPreference_where"] | undefined | null,	fallbackLocale?: ResolverInputTypes["FallbackLocaleInputType"] | undefined | null,	locale?: ResolverInputTypes["LocaleInputType"] | undefined | null,	limit?: number | undefined | null,	page?: number | undefined | null,	sort?: string | undefined | null},ResolverInputTypes["PayloadPreferences"]],
countPayloadPreferences?: [{	where?: ResolverInputTypes["PayloadPreference_where"] | undefined | null,	locale?: ResolverInputTypes["LocaleInputType"] | undefined | null},ResolverInputTypes["countPayloadPreferences"]],
docAccessPayloadPreference?: [{	id: string},ResolverInputTypes["payload_preferencesDocAccess"]],
	Access?:ResolverInputTypes["Access"],
GetEvents?: [{	where?: ResolverInputTypes["Event_where"] | undefined | null,	fallbackLocale?: ResolverInputTypes["FallbackLocaleInputType"] | undefined | null,	locale?: ResolverInputTypes["LocaleInputType"] | undefined | null,	limit?: number | undefined | null,	page?: number | undefined | null,	sort?: string | undefined | null},ResolverInputTypes["Events"]],
GetFilterEvents?: [{	where?: ResolverInputTypes["Event_where"] | undefined | null,	fallbackLocale?: ResolverInputTypes["FallbackLocaleInputType"] | undefined | null,	locale?: ResolverInputTypes["LocaleInputType"] | undefined | null,	limit?: number | undefined | null,	page?: number | undefined | null,	sort?: string | undefined | null},ResolverInputTypes["Events"]],
GetFilterPastEvents?: [{	where?: ResolverInputTypes["Event_where"] | undefined | null,	fallbackLocale?: ResolverInputTypes["FallbackLocaleInputType"] | undefined | null,	locale?: ResolverInputTypes["LocaleInputType"] | undefined | null,	limit?: number | undefined | null,	page?: number | undefined | null,	sort?: string | undefined | null},ResolverInputTypes["Events"]],
GetEventMaxUsedTag?: [{	filterType?: string | undefined | null,	where?: ResolverInputTypes["Event_where"] | undefined | null,	fallbackLocale?: ResolverInputTypes["FallbackLocaleInputType"] | undefined | null,	locale?: ResolverInputTypes["LocaleInputType"] | undefined | null,	limit?: number | undefined | null,	page?: number | undefined | null,	sort?: string | undefined | null},ResolverInputTypes["GetEventMaxUsedTag"]],
GetSortEvent?: [{	where?: ResolverInputTypes["Event_where"] | undefined | null,	fallbackLocale?: ResolverInputTypes["FallbackLocaleInputType"] | undefined | null,	locale?: ResolverInputTypes["LocaleInputType"] | undefined | null,	limit?: number | undefined | null,	page?: number | undefined | null,	sort?: string | undefined | null},ResolverInputTypes["Events"]],
BookmarkEvents?: [{	where?: ResolverInputTypes["Event_where"] | undefined | null,	fallbackLocale?: ResolverInputTypes["FallbackLocaleInputType"] | undefined | null,	locale?: ResolverInputTypes["LocaleInputType"] | undefined | null,	limit?: number | undefined | null,	page?: number | undefined | null,	sort?: string | undefined | null},ResolverInputTypes["Events"]],
GetBookmarks?: [{	where?: ResolverInputTypes["Bookmark_where"] | undefined | null,	fallbackLocale?: ResolverInputTypes["FallbackLocaleInputType"] | undefined | null,	locale?: ResolverInputTypes["LocaleInputType"] | undefined | null,	limit?: number | undefined | null,	page?: number | undefined | null,	sort?: string | undefined | null},ResolverInputTypes["Bookmarks"]],
BookmarkItems?: [{	where?: ResolverInputTypes["Bookmark_where"] | undefined | null,	fallbackLocale?: ResolverInputTypes["FallbackLocaleInputType"] | undefined | null,	locale?: ResolverInputTypes["LocaleInputType"] | undefined | null,	limit?: number | undefined | null,	page?: number | undefined | null,	sort?: string | undefined | null},ResolverInputTypes["Bookmarks"]],
ViewedEvents?: [{	where?: ResolverInputTypes["Event_where"] | undefined | null,	fallbackLocale?: ResolverInputTypes["FallbackLocaleInputType"] | undefined | null,	locale?: ResolverInputTypes["LocaleInputType"] | undefined | null,	limit?: number | undefined | null,	page?: number | undefined | null,	sort?: string | undefined | null},ResolverInputTypes["Events"]],
NonFeaturedPosts?: [{	where?: ResolverInputTypes["Post_where"] | undefined | null,	fallbackLocale?: ResolverInputTypes["FallbackLocaleInputType"] | undefined | null,	locale?: ResolverInputTypes["LocaleInputType"] | undefined | null,	limit?: number | undefined | null,	page?: number | undefined | null,	sort?: string | undefined | null},ResolverInputTypes["Posts"]],
CheckEmailUniqueness?: [{	email?: string | undefined | null},boolean | `@${string}`],
GetOrganisers?: [{	where?: ResolverInputTypes["User_where"] | undefined | null,	fallbackLocale?: ResolverInputTypes["FallbackLocaleInputType"] | undefined | null,	locale?: ResolverInputTypes["LocaleInputType"] | undefined | null,	limit?: number | undefined | null,	page?: number | undefined | null,	sort?: string | undefined | null},ResolverInputTypes["Users"]],
GetOrganiser?: [{	id: string,	fallbackLocale?: ResolverInputTypes["FallbackLocaleInputType"] | undefined | null,	locale?: ResolverInputTypes["LocaleInputType"] | undefined | null},ResolverInputTypes["User"]],
GetSubscriptions?: [{	where?: ResolverInputTypes["Subscription_where"] | undefined | null,	fallbackLocale?: ResolverInputTypes["FallbackLocaleInputType"] | undefined | null,	locale?: ResolverInputTypes["LocaleInputType"] | undefined | null,	limit?: number | undefined | null,	page?: number | undefined | null,	sort?: string | undefined | null},ResolverInputTypes["Subscriptions"]],
GetFollowed?: [{	where?: ResolverInputTypes["Subscription_where"] | undefined | null,	fallbackLocale?: ResolverInputTypes["FallbackLocaleInputType"] | undefined | null,	locale?: ResolverInputTypes["LocaleInputType"] | undefined | null,	limit?: number | undefined | null,	page?: number | undefined | null,	sort?: string | undefined | null},ResolverInputTypes["Subscriptions"]],
GetComments?: [{	where?: ResolverInputTypes["Comment_where"] | undefined | null,	fallbackLocale?: ResolverInputTypes["FallbackLocaleInputType"] | undefined | null,	locale?: ResolverInputTypes["LocaleInputType"] | undefined | null,	limit?: number | undefined | null,	page?: number | undefined | null,	sort?: string | undefined | null},ResolverInputTypes["Comments"]],
CommentsCount?: [{	where?: ResolverInputTypes["Comment_where"] | undefined | null,	fallbackLocale?: ResolverInputTypes["FallbackLocaleInputType"] | undefined | null,	locale?: ResolverInputTypes["LocaleInputType"] | undefined | null,	limit?: number | undefined | null,	page?: number | undefined | null,	sort?: string | undefined | null},ResolverInputTypes["Comments"]],
GetBanners?: [{	where?: ResolverInputTypes["Banner_where"] | undefined | null,	fallbackLocale?: ResolverInputTypes["FallbackLocaleInputType"] | undefined | null,	locale?: ResolverInputTypes["LocaleInputType"] | undefined | null,	limit?: number | undefined | null,	page?: number | undefined | null,	sort?: string | undefined | null},ResolverInputTypes["Banners"]],
GetBannerSection?: [{	where?: ResolverInputTypes["Banner_where"] | undefined | null,	fallbackLocale?: ResolverInputTypes["FallbackLocaleInputType"] | undefined | null,	locale?: ResolverInputTypes["LocaleInputType"] | undefined | null,	limit?: number | undefined | null,	page?: number | undefined | null,	sort?: string | undefined | null,	slug?: string | undefined | null},ResolverInputTypes["Banners"]],
GetNotifications?: [{	where?: ResolverInputTypes["Notification_where"] | undefined | null,	fallbackLocale?: ResolverInputTypes["FallbackLocaleInputType"] | undefined | null,	locale?: ResolverInputTypes["LocaleInputType"] | undefined | null,	limit?: number | undefined | null,	page?: number | undefined | null,	sort?: string | undefined | null},ResolverInputTypes["Notifications"]],
GetCriteriaSettings?: [{	where?: ResolverInputTypes["CriteriaSetting_where"] | undefined | null,	fallbackLocale?: ResolverInputTypes["FallbackLocaleInputType"] | undefined | null,	locale?: ResolverInputTypes["LocaleInputType"] | undefined | null,	limit?: number | undefined | null,	page?: number | undefined | null,	sort?: string | undefined | null},ResolverInputTypes["CriteriaSettings"]],
GetFilterLocations?: [{	where?: ResolverInputTypes["Location_where"] | undefined | null,	fallbackLocale?: ResolverInputTypes["FallbackLocaleInputType"] | undefined | null,	locale?: ResolverInputTypes["LocaleInputType"] | undefined | null,	limit?: number | undefined | null,	page?: number | undefined | null,	sort?: string | undefined | null},ResolverInputTypes["Locations"]],
GetContainEventTagLocations?: [{	where?: ResolverInputTypes["Location_where"] | undefined | null,	fallbackLocale?: ResolverInputTypes["FallbackLocaleInputType"] | undefined | null,	locale?: ResolverInputTypes["LocaleInputType"] | undefined | null,	limit?: number | undefined | null,	page?: number | undefined | null,	sort?: string | undefined | null},ResolverInputTypes["Locations"]],
GetEventRankings?: [{	where?: ResolverInputTypes["EventRanking_where"] | undefined | null,	fallbackLocale?: ResolverInputTypes["FallbackLocaleInputType"] | undefined | null,	locale?: ResolverInputTypes["LocaleInputType"] | undefined | null,	limit?: number | undefined | null,	page?: number | undefined | null,	sort?: string | undefined | null},ResolverInputTypes["EventRankings"]],
ChatbotStocks?: [{	locale?: ResolverInputTypes["LocaleInputType"] | undefined | null},ResolverInputTypes["ChatbotStocks"]],
ChatbotProduct?: [{	productId: string,	locale?: ResolverInputTypes["LocaleInputType"] | undefined | null},ResolverInputTypes["Product"]],
ChatbotTicketing?: [{	ticketingId: string,	locale?: ResolverInputTypes["LocaleInputType"] | undefined | null},ResolverInputTypes["Ticketing"]],
ChatbotEvents?: [{	locale?: ResolverInputTypes["LocaleInputType"] | undefined | null},ResolverInputTypes["ChatbotEvents"]],
FilterExpiredEvent?: [{	where?: ResolverInputTypes["EditorChoice_where"] | undefined | null,	fallbackLocale?: ResolverInputTypes["FallbackLocaleInputType"] | undefined | null,	locale?: ResolverInputTypes["LocaleInputType"] | undefined | null,	limit?: number | undefined | null,	page?: number | undefined | null,	sort?: string | undefined | null},ResolverInputTypes["EditorChoices"]],
WebAppCheckInCountGet?: [{	checkInAppSelectionId: string,	productType: string,	locale?: ResolverInputTypes["LocaleInputType"] | undefined | null},ResolverInputTypes["WebAppCheckInCountGetResponse"]],
WebAppCheckInList?: [{	where?: ResolverInputTypes["CheckInRedemption_where"] | undefined | null,	fallbackLocale?: ResolverInputTypes["FallbackLocaleInputType"] | undefined | null,	locale?: ResolverInputTypes["LocaleInputType"] | undefined | null,	limit?: number | undefined | null,	page?: number | undefined | null,	sort?: string | undefined | null},ResolverInputTypes["CheckInRedemptions"]],
WebAppEventList?: [{	where?: ResolverInputTypes["Event_where"] | undefined | null,	fallbackLocale?: ResolverInputTypes["FallbackLocaleInputType"] | undefined | null,	locale?: ResolverInputTypes["LocaleInputType"] | undefined | null,	limit?: number | undefined | null,	page?: number | undefined | null,	sort?: string | undefined | null},ResolverInputTypes["Events"]],
WebAppProductDetail?: [{	locale?: ResolverInputTypes["LocaleInputType"] | undefined | null,	where?: ResolverInputTypes["ProductWhereInput"] | undefined | null},ResolverInputTypes["Product"]],
WebAppProductList?: [{	eventId: string,	locale?: ResolverInputTypes["LocaleInputType"] | undefined | null},ResolverInputTypes["ProductList"]],
WebAppTicketingDetail?: [{	locale?: ResolverInputTypes["LocaleInputType"] | undefined | null,	where?: ResolverInputTypes["TicketingWhereInput"] | undefined | null},ResolverInputTypes["Ticketing"]],
WebAppTicketingList?: [{	eventId: string,	locale?: ResolverInputTypes["LocaleInputType"] | undefined | null},ResolverInputTypes["TicketingList"]],
WebAppRedemptionCodeList?: [{	locale?: ResolverInputTypes["LocaleInputType"] | undefined | null},ResolverInputTypes["RedemptionCodeList"]],
WebAppRedemptionCodeDetail?: [{	where?: ResolverInputTypes["RedemptionCodeWhereInput"] | undefined | null},ResolverInputTypes["RedemptionCode"]],
WebAppBookmarkList?: [{	userId?: string | undefined | null},ResolverInputTypes["BookmarkList"]],
		__typename?: boolean | `@${string}`
}>;
	["Event"]: AliasType<{
	id?:boolean | `@${string}`,
	region?:boolean | `@${string}`,
creator?: [{	locale?: ResolverInputTypes["LocaleInputType"] | undefined | null,	fallbackLocale?: ResolverInputTypes["FallbackLocaleInputType"] | undefined | null},ResolverInputTypes["User"]],
	permalink?:boolean | `@${string}`,
	status?:boolean | `@${string}`,
	level?:boolean | `@${string}`,
parent?: [{	locale?: ResolverInputTypes["LocaleInputType"] | undefined | null,	fallbackLocale?: ResolverInputTypes["FallbackLocaleInputType"] | undefined | null},ResolverInputTypes["Event"]],
	name?:boolean | `@${string}`,
	chatbotEventTitle?:boolean | `@${string}`,
	chatbotSequence?:boolean | `@${string}`,
thumbnail?: [{	locale?: ResolverInputTypes["LocaleInputType"] | undefined | null,	fallbackLocale?: ResolverInputTypes["FallbackLocaleInputType"] | undefined | null,	where?: ResolverInputTypes["Event_Thumbnail_where"] | undefined | null},ResolverInputTypes["Media"]],
banner?: [{	locale?: ResolverInputTypes["LocaleInputType"] | undefined | null,	fallbackLocale?: ResolverInputTypes["FallbackLocaleInputType"] | undefined | null,	where?: ResolverInputTypes["Event_Banner_where"] | undefined | null},ResolverInputTypes["Media"]],
mobileBanner?: [{	locale?: ResolverInputTypes["LocaleInputType"] | undefined | null,	fallbackLocale?: ResolverInputTypes["FallbackLocaleInputType"] | undefined | null,	where?: ResolverInputTypes["Event_MobileBanner_where"] | undefined | null},ResolverInputTypes["Media"]],
	sections?:ResolverInputTypes["Event_Sections"],
	ticketing?:ResolverInputTypes["Event_Ticketing"],
	content?:ResolverInputTypes["Event_Content"],
	legacyContent?:boolean | `@${string}`,
	showLegacyContent?:boolean | `@${string}`,
	criteria?:ResolverInputTypes["Event_Criteria"],
	walkIn?:boolean | `@${string}`,
	contact?:ResolverInputTypes["Event_Contact"],
	views?:boolean | `@${string}`,
	free?:boolean | `@${string}`,
	fees?:ResolverInputTypes["Event_Fees"],
	homePageInternalClick?:boolean | `@${string}`,
	homePageImpression?:boolean | `@${string}`,
	eodClickRate?:boolean | `@${string}`,
	eodImpressionRate?:boolean | `@${string}`,
	top10ClickRateHomepage?:boolean | `@${string}`,
	top10ImpressionkRateHomepage?:boolean | `@${string}`,
	otherEventRankingClickRateHomepage?:boolean | `@${string}`,
	otherEventRankingImpressionHomePage?:boolean | `@${string}`,
	userPreferenceClickRate?:boolean | `@${string}`,
	userPreferenceImpression?:boolean | `@${string}`,
	eventSearchInternalClick?:boolean | `@${string}`,
	eventListImpression?:boolean | `@${string}`,
	latestEventInternalClick?:boolean | `@${string}`,
	latestEventImpression?:boolean | `@${string}`,
	topTenEventRankingInternalClick?:boolean | `@${string}`,
	topTenImpression?:boolean | `@${string}`,
	editorChoiceInternalClick?:boolean | `@${string}`,
	editorChoiceImpression?:boolean | `@${string}`,
	otherEventRankingInternalClick?:boolean | `@${string}`,
	otherEventRankingImpression?:boolean | `@${string}`,
	bannerInternalClick?:boolean | `@${string}`,
	miscImpression?:boolean | `@${string}`,
	miscInternalClick?:boolean | `@${string}`,
	totalImpression?:boolean | `@${string}`,
	internalClick?:boolean | `@${string}`,
	outboundClick?:boolean | `@${string}`,
	externalClick?:boolean | `@${string}`,
	_viewsCountByMonth?:boolean | `@${string}`,
	products?:ResolverInputTypes["Event_Products"],
legacyGallery?: [{	locale?: ResolverInputTypes["LocaleInputType"] | undefined | null,	fallbackLocale?: ResolverInputTypes["FallbackLocaleInputType"] | undefined | null},ResolverInputTypes["Media"]],
	earliestStartDate?:boolean | `@${string}`,
	latestEndDate?:boolean | `@${string}`,
	latestStartDateTime?:boolean | `@${string}`,
	hasTicketing?:boolean | `@${string}`,
	hasProducts?:boolean | `@${string}`,
	updatedAt?:boolean | `@${string}`,
	createdAt?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["Event_region"]:Event_region;
	["User"]: AliasType<{
	id?:boolean | `@${string}`,
	region?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	displayEmail?:boolean | `@${string}`,
	legacyUsername?:boolean | `@${string}`,
	roles?:boolean | `@${string}`,
	member?:ResolverInputTypes["User_Member"],
	organiser?:ResolverInputTypes["User_Organiser"],
	emailVerified?:boolean | `@${string}`,
	sso?:ResolverInputTypes["User_Sso"],
	oldPassword?:boolean | `@${string}`,
	status?:boolean | `@${string}`,
	updatedAt?:boolean | `@${string}`,
	createdAt?:boolean | `@${string}`,
	email?:boolean | `@${string}`,
	resetPasswordToken?:boolean | `@${string}`,
	resetPasswordExpiration?:boolean | `@${string}`,
	salt?:boolean | `@${string}`,
	hash?:boolean | `@${string}`,
	loginAttempts?:boolean | `@${string}`,
	lockUntil?:boolean | `@${string}`,
	password?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["User_region"]:User_region;
	["User_roles"]:User_roles;
	["User_Member"]: AliasType<{
	region?:boolean | `@${string}`,
	language?:boolean | `@${string}`,
	phone?:boolean | `@${string}`,
avatar?: [{	locale?: ResolverInputTypes["LocaleInputType"] | undefined | null,	fallbackLocale?: ResolverInputTypes["FallbackLocaleInputType"] | undefined | null,	where?: ResolverInputTypes["User_Member_Avatar_where"] | undefined | null},ResolverInputTypes["Media"]],
	birthday?:boolean | `@${string}`,
	gender?:boolean | `@${string}`,
categories?: [{	locale?: ResolverInputTypes["LocaleInputType"] | undefined | null,	fallbackLocale?: ResolverInputTypes["FallbackLocaleInputType"] | undefined | null},ResolverInputTypes["EventCategory"]],
		__typename?: boolean | `@${string}`
}>;
	["User_Member_region"]:User_Member_region;
	["User_Member_language"]:User_Member_language;
	["Media"]: AliasType<{
	id?:boolean | `@${string}`,
	alt?:boolean | `@${string}`,
	updatedAt?:boolean | `@${string}`,
	createdAt?:boolean | `@${string}`,
	url?:boolean | `@${string}`,
	filename?:boolean | `@${string}`,
	mimeType?:boolean | `@${string}`,
	filesize?:boolean | `@${string}`,
	width?:boolean | `@${string}`,
	height?:boolean | `@${string}`,
	focalX?:boolean | `@${string}`,
	focalY?:boolean | `@${string}`,
	sizes?:ResolverInputTypes["Media_Sizes"],
		__typename?: boolean | `@${string}`
}>;
	/** A date-time string at UTC, such as 2007-12-03T10:15:30Z, compliant with the `date-time` format outlined in section 5.6 of the RFC 3339 profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar. */
["DateTime"]:unknown;
	["Media_Sizes"]: AliasType<{
	small?:ResolverInputTypes["Media_Sizes_Small"],
	medium?:ResolverInputTypes["Media_Sizes_Medium"],
	large?:ResolverInputTypes["Media_Sizes_Large"],
	small_jpeg?:ResolverInputTypes["Media_Sizes_Small_jpeg"],
	medium_jpeg?:ResolverInputTypes["Media_Sizes_Medium_jpeg"],
	large_jpeg?:ResolverInputTypes["Media_Sizes_Large_jpeg"],
		__typename?: boolean | `@${string}`
}>;
	["Media_Sizes_Small"]: AliasType<{
	url?:boolean | `@${string}`,
	width?:boolean | `@${string}`,
	height?:boolean | `@${string}`,
	mimeType?:boolean | `@${string}`,
	filesize?:boolean | `@${string}`,
	filename?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["Media_Sizes_Medium"]: AliasType<{
	url?:boolean | `@${string}`,
	width?:boolean | `@${string}`,
	height?:boolean | `@${string}`,
	mimeType?:boolean | `@${string}`,
	filesize?:boolean | `@${string}`,
	filename?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["Media_Sizes_Large"]: AliasType<{
	url?:boolean | `@${string}`,
	width?:boolean | `@${string}`,
	height?:boolean | `@${string}`,
	mimeType?:boolean | `@${string}`,
	filesize?:boolean | `@${string}`,
	filename?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["Media_Sizes_Small_jpeg"]: AliasType<{
	url?:boolean | `@${string}`,
	width?:boolean | `@${string}`,
	height?:boolean | `@${string}`,
	mimeType?:boolean | `@${string}`,
	filesize?:boolean | `@${string}`,
	filename?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["Media_Sizes_Medium_jpeg"]: AliasType<{
	url?:boolean | `@${string}`,
	width?:boolean | `@${string}`,
	height?:boolean | `@${string}`,
	mimeType?:boolean | `@${string}`,
	filesize?:boolean | `@${string}`,
	filename?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["Media_Sizes_Large_jpeg"]: AliasType<{
	url?:boolean | `@${string}`,
	width?:boolean | `@${string}`,
	height?:boolean | `@${string}`,
	mimeType?:boolean | `@${string}`,
	filesize?:boolean | `@${string}`,
	filename?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocaleInputType"]:LocaleInputType;
	["FallbackLocaleInputType"]:FallbackLocaleInputType;
	["User_Member_Avatar_where"]: {
	alt?: ResolverInputTypes["User_Member_Avatar_alt_operator"] | undefined | null,
	updatedAt?: ResolverInputTypes["User_Member_Avatar_updatedAt_operator"] | undefined | null,
	createdAt?: ResolverInputTypes["User_Member_Avatar_createdAt_operator"] | undefined | null,
	url?: ResolverInputTypes["User_Member_Avatar_url_operator"] | undefined | null,
	filename?: ResolverInputTypes["User_Member_Avatar_filename_operator"] | undefined | null,
	mimeType?: ResolverInputTypes["User_Member_Avatar_mimeType_operator"] | undefined | null,
	filesize?: ResolverInputTypes["User_Member_Avatar_filesize_operator"] | undefined | null,
	width?: ResolverInputTypes["User_Member_Avatar_width_operator"] | undefined | null,
	height?: ResolverInputTypes["User_Member_Avatar_height_operator"] | undefined | null,
	focalX?: ResolverInputTypes["User_Member_Avatar_focalX_operator"] | undefined | null,
	focalY?: ResolverInputTypes["User_Member_Avatar_focalY_operator"] | undefined | null,
	sizes__small__url?: ResolverInputTypes["User_Member_Avatar_sizes__small__url_operator"] | undefined | null,
	sizes__small__width?: ResolverInputTypes["User_Member_Avatar_sizes__small__width_operator"] | undefined | null,
	sizes__small__height?: ResolverInputTypes["User_Member_Avatar_sizes__small__height_operator"] | undefined | null,
	sizes__small__mimeType?: ResolverInputTypes["User_Member_Avatar_sizes__small__mimeType_operator"] | undefined | null,
	sizes__small__filesize?: ResolverInputTypes["User_Member_Avatar_sizes__small__filesize_operator"] | undefined | null,
	sizes__small__filename?: ResolverInputTypes["User_Member_Avatar_sizes__small__filename_operator"] | undefined | null,
	sizes__medium__url?: ResolverInputTypes["User_Member_Avatar_sizes__medium__url_operator"] | undefined | null,
	sizes__medium__width?: ResolverInputTypes["User_Member_Avatar_sizes__medium__width_operator"] | undefined | null,
	sizes__medium__height?: ResolverInputTypes["User_Member_Avatar_sizes__medium__height_operator"] | undefined | null,
	sizes__medium__mimeType?: ResolverInputTypes["User_Member_Avatar_sizes__medium__mimeType_operator"] | undefined | null,
	sizes__medium__filesize?: ResolverInputTypes["User_Member_Avatar_sizes__medium__filesize_operator"] | undefined | null,
	sizes__medium__filename?: ResolverInputTypes["User_Member_Avatar_sizes__medium__filename_operator"] | undefined | null,
	sizes__large__url?: ResolverInputTypes["User_Member_Avatar_sizes__large__url_operator"] | undefined | null,
	sizes__large__width?: ResolverInputTypes["User_Member_Avatar_sizes__large__width_operator"] | undefined | null,
	sizes__large__height?: ResolverInputTypes["User_Member_Avatar_sizes__large__height_operator"] | undefined | null,
	sizes__large__mimeType?: ResolverInputTypes["User_Member_Avatar_sizes__large__mimeType_operator"] | undefined | null,
	sizes__large__filesize?: ResolverInputTypes["User_Member_Avatar_sizes__large__filesize_operator"] | undefined | null,
	sizes__large__filename?: ResolverInputTypes["User_Member_Avatar_sizes__large__filename_operator"] | undefined | null,
	sizes__small_jpeg__url?: ResolverInputTypes["User_Member_Avatar_sizes__small_jpeg__url_operator"] | undefined | null,
	sizes__small_jpeg__width?: ResolverInputTypes["User_Member_Avatar_sizes__small_jpeg__width_operator"] | undefined | null,
	sizes__small_jpeg__height?: ResolverInputTypes["User_Member_Avatar_sizes__small_jpeg__height_operator"] | undefined | null,
	sizes__small_jpeg__mimeType?: ResolverInputTypes["User_Member_Avatar_sizes__small_jpeg__mimeType_operator"] | undefined | null,
	sizes__small_jpeg__filesize?: ResolverInputTypes["User_Member_Avatar_sizes__small_jpeg__filesize_operator"] | undefined | null,
	sizes__small_jpeg__filename?: ResolverInputTypes["User_Member_Avatar_sizes__small_jpeg__filename_operator"] | undefined | null,
	sizes__medium_jpeg__url?: ResolverInputTypes["User_Member_Avatar_sizes__medium_jpeg__url_operator"] | undefined | null,
	sizes__medium_jpeg__width?: ResolverInputTypes["User_Member_Avatar_sizes__medium_jpeg__width_operator"] | undefined | null,
	sizes__medium_jpeg__height?: ResolverInputTypes["User_Member_Avatar_sizes__medium_jpeg__height_operator"] | undefined | null,
	sizes__medium_jpeg__mimeType?: ResolverInputTypes["User_Member_Avatar_sizes__medium_jpeg__mimeType_operator"] | undefined | null,
	sizes__medium_jpeg__filesize?: ResolverInputTypes["User_Member_Avatar_sizes__medium_jpeg__filesize_operator"] | undefined | null,
	sizes__medium_jpeg__filename?: ResolverInputTypes["User_Member_Avatar_sizes__medium_jpeg__filename_operator"] | undefined | null,
	sizes__large_jpeg__url?: ResolverInputTypes["User_Member_Avatar_sizes__large_jpeg__url_operator"] | undefined | null,
	sizes__large_jpeg__width?: ResolverInputTypes["User_Member_Avatar_sizes__large_jpeg__width_operator"] | undefined | null,
	sizes__large_jpeg__height?: ResolverInputTypes["User_Member_Avatar_sizes__large_jpeg__height_operator"] | undefined | null,
	sizes__large_jpeg__mimeType?: ResolverInputTypes["User_Member_Avatar_sizes__large_jpeg__mimeType_operator"] | undefined | null,
	sizes__large_jpeg__filesize?: ResolverInputTypes["User_Member_Avatar_sizes__large_jpeg__filesize_operator"] | undefined | null,
	sizes__large_jpeg__filename?: ResolverInputTypes["User_Member_Avatar_sizes__large_jpeg__filename_operator"] | undefined | null,
	id?: ResolverInputTypes["User_Member_Avatar_id_operator"] | undefined | null,
	AND?: Array<ResolverInputTypes["User_Member_Avatar_where_and"] | undefined | null> | undefined | null,
	OR?: Array<ResolverInputTypes["User_Member_Avatar_where_or"] | undefined | null> | undefined | null
};
	["User_Member_Avatar_alt_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["User_Member_Avatar_updatedAt_operator"]: {
	equals?: ResolverInputTypes["DateTime"] | undefined | null,
	not_equals?: ResolverInputTypes["DateTime"] | undefined | null,
	greater_than_equal?: ResolverInputTypes["DateTime"] | undefined | null,
	greater_than?: ResolverInputTypes["DateTime"] | undefined | null,
	less_than_equal?: ResolverInputTypes["DateTime"] | undefined | null,
	less_than?: ResolverInputTypes["DateTime"] | undefined | null,
	like?: ResolverInputTypes["DateTime"] | undefined | null,
	exists?: boolean | undefined | null
};
	["User_Member_Avatar_createdAt_operator"]: {
	equals?: ResolverInputTypes["DateTime"] | undefined | null,
	not_equals?: ResolverInputTypes["DateTime"] | undefined | null,
	greater_than_equal?: ResolverInputTypes["DateTime"] | undefined | null,
	greater_than?: ResolverInputTypes["DateTime"] | undefined | null,
	less_than_equal?: ResolverInputTypes["DateTime"] | undefined | null,
	less_than?: ResolverInputTypes["DateTime"] | undefined | null,
	like?: ResolverInputTypes["DateTime"] | undefined | null,
	exists?: boolean | undefined | null
};
	["User_Member_Avatar_url_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["User_Member_Avatar_filename_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["User_Member_Avatar_mimeType_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["User_Member_Avatar_filesize_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["User_Member_Avatar_width_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["User_Member_Avatar_height_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["User_Member_Avatar_focalX_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["User_Member_Avatar_focalY_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["User_Member_Avatar_sizes__small__url_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["User_Member_Avatar_sizes__small__width_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["User_Member_Avatar_sizes__small__height_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["User_Member_Avatar_sizes__small__mimeType_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["User_Member_Avatar_sizes__small__filesize_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["User_Member_Avatar_sizes__small__filename_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["User_Member_Avatar_sizes__medium__url_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["User_Member_Avatar_sizes__medium__width_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["User_Member_Avatar_sizes__medium__height_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["User_Member_Avatar_sizes__medium__mimeType_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["User_Member_Avatar_sizes__medium__filesize_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["User_Member_Avatar_sizes__medium__filename_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["User_Member_Avatar_sizes__large__url_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["User_Member_Avatar_sizes__large__width_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["User_Member_Avatar_sizes__large__height_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["User_Member_Avatar_sizes__large__mimeType_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["User_Member_Avatar_sizes__large__filesize_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["User_Member_Avatar_sizes__large__filename_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["User_Member_Avatar_sizes__small_jpeg__url_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["User_Member_Avatar_sizes__small_jpeg__width_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["User_Member_Avatar_sizes__small_jpeg__height_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["User_Member_Avatar_sizes__small_jpeg__mimeType_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["User_Member_Avatar_sizes__small_jpeg__filesize_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["User_Member_Avatar_sizes__small_jpeg__filename_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["User_Member_Avatar_sizes__medium_jpeg__url_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["User_Member_Avatar_sizes__medium_jpeg__width_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["User_Member_Avatar_sizes__medium_jpeg__height_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["User_Member_Avatar_sizes__medium_jpeg__mimeType_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["User_Member_Avatar_sizes__medium_jpeg__filesize_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["User_Member_Avatar_sizes__medium_jpeg__filename_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["User_Member_Avatar_sizes__large_jpeg__url_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["User_Member_Avatar_sizes__large_jpeg__width_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["User_Member_Avatar_sizes__large_jpeg__height_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["User_Member_Avatar_sizes__large_jpeg__mimeType_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["User_Member_Avatar_sizes__large_jpeg__filesize_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["User_Member_Avatar_sizes__large_jpeg__filename_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["User_Member_Avatar_id_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["User_Member_Avatar_where_and"]: {
	alt?: ResolverInputTypes["User_Member_Avatar_alt_operator"] | undefined | null,
	updatedAt?: ResolverInputTypes["User_Member_Avatar_updatedAt_operator"] | undefined | null,
	createdAt?: ResolverInputTypes["User_Member_Avatar_createdAt_operator"] | undefined | null,
	url?: ResolverInputTypes["User_Member_Avatar_url_operator"] | undefined | null,
	filename?: ResolverInputTypes["User_Member_Avatar_filename_operator"] | undefined | null,
	mimeType?: ResolverInputTypes["User_Member_Avatar_mimeType_operator"] | undefined | null,
	filesize?: ResolverInputTypes["User_Member_Avatar_filesize_operator"] | undefined | null,
	width?: ResolverInputTypes["User_Member_Avatar_width_operator"] | undefined | null,
	height?: ResolverInputTypes["User_Member_Avatar_height_operator"] | undefined | null,
	focalX?: ResolverInputTypes["User_Member_Avatar_focalX_operator"] | undefined | null,
	focalY?: ResolverInputTypes["User_Member_Avatar_focalY_operator"] | undefined | null,
	sizes__small__url?: ResolverInputTypes["User_Member_Avatar_sizes__small__url_operator"] | undefined | null,
	sizes__small__width?: ResolverInputTypes["User_Member_Avatar_sizes__small__width_operator"] | undefined | null,
	sizes__small__height?: ResolverInputTypes["User_Member_Avatar_sizes__small__height_operator"] | undefined | null,
	sizes__small__mimeType?: ResolverInputTypes["User_Member_Avatar_sizes__small__mimeType_operator"] | undefined | null,
	sizes__small__filesize?: ResolverInputTypes["User_Member_Avatar_sizes__small__filesize_operator"] | undefined | null,
	sizes__small__filename?: ResolverInputTypes["User_Member_Avatar_sizes__small__filename_operator"] | undefined | null,
	sizes__medium__url?: ResolverInputTypes["User_Member_Avatar_sizes__medium__url_operator"] | undefined | null,
	sizes__medium__width?: ResolverInputTypes["User_Member_Avatar_sizes__medium__width_operator"] | undefined | null,
	sizes__medium__height?: ResolverInputTypes["User_Member_Avatar_sizes__medium__height_operator"] | undefined | null,
	sizes__medium__mimeType?: ResolverInputTypes["User_Member_Avatar_sizes__medium__mimeType_operator"] | undefined | null,
	sizes__medium__filesize?: ResolverInputTypes["User_Member_Avatar_sizes__medium__filesize_operator"] | undefined | null,
	sizes__medium__filename?: ResolverInputTypes["User_Member_Avatar_sizes__medium__filename_operator"] | undefined | null,
	sizes__large__url?: ResolverInputTypes["User_Member_Avatar_sizes__large__url_operator"] | undefined | null,
	sizes__large__width?: ResolverInputTypes["User_Member_Avatar_sizes__large__width_operator"] | undefined | null,
	sizes__large__height?: ResolverInputTypes["User_Member_Avatar_sizes__large__height_operator"] | undefined | null,
	sizes__large__mimeType?: ResolverInputTypes["User_Member_Avatar_sizes__large__mimeType_operator"] | undefined | null,
	sizes__large__filesize?: ResolverInputTypes["User_Member_Avatar_sizes__large__filesize_operator"] | undefined | null,
	sizes__large__filename?: ResolverInputTypes["User_Member_Avatar_sizes__large__filename_operator"] | undefined | null,
	sizes__small_jpeg__url?: ResolverInputTypes["User_Member_Avatar_sizes__small_jpeg__url_operator"] | undefined | null,
	sizes__small_jpeg__width?: ResolverInputTypes["User_Member_Avatar_sizes__small_jpeg__width_operator"] | undefined | null,
	sizes__small_jpeg__height?: ResolverInputTypes["User_Member_Avatar_sizes__small_jpeg__height_operator"] | undefined | null,
	sizes__small_jpeg__mimeType?: ResolverInputTypes["User_Member_Avatar_sizes__small_jpeg__mimeType_operator"] | undefined | null,
	sizes__small_jpeg__filesize?: ResolverInputTypes["User_Member_Avatar_sizes__small_jpeg__filesize_operator"] | undefined | null,
	sizes__small_jpeg__filename?: ResolverInputTypes["User_Member_Avatar_sizes__small_jpeg__filename_operator"] | undefined | null,
	sizes__medium_jpeg__url?: ResolverInputTypes["User_Member_Avatar_sizes__medium_jpeg__url_operator"] | undefined | null,
	sizes__medium_jpeg__width?: ResolverInputTypes["User_Member_Avatar_sizes__medium_jpeg__width_operator"] | undefined | null,
	sizes__medium_jpeg__height?: ResolverInputTypes["User_Member_Avatar_sizes__medium_jpeg__height_operator"] | undefined | null,
	sizes__medium_jpeg__mimeType?: ResolverInputTypes["User_Member_Avatar_sizes__medium_jpeg__mimeType_operator"] | undefined | null,
	sizes__medium_jpeg__filesize?: ResolverInputTypes["User_Member_Avatar_sizes__medium_jpeg__filesize_operator"] | undefined | null,
	sizes__medium_jpeg__filename?: ResolverInputTypes["User_Member_Avatar_sizes__medium_jpeg__filename_operator"] | undefined | null,
	sizes__large_jpeg__url?: ResolverInputTypes["User_Member_Avatar_sizes__large_jpeg__url_operator"] | undefined | null,
	sizes__large_jpeg__width?: ResolverInputTypes["User_Member_Avatar_sizes__large_jpeg__width_operator"] | undefined | null,
	sizes__large_jpeg__height?: ResolverInputTypes["User_Member_Avatar_sizes__large_jpeg__height_operator"] | undefined | null,
	sizes__large_jpeg__mimeType?: ResolverInputTypes["User_Member_Avatar_sizes__large_jpeg__mimeType_operator"] | undefined | null,
	sizes__large_jpeg__filesize?: ResolverInputTypes["User_Member_Avatar_sizes__large_jpeg__filesize_operator"] | undefined | null,
	sizes__large_jpeg__filename?: ResolverInputTypes["User_Member_Avatar_sizes__large_jpeg__filename_operator"] | undefined | null,
	id?: ResolverInputTypes["User_Member_Avatar_id_operator"] | undefined | null,
	AND?: Array<ResolverInputTypes["User_Member_Avatar_where_and"] | undefined | null> | undefined | null,
	OR?: Array<ResolverInputTypes["User_Member_Avatar_where_or"] | undefined | null> | undefined | null
};
	["User_Member_Avatar_where_or"]: {
	alt?: ResolverInputTypes["User_Member_Avatar_alt_operator"] | undefined | null,
	updatedAt?: ResolverInputTypes["User_Member_Avatar_updatedAt_operator"] | undefined | null,
	createdAt?: ResolverInputTypes["User_Member_Avatar_createdAt_operator"] | undefined | null,
	url?: ResolverInputTypes["User_Member_Avatar_url_operator"] | undefined | null,
	filename?: ResolverInputTypes["User_Member_Avatar_filename_operator"] | undefined | null,
	mimeType?: ResolverInputTypes["User_Member_Avatar_mimeType_operator"] | undefined | null,
	filesize?: ResolverInputTypes["User_Member_Avatar_filesize_operator"] | undefined | null,
	width?: ResolverInputTypes["User_Member_Avatar_width_operator"] | undefined | null,
	height?: ResolverInputTypes["User_Member_Avatar_height_operator"] | undefined | null,
	focalX?: ResolverInputTypes["User_Member_Avatar_focalX_operator"] | undefined | null,
	focalY?: ResolverInputTypes["User_Member_Avatar_focalY_operator"] | undefined | null,
	sizes__small__url?: ResolverInputTypes["User_Member_Avatar_sizes__small__url_operator"] | undefined | null,
	sizes__small__width?: ResolverInputTypes["User_Member_Avatar_sizes__small__width_operator"] | undefined | null,
	sizes__small__height?: ResolverInputTypes["User_Member_Avatar_sizes__small__height_operator"] | undefined | null,
	sizes__small__mimeType?: ResolverInputTypes["User_Member_Avatar_sizes__small__mimeType_operator"] | undefined | null,
	sizes__small__filesize?: ResolverInputTypes["User_Member_Avatar_sizes__small__filesize_operator"] | undefined | null,
	sizes__small__filename?: ResolverInputTypes["User_Member_Avatar_sizes__small__filename_operator"] | undefined | null,
	sizes__medium__url?: ResolverInputTypes["User_Member_Avatar_sizes__medium__url_operator"] | undefined | null,
	sizes__medium__width?: ResolverInputTypes["User_Member_Avatar_sizes__medium__width_operator"] | undefined | null,
	sizes__medium__height?: ResolverInputTypes["User_Member_Avatar_sizes__medium__height_operator"] | undefined | null,
	sizes__medium__mimeType?: ResolverInputTypes["User_Member_Avatar_sizes__medium__mimeType_operator"] | undefined | null,
	sizes__medium__filesize?: ResolverInputTypes["User_Member_Avatar_sizes__medium__filesize_operator"] | undefined | null,
	sizes__medium__filename?: ResolverInputTypes["User_Member_Avatar_sizes__medium__filename_operator"] | undefined | null,
	sizes__large__url?: ResolverInputTypes["User_Member_Avatar_sizes__large__url_operator"] | undefined | null,
	sizes__large__width?: ResolverInputTypes["User_Member_Avatar_sizes__large__width_operator"] | undefined | null,
	sizes__large__height?: ResolverInputTypes["User_Member_Avatar_sizes__large__height_operator"] | undefined | null,
	sizes__large__mimeType?: ResolverInputTypes["User_Member_Avatar_sizes__large__mimeType_operator"] | undefined | null,
	sizes__large__filesize?: ResolverInputTypes["User_Member_Avatar_sizes__large__filesize_operator"] | undefined | null,
	sizes__large__filename?: ResolverInputTypes["User_Member_Avatar_sizes__large__filename_operator"] | undefined | null,
	sizes__small_jpeg__url?: ResolverInputTypes["User_Member_Avatar_sizes__small_jpeg__url_operator"] | undefined | null,
	sizes__small_jpeg__width?: ResolverInputTypes["User_Member_Avatar_sizes__small_jpeg__width_operator"] | undefined | null,
	sizes__small_jpeg__height?: ResolverInputTypes["User_Member_Avatar_sizes__small_jpeg__height_operator"] | undefined | null,
	sizes__small_jpeg__mimeType?: ResolverInputTypes["User_Member_Avatar_sizes__small_jpeg__mimeType_operator"] | undefined | null,
	sizes__small_jpeg__filesize?: ResolverInputTypes["User_Member_Avatar_sizes__small_jpeg__filesize_operator"] | undefined | null,
	sizes__small_jpeg__filename?: ResolverInputTypes["User_Member_Avatar_sizes__small_jpeg__filename_operator"] | undefined | null,
	sizes__medium_jpeg__url?: ResolverInputTypes["User_Member_Avatar_sizes__medium_jpeg__url_operator"] | undefined | null,
	sizes__medium_jpeg__width?: ResolverInputTypes["User_Member_Avatar_sizes__medium_jpeg__width_operator"] | undefined | null,
	sizes__medium_jpeg__height?: ResolverInputTypes["User_Member_Avatar_sizes__medium_jpeg__height_operator"] | undefined | null,
	sizes__medium_jpeg__mimeType?: ResolverInputTypes["User_Member_Avatar_sizes__medium_jpeg__mimeType_operator"] | undefined | null,
	sizes__medium_jpeg__filesize?: ResolverInputTypes["User_Member_Avatar_sizes__medium_jpeg__filesize_operator"] | undefined | null,
	sizes__medium_jpeg__filename?: ResolverInputTypes["User_Member_Avatar_sizes__medium_jpeg__filename_operator"] | undefined | null,
	sizes__large_jpeg__url?: ResolverInputTypes["User_Member_Avatar_sizes__large_jpeg__url_operator"] | undefined | null,
	sizes__large_jpeg__width?: ResolverInputTypes["User_Member_Avatar_sizes__large_jpeg__width_operator"] | undefined | null,
	sizes__large_jpeg__height?: ResolverInputTypes["User_Member_Avatar_sizes__large_jpeg__height_operator"] | undefined | null,
	sizes__large_jpeg__mimeType?: ResolverInputTypes["User_Member_Avatar_sizes__large_jpeg__mimeType_operator"] | undefined | null,
	sizes__large_jpeg__filesize?: ResolverInputTypes["User_Member_Avatar_sizes__large_jpeg__filesize_operator"] | undefined | null,
	sizes__large_jpeg__filename?: ResolverInputTypes["User_Member_Avatar_sizes__large_jpeg__filename_operator"] | undefined | null,
	id?: ResolverInputTypes["User_Member_Avatar_id_operator"] | undefined | null,
	AND?: Array<ResolverInputTypes["User_Member_Avatar_where_and"] | undefined | null> | undefined | null,
	OR?: Array<ResolverInputTypes["User_Member_Avatar_where_or"] | undefined | null> | undefined | null
};
	["User_Member_gender"]:User_Member_gender;
	["EventCategory"]: AliasType<{
	id?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	value?:boolean | `@${string}`,
tags?: [{	locale?: ResolverInputTypes["LocaleInputType"] | undefined | null,	fallbackLocale?: ResolverInputTypes["FallbackLocaleInputType"] | undefined | null},ResolverInputTypes["Tag"]],
icon?: [{	locale?: ResolverInputTypes["LocaleInputType"] | undefined | null,	fallbackLocale?: ResolverInputTypes["FallbackLocaleInputType"] | undefined | null,	where?: ResolverInputTypes["EventCategory_Icon_where"] | undefined | null},ResolverInputTypes["Media"]],
	region?:boolean | `@${string}`,
	updatedAt?:boolean | `@${string}`,
	createdAt?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["Tag"]: AliasType<{
	id?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	value?:boolean | `@${string}`,
	region?:boolean | `@${string}`,
	count?:boolean | `@${string}`,
	skipCountCheck?:boolean | `@${string}`,
	removeNextCron?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["Tag_region"]:Tag_region;
	["EventCategory_Icon_where"]: {
	alt?: ResolverInputTypes["EventCategory_Icon_alt_operator"] | undefined | null,
	updatedAt?: ResolverInputTypes["EventCategory_Icon_updatedAt_operator"] | undefined | null,
	createdAt?: ResolverInputTypes["EventCategory_Icon_createdAt_operator"] | undefined | null,
	url?: ResolverInputTypes["EventCategory_Icon_url_operator"] | undefined | null,
	filename?: ResolverInputTypes["EventCategory_Icon_filename_operator"] | undefined | null,
	mimeType?: ResolverInputTypes["EventCategory_Icon_mimeType_operator"] | undefined | null,
	filesize?: ResolverInputTypes["EventCategory_Icon_filesize_operator"] | undefined | null,
	width?: ResolverInputTypes["EventCategory_Icon_width_operator"] | undefined | null,
	height?: ResolverInputTypes["EventCategory_Icon_height_operator"] | undefined | null,
	focalX?: ResolverInputTypes["EventCategory_Icon_focalX_operator"] | undefined | null,
	focalY?: ResolverInputTypes["EventCategory_Icon_focalY_operator"] | undefined | null,
	sizes__small__url?: ResolverInputTypes["EventCategory_Icon_sizes__small__url_operator"] | undefined | null,
	sizes__small__width?: ResolverInputTypes["EventCategory_Icon_sizes__small__width_operator"] | undefined | null,
	sizes__small__height?: ResolverInputTypes["EventCategory_Icon_sizes__small__height_operator"] | undefined | null,
	sizes__small__mimeType?: ResolverInputTypes["EventCategory_Icon_sizes__small__mimeType_operator"] | undefined | null,
	sizes__small__filesize?: ResolverInputTypes["EventCategory_Icon_sizes__small__filesize_operator"] | undefined | null,
	sizes__small__filename?: ResolverInputTypes["EventCategory_Icon_sizes__small__filename_operator"] | undefined | null,
	sizes__medium__url?: ResolverInputTypes["EventCategory_Icon_sizes__medium__url_operator"] | undefined | null,
	sizes__medium__width?: ResolverInputTypes["EventCategory_Icon_sizes__medium__width_operator"] | undefined | null,
	sizes__medium__height?: ResolverInputTypes["EventCategory_Icon_sizes__medium__height_operator"] | undefined | null,
	sizes__medium__mimeType?: ResolverInputTypes["EventCategory_Icon_sizes__medium__mimeType_operator"] | undefined | null,
	sizes__medium__filesize?: ResolverInputTypes["EventCategory_Icon_sizes__medium__filesize_operator"] | undefined | null,
	sizes__medium__filename?: ResolverInputTypes["EventCategory_Icon_sizes__medium__filename_operator"] | undefined | null,
	sizes__large__url?: ResolverInputTypes["EventCategory_Icon_sizes__large__url_operator"] | undefined | null,
	sizes__large__width?: ResolverInputTypes["EventCategory_Icon_sizes__large__width_operator"] | undefined | null,
	sizes__large__height?: ResolverInputTypes["EventCategory_Icon_sizes__large__height_operator"] | undefined | null,
	sizes__large__mimeType?: ResolverInputTypes["EventCategory_Icon_sizes__large__mimeType_operator"] | undefined | null,
	sizes__large__filesize?: ResolverInputTypes["EventCategory_Icon_sizes__large__filesize_operator"] | undefined | null,
	sizes__large__filename?: ResolverInputTypes["EventCategory_Icon_sizes__large__filename_operator"] | undefined | null,
	sizes__small_jpeg__url?: ResolverInputTypes["EventCategory_Icon_sizes__small_jpeg__url_operator"] | undefined | null,
	sizes__small_jpeg__width?: ResolverInputTypes["EventCategory_Icon_sizes__small_jpeg__width_operator"] | undefined | null,
	sizes__small_jpeg__height?: ResolverInputTypes["EventCategory_Icon_sizes__small_jpeg__height_operator"] | undefined | null,
	sizes__small_jpeg__mimeType?: ResolverInputTypes["EventCategory_Icon_sizes__small_jpeg__mimeType_operator"] | undefined | null,
	sizes__small_jpeg__filesize?: ResolverInputTypes["EventCategory_Icon_sizes__small_jpeg__filesize_operator"] | undefined | null,
	sizes__small_jpeg__filename?: ResolverInputTypes["EventCategory_Icon_sizes__small_jpeg__filename_operator"] | undefined | null,
	sizes__medium_jpeg__url?: ResolverInputTypes["EventCategory_Icon_sizes__medium_jpeg__url_operator"] | undefined | null,
	sizes__medium_jpeg__width?: ResolverInputTypes["EventCategory_Icon_sizes__medium_jpeg__width_operator"] | undefined | null,
	sizes__medium_jpeg__height?: ResolverInputTypes["EventCategory_Icon_sizes__medium_jpeg__height_operator"] | undefined | null,
	sizes__medium_jpeg__mimeType?: ResolverInputTypes["EventCategory_Icon_sizes__medium_jpeg__mimeType_operator"] | undefined | null,
	sizes__medium_jpeg__filesize?: ResolverInputTypes["EventCategory_Icon_sizes__medium_jpeg__filesize_operator"] | undefined | null,
	sizes__medium_jpeg__filename?: ResolverInputTypes["EventCategory_Icon_sizes__medium_jpeg__filename_operator"] | undefined | null,
	sizes__large_jpeg__url?: ResolverInputTypes["EventCategory_Icon_sizes__large_jpeg__url_operator"] | undefined | null,
	sizes__large_jpeg__width?: ResolverInputTypes["EventCategory_Icon_sizes__large_jpeg__width_operator"] | undefined | null,
	sizes__large_jpeg__height?: ResolverInputTypes["EventCategory_Icon_sizes__large_jpeg__height_operator"] | undefined | null,
	sizes__large_jpeg__mimeType?: ResolverInputTypes["EventCategory_Icon_sizes__large_jpeg__mimeType_operator"] | undefined | null,
	sizes__large_jpeg__filesize?: ResolverInputTypes["EventCategory_Icon_sizes__large_jpeg__filesize_operator"] | undefined | null,
	sizes__large_jpeg__filename?: ResolverInputTypes["EventCategory_Icon_sizes__large_jpeg__filename_operator"] | undefined | null,
	id?: ResolverInputTypes["EventCategory_Icon_id_operator"] | undefined | null,
	AND?: Array<ResolverInputTypes["EventCategory_Icon_where_and"] | undefined | null> | undefined | null,
	OR?: Array<ResolverInputTypes["EventCategory_Icon_where_or"] | undefined | null> | undefined | null
};
	["EventCategory_Icon_alt_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["EventCategory_Icon_updatedAt_operator"]: {
	equals?: ResolverInputTypes["DateTime"] | undefined | null,
	not_equals?: ResolverInputTypes["DateTime"] | undefined | null,
	greater_than_equal?: ResolverInputTypes["DateTime"] | undefined | null,
	greater_than?: ResolverInputTypes["DateTime"] | undefined | null,
	less_than_equal?: ResolverInputTypes["DateTime"] | undefined | null,
	less_than?: ResolverInputTypes["DateTime"] | undefined | null,
	like?: ResolverInputTypes["DateTime"] | undefined | null,
	exists?: boolean | undefined | null
};
	["EventCategory_Icon_createdAt_operator"]: {
	equals?: ResolverInputTypes["DateTime"] | undefined | null,
	not_equals?: ResolverInputTypes["DateTime"] | undefined | null,
	greater_than_equal?: ResolverInputTypes["DateTime"] | undefined | null,
	greater_than?: ResolverInputTypes["DateTime"] | undefined | null,
	less_than_equal?: ResolverInputTypes["DateTime"] | undefined | null,
	less_than?: ResolverInputTypes["DateTime"] | undefined | null,
	like?: ResolverInputTypes["DateTime"] | undefined | null,
	exists?: boolean | undefined | null
};
	["EventCategory_Icon_url_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["EventCategory_Icon_filename_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["EventCategory_Icon_mimeType_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["EventCategory_Icon_filesize_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["EventCategory_Icon_width_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["EventCategory_Icon_height_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["EventCategory_Icon_focalX_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["EventCategory_Icon_focalY_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["EventCategory_Icon_sizes__small__url_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["EventCategory_Icon_sizes__small__width_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["EventCategory_Icon_sizes__small__height_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["EventCategory_Icon_sizes__small__mimeType_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["EventCategory_Icon_sizes__small__filesize_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["EventCategory_Icon_sizes__small__filename_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["EventCategory_Icon_sizes__medium__url_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["EventCategory_Icon_sizes__medium__width_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["EventCategory_Icon_sizes__medium__height_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["EventCategory_Icon_sizes__medium__mimeType_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["EventCategory_Icon_sizes__medium__filesize_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["EventCategory_Icon_sizes__medium__filename_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["EventCategory_Icon_sizes__large__url_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["EventCategory_Icon_sizes__large__width_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["EventCategory_Icon_sizes__large__height_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["EventCategory_Icon_sizes__large__mimeType_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["EventCategory_Icon_sizes__large__filesize_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["EventCategory_Icon_sizes__large__filename_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["EventCategory_Icon_sizes__small_jpeg__url_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["EventCategory_Icon_sizes__small_jpeg__width_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["EventCategory_Icon_sizes__small_jpeg__height_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["EventCategory_Icon_sizes__small_jpeg__mimeType_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["EventCategory_Icon_sizes__small_jpeg__filesize_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["EventCategory_Icon_sizes__small_jpeg__filename_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["EventCategory_Icon_sizes__medium_jpeg__url_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["EventCategory_Icon_sizes__medium_jpeg__width_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["EventCategory_Icon_sizes__medium_jpeg__height_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["EventCategory_Icon_sizes__medium_jpeg__mimeType_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["EventCategory_Icon_sizes__medium_jpeg__filesize_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["EventCategory_Icon_sizes__medium_jpeg__filename_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["EventCategory_Icon_sizes__large_jpeg__url_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["EventCategory_Icon_sizes__large_jpeg__width_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["EventCategory_Icon_sizes__large_jpeg__height_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["EventCategory_Icon_sizes__large_jpeg__mimeType_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["EventCategory_Icon_sizes__large_jpeg__filesize_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["EventCategory_Icon_sizes__large_jpeg__filename_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["EventCategory_Icon_id_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["EventCategory_Icon_where_and"]: {
	alt?: ResolverInputTypes["EventCategory_Icon_alt_operator"] | undefined | null,
	updatedAt?: ResolverInputTypes["EventCategory_Icon_updatedAt_operator"] | undefined | null,
	createdAt?: ResolverInputTypes["EventCategory_Icon_createdAt_operator"] | undefined | null,
	url?: ResolverInputTypes["EventCategory_Icon_url_operator"] | undefined | null,
	filename?: ResolverInputTypes["EventCategory_Icon_filename_operator"] | undefined | null,
	mimeType?: ResolverInputTypes["EventCategory_Icon_mimeType_operator"] | undefined | null,
	filesize?: ResolverInputTypes["EventCategory_Icon_filesize_operator"] | undefined | null,
	width?: ResolverInputTypes["EventCategory_Icon_width_operator"] | undefined | null,
	height?: ResolverInputTypes["EventCategory_Icon_height_operator"] | undefined | null,
	focalX?: ResolverInputTypes["EventCategory_Icon_focalX_operator"] | undefined | null,
	focalY?: ResolverInputTypes["EventCategory_Icon_focalY_operator"] | undefined | null,
	sizes__small__url?: ResolverInputTypes["EventCategory_Icon_sizes__small__url_operator"] | undefined | null,
	sizes__small__width?: ResolverInputTypes["EventCategory_Icon_sizes__small__width_operator"] | undefined | null,
	sizes__small__height?: ResolverInputTypes["EventCategory_Icon_sizes__small__height_operator"] | undefined | null,
	sizes__small__mimeType?: ResolverInputTypes["EventCategory_Icon_sizes__small__mimeType_operator"] | undefined | null,
	sizes__small__filesize?: ResolverInputTypes["EventCategory_Icon_sizes__small__filesize_operator"] | undefined | null,
	sizes__small__filename?: ResolverInputTypes["EventCategory_Icon_sizes__small__filename_operator"] | undefined | null,
	sizes__medium__url?: ResolverInputTypes["EventCategory_Icon_sizes__medium__url_operator"] | undefined | null,
	sizes__medium__width?: ResolverInputTypes["EventCategory_Icon_sizes__medium__width_operator"] | undefined | null,
	sizes__medium__height?: ResolverInputTypes["EventCategory_Icon_sizes__medium__height_operator"] | undefined | null,
	sizes__medium__mimeType?: ResolverInputTypes["EventCategory_Icon_sizes__medium__mimeType_operator"] | undefined | null,
	sizes__medium__filesize?: ResolverInputTypes["EventCategory_Icon_sizes__medium__filesize_operator"] | undefined | null,
	sizes__medium__filename?: ResolverInputTypes["EventCategory_Icon_sizes__medium__filename_operator"] | undefined | null,
	sizes__large__url?: ResolverInputTypes["EventCategory_Icon_sizes__large__url_operator"] | undefined | null,
	sizes__large__width?: ResolverInputTypes["EventCategory_Icon_sizes__large__width_operator"] | undefined | null,
	sizes__large__height?: ResolverInputTypes["EventCategory_Icon_sizes__large__height_operator"] | undefined | null,
	sizes__large__mimeType?: ResolverInputTypes["EventCategory_Icon_sizes__large__mimeType_operator"] | undefined | null,
	sizes__large__filesize?: ResolverInputTypes["EventCategory_Icon_sizes__large__filesize_operator"] | undefined | null,
	sizes__large__filename?: ResolverInputTypes["EventCategory_Icon_sizes__large__filename_operator"] | undefined | null,
	sizes__small_jpeg__url?: ResolverInputTypes["EventCategory_Icon_sizes__small_jpeg__url_operator"] | undefined | null,
	sizes__small_jpeg__width?: ResolverInputTypes["EventCategory_Icon_sizes__small_jpeg__width_operator"] | undefined | null,
	sizes__small_jpeg__height?: ResolverInputTypes["EventCategory_Icon_sizes__small_jpeg__height_operator"] | undefined | null,
	sizes__small_jpeg__mimeType?: ResolverInputTypes["EventCategory_Icon_sizes__small_jpeg__mimeType_operator"] | undefined | null,
	sizes__small_jpeg__filesize?: ResolverInputTypes["EventCategory_Icon_sizes__small_jpeg__filesize_operator"] | undefined | null,
	sizes__small_jpeg__filename?: ResolverInputTypes["EventCategory_Icon_sizes__small_jpeg__filename_operator"] | undefined | null,
	sizes__medium_jpeg__url?: ResolverInputTypes["EventCategory_Icon_sizes__medium_jpeg__url_operator"] | undefined | null,
	sizes__medium_jpeg__width?: ResolverInputTypes["EventCategory_Icon_sizes__medium_jpeg__width_operator"] | undefined | null,
	sizes__medium_jpeg__height?: ResolverInputTypes["EventCategory_Icon_sizes__medium_jpeg__height_operator"] | undefined | null,
	sizes__medium_jpeg__mimeType?: ResolverInputTypes["EventCategory_Icon_sizes__medium_jpeg__mimeType_operator"] | undefined | null,
	sizes__medium_jpeg__filesize?: ResolverInputTypes["EventCategory_Icon_sizes__medium_jpeg__filesize_operator"] | undefined | null,
	sizes__medium_jpeg__filename?: ResolverInputTypes["EventCategory_Icon_sizes__medium_jpeg__filename_operator"] | undefined | null,
	sizes__large_jpeg__url?: ResolverInputTypes["EventCategory_Icon_sizes__large_jpeg__url_operator"] | undefined | null,
	sizes__large_jpeg__width?: ResolverInputTypes["EventCategory_Icon_sizes__large_jpeg__width_operator"] | undefined | null,
	sizes__large_jpeg__height?: ResolverInputTypes["EventCategory_Icon_sizes__large_jpeg__height_operator"] | undefined | null,
	sizes__large_jpeg__mimeType?: ResolverInputTypes["EventCategory_Icon_sizes__large_jpeg__mimeType_operator"] | undefined | null,
	sizes__large_jpeg__filesize?: ResolverInputTypes["EventCategory_Icon_sizes__large_jpeg__filesize_operator"] | undefined | null,
	sizes__large_jpeg__filename?: ResolverInputTypes["EventCategory_Icon_sizes__large_jpeg__filename_operator"] | undefined | null,
	id?: ResolverInputTypes["EventCategory_Icon_id_operator"] | undefined | null,
	AND?: Array<ResolverInputTypes["EventCategory_Icon_where_and"] | undefined | null> | undefined | null,
	OR?: Array<ResolverInputTypes["EventCategory_Icon_where_or"] | undefined | null> | undefined | null
};
	["EventCategory_Icon_where_or"]: {
	alt?: ResolverInputTypes["EventCategory_Icon_alt_operator"] | undefined | null,
	updatedAt?: ResolverInputTypes["EventCategory_Icon_updatedAt_operator"] | undefined | null,
	createdAt?: ResolverInputTypes["EventCategory_Icon_createdAt_operator"] | undefined | null,
	url?: ResolverInputTypes["EventCategory_Icon_url_operator"] | undefined | null,
	filename?: ResolverInputTypes["EventCategory_Icon_filename_operator"] | undefined | null,
	mimeType?: ResolverInputTypes["EventCategory_Icon_mimeType_operator"] | undefined | null,
	filesize?: ResolverInputTypes["EventCategory_Icon_filesize_operator"] | undefined | null,
	width?: ResolverInputTypes["EventCategory_Icon_width_operator"] | undefined | null,
	height?: ResolverInputTypes["EventCategory_Icon_height_operator"] | undefined | null,
	focalX?: ResolverInputTypes["EventCategory_Icon_focalX_operator"] | undefined | null,
	focalY?: ResolverInputTypes["EventCategory_Icon_focalY_operator"] | undefined | null,
	sizes__small__url?: ResolverInputTypes["EventCategory_Icon_sizes__small__url_operator"] | undefined | null,
	sizes__small__width?: ResolverInputTypes["EventCategory_Icon_sizes__small__width_operator"] | undefined | null,
	sizes__small__height?: ResolverInputTypes["EventCategory_Icon_sizes__small__height_operator"] | undefined | null,
	sizes__small__mimeType?: ResolverInputTypes["EventCategory_Icon_sizes__small__mimeType_operator"] | undefined | null,
	sizes__small__filesize?: ResolverInputTypes["EventCategory_Icon_sizes__small__filesize_operator"] | undefined | null,
	sizes__small__filename?: ResolverInputTypes["EventCategory_Icon_sizes__small__filename_operator"] | undefined | null,
	sizes__medium__url?: ResolverInputTypes["EventCategory_Icon_sizes__medium__url_operator"] | undefined | null,
	sizes__medium__width?: ResolverInputTypes["EventCategory_Icon_sizes__medium__width_operator"] | undefined | null,
	sizes__medium__height?: ResolverInputTypes["EventCategory_Icon_sizes__medium__height_operator"] | undefined | null,
	sizes__medium__mimeType?: ResolverInputTypes["EventCategory_Icon_sizes__medium__mimeType_operator"] | undefined | null,
	sizes__medium__filesize?: ResolverInputTypes["EventCategory_Icon_sizes__medium__filesize_operator"] | undefined | null,
	sizes__medium__filename?: ResolverInputTypes["EventCategory_Icon_sizes__medium__filename_operator"] | undefined | null,
	sizes__large__url?: ResolverInputTypes["EventCategory_Icon_sizes__large__url_operator"] | undefined | null,
	sizes__large__width?: ResolverInputTypes["EventCategory_Icon_sizes__large__width_operator"] | undefined | null,
	sizes__large__height?: ResolverInputTypes["EventCategory_Icon_sizes__large__height_operator"] | undefined | null,
	sizes__large__mimeType?: ResolverInputTypes["EventCategory_Icon_sizes__large__mimeType_operator"] | undefined | null,
	sizes__large__filesize?: ResolverInputTypes["EventCategory_Icon_sizes__large__filesize_operator"] | undefined | null,
	sizes__large__filename?: ResolverInputTypes["EventCategory_Icon_sizes__large__filename_operator"] | undefined | null,
	sizes__small_jpeg__url?: ResolverInputTypes["EventCategory_Icon_sizes__small_jpeg__url_operator"] | undefined | null,
	sizes__small_jpeg__width?: ResolverInputTypes["EventCategory_Icon_sizes__small_jpeg__width_operator"] | undefined | null,
	sizes__small_jpeg__height?: ResolverInputTypes["EventCategory_Icon_sizes__small_jpeg__height_operator"] | undefined | null,
	sizes__small_jpeg__mimeType?: ResolverInputTypes["EventCategory_Icon_sizes__small_jpeg__mimeType_operator"] | undefined | null,
	sizes__small_jpeg__filesize?: ResolverInputTypes["EventCategory_Icon_sizes__small_jpeg__filesize_operator"] | undefined | null,
	sizes__small_jpeg__filename?: ResolverInputTypes["EventCategory_Icon_sizes__small_jpeg__filename_operator"] | undefined | null,
	sizes__medium_jpeg__url?: ResolverInputTypes["EventCategory_Icon_sizes__medium_jpeg__url_operator"] | undefined | null,
	sizes__medium_jpeg__width?: ResolverInputTypes["EventCategory_Icon_sizes__medium_jpeg__width_operator"] | undefined | null,
	sizes__medium_jpeg__height?: ResolverInputTypes["EventCategory_Icon_sizes__medium_jpeg__height_operator"] | undefined | null,
	sizes__medium_jpeg__mimeType?: ResolverInputTypes["EventCategory_Icon_sizes__medium_jpeg__mimeType_operator"] | undefined | null,
	sizes__medium_jpeg__filesize?: ResolverInputTypes["EventCategory_Icon_sizes__medium_jpeg__filesize_operator"] | undefined | null,
	sizes__medium_jpeg__filename?: ResolverInputTypes["EventCategory_Icon_sizes__medium_jpeg__filename_operator"] | undefined | null,
	sizes__large_jpeg__url?: ResolverInputTypes["EventCategory_Icon_sizes__large_jpeg__url_operator"] | undefined | null,
	sizes__large_jpeg__width?: ResolverInputTypes["EventCategory_Icon_sizes__large_jpeg__width_operator"] | undefined | null,
	sizes__large_jpeg__height?: ResolverInputTypes["EventCategory_Icon_sizes__large_jpeg__height_operator"] | undefined | null,
	sizes__large_jpeg__mimeType?: ResolverInputTypes["EventCategory_Icon_sizes__large_jpeg__mimeType_operator"] | undefined | null,
	sizes__large_jpeg__filesize?: ResolverInputTypes["EventCategory_Icon_sizes__large_jpeg__filesize_operator"] | undefined | null,
	sizes__large_jpeg__filename?: ResolverInputTypes["EventCategory_Icon_sizes__large_jpeg__filename_operator"] | undefined | null,
	id?: ResolverInputTypes["EventCategory_Icon_id_operator"] | undefined | null,
	AND?: Array<ResolverInputTypes["EventCategory_Icon_where_and"] | undefined | null> | undefined | null,
	OR?: Array<ResolverInputTypes["EventCategory_Icon_where_or"] | undefined | null> | undefined | null
};
	["EventCategory_region"]:EventCategory_region;
	["User_Organiser"]: AliasType<{
logo?: [{	locale?: ResolverInputTypes["LocaleInputType"] | undefined | null,	fallbackLocale?: ResolverInputTypes["FallbackLocaleInputType"] | undefined | null,	where?: ResolverInputTypes["User_Organiser_Logo_where"] | undefined | null},ResolverInputTypes["Media"]],
banner?: [{	locale?: ResolverInputTypes["LocaleInputType"] | undefined | null,	fallbackLocale?: ResolverInputTypes["FallbackLocaleInputType"] | undefined | null,	where?: ResolverInputTypes["User_Organiser_Banner_where"] | undefined | null},ResolverInputTypes["Media"]],
	description?:boolean | `@${string}`,
	foundedIn?:boolean | `@${string}`,
	address?:boolean | `@${string}`,
	website?:boolean | `@${string}`,
	facebook?:boolean | `@${string}`,
	instagram?:boolean | `@${string}`,
	language?:boolean | `@${string}`,
	phone?:boolean | `@${string}`,
	followers?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["User_Organiser_Logo_where"]: {
	alt?: ResolverInputTypes["User_Organiser_Logo_alt_operator"] | undefined | null,
	updatedAt?: ResolverInputTypes["User_Organiser_Logo_updatedAt_operator"] | undefined | null,
	createdAt?: ResolverInputTypes["User_Organiser_Logo_createdAt_operator"] | undefined | null,
	url?: ResolverInputTypes["User_Organiser_Logo_url_operator"] | undefined | null,
	filename?: ResolverInputTypes["User_Organiser_Logo_filename_operator"] | undefined | null,
	mimeType?: ResolverInputTypes["User_Organiser_Logo_mimeType_operator"] | undefined | null,
	filesize?: ResolverInputTypes["User_Organiser_Logo_filesize_operator"] | undefined | null,
	width?: ResolverInputTypes["User_Organiser_Logo_width_operator"] | undefined | null,
	height?: ResolverInputTypes["User_Organiser_Logo_height_operator"] | undefined | null,
	focalX?: ResolverInputTypes["User_Organiser_Logo_focalX_operator"] | undefined | null,
	focalY?: ResolverInputTypes["User_Organiser_Logo_focalY_operator"] | undefined | null,
	sizes__small__url?: ResolverInputTypes["User_Organiser_Logo_sizes__small__url_operator"] | undefined | null,
	sizes__small__width?: ResolverInputTypes["User_Organiser_Logo_sizes__small__width_operator"] | undefined | null,
	sizes__small__height?: ResolverInputTypes["User_Organiser_Logo_sizes__small__height_operator"] | undefined | null,
	sizes__small__mimeType?: ResolverInputTypes["User_Organiser_Logo_sizes__small__mimeType_operator"] | undefined | null,
	sizes__small__filesize?: ResolverInputTypes["User_Organiser_Logo_sizes__small__filesize_operator"] | undefined | null,
	sizes__small__filename?: ResolverInputTypes["User_Organiser_Logo_sizes__small__filename_operator"] | undefined | null,
	sizes__medium__url?: ResolverInputTypes["User_Organiser_Logo_sizes__medium__url_operator"] | undefined | null,
	sizes__medium__width?: ResolverInputTypes["User_Organiser_Logo_sizes__medium__width_operator"] | undefined | null,
	sizes__medium__height?: ResolverInputTypes["User_Organiser_Logo_sizes__medium__height_operator"] | undefined | null,
	sizes__medium__mimeType?: ResolverInputTypes["User_Organiser_Logo_sizes__medium__mimeType_operator"] | undefined | null,
	sizes__medium__filesize?: ResolverInputTypes["User_Organiser_Logo_sizes__medium__filesize_operator"] | undefined | null,
	sizes__medium__filename?: ResolverInputTypes["User_Organiser_Logo_sizes__medium__filename_operator"] | undefined | null,
	sizes__large__url?: ResolverInputTypes["User_Organiser_Logo_sizes__large__url_operator"] | undefined | null,
	sizes__large__width?: ResolverInputTypes["User_Organiser_Logo_sizes__large__width_operator"] | undefined | null,
	sizes__large__height?: ResolverInputTypes["User_Organiser_Logo_sizes__large__height_operator"] | undefined | null,
	sizes__large__mimeType?: ResolverInputTypes["User_Organiser_Logo_sizes__large__mimeType_operator"] | undefined | null,
	sizes__large__filesize?: ResolverInputTypes["User_Organiser_Logo_sizes__large__filesize_operator"] | undefined | null,
	sizes__large__filename?: ResolverInputTypes["User_Organiser_Logo_sizes__large__filename_operator"] | undefined | null,
	sizes__small_jpeg__url?: ResolverInputTypes["User_Organiser_Logo_sizes__small_jpeg__url_operator"] | undefined | null,
	sizes__small_jpeg__width?: ResolverInputTypes["User_Organiser_Logo_sizes__small_jpeg__width_operator"] | undefined | null,
	sizes__small_jpeg__height?: ResolverInputTypes["User_Organiser_Logo_sizes__small_jpeg__height_operator"] | undefined | null,
	sizes__small_jpeg__mimeType?: ResolverInputTypes["User_Organiser_Logo_sizes__small_jpeg__mimeType_operator"] | undefined | null,
	sizes__small_jpeg__filesize?: ResolverInputTypes["User_Organiser_Logo_sizes__small_jpeg__filesize_operator"] | undefined | null,
	sizes__small_jpeg__filename?: ResolverInputTypes["User_Organiser_Logo_sizes__small_jpeg__filename_operator"] | undefined | null,
	sizes__medium_jpeg__url?: ResolverInputTypes["User_Organiser_Logo_sizes__medium_jpeg__url_operator"] | undefined | null,
	sizes__medium_jpeg__width?: ResolverInputTypes["User_Organiser_Logo_sizes__medium_jpeg__width_operator"] | undefined | null,
	sizes__medium_jpeg__height?: ResolverInputTypes["User_Organiser_Logo_sizes__medium_jpeg__height_operator"] | undefined | null,
	sizes__medium_jpeg__mimeType?: ResolverInputTypes["User_Organiser_Logo_sizes__medium_jpeg__mimeType_operator"] | undefined | null,
	sizes__medium_jpeg__filesize?: ResolverInputTypes["User_Organiser_Logo_sizes__medium_jpeg__filesize_operator"] | undefined | null,
	sizes__medium_jpeg__filename?: ResolverInputTypes["User_Organiser_Logo_sizes__medium_jpeg__filename_operator"] | undefined | null,
	sizes__large_jpeg__url?: ResolverInputTypes["User_Organiser_Logo_sizes__large_jpeg__url_operator"] | undefined | null,
	sizes__large_jpeg__width?: ResolverInputTypes["User_Organiser_Logo_sizes__large_jpeg__width_operator"] | undefined | null,
	sizes__large_jpeg__height?: ResolverInputTypes["User_Organiser_Logo_sizes__large_jpeg__height_operator"] | undefined | null,
	sizes__large_jpeg__mimeType?: ResolverInputTypes["User_Organiser_Logo_sizes__large_jpeg__mimeType_operator"] | undefined | null,
	sizes__large_jpeg__filesize?: ResolverInputTypes["User_Organiser_Logo_sizes__large_jpeg__filesize_operator"] | undefined | null,
	sizes__large_jpeg__filename?: ResolverInputTypes["User_Organiser_Logo_sizes__large_jpeg__filename_operator"] | undefined | null,
	id?: ResolverInputTypes["User_Organiser_Logo_id_operator"] | undefined | null,
	AND?: Array<ResolverInputTypes["User_Organiser_Logo_where_and"] | undefined | null> | undefined | null,
	OR?: Array<ResolverInputTypes["User_Organiser_Logo_where_or"] | undefined | null> | undefined | null
};
	["User_Organiser_Logo_alt_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["User_Organiser_Logo_updatedAt_operator"]: {
	equals?: ResolverInputTypes["DateTime"] | undefined | null,
	not_equals?: ResolverInputTypes["DateTime"] | undefined | null,
	greater_than_equal?: ResolverInputTypes["DateTime"] | undefined | null,
	greater_than?: ResolverInputTypes["DateTime"] | undefined | null,
	less_than_equal?: ResolverInputTypes["DateTime"] | undefined | null,
	less_than?: ResolverInputTypes["DateTime"] | undefined | null,
	like?: ResolverInputTypes["DateTime"] | undefined | null,
	exists?: boolean | undefined | null
};
	["User_Organiser_Logo_createdAt_operator"]: {
	equals?: ResolverInputTypes["DateTime"] | undefined | null,
	not_equals?: ResolverInputTypes["DateTime"] | undefined | null,
	greater_than_equal?: ResolverInputTypes["DateTime"] | undefined | null,
	greater_than?: ResolverInputTypes["DateTime"] | undefined | null,
	less_than_equal?: ResolverInputTypes["DateTime"] | undefined | null,
	less_than?: ResolverInputTypes["DateTime"] | undefined | null,
	like?: ResolverInputTypes["DateTime"] | undefined | null,
	exists?: boolean | undefined | null
};
	["User_Organiser_Logo_url_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["User_Organiser_Logo_filename_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["User_Organiser_Logo_mimeType_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["User_Organiser_Logo_filesize_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["User_Organiser_Logo_width_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["User_Organiser_Logo_height_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["User_Organiser_Logo_focalX_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["User_Organiser_Logo_focalY_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["User_Organiser_Logo_sizes__small__url_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["User_Organiser_Logo_sizes__small__width_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["User_Organiser_Logo_sizes__small__height_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["User_Organiser_Logo_sizes__small__mimeType_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["User_Organiser_Logo_sizes__small__filesize_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["User_Organiser_Logo_sizes__small__filename_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["User_Organiser_Logo_sizes__medium__url_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["User_Organiser_Logo_sizes__medium__width_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["User_Organiser_Logo_sizes__medium__height_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["User_Organiser_Logo_sizes__medium__mimeType_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["User_Organiser_Logo_sizes__medium__filesize_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["User_Organiser_Logo_sizes__medium__filename_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["User_Organiser_Logo_sizes__large__url_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["User_Organiser_Logo_sizes__large__width_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["User_Organiser_Logo_sizes__large__height_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["User_Organiser_Logo_sizes__large__mimeType_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["User_Organiser_Logo_sizes__large__filesize_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["User_Organiser_Logo_sizes__large__filename_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["User_Organiser_Logo_sizes__small_jpeg__url_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["User_Organiser_Logo_sizes__small_jpeg__width_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["User_Organiser_Logo_sizes__small_jpeg__height_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["User_Organiser_Logo_sizes__small_jpeg__mimeType_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["User_Organiser_Logo_sizes__small_jpeg__filesize_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["User_Organiser_Logo_sizes__small_jpeg__filename_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["User_Organiser_Logo_sizes__medium_jpeg__url_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["User_Organiser_Logo_sizes__medium_jpeg__width_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["User_Organiser_Logo_sizes__medium_jpeg__height_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["User_Organiser_Logo_sizes__medium_jpeg__mimeType_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["User_Organiser_Logo_sizes__medium_jpeg__filesize_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["User_Organiser_Logo_sizes__medium_jpeg__filename_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["User_Organiser_Logo_sizes__large_jpeg__url_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["User_Organiser_Logo_sizes__large_jpeg__width_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["User_Organiser_Logo_sizes__large_jpeg__height_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["User_Organiser_Logo_sizes__large_jpeg__mimeType_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["User_Organiser_Logo_sizes__large_jpeg__filesize_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["User_Organiser_Logo_sizes__large_jpeg__filename_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["User_Organiser_Logo_id_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["User_Organiser_Logo_where_and"]: {
	alt?: ResolverInputTypes["User_Organiser_Logo_alt_operator"] | undefined | null,
	updatedAt?: ResolverInputTypes["User_Organiser_Logo_updatedAt_operator"] | undefined | null,
	createdAt?: ResolverInputTypes["User_Organiser_Logo_createdAt_operator"] | undefined | null,
	url?: ResolverInputTypes["User_Organiser_Logo_url_operator"] | undefined | null,
	filename?: ResolverInputTypes["User_Organiser_Logo_filename_operator"] | undefined | null,
	mimeType?: ResolverInputTypes["User_Organiser_Logo_mimeType_operator"] | undefined | null,
	filesize?: ResolverInputTypes["User_Organiser_Logo_filesize_operator"] | undefined | null,
	width?: ResolverInputTypes["User_Organiser_Logo_width_operator"] | undefined | null,
	height?: ResolverInputTypes["User_Organiser_Logo_height_operator"] | undefined | null,
	focalX?: ResolverInputTypes["User_Organiser_Logo_focalX_operator"] | undefined | null,
	focalY?: ResolverInputTypes["User_Organiser_Logo_focalY_operator"] | undefined | null,
	sizes__small__url?: ResolverInputTypes["User_Organiser_Logo_sizes__small__url_operator"] | undefined | null,
	sizes__small__width?: ResolverInputTypes["User_Organiser_Logo_sizes__small__width_operator"] | undefined | null,
	sizes__small__height?: ResolverInputTypes["User_Organiser_Logo_sizes__small__height_operator"] | undefined | null,
	sizes__small__mimeType?: ResolverInputTypes["User_Organiser_Logo_sizes__small__mimeType_operator"] | undefined | null,
	sizes__small__filesize?: ResolverInputTypes["User_Organiser_Logo_sizes__small__filesize_operator"] | undefined | null,
	sizes__small__filename?: ResolverInputTypes["User_Organiser_Logo_sizes__small__filename_operator"] | undefined | null,
	sizes__medium__url?: ResolverInputTypes["User_Organiser_Logo_sizes__medium__url_operator"] | undefined | null,
	sizes__medium__width?: ResolverInputTypes["User_Organiser_Logo_sizes__medium__width_operator"] | undefined | null,
	sizes__medium__height?: ResolverInputTypes["User_Organiser_Logo_sizes__medium__height_operator"] | undefined | null,
	sizes__medium__mimeType?: ResolverInputTypes["User_Organiser_Logo_sizes__medium__mimeType_operator"] | undefined | null,
	sizes__medium__filesize?: ResolverInputTypes["User_Organiser_Logo_sizes__medium__filesize_operator"] | undefined | null,
	sizes__medium__filename?: ResolverInputTypes["User_Organiser_Logo_sizes__medium__filename_operator"] | undefined | null,
	sizes__large__url?: ResolverInputTypes["User_Organiser_Logo_sizes__large__url_operator"] | undefined | null,
	sizes__large__width?: ResolverInputTypes["User_Organiser_Logo_sizes__large__width_operator"] | undefined | null,
	sizes__large__height?: ResolverInputTypes["User_Organiser_Logo_sizes__large__height_operator"] | undefined | null,
	sizes__large__mimeType?: ResolverInputTypes["User_Organiser_Logo_sizes__large__mimeType_operator"] | undefined | null,
	sizes__large__filesize?: ResolverInputTypes["User_Organiser_Logo_sizes__large__filesize_operator"] | undefined | null,
	sizes__large__filename?: ResolverInputTypes["User_Organiser_Logo_sizes__large__filename_operator"] | undefined | null,
	sizes__small_jpeg__url?: ResolverInputTypes["User_Organiser_Logo_sizes__small_jpeg__url_operator"] | undefined | null,
	sizes__small_jpeg__width?: ResolverInputTypes["User_Organiser_Logo_sizes__small_jpeg__width_operator"] | undefined | null,
	sizes__small_jpeg__height?: ResolverInputTypes["User_Organiser_Logo_sizes__small_jpeg__height_operator"] | undefined | null,
	sizes__small_jpeg__mimeType?: ResolverInputTypes["User_Organiser_Logo_sizes__small_jpeg__mimeType_operator"] | undefined | null,
	sizes__small_jpeg__filesize?: ResolverInputTypes["User_Organiser_Logo_sizes__small_jpeg__filesize_operator"] | undefined | null,
	sizes__small_jpeg__filename?: ResolverInputTypes["User_Organiser_Logo_sizes__small_jpeg__filename_operator"] | undefined | null,
	sizes__medium_jpeg__url?: ResolverInputTypes["User_Organiser_Logo_sizes__medium_jpeg__url_operator"] | undefined | null,
	sizes__medium_jpeg__width?: ResolverInputTypes["User_Organiser_Logo_sizes__medium_jpeg__width_operator"] | undefined | null,
	sizes__medium_jpeg__height?: ResolverInputTypes["User_Organiser_Logo_sizes__medium_jpeg__height_operator"] | undefined | null,
	sizes__medium_jpeg__mimeType?: ResolverInputTypes["User_Organiser_Logo_sizes__medium_jpeg__mimeType_operator"] | undefined | null,
	sizes__medium_jpeg__filesize?: ResolverInputTypes["User_Organiser_Logo_sizes__medium_jpeg__filesize_operator"] | undefined | null,
	sizes__medium_jpeg__filename?: ResolverInputTypes["User_Organiser_Logo_sizes__medium_jpeg__filename_operator"] | undefined | null,
	sizes__large_jpeg__url?: ResolverInputTypes["User_Organiser_Logo_sizes__large_jpeg__url_operator"] | undefined | null,
	sizes__large_jpeg__width?: ResolverInputTypes["User_Organiser_Logo_sizes__large_jpeg__width_operator"] | undefined | null,
	sizes__large_jpeg__height?: ResolverInputTypes["User_Organiser_Logo_sizes__large_jpeg__height_operator"] | undefined | null,
	sizes__large_jpeg__mimeType?: ResolverInputTypes["User_Organiser_Logo_sizes__large_jpeg__mimeType_operator"] | undefined | null,
	sizes__large_jpeg__filesize?: ResolverInputTypes["User_Organiser_Logo_sizes__large_jpeg__filesize_operator"] | undefined | null,
	sizes__large_jpeg__filename?: ResolverInputTypes["User_Organiser_Logo_sizes__large_jpeg__filename_operator"] | undefined | null,
	id?: ResolverInputTypes["User_Organiser_Logo_id_operator"] | undefined | null,
	AND?: Array<ResolverInputTypes["User_Organiser_Logo_where_and"] | undefined | null> | undefined | null,
	OR?: Array<ResolverInputTypes["User_Organiser_Logo_where_or"] | undefined | null> | undefined | null
};
	["User_Organiser_Logo_where_or"]: {
	alt?: ResolverInputTypes["User_Organiser_Logo_alt_operator"] | undefined | null,
	updatedAt?: ResolverInputTypes["User_Organiser_Logo_updatedAt_operator"] | undefined | null,
	createdAt?: ResolverInputTypes["User_Organiser_Logo_createdAt_operator"] | undefined | null,
	url?: ResolverInputTypes["User_Organiser_Logo_url_operator"] | undefined | null,
	filename?: ResolverInputTypes["User_Organiser_Logo_filename_operator"] | undefined | null,
	mimeType?: ResolverInputTypes["User_Organiser_Logo_mimeType_operator"] | undefined | null,
	filesize?: ResolverInputTypes["User_Organiser_Logo_filesize_operator"] | undefined | null,
	width?: ResolverInputTypes["User_Organiser_Logo_width_operator"] | undefined | null,
	height?: ResolverInputTypes["User_Organiser_Logo_height_operator"] | undefined | null,
	focalX?: ResolverInputTypes["User_Organiser_Logo_focalX_operator"] | undefined | null,
	focalY?: ResolverInputTypes["User_Organiser_Logo_focalY_operator"] | undefined | null,
	sizes__small__url?: ResolverInputTypes["User_Organiser_Logo_sizes__small__url_operator"] | undefined | null,
	sizes__small__width?: ResolverInputTypes["User_Organiser_Logo_sizes__small__width_operator"] | undefined | null,
	sizes__small__height?: ResolverInputTypes["User_Organiser_Logo_sizes__small__height_operator"] | undefined | null,
	sizes__small__mimeType?: ResolverInputTypes["User_Organiser_Logo_sizes__small__mimeType_operator"] | undefined | null,
	sizes__small__filesize?: ResolverInputTypes["User_Organiser_Logo_sizes__small__filesize_operator"] | undefined | null,
	sizes__small__filename?: ResolverInputTypes["User_Organiser_Logo_sizes__small__filename_operator"] | undefined | null,
	sizes__medium__url?: ResolverInputTypes["User_Organiser_Logo_sizes__medium__url_operator"] | undefined | null,
	sizes__medium__width?: ResolverInputTypes["User_Organiser_Logo_sizes__medium__width_operator"] | undefined | null,
	sizes__medium__height?: ResolverInputTypes["User_Organiser_Logo_sizes__medium__height_operator"] | undefined | null,
	sizes__medium__mimeType?: ResolverInputTypes["User_Organiser_Logo_sizes__medium__mimeType_operator"] | undefined | null,
	sizes__medium__filesize?: ResolverInputTypes["User_Organiser_Logo_sizes__medium__filesize_operator"] | undefined | null,
	sizes__medium__filename?: ResolverInputTypes["User_Organiser_Logo_sizes__medium__filename_operator"] | undefined | null,
	sizes__large__url?: ResolverInputTypes["User_Organiser_Logo_sizes__large__url_operator"] | undefined | null,
	sizes__large__width?: ResolverInputTypes["User_Organiser_Logo_sizes__large__width_operator"] | undefined | null,
	sizes__large__height?: ResolverInputTypes["User_Organiser_Logo_sizes__large__height_operator"] | undefined | null,
	sizes__large__mimeType?: ResolverInputTypes["User_Organiser_Logo_sizes__large__mimeType_operator"] | undefined | null,
	sizes__large__filesize?: ResolverInputTypes["User_Organiser_Logo_sizes__large__filesize_operator"] | undefined | null,
	sizes__large__filename?: ResolverInputTypes["User_Organiser_Logo_sizes__large__filename_operator"] | undefined | null,
	sizes__small_jpeg__url?: ResolverInputTypes["User_Organiser_Logo_sizes__small_jpeg__url_operator"] | undefined | null,
	sizes__small_jpeg__width?: ResolverInputTypes["User_Organiser_Logo_sizes__small_jpeg__width_operator"] | undefined | null,
	sizes__small_jpeg__height?: ResolverInputTypes["User_Organiser_Logo_sizes__small_jpeg__height_operator"] | undefined | null,
	sizes__small_jpeg__mimeType?: ResolverInputTypes["User_Organiser_Logo_sizes__small_jpeg__mimeType_operator"] | undefined | null,
	sizes__small_jpeg__filesize?: ResolverInputTypes["User_Organiser_Logo_sizes__small_jpeg__filesize_operator"] | undefined | null,
	sizes__small_jpeg__filename?: ResolverInputTypes["User_Organiser_Logo_sizes__small_jpeg__filename_operator"] | undefined | null,
	sizes__medium_jpeg__url?: ResolverInputTypes["User_Organiser_Logo_sizes__medium_jpeg__url_operator"] | undefined | null,
	sizes__medium_jpeg__width?: ResolverInputTypes["User_Organiser_Logo_sizes__medium_jpeg__width_operator"] | undefined | null,
	sizes__medium_jpeg__height?: ResolverInputTypes["User_Organiser_Logo_sizes__medium_jpeg__height_operator"] | undefined | null,
	sizes__medium_jpeg__mimeType?: ResolverInputTypes["User_Organiser_Logo_sizes__medium_jpeg__mimeType_operator"] | undefined | null,
	sizes__medium_jpeg__filesize?: ResolverInputTypes["User_Organiser_Logo_sizes__medium_jpeg__filesize_operator"] | undefined | null,
	sizes__medium_jpeg__filename?: ResolverInputTypes["User_Organiser_Logo_sizes__medium_jpeg__filename_operator"] | undefined | null,
	sizes__large_jpeg__url?: ResolverInputTypes["User_Organiser_Logo_sizes__large_jpeg__url_operator"] | undefined | null,
	sizes__large_jpeg__width?: ResolverInputTypes["User_Organiser_Logo_sizes__large_jpeg__width_operator"] | undefined | null,
	sizes__large_jpeg__height?: ResolverInputTypes["User_Organiser_Logo_sizes__large_jpeg__height_operator"] | undefined | null,
	sizes__large_jpeg__mimeType?: ResolverInputTypes["User_Organiser_Logo_sizes__large_jpeg__mimeType_operator"] | undefined | null,
	sizes__large_jpeg__filesize?: ResolverInputTypes["User_Organiser_Logo_sizes__large_jpeg__filesize_operator"] | undefined | null,
	sizes__large_jpeg__filename?: ResolverInputTypes["User_Organiser_Logo_sizes__large_jpeg__filename_operator"] | undefined | null,
	id?: ResolverInputTypes["User_Organiser_Logo_id_operator"] | undefined | null,
	AND?: Array<ResolverInputTypes["User_Organiser_Logo_where_and"] | undefined | null> | undefined | null,
	OR?: Array<ResolverInputTypes["User_Organiser_Logo_where_or"] | undefined | null> | undefined | null
};
	["User_Organiser_Banner_where"]: {
	alt?: ResolverInputTypes["User_Organiser_Banner_alt_operator"] | undefined | null,
	updatedAt?: ResolverInputTypes["User_Organiser_Banner_updatedAt_operator"] | undefined | null,
	createdAt?: ResolverInputTypes["User_Organiser_Banner_createdAt_operator"] | undefined | null,
	url?: ResolverInputTypes["User_Organiser_Banner_url_operator"] | undefined | null,
	filename?: ResolverInputTypes["User_Organiser_Banner_filename_operator"] | undefined | null,
	mimeType?: ResolverInputTypes["User_Organiser_Banner_mimeType_operator"] | undefined | null,
	filesize?: ResolverInputTypes["User_Organiser_Banner_filesize_operator"] | undefined | null,
	width?: ResolverInputTypes["User_Organiser_Banner_width_operator"] | undefined | null,
	height?: ResolverInputTypes["User_Organiser_Banner_height_operator"] | undefined | null,
	focalX?: ResolverInputTypes["User_Organiser_Banner_focalX_operator"] | undefined | null,
	focalY?: ResolverInputTypes["User_Organiser_Banner_focalY_operator"] | undefined | null,
	sizes__small__url?: ResolverInputTypes["User_Organiser_Banner_sizes__small__url_operator"] | undefined | null,
	sizes__small__width?: ResolverInputTypes["User_Organiser_Banner_sizes__small__width_operator"] | undefined | null,
	sizes__small__height?: ResolverInputTypes["User_Organiser_Banner_sizes__small__height_operator"] | undefined | null,
	sizes__small__mimeType?: ResolverInputTypes["User_Organiser_Banner_sizes__small__mimeType_operator"] | undefined | null,
	sizes__small__filesize?: ResolverInputTypes["User_Organiser_Banner_sizes__small__filesize_operator"] | undefined | null,
	sizes__small__filename?: ResolverInputTypes["User_Organiser_Banner_sizes__small__filename_operator"] | undefined | null,
	sizes__medium__url?: ResolverInputTypes["User_Organiser_Banner_sizes__medium__url_operator"] | undefined | null,
	sizes__medium__width?: ResolverInputTypes["User_Organiser_Banner_sizes__medium__width_operator"] | undefined | null,
	sizes__medium__height?: ResolverInputTypes["User_Organiser_Banner_sizes__medium__height_operator"] | undefined | null,
	sizes__medium__mimeType?: ResolverInputTypes["User_Organiser_Banner_sizes__medium__mimeType_operator"] | undefined | null,
	sizes__medium__filesize?: ResolverInputTypes["User_Organiser_Banner_sizes__medium__filesize_operator"] | undefined | null,
	sizes__medium__filename?: ResolverInputTypes["User_Organiser_Banner_sizes__medium__filename_operator"] | undefined | null,
	sizes__large__url?: ResolverInputTypes["User_Organiser_Banner_sizes__large__url_operator"] | undefined | null,
	sizes__large__width?: ResolverInputTypes["User_Organiser_Banner_sizes__large__width_operator"] | undefined | null,
	sizes__large__height?: ResolverInputTypes["User_Organiser_Banner_sizes__large__height_operator"] | undefined | null,
	sizes__large__mimeType?: ResolverInputTypes["User_Organiser_Banner_sizes__large__mimeType_operator"] | undefined | null,
	sizes__large__filesize?: ResolverInputTypes["User_Organiser_Banner_sizes__large__filesize_operator"] | undefined | null,
	sizes__large__filename?: ResolverInputTypes["User_Organiser_Banner_sizes__large__filename_operator"] | undefined | null,
	sizes__small_jpeg__url?: ResolverInputTypes["User_Organiser_Banner_sizes__small_jpeg__url_operator"] | undefined | null,
	sizes__small_jpeg__width?: ResolverInputTypes["User_Organiser_Banner_sizes__small_jpeg__width_operator"] | undefined | null,
	sizes__small_jpeg__height?: ResolverInputTypes["User_Organiser_Banner_sizes__small_jpeg__height_operator"] | undefined | null,
	sizes__small_jpeg__mimeType?: ResolverInputTypes["User_Organiser_Banner_sizes__small_jpeg__mimeType_operator"] | undefined | null,
	sizes__small_jpeg__filesize?: ResolverInputTypes["User_Organiser_Banner_sizes__small_jpeg__filesize_operator"] | undefined | null,
	sizes__small_jpeg__filename?: ResolverInputTypes["User_Organiser_Banner_sizes__small_jpeg__filename_operator"] | undefined | null,
	sizes__medium_jpeg__url?: ResolverInputTypes["User_Organiser_Banner_sizes__medium_jpeg__url_operator"] | undefined | null,
	sizes__medium_jpeg__width?: ResolverInputTypes["User_Organiser_Banner_sizes__medium_jpeg__width_operator"] | undefined | null,
	sizes__medium_jpeg__height?: ResolverInputTypes["User_Organiser_Banner_sizes__medium_jpeg__height_operator"] | undefined | null,
	sizes__medium_jpeg__mimeType?: ResolverInputTypes["User_Organiser_Banner_sizes__medium_jpeg__mimeType_operator"] | undefined | null,
	sizes__medium_jpeg__filesize?: ResolverInputTypes["User_Organiser_Banner_sizes__medium_jpeg__filesize_operator"] | undefined | null,
	sizes__medium_jpeg__filename?: ResolverInputTypes["User_Organiser_Banner_sizes__medium_jpeg__filename_operator"] | undefined | null,
	sizes__large_jpeg__url?: ResolverInputTypes["User_Organiser_Banner_sizes__large_jpeg__url_operator"] | undefined | null,
	sizes__large_jpeg__width?: ResolverInputTypes["User_Organiser_Banner_sizes__large_jpeg__width_operator"] | undefined | null,
	sizes__large_jpeg__height?: ResolverInputTypes["User_Organiser_Banner_sizes__large_jpeg__height_operator"] | undefined | null,
	sizes__large_jpeg__mimeType?: ResolverInputTypes["User_Organiser_Banner_sizes__large_jpeg__mimeType_operator"] | undefined | null,
	sizes__large_jpeg__filesize?: ResolverInputTypes["User_Organiser_Banner_sizes__large_jpeg__filesize_operator"] | undefined | null,
	sizes__large_jpeg__filename?: ResolverInputTypes["User_Organiser_Banner_sizes__large_jpeg__filename_operator"] | undefined | null,
	id?: ResolverInputTypes["User_Organiser_Banner_id_operator"] | undefined | null,
	AND?: Array<ResolverInputTypes["User_Organiser_Banner_where_and"] | undefined | null> | undefined | null,
	OR?: Array<ResolverInputTypes["User_Organiser_Banner_where_or"] | undefined | null> | undefined | null
};
	["User_Organiser_Banner_alt_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["User_Organiser_Banner_updatedAt_operator"]: {
	equals?: ResolverInputTypes["DateTime"] | undefined | null,
	not_equals?: ResolverInputTypes["DateTime"] | undefined | null,
	greater_than_equal?: ResolverInputTypes["DateTime"] | undefined | null,
	greater_than?: ResolverInputTypes["DateTime"] | undefined | null,
	less_than_equal?: ResolverInputTypes["DateTime"] | undefined | null,
	less_than?: ResolverInputTypes["DateTime"] | undefined | null,
	like?: ResolverInputTypes["DateTime"] | undefined | null,
	exists?: boolean | undefined | null
};
	["User_Organiser_Banner_createdAt_operator"]: {
	equals?: ResolverInputTypes["DateTime"] | undefined | null,
	not_equals?: ResolverInputTypes["DateTime"] | undefined | null,
	greater_than_equal?: ResolverInputTypes["DateTime"] | undefined | null,
	greater_than?: ResolverInputTypes["DateTime"] | undefined | null,
	less_than_equal?: ResolverInputTypes["DateTime"] | undefined | null,
	less_than?: ResolverInputTypes["DateTime"] | undefined | null,
	like?: ResolverInputTypes["DateTime"] | undefined | null,
	exists?: boolean | undefined | null
};
	["User_Organiser_Banner_url_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["User_Organiser_Banner_filename_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["User_Organiser_Banner_mimeType_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["User_Organiser_Banner_filesize_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["User_Organiser_Banner_width_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["User_Organiser_Banner_height_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["User_Organiser_Banner_focalX_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["User_Organiser_Banner_focalY_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["User_Organiser_Banner_sizes__small__url_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["User_Organiser_Banner_sizes__small__width_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["User_Organiser_Banner_sizes__small__height_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["User_Organiser_Banner_sizes__small__mimeType_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["User_Organiser_Banner_sizes__small__filesize_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["User_Organiser_Banner_sizes__small__filename_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["User_Organiser_Banner_sizes__medium__url_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["User_Organiser_Banner_sizes__medium__width_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["User_Organiser_Banner_sizes__medium__height_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["User_Organiser_Banner_sizes__medium__mimeType_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["User_Organiser_Banner_sizes__medium__filesize_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["User_Organiser_Banner_sizes__medium__filename_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["User_Organiser_Banner_sizes__large__url_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["User_Organiser_Banner_sizes__large__width_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["User_Organiser_Banner_sizes__large__height_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["User_Organiser_Banner_sizes__large__mimeType_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["User_Organiser_Banner_sizes__large__filesize_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["User_Organiser_Banner_sizes__large__filename_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["User_Organiser_Banner_sizes__small_jpeg__url_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["User_Organiser_Banner_sizes__small_jpeg__width_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["User_Organiser_Banner_sizes__small_jpeg__height_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["User_Organiser_Banner_sizes__small_jpeg__mimeType_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["User_Organiser_Banner_sizes__small_jpeg__filesize_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["User_Organiser_Banner_sizes__small_jpeg__filename_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["User_Organiser_Banner_sizes__medium_jpeg__url_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["User_Organiser_Banner_sizes__medium_jpeg__width_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["User_Organiser_Banner_sizes__medium_jpeg__height_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["User_Organiser_Banner_sizes__medium_jpeg__mimeType_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["User_Organiser_Banner_sizes__medium_jpeg__filesize_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["User_Organiser_Banner_sizes__medium_jpeg__filename_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["User_Organiser_Banner_sizes__large_jpeg__url_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["User_Organiser_Banner_sizes__large_jpeg__width_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["User_Organiser_Banner_sizes__large_jpeg__height_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["User_Organiser_Banner_sizes__large_jpeg__mimeType_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["User_Organiser_Banner_sizes__large_jpeg__filesize_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["User_Organiser_Banner_sizes__large_jpeg__filename_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["User_Organiser_Banner_id_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["User_Organiser_Banner_where_and"]: {
	alt?: ResolverInputTypes["User_Organiser_Banner_alt_operator"] | undefined | null,
	updatedAt?: ResolverInputTypes["User_Organiser_Banner_updatedAt_operator"] | undefined | null,
	createdAt?: ResolverInputTypes["User_Organiser_Banner_createdAt_operator"] | undefined | null,
	url?: ResolverInputTypes["User_Organiser_Banner_url_operator"] | undefined | null,
	filename?: ResolverInputTypes["User_Organiser_Banner_filename_operator"] | undefined | null,
	mimeType?: ResolverInputTypes["User_Organiser_Banner_mimeType_operator"] | undefined | null,
	filesize?: ResolverInputTypes["User_Organiser_Banner_filesize_operator"] | undefined | null,
	width?: ResolverInputTypes["User_Organiser_Banner_width_operator"] | undefined | null,
	height?: ResolverInputTypes["User_Organiser_Banner_height_operator"] | undefined | null,
	focalX?: ResolverInputTypes["User_Organiser_Banner_focalX_operator"] | undefined | null,
	focalY?: ResolverInputTypes["User_Organiser_Banner_focalY_operator"] | undefined | null,
	sizes__small__url?: ResolverInputTypes["User_Organiser_Banner_sizes__small__url_operator"] | undefined | null,
	sizes__small__width?: ResolverInputTypes["User_Organiser_Banner_sizes__small__width_operator"] | undefined | null,
	sizes__small__height?: ResolverInputTypes["User_Organiser_Banner_sizes__small__height_operator"] | undefined | null,
	sizes__small__mimeType?: ResolverInputTypes["User_Organiser_Banner_sizes__small__mimeType_operator"] | undefined | null,
	sizes__small__filesize?: ResolverInputTypes["User_Organiser_Banner_sizes__small__filesize_operator"] | undefined | null,
	sizes__small__filename?: ResolverInputTypes["User_Organiser_Banner_sizes__small__filename_operator"] | undefined | null,
	sizes__medium__url?: ResolverInputTypes["User_Organiser_Banner_sizes__medium__url_operator"] | undefined | null,
	sizes__medium__width?: ResolverInputTypes["User_Organiser_Banner_sizes__medium__width_operator"] | undefined | null,
	sizes__medium__height?: ResolverInputTypes["User_Organiser_Banner_sizes__medium__height_operator"] | undefined | null,
	sizes__medium__mimeType?: ResolverInputTypes["User_Organiser_Banner_sizes__medium__mimeType_operator"] | undefined | null,
	sizes__medium__filesize?: ResolverInputTypes["User_Organiser_Banner_sizes__medium__filesize_operator"] | undefined | null,
	sizes__medium__filename?: ResolverInputTypes["User_Organiser_Banner_sizes__medium__filename_operator"] | undefined | null,
	sizes__large__url?: ResolverInputTypes["User_Organiser_Banner_sizes__large__url_operator"] | undefined | null,
	sizes__large__width?: ResolverInputTypes["User_Organiser_Banner_sizes__large__width_operator"] | undefined | null,
	sizes__large__height?: ResolverInputTypes["User_Organiser_Banner_sizes__large__height_operator"] | undefined | null,
	sizes__large__mimeType?: ResolverInputTypes["User_Organiser_Banner_sizes__large__mimeType_operator"] | undefined | null,
	sizes__large__filesize?: ResolverInputTypes["User_Organiser_Banner_sizes__large__filesize_operator"] | undefined | null,
	sizes__large__filename?: ResolverInputTypes["User_Organiser_Banner_sizes__large__filename_operator"] | undefined | null,
	sizes__small_jpeg__url?: ResolverInputTypes["User_Organiser_Banner_sizes__small_jpeg__url_operator"] | undefined | null,
	sizes__small_jpeg__width?: ResolverInputTypes["User_Organiser_Banner_sizes__small_jpeg__width_operator"] | undefined | null,
	sizes__small_jpeg__height?: ResolverInputTypes["User_Organiser_Banner_sizes__small_jpeg__height_operator"] | undefined | null,
	sizes__small_jpeg__mimeType?: ResolverInputTypes["User_Organiser_Banner_sizes__small_jpeg__mimeType_operator"] | undefined | null,
	sizes__small_jpeg__filesize?: ResolverInputTypes["User_Organiser_Banner_sizes__small_jpeg__filesize_operator"] | undefined | null,
	sizes__small_jpeg__filename?: ResolverInputTypes["User_Organiser_Banner_sizes__small_jpeg__filename_operator"] | undefined | null,
	sizes__medium_jpeg__url?: ResolverInputTypes["User_Organiser_Banner_sizes__medium_jpeg__url_operator"] | undefined | null,
	sizes__medium_jpeg__width?: ResolverInputTypes["User_Organiser_Banner_sizes__medium_jpeg__width_operator"] | undefined | null,
	sizes__medium_jpeg__height?: ResolverInputTypes["User_Organiser_Banner_sizes__medium_jpeg__height_operator"] | undefined | null,
	sizes__medium_jpeg__mimeType?: ResolverInputTypes["User_Organiser_Banner_sizes__medium_jpeg__mimeType_operator"] | undefined | null,
	sizes__medium_jpeg__filesize?: ResolverInputTypes["User_Organiser_Banner_sizes__medium_jpeg__filesize_operator"] | undefined | null,
	sizes__medium_jpeg__filename?: ResolverInputTypes["User_Organiser_Banner_sizes__medium_jpeg__filename_operator"] | undefined | null,
	sizes__large_jpeg__url?: ResolverInputTypes["User_Organiser_Banner_sizes__large_jpeg__url_operator"] | undefined | null,
	sizes__large_jpeg__width?: ResolverInputTypes["User_Organiser_Banner_sizes__large_jpeg__width_operator"] | undefined | null,
	sizes__large_jpeg__height?: ResolverInputTypes["User_Organiser_Banner_sizes__large_jpeg__height_operator"] | undefined | null,
	sizes__large_jpeg__mimeType?: ResolverInputTypes["User_Organiser_Banner_sizes__large_jpeg__mimeType_operator"] | undefined | null,
	sizes__large_jpeg__filesize?: ResolverInputTypes["User_Organiser_Banner_sizes__large_jpeg__filesize_operator"] | undefined | null,
	sizes__large_jpeg__filename?: ResolverInputTypes["User_Organiser_Banner_sizes__large_jpeg__filename_operator"] | undefined | null,
	id?: ResolverInputTypes["User_Organiser_Banner_id_operator"] | undefined | null,
	AND?: Array<ResolverInputTypes["User_Organiser_Banner_where_and"] | undefined | null> | undefined | null,
	OR?: Array<ResolverInputTypes["User_Organiser_Banner_where_or"] | undefined | null> | undefined | null
};
	["User_Organiser_Banner_where_or"]: {
	alt?: ResolverInputTypes["User_Organiser_Banner_alt_operator"] | undefined | null,
	updatedAt?: ResolverInputTypes["User_Organiser_Banner_updatedAt_operator"] | undefined | null,
	createdAt?: ResolverInputTypes["User_Organiser_Banner_createdAt_operator"] | undefined | null,
	url?: ResolverInputTypes["User_Organiser_Banner_url_operator"] | undefined | null,
	filename?: ResolverInputTypes["User_Organiser_Banner_filename_operator"] | undefined | null,
	mimeType?: ResolverInputTypes["User_Organiser_Banner_mimeType_operator"] | undefined | null,
	filesize?: ResolverInputTypes["User_Organiser_Banner_filesize_operator"] | undefined | null,
	width?: ResolverInputTypes["User_Organiser_Banner_width_operator"] | undefined | null,
	height?: ResolverInputTypes["User_Organiser_Banner_height_operator"] | undefined | null,
	focalX?: ResolverInputTypes["User_Organiser_Banner_focalX_operator"] | undefined | null,
	focalY?: ResolverInputTypes["User_Organiser_Banner_focalY_operator"] | undefined | null,
	sizes__small__url?: ResolverInputTypes["User_Organiser_Banner_sizes__small__url_operator"] | undefined | null,
	sizes__small__width?: ResolverInputTypes["User_Organiser_Banner_sizes__small__width_operator"] | undefined | null,
	sizes__small__height?: ResolverInputTypes["User_Organiser_Banner_sizes__small__height_operator"] | undefined | null,
	sizes__small__mimeType?: ResolverInputTypes["User_Organiser_Banner_sizes__small__mimeType_operator"] | undefined | null,
	sizes__small__filesize?: ResolverInputTypes["User_Organiser_Banner_sizes__small__filesize_operator"] | undefined | null,
	sizes__small__filename?: ResolverInputTypes["User_Organiser_Banner_sizes__small__filename_operator"] | undefined | null,
	sizes__medium__url?: ResolverInputTypes["User_Organiser_Banner_sizes__medium__url_operator"] | undefined | null,
	sizes__medium__width?: ResolverInputTypes["User_Organiser_Banner_sizes__medium__width_operator"] | undefined | null,
	sizes__medium__height?: ResolverInputTypes["User_Organiser_Banner_sizes__medium__height_operator"] | undefined | null,
	sizes__medium__mimeType?: ResolverInputTypes["User_Organiser_Banner_sizes__medium__mimeType_operator"] | undefined | null,
	sizes__medium__filesize?: ResolverInputTypes["User_Organiser_Banner_sizes__medium__filesize_operator"] | undefined | null,
	sizes__medium__filename?: ResolverInputTypes["User_Organiser_Banner_sizes__medium__filename_operator"] | undefined | null,
	sizes__large__url?: ResolverInputTypes["User_Organiser_Banner_sizes__large__url_operator"] | undefined | null,
	sizes__large__width?: ResolverInputTypes["User_Organiser_Banner_sizes__large__width_operator"] | undefined | null,
	sizes__large__height?: ResolverInputTypes["User_Organiser_Banner_sizes__large__height_operator"] | undefined | null,
	sizes__large__mimeType?: ResolverInputTypes["User_Organiser_Banner_sizes__large__mimeType_operator"] | undefined | null,
	sizes__large__filesize?: ResolverInputTypes["User_Organiser_Banner_sizes__large__filesize_operator"] | undefined | null,
	sizes__large__filename?: ResolverInputTypes["User_Organiser_Banner_sizes__large__filename_operator"] | undefined | null,
	sizes__small_jpeg__url?: ResolverInputTypes["User_Organiser_Banner_sizes__small_jpeg__url_operator"] | undefined | null,
	sizes__small_jpeg__width?: ResolverInputTypes["User_Organiser_Banner_sizes__small_jpeg__width_operator"] | undefined | null,
	sizes__small_jpeg__height?: ResolverInputTypes["User_Organiser_Banner_sizes__small_jpeg__height_operator"] | undefined | null,
	sizes__small_jpeg__mimeType?: ResolverInputTypes["User_Organiser_Banner_sizes__small_jpeg__mimeType_operator"] | undefined | null,
	sizes__small_jpeg__filesize?: ResolverInputTypes["User_Organiser_Banner_sizes__small_jpeg__filesize_operator"] | undefined | null,
	sizes__small_jpeg__filename?: ResolverInputTypes["User_Organiser_Banner_sizes__small_jpeg__filename_operator"] | undefined | null,
	sizes__medium_jpeg__url?: ResolverInputTypes["User_Organiser_Banner_sizes__medium_jpeg__url_operator"] | undefined | null,
	sizes__medium_jpeg__width?: ResolverInputTypes["User_Organiser_Banner_sizes__medium_jpeg__width_operator"] | undefined | null,
	sizes__medium_jpeg__height?: ResolverInputTypes["User_Organiser_Banner_sizes__medium_jpeg__height_operator"] | undefined | null,
	sizes__medium_jpeg__mimeType?: ResolverInputTypes["User_Organiser_Banner_sizes__medium_jpeg__mimeType_operator"] | undefined | null,
	sizes__medium_jpeg__filesize?: ResolverInputTypes["User_Organiser_Banner_sizes__medium_jpeg__filesize_operator"] | undefined | null,
	sizes__medium_jpeg__filename?: ResolverInputTypes["User_Organiser_Banner_sizes__medium_jpeg__filename_operator"] | undefined | null,
	sizes__large_jpeg__url?: ResolverInputTypes["User_Organiser_Banner_sizes__large_jpeg__url_operator"] | undefined | null,
	sizes__large_jpeg__width?: ResolverInputTypes["User_Organiser_Banner_sizes__large_jpeg__width_operator"] | undefined | null,
	sizes__large_jpeg__height?: ResolverInputTypes["User_Organiser_Banner_sizes__large_jpeg__height_operator"] | undefined | null,
	sizes__large_jpeg__mimeType?: ResolverInputTypes["User_Organiser_Banner_sizes__large_jpeg__mimeType_operator"] | undefined | null,
	sizes__large_jpeg__filesize?: ResolverInputTypes["User_Organiser_Banner_sizes__large_jpeg__filesize_operator"] | undefined | null,
	sizes__large_jpeg__filename?: ResolverInputTypes["User_Organiser_Banner_sizes__large_jpeg__filename_operator"] | undefined | null,
	id?: ResolverInputTypes["User_Organiser_Banner_id_operator"] | undefined | null,
	AND?: Array<ResolverInputTypes["User_Organiser_Banner_where_and"] | undefined | null> | undefined | null,
	OR?: Array<ResolverInputTypes["User_Organiser_Banner_where_or"] | undefined | null> | undefined | null
};
	["User_Organiser_language"]:User_Organiser_language;
	["User_Sso"]: AliasType<{
	googleId?:boolean | `@${string}`,
	facebookId?:boolean | `@${string}`,
	appleId?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["User_status"]:User_status;
	/** A field whose value conforms to the standard internet email address format as specified in HTML Spec: https://html.spec.whatwg.org/multipage/input.html#valid-e-mail-address. */
["EmailAddress"]:unknown;
	["Event_level"]:Event_level;
	["Event_Thumbnail_where"]: {
	alt?: ResolverInputTypes["Event_Thumbnail_alt_operator"] | undefined | null,
	updatedAt?: ResolverInputTypes["Event_Thumbnail_updatedAt_operator"] | undefined | null,
	createdAt?: ResolverInputTypes["Event_Thumbnail_createdAt_operator"] | undefined | null,
	url?: ResolverInputTypes["Event_Thumbnail_url_operator"] | undefined | null,
	filename?: ResolverInputTypes["Event_Thumbnail_filename_operator"] | undefined | null,
	mimeType?: ResolverInputTypes["Event_Thumbnail_mimeType_operator"] | undefined | null,
	filesize?: ResolverInputTypes["Event_Thumbnail_filesize_operator"] | undefined | null,
	width?: ResolverInputTypes["Event_Thumbnail_width_operator"] | undefined | null,
	height?: ResolverInputTypes["Event_Thumbnail_height_operator"] | undefined | null,
	focalX?: ResolverInputTypes["Event_Thumbnail_focalX_operator"] | undefined | null,
	focalY?: ResolverInputTypes["Event_Thumbnail_focalY_operator"] | undefined | null,
	sizes__small__url?: ResolverInputTypes["Event_Thumbnail_sizes__small__url_operator"] | undefined | null,
	sizes__small__width?: ResolverInputTypes["Event_Thumbnail_sizes__small__width_operator"] | undefined | null,
	sizes__small__height?: ResolverInputTypes["Event_Thumbnail_sizes__small__height_operator"] | undefined | null,
	sizes__small__mimeType?: ResolverInputTypes["Event_Thumbnail_sizes__small__mimeType_operator"] | undefined | null,
	sizes__small__filesize?: ResolverInputTypes["Event_Thumbnail_sizes__small__filesize_operator"] | undefined | null,
	sizes__small__filename?: ResolverInputTypes["Event_Thumbnail_sizes__small__filename_operator"] | undefined | null,
	sizes__medium__url?: ResolverInputTypes["Event_Thumbnail_sizes__medium__url_operator"] | undefined | null,
	sizes__medium__width?: ResolverInputTypes["Event_Thumbnail_sizes__medium__width_operator"] | undefined | null,
	sizes__medium__height?: ResolverInputTypes["Event_Thumbnail_sizes__medium__height_operator"] | undefined | null,
	sizes__medium__mimeType?: ResolverInputTypes["Event_Thumbnail_sizes__medium__mimeType_operator"] | undefined | null,
	sizes__medium__filesize?: ResolverInputTypes["Event_Thumbnail_sizes__medium__filesize_operator"] | undefined | null,
	sizes__medium__filename?: ResolverInputTypes["Event_Thumbnail_sizes__medium__filename_operator"] | undefined | null,
	sizes__large__url?: ResolverInputTypes["Event_Thumbnail_sizes__large__url_operator"] | undefined | null,
	sizes__large__width?: ResolverInputTypes["Event_Thumbnail_sizes__large__width_operator"] | undefined | null,
	sizes__large__height?: ResolverInputTypes["Event_Thumbnail_sizes__large__height_operator"] | undefined | null,
	sizes__large__mimeType?: ResolverInputTypes["Event_Thumbnail_sizes__large__mimeType_operator"] | undefined | null,
	sizes__large__filesize?: ResolverInputTypes["Event_Thumbnail_sizes__large__filesize_operator"] | undefined | null,
	sizes__large__filename?: ResolverInputTypes["Event_Thumbnail_sizes__large__filename_operator"] | undefined | null,
	sizes__small_jpeg__url?: ResolverInputTypes["Event_Thumbnail_sizes__small_jpeg__url_operator"] | undefined | null,
	sizes__small_jpeg__width?: ResolverInputTypes["Event_Thumbnail_sizes__small_jpeg__width_operator"] | undefined | null,
	sizes__small_jpeg__height?: ResolverInputTypes["Event_Thumbnail_sizes__small_jpeg__height_operator"] | undefined | null,
	sizes__small_jpeg__mimeType?: ResolverInputTypes["Event_Thumbnail_sizes__small_jpeg__mimeType_operator"] | undefined | null,
	sizes__small_jpeg__filesize?: ResolverInputTypes["Event_Thumbnail_sizes__small_jpeg__filesize_operator"] | undefined | null,
	sizes__small_jpeg__filename?: ResolverInputTypes["Event_Thumbnail_sizes__small_jpeg__filename_operator"] | undefined | null,
	sizes__medium_jpeg__url?: ResolverInputTypes["Event_Thumbnail_sizes__medium_jpeg__url_operator"] | undefined | null,
	sizes__medium_jpeg__width?: ResolverInputTypes["Event_Thumbnail_sizes__medium_jpeg__width_operator"] | undefined | null,
	sizes__medium_jpeg__height?: ResolverInputTypes["Event_Thumbnail_sizes__medium_jpeg__height_operator"] | undefined | null,
	sizes__medium_jpeg__mimeType?: ResolverInputTypes["Event_Thumbnail_sizes__medium_jpeg__mimeType_operator"] | undefined | null,
	sizes__medium_jpeg__filesize?: ResolverInputTypes["Event_Thumbnail_sizes__medium_jpeg__filesize_operator"] | undefined | null,
	sizes__medium_jpeg__filename?: ResolverInputTypes["Event_Thumbnail_sizes__medium_jpeg__filename_operator"] | undefined | null,
	sizes__large_jpeg__url?: ResolverInputTypes["Event_Thumbnail_sizes__large_jpeg__url_operator"] | undefined | null,
	sizes__large_jpeg__width?: ResolverInputTypes["Event_Thumbnail_sizes__large_jpeg__width_operator"] | undefined | null,
	sizes__large_jpeg__height?: ResolverInputTypes["Event_Thumbnail_sizes__large_jpeg__height_operator"] | undefined | null,
	sizes__large_jpeg__mimeType?: ResolverInputTypes["Event_Thumbnail_sizes__large_jpeg__mimeType_operator"] | undefined | null,
	sizes__large_jpeg__filesize?: ResolverInputTypes["Event_Thumbnail_sizes__large_jpeg__filesize_operator"] | undefined | null,
	sizes__large_jpeg__filename?: ResolverInputTypes["Event_Thumbnail_sizes__large_jpeg__filename_operator"] | undefined | null,
	id?: ResolverInputTypes["Event_Thumbnail_id_operator"] | undefined | null,
	AND?: Array<ResolverInputTypes["Event_Thumbnail_where_and"] | undefined | null> | undefined | null,
	OR?: Array<ResolverInputTypes["Event_Thumbnail_where_or"] | undefined | null> | undefined | null
};
	["Event_Thumbnail_alt_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Event_Thumbnail_updatedAt_operator"]: {
	equals?: ResolverInputTypes["DateTime"] | undefined | null,
	not_equals?: ResolverInputTypes["DateTime"] | undefined | null,
	greater_than_equal?: ResolverInputTypes["DateTime"] | undefined | null,
	greater_than?: ResolverInputTypes["DateTime"] | undefined | null,
	less_than_equal?: ResolverInputTypes["DateTime"] | undefined | null,
	less_than?: ResolverInputTypes["DateTime"] | undefined | null,
	like?: ResolverInputTypes["DateTime"] | undefined | null,
	exists?: boolean | undefined | null
};
	["Event_Thumbnail_createdAt_operator"]: {
	equals?: ResolverInputTypes["DateTime"] | undefined | null,
	not_equals?: ResolverInputTypes["DateTime"] | undefined | null,
	greater_than_equal?: ResolverInputTypes["DateTime"] | undefined | null,
	greater_than?: ResolverInputTypes["DateTime"] | undefined | null,
	less_than_equal?: ResolverInputTypes["DateTime"] | undefined | null,
	less_than?: ResolverInputTypes["DateTime"] | undefined | null,
	like?: ResolverInputTypes["DateTime"] | undefined | null,
	exists?: boolean | undefined | null
};
	["Event_Thumbnail_url_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Event_Thumbnail_filename_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Event_Thumbnail_mimeType_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Event_Thumbnail_filesize_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Event_Thumbnail_width_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Event_Thumbnail_height_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Event_Thumbnail_focalX_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Event_Thumbnail_focalY_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Event_Thumbnail_sizes__small__url_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Event_Thumbnail_sizes__small__width_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Event_Thumbnail_sizes__small__height_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Event_Thumbnail_sizes__small__mimeType_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Event_Thumbnail_sizes__small__filesize_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Event_Thumbnail_sizes__small__filename_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Event_Thumbnail_sizes__medium__url_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Event_Thumbnail_sizes__medium__width_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Event_Thumbnail_sizes__medium__height_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Event_Thumbnail_sizes__medium__mimeType_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Event_Thumbnail_sizes__medium__filesize_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Event_Thumbnail_sizes__medium__filename_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Event_Thumbnail_sizes__large__url_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Event_Thumbnail_sizes__large__width_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Event_Thumbnail_sizes__large__height_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Event_Thumbnail_sizes__large__mimeType_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Event_Thumbnail_sizes__large__filesize_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Event_Thumbnail_sizes__large__filename_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Event_Thumbnail_sizes__small_jpeg__url_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Event_Thumbnail_sizes__small_jpeg__width_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Event_Thumbnail_sizes__small_jpeg__height_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Event_Thumbnail_sizes__small_jpeg__mimeType_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Event_Thumbnail_sizes__small_jpeg__filesize_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Event_Thumbnail_sizes__small_jpeg__filename_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Event_Thumbnail_sizes__medium_jpeg__url_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Event_Thumbnail_sizes__medium_jpeg__width_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Event_Thumbnail_sizes__medium_jpeg__height_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Event_Thumbnail_sizes__medium_jpeg__mimeType_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Event_Thumbnail_sizes__medium_jpeg__filesize_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Event_Thumbnail_sizes__medium_jpeg__filename_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Event_Thumbnail_sizes__large_jpeg__url_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Event_Thumbnail_sizes__large_jpeg__width_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Event_Thumbnail_sizes__large_jpeg__height_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Event_Thumbnail_sizes__large_jpeg__mimeType_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Event_Thumbnail_sizes__large_jpeg__filesize_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Event_Thumbnail_sizes__large_jpeg__filename_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Event_Thumbnail_id_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Event_Thumbnail_where_and"]: {
	alt?: ResolverInputTypes["Event_Thumbnail_alt_operator"] | undefined | null,
	updatedAt?: ResolverInputTypes["Event_Thumbnail_updatedAt_operator"] | undefined | null,
	createdAt?: ResolverInputTypes["Event_Thumbnail_createdAt_operator"] | undefined | null,
	url?: ResolverInputTypes["Event_Thumbnail_url_operator"] | undefined | null,
	filename?: ResolverInputTypes["Event_Thumbnail_filename_operator"] | undefined | null,
	mimeType?: ResolverInputTypes["Event_Thumbnail_mimeType_operator"] | undefined | null,
	filesize?: ResolverInputTypes["Event_Thumbnail_filesize_operator"] | undefined | null,
	width?: ResolverInputTypes["Event_Thumbnail_width_operator"] | undefined | null,
	height?: ResolverInputTypes["Event_Thumbnail_height_operator"] | undefined | null,
	focalX?: ResolverInputTypes["Event_Thumbnail_focalX_operator"] | undefined | null,
	focalY?: ResolverInputTypes["Event_Thumbnail_focalY_operator"] | undefined | null,
	sizes__small__url?: ResolverInputTypes["Event_Thumbnail_sizes__small__url_operator"] | undefined | null,
	sizes__small__width?: ResolverInputTypes["Event_Thumbnail_sizes__small__width_operator"] | undefined | null,
	sizes__small__height?: ResolverInputTypes["Event_Thumbnail_sizes__small__height_operator"] | undefined | null,
	sizes__small__mimeType?: ResolverInputTypes["Event_Thumbnail_sizes__small__mimeType_operator"] | undefined | null,
	sizes__small__filesize?: ResolverInputTypes["Event_Thumbnail_sizes__small__filesize_operator"] | undefined | null,
	sizes__small__filename?: ResolverInputTypes["Event_Thumbnail_sizes__small__filename_operator"] | undefined | null,
	sizes__medium__url?: ResolverInputTypes["Event_Thumbnail_sizes__medium__url_operator"] | undefined | null,
	sizes__medium__width?: ResolverInputTypes["Event_Thumbnail_sizes__medium__width_operator"] | undefined | null,
	sizes__medium__height?: ResolverInputTypes["Event_Thumbnail_sizes__medium__height_operator"] | undefined | null,
	sizes__medium__mimeType?: ResolverInputTypes["Event_Thumbnail_sizes__medium__mimeType_operator"] | undefined | null,
	sizes__medium__filesize?: ResolverInputTypes["Event_Thumbnail_sizes__medium__filesize_operator"] | undefined | null,
	sizes__medium__filename?: ResolverInputTypes["Event_Thumbnail_sizes__medium__filename_operator"] | undefined | null,
	sizes__large__url?: ResolverInputTypes["Event_Thumbnail_sizes__large__url_operator"] | undefined | null,
	sizes__large__width?: ResolverInputTypes["Event_Thumbnail_sizes__large__width_operator"] | undefined | null,
	sizes__large__height?: ResolverInputTypes["Event_Thumbnail_sizes__large__height_operator"] | undefined | null,
	sizes__large__mimeType?: ResolverInputTypes["Event_Thumbnail_sizes__large__mimeType_operator"] | undefined | null,
	sizes__large__filesize?: ResolverInputTypes["Event_Thumbnail_sizes__large__filesize_operator"] | undefined | null,
	sizes__large__filename?: ResolverInputTypes["Event_Thumbnail_sizes__large__filename_operator"] | undefined | null,
	sizes__small_jpeg__url?: ResolverInputTypes["Event_Thumbnail_sizes__small_jpeg__url_operator"] | undefined | null,
	sizes__small_jpeg__width?: ResolverInputTypes["Event_Thumbnail_sizes__small_jpeg__width_operator"] | undefined | null,
	sizes__small_jpeg__height?: ResolverInputTypes["Event_Thumbnail_sizes__small_jpeg__height_operator"] | undefined | null,
	sizes__small_jpeg__mimeType?: ResolverInputTypes["Event_Thumbnail_sizes__small_jpeg__mimeType_operator"] | undefined | null,
	sizes__small_jpeg__filesize?: ResolverInputTypes["Event_Thumbnail_sizes__small_jpeg__filesize_operator"] | undefined | null,
	sizes__small_jpeg__filename?: ResolverInputTypes["Event_Thumbnail_sizes__small_jpeg__filename_operator"] | undefined | null,
	sizes__medium_jpeg__url?: ResolverInputTypes["Event_Thumbnail_sizes__medium_jpeg__url_operator"] | undefined | null,
	sizes__medium_jpeg__width?: ResolverInputTypes["Event_Thumbnail_sizes__medium_jpeg__width_operator"] | undefined | null,
	sizes__medium_jpeg__height?: ResolverInputTypes["Event_Thumbnail_sizes__medium_jpeg__height_operator"] | undefined | null,
	sizes__medium_jpeg__mimeType?: ResolverInputTypes["Event_Thumbnail_sizes__medium_jpeg__mimeType_operator"] | undefined | null,
	sizes__medium_jpeg__filesize?: ResolverInputTypes["Event_Thumbnail_sizes__medium_jpeg__filesize_operator"] | undefined | null,
	sizes__medium_jpeg__filename?: ResolverInputTypes["Event_Thumbnail_sizes__medium_jpeg__filename_operator"] | undefined | null,
	sizes__large_jpeg__url?: ResolverInputTypes["Event_Thumbnail_sizes__large_jpeg__url_operator"] | undefined | null,
	sizes__large_jpeg__width?: ResolverInputTypes["Event_Thumbnail_sizes__large_jpeg__width_operator"] | undefined | null,
	sizes__large_jpeg__height?: ResolverInputTypes["Event_Thumbnail_sizes__large_jpeg__height_operator"] | undefined | null,
	sizes__large_jpeg__mimeType?: ResolverInputTypes["Event_Thumbnail_sizes__large_jpeg__mimeType_operator"] | undefined | null,
	sizes__large_jpeg__filesize?: ResolverInputTypes["Event_Thumbnail_sizes__large_jpeg__filesize_operator"] | undefined | null,
	sizes__large_jpeg__filename?: ResolverInputTypes["Event_Thumbnail_sizes__large_jpeg__filename_operator"] | undefined | null,
	id?: ResolverInputTypes["Event_Thumbnail_id_operator"] | undefined | null,
	AND?: Array<ResolverInputTypes["Event_Thumbnail_where_and"] | undefined | null> | undefined | null,
	OR?: Array<ResolverInputTypes["Event_Thumbnail_where_or"] | undefined | null> | undefined | null
};
	["Event_Thumbnail_where_or"]: {
	alt?: ResolverInputTypes["Event_Thumbnail_alt_operator"] | undefined | null,
	updatedAt?: ResolverInputTypes["Event_Thumbnail_updatedAt_operator"] | undefined | null,
	createdAt?: ResolverInputTypes["Event_Thumbnail_createdAt_operator"] | undefined | null,
	url?: ResolverInputTypes["Event_Thumbnail_url_operator"] | undefined | null,
	filename?: ResolverInputTypes["Event_Thumbnail_filename_operator"] | undefined | null,
	mimeType?: ResolverInputTypes["Event_Thumbnail_mimeType_operator"] | undefined | null,
	filesize?: ResolverInputTypes["Event_Thumbnail_filesize_operator"] | undefined | null,
	width?: ResolverInputTypes["Event_Thumbnail_width_operator"] | undefined | null,
	height?: ResolverInputTypes["Event_Thumbnail_height_operator"] | undefined | null,
	focalX?: ResolverInputTypes["Event_Thumbnail_focalX_operator"] | undefined | null,
	focalY?: ResolverInputTypes["Event_Thumbnail_focalY_operator"] | undefined | null,
	sizes__small__url?: ResolverInputTypes["Event_Thumbnail_sizes__small__url_operator"] | undefined | null,
	sizes__small__width?: ResolverInputTypes["Event_Thumbnail_sizes__small__width_operator"] | undefined | null,
	sizes__small__height?: ResolverInputTypes["Event_Thumbnail_sizes__small__height_operator"] | undefined | null,
	sizes__small__mimeType?: ResolverInputTypes["Event_Thumbnail_sizes__small__mimeType_operator"] | undefined | null,
	sizes__small__filesize?: ResolverInputTypes["Event_Thumbnail_sizes__small__filesize_operator"] | undefined | null,
	sizes__small__filename?: ResolverInputTypes["Event_Thumbnail_sizes__small__filename_operator"] | undefined | null,
	sizes__medium__url?: ResolverInputTypes["Event_Thumbnail_sizes__medium__url_operator"] | undefined | null,
	sizes__medium__width?: ResolverInputTypes["Event_Thumbnail_sizes__medium__width_operator"] | undefined | null,
	sizes__medium__height?: ResolverInputTypes["Event_Thumbnail_sizes__medium__height_operator"] | undefined | null,
	sizes__medium__mimeType?: ResolverInputTypes["Event_Thumbnail_sizes__medium__mimeType_operator"] | undefined | null,
	sizes__medium__filesize?: ResolverInputTypes["Event_Thumbnail_sizes__medium__filesize_operator"] | undefined | null,
	sizes__medium__filename?: ResolverInputTypes["Event_Thumbnail_sizes__medium__filename_operator"] | undefined | null,
	sizes__large__url?: ResolverInputTypes["Event_Thumbnail_sizes__large__url_operator"] | undefined | null,
	sizes__large__width?: ResolverInputTypes["Event_Thumbnail_sizes__large__width_operator"] | undefined | null,
	sizes__large__height?: ResolverInputTypes["Event_Thumbnail_sizes__large__height_operator"] | undefined | null,
	sizes__large__mimeType?: ResolverInputTypes["Event_Thumbnail_sizes__large__mimeType_operator"] | undefined | null,
	sizes__large__filesize?: ResolverInputTypes["Event_Thumbnail_sizes__large__filesize_operator"] | undefined | null,
	sizes__large__filename?: ResolverInputTypes["Event_Thumbnail_sizes__large__filename_operator"] | undefined | null,
	sizes__small_jpeg__url?: ResolverInputTypes["Event_Thumbnail_sizes__small_jpeg__url_operator"] | undefined | null,
	sizes__small_jpeg__width?: ResolverInputTypes["Event_Thumbnail_sizes__small_jpeg__width_operator"] | undefined | null,
	sizes__small_jpeg__height?: ResolverInputTypes["Event_Thumbnail_sizes__small_jpeg__height_operator"] | undefined | null,
	sizes__small_jpeg__mimeType?: ResolverInputTypes["Event_Thumbnail_sizes__small_jpeg__mimeType_operator"] | undefined | null,
	sizes__small_jpeg__filesize?: ResolverInputTypes["Event_Thumbnail_sizes__small_jpeg__filesize_operator"] | undefined | null,
	sizes__small_jpeg__filename?: ResolverInputTypes["Event_Thumbnail_sizes__small_jpeg__filename_operator"] | undefined | null,
	sizes__medium_jpeg__url?: ResolverInputTypes["Event_Thumbnail_sizes__medium_jpeg__url_operator"] | undefined | null,
	sizes__medium_jpeg__width?: ResolverInputTypes["Event_Thumbnail_sizes__medium_jpeg__width_operator"] | undefined | null,
	sizes__medium_jpeg__height?: ResolverInputTypes["Event_Thumbnail_sizes__medium_jpeg__height_operator"] | undefined | null,
	sizes__medium_jpeg__mimeType?: ResolverInputTypes["Event_Thumbnail_sizes__medium_jpeg__mimeType_operator"] | undefined | null,
	sizes__medium_jpeg__filesize?: ResolverInputTypes["Event_Thumbnail_sizes__medium_jpeg__filesize_operator"] | undefined | null,
	sizes__medium_jpeg__filename?: ResolverInputTypes["Event_Thumbnail_sizes__medium_jpeg__filename_operator"] | undefined | null,
	sizes__large_jpeg__url?: ResolverInputTypes["Event_Thumbnail_sizes__large_jpeg__url_operator"] | undefined | null,
	sizes__large_jpeg__width?: ResolverInputTypes["Event_Thumbnail_sizes__large_jpeg__width_operator"] | undefined | null,
	sizes__large_jpeg__height?: ResolverInputTypes["Event_Thumbnail_sizes__large_jpeg__height_operator"] | undefined | null,
	sizes__large_jpeg__mimeType?: ResolverInputTypes["Event_Thumbnail_sizes__large_jpeg__mimeType_operator"] | undefined | null,
	sizes__large_jpeg__filesize?: ResolverInputTypes["Event_Thumbnail_sizes__large_jpeg__filesize_operator"] | undefined | null,
	sizes__large_jpeg__filename?: ResolverInputTypes["Event_Thumbnail_sizes__large_jpeg__filename_operator"] | undefined | null,
	id?: ResolverInputTypes["Event_Thumbnail_id_operator"] | undefined | null,
	AND?: Array<ResolverInputTypes["Event_Thumbnail_where_and"] | undefined | null> | undefined | null,
	OR?: Array<ResolverInputTypes["Event_Thumbnail_where_or"] | undefined | null> | undefined | null
};
	["Event_Banner_where"]: {
	alt?: ResolverInputTypes["Event_Banner_alt_operator"] | undefined | null,
	updatedAt?: ResolverInputTypes["Event_Banner_updatedAt_operator"] | undefined | null,
	createdAt?: ResolverInputTypes["Event_Banner_createdAt_operator"] | undefined | null,
	url?: ResolverInputTypes["Event_Banner_url_operator"] | undefined | null,
	filename?: ResolverInputTypes["Event_Banner_filename_operator"] | undefined | null,
	mimeType?: ResolverInputTypes["Event_Banner_mimeType_operator"] | undefined | null,
	filesize?: ResolverInputTypes["Event_Banner_filesize_operator"] | undefined | null,
	width?: ResolverInputTypes["Event_Banner_width_operator"] | undefined | null,
	height?: ResolverInputTypes["Event_Banner_height_operator"] | undefined | null,
	focalX?: ResolverInputTypes["Event_Banner_focalX_operator"] | undefined | null,
	focalY?: ResolverInputTypes["Event_Banner_focalY_operator"] | undefined | null,
	sizes__small__url?: ResolverInputTypes["Event_Banner_sizes__small__url_operator"] | undefined | null,
	sizes__small__width?: ResolverInputTypes["Event_Banner_sizes__small__width_operator"] | undefined | null,
	sizes__small__height?: ResolverInputTypes["Event_Banner_sizes__small__height_operator"] | undefined | null,
	sizes__small__mimeType?: ResolverInputTypes["Event_Banner_sizes__small__mimeType_operator"] | undefined | null,
	sizes__small__filesize?: ResolverInputTypes["Event_Banner_sizes__small__filesize_operator"] | undefined | null,
	sizes__small__filename?: ResolverInputTypes["Event_Banner_sizes__small__filename_operator"] | undefined | null,
	sizes__medium__url?: ResolverInputTypes["Event_Banner_sizes__medium__url_operator"] | undefined | null,
	sizes__medium__width?: ResolverInputTypes["Event_Banner_sizes__medium__width_operator"] | undefined | null,
	sizes__medium__height?: ResolverInputTypes["Event_Banner_sizes__medium__height_operator"] | undefined | null,
	sizes__medium__mimeType?: ResolverInputTypes["Event_Banner_sizes__medium__mimeType_operator"] | undefined | null,
	sizes__medium__filesize?: ResolverInputTypes["Event_Banner_sizes__medium__filesize_operator"] | undefined | null,
	sizes__medium__filename?: ResolverInputTypes["Event_Banner_sizes__medium__filename_operator"] | undefined | null,
	sizes__large__url?: ResolverInputTypes["Event_Banner_sizes__large__url_operator"] | undefined | null,
	sizes__large__width?: ResolverInputTypes["Event_Banner_sizes__large__width_operator"] | undefined | null,
	sizes__large__height?: ResolverInputTypes["Event_Banner_sizes__large__height_operator"] | undefined | null,
	sizes__large__mimeType?: ResolverInputTypes["Event_Banner_sizes__large__mimeType_operator"] | undefined | null,
	sizes__large__filesize?: ResolverInputTypes["Event_Banner_sizes__large__filesize_operator"] | undefined | null,
	sizes__large__filename?: ResolverInputTypes["Event_Banner_sizes__large__filename_operator"] | undefined | null,
	sizes__small_jpeg__url?: ResolverInputTypes["Event_Banner_sizes__small_jpeg__url_operator"] | undefined | null,
	sizes__small_jpeg__width?: ResolverInputTypes["Event_Banner_sizes__small_jpeg__width_operator"] | undefined | null,
	sizes__small_jpeg__height?: ResolverInputTypes["Event_Banner_sizes__small_jpeg__height_operator"] | undefined | null,
	sizes__small_jpeg__mimeType?: ResolverInputTypes["Event_Banner_sizes__small_jpeg__mimeType_operator"] | undefined | null,
	sizes__small_jpeg__filesize?: ResolverInputTypes["Event_Banner_sizes__small_jpeg__filesize_operator"] | undefined | null,
	sizes__small_jpeg__filename?: ResolverInputTypes["Event_Banner_sizes__small_jpeg__filename_operator"] | undefined | null,
	sizes__medium_jpeg__url?: ResolverInputTypes["Event_Banner_sizes__medium_jpeg__url_operator"] | undefined | null,
	sizes__medium_jpeg__width?: ResolverInputTypes["Event_Banner_sizes__medium_jpeg__width_operator"] | undefined | null,
	sizes__medium_jpeg__height?: ResolverInputTypes["Event_Banner_sizes__medium_jpeg__height_operator"] | undefined | null,
	sizes__medium_jpeg__mimeType?: ResolverInputTypes["Event_Banner_sizes__medium_jpeg__mimeType_operator"] | undefined | null,
	sizes__medium_jpeg__filesize?: ResolverInputTypes["Event_Banner_sizes__medium_jpeg__filesize_operator"] | undefined | null,
	sizes__medium_jpeg__filename?: ResolverInputTypes["Event_Banner_sizes__medium_jpeg__filename_operator"] | undefined | null,
	sizes__large_jpeg__url?: ResolverInputTypes["Event_Banner_sizes__large_jpeg__url_operator"] | undefined | null,
	sizes__large_jpeg__width?: ResolverInputTypes["Event_Banner_sizes__large_jpeg__width_operator"] | undefined | null,
	sizes__large_jpeg__height?: ResolverInputTypes["Event_Banner_sizes__large_jpeg__height_operator"] | undefined | null,
	sizes__large_jpeg__mimeType?: ResolverInputTypes["Event_Banner_sizes__large_jpeg__mimeType_operator"] | undefined | null,
	sizes__large_jpeg__filesize?: ResolverInputTypes["Event_Banner_sizes__large_jpeg__filesize_operator"] | undefined | null,
	sizes__large_jpeg__filename?: ResolverInputTypes["Event_Banner_sizes__large_jpeg__filename_operator"] | undefined | null,
	id?: ResolverInputTypes["Event_Banner_id_operator"] | undefined | null,
	AND?: Array<ResolverInputTypes["Event_Banner_where_and"] | undefined | null> | undefined | null,
	OR?: Array<ResolverInputTypes["Event_Banner_where_or"] | undefined | null> | undefined | null
};
	["Event_Banner_alt_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Event_Banner_updatedAt_operator"]: {
	equals?: ResolverInputTypes["DateTime"] | undefined | null,
	not_equals?: ResolverInputTypes["DateTime"] | undefined | null,
	greater_than_equal?: ResolverInputTypes["DateTime"] | undefined | null,
	greater_than?: ResolverInputTypes["DateTime"] | undefined | null,
	less_than_equal?: ResolverInputTypes["DateTime"] | undefined | null,
	less_than?: ResolverInputTypes["DateTime"] | undefined | null,
	like?: ResolverInputTypes["DateTime"] | undefined | null,
	exists?: boolean | undefined | null
};
	["Event_Banner_createdAt_operator"]: {
	equals?: ResolverInputTypes["DateTime"] | undefined | null,
	not_equals?: ResolverInputTypes["DateTime"] | undefined | null,
	greater_than_equal?: ResolverInputTypes["DateTime"] | undefined | null,
	greater_than?: ResolverInputTypes["DateTime"] | undefined | null,
	less_than_equal?: ResolverInputTypes["DateTime"] | undefined | null,
	less_than?: ResolverInputTypes["DateTime"] | undefined | null,
	like?: ResolverInputTypes["DateTime"] | undefined | null,
	exists?: boolean | undefined | null
};
	["Event_Banner_url_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Event_Banner_filename_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Event_Banner_mimeType_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Event_Banner_filesize_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Event_Banner_width_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Event_Banner_height_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Event_Banner_focalX_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Event_Banner_focalY_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Event_Banner_sizes__small__url_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Event_Banner_sizes__small__width_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Event_Banner_sizes__small__height_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Event_Banner_sizes__small__mimeType_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Event_Banner_sizes__small__filesize_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Event_Banner_sizes__small__filename_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Event_Banner_sizes__medium__url_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Event_Banner_sizes__medium__width_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Event_Banner_sizes__medium__height_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Event_Banner_sizes__medium__mimeType_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Event_Banner_sizes__medium__filesize_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Event_Banner_sizes__medium__filename_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Event_Banner_sizes__large__url_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Event_Banner_sizes__large__width_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Event_Banner_sizes__large__height_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Event_Banner_sizes__large__mimeType_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Event_Banner_sizes__large__filesize_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Event_Banner_sizes__large__filename_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Event_Banner_sizes__small_jpeg__url_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Event_Banner_sizes__small_jpeg__width_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Event_Banner_sizes__small_jpeg__height_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Event_Banner_sizes__small_jpeg__mimeType_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Event_Banner_sizes__small_jpeg__filesize_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Event_Banner_sizes__small_jpeg__filename_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Event_Banner_sizes__medium_jpeg__url_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Event_Banner_sizes__medium_jpeg__width_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Event_Banner_sizes__medium_jpeg__height_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Event_Banner_sizes__medium_jpeg__mimeType_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Event_Banner_sizes__medium_jpeg__filesize_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Event_Banner_sizes__medium_jpeg__filename_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Event_Banner_sizes__large_jpeg__url_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Event_Banner_sizes__large_jpeg__width_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Event_Banner_sizes__large_jpeg__height_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Event_Banner_sizes__large_jpeg__mimeType_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Event_Banner_sizes__large_jpeg__filesize_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Event_Banner_sizes__large_jpeg__filename_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Event_Banner_id_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Event_Banner_where_and"]: {
	alt?: ResolverInputTypes["Event_Banner_alt_operator"] | undefined | null,
	updatedAt?: ResolverInputTypes["Event_Banner_updatedAt_operator"] | undefined | null,
	createdAt?: ResolverInputTypes["Event_Banner_createdAt_operator"] | undefined | null,
	url?: ResolverInputTypes["Event_Banner_url_operator"] | undefined | null,
	filename?: ResolverInputTypes["Event_Banner_filename_operator"] | undefined | null,
	mimeType?: ResolverInputTypes["Event_Banner_mimeType_operator"] | undefined | null,
	filesize?: ResolverInputTypes["Event_Banner_filesize_operator"] | undefined | null,
	width?: ResolverInputTypes["Event_Banner_width_operator"] | undefined | null,
	height?: ResolverInputTypes["Event_Banner_height_operator"] | undefined | null,
	focalX?: ResolverInputTypes["Event_Banner_focalX_operator"] | undefined | null,
	focalY?: ResolverInputTypes["Event_Banner_focalY_operator"] | undefined | null,
	sizes__small__url?: ResolverInputTypes["Event_Banner_sizes__small__url_operator"] | undefined | null,
	sizes__small__width?: ResolverInputTypes["Event_Banner_sizes__small__width_operator"] | undefined | null,
	sizes__small__height?: ResolverInputTypes["Event_Banner_sizes__small__height_operator"] | undefined | null,
	sizes__small__mimeType?: ResolverInputTypes["Event_Banner_sizes__small__mimeType_operator"] | undefined | null,
	sizes__small__filesize?: ResolverInputTypes["Event_Banner_sizes__small__filesize_operator"] | undefined | null,
	sizes__small__filename?: ResolverInputTypes["Event_Banner_sizes__small__filename_operator"] | undefined | null,
	sizes__medium__url?: ResolverInputTypes["Event_Banner_sizes__medium__url_operator"] | undefined | null,
	sizes__medium__width?: ResolverInputTypes["Event_Banner_sizes__medium__width_operator"] | undefined | null,
	sizes__medium__height?: ResolverInputTypes["Event_Banner_sizes__medium__height_operator"] | undefined | null,
	sizes__medium__mimeType?: ResolverInputTypes["Event_Banner_sizes__medium__mimeType_operator"] | undefined | null,
	sizes__medium__filesize?: ResolverInputTypes["Event_Banner_sizes__medium__filesize_operator"] | undefined | null,
	sizes__medium__filename?: ResolverInputTypes["Event_Banner_sizes__medium__filename_operator"] | undefined | null,
	sizes__large__url?: ResolverInputTypes["Event_Banner_sizes__large__url_operator"] | undefined | null,
	sizes__large__width?: ResolverInputTypes["Event_Banner_sizes__large__width_operator"] | undefined | null,
	sizes__large__height?: ResolverInputTypes["Event_Banner_sizes__large__height_operator"] | undefined | null,
	sizes__large__mimeType?: ResolverInputTypes["Event_Banner_sizes__large__mimeType_operator"] | undefined | null,
	sizes__large__filesize?: ResolverInputTypes["Event_Banner_sizes__large__filesize_operator"] | undefined | null,
	sizes__large__filename?: ResolverInputTypes["Event_Banner_sizes__large__filename_operator"] | undefined | null,
	sizes__small_jpeg__url?: ResolverInputTypes["Event_Banner_sizes__small_jpeg__url_operator"] | undefined | null,
	sizes__small_jpeg__width?: ResolverInputTypes["Event_Banner_sizes__small_jpeg__width_operator"] | undefined | null,
	sizes__small_jpeg__height?: ResolverInputTypes["Event_Banner_sizes__small_jpeg__height_operator"] | undefined | null,
	sizes__small_jpeg__mimeType?: ResolverInputTypes["Event_Banner_sizes__small_jpeg__mimeType_operator"] | undefined | null,
	sizes__small_jpeg__filesize?: ResolverInputTypes["Event_Banner_sizes__small_jpeg__filesize_operator"] | undefined | null,
	sizes__small_jpeg__filename?: ResolverInputTypes["Event_Banner_sizes__small_jpeg__filename_operator"] | undefined | null,
	sizes__medium_jpeg__url?: ResolverInputTypes["Event_Banner_sizes__medium_jpeg__url_operator"] | undefined | null,
	sizes__medium_jpeg__width?: ResolverInputTypes["Event_Banner_sizes__medium_jpeg__width_operator"] | undefined | null,
	sizes__medium_jpeg__height?: ResolverInputTypes["Event_Banner_sizes__medium_jpeg__height_operator"] | undefined | null,
	sizes__medium_jpeg__mimeType?: ResolverInputTypes["Event_Banner_sizes__medium_jpeg__mimeType_operator"] | undefined | null,
	sizes__medium_jpeg__filesize?: ResolverInputTypes["Event_Banner_sizes__medium_jpeg__filesize_operator"] | undefined | null,
	sizes__medium_jpeg__filename?: ResolverInputTypes["Event_Banner_sizes__medium_jpeg__filename_operator"] | undefined | null,
	sizes__large_jpeg__url?: ResolverInputTypes["Event_Banner_sizes__large_jpeg__url_operator"] | undefined | null,
	sizes__large_jpeg__width?: ResolverInputTypes["Event_Banner_sizes__large_jpeg__width_operator"] | undefined | null,
	sizes__large_jpeg__height?: ResolverInputTypes["Event_Banner_sizes__large_jpeg__height_operator"] | undefined | null,
	sizes__large_jpeg__mimeType?: ResolverInputTypes["Event_Banner_sizes__large_jpeg__mimeType_operator"] | undefined | null,
	sizes__large_jpeg__filesize?: ResolverInputTypes["Event_Banner_sizes__large_jpeg__filesize_operator"] | undefined | null,
	sizes__large_jpeg__filename?: ResolverInputTypes["Event_Banner_sizes__large_jpeg__filename_operator"] | undefined | null,
	id?: ResolverInputTypes["Event_Banner_id_operator"] | undefined | null,
	AND?: Array<ResolverInputTypes["Event_Banner_where_and"] | undefined | null> | undefined | null,
	OR?: Array<ResolverInputTypes["Event_Banner_where_or"] | undefined | null> | undefined | null
};
	["Event_Banner_where_or"]: {
	alt?: ResolverInputTypes["Event_Banner_alt_operator"] | undefined | null,
	updatedAt?: ResolverInputTypes["Event_Banner_updatedAt_operator"] | undefined | null,
	createdAt?: ResolverInputTypes["Event_Banner_createdAt_operator"] | undefined | null,
	url?: ResolverInputTypes["Event_Banner_url_operator"] | undefined | null,
	filename?: ResolverInputTypes["Event_Banner_filename_operator"] | undefined | null,
	mimeType?: ResolverInputTypes["Event_Banner_mimeType_operator"] | undefined | null,
	filesize?: ResolverInputTypes["Event_Banner_filesize_operator"] | undefined | null,
	width?: ResolverInputTypes["Event_Banner_width_operator"] | undefined | null,
	height?: ResolverInputTypes["Event_Banner_height_operator"] | undefined | null,
	focalX?: ResolverInputTypes["Event_Banner_focalX_operator"] | undefined | null,
	focalY?: ResolverInputTypes["Event_Banner_focalY_operator"] | undefined | null,
	sizes__small__url?: ResolverInputTypes["Event_Banner_sizes__small__url_operator"] | undefined | null,
	sizes__small__width?: ResolverInputTypes["Event_Banner_sizes__small__width_operator"] | undefined | null,
	sizes__small__height?: ResolverInputTypes["Event_Banner_sizes__small__height_operator"] | undefined | null,
	sizes__small__mimeType?: ResolverInputTypes["Event_Banner_sizes__small__mimeType_operator"] | undefined | null,
	sizes__small__filesize?: ResolverInputTypes["Event_Banner_sizes__small__filesize_operator"] | undefined | null,
	sizes__small__filename?: ResolverInputTypes["Event_Banner_sizes__small__filename_operator"] | undefined | null,
	sizes__medium__url?: ResolverInputTypes["Event_Banner_sizes__medium__url_operator"] | undefined | null,
	sizes__medium__width?: ResolverInputTypes["Event_Banner_sizes__medium__width_operator"] | undefined | null,
	sizes__medium__height?: ResolverInputTypes["Event_Banner_sizes__medium__height_operator"] | undefined | null,
	sizes__medium__mimeType?: ResolverInputTypes["Event_Banner_sizes__medium__mimeType_operator"] | undefined | null,
	sizes__medium__filesize?: ResolverInputTypes["Event_Banner_sizes__medium__filesize_operator"] | undefined | null,
	sizes__medium__filename?: ResolverInputTypes["Event_Banner_sizes__medium__filename_operator"] | undefined | null,
	sizes__large__url?: ResolverInputTypes["Event_Banner_sizes__large__url_operator"] | undefined | null,
	sizes__large__width?: ResolverInputTypes["Event_Banner_sizes__large__width_operator"] | undefined | null,
	sizes__large__height?: ResolverInputTypes["Event_Banner_sizes__large__height_operator"] | undefined | null,
	sizes__large__mimeType?: ResolverInputTypes["Event_Banner_sizes__large__mimeType_operator"] | undefined | null,
	sizes__large__filesize?: ResolverInputTypes["Event_Banner_sizes__large__filesize_operator"] | undefined | null,
	sizes__large__filename?: ResolverInputTypes["Event_Banner_sizes__large__filename_operator"] | undefined | null,
	sizes__small_jpeg__url?: ResolverInputTypes["Event_Banner_sizes__small_jpeg__url_operator"] | undefined | null,
	sizes__small_jpeg__width?: ResolverInputTypes["Event_Banner_sizes__small_jpeg__width_operator"] | undefined | null,
	sizes__small_jpeg__height?: ResolverInputTypes["Event_Banner_sizes__small_jpeg__height_operator"] | undefined | null,
	sizes__small_jpeg__mimeType?: ResolverInputTypes["Event_Banner_sizes__small_jpeg__mimeType_operator"] | undefined | null,
	sizes__small_jpeg__filesize?: ResolverInputTypes["Event_Banner_sizes__small_jpeg__filesize_operator"] | undefined | null,
	sizes__small_jpeg__filename?: ResolverInputTypes["Event_Banner_sizes__small_jpeg__filename_operator"] | undefined | null,
	sizes__medium_jpeg__url?: ResolverInputTypes["Event_Banner_sizes__medium_jpeg__url_operator"] | undefined | null,
	sizes__medium_jpeg__width?: ResolverInputTypes["Event_Banner_sizes__medium_jpeg__width_operator"] | undefined | null,
	sizes__medium_jpeg__height?: ResolverInputTypes["Event_Banner_sizes__medium_jpeg__height_operator"] | undefined | null,
	sizes__medium_jpeg__mimeType?: ResolverInputTypes["Event_Banner_sizes__medium_jpeg__mimeType_operator"] | undefined | null,
	sizes__medium_jpeg__filesize?: ResolverInputTypes["Event_Banner_sizes__medium_jpeg__filesize_operator"] | undefined | null,
	sizes__medium_jpeg__filename?: ResolverInputTypes["Event_Banner_sizes__medium_jpeg__filename_operator"] | undefined | null,
	sizes__large_jpeg__url?: ResolverInputTypes["Event_Banner_sizes__large_jpeg__url_operator"] | undefined | null,
	sizes__large_jpeg__width?: ResolverInputTypes["Event_Banner_sizes__large_jpeg__width_operator"] | undefined | null,
	sizes__large_jpeg__height?: ResolverInputTypes["Event_Banner_sizes__large_jpeg__height_operator"] | undefined | null,
	sizes__large_jpeg__mimeType?: ResolverInputTypes["Event_Banner_sizes__large_jpeg__mimeType_operator"] | undefined | null,
	sizes__large_jpeg__filesize?: ResolverInputTypes["Event_Banner_sizes__large_jpeg__filesize_operator"] | undefined | null,
	sizes__large_jpeg__filename?: ResolverInputTypes["Event_Banner_sizes__large_jpeg__filename_operator"] | undefined | null,
	id?: ResolverInputTypes["Event_Banner_id_operator"] | undefined | null,
	AND?: Array<ResolverInputTypes["Event_Banner_where_and"] | undefined | null> | undefined | null,
	OR?: Array<ResolverInputTypes["Event_Banner_where_or"] | undefined | null> | undefined | null
};
	["Event_MobileBanner_where"]: {
	alt?: ResolverInputTypes["Event_MobileBanner_alt_operator"] | undefined | null,
	updatedAt?: ResolverInputTypes["Event_MobileBanner_updatedAt_operator"] | undefined | null,
	createdAt?: ResolverInputTypes["Event_MobileBanner_createdAt_operator"] | undefined | null,
	url?: ResolverInputTypes["Event_MobileBanner_url_operator"] | undefined | null,
	filename?: ResolverInputTypes["Event_MobileBanner_filename_operator"] | undefined | null,
	mimeType?: ResolverInputTypes["Event_MobileBanner_mimeType_operator"] | undefined | null,
	filesize?: ResolverInputTypes["Event_MobileBanner_filesize_operator"] | undefined | null,
	width?: ResolverInputTypes["Event_MobileBanner_width_operator"] | undefined | null,
	height?: ResolverInputTypes["Event_MobileBanner_height_operator"] | undefined | null,
	focalX?: ResolverInputTypes["Event_MobileBanner_focalX_operator"] | undefined | null,
	focalY?: ResolverInputTypes["Event_MobileBanner_focalY_operator"] | undefined | null,
	sizes__small__url?: ResolverInputTypes["Event_MobileBanner_sizes__small__url_operator"] | undefined | null,
	sizes__small__width?: ResolverInputTypes["Event_MobileBanner_sizes__small__width_operator"] | undefined | null,
	sizes__small__height?: ResolverInputTypes["Event_MobileBanner_sizes__small__height_operator"] | undefined | null,
	sizes__small__mimeType?: ResolverInputTypes["Event_MobileBanner_sizes__small__mimeType_operator"] | undefined | null,
	sizes__small__filesize?: ResolverInputTypes["Event_MobileBanner_sizes__small__filesize_operator"] | undefined | null,
	sizes__small__filename?: ResolverInputTypes["Event_MobileBanner_sizes__small__filename_operator"] | undefined | null,
	sizes__medium__url?: ResolverInputTypes["Event_MobileBanner_sizes__medium__url_operator"] | undefined | null,
	sizes__medium__width?: ResolverInputTypes["Event_MobileBanner_sizes__medium__width_operator"] | undefined | null,
	sizes__medium__height?: ResolverInputTypes["Event_MobileBanner_sizes__medium__height_operator"] | undefined | null,
	sizes__medium__mimeType?: ResolverInputTypes["Event_MobileBanner_sizes__medium__mimeType_operator"] | undefined | null,
	sizes__medium__filesize?: ResolverInputTypes["Event_MobileBanner_sizes__medium__filesize_operator"] | undefined | null,
	sizes__medium__filename?: ResolverInputTypes["Event_MobileBanner_sizes__medium__filename_operator"] | undefined | null,
	sizes__large__url?: ResolverInputTypes["Event_MobileBanner_sizes__large__url_operator"] | undefined | null,
	sizes__large__width?: ResolverInputTypes["Event_MobileBanner_sizes__large__width_operator"] | undefined | null,
	sizes__large__height?: ResolverInputTypes["Event_MobileBanner_sizes__large__height_operator"] | undefined | null,
	sizes__large__mimeType?: ResolverInputTypes["Event_MobileBanner_sizes__large__mimeType_operator"] | undefined | null,
	sizes__large__filesize?: ResolverInputTypes["Event_MobileBanner_sizes__large__filesize_operator"] | undefined | null,
	sizes__large__filename?: ResolverInputTypes["Event_MobileBanner_sizes__large__filename_operator"] | undefined | null,
	sizes__small_jpeg__url?: ResolverInputTypes["Event_MobileBanner_sizes__small_jpeg__url_operator"] | undefined | null,
	sizes__small_jpeg__width?: ResolverInputTypes["Event_MobileBanner_sizes__small_jpeg__width_operator"] | undefined | null,
	sizes__small_jpeg__height?: ResolverInputTypes["Event_MobileBanner_sizes__small_jpeg__height_operator"] | undefined | null,
	sizes__small_jpeg__mimeType?: ResolverInputTypes["Event_MobileBanner_sizes__small_jpeg__mimeType_operator"] | undefined | null,
	sizes__small_jpeg__filesize?: ResolverInputTypes["Event_MobileBanner_sizes__small_jpeg__filesize_operator"] | undefined | null,
	sizes__small_jpeg__filename?: ResolverInputTypes["Event_MobileBanner_sizes__small_jpeg__filename_operator"] | undefined | null,
	sizes__medium_jpeg__url?: ResolverInputTypes["Event_MobileBanner_sizes__medium_jpeg__url_operator"] | undefined | null,
	sizes__medium_jpeg__width?: ResolverInputTypes["Event_MobileBanner_sizes__medium_jpeg__width_operator"] | undefined | null,
	sizes__medium_jpeg__height?: ResolverInputTypes["Event_MobileBanner_sizes__medium_jpeg__height_operator"] | undefined | null,
	sizes__medium_jpeg__mimeType?: ResolverInputTypes["Event_MobileBanner_sizes__medium_jpeg__mimeType_operator"] | undefined | null,
	sizes__medium_jpeg__filesize?: ResolverInputTypes["Event_MobileBanner_sizes__medium_jpeg__filesize_operator"] | undefined | null,
	sizes__medium_jpeg__filename?: ResolverInputTypes["Event_MobileBanner_sizes__medium_jpeg__filename_operator"] | undefined | null,
	sizes__large_jpeg__url?: ResolverInputTypes["Event_MobileBanner_sizes__large_jpeg__url_operator"] | undefined | null,
	sizes__large_jpeg__width?: ResolverInputTypes["Event_MobileBanner_sizes__large_jpeg__width_operator"] | undefined | null,
	sizes__large_jpeg__height?: ResolverInputTypes["Event_MobileBanner_sizes__large_jpeg__height_operator"] | undefined | null,
	sizes__large_jpeg__mimeType?: ResolverInputTypes["Event_MobileBanner_sizes__large_jpeg__mimeType_operator"] | undefined | null,
	sizes__large_jpeg__filesize?: ResolverInputTypes["Event_MobileBanner_sizes__large_jpeg__filesize_operator"] | undefined | null,
	sizes__large_jpeg__filename?: ResolverInputTypes["Event_MobileBanner_sizes__large_jpeg__filename_operator"] | undefined | null,
	id?: ResolverInputTypes["Event_MobileBanner_id_operator"] | undefined | null,
	AND?: Array<ResolverInputTypes["Event_MobileBanner_where_and"] | undefined | null> | undefined | null,
	OR?: Array<ResolverInputTypes["Event_MobileBanner_where_or"] | undefined | null> | undefined | null
};
	["Event_MobileBanner_alt_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Event_MobileBanner_updatedAt_operator"]: {
	equals?: ResolverInputTypes["DateTime"] | undefined | null,
	not_equals?: ResolverInputTypes["DateTime"] | undefined | null,
	greater_than_equal?: ResolverInputTypes["DateTime"] | undefined | null,
	greater_than?: ResolverInputTypes["DateTime"] | undefined | null,
	less_than_equal?: ResolverInputTypes["DateTime"] | undefined | null,
	less_than?: ResolverInputTypes["DateTime"] | undefined | null,
	like?: ResolverInputTypes["DateTime"] | undefined | null,
	exists?: boolean | undefined | null
};
	["Event_MobileBanner_createdAt_operator"]: {
	equals?: ResolverInputTypes["DateTime"] | undefined | null,
	not_equals?: ResolverInputTypes["DateTime"] | undefined | null,
	greater_than_equal?: ResolverInputTypes["DateTime"] | undefined | null,
	greater_than?: ResolverInputTypes["DateTime"] | undefined | null,
	less_than_equal?: ResolverInputTypes["DateTime"] | undefined | null,
	less_than?: ResolverInputTypes["DateTime"] | undefined | null,
	like?: ResolverInputTypes["DateTime"] | undefined | null,
	exists?: boolean | undefined | null
};
	["Event_MobileBanner_url_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Event_MobileBanner_filename_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Event_MobileBanner_mimeType_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Event_MobileBanner_filesize_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Event_MobileBanner_width_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Event_MobileBanner_height_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Event_MobileBanner_focalX_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Event_MobileBanner_focalY_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Event_MobileBanner_sizes__small__url_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Event_MobileBanner_sizes__small__width_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Event_MobileBanner_sizes__small__height_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Event_MobileBanner_sizes__small__mimeType_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Event_MobileBanner_sizes__small__filesize_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Event_MobileBanner_sizes__small__filename_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Event_MobileBanner_sizes__medium__url_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Event_MobileBanner_sizes__medium__width_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Event_MobileBanner_sizes__medium__height_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Event_MobileBanner_sizes__medium__mimeType_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Event_MobileBanner_sizes__medium__filesize_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Event_MobileBanner_sizes__medium__filename_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Event_MobileBanner_sizes__large__url_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Event_MobileBanner_sizes__large__width_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Event_MobileBanner_sizes__large__height_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Event_MobileBanner_sizes__large__mimeType_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Event_MobileBanner_sizes__large__filesize_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Event_MobileBanner_sizes__large__filename_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Event_MobileBanner_sizes__small_jpeg__url_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Event_MobileBanner_sizes__small_jpeg__width_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Event_MobileBanner_sizes__small_jpeg__height_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Event_MobileBanner_sizes__small_jpeg__mimeType_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Event_MobileBanner_sizes__small_jpeg__filesize_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Event_MobileBanner_sizes__small_jpeg__filename_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Event_MobileBanner_sizes__medium_jpeg__url_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Event_MobileBanner_sizes__medium_jpeg__width_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Event_MobileBanner_sizes__medium_jpeg__height_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Event_MobileBanner_sizes__medium_jpeg__mimeType_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Event_MobileBanner_sizes__medium_jpeg__filesize_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Event_MobileBanner_sizes__medium_jpeg__filename_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Event_MobileBanner_sizes__large_jpeg__url_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Event_MobileBanner_sizes__large_jpeg__width_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Event_MobileBanner_sizes__large_jpeg__height_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Event_MobileBanner_sizes__large_jpeg__mimeType_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Event_MobileBanner_sizes__large_jpeg__filesize_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Event_MobileBanner_sizes__large_jpeg__filename_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Event_MobileBanner_id_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Event_MobileBanner_where_and"]: {
	alt?: ResolverInputTypes["Event_MobileBanner_alt_operator"] | undefined | null,
	updatedAt?: ResolverInputTypes["Event_MobileBanner_updatedAt_operator"] | undefined | null,
	createdAt?: ResolverInputTypes["Event_MobileBanner_createdAt_operator"] | undefined | null,
	url?: ResolverInputTypes["Event_MobileBanner_url_operator"] | undefined | null,
	filename?: ResolverInputTypes["Event_MobileBanner_filename_operator"] | undefined | null,
	mimeType?: ResolverInputTypes["Event_MobileBanner_mimeType_operator"] | undefined | null,
	filesize?: ResolverInputTypes["Event_MobileBanner_filesize_operator"] | undefined | null,
	width?: ResolverInputTypes["Event_MobileBanner_width_operator"] | undefined | null,
	height?: ResolverInputTypes["Event_MobileBanner_height_operator"] | undefined | null,
	focalX?: ResolverInputTypes["Event_MobileBanner_focalX_operator"] | undefined | null,
	focalY?: ResolverInputTypes["Event_MobileBanner_focalY_operator"] | undefined | null,
	sizes__small__url?: ResolverInputTypes["Event_MobileBanner_sizes__small__url_operator"] | undefined | null,
	sizes__small__width?: ResolverInputTypes["Event_MobileBanner_sizes__small__width_operator"] | undefined | null,
	sizes__small__height?: ResolverInputTypes["Event_MobileBanner_sizes__small__height_operator"] | undefined | null,
	sizes__small__mimeType?: ResolverInputTypes["Event_MobileBanner_sizes__small__mimeType_operator"] | undefined | null,
	sizes__small__filesize?: ResolverInputTypes["Event_MobileBanner_sizes__small__filesize_operator"] | undefined | null,
	sizes__small__filename?: ResolverInputTypes["Event_MobileBanner_sizes__small__filename_operator"] | undefined | null,
	sizes__medium__url?: ResolverInputTypes["Event_MobileBanner_sizes__medium__url_operator"] | undefined | null,
	sizes__medium__width?: ResolverInputTypes["Event_MobileBanner_sizes__medium__width_operator"] | undefined | null,
	sizes__medium__height?: ResolverInputTypes["Event_MobileBanner_sizes__medium__height_operator"] | undefined | null,
	sizes__medium__mimeType?: ResolverInputTypes["Event_MobileBanner_sizes__medium__mimeType_operator"] | undefined | null,
	sizes__medium__filesize?: ResolverInputTypes["Event_MobileBanner_sizes__medium__filesize_operator"] | undefined | null,
	sizes__medium__filename?: ResolverInputTypes["Event_MobileBanner_sizes__medium__filename_operator"] | undefined | null,
	sizes__large__url?: ResolverInputTypes["Event_MobileBanner_sizes__large__url_operator"] | undefined | null,
	sizes__large__width?: ResolverInputTypes["Event_MobileBanner_sizes__large__width_operator"] | undefined | null,
	sizes__large__height?: ResolverInputTypes["Event_MobileBanner_sizes__large__height_operator"] | undefined | null,
	sizes__large__mimeType?: ResolverInputTypes["Event_MobileBanner_sizes__large__mimeType_operator"] | undefined | null,
	sizes__large__filesize?: ResolverInputTypes["Event_MobileBanner_sizes__large__filesize_operator"] | undefined | null,
	sizes__large__filename?: ResolverInputTypes["Event_MobileBanner_sizes__large__filename_operator"] | undefined | null,
	sizes__small_jpeg__url?: ResolverInputTypes["Event_MobileBanner_sizes__small_jpeg__url_operator"] | undefined | null,
	sizes__small_jpeg__width?: ResolverInputTypes["Event_MobileBanner_sizes__small_jpeg__width_operator"] | undefined | null,
	sizes__small_jpeg__height?: ResolverInputTypes["Event_MobileBanner_sizes__small_jpeg__height_operator"] | undefined | null,
	sizes__small_jpeg__mimeType?: ResolverInputTypes["Event_MobileBanner_sizes__small_jpeg__mimeType_operator"] | undefined | null,
	sizes__small_jpeg__filesize?: ResolverInputTypes["Event_MobileBanner_sizes__small_jpeg__filesize_operator"] | undefined | null,
	sizes__small_jpeg__filename?: ResolverInputTypes["Event_MobileBanner_sizes__small_jpeg__filename_operator"] | undefined | null,
	sizes__medium_jpeg__url?: ResolverInputTypes["Event_MobileBanner_sizes__medium_jpeg__url_operator"] | undefined | null,
	sizes__medium_jpeg__width?: ResolverInputTypes["Event_MobileBanner_sizes__medium_jpeg__width_operator"] | undefined | null,
	sizes__medium_jpeg__height?: ResolverInputTypes["Event_MobileBanner_sizes__medium_jpeg__height_operator"] | undefined | null,
	sizes__medium_jpeg__mimeType?: ResolverInputTypes["Event_MobileBanner_sizes__medium_jpeg__mimeType_operator"] | undefined | null,
	sizes__medium_jpeg__filesize?: ResolverInputTypes["Event_MobileBanner_sizes__medium_jpeg__filesize_operator"] | undefined | null,
	sizes__medium_jpeg__filename?: ResolverInputTypes["Event_MobileBanner_sizes__medium_jpeg__filename_operator"] | undefined | null,
	sizes__large_jpeg__url?: ResolverInputTypes["Event_MobileBanner_sizes__large_jpeg__url_operator"] | undefined | null,
	sizes__large_jpeg__width?: ResolverInputTypes["Event_MobileBanner_sizes__large_jpeg__width_operator"] | undefined | null,
	sizes__large_jpeg__height?: ResolverInputTypes["Event_MobileBanner_sizes__large_jpeg__height_operator"] | undefined | null,
	sizes__large_jpeg__mimeType?: ResolverInputTypes["Event_MobileBanner_sizes__large_jpeg__mimeType_operator"] | undefined | null,
	sizes__large_jpeg__filesize?: ResolverInputTypes["Event_MobileBanner_sizes__large_jpeg__filesize_operator"] | undefined | null,
	sizes__large_jpeg__filename?: ResolverInputTypes["Event_MobileBanner_sizes__large_jpeg__filename_operator"] | undefined | null,
	id?: ResolverInputTypes["Event_MobileBanner_id_operator"] | undefined | null,
	AND?: Array<ResolverInputTypes["Event_MobileBanner_where_and"] | undefined | null> | undefined | null,
	OR?: Array<ResolverInputTypes["Event_MobileBanner_where_or"] | undefined | null> | undefined | null
};
	["Event_MobileBanner_where_or"]: {
	alt?: ResolverInputTypes["Event_MobileBanner_alt_operator"] | undefined | null,
	updatedAt?: ResolverInputTypes["Event_MobileBanner_updatedAt_operator"] | undefined | null,
	createdAt?: ResolverInputTypes["Event_MobileBanner_createdAt_operator"] | undefined | null,
	url?: ResolverInputTypes["Event_MobileBanner_url_operator"] | undefined | null,
	filename?: ResolverInputTypes["Event_MobileBanner_filename_operator"] | undefined | null,
	mimeType?: ResolverInputTypes["Event_MobileBanner_mimeType_operator"] | undefined | null,
	filesize?: ResolverInputTypes["Event_MobileBanner_filesize_operator"] | undefined | null,
	width?: ResolverInputTypes["Event_MobileBanner_width_operator"] | undefined | null,
	height?: ResolverInputTypes["Event_MobileBanner_height_operator"] | undefined | null,
	focalX?: ResolverInputTypes["Event_MobileBanner_focalX_operator"] | undefined | null,
	focalY?: ResolverInputTypes["Event_MobileBanner_focalY_operator"] | undefined | null,
	sizes__small__url?: ResolverInputTypes["Event_MobileBanner_sizes__small__url_operator"] | undefined | null,
	sizes__small__width?: ResolverInputTypes["Event_MobileBanner_sizes__small__width_operator"] | undefined | null,
	sizes__small__height?: ResolverInputTypes["Event_MobileBanner_sizes__small__height_operator"] | undefined | null,
	sizes__small__mimeType?: ResolverInputTypes["Event_MobileBanner_sizes__small__mimeType_operator"] | undefined | null,
	sizes__small__filesize?: ResolverInputTypes["Event_MobileBanner_sizes__small__filesize_operator"] | undefined | null,
	sizes__small__filename?: ResolverInputTypes["Event_MobileBanner_sizes__small__filename_operator"] | undefined | null,
	sizes__medium__url?: ResolverInputTypes["Event_MobileBanner_sizes__medium__url_operator"] | undefined | null,
	sizes__medium__width?: ResolverInputTypes["Event_MobileBanner_sizes__medium__width_operator"] | undefined | null,
	sizes__medium__height?: ResolverInputTypes["Event_MobileBanner_sizes__medium__height_operator"] | undefined | null,
	sizes__medium__mimeType?: ResolverInputTypes["Event_MobileBanner_sizes__medium__mimeType_operator"] | undefined | null,
	sizes__medium__filesize?: ResolverInputTypes["Event_MobileBanner_sizes__medium__filesize_operator"] | undefined | null,
	sizes__medium__filename?: ResolverInputTypes["Event_MobileBanner_sizes__medium__filename_operator"] | undefined | null,
	sizes__large__url?: ResolverInputTypes["Event_MobileBanner_sizes__large__url_operator"] | undefined | null,
	sizes__large__width?: ResolverInputTypes["Event_MobileBanner_sizes__large__width_operator"] | undefined | null,
	sizes__large__height?: ResolverInputTypes["Event_MobileBanner_sizes__large__height_operator"] | undefined | null,
	sizes__large__mimeType?: ResolverInputTypes["Event_MobileBanner_sizes__large__mimeType_operator"] | undefined | null,
	sizes__large__filesize?: ResolverInputTypes["Event_MobileBanner_sizes__large__filesize_operator"] | undefined | null,
	sizes__large__filename?: ResolverInputTypes["Event_MobileBanner_sizes__large__filename_operator"] | undefined | null,
	sizes__small_jpeg__url?: ResolverInputTypes["Event_MobileBanner_sizes__small_jpeg__url_operator"] | undefined | null,
	sizes__small_jpeg__width?: ResolverInputTypes["Event_MobileBanner_sizes__small_jpeg__width_operator"] | undefined | null,
	sizes__small_jpeg__height?: ResolverInputTypes["Event_MobileBanner_sizes__small_jpeg__height_operator"] | undefined | null,
	sizes__small_jpeg__mimeType?: ResolverInputTypes["Event_MobileBanner_sizes__small_jpeg__mimeType_operator"] | undefined | null,
	sizes__small_jpeg__filesize?: ResolverInputTypes["Event_MobileBanner_sizes__small_jpeg__filesize_operator"] | undefined | null,
	sizes__small_jpeg__filename?: ResolverInputTypes["Event_MobileBanner_sizes__small_jpeg__filename_operator"] | undefined | null,
	sizes__medium_jpeg__url?: ResolverInputTypes["Event_MobileBanner_sizes__medium_jpeg__url_operator"] | undefined | null,
	sizes__medium_jpeg__width?: ResolverInputTypes["Event_MobileBanner_sizes__medium_jpeg__width_operator"] | undefined | null,
	sizes__medium_jpeg__height?: ResolverInputTypes["Event_MobileBanner_sizes__medium_jpeg__height_operator"] | undefined | null,
	sizes__medium_jpeg__mimeType?: ResolverInputTypes["Event_MobileBanner_sizes__medium_jpeg__mimeType_operator"] | undefined | null,
	sizes__medium_jpeg__filesize?: ResolverInputTypes["Event_MobileBanner_sizes__medium_jpeg__filesize_operator"] | undefined | null,
	sizes__medium_jpeg__filename?: ResolverInputTypes["Event_MobileBanner_sizes__medium_jpeg__filename_operator"] | undefined | null,
	sizes__large_jpeg__url?: ResolverInputTypes["Event_MobileBanner_sizes__large_jpeg__url_operator"] | undefined | null,
	sizes__large_jpeg__width?: ResolverInputTypes["Event_MobileBanner_sizes__large_jpeg__width_operator"] | undefined | null,
	sizes__large_jpeg__height?: ResolverInputTypes["Event_MobileBanner_sizes__large_jpeg__height_operator"] | undefined | null,
	sizes__large_jpeg__mimeType?: ResolverInputTypes["Event_MobileBanner_sizes__large_jpeg__mimeType_operator"] | undefined | null,
	sizes__large_jpeg__filesize?: ResolverInputTypes["Event_MobileBanner_sizes__large_jpeg__filesize_operator"] | undefined | null,
	sizes__large_jpeg__filename?: ResolverInputTypes["Event_MobileBanner_sizes__large_jpeg__filename_operator"] | undefined | null,
	id?: ResolverInputTypes["Event_MobileBanner_id_operator"] | undefined | null,
	AND?: Array<ResolverInputTypes["Event_MobileBanner_where_and"] | undefined | null> | undefined | null,
	OR?: Array<ResolverInputTypes["Event_MobileBanner_where_or"] | undefined | null> | undefined | null
};
	["Event_Sections"]: AliasType<{
	startDatetime?:boolean | `@${string}`,
	endDatetime?:boolean | `@${string}`,
	fullDay?:boolean | `@${string}`,
	repeat?:boolean | `@${string}`,
	toThisDay?:boolean | `@${string}`,
	recurrance?:ResolverInputTypes["Event_Sections_Recurrance"],
	sameLocation?:boolean | `@${string}`,
location?: [{	locale?: ResolverInputTypes["LocaleInputType"] | undefined | null,	fallbackLocale?: ResolverInputTypes["FallbackLocaleInputType"] | undefined | null},ResolverInputTypes["Location"]],
district?: [{	locale?: ResolverInputTypes["LocaleInputType"] | undefined | null,	fallbackLocale?: ResolverInputTypes["FallbackLocaleInputType"] | undefined | null},ResolverInputTypes["District"]],
	coordinate?:boolean | `@${string}`,
	linkAddress?:boolean | `@${string}`,
	address?:boolean | `@${string}`,
	addressCoordinate?:boolean | `@${string}`,
	id?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["Event_Sections_Recurrance"]: AliasType<{
	type?:boolean | `@${string}`,
	weekday?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["Event_Sections_Recurrance_type"]:Event_Sections_Recurrance_type;
	["Event_Sections_Recurrance_weekday"]:Event_Sections_Recurrance_weekday;
	["Location"]: AliasType<{
	id?:boolean | `@${string}`,
	region?:boolean | `@${string}`,
creator?: [{	locale?: ResolverInputTypes["LocaleInputType"] | undefined | null,	fallbackLocale?: ResolverInputTypes["FallbackLocaleInputType"] | undefined | null},ResolverInputTypes["User"]],
	permalink?:boolean | `@${string}`,
	status?:boolean | `@${string}`,
	level?:boolean | `@${string}`,
parent?: [{	locale?: ResolverInputTypes["LocaleInputType"] | undefined | null,	fallbackLocale?: ResolverInputTypes["FallbackLocaleInputType"] | undefined | null},ResolverInputTypes["Location"]],
	name?:boolean | `@${string}`,
thumbnail?: [{	locale?: ResolverInputTypes["LocaleInputType"] | undefined | null,	fallbackLocale?: ResolverInputTypes["FallbackLocaleInputType"] | undefined | null,	where?: ResolverInputTypes["Location_Thumbnail_where"] | undefined | null},ResolverInputTypes["Media"]],
banner?: [{	locale?: ResolverInputTypes["LocaleInputType"] | undefined | null,	fallbackLocale?: ResolverInputTypes["FallbackLocaleInputType"] | undefined | null,	where?: ResolverInputTypes["Location_Banner_where"] | undefined | null},ResolverInputTypes["Media"]],
	type?:boolean | `@${string}`,
	category?:boolean | `@${string}`,
district?: [{	locale?: ResolverInputTypes["LocaleInputType"] | undefined | null,	fallbackLocale?: ResolverInputTypes["FallbackLocaleInputType"] | undefined | null},ResolverInputTypes["District"]],
	coordinate?:boolean | `@${string}`,
	linkAddress?:boolean | `@${string}`,
	address?:boolean | `@${string}`,
	addressCoordinate?:boolean | `@${string}`,
	permanant?:boolean | `@${string}`,
	Details?:ResolverInputTypes["Location_Details"],
	content?:ResolverInputTypes["Location_Content"],
	legacyContent?:boolean | `@${string}`,
	showLegacyContent?:boolean | `@${string}`,
	criteria?:ResolverInputTypes["Location_Criteria"],
	sections?:ResolverInputTypes["Location_Sections"],
	views?:boolean | `@${string}`,
	_title?:boolean | `@${string}`,
legacyGallery?: [{	locale?: ResolverInputTypes["LocaleInputType"] | undefined | null,	fallbackLocale?: ResolverInputTypes["FallbackLocaleInputType"] | undefined | null},ResolverInputTypes["Media"]],
	updatedAt?:boolean | `@${string}`,
	createdAt?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["Location_region"]:Location_region;
	["Location_level"]:Location_level;
	["Location_Thumbnail_where"]: {
	alt?: ResolverInputTypes["Location_Thumbnail_alt_operator"] | undefined | null,
	updatedAt?: ResolverInputTypes["Location_Thumbnail_updatedAt_operator"] | undefined | null,
	createdAt?: ResolverInputTypes["Location_Thumbnail_createdAt_operator"] | undefined | null,
	url?: ResolverInputTypes["Location_Thumbnail_url_operator"] | undefined | null,
	filename?: ResolverInputTypes["Location_Thumbnail_filename_operator"] | undefined | null,
	mimeType?: ResolverInputTypes["Location_Thumbnail_mimeType_operator"] | undefined | null,
	filesize?: ResolverInputTypes["Location_Thumbnail_filesize_operator"] | undefined | null,
	width?: ResolverInputTypes["Location_Thumbnail_width_operator"] | undefined | null,
	height?: ResolverInputTypes["Location_Thumbnail_height_operator"] | undefined | null,
	focalX?: ResolverInputTypes["Location_Thumbnail_focalX_operator"] | undefined | null,
	focalY?: ResolverInputTypes["Location_Thumbnail_focalY_operator"] | undefined | null,
	sizes__small__url?: ResolverInputTypes["Location_Thumbnail_sizes__small__url_operator"] | undefined | null,
	sizes__small__width?: ResolverInputTypes["Location_Thumbnail_sizes__small__width_operator"] | undefined | null,
	sizes__small__height?: ResolverInputTypes["Location_Thumbnail_sizes__small__height_operator"] | undefined | null,
	sizes__small__mimeType?: ResolverInputTypes["Location_Thumbnail_sizes__small__mimeType_operator"] | undefined | null,
	sizes__small__filesize?: ResolverInputTypes["Location_Thumbnail_sizes__small__filesize_operator"] | undefined | null,
	sizes__small__filename?: ResolverInputTypes["Location_Thumbnail_sizes__small__filename_operator"] | undefined | null,
	sizes__medium__url?: ResolverInputTypes["Location_Thumbnail_sizes__medium__url_operator"] | undefined | null,
	sizes__medium__width?: ResolverInputTypes["Location_Thumbnail_sizes__medium__width_operator"] | undefined | null,
	sizes__medium__height?: ResolverInputTypes["Location_Thumbnail_sizes__medium__height_operator"] | undefined | null,
	sizes__medium__mimeType?: ResolverInputTypes["Location_Thumbnail_sizes__medium__mimeType_operator"] | undefined | null,
	sizes__medium__filesize?: ResolverInputTypes["Location_Thumbnail_sizes__medium__filesize_operator"] | undefined | null,
	sizes__medium__filename?: ResolverInputTypes["Location_Thumbnail_sizes__medium__filename_operator"] | undefined | null,
	sizes__large__url?: ResolverInputTypes["Location_Thumbnail_sizes__large__url_operator"] | undefined | null,
	sizes__large__width?: ResolverInputTypes["Location_Thumbnail_sizes__large__width_operator"] | undefined | null,
	sizes__large__height?: ResolverInputTypes["Location_Thumbnail_sizes__large__height_operator"] | undefined | null,
	sizes__large__mimeType?: ResolverInputTypes["Location_Thumbnail_sizes__large__mimeType_operator"] | undefined | null,
	sizes__large__filesize?: ResolverInputTypes["Location_Thumbnail_sizes__large__filesize_operator"] | undefined | null,
	sizes__large__filename?: ResolverInputTypes["Location_Thumbnail_sizes__large__filename_operator"] | undefined | null,
	sizes__small_jpeg__url?: ResolverInputTypes["Location_Thumbnail_sizes__small_jpeg__url_operator"] | undefined | null,
	sizes__small_jpeg__width?: ResolverInputTypes["Location_Thumbnail_sizes__small_jpeg__width_operator"] | undefined | null,
	sizes__small_jpeg__height?: ResolverInputTypes["Location_Thumbnail_sizes__small_jpeg__height_operator"] | undefined | null,
	sizes__small_jpeg__mimeType?: ResolverInputTypes["Location_Thumbnail_sizes__small_jpeg__mimeType_operator"] | undefined | null,
	sizes__small_jpeg__filesize?: ResolverInputTypes["Location_Thumbnail_sizes__small_jpeg__filesize_operator"] | undefined | null,
	sizes__small_jpeg__filename?: ResolverInputTypes["Location_Thumbnail_sizes__small_jpeg__filename_operator"] | undefined | null,
	sizes__medium_jpeg__url?: ResolverInputTypes["Location_Thumbnail_sizes__medium_jpeg__url_operator"] | undefined | null,
	sizes__medium_jpeg__width?: ResolverInputTypes["Location_Thumbnail_sizes__medium_jpeg__width_operator"] | undefined | null,
	sizes__medium_jpeg__height?: ResolverInputTypes["Location_Thumbnail_sizes__medium_jpeg__height_operator"] | undefined | null,
	sizes__medium_jpeg__mimeType?: ResolverInputTypes["Location_Thumbnail_sizes__medium_jpeg__mimeType_operator"] | undefined | null,
	sizes__medium_jpeg__filesize?: ResolverInputTypes["Location_Thumbnail_sizes__medium_jpeg__filesize_operator"] | undefined | null,
	sizes__medium_jpeg__filename?: ResolverInputTypes["Location_Thumbnail_sizes__medium_jpeg__filename_operator"] | undefined | null,
	sizes__large_jpeg__url?: ResolverInputTypes["Location_Thumbnail_sizes__large_jpeg__url_operator"] | undefined | null,
	sizes__large_jpeg__width?: ResolverInputTypes["Location_Thumbnail_sizes__large_jpeg__width_operator"] | undefined | null,
	sizes__large_jpeg__height?: ResolverInputTypes["Location_Thumbnail_sizes__large_jpeg__height_operator"] | undefined | null,
	sizes__large_jpeg__mimeType?: ResolverInputTypes["Location_Thumbnail_sizes__large_jpeg__mimeType_operator"] | undefined | null,
	sizes__large_jpeg__filesize?: ResolverInputTypes["Location_Thumbnail_sizes__large_jpeg__filesize_operator"] | undefined | null,
	sizes__large_jpeg__filename?: ResolverInputTypes["Location_Thumbnail_sizes__large_jpeg__filename_operator"] | undefined | null,
	id?: ResolverInputTypes["Location_Thumbnail_id_operator"] | undefined | null,
	AND?: Array<ResolverInputTypes["Location_Thumbnail_where_and"] | undefined | null> | undefined | null,
	OR?: Array<ResolverInputTypes["Location_Thumbnail_where_or"] | undefined | null> | undefined | null
};
	["Location_Thumbnail_alt_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Location_Thumbnail_updatedAt_operator"]: {
	equals?: ResolverInputTypes["DateTime"] | undefined | null,
	not_equals?: ResolverInputTypes["DateTime"] | undefined | null,
	greater_than_equal?: ResolverInputTypes["DateTime"] | undefined | null,
	greater_than?: ResolverInputTypes["DateTime"] | undefined | null,
	less_than_equal?: ResolverInputTypes["DateTime"] | undefined | null,
	less_than?: ResolverInputTypes["DateTime"] | undefined | null,
	like?: ResolverInputTypes["DateTime"] | undefined | null,
	exists?: boolean | undefined | null
};
	["Location_Thumbnail_createdAt_operator"]: {
	equals?: ResolverInputTypes["DateTime"] | undefined | null,
	not_equals?: ResolverInputTypes["DateTime"] | undefined | null,
	greater_than_equal?: ResolverInputTypes["DateTime"] | undefined | null,
	greater_than?: ResolverInputTypes["DateTime"] | undefined | null,
	less_than_equal?: ResolverInputTypes["DateTime"] | undefined | null,
	less_than?: ResolverInputTypes["DateTime"] | undefined | null,
	like?: ResolverInputTypes["DateTime"] | undefined | null,
	exists?: boolean | undefined | null
};
	["Location_Thumbnail_url_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Location_Thumbnail_filename_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Location_Thumbnail_mimeType_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Location_Thumbnail_filesize_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Location_Thumbnail_width_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Location_Thumbnail_height_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Location_Thumbnail_focalX_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Location_Thumbnail_focalY_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Location_Thumbnail_sizes__small__url_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Location_Thumbnail_sizes__small__width_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Location_Thumbnail_sizes__small__height_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Location_Thumbnail_sizes__small__mimeType_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Location_Thumbnail_sizes__small__filesize_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Location_Thumbnail_sizes__small__filename_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Location_Thumbnail_sizes__medium__url_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Location_Thumbnail_sizes__medium__width_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Location_Thumbnail_sizes__medium__height_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Location_Thumbnail_sizes__medium__mimeType_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Location_Thumbnail_sizes__medium__filesize_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Location_Thumbnail_sizes__medium__filename_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Location_Thumbnail_sizes__large__url_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Location_Thumbnail_sizes__large__width_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Location_Thumbnail_sizes__large__height_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Location_Thumbnail_sizes__large__mimeType_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Location_Thumbnail_sizes__large__filesize_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Location_Thumbnail_sizes__large__filename_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Location_Thumbnail_sizes__small_jpeg__url_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Location_Thumbnail_sizes__small_jpeg__width_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Location_Thumbnail_sizes__small_jpeg__height_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Location_Thumbnail_sizes__small_jpeg__mimeType_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Location_Thumbnail_sizes__small_jpeg__filesize_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Location_Thumbnail_sizes__small_jpeg__filename_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Location_Thumbnail_sizes__medium_jpeg__url_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Location_Thumbnail_sizes__medium_jpeg__width_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Location_Thumbnail_sizes__medium_jpeg__height_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Location_Thumbnail_sizes__medium_jpeg__mimeType_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Location_Thumbnail_sizes__medium_jpeg__filesize_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Location_Thumbnail_sizes__medium_jpeg__filename_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Location_Thumbnail_sizes__large_jpeg__url_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Location_Thumbnail_sizes__large_jpeg__width_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Location_Thumbnail_sizes__large_jpeg__height_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Location_Thumbnail_sizes__large_jpeg__mimeType_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Location_Thumbnail_sizes__large_jpeg__filesize_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Location_Thumbnail_sizes__large_jpeg__filename_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Location_Thumbnail_id_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Location_Thumbnail_where_and"]: {
	alt?: ResolverInputTypes["Location_Thumbnail_alt_operator"] | undefined | null,
	updatedAt?: ResolverInputTypes["Location_Thumbnail_updatedAt_operator"] | undefined | null,
	createdAt?: ResolverInputTypes["Location_Thumbnail_createdAt_operator"] | undefined | null,
	url?: ResolverInputTypes["Location_Thumbnail_url_operator"] | undefined | null,
	filename?: ResolverInputTypes["Location_Thumbnail_filename_operator"] | undefined | null,
	mimeType?: ResolverInputTypes["Location_Thumbnail_mimeType_operator"] | undefined | null,
	filesize?: ResolverInputTypes["Location_Thumbnail_filesize_operator"] | undefined | null,
	width?: ResolverInputTypes["Location_Thumbnail_width_operator"] | undefined | null,
	height?: ResolverInputTypes["Location_Thumbnail_height_operator"] | undefined | null,
	focalX?: ResolverInputTypes["Location_Thumbnail_focalX_operator"] | undefined | null,
	focalY?: ResolverInputTypes["Location_Thumbnail_focalY_operator"] | undefined | null,
	sizes__small__url?: ResolverInputTypes["Location_Thumbnail_sizes__small__url_operator"] | undefined | null,
	sizes__small__width?: ResolverInputTypes["Location_Thumbnail_sizes__small__width_operator"] | undefined | null,
	sizes__small__height?: ResolverInputTypes["Location_Thumbnail_sizes__small__height_operator"] | undefined | null,
	sizes__small__mimeType?: ResolverInputTypes["Location_Thumbnail_sizes__small__mimeType_operator"] | undefined | null,
	sizes__small__filesize?: ResolverInputTypes["Location_Thumbnail_sizes__small__filesize_operator"] | undefined | null,
	sizes__small__filename?: ResolverInputTypes["Location_Thumbnail_sizes__small__filename_operator"] | undefined | null,
	sizes__medium__url?: ResolverInputTypes["Location_Thumbnail_sizes__medium__url_operator"] | undefined | null,
	sizes__medium__width?: ResolverInputTypes["Location_Thumbnail_sizes__medium__width_operator"] | undefined | null,
	sizes__medium__height?: ResolverInputTypes["Location_Thumbnail_sizes__medium__height_operator"] | undefined | null,
	sizes__medium__mimeType?: ResolverInputTypes["Location_Thumbnail_sizes__medium__mimeType_operator"] | undefined | null,
	sizes__medium__filesize?: ResolverInputTypes["Location_Thumbnail_sizes__medium__filesize_operator"] | undefined | null,
	sizes__medium__filename?: ResolverInputTypes["Location_Thumbnail_sizes__medium__filename_operator"] | undefined | null,
	sizes__large__url?: ResolverInputTypes["Location_Thumbnail_sizes__large__url_operator"] | undefined | null,
	sizes__large__width?: ResolverInputTypes["Location_Thumbnail_sizes__large__width_operator"] | undefined | null,
	sizes__large__height?: ResolverInputTypes["Location_Thumbnail_sizes__large__height_operator"] | undefined | null,
	sizes__large__mimeType?: ResolverInputTypes["Location_Thumbnail_sizes__large__mimeType_operator"] | undefined | null,
	sizes__large__filesize?: ResolverInputTypes["Location_Thumbnail_sizes__large__filesize_operator"] | undefined | null,
	sizes__large__filename?: ResolverInputTypes["Location_Thumbnail_sizes__large__filename_operator"] | undefined | null,
	sizes__small_jpeg__url?: ResolverInputTypes["Location_Thumbnail_sizes__small_jpeg__url_operator"] | undefined | null,
	sizes__small_jpeg__width?: ResolverInputTypes["Location_Thumbnail_sizes__small_jpeg__width_operator"] | undefined | null,
	sizes__small_jpeg__height?: ResolverInputTypes["Location_Thumbnail_sizes__small_jpeg__height_operator"] | undefined | null,
	sizes__small_jpeg__mimeType?: ResolverInputTypes["Location_Thumbnail_sizes__small_jpeg__mimeType_operator"] | undefined | null,
	sizes__small_jpeg__filesize?: ResolverInputTypes["Location_Thumbnail_sizes__small_jpeg__filesize_operator"] | undefined | null,
	sizes__small_jpeg__filename?: ResolverInputTypes["Location_Thumbnail_sizes__small_jpeg__filename_operator"] | undefined | null,
	sizes__medium_jpeg__url?: ResolverInputTypes["Location_Thumbnail_sizes__medium_jpeg__url_operator"] | undefined | null,
	sizes__medium_jpeg__width?: ResolverInputTypes["Location_Thumbnail_sizes__medium_jpeg__width_operator"] | undefined | null,
	sizes__medium_jpeg__height?: ResolverInputTypes["Location_Thumbnail_sizes__medium_jpeg__height_operator"] | undefined | null,
	sizes__medium_jpeg__mimeType?: ResolverInputTypes["Location_Thumbnail_sizes__medium_jpeg__mimeType_operator"] | undefined | null,
	sizes__medium_jpeg__filesize?: ResolverInputTypes["Location_Thumbnail_sizes__medium_jpeg__filesize_operator"] | undefined | null,
	sizes__medium_jpeg__filename?: ResolverInputTypes["Location_Thumbnail_sizes__medium_jpeg__filename_operator"] | undefined | null,
	sizes__large_jpeg__url?: ResolverInputTypes["Location_Thumbnail_sizes__large_jpeg__url_operator"] | undefined | null,
	sizes__large_jpeg__width?: ResolverInputTypes["Location_Thumbnail_sizes__large_jpeg__width_operator"] | undefined | null,
	sizes__large_jpeg__height?: ResolverInputTypes["Location_Thumbnail_sizes__large_jpeg__height_operator"] | undefined | null,
	sizes__large_jpeg__mimeType?: ResolverInputTypes["Location_Thumbnail_sizes__large_jpeg__mimeType_operator"] | undefined | null,
	sizes__large_jpeg__filesize?: ResolverInputTypes["Location_Thumbnail_sizes__large_jpeg__filesize_operator"] | undefined | null,
	sizes__large_jpeg__filename?: ResolverInputTypes["Location_Thumbnail_sizes__large_jpeg__filename_operator"] | undefined | null,
	id?: ResolverInputTypes["Location_Thumbnail_id_operator"] | undefined | null,
	AND?: Array<ResolverInputTypes["Location_Thumbnail_where_and"] | undefined | null> | undefined | null,
	OR?: Array<ResolverInputTypes["Location_Thumbnail_where_or"] | undefined | null> | undefined | null
};
	["Location_Thumbnail_where_or"]: {
	alt?: ResolverInputTypes["Location_Thumbnail_alt_operator"] | undefined | null,
	updatedAt?: ResolverInputTypes["Location_Thumbnail_updatedAt_operator"] | undefined | null,
	createdAt?: ResolverInputTypes["Location_Thumbnail_createdAt_operator"] | undefined | null,
	url?: ResolverInputTypes["Location_Thumbnail_url_operator"] | undefined | null,
	filename?: ResolverInputTypes["Location_Thumbnail_filename_operator"] | undefined | null,
	mimeType?: ResolverInputTypes["Location_Thumbnail_mimeType_operator"] | undefined | null,
	filesize?: ResolverInputTypes["Location_Thumbnail_filesize_operator"] | undefined | null,
	width?: ResolverInputTypes["Location_Thumbnail_width_operator"] | undefined | null,
	height?: ResolverInputTypes["Location_Thumbnail_height_operator"] | undefined | null,
	focalX?: ResolverInputTypes["Location_Thumbnail_focalX_operator"] | undefined | null,
	focalY?: ResolverInputTypes["Location_Thumbnail_focalY_operator"] | undefined | null,
	sizes__small__url?: ResolverInputTypes["Location_Thumbnail_sizes__small__url_operator"] | undefined | null,
	sizes__small__width?: ResolverInputTypes["Location_Thumbnail_sizes__small__width_operator"] | undefined | null,
	sizes__small__height?: ResolverInputTypes["Location_Thumbnail_sizes__small__height_operator"] | undefined | null,
	sizes__small__mimeType?: ResolverInputTypes["Location_Thumbnail_sizes__small__mimeType_operator"] | undefined | null,
	sizes__small__filesize?: ResolverInputTypes["Location_Thumbnail_sizes__small__filesize_operator"] | undefined | null,
	sizes__small__filename?: ResolverInputTypes["Location_Thumbnail_sizes__small__filename_operator"] | undefined | null,
	sizes__medium__url?: ResolverInputTypes["Location_Thumbnail_sizes__medium__url_operator"] | undefined | null,
	sizes__medium__width?: ResolverInputTypes["Location_Thumbnail_sizes__medium__width_operator"] | undefined | null,
	sizes__medium__height?: ResolverInputTypes["Location_Thumbnail_sizes__medium__height_operator"] | undefined | null,
	sizes__medium__mimeType?: ResolverInputTypes["Location_Thumbnail_sizes__medium__mimeType_operator"] | undefined | null,
	sizes__medium__filesize?: ResolverInputTypes["Location_Thumbnail_sizes__medium__filesize_operator"] | undefined | null,
	sizes__medium__filename?: ResolverInputTypes["Location_Thumbnail_sizes__medium__filename_operator"] | undefined | null,
	sizes__large__url?: ResolverInputTypes["Location_Thumbnail_sizes__large__url_operator"] | undefined | null,
	sizes__large__width?: ResolverInputTypes["Location_Thumbnail_sizes__large__width_operator"] | undefined | null,
	sizes__large__height?: ResolverInputTypes["Location_Thumbnail_sizes__large__height_operator"] | undefined | null,
	sizes__large__mimeType?: ResolverInputTypes["Location_Thumbnail_sizes__large__mimeType_operator"] | undefined | null,
	sizes__large__filesize?: ResolverInputTypes["Location_Thumbnail_sizes__large__filesize_operator"] | undefined | null,
	sizes__large__filename?: ResolverInputTypes["Location_Thumbnail_sizes__large__filename_operator"] | undefined | null,
	sizes__small_jpeg__url?: ResolverInputTypes["Location_Thumbnail_sizes__small_jpeg__url_operator"] | undefined | null,
	sizes__small_jpeg__width?: ResolverInputTypes["Location_Thumbnail_sizes__small_jpeg__width_operator"] | undefined | null,
	sizes__small_jpeg__height?: ResolverInputTypes["Location_Thumbnail_sizes__small_jpeg__height_operator"] | undefined | null,
	sizes__small_jpeg__mimeType?: ResolverInputTypes["Location_Thumbnail_sizes__small_jpeg__mimeType_operator"] | undefined | null,
	sizes__small_jpeg__filesize?: ResolverInputTypes["Location_Thumbnail_sizes__small_jpeg__filesize_operator"] | undefined | null,
	sizes__small_jpeg__filename?: ResolverInputTypes["Location_Thumbnail_sizes__small_jpeg__filename_operator"] | undefined | null,
	sizes__medium_jpeg__url?: ResolverInputTypes["Location_Thumbnail_sizes__medium_jpeg__url_operator"] | undefined | null,
	sizes__medium_jpeg__width?: ResolverInputTypes["Location_Thumbnail_sizes__medium_jpeg__width_operator"] | undefined | null,
	sizes__medium_jpeg__height?: ResolverInputTypes["Location_Thumbnail_sizes__medium_jpeg__height_operator"] | undefined | null,
	sizes__medium_jpeg__mimeType?: ResolverInputTypes["Location_Thumbnail_sizes__medium_jpeg__mimeType_operator"] | undefined | null,
	sizes__medium_jpeg__filesize?: ResolverInputTypes["Location_Thumbnail_sizes__medium_jpeg__filesize_operator"] | undefined | null,
	sizes__medium_jpeg__filename?: ResolverInputTypes["Location_Thumbnail_sizes__medium_jpeg__filename_operator"] | undefined | null,
	sizes__large_jpeg__url?: ResolverInputTypes["Location_Thumbnail_sizes__large_jpeg__url_operator"] | undefined | null,
	sizes__large_jpeg__width?: ResolverInputTypes["Location_Thumbnail_sizes__large_jpeg__width_operator"] | undefined | null,
	sizes__large_jpeg__height?: ResolverInputTypes["Location_Thumbnail_sizes__large_jpeg__height_operator"] | undefined | null,
	sizes__large_jpeg__mimeType?: ResolverInputTypes["Location_Thumbnail_sizes__large_jpeg__mimeType_operator"] | undefined | null,
	sizes__large_jpeg__filesize?: ResolverInputTypes["Location_Thumbnail_sizes__large_jpeg__filesize_operator"] | undefined | null,
	sizes__large_jpeg__filename?: ResolverInputTypes["Location_Thumbnail_sizes__large_jpeg__filename_operator"] | undefined | null,
	id?: ResolverInputTypes["Location_Thumbnail_id_operator"] | undefined | null,
	AND?: Array<ResolverInputTypes["Location_Thumbnail_where_and"] | undefined | null> | undefined | null,
	OR?: Array<ResolverInputTypes["Location_Thumbnail_where_or"] | undefined | null> | undefined | null
};
	["Location_Banner_where"]: {
	alt?: ResolverInputTypes["Location_Banner_alt_operator"] | undefined | null,
	updatedAt?: ResolverInputTypes["Location_Banner_updatedAt_operator"] | undefined | null,
	createdAt?: ResolverInputTypes["Location_Banner_createdAt_operator"] | undefined | null,
	url?: ResolverInputTypes["Location_Banner_url_operator"] | undefined | null,
	filename?: ResolverInputTypes["Location_Banner_filename_operator"] | undefined | null,
	mimeType?: ResolverInputTypes["Location_Banner_mimeType_operator"] | undefined | null,
	filesize?: ResolverInputTypes["Location_Banner_filesize_operator"] | undefined | null,
	width?: ResolverInputTypes["Location_Banner_width_operator"] | undefined | null,
	height?: ResolverInputTypes["Location_Banner_height_operator"] | undefined | null,
	focalX?: ResolverInputTypes["Location_Banner_focalX_operator"] | undefined | null,
	focalY?: ResolverInputTypes["Location_Banner_focalY_operator"] | undefined | null,
	sizes__small__url?: ResolverInputTypes["Location_Banner_sizes__small__url_operator"] | undefined | null,
	sizes__small__width?: ResolverInputTypes["Location_Banner_sizes__small__width_operator"] | undefined | null,
	sizes__small__height?: ResolverInputTypes["Location_Banner_sizes__small__height_operator"] | undefined | null,
	sizes__small__mimeType?: ResolverInputTypes["Location_Banner_sizes__small__mimeType_operator"] | undefined | null,
	sizes__small__filesize?: ResolverInputTypes["Location_Banner_sizes__small__filesize_operator"] | undefined | null,
	sizes__small__filename?: ResolverInputTypes["Location_Banner_sizes__small__filename_operator"] | undefined | null,
	sizes__medium__url?: ResolverInputTypes["Location_Banner_sizes__medium__url_operator"] | undefined | null,
	sizes__medium__width?: ResolverInputTypes["Location_Banner_sizes__medium__width_operator"] | undefined | null,
	sizes__medium__height?: ResolverInputTypes["Location_Banner_sizes__medium__height_operator"] | undefined | null,
	sizes__medium__mimeType?: ResolverInputTypes["Location_Banner_sizes__medium__mimeType_operator"] | undefined | null,
	sizes__medium__filesize?: ResolverInputTypes["Location_Banner_sizes__medium__filesize_operator"] | undefined | null,
	sizes__medium__filename?: ResolverInputTypes["Location_Banner_sizes__medium__filename_operator"] | undefined | null,
	sizes__large__url?: ResolverInputTypes["Location_Banner_sizes__large__url_operator"] | undefined | null,
	sizes__large__width?: ResolverInputTypes["Location_Banner_sizes__large__width_operator"] | undefined | null,
	sizes__large__height?: ResolverInputTypes["Location_Banner_sizes__large__height_operator"] | undefined | null,
	sizes__large__mimeType?: ResolverInputTypes["Location_Banner_sizes__large__mimeType_operator"] | undefined | null,
	sizes__large__filesize?: ResolverInputTypes["Location_Banner_sizes__large__filesize_operator"] | undefined | null,
	sizes__large__filename?: ResolverInputTypes["Location_Banner_sizes__large__filename_operator"] | undefined | null,
	sizes__small_jpeg__url?: ResolverInputTypes["Location_Banner_sizes__small_jpeg__url_operator"] | undefined | null,
	sizes__small_jpeg__width?: ResolverInputTypes["Location_Banner_sizes__small_jpeg__width_operator"] | undefined | null,
	sizes__small_jpeg__height?: ResolverInputTypes["Location_Banner_sizes__small_jpeg__height_operator"] | undefined | null,
	sizes__small_jpeg__mimeType?: ResolverInputTypes["Location_Banner_sizes__small_jpeg__mimeType_operator"] | undefined | null,
	sizes__small_jpeg__filesize?: ResolverInputTypes["Location_Banner_sizes__small_jpeg__filesize_operator"] | undefined | null,
	sizes__small_jpeg__filename?: ResolverInputTypes["Location_Banner_sizes__small_jpeg__filename_operator"] | undefined | null,
	sizes__medium_jpeg__url?: ResolverInputTypes["Location_Banner_sizes__medium_jpeg__url_operator"] | undefined | null,
	sizes__medium_jpeg__width?: ResolverInputTypes["Location_Banner_sizes__medium_jpeg__width_operator"] | undefined | null,
	sizes__medium_jpeg__height?: ResolverInputTypes["Location_Banner_sizes__medium_jpeg__height_operator"] | undefined | null,
	sizes__medium_jpeg__mimeType?: ResolverInputTypes["Location_Banner_sizes__medium_jpeg__mimeType_operator"] | undefined | null,
	sizes__medium_jpeg__filesize?: ResolverInputTypes["Location_Banner_sizes__medium_jpeg__filesize_operator"] | undefined | null,
	sizes__medium_jpeg__filename?: ResolverInputTypes["Location_Banner_sizes__medium_jpeg__filename_operator"] | undefined | null,
	sizes__large_jpeg__url?: ResolverInputTypes["Location_Banner_sizes__large_jpeg__url_operator"] | undefined | null,
	sizes__large_jpeg__width?: ResolverInputTypes["Location_Banner_sizes__large_jpeg__width_operator"] | undefined | null,
	sizes__large_jpeg__height?: ResolverInputTypes["Location_Banner_sizes__large_jpeg__height_operator"] | undefined | null,
	sizes__large_jpeg__mimeType?: ResolverInputTypes["Location_Banner_sizes__large_jpeg__mimeType_operator"] | undefined | null,
	sizes__large_jpeg__filesize?: ResolverInputTypes["Location_Banner_sizes__large_jpeg__filesize_operator"] | undefined | null,
	sizes__large_jpeg__filename?: ResolverInputTypes["Location_Banner_sizes__large_jpeg__filename_operator"] | undefined | null,
	id?: ResolverInputTypes["Location_Banner_id_operator"] | undefined | null,
	AND?: Array<ResolverInputTypes["Location_Banner_where_and"] | undefined | null> | undefined | null,
	OR?: Array<ResolverInputTypes["Location_Banner_where_or"] | undefined | null> | undefined | null
};
	["Location_Banner_alt_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Location_Banner_updatedAt_operator"]: {
	equals?: ResolverInputTypes["DateTime"] | undefined | null,
	not_equals?: ResolverInputTypes["DateTime"] | undefined | null,
	greater_than_equal?: ResolverInputTypes["DateTime"] | undefined | null,
	greater_than?: ResolverInputTypes["DateTime"] | undefined | null,
	less_than_equal?: ResolverInputTypes["DateTime"] | undefined | null,
	less_than?: ResolverInputTypes["DateTime"] | undefined | null,
	like?: ResolverInputTypes["DateTime"] | undefined | null,
	exists?: boolean | undefined | null
};
	["Location_Banner_createdAt_operator"]: {
	equals?: ResolverInputTypes["DateTime"] | undefined | null,
	not_equals?: ResolverInputTypes["DateTime"] | undefined | null,
	greater_than_equal?: ResolverInputTypes["DateTime"] | undefined | null,
	greater_than?: ResolverInputTypes["DateTime"] | undefined | null,
	less_than_equal?: ResolverInputTypes["DateTime"] | undefined | null,
	less_than?: ResolverInputTypes["DateTime"] | undefined | null,
	like?: ResolverInputTypes["DateTime"] | undefined | null,
	exists?: boolean | undefined | null
};
	["Location_Banner_url_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Location_Banner_filename_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Location_Banner_mimeType_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Location_Banner_filesize_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Location_Banner_width_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Location_Banner_height_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Location_Banner_focalX_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Location_Banner_focalY_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Location_Banner_sizes__small__url_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Location_Banner_sizes__small__width_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Location_Banner_sizes__small__height_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Location_Banner_sizes__small__mimeType_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Location_Banner_sizes__small__filesize_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Location_Banner_sizes__small__filename_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Location_Banner_sizes__medium__url_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Location_Banner_sizes__medium__width_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Location_Banner_sizes__medium__height_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Location_Banner_sizes__medium__mimeType_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Location_Banner_sizes__medium__filesize_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Location_Banner_sizes__medium__filename_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Location_Banner_sizes__large__url_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Location_Banner_sizes__large__width_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Location_Banner_sizes__large__height_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Location_Banner_sizes__large__mimeType_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Location_Banner_sizes__large__filesize_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Location_Banner_sizes__large__filename_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Location_Banner_sizes__small_jpeg__url_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Location_Banner_sizes__small_jpeg__width_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Location_Banner_sizes__small_jpeg__height_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Location_Banner_sizes__small_jpeg__mimeType_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Location_Banner_sizes__small_jpeg__filesize_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Location_Banner_sizes__small_jpeg__filename_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Location_Banner_sizes__medium_jpeg__url_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Location_Banner_sizes__medium_jpeg__width_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Location_Banner_sizes__medium_jpeg__height_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Location_Banner_sizes__medium_jpeg__mimeType_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Location_Banner_sizes__medium_jpeg__filesize_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Location_Banner_sizes__medium_jpeg__filename_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Location_Banner_sizes__large_jpeg__url_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Location_Banner_sizes__large_jpeg__width_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Location_Banner_sizes__large_jpeg__height_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Location_Banner_sizes__large_jpeg__mimeType_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Location_Banner_sizes__large_jpeg__filesize_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Location_Banner_sizes__large_jpeg__filename_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Location_Banner_id_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Location_Banner_where_and"]: {
	alt?: ResolverInputTypes["Location_Banner_alt_operator"] | undefined | null,
	updatedAt?: ResolverInputTypes["Location_Banner_updatedAt_operator"] | undefined | null,
	createdAt?: ResolverInputTypes["Location_Banner_createdAt_operator"] | undefined | null,
	url?: ResolverInputTypes["Location_Banner_url_operator"] | undefined | null,
	filename?: ResolverInputTypes["Location_Banner_filename_operator"] | undefined | null,
	mimeType?: ResolverInputTypes["Location_Banner_mimeType_operator"] | undefined | null,
	filesize?: ResolverInputTypes["Location_Banner_filesize_operator"] | undefined | null,
	width?: ResolverInputTypes["Location_Banner_width_operator"] | undefined | null,
	height?: ResolverInputTypes["Location_Banner_height_operator"] | undefined | null,
	focalX?: ResolverInputTypes["Location_Banner_focalX_operator"] | undefined | null,
	focalY?: ResolverInputTypes["Location_Banner_focalY_operator"] | undefined | null,
	sizes__small__url?: ResolverInputTypes["Location_Banner_sizes__small__url_operator"] | undefined | null,
	sizes__small__width?: ResolverInputTypes["Location_Banner_sizes__small__width_operator"] | undefined | null,
	sizes__small__height?: ResolverInputTypes["Location_Banner_sizes__small__height_operator"] | undefined | null,
	sizes__small__mimeType?: ResolverInputTypes["Location_Banner_sizes__small__mimeType_operator"] | undefined | null,
	sizes__small__filesize?: ResolverInputTypes["Location_Banner_sizes__small__filesize_operator"] | undefined | null,
	sizes__small__filename?: ResolverInputTypes["Location_Banner_sizes__small__filename_operator"] | undefined | null,
	sizes__medium__url?: ResolverInputTypes["Location_Banner_sizes__medium__url_operator"] | undefined | null,
	sizes__medium__width?: ResolverInputTypes["Location_Banner_sizes__medium__width_operator"] | undefined | null,
	sizes__medium__height?: ResolverInputTypes["Location_Banner_sizes__medium__height_operator"] | undefined | null,
	sizes__medium__mimeType?: ResolverInputTypes["Location_Banner_sizes__medium__mimeType_operator"] | undefined | null,
	sizes__medium__filesize?: ResolverInputTypes["Location_Banner_sizes__medium__filesize_operator"] | undefined | null,
	sizes__medium__filename?: ResolverInputTypes["Location_Banner_sizes__medium__filename_operator"] | undefined | null,
	sizes__large__url?: ResolverInputTypes["Location_Banner_sizes__large__url_operator"] | undefined | null,
	sizes__large__width?: ResolverInputTypes["Location_Banner_sizes__large__width_operator"] | undefined | null,
	sizes__large__height?: ResolverInputTypes["Location_Banner_sizes__large__height_operator"] | undefined | null,
	sizes__large__mimeType?: ResolverInputTypes["Location_Banner_sizes__large__mimeType_operator"] | undefined | null,
	sizes__large__filesize?: ResolverInputTypes["Location_Banner_sizes__large__filesize_operator"] | undefined | null,
	sizes__large__filename?: ResolverInputTypes["Location_Banner_sizes__large__filename_operator"] | undefined | null,
	sizes__small_jpeg__url?: ResolverInputTypes["Location_Banner_sizes__small_jpeg__url_operator"] | undefined | null,
	sizes__small_jpeg__width?: ResolverInputTypes["Location_Banner_sizes__small_jpeg__width_operator"] | undefined | null,
	sizes__small_jpeg__height?: ResolverInputTypes["Location_Banner_sizes__small_jpeg__height_operator"] | undefined | null,
	sizes__small_jpeg__mimeType?: ResolverInputTypes["Location_Banner_sizes__small_jpeg__mimeType_operator"] | undefined | null,
	sizes__small_jpeg__filesize?: ResolverInputTypes["Location_Banner_sizes__small_jpeg__filesize_operator"] | undefined | null,
	sizes__small_jpeg__filename?: ResolverInputTypes["Location_Banner_sizes__small_jpeg__filename_operator"] | undefined | null,
	sizes__medium_jpeg__url?: ResolverInputTypes["Location_Banner_sizes__medium_jpeg__url_operator"] | undefined | null,
	sizes__medium_jpeg__width?: ResolverInputTypes["Location_Banner_sizes__medium_jpeg__width_operator"] | undefined | null,
	sizes__medium_jpeg__height?: ResolverInputTypes["Location_Banner_sizes__medium_jpeg__height_operator"] | undefined | null,
	sizes__medium_jpeg__mimeType?: ResolverInputTypes["Location_Banner_sizes__medium_jpeg__mimeType_operator"] | undefined | null,
	sizes__medium_jpeg__filesize?: ResolverInputTypes["Location_Banner_sizes__medium_jpeg__filesize_operator"] | undefined | null,
	sizes__medium_jpeg__filename?: ResolverInputTypes["Location_Banner_sizes__medium_jpeg__filename_operator"] | undefined | null,
	sizes__large_jpeg__url?: ResolverInputTypes["Location_Banner_sizes__large_jpeg__url_operator"] | undefined | null,
	sizes__large_jpeg__width?: ResolverInputTypes["Location_Banner_sizes__large_jpeg__width_operator"] | undefined | null,
	sizes__large_jpeg__height?: ResolverInputTypes["Location_Banner_sizes__large_jpeg__height_operator"] | undefined | null,
	sizes__large_jpeg__mimeType?: ResolverInputTypes["Location_Banner_sizes__large_jpeg__mimeType_operator"] | undefined | null,
	sizes__large_jpeg__filesize?: ResolverInputTypes["Location_Banner_sizes__large_jpeg__filesize_operator"] | undefined | null,
	sizes__large_jpeg__filename?: ResolverInputTypes["Location_Banner_sizes__large_jpeg__filename_operator"] | undefined | null,
	id?: ResolverInputTypes["Location_Banner_id_operator"] | undefined | null,
	AND?: Array<ResolverInputTypes["Location_Banner_where_and"] | undefined | null> | undefined | null,
	OR?: Array<ResolverInputTypes["Location_Banner_where_or"] | undefined | null> | undefined | null
};
	["Location_Banner_where_or"]: {
	alt?: ResolverInputTypes["Location_Banner_alt_operator"] | undefined | null,
	updatedAt?: ResolverInputTypes["Location_Banner_updatedAt_operator"] | undefined | null,
	createdAt?: ResolverInputTypes["Location_Banner_createdAt_operator"] | undefined | null,
	url?: ResolverInputTypes["Location_Banner_url_operator"] | undefined | null,
	filename?: ResolverInputTypes["Location_Banner_filename_operator"] | undefined | null,
	mimeType?: ResolverInputTypes["Location_Banner_mimeType_operator"] | undefined | null,
	filesize?: ResolverInputTypes["Location_Banner_filesize_operator"] | undefined | null,
	width?: ResolverInputTypes["Location_Banner_width_operator"] | undefined | null,
	height?: ResolverInputTypes["Location_Banner_height_operator"] | undefined | null,
	focalX?: ResolverInputTypes["Location_Banner_focalX_operator"] | undefined | null,
	focalY?: ResolverInputTypes["Location_Banner_focalY_operator"] | undefined | null,
	sizes__small__url?: ResolverInputTypes["Location_Banner_sizes__small__url_operator"] | undefined | null,
	sizes__small__width?: ResolverInputTypes["Location_Banner_sizes__small__width_operator"] | undefined | null,
	sizes__small__height?: ResolverInputTypes["Location_Banner_sizes__small__height_operator"] | undefined | null,
	sizes__small__mimeType?: ResolverInputTypes["Location_Banner_sizes__small__mimeType_operator"] | undefined | null,
	sizes__small__filesize?: ResolverInputTypes["Location_Banner_sizes__small__filesize_operator"] | undefined | null,
	sizes__small__filename?: ResolverInputTypes["Location_Banner_sizes__small__filename_operator"] | undefined | null,
	sizes__medium__url?: ResolverInputTypes["Location_Banner_sizes__medium__url_operator"] | undefined | null,
	sizes__medium__width?: ResolverInputTypes["Location_Banner_sizes__medium__width_operator"] | undefined | null,
	sizes__medium__height?: ResolverInputTypes["Location_Banner_sizes__medium__height_operator"] | undefined | null,
	sizes__medium__mimeType?: ResolverInputTypes["Location_Banner_sizes__medium__mimeType_operator"] | undefined | null,
	sizes__medium__filesize?: ResolverInputTypes["Location_Banner_sizes__medium__filesize_operator"] | undefined | null,
	sizes__medium__filename?: ResolverInputTypes["Location_Banner_sizes__medium__filename_operator"] | undefined | null,
	sizes__large__url?: ResolverInputTypes["Location_Banner_sizes__large__url_operator"] | undefined | null,
	sizes__large__width?: ResolverInputTypes["Location_Banner_sizes__large__width_operator"] | undefined | null,
	sizes__large__height?: ResolverInputTypes["Location_Banner_sizes__large__height_operator"] | undefined | null,
	sizes__large__mimeType?: ResolverInputTypes["Location_Banner_sizes__large__mimeType_operator"] | undefined | null,
	sizes__large__filesize?: ResolverInputTypes["Location_Banner_sizes__large__filesize_operator"] | undefined | null,
	sizes__large__filename?: ResolverInputTypes["Location_Banner_sizes__large__filename_operator"] | undefined | null,
	sizes__small_jpeg__url?: ResolverInputTypes["Location_Banner_sizes__small_jpeg__url_operator"] | undefined | null,
	sizes__small_jpeg__width?: ResolverInputTypes["Location_Banner_sizes__small_jpeg__width_operator"] | undefined | null,
	sizes__small_jpeg__height?: ResolverInputTypes["Location_Banner_sizes__small_jpeg__height_operator"] | undefined | null,
	sizes__small_jpeg__mimeType?: ResolverInputTypes["Location_Banner_sizes__small_jpeg__mimeType_operator"] | undefined | null,
	sizes__small_jpeg__filesize?: ResolverInputTypes["Location_Banner_sizes__small_jpeg__filesize_operator"] | undefined | null,
	sizes__small_jpeg__filename?: ResolverInputTypes["Location_Banner_sizes__small_jpeg__filename_operator"] | undefined | null,
	sizes__medium_jpeg__url?: ResolverInputTypes["Location_Banner_sizes__medium_jpeg__url_operator"] | undefined | null,
	sizes__medium_jpeg__width?: ResolverInputTypes["Location_Banner_sizes__medium_jpeg__width_operator"] | undefined | null,
	sizes__medium_jpeg__height?: ResolverInputTypes["Location_Banner_sizes__medium_jpeg__height_operator"] | undefined | null,
	sizes__medium_jpeg__mimeType?: ResolverInputTypes["Location_Banner_sizes__medium_jpeg__mimeType_operator"] | undefined | null,
	sizes__medium_jpeg__filesize?: ResolverInputTypes["Location_Banner_sizes__medium_jpeg__filesize_operator"] | undefined | null,
	sizes__medium_jpeg__filename?: ResolverInputTypes["Location_Banner_sizes__medium_jpeg__filename_operator"] | undefined | null,
	sizes__large_jpeg__url?: ResolverInputTypes["Location_Banner_sizes__large_jpeg__url_operator"] | undefined | null,
	sizes__large_jpeg__width?: ResolverInputTypes["Location_Banner_sizes__large_jpeg__width_operator"] | undefined | null,
	sizes__large_jpeg__height?: ResolverInputTypes["Location_Banner_sizes__large_jpeg__height_operator"] | undefined | null,
	sizes__large_jpeg__mimeType?: ResolverInputTypes["Location_Banner_sizes__large_jpeg__mimeType_operator"] | undefined | null,
	sizes__large_jpeg__filesize?: ResolverInputTypes["Location_Banner_sizes__large_jpeg__filesize_operator"] | undefined | null,
	sizes__large_jpeg__filename?: ResolverInputTypes["Location_Banner_sizes__large_jpeg__filename_operator"] | undefined | null,
	id?: ResolverInputTypes["Location_Banner_id_operator"] | undefined | null,
	AND?: Array<ResolverInputTypes["Location_Banner_where_and"] | undefined | null> | undefined | null,
	OR?: Array<ResolverInputTypes["Location_Banner_where_or"] | undefined | null> | undefined | null
};
	["Location_type"]:Location_type;
	["Location_category"]:Location_category;
	["District"]: AliasType<{
	id?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	level?:boolean | `@${string}`,
children?: [{	locale?: ResolverInputTypes["LocaleInputType"] | undefined | null,	fallbackLocale?: ResolverInputTypes["FallbackLocaleInputType"] | undefined | null},ResolverInputTypes["District"]],
	value?:boolean | `@${string}`,
	lat?:boolean | `@${string}`,
	long?:boolean | `@${string}`,
	radius?:boolean | `@${string}`,
	region?:boolean | `@${string}`,
	updatedAt?:boolean | `@${string}`,
	createdAt?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["District_region"]:District_region;
	["Location_Details"]: AliasType<{
	code?:boolean | `@${string}`,
	phone?:boolean | `@${string}`,
	page?:boolean | `@${string}`,
	email?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["Location_Content"]: AliasType<{
	RichText?:ResolverInputTypes["RichText"],
	Gallery?:ResolverInputTypes["Gallery"],
	Image?:ResolverInputTypes["Image"],
	Video?:ResolverInputTypes["Video"],
		__typename?: boolean | `@${string}`
}>;
	["RichText"]: AliasType<{
richText?: [{	depth?: number | undefined | null},boolean | `@${string}`],
	id?:boolean | `@${string}`,
	blockName?:boolean | `@${string}`,
	blockType?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	/** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
["JSON"]:unknown;
	["Gallery"]: AliasType<{
	gallery?:ResolverInputTypes["Gallery_Gallery"],
	id?:boolean | `@${string}`,
	blockName?:boolean | `@${string}`,
	blockType?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["Gallery_Gallery"]: AliasType<{
item?: [{	locale?: ResolverInputTypes["LocaleInputType"] | undefined | null,	fallbackLocale?: ResolverInputTypes["FallbackLocaleInputType"] | undefined | null,	where?: ResolverInputTypes["Gallery_Gallery_Item_where"] | undefined | null},ResolverInputTypes["Media"]],
	caption?:boolean | `@${string}`,
	id?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["Gallery_Gallery_Item_where"]: {
	alt?: ResolverInputTypes["Gallery_Gallery_Item_alt_operator"] | undefined | null,
	updatedAt?: ResolverInputTypes["Gallery_Gallery_Item_updatedAt_operator"] | undefined | null,
	createdAt?: ResolverInputTypes["Gallery_Gallery_Item_createdAt_operator"] | undefined | null,
	url?: ResolverInputTypes["Gallery_Gallery_Item_url_operator"] | undefined | null,
	filename?: ResolverInputTypes["Gallery_Gallery_Item_filename_operator"] | undefined | null,
	mimeType?: ResolverInputTypes["Gallery_Gallery_Item_mimeType_operator"] | undefined | null,
	filesize?: ResolverInputTypes["Gallery_Gallery_Item_filesize_operator"] | undefined | null,
	width?: ResolverInputTypes["Gallery_Gallery_Item_width_operator"] | undefined | null,
	height?: ResolverInputTypes["Gallery_Gallery_Item_height_operator"] | undefined | null,
	focalX?: ResolverInputTypes["Gallery_Gallery_Item_focalX_operator"] | undefined | null,
	focalY?: ResolverInputTypes["Gallery_Gallery_Item_focalY_operator"] | undefined | null,
	sizes__small__url?: ResolverInputTypes["Gallery_Gallery_Item_sizes__small__url_operator"] | undefined | null,
	sizes__small__width?: ResolverInputTypes["Gallery_Gallery_Item_sizes__small__width_operator"] | undefined | null,
	sizes__small__height?: ResolverInputTypes["Gallery_Gallery_Item_sizes__small__height_operator"] | undefined | null,
	sizes__small__mimeType?: ResolverInputTypes["Gallery_Gallery_Item_sizes__small__mimeType_operator"] | undefined | null,
	sizes__small__filesize?: ResolverInputTypes["Gallery_Gallery_Item_sizes__small__filesize_operator"] | undefined | null,
	sizes__small__filename?: ResolverInputTypes["Gallery_Gallery_Item_sizes__small__filename_operator"] | undefined | null,
	sizes__medium__url?: ResolverInputTypes["Gallery_Gallery_Item_sizes__medium__url_operator"] | undefined | null,
	sizes__medium__width?: ResolverInputTypes["Gallery_Gallery_Item_sizes__medium__width_operator"] | undefined | null,
	sizes__medium__height?: ResolverInputTypes["Gallery_Gallery_Item_sizes__medium__height_operator"] | undefined | null,
	sizes__medium__mimeType?: ResolverInputTypes["Gallery_Gallery_Item_sizes__medium__mimeType_operator"] | undefined | null,
	sizes__medium__filesize?: ResolverInputTypes["Gallery_Gallery_Item_sizes__medium__filesize_operator"] | undefined | null,
	sizes__medium__filename?: ResolverInputTypes["Gallery_Gallery_Item_sizes__medium__filename_operator"] | undefined | null,
	sizes__large__url?: ResolverInputTypes["Gallery_Gallery_Item_sizes__large__url_operator"] | undefined | null,
	sizes__large__width?: ResolverInputTypes["Gallery_Gallery_Item_sizes__large__width_operator"] | undefined | null,
	sizes__large__height?: ResolverInputTypes["Gallery_Gallery_Item_sizes__large__height_operator"] | undefined | null,
	sizes__large__mimeType?: ResolverInputTypes["Gallery_Gallery_Item_sizes__large__mimeType_operator"] | undefined | null,
	sizes__large__filesize?: ResolverInputTypes["Gallery_Gallery_Item_sizes__large__filesize_operator"] | undefined | null,
	sizes__large__filename?: ResolverInputTypes["Gallery_Gallery_Item_sizes__large__filename_operator"] | undefined | null,
	sizes__small_jpeg__url?: ResolverInputTypes["Gallery_Gallery_Item_sizes__small_jpeg__url_operator"] | undefined | null,
	sizes__small_jpeg__width?: ResolverInputTypes["Gallery_Gallery_Item_sizes__small_jpeg__width_operator"] | undefined | null,
	sizes__small_jpeg__height?: ResolverInputTypes["Gallery_Gallery_Item_sizes__small_jpeg__height_operator"] | undefined | null,
	sizes__small_jpeg__mimeType?: ResolverInputTypes["Gallery_Gallery_Item_sizes__small_jpeg__mimeType_operator"] | undefined | null,
	sizes__small_jpeg__filesize?: ResolverInputTypes["Gallery_Gallery_Item_sizes__small_jpeg__filesize_operator"] | undefined | null,
	sizes__small_jpeg__filename?: ResolverInputTypes["Gallery_Gallery_Item_sizes__small_jpeg__filename_operator"] | undefined | null,
	sizes__medium_jpeg__url?: ResolverInputTypes["Gallery_Gallery_Item_sizes__medium_jpeg__url_operator"] | undefined | null,
	sizes__medium_jpeg__width?: ResolverInputTypes["Gallery_Gallery_Item_sizes__medium_jpeg__width_operator"] | undefined | null,
	sizes__medium_jpeg__height?: ResolverInputTypes["Gallery_Gallery_Item_sizes__medium_jpeg__height_operator"] | undefined | null,
	sizes__medium_jpeg__mimeType?: ResolverInputTypes["Gallery_Gallery_Item_sizes__medium_jpeg__mimeType_operator"] | undefined | null,
	sizes__medium_jpeg__filesize?: ResolverInputTypes["Gallery_Gallery_Item_sizes__medium_jpeg__filesize_operator"] | undefined | null,
	sizes__medium_jpeg__filename?: ResolverInputTypes["Gallery_Gallery_Item_sizes__medium_jpeg__filename_operator"] | undefined | null,
	sizes__large_jpeg__url?: ResolverInputTypes["Gallery_Gallery_Item_sizes__large_jpeg__url_operator"] | undefined | null,
	sizes__large_jpeg__width?: ResolverInputTypes["Gallery_Gallery_Item_sizes__large_jpeg__width_operator"] | undefined | null,
	sizes__large_jpeg__height?: ResolverInputTypes["Gallery_Gallery_Item_sizes__large_jpeg__height_operator"] | undefined | null,
	sizes__large_jpeg__mimeType?: ResolverInputTypes["Gallery_Gallery_Item_sizes__large_jpeg__mimeType_operator"] | undefined | null,
	sizes__large_jpeg__filesize?: ResolverInputTypes["Gallery_Gallery_Item_sizes__large_jpeg__filesize_operator"] | undefined | null,
	sizes__large_jpeg__filename?: ResolverInputTypes["Gallery_Gallery_Item_sizes__large_jpeg__filename_operator"] | undefined | null,
	id?: ResolverInputTypes["Gallery_Gallery_Item_id_operator"] | undefined | null,
	AND?: Array<ResolverInputTypes["Gallery_Gallery_Item_where_and"] | undefined | null> | undefined | null,
	OR?: Array<ResolverInputTypes["Gallery_Gallery_Item_where_or"] | undefined | null> | undefined | null
};
	["Gallery_Gallery_Item_alt_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Gallery_Gallery_Item_updatedAt_operator"]: {
	equals?: ResolverInputTypes["DateTime"] | undefined | null,
	not_equals?: ResolverInputTypes["DateTime"] | undefined | null,
	greater_than_equal?: ResolverInputTypes["DateTime"] | undefined | null,
	greater_than?: ResolverInputTypes["DateTime"] | undefined | null,
	less_than_equal?: ResolverInputTypes["DateTime"] | undefined | null,
	less_than?: ResolverInputTypes["DateTime"] | undefined | null,
	like?: ResolverInputTypes["DateTime"] | undefined | null,
	exists?: boolean | undefined | null
};
	["Gallery_Gallery_Item_createdAt_operator"]: {
	equals?: ResolverInputTypes["DateTime"] | undefined | null,
	not_equals?: ResolverInputTypes["DateTime"] | undefined | null,
	greater_than_equal?: ResolverInputTypes["DateTime"] | undefined | null,
	greater_than?: ResolverInputTypes["DateTime"] | undefined | null,
	less_than_equal?: ResolverInputTypes["DateTime"] | undefined | null,
	less_than?: ResolverInputTypes["DateTime"] | undefined | null,
	like?: ResolverInputTypes["DateTime"] | undefined | null,
	exists?: boolean | undefined | null
};
	["Gallery_Gallery_Item_url_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Gallery_Gallery_Item_filename_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Gallery_Gallery_Item_mimeType_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Gallery_Gallery_Item_filesize_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Gallery_Gallery_Item_width_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Gallery_Gallery_Item_height_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Gallery_Gallery_Item_focalX_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Gallery_Gallery_Item_focalY_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Gallery_Gallery_Item_sizes__small__url_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Gallery_Gallery_Item_sizes__small__width_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Gallery_Gallery_Item_sizes__small__height_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Gallery_Gallery_Item_sizes__small__mimeType_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Gallery_Gallery_Item_sizes__small__filesize_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Gallery_Gallery_Item_sizes__small__filename_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Gallery_Gallery_Item_sizes__medium__url_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Gallery_Gallery_Item_sizes__medium__width_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Gallery_Gallery_Item_sizes__medium__height_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Gallery_Gallery_Item_sizes__medium__mimeType_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Gallery_Gallery_Item_sizes__medium__filesize_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Gallery_Gallery_Item_sizes__medium__filename_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Gallery_Gallery_Item_sizes__large__url_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Gallery_Gallery_Item_sizes__large__width_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Gallery_Gallery_Item_sizes__large__height_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Gallery_Gallery_Item_sizes__large__mimeType_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Gallery_Gallery_Item_sizes__large__filesize_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Gallery_Gallery_Item_sizes__large__filename_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Gallery_Gallery_Item_sizes__small_jpeg__url_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Gallery_Gallery_Item_sizes__small_jpeg__width_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Gallery_Gallery_Item_sizes__small_jpeg__height_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Gallery_Gallery_Item_sizes__small_jpeg__mimeType_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Gallery_Gallery_Item_sizes__small_jpeg__filesize_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Gallery_Gallery_Item_sizes__small_jpeg__filename_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Gallery_Gallery_Item_sizes__medium_jpeg__url_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Gallery_Gallery_Item_sizes__medium_jpeg__width_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Gallery_Gallery_Item_sizes__medium_jpeg__height_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Gallery_Gallery_Item_sizes__medium_jpeg__mimeType_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Gallery_Gallery_Item_sizes__medium_jpeg__filesize_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Gallery_Gallery_Item_sizes__medium_jpeg__filename_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Gallery_Gallery_Item_sizes__large_jpeg__url_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Gallery_Gallery_Item_sizes__large_jpeg__width_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Gallery_Gallery_Item_sizes__large_jpeg__height_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Gallery_Gallery_Item_sizes__large_jpeg__mimeType_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Gallery_Gallery_Item_sizes__large_jpeg__filesize_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Gallery_Gallery_Item_sizes__large_jpeg__filename_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Gallery_Gallery_Item_id_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Gallery_Gallery_Item_where_and"]: {
	alt?: ResolverInputTypes["Gallery_Gallery_Item_alt_operator"] | undefined | null,
	updatedAt?: ResolverInputTypes["Gallery_Gallery_Item_updatedAt_operator"] | undefined | null,
	createdAt?: ResolverInputTypes["Gallery_Gallery_Item_createdAt_operator"] | undefined | null,
	url?: ResolverInputTypes["Gallery_Gallery_Item_url_operator"] | undefined | null,
	filename?: ResolverInputTypes["Gallery_Gallery_Item_filename_operator"] | undefined | null,
	mimeType?: ResolverInputTypes["Gallery_Gallery_Item_mimeType_operator"] | undefined | null,
	filesize?: ResolverInputTypes["Gallery_Gallery_Item_filesize_operator"] | undefined | null,
	width?: ResolverInputTypes["Gallery_Gallery_Item_width_operator"] | undefined | null,
	height?: ResolverInputTypes["Gallery_Gallery_Item_height_operator"] | undefined | null,
	focalX?: ResolverInputTypes["Gallery_Gallery_Item_focalX_operator"] | undefined | null,
	focalY?: ResolverInputTypes["Gallery_Gallery_Item_focalY_operator"] | undefined | null,
	sizes__small__url?: ResolverInputTypes["Gallery_Gallery_Item_sizes__small__url_operator"] | undefined | null,
	sizes__small__width?: ResolverInputTypes["Gallery_Gallery_Item_sizes__small__width_operator"] | undefined | null,
	sizes__small__height?: ResolverInputTypes["Gallery_Gallery_Item_sizes__small__height_operator"] | undefined | null,
	sizes__small__mimeType?: ResolverInputTypes["Gallery_Gallery_Item_sizes__small__mimeType_operator"] | undefined | null,
	sizes__small__filesize?: ResolverInputTypes["Gallery_Gallery_Item_sizes__small__filesize_operator"] | undefined | null,
	sizes__small__filename?: ResolverInputTypes["Gallery_Gallery_Item_sizes__small__filename_operator"] | undefined | null,
	sizes__medium__url?: ResolverInputTypes["Gallery_Gallery_Item_sizes__medium__url_operator"] | undefined | null,
	sizes__medium__width?: ResolverInputTypes["Gallery_Gallery_Item_sizes__medium__width_operator"] | undefined | null,
	sizes__medium__height?: ResolverInputTypes["Gallery_Gallery_Item_sizes__medium__height_operator"] | undefined | null,
	sizes__medium__mimeType?: ResolverInputTypes["Gallery_Gallery_Item_sizes__medium__mimeType_operator"] | undefined | null,
	sizes__medium__filesize?: ResolverInputTypes["Gallery_Gallery_Item_sizes__medium__filesize_operator"] | undefined | null,
	sizes__medium__filename?: ResolverInputTypes["Gallery_Gallery_Item_sizes__medium__filename_operator"] | undefined | null,
	sizes__large__url?: ResolverInputTypes["Gallery_Gallery_Item_sizes__large__url_operator"] | undefined | null,
	sizes__large__width?: ResolverInputTypes["Gallery_Gallery_Item_sizes__large__width_operator"] | undefined | null,
	sizes__large__height?: ResolverInputTypes["Gallery_Gallery_Item_sizes__large__height_operator"] | undefined | null,
	sizes__large__mimeType?: ResolverInputTypes["Gallery_Gallery_Item_sizes__large__mimeType_operator"] | undefined | null,
	sizes__large__filesize?: ResolverInputTypes["Gallery_Gallery_Item_sizes__large__filesize_operator"] | undefined | null,
	sizes__large__filename?: ResolverInputTypes["Gallery_Gallery_Item_sizes__large__filename_operator"] | undefined | null,
	sizes__small_jpeg__url?: ResolverInputTypes["Gallery_Gallery_Item_sizes__small_jpeg__url_operator"] | undefined | null,
	sizes__small_jpeg__width?: ResolverInputTypes["Gallery_Gallery_Item_sizes__small_jpeg__width_operator"] | undefined | null,
	sizes__small_jpeg__height?: ResolverInputTypes["Gallery_Gallery_Item_sizes__small_jpeg__height_operator"] | undefined | null,
	sizes__small_jpeg__mimeType?: ResolverInputTypes["Gallery_Gallery_Item_sizes__small_jpeg__mimeType_operator"] | undefined | null,
	sizes__small_jpeg__filesize?: ResolverInputTypes["Gallery_Gallery_Item_sizes__small_jpeg__filesize_operator"] | undefined | null,
	sizes__small_jpeg__filename?: ResolverInputTypes["Gallery_Gallery_Item_sizes__small_jpeg__filename_operator"] | undefined | null,
	sizes__medium_jpeg__url?: ResolverInputTypes["Gallery_Gallery_Item_sizes__medium_jpeg__url_operator"] | undefined | null,
	sizes__medium_jpeg__width?: ResolverInputTypes["Gallery_Gallery_Item_sizes__medium_jpeg__width_operator"] | undefined | null,
	sizes__medium_jpeg__height?: ResolverInputTypes["Gallery_Gallery_Item_sizes__medium_jpeg__height_operator"] | undefined | null,
	sizes__medium_jpeg__mimeType?: ResolverInputTypes["Gallery_Gallery_Item_sizes__medium_jpeg__mimeType_operator"] | undefined | null,
	sizes__medium_jpeg__filesize?: ResolverInputTypes["Gallery_Gallery_Item_sizes__medium_jpeg__filesize_operator"] | undefined | null,
	sizes__medium_jpeg__filename?: ResolverInputTypes["Gallery_Gallery_Item_sizes__medium_jpeg__filename_operator"] | undefined | null,
	sizes__large_jpeg__url?: ResolverInputTypes["Gallery_Gallery_Item_sizes__large_jpeg__url_operator"] | undefined | null,
	sizes__large_jpeg__width?: ResolverInputTypes["Gallery_Gallery_Item_sizes__large_jpeg__width_operator"] | undefined | null,
	sizes__large_jpeg__height?: ResolverInputTypes["Gallery_Gallery_Item_sizes__large_jpeg__height_operator"] | undefined | null,
	sizes__large_jpeg__mimeType?: ResolverInputTypes["Gallery_Gallery_Item_sizes__large_jpeg__mimeType_operator"] | undefined | null,
	sizes__large_jpeg__filesize?: ResolverInputTypes["Gallery_Gallery_Item_sizes__large_jpeg__filesize_operator"] | undefined | null,
	sizes__large_jpeg__filename?: ResolverInputTypes["Gallery_Gallery_Item_sizes__large_jpeg__filename_operator"] | undefined | null,
	id?: ResolverInputTypes["Gallery_Gallery_Item_id_operator"] | undefined | null,
	AND?: Array<ResolverInputTypes["Gallery_Gallery_Item_where_and"] | undefined | null> | undefined | null,
	OR?: Array<ResolverInputTypes["Gallery_Gallery_Item_where_or"] | undefined | null> | undefined | null
};
	["Gallery_Gallery_Item_where_or"]: {
	alt?: ResolverInputTypes["Gallery_Gallery_Item_alt_operator"] | undefined | null,
	updatedAt?: ResolverInputTypes["Gallery_Gallery_Item_updatedAt_operator"] | undefined | null,
	createdAt?: ResolverInputTypes["Gallery_Gallery_Item_createdAt_operator"] | undefined | null,
	url?: ResolverInputTypes["Gallery_Gallery_Item_url_operator"] | undefined | null,
	filename?: ResolverInputTypes["Gallery_Gallery_Item_filename_operator"] | undefined | null,
	mimeType?: ResolverInputTypes["Gallery_Gallery_Item_mimeType_operator"] | undefined | null,
	filesize?: ResolverInputTypes["Gallery_Gallery_Item_filesize_operator"] | undefined | null,
	width?: ResolverInputTypes["Gallery_Gallery_Item_width_operator"] | undefined | null,
	height?: ResolverInputTypes["Gallery_Gallery_Item_height_operator"] | undefined | null,
	focalX?: ResolverInputTypes["Gallery_Gallery_Item_focalX_operator"] | undefined | null,
	focalY?: ResolverInputTypes["Gallery_Gallery_Item_focalY_operator"] | undefined | null,
	sizes__small__url?: ResolverInputTypes["Gallery_Gallery_Item_sizes__small__url_operator"] | undefined | null,
	sizes__small__width?: ResolverInputTypes["Gallery_Gallery_Item_sizes__small__width_operator"] | undefined | null,
	sizes__small__height?: ResolverInputTypes["Gallery_Gallery_Item_sizes__small__height_operator"] | undefined | null,
	sizes__small__mimeType?: ResolverInputTypes["Gallery_Gallery_Item_sizes__small__mimeType_operator"] | undefined | null,
	sizes__small__filesize?: ResolverInputTypes["Gallery_Gallery_Item_sizes__small__filesize_operator"] | undefined | null,
	sizes__small__filename?: ResolverInputTypes["Gallery_Gallery_Item_sizes__small__filename_operator"] | undefined | null,
	sizes__medium__url?: ResolverInputTypes["Gallery_Gallery_Item_sizes__medium__url_operator"] | undefined | null,
	sizes__medium__width?: ResolverInputTypes["Gallery_Gallery_Item_sizes__medium__width_operator"] | undefined | null,
	sizes__medium__height?: ResolverInputTypes["Gallery_Gallery_Item_sizes__medium__height_operator"] | undefined | null,
	sizes__medium__mimeType?: ResolverInputTypes["Gallery_Gallery_Item_sizes__medium__mimeType_operator"] | undefined | null,
	sizes__medium__filesize?: ResolverInputTypes["Gallery_Gallery_Item_sizes__medium__filesize_operator"] | undefined | null,
	sizes__medium__filename?: ResolverInputTypes["Gallery_Gallery_Item_sizes__medium__filename_operator"] | undefined | null,
	sizes__large__url?: ResolverInputTypes["Gallery_Gallery_Item_sizes__large__url_operator"] | undefined | null,
	sizes__large__width?: ResolverInputTypes["Gallery_Gallery_Item_sizes__large__width_operator"] | undefined | null,
	sizes__large__height?: ResolverInputTypes["Gallery_Gallery_Item_sizes__large__height_operator"] | undefined | null,
	sizes__large__mimeType?: ResolverInputTypes["Gallery_Gallery_Item_sizes__large__mimeType_operator"] | undefined | null,
	sizes__large__filesize?: ResolverInputTypes["Gallery_Gallery_Item_sizes__large__filesize_operator"] | undefined | null,
	sizes__large__filename?: ResolverInputTypes["Gallery_Gallery_Item_sizes__large__filename_operator"] | undefined | null,
	sizes__small_jpeg__url?: ResolverInputTypes["Gallery_Gallery_Item_sizes__small_jpeg__url_operator"] | undefined | null,
	sizes__small_jpeg__width?: ResolverInputTypes["Gallery_Gallery_Item_sizes__small_jpeg__width_operator"] | undefined | null,
	sizes__small_jpeg__height?: ResolverInputTypes["Gallery_Gallery_Item_sizes__small_jpeg__height_operator"] | undefined | null,
	sizes__small_jpeg__mimeType?: ResolverInputTypes["Gallery_Gallery_Item_sizes__small_jpeg__mimeType_operator"] | undefined | null,
	sizes__small_jpeg__filesize?: ResolverInputTypes["Gallery_Gallery_Item_sizes__small_jpeg__filesize_operator"] | undefined | null,
	sizes__small_jpeg__filename?: ResolverInputTypes["Gallery_Gallery_Item_sizes__small_jpeg__filename_operator"] | undefined | null,
	sizes__medium_jpeg__url?: ResolverInputTypes["Gallery_Gallery_Item_sizes__medium_jpeg__url_operator"] | undefined | null,
	sizes__medium_jpeg__width?: ResolverInputTypes["Gallery_Gallery_Item_sizes__medium_jpeg__width_operator"] | undefined | null,
	sizes__medium_jpeg__height?: ResolverInputTypes["Gallery_Gallery_Item_sizes__medium_jpeg__height_operator"] | undefined | null,
	sizes__medium_jpeg__mimeType?: ResolverInputTypes["Gallery_Gallery_Item_sizes__medium_jpeg__mimeType_operator"] | undefined | null,
	sizes__medium_jpeg__filesize?: ResolverInputTypes["Gallery_Gallery_Item_sizes__medium_jpeg__filesize_operator"] | undefined | null,
	sizes__medium_jpeg__filename?: ResolverInputTypes["Gallery_Gallery_Item_sizes__medium_jpeg__filename_operator"] | undefined | null,
	sizes__large_jpeg__url?: ResolverInputTypes["Gallery_Gallery_Item_sizes__large_jpeg__url_operator"] | undefined | null,
	sizes__large_jpeg__width?: ResolverInputTypes["Gallery_Gallery_Item_sizes__large_jpeg__width_operator"] | undefined | null,
	sizes__large_jpeg__height?: ResolverInputTypes["Gallery_Gallery_Item_sizes__large_jpeg__height_operator"] | undefined | null,
	sizes__large_jpeg__mimeType?: ResolverInputTypes["Gallery_Gallery_Item_sizes__large_jpeg__mimeType_operator"] | undefined | null,
	sizes__large_jpeg__filesize?: ResolverInputTypes["Gallery_Gallery_Item_sizes__large_jpeg__filesize_operator"] | undefined | null,
	sizes__large_jpeg__filename?: ResolverInputTypes["Gallery_Gallery_Item_sizes__large_jpeg__filename_operator"] | undefined | null,
	id?: ResolverInputTypes["Gallery_Gallery_Item_id_operator"] | undefined | null,
	AND?: Array<ResolverInputTypes["Gallery_Gallery_Item_where_and"] | undefined | null> | undefined | null,
	OR?: Array<ResolverInputTypes["Gallery_Gallery_Item_where_or"] | undefined | null> | undefined | null
};
	["Image"]: AliasType<{
media?: [{	locale?: ResolverInputTypes["LocaleInputType"] | undefined | null,	fallbackLocale?: ResolverInputTypes["FallbackLocaleInputType"] | undefined | null,	where?: ResolverInputTypes["Image_Media_where"] | undefined | null},ResolverInputTypes["Media"]],
	caption?:boolean | `@${string}`,
	id?:boolean | `@${string}`,
	blockName?:boolean | `@${string}`,
	blockType?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["Image_Media_where"]: {
	alt?: ResolverInputTypes["Image_Media_alt_operator"] | undefined | null,
	updatedAt?: ResolverInputTypes["Image_Media_updatedAt_operator"] | undefined | null,
	createdAt?: ResolverInputTypes["Image_Media_createdAt_operator"] | undefined | null,
	url?: ResolverInputTypes["Image_Media_url_operator"] | undefined | null,
	filename?: ResolverInputTypes["Image_Media_filename_operator"] | undefined | null,
	mimeType?: ResolverInputTypes["Image_Media_mimeType_operator"] | undefined | null,
	filesize?: ResolverInputTypes["Image_Media_filesize_operator"] | undefined | null,
	width?: ResolverInputTypes["Image_Media_width_operator"] | undefined | null,
	height?: ResolverInputTypes["Image_Media_height_operator"] | undefined | null,
	focalX?: ResolverInputTypes["Image_Media_focalX_operator"] | undefined | null,
	focalY?: ResolverInputTypes["Image_Media_focalY_operator"] | undefined | null,
	sizes__small__url?: ResolverInputTypes["Image_Media_sizes__small__url_operator"] | undefined | null,
	sizes__small__width?: ResolverInputTypes["Image_Media_sizes__small__width_operator"] | undefined | null,
	sizes__small__height?: ResolverInputTypes["Image_Media_sizes__small__height_operator"] | undefined | null,
	sizes__small__mimeType?: ResolverInputTypes["Image_Media_sizes__small__mimeType_operator"] | undefined | null,
	sizes__small__filesize?: ResolverInputTypes["Image_Media_sizes__small__filesize_operator"] | undefined | null,
	sizes__small__filename?: ResolverInputTypes["Image_Media_sizes__small__filename_operator"] | undefined | null,
	sizes__medium__url?: ResolverInputTypes["Image_Media_sizes__medium__url_operator"] | undefined | null,
	sizes__medium__width?: ResolverInputTypes["Image_Media_sizes__medium__width_operator"] | undefined | null,
	sizes__medium__height?: ResolverInputTypes["Image_Media_sizes__medium__height_operator"] | undefined | null,
	sizes__medium__mimeType?: ResolverInputTypes["Image_Media_sizes__medium__mimeType_operator"] | undefined | null,
	sizes__medium__filesize?: ResolverInputTypes["Image_Media_sizes__medium__filesize_operator"] | undefined | null,
	sizes__medium__filename?: ResolverInputTypes["Image_Media_sizes__medium__filename_operator"] | undefined | null,
	sizes__large__url?: ResolverInputTypes["Image_Media_sizes__large__url_operator"] | undefined | null,
	sizes__large__width?: ResolverInputTypes["Image_Media_sizes__large__width_operator"] | undefined | null,
	sizes__large__height?: ResolverInputTypes["Image_Media_sizes__large__height_operator"] | undefined | null,
	sizes__large__mimeType?: ResolverInputTypes["Image_Media_sizes__large__mimeType_operator"] | undefined | null,
	sizes__large__filesize?: ResolverInputTypes["Image_Media_sizes__large__filesize_operator"] | undefined | null,
	sizes__large__filename?: ResolverInputTypes["Image_Media_sizes__large__filename_operator"] | undefined | null,
	sizes__small_jpeg__url?: ResolverInputTypes["Image_Media_sizes__small_jpeg__url_operator"] | undefined | null,
	sizes__small_jpeg__width?: ResolverInputTypes["Image_Media_sizes__small_jpeg__width_operator"] | undefined | null,
	sizes__small_jpeg__height?: ResolverInputTypes["Image_Media_sizes__small_jpeg__height_operator"] | undefined | null,
	sizes__small_jpeg__mimeType?: ResolverInputTypes["Image_Media_sizes__small_jpeg__mimeType_operator"] | undefined | null,
	sizes__small_jpeg__filesize?: ResolverInputTypes["Image_Media_sizes__small_jpeg__filesize_operator"] | undefined | null,
	sizes__small_jpeg__filename?: ResolverInputTypes["Image_Media_sizes__small_jpeg__filename_operator"] | undefined | null,
	sizes__medium_jpeg__url?: ResolverInputTypes["Image_Media_sizes__medium_jpeg__url_operator"] | undefined | null,
	sizes__medium_jpeg__width?: ResolverInputTypes["Image_Media_sizes__medium_jpeg__width_operator"] | undefined | null,
	sizes__medium_jpeg__height?: ResolverInputTypes["Image_Media_sizes__medium_jpeg__height_operator"] | undefined | null,
	sizes__medium_jpeg__mimeType?: ResolverInputTypes["Image_Media_sizes__medium_jpeg__mimeType_operator"] | undefined | null,
	sizes__medium_jpeg__filesize?: ResolverInputTypes["Image_Media_sizes__medium_jpeg__filesize_operator"] | undefined | null,
	sizes__medium_jpeg__filename?: ResolverInputTypes["Image_Media_sizes__medium_jpeg__filename_operator"] | undefined | null,
	sizes__large_jpeg__url?: ResolverInputTypes["Image_Media_sizes__large_jpeg__url_operator"] | undefined | null,
	sizes__large_jpeg__width?: ResolverInputTypes["Image_Media_sizes__large_jpeg__width_operator"] | undefined | null,
	sizes__large_jpeg__height?: ResolverInputTypes["Image_Media_sizes__large_jpeg__height_operator"] | undefined | null,
	sizes__large_jpeg__mimeType?: ResolverInputTypes["Image_Media_sizes__large_jpeg__mimeType_operator"] | undefined | null,
	sizes__large_jpeg__filesize?: ResolverInputTypes["Image_Media_sizes__large_jpeg__filesize_operator"] | undefined | null,
	sizes__large_jpeg__filename?: ResolverInputTypes["Image_Media_sizes__large_jpeg__filename_operator"] | undefined | null,
	id?: ResolverInputTypes["Image_Media_id_operator"] | undefined | null,
	AND?: Array<ResolverInputTypes["Image_Media_where_and"] | undefined | null> | undefined | null,
	OR?: Array<ResolverInputTypes["Image_Media_where_or"] | undefined | null> | undefined | null
};
	["Image_Media_alt_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Image_Media_updatedAt_operator"]: {
	equals?: ResolverInputTypes["DateTime"] | undefined | null,
	not_equals?: ResolverInputTypes["DateTime"] | undefined | null,
	greater_than_equal?: ResolverInputTypes["DateTime"] | undefined | null,
	greater_than?: ResolverInputTypes["DateTime"] | undefined | null,
	less_than_equal?: ResolverInputTypes["DateTime"] | undefined | null,
	less_than?: ResolverInputTypes["DateTime"] | undefined | null,
	like?: ResolverInputTypes["DateTime"] | undefined | null,
	exists?: boolean | undefined | null
};
	["Image_Media_createdAt_operator"]: {
	equals?: ResolverInputTypes["DateTime"] | undefined | null,
	not_equals?: ResolverInputTypes["DateTime"] | undefined | null,
	greater_than_equal?: ResolverInputTypes["DateTime"] | undefined | null,
	greater_than?: ResolverInputTypes["DateTime"] | undefined | null,
	less_than_equal?: ResolverInputTypes["DateTime"] | undefined | null,
	less_than?: ResolverInputTypes["DateTime"] | undefined | null,
	like?: ResolverInputTypes["DateTime"] | undefined | null,
	exists?: boolean | undefined | null
};
	["Image_Media_url_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Image_Media_filename_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Image_Media_mimeType_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Image_Media_filesize_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Image_Media_width_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Image_Media_height_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Image_Media_focalX_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Image_Media_focalY_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Image_Media_sizes__small__url_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Image_Media_sizes__small__width_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Image_Media_sizes__small__height_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Image_Media_sizes__small__mimeType_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Image_Media_sizes__small__filesize_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Image_Media_sizes__small__filename_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Image_Media_sizes__medium__url_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Image_Media_sizes__medium__width_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Image_Media_sizes__medium__height_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Image_Media_sizes__medium__mimeType_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Image_Media_sizes__medium__filesize_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Image_Media_sizes__medium__filename_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Image_Media_sizes__large__url_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Image_Media_sizes__large__width_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Image_Media_sizes__large__height_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Image_Media_sizes__large__mimeType_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Image_Media_sizes__large__filesize_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Image_Media_sizes__large__filename_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Image_Media_sizes__small_jpeg__url_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Image_Media_sizes__small_jpeg__width_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Image_Media_sizes__small_jpeg__height_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Image_Media_sizes__small_jpeg__mimeType_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Image_Media_sizes__small_jpeg__filesize_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Image_Media_sizes__small_jpeg__filename_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Image_Media_sizes__medium_jpeg__url_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Image_Media_sizes__medium_jpeg__width_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Image_Media_sizes__medium_jpeg__height_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Image_Media_sizes__medium_jpeg__mimeType_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Image_Media_sizes__medium_jpeg__filesize_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Image_Media_sizes__medium_jpeg__filename_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Image_Media_sizes__large_jpeg__url_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Image_Media_sizes__large_jpeg__width_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Image_Media_sizes__large_jpeg__height_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Image_Media_sizes__large_jpeg__mimeType_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Image_Media_sizes__large_jpeg__filesize_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Image_Media_sizes__large_jpeg__filename_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Image_Media_id_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Image_Media_where_and"]: {
	alt?: ResolverInputTypes["Image_Media_alt_operator"] | undefined | null,
	updatedAt?: ResolverInputTypes["Image_Media_updatedAt_operator"] | undefined | null,
	createdAt?: ResolverInputTypes["Image_Media_createdAt_operator"] | undefined | null,
	url?: ResolverInputTypes["Image_Media_url_operator"] | undefined | null,
	filename?: ResolverInputTypes["Image_Media_filename_operator"] | undefined | null,
	mimeType?: ResolverInputTypes["Image_Media_mimeType_operator"] | undefined | null,
	filesize?: ResolverInputTypes["Image_Media_filesize_operator"] | undefined | null,
	width?: ResolverInputTypes["Image_Media_width_operator"] | undefined | null,
	height?: ResolverInputTypes["Image_Media_height_operator"] | undefined | null,
	focalX?: ResolverInputTypes["Image_Media_focalX_operator"] | undefined | null,
	focalY?: ResolverInputTypes["Image_Media_focalY_operator"] | undefined | null,
	sizes__small__url?: ResolverInputTypes["Image_Media_sizes__small__url_operator"] | undefined | null,
	sizes__small__width?: ResolverInputTypes["Image_Media_sizes__small__width_operator"] | undefined | null,
	sizes__small__height?: ResolverInputTypes["Image_Media_sizes__small__height_operator"] | undefined | null,
	sizes__small__mimeType?: ResolverInputTypes["Image_Media_sizes__small__mimeType_operator"] | undefined | null,
	sizes__small__filesize?: ResolverInputTypes["Image_Media_sizes__small__filesize_operator"] | undefined | null,
	sizes__small__filename?: ResolverInputTypes["Image_Media_sizes__small__filename_operator"] | undefined | null,
	sizes__medium__url?: ResolverInputTypes["Image_Media_sizes__medium__url_operator"] | undefined | null,
	sizes__medium__width?: ResolverInputTypes["Image_Media_sizes__medium__width_operator"] | undefined | null,
	sizes__medium__height?: ResolverInputTypes["Image_Media_sizes__medium__height_operator"] | undefined | null,
	sizes__medium__mimeType?: ResolverInputTypes["Image_Media_sizes__medium__mimeType_operator"] | undefined | null,
	sizes__medium__filesize?: ResolverInputTypes["Image_Media_sizes__medium__filesize_operator"] | undefined | null,
	sizes__medium__filename?: ResolverInputTypes["Image_Media_sizes__medium__filename_operator"] | undefined | null,
	sizes__large__url?: ResolverInputTypes["Image_Media_sizes__large__url_operator"] | undefined | null,
	sizes__large__width?: ResolverInputTypes["Image_Media_sizes__large__width_operator"] | undefined | null,
	sizes__large__height?: ResolverInputTypes["Image_Media_sizes__large__height_operator"] | undefined | null,
	sizes__large__mimeType?: ResolverInputTypes["Image_Media_sizes__large__mimeType_operator"] | undefined | null,
	sizes__large__filesize?: ResolverInputTypes["Image_Media_sizes__large__filesize_operator"] | undefined | null,
	sizes__large__filename?: ResolverInputTypes["Image_Media_sizes__large__filename_operator"] | undefined | null,
	sizes__small_jpeg__url?: ResolverInputTypes["Image_Media_sizes__small_jpeg__url_operator"] | undefined | null,
	sizes__small_jpeg__width?: ResolverInputTypes["Image_Media_sizes__small_jpeg__width_operator"] | undefined | null,
	sizes__small_jpeg__height?: ResolverInputTypes["Image_Media_sizes__small_jpeg__height_operator"] | undefined | null,
	sizes__small_jpeg__mimeType?: ResolverInputTypes["Image_Media_sizes__small_jpeg__mimeType_operator"] | undefined | null,
	sizes__small_jpeg__filesize?: ResolverInputTypes["Image_Media_sizes__small_jpeg__filesize_operator"] | undefined | null,
	sizes__small_jpeg__filename?: ResolverInputTypes["Image_Media_sizes__small_jpeg__filename_operator"] | undefined | null,
	sizes__medium_jpeg__url?: ResolverInputTypes["Image_Media_sizes__medium_jpeg__url_operator"] | undefined | null,
	sizes__medium_jpeg__width?: ResolverInputTypes["Image_Media_sizes__medium_jpeg__width_operator"] | undefined | null,
	sizes__medium_jpeg__height?: ResolverInputTypes["Image_Media_sizes__medium_jpeg__height_operator"] | undefined | null,
	sizes__medium_jpeg__mimeType?: ResolverInputTypes["Image_Media_sizes__medium_jpeg__mimeType_operator"] | undefined | null,
	sizes__medium_jpeg__filesize?: ResolverInputTypes["Image_Media_sizes__medium_jpeg__filesize_operator"] | undefined | null,
	sizes__medium_jpeg__filename?: ResolverInputTypes["Image_Media_sizes__medium_jpeg__filename_operator"] | undefined | null,
	sizes__large_jpeg__url?: ResolverInputTypes["Image_Media_sizes__large_jpeg__url_operator"] | undefined | null,
	sizes__large_jpeg__width?: ResolverInputTypes["Image_Media_sizes__large_jpeg__width_operator"] | undefined | null,
	sizes__large_jpeg__height?: ResolverInputTypes["Image_Media_sizes__large_jpeg__height_operator"] | undefined | null,
	sizes__large_jpeg__mimeType?: ResolverInputTypes["Image_Media_sizes__large_jpeg__mimeType_operator"] | undefined | null,
	sizes__large_jpeg__filesize?: ResolverInputTypes["Image_Media_sizes__large_jpeg__filesize_operator"] | undefined | null,
	sizes__large_jpeg__filename?: ResolverInputTypes["Image_Media_sizes__large_jpeg__filename_operator"] | undefined | null,
	id?: ResolverInputTypes["Image_Media_id_operator"] | undefined | null,
	AND?: Array<ResolverInputTypes["Image_Media_where_and"] | undefined | null> | undefined | null,
	OR?: Array<ResolverInputTypes["Image_Media_where_or"] | undefined | null> | undefined | null
};
	["Image_Media_where_or"]: {
	alt?: ResolverInputTypes["Image_Media_alt_operator"] | undefined | null,
	updatedAt?: ResolverInputTypes["Image_Media_updatedAt_operator"] | undefined | null,
	createdAt?: ResolverInputTypes["Image_Media_createdAt_operator"] | undefined | null,
	url?: ResolverInputTypes["Image_Media_url_operator"] | undefined | null,
	filename?: ResolverInputTypes["Image_Media_filename_operator"] | undefined | null,
	mimeType?: ResolverInputTypes["Image_Media_mimeType_operator"] | undefined | null,
	filesize?: ResolverInputTypes["Image_Media_filesize_operator"] | undefined | null,
	width?: ResolverInputTypes["Image_Media_width_operator"] | undefined | null,
	height?: ResolverInputTypes["Image_Media_height_operator"] | undefined | null,
	focalX?: ResolverInputTypes["Image_Media_focalX_operator"] | undefined | null,
	focalY?: ResolverInputTypes["Image_Media_focalY_operator"] | undefined | null,
	sizes__small__url?: ResolverInputTypes["Image_Media_sizes__small__url_operator"] | undefined | null,
	sizes__small__width?: ResolverInputTypes["Image_Media_sizes__small__width_operator"] | undefined | null,
	sizes__small__height?: ResolverInputTypes["Image_Media_sizes__small__height_operator"] | undefined | null,
	sizes__small__mimeType?: ResolverInputTypes["Image_Media_sizes__small__mimeType_operator"] | undefined | null,
	sizes__small__filesize?: ResolverInputTypes["Image_Media_sizes__small__filesize_operator"] | undefined | null,
	sizes__small__filename?: ResolverInputTypes["Image_Media_sizes__small__filename_operator"] | undefined | null,
	sizes__medium__url?: ResolverInputTypes["Image_Media_sizes__medium__url_operator"] | undefined | null,
	sizes__medium__width?: ResolverInputTypes["Image_Media_sizes__medium__width_operator"] | undefined | null,
	sizes__medium__height?: ResolverInputTypes["Image_Media_sizes__medium__height_operator"] | undefined | null,
	sizes__medium__mimeType?: ResolverInputTypes["Image_Media_sizes__medium__mimeType_operator"] | undefined | null,
	sizes__medium__filesize?: ResolverInputTypes["Image_Media_sizes__medium__filesize_operator"] | undefined | null,
	sizes__medium__filename?: ResolverInputTypes["Image_Media_sizes__medium__filename_operator"] | undefined | null,
	sizes__large__url?: ResolverInputTypes["Image_Media_sizes__large__url_operator"] | undefined | null,
	sizes__large__width?: ResolverInputTypes["Image_Media_sizes__large__width_operator"] | undefined | null,
	sizes__large__height?: ResolverInputTypes["Image_Media_sizes__large__height_operator"] | undefined | null,
	sizes__large__mimeType?: ResolverInputTypes["Image_Media_sizes__large__mimeType_operator"] | undefined | null,
	sizes__large__filesize?: ResolverInputTypes["Image_Media_sizes__large__filesize_operator"] | undefined | null,
	sizes__large__filename?: ResolverInputTypes["Image_Media_sizes__large__filename_operator"] | undefined | null,
	sizes__small_jpeg__url?: ResolverInputTypes["Image_Media_sizes__small_jpeg__url_operator"] | undefined | null,
	sizes__small_jpeg__width?: ResolverInputTypes["Image_Media_sizes__small_jpeg__width_operator"] | undefined | null,
	sizes__small_jpeg__height?: ResolverInputTypes["Image_Media_sizes__small_jpeg__height_operator"] | undefined | null,
	sizes__small_jpeg__mimeType?: ResolverInputTypes["Image_Media_sizes__small_jpeg__mimeType_operator"] | undefined | null,
	sizes__small_jpeg__filesize?: ResolverInputTypes["Image_Media_sizes__small_jpeg__filesize_operator"] | undefined | null,
	sizes__small_jpeg__filename?: ResolverInputTypes["Image_Media_sizes__small_jpeg__filename_operator"] | undefined | null,
	sizes__medium_jpeg__url?: ResolverInputTypes["Image_Media_sizes__medium_jpeg__url_operator"] | undefined | null,
	sizes__medium_jpeg__width?: ResolverInputTypes["Image_Media_sizes__medium_jpeg__width_operator"] | undefined | null,
	sizes__medium_jpeg__height?: ResolverInputTypes["Image_Media_sizes__medium_jpeg__height_operator"] | undefined | null,
	sizes__medium_jpeg__mimeType?: ResolverInputTypes["Image_Media_sizes__medium_jpeg__mimeType_operator"] | undefined | null,
	sizes__medium_jpeg__filesize?: ResolverInputTypes["Image_Media_sizes__medium_jpeg__filesize_operator"] | undefined | null,
	sizes__medium_jpeg__filename?: ResolverInputTypes["Image_Media_sizes__medium_jpeg__filename_operator"] | undefined | null,
	sizes__large_jpeg__url?: ResolverInputTypes["Image_Media_sizes__large_jpeg__url_operator"] | undefined | null,
	sizes__large_jpeg__width?: ResolverInputTypes["Image_Media_sizes__large_jpeg__width_operator"] | undefined | null,
	sizes__large_jpeg__height?: ResolverInputTypes["Image_Media_sizes__large_jpeg__height_operator"] | undefined | null,
	sizes__large_jpeg__mimeType?: ResolverInputTypes["Image_Media_sizes__large_jpeg__mimeType_operator"] | undefined | null,
	sizes__large_jpeg__filesize?: ResolverInputTypes["Image_Media_sizes__large_jpeg__filesize_operator"] | undefined | null,
	sizes__large_jpeg__filename?: ResolverInputTypes["Image_Media_sizes__large_jpeg__filename_operator"] | undefined | null,
	id?: ResolverInputTypes["Image_Media_id_operator"] | undefined | null,
	AND?: Array<ResolverInputTypes["Image_Media_where_and"] | undefined | null> | undefined | null,
	OR?: Array<ResolverInputTypes["Image_Media_where_or"] | undefined | null> | undefined | null
};
	["Video"]: AliasType<{
	url?:boolean | `@${string}`,
	id?:boolean | `@${string}`,
	blockName?:boolean | `@${string}`,
	blockType?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["Location_Criteria"]: AliasType<{
audiences?: [{	locale?: ResolverInputTypes["LocaleInputType"] | undefined | null,	fallbackLocale?: ResolverInputTypes["FallbackLocaleInputType"] | undefined | null},ResolverInputTypes["Audience"]],
dates?: [{	locale?: ResolverInputTypes["LocaleInputType"] | undefined | null,	fallbackLocale?: ResolverInputTypes["FallbackLocaleInputType"] | undefined | null},ResolverInputTypes["Date"]],
categories?: [{	locale?: ResolverInputTypes["LocaleInputType"] | undefined | null,	fallbackLocale?: ResolverInputTypes["FallbackLocaleInputType"] | undefined | null},ResolverInputTypes["EventCategory"]],
tags?: [{	locale?: ResolverInputTypes["LocaleInputType"] | undefined | null,	fallbackLocale?: ResolverInputTypes["FallbackLocaleInputType"] | undefined | null},ResolverInputTypes["Tag"]],
		__typename?: boolean | `@${string}`
}>;
	["Audience"]: AliasType<{
	id?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	value?:boolean | `@${string}`,
icon?: [{	locale?: ResolverInputTypes["LocaleInputType"] | undefined | null,	fallbackLocale?: ResolverInputTypes["FallbackLocaleInputType"] | undefined | null,	where?: ResolverInputTypes["Audience_Icon_where"] | undefined | null},ResolverInputTypes["Media"]],
	region?:boolean | `@${string}`,
	updatedAt?:boolean | `@${string}`,
	createdAt?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["Audience_Icon_where"]: {
	alt?: ResolverInputTypes["Audience_Icon_alt_operator"] | undefined | null,
	updatedAt?: ResolverInputTypes["Audience_Icon_updatedAt_operator"] | undefined | null,
	createdAt?: ResolverInputTypes["Audience_Icon_createdAt_operator"] | undefined | null,
	url?: ResolverInputTypes["Audience_Icon_url_operator"] | undefined | null,
	filename?: ResolverInputTypes["Audience_Icon_filename_operator"] | undefined | null,
	mimeType?: ResolverInputTypes["Audience_Icon_mimeType_operator"] | undefined | null,
	filesize?: ResolverInputTypes["Audience_Icon_filesize_operator"] | undefined | null,
	width?: ResolverInputTypes["Audience_Icon_width_operator"] | undefined | null,
	height?: ResolverInputTypes["Audience_Icon_height_operator"] | undefined | null,
	focalX?: ResolverInputTypes["Audience_Icon_focalX_operator"] | undefined | null,
	focalY?: ResolverInputTypes["Audience_Icon_focalY_operator"] | undefined | null,
	sizes__small__url?: ResolverInputTypes["Audience_Icon_sizes__small__url_operator"] | undefined | null,
	sizes__small__width?: ResolverInputTypes["Audience_Icon_sizes__small__width_operator"] | undefined | null,
	sizes__small__height?: ResolverInputTypes["Audience_Icon_sizes__small__height_operator"] | undefined | null,
	sizes__small__mimeType?: ResolverInputTypes["Audience_Icon_sizes__small__mimeType_operator"] | undefined | null,
	sizes__small__filesize?: ResolverInputTypes["Audience_Icon_sizes__small__filesize_operator"] | undefined | null,
	sizes__small__filename?: ResolverInputTypes["Audience_Icon_sizes__small__filename_operator"] | undefined | null,
	sizes__medium__url?: ResolverInputTypes["Audience_Icon_sizes__medium__url_operator"] | undefined | null,
	sizes__medium__width?: ResolverInputTypes["Audience_Icon_sizes__medium__width_operator"] | undefined | null,
	sizes__medium__height?: ResolverInputTypes["Audience_Icon_sizes__medium__height_operator"] | undefined | null,
	sizes__medium__mimeType?: ResolverInputTypes["Audience_Icon_sizes__medium__mimeType_operator"] | undefined | null,
	sizes__medium__filesize?: ResolverInputTypes["Audience_Icon_sizes__medium__filesize_operator"] | undefined | null,
	sizes__medium__filename?: ResolverInputTypes["Audience_Icon_sizes__medium__filename_operator"] | undefined | null,
	sizes__large__url?: ResolverInputTypes["Audience_Icon_sizes__large__url_operator"] | undefined | null,
	sizes__large__width?: ResolverInputTypes["Audience_Icon_sizes__large__width_operator"] | undefined | null,
	sizes__large__height?: ResolverInputTypes["Audience_Icon_sizes__large__height_operator"] | undefined | null,
	sizes__large__mimeType?: ResolverInputTypes["Audience_Icon_sizes__large__mimeType_operator"] | undefined | null,
	sizes__large__filesize?: ResolverInputTypes["Audience_Icon_sizes__large__filesize_operator"] | undefined | null,
	sizes__large__filename?: ResolverInputTypes["Audience_Icon_sizes__large__filename_operator"] | undefined | null,
	sizes__small_jpeg__url?: ResolverInputTypes["Audience_Icon_sizes__small_jpeg__url_operator"] | undefined | null,
	sizes__small_jpeg__width?: ResolverInputTypes["Audience_Icon_sizes__small_jpeg__width_operator"] | undefined | null,
	sizes__small_jpeg__height?: ResolverInputTypes["Audience_Icon_sizes__small_jpeg__height_operator"] | undefined | null,
	sizes__small_jpeg__mimeType?: ResolverInputTypes["Audience_Icon_sizes__small_jpeg__mimeType_operator"] | undefined | null,
	sizes__small_jpeg__filesize?: ResolverInputTypes["Audience_Icon_sizes__small_jpeg__filesize_operator"] | undefined | null,
	sizes__small_jpeg__filename?: ResolverInputTypes["Audience_Icon_sizes__small_jpeg__filename_operator"] | undefined | null,
	sizes__medium_jpeg__url?: ResolverInputTypes["Audience_Icon_sizes__medium_jpeg__url_operator"] | undefined | null,
	sizes__medium_jpeg__width?: ResolverInputTypes["Audience_Icon_sizes__medium_jpeg__width_operator"] | undefined | null,
	sizes__medium_jpeg__height?: ResolverInputTypes["Audience_Icon_sizes__medium_jpeg__height_operator"] | undefined | null,
	sizes__medium_jpeg__mimeType?: ResolverInputTypes["Audience_Icon_sizes__medium_jpeg__mimeType_operator"] | undefined | null,
	sizes__medium_jpeg__filesize?: ResolverInputTypes["Audience_Icon_sizes__medium_jpeg__filesize_operator"] | undefined | null,
	sizes__medium_jpeg__filename?: ResolverInputTypes["Audience_Icon_sizes__medium_jpeg__filename_operator"] | undefined | null,
	sizes__large_jpeg__url?: ResolverInputTypes["Audience_Icon_sizes__large_jpeg__url_operator"] | undefined | null,
	sizes__large_jpeg__width?: ResolverInputTypes["Audience_Icon_sizes__large_jpeg__width_operator"] | undefined | null,
	sizes__large_jpeg__height?: ResolverInputTypes["Audience_Icon_sizes__large_jpeg__height_operator"] | undefined | null,
	sizes__large_jpeg__mimeType?: ResolverInputTypes["Audience_Icon_sizes__large_jpeg__mimeType_operator"] | undefined | null,
	sizes__large_jpeg__filesize?: ResolverInputTypes["Audience_Icon_sizes__large_jpeg__filesize_operator"] | undefined | null,
	sizes__large_jpeg__filename?: ResolverInputTypes["Audience_Icon_sizes__large_jpeg__filename_operator"] | undefined | null,
	id?: ResolverInputTypes["Audience_Icon_id_operator"] | undefined | null,
	AND?: Array<ResolverInputTypes["Audience_Icon_where_and"] | undefined | null> | undefined | null,
	OR?: Array<ResolverInputTypes["Audience_Icon_where_or"] | undefined | null> | undefined | null
};
	["Audience_Icon_alt_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Audience_Icon_updatedAt_operator"]: {
	equals?: ResolverInputTypes["DateTime"] | undefined | null,
	not_equals?: ResolverInputTypes["DateTime"] | undefined | null,
	greater_than_equal?: ResolverInputTypes["DateTime"] | undefined | null,
	greater_than?: ResolverInputTypes["DateTime"] | undefined | null,
	less_than_equal?: ResolverInputTypes["DateTime"] | undefined | null,
	less_than?: ResolverInputTypes["DateTime"] | undefined | null,
	like?: ResolverInputTypes["DateTime"] | undefined | null,
	exists?: boolean | undefined | null
};
	["Audience_Icon_createdAt_operator"]: {
	equals?: ResolverInputTypes["DateTime"] | undefined | null,
	not_equals?: ResolverInputTypes["DateTime"] | undefined | null,
	greater_than_equal?: ResolverInputTypes["DateTime"] | undefined | null,
	greater_than?: ResolverInputTypes["DateTime"] | undefined | null,
	less_than_equal?: ResolverInputTypes["DateTime"] | undefined | null,
	less_than?: ResolverInputTypes["DateTime"] | undefined | null,
	like?: ResolverInputTypes["DateTime"] | undefined | null,
	exists?: boolean | undefined | null
};
	["Audience_Icon_url_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Audience_Icon_filename_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Audience_Icon_mimeType_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Audience_Icon_filesize_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Audience_Icon_width_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Audience_Icon_height_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Audience_Icon_focalX_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Audience_Icon_focalY_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Audience_Icon_sizes__small__url_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Audience_Icon_sizes__small__width_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Audience_Icon_sizes__small__height_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Audience_Icon_sizes__small__mimeType_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Audience_Icon_sizes__small__filesize_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Audience_Icon_sizes__small__filename_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Audience_Icon_sizes__medium__url_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Audience_Icon_sizes__medium__width_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Audience_Icon_sizes__medium__height_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Audience_Icon_sizes__medium__mimeType_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Audience_Icon_sizes__medium__filesize_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Audience_Icon_sizes__medium__filename_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Audience_Icon_sizes__large__url_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Audience_Icon_sizes__large__width_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Audience_Icon_sizes__large__height_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Audience_Icon_sizes__large__mimeType_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Audience_Icon_sizes__large__filesize_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Audience_Icon_sizes__large__filename_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Audience_Icon_sizes__small_jpeg__url_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Audience_Icon_sizes__small_jpeg__width_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Audience_Icon_sizes__small_jpeg__height_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Audience_Icon_sizes__small_jpeg__mimeType_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Audience_Icon_sizes__small_jpeg__filesize_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Audience_Icon_sizes__small_jpeg__filename_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Audience_Icon_sizes__medium_jpeg__url_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Audience_Icon_sizes__medium_jpeg__width_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Audience_Icon_sizes__medium_jpeg__height_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Audience_Icon_sizes__medium_jpeg__mimeType_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Audience_Icon_sizes__medium_jpeg__filesize_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Audience_Icon_sizes__medium_jpeg__filename_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Audience_Icon_sizes__large_jpeg__url_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Audience_Icon_sizes__large_jpeg__width_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Audience_Icon_sizes__large_jpeg__height_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Audience_Icon_sizes__large_jpeg__mimeType_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Audience_Icon_sizes__large_jpeg__filesize_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Audience_Icon_sizes__large_jpeg__filename_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Audience_Icon_id_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Audience_Icon_where_and"]: {
	alt?: ResolverInputTypes["Audience_Icon_alt_operator"] | undefined | null,
	updatedAt?: ResolverInputTypes["Audience_Icon_updatedAt_operator"] | undefined | null,
	createdAt?: ResolverInputTypes["Audience_Icon_createdAt_operator"] | undefined | null,
	url?: ResolverInputTypes["Audience_Icon_url_operator"] | undefined | null,
	filename?: ResolverInputTypes["Audience_Icon_filename_operator"] | undefined | null,
	mimeType?: ResolverInputTypes["Audience_Icon_mimeType_operator"] | undefined | null,
	filesize?: ResolverInputTypes["Audience_Icon_filesize_operator"] | undefined | null,
	width?: ResolverInputTypes["Audience_Icon_width_operator"] | undefined | null,
	height?: ResolverInputTypes["Audience_Icon_height_operator"] | undefined | null,
	focalX?: ResolverInputTypes["Audience_Icon_focalX_operator"] | undefined | null,
	focalY?: ResolverInputTypes["Audience_Icon_focalY_operator"] | undefined | null,
	sizes__small__url?: ResolverInputTypes["Audience_Icon_sizes__small__url_operator"] | undefined | null,
	sizes__small__width?: ResolverInputTypes["Audience_Icon_sizes__small__width_operator"] | undefined | null,
	sizes__small__height?: ResolverInputTypes["Audience_Icon_sizes__small__height_operator"] | undefined | null,
	sizes__small__mimeType?: ResolverInputTypes["Audience_Icon_sizes__small__mimeType_operator"] | undefined | null,
	sizes__small__filesize?: ResolverInputTypes["Audience_Icon_sizes__small__filesize_operator"] | undefined | null,
	sizes__small__filename?: ResolverInputTypes["Audience_Icon_sizes__small__filename_operator"] | undefined | null,
	sizes__medium__url?: ResolverInputTypes["Audience_Icon_sizes__medium__url_operator"] | undefined | null,
	sizes__medium__width?: ResolverInputTypes["Audience_Icon_sizes__medium__width_operator"] | undefined | null,
	sizes__medium__height?: ResolverInputTypes["Audience_Icon_sizes__medium__height_operator"] | undefined | null,
	sizes__medium__mimeType?: ResolverInputTypes["Audience_Icon_sizes__medium__mimeType_operator"] | undefined | null,
	sizes__medium__filesize?: ResolverInputTypes["Audience_Icon_sizes__medium__filesize_operator"] | undefined | null,
	sizes__medium__filename?: ResolverInputTypes["Audience_Icon_sizes__medium__filename_operator"] | undefined | null,
	sizes__large__url?: ResolverInputTypes["Audience_Icon_sizes__large__url_operator"] | undefined | null,
	sizes__large__width?: ResolverInputTypes["Audience_Icon_sizes__large__width_operator"] | undefined | null,
	sizes__large__height?: ResolverInputTypes["Audience_Icon_sizes__large__height_operator"] | undefined | null,
	sizes__large__mimeType?: ResolverInputTypes["Audience_Icon_sizes__large__mimeType_operator"] | undefined | null,
	sizes__large__filesize?: ResolverInputTypes["Audience_Icon_sizes__large__filesize_operator"] | undefined | null,
	sizes__large__filename?: ResolverInputTypes["Audience_Icon_sizes__large__filename_operator"] | undefined | null,
	sizes__small_jpeg__url?: ResolverInputTypes["Audience_Icon_sizes__small_jpeg__url_operator"] | undefined | null,
	sizes__small_jpeg__width?: ResolverInputTypes["Audience_Icon_sizes__small_jpeg__width_operator"] | undefined | null,
	sizes__small_jpeg__height?: ResolverInputTypes["Audience_Icon_sizes__small_jpeg__height_operator"] | undefined | null,
	sizes__small_jpeg__mimeType?: ResolverInputTypes["Audience_Icon_sizes__small_jpeg__mimeType_operator"] | undefined | null,
	sizes__small_jpeg__filesize?: ResolverInputTypes["Audience_Icon_sizes__small_jpeg__filesize_operator"] | undefined | null,
	sizes__small_jpeg__filename?: ResolverInputTypes["Audience_Icon_sizes__small_jpeg__filename_operator"] | undefined | null,
	sizes__medium_jpeg__url?: ResolverInputTypes["Audience_Icon_sizes__medium_jpeg__url_operator"] | undefined | null,
	sizes__medium_jpeg__width?: ResolverInputTypes["Audience_Icon_sizes__medium_jpeg__width_operator"] | undefined | null,
	sizes__medium_jpeg__height?: ResolverInputTypes["Audience_Icon_sizes__medium_jpeg__height_operator"] | undefined | null,
	sizes__medium_jpeg__mimeType?: ResolverInputTypes["Audience_Icon_sizes__medium_jpeg__mimeType_operator"] | undefined | null,
	sizes__medium_jpeg__filesize?: ResolverInputTypes["Audience_Icon_sizes__medium_jpeg__filesize_operator"] | undefined | null,
	sizes__medium_jpeg__filename?: ResolverInputTypes["Audience_Icon_sizes__medium_jpeg__filename_operator"] | undefined | null,
	sizes__large_jpeg__url?: ResolverInputTypes["Audience_Icon_sizes__large_jpeg__url_operator"] | undefined | null,
	sizes__large_jpeg__width?: ResolverInputTypes["Audience_Icon_sizes__large_jpeg__width_operator"] | undefined | null,
	sizes__large_jpeg__height?: ResolverInputTypes["Audience_Icon_sizes__large_jpeg__height_operator"] | undefined | null,
	sizes__large_jpeg__mimeType?: ResolverInputTypes["Audience_Icon_sizes__large_jpeg__mimeType_operator"] | undefined | null,
	sizes__large_jpeg__filesize?: ResolverInputTypes["Audience_Icon_sizes__large_jpeg__filesize_operator"] | undefined | null,
	sizes__large_jpeg__filename?: ResolverInputTypes["Audience_Icon_sizes__large_jpeg__filename_operator"] | undefined | null,
	id?: ResolverInputTypes["Audience_Icon_id_operator"] | undefined | null,
	AND?: Array<ResolverInputTypes["Audience_Icon_where_and"] | undefined | null> | undefined | null,
	OR?: Array<ResolverInputTypes["Audience_Icon_where_or"] | undefined | null> | undefined | null
};
	["Audience_Icon_where_or"]: {
	alt?: ResolverInputTypes["Audience_Icon_alt_operator"] | undefined | null,
	updatedAt?: ResolverInputTypes["Audience_Icon_updatedAt_operator"] | undefined | null,
	createdAt?: ResolverInputTypes["Audience_Icon_createdAt_operator"] | undefined | null,
	url?: ResolverInputTypes["Audience_Icon_url_operator"] | undefined | null,
	filename?: ResolverInputTypes["Audience_Icon_filename_operator"] | undefined | null,
	mimeType?: ResolverInputTypes["Audience_Icon_mimeType_operator"] | undefined | null,
	filesize?: ResolverInputTypes["Audience_Icon_filesize_operator"] | undefined | null,
	width?: ResolverInputTypes["Audience_Icon_width_operator"] | undefined | null,
	height?: ResolverInputTypes["Audience_Icon_height_operator"] | undefined | null,
	focalX?: ResolverInputTypes["Audience_Icon_focalX_operator"] | undefined | null,
	focalY?: ResolverInputTypes["Audience_Icon_focalY_operator"] | undefined | null,
	sizes__small__url?: ResolverInputTypes["Audience_Icon_sizes__small__url_operator"] | undefined | null,
	sizes__small__width?: ResolverInputTypes["Audience_Icon_sizes__small__width_operator"] | undefined | null,
	sizes__small__height?: ResolverInputTypes["Audience_Icon_sizes__small__height_operator"] | undefined | null,
	sizes__small__mimeType?: ResolverInputTypes["Audience_Icon_sizes__small__mimeType_operator"] | undefined | null,
	sizes__small__filesize?: ResolverInputTypes["Audience_Icon_sizes__small__filesize_operator"] | undefined | null,
	sizes__small__filename?: ResolverInputTypes["Audience_Icon_sizes__small__filename_operator"] | undefined | null,
	sizes__medium__url?: ResolverInputTypes["Audience_Icon_sizes__medium__url_operator"] | undefined | null,
	sizes__medium__width?: ResolverInputTypes["Audience_Icon_sizes__medium__width_operator"] | undefined | null,
	sizes__medium__height?: ResolverInputTypes["Audience_Icon_sizes__medium__height_operator"] | undefined | null,
	sizes__medium__mimeType?: ResolverInputTypes["Audience_Icon_sizes__medium__mimeType_operator"] | undefined | null,
	sizes__medium__filesize?: ResolverInputTypes["Audience_Icon_sizes__medium__filesize_operator"] | undefined | null,
	sizes__medium__filename?: ResolverInputTypes["Audience_Icon_sizes__medium__filename_operator"] | undefined | null,
	sizes__large__url?: ResolverInputTypes["Audience_Icon_sizes__large__url_operator"] | undefined | null,
	sizes__large__width?: ResolverInputTypes["Audience_Icon_sizes__large__width_operator"] | undefined | null,
	sizes__large__height?: ResolverInputTypes["Audience_Icon_sizes__large__height_operator"] | undefined | null,
	sizes__large__mimeType?: ResolverInputTypes["Audience_Icon_sizes__large__mimeType_operator"] | undefined | null,
	sizes__large__filesize?: ResolverInputTypes["Audience_Icon_sizes__large__filesize_operator"] | undefined | null,
	sizes__large__filename?: ResolverInputTypes["Audience_Icon_sizes__large__filename_operator"] | undefined | null,
	sizes__small_jpeg__url?: ResolverInputTypes["Audience_Icon_sizes__small_jpeg__url_operator"] | undefined | null,
	sizes__small_jpeg__width?: ResolverInputTypes["Audience_Icon_sizes__small_jpeg__width_operator"] | undefined | null,
	sizes__small_jpeg__height?: ResolverInputTypes["Audience_Icon_sizes__small_jpeg__height_operator"] | undefined | null,
	sizes__small_jpeg__mimeType?: ResolverInputTypes["Audience_Icon_sizes__small_jpeg__mimeType_operator"] | undefined | null,
	sizes__small_jpeg__filesize?: ResolverInputTypes["Audience_Icon_sizes__small_jpeg__filesize_operator"] | undefined | null,
	sizes__small_jpeg__filename?: ResolverInputTypes["Audience_Icon_sizes__small_jpeg__filename_operator"] | undefined | null,
	sizes__medium_jpeg__url?: ResolverInputTypes["Audience_Icon_sizes__medium_jpeg__url_operator"] | undefined | null,
	sizes__medium_jpeg__width?: ResolverInputTypes["Audience_Icon_sizes__medium_jpeg__width_operator"] | undefined | null,
	sizes__medium_jpeg__height?: ResolverInputTypes["Audience_Icon_sizes__medium_jpeg__height_operator"] | undefined | null,
	sizes__medium_jpeg__mimeType?: ResolverInputTypes["Audience_Icon_sizes__medium_jpeg__mimeType_operator"] | undefined | null,
	sizes__medium_jpeg__filesize?: ResolverInputTypes["Audience_Icon_sizes__medium_jpeg__filesize_operator"] | undefined | null,
	sizes__medium_jpeg__filename?: ResolverInputTypes["Audience_Icon_sizes__medium_jpeg__filename_operator"] | undefined | null,
	sizes__large_jpeg__url?: ResolverInputTypes["Audience_Icon_sizes__large_jpeg__url_operator"] | undefined | null,
	sizes__large_jpeg__width?: ResolverInputTypes["Audience_Icon_sizes__large_jpeg__width_operator"] | undefined | null,
	sizes__large_jpeg__height?: ResolverInputTypes["Audience_Icon_sizes__large_jpeg__height_operator"] | undefined | null,
	sizes__large_jpeg__mimeType?: ResolverInputTypes["Audience_Icon_sizes__large_jpeg__mimeType_operator"] | undefined | null,
	sizes__large_jpeg__filesize?: ResolverInputTypes["Audience_Icon_sizes__large_jpeg__filesize_operator"] | undefined | null,
	sizes__large_jpeg__filename?: ResolverInputTypes["Audience_Icon_sizes__large_jpeg__filename_operator"] | undefined | null,
	id?: ResolverInputTypes["Audience_Icon_id_operator"] | undefined | null,
	AND?: Array<ResolverInputTypes["Audience_Icon_where_and"] | undefined | null> | undefined | null,
	OR?: Array<ResolverInputTypes["Audience_Icon_where_or"] | undefined | null> | undefined | null
};
	["Audience_region"]:Audience_region;
	["Date"]: AliasType<{
	id?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	value?:boolean | `@${string}`,
	type?:boolean | `@${string}`,
	start?:boolean | `@${string}`,
	end?:boolean | `@${string}`,
	_title?:boolean | `@${string}`,
	region?:boolean | `@${string}`,
	updatedAt?:boolean | `@${string}`,
	createdAt?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["Date_type"]:Date_type;
	["Date_region"]:Date_region;
	["Location_Sections"]: AliasType<{
	startDatetime?:boolean | `@${string}`,
	endDatetime?:boolean | `@${string}`,
	fullDay?:boolean | `@${string}`,
	repeat?:boolean | `@${string}`,
	recurrance?:ResolverInputTypes["Location_Sections_Recurrance"],
	id?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["Location_Sections_Recurrance"]: AliasType<{
	type?:boolean | `@${string}`,
	weekday?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["Location_Sections_Recurrance_type"]:Location_Sections_Recurrance_type;
	["Location_Sections_Recurrance_weekday"]:Location_Sections_Recurrance_weekday;
	["Event_Ticketing"]: AliasType<{
	startDatetime?:boolean | `@${string}`,
	endDatetime?:boolean | `@${string}`,
	fullDay?:boolean | `@${string}`,
	type?:boolean | `@${string}`,
	ticketingType?:boolean | `@${string}`,
linkedTicketing?: [{	locale?: ResolverInputTypes["LocaleInputType"] | undefined | null,	fallbackLocale?: ResolverInputTypes["FallbackLocaleInputType"] | undefined | null},ResolverInputTypes["Ticketing"]],
	promotionType?:boolean | `@${string}`,
location?: [{	locale?: ResolverInputTypes["LocaleInputType"] | undefined | null,	fallbackLocale?: ResolverInputTypes["FallbackLocaleInputType"] | undefined | null},ResolverInputTypes["Location"]],
	website?:boolean | `@${string}`,
	description?:boolean | `@${string}`,
	id?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["Event_Ticketing_type"]:Event_Ticketing_type;
	["Event_Ticketing_ticketingType"]:Event_Ticketing_ticketingType;
	["Ticketing"]: AliasType<{
	id?:boolean | `@${string}`,
	status?:boolean | `@${string}`,
creator?: [{	locale?: ResolverInputTypes["LocaleInputType"] | undefined | null,	fallbackLocale?: ResolverInputTypes["FallbackLocaleInputType"] | undefined | null},ResolverInputTypes["User"]],
	productType?:boolean | `@${string}`,
	productName?:boolean | `@${string}`,
	referenceId?:boolean | `@${string}`,
linkedEvent?: [{	locale?: ResolverInputTypes["LocaleInputType"] | undefined | null,	fallbackLocale?: ResolverInputTypes["FallbackLocaleInputType"] | undefined | null},ResolverInputTypes["Event"]],
	CheckInLevel?:boolean | `@${string}`,
	displayPrice?:boolean | `@${string}`,
	tnc?:boolean | `@${string}`,
	cutoffHours?:boolean | `@${string}`,
	sessions?:ResolverInputTypes["TicketingVariants"],
	updatedAt?:boolean | `@${string}`,
	createdAt?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["Ticketing_status"]:Ticketing_status;
	["Ticketing_productType"]:Ticketing_productType;
	["Ticketing_CheckInLevel"]:Ticketing_CheckInLevel;
	["TicketingVariants"]: AliasType<{
	variantId?:boolean | `@${string}`,
	productId?:boolean | `@${string}`,
	startDate?:boolean | `@${string}`,
	endDate?:boolean | `@${string}`,
	inventories?:ResolverInputTypes["Inventories"],
	id?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["Inventories"]: AliasType<{
	name?:boolean | `@${string}`,
	quantity?:boolean | `@${string}`,
	unitPrice?:boolean | `@${string}`,
	handlingFee?:boolean | `@${string}`,
	totalCost?:boolean | `@${string}`,
	minPurchase?:boolean | `@${string}`,
	maxPurchase?:boolean | `@${string}`,
	stocks?:ResolverInputTypes["Inventories_Stocks"],
	remarks?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["Inventories_Stocks"]: AliasType<{
	stockId?:boolean | `@${string}`,
	remaining?:boolean | `@${string}`,
	sold?:boolean | `@${string}`,
	onHold?:boolean | `@${string}`,
	checkedIn?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["Event_Ticketing_promotionType"]:Event_Ticketing_promotionType;
	["Event_Content"]: AliasType<{
	RichText?:ResolverInputTypes["RichText"],
	Gallery?:ResolverInputTypes["Gallery"],
	Image?:ResolverInputTypes["Image"],
	Video?:ResolverInputTypes["Video"],
		__typename?: boolean | `@${string}`
}>;
	["Event_Criteria"]: AliasType<{
categories?: [{	locale?: ResolverInputTypes["LocaleInputType"] | undefined | null,	fallbackLocale?: ResolverInputTypes["FallbackLocaleInputType"] | undefined | null},ResolverInputTypes["EventCategory"]],
audiences?: [{	locale?: ResolverInputTypes["LocaleInputType"] | undefined | null,	fallbackLocale?: ResolverInputTypes["FallbackLocaleInputType"] | undefined | null},ResolverInputTypes["Audience"]],
tags?: [{	locale?: ResolverInputTypes["LocaleInputType"] | undefined | null,	fallbackLocale?: ResolverInputTypes["FallbackLocaleInputType"] | undefined | null},ResolverInputTypes["Tag"]],
specialTags?: [{	locale?: ResolverInputTypes["LocaleInputType"] | undefined | null,	fallbackLocale?: ResolverInputTypes["FallbackLocaleInputType"] | undefined | null},ResolverInputTypes["SpecialTag"]],
		__typename?: boolean | `@${string}`
}>;
	["SpecialTag"]: AliasType<{
	id?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	value?:boolean | `@${string}`,
	region?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["SpecialTag_region"]:SpecialTag_region;
	["Event_Contact"]: AliasType<{
	code?:boolean | `@${string}`,
	phone?:boolean | `@${string}`,
	page?:boolean | `@${string}`,
	email?:boolean | `@${string}`,
privacy?: [{	depth?: number | undefined | null},boolean | `@${string}`],
		__typename?: boolean | `@${string}`
}>;
	["Event_Fees"]: AliasType<{
	fee?:boolean | `@${string}`,
	remark?:boolean | `@${string}`,
	id?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["Event_Products"]: AliasType<{
	startDateTime?:boolean | `@${string}`,
	endDateTime?:boolean | `@${string}`,
	fullDay?:boolean | `@${string}`,
linkedProduct?: [{	locale?: ResolverInputTypes["LocaleInputType"] | undefined | null,	fallbackLocale?: ResolverInputTypes["FallbackLocaleInputType"] | undefined | null},ResolverInputTypes["Product"]],
	id?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["Product"]: AliasType<{
	id?:boolean | `@${string}`,
	status?:boolean | `@${string}`,
creator?: [{	locale?: ResolverInputTypes["LocaleInputType"] | undefined | null,	fallbackLocale?: ResolverInputTypes["FallbackLocaleInputType"] | undefined | null},ResolverInputTypes["User"]],
	productType?:boolean | `@${string}`,
	productName?:boolean | `@${string}`,
	referenceId?:boolean | `@${string}`,
linkedEvent?: [{	locale?: ResolverInputTypes["LocaleInputType"] | undefined | null,	fallbackLocale?: ResolverInputTypes["FallbackLocaleInputType"] | undefined | null},ResolverInputTypes["Event"]],
	CheckInLevel?:boolean | `@${string}`,
thumbnail?: [{	locale?: ResolverInputTypes["LocaleInputType"] | undefined | null,	fallbackLocale?: ResolverInputTypes["FallbackLocaleInputType"] | undefined | null,	where?: ResolverInputTypes["Product_Thumbnail_where"] | undefined | null},ResolverInputTypes["Media"]],
	displayPrice?:boolean | `@${string}`,
	transactionType?:boolean | `@${string}`,
	website?:boolean | `@${string}`,
	tnc?:boolean | `@${string}`,
	remarks?:boolean | `@${string}`,
	sessions?:ResolverInputTypes["ProductVariants"],
	updatedAt?:boolean | `@${string}`,
	createdAt?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["Product_status"]:Product_status;
	["Product_productType"]:Product_productType;
	["Product_CheckInLevel"]:Product_CheckInLevel;
	["Product_Thumbnail_where"]: {
	alt?: ResolverInputTypes["Product_Thumbnail_alt_operator"] | undefined | null,
	updatedAt?: ResolverInputTypes["Product_Thumbnail_updatedAt_operator"] | undefined | null,
	createdAt?: ResolverInputTypes["Product_Thumbnail_createdAt_operator"] | undefined | null,
	url?: ResolverInputTypes["Product_Thumbnail_url_operator"] | undefined | null,
	filename?: ResolverInputTypes["Product_Thumbnail_filename_operator"] | undefined | null,
	mimeType?: ResolverInputTypes["Product_Thumbnail_mimeType_operator"] | undefined | null,
	filesize?: ResolverInputTypes["Product_Thumbnail_filesize_operator"] | undefined | null,
	width?: ResolverInputTypes["Product_Thumbnail_width_operator"] | undefined | null,
	height?: ResolverInputTypes["Product_Thumbnail_height_operator"] | undefined | null,
	focalX?: ResolverInputTypes["Product_Thumbnail_focalX_operator"] | undefined | null,
	focalY?: ResolverInputTypes["Product_Thumbnail_focalY_operator"] | undefined | null,
	sizes__small__url?: ResolverInputTypes["Product_Thumbnail_sizes__small__url_operator"] | undefined | null,
	sizes__small__width?: ResolverInputTypes["Product_Thumbnail_sizes__small__width_operator"] | undefined | null,
	sizes__small__height?: ResolverInputTypes["Product_Thumbnail_sizes__small__height_operator"] | undefined | null,
	sizes__small__mimeType?: ResolverInputTypes["Product_Thumbnail_sizes__small__mimeType_operator"] | undefined | null,
	sizes__small__filesize?: ResolverInputTypes["Product_Thumbnail_sizes__small__filesize_operator"] | undefined | null,
	sizes__small__filename?: ResolverInputTypes["Product_Thumbnail_sizes__small__filename_operator"] | undefined | null,
	sizes__medium__url?: ResolverInputTypes["Product_Thumbnail_sizes__medium__url_operator"] | undefined | null,
	sizes__medium__width?: ResolverInputTypes["Product_Thumbnail_sizes__medium__width_operator"] | undefined | null,
	sizes__medium__height?: ResolverInputTypes["Product_Thumbnail_sizes__medium__height_operator"] | undefined | null,
	sizes__medium__mimeType?: ResolverInputTypes["Product_Thumbnail_sizes__medium__mimeType_operator"] | undefined | null,
	sizes__medium__filesize?: ResolverInputTypes["Product_Thumbnail_sizes__medium__filesize_operator"] | undefined | null,
	sizes__medium__filename?: ResolverInputTypes["Product_Thumbnail_sizes__medium__filename_operator"] | undefined | null,
	sizes__large__url?: ResolverInputTypes["Product_Thumbnail_sizes__large__url_operator"] | undefined | null,
	sizes__large__width?: ResolverInputTypes["Product_Thumbnail_sizes__large__width_operator"] | undefined | null,
	sizes__large__height?: ResolverInputTypes["Product_Thumbnail_sizes__large__height_operator"] | undefined | null,
	sizes__large__mimeType?: ResolverInputTypes["Product_Thumbnail_sizes__large__mimeType_operator"] | undefined | null,
	sizes__large__filesize?: ResolverInputTypes["Product_Thumbnail_sizes__large__filesize_operator"] | undefined | null,
	sizes__large__filename?: ResolverInputTypes["Product_Thumbnail_sizes__large__filename_operator"] | undefined | null,
	sizes__small_jpeg__url?: ResolverInputTypes["Product_Thumbnail_sizes__small_jpeg__url_operator"] | undefined | null,
	sizes__small_jpeg__width?: ResolverInputTypes["Product_Thumbnail_sizes__small_jpeg__width_operator"] | undefined | null,
	sizes__small_jpeg__height?: ResolverInputTypes["Product_Thumbnail_sizes__small_jpeg__height_operator"] | undefined | null,
	sizes__small_jpeg__mimeType?: ResolverInputTypes["Product_Thumbnail_sizes__small_jpeg__mimeType_operator"] | undefined | null,
	sizes__small_jpeg__filesize?: ResolverInputTypes["Product_Thumbnail_sizes__small_jpeg__filesize_operator"] | undefined | null,
	sizes__small_jpeg__filename?: ResolverInputTypes["Product_Thumbnail_sizes__small_jpeg__filename_operator"] | undefined | null,
	sizes__medium_jpeg__url?: ResolverInputTypes["Product_Thumbnail_sizes__medium_jpeg__url_operator"] | undefined | null,
	sizes__medium_jpeg__width?: ResolverInputTypes["Product_Thumbnail_sizes__medium_jpeg__width_operator"] | undefined | null,
	sizes__medium_jpeg__height?: ResolverInputTypes["Product_Thumbnail_sizes__medium_jpeg__height_operator"] | undefined | null,
	sizes__medium_jpeg__mimeType?: ResolverInputTypes["Product_Thumbnail_sizes__medium_jpeg__mimeType_operator"] | undefined | null,
	sizes__medium_jpeg__filesize?: ResolverInputTypes["Product_Thumbnail_sizes__medium_jpeg__filesize_operator"] | undefined | null,
	sizes__medium_jpeg__filename?: ResolverInputTypes["Product_Thumbnail_sizes__medium_jpeg__filename_operator"] | undefined | null,
	sizes__large_jpeg__url?: ResolverInputTypes["Product_Thumbnail_sizes__large_jpeg__url_operator"] | undefined | null,
	sizes__large_jpeg__width?: ResolverInputTypes["Product_Thumbnail_sizes__large_jpeg__width_operator"] | undefined | null,
	sizes__large_jpeg__height?: ResolverInputTypes["Product_Thumbnail_sizes__large_jpeg__height_operator"] | undefined | null,
	sizes__large_jpeg__mimeType?: ResolverInputTypes["Product_Thumbnail_sizes__large_jpeg__mimeType_operator"] | undefined | null,
	sizes__large_jpeg__filesize?: ResolverInputTypes["Product_Thumbnail_sizes__large_jpeg__filesize_operator"] | undefined | null,
	sizes__large_jpeg__filename?: ResolverInputTypes["Product_Thumbnail_sizes__large_jpeg__filename_operator"] | undefined | null,
	id?: ResolverInputTypes["Product_Thumbnail_id_operator"] | undefined | null,
	AND?: Array<ResolverInputTypes["Product_Thumbnail_where_and"] | undefined | null> | undefined | null,
	OR?: Array<ResolverInputTypes["Product_Thumbnail_where_or"] | undefined | null> | undefined | null
};
	["Product_Thumbnail_alt_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Product_Thumbnail_updatedAt_operator"]: {
	equals?: ResolverInputTypes["DateTime"] | undefined | null,
	not_equals?: ResolverInputTypes["DateTime"] | undefined | null,
	greater_than_equal?: ResolverInputTypes["DateTime"] | undefined | null,
	greater_than?: ResolverInputTypes["DateTime"] | undefined | null,
	less_than_equal?: ResolverInputTypes["DateTime"] | undefined | null,
	less_than?: ResolverInputTypes["DateTime"] | undefined | null,
	like?: ResolverInputTypes["DateTime"] | undefined | null,
	exists?: boolean | undefined | null
};
	["Product_Thumbnail_createdAt_operator"]: {
	equals?: ResolverInputTypes["DateTime"] | undefined | null,
	not_equals?: ResolverInputTypes["DateTime"] | undefined | null,
	greater_than_equal?: ResolverInputTypes["DateTime"] | undefined | null,
	greater_than?: ResolverInputTypes["DateTime"] | undefined | null,
	less_than_equal?: ResolverInputTypes["DateTime"] | undefined | null,
	less_than?: ResolverInputTypes["DateTime"] | undefined | null,
	like?: ResolverInputTypes["DateTime"] | undefined | null,
	exists?: boolean | undefined | null
};
	["Product_Thumbnail_url_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Product_Thumbnail_filename_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Product_Thumbnail_mimeType_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Product_Thumbnail_filesize_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Product_Thumbnail_width_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Product_Thumbnail_height_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Product_Thumbnail_focalX_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Product_Thumbnail_focalY_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Product_Thumbnail_sizes__small__url_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Product_Thumbnail_sizes__small__width_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Product_Thumbnail_sizes__small__height_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Product_Thumbnail_sizes__small__mimeType_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Product_Thumbnail_sizes__small__filesize_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Product_Thumbnail_sizes__small__filename_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Product_Thumbnail_sizes__medium__url_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Product_Thumbnail_sizes__medium__width_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Product_Thumbnail_sizes__medium__height_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Product_Thumbnail_sizes__medium__mimeType_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Product_Thumbnail_sizes__medium__filesize_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Product_Thumbnail_sizes__medium__filename_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Product_Thumbnail_sizes__large__url_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Product_Thumbnail_sizes__large__width_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Product_Thumbnail_sizes__large__height_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Product_Thumbnail_sizes__large__mimeType_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Product_Thumbnail_sizes__large__filesize_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Product_Thumbnail_sizes__large__filename_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Product_Thumbnail_sizes__small_jpeg__url_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Product_Thumbnail_sizes__small_jpeg__width_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Product_Thumbnail_sizes__small_jpeg__height_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Product_Thumbnail_sizes__small_jpeg__mimeType_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Product_Thumbnail_sizes__small_jpeg__filesize_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Product_Thumbnail_sizes__small_jpeg__filename_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Product_Thumbnail_sizes__medium_jpeg__url_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Product_Thumbnail_sizes__medium_jpeg__width_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Product_Thumbnail_sizes__medium_jpeg__height_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Product_Thumbnail_sizes__medium_jpeg__mimeType_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Product_Thumbnail_sizes__medium_jpeg__filesize_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Product_Thumbnail_sizes__medium_jpeg__filename_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Product_Thumbnail_sizes__large_jpeg__url_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Product_Thumbnail_sizes__large_jpeg__width_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Product_Thumbnail_sizes__large_jpeg__height_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Product_Thumbnail_sizes__large_jpeg__mimeType_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Product_Thumbnail_sizes__large_jpeg__filesize_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Product_Thumbnail_sizes__large_jpeg__filename_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Product_Thumbnail_id_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Product_Thumbnail_where_and"]: {
	alt?: ResolverInputTypes["Product_Thumbnail_alt_operator"] | undefined | null,
	updatedAt?: ResolverInputTypes["Product_Thumbnail_updatedAt_operator"] | undefined | null,
	createdAt?: ResolverInputTypes["Product_Thumbnail_createdAt_operator"] | undefined | null,
	url?: ResolverInputTypes["Product_Thumbnail_url_operator"] | undefined | null,
	filename?: ResolverInputTypes["Product_Thumbnail_filename_operator"] | undefined | null,
	mimeType?: ResolverInputTypes["Product_Thumbnail_mimeType_operator"] | undefined | null,
	filesize?: ResolverInputTypes["Product_Thumbnail_filesize_operator"] | undefined | null,
	width?: ResolverInputTypes["Product_Thumbnail_width_operator"] | undefined | null,
	height?: ResolverInputTypes["Product_Thumbnail_height_operator"] | undefined | null,
	focalX?: ResolverInputTypes["Product_Thumbnail_focalX_operator"] | undefined | null,
	focalY?: ResolverInputTypes["Product_Thumbnail_focalY_operator"] | undefined | null,
	sizes__small__url?: ResolverInputTypes["Product_Thumbnail_sizes__small__url_operator"] | undefined | null,
	sizes__small__width?: ResolverInputTypes["Product_Thumbnail_sizes__small__width_operator"] | undefined | null,
	sizes__small__height?: ResolverInputTypes["Product_Thumbnail_sizes__small__height_operator"] | undefined | null,
	sizes__small__mimeType?: ResolverInputTypes["Product_Thumbnail_sizes__small__mimeType_operator"] | undefined | null,
	sizes__small__filesize?: ResolverInputTypes["Product_Thumbnail_sizes__small__filesize_operator"] | undefined | null,
	sizes__small__filename?: ResolverInputTypes["Product_Thumbnail_sizes__small__filename_operator"] | undefined | null,
	sizes__medium__url?: ResolverInputTypes["Product_Thumbnail_sizes__medium__url_operator"] | undefined | null,
	sizes__medium__width?: ResolverInputTypes["Product_Thumbnail_sizes__medium__width_operator"] | undefined | null,
	sizes__medium__height?: ResolverInputTypes["Product_Thumbnail_sizes__medium__height_operator"] | undefined | null,
	sizes__medium__mimeType?: ResolverInputTypes["Product_Thumbnail_sizes__medium__mimeType_operator"] | undefined | null,
	sizes__medium__filesize?: ResolverInputTypes["Product_Thumbnail_sizes__medium__filesize_operator"] | undefined | null,
	sizes__medium__filename?: ResolverInputTypes["Product_Thumbnail_sizes__medium__filename_operator"] | undefined | null,
	sizes__large__url?: ResolverInputTypes["Product_Thumbnail_sizes__large__url_operator"] | undefined | null,
	sizes__large__width?: ResolverInputTypes["Product_Thumbnail_sizes__large__width_operator"] | undefined | null,
	sizes__large__height?: ResolverInputTypes["Product_Thumbnail_sizes__large__height_operator"] | undefined | null,
	sizes__large__mimeType?: ResolverInputTypes["Product_Thumbnail_sizes__large__mimeType_operator"] | undefined | null,
	sizes__large__filesize?: ResolverInputTypes["Product_Thumbnail_sizes__large__filesize_operator"] | undefined | null,
	sizes__large__filename?: ResolverInputTypes["Product_Thumbnail_sizes__large__filename_operator"] | undefined | null,
	sizes__small_jpeg__url?: ResolverInputTypes["Product_Thumbnail_sizes__small_jpeg__url_operator"] | undefined | null,
	sizes__small_jpeg__width?: ResolverInputTypes["Product_Thumbnail_sizes__small_jpeg__width_operator"] | undefined | null,
	sizes__small_jpeg__height?: ResolverInputTypes["Product_Thumbnail_sizes__small_jpeg__height_operator"] | undefined | null,
	sizes__small_jpeg__mimeType?: ResolverInputTypes["Product_Thumbnail_sizes__small_jpeg__mimeType_operator"] | undefined | null,
	sizes__small_jpeg__filesize?: ResolverInputTypes["Product_Thumbnail_sizes__small_jpeg__filesize_operator"] | undefined | null,
	sizes__small_jpeg__filename?: ResolverInputTypes["Product_Thumbnail_sizes__small_jpeg__filename_operator"] | undefined | null,
	sizes__medium_jpeg__url?: ResolverInputTypes["Product_Thumbnail_sizes__medium_jpeg__url_operator"] | undefined | null,
	sizes__medium_jpeg__width?: ResolverInputTypes["Product_Thumbnail_sizes__medium_jpeg__width_operator"] | undefined | null,
	sizes__medium_jpeg__height?: ResolverInputTypes["Product_Thumbnail_sizes__medium_jpeg__height_operator"] | undefined | null,
	sizes__medium_jpeg__mimeType?: ResolverInputTypes["Product_Thumbnail_sizes__medium_jpeg__mimeType_operator"] | undefined | null,
	sizes__medium_jpeg__filesize?: ResolverInputTypes["Product_Thumbnail_sizes__medium_jpeg__filesize_operator"] | undefined | null,
	sizes__medium_jpeg__filename?: ResolverInputTypes["Product_Thumbnail_sizes__medium_jpeg__filename_operator"] | undefined | null,
	sizes__large_jpeg__url?: ResolverInputTypes["Product_Thumbnail_sizes__large_jpeg__url_operator"] | undefined | null,
	sizes__large_jpeg__width?: ResolverInputTypes["Product_Thumbnail_sizes__large_jpeg__width_operator"] | undefined | null,
	sizes__large_jpeg__height?: ResolverInputTypes["Product_Thumbnail_sizes__large_jpeg__height_operator"] | undefined | null,
	sizes__large_jpeg__mimeType?: ResolverInputTypes["Product_Thumbnail_sizes__large_jpeg__mimeType_operator"] | undefined | null,
	sizes__large_jpeg__filesize?: ResolverInputTypes["Product_Thumbnail_sizes__large_jpeg__filesize_operator"] | undefined | null,
	sizes__large_jpeg__filename?: ResolverInputTypes["Product_Thumbnail_sizes__large_jpeg__filename_operator"] | undefined | null,
	id?: ResolverInputTypes["Product_Thumbnail_id_operator"] | undefined | null,
	AND?: Array<ResolverInputTypes["Product_Thumbnail_where_and"] | undefined | null> | undefined | null,
	OR?: Array<ResolverInputTypes["Product_Thumbnail_where_or"] | undefined | null> | undefined | null
};
	["Product_Thumbnail_where_or"]: {
	alt?: ResolverInputTypes["Product_Thumbnail_alt_operator"] | undefined | null,
	updatedAt?: ResolverInputTypes["Product_Thumbnail_updatedAt_operator"] | undefined | null,
	createdAt?: ResolverInputTypes["Product_Thumbnail_createdAt_operator"] | undefined | null,
	url?: ResolverInputTypes["Product_Thumbnail_url_operator"] | undefined | null,
	filename?: ResolverInputTypes["Product_Thumbnail_filename_operator"] | undefined | null,
	mimeType?: ResolverInputTypes["Product_Thumbnail_mimeType_operator"] | undefined | null,
	filesize?: ResolverInputTypes["Product_Thumbnail_filesize_operator"] | undefined | null,
	width?: ResolverInputTypes["Product_Thumbnail_width_operator"] | undefined | null,
	height?: ResolverInputTypes["Product_Thumbnail_height_operator"] | undefined | null,
	focalX?: ResolverInputTypes["Product_Thumbnail_focalX_operator"] | undefined | null,
	focalY?: ResolverInputTypes["Product_Thumbnail_focalY_operator"] | undefined | null,
	sizes__small__url?: ResolverInputTypes["Product_Thumbnail_sizes__small__url_operator"] | undefined | null,
	sizes__small__width?: ResolverInputTypes["Product_Thumbnail_sizes__small__width_operator"] | undefined | null,
	sizes__small__height?: ResolverInputTypes["Product_Thumbnail_sizes__small__height_operator"] | undefined | null,
	sizes__small__mimeType?: ResolverInputTypes["Product_Thumbnail_sizes__small__mimeType_operator"] | undefined | null,
	sizes__small__filesize?: ResolverInputTypes["Product_Thumbnail_sizes__small__filesize_operator"] | undefined | null,
	sizes__small__filename?: ResolverInputTypes["Product_Thumbnail_sizes__small__filename_operator"] | undefined | null,
	sizes__medium__url?: ResolverInputTypes["Product_Thumbnail_sizes__medium__url_operator"] | undefined | null,
	sizes__medium__width?: ResolverInputTypes["Product_Thumbnail_sizes__medium__width_operator"] | undefined | null,
	sizes__medium__height?: ResolverInputTypes["Product_Thumbnail_sizes__medium__height_operator"] | undefined | null,
	sizes__medium__mimeType?: ResolverInputTypes["Product_Thumbnail_sizes__medium__mimeType_operator"] | undefined | null,
	sizes__medium__filesize?: ResolverInputTypes["Product_Thumbnail_sizes__medium__filesize_operator"] | undefined | null,
	sizes__medium__filename?: ResolverInputTypes["Product_Thumbnail_sizes__medium__filename_operator"] | undefined | null,
	sizes__large__url?: ResolverInputTypes["Product_Thumbnail_sizes__large__url_operator"] | undefined | null,
	sizes__large__width?: ResolverInputTypes["Product_Thumbnail_sizes__large__width_operator"] | undefined | null,
	sizes__large__height?: ResolverInputTypes["Product_Thumbnail_sizes__large__height_operator"] | undefined | null,
	sizes__large__mimeType?: ResolverInputTypes["Product_Thumbnail_sizes__large__mimeType_operator"] | undefined | null,
	sizes__large__filesize?: ResolverInputTypes["Product_Thumbnail_sizes__large__filesize_operator"] | undefined | null,
	sizes__large__filename?: ResolverInputTypes["Product_Thumbnail_sizes__large__filename_operator"] | undefined | null,
	sizes__small_jpeg__url?: ResolverInputTypes["Product_Thumbnail_sizes__small_jpeg__url_operator"] | undefined | null,
	sizes__small_jpeg__width?: ResolverInputTypes["Product_Thumbnail_sizes__small_jpeg__width_operator"] | undefined | null,
	sizes__small_jpeg__height?: ResolverInputTypes["Product_Thumbnail_sizes__small_jpeg__height_operator"] | undefined | null,
	sizes__small_jpeg__mimeType?: ResolverInputTypes["Product_Thumbnail_sizes__small_jpeg__mimeType_operator"] | undefined | null,
	sizes__small_jpeg__filesize?: ResolverInputTypes["Product_Thumbnail_sizes__small_jpeg__filesize_operator"] | undefined | null,
	sizes__small_jpeg__filename?: ResolverInputTypes["Product_Thumbnail_sizes__small_jpeg__filename_operator"] | undefined | null,
	sizes__medium_jpeg__url?: ResolverInputTypes["Product_Thumbnail_sizes__medium_jpeg__url_operator"] | undefined | null,
	sizes__medium_jpeg__width?: ResolverInputTypes["Product_Thumbnail_sizes__medium_jpeg__width_operator"] | undefined | null,
	sizes__medium_jpeg__height?: ResolverInputTypes["Product_Thumbnail_sizes__medium_jpeg__height_operator"] | undefined | null,
	sizes__medium_jpeg__mimeType?: ResolverInputTypes["Product_Thumbnail_sizes__medium_jpeg__mimeType_operator"] | undefined | null,
	sizes__medium_jpeg__filesize?: ResolverInputTypes["Product_Thumbnail_sizes__medium_jpeg__filesize_operator"] | undefined | null,
	sizes__medium_jpeg__filename?: ResolverInputTypes["Product_Thumbnail_sizes__medium_jpeg__filename_operator"] | undefined | null,
	sizes__large_jpeg__url?: ResolverInputTypes["Product_Thumbnail_sizes__large_jpeg__url_operator"] | undefined | null,
	sizes__large_jpeg__width?: ResolverInputTypes["Product_Thumbnail_sizes__large_jpeg__width_operator"] | undefined | null,
	sizes__large_jpeg__height?: ResolverInputTypes["Product_Thumbnail_sizes__large_jpeg__height_operator"] | undefined | null,
	sizes__large_jpeg__mimeType?: ResolverInputTypes["Product_Thumbnail_sizes__large_jpeg__mimeType_operator"] | undefined | null,
	sizes__large_jpeg__filesize?: ResolverInputTypes["Product_Thumbnail_sizes__large_jpeg__filesize_operator"] | undefined | null,
	sizes__large_jpeg__filename?: ResolverInputTypes["Product_Thumbnail_sizes__large_jpeg__filename_operator"] | undefined | null,
	id?: ResolverInputTypes["Product_Thumbnail_id_operator"] | undefined | null,
	AND?: Array<ResolverInputTypes["Product_Thumbnail_where_and"] | undefined | null> | undefined | null,
	OR?: Array<ResolverInputTypes["Product_Thumbnail_where_or"] | undefined | null> | undefined | null
};
	["Product_transactionType"]:Product_transactionType;
	["ProductVariants"]: AliasType<{
	variantId?:boolean | `@${string}`,
	productId?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	inventories?:ResolverInputTypes["Inventories"],
	id?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["Events"]: AliasType<{
	docs?:ResolverInputTypes["Event"],
	hasNextPage?:boolean | `@${string}`,
	hasPrevPage?:boolean | `@${string}`,
	limit?:boolean | `@${string}`,
	nextPage?:boolean | `@${string}`,
	offset?:boolean | `@${string}`,
	page?:boolean | `@${string}`,
	pagingCounter?:boolean | `@${string}`,
	prevPage?:boolean | `@${string}`,
	totalDocs?:boolean | `@${string}`,
	totalPages?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["Event_where"]: {
	region?: ResolverInputTypes["Event_region_operator"] | undefined | null,
	creator?: ResolverInputTypes["Event_creator_operator"] | undefined | null,
	permalink?: ResolverInputTypes["Event_permalink_operator"] | undefined | null,
	status?: ResolverInputTypes["Event_status_operator"] | undefined | null,
	level?: ResolverInputTypes["Event_level_operator"] | undefined | null,
	parent?: ResolverInputTypes["Event_parent_operator"] | undefined | null,
	name?: ResolverInputTypes["Event_name_operator"] | undefined | null,
	chatbotEventTitle?: ResolverInputTypes["Event_chatbotEventTitle_operator"] | undefined | null,
	chatbotSequence?: ResolverInputTypes["Event_chatbotSequence_operator"] | undefined | null,
	thumbnail?: ResolverInputTypes["Event_thumbnail_operator"] | undefined | null,
	banner?: ResolverInputTypes["Event_banner_operator"] | undefined | null,
	mobileBanner?: ResolverInputTypes["Event_mobileBanner_operator"] | undefined | null,
	sections__startDatetime?: ResolverInputTypes["Event_sections__startDatetime_operator"] | undefined | null,
	sections__endDatetime?: ResolverInputTypes["Event_sections__endDatetime_operator"] | undefined | null,
	sections__fullDay?: ResolverInputTypes["Event_sections__fullDay_operator"] | undefined | null,
	sections__repeat?: ResolverInputTypes["Event_sections__repeat_operator"] | undefined | null,
	sections__toThisDay?: ResolverInputTypes["Event_sections__toThisDay_operator"] | undefined | null,
	sections__recurrance__type?: ResolverInputTypes["Event_sections__recurrance__type_operator"] | undefined | null,
	sections__recurrance__weekday?: ResolverInputTypes["Event_sections__recurrance__weekday_operator"] | undefined | null,
	sections__sameLocation?: ResolverInputTypes["Event_sections__sameLocation_operator"] | undefined | null,
	sections__location?: ResolverInputTypes["Event_sections__location_operator"] | undefined | null,
	sections__district?: ResolverInputTypes["Event_sections__district_operator"] | undefined | null,
	sections__coordinate?: ResolverInputTypes["Event_sections__coordinate_operator"] | undefined | null,
	sections__linkAddress?: ResolverInputTypes["Event_sections__linkAddress_operator"] | undefined | null,
	sections__address?: ResolverInputTypes["Event_sections__address_operator"] | undefined | null,
	sections__addressCoordinate?: ResolverInputTypes["Event_sections__addressCoordinate_operator"] | undefined | null,
	sections__id?: ResolverInputTypes["Event_sections__id_operator"] | undefined | null,
	ticketing__startDatetime?: ResolverInputTypes["Event_ticketing__startDatetime_operator"] | undefined | null,
	ticketing__endDatetime?: ResolverInputTypes["Event_ticketing__endDatetime_operator"] | undefined | null,
	ticketing__fullDay?: ResolverInputTypes["Event_ticketing__fullDay_operator"] | undefined | null,
	ticketing__type?: ResolverInputTypes["Event_ticketing__type_operator"] | undefined | null,
	ticketing__ticketingType?: ResolverInputTypes["Event_ticketing__ticketingType_operator"] | undefined | null,
	ticketing__linkedTicketing?: ResolverInputTypes["Event_ticketing__linkedTicketing_operator"] | undefined | null,
	ticketing__promotionType?: ResolverInputTypes["Event_ticketing__promotionType_operator"] | undefined | null,
	ticketing__location?: ResolverInputTypes["Event_ticketing__location_operator"] | undefined | null,
	ticketing__website?: ResolverInputTypes["Event_ticketing__website_operator"] | undefined | null,
	ticketing__description?: ResolverInputTypes["Event_ticketing__description_operator"] | undefined | null,
	ticketing__id?: ResolverInputTypes["Event_ticketing__id_operator"] | undefined | null,
	legacyContent?: ResolverInputTypes["Event_legacyContent_operator"] | undefined | null,
	showLegacyContent?: ResolverInputTypes["Event_showLegacyContent_operator"] | undefined | null,
	criteria__categories?: ResolverInputTypes["Event_criteria__categories_operator"] | undefined | null,
	criteria__audiences?: ResolverInputTypes["Event_criteria__audiences_operator"] | undefined | null,
	criteria__tags?: ResolverInputTypes["Event_criteria__tags_operator"] | undefined | null,
	criteria__specialTags?: ResolverInputTypes["Event_criteria__specialTags_operator"] | undefined | null,
	walkIn?: ResolverInputTypes["Event_walkIn_operator"] | undefined | null,
	contact__code?: ResolverInputTypes["Event_contact__code_operator"] | undefined | null,
	contact__phone?: ResolverInputTypes["Event_contact__phone_operator"] | undefined | null,
	contact__page?: ResolverInputTypes["Event_contact__page_operator"] | undefined | null,
	contact__email?: ResolverInputTypes["Event_contact__email_operator"] | undefined | null,
	contact__privacy?: ResolverInputTypes["Event_contact__privacy_operator"] | undefined | null,
	views?: ResolverInputTypes["Event_views_operator"] | undefined | null,
	free?: ResolverInputTypes["Event_free_operator"] | undefined | null,
	fees__fee?: ResolverInputTypes["Event_fees__fee_operator"] | undefined | null,
	fees__remark?: ResolverInputTypes["Event_fees__remark_operator"] | undefined | null,
	fees__id?: ResolverInputTypes["Event_fees__id_operator"] | undefined | null,
	homePageInternalClick?: ResolverInputTypes["Event_homePageInternalClick_operator"] | undefined | null,
	homePageImpression?: ResolverInputTypes["Event_homePageImpression_operator"] | undefined | null,
	eodClickRate?: ResolverInputTypes["Event_eodClickRate_operator"] | undefined | null,
	eodImpressionRate?: ResolverInputTypes["Event_eodImpressionRate_operator"] | undefined | null,
	top10ClickRateHomepage?: ResolverInputTypes["Event_top10ClickRateHomepage_operator"] | undefined | null,
	top10ImpressionkRateHomepage?: ResolverInputTypes["Event_top10ImpressionkRateHomepage_operator"] | undefined | null,
	otherEventRankingClickRateHomepage?: ResolverInputTypes["Event_otherEventRankingClickRateHomepage_operator"] | undefined | null,
	otherEventRankingImpressionHomePage?: ResolverInputTypes["Event_otherEventRankingImpressionHomePage_operator"] | undefined | null,
	userPreferenceClickRate?: ResolverInputTypes["Event_userPreferenceClickRate_operator"] | undefined | null,
	userPreferenceImpression?: ResolverInputTypes["Event_userPreferenceImpression_operator"] | undefined | null,
	eventSearchInternalClick?: ResolverInputTypes["Event_eventSearchInternalClick_operator"] | undefined | null,
	eventListImpression?: ResolverInputTypes["Event_eventListImpression_operator"] | undefined | null,
	latestEventInternalClick?: ResolverInputTypes["Event_latestEventInternalClick_operator"] | undefined | null,
	latestEventImpression?: ResolverInputTypes["Event_latestEventImpression_operator"] | undefined | null,
	topTenEventRankingInternalClick?: ResolverInputTypes["Event_topTenEventRankingInternalClick_operator"] | undefined | null,
	topTenImpression?: ResolverInputTypes["Event_topTenImpression_operator"] | undefined | null,
	editorChoiceInternalClick?: ResolverInputTypes["Event_editorChoiceInternalClick_operator"] | undefined | null,
	editorChoiceImpression?: ResolverInputTypes["Event_editorChoiceImpression_operator"] | undefined | null,
	otherEventRankingInternalClick?: ResolverInputTypes["Event_otherEventRankingInternalClick_operator"] | undefined | null,
	otherEventRankingImpression?: ResolverInputTypes["Event_otherEventRankingImpression_operator"] | undefined | null,
	bannerInternalClick?: ResolverInputTypes["Event_bannerInternalClick_operator"] | undefined | null,
	miscImpression?: ResolverInputTypes["Event_miscImpression_operator"] | undefined | null,
	miscInternalClick?: ResolverInputTypes["Event_miscInternalClick_operator"] | undefined | null,
	totalImpression?: ResolverInputTypes["Event_totalImpression_operator"] | undefined | null,
	internalClick?: ResolverInputTypes["Event_internalClick_operator"] | undefined | null,
	outboundClick?: ResolverInputTypes["Event_outboundClick_operator"] | undefined | null,
	externalClick?: ResolverInputTypes["Event_externalClick_operator"] | undefined | null,
	_viewsCountByMonth?: ResolverInputTypes["Event__viewsCountByMonth_operator"] | undefined | null,
	products__startDateTime?: ResolverInputTypes["Event_products__startDateTime_operator"] | undefined | null,
	products__endDateTime?: ResolverInputTypes["Event_products__endDateTime_operator"] | undefined | null,
	products__fullDay?: ResolverInputTypes["Event_products__fullDay_operator"] | undefined | null,
	products__linkedProduct?: ResolverInputTypes["Event_products__linkedProduct_operator"] | undefined | null,
	products__id?: ResolverInputTypes["Event_products__id_operator"] | undefined | null,
	legacyGallery?: ResolverInputTypes["Event_legacyGallery_operator"] | undefined | null,
	earliestStartDate?: ResolverInputTypes["Event_earliestStartDate_operator"] | undefined | null,
	latestEndDate?: ResolverInputTypes["Event_latestEndDate_operator"] | undefined | null,
	latestStartDateTime?: ResolverInputTypes["Event_latestStartDateTime_operator"] | undefined | null,
	hasTicketing?: ResolverInputTypes["Event_hasTicketing_operator"] | undefined | null,
	hasProducts?: ResolverInputTypes["Event_hasProducts_operator"] | undefined | null,
	updatedAt?: ResolverInputTypes["Event_updatedAt_operator"] | undefined | null,
	createdAt?: ResolverInputTypes["Event_createdAt_operator"] | undefined | null,
	id?: ResolverInputTypes["Event_id_operator"] | undefined | null,
	AND?: Array<ResolverInputTypes["Event_where_and"] | undefined | null> | undefined | null,
	OR?: Array<ResolverInputTypes["Event_where_or"] | undefined | null> | undefined | null
};
	["Event_region_operator"]: {
	equals?: ResolverInputTypes["Event_region_Input"] | undefined | null,
	not_equals?: ResolverInputTypes["Event_region_Input"] | undefined | null,
	in?: Array<ResolverInputTypes["Event_region_Input"] | undefined | null> | undefined | null,
	not_in?: Array<ResolverInputTypes["Event_region_Input"] | undefined | null> | undefined | null,
	all?: Array<ResolverInputTypes["Event_region_Input"] | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Event_region_Input"]:Event_region_Input;
	["Event_creator_operator"]: {
	equals?: ResolverInputTypes["JSON"] | undefined | null,
	not_equals?: ResolverInputTypes["JSON"] | undefined | null,
	in?: Array<ResolverInputTypes["JSON"] | undefined | null> | undefined | null,
	not_in?: Array<ResolverInputTypes["JSON"] | undefined | null> | undefined | null,
	all?: Array<ResolverInputTypes["JSON"] | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Event_permalink_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Event_status_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null
};
	["Event_level_operator"]: {
	equals?: ResolverInputTypes["Event_level_Input"] | undefined | null,
	not_equals?: ResolverInputTypes["Event_level_Input"] | undefined | null,
	in?: Array<ResolverInputTypes["Event_level_Input"] | undefined | null> | undefined | null,
	not_in?: Array<ResolverInputTypes["Event_level_Input"] | undefined | null> | undefined | null,
	all?: Array<ResolverInputTypes["Event_level_Input"] | undefined | null> | undefined | null
};
	["Event_level_Input"]:Event_level_Input;
	["Event_parent_operator"]: {
	equals?: ResolverInputTypes["JSON"] | undefined | null,
	not_equals?: ResolverInputTypes["JSON"] | undefined | null,
	in?: Array<ResolverInputTypes["JSON"] | undefined | null> | undefined | null,
	not_in?: Array<ResolverInputTypes["JSON"] | undefined | null> | undefined | null,
	all?: Array<ResolverInputTypes["JSON"] | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Event_name_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null
};
	["Event_chatbotEventTitle_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null
};
	["Event_chatbotSequence_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Event_thumbnail_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	exists?: boolean | undefined | null
};
	["Event_banner_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	exists?: boolean | undefined | null
};
	["Event_mobileBanner_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	exists?: boolean | undefined | null
};
	["Event_sections__startDatetime_operator"]: {
	equals?: ResolverInputTypes["DateTime"] | undefined | null,
	not_equals?: ResolverInputTypes["DateTime"] | undefined | null,
	greater_than_equal?: ResolverInputTypes["DateTime"] | undefined | null,
	greater_than?: ResolverInputTypes["DateTime"] | undefined | null,
	less_than_equal?: ResolverInputTypes["DateTime"] | undefined | null,
	less_than?: ResolverInputTypes["DateTime"] | undefined | null,
	like?: ResolverInputTypes["DateTime"] | undefined | null
};
	["Event_sections__endDatetime_operator"]: {
	equals?: ResolverInputTypes["DateTime"] | undefined | null,
	not_equals?: ResolverInputTypes["DateTime"] | undefined | null,
	greater_than_equal?: ResolverInputTypes["DateTime"] | undefined | null,
	greater_than?: ResolverInputTypes["DateTime"] | undefined | null,
	less_than_equal?: ResolverInputTypes["DateTime"] | undefined | null,
	less_than?: ResolverInputTypes["DateTime"] | undefined | null,
	like?: ResolverInputTypes["DateTime"] | undefined | null
};
	["Event_sections__fullDay_operator"]: {
	equals?: boolean | undefined | null,
	not_equals?: boolean | undefined | null,
	exists?: boolean | undefined | null
};
	["Event_sections__repeat_operator"]: {
	equals?: boolean | undefined | null,
	not_equals?: boolean | undefined | null,
	exists?: boolean | undefined | null
};
	["Event_sections__toThisDay_operator"]: {
	equals?: ResolverInputTypes["DateTime"] | undefined | null,
	not_equals?: ResolverInputTypes["DateTime"] | undefined | null,
	greater_than_equal?: ResolverInputTypes["DateTime"] | undefined | null,
	greater_than?: ResolverInputTypes["DateTime"] | undefined | null,
	less_than_equal?: ResolverInputTypes["DateTime"] | undefined | null,
	less_than?: ResolverInputTypes["DateTime"] | undefined | null,
	like?: ResolverInputTypes["DateTime"] | undefined | null
};
	["Event_sections__recurrance__type_operator"]: {
	equals?: ResolverInputTypes["Event_sections__recurrance__type_Input"] | undefined | null,
	not_equals?: ResolverInputTypes["Event_sections__recurrance__type_Input"] | undefined | null,
	in?: Array<ResolverInputTypes["Event_sections__recurrance__type_Input"] | undefined | null> | undefined | null,
	not_in?: Array<ResolverInputTypes["Event_sections__recurrance__type_Input"] | undefined | null> | undefined | null,
	all?: Array<ResolverInputTypes["Event_sections__recurrance__type_Input"] | undefined | null> | undefined | null
};
	["Event_sections__recurrance__type_Input"]:Event_sections__recurrance__type_Input;
	["Event_sections__recurrance__weekday_operator"]: {
	equals?: ResolverInputTypes["Event_sections__recurrance__weekday_Input"] | undefined | null,
	not_equals?: ResolverInputTypes["Event_sections__recurrance__weekday_Input"] | undefined | null,
	in?: Array<ResolverInputTypes["Event_sections__recurrance__weekday_Input"] | undefined | null> | undefined | null,
	not_in?: Array<ResolverInputTypes["Event_sections__recurrance__weekday_Input"] | undefined | null> | undefined | null,
	all?: Array<ResolverInputTypes["Event_sections__recurrance__weekday_Input"] | undefined | null> | undefined | null
};
	["Event_sections__recurrance__weekday_Input"]:Event_sections__recurrance__weekday_Input;
	["Event_sections__sameLocation_operator"]: {
	equals?: boolean | undefined | null,
	not_equals?: boolean | undefined | null,
	exists?: boolean | undefined | null
};
	["Event_sections__location_operator"]: {
	equals?: ResolverInputTypes["JSON"] | undefined | null,
	not_equals?: ResolverInputTypes["JSON"] | undefined | null,
	in?: Array<ResolverInputTypes["JSON"] | undefined | null> | undefined | null,
	not_in?: Array<ResolverInputTypes["JSON"] | undefined | null> | undefined | null,
	all?: Array<ResolverInputTypes["JSON"] | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Event_sections__district_operator"]: {
	equals?: ResolverInputTypes["JSON"] | undefined | null,
	not_equals?: ResolverInputTypes["JSON"] | undefined | null,
	in?: Array<ResolverInputTypes["JSON"] | undefined | null> | undefined | null,
	not_in?: Array<ResolverInputTypes["JSON"] | undefined | null> | undefined | null,
	all?: Array<ResolverInputTypes["JSON"] | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Event_sections__coordinate_operator"]: {
	equals?: Array<number | undefined | null> | undefined | null,
	not_equals?: Array<number | undefined | null> | undefined | null,
	greater_than_equal?: Array<number | undefined | null> | undefined | null,
	greater_than?: Array<number | undefined | null> | undefined | null,
	less_than_equal?: Array<number | undefined | null> | undefined | null,
	less_than?: Array<number | undefined | null> | undefined | null,
	near?: Array<number | undefined | null> | undefined | null,
	within?: ResolverInputTypes["GeoJSONObject"] | undefined | null,
	intersects?: ResolverInputTypes["GeoJSONObject"] | undefined | null,
	exists?: boolean | undefined | null
};
	["GeoJSONObject"]: {
	coordinates?: ResolverInputTypes["JSON"] | undefined | null,
	type?: string | undefined | null
};
	["Event_sections__linkAddress_operator"]: {
	equals?: boolean | undefined | null,
	not_equals?: boolean | undefined | null,
	exists?: boolean | undefined | null
};
	["Event_sections__address_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Event_sections__addressCoordinate_operator"]: {
	equals?: Array<number | undefined | null> | undefined | null,
	not_equals?: Array<number | undefined | null> | undefined | null,
	greater_than_equal?: Array<number | undefined | null> | undefined | null,
	greater_than?: Array<number | undefined | null> | undefined | null,
	less_than_equal?: Array<number | undefined | null> | undefined | null,
	less_than?: Array<number | undefined | null> | undefined | null,
	near?: Array<number | undefined | null> | undefined | null,
	within?: ResolverInputTypes["GeoJSONObject"] | undefined | null,
	intersects?: ResolverInputTypes["GeoJSONObject"] | undefined | null,
	exists?: boolean | undefined | null
};
	["Event_sections__id_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Event_ticketing__startDatetime_operator"]: {
	equals?: ResolverInputTypes["DateTime"] | undefined | null,
	not_equals?: ResolverInputTypes["DateTime"] | undefined | null,
	greater_than_equal?: ResolverInputTypes["DateTime"] | undefined | null,
	greater_than?: ResolverInputTypes["DateTime"] | undefined | null,
	less_than_equal?: ResolverInputTypes["DateTime"] | undefined | null,
	less_than?: ResolverInputTypes["DateTime"] | undefined | null,
	like?: ResolverInputTypes["DateTime"] | undefined | null
};
	["Event_ticketing__endDatetime_operator"]: {
	equals?: ResolverInputTypes["DateTime"] | undefined | null,
	not_equals?: ResolverInputTypes["DateTime"] | undefined | null,
	greater_than_equal?: ResolverInputTypes["DateTime"] | undefined | null,
	greater_than?: ResolverInputTypes["DateTime"] | undefined | null,
	less_than_equal?: ResolverInputTypes["DateTime"] | undefined | null,
	less_than?: ResolverInputTypes["DateTime"] | undefined | null,
	like?: ResolverInputTypes["DateTime"] | undefined | null
};
	["Event_ticketing__fullDay_operator"]: {
	equals?: boolean | undefined | null,
	not_equals?: boolean | undefined | null,
	exists?: boolean | undefined | null
};
	["Event_ticketing__type_operator"]: {
	equals?: ResolverInputTypes["Event_ticketing__type_Input"] | undefined | null,
	not_equals?: ResolverInputTypes["Event_ticketing__type_Input"] | undefined | null,
	in?: Array<ResolverInputTypes["Event_ticketing__type_Input"] | undefined | null> | undefined | null,
	not_in?: Array<ResolverInputTypes["Event_ticketing__type_Input"] | undefined | null> | undefined | null,
	all?: Array<ResolverInputTypes["Event_ticketing__type_Input"] | undefined | null> | undefined | null
};
	["Event_ticketing__type_Input"]:Event_ticketing__type_Input;
	["Event_ticketing__ticketingType_operator"]: {
	equals?: ResolverInputTypes["Event_ticketing__ticketingType_Input"] | undefined | null,
	not_equals?: ResolverInputTypes["Event_ticketing__ticketingType_Input"] | undefined | null,
	in?: Array<ResolverInputTypes["Event_ticketing__ticketingType_Input"] | undefined | null> | undefined | null,
	not_in?: Array<ResolverInputTypes["Event_ticketing__ticketingType_Input"] | undefined | null> | undefined | null,
	all?: Array<ResolverInputTypes["Event_ticketing__ticketingType_Input"] | undefined | null> | undefined | null
};
	["Event_ticketing__ticketingType_Input"]:Event_ticketing__ticketingType_Input;
	["Event_ticketing__linkedTicketing_operator"]: {
	equals?: ResolverInputTypes["JSON"] | undefined | null,
	not_equals?: ResolverInputTypes["JSON"] | undefined | null,
	in?: Array<ResolverInputTypes["JSON"] | undefined | null> | undefined | null,
	not_in?: Array<ResolverInputTypes["JSON"] | undefined | null> | undefined | null,
	all?: Array<ResolverInputTypes["JSON"] | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Event_ticketing__promotionType_operator"]: {
	equals?: ResolverInputTypes["Event_ticketing__promotionType_Input"] | undefined | null,
	not_equals?: ResolverInputTypes["Event_ticketing__promotionType_Input"] | undefined | null,
	in?: Array<ResolverInputTypes["Event_ticketing__promotionType_Input"] | undefined | null> | undefined | null,
	not_in?: Array<ResolverInputTypes["Event_ticketing__promotionType_Input"] | undefined | null> | undefined | null,
	all?: Array<ResolverInputTypes["Event_ticketing__promotionType_Input"] | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Event_ticketing__promotionType_Input"]:Event_ticketing__promotionType_Input;
	["Event_ticketing__location_operator"]: {
	equals?: ResolverInputTypes["JSON"] | undefined | null,
	not_equals?: ResolverInputTypes["JSON"] | undefined | null,
	in?: Array<ResolverInputTypes["JSON"] | undefined | null> | undefined | null,
	not_in?: Array<ResolverInputTypes["JSON"] | undefined | null> | undefined | null,
	all?: Array<ResolverInputTypes["JSON"] | undefined | null> | undefined | null
};
	["Event_ticketing__website_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Event_ticketing__description_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	exists?: boolean | undefined | null
};
	["Event_ticketing__id_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Event_legacyContent_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	exists?: boolean | undefined | null
};
	["Event_showLegacyContent_operator"]: {
	equals?: boolean | undefined | null,
	not_equals?: boolean | undefined | null,
	exists?: boolean | undefined | null
};
	["Event_criteria__categories_operator"]: {
	equals?: ResolverInputTypes["JSON"] | undefined | null,
	not_equals?: ResolverInputTypes["JSON"] | undefined | null,
	in?: Array<ResolverInputTypes["JSON"] | undefined | null> | undefined | null,
	not_in?: Array<ResolverInputTypes["JSON"] | undefined | null> | undefined | null,
	all?: Array<ResolverInputTypes["JSON"] | undefined | null> | undefined | null
};
	["Event_criteria__audiences_operator"]: {
	equals?: ResolverInputTypes["JSON"] | undefined | null,
	not_equals?: ResolverInputTypes["JSON"] | undefined | null,
	in?: Array<ResolverInputTypes["JSON"] | undefined | null> | undefined | null,
	not_in?: Array<ResolverInputTypes["JSON"] | undefined | null> | undefined | null,
	all?: Array<ResolverInputTypes["JSON"] | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Event_criteria__tags_operator"]: {
	equals?: ResolverInputTypes["JSON"] | undefined | null,
	not_equals?: ResolverInputTypes["JSON"] | undefined | null,
	in?: Array<ResolverInputTypes["JSON"] | undefined | null> | undefined | null,
	not_in?: Array<ResolverInputTypes["JSON"] | undefined | null> | undefined | null,
	all?: Array<ResolverInputTypes["JSON"] | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Event_criteria__specialTags_operator"]: {
	equals?: ResolverInputTypes["JSON"] | undefined | null,
	not_equals?: ResolverInputTypes["JSON"] | undefined | null,
	in?: Array<ResolverInputTypes["JSON"] | undefined | null> | undefined | null,
	not_in?: Array<ResolverInputTypes["JSON"] | undefined | null> | undefined | null,
	all?: Array<ResolverInputTypes["JSON"] | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Event_walkIn_operator"]: {
	equals?: boolean | undefined | null,
	not_equals?: boolean | undefined | null,
	exists?: boolean | undefined | null
};
	["Event_contact__code_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Event_contact__phone_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Event_contact__page_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Event_contact__email_operator"]: {
	equals?: ResolverInputTypes["EmailAddress"] | undefined | null,
	not_equals?: ResolverInputTypes["EmailAddress"] | undefined | null,
	like?: ResolverInputTypes["EmailAddress"] | undefined | null,
	contains?: ResolverInputTypes["EmailAddress"] | undefined | null,
	in?: Array<ResolverInputTypes["EmailAddress"] | undefined | null> | undefined | null,
	not_in?: Array<ResolverInputTypes["EmailAddress"] | undefined | null> | undefined | null,
	all?: Array<ResolverInputTypes["EmailAddress"] | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Event_contact__privacy_operator"]: {
	equals?: ResolverInputTypes["JSON"] | undefined | null,
	not_equals?: ResolverInputTypes["JSON"] | undefined | null,
	like?: ResolverInputTypes["JSON"] | undefined | null,
	contains?: ResolverInputTypes["JSON"] | undefined | null,
	exists?: boolean | undefined | null
};
	["Event_views_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Event_free_operator"]: {
	equals?: boolean | undefined | null,
	not_equals?: boolean | undefined | null,
	exists?: boolean | undefined | null
};
	["Event_fees__fee_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Event_fees__remark_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Event_fees__id_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Event_homePageInternalClick_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Event_homePageImpression_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Event_eodClickRate_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Event_eodImpressionRate_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Event_top10ClickRateHomepage_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Event_top10ImpressionkRateHomepage_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Event_otherEventRankingClickRateHomepage_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Event_otherEventRankingImpressionHomePage_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Event_userPreferenceClickRate_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Event_userPreferenceImpression_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Event_eventSearchInternalClick_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Event_eventListImpression_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Event_latestEventInternalClick_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Event_latestEventImpression_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Event_topTenEventRankingInternalClick_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Event_topTenImpression_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Event_editorChoiceInternalClick_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Event_editorChoiceImpression_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Event_otherEventRankingInternalClick_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Event_otherEventRankingImpression_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Event_bannerInternalClick_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Event_miscImpression_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Event_miscInternalClick_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Event_totalImpression_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Event_internalClick_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Event_outboundClick_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Event_externalClick_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Event__viewsCountByMonth_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Event_products__startDateTime_operator"]: {
	equals?: ResolverInputTypes["DateTime"] | undefined | null,
	not_equals?: ResolverInputTypes["DateTime"] | undefined | null,
	greater_than_equal?: ResolverInputTypes["DateTime"] | undefined | null,
	greater_than?: ResolverInputTypes["DateTime"] | undefined | null,
	less_than_equal?: ResolverInputTypes["DateTime"] | undefined | null,
	less_than?: ResolverInputTypes["DateTime"] | undefined | null,
	like?: ResolverInputTypes["DateTime"] | undefined | null
};
	["Event_products__endDateTime_operator"]: {
	equals?: ResolverInputTypes["DateTime"] | undefined | null,
	not_equals?: ResolverInputTypes["DateTime"] | undefined | null,
	greater_than_equal?: ResolverInputTypes["DateTime"] | undefined | null,
	greater_than?: ResolverInputTypes["DateTime"] | undefined | null,
	less_than_equal?: ResolverInputTypes["DateTime"] | undefined | null,
	less_than?: ResolverInputTypes["DateTime"] | undefined | null,
	like?: ResolverInputTypes["DateTime"] | undefined | null
};
	["Event_products__fullDay_operator"]: {
	equals?: boolean | undefined | null,
	not_equals?: boolean | undefined | null,
	exists?: boolean | undefined | null
};
	["Event_products__linkedProduct_operator"]: {
	equals?: ResolverInputTypes["JSON"] | undefined | null,
	not_equals?: ResolverInputTypes["JSON"] | undefined | null,
	in?: Array<ResolverInputTypes["JSON"] | undefined | null> | undefined | null,
	not_in?: Array<ResolverInputTypes["JSON"] | undefined | null> | undefined | null,
	all?: Array<ResolverInputTypes["JSON"] | undefined | null> | undefined | null
};
	["Event_products__id_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Event_legacyGallery_operator"]: {
	equals?: ResolverInputTypes["JSON"] | undefined | null,
	not_equals?: ResolverInputTypes["JSON"] | undefined | null,
	in?: Array<ResolverInputTypes["JSON"] | undefined | null> | undefined | null,
	not_in?: Array<ResolverInputTypes["JSON"] | undefined | null> | undefined | null,
	all?: Array<ResolverInputTypes["JSON"] | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Event_earliestStartDate_operator"]: {
	equals?: ResolverInputTypes["DateTime"] | undefined | null,
	not_equals?: ResolverInputTypes["DateTime"] | undefined | null,
	greater_than_equal?: ResolverInputTypes["DateTime"] | undefined | null,
	greater_than?: ResolverInputTypes["DateTime"] | undefined | null,
	less_than_equal?: ResolverInputTypes["DateTime"] | undefined | null,
	less_than?: ResolverInputTypes["DateTime"] | undefined | null,
	like?: ResolverInputTypes["DateTime"] | undefined | null,
	exists?: boolean | undefined | null
};
	["Event_latestEndDate_operator"]: {
	equals?: ResolverInputTypes["DateTime"] | undefined | null,
	not_equals?: ResolverInputTypes["DateTime"] | undefined | null,
	greater_than_equal?: ResolverInputTypes["DateTime"] | undefined | null,
	greater_than?: ResolverInputTypes["DateTime"] | undefined | null,
	less_than_equal?: ResolverInputTypes["DateTime"] | undefined | null,
	less_than?: ResolverInputTypes["DateTime"] | undefined | null,
	like?: ResolverInputTypes["DateTime"] | undefined | null,
	exists?: boolean | undefined | null
};
	["Event_latestStartDateTime_operator"]: {
	equals?: ResolverInputTypes["DateTime"] | undefined | null,
	not_equals?: ResolverInputTypes["DateTime"] | undefined | null,
	greater_than_equal?: ResolverInputTypes["DateTime"] | undefined | null,
	greater_than?: ResolverInputTypes["DateTime"] | undefined | null,
	less_than_equal?: ResolverInputTypes["DateTime"] | undefined | null,
	less_than?: ResolverInputTypes["DateTime"] | undefined | null,
	like?: ResolverInputTypes["DateTime"] | undefined | null,
	exists?: boolean | undefined | null
};
	["Event_hasTicketing_operator"]: {
	equals?: boolean | undefined | null,
	not_equals?: boolean | undefined | null,
	exists?: boolean | undefined | null
};
	["Event_hasProducts_operator"]: {
	equals?: boolean | undefined | null,
	not_equals?: boolean | undefined | null,
	exists?: boolean | undefined | null
};
	["Event_updatedAt_operator"]: {
	equals?: ResolverInputTypes["DateTime"] | undefined | null,
	not_equals?: ResolverInputTypes["DateTime"] | undefined | null,
	greater_than_equal?: ResolverInputTypes["DateTime"] | undefined | null,
	greater_than?: ResolverInputTypes["DateTime"] | undefined | null,
	less_than_equal?: ResolverInputTypes["DateTime"] | undefined | null,
	less_than?: ResolverInputTypes["DateTime"] | undefined | null,
	like?: ResolverInputTypes["DateTime"] | undefined | null,
	exists?: boolean | undefined | null
};
	["Event_createdAt_operator"]: {
	equals?: ResolverInputTypes["DateTime"] | undefined | null,
	not_equals?: ResolverInputTypes["DateTime"] | undefined | null,
	greater_than_equal?: ResolverInputTypes["DateTime"] | undefined | null,
	greater_than?: ResolverInputTypes["DateTime"] | undefined | null,
	less_than_equal?: ResolverInputTypes["DateTime"] | undefined | null,
	less_than?: ResolverInputTypes["DateTime"] | undefined | null,
	like?: ResolverInputTypes["DateTime"] | undefined | null,
	exists?: boolean | undefined | null
};
	["Event_id_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Event_where_and"]: {
	region?: ResolverInputTypes["Event_region_operator"] | undefined | null,
	creator?: ResolverInputTypes["Event_creator_operator"] | undefined | null,
	permalink?: ResolverInputTypes["Event_permalink_operator"] | undefined | null,
	status?: ResolverInputTypes["Event_status_operator"] | undefined | null,
	level?: ResolverInputTypes["Event_level_operator"] | undefined | null,
	parent?: ResolverInputTypes["Event_parent_operator"] | undefined | null,
	name?: ResolverInputTypes["Event_name_operator"] | undefined | null,
	chatbotEventTitle?: ResolverInputTypes["Event_chatbotEventTitle_operator"] | undefined | null,
	chatbotSequence?: ResolverInputTypes["Event_chatbotSequence_operator"] | undefined | null,
	thumbnail?: ResolverInputTypes["Event_thumbnail_operator"] | undefined | null,
	banner?: ResolverInputTypes["Event_banner_operator"] | undefined | null,
	mobileBanner?: ResolverInputTypes["Event_mobileBanner_operator"] | undefined | null,
	sections__startDatetime?: ResolverInputTypes["Event_sections__startDatetime_operator"] | undefined | null,
	sections__endDatetime?: ResolverInputTypes["Event_sections__endDatetime_operator"] | undefined | null,
	sections__fullDay?: ResolverInputTypes["Event_sections__fullDay_operator"] | undefined | null,
	sections__repeat?: ResolverInputTypes["Event_sections__repeat_operator"] | undefined | null,
	sections__toThisDay?: ResolverInputTypes["Event_sections__toThisDay_operator"] | undefined | null,
	sections__recurrance__type?: ResolverInputTypes["Event_sections__recurrance__type_operator"] | undefined | null,
	sections__recurrance__weekday?: ResolverInputTypes["Event_sections__recurrance__weekday_operator"] | undefined | null,
	sections__sameLocation?: ResolverInputTypes["Event_sections__sameLocation_operator"] | undefined | null,
	sections__location?: ResolverInputTypes["Event_sections__location_operator"] | undefined | null,
	sections__district?: ResolverInputTypes["Event_sections__district_operator"] | undefined | null,
	sections__coordinate?: ResolverInputTypes["Event_sections__coordinate_operator"] | undefined | null,
	sections__linkAddress?: ResolverInputTypes["Event_sections__linkAddress_operator"] | undefined | null,
	sections__address?: ResolverInputTypes["Event_sections__address_operator"] | undefined | null,
	sections__addressCoordinate?: ResolverInputTypes["Event_sections__addressCoordinate_operator"] | undefined | null,
	sections__id?: ResolverInputTypes["Event_sections__id_operator"] | undefined | null,
	ticketing__startDatetime?: ResolverInputTypes["Event_ticketing__startDatetime_operator"] | undefined | null,
	ticketing__endDatetime?: ResolverInputTypes["Event_ticketing__endDatetime_operator"] | undefined | null,
	ticketing__fullDay?: ResolverInputTypes["Event_ticketing__fullDay_operator"] | undefined | null,
	ticketing__type?: ResolverInputTypes["Event_ticketing__type_operator"] | undefined | null,
	ticketing__ticketingType?: ResolverInputTypes["Event_ticketing__ticketingType_operator"] | undefined | null,
	ticketing__linkedTicketing?: ResolverInputTypes["Event_ticketing__linkedTicketing_operator"] | undefined | null,
	ticketing__promotionType?: ResolverInputTypes["Event_ticketing__promotionType_operator"] | undefined | null,
	ticketing__location?: ResolverInputTypes["Event_ticketing__location_operator"] | undefined | null,
	ticketing__website?: ResolverInputTypes["Event_ticketing__website_operator"] | undefined | null,
	ticketing__description?: ResolverInputTypes["Event_ticketing__description_operator"] | undefined | null,
	ticketing__id?: ResolverInputTypes["Event_ticketing__id_operator"] | undefined | null,
	legacyContent?: ResolverInputTypes["Event_legacyContent_operator"] | undefined | null,
	showLegacyContent?: ResolverInputTypes["Event_showLegacyContent_operator"] | undefined | null,
	criteria__categories?: ResolverInputTypes["Event_criteria__categories_operator"] | undefined | null,
	criteria__audiences?: ResolverInputTypes["Event_criteria__audiences_operator"] | undefined | null,
	criteria__tags?: ResolverInputTypes["Event_criteria__tags_operator"] | undefined | null,
	criteria__specialTags?: ResolverInputTypes["Event_criteria__specialTags_operator"] | undefined | null,
	walkIn?: ResolverInputTypes["Event_walkIn_operator"] | undefined | null,
	contact__code?: ResolverInputTypes["Event_contact__code_operator"] | undefined | null,
	contact__phone?: ResolverInputTypes["Event_contact__phone_operator"] | undefined | null,
	contact__page?: ResolverInputTypes["Event_contact__page_operator"] | undefined | null,
	contact__email?: ResolverInputTypes["Event_contact__email_operator"] | undefined | null,
	contact__privacy?: ResolverInputTypes["Event_contact__privacy_operator"] | undefined | null,
	views?: ResolverInputTypes["Event_views_operator"] | undefined | null,
	free?: ResolverInputTypes["Event_free_operator"] | undefined | null,
	fees__fee?: ResolverInputTypes["Event_fees__fee_operator"] | undefined | null,
	fees__remark?: ResolverInputTypes["Event_fees__remark_operator"] | undefined | null,
	fees__id?: ResolverInputTypes["Event_fees__id_operator"] | undefined | null,
	homePageInternalClick?: ResolverInputTypes["Event_homePageInternalClick_operator"] | undefined | null,
	homePageImpression?: ResolverInputTypes["Event_homePageImpression_operator"] | undefined | null,
	eodClickRate?: ResolverInputTypes["Event_eodClickRate_operator"] | undefined | null,
	eodImpressionRate?: ResolverInputTypes["Event_eodImpressionRate_operator"] | undefined | null,
	top10ClickRateHomepage?: ResolverInputTypes["Event_top10ClickRateHomepage_operator"] | undefined | null,
	top10ImpressionkRateHomepage?: ResolverInputTypes["Event_top10ImpressionkRateHomepage_operator"] | undefined | null,
	otherEventRankingClickRateHomepage?: ResolverInputTypes["Event_otherEventRankingClickRateHomepage_operator"] | undefined | null,
	otherEventRankingImpressionHomePage?: ResolverInputTypes["Event_otherEventRankingImpressionHomePage_operator"] | undefined | null,
	userPreferenceClickRate?: ResolverInputTypes["Event_userPreferenceClickRate_operator"] | undefined | null,
	userPreferenceImpression?: ResolverInputTypes["Event_userPreferenceImpression_operator"] | undefined | null,
	eventSearchInternalClick?: ResolverInputTypes["Event_eventSearchInternalClick_operator"] | undefined | null,
	eventListImpression?: ResolverInputTypes["Event_eventListImpression_operator"] | undefined | null,
	latestEventInternalClick?: ResolverInputTypes["Event_latestEventInternalClick_operator"] | undefined | null,
	latestEventImpression?: ResolverInputTypes["Event_latestEventImpression_operator"] | undefined | null,
	topTenEventRankingInternalClick?: ResolverInputTypes["Event_topTenEventRankingInternalClick_operator"] | undefined | null,
	topTenImpression?: ResolverInputTypes["Event_topTenImpression_operator"] | undefined | null,
	editorChoiceInternalClick?: ResolverInputTypes["Event_editorChoiceInternalClick_operator"] | undefined | null,
	editorChoiceImpression?: ResolverInputTypes["Event_editorChoiceImpression_operator"] | undefined | null,
	otherEventRankingInternalClick?: ResolverInputTypes["Event_otherEventRankingInternalClick_operator"] | undefined | null,
	otherEventRankingImpression?: ResolverInputTypes["Event_otherEventRankingImpression_operator"] | undefined | null,
	bannerInternalClick?: ResolverInputTypes["Event_bannerInternalClick_operator"] | undefined | null,
	miscImpression?: ResolverInputTypes["Event_miscImpression_operator"] | undefined | null,
	miscInternalClick?: ResolverInputTypes["Event_miscInternalClick_operator"] | undefined | null,
	totalImpression?: ResolverInputTypes["Event_totalImpression_operator"] | undefined | null,
	internalClick?: ResolverInputTypes["Event_internalClick_operator"] | undefined | null,
	outboundClick?: ResolverInputTypes["Event_outboundClick_operator"] | undefined | null,
	externalClick?: ResolverInputTypes["Event_externalClick_operator"] | undefined | null,
	_viewsCountByMonth?: ResolverInputTypes["Event__viewsCountByMonth_operator"] | undefined | null,
	products__startDateTime?: ResolverInputTypes["Event_products__startDateTime_operator"] | undefined | null,
	products__endDateTime?: ResolverInputTypes["Event_products__endDateTime_operator"] | undefined | null,
	products__fullDay?: ResolverInputTypes["Event_products__fullDay_operator"] | undefined | null,
	products__linkedProduct?: ResolverInputTypes["Event_products__linkedProduct_operator"] | undefined | null,
	products__id?: ResolverInputTypes["Event_products__id_operator"] | undefined | null,
	legacyGallery?: ResolverInputTypes["Event_legacyGallery_operator"] | undefined | null,
	earliestStartDate?: ResolverInputTypes["Event_earliestStartDate_operator"] | undefined | null,
	latestEndDate?: ResolverInputTypes["Event_latestEndDate_operator"] | undefined | null,
	latestStartDateTime?: ResolverInputTypes["Event_latestStartDateTime_operator"] | undefined | null,
	hasTicketing?: ResolverInputTypes["Event_hasTicketing_operator"] | undefined | null,
	hasProducts?: ResolverInputTypes["Event_hasProducts_operator"] | undefined | null,
	updatedAt?: ResolverInputTypes["Event_updatedAt_operator"] | undefined | null,
	createdAt?: ResolverInputTypes["Event_createdAt_operator"] | undefined | null,
	id?: ResolverInputTypes["Event_id_operator"] | undefined | null,
	AND?: Array<ResolverInputTypes["Event_where_and"] | undefined | null> | undefined | null,
	OR?: Array<ResolverInputTypes["Event_where_or"] | undefined | null> | undefined | null
};
	["Event_where_or"]: {
	region?: ResolverInputTypes["Event_region_operator"] | undefined | null,
	creator?: ResolverInputTypes["Event_creator_operator"] | undefined | null,
	permalink?: ResolverInputTypes["Event_permalink_operator"] | undefined | null,
	status?: ResolverInputTypes["Event_status_operator"] | undefined | null,
	level?: ResolverInputTypes["Event_level_operator"] | undefined | null,
	parent?: ResolverInputTypes["Event_parent_operator"] | undefined | null,
	name?: ResolverInputTypes["Event_name_operator"] | undefined | null,
	chatbotEventTitle?: ResolverInputTypes["Event_chatbotEventTitle_operator"] | undefined | null,
	chatbotSequence?: ResolverInputTypes["Event_chatbotSequence_operator"] | undefined | null,
	thumbnail?: ResolverInputTypes["Event_thumbnail_operator"] | undefined | null,
	banner?: ResolverInputTypes["Event_banner_operator"] | undefined | null,
	mobileBanner?: ResolverInputTypes["Event_mobileBanner_operator"] | undefined | null,
	sections__startDatetime?: ResolverInputTypes["Event_sections__startDatetime_operator"] | undefined | null,
	sections__endDatetime?: ResolverInputTypes["Event_sections__endDatetime_operator"] | undefined | null,
	sections__fullDay?: ResolverInputTypes["Event_sections__fullDay_operator"] | undefined | null,
	sections__repeat?: ResolverInputTypes["Event_sections__repeat_operator"] | undefined | null,
	sections__toThisDay?: ResolverInputTypes["Event_sections__toThisDay_operator"] | undefined | null,
	sections__recurrance__type?: ResolverInputTypes["Event_sections__recurrance__type_operator"] | undefined | null,
	sections__recurrance__weekday?: ResolverInputTypes["Event_sections__recurrance__weekday_operator"] | undefined | null,
	sections__sameLocation?: ResolverInputTypes["Event_sections__sameLocation_operator"] | undefined | null,
	sections__location?: ResolverInputTypes["Event_sections__location_operator"] | undefined | null,
	sections__district?: ResolverInputTypes["Event_sections__district_operator"] | undefined | null,
	sections__coordinate?: ResolverInputTypes["Event_sections__coordinate_operator"] | undefined | null,
	sections__linkAddress?: ResolverInputTypes["Event_sections__linkAddress_operator"] | undefined | null,
	sections__address?: ResolverInputTypes["Event_sections__address_operator"] | undefined | null,
	sections__addressCoordinate?: ResolverInputTypes["Event_sections__addressCoordinate_operator"] | undefined | null,
	sections__id?: ResolverInputTypes["Event_sections__id_operator"] | undefined | null,
	ticketing__startDatetime?: ResolverInputTypes["Event_ticketing__startDatetime_operator"] | undefined | null,
	ticketing__endDatetime?: ResolverInputTypes["Event_ticketing__endDatetime_operator"] | undefined | null,
	ticketing__fullDay?: ResolverInputTypes["Event_ticketing__fullDay_operator"] | undefined | null,
	ticketing__type?: ResolverInputTypes["Event_ticketing__type_operator"] | undefined | null,
	ticketing__ticketingType?: ResolverInputTypes["Event_ticketing__ticketingType_operator"] | undefined | null,
	ticketing__linkedTicketing?: ResolverInputTypes["Event_ticketing__linkedTicketing_operator"] | undefined | null,
	ticketing__promotionType?: ResolverInputTypes["Event_ticketing__promotionType_operator"] | undefined | null,
	ticketing__location?: ResolverInputTypes["Event_ticketing__location_operator"] | undefined | null,
	ticketing__website?: ResolverInputTypes["Event_ticketing__website_operator"] | undefined | null,
	ticketing__description?: ResolverInputTypes["Event_ticketing__description_operator"] | undefined | null,
	ticketing__id?: ResolverInputTypes["Event_ticketing__id_operator"] | undefined | null,
	legacyContent?: ResolverInputTypes["Event_legacyContent_operator"] | undefined | null,
	showLegacyContent?: ResolverInputTypes["Event_showLegacyContent_operator"] | undefined | null,
	criteria__categories?: ResolverInputTypes["Event_criteria__categories_operator"] | undefined | null,
	criteria__audiences?: ResolverInputTypes["Event_criteria__audiences_operator"] | undefined | null,
	criteria__tags?: ResolverInputTypes["Event_criteria__tags_operator"] | undefined | null,
	criteria__specialTags?: ResolverInputTypes["Event_criteria__specialTags_operator"] | undefined | null,
	walkIn?: ResolverInputTypes["Event_walkIn_operator"] | undefined | null,
	contact__code?: ResolverInputTypes["Event_contact__code_operator"] | undefined | null,
	contact__phone?: ResolverInputTypes["Event_contact__phone_operator"] | undefined | null,
	contact__page?: ResolverInputTypes["Event_contact__page_operator"] | undefined | null,
	contact__email?: ResolverInputTypes["Event_contact__email_operator"] | undefined | null,
	contact__privacy?: ResolverInputTypes["Event_contact__privacy_operator"] | undefined | null,
	views?: ResolverInputTypes["Event_views_operator"] | undefined | null,
	free?: ResolverInputTypes["Event_free_operator"] | undefined | null,
	fees__fee?: ResolverInputTypes["Event_fees__fee_operator"] | undefined | null,
	fees__remark?: ResolverInputTypes["Event_fees__remark_operator"] | undefined | null,
	fees__id?: ResolverInputTypes["Event_fees__id_operator"] | undefined | null,
	homePageInternalClick?: ResolverInputTypes["Event_homePageInternalClick_operator"] | undefined | null,
	homePageImpression?: ResolverInputTypes["Event_homePageImpression_operator"] | undefined | null,
	eodClickRate?: ResolverInputTypes["Event_eodClickRate_operator"] | undefined | null,
	eodImpressionRate?: ResolverInputTypes["Event_eodImpressionRate_operator"] | undefined | null,
	top10ClickRateHomepage?: ResolverInputTypes["Event_top10ClickRateHomepage_operator"] | undefined | null,
	top10ImpressionkRateHomepage?: ResolverInputTypes["Event_top10ImpressionkRateHomepage_operator"] | undefined | null,
	otherEventRankingClickRateHomepage?: ResolverInputTypes["Event_otherEventRankingClickRateHomepage_operator"] | undefined | null,
	otherEventRankingImpressionHomePage?: ResolverInputTypes["Event_otherEventRankingImpressionHomePage_operator"] | undefined | null,
	userPreferenceClickRate?: ResolverInputTypes["Event_userPreferenceClickRate_operator"] | undefined | null,
	userPreferenceImpression?: ResolverInputTypes["Event_userPreferenceImpression_operator"] | undefined | null,
	eventSearchInternalClick?: ResolverInputTypes["Event_eventSearchInternalClick_operator"] | undefined | null,
	eventListImpression?: ResolverInputTypes["Event_eventListImpression_operator"] | undefined | null,
	latestEventInternalClick?: ResolverInputTypes["Event_latestEventInternalClick_operator"] | undefined | null,
	latestEventImpression?: ResolverInputTypes["Event_latestEventImpression_operator"] | undefined | null,
	topTenEventRankingInternalClick?: ResolverInputTypes["Event_topTenEventRankingInternalClick_operator"] | undefined | null,
	topTenImpression?: ResolverInputTypes["Event_topTenImpression_operator"] | undefined | null,
	editorChoiceInternalClick?: ResolverInputTypes["Event_editorChoiceInternalClick_operator"] | undefined | null,
	editorChoiceImpression?: ResolverInputTypes["Event_editorChoiceImpression_operator"] | undefined | null,
	otherEventRankingInternalClick?: ResolverInputTypes["Event_otherEventRankingInternalClick_operator"] | undefined | null,
	otherEventRankingImpression?: ResolverInputTypes["Event_otherEventRankingImpression_operator"] | undefined | null,
	bannerInternalClick?: ResolverInputTypes["Event_bannerInternalClick_operator"] | undefined | null,
	miscImpression?: ResolverInputTypes["Event_miscImpression_operator"] | undefined | null,
	miscInternalClick?: ResolverInputTypes["Event_miscInternalClick_operator"] | undefined | null,
	totalImpression?: ResolverInputTypes["Event_totalImpression_operator"] | undefined | null,
	internalClick?: ResolverInputTypes["Event_internalClick_operator"] | undefined | null,
	outboundClick?: ResolverInputTypes["Event_outboundClick_operator"] | undefined | null,
	externalClick?: ResolverInputTypes["Event_externalClick_operator"] | undefined | null,
	_viewsCountByMonth?: ResolverInputTypes["Event__viewsCountByMonth_operator"] | undefined | null,
	products__startDateTime?: ResolverInputTypes["Event_products__startDateTime_operator"] | undefined | null,
	products__endDateTime?: ResolverInputTypes["Event_products__endDateTime_operator"] | undefined | null,
	products__fullDay?: ResolverInputTypes["Event_products__fullDay_operator"] | undefined | null,
	products__linkedProduct?: ResolverInputTypes["Event_products__linkedProduct_operator"] | undefined | null,
	products__id?: ResolverInputTypes["Event_products__id_operator"] | undefined | null,
	legacyGallery?: ResolverInputTypes["Event_legacyGallery_operator"] | undefined | null,
	earliestStartDate?: ResolverInputTypes["Event_earliestStartDate_operator"] | undefined | null,
	latestEndDate?: ResolverInputTypes["Event_latestEndDate_operator"] | undefined | null,
	latestStartDateTime?: ResolverInputTypes["Event_latestStartDateTime_operator"] | undefined | null,
	hasTicketing?: ResolverInputTypes["Event_hasTicketing_operator"] | undefined | null,
	hasProducts?: ResolverInputTypes["Event_hasProducts_operator"] | undefined | null,
	updatedAt?: ResolverInputTypes["Event_updatedAt_operator"] | undefined | null,
	createdAt?: ResolverInputTypes["Event_createdAt_operator"] | undefined | null,
	id?: ResolverInputTypes["Event_id_operator"] | undefined | null,
	AND?: Array<ResolverInputTypes["Event_where_and"] | undefined | null> | undefined | null,
	OR?: Array<ResolverInputTypes["Event_where_or"] | undefined | null> | undefined | null
};
	["countEvents"]: AliasType<{
	totalDocs?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["eventsDocAccess"]: AliasType<{
	fields?:ResolverInputTypes["EventsDocAccessFields"],
	create?:ResolverInputTypes["EventsCreateDocAccess"],
	read?:ResolverInputTypes["EventsReadDocAccess"],
	update?:ResolverInputTypes["EventsUpdateDocAccess"],
	delete?:ResolverInputTypes["EventsDeleteDocAccess"],
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields"]: AliasType<{
	region?:ResolverInputTypes["EventsDocAccessFields_region"],
	creator?:ResolverInputTypes["EventsDocAccessFields_creator"],
	permalink?:ResolverInputTypes["EventsDocAccessFields_permalink"],
	status?:ResolverInputTypes["EventsDocAccessFields_status"],
	level?:ResolverInputTypes["EventsDocAccessFields_level"],
	parent?:ResolverInputTypes["EventsDocAccessFields_parent"],
	name?:ResolverInputTypes["EventsDocAccessFields_name"],
	chatbotEventTitle?:ResolverInputTypes["EventsDocAccessFields_chatbotEventTitle"],
	chatbotSequence?:ResolverInputTypes["EventsDocAccessFields_chatbotSequence"],
	thumbnail?:ResolverInputTypes["EventsDocAccessFields_thumbnail"],
	banner?:ResolverInputTypes["EventsDocAccessFields_banner"],
	mobileBanner?:ResolverInputTypes["EventsDocAccessFields_mobileBanner"],
	sections?:ResolverInputTypes["EventsDocAccessFields_sections"],
	ticketing?:ResolverInputTypes["EventsDocAccessFields_ticketing"],
	content?:ResolverInputTypes["EventsDocAccessFields_content"],
	legacyContent?:ResolverInputTypes["EventsDocAccessFields_legacyContent"],
	showLegacyContent?:ResolverInputTypes["EventsDocAccessFields_showLegacyContent"],
	criteria?:ResolverInputTypes["EventsDocAccessFields_criteria"],
	walkIn?:ResolverInputTypes["EventsDocAccessFields_walkIn"],
	contact?:ResolverInputTypes["EventsDocAccessFields_contact"],
	views?:ResolverInputTypes["EventsDocAccessFields_views"],
	free?:ResolverInputTypes["EventsDocAccessFields_free"],
	fees?:ResolverInputTypes["EventsDocAccessFields_fees"],
	homePageInternalClick?:ResolverInputTypes["EventsDocAccessFields_homePageInternalClick"],
	homePageImpression?:ResolverInputTypes["EventsDocAccessFields_homePageImpression"],
	eodClickRate?:ResolverInputTypes["EventsDocAccessFields_eodClickRate"],
	eodImpressionRate?:ResolverInputTypes["EventsDocAccessFields_eodImpressionRate"],
	top10ClickRateHomepage?:ResolverInputTypes["EventsDocAccessFields_top10ClickRateHomepage"],
	top10ImpressionkRateHomepage?:ResolverInputTypes["EventsDocAccessFields_top10ImpressionkRateHomepage"],
	otherEventRankingClickRateHomepage?:ResolverInputTypes["EventsDocAccessFields_otherEventRankingClickRateHomepage"],
	otherEventRankingImpressionHomePage?:ResolverInputTypes["EventsDocAccessFields_otherEventRankingImpressionHomePage"],
	userPreferenceClickRate?:ResolverInputTypes["EventsDocAccessFields_userPreferenceClickRate"],
	userPreferenceImpression?:ResolverInputTypes["EventsDocAccessFields_userPreferenceImpression"],
	eventSearchInternalClick?:ResolverInputTypes["EventsDocAccessFields_eventSearchInternalClick"],
	eventListImpression?:ResolverInputTypes["EventsDocAccessFields_eventListImpression"],
	latestEventInternalClick?:ResolverInputTypes["EventsDocAccessFields_latestEventInternalClick"],
	latestEventImpression?:ResolverInputTypes["EventsDocAccessFields_latestEventImpression"],
	topTenEventRankingInternalClick?:ResolverInputTypes["EventsDocAccessFields_topTenEventRankingInternalClick"],
	topTenImpression?:ResolverInputTypes["EventsDocAccessFields_topTenImpression"],
	editorChoiceInternalClick?:ResolverInputTypes["EventsDocAccessFields_editorChoiceInternalClick"],
	editorChoiceImpression?:ResolverInputTypes["EventsDocAccessFields_editorChoiceImpression"],
	otherEventRankingInternalClick?:ResolverInputTypes["EventsDocAccessFields_otherEventRankingInternalClick"],
	otherEventRankingImpression?:ResolverInputTypes["EventsDocAccessFields_otherEventRankingImpression"],
	bannerInternalClick?:ResolverInputTypes["EventsDocAccessFields_bannerInternalClick"],
	miscImpression?:ResolverInputTypes["EventsDocAccessFields_miscImpression"],
	miscInternalClick?:ResolverInputTypes["EventsDocAccessFields_miscInternalClick"],
	totalImpression?:ResolverInputTypes["EventsDocAccessFields_totalImpression"],
	internalClick?:ResolverInputTypes["EventsDocAccessFields_internalClick"],
	outboundClick?:ResolverInputTypes["EventsDocAccessFields_outboundClick"],
	externalClick?:ResolverInputTypes["EventsDocAccessFields_externalClick"],
	_viewsCountByMonth?:ResolverInputTypes["EventsDocAccessFields__viewsCountByMonth"],
	products?:ResolverInputTypes["EventsDocAccessFields_products"],
	legacyGallery?:ResolverInputTypes["EventsDocAccessFields_legacyGallery"],
	earliestStartDate?:ResolverInputTypes["EventsDocAccessFields_earliestStartDate"],
	latestEndDate?:ResolverInputTypes["EventsDocAccessFields_latestEndDate"],
	latestStartDateTime?:ResolverInputTypes["EventsDocAccessFields_latestStartDateTime"],
	hasTicketing?:ResolverInputTypes["EventsDocAccessFields_hasTicketing"],
	hasProducts?:ResolverInputTypes["EventsDocAccessFields_hasProducts"],
	updatedAt?:ResolverInputTypes["EventsDocAccessFields_updatedAt"],
	createdAt?:ResolverInputTypes["EventsDocAccessFields_createdAt"],
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_region"]: AliasType<{
	create?:ResolverInputTypes["EventsDocAccessFields_region_Create"],
	read?:ResolverInputTypes["EventsDocAccessFields_region_Read"],
	update?:ResolverInputTypes["EventsDocAccessFields_region_Update"],
	delete?:ResolverInputTypes["EventsDocAccessFields_region_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_region_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_region_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_region_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_region_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_creator"]: AliasType<{
	create?:ResolverInputTypes["EventsDocAccessFields_creator_Create"],
	read?:ResolverInputTypes["EventsDocAccessFields_creator_Read"],
	update?:ResolverInputTypes["EventsDocAccessFields_creator_Update"],
	delete?:ResolverInputTypes["EventsDocAccessFields_creator_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_creator_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_creator_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_creator_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_creator_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_permalink"]: AliasType<{
	create?:ResolverInputTypes["EventsDocAccessFields_permalink_Create"],
	read?:ResolverInputTypes["EventsDocAccessFields_permalink_Read"],
	update?:ResolverInputTypes["EventsDocAccessFields_permalink_Update"],
	delete?:ResolverInputTypes["EventsDocAccessFields_permalink_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_permalink_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_permalink_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_permalink_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_permalink_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_status"]: AliasType<{
	create?:ResolverInputTypes["EventsDocAccessFields_status_Create"],
	read?:ResolverInputTypes["EventsDocAccessFields_status_Read"],
	update?:ResolverInputTypes["EventsDocAccessFields_status_Update"],
	delete?:ResolverInputTypes["EventsDocAccessFields_status_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_status_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_status_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_status_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_status_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_level"]: AliasType<{
	create?:ResolverInputTypes["EventsDocAccessFields_level_Create"],
	read?:ResolverInputTypes["EventsDocAccessFields_level_Read"],
	update?:ResolverInputTypes["EventsDocAccessFields_level_Update"],
	delete?:ResolverInputTypes["EventsDocAccessFields_level_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_level_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_level_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_level_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_level_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_parent"]: AliasType<{
	create?:ResolverInputTypes["EventsDocAccessFields_parent_Create"],
	read?:ResolverInputTypes["EventsDocAccessFields_parent_Read"],
	update?:ResolverInputTypes["EventsDocAccessFields_parent_Update"],
	delete?:ResolverInputTypes["EventsDocAccessFields_parent_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_parent_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_parent_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_parent_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_parent_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_name"]: AliasType<{
	create?:ResolverInputTypes["EventsDocAccessFields_name_Create"],
	read?:ResolverInputTypes["EventsDocAccessFields_name_Read"],
	update?:ResolverInputTypes["EventsDocAccessFields_name_Update"],
	delete?:ResolverInputTypes["EventsDocAccessFields_name_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_name_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_name_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_name_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_name_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_chatbotEventTitle"]: AliasType<{
	create?:ResolverInputTypes["EventsDocAccessFields_chatbotEventTitle_Create"],
	read?:ResolverInputTypes["EventsDocAccessFields_chatbotEventTitle_Read"],
	update?:ResolverInputTypes["EventsDocAccessFields_chatbotEventTitle_Update"],
	delete?:ResolverInputTypes["EventsDocAccessFields_chatbotEventTitle_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_chatbotEventTitle_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_chatbotEventTitle_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_chatbotEventTitle_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_chatbotEventTitle_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_chatbotSequence"]: AliasType<{
	create?:ResolverInputTypes["EventsDocAccessFields_chatbotSequence_Create"],
	read?:ResolverInputTypes["EventsDocAccessFields_chatbotSequence_Read"],
	update?:ResolverInputTypes["EventsDocAccessFields_chatbotSequence_Update"],
	delete?:ResolverInputTypes["EventsDocAccessFields_chatbotSequence_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_chatbotSequence_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_chatbotSequence_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_chatbotSequence_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_chatbotSequence_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_thumbnail"]: AliasType<{
	create?:ResolverInputTypes["EventsDocAccessFields_thumbnail_Create"],
	read?:ResolverInputTypes["EventsDocAccessFields_thumbnail_Read"],
	update?:ResolverInputTypes["EventsDocAccessFields_thumbnail_Update"],
	delete?:ResolverInputTypes["EventsDocAccessFields_thumbnail_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_thumbnail_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_thumbnail_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_thumbnail_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_thumbnail_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_banner"]: AliasType<{
	create?:ResolverInputTypes["EventsDocAccessFields_banner_Create"],
	read?:ResolverInputTypes["EventsDocAccessFields_banner_Read"],
	update?:ResolverInputTypes["EventsDocAccessFields_banner_Update"],
	delete?:ResolverInputTypes["EventsDocAccessFields_banner_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_banner_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_banner_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_banner_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_banner_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_mobileBanner"]: AliasType<{
	create?:ResolverInputTypes["EventsDocAccessFields_mobileBanner_Create"],
	read?:ResolverInputTypes["EventsDocAccessFields_mobileBanner_Read"],
	update?:ResolverInputTypes["EventsDocAccessFields_mobileBanner_Update"],
	delete?:ResolverInputTypes["EventsDocAccessFields_mobileBanner_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_mobileBanner_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_mobileBanner_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_mobileBanner_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_mobileBanner_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_sections"]: AliasType<{
	create?:ResolverInputTypes["EventsDocAccessFields_sections_Create"],
	read?:ResolverInputTypes["EventsDocAccessFields_sections_Read"],
	update?:ResolverInputTypes["EventsDocAccessFields_sections_Update"],
	delete?:ResolverInputTypes["EventsDocAccessFields_sections_Delete"],
	fields?:ResolverInputTypes["EventsDocAccessFields_sections_Fields"],
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_sections_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_sections_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_sections_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_sections_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_sections_Fields"]: AliasType<{
	startDatetime?:ResolverInputTypes["EventsDocAccessFields_sections_startDatetime"],
	endDatetime?:ResolverInputTypes["EventsDocAccessFields_sections_endDatetime"],
	fullDay?:ResolverInputTypes["EventsDocAccessFields_sections_fullDay"],
	repeat?:ResolverInputTypes["EventsDocAccessFields_sections_repeat"],
	toThisDay?:ResolverInputTypes["EventsDocAccessFields_sections_toThisDay"],
	recurrance?:ResolverInputTypes["EventsDocAccessFields_sections_recurrance"],
	sameLocation?:ResolverInputTypes["EventsDocAccessFields_sections_sameLocation"],
	location?:ResolverInputTypes["EventsDocAccessFields_sections_location"],
	district?:ResolverInputTypes["EventsDocAccessFields_sections_district"],
	coordinate?:ResolverInputTypes["EventsDocAccessFields_sections_coordinate"],
	linkAddress?:ResolverInputTypes["EventsDocAccessFields_sections_linkAddress"],
	address?:ResolverInputTypes["EventsDocAccessFields_sections_address"],
	id?:ResolverInputTypes["EventsDocAccessFields_sections_id"],
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_sections_startDatetime"]: AliasType<{
	create?:ResolverInputTypes["EventsDocAccessFields_sections_startDatetime_Create"],
	read?:ResolverInputTypes["EventsDocAccessFields_sections_startDatetime_Read"],
	update?:ResolverInputTypes["EventsDocAccessFields_sections_startDatetime_Update"],
	delete?:ResolverInputTypes["EventsDocAccessFields_sections_startDatetime_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_sections_startDatetime_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_sections_startDatetime_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_sections_startDatetime_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_sections_startDatetime_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_sections_endDatetime"]: AliasType<{
	create?:ResolverInputTypes["EventsDocAccessFields_sections_endDatetime_Create"],
	read?:ResolverInputTypes["EventsDocAccessFields_sections_endDatetime_Read"],
	update?:ResolverInputTypes["EventsDocAccessFields_sections_endDatetime_Update"],
	delete?:ResolverInputTypes["EventsDocAccessFields_sections_endDatetime_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_sections_endDatetime_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_sections_endDatetime_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_sections_endDatetime_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_sections_endDatetime_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_sections_fullDay"]: AliasType<{
	create?:ResolverInputTypes["EventsDocAccessFields_sections_fullDay_Create"],
	read?:ResolverInputTypes["EventsDocAccessFields_sections_fullDay_Read"],
	update?:ResolverInputTypes["EventsDocAccessFields_sections_fullDay_Update"],
	delete?:ResolverInputTypes["EventsDocAccessFields_sections_fullDay_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_sections_fullDay_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_sections_fullDay_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_sections_fullDay_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_sections_fullDay_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_sections_repeat"]: AliasType<{
	create?:ResolverInputTypes["EventsDocAccessFields_sections_repeat_Create"],
	read?:ResolverInputTypes["EventsDocAccessFields_sections_repeat_Read"],
	update?:ResolverInputTypes["EventsDocAccessFields_sections_repeat_Update"],
	delete?:ResolverInputTypes["EventsDocAccessFields_sections_repeat_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_sections_repeat_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_sections_repeat_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_sections_repeat_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_sections_repeat_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_sections_toThisDay"]: AliasType<{
	create?:ResolverInputTypes["EventsDocAccessFields_sections_toThisDay_Create"],
	read?:ResolverInputTypes["EventsDocAccessFields_sections_toThisDay_Read"],
	update?:ResolverInputTypes["EventsDocAccessFields_sections_toThisDay_Update"],
	delete?:ResolverInputTypes["EventsDocAccessFields_sections_toThisDay_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_sections_toThisDay_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_sections_toThisDay_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_sections_toThisDay_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_sections_toThisDay_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_sections_recurrance"]: AliasType<{
	create?:ResolverInputTypes["EventsDocAccessFields_sections_recurrance_Create"],
	read?:ResolverInputTypes["EventsDocAccessFields_sections_recurrance_Read"],
	update?:ResolverInputTypes["EventsDocAccessFields_sections_recurrance_Update"],
	delete?:ResolverInputTypes["EventsDocAccessFields_sections_recurrance_Delete"],
	fields?:ResolverInputTypes["EventsDocAccessFields_sections_recurrance_Fields"],
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_sections_recurrance_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_sections_recurrance_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_sections_recurrance_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_sections_recurrance_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_sections_recurrance_Fields"]: AliasType<{
	type?:ResolverInputTypes["EventsDocAccessFields_sections_recurrance_type"],
	weekday?:ResolverInputTypes["EventsDocAccessFields_sections_recurrance_weekday"],
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_sections_recurrance_type"]: AliasType<{
	create?:ResolverInputTypes["EventsDocAccessFields_sections_recurrance_type_Create"],
	read?:ResolverInputTypes["EventsDocAccessFields_sections_recurrance_type_Read"],
	update?:ResolverInputTypes["EventsDocAccessFields_sections_recurrance_type_Update"],
	delete?:ResolverInputTypes["EventsDocAccessFields_sections_recurrance_type_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_sections_recurrance_type_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_sections_recurrance_type_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_sections_recurrance_type_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_sections_recurrance_type_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_sections_recurrance_weekday"]: AliasType<{
	create?:ResolverInputTypes["EventsDocAccessFields_sections_recurrance_weekday_Create"],
	read?:ResolverInputTypes["EventsDocAccessFields_sections_recurrance_weekday_Read"],
	update?:ResolverInputTypes["EventsDocAccessFields_sections_recurrance_weekday_Update"],
	delete?:ResolverInputTypes["EventsDocAccessFields_sections_recurrance_weekday_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_sections_recurrance_weekday_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_sections_recurrance_weekday_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_sections_recurrance_weekday_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_sections_recurrance_weekday_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_sections_sameLocation"]: AliasType<{
	create?:ResolverInputTypes["EventsDocAccessFields_sections_sameLocation_Create"],
	read?:ResolverInputTypes["EventsDocAccessFields_sections_sameLocation_Read"],
	update?:ResolverInputTypes["EventsDocAccessFields_sections_sameLocation_Update"],
	delete?:ResolverInputTypes["EventsDocAccessFields_sections_sameLocation_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_sections_sameLocation_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_sections_sameLocation_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_sections_sameLocation_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_sections_sameLocation_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_sections_location"]: AliasType<{
	create?:ResolverInputTypes["EventsDocAccessFields_sections_location_Create"],
	read?:ResolverInputTypes["EventsDocAccessFields_sections_location_Read"],
	update?:ResolverInputTypes["EventsDocAccessFields_sections_location_Update"],
	delete?:ResolverInputTypes["EventsDocAccessFields_sections_location_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_sections_location_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_sections_location_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_sections_location_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_sections_location_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_sections_district"]: AliasType<{
	create?:ResolverInputTypes["EventsDocAccessFields_sections_district_Create"],
	read?:ResolverInputTypes["EventsDocAccessFields_sections_district_Read"],
	update?:ResolverInputTypes["EventsDocAccessFields_sections_district_Update"],
	delete?:ResolverInputTypes["EventsDocAccessFields_sections_district_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_sections_district_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_sections_district_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_sections_district_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_sections_district_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_sections_coordinate"]: AliasType<{
	create?:ResolverInputTypes["EventsDocAccessFields_sections_coordinate_Create"],
	read?:ResolverInputTypes["EventsDocAccessFields_sections_coordinate_Read"],
	update?:ResolverInputTypes["EventsDocAccessFields_sections_coordinate_Update"],
	delete?:ResolverInputTypes["EventsDocAccessFields_sections_coordinate_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_sections_coordinate_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_sections_coordinate_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_sections_coordinate_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_sections_coordinate_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_sections_linkAddress"]: AliasType<{
	create?:ResolverInputTypes["EventsDocAccessFields_sections_linkAddress_Create"],
	read?:ResolverInputTypes["EventsDocAccessFields_sections_linkAddress_Read"],
	update?:ResolverInputTypes["EventsDocAccessFields_sections_linkAddress_Update"],
	delete?:ResolverInputTypes["EventsDocAccessFields_sections_linkAddress_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_sections_linkAddress_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_sections_linkAddress_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_sections_linkAddress_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_sections_linkAddress_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_sections_address"]: AliasType<{
	create?:ResolverInputTypes["EventsDocAccessFields_sections_address_Create"],
	read?:ResolverInputTypes["EventsDocAccessFields_sections_address_Read"],
	update?:ResolverInputTypes["EventsDocAccessFields_sections_address_Update"],
	delete?:ResolverInputTypes["EventsDocAccessFields_sections_address_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_sections_address_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_sections_address_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_sections_address_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_sections_address_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_sections_id"]: AliasType<{
	create?:ResolverInputTypes["EventsDocAccessFields_sections_id_Create"],
	read?:ResolverInputTypes["EventsDocAccessFields_sections_id_Read"],
	update?:ResolverInputTypes["EventsDocAccessFields_sections_id_Update"],
	delete?:ResolverInputTypes["EventsDocAccessFields_sections_id_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_sections_id_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_sections_id_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_sections_id_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_sections_id_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_ticketing"]: AliasType<{
	create?:ResolverInputTypes["EventsDocAccessFields_ticketing_Create"],
	read?:ResolverInputTypes["EventsDocAccessFields_ticketing_Read"],
	update?:ResolverInputTypes["EventsDocAccessFields_ticketing_Update"],
	delete?:ResolverInputTypes["EventsDocAccessFields_ticketing_Delete"],
	fields?:ResolverInputTypes["EventsDocAccessFields_ticketing_Fields"],
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_ticketing_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_ticketing_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_ticketing_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_ticketing_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_ticketing_Fields"]: AliasType<{
	startDatetime?:ResolverInputTypes["EventsDocAccessFields_ticketing_startDatetime"],
	endDatetime?:ResolverInputTypes["EventsDocAccessFields_ticketing_endDatetime"],
	fullDay?:ResolverInputTypes["EventsDocAccessFields_ticketing_fullDay"],
	type?:ResolverInputTypes["EventsDocAccessFields_ticketing_type"],
	ticketingType?:ResolverInputTypes["EventsDocAccessFields_ticketing_ticketingType"],
	linkedTicketing?:ResolverInputTypes["EventsDocAccessFields_ticketing_linkedTicketing"],
	promotionType?:ResolverInputTypes["EventsDocAccessFields_ticketing_promotionType"],
	location?:ResolverInputTypes["EventsDocAccessFields_ticketing_location"],
	website?:ResolverInputTypes["EventsDocAccessFields_ticketing_website"],
	description?:ResolverInputTypes["EventsDocAccessFields_ticketing_description"],
	id?:ResolverInputTypes["EventsDocAccessFields_ticketing_id"],
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_ticketing_startDatetime"]: AliasType<{
	create?:ResolverInputTypes["EventsDocAccessFields_ticketing_startDatetime_Create"],
	read?:ResolverInputTypes["EventsDocAccessFields_ticketing_startDatetime_Read"],
	update?:ResolverInputTypes["EventsDocAccessFields_ticketing_startDatetime_Update"],
	delete?:ResolverInputTypes["EventsDocAccessFields_ticketing_startDatetime_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_ticketing_startDatetime_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_ticketing_startDatetime_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_ticketing_startDatetime_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_ticketing_startDatetime_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_ticketing_endDatetime"]: AliasType<{
	create?:ResolverInputTypes["EventsDocAccessFields_ticketing_endDatetime_Create"],
	read?:ResolverInputTypes["EventsDocAccessFields_ticketing_endDatetime_Read"],
	update?:ResolverInputTypes["EventsDocAccessFields_ticketing_endDatetime_Update"],
	delete?:ResolverInputTypes["EventsDocAccessFields_ticketing_endDatetime_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_ticketing_endDatetime_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_ticketing_endDatetime_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_ticketing_endDatetime_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_ticketing_endDatetime_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_ticketing_fullDay"]: AliasType<{
	create?:ResolverInputTypes["EventsDocAccessFields_ticketing_fullDay_Create"],
	read?:ResolverInputTypes["EventsDocAccessFields_ticketing_fullDay_Read"],
	update?:ResolverInputTypes["EventsDocAccessFields_ticketing_fullDay_Update"],
	delete?:ResolverInputTypes["EventsDocAccessFields_ticketing_fullDay_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_ticketing_fullDay_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_ticketing_fullDay_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_ticketing_fullDay_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_ticketing_fullDay_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_ticketing_type"]: AliasType<{
	create?:ResolverInputTypes["EventsDocAccessFields_ticketing_type_Create"],
	read?:ResolverInputTypes["EventsDocAccessFields_ticketing_type_Read"],
	update?:ResolverInputTypes["EventsDocAccessFields_ticketing_type_Update"],
	delete?:ResolverInputTypes["EventsDocAccessFields_ticketing_type_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_ticketing_type_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_ticketing_type_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_ticketing_type_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_ticketing_type_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_ticketing_ticketingType"]: AliasType<{
	create?:ResolverInputTypes["EventsDocAccessFields_ticketing_ticketingType_Create"],
	read?:ResolverInputTypes["EventsDocAccessFields_ticketing_ticketingType_Read"],
	update?:ResolverInputTypes["EventsDocAccessFields_ticketing_ticketingType_Update"],
	delete?:ResolverInputTypes["EventsDocAccessFields_ticketing_ticketingType_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_ticketing_ticketingType_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_ticketing_ticketingType_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_ticketing_ticketingType_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_ticketing_ticketingType_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_ticketing_linkedTicketing"]: AliasType<{
	create?:ResolverInputTypes["EventsDocAccessFields_ticketing_linkedTicketing_Create"],
	read?:ResolverInputTypes["EventsDocAccessFields_ticketing_linkedTicketing_Read"],
	update?:ResolverInputTypes["EventsDocAccessFields_ticketing_linkedTicketing_Update"],
	delete?:ResolverInputTypes["EventsDocAccessFields_ticketing_linkedTicketing_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_ticketing_linkedTicketing_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_ticketing_linkedTicketing_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_ticketing_linkedTicketing_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_ticketing_linkedTicketing_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_ticketing_promotionType"]: AliasType<{
	create?:ResolverInputTypes["EventsDocAccessFields_ticketing_promotionType_Create"],
	read?:ResolverInputTypes["EventsDocAccessFields_ticketing_promotionType_Read"],
	update?:ResolverInputTypes["EventsDocAccessFields_ticketing_promotionType_Update"],
	delete?:ResolverInputTypes["EventsDocAccessFields_ticketing_promotionType_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_ticketing_promotionType_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_ticketing_promotionType_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_ticketing_promotionType_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_ticketing_promotionType_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_ticketing_location"]: AliasType<{
	create?:ResolverInputTypes["EventsDocAccessFields_ticketing_location_Create"],
	read?:ResolverInputTypes["EventsDocAccessFields_ticketing_location_Read"],
	update?:ResolverInputTypes["EventsDocAccessFields_ticketing_location_Update"],
	delete?:ResolverInputTypes["EventsDocAccessFields_ticketing_location_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_ticketing_location_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_ticketing_location_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_ticketing_location_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_ticketing_location_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_ticketing_website"]: AliasType<{
	create?:ResolverInputTypes["EventsDocAccessFields_ticketing_website_Create"],
	read?:ResolverInputTypes["EventsDocAccessFields_ticketing_website_Read"],
	update?:ResolverInputTypes["EventsDocAccessFields_ticketing_website_Update"],
	delete?:ResolverInputTypes["EventsDocAccessFields_ticketing_website_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_ticketing_website_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_ticketing_website_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_ticketing_website_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_ticketing_website_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_ticketing_description"]: AliasType<{
	create?:ResolverInputTypes["EventsDocAccessFields_ticketing_description_Create"],
	read?:ResolverInputTypes["EventsDocAccessFields_ticketing_description_Read"],
	update?:ResolverInputTypes["EventsDocAccessFields_ticketing_description_Update"],
	delete?:ResolverInputTypes["EventsDocAccessFields_ticketing_description_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_ticketing_description_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_ticketing_description_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_ticketing_description_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_ticketing_description_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_ticketing_id"]: AliasType<{
	create?:ResolverInputTypes["EventsDocAccessFields_ticketing_id_Create"],
	read?:ResolverInputTypes["EventsDocAccessFields_ticketing_id_Read"],
	update?:ResolverInputTypes["EventsDocAccessFields_ticketing_id_Update"],
	delete?:ResolverInputTypes["EventsDocAccessFields_ticketing_id_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_ticketing_id_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_ticketing_id_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_ticketing_id_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_ticketing_id_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_content"]: AliasType<{
	create?:ResolverInputTypes["EventsDocAccessFields_content_Create"],
	read?:ResolverInputTypes["EventsDocAccessFields_content_Read"],
	update?:ResolverInputTypes["EventsDocAccessFields_content_Update"],
	delete?:ResolverInputTypes["EventsDocAccessFields_content_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_content_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_content_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_content_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_content_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_legacyContent"]: AliasType<{
	create?:ResolverInputTypes["EventsDocAccessFields_legacyContent_Create"],
	read?:ResolverInputTypes["EventsDocAccessFields_legacyContent_Read"],
	update?:ResolverInputTypes["EventsDocAccessFields_legacyContent_Update"],
	delete?:ResolverInputTypes["EventsDocAccessFields_legacyContent_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_legacyContent_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_legacyContent_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_legacyContent_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_legacyContent_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_showLegacyContent"]: AliasType<{
	create?:ResolverInputTypes["EventsDocAccessFields_showLegacyContent_Create"],
	read?:ResolverInputTypes["EventsDocAccessFields_showLegacyContent_Read"],
	update?:ResolverInputTypes["EventsDocAccessFields_showLegacyContent_Update"],
	delete?:ResolverInputTypes["EventsDocAccessFields_showLegacyContent_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_showLegacyContent_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_showLegacyContent_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_showLegacyContent_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_showLegacyContent_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_criteria"]: AliasType<{
	create?:ResolverInputTypes["EventsDocAccessFields_criteria_Create"],
	read?:ResolverInputTypes["EventsDocAccessFields_criteria_Read"],
	update?:ResolverInputTypes["EventsDocAccessFields_criteria_Update"],
	delete?:ResolverInputTypes["EventsDocAccessFields_criteria_Delete"],
	fields?:ResolverInputTypes["EventsDocAccessFields_criteria_Fields"],
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_criteria_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_criteria_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_criteria_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_criteria_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_criteria_Fields"]: AliasType<{
	categories?:ResolverInputTypes["EventsDocAccessFields_criteria_categories"],
	audiences?:ResolverInputTypes["EventsDocAccessFields_criteria_audiences"],
	tags?:ResolverInputTypes["EventsDocAccessFields_criteria_tags"],
	specialTags?:ResolverInputTypes["EventsDocAccessFields_criteria_specialTags"],
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_criteria_categories"]: AliasType<{
	create?:ResolverInputTypes["EventsDocAccessFields_criteria_categories_Create"],
	read?:ResolverInputTypes["EventsDocAccessFields_criteria_categories_Read"],
	update?:ResolverInputTypes["EventsDocAccessFields_criteria_categories_Update"],
	delete?:ResolverInputTypes["EventsDocAccessFields_criteria_categories_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_criteria_categories_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_criteria_categories_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_criteria_categories_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_criteria_categories_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_criteria_audiences"]: AliasType<{
	create?:ResolverInputTypes["EventsDocAccessFields_criteria_audiences_Create"],
	read?:ResolverInputTypes["EventsDocAccessFields_criteria_audiences_Read"],
	update?:ResolverInputTypes["EventsDocAccessFields_criteria_audiences_Update"],
	delete?:ResolverInputTypes["EventsDocAccessFields_criteria_audiences_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_criteria_audiences_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_criteria_audiences_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_criteria_audiences_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_criteria_audiences_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_criteria_tags"]: AliasType<{
	create?:ResolverInputTypes["EventsDocAccessFields_criteria_tags_Create"],
	read?:ResolverInputTypes["EventsDocAccessFields_criteria_tags_Read"],
	update?:ResolverInputTypes["EventsDocAccessFields_criteria_tags_Update"],
	delete?:ResolverInputTypes["EventsDocAccessFields_criteria_tags_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_criteria_tags_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_criteria_tags_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_criteria_tags_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_criteria_tags_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_criteria_specialTags"]: AliasType<{
	create?:ResolverInputTypes["EventsDocAccessFields_criteria_specialTags_Create"],
	read?:ResolverInputTypes["EventsDocAccessFields_criteria_specialTags_Read"],
	update?:ResolverInputTypes["EventsDocAccessFields_criteria_specialTags_Update"],
	delete?:ResolverInputTypes["EventsDocAccessFields_criteria_specialTags_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_criteria_specialTags_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_criteria_specialTags_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_criteria_specialTags_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_criteria_specialTags_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_walkIn"]: AliasType<{
	create?:ResolverInputTypes["EventsDocAccessFields_walkIn_Create"],
	read?:ResolverInputTypes["EventsDocAccessFields_walkIn_Read"],
	update?:ResolverInputTypes["EventsDocAccessFields_walkIn_Update"],
	delete?:ResolverInputTypes["EventsDocAccessFields_walkIn_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_walkIn_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_walkIn_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_walkIn_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_walkIn_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_contact"]: AliasType<{
	create?:ResolverInputTypes["EventsDocAccessFields_contact_Create"],
	read?:ResolverInputTypes["EventsDocAccessFields_contact_Read"],
	update?:ResolverInputTypes["EventsDocAccessFields_contact_Update"],
	delete?:ResolverInputTypes["EventsDocAccessFields_contact_Delete"],
	fields?:ResolverInputTypes["EventsDocAccessFields_contact_Fields"],
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_contact_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_contact_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_contact_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_contact_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_contact_Fields"]: AliasType<{
	code?:ResolverInputTypes["EventsDocAccessFields_contact_code"],
	phone?:ResolverInputTypes["EventsDocAccessFields_contact_phone"],
	page?:ResolverInputTypes["EventsDocAccessFields_contact_page"],
	email?:ResolverInputTypes["EventsDocAccessFields_contact_email"],
	privacy?:ResolverInputTypes["EventsDocAccessFields_contact_privacy"],
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_contact_code"]: AliasType<{
	create?:ResolverInputTypes["EventsDocAccessFields_contact_code_Create"],
	read?:ResolverInputTypes["EventsDocAccessFields_contact_code_Read"],
	update?:ResolverInputTypes["EventsDocAccessFields_contact_code_Update"],
	delete?:ResolverInputTypes["EventsDocAccessFields_contact_code_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_contact_code_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_contact_code_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_contact_code_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_contact_code_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_contact_phone"]: AliasType<{
	create?:ResolverInputTypes["EventsDocAccessFields_contact_phone_Create"],
	read?:ResolverInputTypes["EventsDocAccessFields_contact_phone_Read"],
	update?:ResolverInputTypes["EventsDocAccessFields_contact_phone_Update"],
	delete?:ResolverInputTypes["EventsDocAccessFields_contact_phone_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_contact_phone_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_contact_phone_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_contact_phone_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_contact_phone_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_contact_page"]: AliasType<{
	create?:ResolverInputTypes["EventsDocAccessFields_contact_page_Create"],
	read?:ResolverInputTypes["EventsDocAccessFields_contact_page_Read"],
	update?:ResolverInputTypes["EventsDocAccessFields_contact_page_Update"],
	delete?:ResolverInputTypes["EventsDocAccessFields_contact_page_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_contact_page_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_contact_page_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_contact_page_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_contact_page_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_contact_email"]: AliasType<{
	create?:ResolverInputTypes["EventsDocAccessFields_contact_email_Create"],
	read?:ResolverInputTypes["EventsDocAccessFields_contact_email_Read"],
	update?:ResolverInputTypes["EventsDocAccessFields_contact_email_Update"],
	delete?:ResolverInputTypes["EventsDocAccessFields_contact_email_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_contact_email_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_contact_email_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_contact_email_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_contact_email_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_contact_privacy"]: AliasType<{
	create?:ResolverInputTypes["EventsDocAccessFields_contact_privacy_Create"],
	read?:ResolverInputTypes["EventsDocAccessFields_contact_privacy_Read"],
	update?:ResolverInputTypes["EventsDocAccessFields_contact_privacy_Update"],
	delete?:ResolverInputTypes["EventsDocAccessFields_contact_privacy_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_contact_privacy_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_contact_privacy_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_contact_privacy_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_contact_privacy_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_views"]: AliasType<{
	create?:ResolverInputTypes["EventsDocAccessFields_views_Create"],
	read?:ResolverInputTypes["EventsDocAccessFields_views_Read"],
	update?:ResolverInputTypes["EventsDocAccessFields_views_Update"],
	delete?:ResolverInputTypes["EventsDocAccessFields_views_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_views_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_views_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_views_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_views_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_free"]: AliasType<{
	create?:ResolverInputTypes["EventsDocAccessFields_free_Create"],
	read?:ResolverInputTypes["EventsDocAccessFields_free_Read"],
	update?:ResolverInputTypes["EventsDocAccessFields_free_Update"],
	delete?:ResolverInputTypes["EventsDocAccessFields_free_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_free_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_free_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_free_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_free_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_fees"]: AliasType<{
	create?:ResolverInputTypes["EventsDocAccessFields_fees_Create"],
	read?:ResolverInputTypes["EventsDocAccessFields_fees_Read"],
	update?:ResolverInputTypes["EventsDocAccessFields_fees_Update"],
	delete?:ResolverInputTypes["EventsDocAccessFields_fees_Delete"],
	fields?:ResolverInputTypes["EventsDocAccessFields_fees_Fields"],
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_fees_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_fees_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_fees_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_fees_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_fees_Fields"]: AliasType<{
	fee?:ResolverInputTypes["EventsDocAccessFields_fees_fee"],
	remark?:ResolverInputTypes["EventsDocAccessFields_fees_remark"],
	id?:ResolverInputTypes["EventsDocAccessFields_fees_id"],
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_fees_fee"]: AliasType<{
	create?:ResolverInputTypes["EventsDocAccessFields_fees_fee_Create"],
	read?:ResolverInputTypes["EventsDocAccessFields_fees_fee_Read"],
	update?:ResolverInputTypes["EventsDocAccessFields_fees_fee_Update"],
	delete?:ResolverInputTypes["EventsDocAccessFields_fees_fee_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_fees_fee_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_fees_fee_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_fees_fee_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_fees_fee_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_fees_remark"]: AliasType<{
	create?:ResolverInputTypes["EventsDocAccessFields_fees_remark_Create"],
	read?:ResolverInputTypes["EventsDocAccessFields_fees_remark_Read"],
	update?:ResolverInputTypes["EventsDocAccessFields_fees_remark_Update"],
	delete?:ResolverInputTypes["EventsDocAccessFields_fees_remark_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_fees_remark_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_fees_remark_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_fees_remark_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_fees_remark_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_fees_id"]: AliasType<{
	create?:ResolverInputTypes["EventsDocAccessFields_fees_id_Create"],
	read?:ResolverInputTypes["EventsDocAccessFields_fees_id_Read"],
	update?:ResolverInputTypes["EventsDocAccessFields_fees_id_Update"],
	delete?:ResolverInputTypes["EventsDocAccessFields_fees_id_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_fees_id_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_fees_id_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_fees_id_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_fees_id_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_homePageInternalClick"]: AliasType<{
	create?:ResolverInputTypes["EventsDocAccessFields_homePageInternalClick_Create"],
	read?:ResolverInputTypes["EventsDocAccessFields_homePageInternalClick_Read"],
	update?:ResolverInputTypes["EventsDocAccessFields_homePageInternalClick_Update"],
	delete?:ResolverInputTypes["EventsDocAccessFields_homePageInternalClick_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_homePageInternalClick_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_homePageInternalClick_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_homePageInternalClick_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_homePageInternalClick_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_homePageImpression"]: AliasType<{
	create?:ResolverInputTypes["EventsDocAccessFields_homePageImpression_Create"],
	read?:ResolverInputTypes["EventsDocAccessFields_homePageImpression_Read"],
	update?:ResolverInputTypes["EventsDocAccessFields_homePageImpression_Update"],
	delete?:ResolverInputTypes["EventsDocAccessFields_homePageImpression_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_homePageImpression_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_homePageImpression_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_homePageImpression_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_homePageImpression_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_eodClickRate"]: AliasType<{
	create?:ResolverInputTypes["EventsDocAccessFields_eodClickRate_Create"],
	read?:ResolverInputTypes["EventsDocAccessFields_eodClickRate_Read"],
	update?:ResolverInputTypes["EventsDocAccessFields_eodClickRate_Update"],
	delete?:ResolverInputTypes["EventsDocAccessFields_eodClickRate_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_eodClickRate_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_eodClickRate_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_eodClickRate_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_eodClickRate_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_eodImpressionRate"]: AliasType<{
	create?:ResolverInputTypes["EventsDocAccessFields_eodImpressionRate_Create"],
	read?:ResolverInputTypes["EventsDocAccessFields_eodImpressionRate_Read"],
	update?:ResolverInputTypes["EventsDocAccessFields_eodImpressionRate_Update"],
	delete?:ResolverInputTypes["EventsDocAccessFields_eodImpressionRate_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_eodImpressionRate_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_eodImpressionRate_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_eodImpressionRate_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_eodImpressionRate_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_top10ClickRateHomepage"]: AliasType<{
	create?:ResolverInputTypes["EventsDocAccessFields_top10ClickRateHomepage_Create"],
	read?:ResolverInputTypes["EventsDocAccessFields_top10ClickRateHomepage_Read"],
	update?:ResolverInputTypes["EventsDocAccessFields_top10ClickRateHomepage_Update"],
	delete?:ResolverInputTypes["EventsDocAccessFields_top10ClickRateHomepage_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_top10ClickRateHomepage_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_top10ClickRateHomepage_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_top10ClickRateHomepage_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_top10ClickRateHomepage_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_top10ImpressionkRateHomepage"]: AliasType<{
	create?:ResolverInputTypes["EventsDocAccessFields_top10ImpressionkRateHomepage_Create"],
	read?:ResolverInputTypes["EventsDocAccessFields_top10ImpressionkRateHomepage_Read"],
	update?:ResolverInputTypes["EventsDocAccessFields_top10ImpressionkRateHomepage_Update"],
	delete?:ResolverInputTypes["EventsDocAccessFields_top10ImpressionkRateHomepage_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_top10ImpressionkRateHomepage_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_top10ImpressionkRateHomepage_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_top10ImpressionkRateHomepage_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_top10ImpressionkRateHomepage_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_otherEventRankingClickRateHomepage"]: AliasType<{
	create?:ResolverInputTypes["EventsDocAccessFields_otherEventRankingClickRateHomepage_Create"],
	read?:ResolverInputTypes["EventsDocAccessFields_otherEventRankingClickRateHomepage_Read"],
	update?:ResolverInputTypes["EventsDocAccessFields_otherEventRankingClickRateHomepage_Update"],
	delete?:ResolverInputTypes["EventsDocAccessFields_otherEventRankingClickRateHomepage_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_otherEventRankingClickRateHomepage_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_otherEventRankingClickRateHomepage_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_otherEventRankingClickRateHomepage_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_otherEventRankingClickRateHomepage_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_otherEventRankingImpressionHomePage"]: AliasType<{
	create?:ResolverInputTypes["EventsDocAccessFields_otherEventRankingImpressionHomePage_Create"],
	read?:ResolverInputTypes["EventsDocAccessFields_otherEventRankingImpressionHomePage_Read"],
	update?:ResolverInputTypes["EventsDocAccessFields_otherEventRankingImpressionHomePage_Update"],
	delete?:ResolverInputTypes["EventsDocAccessFields_otherEventRankingImpressionHomePage_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_otherEventRankingImpressionHomePage_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_otherEventRankingImpressionHomePage_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_otherEventRankingImpressionHomePage_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_otherEventRankingImpressionHomePage_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_userPreferenceClickRate"]: AliasType<{
	create?:ResolverInputTypes["EventsDocAccessFields_userPreferenceClickRate_Create"],
	read?:ResolverInputTypes["EventsDocAccessFields_userPreferenceClickRate_Read"],
	update?:ResolverInputTypes["EventsDocAccessFields_userPreferenceClickRate_Update"],
	delete?:ResolverInputTypes["EventsDocAccessFields_userPreferenceClickRate_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_userPreferenceClickRate_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_userPreferenceClickRate_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_userPreferenceClickRate_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_userPreferenceClickRate_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_userPreferenceImpression"]: AliasType<{
	create?:ResolverInputTypes["EventsDocAccessFields_userPreferenceImpression_Create"],
	read?:ResolverInputTypes["EventsDocAccessFields_userPreferenceImpression_Read"],
	update?:ResolverInputTypes["EventsDocAccessFields_userPreferenceImpression_Update"],
	delete?:ResolverInputTypes["EventsDocAccessFields_userPreferenceImpression_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_userPreferenceImpression_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_userPreferenceImpression_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_userPreferenceImpression_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_userPreferenceImpression_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_eventSearchInternalClick"]: AliasType<{
	create?:ResolverInputTypes["EventsDocAccessFields_eventSearchInternalClick_Create"],
	read?:ResolverInputTypes["EventsDocAccessFields_eventSearchInternalClick_Read"],
	update?:ResolverInputTypes["EventsDocAccessFields_eventSearchInternalClick_Update"],
	delete?:ResolverInputTypes["EventsDocAccessFields_eventSearchInternalClick_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_eventSearchInternalClick_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_eventSearchInternalClick_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_eventSearchInternalClick_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_eventSearchInternalClick_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_eventListImpression"]: AliasType<{
	create?:ResolverInputTypes["EventsDocAccessFields_eventListImpression_Create"],
	read?:ResolverInputTypes["EventsDocAccessFields_eventListImpression_Read"],
	update?:ResolverInputTypes["EventsDocAccessFields_eventListImpression_Update"],
	delete?:ResolverInputTypes["EventsDocAccessFields_eventListImpression_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_eventListImpression_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_eventListImpression_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_eventListImpression_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_eventListImpression_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_latestEventInternalClick"]: AliasType<{
	create?:ResolverInputTypes["EventsDocAccessFields_latestEventInternalClick_Create"],
	read?:ResolverInputTypes["EventsDocAccessFields_latestEventInternalClick_Read"],
	update?:ResolverInputTypes["EventsDocAccessFields_latestEventInternalClick_Update"],
	delete?:ResolverInputTypes["EventsDocAccessFields_latestEventInternalClick_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_latestEventInternalClick_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_latestEventInternalClick_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_latestEventInternalClick_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_latestEventInternalClick_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_latestEventImpression"]: AliasType<{
	create?:ResolverInputTypes["EventsDocAccessFields_latestEventImpression_Create"],
	read?:ResolverInputTypes["EventsDocAccessFields_latestEventImpression_Read"],
	update?:ResolverInputTypes["EventsDocAccessFields_latestEventImpression_Update"],
	delete?:ResolverInputTypes["EventsDocAccessFields_latestEventImpression_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_latestEventImpression_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_latestEventImpression_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_latestEventImpression_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_latestEventImpression_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_topTenEventRankingInternalClick"]: AliasType<{
	create?:ResolverInputTypes["EventsDocAccessFields_topTenEventRankingInternalClick_Create"],
	read?:ResolverInputTypes["EventsDocAccessFields_topTenEventRankingInternalClick_Read"],
	update?:ResolverInputTypes["EventsDocAccessFields_topTenEventRankingInternalClick_Update"],
	delete?:ResolverInputTypes["EventsDocAccessFields_topTenEventRankingInternalClick_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_topTenEventRankingInternalClick_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_topTenEventRankingInternalClick_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_topTenEventRankingInternalClick_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_topTenEventRankingInternalClick_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_topTenImpression"]: AliasType<{
	create?:ResolverInputTypes["EventsDocAccessFields_topTenImpression_Create"],
	read?:ResolverInputTypes["EventsDocAccessFields_topTenImpression_Read"],
	update?:ResolverInputTypes["EventsDocAccessFields_topTenImpression_Update"],
	delete?:ResolverInputTypes["EventsDocAccessFields_topTenImpression_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_topTenImpression_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_topTenImpression_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_topTenImpression_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_topTenImpression_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_editorChoiceInternalClick"]: AliasType<{
	create?:ResolverInputTypes["EventsDocAccessFields_editorChoiceInternalClick_Create"],
	read?:ResolverInputTypes["EventsDocAccessFields_editorChoiceInternalClick_Read"],
	update?:ResolverInputTypes["EventsDocAccessFields_editorChoiceInternalClick_Update"],
	delete?:ResolverInputTypes["EventsDocAccessFields_editorChoiceInternalClick_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_editorChoiceInternalClick_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_editorChoiceInternalClick_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_editorChoiceInternalClick_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_editorChoiceInternalClick_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_editorChoiceImpression"]: AliasType<{
	create?:ResolverInputTypes["EventsDocAccessFields_editorChoiceImpression_Create"],
	read?:ResolverInputTypes["EventsDocAccessFields_editorChoiceImpression_Read"],
	update?:ResolverInputTypes["EventsDocAccessFields_editorChoiceImpression_Update"],
	delete?:ResolverInputTypes["EventsDocAccessFields_editorChoiceImpression_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_editorChoiceImpression_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_editorChoiceImpression_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_editorChoiceImpression_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_editorChoiceImpression_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_otherEventRankingInternalClick"]: AliasType<{
	create?:ResolverInputTypes["EventsDocAccessFields_otherEventRankingInternalClick_Create"],
	read?:ResolverInputTypes["EventsDocAccessFields_otherEventRankingInternalClick_Read"],
	update?:ResolverInputTypes["EventsDocAccessFields_otherEventRankingInternalClick_Update"],
	delete?:ResolverInputTypes["EventsDocAccessFields_otherEventRankingInternalClick_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_otherEventRankingInternalClick_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_otherEventRankingInternalClick_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_otherEventRankingInternalClick_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_otherEventRankingInternalClick_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_otherEventRankingImpression"]: AliasType<{
	create?:ResolverInputTypes["EventsDocAccessFields_otherEventRankingImpression_Create"],
	read?:ResolverInputTypes["EventsDocAccessFields_otherEventRankingImpression_Read"],
	update?:ResolverInputTypes["EventsDocAccessFields_otherEventRankingImpression_Update"],
	delete?:ResolverInputTypes["EventsDocAccessFields_otherEventRankingImpression_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_otherEventRankingImpression_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_otherEventRankingImpression_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_otherEventRankingImpression_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_otherEventRankingImpression_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_bannerInternalClick"]: AliasType<{
	create?:ResolverInputTypes["EventsDocAccessFields_bannerInternalClick_Create"],
	read?:ResolverInputTypes["EventsDocAccessFields_bannerInternalClick_Read"],
	update?:ResolverInputTypes["EventsDocAccessFields_bannerInternalClick_Update"],
	delete?:ResolverInputTypes["EventsDocAccessFields_bannerInternalClick_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_bannerInternalClick_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_bannerInternalClick_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_bannerInternalClick_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_bannerInternalClick_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_miscImpression"]: AliasType<{
	create?:ResolverInputTypes["EventsDocAccessFields_miscImpression_Create"],
	read?:ResolverInputTypes["EventsDocAccessFields_miscImpression_Read"],
	update?:ResolverInputTypes["EventsDocAccessFields_miscImpression_Update"],
	delete?:ResolverInputTypes["EventsDocAccessFields_miscImpression_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_miscImpression_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_miscImpression_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_miscImpression_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_miscImpression_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_miscInternalClick"]: AliasType<{
	create?:ResolverInputTypes["EventsDocAccessFields_miscInternalClick_Create"],
	read?:ResolverInputTypes["EventsDocAccessFields_miscInternalClick_Read"],
	update?:ResolverInputTypes["EventsDocAccessFields_miscInternalClick_Update"],
	delete?:ResolverInputTypes["EventsDocAccessFields_miscInternalClick_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_miscInternalClick_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_miscInternalClick_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_miscInternalClick_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_miscInternalClick_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_totalImpression"]: AliasType<{
	create?:ResolverInputTypes["EventsDocAccessFields_totalImpression_Create"],
	read?:ResolverInputTypes["EventsDocAccessFields_totalImpression_Read"],
	update?:ResolverInputTypes["EventsDocAccessFields_totalImpression_Update"],
	delete?:ResolverInputTypes["EventsDocAccessFields_totalImpression_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_totalImpression_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_totalImpression_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_totalImpression_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_totalImpression_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_internalClick"]: AliasType<{
	create?:ResolverInputTypes["EventsDocAccessFields_internalClick_Create"],
	read?:ResolverInputTypes["EventsDocAccessFields_internalClick_Read"],
	update?:ResolverInputTypes["EventsDocAccessFields_internalClick_Update"],
	delete?:ResolverInputTypes["EventsDocAccessFields_internalClick_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_internalClick_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_internalClick_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_internalClick_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_internalClick_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_outboundClick"]: AliasType<{
	create?:ResolverInputTypes["EventsDocAccessFields_outboundClick_Create"],
	read?:ResolverInputTypes["EventsDocAccessFields_outboundClick_Read"],
	update?:ResolverInputTypes["EventsDocAccessFields_outboundClick_Update"],
	delete?:ResolverInputTypes["EventsDocAccessFields_outboundClick_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_outboundClick_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_outboundClick_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_outboundClick_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_outboundClick_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_externalClick"]: AliasType<{
	create?:ResolverInputTypes["EventsDocAccessFields_externalClick_Create"],
	read?:ResolverInputTypes["EventsDocAccessFields_externalClick_Read"],
	update?:ResolverInputTypes["EventsDocAccessFields_externalClick_Update"],
	delete?:ResolverInputTypes["EventsDocAccessFields_externalClick_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_externalClick_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_externalClick_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_externalClick_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_externalClick_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields__viewsCountByMonth"]: AliasType<{
	create?:ResolverInputTypes["EventsDocAccessFields__viewsCountByMonth_Create"],
	read?:ResolverInputTypes["EventsDocAccessFields__viewsCountByMonth_Read"],
	update?:ResolverInputTypes["EventsDocAccessFields__viewsCountByMonth_Update"],
	delete?:ResolverInputTypes["EventsDocAccessFields__viewsCountByMonth_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields__viewsCountByMonth_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields__viewsCountByMonth_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields__viewsCountByMonth_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields__viewsCountByMonth_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_products"]: AliasType<{
	create?:ResolverInputTypes["EventsDocAccessFields_products_Create"],
	read?:ResolverInputTypes["EventsDocAccessFields_products_Read"],
	update?:ResolverInputTypes["EventsDocAccessFields_products_Update"],
	delete?:ResolverInputTypes["EventsDocAccessFields_products_Delete"],
	fields?:ResolverInputTypes["EventsDocAccessFields_products_Fields"],
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_products_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_products_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_products_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_products_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_products_Fields"]: AliasType<{
	startDateTime?:ResolverInputTypes["EventsDocAccessFields_products_startDateTime"],
	endDateTime?:ResolverInputTypes["EventsDocAccessFields_products_endDateTime"],
	fullDay?:ResolverInputTypes["EventsDocAccessFields_products_fullDay"],
	linkedProduct?:ResolverInputTypes["EventsDocAccessFields_products_linkedProduct"],
	id?:ResolverInputTypes["EventsDocAccessFields_products_id"],
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_products_startDateTime"]: AliasType<{
	create?:ResolverInputTypes["EventsDocAccessFields_products_startDateTime_Create"],
	read?:ResolverInputTypes["EventsDocAccessFields_products_startDateTime_Read"],
	update?:ResolverInputTypes["EventsDocAccessFields_products_startDateTime_Update"],
	delete?:ResolverInputTypes["EventsDocAccessFields_products_startDateTime_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_products_startDateTime_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_products_startDateTime_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_products_startDateTime_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_products_startDateTime_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_products_endDateTime"]: AliasType<{
	create?:ResolverInputTypes["EventsDocAccessFields_products_endDateTime_Create"],
	read?:ResolverInputTypes["EventsDocAccessFields_products_endDateTime_Read"],
	update?:ResolverInputTypes["EventsDocAccessFields_products_endDateTime_Update"],
	delete?:ResolverInputTypes["EventsDocAccessFields_products_endDateTime_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_products_endDateTime_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_products_endDateTime_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_products_endDateTime_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_products_endDateTime_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_products_fullDay"]: AliasType<{
	create?:ResolverInputTypes["EventsDocAccessFields_products_fullDay_Create"],
	read?:ResolverInputTypes["EventsDocAccessFields_products_fullDay_Read"],
	update?:ResolverInputTypes["EventsDocAccessFields_products_fullDay_Update"],
	delete?:ResolverInputTypes["EventsDocAccessFields_products_fullDay_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_products_fullDay_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_products_fullDay_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_products_fullDay_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_products_fullDay_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_products_linkedProduct"]: AliasType<{
	create?:ResolverInputTypes["EventsDocAccessFields_products_linkedProduct_Create"],
	read?:ResolverInputTypes["EventsDocAccessFields_products_linkedProduct_Read"],
	update?:ResolverInputTypes["EventsDocAccessFields_products_linkedProduct_Update"],
	delete?:ResolverInputTypes["EventsDocAccessFields_products_linkedProduct_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_products_linkedProduct_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_products_linkedProduct_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_products_linkedProduct_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_products_linkedProduct_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_products_id"]: AliasType<{
	create?:ResolverInputTypes["EventsDocAccessFields_products_id_Create"],
	read?:ResolverInputTypes["EventsDocAccessFields_products_id_Read"],
	update?:ResolverInputTypes["EventsDocAccessFields_products_id_Update"],
	delete?:ResolverInputTypes["EventsDocAccessFields_products_id_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_products_id_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_products_id_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_products_id_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_products_id_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_legacyGallery"]: AliasType<{
	create?:ResolverInputTypes["EventsDocAccessFields_legacyGallery_Create"],
	read?:ResolverInputTypes["EventsDocAccessFields_legacyGallery_Read"],
	update?:ResolverInputTypes["EventsDocAccessFields_legacyGallery_Update"],
	delete?:ResolverInputTypes["EventsDocAccessFields_legacyGallery_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_legacyGallery_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_legacyGallery_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_legacyGallery_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_legacyGallery_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_earliestStartDate"]: AliasType<{
	create?:ResolverInputTypes["EventsDocAccessFields_earliestStartDate_Create"],
	read?:ResolverInputTypes["EventsDocAccessFields_earliestStartDate_Read"],
	update?:ResolverInputTypes["EventsDocAccessFields_earliestStartDate_Update"],
	delete?:ResolverInputTypes["EventsDocAccessFields_earliestStartDate_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_earliestStartDate_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_earliestStartDate_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_earliestStartDate_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_earliestStartDate_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_latestEndDate"]: AliasType<{
	create?:ResolverInputTypes["EventsDocAccessFields_latestEndDate_Create"],
	read?:ResolverInputTypes["EventsDocAccessFields_latestEndDate_Read"],
	update?:ResolverInputTypes["EventsDocAccessFields_latestEndDate_Update"],
	delete?:ResolverInputTypes["EventsDocAccessFields_latestEndDate_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_latestEndDate_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_latestEndDate_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_latestEndDate_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_latestEndDate_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_latestStartDateTime"]: AliasType<{
	create?:ResolverInputTypes["EventsDocAccessFields_latestStartDateTime_Create"],
	read?:ResolverInputTypes["EventsDocAccessFields_latestStartDateTime_Read"],
	update?:ResolverInputTypes["EventsDocAccessFields_latestStartDateTime_Update"],
	delete?:ResolverInputTypes["EventsDocAccessFields_latestStartDateTime_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_latestStartDateTime_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_latestStartDateTime_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_latestStartDateTime_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_latestStartDateTime_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_hasTicketing"]: AliasType<{
	create?:ResolverInputTypes["EventsDocAccessFields_hasTicketing_Create"],
	read?:ResolverInputTypes["EventsDocAccessFields_hasTicketing_Read"],
	update?:ResolverInputTypes["EventsDocAccessFields_hasTicketing_Update"],
	delete?:ResolverInputTypes["EventsDocAccessFields_hasTicketing_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_hasTicketing_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_hasTicketing_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_hasTicketing_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_hasTicketing_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_hasProducts"]: AliasType<{
	create?:ResolverInputTypes["EventsDocAccessFields_hasProducts_Create"],
	read?:ResolverInputTypes["EventsDocAccessFields_hasProducts_Read"],
	update?:ResolverInputTypes["EventsDocAccessFields_hasProducts_Update"],
	delete?:ResolverInputTypes["EventsDocAccessFields_hasProducts_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_hasProducts_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_hasProducts_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_hasProducts_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_hasProducts_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_updatedAt"]: AliasType<{
	create?:ResolverInputTypes["EventsDocAccessFields_updatedAt_Create"],
	read?:ResolverInputTypes["EventsDocAccessFields_updatedAt_Read"],
	update?:ResolverInputTypes["EventsDocAccessFields_updatedAt_Update"],
	delete?:ResolverInputTypes["EventsDocAccessFields_updatedAt_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_updatedAt_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_updatedAt_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_updatedAt_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_updatedAt_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_createdAt"]: AliasType<{
	create?:ResolverInputTypes["EventsDocAccessFields_createdAt_Create"],
	read?:ResolverInputTypes["EventsDocAccessFields_createdAt_Read"],
	update?:ResolverInputTypes["EventsDocAccessFields_createdAt_Update"],
	delete?:ResolverInputTypes["EventsDocAccessFields_createdAt_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_createdAt_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_createdAt_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_createdAt_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDocAccessFields_createdAt_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsCreateDocAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	/** The `JSONObject` scalar type represents JSON objects as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
["JSONObject"]:unknown;
	["EventsReadDocAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsUpdateDocAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDeleteDocAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["Locations"]: AliasType<{
	docs?:ResolverInputTypes["Location"],
	hasNextPage?:boolean | `@${string}`,
	hasPrevPage?:boolean | `@${string}`,
	limit?:boolean | `@${string}`,
	nextPage?:boolean | `@${string}`,
	offset?:boolean | `@${string}`,
	page?:boolean | `@${string}`,
	pagingCounter?:boolean | `@${string}`,
	prevPage?:boolean | `@${string}`,
	totalDocs?:boolean | `@${string}`,
	totalPages?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["Location_where"]: {
	region?: ResolverInputTypes["Location_region_operator"] | undefined | null,
	creator?: ResolverInputTypes["Location_creator_operator"] | undefined | null,
	permalink?: ResolverInputTypes["Location_permalink_operator"] | undefined | null,
	status?: ResolverInputTypes["Location_status_operator"] | undefined | null,
	level?: ResolverInputTypes["Location_level_operator"] | undefined | null,
	parent?: ResolverInputTypes["Location_parent_operator"] | undefined | null,
	name?: ResolverInputTypes["Location_name_operator"] | undefined | null,
	thumbnail?: ResolverInputTypes["Location_thumbnail_operator"] | undefined | null,
	banner?: ResolverInputTypes["Location_banner_operator"] | undefined | null,
	type?: ResolverInputTypes["Location_type_operator"] | undefined | null,
	category?: ResolverInputTypes["Location_category_operator"] | undefined | null,
	district?: ResolverInputTypes["Location_district_operator"] | undefined | null,
	coordinate?: ResolverInputTypes["Location_coordinate_operator"] | undefined | null,
	linkAddress?: ResolverInputTypes["Location_linkAddress_operator"] | undefined | null,
	address?: ResolverInputTypes["Location_address_operator"] | undefined | null,
	Details__code?: ResolverInputTypes["Location_Details__code_operator"] | undefined | null,
	Details__phone?: ResolverInputTypes["Location_Details__phone_operator"] | undefined | null,
	Details__page?: ResolverInputTypes["Location_Details__page_operator"] | undefined | null,
	Details__email?: ResolverInputTypes["Location_Details__email_operator"] | undefined | null,
	legacyContent?: ResolverInputTypes["Location_legacyContent_operator"] | undefined | null,
	showLegacyContent?: ResolverInputTypes["Location_showLegacyContent_operator"] | undefined | null,
	criteria__audiences?: ResolverInputTypes["Location_criteria__audiences_operator"] | undefined | null,
	criteria__dates?: ResolverInputTypes["Location_criteria__dates_operator"] | undefined | null,
	criteria__categories?: ResolverInputTypes["Location_criteria__categories_operator"] | undefined | null,
	criteria__tags?: ResolverInputTypes["Location_criteria__tags_operator"] | undefined | null,
	sections__startDatetime?: ResolverInputTypes["Location_sections__startDatetime_operator"] | undefined | null,
	sections__endDatetime?: ResolverInputTypes["Location_sections__endDatetime_operator"] | undefined | null,
	sections__fullDay?: ResolverInputTypes["Location_sections__fullDay_operator"] | undefined | null,
	sections__repeat?: ResolverInputTypes["Location_sections__repeat_operator"] | undefined | null,
	sections__recurrance__type?: ResolverInputTypes["Location_sections__recurrance__type_operator"] | undefined | null,
	sections__recurrance__weekday?: ResolverInputTypes["Location_sections__recurrance__weekday_operator"] | undefined | null,
	sections__id?: ResolverInputTypes["Location_sections__id_operator"] | undefined | null,
	_title?: ResolverInputTypes["Location__title_operator"] | undefined | null,
	legacyGallery?: ResolverInputTypes["Location_legacyGallery_operator"] | undefined | null,
	updatedAt?: ResolverInputTypes["Location_updatedAt_operator"] | undefined | null,
	createdAt?: ResolverInputTypes["Location_createdAt_operator"] | undefined | null,
	id?: ResolverInputTypes["Location_id_operator"] | undefined | null,
	AND?: Array<ResolverInputTypes["Location_where_and"] | undefined | null> | undefined | null,
	OR?: Array<ResolverInputTypes["Location_where_or"] | undefined | null> | undefined | null
};
	["Location_region_operator"]: {
	equals?: ResolverInputTypes["Location_region_Input"] | undefined | null,
	not_equals?: ResolverInputTypes["Location_region_Input"] | undefined | null,
	in?: Array<ResolverInputTypes["Location_region_Input"] | undefined | null> | undefined | null,
	not_in?: Array<ResolverInputTypes["Location_region_Input"] | undefined | null> | undefined | null,
	all?: Array<ResolverInputTypes["Location_region_Input"] | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Location_region_Input"]:Location_region_Input;
	["Location_creator_operator"]: {
	equals?: ResolverInputTypes["JSON"] | undefined | null,
	not_equals?: ResolverInputTypes["JSON"] | undefined | null,
	in?: Array<ResolverInputTypes["JSON"] | undefined | null> | undefined | null,
	not_in?: Array<ResolverInputTypes["JSON"] | undefined | null> | undefined | null,
	all?: Array<ResolverInputTypes["JSON"] | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Location_permalink_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Location_status_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null
};
	["Location_level_operator"]: {
	equals?: ResolverInputTypes["Location_level_Input"] | undefined | null,
	not_equals?: ResolverInputTypes["Location_level_Input"] | undefined | null,
	in?: Array<ResolverInputTypes["Location_level_Input"] | undefined | null> | undefined | null,
	not_in?: Array<ResolverInputTypes["Location_level_Input"] | undefined | null> | undefined | null,
	all?: Array<ResolverInputTypes["Location_level_Input"] | undefined | null> | undefined | null
};
	["Location_level_Input"]:Location_level_Input;
	["Location_parent_operator"]: {
	equals?: ResolverInputTypes["JSON"] | undefined | null,
	not_equals?: ResolverInputTypes["JSON"] | undefined | null,
	in?: Array<ResolverInputTypes["JSON"] | undefined | null> | undefined | null,
	not_in?: Array<ResolverInputTypes["JSON"] | undefined | null> | undefined | null,
	all?: Array<ResolverInputTypes["JSON"] | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Location_name_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null
};
	["Location_thumbnail_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	exists?: boolean | undefined | null
};
	["Location_banner_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	exists?: boolean | undefined | null
};
	["Location_type_operator"]: {
	equals?: ResolverInputTypes["Location_type_Input"] | undefined | null,
	not_equals?: ResolverInputTypes["Location_type_Input"] | undefined | null,
	in?: Array<ResolverInputTypes["Location_type_Input"] | undefined | null> | undefined | null,
	not_in?: Array<ResolverInputTypes["Location_type_Input"] | undefined | null> | undefined | null,
	all?: Array<ResolverInputTypes["Location_type_Input"] | undefined | null> | undefined | null
};
	["Location_type_Input"]:Location_type_Input;
	["Location_category_operator"]: {
	equals?: ResolverInputTypes["Location_category_Input"] | undefined | null,
	not_equals?: ResolverInputTypes["Location_category_Input"] | undefined | null,
	in?: Array<ResolverInputTypes["Location_category_Input"] | undefined | null> | undefined | null,
	not_in?: Array<ResolverInputTypes["Location_category_Input"] | undefined | null> | undefined | null,
	all?: Array<ResolverInputTypes["Location_category_Input"] | undefined | null> | undefined | null
};
	["Location_category_Input"]:Location_category_Input;
	["Location_district_operator"]: {
	equals?: ResolverInputTypes["JSON"] | undefined | null,
	not_equals?: ResolverInputTypes["JSON"] | undefined | null,
	in?: Array<ResolverInputTypes["JSON"] | undefined | null> | undefined | null,
	not_in?: Array<ResolverInputTypes["JSON"] | undefined | null> | undefined | null,
	all?: Array<ResolverInputTypes["JSON"] | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Location_coordinate_operator"]: {
	equals?: Array<number | undefined | null> | undefined | null,
	not_equals?: Array<number | undefined | null> | undefined | null,
	greater_than_equal?: Array<number | undefined | null> | undefined | null,
	greater_than?: Array<number | undefined | null> | undefined | null,
	less_than_equal?: Array<number | undefined | null> | undefined | null,
	less_than?: Array<number | undefined | null> | undefined | null,
	near?: Array<number | undefined | null> | undefined | null,
	within?: ResolverInputTypes["GeoJSONObject"] | undefined | null,
	intersects?: ResolverInputTypes["GeoJSONObject"] | undefined | null,
	exists?: boolean | undefined | null
};
	["Location_linkAddress_operator"]: {
	equals?: boolean | undefined | null,
	not_equals?: boolean | undefined | null,
	exists?: boolean | undefined | null
};
	["Location_address_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Location_Details__code_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Location_Details__phone_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Location_Details__page_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Location_Details__email_operator"]: {
	equals?: ResolverInputTypes["EmailAddress"] | undefined | null,
	not_equals?: ResolverInputTypes["EmailAddress"] | undefined | null,
	like?: ResolverInputTypes["EmailAddress"] | undefined | null,
	contains?: ResolverInputTypes["EmailAddress"] | undefined | null,
	in?: Array<ResolverInputTypes["EmailAddress"] | undefined | null> | undefined | null,
	not_in?: Array<ResolverInputTypes["EmailAddress"] | undefined | null> | undefined | null,
	all?: Array<ResolverInputTypes["EmailAddress"] | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Location_legacyContent_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	exists?: boolean | undefined | null
};
	["Location_showLegacyContent_operator"]: {
	equals?: boolean | undefined | null,
	not_equals?: boolean | undefined | null,
	exists?: boolean | undefined | null
};
	["Location_criteria__audiences_operator"]: {
	equals?: ResolverInputTypes["JSON"] | undefined | null,
	not_equals?: ResolverInputTypes["JSON"] | undefined | null,
	in?: Array<ResolverInputTypes["JSON"] | undefined | null> | undefined | null,
	not_in?: Array<ResolverInputTypes["JSON"] | undefined | null> | undefined | null,
	all?: Array<ResolverInputTypes["JSON"] | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Location_criteria__dates_operator"]: {
	equals?: ResolverInputTypes["JSON"] | undefined | null,
	not_equals?: ResolverInputTypes["JSON"] | undefined | null,
	in?: Array<ResolverInputTypes["JSON"] | undefined | null> | undefined | null,
	not_in?: Array<ResolverInputTypes["JSON"] | undefined | null> | undefined | null,
	all?: Array<ResolverInputTypes["JSON"] | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Location_criteria__categories_operator"]: {
	equals?: ResolverInputTypes["JSON"] | undefined | null,
	not_equals?: ResolverInputTypes["JSON"] | undefined | null,
	in?: Array<ResolverInputTypes["JSON"] | undefined | null> | undefined | null,
	not_in?: Array<ResolverInputTypes["JSON"] | undefined | null> | undefined | null,
	all?: Array<ResolverInputTypes["JSON"] | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Location_criteria__tags_operator"]: {
	equals?: ResolverInputTypes["JSON"] | undefined | null,
	not_equals?: ResolverInputTypes["JSON"] | undefined | null,
	in?: Array<ResolverInputTypes["JSON"] | undefined | null> | undefined | null,
	not_in?: Array<ResolverInputTypes["JSON"] | undefined | null> | undefined | null,
	all?: Array<ResolverInputTypes["JSON"] | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Location_sections__startDatetime_operator"]: {
	equals?: ResolverInputTypes["DateTime"] | undefined | null,
	not_equals?: ResolverInputTypes["DateTime"] | undefined | null,
	greater_than_equal?: ResolverInputTypes["DateTime"] | undefined | null,
	greater_than?: ResolverInputTypes["DateTime"] | undefined | null,
	less_than_equal?: ResolverInputTypes["DateTime"] | undefined | null,
	less_than?: ResolverInputTypes["DateTime"] | undefined | null,
	like?: ResolverInputTypes["DateTime"] | undefined | null
};
	["Location_sections__endDatetime_operator"]: {
	equals?: ResolverInputTypes["DateTime"] | undefined | null,
	not_equals?: ResolverInputTypes["DateTime"] | undefined | null,
	greater_than_equal?: ResolverInputTypes["DateTime"] | undefined | null,
	greater_than?: ResolverInputTypes["DateTime"] | undefined | null,
	less_than_equal?: ResolverInputTypes["DateTime"] | undefined | null,
	less_than?: ResolverInputTypes["DateTime"] | undefined | null,
	like?: ResolverInputTypes["DateTime"] | undefined | null
};
	["Location_sections__fullDay_operator"]: {
	equals?: boolean | undefined | null,
	not_equals?: boolean | undefined | null,
	exists?: boolean | undefined | null
};
	["Location_sections__repeat_operator"]: {
	equals?: boolean | undefined | null,
	not_equals?: boolean | undefined | null,
	exists?: boolean | undefined | null
};
	["Location_sections__recurrance__type_operator"]: {
	equals?: ResolverInputTypes["Location_sections__recurrance__type_Input"] | undefined | null,
	not_equals?: ResolverInputTypes["Location_sections__recurrance__type_Input"] | undefined | null,
	in?: Array<ResolverInputTypes["Location_sections__recurrance__type_Input"] | undefined | null> | undefined | null,
	not_in?: Array<ResolverInputTypes["Location_sections__recurrance__type_Input"] | undefined | null> | undefined | null,
	all?: Array<ResolverInputTypes["Location_sections__recurrance__type_Input"] | undefined | null> | undefined | null
};
	["Location_sections__recurrance__type_Input"]:Location_sections__recurrance__type_Input;
	["Location_sections__recurrance__weekday_operator"]: {
	equals?: ResolverInputTypes["Location_sections__recurrance__weekday_Input"] | undefined | null,
	not_equals?: ResolverInputTypes["Location_sections__recurrance__weekday_Input"] | undefined | null,
	in?: Array<ResolverInputTypes["Location_sections__recurrance__weekday_Input"] | undefined | null> | undefined | null,
	not_in?: Array<ResolverInputTypes["Location_sections__recurrance__weekday_Input"] | undefined | null> | undefined | null,
	all?: Array<ResolverInputTypes["Location_sections__recurrance__weekday_Input"] | undefined | null> | undefined | null
};
	["Location_sections__recurrance__weekday_Input"]:Location_sections__recurrance__weekday_Input;
	["Location_sections__id_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Location__title_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Location_legacyGallery_operator"]: {
	equals?: ResolverInputTypes["JSON"] | undefined | null,
	not_equals?: ResolverInputTypes["JSON"] | undefined | null,
	in?: Array<ResolverInputTypes["JSON"] | undefined | null> | undefined | null,
	not_in?: Array<ResolverInputTypes["JSON"] | undefined | null> | undefined | null,
	all?: Array<ResolverInputTypes["JSON"] | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Location_updatedAt_operator"]: {
	equals?: ResolverInputTypes["DateTime"] | undefined | null,
	not_equals?: ResolverInputTypes["DateTime"] | undefined | null,
	greater_than_equal?: ResolverInputTypes["DateTime"] | undefined | null,
	greater_than?: ResolverInputTypes["DateTime"] | undefined | null,
	less_than_equal?: ResolverInputTypes["DateTime"] | undefined | null,
	less_than?: ResolverInputTypes["DateTime"] | undefined | null,
	like?: ResolverInputTypes["DateTime"] | undefined | null,
	exists?: boolean | undefined | null
};
	["Location_createdAt_operator"]: {
	equals?: ResolverInputTypes["DateTime"] | undefined | null,
	not_equals?: ResolverInputTypes["DateTime"] | undefined | null,
	greater_than_equal?: ResolverInputTypes["DateTime"] | undefined | null,
	greater_than?: ResolverInputTypes["DateTime"] | undefined | null,
	less_than_equal?: ResolverInputTypes["DateTime"] | undefined | null,
	less_than?: ResolverInputTypes["DateTime"] | undefined | null,
	like?: ResolverInputTypes["DateTime"] | undefined | null,
	exists?: boolean | undefined | null
};
	["Location_id_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Location_where_and"]: {
	region?: ResolverInputTypes["Location_region_operator"] | undefined | null,
	creator?: ResolverInputTypes["Location_creator_operator"] | undefined | null,
	permalink?: ResolverInputTypes["Location_permalink_operator"] | undefined | null,
	status?: ResolverInputTypes["Location_status_operator"] | undefined | null,
	level?: ResolverInputTypes["Location_level_operator"] | undefined | null,
	parent?: ResolverInputTypes["Location_parent_operator"] | undefined | null,
	name?: ResolverInputTypes["Location_name_operator"] | undefined | null,
	thumbnail?: ResolverInputTypes["Location_thumbnail_operator"] | undefined | null,
	banner?: ResolverInputTypes["Location_banner_operator"] | undefined | null,
	type?: ResolverInputTypes["Location_type_operator"] | undefined | null,
	category?: ResolverInputTypes["Location_category_operator"] | undefined | null,
	district?: ResolverInputTypes["Location_district_operator"] | undefined | null,
	coordinate?: ResolverInputTypes["Location_coordinate_operator"] | undefined | null,
	linkAddress?: ResolverInputTypes["Location_linkAddress_operator"] | undefined | null,
	address?: ResolverInputTypes["Location_address_operator"] | undefined | null,
	Details__code?: ResolverInputTypes["Location_Details__code_operator"] | undefined | null,
	Details__phone?: ResolverInputTypes["Location_Details__phone_operator"] | undefined | null,
	Details__page?: ResolverInputTypes["Location_Details__page_operator"] | undefined | null,
	Details__email?: ResolverInputTypes["Location_Details__email_operator"] | undefined | null,
	legacyContent?: ResolverInputTypes["Location_legacyContent_operator"] | undefined | null,
	showLegacyContent?: ResolverInputTypes["Location_showLegacyContent_operator"] | undefined | null,
	criteria__audiences?: ResolverInputTypes["Location_criteria__audiences_operator"] | undefined | null,
	criteria__dates?: ResolverInputTypes["Location_criteria__dates_operator"] | undefined | null,
	criteria__categories?: ResolverInputTypes["Location_criteria__categories_operator"] | undefined | null,
	criteria__tags?: ResolverInputTypes["Location_criteria__tags_operator"] | undefined | null,
	sections__startDatetime?: ResolverInputTypes["Location_sections__startDatetime_operator"] | undefined | null,
	sections__endDatetime?: ResolverInputTypes["Location_sections__endDatetime_operator"] | undefined | null,
	sections__fullDay?: ResolverInputTypes["Location_sections__fullDay_operator"] | undefined | null,
	sections__repeat?: ResolverInputTypes["Location_sections__repeat_operator"] | undefined | null,
	sections__recurrance__type?: ResolverInputTypes["Location_sections__recurrance__type_operator"] | undefined | null,
	sections__recurrance__weekday?: ResolverInputTypes["Location_sections__recurrance__weekday_operator"] | undefined | null,
	sections__id?: ResolverInputTypes["Location_sections__id_operator"] | undefined | null,
	_title?: ResolverInputTypes["Location__title_operator"] | undefined | null,
	legacyGallery?: ResolverInputTypes["Location_legacyGallery_operator"] | undefined | null,
	updatedAt?: ResolverInputTypes["Location_updatedAt_operator"] | undefined | null,
	createdAt?: ResolverInputTypes["Location_createdAt_operator"] | undefined | null,
	id?: ResolverInputTypes["Location_id_operator"] | undefined | null,
	AND?: Array<ResolverInputTypes["Location_where_and"] | undefined | null> | undefined | null,
	OR?: Array<ResolverInputTypes["Location_where_or"] | undefined | null> | undefined | null
};
	["Location_where_or"]: {
	region?: ResolverInputTypes["Location_region_operator"] | undefined | null,
	creator?: ResolverInputTypes["Location_creator_operator"] | undefined | null,
	permalink?: ResolverInputTypes["Location_permalink_operator"] | undefined | null,
	status?: ResolverInputTypes["Location_status_operator"] | undefined | null,
	level?: ResolverInputTypes["Location_level_operator"] | undefined | null,
	parent?: ResolverInputTypes["Location_parent_operator"] | undefined | null,
	name?: ResolverInputTypes["Location_name_operator"] | undefined | null,
	thumbnail?: ResolverInputTypes["Location_thumbnail_operator"] | undefined | null,
	banner?: ResolverInputTypes["Location_banner_operator"] | undefined | null,
	type?: ResolverInputTypes["Location_type_operator"] | undefined | null,
	category?: ResolverInputTypes["Location_category_operator"] | undefined | null,
	district?: ResolverInputTypes["Location_district_operator"] | undefined | null,
	coordinate?: ResolverInputTypes["Location_coordinate_operator"] | undefined | null,
	linkAddress?: ResolverInputTypes["Location_linkAddress_operator"] | undefined | null,
	address?: ResolverInputTypes["Location_address_operator"] | undefined | null,
	Details__code?: ResolverInputTypes["Location_Details__code_operator"] | undefined | null,
	Details__phone?: ResolverInputTypes["Location_Details__phone_operator"] | undefined | null,
	Details__page?: ResolverInputTypes["Location_Details__page_operator"] | undefined | null,
	Details__email?: ResolverInputTypes["Location_Details__email_operator"] | undefined | null,
	legacyContent?: ResolverInputTypes["Location_legacyContent_operator"] | undefined | null,
	showLegacyContent?: ResolverInputTypes["Location_showLegacyContent_operator"] | undefined | null,
	criteria__audiences?: ResolverInputTypes["Location_criteria__audiences_operator"] | undefined | null,
	criteria__dates?: ResolverInputTypes["Location_criteria__dates_operator"] | undefined | null,
	criteria__categories?: ResolverInputTypes["Location_criteria__categories_operator"] | undefined | null,
	criteria__tags?: ResolverInputTypes["Location_criteria__tags_operator"] | undefined | null,
	sections__startDatetime?: ResolverInputTypes["Location_sections__startDatetime_operator"] | undefined | null,
	sections__endDatetime?: ResolverInputTypes["Location_sections__endDatetime_operator"] | undefined | null,
	sections__fullDay?: ResolverInputTypes["Location_sections__fullDay_operator"] | undefined | null,
	sections__repeat?: ResolverInputTypes["Location_sections__repeat_operator"] | undefined | null,
	sections__recurrance__type?: ResolverInputTypes["Location_sections__recurrance__type_operator"] | undefined | null,
	sections__recurrance__weekday?: ResolverInputTypes["Location_sections__recurrance__weekday_operator"] | undefined | null,
	sections__id?: ResolverInputTypes["Location_sections__id_operator"] | undefined | null,
	_title?: ResolverInputTypes["Location__title_operator"] | undefined | null,
	legacyGallery?: ResolverInputTypes["Location_legacyGallery_operator"] | undefined | null,
	updatedAt?: ResolverInputTypes["Location_updatedAt_operator"] | undefined | null,
	createdAt?: ResolverInputTypes["Location_createdAt_operator"] | undefined | null,
	id?: ResolverInputTypes["Location_id_operator"] | undefined | null,
	AND?: Array<ResolverInputTypes["Location_where_and"] | undefined | null> | undefined | null,
	OR?: Array<ResolverInputTypes["Location_where_or"] | undefined | null> | undefined | null
};
	["countLocations"]: AliasType<{
	totalDocs?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["locationsDocAccess"]: AliasType<{
	fields?:ResolverInputTypes["LocationsDocAccessFields"],
	create?:ResolverInputTypes["LocationsCreateDocAccess"],
	read?:ResolverInputTypes["LocationsReadDocAccess"],
	update?:ResolverInputTypes["LocationsUpdateDocAccess"],
	delete?:ResolverInputTypes["LocationsDeleteDocAccess"],
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields"]: AliasType<{
	region?:ResolverInputTypes["LocationsDocAccessFields_region"],
	creator?:ResolverInputTypes["LocationsDocAccessFields_creator"],
	permalink?:ResolverInputTypes["LocationsDocAccessFields_permalink"],
	status?:ResolverInputTypes["LocationsDocAccessFields_status"],
	level?:ResolverInputTypes["LocationsDocAccessFields_level"],
	parent?:ResolverInputTypes["LocationsDocAccessFields_parent"],
	name?:ResolverInputTypes["LocationsDocAccessFields_name"],
	thumbnail?:ResolverInputTypes["LocationsDocAccessFields_thumbnail"],
	banner?:ResolverInputTypes["LocationsDocAccessFields_banner"],
	type?:ResolverInputTypes["LocationsDocAccessFields_type"],
	category?:ResolverInputTypes["LocationsDocAccessFields_category"],
	district?:ResolverInputTypes["LocationsDocAccessFields_district"],
	coordinate?:ResolverInputTypes["LocationsDocAccessFields_coordinate"],
	linkAddress?:ResolverInputTypes["LocationsDocAccessFields_linkAddress"],
	address?:ResolverInputTypes["LocationsDocAccessFields_address"],
	Details?:ResolverInputTypes["LocationsDocAccessFields_Details"],
	content?:ResolverInputTypes["LocationsDocAccessFields_content"],
	legacyContent?:ResolverInputTypes["LocationsDocAccessFields_legacyContent"],
	showLegacyContent?:ResolverInputTypes["LocationsDocAccessFields_showLegacyContent"],
	criteria?:ResolverInputTypes["LocationsDocAccessFields_criteria"],
	sections?:ResolverInputTypes["LocationsDocAccessFields_sections"],
	_title?:ResolverInputTypes["LocationsDocAccessFields__title"],
	legacyGallery?:ResolverInputTypes["LocationsDocAccessFields_legacyGallery"],
	updatedAt?:ResolverInputTypes["LocationsDocAccessFields_updatedAt"],
	createdAt?:ResolverInputTypes["LocationsDocAccessFields_createdAt"],
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields_region"]: AliasType<{
	create?:ResolverInputTypes["LocationsDocAccessFields_region_Create"],
	read?:ResolverInputTypes["LocationsDocAccessFields_region_Read"],
	update?:ResolverInputTypes["LocationsDocAccessFields_region_Update"],
	delete?:ResolverInputTypes["LocationsDocAccessFields_region_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields_region_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields_region_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields_region_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields_region_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields_creator"]: AliasType<{
	create?:ResolverInputTypes["LocationsDocAccessFields_creator_Create"],
	read?:ResolverInputTypes["LocationsDocAccessFields_creator_Read"],
	update?:ResolverInputTypes["LocationsDocAccessFields_creator_Update"],
	delete?:ResolverInputTypes["LocationsDocAccessFields_creator_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields_creator_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields_creator_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields_creator_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields_creator_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields_permalink"]: AliasType<{
	create?:ResolverInputTypes["LocationsDocAccessFields_permalink_Create"],
	read?:ResolverInputTypes["LocationsDocAccessFields_permalink_Read"],
	update?:ResolverInputTypes["LocationsDocAccessFields_permalink_Update"],
	delete?:ResolverInputTypes["LocationsDocAccessFields_permalink_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields_permalink_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields_permalink_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields_permalink_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields_permalink_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields_status"]: AliasType<{
	create?:ResolverInputTypes["LocationsDocAccessFields_status_Create"],
	read?:ResolverInputTypes["LocationsDocAccessFields_status_Read"],
	update?:ResolverInputTypes["LocationsDocAccessFields_status_Update"],
	delete?:ResolverInputTypes["LocationsDocAccessFields_status_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields_status_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields_status_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields_status_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields_status_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields_level"]: AliasType<{
	create?:ResolverInputTypes["LocationsDocAccessFields_level_Create"],
	read?:ResolverInputTypes["LocationsDocAccessFields_level_Read"],
	update?:ResolverInputTypes["LocationsDocAccessFields_level_Update"],
	delete?:ResolverInputTypes["LocationsDocAccessFields_level_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields_level_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields_level_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields_level_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields_level_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields_parent"]: AliasType<{
	create?:ResolverInputTypes["LocationsDocAccessFields_parent_Create"],
	read?:ResolverInputTypes["LocationsDocAccessFields_parent_Read"],
	update?:ResolverInputTypes["LocationsDocAccessFields_parent_Update"],
	delete?:ResolverInputTypes["LocationsDocAccessFields_parent_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields_parent_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields_parent_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields_parent_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields_parent_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields_name"]: AliasType<{
	create?:ResolverInputTypes["LocationsDocAccessFields_name_Create"],
	read?:ResolverInputTypes["LocationsDocAccessFields_name_Read"],
	update?:ResolverInputTypes["LocationsDocAccessFields_name_Update"],
	delete?:ResolverInputTypes["LocationsDocAccessFields_name_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields_name_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields_name_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields_name_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields_name_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields_thumbnail"]: AliasType<{
	create?:ResolverInputTypes["LocationsDocAccessFields_thumbnail_Create"],
	read?:ResolverInputTypes["LocationsDocAccessFields_thumbnail_Read"],
	update?:ResolverInputTypes["LocationsDocAccessFields_thumbnail_Update"],
	delete?:ResolverInputTypes["LocationsDocAccessFields_thumbnail_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields_thumbnail_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields_thumbnail_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields_thumbnail_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields_thumbnail_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields_banner"]: AliasType<{
	create?:ResolverInputTypes["LocationsDocAccessFields_banner_Create"],
	read?:ResolverInputTypes["LocationsDocAccessFields_banner_Read"],
	update?:ResolverInputTypes["LocationsDocAccessFields_banner_Update"],
	delete?:ResolverInputTypes["LocationsDocAccessFields_banner_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields_banner_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields_banner_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields_banner_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields_banner_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields_type"]: AliasType<{
	create?:ResolverInputTypes["LocationsDocAccessFields_type_Create"],
	read?:ResolverInputTypes["LocationsDocAccessFields_type_Read"],
	update?:ResolverInputTypes["LocationsDocAccessFields_type_Update"],
	delete?:ResolverInputTypes["LocationsDocAccessFields_type_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields_type_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields_type_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields_type_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields_type_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields_category"]: AliasType<{
	create?:ResolverInputTypes["LocationsDocAccessFields_category_Create"],
	read?:ResolverInputTypes["LocationsDocAccessFields_category_Read"],
	update?:ResolverInputTypes["LocationsDocAccessFields_category_Update"],
	delete?:ResolverInputTypes["LocationsDocAccessFields_category_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields_category_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields_category_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields_category_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields_category_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields_district"]: AliasType<{
	create?:ResolverInputTypes["LocationsDocAccessFields_district_Create"],
	read?:ResolverInputTypes["LocationsDocAccessFields_district_Read"],
	update?:ResolverInputTypes["LocationsDocAccessFields_district_Update"],
	delete?:ResolverInputTypes["LocationsDocAccessFields_district_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields_district_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields_district_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields_district_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields_district_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields_coordinate"]: AliasType<{
	create?:ResolverInputTypes["LocationsDocAccessFields_coordinate_Create"],
	read?:ResolverInputTypes["LocationsDocAccessFields_coordinate_Read"],
	update?:ResolverInputTypes["LocationsDocAccessFields_coordinate_Update"],
	delete?:ResolverInputTypes["LocationsDocAccessFields_coordinate_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields_coordinate_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields_coordinate_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields_coordinate_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields_coordinate_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields_linkAddress"]: AliasType<{
	create?:ResolverInputTypes["LocationsDocAccessFields_linkAddress_Create"],
	read?:ResolverInputTypes["LocationsDocAccessFields_linkAddress_Read"],
	update?:ResolverInputTypes["LocationsDocAccessFields_linkAddress_Update"],
	delete?:ResolverInputTypes["LocationsDocAccessFields_linkAddress_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields_linkAddress_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields_linkAddress_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields_linkAddress_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields_linkAddress_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields_address"]: AliasType<{
	create?:ResolverInputTypes["LocationsDocAccessFields_address_Create"],
	read?:ResolverInputTypes["LocationsDocAccessFields_address_Read"],
	update?:ResolverInputTypes["LocationsDocAccessFields_address_Update"],
	delete?:ResolverInputTypes["LocationsDocAccessFields_address_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields_address_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields_address_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields_address_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields_address_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields_Details"]: AliasType<{
	create?:ResolverInputTypes["LocationsDocAccessFields_Details_Create"],
	read?:ResolverInputTypes["LocationsDocAccessFields_Details_Read"],
	update?:ResolverInputTypes["LocationsDocAccessFields_Details_Update"],
	delete?:ResolverInputTypes["LocationsDocAccessFields_Details_Delete"],
	fields?:ResolverInputTypes["LocationsDocAccessFields_Details_Fields"],
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields_Details_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields_Details_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields_Details_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields_Details_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields_Details_Fields"]: AliasType<{
	code?:ResolverInputTypes["LocationsDocAccessFields_Details_code"],
	phone?:ResolverInputTypes["LocationsDocAccessFields_Details_phone"],
	page?:ResolverInputTypes["LocationsDocAccessFields_Details_page"],
	email?:ResolverInputTypes["LocationsDocAccessFields_Details_email"],
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields_Details_code"]: AliasType<{
	create?:ResolverInputTypes["LocationsDocAccessFields_Details_code_Create"],
	read?:ResolverInputTypes["LocationsDocAccessFields_Details_code_Read"],
	update?:ResolverInputTypes["LocationsDocAccessFields_Details_code_Update"],
	delete?:ResolverInputTypes["LocationsDocAccessFields_Details_code_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields_Details_code_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields_Details_code_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields_Details_code_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields_Details_code_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields_Details_phone"]: AliasType<{
	create?:ResolverInputTypes["LocationsDocAccessFields_Details_phone_Create"],
	read?:ResolverInputTypes["LocationsDocAccessFields_Details_phone_Read"],
	update?:ResolverInputTypes["LocationsDocAccessFields_Details_phone_Update"],
	delete?:ResolverInputTypes["LocationsDocAccessFields_Details_phone_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields_Details_phone_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields_Details_phone_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields_Details_phone_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields_Details_phone_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields_Details_page"]: AliasType<{
	create?:ResolverInputTypes["LocationsDocAccessFields_Details_page_Create"],
	read?:ResolverInputTypes["LocationsDocAccessFields_Details_page_Read"],
	update?:ResolverInputTypes["LocationsDocAccessFields_Details_page_Update"],
	delete?:ResolverInputTypes["LocationsDocAccessFields_Details_page_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields_Details_page_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields_Details_page_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields_Details_page_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields_Details_page_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields_Details_email"]: AliasType<{
	create?:ResolverInputTypes["LocationsDocAccessFields_Details_email_Create"],
	read?:ResolverInputTypes["LocationsDocAccessFields_Details_email_Read"],
	update?:ResolverInputTypes["LocationsDocAccessFields_Details_email_Update"],
	delete?:ResolverInputTypes["LocationsDocAccessFields_Details_email_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields_Details_email_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields_Details_email_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields_Details_email_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields_Details_email_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields_content"]: AliasType<{
	create?:ResolverInputTypes["LocationsDocAccessFields_content_Create"],
	read?:ResolverInputTypes["LocationsDocAccessFields_content_Read"],
	update?:ResolverInputTypes["LocationsDocAccessFields_content_Update"],
	delete?:ResolverInputTypes["LocationsDocAccessFields_content_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields_content_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields_content_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields_content_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields_content_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields_legacyContent"]: AliasType<{
	create?:ResolverInputTypes["LocationsDocAccessFields_legacyContent_Create"],
	read?:ResolverInputTypes["LocationsDocAccessFields_legacyContent_Read"],
	update?:ResolverInputTypes["LocationsDocAccessFields_legacyContent_Update"],
	delete?:ResolverInputTypes["LocationsDocAccessFields_legacyContent_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields_legacyContent_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields_legacyContent_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields_legacyContent_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields_legacyContent_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields_showLegacyContent"]: AliasType<{
	create?:ResolverInputTypes["LocationsDocAccessFields_showLegacyContent_Create"],
	read?:ResolverInputTypes["LocationsDocAccessFields_showLegacyContent_Read"],
	update?:ResolverInputTypes["LocationsDocAccessFields_showLegacyContent_Update"],
	delete?:ResolverInputTypes["LocationsDocAccessFields_showLegacyContent_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields_showLegacyContent_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields_showLegacyContent_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields_showLegacyContent_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields_showLegacyContent_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields_criteria"]: AliasType<{
	create?:ResolverInputTypes["LocationsDocAccessFields_criteria_Create"],
	read?:ResolverInputTypes["LocationsDocAccessFields_criteria_Read"],
	update?:ResolverInputTypes["LocationsDocAccessFields_criteria_Update"],
	delete?:ResolverInputTypes["LocationsDocAccessFields_criteria_Delete"],
	fields?:ResolverInputTypes["LocationsDocAccessFields_criteria_Fields"],
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields_criteria_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields_criteria_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields_criteria_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields_criteria_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields_criteria_Fields"]: AliasType<{
	audiences?:ResolverInputTypes["LocationsDocAccessFields_criteria_audiences"],
	dates?:ResolverInputTypes["LocationsDocAccessFields_criteria_dates"],
	categories?:ResolverInputTypes["LocationsDocAccessFields_criteria_categories"],
	tags?:ResolverInputTypes["LocationsDocAccessFields_criteria_tags"],
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields_criteria_audiences"]: AliasType<{
	create?:ResolverInputTypes["LocationsDocAccessFields_criteria_audiences_Create"],
	read?:ResolverInputTypes["LocationsDocAccessFields_criteria_audiences_Read"],
	update?:ResolverInputTypes["LocationsDocAccessFields_criteria_audiences_Update"],
	delete?:ResolverInputTypes["LocationsDocAccessFields_criteria_audiences_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields_criteria_audiences_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields_criteria_audiences_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields_criteria_audiences_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields_criteria_audiences_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields_criteria_dates"]: AliasType<{
	create?:ResolverInputTypes["LocationsDocAccessFields_criteria_dates_Create"],
	read?:ResolverInputTypes["LocationsDocAccessFields_criteria_dates_Read"],
	update?:ResolverInputTypes["LocationsDocAccessFields_criteria_dates_Update"],
	delete?:ResolverInputTypes["LocationsDocAccessFields_criteria_dates_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields_criteria_dates_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields_criteria_dates_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields_criteria_dates_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields_criteria_dates_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields_criteria_categories"]: AliasType<{
	create?:ResolverInputTypes["LocationsDocAccessFields_criteria_categories_Create"],
	read?:ResolverInputTypes["LocationsDocAccessFields_criteria_categories_Read"],
	update?:ResolverInputTypes["LocationsDocAccessFields_criteria_categories_Update"],
	delete?:ResolverInputTypes["LocationsDocAccessFields_criteria_categories_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields_criteria_categories_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields_criteria_categories_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields_criteria_categories_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields_criteria_categories_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields_criteria_tags"]: AliasType<{
	create?:ResolverInputTypes["LocationsDocAccessFields_criteria_tags_Create"],
	read?:ResolverInputTypes["LocationsDocAccessFields_criteria_tags_Read"],
	update?:ResolverInputTypes["LocationsDocAccessFields_criteria_tags_Update"],
	delete?:ResolverInputTypes["LocationsDocAccessFields_criteria_tags_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields_criteria_tags_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields_criteria_tags_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields_criteria_tags_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields_criteria_tags_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields_sections"]: AliasType<{
	create?:ResolverInputTypes["LocationsDocAccessFields_sections_Create"],
	read?:ResolverInputTypes["LocationsDocAccessFields_sections_Read"],
	update?:ResolverInputTypes["LocationsDocAccessFields_sections_Update"],
	delete?:ResolverInputTypes["LocationsDocAccessFields_sections_Delete"],
	fields?:ResolverInputTypes["LocationsDocAccessFields_sections_Fields"],
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields_sections_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields_sections_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields_sections_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields_sections_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields_sections_Fields"]: AliasType<{
	startDatetime?:ResolverInputTypes["LocationsDocAccessFields_sections_startDatetime"],
	endDatetime?:ResolverInputTypes["LocationsDocAccessFields_sections_endDatetime"],
	fullDay?:ResolverInputTypes["LocationsDocAccessFields_sections_fullDay"],
	repeat?:ResolverInputTypes["LocationsDocAccessFields_sections_repeat"],
	recurrance?:ResolverInputTypes["LocationsDocAccessFields_sections_recurrance"],
	id?:ResolverInputTypes["LocationsDocAccessFields_sections_id"],
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields_sections_startDatetime"]: AliasType<{
	create?:ResolverInputTypes["LocationsDocAccessFields_sections_startDatetime_Create"],
	read?:ResolverInputTypes["LocationsDocAccessFields_sections_startDatetime_Read"],
	update?:ResolverInputTypes["LocationsDocAccessFields_sections_startDatetime_Update"],
	delete?:ResolverInputTypes["LocationsDocAccessFields_sections_startDatetime_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields_sections_startDatetime_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields_sections_startDatetime_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields_sections_startDatetime_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields_sections_startDatetime_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields_sections_endDatetime"]: AliasType<{
	create?:ResolverInputTypes["LocationsDocAccessFields_sections_endDatetime_Create"],
	read?:ResolverInputTypes["LocationsDocAccessFields_sections_endDatetime_Read"],
	update?:ResolverInputTypes["LocationsDocAccessFields_sections_endDatetime_Update"],
	delete?:ResolverInputTypes["LocationsDocAccessFields_sections_endDatetime_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields_sections_endDatetime_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields_sections_endDatetime_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields_sections_endDatetime_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields_sections_endDatetime_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields_sections_fullDay"]: AliasType<{
	create?:ResolverInputTypes["LocationsDocAccessFields_sections_fullDay_Create"],
	read?:ResolverInputTypes["LocationsDocAccessFields_sections_fullDay_Read"],
	update?:ResolverInputTypes["LocationsDocAccessFields_sections_fullDay_Update"],
	delete?:ResolverInputTypes["LocationsDocAccessFields_sections_fullDay_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields_sections_fullDay_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields_sections_fullDay_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields_sections_fullDay_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields_sections_fullDay_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields_sections_repeat"]: AliasType<{
	create?:ResolverInputTypes["LocationsDocAccessFields_sections_repeat_Create"],
	read?:ResolverInputTypes["LocationsDocAccessFields_sections_repeat_Read"],
	update?:ResolverInputTypes["LocationsDocAccessFields_sections_repeat_Update"],
	delete?:ResolverInputTypes["LocationsDocAccessFields_sections_repeat_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields_sections_repeat_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields_sections_repeat_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields_sections_repeat_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields_sections_repeat_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields_sections_recurrance"]: AliasType<{
	create?:ResolverInputTypes["LocationsDocAccessFields_sections_recurrance_Create"],
	read?:ResolverInputTypes["LocationsDocAccessFields_sections_recurrance_Read"],
	update?:ResolverInputTypes["LocationsDocAccessFields_sections_recurrance_Update"],
	delete?:ResolverInputTypes["LocationsDocAccessFields_sections_recurrance_Delete"],
	fields?:ResolverInputTypes["LocationsDocAccessFields_sections_recurrance_Fields"],
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields_sections_recurrance_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields_sections_recurrance_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields_sections_recurrance_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields_sections_recurrance_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields_sections_recurrance_Fields"]: AliasType<{
	type?:ResolverInputTypes["LocationsDocAccessFields_sections_recurrance_type"],
	weekday?:ResolverInputTypes["LocationsDocAccessFields_sections_recurrance_weekday"],
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields_sections_recurrance_type"]: AliasType<{
	create?:ResolverInputTypes["LocationsDocAccessFields_sections_recurrance_type_Create"],
	read?:ResolverInputTypes["LocationsDocAccessFields_sections_recurrance_type_Read"],
	update?:ResolverInputTypes["LocationsDocAccessFields_sections_recurrance_type_Update"],
	delete?:ResolverInputTypes["LocationsDocAccessFields_sections_recurrance_type_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields_sections_recurrance_type_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields_sections_recurrance_type_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields_sections_recurrance_type_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields_sections_recurrance_type_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields_sections_recurrance_weekday"]: AliasType<{
	create?:ResolverInputTypes["LocationsDocAccessFields_sections_recurrance_weekday_Create"],
	read?:ResolverInputTypes["LocationsDocAccessFields_sections_recurrance_weekday_Read"],
	update?:ResolverInputTypes["LocationsDocAccessFields_sections_recurrance_weekday_Update"],
	delete?:ResolverInputTypes["LocationsDocAccessFields_sections_recurrance_weekday_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields_sections_recurrance_weekday_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields_sections_recurrance_weekday_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields_sections_recurrance_weekday_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields_sections_recurrance_weekday_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields_sections_id"]: AliasType<{
	create?:ResolverInputTypes["LocationsDocAccessFields_sections_id_Create"],
	read?:ResolverInputTypes["LocationsDocAccessFields_sections_id_Read"],
	update?:ResolverInputTypes["LocationsDocAccessFields_sections_id_Update"],
	delete?:ResolverInputTypes["LocationsDocAccessFields_sections_id_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields_sections_id_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields_sections_id_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields_sections_id_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields_sections_id_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields__title"]: AliasType<{
	create?:ResolverInputTypes["LocationsDocAccessFields__title_Create"],
	read?:ResolverInputTypes["LocationsDocAccessFields__title_Read"],
	update?:ResolverInputTypes["LocationsDocAccessFields__title_Update"],
	delete?:ResolverInputTypes["LocationsDocAccessFields__title_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields__title_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields__title_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields__title_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields__title_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields_legacyGallery"]: AliasType<{
	create?:ResolverInputTypes["LocationsDocAccessFields_legacyGallery_Create"],
	read?:ResolverInputTypes["LocationsDocAccessFields_legacyGallery_Read"],
	update?:ResolverInputTypes["LocationsDocAccessFields_legacyGallery_Update"],
	delete?:ResolverInputTypes["LocationsDocAccessFields_legacyGallery_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields_legacyGallery_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields_legacyGallery_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields_legacyGallery_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields_legacyGallery_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields_updatedAt"]: AliasType<{
	create?:ResolverInputTypes["LocationsDocAccessFields_updatedAt_Create"],
	read?:ResolverInputTypes["LocationsDocAccessFields_updatedAt_Read"],
	update?:ResolverInputTypes["LocationsDocAccessFields_updatedAt_Update"],
	delete?:ResolverInputTypes["LocationsDocAccessFields_updatedAt_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields_updatedAt_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields_updatedAt_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields_updatedAt_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields_updatedAt_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields_createdAt"]: AliasType<{
	create?:ResolverInputTypes["LocationsDocAccessFields_createdAt_Create"],
	read?:ResolverInputTypes["LocationsDocAccessFields_createdAt_Read"],
	update?:ResolverInputTypes["LocationsDocAccessFields_createdAt_Update"],
	delete?:ResolverInputTypes["LocationsDocAccessFields_createdAt_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields_createdAt_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields_createdAt_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields_createdAt_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsDocAccessFields_createdAt_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsCreateDocAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsReadDocAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsUpdateDocAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsDeleteDocAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["Post"]: AliasType<{
	id?:boolean | `@${string}`,
	title?:boolean | `@${string}`,
	region?:boolean | `@${string}`,
authur?: [{	locale?: ResolverInputTypes["LocaleInputType"] | undefined | null,	fallbackLocale?: ResolverInputTypes["FallbackLocaleInputType"] | undefined | null},ResolverInputTypes["User"]],
	publishDateTime?:boolean | `@${string}`,
	permalink?:boolean | `@${string}`,
	status?:boolean | `@${string}`,
	content?:ResolverInputTypes["Post_Content"],
	legacyContent?:boolean | `@${string}`,
	showLegacyContent?:boolean | `@${string}`,
blogCategories?: [{	locale?: ResolverInputTypes["LocaleInputType"] | undefined | null,	fallbackLocale?: ResolverInputTypes["FallbackLocaleInputType"] | undefined | null},ResolverInputTypes["BlogCategory"]],
thumbnail?: [{	locale?: ResolverInputTypes["LocaleInputType"] | undefined | null,	fallbackLocale?: ResolverInputTypes["FallbackLocaleInputType"] | undefined | null,	where?: ResolverInputTypes["Post_Thumbnail_where"] | undefined | null},ResolverInputTypes["Media"]],
banner?: [{	locale?: ResolverInputTypes["LocaleInputType"] | undefined | null,	fallbackLocale?: ResolverInputTypes["FallbackLocaleInputType"] | undefined | null,	where?: ResolverInputTypes["Post_Banner_where"] | undefined | null},ResolverInputTypes["Media"]],
	criteria?:ResolverInputTypes["Post_Criteria"],
location?: [{	locale?: ResolverInputTypes["LocaleInputType"] | undefined | null,	fallbackLocale?: ResolverInputTypes["FallbackLocaleInputType"] | undefined | null},ResolverInputTypes["Location"]],
	views?:boolean | `@${string}`,
	homePageInternalClick?:boolean | `@${string}`,
	homePageImpression?:boolean | `@${string}`,
	blogListInternalClick?:boolean | `@${string}`,
	blogListImpression?:boolean | `@${string}`,
	blogDetailInternalClick?:boolean | `@${string}`,
	blogDetailImpression?:boolean | `@${string}`,
	relatedEventInternalClick?:boolean | `@${string}`,
	relatedEventImpression?:boolean | `@${string}`,
	latestEventInternalClick?:boolean | `@${string}`,
	latestEventImpression?:boolean | `@${string}`,
	internalClick?:boolean | `@${string}`,
	totalImpression?:boolean | `@${string}`,
	externalClick?:boolean | `@${string}`,
	outboundClick?:boolean | `@${string}`,
legacyGallery?: [{	locale?: ResolverInputTypes["LocaleInputType"] | undefined | null,	fallbackLocale?: ResolverInputTypes["FallbackLocaleInputType"] | undefined | null},ResolverInputTypes["Media"]],
	updatedAt?:boolean | `@${string}`,
	createdAt?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["Post_region"]:Post_region;
	["Post_Content"]: AliasType<{
	RichText?:ResolverInputTypes["RichText"],
	Gallery?:ResolverInputTypes["Gallery"],
	Image?:ResolverInputTypes["Image"],
	Video?:ResolverInputTypes["Video"],
		__typename?: boolean | `@${string}`
}>;
	["BlogCategory"]: AliasType<{
	id?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	value?:boolean | `@${string}`,
icon?: [{	locale?: ResolverInputTypes["LocaleInputType"] | undefined | null,	fallbackLocale?: ResolverInputTypes["FallbackLocaleInputType"] | undefined | null,	where?: ResolverInputTypes["BlogCategory_Icon_where"] | undefined | null},ResolverInputTypes["Media"]],
	region?:boolean | `@${string}`,
	updatedAt?:boolean | `@${string}`,
	createdAt?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BlogCategory_Icon_where"]: {
	alt?: ResolverInputTypes["BlogCategory_Icon_alt_operator"] | undefined | null,
	updatedAt?: ResolverInputTypes["BlogCategory_Icon_updatedAt_operator"] | undefined | null,
	createdAt?: ResolverInputTypes["BlogCategory_Icon_createdAt_operator"] | undefined | null,
	url?: ResolverInputTypes["BlogCategory_Icon_url_operator"] | undefined | null,
	filename?: ResolverInputTypes["BlogCategory_Icon_filename_operator"] | undefined | null,
	mimeType?: ResolverInputTypes["BlogCategory_Icon_mimeType_operator"] | undefined | null,
	filesize?: ResolverInputTypes["BlogCategory_Icon_filesize_operator"] | undefined | null,
	width?: ResolverInputTypes["BlogCategory_Icon_width_operator"] | undefined | null,
	height?: ResolverInputTypes["BlogCategory_Icon_height_operator"] | undefined | null,
	focalX?: ResolverInputTypes["BlogCategory_Icon_focalX_operator"] | undefined | null,
	focalY?: ResolverInputTypes["BlogCategory_Icon_focalY_operator"] | undefined | null,
	sizes__small__url?: ResolverInputTypes["BlogCategory_Icon_sizes__small__url_operator"] | undefined | null,
	sizes__small__width?: ResolverInputTypes["BlogCategory_Icon_sizes__small__width_operator"] | undefined | null,
	sizes__small__height?: ResolverInputTypes["BlogCategory_Icon_sizes__small__height_operator"] | undefined | null,
	sizes__small__mimeType?: ResolverInputTypes["BlogCategory_Icon_sizes__small__mimeType_operator"] | undefined | null,
	sizes__small__filesize?: ResolverInputTypes["BlogCategory_Icon_sizes__small__filesize_operator"] | undefined | null,
	sizes__small__filename?: ResolverInputTypes["BlogCategory_Icon_sizes__small__filename_operator"] | undefined | null,
	sizes__medium__url?: ResolverInputTypes["BlogCategory_Icon_sizes__medium__url_operator"] | undefined | null,
	sizes__medium__width?: ResolverInputTypes["BlogCategory_Icon_sizes__medium__width_operator"] | undefined | null,
	sizes__medium__height?: ResolverInputTypes["BlogCategory_Icon_sizes__medium__height_operator"] | undefined | null,
	sizes__medium__mimeType?: ResolverInputTypes["BlogCategory_Icon_sizes__medium__mimeType_operator"] | undefined | null,
	sizes__medium__filesize?: ResolverInputTypes["BlogCategory_Icon_sizes__medium__filesize_operator"] | undefined | null,
	sizes__medium__filename?: ResolverInputTypes["BlogCategory_Icon_sizes__medium__filename_operator"] | undefined | null,
	sizes__large__url?: ResolverInputTypes["BlogCategory_Icon_sizes__large__url_operator"] | undefined | null,
	sizes__large__width?: ResolverInputTypes["BlogCategory_Icon_sizes__large__width_operator"] | undefined | null,
	sizes__large__height?: ResolverInputTypes["BlogCategory_Icon_sizes__large__height_operator"] | undefined | null,
	sizes__large__mimeType?: ResolverInputTypes["BlogCategory_Icon_sizes__large__mimeType_operator"] | undefined | null,
	sizes__large__filesize?: ResolverInputTypes["BlogCategory_Icon_sizes__large__filesize_operator"] | undefined | null,
	sizes__large__filename?: ResolverInputTypes["BlogCategory_Icon_sizes__large__filename_operator"] | undefined | null,
	sizes__small_jpeg__url?: ResolverInputTypes["BlogCategory_Icon_sizes__small_jpeg__url_operator"] | undefined | null,
	sizes__small_jpeg__width?: ResolverInputTypes["BlogCategory_Icon_sizes__small_jpeg__width_operator"] | undefined | null,
	sizes__small_jpeg__height?: ResolverInputTypes["BlogCategory_Icon_sizes__small_jpeg__height_operator"] | undefined | null,
	sizes__small_jpeg__mimeType?: ResolverInputTypes["BlogCategory_Icon_sizes__small_jpeg__mimeType_operator"] | undefined | null,
	sizes__small_jpeg__filesize?: ResolverInputTypes["BlogCategory_Icon_sizes__small_jpeg__filesize_operator"] | undefined | null,
	sizes__small_jpeg__filename?: ResolverInputTypes["BlogCategory_Icon_sizes__small_jpeg__filename_operator"] | undefined | null,
	sizes__medium_jpeg__url?: ResolverInputTypes["BlogCategory_Icon_sizes__medium_jpeg__url_operator"] | undefined | null,
	sizes__medium_jpeg__width?: ResolverInputTypes["BlogCategory_Icon_sizes__medium_jpeg__width_operator"] | undefined | null,
	sizes__medium_jpeg__height?: ResolverInputTypes["BlogCategory_Icon_sizes__medium_jpeg__height_operator"] | undefined | null,
	sizes__medium_jpeg__mimeType?: ResolverInputTypes["BlogCategory_Icon_sizes__medium_jpeg__mimeType_operator"] | undefined | null,
	sizes__medium_jpeg__filesize?: ResolverInputTypes["BlogCategory_Icon_sizes__medium_jpeg__filesize_operator"] | undefined | null,
	sizes__medium_jpeg__filename?: ResolverInputTypes["BlogCategory_Icon_sizes__medium_jpeg__filename_operator"] | undefined | null,
	sizes__large_jpeg__url?: ResolverInputTypes["BlogCategory_Icon_sizes__large_jpeg__url_operator"] | undefined | null,
	sizes__large_jpeg__width?: ResolverInputTypes["BlogCategory_Icon_sizes__large_jpeg__width_operator"] | undefined | null,
	sizes__large_jpeg__height?: ResolverInputTypes["BlogCategory_Icon_sizes__large_jpeg__height_operator"] | undefined | null,
	sizes__large_jpeg__mimeType?: ResolverInputTypes["BlogCategory_Icon_sizes__large_jpeg__mimeType_operator"] | undefined | null,
	sizes__large_jpeg__filesize?: ResolverInputTypes["BlogCategory_Icon_sizes__large_jpeg__filesize_operator"] | undefined | null,
	sizes__large_jpeg__filename?: ResolverInputTypes["BlogCategory_Icon_sizes__large_jpeg__filename_operator"] | undefined | null,
	id?: ResolverInputTypes["BlogCategory_Icon_id_operator"] | undefined | null,
	AND?: Array<ResolverInputTypes["BlogCategory_Icon_where_and"] | undefined | null> | undefined | null,
	OR?: Array<ResolverInputTypes["BlogCategory_Icon_where_or"] | undefined | null> | undefined | null
};
	["BlogCategory_Icon_alt_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["BlogCategory_Icon_updatedAt_operator"]: {
	equals?: ResolverInputTypes["DateTime"] | undefined | null,
	not_equals?: ResolverInputTypes["DateTime"] | undefined | null,
	greater_than_equal?: ResolverInputTypes["DateTime"] | undefined | null,
	greater_than?: ResolverInputTypes["DateTime"] | undefined | null,
	less_than_equal?: ResolverInputTypes["DateTime"] | undefined | null,
	less_than?: ResolverInputTypes["DateTime"] | undefined | null,
	like?: ResolverInputTypes["DateTime"] | undefined | null,
	exists?: boolean | undefined | null
};
	["BlogCategory_Icon_createdAt_operator"]: {
	equals?: ResolverInputTypes["DateTime"] | undefined | null,
	not_equals?: ResolverInputTypes["DateTime"] | undefined | null,
	greater_than_equal?: ResolverInputTypes["DateTime"] | undefined | null,
	greater_than?: ResolverInputTypes["DateTime"] | undefined | null,
	less_than_equal?: ResolverInputTypes["DateTime"] | undefined | null,
	less_than?: ResolverInputTypes["DateTime"] | undefined | null,
	like?: ResolverInputTypes["DateTime"] | undefined | null,
	exists?: boolean | undefined | null
};
	["BlogCategory_Icon_url_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["BlogCategory_Icon_filename_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["BlogCategory_Icon_mimeType_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["BlogCategory_Icon_filesize_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["BlogCategory_Icon_width_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["BlogCategory_Icon_height_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["BlogCategory_Icon_focalX_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["BlogCategory_Icon_focalY_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["BlogCategory_Icon_sizes__small__url_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["BlogCategory_Icon_sizes__small__width_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["BlogCategory_Icon_sizes__small__height_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["BlogCategory_Icon_sizes__small__mimeType_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["BlogCategory_Icon_sizes__small__filesize_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["BlogCategory_Icon_sizes__small__filename_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["BlogCategory_Icon_sizes__medium__url_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["BlogCategory_Icon_sizes__medium__width_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["BlogCategory_Icon_sizes__medium__height_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["BlogCategory_Icon_sizes__medium__mimeType_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["BlogCategory_Icon_sizes__medium__filesize_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["BlogCategory_Icon_sizes__medium__filename_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["BlogCategory_Icon_sizes__large__url_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["BlogCategory_Icon_sizes__large__width_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["BlogCategory_Icon_sizes__large__height_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["BlogCategory_Icon_sizes__large__mimeType_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["BlogCategory_Icon_sizes__large__filesize_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["BlogCategory_Icon_sizes__large__filename_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["BlogCategory_Icon_sizes__small_jpeg__url_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["BlogCategory_Icon_sizes__small_jpeg__width_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["BlogCategory_Icon_sizes__small_jpeg__height_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["BlogCategory_Icon_sizes__small_jpeg__mimeType_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["BlogCategory_Icon_sizes__small_jpeg__filesize_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["BlogCategory_Icon_sizes__small_jpeg__filename_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["BlogCategory_Icon_sizes__medium_jpeg__url_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["BlogCategory_Icon_sizes__medium_jpeg__width_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["BlogCategory_Icon_sizes__medium_jpeg__height_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["BlogCategory_Icon_sizes__medium_jpeg__mimeType_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["BlogCategory_Icon_sizes__medium_jpeg__filesize_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["BlogCategory_Icon_sizes__medium_jpeg__filename_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["BlogCategory_Icon_sizes__large_jpeg__url_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["BlogCategory_Icon_sizes__large_jpeg__width_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["BlogCategory_Icon_sizes__large_jpeg__height_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["BlogCategory_Icon_sizes__large_jpeg__mimeType_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["BlogCategory_Icon_sizes__large_jpeg__filesize_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["BlogCategory_Icon_sizes__large_jpeg__filename_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["BlogCategory_Icon_id_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["BlogCategory_Icon_where_and"]: {
	alt?: ResolverInputTypes["BlogCategory_Icon_alt_operator"] | undefined | null,
	updatedAt?: ResolverInputTypes["BlogCategory_Icon_updatedAt_operator"] | undefined | null,
	createdAt?: ResolverInputTypes["BlogCategory_Icon_createdAt_operator"] | undefined | null,
	url?: ResolverInputTypes["BlogCategory_Icon_url_operator"] | undefined | null,
	filename?: ResolverInputTypes["BlogCategory_Icon_filename_operator"] | undefined | null,
	mimeType?: ResolverInputTypes["BlogCategory_Icon_mimeType_operator"] | undefined | null,
	filesize?: ResolverInputTypes["BlogCategory_Icon_filesize_operator"] | undefined | null,
	width?: ResolverInputTypes["BlogCategory_Icon_width_operator"] | undefined | null,
	height?: ResolverInputTypes["BlogCategory_Icon_height_operator"] | undefined | null,
	focalX?: ResolverInputTypes["BlogCategory_Icon_focalX_operator"] | undefined | null,
	focalY?: ResolverInputTypes["BlogCategory_Icon_focalY_operator"] | undefined | null,
	sizes__small__url?: ResolverInputTypes["BlogCategory_Icon_sizes__small__url_operator"] | undefined | null,
	sizes__small__width?: ResolverInputTypes["BlogCategory_Icon_sizes__small__width_operator"] | undefined | null,
	sizes__small__height?: ResolverInputTypes["BlogCategory_Icon_sizes__small__height_operator"] | undefined | null,
	sizes__small__mimeType?: ResolverInputTypes["BlogCategory_Icon_sizes__small__mimeType_operator"] | undefined | null,
	sizes__small__filesize?: ResolverInputTypes["BlogCategory_Icon_sizes__small__filesize_operator"] | undefined | null,
	sizes__small__filename?: ResolverInputTypes["BlogCategory_Icon_sizes__small__filename_operator"] | undefined | null,
	sizes__medium__url?: ResolverInputTypes["BlogCategory_Icon_sizes__medium__url_operator"] | undefined | null,
	sizes__medium__width?: ResolverInputTypes["BlogCategory_Icon_sizes__medium__width_operator"] | undefined | null,
	sizes__medium__height?: ResolverInputTypes["BlogCategory_Icon_sizes__medium__height_operator"] | undefined | null,
	sizes__medium__mimeType?: ResolverInputTypes["BlogCategory_Icon_sizes__medium__mimeType_operator"] | undefined | null,
	sizes__medium__filesize?: ResolverInputTypes["BlogCategory_Icon_sizes__medium__filesize_operator"] | undefined | null,
	sizes__medium__filename?: ResolverInputTypes["BlogCategory_Icon_sizes__medium__filename_operator"] | undefined | null,
	sizes__large__url?: ResolverInputTypes["BlogCategory_Icon_sizes__large__url_operator"] | undefined | null,
	sizes__large__width?: ResolverInputTypes["BlogCategory_Icon_sizes__large__width_operator"] | undefined | null,
	sizes__large__height?: ResolverInputTypes["BlogCategory_Icon_sizes__large__height_operator"] | undefined | null,
	sizes__large__mimeType?: ResolverInputTypes["BlogCategory_Icon_sizes__large__mimeType_operator"] | undefined | null,
	sizes__large__filesize?: ResolverInputTypes["BlogCategory_Icon_sizes__large__filesize_operator"] | undefined | null,
	sizes__large__filename?: ResolverInputTypes["BlogCategory_Icon_sizes__large__filename_operator"] | undefined | null,
	sizes__small_jpeg__url?: ResolverInputTypes["BlogCategory_Icon_sizes__small_jpeg__url_operator"] | undefined | null,
	sizes__small_jpeg__width?: ResolverInputTypes["BlogCategory_Icon_sizes__small_jpeg__width_operator"] | undefined | null,
	sizes__small_jpeg__height?: ResolverInputTypes["BlogCategory_Icon_sizes__small_jpeg__height_operator"] | undefined | null,
	sizes__small_jpeg__mimeType?: ResolverInputTypes["BlogCategory_Icon_sizes__small_jpeg__mimeType_operator"] | undefined | null,
	sizes__small_jpeg__filesize?: ResolverInputTypes["BlogCategory_Icon_sizes__small_jpeg__filesize_operator"] | undefined | null,
	sizes__small_jpeg__filename?: ResolverInputTypes["BlogCategory_Icon_sizes__small_jpeg__filename_operator"] | undefined | null,
	sizes__medium_jpeg__url?: ResolverInputTypes["BlogCategory_Icon_sizes__medium_jpeg__url_operator"] | undefined | null,
	sizes__medium_jpeg__width?: ResolverInputTypes["BlogCategory_Icon_sizes__medium_jpeg__width_operator"] | undefined | null,
	sizes__medium_jpeg__height?: ResolverInputTypes["BlogCategory_Icon_sizes__medium_jpeg__height_operator"] | undefined | null,
	sizes__medium_jpeg__mimeType?: ResolverInputTypes["BlogCategory_Icon_sizes__medium_jpeg__mimeType_operator"] | undefined | null,
	sizes__medium_jpeg__filesize?: ResolverInputTypes["BlogCategory_Icon_sizes__medium_jpeg__filesize_operator"] | undefined | null,
	sizes__medium_jpeg__filename?: ResolverInputTypes["BlogCategory_Icon_sizes__medium_jpeg__filename_operator"] | undefined | null,
	sizes__large_jpeg__url?: ResolverInputTypes["BlogCategory_Icon_sizes__large_jpeg__url_operator"] | undefined | null,
	sizes__large_jpeg__width?: ResolverInputTypes["BlogCategory_Icon_sizes__large_jpeg__width_operator"] | undefined | null,
	sizes__large_jpeg__height?: ResolverInputTypes["BlogCategory_Icon_sizes__large_jpeg__height_operator"] | undefined | null,
	sizes__large_jpeg__mimeType?: ResolverInputTypes["BlogCategory_Icon_sizes__large_jpeg__mimeType_operator"] | undefined | null,
	sizes__large_jpeg__filesize?: ResolverInputTypes["BlogCategory_Icon_sizes__large_jpeg__filesize_operator"] | undefined | null,
	sizes__large_jpeg__filename?: ResolverInputTypes["BlogCategory_Icon_sizes__large_jpeg__filename_operator"] | undefined | null,
	id?: ResolverInputTypes["BlogCategory_Icon_id_operator"] | undefined | null,
	AND?: Array<ResolverInputTypes["BlogCategory_Icon_where_and"] | undefined | null> | undefined | null,
	OR?: Array<ResolverInputTypes["BlogCategory_Icon_where_or"] | undefined | null> | undefined | null
};
	["BlogCategory_Icon_where_or"]: {
	alt?: ResolverInputTypes["BlogCategory_Icon_alt_operator"] | undefined | null,
	updatedAt?: ResolverInputTypes["BlogCategory_Icon_updatedAt_operator"] | undefined | null,
	createdAt?: ResolverInputTypes["BlogCategory_Icon_createdAt_operator"] | undefined | null,
	url?: ResolverInputTypes["BlogCategory_Icon_url_operator"] | undefined | null,
	filename?: ResolverInputTypes["BlogCategory_Icon_filename_operator"] | undefined | null,
	mimeType?: ResolverInputTypes["BlogCategory_Icon_mimeType_operator"] | undefined | null,
	filesize?: ResolverInputTypes["BlogCategory_Icon_filesize_operator"] | undefined | null,
	width?: ResolverInputTypes["BlogCategory_Icon_width_operator"] | undefined | null,
	height?: ResolverInputTypes["BlogCategory_Icon_height_operator"] | undefined | null,
	focalX?: ResolverInputTypes["BlogCategory_Icon_focalX_operator"] | undefined | null,
	focalY?: ResolverInputTypes["BlogCategory_Icon_focalY_operator"] | undefined | null,
	sizes__small__url?: ResolverInputTypes["BlogCategory_Icon_sizes__small__url_operator"] | undefined | null,
	sizes__small__width?: ResolverInputTypes["BlogCategory_Icon_sizes__small__width_operator"] | undefined | null,
	sizes__small__height?: ResolverInputTypes["BlogCategory_Icon_sizes__small__height_operator"] | undefined | null,
	sizes__small__mimeType?: ResolverInputTypes["BlogCategory_Icon_sizes__small__mimeType_operator"] | undefined | null,
	sizes__small__filesize?: ResolverInputTypes["BlogCategory_Icon_sizes__small__filesize_operator"] | undefined | null,
	sizes__small__filename?: ResolverInputTypes["BlogCategory_Icon_sizes__small__filename_operator"] | undefined | null,
	sizes__medium__url?: ResolverInputTypes["BlogCategory_Icon_sizes__medium__url_operator"] | undefined | null,
	sizes__medium__width?: ResolverInputTypes["BlogCategory_Icon_sizes__medium__width_operator"] | undefined | null,
	sizes__medium__height?: ResolverInputTypes["BlogCategory_Icon_sizes__medium__height_operator"] | undefined | null,
	sizes__medium__mimeType?: ResolverInputTypes["BlogCategory_Icon_sizes__medium__mimeType_operator"] | undefined | null,
	sizes__medium__filesize?: ResolverInputTypes["BlogCategory_Icon_sizes__medium__filesize_operator"] | undefined | null,
	sizes__medium__filename?: ResolverInputTypes["BlogCategory_Icon_sizes__medium__filename_operator"] | undefined | null,
	sizes__large__url?: ResolverInputTypes["BlogCategory_Icon_sizes__large__url_operator"] | undefined | null,
	sizes__large__width?: ResolverInputTypes["BlogCategory_Icon_sizes__large__width_operator"] | undefined | null,
	sizes__large__height?: ResolverInputTypes["BlogCategory_Icon_sizes__large__height_operator"] | undefined | null,
	sizes__large__mimeType?: ResolverInputTypes["BlogCategory_Icon_sizes__large__mimeType_operator"] | undefined | null,
	sizes__large__filesize?: ResolverInputTypes["BlogCategory_Icon_sizes__large__filesize_operator"] | undefined | null,
	sizes__large__filename?: ResolverInputTypes["BlogCategory_Icon_sizes__large__filename_operator"] | undefined | null,
	sizes__small_jpeg__url?: ResolverInputTypes["BlogCategory_Icon_sizes__small_jpeg__url_operator"] | undefined | null,
	sizes__small_jpeg__width?: ResolverInputTypes["BlogCategory_Icon_sizes__small_jpeg__width_operator"] | undefined | null,
	sizes__small_jpeg__height?: ResolverInputTypes["BlogCategory_Icon_sizes__small_jpeg__height_operator"] | undefined | null,
	sizes__small_jpeg__mimeType?: ResolverInputTypes["BlogCategory_Icon_sizes__small_jpeg__mimeType_operator"] | undefined | null,
	sizes__small_jpeg__filesize?: ResolverInputTypes["BlogCategory_Icon_sizes__small_jpeg__filesize_operator"] | undefined | null,
	sizes__small_jpeg__filename?: ResolverInputTypes["BlogCategory_Icon_sizes__small_jpeg__filename_operator"] | undefined | null,
	sizes__medium_jpeg__url?: ResolverInputTypes["BlogCategory_Icon_sizes__medium_jpeg__url_operator"] | undefined | null,
	sizes__medium_jpeg__width?: ResolverInputTypes["BlogCategory_Icon_sizes__medium_jpeg__width_operator"] | undefined | null,
	sizes__medium_jpeg__height?: ResolverInputTypes["BlogCategory_Icon_sizes__medium_jpeg__height_operator"] | undefined | null,
	sizes__medium_jpeg__mimeType?: ResolverInputTypes["BlogCategory_Icon_sizes__medium_jpeg__mimeType_operator"] | undefined | null,
	sizes__medium_jpeg__filesize?: ResolverInputTypes["BlogCategory_Icon_sizes__medium_jpeg__filesize_operator"] | undefined | null,
	sizes__medium_jpeg__filename?: ResolverInputTypes["BlogCategory_Icon_sizes__medium_jpeg__filename_operator"] | undefined | null,
	sizes__large_jpeg__url?: ResolverInputTypes["BlogCategory_Icon_sizes__large_jpeg__url_operator"] | undefined | null,
	sizes__large_jpeg__width?: ResolverInputTypes["BlogCategory_Icon_sizes__large_jpeg__width_operator"] | undefined | null,
	sizes__large_jpeg__height?: ResolverInputTypes["BlogCategory_Icon_sizes__large_jpeg__height_operator"] | undefined | null,
	sizes__large_jpeg__mimeType?: ResolverInputTypes["BlogCategory_Icon_sizes__large_jpeg__mimeType_operator"] | undefined | null,
	sizes__large_jpeg__filesize?: ResolverInputTypes["BlogCategory_Icon_sizes__large_jpeg__filesize_operator"] | undefined | null,
	sizes__large_jpeg__filename?: ResolverInputTypes["BlogCategory_Icon_sizes__large_jpeg__filename_operator"] | undefined | null,
	id?: ResolverInputTypes["BlogCategory_Icon_id_operator"] | undefined | null,
	AND?: Array<ResolverInputTypes["BlogCategory_Icon_where_and"] | undefined | null> | undefined | null,
	OR?: Array<ResolverInputTypes["BlogCategory_Icon_where_or"] | undefined | null> | undefined | null
};
	["BlogCategory_region"]:BlogCategory_region;
	["Post_Thumbnail_where"]: {
	alt?: ResolverInputTypes["Post_Thumbnail_alt_operator"] | undefined | null,
	updatedAt?: ResolverInputTypes["Post_Thumbnail_updatedAt_operator"] | undefined | null,
	createdAt?: ResolverInputTypes["Post_Thumbnail_createdAt_operator"] | undefined | null,
	url?: ResolverInputTypes["Post_Thumbnail_url_operator"] | undefined | null,
	filename?: ResolverInputTypes["Post_Thumbnail_filename_operator"] | undefined | null,
	mimeType?: ResolverInputTypes["Post_Thumbnail_mimeType_operator"] | undefined | null,
	filesize?: ResolverInputTypes["Post_Thumbnail_filesize_operator"] | undefined | null,
	width?: ResolverInputTypes["Post_Thumbnail_width_operator"] | undefined | null,
	height?: ResolverInputTypes["Post_Thumbnail_height_operator"] | undefined | null,
	focalX?: ResolverInputTypes["Post_Thumbnail_focalX_operator"] | undefined | null,
	focalY?: ResolverInputTypes["Post_Thumbnail_focalY_operator"] | undefined | null,
	sizes__small__url?: ResolverInputTypes["Post_Thumbnail_sizes__small__url_operator"] | undefined | null,
	sizes__small__width?: ResolverInputTypes["Post_Thumbnail_sizes__small__width_operator"] | undefined | null,
	sizes__small__height?: ResolverInputTypes["Post_Thumbnail_sizes__small__height_operator"] | undefined | null,
	sizes__small__mimeType?: ResolverInputTypes["Post_Thumbnail_sizes__small__mimeType_operator"] | undefined | null,
	sizes__small__filesize?: ResolverInputTypes["Post_Thumbnail_sizes__small__filesize_operator"] | undefined | null,
	sizes__small__filename?: ResolverInputTypes["Post_Thumbnail_sizes__small__filename_operator"] | undefined | null,
	sizes__medium__url?: ResolverInputTypes["Post_Thumbnail_sizes__medium__url_operator"] | undefined | null,
	sizes__medium__width?: ResolverInputTypes["Post_Thumbnail_sizes__medium__width_operator"] | undefined | null,
	sizes__medium__height?: ResolverInputTypes["Post_Thumbnail_sizes__medium__height_operator"] | undefined | null,
	sizes__medium__mimeType?: ResolverInputTypes["Post_Thumbnail_sizes__medium__mimeType_operator"] | undefined | null,
	sizes__medium__filesize?: ResolverInputTypes["Post_Thumbnail_sizes__medium__filesize_operator"] | undefined | null,
	sizes__medium__filename?: ResolverInputTypes["Post_Thumbnail_sizes__medium__filename_operator"] | undefined | null,
	sizes__large__url?: ResolverInputTypes["Post_Thumbnail_sizes__large__url_operator"] | undefined | null,
	sizes__large__width?: ResolverInputTypes["Post_Thumbnail_sizes__large__width_operator"] | undefined | null,
	sizes__large__height?: ResolverInputTypes["Post_Thumbnail_sizes__large__height_operator"] | undefined | null,
	sizes__large__mimeType?: ResolverInputTypes["Post_Thumbnail_sizes__large__mimeType_operator"] | undefined | null,
	sizes__large__filesize?: ResolverInputTypes["Post_Thumbnail_sizes__large__filesize_operator"] | undefined | null,
	sizes__large__filename?: ResolverInputTypes["Post_Thumbnail_sizes__large__filename_operator"] | undefined | null,
	sizes__small_jpeg__url?: ResolverInputTypes["Post_Thumbnail_sizes__small_jpeg__url_operator"] | undefined | null,
	sizes__small_jpeg__width?: ResolverInputTypes["Post_Thumbnail_sizes__small_jpeg__width_operator"] | undefined | null,
	sizes__small_jpeg__height?: ResolverInputTypes["Post_Thumbnail_sizes__small_jpeg__height_operator"] | undefined | null,
	sizes__small_jpeg__mimeType?: ResolverInputTypes["Post_Thumbnail_sizes__small_jpeg__mimeType_operator"] | undefined | null,
	sizes__small_jpeg__filesize?: ResolverInputTypes["Post_Thumbnail_sizes__small_jpeg__filesize_operator"] | undefined | null,
	sizes__small_jpeg__filename?: ResolverInputTypes["Post_Thumbnail_sizes__small_jpeg__filename_operator"] | undefined | null,
	sizes__medium_jpeg__url?: ResolverInputTypes["Post_Thumbnail_sizes__medium_jpeg__url_operator"] | undefined | null,
	sizes__medium_jpeg__width?: ResolverInputTypes["Post_Thumbnail_sizes__medium_jpeg__width_operator"] | undefined | null,
	sizes__medium_jpeg__height?: ResolverInputTypes["Post_Thumbnail_sizes__medium_jpeg__height_operator"] | undefined | null,
	sizes__medium_jpeg__mimeType?: ResolverInputTypes["Post_Thumbnail_sizes__medium_jpeg__mimeType_operator"] | undefined | null,
	sizes__medium_jpeg__filesize?: ResolverInputTypes["Post_Thumbnail_sizes__medium_jpeg__filesize_operator"] | undefined | null,
	sizes__medium_jpeg__filename?: ResolverInputTypes["Post_Thumbnail_sizes__medium_jpeg__filename_operator"] | undefined | null,
	sizes__large_jpeg__url?: ResolverInputTypes["Post_Thumbnail_sizes__large_jpeg__url_operator"] | undefined | null,
	sizes__large_jpeg__width?: ResolverInputTypes["Post_Thumbnail_sizes__large_jpeg__width_operator"] | undefined | null,
	sizes__large_jpeg__height?: ResolverInputTypes["Post_Thumbnail_sizes__large_jpeg__height_operator"] | undefined | null,
	sizes__large_jpeg__mimeType?: ResolverInputTypes["Post_Thumbnail_sizes__large_jpeg__mimeType_operator"] | undefined | null,
	sizes__large_jpeg__filesize?: ResolverInputTypes["Post_Thumbnail_sizes__large_jpeg__filesize_operator"] | undefined | null,
	sizes__large_jpeg__filename?: ResolverInputTypes["Post_Thumbnail_sizes__large_jpeg__filename_operator"] | undefined | null,
	id?: ResolverInputTypes["Post_Thumbnail_id_operator"] | undefined | null,
	AND?: Array<ResolverInputTypes["Post_Thumbnail_where_and"] | undefined | null> | undefined | null,
	OR?: Array<ResolverInputTypes["Post_Thumbnail_where_or"] | undefined | null> | undefined | null
};
	["Post_Thumbnail_alt_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Post_Thumbnail_updatedAt_operator"]: {
	equals?: ResolverInputTypes["DateTime"] | undefined | null,
	not_equals?: ResolverInputTypes["DateTime"] | undefined | null,
	greater_than_equal?: ResolverInputTypes["DateTime"] | undefined | null,
	greater_than?: ResolverInputTypes["DateTime"] | undefined | null,
	less_than_equal?: ResolverInputTypes["DateTime"] | undefined | null,
	less_than?: ResolverInputTypes["DateTime"] | undefined | null,
	like?: ResolverInputTypes["DateTime"] | undefined | null,
	exists?: boolean | undefined | null
};
	["Post_Thumbnail_createdAt_operator"]: {
	equals?: ResolverInputTypes["DateTime"] | undefined | null,
	not_equals?: ResolverInputTypes["DateTime"] | undefined | null,
	greater_than_equal?: ResolverInputTypes["DateTime"] | undefined | null,
	greater_than?: ResolverInputTypes["DateTime"] | undefined | null,
	less_than_equal?: ResolverInputTypes["DateTime"] | undefined | null,
	less_than?: ResolverInputTypes["DateTime"] | undefined | null,
	like?: ResolverInputTypes["DateTime"] | undefined | null,
	exists?: boolean | undefined | null
};
	["Post_Thumbnail_url_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Post_Thumbnail_filename_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Post_Thumbnail_mimeType_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Post_Thumbnail_filesize_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Post_Thumbnail_width_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Post_Thumbnail_height_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Post_Thumbnail_focalX_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Post_Thumbnail_focalY_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Post_Thumbnail_sizes__small__url_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Post_Thumbnail_sizes__small__width_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Post_Thumbnail_sizes__small__height_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Post_Thumbnail_sizes__small__mimeType_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Post_Thumbnail_sizes__small__filesize_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Post_Thumbnail_sizes__small__filename_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Post_Thumbnail_sizes__medium__url_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Post_Thumbnail_sizes__medium__width_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Post_Thumbnail_sizes__medium__height_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Post_Thumbnail_sizes__medium__mimeType_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Post_Thumbnail_sizes__medium__filesize_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Post_Thumbnail_sizes__medium__filename_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Post_Thumbnail_sizes__large__url_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Post_Thumbnail_sizes__large__width_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Post_Thumbnail_sizes__large__height_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Post_Thumbnail_sizes__large__mimeType_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Post_Thumbnail_sizes__large__filesize_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Post_Thumbnail_sizes__large__filename_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Post_Thumbnail_sizes__small_jpeg__url_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Post_Thumbnail_sizes__small_jpeg__width_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Post_Thumbnail_sizes__small_jpeg__height_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Post_Thumbnail_sizes__small_jpeg__mimeType_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Post_Thumbnail_sizes__small_jpeg__filesize_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Post_Thumbnail_sizes__small_jpeg__filename_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Post_Thumbnail_sizes__medium_jpeg__url_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Post_Thumbnail_sizes__medium_jpeg__width_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Post_Thumbnail_sizes__medium_jpeg__height_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Post_Thumbnail_sizes__medium_jpeg__mimeType_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Post_Thumbnail_sizes__medium_jpeg__filesize_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Post_Thumbnail_sizes__medium_jpeg__filename_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Post_Thumbnail_sizes__large_jpeg__url_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Post_Thumbnail_sizes__large_jpeg__width_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Post_Thumbnail_sizes__large_jpeg__height_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Post_Thumbnail_sizes__large_jpeg__mimeType_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Post_Thumbnail_sizes__large_jpeg__filesize_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Post_Thumbnail_sizes__large_jpeg__filename_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Post_Thumbnail_id_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Post_Thumbnail_where_and"]: {
	alt?: ResolverInputTypes["Post_Thumbnail_alt_operator"] | undefined | null,
	updatedAt?: ResolverInputTypes["Post_Thumbnail_updatedAt_operator"] | undefined | null,
	createdAt?: ResolverInputTypes["Post_Thumbnail_createdAt_operator"] | undefined | null,
	url?: ResolverInputTypes["Post_Thumbnail_url_operator"] | undefined | null,
	filename?: ResolverInputTypes["Post_Thumbnail_filename_operator"] | undefined | null,
	mimeType?: ResolverInputTypes["Post_Thumbnail_mimeType_operator"] | undefined | null,
	filesize?: ResolverInputTypes["Post_Thumbnail_filesize_operator"] | undefined | null,
	width?: ResolverInputTypes["Post_Thumbnail_width_operator"] | undefined | null,
	height?: ResolverInputTypes["Post_Thumbnail_height_operator"] | undefined | null,
	focalX?: ResolverInputTypes["Post_Thumbnail_focalX_operator"] | undefined | null,
	focalY?: ResolverInputTypes["Post_Thumbnail_focalY_operator"] | undefined | null,
	sizes__small__url?: ResolverInputTypes["Post_Thumbnail_sizes__small__url_operator"] | undefined | null,
	sizes__small__width?: ResolverInputTypes["Post_Thumbnail_sizes__small__width_operator"] | undefined | null,
	sizes__small__height?: ResolverInputTypes["Post_Thumbnail_sizes__small__height_operator"] | undefined | null,
	sizes__small__mimeType?: ResolverInputTypes["Post_Thumbnail_sizes__small__mimeType_operator"] | undefined | null,
	sizes__small__filesize?: ResolverInputTypes["Post_Thumbnail_sizes__small__filesize_operator"] | undefined | null,
	sizes__small__filename?: ResolverInputTypes["Post_Thumbnail_sizes__small__filename_operator"] | undefined | null,
	sizes__medium__url?: ResolverInputTypes["Post_Thumbnail_sizes__medium__url_operator"] | undefined | null,
	sizes__medium__width?: ResolverInputTypes["Post_Thumbnail_sizes__medium__width_operator"] | undefined | null,
	sizes__medium__height?: ResolverInputTypes["Post_Thumbnail_sizes__medium__height_operator"] | undefined | null,
	sizes__medium__mimeType?: ResolverInputTypes["Post_Thumbnail_sizes__medium__mimeType_operator"] | undefined | null,
	sizes__medium__filesize?: ResolverInputTypes["Post_Thumbnail_sizes__medium__filesize_operator"] | undefined | null,
	sizes__medium__filename?: ResolverInputTypes["Post_Thumbnail_sizes__medium__filename_operator"] | undefined | null,
	sizes__large__url?: ResolverInputTypes["Post_Thumbnail_sizes__large__url_operator"] | undefined | null,
	sizes__large__width?: ResolverInputTypes["Post_Thumbnail_sizes__large__width_operator"] | undefined | null,
	sizes__large__height?: ResolverInputTypes["Post_Thumbnail_sizes__large__height_operator"] | undefined | null,
	sizes__large__mimeType?: ResolverInputTypes["Post_Thumbnail_sizes__large__mimeType_operator"] | undefined | null,
	sizes__large__filesize?: ResolverInputTypes["Post_Thumbnail_sizes__large__filesize_operator"] | undefined | null,
	sizes__large__filename?: ResolverInputTypes["Post_Thumbnail_sizes__large__filename_operator"] | undefined | null,
	sizes__small_jpeg__url?: ResolverInputTypes["Post_Thumbnail_sizes__small_jpeg__url_operator"] | undefined | null,
	sizes__small_jpeg__width?: ResolverInputTypes["Post_Thumbnail_sizes__small_jpeg__width_operator"] | undefined | null,
	sizes__small_jpeg__height?: ResolverInputTypes["Post_Thumbnail_sizes__small_jpeg__height_operator"] | undefined | null,
	sizes__small_jpeg__mimeType?: ResolverInputTypes["Post_Thumbnail_sizes__small_jpeg__mimeType_operator"] | undefined | null,
	sizes__small_jpeg__filesize?: ResolverInputTypes["Post_Thumbnail_sizes__small_jpeg__filesize_operator"] | undefined | null,
	sizes__small_jpeg__filename?: ResolverInputTypes["Post_Thumbnail_sizes__small_jpeg__filename_operator"] | undefined | null,
	sizes__medium_jpeg__url?: ResolverInputTypes["Post_Thumbnail_sizes__medium_jpeg__url_operator"] | undefined | null,
	sizes__medium_jpeg__width?: ResolverInputTypes["Post_Thumbnail_sizes__medium_jpeg__width_operator"] | undefined | null,
	sizes__medium_jpeg__height?: ResolverInputTypes["Post_Thumbnail_sizes__medium_jpeg__height_operator"] | undefined | null,
	sizes__medium_jpeg__mimeType?: ResolverInputTypes["Post_Thumbnail_sizes__medium_jpeg__mimeType_operator"] | undefined | null,
	sizes__medium_jpeg__filesize?: ResolverInputTypes["Post_Thumbnail_sizes__medium_jpeg__filesize_operator"] | undefined | null,
	sizes__medium_jpeg__filename?: ResolverInputTypes["Post_Thumbnail_sizes__medium_jpeg__filename_operator"] | undefined | null,
	sizes__large_jpeg__url?: ResolverInputTypes["Post_Thumbnail_sizes__large_jpeg__url_operator"] | undefined | null,
	sizes__large_jpeg__width?: ResolverInputTypes["Post_Thumbnail_sizes__large_jpeg__width_operator"] | undefined | null,
	sizes__large_jpeg__height?: ResolverInputTypes["Post_Thumbnail_sizes__large_jpeg__height_operator"] | undefined | null,
	sizes__large_jpeg__mimeType?: ResolverInputTypes["Post_Thumbnail_sizes__large_jpeg__mimeType_operator"] | undefined | null,
	sizes__large_jpeg__filesize?: ResolverInputTypes["Post_Thumbnail_sizes__large_jpeg__filesize_operator"] | undefined | null,
	sizes__large_jpeg__filename?: ResolverInputTypes["Post_Thumbnail_sizes__large_jpeg__filename_operator"] | undefined | null,
	id?: ResolverInputTypes["Post_Thumbnail_id_operator"] | undefined | null,
	AND?: Array<ResolverInputTypes["Post_Thumbnail_where_and"] | undefined | null> | undefined | null,
	OR?: Array<ResolverInputTypes["Post_Thumbnail_where_or"] | undefined | null> | undefined | null
};
	["Post_Thumbnail_where_or"]: {
	alt?: ResolverInputTypes["Post_Thumbnail_alt_operator"] | undefined | null,
	updatedAt?: ResolverInputTypes["Post_Thumbnail_updatedAt_operator"] | undefined | null,
	createdAt?: ResolverInputTypes["Post_Thumbnail_createdAt_operator"] | undefined | null,
	url?: ResolverInputTypes["Post_Thumbnail_url_operator"] | undefined | null,
	filename?: ResolverInputTypes["Post_Thumbnail_filename_operator"] | undefined | null,
	mimeType?: ResolverInputTypes["Post_Thumbnail_mimeType_operator"] | undefined | null,
	filesize?: ResolverInputTypes["Post_Thumbnail_filesize_operator"] | undefined | null,
	width?: ResolverInputTypes["Post_Thumbnail_width_operator"] | undefined | null,
	height?: ResolverInputTypes["Post_Thumbnail_height_operator"] | undefined | null,
	focalX?: ResolverInputTypes["Post_Thumbnail_focalX_operator"] | undefined | null,
	focalY?: ResolverInputTypes["Post_Thumbnail_focalY_operator"] | undefined | null,
	sizes__small__url?: ResolverInputTypes["Post_Thumbnail_sizes__small__url_operator"] | undefined | null,
	sizes__small__width?: ResolverInputTypes["Post_Thumbnail_sizes__small__width_operator"] | undefined | null,
	sizes__small__height?: ResolverInputTypes["Post_Thumbnail_sizes__small__height_operator"] | undefined | null,
	sizes__small__mimeType?: ResolverInputTypes["Post_Thumbnail_sizes__small__mimeType_operator"] | undefined | null,
	sizes__small__filesize?: ResolverInputTypes["Post_Thumbnail_sizes__small__filesize_operator"] | undefined | null,
	sizes__small__filename?: ResolverInputTypes["Post_Thumbnail_sizes__small__filename_operator"] | undefined | null,
	sizes__medium__url?: ResolverInputTypes["Post_Thumbnail_sizes__medium__url_operator"] | undefined | null,
	sizes__medium__width?: ResolverInputTypes["Post_Thumbnail_sizes__medium__width_operator"] | undefined | null,
	sizes__medium__height?: ResolverInputTypes["Post_Thumbnail_sizes__medium__height_operator"] | undefined | null,
	sizes__medium__mimeType?: ResolverInputTypes["Post_Thumbnail_sizes__medium__mimeType_operator"] | undefined | null,
	sizes__medium__filesize?: ResolverInputTypes["Post_Thumbnail_sizes__medium__filesize_operator"] | undefined | null,
	sizes__medium__filename?: ResolverInputTypes["Post_Thumbnail_sizes__medium__filename_operator"] | undefined | null,
	sizes__large__url?: ResolverInputTypes["Post_Thumbnail_sizes__large__url_operator"] | undefined | null,
	sizes__large__width?: ResolverInputTypes["Post_Thumbnail_sizes__large__width_operator"] | undefined | null,
	sizes__large__height?: ResolverInputTypes["Post_Thumbnail_sizes__large__height_operator"] | undefined | null,
	sizes__large__mimeType?: ResolverInputTypes["Post_Thumbnail_sizes__large__mimeType_operator"] | undefined | null,
	sizes__large__filesize?: ResolverInputTypes["Post_Thumbnail_sizes__large__filesize_operator"] | undefined | null,
	sizes__large__filename?: ResolverInputTypes["Post_Thumbnail_sizes__large__filename_operator"] | undefined | null,
	sizes__small_jpeg__url?: ResolverInputTypes["Post_Thumbnail_sizes__small_jpeg__url_operator"] | undefined | null,
	sizes__small_jpeg__width?: ResolverInputTypes["Post_Thumbnail_sizes__small_jpeg__width_operator"] | undefined | null,
	sizes__small_jpeg__height?: ResolverInputTypes["Post_Thumbnail_sizes__small_jpeg__height_operator"] | undefined | null,
	sizes__small_jpeg__mimeType?: ResolverInputTypes["Post_Thumbnail_sizes__small_jpeg__mimeType_operator"] | undefined | null,
	sizes__small_jpeg__filesize?: ResolverInputTypes["Post_Thumbnail_sizes__small_jpeg__filesize_operator"] | undefined | null,
	sizes__small_jpeg__filename?: ResolverInputTypes["Post_Thumbnail_sizes__small_jpeg__filename_operator"] | undefined | null,
	sizes__medium_jpeg__url?: ResolverInputTypes["Post_Thumbnail_sizes__medium_jpeg__url_operator"] | undefined | null,
	sizes__medium_jpeg__width?: ResolverInputTypes["Post_Thumbnail_sizes__medium_jpeg__width_operator"] | undefined | null,
	sizes__medium_jpeg__height?: ResolverInputTypes["Post_Thumbnail_sizes__medium_jpeg__height_operator"] | undefined | null,
	sizes__medium_jpeg__mimeType?: ResolverInputTypes["Post_Thumbnail_sizes__medium_jpeg__mimeType_operator"] | undefined | null,
	sizes__medium_jpeg__filesize?: ResolverInputTypes["Post_Thumbnail_sizes__medium_jpeg__filesize_operator"] | undefined | null,
	sizes__medium_jpeg__filename?: ResolverInputTypes["Post_Thumbnail_sizes__medium_jpeg__filename_operator"] | undefined | null,
	sizes__large_jpeg__url?: ResolverInputTypes["Post_Thumbnail_sizes__large_jpeg__url_operator"] | undefined | null,
	sizes__large_jpeg__width?: ResolverInputTypes["Post_Thumbnail_sizes__large_jpeg__width_operator"] | undefined | null,
	sizes__large_jpeg__height?: ResolverInputTypes["Post_Thumbnail_sizes__large_jpeg__height_operator"] | undefined | null,
	sizes__large_jpeg__mimeType?: ResolverInputTypes["Post_Thumbnail_sizes__large_jpeg__mimeType_operator"] | undefined | null,
	sizes__large_jpeg__filesize?: ResolverInputTypes["Post_Thumbnail_sizes__large_jpeg__filesize_operator"] | undefined | null,
	sizes__large_jpeg__filename?: ResolverInputTypes["Post_Thumbnail_sizes__large_jpeg__filename_operator"] | undefined | null,
	id?: ResolverInputTypes["Post_Thumbnail_id_operator"] | undefined | null,
	AND?: Array<ResolverInputTypes["Post_Thumbnail_where_and"] | undefined | null> | undefined | null,
	OR?: Array<ResolverInputTypes["Post_Thumbnail_where_or"] | undefined | null> | undefined | null
};
	["Post_Banner_where"]: {
	alt?: ResolverInputTypes["Post_Banner_alt_operator"] | undefined | null,
	updatedAt?: ResolverInputTypes["Post_Banner_updatedAt_operator"] | undefined | null,
	createdAt?: ResolverInputTypes["Post_Banner_createdAt_operator"] | undefined | null,
	url?: ResolverInputTypes["Post_Banner_url_operator"] | undefined | null,
	filename?: ResolverInputTypes["Post_Banner_filename_operator"] | undefined | null,
	mimeType?: ResolverInputTypes["Post_Banner_mimeType_operator"] | undefined | null,
	filesize?: ResolverInputTypes["Post_Banner_filesize_operator"] | undefined | null,
	width?: ResolverInputTypes["Post_Banner_width_operator"] | undefined | null,
	height?: ResolverInputTypes["Post_Banner_height_operator"] | undefined | null,
	focalX?: ResolverInputTypes["Post_Banner_focalX_operator"] | undefined | null,
	focalY?: ResolverInputTypes["Post_Banner_focalY_operator"] | undefined | null,
	sizes__small__url?: ResolverInputTypes["Post_Banner_sizes__small__url_operator"] | undefined | null,
	sizes__small__width?: ResolverInputTypes["Post_Banner_sizes__small__width_operator"] | undefined | null,
	sizes__small__height?: ResolverInputTypes["Post_Banner_sizes__small__height_operator"] | undefined | null,
	sizes__small__mimeType?: ResolverInputTypes["Post_Banner_sizes__small__mimeType_operator"] | undefined | null,
	sizes__small__filesize?: ResolverInputTypes["Post_Banner_sizes__small__filesize_operator"] | undefined | null,
	sizes__small__filename?: ResolverInputTypes["Post_Banner_sizes__small__filename_operator"] | undefined | null,
	sizes__medium__url?: ResolverInputTypes["Post_Banner_sizes__medium__url_operator"] | undefined | null,
	sizes__medium__width?: ResolverInputTypes["Post_Banner_sizes__medium__width_operator"] | undefined | null,
	sizes__medium__height?: ResolverInputTypes["Post_Banner_sizes__medium__height_operator"] | undefined | null,
	sizes__medium__mimeType?: ResolverInputTypes["Post_Banner_sizes__medium__mimeType_operator"] | undefined | null,
	sizes__medium__filesize?: ResolverInputTypes["Post_Banner_sizes__medium__filesize_operator"] | undefined | null,
	sizes__medium__filename?: ResolverInputTypes["Post_Banner_sizes__medium__filename_operator"] | undefined | null,
	sizes__large__url?: ResolverInputTypes["Post_Banner_sizes__large__url_operator"] | undefined | null,
	sizes__large__width?: ResolverInputTypes["Post_Banner_sizes__large__width_operator"] | undefined | null,
	sizes__large__height?: ResolverInputTypes["Post_Banner_sizes__large__height_operator"] | undefined | null,
	sizes__large__mimeType?: ResolverInputTypes["Post_Banner_sizes__large__mimeType_operator"] | undefined | null,
	sizes__large__filesize?: ResolverInputTypes["Post_Banner_sizes__large__filesize_operator"] | undefined | null,
	sizes__large__filename?: ResolverInputTypes["Post_Banner_sizes__large__filename_operator"] | undefined | null,
	sizes__small_jpeg__url?: ResolverInputTypes["Post_Banner_sizes__small_jpeg__url_operator"] | undefined | null,
	sizes__small_jpeg__width?: ResolverInputTypes["Post_Banner_sizes__small_jpeg__width_operator"] | undefined | null,
	sizes__small_jpeg__height?: ResolverInputTypes["Post_Banner_sizes__small_jpeg__height_operator"] | undefined | null,
	sizes__small_jpeg__mimeType?: ResolverInputTypes["Post_Banner_sizes__small_jpeg__mimeType_operator"] | undefined | null,
	sizes__small_jpeg__filesize?: ResolverInputTypes["Post_Banner_sizes__small_jpeg__filesize_operator"] | undefined | null,
	sizes__small_jpeg__filename?: ResolverInputTypes["Post_Banner_sizes__small_jpeg__filename_operator"] | undefined | null,
	sizes__medium_jpeg__url?: ResolverInputTypes["Post_Banner_sizes__medium_jpeg__url_operator"] | undefined | null,
	sizes__medium_jpeg__width?: ResolverInputTypes["Post_Banner_sizes__medium_jpeg__width_operator"] | undefined | null,
	sizes__medium_jpeg__height?: ResolverInputTypes["Post_Banner_sizes__medium_jpeg__height_operator"] | undefined | null,
	sizes__medium_jpeg__mimeType?: ResolverInputTypes["Post_Banner_sizes__medium_jpeg__mimeType_operator"] | undefined | null,
	sizes__medium_jpeg__filesize?: ResolverInputTypes["Post_Banner_sizes__medium_jpeg__filesize_operator"] | undefined | null,
	sizes__medium_jpeg__filename?: ResolverInputTypes["Post_Banner_sizes__medium_jpeg__filename_operator"] | undefined | null,
	sizes__large_jpeg__url?: ResolverInputTypes["Post_Banner_sizes__large_jpeg__url_operator"] | undefined | null,
	sizes__large_jpeg__width?: ResolverInputTypes["Post_Banner_sizes__large_jpeg__width_operator"] | undefined | null,
	sizes__large_jpeg__height?: ResolverInputTypes["Post_Banner_sizes__large_jpeg__height_operator"] | undefined | null,
	sizes__large_jpeg__mimeType?: ResolverInputTypes["Post_Banner_sizes__large_jpeg__mimeType_operator"] | undefined | null,
	sizes__large_jpeg__filesize?: ResolverInputTypes["Post_Banner_sizes__large_jpeg__filesize_operator"] | undefined | null,
	sizes__large_jpeg__filename?: ResolverInputTypes["Post_Banner_sizes__large_jpeg__filename_operator"] | undefined | null,
	id?: ResolverInputTypes["Post_Banner_id_operator"] | undefined | null,
	AND?: Array<ResolverInputTypes["Post_Banner_where_and"] | undefined | null> | undefined | null,
	OR?: Array<ResolverInputTypes["Post_Banner_where_or"] | undefined | null> | undefined | null
};
	["Post_Banner_alt_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Post_Banner_updatedAt_operator"]: {
	equals?: ResolverInputTypes["DateTime"] | undefined | null,
	not_equals?: ResolverInputTypes["DateTime"] | undefined | null,
	greater_than_equal?: ResolverInputTypes["DateTime"] | undefined | null,
	greater_than?: ResolverInputTypes["DateTime"] | undefined | null,
	less_than_equal?: ResolverInputTypes["DateTime"] | undefined | null,
	less_than?: ResolverInputTypes["DateTime"] | undefined | null,
	like?: ResolverInputTypes["DateTime"] | undefined | null,
	exists?: boolean | undefined | null
};
	["Post_Banner_createdAt_operator"]: {
	equals?: ResolverInputTypes["DateTime"] | undefined | null,
	not_equals?: ResolverInputTypes["DateTime"] | undefined | null,
	greater_than_equal?: ResolverInputTypes["DateTime"] | undefined | null,
	greater_than?: ResolverInputTypes["DateTime"] | undefined | null,
	less_than_equal?: ResolverInputTypes["DateTime"] | undefined | null,
	less_than?: ResolverInputTypes["DateTime"] | undefined | null,
	like?: ResolverInputTypes["DateTime"] | undefined | null,
	exists?: boolean | undefined | null
};
	["Post_Banner_url_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Post_Banner_filename_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Post_Banner_mimeType_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Post_Banner_filesize_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Post_Banner_width_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Post_Banner_height_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Post_Banner_focalX_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Post_Banner_focalY_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Post_Banner_sizes__small__url_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Post_Banner_sizes__small__width_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Post_Banner_sizes__small__height_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Post_Banner_sizes__small__mimeType_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Post_Banner_sizes__small__filesize_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Post_Banner_sizes__small__filename_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Post_Banner_sizes__medium__url_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Post_Banner_sizes__medium__width_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Post_Banner_sizes__medium__height_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Post_Banner_sizes__medium__mimeType_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Post_Banner_sizes__medium__filesize_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Post_Banner_sizes__medium__filename_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Post_Banner_sizes__large__url_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Post_Banner_sizes__large__width_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Post_Banner_sizes__large__height_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Post_Banner_sizes__large__mimeType_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Post_Banner_sizes__large__filesize_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Post_Banner_sizes__large__filename_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Post_Banner_sizes__small_jpeg__url_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Post_Banner_sizes__small_jpeg__width_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Post_Banner_sizes__small_jpeg__height_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Post_Banner_sizes__small_jpeg__mimeType_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Post_Banner_sizes__small_jpeg__filesize_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Post_Banner_sizes__small_jpeg__filename_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Post_Banner_sizes__medium_jpeg__url_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Post_Banner_sizes__medium_jpeg__width_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Post_Banner_sizes__medium_jpeg__height_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Post_Banner_sizes__medium_jpeg__mimeType_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Post_Banner_sizes__medium_jpeg__filesize_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Post_Banner_sizes__medium_jpeg__filename_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Post_Banner_sizes__large_jpeg__url_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Post_Banner_sizes__large_jpeg__width_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Post_Banner_sizes__large_jpeg__height_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Post_Banner_sizes__large_jpeg__mimeType_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Post_Banner_sizes__large_jpeg__filesize_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Post_Banner_sizes__large_jpeg__filename_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Post_Banner_id_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Post_Banner_where_and"]: {
	alt?: ResolverInputTypes["Post_Banner_alt_operator"] | undefined | null,
	updatedAt?: ResolverInputTypes["Post_Banner_updatedAt_operator"] | undefined | null,
	createdAt?: ResolverInputTypes["Post_Banner_createdAt_operator"] | undefined | null,
	url?: ResolverInputTypes["Post_Banner_url_operator"] | undefined | null,
	filename?: ResolverInputTypes["Post_Banner_filename_operator"] | undefined | null,
	mimeType?: ResolverInputTypes["Post_Banner_mimeType_operator"] | undefined | null,
	filesize?: ResolverInputTypes["Post_Banner_filesize_operator"] | undefined | null,
	width?: ResolverInputTypes["Post_Banner_width_operator"] | undefined | null,
	height?: ResolverInputTypes["Post_Banner_height_operator"] | undefined | null,
	focalX?: ResolverInputTypes["Post_Banner_focalX_operator"] | undefined | null,
	focalY?: ResolverInputTypes["Post_Banner_focalY_operator"] | undefined | null,
	sizes__small__url?: ResolverInputTypes["Post_Banner_sizes__small__url_operator"] | undefined | null,
	sizes__small__width?: ResolverInputTypes["Post_Banner_sizes__small__width_operator"] | undefined | null,
	sizes__small__height?: ResolverInputTypes["Post_Banner_sizes__small__height_operator"] | undefined | null,
	sizes__small__mimeType?: ResolverInputTypes["Post_Banner_sizes__small__mimeType_operator"] | undefined | null,
	sizes__small__filesize?: ResolverInputTypes["Post_Banner_sizes__small__filesize_operator"] | undefined | null,
	sizes__small__filename?: ResolverInputTypes["Post_Banner_sizes__small__filename_operator"] | undefined | null,
	sizes__medium__url?: ResolverInputTypes["Post_Banner_sizes__medium__url_operator"] | undefined | null,
	sizes__medium__width?: ResolverInputTypes["Post_Banner_sizes__medium__width_operator"] | undefined | null,
	sizes__medium__height?: ResolverInputTypes["Post_Banner_sizes__medium__height_operator"] | undefined | null,
	sizes__medium__mimeType?: ResolverInputTypes["Post_Banner_sizes__medium__mimeType_operator"] | undefined | null,
	sizes__medium__filesize?: ResolverInputTypes["Post_Banner_sizes__medium__filesize_operator"] | undefined | null,
	sizes__medium__filename?: ResolverInputTypes["Post_Banner_sizes__medium__filename_operator"] | undefined | null,
	sizes__large__url?: ResolverInputTypes["Post_Banner_sizes__large__url_operator"] | undefined | null,
	sizes__large__width?: ResolverInputTypes["Post_Banner_sizes__large__width_operator"] | undefined | null,
	sizes__large__height?: ResolverInputTypes["Post_Banner_sizes__large__height_operator"] | undefined | null,
	sizes__large__mimeType?: ResolverInputTypes["Post_Banner_sizes__large__mimeType_operator"] | undefined | null,
	sizes__large__filesize?: ResolverInputTypes["Post_Banner_sizes__large__filesize_operator"] | undefined | null,
	sizes__large__filename?: ResolverInputTypes["Post_Banner_sizes__large__filename_operator"] | undefined | null,
	sizes__small_jpeg__url?: ResolverInputTypes["Post_Banner_sizes__small_jpeg__url_operator"] | undefined | null,
	sizes__small_jpeg__width?: ResolverInputTypes["Post_Banner_sizes__small_jpeg__width_operator"] | undefined | null,
	sizes__small_jpeg__height?: ResolverInputTypes["Post_Banner_sizes__small_jpeg__height_operator"] | undefined | null,
	sizes__small_jpeg__mimeType?: ResolverInputTypes["Post_Banner_sizes__small_jpeg__mimeType_operator"] | undefined | null,
	sizes__small_jpeg__filesize?: ResolverInputTypes["Post_Banner_sizes__small_jpeg__filesize_operator"] | undefined | null,
	sizes__small_jpeg__filename?: ResolverInputTypes["Post_Banner_sizes__small_jpeg__filename_operator"] | undefined | null,
	sizes__medium_jpeg__url?: ResolverInputTypes["Post_Banner_sizes__medium_jpeg__url_operator"] | undefined | null,
	sizes__medium_jpeg__width?: ResolverInputTypes["Post_Banner_sizes__medium_jpeg__width_operator"] | undefined | null,
	sizes__medium_jpeg__height?: ResolverInputTypes["Post_Banner_sizes__medium_jpeg__height_operator"] | undefined | null,
	sizes__medium_jpeg__mimeType?: ResolverInputTypes["Post_Banner_sizes__medium_jpeg__mimeType_operator"] | undefined | null,
	sizes__medium_jpeg__filesize?: ResolverInputTypes["Post_Banner_sizes__medium_jpeg__filesize_operator"] | undefined | null,
	sizes__medium_jpeg__filename?: ResolverInputTypes["Post_Banner_sizes__medium_jpeg__filename_operator"] | undefined | null,
	sizes__large_jpeg__url?: ResolverInputTypes["Post_Banner_sizes__large_jpeg__url_operator"] | undefined | null,
	sizes__large_jpeg__width?: ResolverInputTypes["Post_Banner_sizes__large_jpeg__width_operator"] | undefined | null,
	sizes__large_jpeg__height?: ResolverInputTypes["Post_Banner_sizes__large_jpeg__height_operator"] | undefined | null,
	sizes__large_jpeg__mimeType?: ResolverInputTypes["Post_Banner_sizes__large_jpeg__mimeType_operator"] | undefined | null,
	sizes__large_jpeg__filesize?: ResolverInputTypes["Post_Banner_sizes__large_jpeg__filesize_operator"] | undefined | null,
	sizes__large_jpeg__filename?: ResolverInputTypes["Post_Banner_sizes__large_jpeg__filename_operator"] | undefined | null,
	id?: ResolverInputTypes["Post_Banner_id_operator"] | undefined | null,
	AND?: Array<ResolverInputTypes["Post_Banner_where_and"] | undefined | null> | undefined | null,
	OR?: Array<ResolverInputTypes["Post_Banner_where_or"] | undefined | null> | undefined | null
};
	["Post_Banner_where_or"]: {
	alt?: ResolverInputTypes["Post_Banner_alt_operator"] | undefined | null,
	updatedAt?: ResolverInputTypes["Post_Banner_updatedAt_operator"] | undefined | null,
	createdAt?: ResolverInputTypes["Post_Banner_createdAt_operator"] | undefined | null,
	url?: ResolverInputTypes["Post_Banner_url_operator"] | undefined | null,
	filename?: ResolverInputTypes["Post_Banner_filename_operator"] | undefined | null,
	mimeType?: ResolverInputTypes["Post_Banner_mimeType_operator"] | undefined | null,
	filesize?: ResolverInputTypes["Post_Banner_filesize_operator"] | undefined | null,
	width?: ResolverInputTypes["Post_Banner_width_operator"] | undefined | null,
	height?: ResolverInputTypes["Post_Banner_height_operator"] | undefined | null,
	focalX?: ResolverInputTypes["Post_Banner_focalX_operator"] | undefined | null,
	focalY?: ResolverInputTypes["Post_Banner_focalY_operator"] | undefined | null,
	sizes__small__url?: ResolverInputTypes["Post_Banner_sizes__small__url_operator"] | undefined | null,
	sizes__small__width?: ResolverInputTypes["Post_Banner_sizes__small__width_operator"] | undefined | null,
	sizes__small__height?: ResolverInputTypes["Post_Banner_sizes__small__height_operator"] | undefined | null,
	sizes__small__mimeType?: ResolverInputTypes["Post_Banner_sizes__small__mimeType_operator"] | undefined | null,
	sizes__small__filesize?: ResolverInputTypes["Post_Banner_sizes__small__filesize_operator"] | undefined | null,
	sizes__small__filename?: ResolverInputTypes["Post_Banner_sizes__small__filename_operator"] | undefined | null,
	sizes__medium__url?: ResolverInputTypes["Post_Banner_sizes__medium__url_operator"] | undefined | null,
	sizes__medium__width?: ResolverInputTypes["Post_Banner_sizes__medium__width_operator"] | undefined | null,
	sizes__medium__height?: ResolverInputTypes["Post_Banner_sizes__medium__height_operator"] | undefined | null,
	sizes__medium__mimeType?: ResolverInputTypes["Post_Banner_sizes__medium__mimeType_operator"] | undefined | null,
	sizes__medium__filesize?: ResolverInputTypes["Post_Banner_sizes__medium__filesize_operator"] | undefined | null,
	sizes__medium__filename?: ResolverInputTypes["Post_Banner_sizes__medium__filename_operator"] | undefined | null,
	sizes__large__url?: ResolverInputTypes["Post_Banner_sizes__large__url_operator"] | undefined | null,
	sizes__large__width?: ResolverInputTypes["Post_Banner_sizes__large__width_operator"] | undefined | null,
	sizes__large__height?: ResolverInputTypes["Post_Banner_sizes__large__height_operator"] | undefined | null,
	sizes__large__mimeType?: ResolverInputTypes["Post_Banner_sizes__large__mimeType_operator"] | undefined | null,
	sizes__large__filesize?: ResolverInputTypes["Post_Banner_sizes__large__filesize_operator"] | undefined | null,
	sizes__large__filename?: ResolverInputTypes["Post_Banner_sizes__large__filename_operator"] | undefined | null,
	sizes__small_jpeg__url?: ResolverInputTypes["Post_Banner_sizes__small_jpeg__url_operator"] | undefined | null,
	sizes__small_jpeg__width?: ResolverInputTypes["Post_Banner_sizes__small_jpeg__width_operator"] | undefined | null,
	sizes__small_jpeg__height?: ResolverInputTypes["Post_Banner_sizes__small_jpeg__height_operator"] | undefined | null,
	sizes__small_jpeg__mimeType?: ResolverInputTypes["Post_Banner_sizes__small_jpeg__mimeType_operator"] | undefined | null,
	sizes__small_jpeg__filesize?: ResolverInputTypes["Post_Banner_sizes__small_jpeg__filesize_operator"] | undefined | null,
	sizes__small_jpeg__filename?: ResolverInputTypes["Post_Banner_sizes__small_jpeg__filename_operator"] | undefined | null,
	sizes__medium_jpeg__url?: ResolverInputTypes["Post_Banner_sizes__medium_jpeg__url_operator"] | undefined | null,
	sizes__medium_jpeg__width?: ResolverInputTypes["Post_Banner_sizes__medium_jpeg__width_operator"] | undefined | null,
	sizes__medium_jpeg__height?: ResolverInputTypes["Post_Banner_sizes__medium_jpeg__height_operator"] | undefined | null,
	sizes__medium_jpeg__mimeType?: ResolverInputTypes["Post_Banner_sizes__medium_jpeg__mimeType_operator"] | undefined | null,
	sizes__medium_jpeg__filesize?: ResolverInputTypes["Post_Banner_sizes__medium_jpeg__filesize_operator"] | undefined | null,
	sizes__medium_jpeg__filename?: ResolverInputTypes["Post_Banner_sizes__medium_jpeg__filename_operator"] | undefined | null,
	sizes__large_jpeg__url?: ResolverInputTypes["Post_Banner_sizes__large_jpeg__url_operator"] | undefined | null,
	sizes__large_jpeg__width?: ResolverInputTypes["Post_Banner_sizes__large_jpeg__width_operator"] | undefined | null,
	sizes__large_jpeg__height?: ResolverInputTypes["Post_Banner_sizes__large_jpeg__height_operator"] | undefined | null,
	sizes__large_jpeg__mimeType?: ResolverInputTypes["Post_Banner_sizes__large_jpeg__mimeType_operator"] | undefined | null,
	sizes__large_jpeg__filesize?: ResolverInputTypes["Post_Banner_sizes__large_jpeg__filesize_operator"] | undefined | null,
	sizes__large_jpeg__filename?: ResolverInputTypes["Post_Banner_sizes__large_jpeg__filename_operator"] | undefined | null,
	id?: ResolverInputTypes["Post_Banner_id_operator"] | undefined | null,
	AND?: Array<ResolverInputTypes["Post_Banner_where_and"] | undefined | null> | undefined | null,
	OR?: Array<ResolverInputTypes["Post_Banner_where_or"] | undefined | null> | undefined | null
};
	["Post_Criteria"]: AliasType<{
audiences?: [{	locale?: ResolverInputTypes["LocaleInputType"] | undefined | null,	fallbackLocale?: ResolverInputTypes["FallbackLocaleInputType"] | undefined | null},ResolverInputTypes["Audience"]],
dates?: [{	locale?: ResolverInputTypes["LocaleInputType"] | undefined | null,	fallbackLocale?: ResolverInputTypes["FallbackLocaleInputType"] | undefined | null},ResolverInputTypes["Date"]],
categories?: [{	locale?: ResolverInputTypes["LocaleInputType"] | undefined | null,	fallbackLocale?: ResolverInputTypes["FallbackLocaleInputType"] | undefined | null},ResolverInputTypes["EventCategory"]],
tags?: [{	locale?: ResolverInputTypes["LocaleInputType"] | undefined | null,	fallbackLocale?: ResolverInputTypes["FallbackLocaleInputType"] | undefined | null},ResolverInputTypes["Tag"]],
		__typename?: boolean | `@${string}`
}>;
	["Posts"]: AliasType<{
	docs?:ResolverInputTypes["Post"],
	hasNextPage?:boolean | `@${string}`,
	hasPrevPage?:boolean | `@${string}`,
	limit?:boolean | `@${string}`,
	nextPage?:boolean | `@${string}`,
	offset?:boolean | `@${string}`,
	page?:boolean | `@${string}`,
	pagingCounter?:boolean | `@${string}`,
	prevPage?:boolean | `@${string}`,
	totalDocs?:boolean | `@${string}`,
	totalPages?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["Post_where"]: {
	title?: ResolverInputTypes["Post_title_operator"] | undefined | null,
	region?: ResolverInputTypes["Post_region_operator"] | undefined | null,
	authur?: ResolverInputTypes["Post_authur_operator"] | undefined | null,
	publishDateTime?: ResolverInputTypes["Post_publishDateTime_operator"] | undefined | null,
	permalink?: ResolverInputTypes["Post_permalink_operator"] | undefined | null,
	status?: ResolverInputTypes["Post_status_operator"] | undefined | null,
	legacyContent?: ResolverInputTypes["Post_legacyContent_operator"] | undefined | null,
	showLegacyContent?: ResolverInputTypes["Post_showLegacyContent_operator"] | undefined | null,
	blogCategories?: ResolverInputTypes["Post_blogCategories_operator"] | undefined | null,
	thumbnail?: ResolverInputTypes["Post_thumbnail_operator"] | undefined | null,
	banner?: ResolverInputTypes["Post_banner_operator"] | undefined | null,
	criteria__audiences?: ResolverInputTypes["Post_criteria__audiences_operator"] | undefined | null,
	criteria__dates?: ResolverInputTypes["Post_criteria__dates_operator"] | undefined | null,
	criteria__categories?: ResolverInputTypes["Post_criteria__categories_operator"] | undefined | null,
	criteria__tags?: ResolverInputTypes["Post_criteria__tags_operator"] | undefined | null,
	location?: ResolverInputTypes["Post_location_operator"] | undefined | null,
	views?: ResolverInputTypes["Post_views_operator"] | undefined | null,
	homePageInternalClick?: ResolverInputTypes["Post_homePageInternalClick_operator"] | undefined | null,
	homePageImpression?: ResolverInputTypes["Post_homePageImpression_operator"] | undefined | null,
	blogListInternalClick?: ResolverInputTypes["Post_blogListInternalClick_operator"] | undefined | null,
	blogListImpression?: ResolverInputTypes["Post_blogListImpression_operator"] | undefined | null,
	blogDetailInternalClick?: ResolverInputTypes["Post_blogDetailInternalClick_operator"] | undefined | null,
	blogDetailImpression?: ResolverInputTypes["Post_blogDetailImpression_operator"] | undefined | null,
	relatedEventInternalClick?: ResolverInputTypes["Post_relatedEventInternalClick_operator"] | undefined | null,
	relatedEventImpression?: ResolverInputTypes["Post_relatedEventImpression_operator"] | undefined | null,
	latestEventInternalClick?: ResolverInputTypes["Post_latestEventInternalClick_operator"] | undefined | null,
	latestEventImpression?: ResolverInputTypes["Post_latestEventImpression_operator"] | undefined | null,
	internalClick?: ResolverInputTypes["Post_internalClick_operator"] | undefined | null,
	totalImpression?: ResolverInputTypes["Post_totalImpression_operator"] | undefined | null,
	externalClick?: ResolverInputTypes["Post_externalClick_operator"] | undefined | null,
	outboundClick?: ResolverInputTypes["Post_outboundClick_operator"] | undefined | null,
	legacyGallery?: ResolverInputTypes["Post_legacyGallery_operator"] | undefined | null,
	updatedAt?: ResolverInputTypes["Post_updatedAt_operator"] | undefined | null,
	createdAt?: ResolverInputTypes["Post_createdAt_operator"] | undefined | null,
	id?: ResolverInputTypes["Post_id_operator"] | undefined | null,
	AND?: Array<ResolverInputTypes["Post_where_and"] | undefined | null> | undefined | null,
	OR?: Array<ResolverInputTypes["Post_where_or"] | undefined | null> | undefined | null
};
	["Post_title_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Post_region_operator"]: {
	equals?: ResolverInputTypes["Post_region_Input"] | undefined | null,
	not_equals?: ResolverInputTypes["Post_region_Input"] | undefined | null,
	in?: Array<ResolverInputTypes["Post_region_Input"] | undefined | null> | undefined | null,
	not_in?: Array<ResolverInputTypes["Post_region_Input"] | undefined | null> | undefined | null,
	all?: Array<ResolverInputTypes["Post_region_Input"] | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Post_region_Input"]:Post_region_Input;
	["Post_authur_operator"]: {
	equals?: ResolverInputTypes["JSON"] | undefined | null,
	not_equals?: ResolverInputTypes["JSON"] | undefined | null,
	in?: Array<ResolverInputTypes["JSON"] | undefined | null> | undefined | null,
	not_in?: Array<ResolverInputTypes["JSON"] | undefined | null> | undefined | null,
	all?: Array<ResolverInputTypes["JSON"] | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Post_publishDateTime_operator"]: {
	equals?: ResolverInputTypes["DateTime"] | undefined | null,
	not_equals?: ResolverInputTypes["DateTime"] | undefined | null,
	greater_than_equal?: ResolverInputTypes["DateTime"] | undefined | null,
	greater_than?: ResolverInputTypes["DateTime"] | undefined | null,
	less_than_equal?: ResolverInputTypes["DateTime"] | undefined | null,
	less_than?: ResolverInputTypes["DateTime"] | undefined | null,
	like?: ResolverInputTypes["DateTime"] | undefined | null,
	exists?: boolean | undefined | null
};
	["Post_permalink_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Post_status_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null
};
	["Post_legacyContent_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	exists?: boolean | undefined | null
};
	["Post_showLegacyContent_operator"]: {
	equals?: boolean | undefined | null,
	not_equals?: boolean | undefined | null,
	exists?: boolean | undefined | null
};
	["Post_blogCategories_operator"]: {
	equals?: ResolverInputTypes["JSON"] | undefined | null,
	not_equals?: ResolverInputTypes["JSON"] | undefined | null,
	in?: Array<ResolverInputTypes["JSON"] | undefined | null> | undefined | null,
	not_in?: Array<ResolverInputTypes["JSON"] | undefined | null> | undefined | null,
	all?: Array<ResolverInputTypes["JSON"] | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Post_thumbnail_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	exists?: boolean | undefined | null
};
	["Post_banner_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	exists?: boolean | undefined | null
};
	["Post_criteria__audiences_operator"]: {
	equals?: ResolverInputTypes["JSON"] | undefined | null,
	not_equals?: ResolverInputTypes["JSON"] | undefined | null,
	in?: Array<ResolverInputTypes["JSON"] | undefined | null> | undefined | null,
	not_in?: Array<ResolverInputTypes["JSON"] | undefined | null> | undefined | null,
	all?: Array<ResolverInputTypes["JSON"] | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Post_criteria__dates_operator"]: {
	equals?: ResolverInputTypes["JSON"] | undefined | null,
	not_equals?: ResolverInputTypes["JSON"] | undefined | null,
	in?: Array<ResolverInputTypes["JSON"] | undefined | null> | undefined | null,
	not_in?: Array<ResolverInputTypes["JSON"] | undefined | null> | undefined | null,
	all?: Array<ResolverInputTypes["JSON"] | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Post_criteria__categories_operator"]: {
	equals?: ResolverInputTypes["JSON"] | undefined | null,
	not_equals?: ResolverInputTypes["JSON"] | undefined | null,
	in?: Array<ResolverInputTypes["JSON"] | undefined | null> | undefined | null,
	not_in?: Array<ResolverInputTypes["JSON"] | undefined | null> | undefined | null,
	all?: Array<ResolverInputTypes["JSON"] | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Post_criteria__tags_operator"]: {
	equals?: ResolverInputTypes["JSON"] | undefined | null,
	not_equals?: ResolverInputTypes["JSON"] | undefined | null,
	in?: Array<ResolverInputTypes["JSON"] | undefined | null> | undefined | null,
	not_in?: Array<ResolverInputTypes["JSON"] | undefined | null> | undefined | null,
	all?: Array<ResolverInputTypes["JSON"] | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Post_location_operator"]: {
	equals?: ResolverInputTypes["JSON"] | undefined | null,
	not_equals?: ResolverInputTypes["JSON"] | undefined | null,
	in?: Array<ResolverInputTypes["JSON"] | undefined | null> | undefined | null,
	not_in?: Array<ResolverInputTypes["JSON"] | undefined | null> | undefined | null,
	all?: Array<ResolverInputTypes["JSON"] | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Post_views_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Post_homePageInternalClick_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Post_homePageImpression_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Post_blogListInternalClick_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Post_blogListImpression_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Post_blogDetailInternalClick_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Post_blogDetailImpression_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Post_relatedEventInternalClick_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Post_relatedEventImpression_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Post_latestEventInternalClick_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Post_latestEventImpression_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Post_internalClick_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Post_totalImpression_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Post_externalClick_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Post_outboundClick_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Post_legacyGallery_operator"]: {
	equals?: ResolverInputTypes["JSON"] | undefined | null,
	not_equals?: ResolverInputTypes["JSON"] | undefined | null,
	in?: Array<ResolverInputTypes["JSON"] | undefined | null> | undefined | null,
	not_in?: Array<ResolverInputTypes["JSON"] | undefined | null> | undefined | null,
	all?: Array<ResolverInputTypes["JSON"] | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Post_updatedAt_operator"]: {
	equals?: ResolverInputTypes["DateTime"] | undefined | null,
	not_equals?: ResolverInputTypes["DateTime"] | undefined | null,
	greater_than_equal?: ResolverInputTypes["DateTime"] | undefined | null,
	greater_than?: ResolverInputTypes["DateTime"] | undefined | null,
	less_than_equal?: ResolverInputTypes["DateTime"] | undefined | null,
	less_than?: ResolverInputTypes["DateTime"] | undefined | null,
	like?: ResolverInputTypes["DateTime"] | undefined | null,
	exists?: boolean | undefined | null
};
	["Post_createdAt_operator"]: {
	equals?: ResolverInputTypes["DateTime"] | undefined | null,
	not_equals?: ResolverInputTypes["DateTime"] | undefined | null,
	greater_than_equal?: ResolverInputTypes["DateTime"] | undefined | null,
	greater_than?: ResolverInputTypes["DateTime"] | undefined | null,
	less_than_equal?: ResolverInputTypes["DateTime"] | undefined | null,
	less_than?: ResolverInputTypes["DateTime"] | undefined | null,
	like?: ResolverInputTypes["DateTime"] | undefined | null,
	exists?: boolean | undefined | null
};
	["Post_id_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Post_where_and"]: {
	title?: ResolverInputTypes["Post_title_operator"] | undefined | null,
	region?: ResolverInputTypes["Post_region_operator"] | undefined | null,
	authur?: ResolverInputTypes["Post_authur_operator"] | undefined | null,
	publishDateTime?: ResolverInputTypes["Post_publishDateTime_operator"] | undefined | null,
	permalink?: ResolverInputTypes["Post_permalink_operator"] | undefined | null,
	status?: ResolverInputTypes["Post_status_operator"] | undefined | null,
	legacyContent?: ResolverInputTypes["Post_legacyContent_operator"] | undefined | null,
	showLegacyContent?: ResolverInputTypes["Post_showLegacyContent_operator"] | undefined | null,
	blogCategories?: ResolverInputTypes["Post_blogCategories_operator"] | undefined | null,
	thumbnail?: ResolverInputTypes["Post_thumbnail_operator"] | undefined | null,
	banner?: ResolverInputTypes["Post_banner_operator"] | undefined | null,
	criteria__audiences?: ResolverInputTypes["Post_criteria__audiences_operator"] | undefined | null,
	criteria__dates?: ResolverInputTypes["Post_criteria__dates_operator"] | undefined | null,
	criteria__categories?: ResolverInputTypes["Post_criteria__categories_operator"] | undefined | null,
	criteria__tags?: ResolverInputTypes["Post_criteria__tags_operator"] | undefined | null,
	location?: ResolverInputTypes["Post_location_operator"] | undefined | null,
	views?: ResolverInputTypes["Post_views_operator"] | undefined | null,
	homePageInternalClick?: ResolverInputTypes["Post_homePageInternalClick_operator"] | undefined | null,
	homePageImpression?: ResolverInputTypes["Post_homePageImpression_operator"] | undefined | null,
	blogListInternalClick?: ResolverInputTypes["Post_blogListInternalClick_operator"] | undefined | null,
	blogListImpression?: ResolverInputTypes["Post_blogListImpression_operator"] | undefined | null,
	blogDetailInternalClick?: ResolverInputTypes["Post_blogDetailInternalClick_operator"] | undefined | null,
	blogDetailImpression?: ResolverInputTypes["Post_blogDetailImpression_operator"] | undefined | null,
	relatedEventInternalClick?: ResolverInputTypes["Post_relatedEventInternalClick_operator"] | undefined | null,
	relatedEventImpression?: ResolverInputTypes["Post_relatedEventImpression_operator"] | undefined | null,
	latestEventInternalClick?: ResolverInputTypes["Post_latestEventInternalClick_operator"] | undefined | null,
	latestEventImpression?: ResolverInputTypes["Post_latestEventImpression_operator"] | undefined | null,
	internalClick?: ResolverInputTypes["Post_internalClick_operator"] | undefined | null,
	totalImpression?: ResolverInputTypes["Post_totalImpression_operator"] | undefined | null,
	externalClick?: ResolverInputTypes["Post_externalClick_operator"] | undefined | null,
	outboundClick?: ResolverInputTypes["Post_outboundClick_operator"] | undefined | null,
	legacyGallery?: ResolverInputTypes["Post_legacyGallery_operator"] | undefined | null,
	updatedAt?: ResolverInputTypes["Post_updatedAt_operator"] | undefined | null,
	createdAt?: ResolverInputTypes["Post_createdAt_operator"] | undefined | null,
	id?: ResolverInputTypes["Post_id_operator"] | undefined | null,
	AND?: Array<ResolverInputTypes["Post_where_and"] | undefined | null> | undefined | null,
	OR?: Array<ResolverInputTypes["Post_where_or"] | undefined | null> | undefined | null
};
	["Post_where_or"]: {
	title?: ResolverInputTypes["Post_title_operator"] | undefined | null,
	region?: ResolverInputTypes["Post_region_operator"] | undefined | null,
	authur?: ResolverInputTypes["Post_authur_operator"] | undefined | null,
	publishDateTime?: ResolverInputTypes["Post_publishDateTime_operator"] | undefined | null,
	permalink?: ResolverInputTypes["Post_permalink_operator"] | undefined | null,
	status?: ResolverInputTypes["Post_status_operator"] | undefined | null,
	legacyContent?: ResolverInputTypes["Post_legacyContent_operator"] | undefined | null,
	showLegacyContent?: ResolverInputTypes["Post_showLegacyContent_operator"] | undefined | null,
	blogCategories?: ResolverInputTypes["Post_blogCategories_operator"] | undefined | null,
	thumbnail?: ResolverInputTypes["Post_thumbnail_operator"] | undefined | null,
	banner?: ResolverInputTypes["Post_banner_operator"] | undefined | null,
	criteria__audiences?: ResolverInputTypes["Post_criteria__audiences_operator"] | undefined | null,
	criteria__dates?: ResolverInputTypes["Post_criteria__dates_operator"] | undefined | null,
	criteria__categories?: ResolverInputTypes["Post_criteria__categories_operator"] | undefined | null,
	criteria__tags?: ResolverInputTypes["Post_criteria__tags_operator"] | undefined | null,
	location?: ResolverInputTypes["Post_location_operator"] | undefined | null,
	views?: ResolverInputTypes["Post_views_operator"] | undefined | null,
	homePageInternalClick?: ResolverInputTypes["Post_homePageInternalClick_operator"] | undefined | null,
	homePageImpression?: ResolverInputTypes["Post_homePageImpression_operator"] | undefined | null,
	blogListInternalClick?: ResolverInputTypes["Post_blogListInternalClick_operator"] | undefined | null,
	blogListImpression?: ResolverInputTypes["Post_blogListImpression_operator"] | undefined | null,
	blogDetailInternalClick?: ResolverInputTypes["Post_blogDetailInternalClick_operator"] | undefined | null,
	blogDetailImpression?: ResolverInputTypes["Post_blogDetailImpression_operator"] | undefined | null,
	relatedEventInternalClick?: ResolverInputTypes["Post_relatedEventInternalClick_operator"] | undefined | null,
	relatedEventImpression?: ResolverInputTypes["Post_relatedEventImpression_operator"] | undefined | null,
	latestEventInternalClick?: ResolverInputTypes["Post_latestEventInternalClick_operator"] | undefined | null,
	latestEventImpression?: ResolverInputTypes["Post_latestEventImpression_operator"] | undefined | null,
	internalClick?: ResolverInputTypes["Post_internalClick_operator"] | undefined | null,
	totalImpression?: ResolverInputTypes["Post_totalImpression_operator"] | undefined | null,
	externalClick?: ResolverInputTypes["Post_externalClick_operator"] | undefined | null,
	outboundClick?: ResolverInputTypes["Post_outboundClick_operator"] | undefined | null,
	legacyGallery?: ResolverInputTypes["Post_legacyGallery_operator"] | undefined | null,
	updatedAt?: ResolverInputTypes["Post_updatedAt_operator"] | undefined | null,
	createdAt?: ResolverInputTypes["Post_createdAt_operator"] | undefined | null,
	id?: ResolverInputTypes["Post_id_operator"] | undefined | null,
	AND?: Array<ResolverInputTypes["Post_where_and"] | undefined | null> | undefined | null,
	OR?: Array<ResolverInputTypes["Post_where_or"] | undefined | null> | undefined | null
};
	["countPosts"]: AliasType<{
	totalDocs?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["postsDocAccess"]: AliasType<{
	fields?:ResolverInputTypes["PostsDocAccessFields"],
	create?:ResolverInputTypes["PostsCreateDocAccess"],
	read?:ResolverInputTypes["PostsReadDocAccess"],
	update?:ResolverInputTypes["PostsUpdateDocAccess"],
	delete?:ResolverInputTypes["PostsDeleteDocAccess"],
		__typename?: boolean | `@${string}`
}>;
	["PostsDocAccessFields"]: AliasType<{
	title?:ResolverInputTypes["PostsDocAccessFields_title"],
	region?:ResolverInputTypes["PostsDocAccessFields_region"],
	authur?:ResolverInputTypes["PostsDocAccessFields_authur"],
	publishDateTime?:ResolverInputTypes["PostsDocAccessFields_publishDateTime"],
	permalink?:ResolverInputTypes["PostsDocAccessFields_permalink"],
	status?:ResolverInputTypes["PostsDocAccessFields_status"],
	content?:ResolverInputTypes["PostsDocAccessFields_content"],
	legacyContent?:ResolverInputTypes["PostsDocAccessFields_legacyContent"],
	showLegacyContent?:ResolverInputTypes["PostsDocAccessFields_showLegacyContent"],
	blogCategories?:ResolverInputTypes["PostsDocAccessFields_blogCategories"],
	thumbnail?:ResolverInputTypes["PostsDocAccessFields_thumbnail"],
	banner?:ResolverInputTypes["PostsDocAccessFields_banner"],
	criteria?:ResolverInputTypes["PostsDocAccessFields_criteria"],
	location?:ResolverInputTypes["PostsDocAccessFields_location"],
	homePageInternalClick?:ResolverInputTypes["PostsDocAccessFields_homePageInternalClick"],
	homePageImpression?:ResolverInputTypes["PostsDocAccessFields_homePageImpression"],
	blogListInternalClick?:ResolverInputTypes["PostsDocAccessFields_blogListInternalClick"],
	blogListImpression?:ResolverInputTypes["PostsDocAccessFields_blogListImpression"],
	blogDetailInternalClick?:ResolverInputTypes["PostsDocAccessFields_blogDetailInternalClick"],
	blogDetailImpression?:ResolverInputTypes["PostsDocAccessFields_blogDetailImpression"],
	relatedEventInternalClick?:ResolverInputTypes["PostsDocAccessFields_relatedEventInternalClick"],
	relatedEventImpression?:ResolverInputTypes["PostsDocAccessFields_relatedEventImpression"],
	latestEventInternalClick?:ResolverInputTypes["PostsDocAccessFields_latestEventInternalClick"],
	latestEventImpression?:ResolverInputTypes["PostsDocAccessFields_latestEventImpression"],
	internalClick?:ResolverInputTypes["PostsDocAccessFields_internalClick"],
	totalImpression?:ResolverInputTypes["PostsDocAccessFields_totalImpression"],
	externalClick?:ResolverInputTypes["PostsDocAccessFields_externalClick"],
	outboundClick?:ResolverInputTypes["PostsDocAccessFields_outboundClick"],
	legacyGallery?:ResolverInputTypes["PostsDocAccessFields_legacyGallery"],
	updatedAt?:ResolverInputTypes["PostsDocAccessFields_updatedAt"],
	createdAt?:ResolverInputTypes["PostsDocAccessFields_createdAt"],
		__typename?: boolean | `@${string}`
}>;
	["PostsDocAccessFields_title"]: AliasType<{
	create?:ResolverInputTypes["PostsDocAccessFields_title_Create"],
	read?:ResolverInputTypes["PostsDocAccessFields_title_Read"],
	update?:ResolverInputTypes["PostsDocAccessFields_title_Update"],
	delete?:ResolverInputTypes["PostsDocAccessFields_title_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["PostsDocAccessFields_title_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsDocAccessFields_title_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsDocAccessFields_title_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsDocAccessFields_title_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsDocAccessFields_region"]: AliasType<{
	create?:ResolverInputTypes["PostsDocAccessFields_region_Create"],
	read?:ResolverInputTypes["PostsDocAccessFields_region_Read"],
	update?:ResolverInputTypes["PostsDocAccessFields_region_Update"],
	delete?:ResolverInputTypes["PostsDocAccessFields_region_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["PostsDocAccessFields_region_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsDocAccessFields_region_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsDocAccessFields_region_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsDocAccessFields_region_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsDocAccessFields_authur"]: AliasType<{
	create?:ResolverInputTypes["PostsDocAccessFields_authur_Create"],
	read?:ResolverInputTypes["PostsDocAccessFields_authur_Read"],
	update?:ResolverInputTypes["PostsDocAccessFields_authur_Update"],
	delete?:ResolverInputTypes["PostsDocAccessFields_authur_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["PostsDocAccessFields_authur_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsDocAccessFields_authur_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsDocAccessFields_authur_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsDocAccessFields_authur_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsDocAccessFields_publishDateTime"]: AliasType<{
	create?:ResolverInputTypes["PostsDocAccessFields_publishDateTime_Create"],
	read?:ResolverInputTypes["PostsDocAccessFields_publishDateTime_Read"],
	update?:ResolverInputTypes["PostsDocAccessFields_publishDateTime_Update"],
	delete?:ResolverInputTypes["PostsDocAccessFields_publishDateTime_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["PostsDocAccessFields_publishDateTime_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsDocAccessFields_publishDateTime_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsDocAccessFields_publishDateTime_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsDocAccessFields_publishDateTime_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsDocAccessFields_permalink"]: AliasType<{
	create?:ResolverInputTypes["PostsDocAccessFields_permalink_Create"],
	read?:ResolverInputTypes["PostsDocAccessFields_permalink_Read"],
	update?:ResolverInputTypes["PostsDocAccessFields_permalink_Update"],
	delete?:ResolverInputTypes["PostsDocAccessFields_permalink_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["PostsDocAccessFields_permalink_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsDocAccessFields_permalink_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsDocAccessFields_permalink_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsDocAccessFields_permalink_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsDocAccessFields_status"]: AliasType<{
	create?:ResolverInputTypes["PostsDocAccessFields_status_Create"],
	read?:ResolverInputTypes["PostsDocAccessFields_status_Read"],
	update?:ResolverInputTypes["PostsDocAccessFields_status_Update"],
	delete?:ResolverInputTypes["PostsDocAccessFields_status_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["PostsDocAccessFields_status_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsDocAccessFields_status_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsDocAccessFields_status_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsDocAccessFields_status_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsDocAccessFields_content"]: AliasType<{
	create?:ResolverInputTypes["PostsDocAccessFields_content_Create"],
	read?:ResolverInputTypes["PostsDocAccessFields_content_Read"],
	update?:ResolverInputTypes["PostsDocAccessFields_content_Update"],
	delete?:ResolverInputTypes["PostsDocAccessFields_content_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["PostsDocAccessFields_content_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsDocAccessFields_content_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsDocAccessFields_content_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsDocAccessFields_content_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsDocAccessFields_legacyContent"]: AliasType<{
	create?:ResolverInputTypes["PostsDocAccessFields_legacyContent_Create"],
	read?:ResolverInputTypes["PostsDocAccessFields_legacyContent_Read"],
	update?:ResolverInputTypes["PostsDocAccessFields_legacyContent_Update"],
	delete?:ResolverInputTypes["PostsDocAccessFields_legacyContent_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["PostsDocAccessFields_legacyContent_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsDocAccessFields_legacyContent_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsDocAccessFields_legacyContent_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsDocAccessFields_legacyContent_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsDocAccessFields_showLegacyContent"]: AliasType<{
	create?:ResolverInputTypes["PostsDocAccessFields_showLegacyContent_Create"],
	read?:ResolverInputTypes["PostsDocAccessFields_showLegacyContent_Read"],
	update?:ResolverInputTypes["PostsDocAccessFields_showLegacyContent_Update"],
	delete?:ResolverInputTypes["PostsDocAccessFields_showLegacyContent_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["PostsDocAccessFields_showLegacyContent_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsDocAccessFields_showLegacyContent_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsDocAccessFields_showLegacyContent_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsDocAccessFields_showLegacyContent_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsDocAccessFields_blogCategories"]: AliasType<{
	create?:ResolverInputTypes["PostsDocAccessFields_blogCategories_Create"],
	read?:ResolverInputTypes["PostsDocAccessFields_blogCategories_Read"],
	update?:ResolverInputTypes["PostsDocAccessFields_blogCategories_Update"],
	delete?:ResolverInputTypes["PostsDocAccessFields_blogCategories_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["PostsDocAccessFields_blogCategories_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsDocAccessFields_blogCategories_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsDocAccessFields_blogCategories_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsDocAccessFields_blogCategories_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsDocAccessFields_thumbnail"]: AliasType<{
	create?:ResolverInputTypes["PostsDocAccessFields_thumbnail_Create"],
	read?:ResolverInputTypes["PostsDocAccessFields_thumbnail_Read"],
	update?:ResolverInputTypes["PostsDocAccessFields_thumbnail_Update"],
	delete?:ResolverInputTypes["PostsDocAccessFields_thumbnail_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["PostsDocAccessFields_thumbnail_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsDocAccessFields_thumbnail_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsDocAccessFields_thumbnail_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsDocAccessFields_thumbnail_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsDocAccessFields_banner"]: AliasType<{
	create?:ResolverInputTypes["PostsDocAccessFields_banner_Create"],
	read?:ResolverInputTypes["PostsDocAccessFields_banner_Read"],
	update?:ResolverInputTypes["PostsDocAccessFields_banner_Update"],
	delete?:ResolverInputTypes["PostsDocAccessFields_banner_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["PostsDocAccessFields_banner_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsDocAccessFields_banner_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsDocAccessFields_banner_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsDocAccessFields_banner_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsDocAccessFields_criteria"]: AliasType<{
	create?:ResolverInputTypes["PostsDocAccessFields_criteria_Create"],
	read?:ResolverInputTypes["PostsDocAccessFields_criteria_Read"],
	update?:ResolverInputTypes["PostsDocAccessFields_criteria_Update"],
	delete?:ResolverInputTypes["PostsDocAccessFields_criteria_Delete"],
	fields?:ResolverInputTypes["PostsDocAccessFields_criteria_Fields"],
		__typename?: boolean | `@${string}`
}>;
	["PostsDocAccessFields_criteria_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsDocAccessFields_criteria_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsDocAccessFields_criteria_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsDocAccessFields_criteria_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsDocAccessFields_criteria_Fields"]: AliasType<{
	audiences?:ResolverInputTypes["PostsDocAccessFields_criteria_audiences"],
	dates?:ResolverInputTypes["PostsDocAccessFields_criteria_dates"],
	categories?:ResolverInputTypes["PostsDocAccessFields_criteria_categories"],
	tags?:ResolverInputTypes["PostsDocAccessFields_criteria_tags"],
		__typename?: boolean | `@${string}`
}>;
	["PostsDocAccessFields_criteria_audiences"]: AliasType<{
	create?:ResolverInputTypes["PostsDocAccessFields_criteria_audiences_Create"],
	read?:ResolverInputTypes["PostsDocAccessFields_criteria_audiences_Read"],
	update?:ResolverInputTypes["PostsDocAccessFields_criteria_audiences_Update"],
	delete?:ResolverInputTypes["PostsDocAccessFields_criteria_audiences_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["PostsDocAccessFields_criteria_audiences_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsDocAccessFields_criteria_audiences_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsDocAccessFields_criteria_audiences_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsDocAccessFields_criteria_audiences_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsDocAccessFields_criteria_dates"]: AliasType<{
	create?:ResolverInputTypes["PostsDocAccessFields_criteria_dates_Create"],
	read?:ResolverInputTypes["PostsDocAccessFields_criteria_dates_Read"],
	update?:ResolverInputTypes["PostsDocAccessFields_criteria_dates_Update"],
	delete?:ResolverInputTypes["PostsDocAccessFields_criteria_dates_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["PostsDocAccessFields_criteria_dates_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsDocAccessFields_criteria_dates_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsDocAccessFields_criteria_dates_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsDocAccessFields_criteria_dates_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsDocAccessFields_criteria_categories"]: AliasType<{
	create?:ResolverInputTypes["PostsDocAccessFields_criteria_categories_Create"],
	read?:ResolverInputTypes["PostsDocAccessFields_criteria_categories_Read"],
	update?:ResolverInputTypes["PostsDocAccessFields_criteria_categories_Update"],
	delete?:ResolverInputTypes["PostsDocAccessFields_criteria_categories_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["PostsDocAccessFields_criteria_categories_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsDocAccessFields_criteria_categories_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsDocAccessFields_criteria_categories_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsDocAccessFields_criteria_categories_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsDocAccessFields_criteria_tags"]: AliasType<{
	create?:ResolverInputTypes["PostsDocAccessFields_criteria_tags_Create"],
	read?:ResolverInputTypes["PostsDocAccessFields_criteria_tags_Read"],
	update?:ResolverInputTypes["PostsDocAccessFields_criteria_tags_Update"],
	delete?:ResolverInputTypes["PostsDocAccessFields_criteria_tags_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["PostsDocAccessFields_criteria_tags_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsDocAccessFields_criteria_tags_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsDocAccessFields_criteria_tags_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsDocAccessFields_criteria_tags_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsDocAccessFields_location"]: AliasType<{
	create?:ResolverInputTypes["PostsDocAccessFields_location_Create"],
	read?:ResolverInputTypes["PostsDocAccessFields_location_Read"],
	update?:ResolverInputTypes["PostsDocAccessFields_location_Update"],
	delete?:ResolverInputTypes["PostsDocAccessFields_location_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["PostsDocAccessFields_location_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsDocAccessFields_location_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsDocAccessFields_location_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsDocAccessFields_location_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsDocAccessFields_homePageInternalClick"]: AliasType<{
	create?:ResolverInputTypes["PostsDocAccessFields_homePageInternalClick_Create"],
	read?:ResolverInputTypes["PostsDocAccessFields_homePageInternalClick_Read"],
	update?:ResolverInputTypes["PostsDocAccessFields_homePageInternalClick_Update"],
	delete?:ResolverInputTypes["PostsDocAccessFields_homePageInternalClick_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["PostsDocAccessFields_homePageInternalClick_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsDocAccessFields_homePageInternalClick_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsDocAccessFields_homePageInternalClick_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsDocAccessFields_homePageInternalClick_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsDocAccessFields_homePageImpression"]: AliasType<{
	create?:ResolverInputTypes["PostsDocAccessFields_homePageImpression_Create"],
	read?:ResolverInputTypes["PostsDocAccessFields_homePageImpression_Read"],
	update?:ResolverInputTypes["PostsDocAccessFields_homePageImpression_Update"],
	delete?:ResolverInputTypes["PostsDocAccessFields_homePageImpression_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["PostsDocAccessFields_homePageImpression_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsDocAccessFields_homePageImpression_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsDocAccessFields_homePageImpression_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsDocAccessFields_homePageImpression_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsDocAccessFields_blogListInternalClick"]: AliasType<{
	create?:ResolverInputTypes["PostsDocAccessFields_blogListInternalClick_Create"],
	read?:ResolverInputTypes["PostsDocAccessFields_blogListInternalClick_Read"],
	update?:ResolverInputTypes["PostsDocAccessFields_blogListInternalClick_Update"],
	delete?:ResolverInputTypes["PostsDocAccessFields_blogListInternalClick_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["PostsDocAccessFields_blogListInternalClick_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsDocAccessFields_blogListInternalClick_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsDocAccessFields_blogListInternalClick_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsDocAccessFields_blogListInternalClick_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsDocAccessFields_blogListImpression"]: AliasType<{
	create?:ResolverInputTypes["PostsDocAccessFields_blogListImpression_Create"],
	read?:ResolverInputTypes["PostsDocAccessFields_blogListImpression_Read"],
	update?:ResolverInputTypes["PostsDocAccessFields_blogListImpression_Update"],
	delete?:ResolverInputTypes["PostsDocAccessFields_blogListImpression_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["PostsDocAccessFields_blogListImpression_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsDocAccessFields_blogListImpression_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsDocAccessFields_blogListImpression_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsDocAccessFields_blogListImpression_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsDocAccessFields_blogDetailInternalClick"]: AliasType<{
	create?:ResolverInputTypes["PostsDocAccessFields_blogDetailInternalClick_Create"],
	read?:ResolverInputTypes["PostsDocAccessFields_blogDetailInternalClick_Read"],
	update?:ResolverInputTypes["PostsDocAccessFields_blogDetailInternalClick_Update"],
	delete?:ResolverInputTypes["PostsDocAccessFields_blogDetailInternalClick_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["PostsDocAccessFields_blogDetailInternalClick_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsDocAccessFields_blogDetailInternalClick_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsDocAccessFields_blogDetailInternalClick_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsDocAccessFields_blogDetailInternalClick_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsDocAccessFields_blogDetailImpression"]: AliasType<{
	create?:ResolverInputTypes["PostsDocAccessFields_blogDetailImpression_Create"],
	read?:ResolverInputTypes["PostsDocAccessFields_blogDetailImpression_Read"],
	update?:ResolverInputTypes["PostsDocAccessFields_blogDetailImpression_Update"],
	delete?:ResolverInputTypes["PostsDocAccessFields_blogDetailImpression_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["PostsDocAccessFields_blogDetailImpression_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsDocAccessFields_blogDetailImpression_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsDocAccessFields_blogDetailImpression_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsDocAccessFields_blogDetailImpression_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsDocAccessFields_relatedEventInternalClick"]: AliasType<{
	create?:ResolverInputTypes["PostsDocAccessFields_relatedEventInternalClick_Create"],
	read?:ResolverInputTypes["PostsDocAccessFields_relatedEventInternalClick_Read"],
	update?:ResolverInputTypes["PostsDocAccessFields_relatedEventInternalClick_Update"],
	delete?:ResolverInputTypes["PostsDocAccessFields_relatedEventInternalClick_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["PostsDocAccessFields_relatedEventInternalClick_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsDocAccessFields_relatedEventInternalClick_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsDocAccessFields_relatedEventInternalClick_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsDocAccessFields_relatedEventInternalClick_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsDocAccessFields_relatedEventImpression"]: AliasType<{
	create?:ResolverInputTypes["PostsDocAccessFields_relatedEventImpression_Create"],
	read?:ResolverInputTypes["PostsDocAccessFields_relatedEventImpression_Read"],
	update?:ResolverInputTypes["PostsDocAccessFields_relatedEventImpression_Update"],
	delete?:ResolverInputTypes["PostsDocAccessFields_relatedEventImpression_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["PostsDocAccessFields_relatedEventImpression_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsDocAccessFields_relatedEventImpression_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsDocAccessFields_relatedEventImpression_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsDocAccessFields_relatedEventImpression_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsDocAccessFields_latestEventInternalClick"]: AliasType<{
	create?:ResolverInputTypes["PostsDocAccessFields_latestEventInternalClick_Create"],
	read?:ResolverInputTypes["PostsDocAccessFields_latestEventInternalClick_Read"],
	update?:ResolverInputTypes["PostsDocAccessFields_latestEventInternalClick_Update"],
	delete?:ResolverInputTypes["PostsDocAccessFields_latestEventInternalClick_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["PostsDocAccessFields_latestEventInternalClick_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsDocAccessFields_latestEventInternalClick_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsDocAccessFields_latestEventInternalClick_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsDocAccessFields_latestEventInternalClick_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsDocAccessFields_latestEventImpression"]: AliasType<{
	create?:ResolverInputTypes["PostsDocAccessFields_latestEventImpression_Create"],
	read?:ResolverInputTypes["PostsDocAccessFields_latestEventImpression_Read"],
	update?:ResolverInputTypes["PostsDocAccessFields_latestEventImpression_Update"],
	delete?:ResolverInputTypes["PostsDocAccessFields_latestEventImpression_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["PostsDocAccessFields_latestEventImpression_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsDocAccessFields_latestEventImpression_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsDocAccessFields_latestEventImpression_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsDocAccessFields_latestEventImpression_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsDocAccessFields_internalClick"]: AliasType<{
	create?:ResolverInputTypes["PostsDocAccessFields_internalClick_Create"],
	read?:ResolverInputTypes["PostsDocAccessFields_internalClick_Read"],
	update?:ResolverInputTypes["PostsDocAccessFields_internalClick_Update"],
	delete?:ResolverInputTypes["PostsDocAccessFields_internalClick_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["PostsDocAccessFields_internalClick_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsDocAccessFields_internalClick_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsDocAccessFields_internalClick_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsDocAccessFields_internalClick_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsDocAccessFields_totalImpression"]: AliasType<{
	create?:ResolverInputTypes["PostsDocAccessFields_totalImpression_Create"],
	read?:ResolverInputTypes["PostsDocAccessFields_totalImpression_Read"],
	update?:ResolverInputTypes["PostsDocAccessFields_totalImpression_Update"],
	delete?:ResolverInputTypes["PostsDocAccessFields_totalImpression_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["PostsDocAccessFields_totalImpression_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsDocAccessFields_totalImpression_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsDocAccessFields_totalImpression_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsDocAccessFields_totalImpression_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsDocAccessFields_externalClick"]: AliasType<{
	create?:ResolverInputTypes["PostsDocAccessFields_externalClick_Create"],
	read?:ResolverInputTypes["PostsDocAccessFields_externalClick_Read"],
	update?:ResolverInputTypes["PostsDocAccessFields_externalClick_Update"],
	delete?:ResolverInputTypes["PostsDocAccessFields_externalClick_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["PostsDocAccessFields_externalClick_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsDocAccessFields_externalClick_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsDocAccessFields_externalClick_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsDocAccessFields_externalClick_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsDocAccessFields_outboundClick"]: AliasType<{
	create?:ResolverInputTypes["PostsDocAccessFields_outboundClick_Create"],
	read?:ResolverInputTypes["PostsDocAccessFields_outboundClick_Read"],
	update?:ResolverInputTypes["PostsDocAccessFields_outboundClick_Update"],
	delete?:ResolverInputTypes["PostsDocAccessFields_outboundClick_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["PostsDocAccessFields_outboundClick_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsDocAccessFields_outboundClick_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsDocAccessFields_outboundClick_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsDocAccessFields_outboundClick_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsDocAccessFields_legacyGallery"]: AliasType<{
	create?:ResolverInputTypes["PostsDocAccessFields_legacyGallery_Create"],
	read?:ResolverInputTypes["PostsDocAccessFields_legacyGallery_Read"],
	update?:ResolverInputTypes["PostsDocAccessFields_legacyGallery_Update"],
	delete?:ResolverInputTypes["PostsDocAccessFields_legacyGallery_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["PostsDocAccessFields_legacyGallery_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsDocAccessFields_legacyGallery_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsDocAccessFields_legacyGallery_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsDocAccessFields_legacyGallery_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsDocAccessFields_updatedAt"]: AliasType<{
	create?:ResolverInputTypes["PostsDocAccessFields_updatedAt_Create"],
	read?:ResolverInputTypes["PostsDocAccessFields_updatedAt_Read"],
	update?:ResolverInputTypes["PostsDocAccessFields_updatedAt_Update"],
	delete?:ResolverInputTypes["PostsDocAccessFields_updatedAt_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["PostsDocAccessFields_updatedAt_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsDocAccessFields_updatedAt_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsDocAccessFields_updatedAt_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsDocAccessFields_updatedAt_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsDocAccessFields_createdAt"]: AliasType<{
	create?:ResolverInputTypes["PostsDocAccessFields_createdAt_Create"],
	read?:ResolverInputTypes["PostsDocAccessFields_createdAt_Read"],
	update?:ResolverInputTypes["PostsDocAccessFields_createdAt_Update"],
	delete?:ResolverInputTypes["PostsDocAccessFields_createdAt_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["PostsDocAccessFields_createdAt_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsDocAccessFields_createdAt_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsDocAccessFields_createdAt_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsDocAccessFields_createdAt_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsCreateDocAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsReadDocAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsUpdateDocAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsDeleteDocAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["Ticketings"]: AliasType<{
	docs?:ResolverInputTypes["Ticketing"],
	hasNextPage?:boolean | `@${string}`,
	hasPrevPage?:boolean | `@${string}`,
	limit?:boolean | `@${string}`,
	nextPage?:boolean | `@${string}`,
	offset?:boolean | `@${string}`,
	page?:boolean | `@${string}`,
	pagingCounter?:boolean | `@${string}`,
	prevPage?:boolean | `@${string}`,
	totalDocs?:boolean | `@${string}`,
	totalPages?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["Ticketing_where"]: {
	status?: ResolverInputTypes["Ticketing_status_operator"] | undefined | null,
	creator?: ResolverInputTypes["Ticketing_creator_operator"] | undefined | null,
	productType?: ResolverInputTypes["Ticketing_productType_operator"] | undefined | null,
	productName?: ResolverInputTypes["Ticketing_productName_operator"] | undefined | null,
	referenceId?: ResolverInputTypes["Ticketing_referenceId_operator"] | undefined | null,
	linkedEvent?: ResolverInputTypes["Ticketing_linkedEvent_operator"] | undefined | null,
	CheckInLevel?: ResolverInputTypes["Ticketing_CheckInLevel_operator"] | undefined | null,
	displayPrice?: ResolverInputTypes["Ticketing_displayPrice_operator"] | undefined | null,
	tnc?: ResolverInputTypes["Ticketing_tnc_operator"] | undefined | null,
	cutoffHours?: ResolverInputTypes["Ticketing_cutoffHours_operator"] | undefined | null,
	sessions__variantId?: ResolverInputTypes["Ticketing_sessions__variantId_operator"] | undefined | null,
	sessions__productId?: ResolverInputTypes["Ticketing_sessions__productId_operator"] | undefined | null,
	sessions__startDate?: ResolverInputTypes["Ticketing_sessions__startDate_operator"] | undefined | null,
	sessions__endDate?: ResolverInputTypes["Ticketing_sessions__endDate_operator"] | undefined | null,
	sessions__inventories__name?: ResolverInputTypes["Ticketing_sessions__inventories__name_operator"] | undefined | null,
	sessions__inventories__quantity?: ResolverInputTypes["Ticketing_sessions__inventories__quantity_operator"] | undefined | null,
	sessions__inventories__unitPrice?: ResolverInputTypes["Ticketing_sessions__inventories__unitPrice_operator"] | undefined | null,
	sessions__inventories__handlingFee?: ResolverInputTypes["Ticketing_sessions__inventories__handlingFee_operator"] | undefined | null,
	sessions__inventories__totalCost?: ResolverInputTypes["Ticketing_sessions__inventories__totalCost_operator"] | undefined | null,
	sessions__inventories__minPurchase?: ResolverInputTypes["Ticketing_sessions__inventories__minPurchase_operator"] | undefined | null,
	sessions__inventories__maxPurchase?: ResolverInputTypes["Ticketing_sessions__inventories__maxPurchase_operator"] | undefined | null,
	sessions__inventories__stocks__stockId?: ResolverInputTypes["Ticketing_sessions__inventories__stocks__stockId_operator"] | undefined | null,
	sessions__inventories__stocks__remaining?: ResolverInputTypes["Ticketing_sessions__inventories__stocks__remaining_operator"] | undefined | null,
	sessions__inventories__stocks__sold?: ResolverInputTypes["Ticketing_sessions__inventories__stocks__sold_operator"] | undefined | null,
	sessions__inventories__stocks__onHold?: ResolverInputTypes["Ticketing_sessions__inventories__stocks__onHold_operator"] | undefined | null,
	sessions__inventories__stocks__checkedIn?: ResolverInputTypes["Ticketing_sessions__inventories__stocks__checkedIn_operator"] | undefined | null,
	sessions__inventories__remarks?: ResolverInputTypes["Ticketing_sessions__inventories__remarks_operator"] | undefined | null,
	sessions__id?: ResolverInputTypes["Ticketing_sessions__id_operator"] | undefined | null,
	updatedAt?: ResolverInputTypes["Ticketing_updatedAt_operator"] | undefined | null,
	createdAt?: ResolverInputTypes["Ticketing_createdAt_operator"] | undefined | null,
	id?: ResolverInputTypes["Ticketing_id_operator"] | undefined | null,
	AND?: Array<ResolverInputTypes["Ticketing_where_and"] | undefined | null> | undefined | null,
	OR?: Array<ResolverInputTypes["Ticketing_where_or"] | undefined | null> | undefined | null
};
	["Ticketing_status_operator"]: {
	equals?: ResolverInputTypes["Ticketing_status_Input"] | undefined | null,
	not_equals?: ResolverInputTypes["Ticketing_status_Input"] | undefined | null,
	in?: Array<ResolverInputTypes["Ticketing_status_Input"] | undefined | null> | undefined | null,
	not_in?: Array<ResolverInputTypes["Ticketing_status_Input"] | undefined | null> | undefined | null,
	all?: Array<ResolverInputTypes["Ticketing_status_Input"] | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Ticketing_status_Input"]:Ticketing_status_Input;
	["Ticketing_creator_operator"]: {
	equals?: ResolverInputTypes["JSON"] | undefined | null,
	not_equals?: ResolverInputTypes["JSON"] | undefined | null,
	in?: Array<ResolverInputTypes["JSON"] | undefined | null> | undefined | null,
	not_in?: Array<ResolverInputTypes["JSON"] | undefined | null> | undefined | null,
	all?: Array<ResolverInputTypes["JSON"] | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Ticketing_productType_operator"]: {
	equals?: ResolverInputTypes["Ticketing_productType_Input"] | undefined | null,
	not_equals?: ResolverInputTypes["Ticketing_productType_Input"] | undefined | null,
	like?: ResolverInputTypes["Ticketing_productType_Input"] | undefined | null,
	contains?: ResolverInputTypes["Ticketing_productType_Input"] | undefined | null
};
	["Ticketing_productType_Input"]:Ticketing_productType_Input;
	["Ticketing_productName_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null
};
	["Ticketing_referenceId_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Ticketing_linkedEvent_operator"]: {
	equals?: ResolverInputTypes["JSON"] | undefined | null,
	not_equals?: ResolverInputTypes["JSON"] | undefined | null,
	in?: Array<ResolverInputTypes["JSON"] | undefined | null> | undefined | null,
	not_in?: Array<ResolverInputTypes["JSON"] | undefined | null> | undefined | null,
	all?: Array<ResolverInputTypes["JSON"] | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Ticketing_CheckInLevel_operator"]: {
	equals?: ResolverInputTypes["Ticketing_CheckInLevel_Input"] | undefined | null,
	not_equals?: ResolverInputTypes["Ticketing_CheckInLevel_Input"] | undefined | null,
	in?: Array<ResolverInputTypes["Ticketing_CheckInLevel_Input"] | undefined | null> | undefined | null,
	not_in?: Array<ResolverInputTypes["Ticketing_CheckInLevel_Input"] | undefined | null> | undefined | null,
	all?: Array<ResolverInputTypes["Ticketing_CheckInLevel_Input"] | undefined | null> | undefined | null
};
	["Ticketing_CheckInLevel_Input"]:Ticketing_CheckInLevel_Input;
	["Ticketing_displayPrice_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null
};
	["Ticketing_tnc_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null
};
	["Ticketing_cutoffHours_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null
};
	["Ticketing_sessions__variantId_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Ticketing_sessions__productId_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Ticketing_sessions__startDate_operator"]: {
	equals?: ResolverInputTypes["DateTime"] | undefined | null,
	not_equals?: ResolverInputTypes["DateTime"] | undefined | null,
	greater_than_equal?: ResolverInputTypes["DateTime"] | undefined | null,
	greater_than?: ResolverInputTypes["DateTime"] | undefined | null,
	less_than_equal?: ResolverInputTypes["DateTime"] | undefined | null,
	less_than?: ResolverInputTypes["DateTime"] | undefined | null,
	like?: ResolverInputTypes["DateTime"] | undefined | null
};
	["Ticketing_sessions__endDate_operator"]: {
	equals?: ResolverInputTypes["DateTime"] | undefined | null,
	not_equals?: ResolverInputTypes["DateTime"] | undefined | null,
	greater_than_equal?: ResolverInputTypes["DateTime"] | undefined | null,
	greater_than?: ResolverInputTypes["DateTime"] | undefined | null,
	less_than_equal?: ResolverInputTypes["DateTime"] | undefined | null,
	less_than?: ResolverInputTypes["DateTime"] | undefined | null,
	like?: ResolverInputTypes["DateTime"] | undefined | null
};
	["Ticketing_sessions__inventories__name_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null
};
	["Ticketing_sessions__inventories__quantity_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null
};
	["Ticketing_sessions__inventories__unitPrice_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null
};
	["Ticketing_sessions__inventories__handlingFee_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null
};
	["Ticketing_sessions__inventories__totalCost_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Ticketing_sessions__inventories__minPurchase_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null
};
	["Ticketing_sessions__inventories__maxPurchase_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null
};
	["Ticketing_sessions__inventories__stocks__stockId_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Ticketing_sessions__inventories__stocks__remaining_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Ticketing_sessions__inventories__stocks__sold_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Ticketing_sessions__inventories__stocks__onHold_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Ticketing_sessions__inventories__stocks__checkedIn_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Ticketing_sessions__inventories__remarks_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	exists?: boolean | undefined | null
};
	["Ticketing_sessions__id_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Ticketing_updatedAt_operator"]: {
	equals?: ResolverInputTypes["DateTime"] | undefined | null,
	not_equals?: ResolverInputTypes["DateTime"] | undefined | null,
	greater_than_equal?: ResolverInputTypes["DateTime"] | undefined | null,
	greater_than?: ResolverInputTypes["DateTime"] | undefined | null,
	less_than_equal?: ResolverInputTypes["DateTime"] | undefined | null,
	less_than?: ResolverInputTypes["DateTime"] | undefined | null,
	like?: ResolverInputTypes["DateTime"] | undefined | null,
	exists?: boolean | undefined | null
};
	["Ticketing_createdAt_operator"]: {
	equals?: ResolverInputTypes["DateTime"] | undefined | null,
	not_equals?: ResolverInputTypes["DateTime"] | undefined | null,
	greater_than_equal?: ResolverInputTypes["DateTime"] | undefined | null,
	greater_than?: ResolverInputTypes["DateTime"] | undefined | null,
	less_than_equal?: ResolverInputTypes["DateTime"] | undefined | null,
	less_than?: ResolverInputTypes["DateTime"] | undefined | null,
	like?: ResolverInputTypes["DateTime"] | undefined | null,
	exists?: boolean | undefined | null
};
	["Ticketing_id_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Ticketing_where_and"]: {
	status?: ResolverInputTypes["Ticketing_status_operator"] | undefined | null,
	creator?: ResolverInputTypes["Ticketing_creator_operator"] | undefined | null,
	productType?: ResolverInputTypes["Ticketing_productType_operator"] | undefined | null,
	productName?: ResolverInputTypes["Ticketing_productName_operator"] | undefined | null,
	referenceId?: ResolverInputTypes["Ticketing_referenceId_operator"] | undefined | null,
	linkedEvent?: ResolverInputTypes["Ticketing_linkedEvent_operator"] | undefined | null,
	CheckInLevel?: ResolverInputTypes["Ticketing_CheckInLevel_operator"] | undefined | null,
	displayPrice?: ResolverInputTypes["Ticketing_displayPrice_operator"] | undefined | null,
	tnc?: ResolverInputTypes["Ticketing_tnc_operator"] | undefined | null,
	cutoffHours?: ResolverInputTypes["Ticketing_cutoffHours_operator"] | undefined | null,
	sessions__variantId?: ResolverInputTypes["Ticketing_sessions__variantId_operator"] | undefined | null,
	sessions__productId?: ResolverInputTypes["Ticketing_sessions__productId_operator"] | undefined | null,
	sessions__startDate?: ResolverInputTypes["Ticketing_sessions__startDate_operator"] | undefined | null,
	sessions__endDate?: ResolverInputTypes["Ticketing_sessions__endDate_operator"] | undefined | null,
	sessions__inventories__name?: ResolverInputTypes["Ticketing_sessions__inventories__name_operator"] | undefined | null,
	sessions__inventories__quantity?: ResolverInputTypes["Ticketing_sessions__inventories__quantity_operator"] | undefined | null,
	sessions__inventories__unitPrice?: ResolverInputTypes["Ticketing_sessions__inventories__unitPrice_operator"] | undefined | null,
	sessions__inventories__handlingFee?: ResolverInputTypes["Ticketing_sessions__inventories__handlingFee_operator"] | undefined | null,
	sessions__inventories__totalCost?: ResolverInputTypes["Ticketing_sessions__inventories__totalCost_operator"] | undefined | null,
	sessions__inventories__minPurchase?: ResolverInputTypes["Ticketing_sessions__inventories__minPurchase_operator"] | undefined | null,
	sessions__inventories__maxPurchase?: ResolverInputTypes["Ticketing_sessions__inventories__maxPurchase_operator"] | undefined | null,
	sessions__inventories__stocks__stockId?: ResolverInputTypes["Ticketing_sessions__inventories__stocks__stockId_operator"] | undefined | null,
	sessions__inventories__stocks__remaining?: ResolverInputTypes["Ticketing_sessions__inventories__stocks__remaining_operator"] | undefined | null,
	sessions__inventories__stocks__sold?: ResolverInputTypes["Ticketing_sessions__inventories__stocks__sold_operator"] | undefined | null,
	sessions__inventories__stocks__onHold?: ResolverInputTypes["Ticketing_sessions__inventories__stocks__onHold_operator"] | undefined | null,
	sessions__inventories__stocks__checkedIn?: ResolverInputTypes["Ticketing_sessions__inventories__stocks__checkedIn_operator"] | undefined | null,
	sessions__inventories__remarks?: ResolverInputTypes["Ticketing_sessions__inventories__remarks_operator"] | undefined | null,
	sessions__id?: ResolverInputTypes["Ticketing_sessions__id_operator"] | undefined | null,
	updatedAt?: ResolverInputTypes["Ticketing_updatedAt_operator"] | undefined | null,
	createdAt?: ResolverInputTypes["Ticketing_createdAt_operator"] | undefined | null,
	id?: ResolverInputTypes["Ticketing_id_operator"] | undefined | null,
	AND?: Array<ResolverInputTypes["Ticketing_where_and"] | undefined | null> | undefined | null,
	OR?: Array<ResolverInputTypes["Ticketing_where_or"] | undefined | null> | undefined | null
};
	["Ticketing_where_or"]: {
	status?: ResolverInputTypes["Ticketing_status_operator"] | undefined | null,
	creator?: ResolverInputTypes["Ticketing_creator_operator"] | undefined | null,
	productType?: ResolverInputTypes["Ticketing_productType_operator"] | undefined | null,
	productName?: ResolverInputTypes["Ticketing_productName_operator"] | undefined | null,
	referenceId?: ResolverInputTypes["Ticketing_referenceId_operator"] | undefined | null,
	linkedEvent?: ResolverInputTypes["Ticketing_linkedEvent_operator"] | undefined | null,
	CheckInLevel?: ResolverInputTypes["Ticketing_CheckInLevel_operator"] | undefined | null,
	displayPrice?: ResolverInputTypes["Ticketing_displayPrice_operator"] | undefined | null,
	tnc?: ResolverInputTypes["Ticketing_tnc_operator"] | undefined | null,
	cutoffHours?: ResolverInputTypes["Ticketing_cutoffHours_operator"] | undefined | null,
	sessions__variantId?: ResolverInputTypes["Ticketing_sessions__variantId_operator"] | undefined | null,
	sessions__productId?: ResolverInputTypes["Ticketing_sessions__productId_operator"] | undefined | null,
	sessions__startDate?: ResolverInputTypes["Ticketing_sessions__startDate_operator"] | undefined | null,
	sessions__endDate?: ResolverInputTypes["Ticketing_sessions__endDate_operator"] | undefined | null,
	sessions__inventories__name?: ResolverInputTypes["Ticketing_sessions__inventories__name_operator"] | undefined | null,
	sessions__inventories__quantity?: ResolverInputTypes["Ticketing_sessions__inventories__quantity_operator"] | undefined | null,
	sessions__inventories__unitPrice?: ResolverInputTypes["Ticketing_sessions__inventories__unitPrice_operator"] | undefined | null,
	sessions__inventories__handlingFee?: ResolverInputTypes["Ticketing_sessions__inventories__handlingFee_operator"] | undefined | null,
	sessions__inventories__totalCost?: ResolverInputTypes["Ticketing_sessions__inventories__totalCost_operator"] | undefined | null,
	sessions__inventories__minPurchase?: ResolverInputTypes["Ticketing_sessions__inventories__minPurchase_operator"] | undefined | null,
	sessions__inventories__maxPurchase?: ResolverInputTypes["Ticketing_sessions__inventories__maxPurchase_operator"] | undefined | null,
	sessions__inventories__stocks__stockId?: ResolverInputTypes["Ticketing_sessions__inventories__stocks__stockId_operator"] | undefined | null,
	sessions__inventories__stocks__remaining?: ResolverInputTypes["Ticketing_sessions__inventories__stocks__remaining_operator"] | undefined | null,
	sessions__inventories__stocks__sold?: ResolverInputTypes["Ticketing_sessions__inventories__stocks__sold_operator"] | undefined | null,
	sessions__inventories__stocks__onHold?: ResolverInputTypes["Ticketing_sessions__inventories__stocks__onHold_operator"] | undefined | null,
	sessions__inventories__stocks__checkedIn?: ResolverInputTypes["Ticketing_sessions__inventories__stocks__checkedIn_operator"] | undefined | null,
	sessions__inventories__remarks?: ResolverInputTypes["Ticketing_sessions__inventories__remarks_operator"] | undefined | null,
	sessions__id?: ResolverInputTypes["Ticketing_sessions__id_operator"] | undefined | null,
	updatedAt?: ResolverInputTypes["Ticketing_updatedAt_operator"] | undefined | null,
	createdAt?: ResolverInputTypes["Ticketing_createdAt_operator"] | undefined | null,
	id?: ResolverInputTypes["Ticketing_id_operator"] | undefined | null,
	AND?: Array<ResolverInputTypes["Ticketing_where_and"] | undefined | null> | undefined | null,
	OR?: Array<ResolverInputTypes["Ticketing_where_or"] | undefined | null> | undefined | null
};
	["countTicketings"]: AliasType<{
	totalDocs?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ticketingDocAccess"]: AliasType<{
	fields?:ResolverInputTypes["TicketingDocAccessFields"],
	create?:ResolverInputTypes["TicketingCreateDocAccess"],
	read?:ResolverInputTypes["TicketingReadDocAccess"],
	update?:ResolverInputTypes["TicketingUpdateDocAccess"],
	delete?:ResolverInputTypes["TicketingDeleteDocAccess"],
		__typename?: boolean | `@${string}`
}>;
	["TicketingDocAccessFields"]: AliasType<{
	status?:ResolverInputTypes["TicketingDocAccessFields_status"],
	creator?:ResolverInputTypes["TicketingDocAccessFields_creator"],
	productType?:ResolverInputTypes["TicketingDocAccessFields_productType"],
	productName?:ResolverInputTypes["TicketingDocAccessFields_productName"],
	referenceId?:ResolverInputTypes["TicketingDocAccessFields_referenceId"],
	linkedEvent?:ResolverInputTypes["TicketingDocAccessFields_linkedEvent"],
	CheckInLevel?:ResolverInputTypes["TicketingDocAccessFields_CheckInLevel"],
	displayPrice?:ResolverInputTypes["TicketingDocAccessFields_displayPrice"],
	tnc?:ResolverInputTypes["TicketingDocAccessFields_tnc"],
	cutoffHours?:ResolverInputTypes["TicketingDocAccessFields_cutoffHours"],
	sessions?:ResolverInputTypes["TicketingDocAccessFields_sessions"],
	updatedAt?:ResolverInputTypes["TicketingDocAccessFields_updatedAt"],
	createdAt?:ResolverInputTypes["TicketingDocAccessFields_createdAt"],
		__typename?: boolean | `@${string}`
}>;
	["TicketingDocAccessFields_status"]: AliasType<{
	create?:ResolverInputTypes["TicketingDocAccessFields_status_Create"],
	read?:ResolverInputTypes["TicketingDocAccessFields_status_Read"],
	update?:ResolverInputTypes["TicketingDocAccessFields_status_Update"],
	delete?:ResolverInputTypes["TicketingDocAccessFields_status_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["TicketingDocAccessFields_status_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingDocAccessFields_status_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingDocAccessFields_status_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingDocAccessFields_status_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingDocAccessFields_creator"]: AliasType<{
	create?:ResolverInputTypes["TicketingDocAccessFields_creator_Create"],
	read?:ResolverInputTypes["TicketingDocAccessFields_creator_Read"],
	update?:ResolverInputTypes["TicketingDocAccessFields_creator_Update"],
	delete?:ResolverInputTypes["TicketingDocAccessFields_creator_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["TicketingDocAccessFields_creator_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingDocAccessFields_creator_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingDocAccessFields_creator_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingDocAccessFields_creator_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingDocAccessFields_productType"]: AliasType<{
	create?:ResolverInputTypes["TicketingDocAccessFields_productType_Create"],
	read?:ResolverInputTypes["TicketingDocAccessFields_productType_Read"],
	update?:ResolverInputTypes["TicketingDocAccessFields_productType_Update"],
	delete?:ResolverInputTypes["TicketingDocAccessFields_productType_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["TicketingDocAccessFields_productType_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingDocAccessFields_productType_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingDocAccessFields_productType_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingDocAccessFields_productType_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingDocAccessFields_productName"]: AliasType<{
	create?:ResolverInputTypes["TicketingDocAccessFields_productName_Create"],
	read?:ResolverInputTypes["TicketingDocAccessFields_productName_Read"],
	update?:ResolverInputTypes["TicketingDocAccessFields_productName_Update"],
	delete?:ResolverInputTypes["TicketingDocAccessFields_productName_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["TicketingDocAccessFields_productName_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingDocAccessFields_productName_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingDocAccessFields_productName_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingDocAccessFields_productName_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingDocAccessFields_referenceId"]: AliasType<{
	create?:ResolverInputTypes["TicketingDocAccessFields_referenceId_Create"],
	read?:ResolverInputTypes["TicketingDocAccessFields_referenceId_Read"],
	update?:ResolverInputTypes["TicketingDocAccessFields_referenceId_Update"],
	delete?:ResolverInputTypes["TicketingDocAccessFields_referenceId_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["TicketingDocAccessFields_referenceId_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingDocAccessFields_referenceId_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingDocAccessFields_referenceId_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingDocAccessFields_referenceId_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingDocAccessFields_linkedEvent"]: AliasType<{
	create?:ResolverInputTypes["TicketingDocAccessFields_linkedEvent_Create"],
	read?:ResolverInputTypes["TicketingDocAccessFields_linkedEvent_Read"],
	update?:ResolverInputTypes["TicketingDocAccessFields_linkedEvent_Update"],
	delete?:ResolverInputTypes["TicketingDocAccessFields_linkedEvent_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["TicketingDocAccessFields_linkedEvent_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingDocAccessFields_linkedEvent_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingDocAccessFields_linkedEvent_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingDocAccessFields_linkedEvent_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingDocAccessFields_CheckInLevel"]: AliasType<{
	create?:ResolverInputTypes["TicketingDocAccessFields_CheckInLevel_Create"],
	read?:ResolverInputTypes["TicketingDocAccessFields_CheckInLevel_Read"],
	update?:ResolverInputTypes["TicketingDocAccessFields_CheckInLevel_Update"],
	delete?:ResolverInputTypes["TicketingDocAccessFields_CheckInLevel_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["TicketingDocAccessFields_CheckInLevel_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingDocAccessFields_CheckInLevel_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingDocAccessFields_CheckInLevel_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingDocAccessFields_CheckInLevel_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingDocAccessFields_displayPrice"]: AliasType<{
	create?:ResolverInputTypes["TicketingDocAccessFields_displayPrice_Create"],
	read?:ResolverInputTypes["TicketingDocAccessFields_displayPrice_Read"],
	update?:ResolverInputTypes["TicketingDocAccessFields_displayPrice_Update"],
	delete?:ResolverInputTypes["TicketingDocAccessFields_displayPrice_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["TicketingDocAccessFields_displayPrice_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingDocAccessFields_displayPrice_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingDocAccessFields_displayPrice_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingDocAccessFields_displayPrice_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingDocAccessFields_tnc"]: AliasType<{
	create?:ResolverInputTypes["TicketingDocAccessFields_tnc_Create"],
	read?:ResolverInputTypes["TicketingDocAccessFields_tnc_Read"],
	update?:ResolverInputTypes["TicketingDocAccessFields_tnc_Update"],
	delete?:ResolverInputTypes["TicketingDocAccessFields_tnc_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["TicketingDocAccessFields_tnc_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingDocAccessFields_tnc_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingDocAccessFields_tnc_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingDocAccessFields_tnc_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingDocAccessFields_cutoffHours"]: AliasType<{
	create?:ResolverInputTypes["TicketingDocAccessFields_cutoffHours_Create"],
	read?:ResolverInputTypes["TicketingDocAccessFields_cutoffHours_Read"],
	update?:ResolverInputTypes["TicketingDocAccessFields_cutoffHours_Update"],
	delete?:ResolverInputTypes["TicketingDocAccessFields_cutoffHours_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["TicketingDocAccessFields_cutoffHours_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingDocAccessFields_cutoffHours_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingDocAccessFields_cutoffHours_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingDocAccessFields_cutoffHours_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingDocAccessFields_sessions"]: AliasType<{
	create?:ResolverInputTypes["TicketingDocAccessFields_sessions_Create"],
	read?:ResolverInputTypes["TicketingDocAccessFields_sessions_Read"],
	update?:ResolverInputTypes["TicketingDocAccessFields_sessions_Update"],
	delete?:ResolverInputTypes["TicketingDocAccessFields_sessions_Delete"],
	fields?:ResolverInputTypes["TicketingDocAccessFields_sessions_Fields"],
		__typename?: boolean | `@${string}`
}>;
	["TicketingDocAccessFields_sessions_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingDocAccessFields_sessions_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingDocAccessFields_sessions_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingDocAccessFields_sessions_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingDocAccessFields_sessions_Fields"]: AliasType<{
	productId?:ResolverInputTypes["TicketingDocAccessFields_sessions_productId"],
	startDate?:ResolverInputTypes["TicketingDocAccessFields_sessions_startDate"],
	endDate?:ResolverInputTypes["TicketingDocAccessFields_sessions_endDate"],
	inventories?:ResolverInputTypes["TicketingDocAccessFields_sessions_inventories"],
	id?:ResolverInputTypes["TicketingDocAccessFields_sessions_id"],
		__typename?: boolean | `@${string}`
}>;
	["TicketingDocAccessFields_sessions_productId"]: AliasType<{
	create?:ResolverInputTypes["TicketingDocAccessFields_sessions_productId_Create"],
	read?:ResolverInputTypes["TicketingDocAccessFields_sessions_productId_Read"],
	update?:ResolverInputTypes["TicketingDocAccessFields_sessions_productId_Update"],
	delete?:ResolverInputTypes["TicketingDocAccessFields_sessions_productId_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["TicketingDocAccessFields_sessions_productId_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingDocAccessFields_sessions_productId_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingDocAccessFields_sessions_productId_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingDocAccessFields_sessions_productId_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingDocAccessFields_sessions_startDate"]: AliasType<{
	create?:ResolverInputTypes["TicketingDocAccessFields_sessions_startDate_Create"],
	read?:ResolverInputTypes["TicketingDocAccessFields_sessions_startDate_Read"],
	update?:ResolverInputTypes["TicketingDocAccessFields_sessions_startDate_Update"],
	delete?:ResolverInputTypes["TicketingDocAccessFields_sessions_startDate_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["TicketingDocAccessFields_sessions_startDate_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingDocAccessFields_sessions_startDate_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingDocAccessFields_sessions_startDate_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingDocAccessFields_sessions_startDate_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingDocAccessFields_sessions_endDate"]: AliasType<{
	create?:ResolverInputTypes["TicketingDocAccessFields_sessions_endDate_Create"],
	read?:ResolverInputTypes["TicketingDocAccessFields_sessions_endDate_Read"],
	update?:ResolverInputTypes["TicketingDocAccessFields_sessions_endDate_Update"],
	delete?:ResolverInputTypes["TicketingDocAccessFields_sessions_endDate_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["TicketingDocAccessFields_sessions_endDate_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingDocAccessFields_sessions_endDate_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingDocAccessFields_sessions_endDate_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingDocAccessFields_sessions_endDate_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingDocAccessFields_sessions_inventories"]: AliasType<{
	create?:ResolverInputTypes["TicketingDocAccessFields_sessions_inventories_Create"],
	read?:ResolverInputTypes["TicketingDocAccessFields_sessions_inventories_Read"],
	update?:ResolverInputTypes["TicketingDocAccessFields_sessions_inventories_Update"],
	delete?:ResolverInputTypes["TicketingDocAccessFields_sessions_inventories_Delete"],
	fields?:ResolverInputTypes["TicketingDocAccessFields_sessions_inventories_Fields"],
		__typename?: boolean | `@${string}`
}>;
	["TicketingDocAccessFields_sessions_inventories_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingDocAccessFields_sessions_inventories_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingDocAccessFields_sessions_inventories_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingDocAccessFields_sessions_inventories_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingDocAccessFields_sessions_inventories_Fields"]: AliasType<{
	name?:ResolverInputTypes["TicketingDocAccessFields_sessions_inventories_name"],
	quantity?:ResolverInputTypes["TicketingDocAccessFields_sessions_inventories_quantity"],
	unitPrice?:ResolverInputTypes["TicketingDocAccessFields_sessions_inventories_unitPrice"],
	handlingFee?:ResolverInputTypes["TicketingDocAccessFields_sessions_inventories_handlingFee"],
	totalCost?:ResolverInputTypes["TicketingDocAccessFields_sessions_inventories_totalCost"],
	minPurchase?:ResolverInputTypes["TicketingDocAccessFields_sessions_inventories_minPurchase"],
	maxPurchase?:ResolverInputTypes["TicketingDocAccessFields_sessions_inventories_maxPurchase"],
	stocks?:ResolverInputTypes["TicketingDocAccessFields_sessions_inventories_stocks"],
	remarks?:ResolverInputTypes["TicketingDocAccessFields_sessions_inventories_remarks"],
		__typename?: boolean | `@${string}`
}>;
	["TicketingDocAccessFields_sessions_inventories_name"]: AliasType<{
	create?:ResolverInputTypes["TicketingDocAccessFields_sessions_inventories_name_Create"],
	read?:ResolverInputTypes["TicketingDocAccessFields_sessions_inventories_name_Read"],
	update?:ResolverInputTypes["TicketingDocAccessFields_sessions_inventories_name_Update"],
	delete?:ResolverInputTypes["TicketingDocAccessFields_sessions_inventories_name_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["TicketingDocAccessFields_sessions_inventories_name_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingDocAccessFields_sessions_inventories_name_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingDocAccessFields_sessions_inventories_name_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingDocAccessFields_sessions_inventories_name_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingDocAccessFields_sessions_inventories_quantity"]: AliasType<{
	create?:ResolverInputTypes["TicketingDocAccessFields_sessions_inventories_quantity_Create"],
	read?:ResolverInputTypes["TicketingDocAccessFields_sessions_inventories_quantity_Read"],
	update?:ResolverInputTypes["TicketingDocAccessFields_sessions_inventories_quantity_Update"],
	delete?:ResolverInputTypes["TicketingDocAccessFields_sessions_inventories_quantity_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["TicketingDocAccessFields_sessions_inventories_quantity_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingDocAccessFields_sessions_inventories_quantity_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingDocAccessFields_sessions_inventories_quantity_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingDocAccessFields_sessions_inventories_quantity_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingDocAccessFields_sessions_inventories_unitPrice"]: AliasType<{
	create?:ResolverInputTypes["TicketingDocAccessFields_sessions_inventories_unitPrice_Create"],
	read?:ResolverInputTypes["TicketingDocAccessFields_sessions_inventories_unitPrice_Read"],
	update?:ResolverInputTypes["TicketingDocAccessFields_sessions_inventories_unitPrice_Update"],
	delete?:ResolverInputTypes["TicketingDocAccessFields_sessions_inventories_unitPrice_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["TicketingDocAccessFields_sessions_inventories_unitPrice_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingDocAccessFields_sessions_inventories_unitPrice_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingDocAccessFields_sessions_inventories_unitPrice_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingDocAccessFields_sessions_inventories_unitPrice_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingDocAccessFields_sessions_inventories_handlingFee"]: AliasType<{
	create?:ResolverInputTypes["TicketingDocAccessFields_sessions_inventories_handlingFee_Create"],
	read?:ResolverInputTypes["TicketingDocAccessFields_sessions_inventories_handlingFee_Read"],
	update?:ResolverInputTypes["TicketingDocAccessFields_sessions_inventories_handlingFee_Update"],
	delete?:ResolverInputTypes["TicketingDocAccessFields_sessions_inventories_handlingFee_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["TicketingDocAccessFields_sessions_inventories_handlingFee_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingDocAccessFields_sessions_inventories_handlingFee_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingDocAccessFields_sessions_inventories_handlingFee_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingDocAccessFields_sessions_inventories_handlingFee_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingDocAccessFields_sessions_inventories_totalCost"]: AliasType<{
	create?:ResolverInputTypes["TicketingDocAccessFields_sessions_inventories_totalCost_Create"],
	read?:ResolverInputTypes["TicketingDocAccessFields_sessions_inventories_totalCost_Read"],
	update?:ResolverInputTypes["TicketingDocAccessFields_sessions_inventories_totalCost_Update"],
	delete?:ResolverInputTypes["TicketingDocAccessFields_sessions_inventories_totalCost_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["TicketingDocAccessFields_sessions_inventories_totalCost_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingDocAccessFields_sessions_inventories_totalCost_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingDocAccessFields_sessions_inventories_totalCost_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingDocAccessFields_sessions_inventories_totalCost_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingDocAccessFields_sessions_inventories_minPurchase"]: AliasType<{
	create?:ResolverInputTypes["TicketingDocAccessFields_sessions_inventories_minPurchase_Create"],
	read?:ResolverInputTypes["TicketingDocAccessFields_sessions_inventories_minPurchase_Read"],
	update?:ResolverInputTypes["TicketingDocAccessFields_sessions_inventories_minPurchase_Update"],
	delete?:ResolverInputTypes["TicketingDocAccessFields_sessions_inventories_minPurchase_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["TicketingDocAccessFields_sessions_inventories_minPurchase_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingDocAccessFields_sessions_inventories_minPurchase_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingDocAccessFields_sessions_inventories_minPurchase_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingDocAccessFields_sessions_inventories_minPurchase_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingDocAccessFields_sessions_inventories_maxPurchase"]: AliasType<{
	create?:ResolverInputTypes["TicketingDocAccessFields_sessions_inventories_maxPurchase_Create"],
	read?:ResolverInputTypes["TicketingDocAccessFields_sessions_inventories_maxPurchase_Read"],
	update?:ResolverInputTypes["TicketingDocAccessFields_sessions_inventories_maxPurchase_Update"],
	delete?:ResolverInputTypes["TicketingDocAccessFields_sessions_inventories_maxPurchase_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["TicketingDocAccessFields_sessions_inventories_maxPurchase_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingDocAccessFields_sessions_inventories_maxPurchase_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingDocAccessFields_sessions_inventories_maxPurchase_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingDocAccessFields_sessions_inventories_maxPurchase_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingDocAccessFields_sessions_inventories_stocks"]: AliasType<{
	create?:ResolverInputTypes["TicketingDocAccessFields_sessions_inventories_stocks_Create"],
	read?:ResolverInputTypes["TicketingDocAccessFields_sessions_inventories_stocks_Read"],
	update?:ResolverInputTypes["TicketingDocAccessFields_sessions_inventories_stocks_Update"],
	delete?:ResolverInputTypes["TicketingDocAccessFields_sessions_inventories_stocks_Delete"],
	fields?:ResolverInputTypes["TicketingDocAccessFields_sessions_inventories_stocks_Fields"],
		__typename?: boolean | `@${string}`
}>;
	["TicketingDocAccessFields_sessions_inventories_stocks_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingDocAccessFields_sessions_inventories_stocks_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingDocAccessFields_sessions_inventories_stocks_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingDocAccessFields_sessions_inventories_stocks_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingDocAccessFields_sessions_inventories_stocks_Fields"]: AliasType<{
	stockId?:ResolverInputTypes["TicketingDocAccessFields_sessions_inventories_stocks_stockId"],
	remaining?:ResolverInputTypes["TicketingDocAccessFields_sessions_inventories_stocks_remaining"],
	sold?:ResolverInputTypes["TicketingDocAccessFields_sessions_inventories_stocks_sold"],
	onHold?:ResolverInputTypes["TicketingDocAccessFields_sessions_inventories_stocks_onHold"],
	checkedIn?:ResolverInputTypes["TicketingDocAccessFields_sessions_inventories_stocks_checkedIn"],
		__typename?: boolean | `@${string}`
}>;
	["TicketingDocAccessFields_sessions_inventories_stocks_stockId"]: AliasType<{
	create?:ResolverInputTypes["TicketingDocAccessFields_sessions_inventories_stocks_stockId_Create"],
	read?:ResolverInputTypes["TicketingDocAccessFields_sessions_inventories_stocks_stockId_Read"],
	update?:ResolverInputTypes["TicketingDocAccessFields_sessions_inventories_stocks_stockId_Update"],
	delete?:ResolverInputTypes["TicketingDocAccessFields_sessions_inventories_stocks_stockId_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["TicketingDocAccessFields_sessions_inventories_stocks_stockId_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingDocAccessFields_sessions_inventories_stocks_stockId_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingDocAccessFields_sessions_inventories_stocks_stockId_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingDocAccessFields_sessions_inventories_stocks_stockId_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingDocAccessFields_sessions_inventories_stocks_remaining"]: AliasType<{
	create?:ResolverInputTypes["TicketingDocAccessFields_sessions_inventories_stocks_remaining_Create"],
	read?:ResolverInputTypes["TicketingDocAccessFields_sessions_inventories_stocks_remaining_Read"],
	update?:ResolverInputTypes["TicketingDocAccessFields_sessions_inventories_stocks_remaining_Update"],
	delete?:ResolverInputTypes["TicketingDocAccessFields_sessions_inventories_stocks_remaining_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["TicketingDocAccessFields_sessions_inventories_stocks_remaining_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingDocAccessFields_sessions_inventories_stocks_remaining_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingDocAccessFields_sessions_inventories_stocks_remaining_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingDocAccessFields_sessions_inventories_stocks_remaining_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingDocAccessFields_sessions_inventories_stocks_sold"]: AliasType<{
	create?:ResolverInputTypes["TicketingDocAccessFields_sessions_inventories_stocks_sold_Create"],
	read?:ResolverInputTypes["TicketingDocAccessFields_sessions_inventories_stocks_sold_Read"],
	update?:ResolverInputTypes["TicketingDocAccessFields_sessions_inventories_stocks_sold_Update"],
	delete?:ResolverInputTypes["TicketingDocAccessFields_sessions_inventories_stocks_sold_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["TicketingDocAccessFields_sessions_inventories_stocks_sold_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingDocAccessFields_sessions_inventories_stocks_sold_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingDocAccessFields_sessions_inventories_stocks_sold_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingDocAccessFields_sessions_inventories_stocks_sold_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingDocAccessFields_sessions_inventories_stocks_onHold"]: AliasType<{
	create?:ResolverInputTypes["TicketingDocAccessFields_sessions_inventories_stocks_onHold_Create"],
	read?:ResolverInputTypes["TicketingDocAccessFields_sessions_inventories_stocks_onHold_Read"],
	update?:ResolverInputTypes["TicketingDocAccessFields_sessions_inventories_stocks_onHold_Update"],
	delete?:ResolverInputTypes["TicketingDocAccessFields_sessions_inventories_stocks_onHold_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["TicketingDocAccessFields_sessions_inventories_stocks_onHold_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingDocAccessFields_sessions_inventories_stocks_onHold_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingDocAccessFields_sessions_inventories_stocks_onHold_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingDocAccessFields_sessions_inventories_stocks_onHold_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingDocAccessFields_sessions_inventories_stocks_checkedIn"]: AliasType<{
	create?:ResolverInputTypes["TicketingDocAccessFields_sessions_inventories_stocks_checkedIn_Create"],
	read?:ResolverInputTypes["TicketingDocAccessFields_sessions_inventories_stocks_checkedIn_Read"],
	update?:ResolverInputTypes["TicketingDocAccessFields_sessions_inventories_stocks_checkedIn_Update"],
	delete?:ResolverInputTypes["TicketingDocAccessFields_sessions_inventories_stocks_checkedIn_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["TicketingDocAccessFields_sessions_inventories_stocks_checkedIn_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingDocAccessFields_sessions_inventories_stocks_checkedIn_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingDocAccessFields_sessions_inventories_stocks_checkedIn_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingDocAccessFields_sessions_inventories_stocks_checkedIn_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingDocAccessFields_sessions_inventories_remarks"]: AliasType<{
	create?:ResolverInputTypes["TicketingDocAccessFields_sessions_inventories_remarks_Create"],
	read?:ResolverInputTypes["TicketingDocAccessFields_sessions_inventories_remarks_Read"],
	update?:ResolverInputTypes["TicketingDocAccessFields_sessions_inventories_remarks_Update"],
	delete?:ResolverInputTypes["TicketingDocAccessFields_sessions_inventories_remarks_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["TicketingDocAccessFields_sessions_inventories_remarks_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingDocAccessFields_sessions_inventories_remarks_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingDocAccessFields_sessions_inventories_remarks_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingDocAccessFields_sessions_inventories_remarks_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingDocAccessFields_sessions_id"]: AliasType<{
	create?:ResolverInputTypes["TicketingDocAccessFields_sessions_id_Create"],
	read?:ResolverInputTypes["TicketingDocAccessFields_sessions_id_Read"],
	update?:ResolverInputTypes["TicketingDocAccessFields_sessions_id_Update"],
	delete?:ResolverInputTypes["TicketingDocAccessFields_sessions_id_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["TicketingDocAccessFields_sessions_id_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingDocAccessFields_sessions_id_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingDocAccessFields_sessions_id_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingDocAccessFields_sessions_id_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingDocAccessFields_updatedAt"]: AliasType<{
	create?:ResolverInputTypes["TicketingDocAccessFields_updatedAt_Create"],
	read?:ResolverInputTypes["TicketingDocAccessFields_updatedAt_Read"],
	update?:ResolverInputTypes["TicketingDocAccessFields_updatedAt_Update"],
	delete?:ResolverInputTypes["TicketingDocAccessFields_updatedAt_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["TicketingDocAccessFields_updatedAt_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingDocAccessFields_updatedAt_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingDocAccessFields_updatedAt_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingDocAccessFields_updatedAt_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingDocAccessFields_createdAt"]: AliasType<{
	create?:ResolverInputTypes["TicketingDocAccessFields_createdAt_Create"],
	read?:ResolverInputTypes["TicketingDocAccessFields_createdAt_Read"],
	update?:ResolverInputTypes["TicketingDocAccessFields_createdAt_Update"],
	delete?:ResolverInputTypes["TicketingDocAccessFields_createdAt_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["TicketingDocAccessFields_createdAt_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingDocAccessFields_createdAt_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingDocAccessFields_createdAt_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingDocAccessFields_createdAt_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingCreateDocAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingReadDocAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingUpdateDocAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingDeleteDocAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["Products"]: AliasType<{
	docs?:ResolverInputTypes["Product"],
	hasNextPage?:boolean | `@${string}`,
	hasPrevPage?:boolean | `@${string}`,
	limit?:boolean | `@${string}`,
	nextPage?:boolean | `@${string}`,
	offset?:boolean | `@${string}`,
	page?:boolean | `@${string}`,
	pagingCounter?:boolean | `@${string}`,
	prevPage?:boolean | `@${string}`,
	totalDocs?:boolean | `@${string}`,
	totalPages?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["Product_where"]: {
	status?: ResolverInputTypes["Product_status_operator"] | undefined | null,
	creator?: ResolverInputTypes["Product_creator_operator"] | undefined | null,
	productType?: ResolverInputTypes["Product_productType_operator"] | undefined | null,
	productName?: ResolverInputTypes["Product_productName_operator"] | undefined | null,
	referenceId?: ResolverInputTypes["Product_referenceId_operator"] | undefined | null,
	linkedEvent?: ResolverInputTypes["Product_linkedEvent_operator"] | undefined | null,
	CheckInLevel?: ResolverInputTypes["Product_CheckInLevel_operator"] | undefined | null,
	thumbnail?: ResolverInputTypes["Product_thumbnail_operator"] | undefined | null,
	displayPrice?: ResolverInputTypes["Product_displayPrice_operator"] | undefined | null,
	transactionType?: ResolverInputTypes["Product_transactionType_operator"] | undefined | null,
	website?: ResolverInputTypes["Product_website_operator"] | undefined | null,
	tnc?: ResolverInputTypes["Product_tnc_operator"] | undefined | null,
	remarks?: ResolverInputTypes["Product_remarks_operator"] | undefined | null,
	sessions__variantId?: ResolverInputTypes["Product_sessions__variantId_operator"] | undefined | null,
	sessions__productId?: ResolverInputTypes["Product_sessions__productId_operator"] | undefined | null,
	sessions__name?: ResolverInputTypes["Product_sessions__name_operator"] | undefined | null,
	sessions__inventories__quantity?: ResolverInputTypes["Product_sessions__inventories__quantity_operator"] | undefined | null,
	sessions__inventories__unitPrice?: ResolverInputTypes["Product_sessions__inventories__unitPrice_operator"] | undefined | null,
	sessions__inventories__handlingFee?: ResolverInputTypes["Product_sessions__inventories__handlingFee_operator"] | undefined | null,
	sessions__inventories__totalCost?: ResolverInputTypes["Product_sessions__inventories__totalCost_operator"] | undefined | null,
	sessions__inventories__minPurchase?: ResolverInputTypes["Product_sessions__inventories__minPurchase_operator"] | undefined | null,
	sessions__inventories__maxPurchase?: ResolverInputTypes["Product_sessions__inventories__maxPurchase_operator"] | undefined | null,
	sessions__inventories__stocks__stockId?: ResolverInputTypes["Product_sessions__inventories__stocks__stockId_operator"] | undefined | null,
	sessions__inventories__stocks__remaining?: ResolverInputTypes["Product_sessions__inventories__stocks__remaining_operator"] | undefined | null,
	sessions__inventories__stocks__sold?: ResolverInputTypes["Product_sessions__inventories__stocks__sold_operator"] | undefined | null,
	sessions__inventories__stocks__onHold?: ResolverInputTypes["Product_sessions__inventories__stocks__onHold_operator"] | undefined | null,
	sessions__inventories__stocks__checkedIn?: ResolverInputTypes["Product_sessions__inventories__stocks__checkedIn_operator"] | undefined | null,
	sessions__id?: ResolverInputTypes["Product_sessions__id_operator"] | undefined | null,
	updatedAt?: ResolverInputTypes["Product_updatedAt_operator"] | undefined | null,
	createdAt?: ResolverInputTypes["Product_createdAt_operator"] | undefined | null,
	id?: ResolverInputTypes["Product_id_operator"] | undefined | null,
	AND?: Array<ResolverInputTypes["Product_where_and"] | undefined | null> | undefined | null,
	OR?: Array<ResolverInputTypes["Product_where_or"] | undefined | null> | undefined | null
};
	["Product_status_operator"]: {
	equals?: ResolverInputTypes["Product_status_Input"] | undefined | null,
	not_equals?: ResolverInputTypes["Product_status_Input"] | undefined | null,
	in?: Array<ResolverInputTypes["Product_status_Input"] | undefined | null> | undefined | null,
	not_in?: Array<ResolverInputTypes["Product_status_Input"] | undefined | null> | undefined | null,
	all?: Array<ResolverInputTypes["Product_status_Input"] | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Product_status_Input"]:Product_status_Input;
	["Product_creator_operator"]: {
	equals?: ResolverInputTypes["JSON"] | undefined | null,
	not_equals?: ResolverInputTypes["JSON"] | undefined | null,
	in?: Array<ResolverInputTypes["JSON"] | undefined | null> | undefined | null,
	not_in?: Array<ResolverInputTypes["JSON"] | undefined | null> | undefined | null,
	all?: Array<ResolverInputTypes["JSON"] | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Product_productType_operator"]: {
	equals?: ResolverInputTypes["Product_productType_Input"] | undefined | null,
	not_equals?: ResolverInputTypes["Product_productType_Input"] | undefined | null,
	like?: ResolverInputTypes["Product_productType_Input"] | undefined | null,
	contains?: ResolverInputTypes["Product_productType_Input"] | undefined | null
};
	["Product_productType_Input"]:Product_productType_Input;
	["Product_productName_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null
};
	["Product_referenceId_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Product_linkedEvent_operator"]: {
	equals?: ResolverInputTypes["JSON"] | undefined | null,
	not_equals?: ResolverInputTypes["JSON"] | undefined | null,
	in?: Array<ResolverInputTypes["JSON"] | undefined | null> | undefined | null,
	not_in?: Array<ResolverInputTypes["JSON"] | undefined | null> | undefined | null,
	all?: Array<ResolverInputTypes["JSON"] | undefined | null> | undefined | null
};
	["Product_CheckInLevel_operator"]: {
	equals?: ResolverInputTypes["Product_CheckInLevel_Input"] | undefined | null,
	not_equals?: ResolverInputTypes["Product_CheckInLevel_Input"] | undefined | null,
	in?: Array<ResolverInputTypes["Product_CheckInLevel_Input"] | undefined | null> | undefined | null,
	not_in?: Array<ResolverInputTypes["Product_CheckInLevel_Input"] | undefined | null> | undefined | null,
	all?: Array<ResolverInputTypes["Product_CheckInLevel_Input"] | undefined | null> | undefined | null
};
	["Product_CheckInLevel_Input"]:Product_CheckInLevel_Input;
	["Product_thumbnail_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null
};
	["Product_displayPrice_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null
};
	["Product_transactionType_operator"]: {
	equals?: ResolverInputTypes["Product_transactionType_Input"] | undefined | null,
	not_equals?: ResolverInputTypes["Product_transactionType_Input"] | undefined | null,
	like?: ResolverInputTypes["Product_transactionType_Input"] | undefined | null,
	contains?: ResolverInputTypes["Product_transactionType_Input"] | undefined | null
};
	["Product_transactionType_Input"]:Product_transactionType_Input;
	["Product_website_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null
};
	["Product_tnc_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null
};
	["Product_remarks_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	exists?: boolean | undefined | null
};
	["Product_sessions__variantId_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Product_sessions__productId_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Product_sessions__name_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null
};
	["Product_sessions__inventories__quantity_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null
};
	["Product_sessions__inventories__unitPrice_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null
};
	["Product_sessions__inventories__handlingFee_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null
};
	["Product_sessions__inventories__totalCost_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Product_sessions__inventories__minPurchase_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null
};
	["Product_sessions__inventories__maxPurchase_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null
};
	["Product_sessions__inventories__stocks__stockId_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Product_sessions__inventories__stocks__remaining_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Product_sessions__inventories__stocks__sold_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Product_sessions__inventories__stocks__onHold_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Product_sessions__inventories__stocks__checkedIn_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Product_sessions__id_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Product_updatedAt_operator"]: {
	equals?: ResolverInputTypes["DateTime"] | undefined | null,
	not_equals?: ResolverInputTypes["DateTime"] | undefined | null,
	greater_than_equal?: ResolverInputTypes["DateTime"] | undefined | null,
	greater_than?: ResolverInputTypes["DateTime"] | undefined | null,
	less_than_equal?: ResolverInputTypes["DateTime"] | undefined | null,
	less_than?: ResolverInputTypes["DateTime"] | undefined | null,
	like?: ResolverInputTypes["DateTime"] | undefined | null,
	exists?: boolean | undefined | null
};
	["Product_createdAt_operator"]: {
	equals?: ResolverInputTypes["DateTime"] | undefined | null,
	not_equals?: ResolverInputTypes["DateTime"] | undefined | null,
	greater_than_equal?: ResolverInputTypes["DateTime"] | undefined | null,
	greater_than?: ResolverInputTypes["DateTime"] | undefined | null,
	less_than_equal?: ResolverInputTypes["DateTime"] | undefined | null,
	less_than?: ResolverInputTypes["DateTime"] | undefined | null,
	like?: ResolverInputTypes["DateTime"] | undefined | null,
	exists?: boolean | undefined | null
};
	["Product_id_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Product_where_and"]: {
	status?: ResolverInputTypes["Product_status_operator"] | undefined | null,
	creator?: ResolverInputTypes["Product_creator_operator"] | undefined | null,
	productType?: ResolverInputTypes["Product_productType_operator"] | undefined | null,
	productName?: ResolverInputTypes["Product_productName_operator"] | undefined | null,
	referenceId?: ResolverInputTypes["Product_referenceId_operator"] | undefined | null,
	linkedEvent?: ResolverInputTypes["Product_linkedEvent_operator"] | undefined | null,
	CheckInLevel?: ResolverInputTypes["Product_CheckInLevel_operator"] | undefined | null,
	thumbnail?: ResolverInputTypes["Product_thumbnail_operator"] | undefined | null,
	displayPrice?: ResolverInputTypes["Product_displayPrice_operator"] | undefined | null,
	transactionType?: ResolverInputTypes["Product_transactionType_operator"] | undefined | null,
	website?: ResolverInputTypes["Product_website_operator"] | undefined | null,
	tnc?: ResolverInputTypes["Product_tnc_operator"] | undefined | null,
	remarks?: ResolverInputTypes["Product_remarks_operator"] | undefined | null,
	sessions__variantId?: ResolverInputTypes["Product_sessions__variantId_operator"] | undefined | null,
	sessions__productId?: ResolverInputTypes["Product_sessions__productId_operator"] | undefined | null,
	sessions__name?: ResolverInputTypes["Product_sessions__name_operator"] | undefined | null,
	sessions__inventories__quantity?: ResolverInputTypes["Product_sessions__inventories__quantity_operator"] | undefined | null,
	sessions__inventories__unitPrice?: ResolverInputTypes["Product_sessions__inventories__unitPrice_operator"] | undefined | null,
	sessions__inventories__handlingFee?: ResolverInputTypes["Product_sessions__inventories__handlingFee_operator"] | undefined | null,
	sessions__inventories__totalCost?: ResolverInputTypes["Product_sessions__inventories__totalCost_operator"] | undefined | null,
	sessions__inventories__minPurchase?: ResolverInputTypes["Product_sessions__inventories__minPurchase_operator"] | undefined | null,
	sessions__inventories__maxPurchase?: ResolverInputTypes["Product_sessions__inventories__maxPurchase_operator"] | undefined | null,
	sessions__inventories__stocks__stockId?: ResolverInputTypes["Product_sessions__inventories__stocks__stockId_operator"] | undefined | null,
	sessions__inventories__stocks__remaining?: ResolverInputTypes["Product_sessions__inventories__stocks__remaining_operator"] | undefined | null,
	sessions__inventories__stocks__sold?: ResolverInputTypes["Product_sessions__inventories__stocks__sold_operator"] | undefined | null,
	sessions__inventories__stocks__onHold?: ResolverInputTypes["Product_sessions__inventories__stocks__onHold_operator"] | undefined | null,
	sessions__inventories__stocks__checkedIn?: ResolverInputTypes["Product_sessions__inventories__stocks__checkedIn_operator"] | undefined | null,
	sessions__id?: ResolverInputTypes["Product_sessions__id_operator"] | undefined | null,
	updatedAt?: ResolverInputTypes["Product_updatedAt_operator"] | undefined | null,
	createdAt?: ResolverInputTypes["Product_createdAt_operator"] | undefined | null,
	id?: ResolverInputTypes["Product_id_operator"] | undefined | null,
	AND?: Array<ResolverInputTypes["Product_where_and"] | undefined | null> | undefined | null,
	OR?: Array<ResolverInputTypes["Product_where_or"] | undefined | null> | undefined | null
};
	["Product_where_or"]: {
	status?: ResolverInputTypes["Product_status_operator"] | undefined | null,
	creator?: ResolverInputTypes["Product_creator_operator"] | undefined | null,
	productType?: ResolverInputTypes["Product_productType_operator"] | undefined | null,
	productName?: ResolverInputTypes["Product_productName_operator"] | undefined | null,
	referenceId?: ResolverInputTypes["Product_referenceId_operator"] | undefined | null,
	linkedEvent?: ResolverInputTypes["Product_linkedEvent_operator"] | undefined | null,
	CheckInLevel?: ResolverInputTypes["Product_CheckInLevel_operator"] | undefined | null,
	thumbnail?: ResolverInputTypes["Product_thumbnail_operator"] | undefined | null,
	displayPrice?: ResolverInputTypes["Product_displayPrice_operator"] | undefined | null,
	transactionType?: ResolverInputTypes["Product_transactionType_operator"] | undefined | null,
	website?: ResolverInputTypes["Product_website_operator"] | undefined | null,
	tnc?: ResolverInputTypes["Product_tnc_operator"] | undefined | null,
	remarks?: ResolverInputTypes["Product_remarks_operator"] | undefined | null,
	sessions__variantId?: ResolverInputTypes["Product_sessions__variantId_operator"] | undefined | null,
	sessions__productId?: ResolverInputTypes["Product_sessions__productId_operator"] | undefined | null,
	sessions__name?: ResolverInputTypes["Product_sessions__name_operator"] | undefined | null,
	sessions__inventories__quantity?: ResolverInputTypes["Product_sessions__inventories__quantity_operator"] | undefined | null,
	sessions__inventories__unitPrice?: ResolverInputTypes["Product_sessions__inventories__unitPrice_operator"] | undefined | null,
	sessions__inventories__handlingFee?: ResolverInputTypes["Product_sessions__inventories__handlingFee_operator"] | undefined | null,
	sessions__inventories__totalCost?: ResolverInputTypes["Product_sessions__inventories__totalCost_operator"] | undefined | null,
	sessions__inventories__minPurchase?: ResolverInputTypes["Product_sessions__inventories__minPurchase_operator"] | undefined | null,
	sessions__inventories__maxPurchase?: ResolverInputTypes["Product_sessions__inventories__maxPurchase_operator"] | undefined | null,
	sessions__inventories__stocks__stockId?: ResolverInputTypes["Product_sessions__inventories__stocks__stockId_operator"] | undefined | null,
	sessions__inventories__stocks__remaining?: ResolverInputTypes["Product_sessions__inventories__stocks__remaining_operator"] | undefined | null,
	sessions__inventories__stocks__sold?: ResolverInputTypes["Product_sessions__inventories__stocks__sold_operator"] | undefined | null,
	sessions__inventories__stocks__onHold?: ResolverInputTypes["Product_sessions__inventories__stocks__onHold_operator"] | undefined | null,
	sessions__inventories__stocks__checkedIn?: ResolverInputTypes["Product_sessions__inventories__stocks__checkedIn_operator"] | undefined | null,
	sessions__id?: ResolverInputTypes["Product_sessions__id_operator"] | undefined | null,
	updatedAt?: ResolverInputTypes["Product_updatedAt_operator"] | undefined | null,
	createdAt?: ResolverInputTypes["Product_createdAt_operator"] | undefined | null,
	id?: ResolverInputTypes["Product_id_operator"] | undefined | null,
	AND?: Array<ResolverInputTypes["Product_where_and"] | undefined | null> | undefined | null,
	OR?: Array<ResolverInputTypes["Product_where_or"] | undefined | null> | undefined | null
};
	["countProducts"]: AliasType<{
	totalDocs?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["productsDocAccess"]: AliasType<{
	fields?:ResolverInputTypes["ProductsDocAccessFields"],
	create?:ResolverInputTypes["ProductsCreateDocAccess"],
	read?:ResolverInputTypes["ProductsReadDocAccess"],
	update?:ResolverInputTypes["ProductsUpdateDocAccess"],
	delete?:ResolverInputTypes["ProductsDeleteDocAccess"],
		__typename?: boolean | `@${string}`
}>;
	["ProductsDocAccessFields"]: AliasType<{
	status?:ResolverInputTypes["ProductsDocAccessFields_status"],
	creator?:ResolverInputTypes["ProductsDocAccessFields_creator"],
	productType?:ResolverInputTypes["ProductsDocAccessFields_productType"],
	productName?:ResolverInputTypes["ProductsDocAccessFields_productName"],
	referenceId?:ResolverInputTypes["ProductsDocAccessFields_referenceId"],
	linkedEvent?:ResolverInputTypes["ProductsDocAccessFields_linkedEvent"],
	CheckInLevel?:ResolverInputTypes["ProductsDocAccessFields_CheckInLevel"],
	thumbnail?:ResolverInputTypes["ProductsDocAccessFields_thumbnail"],
	displayPrice?:ResolverInputTypes["ProductsDocAccessFields_displayPrice"],
	transactionType?:ResolverInputTypes["ProductsDocAccessFields_transactionType"],
	website?:ResolverInputTypes["ProductsDocAccessFields_website"],
	tnc?:ResolverInputTypes["ProductsDocAccessFields_tnc"],
	remarks?:ResolverInputTypes["ProductsDocAccessFields_remarks"],
	sessions?:ResolverInputTypes["ProductsDocAccessFields_sessions"],
	updatedAt?:ResolverInputTypes["ProductsDocAccessFields_updatedAt"],
	createdAt?:ResolverInputTypes["ProductsDocAccessFields_createdAt"],
		__typename?: boolean | `@${string}`
}>;
	["ProductsDocAccessFields_status"]: AliasType<{
	create?:ResolverInputTypes["ProductsDocAccessFields_status_Create"],
	read?:ResolverInputTypes["ProductsDocAccessFields_status_Read"],
	update?:ResolverInputTypes["ProductsDocAccessFields_status_Update"],
	delete?:ResolverInputTypes["ProductsDocAccessFields_status_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["ProductsDocAccessFields_status_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsDocAccessFields_status_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsDocAccessFields_status_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsDocAccessFields_status_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsDocAccessFields_creator"]: AliasType<{
	create?:ResolverInputTypes["ProductsDocAccessFields_creator_Create"],
	read?:ResolverInputTypes["ProductsDocAccessFields_creator_Read"],
	update?:ResolverInputTypes["ProductsDocAccessFields_creator_Update"],
	delete?:ResolverInputTypes["ProductsDocAccessFields_creator_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["ProductsDocAccessFields_creator_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsDocAccessFields_creator_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsDocAccessFields_creator_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsDocAccessFields_creator_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsDocAccessFields_productType"]: AliasType<{
	create?:ResolverInputTypes["ProductsDocAccessFields_productType_Create"],
	read?:ResolverInputTypes["ProductsDocAccessFields_productType_Read"],
	update?:ResolverInputTypes["ProductsDocAccessFields_productType_Update"],
	delete?:ResolverInputTypes["ProductsDocAccessFields_productType_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["ProductsDocAccessFields_productType_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsDocAccessFields_productType_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsDocAccessFields_productType_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsDocAccessFields_productType_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsDocAccessFields_productName"]: AliasType<{
	create?:ResolverInputTypes["ProductsDocAccessFields_productName_Create"],
	read?:ResolverInputTypes["ProductsDocAccessFields_productName_Read"],
	update?:ResolverInputTypes["ProductsDocAccessFields_productName_Update"],
	delete?:ResolverInputTypes["ProductsDocAccessFields_productName_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["ProductsDocAccessFields_productName_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsDocAccessFields_productName_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsDocAccessFields_productName_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsDocAccessFields_productName_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsDocAccessFields_referenceId"]: AliasType<{
	create?:ResolverInputTypes["ProductsDocAccessFields_referenceId_Create"],
	read?:ResolverInputTypes["ProductsDocAccessFields_referenceId_Read"],
	update?:ResolverInputTypes["ProductsDocAccessFields_referenceId_Update"],
	delete?:ResolverInputTypes["ProductsDocAccessFields_referenceId_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["ProductsDocAccessFields_referenceId_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsDocAccessFields_referenceId_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsDocAccessFields_referenceId_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsDocAccessFields_referenceId_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsDocAccessFields_linkedEvent"]: AliasType<{
	create?:ResolverInputTypes["ProductsDocAccessFields_linkedEvent_Create"],
	read?:ResolverInputTypes["ProductsDocAccessFields_linkedEvent_Read"],
	update?:ResolverInputTypes["ProductsDocAccessFields_linkedEvent_Update"],
	delete?:ResolverInputTypes["ProductsDocAccessFields_linkedEvent_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["ProductsDocAccessFields_linkedEvent_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsDocAccessFields_linkedEvent_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsDocAccessFields_linkedEvent_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsDocAccessFields_linkedEvent_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsDocAccessFields_CheckInLevel"]: AliasType<{
	create?:ResolverInputTypes["ProductsDocAccessFields_CheckInLevel_Create"],
	read?:ResolverInputTypes["ProductsDocAccessFields_CheckInLevel_Read"],
	update?:ResolverInputTypes["ProductsDocAccessFields_CheckInLevel_Update"],
	delete?:ResolverInputTypes["ProductsDocAccessFields_CheckInLevel_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["ProductsDocAccessFields_CheckInLevel_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsDocAccessFields_CheckInLevel_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsDocAccessFields_CheckInLevel_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsDocAccessFields_CheckInLevel_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsDocAccessFields_thumbnail"]: AliasType<{
	create?:ResolverInputTypes["ProductsDocAccessFields_thumbnail_Create"],
	read?:ResolverInputTypes["ProductsDocAccessFields_thumbnail_Read"],
	update?:ResolverInputTypes["ProductsDocAccessFields_thumbnail_Update"],
	delete?:ResolverInputTypes["ProductsDocAccessFields_thumbnail_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["ProductsDocAccessFields_thumbnail_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsDocAccessFields_thumbnail_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsDocAccessFields_thumbnail_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsDocAccessFields_thumbnail_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsDocAccessFields_displayPrice"]: AliasType<{
	create?:ResolverInputTypes["ProductsDocAccessFields_displayPrice_Create"],
	read?:ResolverInputTypes["ProductsDocAccessFields_displayPrice_Read"],
	update?:ResolverInputTypes["ProductsDocAccessFields_displayPrice_Update"],
	delete?:ResolverInputTypes["ProductsDocAccessFields_displayPrice_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["ProductsDocAccessFields_displayPrice_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsDocAccessFields_displayPrice_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsDocAccessFields_displayPrice_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsDocAccessFields_displayPrice_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsDocAccessFields_transactionType"]: AliasType<{
	create?:ResolverInputTypes["ProductsDocAccessFields_transactionType_Create"],
	read?:ResolverInputTypes["ProductsDocAccessFields_transactionType_Read"],
	update?:ResolverInputTypes["ProductsDocAccessFields_transactionType_Update"],
	delete?:ResolverInputTypes["ProductsDocAccessFields_transactionType_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["ProductsDocAccessFields_transactionType_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsDocAccessFields_transactionType_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsDocAccessFields_transactionType_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsDocAccessFields_transactionType_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsDocAccessFields_website"]: AliasType<{
	create?:ResolverInputTypes["ProductsDocAccessFields_website_Create"],
	read?:ResolverInputTypes["ProductsDocAccessFields_website_Read"],
	update?:ResolverInputTypes["ProductsDocAccessFields_website_Update"],
	delete?:ResolverInputTypes["ProductsDocAccessFields_website_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["ProductsDocAccessFields_website_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsDocAccessFields_website_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsDocAccessFields_website_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsDocAccessFields_website_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsDocAccessFields_tnc"]: AliasType<{
	create?:ResolverInputTypes["ProductsDocAccessFields_tnc_Create"],
	read?:ResolverInputTypes["ProductsDocAccessFields_tnc_Read"],
	update?:ResolverInputTypes["ProductsDocAccessFields_tnc_Update"],
	delete?:ResolverInputTypes["ProductsDocAccessFields_tnc_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["ProductsDocAccessFields_tnc_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsDocAccessFields_tnc_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsDocAccessFields_tnc_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsDocAccessFields_tnc_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsDocAccessFields_remarks"]: AliasType<{
	create?:ResolverInputTypes["ProductsDocAccessFields_remarks_Create"],
	read?:ResolverInputTypes["ProductsDocAccessFields_remarks_Read"],
	update?:ResolverInputTypes["ProductsDocAccessFields_remarks_Update"],
	delete?:ResolverInputTypes["ProductsDocAccessFields_remarks_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["ProductsDocAccessFields_remarks_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsDocAccessFields_remarks_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsDocAccessFields_remarks_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsDocAccessFields_remarks_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsDocAccessFields_sessions"]: AliasType<{
	create?:ResolverInputTypes["ProductsDocAccessFields_sessions_Create"],
	read?:ResolverInputTypes["ProductsDocAccessFields_sessions_Read"],
	update?:ResolverInputTypes["ProductsDocAccessFields_sessions_Update"],
	delete?:ResolverInputTypes["ProductsDocAccessFields_sessions_Delete"],
	fields?:ResolverInputTypes["ProductsDocAccessFields_sessions_Fields"],
		__typename?: boolean | `@${string}`
}>;
	["ProductsDocAccessFields_sessions_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsDocAccessFields_sessions_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsDocAccessFields_sessions_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsDocAccessFields_sessions_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsDocAccessFields_sessions_Fields"]: AliasType<{
	productId?:ResolverInputTypes["ProductsDocAccessFields_sessions_productId"],
	name?:ResolverInputTypes["ProductsDocAccessFields_sessions_name"],
	inventories?:ResolverInputTypes["ProductsDocAccessFields_sessions_inventories"],
	id?:ResolverInputTypes["ProductsDocAccessFields_sessions_id"],
		__typename?: boolean | `@${string}`
}>;
	["ProductsDocAccessFields_sessions_productId"]: AliasType<{
	create?:ResolverInputTypes["ProductsDocAccessFields_sessions_productId_Create"],
	read?:ResolverInputTypes["ProductsDocAccessFields_sessions_productId_Read"],
	update?:ResolverInputTypes["ProductsDocAccessFields_sessions_productId_Update"],
	delete?:ResolverInputTypes["ProductsDocAccessFields_sessions_productId_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["ProductsDocAccessFields_sessions_productId_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsDocAccessFields_sessions_productId_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsDocAccessFields_sessions_productId_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsDocAccessFields_sessions_productId_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsDocAccessFields_sessions_name"]: AliasType<{
	create?:ResolverInputTypes["ProductsDocAccessFields_sessions_name_Create"],
	read?:ResolverInputTypes["ProductsDocAccessFields_sessions_name_Read"],
	update?:ResolverInputTypes["ProductsDocAccessFields_sessions_name_Update"],
	delete?:ResolverInputTypes["ProductsDocAccessFields_sessions_name_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["ProductsDocAccessFields_sessions_name_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsDocAccessFields_sessions_name_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsDocAccessFields_sessions_name_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsDocAccessFields_sessions_name_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsDocAccessFields_sessions_inventories"]: AliasType<{
	create?:ResolverInputTypes["ProductsDocAccessFields_sessions_inventories_Create"],
	read?:ResolverInputTypes["ProductsDocAccessFields_sessions_inventories_Read"],
	update?:ResolverInputTypes["ProductsDocAccessFields_sessions_inventories_Update"],
	delete?:ResolverInputTypes["ProductsDocAccessFields_sessions_inventories_Delete"],
	fields?:ResolverInputTypes["ProductsDocAccessFields_sessions_inventories_Fields"],
		__typename?: boolean | `@${string}`
}>;
	["ProductsDocAccessFields_sessions_inventories_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsDocAccessFields_sessions_inventories_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsDocAccessFields_sessions_inventories_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsDocAccessFields_sessions_inventories_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsDocAccessFields_sessions_inventories_Fields"]: AliasType<{
	quantity?:ResolverInputTypes["ProductsDocAccessFields_sessions_inventories_quantity"],
	unitPrice?:ResolverInputTypes["ProductsDocAccessFields_sessions_inventories_unitPrice"],
	handlingFee?:ResolverInputTypes["ProductsDocAccessFields_sessions_inventories_handlingFee"],
	totalCost?:ResolverInputTypes["ProductsDocAccessFields_sessions_inventories_totalCost"],
	minPurchase?:ResolverInputTypes["ProductsDocAccessFields_sessions_inventories_minPurchase"],
	maxPurchase?:ResolverInputTypes["ProductsDocAccessFields_sessions_inventories_maxPurchase"],
	stocks?:ResolverInputTypes["ProductsDocAccessFields_sessions_inventories_stocks"],
		__typename?: boolean | `@${string}`
}>;
	["ProductsDocAccessFields_sessions_inventories_quantity"]: AliasType<{
	create?:ResolverInputTypes["ProductsDocAccessFields_sessions_inventories_quantity_Create"],
	read?:ResolverInputTypes["ProductsDocAccessFields_sessions_inventories_quantity_Read"],
	update?:ResolverInputTypes["ProductsDocAccessFields_sessions_inventories_quantity_Update"],
	delete?:ResolverInputTypes["ProductsDocAccessFields_sessions_inventories_quantity_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["ProductsDocAccessFields_sessions_inventories_quantity_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsDocAccessFields_sessions_inventories_quantity_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsDocAccessFields_sessions_inventories_quantity_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsDocAccessFields_sessions_inventories_quantity_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsDocAccessFields_sessions_inventories_unitPrice"]: AliasType<{
	create?:ResolverInputTypes["ProductsDocAccessFields_sessions_inventories_unitPrice_Create"],
	read?:ResolverInputTypes["ProductsDocAccessFields_sessions_inventories_unitPrice_Read"],
	update?:ResolverInputTypes["ProductsDocAccessFields_sessions_inventories_unitPrice_Update"],
	delete?:ResolverInputTypes["ProductsDocAccessFields_sessions_inventories_unitPrice_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["ProductsDocAccessFields_sessions_inventories_unitPrice_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsDocAccessFields_sessions_inventories_unitPrice_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsDocAccessFields_sessions_inventories_unitPrice_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsDocAccessFields_sessions_inventories_unitPrice_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsDocAccessFields_sessions_inventories_handlingFee"]: AliasType<{
	create?:ResolverInputTypes["ProductsDocAccessFields_sessions_inventories_handlingFee_Create"],
	read?:ResolverInputTypes["ProductsDocAccessFields_sessions_inventories_handlingFee_Read"],
	update?:ResolverInputTypes["ProductsDocAccessFields_sessions_inventories_handlingFee_Update"],
	delete?:ResolverInputTypes["ProductsDocAccessFields_sessions_inventories_handlingFee_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["ProductsDocAccessFields_sessions_inventories_handlingFee_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsDocAccessFields_sessions_inventories_handlingFee_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsDocAccessFields_sessions_inventories_handlingFee_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsDocAccessFields_sessions_inventories_handlingFee_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsDocAccessFields_sessions_inventories_totalCost"]: AliasType<{
	create?:ResolverInputTypes["ProductsDocAccessFields_sessions_inventories_totalCost_Create"],
	read?:ResolverInputTypes["ProductsDocAccessFields_sessions_inventories_totalCost_Read"],
	update?:ResolverInputTypes["ProductsDocAccessFields_sessions_inventories_totalCost_Update"],
	delete?:ResolverInputTypes["ProductsDocAccessFields_sessions_inventories_totalCost_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["ProductsDocAccessFields_sessions_inventories_totalCost_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsDocAccessFields_sessions_inventories_totalCost_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsDocAccessFields_sessions_inventories_totalCost_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsDocAccessFields_sessions_inventories_totalCost_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsDocAccessFields_sessions_inventories_minPurchase"]: AliasType<{
	create?:ResolverInputTypes["ProductsDocAccessFields_sessions_inventories_minPurchase_Create"],
	read?:ResolverInputTypes["ProductsDocAccessFields_sessions_inventories_minPurchase_Read"],
	update?:ResolverInputTypes["ProductsDocAccessFields_sessions_inventories_minPurchase_Update"],
	delete?:ResolverInputTypes["ProductsDocAccessFields_sessions_inventories_minPurchase_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["ProductsDocAccessFields_sessions_inventories_minPurchase_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsDocAccessFields_sessions_inventories_minPurchase_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsDocAccessFields_sessions_inventories_minPurchase_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsDocAccessFields_sessions_inventories_minPurchase_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsDocAccessFields_sessions_inventories_maxPurchase"]: AliasType<{
	create?:ResolverInputTypes["ProductsDocAccessFields_sessions_inventories_maxPurchase_Create"],
	read?:ResolverInputTypes["ProductsDocAccessFields_sessions_inventories_maxPurchase_Read"],
	update?:ResolverInputTypes["ProductsDocAccessFields_sessions_inventories_maxPurchase_Update"],
	delete?:ResolverInputTypes["ProductsDocAccessFields_sessions_inventories_maxPurchase_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["ProductsDocAccessFields_sessions_inventories_maxPurchase_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsDocAccessFields_sessions_inventories_maxPurchase_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsDocAccessFields_sessions_inventories_maxPurchase_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsDocAccessFields_sessions_inventories_maxPurchase_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsDocAccessFields_sessions_inventories_stocks"]: AliasType<{
	create?:ResolverInputTypes["ProductsDocAccessFields_sessions_inventories_stocks_Create"],
	read?:ResolverInputTypes["ProductsDocAccessFields_sessions_inventories_stocks_Read"],
	update?:ResolverInputTypes["ProductsDocAccessFields_sessions_inventories_stocks_Update"],
	delete?:ResolverInputTypes["ProductsDocAccessFields_sessions_inventories_stocks_Delete"],
	fields?:ResolverInputTypes["ProductsDocAccessFields_sessions_inventories_stocks_Fields"],
		__typename?: boolean | `@${string}`
}>;
	["ProductsDocAccessFields_sessions_inventories_stocks_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsDocAccessFields_sessions_inventories_stocks_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsDocAccessFields_sessions_inventories_stocks_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsDocAccessFields_sessions_inventories_stocks_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsDocAccessFields_sessions_inventories_stocks_Fields"]: AliasType<{
	stockId?:ResolverInputTypes["ProductsDocAccessFields_sessions_inventories_stocks_stockId"],
	remaining?:ResolverInputTypes["ProductsDocAccessFields_sessions_inventories_stocks_remaining"],
	sold?:ResolverInputTypes["ProductsDocAccessFields_sessions_inventories_stocks_sold"],
	onHold?:ResolverInputTypes["ProductsDocAccessFields_sessions_inventories_stocks_onHold"],
	checkedIn?:ResolverInputTypes["ProductsDocAccessFields_sessions_inventories_stocks_checkedIn"],
		__typename?: boolean | `@${string}`
}>;
	["ProductsDocAccessFields_sessions_inventories_stocks_stockId"]: AliasType<{
	create?:ResolverInputTypes["ProductsDocAccessFields_sessions_inventories_stocks_stockId_Create"],
	read?:ResolverInputTypes["ProductsDocAccessFields_sessions_inventories_stocks_stockId_Read"],
	update?:ResolverInputTypes["ProductsDocAccessFields_sessions_inventories_stocks_stockId_Update"],
	delete?:ResolverInputTypes["ProductsDocAccessFields_sessions_inventories_stocks_stockId_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["ProductsDocAccessFields_sessions_inventories_stocks_stockId_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsDocAccessFields_sessions_inventories_stocks_stockId_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsDocAccessFields_sessions_inventories_stocks_stockId_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsDocAccessFields_sessions_inventories_stocks_stockId_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsDocAccessFields_sessions_inventories_stocks_remaining"]: AliasType<{
	create?:ResolverInputTypes["ProductsDocAccessFields_sessions_inventories_stocks_remaining_Create"],
	read?:ResolverInputTypes["ProductsDocAccessFields_sessions_inventories_stocks_remaining_Read"],
	update?:ResolverInputTypes["ProductsDocAccessFields_sessions_inventories_stocks_remaining_Update"],
	delete?:ResolverInputTypes["ProductsDocAccessFields_sessions_inventories_stocks_remaining_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["ProductsDocAccessFields_sessions_inventories_stocks_remaining_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsDocAccessFields_sessions_inventories_stocks_remaining_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsDocAccessFields_sessions_inventories_stocks_remaining_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsDocAccessFields_sessions_inventories_stocks_remaining_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsDocAccessFields_sessions_inventories_stocks_sold"]: AliasType<{
	create?:ResolverInputTypes["ProductsDocAccessFields_sessions_inventories_stocks_sold_Create"],
	read?:ResolverInputTypes["ProductsDocAccessFields_sessions_inventories_stocks_sold_Read"],
	update?:ResolverInputTypes["ProductsDocAccessFields_sessions_inventories_stocks_sold_Update"],
	delete?:ResolverInputTypes["ProductsDocAccessFields_sessions_inventories_stocks_sold_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["ProductsDocAccessFields_sessions_inventories_stocks_sold_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsDocAccessFields_sessions_inventories_stocks_sold_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsDocAccessFields_sessions_inventories_stocks_sold_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsDocAccessFields_sessions_inventories_stocks_sold_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsDocAccessFields_sessions_inventories_stocks_onHold"]: AliasType<{
	create?:ResolverInputTypes["ProductsDocAccessFields_sessions_inventories_stocks_onHold_Create"],
	read?:ResolverInputTypes["ProductsDocAccessFields_sessions_inventories_stocks_onHold_Read"],
	update?:ResolverInputTypes["ProductsDocAccessFields_sessions_inventories_stocks_onHold_Update"],
	delete?:ResolverInputTypes["ProductsDocAccessFields_sessions_inventories_stocks_onHold_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["ProductsDocAccessFields_sessions_inventories_stocks_onHold_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsDocAccessFields_sessions_inventories_stocks_onHold_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsDocAccessFields_sessions_inventories_stocks_onHold_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsDocAccessFields_sessions_inventories_stocks_onHold_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsDocAccessFields_sessions_inventories_stocks_checkedIn"]: AliasType<{
	create?:ResolverInputTypes["ProductsDocAccessFields_sessions_inventories_stocks_checkedIn_Create"],
	read?:ResolverInputTypes["ProductsDocAccessFields_sessions_inventories_stocks_checkedIn_Read"],
	update?:ResolverInputTypes["ProductsDocAccessFields_sessions_inventories_stocks_checkedIn_Update"],
	delete?:ResolverInputTypes["ProductsDocAccessFields_sessions_inventories_stocks_checkedIn_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["ProductsDocAccessFields_sessions_inventories_stocks_checkedIn_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsDocAccessFields_sessions_inventories_stocks_checkedIn_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsDocAccessFields_sessions_inventories_stocks_checkedIn_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsDocAccessFields_sessions_inventories_stocks_checkedIn_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsDocAccessFields_sessions_id"]: AliasType<{
	create?:ResolverInputTypes["ProductsDocAccessFields_sessions_id_Create"],
	read?:ResolverInputTypes["ProductsDocAccessFields_sessions_id_Read"],
	update?:ResolverInputTypes["ProductsDocAccessFields_sessions_id_Update"],
	delete?:ResolverInputTypes["ProductsDocAccessFields_sessions_id_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["ProductsDocAccessFields_sessions_id_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsDocAccessFields_sessions_id_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsDocAccessFields_sessions_id_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsDocAccessFields_sessions_id_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsDocAccessFields_updatedAt"]: AliasType<{
	create?:ResolverInputTypes["ProductsDocAccessFields_updatedAt_Create"],
	read?:ResolverInputTypes["ProductsDocAccessFields_updatedAt_Read"],
	update?:ResolverInputTypes["ProductsDocAccessFields_updatedAt_Update"],
	delete?:ResolverInputTypes["ProductsDocAccessFields_updatedAt_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["ProductsDocAccessFields_updatedAt_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsDocAccessFields_updatedAt_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsDocAccessFields_updatedAt_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsDocAccessFields_updatedAt_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsDocAccessFields_createdAt"]: AliasType<{
	create?:ResolverInputTypes["ProductsDocAccessFields_createdAt_Create"],
	read?:ResolverInputTypes["ProductsDocAccessFields_createdAt_Read"],
	update?:ResolverInputTypes["ProductsDocAccessFields_createdAt_Update"],
	delete?:ResolverInputTypes["ProductsDocAccessFields_createdAt_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["ProductsDocAccessFields_createdAt_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsDocAccessFields_createdAt_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsDocAccessFields_createdAt_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsDocAccessFields_createdAt_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsCreateDocAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsReadDocAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsUpdateDocAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsDeleteDocAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["InventoryStatus"]: AliasType<{
	id?:boolean | `@${string}`,
	variantId?:boolean | `@${string}`,
	variantName?:boolean | `@${string}`,
	productType?:boolean | `@${string}`,
	stockUpdateStatus?:boolean | `@${string}`,
	stockUpdateAmount?:boolean | `@${string}`,
user?: [{	locale?: ResolverInputTypes["LocaleInputType"] | undefined | null,	fallbackLocale?: ResolverInputTypes["FallbackLocaleInputType"] | undefined | null},ResolverInputTypes["User"]],
	publicUserPhone?:boolean | `@${string}`,
	expiredAt?:boolean | `@${string}`,
	updatedAt?:boolean | `@${string}`,
	createdAt?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["InventoryStatus_productType"]:InventoryStatus_productType;
	["InventoryStatus_stockUpdateStatus"]:InventoryStatus_stockUpdateStatus;
	["InventoryStatuses"]: AliasType<{
	docs?:ResolverInputTypes["InventoryStatus"],
	hasNextPage?:boolean | `@${string}`,
	hasPrevPage?:boolean | `@${string}`,
	limit?:boolean | `@${string}`,
	nextPage?:boolean | `@${string}`,
	offset?:boolean | `@${string}`,
	page?:boolean | `@${string}`,
	pagingCounter?:boolean | `@${string}`,
	prevPage?:boolean | `@${string}`,
	totalDocs?:boolean | `@${string}`,
	totalPages?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["InventoryStatus_where"]: {
	variantId?: ResolverInputTypes["InventoryStatus_variantId_operator"] | undefined | null,
	variantName?: ResolverInputTypes["InventoryStatus_variantName_operator"] | undefined | null,
	productType?: ResolverInputTypes["InventoryStatus_productType_operator"] | undefined | null,
	stockUpdateStatus?: ResolverInputTypes["InventoryStatus_stockUpdateStatus_operator"] | undefined | null,
	stockUpdateAmount?: ResolverInputTypes["InventoryStatus_stockUpdateAmount_operator"] | undefined | null,
	user?: ResolverInputTypes["InventoryStatus_user_operator"] | undefined | null,
	publicUserPhone?: ResolverInputTypes["InventoryStatus_publicUserPhone_operator"] | undefined | null,
	expiredAt?: ResolverInputTypes["InventoryStatus_expiredAt_operator"] | undefined | null,
	updatedAt?: ResolverInputTypes["InventoryStatus_updatedAt_operator"] | undefined | null,
	createdAt?: ResolverInputTypes["InventoryStatus_createdAt_operator"] | undefined | null,
	id?: ResolverInputTypes["InventoryStatus_id_operator"] | undefined | null,
	AND?: Array<ResolverInputTypes["InventoryStatus_where_and"] | undefined | null> | undefined | null,
	OR?: Array<ResolverInputTypes["InventoryStatus_where_or"] | undefined | null> | undefined | null
};
	["InventoryStatus_variantId_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["InventoryStatus_variantName_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["InventoryStatus_productType_operator"]: {
	equals?: ResolverInputTypes["InventoryStatus_productType_Input"] | undefined | null,
	not_equals?: ResolverInputTypes["InventoryStatus_productType_Input"] | undefined | null,
	like?: ResolverInputTypes["InventoryStatus_productType_Input"] | undefined | null,
	contains?: ResolverInputTypes["InventoryStatus_productType_Input"] | undefined | null,
	exists?: boolean | undefined | null
};
	["InventoryStatus_productType_Input"]:InventoryStatus_productType_Input;
	["InventoryStatus_stockUpdateStatus_operator"]: {
	equals?: ResolverInputTypes["InventoryStatus_stockUpdateStatus_Input"] | undefined | null,
	not_equals?: ResolverInputTypes["InventoryStatus_stockUpdateStatus_Input"] | undefined | null,
	in?: Array<ResolverInputTypes["InventoryStatus_stockUpdateStatus_Input"] | undefined | null> | undefined | null,
	not_in?: Array<ResolverInputTypes["InventoryStatus_stockUpdateStatus_Input"] | undefined | null> | undefined | null,
	all?: Array<ResolverInputTypes["InventoryStatus_stockUpdateStatus_Input"] | undefined | null> | undefined | null
};
	["InventoryStatus_stockUpdateStatus_Input"]:InventoryStatus_stockUpdateStatus_Input;
	["InventoryStatus_stockUpdateAmount_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null
};
	["InventoryStatus_user_operator"]: {
	equals?: ResolverInputTypes["JSON"] | undefined | null,
	not_equals?: ResolverInputTypes["JSON"] | undefined | null,
	in?: Array<ResolverInputTypes["JSON"] | undefined | null> | undefined | null,
	not_in?: Array<ResolverInputTypes["JSON"] | undefined | null> | undefined | null,
	all?: Array<ResolverInputTypes["JSON"] | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["InventoryStatus_publicUserPhone_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["InventoryStatus_expiredAt_operator"]: {
	equals?: ResolverInputTypes["DateTime"] | undefined | null,
	not_equals?: ResolverInputTypes["DateTime"] | undefined | null,
	greater_than_equal?: ResolverInputTypes["DateTime"] | undefined | null,
	greater_than?: ResolverInputTypes["DateTime"] | undefined | null,
	less_than_equal?: ResolverInputTypes["DateTime"] | undefined | null,
	less_than?: ResolverInputTypes["DateTime"] | undefined | null,
	like?: ResolverInputTypes["DateTime"] | undefined | null,
	exists?: boolean | undefined | null
};
	["InventoryStatus_updatedAt_operator"]: {
	equals?: ResolverInputTypes["DateTime"] | undefined | null,
	not_equals?: ResolverInputTypes["DateTime"] | undefined | null,
	greater_than_equal?: ResolverInputTypes["DateTime"] | undefined | null,
	greater_than?: ResolverInputTypes["DateTime"] | undefined | null,
	less_than_equal?: ResolverInputTypes["DateTime"] | undefined | null,
	less_than?: ResolverInputTypes["DateTime"] | undefined | null,
	like?: ResolverInputTypes["DateTime"] | undefined | null,
	exists?: boolean | undefined | null
};
	["InventoryStatus_createdAt_operator"]: {
	equals?: ResolverInputTypes["DateTime"] | undefined | null,
	not_equals?: ResolverInputTypes["DateTime"] | undefined | null,
	greater_than_equal?: ResolverInputTypes["DateTime"] | undefined | null,
	greater_than?: ResolverInputTypes["DateTime"] | undefined | null,
	less_than_equal?: ResolverInputTypes["DateTime"] | undefined | null,
	less_than?: ResolverInputTypes["DateTime"] | undefined | null,
	like?: ResolverInputTypes["DateTime"] | undefined | null,
	exists?: boolean | undefined | null
};
	["InventoryStatus_id_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["InventoryStatus_where_and"]: {
	variantId?: ResolverInputTypes["InventoryStatus_variantId_operator"] | undefined | null,
	variantName?: ResolverInputTypes["InventoryStatus_variantName_operator"] | undefined | null,
	productType?: ResolverInputTypes["InventoryStatus_productType_operator"] | undefined | null,
	stockUpdateStatus?: ResolverInputTypes["InventoryStatus_stockUpdateStatus_operator"] | undefined | null,
	stockUpdateAmount?: ResolverInputTypes["InventoryStatus_stockUpdateAmount_operator"] | undefined | null,
	user?: ResolverInputTypes["InventoryStatus_user_operator"] | undefined | null,
	publicUserPhone?: ResolverInputTypes["InventoryStatus_publicUserPhone_operator"] | undefined | null,
	expiredAt?: ResolverInputTypes["InventoryStatus_expiredAt_operator"] | undefined | null,
	updatedAt?: ResolverInputTypes["InventoryStatus_updatedAt_operator"] | undefined | null,
	createdAt?: ResolverInputTypes["InventoryStatus_createdAt_operator"] | undefined | null,
	id?: ResolverInputTypes["InventoryStatus_id_operator"] | undefined | null,
	AND?: Array<ResolverInputTypes["InventoryStatus_where_and"] | undefined | null> | undefined | null,
	OR?: Array<ResolverInputTypes["InventoryStatus_where_or"] | undefined | null> | undefined | null
};
	["InventoryStatus_where_or"]: {
	variantId?: ResolverInputTypes["InventoryStatus_variantId_operator"] | undefined | null,
	variantName?: ResolverInputTypes["InventoryStatus_variantName_operator"] | undefined | null,
	productType?: ResolverInputTypes["InventoryStatus_productType_operator"] | undefined | null,
	stockUpdateStatus?: ResolverInputTypes["InventoryStatus_stockUpdateStatus_operator"] | undefined | null,
	stockUpdateAmount?: ResolverInputTypes["InventoryStatus_stockUpdateAmount_operator"] | undefined | null,
	user?: ResolverInputTypes["InventoryStatus_user_operator"] | undefined | null,
	publicUserPhone?: ResolverInputTypes["InventoryStatus_publicUserPhone_operator"] | undefined | null,
	expiredAt?: ResolverInputTypes["InventoryStatus_expiredAt_operator"] | undefined | null,
	updatedAt?: ResolverInputTypes["InventoryStatus_updatedAt_operator"] | undefined | null,
	createdAt?: ResolverInputTypes["InventoryStatus_createdAt_operator"] | undefined | null,
	id?: ResolverInputTypes["InventoryStatus_id_operator"] | undefined | null,
	AND?: Array<ResolverInputTypes["InventoryStatus_where_and"] | undefined | null> | undefined | null,
	OR?: Array<ResolverInputTypes["InventoryStatus_where_or"] | undefined | null> | undefined | null
};
	["countInventoryStatuses"]: AliasType<{
	totalDocs?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["inventory_statusDocAccess"]: AliasType<{
	fields?:ResolverInputTypes["InventoryStatusDocAccessFields"],
	create?:ResolverInputTypes["InventoryStatusCreateDocAccess"],
	read?:ResolverInputTypes["InventoryStatusReadDocAccess"],
	update?:ResolverInputTypes["InventoryStatusUpdateDocAccess"],
	delete?:ResolverInputTypes["InventoryStatusDeleteDocAccess"],
		__typename?: boolean | `@${string}`
}>;
	["InventoryStatusDocAccessFields"]: AliasType<{
	variantId?:ResolverInputTypes["InventoryStatusDocAccessFields_variantId"],
	variantName?:ResolverInputTypes["InventoryStatusDocAccessFields_variantName"],
	productType?:ResolverInputTypes["InventoryStatusDocAccessFields_productType"],
	stockUpdateStatus?:ResolverInputTypes["InventoryStatusDocAccessFields_stockUpdateStatus"],
	stockUpdateAmount?:ResolverInputTypes["InventoryStatusDocAccessFields_stockUpdateAmount"],
	user?:ResolverInputTypes["InventoryStatusDocAccessFields_user"],
	publicUserPhone?:ResolverInputTypes["InventoryStatusDocAccessFields_publicUserPhone"],
	expiredAt?:ResolverInputTypes["InventoryStatusDocAccessFields_expiredAt"],
	updatedAt?:ResolverInputTypes["InventoryStatusDocAccessFields_updatedAt"],
	createdAt?:ResolverInputTypes["InventoryStatusDocAccessFields_createdAt"],
		__typename?: boolean | `@${string}`
}>;
	["InventoryStatusDocAccessFields_variantId"]: AliasType<{
	create?:ResolverInputTypes["InventoryStatusDocAccessFields_variantId_Create"],
	read?:ResolverInputTypes["InventoryStatusDocAccessFields_variantId_Read"],
	update?:ResolverInputTypes["InventoryStatusDocAccessFields_variantId_Update"],
	delete?:ResolverInputTypes["InventoryStatusDocAccessFields_variantId_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["InventoryStatusDocAccessFields_variantId_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["InventoryStatusDocAccessFields_variantId_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["InventoryStatusDocAccessFields_variantId_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["InventoryStatusDocAccessFields_variantId_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["InventoryStatusDocAccessFields_variantName"]: AliasType<{
	create?:ResolverInputTypes["InventoryStatusDocAccessFields_variantName_Create"],
	read?:ResolverInputTypes["InventoryStatusDocAccessFields_variantName_Read"],
	update?:ResolverInputTypes["InventoryStatusDocAccessFields_variantName_Update"],
	delete?:ResolverInputTypes["InventoryStatusDocAccessFields_variantName_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["InventoryStatusDocAccessFields_variantName_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["InventoryStatusDocAccessFields_variantName_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["InventoryStatusDocAccessFields_variantName_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["InventoryStatusDocAccessFields_variantName_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["InventoryStatusDocAccessFields_productType"]: AliasType<{
	create?:ResolverInputTypes["InventoryStatusDocAccessFields_productType_Create"],
	read?:ResolverInputTypes["InventoryStatusDocAccessFields_productType_Read"],
	update?:ResolverInputTypes["InventoryStatusDocAccessFields_productType_Update"],
	delete?:ResolverInputTypes["InventoryStatusDocAccessFields_productType_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["InventoryStatusDocAccessFields_productType_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["InventoryStatusDocAccessFields_productType_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["InventoryStatusDocAccessFields_productType_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["InventoryStatusDocAccessFields_productType_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["InventoryStatusDocAccessFields_stockUpdateStatus"]: AliasType<{
	create?:ResolverInputTypes["InventoryStatusDocAccessFields_stockUpdateStatus_Create"],
	read?:ResolverInputTypes["InventoryStatusDocAccessFields_stockUpdateStatus_Read"],
	update?:ResolverInputTypes["InventoryStatusDocAccessFields_stockUpdateStatus_Update"],
	delete?:ResolverInputTypes["InventoryStatusDocAccessFields_stockUpdateStatus_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["InventoryStatusDocAccessFields_stockUpdateStatus_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["InventoryStatusDocAccessFields_stockUpdateStatus_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["InventoryStatusDocAccessFields_stockUpdateStatus_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["InventoryStatusDocAccessFields_stockUpdateStatus_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["InventoryStatusDocAccessFields_stockUpdateAmount"]: AliasType<{
	create?:ResolverInputTypes["InventoryStatusDocAccessFields_stockUpdateAmount_Create"],
	read?:ResolverInputTypes["InventoryStatusDocAccessFields_stockUpdateAmount_Read"],
	update?:ResolverInputTypes["InventoryStatusDocAccessFields_stockUpdateAmount_Update"],
	delete?:ResolverInputTypes["InventoryStatusDocAccessFields_stockUpdateAmount_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["InventoryStatusDocAccessFields_stockUpdateAmount_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["InventoryStatusDocAccessFields_stockUpdateAmount_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["InventoryStatusDocAccessFields_stockUpdateAmount_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["InventoryStatusDocAccessFields_stockUpdateAmount_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["InventoryStatusDocAccessFields_user"]: AliasType<{
	create?:ResolverInputTypes["InventoryStatusDocAccessFields_user_Create"],
	read?:ResolverInputTypes["InventoryStatusDocAccessFields_user_Read"],
	update?:ResolverInputTypes["InventoryStatusDocAccessFields_user_Update"],
	delete?:ResolverInputTypes["InventoryStatusDocAccessFields_user_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["InventoryStatusDocAccessFields_user_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["InventoryStatusDocAccessFields_user_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["InventoryStatusDocAccessFields_user_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["InventoryStatusDocAccessFields_user_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["InventoryStatusDocAccessFields_publicUserPhone"]: AliasType<{
	create?:ResolverInputTypes["InventoryStatusDocAccessFields_publicUserPhone_Create"],
	read?:ResolverInputTypes["InventoryStatusDocAccessFields_publicUserPhone_Read"],
	update?:ResolverInputTypes["InventoryStatusDocAccessFields_publicUserPhone_Update"],
	delete?:ResolverInputTypes["InventoryStatusDocAccessFields_publicUserPhone_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["InventoryStatusDocAccessFields_publicUserPhone_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["InventoryStatusDocAccessFields_publicUserPhone_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["InventoryStatusDocAccessFields_publicUserPhone_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["InventoryStatusDocAccessFields_publicUserPhone_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["InventoryStatusDocAccessFields_expiredAt"]: AliasType<{
	create?:ResolverInputTypes["InventoryStatusDocAccessFields_expiredAt_Create"],
	read?:ResolverInputTypes["InventoryStatusDocAccessFields_expiredAt_Read"],
	update?:ResolverInputTypes["InventoryStatusDocAccessFields_expiredAt_Update"],
	delete?:ResolverInputTypes["InventoryStatusDocAccessFields_expiredAt_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["InventoryStatusDocAccessFields_expiredAt_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["InventoryStatusDocAccessFields_expiredAt_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["InventoryStatusDocAccessFields_expiredAt_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["InventoryStatusDocAccessFields_expiredAt_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["InventoryStatusDocAccessFields_updatedAt"]: AliasType<{
	create?:ResolverInputTypes["InventoryStatusDocAccessFields_updatedAt_Create"],
	read?:ResolverInputTypes["InventoryStatusDocAccessFields_updatedAt_Read"],
	update?:ResolverInputTypes["InventoryStatusDocAccessFields_updatedAt_Update"],
	delete?:ResolverInputTypes["InventoryStatusDocAccessFields_updatedAt_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["InventoryStatusDocAccessFields_updatedAt_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["InventoryStatusDocAccessFields_updatedAt_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["InventoryStatusDocAccessFields_updatedAt_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["InventoryStatusDocAccessFields_updatedAt_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["InventoryStatusDocAccessFields_createdAt"]: AliasType<{
	create?:ResolverInputTypes["InventoryStatusDocAccessFields_createdAt_Create"],
	read?:ResolverInputTypes["InventoryStatusDocAccessFields_createdAt_Read"],
	update?:ResolverInputTypes["InventoryStatusDocAccessFields_createdAt_Update"],
	delete?:ResolverInputTypes["InventoryStatusDocAccessFields_createdAt_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["InventoryStatusDocAccessFields_createdAt_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["InventoryStatusDocAccessFields_createdAt_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["InventoryStatusDocAccessFields_createdAt_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["InventoryStatusDocAccessFields_createdAt_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["InventoryStatusCreateDocAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["InventoryStatusReadDocAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["InventoryStatusUpdateDocAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["InventoryStatusDeleteDocAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["Transaction"]: AliasType<{
	id?:boolean | `@${string}`,
creator?: [{	locale?: ResolverInputTypes["LocaleInputType"] | undefined | null,	fallbackLocale?: ResolverInputTypes["FallbackLocaleInputType"] | undefined | null},ResolverInputTypes["User"]],
	productName?:boolean | `@${string}`,
	referenceId?:boolean | `@${string}`,
linkedEvent?: [{	locale?: ResolverInputTypes["LocaleInputType"] | undefined | null,	fallbackLocale?: ResolverInputTypes["FallbackLocaleInputType"] | undefined | null},ResolverInputTypes["Event"]],
	purchaseDateTime?:boolean | `@${string}`,
	timableOrderId?:boolean | `@${string}`,
	stripePaymentId?:boolean | `@${string}`,
	totalAmount?:boolean | `@${string}`,
	contactPersonName?:boolean | `@${string}`,
	contactPersonPhone?:boolean | `@${string}`,
	remarks?:boolean | `@${string}`,
	redemptionCodes?:ResolverInputTypes["Transaction_RedemptionCodes"],
	updatedAt?:boolean | `@${string}`,
	createdAt?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["Transaction_RedemptionCodes"]: AliasType<{
	code?:boolean | `@${string}`,
	id?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["Transactions"]: AliasType<{
	docs?:ResolverInputTypes["Transaction"],
	hasNextPage?:boolean | `@${string}`,
	hasPrevPage?:boolean | `@${string}`,
	limit?:boolean | `@${string}`,
	nextPage?:boolean | `@${string}`,
	offset?:boolean | `@${string}`,
	page?:boolean | `@${string}`,
	pagingCounter?:boolean | `@${string}`,
	prevPage?:boolean | `@${string}`,
	totalDocs?:boolean | `@${string}`,
	totalPages?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["Transaction_where"]: {
	creator?: ResolverInputTypes["Transaction_creator_operator"] | undefined | null,
	productName?: ResolverInputTypes["Transaction_productName_operator"] | undefined | null,
	referenceId?: ResolverInputTypes["Transaction_referenceId_operator"] | undefined | null,
	linkedEvent?: ResolverInputTypes["Transaction_linkedEvent_operator"] | undefined | null,
	purchaseDateTime?: ResolverInputTypes["Transaction_purchaseDateTime_operator"] | undefined | null,
	timableOrderId?: ResolverInputTypes["Transaction_timableOrderId_operator"] | undefined | null,
	stripePaymentId?: ResolverInputTypes["Transaction_stripePaymentId_operator"] | undefined | null,
	totalAmount?: ResolverInputTypes["Transaction_totalAmount_operator"] | undefined | null,
	contactPersonName?: ResolverInputTypes["Transaction_contactPersonName_operator"] | undefined | null,
	contactPersonPhone?: ResolverInputTypes["Transaction_contactPersonPhone_operator"] | undefined | null,
	remarks?: ResolverInputTypes["Transaction_remarks_operator"] | undefined | null,
	redemptionCodes__code?: ResolverInputTypes["Transaction_redemptionCodes__code_operator"] | undefined | null,
	redemptionCodes__id?: ResolverInputTypes["Transaction_redemptionCodes__id_operator"] | undefined | null,
	updatedAt?: ResolverInputTypes["Transaction_updatedAt_operator"] | undefined | null,
	createdAt?: ResolverInputTypes["Transaction_createdAt_operator"] | undefined | null,
	id?: ResolverInputTypes["Transaction_id_operator"] | undefined | null,
	AND?: Array<ResolverInputTypes["Transaction_where_and"] | undefined | null> | undefined | null,
	OR?: Array<ResolverInputTypes["Transaction_where_or"] | undefined | null> | undefined | null
};
	["Transaction_creator_operator"]: {
	equals?: ResolverInputTypes["JSON"] | undefined | null,
	not_equals?: ResolverInputTypes["JSON"] | undefined | null,
	in?: Array<ResolverInputTypes["JSON"] | undefined | null> | undefined | null,
	not_in?: Array<ResolverInputTypes["JSON"] | undefined | null> | undefined | null,
	all?: Array<ResolverInputTypes["JSON"] | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Transaction_productName_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Transaction_referenceId_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Transaction_linkedEvent_operator"]: {
	equals?: ResolverInputTypes["JSON"] | undefined | null,
	not_equals?: ResolverInputTypes["JSON"] | undefined | null,
	in?: Array<ResolverInputTypes["JSON"] | undefined | null> | undefined | null,
	not_in?: Array<ResolverInputTypes["JSON"] | undefined | null> | undefined | null,
	all?: Array<ResolverInputTypes["JSON"] | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Transaction_purchaseDateTime_operator"]: {
	equals?: ResolverInputTypes["DateTime"] | undefined | null,
	not_equals?: ResolverInputTypes["DateTime"] | undefined | null,
	greater_than_equal?: ResolverInputTypes["DateTime"] | undefined | null,
	greater_than?: ResolverInputTypes["DateTime"] | undefined | null,
	less_than_equal?: ResolverInputTypes["DateTime"] | undefined | null,
	less_than?: ResolverInputTypes["DateTime"] | undefined | null,
	like?: ResolverInputTypes["DateTime"] | undefined | null,
	exists?: boolean | undefined | null
};
	["Transaction_timableOrderId_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Transaction_stripePaymentId_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Transaction_totalAmount_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Transaction_contactPersonName_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Transaction_contactPersonPhone_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Transaction_remarks_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	exists?: boolean | undefined | null
};
	["Transaction_redemptionCodes__code_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Transaction_redemptionCodes__id_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Transaction_updatedAt_operator"]: {
	equals?: ResolverInputTypes["DateTime"] | undefined | null,
	not_equals?: ResolverInputTypes["DateTime"] | undefined | null,
	greater_than_equal?: ResolverInputTypes["DateTime"] | undefined | null,
	greater_than?: ResolverInputTypes["DateTime"] | undefined | null,
	less_than_equal?: ResolverInputTypes["DateTime"] | undefined | null,
	less_than?: ResolverInputTypes["DateTime"] | undefined | null,
	like?: ResolverInputTypes["DateTime"] | undefined | null,
	exists?: boolean | undefined | null
};
	["Transaction_createdAt_operator"]: {
	equals?: ResolverInputTypes["DateTime"] | undefined | null,
	not_equals?: ResolverInputTypes["DateTime"] | undefined | null,
	greater_than_equal?: ResolverInputTypes["DateTime"] | undefined | null,
	greater_than?: ResolverInputTypes["DateTime"] | undefined | null,
	less_than_equal?: ResolverInputTypes["DateTime"] | undefined | null,
	less_than?: ResolverInputTypes["DateTime"] | undefined | null,
	like?: ResolverInputTypes["DateTime"] | undefined | null,
	exists?: boolean | undefined | null
};
	["Transaction_id_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Transaction_where_and"]: {
	creator?: ResolverInputTypes["Transaction_creator_operator"] | undefined | null,
	productName?: ResolverInputTypes["Transaction_productName_operator"] | undefined | null,
	referenceId?: ResolverInputTypes["Transaction_referenceId_operator"] | undefined | null,
	linkedEvent?: ResolverInputTypes["Transaction_linkedEvent_operator"] | undefined | null,
	purchaseDateTime?: ResolverInputTypes["Transaction_purchaseDateTime_operator"] | undefined | null,
	timableOrderId?: ResolverInputTypes["Transaction_timableOrderId_operator"] | undefined | null,
	stripePaymentId?: ResolverInputTypes["Transaction_stripePaymentId_operator"] | undefined | null,
	totalAmount?: ResolverInputTypes["Transaction_totalAmount_operator"] | undefined | null,
	contactPersonName?: ResolverInputTypes["Transaction_contactPersonName_operator"] | undefined | null,
	contactPersonPhone?: ResolverInputTypes["Transaction_contactPersonPhone_operator"] | undefined | null,
	remarks?: ResolverInputTypes["Transaction_remarks_operator"] | undefined | null,
	redemptionCodes__code?: ResolverInputTypes["Transaction_redemptionCodes__code_operator"] | undefined | null,
	redemptionCodes__id?: ResolverInputTypes["Transaction_redemptionCodes__id_operator"] | undefined | null,
	updatedAt?: ResolverInputTypes["Transaction_updatedAt_operator"] | undefined | null,
	createdAt?: ResolverInputTypes["Transaction_createdAt_operator"] | undefined | null,
	id?: ResolverInputTypes["Transaction_id_operator"] | undefined | null,
	AND?: Array<ResolverInputTypes["Transaction_where_and"] | undefined | null> | undefined | null,
	OR?: Array<ResolverInputTypes["Transaction_where_or"] | undefined | null> | undefined | null
};
	["Transaction_where_or"]: {
	creator?: ResolverInputTypes["Transaction_creator_operator"] | undefined | null,
	productName?: ResolverInputTypes["Transaction_productName_operator"] | undefined | null,
	referenceId?: ResolverInputTypes["Transaction_referenceId_operator"] | undefined | null,
	linkedEvent?: ResolverInputTypes["Transaction_linkedEvent_operator"] | undefined | null,
	purchaseDateTime?: ResolverInputTypes["Transaction_purchaseDateTime_operator"] | undefined | null,
	timableOrderId?: ResolverInputTypes["Transaction_timableOrderId_operator"] | undefined | null,
	stripePaymentId?: ResolverInputTypes["Transaction_stripePaymentId_operator"] | undefined | null,
	totalAmount?: ResolverInputTypes["Transaction_totalAmount_operator"] | undefined | null,
	contactPersonName?: ResolverInputTypes["Transaction_contactPersonName_operator"] | undefined | null,
	contactPersonPhone?: ResolverInputTypes["Transaction_contactPersonPhone_operator"] | undefined | null,
	remarks?: ResolverInputTypes["Transaction_remarks_operator"] | undefined | null,
	redemptionCodes__code?: ResolverInputTypes["Transaction_redemptionCodes__code_operator"] | undefined | null,
	redemptionCodes__id?: ResolverInputTypes["Transaction_redemptionCodes__id_operator"] | undefined | null,
	updatedAt?: ResolverInputTypes["Transaction_updatedAt_operator"] | undefined | null,
	createdAt?: ResolverInputTypes["Transaction_createdAt_operator"] | undefined | null,
	id?: ResolverInputTypes["Transaction_id_operator"] | undefined | null,
	AND?: Array<ResolverInputTypes["Transaction_where_and"] | undefined | null> | undefined | null,
	OR?: Array<ResolverInputTypes["Transaction_where_or"] | undefined | null> | undefined | null
};
	["countTransactions"]: AliasType<{
	totalDocs?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["transactionsDocAccess"]: AliasType<{
	fields?:ResolverInputTypes["TransactionsDocAccessFields"],
	create?:ResolverInputTypes["TransactionsCreateDocAccess"],
	read?:ResolverInputTypes["TransactionsReadDocAccess"],
	update?:ResolverInputTypes["TransactionsUpdateDocAccess"],
	delete?:ResolverInputTypes["TransactionsDeleteDocAccess"],
		__typename?: boolean | `@${string}`
}>;
	["TransactionsDocAccessFields"]: AliasType<{
	creator?:ResolverInputTypes["TransactionsDocAccessFields_creator"],
	productName?:ResolverInputTypes["TransactionsDocAccessFields_productName"],
	referenceId?:ResolverInputTypes["TransactionsDocAccessFields_referenceId"],
	linkedEvent?:ResolverInputTypes["TransactionsDocAccessFields_linkedEvent"],
	purchaseDateTime?:ResolverInputTypes["TransactionsDocAccessFields_purchaseDateTime"],
	timableOrderId?:ResolverInputTypes["TransactionsDocAccessFields_timableOrderId"],
	stripePaymentId?:ResolverInputTypes["TransactionsDocAccessFields_stripePaymentId"],
	totalAmount?:ResolverInputTypes["TransactionsDocAccessFields_totalAmount"],
	contactPersonName?:ResolverInputTypes["TransactionsDocAccessFields_contactPersonName"],
	contactPersonPhone?:ResolverInputTypes["TransactionsDocAccessFields_contactPersonPhone"],
	remarks?:ResolverInputTypes["TransactionsDocAccessFields_remarks"],
	redemptionCodes?:ResolverInputTypes["TransactionsDocAccessFields_redemptionCodes"],
	updatedAt?:ResolverInputTypes["TransactionsDocAccessFields_updatedAt"],
	createdAt?:ResolverInputTypes["TransactionsDocAccessFields_createdAt"],
		__typename?: boolean | `@${string}`
}>;
	["TransactionsDocAccessFields_creator"]: AliasType<{
	create?:ResolverInputTypes["TransactionsDocAccessFields_creator_Create"],
	read?:ResolverInputTypes["TransactionsDocAccessFields_creator_Read"],
	update?:ResolverInputTypes["TransactionsDocAccessFields_creator_Update"],
	delete?:ResolverInputTypes["TransactionsDocAccessFields_creator_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["TransactionsDocAccessFields_creator_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TransactionsDocAccessFields_creator_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TransactionsDocAccessFields_creator_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TransactionsDocAccessFields_creator_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TransactionsDocAccessFields_productName"]: AliasType<{
	create?:ResolverInputTypes["TransactionsDocAccessFields_productName_Create"],
	read?:ResolverInputTypes["TransactionsDocAccessFields_productName_Read"],
	update?:ResolverInputTypes["TransactionsDocAccessFields_productName_Update"],
	delete?:ResolverInputTypes["TransactionsDocAccessFields_productName_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["TransactionsDocAccessFields_productName_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TransactionsDocAccessFields_productName_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TransactionsDocAccessFields_productName_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TransactionsDocAccessFields_productName_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TransactionsDocAccessFields_referenceId"]: AliasType<{
	create?:ResolverInputTypes["TransactionsDocAccessFields_referenceId_Create"],
	read?:ResolverInputTypes["TransactionsDocAccessFields_referenceId_Read"],
	update?:ResolverInputTypes["TransactionsDocAccessFields_referenceId_Update"],
	delete?:ResolverInputTypes["TransactionsDocAccessFields_referenceId_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["TransactionsDocAccessFields_referenceId_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TransactionsDocAccessFields_referenceId_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TransactionsDocAccessFields_referenceId_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TransactionsDocAccessFields_referenceId_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TransactionsDocAccessFields_linkedEvent"]: AliasType<{
	create?:ResolverInputTypes["TransactionsDocAccessFields_linkedEvent_Create"],
	read?:ResolverInputTypes["TransactionsDocAccessFields_linkedEvent_Read"],
	update?:ResolverInputTypes["TransactionsDocAccessFields_linkedEvent_Update"],
	delete?:ResolverInputTypes["TransactionsDocAccessFields_linkedEvent_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["TransactionsDocAccessFields_linkedEvent_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TransactionsDocAccessFields_linkedEvent_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TransactionsDocAccessFields_linkedEvent_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TransactionsDocAccessFields_linkedEvent_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TransactionsDocAccessFields_purchaseDateTime"]: AliasType<{
	create?:ResolverInputTypes["TransactionsDocAccessFields_purchaseDateTime_Create"],
	read?:ResolverInputTypes["TransactionsDocAccessFields_purchaseDateTime_Read"],
	update?:ResolverInputTypes["TransactionsDocAccessFields_purchaseDateTime_Update"],
	delete?:ResolverInputTypes["TransactionsDocAccessFields_purchaseDateTime_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["TransactionsDocAccessFields_purchaseDateTime_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TransactionsDocAccessFields_purchaseDateTime_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TransactionsDocAccessFields_purchaseDateTime_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TransactionsDocAccessFields_purchaseDateTime_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TransactionsDocAccessFields_timableOrderId"]: AliasType<{
	create?:ResolverInputTypes["TransactionsDocAccessFields_timableOrderId_Create"],
	read?:ResolverInputTypes["TransactionsDocAccessFields_timableOrderId_Read"],
	update?:ResolverInputTypes["TransactionsDocAccessFields_timableOrderId_Update"],
	delete?:ResolverInputTypes["TransactionsDocAccessFields_timableOrderId_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["TransactionsDocAccessFields_timableOrderId_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TransactionsDocAccessFields_timableOrderId_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TransactionsDocAccessFields_timableOrderId_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TransactionsDocAccessFields_timableOrderId_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TransactionsDocAccessFields_stripePaymentId"]: AliasType<{
	create?:ResolverInputTypes["TransactionsDocAccessFields_stripePaymentId_Create"],
	read?:ResolverInputTypes["TransactionsDocAccessFields_stripePaymentId_Read"],
	update?:ResolverInputTypes["TransactionsDocAccessFields_stripePaymentId_Update"],
	delete?:ResolverInputTypes["TransactionsDocAccessFields_stripePaymentId_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["TransactionsDocAccessFields_stripePaymentId_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TransactionsDocAccessFields_stripePaymentId_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TransactionsDocAccessFields_stripePaymentId_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TransactionsDocAccessFields_stripePaymentId_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TransactionsDocAccessFields_totalAmount"]: AliasType<{
	create?:ResolverInputTypes["TransactionsDocAccessFields_totalAmount_Create"],
	read?:ResolverInputTypes["TransactionsDocAccessFields_totalAmount_Read"],
	update?:ResolverInputTypes["TransactionsDocAccessFields_totalAmount_Update"],
	delete?:ResolverInputTypes["TransactionsDocAccessFields_totalAmount_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["TransactionsDocAccessFields_totalAmount_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TransactionsDocAccessFields_totalAmount_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TransactionsDocAccessFields_totalAmount_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TransactionsDocAccessFields_totalAmount_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TransactionsDocAccessFields_contactPersonName"]: AliasType<{
	create?:ResolverInputTypes["TransactionsDocAccessFields_contactPersonName_Create"],
	read?:ResolverInputTypes["TransactionsDocAccessFields_contactPersonName_Read"],
	update?:ResolverInputTypes["TransactionsDocAccessFields_contactPersonName_Update"],
	delete?:ResolverInputTypes["TransactionsDocAccessFields_contactPersonName_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["TransactionsDocAccessFields_contactPersonName_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TransactionsDocAccessFields_contactPersonName_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TransactionsDocAccessFields_contactPersonName_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TransactionsDocAccessFields_contactPersonName_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TransactionsDocAccessFields_contactPersonPhone"]: AliasType<{
	create?:ResolverInputTypes["TransactionsDocAccessFields_contactPersonPhone_Create"],
	read?:ResolverInputTypes["TransactionsDocAccessFields_contactPersonPhone_Read"],
	update?:ResolverInputTypes["TransactionsDocAccessFields_contactPersonPhone_Update"],
	delete?:ResolverInputTypes["TransactionsDocAccessFields_contactPersonPhone_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["TransactionsDocAccessFields_contactPersonPhone_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TransactionsDocAccessFields_contactPersonPhone_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TransactionsDocAccessFields_contactPersonPhone_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TransactionsDocAccessFields_contactPersonPhone_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TransactionsDocAccessFields_remarks"]: AliasType<{
	create?:ResolverInputTypes["TransactionsDocAccessFields_remarks_Create"],
	read?:ResolverInputTypes["TransactionsDocAccessFields_remarks_Read"],
	update?:ResolverInputTypes["TransactionsDocAccessFields_remarks_Update"],
	delete?:ResolverInputTypes["TransactionsDocAccessFields_remarks_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["TransactionsDocAccessFields_remarks_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TransactionsDocAccessFields_remarks_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TransactionsDocAccessFields_remarks_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TransactionsDocAccessFields_remarks_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TransactionsDocAccessFields_redemptionCodes"]: AliasType<{
	create?:ResolverInputTypes["TransactionsDocAccessFields_redemptionCodes_Create"],
	read?:ResolverInputTypes["TransactionsDocAccessFields_redemptionCodes_Read"],
	update?:ResolverInputTypes["TransactionsDocAccessFields_redemptionCodes_Update"],
	delete?:ResolverInputTypes["TransactionsDocAccessFields_redemptionCodes_Delete"],
	fields?:ResolverInputTypes["TransactionsDocAccessFields_redemptionCodes_Fields"],
		__typename?: boolean | `@${string}`
}>;
	["TransactionsDocAccessFields_redemptionCodes_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TransactionsDocAccessFields_redemptionCodes_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TransactionsDocAccessFields_redemptionCodes_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TransactionsDocAccessFields_redemptionCodes_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TransactionsDocAccessFields_redemptionCodes_Fields"]: AliasType<{
	code?:ResolverInputTypes["TransactionsDocAccessFields_redemptionCodes_code"],
	id?:ResolverInputTypes["TransactionsDocAccessFields_redemptionCodes_id"],
		__typename?: boolean | `@${string}`
}>;
	["TransactionsDocAccessFields_redemptionCodes_code"]: AliasType<{
	create?:ResolverInputTypes["TransactionsDocAccessFields_redemptionCodes_code_Create"],
	read?:ResolverInputTypes["TransactionsDocAccessFields_redemptionCodes_code_Read"],
	update?:ResolverInputTypes["TransactionsDocAccessFields_redemptionCodes_code_Update"],
	delete?:ResolverInputTypes["TransactionsDocAccessFields_redemptionCodes_code_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["TransactionsDocAccessFields_redemptionCodes_code_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TransactionsDocAccessFields_redemptionCodes_code_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TransactionsDocAccessFields_redemptionCodes_code_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TransactionsDocAccessFields_redemptionCodes_code_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TransactionsDocAccessFields_redemptionCodes_id"]: AliasType<{
	create?:ResolverInputTypes["TransactionsDocAccessFields_redemptionCodes_id_Create"],
	read?:ResolverInputTypes["TransactionsDocAccessFields_redemptionCodes_id_Read"],
	update?:ResolverInputTypes["TransactionsDocAccessFields_redemptionCodes_id_Update"],
	delete?:ResolverInputTypes["TransactionsDocAccessFields_redemptionCodes_id_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["TransactionsDocAccessFields_redemptionCodes_id_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TransactionsDocAccessFields_redemptionCodes_id_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TransactionsDocAccessFields_redemptionCodes_id_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TransactionsDocAccessFields_redemptionCodes_id_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TransactionsDocAccessFields_updatedAt"]: AliasType<{
	create?:ResolverInputTypes["TransactionsDocAccessFields_updatedAt_Create"],
	read?:ResolverInputTypes["TransactionsDocAccessFields_updatedAt_Read"],
	update?:ResolverInputTypes["TransactionsDocAccessFields_updatedAt_Update"],
	delete?:ResolverInputTypes["TransactionsDocAccessFields_updatedAt_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["TransactionsDocAccessFields_updatedAt_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TransactionsDocAccessFields_updatedAt_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TransactionsDocAccessFields_updatedAt_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TransactionsDocAccessFields_updatedAt_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TransactionsDocAccessFields_createdAt"]: AliasType<{
	create?:ResolverInputTypes["TransactionsDocAccessFields_createdAt_Create"],
	read?:ResolverInputTypes["TransactionsDocAccessFields_createdAt_Read"],
	update?:ResolverInputTypes["TransactionsDocAccessFields_createdAt_Update"],
	delete?:ResolverInputTypes["TransactionsDocAccessFields_createdAt_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["TransactionsDocAccessFields_createdAt_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TransactionsDocAccessFields_createdAt_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TransactionsDocAccessFields_createdAt_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TransactionsDocAccessFields_createdAt_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TransactionsCreateDocAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TransactionsReadDocAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TransactionsUpdateDocAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TransactionsDeleteDocAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCode"]: AliasType<{
	id?:boolean | `@${string}`,
creator?: [{	locale?: ResolverInputTypes["LocaleInputType"] | undefined | null,	fallbackLocale?: ResolverInputTypes["FallbackLocaleInputType"] | undefined | null},ResolverInputTypes["User"]],
	status?:boolean | `@${string}`,
	qrCodeId?:boolean | `@${string}`,
	lastCheckedInDateTime?:boolean | `@${string}`,
	voidedDateTime?:boolean | `@${string}`,
	refundedDateTime?:boolean | `@${string}`,
	eventStartTime?:boolean | `@${string}`,
	eventEndTime?:boolean | `@${string}`,
	eventPriceZone?:boolean | `@${string}`,
	ownerName?:boolean | `@${string}`,
	phone?:boolean | `@${string}`,
pdf?: [{	locale?: ResolverInputTypes["LocaleInputType"] | undefined | null,	fallbackLocale?: ResolverInputTypes["FallbackLocaleInputType"] | undefined | null},ResolverInputTypes["RedemptionPdf"]],
	variants?:ResolverInputTypes["RedemptionCode_Variants"],
	productId?:boolean | `@${string}`,
	referenceId?:boolean | `@${string}`,
	productType?:boolean | `@${string}`,
	productName?:boolean | `@${string}`,
linkedEvent?: [{	locale?: ResolverInputTypes["LocaleInputType"] | undefined | null,	fallbackLocale?: ResolverInputTypes["FallbackLocaleInputType"] | undefined | null},ResolverInputTypes["Event"]],
	timableOrderId?:boolean | `@${string}`,
	purchaseDateTime?:boolean | `@${string}`,
	changeHistory?:ResolverInputTypes["RedemptionCode_ChangeHistory"],
	updatedAt?:boolean | `@${string}`,
	createdAt?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCode_status"]:RedemptionCode_status;
	["RedemptionPdf"]: AliasType<{
	id?:boolean | `@${string}`,
	updatedAt?:boolean | `@${string}`,
	createdAt?:boolean | `@${string}`,
	url?:boolean | `@${string}`,
	filename?:boolean | `@${string}`,
	mimeType?:boolean | `@${string}`,
	filesize?:boolean | `@${string}`,
	width?:boolean | `@${string}`,
	height?:boolean | `@${string}`,
	focalX?:boolean | `@${string}`,
	focalY?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCode_Variants"]: AliasType<{
	variantId?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	quantity?:boolean | `@${string}`,
	totalCost?:boolean | `@${string}`,
	id?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCode_productType"]:RedemptionCode_productType;
	["RedemptionCode_ChangeHistory"]: AliasType<{
	timestamp?:boolean | `@${string}`,
	field?:boolean | `@${string}`,
	oldValue?:boolean | `@${string}`,
	newValue?:boolean | `@${string}`,
	id?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodes"]: AliasType<{
	docs?:ResolverInputTypes["RedemptionCode"],
	hasNextPage?:boolean | `@${string}`,
	hasPrevPage?:boolean | `@${string}`,
	limit?:boolean | `@${string}`,
	nextPage?:boolean | `@${string}`,
	offset?:boolean | `@${string}`,
	page?:boolean | `@${string}`,
	pagingCounter?:boolean | `@${string}`,
	prevPage?:boolean | `@${string}`,
	totalDocs?:boolean | `@${string}`,
	totalPages?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCode_where"]: {
	creator?: ResolverInputTypes["RedemptionCode_creator_operator"] | undefined | null,
	status?: ResolverInputTypes["RedemptionCode_status_operator"] | undefined | null,
	qrCodeId?: ResolverInputTypes["RedemptionCode_qrCodeId_operator"] | undefined | null,
	lastCheckedInDateTime?: ResolverInputTypes["RedemptionCode_lastCheckedInDateTime_operator"] | undefined | null,
	voidedDateTime?: ResolverInputTypes["RedemptionCode_voidedDateTime_operator"] | undefined | null,
	refundedDateTime?: ResolverInputTypes["RedemptionCode_refundedDateTime_operator"] | undefined | null,
	eventStartTime?: ResolverInputTypes["RedemptionCode_eventStartTime_operator"] | undefined | null,
	eventEndTime?: ResolverInputTypes["RedemptionCode_eventEndTime_operator"] | undefined | null,
	eventPriceZone?: ResolverInputTypes["RedemptionCode_eventPriceZone_operator"] | undefined | null,
	ownerName?: ResolverInputTypes["RedemptionCode_ownerName_operator"] | undefined | null,
	phone?: ResolverInputTypes["RedemptionCode_phone_operator"] | undefined | null,
	pdf?: ResolverInputTypes["RedemptionCode_pdf_operator"] | undefined | null,
	variants__variantId?: ResolverInputTypes["RedemptionCode_variants__variantId_operator"] | undefined | null,
	variants__name?: ResolverInputTypes["RedemptionCode_variants__name_operator"] | undefined | null,
	variants__quantity?: ResolverInputTypes["RedemptionCode_variants__quantity_operator"] | undefined | null,
	variants__totalCost?: ResolverInputTypes["RedemptionCode_variants__totalCost_operator"] | undefined | null,
	variants__id?: ResolverInputTypes["RedemptionCode_variants__id_operator"] | undefined | null,
	productId?: ResolverInputTypes["RedemptionCode_productId_operator"] | undefined | null,
	referenceId?: ResolverInputTypes["RedemptionCode_referenceId_operator"] | undefined | null,
	productType?: ResolverInputTypes["RedemptionCode_productType_operator"] | undefined | null,
	productName?: ResolverInputTypes["RedemptionCode_productName_operator"] | undefined | null,
	linkedEvent?: ResolverInputTypes["RedemptionCode_linkedEvent_operator"] | undefined | null,
	timableOrderId?: ResolverInputTypes["RedemptionCode_timableOrderId_operator"] | undefined | null,
	purchaseDateTime?: ResolverInputTypes["RedemptionCode_purchaseDateTime_operator"] | undefined | null,
	changeHistory__timestamp?: ResolverInputTypes["RedemptionCode_changeHistory__timestamp_operator"] | undefined | null,
	changeHistory__field?: ResolverInputTypes["RedemptionCode_changeHistory__field_operator"] | undefined | null,
	changeHistory__oldValue?: ResolverInputTypes["RedemptionCode_changeHistory__oldValue_operator"] | undefined | null,
	changeHistory__newValue?: ResolverInputTypes["RedemptionCode_changeHistory__newValue_operator"] | undefined | null,
	changeHistory__id?: ResolverInputTypes["RedemptionCode_changeHistory__id_operator"] | undefined | null,
	updatedAt?: ResolverInputTypes["RedemptionCode_updatedAt_operator"] | undefined | null,
	createdAt?: ResolverInputTypes["RedemptionCode_createdAt_operator"] | undefined | null,
	id?: ResolverInputTypes["RedemptionCode_id_operator"] | undefined | null,
	AND?: Array<ResolverInputTypes["RedemptionCode_where_and"] | undefined | null> | undefined | null,
	OR?: Array<ResolverInputTypes["RedemptionCode_where_or"] | undefined | null> | undefined | null
};
	["RedemptionCode_creator_operator"]: {
	equals?: ResolverInputTypes["JSON"] | undefined | null,
	not_equals?: ResolverInputTypes["JSON"] | undefined | null,
	in?: Array<ResolverInputTypes["JSON"] | undefined | null> | undefined | null,
	not_in?: Array<ResolverInputTypes["JSON"] | undefined | null> | undefined | null,
	all?: Array<ResolverInputTypes["JSON"] | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["RedemptionCode_status_operator"]: {
	equals?: ResolverInputTypes["RedemptionCode_status_Input"] | undefined | null,
	not_equals?: ResolverInputTypes["RedemptionCode_status_Input"] | undefined | null,
	in?: Array<ResolverInputTypes["RedemptionCode_status_Input"] | undefined | null> | undefined | null,
	not_in?: Array<ResolverInputTypes["RedemptionCode_status_Input"] | undefined | null> | undefined | null,
	all?: Array<ResolverInputTypes["RedemptionCode_status_Input"] | undefined | null> | undefined | null
};
	["RedemptionCode_status_Input"]:RedemptionCode_status_Input;
	["RedemptionCode_qrCodeId_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["RedemptionCode_lastCheckedInDateTime_operator"]: {
	equals?: ResolverInputTypes["DateTime"] | undefined | null,
	not_equals?: ResolverInputTypes["DateTime"] | undefined | null,
	greater_than_equal?: ResolverInputTypes["DateTime"] | undefined | null,
	greater_than?: ResolverInputTypes["DateTime"] | undefined | null,
	less_than_equal?: ResolverInputTypes["DateTime"] | undefined | null,
	less_than?: ResolverInputTypes["DateTime"] | undefined | null,
	like?: ResolverInputTypes["DateTime"] | undefined | null,
	exists?: boolean | undefined | null
};
	["RedemptionCode_voidedDateTime_operator"]: {
	equals?: ResolverInputTypes["DateTime"] | undefined | null,
	not_equals?: ResolverInputTypes["DateTime"] | undefined | null,
	greater_than_equal?: ResolverInputTypes["DateTime"] | undefined | null,
	greater_than?: ResolverInputTypes["DateTime"] | undefined | null,
	less_than_equal?: ResolverInputTypes["DateTime"] | undefined | null,
	less_than?: ResolverInputTypes["DateTime"] | undefined | null,
	like?: ResolverInputTypes["DateTime"] | undefined | null,
	exists?: boolean | undefined | null
};
	["RedemptionCode_refundedDateTime_operator"]: {
	equals?: ResolverInputTypes["DateTime"] | undefined | null,
	not_equals?: ResolverInputTypes["DateTime"] | undefined | null,
	greater_than_equal?: ResolverInputTypes["DateTime"] | undefined | null,
	greater_than?: ResolverInputTypes["DateTime"] | undefined | null,
	less_than_equal?: ResolverInputTypes["DateTime"] | undefined | null,
	less_than?: ResolverInputTypes["DateTime"] | undefined | null,
	like?: ResolverInputTypes["DateTime"] | undefined | null,
	exists?: boolean | undefined | null
};
	["RedemptionCode_eventStartTime_operator"]: {
	equals?: ResolverInputTypes["DateTime"] | undefined | null,
	not_equals?: ResolverInputTypes["DateTime"] | undefined | null,
	greater_than_equal?: ResolverInputTypes["DateTime"] | undefined | null,
	greater_than?: ResolverInputTypes["DateTime"] | undefined | null,
	less_than_equal?: ResolverInputTypes["DateTime"] | undefined | null,
	less_than?: ResolverInputTypes["DateTime"] | undefined | null,
	like?: ResolverInputTypes["DateTime"] | undefined | null,
	exists?: boolean | undefined | null
};
	["RedemptionCode_eventEndTime_operator"]: {
	equals?: ResolverInputTypes["DateTime"] | undefined | null,
	not_equals?: ResolverInputTypes["DateTime"] | undefined | null,
	greater_than_equal?: ResolverInputTypes["DateTime"] | undefined | null,
	greater_than?: ResolverInputTypes["DateTime"] | undefined | null,
	less_than_equal?: ResolverInputTypes["DateTime"] | undefined | null,
	less_than?: ResolverInputTypes["DateTime"] | undefined | null,
	like?: ResolverInputTypes["DateTime"] | undefined | null,
	exists?: boolean | undefined | null
};
	["RedemptionCode_eventPriceZone_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["RedemptionCode_ownerName_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null
};
	["RedemptionCode_phone_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null
};
	["RedemptionCode_pdf_operator"]: {
	equals?: ResolverInputTypes["JSON"] | undefined | null,
	not_equals?: ResolverInputTypes["JSON"] | undefined | null,
	in?: Array<ResolverInputTypes["JSON"] | undefined | null> | undefined | null,
	not_in?: Array<ResolverInputTypes["JSON"] | undefined | null> | undefined | null,
	all?: Array<ResolverInputTypes["JSON"] | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["RedemptionCode_variants__variantId_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["RedemptionCode_variants__name_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["RedemptionCode_variants__quantity_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["RedemptionCode_variants__totalCost_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["RedemptionCode_variants__id_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["RedemptionCode_productId_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["RedemptionCode_referenceId_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["RedemptionCode_productType_operator"]: {
	equals?: ResolverInputTypes["RedemptionCode_productType_Input"] | undefined | null,
	not_equals?: ResolverInputTypes["RedemptionCode_productType_Input"] | undefined | null,
	like?: ResolverInputTypes["RedemptionCode_productType_Input"] | undefined | null,
	contains?: ResolverInputTypes["RedemptionCode_productType_Input"] | undefined | null,
	exists?: boolean | undefined | null
};
	["RedemptionCode_productType_Input"]:RedemptionCode_productType_Input;
	["RedemptionCode_productName_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["RedemptionCode_linkedEvent_operator"]: {
	equals?: ResolverInputTypes["JSON"] | undefined | null,
	not_equals?: ResolverInputTypes["JSON"] | undefined | null,
	in?: Array<ResolverInputTypes["JSON"] | undefined | null> | undefined | null,
	not_in?: Array<ResolverInputTypes["JSON"] | undefined | null> | undefined | null,
	all?: Array<ResolverInputTypes["JSON"] | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["RedemptionCode_timableOrderId_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["RedemptionCode_purchaseDateTime_operator"]: {
	equals?: ResolverInputTypes["DateTime"] | undefined | null,
	not_equals?: ResolverInputTypes["DateTime"] | undefined | null,
	greater_than_equal?: ResolverInputTypes["DateTime"] | undefined | null,
	greater_than?: ResolverInputTypes["DateTime"] | undefined | null,
	less_than_equal?: ResolverInputTypes["DateTime"] | undefined | null,
	less_than?: ResolverInputTypes["DateTime"] | undefined | null,
	like?: ResolverInputTypes["DateTime"] | undefined | null,
	exists?: boolean | undefined | null
};
	["RedemptionCode_changeHistory__timestamp_operator"]: {
	equals?: ResolverInputTypes["DateTime"] | undefined | null,
	not_equals?: ResolverInputTypes["DateTime"] | undefined | null,
	greater_than_equal?: ResolverInputTypes["DateTime"] | undefined | null,
	greater_than?: ResolverInputTypes["DateTime"] | undefined | null,
	less_than_equal?: ResolverInputTypes["DateTime"] | undefined | null,
	less_than?: ResolverInputTypes["DateTime"] | undefined | null,
	like?: ResolverInputTypes["DateTime"] | undefined | null
};
	["RedemptionCode_changeHistory__field_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null
};
	["RedemptionCode_changeHistory__oldValue_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null
};
	["RedemptionCode_changeHistory__newValue_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null
};
	["RedemptionCode_changeHistory__id_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["RedemptionCode_updatedAt_operator"]: {
	equals?: ResolverInputTypes["DateTime"] | undefined | null,
	not_equals?: ResolverInputTypes["DateTime"] | undefined | null,
	greater_than_equal?: ResolverInputTypes["DateTime"] | undefined | null,
	greater_than?: ResolverInputTypes["DateTime"] | undefined | null,
	less_than_equal?: ResolverInputTypes["DateTime"] | undefined | null,
	less_than?: ResolverInputTypes["DateTime"] | undefined | null,
	like?: ResolverInputTypes["DateTime"] | undefined | null,
	exists?: boolean | undefined | null
};
	["RedemptionCode_createdAt_operator"]: {
	equals?: ResolverInputTypes["DateTime"] | undefined | null,
	not_equals?: ResolverInputTypes["DateTime"] | undefined | null,
	greater_than_equal?: ResolverInputTypes["DateTime"] | undefined | null,
	greater_than?: ResolverInputTypes["DateTime"] | undefined | null,
	less_than_equal?: ResolverInputTypes["DateTime"] | undefined | null,
	less_than?: ResolverInputTypes["DateTime"] | undefined | null,
	like?: ResolverInputTypes["DateTime"] | undefined | null,
	exists?: boolean | undefined | null
};
	["RedemptionCode_id_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["RedemptionCode_where_and"]: {
	creator?: ResolverInputTypes["RedemptionCode_creator_operator"] | undefined | null,
	status?: ResolverInputTypes["RedemptionCode_status_operator"] | undefined | null,
	qrCodeId?: ResolverInputTypes["RedemptionCode_qrCodeId_operator"] | undefined | null,
	lastCheckedInDateTime?: ResolverInputTypes["RedemptionCode_lastCheckedInDateTime_operator"] | undefined | null,
	voidedDateTime?: ResolverInputTypes["RedemptionCode_voidedDateTime_operator"] | undefined | null,
	refundedDateTime?: ResolverInputTypes["RedemptionCode_refundedDateTime_operator"] | undefined | null,
	eventStartTime?: ResolverInputTypes["RedemptionCode_eventStartTime_operator"] | undefined | null,
	eventEndTime?: ResolverInputTypes["RedemptionCode_eventEndTime_operator"] | undefined | null,
	eventPriceZone?: ResolverInputTypes["RedemptionCode_eventPriceZone_operator"] | undefined | null,
	ownerName?: ResolverInputTypes["RedemptionCode_ownerName_operator"] | undefined | null,
	phone?: ResolverInputTypes["RedemptionCode_phone_operator"] | undefined | null,
	pdf?: ResolverInputTypes["RedemptionCode_pdf_operator"] | undefined | null,
	variants__variantId?: ResolverInputTypes["RedemptionCode_variants__variantId_operator"] | undefined | null,
	variants__name?: ResolverInputTypes["RedemptionCode_variants__name_operator"] | undefined | null,
	variants__quantity?: ResolverInputTypes["RedemptionCode_variants__quantity_operator"] | undefined | null,
	variants__totalCost?: ResolverInputTypes["RedemptionCode_variants__totalCost_operator"] | undefined | null,
	variants__id?: ResolverInputTypes["RedemptionCode_variants__id_operator"] | undefined | null,
	productId?: ResolverInputTypes["RedemptionCode_productId_operator"] | undefined | null,
	referenceId?: ResolverInputTypes["RedemptionCode_referenceId_operator"] | undefined | null,
	productType?: ResolverInputTypes["RedemptionCode_productType_operator"] | undefined | null,
	productName?: ResolverInputTypes["RedemptionCode_productName_operator"] | undefined | null,
	linkedEvent?: ResolverInputTypes["RedemptionCode_linkedEvent_operator"] | undefined | null,
	timableOrderId?: ResolverInputTypes["RedemptionCode_timableOrderId_operator"] | undefined | null,
	purchaseDateTime?: ResolverInputTypes["RedemptionCode_purchaseDateTime_operator"] | undefined | null,
	changeHistory__timestamp?: ResolverInputTypes["RedemptionCode_changeHistory__timestamp_operator"] | undefined | null,
	changeHistory__field?: ResolverInputTypes["RedemptionCode_changeHistory__field_operator"] | undefined | null,
	changeHistory__oldValue?: ResolverInputTypes["RedemptionCode_changeHistory__oldValue_operator"] | undefined | null,
	changeHistory__newValue?: ResolverInputTypes["RedemptionCode_changeHistory__newValue_operator"] | undefined | null,
	changeHistory__id?: ResolverInputTypes["RedemptionCode_changeHistory__id_operator"] | undefined | null,
	updatedAt?: ResolverInputTypes["RedemptionCode_updatedAt_operator"] | undefined | null,
	createdAt?: ResolverInputTypes["RedemptionCode_createdAt_operator"] | undefined | null,
	id?: ResolverInputTypes["RedemptionCode_id_operator"] | undefined | null,
	AND?: Array<ResolverInputTypes["RedemptionCode_where_and"] | undefined | null> | undefined | null,
	OR?: Array<ResolverInputTypes["RedemptionCode_where_or"] | undefined | null> | undefined | null
};
	["RedemptionCode_where_or"]: {
	creator?: ResolverInputTypes["RedemptionCode_creator_operator"] | undefined | null,
	status?: ResolverInputTypes["RedemptionCode_status_operator"] | undefined | null,
	qrCodeId?: ResolverInputTypes["RedemptionCode_qrCodeId_operator"] | undefined | null,
	lastCheckedInDateTime?: ResolverInputTypes["RedemptionCode_lastCheckedInDateTime_operator"] | undefined | null,
	voidedDateTime?: ResolverInputTypes["RedemptionCode_voidedDateTime_operator"] | undefined | null,
	refundedDateTime?: ResolverInputTypes["RedemptionCode_refundedDateTime_operator"] | undefined | null,
	eventStartTime?: ResolverInputTypes["RedemptionCode_eventStartTime_operator"] | undefined | null,
	eventEndTime?: ResolverInputTypes["RedemptionCode_eventEndTime_operator"] | undefined | null,
	eventPriceZone?: ResolverInputTypes["RedemptionCode_eventPriceZone_operator"] | undefined | null,
	ownerName?: ResolverInputTypes["RedemptionCode_ownerName_operator"] | undefined | null,
	phone?: ResolverInputTypes["RedemptionCode_phone_operator"] | undefined | null,
	pdf?: ResolverInputTypes["RedemptionCode_pdf_operator"] | undefined | null,
	variants__variantId?: ResolverInputTypes["RedemptionCode_variants__variantId_operator"] | undefined | null,
	variants__name?: ResolverInputTypes["RedemptionCode_variants__name_operator"] | undefined | null,
	variants__quantity?: ResolverInputTypes["RedemptionCode_variants__quantity_operator"] | undefined | null,
	variants__totalCost?: ResolverInputTypes["RedemptionCode_variants__totalCost_operator"] | undefined | null,
	variants__id?: ResolverInputTypes["RedemptionCode_variants__id_operator"] | undefined | null,
	productId?: ResolverInputTypes["RedemptionCode_productId_operator"] | undefined | null,
	referenceId?: ResolverInputTypes["RedemptionCode_referenceId_operator"] | undefined | null,
	productType?: ResolverInputTypes["RedemptionCode_productType_operator"] | undefined | null,
	productName?: ResolverInputTypes["RedemptionCode_productName_operator"] | undefined | null,
	linkedEvent?: ResolverInputTypes["RedemptionCode_linkedEvent_operator"] | undefined | null,
	timableOrderId?: ResolverInputTypes["RedemptionCode_timableOrderId_operator"] | undefined | null,
	purchaseDateTime?: ResolverInputTypes["RedemptionCode_purchaseDateTime_operator"] | undefined | null,
	changeHistory__timestamp?: ResolverInputTypes["RedemptionCode_changeHistory__timestamp_operator"] | undefined | null,
	changeHistory__field?: ResolverInputTypes["RedemptionCode_changeHistory__field_operator"] | undefined | null,
	changeHistory__oldValue?: ResolverInputTypes["RedemptionCode_changeHistory__oldValue_operator"] | undefined | null,
	changeHistory__newValue?: ResolverInputTypes["RedemptionCode_changeHistory__newValue_operator"] | undefined | null,
	changeHistory__id?: ResolverInputTypes["RedemptionCode_changeHistory__id_operator"] | undefined | null,
	updatedAt?: ResolverInputTypes["RedemptionCode_updatedAt_operator"] | undefined | null,
	createdAt?: ResolverInputTypes["RedemptionCode_createdAt_operator"] | undefined | null,
	id?: ResolverInputTypes["RedemptionCode_id_operator"] | undefined | null,
	AND?: Array<ResolverInputTypes["RedemptionCode_where_and"] | undefined | null> | undefined | null,
	OR?: Array<ResolverInputTypes["RedemptionCode_where_or"] | undefined | null> | undefined | null
};
	["countRedemptionCodes"]: AliasType<{
	totalDocs?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["redemption_codesDocAccess"]: AliasType<{
	fields?:ResolverInputTypes["RedemptionCodesDocAccessFields"],
	create?:ResolverInputTypes["RedemptionCodesCreateDocAccess"],
	read?:ResolverInputTypes["RedemptionCodesReadDocAccess"],
	update?:ResolverInputTypes["RedemptionCodesUpdateDocAccess"],
	delete?:ResolverInputTypes["RedemptionCodesDeleteDocAccess"],
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesDocAccessFields"]: AliasType<{
	creator?:ResolverInputTypes["RedemptionCodesDocAccessFields_creator"],
	status?:ResolverInputTypes["RedemptionCodesDocAccessFields_status"],
	qrCodeId?:ResolverInputTypes["RedemptionCodesDocAccessFields_qrCodeId"],
	lastCheckedInDateTime?:ResolverInputTypes["RedemptionCodesDocAccessFields_lastCheckedInDateTime"],
	voidedDateTime?:ResolverInputTypes["RedemptionCodesDocAccessFields_voidedDateTime"],
	refundedDateTime?:ResolverInputTypes["RedemptionCodesDocAccessFields_refundedDateTime"],
	eventStartTime?:ResolverInputTypes["RedemptionCodesDocAccessFields_eventStartTime"],
	eventEndTime?:ResolverInputTypes["RedemptionCodesDocAccessFields_eventEndTime"],
	eventPriceZone?:ResolverInputTypes["RedemptionCodesDocAccessFields_eventPriceZone"],
	ownerName?:ResolverInputTypes["RedemptionCodesDocAccessFields_ownerName"],
	phone?:ResolverInputTypes["RedemptionCodesDocAccessFields_phone"],
	pdf?:ResolverInputTypes["RedemptionCodesDocAccessFields_pdf"],
	variants?:ResolverInputTypes["RedemptionCodesDocAccessFields_variants"],
	productId?:ResolverInputTypes["RedemptionCodesDocAccessFields_productId"],
	referenceId?:ResolverInputTypes["RedemptionCodesDocAccessFields_referenceId"],
	productType?:ResolverInputTypes["RedemptionCodesDocAccessFields_productType"],
	productName?:ResolverInputTypes["RedemptionCodesDocAccessFields_productName"],
	linkedEvent?:ResolverInputTypes["RedemptionCodesDocAccessFields_linkedEvent"],
	timableOrderId?:ResolverInputTypes["RedemptionCodesDocAccessFields_timableOrderId"],
	purchaseDateTime?:ResolverInputTypes["RedemptionCodesDocAccessFields_purchaseDateTime"],
	changeHistory?:ResolverInputTypes["RedemptionCodesDocAccessFields_changeHistory"],
	updatedAt?:ResolverInputTypes["RedemptionCodesDocAccessFields_updatedAt"],
	createdAt?:ResolverInputTypes["RedemptionCodesDocAccessFields_createdAt"],
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesDocAccessFields_creator"]: AliasType<{
	create?:ResolverInputTypes["RedemptionCodesDocAccessFields_creator_Create"],
	read?:ResolverInputTypes["RedemptionCodesDocAccessFields_creator_Read"],
	update?:ResolverInputTypes["RedemptionCodesDocAccessFields_creator_Update"],
	delete?:ResolverInputTypes["RedemptionCodesDocAccessFields_creator_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesDocAccessFields_creator_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesDocAccessFields_creator_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesDocAccessFields_creator_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesDocAccessFields_creator_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesDocAccessFields_status"]: AliasType<{
	create?:ResolverInputTypes["RedemptionCodesDocAccessFields_status_Create"],
	read?:ResolverInputTypes["RedemptionCodesDocAccessFields_status_Read"],
	update?:ResolverInputTypes["RedemptionCodesDocAccessFields_status_Update"],
	delete?:ResolverInputTypes["RedemptionCodesDocAccessFields_status_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesDocAccessFields_status_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesDocAccessFields_status_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesDocAccessFields_status_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesDocAccessFields_status_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesDocAccessFields_qrCodeId"]: AliasType<{
	create?:ResolverInputTypes["RedemptionCodesDocAccessFields_qrCodeId_Create"],
	read?:ResolverInputTypes["RedemptionCodesDocAccessFields_qrCodeId_Read"],
	update?:ResolverInputTypes["RedemptionCodesDocAccessFields_qrCodeId_Update"],
	delete?:ResolverInputTypes["RedemptionCodesDocAccessFields_qrCodeId_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesDocAccessFields_qrCodeId_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesDocAccessFields_qrCodeId_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesDocAccessFields_qrCodeId_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesDocAccessFields_qrCodeId_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesDocAccessFields_lastCheckedInDateTime"]: AliasType<{
	create?:ResolverInputTypes["RedemptionCodesDocAccessFields_lastCheckedInDateTime_Create"],
	read?:ResolverInputTypes["RedemptionCodesDocAccessFields_lastCheckedInDateTime_Read"],
	update?:ResolverInputTypes["RedemptionCodesDocAccessFields_lastCheckedInDateTime_Update"],
	delete?:ResolverInputTypes["RedemptionCodesDocAccessFields_lastCheckedInDateTime_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesDocAccessFields_lastCheckedInDateTime_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesDocAccessFields_lastCheckedInDateTime_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesDocAccessFields_lastCheckedInDateTime_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesDocAccessFields_lastCheckedInDateTime_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesDocAccessFields_voidedDateTime"]: AliasType<{
	create?:ResolverInputTypes["RedemptionCodesDocAccessFields_voidedDateTime_Create"],
	read?:ResolverInputTypes["RedemptionCodesDocAccessFields_voidedDateTime_Read"],
	update?:ResolverInputTypes["RedemptionCodesDocAccessFields_voidedDateTime_Update"],
	delete?:ResolverInputTypes["RedemptionCodesDocAccessFields_voidedDateTime_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesDocAccessFields_voidedDateTime_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesDocAccessFields_voidedDateTime_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesDocAccessFields_voidedDateTime_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesDocAccessFields_voidedDateTime_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesDocAccessFields_refundedDateTime"]: AliasType<{
	create?:ResolverInputTypes["RedemptionCodesDocAccessFields_refundedDateTime_Create"],
	read?:ResolverInputTypes["RedemptionCodesDocAccessFields_refundedDateTime_Read"],
	update?:ResolverInputTypes["RedemptionCodesDocAccessFields_refundedDateTime_Update"],
	delete?:ResolverInputTypes["RedemptionCodesDocAccessFields_refundedDateTime_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesDocAccessFields_refundedDateTime_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesDocAccessFields_refundedDateTime_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesDocAccessFields_refundedDateTime_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesDocAccessFields_refundedDateTime_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesDocAccessFields_eventStartTime"]: AliasType<{
	create?:ResolverInputTypes["RedemptionCodesDocAccessFields_eventStartTime_Create"],
	read?:ResolverInputTypes["RedemptionCodesDocAccessFields_eventStartTime_Read"],
	update?:ResolverInputTypes["RedemptionCodesDocAccessFields_eventStartTime_Update"],
	delete?:ResolverInputTypes["RedemptionCodesDocAccessFields_eventStartTime_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesDocAccessFields_eventStartTime_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesDocAccessFields_eventStartTime_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesDocAccessFields_eventStartTime_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesDocAccessFields_eventStartTime_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesDocAccessFields_eventEndTime"]: AliasType<{
	create?:ResolverInputTypes["RedemptionCodesDocAccessFields_eventEndTime_Create"],
	read?:ResolverInputTypes["RedemptionCodesDocAccessFields_eventEndTime_Read"],
	update?:ResolverInputTypes["RedemptionCodesDocAccessFields_eventEndTime_Update"],
	delete?:ResolverInputTypes["RedemptionCodesDocAccessFields_eventEndTime_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesDocAccessFields_eventEndTime_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesDocAccessFields_eventEndTime_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesDocAccessFields_eventEndTime_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesDocAccessFields_eventEndTime_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesDocAccessFields_eventPriceZone"]: AliasType<{
	create?:ResolverInputTypes["RedemptionCodesDocAccessFields_eventPriceZone_Create"],
	read?:ResolverInputTypes["RedemptionCodesDocAccessFields_eventPriceZone_Read"],
	update?:ResolverInputTypes["RedemptionCodesDocAccessFields_eventPriceZone_Update"],
	delete?:ResolverInputTypes["RedemptionCodesDocAccessFields_eventPriceZone_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesDocAccessFields_eventPriceZone_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesDocAccessFields_eventPriceZone_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesDocAccessFields_eventPriceZone_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesDocAccessFields_eventPriceZone_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesDocAccessFields_ownerName"]: AliasType<{
	create?:ResolverInputTypes["RedemptionCodesDocAccessFields_ownerName_Create"],
	read?:ResolverInputTypes["RedemptionCodesDocAccessFields_ownerName_Read"],
	update?:ResolverInputTypes["RedemptionCodesDocAccessFields_ownerName_Update"],
	delete?:ResolverInputTypes["RedemptionCodesDocAccessFields_ownerName_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesDocAccessFields_ownerName_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesDocAccessFields_ownerName_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesDocAccessFields_ownerName_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesDocAccessFields_ownerName_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesDocAccessFields_phone"]: AliasType<{
	create?:ResolverInputTypes["RedemptionCodesDocAccessFields_phone_Create"],
	read?:ResolverInputTypes["RedemptionCodesDocAccessFields_phone_Read"],
	update?:ResolverInputTypes["RedemptionCodesDocAccessFields_phone_Update"],
	delete?:ResolverInputTypes["RedemptionCodesDocAccessFields_phone_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesDocAccessFields_phone_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesDocAccessFields_phone_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesDocAccessFields_phone_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesDocAccessFields_phone_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesDocAccessFields_pdf"]: AliasType<{
	create?:ResolverInputTypes["RedemptionCodesDocAccessFields_pdf_Create"],
	read?:ResolverInputTypes["RedemptionCodesDocAccessFields_pdf_Read"],
	update?:ResolverInputTypes["RedemptionCodesDocAccessFields_pdf_Update"],
	delete?:ResolverInputTypes["RedemptionCodesDocAccessFields_pdf_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesDocAccessFields_pdf_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesDocAccessFields_pdf_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesDocAccessFields_pdf_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesDocAccessFields_pdf_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesDocAccessFields_variants"]: AliasType<{
	create?:ResolverInputTypes["RedemptionCodesDocAccessFields_variants_Create"],
	read?:ResolverInputTypes["RedemptionCodesDocAccessFields_variants_Read"],
	update?:ResolverInputTypes["RedemptionCodesDocAccessFields_variants_Update"],
	delete?:ResolverInputTypes["RedemptionCodesDocAccessFields_variants_Delete"],
	fields?:ResolverInputTypes["RedemptionCodesDocAccessFields_variants_Fields"],
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesDocAccessFields_variants_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesDocAccessFields_variants_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesDocAccessFields_variants_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesDocAccessFields_variants_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesDocAccessFields_variants_Fields"]: AliasType<{
	variantId?:ResolverInputTypes["RedemptionCodesDocAccessFields_variants_variantId"],
	name?:ResolverInputTypes["RedemptionCodesDocAccessFields_variants_name"],
	quantity?:ResolverInputTypes["RedemptionCodesDocAccessFields_variants_quantity"],
	totalCost?:ResolverInputTypes["RedemptionCodesDocAccessFields_variants_totalCost"],
	id?:ResolverInputTypes["RedemptionCodesDocAccessFields_variants_id"],
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesDocAccessFields_variants_variantId"]: AliasType<{
	create?:ResolverInputTypes["RedemptionCodesDocAccessFields_variants_variantId_Create"],
	read?:ResolverInputTypes["RedemptionCodesDocAccessFields_variants_variantId_Read"],
	update?:ResolverInputTypes["RedemptionCodesDocAccessFields_variants_variantId_Update"],
	delete?:ResolverInputTypes["RedemptionCodesDocAccessFields_variants_variantId_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesDocAccessFields_variants_variantId_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesDocAccessFields_variants_variantId_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesDocAccessFields_variants_variantId_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesDocAccessFields_variants_variantId_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesDocAccessFields_variants_name"]: AliasType<{
	create?:ResolverInputTypes["RedemptionCodesDocAccessFields_variants_name_Create"],
	read?:ResolverInputTypes["RedemptionCodesDocAccessFields_variants_name_Read"],
	update?:ResolverInputTypes["RedemptionCodesDocAccessFields_variants_name_Update"],
	delete?:ResolverInputTypes["RedemptionCodesDocAccessFields_variants_name_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesDocAccessFields_variants_name_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesDocAccessFields_variants_name_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesDocAccessFields_variants_name_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesDocAccessFields_variants_name_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesDocAccessFields_variants_quantity"]: AliasType<{
	create?:ResolverInputTypes["RedemptionCodesDocAccessFields_variants_quantity_Create"],
	read?:ResolverInputTypes["RedemptionCodesDocAccessFields_variants_quantity_Read"],
	update?:ResolverInputTypes["RedemptionCodesDocAccessFields_variants_quantity_Update"],
	delete?:ResolverInputTypes["RedemptionCodesDocAccessFields_variants_quantity_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesDocAccessFields_variants_quantity_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesDocAccessFields_variants_quantity_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesDocAccessFields_variants_quantity_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesDocAccessFields_variants_quantity_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesDocAccessFields_variants_totalCost"]: AliasType<{
	create?:ResolverInputTypes["RedemptionCodesDocAccessFields_variants_totalCost_Create"],
	read?:ResolverInputTypes["RedemptionCodesDocAccessFields_variants_totalCost_Read"],
	update?:ResolverInputTypes["RedemptionCodesDocAccessFields_variants_totalCost_Update"],
	delete?:ResolverInputTypes["RedemptionCodesDocAccessFields_variants_totalCost_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesDocAccessFields_variants_totalCost_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesDocAccessFields_variants_totalCost_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesDocAccessFields_variants_totalCost_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesDocAccessFields_variants_totalCost_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesDocAccessFields_variants_id"]: AliasType<{
	create?:ResolverInputTypes["RedemptionCodesDocAccessFields_variants_id_Create"],
	read?:ResolverInputTypes["RedemptionCodesDocAccessFields_variants_id_Read"],
	update?:ResolverInputTypes["RedemptionCodesDocAccessFields_variants_id_Update"],
	delete?:ResolverInputTypes["RedemptionCodesDocAccessFields_variants_id_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesDocAccessFields_variants_id_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesDocAccessFields_variants_id_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesDocAccessFields_variants_id_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesDocAccessFields_variants_id_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesDocAccessFields_productId"]: AliasType<{
	create?:ResolverInputTypes["RedemptionCodesDocAccessFields_productId_Create"],
	read?:ResolverInputTypes["RedemptionCodesDocAccessFields_productId_Read"],
	update?:ResolverInputTypes["RedemptionCodesDocAccessFields_productId_Update"],
	delete?:ResolverInputTypes["RedemptionCodesDocAccessFields_productId_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesDocAccessFields_productId_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesDocAccessFields_productId_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesDocAccessFields_productId_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesDocAccessFields_productId_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesDocAccessFields_referenceId"]: AliasType<{
	create?:ResolverInputTypes["RedemptionCodesDocAccessFields_referenceId_Create"],
	read?:ResolverInputTypes["RedemptionCodesDocAccessFields_referenceId_Read"],
	update?:ResolverInputTypes["RedemptionCodesDocAccessFields_referenceId_Update"],
	delete?:ResolverInputTypes["RedemptionCodesDocAccessFields_referenceId_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesDocAccessFields_referenceId_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesDocAccessFields_referenceId_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesDocAccessFields_referenceId_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesDocAccessFields_referenceId_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesDocAccessFields_productType"]: AliasType<{
	create?:ResolverInputTypes["RedemptionCodesDocAccessFields_productType_Create"],
	read?:ResolverInputTypes["RedemptionCodesDocAccessFields_productType_Read"],
	update?:ResolverInputTypes["RedemptionCodesDocAccessFields_productType_Update"],
	delete?:ResolverInputTypes["RedemptionCodesDocAccessFields_productType_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesDocAccessFields_productType_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesDocAccessFields_productType_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesDocAccessFields_productType_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesDocAccessFields_productType_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesDocAccessFields_productName"]: AliasType<{
	create?:ResolverInputTypes["RedemptionCodesDocAccessFields_productName_Create"],
	read?:ResolverInputTypes["RedemptionCodesDocAccessFields_productName_Read"],
	update?:ResolverInputTypes["RedemptionCodesDocAccessFields_productName_Update"],
	delete?:ResolverInputTypes["RedemptionCodesDocAccessFields_productName_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesDocAccessFields_productName_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesDocAccessFields_productName_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesDocAccessFields_productName_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesDocAccessFields_productName_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesDocAccessFields_linkedEvent"]: AliasType<{
	create?:ResolverInputTypes["RedemptionCodesDocAccessFields_linkedEvent_Create"],
	read?:ResolverInputTypes["RedemptionCodesDocAccessFields_linkedEvent_Read"],
	update?:ResolverInputTypes["RedemptionCodesDocAccessFields_linkedEvent_Update"],
	delete?:ResolverInputTypes["RedemptionCodesDocAccessFields_linkedEvent_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesDocAccessFields_linkedEvent_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesDocAccessFields_linkedEvent_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesDocAccessFields_linkedEvent_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesDocAccessFields_linkedEvent_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesDocAccessFields_timableOrderId"]: AliasType<{
	create?:ResolverInputTypes["RedemptionCodesDocAccessFields_timableOrderId_Create"],
	read?:ResolverInputTypes["RedemptionCodesDocAccessFields_timableOrderId_Read"],
	update?:ResolverInputTypes["RedemptionCodesDocAccessFields_timableOrderId_Update"],
	delete?:ResolverInputTypes["RedemptionCodesDocAccessFields_timableOrderId_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesDocAccessFields_timableOrderId_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesDocAccessFields_timableOrderId_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesDocAccessFields_timableOrderId_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesDocAccessFields_timableOrderId_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesDocAccessFields_purchaseDateTime"]: AliasType<{
	create?:ResolverInputTypes["RedemptionCodesDocAccessFields_purchaseDateTime_Create"],
	read?:ResolverInputTypes["RedemptionCodesDocAccessFields_purchaseDateTime_Read"],
	update?:ResolverInputTypes["RedemptionCodesDocAccessFields_purchaseDateTime_Update"],
	delete?:ResolverInputTypes["RedemptionCodesDocAccessFields_purchaseDateTime_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesDocAccessFields_purchaseDateTime_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesDocAccessFields_purchaseDateTime_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesDocAccessFields_purchaseDateTime_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesDocAccessFields_purchaseDateTime_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesDocAccessFields_changeHistory"]: AliasType<{
	create?:ResolverInputTypes["RedemptionCodesDocAccessFields_changeHistory_Create"],
	read?:ResolverInputTypes["RedemptionCodesDocAccessFields_changeHistory_Read"],
	update?:ResolverInputTypes["RedemptionCodesDocAccessFields_changeHistory_Update"],
	delete?:ResolverInputTypes["RedemptionCodesDocAccessFields_changeHistory_Delete"],
	fields?:ResolverInputTypes["RedemptionCodesDocAccessFields_changeHistory_Fields"],
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesDocAccessFields_changeHistory_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesDocAccessFields_changeHistory_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesDocAccessFields_changeHistory_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesDocAccessFields_changeHistory_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesDocAccessFields_changeHistory_Fields"]: AliasType<{
	timestamp?:ResolverInputTypes["RedemptionCodesDocAccessFields_changeHistory_timestamp"],
	field?:ResolverInputTypes["RedemptionCodesDocAccessFields_changeHistory_field"],
	oldValue?:ResolverInputTypes["RedemptionCodesDocAccessFields_changeHistory_oldValue"],
	newValue?:ResolverInputTypes["RedemptionCodesDocAccessFields_changeHistory_newValue"],
	id?:ResolverInputTypes["RedemptionCodesDocAccessFields_changeHistory_id"],
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesDocAccessFields_changeHistory_timestamp"]: AliasType<{
	create?:ResolverInputTypes["RedemptionCodesDocAccessFields_changeHistory_timestamp_Create"],
	read?:ResolverInputTypes["RedemptionCodesDocAccessFields_changeHistory_timestamp_Read"],
	update?:ResolverInputTypes["RedemptionCodesDocAccessFields_changeHistory_timestamp_Update"],
	delete?:ResolverInputTypes["RedemptionCodesDocAccessFields_changeHistory_timestamp_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesDocAccessFields_changeHistory_timestamp_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesDocAccessFields_changeHistory_timestamp_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesDocAccessFields_changeHistory_timestamp_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesDocAccessFields_changeHistory_timestamp_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesDocAccessFields_changeHistory_field"]: AliasType<{
	create?:ResolverInputTypes["RedemptionCodesDocAccessFields_changeHistory_field_Create"],
	read?:ResolverInputTypes["RedemptionCodesDocAccessFields_changeHistory_field_Read"],
	update?:ResolverInputTypes["RedemptionCodesDocAccessFields_changeHistory_field_Update"],
	delete?:ResolverInputTypes["RedemptionCodesDocAccessFields_changeHistory_field_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesDocAccessFields_changeHistory_field_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesDocAccessFields_changeHistory_field_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesDocAccessFields_changeHistory_field_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesDocAccessFields_changeHistory_field_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesDocAccessFields_changeHistory_oldValue"]: AliasType<{
	create?:ResolverInputTypes["RedemptionCodesDocAccessFields_changeHistory_oldValue_Create"],
	read?:ResolverInputTypes["RedemptionCodesDocAccessFields_changeHistory_oldValue_Read"],
	update?:ResolverInputTypes["RedemptionCodesDocAccessFields_changeHistory_oldValue_Update"],
	delete?:ResolverInputTypes["RedemptionCodesDocAccessFields_changeHistory_oldValue_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesDocAccessFields_changeHistory_oldValue_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesDocAccessFields_changeHistory_oldValue_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesDocAccessFields_changeHistory_oldValue_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesDocAccessFields_changeHistory_oldValue_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesDocAccessFields_changeHistory_newValue"]: AliasType<{
	create?:ResolverInputTypes["RedemptionCodesDocAccessFields_changeHistory_newValue_Create"],
	read?:ResolverInputTypes["RedemptionCodesDocAccessFields_changeHistory_newValue_Read"],
	update?:ResolverInputTypes["RedemptionCodesDocAccessFields_changeHistory_newValue_Update"],
	delete?:ResolverInputTypes["RedemptionCodesDocAccessFields_changeHistory_newValue_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesDocAccessFields_changeHistory_newValue_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesDocAccessFields_changeHistory_newValue_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesDocAccessFields_changeHistory_newValue_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesDocAccessFields_changeHistory_newValue_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesDocAccessFields_changeHistory_id"]: AliasType<{
	create?:ResolverInputTypes["RedemptionCodesDocAccessFields_changeHistory_id_Create"],
	read?:ResolverInputTypes["RedemptionCodesDocAccessFields_changeHistory_id_Read"],
	update?:ResolverInputTypes["RedemptionCodesDocAccessFields_changeHistory_id_Update"],
	delete?:ResolverInputTypes["RedemptionCodesDocAccessFields_changeHistory_id_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesDocAccessFields_changeHistory_id_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesDocAccessFields_changeHistory_id_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesDocAccessFields_changeHistory_id_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesDocAccessFields_changeHistory_id_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesDocAccessFields_updatedAt"]: AliasType<{
	create?:ResolverInputTypes["RedemptionCodesDocAccessFields_updatedAt_Create"],
	read?:ResolverInputTypes["RedemptionCodesDocAccessFields_updatedAt_Read"],
	update?:ResolverInputTypes["RedemptionCodesDocAccessFields_updatedAt_Update"],
	delete?:ResolverInputTypes["RedemptionCodesDocAccessFields_updatedAt_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesDocAccessFields_updatedAt_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesDocAccessFields_updatedAt_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesDocAccessFields_updatedAt_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesDocAccessFields_updatedAt_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesDocAccessFields_createdAt"]: AliasType<{
	create?:ResolverInputTypes["RedemptionCodesDocAccessFields_createdAt_Create"],
	read?:ResolverInputTypes["RedemptionCodesDocAccessFields_createdAt_Read"],
	update?:ResolverInputTypes["RedemptionCodesDocAccessFields_createdAt_Update"],
	delete?:ResolverInputTypes["RedemptionCodesDocAccessFields_createdAt_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesDocAccessFields_createdAt_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesDocAccessFields_createdAt_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesDocAccessFields_createdAt_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesDocAccessFields_createdAt_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesCreateDocAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesReadDocAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesUpdateDocAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesDeleteDocAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionPdfs"]: AliasType<{
	docs?:ResolverInputTypes["RedemptionPdf"],
	hasNextPage?:boolean | `@${string}`,
	hasPrevPage?:boolean | `@${string}`,
	limit?:boolean | `@${string}`,
	nextPage?:boolean | `@${string}`,
	offset?:boolean | `@${string}`,
	page?:boolean | `@${string}`,
	pagingCounter?:boolean | `@${string}`,
	prevPage?:boolean | `@${string}`,
	totalDocs?:boolean | `@${string}`,
	totalPages?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionPdf_where"]: {
	updatedAt?: ResolverInputTypes["RedemptionPdf_updatedAt_operator"] | undefined | null,
	createdAt?: ResolverInputTypes["RedemptionPdf_createdAt_operator"] | undefined | null,
	url?: ResolverInputTypes["RedemptionPdf_url_operator"] | undefined | null,
	filename?: ResolverInputTypes["RedemptionPdf_filename_operator"] | undefined | null,
	mimeType?: ResolverInputTypes["RedemptionPdf_mimeType_operator"] | undefined | null,
	filesize?: ResolverInputTypes["RedemptionPdf_filesize_operator"] | undefined | null,
	width?: ResolverInputTypes["RedemptionPdf_width_operator"] | undefined | null,
	height?: ResolverInputTypes["RedemptionPdf_height_operator"] | undefined | null,
	focalX?: ResolverInputTypes["RedemptionPdf_focalX_operator"] | undefined | null,
	focalY?: ResolverInputTypes["RedemptionPdf_focalY_operator"] | undefined | null,
	id?: ResolverInputTypes["RedemptionPdf_id_operator"] | undefined | null,
	AND?: Array<ResolverInputTypes["RedemptionPdf_where_and"] | undefined | null> | undefined | null,
	OR?: Array<ResolverInputTypes["RedemptionPdf_where_or"] | undefined | null> | undefined | null
};
	["RedemptionPdf_updatedAt_operator"]: {
	equals?: ResolverInputTypes["DateTime"] | undefined | null,
	not_equals?: ResolverInputTypes["DateTime"] | undefined | null,
	greater_than_equal?: ResolverInputTypes["DateTime"] | undefined | null,
	greater_than?: ResolverInputTypes["DateTime"] | undefined | null,
	less_than_equal?: ResolverInputTypes["DateTime"] | undefined | null,
	less_than?: ResolverInputTypes["DateTime"] | undefined | null,
	like?: ResolverInputTypes["DateTime"] | undefined | null,
	exists?: boolean | undefined | null
};
	["RedemptionPdf_createdAt_operator"]: {
	equals?: ResolverInputTypes["DateTime"] | undefined | null,
	not_equals?: ResolverInputTypes["DateTime"] | undefined | null,
	greater_than_equal?: ResolverInputTypes["DateTime"] | undefined | null,
	greater_than?: ResolverInputTypes["DateTime"] | undefined | null,
	less_than_equal?: ResolverInputTypes["DateTime"] | undefined | null,
	less_than?: ResolverInputTypes["DateTime"] | undefined | null,
	like?: ResolverInputTypes["DateTime"] | undefined | null,
	exists?: boolean | undefined | null
};
	["RedemptionPdf_url_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["RedemptionPdf_filename_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["RedemptionPdf_mimeType_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["RedemptionPdf_filesize_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["RedemptionPdf_width_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["RedemptionPdf_height_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["RedemptionPdf_focalX_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["RedemptionPdf_focalY_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["RedemptionPdf_id_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["RedemptionPdf_where_and"]: {
	updatedAt?: ResolverInputTypes["RedemptionPdf_updatedAt_operator"] | undefined | null,
	createdAt?: ResolverInputTypes["RedemptionPdf_createdAt_operator"] | undefined | null,
	url?: ResolverInputTypes["RedemptionPdf_url_operator"] | undefined | null,
	filename?: ResolverInputTypes["RedemptionPdf_filename_operator"] | undefined | null,
	mimeType?: ResolverInputTypes["RedemptionPdf_mimeType_operator"] | undefined | null,
	filesize?: ResolverInputTypes["RedemptionPdf_filesize_operator"] | undefined | null,
	width?: ResolverInputTypes["RedemptionPdf_width_operator"] | undefined | null,
	height?: ResolverInputTypes["RedemptionPdf_height_operator"] | undefined | null,
	focalX?: ResolverInputTypes["RedemptionPdf_focalX_operator"] | undefined | null,
	focalY?: ResolverInputTypes["RedemptionPdf_focalY_operator"] | undefined | null,
	id?: ResolverInputTypes["RedemptionPdf_id_operator"] | undefined | null,
	AND?: Array<ResolverInputTypes["RedemptionPdf_where_and"] | undefined | null> | undefined | null,
	OR?: Array<ResolverInputTypes["RedemptionPdf_where_or"] | undefined | null> | undefined | null
};
	["RedemptionPdf_where_or"]: {
	updatedAt?: ResolverInputTypes["RedemptionPdf_updatedAt_operator"] | undefined | null,
	createdAt?: ResolverInputTypes["RedemptionPdf_createdAt_operator"] | undefined | null,
	url?: ResolverInputTypes["RedemptionPdf_url_operator"] | undefined | null,
	filename?: ResolverInputTypes["RedemptionPdf_filename_operator"] | undefined | null,
	mimeType?: ResolverInputTypes["RedemptionPdf_mimeType_operator"] | undefined | null,
	filesize?: ResolverInputTypes["RedemptionPdf_filesize_operator"] | undefined | null,
	width?: ResolverInputTypes["RedemptionPdf_width_operator"] | undefined | null,
	height?: ResolverInputTypes["RedemptionPdf_height_operator"] | undefined | null,
	focalX?: ResolverInputTypes["RedemptionPdf_focalX_operator"] | undefined | null,
	focalY?: ResolverInputTypes["RedemptionPdf_focalY_operator"] | undefined | null,
	id?: ResolverInputTypes["RedemptionPdf_id_operator"] | undefined | null,
	AND?: Array<ResolverInputTypes["RedemptionPdf_where_and"] | undefined | null> | undefined | null,
	OR?: Array<ResolverInputTypes["RedemptionPdf_where_or"] | undefined | null> | undefined | null
};
	["countRedemptionPdfs"]: AliasType<{
	totalDocs?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["redemption_pdfDocAccess"]: AliasType<{
	fields?:ResolverInputTypes["RedemptionPdfDocAccessFields"],
	create?:ResolverInputTypes["RedemptionPdfCreateDocAccess"],
	read?:ResolverInputTypes["RedemptionPdfReadDocAccess"],
	update?:ResolverInputTypes["RedemptionPdfUpdateDocAccess"],
	delete?:ResolverInputTypes["RedemptionPdfDeleteDocAccess"],
		__typename?: boolean | `@${string}`
}>;
	["RedemptionPdfDocAccessFields"]: AliasType<{
	updatedAt?:ResolverInputTypes["RedemptionPdfDocAccessFields_updatedAt"],
	createdAt?:ResolverInputTypes["RedemptionPdfDocAccessFields_createdAt"],
	url?:ResolverInputTypes["RedemptionPdfDocAccessFields_url"],
	filename?:ResolverInputTypes["RedemptionPdfDocAccessFields_filename"],
	mimeType?:ResolverInputTypes["RedemptionPdfDocAccessFields_mimeType"],
	filesize?:ResolverInputTypes["RedemptionPdfDocAccessFields_filesize"],
	width?:ResolverInputTypes["RedemptionPdfDocAccessFields_width"],
	height?:ResolverInputTypes["RedemptionPdfDocAccessFields_height"],
	focalX?:ResolverInputTypes["RedemptionPdfDocAccessFields_focalX"],
	focalY?:ResolverInputTypes["RedemptionPdfDocAccessFields_focalY"],
		__typename?: boolean | `@${string}`
}>;
	["RedemptionPdfDocAccessFields_updatedAt"]: AliasType<{
	create?:ResolverInputTypes["RedemptionPdfDocAccessFields_updatedAt_Create"],
	read?:ResolverInputTypes["RedemptionPdfDocAccessFields_updatedAt_Read"],
	update?:ResolverInputTypes["RedemptionPdfDocAccessFields_updatedAt_Update"],
	delete?:ResolverInputTypes["RedemptionPdfDocAccessFields_updatedAt_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["RedemptionPdfDocAccessFields_updatedAt_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionPdfDocAccessFields_updatedAt_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionPdfDocAccessFields_updatedAt_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionPdfDocAccessFields_updatedAt_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionPdfDocAccessFields_createdAt"]: AliasType<{
	create?:ResolverInputTypes["RedemptionPdfDocAccessFields_createdAt_Create"],
	read?:ResolverInputTypes["RedemptionPdfDocAccessFields_createdAt_Read"],
	update?:ResolverInputTypes["RedemptionPdfDocAccessFields_createdAt_Update"],
	delete?:ResolverInputTypes["RedemptionPdfDocAccessFields_createdAt_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["RedemptionPdfDocAccessFields_createdAt_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionPdfDocAccessFields_createdAt_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionPdfDocAccessFields_createdAt_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionPdfDocAccessFields_createdAt_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionPdfDocAccessFields_url"]: AliasType<{
	create?:ResolverInputTypes["RedemptionPdfDocAccessFields_url_Create"],
	read?:ResolverInputTypes["RedemptionPdfDocAccessFields_url_Read"],
	update?:ResolverInputTypes["RedemptionPdfDocAccessFields_url_Update"],
	delete?:ResolverInputTypes["RedemptionPdfDocAccessFields_url_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["RedemptionPdfDocAccessFields_url_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionPdfDocAccessFields_url_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionPdfDocAccessFields_url_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionPdfDocAccessFields_url_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionPdfDocAccessFields_filename"]: AliasType<{
	create?:ResolverInputTypes["RedemptionPdfDocAccessFields_filename_Create"],
	read?:ResolverInputTypes["RedemptionPdfDocAccessFields_filename_Read"],
	update?:ResolverInputTypes["RedemptionPdfDocAccessFields_filename_Update"],
	delete?:ResolverInputTypes["RedemptionPdfDocAccessFields_filename_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["RedemptionPdfDocAccessFields_filename_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionPdfDocAccessFields_filename_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionPdfDocAccessFields_filename_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionPdfDocAccessFields_filename_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionPdfDocAccessFields_mimeType"]: AliasType<{
	create?:ResolverInputTypes["RedemptionPdfDocAccessFields_mimeType_Create"],
	read?:ResolverInputTypes["RedemptionPdfDocAccessFields_mimeType_Read"],
	update?:ResolverInputTypes["RedemptionPdfDocAccessFields_mimeType_Update"],
	delete?:ResolverInputTypes["RedemptionPdfDocAccessFields_mimeType_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["RedemptionPdfDocAccessFields_mimeType_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionPdfDocAccessFields_mimeType_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionPdfDocAccessFields_mimeType_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionPdfDocAccessFields_mimeType_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionPdfDocAccessFields_filesize"]: AliasType<{
	create?:ResolverInputTypes["RedemptionPdfDocAccessFields_filesize_Create"],
	read?:ResolverInputTypes["RedemptionPdfDocAccessFields_filesize_Read"],
	update?:ResolverInputTypes["RedemptionPdfDocAccessFields_filesize_Update"],
	delete?:ResolverInputTypes["RedemptionPdfDocAccessFields_filesize_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["RedemptionPdfDocAccessFields_filesize_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionPdfDocAccessFields_filesize_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionPdfDocAccessFields_filesize_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionPdfDocAccessFields_filesize_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionPdfDocAccessFields_width"]: AliasType<{
	create?:ResolverInputTypes["RedemptionPdfDocAccessFields_width_Create"],
	read?:ResolverInputTypes["RedemptionPdfDocAccessFields_width_Read"],
	update?:ResolverInputTypes["RedemptionPdfDocAccessFields_width_Update"],
	delete?:ResolverInputTypes["RedemptionPdfDocAccessFields_width_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["RedemptionPdfDocAccessFields_width_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionPdfDocAccessFields_width_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionPdfDocAccessFields_width_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionPdfDocAccessFields_width_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionPdfDocAccessFields_height"]: AliasType<{
	create?:ResolverInputTypes["RedemptionPdfDocAccessFields_height_Create"],
	read?:ResolverInputTypes["RedemptionPdfDocAccessFields_height_Read"],
	update?:ResolverInputTypes["RedemptionPdfDocAccessFields_height_Update"],
	delete?:ResolverInputTypes["RedemptionPdfDocAccessFields_height_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["RedemptionPdfDocAccessFields_height_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionPdfDocAccessFields_height_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionPdfDocAccessFields_height_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionPdfDocAccessFields_height_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionPdfDocAccessFields_focalX"]: AliasType<{
	create?:ResolverInputTypes["RedemptionPdfDocAccessFields_focalX_Create"],
	read?:ResolverInputTypes["RedemptionPdfDocAccessFields_focalX_Read"],
	update?:ResolverInputTypes["RedemptionPdfDocAccessFields_focalX_Update"],
	delete?:ResolverInputTypes["RedemptionPdfDocAccessFields_focalX_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["RedemptionPdfDocAccessFields_focalX_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionPdfDocAccessFields_focalX_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionPdfDocAccessFields_focalX_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionPdfDocAccessFields_focalX_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionPdfDocAccessFields_focalY"]: AliasType<{
	create?:ResolverInputTypes["RedemptionPdfDocAccessFields_focalY_Create"],
	read?:ResolverInputTypes["RedemptionPdfDocAccessFields_focalY_Read"],
	update?:ResolverInputTypes["RedemptionPdfDocAccessFields_focalY_Update"],
	delete?:ResolverInputTypes["RedemptionPdfDocAccessFields_focalY_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["RedemptionPdfDocAccessFields_focalY_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionPdfDocAccessFields_focalY_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionPdfDocAccessFields_focalY_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionPdfDocAccessFields_focalY_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionPdfCreateDocAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionPdfReadDocAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionPdfUpdateDocAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionPdfDeleteDocAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemption"]: AliasType<{
	id?:boolean | `@${string}`,
creator?: [{	locale?: ResolverInputTypes["LocaleInputType"] | undefined | null,	fallbackLocale?: ResolverInputTypes["FallbackLocaleInputType"] | undefined | null},ResolverInputTypes["User"]],
	qrCodeId?:boolean | `@${string}`,
	codeownerName?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	phone?:boolean | `@${string}`,
linkedEvent?: [{	locale?: ResolverInputTypes["LocaleInputType"] | undefined | null,	fallbackLocale?: ResolverInputTypes["FallbackLocaleInputType"] | undefined | null},ResolverInputTypes["Event"]],
	entryNumber?:boolean | `@${string}`,
	scanAt?:boolean | `@${string}`,
	uploadAt?:boolean | `@${string}`,
	status?:boolean | `@${string}`,
	failReason?:boolean | `@${string}`,
	variants?:ResolverInputTypes["CheckInRedemption_Variants"],
	checkInAppSelectionId?:boolean | `@${string}`,
	qrCodeStatus?:boolean | `@${string}`,
	productType?:boolean | `@${string}`,
	updatedAt?:boolean | `@${string}`,
	createdAt?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemption_Variants"]: AliasType<{
	variantId?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	quantity?:boolean | `@${string}`,
	totalCost?:boolean | `@${string}`,
	id?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemption_productType"]:CheckInRedemption_productType;
	["CheckInRedemptions"]: AliasType<{
	docs?:ResolverInputTypes["CheckInRedemption"],
	hasNextPage?:boolean | `@${string}`,
	hasPrevPage?:boolean | `@${string}`,
	limit?:boolean | `@${string}`,
	nextPage?:boolean | `@${string}`,
	offset?:boolean | `@${string}`,
	page?:boolean | `@${string}`,
	pagingCounter?:boolean | `@${string}`,
	prevPage?:boolean | `@${string}`,
	totalDocs?:boolean | `@${string}`,
	totalPages?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemption_where"]: {
	creator?: ResolverInputTypes["CheckInRedemption_creator_operator"] | undefined | null,
	qrCodeId?: ResolverInputTypes["CheckInRedemption_qrCodeId_operator"] | undefined | null,
	codeownerName?: ResolverInputTypes["CheckInRedemption_codeownerName_operator"] | undefined | null,
	name?: ResolverInputTypes["CheckInRedemption_name_operator"] | undefined | null,
	phone?: ResolverInputTypes["CheckInRedemption_phone_operator"] | undefined | null,
	linkedEvent?: ResolverInputTypes["CheckInRedemption_linkedEvent_operator"] | undefined | null,
	entryNumber?: ResolverInputTypes["CheckInRedemption_entryNumber_operator"] | undefined | null,
	scanAt?: ResolverInputTypes["CheckInRedemption_scanAt_operator"] | undefined | null,
	uploadAt?: ResolverInputTypes["CheckInRedemption_uploadAt_operator"] | undefined | null,
	status?: ResolverInputTypes["CheckInRedemption_status_operator"] | undefined | null,
	failReason?: ResolverInputTypes["CheckInRedemption_failReason_operator"] | undefined | null,
	variants__variantId?: ResolverInputTypes["CheckInRedemption_variants__variantId_operator"] | undefined | null,
	variants__name?: ResolverInputTypes["CheckInRedemption_variants__name_operator"] | undefined | null,
	variants__quantity?: ResolverInputTypes["CheckInRedemption_variants__quantity_operator"] | undefined | null,
	variants__totalCost?: ResolverInputTypes["CheckInRedemption_variants__totalCost_operator"] | undefined | null,
	variants__id?: ResolverInputTypes["CheckInRedemption_variants__id_operator"] | undefined | null,
	checkInAppSelectionId?: ResolverInputTypes["CheckInRedemption_checkInAppSelectionId_operator"] | undefined | null,
	qrCodeStatus?: ResolverInputTypes["CheckInRedemption_qrCodeStatus_operator"] | undefined | null,
	productType?: ResolverInputTypes["CheckInRedemption_productType_operator"] | undefined | null,
	updatedAt?: ResolverInputTypes["CheckInRedemption_updatedAt_operator"] | undefined | null,
	createdAt?: ResolverInputTypes["CheckInRedemption_createdAt_operator"] | undefined | null,
	id?: ResolverInputTypes["CheckInRedemption_id_operator"] | undefined | null,
	AND?: Array<ResolverInputTypes["CheckInRedemption_where_and"] | undefined | null> | undefined | null,
	OR?: Array<ResolverInputTypes["CheckInRedemption_where_or"] | undefined | null> | undefined | null
};
	["CheckInRedemption_creator_operator"]: {
	equals?: ResolverInputTypes["JSON"] | undefined | null,
	not_equals?: ResolverInputTypes["JSON"] | undefined | null,
	in?: Array<ResolverInputTypes["JSON"] | undefined | null> | undefined | null,
	not_in?: Array<ResolverInputTypes["JSON"] | undefined | null> | undefined | null,
	all?: Array<ResolverInputTypes["JSON"] | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["CheckInRedemption_qrCodeId_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["CheckInRedemption_codeownerName_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["CheckInRedemption_name_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["CheckInRedemption_phone_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["CheckInRedemption_linkedEvent_operator"]: {
	equals?: ResolverInputTypes["JSON"] | undefined | null,
	not_equals?: ResolverInputTypes["JSON"] | undefined | null,
	in?: Array<ResolverInputTypes["JSON"] | undefined | null> | undefined | null,
	not_in?: Array<ResolverInputTypes["JSON"] | undefined | null> | undefined | null,
	all?: Array<ResolverInputTypes["JSON"] | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["CheckInRedemption_entryNumber_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["CheckInRedemption_scanAt_operator"]: {
	equals?: ResolverInputTypes["DateTime"] | undefined | null,
	not_equals?: ResolverInputTypes["DateTime"] | undefined | null,
	greater_than_equal?: ResolverInputTypes["DateTime"] | undefined | null,
	greater_than?: ResolverInputTypes["DateTime"] | undefined | null,
	less_than_equal?: ResolverInputTypes["DateTime"] | undefined | null,
	less_than?: ResolverInputTypes["DateTime"] | undefined | null,
	like?: ResolverInputTypes["DateTime"] | undefined | null,
	exists?: boolean | undefined | null
};
	["CheckInRedemption_uploadAt_operator"]: {
	equals?: ResolverInputTypes["DateTime"] | undefined | null,
	not_equals?: ResolverInputTypes["DateTime"] | undefined | null,
	greater_than_equal?: ResolverInputTypes["DateTime"] | undefined | null,
	greater_than?: ResolverInputTypes["DateTime"] | undefined | null,
	less_than_equal?: ResolverInputTypes["DateTime"] | undefined | null,
	less_than?: ResolverInputTypes["DateTime"] | undefined | null,
	like?: ResolverInputTypes["DateTime"] | undefined | null,
	exists?: boolean | undefined | null
};
	["CheckInRedemption_status_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["CheckInRedemption_failReason_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["CheckInRedemption_variants__variantId_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["CheckInRedemption_variants__name_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["CheckInRedemption_variants__quantity_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["CheckInRedemption_variants__totalCost_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["CheckInRedemption_variants__id_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["CheckInRedemption_checkInAppSelectionId_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["CheckInRedemption_qrCodeStatus_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["CheckInRedemption_productType_operator"]: {
	equals?: ResolverInputTypes["CheckInRedemption_productType_Input"] | undefined | null,
	not_equals?: ResolverInputTypes["CheckInRedemption_productType_Input"] | undefined | null,
	like?: ResolverInputTypes["CheckInRedemption_productType_Input"] | undefined | null,
	contains?: ResolverInputTypes["CheckInRedemption_productType_Input"] | undefined | null,
	exists?: boolean | undefined | null
};
	["CheckInRedemption_productType_Input"]:CheckInRedemption_productType_Input;
	["CheckInRedemption_updatedAt_operator"]: {
	equals?: ResolverInputTypes["DateTime"] | undefined | null,
	not_equals?: ResolverInputTypes["DateTime"] | undefined | null,
	greater_than_equal?: ResolverInputTypes["DateTime"] | undefined | null,
	greater_than?: ResolverInputTypes["DateTime"] | undefined | null,
	less_than_equal?: ResolverInputTypes["DateTime"] | undefined | null,
	less_than?: ResolverInputTypes["DateTime"] | undefined | null,
	like?: ResolverInputTypes["DateTime"] | undefined | null,
	exists?: boolean | undefined | null
};
	["CheckInRedemption_createdAt_operator"]: {
	equals?: ResolverInputTypes["DateTime"] | undefined | null,
	not_equals?: ResolverInputTypes["DateTime"] | undefined | null,
	greater_than_equal?: ResolverInputTypes["DateTime"] | undefined | null,
	greater_than?: ResolverInputTypes["DateTime"] | undefined | null,
	less_than_equal?: ResolverInputTypes["DateTime"] | undefined | null,
	less_than?: ResolverInputTypes["DateTime"] | undefined | null,
	like?: ResolverInputTypes["DateTime"] | undefined | null,
	exists?: boolean | undefined | null
};
	["CheckInRedemption_id_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["CheckInRedemption_where_and"]: {
	creator?: ResolverInputTypes["CheckInRedemption_creator_operator"] | undefined | null,
	qrCodeId?: ResolverInputTypes["CheckInRedemption_qrCodeId_operator"] | undefined | null,
	codeownerName?: ResolverInputTypes["CheckInRedemption_codeownerName_operator"] | undefined | null,
	name?: ResolverInputTypes["CheckInRedemption_name_operator"] | undefined | null,
	phone?: ResolverInputTypes["CheckInRedemption_phone_operator"] | undefined | null,
	linkedEvent?: ResolverInputTypes["CheckInRedemption_linkedEvent_operator"] | undefined | null,
	entryNumber?: ResolverInputTypes["CheckInRedemption_entryNumber_operator"] | undefined | null,
	scanAt?: ResolverInputTypes["CheckInRedemption_scanAt_operator"] | undefined | null,
	uploadAt?: ResolverInputTypes["CheckInRedemption_uploadAt_operator"] | undefined | null,
	status?: ResolverInputTypes["CheckInRedemption_status_operator"] | undefined | null,
	failReason?: ResolverInputTypes["CheckInRedemption_failReason_operator"] | undefined | null,
	variants__variantId?: ResolverInputTypes["CheckInRedemption_variants__variantId_operator"] | undefined | null,
	variants__name?: ResolverInputTypes["CheckInRedemption_variants__name_operator"] | undefined | null,
	variants__quantity?: ResolverInputTypes["CheckInRedemption_variants__quantity_operator"] | undefined | null,
	variants__totalCost?: ResolverInputTypes["CheckInRedemption_variants__totalCost_operator"] | undefined | null,
	variants__id?: ResolverInputTypes["CheckInRedemption_variants__id_operator"] | undefined | null,
	checkInAppSelectionId?: ResolverInputTypes["CheckInRedemption_checkInAppSelectionId_operator"] | undefined | null,
	qrCodeStatus?: ResolverInputTypes["CheckInRedemption_qrCodeStatus_operator"] | undefined | null,
	productType?: ResolverInputTypes["CheckInRedemption_productType_operator"] | undefined | null,
	updatedAt?: ResolverInputTypes["CheckInRedemption_updatedAt_operator"] | undefined | null,
	createdAt?: ResolverInputTypes["CheckInRedemption_createdAt_operator"] | undefined | null,
	id?: ResolverInputTypes["CheckInRedemption_id_operator"] | undefined | null,
	AND?: Array<ResolverInputTypes["CheckInRedemption_where_and"] | undefined | null> | undefined | null,
	OR?: Array<ResolverInputTypes["CheckInRedemption_where_or"] | undefined | null> | undefined | null
};
	["CheckInRedemption_where_or"]: {
	creator?: ResolverInputTypes["CheckInRedemption_creator_operator"] | undefined | null,
	qrCodeId?: ResolverInputTypes["CheckInRedemption_qrCodeId_operator"] | undefined | null,
	codeownerName?: ResolverInputTypes["CheckInRedemption_codeownerName_operator"] | undefined | null,
	name?: ResolverInputTypes["CheckInRedemption_name_operator"] | undefined | null,
	phone?: ResolverInputTypes["CheckInRedemption_phone_operator"] | undefined | null,
	linkedEvent?: ResolverInputTypes["CheckInRedemption_linkedEvent_operator"] | undefined | null,
	entryNumber?: ResolverInputTypes["CheckInRedemption_entryNumber_operator"] | undefined | null,
	scanAt?: ResolverInputTypes["CheckInRedemption_scanAt_operator"] | undefined | null,
	uploadAt?: ResolverInputTypes["CheckInRedemption_uploadAt_operator"] | undefined | null,
	status?: ResolverInputTypes["CheckInRedemption_status_operator"] | undefined | null,
	failReason?: ResolverInputTypes["CheckInRedemption_failReason_operator"] | undefined | null,
	variants__variantId?: ResolverInputTypes["CheckInRedemption_variants__variantId_operator"] | undefined | null,
	variants__name?: ResolverInputTypes["CheckInRedemption_variants__name_operator"] | undefined | null,
	variants__quantity?: ResolverInputTypes["CheckInRedemption_variants__quantity_operator"] | undefined | null,
	variants__totalCost?: ResolverInputTypes["CheckInRedemption_variants__totalCost_operator"] | undefined | null,
	variants__id?: ResolverInputTypes["CheckInRedemption_variants__id_operator"] | undefined | null,
	checkInAppSelectionId?: ResolverInputTypes["CheckInRedemption_checkInAppSelectionId_operator"] | undefined | null,
	qrCodeStatus?: ResolverInputTypes["CheckInRedemption_qrCodeStatus_operator"] | undefined | null,
	productType?: ResolverInputTypes["CheckInRedemption_productType_operator"] | undefined | null,
	updatedAt?: ResolverInputTypes["CheckInRedemption_updatedAt_operator"] | undefined | null,
	createdAt?: ResolverInputTypes["CheckInRedemption_createdAt_operator"] | undefined | null,
	id?: ResolverInputTypes["CheckInRedemption_id_operator"] | undefined | null,
	AND?: Array<ResolverInputTypes["CheckInRedemption_where_and"] | undefined | null> | undefined | null,
	OR?: Array<ResolverInputTypes["CheckInRedemption_where_or"] | undefined | null> | undefined | null
};
	["countCheckInRedemptions"]: AliasType<{
	totalDocs?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["check_in_redemptionDocAccess"]: AliasType<{
	fields?:ResolverInputTypes["CheckInRedemptionDocAccessFields"],
	create?:ResolverInputTypes["CheckInRedemptionCreateDocAccess"],
	read?:ResolverInputTypes["CheckInRedemptionReadDocAccess"],
	update?:ResolverInputTypes["CheckInRedemptionUpdateDocAccess"],
	delete?:ResolverInputTypes["CheckInRedemptionDeleteDocAccess"],
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionDocAccessFields"]: AliasType<{
	creator?:ResolverInputTypes["CheckInRedemptionDocAccessFields_creator"],
	qrCodeId?:ResolverInputTypes["CheckInRedemptionDocAccessFields_qrCodeId"],
	codeownerName?:ResolverInputTypes["CheckInRedemptionDocAccessFields_codeownerName"],
	name?:ResolverInputTypes["CheckInRedemptionDocAccessFields_name"],
	phone?:ResolverInputTypes["CheckInRedemptionDocAccessFields_phone"],
	linkedEvent?:ResolverInputTypes["CheckInRedemptionDocAccessFields_linkedEvent"],
	entryNumber?:ResolverInputTypes["CheckInRedemptionDocAccessFields_entryNumber"],
	scanAt?:ResolverInputTypes["CheckInRedemptionDocAccessFields_scanAt"],
	uploadAt?:ResolverInputTypes["CheckInRedemptionDocAccessFields_uploadAt"],
	status?:ResolverInputTypes["CheckInRedemptionDocAccessFields_status"],
	failReason?:ResolverInputTypes["CheckInRedemptionDocAccessFields_failReason"],
	variants?:ResolverInputTypes["CheckInRedemptionDocAccessFields_variants"],
	checkInAppSelectionId?:ResolverInputTypes["CheckInRedemptionDocAccessFields_checkInAppSelectionId"],
	qrCodeStatus?:ResolverInputTypes["CheckInRedemptionDocAccessFields_qrCodeStatus"],
	productType?:ResolverInputTypes["CheckInRedemptionDocAccessFields_productType"],
	updatedAt?:ResolverInputTypes["CheckInRedemptionDocAccessFields_updatedAt"],
	createdAt?:ResolverInputTypes["CheckInRedemptionDocAccessFields_createdAt"],
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionDocAccessFields_creator"]: AliasType<{
	create?:ResolverInputTypes["CheckInRedemptionDocAccessFields_creator_Create"],
	read?:ResolverInputTypes["CheckInRedemptionDocAccessFields_creator_Read"],
	update?:ResolverInputTypes["CheckInRedemptionDocAccessFields_creator_Update"],
	delete?:ResolverInputTypes["CheckInRedemptionDocAccessFields_creator_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionDocAccessFields_creator_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionDocAccessFields_creator_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionDocAccessFields_creator_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionDocAccessFields_creator_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionDocAccessFields_qrCodeId"]: AliasType<{
	create?:ResolverInputTypes["CheckInRedemptionDocAccessFields_qrCodeId_Create"],
	read?:ResolverInputTypes["CheckInRedemptionDocAccessFields_qrCodeId_Read"],
	update?:ResolverInputTypes["CheckInRedemptionDocAccessFields_qrCodeId_Update"],
	delete?:ResolverInputTypes["CheckInRedemptionDocAccessFields_qrCodeId_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionDocAccessFields_qrCodeId_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionDocAccessFields_qrCodeId_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionDocAccessFields_qrCodeId_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionDocAccessFields_qrCodeId_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionDocAccessFields_codeownerName"]: AliasType<{
	create?:ResolverInputTypes["CheckInRedemptionDocAccessFields_codeownerName_Create"],
	read?:ResolverInputTypes["CheckInRedemptionDocAccessFields_codeownerName_Read"],
	update?:ResolverInputTypes["CheckInRedemptionDocAccessFields_codeownerName_Update"],
	delete?:ResolverInputTypes["CheckInRedemptionDocAccessFields_codeownerName_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionDocAccessFields_codeownerName_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionDocAccessFields_codeownerName_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionDocAccessFields_codeownerName_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionDocAccessFields_codeownerName_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionDocAccessFields_name"]: AliasType<{
	create?:ResolverInputTypes["CheckInRedemptionDocAccessFields_name_Create"],
	read?:ResolverInputTypes["CheckInRedemptionDocAccessFields_name_Read"],
	update?:ResolverInputTypes["CheckInRedemptionDocAccessFields_name_Update"],
	delete?:ResolverInputTypes["CheckInRedemptionDocAccessFields_name_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionDocAccessFields_name_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionDocAccessFields_name_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionDocAccessFields_name_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionDocAccessFields_name_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionDocAccessFields_phone"]: AliasType<{
	create?:ResolverInputTypes["CheckInRedemptionDocAccessFields_phone_Create"],
	read?:ResolverInputTypes["CheckInRedemptionDocAccessFields_phone_Read"],
	update?:ResolverInputTypes["CheckInRedemptionDocAccessFields_phone_Update"],
	delete?:ResolverInputTypes["CheckInRedemptionDocAccessFields_phone_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionDocAccessFields_phone_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionDocAccessFields_phone_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionDocAccessFields_phone_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionDocAccessFields_phone_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionDocAccessFields_linkedEvent"]: AliasType<{
	create?:ResolverInputTypes["CheckInRedemptionDocAccessFields_linkedEvent_Create"],
	read?:ResolverInputTypes["CheckInRedemptionDocAccessFields_linkedEvent_Read"],
	update?:ResolverInputTypes["CheckInRedemptionDocAccessFields_linkedEvent_Update"],
	delete?:ResolverInputTypes["CheckInRedemptionDocAccessFields_linkedEvent_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionDocAccessFields_linkedEvent_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionDocAccessFields_linkedEvent_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionDocAccessFields_linkedEvent_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionDocAccessFields_linkedEvent_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionDocAccessFields_entryNumber"]: AliasType<{
	create?:ResolverInputTypes["CheckInRedemptionDocAccessFields_entryNumber_Create"],
	read?:ResolverInputTypes["CheckInRedemptionDocAccessFields_entryNumber_Read"],
	update?:ResolverInputTypes["CheckInRedemptionDocAccessFields_entryNumber_Update"],
	delete?:ResolverInputTypes["CheckInRedemptionDocAccessFields_entryNumber_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionDocAccessFields_entryNumber_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionDocAccessFields_entryNumber_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionDocAccessFields_entryNumber_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionDocAccessFields_entryNumber_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionDocAccessFields_scanAt"]: AliasType<{
	create?:ResolverInputTypes["CheckInRedemptionDocAccessFields_scanAt_Create"],
	read?:ResolverInputTypes["CheckInRedemptionDocAccessFields_scanAt_Read"],
	update?:ResolverInputTypes["CheckInRedemptionDocAccessFields_scanAt_Update"],
	delete?:ResolverInputTypes["CheckInRedemptionDocAccessFields_scanAt_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionDocAccessFields_scanAt_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionDocAccessFields_scanAt_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionDocAccessFields_scanAt_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionDocAccessFields_scanAt_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionDocAccessFields_uploadAt"]: AliasType<{
	create?:ResolverInputTypes["CheckInRedemptionDocAccessFields_uploadAt_Create"],
	read?:ResolverInputTypes["CheckInRedemptionDocAccessFields_uploadAt_Read"],
	update?:ResolverInputTypes["CheckInRedemptionDocAccessFields_uploadAt_Update"],
	delete?:ResolverInputTypes["CheckInRedemptionDocAccessFields_uploadAt_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionDocAccessFields_uploadAt_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionDocAccessFields_uploadAt_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionDocAccessFields_uploadAt_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionDocAccessFields_uploadAt_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionDocAccessFields_status"]: AliasType<{
	create?:ResolverInputTypes["CheckInRedemptionDocAccessFields_status_Create"],
	read?:ResolverInputTypes["CheckInRedemptionDocAccessFields_status_Read"],
	update?:ResolverInputTypes["CheckInRedemptionDocAccessFields_status_Update"],
	delete?:ResolverInputTypes["CheckInRedemptionDocAccessFields_status_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionDocAccessFields_status_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionDocAccessFields_status_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionDocAccessFields_status_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionDocAccessFields_status_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionDocAccessFields_failReason"]: AliasType<{
	create?:ResolverInputTypes["CheckInRedemptionDocAccessFields_failReason_Create"],
	read?:ResolverInputTypes["CheckInRedemptionDocAccessFields_failReason_Read"],
	update?:ResolverInputTypes["CheckInRedemptionDocAccessFields_failReason_Update"],
	delete?:ResolverInputTypes["CheckInRedemptionDocAccessFields_failReason_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionDocAccessFields_failReason_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionDocAccessFields_failReason_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionDocAccessFields_failReason_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionDocAccessFields_failReason_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionDocAccessFields_variants"]: AliasType<{
	create?:ResolverInputTypes["CheckInRedemptionDocAccessFields_variants_Create"],
	read?:ResolverInputTypes["CheckInRedemptionDocAccessFields_variants_Read"],
	update?:ResolverInputTypes["CheckInRedemptionDocAccessFields_variants_Update"],
	delete?:ResolverInputTypes["CheckInRedemptionDocAccessFields_variants_Delete"],
	fields?:ResolverInputTypes["CheckInRedemptionDocAccessFields_variants_Fields"],
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionDocAccessFields_variants_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionDocAccessFields_variants_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionDocAccessFields_variants_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionDocAccessFields_variants_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionDocAccessFields_variants_Fields"]: AliasType<{
	variantId?:ResolverInputTypes["CheckInRedemptionDocAccessFields_variants_variantId"],
	name?:ResolverInputTypes["CheckInRedemptionDocAccessFields_variants_name"],
	quantity?:ResolverInputTypes["CheckInRedemptionDocAccessFields_variants_quantity"],
	totalCost?:ResolverInputTypes["CheckInRedemptionDocAccessFields_variants_totalCost"],
	id?:ResolverInputTypes["CheckInRedemptionDocAccessFields_variants_id"],
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionDocAccessFields_variants_variantId"]: AliasType<{
	create?:ResolverInputTypes["CheckInRedemptionDocAccessFields_variants_variantId_Create"],
	read?:ResolverInputTypes["CheckInRedemptionDocAccessFields_variants_variantId_Read"],
	update?:ResolverInputTypes["CheckInRedemptionDocAccessFields_variants_variantId_Update"],
	delete?:ResolverInputTypes["CheckInRedemptionDocAccessFields_variants_variantId_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionDocAccessFields_variants_variantId_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionDocAccessFields_variants_variantId_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionDocAccessFields_variants_variantId_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionDocAccessFields_variants_variantId_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionDocAccessFields_variants_name"]: AliasType<{
	create?:ResolverInputTypes["CheckInRedemptionDocAccessFields_variants_name_Create"],
	read?:ResolverInputTypes["CheckInRedemptionDocAccessFields_variants_name_Read"],
	update?:ResolverInputTypes["CheckInRedemptionDocAccessFields_variants_name_Update"],
	delete?:ResolverInputTypes["CheckInRedemptionDocAccessFields_variants_name_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionDocAccessFields_variants_name_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionDocAccessFields_variants_name_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionDocAccessFields_variants_name_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionDocAccessFields_variants_name_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionDocAccessFields_variants_quantity"]: AliasType<{
	create?:ResolverInputTypes["CheckInRedemptionDocAccessFields_variants_quantity_Create"],
	read?:ResolverInputTypes["CheckInRedemptionDocAccessFields_variants_quantity_Read"],
	update?:ResolverInputTypes["CheckInRedemptionDocAccessFields_variants_quantity_Update"],
	delete?:ResolverInputTypes["CheckInRedemptionDocAccessFields_variants_quantity_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionDocAccessFields_variants_quantity_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionDocAccessFields_variants_quantity_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionDocAccessFields_variants_quantity_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionDocAccessFields_variants_quantity_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionDocAccessFields_variants_totalCost"]: AliasType<{
	create?:ResolverInputTypes["CheckInRedemptionDocAccessFields_variants_totalCost_Create"],
	read?:ResolverInputTypes["CheckInRedemptionDocAccessFields_variants_totalCost_Read"],
	update?:ResolverInputTypes["CheckInRedemptionDocAccessFields_variants_totalCost_Update"],
	delete?:ResolverInputTypes["CheckInRedemptionDocAccessFields_variants_totalCost_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionDocAccessFields_variants_totalCost_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionDocAccessFields_variants_totalCost_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionDocAccessFields_variants_totalCost_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionDocAccessFields_variants_totalCost_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionDocAccessFields_variants_id"]: AliasType<{
	create?:ResolverInputTypes["CheckInRedemptionDocAccessFields_variants_id_Create"],
	read?:ResolverInputTypes["CheckInRedemptionDocAccessFields_variants_id_Read"],
	update?:ResolverInputTypes["CheckInRedemptionDocAccessFields_variants_id_Update"],
	delete?:ResolverInputTypes["CheckInRedemptionDocAccessFields_variants_id_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionDocAccessFields_variants_id_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionDocAccessFields_variants_id_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionDocAccessFields_variants_id_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionDocAccessFields_variants_id_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionDocAccessFields_checkInAppSelectionId"]: AliasType<{
	create?:ResolverInputTypes["CheckInRedemptionDocAccessFields_checkInAppSelectionId_Create"],
	read?:ResolverInputTypes["CheckInRedemptionDocAccessFields_checkInAppSelectionId_Read"],
	update?:ResolverInputTypes["CheckInRedemptionDocAccessFields_checkInAppSelectionId_Update"],
	delete?:ResolverInputTypes["CheckInRedemptionDocAccessFields_checkInAppSelectionId_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionDocAccessFields_checkInAppSelectionId_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionDocAccessFields_checkInAppSelectionId_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionDocAccessFields_checkInAppSelectionId_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionDocAccessFields_checkInAppSelectionId_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionDocAccessFields_qrCodeStatus"]: AliasType<{
	create?:ResolverInputTypes["CheckInRedemptionDocAccessFields_qrCodeStatus_Create"],
	read?:ResolverInputTypes["CheckInRedemptionDocAccessFields_qrCodeStatus_Read"],
	update?:ResolverInputTypes["CheckInRedemptionDocAccessFields_qrCodeStatus_Update"],
	delete?:ResolverInputTypes["CheckInRedemptionDocAccessFields_qrCodeStatus_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionDocAccessFields_qrCodeStatus_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionDocAccessFields_qrCodeStatus_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionDocAccessFields_qrCodeStatus_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionDocAccessFields_qrCodeStatus_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionDocAccessFields_productType"]: AliasType<{
	create?:ResolverInputTypes["CheckInRedemptionDocAccessFields_productType_Create"],
	read?:ResolverInputTypes["CheckInRedemptionDocAccessFields_productType_Read"],
	update?:ResolverInputTypes["CheckInRedemptionDocAccessFields_productType_Update"],
	delete?:ResolverInputTypes["CheckInRedemptionDocAccessFields_productType_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionDocAccessFields_productType_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionDocAccessFields_productType_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionDocAccessFields_productType_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionDocAccessFields_productType_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionDocAccessFields_updatedAt"]: AliasType<{
	create?:ResolverInputTypes["CheckInRedemptionDocAccessFields_updatedAt_Create"],
	read?:ResolverInputTypes["CheckInRedemptionDocAccessFields_updatedAt_Read"],
	update?:ResolverInputTypes["CheckInRedemptionDocAccessFields_updatedAt_Update"],
	delete?:ResolverInputTypes["CheckInRedemptionDocAccessFields_updatedAt_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionDocAccessFields_updatedAt_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionDocAccessFields_updatedAt_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionDocAccessFields_updatedAt_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionDocAccessFields_updatedAt_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionDocAccessFields_createdAt"]: AliasType<{
	create?:ResolverInputTypes["CheckInRedemptionDocAccessFields_createdAt_Create"],
	read?:ResolverInputTypes["CheckInRedemptionDocAccessFields_createdAt_Read"],
	update?:ResolverInputTypes["CheckInRedemptionDocAccessFields_createdAt_Update"],
	delete?:ResolverInputTypes["CheckInRedemptionDocAccessFields_createdAt_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionDocAccessFields_createdAt_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionDocAccessFields_createdAt_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionDocAccessFields_createdAt_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionDocAccessFields_createdAt_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionCreateDocAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionReadDocAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionUpdateDocAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionDeleteDocAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["Users"]: AliasType<{
	docs?:ResolverInputTypes["User"],
	hasNextPage?:boolean | `@${string}`,
	hasPrevPage?:boolean | `@${string}`,
	limit?:boolean | `@${string}`,
	nextPage?:boolean | `@${string}`,
	offset?:boolean | `@${string}`,
	page?:boolean | `@${string}`,
	pagingCounter?:boolean | `@${string}`,
	prevPage?:boolean | `@${string}`,
	totalDocs?:boolean | `@${string}`,
	totalPages?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["User_where"]: {
	region?: ResolverInputTypes["User_region_operator"] | undefined | null,
	name?: ResolverInputTypes["User_name_operator"] | undefined | null,
	displayEmail?: ResolverInputTypes["User_displayEmail_operator"] | undefined | null,
	legacyUsername?: ResolverInputTypes["User_legacyUsername_operator"] | undefined | null,
	roles?: ResolverInputTypes["User_roles_operator"] | undefined | null,
	member__region?: ResolverInputTypes["User_member__region_operator"] | undefined | null,
	member__language?: ResolverInputTypes["User_member__language_operator"] | undefined | null,
	member__phone?: ResolverInputTypes["User_member__phone_operator"] | undefined | null,
	member__avatar?: ResolverInputTypes["User_member__avatar_operator"] | undefined | null,
	member__birthday?: ResolverInputTypes["User_member__birthday_operator"] | undefined | null,
	member__gender?: ResolverInputTypes["User_member__gender_operator"] | undefined | null,
	member__categories?: ResolverInputTypes["User_member__categories_operator"] | undefined | null,
	organiser__logo?: ResolverInputTypes["User_organiser__logo_operator"] | undefined | null,
	organiser__banner?: ResolverInputTypes["User_organiser__banner_operator"] | undefined | null,
	organiser__description?: ResolverInputTypes["User_organiser__description_operator"] | undefined | null,
	organiser__foundedIn?: ResolverInputTypes["User_organiser__foundedIn_operator"] | undefined | null,
	organiser__address?: ResolverInputTypes["User_organiser__address_operator"] | undefined | null,
	organiser__website?: ResolverInputTypes["User_organiser__website_operator"] | undefined | null,
	organiser__facebook?: ResolverInputTypes["User_organiser__facebook_operator"] | undefined | null,
	organiser__instagram?: ResolverInputTypes["User_organiser__instagram_operator"] | undefined | null,
	organiser__language?: ResolverInputTypes["User_organiser__language_operator"] | undefined | null,
	organiser__phone?: ResolverInputTypes["User_organiser__phone_operator"] | undefined | null,
	organiser__followers?: ResolverInputTypes["User_organiser__followers_operator"] | undefined | null,
	emailVerified?: ResolverInputTypes["User_emailVerified_operator"] | undefined | null,
	sso__googleId?: ResolverInputTypes["User_sso__googleId_operator"] | undefined | null,
	sso__facebookId?: ResolverInputTypes["User_sso__facebookId_operator"] | undefined | null,
	sso__appleId?: ResolverInputTypes["User_sso__appleId_operator"] | undefined | null,
	oldPassword?: ResolverInputTypes["User_oldPassword_operator"] | undefined | null,
	status?: ResolverInputTypes["User_status_operator"] | undefined | null,
	updatedAt?: ResolverInputTypes["User_updatedAt_operator"] | undefined | null,
	createdAt?: ResolverInputTypes["User_createdAt_operator"] | undefined | null,
	email?: ResolverInputTypes["User_email_operator"] | undefined | null,
	id?: ResolverInputTypes["User_id_operator"] | undefined | null,
	AND?: Array<ResolverInputTypes["User_where_and"] | undefined | null> | undefined | null,
	OR?: Array<ResolverInputTypes["User_where_or"] | undefined | null> | undefined | null
};
	["User_region_operator"]: {
	equals?: ResolverInputTypes["User_region_Input"] | undefined | null,
	not_equals?: ResolverInputTypes["User_region_Input"] | undefined | null,
	in?: Array<ResolverInputTypes["User_region_Input"] | undefined | null> | undefined | null,
	not_in?: Array<ResolverInputTypes["User_region_Input"] | undefined | null> | undefined | null,
	all?: Array<ResolverInputTypes["User_region_Input"] | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["User_region_Input"]:User_region_Input;
	["User_name_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["User_displayEmail_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["User_legacyUsername_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["User_roles_operator"]: {
	equals?: ResolverInputTypes["User_roles_Input"] | undefined | null,
	not_equals?: ResolverInputTypes["User_roles_Input"] | undefined | null,
	in?: Array<ResolverInputTypes["User_roles_Input"] | undefined | null> | undefined | null,
	not_in?: Array<ResolverInputTypes["User_roles_Input"] | undefined | null> | undefined | null,
	all?: Array<ResolverInputTypes["User_roles_Input"] | undefined | null> | undefined | null
};
	["User_roles_Input"]:User_roles_Input;
	["User_member__region_operator"]: {
	equals?: ResolverInputTypes["User_member__region_Input"] | undefined | null,
	not_equals?: ResolverInputTypes["User_member__region_Input"] | undefined | null,
	in?: Array<ResolverInputTypes["User_member__region_Input"] | undefined | null> | undefined | null,
	not_in?: Array<ResolverInputTypes["User_member__region_Input"] | undefined | null> | undefined | null,
	all?: Array<ResolverInputTypes["User_member__region_Input"] | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["User_member__region_Input"]:User_member__region_Input;
	["User_member__language_operator"]: {
	equals?: ResolverInputTypes["User_member__language_Input"] | undefined | null,
	not_equals?: ResolverInputTypes["User_member__language_Input"] | undefined | null,
	in?: Array<ResolverInputTypes["User_member__language_Input"] | undefined | null> | undefined | null,
	not_in?: Array<ResolverInputTypes["User_member__language_Input"] | undefined | null> | undefined | null,
	all?: Array<ResolverInputTypes["User_member__language_Input"] | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["User_member__language_Input"]:User_member__language_Input;
	["User_member__phone_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["User_member__avatar_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	exists?: boolean | undefined | null
};
	["User_member__birthday_operator"]: {
	equals?: ResolverInputTypes["DateTime"] | undefined | null,
	not_equals?: ResolverInputTypes["DateTime"] | undefined | null,
	greater_than_equal?: ResolverInputTypes["DateTime"] | undefined | null,
	greater_than?: ResolverInputTypes["DateTime"] | undefined | null,
	less_than_equal?: ResolverInputTypes["DateTime"] | undefined | null,
	less_than?: ResolverInputTypes["DateTime"] | undefined | null,
	like?: ResolverInputTypes["DateTime"] | undefined | null,
	exists?: boolean | undefined | null
};
	["User_member__gender_operator"]: {
	equals?: ResolverInputTypes["User_member__gender_Input"] | undefined | null,
	not_equals?: ResolverInputTypes["User_member__gender_Input"] | undefined | null,
	in?: Array<ResolverInputTypes["User_member__gender_Input"] | undefined | null> | undefined | null,
	not_in?: Array<ResolverInputTypes["User_member__gender_Input"] | undefined | null> | undefined | null,
	all?: Array<ResolverInputTypes["User_member__gender_Input"] | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["User_member__gender_Input"]:User_member__gender_Input;
	["User_member__categories_operator"]: {
	equals?: ResolverInputTypes["JSON"] | undefined | null,
	not_equals?: ResolverInputTypes["JSON"] | undefined | null,
	in?: Array<ResolverInputTypes["JSON"] | undefined | null> | undefined | null,
	not_in?: Array<ResolverInputTypes["JSON"] | undefined | null> | undefined | null,
	all?: Array<ResolverInputTypes["JSON"] | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["User_organiser__logo_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	exists?: boolean | undefined | null
};
	["User_organiser__banner_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	exists?: boolean | undefined | null
};
	["User_organiser__description_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	exists?: boolean | undefined | null
};
	["User_organiser__foundedIn_operator"]: {
	equals?: ResolverInputTypes["DateTime"] | undefined | null,
	not_equals?: ResolverInputTypes["DateTime"] | undefined | null,
	greater_than_equal?: ResolverInputTypes["DateTime"] | undefined | null,
	greater_than?: ResolverInputTypes["DateTime"] | undefined | null,
	less_than_equal?: ResolverInputTypes["DateTime"] | undefined | null,
	less_than?: ResolverInputTypes["DateTime"] | undefined | null,
	like?: ResolverInputTypes["DateTime"] | undefined | null,
	exists?: boolean | undefined | null
};
	["User_organiser__address_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["User_organiser__website_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["User_organiser__facebook_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["User_organiser__instagram_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["User_organiser__language_operator"]: {
	equals?: ResolverInputTypes["User_organiser__language_Input"] | undefined | null,
	not_equals?: ResolverInputTypes["User_organiser__language_Input"] | undefined | null,
	in?: Array<ResolverInputTypes["User_organiser__language_Input"] | undefined | null> | undefined | null,
	not_in?: Array<ResolverInputTypes["User_organiser__language_Input"] | undefined | null> | undefined | null,
	all?: Array<ResolverInputTypes["User_organiser__language_Input"] | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["User_organiser__language_Input"]:User_organiser__language_Input;
	["User_organiser__phone_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["User_organiser__followers_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["User_emailVerified_operator"]: {
	equals?: boolean | undefined | null,
	not_equals?: boolean | undefined | null,
	exists?: boolean | undefined | null
};
	["User_sso__googleId_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["User_sso__facebookId_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["User_sso__appleId_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["User_oldPassword_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["User_status_operator"]: {
	equals?: ResolverInputTypes["User_status_Input"] | undefined | null,
	not_equals?: ResolverInputTypes["User_status_Input"] | undefined | null,
	in?: Array<ResolverInputTypes["User_status_Input"] | undefined | null> | undefined | null,
	not_in?: Array<ResolverInputTypes["User_status_Input"] | undefined | null> | undefined | null,
	all?: Array<ResolverInputTypes["User_status_Input"] | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["User_status_Input"]:User_status_Input;
	["User_updatedAt_operator"]: {
	equals?: ResolverInputTypes["DateTime"] | undefined | null,
	not_equals?: ResolverInputTypes["DateTime"] | undefined | null,
	greater_than_equal?: ResolverInputTypes["DateTime"] | undefined | null,
	greater_than?: ResolverInputTypes["DateTime"] | undefined | null,
	less_than_equal?: ResolverInputTypes["DateTime"] | undefined | null,
	less_than?: ResolverInputTypes["DateTime"] | undefined | null,
	like?: ResolverInputTypes["DateTime"] | undefined | null,
	exists?: boolean | undefined | null
};
	["User_createdAt_operator"]: {
	equals?: ResolverInputTypes["DateTime"] | undefined | null,
	not_equals?: ResolverInputTypes["DateTime"] | undefined | null,
	greater_than_equal?: ResolverInputTypes["DateTime"] | undefined | null,
	greater_than?: ResolverInputTypes["DateTime"] | undefined | null,
	less_than_equal?: ResolverInputTypes["DateTime"] | undefined | null,
	less_than?: ResolverInputTypes["DateTime"] | undefined | null,
	like?: ResolverInputTypes["DateTime"] | undefined | null,
	exists?: boolean | undefined | null
};
	["User_email_operator"]: {
	equals?: ResolverInputTypes["EmailAddress"] | undefined | null,
	not_equals?: ResolverInputTypes["EmailAddress"] | undefined | null,
	like?: ResolverInputTypes["EmailAddress"] | undefined | null,
	contains?: ResolverInputTypes["EmailAddress"] | undefined | null,
	in?: Array<ResolverInputTypes["EmailAddress"] | undefined | null> | undefined | null,
	not_in?: Array<ResolverInputTypes["EmailAddress"] | undefined | null> | undefined | null,
	all?: Array<ResolverInputTypes["EmailAddress"] | undefined | null> | undefined | null
};
	["User_id_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["User_where_and"]: {
	region?: ResolverInputTypes["User_region_operator"] | undefined | null,
	name?: ResolverInputTypes["User_name_operator"] | undefined | null,
	displayEmail?: ResolverInputTypes["User_displayEmail_operator"] | undefined | null,
	legacyUsername?: ResolverInputTypes["User_legacyUsername_operator"] | undefined | null,
	roles?: ResolverInputTypes["User_roles_operator"] | undefined | null,
	member__region?: ResolverInputTypes["User_member__region_operator"] | undefined | null,
	member__language?: ResolverInputTypes["User_member__language_operator"] | undefined | null,
	member__phone?: ResolverInputTypes["User_member__phone_operator"] | undefined | null,
	member__avatar?: ResolverInputTypes["User_member__avatar_operator"] | undefined | null,
	member__birthday?: ResolverInputTypes["User_member__birthday_operator"] | undefined | null,
	member__gender?: ResolverInputTypes["User_member__gender_operator"] | undefined | null,
	member__categories?: ResolverInputTypes["User_member__categories_operator"] | undefined | null,
	organiser__logo?: ResolverInputTypes["User_organiser__logo_operator"] | undefined | null,
	organiser__banner?: ResolverInputTypes["User_organiser__banner_operator"] | undefined | null,
	organiser__description?: ResolverInputTypes["User_organiser__description_operator"] | undefined | null,
	organiser__foundedIn?: ResolverInputTypes["User_organiser__foundedIn_operator"] | undefined | null,
	organiser__address?: ResolverInputTypes["User_organiser__address_operator"] | undefined | null,
	organiser__website?: ResolverInputTypes["User_organiser__website_operator"] | undefined | null,
	organiser__facebook?: ResolverInputTypes["User_organiser__facebook_operator"] | undefined | null,
	organiser__instagram?: ResolverInputTypes["User_organiser__instagram_operator"] | undefined | null,
	organiser__language?: ResolverInputTypes["User_organiser__language_operator"] | undefined | null,
	organiser__phone?: ResolverInputTypes["User_organiser__phone_operator"] | undefined | null,
	organiser__followers?: ResolverInputTypes["User_organiser__followers_operator"] | undefined | null,
	emailVerified?: ResolverInputTypes["User_emailVerified_operator"] | undefined | null,
	sso__googleId?: ResolverInputTypes["User_sso__googleId_operator"] | undefined | null,
	sso__facebookId?: ResolverInputTypes["User_sso__facebookId_operator"] | undefined | null,
	sso__appleId?: ResolverInputTypes["User_sso__appleId_operator"] | undefined | null,
	oldPassword?: ResolverInputTypes["User_oldPassword_operator"] | undefined | null,
	status?: ResolverInputTypes["User_status_operator"] | undefined | null,
	updatedAt?: ResolverInputTypes["User_updatedAt_operator"] | undefined | null,
	createdAt?: ResolverInputTypes["User_createdAt_operator"] | undefined | null,
	email?: ResolverInputTypes["User_email_operator"] | undefined | null,
	id?: ResolverInputTypes["User_id_operator"] | undefined | null,
	AND?: Array<ResolverInputTypes["User_where_and"] | undefined | null> | undefined | null,
	OR?: Array<ResolverInputTypes["User_where_or"] | undefined | null> | undefined | null
};
	["User_where_or"]: {
	region?: ResolverInputTypes["User_region_operator"] | undefined | null,
	name?: ResolverInputTypes["User_name_operator"] | undefined | null,
	displayEmail?: ResolverInputTypes["User_displayEmail_operator"] | undefined | null,
	legacyUsername?: ResolverInputTypes["User_legacyUsername_operator"] | undefined | null,
	roles?: ResolverInputTypes["User_roles_operator"] | undefined | null,
	member__region?: ResolverInputTypes["User_member__region_operator"] | undefined | null,
	member__language?: ResolverInputTypes["User_member__language_operator"] | undefined | null,
	member__phone?: ResolverInputTypes["User_member__phone_operator"] | undefined | null,
	member__avatar?: ResolverInputTypes["User_member__avatar_operator"] | undefined | null,
	member__birthday?: ResolverInputTypes["User_member__birthday_operator"] | undefined | null,
	member__gender?: ResolverInputTypes["User_member__gender_operator"] | undefined | null,
	member__categories?: ResolverInputTypes["User_member__categories_operator"] | undefined | null,
	organiser__logo?: ResolverInputTypes["User_organiser__logo_operator"] | undefined | null,
	organiser__banner?: ResolverInputTypes["User_organiser__banner_operator"] | undefined | null,
	organiser__description?: ResolverInputTypes["User_organiser__description_operator"] | undefined | null,
	organiser__foundedIn?: ResolverInputTypes["User_organiser__foundedIn_operator"] | undefined | null,
	organiser__address?: ResolverInputTypes["User_organiser__address_operator"] | undefined | null,
	organiser__website?: ResolverInputTypes["User_organiser__website_operator"] | undefined | null,
	organiser__facebook?: ResolverInputTypes["User_organiser__facebook_operator"] | undefined | null,
	organiser__instagram?: ResolverInputTypes["User_organiser__instagram_operator"] | undefined | null,
	organiser__language?: ResolverInputTypes["User_organiser__language_operator"] | undefined | null,
	organiser__phone?: ResolverInputTypes["User_organiser__phone_operator"] | undefined | null,
	organiser__followers?: ResolverInputTypes["User_organiser__followers_operator"] | undefined | null,
	emailVerified?: ResolverInputTypes["User_emailVerified_operator"] | undefined | null,
	sso__googleId?: ResolverInputTypes["User_sso__googleId_operator"] | undefined | null,
	sso__facebookId?: ResolverInputTypes["User_sso__facebookId_operator"] | undefined | null,
	sso__appleId?: ResolverInputTypes["User_sso__appleId_operator"] | undefined | null,
	oldPassword?: ResolverInputTypes["User_oldPassword_operator"] | undefined | null,
	status?: ResolverInputTypes["User_status_operator"] | undefined | null,
	updatedAt?: ResolverInputTypes["User_updatedAt_operator"] | undefined | null,
	createdAt?: ResolverInputTypes["User_createdAt_operator"] | undefined | null,
	email?: ResolverInputTypes["User_email_operator"] | undefined | null,
	id?: ResolverInputTypes["User_id_operator"] | undefined | null,
	AND?: Array<ResolverInputTypes["User_where_and"] | undefined | null> | undefined | null,
	OR?: Array<ResolverInputTypes["User_where_or"] | undefined | null> | undefined | null
};
	["countUsers"]: AliasType<{
	totalDocs?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["usersDocAccess"]: AliasType<{
	fields?:ResolverInputTypes["UsersDocAccessFields"],
	create?:ResolverInputTypes["UsersCreateDocAccess"],
	read?:ResolverInputTypes["UsersReadDocAccess"],
	update?:ResolverInputTypes["UsersUpdateDocAccess"],
	delete?:ResolverInputTypes["UsersDeleteDocAccess"],
	unlock?:ResolverInputTypes["UsersUnlockDocAccess"],
		__typename?: boolean | `@${string}`
}>;
	["UsersDocAccessFields"]: AliasType<{
	region?:ResolverInputTypes["UsersDocAccessFields_region"],
	name?:ResolverInputTypes["UsersDocAccessFields_name"],
	displayEmail?:ResolverInputTypes["UsersDocAccessFields_displayEmail"],
	legacyUsername?:ResolverInputTypes["UsersDocAccessFields_legacyUsername"],
	roles?:ResolverInputTypes["UsersDocAccessFields_roles"],
	member?:ResolverInputTypes["UsersDocAccessFields_member"],
	organiser?:ResolverInputTypes["UsersDocAccessFields_organiser"],
	emailVerified?:ResolverInputTypes["UsersDocAccessFields_emailVerified"],
	sso?:ResolverInputTypes["UsersDocAccessFields_sso"],
	oldPassword?:ResolverInputTypes["UsersDocAccessFields_oldPassword"],
	status?:ResolverInputTypes["UsersDocAccessFields_status"],
	updatedAt?:ResolverInputTypes["UsersDocAccessFields_updatedAt"],
	createdAt?:ResolverInputTypes["UsersDocAccessFields_createdAt"],
	email?:ResolverInputTypes["UsersDocAccessFields_email"],
	password?:ResolverInputTypes["UsersDocAccessFields_password"],
		__typename?: boolean | `@${string}`
}>;
	["UsersDocAccessFields_region"]: AliasType<{
	create?:ResolverInputTypes["UsersDocAccessFields_region_Create"],
	read?:ResolverInputTypes["UsersDocAccessFields_region_Read"],
	update?:ResolverInputTypes["UsersDocAccessFields_region_Update"],
	delete?:ResolverInputTypes["UsersDocAccessFields_region_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["UsersDocAccessFields_region_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersDocAccessFields_region_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersDocAccessFields_region_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersDocAccessFields_region_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersDocAccessFields_name"]: AliasType<{
	create?:ResolverInputTypes["UsersDocAccessFields_name_Create"],
	read?:ResolverInputTypes["UsersDocAccessFields_name_Read"],
	update?:ResolverInputTypes["UsersDocAccessFields_name_Update"],
	delete?:ResolverInputTypes["UsersDocAccessFields_name_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["UsersDocAccessFields_name_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersDocAccessFields_name_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersDocAccessFields_name_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersDocAccessFields_name_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersDocAccessFields_displayEmail"]: AliasType<{
	create?:ResolverInputTypes["UsersDocAccessFields_displayEmail_Create"],
	read?:ResolverInputTypes["UsersDocAccessFields_displayEmail_Read"],
	update?:ResolverInputTypes["UsersDocAccessFields_displayEmail_Update"],
	delete?:ResolverInputTypes["UsersDocAccessFields_displayEmail_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["UsersDocAccessFields_displayEmail_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersDocAccessFields_displayEmail_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersDocAccessFields_displayEmail_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersDocAccessFields_displayEmail_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersDocAccessFields_legacyUsername"]: AliasType<{
	create?:ResolverInputTypes["UsersDocAccessFields_legacyUsername_Create"],
	read?:ResolverInputTypes["UsersDocAccessFields_legacyUsername_Read"],
	update?:ResolverInputTypes["UsersDocAccessFields_legacyUsername_Update"],
	delete?:ResolverInputTypes["UsersDocAccessFields_legacyUsername_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["UsersDocAccessFields_legacyUsername_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersDocAccessFields_legacyUsername_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersDocAccessFields_legacyUsername_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersDocAccessFields_legacyUsername_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersDocAccessFields_roles"]: AliasType<{
	create?:ResolverInputTypes["UsersDocAccessFields_roles_Create"],
	read?:ResolverInputTypes["UsersDocAccessFields_roles_Read"],
	update?:ResolverInputTypes["UsersDocAccessFields_roles_Update"],
	delete?:ResolverInputTypes["UsersDocAccessFields_roles_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["UsersDocAccessFields_roles_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersDocAccessFields_roles_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersDocAccessFields_roles_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersDocAccessFields_roles_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersDocAccessFields_member"]: AliasType<{
	create?:ResolverInputTypes["UsersDocAccessFields_member_Create"],
	read?:ResolverInputTypes["UsersDocAccessFields_member_Read"],
	update?:ResolverInputTypes["UsersDocAccessFields_member_Update"],
	delete?:ResolverInputTypes["UsersDocAccessFields_member_Delete"],
	fields?:ResolverInputTypes["UsersDocAccessFields_member_Fields"],
		__typename?: boolean | `@${string}`
}>;
	["UsersDocAccessFields_member_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersDocAccessFields_member_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersDocAccessFields_member_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersDocAccessFields_member_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersDocAccessFields_member_Fields"]: AliasType<{
	region?:ResolverInputTypes["UsersDocAccessFields_member_region"],
	language?:ResolverInputTypes["UsersDocAccessFields_member_language"],
	phone?:ResolverInputTypes["UsersDocAccessFields_member_phone"],
	avatar?:ResolverInputTypes["UsersDocAccessFields_member_avatar"],
	birthday?:ResolverInputTypes["UsersDocAccessFields_member_birthday"],
	gender?:ResolverInputTypes["UsersDocAccessFields_member_gender"],
	categories?:ResolverInputTypes["UsersDocAccessFields_member_categories"],
		__typename?: boolean | `@${string}`
}>;
	["UsersDocAccessFields_member_region"]: AliasType<{
	create?:ResolverInputTypes["UsersDocAccessFields_member_region_Create"],
	read?:ResolverInputTypes["UsersDocAccessFields_member_region_Read"],
	update?:ResolverInputTypes["UsersDocAccessFields_member_region_Update"],
	delete?:ResolverInputTypes["UsersDocAccessFields_member_region_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["UsersDocAccessFields_member_region_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersDocAccessFields_member_region_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersDocAccessFields_member_region_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersDocAccessFields_member_region_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersDocAccessFields_member_language"]: AliasType<{
	create?:ResolverInputTypes["UsersDocAccessFields_member_language_Create"],
	read?:ResolverInputTypes["UsersDocAccessFields_member_language_Read"],
	update?:ResolverInputTypes["UsersDocAccessFields_member_language_Update"],
	delete?:ResolverInputTypes["UsersDocAccessFields_member_language_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["UsersDocAccessFields_member_language_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersDocAccessFields_member_language_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersDocAccessFields_member_language_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersDocAccessFields_member_language_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersDocAccessFields_member_phone"]: AliasType<{
	create?:ResolverInputTypes["UsersDocAccessFields_member_phone_Create"],
	read?:ResolverInputTypes["UsersDocAccessFields_member_phone_Read"],
	update?:ResolverInputTypes["UsersDocAccessFields_member_phone_Update"],
	delete?:ResolverInputTypes["UsersDocAccessFields_member_phone_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["UsersDocAccessFields_member_phone_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersDocAccessFields_member_phone_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersDocAccessFields_member_phone_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersDocAccessFields_member_phone_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersDocAccessFields_member_avatar"]: AliasType<{
	create?:ResolverInputTypes["UsersDocAccessFields_member_avatar_Create"],
	read?:ResolverInputTypes["UsersDocAccessFields_member_avatar_Read"],
	update?:ResolverInputTypes["UsersDocAccessFields_member_avatar_Update"],
	delete?:ResolverInputTypes["UsersDocAccessFields_member_avatar_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["UsersDocAccessFields_member_avatar_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersDocAccessFields_member_avatar_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersDocAccessFields_member_avatar_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersDocAccessFields_member_avatar_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersDocAccessFields_member_birthday"]: AliasType<{
	create?:ResolverInputTypes["UsersDocAccessFields_member_birthday_Create"],
	read?:ResolverInputTypes["UsersDocAccessFields_member_birthday_Read"],
	update?:ResolverInputTypes["UsersDocAccessFields_member_birthday_Update"],
	delete?:ResolverInputTypes["UsersDocAccessFields_member_birthday_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["UsersDocAccessFields_member_birthday_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersDocAccessFields_member_birthday_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersDocAccessFields_member_birthday_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersDocAccessFields_member_birthday_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersDocAccessFields_member_gender"]: AliasType<{
	create?:ResolverInputTypes["UsersDocAccessFields_member_gender_Create"],
	read?:ResolverInputTypes["UsersDocAccessFields_member_gender_Read"],
	update?:ResolverInputTypes["UsersDocAccessFields_member_gender_Update"],
	delete?:ResolverInputTypes["UsersDocAccessFields_member_gender_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["UsersDocAccessFields_member_gender_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersDocAccessFields_member_gender_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersDocAccessFields_member_gender_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersDocAccessFields_member_gender_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersDocAccessFields_member_categories"]: AliasType<{
	create?:ResolverInputTypes["UsersDocAccessFields_member_categories_Create"],
	read?:ResolverInputTypes["UsersDocAccessFields_member_categories_Read"],
	update?:ResolverInputTypes["UsersDocAccessFields_member_categories_Update"],
	delete?:ResolverInputTypes["UsersDocAccessFields_member_categories_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["UsersDocAccessFields_member_categories_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersDocAccessFields_member_categories_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersDocAccessFields_member_categories_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersDocAccessFields_member_categories_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersDocAccessFields_organiser"]: AliasType<{
	create?:ResolverInputTypes["UsersDocAccessFields_organiser_Create"],
	read?:ResolverInputTypes["UsersDocAccessFields_organiser_Read"],
	update?:ResolverInputTypes["UsersDocAccessFields_organiser_Update"],
	delete?:ResolverInputTypes["UsersDocAccessFields_organiser_Delete"],
	fields?:ResolverInputTypes["UsersDocAccessFields_organiser_Fields"],
		__typename?: boolean | `@${string}`
}>;
	["UsersDocAccessFields_organiser_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersDocAccessFields_organiser_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersDocAccessFields_organiser_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersDocAccessFields_organiser_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersDocAccessFields_organiser_Fields"]: AliasType<{
	logo?:ResolverInputTypes["UsersDocAccessFields_organiser_logo"],
	banner?:ResolverInputTypes["UsersDocAccessFields_organiser_banner"],
	description?:ResolverInputTypes["UsersDocAccessFields_organiser_description"],
	foundedIn?:ResolverInputTypes["UsersDocAccessFields_organiser_foundedIn"],
	address?:ResolverInputTypes["UsersDocAccessFields_organiser_address"],
	website?:ResolverInputTypes["UsersDocAccessFields_organiser_website"],
	facebook?:ResolverInputTypes["UsersDocAccessFields_organiser_facebook"],
	instagram?:ResolverInputTypes["UsersDocAccessFields_organiser_instagram"],
	language?:ResolverInputTypes["UsersDocAccessFields_organiser_language"],
	phone?:ResolverInputTypes["UsersDocAccessFields_organiser_phone"],
	followers?:ResolverInputTypes["UsersDocAccessFields_organiser_followers"],
		__typename?: boolean | `@${string}`
}>;
	["UsersDocAccessFields_organiser_logo"]: AliasType<{
	create?:ResolverInputTypes["UsersDocAccessFields_organiser_logo_Create"],
	read?:ResolverInputTypes["UsersDocAccessFields_organiser_logo_Read"],
	update?:ResolverInputTypes["UsersDocAccessFields_organiser_logo_Update"],
	delete?:ResolverInputTypes["UsersDocAccessFields_organiser_logo_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["UsersDocAccessFields_organiser_logo_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersDocAccessFields_organiser_logo_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersDocAccessFields_organiser_logo_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersDocAccessFields_organiser_logo_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersDocAccessFields_organiser_banner"]: AliasType<{
	create?:ResolverInputTypes["UsersDocAccessFields_organiser_banner_Create"],
	read?:ResolverInputTypes["UsersDocAccessFields_organiser_banner_Read"],
	update?:ResolverInputTypes["UsersDocAccessFields_organiser_banner_Update"],
	delete?:ResolverInputTypes["UsersDocAccessFields_organiser_banner_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["UsersDocAccessFields_organiser_banner_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersDocAccessFields_organiser_banner_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersDocAccessFields_organiser_banner_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersDocAccessFields_organiser_banner_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersDocAccessFields_organiser_description"]: AliasType<{
	create?:ResolverInputTypes["UsersDocAccessFields_organiser_description_Create"],
	read?:ResolverInputTypes["UsersDocAccessFields_organiser_description_Read"],
	update?:ResolverInputTypes["UsersDocAccessFields_organiser_description_Update"],
	delete?:ResolverInputTypes["UsersDocAccessFields_organiser_description_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["UsersDocAccessFields_organiser_description_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersDocAccessFields_organiser_description_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersDocAccessFields_organiser_description_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersDocAccessFields_organiser_description_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersDocAccessFields_organiser_foundedIn"]: AliasType<{
	create?:ResolverInputTypes["UsersDocAccessFields_organiser_foundedIn_Create"],
	read?:ResolverInputTypes["UsersDocAccessFields_organiser_foundedIn_Read"],
	update?:ResolverInputTypes["UsersDocAccessFields_organiser_foundedIn_Update"],
	delete?:ResolverInputTypes["UsersDocAccessFields_organiser_foundedIn_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["UsersDocAccessFields_organiser_foundedIn_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersDocAccessFields_organiser_foundedIn_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersDocAccessFields_organiser_foundedIn_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersDocAccessFields_organiser_foundedIn_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersDocAccessFields_organiser_address"]: AliasType<{
	create?:ResolverInputTypes["UsersDocAccessFields_organiser_address_Create"],
	read?:ResolverInputTypes["UsersDocAccessFields_organiser_address_Read"],
	update?:ResolverInputTypes["UsersDocAccessFields_organiser_address_Update"],
	delete?:ResolverInputTypes["UsersDocAccessFields_organiser_address_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["UsersDocAccessFields_organiser_address_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersDocAccessFields_organiser_address_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersDocAccessFields_organiser_address_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersDocAccessFields_organiser_address_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersDocAccessFields_organiser_website"]: AliasType<{
	create?:ResolverInputTypes["UsersDocAccessFields_organiser_website_Create"],
	read?:ResolverInputTypes["UsersDocAccessFields_organiser_website_Read"],
	update?:ResolverInputTypes["UsersDocAccessFields_organiser_website_Update"],
	delete?:ResolverInputTypes["UsersDocAccessFields_organiser_website_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["UsersDocAccessFields_organiser_website_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersDocAccessFields_organiser_website_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersDocAccessFields_organiser_website_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersDocAccessFields_organiser_website_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersDocAccessFields_organiser_facebook"]: AliasType<{
	create?:ResolverInputTypes["UsersDocAccessFields_organiser_facebook_Create"],
	read?:ResolverInputTypes["UsersDocAccessFields_organiser_facebook_Read"],
	update?:ResolverInputTypes["UsersDocAccessFields_organiser_facebook_Update"],
	delete?:ResolverInputTypes["UsersDocAccessFields_organiser_facebook_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["UsersDocAccessFields_organiser_facebook_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersDocAccessFields_organiser_facebook_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersDocAccessFields_organiser_facebook_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersDocAccessFields_organiser_facebook_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersDocAccessFields_organiser_instagram"]: AliasType<{
	create?:ResolverInputTypes["UsersDocAccessFields_organiser_instagram_Create"],
	read?:ResolverInputTypes["UsersDocAccessFields_organiser_instagram_Read"],
	update?:ResolverInputTypes["UsersDocAccessFields_organiser_instagram_Update"],
	delete?:ResolverInputTypes["UsersDocAccessFields_organiser_instagram_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["UsersDocAccessFields_organiser_instagram_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersDocAccessFields_organiser_instagram_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersDocAccessFields_organiser_instagram_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersDocAccessFields_organiser_instagram_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersDocAccessFields_organiser_language"]: AliasType<{
	create?:ResolverInputTypes["UsersDocAccessFields_organiser_language_Create"],
	read?:ResolverInputTypes["UsersDocAccessFields_organiser_language_Read"],
	update?:ResolverInputTypes["UsersDocAccessFields_organiser_language_Update"],
	delete?:ResolverInputTypes["UsersDocAccessFields_organiser_language_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["UsersDocAccessFields_organiser_language_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersDocAccessFields_organiser_language_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersDocAccessFields_organiser_language_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersDocAccessFields_organiser_language_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersDocAccessFields_organiser_phone"]: AliasType<{
	create?:ResolverInputTypes["UsersDocAccessFields_organiser_phone_Create"],
	read?:ResolverInputTypes["UsersDocAccessFields_organiser_phone_Read"],
	update?:ResolverInputTypes["UsersDocAccessFields_organiser_phone_Update"],
	delete?:ResolverInputTypes["UsersDocAccessFields_organiser_phone_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["UsersDocAccessFields_organiser_phone_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersDocAccessFields_organiser_phone_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersDocAccessFields_organiser_phone_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersDocAccessFields_organiser_phone_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersDocAccessFields_organiser_followers"]: AliasType<{
	create?:ResolverInputTypes["UsersDocAccessFields_organiser_followers_Create"],
	read?:ResolverInputTypes["UsersDocAccessFields_organiser_followers_Read"],
	update?:ResolverInputTypes["UsersDocAccessFields_organiser_followers_Update"],
	delete?:ResolverInputTypes["UsersDocAccessFields_organiser_followers_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["UsersDocAccessFields_organiser_followers_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersDocAccessFields_organiser_followers_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersDocAccessFields_organiser_followers_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersDocAccessFields_organiser_followers_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersDocAccessFields_emailVerified"]: AliasType<{
	create?:ResolverInputTypes["UsersDocAccessFields_emailVerified_Create"],
	read?:ResolverInputTypes["UsersDocAccessFields_emailVerified_Read"],
	update?:ResolverInputTypes["UsersDocAccessFields_emailVerified_Update"],
	delete?:ResolverInputTypes["UsersDocAccessFields_emailVerified_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["UsersDocAccessFields_emailVerified_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersDocAccessFields_emailVerified_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersDocAccessFields_emailVerified_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersDocAccessFields_emailVerified_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersDocAccessFields_sso"]: AliasType<{
	create?:ResolverInputTypes["UsersDocAccessFields_sso_Create"],
	read?:ResolverInputTypes["UsersDocAccessFields_sso_Read"],
	update?:ResolverInputTypes["UsersDocAccessFields_sso_Update"],
	delete?:ResolverInputTypes["UsersDocAccessFields_sso_Delete"],
	fields?:ResolverInputTypes["UsersDocAccessFields_sso_Fields"],
		__typename?: boolean | `@${string}`
}>;
	["UsersDocAccessFields_sso_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersDocAccessFields_sso_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersDocAccessFields_sso_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersDocAccessFields_sso_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersDocAccessFields_sso_Fields"]: AliasType<{
	googleId?:ResolverInputTypes["UsersDocAccessFields_sso_googleId"],
	facebookId?:ResolverInputTypes["UsersDocAccessFields_sso_facebookId"],
	appleId?:ResolverInputTypes["UsersDocAccessFields_sso_appleId"],
		__typename?: boolean | `@${string}`
}>;
	["UsersDocAccessFields_sso_googleId"]: AliasType<{
	create?:ResolverInputTypes["UsersDocAccessFields_sso_googleId_Create"],
	read?:ResolverInputTypes["UsersDocAccessFields_sso_googleId_Read"],
	update?:ResolverInputTypes["UsersDocAccessFields_sso_googleId_Update"],
	delete?:ResolverInputTypes["UsersDocAccessFields_sso_googleId_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["UsersDocAccessFields_sso_googleId_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersDocAccessFields_sso_googleId_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersDocAccessFields_sso_googleId_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersDocAccessFields_sso_googleId_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersDocAccessFields_sso_facebookId"]: AliasType<{
	create?:ResolverInputTypes["UsersDocAccessFields_sso_facebookId_Create"],
	read?:ResolverInputTypes["UsersDocAccessFields_sso_facebookId_Read"],
	update?:ResolverInputTypes["UsersDocAccessFields_sso_facebookId_Update"],
	delete?:ResolverInputTypes["UsersDocAccessFields_sso_facebookId_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["UsersDocAccessFields_sso_facebookId_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersDocAccessFields_sso_facebookId_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersDocAccessFields_sso_facebookId_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersDocAccessFields_sso_facebookId_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersDocAccessFields_sso_appleId"]: AliasType<{
	create?:ResolverInputTypes["UsersDocAccessFields_sso_appleId_Create"],
	read?:ResolverInputTypes["UsersDocAccessFields_sso_appleId_Read"],
	update?:ResolverInputTypes["UsersDocAccessFields_sso_appleId_Update"],
	delete?:ResolverInputTypes["UsersDocAccessFields_sso_appleId_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["UsersDocAccessFields_sso_appleId_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersDocAccessFields_sso_appleId_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersDocAccessFields_sso_appleId_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersDocAccessFields_sso_appleId_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersDocAccessFields_oldPassword"]: AliasType<{
	create?:ResolverInputTypes["UsersDocAccessFields_oldPassword_Create"],
	read?:ResolverInputTypes["UsersDocAccessFields_oldPassword_Read"],
	update?:ResolverInputTypes["UsersDocAccessFields_oldPassword_Update"],
	delete?:ResolverInputTypes["UsersDocAccessFields_oldPassword_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["UsersDocAccessFields_oldPassword_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersDocAccessFields_oldPassword_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersDocAccessFields_oldPassword_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersDocAccessFields_oldPassword_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersDocAccessFields_status"]: AliasType<{
	create?:ResolverInputTypes["UsersDocAccessFields_status_Create"],
	read?:ResolverInputTypes["UsersDocAccessFields_status_Read"],
	update?:ResolverInputTypes["UsersDocAccessFields_status_Update"],
	delete?:ResolverInputTypes["UsersDocAccessFields_status_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["UsersDocAccessFields_status_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersDocAccessFields_status_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersDocAccessFields_status_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersDocAccessFields_status_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersDocAccessFields_updatedAt"]: AliasType<{
	create?:ResolverInputTypes["UsersDocAccessFields_updatedAt_Create"],
	read?:ResolverInputTypes["UsersDocAccessFields_updatedAt_Read"],
	update?:ResolverInputTypes["UsersDocAccessFields_updatedAt_Update"],
	delete?:ResolverInputTypes["UsersDocAccessFields_updatedAt_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["UsersDocAccessFields_updatedAt_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersDocAccessFields_updatedAt_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersDocAccessFields_updatedAt_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersDocAccessFields_updatedAt_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersDocAccessFields_createdAt"]: AliasType<{
	create?:ResolverInputTypes["UsersDocAccessFields_createdAt_Create"],
	read?:ResolverInputTypes["UsersDocAccessFields_createdAt_Read"],
	update?:ResolverInputTypes["UsersDocAccessFields_createdAt_Update"],
	delete?:ResolverInputTypes["UsersDocAccessFields_createdAt_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["UsersDocAccessFields_createdAt_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersDocAccessFields_createdAt_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersDocAccessFields_createdAt_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersDocAccessFields_createdAt_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersDocAccessFields_email"]: AliasType<{
	create?:ResolverInputTypes["UsersDocAccessFields_email_Create"],
	read?:ResolverInputTypes["UsersDocAccessFields_email_Read"],
	update?:ResolverInputTypes["UsersDocAccessFields_email_Update"],
	delete?:ResolverInputTypes["UsersDocAccessFields_email_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["UsersDocAccessFields_email_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersDocAccessFields_email_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersDocAccessFields_email_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersDocAccessFields_email_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersDocAccessFields_password"]: AliasType<{
	create?:ResolverInputTypes["UsersDocAccessFields_password_Create"],
	read?:ResolverInputTypes["UsersDocAccessFields_password_Read"],
	update?:ResolverInputTypes["UsersDocAccessFields_password_Update"],
	delete?:ResolverInputTypes["UsersDocAccessFields_password_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["UsersDocAccessFields_password_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersDocAccessFields_password_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersDocAccessFields_password_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersDocAccessFields_password_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersCreateDocAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersReadDocAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersUpdateDocAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersDeleteDocAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersUnlockDocAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["usersMe"]: AliasType<{
	collection?:boolean | `@${string}`,
	exp?:boolean | `@${string}`,
	strategy?:boolean | `@${string}`,
	token?:boolean | `@${string}`,
	user?:ResolverInputTypes["User"],
		__typename?: boolean | `@${string}`
}>;
	["Bookmark"]: AliasType<{
	id?:boolean | `@${string}`,
user?: [{	locale?: ResolverInputTypes["LocaleInputType"] | undefined | null,	fallbackLocale?: ResolverInputTypes["FallbackLocaleInputType"] | undefined | null},ResolverInputTypes["Bookmark_User_Relationship"]],
item?: [{	locale?: ResolverInputTypes["LocaleInputType"] | undefined | null,	fallbackLocale?: ResolverInputTypes["FallbackLocaleInputType"] | undefined | null},ResolverInputTypes["Bookmark_Item_Relationship"]],
	updatedAt?:boolean | `@${string}`,
	createdAt?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["Bookmark_User_Relationship"]: AliasType<{
	relationTo?:boolean | `@${string}`,
	value?:ResolverInputTypes["Bookmark_User"],
		__typename?: boolean | `@${string}`
}>;
	["Bookmark_User_RelationTo"]:Bookmark_User_RelationTo;
	["Bookmark_User"]: AliasType<{
	User?:ResolverInputTypes["User"],
		__typename?: boolean | `@${string}`
}>;
	["Bookmark_Item_Relationship"]: AliasType<{
	relationTo?:boolean | `@${string}`,
	value?:ResolverInputTypes["Bookmark_Item"],
		__typename?: boolean | `@${string}`
}>;
	["Bookmark_Item_RelationTo"]:Bookmark_Item_RelationTo;
	["Bookmark_Item"]: AliasType<{
	Event?:ResolverInputTypes["Event"],
	Location?:ResolverInputTypes["Location"],
	Post?:ResolverInputTypes["Post"],
		__typename?: boolean | `@${string}`
}>;
	["Bookmarks"]: AliasType<{
	docs?:ResolverInputTypes["Bookmark"],
	hasNextPage?:boolean | `@${string}`,
	hasPrevPage?:boolean | `@${string}`,
	limit?:boolean | `@${string}`,
	nextPage?:boolean | `@${string}`,
	offset?:boolean | `@${string}`,
	page?:boolean | `@${string}`,
	pagingCounter?:boolean | `@${string}`,
	prevPage?:boolean | `@${string}`,
	totalDocs?:boolean | `@${string}`,
	totalPages?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["Bookmark_where"]: {
	user?: ResolverInputTypes["Bookmark_user_Relation"] | undefined | null,
	item?: ResolverInputTypes["Bookmark_item_Relation"] | undefined | null,
	updatedAt?: ResolverInputTypes["Bookmark_updatedAt_operator"] | undefined | null,
	createdAt?: ResolverInputTypes["Bookmark_createdAt_operator"] | undefined | null,
	id?: ResolverInputTypes["Bookmark_id_operator"] | undefined | null,
	AND?: Array<ResolverInputTypes["Bookmark_where_and"] | undefined | null> | undefined | null,
	OR?: Array<ResolverInputTypes["Bookmark_where_or"] | undefined | null> | undefined | null
};
	["Bookmark_user_Relation"]: {
	relationTo?: ResolverInputTypes["Bookmark_user_Relation_RelationTo"] | undefined | null,
	value?: ResolverInputTypes["JSON"] | undefined | null
};
	["Bookmark_user_Relation_RelationTo"]:Bookmark_user_Relation_RelationTo;
	["Bookmark_item_Relation"]: {
	relationTo?: ResolverInputTypes["Bookmark_item_Relation_RelationTo"] | undefined | null,
	value?: ResolverInputTypes["JSON"] | undefined | null
};
	["Bookmark_item_Relation_RelationTo"]:Bookmark_item_Relation_RelationTo;
	["Bookmark_updatedAt_operator"]: {
	equals?: ResolverInputTypes["DateTime"] | undefined | null,
	not_equals?: ResolverInputTypes["DateTime"] | undefined | null,
	greater_than_equal?: ResolverInputTypes["DateTime"] | undefined | null,
	greater_than?: ResolverInputTypes["DateTime"] | undefined | null,
	less_than_equal?: ResolverInputTypes["DateTime"] | undefined | null,
	less_than?: ResolverInputTypes["DateTime"] | undefined | null,
	like?: ResolverInputTypes["DateTime"] | undefined | null,
	exists?: boolean | undefined | null
};
	["Bookmark_createdAt_operator"]: {
	equals?: ResolverInputTypes["DateTime"] | undefined | null,
	not_equals?: ResolverInputTypes["DateTime"] | undefined | null,
	greater_than_equal?: ResolverInputTypes["DateTime"] | undefined | null,
	greater_than?: ResolverInputTypes["DateTime"] | undefined | null,
	less_than_equal?: ResolverInputTypes["DateTime"] | undefined | null,
	less_than?: ResolverInputTypes["DateTime"] | undefined | null,
	like?: ResolverInputTypes["DateTime"] | undefined | null,
	exists?: boolean | undefined | null
};
	["Bookmark_id_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Bookmark_where_and"]: {
	user?: ResolverInputTypes["Bookmark_user_Relation"] | undefined | null,
	item?: ResolverInputTypes["Bookmark_item_Relation"] | undefined | null,
	updatedAt?: ResolverInputTypes["Bookmark_updatedAt_operator"] | undefined | null,
	createdAt?: ResolverInputTypes["Bookmark_createdAt_operator"] | undefined | null,
	id?: ResolverInputTypes["Bookmark_id_operator"] | undefined | null,
	AND?: Array<ResolverInputTypes["Bookmark_where_and"] | undefined | null> | undefined | null,
	OR?: Array<ResolverInputTypes["Bookmark_where_or"] | undefined | null> | undefined | null
};
	["Bookmark_where_or"]: {
	user?: ResolverInputTypes["Bookmark_user_Relation"] | undefined | null,
	item?: ResolverInputTypes["Bookmark_item_Relation"] | undefined | null,
	updatedAt?: ResolverInputTypes["Bookmark_updatedAt_operator"] | undefined | null,
	createdAt?: ResolverInputTypes["Bookmark_createdAt_operator"] | undefined | null,
	id?: ResolverInputTypes["Bookmark_id_operator"] | undefined | null,
	AND?: Array<ResolverInputTypes["Bookmark_where_and"] | undefined | null> | undefined | null,
	OR?: Array<ResolverInputTypes["Bookmark_where_or"] | undefined | null> | undefined | null
};
	["countBookmarks"]: AliasType<{
	totalDocs?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["bookmarksDocAccess"]: AliasType<{
	fields?:ResolverInputTypes["BookmarksDocAccessFields"],
	create?:ResolverInputTypes["BookmarksCreateDocAccess"],
	read?:ResolverInputTypes["BookmarksReadDocAccess"],
	update?:ResolverInputTypes["BookmarksUpdateDocAccess"],
	delete?:ResolverInputTypes["BookmarksDeleteDocAccess"],
		__typename?: boolean | `@${string}`
}>;
	["BookmarksDocAccessFields"]: AliasType<{
	user?:ResolverInputTypes["BookmarksDocAccessFields_user"],
	item?:ResolverInputTypes["BookmarksDocAccessFields_item"],
	updatedAt?:ResolverInputTypes["BookmarksDocAccessFields_updatedAt"],
	createdAt?:ResolverInputTypes["BookmarksDocAccessFields_createdAt"],
		__typename?: boolean | `@${string}`
}>;
	["BookmarksDocAccessFields_user"]: AliasType<{
	create?:ResolverInputTypes["BookmarksDocAccessFields_user_Create"],
	read?:ResolverInputTypes["BookmarksDocAccessFields_user_Read"],
	update?:ResolverInputTypes["BookmarksDocAccessFields_user_Update"],
	delete?:ResolverInputTypes["BookmarksDocAccessFields_user_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["BookmarksDocAccessFields_user_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BookmarksDocAccessFields_user_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BookmarksDocAccessFields_user_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BookmarksDocAccessFields_user_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BookmarksDocAccessFields_item"]: AliasType<{
	create?:ResolverInputTypes["BookmarksDocAccessFields_item_Create"],
	read?:ResolverInputTypes["BookmarksDocAccessFields_item_Read"],
	update?:ResolverInputTypes["BookmarksDocAccessFields_item_Update"],
	delete?:ResolverInputTypes["BookmarksDocAccessFields_item_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["BookmarksDocAccessFields_item_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BookmarksDocAccessFields_item_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BookmarksDocAccessFields_item_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BookmarksDocAccessFields_item_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BookmarksDocAccessFields_updatedAt"]: AliasType<{
	create?:ResolverInputTypes["BookmarksDocAccessFields_updatedAt_Create"],
	read?:ResolverInputTypes["BookmarksDocAccessFields_updatedAt_Read"],
	update?:ResolverInputTypes["BookmarksDocAccessFields_updatedAt_Update"],
	delete?:ResolverInputTypes["BookmarksDocAccessFields_updatedAt_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["BookmarksDocAccessFields_updatedAt_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BookmarksDocAccessFields_updatedAt_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BookmarksDocAccessFields_updatedAt_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BookmarksDocAccessFields_updatedAt_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BookmarksDocAccessFields_createdAt"]: AliasType<{
	create?:ResolverInputTypes["BookmarksDocAccessFields_createdAt_Create"],
	read?:ResolverInputTypes["BookmarksDocAccessFields_createdAt_Read"],
	update?:ResolverInputTypes["BookmarksDocAccessFields_createdAt_Update"],
	delete?:ResolverInputTypes["BookmarksDocAccessFields_createdAt_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["BookmarksDocAccessFields_createdAt_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BookmarksDocAccessFields_createdAt_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BookmarksDocAccessFields_createdAt_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BookmarksDocAccessFields_createdAt_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BookmarksCreateDocAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BookmarksReadDocAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BookmarksUpdateDocAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BookmarksDeleteDocAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["WebAppBookmark"]: AliasType<{
	id?:boolean | `@${string}`,
user?: [{	locale?: ResolverInputTypes["LocaleInputType"] | undefined | null,	fallbackLocale?: ResolverInputTypes["FallbackLocaleInputType"] | undefined | null},ResolverInputTypes["WebAppBookmark_User_Relationship"]],
event?: [{	locale?: ResolverInputTypes["LocaleInputType"] | undefined | null,	fallbackLocale?: ResolverInputTypes["FallbackLocaleInputType"] | undefined | null},ResolverInputTypes["WebAppBookmark_Event_Relationship"]],
	productType?:boolean | `@${string}`,
	item?:boolean | `@${string}`,
	entryNumber?:boolean | `@${string}`,
	updatedAt?:boolean | `@${string}`,
	createdAt?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["WebAppBookmark_User_Relationship"]: AliasType<{
	relationTo?:boolean | `@${string}`,
	value?:ResolverInputTypes["WebAppBookmark_User"],
		__typename?: boolean | `@${string}`
}>;
	["WebAppBookmark_User_RelationTo"]:WebAppBookmark_User_RelationTo;
	["WebAppBookmark_User"]: AliasType<{
	User?:ResolverInputTypes["User"],
		__typename?: boolean | `@${string}`
}>;
	["WebAppBookmark_Event_Relationship"]: AliasType<{
	relationTo?:boolean | `@${string}`,
	value?:ResolverInputTypes["WebAppBookmark_Event"],
		__typename?: boolean | `@${string}`
}>;
	["WebAppBookmark_Event_RelationTo"]:WebAppBookmark_Event_RelationTo;
	["WebAppBookmark_Event"]: AliasType<{
	Event?:ResolverInputTypes["Event"],
		__typename?: boolean | `@${string}`
}>;
	["WebAppBookmark_productType"]:WebAppBookmark_productType;
	["WebAppBookmarks"]: AliasType<{
	docs?:ResolverInputTypes["WebAppBookmark"],
	hasNextPage?:boolean | `@${string}`,
	hasPrevPage?:boolean | `@${string}`,
	limit?:boolean | `@${string}`,
	nextPage?:boolean | `@${string}`,
	offset?:boolean | `@${string}`,
	page?:boolean | `@${string}`,
	pagingCounter?:boolean | `@${string}`,
	prevPage?:boolean | `@${string}`,
	totalDocs?:boolean | `@${string}`,
	totalPages?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["WebAppBookmark_where"]: {
	user?: ResolverInputTypes["WebAppBookmark_user_Relation"] | undefined | null,
	event?: ResolverInputTypes["WebAppBookmark_event_Relation"] | undefined | null,
	productType?: ResolverInputTypes["WebAppBookmark_productType_operator"] | undefined | null,
	item?: ResolverInputTypes["WebAppBookmark_item_operator"] | undefined | null,
	entryNumber?: ResolverInputTypes["WebAppBookmark_entryNumber_operator"] | undefined | null,
	updatedAt?: ResolverInputTypes["WebAppBookmark_updatedAt_operator"] | undefined | null,
	createdAt?: ResolverInputTypes["WebAppBookmark_createdAt_operator"] | undefined | null,
	id?: ResolverInputTypes["WebAppBookmark_id_operator"] | undefined | null,
	AND?: Array<ResolverInputTypes["WebAppBookmark_where_and"] | undefined | null> | undefined | null,
	OR?: Array<ResolverInputTypes["WebAppBookmark_where_or"] | undefined | null> | undefined | null
};
	["WebAppBookmark_user_Relation"]: {
	relationTo?: ResolverInputTypes["WebAppBookmark_user_Relation_RelationTo"] | undefined | null,
	value?: ResolverInputTypes["JSON"] | undefined | null
};
	["WebAppBookmark_user_Relation_RelationTo"]:WebAppBookmark_user_Relation_RelationTo;
	["WebAppBookmark_event_Relation"]: {
	relationTo?: ResolverInputTypes["WebAppBookmark_event_Relation_RelationTo"] | undefined | null,
	value?: ResolverInputTypes["JSON"] | undefined | null
};
	["WebAppBookmark_event_Relation_RelationTo"]:WebAppBookmark_event_Relation_RelationTo;
	["WebAppBookmark_productType_operator"]: {
	equals?: ResolverInputTypes["WebAppBookmark_productType_Input"] | undefined | null,
	not_equals?: ResolverInputTypes["WebAppBookmark_productType_Input"] | undefined | null,
	in?: Array<ResolverInputTypes["WebAppBookmark_productType_Input"] | undefined | null> | undefined | null,
	not_in?: Array<ResolverInputTypes["WebAppBookmark_productType_Input"] | undefined | null> | undefined | null,
	all?: Array<ResolverInputTypes["WebAppBookmark_productType_Input"] | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["WebAppBookmark_productType_Input"]:WebAppBookmark_productType_Input;
	["WebAppBookmark_item_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["WebAppBookmark_entryNumber_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["WebAppBookmark_updatedAt_operator"]: {
	equals?: ResolverInputTypes["DateTime"] | undefined | null,
	not_equals?: ResolverInputTypes["DateTime"] | undefined | null,
	greater_than_equal?: ResolverInputTypes["DateTime"] | undefined | null,
	greater_than?: ResolverInputTypes["DateTime"] | undefined | null,
	less_than_equal?: ResolverInputTypes["DateTime"] | undefined | null,
	less_than?: ResolverInputTypes["DateTime"] | undefined | null,
	like?: ResolverInputTypes["DateTime"] | undefined | null,
	exists?: boolean | undefined | null
};
	["WebAppBookmark_createdAt_operator"]: {
	equals?: ResolverInputTypes["DateTime"] | undefined | null,
	not_equals?: ResolverInputTypes["DateTime"] | undefined | null,
	greater_than_equal?: ResolverInputTypes["DateTime"] | undefined | null,
	greater_than?: ResolverInputTypes["DateTime"] | undefined | null,
	less_than_equal?: ResolverInputTypes["DateTime"] | undefined | null,
	less_than?: ResolverInputTypes["DateTime"] | undefined | null,
	like?: ResolverInputTypes["DateTime"] | undefined | null,
	exists?: boolean | undefined | null
};
	["WebAppBookmark_id_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["WebAppBookmark_where_and"]: {
	user?: ResolverInputTypes["WebAppBookmark_user_Relation"] | undefined | null,
	event?: ResolverInputTypes["WebAppBookmark_event_Relation"] | undefined | null,
	productType?: ResolverInputTypes["WebAppBookmark_productType_operator"] | undefined | null,
	item?: ResolverInputTypes["WebAppBookmark_item_operator"] | undefined | null,
	entryNumber?: ResolverInputTypes["WebAppBookmark_entryNumber_operator"] | undefined | null,
	updatedAt?: ResolverInputTypes["WebAppBookmark_updatedAt_operator"] | undefined | null,
	createdAt?: ResolverInputTypes["WebAppBookmark_createdAt_operator"] | undefined | null,
	id?: ResolverInputTypes["WebAppBookmark_id_operator"] | undefined | null,
	AND?: Array<ResolverInputTypes["WebAppBookmark_where_and"] | undefined | null> | undefined | null,
	OR?: Array<ResolverInputTypes["WebAppBookmark_where_or"] | undefined | null> | undefined | null
};
	["WebAppBookmark_where_or"]: {
	user?: ResolverInputTypes["WebAppBookmark_user_Relation"] | undefined | null,
	event?: ResolverInputTypes["WebAppBookmark_event_Relation"] | undefined | null,
	productType?: ResolverInputTypes["WebAppBookmark_productType_operator"] | undefined | null,
	item?: ResolverInputTypes["WebAppBookmark_item_operator"] | undefined | null,
	entryNumber?: ResolverInputTypes["WebAppBookmark_entryNumber_operator"] | undefined | null,
	updatedAt?: ResolverInputTypes["WebAppBookmark_updatedAt_operator"] | undefined | null,
	createdAt?: ResolverInputTypes["WebAppBookmark_createdAt_operator"] | undefined | null,
	id?: ResolverInputTypes["WebAppBookmark_id_operator"] | undefined | null,
	AND?: Array<ResolverInputTypes["WebAppBookmark_where_and"] | undefined | null> | undefined | null,
	OR?: Array<ResolverInputTypes["WebAppBookmark_where_or"] | undefined | null> | undefined | null
};
	["countWebAppBookmarks"]: AliasType<{
	totalDocs?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["webAppBookmarksDocAccess"]: AliasType<{
	fields?:ResolverInputTypes["WebAppBookmarksDocAccessFields"],
	create?:ResolverInputTypes["WebAppBookmarksCreateDocAccess"],
	read?:ResolverInputTypes["WebAppBookmarksReadDocAccess"],
	update?:ResolverInputTypes["WebAppBookmarksUpdateDocAccess"],
	delete?:ResolverInputTypes["WebAppBookmarksDeleteDocAccess"],
		__typename?: boolean | `@${string}`
}>;
	["WebAppBookmarksDocAccessFields"]: AliasType<{
	user?:ResolverInputTypes["WebAppBookmarksDocAccessFields_user"],
	event?:ResolverInputTypes["WebAppBookmarksDocAccessFields_event"],
	productType?:ResolverInputTypes["WebAppBookmarksDocAccessFields_productType"],
	item?:ResolverInputTypes["WebAppBookmarksDocAccessFields_item"],
	entryNumber?:ResolverInputTypes["WebAppBookmarksDocAccessFields_entryNumber"],
	updatedAt?:ResolverInputTypes["WebAppBookmarksDocAccessFields_updatedAt"],
	createdAt?:ResolverInputTypes["WebAppBookmarksDocAccessFields_createdAt"],
		__typename?: boolean | `@${string}`
}>;
	["WebAppBookmarksDocAccessFields_user"]: AliasType<{
	create?:ResolverInputTypes["WebAppBookmarksDocAccessFields_user_Create"],
	read?:ResolverInputTypes["WebAppBookmarksDocAccessFields_user_Read"],
	update?:ResolverInputTypes["WebAppBookmarksDocAccessFields_user_Update"],
	delete?:ResolverInputTypes["WebAppBookmarksDocAccessFields_user_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["WebAppBookmarksDocAccessFields_user_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["WebAppBookmarksDocAccessFields_user_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["WebAppBookmarksDocAccessFields_user_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["WebAppBookmarksDocAccessFields_user_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["WebAppBookmarksDocAccessFields_event"]: AliasType<{
	create?:ResolverInputTypes["WebAppBookmarksDocAccessFields_event_Create"],
	read?:ResolverInputTypes["WebAppBookmarksDocAccessFields_event_Read"],
	update?:ResolverInputTypes["WebAppBookmarksDocAccessFields_event_Update"],
	delete?:ResolverInputTypes["WebAppBookmarksDocAccessFields_event_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["WebAppBookmarksDocAccessFields_event_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["WebAppBookmarksDocAccessFields_event_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["WebAppBookmarksDocAccessFields_event_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["WebAppBookmarksDocAccessFields_event_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["WebAppBookmarksDocAccessFields_productType"]: AliasType<{
	create?:ResolverInputTypes["WebAppBookmarksDocAccessFields_productType_Create"],
	read?:ResolverInputTypes["WebAppBookmarksDocAccessFields_productType_Read"],
	update?:ResolverInputTypes["WebAppBookmarksDocAccessFields_productType_Update"],
	delete?:ResolverInputTypes["WebAppBookmarksDocAccessFields_productType_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["WebAppBookmarksDocAccessFields_productType_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["WebAppBookmarksDocAccessFields_productType_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["WebAppBookmarksDocAccessFields_productType_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["WebAppBookmarksDocAccessFields_productType_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["WebAppBookmarksDocAccessFields_item"]: AliasType<{
	create?:ResolverInputTypes["WebAppBookmarksDocAccessFields_item_Create"],
	read?:ResolverInputTypes["WebAppBookmarksDocAccessFields_item_Read"],
	update?:ResolverInputTypes["WebAppBookmarksDocAccessFields_item_Update"],
	delete?:ResolverInputTypes["WebAppBookmarksDocAccessFields_item_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["WebAppBookmarksDocAccessFields_item_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["WebAppBookmarksDocAccessFields_item_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["WebAppBookmarksDocAccessFields_item_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["WebAppBookmarksDocAccessFields_item_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["WebAppBookmarksDocAccessFields_entryNumber"]: AliasType<{
	create?:ResolverInputTypes["WebAppBookmarksDocAccessFields_entryNumber_Create"],
	read?:ResolverInputTypes["WebAppBookmarksDocAccessFields_entryNumber_Read"],
	update?:ResolverInputTypes["WebAppBookmarksDocAccessFields_entryNumber_Update"],
	delete?:ResolverInputTypes["WebAppBookmarksDocAccessFields_entryNumber_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["WebAppBookmarksDocAccessFields_entryNumber_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["WebAppBookmarksDocAccessFields_entryNumber_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["WebAppBookmarksDocAccessFields_entryNumber_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["WebAppBookmarksDocAccessFields_entryNumber_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["WebAppBookmarksDocAccessFields_updatedAt"]: AliasType<{
	create?:ResolverInputTypes["WebAppBookmarksDocAccessFields_updatedAt_Create"],
	read?:ResolverInputTypes["WebAppBookmarksDocAccessFields_updatedAt_Read"],
	update?:ResolverInputTypes["WebAppBookmarksDocAccessFields_updatedAt_Update"],
	delete?:ResolverInputTypes["WebAppBookmarksDocAccessFields_updatedAt_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["WebAppBookmarksDocAccessFields_updatedAt_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["WebAppBookmarksDocAccessFields_updatedAt_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["WebAppBookmarksDocAccessFields_updatedAt_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["WebAppBookmarksDocAccessFields_updatedAt_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["WebAppBookmarksDocAccessFields_createdAt"]: AliasType<{
	create?:ResolverInputTypes["WebAppBookmarksDocAccessFields_createdAt_Create"],
	read?:ResolverInputTypes["WebAppBookmarksDocAccessFields_createdAt_Read"],
	update?:ResolverInputTypes["WebAppBookmarksDocAccessFields_createdAt_Update"],
	delete?:ResolverInputTypes["WebAppBookmarksDocAccessFields_createdAt_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["WebAppBookmarksDocAccessFields_createdAt_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["WebAppBookmarksDocAccessFields_createdAt_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["WebAppBookmarksDocAccessFields_createdAt_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["WebAppBookmarksDocAccessFields_createdAt_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["WebAppBookmarksCreateDocAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["WebAppBookmarksReadDocAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["WebAppBookmarksUpdateDocAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["WebAppBookmarksDeleteDocAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ViewRecord"]: AliasType<{
	id?:boolean | `@${string}`,
user?: [{	locale?: ResolverInputTypes["LocaleInputType"] | undefined | null,	fallbackLocale?: ResolverInputTypes["FallbackLocaleInputType"] | undefined | null},ResolverInputTypes["ViewRecord_User_Relationship"]],
item?: [{	locale?: ResolverInputTypes["LocaleInputType"] | undefined | null,	fallbackLocale?: ResolverInputTypes["FallbackLocaleInputType"] | undefined | null},ResolverInputTypes["ViewRecord_Item_Relationship"]],
	updatedAt?:boolean | `@${string}`,
	createdAt?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ViewRecord_User_Relationship"]: AliasType<{
	relationTo?:boolean | `@${string}`,
	value?:ResolverInputTypes["ViewRecord_User"],
		__typename?: boolean | `@${string}`
}>;
	["ViewRecord_User_RelationTo"]:ViewRecord_User_RelationTo;
	["ViewRecord_User"]: AliasType<{
	User?:ResolverInputTypes["User"],
		__typename?: boolean | `@${string}`
}>;
	["ViewRecord_Item_Relationship"]: AliasType<{
	relationTo?:boolean | `@${string}`,
	value?:ResolverInputTypes["ViewRecord_Item"],
		__typename?: boolean | `@${string}`
}>;
	["ViewRecord_Item_RelationTo"]:ViewRecord_Item_RelationTo;
	["ViewRecord_Item"]: AliasType<{
	Event?:ResolverInputTypes["Event"],
	Location?:ResolverInputTypes["Location"],
	Post?:ResolverInputTypes["Post"],
		__typename?: boolean | `@${string}`
}>;
	["ViewRecords"]: AliasType<{
	docs?:ResolverInputTypes["ViewRecord"],
	hasNextPage?:boolean | `@${string}`,
	hasPrevPage?:boolean | `@${string}`,
	limit?:boolean | `@${string}`,
	nextPage?:boolean | `@${string}`,
	offset?:boolean | `@${string}`,
	page?:boolean | `@${string}`,
	pagingCounter?:boolean | `@${string}`,
	prevPage?:boolean | `@${string}`,
	totalDocs?:boolean | `@${string}`,
	totalPages?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ViewRecord_where"]: {
	user?: ResolverInputTypes["ViewRecord_user_Relation"] | undefined | null,
	item?: ResolverInputTypes["ViewRecord_item_Relation"] | undefined | null,
	updatedAt?: ResolverInputTypes["ViewRecord_updatedAt_operator"] | undefined | null,
	createdAt?: ResolverInputTypes["ViewRecord_createdAt_operator"] | undefined | null,
	id?: ResolverInputTypes["ViewRecord_id_operator"] | undefined | null,
	AND?: Array<ResolverInputTypes["ViewRecord_where_and"] | undefined | null> | undefined | null,
	OR?: Array<ResolverInputTypes["ViewRecord_where_or"] | undefined | null> | undefined | null
};
	["ViewRecord_user_Relation"]: {
	relationTo?: ResolverInputTypes["ViewRecord_user_Relation_RelationTo"] | undefined | null,
	value?: ResolverInputTypes["JSON"] | undefined | null
};
	["ViewRecord_user_Relation_RelationTo"]:ViewRecord_user_Relation_RelationTo;
	["ViewRecord_item_Relation"]: {
	relationTo?: ResolverInputTypes["ViewRecord_item_Relation_RelationTo"] | undefined | null,
	value?: ResolverInputTypes["JSON"] | undefined | null
};
	["ViewRecord_item_Relation_RelationTo"]:ViewRecord_item_Relation_RelationTo;
	["ViewRecord_updatedAt_operator"]: {
	equals?: ResolverInputTypes["DateTime"] | undefined | null,
	not_equals?: ResolverInputTypes["DateTime"] | undefined | null,
	greater_than_equal?: ResolverInputTypes["DateTime"] | undefined | null,
	greater_than?: ResolverInputTypes["DateTime"] | undefined | null,
	less_than_equal?: ResolverInputTypes["DateTime"] | undefined | null,
	less_than?: ResolverInputTypes["DateTime"] | undefined | null,
	like?: ResolverInputTypes["DateTime"] | undefined | null,
	exists?: boolean | undefined | null
};
	["ViewRecord_createdAt_operator"]: {
	equals?: ResolverInputTypes["DateTime"] | undefined | null,
	not_equals?: ResolverInputTypes["DateTime"] | undefined | null,
	greater_than_equal?: ResolverInputTypes["DateTime"] | undefined | null,
	greater_than?: ResolverInputTypes["DateTime"] | undefined | null,
	less_than_equal?: ResolverInputTypes["DateTime"] | undefined | null,
	less_than?: ResolverInputTypes["DateTime"] | undefined | null,
	like?: ResolverInputTypes["DateTime"] | undefined | null,
	exists?: boolean | undefined | null
};
	["ViewRecord_id_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["ViewRecord_where_and"]: {
	user?: ResolverInputTypes["ViewRecord_user_Relation"] | undefined | null,
	item?: ResolverInputTypes["ViewRecord_item_Relation"] | undefined | null,
	updatedAt?: ResolverInputTypes["ViewRecord_updatedAt_operator"] | undefined | null,
	createdAt?: ResolverInputTypes["ViewRecord_createdAt_operator"] | undefined | null,
	id?: ResolverInputTypes["ViewRecord_id_operator"] | undefined | null,
	AND?: Array<ResolverInputTypes["ViewRecord_where_and"] | undefined | null> | undefined | null,
	OR?: Array<ResolverInputTypes["ViewRecord_where_or"] | undefined | null> | undefined | null
};
	["ViewRecord_where_or"]: {
	user?: ResolverInputTypes["ViewRecord_user_Relation"] | undefined | null,
	item?: ResolverInputTypes["ViewRecord_item_Relation"] | undefined | null,
	updatedAt?: ResolverInputTypes["ViewRecord_updatedAt_operator"] | undefined | null,
	createdAt?: ResolverInputTypes["ViewRecord_createdAt_operator"] | undefined | null,
	id?: ResolverInputTypes["ViewRecord_id_operator"] | undefined | null,
	AND?: Array<ResolverInputTypes["ViewRecord_where_and"] | undefined | null> | undefined | null,
	OR?: Array<ResolverInputTypes["ViewRecord_where_or"] | undefined | null> | undefined | null
};
	["countViewRecords"]: AliasType<{
	totalDocs?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["view_recordsDocAccess"]: AliasType<{
	fields?:ResolverInputTypes["ViewRecordsDocAccessFields"],
	create?:ResolverInputTypes["ViewRecordsCreateDocAccess"],
	read?:ResolverInputTypes["ViewRecordsReadDocAccess"],
	update?:ResolverInputTypes["ViewRecordsUpdateDocAccess"],
	delete?:ResolverInputTypes["ViewRecordsDeleteDocAccess"],
		__typename?: boolean | `@${string}`
}>;
	["ViewRecordsDocAccessFields"]: AliasType<{
	user?:ResolverInputTypes["ViewRecordsDocAccessFields_user"],
	item?:ResolverInputTypes["ViewRecordsDocAccessFields_item"],
	updatedAt?:ResolverInputTypes["ViewRecordsDocAccessFields_updatedAt"],
	createdAt?:ResolverInputTypes["ViewRecordsDocAccessFields_createdAt"],
		__typename?: boolean | `@${string}`
}>;
	["ViewRecordsDocAccessFields_user"]: AliasType<{
	create?:ResolverInputTypes["ViewRecordsDocAccessFields_user_Create"],
	read?:ResolverInputTypes["ViewRecordsDocAccessFields_user_Read"],
	update?:ResolverInputTypes["ViewRecordsDocAccessFields_user_Update"],
	delete?:ResolverInputTypes["ViewRecordsDocAccessFields_user_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["ViewRecordsDocAccessFields_user_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ViewRecordsDocAccessFields_user_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ViewRecordsDocAccessFields_user_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ViewRecordsDocAccessFields_user_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ViewRecordsDocAccessFields_item"]: AliasType<{
	create?:ResolverInputTypes["ViewRecordsDocAccessFields_item_Create"],
	read?:ResolverInputTypes["ViewRecordsDocAccessFields_item_Read"],
	update?:ResolverInputTypes["ViewRecordsDocAccessFields_item_Update"],
	delete?:ResolverInputTypes["ViewRecordsDocAccessFields_item_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["ViewRecordsDocAccessFields_item_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ViewRecordsDocAccessFields_item_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ViewRecordsDocAccessFields_item_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ViewRecordsDocAccessFields_item_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ViewRecordsDocAccessFields_updatedAt"]: AliasType<{
	create?:ResolverInputTypes["ViewRecordsDocAccessFields_updatedAt_Create"],
	read?:ResolverInputTypes["ViewRecordsDocAccessFields_updatedAt_Read"],
	update?:ResolverInputTypes["ViewRecordsDocAccessFields_updatedAt_Update"],
	delete?:ResolverInputTypes["ViewRecordsDocAccessFields_updatedAt_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["ViewRecordsDocAccessFields_updatedAt_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ViewRecordsDocAccessFields_updatedAt_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ViewRecordsDocAccessFields_updatedAt_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ViewRecordsDocAccessFields_updatedAt_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ViewRecordsDocAccessFields_createdAt"]: AliasType<{
	create?:ResolverInputTypes["ViewRecordsDocAccessFields_createdAt_Create"],
	read?:ResolverInputTypes["ViewRecordsDocAccessFields_createdAt_Read"],
	update?:ResolverInputTypes["ViewRecordsDocAccessFields_createdAt_Update"],
	delete?:ResolverInputTypes["ViewRecordsDocAccessFields_createdAt_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["ViewRecordsDocAccessFields_createdAt_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ViewRecordsDocAccessFields_createdAt_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ViewRecordsDocAccessFields_createdAt_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ViewRecordsDocAccessFields_createdAt_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ViewRecordsCreateDocAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ViewRecordsReadDocAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ViewRecordsUpdateDocAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ViewRecordsDeleteDocAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["Comment"]: AliasType<{
	id?:boolean | `@${string}`,
commenter?: [{	locale?: ResolverInputTypes["LocaleInputType"] | undefined | null,	fallbackLocale?: ResolverInputTypes["FallbackLocaleInputType"] | undefined | null},ResolverInputTypes["User"]],
	content?:boolean | `@${string}`,
image?: [{	locale?: ResolverInputTypes["LocaleInputType"] | undefined | null,	fallbackLocale?: ResolverInputTypes["FallbackLocaleInputType"] | undefined | null,	where?: ResolverInputTypes["Comment_Image_where"] | undefined | null},ResolverInputTypes["Media"]],
target?: [{	locale?: ResolverInputTypes["LocaleInputType"] | undefined | null,	fallbackLocale?: ResolverInputTypes["FallbackLocaleInputType"] | undefined | null},ResolverInputTypes["Comment_Target_Relationship"]],
parent?: [{	locale?: ResolverInputTypes["LocaleInputType"] | undefined | null,	fallbackLocale?: ResolverInputTypes["FallbackLocaleInputType"] | undefined | null},ResolverInputTypes["Comment_Parent_Relationship"]],
	isReviewed?:boolean | `@${string}`,
reviewedBy?: [{	locale?: ResolverInputTypes["LocaleInputType"] | undefined | null,	fallbackLocale?: ResolverInputTypes["FallbackLocaleInputType"] | undefined | null},ResolverInputTypes["User"]],
	banned?:boolean | `@${string}`,
	type?:boolean | `@${string}`,
	updatedAt?:boolean | `@${string}`,
	createdAt?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["Comment_Image_where"]: {
	alt?: ResolverInputTypes["Comment_Image_alt_operator"] | undefined | null,
	updatedAt?: ResolverInputTypes["Comment_Image_updatedAt_operator"] | undefined | null,
	createdAt?: ResolverInputTypes["Comment_Image_createdAt_operator"] | undefined | null,
	url?: ResolverInputTypes["Comment_Image_url_operator"] | undefined | null,
	filename?: ResolverInputTypes["Comment_Image_filename_operator"] | undefined | null,
	mimeType?: ResolverInputTypes["Comment_Image_mimeType_operator"] | undefined | null,
	filesize?: ResolverInputTypes["Comment_Image_filesize_operator"] | undefined | null,
	width?: ResolverInputTypes["Comment_Image_width_operator"] | undefined | null,
	height?: ResolverInputTypes["Comment_Image_height_operator"] | undefined | null,
	focalX?: ResolverInputTypes["Comment_Image_focalX_operator"] | undefined | null,
	focalY?: ResolverInputTypes["Comment_Image_focalY_operator"] | undefined | null,
	sizes__small__url?: ResolverInputTypes["Comment_Image_sizes__small__url_operator"] | undefined | null,
	sizes__small__width?: ResolverInputTypes["Comment_Image_sizes__small__width_operator"] | undefined | null,
	sizes__small__height?: ResolverInputTypes["Comment_Image_sizes__small__height_operator"] | undefined | null,
	sizes__small__mimeType?: ResolverInputTypes["Comment_Image_sizes__small__mimeType_operator"] | undefined | null,
	sizes__small__filesize?: ResolverInputTypes["Comment_Image_sizes__small__filesize_operator"] | undefined | null,
	sizes__small__filename?: ResolverInputTypes["Comment_Image_sizes__small__filename_operator"] | undefined | null,
	sizes__medium__url?: ResolverInputTypes["Comment_Image_sizes__medium__url_operator"] | undefined | null,
	sizes__medium__width?: ResolverInputTypes["Comment_Image_sizes__medium__width_operator"] | undefined | null,
	sizes__medium__height?: ResolverInputTypes["Comment_Image_sizes__medium__height_operator"] | undefined | null,
	sizes__medium__mimeType?: ResolverInputTypes["Comment_Image_sizes__medium__mimeType_operator"] | undefined | null,
	sizes__medium__filesize?: ResolverInputTypes["Comment_Image_sizes__medium__filesize_operator"] | undefined | null,
	sizes__medium__filename?: ResolverInputTypes["Comment_Image_sizes__medium__filename_operator"] | undefined | null,
	sizes__large__url?: ResolverInputTypes["Comment_Image_sizes__large__url_operator"] | undefined | null,
	sizes__large__width?: ResolverInputTypes["Comment_Image_sizes__large__width_operator"] | undefined | null,
	sizes__large__height?: ResolverInputTypes["Comment_Image_sizes__large__height_operator"] | undefined | null,
	sizes__large__mimeType?: ResolverInputTypes["Comment_Image_sizes__large__mimeType_operator"] | undefined | null,
	sizes__large__filesize?: ResolverInputTypes["Comment_Image_sizes__large__filesize_operator"] | undefined | null,
	sizes__large__filename?: ResolverInputTypes["Comment_Image_sizes__large__filename_operator"] | undefined | null,
	sizes__small_jpeg__url?: ResolverInputTypes["Comment_Image_sizes__small_jpeg__url_operator"] | undefined | null,
	sizes__small_jpeg__width?: ResolverInputTypes["Comment_Image_sizes__small_jpeg__width_operator"] | undefined | null,
	sizes__small_jpeg__height?: ResolverInputTypes["Comment_Image_sizes__small_jpeg__height_operator"] | undefined | null,
	sizes__small_jpeg__mimeType?: ResolverInputTypes["Comment_Image_sizes__small_jpeg__mimeType_operator"] | undefined | null,
	sizes__small_jpeg__filesize?: ResolverInputTypes["Comment_Image_sizes__small_jpeg__filesize_operator"] | undefined | null,
	sizes__small_jpeg__filename?: ResolverInputTypes["Comment_Image_sizes__small_jpeg__filename_operator"] | undefined | null,
	sizes__medium_jpeg__url?: ResolverInputTypes["Comment_Image_sizes__medium_jpeg__url_operator"] | undefined | null,
	sizes__medium_jpeg__width?: ResolverInputTypes["Comment_Image_sizes__medium_jpeg__width_operator"] | undefined | null,
	sizes__medium_jpeg__height?: ResolverInputTypes["Comment_Image_sizes__medium_jpeg__height_operator"] | undefined | null,
	sizes__medium_jpeg__mimeType?: ResolverInputTypes["Comment_Image_sizes__medium_jpeg__mimeType_operator"] | undefined | null,
	sizes__medium_jpeg__filesize?: ResolverInputTypes["Comment_Image_sizes__medium_jpeg__filesize_operator"] | undefined | null,
	sizes__medium_jpeg__filename?: ResolverInputTypes["Comment_Image_sizes__medium_jpeg__filename_operator"] | undefined | null,
	sizes__large_jpeg__url?: ResolverInputTypes["Comment_Image_sizes__large_jpeg__url_operator"] | undefined | null,
	sizes__large_jpeg__width?: ResolverInputTypes["Comment_Image_sizes__large_jpeg__width_operator"] | undefined | null,
	sizes__large_jpeg__height?: ResolverInputTypes["Comment_Image_sizes__large_jpeg__height_operator"] | undefined | null,
	sizes__large_jpeg__mimeType?: ResolverInputTypes["Comment_Image_sizes__large_jpeg__mimeType_operator"] | undefined | null,
	sizes__large_jpeg__filesize?: ResolverInputTypes["Comment_Image_sizes__large_jpeg__filesize_operator"] | undefined | null,
	sizes__large_jpeg__filename?: ResolverInputTypes["Comment_Image_sizes__large_jpeg__filename_operator"] | undefined | null,
	id?: ResolverInputTypes["Comment_Image_id_operator"] | undefined | null,
	AND?: Array<ResolverInputTypes["Comment_Image_where_and"] | undefined | null> | undefined | null,
	OR?: Array<ResolverInputTypes["Comment_Image_where_or"] | undefined | null> | undefined | null
};
	["Comment_Image_alt_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Comment_Image_updatedAt_operator"]: {
	equals?: ResolverInputTypes["DateTime"] | undefined | null,
	not_equals?: ResolverInputTypes["DateTime"] | undefined | null,
	greater_than_equal?: ResolverInputTypes["DateTime"] | undefined | null,
	greater_than?: ResolverInputTypes["DateTime"] | undefined | null,
	less_than_equal?: ResolverInputTypes["DateTime"] | undefined | null,
	less_than?: ResolverInputTypes["DateTime"] | undefined | null,
	like?: ResolverInputTypes["DateTime"] | undefined | null,
	exists?: boolean | undefined | null
};
	["Comment_Image_createdAt_operator"]: {
	equals?: ResolverInputTypes["DateTime"] | undefined | null,
	not_equals?: ResolverInputTypes["DateTime"] | undefined | null,
	greater_than_equal?: ResolverInputTypes["DateTime"] | undefined | null,
	greater_than?: ResolverInputTypes["DateTime"] | undefined | null,
	less_than_equal?: ResolverInputTypes["DateTime"] | undefined | null,
	less_than?: ResolverInputTypes["DateTime"] | undefined | null,
	like?: ResolverInputTypes["DateTime"] | undefined | null,
	exists?: boolean | undefined | null
};
	["Comment_Image_url_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Comment_Image_filename_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Comment_Image_mimeType_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Comment_Image_filesize_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Comment_Image_width_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Comment_Image_height_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Comment_Image_focalX_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Comment_Image_focalY_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Comment_Image_sizes__small__url_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Comment_Image_sizes__small__width_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Comment_Image_sizes__small__height_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Comment_Image_sizes__small__mimeType_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Comment_Image_sizes__small__filesize_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Comment_Image_sizes__small__filename_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Comment_Image_sizes__medium__url_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Comment_Image_sizes__medium__width_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Comment_Image_sizes__medium__height_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Comment_Image_sizes__medium__mimeType_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Comment_Image_sizes__medium__filesize_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Comment_Image_sizes__medium__filename_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Comment_Image_sizes__large__url_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Comment_Image_sizes__large__width_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Comment_Image_sizes__large__height_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Comment_Image_sizes__large__mimeType_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Comment_Image_sizes__large__filesize_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Comment_Image_sizes__large__filename_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Comment_Image_sizes__small_jpeg__url_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Comment_Image_sizes__small_jpeg__width_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Comment_Image_sizes__small_jpeg__height_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Comment_Image_sizes__small_jpeg__mimeType_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Comment_Image_sizes__small_jpeg__filesize_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Comment_Image_sizes__small_jpeg__filename_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Comment_Image_sizes__medium_jpeg__url_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Comment_Image_sizes__medium_jpeg__width_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Comment_Image_sizes__medium_jpeg__height_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Comment_Image_sizes__medium_jpeg__mimeType_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Comment_Image_sizes__medium_jpeg__filesize_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Comment_Image_sizes__medium_jpeg__filename_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Comment_Image_sizes__large_jpeg__url_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Comment_Image_sizes__large_jpeg__width_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Comment_Image_sizes__large_jpeg__height_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Comment_Image_sizes__large_jpeg__mimeType_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Comment_Image_sizes__large_jpeg__filesize_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Comment_Image_sizes__large_jpeg__filename_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Comment_Image_id_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Comment_Image_where_and"]: {
	alt?: ResolverInputTypes["Comment_Image_alt_operator"] | undefined | null,
	updatedAt?: ResolverInputTypes["Comment_Image_updatedAt_operator"] | undefined | null,
	createdAt?: ResolverInputTypes["Comment_Image_createdAt_operator"] | undefined | null,
	url?: ResolverInputTypes["Comment_Image_url_operator"] | undefined | null,
	filename?: ResolverInputTypes["Comment_Image_filename_operator"] | undefined | null,
	mimeType?: ResolverInputTypes["Comment_Image_mimeType_operator"] | undefined | null,
	filesize?: ResolverInputTypes["Comment_Image_filesize_operator"] | undefined | null,
	width?: ResolverInputTypes["Comment_Image_width_operator"] | undefined | null,
	height?: ResolverInputTypes["Comment_Image_height_operator"] | undefined | null,
	focalX?: ResolverInputTypes["Comment_Image_focalX_operator"] | undefined | null,
	focalY?: ResolverInputTypes["Comment_Image_focalY_operator"] | undefined | null,
	sizes__small__url?: ResolverInputTypes["Comment_Image_sizes__small__url_operator"] | undefined | null,
	sizes__small__width?: ResolverInputTypes["Comment_Image_sizes__small__width_operator"] | undefined | null,
	sizes__small__height?: ResolverInputTypes["Comment_Image_sizes__small__height_operator"] | undefined | null,
	sizes__small__mimeType?: ResolverInputTypes["Comment_Image_sizes__small__mimeType_operator"] | undefined | null,
	sizes__small__filesize?: ResolverInputTypes["Comment_Image_sizes__small__filesize_operator"] | undefined | null,
	sizes__small__filename?: ResolverInputTypes["Comment_Image_sizes__small__filename_operator"] | undefined | null,
	sizes__medium__url?: ResolverInputTypes["Comment_Image_sizes__medium__url_operator"] | undefined | null,
	sizes__medium__width?: ResolverInputTypes["Comment_Image_sizes__medium__width_operator"] | undefined | null,
	sizes__medium__height?: ResolverInputTypes["Comment_Image_sizes__medium__height_operator"] | undefined | null,
	sizes__medium__mimeType?: ResolverInputTypes["Comment_Image_sizes__medium__mimeType_operator"] | undefined | null,
	sizes__medium__filesize?: ResolverInputTypes["Comment_Image_sizes__medium__filesize_operator"] | undefined | null,
	sizes__medium__filename?: ResolverInputTypes["Comment_Image_sizes__medium__filename_operator"] | undefined | null,
	sizes__large__url?: ResolverInputTypes["Comment_Image_sizes__large__url_operator"] | undefined | null,
	sizes__large__width?: ResolverInputTypes["Comment_Image_sizes__large__width_operator"] | undefined | null,
	sizes__large__height?: ResolverInputTypes["Comment_Image_sizes__large__height_operator"] | undefined | null,
	sizes__large__mimeType?: ResolverInputTypes["Comment_Image_sizes__large__mimeType_operator"] | undefined | null,
	sizes__large__filesize?: ResolverInputTypes["Comment_Image_sizes__large__filesize_operator"] | undefined | null,
	sizes__large__filename?: ResolverInputTypes["Comment_Image_sizes__large__filename_operator"] | undefined | null,
	sizes__small_jpeg__url?: ResolverInputTypes["Comment_Image_sizes__small_jpeg__url_operator"] | undefined | null,
	sizes__small_jpeg__width?: ResolverInputTypes["Comment_Image_sizes__small_jpeg__width_operator"] | undefined | null,
	sizes__small_jpeg__height?: ResolverInputTypes["Comment_Image_sizes__small_jpeg__height_operator"] | undefined | null,
	sizes__small_jpeg__mimeType?: ResolverInputTypes["Comment_Image_sizes__small_jpeg__mimeType_operator"] | undefined | null,
	sizes__small_jpeg__filesize?: ResolverInputTypes["Comment_Image_sizes__small_jpeg__filesize_operator"] | undefined | null,
	sizes__small_jpeg__filename?: ResolverInputTypes["Comment_Image_sizes__small_jpeg__filename_operator"] | undefined | null,
	sizes__medium_jpeg__url?: ResolverInputTypes["Comment_Image_sizes__medium_jpeg__url_operator"] | undefined | null,
	sizes__medium_jpeg__width?: ResolverInputTypes["Comment_Image_sizes__medium_jpeg__width_operator"] | undefined | null,
	sizes__medium_jpeg__height?: ResolverInputTypes["Comment_Image_sizes__medium_jpeg__height_operator"] | undefined | null,
	sizes__medium_jpeg__mimeType?: ResolverInputTypes["Comment_Image_sizes__medium_jpeg__mimeType_operator"] | undefined | null,
	sizes__medium_jpeg__filesize?: ResolverInputTypes["Comment_Image_sizes__medium_jpeg__filesize_operator"] | undefined | null,
	sizes__medium_jpeg__filename?: ResolverInputTypes["Comment_Image_sizes__medium_jpeg__filename_operator"] | undefined | null,
	sizes__large_jpeg__url?: ResolverInputTypes["Comment_Image_sizes__large_jpeg__url_operator"] | undefined | null,
	sizes__large_jpeg__width?: ResolverInputTypes["Comment_Image_sizes__large_jpeg__width_operator"] | undefined | null,
	sizes__large_jpeg__height?: ResolverInputTypes["Comment_Image_sizes__large_jpeg__height_operator"] | undefined | null,
	sizes__large_jpeg__mimeType?: ResolverInputTypes["Comment_Image_sizes__large_jpeg__mimeType_operator"] | undefined | null,
	sizes__large_jpeg__filesize?: ResolverInputTypes["Comment_Image_sizes__large_jpeg__filesize_operator"] | undefined | null,
	sizes__large_jpeg__filename?: ResolverInputTypes["Comment_Image_sizes__large_jpeg__filename_operator"] | undefined | null,
	id?: ResolverInputTypes["Comment_Image_id_operator"] | undefined | null,
	AND?: Array<ResolverInputTypes["Comment_Image_where_and"] | undefined | null> | undefined | null,
	OR?: Array<ResolverInputTypes["Comment_Image_where_or"] | undefined | null> | undefined | null
};
	["Comment_Image_where_or"]: {
	alt?: ResolverInputTypes["Comment_Image_alt_operator"] | undefined | null,
	updatedAt?: ResolverInputTypes["Comment_Image_updatedAt_operator"] | undefined | null,
	createdAt?: ResolverInputTypes["Comment_Image_createdAt_operator"] | undefined | null,
	url?: ResolverInputTypes["Comment_Image_url_operator"] | undefined | null,
	filename?: ResolverInputTypes["Comment_Image_filename_operator"] | undefined | null,
	mimeType?: ResolverInputTypes["Comment_Image_mimeType_operator"] | undefined | null,
	filesize?: ResolverInputTypes["Comment_Image_filesize_operator"] | undefined | null,
	width?: ResolverInputTypes["Comment_Image_width_operator"] | undefined | null,
	height?: ResolverInputTypes["Comment_Image_height_operator"] | undefined | null,
	focalX?: ResolverInputTypes["Comment_Image_focalX_operator"] | undefined | null,
	focalY?: ResolverInputTypes["Comment_Image_focalY_operator"] | undefined | null,
	sizes__small__url?: ResolverInputTypes["Comment_Image_sizes__small__url_operator"] | undefined | null,
	sizes__small__width?: ResolverInputTypes["Comment_Image_sizes__small__width_operator"] | undefined | null,
	sizes__small__height?: ResolverInputTypes["Comment_Image_sizes__small__height_operator"] | undefined | null,
	sizes__small__mimeType?: ResolverInputTypes["Comment_Image_sizes__small__mimeType_operator"] | undefined | null,
	sizes__small__filesize?: ResolverInputTypes["Comment_Image_sizes__small__filesize_operator"] | undefined | null,
	sizes__small__filename?: ResolverInputTypes["Comment_Image_sizes__small__filename_operator"] | undefined | null,
	sizes__medium__url?: ResolverInputTypes["Comment_Image_sizes__medium__url_operator"] | undefined | null,
	sizes__medium__width?: ResolverInputTypes["Comment_Image_sizes__medium__width_operator"] | undefined | null,
	sizes__medium__height?: ResolverInputTypes["Comment_Image_sizes__medium__height_operator"] | undefined | null,
	sizes__medium__mimeType?: ResolverInputTypes["Comment_Image_sizes__medium__mimeType_operator"] | undefined | null,
	sizes__medium__filesize?: ResolverInputTypes["Comment_Image_sizes__medium__filesize_operator"] | undefined | null,
	sizes__medium__filename?: ResolverInputTypes["Comment_Image_sizes__medium__filename_operator"] | undefined | null,
	sizes__large__url?: ResolverInputTypes["Comment_Image_sizes__large__url_operator"] | undefined | null,
	sizes__large__width?: ResolverInputTypes["Comment_Image_sizes__large__width_operator"] | undefined | null,
	sizes__large__height?: ResolverInputTypes["Comment_Image_sizes__large__height_operator"] | undefined | null,
	sizes__large__mimeType?: ResolverInputTypes["Comment_Image_sizes__large__mimeType_operator"] | undefined | null,
	sizes__large__filesize?: ResolverInputTypes["Comment_Image_sizes__large__filesize_operator"] | undefined | null,
	sizes__large__filename?: ResolverInputTypes["Comment_Image_sizes__large__filename_operator"] | undefined | null,
	sizes__small_jpeg__url?: ResolverInputTypes["Comment_Image_sizes__small_jpeg__url_operator"] | undefined | null,
	sizes__small_jpeg__width?: ResolverInputTypes["Comment_Image_sizes__small_jpeg__width_operator"] | undefined | null,
	sizes__small_jpeg__height?: ResolverInputTypes["Comment_Image_sizes__small_jpeg__height_operator"] | undefined | null,
	sizes__small_jpeg__mimeType?: ResolverInputTypes["Comment_Image_sizes__small_jpeg__mimeType_operator"] | undefined | null,
	sizes__small_jpeg__filesize?: ResolverInputTypes["Comment_Image_sizes__small_jpeg__filesize_operator"] | undefined | null,
	sizes__small_jpeg__filename?: ResolverInputTypes["Comment_Image_sizes__small_jpeg__filename_operator"] | undefined | null,
	sizes__medium_jpeg__url?: ResolverInputTypes["Comment_Image_sizes__medium_jpeg__url_operator"] | undefined | null,
	sizes__medium_jpeg__width?: ResolverInputTypes["Comment_Image_sizes__medium_jpeg__width_operator"] | undefined | null,
	sizes__medium_jpeg__height?: ResolverInputTypes["Comment_Image_sizes__medium_jpeg__height_operator"] | undefined | null,
	sizes__medium_jpeg__mimeType?: ResolverInputTypes["Comment_Image_sizes__medium_jpeg__mimeType_operator"] | undefined | null,
	sizes__medium_jpeg__filesize?: ResolverInputTypes["Comment_Image_sizes__medium_jpeg__filesize_operator"] | undefined | null,
	sizes__medium_jpeg__filename?: ResolverInputTypes["Comment_Image_sizes__medium_jpeg__filename_operator"] | undefined | null,
	sizes__large_jpeg__url?: ResolverInputTypes["Comment_Image_sizes__large_jpeg__url_operator"] | undefined | null,
	sizes__large_jpeg__width?: ResolverInputTypes["Comment_Image_sizes__large_jpeg__width_operator"] | undefined | null,
	sizes__large_jpeg__height?: ResolverInputTypes["Comment_Image_sizes__large_jpeg__height_operator"] | undefined | null,
	sizes__large_jpeg__mimeType?: ResolverInputTypes["Comment_Image_sizes__large_jpeg__mimeType_operator"] | undefined | null,
	sizes__large_jpeg__filesize?: ResolverInputTypes["Comment_Image_sizes__large_jpeg__filesize_operator"] | undefined | null,
	sizes__large_jpeg__filename?: ResolverInputTypes["Comment_Image_sizes__large_jpeg__filename_operator"] | undefined | null,
	id?: ResolverInputTypes["Comment_Image_id_operator"] | undefined | null,
	AND?: Array<ResolverInputTypes["Comment_Image_where_and"] | undefined | null> | undefined | null,
	OR?: Array<ResolverInputTypes["Comment_Image_where_or"] | undefined | null> | undefined | null
};
	["Comment_Target_Relationship"]: AliasType<{
	relationTo?:boolean | `@${string}`,
	value?:ResolverInputTypes["Comment_Target"],
		__typename?: boolean | `@${string}`
}>;
	["Comment_Target_RelationTo"]:Comment_Target_RelationTo;
	["Comment_Target"]: AliasType<{
	Event?:ResolverInputTypes["Event"],
	Post?:ResolverInputTypes["Post"],
	Location?:ResolverInputTypes["Location"],
		__typename?: boolean | `@${string}`
}>;
	["Comment_Parent_Relationship"]: AliasType<{
	relationTo?:boolean | `@${string}`,
	value?:ResolverInputTypes["Comment_Parent"],
		__typename?: boolean | `@${string}`
}>;
	["Comment_Parent_RelationTo"]:Comment_Parent_RelationTo;
	["Comment_Parent"]: AliasType<{
	Comment?:ResolverInputTypes["Comment"],
		__typename?: boolean | `@${string}`
}>;
	["Comments"]: AliasType<{
	docs?:ResolverInputTypes["Comment"],
	hasNextPage?:boolean | `@${string}`,
	hasPrevPage?:boolean | `@${string}`,
	limit?:boolean | `@${string}`,
	nextPage?:boolean | `@${string}`,
	offset?:boolean | `@${string}`,
	page?:boolean | `@${string}`,
	pagingCounter?:boolean | `@${string}`,
	prevPage?:boolean | `@${string}`,
	totalDocs?:boolean | `@${string}`,
	totalPages?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["Comment_where"]: {
	commenter?: ResolverInputTypes["Comment_commenter_operator"] | undefined | null,
	content?: ResolverInputTypes["Comment_content_operator"] | undefined | null,
	image?: ResolverInputTypes["Comment_image_operator"] | undefined | null,
	target?: ResolverInputTypes["Comment_target_Relation"] | undefined | null,
	parent?: ResolverInputTypes["Comment_parent_Relation"] | undefined | null,
	isReviewed?: ResolverInputTypes["Comment_isReviewed_operator"] | undefined | null,
	reviewedBy?: ResolverInputTypes["Comment_reviewedBy_operator"] | undefined | null,
	banned?: ResolverInputTypes["Comment_banned_operator"] | undefined | null,
	type?: ResolverInputTypes["Comment_type_operator"] | undefined | null,
	updatedAt?: ResolverInputTypes["Comment_updatedAt_operator"] | undefined | null,
	createdAt?: ResolverInputTypes["Comment_createdAt_operator"] | undefined | null,
	id?: ResolverInputTypes["Comment_id_operator"] | undefined | null,
	AND?: Array<ResolverInputTypes["Comment_where_and"] | undefined | null> | undefined | null,
	OR?: Array<ResolverInputTypes["Comment_where_or"] | undefined | null> | undefined | null
};
	["Comment_commenter_operator"]: {
	equals?: ResolverInputTypes["JSON"] | undefined | null,
	not_equals?: ResolverInputTypes["JSON"] | undefined | null,
	in?: Array<ResolverInputTypes["JSON"] | undefined | null> | undefined | null,
	not_in?: Array<ResolverInputTypes["JSON"] | undefined | null> | undefined | null,
	all?: Array<ResolverInputTypes["JSON"] | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Comment_content_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Comment_image_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	exists?: boolean | undefined | null
};
	["Comment_target_Relation"]: {
	relationTo?: ResolverInputTypes["Comment_target_Relation_RelationTo"] | undefined | null,
	value?: ResolverInputTypes["JSON"] | undefined | null
};
	["Comment_target_Relation_RelationTo"]:Comment_target_Relation_RelationTo;
	["Comment_parent_Relation"]: {
	relationTo?: ResolverInputTypes["Comment_parent_Relation_RelationTo"] | undefined | null,
	value?: ResolverInputTypes["JSON"] | undefined | null
};
	["Comment_parent_Relation_RelationTo"]:Comment_parent_Relation_RelationTo;
	["Comment_isReviewed_operator"]: {
	equals?: boolean | undefined | null,
	not_equals?: boolean | undefined | null,
	exists?: boolean | undefined | null
};
	["Comment_reviewedBy_operator"]: {
	equals?: ResolverInputTypes["JSON"] | undefined | null,
	not_equals?: ResolverInputTypes["JSON"] | undefined | null,
	in?: Array<ResolverInputTypes["JSON"] | undefined | null> | undefined | null,
	not_in?: Array<ResolverInputTypes["JSON"] | undefined | null> | undefined | null,
	all?: Array<ResolverInputTypes["JSON"] | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Comment_banned_operator"]: {
	equals?: boolean | undefined | null,
	not_equals?: boolean | undefined | null,
	exists?: boolean | undefined | null
};
	["Comment_type_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Comment_updatedAt_operator"]: {
	equals?: ResolverInputTypes["DateTime"] | undefined | null,
	not_equals?: ResolverInputTypes["DateTime"] | undefined | null,
	greater_than_equal?: ResolverInputTypes["DateTime"] | undefined | null,
	greater_than?: ResolverInputTypes["DateTime"] | undefined | null,
	less_than_equal?: ResolverInputTypes["DateTime"] | undefined | null,
	less_than?: ResolverInputTypes["DateTime"] | undefined | null,
	like?: ResolverInputTypes["DateTime"] | undefined | null,
	exists?: boolean | undefined | null
};
	["Comment_createdAt_operator"]: {
	equals?: ResolverInputTypes["DateTime"] | undefined | null,
	not_equals?: ResolverInputTypes["DateTime"] | undefined | null,
	greater_than_equal?: ResolverInputTypes["DateTime"] | undefined | null,
	greater_than?: ResolverInputTypes["DateTime"] | undefined | null,
	less_than_equal?: ResolverInputTypes["DateTime"] | undefined | null,
	less_than?: ResolverInputTypes["DateTime"] | undefined | null,
	like?: ResolverInputTypes["DateTime"] | undefined | null,
	exists?: boolean | undefined | null
};
	["Comment_id_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Comment_where_and"]: {
	commenter?: ResolverInputTypes["Comment_commenter_operator"] | undefined | null,
	content?: ResolverInputTypes["Comment_content_operator"] | undefined | null,
	image?: ResolverInputTypes["Comment_image_operator"] | undefined | null,
	target?: ResolverInputTypes["Comment_target_Relation"] | undefined | null,
	parent?: ResolverInputTypes["Comment_parent_Relation"] | undefined | null,
	isReviewed?: ResolverInputTypes["Comment_isReviewed_operator"] | undefined | null,
	reviewedBy?: ResolverInputTypes["Comment_reviewedBy_operator"] | undefined | null,
	banned?: ResolverInputTypes["Comment_banned_operator"] | undefined | null,
	type?: ResolverInputTypes["Comment_type_operator"] | undefined | null,
	updatedAt?: ResolverInputTypes["Comment_updatedAt_operator"] | undefined | null,
	createdAt?: ResolverInputTypes["Comment_createdAt_operator"] | undefined | null,
	id?: ResolverInputTypes["Comment_id_operator"] | undefined | null,
	AND?: Array<ResolverInputTypes["Comment_where_and"] | undefined | null> | undefined | null,
	OR?: Array<ResolverInputTypes["Comment_where_or"] | undefined | null> | undefined | null
};
	["Comment_where_or"]: {
	commenter?: ResolverInputTypes["Comment_commenter_operator"] | undefined | null,
	content?: ResolverInputTypes["Comment_content_operator"] | undefined | null,
	image?: ResolverInputTypes["Comment_image_operator"] | undefined | null,
	target?: ResolverInputTypes["Comment_target_Relation"] | undefined | null,
	parent?: ResolverInputTypes["Comment_parent_Relation"] | undefined | null,
	isReviewed?: ResolverInputTypes["Comment_isReviewed_operator"] | undefined | null,
	reviewedBy?: ResolverInputTypes["Comment_reviewedBy_operator"] | undefined | null,
	banned?: ResolverInputTypes["Comment_banned_operator"] | undefined | null,
	type?: ResolverInputTypes["Comment_type_operator"] | undefined | null,
	updatedAt?: ResolverInputTypes["Comment_updatedAt_operator"] | undefined | null,
	createdAt?: ResolverInputTypes["Comment_createdAt_operator"] | undefined | null,
	id?: ResolverInputTypes["Comment_id_operator"] | undefined | null,
	AND?: Array<ResolverInputTypes["Comment_where_and"] | undefined | null> | undefined | null,
	OR?: Array<ResolverInputTypes["Comment_where_or"] | undefined | null> | undefined | null
};
	["countComments"]: AliasType<{
	totalDocs?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["commentsDocAccess"]: AliasType<{
	fields?:ResolverInputTypes["CommentsDocAccessFields"],
	create?:ResolverInputTypes["CommentsCreateDocAccess"],
	read?:ResolverInputTypes["CommentsReadDocAccess"],
	update?:ResolverInputTypes["CommentsUpdateDocAccess"],
	delete?:ResolverInputTypes["CommentsDeleteDocAccess"],
		__typename?: boolean | `@${string}`
}>;
	["CommentsDocAccessFields"]: AliasType<{
	commenter?:ResolverInputTypes["CommentsDocAccessFields_commenter"],
	content?:ResolverInputTypes["CommentsDocAccessFields_content"],
	image?:ResolverInputTypes["CommentsDocAccessFields_image"],
	target?:ResolverInputTypes["CommentsDocAccessFields_target"],
	parent?:ResolverInputTypes["CommentsDocAccessFields_parent"],
	isReviewed?:ResolverInputTypes["CommentsDocAccessFields_isReviewed"],
	reviewedBy?:ResolverInputTypes["CommentsDocAccessFields_reviewedBy"],
	banned?:ResolverInputTypes["CommentsDocAccessFields_banned"],
	type?:ResolverInputTypes["CommentsDocAccessFields_type"],
	updatedAt?:ResolverInputTypes["CommentsDocAccessFields_updatedAt"],
	createdAt?:ResolverInputTypes["CommentsDocAccessFields_createdAt"],
		__typename?: boolean | `@${string}`
}>;
	["CommentsDocAccessFields_commenter"]: AliasType<{
	create?:ResolverInputTypes["CommentsDocAccessFields_commenter_Create"],
	read?:ResolverInputTypes["CommentsDocAccessFields_commenter_Read"],
	update?:ResolverInputTypes["CommentsDocAccessFields_commenter_Update"],
	delete?:ResolverInputTypes["CommentsDocAccessFields_commenter_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["CommentsDocAccessFields_commenter_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CommentsDocAccessFields_commenter_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CommentsDocAccessFields_commenter_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CommentsDocAccessFields_commenter_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CommentsDocAccessFields_content"]: AliasType<{
	create?:ResolverInputTypes["CommentsDocAccessFields_content_Create"],
	read?:ResolverInputTypes["CommentsDocAccessFields_content_Read"],
	update?:ResolverInputTypes["CommentsDocAccessFields_content_Update"],
	delete?:ResolverInputTypes["CommentsDocAccessFields_content_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["CommentsDocAccessFields_content_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CommentsDocAccessFields_content_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CommentsDocAccessFields_content_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CommentsDocAccessFields_content_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CommentsDocAccessFields_image"]: AliasType<{
	create?:ResolverInputTypes["CommentsDocAccessFields_image_Create"],
	read?:ResolverInputTypes["CommentsDocAccessFields_image_Read"],
	update?:ResolverInputTypes["CommentsDocAccessFields_image_Update"],
	delete?:ResolverInputTypes["CommentsDocAccessFields_image_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["CommentsDocAccessFields_image_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CommentsDocAccessFields_image_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CommentsDocAccessFields_image_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CommentsDocAccessFields_image_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CommentsDocAccessFields_target"]: AliasType<{
	create?:ResolverInputTypes["CommentsDocAccessFields_target_Create"],
	read?:ResolverInputTypes["CommentsDocAccessFields_target_Read"],
	update?:ResolverInputTypes["CommentsDocAccessFields_target_Update"],
	delete?:ResolverInputTypes["CommentsDocAccessFields_target_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["CommentsDocAccessFields_target_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CommentsDocAccessFields_target_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CommentsDocAccessFields_target_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CommentsDocAccessFields_target_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CommentsDocAccessFields_parent"]: AliasType<{
	create?:ResolverInputTypes["CommentsDocAccessFields_parent_Create"],
	read?:ResolverInputTypes["CommentsDocAccessFields_parent_Read"],
	update?:ResolverInputTypes["CommentsDocAccessFields_parent_Update"],
	delete?:ResolverInputTypes["CommentsDocAccessFields_parent_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["CommentsDocAccessFields_parent_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CommentsDocAccessFields_parent_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CommentsDocAccessFields_parent_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CommentsDocAccessFields_parent_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CommentsDocAccessFields_isReviewed"]: AliasType<{
	create?:ResolverInputTypes["CommentsDocAccessFields_isReviewed_Create"],
	read?:ResolverInputTypes["CommentsDocAccessFields_isReviewed_Read"],
	update?:ResolverInputTypes["CommentsDocAccessFields_isReviewed_Update"],
	delete?:ResolverInputTypes["CommentsDocAccessFields_isReviewed_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["CommentsDocAccessFields_isReviewed_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CommentsDocAccessFields_isReviewed_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CommentsDocAccessFields_isReviewed_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CommentsDocAccessFields_isReviewed_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CommentsDocAccessFields_reviewedBy"]: AliasType<{
	create?:ResolverInputTypes["CommentsDocAccessFields_reviewedBy_Create"],
	read?:ResolverInputTypes["CommentsDocAccessFields_reviewedBy_Read"],
	update?:ResolverInputTypes["CommentsDocAccessFields_reviewedBy_Update"],
	delete?:ResolverInputTypes["CommentsDocAccessFields_reviewedBy_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["CommentsDocAccessFields_reviewedBy_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CommentsDocAccessFields_reviewedBy_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CommentsDocAccessFields_reviewedBy_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CommentsDocAccessFields_reviewedBy_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CommentsDocAccessFields_banned"]: AliasType<{
	create?:ResolverInputTypes["CommentsDocAccessFields_banned_Create"],
	read?:ResolverInputTypes["CommentsDocAccessFields_banned_Read"],
	update?:ResolverInputTypes["CommentsDocAccessFields_banned_Update"],
	delete?:ResolverInputTypes["CommentsDocAccessFields_banned_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["CommentsDocAccessFields_banned_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CommentsDocAccessFields_banned_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CommentsDocAccessFields_banned_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CommentsDocAccessFields_banned_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CommentsDocAccessFields_type"]: AliasType<{
	create?:ResolverInputTypes["CommentsDocAccessFields_type_Create"],
	read?:ResolverInputTypes["CommentsDocAccessFields_type_Read"],
	update?:ResolverInputTypes["CommentsDocAccessFields_type_Update"],
	delete?:ResolverInputTypes["CommentsDocAccessFields_type_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["CommentsDocAccessFields_type_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CommentsDocAccessFields_type_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CommentsDocAccessFields_type_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CommentsDocAccessFields_type_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CommentsDocAccessFields_updatedAt"]: AliasType<{
	create?:ResolverInputTypes["CommentsDocAccessFields_updatedAt_Create"],
	read?:ResolverInputTypes["CommentsDocAccessFields_updatedAt_Read"],
	update?:ResolverInputTypes["CommentsDocAccessFields_updatedAt_Update"],
	delete?:ResolverInputTypes["CommentsDocAccessFields_updatedAt_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["CommentsDocAccessFields_updatedAt_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CommentsDocAccessFields_updatedAt_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CommentsDocAccessFields_updatedAt_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CommentsDocAccessFields_updatedAt_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CommentsDocAccessFields_createdAt"]: AliasType<{
	create?:ResolverInputTypes["CommentsDocAccessFields_createdAt_Create"],
	read?:ResolverInputTypes["CommentsDocAccessFields_createdAt_Read"],
	update?:ResolverInputTypes["CommentsDocAccessFields_createdAt_Update"],
	delete?:ResolverInputTypes["CommentsDocAccessFields_createdAt_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["CommentsDocAccessFields_createdAt_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CommentsDocAccessFields_createdAt_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CommentsDocAccessFields_createdAt_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CommentsDocAccessFields_createdAt_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CommentsCreateDocAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CommentsReadDocAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CommentsUpdateDocAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CommentsDeleteDocAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["Subscription"]: AliasType<{
	id?:boolean | `@${string}`,
user?: [{	locale?: ResolverInputTypes["LocaleInputType"] | undefined | null,	fallbackLocale?: ResolverInputTypes["FallbackLocaleInputType"] | undefined | null},ResolverInputTypes["Subscription_User_Relationship"]],
organiser?: [{	locale?: ResolverInputTypes["LocaleInputType"] | undefined | null,	fallbackLocale?: ResolverInputTypes["FallbackLocaleInputType"] | undefined | null},ResolverInputTypes["Subscription_Organiser_Relationship"]],
	updatedAt?:boolean | `@${string}`,
	createdAt?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["Subscription_User_Relationship"]: AliasType<{
	relationTo?:boolean | `@${string}`,
	value?:ResolverInputTypes["Subscription_User"],
		__typename?: boolean | `@${string}`
}>;
	["Subscription_User_RelationTo"]:Subscription_User_RelationTo;
	["Subscription_User"]: AliasType<{
	User?:ResolverInputTypes["User"],
		__typename?: boolean | `@${string}`
}>;
	["Subscription_Organiser_Relationship"]: AliasType<{
	relationTo?:boolean | `@${string}`,
	value?:ResolverInputTypes["Subscription_Organiser"],
		__typename?: boolean | `@${string}`
}>;
	["Subscription_Organiser_RelationTo"]:Subscription_Organiser_RelationTo;
	["Subscription_Organiser"]: AliasType<{
	User?:ResolverInputTypes["User"],
		__typename?: boolean | `@${string}`
}>;
	["Subscriptions"]: AliasType<{
	docs?:ResolverInputTypes["Subscription"],
	hasNextPage?:boolean | `@${string}`,
	hasPrevPage?:boolean | `@${string}`,
	limit?:boolean | `@${string}`,
	nextPage?:boolean | `@${string}`,
	offset?:boolean | `@${string}`,
	page?:boolean | `@${string}`,
	pagingCounter?:boolean | `@${string}`,
	prevPage?:boolean | `@${string}`,
	totalDocs?:boolean | `@${string}`,
	totalPages?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["Subscription_where"]: {
	user?: ResolverInputTypes["Subscription_user_Relation"] | undefined | null,
	organiser?: ResolverInputTypes["Subscription_organiser_Relation"] | undefined | null,
	updatedAt?: ResolverInputTypes["Subscription_updatedAt_operator"] | undefined | null,
	createdAt?: ResolverInputTypes["Subscription_createdAt_operator"] | undefined | null,
	id?: ResolverInputTypes["Subscription_id_operator"] | undefined | null,
	AND?: Array<ResolverInputTypes["Subscription_where_and"] | undefined | null> | undefined | null,
	OR?: Array<ResolverInputTypes["Subscription_where_or"] | undefined | null> | undefined | null
};
	["Subscription_user_Relation"]: {
	relationTo?: ResolverInputTypes["Subscription_user_Relation_RelationTo"] | undefined | null,
	value?: ResolverInputTypes["JSON"] | undefined | null
};
	["Subscription_user_Relation_RelationTo"]:Subscription_user_Relation_RelationTo;
	["Subscription_organiser_Relation"]: {
	relationTo?: ResolverInputTypes["Subscription_organiser_Relation_RelationTo"] | undefined | null,
	value?: ResolverInputTypes["JSON"] | undefined | null
};
	["Subscription_organiser_Relation_RelationTo"]:Subscription_organiser_Relation_RelationTo;
	["Subscription_updatedAt_operator"]: {
	equals?: ResolverInputTypes["DateTime"] | undefined | null,
	not_equals?: ResolverInputTypes["DateTime"] | undefined | null,
	greater_than_equal?: ResolverInputTypes["DateTime"] | undefined | null,
	greater_than?: ResolverInputTypes["DateTime"] | undefined | null,
	less_than_equal?: ResolverInputTypes["DateTime"] | undefined | null,
	less_than?: ResolverInputTypes["DateTime"] | undefined | null,
	like?: ResolverInputTypes["DateTime"] | undefined | null,
	exists?: boolean | undefined | null
};
	["Subscription_createdAt_operator"]: {
	equals?: ResolverInputTypes["DateTime"] | undefined | null,
	not_equals?: ResolverInputTypes["DateTime"] | undefined | null,
	greater_than_equal?: ResolverInputTypes["DateTime"] | undefined | null,
	greater_than?: ResolverInputTypes["DateTime"] | undefined | null,
	less_than_equal?: ResolverInputTypes["DateTime"] | undefined | null,
	less_than?: ResolverInputTypes["DateTime"] | undefined | null,
	like?: ResolverInputTypes["DateTime"] | undefined | null,
	exists?: boolean | undefined | null
};
	["Subscription_id_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Subscription_where_and"]: {
	user?: ResolverInputTypes["Subscription_user_Relation"] | undefined | null,
	organiser?: ResolverInputTypes["Subscription_organiser_Relation"] | undefined | null,
	updatedAt?: ResolverInputTypes["Subscription_updatedAt_operator"] | undefined | null,
	createdAt?: ResolverInputTypes["Subscription_createdAt_operator"] | undefined | null,
	id?: ResolverInputTypes["Subscription_id_operator"] | undefined | null,
	AND?: Array<ResolverInputTypes["Subscription_where_and"] | undefined | null> | undefined | null,
	OR?: Array<ResolverInputTypes["Subscription_where_or"] | undefined | null> | undefined | null
};
	["Subscription_where_or"]: {
	user?: ResolverInputTypes["Subscription_user_Relation"] | undefined | null,
	organiser?: ResolverInputTypes["Subscription_organiser_Relation"] | undefined | null,
	updatedAt?: ResolverInputTypes["Subscription_updatedAt_operator"] | undefined | null,
	createdAt?: ResolverInputTypes["Subscription_createdAt_operator"] | undefined | null,
	id?: ResolverInputTypes["Subscription_id_operator"] | undefined | null,
	AND?: Array<ResolverInputTypes["Subscription_where_and"] | undefined | null> | undefined | null,
	OR?: Array<ResolverInputTypes["Subscription_where_or"] | undefined | null> | undefined | null
};
	["countSubscriptions"]: AliasType<{
	totalDocs?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["subscriptionsDocAccess"]: AliasType<{
	fields?:ResolverInputTypes["SubscriptionsDocAccessFields"],
	create?:ResolverInputTypes["SubscriptionsCreateDocAccess"],
	read?:ResolverInputTypes["SubscriptionsReadDocAccess"],
	update?:ResolverInputTypes["SubscriptionsUpdateDocAccess"],
	delete?:ResolverInputTypes["SubscriptionsDeleteDocAccess"],
		__typename?: boolean | `@${string}`
}>;
	["SubscriptionsDocAccessFields"]: AliasType<{
	user?:ResolverInputTypes["SubscriptionsDocAccessFields_user"],
	organiser?:ResolverInputTypes["SubscriptionsDocAccessFields_organiser"],
	updatedAt?:ResolverInputTypes["SubscriptionsDocAccessFields_updatedAt"],
	createdAt?:ResolverInputTypes["SubscriptionsDocAccessFields_createdAt"],
		__typename?: boolean | `@${string}`
}>;
	["SubscriptionsDocAccessFields_user"]: AliasType<{
	create?:ResolverInputTypes["SubscriptionsDocAccessFields_user_Create"],
	read?:ResolverInputTypes["SubscriptionsDocAccessFields_user_Read"],
	update?:ResolverInputTypes["SubscriptionsDocAccessFields_user_Update"],
	delete?:ResolverInputTypes["SubscriptionsDocAccessFields_user_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["SubscriptionsDocAccessFields_user_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["SubscriptionsDocAccessFields_user_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["SubscriptionsDocAccessFields_user_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["SubscriptionsDocAccessFields_user_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["SubscriptionsDocAccessFields_organiser"]: AliasType<{
	create?:ResolverInputTypes["SubscriptionsDocAccessFields_organiser_Create"],
	read?:ResolverInputTypes["SubscriptionsDocAccessFields_organiser_Read"],
	update?:ResolverInputTypes["SubscriptionsDocAccessFields_organiser_Update"],
	delete?:ResolverInputTypes["SubscriptionsDocAccessFields_organiser_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["SubscriptionsDocAccessFields_organiser_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["SubscriptionsDocAccessFields_organiser_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["SubscriptionsDocAccessFields_organiser_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["SubscriptionsDocAccessFields_organiser_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["SubscriptionsDocAccessFields_updatedAt"]: AliasType<{
	create?:ResolverInputTypes["SubscriptionsDocAccessFields_updatedAt_Create"],
	read?:ResolverInputTypes["SubscriptionsDocAccessFields_updatedAt_Read"],
	update?:ResolverInputTypes["SubscriptionsDocAccessFields_updatedAt_Update"],
	delete?:ResolverInputTypes["SubscriptionsDocAccessFields_updatedAt_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["SubscriptionsDocAccessFields_updatedAt_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["SubscriptionsDocAccessFields_updatedAt_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["SubscriptionsDocAccessFields_updatedAt_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["SubscriptionsDocAccessFields_updatedAt_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["SubscriptionsDocAccessFields_createdAt"]: AliasType<{
	create?:ResolverInputTypes["SubscriptionsDocAccessFields_createdAt_Create"],
	read?:ResolverInputTypes["SubscriptionsDocAccessFields_createdAt_Read"],
	update?:ResolverInputTypes["SubscriptionsDocAccessFields_createdAt_Update"],
	delete?:ResolverInputTypes["SubscriptionsDocAccessFields_createdAt_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["SubscriptionsDocAccessFields_createdAt_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["SubscriptionsDocAccessFields_createdAt_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["SubscriptionsDocAccessFields_createdAt_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["SubscriptionsDocAccessFields_createdAt_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["SubscriptionsCreateDocAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["SubscriptionsReadDocAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["SubscriptionsUpdateDocAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["SubscriptionsDeleteDocAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["Newsletter"]: AliasType<{
	id?:boolean | `@${string}`,
	email?:boolean | `@${string}`,
	region?:boolean | `@${string}`,
	updatedAt?:boolean | `@${string}`,
	createdAt?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["Newsletter_region"]:Newsletter_region;
	["Newsletters"]: AliasType<{
	docs?:ResolverInputTypes["Newsletter"],
	hasNextPage?:boolean | `@${string}`,
	hasPrevPage?:boolean | `@${string}`,
	limit?:boolean | `@${string}`,
	nextPage?:boolean | `@${string}`,
	offset?:boolean | `@${string}`,
	page?:boolean | `@${string}`,
	pagingCounter?:boolean | `@${string}`,
	prevPage?:boolean | `@${string}`,
	totalDocs?:boolean | `@${string}`,
	totalPages?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["Newsletter_where"]: {
	email?: ResolverInputTypes["Newsletter_email_operator"] | undefined | null,
	region?: ResolverInputTypes["Newsletter_region_operator"] | undefined | null,
	updatedAt?: ResolverInputTypes["Newsletter_updatedAt_operator"] | undefined | null,
	createdAt?: ResolverInputTypes["Newsletter_createdAt_operator"] | undefined | null,
	id?: ResolverInputTypes["Newsletter_id_operator"] | undefined | null,
	AND?: Array<ResolverInputTypes["Newsletter_where_and"] | undefined | null> | undefined | null,
	OR?: Array<ResolverInputTypes["Newsletter_where_or"] | undefined | null> | undefined | null
};
	["Newsletter_email_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null
};
	["Newsletter_region_operator"]: {
	equals?: ResolverInputTypes["Newsletter_region_Input"] | undefined | null,
	not_equals?: ResolverInputTypes["Newsletter_region_Input"] | undefined | null,
	in?: Array<ResolverInputTypes["Newsletter_region_Input"] | undefined | null> | undefined | null,
	not_in?: Array<ResolverInputTypes["Newsletter_region_Input"] | undefined | null> | undefined | null,
	all?: Array<ResolverInputTypes["Newsletter_region_Input"] | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Newsletter_region_Input"]:Newsletter_region_Input;
	["Newsletter_updatedAt_operator"]: {
	equals?: ResolverInputTypes["DateTime"] | undefined | null,
	not_equals?: ResolverInputTypes["DateTime"] | undefined | null,
	greater_than_equal?: ResolverInputTypes["DateTime"] | undefined | null,
	greater_than?: ResolverInputTypes["DateTime"] | undefined | null,
	less_than_equal?: ResolverInputTypes["DateTime"] | undefined | null,
	less_than?: ResolverInputTypes["DateTime"] | undefined | null,
	like?: ResolverInputTypes["DateTime"] | undefined | null,
	exists?: boolean | undefined | null
};
	["Newsletter_createdAt_operator"]: {
	equals?: ResolverInputTypes["DateTime"] | undefined | null,
	not_equals?: ResolverInputTypes["DateTime"] | undefined | null,
	greater_than_equal?: ResolverInputTypes["DateTime"] | undefined | null,
	greater_than?: ResolverInputTypes["DateTime"] | undefined | null,
	less_than_equal?: ResolverInputTypes["DateTime"] | undefined | null,
	less_than?: ResolverInputTypes["DateTime"] | undefined | null,
	like?: ResolverInputTypes["DateTime"] | undefined | null,
	exists?: boolean | undefined | null
};
	["Newsletter_id_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Newsletter_where_and"]: {
	email?: ResolverInputTypes["Newsletter_email_operator"] | undefined | null,
	region?: ResolverInputTypes["Newsletter_region_operator"] | undefined | null,
	updatedAt?: ResolverInputTypes["Newsletter_updatedAt_operator"] | undefined | null,
	createdAt?: ResolverInputTypes["Newsletter_createdAt_operator"] | undefined | null,
	id?: ResolverInputTypes["Newsletter_id_operator"] | undefined | null,
	AND?: Array<ResolverInputTypes["Newsletter_where_and"] | undefined | null> | undefined | null,
	OR?: Array<ResolverInputTypes["Newsletter_where_or"] | undefined | null> | undefined | null
};
	["Newsletter_where_or"]: {
	email?: ResolverInputTypes["Newsletter_email_operator"] | undefined | null,
	region?: ResolverInputTypes["Newsletter_region_operator"] | undefined | null,
	updatedAt?: ResolverInputTypes["Newsletter_updatedAt_operator"] | undefined | null,
	createdAt?: ResolverInputTypes["Newsletter_createdAt_operator"] | undefined | null,
	id?: ResolverInputTypes["Newsletter_id_operator"] | undefined | null,
	AND?: Array<ResolverInputTypes["Newsletter_where_and"] | undefined | null> | undefined | null,
	OR?: Array<ResolverInputTypes["Newsletter_where_or"] | undefined | null> | undefined | null
};
	["countNewsletters"]: AliasType<{
	totalDocs?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["newsletterDocAccess"]: AliasType<{
	fields?:ResolverInputTypes["NewsletterDocAccessFields"],
	create?:ResolverInputTypes["NewsletterCreateDocAccess"],
	read?:ResolverInputTypes["NewsletterReadDocAccess"],
	update?:ResolverInputTypes["NewsletterUpdateDocAccess"],
	delete?:ResolverInputTypes["NewsletterDeleteDocAccess"],
		__typename?: boolean | `@${string}`
}>;
	["NewsletterDocAccessFields"]: AliasType<{
	email?:ResolverInputTypes["NewsletterDocAccessFields_email"],
	region?:ResolverInputTypes["NewsletterDocAccessFields_region"],
	updatedAt?:ResolverInputTypes["NewsletterDocAccessFields_updatedAt"],
	createdAt?:ResolverInputTypes["NewsletterDocAccessFields_createdAt"],
		__typename?: boolean | `@${string}`
}>;
	["NewsletterDocAccessFields_email"]: AliasType<{
	create?:ResolverInputTypes["NewsletterDocAccessFields_email_Create"],
	read?:ResolverInputTypes["NewsletterDocAccessFields_email_Read"],
	update?:ResolverInputTypes["NewsletterDocAccessFields_email_Update"],
	delete?:ResolverInputTypes["NewsletterDocAccessFields_email_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["NewsletterDocAccessFields_email_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["NewsletterDocAccessFields_email_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["NewsletterDocAccessFields_email_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["NewsletterDocAccessFields_email_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["NewsletterDocAccessFields_region"]: AliasType<{
	create?:ResolverInputTypes["NewsletterDocAccessFields_region_Create"],
	read?:ResolverInputTypes["NewsletterDocAccessFields_region_Read"],
	update?:ResolverInputTypes["NewsletterDocAccessFields_region_Update"],
	delete?:ResolverInputTypes["NewsletterDocAccessFields_region_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["NewsletterDocAccessFields_region_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["NewsletterDocAccessFields_region_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["NewsletterDocAccessFields_region_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["NewsletterDocAccessFields_region_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["NewsletterDocAccessFields_updatedAt"]: AliasType<{
	create?:ResolverInputTypes["NewsletterDocAccessFields_updatedAt_Create"],
	read?:ResolverInputTypes["NewsletterDocAccessFields_updatedAt_Read"],
	update?:ResolverInputTypes["NewsletterDocAccessFields_updatedAt_Update"],
	delete?:ResolverInputTypes["NewsletterDocAccessFields_updatedAt_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["NewsletterDocAccessFields_updatedAt_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["NewsletterDocAccessFields_updatedAt_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["NewsletterDocAccessFields_updatedAt_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["NewsletterDocAccessFields_updatedAt_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["NewsletterDocAccessFields_createdAt"]: AliasType<{
	create?:ResolverInputTypes["NewsletterDocAccessFields_createdAt_Create"],
	read?:ResolverInputTypes["NewsletterDocAccessFields_createdAt_Read"],
	update?:ResolverInputTypes["NewsletterDocAccessFields_createdAt_Update"],
	delete?:ResolverInputTypes["NewsletterDocAccessFields_createdAt_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["NewsletterDocAccessFields_createdAt_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["NewsletterDocAccessFields_createdAt_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["NewsletterDocAccessFields_createdAt_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["NewsletterDocAccessFields_createdAt_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["NewsletterCreateDocAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["NewsletterReadDocAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["NewsletterUpdateDocAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["NewsletterDeleteDocAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BlogCategories"]: AliasType<{
	docs?:ResolverInputTypes["BlogCategory"],
	hasNextPage?:boolean | `@${string}`,
	hasPrevPage?:boolean | `@${string}`,
	limit?:boolean | `@${string}`,
	nextPage?:boolean | `@${string}`,
	offset?:boolean | `@${string}`,
	page?:boolean | `@${string}`,
	pagingCounter?:boolean | `@${string}`,
	prevPage?:boolean | `@${string}`,
	totalDocs?:boolean | `@${string}`,
	totalPages?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BlogCategory_where"]: {
	name?: ResolverInputTypes["BlogCategory_name_operator"] | undefined | null,
	value?: ResolverInputTypes["BlogCategory_value_operator"] | undefined | null,
	icon?: ResolverInputTypes["BlogCategory_icon_operator"] | undefined | null,
	region?: ResolverInputTypes["BlogCategory_region_operator"] | undefined | null,
	updatedAt?: ResolverInputTypes["BlogCategory_updatedAt_operator"] | undefined | null,
	createdAt?: ResolverInputTypes["BlogCategory_createdAt_operator"] | undefined | null,
	id?: ResolverInputTypes["BlogCategory_id_operator"] | undefined | null,
	AND?: Array<ResolverInputTypes["BlogCategory_where_and"] | undefined | null> | undefined | null,
	OR?: Array<ResolverInputTypes["BlogCategory_where_or"] | undefined | null> | undefined | null
};
	["BlogCategory_name_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null
};
	["BlogCategory_value_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null
};
	["BlogCategory_icon_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	exists?: boolean | undefined | null
};
	["BlogCategory_region_operator"]: {
	equals?: ResolverInputTypes["BlogCategory_region_Input"] | undefined | null,
	not_equals?: ResolverInputTypes["BlogCategory_region_Input"] | undefined | null,
	in?: Array<ResolverInputTypes["BlogCategory_region_Input"] | undefined | null> | undefined | null,
	not_in?: Array<ResolverInputTypes["BlogCategory_region_Input"] | undefined | null> | undefined | null,
	all?: Array<ResolverInputTypes["BlogCategory_region_Input"] | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["BlogCategory_region_Input"]:BlogCategory_region_Input;
	["BlogCategory_updatedAt_operator"]: {
	equals?: ResolverInputTypes["DateTime"] | undefined | null,
	not_equals?: ResolverInputTypes["DateTime"] | undefined | null,
	greater_than_equal?: ResolverInputTypes["DateTime"] | undefined | null,
	greater_than?: ResolverInputTypes["DateTime"] | undefined | null,
	less_than_equal?: ResolverInputTypes["DateTime"] | undefined | null,
	less_than?: ResolverInputTypes["DateTime"] | undefined | null,
	like?: ResolverInputTypes["DateTime"] | undefined | null,
	exists?: boolean | undefined | null
};
	["BlogCategory_createdAt_operator"]: {
	equals?: ResolverInputTypes["DateTime"] | undefined | null,
	not_equals?: ResolverInputTypes["DateTime"] | undefined | null,
	greater_than_equal?: ResolverInputTypes["DateTime"] | undefined | null,
	greater_than?: ResolverInputTypes["DateTime"] | undefined | null,
	less_than_equal?: ResolverInputTypes["DateTime"] | undefined | null,
	less_than?: ResolverInputTypes["DateTime"] | undefined | null,
	like?: ResolverInputTypes["DateTime"] | undefined | null,
	exists?: boolean | undefined | null
};
	["BlogCategory_id_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["BlogCategory_where_and"]: {
	name?: ResolverInputTypes["BlogCategory_name_operator"] | undefined | null,
	value?: ResolverInputTypes["BlogCategory_value_operator"] | undefined | null,
	icon?: ResolverInputTypes["BlogCategory_icon_operator"] | undefined | null,
	region?: ResolverInputTypes["BlogCategory_region_operator"] | undefined | null,
	updatedAt?: ResolverInputTypes["BlogCategory_updatedAt_operator"] | undefined | null,
	createdAt?: ResolverInputTypes["BlogCategory_createdAt_operator"] | undefined | null,
	id?: ResolverInputTypes["BlogCategory_id_operator"] | undefined | null,
	AND?: Array<ResolverInputTypes["BlogCategory_where_and"] | undefined | null> | undefined | null,
	OR?: Array<ResolverInputTypes["BlogCategory_where_or"] | undefined | null> | undefined | null
};
	["BlogCategory_where_or"]: {
	name?: ResolverInputTypes["BlogCategory_name_operator"] | undefined | null,
	value?: ResolverInputTypes["BlogCategory_value_operator"] | undefined | null,
	icon?: ResolverInputTypes["BlogCategory_icon_operator"] | undefined | null,
	region?: ResolverInputTypes["BlogCategory_region_operator"] | undefined | null,
	updatedAt?: ResolverInputTypes["BlogCategory_updatedAt_operator"] | undefined | null,
	createdAt?: ResolverInputTypes["BlogCategory_createdAt_operator"] | undefined | null,
	id?: ResolverInputTypes["BlogCategory_id_operator"] | undefined | null,
	AND?: Array<ResolverInputTypes["BlogCategory_where_and"] | undefined | null> | undefined | null,
	OR?: Array<ResolverInputTypes["BlogCategory_where_or"] | undefined | null> | undefined | null
};
	["countBlogCategories"]: AliasType<{
	totalDocs?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["blog_categoriesDocAccess"]: AliasType<{
	fields?:ResolverInputTypes["BlogCategoriesDocAccessFields"],
	create?:ResolverInputTypes["BlogCategoriesCreateDocAccess"],
	read?:ResolverInputTypes["BlogCategoriesReadDocAccess"],
	update?:ResolverInputTypes["BlogCategoriesUpdateDocAccess"],
	delete?:ResolverInputTypes["BlogCategoriesDeleteDocAccess"],
		__typename?: boolean | `@${string}`
}>;
	["BlogCategoriesDocAccessFields"]: AliasType<{
	name?:ResolverInputTypes["BlogCategoriesDocAccessFields_name"],
	value?:ResolverInputTypes["BlogCategoriesDocAccessFields_value"],
	icon?:ResolverInputTypes["BlogCategoriesDocAccessFields_icon"],
	region?:ResolverInputTypes["BlogCategoriesDocAccessFields_region"],
	updatedAt?:ResolverInputTypes["BlogCategoriesDocAccessFields_updatedAt"],
	createdAt?:ResolverInputTypes["BlogCategoriesDocAccessFields_createdAt"],
		__typename?: boolean | `@${string}`
}>;
	["BlogCategoriesDocAccessFields_name"]: AliasType<{
	create?:ResolverInputTypes["BlogCategoriesDocAccessFields_name_Create"],
	read?:ResolverInputTypes["BlogCategoriesDocAccessFields_name_Read"],
	update?:ResolverInputTypes["BlogCategoriesDocAccessFields_name_Update"],
	delete?:ResolverInputTypes["BlogCategoriesDocAccessFields_name_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["BlogCategoriesDocAccessFields_name_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BlogCategoriesDocAccessFields_name_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BlogCategoriesDocAccessFields_name_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BlogCategoriesDocAccessFields_name_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BlogCategoriesDocAccessFields_value"]: AliasType<{
	create?:ResolverInputTypes["BlogCategoriesDocAccessFields_value_Create"],
	read?:ResolverInputTypes["BlogCategoriesDocAccessFields_value_Read"],
	update?:ResolverInputTypes["BlogCategoriesDocAccessFields_value_Update"],
	delete?:ResolverInputTypes["BlogCategoriesDocAccessFields_value_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["BlogCategoriesDocAccessFields_value_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BlogCategoriesDocAccessFields_value_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BlogCategoriesDocAccessFields_value_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BlogCategoriesDocAccessFields_value_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BlogCategoriesDocAccessFields_icon"]: AliasType<{
	create?:ResolverInputTypes["BlogCategoriesDocAccessFields_icon_Create"],
	read?:ResolverInputTypes["BlogCategoriesDocAccessFields_icon_Read"],
	update?:ResolverInputTypes["BlogCategoriesDocAccessFields_icon_Update"],
	delete?:ResolverInputTypes["BlogCategoriesDocAccessFields_icon_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["BlogCategoriesDocAccessFields_icon_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BlogCategoriesDocAccessFields_icon_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BlogCategoriesDocAccessFields_icon_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BlogCategoriesDocAccessFields_icon_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BlogCategoriesDocAccessFields_region"]: AliasType<{
	create?:ResolverInputTypes["BlogCategoriesDocAccessFields_region_Create"],
	read?:ResolverInputTypes["BlogCategoriesDocAccessFields_region_Read"],
	update?:ResolverInputTypes["BlogCategoriesDocAccessFields_region_Update"],
	delete?:ResolverInputTypes["BlogCategoriesDocAccessFields_region_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["BlogCategoriesDocAccessFields_region_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BlogCategoriesDocAccessFields_region_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BlogCategoriesDocAccessFields_region_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BlogCategoriesDocAccessFields_region_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BlogCategoriesDocAccessFields_updatedAt"]: AliasType<{
	create?:ResolverInputTypes["BlogCategoriesDocAccessFields_updatedAt_Create"],
	read?:ResolverInputTypes["BlogCategoriesDocAccessFields_updatedAt_Read"],
	update?:ResolverInputTypes["BlogCategoriesDocAccessFields_updatedAt_Update"],
	delete?:ResolverInputTypes["BlogCategoriesDocAccessFields_updatedAt_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["BlogCategoriesDocAccessFields_updatedAt_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BlogCategoriesDocAccessFields_updatedAt_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BlogCategoriesDocAccessFields_updatedAt_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BlogCategoriesDocAccessFields_updatedAt_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BlogCategoriesDocAccessFields_createdAt"]: AliasType<{
	create?:ResolverInputTypes["BlogCategoriesDocAccessFields_createdAt_Create"],
	read?:ResolverInputTypes["BlogCategoriesDocAccessFields_createdAt_Read"],
	update?:ResolverInputTypes["BlogCategoriesDocAccessFields_createdAt_Update"],
	delete?:ResolverInputTypes["BlogCategoriesDocAccessFields_createdAt_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["BlogCategoriesDocAccessFields_createdAt_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BlogCategoriesDocAccessFields_createdAt_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BlogCategoriesDocAccessFields_createdAt_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BlogCategoriesDocAccessFields_createdAt_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BlogCategoriesCreateDocAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BlogCategoriesReadDocAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BlogCategoriesUpdateDocAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BlogCategoriesDeleteDocAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["Tags"]: AliasType<{
	docs?:ResolverInputTypes["Tag"],
	hasNextPage?:boolean | `@${string}`,
	hasPrevPage?:boolean | `@${string}`,
	limit?:boolean | `@${string}`,
	nextPage?:boolean | `@${string}`,
	offset?:boolean | `@${string}`,
	page?:boolean | `@${string}`,
	pagingCounter?:boolean | `@${string}`,
	prevPage?:boolean | `@${string}`,
	totalDocs?:boolean | `@${string}`,
	totalPages?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["Tag_where"]: {
	name?: ResolverInputTypes["Tag_name_operator"] | undefined | null,
	value?: ResolverInputTypes["Tag_value_operator"] | undefined | null,
	region?: ResolverInputTypes["Tag_region_operator"] | undefined | null,
	count?: ResolverInputTypes["Tag_count_operator"] | undefined | null,
	skipCountCheck?: ResolverInputTypes["Tag_skipCountCheck_operator"] | undefined | null,
	removeNextCron?: ResolverInputTypes["Tag_removeNextCron_operator"] | undefined | null,
	id?: ResolverInputTypes["Tag_id_operator"] | undefined | null,
	AND?: Array<ResolverInputTypes["Tag_where_and"] | undefined | null> | undefined | null,
	OR?: Array<ResolverInputTypes["Tag_where_or"] | undefined | null> | undefined | null
};
	["Tag_name_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null
};
	["Tag_value_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null
};
	["Tag_region_operator"]: {
	equals?: ResolverInputTypes["Tag_region_Input"] | undefined | null,
	not_equals?: ResolverInputTypes["Tag_region_Input"] | undefined | null,
	in?: Array<ResolverInputTypes["Tag_region_Input"] | undefined | null> | undefined | null,
	not_in?: Array<ResolverInputTypes["Tag_region_Input"] | undefined | null> | undefined | null,
	all?: Array<ResolverInputTypes["Tag_region_Input"] | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Tag_region_Input"]:Tag_region_Input;
	["Tag_count_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Tag_skipCountCheck_operator"]: {
	equals?: boolean | undefined | null,
	not_equals?: boolean | undefined | null,
	exists?: boolean | undefined | null
};
	["Tag_removeNextCron_operator"]: {
	equals?: boolean | undefined | null,
	not_equals?: boolean | undefined | null,
	exists?: boolean | undefined | null
};
	["Tag_id_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Tag_where_and"]: {
	name?: ResolverInputTypes["Tag_name_operator"] | undefined | null,
	value?: ResolverInputTypes["Tag_value_operator"] | undefined | null,
	region?: ResolverInputTypes["Tag_region_operator"] | undefined | null,
	count?: ResolverInputTypes["Tag_count_operator"] | undefined | null,
	skipCountCheck?: ResolverInputTypes["Tag_skipCountCheck_operator"] | undefined | null,
	removeNextCron?: ResolverInputTypes["Tag_removeNextCron_operator"] | undefined | null,
	id?: ResolverInputTypes["Tag_id_operator"] | undefined | null,
	AND?: Array<ResolverInputTypes["Tag_where_and"] | undefined | null> | undefined | null,
	OR?: Array<ResolverInputTypes["Tag_where_or"] | undefined | null> | undefined | null
};
	["Tag_where_or"]: {
	name?: ResolverInputTypes["Tag_name_operator"] | undefined | null,
	value?: ResolverInputTypes["Tag_value_operator"] | undefined | null,
	region?: ResolverInputTypes["Tag_region_operator"] | undefined | null,
	count?: ResolverInputTypes["Tag_count_operator"] | undefined | null,
	skipCountCheck?: ResolverInputTypes["Tag_skipCountCheck_operator"] | undefined | null,
	removeNextCron?: ResolverInputTypes["Tag_removeNextCron_operator"] | undefined | null,
	id?: ResolverInputTypes["Tag_id_operator"] | undefined | null,
	AND?: Array<ResolverInputTypes["Tag_where_and"] | undefined | null> | undefined | null,
	OR?: Array<ResolverInputTypes["Tag_where_or"] | undefined | null> | undefined | null
};
	["countTags"]: AliasType<{
	totalDocs?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["tagsDocAccess"]: AliasType<{
	fields?:ResolverInputTypes["TagsDocAccessFields"],
	create?:ResolverInputTypes["TagsCreateDocAccess"],
	read?:ResolverInputTypes["TagsReadDocAccess"],
	update?:ResolverInputTypes["TagsUpdateDocAccess"],
	delete?:ResolverInputTypes["TagsDeleteDocAccess"],
		__typename?: boolean | `@${string}`
}>;
	["TagsDocAccessFields"]: AliasType<{
	name?:ResolverInputTypes["TagsDocAccessFields_name"],
	value?:ResolverInputTypes["TagsDocAccessFields_value"],
	region?:ResolverInputTypes["TagsDocAccessFields_region"],
	count?:ResolverInputTypes["TagsDocAccessFields_count"],
	skipCountCheck?:ResolverInputTypes["TagsDocAccessFields_skipCountCheck"],
	removeNextCron?:ResolverInputTypes["TagsDocAccessFields_removeNextCron"],
		__typename?: boolean | `@${string}`
}>;
	["TagsDocAccessFields_name"]: AliasType<{
	create?:ResolverInputTypes["TagsDocAccessFields_name_Create"],
	read?:ResolverInputTypes["TagsDocAccessFields_name_Read"],
	update?:ResolverInputTypes["TagsDocAccessFields_name_Update"],
	delete?:ResolverInputTypes["TagsDocAccessFields_name_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["TagsDocAccessFields_name_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TagsDocAccessFields_name_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TagsDocAccessFields_name_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TagsDocAccessFields_name_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TagsDocAccessFields_value"]: AliasType<{
	create?:ResolverInputTypes["TagsDocAccessFields_value_Create"],
	read?:ResolverInputTypes["TagsDocAccessFields_value_Read"],
	update?:ResolverInputTypes["TagsDocAccessFields_value_Update"],
	delete?:ResolverInputTypes["TagsDocAccessFields_value_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["TagsDocAccessFields_value_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TagsDocAccessFields_value_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TagsDocAccessFields_value_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TagsDocAccessFields_value_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TagsDocAccessFields_region"]: AliasType<{
	create?:ResolverInputTypes["TagsDocAccessFields_region_Create"],
	read?:ResolverInputTypes["TagsDocAccessFields_region_Read"],
	update?:ResolverInputTypes["TagsDocAccessFields_region_Update"],
	delete?:ResolverInputTypes["TagsDocAccessFields_region_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["TagsDocAccessFields_region_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TagsDocAccessFields_region_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TagsDocAccessFields_region_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TagsDocAccessFields_region_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TagsDocAccessFields_count"]: AliasType<{
	create?:ResolverInputTypes["TagsDocAccessFields_count_Create"],
	read?:ResolverInputTypes["TagsDocAccessFields_count_Read"],
	update?:ResolverInputTypes["TagsDocAccessFields_count_Update"],
	delete?:ResolverInputTypes["TagsDocAccessFields_count_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["TagsDocAccessFields_count_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TagsDocAccessFields_count_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TagsDocAccessFields_count_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TagsDocAccessFields_count_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TagsDocAccessFields_skipCountCheck"]: AliasType<{
	create?:ResolverInputTypes["TagsDocAccessFields_skipCountCheck_Create"],
	read?:ResolverInputTypes["TagsDocAccessFields_skipCountCheck_Read"],
	update?:ResolverInputTypes["TagsDocAccessFields_skipCountCheck_Update"],
	delete?:ResolverInputTypes["TagsDocAccessFields_skipCountCheck_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["TagsDocAccessFields_skipCountCheck_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TagsDocAccessFields_skipCountCheck_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TagsDocAccessFields_skipCountCheck_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TagsDocAccessFields_skipCountCheck_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TagsDocAccessFields_removeNextCron"]: AliasType<{
	create?:ResolverInputTypes["TagsDocAccessFields_removeNextCron_Create"],
	read?:ResolverInputTypes["TagsDocAccessFields_removeNextCron_Read"],
	update?:ResolverInputTypes["TagsDocAccessFields_removeNextCron_Update"],
	delete?:ResolverInputTypes["TagsDocAccessFields_removeNextCron_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["TagsDocAccessFields_removeNextCron_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TagsDocAccessFields_removeNextCron_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TagsDocAccessFields_removeNextCron_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TagsDocAccessFields_removeNextCron_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TagsCreateDocAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TagsReadDocAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TagsUpdateDocAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TagsDeleteDocAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["SpecialTags"]: AliasType<{
	docs?:ResolverInputTypes["SpecialTag"],
	hasNextPage?:boolean | `@${string}`,
	hasPrevPage?:boolean | `@${string}`,
	limit?:boolean | `@${string}`,
	nextPage?:boolean | `@${string}`,
	offset?:boolean | `@${string}`,
	page?:boolean | `@${string}`,
	pagingCounter?:boolean | `@${string}`,
	prevPage?:boolean | `@${string}`,
	totalDocs?:boolean | `@${string}`,
	totalPages?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["SpecialTag_where"]: {
	name?: ResolverInputTypes["SpecialTag_name_operator"] | undefined | null,
	value?: ResolverInputTypes["SpecialTag_value_operator"] | undefined | null,
	region?: ResolverInputTypes["SpecialTag_region_operator"] | undefined | null,
	id?: ResolverInputTypes["SpecialTag_id_operator"] | undefined | null,
	AND?: Array<ResolverInputTypes["SpecialTag_where_and"] | undefined | null> | undefined | null,
	OR?: Array<ResolverInputTypes["SpecialTag_where_or"] | undefined | null> | undefined | null
};
	["SpecialTag_name_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null
};
	["SpecialTag_value_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null
};
	["SpecialTag_region_operator"]: {
	equals?: ResolverInputTypes["SpecialTag_region_Input"] | undefined | null,
	not_equals?: ResolverInputTypes["SpecialTag_region_Input"] | undefined | null,
	in?: Array<ResolverInputTypes["SpecialTag_region_Input"] | undefined | null> | undefined | null,
	not_in?: Array<ResolverInputTypes["SpecialTag_region_Input"] | undefined | null> | undefined | null,
	all?: Array<ResolverInputTypes["SpecialTag_region_Input"] | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["SpecialTag_region_Input"]:SpecialTag_region_Input;
	["SpecialTag_id_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["SpecialTag_where_and"]: {
	name?: ResolverInputTypes["SpecialTag_name_operator"] | undefined | null,
	value?: ResolverInputTypes["SpecialTag_value_operator"] | undefined | null,
	region?: ResolverInputTypes["SpecialTag_region_operator"] | undefined | null,
	id?: ResolverInputTypes["SpecialTag_id_operator"] | undefined | null,
	AND?: Array<ResolverInputTypes["SpecialTag_where_and"] | undefined | null> | undefined | null,
	OR?: Array<ResolverInputTypes["SpecialTag_where_or"] | undefined | null> | undefined | null
};
	["SpecialTag_where_or"]: {
	name?: ResolverInputTypes["SpecialTag_name_operator"] | undefined | null,
	value?: ResolverInputTypes["SpecialTag_value_operator"] | undefined | null,
	region?: ResolverInputTypes["SpecialTag_region_operator"] | undefined | null,
	id?: ResolverInputTypes["SpecialTag_id_operator"] | undefined | null,
	AND?: Array<ResolverInputTypes["SpecialTag_where_and"] | undefined | null> | undefined | null,
	OR?: Array<ResolverInputTypes["SpecialTag_where_or"] | undefined | null> | undefined | null
};
	["countSpecialTags"]: AliasType<{
	totalDocs?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["specialTagsDocAccess"]: AliasType<{
	fields?:ResolverInputTypes["SpecialTagsDocAccessFields"],
	create?:ResolverInputTypes["SpecialTagsCreateDocAccess"],
	read?:ResolverInputTypes["SpecialTagsReadDocAccess"],
	update?:ResolverInputTypes["SpecialTagsUpdateDocAccess"],
	delete?:ResolverInputTypes["SpecialTagsDeleteDocAccess"],
		__typename?: boolean | `@${string}`
}>;
	["SpecialTagsDocAccessFields"]: AliasType<{
	name?:ResolverInputTypes["SpecialTagsDocAccessFields_name"],
	value?:ResolverInputTypes["SpecialTagsDocAccessFields_value"],
	region?:ResolverInputTypes["SpecialTagsDocAccessFields_region"],
		__typename?: boolean | `@${string}`
}>;
	["SpecialTagsDocAccessFields_name"]: AliasType<{
	create?:ResolverInputTypes["SpecialTagsDocAccessFields_name_Create"],
	read?:ResolverInputTypes["SpecialTagsDocAccessFields_name_Read"],
	update?:ResolverInputTypes["SpecialTagsDocAccessFields_name_Update"],
	delete?:ResolverInputTypes["SpecialTagsDocAccessFields_name_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["SpecialTagsDocAccessFields_name_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["SpecialTagsDocAccessFields_name_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["SpecialTagsDocAccessFields_name_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["SpecialTagsDocAccessFields_name_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["SpecialTagsDocAccessFields_value"]: AliasType<{
	create?:ResolverInputTypes["SpecialTagsDocAccessFields_value_Create"],
	read?:ResolverInputTypes["SpecialTagsDocAccessFields_value_Read"],
	update?:ResolverInputTypes["SpecialTagsDocAccessFields_value_Update"],
	delete?:ResolverInputTypes["SpecialTagsDocAccessFields_value_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["SpecialTagsDocAccessFields_value_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["SpecialTagsDocAccessFields_value_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["SpecialTagsDocAccessFields_value_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["SpecialTagsDocAccessFields_value_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["SpecialTagsDocAccessFields_region"]: AliasType<{
	create?:ResolverInputTypes["SpecialTagsDocAccessFields_region_Create"],
	read?:ResolverInputTypes["SpecialTagsDocAccessFields_region_Read"],
	update?:ResolverInputTypes["SpecialTagsDocAccessFields_region_Update"],
	delete?:ResolverInputTypes["SpecialTagsDocAccessFields_region_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["SpecialTagsDocAccessFields_region_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["SpecialTagsDocAccessFields_region_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["SpecialTagsDocAccessFields_region_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["SpecialTagsDocAccessFields_region_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["SpecialTagsCreateDocAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["SpecialTagsReadDocAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["SpecialTagsUpdateDocAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["SpecialTagsDeleteDocAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["Dates"]: AliasType<{
	docs?:ResolverInputTypes["Date"],
	hasNextPage?:boolean | `@${string}`,
	hasPrevPage?:boolean | `@${string}`,
	limit?:boolean | `@${string}`,
	nextPage?:boolean | `@${string}`,
	offset?:boolean | `@${string}`,
	page?:boolean | `@${string}`,
	pagingCounter?:boolean | `@${string}`,
	prevPage?:boolean | `@${string}`,
	totalDocs?:boolean | `@${string}`,
	totalPages?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["Date_where"]: {
	name?: ResolverInputTypes["Date_name_operator"] | undefined | null,
	value?: ResolverInputTypes["Date_value_operator"] | undefined | null,
	type?: ResolverInputTypes["Date_type_operator"] | undefined | null,
	start?: ResolverInputTypes["Date_start_operator"] | undefined | null,
	end?: ResolverInputTypes["Date_end_operator"] | undefined | null,
	_title?: ResolverInputTypes["Date__title_operator"] | undefined | null,
	region?: ResolverInputTypes["Date_region_operator"] | undefined | null,
	updatedAt?: ResolverInputTypes["Date_updatedAt_operator"] | undefined | null,
	createdAt?: ResolverInputTypes["Date_createdAt_operator"] | undefined | null,
	id?: ResolverInputTypes["Date_id_operator"] | undefined | null,
	AND?: Array<ResolverInputTypes["Date_where_and"] | undefined | null> | undefined | null,
	OR?: Array<ResolverInputTypes["Date_where_or"] | undefined | null> | undefined | null
};
	["Date_name_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null
};
	["Date_value_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null
};
	["Date_type_operator"]: {
	equals?: ResolverInputTypes["Date_type_Input"] | undefined | null,
	not_equals?: ResolverInputTypes["Date_type_Input"] | undefined | null,
	in?: Array<ResolverInputTypes["Date_type_Input"] | undefined | null> | undefined | null,
	not_in?: Array<ResolverInputTypes["Date_type_Input"] | undefined | null> | undefined | null,
	all?: Array<ResolverInputTypes["Date_type_Input"] | undefined | null> | undefined | null
};
	["Date_type_Input"]:Date_type_Input;
	["Date_start_operator"]: {
	equals?: ResolverInputTypes["DateTime"] | undefined | null,
	not_equals?: ResolverInputTypes["DateTime"] | undefined | null,
	greater_than_equal?: ResolverInputTypes["DateTime"] | undefined | null,
	greater_than?: ResolverInputTypes["DateTime"] | undefined | null,
	less_than_equal?: ResolverInputTypes["DateTime"] | undefined | null,
	less_than?: ResolverInputTypes["DateTime"] | undefined | null,
	like?: ResolverInputTypes["DateTime"] | undefined | null,
	exists?: boolean | undefined | null
};
	["Date_end_operator"]: {
	equals?: ResolverInputTypes["DateTime"] | undefined | null,
	not_equals?: ResolverInputTypes["DateTime"] | undefined | null,
	greater_than_equal?: ResolverInputTypes["DateTime"] | undefined | null,
	greater_than?: ResolverInputTypes["DateTime"] | undefined | null,
	less_than_equal?: ResolverInputTypes["DateTime"] | undefined | null,
	less_than?: ResolverInputTypes["DateTime"] | undefined | null,
	like?: ResolverInputTypes["DateTime"] | undefined | null,
	exists?: boolean | undefined | null
};
	["Date__title_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Date_region_operator"]: {
	equals?: ResolverInputTypes["Date_region_Input"] | undefined | null,
	not_equals?: ResolverInputTypes["Date_region_Input"] | undefined | null,
	in?: Array<ResolverInputTypes["Date_region_Input"] | undefined | null> | undefined | null,
	not_in?: Array<ResolverInputTypes["Date_region_Input"] | undefined | null> | undefined | null,
	all?: Array<ResolverInputTypes["Date_region_Input"] | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Date_region_Input"]:Date_region_Input;
	["Date_updatedAt_operator"]: {
	equals?: ResolverInputTypes["DateTime"] | undefined | null,
	not_equals?: ResolverInputTypes["DateTime"] | undefined | null,
	greater_than_equal?: ResolverInputTypes["DateTime"] | undefined | null,
	greater_than?: ResolverInputTypes["DateTime"] | undefined | null,
	less_than_equal?: ResolverInputTypes["DateTime"] | undefined | null,
	less_than?: ResolverInputTypes["DateTime"] | undefined | null,
	like?: ResolverInputTypes["DateTime"] | undefined | null,
	exists?: boolean | undefined | null
};
	["Date_createdAt_operator"]: {
	equals?: ResolverInputTypes["DateTime"] | undefined | null,
	not_equals?: ResolverInputTypes["DateTime"] | undefined | null,
	greater_than_equal?: ResolverInputTypes["DateTime"] | undefined | null,
	greater_than?: ResolverInputTypes["DateTime"] | undefined | null,
	less_than_equal?: ResolverInputTypes["DateTime"] | undefined | null,
	less_than?: ResolverInputTypes["DateTime"] | undefined | null,
	like?: ResolverInputTypes["DateTime"] | undefined | null,
	exists?: boolean | undefined | null
};
	["Date_id_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Date_where_and"]: {
	name?: ResolverInputTypes["Date_name_operator"] | undefined | null,
	value?: ResolverInputTypes["Date_value_operator"] | undefined | null,
	type?: ResolverInputTypes["Date_type_operator"] | undefined | null,
	start?: ResolverInputTypes["Date_start_operator"] | undefined | null,
	end?: ResolverInputTypes["Date_end_operator"] | undefined | null,
	_title?: ResolverInputTypes["Date__title_operator"] | undefined | null,
	region?: ResolverInputTypes["Date_region_operator"] | undefined | null,
	updatedAt?: ResolverInputTypes["Date_updatedAt_operator"] | undefined | null,
	createdAt?: ResolverInputTypes["Date_createdAt_operator"] | undefined | null,
	id?: ResolverInputTypes["Date_id_operator"] | undefined | null,
	AND?: Array<ResolverInputTypes["Date_where_and"] | undefined | null> | undefined | null,
	OR?: Array<ResolverInputTypes["Date_where_or"] | undefined | null> | undefined | null
};
	["Date_where_or"]: {
	name?: ResolverInputTypes["Date_name_operator"] | undefined | null,
	value?: ResolverInputTypes["Date_value_operator"] | undefined | null,
	type?: ResolverInputTypes["Date_type_operator"] | undefined | null,
	start?: ResolverInputTypes["Date_start_operator"] | undefined | null,
	end?: ResolverInputTypes["Date_end_operator"] | undefined | null,
	_title?: ResolverInputTypes["Date__title_operator"] | undefined | null,
	region?: ResolverInputTypes["Date_region_operator"] | undefined | null,
	updatedAt?: ResolverInputTypes["Date_updatedAt_operator"] | undefined | null,
	createdAt?: ResolverInputTypes["Date_createdAt_operator"] | undefined | null,
	id?: ResolverInputTypes["Date_id_operator"] | undefined | null,
	AND?: Array<ResolverInputTypes["Date_where_and"] | undefined | null> | undefined | null,
	OR?: Array<ResolverInputTypes["Date_where_or"] | undefined | null> | undefined | null
};
	["countDates"]: AliasType<{
	totalDocs?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["dateDocAccess"]: AliasType<{
	fields?:ResolverInputTypes["DateDocAccessFields"],
	create?:ResolverInputTypes["DateCreateDocAccess"],
	read?:ResolverInputTypes["DateReadDocAccess"],
	update?:ResolverInputTypes["DateUpdateDocAccess"],
	delete?:ResolverInputTypes["DateDeleteDocAccess"],
		__typename?: boolean | `@${string}`
}>;
	["DateDocAccessFields"]: AliasType<{
	name?:ResolverInputTypes["DateDocAccessFields_name"],
	value?:ResolverInputTypes["DateDocAccessFields_value"],
	type?:ResolverInputTypes["DateDocAccessFields_type"],
	start?:ResolverInputTypes["DateDocAccessFields_start"],
	end?:ResolverInputTypes["DateDocAccessFields_end"],
	_title?:ResolverInputTypes["DateDocAccessFields__title"],
	region?:ResolverInputTypes["DateDocAccessFields_region"],
	updatedAt?:ResolverInputTypes["DateDocAccessFields_updatedAt"],
	createdAt?:ResolverInputTypes["DateDocAccessFields_createdAt"],
		__typename?: boolean | `@${string}`
}>;
	["DateDocAccessFields_name"]: AliasType<{
	create?:ResolverInputTypes["DateDocAccessFields_name_Create"],
	read?:ResolverInputTypes["DateDocAccessFields_name_Read"],
	update?:ResolverInputTypes["DateDocAccessFields_name_Update"],
	delete?:ResolverInputTypes["DateDocAccessFields_name_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["DateDocAccessFields_name_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DateDocAccessFields_name_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DateDocAccessFields_name_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DateDocAccessFields_name_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DateDocAccessFields_value"]: AliasType<{
	create?:ResolverInputTypes["DateDocAccessFields_value_Create"],
	read?:ResolverInputTypes["DateDocAccessFields_value_Read"],
	update?:ResolverInputTypes["DateDocAccessFields_value_Update"],
	delete?:ResolverInputTypes["DateDocAccessFields_value_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["DateDocAccessFields_value_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DateDocAccessFields_value_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DateDocAccessFields_value_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DateDocAccessFields_value_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DateDocAccessFields_type"]: AliasType<{
	create?:ResolverInputTypes["DateDocAccessFields_type_Create"],
	read?:ResolverInputTypes["DateDocAccessFields_type_Read"],
	update?:ResolverInputTypes["DateDocAccessFields_type_Update"],
	delete?:ResolverInputTypes["DateDocAccessFields_type_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["DateDocAccessFields_type_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DateDocAccessFields_type_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DateDocAccessFields_type_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DateDocAccessFields_type_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DateDocAccessFields_start"]: AliasType<{
	create?:ResolverInputTypes["DateDocAccessFields_start_Create"],
	read?:ResolverInputTypes["DateDocAccessFields_start_Read"],
	update?:ResolverInputTypes["DateDocAccessFields_start_Update"],
	delete?:ResolverInputTypes["DateDocAccessFields_start_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["DateDocAccessFields_start_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DateDocAccessFields_start_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DateDocAccessFields_start_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DateDocAccessFields_start_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DateDocAccessFields_end"]: AliasType<{
	create?:ResolverInputTypes["DateDocAccessFields_end_Create"],
	read?:ResolverInputTypes["DateDocAccessFields_end_Read"],
	update?:ResolverInputTypes["DateDocAccessFields_end_Update"],
	delete?:ResolverInputTypes["DateDocAccessFields_end_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["DateDocAccessFields_end_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DateDocAccessFields_end_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DateDocAccessFields_end_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DateDocAccessFields_end_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DateDocAccessFields__title"]: AliasType<{
	create?:ResolverInputTypes["DateDocAccessFields__title_Create"],
	read?:ResolverInputTypes["DateDocAccessFields__title_Read"],
	update?:ResolverInputTypes["DateDocAccessFields__title_Update"],
	delete?:ResolverInputTypes["DateDocAccessFields__title_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["DateDocAccessFields__title_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DateDocAccessFields__title_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DateDocAccessFields__title_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DateDocAccessFields__title_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DateDocAccessFields_region"]: AliasType<{
	create?:ResolverInputTypes["DateDocAccessFields_region_Create"],
	read?:ResolverInputTypes["DateDocAccessFields_region_Read"],
	update?:ResolverInputTypes["DateDocAccessFields_region_Update"],
	delete?:ResolverInputTypes["DateDocAccessFields_region_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["DateDocAccessFields_region_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DateDocAccessFields_region_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DateDocAccessFields_region_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DateDocAccessFields_region_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DateDocAccessFields_updatedAt"]: AliasType<{
	create?:ResolverInputTypes["DateDocAccessFields_updatedAt_Create"],
	read?:ResolverInputTypes["DateDocAccessFields_updatedAt_Read"],
	update?:ResolverInputTypes["DateDocAccessFields_updatedAt_Update"],
	delete?:ResolverInputTypes["DateDocAccessFields_updatedAt_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["DateDocAccessFields_updatedAt_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DateDocAccessFields_updatedAt_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DateDocAccessFields_updatedAt_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DateDocAccessFields_updatedAt_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DateDocAccessFields_createdAt"]: AliasType<{
	create?:ResolverInputTypes["DateDocAccessFields_createdAt_Create"],
	read?:ResolverInputTypes["DateDocAccessFields_createdAt_Read"],
	update?:ResolverInputTypes["DateDocAccessFields_createdAt_Update"],
	delete?:ResolverInputTypes["DateDocAccessFields_createdAt_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["DateDocAccessFields_createdAt_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DateDocAccessFields_createdAt_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DateDocAccessFields_createdAt_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DateDocAccessFields_createdAt_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DateCreateDocAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DateReadDocAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DateUpdateDocAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DateDeleteDocAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["Districts"]: AliasType<{
	docs?:ResolverInputTypes["District"],
	hasNextPage?:boolean | `@${string}`,
	hasPrevPage?:boolean | `@${string}`,
	limit?:boolean | `@${string}`,
	nextPage?:boolean | `@${string}`,
	offset?:boolean | `@${string}`,
	page?:boolean | `@${string}`,
	pagingCounter?:boolean | `@${string}`,
	prevPage?:boolean | `@${string}`,
	totalDocs?:boolean | `@${string}`,
	totalPages?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["District_where"]: {
	name?: ResolverInputTypes["District_name_operator"] | undefined | null,
	level?: ResolverInputTypes["District_level_operator"] | undefined | null,
	children?: ResolverInputTypes["District_children_operator"] | undefined | null,
	value?: ResolverInputTypes["District_value_operator"] | undefined | null,
	lat?: ResolverInputTypes["District_lat_operator"] | undefined | null,
	long?: ResolverInputTypes["District_long_operator"] | undefined | null,
	radius?: ResolverInputTypes["District_radius_operator"] | undefined | null,
	region?: ResolverInputTypes["District_region_operator"] | undefined | null,
	updatedAt?: ResolverInputTypes["District_updatedAt_operator"] | undefined | null,
	createdAt?: ResolverInputTypes["District_createdAt_operator"] | undefined | null,
	id?: ResolverInputTypes["District_id_operator"] | undefined | null,
	AND?: Array<ResolverInputTypes["District_where_and"] | undefined | null> | undefined | null,
	OR?: Array<ResolverInputTypes["District_where_or"] | undefined | null> | undefined | null
};
	["District_name_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null
};
	["District_level_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null
};
	["District_children_operator"]: {
	equals?: ResolverInputTypes["JSON"] | undefined | null,
	not_equals?: ResolverInputTypes["JSON"] | undefined | null,
	in?: Array<ResolverInputTypes["JSON"] | undefined | null> | undefined | null,
	not_in?: Array<ResolverInputTypes["JSON"] | undefined | null> | undefined | null,
	all?: Array<ResolverInputTypes["JSON"] | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["District_value_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null
};
	["District_lat_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["District_long_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["District_radius_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["District_region_operator"]: {
	equals?: ResolverInputTypes["District_region_Input"] | undefined | null,
	not_equals?: ResolverInputTypes["District_region_Input"] | undefined | null,
	in?: Array<ResolverInputTypes["District_region_Input"] | undefined | null> | undefined | null,
	not_in?: Array<ResolverInputTypes["District_region_Input"] | undefined | null> | undefined | null,
	all?: Array<ResolverInputTypes["District_region_Input"] | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["District_region_Input"]:District_region_Input;
	["District_updatedAt_operator"]: {
	equals?: ResolverInputTypes["DateTime"] | undefined | null,
	not_equals?: ResolverInputTypes["DateTime"] | undefined | null,
	greater_than_equal?: ResolverInputTypes["DateTime"] | undefined | null,
	greater_than?: ResolverInputTypes["DateTime"] | undefined | null,
	less_than_equal?: ResolverInputTypes["DateTime"] | undefined | null,
	less_than?: ResolverInputTypes["DateTime"] | undefined | null,
	like?: ResolverInputTypes["DateTime"] | undefined | null,
	exists?: boolean | undefined | null
};
	["District_createdAt_operator"]: {
	equals?: ResolverInputTypes["DateTime"] | undefined | null,
	not_equals?: ResolverInputTypes["DateTime"] | undefined | null,
	greater_than_equal?: ResolverInputTypes["DateTime"] | undefined | null,
	greater_than?: ResolverInputTypes["DateTime"] | undefined | null,
	less_than_equal?: ResolverInputTypes["DateTime"] | undefined | null,
	less_than?: ResolverInputTypes["DateTime"] | undefined | null,
	like?: ResolverInputTypes["DateTime"] | undefined | null,
	exists?: boolean | undefined | null
};
	["District_id_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["District_where_and"]: {
	name?: ResolverInputTypes["District_name_operator"] | undefined | null,
	level?: ResolverInputTypes["District_level_operator"] | undefined | null,
	children?: ResolverInputTypes["District_children_operator"] | undefined | null,
	value?: ResolverInputTypes["District_value_operator"] | undefined | null,
	lat?: ResolverInputTypes["District_lat_operator"] | undefined | null,
	long?: ResolverInputTypes["District_long_operator"] | undefined | null,
	radius?: ResolverInputTypes["District_radius_operator"] | undefined | null,
	region?: ResolverInputTypes["District_region_operator"] | undefined | null,
	updatedAt?: ResolverInputTypes["District_updatedAt_operator"] | undefined | null,
	createdAt?: ResolverInputTypes["District_createdAt_operator"] | undefined | null,
	id?: ResolverInputTypes["District_id_operator"] | undefined | null,
	AND?: Array<ResolverInputTypes["District_where_and"] | undefined | null> | undefined | null,
	OR?: Array<ResolverInputTypes["District_where_or"] | undefined | null> | undefined | null
};
	["District_where_or"]: {
	name?: ResolverInputTypes["District_name_operator"] | undefined | null,
	level?: ResolverInputTypes["District_level_operator"] | undefined | null,
	children?: ResolverInputTypes["District_children_operator"] | undefined | null,
	value?: ResolverInputTypes["District_value_operator"] | undefined | null,
	lat?: ResolverInputTypes["District_lat_operator"] | undefined | null,
	long?: ResolverInputTypes["District_long_operator"] | undefined | null,
	radius?: ResolverInputTypes["District_radius_operator"] | undefined | null,
	region?: ResolverInputTypes["District_region_operator"] | undefined | null,
	updatedAt?: ResolverInputTypes["District_updatedAt_operator"] | undefined | null,
	createdAt?: ResolverInputTypes["District_createdAt_operator"] | undefined | null,
	id?: ResolverInputTypes["District_id_operator"] | undefined | null,
	AND?: Array<ResolverInputTypes["District_where_and"] | undefined | null> | undefined | null,
	OR?: Array<ResolverInputTypes["District_where_or"] | undefined | null> | undefined | null
};
	["countDistricts"]: AliasType<{
	totalDocs?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["districtsDocAccess"]: AliasType<{
	fields?:ResolverInputTypes["DistrictsDocAccessFields"],
	create?:ResolverInputTypes["DistrictsCreateDocAccess"],
	read?:ResolverInputTypes["DistrictsReadDocAccess"],
	update?:ResolverInputTypes["DistrictsUpdateDocAccess"],
	delete?:ResolverInputTypes["DistrictsDeleteDocAccess"],
		__typename?: boolean | `@${string}`
}>;
	["DistrictsDocAccessFields"]: AliasType<{
	name?:ResolverInputTypes["DistrictsDocAccessFields_name"],
	level?:ResolverInputTypes["DistrictsDocAccessFields_level"],
	children?:ResolverInputTypes["DistrictsDocAccessFields_children"],
	value?:ResolverInputTypes["DistrictsDocAccessFields_value"],
	lat?:ResolverInputTypes["DistrictsDocAccessFields_lat"],
	long?:ResolverInputTypes["DistrictsDocAccessFields_long"],
	radius?:ResolverInputTypes["DistrictsDocAccessFields_radius"],
	region?:ResolverInputTypes["DistrictsDocAccessFields_region"],
	updatedAt?:ResolverInputTypes["DistrictsDocAccessFields_updatedAt"],
	createdAt?:ResolverInputTypes["DistrictsDocAccessFields_createdAt"],
		__typename?: boolean | `@${string}`
}>;
	["DistrictsDocAccessFields_name"]: AliasType<{
	create?:ResolverInputTypes["DistrictsDocAccessFields_name_Create"],
	read?:ResolverInputTypes["DistrictsDocAccessFields_name_Read"],
	update?:ResolverInputTypes["DistrictsDocAccessFields_name_Update"],
	delete?:ResolverInputTypes["DistrictsDocAccessFields_name_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["DistrictsDocAccessFields_name_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DistrictsDocAccessFields_name_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DistrictsDocAccessFields_name_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DistrictsDocAccessFields_name_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DistrictsDocAccessFields_level"]: AliasType<{
	create?:ResolverInputTypes["DistrictsDocAccessFields_level_Create"],
	read?:ResolverInputTypes["DistrictsDocAccessFields_level_Read"],
	update?:ResolverInputTypes["DistrictsDocAccessFields_level_Update"],
	delete?:ResolverInputTypes["DistrictsDocAccessFields_level_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["DistrictsDocAccessFields_level_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DistrictsDocAccessFields_level_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DistrictsDocAccessFields_level_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DistrictsDocAccessFields_level_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DistrictsDocAccessFields_children"]: AliasType<{
	create?:ResolverInputTypes["DistrictsDocAccessFields_children_Create"],
	read?:ResolverInputTypes["DistrictsDocAccessFields_children_Read"],
	update?:ResolverInputTypes["DistrictsDocAccessFields_children_Update"],
	delete?:ResolverInputTypes["DistrictsDocAccessFields_children_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["DistrictsDocAccessFields_children_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DistrictsDocAccessFields_children_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DistrictsDocAccessFields_children_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DistrictsDocAccessFields_children_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DistrictsDocAccessFields_value"]: AliasType<{
	create?:ResolverInputTypes["DistrictsDocAccessFields_value_Create"],
	read?:ResolverInputTypes["DistrictsDocAccessFields_value_Read"],
	update?:ResolverInputTypes["DistrictsDocAccessFields_value_Update"],
	delete?:ResolverInputTypes["DistrictsDocAccessFields_value_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["DistrictsDocAccessFields_value_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DistrictsDocAccessFields_value_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DistrictsDocAccessFields_value_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DistrictsDocAccessFields_value_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DistrictsDocAccessFields_lat"]: AliasType<{
	create?:ResolverInputTypes["DistrictsDocAccessFields_lat_Create"],
	read?:ResolverInputTypes["DistrictsDocAccessFields_lat_Read"],
	update?:ResolverInputTypes["DistrictsDocAccessFields_lat_Update"],
	delete?:ResolverInputTypes["DistrictsDocAccessFields_lat_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["DistrictsDocAccessFields_lat_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DistrictsDocAccessFields_lat_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DistrictsDocAccessFields_lat_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DistrictsDocAccessFields_lat_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DistrictsDocAccessFields_long"]: AliasType<{
	create?:ResolverInputTypes["DistrictsDocAccessFields_long_Create"],
	read?:ResolverInputTypes["DistrictsDocAccessFields_long_Read"],
	update?:ResolverInputTypes["DistrictsDocAccessFields_long_Update"],
	delete?:ResolverInputTypes["DistrictsDocAccessFields_long_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["DistrictsDocAccessFields_long_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DistrictsDocAccessFields_long_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DistrictsDocAccessFields_long_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DistrictsDocAccessFields_long_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DistrictsDocAccessFields_radius"]: AliasType<{
	create?:ResolverInputTypes["DistrictsDocAccessFields_radius_Create"],
	read?:ResolverInputTypes["DistrictsDocAccessFields_radius_Read"],
	update?:ResolverInputTypes["DistrictsDocAccessFields_radius_Update"],
	delete?:ResolverInputTypes["DistrictsDocAccessFields_radius_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["DistrictsDocAccessFields_radius_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DistrictsDocAccessFields_radius_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DistrictsDocAccessFields_radius_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DistrictsDocAccessFields_radius_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DistrictsDocAccessFields_region"]: AliasType<{
	create?:ResolverInputTypes["DistrictsDocAccessFields_region_Create"],
	read?:ResolverInputTypes["DistrictsDocAccessFields_region_Read"],
	update?:ResolverInputTypes["DistrictsDocAccessFields_region_Update"],
	delete?:ResolverInputTypes["DistrictsDocAccessFields_region_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["DistrictsDocAccessFields_region_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DistrictsDocAccessFields_region_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DistrictsDocAccessFields_region_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DistrictsDocAccessFields_region_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DistrictsDocAccessFields_updatedAt"]: AliasType<{
	create?:ResolverInputTypes["DistrictsDocAccessFields_updatedAt_Create"],
	read?:ResolverInputTypes["DistrictsDocAccessFields_updatedAt_Read"],
	update?:ResolverInputTypes["DistrictsDocAccessFields_updatedAt_Update"],
	delete?:ResolverInputTypes["DistrictsDocAccessFields_updatedAt_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["DistrictsDocAccessFields_updatedAt_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DistrictsDocAccessFields_updatedAt_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DistrictsDocAccessFields_updatedAt_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DistrictsDocAccessFields_updatedAt_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DistrictsDocAccessFields_createdAt"]: AliasType<{
	create?:ResolverInputTypes["DistrictsDocAccessFields_createdAt_Create"],
	read?:ResolverInputTypes["DistrictsDocAccessFields_createdAt_Read"],
	update?:ResolverInputTypes["DistrictsDocAccessFields_createdAt_Update"],
	delete?:ResolverInputTypes["DistrictsDocAccessFields_createdAt_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["DistrictsDocAccessFields_createdAt_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DistrictsDocAccessFields_createdAt_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DistrictsDocAccessFields_createdAt_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DistrictsDocAccessFields_createdAt_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DistrictsCreateDocAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DistrictsReadDocAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DistrictsUpdateDocAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DistrictsDeleteDocAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["Audiences"]: AliasType<{
	docs?:ResolverInputTypes["Audience"],
	hasNextPage?:boolean | `@${string}`,
	hasPrevPage?:boolean | `@${string}`,
	limit?:boolean | `@${string}`,
	nextPage?:boolean | `@${string}`,
	offset?:boolean | `@${string}`,
	page?:boolean | `@${string}`,
	pagingCounter?:boolean | `@${string}`,
	prevPage?:boolean | `@${string}`,
	totalDocs?:boolean | `@${string}`,
	totalPages?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["Audience_where"]: {
	name?: ResolverInputTypes["Audience_name_operator"] | undefined | null,
	value?: ResolverInputTypes["Audience_value_operator"] | undefined | null,
	icon?: ResolverInputTypes["Audience_icon_operator"] | undefined | null,
	region?: ResolverInputTypes["Audience_region_operator"] | undefined | null,
	updatedAt?: ResolverInputTypes["Audience_updatedAt_operator"] | undefined | null,
	createdAt?: ResolverInputTypes["Audience_createdAt_operator"] | undefined | null,
	id?: ResolverInputTypes["Audience_id_operator"] | undefined | null,
	AND?: Array<ResolverInputTypes["Audience_where_and"] | undefined | null> | undefined | null,
	OR?: Array<ResolverInputTypes["Audience_where_or"] | undefined | null> | undefined | null
};
	["Audience_name_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null
};
	["Audience_value_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null
};
	["Audience_icon_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	exists?: boolean | undefined | null
};
	["Audience_region_operator"]: {
	equals?: ResolverInputTypes["Audience_region_Input"] | undefined | null,
	not_equals?: ResolverInputTypes["Audience_region_Input"] | undefined | null,
	in?: Array<ResolverInputTypes["Audience_region_Input"] | undefined | null> | undefined | null,
	not_in?: Array<ResolverInputTypes["Audience_region_Input"] | undefined | null> | undefined | null,
	all?: Array<ResolverInputTypes["Audience_region_Input"] | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Audience_region_Input"]:Audience_region_Input;
	["Audience_updatedAt_operator"]: {
	equals?: ResolverInputTypes["DateTime"] | undefined | null,
	not_equals?: ResolverInputTypes["DateTime"] | undefined | null,
	greater_than_equal?: ResolverInputTypes["DateTime"] | undefined | null,
	greater_than?: ResolverInputTypes["DateTime"] | undefined | null,
	less_than_equal?: ResolverInputTypes["DateTime"] | undefined | null,
	less_than?: ResolverInputTypes["DateTime"] | undefined | null,
	like?: ResolverInputTypes["DateTime"] | undefined | null,
	exists?: boolean | undefined | null
};
	["Audience_createdAt_operator"]: {
	equals?: ResolverInputTypes["DateTime"] | undefined | null,
	not_equals?: ResolverInputTypes["DateTime"] | undefined | null,
	greater_than_equal?: ResolverInputTypes["DateTime"] | undefined | null,
	greater_than?: ResolverInputTypes["DateTime"] | undefined | null,
	less_than_equal?: ResolverInputTypes["DateTime"] | undefined | null,
	less_than?: ResolverInputTypes["DateTime"] | undefined | null,
	like?: ResolverInputTypes["DateTime"] | undefined | null,
	exists?: boolean | undefined | null
};
	["Audience_id_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Audience_where_and"]: {
	name?: ResolverInputTypes["Audience_name_operator"] | undefined | null,
	value?: ResolverInputTypes["Audience_value_operator"] | undefined | null,
	icon?: ResolverInputTypes["Audience_icon_operator"] | undefined | null,
	region?: ResolverInputTypes["Audience_region_operator"] | undefined | null,
	updatedAt?: ResolverInputTypes["Audience_updatedAt_operator"] | undefined | null,
	createdAt?: ResolverInputTypes["Audience_createdAt_operator"] | undefined | null,
	id?: ResolverInputTypes["Audience_id_operator"] | undefined | null,
	AND?: Array<ResolverInputTypes["Audience_where_and"] | undefined | null> | undefined | null,
	OR?: Array<ResolverInputTypes["Audience_where_or"] | undefined | null> | undefined | null
};
	["Audience_where_or"]: {
	name?: ResolverInputTypes["Audience_name_operator"] | undefined | null,
	value?: ResolverInputTypes["Audience_value_operator"] | undefined | null,
	icon?: ResolverInputTypes["Audience_icon_operator"] | undefined | null,
	region?: ResolverInputTypes["Audience_region_operator"] | undefined | null,
	updatedAt?: ResolverInputTypes["Audience_updatedAt_operator"] | undefined | null,
	createdAt?: ResolverInputTypes["Audience_createdAt_operator"] | undefined | null,
	id?: ResolverInputTypes["Audience_id_operator"] | undefined | null,
	AND?: Array<ResolverInputTypes["Audience_where_and"] | undefined | null> | undefined | null,
	OR?: Array<ResolverInputTypes["Audience_where_or"] | undefined | null> | undefined | null
};
	["countAudiences"]: AliasType<{
	totalDocs?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["audienceDocAccess"]: AliasType<{
	fields?:ResolverInputTypes["AudienceDocAccessFields"],
	create?:ResolverInputTypes["AudienceCreateDocAccess"],
	read?:ResolverInputTypes["AudienceReadDocAccess"],
	update?:ResolverInputTypes["AudienceUpdateDocAccess"],
	delete?:ResolverInputTypes["AudienceDeleteDocAccess"],
		__typename?: boolean | `@${string}`
}>;
	["AudienceDocAccessFields"]: AliasType<{
	name?:ResolverInputTypes["AudienceDocAccessFields_name"],
	value?:ResolverInputTypes["AudienceDocAccessFields_value"],
	icon?:ResolverInputTypes["AudienceDocAccessFields_icon"],
	region?:ResolverInputTypes["AudienceDocAccessFields_region"],
	updatedAt?:ResolverInputTypes["AudienceDocAccessFields_updatedAt"],
	createdAt?:ResolverInputTypes["AudienceDocAccessFields_createdAt"],
		__typename?: boolean | `@${string}`
}>;
	["AudienceDocAccessFields_name"]: AliasType<{
	create?:ResolverInputTypes["AudienceDocAccessFields_name_Create"],
	read?:ResolverInputTypes["AudienceDocAccessFields_name_Read"],
	update?:ResolverInputTypes["AudienceDocAccessFields_name_Update"],
	delete?:ResolverInputTypes["AudienceDocAccessFields_name_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["AudienceDocAccessFields_name_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AudienceDocAccessFields_name_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AudienceDocAccessFields_name_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AudienceDocAccessFields_name_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AudienceDocAccessFields_value"]: AliasType<{
	create?:ResolverInputTypes["AudienceDocAccessFields_value_Create"],
	read?:ResolverInputTypes["AudienceDocAccessFields_value_Read"],
	update?:ResolverInputTypes["AudienceDocAccessFields_value_Update"],
	delete?:ResolverInputTypes["AudienceDocAccessFields_value_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["AudienceDocAccessFields_value_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AudienceDocAccessFields_value_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AudienceDocAccessFields_value_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AudienceDocAccessFields_value_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AudienceDocAccessFields_icon"]: AliasType<{
	create?:ResolverInputTypes["AudienceDocAccessFields_icon_Create"],
	read?:ResolverInputTypes["AudienceDocAccessFields_icon_Read"],
	update?:ResolverInputTypes["AudienceDocAccessFields_icon_Update"],
	delete?:ResolverInputTypes["AudienceDocAccessFields_icon_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["AudienceDocAccessFields_icon_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AudienceDocAccessFields_icon_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AudienceDocAccessFields_icon_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AudienceDocAccessFields_icon_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AudienceDocAccessFields_region"]: AliasType<{
	create?:ResolverInputTypes["AudienceDocAccessFields_region_Create"],
	read?:ResolverInputTypes["AudienceDocAccessFields_region_Read"],
	update?:ResolverInputTypes["AudienceDocAccessFields_region_Update"],
	delete?:ResolverInputTypes["AudienceDocAccessFields_region_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["AudienceDocAccessFields_region_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AudienceDocAccessFields_region_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AudienceDocAccessFields_region_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AudienceDocAccessFields_region_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AudienceDocAccessFields_updatedAt"]: AliasType<{
	create?:ResolverInputTypes["AudienceDocAccessFields_updatedAt_Create"],
	read?:ResolverInputTypes["AudienceDocAccessFields_updatedAt_Read"],
	update?:ResolverInputTypes["AudienceDocAccessFields_updatedAt_Update"],
	delete?:ResolverInputTypes["AudienceDocAccessFields_updatedAt_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["AudienceDocAccessFields_updatedAt_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AudienceDocAccessFields_updatedAt_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AudienceDocAccessFields_updatedAt_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AudienceDocAccessFields_updatedAt_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AudienceDocAccessFields_createdAt"]: AliasType<{
	create?:ResolverInputTypes["AudienceDocAccessFields_createdAt_Create"],
	read?:ResolverInputTypes["AudienceDocAccessFields_createdAt_Read"],
	update?:ResolverInputTypes["AudienceDocAccessFields_createdAt_Update"],
	delete?:ResolverInputTypes["AudienceDocAccessFields_createdAt_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["AudienceDocAccessFields_createdAt_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AudienceDocAccessFields_createdAt_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AudienceDocAccessFields_createdAt_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AudienceDocAccessFields_createdAt_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AudienceCreateDocAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AudienceReadDocAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AudienceUpdateDocAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AudienceDeleteDocAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventCategories"]: AliasType<{
	docs?:ResolverInputTypes["EventCategory"],
	hasNextPage?:boolean | `@${string}`,
	hasPrevPage?:boolean | `@${string}`,
	limit?:boolean | `@${string}`,
	nextPage?:boolean | `@${string}`,
	offset?:boolean | `@${string}`,
	page?:boolean | `@${string}`,
	pagingCounter?:boolean | `@${string}`,
	prevPage?:boolean | `@${string}`,
	totalDocs?:boolean | `@${string}`,
	totalPages?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventCategory_where"]: {
	name?: ResolverInputTypes["EventCategory_name_operator"] | undefined | null,
	value?: ResolverInputTypes["EventCategory_value_operator"] | undefined | null,
	tags?: ResolverInputTypes["EventCategory_tags_operator"] | undefined | null,
	icon?: ResolverInputTypes["EventCategory_icon_operator"] | undefined | null,
	region?: ResolverInputTypes["EventCategory_region_operator"] | undefined | null,
	updatedAt?: ResolverInputTypes["EventCategory_updatedAt_operator"] | undefined | null,
	createdAt?: ResolverInputTypes["EventCategory_createdAt_operator"] | undefined | null,
	id?: ResolverInputTypes["EventCategory_id_operator"] | undefined | null,
	AND?: Array<ResolverInputTypes["EventCategory_where_and"] | undefined | null> | undefined | null,
	OR?: Array<ResolverInputTypes["EventCategory_where_or"] | undefined | null> | undefined | null
};
	["EventCategory_name_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null
};
	["EventCategory_value_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null
};
	["EventCategory_tags_operator"]: {
	equals?: ResolverInputTypes["JSON"] | undefined | null,
	not_equals?: ResolverInputTypes["JSON"] | undefined | null,
	in?: Array<ResolverInputTypes["JSON"] | undefined | null> | undefined | null,
	not_in?: Array<ResolverInputTypes["JSON"] | undefined | null> | undefined | null,
	all?: Array<ResolverInputTypes["JSON"] | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["EventCategory_icon_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	exists?: boolean | undefined | null
};
	["EventCategory_region_operator"]: {
	equals?: ResolverInputTypes["EventCategory_region_Input"] | undefined | null,
	not_equals?: ResolverInputTypes["EventCategory_region_Input"] | undefined | null,
	in?: Array<ResolverInputTypes["EventCategory_region_Input"] | undefined | null> | undefined | null,
	not_in?: Array<ResolverInputTypes["EventCategory_region_Input"] | undefined | null> | undefined | null,
	all?: Array<ResolverInputTypes["EventCategory_region_Input"] | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["EventCategory_region_Input"]:EventCategory_region_Input;
	["EventCategory_updatedAt_operator"]: {
	equals?: ResolverInputTypes["DateTime"] | undefined | null,
	not_equals?: ResolverInputTypes["DateTime"] | undefined | null,
	greater_than_equal?: ResolverInputTypes["DateTime"] | undefined | null,
	greater_than?: ResolverInputTypes["DateTime"] | undefined | null,
	less_than_equal?: ResolverInputTypes["DateTime"] | undefined | null,
	less_than?: ResolverInputTypes["DateTime"] | undefined | null,
	like?: ResolverInputTypes["DateTime"] | undefined | null,
	exists?: boolean | undefined | null
};
	["EventCategory_createdAt_operator"]: {
	equals?: ResolverInputTypes["DateTime"] | undefined | null,
	not_equals?: ResolverInputTypes["DateTime"] | undefined | null,
	greater_than_equal?: ResolverInputTypes["DateTime"] | undefined | null,
	greater_than?: ResolverInputTypes["DateTime"] | undefined | null,
	less_than_equal?: ResolverInputTypes["DateTime"] | undefined | null,
	less_than?: ResolverInputTypes["DateTime"] | undefined | null,
	like?: ResolverInputTypes["DateTime"] | undefined | null,
	exists?: boolean | undefined | null
};
	["EventCategory_id_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["EventCategory_where_and"]: {
	name?: ResolverInputTypes["EventCategory_name_operator"] | undefined | null,
	value?: ResolverInputTypes["EventCategory_value_operator"] | undefined | null,
	tags?: ResolverInputTypes["EventCategory_tags_operator"] | undefined | null,
	icon?: ResolverInputTypes["EventCategory_icon_operator"] | undefined | null,
	region?: ResolverInputTypes["EventCategory_region_operator"] | undefined | null,
	updatedAt?: ResolverInputTypes["EventCategory_updatedAt_operator"] | undefined | null,
	createdAt?: ResolverInputTypes["EventCategory_createdAt_operator"] | undefined | null,
	id?: ResolverInputTypes["EventCategory_id_operator"] | undefined | null,
	AND?: Array<ResolverInputTypes["EventCategory_where_and"] | undefined | null> | undefined | null,
	OR?: Array<ResolverInputTypes["EventCategory_where_or"] | undefined | null> | undefined | null
};
	["EventCategory_where_or"]: {
	name?: ResolverInputTypes["EventCategory_name_operator"] | undefined | null,
	value?: ResolverInputTypes["EventCategory_value_operator"] | undefined | null,
	tags?: ResolverInputTypes["EventCategory_tags_operator"] | undefined | null,
	icon?: ResolverInputTypes["EventCategory_icon_operator"] | undefined | null,
	region?: ResolverInputTypes["EventCategory_region_operator"] | undefined | null,
	updatedAt?: ResolverInputTypes["EventCategory_updatedAt_operator"] | undefined | null,
	createdAt?: ResolverInputTypes["EventCategory_createdAt_operator"] | undefined | null,
	id?: ResolverInputTypes["EventCategory_id_operator"] | undefined | null,
	AND?: Array<ResolverInputTypes["EventCategory_where_and"] | undefined | null> | undefined | null,
	OR?: Array<ResolverInputTypes["EventCategory_where_or"] | undefined | null> | undefined | null
};
	["countEventCategories"]: AliasType<{
	totalDocs?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["categoryDocAccess"]: AliasType<{
	fields?:ResolverInputTypes["CategoryDocAccessFields"],
	create?:ResolverInputTypes["CategoryCreateDocAccess"],
	read?:ResolverInputTypes["CategoryReadDocAccess"],
	update?:ResolverInputTypes["CategoryUpdateDocAccess"],
	delete?:ResolverInputTypes["CategoryDeleteDocAccess"],
		__typename?: boolean | `@${string}`
}>;
	["CategoryDocAccessFields"]: AliasType<{
	name?:ResolverInputTypes["CategoryDocAccessFields_name"],
	value?:ResolverInputTypes["CategoryDocAccessFields_value"],
	tags?:ResolverInputTypes["CategoryDocAccessFields_tags"],
	icon?:ResolverInputTypes["CategoryDocAccessFields_icon"],
	region?:ResolverInputTypes["CategoryDocAccessFields_region"],
	updatedAt?:ResolverInputTypes["CategoryDocAccessFields_updatedAt"],
	createdAt?:ResolverInputTypes["CategoryDocAccessFields_createdAt"],
		__typename?: boolean | `@${string}`
}>;
	["CategoryDocAccessFields_name"]: AliasType<{
	create?:ResolverInputTypes["CategoryDocAccessFields_name_Create"],
	read?:ResolverInputTypes["CategoryDocAccessFields_name_Read"],
	update?:ResolverInputTypes["CategoryDocAccessFields_name_Update"],
	delete?:ResolverInputTypes["CategoryDocAccessFields_name_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["CategoryDocAccessFields_name_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CategoryDocAccessFields_name_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CategoryDocAccessFields_name_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CategoryDocAccessFields_name_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CategoryDocAccessFields_value"]: AliasType<{
	create?:ResolverInputTypes["CategoryDocAccessFields_value_Create"],
	read?:ResolverInputTypes["CategoryDocAccessFields_value_Read"],
	update?:ResolverInputTypes["CategoryDocAccessFields_value_Update"],
	delete?:ResolverInputTypes["CategoryDocAccessFields_value_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["CategoryDocAccessFields_value_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CategoryDocAccessFields_value_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CategoryDocAccessFields_value_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CategoryDocAccessFields_value_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CategoryDocAccessFields_tags"]: AliasType<{
	create?:ResolverInputTypes["CategoryDocAccessFields_tags_Create"],
	read?:ResolverInputTypes["CategoryDocAccessFields_tags_Read"],
	update?:ResolverInputTypes["CategoryDocAccessFields_tags_Update"],
	delete?:ResolverInputTypes["CategoryDocAccessFields_tags_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["CategoryDocAccessFields_tags_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CategoryDocAccessFields_tags_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CategoryDocAccessFields_tags_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CategoryDocAccessFields_tags_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CategoryDocAccessFields_icon"]: AliasType<{
	create?:ResolverInputTypes["CategoryDocAccessFields_icon_Create"],
	read?:ResolverInputTypes["CategoryDocAccessFields_icon_Read"],
	update?:ResolverInputTypes["CategoryDocAccessFields_icon_Update"],
	delete?:ResolverInputTypes["CategoryDocAccessFields_icon_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["CategoryDocAccessFields_icon_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CategoryDocAccessFields_icon_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CategoryDocAccessFields_icon_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CategoryDocAccessFields_icon_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CategoryDocAccessFields_region"]: AliasType<{
	create?:ResolverInputTypes["CategoryDocAccessFields_region_Create"],
	read?:ResolverInputTypes["CategoryDocAccessFields_region_Read"],
	update?:ResolverInputTypes["CategoryDocAccessFields_region_Update"],
	delete?:ResolverInputTypes["CategoryDocAccessFields_region_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["CategoryDocAccessFields_region_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CategoryDocAccessFields_region_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CategoryDocAccessFields_region_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CategoryDocAccessFields_region_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CategoryDocAccessFields_updatedAt"]: AliasType<{
	create?:ResolverInputTypes["CategoryDocAccessFields_updatedAt_Create"],
	read?:ResolverInputTypes["CategoryDocAccessFields_updatedAt_Read"],
	update?:ResolverInputTypes["CategoryDocAccessFields_updatedAt_Update"],
	delete?:ResolverInputTypes["CategoryDocAccessFields_updatedAt_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["CategoryDocAccessFields_updatedAt_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CategoryDocAccessFields_updatedAt_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CategoryDocAccessFields_updatedAt_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CategoryDocAccessFields_updatedAt_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CategoryDocAccessFields_createdAt"]: AliasType<{
	create?:ResolverInputTypes["CategoryDocAccessFields_createdAt_Create"],
	read?:ResolverInputTypes["CategoryDocAccessFields_createdAt_Read"],
	update?:ResolverInputTypes["CategoryDocAccessFields_createdAt_Update"],
	delete?:ResolverInputTypes["CategoryDocAccessFields_createdAt_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["CategoryDocAccessFields_createdAt_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CategoryDocAccessFields_createdAt_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CategoryDocAccessFields_createdAt_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CategoryDocAccessFields_createdAt_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CategoryCreateDocAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CategoryReadDocAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CategoryUpdateDocAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CategoryDeleteDocAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMedia"]: AliasType<{
	id?:boolean | `@${string}`,
	alt?:boolean | `@${string}`,
	updatedAt?:boolean | `@${string}`,
	createdAt?:boolean | `@${string}`,
	url?:boolean | `@${string}`,
	filename?:boolean | `@${string}`,
	mimeType?:boolean | `@${string}`,
	filesize?:boolean | `@${string}`,
	width?:boolean | `@${string}`,
	height?:boolean | `@${string}`,
	focalX?:boolean | `@${string}`,
	focalY?:boolean | `@${string}`,
	sizes?:ResolverInputTypes["PrivateMedia_Sizes"],
		__typename?: boolean | `@${string}`
}>;
	["PrivateMedia_Sizes"]: AliasType<{
	thumbnail?:ResolverInputTypes["PrivateMedia_Sizes_Thumbnail"],
	portrait?:ResolverInputTypes["PrivateMedia_Sizes_Portrait"],
	hero?:ResolverInputTypes["PrivateMedia_Sizes_Hero"],
	thumbnail_jpeg?:ResolverInputTypes["PrivateMedia_Sizes_Thumbnail_jpeg"],
	portrait_jpeg?:ResolverInputTypes["PrivateMedia_Sizes_Portrait_jpeg"],
	hero_jpeg?:ResolverInputTypes["PrivateMedia_Sizes_Hero_jpeg"],
		__typename?: boolean | `@${string}`
}>;
	["PrivateMedia_Sizes_Thumbnail"]: AliasType<{
	url?:boolean | `@${string}`,
	width?:boolean | `@${string}`,
	height?:boolean | `@${string}`,
	mimeType?:boolean | `@${string}`,
	filesize?:boolean | `@${string}`,
	filename?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMedia_Sizes_Portrait"]: AliasType<{
	url?:boolean | `@${string}`,
	width?:boolean | `@${string}`,
	height?:boolean | `@${string}`,
	mimeType?:boolean | `@${string}`,
	filesize?:boolean | `@${string}`,
	filename?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMedia_Sizes_Hero"]: AliasType<{
	url?:boolean | `@${string}`,
	width?:boolean | `@${string}`,
	height?:boolean | `@${string}`,
	mimeType?:boolean | `@${string}`,
	filesize?:boolean | `@${string}`,
	filename?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMedia_Sizes_Thumbnail_jpeg"]: AliasType<{
	url?:boolean | `@${string}`,
	width?:boolean | `@${string}`,
	height?:boolean | `@${string}`,
	mimeType?:boolean | `@${string}`,
	filesize?:boolean | `@${string}`,
	filename?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMedia_Sizes_Portrait_jpeg"]: AliasType<{
	url?:boolean | `@${string}`,
	width?:boolean | `@${string}`,
	height?:boolean | `@${string}`,
	mimeType?:boolean | `@${string}`,
	filesize?:boolean | `@${string}`,
	filename?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMedia_Sizes_Hero_jpeg"]: AliasType<{
	url?:boolean | `@${string}`,
	width?:boolean | `@${string}`,
	height?:boolean | `@${string}`,
	mimeType?:boolean | `@${string}`,
	filesize?:boolean | `@${string}`,
	filename?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMedias"]: AliasType<{
	docs?:ResolverInputTypes["PrivateMedia"],
	hasNextPage?:boolean | `@${string}`,
	hasPrevPage?:boolean | `@${string}`,
	limit?:boolean | `@${string}`,
	nextPage?:boolean | `@${string}`,
	offset?:boolean | `@${string}`,
	page?:boolean | `@${string}`,
	pagingCounter?:boolean | `@${string}`,
	prevPage?:boolean | `@${string}`,
	totalDocs?:boolean | `@${string}`,
	totalPages?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMedia_where"]: {
	alt?: ResolverInputTypes["PrivateMedia_alt_operator"] | undefined | null,
	updatedAt?: ResolverInputTypes["PrivateMedia_updatedAt_operator"] | undefined | null,
	createdAt?: ResolverInputTypes["PrivateMedia_createdAt_operator"] | undefined | null,
	url?: ResolverInputTypes["PrivateMedia_url_operator"] | undefined | null,
	filename?: ResolverInputTypes["PrivateMedia_filename_operator"] | undefined | null,
	mimeType?: ResolverInputTypes["PrivateMedia_mimeType_operator"] | undefined | null,
	filesize?: ResolverInputTypes["PrivateMedia_filesize_operator"] | undefined | null,
	width?: ResolverInputTypes["PrivateMedia_width_operator"] | undefined | null,
	height?: ResolverInputTypes["PrivateMedia_height_operator"] | undefined | null,
	focalX?: ResolverInputTypes["PrivateMedia_focalX_operator"] | undefined | null,
	focalY?: ResolverInputTypes["PrivateMedia_focalY_operator"] | undefined | null,
	sizes__thumbnail__url?: ResolverInputTypes["PrivateMedia_sizes__thumbnail__url_operator"] | undefined | null,
	sizes__thumbnail__width?: ResolverInputTypes["PrivateMedia_sizes__thumbnail__width_operator"] | undefined | null,
	sizes__thumbnail__height?: ResolverInputTypes["PrivateMedia_sizes__thumbnail__height_operator"] | undefined | null,
	sizes__thumbnail__mimeType?: ResolverInputTypes["PrivateMedia_sizes__thumbnail__mimeType_operator"] | undefined | null,
	sizes__thumbnail__filesize?: ResolverInputTypes["PrivateMedia_sizes__thumbnail__filesize_operator"] | undefined | null,
	sizes__thumbnail__filename?: ResolverInputTypes["PrivateMedia_sizes__thumbnail__filename_operator"] | undefined | null,
	sizes__portrait__url?: ResolverInputTypes["PrivateMedia_sizes__portrait__url_operator"] | undefined | null,
	sizes__portrait__width?: ResolverInputTypes["PrivateMedia_sizes__portrait__width_operator"] | undefined | null,
	sizes__portrait__height?: ResolverInputTypes["PrivateMedia_sizes__portrait__height_operator"] | undefined | null,
	sizes__portrait__mimeType?: ResolverInputTypes["PrivateMedia_sizes__portrait__mimeType_operator"] | undefined | null,
	sizes__portrait__filesize?: ResolverInputTypes["PrivateMedia_sizes__portrait__filesize_operator"] | undefined | null,
	sizes__portrait__filename?: ResolverInputTypes["PrivateMedia_sizes__portrait__filename_operator"] | undefined | null,
	sizes__hero__url?: ResolverInputTypes["PrivateMedia_sizes__hero__url_operator"] | undefined | null,
	sizes__hero__width?: ResolverInputTypes["PrivateMedia_sizes__hero__width_operator"] | undefined | null,
	sizes__hero__height?: ResolverInputTypes["PrivateMedia_sizes__hero__height_operator"] | undefined | null,
	sizes__hero__mimeType?: ResolverInputTypes["PrivateMedia_sizes__hero__mimeType_operator"] | undefined | null,
	sizes__hero__filesize?: ResolverInputTypes["PrivateMedia_sizes__hero__filesize_operator"] | undefined | null,
	sizes__hero__filename?: ResolverInputTypes["PrivateMedia_sizes__hero__filename_operator"] | undefined | null,
	sizes__thumbnail_jpeg__url?: ResolverInputTypes["PrivateMedia_sizes__thumbnail_jpeg__url_operator"] | undefined | null,
	sizes__thumbnail_jpeg__width?: ResolverInputTypes["PrivateMedia_sizes__thumbnail_jpeg__width_operator"] | undefined | null,
	sizes__thumbnail_jpeg__height?: ResolverInputTypes["PrivateMedia_sizes__thumbnail_jpeg__height_operator"] | undefined | null,
	sizes__thumbnail_jpeg__mimeType?: ResolverInputTypes["PrivateMedia_sizes__thumbnail_jpeg__mimeType_operator"] | undefined | null,
	sizes__thumbnail_jpeg__filesize?: ResolverInputTypes["PrivateMedia_sizes__thumbnail_jpeg__filesize_operator"] | undefined | null,
	sizes__thumbnail_jpeg__filename?: ResolverInputTypes["PrivateMedia_sizes__thumbnail_jpeg__filename_operator"] | undefined | null,
	sizes__portrait_jpeg__url?: ResolverInputTypes["PrivateMedia_sizes__portrait_jpeg__url_operator"] | undefined | null,
	sizes__portrait_jpeg__width?: ResolverInputTypes["PrivateMedia_sizes__portrait_jpeg__width_operator"] | undefined | null,
	sizes__portrait_jpeg__height?: ResolverInputTypes["PrivateMedia_sizes__portrait_jpeg__height_operator"] | undefined | null,
	sizes__portrait_jpeg__mimeType?: ResolverInputTypes["PrivateMedia_sizes__portrait_jpeg__mimeType_operator"] | undefined | null,
	sizes__portrait_jpeg__filesize?: ResolverInputTypes["PrivateMedia_sizes__portrait_jpeg__filesize_operator"] | undefined | null,
	sizes__portrait_jpeg__filename?: ResolverInputTypes["PrivateMedia_sizes__portrait_jpeg__filename_operator"] | undefined | null,
	sizes__hero_jpeg__url?: ResolverInputTypes["PrivateMedia_sizes__hero_jpeg__url_operator"] | undefined | null,
	sizes__hero_jpeg__width?: ResolverInputTypes["PrivateMedia_sizes__hero_jpeg__width_operator"] | undefined | null,
	sizes__hero_jpeg__height?: ResolverInputTypes["PrivateMedia_sizes__hero_jpeg__height_operator"] | undefined | null,
	sizes__hero_jpeg__mimeType?: ResolverInputTypes["PrivateMedia_sizes__hero_jpeg__mimeType_operator"] | undefined | null,
	sizes__hero_jpeg__filesize?: ResolverInputTypes["PrivateMedia_sizes__hero_jpeg__filesize_operator"] | undefined | null,
	sizes__hero_jpeg__filename?: ResolverInputTypes["PrivateMedia_sizes__hero_jpeg__filename_operator"] | undefined | null,
	id?: ResolverInputTypes["PrivateMedia_id_operator"] | undefined | null,
	AND?: Array<ResolverInputTypes["PrivateMedia_where_and"] | undefined | null> | undefined | null,
	OR?: Array<ResolverInputTypes["PrivateMedia_where_or"] | undefined | null> | undefined | null
};
	["PrivateMedia_alt_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["PrivateMedia_updatedAt_operator"]: {
	equals?: ResolverInputTypes["DateTime"] | undefined | null,
	not_equals?: ResolverInputTypes["DateTime"] | undefined | null,
	greater_than_equal?: ResolverInputTypes["DateTime"] | undefined | null,
	greater_than?: ResolverInputTypes["DateTime"] | undefined | null,
	less_than_equal?: ResolverInputTypes["DateTime"] | undefined | null,
	less_than?: ResolverInputTypes["DateTime"] | undefined | null,
	like?: ResolverInputTypes["DateTime"] | undefined | null,
	exists?: boolean | undefined | null
};
	["PrivateMedia_createdAt_operator"]: {
	equals?: ResolverInputTypes["DateTime"] | undefined | null,
	not_equals?: ResolverInputTypes["DateTime"] | undefined | null,
	greater_than_equal?: ResolverInputTypes["DateTime"] | undefined | null,
	greater_than?: ResolverInputTypes["DateTime"] | undefined | null,
	less_than_equal?: ResolverInputTypes["DateTime"] | undefined | null,
	less_than?: ResolverInputTypes["DateTime"] | undefined | null,
	like?: ResolverInputTypes["DateTime"] | undefined | null,
	exists?: boolean | undefined | null
};
	["PrivateMedia_url_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["PrivateMedia_filename_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["PrivateMedia_mimeType_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["PrivateMedia_filesize_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["PrivateMedia_width_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["PrivateMedia_height_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["PrivateMedia_focalX_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["PrivateMedia_focalY_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["PrivateMedia_sizes__thumbnail__url_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["PrivateMedia_sizes__thumbnail__width_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["PrivateMedia_sizes__thumbnail__height_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["PrivateMedia_sizes__thumbnail__mimeType_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["PrivateMedia_sizes__thumbnail__filesize_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["PrivateMedia_sizes__thumbnail__filename_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["PrivateMedia_sizes__portrait__url_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["PrivateMedia_sizes__portrait__width_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["PrivateMedia_sizes__portrait__height_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["PrivateMedia_sizes__portrait__mimeType_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["PrivateMedia_sizes__portrait__filesize_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["PrivateMedia_sizes__portrait__filename_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["PrivateMedia_sizes__hero__url_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["PrivateMedia_sizes__hero__width_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["PrivateMedia_sizes__hero__height_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["PrivateMedia_sizes__hero__mimeType_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["PrivateMedia_sizes__hero__filesize_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["PrivateMedia_sizes__hero__filename_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["PrivateMedia_sizes__thumbnail_jpeg__url_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["PrivateMedia_sizes__thumbnail_jpeg__width_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["PrivateMedia_sizes__thumbnail_jpeg__height_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["PrivateMedia_sizes__thumbnail_jpeg__mimeType_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["PrivateMedia_sizes__thumbnail_jpeg__filesize_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["PrivateMedia_sizes__thumbnail_jpeg__filename_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["PrivateMedia_sizes__portrait_jpeg__url_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["PrivateMedia_sizes__portrait_jpeg__width_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["PrivateMedia_sizes__portrait_jpeg__height_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["PrivateMedia_sizes__portrait_jpeg__mimeType_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["PrivateMedia_sizes__portrait_jpeg__filesize_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["PrivateMedia_sizes__portrait_jpeg__filename_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["PrivateMedia_sizes__hero_jpeg__url_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["PrivateMedia_sizes__hero_jpeg__width_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["PrivateMedia_sizes__hero_jpeg__height_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["PrivateMedia_sizes__hero_jpeg__mimeType_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["PrivateMedia_sizes__hero_jpeg__filesize_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["PrivateMedia_sizes__hero_jpeg__filename_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["PrivateMedia_id_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["PrivateMedia_where_and"]: {
	alt?: ResolverInputTypes["PrivateMedia_alt_operator"] | undefined | null,
	updatedAt?: ResolverInputTypes["PrivateMedia_updatedAt_operator"] | undefined | null,
	createdAt?: ResolverInputTypes["PrivateMedia_createdAt_operator"] | undefined | null,
	url?: ResolverInputTypes["PrivateMedia_url_operator"] | undefined | null,
	filename?: ResolverInputTypes["PrivateMedia_filename_operator"] | undefined | null,
	mimeType?: ResolverInputTypes["PrivateMedia_mimeType_operator"] | undefined | null,
	filesize?: ResolverInputTypes["PrivateMedia_filesize_operator"] | undefined | null,
	width?: ResolverInputTypes["PrivateMedia_width_operator"] | undefined | null,
	height?: ResolverInputTypes["PrivateMedia_height_operator"] | undefined | null,
	focalX?: ResolverInputTypes["PrivateMedia_focalX_operator"] | undefined | null,
	focalY?: ResolverInputTypes["PrivateMedia_focalY_operator"] | undefined | null,
	sizes__thumbnail__url?: ResolverInputTypes["PrivateMedia_sizes__thumbnail__url_operator"] | undefined | null,
	sizes__thumbnail__width?: ResolverInputTypes["PrivateMedia_sizes__thumbnail__width_operator"] | undefined | null,
	sizes__thumbnail__height?: ResolverInputTypes["PrivateMedia_sizes__thumbnail__height_operator"] | undefined | null,
	sizes__thumbnail__mimeType?: ResolverInputTypes["PrivateMedia_sizes__thumbnail__mimeType_operator"] | undefined | null,
	sizes__thumbnail__filesize?: ResolverInputTypes["PrivateMedia_sizes__thumbnail__filesize_operator"] | undefined | null,
	sizes__thumbnail__filename?: ResolverInputTypes["PrivateMedia_sizes__thumbnail__filename_operator"] | undefined | null,
	sizes__portrait__url?: ResolverInputTypes["PrivateMedia_sizes__portrait__url_operator"] | undefined | null,
	sizes__portrait__width?: ResolverInputTypes["PrivateMedia_sizes__portrait__width_operator"] | undefined | null,
	sizes__portrait__height?: ResolverInputTypes["PrivateMedia_sizes__portrait__height_operator"] | undefined | null,
	sizes__portrait__mimeType?: ResolverInputTypes["PrivateMedia_sizes__portrait__mimeType_operator"] | undefined | null,
	sizes__portrait__filesize?: ResolverInputTypes["PrivateMedia_sizes__portrait__filesize_operator"] | undefined | null,
	sizes__portrait__filename?: ResolverInputTypes["PrivateMedia_sizes__portrait__filename_operator"] | undefined | null,
	sizes__hero__url?: ResolverInputTypes["PrivateMedia_sizes__hero__url_operator"] | undefined | null,
	sizes__hero__width?: ResolverInputTypes["PrivateMedia_sizes__hero__width_operator"] | undefined | null,
	sizes__hero__height?: ResolverInputTypes["PrivateMedia_sizes__hero__height_operator"] | undefined | null,
	sizes__hero__mimeType?: ResolverInputTypes["PrivateMedia_sizes__hero__mimeType_operator"] | undefined | null,
	sizes__hero__filesize?: ResolverInputTypes["PrivateMedia_sizes__hero__filesize_operator"] | undefined | null,
	sizes__hero__filename?: ResolverInputTypes["PrivateMedia_sizes__hero__filename_operator"] | undefined | null,
	sizes__thumbnail_jpeg__url?: ResolverInputTypes["PrivateMedia_sizes__thumbnail_jpeg__url_operator"] | undefined | null,
	sizes__thumbnail_jpeg__width?: ResolverInputTypes["PrivateMedia_sizes__thumbnail_jpeg__width_operator"] | undefined | null,
	sizes__thumbnail_jpeg__height?: ResolverInputTypes["PrivateMedia_sizes__thumbnail_jpeg__height_operator"] | undefined | null,
	sizes__thumbnail_jpeg__mimeType?: ResolverInputTypes["PrivateMedia_sizes__thumbnail_jpeg__mimeType_operator"] | undefined | null,
	sizes__thumbnail_jpeg__filesize?: ResolverInputTypes["PrivateMedia_sizes__thumbnail_jpeg__filesize_operator"] | undefined | null,
	sizes__thumbnail_jpeg__filename?: ResolverInputTypes["PrivateMedia_sizes__thumbnail_jpeg__filename_operator"] | undefined | null,
	sizes__portrait_jpeg__url?: ResolverInputTypes["PrivateMedia_sizes__portrait_jpeg__url_operator"] | undefined | null,
	sizes__portrait_jpeg__width?: ResolverInputTypes["PrivateMedia_sizes__portrait_jpeg__width_operator"] | undefined | null,
	sizes__portrait_jpeg__height?: ResolverInputTypes["PrivateMedia_sizes__portrait_jpeg__height_operator"] | undefined | null,
	sizes__portrait_jpeg__mimeType?: ResolverInputTypes["PrivateMedia_sizes__portrait_jpeg__mimeType_operator"] | undefined | null,
	sizes__portrait_jpeg__filesize?: ResolverInputTypes["PrivateMedia_sizes__portrait_jpeg__filesize_operator"] | undefined | null,
	sizes__portrait_jpeg__filename?: ResolverInputTypes["PrivateMedia_sizes__portrait_jpeg__filename_operator"] | undefined | null,
	sizes__hero_jpeg__url?: ResolverInputTypes["PrivateMedia_sizes__hero_jpeg__url_operator"] | undefined | null,
	sizes__hero_jpeg__width?: ResolverInputTypes["PrivateMedia_sizes__hero_jpeg__width_operator"] | undefined | null,
	sizes__hero_jpeg__height?: ResolverInputTypes["PrivateMedia_sizes__hero_jpeg__height_operator"] | undefined | null,
	sizes__hero_jpeg__mimeType?: ResolverInputTypes["PrivateMedia_sizes__hero_jpeg__mimeType_operator"] | undefined | null,
	sizes__hero_jpeg__filesize?: ResolverInputTypes["PrivateMedia_sizes__hero_jpeg__filesize_operator"] | undefined | null,
	sizes__hero_jpeg__filename?: ResolverInputTypes["PrivateMedia_sizes__hero_jpeg__filename_operator"] | undefined | null,
	id?: ResolverInputTypes["PrivateMedia_id_operator"] | undefined | null,
	AND?: Array<ResolverInputTypes["PrivateMedia_where_and"] | undefined | null> | undefined | null,
	OR?: Array<ResolverInputTypes["PrivateMedia_where_or"] | undefined | null> | undefined | null
};
	["PrivateMedia_where_or"]: {
	alt?: ResolverInputTypes["PrivateMedia_alt_operator"] | undefined | null,
	updatedAt?: ResolverInputTypes["PrivateMedia_updatedAt_operator"] | undefined | null,
	createdAt?: ResolverInputTypes["PrivateMedia_createdAt_operator"] | undefined | null,
	url?: ResolverInputTypes["PrivateMedia_url_operator"] | undefined | null,
	filename?: ResolverInputTypes["PrivateMedia_filename_operator"] | undefined | null,
	mimeType?: ResolverInputTypes["PrivateMedia_mimeType_operator"] | undefined | null,
	filesize?: ResolverInputTypes["PrivateMedia_filesize_operator"] | undefined | null,
	width?: ResolverInputTypes["PrivateMedia_width_operator"] | undefined | null,
	height?: ResolverInputTypes["PrivateMedia_height_operator"] | undefined | null,
	focalX?: ResolverInputTypes["PrivateMedia_focalX_operator"] | undefined | null,
	focalY?: ResolverInputTypes["PrivateMedia_focalY_operator"] | undefined | null,
	sizes__thumbnail__url?: ResolverInputTypes["PrivateMedia_sizes__thumbnail__url_operator"] | undefined | null,
	sizes__thumbnail__width?: ResolverInputTypes["PrivateMedia_sizes__thumbnail__width_operator"] | undefined | null,
	sizes__thumbnail__height?: ResolverInputTypes["PrivateMedia_sizes__thumbnail__height_operator"] | undefined | null,
	sizes__thumbnail__mimeType?: ResolverInputTypes["PrivateMedia_sizes__thumbnail__mimeType_operator"] | undefined | null,
	sizes__thumbnail__filesize?: ResolverInputTypes["PrivateMedia_sizes__thumbnail__filesize_operator"] | undefined | null,
	sizes__thumbnail__filename?: ResolverInputTypes["PrivateMedia_sizes__thumbnail__filename_operator"] | undefined | null,
	sizes__portrait__url?: ResolverInputTypes["PrivateMedia_sizes__portrait__url_operator"] | undefined | null,
	sizes__portrait__width?: ResolverInputTypes["PrivateMedia_sizes__portrait__width_operator"] | undefined | null,
	sizes__portrait__height?: ResolverInputTypes["PrivateMedia_sizes__portrait__height_operator"] | undefined | null,
	sizes__portrait__mimeType?: ResolverInputTypes["PrivateMedia_sizes__portrait__mimeType_operator"] | undefined | null,
	sizes__portrait__filesize?: ResolverInputTypes["PrivateMedia_sizes__portrait__filesize_operator"] | undefined | null,
	sizes__portrait__filename?: ResolverInputTypes["PrivateMedia_sizes__portrait__filename_operator"] | undefined | null,
	sizes__hero__url?: ResolverInputTypes["PrivateMedia_sizes__hero__url_operator"] | undefined | null,
	sizes__hero__width?: ResolverInputTypes["PrivateMedia_sizes__hero__width_operator"] | undefined | null,
	sizes__hero__height?: ResolverInputTypes["PrivateMedia_sizes__hero__height_operator"] | undefined | null,
	sizes__hero__mimeType?: ResolverInputTypes["PrivateMedia_sizes__hero__mimeType_operator"] | undefined | null,
	sizes__hero__filesize?: ResolverInputTypes["PrivateMedia_sizes__hero__filesize_operator"] | undefined | null,
	sizes__hero__filename?: ResolverInputTypes["PrivateMedia_sizes__hero__filename_operator"] | undefined | null,
	sizes__thumbnail_jpeg__url?: ResolverInputTypes["PrivateMedia_sizes__thumbnail_jpeg__url_operator"] | undefined | null,
	sizes__thumbnail_jpeg__width?: ResolverInputTypes["PrivateMedia_sizes__thumbnail_jpeg__width_operator"] | undefined | null,
	sizes__thumbnail_jpeg__height?: ResolverInputTypes["PrivateMedia_sizes__thumbnail_jpeg__height_operator"] | undefined | null,
	sizes__thumbnail_jpeg__mimeType?: ResolverInputTypes["PrivateMedia_sizes__thumbnail_jpeg__mimeType_operator"] | undefined | null,
	sizes__thumbnail_jpeg__filesize?: ResolverInputTypes["PrivateMedia_sizes__thumbnail_jpeg__filesize_operator"] | undefined | null,
	sizes__thumbnail_jpeg__filename?: ResolverInputTypes["PrivateMedia_sizes__thumbnail_jpeg__filename_operator"] | undefined | null,
	sizes__portrait_jpeg__url?: ResolverInputTypes["PrivateMedia_sizes__portrait_jpeg__url_operator"] | undefined | null,
	sizes__portrait_jpeg__width?: ResolverInputTypes["PrivateMedia_sizes__portrait_jpeg__width_operator"] | undefined | null,
	sizes__portrait_jpeg__height?: ResolverInputTypes["PrivateMedia_sizes__portrait_jpeg__height_operator"] | undefined | null,
	sizes__portrait_jpeg__mimeType?: ResolverInputTypes["PrivateMedia_sizes__portrait_jpeg__mimeType_operator"] | undefined | null,
	sizes__portrait_jpeg__filesize?: ResolverInputTypes["PrivateMedia_sizes__portrait_jpeg__filesize_operator"] | undefined | null,
	sizes__portrait_jpeg__filename?: ResolverInputTypes["PrivateMedia_sizes__portrait_jpeg__filename_operator"] | undefined | null,
	sizes__hero_jpeg__url?: ResolverInputTypes["PrivateMedia_sizes__hero_jpeg__url_operator"] | undefined | null,
	sizes__hero_jpeg__width?: ResolverInputTypes["PrivateMedia_sizes__hero_jpeg__width_operator"] | undefined | null,
	sizes__hero_jpeg__height?: ResolverInputTypes["PrivateMedia_sizes__hero_jpeg__height_operator"] | undefined | null,
	sizes__hero_jpeg__mimeType?: ResolverInputTypes["PrivateMedia_sizes__hero_jpeg__mimeType_operator"] | undefined | null,
	sizes__hero_jpeg__filesize?: ResolverInputTypes["PrivateMedia_sizes__hero_jpeg__filesize_operator"] | undefined | null,
	sizes__hero_jpeg__filename?: ResolverInputTypes["PrivateMedia_sizes__hero_jpeg__filename_operator"] | undefined | null,
	id?: ResolverInputTypes["PrivateMedia_id_operator"] | undefined | null,
	AND?: Array<ResolverInputTypes["PrivateMedia_where_and"] | undefined | null> | undefined | null,
	OR?: Array<ResolverInputTypes["PrivateMedia_where_or"] | undefined | null> | undefined | null
};
	["countPrivateMedias"]: AliasType<{
	totalDocs?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["private_mediaDocAccess"]: AliasType<{
	fields?:ResolverInputTypes["PrivateMediaDocAccessFields"],
	create?:ResolverInputTypes["PrivateMediaCreateDocAccess"],
	read?:ResolverInputTypes["PrivateMediaReadDocAccess"],
	update?:ResolverInputTypes["PrivateMediaUpdateDocAccess"],
	delete?:ResolverInputTypes["PrivateMediaDeleteDocAccess"],
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields"]: AliasType<{
	alt?:ResolverInputTypes["PrivateMediaDocAccessFields_alt"],
	updatedAt?:ResolverInputTypes["PrivateMediaDocAccessFields_updatedAt"],
	createdAt?:ResolverInputTypes["PrivateMediaDocAccessFields_createdAt"],
	url?:ResolverInputTypes["PrivateMediaDocAccessFields_url"],
	filename?:ResolverInputTypes["PrivateMediaDocAccessFields_filename"],
	mimeType?:ResolverInputTypes["PrivateMediaDocAccessFields_mimeType"],
	filesize?:ResolverInputTypes["PrivateMediaDocAccessFields_filesize"],
	width?:ResolverInputTypes["PrivateMediaDocAccessFields_width"],
	height?:ResolverInputTypes["PrivateMediaDocAccessFields_height"],
	focalX?:ResolverInputTypes["PrivateMediaDocAccessFields_focalX"],
	focalY?:ResolverInputTypes["PrivateMediaDocAccessFields_focalY"],
	sizes?:ResolverInputTypes["PrivateMediaDocAccessFields_sizes"],
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_alt"]: AliasType<{
	create?:ResolverInputTypes["PrivateMediaDocAccessFields_alt_Create"],
	read?:ResolverInputTypes["PrivateMediaDocAccessFields_alt_Read"],
	update?:ResolverInputTypes["PrivateMediaDocAccessFields_alt_Update"],
	delete?:ResolverInputTypes["PrivateMediaDocAccessFields_alt_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_alt_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_alt_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_alt_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_alt_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_updatedAt"]: AliasType<{
	create?:ResolverInputTypes["PrivateMediaDocAccessFields_updatedAt_Create"],
	read?:ResolverInputTypes["PrivateMediaDocAccessFields_updatedAt_Read"],
	update?:ResolverInputTypes["PrivateMediaDocAccessFields_updatedAt_Update"],
	delete?:ResolverInputTypes["PrivateMediaDocAccessFields_updatedAt_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_updatedAt_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_updatedAt_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_updatedAt_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_updatedAt_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_createdAt"]: AliasType<{
	create?:ResolverInputTypes["PrivateMediaDocAccessFields_createdAt_Create"],
	read?:ResolverInputTypes["PrivateMediaDocAccessFields_createdAt_Read"],
	update?:ResolverInputTypes["PrivateMediaDocAccessFields_createdAt_Update"],
	delete?:ResolverInputTypes["PrivateMediaDocAccessFields_createdAt_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_createdAt_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_createdAt_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_createdAt_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_createdAt_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_url"]: AliasType<{
	create?:ResolverInputTypes["PrivateMediaDocAccessFields_url_Create"],
	read?:ResolverInputTypes["PrivateMediaDocAccessFields_url_Read"],
	update?:ResolverInputTypes["PrivateMediaDocAccessFields_url_Update"],
	delete?:ResolverInputTypes["PrivateMediaDocAccessFields_url_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_url_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_url_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_url_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_url_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_filename"]: AliasType<{
	create?:ResolverInputTypes["PrivateMediaDocAccessFields_filename_Create"],
	read?:ResolverInputTypes["PrivateMediaDocAccessFields_filename_Read"],
	update?:ResolverInputTypes["PrivateMediaDocAccessFields_filename_Update"],
	delete?:ResolverInputTypes["PrivateMediaDocAccessFields_filename_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_filename_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_filename_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_filename_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_filename_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_mimeType"]: AliasType<{
	create?:ResolverInputTypes["PrivateMediaDocAccessFields_mimeType_Create"],
	read?:ResolverInputTypes["PrivateMediaDocAccessFields_mimeType_Read"],
	update?:ResolverInputTypes["PrivateMediaDocAccessFields_mimeType_Update"],
	delete?:ResolverInputTypes["PrivateMediaDocAccessFields_mimeType_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_mimeType_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_mimeType_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_mimeType_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_mimeType_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_filesize"]: AliasType<{
	create?:ResolverInputTypes["PrivateMediaDocAccessFields_filesize_Create"],
	read?:ResolverInputTypes["PrivateMediaDocAccessFields_filesize_Read"],
	update?:ResolverInputTypes["PrivateMediaDocAccessFields_filesize_Update"],
	delete?:ResolverInputTypes["PrivateMediaDocAccessFields_filesize_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_filesize_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_filesize_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_filesize_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_filesize_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_width"]: AliasType<{
	create?:ResolverInputTypes["PrivateMediaDocAccessFields_width_Create"],
	read?:ResolverInputTypes["PrivateMediaDocAccessFields_width_Read"],
	update?:ResolverInputTypes["PrivateMediaDocAccessFields_width_Update"],
	delete?:ResolverInputTypes["PrivateMediaDocAccessFields_width_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_width_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_width_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_width_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_width_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_height"]: AliasType<{
	create?:ResolverInputTypes["PrivateMediaDocAccessFields_height_Create"],
	read?:ResolverInputTypes["PrivateMediaDocAccessFields_height_Read"],
	update?:ResolverInputTypes["PrivateMediaDocAccessFields_height_Update"],
	delete?:ResolverInputTypes["PrivateMediaDocAccessFields_height_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_height_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_height_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_height_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_height_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_focalX"]: AliasType<{
	create?:ResolverInputTypes["PrivateMediaDocAccessFields_focalX_Create"],
	read?:ResolverInputTypes["PrivateMediaDocAccessFields_focalX_Read"],
	update?:ResolverInputTypes["PrivateMediaDocAccessFields_focalX_Update"],
	delete?:ResolverInputTypes["PrivateMediaDocAccessFields_focalX_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_focalX_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_focalX_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_focalX_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_focalX_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_focalY"]: AliasType<{
	create?:ResolverInputTypes["PrivateMediaDocAccessFields_focalY_Create"],
	read?:ResolverInputTypes["PrivateMediaDocAccessFields_focalY_Read"],
	update?:ResolverInputTypes["PrivateMediaDocAccessFields_focalY_Update"],
	delete?:ResolverInputTypes["PrivateMediaDocAccessFields_focalY_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_focalY_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_focalY_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_focalY_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_focalY_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes"]: AliasType<{
	create?:ResolverInputTypes["PrivateMediaDocAccessFields_sizes_Create"],
	read?:ResolverInputTypes["PrivateMediaDocAccessFields_sizes_Read"],
	update?:ResolverInputTypes["PrivateMediaDocAccessFields_sizes_Update"],
	delete?:ResolverInputTypes["PrivateMediaDocAccessFields_sizes_Delete"],
	fields?:ResolverInputTypes["PrivateMediaDocAccessFields_sizes_Fields"],
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_Fields"]: AliasType<{
	thumbnail?:ResolverInputTypes["PrivateMediaDocAccessFields_sizes_thumbnail"],
	portrait?:ResolverInputTypes["PrivateMediaDocAccessFields_sizes_portrait"],
	hero?:ResolverInputTypes["PrivateMediaDocAccessFields_sizes_hero"],
	thumbnail_jpeg?:ResolverInputTypes["PrivateMediaDocAccessFields_sizes_thumbnail_jpeg"],
	portrait_jpeg?:ResolverInputTypes["PrivateMediaDocAccessFields_sizes_portrait_jpeg"],
	hero_jpeg?:ResolverInputTypes["PrivateMediaDocAccessFields_sizes_hero_jpeg"],
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_thumbnail"]: AliasType<{
	create?:ResolverInputTypes["PrivateMediaDocAccessFields_sizes_thumbnail_Create"],
	read?:ResolverInputTypes["PrivateMediaDocAccessFields_sizes_thumbnail_Read"],
	update?:ResolverInputTypes["PrivateMediaDocAccessFields_sizes_thumbnail_Update"],
	delete?:ResolverInputTypes["PrivateMediaDocAccessFields_sizes_thumbnail_Delete"],
	fields?:ResolverInputTypes["PrivateMediaDocAccessFields_sizes_thumbnail_Fields"],
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_thumbnail_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_thumbnail_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_thumbnail_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_thumbnail_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_thumbnail_Fields"]: AliasType<{
	url?:ResolverInputTypes["PrivateMediaDocAccessFields_sizes_thumbnail_url"],
	width?:ResolverInputTypes["PrivateMediaDocAccessFields_sizes_thumbnail_width"],
	height?:ResolverInputTypes["PrivateMediaDocAccessFields_sizes_thumbnail_height"],
	mimeType?:ResolverInputTypes["PrivateMediaDocAccessFields_sizes_thumbnail_mimeType"],
	filesize?:ResolverInputTypes["PrivateMediaDocAccessFields_sizes_thumbnail_filesize"],
	filename?:ResolverInputTypes["PrivateMediaDocAccessFields_sizes_thumbnail_filename"],
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_thumbnail_url"]: AliasType<{
	create?:ResolverInputTypes["PrivateMediaDocAccessFields_sizes_thumbnail_url_Create"],
	read?:ResolverInputTypes["PrivateMediaDocAccessFields_sizes_thumbnail_url_Read"],
	update?:ResolverInputTypes["PrivateMediaDocAccessFields_sizes_thumbnail_url_Update"],
	delete?:ResolverInputTypes["PrivateMediaDocAccessFields_sizes_thumbnail_url_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_thumbnail_url_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_thumbnail_url_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_thumbnail_url_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_thumbnail_url_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_thumbnail_width"]: AliasType<{
	create?:ResolverInputTypes["PrivateMediaDocAccessFields_sizes_thumbnail_width_Create"],
	read?:ResolverInputTypes["PrivateMediaDocAccessFields_sizes_thumbnail_width_Read"],
	update?:ResolverInputTypes["PrivateMediaDocAccessFields_sizes_thumbnail_width_Update"],
	delete?:ResolverInputTypes["PrivateMediaDocAccessFields_sizes_thumbnail_width_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_thumbnail_width_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_thumbnail_width_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_thumbnail_width_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_thumbnail_width_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_thumbnail_height"]: AliasType<{
	create?:ResolverInputTypes["PrivateMediaDocAccessFields_sizes_thumbnail_height_Create"],
	read?:ResolverInputTypes["PrivateMediaDocAccessFields_sizes_thumbnail_height_Read"],
	update?:ResolverInputTypes["PrivateMediaDocAccessFields_sizes_thumbnail_height_Update"],
	delete?:ResolverInputTypes["PrivateMediaDocAccessFields_sizes_thumbnail_height_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_thumbnail_height_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_thumbnail_height_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_thumbnail_height_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_thumbnail_height_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_thumbnail_mimeType"]: AliasType<{
	create?:ResolverInputTypes["PrivateMediaDocAccessFields_sizes_thumbnail_mimeType_Create"],
	read?:ResolverInputTypes["PrivateMediaDocAccessFields_sizes_thumbnail_mimeType_Read"],
	update?:ResolverInputTypes["PrivateMediaDocAccessFields_sizes_thumbnail_mimeType_Update"],
	delete?:ResolverInputTypes["PrivateMediaDocAccessFields_sizes_thumbnail_mimeType_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_thumbnail_mimeType_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_thumbnail_mimeType_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_thumbnail_mimeType_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_thumbnail_mimeType_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_thumbnail_filesize"]: AliasType<{
	create?:ResolverInputTypes["PrivateMediaDocAccessFields_sizes_thumbnail_filesize_Create"],
	read?:ResolverInputTypes["PrivateMediaDocAccessFields_sizes_thumbnail_filesize_Read"],
	update?:ResolverInputTypes["PrivateMediaDocAccessFields_sizes_thumbnail_filesize_Update"],
	delete?:ResolverInputTypes["PrivateMediaDocAccessFields_sizes_thumbnail_filesize_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_thumbnail_filesize_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_thumbnail_filesize_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_thumbnail_filesize_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_thumbnail_filesize_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_thumbnail_filename"]: AliasType<{
	create?:ResolverInputTypes["PrivateMediaDocAccessFields_sizes_thumbnail_filename_Create"],
	read?:ResolverInputTypes["PrivateMediaDocAccessFields_sizes_thumbnail_filename_Read"],
	update?:ResolverInputTypes["PrivateMediaDocAccessFields_sizes_thumbnail_filename_Update"],
	delete?:ResolverInputTypes["PrivateMediaDocAccessFields_sizes_thumbnail_filename_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_thumbnail_filename_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_thumbnail_filename_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_thumbnail_filename_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_thumbnail_filename_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_portrait"]: AliasType<{
	create?:ResolverInputTypes["PrivateMediaDocAccessFields_sizes_portrait_Create"],
	read?:ResolverInputTypes["PrivateMediaDocAccessFields_sizes_portrait_Read"],
	update?:ResolverInputTypes["PrivateMediaDocAccessFields_sizes_portrait_Update"],
	delete?:ResolverInputTypes["PrivateMediaDocAccessFields_sizes_portrait_Delete"],
	fields?:ResolverInputTypes["PrivateMediaDocAccessFields_sizes_portrait_Fields"],
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_portrait_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_portrait_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_portrait_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_portrait_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_portrait_Fields"]: AliasType<{
	url?:ResolverInputTypes["PrivateMediaDocAccessFields_sizes_portrait_url"],
	width?:ResolverInputTypes["PrivateMediaDocAccessFields_sizes_portrait_width"],
	height?:ResolverInputTypes["PrivateMediaDocAccessFields_sizes_portrait_height"],
	mimeType?:ResolverInputTypes["PrivateMediaDocAccessFields_sizes_portrait_mimeType"],
	filesize?:ResolverInputTypes["PrivateMediaDocAccessFields_sizes_portrait_filesize"],
	filename?:ResolverInputTypes["PrivateMediaDocAccessFields_sizes_portrait_filename"],
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_portrait_url"]: AliasType<{
	create?:ResolverInputTypes["PrivateMediaDocAccessFields_sizes_portrait_url_Create"],
	read?:ResolverInputTypes["PrivateMediaDocAccessFields_sizes_portrait_url_Read"],
	update?:ResolverInputTypes["PrivateMediaDocAccessFields_sizes_portrait_url_Update"],
	delete?:ResolverInputTypes["PrivateMediaDocAccessFields_sizes_portrait_url_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_portrait_url_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_portrait_url_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_portrait_url_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_portrait_url_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_portrait_width"]: AliasType<{
	create?:ResolverInputTypes["PrivateMediaDocAccessFields_sizes_portrait_width_Create"],
	read?:ResolverInputTypes["PrivateMediaDocAccessFields_sizes_portrait_width_Read"],
	update?:ResolverInputTypes["PrivateMediaDocAccessFields_sizes_portrait_width_Update"],
	delete?:ResolverInputTypes["PrivateMediaDocAccessFields_sizes_portrait_width_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_portrait_width_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_portrait_width_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_portrait_width_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_portrait_width_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_portrait_height"]: AliasType<{
	create?:ResolverInputTypes["PrivateMediaDocAccessFields_sizes_portrait_height_Create"],
	read?:ResolverInputTypes["PrivateMediaDocAccessFields_sizes_portrait_height_Read"],
	update?:ResolverInputTypes["PrivateMediaDocAccessFields_sizes_portrait_height_Update"],
	delete?:ResolverInputTypes["PrivateMediaDocAccessFields_sizes_portrait_height_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_portrait_height_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_portrait_height_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_portrait_height_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_portrait_height_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_portrait_mimeType"]: AliasType<{
	create?:ResolverInputTypes["PrivateMediaDocAccessFields_sizes_portrait_mimeType_Create"],
	read?:ResolverInputTypes["PrivateMediaDocAccessFields_sizes_portrait_mimeType_Read"],
	update?:ResolverInputTypes["PrivateMediaDocAccessFields_sizes_portrait_mimeType_Update"],
	delete?:ResolverInputTypes["PrivateMediaDocAccessFields_sizes_portrait_mimeType_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_portrait_mimeType_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_portrait_mimeType_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_portrait_mimeType_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_portrait_mimeType_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_portrait_filesize"]: AliasType<{
	create?:ResolverInputTypes["PrivateMediaDocAccessFields_sizes_portrait_filesize_Create"],
	read?:ResolverInputTypes["PrivateMediaDocAccessFields_sizes_portrait_filesize_Read"],
	update?:ResolverInputTypes["PrivateMediaDocAccessFields_sizes_portrait_filesize_Update"],
	delete?:ResolverInputTypes["PrivateMediaDocAccessFields_sizes_portrait_filesize_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_portrait_filesize_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_portrait_filesize_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_portrait_filesize_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_portrait_filesize_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_portrait_filename"]: AliasType<{
	create?:ResolverInputTypes["PrivateMediaDocAccessFields_sizes_portrait_filename_Create"],
	read?:ResolverInputTypes["PrivateMediaDocAccessFields_sizes_portrait_filename_Read"],
	update?:ResolverInputTypes["PrivateMediaDocAccessFields_sizes_portrait_filename_Update"],
	delete?:ResolverInputTypes["PrivateMediaDocAccessFields_sizes_portrait_filename_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_portrait_filename_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_portrait_filename_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_portrait_filename_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_portrait_filename_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_hero"]: AliasType<{
	create?:ResolverInputTypes["PrivateMediaDocAccessFields_sizes_hero_Create"],
	read?:ResolverInputTypes["PrivateMediaDocAccessFields_sizes_hero_Read"],
	update?:ResolverInputTypes["PrivateMediaDocAccessFields_sizes_hero_Update"],
	delete?:ResolverInputTypes["PrivateMediaDocAccessFields_sizes_hero_Delete"],
	fields?:ResolverInputTypes["PrivateMediaDocAccessFields_sizes_hero_Fields"],
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_hero_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_hero_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_hero_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_hero_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_hero_Fields"]: AliasType<{
	url?:ResolverInputTypes["PrivateMediaDocAccessFields_sizes_hero_url"],
	width?:ResolverInputTypes["PrivateMediaDocAccessFields_sizes_hero_width"],
	height?:ResolverInputTypes["PrivateMediaDocAccessFields_sizes_hero_height"],
	mimeType?:ResolverInputTypes["PrivateMediaDocAccessFields_sizes_hero_mimeType"],
	filesize?:ResolverInputTypes["PrivateMediaDocAccessFields_sizes_hero_filesize"],
	filename?:ResolverInputTypes["PrivateMediaDocAccessFields_sizes_hero_filename"],
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_hero_url"]: AliasType<{
	create?:ResolverInputTypes["PrivateMediaDocAccessFields_sizes_hero_url_Create"],
	read?:ResolverInputTypes["PrivateMediaDocAccessFields_sizes_hero_url_Read"],
	update?:ResolverInputTypes["PrivateMediaDocAccessFields_sizes_hero_url_Update"],
	delete?:ResolverInputTypes["PrivateMediaDocAccessFields_sizes_hero_url_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_hero_url_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_hero_url_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_hero_url_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_hero_url_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_hero_width"]: AliasType<{
	create?:ResolverInputTypes["PrivateMediaDocAccessFields_sizes_hero_width_Create"],
	read?:ResolverInputTypes["PrivateMediaDocAccessFields_sizes_hero_width_Read"],
	update?:ResolverInputTypes["PrivateMediaDocAccessFields_sizes_hero_width_Update"],
	delete?:ResolverInputTypes["PrivateMediaDocAccessFields_sizes_hero_width_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_hero_width_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_hero_width_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_hero_width_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_hero_width_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_hero_height"]: AliasType<{
	create?:ResolverInputTypes["PrivateMediaDocAccessFields_sizes_hero_height_Create"],
	read?:ResolverInputTypes["PrivateMediaDocAccessFields_sizes_hero_height_Read"],
	update?:ResolverInputTypes["PrivateMediaDocAccessFields_sizes_hero_height_Update"],
	delete?:ResolverInputTypes["PrivateMediaDocAccessFields_sizes_hero_height_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_hero_height_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_hero_height_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_hero_height_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_hero_height_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_hero_mimeType"]: AliasType<{
	create?:ResolverInputTypes["PrivateMediaDocAccessFields_sizes_hero_mimeType_Create"],
	read?:ResolverInputTypes["PrivateMediaDocAccessFields_sizes_hero_mimeType_Read"],
	update?:ResolverInputTypes["PrivateMediaDocAccessFields_sizes_hero_mimeType_Update"],
	delete?:ResolverInputTypes["PrivateMediaDocAccessFields_sizes_hero_mimeType_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_hero_mimeType_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_hero_mimeType_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_hero_mimeType_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_hero_mimeType_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_hero_filesize"]: AliasType<{
	create?:ResolverInputTypes["PrivateMediaDocAccessFields_sizes_hero_filesize_Create"],
	read?:ResolverInputTypes["PrivateMediaDocAccessFields_sizes_hero_filesize_Read"],
	update?:ResolverInputTypes["PrivateMediaDocAccessFields_sizes_hero_filesize_Update"],
	delete?:ResolverInputTypes["PrivateMediaDocAccessFields_sizes_hero_filesize_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_hero_filesize_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_hero_filesize_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_hero_filesize_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_hero_filesize_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_hero_filename"]: AliasType<{
	create?:ResolverInputTypes["PrivateMediaDocAccessFields_sizes_hero_filename_Create"],
	read?:ResolverInputTypes["PrivateMediaDocAccessFields_sizes_hero_filename_Read"],
	update?:ResolverInputTypes["PrivateMediaDocAccessFields_sizes_hero_filename_Update"],
	delete?:ResolverInputTypes["PrivateMediaDocAccessFields_sizes_hero_filename_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_hero_filename_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_hero_filename_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_hero_filename_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_hero_filename_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_thumbnail_jpeg"]: AliasType<{
	create?:ResolverInputTypes["PrivateMediaDocAccessFields_sizes_thumbnail_jpeg_Create"],
	read?:ResolverInputTypes["PrivateMediaDocAccessFields_sizes_thumbnail_jpeg_Read"],
	update?:ResolverInputTypes["PrivateMediaDocAccessFields_sizes_thumbnail_jpeg_Update"],
	delete?:ResolverInputTypes["PrivateMediaDocAccessFields_sizes_thumbnail_jpeg_Delete"],
	fields?:ResolverInputTypes["PrivateMediaDocAccessFields_sizes_thumbnail_jpeg_Fields"],
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_thumbnail_jpeg_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_thumbnail_jpeg_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_thumbnail_jpeg_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_thumbnail_jpeg_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_thumbnail_jpeg_Fields"]: AliasType<{
	url?:ResolverInputTypes["PrivateMediaDocAccessFields_sizes_thumbnail_jpeg_url"],
	width?:ResolverInputTypes["PrivateMediaDocAccessFields_sizes_thumbnail_jpeg_width"],
	height?:ResolverInputTypes["PrivateMediaDocAccessFields_sizes_thumbnail_jpeg_height"],
	mimeType?:ResolverInputTypes["PrivateMediaDocAccessFields_sizes_thumbnail_jpeg_mimeType"],
	filesize?:ResolverInputTypes["PrivateMediaDocAccessFields_sizes_thumbnail_jpeg_filesize"],
	filename?:ResolverInputTypes["PrivateMediaDocAccessFields_sizes_thumbnail_jpeg_filename"],
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_thumbnail_jpeg_url"]: AliasType<{
	create?:ResolverInputTypes["PrivateMediaDocAccessFields_sizes_thumbnail_jpeg_url_Create"],
	read?:ResolverInputTypes["PrivateMediaDocAccessFields_sizes_thumbnail_jpeg_url_Read"],
	update?:ResolverInputTypes["PrivateMediaDocAccessFields_sizes_thumbnail_jpeg_url_Update"],
	delete?:ResolverInputTypes["PrivateMediaDocAccessFields_sizes_thumbnail_jpeg_url_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_thumbnail_jpeg_url_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_thumbnail_jpeg_url_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_thumbnail_jpeg_url_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_thumbnail_jpeg_url_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_thumbnail_jpeg_width"]: AliasType<{
	create?:ResolverInputTypes["PrivateMediaDocAccessFields_sizes_thumbnail_jpeg_width_Create"],
	read?:ResolverInputTypes["PrivateMediaDocAccessFields_sizes_thumbnail_jpeg_width_Read"],
	update?:ResolverInputTypes["PrivateMediaDocAccessFields_sizes_thumbnail_jpeg_width_Update"],
	delete?:ResolverInputTypes["PrivateMediaDocAccessFields_sizes_thumbnail_jpeg_width_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_thumbnail_jpeg_width_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_thumbnail_jpeg_width_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_thumbnail_jpeg_width_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_thumbnail_jpeg_width_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_thumbnail_jpeg_height"]: AliasType<{
	create?:ResolverInputTypes["PrivateMediaDocAccessFields_sizes_thumbnail_jpeg_height_Create"],
	read?:ResolverInputTypes["PrivateMediaDocAccessFields_sizes_thumbnail_jpeg_height_Read"],
	update?:ResolverInputTypes["PrivateMediaDocAccessFields_sizes_thumbnail_jpeg_height_Update"],
	delete?:ResolverInputTypes["PrivateMediaDocAccessFields_sizes_thumbnail_jpeg_height_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_thumbnail_jpeg_height_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_thumbnail_jpeg_height_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_thumbnail_jpeg_height_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_thumbnail_jpeg_height_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_thumbnail_jpeg_mimeType"]: AliasType<{
	create?:ResolverInputTypes["PrivateMediaDocAccessFields_sizes_thumbnail_jpeg_mimeType_Create"],
	read?:ResolverInputTypes["PrivateMediaDocAccessFields_sizes_thumbnail_jpeg_mimeType_Read"],
	update?:ResolverInputTypes["PrivateMediaDocAccessFields_sizes_thumbnail_jpeg_mimeType_Update"],
	delete?:ResolverInputTypes["PrivateMediaDocAccessFields_sizes_thumbnail_jpeg_mimeType_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_thumbnail_jpeg_mimeType_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_thumbnail_jpeg_mimeType_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_thumbnail_jpeg_mimeType_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_thumbnail_jpeg_mimeType_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_thumbnail_jpeg_filesize"]: AliasType<{
	create?:ResolverInputTypes["PrivateMediaDocAccessFields_sizes_thumbnail_jpeg_filesize_Create"],
	read?:ResolverInputTypes["PrivateMediaDocAccessFields_sizes_thumbnail_jpeg_filesize_Read"],
	update?:ResolverInputTypes["PrivateMediaDocAccessFields_sizes_thumbnail_jpeg_filesize_Update"],
	delete?:ResolverInputTypes["PrivateMediaDocAccessFields_sizes_thumbnail_jpeg_filesize_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_thumbnail_jpeg_filesize_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_thumbnail_jpeg_filesize_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_thumbnail_jpeg_filesize_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_thumbnail_jpeg_filesize_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_thumbnail_jpeg_filename"]: AliasType<{
	create?:ResolverInputTypes["PrivateMediaDocAccessFields_sizes_thumbnail_jpeg_filename_Create"],
	read?:ResolverInputTypes["PrivateMediaDocAccessFields_sizes_thumbnail_jpeg_filename_Read"],
	update?:ResolverInputTypes["PrivateMediaDocAccessFields_sizes_thumbnail_jpeg_filename_Update"],
	delete?:ResolverInputTypes["PrivateMediaDocAccessFields_sizes_thumbnail_jpeg_filename_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_thumbnail_jpeg_filename_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_thumbnail_jpeg_filename_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_thumbnail_jpeg_filename_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_thumbnail_jpeg_filename_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_portrait_jpeg"]: AliasType<{
	create?:ResolverInputTypes["PrivateMediaDocAccessFields_sizes_portrait_jpeg_Create"],
	read?:ResolverInputTypes["PrivateMediaDocAccessFields_sizes_portrait_jpeg_Read"],
	update?:ResolverInputTypes["PrivateMediaDocAccessFields_sizes_portrait_jpeg_Update"],
	delete?:ResolverInputTypes["PrivateMediaDocAccessFields_sizes_portrait_jpeg_Delete"],
	fields?:ResolverInputTypes["PrivateMediaDocAccessFields_sizes_portrait_jpeg_Fields"],
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_portrait_jpeg_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_portrait_jpeg_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_portrait_jpeg_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_portrait_jpeg_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_portrait_jpeg_Fields"]: AliasType<{
	url?:ResolverInputTypes["PrivateMediaDocAccessFields_sizes_portrait_jpeg_url"],
	width?:ResolverInputTypes["PrivateMediaDocAccessFields_sizes_portrait_jpeg_width"],
	height?:ResolverInputTypes["PrivateMediaDocAccessFields_sizes_portrait_jpeg_height"],
	mimeType?:ResolverInputTypes["PrivateMediaDocAccessFields_sizes_portrait_jpeg_mimeType"],
	filesize?:ResolverInputTypes["PrivateMediaDocAccessFields_sizes_portrait_jpeg_filesize"],
	filename?:ResolverInputTypes["PrivateMediaDocAccessFields_sizes_portrait_jpeg_filename"],
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_portrait_jpeg_url"]: AliasType<{
	create?:ResolverInputTypes["PrivateMediaDocAccessFields_sizes_portrait_jpeg_url_Create"],
	read?:ResolverInputTypes["PrivateMediaDocAccessFields_sizes_portrait_jpeg_url_Read"],
	update?:ResolverInputTypes["PrivateMediaDocAccessFields_sizes_portrait_jpeg_url_Update"],
	delete?:ResolverInputTypes["PrivateMediaDocAccessFields_sizes_portrait_jpeg_url_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_portrait_jpeg_url_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_portrait_jpeg_url_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_portrait_jpeg_url_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_portrait_jpeg_url_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_portrait_jpeg_width"]: AliasType<{
	create?:ResolverInputTypes["PrivateMediaDocAccessFields_sizes_portrait_jpeg_width_Create"],
	read?:ResolverInputTypes["PrivateMediaDocAccessFields_sizes_portrait_jpeg_width_Read"],
	update?:ResolverInputTypes["PrivateMediaDocAccessFields_sizes_portrait_jpeg_width_Update"],
	delete?:ResolverInputTypes["PrivateMediaDocAccessFields_sizes_portrait_jpeg_width_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_portrait_jpeg_width_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_portrait_jpeg_width_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_portrait_jpeg_width_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_portrait_jpeg_width_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_portrait_jpeg_height"]: AliasType<{
	create?:ResolverInputTypes["PrivateMediaDocAccessFields_sizes_portrait_jpeg_height_Create"],
	read?:ResolverInputTypes["PrivateMediaDocAccessFields_sizes_portrait_jpeg_height_Read"],
	update?:ResolverInputTypes["PrivateMediaDocAccessFields_sizes_portrait_jpeg_height_Update"],
	delete?:ResolverInputTypes["PrivateMediaDocAccessFields_sizes_portrait_jpeg_height_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_portrait_jpeg_height_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_portrait_jpeg_height_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_portrait_jpeg_height_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_portrait_jpeg_height_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_portrait_jpeg_mimeType"]: AliasType<{
	create?:ResolverInputTypes["PrivateMediaDocAccessFields_sizes_portrait_jpeg_mimeType_Create"],
	read?:ResolverInputTypes["PrivateMediaDocAccessFields_sizes_portrait_jpeg_mimeType_Read"],
	update?:ResolverInputTypes["PrivateMediaDocAccessFields_sizes_portrait_jpeg_mimeType_Update"],
	delete?:ResolverInputTypes["PrivateMediaDocAccessFields_sizes_portrait_jpeg_mimeType_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_portrait_jpeg_mimeType_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_portrait_jpeg_mimeType_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_portrait_jpeg_mimeType_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_portrait_jpeg_mimeType_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_portrait_jpeg_filesize"]: AliasType<{
	create?:ResolverInputTypes["PrivateMediaDocAccessFields_sizes_portrait_jpeg_filesize_Create"],
	read?:ResolverInputTypes["PrivateMediaDocAccessFields_sizes_portrait_jpeg_filesize_Read"],
	update?:ResolverInputTypes["PrivateMediaDocAccessFields_sizes_portrait_jpeg_filesize_Update"],
	delete?:ResolverInputTypes["PrivateMediaDocAccessFields_sizes_portrait_jpeg_filesize_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_portrait_jpeg_filesize_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_portrait_jpeg_filesize_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_portrait_jpeg_filesize_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_portrait_jpeg_filesize_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_portrait_jpeg_filename"]: AliasType<{
	create?:ResolverInputTypes["PrivateMediaDocAccessFields_sizes_portrait_jpeg_filename_Create"],
	read?:ResolverInputTypes["PrivateMediaDocAccessFields_sizes_portrait_jpeg_filename_Read"],
	update?:ResolverInputTypes["PrivateMediaDocAccessFields_sizes_portrait_jpeg_filename_Update"],
	delete?:ResolverInputTypes["PrivateMediaDocAccessFields_sizes_portrait_jpeg_filename_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_portrait_jpeg_filename_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_portrait_jpeg_filename_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_portrait_jpeg_filename_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_portrait_jpeg_filename_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_hero_jpeg"]: AliasType<{
	create?:ResolverInputTypes["PrivateMediaDocAccessFields_sizes_hero_jpeg_Create"],
	read?:ResolverInputTypes["PrivateMediaDocAccessFields_sizes_hero_jpeg_Read"],
	update?:ResolverInputTypes["PrivateMediaDocAccessFields_sizes_hero_jpeg_Update"],
	delete?:ResolverInputTypes["PrivateMediaDocAccessFields_sizes_hero_jpeg_Delete"],
	fields?:ResolverInputTypes["PrivateMediaDocAccessFields_sizes_hero_jpeg_Fields"],
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_hero_jpeg_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_hero_jpeg_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_hero_jpeg_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_hero_jpeg_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_hero_jpeg_Fields"]: AliasType<{
	url?:ResolverInputTypes["PrivateMediaDocAccessFields_sizes_hero_jpeg_url"],
	width?:ResolverInputTypes["PrivateMediaDocAccessFields_sizes_hero_jpeg_width"],
	height?:ResolverInputTypes["PrivateMediaDocAccessFields_sizes_hero_jpeg_height"],
	mimeType?:ResolverInputTypes["PrivateMediaDocAccessFields_sizes_hero_jpeg_mimeType"],
	filesize?:ResolverInputTypes["PrivateMediaDocAccessFields_sizes_hero_jpeg_filesize"],
	filename?:ResolverInputTypes["PrivateMediaDocAccessFields_sizes_hero_jpeg_filename"],
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_hero_jpeg_url"]: AliasType<{
	create?:ResolverInputTypes["PrivateMediaDocAccessFields_sizes_hero_jpeg_url_Create"],
	read?:ResolverInputTypes["PrivateMediaDocAccessFields_sizes_hero_jpeg_url_Read"],
	update?:ResolverInputTypes["PrivateMediaDocAccessFields_sizes_hero_jpeg_url_Update"],
	delete?:ResolverInputTypes["PrivateMediaDocAccessFields_sizes_hero_jpeg_url_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_hero_jpeg_url_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_hero_jpeg_url_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_hero_jpeg_url_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_hero_jpeg_url_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_hero_jpeg_width"]: AliasType<{
	create?:ResolverInputTypes["PrivateMediaDocAccessFields_sizes_hero_jpeg_width_Create"],
	read?:ResolverInputTypes["PrivateMediaDocAccessFields_sizes_hero_jpeg_width_Read"],
	update?:ResolverInputTypes["PrivateMediaDocAccessFields_sizes_hero_jpeg_width_Update"],
	delete?:ResolverInputTypes["PrivateMediaDocAccessFields_sizes_hero_jpeg_width_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_hero_jpeg_width_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_hero_jpeg_width_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_hero_jpeg_width_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_hero_jpeg_width_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_hero_jpeg_height"]: AliasType<{
	create?:ResolverInputTypes["PrivateMediaDocAccessFields_sizes_hero_jpeg_height_Create"],
	read?:ResolverInputTypes["PrivateMediaDocAccessFields_sizes_hero_jpeg_height_Read"],
	update?:ResolverInputTypes["PrivateMediaDocAccessFields_sizes_hero_jpeg_height_Update"],
	delete?:ResolverInputTypes["PrivateMediaDocAccessFields_sizes_hero_jpeg_height_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_hero_jpeg_height_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_hero_jpeg_height_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_hero_jpeg_height_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_hero_jpeg_height_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_hero_jpeg_mimeType"]: AliasType<{
	create?:ResolverInputTypes["PrivateMediaDocAccessFields_sizes_hero_jpeg_mimeType_Create"],
	read?:ResolverInputTypes["PrivateMediaDocAccessFields_sizes_hero_jpeg_mimeType_Read"],
	update?:ResolverInputTypes["PrivateMediaDocAccessFields_sizes_hero_jpeg_mimeType_Update"],
	delete?:ResolverInputTypes["PrivateMediaDocAccessFields_sizes_hero_jpeg_mimeType_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_hero_jpeg_mimeType_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_hero_jpeg_mimeType_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_hero_jpeg_mimeType_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_hero_jpeg_mimeType_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_hero_jpeg_filesize"]: AliasType<{
	create?:ResolverInputTypes["PrivateMediaDocAccessFields_sizes_hero_jpeg_filesize_Create"],
	read?:ResolverInputTypes["PrivateMediaDocAccessFields_sizes_hero_jpeg_filesize_Read"],
	update?:ResolverInputTypes["PrivateMediaDocAccessFields_sizes_hero_jpeg_filesize_Update"],
	delete?:ResolverInputTypes["PrivateMediaDocAccessFields_sizes_hero_jpeg_filesize_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_hero_jpeg_filesize_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_hero_jpeg_filesize_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_hero_jpeg_filesize_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_hero_jpeg_filesize_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_hero_jpeg_filename"]: AliasType<{
	create?:ResolverInputTypes["PrivateMediaDocAccessFields_sizes_hero_jpeg_filename_Create"],
	read?:ResolverInputTypes["PrivateMediaDocAccessFields_sizes_hero_jpeg_filename_Read"],
	update?:ResolverInputTypes["PrivateMediaDocAccessFields_sizes_hero_jpeg_filename_Update"],
	delete?:ResolverInputTypes["PrivateMediaDocAccessFields_sizes_hero_jpeg_filename_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_hero_jpeg_filename_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_hero_jpeg_filename_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_hero_jpeg_filename_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDocAccessFields_sizes_hero_jpeg_filename_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaCreateDocAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaReadDocAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaUpdateDocAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDeleteDocAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["allMedia"]: AliasType<{
	docs?:ResolverInputTypes["Media"],
	hasNextPage?:boolean | `@${string}`,
	hasPrevPage?:boolean | `@${string}`,
	limit?:boolean | `@${string}`,
	nextPage?:boolean | `@${string}`,
	offset?:boolean | `@${string}`,
	page?:boolean | `@${string}`,
	pagingCounter?:boolean | `@${string}`,
	prevPage?:boolean | `@${string}`,
	totalDocs?:boolean | `@${string}`,
	totalPages?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["Media_where"]: {
	alt?: ResolverInputTypes["Media_alt_operator"] | undefined | null,
	updatedAt?: ResolverInputTypes["Media_updatedAt_operator"] | undefined | null,
	createdAt?: ResolverInputTypes["Media_createdAt_operator"] | undefined | null,
	url?: ResolverInputTypes["Media_url_operator"] | undefined | null,
	filename?: ResolverInputTypes["Media_filename_operator"] | undefined | null,
	mimeType?: ResolverInputTypes["Media_mimeType_operator"] | undefined | null,
	filesize?: ResolverInputTypes["Media_filesize_operator"] | undefined | null,
	width?: ResolverInputTypes["Media_width_operator"] | undefined | null,
	height?: ResolverInputTypes["Media_height_operator"] | undefined | null,
	focalX?: ResolverInputTypes["Media_focalX_operator"] | undefined | null,
	focalY?: ResolverInputTypes["Media_focalY_operator"] | undefined | null,
	sizes__small__url?: ResolverInputTypes["Media_sizes__small__url_operator"] | undefined | null,
	sizes__small__width?: ResolverInputTypes["Media_sizes__small__width_operator"] | undefined | null,
	sizes__small__height?: ResolverInputTypes["Media_sizes__small__height_operator"] | undefined | null,
	sizes__small__mimeType?: ResolverInputTypes["Media_sizes__small__mimeType_operator"] | undefined | null,
	sizes__small__filesize?: ResolverInputTypes["Media_sizes__small__filesize_operator"] | undefined | null,
	sizes__small__filename?: ResolverInputTypes["Media_sizes__small__filename_operator"] | undefined | null,
	sizes__medium__url?: ResolverInputTypes["Media_sizes__medium__url_operator"] | undefined | null,
	sizes__medium__width?: ResolverInputTypes["Media_sizes__medium__width_operator"] | undefined | null,
	sizes__medium__height?: ResolverInputTypes["Media_sizes__medium__height_operator"] | undefined | null,
	sizes__medium__mimeType?: ResolverInputTypes["Media_sizes__medium__mimeType_operator"] | undefined | null,
	sizes__medium__filesize?: ResolverInputTypes["Media_sizes__medium__filesize_operator"] | undefined | null,
	sizes__medium__filename?: ResolverInputTypes["Media_sizes__medium__filename_operator"] | undefined | null,
	sizes__large__url?: ResolverInputTypes["Media_sizes__large__url_operator"] | undefined | null,
	sizes__large__width?: ResolverInputTypes["Media_sizes__large__width_operator"] | undefined | null,
	sizes__large__height?: ResolverInputTypes["Media_sizes__large__height_operator"] | undefined | null,
	sizes__large__mimeType?: ResolverInputTypes["Media_sizes__large__mimeType_operator"] | undefined | null,
	sizes__large__filesize?: ResolverInputTypes["Media_sizes__large__filesize_operator"] | undefined | null,
	sizes__large__filename?: ResolverInputTypes["Media_sizes__large__filename_operator"] | undefined | null,
	sizes__small_jpeg__url?: ResolverInputTypes["Media_sizes__small_jpeg__url_operator"] | undefined | null,
	sizes__small_jpeg__width?: ResolverInputTypes["Media_sizes__small_jpeg__width_operator"] | undefined | null,
	sizes__small_jpeg__height?: ResolverInputTypes["Media_sizes__small_jpeg__height_operator"] | undefined | null,
	sizes__small_jpeg__mimeType?: ResolverInputTypes["Media_sizes__small_jpeg__mimeType_operator"] | undefined | null,
	sizes__small_jpeg__filesize?: ResolverInputTypes["Media_sizes__small_jpeg__filesize_operator"] | undefined | null,
	sizes__small_jpeg__filename?: ResolverInputTypes["Media_sizes__small_jpeg__filename_operator"] | undefined | null,
	sizes__medium_jpeg__url?: ResolverInputTypes["Media_sizes__medium_jpeg__url_operator"] | undefined | null,
	sizes__medium_jpeg__width?: ResolverInputTypes["Media_sizes__medium_jpeg__width_operator"] | undefined | null,
	sizes__medium_jpeg__height?: ResolverInputTypes["Media_sizes__medium_jpeg__height_operator"] | undefined | null,
	sizes__medium_jpeg__mimeType?: ResolverInputTypes["Media_sizes__medium_jpeg__mimeType_operator"] | undefined | null,
	sizes__medium_jpeg__filesize?: ResolverInputTypes["Media_sizes__medium_jpeg__filesize_operator"] | undefined | null,
	sizes__medium_jpeg__filename?: ResolverInputTypes["Media_sizes__medium_jpeg__filename_operator"] | undefined | null,
	sizes__large_jpeg__url?: ResolverInputTypes["Media_sizes__large_jpeg__url_operator"] | undefined | null,
	sizes__large_jpeg__width?: ResolverInputTypes["Media_sizes__large_jpeg__width_operator"] | undefined | null,
	sizes__large_jpeg__height?: ResolverInputTypes["Media_sizes__large_jpeg__height_operator"] | undefined | null,
	sizes__large_jpeg__mimeType?: ResolverInputTypes["Media_sizes__large_jpeg__mimeType_operator"] | undefined | null,
	sizes__large_jpeg__filesize?: ResolverInputTypes["Media_sizes__large_jpeg__filesize_operator"] | undefined | null,
	sizes__large_jpeg__filename?: ResolverInputTypes["Media_sizes__large_jpeg__filename_operator"] | undefined | null,
	id?: ResolverInputTypes["Media_id_operator"] | undefined | null,
	AND?: Array<ResolverInputTypes["Media_where_and"] | undefined | null> | undefined | null,
	OR?: Array<ResolverInputTypes["Media_where_or"] | undefined | null> | undefined | null
};
	["Media_alt_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Media_updatedAt_operator"]: {
	equals?: ResolverInputTypes["DateTime"] | undefined | null,
	not_equals?: ResolverInputTypes["DateTime"] | undefined | null,
	greater_than_equal?: ResolverInputTypes["DateTime"] | undefined | null,
	greater_than?: ResolverInputTypes["DateTime"] | undefined | null,
	less_than_equal?: ResolverInputTypes["DateTime"] | undefined | null,
	less_than?: ResolverInputTypes["DateTime"] | undefined | null,
	like?: ResolverInputTypes["DateTime"] | undefined | null,
	exists?: boolean | undefined | null
};
	["Media_createdAt_operator"]: {
	equals?: ResolverInputTypes["DateTime"] | undefined | null,
	not_equals?: ResolverInputTypes["DateTime"] | undefined | null,
	greater_than_equal?: ResolverInputTypes["DateTime"] | undefined | null,
	greater_than?: ResolverInputTypes["DateTime"] | undefined | null,
	less_than_equal?: ResolverInputTypes["DateTime"] | undefined | null,
	less_than?: ResolverInputTypes["DateTime"] | undefined | null,
	like?: ResolverInputTypes["DateTime"] | undefined | null,
	exists?: boolean | undefined | null
};
	["Media_url_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Media_filename_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Media_mimeType_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Media_filesize_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Media_width_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Media_height_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Media_focalX_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Media_focalY_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Media_sizes__small__url_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Media_sizes__small__width_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Media_sizes__small__height_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Media_sizes__small__mimeType_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Media_sizes__small__filesize_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Media_sizes__small__filename_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Media_sizes__medium__url_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Media_sizes__medium__width_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Media_sizes__medium__height_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Media_sizes__medium__mimeType_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Media_sizes__medium__filesize_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Media_sizes__medium__filename_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Media_sizes__large__url_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Media_sizes__large__width_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Media_sizes__large__height_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Media_sizes__large__mimeType_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Media_sizes__large__filesize_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Media_sizes__large__filename_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Media_sizes__small_jpeg__url_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Media_sizes__small_jpeg__width_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Media_sizes__small_jpeg__height_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Media_sizes__small_jpeg__mimeType_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Media_sizes__small_jpeg__filesize_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Media_sizes__small_jpeg__filename_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Media_sizes__medium_jpeg__url_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Media_sizes__medium_jpeg__width_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Media_sizes__medium_jpeg__height_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Media_sizes__medium_jpeg__mimeType_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Media_sizes__medium_jpeg__filesize_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Media_sizes__medium_jpeg__filename_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Media_sizes__large_jpeg__url_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Media_sizes__large_jpeg__width_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Media_sizes__large_jpeg__height_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Media_sizes__large_jpeg__mimeType_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Media_sizes__large_jpeg__filesize_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Media_sizes__large_jpeg__filename_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Media_id_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Media_where_and"]: {
	alt?: ResolverInputTypes["Media_alt_operator"] | undefined | null,
	updatedAt?: ResolverInputTypes["Media_updatedAt_operator"] | undefined | null,
	createdAt?: ResolverInputTypes["Media_createdAt_operator"] | undefined | null,
	url?: ResolverInputTypes["Media_url_operator"] | undefined | null,
	filename?: ResolverInputTypes["Media_filename_operator"] | undefined | null,
	mimeType?: ResolverInputTypes["Media_mimeType_operator"] | undefined | null,
	filesize?: ResolverInputTypes["Media_filesize_operator"] | undefined | null,
	width?: ResolverInputTypes["Media_width_operator"] | undefined | null,
	height?: ResolverInputTypes["Media_height_operator"] | undefined | null,
	focalX?: ResolverInputTypes["Media_focalX_operator"] | undefined | null,
	focalY?: ResolverInputTypes["Media_focalY_operator"] | undefined | null,
	sizes__small__url?: ResolverInputTypes["Media_sizes__small__url_operator"] | undefined | null,
	sizes__small__width?: ResolverInputTypes["Media_sizes__small__width_operator"] | undefined | null,
	sizes__small__height?: ResolverInputTypes["Media_sizes__small__height_operator"] | undefined | null,
	sizes__small__mimeType?: ResolverInputTypes["Media_sizes__small__mimeType_operator"] | undefined | null,
	sizes__small__filesize?: ResolverInputTypes["Media_sizes__small__filesize_operator"] | undefined | null,
	sizes__small__filename?: ResolverInputTypes["Media_sizes__small__filename_operator"] | undefined | null,
	sizes__medium__url?: ResolverInputTypes["Media_sizes__medium__url_operator"] | undefined | null,
	sizes__medium__width?: ResolverInputTypes["Media_sizes__medium__width_operator"] | undefined | null,
	sizes__medium__height?: ResolverInputTypes["Media_sizes__medium__height_operator"] | undefined | null,
	sizes__medium__mimeType?: ResolverInputTypes["Media_sizes__medium__mimeType_operator"] | undefined | null,
	sizes__medium__filesize?: ResolverInputTypes["Media_sizes__medium__filesize_operator"] | undefined | null,
	sizes__medium__filename?: ResolverInputTypes["Media_sizes__medium__filename_operator"] | undefined | null,
	sizes__large__url?: ResolverInputTypes["Media_sizes__large__url_operator"] | undefined | null,
	sizes__large__width?: ResolverInputTypes["Media_sizes__large__width_operator"] | undefined | null,
	sizes__large__height?: ResolverInputTypes["Media_sizes__large__height_operator"] | undefined | null,
	sizes__large__mimeType?: ResolverInputTypes["Media_sizes__large__mimeType_operator"] | undefined | null,
	sizes__large__filesize?: ResolverInputTypes["Media_sizes__large__filesize_operator"] | undefined | null,
	sizes__large__filename?: ResolverInputTypes["Media_sizes__large__filename_operator"] | undefined | null,
	sizes__small_jpeg__url?: ResolverInputTypes["Media_sizes__small_jpeg__url_operator"] | undefined | null,
	sizes__small_jpeg__width?: ResolverInputTypes["Media_sizes__small_jpeg__width_operator"] | undefined | null,
	sizes__small_jpeg__height?: ResolverInputTypes["Media_sizes__small_jpeg__height_operator"] | undefined | null,
	sizes__small_jpeg__mimeType?: ResolverInputTypes["Media_sizes__small_jpeg__mimeType_operator"] | undefined | null,
	sizes__small_jpeg__filesize?: ResolverInputTypes["Media_sizes__small_jpeg__filesize_operator"] | undefined | null,
	sizes__small_jpeg__filename?: ResolverInputTypes["Media_sizes__small_jpeg__filename_operator"] | undefined | null,
	sizes__medium_jpeg__url?: ResolverInputTypes["Media_sizes__medium_jpeg__url_operator"] | undefined | null,
	sizes__medium_jpeg__width?: ResolverInputTypes["Media_sizes__medium_jpeg__width_operator"] | undefined | null,
	sizes__medium_jpeg__height?: ResolverInputTypes["Media_sizes__medium_jpeg__height_operator"] | undefined | null,
	sizes__medium_jpeg__mimeType?: ResolverInputTypes["Media_sizes__medium_jpeg__mimeType_operator"] | undefined | null,
	sizes__medium_jpeg__filesize?: ResolverInputTypes["Media_sizes__medium_jpeg__filesize_operator"] | undefined | null,
	sizes__medium_jpeg__filename?: ResolverInputTypes["Media_sizes__medium_jpeg__filename_operator"] | undefined | null,
	sizes__large_jpeg__url?: ResolverInputTypes["Media_sizes__large_jpeg__url_operator"] | undefined | null,
	sizes__large_jpeg__width?: ResolverInputTypes["Media_sizes__large_jpeg__width_operator"] | undefined | null,
	sizes__large_jpeg__height?: ResolverInputTypes["Media_sizes__large_jpeg__height_operator"] | undefined | null,
	sizes__large_jpeg__mimeType?: ResolverInputTypes["Media_sizes__large_jpeg__mimeType_operator"] | undefined | null,
	sizes__large_jpeg__filesize?: ResolverInputTypes["Media_sizes__large_jpeg__filesize_operator"] | undefined | null,
	sizes__large_jpeg__filename?: ResolverInputTypes["Media_sizes__large_jpeg__filename_operator"] | undefined | null,
	id?: ResolverInputTypes["Media_id_operator"] | undefined | null,
	AND?: Array<ResolverInputTypes["Media_where_and"] | undefined | null> | undefined | null,
	OR?: Array<ResolverInputTypes["Media_where_or"] | undefined | null> | undefined | null
};
	["Media_where_or"]: {
	alt?: ResolverInputTypes["Media_alt_operator"] | undefined | null,
	updatedAt?: ResolverInputTypes["Media_updatedAt_operator"] | undefined | null,
	createdAt?: ResolverInputTypes["Media_createdAt_operator"] | undefined | null,
	url?: ResolverInputTypes["Media_url_operator"] | undefined | null,
	filename?: ResolverInputTypes["Media_filename_operator"] | undefined | null,
	mimeType?: ResolverInputTypes["Media_mimeType_operator"] | undefined | null,
	filesize?: ResolverInputTypes["Media_filesize_operator"] | undefined | null,
	width?: ResolverInputTypes["Media_width_operator"] | undefined | null,
	height?: ResolverInputTypes["Media_height_operator"] | undefined | null,
	focalX?: ResolverInputTypes["Media_focalX_operator"] | undefined | null,
	focalY?: ResolverInputTypes["Media_focalY_operator"] | undefined | null,
	sizes__small__url?: ResolverInputTypes["Media_sizes__small__url_operator"] | undefined | null,
	sizes__small__width?: ResolverInputTypes["Media_sizes__small__width_operator"] | undefined | null,
	sizes__small__height?: ResolverInputTypes["Media_sizes__small__height_operator"] | undefined | null,
	sizes__small__mimeType?: ResolverInputTypes["Media_sizes__small__mimeType_operator"] | undefined | null,
	sizes__small__filesize?: ResolverInputTypes["Media_sizes__small__filesize_operator"] | undefined | null,
	sizes__small__filename?: ResolverInputTypes["Media_sizes__small__filename_operator"] | undefined | null,
	sizes__medium__url?: ResolverInputTypes["Media_sizes__medium__url_operator"] | undefined | null,
	sizes__medium__width?: ResolverInputTypes["Media_sizes__medium__width_operator"] | undefined | null,
	sizes__medium__height?: ResolverInputTypes["Media_sizes__medium__height_operator"] | undefined | null,
	sizes__medium__mimeType?: ResolverInputTypes["Media_sizes__medium__mimeType_operator"] | undefined | null,
	sizes__medium__filesize?: ResolverInputTypes["Media_sizes__medium__filesize_operator"] | undefined | null,
	sizes__medium__filename?: ResolverInputTypes["Media_sizes__medium__filename_operator"] | undefined | null,
	sizes__large__url?: ResolverInputTypes["Media_sizes__large__url_operator"] | undefined | null,
	sizes__large__width?: ResolverInputTypes["Media_sizes__large__width_operator"] | undefined | null,
	sizes__large__height?: ResolverInputTypes["Media_sizes__large__height_operator"] | undefined | null,
	sizes__large__mimeType?: ResolverInputTypes["Media_sizes__large__mimeType_operator"] | undefined | null,
	sizes__large__filesize?: ResolverInputTypes["Media_sizes__large__filesize_operator"] | undefined | null,
	sizes__large__filename?: ResolverInputTypes["Media_sizes__large__filename_operator"] | undefined | null,
	sizes__small_jpeg__url?: ResolverInputTypes["Media_sizes__small_jpeg__url_operator"] | undefined | null,
	sizes__small_jpeg__width?: ResolverInputTypes["Media_sizes__small_jpeg__width_operator"] | undefined | null,
	sizes__small_jpeg__height?: ResolverInputTypes["Media_sizes__small_jpeg__height_operator"] | undefined | null,
	sizes__small_jpeg__mimeType?: ResolverInputTypes["Media_sizes__small_jpeg__mimeType_operator"] | undefined | null,
	sizes__small_jpeg__filesize?: ResolverInputTypes["Media_sizes__small_jpeg__filesize_operator"] | undefined | null,
	sizes__small_jpeg__filename?: ResolverInputTypes["Media_sizes__small_jpeg__filename_operator"] | undefined | null,
	sizes__medium_jpeg__url?: ResolverInputTypes["Media_sizes__medium_jpeg__url_operator"] | undefined | null,
	sizes__medium_jpeg__width?: ResolverInputTypes["Media_sizes__medium_jpeg__width_operator"] | undefined | null,
	sizes__medium_jpeg__height?: ResolverInputTypes["Media_sizes__medium_jpeg__height_operator"] | undefined | null,
	sizes__medium_jpeg__mimeType?: ResolverInputTypes["Media_sizes__medium_jpeg__mimeType_operator"] | undefined | null,
	sizes__medium_jpeg__filesize?: ResolverInputTypes["Media_sizes__medium_jpeg__filesize_operator"] | undefined | null,
	sizes__medium_jpeg__filename?: ResolverInputTypes["Media_sizes__medium_jpeg__filename_operator"] | undefined | null,
	sizes__large_jpeg__url?: ResolverInputTypes["Media_sizes__large_jpeg__url_operator"] | undefined | null,
	sizes__large_jpeg__width?: ResolverInputTypes["Media_sizes__large_jpeg__width_operator"] | undefined | null,
	sizes__large_jpeg__height?: ResolverInputTypes["Media_sizes__large_jpeg__height_operator"] | undefined | null,
	sizes__large_jpeg__mimeType?: ResolverInputTypes["Media_sizes__large_jpeg__mimeType_operator"] | undefined | null,
	sizes__large_jpeg__filesize?: ResolverInputTypes["Media_sizes__large_jpeg__filesize_operator"] | undefined | null,
	sizes__large_jpeg__filename?: ResolverInputTypes["Media_sizes__large_jpeg__filename_operator"] | undefined | null,
	id?: ResolverInputTypes["Media_id_operator"] | undefined | null,
	AND?: Array<ResolverInputTypes["Media_where_and"] | undefined | null> | undefined | null,
	OR?: Array<ResolverInputTypes["Media_where_or"] | undefined | null> | undefined | null
};
	["countallMedia"]: AliasType<{
	totalDocs?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["mediaDocAccess"]: AliasType<{
	fields?:ResolverInputTypes["MediaDocAccessFields"],
	create?:ResolverInputTypes["MediaCreateDocAccess"],
	read?:ResolverInputTypes["MediaReadDocAccess"],
	update?:ResolverInputTypes["MediaUpdateDocAccess"],
	delete?:ResolverInputTypes["MediaDeleteDocAccess"],
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields"]: AliasType<{
	alt?:ResolverInputTypes["MediaDocAccessFields_alt"],
	updatedAt?:ResolverInputTypes["MediaDocAccessFields_updatedAt"],
	createdAt?:ResolverInputTypes["MediaDocAccessFields_createdAt"],
	url?:ResolverInputTypes["MediaDocAccessFields_url"],
	filename?:ResolverInputTypes["MediaDocAccessFields_filename"],
	mimeType?:ResolverInputTypes["MediaDocAccessFields_mimeType"],
	filesize?:ResolverInputTypes["MediaDocAccessFields_filesize"],
	width?:ResolverInputTypes["MediaDocAccessFields_width"],
	height?:ResolverInputTypes["MediaDocAccessFields_height"],
	focalX?:ResolverInputTypes["MediaDocAccessFields_focalX"],
	focalY?:ResolverInputTypes["MediaDocAccessFields_focalY"],
	sizes?:ResolverInputTypes["MediaDocAccessFields_sizes"],
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_alt"]: AliasType<{
	create?:ResolverInputTypes["MediaDocAccessFields_alt_Create"],
	read?:ResolverInputTypes["MediaDocAccessFields_alt_Read"],
	update?:ResolverInputTypes["MediaDocAccessFields_alt_Update"],
	delete?:ResolverInputTypes["MediaDocAccessFields_alt_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_alt_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_alt_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_alt_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_alt_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_updatedAt"]: AliasType<{
	create?:ResolverInputTypes["MediaDocAccessFields_updatedAt_Create"],
	read?:ResolverInputTypes["MediaDocAccessFields_updatedAt_Read"],
	update?:ResolverInputTypes["MediaDocAccessFields_updatedAt_Update"],
	delete?:ResolverInputTypes["MediaDocAccessFields_updatedAt_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_updatedAt_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_updatedAt_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_updatedAt_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_updatedAt_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_createdAt"]: AliasType<{
	create?:ResolverInputTypes["MediaDocAccessFields_createdAt_Create"],
	read?:ResolverInputTypes["MediaDocAccessFields_createdAt_Read"],
	update?:ResolverInputTypes["MediaDocAccessFields_createdAt_Update"],
	delete?:ResolverInputTypes["MediaDocAccessFields_createdAt_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_createdAt_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_createdAt_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_createdAt_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_createdAt_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_url"]: AliasType<{
	create?:ResolverInputTypes["MediaDocAccessFields_url_Create"],
	read?:ResolverInputTypes["MediaDocAccessFields_url_Read"],
	update?:ResolverInputTypes["MediaDocAccessFields_url_Update"],
	delete?:ResolverInputTypes["MediaDocAccessFields_url_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_url_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_url_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_url_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_url_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_filename"]: AliasType<{
	create?:ResolverInputTypes["MediaDocAccessFields_filename_Create"],
	read?:ResolverInputTypes["MediaDocAccessFields_filename_Read"],
	update?:ResolverInputTypes["MediaDocAccessFields_filename_Update"],
	delete?:ResolverInputTypes["MediaDocAccessFields_filename_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_filename_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_filename_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_filename_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_filename_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_mimeType"]: AliasType<{
	create?:ResolverInputTypes["MediaDocAccessFields_mimeType_Create"],
	read?:ResolverInputTypes["MediaDocAccessFields_mimeType_Read"],
	update?:ResolverInputTypes["MediaDocAccessFields_mimeType_Update"],
	delete?:ResolverInputTypes["MediaDocAccessFields_mimeType_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_mimeType_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_mimeType_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_mimeType_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_mimeType_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_filesize"]: AliasType<{
	create?:ResolverInputTypes["MediaDocAccessFields_filesize_Create"],
	read?:ResolverInputTypes["MediaDocAccessFields_filesize_Read"],
	update?:ResolverInputTypes["MediaDocAccessFields_filesize_Update"],
	delete?:ResolverInputTypes["MediaDocAccessFields_filesize_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_filesize_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_filesize_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_filesize_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_filesize_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_width"]: AliasType<{
	create?:ResolverInputTypes["MediaDocAccessFields_width_Create"],
	read?:ResolverInputTypes["MediaDocAccessFields_width_Read"],
	update?:ResolverInputTypes["MediaDocAccessFields_width_Update"],
	delete?:ResolverInputTypes["MediaDocAccessFields_width_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_width_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_width_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_width_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_width_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_height"]: AliasType<{
	create?:ResolverInputTypes["MediaDocAccessFields_height_Create"],
	read?:ResolverInputTypes["MediaDocAccessFields_height_Read"],
	update?:ResolverInputTypes["MediaDocAccessFields_height_Update"],
	delete?:ResolverInputTypes["MediaDocAccessFields_height_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_height_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_height_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_height_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_height_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_focalX"]: AliasType<{
	create?:ResolverInputTypes["MediaDocAccessFields_focalX_Create"],
	read?:ResolverInputTypes["MediaDocAccessFields_focalX_Read"],
	update?:ResolverInputTypes["MediaDocAccessFields_focalX_Update"],
	delete?:ResolverInputTypes["MediaDocAccessFields_focalX_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_focalX_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_focalX_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_focalX_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_focalX_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_focalY"]: AliasType<{
	create?:ResolverInputTypes["MediaDocAccessFields_focalY_Create"],
	read?:ResolverInputTypes["MediaDocAccessFields_focalY_Read"],
	update?:ResolverInputTypes["MediaDocAccessFields_focalY_Update"],
	delete?:ResolverInputTypes["MediaDocAccessFields_focalY_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_focalY_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_focalY_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_focalY_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_focalY_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes"]: AliasType<{
	create?:ResolverInputTypes["MediaDocAccessFields_sizes_Create"],
	read?:ResolverInputTypes["MediaDocAccessFields_sizes_Read"],
	update?:ResolverInputTypes["MediaDocAccessFields_sizes_Update"],
	delete?:ResolverInputTypes["MediaDocAccessFields_sizes_Delete"],
	fields?:ResolverInputTypes["MediaDocAccessFields_sizes_Fields"],
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_Fields"]: AliasType<{
	small?:ResolverInputTypes["MediaDocAccessFields_sizes_small"],
	medium?:ResolverInputTypes["MediaDocAccessFields_sizes_medium"],
	large?:ResolverInputTypes["MediaDocAccessFields_sizes_large"],
	small_jpeg?:ResolverInputTypes["MediaDocAccessFields_sizes_small_jpeg"],
	medium_jpeg?:ResolverInputTypes["MediaDocAccessFields_sizes_medium_jpeg"],
	large_jpeg?:ResolverInputTypes["MediaDocAccessFields_sizes_large_jpeg"],
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_small"]: AliasType<{
	create?:ResolverInputTypes["MediaDocAccessFields_sizes_small_Create"],
	read?:ResolverInputTypes["MediaDocAccessFields_sizes_small_Read"],
	update?:ResolverInputTypes["MediaDocAccessFields_sizes_small_Update"],
	delete?:ResolverInputTypes["MediaDocAccessFields_sizes_small_Delete"],
	fields?:ResolverInputTypes["MediaDocAccessFields_sizes_small_Fields"],
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_small_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_small_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_small_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_small_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_small_Fields"]: AliasType<{
	url?:ResolverInputTypes["MediaDocAccessFields_sizes_small_url"],
	width?:ResolverInputTypes["MediaDocAccessFields_sizes_small_width"],
	height?:ResolverInputTypes["MediaDocAccessFields_sizes_small_height"],
	mimeType?:ResolverInputTypes["MediaDocAccessFields_sizes_small_mimeType"],
	filesize?:ResolverInputTypes["MediaDocAccessFields_sizes_small_filesize"],
	filename?:ResolverInputTypes["MediaDocAccessFields_sizes_small_filename"],
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_small_url"]: AliasType<{
	create?:ResolverInputTypes["MediaDocAccessFields_sizes_small_url_Create"],
	read?:ResolverInputTypes["MediaDocAccessFields_sizes_small_url_Read"],
	update?:ResolverInputTypes["MediaDocAccessFields_sizes_small_url_Update"],
	delete?:ResolverInputTypes["MediaDocAccessFields_sizes_small_url_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_small_url_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_small_url_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_small_url_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_small_url_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_small_width"]: AliasType<{
	create?:ResolverInputTypes["MediaDocAccessFields_sizes_small_width_Create"],
	read?:ResolverInputTypes["MediaDocAccessFields_sizes_small_width_Read"],
	update?:ResolverInputTypes["MediaDocAccessFields_sizes_small_width_Update"],
	delete?:ResolverInputTypes["MediaDocAccessFields_sizes_small_width_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_small_width_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_small_width_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_small_width_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_small_width_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_small_height"]: AliasType<{
	create?:ResolverInputTypes["MediaDocAccessFields_sizes_small_height_Create"],
	read?:ResolverInputTypes["MediaDocAccessFields_sizes_small_height_Read"],
	update?:ResolverInputTypes["MediaDocAccessFields_sizes_small_height_Update"],
	delete?:ResolverInputTypes["MediaDocAccessFields_sizes_small_height_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_small_height_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_small_height_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_small_height_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_small_height_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_small_mimeType"]: AliasType<{
	create?:ResolverInputTypes["MediaDocAccessFields_sizes_small_mimeType_Create"],
	read?:ResolverInputTypes["MediaDocAccessFields_sizes_small_mimeType_Read"],
	update?:ResolverInputTypes["MediaDocAccessFields_sizes_small_mimeType_Update"],
	delete?:ResolverInputTypes["MediaDocAccessFields_sizes_small_mimeType_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_small_mimeType_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_small_mimeType_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_small_mimeType_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_small_mimeType_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_small_filesize"]: AliasType<{
	create?:ResolverInputTypes["MediaDocAccessFields_sizes_small_filesize_Create"],
	read?:ResolverInputTypes["MediaDocAccessFields_sizes_small_filesize_Read"],
	update?:ResolverInputTypes["MediaDocAccessFields_sizes_small_filesize_Update"],
	delete?:ResolverInputTypes["MediaDocAccessFields_sizes_small_filesize_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_small_filesize_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_small_filesize_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_small_filesize_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_small_filesize_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_small_filename"]: AliasType<{
	create?:ResolverInputTypes["MediaDocAccessFields_sizes_small_filename_Create"],
	read?:ResolverInputTypes["MediaDocAccessFields_sizes_small_filename_Read"],
	update?:ResolverInputTypes["MediaDocAccessFields_sizes_small_filename_Update"],
	delete?:ResolverInputTypes["MediaDocAccessFields_sizes_small_filename_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_small_filename_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_small_filename_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_small_filename_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_small_filename_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_medium"]: AliasType<{
	create?:ResolverInputTypes["MediaDocAccessFields_sizes_medium_Create"],
	read?:ResolverInputTypes["MediaDocAccessFields_sizes_medium_Read"],
	update?:ResolverInputTypes["MediaDocAccessFields_sizes_medium_Update"],
	delete?:ResolverInputTypes["MediaDocAccessFields_sizes_medium_Delete"],
	fields?:ResolverInputTypes["MediaDocAccessFields_sizes_medium_Fields"],
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_medium_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_medium_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_medium_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_medium_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_medium_Fields"]: AliasType<{
	url?:ResolverInputTypes["MediaDocAccessFields_sizes_medium_url"],
	width?:ResolverInputTypes["MediaDocAccessFields_sizes_medium_width"],
	height?:ResolverInputTypes["MediaDocAccessFields_sizes_medium_height"],
	mimeType?:ResolverInputTypes["MediaDocAccessFields_sizes_medium_mimeType"],
	filesize?:ResolverInputTypes["MediaDocAccessFields_sizes_medium_filesize"],
	filename?:ResolverInputTypes["MediaDocAccessFields_sizes_medium_filename"],
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_medium_url"]: AliasType<{
	create?:ResolverInputTypes["MediaDocAccessFields_sizes_medium_url_Create"],
	read?:ResolverInputTypes["MediaDocAccessFields_sizes_medium_url_Read"],
	update?:ResolverInputTypes["MediaDocAccessFields_sizes_medium_url_Update"],
	delete?:ResolverInputTypes["MediaDocAccessFields_sizes_medium_url_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_medium_url_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_medium_url_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_medium_url_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_medium_url_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_medium_width"]: AliasType<{
	create?:ResolverInputTypes["MediaDocAccessFields_sizes_medium_width_Create"],
	read?:ResolverInputTypes["MediaDocAccessFields_sizes_medium_width_Read"],
	update?:ResolverInputTypes["MediaDocAccessFields_sizes_medium_width_Update"],
	delete?:ResolverInputTypes["MediaDocAccessFields_sizes_medium_width_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_medium_width_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_medium_width_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_medium_width_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_medium_width_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_medium_height"]: AliasType<{
	create?:ResolverInputTypes["MediaDocAccessFields_sizes_medium_height_Create"],
	read?:ResolverInputTypes["MediaDocAccessFields_sizes_medium_height_Read"],
	update?:ResolverInputTypes["MediaDocAccessFields_sizes_medium_height_Update"],
	delete?:ResolverInputTypes["MediaDocAccessFields_sizes_medium_height_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_medium_height_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_medium_height_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_medium_height_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_medium_height_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_medium_mimeType"]: AliasType<{
	create?:ResolverInputTypes["MediaDocAccessFields_sizes_medium_mimeType_Create"],
	read?:ResolverInputTypes["MediaDocAccessFields_sizes_medium_mimeType_Read"],
	update?:ResolverInputTypes["MediaDocAccessFields_sizes_medium_mimeType_Update"],
	delete?:ResolverInputTypes["MediaDocAccessFields_sizes_medium_mimeType_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_medium_mimeType_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_medium_mimeType_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_medium_mimeType_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_medium_mimeType_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_medium_filesize"]: AliasType<{
	create?:ResolverInputTypes["MediaDocAccessFields_sizes_medium_filesize_Create"],
	read?:ResolverInputTypes["MediaDocAccessFields_sizes_medium_filesize_Read"],
	update?:ResolverInputTypes["MediaDocAccessFields_sizes_medium_filesize_Update"],
	delete?:ResolverInputTypes["MediaDocAccessFields_sizes_medium_filesize_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_medium_filesize_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_medium_filesize_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_medium_filesize_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_medium_filesize_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_medium_filename"]: AliasType<{
	create?:ResolverInputTypes["MediaDocAccessFields_sizes_medium_filename_Create"],
	read?:ResolverInputTypes["MediaDocAccessFields_sizes_medium_filename_Read"],
	update?:ResolverInputTypes["MediaDocAccessFields_sizes_medium_filename_Update"],
	delete?:ResolverInputTypes["MediaDocAccessFields_sizes_medium_filename_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_medium_filename_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_medium_filename_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_medium_filename_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_medium_filename_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_large"]: AliasType<{
	create?:ResolverInputTypes["MediaDocAccessFields_sizes_large_Create"],
	read?:ResolverInputTypes["MediaDocAccessFields_sizes_large_Read"],
	update?:ResolverInputTypes["MediaDocAccessFields_sizes_large_Update"],
	delete?:ResolverInputTypes["MediaDocAccessFields_sizes_large_Delete"],
	fields?:ResolverInputTypes["MediaDocAccessFields_sizes_large_Fields"],
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_large_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_large_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_large_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_large_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_large_Fields"]: AliasType<{
	url?:ResolverInputTypes["MediaDocAccessFields_sizes_large_url"],
	width?:ResolverInputTypes["MediaDocAccessFields_sizes_large_width"],
	height?:ResolverInputTypes["MediaDocAccessFields_sizes_large_height"],
	mimeType?:ResolverInputTypes["MediaDocAccessFields_sizes_large_mimeType"],
	filesize?:ResolverInputTypes["MediaDocAccessFields_sizes_large_filesize"],
	filename?:ResolverInputTypes["MediaDocAccessFields_sizes_large_filename"],
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_large_url"]: AliasType<{
	create?:ResolverInputTypes["MediaDocAccessFields_sizes_large_url_Create"],
	read?:ResolverInputTypes["MediaDocAccessFields_sizes_large_url_Read"],
	update?:ResolverInputTypes["MediaDocAccessFields_sizes_large_url_Update"],
	delete?:ResolverInputTypes["MediaDocAccessFields_sizes_large_url_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_large_url_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_large_url_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_large_url_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_large_url_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_large_width"]: AliasType<{
	create?:ResolverInputTypes["MediaDocAccessFields_sizes_large_width_Create"],
	read?:ResolverInputTypes["MediaDocAccessFields_sizes_large_width_Read"],
	update?:ResolverInputTypes["MediaDocAccessFields_sizes_large_width_Update"],
	delete?:ResolverInputTypes["MediaDocAccessFields_sizes_large_width_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_large_width_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_large_width_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_large_width_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_large_width_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_large_height"]: AliasType<{
	create?:ResolverInputTypes["MediaDocAccessFields_sizes_large_height_Create"],
	read?:ResolverInputTypes["MediaDocAccessFields_sizes_large_height_Read"],
	update?:ResolverInputTypes["MediaDocAccessFields_sizes_large_height_Update"],
	delete?:ResolverInputTypes["MediaDocAccessFields_sizes_large_height_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_large_height_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_large_height_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_large_height_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_large_height_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_large_mimeType"]: AliasType<{
	create?:ResolverInputTypes["MediaDocAccessFields_sizes_large_mimeType_Create"],
	read?:ResolverInputTypes["MediaDocAccessFields_sizes_large_mimeType_Read"],
	update?:ResolverInputTypes["MediaDocAccessFields_sizes_large_mimeType_Update"],
	delete?:ResolverInputTypes["MediaDocAccessFields_sizes_large_mimeType_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_large_mimeType_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_large_mimeType_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_large_mimeType_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_large_mimeType_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_large_filesize"]: AliasType<{
	create?:ResolverInputTypes["MediaDocAccessFields_sizes_large_filesize_Create"],
	read?:ResolverInputTypes["MediaDocAccessFields_sizes_large_filesize_Read"],
	update?:ResolverInputTypes["MediaDocAccessFields_sizes_large_filesize_Update"],
	delete?:ResolverInputTypes["MediaDocAccessFields_sizes_large_filesize_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_large_filesize_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_large_filesize_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_large_filesize_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_large_filesize_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_large_filename"]: AliasType<{
	create?:ResolverInputTypes["MediaDocAccessFields_sizes_large_filename_Create"],
	read?:ResolverInputTypes["MediaDocAccessFields_sizes_large_filename_Read"],
	update?:ResolverInputTypes["MediaDocAccessFields_sizes_large_filename_Update"],
	delete?:ResolverInputTypes["MediaDocAccessFields_sizes_large_filename_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_large_filename_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_large_filename_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_large_filename_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_large_filename_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_small_jpeg"]: AliasType<{
	create?:ResolverInputTypes["MediaDocAccessFields_sizes_small_jpeg_Create"],
	read?:ResolverInputTypes["MediaDocAccessFields_sizes_small_jpeg_Read"],
	update?:ResolverInputTypes["MediaDocAccessFields_sizes_small_jpeg_Update"],
	delete?:ResolverInputTypes["MediaDocAccessFields_sizes_small_jpeg_Delete"],
	fields?:ResolverInputTypes["MediaDocAccessFields_sizes_small_jpeg_Fields"],
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_small_jpeg_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_small_jpeg_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_small_jpeg_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_small_jpeg_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_small_jpeg_Fields"]: AliasType<{
	url?:ResolverInputTypes["MediaDocAccessFields_sizes_small_jpeg_url"],
	width?:ResolverInputTypes["MediaDocAccessFields_sizes_small_jpeg_width"],
	height?:ResolverInputTypes["MediaDocAccessFields_sizes_small_jpeg_height"],
	mimeType?:ResolverInputTypes["MediaDocAccessFields_sizes_small_jpeg_mimeType"],
	filesize?:ResolverInputTypes["MediaDocAccessFields_sizes_small_jpeg_filesize"],
	filename?:ResolverInputTypes["MediaDocAccessFields_sizes_small_jpeg_filename"],
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_small_jpeg_url"]: AliasType<{
	create?:ResolverInputTypes["MediaDocAccessFields_sizes_small_jpeg_url_Create"],
	read?:ResolverInputTypes["MediaDocAccessFields_sizes_small_jpeg_url_Read"],
	update?:ResolverInputTypes["MediaDocAccessFields_sizes_small_jpeg_url_Update"],
	delete?:ResolverInputTypes["MediaDocAccessFields_sizes_small_jpeg_url_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_small_jpeg_url_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_small_jpeg_url_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_small_jpeg_url_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_small_jpeg_url_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_small_jpeg_width"]: AliasType<{
	create?:ResolverInputTypes["MediaDocAccessFields_sizes_small_jpeg_width_Create"],
	read?:ResolverInputTypes["MediaDocAccessFields_sizes_small_jpeg_width_Read"],
	update?:ResolverInputTypes["MediaDocAccessFields_sizes_small_jpeg_width_Update"],
	delete?:ResolverInputTypes["MediaDocAccessFields_sizes_small_jpeg_width_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_small_jpeg_width_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_small_jpeg_width_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_small_jpeg_width_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_small_jpeg_width_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_small_jpeg_height"]: AliasType<{
	create?:ResolverInputTypes["MediaDocAccessFields_sizes_small_jpeg_height_Create"],
	read?:ResolverInputTypes["MediaDocAccessFields_sizes_small_jpeg_height_Read"],
	update?:ResolverInputTypes["MediaDocAccessFields_sizes_small_jpeg_height_Update"],
	delete?:ResolverInputTypes["MediaDocAccessFields_sizes_small_jpeg_height_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_small_jpeg_height_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_small_jpeg_height_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_small_jpeg_height_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_small_jpeg_height_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_small_jpeg_mimeType"]: AliasType<{
	create?:ResolverInputTypes["MediaDocAccessFields_sizes_small_jpeg_mimeType_Create"],
	read?:ResolverInputTypes["MediaDocAccessFields_sizes_small_jpeg_mimeType_Read"],
	update?:ResolverInputTypes["MediaDocAccessFields_sizes_small_jpeg_mimeType_Update"],
	delete?:ResolverInputTypes["MediaDocAccessFields_sizes_small_jpeg_mimeType_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_small_jpeg_mimeType_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_small_jpeg_mimeType_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_small_jpeg_mimeType_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_small_jpeg_mimeType_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_small_jpeg_filesize"]: AliasType<{
	create?:ResolverInputTypes["MediaDocAccessFields_sizes_small_jpeg_filesize_Create"],
	read?:ResolverInputTypes["MediaDocAccessFields_sizes_small_jpeg_filesize_Read"],
	update?:ResolverInputTypes["MediaDocAccessFields_sizes_small_jpeg_filesize_Update"],
	delete?:ResolverInputTypes["MediaDocAccessFields_sizes_small_jpeg_filesize_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_small_jpeg_filesize_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_small_jpeg_filesize_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_small_jpeg_filesize_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_small_jpeg_filesize_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_small_jpeg_filename"]: AliasType<{
	create?:ResolverInputTypes["MediaDocAccessFields_sizes_small_jpeg_filename_Create"],
	read?:ResolverInputTypes["MediaDocAccessFields_sizes_small_jpeg_filename_Read"],
	update?:ResolverInputTypes["MediaDocAccessFields_sizes_small_jpeg_filename_Update"],
	delete?:ResolverInputTypes["MediaDocAccessFields_sizes_small_jpeg_filename_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_small_jpeg_filename_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_small_jpeg_filename_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_small_jpeg_filename_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_small_jpeg_filename_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_medium_jpeg"]: AliasType<{
	create?:ResolverInputTypes["MediaDocAccessFields_sizes_medium_jpeg_Create"],
	read?:ResolverInputTypes["MediaDocAccessFields_sizes_medium_jpeg_Read"],
	update?:ResolverInputTypes["MediaDocAccessFields_sizes_medium_jpeg_Update"],
	delete?:ResolverInputTypes["MediaDocAccessFields_sizes_medium_jpeg_Delete"],
	fields?:ResolverInputTypes["MediaDocAccessFields_sizes_medium_jpeg_Fields"],
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_medium_jpeg_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_medium_jpeg_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_medium_jpeg_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_medium_jpeg_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_medium_jpeg_Fields"]: AliasType<{
	url?:ResolverInputTypes["MediaDocAccessFields_sizes_medium_jpeg_url"],
	width?:ResolverInputTypes["MediaDocAccessFields_sizes_medium_jpeg_width"],
	height?:ResolverInputTypes["MediaDocAccessFields_sizes_medium_jpeg_height"],
	mimeType?:ResolverInputTypes["MediaDocAccessFields_sizes_medium_jpeg_mimeType"],
	filesize?:ResolverInputTypes["MediaDocAccessFields_sizes_medium_jpeg_filesize"],
	filename?:ResolverInputTypes["MediaDocAccessFields_sizes_medium_jpeg_filename"],
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_medium_jpeg_url"]: AliasType<{
	create?:ResolverInputTypes["MediaDocAccessFields_sizes_medium_jpeg_url_Create"],
	read?:ResolverInputTypes["MediaDocAccessFields_sizes_medium_jpeg_url_Read"],
	update?:ResolverInputTypes["MediaDocAccessFields_sizes_medium_jpeg_url_Update"],
	delete?:ResolverInputTypes["MediaDocAccessFields_sizes_medium_jpeg_url_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_medium_jpeg_url_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_medium_jpeg_url_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_medium_jpeg_url_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_medium_jpeg_url_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_medium_jpeg_width"]: AliasType<{
	create?:ResolverInputTypes["MediaDocAccessFields_sizes_medium_jpeg_width_Create"],
	read?:ResolverInputTypes["MediaDocAccessFields_sizes_medium_jpeg_width_Read"],
	update?:ResolverInputTypes["MediaDocAccessFields_sizes_medium_jpeg_width_Update"],
	delete?:ResolverInputTypes["MediaDocAccessFields_sizes_medium_jpeg_width_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_medium_jpeg_width_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_medium_jpeg_width_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_medium_jpeg_width_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_medium_jpeg_width_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_medium_jpeg_height"]: AliasType<{
	create?:ResolverInputTypes["MediaDocAccessFields_sizes_medium_jpeg_height_Create"],
	read?:ResolverInputTypes["MediaDocAccessFields_sizes_medium_jpeg_height_Read"],
	update?:ResolverInputTypes["MediaDocAccessFields_sizes_medium_jpeg_height_Update"],
	delete?:ResolverInputTypes["MediaDocAccessFields_sizes_medium_jpeg_height_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_medium_jpeg_height_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_medium_jpeg_height_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_medium_jpeg_height_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_medium_jpeg_height_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_medium_jpeg_mimeType"]: AliasType<{
	create?:ResolverInputTypes["MediaDocAccessFields_sizes_medium_jpeg_mimeType_Create"],
	read?:ResolverInputTypes["MediaDocAccessFields_sizes_medium_jpeg_mimeType_Read"],
	update?:ResolverInputTypes["MediaDocAccessFields_sizes_medium_jpeg_mimeType_Update"],
	delete?:ResolverInputTypes["MediaDocAccessFields_sizes_medium_jpeg_mimeType_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_medium_jpeg_mimeType_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_medium_jpeg_mimeType_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_medium_jpeg_mimeType_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_medium_jpeg_mimeType_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_medium_jpeg_filesize"]: AliasType<{
	create?:ResolverInputTypes["MediaDocAccessFields_sizes_medium_jpeg_filesize_Create"],
	read?:ResolverInputTypes["MediaDocAccessFields_sizes_medium_jpeg_filesize_Read"],
	update?:ResolverInputTypes["MediaDocAccessFields_sizes_medium_jpeg_filesize_Update"],
	delete?:ResolverInputTypes["MediaDocAccessFields_sizes_medium_jpeg_filesize_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_medium_jpeg_filesize_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_medium_jpeg_filesize_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_medium_jpeg_filesize_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_medium_jpeg_filesize_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_medium_jpeg_filename"]: AliasType<{
	create?:ResolverInputTypes["MediaDocAccessFields_sizes_medium_jpeg_filename_Create"],
	read?:ResolverInputTypes["MediaDocAccessFields_sizes_medium_jpeg_filename_Read"],
	update?:ResolverInputTypes["MediaDocAccessFields_sizes_medium_jpeg_filename_Update"],
	delete?:ResolverInputTypes["MediaDocAccessFields_sizes_medium_jpeg_filename_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_medium_jpeg_filename_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_medium_jpeg_filename_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_medium_jpeg_filename_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_medium_jpeg_filename_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_large_jpeg"]: AliasType<{
	create?:ResolverInputTypes["MediaDocAccessFields_sizes_large_jpeg_Create"],
	read?:ResolverInputTypes["MediaDocAccessFields_sizes_large_jpeg_Read"],
	update?:ResolverInputTypes["MediaDocAccessFields_sizes_large_jpeg_Update"],
	delete?:ResolverInputTypes["MediaDocAccessFields_sizes_large_jpeg_Delete"],
	fields?:ResolverInputTypes["MediaDocAccessFields_sizes_large_jpeg_Fields"],
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_large_jpeg_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_large_jpeg_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_large_jpeg_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_large_jpeg_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_large_jpeg_Fields"]: AliasType<{
	url?:ResolverInputTypes["MediaDocAccessFields_sizes_large_jpeg_url"],
	width?:ResolverInputTypes["MediaDocAccessFields_sizes_large_jpeg_width"],
	height?:ResolverInputTypes["MediaDocAccessFields_sizes_large_jpeg_height"],
	mimeType?:ResolverInputTypes["MediaDocAccessFields_sizes_large_jpeg_mimeType"],
	filesize?:ResolverInputTypes["MediaDocAccessFields_sizes_large_jpeg_filesize"],
	filename?:ResolverInputTypes["MediaDocAccessFields_sizes_large_jpeg_filename"],
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_large_jpeg_url"]: AliasType<{
	create?:ResolverInputTypes["MediaDocAccessFields_sizes_large_jpeg_url_Create"],
	read?:ResolverInputTypes["MediaDocAccessFields_sizes_large_jpeg_url_Read"],
	update?:ResolverInputTypes["MediaDocAccessFields_sizes_large_jpeg_url_Update"],
	delete?:ResolverInputTypes["MediaDocAccessFields_sizes_large_jpeg_url_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_large_jpeg_url_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_large_jpeg_url_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_large_jpeg_url_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_large_jpeg_url_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_large_jpeg_width"]: AliasType<{
	create?:ResolverInputTypes["MediaDocAccessFields_sizes_large_jpeg_width_Create"],
	read?:ResolverInputTypes["MediaDocAccessFields_sizes_large_jpeg_width_Read"],
	update?:ResolverInputTypes["MediaDocAccessFields_sizes_large_jpeg_width_Update"],
	delete?:ResolverInputTypes["MediaDocAccessFields_sizes_large_jpeg_width_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_large_jpeg_width_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_large_jpeg_width_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_large_jpeg_width_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_large_jpeg_width_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_large_jpeg_height"]: AliasType<{
	create?:ResolverInputTypes["MediaDocAccessFields_sizes_large_jpeg_height_Create"],
	read?:ResolverInputTypes["MediaDocAccessFields_sizes_large_jpeg_height_Read"],
	update?:ResolverInputTypes["MediaDocAccessFields_sizes_large_jpeg_height_Update"],
	delete?:ResolverInputTypes["MediaDocAccessFields_sizes_large_jpeg_height_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_large_jpeg_height_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_large_jpeg_height_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_large_jpeg_height_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_large_jpeg_height_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_large_jpeg_mimeType"]: AliasType<{
	create?:ResolverInputTypes["MediaDocAccessFields_sizes_large_jpeg_mimeType_Create"],
	read?:ResolverInputTypes["MediaDocAccessFields_sizes_large_jpeg_mimeType_Read"],
	update?:ResolverInputTypes["MediaDocAccessFields_sizes_large_jpeg_mimeType_Update"],
	delete?:ResolverInputTypes["MediaDocAccessFields_sizes_large_jpeg_mimeType_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_large_jpeg_mimeType_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_large_jpeg_mimeType_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_large_jpeg_mimeType_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_large_jpeg_mimeType_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_large_jpeg_filesize"]: AliasType<{
	create?:ResolverInputTypes["MediaDocAccessFields_sizes_large_jpeg_filesize_Create"],
	read?:ResolverInputTypes["MediaDocAccessFields_sizes_large_jpeg_filesize_Read"],
	update?:ResolverInputTypes["MediaDocAccessFields_sizes_large_jpeg_filesize_Update"],
	delete?:ResolverInputTypes["MediaDocAccessFields_sizes_large_jpeg_filesize_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_large_jpeg_filesize_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_large_jpeg_filesize_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_large_jpeg_filesize_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_large_jpeg_filesize_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_large_jpeg_filename"]: AliasType<{
	create?:ResolverInputTypes["MediaDocAccessFields_sizes_large_jpeg_filename_Create"],
	read?:ResolverInputTypes["MediaDocAccessFields_sizes_large_jpeg_filename_Read"],
	update?:ResolverInputTypes["MediaDocAccessFields_sizes_large_jpeg_filename_Update"],
	delete?:ResolverInputTypes["MediaDocAccessFields_sizes_large_jpeg_filename_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_large_jpeg_filename_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_large_jpeg_filename_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_large_jpeg_filename_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDocAccessFields_sizes_large_jpeg_filename_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaCreateDocAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaReadDocAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaUpdateDocAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDeleteDocAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMedia"]: AliasType<{
	id?:boolean | `@${string}`,
	alt?:boolean | `@${string}`,
creator?: [{	locale?: ResolverInputTypes["LocaleInputType"] | undefined | null,	fallbackLocale?: ResolverInputTypes["FallbackLocaleInputType"] | undefined | null},ResolverInputTypes["User"]],
	updatedAt?:boolean | `@${string}`,
	createdAt?:boolean | `@${string}`,
	url?:boolean | `@${string}`,
	filename?:boolean | `@${string}`,
	mimeType?:boolean | `@${string}`,
	filesize?:boolean | `@${string}`,
	width?:boolean | `@${string}`,
	height?:boolean | `@${string}`,
	focalX?:boolean | `@${string}`,
	focalY?:boolean | `@${string}`,
	sizes?:ResolverInputTypes["BackupMedia_Sizes"],
		__typename?: boolean | `@${string}`
}>;
	["BackupMedia_Sizes"]: AliasType<{
	thumbnail?:ResolverInputTypes["BackupMedia_Sizes_Thumbnail"],
	sixteenByNineMedium?:ResolverInputTypes["BackupMedia_Sizes_SixteenByNineMedium"],
		__typename?: boolean | `@${string}`
}>;
	["BackupMedia_Sizes_Thumbnail"]: AliasType<{
	url?:boolean | `@${string}`,
	width?:boolean | `@${string}`,
	height?:boolean | `@${string}`,
	mimeType?:boolean | `@${string}`,
	filesize?:boolean | `@${string}`,
	filename?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMedia_Sizes_SixteenByNineMedium"]: AliasType<{
	url?:boolean | `@${string}`,
	width?:boolean | `@${string}`,
	height?:boolean | `@${string}`,
	mimeType?:boolean | `@${string}`,
	filesize?:boolean | `@${string}`,
	filename?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMedias"]: AliasType<{
	docs?:ResolverInputTypes["BackupMedia"],
	hasNextPage?:boolean | `@${string}`,
	hasPrevPage?:boolean | `@${string}`,
	limit?:boolean | `@${string}`,
	nextPage?:boolean | `@${string}`,
	offset?:boolean | `@${string}`,
	page?:boolean | `@${string}`,
	pagingCounter?:boolean | `@${string}`,
	prevPage?:boolean | `@${string}`,
	totalDocs?:boolean | `@${string}`,
	totalPages?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMedia_where"]: {
	alt?: ResolverInputTypes["BackupMedia_alt_operator"] | undefined | null,
	creator?: ResolverInputTypes["BackupMedia_creator_operator"] | undefined | null,
	updatedAt?: ResolverInputTypes["BackupMedia_updatedAt_operator"] | undefined | null,
	createdAt?: ResolverInputTypes["BackupMedia_createdAt_operator"] | undefined | null,
	url?: ResolverInputTypes["BackupMedia_url_operator"] | undefined | null,
	filename?: ResolverInputTypes["BackupMedia_filename_operator"] | undefined | null,
	mimeType?: ResolverInputTypes["BackupMedia_mimeType_operator"] | undefined | null,
	filesize?: ResolverInputTypes["BackupMedia_filesize_operator"] | undefined | null,
	width?: ResolverInputTypes["BackupMedia_width_operator"] | undefined | null,
	height?: ResolverInputTypes["BackupMedia_height_operator"] | undefined | null,
	focalX?: ResolverInputTypes["BackupMedia_focalX_operator"] | undefined | null,
	focalY?: ResolverInputTypes["BackupMedia_focalY_operator"] | undefined | null,
	sizes__thumbnail__url?: ResolverInputTypes["BackupMedia_sizes__thumbnail__url_operator"] | undefined | null,
	sizes__thumbnail__width?: ResolverInputTypes["BackupMedia_sizes__thumbnail__width_operator"] | undefined | null,
	sizes__thumbnail__height?: ResolverInputTypes["BackupMedia_sizes__thumbnail__height_operator"] | undefined | null,
	sizes__thumbnail__mimeType?: ResolverInputTypes["BackupMedia_sizes__thumbnail__mimeType_operator"] | undefined | null,
	sizes__thumbnail__filesize?: ResolverInputTypes["BackupMedia_sizes__thumbnail__filesize_operator"] | undefined | null,
	sizes__thumbnail__filename?: ResolverInputTypes["BackupMedia_sizes__thumbnail__filename_operator"] | undefined | null,
	sizes__sixteenByNineMedium__url?: ResolverInputTypes["BackupMedia_sizes__sixteenByNineMedium__url_operator"] | undefined | null,
	sizes__sixteenByNineMedium__width?: ResolverInputTypes["BackupMedia_sizes__sixteenByNineMedium__width_operator"] | undefined | null,
	sizes__sixteenByNineMedium__height?: ResolverInputTypes["BackupMedia_sizes__sixteenByNineMedium__height_operator"] | undefined | null,
	sizes__sixteenByNineMedium__mimeType?: ResolverInputTypes["BackupMedia_sizes__sixteenByNineMedium__mimeType_operator"] | undefined | null,
	sizes__sixteenByNineMedium__filesize?: ResolverInputTypes["BackupMedia_sizes__sixteenByNineMedium__filesize_operator"] | undefined | null,
	sizes__sixteenByNineMedium__filename?: ResolverInputTypes["BackupMedia_sizes__sixteenByNineMedium__filename_operator"] | undefined | null,
	id?: ResolverInputTypes["BackupMedia_id_operator"] | undefined | null,
	AND?: Array<ResolverInputTypes["BackupMedia_where_and"] | undefined | null> | undefined | null,
	OR?: Array<ResolverInputTypes["BackupMedia_where_or"] | undefined | null> | undefined | null
};
	["BackupMedia_alt_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["BackupMedia_creator_operator"]: {
	equals?: ResolverInputTypes["JSON"] | undefined | null,
	not_equals?: ResolverInputTypes["JSON"] | undefined | null,
	in?: Array<ResolverInputTypes["JSON"] | undefined | null> | undefined | null,
	not_in?: Array<ResolverInputTypes["JSON"] | undefined | null> | undefined | null,
	all?: Array<ResolverInputTypes["JSON"] | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["BackupMedia_updatedAt_operator"]: {
	equals?: ResolverInputTypes["DateTime"] | undefined | null,
	not_equals?: ResolverInputTypes["DateTime"] | undefined | null,
	greater_than_equal?: ResolverInputTypes["DateTime"] | undefined | null,
	greater_than?: ResolverInputTypes["DateTime"] | undefined | null,
	less_than_equal?: ResolverInputTypes["DateTime"] | undefined | null,
	less_than?: ResolverInputTypes["DateTime"] | undefined | null,
	like?: ResolverInputTypes["DateTime"] | undefined | null,
	exists?: boolean | undefined | null
};
	["BackupMedia_createdAt_operator"]: {
	equals?: ResolverInputTypes["DateTime"] | undefined | null,
	not_equals?: ResolverInputTypes["DateTime"] | undefined | null,
	greater_than_equal?: ResolverInputTypes["DateTime"] | undefined | null,
	greater_than?: ResolverInputTypes["DateTime"] | undefined | null,
	less_than_equal?: ResolverInputTypes["DateTime"] | undefined | null,
	less_than?: ResolverInputTypes["DateTime"] | undefined | null,
	like?: ResolverInputTypes["DateTime"] | undefined | null,
	exists?: boolean | undefined | null
};
	["BackupMedia_url_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["BackupMedia_filename_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["BackupMedia_mimeType_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["BackupMedia_filesize_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["BackupMedia_width_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["BackupMedia_height_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["BackupMedia_focalX_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["BackupMedia_focalY_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["BackupMedia_sizes__thumbnail__url_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["BackupMedia_sizes__thumbnail__width_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["BackupMedia_sizes__thumbnail__height_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["BackupMedia_sizes__thumbnail__mimeType_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["BackupMedia_sizes__thumbnail__filesize_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["BackupMedia_sizes__thumbnail__filename_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["BackupMedia_sizes__sixteenByNineMedium__url_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["BackupMedia_sizes__sixteenByNineMedium__width_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["BackupMedia_sizes__sixteenByNineMedium__height_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["BackupMedia_sizes__sixteenByNineMedium__mimeType_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["BackupMedia_sizes__sixteenByNineMedium__filesize_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["BackupMedia_sizes__sixteenByNineMedium__filename_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["BackupMedia_id_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["BackupMedia_where_and"]: {
	alt?: ResolverInputTypes["BackupMedia_alt_operator"] | undefined | null,
	creator?: ResolverInputTypes["BackupMedia_creator_operator"] | undefined | null,
	updatedAt?: ResolverInputTypes["BackupMedia_updatedAt_operator"] | undefined | null,
	createdAt?: ResolverInputTypes["BackupMedia_createdAt_operator"] | undefined | null,
	url?: ResolverInputTypes["BackupMedia_url_operator"] | undefined | null,
	filename?: ResolverInputTypes["BackupMedia_filename_operator"] | undefined | null,
	mimeType?: ResolverInputTypes["BackupMedia_mimeType_operator"] | undefined | null,
	filesize?: ResolverInputTypes["BackupMedia_filesize_operator"] | undefined | null,
	width?: ResolverInputTypes["BackupMedia_width_operator"] | undefined | null,
	height?: ResolverInputTypes["BackupMedia_height_operator"] | undefined | null,
	focalX?: ResolverInputTypes["BackupMedia_focalX_operator"] | undefined | null,
	focalY?: ResolverInputTypes["BackupMedia_focalY_operator"] | undefined | null,
	sizes__thumbnail__url?: ResolverInputTypes["BackupMedia_sizes__thumbnail__url_operator"] | undefined | null,
	sizes__thumbnail__width?: ResolverInputTypes["BackupMedia_sizes__thumbnail__width_operator"] | undefined | null,
	sizes__thumbnail__height?: ResolverInputTypes["BackupMedia_sizes__thumbnail__height_operator"] | undefined | null,
	sizes__thumbnail__mimeType?: ResolverInputTypes["BackupMedia_sizes__thumbnail__mimeType_operator"] | undefined | null,
	sizes__thumbnail__filesize?: ResolverInputTypes["BackupMedia_sizes__thumbnail__filesize_operator"] | undefined | null,
	sizes__thumbnail__filename?: ResolverInputTypes["BackupMedia_sizes__thumbnail__filename_operator"] | undefined | null,
	sizes__sixteenByNineMedium__url?: ResolverInputTypes["BackupMedia_sizes__sixteenByNineMedium__url_operator"] | undefined | null,
	sizes__sixteenByNineMedium__width?: ResolverInputTypes["BackupMedia_sizes__sixteenByNineMedium__width_operator"] | undefined | null,
	sizes__sixteenByNineMedium__height?: ResolverInputTypes["BackupMedia_sizes__sixteenByNineMedium__height_operator"] | undefined | null,
	sizes__sixteenByNineMedium__mimeType?: ResolverInputTypes["BackupMedia_sizes__sixteenByNineMedium__mimeType_operator"] | undefined | null,
	sizes__sixteenByNineMedium__filesize?: ResolverInputTypes["BackupMedia_sizes__sixteenByNineMedium__filesize_operator"] | undefined | null,
	sizes__sixteenByNineMedium__filename?: ResolverInputTypes["BackupMedia_sizes__sixteenByNineMedium__filename_operator"] | undefined | null,
	id?: ResolverInputTypes["BackupMedia_id_operator"] | undefined | null,
	AND?: Array<ResolverInputTypes["BackupMedia_where_and"] | undefined | null> | undefined | null,
	OR?: Array<ResolverInputTypes["BackupMedia_where_or"] | undefined | null> | undefined | null
};
	["BackupMedia_where_or"]: {
	alt?: ResolverInputTypes["BackupMedia_alt_operator"] | undefined | null,
	creator?: ResolverInputTypes["BackupMedia_creator_operator"] | undefined | null,
	updatedAt?: ResolverInputTypes["BackupMedia_updatedAt_operator"] | undefined | null,
	createdAt?: ResolverInputTypes["BackupMedia_createdAt_operator"] | undefined | null,
	url?: ResolverInputTypes["BackupMedia_url_operator"] | undefined | null,
	filename?: ResolverInputTypes["BackupMedia_filename_operator"] | undefined | null,
	mimeType?: ResolverInputTypes["BackupMedia_mimeType_operator"] | undefined | null,
	filesize?: ResolverInputTypes["BackupMedia_filesize_operator"] | undefined | null,
	width?: ResolverInputTypes["BackupMedia_width_operator"] | undefined | null,
	height?: ResolverInputTypes["BackupMedia_height_operator"] | undefined | null,
	focalX?: ResolverInputTypes["BackupMedia_focalX_operator"] | undefined | null,
	focalY?: ResolverInputTypes["BackupMedia_focalY_operator"] | undefined | null,
	sizes__thumbnail__url?: ResolverInputTypes["BackupMedia_sizes__thumbnail__url_operator"] | undefined | null,
	sizes__thumbnail__width?: ResolverInputTypes["BackupMedia_sizes__thumbnail__width_operator"] | undefined | null,
	sizes__thumbnail__height?: ResolverInputTypes["BackupMedia_sizes__thumbnail__height_operator"] | undefined | null,
	sizes__thumbnail__mimeType?: ResolverInputTypes["BackupMedia_sizes__thumbnail__mimeType_operator"] | undefined | null,
	sizes__thumbnail__filesize?: ResolverInputTypes["BackupMedia_sizes__thumbnail__filesize_operator"] | undefined | null,
	sizes__thumbnail__filename?: ResolverInputTypes["BackupMedia_sizes__thumbnail__filename_operator"] | undefined | null,
	sizes__sixteenByNineMedium__url?: ResolverInputTypes["BackupMedia_sizes__sixteenByNineMedium__url_operator"] | undefined | null,
	sizes__sixteenByNineMedium__width?: ResolverInputTypes["BackupMedia_sizes__sixteenByNineMedium__width_operator"] | undefined | null,
	sizes__sixteenByNineMedium__height?: ResolverInputTypes["BackupMedia_sizes__sixteenByNineMedium__height_operator"] | undefined | null,
	sizes__sixteenByNineMedium__mimeType?: ResolverInputTypes["BackupMedia_sizes__sixteenByNineMedium__mimeType_operator"] | undefined | null,
	sizes__sixteenByNineMedium__filesize?: ResolverInputTypes["BackupMedia_sizes__sixteenByNineMedium__filesize_operator"] | undefined | null,
	sizes__sixteenByNineMedium__filename?: ResolverInputTypes["BackupMedia_sizes__sixteenByNineMedium__filename_operator"] | undefined | null,
	id?: ResolverInputTypes["BackupMedia_id_operator"] | undefined | null,
	AND?: Array<ResolverInputTypes["BackupMedia_where_and"] | undefined | null> | undefined | null,
	OR?: Array<ResolverInputTypes["BackupMedia_where_or"] | undefined | null> | undefined | null
};
	["countBackupMedias"]: AliasType<{
	totalDocs?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["backupMediaDocAccess"]: AliasType<{
	fields?:ResolverInputTypes["BackupMediaDocAccessFields"],
	create?:ResolverInputTypes["BackupMediaCreateDocAccess"],
	read?:ResolverInputTypes["BackupMediaReadDocAccess"],
	update?:ResolverInputTypes["BackupMediaUpdateDocAccess"],
	delete?:ResolverInputTypes["BackupMediaDeleteDocAccess"],
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaDocAccessFields"]: AliasType<{
	alt?:ResolverInputTypes["BackupMediaDocAccessFields_alt"],
	creator?:ResolverInputTypes["BackupMediaDocAccessFields_creator"],
	updatedAt?:ResolverInputTypes["BackupMediaDocAccessFields_updatedAt"],
	createdAt?:ResolverInputTypes["BackupMediaDocAccessFields_createdAt"],
	url?:ResolverInputTypes["BackupMediaDocAccessFields_url"],
	filename?:ResolverInputTypes["BackupMediaDocAccessFields_filename"],
	mimeType?:ResolverInputTypes["BackupMediaDocAccessFields_mimeType"],
	filesize?:ResolverInputTypes["BackupMediaDocAccessFields_filesize"],
	width?:ResolverInputTypes["BackupMediaDocAccessFields_width"],
	height?:ResolverInputTypes["BackupMediaDocAccessFields_height"],
	focalX?:ResolverInputTypes["BackupMediaDocAccessFields_focalX"],
	focalY?:ResolverInputTypes["BackupMediaDocAccessFields_focalY"],
	sizes?:ResolverInputTypes["BackupMediaDocAccessFields_sizes"],
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaDocAccessFields_alt"]: AliasType<{
	create?:ResolverInputTypes["BackupMediaDocAccessFields_alt_Create"],
	read?:ResolverInputTypes["BackupMediaDocAccessFields_alt_Read"],
	update?:ResolverInputTypes["BackupMediaDocAccessFields_alt_Update"],
	delete?:ResolverInputTypes["BackupMediaDocAccessFields_alt_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaDocAccessFields_alt_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaDocAccessFields_alt_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaDocAccessFields_alt_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaDocAccessFields_alt_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaDocAccessFields_creator"]: AliasType<{
	create?:ResolverInputTypes["BackupMediaDocAccessFields_creator_Create"],
	read?:ResolverInputTypes["BackupMediaDocAccessFields_creator_Read"],
	update?:ResolverInputTypes["BackupMediaDocAccessFields_creator_Update"],
	delete?:ResolverInputTypes["BackupMediaDocAccessFields_creator_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaDocAccessFields_creator_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaDocAccessFields_creator_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaDocAccessFields_creator_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaDocAccessFields_creator_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaDocAccessFields_updatedAt"]: AliasType<{
	create?:ResolverInputTypes["BackupMediaDocAccessFields_updatedAt_Create"],
	read?:ResolverInputTypes["BackupMediaDocAccessFields_updatedAt_Read"],
	update?:ResolverInputTypes["BackupMediaDocAccessFields_updatedAt_Update"],
	delete?:ResolverInputTypes["BackupMediaDocAccessFields_updatedAt_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaDocAccessFields_updatedAt_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaDocAccessFields_updatedAt_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaDocAccessFields_updatedAt_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaDocAccessFields_updatedAt_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaDocAccessFields_createdAt"]: AliasType<{
	create?:ResolverInputTypes["BackupMediaDocAccessFields_createdAt_Create"],
	read?:ResolverInputTypes["BackupMediaDocAccessFields_createdAt_Read"],
	update?:ResolverInputTypes["BackupMediaDocAccessFields_createdAt_Update"],
	delete?:ResolverInputTypes["BackupMediaDocAccessFields_createdAt_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaDocAccessFields_createdAt_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaDocAccessFields_createdAt_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaDocAccessFields_createdAt_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaDocAccessFields_createdAt_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaDocAccessFields_url"]: AliasType<{
	create?:ResolverInputTypes["BackupMediaDocAccessFields_url_Create"],
	read?:ResolverInputTypes["BackupMediaDocAccessFields_url_Read"],
	update?:ResolverInputTypes["BackupMediaDocAccessFields_url_Update"],
	delete?:ResolverInputTypes["BackupMediaDocAccessFields_url_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaDocAccessFields_url_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaDocAccessFields_url_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaDocAccessFields_url_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaDocAccessFields_url_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaDocAccessFields_filename"]: AliasType<{
	create?:ResolverInputTypes["BackupMediaDocAccessFields_filename_Create"],
	read?:ResolverInputTypes["BackupMediaDocAccessFields_filename_Read"],
	update?:ResolverInputTypes["BackupMediaDocAccessFields_filename_Update"],
	delete?:ResolverInputTypes["BackupMediaDocAccessFields_filename_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaDocAccessFields_filename_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaDocAccessFields_filename_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaDocAccessFields_filename_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaDocAccessFields_filename_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaDocAccessFields_mimeType"]: AliasType<{
	create?:ResolverInputTypes["BackupMediaDocAccessFields_mimeType_Create"],
	read?:ResolverInputTypes["BackupMediaDocAccessFields_mimeType_Read"],
	update?:ResolverInputTypes["BackupMediaDocAccessFields_mimeType_Update"],
	delete?:ResolverInputTypes["BackupMediaDocAccessFields_mimeType_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaDocAccessFields_mimeType_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaDocAccessFields_mimeType_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaDocAccessFields_mimeType_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaDocAccessFields_mimeType_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaDocAccessFields_filesize"]: AliasType<{
	create?:ResolverInputTypes["BackupMediaDocAccessFields_filesize_Create"],
	read?:ResolverInputTypes["BackupMediaDocAccessFields_filesize_Read"],
	update?:ResolverInputTypes["BackupMediaDocAccessFields_filesize_Update"],
	delete?:ResolverInputTypes["BackupMediaDocAccessFields_filesize_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaDocAccessFields_filesize_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaDocAccessFields_filesize_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaDocAccessFields_filesize_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaDocAccessFields_filesize_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaDocAccessFields_width"]: AliasType<{
	create?:ResolverInputTypes["BackupMediaDocAccessFields_width_Create"],
	read?:ResolverInputTypes["BackupMediaDocAccessFields_width_Read"],
	update?:ResolverInputTypes["BackupMediaDocAccessFields_width_Update"],
	delete?:ResolverInputTypes["BackupMediaDocAccessFields_width_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaDocAccessFields_width_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaDocAccessFields_width_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaDocAccessFields_width_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaDocAccessFields_width_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaDocAccessFields_height"]: AliasType<{
	create?:ResolverInputTypes["BackupMediaDocAccessFields_height_Create"],
	read?:ResolverInputTypes["BackupMediaDocAccessFields_height_Read"],
	update?:ResolverInputTypes["BackupMediaDocAccessFields_height_Update"],
	delete?:ResolverInputTypes["BackupMediaDocAccessFields_height_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaDocAccessFields_height_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaDocAccessFields_height_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaDocAccessFields_height_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaDocAccessFields_height_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaDocAccessFields_focalX"]: AliasType<{
	create?:ResolverInputTypes["BackupMediaDocAccessFields_focalX_Create"],
	read?:ResolverInputTypes["BackupMediaDocAccessFields_focalX_Read"],
	update?:ResolverInputTypes["BackupMediaDocAccessFields_focalX_Update"],
	delete?:ResolverInputTypes["BackupMediaDocAccessFields_focalX_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaDocAccessFields_focalX_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaDocAccessFields_focalX_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaDocAccessFields_focalX_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaDocAccessFields_focalX_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaDocAccessFields_focalY"]: AliasType<{
	create?:ResolverInputTypes["BackupMediaDocAccessFields_focalY_Create"],
	read?:ResolverInputTypes["BackupMediaDocAccessFields_focalY_Read"],
	update?:ResolverInputTypes["BackupMediaDocAccessFields_focalY_Update"],
	delete?:ResolverInputTypes["BackupMediaDocAccessFields_focalY_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaDocAccessFields_focalY_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaDocAccessFields_focalY_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaDocAccessFields_focalY_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaDocAccessFields_focalY_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaDocAccessFields_sizes"]: AliasType<{
	create?:ResolverInputTypes["BackupMediaDocAccessFields_sizes_Create"],
	read?:ResolverInputTypes["BackupMediaDocAccessFields_sizes_Read"],
	update?:ResolverInputTypes["BackupMediaDocAccessFields_sizes_Update"],
	delete?:ResolverInputTypes["BackupMediaDocAccessFields_sizes_Delete"],
	fields?:ResolverInputTypes["BackupMediaDocAccessFields_sizes_Fields"],
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaDocAccessFields_sizes_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaDocAccessFields_sizes_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaDocAccessFields_sizes_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaDocAccessFields_sizes_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaDocAccessFields_sizes_Fields"]: AliasType<{
	thumbnail?:ResolverInputTypes["BackupMediaDocAccessFields_sizes_thumbnail"],
	sixteenByNineMedium?:ResolverInputTypes["BackupMediaDocAccessFields_sizes_sixteenByNineMedium"],
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaDocAccessFields_sizes_thumbnail"]: AliasType<{
	create?:ResolverInputTypes["BackupMediaDocAccessFields_sizes_thumbnail_Create"],
	read?:ResolverInputTypes["BackupMediaDocAccessFields_sizes_thumbnail_Read"],
	update?:ResolverInputTypes["BackupMediaDocAccessFields_sizes_thumbnail_Update"],
	delete?:ResolverInputTypes["BackupMediaDocAccessFields_sizes_thumbnail_Delete"],
	fields?:ResolverInputTypes["BackupMediaDocAccessFields_sizes_thumbnail_Fields"],
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaDocAccessFields_sizes_thumbnail_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaDocAccessFields_sizes_thumbnail_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaDocAccessFields_sizes_thumbnail_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaDocAccessFields_sizes_thumbnail_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaDocAccessFields_sizes_thumbnail_Fields"]: AliasType<{
	url?:ResolverInputTypes["BackupMediaDocAccessFields_sizes_thumbnail_url"],
	width?:ResolverInputTypes["BackupMediaDocAccessFields_sizes_thumbnail_width"],
	height?:ResolverInputTypes["BackupMediaDocAccessFields_sizes_thumbnail_height"],
	mimeType?:ResolverInputTypes["BackupMediaDocAccessFields_sizes_thumbnail_mimeType"],
	filesize?:ResolverInputTypes["BackupMediaDocAccessFields_sizes_thumbnail_filesize"],
	filename?:ResolverInputTypes["BackupMediaDocAccessFields_sizes_thumbnail_filename"],
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaDocAccessFields_sizes_thumbnail_url"]: AliasType<{
	create?:ResolverInputTypes["BackupMediaDocAccessFields_sizes_thumbnail_url_Create"],
	read?:ResolverInputTypes["BackupMediaDocAccessFields_sizes_thumbnail_url_Read"],
	update?:ResolverInputTypes["BackupMediaDocAccessFields_sizes_thumbnail_url_Update"],
	delete?:ResolverInputTypes["BackupMediaDocAccessFields_sizes_thumbnail_url_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaDocAccessFields_sizes_thumbnail_url_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaDocAccessFields_sizes_thumbnail_url_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaDocAccessFields_sizes_thumbnail_url_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaDocAccessFields_sizes_thumbnail_url_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaDocAccessFields_sizes_thumbnail_width"]: AliasType<{
	create?:ResolverInputTypes["BackupMediaDocAccessFields_sizes_thumbnail_width_Create"],
	read?:ResolverInputTypes["BackupMediaDocAccessFields_sizes_thumbnail_width_Read"],
	update?:ResolverInputTypes["BackupMediaDocAccessFields_sizes_thumbnail_width_Update"],
	delete?:ResolverInputTypes["BackupMediaDocAccessFields_sizes_thumbnail_width_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaDocAccessFields_sizes_thumbnail_width_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaDocAccessFields_sizes_thumbnail_width_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaDocAccessFields_sizes_thumbnail_width_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaDocAccessFields_sizes_thumbnail_width_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaDocAccessFields_sizes_thumbnail_height"]: AliasType<{
	create?:ResolverInputTypes["BackupMediaDocAccessFields_sizes_thumbnail_height_Create"],
	read?:ResolverInputTypes["BackupMediaDocAccessFields_sizes_thumbnail_height_Read"],
	update?:ResolverInputTypes["BackupMediaDocAccessFields_sizes_thumbnail_height_Update"],
	delete?:ResolverInputTypes["BackupMediaDocAccessFields_sizes_thumbnail_height_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaDocAccessFields_sizes_thumbnail_height_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaDocAccessFields_sizes_thumbnail_height_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaDocAccessFields_sizes_thumbnail_height_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaDocAccessFields_sizes_thumbnail_height_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaDocAccessFields_sizes_thumbnail_mimeType"]: AliasType<{
	create?:ResolverInputTypes["BackupMediaDocAccessFields_sizes_thumbnail_mimeType_Create"],
	read?:ResolverInputTypes["BackupMediaDocAccessFields_sizes_thumbnail_mimeType_Read"],
	update?:ResolverInputTypes["BackupMediaDocAccessFields_sizes_thumbnail_mimeType_Update"],
	delete?:ResolverInputTypes["BackupMediaDocAccessFields_sizes_thumbnail_mimeType_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaDocAccessFields_sizes_thumbnail_mimeType_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaDocAccessFields_sizes_thumbnail_mimeType_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaDocAccessFields_sizes_thumbnail_mimeType_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaDocAccessFields_sizes_thumbnail_mimeType_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaDocAccessFields_sizes_thumbnail_filesize"]: AliasType<{
	create?:ResolverInputTypes["BackupMediaDocAccessFields_sizes_thumbnail_filesize_Create"],
	read?:ResolverInputTypes["BackupMediaDocAccessFields_sizes_thumbnail_filesize_Read"],
	update?:ResolverInputTypes["BackupMediaDocAccessFields_sizes_thumbnail_filesize_Update"],
	delete?:ResolverInputTypes["BackupMediaDocAccessFields_sizes_thumbnail_filesize_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaDocAccessFields_sizes_thumbnail_filesize_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaDocAccessFields_sizes_thumbnail_filesize_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaDocAccessFields_sizes_thumbnail_filesize_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaDocAccessFields_sizes_thumbnail_filesize_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaDocAccessFields_sizes_thumbnail_filename"]: AliasType<{
	create?:ResolverInputTypes["BackupMediaDocAccessFields_sizes_thumbnail_filename_Create"],
	read?:ResolverInputTypes["BackupMediaDocAccessFields_sizes_thumbnail_filename_Read"],
	update?:ResolverInputTypes["BackupMediaDocAccessFields_sizes_thumbnail_filename_Update"],
	delete?:ResolverInputTypes["BackupMediaDocAccessFields_sizes_thumbnail_filename_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaDocAccessFields_sizes_thumbnail_filename_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaDocAccessFields_sizes_thumbnail_filename_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaDocAccessFields_sizes_thumbnail_filename_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaDocAccessFields_sizes_thumbnail_filename_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaDocAccessFields_sizes_sixteenByNineMedium"]: AliasType<{
	create?:ResolverInputTypes["BackupMediaDocAccessFields_sizes_sixteenByNineMedium_Create"],
	read?:ResolverInputTypes["BackupMediaDocAccessFields_sizes_sixteenByNineMedium_Read"],
	update?:ResolverInputTypes["BackupMediaDocAccessFields_sizes_sixteenByNineMedium_Update"],
	delete?:ResolverInputTypes["BackupMediaDocAccessFields_sizes_sixteenByNineMedium_Delete"],
	fields?:ResolverInputTypes["BackupMediaDocAccessFields_sizes_sixteenByNineMedium_Fields"],
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaDocAccessFields_sizes_sixteenByNineMedium_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaDocAccessFields_sizes_sixteenByNineMedium_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaDocAccessFields_sizes_sixteenByNineMedium_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaDocAccessFields_sizes_sixteenByNineMedium_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaDocAccessFields_sizes_sixteenByNineMedium_Fields"]: AliasType<{
	url?:ResolverInputTypes["BackupMediaDocAccessFields_sizes_sixteenByNineMedium_url"],
	width?:ResolverInputTypes["BackupMediaDocAccessFields_sizes_sixteenByNineMedium_width"],
	height?:ResolverInputTypes["BackupMediaDocAccessFields_sizes_sixteenByNineMedium_height"],
	mimeType?:ResolverInputTypes["BackupMediaDocAccessFields_sizes_sixteenByNineMedium_mimeType"],
	filesize?:ResolverInputTypes["BackupMediaDocAccessFields_sizes_sixteenByNineMedium_filesize"],
	filename?:ResolverInputTypes["BackupMediaDocAccessFields_sizes_sixteenByNineMedium_filename"],
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaDocAccessFields_sizes_sixteenByNineMedium_url"]: AliasType<{
	create?:ResolverInputTypes["BackupMediaDocAccessFields_sizes_sixteenByNineMedium_url_Create"],
	read?:ResolverInputTypes["BackupMediaDocAccessFields_sizes_sixteenByNineMedium_url_Read"],
	update?:ResolverInputTypes["BackupMediaDocAccessFields_sizes_sixteenByNineMedium_url_Update"],
	delete?:ResolverInputTypes["BackupMediaDocAccessFields_sizes_sixteenByNineMedium_url_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaDocAccessFields_sizes_sixteenByNineMedium_url_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaDocAccessFields_sizes_sixteenByNineMedium_url_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaDocAccessFields_sizes_sixteenByNineMedium_url_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaDocAccessFields_sizes_sixteenByNineMedium_url_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaDocAccessFields_sizes_sixteenByNineMedium_width"]: AliasType<{
	create?:ResolverInputTypes["BackupMediaDocAccessFields_sizes_sixteenByNineMedium_width_Create"],
	read?:ResolverInputTypes["BackupMediaDocAccessFields_sizes_sixteenByNineMedium_width_Read"],
	update?:ResolverInputTypes["BackupMediaDocAccessFields_sizes_sixteenByNineMedium_width_Update"],
	delete?:ResolverInputTypes["BackupMediaDocAccessFields_sizes_sixteenByNineMedium_width_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaDocAccessFields_sizes_sixteenByNineMedium_width_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaDocAccessFields_sizes_sixteenByNineMedium_width_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaDocAccessFields_sizes_sixteenByNineMedium_width_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaDocAccessFields_sizes_sixteenByNineMedium_width_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaDocAccessFields_sizes_sixteenByNineMedium_height"]: AliasType<{
	create?:ResolverInputTypes["BackupMediaDocAccessFields_sizes_sixteenByNineMedium_height_Create"],
	read?:ResolverInputTypes["BackupMediaDocAccessFields_sizes_sixteenByNineMedium_height_Read"],
	update?:ResolverInputTypes["BackupMediaDocAccessFields_sizes_sixteenByNineMedium_height_Update"],
	delete?:ResolverInputTypes["BackupMediaDocAccessFields_sizes_sixteenByNineMedium_height_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaDocAccessFields_sizes_sixteenByNineMedium_height_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaDocAccessFields_sizes_sixteenByNineMedium_height_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaDocAccessFields_sizes_sixteenByNineMedium_height_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaDocAccessFields_sizes_sixteenByNineMedium_height_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaDocAccessFields_sizes_sixteenByNineMedium_mimeType"]: AliasType<{
	create?:ResolverInputTypes["BackupMediaDocAccessFields_sizes_sixteenByNineMedium_mimeType_Create"],
	read?:ResolverInputTypes["BackupMediaDocAccessFields_sizes_sixteenByNineMedium_mimeType_Read"],
	update?:ResolverInputTypes["BackupMediaDocAccessFields_sizes_sixteenByNineMedium_mimeType_Update"],
	delete?:ResolverInputTypes["BackupMediaDocAccessFields_sizes_sixteenByNineMedium_mimeType_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaDocAccessFields_sizes_sixteenByNineMedium_mimeType_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaDocAccessFields_sizes_sixteenByNineMedium_mimeType_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaDocAccessFields_sizes_sixteenByNineMedium_mimeType_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaDocAccessFields_sizes_sixteenByNineMedium_mimeType_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaDocAccessFields_sizes_sixteenByNineMedium_filesize"]: AliasType<{
	create?:ResolverInputTypes["BackupMediaDocAccessFields_sizes_sixteenByNineMedium_filesize_Create"],
	read?:ResolverInputTypes["BackupMediaDocAccessFields_sizes_sixteenByNineMedium_filesize_Read"],
	update?:ResolverInputTypes["BackupMediaDocAccessFields_sizes_sixteenByNineMedium_filesize_Update"],
	delete?:ResolverInputTypes["BackupMediaDocAccessFields_sizes_sixteenByNineMedium_filesize_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaDocAccessFields_sizes_sixteenByNineMedium_filesize_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaDocAccessFields_sizes_sixteenByNineMedium_filesize_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaDocAccessFields_sizes_sixteenByNineMedium_filesize_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaDocAccessFields_sizes_sixteenByNineMedium_filesize_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaDocAccessFields_sizes_sixteenByNineMedium_filename"]: AliasType<{
	create?:ResolverInputTypes["BackupMediaDocAccessFields_sizes_sixteenByNineMedium_filename_Create"],
	read?:ResolverInputTypes["BackupMediaDocAccessFields_sizes_sixteenByNineMedium_filename_Read"],
	update?:ResolverInputTypes["BackupMediaDocAccessFields_sizes_sixteenByNineMedium_filename_Update"],
	delete?:ResolverInputTypes["BackupMediaDocAccessFields_sizes_sixteenByNineMedium_filename_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaDocAccessFields_sizes_sixteenByNineMedium_filename_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaDocAccessFields_sizes_sixteenByNineMedium_filename_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaDocAccessFields_sizes_sixteenByNineMedium_filename_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaDocAccessFields_sizes_sixteenByNineMedium_filename_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaCreateDocAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaReadDocAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaUpdateDocAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaDeleteDocAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AccountSuspendRequest"]: AliasType<{
	id?:boolean | `@${string}`,
user?: [{	locale?: ResolverInputTypes["LocaleInputType"] | undefined | null,	fallbackLocale?: ResolverInputTypes["FallbackLocaleInputType"] | undefined | null},ResolverInputTypes["User"]],
handledBy?: [{	locale?: ResolverInputTypes["LocaleInputType"] | undefined | null,	fallbackLocale?: ResolverInputTypes["FallbackLocaleInputType"] | undefined | null},ResolverInputTypes["User"]],
	updatedAt?:boolean | `@${string}`,
	createdAt?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AccountSuspendRequests"]: AliasType<{
	docs?:ResolverInputTypes["AccountSuspendRequest"],
	hasNextPage?:boolean | `@${string}`,
	hasPrevPage?:boolean | `@${string}`,
	limit?:boolean | `@${string}`,
	nextPage?:boolean | `@${string}`,
	offset?:boolean | `@${string}`,
	page?:boolean | `@${string}`,
	pagingCounter?:boolean | `@${string}`,
	prevPage?:boolean | `@${string}`,
	totalDocs?:boolean | `@${string}`,
	totalPages?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AccountSuspendRequest_where"]: {
	user?: ResolverInputTypes["AccountSuspendRequest_user_operator"] | undefined | null,
	handledBy?: ResolverInputTypes["AccountSuspendRequest_handledBy_operator"] | undefined | null,
	updatedAt?: ResolverInputTypes["AccountSuspendRequest_updatedAt_operator"] | undefined | null,
	createdAt?: ResolverInputTypes["AccountSuspendRequest_createdAt_operator"] | undefined | null,
	id?: ResolverInputTypes["AccountSuspendRequest_id_operator"] | undefined | null,
	AND?: Array<ResolverInputTypes["AccountSuspendRequest_where_and"] | undefined | null> | undefined | null,
	OR?: Array<ResolverInputTypes["AccountSuspendRequest_where_or"] | undefined | null> | undefined | null
};
	["AccountSuspendRequest_user_operator"]: {
	equals?: ResolverInputTypes["JSON"] | undefined | null,
	not_equals?: ResolverInputTypes["JSON"] | undefined | null,
	in?: Array<ResolverInputTypes["JSON"] | undefined | null> | undefined | null,
	not_in?: Array<ResolverInputTypes["JSON"] | undefined | null> | undefined | null,
	all?: Array<ResolverInputTypes["JSON"] | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["AccountSuspendRequest_handledBy_operator"]: {
	equals?: ResolverInputTypes["JSON"] | undefined | null,
	not_equals?: ResolverInputTypes["JSON"] | undefined | null,
	in?: Array<ResolverInputTypes["JSON"] | undefined | null> | undefined | null,
	not_in?: Array<ResolverInputTypes["JSON"] | undefined | null> | undefined | null,
	all?: Array<ResolverInputTypes["JSON"] | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["AccountSuspendRequest_updatedAt_operator"]: {
	equals?: ResolverInputTypes["DateTime"] | undefined | null,
	not_equals?: ResolverInputTypes["DateTime"] | undefined | null,
	greater_than_equal?: ResolverInputTypes["DateTime"] | undefined | null,
	greater_than?: ResolverInputTypes["DateTime"] | undefined | null,
	less_than_equal?: ResolverInputTypes["DateTime"] | undefined | null,
	less_than?: ResolverInputTypes["DateTime"] | undefined | null,
	like?: ResolverInputTypes["DateTime"] | undefined | null,
	exists?: boolean | undefined | null
};
	["AccountSuspendRequest_createdAt_operator"]: {
	equals?: ResolverInputTypes["DateTime"] | undefined | null,
	not_equals?: ResolverInputTypes["DateTime"] | undefined | null,
	greater_than_equal?: ResolverInputTypes["DateTime"] | undefined | null,
	greater_than?: ResolverInputTypes["DateTime"] | undefined | null,
	less_than_equal?: ResolverInputTypes["DateTime"] | undefined | null,
	less_than?: ResolverInputTypes["DateTime"] | undefined | null,
	like?: ResolverInputTypes["DateTime"] | undefined | null,
	exists?: boolean | undefined | null
};
	["AccountSuspendRequest_id_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["AccountSuspendRequest_where_and"]: {
	user?: ResolverInputTypes["AccountSuspendRequest_user_operator"] | undefined | null,
	handledBy?: ResolverInputTypes["AccountSuspendRequest_handledBy_operator"] | undefined | null,
	updatedAt?: ResolverInputTypes["AccountSuspendRequest_updatedAt_operator"] | undefined | null,
	createdAt?: ResolverInputTypes["AccountSuspendRequest_createdAt_operator"] | undefined | null,
	id?: ResolverInputTypes["AccountSuspendRequest_id_operator"] | undefined | null,
	AND?: Array<ResolverInputTypes["AccountSuspendRequest_where_and"] | undefined | null> | undefined | null,
	OR?: Array<ResolverInputTypes["AccountSuspendRequest_where_or"] | undefined | null> | undefined | null
};
	["AccountSuspendRequest_where_or"]: {
	user?: ResolverInputTypes["AccountSuspendRequest_user_operator"] | undefined | null,
	handledBy?: ResolverInputTypes["AccountSuspendRequest_handledBy_operator"] | undefined | null,
	updatedAt?: ResolverInputTypes["AccountSuspendRequest_updatedAt_operator"] | undefined | null,
	createdAt?: ResolverInputTypes["AccountSuspendRequest_createdAt_operator"] | undefined | null,
	id?: ResolverInputTypes["AccountSuspendRequest_id_operator"] | undefined | null,
	AND?: Array<ResolverInputTypes["AccountSuspendRequest_where_and"] | undefined | null> | undefined | null,
	OR?: Array<ResolverInputTypes["AccountSuspendRequest_where_or"] | undefined | null> | undefined | null
};
	["countAccountSuspendRequests"]: AliasType<{
	totalDocs?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["account_suspend_requestsDocAccess"]: AliasType<{
	fields?:ResolverInputTypes["AccountSuspendRequestsDocAccessFields"],
	create?:ResolverInputTypes["AccountSuspendRequestsCreateDocAccess"],
	read?:ResolverInputTypes["AccountSuspendRequestsReadDocAccess"],
	update?:ResolverInputTypes["AccountSuspendRequestsUpdateDocAccess"],
	delete?:ResolverInputTypes["AccountSuspendRequestsDeleteDocAccess"],
		__typename?: boolean | `@${string}`
}>;
	["AccountSuspendRequestsDocAccessFields"]: AliasType<{
	user?:ResolverInputTypes["AccountSuspendRequestsDocAccessFields_user"],
	handledBy?:ResolverInputTypes["AccountSuspendRequestsDocAccessFields_handledBy"],
	updatedAt?:ResolverInputTypes["AccountSuspendRequestsDocAccessFields_updatedAt"],
	createdAt?:ResolverInputTypes["AccountSuspendRequestsDocAccessFields_createdAt"],
		__typename?: boolean | `@${string}`
}>;
	["AccountSuspendRequestsDocAccessFields_user"]: AliasType<{
	create?:ResolverInputTypes["AccountSuspendRequestsDocAccessFields_user_Create"],
	read?:ResolverInputTypes["AccountSuspendRequestsDocAccessFields_user_Read"],
	update?:ResolverInputTypes["AccountSuspendRequestsDocAccessFields_user_Update"],
	delete?:ResolverInputTypes["AccountSuspendRequestsDocAccessFields_user_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["AccountSuspendRequestsDocAccessFields_user_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AccountSuspendRequestsDocAccessFields_user_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AccountSuspendRequestsDocAccessFields_user_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AccountSuspendRequestsDocAccessFields_user_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AccountSuspendRequestsDocAccessFields_handledBy"]: AliasType<{
	create?:ResolverInputTypes["AccountSuspendRequestsDocAccessFields_handledBy_Create"],
	read?:ResolverInputTypes["AccountSuspendRequestsDocAccessFields_handledBy_Read"],
	update?:ResolverInputTypes["AccountSuspendRequestsDocAccessFields_handledBy_Update"],
	delete?:ResolverInputTypes["AccountSuspendRequestsDocAccessFields_handledBy_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["AccountSuspendRequestsDocAccessFields_handledBy_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AccountSuspendRequestsDocAccessFields_handledBy_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AccountSuspendRequestsDocAccessFields_handledBy_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AccountSuspendRequestsDocAccessFields_handledBy_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AccountSuspendRequestsDocAccessFields_updatedAt"]: AliasType<{
	create?:ResolverInputTypes["AccountSuspendRequestsDocAccessFields_updatedAt_Create"],
	read?:ResolverInputTypes["AccountSuspendRequestsDocAccessFields_updatedAt_Read"],
	update?:ResolverInputTypes["AccountSuspendRequestsDocAccessFields_updatedAt_Update"],
	delete?:ResolverInputTypes["AccountSuspendRequestsDocAccessFields_updatedAt_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["AccountSuspendRequestsDocAccessFields_updatedAt_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AccountSuspendRequestsDocAccessFields_updatedAt_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AccountSuspendRequestsDocAccessFields_updatedAt_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AccountSuspendRequestsDocAccessFields_updatedAt_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AccountSuspendRequestsDocAccessFields_createdAt"]: AliasType<{
	create?:ResolverInputTypes["AccountSuspendRequestsDocAccessFields_createdAt_Create"],
	read?:ResolverInputTypes["AccountSuspendRequestsDocAccessFields_createdAt_Read"],
	update?:ResolverInputTypes["AccountSuspendRequestsDocAccessFields_createdAt_Update"],
	delete?:ResolverInputTypes["AccountSuspendRequestsDocAccessFields_createdAt_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["AccountSuspendRequestsDocAccessFields_createdAt_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AccountSuspendRequestsDocAccessFields_createdAt_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AccountSuspendRequestsDocAccessFields_createdAt_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AccountSuspendRequestsDocAccessFields_createdAt_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AccountSuspendRequestsCreateDocAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AccountSuspendRequestsReadDocAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AccountSuspendRequestsUpdateDocAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AccountSuspendRequestsDeleteDocAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["Device"]: AliasType<{
	id?:boolean | `@${string}`,
user?: [{	locale?: ResolverInputTypes["LocaleInputType"] | undefined | null,	fallbackLocale?: ResolverInputTypes["FallbackLocaleInputType"] | undefined | null},ResolverInputTypes["Device_User_Relationship"]],
	udid?:boolean | `@${string}`,
	pushToken?:boolean | `@${string}`,
	updatedAt?:boolean | `@${string}`,
	createdAt?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["Device_User_Relationship"]: AliasType<{
	relationTo?:boolean | `@${string}`,
	value?:ResolverInputTypes["Device_User"],
		__typename?: boolean | `@${string}`
}>;
	["Device_User_RelationTo"]:Device_User_RelationTo;
	["Device_User"]: AliasType<{
	User?:ResolverInputTypes["User"],
		__typename?: boolean | `@${string}`
}>;
	["Devices"]: AliasType<{
	docs?:ResolverInputTypes["Device"],
	hasNextPage?:boolean | `@${string}`,
	hasPrevPage?:boolean | `@${string}`,
	limit?:boolean | `@${string}`,
	nextPage?:boolean | `@${string}`,
	offset?:boolean | `@${string}`,
	page?:boolean | `@${string}`,
	pagingCounter?:boolean | `@${string}`,
	prevPage?:boolean | `@${string}`,
	totalDocs?:boolean | `@${string}`,
	totalPages?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["Device_where"]: {
	user?: ResolverInputTypes["Device_user_Relation"] | undefined | null,
	udid?: ResolverInputTypes["Device_udid_operator"] | undefined | null,
	pushToken?: ResolverInputTypes["Device_pushToken_operator"] | undefined | null,
	updatedAt?: ResolverInputTypes["Device_updatedAt_operator"] | undefined | null,
	createdAt?: ResolverInputTypes["Device_createdAt_operator"] | undefined | null,
	id?: ResolverInputTypes["Device_id_operator"] | undefined | null,
	AND?: Array<ResolverInputTypes["Device_where_and"] | undefined | null> | undefined | null,
	OR?: Array<ResolverInputTypes["Device_where_or"] | undefined | null> | undefined | null
};
	["Device_user_Relation"]: {
	relationTo?: ResolverInputTypes["Device_user_Relation_RelationTo"] | undefined | null,
	value?: ResolverInputTypes["JSON"] | undefined | null
};
	["Device_user_Relation_RelationTo"]:Device_user_Relation_RelationTo;
	["Device_udid_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Device_pushToken_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Device_updatedAt_operator"]: {
	equals?: ResolverInputTypes["DateTime"] | undefined | null,
	not_equals?: ResolverInputTypes["DateTime"] | undefined | null,
	greater_than_equal?: ResolverInputTypes["DateTime"] | undefined | null,
	greater_than?: ResolverInputTypes["DateTime"] | undefined | null,
	less_than_equal?: ResolverInputTypes["DateTime"] | undefined | null,
	less_than?: ResolverInputTypes["DateTime"] | undefined | null,
	like?: ResolverInputTypes["DateTime"] | undefined | null,
	exists?: boolean | undefined | null
};
	["Device_createdAt_operator"]: {
	equals?: ResolverInputTypes["DateTime"] | undefined | null,
	not_equals?: ResolverInputTypes["DateTime"] | undefined | null,
	greater_than_equal?: ResolverInputTypes["DateTime"] | undefined | null,
	greater_than?: ResolverInputTypes["DateTime"] | undefined | null,
	less_than_equal?: ResolverInputTypes["DateTime"] | undefined | null,
	less_than?: ResolverInputTypes["DateTime"] | undefined | null,
	like?: ResolverInputTypes["DateTime"] | undefined | null,
	exists?: boolean | undefined | null
};
	["Device_id_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Device_where_and"]: {
	user?: ResolverInputTypes["Device_user_Relation"] | undefined | null,
	udid?: ResolverInputTypes["Device_udid_operator"] | undefined | null,
	pushToken?: ResolverInputTypes["Device_pushToken_operator"] | undefined | null,
	updatedAt?: ResolverInputTypes["Device_updatedAt_operator"] | undefined | null,
	createdAt?: ResolverInputTypes["Device_createdAt_operator"] | undefined | null,
	id?: ResolverInputTypes["Device_id_operator"] | undefined | null,
	AND?: Array<ResolverInputTypes["Device_where_and"] | undefined | null> | undefined | null,
	OR?: Array<ResolverInputTypes["Device_where_or"] | undefined | null> | undefined | null
};
	["Device_where_or"]: {
	user?: ResolverInputTypes["Device_user_Relation"] | undefined | null,
	udid?: ResolverInputTypes["Device_udid_operator"] | undefined | null,
	pushToken?: ResolverInputTypes["Device_pushToken_operator"] | undefined | null,
	updatedAt?: ResolverInputTypes["Device_updatedAt_operator"] | undefined | null,
	createdAt?: ResolverInputTypes["Device_createdAt_operator"] | undefined | null,
	id?: ResolverInputTypes["Device_id_operator"] | undefined | null,
	AND?: Array<ResolverInputTypes["Device_where_and"] | undefined | null> | undefined | null,
	OR?: Array<ResolverInputTypes["Device_where_or"] | undefined | null> | undefined | null
};
	["countDevices"]: AliasType<{
	totalDocs?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["devicesDocAccess"]: AliasType<{
	fields?:ResolverInputTypes["DevicesDocAccessFields"],
	create?:ResolverInputTypes["DevicesCreateDocAccess"],
	read?:ResolverInputTypes["DevicesReadDocAccess"],
	update?:ResolverInputTypes["DevicesUpdateDocAccess"],
	delete?:ResolverInputTypes["DevicesDeleteDocAccess"],
		__typename?: boolean | `@${string}`
}>;
	["DevicesDocAccessFields"]: AliasType<{
	user?:ResolverInputTypes["DevicesDocAccessFields_user"],
	udid?:ResolverInputTypes["DevicesDocAccessFields_udid"],
	pushToken?:ResolverInputTypes["DevicesDocAccessFields_pushToken"],
	updatedAt?:ResolverInputTypes["DevicesDocAccessFields_updatedAt"],
	createdAt?:ResolverInputTypes["DevicesDocAccessFields_createdAt"],
		__typename?: boolean | `@${string}`
}>;
	["DevicesDocAccessFields_user"]: AliasType<{
	create?:ResolverInputTypes["DevicesDocAccessFields_user_Create"],
	read?:ResolverInputTypes["DevicesDocAccessFields_user_Read"],
	update?:ResolverInputTypes["DevicesDocAccessFields_user_Update"],
	delete?:ResolverInputTypes["DevicesDocAccessFields_user_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["DevicesDocAccessFields_user_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DevicesDocAccessFields_user_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DevicesDocAccessFields_user_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DevicesDocAccessFields_user_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DevicesDocAccessFields_udid"]: AliasType<{
	create?:ResolverInputTypes["DevicesDocAccessFields_udid_Create"],
	read?:ResolverInputTypes["DevicesDocAccessFields_udid_Read"],
	update?:ResolverInputTypes["DevicesDocAccessFields_udid_Update"],
	delete?:ResolverInputTypes["DevicesDocAccessFields_udid_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["DevicesDocAccessFields_udid_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DevicesDocAccessFields_udid_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DevicesDocAccessFields_udid_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DevicesDocAccessFields_udid_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DevicesDocAccessFields_pushToken"]: AliasType<{
	create?:ResolverInputTypes["DevicesDocAccessFields_pushToken_Create"],
	read?:ResolverInputTypes["DevicesDocAccessFields_pushToken_Read"],
	update?:ResolverInputTypes["DevicesDocAccessFields_pushToken_Update"],
	delete?:ResolverInputTypes["DevicesDocAccessFields_pushToken_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["DevicesDocAccessFields_pushToken_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DevicesDocAccessFields_pushToken_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DevicesDocAccessFields_pushToken_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DevicesDocAccessFields_pushToken_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DevicesDocAccessFields_updatedAt"]: AliasType<{
	create?:ResolverInputTypes["DevicesDocAccessFields_updatedAt_Create"],
	read?:ResolverInputTypes["DevicesDocAccessFields_updatedAt_Read"],
	update?:ResolverInputTypes["DevicesDocAccessFields_updatedAt_Update"],
	delete?:ResolverInputTypes["DevicesDocAccessFields_updatedAt_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["DevicesDocAccessFields_updatedAt_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DevicesDocAccessFields_updatedAt_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DevicesDocAccessFields_updatedAt_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DevicesDocAccessFields_updatedAt_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DevicesDocAccessFields_createdAt"]: AliasType<{
	create?:ResolverInputTypes["DevicesDocAccessFields_createdAt_Create"],
	read?:ResolverInputTypes["DevicesDocAccessFields_createdAt_Read"],
	update?:ResolverInputTypes["DevicesDocAccessFields_createdAt_Update"],
	delete?:ResolverInputTypes["DevicesDocAccessFields_createdAt_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["DevicesDocAccessFields_createdAt_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DevicesDocAccessFields_createdAt_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DevicesDocAccessFields_createdAt_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DevicesDocAccessFields_createdAt_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DevicesCreateDocAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DevicesReadDocAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DevicesUpdateDocAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DevicesDeleteDocAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["Notification"]: AliasType<{
	id?:boolean | `@${string}`,
receiver?: [{	locale?: ResolverInputTypes["LocaleInputType"] | undefined | null,	fallbackLocale?: ResolverInputTypes["FallbackLocaleInputType"] | undefined | null},ResolverInputTypes["User"]],
	type?:boolean | `@${string}`,
meta?: [{	locale?: ResolverInputTypes["LocaleInputType"] | undefined | null,	fallbackLocale?: ResolverInputTypes["FallbackLocaleInputType"] | undefined | null},ResolverInputTypes["Notification_Meta_Relationship"]],
	message?:boolean | `@${string}`,
sender?: [{	locale?: ResolverInputTypes["LocaleInputType"] | undefined | null,	fallbackLocale?: ResolverInputTypes["FallbackLocaleInputType"] | undefined | null},ResolverInputTypes["User"]],
	readed?:boolean | `@${string}`,
	deletedByUser?:boolean | `@${string}`,
	updatedAt?:boolean | `@${string}`,
	createdAt?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["Notification_type"]:Notification_type;
	["Notification_Meta_Relationship"]: AliasType<{
	relationTo?:boolean | `@${string}`,
	value?:ResolverInputTypes["Notification_Meta"],
		__typename?: boolean | `@${string}`
}>;
	["Notification_Meta_RelationTo"]:Notification_Meta_RelationTo;
	["Notification_Meta"]: AliasType<{
	Event?:ResolverInputTypes["Event"],
	Location?:ResolverInputTypes["Location"],
	Post?:ResolverInputTypes["Post"],
	Comment?:ResolverInputTypes["Comment"],
		__typename?: boolean | `@${string}`
}>;
	["Notifications"]: AliasType<{
	docs?:ResolverInputTypes["Notification"],
	hasNextPage?:boolean | `@${string}`,
	hasPrevPage?:boolean | `@${string}`,
	limit?:boolean | `@${string}`,
	nextPage?:boolean | `@${string}`,
	offset?:boolean | `@${string}`,
	page?:boolean | `@${string}`,
	pagingCounter?:boolean | `@${string}`,
	prevPage?:boolean | `@${string}`,
	totalDocs?:boolean | `@${string}`,
	totalPages?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["Notification_where"]: {
	receiver?: ResolverInputTypes["Notification_receiver_operator"] | undefined | null,
	type?: ResolverInputTypes["Notification_type_operator"] | undefined | null,
	meta?: ResolverInputTypes["Notification_meta_Relation"] | undefined | null,
	message?: ResolverInputTypes["Notification_message_operator"] | undefined | null,
	sender?: ResolverInputTypes["Notification_sender_operator"] | undefined | null,
	readed?: ResolverInputTypes["Notification_readed_operator"] | undefined | null,
	deletedByUser?: ResolverInputTypes["Notification_deletedByUser_operator"] | undefined | null,
	updatedAt?: ResolverInputTypes["Notification_updatedAt_operator"] | undefined | null,
	createdAt?: ResolverInputTypes["Notification_createdAt_operator"] | undefined | null,
	id?: ResolverInputTypes["Notification_id_operator"] | undefined | null,
	AND?: Array<ResolverInputTypes["Notification_where_and"] | undefined | null> | undefined | null,
	OR?: Array<ResolverInputTypes["Notification_where_or"] | undefined | null> | undefined | null
};
	["Notification_receiver_operator"]: {
	equals?: ResolverInputTypes["JSON"] | undefined | null,
	not_equals?: ResolverInputTypes["JSON"] | undefined | null,
	in?: Array<ResolverInputTypes["JSON"] | undefined | null> | undefined | null,
	not_in?: Array<ResolverInputTypes["JSON"] | undefined | null> | undefined | null,
	all?: Array<ResolverInputTypes["JSON"] | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Notification_type_operator"]: {
	equals?: ResolverInputTypes["Notification_type_Input"] | undefined | null,
	not_equals?: ResolverInputTypes["Notification_type_Input"] | undefined | null,
	in?: Array<ResolverInputTypes["Notification_type_Input"] | undefined | null> | undefined | null,
	not_in?: Array<ResolverInputTypes["Notification_type_Input"] | undefined | null> | undefined | null,
	all?: Array<ResolverInputTypes["Notification_type_Input"] | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Notification_type_Input"]:Notification_type_Input;
	["Notification_meta_Relation"]: {
	relationTo?: ResolverInputTypes["Notification_meta_Relation_RelationTo"] | undefined | null,
	value?: ResolverInputTypes["JSON"] | undefined | null
};
	["Notification_meta_Relation_RelationTo"]:Notification_meta_Relation_RelationTo;
	["Notification_message_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Notification_sender_operator"]: {
	equals?: ResolverInputTypes["JSON"] | undefined | null,
	not_equals?: ResolverInputTypes["JSON"] | undefined | null,
	in?: Array<ResolverInputTypes["JSON"] | undefined | null> | undefined | null,
	not_in?: Array<ResolverInputTypes["JSON"] | undefined | null> | undefined | null,
	all?: Array<ResolverInputTypes["JSON"] | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Notification_readed_operator"]: {
	equals?: boolean | undefined | null,
	not_equals?: boolean | undefined | null,
	exists?: boolean | undefined | null
};
	["Notification_deletedByUser_operator"]: {
	equals?: boolean | undefined | null,
	not_equals?: boolean | undefined | null,
	exists?: boolean | undefined | null
};
	["Notification_updatedAt_operator"]: {
	equals?: ResolverInputTypes["DateTime"] | undefined | null,
	not_equals?: ResolverInputTypes["DateTime"] | undefined | null,
	greater_than_equal?: ResolverInputTypes["DateTime"] | undefined | null,
	greater_than?: ResolverInputTypes["DateTime"] | undefined | null,
	less_than_equal?: ResolverInputTypes["DateTime"] | undefined | null,
	less_than?: ResolverInputTypes["DateTime"] | undefined | null,
	like?: ResolverInputTypes["DateTime"] | undefined | null,
	exists?: boolean | undefined | null
};
	["Notification_createdAt_operator"]: {
	equals?: ResolverInputTypes["DateTime"] | undefined | null,
	not_equals?: ResolverInputTypes["DateTime"] | undefined | null,
	greater_than_equal?: ResolverInputTypes["DateTime"] | undefined | null,
	greater_than?: ResolverInputTypes["DateTime"] | undefined | null,
	less_than_equal?: ResolverInputTypes["DateTime"] | undefined | null,
	less_than?: ResolverInputTypes["DateTime"] | undefined | null,
	like?: ResolverInputTypes["DateTime"] | undefined | null,
	exists?: boolean | undefined | null
};
	["Notification_id_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Notification_where_and"]: {
	receiver?: ResolverInputTypes["Notification_receiver_operator"] | undefined | null,
	type?: ResolverInputTypes["Notification_type_operator"] | undefined | null,
	meta?: ResolverInputTypes["Notification_meta_Relation"] | undefined | null,
	message?: ResolverInputTypes["Notification_message_operator"] | undefined | null,
	sender?: ResolverInputTypes["Notification_sender_operator"] | undefined | null,
	readed?: ResolverInputTypes["Notification_readed_operator"] | undefined | null,
	deletedByUser?: ResolverInputTypes["Notification_deletedByUser_operator"] | undefined | null,
	updatedAt?: ResolverInputTypes["Notification_updatedAt_operator"] | undefined | null,
	createdAt?: ResolverInputTypes["Notification_createdAt_operator"] | undefined | null,
	id?: ResolverInputTypes["Notification_id_operator"] | undefined | null,
	AND?: Array<ResolverInputTypes["Notification_where_and"] | undefined | null> | undefined | null,
	OR?: Array<ResolverInputTypes["Notification_where_or"] | undefined | null> | undefined | null
};
	["Notification_where_or"]: {
	receiver?: ResolverInputTypes["Notification_receiver_operator"] | undefined | null,
	type?: ResolverInputTypes["Notification_type_operator"] | undefined | null,
	meta?: ResolverInputTypes["Notification_meta_Relation"] | undefined | null,
	message?: ResolverInputTypes["Notification_message_operator"] | undefined | null,
	sender?: ResolverInputTypes["Notification_sender_operator"] | undefined | null,
	readed?: ResolverInputTypes["Notification_readed_operator"] | undefined | null,
	deletedByUser?: ResolverInputTypes["Notification_deletedByUser_operator"] | undefined | null,
	updatedAt?: ResolverInputTypes["Notification_updatedAt_operator"] | undefined | null,
	createdAt?: ResolverInputTypes["Notification_createdAt_operator"] | undefined | null,
	id?: ResolverInputTypes["Notification_id_operator"] | undefined | null,
	AND?: Array<ResolverInputTypes["Notification_where_and"] | undefined | null> | undefined | null,
	OR?: Array<ResolverInputTypes["Notification_where_or"] | undefined | null> | undefined | null
};
	["countNotifications"]: AliasType<{
	totalDocs?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["notificationsDocAccess"]: AliasType<{
	fields?:ResolverInputTypes["NotificationsDocAccessFields"],
	create?:ResolverInputTypes["NotificationsCreateDocAccess"],
	read?:ResolverInputTypes["NotificationsReadDocAccess"],
	update?:ResolverInputTypes["NotificationsUpdateDocAccess"],
	delete?:ResolverInputTypes["NotificationsDeleteDocAccess"],
		__typename?: boolean | `@${string}`
}>;
	["NotificationsDocAccessFields"]: AliasType<{
	receiver?:ResolverInputTypes["NotificationsDocAccessFields_receiver"],
	type?:ResolverInputTypes["NotificationsDocAccessFields_type"],
	meta?:ResolverInputTypes["NotificationsDocAccessFields_meta"],
	message?:ResolverInputTypes["NotificationsDocAccessFields_message"],
	sender?:ResolverInputTypes["NotificationsDocAccessFields_sender"],
	readed?:ResolverInputTypes["NotificationsDocAccessFields_readed"],
	deletedByUser?:ResolverInputTypes["NotificationsDocAccessFields_deletedByUser"],
	updatedAt?:ResolverInputTypes["NotificationsDocAccessFields_updatedAt"],
	createdAt?:ResolverInputTypes["NotificationsDocAccessFields_createdAt"],
		__typename?: boolean | `@${string}`
}>;
	["NotificationsDocAccessFields_receiver"]: AliasType<{
	create?:ResolverInputTypes["NotificationsDocAccessFields_receiver_Create"],
	read?:ResolverInputTypes["NotificationsDocAccessFields_receiver_Read"],
	update?:ResolverInputTypes["NotificationsDocAccessFields_receiver_Update"],
	delete?:ResolverInputTypes["NotificationsDocAccessFields_receiver_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["NotificationsDocAccessFields_receiver_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["NotificationsDocAccessFields_receiver_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["NotificationsDocAccessFields_receiver_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["NotificationsDocAccessFields_receiver_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["NotificationsDocAccessFields_type"]: AliasType<{
	create?:ResolverInputTypes["NotificationsDocAccessFields_type_Create"],
	read?:ResolverInputTypes["NotificationsDocAccessFields_type_Read"],
	update?:ResolverInputTypes["NotificationsDocAccessFields_type_Update"],
	delete?:ResolverInputTypes["NotificationsDocAccessFields_type_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["NotificationsDocAccessFields_type_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["NotificationsDocAccessFields_type_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["NotificationsDocAccessFields_type_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["NotificationsDocAccessFields_type_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["NotificationsDocAccessFields_meta"]: AliasType<{
	create?:ResolverInputTypes["NotificationsDocAccessFields_meta_Create"],
	read?:ResolverInputTypes["NotificationsDocAccessFields_meta_Read"],
	update?:ResolverInputTypes["NotificationsDocAccessFields_meta_Update"],
	delete?:ResolverInputTypes["NotificationsDocAccessFields_meta_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["NotificationsDocAccessFields_meta_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["NotificationsDocAccessFields_meta_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["NotificationsDocAccessFields_meta_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["NotificationsDocAccessFields_meta_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["NotificationsDocAccessFields_message"]: AliasType<{
	create?:ResolverInputTypes["NotificationsDocAccessFields_message_Create"],
	read?:ResolverInputTypes["NotificationsDocAccessFields_message_Read"],
	update?:ResolverInputTypes["NotificationsDocAccessFields_message_Update"],
	delete?:ResolverInputTypes["NotificationsDocAccessFields_message_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["NotificationsDocAccessFields_message_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["NotificationsDocAccessFields_message_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["NotificationsDocAccessFields_message_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["NotificationsDocAccessFields_message_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["NotificationsDocAccessFields_sender"]: AliasType<{
	create?:ResolverInputTypes["NotificationsDocAccessFields_sender_Create"],
	read?:ResolverInputTypes["NotificationsDocAccessFields_sender_Read"],
	update?:ResolverInputTypes["NotificationsDocAccessFields_sender_Update"],
	delete?:ResolverInputTypes["NotificationsDocAccessFields_sender_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["NotificationsDocAccessFields_sender_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["NotificationsDocAccessFields_sender_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["NotificationsDocAccessFields_sender_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["NotificationsDocAccessFields_sender_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["NotificationsDocAccessFields_readed"]: AliasType<{
	create?:ResolverInputTypes["NotificationsDocAccessFields_readed_Create"],
	read?:ResolverInputTypes["NotificationsDocAccessFields_readed_Read"],
	update?:ResolverInputTypes["NotificationsDocAccessFields_readed_Update"],
	delete?:ResolverInputTypes["NotificationsDocAccessFields_readed_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["NotificationsDocAccessFields_readed_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["NotificationsDocAccessFields_readed_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["NotificationsDocAccessFields_readed_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["NotificationsDocAccessFields_readed_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["NotificationsDocAccessFields_deletedByUser"]: AliasType<{
	create?:ResolverInputTypes["NotificationsDocAccessFields_deletedByUser_Create"],
	read?:ResolverInputTypes["NotificationsDocAccessFields_deletedByUser_Read"],
	update?:ResolverInputTypes["NotificationsDocAccessFields_deletedByUser_Update"],
	delete?:ResolverInputTypes["NotificationsDocAccessFields_deletedByUser_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["NotificationsDocAccessFields_deletedByUser_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["NotificationsDocAccessFields_deletedByUser_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["NotificationsDocAccessFields_deletedByUser_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["NotificationsDocAccessFields_deletedByUser_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["NotificationsDocAccessFields_updatedAt"]: AliasType<{
	create?:ResolverInputTypes["NotificationsDocAccessFields_updatedAt_Create"],
	read?:ResolverInputTypes["NotificationsDocAccessFields_updatedAt_Read"],
	update?:ResolverInputTypes["NotificationsDocAccessFields_updatedAt_Update"],
	delete?:ResolverInputTypes["NotificationsDocAccessFields_updatedAt_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["NotificationsDocAccessFields_updatedAt_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["NotificationsDocAccessFields_updatedAt_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["NotificationsDocAccessFields_updatedAt_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["NotificationsDocAccessFields_updatedAt_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["NotificationsDocAccessFields_createdAt"]: AliasType<{
	create?:ResolverInputTypes["NotificationsDocAccessFields_createdAt_Create"],
	read?:ResolverInputTypes["NotificationsDocAccessFields_createdAt_Read"],
	update?:ResolverInputTypes["NotificationsDocAccessFields_createdAt_Update"],
	delete?:ResolverInputTypes["NotificationsDocAccessFields_createdAt_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["NotificationsDocAccessFields_createdAt_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["NotificationsDocAccessFields_createdAt_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["NotificationsDocAccessFields_createdAt_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["NotificationsDocAccessFields_createdAt_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["NotificationsCreateDocAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["NotificationsReadDocAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["NotificationsUpdateDocAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["NotificationsDeleteDocAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedirectSetting"]: AliasType<{
	id?:boolean | `@${string}`,
	from?:boolean | `@${string}`,
	to?:boolean | `@${string}`,
	updatedAt?:boolean | `@${string}`,
	createdAt?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedirectSettings"]: AliasType<{
	docs?:ResolverInputTypes["RedirectSetting"],
	hasNextPage?:boolean | `@${string}`,
	hasPrevPage?:boolean | `@${string}`,
	limit?:boolean | `@${string}`,
	nextPage?:boolean | `@${string}`,
	offset?:boolean | `@${string}`,
	page?:boolean | `@${string}`,
	pagingCounter?:boolean | `@${string}`,
	prevPage?:boolean | `@${string}`,
	totalDocs?:boolean | `@${string}`,
	totalPages?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedirectSetting_where"]: {
	from?: ResolverInputTypes["RedirectSetting_from_operator"] | undefined | null,
	to?: ResolverInputTypes["RedirectSetting_to_operator"] | undefined | null,
	updatedAt?: ResolverInputTypes["RedirectSetting_updatedAt_operator"] | undefined | null,
	createdAt?: ResolverInputTypes["RedirectSetting_createdAt_operator"] | undefined | null,
	id?: ResolverInputTypes["RedirectSetting_id_operator"] | undefined | null,
	AND?: Array<ResolverInputTypes["RedirectSetting_where_and"] | undefined | null> | undefined | null,
	OR?: Array<ResolverInputTypes["RedirectSetting_where_or"] | undefined | null> | undefined | null
};
	["RedirectSetting_from_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["RedirectSetting_to_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["RedirectSetting_updatedAt_operator"]: {
	equals?: ResolverInputTypes["DateTime"] | undefined | null,
	not_equals?: ResolverInputTypes["DateTime"] | undefined | null,
	greater_than_equal?: ResolverInputTypes["DateTime"] | undefined | null,
	greater_than?: ResolverInputTypes["DateTime"] | undefined | null,
	less_than_equal?: ResolverInputTypes["DateTime"] | undefined | null,
	less_than?: ResolverInputTypes["DateTime"] | undefined | null,
	like?: ResolverInputTypes["DateTime"] | undefined | null,
	exists?: boolean | undefined | null
};
	["RedirectSetting_createdAt_operator"]: {
	equals?: ResolverInputTypes["DateTime"] | undefined | null,
	not_equals?: ResolverInputTypes["DateTime"] | undefined | null,
	greater_than_equal?: ResolverInputTypes["DateTime"] | undefined | null,
	greater_than?: ResolverInputTypes["DateTime"] | undefined | null,
	less_than_equal?: ResolverInputTypes["DateTime"] | undefined | null,
	less_than?: ResolverInputTypes["DateTime"] | undefined | null,
	like?: ResolverInputTypes["DateTime"] | undefined | null,
	exists?: boolean | undefined | null
};
	["RedirectSetting_id_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["RedirectSetting_where_and"]: {
	from?: ResolverInputTypes["RedirectSetting_from_operator"] | undefined | null,
	to?: ResolverInputTypes["RedirectSetting_to_operator"] | undefined | null,
	updatedAt?: ResolverInputTypes["RedirectSetting_updatedAt_operator"] | undefined | null,
	createdAt?: ResolverInputTypes["RedirectSetting_createdAt_operator"] | undefined | null,
	id?: ResolverInputTypes["RedirectSetting_id_operator"] | undefined | null,
	AND?: Array<ResolverInputTypes["RedirectSetting_where_and"] | undefined | null> | undefined | null,
	OR?: Array<ResolverInputTypes["RedirectSetting_where_or"] | undefined | null> | undefined | null
};
	["RedirectSetting_where_or"]: {
	from?: ResolverInputTypes["RedirectSetting_from_operator"] | undefined | null,
	to?: ResolverInputTypes["RedirectSetting_to_operator"] | undefined | null,
	updatedAt?: ResolverInputTypes["RedirectSetting_updatedAt_operator"] | undefined | null,
	createdAt?: ResolverInputTypes["RedirectSetting_createdAt_operator"] | undefined | null,
	id?: ResolverInputTypes["RedirectSetting_id_operator"] | undefined | null,
	AND?: Array<ResolverInputTypes["RedirectSetting_where_and"] | undefined | null> | undefined | null,
	OR?: Array<ResolverInputTypes["RedirectSetting_where_or"] | undefined | null> | undefined | null
};
	["countRedirectSettings"]: AliasType<{
	totalDocs?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["redirectSettingDocAccess"]: AliasType<{
	fields?:ResolverInputTypes["RedirectSettingDocAccessFields"],
	create?:ResolverInputTypes["RedirectSettingCreateDocAccess"],
	read?:ResolverInputTypes["RedirectSettingReadDocAccess"],
	update?:ResolverInputTypes["RedirectSettingUpdateDocAccess"],
	delete?:ResolverInputTypes["RedirectSettingDeleteDocAccess"],
		__typename?: boolean | `@${string}`
}>;
	["RedirectSettingDocAccessFields"]: AliasType<{
	from?:ResolverInputTypes["RedirectSettingDocAccessFields_from"],
	to?:ResolverInputTypes["RedirectSettingDocAccessFields_to"],
	updatedAt?:ResolverInputTypes["RedirectSettingDocAccessFields_updatedAt"],
	createdAt?:ResolverInputTypes["RedirectSettingDocAccessFields_createdAt"],
		__typename?: boolean | `@${string}`
}>;
	["RedirectSettingDocAccessFields_from"]: AliasType<{
	create?:ResolverInputTypes["RedirectSettingDocAccessFields_from_Create"],
	read?:ResolverInputTypes["RedirectSettingDocAccessFields_from_Read"],
	update?:ResolverInputTypes["RedirectSettingDocAccessFields_from_Update"],
	delete?:ResolverInputTypes["RedirectSettingDocAccessFields_from_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["RedirectSettingDocAccessFields_from_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedirectSettingDocAccessFields_from_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedirectSettingDocAccessFields_from_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedirectSettingDocAccessFields_from_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedirectSettingDocAccessFields_to"]: AliasType<{
	create?:ResolverInputTypes["RedirectSettingDocAccessFields_to_Create"],
	read?:ResolverInputTypes["RedirectSettingDocAccessFields_to_Read"],
	update?:ResolverInputTypes["RedirectSettingDocAccessFields_to_Update"],
	delete?:ResolverInputTypes["RedirectSettingDocAccessFields_to_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["RedirectSettingDocAccessFields_to_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedirectSettingDocAccessFields_to_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedirectSettingDocAccessFields_to_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedirectSettingDocAccessFields_to_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedirectSettingDocAccessFields_updatedAt"]: AliasType<{
	create?:ResolverInputTypes["RedirectSettingDocAccessFields_updatedAt_Create"],
	read?:ResolverInputTypes["RedirectSettingDocAccessFields_updatedAt_Read"],
	update?:ResolverInputTypes["RedirectSettingDocAccessFields_updatedAt_Update"],
	delete?:ResolverInputTypes["RedirectSettingDocAccessFields_updatedAt_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["RedirectSettingDocAccessFields_updatedAt_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedirectSettingDocAccessFields_updatedAt_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedirectSettingDocAccessFields_updatedAt_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedirectSettingDocAccessFields_updatedAt_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedirectSettingDocAccessFields_createdAt"]: AliasType<{
	create?:ResolverInputTypes["RedirectSettingDocAccessFields_createdAt_Create"],
	read?:ResolverInputTypes["RedirectSettingDocAccessFields_createdAt_Read"],
	update?:ResolverInputTypes["RedirectSettingDocAccessFields_createdAt_Update"],
	delete?:ResolverInputTypes["RedirectSettingDocAccessFields_createdAt_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["RedirectSettingDocAccessFields_createdAt_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedirectSettingDocAccessFields_createdAt_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedirectSettingDocAccessFields_createdAt_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedirectSettingDocAccessFields_createdAt_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedirectSettingCreateDocAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedirectSettingReadDocAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedirectSettingUpdateDocAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedirectSettingDeleteDocAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventRanking"]: AliasType<{
	id?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	visible?:boolean | `@${string}`,
	maxItems?:boolean | `@${string}`,
	template?:boolean | `@${string}`,
	list?:ResolverInputTypes["EventRanking_List"],
	region?:boolean | `@${string}`,
	updatedAt?:boolean | `@${string}`,
	createdAt?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventRanking_template"]:EventRanking_template;
	["EventRanking_List"]: AliasType<{
item?: [{	locale?: ResolverInputTypes["LocaleInputType"] | undefined | null,	fallbackLocale?: ResolverInputTypes["FallbackLocaleInputType"] | undefined | null},ResolverInputTypes["Event"]],
	id?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventRanking_region"]:EventRanking_region;
	["EventRankings"]: AliasType<{
	docs?:ResolverInputTypes["EventRanking"],
	hasNextPage?:boolean | `@${string}`,
	hasPrevPage?:boolean | `@${string}`,
	limit?:boolean | `@${string}`,
	nextPage?:boolean | `@${string}`,
	offset?:boolean | `@${string}`,
	page?:boolean | `@${string}`,
	pagingCounter?:boolean | `@${string}`,
	prevPage?:boolean | `@${string}`,
	totalDocs?:boolean | `@${string}`,
	totalPages?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventRanking_where"]: {
	name?: ResolverInputTypes["EventRanking_name_operator"] | undefined | null,
	visible?: ResolverInputTypes["EventRanking_visible_operator"] | undefined | null,
	maxItems?: ResolverInputTypes["EventRanking_maxItems_operator"] | undefined | null,
	template?: ResolverInputTypes["EventRanking_template_operator"] | undefined | null,
	list__item?: ResolverInputTypes["EventRanking_list__item_operator"] | undefined | null,
	list__id?: ResolverInputTypes["EventRanking_list__id_operator"] | undefined | null,
	region?: ResolverInputTypes["EventRanking_region_operator"] | undefined | null,
	updatedAt?: ResolverInputTypes["EventRanking_updatedAt_operator"] | undefined | null,
	createdAt?: ResolverInputTypes["EventRanking_createdAt_operator"] | undefined | null,
	id?: ResolverInputTypes["EventRanking_id_operator"] | undefined | null,
	AND?: Array<ResolverInputTypes["EventRanking_where_and"] | undefined | null> | undefined | null,
	OR?: Array<ResolverInputTypes["EventRanking_where_or"] | undefined | null> | undefined | null
};
	["EventRanking_name_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null
};
	["EventRanking_visible_operator"]: {
	equals?: boolean | undefined | null,
	not_equals?: boolean | undefined | null,
	exists?: boolean | undefined | null
};
	["EventRanking_maxItems_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null
};
	["EventRanking_template_operator"]: {
	equals?: ResolverInputTypes["EventRanking_template_Input"] | undefined | null,
	not_equals?: ResolverInputTypes["EventRanking_template_Input"] | undefined | null,
	in?: Array<ResolverInputTypes["EventRanking_template_Input"] | undefined | null> | undefined | null,
	not_in?: Array<ResolverInputTypes["EventRanking_template_Input"] | undefined | null> | undefined | null,
	all?: Array<ResolverInputTypes["EventRanking_template_Input"] | undefined | null> | undefined | null
};
	["EventRanking_template_Input"]:EventRanking_template_Input;
	["EventRanking_list__item_operator"]: {
	equals?: ResolverInputTypes["JSON"] | undefined | null,
	not_equals?: ResolverInputTypes["JSON"] | undefined | null,
	in?: Array<ResolverInputTypes["JSON"] | undefined | null> | undefined | null,
	not_in?: Array<ResolverInputTypes["JSON"] | undefined | null> | undefined | null,
	all?: Array<ResolverInputTypes["JSON"] | undefined | null> | undefined | null
};
	["EventRanking_list__id_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["EventRanking_region_operator"]: {
	equals?: ResolverInputTypes["EventRanking_region_Input"] | undefined | null,
	not_equals?: ResolverInputTypes["EventRanking_region_Input"] | undefined | null,
	in?: Array<ResolverInputTypes["EventRanking_region_Input"] | undefined | null> | undefined | null,
	not_in?: Array<ResolverInputTypes["EventRanking_region_Input"] | undefined | null> | undefined | null,
	all?: Array<ResolverInputTypes["EventRanking_region_Input"] | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["EventRanking_region_Input"]:EventRanking_region_Input;
	["EventRanking_updatedAt_operator"]: {
	equals?: ResolverInputTypes["DateTime"] | undefined | null,
	not_equals?: ResolverInputTypes["DateTime"] | undefined | null,
	greater_than_equal?: ResolverInputTypes["DateTime"] | undefined | null,
	greater_than?: ResolverInputTypes["DateTime"] | undefined | null,
	less_than_equal?: ResolverInputTypes["DateTime"] | undefined | null,
	less_than?: ResolverInputTypes["DateTime"] | undefined | null,
	like?: ResolverInputTypes["DateTime"] | undefined | null,
	exists?: boolean | undefined | null
};
	["EventRanking_createdAt_operator"]: {
	equals?: ResolverInputTypes["DateTime"] | undefined | null,
	not_equals?: ResolverInputTypes["DateTime"] | undefined | null,
	greater_than_equal?: ResolverInputTypes["DateTime"] | undefined | null,
	greater_than?: ResolverInputTypes["DateTime"] | undefined | null,
	less_than_equal?: ResolverInputTypes["DateTime"] | undefined | null,
	less_than?: ResolverInputTypes["DateTime"] | undefined | null,
	like?: ResolverInputTypes["DateTime"] | undefined | null,
	exists?: boolean | undefined | null
};
	["EventRanking_id_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["EventRanking_where_and"]: {
	name?: ResolverInputTypes["EventRanking_name_operator"] | undefined | null,
	visible?: ResolverInputTypes["EventRanking_visible_operator"] | undefined | null,
	maxItems?: ResolverInputTypes["EventRanking_maxItems_operator"] | undefined | null,
	template?: ResolverInputTypes["EventRanking_template_operator"] | undefined | null,
	list__item?: ResolverInputTypes["EventRanking_list__item_operator"] | undefined | null,
	list__id?: ResolverInputTypes["EventRanking_list__id_operator"] | undefined | null,
	region?: ResolverInputTypes["EventRanking_region_operator"] | undefined | null,
	updatedAt?: ResolverInputTypes["EventRanking_updatedAt_operator"] | undefined | null,
	createdAt?: ResolverInputTypes["EventRanking_createdAt_operator"] | undefined | null,
	id?: ResolverInputTypes["EventRanking_id_operator"] | undefined | null,
	AND?: Array<ResolverInputTypes["EventRanking_where_and"] | undefined | null> | undefined | null,
	OR?: Array<ResolverInputTypes["EventRanking_where_or"] | undefined | null> | undefined | null
};
	["EventRanking_where_or"]: {
	name?: ResolverInputTypes["EventRanking_name_operator"] | undefined | null,
	visible?: ResolverInputTypes["EventRanking_visible_operator"] | undefined | null,
	maxItems?: ResolverInputTypes["EventRanking_maxItems_operator"] | undefined | null,
	template?: ResolverInputTypes["EventRanking_template_operator"] | undefined | null,
	list__item?: ResolverInputTypes["EventRanking_list__item_operator"] | undefined | null,
	list__id?: ResolverInputTypes["EventRanking_list__id_operator"] | undefined | null,
	region?: ResolverInputTypes["EventRanking_region_operator"] | undefined | null,
	updatedAt?: ResolverInputTypes["EventRanking_updatedAt_operator"] | undefined | null,
	createdAt?: ResolverInputTypes["EventRanking_createdAt_operator"] | undefined | null,
	id?: ResolverInputTypes["EventRanking_id_operator"] | undefined | null,
	AND?: Array<ResolverInputTypes["EventRanking_where_and"] | undefined | null> | undefined | null,
	OR?: Array<ResolverInputTypes["EventRanking_where_or"] | undefined | null> | undefined | null
};
	["countEventRankings"]: AliasType<{
	totalDocs?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ranking_eventsDocAccess"]: AliasType<{
	fields?:ResolverInputTypes["RankingEventsDocAccessFields"],
	create?:ResolverInputTypes["RankingEventsCreateDocAccess"],
	read?:ResolverInputTypes["RankingEventsReadDocAccess"],
	update?:ResolverInputTypes["RankingEventsUpdateDocAccess"],
	delete?:ResolverInputTypes["RankingEventsDeleteDocAccess"],
		__typename?: boolean | `@${string}`
}>;
	["RankingEventsDocAccessFields"]: AliasType<{
	name?:ResolverInputTypes["RankingEventsDocAccessFields_name"],
	visible?:ResolverInputTypes["RankingEventsDocAccessFields_visible"],
	maxItems?:ResolverInputTypes["RankingEventsDocAccessFields_maxItems"],
	template?:ResolverInputTypes["RankingEventsDocAccessFields_template"],
	list?:ResolverInputTypes["RankingEventsDocAccessFields_list"],
	region?:ResolverInputTypes["RankingEventsDocAccessFields_region"],
	updatedAt?:ResolverInputTypes["RankingEventsDocAccessFields_updatedAt"],
	createdAt?:ResolverInputTypes["RankingEventsDocAccessFields_createdAt"],
		__typename?: boolean | `@${string}`
}>;
	["RankingEventsDocAccessFields_name"]: AliasType<{
	create?:ResolverInputTypes["RankingEventsDocAccessFields_name_Create"],
	read?:ResolverInputTypes["RankingEventsDocAccessFields_name_Read"],
	update?:ResolverInputTypes["RankingEventsDocAccessFields_name_Update"],
	delete?:ResolverInputTypes["RankingEventsDocAccessFields_name_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["RankingEventsDocAccessFields_name_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RankingEventsDocAccessFields_name_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RankingEventsDocAccessFields_name_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RankingEventsDocAccessFields_name_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RankingEventsDocAccessFields_visible"]: AliasType<{
	create?:ResolverInputTypes["RankingEventsDocAccessFields_visible_Create"],
	read?:ResolverInputTypes["RankingEventsDocAccessFields_visible_Read"],
	update?:ResolverInputTypes["RankingEventsDocAccessFields_visible_Update"],
	delete?:ResolverInputTypes["RankingEventsDocAccessFields_visible_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["RankingEventsDocAccessFields_visible_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RankingEventsDocAccessFields_visible_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RankingEventsDocAccessFields_visible_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RankingEventsDocAccessFields_visible_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RankingEventsDocAccessFields_maxItems"]: AliasType<{
	create?:ResolverInputTypes["RankingEventsDocAccessFields_maxItems_Create"],
	read?:ResolverInputTypes["RankingEventsDocAccessFields_maxItems_Read"],
	update?:ResolverInputTypes["RankingEventsDocAccessFields_maxItems_Update"],
	delete?:ResolverInputTypes["RankingEventsDocAccessFields_maxItems_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["RankingEventsDocAccessFields_maxItems_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RankingEventsDocAccessFields_maxItems_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RankingEventsDocAccessFields_maxItems_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RankingEventsDocAccessFields_maxItems_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RankingEventsDocAccessFields_template"]: AliasType<{
	create?:ResolverInputTypes["RankingEventsDocAccessFields_template_Create"],
	read?:ResolverInputTypes["RankingEventsDocAccessFields_template_Read"],
	update?:ResolverInputTypes["RankingEventsDocAccessFields_template_Update"],
	delete?:ResolverInputTypes["RankingEventsDocAccessFields_template_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["RankingEventsDocAccessFields_template_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RankingEventsDocAccessFields_template_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RankingEventsDocAccessFields_template_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RankingEventsDocAccessFields_template_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RankingEventsDocAccessFields_list"]: AliasType<{
	create?:ResolverInputTypes["RankingEventsDocAccessFields_list_Create"],
	read?:ResolverInputTypes["RankingEventsDocAccessFields_list_Read"],
	update?:ResolverInputTypes["RankingEventsDocAccessFields_list_Update"],
	delete?:ResolverInputTypes["RankingEventsDocAccessFields_list_Delete"],
	fields?:ResolverInputTypes["RankingEventsDocAccessFields_list_Fields"],
		__typename?: boolean | `@${string}`
}>;
	["RankingEventsDocAccessFields_list_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RankingEventsDocAccessFields_list_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RankingEventsDocAccessFields_list_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RankingEventsDocAccessFields_list_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RankingEventsDocAccessFields_list_Fields"]: AliasType<{
	item?:ResolverInputTypes["RankingEventsDocAccessFields_list_item"],
	id?:ResolverInputTypes["RankingEventsDocAccessFields_list_id"],
		__typename?: boolean | `@${string}`
}>;
	["RankingEventsDocAccessFields_list_item"]: AliasType<{
	create?:ResolverInputTypes["RankingEventsDocAccessFields_list_item_Create"],
	read?:ResolverInputTypes["RankingEventsDocAccessFields_list_item_Read"],
	update?:ResolverInputTypes["RankingEventsDocAccessFields_list_item_Update"],
	delete?:ResolverInputTypes["RankingEventsDocAccessFields_list_item_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["RankingEventsDocAccessFields_list_item_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RankingEventsDocAccessFields_list_item_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RankingEventsDocAccessFields_list_item_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RankingEventsDocAccessFields_list_item_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RankingEventsDocAccessFields_list_id"]: AliasType<{
	create?:ResolverInputTypes["RankingEventsDocAccessFields_list_id_Create"],
	read?:ResolverInputTypes["RankingEventsDocAccessFields_list_id_Read"],
	update?:ResolverInputTypes["RankingEventsDocAccessFields_list_id_Update"],
	delete?:ResolverInputTypes["RankingEventsDocAccessFields_list_id_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["RankingEventsDocAccessFields_list_id_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RankingEventsDocAccessFields_list_id_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RankingEventsDocAccessFields_list_id_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RankingEventsDocAccessFields_list_id_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RankingEventsDocAccessFields_region"]: AliasType<{
	create?:ResolverInputTypes["RankingEventsDocAccessFields_region_Create"],
	read?:ResolverInputTypes["RankingEventsDocAccessFields_region_Read"],
	update?:ResolverInputTypes["RankingEventsDocAccessFields_region_Update"],
	delete?:ResolverInputTypes["RankingEventsDocAccessFields_region_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["RankingEventsDocAccessFields_region_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RankingEventsDocAccessFields_region_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RankingEventsDocAccessFields_region_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RankingEventsDocAccessFields_region_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RankingEventsDocAccessFields_updatedAt"]: AliasType<{
	create?:ResolverInputTypes["RankingEventsDocAccessFields_updatedAt_Create"],
	read?:ResolverInputTypes["RankingEventsDocAccessFields_updatedAt_Read"],
	update?:ResolverInputTypes["RankingEventsDocAccessFields_updatedAt_Update"],
	delete?:ResolverInputTypes["RankingEventsDocAccessFields_updatedAt_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["RankingEventsDocAccessFields_updatedAt_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RankingEventsDocAccessFields_updatedAt_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RankingEventsDocAccessFields_updatedAt_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RankingEventsDocAccessFields_updatedAt_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RankingEventsDocAccessFields_createdAt"]: AliasType<{
	create?:ResolverInputTypes["RankingEventsDocAccessFields_createdAt_Create"],
	read?:ResolverInputTypes["RankingEventsDocAccessFields_createdAt_Read"],
	update?:ResolverInputTypes["RankingEventsDocAccessFields_createdAt_Update"],
	delete?:ResolverInputTypes["RankingEventsDocAccessFields_createdAt_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["RankingEventsDocAccessFields_createdAt_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RankingEventsDocAccessFields_createdAt_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RankingEventsDocAccessFields_createdAt_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RankingEventsDocAccessFields_createdAt_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RankingEventsCreateDocAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RankingEventsReadDocAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RankingEventsUpdateDocAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RankingEventsDeleteDocAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationRanking"]: AliasType<{
	id?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	visible?:boolean | `@${string}`,
	maxItems?:boolean | `@${string}`,
	template?:boolean | `@${string}`,
	list?:ResolverInputTypes["LocationRanking_List"],
	region?:boolean | `@${string}`,
	updatedAt?:boolean | `@${string}`,
	createdAt?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationRanking_template"]:LocationRanking_template;
	["LocationRanking_List"]: AliasType<{
item?: [{	locale?: ResolverInputTypes["LocaleInputType"] | undefined | null,	fallbackLocale?: ResolverInputTypes["FallbackLocaleInputType"] | undefined | null},ResolverInputTypes["Location"]],
	id?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationRanking_region"]:LocationRanking_region;
	["LocationRankings"]: AliasType<{
	docs?:ResolverInputTypes["LocationRanking"],
	hasNextPage?:boolean | `@${string}`,
	hasPrevPage?:boolean | `@${string}`,
	limit?:boolean | `@${string}`,
	nextPage?:boolean | `@${string}`,
	offset?:boolean | `@${string}`,
	page?:boolean | `@${string}`,
	pagingCounter?:boolean | `@${string}`,
	prevPage?:boolean | `@${string}`,
	totalDocs?:boolean | `@${string}`,
	totalPages?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationRanking_where"]: {
	name?: ResolverInputTypes["LocationRanking_name_operator"] | undefined | null,
	visible?: ResolverInputTypes["LocationRanking_visible_operator"] | undefined | null,
	maxItems?: ResolverInputTypes["LocationRanking_maxItems_operator"] | undefined | null,
	template?: ResolverInputTypes["LocationRanking_template_operator"] | undefined | null,
	list__item?: ResolverInputTypes["LocationRanking_list__item_operator"] | undefined | null,
	list__id?: ResolverInputTypes["LocationRanking_list__id_operator"] | undefined | null,
	region?: ResolverInputTypes["LocationRanking_region_operator"] | undefined | null,
	updatedAt?: ResolverInputTypes["LocationRanking_updatedAt_operator"] | undefined | null,
	createdAt?: ResolverInputTypes["LocationRanking_createdAt_operator"] | undefined | null,
	id?: ResolverInputTypes["LocationRanking_id_operator"] | undefined | null,
	AND?: Array<ResolverInputTypes["LocationRanking_where_and"] | undefined | null> | undefined | null,
	OR?: Array<ResolverInputTypes["LocationRanking_where_or"] | undefined | null> | undefined | null
};
	["LocationRanking_name_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null
};
	["LocationRanking_visible_operator"]: {
	equals?: boolean | undefined | null,
	not_equals?: boolean | undefined | null,
	exists?: boolean | undefined | null
};
	["LocationRanking_maxItems_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["LocationRanking_template_operator"]: {
	equals?: ResolverInputTypes["LocationRanking_template_Input"] | undefined | null,
	not_equals?: ResolverInputTypes["LocationRanking_template_Input"] | undefined | null,
	in?: Array<ResolverInputTypes["LocationRanking_template_Input"] | undefined | null> | undefined | null,
	not_in?: Array<ResolverInputTypes["LocationRanking_template_Input"] | undefined | null> | undefined | null,
	all?: Array<ResolverInputTypes["LocationRanking_template_Input"] | undefined | null> | undefined | null
};
	["LocationRanking_template_Input"]:LocationRanking_template_Input;
	["LocationRanking_list__item_operator"]: {
	equals?: ResolverInputTypes["JSON"] | undefined | null,
	not_equals?: ResolverInputTypes["JSON"] | undefined | null,
	in?: Array<ResolverInputTypes["JSON"] | undefined | null> | undefined | null,
	not_in?: Array<ResolverInputTypes["JSON"] | undefined | null> | undefined | null,
	all?: Array<ResolverInputTypes["JSON"] | undefined | null> | undefined | null
};
	["LocationRanking_list__id_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["LocationRanking_region_operator"]: {
	equals?: ResolverInputTypes["LocationRanking_region_Input"] | undefined | null,
	not_equals?: ResolverInputTypes["LocationRanking_region_Input"] | undefined | null,
	in?: Array<ResolverInputTypes["LocationRanking_region_Input"] | undefined | null> | undefined | null,
	not_in?: Array<ResolverInputTypes["LocationRanking_region_Input"] | undefined | null> | undefined | null,
	all?: Array<ResolverInputTypes["LocationRanking_region_Input"] | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["LocationRanking_region_Input"]:LocationRanking_region_Input;
	["LocationRanking_updatedAt_operator"]: {
	equals?: ResolverInputTypes["DateTime"] | undefined | null,
	not_equals?: ResolverInputTypes["DateTime"] | undefined | null,
	greater_than_equal?: ResolverInputTypes["DateTime"] | undefined | null,
	greater_than?: ResolverInputTypes["DateTime"] | undefined | null,
	less_than_equal?: ResolverInputTypes["DateTime"] | undefined | null,
	less_than?: ResolverInputTypes["DateTime"] | undefined | null,
	like?: ResolverInputTypes["DateTime"] | undefined | null,
	exists?: boolean | undefined | null
};
	["LocationRanking_createdAt_operator"]: {
	equals?: ResolverInputTypes["DateTime"] | undefined | null,
	not_equals?: ResolverInputTypes["DateTime"] | undefined | null,
	greater_than_equal?: ResolverInputTypes["DateTime"] | undefined | null,
	greater_than?: ResolverInputTypes["DateTime"] | undefined | null,
	less_than_equal?: ResolverInputTypes["DateTime"] | undefined | null,
	less_than?: ResolverInputTypes["DateTime"] | undefined | null,
	like?: ResolverInputTypes["DateTime"] | undefined | null,
	exists?: boolean | undefined | null
};
	["LocationRanking_id_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["LocationRanking_where_and"]: {
	name?: ResolverInputTypes["LocationRanking_name_operator"] | undefined | null,
	visible?: ResolverInputTypes["LocationRanking_visible_operator"] | undefined | null,
	maxItems?: ResolverInputTypes["LocationRanking_maxItems_operator"] | undefined | null,
	template?: ResolverInputTypes["LocationRanking_template_operator"] | undefined | null,
	list__item?: ResolverInputTypes["LocationRanking_list__item_operator"] | undefined | null,
	list__id?: ResolverInputTypes["LocationRanking_list__id_operator"] | undefined | null,
	region?: ResolverInputTypes["LocationRanking_region_operator"] | undefined | null,
	updatedAt?: ResolverInputTypes["LocationRanking_updatedAt_operator"] | undefined | null,
	createdAt?: ResolverInputTypes["LocationRanking_createdAt_operator"] | undefined | null,
	id?: ResolverInputTypes["LocationRanking_id_operator"] | undefined | null,
	AND?: Array<ResolverInputTypes["LocationRanking_where_and"] | undefined | null> | undefined | null,
	OR?: Array<ResolverInputTypes["LocationRanking_where_or"] | undefined | null> | undefined | null
};
	["LocationRanking_where_or"]: {
	name?: ResolverInputTypes["LocationRanking_name_operator"] | undefined | null,
	visible?: ResolverInputTypes["LocationRanking_visible_operator"] | undefined | null,
	maxItems?: ResolverInputTypes["LocationRanking_maxItems_operator"] | undefined | null,
	template?: ResolverInputTypes["LocationRanking_template_operator"] | undefined | null,
	list__item?: ResolverInputTypes["LocationRanking_list__item_operator"] | undefined | null,
	list__id?: ResolverInputTypes["LocationRanking_list__id_operator"] | undefined | null,
	region?: ResolverInputTypes["LocationRanking_region_operator"] | undefined | null,
	updatedAt?: ResolverInputTypes["LocationRanking_updatedAt_operator"] | undefined | null,
	createdAt?: ResolverInputTypes["LocationRanking_createdAt_operator"] | undefined | null,
	id?: ResolverInputTypes["LocationRanking_id_operator"] | undefined | null,
	AND?: Array<ResolverInputTypes["LocationRanking_where_and"] | undefined | null> | undefined | null,
	OR?: Array<ResolverInputTypes["LocationRanking_where_or"] | undefined | null> | undefined | null
};
	["countLocationRankings"]: AliasType<{
	totalDocs?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ranking_locationsDocAccess"]: AliasType<{
	fields?:ResolverInputTypes["RankingLocationsDocAccessFields"],
	create?:ResolverInputTypes["RankingLocationsCreateDocAccess"],
	read?:ResolverInputTypes["RankingLocationsReadDocAccess"],
	update?:ResolverInputTypes["RankingLocationsUpdateDocAccess"],
	delete?:ResolverInputTypes["RankingLocationsDeleteDocAccess"],
		__typename?: boolean | `@${string}`
}>;
	["RankingLocationsDocAccessFields"]: AliasType<{
	name?:ResolverInputTypes["RankingLocationsDocAccessFields_name"],
	visible?:ResolverInputTypes["RankingLocationsDocAccessFields_visible"],
	maxItems?:ResolverInputTypes["RankingLocationsDocAccessFields_maxItems"],
	template?:ResolverInputTypes["RankingLocationsDocAccessFields_template"],
	list?:ResolverInputTypes["RankingLocationsDocAccessFields_list"],
	region?:ResolverInputTypes["RankingLocationsDocAccessFields_region"],
	updatedAt?:ResolverInputTypes["RankingLocationsDocAccessFields_updatedAt"],
	createdAt?:ResolverInputTypes["RankingLocationsDocAccessFields_createdAt"],
		__typename?: boolean | `@${string}`
}>;
	["RankingLocationsDocAccessFields_name"]: AliasType<{
	create?:ResolverInputTypes["RankingLocationsDocAccessFields_name_Create"],
	read?:ResolverInputTypes["RankingLocationsDocAccessFields_name_Read"],
	update?:ResolverInputTypes["RankingLocationsDocAccessFields_name_Update"],
	delete?:ResolverInputTypes["RankingLocationsDocAccessFields_name_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["RankingLocationsDocAccessFields_name_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RankingLocationsDocAccessFields_name_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RankingLocationsDocAccessFields_name_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RankingLocationsDocAccessFields_name_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RankingLocationsDocAccessFields_visible"]: AliasType<{
	create?:ResolverInputTypes["RankingLocationsDocAccessFields_visible_Create"],
	read?:ResolverInputTypes["RankingLocationsDocAccessFields_visible_Read"],
	update?:ResolverInputTypes["RankingLocationsDocAccessFields_visible_Update"],
	delete?:ResolverInputTypes["RankingLocationsDocAccessFields_visible_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["RankingLocationsDocAccessFields_visible_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RankingLocationsDocAccessFields_visible_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RankingLocationsDocAccessFields_visible_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RankingLocationsDocAccessFields_visible_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RankingLocationsDocAccessFields_maxItems"]: AliasType<{
	create?:ResolverInputTypes["RankingLocationsDocAccessFields_maxItems_Create"],
	read?:ResolverInputTypes["RankingLocationsDocAccessFields_maxItems_Read"],
	update?:ResolverInputTypes["RankingLocationsDocAccessFields_maxItems_Update"],
	delete?:ResolverInputTypes["RankingLocationsDocAccessFields_maxItems_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["RankingLocationsDocAccessFields_maxItems_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RankingLocationsDocAccessFields_maxItems_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RankingLocationsDocAccessFields_maxItems_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RankingLocationsDocAccessFields_maxItems_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RankingLocationsDocAccessFields_template"]: AliasType<{
	create?:ResolverInputTypes["RankingLocationsDocAccessFields_template_Create"],
	read?:ResolverInputTypes["RankingLocationsDocAccessFields_template_Read"],
	update?:ResolverInputTypes["RankingLocationsDocAccessFields_template_Update"],
	delete?:ResolverInputTypes["RankingLocationsDocAccessFields_template_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["RankingLocationsDocAccessFields_template_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RankingLocationsDocAccessFields_template_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RankingLocationsDocAccessFields_template_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RankingLocationsDocAccessFields_template_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RankingLocationsDocAccessFields_list"]: AliasType<{
	create?:ResolverInputTypes["RankingLocationsDocAccessFields_list_Create"],
	read?:ResolverInputTypes["RankingLocationsDocAccessFields_list_Read"],
	update?:ResolverInputTypes["RankingLocationsDocAccessFields_list_Update"],
	delete?:ResolverInputTypes["RankingLocationsDocAccessFields_list_Delete"],
	fields?:ResolverInputTypes["RankingLocationsDocAccessFields_list_Fields"],
		__typename?: boolean | `@${string}`
}>;
	["RankingLocationsDocAccessFields_list_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RankingLocationsDocAccessFields_list_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RankingLocationsDocAccessFields_list_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RankingLocationsDocAccessFields_list_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RankingLocationsDocAccessFields_list_Fields"]: AliasType<{
	item?:ResolverInputTypes["RankingLocationsDocAccessFields_list_item"],
	id?:ResolverInputTypes["RankingLocationsDocAccessFields_list_id"],
		__typename?: boolean | `@${string}`
}>;
	["RankingLocationsDocAccessFields_list_item"]: AliasType<{
	create?:ResolverInputTypes["RankingLocationsDocAccessFields_list_item_Create"],
	read?:ResolverInputTypes["RankingLocationsDocAccessFields_list_item_Read"],
	update?:ResolverInputTypes["RankingLocationsDocAccessFields_list_item_Update"],
	delete?:ResolverInputTypes["RankingLocationsDocAccessFields_list_item_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["RankingLocationsDocAccessFields_list_item_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RankingLocationsDocAccessFields_list_item_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RankingLocationsDocAccessFields_list_item_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RankingLocationsDocAccessFields_list_item_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RankingLocationsDocAccessFields_list_id"]: AliasType<{
	create?:ResolverInputTypes["RankingLocationsDocAccessFields_list_id_Create"],
	read?:ResolverInputTypes["RankingLocationsDocAccessFields_list_id_Read"],
	update?:ResolverInputTypes["RankingLocationsDocAccessFields_list_id_Update"],
	delete?:ResolverInputTypes["RankingLocationsDocAccessFields_list_id_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["RankingLocationsDocAccessFields_list_id_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RankingLocationsDocAccessFields_list_id_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RankingLocationsDocAccessFields_list_id_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RankingLocationsDocAccessFields_list_id_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RankingLocationsDocAccessFields_region"]: AliasType<{
	create?:ResolverInputTypes["RankingLocationsDocAccessFields_region_Create"],
	read?:ResolverInputTypes["RankingLocationsDocAccessFields_region_Read"],
	update?:ResolverInputTypes["RankingLocationsDocAccessFields_region_Update"],
	delete?:ResolverInputTypes["RankingLocationsDocAccessFields_region_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["RankingLocationsDocAccessFields_region_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RankingLocationsDocAccessFields_region_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RankingLocationsDocAccessFields_region_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RankingLocationsDocAccessFields_region_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RankingLocationsDocAccessFields_updatedAt"]: AliasType<{
	create?:ResolverInputTypes["RankingLocationsDocAccessFields_updatedAt_Create"],
	read?:ResolverInputTypes["RankingLocationsDocAccessFields_updatedAt_Read"],
	update?:ResolverInputTypes["RankingLocationsDocAccessFields_updatedAt_Update"],
	delete?:ResolverInputTypes["RankingLocationsDocAccessFields_updatedAt_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["RankingLocationsDocAccessFields_updatedAt_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RankingLocationsDocAccessFields_updatedAt_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RankingLocationsDocAccessFields_updatedAt_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RankingLocationsDocAccessFields_updatedAt_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RankingLocationsDocAccessFields_createdAt"]: AliasType<{
	create?:ResolverInputTypes["RankingLocationsDocAccessFields_createdAt_Create"],
	read?:ResolverInputTypes["RankingLocationsDocAccessFields_createdAt_Read"],
	update?:ResolverInputTypes["RankingLocationsDocAccessFields_createdAt_Update"],
	delete?:ResolverInputTypes["RankingLocationsDocAccessFields_createdAt_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["RankingLocationsDocAccessFields_createdAt_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RankingLocationsDocAccessFields_createdAt_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RankingLocationsDocAccessFields_createdAt_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RankingLocationsDocAccessFields_createdAt_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RankingLocationsCreateDocAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RankingLocationsReadDocAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RankingLocationsUpdateDocAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RankingLocationsDeleteDocAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventCalendar"]: AliasType<{
	id?:boolean | `@${string}`,
	date?:boolean | `@${string}`,
item?: [{	locale?: ResolverInputTypes["LocaleInputType"] | undefined | null,	fallbackLocale?: ResolverInputTypes["FallbackLocaleInputType"] | undefined | null},ResolverInputTypes["Event"]],
	title?:boolean | `@${string}`,
	region?:boolean | `@${string}`,
	updatedAt?:boolean | `@${string}`,
	createdAt?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventCalendar_region"]:EventCalendar_region;
	["EventCalendars"]: AliasType<{
	docs?:ResolverInputTypes["EventCalendar"],
	hasNextPage?:boolean | `@${string}`,
	hasPrevPage?:boolean | `@${string}`,
	limit?:boolean | `@${string}`,
	nextPage?:boolean | `@${string}`,
	offset?:boolean | `@${string}`,
	page?:boolean | `@${string}`,
	pagingCounter?:boolean | `@${string}`,
	prevPage?:boolean | `@${string}`,
	totalDocs?:boolean | `@${string}`,
	totalPages?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventCalendar_where"]: {
	date?: ResolverInputTypes["EventCalendar_date_operator"] | undefined | null,
	item?: ResolverInputTypes["EventCalendar_item_operator"] | undefined | null,
	title?: ResolverInputTypes["EventCalendar_title_operator"] | undefined | null,
	region?: ResolverInputTypes["EventCalendar_region_operator"] | undefined | null,
	updatedAt?: ResolverInputTypes["EventCalendar_updatedAt_operator"] | undefined | null,
	createdAt?: ResolverInputTypes["EventCalendar_createdAt_operator"] | undefined | null,
	id?: ResolverInputTypes["EventCalendar_id_operator"] | undefined | null,
	AND?: Array<ResolverInputTypes["EventCalendar_where_and"] | undefined | null> | undefined | null,
	OR?: Array<ResolverInputTypes["EventCalendar_where_or"] | undefined | null> | undefined | null
};
	["EventCalendar_date_operator"]: {
	equals?: ResolverInputTypes["DateTime"] | undefined | null,
	not_equals?: ResolverInputTypes["DateTime"] | undefined | null,
	greater_than_equal?: ResolverInputTypes["DateTime"] | undefined | null,
	greater_than?: ResolverInputTypes["DateTime"] | undefined | null,
	less_than_equal?: ResolverInputTypes["DateTime"] | undefined | null,
	less_than?: ResolverInputTypes["DateTime"] | undefined | null,
	like?: ResolverInputTypes["DateTime"] | undefined | null
};
	["EventCalendar_item_operator"]: {
	equals?: ResolverInputTypes["JSON"] | undefined | null,
	not_equals?: ResolverInputTypes["JSON"] | undefined | null,
	in?: Array<ResolverInputTypes["JSON"] | undefined | null> | undefined | null,
	not_in?: Array<ResolverInputTypes["JSON"] | undefined | null> | undefined | null,
	all?: Array<ResolverInputTypes["JSON"] | undefined | null> | undefined | null
};
	["EventCalendar_title_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["EventCalendar_region_operator"]: {
	equals?: ResolverInputTypes["EventCalendar_region_Input"] | undefined | null,
	not_equals?: ResolverInputTypes["EventCalendar_region_Input"] | undefined | null,
	in?: Array<ResolverInputTypes["EventCalendar_region_Input"] | undefined | null> | undefined | null,
	not_in?: Array<ResolverInputTypes["EventCalendar_region_Input"] | undefined | null> | undefined | null,
	all?: Array<ResolverInputTypes["EventCalendar_region_Input"] | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["EventCalendar_region_Input"]:EventCalendar_region_Input;
	["EventCalendar_updatedAt_operator"]: {
	equals?: ResolverInputTypes["DateTime"] | undefined | null,
	not_equals?: ResolverInputTypes["DateTime"] | undefined | null,
	greater_than_equal?: ResolverInputTypes["DateTime"] | undefined | null,
	greater_than?: ResolverInputTypes["DateTime"] | undefined | null,
	less_than_equal?: ResolverInputTypes["DateTime"] | undefined | null,
	less_than?: ResolverInputTypes["DateTime"] | undefined | null,
	like?: ResolverInputTypes["DateTime"] | undefined | null,
	exists?: boolean | undefined | null
};
	["EventCalendar_createdAt_operator"]: {
	equals?: ResolverInputTypes["DateTime"] | undefined | null,
	not_equals?: ResolverInputTypes["DateTime"] | undefined | null,
	greater_than_equal?: ResolverInputTypes["DateTime"] | undefined | null,
	greater_than?: ResolverInputTypes["DateTime"] | undefined | null,
	less_than_equal?: ResolverInputTypes["DateTime"] | undefined | null,
	less_than?: ResolverInputTypes["DateTime"] | undefined | null,
	like?: ResolverInputTypes["DateTime"] | undefined | null,
	exists?: boolean | undefined | null
};
	["EventCalendar_id_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["EventCalendar_where_and"]: {
	date?: ResolverInputTypes["EventCalendar_date_operator"] | undefined | null,
	item?: ResolverInputTypes["EventCalendar_item_operator"] | undefined | null,
	title?: ResolverInputTypes["EventCalendar_title_operator"] | undefined | null,
	region?: ResolverInputTypes["EventCalendar_region_operator"] | undefined | null,
	updatedAt?: ResolverInputTypes["EventCalendar_updatedAt_operator"] | undefined | null,
	createdAt?: ResolverInputTypes["EventCalendar_createdAt_operator"] | undefined | null,
	id?: ResolverInputTypes["EventCalendar_id_operator"] | undefined | null,
	AND?: Array<ResolverInputTypes["EventCalendar_where_and"] | undefined | null> | undefined | null,
	OR?: Array<ResolverInputTypes["EventCalendar_where_or"] | undefined | null> | undefined | null
};
	["EventCalendar_where_or"]: {
	date?: ResolverInputTypes["EventCalendar_date_operator"] | undefined | null,
	item?: ResolverInputTypes["EventCalendar_item_operator"] | undefined | null,
	title?: ResolverInputTypes["EventCalendar_title_operator"] | undefined | null,
	region?: ResolverInputTypes["EventCalendar_region_operator"] | undefined | null,
	updatedAt?: ResolverInputTypes["EventCalendar_updatedAt_operator"] | undefined | null,
	createdAt?: ResolverInputTypes["EventCalendar_createdAt_operator"] | undefined | null,
	id?: ResolverInputTypes["EventCalendar_id_operator"] | undefined | null,
	AND?: Array<ResolverInputTypes["EventCalendar_where_and"] | undefined | null> | undefined | null,
	OR?: Array<ResolverInputTypes["EventCalendar_where_or"] | undefined | null> | undefined | null
};
	["countEventCalendars"]: AliasType<{
	totalDocs?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["event_calendarDocAccess"]: AliasType<{
	fields?:ResolverInputTypes["EventCalendarDocAccessFields"],
	create?:ResolverInputTypes["EventCalendarCreateDocAccess"],
	read?:ResolverInputTypes["EventCalendarReadDocAccess"],
	update?:ResolverInputTypes["EventCalendarUpdateDocAccess"],
	delete?:ResolverInputTypes["EventCalendarDeleteDocAccess"],
		__typename?: boolean | `@${string}`
}>;
	["EventCalendarDocAccessFields"]: AliasType<{
	date?:ResolverInputTypes["EventCalendarDocAccessFields_date"],
	item?:ResolverInputTypes["EventCalendarDocAccessFields_item"],
	title?:ResolverInputTypes["EventCalendarDocAccessFields_title"],
	region?:ResolverInputTypes["EventCalendarDocAccessFields_region"],
	updatedAt?:ResolverInputTypes["EventCalendarDocAccessFields_updatedAt"],
	createdAt?:ResolverInputTypes["EventCalendarDocAccessFields_createdAt"],
		__typename?: boolean | `@${string}`
}>;
	["EventCalendarDocAccessFields_date"]: AliasType<{
	create?:ResolverInputTypes["EventCalendarDocAccessFields_date_Create"],
	read?:ResolverInputTypes["EventCalendarDocAccessFields_date_Read"],
	update?:ResolverInputTypes["EventCalendarDocAccessFields_date_Update"],
	delete?:ResolverInputTypes["EventCalendarDocAccessFields_date_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventCalendarDocAccessFields_date_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventCalendarDocAccessFields_date_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventCalendarDocAccessFields_date_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventCalendarDocAccessFields_date_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventCalendarDocAccessFields_item"]: AliasType<{
	create?:ResolverInputTypes["EventCalendarDocAccessFields_item_Create"],
	read?:ResolverInputTypes["EventCalendarDocAccessFields_item_Read"],
	update?:ResolverInputTypes["EventCalendarDocAccessFields_item_Update"],
	delete?:ResolverInputTypes["EventCalendarDocAccessFields_item_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventCalendarDocAccessFields_item_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventCalendarDocAccessFields_item_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventCalendarDocAccessFields_item_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventCalendarDocAccessFields_item_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventCalendarDocAccessFields_title"]: AliasType<{
	create?:ResolverInputTypes["EventCalendarDocAccessFields_title_Create"],
	read?:ResolverInputTypes["EventCalendarDocAccessFields_title_Read"],
	update?:ResolverInputTypes["EventCalendarDocAccessFields_title_Update"],
	delete?:ResolverInputTypes["EventCalendarDocAccessFields_title_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventCalendarDocAccessFields_title_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventCalendarDocAccessFields_title_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventCalendarDocAccessFields_title_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventCalendarDocAccessFields_title_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventCalendarDocAccessFields_region"]: AliasType<{
	create?:ResolverInputTypes["EventCalendarDocAccessFields_region_Create"],
	read?:ResolverInputTypes["EventCalendarDocAccessFields_region_Read"],
	update?:ResolverInputTypes["EventCalendarDocAccessFields_region_Update"],
	delete?:ResolverInputTypes["EventCalendarDocAccessFields_region_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventCalendarDocAccessFields_region_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventCalendarDocAccessFields_region_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventCalendarDocAccessFields_region_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventCalendarDocAccessFields_region_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventCalendarDocAccessFields_updatedAt"]: AliasType<{
	create?:ResolverInputTypes["EventCalendarDocAccessFields_updatedAt_Create"],
	read?:ResolverInputTypes["EventCalendarDocAccessFields_updatedAt_Read"],
	update?:ResolverInputTypes["EventCalendarDocAccessFields_updatedAt_Update"],
	delete?:ResolverInputTypes["EventCalendarDocAccessFields_updatedAt_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventCalendarDocAccessFields_updatedAt_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventCalendarDocAccessFields_updatedAt_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventCalendarDocAccessFields_updatedAt_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventCalendarDocAccessFields_updatedAt_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventCalendarDocAccessFields_createdAt"]: AliasType<{
	create?:ResolverInputTypes["EventCalendarDocAccessFields_createdAt_Create"],
	read?:ResolverInputTypes["EventCalendarDocAccessFields_createdAt_Read"],
	update?:ResolverInputTypes["EventCalendarDocAccessFields_createdAt_Update"],
	delete?:ResolverInputTypes["EventCalendarDocAccessFields_createdAt_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventCalendarDocAccessFields_createdAt_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventCalendarDocAccessFields_createdAt_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventCalendarDocAccessFields_createdAt_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventCalendarDocAccessFields_createdAt_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventCalendarCreateDocAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventCalendarReadDocAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventCalendarUpdateDocAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventCalendarDeleteDocAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EditorChoice"]: AliasType<{
	id?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	visible?:boolean | `@${string}`,
editorialPicks?: [{	locale?: ResolverInputTypes["LocaleInputType"] | undefined | null,	fallbackLocale?: ResolverInputTypes["FallbackLocaleInputType"] | undefined | null},ResolverInputTypes["Event"]],
	list?:ResolverInputTypes["EditorChoice_List"],
	region?:boolean | `@${string}`,
	updatedAt?:boolean | `@${string}`,
	createdAt?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EditorChoice_List"]: AliasType<{
item?: [{	locale?: ResolverInputTypes["LocaleInputType"] | undefined | null,	fallbackLocale?: ResolverInputTypes["FallbackLocaleInputType"] | undefined | null},ResolverInputTypes["Event"]],
	id?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EditorChoice_region"]:EditorChoice_region;
	["EditorChoices"]: AliasType<{
	docs?:ResolverInputTypes["EditorChoice"],
	hasNextPage?:boolean | `@${string}`,
	hasPrevPage?:boolean | `@${string}`,
	limit?:boolean | `@${string}`,
	nextPage?:boolean | `@${string}`,
	offset?:boolean | `@${string}`,
	page?:boolean | `@${string}`,
	pagingCounter?:boolean | `@${string}`,
	prevPage?:boolean | `@${string}`,
	totalDocs?:boolean | `@${string}`,
	totalPages?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EditorChoice_where"]: {
	name?: ResolverInputTypes["EditorChoice_name_operator"] | undefined | null,
	visible?: ResolverInputTypes["EditorChoice_visible_operator"] | undefined | null,
	editorialPicks?: ResolverInputTypes["EditorChoice_editorialPicks_operator"] | undefined | null,
	list__item?: ResolverInputTypes["EditorChoice_list__item_operator"] | undefined | null,
	list__id?: ResolverInputTypes["EditorChoice_list__id_operator"] | undefined | null,
	region?: ResolverInputTypes["EditorChoice_region_operator"] | undefined | null,
	updatedAt?: ResolverInputTypes["EditorChoice_updatedAt_operator"] | undefined | null,
	createdAt?: ResolverInputTypes["EditorChoice_createdAt_operator"] | undefined | null,
	id?: ResolverInputTypes["EditorChoice_id_operator"] | undefined | null,
	AND?: Array<ResolverInputTypes["EditorChoice_where_and"] | undefined | null> | undefined | null,
	OR?: Array<ResolverInputTypes["EditorChoice_where_or"] | undefined | null> | undefined | null
};
	["EditorChoice_name_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null
};
	["EditorChoice_visible_operator"]: {
	equals?: boolean | undefined | null,
	not_equals?: boolean | undefined | null,
	exists?: boolean | undefined | null
};
	["EditorChoice_editorialPicks_operator"]: {
	equals?: ResolverInputTypes["JSON"] | undefined | null,
	not_equals?: ResolverInputTypes["JSON"] | undefined | null,
	in?: Array<ResolverInputTypes["JSON"] | undefined | null> | undefined | null,
	not_in?: Array<ResolverInputTypes["JSON"] | undefined | null> | undefined | null,
	all?: Array<ResolverInputTypes["JSON"] | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["EditorChoice_list__item_operator"]: {
	equals?: ResolverInputTypes["JSON"] | undefined | null,
	not_equals?: ResolverInputTypes["JSON"] | undefined | null,
	in?: Array<ResolverInputTypes["JSON"] | undefined | null> | undefined | null,
	not_in?: Array<ResolverInputTypes["JSON"] | undefined | null> | undefined | null,
	all?: Array<ResolverInputTypes["JSON"] | undefined | null> | undefined | null
};
	["EditorChoice_list__id_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["EditorChoice_region_operator"]: {
	equals?: ResolverInputTypes["EditorChoice_region_Input"] | undefined | null,
	not_equals?: ResolverInputTypes["EditorChoice_region_Input"] | undefined | null,
	in?: Array<ResolverInputTypes["EditorChoice_region_Input"] | undefined | null> | undefined | null,
	not_in?: Array<ResolverInputTypes["EditorChoice_region_Input"] | undefined | null> | undefined | null,
	all?: Array<ResolverInputTypes["EditorChoice_region_Input"] | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["EditorChoice_region_Input"]:EditorChoice_region_Input;
	["EditorChoice_updatedAt_operator"]: {
	equals?: ResolverInputTypes["DateTime"] | undefined | null,
	not_equals?: ResolverInputTypes["DateTime"] | undefined | null,
	greater_than_equal?: ResolverInputTypes["DateTime"] | undefined | null,
	greater_than?: ResolverInputTypes["DateTime"] | undefined | null,
	less_than_equal?: ResolverInputTypes["DateTime"] | undefined | null,
	less_than?: ResolverInputTypes["DateTime"] | undefined | null,
	like?: ResolverInputTypes["DateTime"] | undefined | null,
	exists?: boolean | undefined | null
};
	["EditorChoice_createdAt_operator"]: {
	equals?: ResolverInputTypes["DateTime"] | undefined | null,
	not_equals?: ResolverInputTypes["DateTime"] | undefined | null,
	greater_than_equal?: ResolverInputTypes["DateTime"] | undefined | null,
	greater_than?: ResolverInputTypes["DateTime"] | undefined | null,
	less_than_equal?: ResolverInputTypes["DateTime"] | undefined | null,
	less_than?: ResolverInputTypes["DateTime"] | undefined | null,
	like?: ResolverInputTypes["DateTime"] | undefined | null,
	exists?: boolean | undefined | null
};
	["EditorChoice_id_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["EditorChoice_where_and"]: {
	name?: ResolverInputTypes["EditorChoice_name_operator"] | undefined | null,
	visible?: ResolverInputTypes["EditorChoice_visible_operator"] | undefined | null,
	editorialPicks?: ResolverInputTypes["EditorChoice_editorialPicks_operator"] | undefined | null,
	list__item?: ResolverInputTypes["EditorChoice_list__item_operator"] | undefined | null,
	list__id?: ResolverInputTypes["EditorChoice_list__id_operator"] | undefined | null,
	region?: ResolverInputTypes["EditorChoice_region_operator"] | undefined | null,
	updatedAt?: ResolverInputTypes["EditorChoice_updatedAt_operator"] | undefined | null,
	createdAt?: ResolverInputTypes["EditorChoice_createdAt_operator"] | undefined | null,
	id?: ResolverInputTypes["EditorChoice_id_operator"] | undefined | null,
	AND?: Array<ResolverInputTypes["EditorChoice_where_and"] | undefined | null> | undefined | null,
	OR?: Array<ResolverInputTypes["EditorChoice_where_or"] | undefined | null> | undefined | null
};
	["EditorChoice_where_or"]: {
	name?: ResolverInputTypes["EditorChoice_name_operator"] | undefined | null,
	visible?: ResolverInputTypes["EditorChoice_visible_operator"] | undefined | null,
	editorialPicks?: ResolverInputTypes["EditorChoice_editorialPicks_operator"] | undefined | null,
	list__item?: ResolverInputTypes["EditorChoice_list__item_operator"] | undefined | null,
	list__id?: ResolverInputTypes["EditorChoice_list__id_operator"] | undefined | null,
	region?: ResolverInputTypes["EditorChoice_region_operator"] | undefined | null,
	updatedAt?: ResolverInputTypes["EditorChoice_updatedAt_operator"] | undefined | null,
	createdAt?: ResolverInputTypes["EditorChoice_createdAt_operator"] | undefined | null,
	id?: ResolverInputTypes["EditorChoice_id_operator"] | undefined | null,
	AND?: Array<ResolverInputTypes["EditorChoice_where_and"] | undefined | null> | undefined | null,
	OR?: Array<ResolverInputTypes["EditorChoice_where_or"] | undefined | null> | undefined | null
};
	["countEditorChoices"]: AliasType<{
	totalDocs?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["editor_choicesDocAccess"]: AliasType<{
	fields?:ResolverInputTypes["EditorChoicesDocAccessFields"],
	create?:ResolverInputTypes["EditorChoicesCreateDocAccess"],
	read?:ResolverInputTypes["EditorChoicesReadDocAccess"],
	update?:ResolverInputTypes["EditorChoicesUpdateDocAccess"],
	delete?:ResolverInputTypes["EditorChoicesDeleteDocAccess"],
		__typename?: boolean | `@${string}`
}>;
	["EditorChoicesDocAccessFields"]: AliasType<{
	name?:ResolverInputTypes["EditorChoicesDocAccessFields_name"],
	visible?:ResolverInputTypes["EditorChoicesDocAccessFields_visible"],
	editorialPicks?:ResolverInputTypes["EditorChoicesDocAccessFields_editorialPicks"],
	list?:ResolverInputTypes["EditorChoicesDocAccessFields_list"],
	region?:ResolverInputTypes["EditorChoicesDocAccessFields_region"],
	updatedAt?:ResolverInputTypes["EditorChoicesDocAccessFields_updatedAt"],
	createdAt?:ResolverInputTypes["EditorChoicesDocAccessFields_createdAt"],
		__typename?: boolean | `@${string}`
}>;
	["EditorChoicesDocAccessFields_name"]: AliasType<{
	create?:ResolverInputTypes["EditorChoicesDocAccessFields_name_Create"],
	read?:ResolverInputTypes["EditorChoicesDocAccessFields_name_Read"],
	update?:ResolverInputTypes["EditorChoicesDocAccessFields_name_Update"],
	delete?:ResolverInputTypes["EditorChoicesDocAccessFields_name_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EditorChoicesDocAccessFields_name_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EditorChoicesDocAccessFields_name_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EditorChoicesDocAccessFields_name_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EditorChoicesDocAccessFields_name_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EditorChoicesDocAccessFields_visible"]: AliasType<{
	create?:ResolverInputTypes["EditorChoicesDocAccessFields_visible_Create"],
	read?:ResolverInputTypes["EditorChoicesDocAccessFields_visible_Read"],
	update?:ResolverInputTypes["EditorChoicesDocAccessFields_visible_Update"],
	delete?:ResolverInputTypes["EditorChoicesDocAccessFields_visible_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EditorChoicesDocAccessFields_visible_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EditorChoicesDocAccessFields_visible_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EditorChoicesDocAccessFields_visible_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EditorChoicesDocAccessFields_visible_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EditorChoicesDocAccessFields_editorialPicks"]: AliasType<{
	create?:ResolverInputTypes["EditorChoicesDocAccessFields_editorialPicks_Create"],
	read?:ResolverInputTypes["EditorChoicesDocAccessFields_editorialPicks_Read"],
	update?:ResolverInputTypes["EditorChoicesDocAccessFields_editorialPicks_Update"],
	delete?:ResolverInputTypes["EditorChoicesDocAccessFields_editorialPicks_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EditorChoicesDocAccessFields_editorialPicks_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EditorChoicesDocAccessFields_editorialPicks_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EditorChoicesDocAccessFields_editorialPicks_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EditorChoicesDocAccessFields_editorialPicks_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EditorChoicesDocAccessFields_list"]: AliasType<{
	create?:ResolverInputTypes["EditorChoicesDocAccessFields_list_Create"],
	read?:ResolverInputTypes["EditorChoicesDocAccessFields_list_Read"],
	update?:ResolverInputTypes["EditorChoicesDocAccessFields_list_Update"],
	delete?:ResolverInputTypes["EditorChoicesDocAccessFields_list_Delete"],
	fields?:ResolverInputTypes["EditorChoicesDocAccessFields_list_Fields"],
		__typename?: boolean | `@${string}`
}>;
	["EditorChoicesDocAccessFields_list_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EditorChoicesDocAccessFields_list_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EditorChoicesDocAccessFields_list_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EditorChoicesDocAccessFields_list_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EditorChoicesDocAccessFields_list_Fields"]: AliasType<{
	item?:ResolverInputTypes["EditorChoicesDocAccessFields_list_item"],
	id?:ResolverInputTypes["EditorChoicesDocAccessFields_list_id"],
		__typename?: boolean | `@${string}`
}>;
	["EditorChoicesDocAccessFields_list_item"]: AliasType<{
	create?:ResolverInputTypes["EditorChoicesDocAccessFields_list_item_Create"],
	read?:ResolverInputTypes["EditorChoicesDocAccessFields_list_item_Read"],
	update?:ResolverInputTypes["EditorChoicesDocAccessFields_list_item_Update"],
	delete?:ResolverInputTypes["EditorChoicesDocAccessFields_list_item_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EditorChoicesDocAccessFields_list_item_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EditorChoicesDocAccessFields_list_item_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EditorChoicesDocAccessFields_list_item_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EditorChoicesDocAccessFields_list_item_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EditorChoicesDocAccessFields_list_id"]: AliasType<{
	create?:ResolverInputTypes["EditorChoicesDocAccessFields_list_id_Create"],
	read?:ResolverInputTypes["EditorChoicesDocAccessFields_list_id_Read"],
	update?:ResolverInputTypes["EditorChoicesDocAccessFields_list_id_Update"],
	delete?:ResolverInputTypes["EditorChoicesDocAccessFields_list_id_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EditorChoicesDocAccessFields_list_id_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EditorChoicesDocAccessFields_list_id_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EditorChoicesDocAccessFields_list_id_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EditorChoicesDocAccessFields_list_id_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EditorChoicesDocAccessFields_region"]: AliasType<{
	create?:ResolverInputTypes["EditorChoicesDocAccessFields_region_Create"],
	read?:ResolverInputTypes["EditorChoicesDocAccessFields_region_Read"],
	update?:ResolverInputTypes["EditorChoicesDocAccessFields_region_Update"],
	delete?:ResolverInputTypes["EditorChoicesDocAccessFields_region_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EditorChoicesDocAccessFields_region_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EditorChoicesDocAccessFields_region_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EditorChoicesDocAccessFields_region_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EditorChoicesDocAccessFields_region_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EditorChoicesDocAccessFields_updatedAt"]: AliasType<{
	create?:ResolverInputTypes["EditorChoicesDocAccessFields_updatedAt_Create"],
	read?:ResolverInputTypes["EditorChoicesDocAccessFields_updatedAt_Read"],
	update?:ResolverInputTypes["EditorChoicesDocAccessFields_updatedAt_Update"],
	delete?:ResolverInputTypes["EditorChoicesDocAccessFields_updatedAt_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EditorChoicesDocAccessFields_updatedAt_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EditorChoicesDocAccessFields_updatedAt_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EditorChoicesDocAccessFields_updatedAt_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EditorChoicesDocAccessFields_updatedAt_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EditorChoicesDocAccessFields_createdAt"]: AliasType<{
	create?:ResolverInputTypes["EditorChoicesDocAccessFields_createdAt_Create"],
	read?:ResolverInputTypes["EditorChoicesDocAccessFields_createdAt_Read"],
	update?:ResolverInputTypes["EditorChoicesDocAccessFields_createdAt_Update"],
	delete?:ResolverInputTypes["EditorChoicesDocAccessFields_createdAt_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EditorChoicesDocAccessFields_createdAt_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EditorChoicesDocAccessFields_createdAt_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EditorChoicesDocAccessFields_createdAt_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EditorChoicesDocAccessFields_createdAt_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EditorChoicesCreateDocAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EditorChoicesReadDocAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EditorChoicesUpdateDocAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EditorChoicesDeleteDocAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CriteriaSetting"]: AliasType<{
	id?:boolean | `@${string}`,
	criteria?:ResolverInputTypes["CriteriaSetting_Criteria"],
	updatedAt?:boolean | `@${string}`,
	createdAt?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CriteriaSetting_Criteria"]: AliasType<{
date?: [{	locale?: ResolverInputTypes["LocaleInputType"] | undefined | null,	fallbackLocale?: ResolverInputTypes["FallbackLocaleInputType"] | undefined | null},ResolverInputTypes["Date"]],
audience?: [{	locale?: ResolverInputTypes["LocaleInputType"] | undefined | null,	fallbackLocale?: ResolverInputTypes["FallbackLocaleInputType"] | undefined | null},ResolverInputTypes["Audience"]],
category?: [{	locale?: ResolverInputTypes["LocaleInputType"] | undefined | null,	fallbackLocale?: ResolverInputTypes["FallbackLocaleInputType"] | undefined | null},ResolverInputTypes["EventCategory"]],
tag?: [{	locale?: ResolverInputTypes["LocaleInputType"] | undefined | null,	fallbackLocale?: ResolverInputTypes["FallbackLocaleInputType"] | undefined | null},ResolverInputTypes["Tag"]],
	region?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CriteriaSetting_Criteria_region"]:CriteriaSetting_Criteria_region;
	["CriteriaSettings"]: AliasType<{
	docs?:ResolverInputTypes["CriteriaSetting"],
	hasNextPage?:boolean | `@${string}`,
	hasPrevPage?:boolean | `@${string}`,
	limit?:boolean | `@${string}`,
	nextPage?:boolean | `@${string}`,
	offset?:boolean | `@${string}`,
	page?:boolean | `@${string}`,
	pagingCounter?:boolean | `@${string}`,
	prevPage?:boolean | `@${string}`,
	totalDocs?:boolean | `@${string}`,
	totalPages?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CriteriaSetting_where"]: {
	criteria__date?: ResolverInputTypes["CriteriaSetting_criteria__date_operator"] | undefined | null,
	criteria__audience?: ResolverInputTypes["CriteriaSetting_criteria__audience_operator"] | undefined | null,
	criteria__category?: ResolverInputTypes["CriteriaSetting_criteria__category_operator"] | undefined | null,
	criteria__tag?: ResolverInputTypes["CriteriaSetting_criteria__tag_operator"] | undefined | null,
	criteria__region?: ResolverInputTypes["CriteriaSetting_criteria__region_operator"] | undefined | null,
	updatedAt?: ResolverInputTypes["CriteriaSetting_updatedAt_operator"] | undefined | null,
	createdAt?: ResolverInputTypes["CriteriaSetting_createdAt_operator"] | undefined | null,
	id?: ResolverInputTypes["CriteriaSetting_id_operator"] | undefined | null,
	AND?: Array<ResolverInputTypes["CriteriaSetting_where_and"] | undefined | null> | undefined | null,
	OR?: Array<ResolverInputTypes["CriteriaSetting_where_or"] | undefined | null> | undefined | null
};
	["CriteriaSetting_criteria__date_operator"]: {
	equals?: ResolverInputTypes["JSON"] | undefined | null,
	not_equals?: ResolverInputTypes["JSON"] | undefined | null,
	in?: Array<ResolverInputTypes["JSON"] | undefined | null> | undefined | null,
	not_in?: Array<ResolverInputTypes["JSON"] | undefined | null> | undefined | null,
	all?: Array<ResolverInputTypes["JSON"] | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["CriteriaSetting_criteria__audience_operator"]: {
	equals?: ResolverInputTypes["JSON"] | undefined | null,
	not_equals?: ResolverInputTypes["JSON"] | undefined | null,
	in?: Array<ResolverInputTypes["JSON"] | undefined | null> | undefined | null,
	not_in?: Array<ResolverInputTypes["JSON"] | undefined | null> | undefined | null,
	all?: Array<ResolverInputTypes["JSON"] | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["CriteriaSetting_criteria__category_operator"]: {
	equals?: ResolverInputTypes["JSON"] | undefined | null,
	not_equals?: ResolverInputTypes["JSON"] | undefined | null,
	in?: Array<ResolverInputTypes["JSON"] | undefined | null> | undefined | null,
	not_in?: Array<ResolverInputTypes["JSON"] | undefined | null> | undefined | null,
	all?: Array<ResolverInputTypes["JSON"] | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["CriteriaSetting_criteria__tag_operator"]: {
	equals?: ResolverInputTypes["JSON"] | undefined | null,
	not_equals?: ResolverInputTypes["JSON"] | undefined | null,
	in?: Array<ResolverInputTypes["JSON"] | undefined | null> | undefined | null,
	not_in?: Array<ResolverInputTypes["JSON"] | undefined | null> | undefined | null,
	all?: Array<ResolverInputTypes["JSON"] | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["CriteriaSetting_criteria__region_operator"]: {
	equals?: ResolverInputTypes["CriteriaSetting_criteria__region_Input"] | undefined | null,
	not_equals?: ResolverInputTypes["CriteriaSetting_criteria__region_Input"] | undefined | null,
	in?: Array<ResolverInputTypes["CriteriaSetting_criteria__region_Input"] | undefined | null> | undefined | null,
	not_in?: Array<ResolverInputTypes["CriteriaSetting_criteria__region_Input"] | undefined | null> | undefined | null,
	all?: Array<ResolverInputTypes["CriteriaSetting_criteria__region_Input"] | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["CriteriaSetting_criteria__region_Input"]:CriteriaSetting_criteria__region_Input;
	["CriteriaSetting_updatedAt_operator"]: {
	equals?: ResolverInputTypes["DateTime"] | undefined | null,
	not_equals?: ResolverInputTypes["DateTime"] | undefined | null,
	greater_than_equal?: ResolverInputTypes["DateTime"] | undefined | null,
	greater_than?: ResolverInputTypes["DateTime"] | undefined | null,
	less_than_equal?: ResolverInputTypes["DateTime"] | undefined | null,
	less_than?: ResolverInputTypes["DateTime"] | undefined | null,
	like?: ResolverInputTypes["DateTime"] | undefined | null,
	exists?: boolean | undefined | null
};
	["CriteriaSetting_createdAt_operator"]: {
	equals?: ResolverInputTypes["DateTime"] | undefined | null,
	not_equals?: ResolverInputTypes["DateTime"] | undefined | null,
	greater_than_equal?: ResolverInputTypes["DateTime"] | undefined | null,
	greater_than?: ResolverInputTypes["DateTime"] | undefined | null,
	less_than_equal?: ResolverInputTypes["DateTime"] | undefined | null,
	less_than?: ResolverInputTypes["DateTime"] | undefined | null,
	like?: ResolverInputTypes["DateTime"] | undefined | null,
	exists?: boolean | undefined | null
};
	["CriteriaSetting_id_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["CriteriaSetting_where_and"]: {
	criteria__date?: ResolverInputTypes["CriteriaSetting_criteria__date_operator"] | undefined | null,
	criteria__audience?: ResolverInputTypes["CriteriaSetting_criteria__audience_operator"] | undefined | null,
	criteria__category?: ResolverInputTypes["CriteriaSetting_criteria__category_operator"] | undefined | null,
	criteria__tag?: ResolverInputTypes["CriteriaSetting_criteria__tag_operator"] | undefined | null,
	criteria__region?: ResolverInputTypes["CriteriaSetting_criteria__region_operator"] | undefined | null,
	updatedAt?: ResolverInputTypes["CriteriaSetting_updatedAt_operator"] | undefined | null,
	createdAt?: ResolverInputTypes["CriteriaSetting_createdAt_operator"] | undefined | null,
	id?: ResolverInputTypes["CriteriaSetting_id_operator"] | undefined | null,
	AND?: Array<ResolverInputTypes["CriteriaSetting_where_and"] | undefined | null> | undefined | null,
	OR?: Array<ResolverInputTypes["CriteriaSetting_where_or"] | undefined | null> | undefined | null
};
	["CriteriaSetting_where_or"]: {
	criteria__date?: ResolverInputTypes["CriteriaSetting_criteria__date_operator"] | undefined | null,
	criteria__audience?: ResolverInputTypes["CriteriaSetting_criteria__audience_operator"] | undefined | null,
	criteria__category?: ResolverInputTypes["CriteriaSetting_criteria__category_operator"] | undefined | null,
	criteria__tag?: ResolverInputTypes["CriteriaSetting_criteria__tag_operator"] | undefined | null,
	criteria__region?: ResolverInputTypes["CriteriaSetting_criteria__region_operator"] | undefined | null,
	updatedAt?: ResolverInputTypes["CriteriaSetting_updatedAt_operator"] | undefined | null,
	createdAt?: ResolverInputTypes["CriteriaSetting_createdAt_operator"] | undefined | null,
	id?: ResolverInputTypes["CriteriaSetting_id_operator"] | undefined | null,
	AND?: Array<ResolverInputTypes["CriteriaSetting_where_and"] | undefined | null> | undefined | null,
	OR?: Array<ResolverInputTypes["CriteriaSetting_where_or"] | undefined | null> | undefined | null
};
	["countCriteriaSettings"]: AliasType<{
	totalDocs?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["criteria_settingDocAccess"]: AliasType<{
	fields?:ResolverInputTypes["CriteriaSettingDocAccessFields"],
	create?:ResolverInputTypes["CriteriaSettingCreateDocAccess"],
	read?:ResolverInputTypes["CriteriaSettingReadDocAccess"],
	update?:ResolverInputTypes["CriteriaSettingUpdateDocAccess"],
	delete?:ResolverInputTypes["CriteriaSettingDeleteDocAccess"],
		__typename?: boolean | `@${string}`
}>;
	["CriteriaSettingDocAccessFields"]: AliasType<{
	criteria?:ResolverInputTypes["CriteriaSettingDocAccessFields_criteria"],
	updatedAt?:ResolverInputTypes["CriteriaSettingDocAccessFields_updatedAt"],
	createdAt?:ResolverInputTypes["CriteriaSettingDocAccessFields_createdAt"],
		__typename?: boolean | `@${string}`
}>;
	["CriteriaSettingDocAccessFields_criteria"]: AliasType<{
	create?:ResolverInputTypes["CriteriaSettingDocAccessFields_criteria_Create"],
	read?:ResolverInputTypes["CriteriaSettingDocAccessFields_criteria_Read"],
	update?:ResolverInputTypes["CriteriaSettingDocAccessFields_criteria_Update"],
	delete?:ResolverInputTypes["CriteriaSettingDocAccessFields_criteria_Delete"],
	fields?:ResolverInputTypes["CriteriaSettingDocAccessFields_criteria_Fields"],
		__typename?: boolean | `@${string}`
}>;
	["CriteriaSettingDocAccessFields_criteria_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CriteriaSettingDocAccessFields_criteria_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CriteriaSettingDocAccessFields_criteria_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CriteriaSettingDocAccessFields_criteria_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CriteriaSettingDocAccessFields_criteria_Fields"]: AliasType<{
	date?:ResolverInputTypes["CriteriaSettingDocAccessFields_criteria_date"],
	audience?:ResolverInputTypes["CriteriaSettingDocAccessFields_criteria_audience"],
	category?:ResolverInputTypes["CriteriaSettingDocAccessFields_criteria_category"],
	tag?:ResolverInputTypes["CriteriaSettingDocAccessFields_criteria_tag"],
	region?:ResolverInputTypes["CriteriaSettingDocAccessFields_criteria_region"],
		__typename?: boolean | `@${string}`
}>;
	["CriteriaSettingDocAccessFields_criteria_date"]: AliasType<{
	create?:ResolverInputTypes["CriteriaSettingDocAccessFields_criteria_date_Create"],
	read?:ResolverInputTypes["CriteriaSettingDocAccessFields_criteria_date_Read"],
	update?:ResolverInputTypes["CriteriaSettingDocAccessFields_criteria_date_Update"],
	delete?:ResolverInputTypes["CriteriaSettingDocAccessFields_criteria_date_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["CriteriaSettingDocAccessFields_criteria_date_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CriteriaSettingDocAccessFields_criteria_date_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CriteriaSettingDocAccessFields_criteria_date_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CriteriaSettingDocAccessFields_criteria_date_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CriteriaSettingDocAccessFields_criteria_audience"]: AliasType<{
	create?:ResolverInputTypes["CriteriaSettingDocAccessFields_criteria_audience_Create"],
	read?:ResolverInputTypes["CriteriaSettingDocAccessFields_criteria_audience_Read"],
	update?:ResolverInputTypes["CriteriaSettingDocAccessFields_criteria_audience_Update"],
	delete?:ResolverInputTypes["CriteriaSettingDocAccessFields_criteria_audience_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["CriteriaSettingDocAccessFields_criteria_audience_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CriteriaSettingDocAccessFields_criteria_audience_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CriteriaSettingDocAccessFields_criteria_audience_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CriteriaSettingDocAccessFields_criteria_audience_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CriteriaSettingDocAccessFields_criteria_category"]: AliasType<{
	create?:ResolverInputTypes["CriteriaSettingDocAccessFields_criteria_category_Create"],
	read?:ResolverInputTypes["CriteriaSettingDocAccessFields_criteria_category_Read"],
	update?:ResolverInputTypes["CriteriaSettingDocAccessFields_criteria_category_Update"],
	delete?:ResolverInputTypes["CriteriaSettingDocAccessFields_criteria_category_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["CriteriaSettingDocAccessFields_criteria_category_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CriteriaSettingDocAccessFields_criteria_category_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CriteriaSettingDocAccessFields_criteria_category_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CriteriaSettingDocAccessFields_criteria_category_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CriteriaSettingDocAccessFields_criteria_tag"]: AliasType<{
	create?:ResolverInputTypes["CriteriaSettingDocAccessFields_criteria_tag_Create"],
	read?:ResolverInputTypes["CriteriaSettingDocAccessFields_criteria_tag_Read"],
	update?:ResolverInputTypes["CriteriaSettingDocAccessFields_criteria_tag_Update"],
	delete?:ResolverInputTypes["CriteriaSettingDocAccessFields_criteria_tag_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["CriteriaSettingDocAccessFields_criteria_tag_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CriteriaSettingDocAccessFields_criteria_tag_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CriteriaSettingDocAccessFields_criteria_tag_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CriteriaSettingDocAccessFields_criteria_tag_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CriteriaSettingDocAccessFields_criteria_region"]: AliasType<{
	create?:ResolverInputTypes["CriteriaSettingDocAccessFields_criteria_region_Create"],
	read?:ResolverInputTypes["CriteriaSettingDocAccessFields_criteria_region_Read"],
	update?:ResolverInputTypes["CriteriaSettingDocAccessFields_criteria_region_Update"],
	delete?:ResolverInputTypes["CriteriaSettingDocAccessFields_criteria_region_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["CriteriaSettingDocAccessFields_criteria_region_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CriteriaSettingDocAccessFields_criteria_region_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CriteriaSettingDocAccessFields_criteria_region_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CriteriaSettingDocAccessFields_criteria_region_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CriteriaSettingDocAccessFields_updatedAt"]: AliasType<{
	create?:ResolverInputTypes["CriteriaSettingDocAccessFields_updatedAt_Create"],
	read?:ResolverInputTypes["CriteriaSettingDocAccessFields_updatedAt_Read"],
	update?:ResolverInputTypes["CriteriaSettingDocAccessFields_updatedAt_Update"],
	delete?:ResolverInputTypes["CriteriaSettingDocAccessFields_updatedAt_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["CriteriaSettingDocAccessFields_updatedAt_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CriteriaSettingDocAccessFields_updatedAt_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CriteriaSettingDocAccessFields_updatedAt_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CriteriaSettingDocAccessFields_updatedAt_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CriteriaSettingDocAccessFields_createdAt"]: AliasType<{
	create?:ResolverInputTypes["CriteriaSettingDocAccessFields_createdAt_Create"],
	read?:ResolverInputTypes["CriteriaSettingDocAccessFields_createdAt_Read"],
	update?:ResolverInputTypes["CriteriaSettingDocAccessFields_createdAt_Update"],
	delete?:ResolverInputTypes["CriteriaSettingDocAccessFields_createdAt_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["CriteriaSettingDocAccessFields_createdAt_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CriteriaSettingDocAccessFields_createdAt_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CriteriaSettingDocAccessFields_createdAt_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CriteriaSettingDocAccessFields_createdAt_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CriteriaSettingCreateDocAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CriteriaSettingReadDocAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CriteriaSettingUpdateDocAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CriteriaSettingDeleteDocAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["OrganiserSetting"]: AliasType<{
	id?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
organiserList?: [{	locale?: ResolverInputTypes["LocaleInputType"] | undefined | null,	fallbackLocale?: ResolverInputTypes["FallbackLocaleInputType"] | undefined | null},ResolverInputTypes["User"]],
	region?:boolean | `@${string}`,
	updatedAt?:boolean | `@${string}`,
	createdAt?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["OrganiserSetting_region"]:OrganiserSetting_region;
	["OrganiserSettings"]: AliasType<{
	docs?:ResolverInputTypes["OrganiserSetting"],
	hasNextPage?:boolean | `@${string}`,
	hasPrevPage?:boolean | `@${string}`,
	limit?:boolean | `@${string}`,
	nextPage?:boolean | `@${string}`,
	offset?:boolean | `@${string}`,
	page?:boolean | `@${string}`,
	pagingCounter?:boolean | `@${string}`,
	prevPage?:boolean | `@${string}`,
	totalDocs?:boolean | `@${string}`,
	totalPages?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["OrganiserSetting_where"]: {
	name?: ResolverInputTypes["OrganiserSetting_name_operator"] | undefined | null,
	organiserList?: ResolverInputTypes["OrganiserSetting_organiserList_operator"] | undefined | null,
	region?: ResolverInputTypes["OrganiserSetting_region_operator"] | undefined | null,
	updatedAt?: ResolverInputTypes["OrganiserSetting_updatedAt_operator"] | undefined | null,
	createdAt?: ResolverInputTypes["OrganiserSetting_createdAt_operator"] | undefined | null,
	id?: ResolverInputTypes["OrganiserSetting_id_operator"] | undefined | null,
	AND?: Array<ResolverInputTypes["OrganiserSetting_where_and"] | undefined | null> | undefined | null,
	OR?: Array<ResolverInputTypes["OrganiserSetting_where_or"] | undefined | null> | undefined | null
};
	["OrganiserSetting_name_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["OrganiserSetting_organiserList_operator"]: {
	equals?: ResolverInputTypes["JSON"] | undefined | null,
	not_equals?: ResolverInputTypes["JSON"] | undefined | null,
	in?: Array<ResolverInputTypes["JSON"] | undefined | null> | undefined | null,
	not_in?: Array<ResolverInputTypes["JSON"] | undefined | null> | undefined | null,
	all?: Array<ResolverInputTypes["JSON"] | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["OrganiserSetting_region_operator"]: {
	equals?: ResolverInputTypes["OrganiserSetting_region_Input"] | undefined | null,
	not_equals?: ResolverInputTypes["OrganiserSetting_region_Input"] | undefined | null,
	in?: Array<ResolverInputTypes["OrganiserSetting_region_Input"] | undefined | null> | undefined | null,
	not_in?: Array<ResolverInputTypes["OrganiserSetting_region_Input"] | undefined | null> | undefined | null,
	all?: Array<ResolverInputTypes["OrganiserSetting_region_Input"] | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["OrganiserSetting_region_Input"]:OrganiserSetting_region_Input;
	["OrganiserSetting_updatedAt_operator"]: {
	equals?: ResolverInputTypes["DateTime"] | undefined | null,
	not_equals?: ResolverInputTypes["DateTime"] | undefined | null,
	greater_than_equal?: ResolverInputTypes["DateTime"] | undefined | null,
	greater_than?: ResolverInputTypes["DateTime"] | undefined | null,
	less_than_equal?: ResolverInputTypes["DateTime"] | undefined | null,
	less_than?: ResolverInputTypes["DateTime"] | undefined | null,
	like?: ResolverInputTypes["DateTime"] | undefined | null,
	exists?: boolean | undefined | null
};
	["OrganiserSetting_createdAt_operator"]: {
	equals?: ResolverInputTypes["DateTime"] | undefined | null,
	not_equals?: ResolverInputTypes["DateTime"] | undefined | null,
	greater_than_equal?: ResolverInputTypes["DateTime"] | undefined | null,
	greater_than?: ResolverInputTypes["DateTime"] | undefined | null,
	less_than_equal?: ResolverInputTypes["DateTime"] | undefined | null,
	less_than?: ResolverInputTypes["DateTime"] | undefined | null,
	like?: ResolverInputTypes["DateTime"] | undefined | null,
	exists?: boolean | undefined | null
};
	["OrganiserSetting_id_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["OrganiserSetting_where_and"]: {
	name?: ResolverInputTypes["OrganiserSetting_name_operator"] | undefined | null,
	organiserList?: ResolverInputTypes["OrganiserSetting_organiserList_operator"] | undefined | null,
	region?: ResolverInputTypes["OrganiserSetting_region_operator"] | undefined | null,
	updatedAt?: ResolverInputTypes["OrganiserSetting_updatedAt_operator"] | undefined | null,
	createdAt?: ResolverInputTypes["OrganiserSetting_createdAt_operator"] | undefined | null,
	id?: ResolverInputTypes["OrganiserSetting_id_operator"] | undefined | null,
	AND?: Array<ResolverInputTypes["OrganiserSetting_where_and"] | undefined | null> | undefined | null,
	OR?: Array<ResolverInputTypes["OrganiserSetting_where_or"] | undefined | null> | undefined | null
};
	["OrganiserSetting_where_or"]: {
	name?: ResolverInputTypes["OrganiserSetting_name_operator"] | undefined | null,
	organiserList?: ResolverInputTypes["OrganiserSetting_organiserList_operator"] | undefined | null,
	region?: ResolverInputTypes["OrganiserSetting_region_operator"] | undefined | null,
	updatedAt?: ResolverInputTypes["OrganiserSetting_updatedAt_operator"] | undefined | null,
	createdAt?: ResolverInputTypes["OrganiserSetting_createdAt_operator"] | undefined | null,
	id?: ResolverInputTypes["OrganiserSetting_id_operator"] | undefined | null,
	AND?: Array<ResolverInputTypes["OrganiserSetting_where_and"] | undefined | null> | undefined | null,
	OR?: Array<ResolverInputTypes["OrganiserSetting_where_or"] | undefined | null> | undefined | null
};
	["countOrganiserSettings"]: AliasType<{
	totalDocs?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["organiserSettingDocAccess"]: AliasType<{
	fields?:ResolverInputTypes["OrganiserSettingDocAccessFields"],
	create?:ResolverInputTypes["OrganiserSettingCreateDocAccess"],
	read?:ResolverInputTypes["OrganiserSettingReadDocAccess"],
	update?:ResolverInputTypes["OrganiserSettingUpdateDocAccess"],
	delete?:ResolverInputTypes["OrganiserSettingDeleteDocAccess"],
		__typename?: boolean | `@${string}`
}>;
	["OrganiserSettingDocAccessFields"]: AliasType<{
	name?:ResolverInputTypes["OrganiserSettingDocAccessFields_name"],
	organiserList?:ResolverInputTypes["OrganiserSettingDocAccessFields_organiserList"],
	region?:ResolverInputTypes["OrganiserSettingDocAccessFields_region"],
	updatedAt?:ResolverInputTypes["OrganiserSettingDocAccessFields_updatedAt"],
	createdAt?:ResolverInputTypes["OrganiserSettingDocAccessFields_createdAt"],
		__typename?: boolean | `@${string}`
}>;
	["OrganiserSettingDocAccessFields_name"]: AliasType<{
	create?:ResolverInputTypes["OrganiserSettingDocAccessFields_name_Create"],
	read?:ResolverInputTypes["OrganiserSettingDocAccessFields_name_Read"],
	update?:ResolverInputTypes["OrganiserSettingDocAccessFields_name_Update"],
	delete?:ResolverInputTypes["OrganiserSettingDocAccessFields_name_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["OrganiserSettingDocAccessFields_name_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["OrganiserSettingDocAccessFields_name_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["OrganiserSettingDocAccessFields_name_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["OrganiserSettingDocAccessFields_name_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["OrganiserSettingDocAccessFields_organiserList"]: AliasType<{
	create?:ResolverInputTypes["OrganiserSettingDocAccessFields_organiserList_Create"],
	read?:ResolverInputTypes["OrganiserSettingDocAccessFields_organiserList_Read"],
	update?:ResolverInputTypes["OrganiserSettingDocAccessFields_organiserList_Update"],
	delete?:ResolverInputTypes["OrganiserSettingDocAccessFields_organiserList_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["OrganiserSettingDocAccessFields_organiserList_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["OrganiserSettingDocAccessFields_organiserList_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["OrganiserSettingDocAccessFields_organiserList_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["OrganiserSettingDocAccessFields_organiserList_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["OrganiserSettingDocAccessFields_region"]: AliasType<{
	create?:ResolverInputTypes["OrganiserSettingDocAccessFields_region_Create"],
	read?:ResolverInputTypes["OrganiserSettingDocAccessFields_region_Read"],
	update?:ResolverInputTypes["OrganiserSettingDocAccessFields_region_Update"],
	delete?:ResolverInputTypes["OrganiserSettingDocAccessFields_region_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["OrganiserSettingDocAccessFields_region_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["OrganiserSettingDocAccessFields_region_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["OrganiserSettingDocAccessFields_region_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["OrganiserSettingDocAccessFields_region_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["OrganiserSettingDocAccessFields_updatedAt"]: AliasType<{
	create?:ResolverInputTypes["OrganiserSettingDocAccessFields_updatedAt_Create"],
	read?:ResolverInputTypes["OrganiserSettingDocAccessFields_updatedAt_Read"],
	update?:ResolverInputTypes["OrganiserSettingDocAccessFields_updatedAt_Update"],
	delete?:ResolverInputTypes["OrganiserSettingDocAccessFields_updatedAt_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["OrganiserSettingDocAccessFields_updatedAt_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["OrganiserSettingDocAccessFields_updatedAt_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["OrganiserSettingDocAccessFields_updatedAt_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["OrganiserSettingDocAccessFields_updatedAt_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["OrganiserSettingDocAccessFields_createdAt"]: AliasType<{
	create?:ResolverInputTypes["OrganiserSettingDocAccessFields_createdAt_Create"],
	read?:ResolverInputTypes["OrganiserSettingDocAccessFields_createdAt_Read"],
	update?:ResolverInputTypes["OrganiserSettingDocAccessFields_createdAt_Update"],
	delete?:ResolverInputTypes["OrganiserSettingDocAccessFields_createdAt_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["OrganiserSettingDocAccessFields_createdAt_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["OrganiserSettingDocAccessFields_createdAt_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["OrganiserSettingDocAccessFields_createdAt_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["OrganiserSettingDocAccessFields_createdAt_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["OrganiserSettingCreateDocAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["OrganiserSettingReadDocAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["OrganiserSettingUpdateDocAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["OrganiserSettingDeleteDocAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostSetting"]: AliasType<{
	id?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
featuredPosts?: [{	locale?: ResolverInputTypes["LocaleInputType"] | undefined | null,	fallbackLocale?: ResolverInputTypes["FallbackLocaleInputType"] | undefined | null},ResolverInputTypes["Post"]],
	region?:boolean | `@${string}`,
	updatedAt?:boolean | `@${string}`,
	createdAt?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostSetting_region"]:PostSetting_region;
	["PostSettings"]: AliasType<{
	docs?:ResolverInputTypes["PostSetting"],
	hasNextPage?:boolean | `@${string}`,
	hasPrevPage?:boolean | `@${string}`,
	limit?:boolean | `@${string}`,
	nextPage?:boolean | `@${string}`,
	offset?:boolean | `@${string}`,
	page?:boolean | `@${string}`,
	pagingCounter?:boolean | `@${string}`,
	prevPage?:boolean | `@${string}`,
	totalDocs?:boolean | `@${string}`,
	totalPages?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostSetting_where"]: {
	name?: ResolverInputTypes["PostSetting_name_operator"] | undefined | null,
	featuredPosts?: ResolverInputTypes["PostSetting_featuredPosts_operator"] | undefined | null,
	region?: ResolverInputTypes["PostSetting_region_operator"] | undefined | null,
	updatedAt?: ResolverInputTypes["PostSetting_updatedAt_operator"] | undefined | null,
	createdAt?: ResolverInputTypes["PostSetting_createdAt_operator"] | undefined | null,
	id?: ResolverInputTypes["PostSetting_id_operator"] | undefined | null,
	AND?: Array<ResolverInputTypes["PostSetting_where_and"] | undefined | null> | undefined | null,
	OR?: Array<ResolverInputTypes["PostSetting_where_or"] | undefined | null> | undefined | null
};
	["PostSetting_name_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["PostSetting_featuredPosts_operator"]: {
	equals?: ResolverInputTypes["JSON"] | undefined | null,
	not_equals?: ResolverInputTypes["JSON"] | undefined | null,
	in?: Array<ResolverInputTypes["JSON"] | undefined | null> | undefined | null,
	not_in?: Array<ResolverInputTypes["JSON"] | undefined | null> | undefined | null,
	all?: Array<ResolverInputTypes["JSON"] | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["PostSetting_region_operator"]: {
	equals?: ResolverInputTypes["PostSetting_region_Input"] | undefined | null,
	not_equals?: ResolverInputTypes["PostSetting_region_Input"] | undefined | null,
	in?: Array<ResolverInputTypes["PostSetting_region_Input"] | undefined | null> | undefined | null,
	not_in?: Array<ResolverInputTypes["PostSetting_region_Input"] | undefined | null> | undefined | null,
	all?: Array<ResolverInputTypes["PostSetting_region_Input"] | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["PostSetting_region_Input"]:PostSetting_region_Input;
	["PostSetting_updatedAt_operator"]: {
	equals?: ResolverInputTypes["DateTime"] | undefined | null,
	not_equals?: ResolverInputTypes["DateTime"] | undefined | null,
	greater_than_equal?: ResolverInputTypes["DateTime"] | undefined | null,
	greater_than?: ResolverInputTypes["DateTime"] | undefined | null,
	less_than_equal?: ResolverInputTypes["DateTime"] | undefined | null,
	less_than?: ResolverInputTypes["DateTime"] | undefined | null,
	like?: ResolverInputTypes["DateTime"] | undefined | null,
	exists?: boolean | undefined | null
};
	["PostSetting_createdAt_operator"]: {
	equals?: ResolverInputTypes["DateTime"] | undefined | null,
	not_equals?: ResolverInputTypes["DateTime"] | undefined | null,
	greater_than_equal?: ResolverInputTypes["DateTime"] | undefined | null,
	greater_than?: ResolverInputTypes["DateTime"] | undefined | null,
	less_than_equal?: ResolverInputTypes["DateTime"] | undefined | null,
	less_than?: ResolverInputTypes["DateTime"] | undefined | null,
	like?: ResolverInputTypes["DateTime"] | undefined | null,
	exists?: boolean | undefined | null
};
	["PostSetting_id_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["PostSetting_where_and"]: {
	name?: ResolverInputTypes["PostSetting_name_operator"] | undefined | null,
	featuredPosts?: ResolverInputTypes["PostSetting_featuredPosts_operator"] | undefined | null,
	region?: ResolverInputTypes["PostSetting_region_operator"] | undefined | null,
	updatedAt?: ResolverInputTypes["PostSetting_updatedAt_operator"] | undefined | null,
	createdAt?: ResolverInputTypes["PostSetting_createdAt_operator"] | undefined | null,
	id?: ResolverInputTypes["PostSetting_id_operator"] | undefined | null,
	AND?: Array<ResolverInputTypes["PostSetting_where_and"] | undefined | null> | undefined | null,
	OR?: Array<ResolverInputTypes["PostSetting_where_or"] | undefined | null> | undefined | null
};
	["PostSetting_where_or"]: {
	name?: ResolverInputTypes["PostSetting_name_operator"] | undefined | null,
	featuredPosts?: ResolverInputTypes["PostSetting_featuredPosts_operator"] | undefined | null,
	region?: ResolverInputTypes["PostSetting_region_operator"] | undefined | null,
	updatedAt?: ResolverInputTypes["PostSetting_updatedAt_operator"] | undefined | null,
	createdAt?: ResolverInputTypes["PostSetting_createdAt_operator"] | undefined | null,
	id?: ResolverInputTypes["PostSetting_id_operator"] | undefined | null,
	AND?: Array<ResolverInputTypes["PostSetting_where_and"] | undefined | null> | undefined | null,
	OR?: Array<ResolverInputTypes["PostSetting_where_or"] | undefined | null> | undefined | null
};
	["countPostSettings"]: AliasType<{
	totalDocs?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["post_settingDocAccess"]: AliasType<{
	fields?:ResolverInputTypes["PostSettingDocAccessFields"],
	create?:ResolverInputTypes["PostSettingCreateDocAccess"],
	read?:ResolverInputTypes["PostSettingReadDocAccess"],
	update?:ResolverInputTypes["PostSettingUpdateDocAccess"],
	delete?:ResolverInputTypes["PostSettingDeleteDocAccess"],
		__typename?: boolean | `@${string}`
}>;
	["PostSettingDocAccessFields"]: AliasType<{
	name?:ResolverInputTypes["PostSettingDocAccessFields_name"],
	featuredPosts?:ResolverInputTypes["PostSettingDocAccessFields_featuredPosts"],
	region?:ResolverInputTypes["PostSettingDocAccessFields_region"],
	updatedAt?:ResolverInputTypes["PostSettingDocAccessFields_updatedAt"],
	createdAt?:ResolverInputTypes["PostSettingDocAccessFields_createdAt"],
		__typename?: boolean | `@${string}`
}>;
	["PostSettingDocAccessFields_name"]: AliasType<{
	create?:ResolverInputTypes["PostSettingDocAccessFields_name_Create"],
	read?:ResolverInputTypes["PostSettingDocAccessFields_name_Read"],
	update?:ResolverInputTypes["PostSettingDocAccessFields_name_Update"],
	delete?:ResolverInputTypes["PostSettingDocAccessFields_name_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["PostSettingDocAccessFields_name_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostSettingDocAccessFields_name_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostSettingDocAccessFields_name_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostSettingDocAccessFields_name_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostSettingDocAccessFields_featuredPosts"]: AliasType<{
	create?:ResolverInputTypes["PostSettingDocAccessFields_featuredPosts_Create"],
	read?:ResolverInputTypes["PostSettingDocAccessFields_featuredPosts_Read"],
	update?:ResolverInputTypes["PostSettingDocAccessFields_featuredPosts_Update"],
	delete?:ResolverInputTypes["PostSettingDocAccessFields_featuredPosts_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["PostSettingDocAccessFields_featuredPosts_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostSettingDocAccessFields_featuredPosts_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostSettingDocAccessFields_featuredPosts_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostSettingDocAccessFields_featuredPosts_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostSettingDocAccessFields_region"]: AliasType<{
	create?:ResolverInputTypes["PostSettingDocAccessFields_region_Create"],
	read?:ResolverInputTypes["PostSettingDocAccessFields_region_Read"],
	update?:ResolverInputTypes["PostSettingDocAccessFields_region_Update"],
	delete?:ResolverInputTypes["PostSettingDocAccessFields_region_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["PostSettingDocAccessFields_region_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostSettingDocAccessFields_region_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostSettingDocAccessFields_region_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostSettingDocAccessFields_region_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostSettingDocAccessFields_updatedAt"]: AliasType<{
	create?:ResolverInputTypes["PostSettingDocAccessFields_updatedAt_Create"],
	read?:ResolverInputTypes["PostSettingDocAccessFields_updatedAt_Read"],
	update?:ResolverInputTypes["PostSettingDocAccessFields_updatedAt_Update"],
	delete?:ResolverInputTypes["PostSettingDocAccessFields_updatedAt_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["PostSettingDocAccessFields_updatedAt_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostSettingDocAccessFields_updatedAt_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostSettingDocAccessFields_updatedAt_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostSettingDocAccessFields_updatedAt_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostSettingDocAccessFields_createdAt"]: AliasType<{
	create?:ResolverInputTypes["PostSettingDocAccessFields_createdAt_Create"],
	read?:ResolverInputTypes["PostSettingDocAccessFields_createdAt_Read"],
	update?:ResolverInputTypes["PostSettingDocAccessFields_createdAt_Update"],
	delete?:ResolverInputTypes["PostSettingDocAccessFields_createdAt_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["PostSettingDocAccessFields_createdAt_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostSettingDocAccessFields_createdAt_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostSettingDocAccessFields_createdAt_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostSettingDocAccessFields_createdAt_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostSettingCreateDocAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostSettingReadDocAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostSettingUpdateDocAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostSettingDeleteDocAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["HomepageSetting"]: AliasType<{
	id?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	region?:boolean | `@${string}`,
	sections?:ResolverInputTypes["HomepageSetting_Sections"],
	updatedAt?:boolean | `@${string}`,
	createdAt?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["HomepageSetting_region"]:HomepageSetting_region;
	["HomepageSetting_Sections"]: AliasType<{
	ComingWeekEventAndLocationRankingBlock?:ResolverInputTypes["ComingWeekEventAndLocationRankingBlock"],
	CategoryListBlock?:ResolverInputTypes["CategoryListBlock"],
	UserPreferenceBlock?:ResolverInputTypes["UserPreferenceBlock"],
	EventRankingBlock?:ResolverInputTypes["EventRankingBlock"],
	EventTrendBlock?:ResolverInputTypes["EventTrendBlock"],
	LocationRankingBlock?:ResolverInputTypes["LocationRankingBlock"],
	OrganizarBlock?:ResolverInputTypes["OrganizarBlock"],
	SubscriptionBlock?:ResolverInputTypes["SubscriptionBlock"],
	TimableRankingBlock?:ResolverInputTypes["TimableRankingBlock"],
	MultipleEventRankingBlock?:ResolverInputTypes["MultipleEventRankingBlock"],
	TicketingBlock?:ResolverInputTypes["TicketingBlock"],
	BlogBlock?:ResolverInputTypes["BlogBlock"],
	EditorChoiceBlock?:ResolverInputTypes["EditorChoiceBlock"],
		__typename?: boolean | `@${string}`
}>;
	["ComingWeekEventAndLocationRankingBlock"]: AliasType<{
	title?:boolean | `@${string}`,
	locationRankingTitle?:boolean | `@${string}`,
	appearance?:boolean | `@${string}`,
	byRegion?:boolean | `@${string}`,
rankings?: [{	locale?: ResolverInputTypes["LocaleInputType"] | undefined | null,	fallbackLocale?: ResolverInputTypes["FallbackLocaleInputType"] | undefined | null},ResolverInputTypes["LocationRanking"]],
	id?:boolean | `@${string}`,
	blockName?:boolean | `@${string}`,
	blockType?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ComingWeekEventAndLocationRankingBlock_appearance"]:ComingWeekEventAndLocationRankingBlock_appearance;
	["ComingWeekEventAndLocationRankingBlock_byRegion"]:ComingWeekEventAndLocationRankingBlock_byRegion;
	["CategoryListBlock"]: AliasType<{
	title?:boolean | `@${string}`,
	id?:boolean | `@${string}`,
	blockName?:boolean | `@${string}`,
	blockType?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UserPreferenceBlock"]: AliasType<{
	title?:boolean | `@${string}`,
	id?:boolean | `@${string}`,
	blockName?:boolean | `@${string}`,
	blockType?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventRankingBlock"]: AliasType<{
	title?:boolean | `@${string}`,
	appearance?:boolean | `@${string}`,
ranking?: [{	locale?: ResolverInputTypes["LocaleInputType"] | undefined | null,	fallbackLocale?: ResolverInputTypes["FallbackLocaleInputType"] | undefined | null},ResolverInputTypes["EventRanking"]],
	id?:boolean | `@${string}`,
	blockName?:boolean | `@${string}`,
	blockType?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventRankingBlock_appearance"]:EventRankingBlock_appearance;
	["EventTrendBlock"]: AliasType<{
	title?:boolean | `@${string}`,
	id?:boolean | `@${string}`,
	blockName?:boolean | `@${string}`,
	blockType?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationRankingBlock"]: AliasType<{
	title?:boolean | `@${string}`,
	appearance?:boolean | `@${string}`,
ranking?: [{	locale?: ResolverInputTypes["LocaleInputType"] | undefined | null,	fallbackLocale?: ResolverInputTypes["FallbackLocaleInputType"] | undefined | null},ResolverInputTypes["LocationRanking"]],
	id?:boolean | `@${string}`,
	blockName?:boolean | `@${string}`,
	blockType?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationRankingBlock_appearance"]:LocationRankingBlock_appearance;
	["OrganizarBlock"]: AliasType<{
	title?:boolean | `@${string}`,
	id?:boolean | `@${string}`,
	blockName?:boolean | `@${string}`,
	blockType?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["SubscriptionBlock"]: AliasType<{
	title?:boolean | `@${string}`,
	id?:boolean | `@${string}`,
	blockName?:boolean | `@${string}`,
	blockType?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TimableRankingBlock"]: AliasType<{
	title?:boolean | `@${string}`,
ranking?: [{	locale?: ResolverInputTypes["LocaleInputType"] | undefined | null,	fallbackLocale?: ResolverInputTypes["FallbackLocaleInputType"] | undefined | null},ResolverInputTypes["LocationRanking"]],
	id?:boolean | `@${string}`,
	blockName?:boolean | `@${string}`,
	blockType?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MultipleEventRankingBlock"]: AliasType<{
	title?:boolean | `@${string}`,
ranking?: [{	locale?: ResolverInputTypes["LocaleInputType"] | undefined | null,	fallbackLocale?: ResolverInputTypes["FallbackLocaleInputType"] | undefined | null},ResolverInputTypes["EventRanking"]],
	id?:boolean | `@${string}`,
	blockName?:boolean | `@${string}`,
	blockType?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingBlock"]: AliasType<{
	title?:boolean | `@${string}`,
	id?:boolean | `@${string}`,
	blockName?:boolean | `@${string}`,
	blockType?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BlogBlock"]: AliasType<{
	title?:boolean | `@${string}`,
	id?:boolean | `@${string}`,
	blockName?:boolean | `@${string}`,
	blockType?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EditorChoiceBlock"]: AliasType<{
	title?:boolean | `@${string}`,
	id?:boolean | `@${string}`,
	blockName?:boolean | `@${string}`,
	blockType?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["HomepageSettings"]: AliasType<{
	docs?:ResolverInputTypes["HomepageSetting"],
	hasNextPage?:boolean | `@${string}`,
	hasPrevPage?:boolean | `@${string}`,
	limit?:boolean | `@${string}`,
	nextPage?:boolean | `@${string}`,
	offset?:boolean | `@${string}`,
	page?:boolean | `@${string}`,
	pagingCounter?:boolean | `@${string}`,
	prevPage?:boolean | `@${string}`,
	totalDocs?:boolean | `@${string}`,
	totalPages?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["HomepageSetting_where"]: {
	name?: ResolverInputTypes["HomepageSetting_name_operator"] | undefined | null,
	region?: ResolverInputTypes["HomepageSetting_region_operator"] | undefined | null,
	updatedAt?: ResolverInputTypes["HomepageSetting_updatedAt_operator"] | undefined | null,
	createdAt?: ResolverInputTypes["HomepageSetting_createdAt_operator"] | undefined | null,
	id?: ResolverInputTypes["HomepageSetting_id_operator"] | undefined | null,
	AND?: Array<ResolverInputTypes["HomepageSetting_where_and"] | undefined | null> | undefined | null,
	OR?: Array<ResolverInputTypes["HomepageSetting_where_or"] | undefined | null> | undefined | null
};
	["HomepageSetting_name_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null
};
	["HomepageSetting_region_operator"]: {
	equals?: ResolverInputTypes["HomepageSetting_region_Input"] | undefined | null,
	not_equals?: ResolverInputTypes["HomepageSetting_region_Input"] | undefined | null,
	in?: Array<ResolverInputTypes["HomepageSetting_region_Input"] | undefined | null> | undefined | null,
	not_in?: Array<ResolverInputTypes["HomepageSetting_region_Input"] | undefined | null> | undefined | null,
	all?: Array<ResolverInputTypes["HomepageSetting_region_Input"] | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["HomepageSetting_region_Input"]:HomepageSetting_region_Input;
	["HomepageSetting_updatedAt_operator"]: {
	equals?: ResolverInputTypes["DateTime"] | undefined | null,
	not_equals?: ResolverInputTypes["DateTime"] | undefined | null,
	greater_than_equal?: ResolverInputTypes["DateTime"] | undefined | null,
	greater_than?: ResolverInputTypes["DateTime"] | undefined | null,
	less_than_equal?: ResolverInputTypes["DateTime"] | undefined | null,
	less_than?: ResolverInputTypes["DateTime"] | undefined | null,
	like?: ResolverInputTypes["DateTime"] | undefined | null,
	exists?: boolean | undefined | null
};
	["HomepageSetting_createdAt_operator"]: {
	equals?: ResolverInputTypes["DateTime"] | undefined | null,
	not_equals?: ResolverInputTypes["DateTime"] | undefined | null,
	greater_than_equal?: ResolverInputTypes["DateTime"] | undefined | null,
	greater_than?: ResolverInputTypes["DateTime"] | undefined | null,
	less_than_equal?: ResolverInputTypes["DateTime"] | undefined | null,
	less_than?: ResolverInputTypes["DateTime"] | undefined | null,
	like?: ResolverInputTypes["DateTime"] | undefined | null,
	exists?: boolean | undefined | null
};
	["HomepageSetting_id_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["HomepageSetting_where_and"]: {
	name?: ResolverInputTypes["HomepageSetting_name_operator"] | undefined | null,
	region?: ResolverInputTypes["HomepageSetting_region_operator"] | undefined | null,
	updatedAt?: ResolverInputTypes["HomepageSetting_updatedAt_operator"] | undefined | null,
	createdAt?: ResolverInputTypes["HomepageSetting_createdAt_operator"] | undefined | null,
	id?: ResolverInputTypes["HomepageSetting_id_operator"] | undefined | null,
	AND?: Array<ResolverInputTypes["HomepageSetting_where_and"] | undefined | null> | undefined | null,
	OR?: Array<ResolverInputTypes["HomepageSetting_where_or"] | undefined | null> | undefined | null
};
	["HomepageSetting_where_or"]: {
	name?: ResolverInputTypes["HomepageSetting_name_operator"] | undefined | null,
	region?: ResolverInputTypes["HomepageSetting_region_operator"] | undefined | null,
	updatedAt?: ResolverInputTypes["HomepageSetting_updatedAt_operator"] | undefined | null,
	createdAt?: ResolverInputTypes["HomepageSetting_createdAt_operator"] | undefined | null,
	id?: ResolverInputTypes["HomepageSetting_id_operator"] | undefined | null,
	AND?: Array<ResolverInputTypes["HomepageSetting_where_and"] | undefined | null> | undefined | null,
	OR?: Array<ResolverInputTypes["HomepageSetting_where_or"] | undefined | null> | undefined | null
};
	["countHomepageSettings"]: AliasType<{
	totalDocs?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["homepageSettingDocAccess"]: AliasType<{
	fields?:ResolverInputTypes["HomepageSettingDocAccessFields"],
	create?:ResolverInputTypes["HomepageSettingCreateDocAccess"],
	read?:ResolverInputTypes["HomepageSettingReadDocAccess"],
	update?:ResolverInputTypes["HomepageSettingUpdateDocAccess"],
	delete?:ResolverInputTypes["HomepageSettingDeleteDocAccess"],
		__typename?: boolean | `@${string}`
}>;
	["HomepageSettingDocAccessFields"]: AliasType<{
	name?:ResolverInputTypes["HomepageSettingDocAccessFields_name"],
	region?:ResolverInputTypes["HomepageSettingDocAccessFields_region"],
	sections?:ResolverInputTypes["HomepageSettingDocAccessFields_sections"],
	updatedAt?:ResolverInputTypes["HomepageSettingDocAccessFields_updatedAt"],
	createdAt?:ResolverInputTypes["HomepageSettingDocAccessFields_createdAt"],
		__typename?: boolean | `@${string}`
}>;
	["HomepageSettingDocAccessFields_name"]: AliasType<{
	create?:ResolverInputTypes["HomepageSettingDocAccessFields_name_Create"],
	read?:ResolverInputTypes["HomepageSettingDocAccessFields_name_Read"],
	update?:ResolverInputTypes["HomepageSettingDocAccessFields_name_Update"],
	delete?:ResolverInputTypes["HomepageSettingDocAccessFields_name_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["HomepageSettingDocAccessFields_name_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["HomepageSettingDocAccessFields_name_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["HomepageSettingDocAccessFields_name_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["HomepageSettingDocAccessFields_name_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["HomepageSettingDocAccessFields_region"]: AliasType<{
	create?:ResolverInputTypes["HomepageSettingDocAccessFields_region_Create"],
	read?:ResolverInputTypes["HomepageSettingDocAccessFields_region_Read"],
	update?:ResolverInputTypes["HomepageSettingDocAccessFields_region_Update"],
	delete?:ResolverInputTypes["HomepageSettingDocAccessFields_region_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["HomepageSettingDocAccessFields_region_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["HomepageSettingDocAccessFields_region_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["HomepageSettingDocAccessFields_region_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["HomepageSettingDocAccessFields_region_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["HomepageSettingDocAccessFields_sections"]: AliasType<{
	create?:ResolverInputTypes["HomepageSettingDocAccessFields_sections_Create"],
	read?:ResolverInputTypes["HomepageSettingDocAccessFields_sections_Read"],
	update?:ResolverInputTypes["HomepageSettingDocAccessFields_sections_Update"],
	delete?:ResolverInputTypes["HomepageSettingDocAccessFields_sections_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["HomepageSettingDocAccessFields_sections_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["HomepageSettingDocAccessFields_sections_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["HomepageSettingDocAccessFields_sections_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["HomepageSettingDocAccessFields_sections_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["HomepageSettingDocAccessFields_updatedAt"]: AliasType<{
	create?:ResolverInputTypes["HomepageSettingDocAccessFields_updatedAt_Create"],
	read?:ResolverInputTypes["HomepageSettingDocAccessFields_updatedAt_Read"],
	update?:ResolverInputTypes["HomepageSettingDocAccessFields_updatedAt_Update"],
	delete?:ResolverInputTypes["HomepageSettingDocAccessFields_updatedAt_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["HomepageSettingDocAccessFields_updatedAt_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["HomepageSettingDocAccessFields_updatedAt_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["HomepageSettingDocAccessFields_updatedAt_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["HomepageSettingDocAccessFields_updatedAt_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["HomepageSettingDocAccessFields_createdAt"]: AliasType<{
	create?:ResolverInputTypes["HomepageSettingDocAccessFields_createdAt_Create"],
	read?:ResolverInputTypes["HomepageSettingDocAccessFields_createdAt_Read"],
	update?:ResolverInputTypes["HomepageSettingDocAccessFields_createdAt_Update"],
	delete?:ResolverInputTypes["HomepageSettingDocAccessFields_createdAt_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["HomepageSettingDocAccessFields_createdAt_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["HomepageSettingDocAccessFields_createdAt_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["HomepageSettingDocAccessFields_createdAt_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["HomepageSettingDocAccessFields_createdAt_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["HomepageSettingCreateDocAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["HomepageSettingReadDocAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["HomepageSettingUpdateDocAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["HomepageSettingDeleteDocAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannerSection"]: AliasType<{
	id?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	slug?:boolean | `@${string}`,
	banners?:ResolverInputTypes["BannerSection_Banners"],
	updatedAt?:boolean | `@${string}`,
	createdAt?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannerSection_Banners"]: AliasType<{
	name?:boolean | `@${string}`,
	status?:boolean | `@${string}`,
desktop?: [{	locale?: ResolverInputTypes["LocaleInputType"] | undefined | null,	fallbackLocale?: ResolverInputTypes["FallbackLocaleInputType"] | undefined | null,	where?: ResolverInputTypes["BannerSection_Banners_Desktop_where"] | undefined | null},ResolverInputTypes["Media"]],
	bannerStartDate?:boolean | `@${string}`,
	bannerEndDate?:boolean | `@${string}`,
	region?:boolean | `@${string}`,
	id?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannerSection_Banners_Desktop_where"]: {
	alt?: ResolverInputTypes["BannerSection_Banners_Desktop_alt_operator"] | undefined | null,
	updatedAt?: ResolverInputTypes["BannerSection_Banners_Desktop_updatedAt_operator"] | undefined | null,
	createdAt?: ResolverInputTypes["BannerSection_Banners_Desktop_createdAt_operator"] | undefined | null,
	url?: ResolverInputTypes["BannerSection_Banners_Desktop_url_operator"] | undefined | null,
	filename?: ResolverInputTypes["BannerSection_Banners_Desktop_filename_operator"] | undefined | null,
	mimeType?: ResolverInputTypes["BannerSection_Banners_Desktop_mimeType_operator"] | undefined | null,
	filesize?: ResolverInputTypes["BannerSection_Banners_Desktop_filesize_operator"] | undefined | null,
	width?: ResolverInputTypes["BannerSection_Banners_Desktop_width_operator"] | undefined | null,
	height?: ResolverInputTypes["BannerSection_Banners_Desktop_height_operator"] | undefined | null,
	focalX?: ResolverInputTypes["BannerSection_Banners_Desktop_focalX_operator"] | undefined | null,
	focalY?: ResolverInputTypes["BannerSection_Banners_Desktop_focalY_operator"] | undefined | null,
	sizes__small__url?: ResolverInputTypes["BannerSection_Banners_Desktop_sizes__small__url_operator"] | undefined | null,
	sizes__small__width?: ResolverInputTypes["BannerSection_Banners_Desktop_sizes__small__width_operator"] | undefined | null,
	sizes__small__height?: ResolverInputTypes["BannerSection_Banners_Desktop_sizes__small__height_operator"] | undefined | null,
	sizes__small__mimeType?: ResolverInputTypes["BannerSection_Banners_Desktop_sizes__small__mimeType_operator"] | undefined | null,
	sizes__small__filesize?: ResolverInputTypes["BannerSection_Banners_Desktop_sizes__small__filesize_operator"] | undefined | null,
	sizes__small__filename?: ResolverInputTypes["BannerSection_Banners_Desktop_sizes__small__filename_operator"] | undefined | null,
	sizes__medium__url?: ResolverInputTypes["BannerSection_Banners_Desktop_sizes__medium__url_operator"] | undefined | null,
	sizes__medium__width?: ResolverInputTypes["BannerSection_Banners_Desktop_sizes__medium__width_operator"] | undefined | null,
	sizes__medium__height?: ResolverInputTypes["BannerSection_Banners_Desktop_sizes__medium__height_operator"] | undefined | null,
	sizes__medium__mimeType?: ResolverInputTypes["BannerSection_Banners_Desktop_sizes__medium__mimeType_operator"] | undefined | null,
	sizes__medium__filesize?: ResolverInputTypes["BannerSection_Banners_Desktop_sizes__medium__filesize_operator"] | undefined | null,
	sizes__medium__filename?: ResolverInputTypes["BannerSection_Banners_Desktop_sizes__medium__filename_operator"] | undefined | null,
	sizes__large__url?: ResolverInputTypes["BannerSection_Banners_Desktop_sizes__large__url_operator"] | undefined | null,
	sizes__large__width?: ResolverInputTypes["BannerSection_Banners_Desktop_sizes__large__width_operator"] | undefined | null,
	sizes__large__height?: ResolverInputTypes["BannerSection_Banners_Desktop_sizes__large__height_operator"] | undefined | null,
	sizes__large__mimeType?: ResolverInputTypes["BannerSection_Banners_Desktop_sizes__large__mimeType_operator"] | undefined | null,
	sizes__large__filesize?: ResolverInputTypes["BannerSection_Banners_Desktop_sizes__large__filesize_operator"] | undefined | null,
	sizes__large__filename?: ResolverInputTypes["BannerSection_Banners_Desktop_sizes__large__filename_operator"] | undefined | null,
	sizes__small_jpeg__url?: ResolverInputTypes["BannerSection_Banners_Desktop_sizes__small_jpeg__url_operator"] | undefined | null,
	sizes__small_jpeg__width?: ResolverInputTypes["BannerSection_Banners_Desktop_sizes__small_jpeg__width_operator"] | undefined | null,
	sizes__small_jpeg__height?: ResolverInputTypes["BannerSection_Banners_Desktop_sizes__small_jpeg__height_operator"] | undefined | null,
	sizes__small_jpeg__mimeType?: ResolverInputTypes["BannerSection_Banners_Desktop_sizes__small_jpeg__mimeType_operator"] | undefined | null,
	sizes__small_jpeg__filesize?: ResolverInputTypes["BannerSection_Banners_Desktop_sizes__small_jpeg__filesize_operator"] | undefined | null,
	sizes__small_jpeg__filename?: ResolverInputTypes["BannerSection_Banners_Desktop_sizes__small_jpeg__filename_operator"] | undefined | null,
	sizes__medium_jpeg__url?: ResolverInputTypes["BannerSection_Banners_Desktop_sizes__medium_jpeg__url_operator"] | undefined | null,
	sizes__medium_jpeg__width?: ResolverInputTypes["BannerSection_Banners_Desktop_sizes__medium_jpeg__width_operator"] | undefined | null,
	sizes__medium_jpeg__height?: ResolverInputTypes["BannerSection_Banners_Desktop_sizes__medium_jpeg__height_operator"] | undefined | null,
	sizes__medium_jpeg__mimeType?: ResolverInputTypes["BannerSection_Banners_Desktop_sizes__medium_jpeg__mimeType_operator"] | undefined | null,
	sizes__medium_jpeg__filesize?: ResolverInputTypes["BannerSection_Banners_Desktop_sizes__medium_jpeg__filesize_operator"] | undefined | null,
	sizes__medium_jpeg__filename?: ResolverInputTypes["BannerSection_Banners_Desktop_sizes__medium_jpeg__filename_operator"] | undefined | null,
	sizes__large_jpeg__url?: ResolverInputTypes["BannerSection_Banners_Desktop_sizes__large_jpeg__url_operator"] | undefined | null,
	sizes__large_jpeg__width?: ResolverInputTypes["BannerSection_Banners_Desktop_sizes__large_jpeg__width_operator"] | undefined | null,
	sizes__large_jpeg__height?: ResolverInputTypes["BannerSection_Banners_Desktop_sizes__large_jpeg__height_operator"] | undefined | null,
	sizes__large_jpeg__mimeType?: ResolverInputTypes["BannerSection_Banners_Desktop_sizes__large_jpeg__mimeType_operator"] | undefined | null,
	sizes__large_jpeg__filesize?: ResolverInputTypes["BannerSection_Banners_Desktop_sizes__large_jpeg__filesize_operator"] | undefined | null,
	sizes__large_jpeg__filename?: ResolverInputTypes["BannerSection_Banners_Desktop_sizes__large_jpeg__filename_operator"] | undefined | null,
	id?: ResolverInputTypes["BannerSection_Banners_Desktop_id_operator"] | undefined | null,
	AND?: Array<ResolverInputTypes["BannerSection_Banners_Desktop_where_and"] | undefined | null> | undefined | null,
	OR?: Array<ResolverInputTypes["BannerSection_Banners_Desktop_where_or"] | undefined | null> | undefined | null
};
	["BannerSection_Banners_Desktop_alt_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["BannerSection_Banners_Desktop_updatedAt_operator"]: {
	equals?: ResolverInputTypes["DateTime"] | undefined | null,
	not_equals?: ResolverInputTypes["DateTime"] | undefined | null,
	greater_than_equal?: ResolverInputTypes["DateTime"] | undefined | null,
	greater_than?: ResolverInputTypes["DateTime"] | undefined | null,
	less_than_equal?: ResolverInputTypes["DateTime"] | undefined | null,
	less_than?: ResolverInputTypes["DateTime"] | undefined | null,
	like?: ResolverInputTypes["DateTime"] | undefined | null,
	exists?: boolean | undefined | null
};
	["BannerSection_Banners_Desktop_createdAt_operator"]: {
	equals?: ResolverInputTypes["DateTime"] | undefined | null,
	not_equals?: ResolverInputTypes["DateTime"] | undefined | null,
	greater_than_equal?: ResolverInputTypes["DateTime"] | undefined | null,
	greater_than?: ResolverInputTypes["DateTime"] | undefined | null,
	less_than_equal?: ResolverInputTypes["DateTime"] | undefined | null,
	less_than?: ResolverInputTypes["DateTime"] | undefined | null,
	like?: ResolverInputTypes["DateTime"] | undefined | null,
	exists?: boolean | undefined | null
};
	["BannerSection_Banners_Desktop_url_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["BannerSection_Banners_Desktop_filename_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["BannerSection_Banners_Desktop_mimeType_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["BannerSection_Banners_Desktop_filesize_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["BannerSection_Banners_Desktop_width_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["BannerSection_Banners_Desktop_height_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["BannerSection_Banners_Desktop_focalX_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["BannerSection_Banners_Desktop_focalY_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["BannerSection_Banners_Desktop_sizes__small__url_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["BannerSection_Banners_Desktop_sizes__small__width_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["BannerSection_Banners_Desktop_sizes__small__height_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["BannerSection_Banners_Desktop_sizes__small__mimeType_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["BannerSection_Banners_Desktop_sizes__small__filesize_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["BannerSection_Banners_Desktop_sizes__small__filename_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["BannerSection_Banners_Desktop_sizes__medium__url_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["BannerSection_Banners_Desktop_sizes__medium__width_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["BannerSection_Banners_Desktop_sizes__medium__height_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["BannerSection_Banners_Desktop_sizes__medium__mimeType_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["BannerSection_Banners_Desktop_sizes__medium__filesize_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["BannerSection_Banners_Desktop_sizes__medium__filename_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["BannerSection_Banners_Desktop_sizes__large__url_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["BannerSection_Banners_Desktop_sizes__large__width_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["BannerSection_Banners_Desktop_sizes__large__height_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["BannerSection_Banners_Desktop_sizes__large__mimeType_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["BannerSection_Banners_Desktop_sizes__large__filesize_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["BannerSection_Banners_Desktop_sizes__large__filename_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["BannerSection_Banners_Desktop_sizes__small_jpeg__url_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["BannerSection_Banners_Desktop_sizes__small_jpeg__width_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["BannerSection_Banners_Desktop_sizes__small_jpeg__height_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["BannerSection_Banners_Desktop_sizes__small_jpeg__mimeType_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["BannerSection_Banners_Desktop_sizes__small_jpeg__filesize_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["BannerSection_Banners_Desktop_sizes__small_jpeg__filename_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["BannerSection_Banners_Desktop_sizes__medium_jpeg__url_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["BannerSection_Banners_Desktop_sizes__medium_jpeg__width_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["BannerSection_Banners_Desktop_sizes__medium_jpeg__height_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["BannerSection_Banners_Desktop_sizes__medium_jpeg__mimeType_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["BannerSection_Banners_Desktop_sizes__medium_jpeg__filesize_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["BannerSection_Banners_Desktop_sizes__medium_jpeg__filename_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["BannerSection_Banners_Desktop_sizes__large_jpeg__url_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["BannerSection_Banners_Desktop_sizes__large_jpeg__width_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["BannerSection_Banners_Desktop_sizes__large_jpeg__height_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["BannerSection_Banners_Desktop_sizes__large_jpeg__mimeType_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["BannerSection_Banners_Desktop_sizes__large_jpeg__filesize_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["BannerSection_Banners_Desktop_sizes__large_jpeg__filename_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["BannerSection_Banners_Desktop_id_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["BannerSection_Banners_Desktop_where_and"]: {
	alt?: ResolverInputTypes["BannerSection_Banners_Desktop_alt_operator"] | undefined | null,
	updatedAt?: ResolverInputTypes["BannerSection_Banners_Desktop_updatedAt_operator"] | undefined | null,
	createdAt?: ResolverInputTypes["BannerSection_Banners_Desktop_createdAt_operator"] | undefined | null,
	url?: ResolverInputTypes["BannerSection_Banners_Desktop_url_operator"] | undefined | null,
	filename?: ResolverInputTypes["BannerSection_Banners_Desktop_filename_operator"] | undefined | null,
	mimeType?: ResolverInputTypes["BannerSection_Banners_Desktop_mimeType_operator"] | undefined | null,
	filesize?: ResolverInputTypes["BannerSection_Banners_Desktop_filesize_operator"] | undefined | null,
	width?: ResolverInputTypes["BannerSection_Banners_Desktop_width_operator"] | undefined | null,
	height?: ResolverInputTypes["BannerSection_Banners_Desktop_height_operator"] | undefined | null,
	focalX?: ResolverInputTypes["BannerSection_Banners_Desktop_focalX_operator"] | undefined | null,
	focalY?: ResolverInputTypes["BannerSection_Banners_Desktop_focalY_operator"] | undefined | null,
	sizes__small__url?: ResolverInputTypes["BannerSection_Banners_Desktop_sizes__small__url_operator"] | undefined | null,
	sizes__small__width?: ResolverInputTypes["BannerSection_Banners_Desktop_sizes__small__width_operator"] | undefined | null,
	sizes__small__height?: ResolverInputTypes["BannerSection_Banners_Desktop_sizes__small__height_operator"] | undefined | null,
	sizes__small__mimeType?: ResolverInputTypes["BannerSection_Banners_Desktop_sizes__small__mimeType_operator"] | undefined | null,
	sizes__small__filesize?: ResolverInputTypes["BannerSection_Banners_Desktop_sizes__small__filesize_operator"] | undefined | null,
	sizes__small__filename?: ResolverInputTypes["BannerSection_Banners_Desktop_sizes__small__filename_operator"] | undefined | null,
	sizes__medium__url?: ResolverInputTypes["BannerSection_Banners_Desktop_sizes__medium__url_operator"] | undefined | null,
	sizes__medium__width?: ResolverInputTypes["BannerSection_Banners_Desktop_sizes__medium__width_operator"] | undefined | null,
	sizes__medium__height?: ResolverInputTypes["BannerSection_Banners_Desktop_sizes__medium__height_operator"] | undefined | null,
	sizes__medium__mimeType?: ResolverInputTypes["BannerSection_Banners_Desktop_sizes__medium__mimeType_operator"] | undefined | null,
	sizes__medium__filesize?: ResolverInputTypes["BannerSection_Banners_Desktop_sizes__medium__filesize_operator"] | undefined | null,
	sizes__medium__filename?: ResolverInputTypes["BannerSection_Banners_Desktop_sizes__medium__filename_operator"] | undefined | null,
	sizes__large__url?: ResolverInputTypes["BannerSection_Banners_Desktop_sizes__large__url_operator"] | undefined | null,
	sizes__large__width?: ResolverInputTypes["BannerSection_Banners_Desktop_sizes__large__width_operator"] | undefined | null,
	sizes__large__height?: ResolverInputTypes["BannerSection_Banners_Desktop_sizes__large__height_operator"] | undefined | null,
	sizes__large__mimeType?: ResolverInputTypes["BannerSection_Banners_Desktop_sizes__large__mimeType_operator"] | undefined | null,
	sizes__large__filesize?: ResolverInputTypes["BannerSection_Banners_Desktop_sizes__large__filesize_operator"] | undefined | null,
	sizes__large__filename?: ResolverInputTypes["BannerSection_Banners_Desktop_sizes__large__filename_operator"] | undefined | null,
	sizes__small_jpeg__url?: ResolverInputTypes["BannerSection_Banners_Desktop_sizes__small_jpeg__url_operator"] | undefined | null,
	sizes__small_jpeg__width?: ResolverInputTypes["BannerSection_Banners_Desktop_sizes__small_jpeg__width_operator"] | undefined | null,
	sizes__small_jpeg__height?: ResolverInputTypes["BannerSection_Banners_Desktop_sizes__small_jpeg__height_operator"] | undefined | null,
	sizes__small_jpeg__mimeType?: ResolverInputTypes["BannerSection_Banners_Desktop_sizes__small_jpeg__mimeType_operator"] | undefined | null,
	sizes__small_jpeg__filesize?: ResolverInputTypes["BannerSection_Banners_Desktop_sizes__small_jpeg__filesize_operator"] | undefined | null,
	sizes__small_jpeg__filename?: ResolverInputTypes["BannerSection_Banners_Desktop_sizes__small_jpeg__filename_operator"] | undefined | null,
	sizes__medium_jpeg__url?: ResolverInputTypes["BannerSection_Banners_Desktop_sizes__medium_jpeg__url_operator"] | undefined | null,
	sizes__medium_jpeg__width?: ResolverInputTypes["BannerSection_Banners_Desktop_sizes__medium_jpeg__width_operator"] | undefined | null,
	sizes__medium_jpeg__height?: ResolverInputTypes["BannerSection_Banners_Desktop_sizes__medium_jpeg__height_operator"] | undefined | null,
	sizes__medium_jpeg__mimeType?: ResolverInputTypes["BannerSection_Banners_Desktop_sizes__medium_jpeg__mimeType_operator"] | undefined | null,
	sizes__medium_jpeg__filesize?: ResolverInputTypes["BannerSection_Banners_Desktop_sizes__medium_jpeg__filesize_operator"] | undefined | null,
	sizes__medium_jpeg__filename?: ResolverInputTypes["BannerSection_Banners_Desktop_sizes__medium_jpeg__filename_operator"] | undefined | null,
	sizes__large_jpeg__url?: ResolverInputTypes["BannerSection_Banners_Desktop_sizes__large_jpeg__url_operator"] | undefined | null,
	sizes__large_jpeg__width?: ResolverInputTypes["BannerSection_Banners_Desktop_sizes__large_jpeg__width_operator"] | undefined | null,
	sizes__large_jpeg__height?: ResolverInputTypes["BannerSection_Banners_Desktop_sizes__large_jpeg__height_operator"] | undefined | null,
	sizes__large_jpeg__mimeType?: ResolverInputTypes["BannerSection_Banners_Desktop_sizes__large_jpeg__mimeType_operator"] | undefined | null,
	sizes__large_jpeg__filesize?: ResolverInputTypes["BannerSection_Banners_Desktop_sizes__large_jpeg__filesize_operator"] | undefined | null,
	sizes__large_jpeg__filename?: ResolverInputTypes["BannerSection_Banners_Desktop_sizes__large_jpeg__filename_operator"] | undefined | null,
	id?: ResolverInputTypes["BannerSection_Banners_Desktop_id_operator"] | undefined | null,
	AND?: Array<ResolverInputTypes["BannerSection_Banners_Desktop_where_and"] | undefined | null> | undefined | null,
	OR?: Array<ResolverInputTypes["BannerSection_Banners_Desktop_where_or"] | undefined | null> | undefined | null
};
	["BannerSection_Banners_Desktop_where_or"]: {
	alt?: ResolverInputTypes["BannerSection_Banners_Desktop_alt_operator"] | undefined | null,
	updatedAt?: ResolverInputTypes["BannerSection_Banners_Desktop_updatedAt_operator"] | undefined | null,
	createdAt?: ResolverInputTypes["BannerSection_Banners_Desktop_createdAt_operator"] | undefined | null,
	url?: ResolverInputTypes["BannerSection_Banners_Desktop_url_operator"] | undefined | null,
	filename?: ResolverInputTypes["BannerSection_Banners_Desktop_filename_operator"] | undefined | null,
	mimeType?: ResolverInputTypes["BannerSection_Banners_Desktop_mimeType_operator"] | undefined | null,
	filesize?: ResolverInputTypes["BannerSection_Banners_Desktop_filesize_operator"] | undefined | null,
	width?: ResolverInputTypes["BannerSection_Banners_Desktop_width_operator"] | undefined | null,
	height?: ResolverInputTypes["BannerSection_Banners_Desktop_height_operator"] | undefined | null,
	focalX?: ResolverInputTypes["BannerSection_Banners_Desktop_focalX_operator"] | undefined | null,
	focalY?: ResolverInputTypes["BannerSection_Banners_Desktop_focalY_operator"] | undefined | null,
	sizes__small__url?: ResolverInputTypes["BannerSection_Banners_Desktop_sizes__small__url_operator"] | undefined | null,
	sizes__small__width?: ResolverInputTypes["BannerSection_Banners_Desktop_sizes__small__width_operator"] | undefined | null,
	sizes__small__height?: ResolverInputTypes["BannerSection_Banners_Desktop_sizes__small__height_operator"] | undefined | null,
	sizes__small__mimeType?: ResolverInputTypes["BannerSection_Banners_Desktop_sizes__small__mimeType_operator"] | undefined | null,
	sizes__small__filesize?: ResolverInputTypes["BannerSection_Banners_Desktop_sizes__small__filesize_operator"] | undefined | null,
	sizes__small__filename?: ResolverInputTypes["BannerSection_Banners_Desktop_sizes__small__filename_operator"] | undefined | null,
	sizes__medium__url?: ResolverInputTypes["BannerSection_Banners_Desktop_sizes__medium__url_operator"] | undefined | null,
	sizes__medium__width?: ResolverInputTypes["BannerSection_Banners_Desktop_sizes__medium__width_operator"] | undefined | null,
	sizes__medium__height?: ResolverInputTypes["BannerSection_Banners_Desktop_sizes__medium__height_operator"] | undefined | null,
	sizes__medium__mimeType?: ResolverInputTypes["BannerSection_Banners_Desktop_sizes__medium__mimeType_operator"] | undefined | null,
	sizes__medium__filesize?: ResolverInputTypes["BannerSection_Banners_Desktop_sizes__medium__filesize_operator"] | undefined | null,
	sizes__medium__filename?: ResolverInputTypes["BannerSection_Banners_Desktop_sizes__medium__filename_operator"] | undefined | null,
	sizes__large__url?: ResolverInputTypes["BannerSection_Banners_Desktop_sizes__large__url_operator"] | undefined | null,
	sizes__large__width?: ResolverInputTypes["BannerSection_Banners_Desktop_sizes__large__width_operator"] | undefined | null,
	sizes__large__height?: ResolverInputTypes["BannerSection_Banners_Desktop_sizes__large__height_operator"] | undefined | null,
	sizes__large__mimeType?: ResolverInputTypes["BannerSection_Banners_Desktop_sizes__large__mimeType_operator"] | undefined | null,
	sizes__large__filesize?: ResolverInputTypes["BannerSection_Banners_Desktop_sizes__large__filesize_operator"] | undefined | null,
	sizes__large__filename?: ResolverInputTypes["BannerSection_Banners_Desktop_sizes__large__filename_operator"] | undefined | null,
	sizes__small_jpeg__url?: ResolverInputTypes["BannerSection_Banners_Desktop_sizes__small_jpeg__url_operator"] | undefined | null,
	sizes__small_jpeg__width?: ResolverInputTypes["BannerSection_Banners_Desktop_sizes__small_jpeg__width_operator"] | undefined | null,
	sizes__small_jpeg__height?: ResolverInputTypes["BannerSection_Banners_Desktop_sizes__small_jpeg__height_operator"] | undefined | null,
	sizes__small_jpeg__mimeType?: ResolverInputTypes["BannerSection_Banners_Desktop_sizes__small_jpeg__mimeType_operator"] | undefined | null,
	sizes__small_jpeg__filesize?: ResolverInputTypes["BannerSection_Banners_Desktop_sizes__small_jpeg__filesize_operator"] | undefined | null,
	sizes__small_jpeg__filename?: ResolverInputTypes["BannerSection_Banners_Desktop_sizes__small_jpeg__filename_operator"] | undefined | null,
	sizes__medium_jpeg__url?: ResolverInputTypes["BannerSection_Banners_Desktop_sizes__medium_jpeg__url_operator"] | undefined | null,
	sizes__medium_jpeg__width?: ResolverInputTypes["BannerSection_Banners_Desktop_sizes__medium_jpeg__width_operator"] | undefined | null,
	sizes__medium_jpeg__height?: ResolverInputTypes["BannerSection_Banners_Desktop_sizes__medium_jpeg__height_operator"] | undefined | null,
	sizes__medium_jpeg__mimeType?: ResolverInputTypes["BannerSection_Banners_Desktop_sizes__medium_jpeg__mimeType_operator"] | undefined | null,
	sizes__medium_jpeg__filesize?: ResolverInputTypes["BannerSection_Banners_Desktop_sizes__medium_jpeg__filesize_operator"] | undefined | null,
	sizes__medium_jpeg__filename?: ResolverInputTypes["BannerSection_Banners_Desktop_sizes__medium_jpeg__filename_operator"] | undefined | null,
	sizes__large_jpeg__url?: ResolverInputTypes["BannerSection_Banners_Desktop_sizes__large_jpeg__url_operator"] | undefined | null,
	sizes__large_jpeg__width?: ResolverInputTypes["BannerSection_Banners_Desktop_sizes__large_jpeg__width_operator"] | undefined | null,
	sizes__large_jpeg__height?: ResolverInputTypes["BannerSection_Banners_Desktop_sizes__large_jpeg__height_operator"] | undefined | null,
	sizes__large_jpeg__mimeType?: ResolverInputTypes["BannerSection_Banners_Desktop_sizes__large_jpeg__mimeType_operator"] | undefined | null,
	sizes__large_jpeg__filesize?: ResolverInputTypes["BannerSection_Banners_Desktop_sizes__large_jpeg__filesize_operator"] | undefined | null,
	sizes__large_jpeg__filename?: ResolverInputTypes["BannerSection_Banners_Desktop_sizes__large_jpeg__filename_operator"] | undefined | null,
	id?: ResolverInputTypes["BannerSection_Banners_Desktop_id_operator"] | undefined | null,
	AND?: Array<ResolverInputTypes["BannerSection_Banners_Desktop_where_and"] | undefined | null> | undefined | null,
	OR?: Array<ResolverInputTypes["BannerSection_Banners_Desktop_where_or"] | undefined | null> | undefined | null
};
	["BannerSection_Banners_region"]:BannerSection_Banners_region;
	["BannerSections"]: AliasType<{
	docs?:ResolverInputTypes["BannerSection"],
	hasNextPage?:boolean | `@${string}`,
	hasPrevPage?:boolean | `@${string}`,
	limit?:boolean | `@${string}`,
	nextPage?:boolean | `@${string}`,
	offset?:boolean | `@${string}`,
	page?:boolean | `@${string}`,
	pagingCounter?:boolean | `@${string}`,
	prevPage?:boolean | `@${string}`,
	totalDocs?:boolean | `@${string}`,
	totalPages?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannerSection_where"]: {
	name?: ResolverInputTypes["BannerSection_name_operator"] | undefined | null,
	slug?: ResolverInputTypes["BannerSection_slug_operator"] | undefined | null,
	banners__name?: ResolverInputTypes["BannerSection_banners__name_operator"] | undefined | null,
	banners__status?: ResolverInputTypes["BannerSection_banners__status_operator"] | undefined | null,
	banners__desktop?: ResolverInputTypes["BannerSection_banners__desktop_operator"] | undefined | null,
	banners__bannerStartDate?: ResolverInputTypes["BannerSection_banners__bannerStartDate_operator"] | undefined | null,
	banners__bannerEndDate?: ResolverInputTypes["BannerSection_banners__bannerEndDate_operator"] | undefined | null,
	banners__region?: ResolverInputTypes["BannerSection_banners__region_operator"] | undefined | null,
	banners__id?: ResolverInputTypes["BannerSection_banners__id_operator"] | undefined | null,
	updatedAt?: ResolverInputTypes["BannerSection_updatedAt_operator"] | undefined | null,
	createdAt?: ResolverInputTypes["BannerSection_createdAt_operator"] | undefined | null,
	id?: ResolverInputTypes["BannerSection_id_operator"] | undefined | null,
	AND?: Array<ResolverInputTypes["BannerSection_where_and"] | undefined | null> | undefined | null,
	OR?: Array<ResolverInputTypes["BannerSection_where_or"] | undefined | null> | undefined | null
};
	["BannerSection_name_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["BannerSection_slug_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["BannerSection_banners__name_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["BannerSection_banners__status_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["BannerSection_banners__desktop_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null
};
	["BannerSection_banners__bannerStartDate_operator"]: {
	equals?: ResolverInputTypes["DateTime"] | undefined | null,
	not_equals?: ResolverInputTypes["DateTime"] | undefined | null,
	greater_than_equal?: ResolverInputTypes["DateTime"] | undefined | null,
	greater_than?: ResolverInputTypes["DateTime"] | undefined | null,
	less_than_equal?: ResolverInputTypes["DateTime"] | undefined | null,
	less_than?: ResolverInputTypes["DateTime"] | undefined | null,
	like?: ResolverInputTypes["DateTime"] | undefined | null,
	exists?: boolean | undefined | null
};
	["BannerSection_banners__bannerEndDate_operator"]: {
	equals?: ResolverInputTypes["DateTime"] | undefined | null,
	not_equals?: ResolverInputTypes["DateTime"] | undefined | null,
	greater_than_equal?: ResolverInputTypes["DateTime"] | undefined | null,
	greater_than?: ResolverInputTypes["DateTime"] | undefined | null,
	less_than_equal?: ResolverInputTypes["DateTime"] | undefined | null,
	less_than?: ResolverInputTypes["DateTime"] | undefined | null,
	like?: ResolverInputTypes["DateTime"] | undefined | null,
	exists?: boolean | undefined | null
};
	["BannerSection_banners__region_operator"]: {
	equals?: ResolverInputTypes["BannerSection_banners__region_Input"] | undefined | null,
	not_equals?: ResolverInputTypes["BannerSection_banners__region_Input"] | undefined | null,
	in?: Array<ResolverInputTypes["BannerSection_banners__region_Input"] | undefined | null> | undefined | null,
	not_in?: Array<ResolverInputTypes["BannerSection_banners__region_Input"] | undefined | null> | undefined | null,
	all?: Array<ResolverInputTypes["BannerSection_banners__region_Input"] | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["BannerSection_banners__region_Input"]:BannerSection_banners__region_Input;
	["BannerSection_banners__id_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["BannerSection_updatedAt_operator"]: {
	equals?: ResolverInputTypes["DateTime"] | undefined | null,
	not_equals?: ResolverInputTypes["DateTime"] | undefined | null,
	greater_than_equal?: ResolverInputTypes["DateTime"] | undefined | null,
	greater_than?: ResolverInputTypes["DateTime"] | undefined | null,
	less_than_equal?: ResolverInputTypes["DateTime"] | undefined | null,
	less_than?: ResolverInputTypes["DateTime"] | undefined | null,
	like?: ResolverInputTypes["DateTime"] | undefined | null,
	exists?: boolean | undefined | null
};
	["BannerSection_createdAt_operator"]: {
	equals?: ResolverInputTypes["DateTime"] | undefined | null,
	not_equals?: ResolverInputTypes["DateTime"] | undefined | null,
	greater_than_equal?: ResolverInputTypes["DateTime"] | undefined | null,
	greater_than?: ResolverInputTypes["DateTime"] | undefined | null,
	less_than_equal?: ResolverInputTypes["DateTime"] | undefined | null,
	less_than?: ResolverInputTypes["DateTime"] | undefined | null,
	like?: ResolverInputTypes["DateTime"] | undefined | null,
	exists?: boolean | undefined | null
};
	["BannerSection_id_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["BannerSection_where_and"]: {
	name?: ResolverInputTypes["BannerSection_name_operator"] | undefined | null,
	slug?: ResolverInputTypes["BannerSection_slug_operator"] | undefined | null,
	banners__name?: ResolverInputTypes["BannerSection_banners__name_operator"] | undefined | null,
	banners__status?: ResolverInputTypes["BannerSection_banners__status_operator"] | undefined | null,
	banners__desktop?: ResolverInputTypes["BannerSection_banners__desktop_operator"] | undefined | null,
	banners__bannerStartDate?: ResolverInputTypes["BannerSection_banners__bannerStartDate_operator"] | undefined | null,
	banners__bannerEndDate?: ResolverInputTypes["BannerSection_banners__bannerEndDate_operator"] | undefined | null,
	banners__region?: ResolverInputTypes["BannerSection_banners__region_operator"] | undefined | null,
	banners__id?: ResolverInputTypes["BannerSection_banners__id_operator"] | undefined | null,
	updatedAt?: ResolverInputTypes["BannerSection_updatedAt_operator"] | undefined | null,
	createdAt?: ResolverInputTypes["BannerSection_createdAt_operator"] | undefined | null,
	id?: ResolverInputTypes["BannerSection_id_operator"] | undefined | null,
	AND?: Array<ResolverInputTypes["BannerSection_where_and"] | undefined | null> | undefined | null,
	OR?: Array<ResolverInputTypes["BannerSection_where_or"] | undefined | null> | undefined | null
};
	["BannerSection_where_or"]: {
	name?: ResolverInputTypes["BannerSection_name_operator"] | undefined | null,
	slug?: ResolverInputTypes["BannerSection_slug_operator"] | undefined | null,
	banners__name?: ResolverInputTypes["BannerSection_banners__name_operator"] | undefined | null,
	banners__status?: ResolverInputTypes["BannerSection_banners__status_operator"] | undefined | null,
	banners__desktop?: ResolverInputTypes["BannerSection_banners__desktop_operator"] | undefined | null,
	banners__bannerStartDate?: ResolverInputTypes["BannerSection_banners__bannerStartDate_operator"] | undefined | null,
	banners__bannerEndDate?: ResolverInputTypes["BannerSection_banners__bannerEndDate_operator"] | undefined | null,
	banners__region?: ResolverInputTypes["BannerSection_banners__region_operator"] | undefined | null,
	banners__id?: ResolverInputTypes["BannerSection_banners__id_operator"] | undefined | null,
	updatedAt?: ResolverInputTypes["BannerSection_updatedAt_operator"] | undefined | null,
	createdAt?: ResolverInputTypes["BannerSection_createdAt_operator"] | undefined | null,
	id?: ResolverInputTypes["BannerSection_id_operator"] | undefined | null,
	AND?: Array<ResolverInputTypes["BannerSection_where_and"] | undefined | null> | undefined | null,
	OR?: Array<ResolverInputTypes["BannerSection_where_or"] | undefined | null> | undefined | null
};
	["countBannerSections"]: AliasType<{
	totalDocs?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["banner_sectionDocAccess"]: AliasType<{
	fields?:ResolverInputTypes["BannerSectionDocAccessFields"],
	create?:ResolverInputTypes["BannerSectionCreateDocAccess"],
	read?:ResolverInputTypes["BannerSectionReadDocAccess"],
	update?:ResolverInputTypes["BannerSectionUpdateDocAccess"],
	delete?:ResolverInputTypes["BannerSectionDeleteDocAccess"],
		__typename?: boolean | `@${string}`
}>;
	["BannerSectionDocAccessFields"]: AliasType<{
	name?:ResolverInputTypes["BannerSectionDocAccessFields_name"],
	slug?:ResolverInputTypes["BannerSectionDocAccessFields_slug"],
	banners?:ResolverInputTypes["BannerSectionDocAccessFields_banners"],
	updatedAt?:ResolverInputTypes["BannerSectionDocAccessFields_updatedAt"],
	createdAt?:ResolverInputTypes["BannerSectionDocAccessFields_createdAt"],
		__typename?: boolean | `@${string}`
}>;
	["BannerSectionDocAccessFields_name"]: AliasType<{
	create?:ResolverInputTypes["BannerSectionDocAccessFields_name_Create"],
	read?:ResolverInputTypes["BannerSectionDocAccessFields_name_Read"],
	update?:ResolverInputTypes["BannerSectionDocAccessFields_name_Update"],
	delete?:ResolverInputTypes["BannerSectionDocAccessFields_name_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["BannerSectionDocAccessFields_name_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannerSectionDocAccessFields_name_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannerSectionDocAccessFields_name_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannerSectionDocAccessFields_name_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannerSectionDocAccessFields_slug"]: AliasType<{
	create?:ResolverInputTypes["BannerSectionDocAccessFields_slug_Create"],
	read?:ResolverInputTypes["BannerSectionDocAccessFields_slug_Read"],
	update?:ResolverInputTypes["BannerSectionDocAccessFields_slug_Update"],
	delete?:ResolverInputTypes["BannerSectionDocAccessFields_slug_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["BannerSectionDocAccessFields_slug_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannerSectionDocAccessFields_slug_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannerSectionDocAccessFields_slug_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannerSectionDocAccessFields_slug_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannerSectionDocAccessFields_banners"]: AliasType<{
	create?:ResolverInputTypes["BannerSectionDocAccessFields_banners_Create"],
	read?:ResolverInputTypes["BannerSectionDocAccessFields_banners_Read"],
	update?:ResolverInputTypes["BannerSectionDocAccessFields_banners_Update"],
	delete?:ResolverInputTypes["BannerSectionDocAccessFields_banners_Delete"],
	fields?:ResolverInputTypes["BannerSectionDocAccessFields_banners_Fields"],
		__typename?: boolean | `@${string}`
}>;
	["BannerSectionDocAccessFields_banners_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannerSectionDocAccessFields_banners_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannerSectionDocAccessFields_banners_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannerSectionDocAccessFields_banners_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannerSectionDocAccessFields_banners_Fields"]: AliasType<{
	name?:ResolverInputTypes["BannerSectionDocAccessFields_banners_name"],
	status?:ResolverInputTypes["BannerSectionDocAccessFields_banners_status"],
	desktop?:ResolverInputTypes["BannerSectionDocAccessFields_banners_desktop"],
	bannerStartDate?:ResolverInputTypes["BannerSectionDocAccessFields_banners_bannerStartDate"],
	bannerEndDate?:ResolverInputTypes["BannerSectionDocAccessFields_banners_bannerEndDate"],
	region?:ResolverInputTypes["BannerSectionDocAccessFields_banners_region"],
	id?:ResolverInputTypes["BannerSectionDocAccessFields_banners_id"],
		__typename?: boolean | `@${string}`
}>;
	["BannerSectionDocAccessFields_banners_name"]: AliasType<{
	create?:ResolverInputTypes["BannerSectionDocAccessFields_banners_name_Create"],
	read?:ResolverInputTypes["BannerSectionDocAccessFields_banners_name_Read"],
	update?:ResolverInputTypes["BannerSectionDocAccessFields_banners_name_Update"],
	delete?:ResolverInputTypes["BannerSectionDocAccessFields_banners_name_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["BannerSectionDocAccessFields_banners_name_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannerSectionDocAccessFields_banners_name_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannerSectionDocAccessFields_banners_name_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannerSectionDocAccessFields_banners_name_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannerSectionDocAccessFields_banners_status"]: AliasType<{
	create?:ResolverInputTypes["BannerSectionDocAccessFields_banners_status_Create"],
	read?:ResolverInputTypes["BannerSectionDocAccessFields_banners_status_Read"],
	update?:ResolverInputTypes["BannerSectionDocAccessFields_banners_status_Update"],
	delete?:ResolverInputTypes["BannerSectionDocAccessFields_banners_status_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["BannerSectionDocAccessFields_banners_status_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannerSectionDocAccessFields_banners_status_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannerSectionDocAccessFields_banners_status_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannerSectionDocAccessFields_banners_status_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannerSectionDocAccessFields_banners_desktop"]: AliasType<{
	create?:ResolverInputTypes["BannerSectionDocAccessFields_banners_desktop_Create"],
	read?:ResolverInputTypes["BannerSectionDocAccessFields_banners_desktop_Read"],
	update?:ResolverInputTypes["BannerSectionDocAccessFields_banners_desktop_Update"],
	delete?:ResolverInputTypes["BannerSectionDocAccessFields_banners_desktop_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["BannerSectionDocAccessFields_banners_desktop_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannerSectionDocAccessFields_banners_desktop_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannerSectionDocAccessFields_banners_desktop_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannerSectionDocAccessFields_banners_desktop_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannerSectionDocAccessFields_banners_bannerStartDate"]: AliasType<{
	create?:ResolverInputTypes["BannerSectionDocAccessFields_banners_bannerStartDate_Create"],
	read?:ResolverInputTypes["BannerSectionDocAccessFields_banners_bannerStartDate_Read"],
	update?:ResolverInputTypes["BannerSectionDocAccessFields_banners_bannerStartDate_Update"],
	delete?:ResolverInputTypes["BannerSectionDocAccessFields_banners_bannerStartDate_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["BannerSectionDocAccessFields_banners_bannerStartDate_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannerSectionDocAccessFields_banners_bannerStartDate_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannerSectionDocAccessFields_banners_bannerStartDate_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannerSectionDocAccessFields_banners_bannerStartDate_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannerSectionDocAccessFields_banners_bannerEndDate"]: AliasType<{
	create?:ResolverInputTypes["BannerSectionDocAccessFields_banners_bannerEndDate_Create"],
	read?:ResolverInputTypes["BannerSectionDocAccessFields_banners_bannerEndDate_Read"],
	update?:ResolverInputTypes["BannerSectionDocAccessFields_banners_bannerEndDate_Update"],
	delete?:ResolverInputTypes["BannerSectionDocAccessFields_banners_bannerEndDate_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["BannerSectionDocAccessFields_banners_bannerEndDate_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannerSectionDocAccessFields_banners_bannerEndDate_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannerSectionDocAccessFields_banners_bannerEndDate_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannerSectionDocAccessFields_banners_bannerEndDate_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannerSectionDocAccessFields_banners_region"]: AliasType<{
	create?:ResolverInputTypes["BannerSectionDocAccessFields_banners_region_Create"],
	read?:ResolverInputTypes["BannerSectionDocAccessFields_banners_region_Read"],
	update?:ResolverInputTypes["BannerSectionDocAccessFields_banners_region_Update"],
	delete?:ResolverInputTypes["BannerSectionDocAccessFields_banners_region_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["BannerSectionDocAccessFields_banners_region_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannerSectionDocAccessFields_banners_region_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannerSectionDocAccessFields_banners_region_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannerSectionDocAccessFields_banners_region_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannerSectionDocAccessFields_banners_id"]: AliasType<{
	create?:ResolverInputTypes["BannerSectionDocAccessFields_banners_id_Create"],
	read?:ResolverInputTypes["BannerSectionDocAccessFields_banners_id_Read"],
	update?:ResolverInputTypes["BannerSectionDocAccessFields_banners_id_Update"],
	delete?:ResolverInputTypes["BannerSectionDocAccessFields_banners_id_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["BannerSectionDocAccessFields_banners_id_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannerSectionDocAccessFields_banners_id_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannerSectionDocAccessFields_banners_id_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannerSectionDocAccessFields_banners_id_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannerSectionDocAccessFields_updatedAt"]: AliasType<{
	create?:ResolverInputTypes["BannerSectionDocAccessFields_updatedAt_Create"],
	read?:ResolverInputTypes["BannerSectionDocAccessFields_updatedAt_Read"],
	update?:ResolverInputTypes["BannerSectionDocAccessFields_updatedAt_Update"],
	delete?:ResolverInputTypes["BannerSectionDocAccessFields_updatedAt_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["BannerSectionDocAccessFields_updatedAt_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannerSectionDocAccessFields_updatedAt_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannerSectionDocAccessFields_updatedAt_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannerSectionDocAccessFields_updatedAt_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannerSectionDocAccessFields_createdAt"]: AliasType<{
	create?:ResolverInputTypes["BannerSectionDocAccessFields_createdAt_Create"],
	read?:ResolverInputTypes["BannerSectionDocAccessFields_createdAt_Read"],
	update?:ResolverInputTypes["BannerSectionDocAccessFields_createdAt_Update"],
	delete?:ResolverInputTypes["BannerSectionDocAccessFields_createdAt_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["BannerSectionDocAccessFields_createdAt_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannerSectionDocAccessFields_createdAt_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannerSectionDocAccessFields_createdAt_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannerSectionDocAccessFields_createdAt_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannerSectionCreateDocAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannerSectionReadDocAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannerSectionUpdateDocAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannerSectionDeleteDocAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MacaoBannerSection"]: AliasType<{
	id?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	slug?:boolean | `@${string}`,
	banners?:ResolverInputTypes["MacaoBannerSection_Banners"],
	updatedAt?:boolean | `@${string}`,
	createdAt?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MacaoBannerSection_Banners"]: AliasType<{
	name?:boolean | `@${string}`,
	status?:boolean | `@${string}`,
desktop?: [{	locale?: ResolverInputTypes["LocaleInputType"] | undefined | null,	fallbackLocale?: ResolverInputTypes["FallbackLocaleInputType"] | undefined | null,	where?: ResolverInputTypes["MacaoBannerSection_Banners_Desktop_where"] | undefined | null},ResolverInputTypes["Media"]],
	bannerStartDate?:boolean | `@${string}`,
	bannerEndDate?:boolean | `@${string}`,
	region?:boolean | `@${string}`,
	id?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MacaoBannerSection_Banners_Desktop_where"]: {
	alt?: ResolverInputTypes["MacaoBannerSection_Banners_Desktop_alt_operator"] | undefined | null,
	updatedAt?: ResolverInputTypes["MacaoBannerSection_Banners_Desktop_updatedAt_operator"] | undefined | null,
	createdAt?: ResolverInputTypes["MacaoBannerSection_Banners_Desktop_createdAt_operator"] | undefined | null,
	url?: ResolverInputTypes["MacaoBannerSection_Banners_Desktop_url_operator"] | undefined | null,
	filename?: ResolverInputTypes["MacaoBannerSection_Banners_Desktop_filename_operator"] | undefined | null,
	mimeType?: ResolverInputTypes["MacaoBannerSection_Banners_Desktop_mimeType_operator"] | undefined | null,
	filesize?: ResolverInputTypes["MacaoBannerSection_Banners_Desktop_filesize_operator"] | undefined | null,
	width?: ResolverInputTypes["MacaoBannerSection_Banners_Desktop_width_operator"] | undefined | null,
	height?: ResolverInputTypes["MacaoBannerSection_Banners_Desktop_height_operator"] | undefined | null,
	focalX?: ResolverInputTypes["MacaoBannerSection_Banners_Desktop_focalX_operator"] | undefined | null,
	focalY?: ResolverInputTypes["MacaoBannerSection_Banners_Desktop_focalY_operator"] | undefined | null,
	sizes__small__url?: ResolverInputTypes["MacaoBannerSection_Banners_Desktop_sizes__small__url_operator"] | undefined | null,
	sizes__small__width?: ResolverInputTypes["MacaoBannerSection_Banners_Desktop_sizes__small__width_operator"] | undefined | null,
	sizes__small__height?: ResolverInputTypes["MacaoBannerSection_Banners_Desktop_sizes__small__height_operator"] | undefined | null,
	sizes__small__mimeType?: ResolverInputTypes["MacaoBannerSection_Banners_Desktop_sizes__small__mimeType_operator"] | undefined | null,
	sizes__small__filesize?: ResolverInputTypes["MacaoBannerSection_Banners_Desktop_sizes__small__filesize_operator"] | undefined | null,
	sizes__small__filename?: ResolverInputTypes["MacaoBannerSection_Banners_Desktop_sizes__small__filename_operator"] | undefined | null,
	sizes__medium__url?: ResolverInputTypes["MacaoBannerSection_Banners_Desktop_sizes__medium__url_operator"] | undefined | null,
	sizes__medium__width?: ResolverInputTypes["MacaoBannerSection_Banners_Desktop_sizes__medium__width_operator"] | undefined | null,
	sizes__medium__height?: ResolverInputTypes["MacaoBannerSection_Banners_Desktop_sizes__medium__height_operator"] | undefined | null,
	sizes__medium__mimeType?: ResolverInputTypes["MacaoBannerSection_Banners_Desktop_sizes__medium__mimeType_operator"] | undefined | null,
	sizes__medium__filesize?: ResolverInputTypes["MacaoBannerSection_Banners_Desktop_sizes__medium__filesize_operator"] | undefined | null,
	sizes__medium__filename?: ResolverInputTypes["MacaoBannerSection_Banners_Desktop_sizes__medium__filename_operator"] | undefined | null,
	sizes__large__url?: ResolverInputTypes["MacaoBannerSection_Banners_Desktop_sizes__large__url_operator"] | undefined | null,
	sizes__large__width?: ResolverInputTypes["MacaoBannerSection_Banners_Desktop_sizes__large__width_operator"] | undefined | null,
	sizes__large__height?: ResolverInputTypes["MacaoBannerSection_Banners_Desktop_sizes__large__height_operator"] | undefined | null,
	sizes__large__mimeType?: ResolverInputTypes["MacaoBannerSection_Banners_Desktop_sizes__large__mimeType_operator"] | undefined | null,
	sizes__large__filesize?: ResolverInputTypes["MacaoBannerSection_Banners_Desktop_sizes__large__filesize_operator"] | undefined | null,
	sizes__large__filename?: ResolverInputTypes["MacaoBannerSection_Banners_Desktop_sizes__large__filename_operator"] | undefined | null,
	sizes__small_jpeg__url?: ResolverInputTypes["MacaoBannerSection_Banners_Desktop_sizes__small_jpeg__url_operator"] | undefined | null,
	sizes__small_jpeg__width?: ResolverInputTypes["MacaoBannerSection_Banners_Desktop_sizes__small_jpeg__width_operator"] | undefined | null,
	sizes__small_jpeg__height?: ResolverInputTypes["MacaoBannerSection_Banners_Desktop_sizes__small_jpeg__height_operator"] | undefined | null,
	sizes__small_jpeg__mimeType?: ResolverInputTypes["MacaoBannerSection_Banners_Desktop_sizes__small_jpeg__mimeType_operator"] | undefined | null,
	sizes__small_jpeg__filesize?: ResolverInputTypes["MacaoBannerSection_Banners_Desktop_sizes__small_jpeg__filesize_operator"] | undefined | null,
	sizes__small_jpeg__filename?: ResolverInputTypes["MacaoBannerSection_Banners_Desktop_sizes__small_jpeg__filename_operator"] | undefined | null,
	sizes__medium_jpeg__url?: ResolverInputTypes["MacaoBannerSection_Banners_Desktop_sizes__medium_jpeg__url_operator"] | undefined | null,
	sizes__medium_jpeg__width?: ResolverInputTypes["MacaoBannerSection_Banners_Desktop_sizes__medium_jpeg__width_operator"] | undefined | null,
	sizes__medium_jpeg__height?: ResolverInputTypes["MacaoBannerSection_Banners_Desktop_sizes__medium_jpeg__height_operator"] | undefined | null,
	sizes__medium_jpeg__mimeType?: ResolverInputTypes["MacaoBannerSection_Banners_Desktop_sizes__medium_jpeg__mimeType_operator"] | undefined | null,
	sizes__medium_jpeg__filesize?: ResolverInputTypes["MacaoBannerSection_Banners_Desktop_sizes__medium_jpeg__filesize_operator"] | undefined | null,
	sizes__medium_jpeg__filename?: ResolverInputTypes["MacaoBannerSection_Banners_Desktop_sizes__medium_jpeg__filename_operator"] | undefined | null,
	sizes__large_jpeg__url?: ResolverInputTypes["MacaoBannerSection_Banners_Desktop_sizes__large_jpeg__url_operator"] | undefined | null,
	sizes__large_jpeg__width?: ResolverInputTypes["MacaoBannerSection_Banners_Desktop_sizes__large_jpeg__width_operator"] | undefined | null,
	sizes__large_jpeg__height?: ResolverInputTypes["MacaoBannerSection_Banners_Desktop_sizes__large_jpeg__height_operator"] | undefined | null,
	sizes__large_jpeg__mimeType?: ResolverInputTypes["MacaoBannerSection_Banners_Desktop_sizes__large_jpeg__mimeType_operator"] | undefined | null,
	sizes__large_jpeg__filesize?: ResolverInputTypes["MacaoBannerSection_Banners_Desktop_sizes__large_jpeg__filesize_operator"] | undefined | null,
	sizes__large_jpeg__filename?: ResolverInputTypes["MacaoBannerSection_Banners_Desktop_sizes__large_jpeg__filename_operator"] | undefined | null,
	id?: ResolverInputTypes["MacaoBannerSection_Banners_Desktop_id_operator"] | undefined | null,
	AND?: Array<ResolverInputTypes["MacaoBannerSection_Banners_Desktop_where_and"] | undefined | null> | undefined | null,
	OR?: Array<ResolverInputTypes["MacaoBannerSection_Banners_Desktop_where_or"] | undefined | null> | undefined | null
};
	["MacaoBannerSection_Banners_Desktop_alt_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["MacaoBannerSection_Banners_Desktop_updatedAt_operator"]: {
	equals?: ResolverInputTypes["DateTime"] | undefined | null,
	not_equals?: ResolverInputTypes["DateTime"] | undefined | null,
	greater_than_equal?: ResolverInputTypes["DateTime"] | undefined | null,
	greater_than?: ResolverInputTypes["DateTime"] | undefined | null,
	less_than_equal?: ResolverInputTypes["DateTime"] | undefined | null,
	less_than?: ResolverInputTypes["DateTime"] | undefined | null,
	like?: ResolverInputTypes["DateTime"] | undefined | null,
	exists?: boolean | undefined | null
};
	["MacaoBannerSection_Banners_Desktop_createdAt_operator"]: {
	equals?: ResolverInputTypes["DateTime"] | undefined | null,
	not_equals?: ResolverInputTypes["DateTime"] | undefined | null,
	greater_than_equal?: ResolverInputTypes["DateTime"] | undefined | null,
	greater_than?: ResolverInputTypes["DateTime"] | undefined | null,
	less_than_equal?: ResolverInputTypes["DateTime"] | undefined | null,
	less_than?: ResolverInputTypes["DateTime"] | undefined | null,
	like?: ResolverInputTypes["DateTime"] | undefined | null,
	exists?: boolean | undefined | null
};
	["MacaoBannerSection_Banners_Desktop_url_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["MacaoBannerSection_Banners_Desktop_filename_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["MacaoBannerSection_Banners_Desktop_mimeType_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["MacaoBannerSection_Banners_Desktop_filesize_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["MacaoBannerSection_Banners_Desktop_width_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["MacaoBannerSection_Banners_Desktop_height_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["MacaoBannerSection_Banners_Desktop_focalX_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["MacaoBannerSection_Banners_Desktop_focalY_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["MacaoBannerSection_Banners_Desktop_sizes__small__url_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["MacaoBannerSection_Banners_Desktop_sizes__small__width_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["MacaoBannerSection_Banners_Desktop_sizes__small__height_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["MacaoBannerSection_Banners_Desktop_sizes__small__mimeType_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["MacaoBannerSection_Banners_Desktop_sizes__small__filesize_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["MacaoBannerSection_Banners_Desktop_sizes__small__filename_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["MacaoBannerSection_Banners_Desktop_sizes__medium__url_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["MacaoBannerSection_Banners_Desktop_sizes__medium__width_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["MacaoBannerSection_Banners_Desktop_sizes__medium__height_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["MacaoBannerSection_Banners_Desktop_sizes__medium__mimeType_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["MacaoBannerSection_Banners_Desktop_sizes__medium__filesize_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["MacaoBannerSection_Banners_Desktop_sizes__medium__filename_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["MacaoBannerSection_Banners_Desktop_sizes__large__url_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["MacaoBannerSection_Banners_Desktop_sizes__large__width_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["MacaoBannerSection_Banners_Desktop_sizes__large__height_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["MacaoBannerSection_Banners_Desktop_sizes__large__mimeType_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["MacaoBannerSection_Banners_Desktop_sizes__large__filesize_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["MacaoBannerSection_Banners_Desktop_sizes__large__filename_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["MacaoBannerSection_Banners_Desktop_sizes__small_jpeg__url_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["MacaoBannerSection_Banners_Desktop_sizes__small_jpeg__width_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["MacaoBannerSection_Banners_Desktop_sizes__small_jpeg__height_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["MacaoBannerSection_Banners_Desktop_sizes__small_jpeg__mimeType_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["MacaoBannerSection_Banners_Desktop_sizes__small_jpeg__filesize_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["MacaoBannerSection_Banners_Desktop_sizes__small_jpeg__filename_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["MacaoBannerSection_Banners_Desktop_sizes__medium_jpeg__url_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["MacaoBannerSection_Banners_Desktop_sizes__medium_jpeg__width_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["MacaoBannerSection_Banners_Desktop_sizes__medium_jpeg__height_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["MacaoBannerSection_Banners_Desktop_sizes__medium_jpeg__mimeType_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["MacaoBannerSection_Banners_Desktop_sizes__medium_jpeg__filesize_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["MacaoBannerSection_Banners_Desktop_sizes__medium_jpeg__filename_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["MacaoBannerSection_Banners_Desktop_sizes__large_jpeg__url_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["MacaoBannerSection_Banners_Desktop_sizes__large_jpeg__width_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["MacaoBannerSection_Banners_Desktop_sizes__large_jpeg__height_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["MacaoBannerSection_Banners_Desktop_sizes__large_jpeg__mimeType_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["MacaoBannerSection_Banners_Desktop_sizes__large_jpeg__filesize_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["MacaoBannerSection_Banners_Desktop_sizes__large_jpeg__filename_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["MacaoBannerSection_Banners_Desktop_id_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["MacaoBannerSection_Banners_Desktop_where_and"]: {
	alt?: ResolverInputTypes["MacaoBannerSection_Banners_Desktop_alt_operator"] | undefined | null,
	updatedAt?: ResolverInputTypes["MacaoBannerSection_Banners_Desktop_updatedAt_operator"] | undefined | null,
	createdAt?: ResolverInputTypes["MacaoBannerSection_Banners_Desktop_createdAt_operator"] | undefined | null,
	url?: ResolverInputTypes["MacaoBannerSection_Banners_Desktop_url_operator"] | undefined | null,
	filename?: ResolverInputTypes["MacaoBannerSection_Banners_Desktop_filename_operator"] | undefined | null,
	mimeType?: ResolverInputTypes["MacaoBannerSection_Banners_Desktop_mimeType_operator"] | undefined | null,
	filesize?: ResolverInputTypes["MacaoBannerSection_Banners_Desktop_filesize_operator"] | undefined | null,
	width?: ResolverInputTypes["MacaoBannerSection_Banners_Desktop_width_operator"] | undefined | null,
	height?: ResolverInputTypes["MacaoBannerSection_Banners_Desktop_height_operator"] | undefined | null,
	focalX?: ResolverInputTypes["MacaoBannerSection_Banners_Desktop_focalX_operator"] | undefined | null,
	focalY?: ResolverInputTypes["MacaoBannerSection_Banners_Desktop_focalY_operator"] | undefined | null,
	sizes__small__url?: ResolverInputTypes["MacaoBannerSection_Banners_Desktop_sizes__small__url_operator"] | undefined | null,
	sizes__small__width?: ResolverInputTypes["MacaoBannerSection_Banners_Desktop_sizes__small__width_operator"] | undefined | null,
	sizes__small__height?: ResolverInputTypes["MacaoBannerSection_Banners_Desktop_sizes__small__height_operator"] | undefined | null,
	sizes__small__mimeType?: ResolverInputTypes["MacaoBannerSection_Banners_Desktop_sizes__small__mimeType_operator"] | undefined | null,
	sizes__small__filesize?: ResolverInputTypes["MacaoBannerSection_Banners_Desktop_sizes__small__filesize_operator"] | undefined | null,
	sizes__small__filename?: ResolverInputTypes["MacaoBannerSection_Banners_Desktop_sizes__small__filename_operator"] | undefined | null,
	sizes__medium__url?: ResolverInputTypes["MacaoBannerSection_Banners_Desktop_sizes__medium__url_operator"] | undefined | null,
	sizes__medium__width?: ResolverInputTypes["MacaoBannerSection_Banners_Desktop_sizes__medium__width_operator"] | undefined | null,
	sizes__medium__height?: ResolverInputTypes["MacaoBannerSection_Banners_Desktop_sizes__medium__height_operator"] | undefined | null,
	sizes__medium__mimeType?: ResolverInputTypes["MacaoBannerSection_Banners_Desktop_sizes__medium__mimeType_operator"] | undefined | null,
	sizes__medium__filesize?: ResolverInputTypes["MacaoBannerSection_Banners_Desktop_sizes__medium__filesize_operator"] | undefined | null,
	sizes__medium__filename?: ResolverInputTypes["MacaoBannerSection_Banners_Desktop_sizes__medium__filename_operator"] | undefined | null,
	sizes__large__url?: ResolverInputTypes["MacaoBannerSection_Banners_Desktop_sizes__large__url_operator"] | undefined | null,
	sizes__large__width?: ResolverInputTypes["MacaoBannerSection_Banners_Desktop_sizes__large__width_operator"] | undefined | null,
	sizes__large__height?: ResolverInputTypes["MacaoBannerSection_Banners_Desktop_sizes__large__height_operator"] | undefined | null,
	sizes__large__mimeType?: ResolverInputTypes["MacaoBannerSection_Banners_Desktop_sizes__large__mimeType_operator"] | undefined | null,
	sizes__large__filesize?: ResolverInputTypes["MacaoBannerSection_Banners_Desktop_sizes__large__filesize_operator"] | undefined | null,
	sizes__large__filename?: ResolverInputTypes["MacaoBannerSection_Banners_Desktop_sizes__large__filename_operator"] | undefined | null,
	sizes__small_jpeg__url?: ResolverInputTypes["MacaoBannerSection_Banners_Desktop_sizes__small_jpeg__url_operator"] | undefined | null,
	sizes__small_jpeg__width?: ResolverInputTypes["MacaoBannerSection_Banners_Desktop_sizes__small_jpeg__width_operator"] | undefined | null,
	sizes__small_jpeg__height?: ResolverInputTypes["MacaoBannerSection_Banners_Desktop_sizes__small_jpeg__height_operator"] | undefined | null,
	sizes__small_jpeg__mimeType?: ResolverInputTypes["MacaoBannerSection_Banners_Desktop_sizes__small_jpeg__mimeType_operator"] | undefined | null,
	sizes__small_jpeg__filesize?: ResolverInputTypes["MacaoBannerSection_Banners_Desktop_sizes__small_jpeg__filesize_operator"] | undefined | null,
	sizes__small_jpeg__filename?: ResolverInputTypes["MacaoBannerSection_Banners_Desktop_sizes__small_jpeg__filename_operator"] | undefined | null,
	sizes__medium_jpeg__url?: ResolverInputTypes["MacaoBannerSection_Banners_Desktop_sizes__medium_jpeg__url_operator"] | undefined | null,
	sizes__medium_jpeg__width?: ResolverInputTypes["MacaoBannerSection_Banners_Desktop_sizes__medium_jpeg__width_operator"] | undefined | null,
	sizes__medium_jpeg__height?: ResolverInputTypes["MacaoBannerSection_Banners_Desktop_sizes__medium_jpeg__height_operator"] | undefined | null,
	sizes__medium_jpeg__mimeType?: ResolverInputTypes["MacaoBannerSection_Banners_Desktop_sizes__medium_jpeg__mimeType_operator"] | undefined | null,
	sizes__medium_jpeg__filesize?: ResolverInputTypes["MacaoBannerSection_Banners_Desktop_sizes__medium_jpeg__filesize_operator"] | undefined | null,
	sizes__medium_jpeg__filename?: ResolverInputTypes["MacaoBannerSection_Banners_Desktop_sizes__medium_jpeg__filename_operator"] | undefined | null,
	sizes__large_jpeg__url?: ResolverInputTypes["MacaoBannerSection_Banners_Desktop_sizes__large_jpeg__url_operator"] | undefined | null,
	sizes__large_jpeg__width?: ResolverInputTypes["MacaoBannerSection_Banners_Desktop_sizes__large_jpeg__width_operator"] | undefined | null,
	sizes__large_jpeg__height?: ResolverInputTypes["MacaoBannerSection_Banners_Desktop_sizes__large_jpeg__height_operator"] | undefined | null,
	sizes__large_jpeg__mimeType?: ResolverInputTypes["MacaoBannerSection_Banners_Desktop_sizes__large_jpeg__mimeType_operator"] | undefined | null,
	sizes__large_jpeg__filesize?: ResolverInputTypes["MacaoBannerSection_Banners_Desktop_sizes__large_jpeg__filesize_operator"] | undefined | null,
	sizes__large_jpeg__filename?: ResolverInputTypes["MacaoBannerSection_Banners_Desktop_sizes__large_jpeg__filename_operator"] | undefined | null,
	id?: ResolverInputTypes["MacaoBannerSection_Banners_Desktop_id_operator"] | undefined | null,
	AND?: Array<ResolverInputTypes["MacaoBannerSection_Banners_Desktop_where_and"] | undefined | null> | undefined | null,
	OR?: Array<ResolverInputTypes["MacaoBannerSection_Banners_Desktop_where_or"] | undefined | null> | undefined | null
};
	["MacaoBannerSection_Banners_Desktop_where_or"]: {
	alt?: ResolverInputTypes["MacaoBannerSection_Banners_Desktop_alt_operator"] | undefined | null,
	updatedAt?: ResolverInputTypes["MacaoBannerSection_Banners_Desktop_updatedAt_operator"] | undefined | null,
	createdAt?: ResolverInputTypes["MacaoBannerSection_Banners_Desktop_createdAt_operator"] | undefined | null,
	url?: ResolverInputTypes["MacaoBannerSection_Banners_Desktop_url_operator"] | undefined | null,
	filename?: ResolverInputTypes["MacaoBannerSection_Banners_Desktop_filename_operator"] | undefined | null,
	mimeType?: ResolverInputTypes["MacaoBannerSection_Banners_Desktop_mimeType_operator"] | undefined | null,
	filesize?: ResolverInputTypes["MacaoBannerSection_Banners_Desktop_filesize_operator"] | undefined | null,
	width?: ResolverInputTypes["MacaoBannerSection_Banners_Desktop_width_operator"] | undefined | null,
	height?: ResolverInputTypes["MacaoBannerSection_Banners_Desktop_height_operator"] | undefined | null,
	focalX?: ResolverInputTypes["MacaoBannerSection_Banners_Desktop_focalX_operator"] | undefined | null,
	focalY?: ResolverInputTypes["MacaoBannerSection_Banners_Desktop_focalY_operator"] | undefined | null,
	sizes__small__url?: ResolverInputTypes["MacaoBannerSection_Banners_Desktop_sizes__small__url_operator"] | undefined | null,
	sizes__small__width?: ResolverInputTypes["MacaoBannerSection_Banners_Desktop_sizes__small__width_operator"] | undefined | null,
	sizes__small__height?: ResolverInputTypes["MacaoBannerSection_Banners_Desktop_sizes__small__height_operator"] | undefined | null,
	sizes__small__mimeType?: ResolverInputTypes["MacaoBannerSection_Banners_Desktop_sizes__small__mimeType_operator"] | undefined | null,
	sizes__small__filesize?: ResolverInputTypes["MacaoBannerSection_Banners_Desktop_sizes__small__filesize_operator"] | undefined | null,
	sizes__small__filename?: ResolverInputTypes["MacaoBannerSection_Banners_Desktop_sizes__small__filename_operator"] | undefined | null,
	sizes__medium__url?: ResolverInputTypes["MacaoBannerSection_Banners_Desktop_sizes__medium__url_operator"] | undefined | null,
	sizes__medium__width?: ResolverInputTypes["MacaoBannerSection_Banners_Desktop_sizes__medium__width_operator"] | undefined | null,
	sizes__medium__height?: ResolverInputTypes["MacaoBannerSection_Banners_Desktop_sizes__medium__height_operator"] | undefined | null,
	sizes__medium__mimeType?: ResolverInputTypes["MacaoBannerSection_Banners_Desktop_sizes__medium__mimeType_operator"] | undefined | null,
	sizes__medium__filesize?: ResolverInputTypes["MacaoBannerSection_Banners_Desktop_sizes__medium__filesize_operator"] | undefined | null,
	sizes__medium__filename?: ResolverInputTypes["MacaoBannerSection_Banners_Desktop_sizes__medium__filename_operator"] | undefined | null,
	sizes__large__url?: ResolverInputTypes["MacaoBannerSection_Banners_Desktop_sizes__large__url_operator"] | undefined | null,
	sizes__large__width?: ResolverInputTypes["MacaoBannerSection_Banners_Desktop_sizes__large__width_operator"] | undefined | null,
	sizes__large__height?: ResolverInputTypes["MacaoBannerSection_Banners_Desktop_sizes__large__height_operator"] | undefined | null,
	sizes__large__mimeType?: ResolverInputTypes["MacaoBannerSection_Banners_Desktop_sizes__large__mimeType_operator"] | undefined | null,
	sizes__large__filesize?: ResolverInputTypes["MacaoBannerSection_Banners_Desktop_sizes__large__filesize_operator"] | undefined | null,
	sizes__large__filename?: ResolverInputTypes["MacaoBannerSection_Banners_Desktop_sizes__large__filename_operator"] | undefined | null,
	sizes__small_jpeg__url?: ResolverInputTypes["MacaoBannerSection_Banners_Desktop_sizes__small_jpeg__url_operator"] | undefined | null,
	sizes__small_jpeg__width?: ResolverInputTypes["MacaoBannerSection_Banners_Desktop_sizes__small_jpeg__width_operator"] | undefined | null,
	sizes__small_jpeg__height?: ResolverInputTypes["MacaoBannerSection_Banners_Desktop_sizes__small_jpeg__height_operator"] | undefined | null,
	sizes__small_jpeg__mimeType?: ResolverInputTypes["MacaoBannerSection_Banners_Desktop_sizes__small_jpeg__mimeType_operator"] | undefined | null,
	sizes__small_jpeg__filesize?: ResolverInputTypes["MacaoBannerSection_Banners_Desktop_sizes__small_jpeg__filesize_operator"] | undefined | null,
	sizes__small_jpeg__filename?: ResolverInputTypes["MacaoBannerSection_Banners_Desktop_sizes__small_jpeg__filename_operator"] | undefined | null,
	sizes__medium_jpeg__url?: ResolverInputTypes["MacaoBannerSection_Banners_Desktop_sizes__medium_jpeg__url_operator"] | undefined | null,
	sizes__medium_jpeg__width?: ResolverInputTypes["MacaoBannerSection_Banners_Desktop_sizes__medium_jpeg__width_operator"] | undefined | null,
	sizes__medium_jpeg__height?: ResolverInputTypes["MacaoBannerSection_Banners_Desktop_sizes__medium_jpeg__height_operator"] | undefined | null,
	sizes__medium_jpeg__mimeType?: ResolverInputTypes["MacaoBannerSection_Banners_Desktop_sizes__medium_jpeg__mimeType_operator"] | undefined | null,
	sizes__medium_jpeg__filesize?: ResolverInputTypes["MacaoBannerSection_Banners_Desktop_sizes__medium_jpeg__filesize_operator"] | undefined | null,
	sizes__medium_jpeg__filename?: ResolverInputTypes["MacaoBannerSection_Banners_Desktop_sizes__medium_jpeg__filename_operator"] | undefined | null,
	sizes__large_jpeg__url?: ResolverInputTypes["MacaoBannerSection_Banners_Desktop_sizes__large_jpeg__url_operator"] | undefined | null,
	sizes__large_jpeg__width?: ResolverInputTypes["MacaoBannerSection_Banners_Desktop_sizes__large_jpeg__width_operator"] | undefined | null,
	sizes__large_jpeg__height?: ResolverInputTypes["MacaoBannerSection_Banners_Desktop_sizes__large_jpeg__height_operator"] | undefined | null,
	sizes__large_jpeg__mimeType?: ResolverInputTypes["MacaoBannerSection_Banners_Desktop_sizes__large_jpeg__mimeType_operator"] | undefined | null,
	sizes__large_jpeg__filesize?: ResolverInputTypes["MacaoBannerSection_Banners_Desktop_sizes__large_jpeg__filesize_operator"] | undefined | null,
	sizes__large_jpeg__filename?: ResolverInputTypes["MacaoBannerSection_Banners_Desktop_sizes__large_jpeg__filename_operator"] | undefined | null,
	id?: ResolverInputTypes["MacaoBannerSection_Banners_Desktop_id_operator"] | undefined | null,
	AND?: Array<ResolverInputTypes["MacaoBannerSection_Banners_Desktop_where_and"] | undefined | null> | undefined | null,
	OR?: Array<ResolverInputTypes["MacaoBannerSection_Banners_Desktop_where_or"] | undefined | null> | undefined | null
};
	["MacaoBannerSection_Banners_region"]:MacaoBannerSection_Banners_region;
	["MacaoBannerSections"]: AliasType<{
	docs?:ResolverInputTypes["MacaoBannerSection"],
	hasNextPage?:boolean | `@${string}`,
	hasPrevPage?:boolean | `@${string}`,
	limit?:boolean | `@${string}`,
	nextPage?:boolean | `@${string}`,
	offset?:boolean | `@${string}`,
	page?:boolean | `@${string}`,
	pagingCounter?:boolean | `@${string}`,
	prevPage?:boolean | `@${string}`,
	totalDocs?:boolean | `@${string}`,
	totalPages?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MacaoBannerSection_where"]: {
	name?: ResolverInputTypes["MacaoBannerSection_name_operator"] | undefined | null,
	slug?: ResolverInputTypes["MacaoBannerSection_slug_operator"] | undefined | null,
	banners__name?: ResolverInputTypes["MacaoBannerSection_banners__name_operator"] | undefined | null,
	banners__status?: ResolverInputTypes["MacaoBannerSection_banners__status_operator"] | undefined | null,
	banners__desktop?: ResolverInputTypes["MacaoBannerSection_banners__desktop_operator"] | undefined | null,
	banners__bannerStartDate?: ResolverInputTypes["MacaoBannerSection_banners__bannerStartDate_operator"] | undefined | null,
	banners__bannerEndDate?: ResolverInputTypes["MacaoBannerSection_banners__bannerEndDate_operator"] | undefined | null,
	banners__region?: ResolverInputTypes["MacaoBannerSection_banners__region_operator"] | undefined | null,
	banners__id?: ResolverInputTypes["MacaoBannerSection_banners__id_operator"] | undefined | null,
	updatedAt?: ResolverInputTypes["MacaoBannerSection_updatedAt_operator"] | undefined | null,
	createdAt?: ResolverInputTypes["MacaoBannerSection_createdAt_operator"] | undefined | null,
	id?: ResolverInputTypes["MacaoBannerSection_id_operator"] | undefined | null,
	AND?: Array<ResolverInputTypes["MacaoBannerSection_where_and"] | undefined | null> | undefined | null,
	OR?: Array<ResolverInputTypes["MacaoBannerSection_where_or"] | undefined | null> | undefined | null
};
	["MacaoBannerSection_name_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["MacaoBannerSection_slug_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["MacaoBannerSection_banners__name_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["MacaoBannerSection_banners__status_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["MacaoBannerSection_banners__desktop_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null
};
	["MacaoBannerSection_banners__bannerStartDate_operator"]: {
	equals?: ResolverInputTypes["DateTime"] | undefined | null,
	not_equals?: ResolverInputTypes["DateTime"] | undefined | null,
	greater_than_equal?: ResolverInputTypes["DateTime"] | undefined | null,
	greater_than?: ResolverInputTypes["DateTime"] | undefined | null,
	less_than_equal?: ResolverInputTypes["DateTime"] | undefined | null,
	less_than?: ResolverInputTypes["DateTime"] | undefined | null,
	like?: ResolverInputTypes["DateTime"] | undefined | null,
	exists?: boolean | undefined | null
};
	["MacaoBannerSection_banners__bannerEndDate_operator"]: {
	equals?: ResolverInputTypes["DateTime"] | undefined | null,
	not_equals?: ResolverInputTypes["DateTime"] | undefined | null,
	greater_than_equal?: ResolverInputTypes["DateTime"] | undefined | null,
	greater_than?: ResolverInputTypes["DateTime"] | undefined | null,
	less_than_equal?: ResolverInputTypes["DateTime"] | undefined | null,
	less_than?: ResolverInputTypes["DateTime"] | undefined | null,
	like?: ResolverInputTypes["DateTime"] | undefined | null,
	exists?: boolean | undefined | null
};
	["MacaoBannerSection_banners__region_operator"]: {
	equals?: ResolverInputTypes["MacaoBannerSection_banners__region_Input"] | undefined | null,
	not_equals?: ResolverInputTypes["MacaoBannerSection_banners__region_Input"] | undefined | null,
	in?: Array<ResolverInputTypes["MacaoBannerSection_banners__region_Input"] | undefined | null> | undefined | null,
	not_in?: Array<ResolverInputTypes["MacaoBannerSection_banners__region_Input"] | undefined | null> | undefined | null,
	all?: Array<ResolverInputTypes["MacaoBannerSection_banners__region_Input"] | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["MacaoBannerSection_banners__region_Input"]:MacaoBannerSection_banners__region_Input;
	["MacaoBannerSection_banners__id_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["MacaoBannerSection_updatedAt_operator"]: {
	equals?: ResolverInputTypes["DateTime"] | undefined | null,
	not_equals?: ResolverInputTypes["DateTime"] | undefined | null,
	greater_than_equal?: ResolverInputTypes["DateTime"] | undefined | null,
	greater_than?: ResolverInputTypes["DateTime"] | undefined | null,
	less_than_equal?: ResolverInputTypes["DateTime"] | undefined | null,
	less_than?: ResolverInputTypes["DateTime"] | undefined | null,
	like?: ResolverInputTypes["DateTime"] | undefined | null,
	exists?: boolean | undefined | null
};
	["MacaoBannerSection_createdAt_operator"]: {
	equals?: ResolverInputTypes["DateTime"] | undefined | null,
	not_equals?: ResolverInputTypes["DateTime"] | undefined | null,
	greater_than_equal?: ResolverInputTypes["DateTime"] | undefined | null,
	greater_than?: ResolverInputTypes["DateTime"] | undefined | null,
	less_than_equal?: ResolverInputTypes["DateTime"] | undefined | null,
	less_than?: ResolverInputTypes["DateTime"] | undefined | null,
	like?: ResolverInputTypes["DateTime"] | undefined | null,
	exists?: boolean | undefined | null
};
	["MacaoBannerSection_id_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["MacaoBannerSection_where_and"]: {
	name?: ResolverInputTypes["MacaoBannerSection_name_operator"] | undefined | null,
	slug?: ResolverInputTypes["MacaoBannerSection_slug_operator"] | undefined | null,
	banners__name?: ResolverInputTypes["MacaoBannerSection_banners__name_operator"] | undefined | null,
	banners__status?: ResolverInputTypes["MacaoBannerSection_banners__status_operator"] | undefined | null,
	banners__desktop?: ResolverInputTypes["MacaoBannerSection_banners__desktop_operator"] | undefined | null,
	banners__bannerStartDate?: ResolverInputTypes["MacaoBannerSection_banners__bannerStartDate_operator"] | undefined | null,
	banners__bannerEndDate?: ResolverInputTypes["MacaoBannerSection_banners__bannerEndDate_operator"] | undefined | null,
	banners__region?: ResolverInputTypes["MacaoBannerSection_banners__region_operator"] | undefined | null,
	banners__id?: ResolverInputTypes["MacaoBannerSection_banners__id_operator"] | undefined | null,
	updatedAt?: ResolverInputTypes["MacaoBannerSection_updatedAt_operator"] | undefined | null,
	createdAt?: ResolverInputTypes["MacaoBannerSection_createdAt_operator"] | undefined | null,
	id?: ResolverInputTypes["MacaoBannerSection_id_operator"] | undefined | null,
	AND?: Array<ResolverInputTypes["MacaoBannerSection_where_and"] | undefined | null> | undefined | null,
	OR?: Array<ResolverInputTypes["MacaoBannerSection_where_or"] | undefined | null> | undefined | null
};
	["MacaoBannerSection_where_or"]: {
	name?: ResolverInputTypes["MacaoBannerSection_name_operator"] | undefined | null,
	slug?: ResolverInputTypes["MacaoBannerSection_slug_operator"] | undefined | null,
	banners__name?: ResolverInputTypes["MacaoBannerSection_banners__name_operator"] | undefined | null,
	banners__status?: ResolverInputTypes["MacaoBannerSection_banners__status_operator"] | undefined | null,
	banners__desktop?: ResolverInputTypes["MacaoBannerSection_banners__desktop_operator"] | undefined | null,
	banners__bannerStartDate?: ResolverInputTypes["MacaoBannerSection_banners__bannerStartDate_operator"] | undefined | null,
	banners__bannerEndDate?: ResolverInputTypes["MacaoBannerSection_banners__bannerEndDate_operator"] | undefined | null,
	banners__region?: ResolverInputTypes["MacaoBannerSection_banners__region_operator"] | undefined | null,
	banners__id?: ResolverInputTypes["MacaoBannerSection_banners__id_operator"] | undefined | null,
	updatedAt?: ResolverInputTypes["MacaoBannerSection_updatedAt_operator"] | undefined | null,
	createdAt?: ResolverInputTypes["MacaoBannerSection_createdAt_operator"] | undefined | null,
	id?: ResolverInputTypes["MacaoBannerSection_id_operator"] | undefined | null,
	AND?: Array<ResolverInputTypes["MacaoBannerSection_where_and"] | undefined | null> | undefined | null,
	OR?: Array<ResolverInputTypes["MacaoBannerSection_where_or"] | undefined | null> | undefined | null
};
	["countMacaoBannerSections"]: AliasType<{
	totalDocs?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["macao_banner_sectionDocAccess"]: AliasType<{
	fields?:ResolverInputTypes["MacaoBannerSectionDocAccessFields"],
	create?:ResolverInputTypes["MacaoBannerSectionCreateDocAccess"],
	read?:ResolverInputTypes["MacaoBannerSectionReadDocAccess"],
	update?:ResolverInputTypes["MacaoBannerSectionUpdateDocAccess"],
	delete?:ResolverInputTypes["MacaoBannerSectionDeleteDocAccess"],
		__typename?: boolean | `@${string}`
}>;
	["MacaoBannerSectionDocAccessFields"]: AliasType<{
	name?:ResolverInputTypes["MacaoBannerSectionDocAccessFields_name"],
	slug?:ResolverInputTypes["MacaoBannerSectionDocAccessFields_slug"],
	banners?:ResolverInputTypes["MacaoBannerSectionDocAccessFields_banners"],
	updatedAt?:ResolverInputTypes["MacaoBannerSectionDocAccessFields_updatedAt"],
	createdAt?:ResolverInputTypes["MacaoBannerSectionDocAccessFields_createdAt"],
		__typename?: boolean | `@${string}`
}>;
	["MacaoBannerSectionDocAccessFields_name"]: AliasType<{
	create?:ResolverInputTypes["MacaoBannerSectionDocAccessFields_name_Create"],
	read?:ResolverInputTypes["MacaoBannerSectionDocAccessFields_name_Read"],
	update?:ResolverInputTypes["MacaoBannerSectionDocAccessFields_name_Update"],
	delete?:ResolverInputTypes["MacaoBannerSectionDocAccessFields_name_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["MacaoBannerSectionDocAccessFields_name_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MacaoBannerSectionDocAccessFields_name_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MacaoBannerSectionDocAccessFields_name_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MacaoBannerSectionDocAccessFields_name_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MacaoBannerSectionDocAccessFields_slug"]: AliasType<{
	create?:ResolverInputTypes["MacaoBannerSectionDocAccessFields_slug_Create"],
	read?:ResolverInputTypes["MacaoBannerSectionDocAccessFields_slug_Read"],
	update?:ResolverInputTypes["MacaoBannerSectionDocAccessFields_slug_Update"],
	delete?:ResolverInputTypes["MacaoBannerSectionDocAccessFields_slug_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["MacaoBannerSectionDocAccessFields_slug_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MacaoBannerSectionDocAccessFields_slug_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MacaoBannerSectionDocAccessFields_slug_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MacaoBannerSectionDocAccessFields_slug_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MacaoBannerSectionDocAccessFields_banners"]: AliasType<{
	create?:ResolverInputTypes["MacaoBannerSectionDocAccessFields_banners_Create"],
	read?:ResolverInputTypes["MacaoBannerSectionDocAccessFields_banners_Read"],
	update?:ResolverInputTypes["MacaoBannerSectionDocAccessFields_banners_Update"],
	delete?:ResolverInputTypes["MacaoBannerSectionDocAccessFields_banners_Delete"],
	fields?:ResolverInputTypes["MacaoBannerSectionDocAccessFields_banners_Fields"],
		__typename?: boolean | `@${string}`
}>;
	["MacaoBannerSectionDocAccessFields_banners_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MacaoBannerSectionDocAccessFields_banners_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MacaoBannerSectionDocAccessFields_banners_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MacaoBannerSectionDocAccessFields_banners_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MacaoBannerSectionDocAccessFields_banners_Fields"]: AliasType<{
	name?:ResolverInputTypes["MacaoBannerSectionDocAccessFields_banners_name"],
	status?:ResolverInputTypes["MacaoBannerSectionDocAccessFields_banners_status"],
	desktop?:ResolverInputTypes["MacaoBannerSectionDocAccessFields_banners_desktop"],
	bannerStartDate?:ResolverInputTypes["MacaoBannerSectionDocAccessFields_banners_bannerStartDate"],
	bannerEndDate?:ResolverInputTypes["MacaoBannerSectionDocAccessFields_banners_bannerEndDate"],
	region?:ResolverInputTypes["MacaoBannerSectionDocAccessFields_banners_region"],
	id?:ResolverInputTypes["MacaoBannerSectionDocAccessFields_banners_id"],
		__typename?: boolean | `@${string}`
}>;
	["MacaoBannerSectionDocAccessFields_banners_name"]: AliasType<{
	create?:ResolverInputTypes["MacaoBannerSectionDocAccessFields_banners_name_Create"],
	read?:ResolverInputTypes["MacaoBannerSectionDocAccessFields_banners_name_Read"],
	update?:ResolverInputTypes["MacaoBannerSectionDocAccessFields_banners_name_Update"],
	delete?:ResolverInputTypes["MacaoBannerSectionDocAccessFields_banners_name_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["MacaoBannerSectionDocAccessFields_banners_name_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MacaoBannerSectionDocAccessFields_banners_name_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MacaoBannerSectionDocAccessFields_banners_name_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MacaoBannerSectionDocAccessFields_banners_name_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MacaoBannerSectionDocAccessFields_banners_status"]: AliasType<{
	create?:ResolverInputTypes["MacaoBannerSectionDocAccessFields_banners_status_Create"],
	read?:ResolverInputTypes["MacaoBannerSectionDocAccessFields_banners_status_Read"],
	update?:ResolverInputTypes["MacaoBannerSectionDocAccessFields_banners_status_Update"],
	delete?:ResolverInputTypes["MacaoBannerSectionDocAccessFields_banners_status_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["MacaoBannerSectionDocAccessFields_banners_status_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MacaoBannerSectionDocAccessFields_banners_status_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MacaoBannerSectionDocAccessFields_banners_status_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MacaoBannerSectionDocAccessFields_banners_status_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MacaoBannerSectionDocAccessFields_banners_desktop"]: AliasType<{
	create?:ResolverInputTypes["MacaoBannerSectionDocAccessFields_banners_desktop_Create"],
	read?:ResolverInputTypes["MacaoBannerSectionDocAccessFields_banners_desktop_Read"],
	update?:ResolverInputTypes["MacaoBannerSectionDocAccessFields_banners_desktop_Update"],
	delete?:ResolverInputTypes["MacaoBannerSectionDocAccessFields_banners_desktop_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["MacaoBannerSectionDocAccessFields_banners_desktop_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MacaoBannerSectionDocAccessFields_banners_desktop_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MacaoBannerSectionDocAccessFields_banners_desktop_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MacaoBannerSectionDocAccessFields_banners_desktop_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MacaoBannerSectionDocAccessFields_banners_bannerStartDate"]: AliasType<{
	create?:ResolverInputTypes["MacaoBannerSectionDocAccessFields_banners_bannerStartDate_Create"],
	read?:ResolverInputTypes["MacaoBannerSectionDocAccessFields_banners_bannerStartDate_Read"],
	update?:ResolverInputTypes["MacaoBannerSectionDocAccessFields_banners_bannerStartDate_Update"],
	delete?:ResolverInputTypes["MacaoBannerSectionDocAccessFields_banners_bannerStartDate_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["MacaoBannerSectionDocAccessFields_banners_bannerStartDate_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MacaoBannerSectionDocAccessFields_banners_bannerStartDate_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MacaoBannerSectionDocAccessFields_banners_bannerStartDate_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MacaoBannerSectionDocAccessFields_banners_bannerStartDate_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MacaoBannerSectionDocAccessFields_banners_bannerEndDate"]: AliasType<{
	create?:ResolverInputTypes["MacaoBannerSectionDocAccessFields_banners_bannerEndDate_Create"],
	read?:ResolverInputTypes["MacaoBannerSectionDocAccessFields_banners_bannerEndDate_Read"],
	update?:ResolverInputTypes["MacaoBannerSectionDocAccessFields_banners_bannerEndDate_Update"],
	delete?:ResolverInputTypes["MacaoBannerSectionDocAccessFields_banners_bannerEndDate_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["MacaoBannerSectionDocAccessFields_banners_bannerEndDate_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MacaoBannerSectionDocAccessFields_banners_bannerEndDate_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MacaoBannerSectionDocAccessFields_banners_bannerEndDate_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MacaoBannerSectionDocAccessFields_banners_bannerEndDate_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MacaoBannerSectionDocAccessFields_banners_region"]: AliasType<{
	create?:ResolverInputTypes["MacaoBannerSectionDocAccessFields_banners_region_Create"],
	read?:ResolverInputTypes["MacaoBannerSectionDocAccessFields_banners_region_Read"],
	update?:ResolverInputTypes["MacaoBannerSectionDocAccessFields_banners_region_Update"],
	delete?:ResolverInputTypes["MacaoBannerSectionDocAccessFields_banners_region_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["MacaoBannerSectionDocAccessFields_banners_region_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MacaoBannerSectionDocAccessFields_banners_region_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MacaoBannerSectionDocAccessFields_banners_region_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MacaoBannerSectionDocAccessFields_banners_region_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MacaoBannerSectionDocAccessFields_banners_id"]: AliasType<{
	create?:ResolverInputTypes["MacaoBannerSectionDocAccessFields_banners_id_Create"],
	read?:ResolverInputTypes["MacaoBannerSectionDocAccessFields_banners_id_Read"],
	update?:ResolverInputTypes["MacaoBannerSectionDocAccessFields_banners_id_Update"],
	delete?:ResolverInputTypes["MacaoBannerSectionDocAccessFields_banners_id_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["MacaoBannerSectionDocAccessFields_banners_id_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MacaoBannerSectionDocAccessFields_banners_id_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MacaoBannerSectionDocAccessFields_banners_id_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MacaoBannerSectionDocAccessFields_banners_id_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MacaoBannerSectionDocAccessFields_updatedAt"]: AliasType<{
	create?:ResolverInputTypes["MacaoBannerSectionDocAccessFields_updatedAt_Create"],
	read?:ResolverInputTypes["MacaoBannerSectionDocAccessFields_updatedAt_Read"],
	update?:ResolverInputTypes["MacaoBannerSectionDocAccessFields_updatedAt_Update"],
	delete?:ResolverInputTypes["MacaoBannerSectionDocAccessFields_updatedAt_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["MacaoBannerSectionDocAccessFields_updatedAt_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MacaoBannerSectionDocAccessFields_updatedAt_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MacaoBannerSectionDocAccessFields_updatedAt_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MacaoBannerSectionDocAccessFields_updatedAt_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MacaoBannerSectionDocAccessFields_createdAt"]: AliasType<{
	create?:ResolverInputTypes["MacaoBannerSectionDocAccessFields_createdAt_Create"],
	read?:ResolverInputTypes["MacaoBannerSectionDocAccessFields_createdAt_Read"],
	update?:ResolverInputTypes["MacaoBannerSectionDocAccessFields_createdAt_Update"],
	delete?:ResolverInputTypes["MacaoBannerSectionDocAccessFields_createdAt_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["MacaoBannerSectionDocAccessFields_createdAt_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MacaoBannerSectionDocAccessFields_createdAt_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MacaoBannerSectionDocAccessFields_createdAt_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MacaoBannerSectionDocAccessFields_createdAt_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MacaoBannerSectionCreateDocAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MacaoBannerSectionReadDocAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MacaoBannerSectionUpdateDocAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MacaoBannerSectionDeleteDocAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["Banner"]: AliasType<{
	id?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
desktop?: [{	locale?: ResolverInputTypes["LocaleInputType"] | undefined | null,	fallbackLocale?: ResolverInputTypes["FallbackLocaleInputType"] | undefined | null,	where?: ResolverInputTypes["Banner_Desktop_where"] | undefined | null},ResolverInputTypes["Media"]],
mobile?: [{	locale?: ResolverInputTypes["LocaleInputType"] | undefined | null,	fallbackLocale?: ResolverInputTypes["FallbackLocaleInputType"] | undefined | null,	where?: ResolverInputTypes["Banner_Mobile_where"] | undefined | null},ResolverInputTypes["Media"]],
	bannerStartDate?:boolean | `@${string}`,
	bannerEndDate?:boolean | `@${string}`,
	location?:ResolverInputTypes["Banner_Location"],
	link?:boolean | `@${string}`,
	external?:boolean | `@${string}`,
	type?:boolean | `@${string}`,
	status?:boolean | `@${string}`,
	advertise?:ResolverInputTypes["Banner_Advertise"],
	_locations?:boolean | `@${string}`,
	region?:boolean | `@${string}`,
	homePageClick?:boolean | `@${string}`,
	homePageImpression?:boolean | `@${string}`,
	latestEventClick?:boolean | `@${string}`,
	latestEventImpression?:boolean | `@${string}`,
	editorChoiceClick?:boolean | `@${string}`,
	editorChoiceImpression?:boolean | `@${string}`,
	eventListClick?:boolean | `@${string}`,
	eventListImpression?:boolean | `@${string}`,
	topTenClick?:boolean | `@${string}`,
	topTenImpression?:boolean | `@${string}`,
	miscClick?:boolean | `@${string}`,
	miscImpression?:boolean | `@${string}`,
	clickTotal?:boolean | `@${string}`,
	totalImpression?:boolean | `@${string}`,
	updatedAt?:boolean | `@${string}`,
	createdAt?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["Banner_Desktop_where"]: {
	alt?: ResolverInputTypes["Banner_Desktop_alt_operator"] | undefined | null,
	updatedAt?: ResolverInputTypes["Banner_Desktop_updatedAt_operator"] | undefined | null,
	createdAt?: ResolverInputTypes["Banner_Desktop_createdAt_operator"] | undefined | null,
	url?: ResolverInputTypes["Banner_Desktop_url_operator"] | undefined | null,
	filename?: ResolverInputTypes["Banner_Desktop_filename_operator"] | undefined | null,
	mimeType?: ResolverInputTypes["Banner_Desktop_mimeType_operator"] | undefined | null,
	filesize?: ResolverInputTypes["Banner_Desktop_filesize_operator"] | undefined | null,
	width?: ResolverInputTypes["Banner_Desktop_width_operator"] | undefined | null,
	height?: ResolverInputTypes["Banner_Desktop_height_operator"] | undefined | null,
	focalX?: ResolverInputTypes["Banner_Desktop_focalX_operator"] | undefined | null,
	focalY?: ResolverInputTypes["Banner_Desktop_focalY_operator"] | undefined | null,
	sizes__small__url?: ResolverInputTypes["Banner_Desktop_sizes__small__url_operator"] | undefined | null,
	sizes__small__width?: ResolverInputTypes["Banner_Desktop_sizes__small__width_operator"] | undefined | null,
	sizes__small__height?: ResolverInputTypes["Banner_Desktop_sizes__small__height_operator"] | undefined | null,
	sizes__small__mimeType?: ResolverInputTypes["Banner_Desktop_sizes__small__mimeType_operator"] | undefined | null,
	sizes__small__filesize?: ResolverInputTypes["Banner_Desktop_sizes__small__filesize_operator"] | undefined | null,
	sizes__small__filename?: ResolverInputTypes["Banner_Desktop_sizes__small__filename_operator"] | undefined | null,
	sizes__medium__url?: ResolverInputTypes["Banner_Desktop_sizes__medium__url_operator"] | undefined | null,
	sizes__medium__width?: ResolverInputTypes["Banner_Desktop_sizes__medium__width_operator"] | undefined | null,
	sizes__medium__height?: ResolverInputTypes["Banner_Desktop_sizes__medium__height_operator"] | undefined | null,
	sizes__medium__mimeType?: ResolverInputTypes["Banner_Desktop_sizes__medium__mimeType_operator"] | undefined | null,
	sizes__medium__filesize?: ResolverInputTypes["Banner_Desktop_sizes__medium__filesize_operator"] | undefined | null,
	sizes__medium__filename?: ResolverInputTypes["Banner_Desktop_sizes__medium__filename_operator"] | undefined | null,
	sizes__large__url?: ResolverInputTypes["Banner_Desktop_sizes__large__url_operator"] | undefined | null,
	sizes__large__width?: ResolverInputTypes["Banner_Desktop_sizes__large__width_operator"] | undefined | null,
	sizes__large__height?: ResolverInputTypes["Banner_Desktop_sizes__large__height_operator"] | undefined | null,
	sizes__large__mimeType?: ResolverInputTypes["Banner_Desktop_sizes__large__mimeType_operator"] | undefined | null,
	sizes__large__filesize?: ResolverInputTypes["Banner_Desktop_sizes__large__filesize_operator"] | undefined | null,
	sizes__large__filename?: ResolverInputTypes["Banner_Desktop_sizes__large__filename_operator"] | undefined | null,
	sizes__small_jpeg__url?: ResolverInputTypes["Banner_Desktop_sizes__small_jpeg__url_operator"] | undefined | null,
	sizes__small_jpeg__width?: ResolverInputTypes["Banner_Desktop_sizes__small_jpeg__width_operator"] | undefined | null,
	sizes__small_jpeg__height?: ResolverInputTypes["Banner_Desktop_sizes__small_jpeg__height_operator"] | undefined | null,
	sizes__small_jpeg__mimeType?: ResolverInputTypes["Banner_Desktop_sizes__small_jpeg__mimeType_operator"] | undefined | null,
	sizes__small_jpeg__filesize?: ResolverInputTypes["Banner_Desktop_sizes__small_jpeg__filesize_operator"] | undefined | null,
	sizes__small_jpeg__filename?: ResolverInputTypes["Banner_Desktop_sizes__small_jpeg__filename_operator"] | undefined | null,
	sizes__medium_jpeg__url?: ResolverInputTypes["Banner_Desktop_sizes__medium_jpeg__url_operator"] | undefined | null,
	sizes__medium_jpeg__width?: ResolverInputTypes["Banner_Desktop_sizes__medium_jpeg__width_operator"] | undefined | null,
	sizes__medium_jpeg__height?: ResolverInputTypes["Banner_Desktop_sizes__medium_jpeg__height_operator"] | undefined | null,
	sizes__medium_jpeg__mimeType?: ResolverInputTypes["Banner_Desktop_sizes__medium_jpeg__mimeType_operator"] | undefined | null,
	sizes__medium_jpeg__filesize?: ResolverInputTypes["Banner_Desktop_sizes__medium_jpeg__filesize_operator"] | undefined | null,
	sizes__medium_jpeg__filename?: ResolverInputTypes["Banner_Desktop_sizes__medium_jpeg__filename_operator"] | undefined | null,
	sizes__large_jpeg__url?: ResolverInputTypes["Banner_Desktop_sizes__large_jpeg__url_operator"] | undefined | null,
	sizes__large_jpeg__width?: ResolverInputTypes["Banner_Desktop_sizes__large_jpeg__width_operator"] | undefined | null,
	sizes__large_jpeg__height?: ResolverInputTypes["Banner_Desktop_sizes__large_jpeg__height_operator"] | undefined | null,
	sizes__large_jpeg__mimeType?: ResolverInputTypes["Banner_Desktop_sizes__large_jpeg__mimeType_operator"] | undefined | null,
	sizes__large_jpeg__filesize?: ResolverInputTypes["Banner_Desktop_sizes__large_jpeg__filesize_operator"] | undefined | null,
	sizes__large_jpeg__filename?: ResolverInputTypes["Banner_Desktop_sizes__large_jpeg__filename_operator"] | undefined | null,
	id?: ResolverInputTypes["Banner_Desktop_id_operator"] | undefined | null,
	AND?: Array<ResolverInputTypes["Banner_Desktop_where_and"] | undefined | null> | undefined | null,
	OR?: Array<ResolverInputTypes["Banner_Desktop_where_or"] | undefined | null> | undefined | null
};
	["Banner_Desktop_alt_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Banner_Desktop_updatedAt_operator"]: {
	equals?: ResolverInputTypes["DateTime"] | undefined | null,
	not_equals?: ResolverInputTypes["DateTime"] | undefined | null,
	greater_than_equal?: ResolverInputTypes["DateTime"] | undefined | null,
	greater_than?: ResolverInputTypes["DateTime"] | undefined | null,
	less_than_equal?: ResolverInputTypes["DateTime"] | undefined | null,
	less_than?: ResolverInputTypes["DateTime"] | undefined | null,
	like?: ResolverInputTypes["DateTime"] | undefined | null,
	exists?: boolean | undefined | null
};
	["Banner_Desktop_createdAt_operator"]: {
	equals?: ResolverInputTypes["DateTime"] | undefined | null,
	not_equals?: ResolverInputTypes["DateTime"] | undefined | null,
	greater_than_equal?: ResolverInputTypes["DateTime"] | undefined | null,
	greater_than?: ResolverInputTypes["DateTime"] | undefined | null,
	less_than_equal?: ResolverInputTypes["DateTime"] | undefined | null,
	less_than?: ResolverInputTypes["DateTime"] | undefined | null,
	like?: ResolverInputTypes["DateTime"] | undefined | null,
	exists?: boolean | undefined | null
};
	["Banner_Desktop_url_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Banner_Desktop_filename_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Banner_Desktop_mimeType_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Banner_Desktop_filesize_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Banner_Desktop_width_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Banner_Desktop_height_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Banner_Desktop_focalX_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Banner_Desktop_focalY_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Banner_Desktop_sizes__small__url_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Banner_Desktop_sizes__small__width_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Banner_Desktop_sizes__small__height_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Banner_Desktop_sizes__small__mimeType_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Banner_Desktop_sizes__small__filesize_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Banner_Desktop_sizes__small__filename_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Banner_Desktop_sizes__medium__url_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Banner_Desktop_sizes__medium__width_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Banner_Desktop_sizes__medium__height_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Banner_Desktop_sizes__medium__mimeType_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Banner_Desktop_sizes__medium__filesize_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Banner_Desktop_sizes__medium__filename_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Banner_Desktop_sizes__large__url_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Banner_Desktop_sizes__large__width_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Banner_Desktop_sizes__large__height_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Banner_Desktop_sizes__large__mimeType_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Banner_Desktop_sizes__large__filesize_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Banner_Desktop_sizes__large__filename_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Banner_Desktop_sizes__small_jpeg__url_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Banner_Desktop_sizes__small_jpeg__width_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Banner_Desktop_sizes__small_jpeg__height_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Banner_Desktop_sizes__small_jpeg__mimeType_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Banner_Desktop_sizes__small_jpeg__filesize_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Banner_Desktop_sizes__small_jpeg__filename_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Banner_Desktop_sizes__medium_jpeg__url_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Banner_Desktop_sizes__medium_jpeg__width_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Banner_Desktop_sizes__medium_jpeg__height_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Banner_Desktop_sizes__medium_jpeg__mimeType_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Banner_Desktop_sizes__medium_jpeg__filesize_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Banner_Desktop_sizes__medium_jpeg__filename_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Banner_Desktop_sizes__large_jpeg__url_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Banner_Desktop_sizes__large_jpeg__width_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Banner_Desktop_sizes__large_jpeg__height_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Banner_Desktop_sizes__large_jpeg__mimeType_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Banner_Desktop_sizes__large_jpeg__filesize_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Banner_Desktop_sizes__large_jpeg__filename_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Banner_Desktop_id_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Banner_Desktop_where_and"]: {
	alt?: ResolverInputTypes["Banner_Desktop_alt_operator"] | undefined | null,
	updatedAt?: ResolverInputTypes["Banner_Desktop_updatedAt_operator"] | undefined | null,
	createdAt?: ResolverInputTypes["Banner_Desktop_createdAt_operator"] | undefined | null,
	url?: ResolverInputTypes["Banner_Desktop_url_operator"] | undefined | null,
	filename?: ResolverInputTypes["Banner_Desktop_filename_operator"] | undefined | null,
	mimeType?: ResolverInputTypes["Banner_Desktop_mimeType_operator"] | undefined | null,
	filesize?: ResolverInputTypes["Banner_Desktop_filesize_operator"] | undefined | null,
	width?: ResolverInputTypes["Banner_Desktop_width_operator"] | undefined | null,
	height?: ResolverInputTypes["Banner_Desktop_height_operator"] | undefined | null,
	focalX?: ResolverInputTypes["Banner_Desktop_focalX_operator"] | undefined | null,
	focalY?: ResolverInputTypes["Banner_Desktop_focalY_operator"] | undefined | null,
	sizes__small__url?: ResolverInputTypes["Banner_Desktop_sizes__small__url_operator"] | undefined | null,
	sizes__small__width?: ResolverInputTypes["Banner_Desktop_sizes__small__width_operator"] | undefined | null,
	sizes__small__height?: ResolverInputTypes["Banner_Desktop_sizes__small__height_operator"] | undefined | null,
	sizes__small__mimeType?: ResolverInputTypes["Banner_Desktop_sizes__small__mimeType_operator"] | undefined | null,
	sizes__small__filesize?: ResolverInputTypes["Banner_Desktop_sizes__small__filesize_operator"] | undefined | null,
	sizes__small__filename?: ResolverInputTypes["Banner_Desktop_sizes__small__filename_operator"] | undefined | null,
	sizes__medium__url?: ResolverInputTypes["Banner_Desktop_sizes__medium__url_operator"] | undefined | null,
	sizes__medium__width?: ResolverInputTypes["Banner_Desktop_sizes__medium__width_operator"] | undefined | null,
	sizes__medium__height?: ResolverInputTypes["Banner_Desktop_sizes__medium__height_operator"] | undefined | null,
	sizes__medium__mimeType?: ResolverInputTypes["Banner_Desktop_sizes__medium__mimeType_operator"] | undefined | null,
	sizes__medium__filesize?: ResolverInputTypes["Banner_Desktop_sizes__medium__filesize_operator"] | undefined | null,
	sizes__medium__filename?: ResolverInputTypes["Banner_Desktop_sizes__medium__filename_operator"] | undefined | null,
	sizes__large__url?: ResolverInputTypes["Banner_Desktop_sizes__large__url_operator"] | undefined | null,
	sizes__large__width?: ResolverInputTypes["Banner_Desktop_sizes__large__width_operator"] | undefined | null,
	sizes__large__height?: ResolverInputTypes["Banner_Desktop_sizes__large__height_operator"] | undefined | null,
	sizes__large__mimeType?: ResolverInputTypes["Banner_Desktop_sizes__large__mimeType_operator"] | undefined | null,
	sizes__large__filesize?: ResolverInputTypes["Banner_Desktop_sizes__large__filesize_operator"] | undefined | null,
	sizes__large__filename?: ResolverInputTypes["Banner_Desktop_sizes__large__filename_operator"] | undefined | null,
	sizes__small_jpeg__url?: ResolverInputTypes["Banner_Desktop_sizes__small_jpeg__url_operator"] | undefined | null,
	sizes__small_jpeg__width?: ResolverInputTypes["Banner_Desktop_sizes__small_jpeg__width_operator"] | undefined | null,
	sizes__small_jpeg__height?: ResolverInputTypes["Banner_Desktop_sizes__small_jpeg__height_operator"] | undefined | null,
	sizes__small_jpeg__mimeType?: ResolverInputTypes["Banner_Desktop_sizes__small_jpeg__mimeType_operator"] | undefined | null,
	sizes__small_jpeg__filesize?: ResolverInputTypes["Banner_Desktop_sizes__small_jpeg__filesize_operator"] | undefined | null,
	sizes__small_jpeg__filename?: ResolverInputTypes["Banner_Desktop_sizes__small_jpeg__filename_operator"] | undefined | null,
	sizes__medium_jpeg__url?: ResolverInputTypes["Banner_Desktop_sizes__medium_jpeg__url_operator"] | undefined | null,
	sizes__medium_jpeg__width?: ResolverInputTypes["Banner_Desktop_sizes__medium_jpeg__width_operator"] | undefined | null,
	sizes__medium_jpeg__height?: ResolverInputTypes["Banner_Desktop_sizes__medium_jpeg__height_operator"] | undefined | null,
	sizes__medium_jpeg__mimeType?: ResolverInputTypes["Banner_Desktop_sizes__medium_jpeg__mimeType_operator"] | undefined | null,
	sizes__medium_jpeg__filesize?: ResolverInputTypes["Banner_Desktop_sizes__medium_jpeg__filesize_operator"] | undefined | null,
	sizes__medium_jpeg__filename?: ResolverInputTypes["Banner_Desktop_sizes__medium_jpeg__filename_operator"] | undefined | null,
	sizes__large_jpeg__url?: ResolverInputTypes["Banner_Desktop_sizes__large_jpeg__url_operator"] | undefined | null,
	sizes__large_jpeg__width?: ResolverInputTypes["Banner_Desktop_sizes__large_jpeg__width_operator"] | undefined | null,
	sizes__large_jpeg__height?: ResolverInputTypes["Banner_Desktop_sizes__large_jpeg__height_operator"] | undefined | null,
	sizes__large_jpeg__mimeType?: ResolverInputTypes["Banner_Desktop_sizes__large_jpeg__mimeType_operator"] | undefined | null,
	sizes__large_jpeg__filesize?: ResolverInputTypes["Banner_Desktop_sizes__large_jpeg__filesize_operator"] | undefined | null,
	sizes__large_jpeg__filename?: ResolverInputTypes["Banner_Desktop_sizes__large_jpeg__filename_operator"] | undefined | null,
	id?: ResolverInputTypes["Banner_Desktop_id_operator"] | undefined | null,
	AND?: Array<ResolverInputTypes["Banner_Desktop_where_and"] | undefined | null> | undefined | null,
	OR?: Array<ResolverInputTypes["Banner_Desktop_where_or"] | undefined | null> | undefined | null
};
	["Banner_Desktop_where_or"]: {
	alt?: ResolverInputTypes["Banner_Desktop_alt_operator"] | undefined | null,
	updatedAt?: ResolverInputTypes["Banner_Desktop_updatedAt_operator"] | undefined | null,
	createdAt?: ResolverInputTypes["Banner_Desktop_createdAt_operator"] | undefined | null,
	url?: ResolverInputTypes["Banner_Desktop_url_operator"] | undefined | null,
	filename?: ResolverInputTypes["Banner_Desktop_filename_operator"] | undefined | null,
	mimeType?: ResolverInputTypes["Banner_Desktop_mimeType_operator"] | undefined | null,
	filesize?: ResolverInputTypes["Banner_Desktop_filesize_operator"] | undefined | null,
	width?: ResolverInputTypes["Banner_Desktop_width_operator"] | undefined | null,
	height?: ResolverInputTypes["Banner_Desktop_height_operator"] | undefined | null,
	focalX?: ResolverInputTypes["Banner_Desktop_focalX_operator"] | undefined | null,
	focalY?: ResolverInputTypes["Banner_Desktop_focalY_operator"] | undefined | null,
	sizes__small__url?: ResolverInputTypes["Banner_Desktop_sizes__small__url_operator"] | undefined | null,
	sizes__small__width?: ResolverInputTypes["Banner_Desktop_sizes__small__width_operator"] | undefined | null,
	sizes__small__height?: ResolverInputTypes["Banner_Desktop_sizes__small__height_operator"] | undefined | null,
	sizes__small__mimeType?: ResolverInputTypes["Banner_Desktop_sizes__small__mimeType_operator"] | undefined | null,
	sizes__small__filesize?: ResolverInputTypes["Banner_Desktop_sizes__small__filesize_operator"] | undefined | null,
	sizes__small__filename?: ResolverInputTypes["Banner_Desktop_sizes__small__filename_operator"] | undefined | null,
	sizes__medium__url?: ResolverInputTypes["Banner_Desktop_sizes__medium__url_operator"] | undefined | null,
	sizes__medium__width?: ResolverInputTypes["Banner_Desktop_sizes__medium__width_operator"] | undefined | null,
	sizes__medium__height?: ResolverInputTypes["Banner_Desktop_sizes__medium__height_operator"] | undefined | null,
	sizes__medium__mimeType?: ResolverInputTypes["Banner_Desktop_sizes__medium__mimeType_operator"] | undefined | null,
	sizes__medium__filesize?: ResolverInputTypes["Banner_Desktop_sizes__medium__filesize_operator"] | undefined | null,
	sizes__medium__filename?: ResolverInputTypes["Banner_Desktop_sizes__medium__filename_operator"] | undefined | null,
	sizes__large__url?: ResolverInputTypes["Banner_Desktop_sizes__large__url_operator"] | undefined | null,
	sizes__large__width?: ResolverInputTypes["Banner_Desktop_sizes__large__width_operator"] | undefined | null,
	sizes__large__height?: ResolverInputTypes["Banner_Desktop_sizes__large__height_operator"] | undefined | null,
	sizes__large__mimeType?: ResolverInputTypes["Banner_Desktop_sizes__large__mimeType_operator"] | undefined | null,
	sizes__large__filesize?: ResolverInputTypes["Banner_Desktop_sizes__large__filesize_operator"] | undefined | null,
	sizes__large__filename?: ResolverInputTypes["Banner_Desktop_sizes__large__filename_operator"] | undefined | null,
	sizes__small_jpeg__url?: ResolverInputTypes["Banner_Desktop_sizes__small_jpeg__url_operator"] | undefined | null,
	sizes__small_jpeg__width?: ResolverInputTypes["Banner_Desktop_sizes__small_jpeg__width_operator"] | undefined | null,
	sizes__small_jpeg__height?: ResolverInputTypes["Banner_Desktop_sizes__small_jpeg__height_operator"] | undefined | null,
	sizes__small_jpeg__mimeType?: ResolverInputTypes["Banner_Desktop_sizes__small_jpeg__mimeType_operator"] | undefined | null,
	sizes__small_jpeg__filesize?: ResolverInputTypes["Banner_Desktop_sizes__small_jpeg__filesize_operator"] | undefined | null,
	sizes__small_jpeg__filename?: ResolverInputTypes["Banner_Desktop_sizes__small_jpeg__filename_operator"] | undefined | null,
	sizes__medium_jpeg__url?: ResolverInputTypes["Banner_Desktop_sizes__medium_jpeg__url_operator"] | undefined | null,
	sizes__medium_jpeg__width?: ResolverInputTypes["Banner_Desktop_sizes__medium_jpeg__width_operator"] | undefined | null,
	sizes__medium_jpeg__height?: ResolverInputTypes["Banner_Desktop_sizes__medium_jpeg__height_operator"] | undefined | null,
	sizes__medium_jpeg__mimeType?: ResolverInputTypes["Banner_Desktop_sizes__medium_jpeg__mimeType_operator"] | undefined | null,
	sizes__medium_jpeg__filesize?: ResolverInputTypes["Banner_Desktop_sizes__medium_jpeg__filesize_operator"] | undefined | null,
	sizes__medium_jpeg__filename?: ResolverInputTypes["Banner_Desktop_sizes__medium_jpeg__filename_operator"] | undefined | null,
	sizes__large_jpeg__url?: ResolverInputTypes["Banner_Desktop_sizes__large_jpeg__url_operator"] | undefined | null,
	sizes__large_jpeg__width?: ResolverInputTypes["Banner_Desktop_sizes__large_jpeg__width_operator"] | undefined | null,
	sizes__large_jpeg__height?: ResolverInputTypes["Banner_Desktop_sizes__large_jpeg__height_operator"] | undefined | null,
	sizes__large_jpeg__mimeType?: ResolverInputTypes["Banner_Desktop_sizes__large_jpeg__mimeType_operator"] | undefined | null,
	sizes__large_jpeg__filesize?: ResolverInputTypes["Banner_Desktop_sizes__large_jpeg__filesize_operator"] | undefined | null,
	sizes__large_jpeg__filename?: ResolverInputTypes["Banner_Desktop_sizes__large_jpeg__filename_operator"] | undefined | null,
	id?: ResolverInputTypes["Banner_Desktop_id_operator"] | undefined | null,
	AND?: Array<ResolverInputTypes["Banner_Desktop_where_and"] | undefined | null> | undefined | null,
	OR?: Array<ResolverInputTypes["Banner_Desktop_where_or"] | undefined | null> | undefined | null
};
	["Banner_Mobile_where"]: {
	alt?: ResolverInputTypes["Banner_Mobile_alt_operator"] | undefined | null,
	updatedAt?: ResolverInputTypes["Banner_Mobile_updatedAt_operator"] | undefined | null,
	createdAt?: ResolverInputTypes["Banner_Mobile_createdAt_operator"] | undefined | null,
	url?: ResolverInputTypes["Banner_Mobile_url_operator"] | undefined | null,
	filename?: ResolverInputTypes["Banner_Mobile_filename_operator"] | undefined | null,
	mimeType?: ResolverInputTypes["Banner_Mobile_mimeType_operator"] | undefined | null,
	filesize?: ResolverInputTypes["Banner_Mobile_filesize_operator"] | undefined | null,
	width?: ResolverInputTypes["Banner_Mobile_width_operator"] | undefined | null,
	height?: ResolverInputTypes["Banner_Mobile_height_operator"] | undefined | null,
	focalX?: ResolverInputTypes["Banner_Mobile_focalX_operator"] | undefined | null,
	focalY?: ResolverInputTypes["Banner_Mobile_focalY_operator"] | undefined | null,
	sizes__small__url?: ResolverInputTypes["Banner_Mobile_sizes__small__url_operator"] | undefined | null,
	sizes__small__width?: ResolverInputTypes["Banner_Mobile_sizes__small__width_operator"] | undefined | null,
	sizes__small__height?: ResolverInputTypes["Banner_Mobile_sizes__small__height_operator"] | undefined | null,
	sizes__small__mimeType?: ResolverInputTypes["Banner_Mobile_sizes__small__mimeType_operator"] | undefined | null,
	sizes__small__filesize?: ResolverInputTypes["Banner_Mobile_sizes__small__filesize_operator"] | undefined | null,
	sizes__small__filename?: ResolverInputTypes["Banner_Mobile_sizes__small__filename_operator"] | undefined | null,
	sizes__medium__url?: ResolverInputTypes["Banner_Mobile_sizes__medium__url_operator"] | undefined | null,
	sizes__medium__width?: ResolverInputTypes["Banner_Mobile_sizes__medium__width_operator"] | undefined | null,
	sizes__medium__height?: ResolverInputTypes["Banner_Mobile_sizes__medium__height_operator"] | undefined | null,
	sizes__medium__mimeType?: ResolverInputTypes["Banner_Mobile_sizes__medium__mimeType_operator"] | undefined | null,
	sizes__medium__filesize?: ResolverInputTypes["Banner_Mobile_sizes__medium__filesize_operator"] | undefined | null,
	sizes__medium__filename?: ResolverInputTypes["Banner_Mobile_sizes__medium__filename_operator"] | undefined | null,
	sizes__large__url?: ResolverInputTypes["Banner_Mobile_sizes__large__url_operator"] | undefined | null,
	sizes__large__width?: ResolverInputTypes["Banner_Mobile_sizes__large__width_operator"] | undefined | null,
	sizes__large__height?: ResolverInputTypes["Banner_Mobile_sizes__large__height_operator"] | undefined | null,
	sizes__large__mimeType?: ResolverInputTypes["Banner_Mobile_sizes__large__mimeType_operator"] | undefined | null,
	sizes__large__filesize?: ResolverInputTypes["Banner_Mobile_sizes__large__filesize_operator"] | undefined | null,
	sizes__large__filename?: ResolverInputTypes["Banner_Mobile_sizes__large__filename_operator"] | undefined | null,
	sizes__small_jpeg__url?: ResolverInputTypes["Banner_Mobile_sizes__small_jpeg__url_operator"] | undefined | null,
	sizes__small_jpeg__width?: ResolverInputTypes["Banner_Mobile_sizes__small_jpeg__width_operator"] | undefined | null,
	sizes__small_jpeg__height?: ResolverInputTypes["Banner_Mobile_sizes__small_jpeg__height_operator"] | undefined | null,
	sizes__small_jpeg__mimeType?: ResolverInputTypes["Banner_Mobile_sizes__small_jpeg__mimeType_operator"] | undefined | null,
	sizes__small_jpeg__filesize?: ResolverInputTypes["Banner_Mobile_sizes__small_jpeg__filesize_operator"] | undefined | null,
	sizes__small_jpeg__filename?: ResolverInputTypes["Banner_Mobile_sizes__small_jpeg__filename_operator"] | undefined | null,
	sizes__medium_jpeg__url?: ResolverInputTypes["Banner_Mobile_sizes__medium_jpeg__url_operator"] | undefined | null,
	sizes__medium_jpeg__width?: ResolverInputTypes["Banner_Mobile_sizes__medium_jpeg__width_operator"] | undefined | null,
	sizes__medium_jpeg__height?: ResolverInputTypes["Banner_Mobile_sizes__medium_jpeg__height_operator"] | undefined | null,
	sizes__medium_jpeg__mimeType?: ResolverInputTypes["Banner_Mobile_sizes__medium_jpeg__mimeType_operator"] | undefined | null,
	sizes__medium_jpeg__filesize?: ResolverInputTypes["Banner_Mobile_sizes__medium_jpeg__filesize_operator"] | undefined | null,
	sizes__medium_jpeg__filename?: ResolverInputTypes["Banner_Mobile_sizes__medium_jpeg__filename_operator"] | undefined | null,
	sizes__large_jpeg__url?: ResolverInputTypes["Banner_Mobile_sizes__large_jpeg__url_operator"] | undefined | null,
	sizes__large_jpeg__width?: ResolverInputTypes["Banner_Mobile_sizes__large_jpeg__width_operator"] | undefined | null,
	sizes__large_jpeg__height?: ResolverInputTypes["Banner_Mobile_sizes__large_jpeg__height_operator"] | undefined | null,
	sizes__large_jpeg__mimeType?: ResolverInputTypes["Banner_Mobile_sizes__large_jpeg__mimeType_operator"] | undefined | null,
	sizes__large_jpeg__filesize?: ResolverInputTypes["Banner_Mobile_sizes__large_jpeg__filesize_operator"] | undefined | null,
	sizes__large_jpeg__filename?: ResolverInputTypes["Banner_Mobile_sizes__large_jpeg__filename_operator"] | undefined | null,
	id?: ResolverInputTypes["Banner_Mobile_id_operator"] | undefined | null,
	AND?: Array<ResolverInputTypes["Banner_Mobile_where_and"] | undefined | null> | undefined | null,
	OR?: Array<ResolverInputTypes["Banner_Mobile_where_or"] | undefined | null> | undefined | null
};
	["Banner_Mobile_alt_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Banner_Mobile_updatedAt_operator"]: {
	equals?: ResolverInputTypes["DateTime"] | undefined | null,
	not_equals?: ResolverInputTypes["DateTime"] | undefined | null,
	greater_than_equal?: ResolverInputTypes["DateTime"] | undefined | null,
	greater_than?: ResolverInputTypes["DateTime"] | undefined | null,
	less_than_equal?: ResolverInputTypes["DateTime"] | undefined | null,
	less_than?: ResolverInputTypes["DateTime"] | undefined | null,
	like?: ResolverInputTypes["DateTime"] | undefined | null,
	exists?: boolean | undefined | null
};
	["Banner_Mobile_createdAt_operator"]: {
	equals?: ResolverInputTypes["DateTime"] | undefined | null,
	not_equals?: ResolverInputTypes["DateTime"] | undefined | null,
	greater_than_equal?: ResolverInputTypes["DateTime"] | undefined | null,
	greater_than?: ResolverInputTypes["DateTime"] | undefined | null,
	less_than_equal?: ResolverInputTypes["DateTime"] | undefined | null,
	less_than?: ResolverInputTypes["DateTime"] | undefined | null,
	like?: ResolverInputTypes["DateTime"] | undefined | null,
	exists?: boolean | undefined | null
};
	["Banner_Mobile_url_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Banner_Mobile_filename_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Banner_Mobile_mimeType_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Banner_Mobile_filesize_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Banner_Mobile_width_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Banner_Mobile_height_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Banner_Mobile_focalX_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Banner_Mobile_focalY_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Banner_Mobile_sizes__small__url_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Banner_Mobile_sizes__small__width_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Banner_Mobile_sizes__small__height_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Banner_Mobile_sizes__small__mimeType_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Banner_Mobile_sizes__small__filesize_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Banner_Mobile_sizes__small__filename_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Banner_Mobile_sizes__medium__url_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Banner_Mobile_sizes__medium__width_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Banner_Mobile_sizes__medium__height_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Banner_Mobile_sizes__medium__mimeType_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Banner_Mobile_sizes__medium__filesize_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Banner_Mobile_sizes__medium__filename_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Banner_Mobile_sizes__large__url_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Banner_Mobile_sizes__large__width_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Banner_Mobile_sizes__large__height_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Banner_Mobile_sizes__large__mimeType_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Banner_Mobile_sizes__large__filesize_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Banner_Mobile_sizes__large__filename_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Banner_Mobile_sizes__small_jpeg__url_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Banner_Mobile_sizes__small_jpeg__width_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Banner_Mobile_sizes__small_jpeg__height_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Banner_Mobile_sizes__small_jpeg__mimeType_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Banner_Mobile_sizes__small_jpeg__filesize_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Banner_Mobile_sizes__small_jpeg__filename_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Banner_Mobile_sizes__medium_jpeg__url_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Banner_Mobile_sizes__medium_jpeg__width_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Banner_Mobile_sizes__medium_jpeg__height_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Banner_Mobile_sizes__medium_jpeg__mimeType_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Banner_Mobile_sizes__medium_jpeg__filesize_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Banner_Mobile_sizes__medium_jpeg__filename_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Banner_Mobile_sizes__large_jpeg__url_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Banner_Mobile_sizes__large_jpeg__width_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Banner_Mobile_sizes__large_jpeg__height_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Banner_Mobile_sizes__large_jpeg__mimeType_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Banner_Mobile_sizes__large_jpeg__filesize_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Banner_Mobile_sizes__large_jpeg__filename_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Banner_Mobile_id_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Banner_Mobile_where_and"]: {
	alt?: ResolverInputTypes["Banner_Mobile_alt_operator"] | undefined | null,
	updatedAt?: ResolverInputTypes["Banner_Mobile_updatedAt_operator"] | undefined | null,
	createdAt?: ResolverInputTypes["Banner_Mobile_createdAt_operator"] | undefined | null,
	url?: ResolverInputTypes["Banner_Mobile_url_operator"] | undefined | null,
	filename?: ResolverInputTypes["Banner_Mobile_filename_operator"] | undefined | null,
	mimeType?: ResolverInputTypes["Banner_Mobile_mimeType_operator"] | undefined | null,
	filesize?: ResolverInputTypes["Banner_Mobile_filesize_operator"] | undefined | null,
	width?: ResolverInputTypes["Banner_Mobile_width_operator"] | undefined | null,
	height?: ResolverInputTypes["Banner_Mobile_height_operator"] | undefined | null,
	focalX?: ResolverInputTypes["Banner_Mobile_focalX_operator"] | undefined | null,
	focalY?: ResolverInputTypes["Banner_Mobile_focalY_operator"] | undefined | null,
	sizes__small__url?: ResolverInputTypes["Banner_Mobile_sizes__small__url_operator"] | undefined | null,
	sizes__small__width?: ResolverInputTypes["Banner_Mobile_sizes__small__width_operator"] | undefined | null,
	sizes__small__height?: ResolverInputTypes["Banner_Mobile_sizes__small__height_operator"] | undefined | null,
	sizes__small__mimeType?: ResolverInputTypes["Banner_Mobile_sizes__small__mimeType_operator"] | undefined | null,
	sizes__small__filesize?: ResolverInputTypes["Banner_Mobile_sizes__small__filesize_operator"] | undefined | null,
	sizes__small__filename?: ResolverInputTypes["Banner_Mobile_sizes__small__filename_operator"] | undefined | null,
	sizes__medium__url?: ResolverInputTypes["Banner_Mobile_sizes__medium__url_operator"] | undefined | null,
	sizes__medium__width?: ResolverInputTypes["Banner_Mobile_sizes__medium__width_operator"] | undefined | null,
	sizes__medium__height?: ResolverInputTypes["Banner_Mobile_sizes__medium__height_operator"] | undefined | null,
	sizes__medium__mimeType?: ResolverInputTypes["Banner_Mobile_sizes__medium__mimeType_operator"] | undefined | null,
	sizes__medium__filesize?: ResolverInputTypes["Banner_Mobile_sizes__medium__filesize_operator"] | undefined | null,
	sizes__medium__filename?: ResolverInputTypes["Banner_Mobile_sizes__medium__filename_operator"] | undefined | null,
	sizes__large__url?: ResolverInputTypes["Banner_Mobile_sizes__large__url_operator"] | undefined | null,
	sizes__large__width?: ResolverInputTypes["Banner_Mobile_sizes__large__width_operator"] | undefined | null,
	sizes__large__height?: ResolverInputTypes["Banner_Mobile_sizes__large__height_operator"] | undefined | null,
	sizes__large__mimeType?: ResolverInputTypes["Banner_Mobile_sizes__large__mimeType_operator"] | undefined | null,
	sizes__large__filesize?: ResolverInputTypes["Banner_Mobile_sizes__large__filesize_operator"] | undefined | null,
	sizes__large__filename?: ResolverInputTypes["Banner_Mobile_sizes__large__filename_operator"] | undefined | null,
	sizes__small_jpeg__url?: ResolverInputTypes["Banner_Mobile_sizes__small_jpeg__url_operator"] | undefined | null,
	sizes__small_jpeg__width?: ResolverInputTypes["Banner_Mobile_sizes__small_jpeg__width_operator"] | undefined | null,
	sizes__small_jpeg__height?: ResolverInputTypes["Banner_Mobile_sizes__small_jpeg__height_operator"] | undefined | null,
	sizes__small_jpeg__mimeType?: ResolverInputTypes["Banner_Mobile_sizes__small_jpeg__mimeType_operator"] | undefined | null,
	sizes__small_jpeg__filesize?: ResolverInputTypes["Banner_Mobile_sizes__small_jpeg__filesize_operator"] | undefined | null,
	sizes__small_jpeg__filename?: ResolverInputTypes["Banner_Mobile_sizes__small_jpeg__filename_operator"] | undefined | null,
	sizes__medium_jpeg__url?: ResolverInputTypes["Banner_Mobile_sizes__medium_jpeg__url_operator"] | undefined | null,
	sizes__medium_jpeg__width?: ResolverInputTypes["Banner_Mobile_sizes__medium_jpeg__width_operator"] | undefined | null,
	sizes__medium_jpeg__height?: ResolverInputTypes["Banner_Mobile_sizes__medium_jpeg__height_operator"] | undefined | null,
	sizes__medium_jpeg__mimeType?: ResolverInputTypes["Banner_Mobile_sizes__medium_jpeg__mimeType_operator"] | undefined | null,
	sizes__medium_jpeg__filesize?: ResolverInputTypes["Banner_Mobile_sizes__medium_jpeg__filesize_operator"] | undefined | null,
	sizes__medium_jpeg__filename?: ResolverInputTypes["Banner_Mobile_sizes__medium_jpeg__filename_operator"] | undefined | null,
	sizes__large_jpeg__url?: ResolverInputTypes["Banner_Mobile_sizes__large_jpeg__url_operator"] | undefined | null,
	sizes__large_jpeg__width?: ResolverInputTypes["Banner_Mobile_sizes__large_jpeg__width_operator"] | undefined | null,
	sizes__large_jpeg__height?: ResolverInputTypes["Banner_Mobile_sizes__large_jpeg__height_operator"] | undefined | null,
	sizes__large_jpeg__mimeType?: ResolverInputTypes["Banner_Mobile_sizes__large_jpeg__mimeType_operator"] | undefined | null,
	sizes__large_jpeg__filesize?: ResolverInputTypes["Banner_Mobile_sizes__large_jpeg__filesize_operator"] | undefined | null,
	sizes__large_jpeg__filename?: ResolverInputTypes["Banner_Mobile_sizes__large_jpeg__filename_operator"] | undefined | null,
	id?: ResolverInputTypes["Banner_Mobile_id_operator"] | undefined | null,
	AND?: Array<ResolverInputTypes["Banner_Mobile_where_and"] | undefined | null> | undefined | null,
	OR?: Array<ResolverInputTypes["Banner_Mobile_where_or"] | undefined | null> | undefined | null
};
	["Banner_Mobile_where_or"]: {
	alt?: ResolverInputTypes["Banner_Mobile_alt_operator"] | undefined | null,
	updatedAt?: ResolverInputTypes["Banner_Mobile_updatedAt_operator"] | undefined | null,
	createdAt?: ResolverInputTypes["Banner_Mobile_createdAt_operator"] | undefined | null,
	url?: ResolverInputTypes["Banner_Mobile_url_operator"] | undefined | null,
	filename?: ResolverInputTypes["Banner_Mobile_filename_operator"] | undefined | null,
	mimeType?: ResolverInputTypes["Banner_Mobile_mimeType_operator"] | undefined | null,
	filesize?: ResolverInputTypes["Banner_Mobile_filesize_operator"] | undefined | null,
	width?: ResolverInputTypes["Banner_Mobile_width_operator"] | undefined | null,
	height?: ResolverInputTypes["Banner_Mobile_height_operator"] | undefined | null,
	focalX?: ResolverInputTypes["Banner_Mobile_focalX_operator"] | undefined | null,
	focalY?: ResolverInputTypes["Banner_Mobile_focalY_operator"] | undefined | null,
	sizes__small__url?: ResolverInputTypes["Banner_Mobile_sizes__small__url_operator"] | undefined | null,
	sizes__small__width?: ResolverInputTypes["Banner_Mobile_sizes__small__width_operator"] | undefined | null,
	sizes__small__height?: ResolverInputTypes["Banner_Mobile_sizes__small__height_operator"] | undefined | null,
	sizes__small__mimeType?: ResolverInputTypes["Banner_Mobile_sizes__small__mimeType_operator"] | undefined | null,
	sizes__small__filesize?: ResolverInputTypes["Banner_Mobile_sizes__small__filesize_operator"] | undefined | null,
	sizes__small__filename?: ResolverInputTypes["Banner_Mobile_sizes__small__filename_operator"] | undefined | null,
	sizes__medium__url?: ResolverInputTypes["Banner_Mobile_sizes__medium__url_operator"] | undefined | null,
	sizes__medium__width?: ResolverInputTypes["Banner_Mobile_sizes__medium__width_operator"] | undefined | null,
	sizes__medium__height?: ResolverInputTypes["Banner_Mobile_sizes__medium__height_operator"] | undefined | null,
	sizes__medium__mimeType?: ResolverInputTypes["Banner_Mobile_sizes__medium__mimeType_operator"] | undefined | null,
	sizes__medium__filesize?: ResolverInputTypes["Banner_Mobile_sizes__medium__filesize_operator"] | undefined | null,
	sizes__medium__filename?: ResolverInputTypes["Banner_Mobile_sizes__medium__filename_operator"] | undefined | null,
	sizes__large__url?: ResolverInputTypes["Banner_Mobile_sizes__large__url_operator"] | undefined | null,
	sizes__large__width?: ResolverInputTypes["Banner_Mobile_sizes__large__width_operator"] | undefined | null,
	sizes__large__height?: ResolverInputTypes["Banner_Mobile_sizes__large__height_operator"] | undefined | null,
	sizes__large__mimeType?: ResolverInputTypes["Banner_Mobile_sizes__large__mimeType_operator"] | undefined | null,
	sizes__large__filesize?: ResolverInputTypes["Banner_Mobile_sizes__large__filesize_operator"] | undefined | null,
	sizes__large__filename?: ResolverInputTypes["Banner_Mobile_sizes__large__filename_operator"] | undefined | null,
	sizes__small_jpeg__url?: ResolverInputTypes["Banner_Mobile_sizes__small_jpeg__url_operator"] | undefined | null,
	sizes__small_jpeg__width?: ResolverInputTypes["Banner_Mobile_sizes__small_jpeg__width_operator"] | undefined | null,
	sizes__small_jpeg__height?: ResolverInputTypes["Banner_Mobile_sizes__small_jpeg__height_operator"] | undefined | null,
	sizes__small_jpeg__mimeType?: ResolverInputTypes["Banner_Mobile_sizes__small_jpeg__mimeType_operator"] | undefined | null,
	sizes__small_jpeg__filesize?: ResolverInputTypes["Banner_Mobile_sizes__small_jpeg__filesize_operator"] | undefined | null,
	sizes__small_jpeg__filename?: ResolverInputTypes["Banner_Mobile_sizes__small_jpeg__filename_operator"] | undefined | null,
	sizes__medium_jpeg__url?: ResolverInputTypes["Banner_Mobile_sizes__medium_jpeg__url_operator"] | undefined | null,
	sizes__medium_jpeg__width?: ResolverInputTypes["Banner_Mobile_sizes__medium_jpeg__width_operator"] | undefined | null,
	sizes__medium_jpeg__height?: ResolverInputTypes["Banner_Mobile_sizes__medium_jpeg__height_operator"] | undefined | null,
	sizes__medium_jpeg__mimeType?: ResolverInputTypes["Banner_Mobile_sizes__medium_jpeg__mimeType_operator"] | undefined | null,
	sizes__medium_jpeg__filesize?: ResolverInputTypes["Banner_Mobile_sizes__medium_jpeg__filesize_operator"] | undefined | null,
	sizes__medium_jpeg__filename?: ResolverInputTypes["Banner_Mobile_sizes__medium_jpeg__filename_operator"] | undefined | null,
	sizes__large_jpeg__url?: ResolverInputTypes["Banner_Mobile_sizes__large_jpeg__url_operator"] | undefined | null,
	sizes__large_jpeg__width?: ResolverInputTypes["Banner_Mobile_sizes__large_jpeg__width_operator"] | undefined | null,
	sizes__large_jpeg__height?: ResolverInputTypes["Banner_Mobile_sizes__large_jpeg__height_operator"] | undefined | null,
	sizes__large_jpeg__mimeType?: ResolverInputTypes["Banner_Mobile_sizes__large_jpeg__mimeType_operator"] | undefined | null,
	sizes__large_jpeg__filesize?: ResolverInputTypes["Banner_Mobile_sizes__large_jpeg__filesize_operator"] | undefined | null,
	sizes__large_jpeg__filename?: ResolverInputTypes["Banner_Mobile_sizes__large_jpeg__filename_operator"] | undefined | null,
	id?: ResolverInputTypes["Banner_Mobile_id_operator"] | undefined | null,
	AND?: Array<ResolverInputTypes["Banner_Mobile_where_and"] | undefined | null> | undefined | null,
	OR?: Array<ResolverInputTypes["Banner_Mobile_where_or"] | undefined | null> | undefined | null
};
	["Banner_Location"]: AliasType<{
	page?:boolean | `@${string}`,
eventRankingList?: [{	locale?: ResolverInputTypes["LocaleInputType"] | undefined | null,	fallbackLocale?: ResolverInputTypes["FallbackLocaleInputType"] | undefined | null},ResolverInputTypes["EventRanking"]],
locationRankingList?: [{	locale?: ResolverInputTypes["LocaleInputType"] | undefined | null,	fallbackLocale?: ResolverInputTypes["FallbackLocaleInputType"] | undefined | null},ResolverInputTypes["LocationRanking"]],
categories?: [{	locale?: ResolverInputTypes["LocaleInputType"] | undefined | null,	fallbackLocale?: ResolverInputTypes["FallbackLocaleInputType"] | undefined | null},ResolverInputTypes["EventCategory"]],
	time?:boolean | `@${string}`,
	district?:boolean | `@${string}`,
	audience?:boolean | `@${string}`,
	multipleCriteriaSearch?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["Banner_Location_page"]:Banner_Location_page;
	["Banner_type"]:Banner_type;
	["Banner_status"]:Banner_status;
	["Banner_Advertise"]: AliasType<{
campaign?: [{	locale?: ResolverInputTypes["LocaleInputType"] | undefined | null,	fallbackLocale?: ResolverInputTypes["FallbackLocaleInputType"] | undefined | null},ResolverInputTypes["Campaign"]],
	invoiceDescription?:boolean | `@${string}`,
	standardPrice?:boolean | `@${string}`,
	discount?:boolean | `@${string}`,
	timableCost?:boolean | `@${string}`,
	remark?:boolean | `@${string}`,
	public?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["Campaign"]: AliasType<{
	id?:boolean | `@${string}`,
creator?: [{	locale?: ResolverInputTypes["LocaleInputType"] | undefined | null,	fallbackLocale?: ResolverInputTypes["FallbackLocaleInputType"] | undefined | null},ResolverInputTypes["Campaign_Creator_Relationship"]],
	invoiceNumber?:boolean | `@${string}`,
	quotationNumber?:boolean | `@${string}`,
	campaignName?:boolean | `@${string}`,
	client?:boolean | `@${string}`,
	contactPerson?:boolean | `@${string}`,
	email?:boolean | `@${string}`,
	phone?:boolean | `@${string}`,
	agentName?:boolean | `@${string}`,
	agentCommission?:boolean | `@${string}`,
	agentOrderNumber?:boolean | `@${string}`,
	_firstPublishedTime?:boolean | `@${string}`,
	updatedAt?:boolean | `@${string}`,
	createdAt?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["Campaign_Creator_Relationship"]: AliasType<{
	relationTo?:boolean | `@${string}`,
	value?:ResolverInputTypes["Campaign_Creator"],
		__typename?: boolean | `@${string}`
}>;
	["Campaign_Creator_RelationTo"]:Campaign_Creator_RelationTo;
	["Campaign_Creator"]: AliasType<{
	User?:ResolverInputTypes["User"],
		__typename?: boolean | `@${string}`
}>;
	["Banner_region"]:Banner_region;
	["Banners"]: AliasType<{
	docs?:ResolverInputTypes["Banner"],
	hasNextPage?:boolean | `@${string}`,
	hasPrevPage?:boolean | `@${string}`,
	limit?:boolean | `@${string}`,
	nextPage?:boolean | `@${string}`,
	offset?:boolean | `@${string}`,
	page?:boolean | `@${string}`,
	pagingCounter?:boolean | `@${string}`,
	prevPage?:boolean | `@${string}`,
	totalDocs?:boolean | `@${string}`,
	totalPages?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["Banner_where"]: {
	name?: ResolverInputTypes["Banner_name_operator"] | undefined | null,
	desktop?: ResolverInputTypes["Banner_desktop_operator"] | undefined | null,
	mobile?: ResolverInputTypes["Banner_mobile_operator"] | undefined | null,
	bannerStartDate?: ResolverInputTypes["Banner_bannerStartDate_operator"] | undefined | null,
	bannerEndDate?: ResolverInputTypes["Banner_bannerEndDate_operator"] | undefined | null,
	location__page?: ResolverInputTypes["Banner_location__page_operator"] | undefined | null,
	location__eventRankingList?: ResolverInputTypes["Banner_location__eventRankingList_operator"] | undefined | null,
	location__locationRankingList?: ResolverInputTypes["Banner_location__locationRankingList_operator"] | undefined | null,
	location__categories?: ResolverInputTypes["Banner_location__categories_operator"] | undefined | null,
	location__time?: ResolverInputTypes["Banner_location__time_operator"] | undefined | null,
	location__district?: ResolverInputTypes["Banner_location__district_operator"] | undefined | null,
	location__audience?: ResolverInputTypes["Banner_location__audience_operator"] | undefined | null,
	location__multipleCriteriaSearch?: ResolverInputTypes["Banner_location__multipleCriteriaSearch_operator"] | undefined | null,
	link?: ResolverInputTypes["Banner_link_operator"] | undefined | null,
	external?: ResolverInputTypes["Banner_external_operator"] | undefined | null,
	type?: ResolverInputTypes["Banner_type_operator"] | undefined | null,
	status?: ResolverInputTypes["Banner_status_operator"] | undefined | null,
	advertise__campaign?: ResolverInputTypes["Banner_advertise__campaign_operator"] | undefined | null,
	advertise__invoiceDescription?: ResolverInputTypes["Banner_advertise__invoiceDescription_operator"] | undefined | null,
	advertise__standardPrice?: ResolverInputTypes["Banner_advertise__standardPrice_operator"] | undefined | null,
	advertise__discount?: ResolverInputTypes["Banner_advertise__discount_operator"] | undefined | null,
	advertise__timableCost?: ResolverInputTypes["Banner_advertise__timableCost_operator"] | undefined | null,
	advertise__remark?: ResolverInputTypes["Banner_advertise__remark_operator"] | undefined | null,
	advertise__public?: ResolverInputTypes["Banner_advertise__public_operator"] | undefined | null,
	_locations?: ResolverInputTypes["Banner__locations_operator"] | undefined | null,
	region?: ResolverInputTypes["Banner_region_operator"] | undefined | null,
	homePageClick?: ResolverInputTypes["Banner_homePageClick_operator"] | undefined | null,
	homePageImpression?: ResolverInputTypes["Banner_homePageImpression_operator"] | undefined | null,
	latestEventClick?: ResolverInputTypes["Banner_latestEventClick_operator"] | undefined | null,
	latestEventImpression?: ResolverInputTypes["Banner_latestEventImpression_operator"] | undefined | null,
	editorChoiceClick?: ResolverInputTypes["Banner_editorChoiceClick_operator"] | undefined | null,
	editorChoiceImpression?: ResolverInputTypes["Banner_editorChoiceImpression_operator"] | undefined | null,
	eventListClick?: ResolverInputTypes["Banner_eventListClick_operator"] | undefined | null,
	eventListImpression?: ResolverInputTypes["Banner_eventListImpression_operator"] | undefined | null,
	topTenClick?: ResolverInputTypes["Banner_topTenClick_operator"] | undefined | null,
	topTenImpression?: ResolverInputTypes["Banner_topTenImpression_operator"] | undefined | null,
	miscClick?: ResolverInputTypes["Banner_miscClick_operator"] | undefined | null,
	miscImpression?: ResolverInputTypes["Banner_miscImpression_operator"] | undefined | null,
	clickTotal?: ResolverInputTypes["Banner_clickTotal_operator"] | undefined | null,
	totalImpression?: ResolverInputTypes["Banner_totalImpression_operator"] | undefined | null,
	updatedAt?: ResolverInputTypes["Banner_updatedAt_operator"] | undefined | null,
	createdAt?: ResolverInputTypes["Banner_createdAt_operator"] | undefined | null,
	id?: ResolverInputTypes["Banner_id_operator"] | undefined | null,
	AND?: Array<ResolverInputTypes["Banner_where_and"] | undefined | null> | undefined | null,
	OR?: Array<ResolverInputTypes["Banner_where_or"] | undefined | null> | undefined | null
};
	["Banner_name_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null
};
	["Banner_desktop_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null
};
	["Banner_mobile_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null
};
	["Banner_bannerStartDate_operator"]: {
	equals?: ResolverInputTypes["DateTime"] | undefined | null,
	not_equals?: ResolverInputTypes["DateTime"] | undefined | null,
	greater_than_equal?: ResolverInputTypes["DateTime"] | undefined | null,
	greater_than?: ResolverInputTypes["DateTime"] | undefined | null,
	less_than_equal?: ResolverInputTypes["DateTime"] | undefined | null,
	less_than?: ResolverInputTypes["DateTime"] | undefined | null,
	like?: ResolverInputTypes["DateTime"] | undefined | null
};
	["Banner_bannerEndDate_operator"]: {
	equals?: ResolverInputTypes["DateTime"] | undefined | null,
	not_equals?: ResolverInputTypes["DateTime"] | undefined | null,
	greater_than_equal?: ResolverInputTypes["DateTime"] | undefined | null,
	greater_than?: ResolverInputTypes["DateTime"] | undefined | null,
	less_than_equal?: ResolverInputTypes["DateTime"] | undefined | null,
	less_than?: ResolverInputTypes["DateTime"] | undefined | null,
	like?: ResolverInputTypes["DateTime"] | undefined | null
};
	["Banner_location__page_operator"]: {
	equals?: ResolverInputTypes["Banner_location__page_Input"] | undefined | null,
	not_equals?: ResolverInputTypes["Banner_location__page_Input"] | undefined | null,
	in?: Array<ResolverInputTypes["Banner_location__page_Input"] | undefined | null> | undefined | null,
	not_in?: Array<ResolverInputTypes["Banner_location__page_Input"] | undefined | null> | undefined | null,
	all?: Array<ResolverInputTypes["Banner_location__page_Input"] | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Banner_location__page_Input"]:Banner_location__page_Input;
	["Banner_location__eventRankingList_operator"]: {
	equals?: ResolverInputTypes["JSON"] | undefined | null,
	not_equals?: ResolverInputTypes["JSON"] | undefined | null,
	in?: Array<ResolverInputTypes["JSON"] | undefined | null> | undefined | null,
	not_in?: Array<ResolverInputTypes["JSON"] | undefined | null> | undefined | null,
	all?: Array<ResolverInputTypes["JSON"] | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Banner_location__locationRankingList_operator"]: {
	equals?: ResolverInputTypes["JSON"] | undefined | null,
	not_equals?: ResolverInputTypes["JSON"] | undefined | null,
	in?: Array<ResolverInputTypes["JSON"] | undefined | null> | undefined | null,
	not_in?: Array<ResolverInputTypes["JSON"] | undefined | null> | undefined | null,
	all?: Array<ResolverInputTypes["JSON"] | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Banner_location__categories_operator"]: {
	equals?: ResolverInputTypes["JSON"] | undefined | null,
	not_equals?: ResolverInputTypes["JSON"] | undefined | null,
	in?: Array<ResolverInputTypes["JSON"] | undefined | null> | undefined | null,
	not_in?: Array<ResolverInputTypes["JSON"] | undefined | null> | undefined | null,
	all?: Array<ResolverInputTypes["JSON"] | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Banner_location__time_operator"]: {
	equals?: boolean | undefined | null,
	not_equals?: boolean | undefined | null,
	exists?: boolean | undefined | null
};
	["Banner_location__district_operator"]: {
	equals?: boolean | undefined | null,
	not_equals?: boolean | undefined | null,
	exists?: boolean | undefined | null
};
	["Banner_location__audience_operator"]: {
	equals?: boolean | undefined | null,
	not_equals?: boolean | undefined | null,
	exists?: boolean | undefined | null
};
	["Banner_location__multipleCriteriaSearch_operator"]: {
	equals?: boolean | undefined | null,
	not_equals?: boolean | undefined | null,
	exists?: boolean | undefined | null
};
	["Banner_link_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Banner_external_operator"]: {
	equals?: boolean | undefined | null,
	not_equals?: boolean | undefined | null,
	exists?: boolean | undefined | null
};
	["Banner_type_operator"]: {
	equals?: ResolverInputTypes["Banner_type_Input"] | undefined | null,
	not_equals?: ResolverInputTypes["Banner_type_Input"] | undefined | null,
	in?: Array<ResolverInputTypes["Banner_type_Input"] | undefined | null> | undefined | null,
	not_in?: Array<ResolverInputTypes["Banner_type_Input"] | undefined | null> | undefined | null,
	all?: Array<ResolverInputTypes["Banner_type_Input"] | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Banner_type_Input"]:Banner_type_Input;
	["Banner_status_operator"]: {
	equals?: ResolverInputTypes["Banner_status_Input"] | undefined | null,
	not_equals?: ResolverInputTypes["Banner_status_Input"] | undefined | null,
	in?: Array<ResolverInputTypes["Banner_status_Input"] | undefined | null> | undefined | null,
	not_in?: Array<ResolverInputTypes["Banner_status_Input"] | undefined | null> | undefined | null,
	all?: Array<ResolverInputTypes["Banner_status_Input"] | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Banner_status_Input"]:Banner_status_Input;
	["Banner_advertise__campaign_operator"]: {
	equals?: ResolverInputTypes["JSON"] | undefined | null,
	not_equals?: ResolverInputTypes["JSON"] | undefined | null,
	in?: Array<ResolverInputTypes["JSON"] | undefined | null> | undefined | null,
	not_in?: Array<ResolverInputTypes["JSON"] | undefined | null> | undefined | null,
	all?: Array<ResolverInputTypes["JSON"] | undefined | null> | undefined | null
};
	["Banner_advertise__invoiceDescription_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	exists?: boolean | undefined | null
};
	["Banner_advertise__standardPrice_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null
};
	["Banner_advertise__discount_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Banner_advertise__timableCost_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Banner_advertise__remark_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	exists?: boolean | undefined | null
};
	["Banner_advertise__public_operator"]: {
	equals?: boolean | undefined | null,
	not_equals?: boolean | undefined | null,
	exists?: boolean | undefined | null
};
	["Banner__locations_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Banner_region_operator"]: {
	equals?: ResolverInputTypes["Banner_region_Input"] | undefined | null,
	not_equals?: ResolverInputTypes["Banner_region_Input"] | undefined | null,
	in?: Array<ResolverInputTypes["Banner_region_Input"] | undefined | null> | undefined | null,
	not_in?: Array<ResolverInputTypes["Banner_region_Input"] | undefined | null> | undefined | null,
	all?: Array<ResolverInputTypes["Banner_region_Input"] | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Banner_region_Input"]:Banner_region_Input;
	["Banner_homePageClick_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Banner_homePageImpression_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Banner_latestEventClick_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Banner_latestEventImpression_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Banner_editorChoiceClick_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Banner_editorChoiceImpression_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Banner_eventListClick_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Banner_eventListImpression_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Banner_topTenClick_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Banner_topTenImpression_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Banner_miscClick_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Banner_miscImpression_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Banner_clickTotal_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Banner_totalImpression_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Banner_updatedAt_operator"]: {
	equals?: ResolverInputTypes["DateTime"] | undefined | null,
	not_equals?: ResolverInputTypes["DateTime"] | undefined | null,
	greater_than_equal?: ResolverInputTypes["DateTime"] | undefined | null,
	greater_than?: ResolverInputTypes["DateTime"] | undefined | null,
	less_than_equal?: ResolverInputTypes["DateTime"] | undefined | null,
	less_than?: ResolverInputTypes["DateTime"] | undefined | null,
	like?: ResolverInputTypes["DateTime"] | undefined | null,
	exists?: boolean | undefined | null
};
	["Banner_createdAt_operator"]: {
	equals?: ResolverInputTypes["DateTime"] | undefined | null,
	not_equals?: ResolverInputTypes["DateTime"] | undefined | null,
	greater_than_equal?: ResolverInputTypes["DateTime"] | undefined | null,
	greater_than?: ResolverInputTypes["DateTime"] | undefined | null,
	less_than_equal?: ResolverInputTypes["DateTime"] | undefined | null,
	less_than?: ResolverInputTypes["DateTime"] | undefined | null,
	like?: ResolverInputTypes["DateTime"] | undefined | null,
	exists?: boolean | undefined | null
};
	["Banner_id_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Banner_where_and"]: {
	name?: ResolverInputTypes["Banner_name_operator"] | undefined | null,
	desktop?: ResolverInputTypes["Banner_desktop_operator"] | undefined | null,
	mobile?: ResolverInputTypes["Banner_mobile_operator"] | undefined | null,
	bannerStartDate?: ResolverInputTypes["Banner_bannerStartDate_operator"] | undefined | null,
	bannerEndDate?: ResolverInputTypes["Banner_bannerEndDate_operator"] | undefined | null,
	location__page?: ResolverInputTypes["Banner_location__page_operator"] | undefined | null,
	location__eventRankingList?: ResolverInputTypes["Banner_location__eventRankingList_operator"] | undefined | null,
	location__locationRankingList?: ResolverInputTypes["Banner_location__locationRankingList_operator"] | undefined | null,
	location__categories?: ResolverInputTypes["Banner_location__categories_operator"] | undefined | null,
	location__time?: ResolverInputTypes["Banner_location__time_operator"] | undefined | null,
	location__district?: ResolverInputTypes["Banner_location__district_operator"] | undefined | null,
	location__audience?: ResolverInputTypes["Banner_location__audience_operator"] | undefined | null,
	location__multipleCriteriaSearch?: ResolverInputTypes["Banner_location__multipleCriteriaSearch_operator"] | undefined | null,
	link?: ResolverInputTypes["Banner_link_operator"] | undefined | null,
	external?: ResolverInputTypes["Banner_external_operator"] | undefined | null,
	type?: ResolverInputTypes["Banner_type_operator"] | undefined | null,
	status?: ResolverInputTypes["Banner_status_operator"] | undefined | null,
	advertise__campaign?: ResolverInputTypes["Banner_advertise__campaign_operator"] | undefined | null,
	advertise__invoiceDescription?: ResolverInputTypes["Banner_advertise__invoiceDescription_operator"] | undefined | null,
	advertise__standardPrice?: ResolverInputTypes["Banner_advertise__standardPrice_operator"] | undefined | null,
	advertise__discount?: ResolverInputTypes["Banner_advertise__discount_operator"] | undefined | null,
	advertise__timableCost?: ResolverInputTypes["Banner_advertise__timableCost_operator"] | undefined | null,
	advertise__remark?: ResolverInputTypes["Banner_advertise__remark_operator"] | undefined | null,
	advertise__public?: ResolverInputTypes["Banner_advertise__public_operator"] | undefined | null,
	_locations?: ResolverInputTypes["Banner__locations_operator"] | undefined | null,
	region?: ResolverInputTypes["Banner_region_operator"] | undefined | null,
	homePageClick?: ResolverInputTypes["Banner_homePageClick_operator"] | undefined | null,
	homePageImpression?: ResolverInputTypes["Banner_homePageImpression_operator"] | undefined | null,
	latestEventClick?: ResolverInputTypes["Banner_latestEventClick_operator"] | undefined | null,
	latestEventImpression?: ResolverInputTypes["Banner_latestEventImpression_operator"] | undefined | null,
	editorChoiceClick?: ResolverInputTypes["Banner_editorChoiceClick_operator"] | undefined | null,
	editorChoiceImpression?: ResolverInputTypes["Banner_editorChoiceImpression_operator"] | undefined | null,
	eventListClick?: ResolverInputTypes["Banner_eventListClick_operator"] | undefined | null,
	eventListImpression?: ResolverInputTypes["Banner_eventListImpression_operator"] | undefined | null,
	topTenClick?: ResolverInputTypes["Banner_topTenClick_operator"] | undefined | null,
	topTenImpression?: ResolverInputTypes["Banner_topTenImpression_operator"] | undefined | null,
	miscClick?: ResolverInputTypes["Banner_miscClick_operator"] | undefined | null,
	miscImpression?: ResolverInputTypes["Banner_miscImpression_operator"] | undefined | null,
	clickTotal?: ResolverInputTypes["Banner_clickTotal_operator"] | undefined | null,
	totalImpression?: ResolverInputTypes["Banner_totalImpression_operator"] | undefined | null,
	updatedAt?: ResolverInputTypes["Banner_updatedAt_operator"] | undefined | null,
	createdAt?: ResolverInputTypes["Banner_createdAt_operator"] | undefined | null,
	id?: ResolverInputTypes["Banner_id_operator"] | undefined | null,
	AND?: Array<ResolverInputTypes["Banner_where_and"] | undefined | null> | undefined | null,
	OR?: Array<ResolverInputTypes["Banner_where_or"] | undefined | null> | undefined | null
};
	["Banner_where_or"]: {
	name?: ResolverInputTypes["Banner_name_operator"] | undefined | null,
	desktop?: ResolverInputTypes["Banner_desktop_operator"] | undefined | null,
	mobile?: ResolverInputTypes["Banner_mobile_operator"] | undefined | null,
	bannerStartDate?: ResolverInputTypes["Banner_bannerStartDate_operator"] | undefined | null,
	bannerEndDate?: ResolverInputTypes["Banner_bannerEndDate_operator"] | undefined | null,
	location__page?: ResolverInputTypes["Banner_location__page_operator"] | undefined | null,
	location__eventRankingList?: ResolverInputTypes["Banner_location__eventRankingList_operator"] | undefined | null,
	location__locationRankingList?: ResolverInputTypes["Banner_location__locationRankingList_operator"] | undefined | null,
	location__categories?: ResolverInputTypes["Banner_location__categories_operator"] | undefined | null,
	location__time?: ResolverInputTypes["Banner_location__time_operator"] | undefined | null,
	location__district?: ResolverInputTypes["Banner_location__district_operator"] | undefined | null,
	location__audience?: ResolverInputTypes["Banner_location__audience_operator"] | undefined | null,
	location__multipleCriteriaSearch?: ResolverInputTypes["Banner_location__multipleCriteriaSearch_operator"] | undefined | null,
	link?: ResolverInputTypes["Banner_link_operator"] | undefined | null,
	external?: ResolverInputTypes["Banner_external_operator"] | undefined | null,
	type?: ResolverInputTypes["Banner_type_operator"] | undefined | null,
	status?: ResolverInputTypes["Banner_status_operator"] | undefined | null,
	advertise__campaign?: ResolverInputTypes["Banner_advertise__campaign_operator"] | undefined | null,
	advertise__invoiceDescription?: ResolverInputTypes["Banner_advertise__invoiceDescription_operator"] | undefined | null,
	advertise__standardPrice?: ResolverInputTypes["Banner_advertise__standardPrice_operator"] | undefined | null,
	advertise__discount?: ResolverInputTypes["Banner_advertise__discount_operator"] | undefined | null,
	advertise__timableCost?: ResolverInputTypes["Banner_advertise__timableCost_operator"] | undefined | null,
	advertise__remark?: ResolverInputTypes["Banner_advertise__remark_operator"] | undefined | null,
	advertise__public?: ResolverInputTypes["Banner_advertise__public_operator"] | undefined | null,
	_locations?: ResolverInputTypes["Banner__locations_operator"] | undefined | null,
	region?: ResolverInputTypes["Banner_region_operator"] | undefined | null,
	homePageClick?: ResolverInputTypes["Banner_homePageClick_operator"] | undefined | null,
	homePageImpression?: ResolverInputTypes["Banner_homePageImpression_operator"] | undefined | null,
	latestEventClick?: ResolverInputTypes["Banner_latestEventClick_operator"] | undefined | null,
	latestEventImpression?: ResolverInputTypes["Banner_latestEventImpression_operator"] | undefined | null,
	editorChoiceClick?: ResolverInputTypes["Banner_editorChoiceClick_operator"] | undefined | null,
	editorChoiceImpression?: ResolverInputTypes["Banner_editorChoiceImpression_operator"] | undefined | null,
	eventListClick?: ResolverInputTypes["Banner_eventListClick_operator"] | undefined | null,
	eventListImpression?: ResolverInputTypes["Banner_eventListImpression_operator"] | undefined | null,
	topTenClick?: ResolverInputTypes["Banner_topTenClick_operator"] | undefined | null,
	topTenImpression?: ResolverInputTypes["Banner_topTenImpression_operator"] | undefined | null,
	miscClick?: ResolverInputTypes["Banner_miscClick_operator"] | undefined | null,
	miscImpression?: ResolverInputTypes["Banner_miscImpression_operator"] | undefined | null,
	clickTotal?: ResolverInputTypes["Banner_clickTotal_operator"] | undefined | null,
	totalImpression?: ResolverInputTypes["Banner_totalImpression_operator"] | undefined | null,
	updatedAt?: ResolverInputTypes["Banner_updatedAt_operator"] | undefined | null,
	createdAt?: ResolverInputTypes["Banner_createdAt_operator"] | undefined | null,
	id?: ResolverInputTypes["Banner_id_operator"] | undefined | null,
	AND?: Array<ResolverInputTypes["Banner_where_and"] | undefined | null> | undefined | null,
	OR?: Array<ResolverInputTypes["Banner_where_or"] | undefined | null> | undefined | null
};
	["countBanners"]: AliasType<{
	totalDocs?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["bannersDocAccess"]: AliasType<{
	fields?:ResolverInputTypes["BannersDocAccessFields"],
	create?:ResolverInputTypes["BannersCreateDocAccess"],
	read?:ResolverInputTypes["BannersReadDocAccess"],
	update?:ResolverInputTypes["BannersUpdateDocAccess"],
	delete?:ResolverInputTypes["BannersDeleteDocAccess"],
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields"]: AliasType<{
	name?:ResolverInputTypes["BannersDocAccessFields_name"],
	desktop?:ResolverInputTypes["BannersDocAccessFields_desktop"],
	mobile?:ResolverInputTypes["BannersDocAccessFields_mobile"],
	bannerStartDate?:ResolverInputTypes["BannersDocAccessFields_bannerStartDate"],
	bannerEndDate?:ResolverInputTypes["BannersDocAccessFields_bannerEndDate"],
	location?:ResolverInputTypes["BannersDocAccessFields_location"],
	link?:ResolverInputTypes["BannersDocAccessFields_link"],
	external?:ResolverInputTypes["BannersDocAccessFields_external"],
	type?:ResolverInputTypes["BannersDocAccessFields_type"],
	status?:ResolverInputTypes["BannersDocAccessFields_status"],
	advertise?:ResolverInputTypes["BannersDocAccessFields_advertise"],
	_locations?:ResolverInputTypes["BannersDocAccessFields__locations"],
	region?:ResolverInputTypes["BannersDocAccessFields_region"],
	homePageClick?:ResolverInputTypes["BannersDocAccessFields_homePageClick"],
	homePageImpression?:ResolverInputTypes["BannersDocAccessFields_homePageImpression"],
	latestEventClick?:ResolverInputTypes["BannersDocAccessFields_latestEventClick"],
	latestEventImpression?:ResolverInputTypes["BannersDocAccessFields_latestEventImpression"],
	editorChoiceClick?:ResolverInputTypes["BannersDocAccessFields_editorChoiceClick"],
	editorChoiceImpression?:ResolverInputTypes["BannersDocAccessFields_editorChoiceImpression"],
	eventListClick?:ResolverInputTypes["BannersDocAccessFields_eventListClick"],
	eventListImpression?:ResolverInputTypes["BannersDocAccessFields_eventListImpression"],
	topTenClick?:ResolverInputTypes["BannersDocAccessFields_topTenClick"],
	topTenImpression?:ResolverInputTypes["BannersDocAccessFields_topTenImpression"],
	miscClick?:ResolverInputTypes["BannersDocAccessFields_miscClick"],
	miscImpression?:ResolverInputTypes["BannersDocAccessFields_miscImpression"],
	clickTotal?:ResolverInputTypes["BannersDocAccessFields_clickTotal"],
	totalImpression?:ResolverInputTypes["BannersDocAccessFields_totalImpression"],
	updatedAt?:ResolverInputTypes["BannersDocAccessFields_updatedAt"],
	createdAt?:ResolverInputTypes["BannersDocAccessFields_createdAt"],
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_name"]: AliasType<{
	create?:ResolverInputTypes["BannersDocAccessFields_name_Create"],
	read?:ResolverInputTypes["BannersDocAccessFields_name_Read"],
	update?:ResolverInputTypes["BannersDocAccessFields_name_Update"],
	delete?:ResolverInputTypes["BannersDocAccessFields_name_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_name_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_name_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_name_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_name_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_desktop"]: AliasType<{
	create?:ResolverInputTypes["BannersDocAccessFields_desktop_Create"],
	read?:ResolverInputTypes["BannersDocAccessFields_desktop_Read"],
	update?:ResolverInputTypes["BannersDocAccessFields_desktop_Update"],
	delete?:ResolverInputTypes["BannersDocAccessFields_desktop_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_desktop_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_desktop_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_desktop_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_desktop_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_mobile"]: AliasType<{
	create?:ResolverInputTypes["BannersDocAccessFields_mobile_Create"],
	read?:ResolverInputTypes["BannersDocAccessFields_mobile_Read"],
	update?:ResolverInputTypes["BannersDocAccessFields_mobile_Update"],
	delete?:ResolverInputTypes["BannersDocAccessFields_mobile_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_mobile_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_mobile_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_mobile_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_mobile_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_bannerStartDate"]: AliasType<{
	create?:ResolverInputTypes["BannersDocAccessFields_bannerStartDate_Create"],
	read?:ResolverInputTypes["BannersDocAccessFields_bannerStartDate_Read"],
	update?:ResolverInputTypes["BannersDocAccessFields_bannerStartDate_Update"],
	delete?:ResolverInputTypes["BannersDocAccessFields_bannerStartDate_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_bannerStartDate_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_bannerStartDate_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_bannerStartDate_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_bannerStartDate_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_bannerEndDate"]: AliasType<{
	create?:ResolverInputTypes["BannersDocAccessFields_bannerEndDate_Create"],
	read?:ResolverInputTypes["BannersDocAccessFields_bannerEndDate_Read"],
	update?:ResolverInputTypes["BannersDocAccessFields_bannerEndDate_Update"],
	delete?:ResolverInputTypes["BannersDocAccessFields_bannerEndDate_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_bannerEndDate_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_bannerEndDate_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_bannerEndDate_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_bannerEndDate_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_location"]: AliasType<{
	create?:ResolverInputTypes["BannersDocAccessFields_location_Create"],
	read?:ResolverInputTypes["BannersDocAccessFields_location_Read"],
	update?:ResolverInputTypes["BannersDocAccessFields_location_Update"],
	delete?:ResolverInputTypes["BannersDocAccessFields_location_Delete"],
	fields?:ResolverInputTypes["BannersDocAccessFields_location_Fields"],
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_location_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_location_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_location_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_location_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_location_Fields"]: AliasType<{
	page?:ResolverInputTypes["BannersDocAccessFields_location_page"],
	eventRankingList?:ResolverInputTypes["BannersDocAccessFields_location_eventRankingList"],
	locationRankingList?:ResolverInputTypes["BannersDocAccessFields_location_locationRankingList"],
	categories?:ResolverInputTypes["BannersDocAccessFields_location_categories"],
	time?:ResolverInputTypes["BannersDocAccessFields_location_time"],
	district?:ResolverInputTypes["BannersDocAccessFields_location_district"],
	audience?:ResolverInputTypes["BannersDocAccessFields_location_audience"],
	multipleCriteriaSearch?:ResolverInputTypes["BannersDocAccessFields_location_multipleCriteriaSearch"],
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_location_page"]: AliasType<{
	create?:ResolverInputTypes["BannersDocAccessFields_location_page_Create"],
	read?:ResolverInputTypes["BannersDocAccessFields_location_page_Read"],
	update?:ResolverInputTypes["BannersDocAccessFields_location_page_Update"],
	delete?:ResolverInputTypes["BannersDocAccessFields_location_page_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_location_page_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_location_page_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_location_page_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_location_page_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_location_eventRankingList"]: AliasType<{
	create?:ResolverInputTypes["BannersDocAccessFields_location_eventRankingList_Create"],
	read?:ResolverInputTypes["BannersDocAccessFields_location_eventRankingList_Read"],
	update?:ResolverInputTypes["BannersDocAccessFields_location_eventRankingList_Update"],
	delete?:ResolverInputTypes["BannersDocAccessFields_location_eventRankingList_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_location_eventRankingList_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_location_eventRankingList_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_location_eventRankingList_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_location_eventRankingList_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_location_locationRankingList"]: AliasType<{
	create?:ResolverInputTypes["BannersDocAccessFields_location_locationRankingList_Create"],
	read?:ResolverInputTypes["BannersDocAccessFields_location_locationRankingList_Read"],
	update?:ResolverInputTypes["BannersDocAccessFields_location_locationRankingList_Update"],
	delete?:ResolverInputTypes["BannersDocAccessFields_location_locationRankingList_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_location_locationRankingList_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_location_locationRankingList_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_location_locationRankingList_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_location_locationRankingList_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_location_categories"]: AliasType<{
	create?:ResolverInputTypes["BannersDocAccessFields_location_categories_Create"],
	read?:ResolverInputTypes["BannersDocAccessFields_location_categories_Read"],
	update?:ResolverInputTypes["BannersDocAccessFields_location_categories_Update"],
	delete?:ResolverInputTypes["BannersDocAccessFields_location_categories_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_location_categories_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_location_categories_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_location_categories_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_location_categories_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_location_time"]: AliasType<{
	create?:ResolverInputTypes["BannersDocAccessFields_location_time_Create"],
	read?:ResolverInputTypes["BannersDocAccessFields_location_time_Read"],
	update?:ResolverInputTypes["BannersDocAccessFields_location_time_Update"],
	delete?:ResolverInputTypes["BannersDocAccessFields_location_time_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_location_time_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_location_time_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_location_time_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_location_time_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_location_district"]: AliasType<{
	create?:ResolverInputTypes["BannersDocAccessFields_location_district_Create"],
	read?:ResolverInputTypes["BannersDocAccessFields_location_district_Read"],
	update?:ResolverInputTypes["BannersDocAccessFields_location_district_Update"],
	delete?:ResolverInputTypes["BannersDocAccessFields_location_district_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_location_district_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_location_district_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_location_district_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_location_district_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_location_audience"]: AliasType<{
	create?:ResolverInputTypes["BannersDocAccessFields_location_audience_Create"],
	read?:ResolverInputTypes["BannersDocAccessFields_location_audience_Read"],
	update?:ResolverInputTypes["BannersDocAccessFields_location_audience_Update"],
	delete?:ResolverInputTypes["BannersDocAccessFields_location_audience_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_location_audience_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_location_audience_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_location_audience_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_location_audience_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_location_multipleCriteriaSearch"]: AliasType<{
	create?:ResolverInputTypes["BannersDocAccessFields_location_multipleCriteriaSearch_Create"],
	read?:ResolverInputTypes["BannersDocAccessFields_location_multipleCriteriaSearch_Read"],
	update?:ResolverInputTypes["BannersDocAccessFields_location_multipleCriteriaSearch_Update"],
	delete?:ResolverInputTypes["BannersDocAccessFields_location_multipleCriteriaSearch_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_location_multipleCriteriaSearch_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_location_multipleCriteriaSearch_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_location_multipleCriteriaSearch_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_location_multipleCriteriaSearch_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_link"]: AliasType<{
	create?:ResolverInputTypes["BannersDocAccessFields_link_Create"],
	read?:ResolverInputTypes["BannersDocAccessFields_link_Read"],
	update?:ResolverInputTypes["BannersDocAccessFields_link_Update"],
	delete?:ResolverInputTypes["BannersDocAccessFields_link_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_link_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_link_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_link_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_link_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_external"]: AliasType<{
	create?:ResolverInputTypes["BannersDocAccessFields_external_Create"],
	read?:ResolverInputTypes["BannersDocAccessFields_external_Read"],
	update?:ResolverInputTypes["BannersDocAccessFields_external_Update"],
	delete?:ResolverInputTypes["BannersDocAccessFields_external_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_external_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_external_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_external_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_external_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_type"]: AliasType<{
	create?:ResolverInputTypes["BannersDocAccessFields_type_Create"],
	read?:ResolverInputTypes["BannersDocAccessFields_type_Read"],
	update?:ResolverInputTypes["BannersDocAccessFields_type_Update"],
	delete?:ResolverInputTypes["BannersDocAccessFields_type_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_type_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_type_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_type_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_type_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_status"]: AliasType<{
	create?:ResolverInputTypes["BannersDocAccessFields_status_Create"],
	read?:ResolverInputTypes["BannersDocAccessFields_status_Read"],
	update?:ResolverInputTypes["BannersDocAccessFields_status_Update"],
	delete?:ResolverInputTypes["BannersDocAccessFields_status_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_status_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_status_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_status_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_status_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_advertise"]: AliasType<{
	create?:ResolverInputTypes["BannersDocAccessFields_advertise_Create"],
	read?:ResolverInputTypes["BannersDocAccessFields_advertise_Read"],
	update?:ResolverInputTypes["BannersDocAccessFields_advertise_Update"],
	delete?:ResolverInputTypes["BannersDocAccessFields_advertise_Delete"],
	fields?:ResolverInputTypes["BannersDocAccessFields_advertise_Fields"],
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_advertise_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_advertise_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_advertise_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_advertise_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_advertise_Fields"]: AliasType<{
	campaign?:ResolverInputTypes["BannersDocAccessFields_advertise_campaign"],
	invoiceDescription?:ResolverInputTypes["BannersDocAccessFields_advertise_invoiceDescription"],
	standardPrice?:ResolverInputTypes["BannersDocAccessFields_advertise_standardPrice"],
	discount?:ResolverInputTypes["BannersDocAccessFields_advertise_discount"],
	timableCost?:ResolverInputTypes["BannersDocAccessFields_advertise_timableCost"],
	remark?:ResolverInputTypes["BannersDocAccessFields_advertise_remark"],
	public?:ResolverInputTypes["BannersDocAccessFields_advertise_public"],
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_advertise_campaign"]: AliasType<{
	create?:ResolverInputTypes["BannersDocAccessFields_advertise_campaign_Create"],
	read?:ResolverInputTypes["BannersDocAccessFields_advertise_campaign_Read"],
	update?:ResolverInputTypes["BannersDocAccessFields_advertise_campaign_Update"],
	delete?:ResolverInputTypes["BannersDocAccessFields_advertise_campaign_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_advertise_campaign_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_advertise_campaign_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_advertise_campaign_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_advertise_campaign_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_advertise_invoiceDescription"]: AliasType<{
	create?:ResolverInputTypes["BannersDocAccessFields_advertise_invoiceDescription_Create"],
	read?:ResolverInputTypes["BannersDocAccessFields_advertise_invoiceDescription_Read"],
	update?:ResolverInputTypes["BannersDocAccessFields_advertise_invoiceDescription_Update"],
	delete?:ResolverInputTypes["BannersDocAccessFields_advertise_invoiceDescription_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_advertise_invoiceDescription_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_advertise_invoiceDescription_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_advertise_invoiceDescription_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_advertise_invoiceDescription_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_advertise_standardPrice"]: AliasType<{
	create?:ResolverInputTypes["BannersDocAccessFields_advertise_standardPrice_Create"],
	read?:ResolverInputTypes["BannersDocAccessFields_advertise_standardPrice_Read"],
	update?:ResolverInputTypes["BannersDocAccessFields_advertise_standardPrice_Update"],
	delete?:ResolverInputTypes["BannersDocAccessFields_advertise_standardPrice_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_advertise_standardPrice_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_advertise_standardPrice_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_advertise_standardPrice_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_advertise_standardPrice_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_advertise_discount"]: AliasType<{
	create?:ResolverInputTypes["BannersDocAccessFields_advertise_discount_Create"],
	read?:ResolverInputTypes["BannersDocAccessFields_advertise_discount_Read"],
	update?:ResolverInputTypes["BannersDocAccessFields_advertise_discount_Update"],
	delete?:ResolverInputTypes["BannersDocAccessFields_advertise_discount_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_advertise_discount_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_advertise_discount_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_advertise_discount_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_advertise_discount_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_advertise_timableCost"]: AliasType<{
	create?:ResolverInputTypes["BannersDocAccessFields_advertise_timableCost_Create"],
	read?:ResolverInputTypes["BannersDocAccessFields_advertise_timableCost_Read"],
	update?:ResolverInputTypes["BannersDocAccessFields_advertise_timableCost_Update"],
	delete?:ResolverInputTypes["BannersDocAccessFields_advertise_timableCost_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_advertise_timableCost_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_advertise_timableCost_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_advertise_timableCost_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_advertise_timableCost_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_advertise_remark"]: AliasType<{
	create?:ResolverInputTypes["BannersDocAccessFields_advertise_remark_Create"],
	read?:ResolverInputTypes["BannersDocAccessFields_advertise_remark_Read"],
	update?:ResolverInputTypes["BannersDocAccessFields_advertise_remark_Update"],
	delete?:ResolverInputTypes["BannersDocAccessFields_advertise_remark_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_advertise_remark_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_advertise_remark_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_advertise_remark_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_advertise_remark_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_advertise_public"]: AliasType<{
	create?:ResolverInputTypes["BannersDocAccessFields_advertise_public_Create"],
	read?:ResolverInputTypes["BannersDocAccessFields_advertise_public_Read"],
	update?:ResolverInputTypes["BannersDocAccessFields_advertise_public_Update"],
	delete?:ResolverInputTypes["BannersDocAccessFields_advertise_public_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_advertise_public_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_advertise_public_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_advertise_public_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_advertise_public_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields__locations"]: AliasType<{
	create?:ResolverInputTypes["BannersDocAccessFields__locations_Create"],
	read?:ResolverInputTypes["BannersDocAccessFields__locations_Read"],
	update?:ResolverInputTypes["BannersDocAccessFields__locations_Update"],
	delete?:ResolverInputTypes["BannersDocAccessFields__locations_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields__locations_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields__locations_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields__locations_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields__locations_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_region"]: AliasType<{
	create?:ResolverInputTypes["BannersDocAccessFields_region_Create"],
	read?:ResolverInputTypes["BannersDocAccessFields_region_Read"],
	update?:ResolverInputTypes["BannersDocAccessFields_region_Update"],
	delete?:ResolverInputTypes["BannersDocAccessFields_region_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_region_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_region_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_region_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_region_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_homePageClick"]: AliasType<{
	create?:ResolverInputTypes["BannersDocAccessFields_homePageClick_Create"],
	read?:ResolverInputTypes["BannersDocAccessFields_homePageClick_Read"],
	update?:ResolverInputTypes["BannersDocAccessFields_homePageClick_Update"],
	delete?:ResolverInputTypes["BannersDocAccessFields_homePageClick_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_homePageClick_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_homePageClick_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_homePageClick_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_homePageClick_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_homePageImpression"]: AliasType<{
	create?:ResolverInputTypes["BannersDocAccessFields_homePageImpression_Create"],
	read?:ResolverInputTypes["BannersDocAccessFields_homePageImpression_Read"],
	update?:ResolverInputTypes["BannersDocAccessFields_homePageImpression_Update"],
	delete?:ResolverInputTypes["BannersDocAccessFields_homePageImpression_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_homePageImpression_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_homePageImpression_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_homePageImpression_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_homePageImpression_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_latestEventClick"]: AliasType<{
	create?:ResolverInputTypes["BannersDocAccessFields_latestEventClick_Create"],
	read?:ResolverInputTypes["BannersDocAccessFields_latestEventClick_Read"],
	update?:ResolverInputTypes["BannersDocAccessFields_latestEventClick_Update"],
	delete?:ResolverInputTypes["BannersDocAccessFields_latestEventClick_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_latestEventClick_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_latestEventClick_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_latestEventClick_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_latestEventClick_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_latestEventImpression"]: AliasType<{
	create?:ResolverInputTypes["BannersDocAccessFields_latestEventImpression_Create"],
	read?:ResolverInputTypes["BannersDocAccessFields_latestEventImpression_Read"],
	update?:ResolverInputTypes["BannersDocAccessFields_latestEventImpression_Update"],
	delete?:ResolverInputTypes["BannersDocAccessFields_latestEventImpression_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_latestEventImpression_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_latestEventImpression_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_latestEventImpression_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_latestEventImpression_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_editorChoiceClick"]: AliasType<{
	create?:ResolverInputTypes["BannersDocAccessFields_editorChoiceClick_Create"],
	read?:ResolverInputTypes["BannersDocAccessFields_editorChoiceClick_Read"],
	update?:ResolverInputTypes["BannersDocAccessFields_editorChoiceClick_Update"],
	delete?:ResolverInputTypes["BannersDocAccessFields_editorChoiceClick_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_editorChoiceClick_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_editorChoiceClick_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_editorChoiceClick_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_editorChoiceClick_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_editorChoiceImpression"]: AliasType<{
	create?:ResolverInputTypes["BannersDocAccessFields_editorChoiceImpression_Create"],
	read?:ResolverInputTypes["BannersDocAccessFields_editorChoiceImpression_Read"],
	update?:ResolverInputTypes["BannersDocAccessFields_editorChoiceImpression_Update"],
	delete?:ResolverInputTypes["BannersDocAccessFields_editorChoiceImpression_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_editorChoiceImpression_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_editorChoiceImpression_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_editorChoiceImpression_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_editorChoiceImpression_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_eventListClick"]: AliasType<{
	create?:ResolverInputTypes["BannersDocAccessFields_eventListClick_Create"],
	read?:ResolverInputTypes["BannersDocAccessFields_eventListClick_Read"],
	update?:ResolverInputTypes["BannersDocAccessFields_eventListClick_Update"],
	delete?:ResolverInputTypes["BannersDocAccessFields_eventListClick_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_eventListClick_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_eventListClick_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_eventListClick_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_eventListClick_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_eventListImpression"]: AliasType<{
	create?:ResolverInputTypes["BannersDocAccessFields_eventListImpression_Create"],
	read?:ResolverInputTypes["BannersDocAccessFields_eventListImpression_Read"],
	update?:ResolverInputTypes["BannersDocAccessFields_eventListImpression_Update"],
	delete?:ResolverInputTypes["BannersDocAccessFields_eventListImpression_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_eventListImpression_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_eventListImpression_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_eventListImpression_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_eventListImpression_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_topTenClick"]: AliasType<{
	create?:ResolverInputTypes["BannersDocAccessFields_topTenClick_Create"],
	read?:ResolverInputTypes["BannersDocAccessFields_topTenClick_Read"],
	update?:ResolverInputTypes["BannersDocAccessFields_topTenClick_Update"],
	delete?:ResolverInputTypes["BannersDocAccessFields_topTenClick_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_topTenClick_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_topTenClick_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_topTenClick_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_topTenClick_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_topTenImpression"]: AliasType<{
	create?:ResolverInputTypes["BannersDocAccessFields_topTenImpression_Create"],
	read?:ResolverInputTypes["BannersDocAccessFields_topTenImpression_Read"],
	update?:ResolverInputTypes["BannersDocAccessFields_topTenImpression_Update"],
	delete?:ResolverInputTypes["BannersDocAccessFields_topTenImpression_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_topTenImpression_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_topTenImpression_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_topTenImpression_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_topTenImpression_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_miscClick"]: AliasType<{
	create?:ResolverInputTypes["BannersDocAccessFields_miscClick_Create"],
	read?:ResolverInputTypes["BannersDocAccessFields_miscClick_Read"],
	update?:ResolverInputTypes["BannersDocAccessFields_miscClick_Update"],
	delete?:ResolverInputTypes["BannersDocAccessFields_miscClick_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_miscClick_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_miscClick_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_miscClick_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_miscClick_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_miscImpression"]: AliasType<{
	create?:ResolverInputTypes["BannersDocAccessFields_miscImpression_Create"],
	read?:ResolverInputTypes["BannersDocAccessFields_miscImpression_Read"],
	update?:ResolverInputTypes["BannersDocAccessFields_miscImpression_Update"],
	delete?:ResolverInputTypes["BannersDocAccessFields_miscImpression_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_miscImpression_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_miscImpression_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_miscImpression_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_miscImpression_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_clickTotal"]: AliasType<{
	create?:ResolverInputTypes["BannersDocAccessFields_clickTotal_Create"],
	read?:ResolverInputTypes["BannersDocAccessFields_clickTotal_Read"],
	update?:ResolverInputTypes["BannersDocAccessFields_clickTotal_Update"],
	delete?:ResolverInputTypes["BannersDocAccessFields_clickTotal_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_clickTotal_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_clickTotal_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_clickTotal_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_clickTotal_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_totalImpression"]: AliasType<{
	create?:ResolverInputTypes["BannersDocAccessFields_totalImpression_Create"],
	read?:ResolverInputTypes["BannersDocAccessFields_totalImpression_Read"],
	update?:ResolverInputTypes["BannersDocAccessFields_totalImpression_Update"],
	delete?:ResolverInputTypes["BannersDocAccessFields_totalImpression_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_totalImpression_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_totalImpression_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_totalImpression_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_totalImpression_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_updatedAt"]: AliasType<{
	create?:ResolverInputTypes["BannersDocAccessFields_updatedAt_Create"],
	read?:ResolverInputTypes["BannersDocAccessFields_updatedAt_Read"],
	update?:ResolverInputTypes["BannersDocAccessFields_updatedAt_Update"],
	delete?:ResolverInputTypes["BannersDocAccessFields_updatedAt_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_updatedAt_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_updatedAt_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_updatedAt_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_updatedAt_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_createdAt"]: AliasType<{
	create?:ResolverInputTypes["BannersDocAccessFields_createdAt_Create"],
	read?:ResolverInputTypes["BannersDocAccessFields_createdAt_Read"],
	update?:ResolverInputTypes["BannersDocAccessFields_createdAt_Update"],
	delete?:ResolverInputTypes["BannersDocAccessFields_createdAt_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_createdAt_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_createdAt_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_createdAt_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersDocAccessFields_createdAt_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersCreateDocAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersReadDocAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersUpdateDocAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersDeleteDocAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["Campaigns"]: AliasType<{
	docs?:ResolverInputTypes["Campaign"],
	hasNextPage?:boolean | `@${string}`,
	hasPrevPage?:boolean | `@${string}`,
	limit?:boolean | `@${string}`,
	nextPage?:boolean | `@${string}`,
	offset?:boolean | `@${string}`,
	page?:boolean | `@${string}`,
	pagingCounter?:boolean | `@${string}`,
	prevPage?:boolean | `@${string}`,
	totalDocs?:boolean | `@${string}`,
	totalPages?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["Campaign_where"]: {
	creator?: ResolverInputTypes["Campaign_creator_Relation"] | undefined | null,
	invoiceNumber?: ResolverInputTypes["Campaign_invoiceNumber_operator"] | undefined | null,
	quotationNumber?: ResolverInputTypes["Campaign_quotationNumber_operator"] | undefined | null,
	campaignName?: ResolverInputTypes["Campaign_campaignName_operator"] | undefined | null,
	client?: ResolverInputTypes["Campaign_client_operator"] | undefined | null,
	contactPerson?: ResolverInputTypes["Campaign_contactPerson_operator"] | undefined | null,
	email?: ResolverInputTypes["Campaign_email_operator"] | undefined | null,
	phone?: ResolverInputTypes["Campaign_phone_operator"] | undefined | null,
	agentName?: ResolverInputTypes["Campaign_agentName_operator"] | undefined | null,
	agentCommission?: ResolverInputTypes["Campaign_agentCommission_operator"] | undefined | null,
	agentOrderNumber?: ResolverInputTypes["Campaign_agentOrderNumber_operator"] | undefined | null,
	_firstPublishedTime?: ResolverInputTypes["Campaign__firstPublishedTime_operator"] | undefined | null,
	updatedAt?: ResolverInputTypes["Campaign_updatedAt_operator"] | undefined | null,
	createdAt?: ResolverInputTypes["Campaign_createdAt_operator"] | undefined | null,
	id?: ResolverInputTypes["Campaign_id_operator"] | undefined | null,
	AND?: Array<ResolverInputTypes["Campaign_where_and"] | undefined | null> | undefined | null,
	OR?: Array<ResolverInputTypes["Campaign_where_or"] | undefined | null> | undefined | null
};
	["Campaign_creator_Relation"]: {
	relationTo?: ResolverInputTypes["Campaign_creator_Relation_RelationTo"] | undefined | null,
	value?: ResolverInputTypes["JSON"] | undefined | null
};
	["Campaign_creator_Relation_RelationTo"]:Campaign_creator_Relation_RelationTo;
	["Campaign_invoiceNumber_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Campaign_quotationNumber_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Campaign_campaignName_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null
};
	["Campaign_client_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null
};
	["Campaign_contactPerson_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null
};
	["Campaign_email_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null
};
	["Campaign_phone_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null
};
	["Campaign_agentName_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Campaign_agentCommission_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null
};
	["Campaign_agentOrderNumber_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Campaign__firstPublishedTime_operator"]: {
	equals?: ResolverInputTypes["DateTime"] | undefined | null,
	not_equals?: ResolverInputTypes["DateTime"] | undefined | null,
	greater_than_equal?: ResolverInputTypes["DateTime"] | undefined | null,
	greater_than?: ResolverInputTypes["DateTime"] | undefined | null,
	less_than_equal?: ResolverInputTypes["DateTime"] | undefined | null,
	less_than?: ResolverInputTypes["DateTime"] | undefined | null,
	like?: ResolverInputTypes["DateTime"] | undefined | null,
	exists?: boolean | undefined | null
};
	["Campaign_updatedAt_operator"]: {
	equals?: ResolverInputTypes["DateTime"] | undefined | null,
	not_equals?: ResolverInputTypes["DateTime"] | undefined | null,
	greater_than_equal?: ResolverInputTypes["DateTime"] | undefined | null,
	greater_than?: ResolverInputTypes["DateTime"] | undefined | null,
	less_than_equal?: ResolverInputTypes["DateTime"] | undefined | null,
	less_than?: ResolverInputTypes["DateTime"] | undefined | null,
	like?: ResolverInputTypes["DateTime"] | undefined | null,
	exists?: boolean | undefined | null
};
	["Campaign_createdAt_operator"]: {
	equals?: ResolverInputTypes["DateTime"] | undefined | null,
	not_equals?: ResolverInputTypes["DateTime"] | undefined | null,
	greater_than_equal?: ResolverInputTypes["DateTime"] | undefined | null,
	greater_than?: ResolverInputTypes["DateTime"] | undefined | null,
	less_than_equal?: ResolverInputTypes["DateTime"] | undefined | null,
	less_than?: ResolverInputTypes["DateTime"] | undefined | null,
	like?: ResolverInputTypes["DateTime"] | undefined | null,
	exists?: boolean | undefined | null
};
	["Campaign_id_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Campaign_where_and"]: {
	creator?: ResolverInputTypes["Campaign_creator_Relation"] | undefined | null,
	invoiceNumber?: ResolverInputTypes["Campaign_invoiceNumber_operator"] | undefined | null,
	quotationNumber?: ResolverInputTypes["Campaign_quotationNumber_operator"] | undefined | null,
	campaignName?: ResolverInputTypes["Campaign_campaignName_operator"] | undefined | null,
	client?: ResolverInputTypes["Campaign_client_operator"] | undefined | null,
	contactPerson?: ResolverInputTypes["Campaign_contactPerson_operator"] | undefined | null,
	email?: ResolverInputTypes["Campaign_email_operator"] | undefined | null,
	phone?: ResolverInputTypes["Campaign_phone_operator"] | undefined | null,
	agentName?: ResolverInputTypes["Campaign_agentName_operator"] | undefined | null,
	agentCommission?: ResolverInputTypes["Campaign_agentCommission_operator"] | undefined | null,
	agentOrderNumber?: ResolverInputTypes["Campaign_agentOrderNumber_operator"] | undefined | null,
	_firstPublishedTime?: ResolverInputTypes["Campaign__firstPublishedTime_operator"] | undefined | null,
	updatedAt?: ResolverInputTypes["Campaign_updatedAt_operator"] | undefined | null,
	createdAt?: ResolverInputTypes["Campaign_createdAt_operator"] | undefined | null,
	id?: ResolverInputTypes["Campaign_id_operator"] | undefined | null,
	AND?: Array<ResolverInputTypes["Campaign_where_and"] | undefined | null> | undefined | null,
	OR?: Array<ResolverInputTypes["Campaign_where_or"] | undefined | null> | undefined | null
};
	["Campaign_where_or"]: {
	creator?: ResolverInputTypes["Campaign_creator_Relation"] | undefined | null,
	invoiceNumber?: ResolverInputTypes["Campaign_invoiceNumber_operator"] | undefined | null,
	quotationNumber?: ResolverInputTypes["Campaign_quotationNumber_operator"] | undefined | null,
	campaignName?: ResolverInputTypes["Campaign_campaignName_operator"] | undefined | null,
	client?: ResolverInputTypes["Campaign_client_operator"] | undefined | null,
	contactPerson?: ResolverInputTypes["Campaign_contactPerson_operator"] | undefined | null,
	email?: ResolverInputTypes["Campaign_email_operator"] | undefined | null,
	phone?: ResolverInputTypes["Campaign_phone_operator"] | undefined | null,
	agentName?: ResolverInputTypes["Campaign_agentName_operator"] | undefined | null,
	agentCommission?: ResolverInputTypes["Campaign_agentCommission_operator"] | undefined | null,
	agentOrderNumber?: ResolverInputTypes["Campaign_agentOrderNumber_operator"] | undefined | null,
	_firstPublishedTime?: ResolverInputTypes["Campaign__firstPublishedTime_operator"] | undefined | null,
	updatedAt?: ResolverInputTypes["Campaign_updatedAt_operator"] | undefined | null,
	createdAt?: ResolverInputTypes["Campaign_createdAt_operator"] | undefined | null,
	id?: ResolverInputTypes["Campaign_id_operator"] | undefined | null,
	AND?: Array<ResolverInputTypes["Campaign_where_and"] | undefined | null> | undefined | null,
	OR?: Array<ResolverInputTypes["Campaign_where_or"] | undefined | null> | undefined | null
};
	["countCampaigns"]: AliasType<{
	totalDocs?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["campaignsDocAccess"]: AliasType<{
	fields?:ResolverInputTypes["CampaignsDocAccessFields"],
	create?:ResolverInputTypes["CampaignsCreateDocAccess"],
	read?:ResolverInputTypes["CampaignsReadDocAccess"],
	update?:ResolverInputTypes["CampaignsUpdateDocAccess"],
	delete?:ResolverInputTypes["CampaignsDeleteDocAccess"],
		__typename?: boolean | `@${string}`
}>;
	["CampaignsDocAccessFields"]: AliasType<{
	creator?:ResolverInputTypes["CampaignsDocAccessFields_creator"],
	invoiceNumber?:ResolverInputTypes["CampaignsDocAccessFields_invoiceNumber"],
	quotationNumber?:ResolverInputTypes["CampaignsDocAccessFields_quotationNumber"],
	campaignName?:ResolverInputTypes["CampaignsDocAccessFields_campaignName"],
	client?:ResolverInputTypes["CampaignsDocAccessFields_client"],
	contactPerson?:ResolverInputTypes["CampaignsDocAccessFields_contactPerson"],
	email?:ResolverInputTypes["CampaignsDocAccessFields_email"],
	phone?:ResolverInputTypes["CampaignsDocAccessFields_phone"],
	agentName?:ResolverInputTypes["CampaignsDocAccessFields_agentName"],
	agentCommission?:ResolverInputTypes["CampaignsDocAccessFields_agentCommission"],
	agentOrderNumber?:ResolverInputTypes["CampaignsDocAccessFields_agentOrderNumber"],
	_firstPublishedTime?:ResolverInputTypes["CampaignsDocAccessFields__firstPublishedTime"],
	updatedAt?:ResolverInputTypes["CampaignsDocAccessFields_updatedAt"],
	createdAt?:ResolverInputTypes["CampaignsDocAccessFields_createdAt"],
		__typename?: boolean | `@${string}`
}>;
	["CampaignsDocAccessFields_creator"]: AliasType<{
	create?:ResolverInputTypes["CampaignsDocAccessFields_creator_Create"],
	read?:ResolverInputTypes["CampaignsDocAccessFields_creator_Read"],
	update?:ResolverInputTypes["CampaignsDocAccessFields_creator_Update"],
	delete?:ResolverInputTypes["CampaignsDocAccessFields_creator_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["CampaignsDocAccessFields_creator_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CampaignsDocAccessFields_creator_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CampaignsDocAccessFields_creator_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CampaignsDocAccessFields_creator_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CampaignsDocAccessFields_invoiceNumber"]: AliasType<{
	create?:ResolverInputTypes["CampaignsDocAccessFields_invoiceNumber_Create"],
	read?:ResolverInputTypes["CampaignsDocAccessFields_invoiceNumber_Read"],
	update?:ResolverInputTypes["CampaignsDocAccessFields_invoiceNumber_Update"],
	delete?:ResolverInputTypes["CampaignsDocAccessFields_invoiceNumber_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["CampaignsDocAccessFields_invoiceNumber_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CampaignsDocAccessFields_invoiceNumber_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CampaignsDocAccessFields_invoiceNumber_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CampaignsDocAccessFields_invoiceNumber_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CampaignsDocAccessFields_quotationNumber"]: AliasType<{
	create?:ResolverInputTypes["CampaignsDocAccessFields_quotationNumber_Create"],
	read?:ResolverInputTypes["CampaignsDocAccessFields_quotationNumber_Read"],
	update?:ResolverInputTypes["CampaignsDocAccessFields_quotationNumber_Update"],
	delete?:ResolverInputTypes["CampaignsDocAccessFields_quotationNumber_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["CampaignsDocAccessFields_quotationNumber_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CampaignsDocAccessFields_quotationNumber_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CampaignsDocAccessFields_quotationNumber_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CampaignsDocAccessFields_quotationNumber_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CampaignsDocAccessFields_campaignName"]: AliasType<{
	create?:ResolverInputTypes["CampaignsDocAccessFields_campaignName_Create"],
	read?:ResolverInputTypes["CampaignsDocAccessFields_campaignName_Read"],
	update?:ResolverInputTypes["CampaignsDocAccessFields_campaignName_Update"],
	delete?:ResolverInputTypes["CampaignsDocAccessFields_campaignName_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["CampaignsDocAccessFields_campaignName_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CampaignsDocAccessFields_campaignName_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CampaignsDocAccessFields_campaignName_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CampaignsDocAccessFields_campaignName_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CampaignsDocAccessFields_client"]: AliasType<{
	create?:ResolverInputTypes["CampaignsDocAccessFields_client_Create"],
	read?:ResolverInputTypes["CampaignsDocAccessFields_client_Read"],
	update?:ResolverInputTypes["CampaignsDocAccessFields_client_Update"],
	delete?:ResolverInputTypes["CampaignsDocAccessFields_client_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["CampaignsDocAccessFields_client_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CampaignsDocAccessFields_client_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CampaignsDocAccessFields_client_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CampaignsDocAccessFields_client_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CampaignsDocAccessFields_contactPerson"]: AliasType<{
	create?:ResolverInputTypes["CampaignsDocAccessFields_contactPerson_Create"],
	read?:ResolverInputTypes["CampaignsDocAccessFields_contactPerson_Read"],
	update?:ResolverInputTypes["CampaignsDocAccessFields_contactPerson_Update"],
	delete?:ResolverInputTypes["CampaignsDocAccessFields_contactPerson_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["CampaignsDocAccessFields_contactPerson_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CampaignsDocAccessFields_contactPerson_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CampaignsDocAccessFields_contactPerson_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CampaignsDocAccessFields_contactPerson_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CampaignsDocAccessFields_email"]: AliasType<{
	create?:ResolverInputTypes["CampaignsDocAccessFields_email_Create"],
	read?:ResolverInputTypes["CampaignsDocAccessFields_email_Read"],
	update?:ResolverInputTypes["CampaignsDocAccessFields_email_Update"],
	delete?:ResolverInputTypes["CampaignsDocAccessFields_email_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["CampaignsDocAccessFields_email_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CampaignsDocAccessFields_email_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CampaignsDocAccessFields_email_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CampaignsDocAccessFields_email_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CampaignsDocAccessFields_phone"]: AliasType<{
	create?:ResolverInputTypes["CampaignsDocAccessFields_phone_Create"],
	read?:ResolverInputTypes["CampaignsDocAccessFields_phone_Read"],
	update?:ResolverInputTypes["CampaignsDocAccessFields_phone_Update"],
	delete?:ResolverInputTypes["CampaignsDocAccessFields_phone_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["CampaignsDocAccessFields_phone_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CampaignsDocAccessFields_phone_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CampaignsDocAccessFields_phone_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CampaignsDocAccessFields_phone_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CampaignsDocAccessFields_agentName"]: AliasType<{
	create?:ResolverInputTypes["CampaignsDocAccessFields_agentName_Create"],
	read?:ResolverInputTypes["CampaignsDocAccessFields_agentName_Read"],
	update?:ResolverInputTypes["CampaignsDocAccessFields_agentName_Update"],
	delete?:ResolverInputTypes["CampaignsDocAccessFields_agentName_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["CampaignsDocAccessFields_agentName_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CampaignsDocAccessFields_agentName_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CampaignsDocAccessFields_agentName_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CampaignsDocAccessFields_agentName_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CampaignsDocAccessFields_agentCommission"]: AliasType<{
	create?:ResolverInputTypes["CampaignsDocAccessFields_agentCommission_Create"],
	read?:ResolverInputTypes["CampaignsDocAccessFields_agentCommission_Read"],
	update?:ResolverInputTypes["CampaignsDocAccessFields_agentCommission_Update"],
	delete?:ResolverInputTypes["CampaignsDocAccessFields_agentCommission_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["CampaignsDocAccessFields_agentCommission_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CampaignsDocAccessFields_agentCommission_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CampaignsDocAccessFields_agentCommission_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CampaignsDocAccessFields_agentCommission_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CampaignsDocAccessFields_agentOrderNumber"]: AliasType<{
	create?:ResolverInputTypes["CampaignsDocAccessFields_agentOrderNumber_Create"],
	read?:ResolverInputTypes["CampaignsDocAccessFields_agentOrderNumber_Read"],
	update?:ResolverInputTypes["CampaignsDocAccessFields_agentOrderNumber_Update"],
	delete?:ResolverInputTypes["CampaignsDocAccessFields_agentOrderNumber_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["CampaignsDocAccessFields_agentOrderNumber_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CampaignsDocAccessFields_agentOrderNumber_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CampaignsDocAccessFields_agentOrderNumber_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CampaignsDocAccessFields_agentOrderNumber_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CampaignsDocAccessFields__firstPublishedTime"]: AliasType<{
	create?:ResolverInputTypes["CampaignsDocAccessFields__firstPublishedTime_Create"],
	read?:ResolverInputTypes["CampaignsDocAccessFields__firstPublishedTime_Read"],
	update?:ResolverInputTypes["CampaignsDocAccessFields__firstPublishedTime_Update"],
	delete?:ResolverInputTypes["CampaignsDocAccessFields__firstPublishedTime_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["CampaignsDocAccessFields__firstPublishedTime_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CampaignsDocAccessFields__firstPublishedTime_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CampaignsDocAccessFields__firstPublishedTime_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CampaignsDocAccessFields__firstPublishedTime_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CampaignsDocAccessFields_updatedAt"]: AliasType<{
	create?:ResolverInputTypes["CampaignsDocAccessFields_updatedAt_Create"],
	read?:ResolverInputTypes["CampaignsDocAccessFields_updatedAt_Read"],
	update?:ResolverInputTypes["CampaignsDocAccessFields_updatedAt_Update"],
	delete?:ResolverInputTypes["CampaignsDocAccessFields_updatedAt_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["CampaignsDocAccessFields_updatedAt_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CampaignsDocAccessFields_updatedAt_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CampaignsDocAccessFields_updatedAt_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CampaignsDocAccessFields_updatedAt_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CampaignsDocAccessFields_createdAt"]: AliasType<{
	create?:ResolverInputTypes["CampaignsDocAccessFields_createdAt_Create"],
	read?:ResolverInputTypes["CampaignsDocAccessFields_createdAt_Read"],
	update?:ResolverInputTypes["CampaignsDocAccessFields_createdAt_Update"],
	delete?:ResolverInputTypes["CampaignsDocAccessFields_createdAt_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["CampaignsDocAccessFields_createdAt_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CampaignsDocAccessFields_createdAt_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CampaignsDocAccessFields_createdAt_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CampaignsDocAccessFields_createdAt_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CampaignsCreateDocAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CampaignsReadDocAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CampaignsUpdateDocAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CampaignsDeleteDocAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["Advertise"]: AliasType<{
	id?:boolean | `@${string}`,
	advertise?:ResolverInputTypes["Advertise_Advertise"],
	advertiseStartDate?:boolean | `@${string}`,
	advertiseEndDate?:boolean | `@${string}`,
	type?:boolean | `@${string}`,
	invoiceDescription?:boolean | `@${string}`,
	standardPrice?:boolean | `@${string}`,
	discount?:boolean | `@${string}`,
	discountPercentage?:boolean | `@${string}`,
	_calculateByDiscount?:boolean | `@${string}`,
	timableCost?:boolean | `@${string}`,
	remark?:boolean | `@${string}`,
	public?:boolean | `@${string}`,
	campaignName?:boolean | `@${string}`,
	campaignId?:boolean | `@${string}`,
	status?:boolean | `@${string}`,
	updatedAt?:boolean | `@${string}`,
	createdAt?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["Advertise_Advertise"]: AliasType<{
	type?:boolean | `@${string}`,
event?: [{	locale?: ResolverInputTypes["LocaleInputType"] | undefined | null,	fallbackLocale?: ResolverInputTypes["FallbackLocaleInputType"] | undefined | null},ResolverInputTypes["Event"]],
	keyword?:boolean | `@${string}`,
location?: [{	locale?: ResolverInputTypes["LocaleInputType"] | undefined | null,	fallbackLocale?: ResolverInputTypes["FallbackLocaleInputType"] | undefined | null},ResolverInputTypes["Location"]],
	displayPosition?:boolean | `@${string}`,
	rankingListRemark?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["Advertise_Advertise_type"]:Advertise_Advertise_type;
	["Advertise_Advertise_displayPosition"]:Advertise_Advertise_displayPosition;
	["Advertise_type"]:Advertise_type;
	["Advertises"]: AliasType<{
	docs?:ResolverInputTypes["Advertise"],
	hasNextPage?:boolean | `@${string}`,
	hasPrevPage?:boolean | `@${string}`,
	limit?:boolean | `@${string}`,
	nextPage?:boolean | `@${string}`,
	offset?:boolean | `@${string}`,
	page?:boolean | `@${string}`,
	pagingCounter?:boolean | `@${string}`,
	prevPage?:boolean | `@${string}`,
	totalDocs?:boolean | `@${string}`,
	totalPages?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["Advertise_where"]: {
	advertise__type?: ResolverInputTypes["Advertise_advertise__type_operator"] | undefined | null,
	advertise__event?: ResolverInputTypes["Advertise_advertise__event_operator"] | undefined | null,
	advertise__keyword?: ResolverInputTypes["Advertise_advertise__keyword_operator"] | undefined | null,
	advertise__location?: ResolverInputTypes["Advertise_advertise__location_operator"] | undefined | null,
	advertise__displayPosition?: ResolverInputTypes["Advertise_advertise__displayPosition_operator"] | undefined | null,
	advertise__rankingListRemark?: ResolverInputTypes["Advertise_advertise__rankingListRemark_operator"] | undefined | null,
	advertiseStartDate?: ResolverInputTypes["Advertise_advertiseStartDate_operator"] | undefined | null,
	advertiseEndDate?: ResolverInputTypes["Advertise_advertiseEndDate_operator"] | undefined | null,
	type?: ResolverInputTypes["Advertise_type_operator"] | undefined | null,
	invoiceDescription?: ResolverInputTypes["Advertise_invoiceDescription_operator"] | undefined | null,
	standardPrice?: ResolverInputTypes["Advertise_standardPrice_operator"] | undefined | null,
	discount?: ResolverInputTypes["Advertise_discount_operator"] | undefined | null,
	discountPercentage?: ResolverInputTypes["Advertise_discountPercentage_operator"] | undefined | null,
	_calculateByDiscount?: ResolverInputTypes["Advertise__calculateByDiscount_operator"] | undefined | null,
	timableCost?: ResolverInputTypes["Advertise_timableCost_operator"] | undefined | null,
	remark?: ResolverInputTypes["Advertise_remark_operator"] | undefined | null,
	public?: ResolverInputTypes["Advertise_public_operator"] | undefined | null,
	campaignName?: ResolverInputTypes["Advertise_campaignName_operator"] | undefined | null,
	campaignId?: ResolverInputTypes["Advertise_campaignId_operator"] | undefined | null,
	status?: ResolverInputTypes["Advertise_status_operator"] | undefined | null,
	updatedAt?: ResolverInputTypes["Advertise_updatedAt_operator"] | undefined | null,
	createdAt?: ResolverInputTypes["Advertise_createdAt_operator"] | undefined | null,
	id?: ResolverInputTypes["Advertise_id_operator"] | undefined | null,
	AND?: Array<ResolverInputTypes["Advertise_where_and"] | undefined | null> | undefined | null,
	OR?: Array<ResolverInputTypes["Advertise_where_or"] | undefined | null> | undefined | null
};
	["Advertise_advertise__type_operator"]: {
	equals?: ResolverInputTypes["Advertise_advertise__type_Input"] | undefined | null,
	not_equals?: ResolverInputTypes["Advertise_advertise__type_Input"] | undefined | null,
	in?: Array<ResolverInputTypes["Advertise_advertise__type_Input"] | undefined | null> | undefined | null,
	not_in?: Array<ResolverInputTypes["Advertise_advertise__type_Input"] | undefined | null> | undefined | null,
	all?: Array<ResolverInputTypes["Advertise_advertise__type_Input"] | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Advertise_advertise__type_Input"]:Advertise_advertise__type_Input;
	["Advertise_advertise__event_operator"]: {
	equals?: ResolverInputTypes["JSON"] | undefined | null,
	not_equals?: ResolverInputTypes["JSON"] | undefined | null,
	in?: Array<ResolverInputTypes["JSON"] | undefined | null> | undefined | null,
	not_in?: Array<ResolverInputTypes["JSON"] | undefined | null> | undefined | null,
	all?: Array<ResolverInputTypes["JSON"] | undefined | null> | undefined | null
};
	["Advertise_advertise__keyword_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null
};
	["Advertise_advertise__location_operator"]: {
	equals?: ResolverInputTypes["JSON"] | undefined | null,
	not_equals?: ResolverInputTypes["JSON"] | undefined | null,
	in?: Array<ResolverInputTypes["JSON"] | undefined | null> | undefined | null,
	not_in?: Array<ResolverInputTypes["JSON"] | undefined | null> | undefined | null,
	all?: Array<ResolverInputTypes["JSON"] | undefined | null> | undefined | null
};
	["Advertise_advertise__displayPosition_operator"]: {
	equals?: ResolverInputTypes["Advertise_advertise__displayPosition_Input"] | undefined | null,
	not_equals?: ResolverInputTypes["Advertise_advertise__displayPosition_Input"] | undefined | null,
	like?: ResolverInputTypes["Advertise_advertise__displayPosition_Input"] | undefined | null,
	contains?: ResolverInputTypes["Advertise_advertise__displayPosition_Input"] | undefined | null
};
	["Advertise_advertise__displayPosition_Input"]:Advertise_advertise__displayPosition_Input;
	["Advertise_advertise__rankingListRemark_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Advertise_advertiseStartDate_operator"]: {
	equals?: ResolverInputTypes["DateTime"] | undefined | null,
	not_equals?: ResolverInputTypes["DateTime"] | undefined | null,
	greater_than_equal?: ResolverInputTypes["DateTime"] | undefined | null,
	greater_than?: ResolverInputTypes["DateTime"] | undefined | null,
	less_than_equal?: ResolverInputTypes["DateTime"] | undefined | null,
	less_than?: ResolverInputTypes["DateTime"] | undefined | null,
	like?: ResolverInputTypes["DateTime"] | undefined | null
};
	["Advertise_advertiseEndDate_operator"]: {
	equals?: ResolverInputTypes["DateTime"] | undefined | null,
	not_equals?: ResolverInputTypes["DateTime"] | undefined | null,
	greater_than_equal?: ResolverInputTypes["DateTime"] | undefined | null,
	greater_than?: ResolverInputTypes["DateTime"] | undefined | null,
	less_than_equal?: ResolverInputTypes["DateTime"] | undefined | null,
	less_than?: ResolverInputTypes["DateTime"] | undefined | null,
	like?: ResolverInputTypes["DateTime"] | undefined | null
};
	["Advertise_type_operator"]: {
	equals?: ResolverInputTypes["Advertise_type_Input"] | undefined | null,
	not_equals?: ResolverInputTypes["Advertise_type_Input"] | undefined | null,
	in?: Array<ResolverInputTypes["Advertise_type_Input"] | undefined | null> | undefined | null,
	not_in?: Array<ResolverInputTypes["Advertise_type_Input"] | undefined | null> | undefined | null,
	all?: Array<ResolverInputTypes["Advertise_type_Input"] | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Advertise_type_Input"]:Advertise_type_Input;
	["Advertise_invoiceDescription_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	exists?: boolean | undefined | null
};
	["Advertise_standardPrice_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null
};
	["Advertise_discount_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Advertise_discountPercentage_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Advertise__calculateByDiscount_operator"]: {
	equals?: boolean | undefined | null,
	not_equals?: boolean | undefined | null,
	exists?: boolean | undefined | null
};
	["Advertise_timableCost_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null,
	exists?: boolean | undefined | null
};
	["Advertise_remark_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	exists?: boolean | undefined | null
};
	["Advertise_public_operator"]: {
	equals?: boolean | undefined | null,
	not_equals?: boolean | undefined | null,
	exists?: boolean | undefined | null
};
	["Advertise_campaignName_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Advertise_campaignId_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Advertise_status_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Advertise_updatedAt_operator"]: {
	equals?: ResolverInputTypes["DateTime"] | undefined | null,
	not_equals?: ResolverInputTypes["DateTime"] | undefined | null,
	greater_than_equal?: ResolverInputTypes["DateTime"] | undefined | null,
	greater_than?: ResolverInputTypes["DateTime"] | undefined | null,
	less_than_equal?: ResolverInputTypes["DateTime"] | undefined | null,
	less_than?: ResolverInputTypes["DateTime"] | undefined | null,
	like?: ResolverInputTypes["DateTime"] | undefined | null,
	exists?: boolean | undefined | null
};
	["Advertise_createdAt_operator"]: {
	equals?: ResolverInputTypes["DateTime"] | undefined | null,
	not_equals?: ResolverInputTypes["DateTime"] | undefined | null,
	greater_than_equal?: ResolverInputTypes["DateTime"] | undefined | null,
	greater_than?: ResolverInputTypes["DateTime"] | undefined | null,
	less_than_equal?: ResolverInputTypes["DateTime"] | undefined | null,
	less_than?: ResolverInputTypes["DateTime"] | undefined | null,
	like?: ResolverInputTypes["DateTime"] | undefined | null,
	exists?: boolean | undefined | null
};
	["Advertise_id_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Advertise_where_and"]: {
	advertise__type?: ResolverInputTypes["Advertise_advertise__type_operator"] | undefined | null,
	advertise__event?: ResolverInputTypes["Advertise_advertise__event_operator"] | undefined | null,
	advertise__keyword?: ResolverInputTypes["Advertise_advertise__keyword_operator"] | undefined | null,
	advertise__location?: ResolverInputTypes["Advertise_advertise__location_operator"] | undefined | null,
	advertise__displayPosition?: ResolverInputTypes["Advertise_advertise__displayPosition_operator"] | undefined | null,
	advertise__rankingListRemark?: ResolverInputTypes["Advertise_advertise__rankingListRemark_operator"] | undefined | null,
	advertiseStartDate?: ResolverInputTypes["Advertise_advertiseStartDate_operator"] | undefined | null,
	advertiseEndDate?: ResolverInputTypes["Advertise_advertiseEndDate_operator"] | undefined | null,
	type?: ResolverInputTypes["Advertise_type_operator"] | undefined | null,
	invoiceDescription?: ResolverInputTypes["Advertise_invoiceDescription_operator"] | undefined | null,
	standardPrice?: ResolverInputTypes["Advertise_standardPrice_operator"] | undefined | null,
	discount?: ResolverInputTypes["Advertise_discount_operator"] | undefined | null,
	discountPercentage?: ResolverInputTypes["Advertise_discountPercentage_operator"] | undefined | null,
	_calculateByDiscount?: ResolverInputTypes["Advertise__calculateByDiscount_operator"] | undefined | null,
	timableCost?: ResolverInputTypes["Advertise_timableCost_operator"] | undefined | null,
	remark?: ResolverInputTypes["Advertise_remark_operator"] | undefined | null,
	public?: ResolverInputTypes["Advertise_public_operator"] | undefined | null,
	campaignName?: ResolverInputTypes["Advertise_campaignName_operator"] | undefined | null,
	campaignId?: ResolverInputTypes["Advertise_campaignId_operator"] | undefined | null,
	status?: ResolverInputTypes["Advertise_status_operator"] | undefined | null,
	updatedAt?: ResolverInputTypes["Advertise_updatedAt_operator"] | undefined | null,
	createdAt?: ResolverInputTypes["Advertise_createdAt_operator"] | undefined | null,
	id?: ResolverInputTypes["Advertise_id_operator"] | undefined | null,
	AND?: Array<ResolverInputTypes["Advertise_where_and"] | undefined | null> | undefined | null,
	OR?: Array<ResolverInputTypes["Advertise_where_or"] | undefined | null> | undefined | null
};
	["Advertise_where_or"]: {
	advertise__type?: ResolverInputTypes["Advertise_advertise__type_operator"] | undefined | null,
	advertise__event?: ResolverInputTypes["Advertise_advertise__event_operator"] | undefined | null,
	advertise__keyword?: ResolverInputTypes["Advertise_advertise__keyword_operator"] | undefined | null,
	advertise__location?: ResolverInputTypes["Advertise_advertise__location_operator"] | undefined | null,
	advertise__displayPosition?: ResolverInputTypes["Advertise_advertise__displayPosition_operator"] | undefined | null,
	advertise__rankingListRemark?: ResolverInputTypes["Advertise_advertise__rankingListRemark_operator"] | undefined | null,
	advertiseStartDate?: ResolverInputTypes["Advertise_advertiseStartDate_operator"] | undefined | null,
	advertiseEndDate?: ResolverInputTypes["Advertise_advertiseEndDate_operator"] | undefined | null,
	type?: ResolverInputTypes["Advertise_type_operator"] | undefined | null,
	invoiceDescription?: ResolverInputTypes["Advertise_invoiceDescription_operator"] | undefined | null,
	standardPrice?: ResolverInputTypes["Advertise_standardPrice_operator"] | undefined | null,
	discount?: ResolverInputTypes["Advertise_discount_operator"] | undefined | null,
	discountPercentage?: ResolverInputTypes["Advertise_discountPercentage_operator"] | undefined | null,
	_calculateByDiscount?: ResolverInputTypes["Advertise__calculateByDiscount_operator"] | undefined | null,
	timableCost?: ResolverInputTypes["Advertise_timableCost_operator"] | undefined | null,
	remark?: ResolverInputTypes["Advertise_remark_operator"] | undefined | null,
	public?: ResolverInputTypes["Advertise_public_operator"] | undefined | null,
	campaignName?: ResolverInputTypes["Advertise_campaignName_operator"] | undefined | null,
	campaignId?: ResolverInputTypes["Advertise_campaignId_operator"] | undefined | null,
	status?: ResolverInputTypes["Advertise_status_operator"] | undefined | null,
	updatedAt?: ResolverInputTypes["Advertise_updatedAt_operator"] | undefined | null,
	createdAt?: ResolverInputTypes["Advertise_createdAt_operator"] | undefined | null,
	id?: ResolverInputTypes["Advertise_id_operator"] | undefined | null,
	AND?: Array<ResolverInputTypes["Advertise_where_and"] | undefined | null> | undefined | null,
	OR?: Array<ResolverInputTypes["Advertise_where_or"] | undefined | null> | undefined | null
};
	["countAdvertises"]: AliasType<{
	totalDocs?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["advertisesDocAccess"]: AliasType<{
	fields?:ResolverInputTypes["AdvertisesDocAccessFields"],
	create?:ResolverInputTypes["AdvertisesCreateDocAccess"],
	read?:ResolverInputTypes["AdvertisesReadDocAccess"],
	update?:ResolverInputTypes["AdvertisesUpdateDocAccess"],
	delete?:ResolverInputTypes["AdvertisesDeleteDocAccess"],
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesDocAccessFields"]: AliasType<{
	advertise?:ResolverInputTypes["AdvertisesDocAccessFields_advertise"],
	advertiseStartDate?:ResolverInputTypes["AdvertisesDocAccessFields_advertiseStartDate"],
	advertiseEndDate?:ResolverInputTypes["AdvertisesDocAccessFields_advertiseEndDate"],
	type?:ResolverInputTypes["AdvertisesDocAccessFields_type"],
	invoiceDescription?:ResolverInputTypes["AdvertisesDocAccessFields_invoiceDescription"],
	standardPrice?:ResolverInputTypes["AdvertisesDocAccessFields_standardPrice"],
	discount?:ResolverInputTypes["AdvertisesDocAccessFields_discount"],
	discountPercentage?:ResolverInputTypes["AdvertisesDocAccessFields_discountPercentage"],
	_calculateByDiscount?:ResolverInputTypes["AdvertisesDocAccessFields__calculateByDiscount"],
	timableCost?:ResolverInputTypes["AdvertisesDocAccessFields_timableCost"],
	remark?:ResolverInputTypes["AdvertisesDocAccessFields_remark"],
	public?:ResolverInputTypes["AdvertisesDocAccessFields_public"],
	campaignName?:ResolverInputTypes["AdvertisesDocAccessFields_campaignName"],
	campaignId?:ResolverInputTypes["AdvertisesDocAccessFields_campaignId"],
	status?:ResolverInputTypes["AdvertisesDocAccessFields_status"],
	updatedAt?:ResolverInputTypes["AdvertisesDocAccessFields_updatedAt"],
	createdAt?:ResolverInputTypes["AdvertisesDocAccessFields_createdAt"],
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesDocAccessFields_advertise"]: AliasType<{
	create?:ResolverInputTypes["AdvertisesDocAccessFields_advertise_Create"],
	read?:ResolverInputTypes["AdvertisesDocAccessFields_advertise_Read"],
	update?:ResolverInputTypes["AdvertisesDocAccessFields_advertise_Update"],
	delete?:ResolverInputTypes["AdvertisesDocAccessFields_advertise_Delete"],
	fields?:ResolverInputTypes["AdvertisesDocAccessFields_advertise_Fields"],
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesDocAccessFields_advertise_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesDocAccessFields_advertise_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesDocAccessFields_advertise_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesDocAccessFields_advertise_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesDocAccessFields_advertise_Fields"]: AliasType<{
	type?:ResolverInputTypes["AdvertisesDocAccessFields_advertise_type"],
	event?:ResolverInputTypes["AdvertisesDocAccessFields_advertise_event"],
	keyword?:ResolverInputTypes["AdvertisesDocAccessFields_advertise_keyword"],
	location?:ResolverInputTypes["AdvertisesDocAccessFields_advertise_location"],
	displayPosition?:ResolverInputTypes["AdvertisesDocAccessFields_advertise_displayPosition"],
	rankingListRemark?:ResolverInputTypes["AdvertisesDocAccessFields_advertise_rankingListRemark"],
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesDocAccessFields_advertise_type"]: AliasType<{
	create?:ResolverInputTypes["AdvertisesDocAccessFields_advertise_type_Create"],
	read?:ResolverInputTypes["AdvertisesDocAccessFields_advertise_type_Read"],
	update?:ResolverInputTypes["AdvertisesDocAccessFields_advertise_type_Update"],
	delete?:ResolverInputTypes["AdvertisesDocAccessFields_advertise_type_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesDocAccessFields_advertise_type_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesDocAccessFields_advertise_type_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesDocAccessFields_advertise_type_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesDocAccessFields_advertise_type_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesDocAccessFields_advertise_event"]: AliasType<{
	create?:ResolverInputTypes["AdvertisesDocAccessFields_advertise_event_Create"],
	read?:ResolverInputTypes["AdvertisesDocAccessFields_advertise_event_Read"],
	update?:ResolverInputTypes["AdvertisesDocAccessFields_advertise_event_Update"],
	delete?:ResolverInputTypes["AdvertisesDocAccessFields_advertise_event_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesDocAccessFields_advertise_event_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesDocAccessFields_advertise_event_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesDocAccessFields_advertise_event_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesDocAccessFields_advertise_event_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesDocAccessFields_advertise_keyword"]: AliasType<{
	create?:ResolverInputTypes["AdvertisesDocAccessFields_advertise_keyword_Create"],
	read?:ResolverInputTypes["AdvertisesDocAccessFields_advertise_keyword_Read"],
	update?:ResolverInputTypes["AdvertisesDocAccessFields_advertise_keyword_Update"],
	delete?:ResolverInputTypes["AdvertisesDocAccessFields_advertise_keyword_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesDocAccessFields_advertise_keyword_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesDocAccessFields_advertise_keyword_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesDocAccessFields_advertise_keyword_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesDocAccessFields_advertise_keyword_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesDocAccessFields_advertise_location"]: AliasType<{
	create?:ResolverInputTypes["AdvertisesDocAccessFields_advertise_location_Create"],
	read?:ResolverInputTypes["AdvertisesDocAccessFields_advertise_location_Read"],
	update?:ResolverInputTypes["AdvertisesDocAccessFields_advertise_location_Update"],
	delete?:ResolverInputTypes["AdvertisesDocAccessFields_advertise_location_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesDocAccessFields_advertise_location_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesDocAccessFields_advertise_location_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesDocAccessFields_advertise_location_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesDocAccessFields_advertise_location_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesDocAccessFields_advertise_displayPosition"]: AliasType<{
	create?:ResolverInputTypes["AdvertisesDocAccessFields_advertise_displayPosition_Create"],
	read?:ResolverInputTypes["AdvertisesDocAccessFields_advertise_displayPosition_Read"],
	update?:ResolverInputTypes["AdvertisesDocAccessFields_advertise_displayPosition_Update"],
	delete?:ResolverInputTypes["AdvertisesDocAccessFields_advertise_displayPosition_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesDocAccessFields_advertise_displayPosition_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesDocAccessFields_advertise_displayPosition_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesDocAccessFields_advertise_displayPosition_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesDocAccessFields_advertise_displayPosition_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesDocAccessFields_advertise_rankingListRemark"]: AliasType<{
	create?:ResolverInputTypes["AdvertisesDocAccessFields_advertise_rankingListRemark_Create"],
	read?:ResolverInputTypes["AdvertisesDocAccessFields_advertise_rankingListRemark_Read"],
	update?:ResolverInputTypes["AdvertisesDocAccessFields_advertise_rankingListRemark_Update"],
	delete?:ResolverInputTypes["AdvertisesDocAccessFields_advertise_rankingListRemark_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesDocAccessFields_advertise_rankingListRemark_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesDocAccessFields_advertise_rankingListRemark_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesDocAccessFields_advertise_rankingListRemark_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesDocAccessFields_advertise_rankingListRemark_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesDocAccessFields_advertiseStartDate"]: AliasType<{
	create?:ResolverInputTypes["AdvertisesDocAccessFields_advertiseStartDate_Create"],
	read?:ResolverInputTypes["AdvertisesDocAccessFields_advertiseStartDate_Read"],
	update?:ResolverInputTypes["AdvertisesDocAccessFields_advertiseStartDate_Update"],
	delete?:ResolverInputTypes["AdvertisesDocAccessFields_advertiseStartDate_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesDocAccessFields_advertiseStartDate_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesDocAccessFields_advertiseStartDate_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesDocAccessFields_advertiseStartDate_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesDocAccessFields_advertiseStartDate_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesDocAccessFields_advertiseEndDate"]: AliasType<{
	create?:ResolverInputTypes["AdvertisesDocAccessFields_advertiseEndDate_Create"],
	read?:ResolverInputTypes["AdvertisesDocAccessFields_advertiseEndDate_Read"],
	update?:ResolverInputTypes["AdvertisesDocAccessFields_advertiseEndDate_Update"],
	delete?:ResolverInputTypes["AdvertisesDocAccessFields_advertiseEndDate_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesDocAccessFields_advertiseEndDate_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesDocAccessFields_advertiseEndDate_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesDocAccessFields_advertiseEndDate_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesDocAccessFields_advertiseEndDate_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesDocAccessFields_type"]: AliasType<{
	create?:ResolverInputTypes["AdvertisesDocAccessFields_type_Create"],
	read?:ResolverInputTypes["AdvertisesDocAccessFields_type_Read"],
	update?:ResolverInputTypes["AdvertisesDocAccessFields_type_Update"],
	delete?:ResolverInputTypes["AdvertisesDocAccessFields_type_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesDocAccessFields_type_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesDocAccessFields_type_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesDocAccessFields_type_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesDocAccessFields_type_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesDocAccessFields_invoiceDescription"]: AliasType<{
	create?:ResolverInputTypes["AdvertisesDocAccessFields_invoiceDescription_Create"],
	read?:ResolverInputTypes["AdvertisesDocAccessFields_invoiceDescription_Read"],
	update?:ResolverInputTypes["AdvertisesDocAccessFields_invoiceDescription_Update"],
	delete?:ResolverInputTypes["AdvertisesDocAccessFields_invoiceDescription_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesDocAccessFields_invoiceDescription_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesDocAccessFields_invoiceDescription_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesDocAccessFields_invoiceDescription_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesDocAccessFields_invoiceDescription_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesDocAccessFields_standardPrice"]: AliasType<{
	create?:ResolverInputTypes["AdvertisesDocAccessFields_standardPrice_Create"],
	read?:ResolverInputTypes["AdvertisesDocAccessFields_standardPrice_Read"],
	update?:ResolverInputTypes["AdvertisesDocAccessFields_standardPrice_Update"],
	delete?:ResolverInputTypes["AdvertisesDocAccessFields_standardPrice_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesDocAccessFields_standardPrice_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesDocAccessFields_standardPrice_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesDocAccessFields_standardPrice_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesDocAccessFields_standardPrice_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesDocAccessFields_discount"]: AliasType<{
	create?:ResolverInputTypes["AdvertisesDocAccessFields_discount_Create"],
	read?:ResolverInputTypes["AdvertisesDocAccessFields_discount_Read"],
	update?:ResolverInputTypes["AdvertisesDocAccessFields_discount_Update"],
	delete?:ResolverInputTypes["AdvertisesDocAccessFields_discount_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesDocAccessFields_discount_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesDocAccessFields_discount_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesDocAccessFields_discount_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesDocAccessFields_discount_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesDocAccessFields_discountPercentage"]: AliasType<{
	create?:ResolverInputTypes["AdvertisesDocAccessFields_discountPercentage_Create"],
	read?:ResolverInputTypes["AdvertisesDocAccessFields_discountPercentage_Read"],
	update?:ResolverInputTypes["AdvertisesDocAccessFields_discountPercentage_Update"],
	delete?:ResolverInputTypes["AdvertisesDocAccessFields_discountPercentage_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesDocAccessFields_discountPercentage_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesDocAccessFields_discountPercentage_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesDocAccessFields_discountPercentage_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesDocAccessFields_discountPercentage_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesDocAccessFields__calculateByDiscount"]: AliasType<{
	create?:ResolverInputTypes["AdvertisesDocAccessFields__calculateByDiscount_Create"],
	read?:ResolverInputTypes["AdvertisesDocAccessFields__calculateByDiscount_Read"],
	update?:ResolverInputTypes["AdvertisesDocAccessFields__calculateByDiscount_Update"],
	delete?:ResolverInputTypes["AdvertisesDocAccessFields__calculateByDiscount_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesDocAccessFields__calculateByDiscount_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesDocAccessFields__calculateByDiscount_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesDocAccessFields__calculateByDiscount_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesDocAccessFields__calculateByDiscount_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesDocAccessFields_timableCost"]: AliasType<{
	create?:ResolverInputTypes["AdvertisesDocAccessFields_timableCost_Create"],
	read?:ResolverInputTypes["AdvertisesDocAccessFields_timableCost_Read"],
	update?:ResolverInputTypes["AdvertisesDocAccessFields_timableCost_Update"],
	delete?:ResolverInputTypes["AdvertisesDocAccessFields_timableCost_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesDocAccessFields_timableCost_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesDocAccessFields_timableCost_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesDocAccessFields_timableCost_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesDocAccessFields_timableCost_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesDocAccessFields_remark"]: AliasType<{
	create?:ResolverInputTypes["AdvertisesDocAccessFields_remark_Create"],
	read?:ResolverInputTypes["AdvertisesDocAccessFields_remark_Read"],
	update?:ResolverInputTypes["AdvertisesDocAccessFields_remark_Update"],
	delete?:ResolverInputTypes["AdvertisesDocAccessFields_remark_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesDocAccessFields_remark_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesDocAccessFields_remark_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesDocAccessFields_remark_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesDocAccessFields_remark_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesDocAccessFields_public"]: AliasType<{
	create?:ResolverInputTypes["AdvertisesDocAccessFields_public_Create"],
	read?:ResolverInputTypes["AdvertisesDocAccessFields_public_Read"],
	update?:ResolverInputTypes["AdvertisesDocAccessFields_public_Update"],
	delete?:ResolverInputTypes["AdvertisesDocAccessFields_public_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesDocAccessFields_public_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesDocAccessFields_public_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesDocAccessFields_public_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesDocAccessFields_public_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesDocAccessFields_campaignName"]: AliasType<{
	create?:ResolverInputTypes["AdvertisesDocAccessFields_campaignName_Create"],
	read?:ResolverInputTypes["AdvertisesDocAccessFields_campaignName_Read"],
	update?:ResolverInputTypes["AdvertisesDocAccessFields_campaignName_Update"],
	delete?:ResolverInputTypes["AdvertisesDocAccessFields_campaignName_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesDocAccessFields_campaignName_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesDocAccessFields_campaignName_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesDocAccessFields_campaignName_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesDocAccessFields_campaignName_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesDocAccessFields_campaignId"]: AliasType<{
	create?:ResolverInputTypes["AdvertisesDocAccessFields_campaignId_Create"],
	read?:ResolverInputTypes["AdvertisesDocAccessFields_campaignId_Read"],
	update?:ResolverInputTypes["AdvertisesDocAccessFields_campaignId_Update"],
	delete?:ResolverInputTypes["AdvertisesDocAccessFields_campaignId_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesDocAccessFields_campaignId_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesDocAccessFields_campaignId_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesDocAccessFields_campaignId_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesDocAccessFields_campaignId_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesDocAccessFields_status"]: AliasType<{
	create?:ResolverInputTypes["AdvertisesDocAccessFields_status_Create"],
	read?:ResolverInputTypes["AdvertisesDocAccessFields_status_Read"],
	update?:ResolverInputTypes["AdvertisesDocAccessFields_status_Update"],
	delete?:ResolverInputTypes["AdvertisesDocAccessFields_status_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesDocAccessFields_status_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesDocAccessFields_status_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesDocAccessFields_status_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesDocAccessFields_status_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesDocAccessFields_updatedAt"]: AliasType<{
	create?:ResolverInputTypes["AdvertisesDocAccessFields_updatedAt_Create"],
	read?:ResolverInputTypes["AdvertisesDocAccessFields_updatedAt_Read"],
	update?:ResolverInputTypes["AdvertisesDocAccessFields_updatedAt_Update"],
	delete?:ResolverInputTypes["AdvertisesDocAccessFields_updatedAt_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesDocAccessFields_updatedAt_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesDocAccessFields_updatedAt_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesDocAccessFields_updatedAt_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesDocAccessFields_updatedAt_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesDocAccessFields_createdAt"]: AliasType<{
	create?:ResolverInputTypes["AdvertisesDocAccessFields_createdAt_Create"],
	read?:ResolverInputTypes["AdvertisesDocAccessFields_createdAt_Read"],
	update?:ResolverInputTypes["AdvertisesDocAccessFields_createdAt_Update"],
	delete?:ResolverInputTypes["AdvertisesDocAccessFields_createdAt_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesDocAccessFields_createdAt_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesDocAccessFields_createdAt_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesDocAccessFields_createdAt_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesDocAccessFields_createdAt_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesCreateDocAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesReadDocAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesUpdateDocAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesDeleteDocAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["Static"]: AliasType<{
	id?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	key?:boolean | `@${string}`,
	content?:ResolverInputTypes["Static_Content"],
	region?:boolean | `@${string}`,
	updatedAt?:boolean | `@${string}`,
	createdAt?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["Static_Content"]: AliasType<{
	RichText?:ResolverInputTypes["RichText"],
		__typename?: boolean | `@${string}`
}>;
	["Static_region"]:Static_region;
	["Statics"]: AliasType<{
	docs?:ResolverInputTypes["Static"],
	hasNextPage?:boolean | `@${string}`,
	hasPrevPage?:boolean | `@${string}`,
	limit?:boolean | `@${string}`,
	nextPage?:boolean | `@${string}`,
	offset?:boolean | `@${string}`,
	page?:boolean | `@${string}`,
	pagingCounter?:boolean | `@${string}`,
	prevPage?:boolean | `@${string}`,
	totalDocs?:boolean | `@${string}`,
	totalPages?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["Static_where"]: {
	name?: ResolverInputTypes["Static_name_operator"] | undefined | null,
	key?: ResolverInputTypes["Static_key_operator"] | undefined | null,
	region?: ResolverInputTypes["Static_region_operator"] | undefined | null,
	updatedAt?: ResolverInputTypes["Static_updatedAt_operator"] | undefined | null,
	createdAt?: ResolverInputTypes["Static_createdAt_operator"] | undefined | null,
	id?: ResolverInputTypes["Static_id_operator"] | undefined | null,
	AND?: Array<ResolverInputTypes["Static_where_and"] | undefined | null> | undefined | null,
	OR?: Array<ResolverInputTypes["Static_where_or"] | undefined | null> | undefined | null
};
	["Static_name_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Static_key_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Static_region_operator"]: {
	equals?: ResolverInputTypes["Static_region_Input"] | undefined | null,
	not_equals?: ResolverInputTypes["Static_region_Input"] | undefined | null,
	in?: Array<ResolverInputTypes["Static_region_Input"] | undefined | null> | undefined | null,
	not_in?: Array<ResolverInputTypes["Static_region_Input"] | undefined | null> | undefined | null,
	all?: Array<ResolverInputTypes["Static_region_Input"] | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Static_region_Input"]:Static_region_Input;
	["Static_updatedAt_operator"]: {
	equals?: ResolverInputTypes["DateTime"] | undefined | null,
	not_equals?: ResolverInputTypes["DateTime"] | undefined | null,
	greater_than_equal?: ResolverInputTypes["DateTime"] | undefined | null,
	greater_than?: ResolverInputTypes["DateTime"] | undefined | null,
	less_than_equal?: ResolverInputTypes["DateTime"] | undefined | null,
	less_than?: ResolverInputTypes["DateTime"] | undefined | null,
	like?: ResolverInputTypes["DateTime"] | undefined | null,
	exists?: boolean | undefined | null
};
	["Static_createdAt_operator"]: {
	equals?: ResolverInputTypes["DateTime"] | undefined | null,
	not_equals?: ResolverInputTypes["DateTime"] | undefined | null,
	greater_than_equal?: ResolverInputTypes["DateTime"] | undefined | null,
	greater_than?: ResolverInputTypes["DateTime"] | undefined | null,
	less_than_equal?: ResolverInputTypes["DateTime"] | undefined | null,
	less_than?: ResolverInputTypes["DateTime"] | undefined | null,
	like?: ResolverInputTypes["DateTime"] | undefined | null,
	exists?: boolean | undefined | null
};
	["Static_id_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["Static_where_and"]: {
	name?: ResolverInputTypes["Static_name_operator"] | undefined | null,
	key?: ResolverInputTypes["Static_key_operator"] | undefined | null,
	region?: ResolverInputTypes["Static_region_operator"] | undefined | null,
	updatedAt?: ResolverInputTypes["Static_updatedAt_operator"] | undefined | null,
	createdAt?: ResolverInputTypes["Static_createdAt_operator"] | undefined | null,
	id?: ResolverInputTypes["Static_id_operator"] | undefined | null,
	AND?: Array<ResolverInputTypes["Static_where_and"] | undefined | null> | undefined | null,
	OR?: Array<ResolverInputTypes["Static_where_or"] | undefined | null> | undefined | null
};
	["Static_where_or"]: {
	name?: ResolverInputTypes["Static_name_operator"] | undefined | null,
	key?: ResolverInputTypes["Static_key_operator"] | undefined | null,
	region?: ResolverInputTypes["Static_region_operator"] | undefined | null,
	updatedAt?: ResolverInputTypes["Static_updatedAt_operator"] | undefined | null,
	createdAt?: ResolverInputTypes["Static_createdAt_operator"] | undefined | null,
	id?: ResolverInputTypes["Static_id_operator"] | undefined | null,
	AND?: Array<ResolverInputTypes["Static_where_and"] | undefined | null> | undefined | null,
	OR?: Array<ResolverInputTypes["Static_where_or"] | undefined | null> | undefined | null
};
	["countStatics"]: AliasType<{
	totalDocs?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["staticsDocAccess"]: AliasType<{
	fields?:ResolverInputTypes["StaticsDocAccessFields"],
	create?:ResolverInputTypes["StaticsCreateDocAccess"],
	read?:ResolverInputTypes["StaticsReadDocAccess"],
	update?:ResolverInputTypes["StaticsUpdateDocAccess"],
	delete?:ResolverInputTypes["StaticsDeleteDocAccess"],
		__typename?: boolean | `@${string}`
}>;
	["StaticsDocAccessFields"]: AliasType<{
	name?:ResolverInputTypes["StaticsDocAccessFields_name"],
	key?:ResolverInputTypes["StaticsDocAccessFields_key"],
	content?:ResolverInputTypes["StaticsDocAccessFields_content"],
	region?:ResolverInputTypes["StaticsDocAccessFields_region"],
	updatedAt?:ResolverInputTypes["StaticsDocAccessFields_updatedAt"],
	createdAt?:ResolverInputTypes["StaticsDocAccessFields_createdAt"],
		__typename?: boolean | `@${string}`
}>;
	["StaticsDocAccessFields_name"]: AliasType<{
	create?:ResolverInputTypes["StaticsDocAccessFields_name_Create"],
	read?:ResolverInputTypes["StaticsDocAccessFields_name_Read"],
	update?:ResolverInputTypes["StaticsDocAccessFields_name_Update"],
	delete?:ResolverInputTypes["StaticsDocAccessFields_name_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["StaticsDocAccessFields_name_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["StaticsDocAccessFields_name_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["StaticsDocAccessFields_name_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["StaticsDocAccessFields_name_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["StaticsDocAccessFields_key"]: AliasType<{
	create?:ResolverInputTypes["StaticsDocAccessFields_key_Create"],
	read?:ResolverInputTypes["StaticsDocAccessFields_key_Read"],
	update?:ResolverInputTypes["StaticsDocAccessFields_key_Update"],
	delete?:ResolverInputTypes["StaticsDocAccessFields_key_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["StaticsDocAccessFields_key_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["StaticsDocAccessFields_key_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["StaticsDocAccessFields_key_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["StaticsDocAccessFields_key_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["StaticsDocAccessFields_content"]: AliasType<{
	create?:ResolverInputTypes["StaticsDocAccessFields_content_Create"],
	read?:ResolverInputTypes["StaticsDocAccessFields_content_Read"],
	update?:ResolverInputTypes["StaticsDocAccessFields_content_Update"],
	delete?:ResolverInputTypes["StaticsDocAccessFields_content_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["StaticsDocAccessFields_content_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["StaticsDocAccessFields_content_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["StaticsDocAccessFields_content_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["StaticsDocAccessFields_content_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["StaticsDocAccessFields_region"]: AliasType<{
	create?:ResolverInputTypes["StaticsDocAccessFields_region_Create"],
	read?:ResolverInputTypes["StaticsDocAccessFields_region_Read"],
	update?:ResolverInputTypes["StaticsDocAccessFields_region_Update"],
	delete?:ResolverInputTypes["StaticsDocAccessFields_region_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["StaticsDocAccessFields_region_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["StaticsDocAccessFields_region_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["StaticsDocAccessFields_region_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["StaticsDocAccessFields_region_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["StaticsDocAccessFields_updatedAt"]: AliasType<{
	create?:ResolverInputTypes["StaticsDocAccessFields_updatedAt_Create"],
	read?:ResolverInputTypes["StaticsDocAccessFields_updatedAt_Read"],
	update?:ResolverInputTypes["StaticsDocAccessFields_updatedAt_Update"],
	delete?:ResolverInputTypes["StaticsDocAccessFields_updatedAt_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["StaticsDocAccessFields_updatedAt_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["StaticsDocAccessFields_updatedAt_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["StaticsDocAccessFields_updatedAt_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["StaticsDocAccessFields_updatedAt_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["StaticsDocAccessFields_createdAt"]: AliasType<{
	create?:ResolverInputTypes["StaticsDocAccessFields_createdAt_Create"],
	read?:ResolverInputTypes["StaticsDocAccessFields_createdAt_Read"],
	update?:ResolverInputTypes["StaticsDocAccessFields_createdAt_Update"],
	delete?:ResolverInputTypes["StaticsDocAccessFields_createdAt_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["StaticsDocAccessFields_createdAt_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["StaticsDocAccessFields_createdAt_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["StaticsDocAccessFields_createdAt_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["StaticsDocAccessFields_createdAt_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["StaticsCreateDocAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["StaticsReadDocAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["StaticsUpdateDocAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["StaticsDeleteDocAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventView"]: AliasType<{
	id?:boolean | `@${string}`,
	date?:boolean | `@${string}`,
eventId?: [{	locale?: ResolverInputTypes["LocaleInputType"] | undefined | null,	fallbackLocale?: ResolverInputTypes["FallbackLocaleInputType"] | undefined | null},ResolverInputTypes["Event"]],
	viewCount?:boolean | `@${string}`,
	updatedAt?:boolean | `@${string}`,
	createdAt?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventViews"]: AliasType<{
	docs?:ResolverInputTypes["EventView"],
	hasNextPage?:boolean | `@${string}`,
	hasPrevPage?:boolean | `@${string}`,
	limit?:boolean | `@${string}`,
	nextPage?:boolean | `@${string}`,
	offset?:boolean | `@${string}`,
	page?:boolean | `@${string}`,
	pagingCounter?:boolean | `@${string}`,
	prevPage?:boolean | `@${string}`,
	totalDocs?:boolean | `@${string}`,
	totalPages?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventView_where"]: {
	date?: ResolverInputTypes["EventView_date_operator"] | undefined | null,
	eventId?: ResolverInputTypes["EventView_eventId_operator"] | undefined | null,
	viewCount?: ResolverInputTypes["EventView_viewCount_operator"] | undefined | null,
	updatedAt?: ResolverInputTypes["EventView_updatedAt_operator"] | undefined | null,
	createdAt?: ResolverInputTypes["EventView_createdAt_operator"] | undefined | null,
	id?: ResolverInputTypes["EventView_id_operator"] | undefined | null,
	AND?: Array<ResolverInputTypes["EventView_where_and"] | undefined | null> | undefined | null,
	OR?: Array<ResolverInputTypes["EventView_where_or"] | undefined | null> | undefined | null
};
	["EventView_date_operator"]: {
	equals?: ResolverInputTypes["DateTime"] | undefined | null,
	not_equals?: ResolverInputTypes["DateTime"] | undefined | null,
	greater_than_equal?: ResolverInputTypes["DateTime"] | undefined | null,
	greater_than?: ResolverInputTypes["DateTime"] | undefined | null,
	less_than_equal?: ResolverInputTypes["DateTime"] | undefined | null,
	less_than?: ResolverInputTypes["DateTime"] | undefined | null,
	like?: ResolverInputTypes["DateTime"] | undefined | null
};
	["EventView_eventId_operator"]: {
	equals?: ResolverInputTypes["JSON"] | undefined | null,
	not_equals?: ResolverInputTypes["JSON"] | undefined | null,
	in?: Array<ResolverInputTypes["JSON"] | undefined | null> | undefined | null,
	not_in?: Array<ResolverInputTypes["JSON"] | undefined | null> | undefined | null,
	all?: Array<ResolverInputTypes["JSON"] | undefined | null> | undefined | null
};
	["EventView_viewCount_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null
};
	["EventView_updatedAt_operator"]: {
	equals?: ResolverInputTypes["DateTime"] | undefined | null,
	not_equals?: ResolverInputTypes["DateTime"] | undefined | null,
	greater_than_equal?: ResolverInputTypes["DateTime"] | undefined | null,
	greater_than?: ResolverInputTypes["DateTime"] | undefined | null,
	less_than_equal?: ResolverInputTypes["DateTime"] | undefined | null,
	less_than?: ResolverInputTypes["DateTime"] | undefined | null,
	like?: ResolverInputTypes["DateTime"] | undefined | null,
	exists?: boolean | undefined | null
};
	["EventView_createdAt_operator"]: {
	equals?: ResolverInputTypes["DateTime"] | undefined | null,
	not_equals?: ResolverInputTypes["DateTime"] | undefined | null,
	greater_than_equal?: ResolverInputTypes["DateTime"] | undefined | null,
	greater_than?: ResolverInputTypes["DateTime"] | undefined | null,
	less_than_equal?: ResolverInputTypes["DateTime"] | undefined | null,
	less_than?: ResolverInputTypes["DateTime"] | undefined | null,
	like?: ResolverInputTypes["DateTime"] | undefined | null,
	exists?: boolean | undefined | null
};
	["EventView_id_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["EventView_where_and"]: {
	date?: ResolverInputTypes["EventView_date_operator"] | undefined | null,
	eventId?: ResolverInputTypes["EventView_eventId_operator"] | undefined | null,
	viewCount?: ResolverInputTypes["EventView_viewCount_operator"] | undefined | null,
	updatedAt?: ResolverInputTypes["EventView_updatedAt_operator"] | undefined | null,
	createdAt?: ResolverInputTypes["EventView_createdAt_operator"] | undefined | null,
	id?: ResolverInputTypes["EventView_id_operator"] | undefined | null,
	AND?: Array<ResolverInputTypes["EventView_where_and"] | undefined | null> | undefined | null,
	OR?: Array<ResolverInputTypes["EventView_where_or"] | undefined | null> | undefined | null
};
	["EventView_where_or"]: {
	date?: ResolverInputTypes["EventView_date_operator"] | undefined | null,
	eventId?: ResolverInputTypes["EventView_eventId_operator"] | undefined | null,
	viewCount?: ResolverInputTypes["EventView_viewCount_operator"] | undefined | null,
	updatedAt?: ResolverInputTypes["EventView_updatedAt_operator"] | undefined | null,
	createdAt?: ResolverInputTypes["EventView_createdAt_operator"] | undefined | null,
	id?: ResolverInputTypes["EventView_id_operator"] | undefined | null,
	AND?: Array<ResolverInputTypes["EventView_where_and"] | undefined | null> | undefined | null,
	OR?: Array<ResolverInputTypes["EventView_where_or"] | undefined | null> | undefined | null
};
	["countEventViews"]: AliasType<{
	totalDocs?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["event_viewsDocAccess"]: AliasType<{
	fields?:ResolverInputTypes["EventViewsDocAccessFields"],
	create?:ResolverInputTypes["EventViewsCreateDocAccess"],
	read?:ResolverInputTypes["EventViewsReadDocAccess"],
	update?:ResolverInputTypes["EventViewsUpdateDocAccess"],
	delete?:ResolverInputTypes["EventViewsDeleteDocAccess"],
		__typename?: boolean | `@${string}`
}>;
	["EventViewsDocAccessFields"]: AliasType<{
	date?:ResolverInputTypes["EventViewsDocAccessFields_date"],
	eventId?:ResolverInputTypes["EventViewsDocAccessFields_eventId"],
	viewCount?:ResolverInputTypes["EventViewsDocAccessFields_viewCount"],
	updatedAt?:ResolverInputTypes["EventViewsDocAccessFields_updatedAt"],
	createdAt?:ResolverInputTypes["EventViewsDocAccessFields_createdAt"],
		__typename?: boolean | `@${string}`
}>;
	["EventViewsDocAccessFields_date"]: AliasType<{
	create?:ResolverInputTypes["EventViewsDocAccessFields_date_Create"],
	read?:ResolverInputTypes["EventViewsDocAccessFields_date_Read"],
	update?:ResolverInputTypes["EventViewsDocAccessFields_date_Update"],
	delete?:ResolverInputTypes["EventViewsDocAccessFields_date_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventViewsDocAccessFields_date_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventViewsDocAccessFields_date_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventViewsDocAccessFields_date_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventViewsDocAccessFields_date_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventViewsDocAccessFields_eventId"]: AliasType<{
	create?:ResolverInputTypes["EventViewsDocAccessFields_eventId_Create"],
	read?:ResolverInputTypes["EventViewsDocAccessFields_eventId_Read"],
	update?:ResolverInputTypes["EventViewsDocAccessFields_eventId_Update"],
	delete?:ResolverInputTypes["EventViewsDocAccessFields_eventId_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventViewsDocAccessFields_eventId_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventViewsDocAccessFields_eventId_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventViewsDocAccessFields_eventId_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventViewsDocAccessFields_eventId_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventViewsDocAccessFields_viewCount"]: AliasType<{
	create?:ResolverInputTypes["EventViewsDocAccessFields_viewCount_Create"],
	read?:ResolverInputTypes["EventViewsDocAccessFields_viewCount_Read"],
	update?:ResolverInputTypes["EventViewsDocAccessFields_viewCount_Update"],
	delete?:ResolverInputTypes["EventViewsDocAccessFields_viewCount_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventViewsDocAccessFields_viewCount_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventViewsDocAccessFields_viewCount_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventViewsDocAccessFields_viewCount_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventViewsDocAccessFields_viewCount_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventViewsDocAccessFields_updatedAt"]: AliasType<{
	create?:ResolverInputTypes["EventViewsDocAccessFields_updatedAt_Create"],
	read?:ResolverInputTypes["EventViewsDocAccessFields_updatedAt_Read"],
	update?:ResolverInputTypes["EventViewsDocAccessFields_updatedAt_Update"],
	delete?:ResolverInputTypes["EventViewsDocAccessFields_updatedAt_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventViewsDocAccessFields_updatedAt_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventViewsDocAccessFields_updatedAt_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventViewsDocAccessFields_updatedAt_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventViewsDocAccessFields_updatedAt_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventViewsDocAccessFields_createdAt"]: AliasType<{
	create?:ResolverInputTypes["EventViewsDocAccessFields_createdAt_Create"],
	read?:ResolverInputTypes["EventViewsDocAccessFields_createdAt_Read"],
	update?:ResolverInputTypes["EventViewsDocAccessFields_createdAt_Update"],
	delete?:ResolverInputTypes["EventViewsDocAccessFields_createdAt_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventViewsDocAccessFields_createdAt_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventViewsDocAccessFields_createdAt_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventViewsDocAccessFields_createdAt_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventViewsDocAccessFields_createdAt_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventViewsCreateDocAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventViewsReadDocAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventViewsUpdateDocAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventViewsDeleteDocAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostView"]: AliasType<{
	id?:boolean | `@${string}`,
	date?:boolean | `@${string}`,
postId?: [{	locale?: ResolverInputTypes["LocaleInputType"] | undefined | null,	fallbackLocale?: ResolverInputTypes["FallbackLocaleInputType"] | undefined | null},ResolverInputTypes["Post"]],
	viewCount?:boolean | `@${string}`,
	updatedAt?:boolean | `@${string}`,
	createdAt?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostViews"]: AliasType<{
	docs?:ResolverInputTypes["PostView"],
	hasNextPage?:boolean | `@${string}`,
	hasPrevPage?:boolean | `@${string}`,
	limit?:boolean | `@${string}`,
	nextPage?:boolean | `@${string}`,
	offset?:boolean | `@${string}`,
	page?:boolean | `@${string}`,
	pagingCounter?:boolean | `@${string}`,
	prevPage?:boolean | `@${string}`,
	totalDocs?:boolean | `@${string}`,
	totalPages?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostView_where"]: {
	date?: ResolverInputTypes["PostView_date_operator"] | undefined | null,
	postId?: ResolverInputTypes["PostView_postId_operator"] | undefined | null,
	viewCount?: ResolverInputTypes["PostView_viewCount_operator"] | undefined | null,
	updatedAt?: ResolverInputTypes["PostView_updatedAt_operator"] | undefined | null,
	createdAt?: ResolverInputTypes["PostView_createdAt_operator"] | undefined | null,
	id?: ResolverInputTypes["PostView_id_operator"] | undefined | null,
	AND?: Array<ResolverInputTypes["PostView_where_and"] | undefined | null> | undefined | null,
	OR?: Array<ResolverInputTypes["PostView_where_or"] | undefined | null> | undefined | null
};
	["PostView_date_operator"]: {
	equals?: ResolverInputTypes["DateTime"] | undefined | null,
	not_equals?: ResolverInputTypes["DateTime"] | undefined | null,
	greater_than_equal?: ResolverInputTypes["DateTime"] | undefined | null,
	greater_than?: ResolverInputTypes["DateTime"] | undefined | null,
	less_than_equal?: ResolverInputTypes["DateTime"] | undefined | null,
	less_than?: ResolverInputTypes["DateTime"] | undefined | null,
	like?: ResolverInputTypes["DateTime"] | undefined | null
};
	["PostView_postId_operator"]: {
	equals?: ResolverInputTypes["JSON"] | undefined | null,
	not_equals?: ResolverInputTypes["JSON"] | undefined | null,
	in?: Array<ResolverInputTypes["JSON"] | undefined | null> | undefined | null,
	not_in?: Array<ResolverInputTypes["JSON"] | undefined | null> | undefined | null,
	all?: Array<ResolverInputTypes["JSON"] | undefined | null> | undefined | null
};
	["PostView_viewCount_operator"]: {
	equals?: number | undefined | null,
	not_equals?: number | undefined | null,
	greater_than_equal?: number | undefined | null,
	greater_than?: number | undefined | null,
	less_than_equal?: number | undefined | null,
	less_than?: number | undefined | null
};
	["PostView_updatedAt_operator"]: {
	equals?: ResolverInputTypes["DateTime"] | undefined | null,
	not_equals?: ResolverInputTypes["DateTime"] | undefined | null,
	greater_than_equal?: ResolverInputTypes["DateTime"] | undefined | null,
	greater_than?: ResolverInputTypes["DateTime"] | undefined | null,
	less_than_equal?: ResolverInputTypes["DateTime"] | undefined | null,
	less_than?: ResolverInputTypes["DateTime"] | undefined | null,
	like?: ResolverInputTypes["DateTime"] | undefined | null,
	exists?: boolean | undefined | null
};
	["PostView_createdAt_operator"]: {
	equals?: ResolverInputTypes["DateTime"] | undefined | null,
	not_equals?: ResolverInputTypes["DateTime"] | undefined | null,
	greater_than_equal?: ResolverInputTypes["DateTime"] | undefined | null,
	greater_than?: ResolverInputTypes["DateTime"] | undefined | null,
	less_than_equal?: ResolverInputTypes["DateTime"] | undefined | null,
	less_than?: ResolverInputTypes["DateTime"] | undefined | null,
	like?: ResolverInputTypes["DateTime"] | undefined | null,
	exists?: boolean | undefined | null
};
	["PostView_id_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["PostView_where_and"]: {
	date?: ResolverInputTypes["PostView_date_operator"] | undefined | null,
	postId?: ResolverInputTypes["PostView_postId_operator"] | undefined | null,
	viewCount?: ResolverInputTypes["PostView_viewCount_operator"] | undefined | null,
	updatedAt?: ResolverInputTypes["PostView_updatedAt_operator"] | undefined | null,
	createdAt?: ResolverInputTypes["PostView_createdAt_operator"] | undefined | null,
	id?: ResolverInputTypes["PostView_id_operator"] | undefined | null,
	AND?: Array<ResolverInputTypes["PostView_where_and"] | undefined | null> | undefined | null,
	OR?: Array<ResolverInputTypes["PostView_where_or"] | undefined | null> | undefined | null
};
	["PostView_where_or"]: {
	date?: ResolverInputTypes["PostView_date_operator"] | undefined | null,
	postId?: ResolverInputTypes["PostView_postId_operator"] | undefined | null,
	viewCount?: ResolverInputTypes["PostView_viewCount_operator"] | undefined | null,
	updatedAt?: ResolverInputTypes["PostView_updatedAt_operator"] | undefined | null,
	createdAt?: ResolverInputTypes["PostView_createdAt_operator"] | undefined | null,
	id?: ResolverInputTypes["PostView_id_operator"] | undefined | null,
	AND?: Array<ResolverInputTypes["PostView_where_and"] | undefined | null> | undefined | null,
	OR?: Array<ResolverInputTypes["PostView_where_or"] | undefined | null> | undefined | null
};
	["countPostViews"]: AliasType<{
	totalDocs?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["post_viewsDocAccess"]: AliasType<{
	fields?:ResolverInputTypes["PostViewsDocAccessFields"],
	create?:ResolverInputTypes["PostViewsCreateDocAccess"],
	read?:ResolverInputTypes["PostViewsReadDocAccess"],
	update?:ResolverInputTypes["PostViewsUpdateDocAccess"],
	delete?:ResolverInputTypes["PostViewsDeleteDocAccess"],
		__typename?: boolean | `@${string}`
}>;
	["PostViewsDocAccessFields"]: AliasType<{
	date?:ResolverInputTypes["PostViewsDocAccessFields_date"],
	postId?:ResolverInputTypes["PostViewsDocAccessFields_postId"],
	viewCount?:ResolverInputTypes["PostViewsDocAccessFields_viewCount"],
	updatedAt?:ResolverInputTypes["PostViewsDocAccessFields_updatedAt"],
	createdAt?:ResolverInputTypes["PostViewsDocAccessFields_createdAt"],
		__typename?: boolean | `@${string}`
}>;
	["PostViewsDocAccessFields_date"]: AliasType<{
	create?:ResolverInputTypes["PostViewsDocAccessFields_date_Create"],
	read?:ResolverInputTypes["PostViewsDocAccessFields_date_Read"],
	update?:ResolverInputTypes["PostViewsDocAccessFields_date_Update"],
	delete?:ResolverInputTypes["PostViewsDocAccessFields_date_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["PostViewsDocAccessFields_date_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostViewsDocAccessFields_date_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostViewsDocAccessFields_date_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostViewsDocAccessFields_date_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostViewsDocAccessFields_postId"]: AliasType<{
	create?:ResolverInputTypes["PostViewsDocAccessFields_postId_Create"],
	read?:ResolverInputTypes["PostViewsDocAccessFields_postId_Read"],
	update?:ResolverInputTypes["PostViewsDocAccessFields_postId_Update"],
	delete?:ResolverInputTypes["PostViewsDocAccessFields_postId_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["PostViewsDocAccessFields_postId_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostViewsDocAccessFields_postId_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostViewsDocAccessFields_postId_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostViewsDocAccessFields_postId_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostViewsDocAccessFields_viewCount"]: AliasType<{
	create?:ResolverInputTypes["PostViewsDocAccessFields_viewCount_Create"],
	read?:ResolverInputTypes["PostViewsDocAccessFields_viewCount_Read"],
	update?:ResolverInputTypes["PostViewsDocAccessFields_viewCount_Update"],
	delete?:ResolverInputTypes["PostViewsDocAccessFields_viewCount_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["PostViewsDocAccessFields_viewCount_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostViewsDocAccessFields_viewCount_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostViewsDocAccessFields_viewCount_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostViewsDocAccessFields_viewCount_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostViewsDocAccessFields_updatedAt"]: AliasType<{
	create?:ResolverInputTypes["PostViewsDocAccessFields_updatedAt_Create"],
	read?:ResolverInputTypes["PostViewsDocAccessFields_updatedAt_Read"],
	update?:ResolverInputTypes["PostViewsDocAccessFields_updatedAt_Update"],
	delete?:ResolverInputTypes["PostViewsDocAccessFields_updatedAt_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["PostViewsDocAccessFields_updatedAt_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostViewsDocAccessFields_updatedAt_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostViewsDocAccessFields_updatedAt_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostViewsDocAccessFields_updatedAt_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostViewsDocAccessFields_createdAt"]: AliasType<{
	create?:ResolverInputTypes["PostViewsDocAccessFields_createdAt_Create"],
	read?:ResolverInputTypes["PostViewsDocAccessFields_createdAt_Read"],
	update?:ResolverInputTypes["PostViewsDocAccessFields_createdAt_Update"],
	delete?:ResolverInputTypes["PostViewsDocAccessFields_createdAt_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["PostViewsDocAccessFields_createdAt_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostViewsDocAccessFields_createdAt_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostViewsDocAccessFields_createdAt_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostViewsDocAccessFields_createdAt_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostViewsCreateDocAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostViewsReadDocAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostViewsUpdateDocAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostViewsDeleteDocAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PayloadPreference"]: AliasType<{
	id?:boolean | `@${string}`,
user?: [{	locale?: ResolverInputTypes["LocaleInputType"] | undefined | null,	fallbackLocale?: ResolverInputTypes["FallbackLocaleInputType"] | undefined | null},ResolverInputTypes["PayloadPreference_User_Relationship"]],
	key?:boolean | `@${string}`,
	value?:boolean | `@${string}`,
	updatedAt?:boolean | `@${string}`,
	createdAt?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PayloadPreference_User_Relationship"]: AliasType<{
	relationTo?:boolean | `@${string}`,
	value?:ResolverInputTypes["PayloadPreference_User"],
		__typename?: boolean | `@${string}`
}>;
	["PayloadPreference_User_RelationTo"]:PayloadPreference_User_RelationTo;
	["PayloadPreference_User"]: AliasType<{
	User?:ResolverInputTypes["User"],
		__typename?: boolean | `@${string}`
}>;
	["PayloadPreferences"]: AliasType<{
	docs?:ResolverInputTypes["PayloadPreference"],
	hasNextPage?:boolean | `@${string}`,
	hasPrevPage?:boolean | `@${string}`,
	limit?:boolean | `@${string}`,
	nextPage?:boolean | `@${string}`,
	offset?:boolean | `@${string}`,
	page?:boolean | `@${string}`,
	pagingCounter?:boolean | `@${string}`,
	prevPage?:boolean | `@${string}`,
	totalDocs?:boolean | `@${string}`,
	totalPages?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PayloadPreference_where"]: {
	user?: ResolverInputTypes["PayloadPreference_user_Relation"] | undefined | null,
	key?: ResolverInputTypes["PayloadPreference_key_operator"] | undefined | null,
	value?: ResolverInputTypes["PayloadPreference_value_operator"] | undefined | null,
	updatedAt?: ResolverInputTypes["PayloadPreference_updatedAt_operator"] | undefined | null,
	createdAt?: ResolverInputTypes["PayloadPreference_createdAt_operator"] | undefined | null,
	id?: ResolverInputTypes["PayloadPreference_id_operator"] | undefined | null,
	AND?: Array<ResolverInputTypes["PayloadPreference_where_and"] | undefined | null> | undefined | null,
	OR?: Array<ResolverInputTypes["PayloadPreference_where_or"] | undefined | null> | undefined | null
};
	["PayloadPreference_user_Relation"]: {
	relationTo?: ResolverInputTypes["PayloadPreference_user_Relation_RelationTo"] | undefined | null,
	value?: ResolverInputTypes["JSON"] | undefined | null
};
	["PayloadPreference_user_Relation_RelationTo"]:PayloadPreference_user_Relation_RelationTo;
	["PayloadPreference_key_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["PayloadPreference_value_operator"]: {
	equals?: ResolverInputTypes["JSON"] | undefined | null,
	not_equals?: ResolverInputTypes["JSON"] | undefined | null,
	like?: ResolverInputTypes["JSON"] | undefined | null,
	contains?: ResolverInputTypes["JSON"] | undefined | null,
	within?: ResolverInputTypes["JSON"] | undefined | null,
	intersects?: ResolverInputTypes["JSON"] | undefined | null,
	exists?: boolean | undefined | null
};
	["PayloadPreference_updatedAt_operator"]: {
	equals?: ResolverInputTypes["DateTime"] | undefined | null,
	not_equals?: ResolverInputTypes["DateTime"] | undefined | null,
	greater_than_equal?: ResolverInputTypes["DateTime"] | undefined | null,
	greater_than?: ResolverInputTypes["DateTime"] | undefined | null,
	less_than_equal?: ResolverInputTypes["DateTime"] | undefined | null,
	less_than?: ResolverInputTypes["DateTime"] | undefined | null,
	like?: ResolverInputTypes["DateTime"] | undefined | null,
	exists?: boolean | undefined | null
};
	["PayloadPreference_createdAt_operator"]: {
	equals?: ResolverInputTypes["DateTime"] | undefined | null,
	not_equals?: ResolverInputTypes["DateTime"] | undefined | null,
	greater_than_equal?: ResolverInputTypes["DateTime"] | undefined | null,
	greater_than?: ResolverInputTypes["DateTime"] | undefined | null,
	less_than_equal?: ResolverInputTypes["DateTime"] | undefined | null,
	less_than?: ResolverInputTypes["DateTime"] | undefined | null,
	like?: ResolverInputTypes["DateTime"] | undefined | null,
	exists?: boolean | undefined | null
};
	["PayloadPreference_id_operator"]: {
	equals?: string | undefined | null,
	not_equals?: string | undefined | null,
	like?: string | undefined | null,
	contains?: string | undefined | null,
	in?: Array<string | undefined | null> | undefined | null,
	not_in?: Array<string | undefined | null> | undefined | null,
	all?: Array<string | undefined | null> | undefined | null,
	exists?: boolean | undefined | null
};
	["PayloadPreference_where_and"]: {
	user?: ResolverInputTypes["PayloadPreference_user_Relation"] | undefined | null,
	key?: ResolverInputTypes["PayloadPreference_key_operator"] | undefined | null,
	value?: ResolverInputTypes["PayloadPreference_value_operator"] | undefined | null,
	updatedAt?: ResolverInputTypes["PayloadPreference_updatedAt_operator"] | undefined | null,
	createdAt?: ResolverInputTypes["PayloadPreference_createdAt_operator"] | undefined | null,
	id?: ResolverInputTypes["PayloadPreference_id_operator"] | undefined | null,
	AND?: Array<ResolverInputTypes["PayloadPreference_where_and"] | undefined | null> | undefined | null,
	OR?: Array<ResolverInputTypes["PayloadPreference_where_or"] | undefined | null> | undefined | null
};
	["PayloadPreference_where_or"]: {
	user?: ResolverInputTypes["PayloadPreference_user_Relation"] | undefined | null,
	key?: ResolverInputTypes["PayloadPreference_key_operator"] | undefined | null,
	value?: ResolverInputTypes["PayloadPreference_value_operator"] | undefined | null,
	updatedAt?: ResolverInputTypes["PayloadPreference_updatedAt_operator"] | undefined | null,
	createdAt?: ResolverInputTypes["PayloadPreference_createdAt_operator"] | undefined | null,
	id?: ResolverInputTypes["PayloadPreference_id_operator"] | undefined | null,
	AND?: Array<ResolverInputTypes["PayloadPreference_where_and"] | undefined | null> | undefined | null,
	OR?: Array<ResolverInputTypes["PayloadPreference_where_or"] | undefined | null> | undefined | null
};
	["countPayloadPreferences"]: AliasType<{
	totalDocs?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["payload_preferencesDocAccess"]: AliasType<{
	fields?:ResolverInputTypes["PayloadPreferencesDocAccessFields"],
	create?:ResolverInputTypes["PayloadPreferencesCreateDocAccess"],
	read?:ResolverInputTypes["PayloadPreferencesReadDocAccess"],
	update?:ResolverInputTypes["PayloadPreferencesUpdateDocAccess"],
	delete?:ResolverInputTypes["PayloadPreferencesDeleteDocAccess"],
		__typename?: boolean | `@${string}`
}>;
	["PayloadPreferencesDocAccessFields"]: AliasType<{
	user?:ResolverInputTypes["PayloadPreferencesDocAccessFields_user"],
	key?:ResolverInputTypes["PayloadPreferencesDocAccessFields_key"],
	value?:ResolverInputTypes["PayloadPreferencesDocAccessFields_value"],
	updatedAt?:ResolverInputTypes["PayloadPreferencesDocAccessFields_updatedAt"],
	createdAt?:ResolverInputTypes["PayloadPreferencesDocAccessFields_createdAt"],
		__typename?: boolean | `@${string}`
}>;
	["PayloadPreferencesDocAccessFields_user"]: AliasType<{
	create?:ResolverInputTypes["PayloadPreferencesDocAccessFields_user_Create"],
	read?:ResolverInputTypes["PayloadPreferencesDocAccessFields_user_Read"],
	update?:ResolverInputTypes["PayloadPreferencesDocAccessFields_user_Update"],
	delete?:ResolverInputTypes["PayloadPreferencesDocAccessFields_user_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["PayloadPreferencesDocAccessFields_user_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PayloadPreferencesDocAccessFields_user_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PayloadPreferencesDocAccessFields_user_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PayloadPreferencesDocAccessFields_user_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PayloadPreferencesDocAccessFields_key"]: AliasType<{
	create?:ResolverInputTypes["PayloadPreferencesDocAccessFields_key_Create"],
	read?:ResolverInputTypes["PayloadPreferencesDocAccessFields_key_Read"],
	update?:ResolverInputTypes["PayloadPreferencesDocAccessFields_key_Update"],
	delete?:ResolverInputTypes["PayloadPreferencesDocAccessFields_key_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["PayloadPreferencesDocAccessFields_key_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PayloadPreferencesDocAccessFields_key_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PayloadPreferencesDocAccessFields_key_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PayloadPreferencesDocAccessFields_key_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PayloadPreferencesDocAccessFields_value"]: AliasType<{
	create?:ResolverInputTypes["PayloadPreferencesDocAccessFields_value_Create"],
	read?:ResolverInputTypes["PayloadPreferencesDocAccessFields_value_Read"],
	update?:ResolverInputTypes["PayloadPreferencesDocAccessFields_value_Update"],
	delete?:ResolverInputTypes["PayloadPreferencesDocAccessFields_value_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["PayloadPreferencesDocAccessFields_value_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PayloadPreferencesDocAccessFields_value_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PayloadPreferencesDocAccessFields_value_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PayloadPreferencesDocAccessFields_value_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PayloadPreferencesDocAccessFields_updatedAt"]: AliasType<{
	create?:ResolverInputTypes["PayloadPreferencesDocAccessFields_updatedAt_Create"],
	read?:ResolverInputTypes["PayloadPreferencesDocAccessFields_updatedAt_Read"],
	update?:ResolverInputTypes["PayloadPreferencesDocAccessFields_updatedAt_Update"],
	delete?:ResolverInputTypes["PayloadPreferencesDocAccessFields_updatedAt_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["PayloadPreferencesDocAccessFields_updatedAt_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PayloadPreferencesDocAccessFields_updatedAt_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PayloadPreferencesDocAccessFields_updatedAt_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PayloadPreferencesDocAccessFields_updatedAt_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PayloadPreferencesDocAccessFields_createdAt"]: AliasType<{
	create?:ResolverInputTypes["PayloadPreferencesDocAccessFields_createdAt_Create"],
	read?:ResolverInputTypes["PayloadPreferencesDocAccessFields_createdAt_Read"],
	update?:ResolverInputTypes["PayloadPreferencesDocAccessFields_createdAt_Update"],
	delete?:ResolverInputTypes["PayloadPreferencesDocAccessFields_createdAt_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["PayloadPreferencesDocAccessFields_createdAt_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PayloadPreferencesDocAccessFields_createdAt_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PayloadPreferencesDocAccessFields_createdAt_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PayloadPreferencesDocAccessFields_createdAt_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PayloadPreferencesCreateDocAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PayloadPreferencesReadDocAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PayloadPreferencesUpdateDocAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PayloadPreferencesDeleteDocAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["Access"]: AliasType<{
	canAccessAdmin?:boolean | `@${string}`,
	events?:ResolverInputTypes["eventsAccess"],
	locations?:ResolverInputTypes["locationsAccess"],
	posts?:ResolverInputTypes["postsAccess"],
	ticketing?:ResolverInputTypes["ticketingAccess"],
	products?:ResolverInputTypes["productsAccess"],
	inventory_status?:ResolverInputTypes["inventory_statusAccess"],
	transactions?:ResolverInputTypes["transactionsAccess"],
	redemption_codes?:ResolverInputTypes["redemption_codesAccess"],
	redemption_pdf?:ResolverInputTypes["redemption_pdfAccess"],
	check_in_redemption?:ResolverInputTypes["check_in_redemptionAccess"],
	users?:ResolverInputTypes["usersAccess"],
	bookmarks?:ResolverInputTypes["bookmarksAccess"],
	webAppBookmarks?:ResolverInputTypes["webAppBookmarksAccess"],
	view_records?:ResolverInputTypes["view_recordsAccess"],
	comments?:ResolverInputTypes["commentsAccess"],
	subscriptions?:ResolverInputTypes["subscriptionsAccess"],
	newsletter?:ResolverInputTypes["newsletterAccess"],
	blog_categories?:ResolverInputTypes["blog_categoriesAccess"],
	tags?:ResolverInputTypes["tagsAccess"],
	specialTags?:ResolverInputTypes["specialTagsAccess"],
	date?:ResolverInputTypes["dateAccess"],
	districts?:ResolverInputTypes["districtsAccess"],
	audience?:ResolverInputTypes["audienceAccess"],
	category?:ResolverInputTypes["categoryAccess"],
	private_media?:ResolverInputTypes["private_mediaAccess"],
	media?:ResolverInputTypes["mediaAccess"],
	backupMedia?:ResolverInputTypes["backupMediaAccess"],
	account_suspend_requests?:ResolverInputTypes["account_suspend_requestsAccess"],
	devices?:ResolverInputTypes["devicesAccess"],
	notifications?:ResolverInputTypes["notificationsAccess"],
	redirectSetting?:ResolverInputTypes["redirectSettingAccess"],
	ranking_events?:ResolverInputTypes["ranking_eventsAccess"],
	ranking_locations?:ResolverInputTypes["ranking_locationsAccess"],
	event_calendar?:ResolverInputTypes["event_calendarAccess"],
	editor_choices?:ResolverInputTypes["editor_choicesAccess"],
	criteria_setting?:ResolverInputTypes["criteria_settingAccess"],
	organiserSetting?:ResolverInputTypes["organiserSettingAccess"],
	post_setting?:ResolverInputTypes["post_settingAccess"],
	homepageSetting?:ResolverInputTypes["homepageSettingAccess"],
	banner_section?:ResolverInputTypes["banner_sectionAccess"],
	macao_banner_section?:ResolverInputTypes["macao_banner_sectionAccess"],
	banners?:ResolverInputTypes["bannersAccess"],
	campaigns?:ResolverInputTypes["campaignsAccess"],
	advertises?:ResolverInputTypes["advertisesAccess"],
	statics?:ResolverInputTypes["staticsAccess"],
	event_views?:ResolverInputTypes["event_viewsAccess"],
	post_views?:ResolverInputTypes["post_viewsAccess"],
	payload_preferences?:ResolverInputTypes["payload_preferencesAccess"],
		__typename?: boolean | `@${string}`
}>;
	["eventsAccess"]: AliasType<{
	fields?:ResolverInputTypes["EventsFields"],
	create?:ResolverInputTypes["EventsCreateAccess"],
	read?:ResolverInputTypes["EventsReadAccess"],
	update?:ResolverInputTypes["EventsUpdateAccess"],
	delete?:ResolverInputTypes["EventsDeleteAccess"],
		__typename?: boolean | `@${string}`
}>;
	["EventsFields"]: AliasType<{
	region?:ResolverInputTypes["EventsFields_region"],
	creator?:ResolverInputTypes["EventsFields_creator"],
	permalink?:ResolverInputTypes["EventsFields_permalink"],
	status?:ResolverInputTypes["EventsFields_status"],
	level?:ResolverInputTypes["EventsFields_level"],
	parent?:ResolverInputTypes["EventsFields_parent"],
	name?:ResolverInputTypes["EventsFields_name"],
	chatbotEventTitle?:ResolverInputTypes["EventsFields_chatbotEventTitle"],
	chatbotSequence?:ResolverInputTypes["EventsFields_chatbotSequence"],
	thumbnail?:ResolverInputTypes["EventsFields_thumbnail"],
	banner?:ResolverInputTypes["EventsFields_banner"],
	mobileBanner?:ResolverInputTypes["EventsFields_mobileBanner"],
	sections?:ResolverInputTypes["EventsFields_sections"],
	ticketing?:ResolverInputTypes["EventsFields_ticketing"],
	content?:ResolverInputTypes["EventsFields_content"],
	legacyContent?:ResolverInputTypes["EventsFields_legacyContent"],
	showLegacyContent?:ResolverInputTypes["EventsFields_showLegacyContent"],
	criteria?:ResolverInputTypes["EventsFields_criteria"],
	walkIn?:ResolverInputTypes["EventsFields_walkIn"],
	contact?:ResolverInputTypes["EventsFields_contact"],
	views?:ResolverInputTypes["EventsFields_views"],
	free?:ResolverInputTypes["EventsFields_free"],
	fees?:ResolverInputTypes["EventsFields_fees"],
	homePageInternalClick?:ResolverInputTypes["EventsFields_homePageInternalClick"],
	homePageImpression?:ResolverInputTypes["EventsFields_homePageImpression"],
	eodClickRate?:ResolverInputTypes["EventsFields_eodClickRate"],
	eodImpressionRate?:ResolverInputTypes["EventsFields_eodImpressionRate"],
	top10ClickRateHomepage?:ResolverInputTypes["EventsFields_top10ClickRateHomepage"],
	top10ImpressionkRateHomepage?:ResolverInputTypes["EventsFields_top10ImpressionkRateHomepage"],
	otherEventRankingClickRateHomepage?:ResolverInputTypes["EventsFields_otherEventRankingClickRateHomepage"],
	otherEventRankingImpressionHomePage?:ResolverInputTypes["EventsFields_otherEventRankingImpressionHomePage"],
	userPreferenceClickRate?:ResolverInputTypes["EventsFields_userPreferenceClickRate"],
	userPreferenceImpression?:ResolverInputTypes["EventsFields_userPreferenceImpression"],
	eventSearchInternalClick?:ResolverInputTypes["EventsFields_eventSearchInternalClick"],
	eventListImpression?:ResolverInputTypes["EventsFields_eventListImpression"],
	latestEventInternalClick?:ResolverInputTypes["EventsFields_latestEventInternalClick"],
	latestEventImpression?:ResolverInputTypes["EventsFields_latestEventImpression"],
	topTenEventRankingInternalClick?:ResolverInputTypes["EventsFields_topTenEventRankingInternalClick"],
	topTenImpression?:ResolverInputTypes["EventsFields_topTenImpression"],
	editorChoiceInternalClick?:ResolverInputTypes["EventsFields_editorChoiceInternalClick"],
	editorChoiceImpression?:ResolverInputTypes["EventsFields_editorChoiceImpression"],
	otherEventRankingInternalClick?:ResolverInputTypes["EventsFields_otherEventRankingInternalClick"],
	otherEventRankingImpression?:ResolverInputTypes["EventsFields_otherEventRankingImpression"],
	bannerInternalClick?:ResolverInputTypes["EventsFields_bannerInternalClick"],
	miscImpression?:ResolverInputTypes["EventsFields_miscImpression"],
	miscInternalClick?:ResolverInputTypes["EventsFields_miscInternalClick"],
	totalImpression?:ResolverInputTypes["EventsFields_totalImpression"],
	internalClick?:ResolverInputTypes["EventsFields_internalClick"],
	outboundClick?:ResolverInputTypes["EventsFields_outboundClick"],
	externalClick?:ResolverInputTypes["EventsFields_externalClick"],
	_viewsCountByMonth?:ResolverInputTypes["EventsFields__viewsCountByMonth"],
	products?:ResolverInputTypes["EventsFields_products"],
	legacyGallery?:ResolverInputTypes["EventsFields_legacyGallery"],
	earliestStartDate?:ResolverInputTypes["EventsFields_earliestStartDate"],
	latestEndDate?:ResolverInputTypes["EventsFields_latestEndDate"],
	latestStartDateTime?:ResolverInputTypes["EventsFields_latestStartDateTime"],
	hasTicketing?:ResolverInputTypes["EventsFields_hasTicketing"],
	hasProducts?:ResolverInputTypes["EventsFields_hasProducts"],
	updatedAt?:ResolverInputTypes["EventsFields_updatedAt"],
	createdAt?:ResolverInputTypes["EventsFields_createdAt"],
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_region"]: AliasType<{
	create?:ResolverInputTypes["EventsFields_region_Create"],
	read?:ResolverInputTypes["EventsFields_region_Read"],
	update?:ResolverInputTypes["EventsFields_region_Update"],
	delete?:ResolverInputTypes["EventsFields_region_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_region_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_region_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_region_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_region_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_creator"]: AliasType<{
	create?:ResolverInputTypes["EventsFields_creator_Create"],
	read?:ResolverInputTypes["EventsFields_creator_Read"],
	update?:ResolverInputTypes["EventsFields_creator_Update"],
	delete?:ResolverInputTypes["EventsFields_creator_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_creator_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_creator_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_creator_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_creator_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_permalink"]: AliasType<{
	create?:ResolverInputTypes["EventsFields_permalink_Create"],
	read?:ResolverInputTypes["EventsFields_permalink_Read"],
	update?:ResolverInputTypes["EventsFields_permalink_Update"],
	delete?:ResolverInputTypes["EventsFields_permalink_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_permalink_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_permalink_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_permalink_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_permalink_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_status"]: AliasType<{
	create?:ResolverInputTypes["EventsFields_status_Create"],
	read?:ResolverInputTypes["EventsFields_status_Read"],
	update?:ResolverInputTypes["EventsFields_status_Update"],
	delete?:ResolverInputTypes["EventsFields_status_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_status_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_status_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_status_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_status_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_level"]: AliasType<{
	create?:ResolverInputTypes["EventsFields_level_Create"],
	read?:ResolverInputTypes["EventsFields_level_Read"],
	update?:ResolverInputTypes["EventsFields_level_Update"],
	delete?:ResolverInputTypes["EventsFields_level_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_level_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_level_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_level_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_level_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_parent"]: AliasType<{
	create?:ResolverInputTypes["EventsFields_parent_Create"],
	read?:ResolverInputTypes["EventsFields_parent_Read"],
	update?:ResolverInputTypes["EventsFields_parent_Update"],
	delete?:ResolverInputTypes["EventsFields_parent_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_parent_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_parent_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_parent_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_parent_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_name"]: AliasType<{
	create?:ResolverInputTypes["EventsFields_name_Create"],
	read?:ResolverInputTypes["EventsFields_name_Read"],
	update?:ResolverInputTypes["EventsFields_name_Update"],
	delete?:ResolverInputTypes["EventsFields_name_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_name_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_name_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_name_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_name_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_chatbotEventTitle"]: AliasType<{
	create?:ResolverInputTypes["EventsFields_chatbotEventTitle_Create"],
	read?:ResolverInputTypes["EventsFields_chatbotEventTitle_Read"],
	update?:ResolverInputTypes["EventsFields_chatbotEventTitle_Update"],
	delete?:ResolverInputTypes["EventsFields_chatbotEventTitle_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_chatbotEventTitle_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_chatbotEventTitle_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_chatbotEventTitle_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_chatbotEventTitle_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_chatbotSequence"]: AliasType<{
	create?:ResolverInputTypes["EventsFields_chatbotSequence_Create"],
	read?:ResolverInputTypes["EventsFields_chatbotSequence_Read"],
	update?:ResolverInputTypes["EventsFields_chatbotSequence_Update"],
	delete?:ResolverInputTypes["EventsFields_chatbotSequence_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_chatbotSequence_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_chatbotSequence_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_chatbotSequence_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_chatbotSequence_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_thumbnail"]: AliasType<{
	create?:ResolverInputTypes["EventsFields_thumbnail_Create"],
	read?:ResolverInputTypes["EventsFields_thumbnail_Read"],
	update?:ResolverInputTypes["EventsFields_thumbnail_Update"],
	delete?:ResolverInputTypes["EventsFields_thumbnail_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_thumbnail_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_thumbnail_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_thumbnail_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_thumbnail_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_banner"]: AliasType<{
	create?:ResolverInputTypes["EventsFields_banner_Create"],
	read?:ResolverInputTypes["EventsFields_banner_Read"],
	update?:ResolverInputTypes["EventsFields_banner_Update"],
	delete?:ResolverInputTypes["EventsFields_banner_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_banner_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_banner_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_banner_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_banner_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_mobileBanner"]: AliasType<{
	create?:ResolverInputTypes["EventsFields_mobileBanner_Create"],
	read?:ResolverInputTypes["EventsFields_mobileBanner_Read"],
	update?:ResolverInputTypes["EventsFields_mobileBanner_Update"],
	delete?:ResolverInputTypes["EventsFields_mobileBanner_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_mobileBanner_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_mobileBanner_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_mobileBanner_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_mobileBanner_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_sections"]: AliasType<{
	create?:ResolverInputTypes["EventsFields_sections_Create"],
	read?:ResolverInputTypes["EventsFields_sections_Read"],
	update?:ResolverInputTypes["EventsFields_sections_Update"],
	delete?:ResolverInputTypes["EventsFields_sections_Delete"],
	fields?:ResolverInputTypes["EventsFields_sections_Fields"],
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_sections_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_sections_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_sections_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_sections_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_sections_Fields"]: AliasType<{
	startDatetime?:ResolverInputTypes["EventsFields_sections_startDatetime"],
	endDatetime?:ResolverInputTypes["EventsFields_sections_endDatetime"],
	fullDay?:ResolverInputTypes["EventsFields_sections_fullDay"],
	repeat?:ResolverInputTypes["EventsFields_sections_repeat"],
	toThisDay?:ResolverInputTypes["EventsFields_sections_toThisDay"],
	recurrance?:ResolverInputTypes["EventsFields_sections_recurrance"],
	sameLocation?:ResolverInputTypes["EventsFields_sections_sameLocation"],
	location?:ResolverInputTypes["EventsFields_sections_location"],
	district?:ResolverInputTypes["EventsFields_sections_district"],
	coordinate?:ResolverInputTypes["EventsFields_sections_coordinate"],
	linkAddress?:ResolverInputTypes["EventsFields_sections_linkAddress"],
	address?:ResolverInputTypes["EventsFields_sections_address"],
	id?:ResolverInputTypes["EventsFields_sections_id"],
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_sections_startDatetime"]: AliasType<{
	create?:ResolverInputTypes["EventsFields_sections_startDatetime_Create"],
	read?:ResolverInputTypes["EventsFields_sections_startDatetime_Read"],
	update?:ResolverInputTypes["EventsFields_sections_startDatetime_Update"],
	delete?:ResolverInputTypes["EventsFields_sections_startDatetime_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_sections_startDatetime_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_sections_startDatetime_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_sections_startDatetime_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_sections_startDatetime_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_sections_endDatetime"]: AliasType<{
	create?:ResolverInputTypes["EventsFields_sections_endDatetime_Create"],
	read?:ResolverInputTypes["EventsFields_sections_endDatetime_Read"],
	update?:ResolverInputTypes["EventsFields_sections_endDatetime_Update"],
	delete?:ResolverInputTypes["EventsFields_sections_endDatetime_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_sections_endDatetime_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_sections_endDatetime_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_sections_endDatetime_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_sections_endDatetime_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_sections_fullDay"]: AliasType<{
	create?:ResolverInputTypes["EventsFields_sections_fullDay_Create"],
	read?:ResolverInputTypes["EventsFields_sections_fullDay_Read"],
	update?:ResolverInputTypes["EventsFields_sections_fullDay_Update"],
	delete?:ResolverInputTypes["EventsFields_sections_fullDay_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_sections_fullDay_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_sections_fullDay_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_sections_fullDay_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_sections_fullDay_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_sections_repeat"]: AliasType<{
	create?:ResolverInputTypes["EventsFields_sections_repeat_Create"],
	read?:ResolverInputTypes["EventsFields_sections_repeat_Read"],
	update?:ResolverInputTypes["EventsFields_sections_repeat_Update"],
	delete?:ResolverInputTypes["EventsFields_sections_repeat_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_sections_repeat_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_sections_repeat_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_sections_repeat_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_sections_repeat_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_sections_toThisDay"]: AliasType<{
	create?:ResolverInputTypes["EventsFields_sections_toThisDay_Create"],
	read?:ResolverInputTypes["EventsFields_sections_toThisDay_Read"],
	update?:ResolverInputTypes["EventsFields_sections_toThisDay_Update"],
	delete?:ResolverInputTypes["EventsFields_sections_toThisDay_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_sections_toThisDay_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_sections_toThisDay_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_sections_toThisDay_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_sections_toThisDay_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_sections_recurrance"]: AliasType<{
	create?:ResolverInputTypes["EventsFields_sections_recurrance_Create"],
	read?:ResolverInputTypes["EventsFields_sections_recurrance_Read"],
	update?:ResolverInputTypes["EventsFields_sections_recurrance_Update"],
	delete?:ResolverInputTypes["EventsFields_sections_recurrance_Delete"],
	fields?:ResolverInputTypes["EventsFields_sections_recurrance_Fields"],
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_sections_recurrance_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_sections_recurrance_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_sections_recurrance_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_sections_recurrance_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_sections_recurrance_Fields"]: AliasType<{
	type?:ResolverInputTypes["EventsFields_sections_recurrance_type"],
	weekday?:ResolverInputTypes["EventsFields_sections_recurrance_weekday"],
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_sections_recurrance_type"]: AliasType<{
	create?:ResolverInputTypes["EventsFields_sections_recurrance_type_Create"],
	read?:ResolverInputTypes["EventsFields_sections_recurrance_type_Read"],
	update?:ResolverInputTypes["EventsFields_sections_recurrance_type_Update"],
	delete?:ResolverInputTypes["EventsFields_sections_recurrance_type_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_sections_recurrance_type_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_sections_recurrance_type_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_sections_recurrance_type_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_sections_recurrance_type_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_sections_recurrance_weekday"]: AliasType<{
	create?:ResolverInputTypes["EventsFields_sections_recurrance_weekday_Create"],
	read?:ResolverInputTypes["EventsFields_sections_recurrance_weekday_Read"],
	update?:ResolverInputTypes["EventsFields_sections_recurrance_weekday_Update"],
	delete?:ResolverInputTypes["EventsFields_sections_recurrance_weekday_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_sections_recurrance_weekday_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_sections_recurrance_weekday_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_sections_recurrance_weekday_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_sections_recurrance_weekday_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_sections_sameLocation"]: AliasType<{
	create?:ResolverInputTypes["EventsFields_sections_sameLocation_Create"],
	read?:ResolverInputTypes["EventsFields_sections_sameLocation_Read"],
	update?:ResolverInputTypes["EventsFields_sections_sameLocation_Update"],
	delete?:ResolverInputTypes["EventsFields_sections_sameLocation_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_sections_sameLocation_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_sections_sameLocation_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_sections_sameLocation_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_sections_sameLocation_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_sections_location"]: AliasType<{
	create?:ResolverInputTypes["EventsFields_sections_location_Create"],
	read?:ResolverInputTypes["EventsFields_sections_location_Read"],
	update?:ResolverInputTypes["EventsFields_sections_location_Update"],
	delete?:ResolverInputTypes["EventsFields_sections_location_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_sections_location_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_sections_location_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_sections_location_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_sections_location_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_sections_district"]: AliasType<{
	create?:ResolverInputTypes["EventsFields_sections_district_Create"],
	read?:ResolverInputTypes["EventsFields_sections_district_Read"],
	update?:ResolverInputTypes["EventsFields_sections_district_Update"],
	delete?:ResolverInputTypes["EventsFields_sections_district_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_sections_district_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_sections_district_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_sections_district_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_sections_district_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_sections_coordinate"]: AliasType<{
	create?:ResolverInputTypes["EventsFields_sections_coordinate_Create"],
	read?:ResolverInputTypes["EventsFields_sections_coordinate_Read"],
	update?:ResolverInputTypes["EventsFields_sections_coordinate_Update"],
	delete?:ResolverInputTypes["EventsFields_sections_coordinate_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_sections_coordinate_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_sections_coordinate_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_sections_coordinate_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_sections_coordinate_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_sections_linkAddress"]: AliasType<{
	create?:ResolverInputTypes["EventsFields_sections_linkAddress_Create"],
	read?:ResolverInputTypes["EventsFields_sections_linkAddress_Read"],
	update?:ResolverInputTypes["EventsFields_sections_linkAddress_Update"],
	delete?:ResolverInputTypes["EventsFields_sections_linkAddress_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_sections_linkAddress_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_sections_linkAddress_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_sections_linkAddress_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_sections_linkAddress_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_sections_address"]: AliasType<{
	create?:ResolverInputTypes["EventsFields_sections_address_Create"],
	read?:ResolverInputTypes["EventsFields_sections_address_Read"],
	update?:ResolverInputTypes["EventsFields_sections_address_Update"],
	delete?:ResolverInputTypes["EventsFields_sections_address_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_sections_address_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_sections_address_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_sections_address_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_sections_address_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_sections_id"]: AliasType<{
	create?:ResolverInputTypes["EventsFields_sections_id_Create"],
	read?:ResolverInputTypes["EventsFields_sections_id_Read"],
	update?:ResolverInputTypes["EventsFields_sections_id_Update"],
	delete?:ResolverInputTypes["EventsFields_sections_id_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_sections_id_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_sections_id_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_sections_id_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_sections_id_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_ticketing"]: AliasType<{
	create?:ResolverInputTypes["EventsFields_ticketing_Create"],
	read?:ResolverInputTypes["EventsFields_ticketing_Read"],
	update?:ResolverInputTypes["EventsFields_ticketing_Update"],
	delete?:ResolverInputTypes["EventsFields_ticketing_Delete"],
	fields?:ResolverInputTypes["EventsFields_ticketing_Fields"],
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_ticketing_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_ticketing_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_ticketing_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_ticketing_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_ticketing_Fields"]: AliasType<{
	startDatetime?:ResolverInputTypes["EventsFields_ticketing_startDatetime"],
	endDatetime?:ResolverInputTypes["EventsFields_ticketing_endDatetime"],
	fullDay?:ResolverInputTypes["EventsFields_ticketing_fullDay"],
	type?:ResolverInputTypes["EventsFields_ticketing_type"],
	ticketingType?:ResolverInputTypes["EventsFields_ticketing_ticketingType"],
	linkedTicketing?:ResolverInputTypes["EventsFields_ticketing_linkedTicketing"],
	promotionType?:ResolverInputTypes["EventsFields_ticketing_promotionType"],
	location?:ResolverInputTypes["EventsFields_ticketing_location"],
	website?:ResolverInputTypes["EventsFields_ticketing_website"],
	description?:ResolverInputTypes["EventsFields_ticketing_description"],
	id?:ResolverInputTypes["EventsFields_ticketing_id"],
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_ticketing_startDatetime"]: AliasType<{
	create?:ResolverInputTypes["EventsFields_ticketing_startDatetime_Create"],
	read?:ResolverInputTypes["EventsFields_ticketing_startDatetime_Read"],
	update?:ResolverInputTypes["EventsFields_ticketing_startDatetime_Update"],
	delete?:ResolverInputTypes["EventsFields_ticketing_startDatetime_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_ticketing_startDatetime_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_ticketing_startDatetime_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_ticketing_startDatetime_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_ticketing_startDatetime_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_ticketing_endDatetime"]: AliasType<{
	create?:ResolverInputTypes["EventsFields_ticketing_endDatetime_Create"],
	read?:ResolverInputTypes["EventsFields_ticketing_endDatetime_Read"],
	update?:ResolverInputTypes["EventsFields_ticketing_endDatetime_Update"],
	delete?:ResolverInputTypes["EventsFields_ticketing_endDatetime_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_ticketing_endDatetime_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_ticketing_endDatetime_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_ticketing_endDatetime_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_ticketing_endDatetime_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_ticketing_fullDay"]: AliasType<{
	create?:ResolverInputTypes["EventsFields_ticketing_fullDay_Create"],
	read?:ResolverInputTypes["EventsFields_ticketing_fullDay_Read"],
	update?:ResolverInputTypes["EventsFields_ticketing_fullDay_Update"],
	delete?:ResolverInputTypes["EventsFields_ticketing_fullDay_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_ticketing_fullDay_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_ticketing_fullDay_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_ticketing_fullDay_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_ticketing_fullDay_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_ticketing_type"]: AliasType<{
	create?:ResolverInputTypes["EventsFields_ticketing_type_Create"],
	read?:ResolverInputTypes["EventsFields_ticketing_type_Read"],
	update?:ResolverInputTypes["EventsFields_ticketing_type_Update"],
	delete?:ResolverInputTypes["EventsFields_ticketing_type_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_ticketing_type_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_ticketing_type_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_ticketing_type_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_ticketing_type_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_ticketing_ticketingType"]: AliasType<{
	create?:ResolverInputTypes["EventsFields_ticketing_ticketingType_Create"],
	read?:ResolverInputTypes["EventsFields_ticketing_ticketingType_Read"],
	update?:ResolverInputTypes["EventsFields_ticketing_ticketingType_Update"],
	delete?:ResolverInputTypes["EventsFields_ticketing_ticketingType_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_ticketing_ticketingType_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_ticketing_ticketingType_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_ticketing_ticketingType_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_ticketing_ticketingType_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_ticketing_linkedTicketing"]: AliasType<{
	create?:ResolverInputTypes["EventsFields_ticketing_linkedTicketing_Create"],
	read?:ResolverInputTypes["EventsFields_ticketing_linkedTicketing_Read"],
	update?:ResolverInputTypes["EventsFields_ticketing_linkedTicketing_Update"],
	delete?:ResolverInputTypes["EventsFields_ticketing_linkedTicketing_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_ticketing_linkedTicketing_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_ticketing_linkedTicketing_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_ticketing_linkedTicketing_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_ticketing_linkedTicketing_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_ticketing_promotionType"]: AliasType<{
	create?:ResolverInputTypes["EventsFields_ticketing_promotionType_Create"],
	read?:ResolverInputTypes["EventsFields_ticketing_promotionType_Read"],
	update?:ResolverInputTypes["EventsFields_ticketing_promotionType_Update"],
	delete?:ResolverInputTypes["EventsFields_ticketing_promotionType_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_ticketing_promotionType_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_ticketing_promotionType_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_ticketing_promotionType_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_ticketing_promotionType_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_ticketing_location"]: AliasType<{
	create?:ResolverInputTypes["EventsFields_ticketing_location_Create"],
	read?:ResolverInputTypes["EventsFields_ticketing_location_Read"],
	update?:ResolverInputTypes["EventsFields_ticketing_location_Update"],
	delete?:ResolverInputTypes["EventsFields_ticketing_location_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_ticketing_location_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_ticketing_location_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_ticketing_location_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_ticketing_location_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_ticketing_website"]: AliasType<{
	create?:ResolverInputTypes["EventsFields_ticketing_website_Create"],
	read?:ResolverInputTypes["EventsFields_ticketing_website_Read"],
	update?:ResolverInputTypes["EventsFields_ticketing_website_Update"],
	delete?:ResolverInputTypes["EventsFields_ticketing_website_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_ticketing_website_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_ticketing_website_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_ticketing_website_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_ticketing_website_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_ticketing_description"]: AliasType<{
	create?:ResolverInputTypes["EventsFields_ticketing_description_Create"],
	read?:ResolverInputTypes["EventsFields_ticketing_description_Read"],
	update?:ResolverInputTypes["EventsFields_ticketing_description_Update"],
	delete?:ResolverInputTypes["EventsFields_ticketing_description_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_ticketing_description_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_ticketing_description_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_ticketing_description_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_ticketing_description_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_ticketing_id"]: AliasType<{
	create?:ResolverInputTypes["EventsFields_ticketing_id_Create"],
	read?:ResolverInputTypes["EventsFields_ticketing_id_Read"],
	update?:ResolverInputTypes["EventsFields_ticketing_id_Update"],
	delete?:ResolverInputTypes["EventsFields_ticketing_id_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_ticketing_id_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_ticketing_id_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_ticketing_id_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_ticketing_id_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_content"]: AliasType<{
	create?:ResolverInputTypes["EventsFields_content_Create"],
	read?:ResolverInputTypes["EventsFields_content_Read"],
	update?:ResolverInputTypes["EventsFields_content_Update"],
	delete?:ResolverInputTypes["EventsFields_content_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_content_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_content_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_content_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_content_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_legacyContent"]: AliasType<{
	create?:ResolverInputTypes["EventsFields_legacyContent_Create"],
	read?:ResolverInputTypes["EventsFields_legacyContent_Read"],
	update?:ResolverInputTypes["EventsFields_legacyContent_Update"],
	delete?:ResolverInputTypes["EventsFields_legacyContent_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_legacyContent_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_legacyContent_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_legacyContent_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_legacyContent_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_showLegacyContent"]: AliasType<{
	create?:ResolverInputTypes["EventsFields_showLegacyContent_Create"],
	read?:ResolverInputTypes["EventsFields_showLegacyContent_Read"],
	update?:ResolverInputTypes["EventsFields_showLegacyContent_Update"],
	delete?:ResolverInputTypes["EventsFields_showLegacyContent_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_showLegacyContent_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_showLegacyContent_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_showLegacyContent_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_showLegacyContent_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_criteria"]: AliasType<{
	create?:ResolverInputTypes["EventsFields_criteria_Create"],
	read?:ResolverInputTypes["EventsFields_criteria_Read"],
	update?:ResolverInputTypes["EventsFields_criteria_Update"],
	delete?:ResolverInputTypes["EventsFields_criteria_Delete"],
	fields?:ResolverInputTypes["EventsFields_criteria_Fields"],
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_criteria_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_criteria_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_criteria_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_criteria_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_criteria_Fields"]: AliasType<{
	categories?:ResolverInputTypes["EventsFields_criteria_categories"],
	audiences?:ResolverInputTypes["EventsFields_criteria_audiences"],
	tags?:ResolverInputTypes["EventsFields_criteria_tags"],
	specialTags?:ResolverInputTypes["EventsFields_criteria_specialTags"],
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_criteria_categories"]: AliasType<{
	create?:ResolverInputTypes["EventsFields_criteria_categories_Create"],
	read?:ResolverInputTypes["EventsFields_criteria_categories_Read"],
	update?:ResolverInputTypes["EventsFields_criteria_categories_Update"],
	delete?:ResolverInputTypes["EventsFields_criteria_categories_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_criteria_categories_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_criteria_categories_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_criteria_categories_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_criteria_categories_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_criteria_audiences"]: AliasType<{
	create?:ResolverInputTypes["EventsFields_criteria_audiences_Create"],
	read?:ResolverInputTypes["EventsFields_criteria_audiences_Read"],
	update?:ResolverInputTypes["EventsFields_criteria_audiences_Update"],
	delete?:ResolverInputTypes["EventsFields_criteria_audiences_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_criteria_audiences_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_criteria_audiences_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_criteria_audiences_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_criteria_audiences_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_criteria_tags"]: AliasType<{
	create?:ResolverInputTypes["EventsFields_criteria_tags_Create"],
	read?:ResolverInputTypes["EventsFields_criteria_tags_Read"],
	update?:ResolverInputTypes["EventsFields_criteria_tags_Update"],
	delete?:ResolverInputTypes["EventsFields_criteria_tags_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_criteria_tags_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_criteria_tags_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_criteria_tags_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_criteria_tags_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_criteria_specialTags"]: AliasType<{
	create?:ResolverInputTypes["EventsFields_criteria_specialTags_Create"],
	read?:ResolverInputTypes["EventsFields_criteria_specialTags_Read"],
	update?:ResolverInputTypes["EventsFields_criteria_specialTags_Update"],
	delete?:ResolverInputTypes["EventsFields_criteria_specialTags_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_criteria_specialTags_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_criteria_specialTags_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_criteria_specialTags_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_criteria_specialTags_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_walkIn"]: AliasType<{
	create?:ResolverInputTypes["EventsFields_walkIn_Create"],
	read?:ResolverInputTypes["EventsFields_walkIn_Read"],
	update?:ResolverInputTypes["EventsFields_walkIn_Update"],
	delete?:ResolverInputTypes["EventsFields_walkIn_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_walkIn_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_walkIn_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_walkIn_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_walkIn_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_contact"]: AliasType<{
	create?:ResolverInputTypes["EventsFields_contact_Create"],
	read?:ResolverInputTypes["EventsFields_contact_Read"],
	update?:ResolverInputTypes["EventsFields_contact_Update"],
	delete?:ResolverInputTypes["EventsFields_contact_Delete"],
	fields?:ResolverInputTypes["EventsFields_contact_Fields"],
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_contact_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_contact_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_contact_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_contact_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_contact_Fields"]: AliasType<{
	code?:ResolverInputTypes["EventsFields_contact_code"],
	phone?:ResolverInputTypes["EventsFields_contact_phone"],
	page?:ResolverInputTypes["EventsFields_contact_page"],
	email?:ResolverInputTypes["EventsFields_contact_email"],
	privacy?:ResolverInputTypes["EventsFields_contact_privacy"],
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_contact_code"]: AliasType<{
	create?:ResolverInputTypes["EventsFields_contact_code_Create"],
	read?:ResolverInputTypes["EventsFields_contact_code_Read"],
	update?:ResolverInputTypes["EventsFields_contact_code_Update"],
	delete?:ResolverInputTypes["EventsFields_contact_code_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_contact_code_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_contact_code_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_contact_code_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_contact_code_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_contact_phone"]: AliasType<{
	create?:ResolverInputTypes["EventsFields_contact_phone_Create"],
	read?:ResolverInputTypes["EventsFields_contact_phone_Read"],
	update?:ResolverInputTypes["EventsFields_contact_phone_Update"],
	delete?:ResolverInputTypes["EventsFields_contact_phone_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_contact_phone_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_contact_phone_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_contact_phone_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_contact_phone_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_contact_page"]: AliasType<{
	create?:ResolverInputTypes["EventsFields_contact_page_Create"],
	read?:ResolverInputTypes["EventsFields_contact_page_Read"],
	update?:ResolverInputTypes["EventsFields_contact_page_Update"],
	delete?:ResolverInputTypes["EventsFields_contact_page_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_contact_page_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_contact_page_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_contact_page_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_contact_page_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_contact_email"]: AliasType<{
	create?:ResolverInputTypes["EventsFields_contact_email_Create"],
	read?:ResolverInputTypes["EventsFields_contact_email_Read"],
	update?:ResolverInputTypes["EventsFields_contact_email_Update"],
	delete?:ResolverInputTypes["EventsFields_contact_email_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_contact_email_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_contact_email_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_contact_email_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_contact_email_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_contact_privacy"]: AliasType<{
	create?:ResolverInputTypes["EventsFields_contact_privacy_Create"],
	read?:ResolverInputTypes["EventsFields_contact_privacy_Read"],
	update?:ResolverInputTypes["EventsFields_contact_privacy_Update"],
	delete?:ResolverInputTypes["EventsFields_contact_privacy_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_contact_privacy_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_contact_privacy_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_contact_privacy_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_contact_privacy_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_views"]: AliasType<{
	create?:ResolverInputTypes["EventsFields_views_Create"],
	read?:ResolverInputTypes["EventsFields_views_Read"],
	update?:ResolverInputTypes["EventsFields_views_Update"],
	delete?:ResolverInputTypes["EventsFields_views_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_views_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_views_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_views_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_views_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_free"]: AliasType<{
	create?:ResolverInputTypes["EventsFields_free_Create"],
	read?:ResolverInputTypes["EventsFields_free_Read"],
	update?:ResolverInputTypes["EventsFields_free_Update"],
	delete?:ResolverInputTypes["EventsFields_free_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_free_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_free_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_free_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_free_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_fees"]: AliasType<{
	create?:ResolverInputTypes["EventsFields_fees_Create"],
	read?:ResolverInputTypes["EventsFields_fees_Read"],
	update?:ResolverInputTypes["EventsFields_fees_Update"],
	delete?:ResolverInputTypes["EventsFields_fees_Delete"],
	fields?:ResolverInputTypes["EventsFields_fees_Fields"],
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_fees_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_fees_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_fees_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_fees_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_fees_Fields"]: AliasType<{
	fee?:ResolverInputTypes["EventsFields_fees_fee"],
	remark?:ResolverInputTypes["EventsFields_fees_remark"],
	id?:ResolverInputTypes["EventsFields_fees_id"],
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_fees_fee"]: AliasType<{
	create?:ResolverInputTypes["EventsFields_fees_fee_Create"],
	read?:ResolverInputTypes["EventsFields_fees_fee_Read"],
	update?:ResolverInputTypes["EventsFields_fees_fee_Update"],
	delete?:ResolverInputTypes["EventsFields_fees_fee_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_fees_fee_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_fees_fee_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_fees_fee_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_fees_fee_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_fees_remark"]: AliasType<{
	create?:ResolverInputTypes["EventsFields_fees_remark_Create"],
	read?:ResolverInputTypes["EventsFields_fees_remark_Read"],
	update?:ResolverInputTypes["EventsFields_fees_remark_Update"],
	delete?:ResolverInputTypes["EventsFields_fees_remark_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_fees_remark_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_fees_remark_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_fees_remark_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_fees_remark_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_fees_id"]: AliasType<{
	create?:ResolverInputTypes["EventsFields_fees_id_Create"],
	read?:ResolverInputTypes["EventsFields_fees_id_Read"],
	update?:ResolverInputTypes["EventsFields_fees_id_Update"],
	delete?:ResolverInputTypes["EventsFields_fees_id_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_fees_id_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_fees_id_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_fees_id_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_fees_id_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_homePageInternalClick"]: AliasType<{
	create?:ResolverInputTypes["EventsFields_homePageInternalClick_Create"],
	read?:ResolverInputTypes["EventsFields_homePageInternalClick_Read"],
	update?:ResolverInputTypes["EventsFields_homePageInternalClick_Update"],
	delete?:ResolverInputTypes["EventsFields_homePageInternalClick_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_homePageInternalClick_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_homePageInternalClick_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_homePageInternalClick_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_homePageInternalClick_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_homePageImpression"]: AliasType<{
	create?:ResolverInputTypes["EventsFields_homePageImpression_Create"],
	read?:ResolverInputTypes["EventsFields_homePageImpression_Read"],
	update?:ResolverInputTypes["EventsFields_homePageImpression_Update"],
	delete?:ResolverInputTypes["EventsFields_homePageImpression_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_homePageImpression_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_homePageImpression_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_homePageImpression_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_homePageImpression_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_eodClickRate"]: AliasType<{
	create?:ResolverInputTypes["EventsFields_eodClickRate_Create"],
	read?:ResolverInputTypes["EventsFields_eodClickRate_Read"],
	update?:ResolverInputTypes["EventsFields_eodClickRate_Update"],
	delete?:ResolverInputTypes["EventsFields_eodClickRate_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_eodClickRate_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_eodClickRate_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_eodClickRate_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_eodClickRate_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_eodImpressionRate"]: AliasType<{
	create?:ResolverInputTypes["EventsFields_eodImpressionRate_Create"],
	read?:ResolverInputTypes["EventsFields_eodImpressionRate_Read"],
	update?:ResolverInputTypes["EventsFields_eodImpressionRate_Update"],
	delete?:ResolverInputTypes["EventsFields_eodImpressionRate_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_eodImpressionRate_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_eodImpressionRate_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_eodImpressionRate_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_eodImpressionRate_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_top10ClickRateHomepage"]: AliasType<{
	create?:ResolverInputTypes["EventsFields_top10ClickRateHomepage_Create"],
	read?:ResolverInputTypes["EventsFields_top10ClickRateHomepage_Read"],
	update?:ResolverInputTypes["EventsFields_top10ClickRateHomepage_Update"],
	delete?:ResolverInputTypes["EventsFields_top10ClickRateHomepage_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_top10ClickRateHomepage_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_top10ClickRateHomepage_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_top10ClickRateHomepage_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_top10ClickRateHomepage_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_top10ImpressionkRateHomepage"]: AliasType<{
	create?:ResolverInputTypes["EventsFields_top10ImpressionkRateHomepage_Create"],
	read?:ResolverInputTypes["EventsFields_top10ImpressionkRateHomepage_Read"],
	update?:ResolverInputTypes["EventsFields_top10ImpressionkRateHomepage_Update"],
	delete?:ResolverInputTypes["EventsFields_top10ImpressionkRateHomepage_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_top10ImpressionkRateHomepage_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_top10ImpressionkRateHomepage_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_top10ImpressionkRateHomepage_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_top10ImpressionkRateHomepage_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_otherEventRankingClickRateHomepage"]: AliasType<{
	create?:ResolverInputTypes["EventsFields_otherEventRankingClickRateHomepage_Create"],
	read?:ResolverInputTypes["EventsFields_otherEventRankingClickRateHomepage_Read"],
	update?:ResolverInputTypes["EventsFields_otherEventRankingClickRateHomepage_Update"],
	delete?:ResolverInputTypes["EventsFields_otherEventRankingClickRateHomepage_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_otherEventRankingClickRateHomepage_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_otherEventRankingClickRateHomepage_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_otherEventRankingClickRateHomepage_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_otherEventRankingClickRateHomepage_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_otherEventRankingImpressionHomePage"]: AliasType<{
	create?:ResolverInputTypes["EventsFields_otherEventRankingImpressionHomePage_Create"],
	read?:ResolverInputTypes["EventsFields_otherEventRankingImpressionHomePage_Read"],
	update?:ResolverInputTypes["EventsFields_otherEventRankingImpressionHomePage_Update"],
	delete?:ResolverInputTypes["EventsFields_otherEventRankingImpressionHomePage_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_otherEventRankingImpressionHomePage_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_otherEventRankingImpressionHomePage_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_otherEventRankingImpressionHomePage_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_otherEventRankingImpressionHomePage_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_userPreferenceClickRate"]: AliasType<{
	create?:ResolverInputTypes["EventsFields_userPreferenceClickRate_Create"],
	read?:ResolverInputTypes["EventsFields_userPreferenceClickRate_Read"],
	update?:ResolverInputTypes["EventsFields_userPreferenceClickRate_Update"],
	delete?:ResolverInputTypes["EventsFields_userPreferenceClickRate_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_userPreferenceClickRate_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_userPreferenceClickRate_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_userPreferenceClickRate_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_userPreferenceClickRate_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_userPreferenceImpression"]: AliasType<{
	create?:ResolverInputTypes["EventsFields_userPreferenceImpression_Create"],
	read?:ResolverInputTypes["EventsFields_userPreferenceImpression_Read"],
	update?:ResolverInputTypes["EventsFields_userPreferenceImpression_Update"],
	delete?:ResolverInputTypes["EventsFields_userPreferenceImpression_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_userPreferenceImpression_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_userPreferenceImpression_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_userPreferenceImpression_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_userPreferenceImpression_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_eventSearchInternalClick"]: AliasType<{
	create?:ResolverInputTypes["EventsFields_eventSearchInternalClick_Create"],
	read?:ResolverInputTypes["EventsFields_eventSearchInternalClick_Read"],
	update?:ResolverInputTypes["EventsFields_eventSearchInternalClick_Update"],
	delete?:ResolverInputTypes["EventsFields_eventSearchInternalClick_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_eventSearchInternalClick_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_eventSearchInternalClick_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_eventSearchInternalClick_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_eventSearchInternalClick_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_eventListImpression"]: AliasType<{
	create?:ResolverInputTypes["EventsFields_eventListImpression_Create"],
	read?:ResolverInputTypes["EventsFields_eventListImpression_Read"],
	update?:ResolverInputTypes["EventsFields_eventListImpression_Update"],
	delete?:ResolverInputTypes["EventsFields_eventListImpression_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_eventListImpression_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_eventListImpression_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_eventListImpression_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_eventListImpression_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_latestEventInternalClick"]: AliasType<{
	create?:ResolverInputTypes["EventsFields_latestEventInternalClick_Create"],
	read?:ResolverInputTypes["EventsFields_latestEventInternalClick_Read"],
	update?:ResolverInputTypes["EventsFields_latestEventInternalClick_Update"],
	delete?:ResolverInputTypes["EventsFields_latestEventInternalClick_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_latestEventInternalClick_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_latestEventInternalClick_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_latestEventInternalClick_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_latestEventInternalClick_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_latestEventImpression"]: AliasType<{
	create?:ResolverInputTypes["EventsFields_latestEventImpression_Create"],
	read?:ResolverInputTypes["EventsFields_latestEventImpression_Read"],
	update?:ResolverInputTypes["EventsFields_latestEventImpression_Update"],
	delete?:ResolverInputTypes["EventsFields_latestEventImpression_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_latestEventImpression_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_latestEventImpression_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_latestEventImpression_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_latestEventImpression_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_topTenEventRankingInternalClick"]: AliasType<{
	create?:ResolverInputTypes["EventsFields_topTenEventRankingInternalClick_Create"],
	read?:ResolverInputTypes["EventsFields_topTenEventRankingInternalClick_Read"],
	update?:ResolverInputTypes["EventsFields_topTenEventRankingInternalClick_Update"],
	delete?:ResolverInputTypes["EventsFields_topTenEventRankingInternalClick_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_topTenEventRankingInternalClick_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_topTenEventRankingInternalClick_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_topTenEventRankingInternalClick_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_topTenEventRankingInternalClick_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_topTenImpression"]: AliasType<{
	create?:ResolverInputTypes["EventsFields_topTenImpression_Create"],
	read?:ResolverInputTypes["EventsFields_topTenImpression_Read"],
	update?:ResolverInputTypes["EventsFields_topTenImpression_Update"],
	delete?:ResolverInputTypes["EventsFields_topTenImpression_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_topTenImpression_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_topTenImpression_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_topTenImpression_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_topTenImpression_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_editorChoiceInternalClick"]: AliasType<{
	create?:ResolverInputTypes["EventsFields_editorChoiceInternalClick_Create"],
	read?:ResolverInputTypes["EventsFields_editorChoiceInternalClick_Read"],
	update?:ResolverInputTypes["EventsFields_editorChoiceInternalClick_Update"],
	delete?:ResolverInputTypes["EventsFields_editorChoiceInternalClick_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_editorChoiceInternalClick_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_editorChoiceInternalClick_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_editorChoiceInternalClick_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_editorChoiceInternalClick_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_editorChoiceImpression"]: AliasType<{
	create?:ResolverInputTypes["EventsFields_editorChoiceImpression_Create"],
	read?:ResolverInputTypes["EventsFields_editorChoiceImpression_Read"],
	update?:ResolverInputTypes["EventsFields_editorChoiceImpression_Update"],
	delete?:ResolverInputTypes["EventsFields_editorChoiceImpression_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_editorChoiceImpression_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_editorChoiceImpression_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_editorChoiceImpression_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_editorChoiceImpression_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_otherEventRankingInternalClick"]: AliasType<{
	create?:ResolverInputTypes["EventsFields_otherEventRankingInternalClick_Create"],
	read?:ResolverInputTypes["EventsFields_otherEventRankingInternalClick_Read"],
	update?:ResolverInputTypes["EventsFields_otherEventRankingInternalClick_Update"],
	delete?:ResolverInputTypes["EventsFields_otherEventRankingInternalClick_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_otherEventRankingInternalClick_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_otherEventRankingInternalClick_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_otherEventRankingInternalClick_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_otherEventRankingInternalClick_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_otherEventRankingImpression"]: AliasType<{
	create?:ResolverInputTypes["EventsFields_otherEventRankingImpression_Create"],
	read?:ResolverInputTypes["EventsFields_otherEventRankingImpression_Read"],
	update?:ResolverInputTypes["EventsFields_otherEventRankingImpression_Update"],
	delete?:ResolverInputTypes["EventsFields_otherEventRankingImpression_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_otherEventRankingImpression_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_otherEventRankingImpression_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_otherEventRankingImpression_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_otherEventRankingImpression_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_bannerInternalClick"]: AliasType<{
	create?:ResolverInputTypes["EventsFields_bannerInternalClick_Create"],
	read?:ResolverInputTypes["EventsFields_bannerInternalClick_Read"],
	update?:ResolverInputTypes["EventsFields_bannerInternalClick_Update"],
	delete?:ResolverInputTypes["EventsFields_bannerInternalClick_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_bannerInternalClick_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_bannerInternalClick_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_bannerInternalClick_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_bannerInternalClick_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_miscImpression"]: AliasType<{
	create?:ResolverInputTypes["EventsFields_miscImpression_Create"],
	read?:ResolverInputTypes["EventsFields_miscImpression_Read"],
	update?:ResolverInputTypes["EventsFields_miscImpression_Update"],
	delete?:ResolverInputTypes["EventsFields_miscImpression_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_miscImpression_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_miscImpression_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_miscImpression_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_miscImpression_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_miscInternalClick"]: AliasType<{
	create?:ResolverInputTypes["EventsFields_miscInternalClick_Create"],
	read?:ResolverInputTypes["EventsFields_miscInternalClick_Read"],
	update?:ResolverInputTypes["EventsFields_miscInternalClick_Update"],
	delete?:ResolverInputTypes["EventsFields_miscInternalClick_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_miscInternalClick_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_miscInternalClick_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_miscInternalClick_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_miscInternalClick_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_totalImpression"]: AliasType<{
	create?:ResolverInputTypes["EventsFields_totalImpression_Create"],
	read?:ResolverInputTypes["EventsFields_totalImpression_Read"],
	update?:ResolverInputTypes["EventsFields_totalImpression_Update"],
	delete?:ResolverInputTypes["EventsFields_totalImpression_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_totalImpression_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_totalImpression_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_totalImpression_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_totalImpression_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_internalClick"]: AliasType<{
	create?:ResolverInputTypes["EventsFields_internalClick_Create"],
	read?:ResolverInputTypes["EventsFields_internalClick_Read"],
	update?:ResolverInputTypes["EventsFields_internalClick_Update"],
	delete?:ResolverInputTypes["EventsFields_internalClick_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_internalClick_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_internalClick_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_internalClick_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_internalClick_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_outboundClick"]: AliasType<{
	create?:ResolverInputTypes["EventsFields_outboundClick_Create"],
	read?:ResolverInputTypes["EventsFields_outboundClick_Read"],
	update?:ResolverInputTypes["EventsFields_outboundClick_Update"],
	delete?:ResolverInputTypes["EventsFields_outboundClick_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_outboundClick_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_outboundClick_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_outboundClick_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_outboundClick_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_externalClick"]: AliasType<{
	create?:ResolverInputTypes["EventsFields_externalClick_Create"],
	read?:ResolverInputTypes["EventsFields_externalClick_Read"],
	update?:ResolverInputTypes["EventsFields_externalClick_Update"],
	delete?:ResolverInputTypes["EventsFields_externalClick_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_externalClick_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_externalClick_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_externalClick_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_externalClick_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields__viewsCountByMonth"]: AliasType<{
	create?:ResolverInputTypes["EventsFields__viewsCountByMonth_Create"],
	read?:ResolverInputTypes["EventsFields__viewsCountByMonth_Read"],
	update?:ResolverInputTypes["EventsFields__viewsCountByMonth_Update"],
	delete?:ResolverInputTypes["EventsFields__viewsCountByMonth_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventsFields__viewsCountByMonth_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields__viewsCountByMonth_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields__viewsCountByMonth_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields__viewsCountByMonth_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_products"]: AliasType<{
	create?:ResolverInputTypes["EventsFields_products_Create"],
	read?:ResolverInputTypes["EventsFields_products_Read"],
	update?:ResolverInputTypes["EventsFields_products_Update"],
	delete?:ResolverInputTypes["EventsFields_products_Delete"],
	fields?:ResolverInputTypes["EventsFields_products_Fields"],
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_products_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_products_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_products_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_products_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_products_Fields"]: AliasType<{
	startDateTime?:ResolverInputTypes["EventsFields_products_startDateTime"],
	endDateTime?:ResolverInputTypes["EventsFields_products_endDateTime"],
	fullDay?:ResolverInputTypes["EventsFields_products_fullDay"],
	linkedProduct?:ResolverInputTypes["EventsFields_products_linkedProduct"],
	id?:ResolverInputTypes["EventsFields_products_id"],
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_products_startDateTime"]: AliasType<{
	create?:ResolverInputTypes["EventsFields_products_startDateTime_Create"],
	read?:ResolverInputTypes["EventsFields_products_startDateTime_Read"],
	update?:ResolverInputTypes["EventsFields_products_startDateTime_Update"],
	delete?:ResolverInputTypes["EventsFields_products_startDateTime_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_products_startDateTime_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_products_startDateTime_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_products_startDateTime_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_products_startDateTime_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_products_endDateTime"]: AliasType<{
	create?:ResolverInputTypes["EventsFields_products_endDateTime_Create"],
	read?:ResolverInputTypes["EventsFields_products_endDateTime_Read"],
	update?:ResolverInputTypes["EventsFields_products_endDateTime_Update"],
	delete?:ResolverInputTypes["EventsFields_products_endDateTime_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_products_endDateTime_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_products_endDateTime_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_products_endDateTime_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_products_endDateTime_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_products_fullDay"]: AliasType<{
	create?:ResolverInputTypes["EventsFields_products_fullDay_Create"],
	read?:ResolverInputTypes["EventsFields_products_fullDay_Read"],
	update?:ResolverInputTypes["EventsFields_products_fullDay_Update"],
	delete?:ResolverInputTypes["EventsFields_products_fullDay_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_products_fullDay_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_products_fullDay_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_products_fullDay_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_products_fullDay_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_products_linkedProduct"]: AliasType<{
	create?:ResolverInputTypes["EventsFields_products_linkedProduct_Create"],
	read?:ResolverInputTypes["EventsFields_products_linkedProduct_Read"],
	update?:ResolverInputTypes["EventsFields_products_linkedProduct_Update"],
	delete?:ResolverInputTypes["EventsFields_products_linkedProduct_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_products_linkedProduct_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_products_linkedProduct_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_products_linkedProduct_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_products_linkedProduct_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_products_id"]: AliasType<{
	create?:ResolverInputTypes["EventsFields_products_id_Create"],
	read?:ResolverInputTypes["EventsFields_products_id_Read"],
	update?:ResolverInputTypes["EventsFields_products_id_Update"],
	delete?:ResolverInputTypes["EventsFields_products_id_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_products_id_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_products_id_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_products_id_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_products_id_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_legacyGallery"]: AliasType<{
	create?:ResolverInputTypes["EventsFields_legacyGallery_Create"],
	read?:ResolverInputTypes["EventsFields_legacyGallery_Read"],
	update?:ResolverInputTypes["EventsFields_legacyGallery_Update"],
	delete?:ResolverInputTypes["EventsFields_legacyGallery_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_legacyGallery_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_legacyGallery_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_legacyGallery_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_legacyGallery_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_earliestStartDate"]: AliasType<{
	create?:ResolverInputTypes["EventsFields_earliestStartDate_Create"],
	read?:ResolverInputTypes["EventsFields_earliestStartDate_Read"],
	update?:ResolverInputTypes["EventsFields_earliestStartDate_Update"],
	delete?:ResolverInputTypes["EventsFields_earliestStartDate_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_earliestStartDate_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_earliestStartDate_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_earliestStartDate_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_earliestStartDate_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_latestEndDate"]: AliasType<{
	create?:ResolverInputTypes["EventsFields_latestEndDate_Create"],
	read?:ResolverInputTypes["EventsFields_latestEndDate_Read"],
	update?:ResolverInputTypes["EventsFields_latestEndDate_Update"],
	delete?:ResolverInputTypes["EventsFields_latestEndDate_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_latestEndDate_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_latestEndDate_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_latestEndDate_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_latestEndDate_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_latestStartDateTime"]: AliasType<{
	create?:ResolverInputTypes["EventsFields_latestStartDateTime_Create"],
	read?:ResolverInputTypes["EventsFields_latestStartDateTime_Read"],
	update?:ResolverInputTypes["EventsFields_latestStartDateTime_Update"],
	delete?:ResolverInputTypes["EventsFields_latestStartDateTime_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_latestStartDateTime_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_latestStartDateTime_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_latestStartDateTime_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_latestStartDateTime_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_hasTicketing"]: AliasType<{
	create?:ResolverInputTypes["EventsFields_hasTicketing_Create"],
	read?:ResolverInputTypes["EventsFields_hasTicketing_Read"],
	update?:ResolverInputTypes["EventsFields_hasTicketing_Update"],
	delete?:ResolverInputTypes["EventsFields_hasTicketing_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_hasTicketing_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_hasTicketing_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_hasTicketing_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_hasTicketing_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_hasProducts"]: AliasType<{
	create?:ResolverInputTypes["EventsFields_hasProducts_Create"],
	read?:ResolverInputTypes["EventsFields_hasProducts_Read"],
	update?:ResolverInputTypes["EventsFields_hasProducts_Update"],
	delete?:ResolverInputTypes["EventsFields_hasProducts_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_hasProducts_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_hasProducts_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_hasProducts_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_hasProducts_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_updatedAt"]: AliasType<{
	create?:ResolverInputTypes["EventsFields_updatedAt_Create"],
	read?:ResolverInputTypes["EventsFields_updatedAt_Read"],
	update?:ResolverInputTypes["EventsFields_updatedAt_Update"],
	delete?:ResolverInputTypes["EventsFields_updatedAt_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_updatedAt_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_updatedAt_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_updatedAt_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_updatedAt_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_createdAt"]: AliasType<{
	create?:ResolverInputTypes["EventsFields_createdAt_Create"],
	read?:ResolverInputTypes["EventsFields_createdAt_Read"],
	update?:ResolverInputTypes["EventsFields_createdAt_Update"],
	delete?:ResolverInputTypes["EventsFields_createdAt_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_createdAt_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_createdAt_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_createdAt_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsFields_createdAt_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsCreateAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsReadAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsUpdateAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventsDeleteAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["locationsAccess"]: AliasType<{
	fields?:ResolverInputTypes["LocationsFields"],
	create?:ResolverInputTypes["LocationsCreateAccess"],
	read?:ResolverInputTypes["LocationsReadAccess"],
	update?:ResolverInputTypes["LocationsUpdateAccess"],
	delete?:ResolverInputTypes["LocationsDeleteAccess"],
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields"]: AliasType<{
	region?:ResolverInputTypes["LocationsFields_region"],
	creator?:ResolverInputTypes["LocationsFields_creator"],
	permalink?:ResolverInputTypes["LocationsFields_permalink"],
	status?:ResolverInputTypes["LocationsFields_status"],
	level?:ResolverInputTypes["LocationsFields_level"],
	parent?:ResolverInputTypes["LocationsFields_parent"],
	name?:ResolverInputTypes["LocationsFields_name"],
	thumbnail?:ResolverInputTypes["LocationsFields_thumbnail"],
	banner?:ResolverInputTypes["LocationsFields_banner"],
	type?:ResolverInputTypes["LocationsFields_type"],
	category?:ResolverInputTypes["LocationsFields_category"],
	district?:ResolverInputTypes["LocationsFields_district"],
	coordinate?:ResolverInputTypes["LocationsFields_coordinate"],
	linkAddress?:ResolverInputTypes["LocationsFields_linkAddress"],
	address?:ResolverInputTypes["LocationsFields_address"],
	Details?:ResolverInputTypes["LocationsFields_Details"],
	content?:ResolverInputTypes["LocationsFields_content"],
	legacyContent?:ResolverInputTypes["LocationsFields_legacyContent"],
	showLegacyContent?:ResolverInputTypes["LocationsFields_showLegacyContent"],
	criteria?:ResolverInputTypes["LocationsFields_criteria"],
	sections?:ResolverInputTypes["LocationsFields_sections"],
	_title?:ResolverInputTypes["LocationsFields__title"],
	legacyGallery?:ResolverInputTypes["LocationsFields_legacyGallery"],
	updatedAt?:ResolverInputTypes["LocationsFields_updatedAt"],
	createdAt?:ResolverInputTypes["LocationsFields_createdAt"],
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields_region"]: AliasType<{
	create?:ResolverInputTypes["LocationsFields_region_Create"],
	read?:ResolverInputTypes["LocationsFields_region_Read"],
	update?:ResolverInputTypes["LocationsFields_region_Update"],
	delete?:ResolverInputTypes["LocationsFields_region_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields_region_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields_region_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields_region_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields_region_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields_creator"]: AliasType<{
	create?:ResolverInputTypes["LocationsFields_creator_Create"],
	read?:ResolverInputTypes["LocationsFields_creator_Read"],
	update?:ResolverInputTypes["LocationsFields_creator_Update"],
	delete?:ResolverInputTypes["LocationsFields_creator_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields_creator_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields_creator_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields_creator_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields_creator_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields_permalink"]: AliasType<{
	create?:ResolverInputTypes["LocationsFields_permalink_Create"],
	read?:ResolverInputTypes["LocationsFields_permalink_Read"],
	update?:ResolverInputTypes["LocationsFields_permalink_Update"],
	delete?:ResolverInputTypes["LocationsFields_permalink_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields_permalink_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields_permalink_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields_permalink_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields_permalink_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields_status"]: AliasType<{
	create?:ResolverInputTypes["LocationsFields_status_Create"],
	read?:ResolverInputTypes["LocationsFields_status_Read"],
	update?:ResolverInputTypes["LocationsFields_status_Update"],
	delete?:ResolverInputTypes["LocationsFields_status_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields_status_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields_status_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields_status_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields_status_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields_level"]: AliasType<{
	create?:ResolverInputTypes["LocationsFields_level_Create"],
	read?:ResolverInputTypes["LocationsFields_level_Read"],
	update?:ResolverInputTypes["LocationsFields_level_Update"],
	delete?:ResolverInputTypes["LocationsFields_level_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields_level_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields_level_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields_level_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields_level_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields_parent"]: AliasType<{
	create?:ResolverInputTypes["LocationsFields_parent_Create"],
	read?:ResolverInputTypes["LocationsFields_parent_Read"],
	update?:ResolverInputTypes["LocationsFields_parent_Update"],
	delete?:ResolverInputTypes["LocationsFields_parent_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields_parent_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields_parent_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields_parent_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields_parent_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields_name"]: AliasType<{
	create?:ResolverInputTypes["LocationsFields_name_Create"],
	read?:ResolverInputTypes["LocationsFields_name_Read"],
	update?:ResolverInputTypes["LocationsFields_name_Update"],
	delete?:ResolverInputTypes["LocationsFields_name_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields_name_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields_name_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields_name_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields_name_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields_thumbnail"]: AliasType<{
	create?:ResolverInputTypes["LocationsFields_thumbnail_Create"],
	read?:ResolverInputTypes["LocationsFields_thumbnail_Read"],
	update?:ResolverInputTypes["LocationsFields_thumbnail_Update"],
	delete?:ResolverInputTypes["LocationsFields_thumbnail_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields_thumbnail_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields_thumbnail_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields_thumbnail_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields_thumbnail_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields_banner"]: AliasType<{
	create?:ResolverInputTypes["LocationsFields_banner_Create"],
	read?:ResolverInputTypes["LocationsFields_banner_Read"],
	update?:ResolverInputTypes["LocationsFields_banner_Update"],
	delete?:ResolverInputTypes["LocationsFields_banner_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields_banner_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields_banner_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields_banner_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields_banner_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields_type"]: AliasType<{
	create?:ResolverInputTypes["LocationsFields_type_Create"],
	read?:ResolverInputTypes["LocationsFields_type_Read"],
	update?:ResolverInputTypes["LocationsFields_type_Update"],
	delete?:ResolverInputTypes["LocationsFields_type_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields_type_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields_type_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields_type_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields_type_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields_category"]: AliasType<{
	create?:ResolverInputTypes["LocationsFields_category_Create"],
	read?:ResolverInputTypes["LocationsFields_category_Read"],
	update?:ResolverInputTypes["LocationsFields_category_Update"],
	delete?:ResolverInputTypes["LocationsFields_category_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields_category_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields_category_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields_category_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields_category_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields_district"]: AliasType<{
	create?:ResolverInputTypes["LocationsFields_district_Create"],
	read?:ResolverInputTypes["LocationsFields_district_Read"],
	update?:ResolverInputTypes["LocationsFields_district_Update"],
	delete?:ResolverInputTypes["LocationsFields_district_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields_district_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields_district_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields_district_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields_district_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields_coordinate"]: AliasType<{
	create?:ResolverInputTypes["LocationsFields_coordinate_Create"],
	read?:ResolverInputTypes["LocationsFields_coordinate_Read"],
	update?:ResolverInputTypes["LocationsFields_coordinate_Update"],
	delete?:ResolverInputTypes["LocationsFields_coordinate_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields_coordinate_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields_coordinate_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields_coordinate_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields_coordinate_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields_linkAddress"]: AliasType<{
	create?:ResolverInputTypes["LocationsFields_linkAddress_Create"],
	read?:ResolverInputTypes["LocationsFields_linkAddress_Read"],
	update?:ResolverInputTypes["LocationsFields_linkAddress_Update"],
	delete?:ResolverInputTypes["LocationsFields_linkAddress_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields_linkAddress_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields_linkAddress_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields_linkAddress_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields_linkAddress_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields_address"]: AliasType<{
	create?:ResolverInputTypes["LocationsFields_address_Create"],
	read?:ResolverInputTypes["LocationsFields_address_Read"],
	update?:ResolverInputTypes["LocationsFields_address_Update"],
	delete?:ResolverInputTypes["LocationsFields_address_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields_address_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields_address_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields_address_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields_address_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields_Details"]: AliasType<{
	create?:ResolverInputTypes["LocationsFields_Details_Create"],
	read?:ResolverInputTypes["LocationsFields_Details_Read"],
	update?:ResolverInputTypes["LocationsFields_Details_Update"],
	delete?:ResolverInputTypes["LocationsFields_Details_Delete"],
	fields?:ResolverInputTypes["LocationsFields_Details_Fields"],
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields_Details_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields_Details_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields_Details_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields_Details_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields_Details_Fields"]: AliasType<{
	code?:ResolverInputTypes["LocationsFields_Details_code"],
	phone?:ResolverInputTypes["LocationsFields_Details_phone"],
	page?:ResolverInputTypes["LocationsFields_Details_page"],
	email?:ResolverInputTypes["LocationsFields_Details_email"],
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields_Details_code"]: AliasType<{
	create?:ResolverInputTypes["LocationsFields_Details_code_Create"],
	read?:ResolverInputTypes["LocationsFields_Details_code_Read"],
	update?:ResolverInputTypes["LocationsFields_Details_code_Update"],
	delete?:ResolverInputTypes["LocationsFields_Details_code_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields_Details_code_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields_Details_code_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields_Details_code_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields_Details_code_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields_Details_phone"]: AliasType<{
	create?:ResolverInputTypes["LocationsFields_Details_phone_Create"],
	read?:ResolverInputTypes["LocationsFields_Details_phone_Read"],
	update?:ResolverInputTypes["LocationsFields_Details_phone_Update"],
	delete?:ResolverInputTypes["LocationsFields_Details_phone_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields_Details_phone_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields_Details_phone_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields_Details_phone_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields_Details_phone_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields_Details_page"]: AliasType<{
	create?:ResolverInputTypes["LocationsFields_Details_page_Create"],
	read?:ResolverInputTypes["LocationsFields_Details_page_Read"],
	update?:ResolverInputTypes["LocationsFields_Details_page_Update"],
	delete?:ResolverInputTypes["LocationsFields_Details_page_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields_Details_page_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields_Details_page_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields_Details_page_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields_Details_page_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields_Details_email"]: AliasType<{
	create?:ResolverInputTypes["LocationsFields_Details_email_Create"],
	read?:ResolverInputTypes["LocationsFields_Details_email_Read"],
	update?:ResolverInputTypes["LocationsFields_Details_email_Update"],
	delete?:ResolverInputTypes["LocationsFields_Details_email_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields_Details_email_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields_Details_email_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields_Details_email_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields_Details_email_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields_content"]: AliasType<{
	create?:ResolverInputTypes["LocationsFields_content_Create"],
	read?:ResolverInputTypes["LocationsFields_content_Read"],
	update?:ResolverInputTypes["LocationsFields_content_Update"],
	delete?:ResolverInputTypes["LocationsFields_content_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields_content_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields_content_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields_content_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields_content_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields_legacyContent"]: AliasType<{
	create?:ResolverInputTypes["LocationsFields_legacyContent_Create"],
	read?:ResolverInputTypes["LocationsFields_legacyContent_Read"],
	update?:ResolverInputTypes["LocationsFields_legacyContent_Update"],
	delete?:ResolverInputTypes["LocationsFields_legacyContent_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields_legacyContent_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields_legacyContent_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields_legacyContent_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields_legacyContent_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields_showLegacyContent"]: AliasType<{
	create?:ResolverInputTypes["LocationsFields_showLegacyContent_Create"],
	read?:ResolverInputTypes["LocationsFields_showLegacyContent_Read"],
	update?:ResolverInputTypes["LocationsFields_showLegacyContent_Update"],
	delete?:ResolverInputTypes["LocationsFields_showLegacyContent_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields_showLegacyContent_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields_showLegacyContent_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields_showLegacyContent_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields_showLegacyContent_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields_criteria"]: AliasType<{
	create?:ResolverInputTypes["LocationsFields_criteria_Create"],
	read?:ResolverInputTypes["LocationsFields_criteria_Read"],
	update?:ResolverInputTypes["LocationsFields_criteria_Update"],
	delete?:ResolverInputTypes["LocationsFields_criteria_Delete"],
	fields?:ResolverInputTypes["LocationsFields_criteria_Fields"],
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields_criteria_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields_criteria_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields_criteria_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields_criteria_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields_criteria_Fields"]: AliasType<{
	audiences?:ResolverInputTypes["LocationsFields_criteria_audiences"],
	dates?:ResolverInputTypes["LocationsFields_criteria_dates"],
	categories?:ResolverInputTypes["LocationsFields_criteria_categories"],
	tags?:ResolverInputTypes["LocationsFields_criteria_tags"],
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields_criteria_audiences"]: AliasType<{
	create?:ResolverInputTypes["LocationsFields_criteria_audiences_Create"],
	read?:ResolverInputTypes["LocationsFields_criteria_audiences_Read"],
	update?:ResolverInputTypes["LocationsFields_criteria_audiences_Update"],
	delete?:ResolverInputTypes["LocationsFields_criteria_audiences_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields_criteria_audiences_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields_criteria_audiences_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields_criteria_audiences_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields_criteria_audiences_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields_criteria_dates"]: AliasType<{
	create?:ResolverInputTypes["LocationsFields_criteria_dates_Create"],
	read?:ResolverInputTypes["LocationsFields_criteria_dates_Read"],
	update?:ResolverInputTypes["LocationsFields_criteria_dates_Update"],
	delete?:ResolverInputTypes["LocationsFields_criteria_dates_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields_criteria_dates_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields_criteria_dates_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields_criteria_dates_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields_criteria_dates_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields_criteria_categories"]: AliasType<{
	create?:ResolverInputTypes["LocationsFields_criteria_categories_Create"],
	read?:ResolverInputTypes["LocationsFields_criteria_categories_Read"],
	update?:ResolverInputTypes["LocationsFields_criteria_categories_Update"],
	delete?:ResolverInputTypes["LocationsFields_criteria_categories_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields_criteria_categories_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields_criteria_categories_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields_criteria_categories_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields_criteria_categories_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields_criteria_tags"]: AliasType<{
	create?:ResolverInputTypes["LocationsFields_criteria_tags_Create"],
	read?:ResolverInputTypes["LocationsFields_criteria_tags_Read"],
	update?:ResolverInputTypes["LocationsFields_criteria_tags_Update"],
	delete?:ResolverInputTypes["LocationsFields_criteria_tags_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields_criteria_tags_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields_criteria_tags_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields_criteria_tags_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields_criteria_tags_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields_sections"]: AliasType<{
	create?:ResolverInputTypes["LocationsFields_sections_Create"],
	read?:ResolverInputTypes["LocationsFields_sections_Read"],
	update?:ResolverInputTypes["LocationsFields_sections_Update"],
	delete?:ResolverInputTypes["LocationsFields_sections_Delete"],
	fields?:ResolverInputTypes["LocationsFields_sections_Fields"],
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields_sections_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields_sections_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields_sections_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields_sections_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields_sections_Fields"]: AliasType<{
	startDatetime?:ResolverInputTypes["LocationsFields_sections_startDatetime"],
	endDatetime?:ResolverInputTypes["LocationsFields_sections_endDatetime"],
	fullDay?:ResolverInputTypes["LocationsFields_sections_fullDay"],
	repeat?:ResolverInputTypes["LocationsFields_sections_repeat"],
	recurrance?:ResolverInputTypes["LocationsFields_sections_recurrance"],
	id?:ResolverInputTypes["LocationsFields_sections_id"],
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields_sections_startDatetime"]: AliasType<{
	create?:ResolverInputTypes["LocationsFields_sections_startDatetime_Create"],
	read?:ResolverInputTypes["LocationsFields_sections_startDatetime_Read"],
	update?:ResolverInputTypes["LocationsFields_sections_startDatetime_Update"],
	delete?:ResolverInputTypes["LocationsFields_sections_startDatetime_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields_sections_startDatetime_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields_sections_startDatetime_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields_sections_startDatetime_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields_sections_startDatetime_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields_sections_endDatetime"]: AliasType<{
	create?:ResolverInputTypes["LocationsFields_sections_endDatetime_Create"],
	read?:ResolverInputTypes["LocationsFields_sections_endDatetime_Read"],
	update?:ResolverInputTypes["LocationsFields_sections_endDatetime_Update"],
	delete?:ResolverInputTypes["LocationsFields_sections_endDatetime_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields_sections_endDatetime_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields_sections_endDatetime_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields_sections_endDatetime_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields_sections_endDatetime_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields_sections_fullDay"]: AliasType<{
	create?:ResolverInputTypes["LocationsFields_sections_fullDay_Create"],
	read?:ResolverInputTypes["LocationsFields_sections_fullDay_Read"],
	update?:ResolverInputTypes["LocationsFields_sections_fullDay_Update"],
	delete?:ResolverInputTypes["LocationsFields_sections_fullDay_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields_sections_fullDay_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields_sections_fullDay_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields_sections_fullDay_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields_sections_fullDay_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields_sections_repeat"]: AliasType<{
	create?:ResolverInputTypes["LocationsFields_sections_repeat_Create"],
	read?:ResolverInputTypes["LocationsFields_sections_repeat_Read"],
	update?:ResolverInputTypes["LocationsFields_sections_repeat_Update"],
	delete?:ResolverInputTypes["LocationsFields_sections_repeat_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields_sections_repeat_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields_sections_repeat_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields_sections_repeat_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields_sections_repeat_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields_sections_recurrance"]: AliasType<{
	create?:ResolverInputTypes["LocationsFields_sections_recurrance_Create"],
	read?:ResolverInputTypes["LocationsFields_sections_recurrance_Read"],
	update?:ResolverInputTypes["LocationsFields_sections_recurrance_Update"],
	delete?:ResolverInputTypes["LocationsFields_sections_recurrance_Delete"],
	fields?:ResolverInputTypes["LocationsFields_sections_recurrance_Fields"],
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields_sections_recurrance_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields_sections_recurrance_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields_sections_recurrance_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields_sections_recurrance_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields_sections_recurrance_Fields"]: AliasType<{
	type?:ResolverInputTypes["LocationsFields_sections_recurrance_type"],
	weekday?:ResolverInputTypes["LocationsFields_sections_recurrance_weekday"],
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields_sections_recurrance_type"]: AliasType<{
	create?:ResolverInputTypes["LocationsFields_sections_recurrance_type_Create"],
	read?:ResolverInputTypes["LocationsFields_sections_recurrance_type_Read"],
	update?:ResolverInputTypes["LocationsFields_sections_recurrance_type_Update"],
	delete?:ResolverInputTypes["LocationsFields_sections_recurrance_type_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields_sections_recurrance_type_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields_sections_recurrance_type_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields_sections_recurrance_type_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields_sections_recurrance_type_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields_sections_recurrance_weekday"]: AliasType<{
	create?:ResolverInputTypes["LocationsFields_sections_recurrance_weekday_Create"],
	read?:ResolverInputTypes["LocationsFields_sections_recurrance_weekday_Read"],
	update?:ResolverInputTypes["LocationsFields_sections_recurrance_weekday_Update"],
	delete?:ResolverInputTypes["LocationsFields_sections_recurrance_weekday_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields_sections_recurrance_weekday_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields_sections_recurrance_weekday_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields_sections_recurrance_weekday_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields_sections_recurrance_weekday_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields_sections_id"]: AliasType<{
	create?:ResolverInputTypes["LocationsFields_sections_id_Create"],
	read?:ResolverInputTypes["LocationsFields_sections_id_Read"],
	update?:ResolverInputTypes["LocationsFields_sections_id_Update"],
	delete?:ResolverInputTypes["LocationsFields_sections_id_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields_sections_id_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields_sections_id_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields_sections_id_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields_sections_id_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields__title"]: AliasType<{
	create?:ResolverInputTypes["LocationsFields__title_Create"],
	read?:ResolverInputTypes["LocationsFields__title_Read"],
	update?:ResolverInputTypes["LocationsFields__title_Update"],
	delete?:ResolverInputTypes["LocationsFields__title_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields__title_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields__title_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields__title_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields__title_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields_legacyGallery"]: AliasType<{
	create?:ResolverInputTypes["LocationsFields_legacyGallery_Create"],
	read?:ResolverInputTypes["LocationsFields_legacyGallery_Read"],
	update?:ResolverInputTypes["LocationsFields_legacyGallery_Update"],
	delete?:ResolverInputTypes["LocationsFields_legacyGallery_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields_legacyGallery_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields_legacyGallery_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields_legacyGallery_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields_legacyGallery_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields_updatedAt"]: AliasType<{
	create?:ResolverInputTypes["LocationsFields_updatedAt_Create"],
	read?:ResolverInputTypes["LocationsFields_updatedAt_Read"],
	update?:ResolverInputTypes["LocationsFields_updatedAt_Update"],
	delete?:ResolverInputTypes["LocationsFields_updatedAt_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields_updatedAt_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields_updatedAt_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields_updatedAt_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields_updatedAt_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields_createdAt"]: AliasType<{
	create?:ResolverInputTypes["LocationsFields_createdAt_Create"],
	read?:ResolverInputTypes["LocationsFields_createdAt_Read"],
	update?:ResolverInputTypes["LocationsFields_createdAt_Update"],
	delete?:ResolverInputTypes["LocationsFields_createdAt_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields_createdAt_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields_createdAt_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields_createdAt_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsFields_createdAt_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsCreateAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsReadAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsUpdateAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["LocationsDeleteAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["postsAccess"]: AliasType<{
	fields?:ResolverInputTypes["PostsFields"],
	create?:ResolverInputTypes["PostsCreateAccess"],
	read?:ResolverInputTypes["PostsReadAccess"],
	update?:ResolverInputTypes["PostsUpdateAccess"],
	delete?:ResolverInputTypes["PostsDeleteAccess"],
		__typename?: boolean | `@${string}`
}>;
	["PostsFields"]: AliasType<{
	title?:ResolverInputTypes["PostsFields_title"],
	region?:ResolverInputTypes["PostsFields_region"],
	authur?:ResolverInputTypes["PostsFields_authur"],
	publishDateTime?:ResolverInputTypes["PostsFields_publishDateTime"],
	permalink?:ResolverInputTypes["PostsFields_permalink"],
	status?:ResolverInputTypes["PostsFields_status"],
	content?:ResolverInputTypes["PostsFields_content"],
	legacyContent?:ResolverInputTypes["PostsFields_legacyContent"],
	showLegacyContent?:ResolverInputTypes["PostsFields_showLegacyContent"],
	blogCategories?:ResolverInputTypes["PostsFields_blogCategories"],
	thumbnail?:ResolverInputTypes["PostsFields_thumbnail"],
	banner?:ResolverInputTypes["PostsFields_banner"],
	criteria?:ResolverInputTypes["PostsFields_criteria"],
	location?:ResolverInputTypes["PostsFields_location"],
	homePageInternalClick?:ResolverInputTypes["PostsFields_homePageInternalClick"],
	homePageImpression?:ResolverInputTypes["PostsFields_homePageImpression"],
	blogListInternalClick?:ResolverInputTypes["PostsFields_blogListInternalClick"],
	blogListImpression?:ResolverInputTypes["PostsFields_blogListImpression"],
	blogDetailInternalClick?:ResolverInputTypes["PostsFields_blogDetailInternalClick"],
	blogDetailImpression?:ResolverInputTypes["PostsFields_blogDetailImpression"],
	relatedEventInternalClick?:ResolverInputTypes["PostsFields_relatedEventInternalClick"],
	relatedEventImpression?:ResolverInputTypes["PostsFields_relatedEventImpression"],
	latestEventInternalClick?:ResolverInputTypes["PostsFields_latestEventInternalClick"],
	latestEventImpression?:ResolverInputTypes["PostsFields_latestEventImpression"],
	internalClick?:ResolverInputTypes["PostsFields_internalClick"],
	totalImpression?:ResolverInputTypes["PostsFields_totalImpression"],
	externalClick?:ResolverInputTypes["PostsFields_externalClick"],
	outboundClick?:ResolverInputTypes["PostsFields_outboundClick"],
	legacyGallery?:ResolverInputTypes["PostsFields_legacyGallery"],
	updatedAt?:ResolverInputTypes["PostsFields_updatedAt"],
	createdAt?:ResolverInputTypes["PostsFields_createdAt"],
		__typename?: boolean | `@${string}`
}>;
	["PostsFields_title"]: AliasType<{
	create?:ResolverInputTypes["PostsFields_title_Create"],
	read?:ResolverInputTypes["PostsFields_title_Read"],
	update?:ResolverInputTypes["PostsFields_title_Update"],
	delete?:ResolverInputTypes["PostsFields_title_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["PostsFields_title_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsFields_title_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsFields_title_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsFields_title_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsFields_region"]: AliasType<{
	create?:ResolverInputTypes["PostsFields_region_Create"],
	read?:ResolverInputTypes["PostsFields_region_Read"],
	update?:ResolverInputTypes["PostsFields_region_Update"],
	delete?:ResolverInputTypes["PostsFields_region_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["PostsFields_region_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsFields_region_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsFields_region_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsFields_region_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsFields_authur"]: AliasType<{
	create?:ResolverInputTypes["PostsFields_authur_Create"],
	read?:ResolverInputTypes["PostsFields_authur_Read"],
	update?:ResolverInputTypes["PostsFields_authur_Update"],
	delete?:ResolverInputTypes["PostsFields_authur_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["PostsFields_authur_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsFields_authur_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsFields_authur_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsFields_authur_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsFields_publishDateTime"]: AliasType<{
	create?:ResolverInputTypes["PostsFields_publishDateTime_Create"],
	read?:ResolverInputTypes["PostsFields_publishDateTime_Read"],
	update?:ResolverInputTypes["PostsFields_publishDateTime_Update"],
	delete?:ResolverInputTypes["PostsFields_publishDateTime_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["PostsFields_publishDateTime_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsFields_publishDateTime_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsFields_publishDateTime_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsFields_publishDateTime_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsFields_permalink"]: AliasType<{
	create?:ResolverInputTypes["PostsFields_permalink_Create"],
	read?:ResolverInputTypes["PostsFields_permalink_Read"],
	update?:ResolverInputTypes["PostsFields_permalink_Update"],
	delete?:ResolverInputTypes["PostsFields_permalink_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["PostsFields_permalink_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsFields_permalink_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsFields_permalink_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsFields_permalink_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsFields_status"]: AliasType<{
	create?:ResolverInputTypes["PostsFields_status_Create"],
	read?:ResolverInputTypes["PostsFields_status_Read"],
	update?:ResolverInputTypes["PostsFields_status_Update"],
	delete?:ResolverInputTypes["PostsFields_status_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["PostsFields_status_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsFields_status_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsFields_status_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsFields_status_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsFields_content"]: AliasType<{
	create?:ResolverInputTypes["PostsFields_content_Create"],
	read?:ResolverInputTypes["PostsFields_content_Read"],
	update?:ResolverInputTypes["PostsFields_content_Update"],
	delete?:ResolverInputTypes["PostsFields_content_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["PostsFields_content_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsFields_content_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsFields_content_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsFields_content_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsFields_legacyContent"]: AliasType<{
	create?:ResolverInputTypes["PostsFields_legacyContent_Create"],
	read?:ResolverInputTypes["PostsFields_legacyContent_Read"],
	update?:ResolverInputTypes["PostsFields_legacyContent_Update"],
	delete?:ResolverInputTypes["PostsFields_legacyContent_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["PostsFields_legacyContent_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsFields_legacyContent_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsFields_legacyContent_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsFields_legacyContent_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsFields_showLegacyContent"]: AliasType<{
	create?:ResolverInputTypes["PostsFields_showLegacyContent_Create"],
	read?:ResolverInputTypes["PostsFields_showLegacyContent_Read"],
	update?:ResolverInputTypes["PostsFields_showLegacyContent_Update"],
	delete?:ResolverInputTypes["PostsFields_showLegacyContent_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["PostsFields_showLegacyContent_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsFields_showLegacyContent_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsFields_showLegacyContent_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsFields_showLegacyContent_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsFields_blogCategories"]: AliasType<{
	create?:ResolverInputTypes["PostsFields_blogCategories_Create"],
	read?:ResolverInputTypes["PostsFields_blogCategories_Read"],
	update?:ResolverInputTypes["PostsFields_blogCategories_Update"],
	delete?:ResolverInputTypes["PostsFields_blogCategories_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["PostsFields_blogCategories_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsFields_blogCategories_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsFields_blogCategories_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsFields_blogCategories_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsFields_thumbnail"]: AliasType<{
	create?:ResolverInputTypes["PostsFields_thumbnail_Create"],
	read?:ResolverInputTypes["PostsFields_thumbnail_Read"],
	update?:ResolverInputTypes["PostsFields_thumbnail_Update"],
	delete?:ResolverInputTypes["PostsFields_thumbnail_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["PostsFields_thumbnail_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsFields_thumbnail_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsFields_thumbnail_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsFields_thumbnail_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsFields_banner"]: AliasType<{
	create?:ResolverInputTypes["PostsFields_banner_Create"],
	read?:ResolverInputTypes["PostsFields_banner_Read"],
	update?:ResolverInputTypes["PostsFields_banner_Update"],
	delete?:ResolverInputTypes["PostsFields_banner_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["PostsFields_banner_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsFields_banner_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsFields_banner_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsFields_banner_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsFields_criteria"]: AliasType<{
	create?:ResolverInputTypes["PostsFields_criteria_Create"],
	read?:ResolverInputTypes["PostsFields_criteria_Read"],
	update?:ResolverInputTypes["PostsFields_criteria_Update"],
	delete?:ResolverInputTypes["PostsFields_criteria_Delete"],
	fields?:ResolverInputTypes["PostsFields_criteria_Fields"],
		__typename?: boolean | `@${string}`
}>;
	["PostsFields_criteria_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsFields_criteria_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsFields_criteria_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsFields_criteria_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsFields_criteria_Fields"]: AliasType<{
	audiences?:ResolverInputTypes["PostsFields_criteria_audiences"],
	dates?:ResolverInputTypes["PostsFields_criteria_dates"],
	categories?:ResolverInputTypes["PostsFields_criteria_categories"],
	tags?:ResolverInputTypes["PostsFields_criteria_tags"],
		__typename?: boolean | `@${string}`
}>;
	["PostsFields_criteria_audiences"]: AliasType<{
	create?:ResolverInputTypes["PostsFields_criteria_audiences_Create"],
	read?:ResolverInputTypes["PostsFields_criteria_audiences_Read"],
	update?:ResolverInputTypes["PostsFields_criteria_audiences_Update"],
	delete?:ResolverInputTypes["PostsFields_criteria_audiences_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["PostsFields_criteria_audiences_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsFields_criteria_audiences_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsFields_criteria_audiences_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsFields_criteria_audiences_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsFields_criteria_dates"]: AliasType<{
	create?:ResolverInputTypes["PostsFields_criteria_dates_Create"],
	read?:ResolverInputTypes["PostsFields_criteria_dates_Read"],
	update?:ResolverInputTypes["PostsFields_criteria_dates_Update"],
	delete?:ResolverInputTypes["PostsFields_criteria_dates_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["PostsFields_criteria_dates_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsFields_criteria_dates_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsFields_criteria_dates_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsFields_criteria_dates_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsFields_criteria_categories"]: AliasType<{
	create?:ResolverInputTypes["PostsFields_criteria_categories_Create"],
	read?:ResolverInputTypes["PostsFields_criteria_categories_Read"],
	update?:ResolverInputTypes["PostsFields_criteria_categories_Update"],
	delete?:ResolverInputTypes["PostsFields_criteria_categories_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["PostsFields_criteria_categories_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsFields_criteria_categories_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsFields_criteria_categories_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsFields_criteria_categories_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsFields_criteria_tags"]: AliasType<{
	create?:ResolverInputTypes["PostsFields_criteria_tags_Create"],
	read?:ResolverInputTypes["PostsFields_criteria_tags_Read"],
	update?:ResolverInputTypes["PostsFields_criteria_tags_Update"],
	delete?:ResolverInputTypes["PostsFields_criteria_tags_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["PostsFields_criteria_tags_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsFields_criteria_tags_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsFields_criteria_tags_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsFields_criteria_tags_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsFields_location"]: AliasType<{
	create?:ResolverInputTypes["PostsFields_location_Create"],
	read?:ResolverInputTypes["PostsFields_location_Read"],
	update?:ResolverInputTypes["PostsFields_location_Update"],
	delete?:ResolverInputTypes["PostsFields_location_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["PostsFields_location_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsFields_location_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsFields_location_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsFields_location_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsFields_homePageInternalClick"]: AliasType<{
	create?:ResolverInputTypes["PostsFields_homePageInternalClick_Create"],
	read?:ResolverInputTypes["PostsFields_homePageInternalClick_Read"],
	update?:ResolverInputTypes["PostsFields_homePageInternalClick_Update"],
	delete?:ResolverInputTypes["PostsFields_homePageInternalClick_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["PostsFields_homePageInternalClick_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsFields_homePageInternalClick_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsFields_homePageInternalClick_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsFields_homePageInternalClick_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsFields_homePageImpression"]: AliasType<{
	create?:ResolverInputTypes["PostsFields_homePageImpression_Create"],
	read?:ResolverInputTypes["PostsFields_homePageImpression_Read"],
	update?:ResolverInputTypes["PostsFields_homePageImpression_Update"],
	delete?:ResolverInputTypes["PostsFields_homePageImpression_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["PostsFields_homePageImpression_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsFields_homePageImpression_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsFields_homePageImpression_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsFields_homePageImpression_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsFields_blogListInternalClick"]: AliasType<{
	create?:ResolverInputTypes["PostsFields_blogListInternalClick_Create"],
	read?:ResolverInputTypes["PostsFields_blogListInternalClick_Read"],
	update?:ResolverInputTypes["PostsFields_blogListInternalClick_Update"],
	delete?:ResolverInputTypes["PostsFields_blogListInternalClick_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["PostsFields_blogListInternalClick_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsFields_blogListInternalClick_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsFields_blogListInternalClick_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsFields_blogListInternalClick_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsFields_blogListImpression"]: AliasType<{
	create?:ResolverInputTypes["PostsFields_blogListImpression_Create"],
	read?:ResolverInputTypes["PostsFields_blogListImpression_Read"],
	update?:ResolverInputTypes["PostsFields_blogListImpression_Update"],
	delete?:ResolverInputTypes["PostsFields_blogListImpression_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["PostsFields_blogListImpression_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsFields_blogListImpression_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsFields_blogListImpression_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsFields_blogListImpression_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsFields_blogDetailInternalClick"]: AliasType<{
	create?:ResolverInputTypes["PostsFields_blogDetailInternalClick_Create"],
	read?:ResolverInputTypes["PostsFields_blogDetailInternalClick_Read"],
	update?:ResolverInputTypes["PostsFields_blogDetailInternalClick_Update"],
	delete?:ResolverInputTypes["PostsFields_blogDetailInternalClick_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["PostsFields_blogDetailInternalClick_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsFields_blogDetailInternalClick_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsFields_blogDetailInternalClick_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsFields_blogDetailInternalClick_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsFields_blogDetailImpression"]: AliasType<{
	create?:ResolverInputTypes["PostsFields_blogDetailImpression_Create"],
	read?:ResolverInputTypes["PostsFields_blogDetailImpression_Read"],
	update?:ResolverInputTypes["PostsFields_blogDetailImpression_Update"],
	delete?:ResolverInputTypes["PostsFields_blogDetailImpression_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["PostsFields_blogDetailImpression_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsFields_blogDetailImpression_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsFields_blogDetailImpression_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsFields_blogDetailImpression_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsFields_relatedEventInternalClick"]: AliasType<{
	create?:ResolverInputTypes["PostsFields_relatedEventInternalClick_Create"],
	read?:ResolverInputTypes["PostsFields_relatedEventInternalClick_Read"],
	update?:ResolverInputTypes["PostsFields_relatedEventInternalClick_Update"],
	delete?:ResolverInputTypes["PostsFields_relatedEventInternalClick_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["PostsFields_relatedEventInternalClick_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsFields_relatedEventInternalClick_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsFields_relatedEventInternalClick_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsFields_relatedEventInternalClick_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsFields_relatedEventImpression"]: AliasType<{
	create?:ResolverInputTypes["PostsFields_relatedEventImpression_Create"],
	read?:ResolverInputTypes["PostsFields_relatedEventImpression_Read"],
	update?:ResolverInputTypes["PostsFields_relatedEventImpression_Update"],
	delete?:ResolverInputTypes["PostsFields_relatedEventImpression_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["PostsFields_relatedEventImpression_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsFields_relatedEventImpression_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsFields_relatedEventImpression_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsFields_relatedEventImpression_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsFields_latestEventInternalClick"]: AliasType<{
	create?:ResolverInputTypes["PostsFields_latestEventInternalClick_Create"],
	read?:ResolverInputTypes["PostsFields_latestEventInternalClick_Read"],
	update?:ResolverInputTypes["PostsFields_latestEventInternalClick_Update"],
	delete?:ResolverInputTypes["PostsFields_latestEventInternalClick_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["PostsFields_latestEventInternalClick_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsFields_latestEventInternalClick_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsFields_latestEventInternalClick_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsFields_latestEventInternalClick_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsFields_latestEventImpression"]: AliasType<{
	create?:ResolverInputTypes["PostsFields_latestEventImpression_Create"],
	read?:ResolverInputTypes["PostsFields_latestEventImpression_Read"],
	update?:ResolverInputTypes["PostsFields_latestEventImpression_Update"],
	delete?:ResolverInputTypes["PostsFields_latestEventImpression_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["PostsFields_latestEventImpression_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsFields_latestEventImpression_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsFields_latestEventImpression_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsFields_latestEventImpression_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsFields_internalClick"]: AliasType<{
	create?:ResolverInputTypes["PostsFields_internalClick_Create"],
	read?:ResolverInputTypes["PostsFields_internalClick_Read"],
	update?:ResolverInputTypes["PostsFields_internalClick_Update"],
	delete?:ResolverInputTypes["PostsFields_internalClick_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["PostsFields_internalClick_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsFields_internalClick_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsFields_internalClick_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsFields_internalClick_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsFields_totalImpression"]: AliasType<{
	create?:ResolverInputTypes["PostsFields_totalImpression_Create"],
	read?:ResolverInputTypes["PostsFields_totalImpression_Read"],
	update?:ResolverInputTypes["PostsFields_totalImpression_Update"],
	delete?:ResolverInputTypes["PostsFields_totalImpression_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["PostsFields_totalImpression_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsFields_totalImpression_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsFields_totalImpression_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsFields_totalImpression_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsFields_externalClick"]: AliasType<{
	create?:ResolverInputTypes["PostsFields_externalClick_Create"],
	read?:ResolverInputTypes["PostsFields_externalClick_Read"],
	update?:ResolverInputTypes["PostsFields_externalClick_Update"],
	delete?:ResolverInputTypes["PostsFields_externalClick_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["PostsFields_externalClick_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsFields_externalClick_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsFields_externalClick_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsFields_externalClick_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsFields_outboundClick"]: AliasType<{
	create?:ResolverInputTypes["PostsFields_outboundClick_Create"],
	read?:ResolverInputTypes["PostsFields_outboundClick_Read"],
	update?:ResolverInputTypes["PostsFields_outboundClick_Update"],
	delete?:ResolverInputTypes["PostsFields_outboundClick_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["PostsFields_outboundClick_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsFields_outboundClick_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsFields_outboundClick_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsFields_outboundClick_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsFields_legacyGallery"]: AliasType<{
	create?:ResolverInputTypes["PostsFields_legacyGallery_Create"],
	read?:ResolverInputTypes["PostsFields_legacyGallery_Read"],
	update?:ResolverInputTypes["PostsFields_legacyGallery_Update"],
	delete?:ResolverInputTypes["PostsFields_legacyGallery_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["PostsFields_legacyGallery_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsFields_legacyGallery_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsFields_legacyGallery_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsFields_legacyGallery_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsFields_updatedAt"]: AliasType<{
	create?:ResolverInputTypes["PostsFields_updatedAt_Create"],
	read?:ResolverInputTypes["PostsFields_updatedAt_Read"],
	update?:ResolverInputTypes["PostsFields_updatedAt_Update"],
	delete?:ResolverInputTypes["PostsFields_updatedAt_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["PostsFields_updatedAt_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsFields_updatedAt_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsFields_updatedAt_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsFields_updatedAt_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsFields_createdAt"]: AliasType<{
	create?:ResolverInputTypes["PostsFields_createdAt_Create"],
	read?:ResolverInputTypes["PostsFields_createdAt_Read"],
	update?:ResolverInputTypes["PostsFields_createdAt_Update"],
	delete?:ResolverInputTypes["PostsFields_createdAt_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["PostsFields_createdAt_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsFields_createdAt_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsFields_createdAt_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsFields_createdAt_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsCreateAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsReadAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsUpdateAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostsDeleteAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ticketingAccess"]: AliasType<{
	fields?:ResolverInputTypes["TicketingFields"],
	create?:ResolverInputTypes["TicketingCreateAccess"],
	read?:ResolverInputTypes["TicketingReadAccess"],
	update?:ResolverInputTypes["TicketingUpdateAccess"],
	delete?:ResolverInputTypes["TicketingDeleteAccess"],
		__typename?: boolean | `@${string}`
}>;
	["TicketingFields"]: AliasType<{
	status?:ResolverInputTypes["TicketingFields_status"],
	creator?:ResolverInputTypes["TicketingFields_creator"],
	productType?:ResolverInputTypes["TicketingFields_productType"],
	productName?:ResolverInputTypes["TicketingFields_productName"],
	referenceId?:ResolverInputTypes["TicketingFields_referenceId"],
	linkedEvent?:ResolverInputTypes["TicketingFields_linkedEvent"],
	CheckInLevel?:ResolverInputTypes["TicketingFields_CheckInLevel"],
	displayPrice?:ResolverInputTypes["TicketingFields_displayPrice"],
	tnc?:ResolverInputTypes["TicketingFields_tnc"],
	cutoffHours?:ResolverInputTypes["TicketingFields_cutoffHours"],
	sessions?:ResolverInputTypes["TicketingFields_sessions"],
	updatedAt?:ResolverInputTypes["TicketingFields_updatedAt"],
	createdAt?:ResolverInputTypes["TicketingFields_createdAt"],
		__typename?: boolean | `@${string}`
}>;
	["TicketingFields_status"]: AliasType<{
	create?:ResolverInputTypes["TicketingFields_status_Create"],
	read?:ResolverInputTypes["TicketingFields_status_Read"],
	update?:ResolverInputTypes["TicketingFields_status_Update"],
	delete?:ResolverInputTypes["TicketingFields_status_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["TicketingFields_status_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingFields_status_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingFields_status_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingFields_status_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingFields_creator"]: AliasType<{
	create?:ResolverInputTypes["TicketingFields_creator_Create"],
	read?:ResolverInputTypes["TicketingFields_creator_Read"],
	update?:ResolverInputTypes["TicketingFields_creator_Update"],
	delete?:ResolverInputTypes["TicketingFields_creator_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["TicketingFields_creator_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingFields_creator_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingFields_creator_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingFields_creator_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingFields_productType"]: AliasType<{
	create?:ResolverInputTypes["TicketingFields_productType_Create"],
	read?:ResolverInputTypes["TicketingFields_productType_Read"],
	update?:ResolverInputTypes["TicketingFields_productType_Update"],
	delete?:ResolverInputTypes["TicketingFields_productType_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["TicketingFields_productType_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingFields_productType_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingFields_productType_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingFields_productType_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingFields_productName"]: AliasType<{
	create?:ResolverInputTypes["TicketingFields_productName_Create"],
	read?:ResolverInputTypes["TicketingFields_productName_Read"],
	update?:ResolverInputTypes["TicketingFields_productName_Update"],
	delete?:ResolverInputTypes["TicketingFields_productName_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["TicketingFields_productName_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingFields_productName_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingFields_productName_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingFields_productName_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingFields_referenceId"]: AliasType<{
	create?:ResolverInputTypes["TicketingFields_referenceId_Create"],
	read?:ResolverInputTypes["TicketingFields_referenceId_Read"],
	update?:ResolverInputTypes["TicketingFields_referenceId_Update"],
	delete?:ResolverInputTypes["TicketingFields_referenceId_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["TicketingFields_referenceId_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingFields_referenceId_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingFields_referenceId_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingFields_referenceId_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingFields_linkedEvent"]: AliasType<{
	create?:ResolverInputTypes["TicketingFields_linkedEvent_Create"],
	read?:ResolverInputTypes["TicketingFields_linkedEvent_Read"],
	update?:ResolverInputTypes["TicketingFields_linkedEvent_Update"],
	delete?:ResolverInputTypes["TicketingFields_linkedEvent_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["TicketingFields_linkedEvent_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingFields_linkedEvent_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingFields_linkedEvent_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingFields_linkedEvent_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingFields_CheckInLevel"]: AliasType<{
	create?:ResolverInputTypes["TicketingFields_CheckInLevel_Create"],
	read?:ResolverInputTypes["TicketingFields_CheckInLevel_Read"],
	update?:ResolverInputTypes["TicketingFields_CheckInLevel_Update"],
	delete?:ResolverInputTypes["TicketingFields_CheckInLevel_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["TicketingFields_CheckInLevel_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingFields_CheckInLevel_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingFields_CheckInLevel_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingFields_CheckInLevel_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingFields_displayPrice"]: AliasType<{
	create?:ResolverInputTypes["TicketingFields_displayPrice_Create"],
	read?:ResolverInputTypes["TicketingFields_displayPrice_Read"],
	update?:ResolverInputTypes["TicketingFields_displayPrice_Update"],
	delete?:ResolverInputTypes["TicketingFields_displayPrice_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["TicketingFields_displayPrice_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingFields_displayPrice_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingFields_displayPrice_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingFields_displayPrice_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingFields_tnc"]: AliasType<{
	create?:ResolverInputTypes["TicketingFields_tnc_Create"],
	read?:ResolverInputTypes["TicketingFields_tnc_Read"],
	update?:ResolverInputTypes["TicketingFields_tnc_Update"],
	delete?:ResolverInputTypes["TicketingFields_tnc_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["TicketingFields_tnc_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingFields_tnc_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingFields_tnc_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingFields_tnc_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingFields_cutoffHours"]: AliasType<{
	create?:ResolverInputTypes["TicketingFields_cutoffHours_Create"],
	read?:ResolverInputTypes["TicketingFields_cutoffHours_Read"],
	update?:ResolverInputTypes["TicketingFields_cutoffHours_Update"],
	delete?:ResolverInputTypes["TicketingFields_cutoffHours_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["TicketingFields_cutoffHours_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingFields_cutoffHours_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingFields_cutoffHours_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingFields_cutoffHours_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingFields_sessions"]: AliasType<{
	create?:ResolverInputTypes["TicketingFields_sessions_Create"],
	read?:ResolverInputTypes["TicketingFields_sessions_Read"],
	update?:ResolverInputTypes["TicketingFields_sessions_Update"],
	delete?:ResolverInputTypes["TicketingFields_sessions_Delete"],
	fields?:ResolverInputTypes["TicketingFields_sessions_Fields"],
		__typename?: boolean | `@${string}`
}>;
	["TicketingFields_sessions_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingFields_sessions_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingFields_sessions_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingFields_sessions_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingFields_sessions_Fields"]: AliasType<{
	productId?:ResolverInputTypes["TicketingFields_sessions_productId"],
	startDate?:ResolverInputTypes["TicketingFields_sessions_startDate"],
	endDate?:ResolverInputTypes["TicketingFields_sessions_endDate"],
	inventories?:ResolverInputTypes["TicketingFields_sessions_inventories"],
	id?:ResolverInputTypes["TicketingFields_sessions_id"],
		__typename?: boolean | `@${string}`
}>;
	["TicketingFields_sessions_productId"]: AliasType<{
	create?:ResolverInputTypes["TicketingFields_sessions_productId_Create"],
	read?:ResolverInputTypes["TicketingFields_sessions_productId_Read"],
	update?:ResolverInputTypes["TicketingFields_sessions_productId_Update"],
	delete?:ResolverInputTypes["TicketingFields_sessions_productId_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["TicketingFields_sessions_productId_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingFields_sessions_productId_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingFields_sessions_productId_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingFields_sessions_productId_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingFields_sessions_startDate"]: AliasType<{
	create?:ResolverInputTypes["TicketingFields_sessions_startDate_Create"],
	read?:ResolverInputTypes["TicketingFields_sessions_startDate_Read"],
	update?:ResolverInputTypes["TicketingFields_sessions_startDate_Update"],
	delete?:ResolverInputTypes["TicketingFields_sessions_startDate_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["TicketingFields_sessions_startDate_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingFields_sessions_startDate_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingFields_sessions_startDate_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingFields_sessions_startDate_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingFields_sessions_endDate"]: AliasType<{
	create?:ResolverInputTypes["TicketingFields_sessions_endDate_Create"],
	read?:ResolverInputTypes["TicketingFields_sessions_endDate_Read"],
	update?:ResolverInputTypes["TicketingFields_sessions_endDate_Update"],
	delete?:ResolverInputTypes["TicketingFields_sessions_endDate_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["TicketingFields_sessions_endDate_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingFields_sessions_endDate_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingFields_sessions_endDate_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingFields_sessions_endDate_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingFields_sessions_inventories"]: AliasType<{
	create?:ResolverInputTypes["TicketingFields_sessions_inventories_Create"],
	read?:ResolverInputTypes["TicketingFields_sessions_inventories_Read"],
	update?:ResolverInputTypes["TicketingFields_sessions_inventories_Update"],
	delete?:ResolverInputTypes["TicketingFields_sessions_inventories_Delete"],
	fields?:ResolverInputTypes["TicketingFields_sessions_inventories_Fields"],
		__typename?: boolean | `@${string}`
}>;
	["TicketingFields_sessions_inventories_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingFields_sessions_inventories_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingFields_sessions_inventories_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingFields_sessions_inventories_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingFields_sessions_inventories_Fields"]: AliasType<{
	name?:ResolverInputTypes["TicketingFields_sessions_inventories_name"],
	quantity?:ResolverInputTypes["TicketingFields_sessions_inventories_quantity"],
	unitPrice?:ResolverInputTypes["TicketingFields_sessions_inventories_unitPrice"],
	handlingFee?:ResolverInputTypes["TicketingFields_sessions_inventories_handlingFee"],
	totalCost?:ResolverInputTypes["TicketingFields_sessions_inventories_totalCost"],
	minPurchase?:ResolverInputTypes["TicketingFields_sessions_inventories_minPurchase"],
	maxPurchase?:ResolverInputTypes["TicketingFields_sessions_inventories_maxPurchase"],
	stocks?:ResolverInputTypes["TicketingFields_sessions_inventories_stocks"],
	remarks?:ResolverInputTypes["TicketingFields_sessions_inventories_remarks"],
		__typename?: boolean | `@${string}`
}>;
	["TicketingFields_sessions_inventories_name"]: AliasType<{
	create?:ResolverInputTypes["TicketingFields_sessions_inventories_name_Create"],
	read?:ResolverInputTypes["TicketingFields_sessions_inventories_name_Read"],
	update?:ResolverInputTypes["TicketingFields_sessions_inventories_name_Update"],
	delete?:ResolverInputTypes["TicketingFields_sessions_inventories_name_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["TicketingFields_sessions_inventories_name_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingFields_sessions_inventories_name_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingFields_sessions_inventories_name_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingFields_sessions_inventories_name_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingFields_sessions_inventories_quantity"]: AliasType<{
	create?:ResolverInputTypes["TicketingFields_sessions_inventories_quantity_Create"],
	read?:ResolverInputTypes["TicketingFields_sessions_inventories_quantity_Read"],
	update?:ResolverInputTypes["TicketingFields_sessions_inventories_quantity_Update"],
	delete?:ResolverInputTypes["TicketingFields_sessions_inventories_quantity_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["TicketingFields_sessions_inventories_quantity_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingFields_sessions_inventories_quantity_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingFields_sessions_inventories_quantity_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingFields_sessions_inventories_quantity_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingFields_sessions_inventories_unitPrice"]: AliasType<{
	create?:ResolverInputTypes["TicketingFields_sessions_inventories_unitPrice_Create"],
	read?:ResolverInputTypes["TicketingFields_sessions_inventories_unitPrice_Read"],
	update?:ResolverInputTypes["TicketingFields_sessions_inventories_unitPrice_Update"],
	delete?:ResolverInputTypes["TicketingFields_sessions_inventories_unitPrice_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["TicketingFields_sessions_inventories_unitPrice_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingFields_sessions_inventories_unitPrice_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingFields_sessions_inventories_unitPrice_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingFields_sessions_inventories_unitPrice_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingFields_sessions_inventories_handlingFee"]: AliasType<{
	create?:ResolverInputTypes["TicketingFields_sessions_inventories_handlingFee_Create"],
	read?:ResolverInputTypes["TicketingFields_sessions_inventories_handlingFee_Read"],
	update?:ResolverInputTypes["TicketingFields_sessions_inventories_handlingFee_Update"],
	delete?:ResolverInputTypes["TicketingFields_sessions_inventories_handlingFee_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["TicketingFields_sessions_inventories_handlingFee_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingFields_sessions_inventories_handlingFee_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingFields_sessions_inventories_handlingFee_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingFields_sessions_inventories_handlingFee_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingFields_sessions_inventories_totalCost"]: AliasType<{
	create?:ResolverInputTypes["TicketingFields_sessions_inventories_totalCost_Create"],
	read?:ResolverInputTypes["TicketingFields_sessions_inventories_totalCost_Read"],
	update?:ResolverInputTypes["TicketingFields_sessions_inventories_totalCost_Update"],
	delete?:ResolverInputTypes["TicketingFields_sessions_inventories_totalCost_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["TicketingFields_sessions_inventories_totalCost_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingFields_sessions_inventories_totalCost_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingFields_sessions_inventories_totalCost_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingFields_sessions_inventories_totalCost_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingFields_sessions_inventories_minPurchase"]: AliasType<{
	create?:ResolverInputTypes["TicketingFields_sessions_inventories_minPurchase_Create"],
	read?:ResolverInputTypes["TicketingFields_sessions_inventories_minPurchase_Read"],
	update?:ResolverInputTypes["TicketingFields_sessions_inventories_minPurchase_Update"],
	delete?:ResolverInputTypes["TicketingFields_sessions_inventories_minPurchase_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["TicketingFields_sessions_inventories_minPurchase_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingFields_sessions_inventories_minPurchase_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingFields_sessions_inventories_minPurchase_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingFields_sessions_inventories_minPurchase_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingFields_sessions_inventories_maxPurchase"]: AliasType<{
	create?:ResolverInputTypes["TicketingFields_sessions_inventories_maxPurchase_Create"],
	read?:ResolverInputTypes["TicketingFields_sessions_inventories_maxPurchase_Read"],
	update?:ResolverInputTypes["TicketingFields_sessions_inventories_maxPurchase_Update"],
	delete?:ResolverInputTypes["TicketingFields_sessions_inventories_maxPurchase_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["TicketingFields_sessions_inventories_maxPurchase_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingFields_sessions_inventories_maxPurchase_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingFields_sessions_inventories_maxPurchase_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingFields_sessions_inventories_maxPurchase_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingFields_sessions_inventories_stocks"]: AliasType<{
	create?:ResolverInputTypes["TicketingFields_sessions_inventories_stocks_Create"],
	read?:ResolverInputTypes["TicketingFields_sessions_inventories_stocks_Read"],
	update?:ResolverInputTypes["TicketingFields_sessions_inventories_stocks_Update"],
	delete?:ResolverInputTypes["TicketingFields_sessions_inventories_stocks_Delete"],
	fields?:ResolverInputTypes["TicketingFields_sessions_inventories_stocks_Fields"],
		__typename?: boolean | `@${string}`
}>;
	["TicketingFields_sessions_inventories_stocks_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingFields_sessions_inventories_stocks_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingFields_sessions_inventories_stocks_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingFields_sessions_inventories_stocks_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingFields_sessions_inventories_stocks_Fields"]: AliasType<{
	stockId?:ResolverInputTypes["TicketingFields_sessions_inventories_stocks_stockId"],
	remaining?:ResolverInputTypes["TicketingFields_sessions_inventories_stocks_remaining"],
	sold?:ResolverInputTypes["TicketingFields_sessions_inventories_stocks_sold"],
	onHold?:ResolverInputTypes["TicketingFields_sessions_inventories_stocks_onHold"],
	checkedIn?:ResolverInputTypes["TicketingFields_sessions_inventories_stocks_checkedIn"],
		__typename?: boolean | `@${string}`
}>;
	["TicketingFields_sessions_inventories_stocks_stockId"]: AliasType<{
	create?:ResolverInputTypes["TicketingFields_sessions_inventories_stocks_stockId_Create"],
	read?:ResolverInputTypes["TicketingFields_sessions_inventories_stocks_stockId_Read"],
	update?:ResolverInputTypes["TicketingFields_sessions_inventories_stocks_stockId_Update"],
	delete?:ResolverInputTypes["TicketingFields_sessions_inventories_stocks_stockId_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["TicketingFields_sessions_inventories_stocks_stockId_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingFields_sessions_inventories_stocks_stockId_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingFields_sessions_inventories_stocks_stockId_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingFields_sessions_inventories_stocks_stockId_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingFields_sessions_inventories_stocks_remaining"]: AliasType<{
	create?:ResolverInputTypes["TicketingFields_sessions_inventories_stocks_remaining_Create"],
	read?:ResolverInputTypes["TicketingFields_sessions_inventories_stocks_remaining_Read"],
	update?:ResolverInputTypes["TicketingFields_sessions_inventories_stocks_remaining_Update"],
	delete?:ResolverInputTypes["TicketingFields_sessions_inventories_stocks_remaining_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["TicketingFields_sessions_inventories_stocks_remaining_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingFields_sessions_inventories_stocks_remaining_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingFields_sessions_inventories_stocks_remaining_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingFields_sessions_inventories_stocks_remaining_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingFields_sessions_inventories_stocks_sold"]: AliasType<{
	create?:ResolverInputTypes["TicketingFields_sessions_inventories_stocks_sold_Create"],
	read?:ResolverInputTypes["TicketingFields_sessions_inventories_stocks_sold_Read"],
	update?:ResolverInputTypes["TicketingFields_sessions_inventories_stocks_sold_Update"],
	delete?:ResolverInputTypes["TicketingFields_sessions_inventories_stocks_sold_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["TicketingFields_sessions_inventories_stocks_sold_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingFields_sessions_inventories_stocks_sold_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingFields_sessions_inventories_stocks_sold_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingFields_sessions_inventories_stocks_sold_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingFields_sessions_inventories_stocks_onHold"]: AliasType<{
	create?:ResolverInputTypes["TicketingFields_sessions_inventories_stocks_onHold_Create"],
	read?:ResolverInputTypes["TicketingFields_sessions_inventories_stocks_onHold_Read"],
	update?:ResolverInputTypes["TicketingFields_sessions_inventories_stocks_onHold_Update"],
	delete?:ResolverInputTypes["TicketingFields_sessions_inventories_stocks_onHold_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["TicketingFields_sessions_inventories_stocks_onHold_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingFields_sessions_inventories_stocks_onHold_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingFields_sessions_inventories_stocks_onHold_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingFields_sessions_inventories_stocks_onHold_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingFields_sessions_inventories_stocks_checkedIn"]: AliasType<{
	create?:ResolverInputTypes["TicketingFields_sessions_inventories_stocks_checkedIn_Create"],
	read?:ResolverInputTypes["TicketingFields_sessions_inventories_stocks_checkedIn_Read"],
	update?:ResolverInputTypes["TicketingFields_sessions_inventories_stocks_checkedIn_Update"],
	delete?:ResolverInputTypes["TicketingFields_sessions_inventories_stocks_checkedIn_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["TicketingFields_sessions_inventories_stocks_checkedIn_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingFields_sessions_inventories_stocks_checkedIn_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingFields_sessions_inventories_stocks_checkedIn_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingFields_sessions_inventories_stocks_checkedIn_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingFields_sessions_inventories_remarks"]: AliasType<{
	create?:ResolverInputTypes["TicketingFields_sessions_inventories_remarks_Create"],
	read?:ResolverInputTypes["TicketingFields_sessions_inventories_remarks_Read"],
	update?:ResolverInputTypes["TicketingFields_sessions_inventories_remarks_Update"],
	delete?:ResolverInputTypes["TicketingFields_sessions_inventories_remarks_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["TicketingFields_sessions_inventories_remarks_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingFields_sessions_inventories_remarks_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingFields_sessions_inventories_remarks_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingFields_sessions_inventories_remarks_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingFields_sessions_id"]: AliasType<{
	create?:ResolverInputTypes["TicketingFields_sessions_id_Create"],
	read?:ResolverInputTypes["TicketingFields_sessions_id_Read"],
	update?:ResolverInputTypes["TicketingFields_sessions_id_Update"],
	delete?:ResolverInputTypes["TicketingFields_sessions_id_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["TicketingFields_sessions_id_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingFields_sessions_id_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingFields_sessions_id_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingFields_sessions_id_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingFields_updatedAt"]: AliasType<{
	create?:ResolverInputTypes["TicketingFields_updatedAt_Create"],
	read?:ResolverInputTypes["TicketingFields_updatedAt_Read"],
	update?:ResolverInputTypes["TicketingFields_updatedAt_Update"],
	delete?:ResolverInputTypes["TicketingFields_updatedAt_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["TicketingFields_updatedAt_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingFields_updatedAt_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingFields_updatedAt_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingFields_updatedAt_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingFields_createdAt"]: AliasType<{
	create?:ResolverInputTypes["TicketingFields_createdAt_Create"],
	read?:ResolverInputTypes["TicketingFields_createdAt_Read"],
	update?:ResolverInputTypes["TicketingFields_createdAt_Update"],
	delete?:ResolverInputTypes["TicketingFields_createdAt_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["TicketingFields_createdAt_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingFields_createdAt_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingFields_createdAt_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingFields_createdAt_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingCreateAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingReadAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingUpdateAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TicketingDeleteAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["productsAccess"]: AliasType<{
	fields?:ResolverInputTypes["ProductsFields"],
	create?:ResolverInputTypes["ProductsCreateAccess"],
	read?:ResolverInputTypes["ProductsReadAccess"],
	update?:ResolverInputTypes["ProductsUpdateAccess"],
	delete?:ResolverInputTypes["ProductsDeleteAccess"],
		__typename?: boolean | `@${string}`
}>;
	["ProductsFields"]: AliasType<{
	status?:ResolverInputTypes["ProductsFields_status"],
	creator?:ResolverInputTypes["ProductsFields_creator"],
	productType?:ResolverInputTypes["ProductsFields_productType"],
	productName?:ResolverInputTypes["ProductsFields_productName"],
	referenceId?:ResolverInputTypes["ProductsFields_referenceId"],
	linkedEvent?:ResolverInputTypes["ProductsFields_linkedEvent"],
	CheckInLevel?:ResolverInputTypes["ProductsFields_CheckInLevel"],
	thumbnail?:ResolverInputTypes["ProductsFields_thumbnail"],
	displayPrice?:ResolverInputTypes["ProductsFields_displayPrice"],
	transactionType?:ResolverInputTypes["ProductsFields_transactionType"],
	website?:ResolverInputTypes["ProductsFields_website"],
	tnc?:ResolverInputTypes["ProductsFields_tnc"],
	remarks?:ResolverInputTypes["ProductsFields_remarks"],
	sessions?:ResolverInputTypes["ProductsFields_sessions"],
	updatedAt?:ResolverInputTypes["ProductsFields_updatedAt"],
	createdAt?:ResolverInputTypes["ProductsFields_createdAt"],
		__typename?: boolean | `@${string}`
}>;
	["ProductsFields_status"]: AliasType<{
	create?:ResolverInputTypes["ProductsFields_status_Create"],
	read?:ResolverInputTypes["ProductsFields_status_Read"],
	update?:ResolverInputTypes["ProductsFields_status_Update"],
	delete?:ResolverInputTypes["ProductsFields_status_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["ProductsFields_status_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsFields_status_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsFields_status_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsFields_status_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsFields_creator"]: AliasType<{
	create?:ResolverInputTypes["ProductsFields_creator_Create"],
	read?:ResolverInputTypes["ProductsFields_creator_Read"],
	update?:ResolverInputTypes["ProductsFields_creator_Update"],
	delete?:ResolverInputTypes["ProductsFields_creator_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["ProductsFields_creator_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsFields_creator_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsFields_creator_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsFields_creator_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsFields_productType"]: AliasType<{
	create?:ResolverInputTypes["ProductsFields_productType_Create"],
	read?:ResolverInputTypes["ProductsFields_productType_Read"],
	update?:ResolverInputTypes["ProductsFields_productType_Update"],
	delete?:ResolverInputTypes["ProductsFields_productType_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["ProductsFields_productType_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsFields_productType_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsFields_productType_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsFields_productType_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsFields_productName"]: AliasType<{
	create?:ResolverInputTypes["ProductsFields_productName_Create"],
	read?:ResolverInputTypes["ProductsFields_productName_Read"],
	update?:ResolverInputTypes["ProductsFields_productName_Update"],
	delete?:ResolverInputTypes["ProductsFields_productName_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["ProductsFields_productName_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsFields_productName_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsFields_productName_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsFields_productName_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsFields_referenceId"]: AliasType<{
	create?:ResolverInputTypes["ProductsFields_referenceId_Create"],
	read?:ResolverInputTypes["ProductsFields_referenceId_Read"],
	update?:ResolverInputTypes["ProductsFields_referenceId_Update"],
	delete?:ResolverInputTypes["ProductsFields_referenceId_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["ProductsFields_referenceId_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsFields_referenceId_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsFields_referenceId_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsFields_referenceId_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsFields_linkedEvent"]: AliasType<{
	create?:ResolverInputTypes["ProductsFields_linkedEvent_Create"],
	read?:ResolverInputTypes["ProductsFields_linkedEvent_Read"],
	update?:ResolverInputTypes["ProductsFields_linkedEvent_Update"],
	delete?:ResolverInputTypes["ProductsFields_linkedEvent_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["ProductsFields_linkedEvent_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsFields_linkedEvent_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsFields_linkedEvent_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsFields_linkedEvent_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsFields_CheckInLevel"]: AliasType<{
	create?:ResolverInputTypes["ProductsFields_CheckInLevel_Create"],
	read?:ResolverInputTypes["ProductsFields_CheckInLevel_Read"],
	update?:ResolverInputTypes["ProductsFields_CheckInLevel_Update"],
	delete?:ResolverInputTypes["ProductsFields_CheckInLevel_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["ProductsFields_CheckInLevel_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsFields_CheckInLevel_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsFields_CheckInLevel_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsFields_CheckInLevel_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsFields_thumbnail"]: AliasType<{
	create?:ResolverInputTypes["ProductsFields_thumbnail_Create"],
	read?:ResolverInputTypes["ProductsFields_thumbnail_Read"],
	update?:ResolverInputTypes["ProductsFields_thumbnail_Update"],
	delete?:ResolverInputTypes["ProductsFields_thumbnail_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["ProductsFields_thumbnail_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsFields_thumbnail_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsFields_thumbnail_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsFields_thumbnail_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsFields_displayPrice"]: AliasType<{
	create?:ResolverInputTypes["ProductsFields_displayPrice_Create"],
	read?:ResolverInputTypes["ProductsFields_displayPrice_Read"],
	update?:ResolverInputTypes["ProductsFields_displayPrice_Update"],
	delete?:ResolverInputTypes["ProductsFields_displayPrice_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["ProductsFields_displayPrice_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsFields_displayPrice_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsFields_displayPrice_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsFields_displayPrice_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsFields_transactionType"]: AliasType<{
	create?:ResolverInputTypes["ProductsFields_transactionType_Create"],
	read?:ResolverInputTypes["ProductsFields_transactionType_Read"],
	update?:ResolverInputTypes["ProductsFields_transactionType_Update"],
	delete?:ResolverInputTypes["ProductsFields_transactionType_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["ProductsFields_transactionType_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsFields_transactionType_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsFields_transactionType_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsFields_transactionType_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsFields_website"]: AliasType<{
	create?:ResolverInputTypes["ProductsFields_website_Create"],
	read?:ResolverInputTypes["ProductsFields_website_Read"],
	update?:ResolverInputTypes["ProductsFields_website_Update"],
	delete?:ResolverInputTypes["ProductsFields_website_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["ProductsFields_website_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsFields_website_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsFields_website_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsFields_website_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsFields_tnc"]: AliasType<{
	create?:ResolverInputTypes["ProductsFields_tnc_Create"],
	read?:ResolverInputTypes["ProductsFields_tnc_Read"],
	update?:ResolverInputTypes["ProductsFields_tnc_Update"],
	delete?:ResolverInputTypes["ProductsFields_tnc_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["ProductsFields_tnc_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsFields_tnc_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsFields_tnc_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsFields_tnc_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsFields_remarks"]: AliasType<{
	create?:ResolverInputTypes["ProductsFields_remarks_Create"],
	read?:ResolverInputTypes["ProductsFields_remarks_Read"],
	update?:ResolverInputTypes["ProductsFields_remarks_Update"],
	delete?:ResolverInputTypes["ProductsFields_remarks_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["ProductsFields_remarks_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsFields_remarks_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsFields_remarks_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsFields_remarks_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsFields_sessions"]: AliasType<{
	create?:ResolverInputTypes["ProductsFields_sessions_Create"],
	read?:ResolverInputTypes["ProductsFields_sessions_Read"],
	update?:ResolverInputTypes["ProductsFields_sessions_Update"],
	delete?:ResolverInputTypes["ProductsFields_sessions_Delete"],
	fields?:ResolverInputTypes["ProductsFields_sessions_Fields"],
		__typename?: boolean | `@${string}`
}>;
	["ProductsFields_sessions_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsFields_sessions_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsFields_sessions_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsFields_sessions_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsFields_sessions_Fields"]: AliasType<{
	productId?:ResolverInputTypes["ProductsFields_sessions_productId"],
	name?:ResolverInputTypes["ProductsFields_sessions_name"],
	inventories?:ResolverInputTypes["ProductsFields_sessions_inventories"],
	id?:ResolverInputTypes["ProductsFields_sessions_id"],
		__typename?: boolean | `@${string}`
}>;
	["ProductsFields_sessions_productId"]: AliasType<{
	create?:ResolverInputTypes["ProductsFields_sessions_productId_Create"],
	read?:ResolverInputTypes["ProductsFields_sessions_productId_Read"],
	update?:ResolverInputTypes["ProductsFields_sessions_productId_Update"],
	delete?:ResolverInputTypes["ProductsFields_sessions_productId_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["ProductsFields_sessions_productId_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsFields_sessions_productId_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsFields_sessions_productId_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsFields_sessions_productId_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsFields_sessions_name"]: AliasType<{
	create?:ResolverInputTypes["ProductsFields_sessions_name_Create"],
	read?:ResolverInputTypes["ProductsFields_sessions_name_Read"],
	update?:ResolverInputTypes["ProductsFields_sessions_name_Update"],
	delete?:ResolverInputTypes["ProductsFields_sessions_name_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["ProductsFields_sessions_name_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsFields_sessions_name_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsFields_sessions_name_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsFields_sessions_name_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsFields_sessions_inventories"]: AliasType<{
	create?:ResolverInputTypes["ProductsFields_sessions_inventories_Create"],
	read?:ResolverInputTypes["ProductsFields_sessions_inventories_Read"],
	update?:ResolverInputTypes["ProductsFields_sessions_inventories_Update"],
	delete?:ResolverInputTypes["ProductsFields_sessions_inventories_Delete"],
	fields?:ResolverInputTypes["ProductsFields_sessions_inventories_Fields"],
		__typename?: boolean | `@${string}`
}>;
	["ProductsFields_sessions_inventories_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsFields_sessions_inventories_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsFields_sessions_inventories_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsFields_sessions_inventories_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsFields_sessions_inventories_Fields"]: AliasType<{
	quantity?:ResolverInputTypes["ProductsFields_sessions_inventories_quantity"],
	unitPrice?:ResolverInputTypes["ProductsFields_sessions_inventories_unitPrice"],
	handlingFee?:ResolverInputTypes["ProductsFields_sessions_inventories_handlingFee"],
	totalCost?:ResolverInputTypes["ProductsFields_sessions_inventories_totalCost"],
	minPurchase?:ResolverInputTypes["ProductsFields_sessions_inventories_minPurchase"],
	maxPurchase?:ResolverInputTypes["ProductsFields_sessions_inventories_maxPurchase"],
	stocks?:ResolverInputTypes["ProductsFields_sessions_inventories_stocks"],
		__typename?: boolean | `@${string}`
}>;
	["ProductsFields_sessions_inventories_quantity"]: AliasType<{
	create?:ResolverInputTypes["ProductsFields_sessions_inventories_quantity_Create"],
	read?:ResolverInputTypes["ProductsFields_sessions_inventories_quantity_Read"],
	update?:ResolverInputTypes["ProductsFields_sessions_inventories_quantity_Update"],
	delete?:ResolverInputTypes["ProductsFields_sessions_inventories_quantity_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["ProductsFields_sessions_inventories_quantity_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsFields_sessions_inventories_quantity_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsFields_sessions_inventories_quantity_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsFields_sessions_inventories_quantity_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsFields_sessions_inventories_unitPrice"]: AliasType<{
	create?:ResolverInputTypes["ProductsFields_sessions_inventories_unitPrice_Create"],
	read?:ResolverInputTypes["ProductsFields_sessions_inventories_unitPrice_Read"],
	update?:ResolverInputTypes["ProductsFields_sessions_inventories_unitPrice_Update"],
	delete?:ResolverInputTypes["ProductsFields_sessions_inventories_unitPrice_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["ProductsFields_sessions_inventories_unitPrice_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsFields_sessions_inventories_unitPrice_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsFields_sessions_inventories_unitPrice_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsFields_sessions_inventories_unitPrice_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsFields_sessions_inventories_handlingFee"]: AliasType<{
	create?:ResolverInputTypes["ProductsFields_sessions_inventories_handlingFee_Create"],
	read?:ResolverInputTypes["ProductsFields_sessions_inventories_handlingFee_Read"],
	update?:ResolverInputTypes["ProductsFields_sessions_inventories_handlingFee_Update"],
	delete?:ResolverInputTypes["ProductsFields_sessions_inventories_handlingFee_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["ProductsFields_sessions_inventories_handlingFee_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsFields_sessions_inventories_handlingFee_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsFields_sessions_inventories_handlingFee_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsFields_sessions_inventories_handlingFee_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsFields_sessions_inventories_totalCost"]: AliasType<{
	create?:ResolverInputTypes["ProductsFields_sessions_inventories_totalCost_Create"],
	read?:ResolverInputTypes["ProductsFields_sessions_inventories_totalCost_Read"],
	update?:ResolverInputTypes["ProductsFields_sessions_inventories_totalCost_Update"],
	delete?:ResolverInputTypes["ProductsFields_sessions_inventories_totalCost_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["ProductsFields_sessions_inventories_totalCost_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsFields_sessions_inventories_totalCost_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsFields_sessions_inventories_totalCost_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsFields_sessions_inventories_totalCost_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsFields_sessions_inventories_minPurchase"]: AliasType<{
	create?:ResolverInputTypes["ProductsFields_sessions_inventories_minPurchase_Create"],
	read?:ResolverInputTypes["ProductsFields_sessions_inventories_minPurchase_Read"],
	update?:ResolverInputTypes["ProductsFields_sessions_inventories_minPurchase_Update"],
	delete?:ResolverInputTypes["ProductsFields_sessions_inventories_minPurchase_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["ProductsFields_sessions_inventories_minPurchase_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsFields_sessions_inventories_minPurchase_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsFields_sessions_inventories_minPurchase_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsFields_sessions_inventories_minPurchase_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsFields_sessions_inventories_maxPurchase"]: AliasType<{
	create?:ResolverInputTypes["ProductsFields_sessions_inventories_maxPurchase_Create"],
	read?:ResolverInputTypes["ProductsFields_sessions_inventories_maxPurchase_Read"],
	update?:ResolverInputTypes["ProductsFields_sessions_inventories_maxPurchase_Update"],
	delete?:ResolverInputTypes["ProductsFields_sessions_inventories_maxPurchase_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["ProductsFields_sessions_inventories_maxPurchase_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsFields_sessions_inventories_maxPurchase_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsFields_sessions_inventories_maxPurchase_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsFields_sessions_inventories_maxPurchase_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsFields_sessions_inventories_stocks"]: AliasType<{
	create?:ResolverInputTypes["ProductsFields_sessions_inventories_stocks_Create"],
	read?:ResolverInputTypes["ProductsFields_sessions_inventories_stocks_Read"],
	update?:ResolverInputTypes["ProductsFields_sessions_inventories_stocks_Update"],
	delete?:ResolverInputTypes["ProductsFields_sessions_inventories_stocks_Delete"],
	fields?:ResolverInputTypes["ProductsFields_sessions_inventories_stocks_Fields"],
		__typename?: boolean | `@${string}`
}>;
	["ProductsFields_sessions_inventories_stocks_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsFields_sessions_inventories_stocks_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsFields_sessions_inventories_stocks_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsFields_sessions_inventories_stocks_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsFields_sessions_inventories_stocks_Fields"]: AliasType<{
	stockId?:ResolverInputTypes["ProductsFields_sessions_inventories_stocks_stockId"],
	remaining?:ResolverInputTypes["ProductsFields_sessions_inventories_stocks_remaining"],
	sold?:ResolverInputTypes["ProductsFields_sessions_inventories_stocks_sold"],
	onHold?:ResolverInputTypes["ProductsFields_sessions_inventories_stocks_onHold"],
	checkedIn?:ResolverInputTypes["ProductsFields_sessions_inventories_stocks_checkedIn"],
		__typename?: boolean | `@${string}`
}>;
	["ProductsFields_sessions_inventories_stocks_stockId"]: AliasType<{
	create?:ResolverInputTypes["ProductsFields_sessions_inventories_stocks_stockId_Create"],
	read?:ResolverInputTypes["ProductsFields_sessions_inventories_stocks_stockId_Read"],
	update?:ResolverInputTypes["ProductsFields_sessions_inventories_stocks_stockId_Update"],
	delete?:ResolverInputTypes["ProductsFields_sessions_inventories_stocks_stockId_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["ProductsFields_sessions_inventories_stocks_stockId_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsFields_sessions_inventories_stocks_stockId_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsFields_sessions_inventories_stocks_stockId_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsFields_sessions_inventories_stocks_stockId_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsFields_sessions_inventories_stocks_remaining"]: AliasType<{
	create?:ResolverInputTypes["ProductsFields_sessions_inventories_stocks_remaining_Create"],
	read?:ResolverInputTypes["ProductsFields_sessions_inventories_stocks_remaining_Read"],
	update?:ResolverInputTypes["ProductsFields_sessions_inventories_stocks_remaining_Update"],
	delete?:ResolverInputTypes["ProductsFields_sessions_inventories_stocks_remaining_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["ProductsFields_sessions_inventories_stocks_remaining_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsFields_sessions_inventories_stocks_remaining_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsFields_sessions_inventories_stocks_remaining_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsFields_sessions_inventories_stocks_remaining_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsFields_sessions_inventories_stocks_sold"]: AliasType<{
	create?:ResolverInputTypes["ProductsFields_sessions_inventories_stocks_sold_Create"],
	read?:ResolverInputTypes["ProductsFields_sessions_inventories_stocks_sold_Read"],
	update?:ResolverInputTypes["ProductsFields_sessions_inventories_stocks_sold_Update"],
	delete?:ResolverInputTypes["ProductsFields_sessions_inventories_stocks_sold_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["ProductsFields_sessions_inventories_stocks_sold_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsFields_sessions_inventories_stocks_sold_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsFields_sessions_inventories_stocks_sold_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsFields_sessions_inventories_stocks_sold_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsFields_sessions_inventories_stocks_onHold"]: AliasType<{
	create?:ResolverInputTypes["ProductsFields_sessions_inventories_stocks_onHold_Create"],
	read?:ResolverInputTypes["ProductsFields_sessions_inventories_stocks_onHold_Read"],
	update?:ResolverInputTypes["ProductsFields_sessions_inventories_stocks_onHold_Update"],
	delete?:ResolverInputTypes["ProductsFields_sessions_inventories_stocks_onHold_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["ProductsFields_sessions_inventories_stocks_onHold_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsFields_sessions_inventories_stocks_onHold_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsFields_sessions_inventories_stocks_onHold_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsFields_sessions_inventories_stocks_onHold_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsFields_sessions_inventories_stocks_checkedIn"]: AliasType<{
	create?:ResolverInputTypes["ProductsFields_sessions_inventories_stocks_checkedIn_Create"],
	read?:ResolverInputTypes["ProductsFields_sessions_inventories_stocks_checkedIn_Read"],
	update?:ResolverInputTypes["ProductsFields_sessions_inventories_stocks_checkedIn_Update"],
	delete?:ResolverInputTypes["ProductsFields_sessions_inventories_stocks_checkedIn_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["ProductsFields_sessions_inventories_stocks_checkedIn_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsFields_sessions_inventories_stocks_checkedIn_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsFields_sessions_inventories_stocks_checkedIn_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsFields_sessions_inventories_stocks_checkedIn_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsFields_sessions_id"]: AliasType<{
	create?:ResolverInputTypes["ProductsFields_sessions_id_Create"],
	read?:ResolverInputTypes["ProductsFields_sessions_id_Read"],
	update?:ResolverInputTypes["ProductsFields_sessions_id_Update"],
	delete?:ResolverInputTypes["ProductsFields_sessions_id_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["ProductsFields_sessions_id_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsFields_sessions_id_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsFields_sessions_id_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsFields_sessions_id_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsFields_updatedAt"]: AliasType<{
	create?:ResolverInputTypes["ProductsFields_updatedAt_Create"],
	read?:ResolverInputTypes["ProductsFields_updatedAt_Read"],
	update?:ResolverInputTypes["ProductsFields_updatedAt_Update"],
	delete?:ResolverInputTypes["ProductsFields_updatedAt_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["ProductsFields_updatedAt_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsFields_updatedAt_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsFields_updatedAt_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsFields_updatedAt_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsFields_createdAt"]: AliasType<{
	create?:ResolverInputTypes["ProductsFields_createdAt_Create"],
	read?:ResolverInputTypes["ProductsFields_createdAt_Read"],
	update?:ResolverInputTypes["ProductsFields_createdAt_Update"],
	delete?:ResolverInputTypes["ProductsFields_createdAt_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["ProductsFields_createdAt_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsFields_createdAt_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsFields_createdAt_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsFields_createdAt_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsCreateAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsReadAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsUpdateAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductsDeleteAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["inventory_statusAccess"]: AliasType<{
	fields?:ResolverInputTypes["InventoryStatusFields"],
	create?:ResolverInputTypes["InventoryStatusCreateAccess"],
	read?:ResolverInputTypes["InventoryStatusReadAccess"],
	update?:ResolverInputTypes["InventoryStatusUpdateAccess"],
	delete?:ResolverInputTypes["InventoryStatusDeleteAccess"],
		__typename?: boolean | `@${string}`
}>;
	["InventoryStatusFields"]: AliasType<{
	variantId?:ResolverInputTypes["InventoryStatusFields_variantId"],
	variantName?:ResolverInputTypes["InventoryStatusFields_variantName"],
	productType?:ResolverInputTypes["InventoryStatusFields_productType"],
	stockUpdateStatus?:ResolverInputTypes["InventoryStatusFields_stockUpdateStatus"],
	stockUpdateAmount?:ResolverInputTypes["InventoryStatusFields_stockUpdateAmount"],
	user?:ResolverInputTypes["InventoryStatusFields_user"],
	publicUserPhone?:ResolverInputTypes["InventoryStatusFields_publicUserPhone"],
	expiredAt?:ResolverInputTypes["InventoryStatusFields_expiredAt"],
	updatedAt?:ResolverInputTypes["InventoryStatusFields_updatedAt"],
	createdAt?:ResolverInputTypes["InventoryStatusFields_createdAt"],
		__typename?: boolean | `@${string}`
}>;
	["InventoryStatusFields_variantId"]: AliasType<{
	create?:ResolverInputTypes["InventoryStatusFields_variantId_Create"],
	read?:ResolverInputTypes["InventoryStatusFields_variantId_Read"],
	update?:ResolverInputTypes["InventoryStatusFields_variantId_Update"],
	delete?:ResolverInputTypes["InventoryStatusFields_variantId_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["InventoryStatusFields_variantId_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["InventoryStatusFields_variantId_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["InventoryStatusFields_variantId_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["InventoryStatusFields_variantId_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["InventoryStatusFields_variantName"]: AliasType<{
	create?:ResolverInputTypes["InventoryStatusFields_variantName_Create"],
	read?:ResolverInputTypes["InventoryStatusFields_variantName_Read"],
	update?:ResolverInputTypes["InventoryStatusFields_variantName_Update"],
	delete?:ResolverInputTypes["InventoryStatusFields_variantName_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["InventoryStatusFields_variantName_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["InventoryStatusFields_variantName_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["InventoryStatusFields_variantName_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["InventoryStatusFields_variantName_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["InventoryStatusFields_productType"]: AliasType<{
	create?:ResolverInputTypes["InventoryStatusFields_productType_Create"],
	read?:ResolverInputTypes["InventoryStatusFields_productType_Read"],
	update?:ResolverInputTypes["InventoryStatusFields_productType_Update"],
	delete?:ResolverInputTypes["InventoryStatusFields_productType_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["InventoryStatusFields_productType_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["InventoryStatusFields_productType_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["InventoryStatusFields_productType_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["InventoryStatusFields_productType_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["InventoryStatusFields_stockUpdateStatus"]: AliasType<{
	create?:ResolverInputTypes["InventoryStatusFields_stockUpdateStatus_Create"],
	read?:ResolverInputTypes["InventoryStatusFields_stockUpdateStatus_Read"],
	update?:ResolverInputTypes["InventoryStatusFields_stockUpdateStatus_Update"],
	delete?:ResolverInputTypes["InventoryStatusFields_stockUpdateStatus_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["InventoryStatusFields_stockUpdateStatus_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["InventoryStatusFields_stockUpdateStatus_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["InventoryStatusFields_stockUpdateStatus_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["InventoryStatusFields_stockUpdateStatus_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["InventoryStatusFields_stockUpdateAmount"]: AliasType<{
	create?:ResolverInputTypes["InventoryStatusFields_stockUpdateAmount_Create"],
	read?:ResolverInputTypes["InventoryStatusFields_stockUpdateAmount_Read"],
	update?:ResolverInputTypes["InventoryStatusFields_stockUpdateAmount_Update"],
	delete?:ResolverInputTypes["InventoryStatusFields_stockUpdateAmount_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["InventoryStatusFields_stockUpdateAmount_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["InventoryStatusFields_stockUpdateAmount_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["InventoryStatusFields_stockUpdateAmount_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["InventoryStatusFields_stockUpdateAmount_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["InventoryStatusFields_user"]: AliasType<{
	create?:ResolverInputTypes["InventoryStatusFields_user_Create"],
	read?:ResolverInputTypes["InventoryStatusFields_user_Read"],
	update?:ResolverInputTypes["InventoryStatusFields_user_Update"],
	delete?:ResolverInputTypes["InventoryStatusFields_user_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["InventoryStatusFields_user_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["InventoryStatusFields_user_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["InventoryStatusFields_user_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["InventoryStatusFields_user_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["InventoryStatusFields_publicUserPhone"]: AliasType<{
	create?:ResolverInputTypes["InventoryStatusFields_publicUserPhone_Create"],
	read?:ResolverInputTypes["InventoryStatusFields_publicUserPhone_Read"],
	update?:ResolverInputTypes["InventoryStatusFields_publicUserPhone_Update"],
	delete?:ResolverInputTypes["InventoryStatusFields_publicUserPhone_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["InventoryStatusFields_publicUserPhone_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["InventoryStatusFields_publicUserPhone_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["InventoryStatusFields_publicUserPhone_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["InventoryStatusFields_publicUserPhone_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["InventoryStatusFields_expiredAt"]: AliasType<{
	create?:ResolverInputTypes["InventoryStatusFields_expiredAt_Create"],
	read?:ResolverInputTypes["InventoryStatusFields_expiredAt_Read"],
	update?:ResolverInputTypes["InventoryStatusFields_expiredAt_Update"],
	delete?:ResolverInputTypes["InventoryStatusFields_expiredAt_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["InventoryStatusFields_expiredAt_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["InventoryStatusFields_expiredAt_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["InventoryStatusFields_expiredAt_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["InventoryStatusFields_expiredAt_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["InventoryStatusFields_updatedAt"]: AliasType<{
	create?:ResolverInputTypes["InventoryStatusFields_updatedAt_Create"],
	read?:ResolverInputTypes["InventoryStatusFields_updatedAt_Read"],
	update?:ResolverInputTypes["InventoryStatusFields_updatedAt_Update"],
	delete?:ResolverInputTypes["InventoryStatusFields_updatedAt_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["InventoryStatusFields_updatedAt_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["InventoryStatusFields_updatedAt_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["InventoryStatusFields_updatedAt_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["InventoryStatusFields_updatedAt_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["InventoryStatusFields_createdAt"]: AliasType<{
	create?:ResolverInputTypes["InventoryStatusFields_createdAt_Create"],
	read?:ResolverInputTypes["InventoryStatusFields_createdAt_Read"],
	update?:ResolverInputTypes["InventoryStatusFields_createdAt_Update"],
	delete?:ResolverInputTypes["InventoryStatusFields_createdAt_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["InventoryStatusFields_createdAt_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["InventoryStatusFields_createdAt_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["InventoryStatusFields_createdAt_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["InventoryStatusFields_createdAt_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["InventoryStatusCreateAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["InventoryStatusReadAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["InventoryStatusUpdateAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["InventoryStatusDeleteAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["transactionsAccess"]: AliasType<{
	fields?:ResolverInputTypes["TransactionsFields"],
	create?:ResolverInputTypes["TransactionsCreateAccess"],
	read?:ResolverInputTypes["TransactionsReadAccess"],
	update?:ResolverInputTypes["TransactionsUpdateAccess"],
	delete?:ResolverInputTypes["TransactionsDeleteAccess"],
		__typename?: boolean | `@${string}`
}>;
	["TransactionsFields"]: AliasType<{
	creator?:ResolverInputTypes["TransactionsFields_creator"],
	productName?:ResolverInputTypes["TransactionsFields_productName"],
	referenceId?:ResolverInputTypes["TransactionsFields_referenceId"],
	linkedEvent?:ResolverInputTypes["TransactionsFields_linkedEvent"],
	purchaseDateTime?:ResolverInputTypes["TransactionsFields_purchaseDateTime"],
	timableOrderId?:ResolverInputTypes["TransactionsFields_timableOrderId"],
	stripePaymentId?:ResolverInputTypes["TransactionsFields_stripePaymentId"],
	totalAmount?:ResolverInputTypes["TransactionsFields_totalAmount"],
	contactPersonName?:ResolverInputTypes["TransactionsFields_contactPersonName"],
	contactPersonPhone?:ResolverInputTypes["TransactionsFields_contactPersonPhone"],
	remarks?:ResolverInputTypes["TransactionsFields_remarks"],
	redemptionCodes?:ResolverInputTypes["TransactionsFields_redemptionCodes"],
	updatedAt?:ResolverInputTypes["TransactionsFields_updatedAt"],
	createdAt?:ResolverInputTypes["TransactionsFields_createdAt"],
		__typename?: boolean | `@${string}`
}>;
	["TransactionsFields_creator"]: AliasType<{
	create?:ResolverInputTypes["TransactionsFields_creator_Create"],
	read?:ResolverInputTypes["TransactionsFields_creator_Read"],
	update?:ResolverInputTypes["TransactionsFields_creator_Update"],
	delete?:ResolverInputTypes["TransactionsFields_creator_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["TransactionsFields_creator_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TransactionsFields_creator_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TransactionsFields_creator_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TransactionsFields_creator_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TransactionsFields_productName"]: AliasType<{
	create?:ResolverInputTypes["TransactionsFields_productName_Create"],
	read?:ResolverInputTypes["TransactionsFields_productName_Read"],
	update?:ResolverInputTypes["TransactionsFields_productName_Update"],
	delete?:ResolverInputTypes["TransactionsFields_productName_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["TransactionsFields_productName_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TransactionsFields_productName_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TransactionsFields_productName_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TransactionsFields_productName_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TransactionsFields_referenceId"]: AliasType<{
	create?:ResolverInputTypes["TransactionsFields_referenceId_Create"],
	read?:ResolverInputTypes["TransactionsFields_referenceId_Read"],
	update?:ResolverInputTypes["TransactionsFields_referenceId_Update"],
	delete?:ResolverInputTypes["TransactionsFields_referenceId_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["TransactionsFields_referenceId_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TransactionsFields_referenceId_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TransactionsFields_referenceId_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TransactionsFields_referenceId_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TransactionsFields_linkedEvent"]: AliasType<{
	create?:ResolverInputTypes["TransactionsFields_linkedEvent_Create"],
	read?:ResolverInputTypes["TransactionsFields_linkedEvent_Read"],
	update?:ResolverInputTypes["TransactionsFields_linkedEvent_Update"],
	delete?:ResolverInputTypes["TransactionsFields_linkedEvent_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["TransactionsFields_linkedEvent_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TransactionsFields_linkedEvent_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TransactionsFields_linkedEvent_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TransactionsFields_linkedEvent_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TransactionsFields_purchaseDateTime"]: AliasType<{
	create?:ResolverInputTypes["TransactionsFields_purchaseDateTime_Create"],
	read?:ResolverInputTypes["TransactionsFields_purchaseDateTime_Read"],
	update?:ResolverInputTypes["TransactionsFields_purchaseDateTime_Update"],
	delete?:ResolverInputTypes["TransactionsFields_purchaseDateTime_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["TransactionsFields_purchaseDateTime_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TransactionsFields_purchaseDateTime_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TransactionsFields_purchaseDateTime_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TransactionsFields_purchaseDateTime_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TransactionsFields_timableOrderId"]: AliasType<{
	create?:ResolverInputTypes["TransactionsFields_timableOrderId_Create"],
	read?:ResolverInputTypes["TransactionsFields_timableOrderId_Read"],
	update?:ResolverInputTypes["TransactionsFields_timableOrderId_Update"],
	delete?:ResolverInputTypes["TransactionsFields_timableOrderId_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["TransactionsFields_timableOrderId_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TransactionsFields_timableOrderId_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TransactionsFields_timableOrderId_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TransactionsFields_timableOrderId_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TransactionsFields_stripePaymentId"]: AliasType<{
	create?:ResolverInputTypes["TransactionsFields_stripePaymentId_Create"],
	read?:ResolverInputTypes["TransactionsFields_stripePaymentId_Read"],
	update?:ResolverInputTypes["TransactionsFields_stripePaymentId_Update"],
	delete?:ResolverInputTypes["TransactionsFields_stripePaymentId_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["TransactionsFields_stripePaymentId_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TransactionsFields_stripePaymentId_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TransactionsFields_stripePaymentId_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TransactionsFields_stripePaymentId_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TransactionsFields_totalAmount"]: AliasType<{
	create?:ResolverInputTypes["TransactionsFields_totalAmount_Create"],
	read?:ResolverInputTypes["TransactionsFields_totalAmount_Read"],
	update?:ResolverInputTypes["TransactionsFields_totalAmount_Update"],
	delete?:ResolverInputTypes["TransactionsFields_totalAmount_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["TransactionsFields_totalAmount_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TransactionsFields_totalAmount_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TransactionsFields_totalAmount_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TransactionsFields_totalAmount_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TransactionsFields_contactPersonName"]: AliasType<{
	create?:ResolverInputTypes["TransactionsFields_contactPersonName_Create"],
	read?:ResolverInputTypes["TransactionsFields_contactPersonName_Read"],
	update?:ResolverInputTypes["TransactionsFields_contactPersonName_Update"],
	delete?:ResolverInputTypes["TransactionsFields_contactPersonName_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["TransactionsFields_contactPersonName_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TransactionsFields_contactPersonName_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TransactionsFields_contactPersonName_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TransactionsFields_contactPersonName_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TransactionsFields_contactPersonPhone"]: AliasType<{
	create?:ResolverInputTypes["TransactionsFields_contactPersonPhone_Create"],
	read?:ResolverInputTypes["TransactionsFields_contactPersonPhone_Read"],
	update?:ResolverInputTypes["TransactionsFields_contactPersonPhone_Update"],
	delete?:ResolverInputTypes["TransactionsFields_contactPersonPhone_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["TransactionsFields_contactPersonPhone_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TransactionsFields_contactPersonPhone_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TransactionsFields_contactPersonPhone_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TransactionsFields_contactPersonPhone_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TransactionsFields_remarks"]: AliasType<{
	create?:ResolverInputTypes["TransactionsFields_remarks_Create"],
	read?:ResolverInputTypes["TransactionsFields_remarks_Read"],
	update?:ResolverInputTypes["TransactionsFields_remarks_Update"],
	delete?:ResolverInputTypes["TransactionsFields_remarks_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["TransactionsFields_remarks_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TransactionsFields_remarks_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TransactionsFields_remarks_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TransactionsFields_remarks_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TransactionsFields_redemptionCodes"]: AliasType<{
	create?:ResolverInputTypes["TransactionsFields_redemptionCodes_Create"],
	read?:ResolverInputTypes["TransactionsFields_redemptionCodes_Read"],
	update?:ResolverInputTypes["TransactionsFields_redemptionCodes_Update"],
	delete?:ResolverInputTypes["TransactionsFields_redemptionCodes_Delete"],
	fields?:ResolverInputTypes["TransactionsFields_redemptionCodes_Fields"],
		__typename?: boolean | `@${string}`
}>;
	["TransactionsFields_redemptionCodes_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TransactionsFields_redemptionCodes_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TransactionsFields_redemptionCodes_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TransactionsFields_redemptionCodes_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TransactionsFields_redemptionCodes_Fields"]: AliasType<{
	code?:ResolverInputTypes["TransactionsFields_redemptionCodes_code"],
	id?:ResolverInputTypes["TransactionsFields_redemptionCodes_id"],
		__typename?: boolean | `@${string}`
}>;
	["TransactionsFields_redemptionCodes_code"]: AliasType<{
	create?:ResolverInputTypes["TransactionsFields_redemptionCodes_code_Create"],
	read?:ResolverInputTypes["TransactionsFields_redemptionCodes_code_Read"],
	update?:ResolverInputTypes["TransactionsFields_redemptionCodes_code_Update"],
	delete?:ResolverInputTypes["TransactionsFields_redemptionCodes_code_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["TransactionsFields_redemptionCodes_code_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TransactionsFields_redemptionCodes_code_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TransactionsFields_redemptionCodes_code_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TransactionsFields_redemptionCodes_code_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TransactionsFields_redemptionCodes_id"]: AliasType<{
	create?:ResolverInputTypes["TransactionsFields_redemptionCodes_id_Create"],
	read?:ResolverInputTypes["TransactionsFields_redemptionCodes_id_Read"],
	update?:ResolverInputTypes["TransactionsFields_redemptionCodes_id_Update"],
	delete?:ResolverInputTypes["TransactionsFields_redemptionCodes_id_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["TransactionsFields_redemptionCodes_id_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TransactionsFields_redemptionCodes_id_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TransactionsFields_redemptionCodes_id_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TransactionsFields_redemptionCodes_id_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TransactionsFields_updatedAt"]: AliasType<{
	create?:ResolverInputTypes["TransactionsFields_updatedAt_Create"],
	read?:ResolverInputTypes["TransactionsFields_updatedAt_Read"],
	update?:ResolverInputTypes["TransactionsFields_updatedAt_Update"],
	delete?:ResolverInputTypes["TransactionsFields_updatedAt_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["TransactionsFields_updatedAt_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TransactionsFields_updatedAt_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TransactionsFields_updatedAt_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TransactionsFields_updatedAt_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TransactionsFields_createdAt"]: AliasType<{
	create?:ResolverInputTypes["TransactionsFields_createdAt_Create"],
	read?:ResolverInputTypes["TransactionsFields_createdAt_Read"],
	update?:ResolverInputTypes["TransactionsFields_createdAt_Update"],
	delete?:ResolverInputTypes["TransactionsFields_createdAt_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["TransactionsFields_createdAt_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TransactionsFields_createdAt_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TransactionsFields_createdAt_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TransactionsFields_createdAt_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TransactionsCreateAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TransactionsReadAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TransactionsUpdateAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TransactionsDeleteAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["redemption_codesAccess"]: AliasType<{
	fields?:ResolverInputTypes["RedemptionCodesFields"],
	create?:ResolverInputTypes["RedemptionCodesCreateAccess"],
	read?:ResolverInputTypes["RedemptionCodesReadAccess"],
	update?:ResolverInputTypes["RedemptionCodesUpdateAccess"],
	delete?:ResolverInputTypes["RedemptionCodesDeleteAccess"],
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesFields"]: AliasType<{
	creator?:ResolverInputTypes["RedemptionCodesFields_creator"],
	status?:ResolverInputTypes["RedemptionCodesFields_status"],
	qrCodeId?:ResolverInputTypes["RedemptionCodesFields_qrCodeId"],
	lastCheckedInDateTime?:ResolverInputTypes["RedemptionCodesFields_lastCheckedInDateTime"],
	voidedDateTime?:ResolverInputTypes["RedemptionCodesFields_voidedDateTime"],
	refundedDateTime?:ResolverInputTypes["RedemptionCodesFields_refundedDateTime"],
	eventStartTime?:ResolverInputTypes["RedemptionCodesFields_eventStartTime"],
	eventEndTime?:ResolverInputTypes["RedemptionCodesFields_eventEndTime"],
	eventPriceZone?:ResolverInputTypes["RedemptionCodesFields_eventPriceZone"],
	ownerName?:ResolverInputTypes["RedemptionCodesFields_ownerName"],
	phone?:ResolverInputTypes["RedemptionCodesFields_phone"],
	pdf?:ResolverInputTypes["RedemptionCodesFields_pdf"],
	variants?:ResolverInputTypes["RedemptionCodesFields_variants"],
	productId?:ResolverInputTypes["RedemptionCodesFields_productId"],
	referenceId?:ResolverInputTypes["RedemptionCodesFields_referenceId"],
	productType?:ResolverInputTypes["RedemptionCodesFields_productType"],
	productName?:ResolverInputTypes["RedemptionCodesFields_productName"],
	linkedEvent?:ResolverInputTypes["RedemptionCodesFields_linkedEvent"],
	timableOrderId?:ResolverInputTypes["RedemptionCodesFields_timableOrderId"],
	purchaseDateTime?:ResolverInputTypes["RedemptionCodesFields_purchaseDateTime"],
	changeHistory?:ResolverInputTypes["RedemptionCodesFields_changeHistory"],
	updatedAt?:ResolverInputTypes["RedemptionCodesFields_updatedAt"],
	createdAt?:ResolverInputTypes["RedemptionCodesFields_createdAt"],
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesFields_creator"]: AliasType<{
	create?:ResolverInputTypes["RedemptionCodesFields_creator_Create"],
	read?:ResolverInputTypes["RedemptionCodesFields_creator_Read"],
	update?:ResolverInputTypes["RedemptionCodesFields_creator_Update"],
	delete?:ResolverInputTypes["RedemptionCodesFields_creator_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesFields_creator_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesFields_creator_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesFields_creator_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesFields_creator_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesFields_status"]: AliasType<{
	create?:ResolverInputTypes["RedemptionCodesFields_status_Create"],
	read?:ResolverInputTypes["RedemptionCodesFields_status_Read"],
	update?:ResolverInputTypes["RedemptionCodesFields_status_Update"],
	delete?:ResolverInputTypes["RedemptionCodesFields_status_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesFields_status_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesFields_status_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesFields_status_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesFields_status_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesFields_qrCodeId"]: AliasType<{
	create?:ResolverInputTypes["RedemptionCodesFields_qrCodeId_Create"],
	read?:ResolverInputTypes["RedemptionCodesFields_qrCodeId_Read"],
	update?:ResolverInputTypes["RedemptionCodesFields_qrCodeId_Update"],
	delete?:ResolverInputTypes["RedemptionCodesFields_qrCodeId_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesFields_qrCodeId_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesFields_qrCodeId_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesFields_qrCodeId_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesFields_qrCodeId_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesFields_lastCheckedInDateTime"]: AliasType<{
	create?:ResolverInputTypes["RedemptionCodesFields_lastCheckedInDateTime_Create"],
	read?:ResolverInputTypes["RedemptionCodesFields_lastCheckedInDateTime_Read"],
	update?:ResolverInputTypes["RedemptionCodesFields_lastCheckedInDateTime_Update"],
	delete?:ResolverInputTypes["RedemptionCodesFields_lastCheckedInDateTime_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesFields_lastCheckedInDateTime_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesFields_lastCheckedInDateTime_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesFields_lastCheckedInDateTime_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesFields_lastCheckedInDateTime_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesFields_voidedDateTime"]: AliasType<{
	create?:ResolverInputTypes["RedemptionCodesFields_voidedDateTime_Create"],
	read?:ResolverInputTypes["RedemptionCodesFields_voidedDateTime_Read"],
	update?:ResolverInputTypes["RedemptionCodesFields_voidedDateTime_Update"],
	delete?:ResolverInputTypes["RedemptionCodesFields_voidedDateTime_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesFields_voidedDateTime_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesFields_voidedDateTime_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesFields_voidedDateTime_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesFields_voidedDateTime_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesFields_refundedDateTime"]: AliasType<{
	create?:ResolverInputTypes["RedemptionCodesFields_refundedDateTime_Create"],
	read?:ResolverInputTypes["RedemptionCodesFields_refundedDateTime_Read"],
	update?:ResolverInputTypes["RedemptionCodesFields_refundedDateTime_Update"],
	delete?:ResolverInputTypes["RedemptionCodesFields_refundedDateTime_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesFields_refundedDateTime_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesFields_refundedDateTime_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesFields_refundedDateTime_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesFields_refundedDateTime_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesFields_eventStartTime"]: AliasType<{
	create?:ResolverInputTypes["RedemptionCodesFields_eventStartTime_Create"],
	read?:ResolverInputTypes["RedemptionCodesFields_eventStartTime_Read"],
	update?:ResolverInputTypes["RedemptionCodesFields_eventStartTime_Update"],
	delete?:ResolverInputTypes["RedemptionCodesFields_eventStartTime_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesFields_eventStartTime_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesFields_eventStartTime_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesFields_eventStartTime_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesFields_eventStartTime_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesFields_eventEndTime"]: AliasType<{
	create?:ResolverInputTypes["RedemptionCodesFields_eventEndTime_Create"],
	read?:ResolverInputTypes["RedemptionCodesFields_eventEndTime_Read"],
	update?:ResolverInputTypes["RedemptionCodesFields_eventEndTime_Update"],
	delete?:ResolverInputTypes["RedemptionCodesFields_eventEndTime_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesFields_eventEndTime_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesFields_eventEndTime_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesFields_eventEndTime_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesFields_eventEndTime_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesFields_eventPriceZone"]: AliasType<{
	create?:ResolverInputTypes["RedemptionCodesFields_eventPriceZone_Create"],
	read?:ResolverInputTypes["RedemptionCodesFields_eventPriceZone_Read"],
	update?:ResolverInputTypes["RedemptionCodesFields_eventPriceZone_Update"],
	delete?:ResolverInputTypes["RedemptionCodesFields_eventPriceZone_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesFields_eventPriceZone_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesFields_eventPriceZone_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesFields_eventPriceZone_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesFields_eventPriceZone_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesFields_ownerName"]: AliasType<{
	create?:ResolverInputTypes["RedemptionCodesFields_ownerName_Create"],
	read?:ResolverInputTypes["RedemptionCodesFields_ownerName_Read"],
	update?:ResolverInputTypes["RedemptionCodesFields_ownerName_Update"],
	delete?:ResolverInputTypes["RedemptionCodesFields_ownerName_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesFields_ownerName_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesFields_ownerName_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesFields_ownerName_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesFields_ownerName_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesFields_phone"]: AliasType<{
	create?:ResolverInputTypes["RedemptionCodesFields_phone_Create"],
	read?:ResolverInputTypes["RedemptionCodesFields_phone_Read"],
	update?:ResolverInputTypes["RedemptionCodesFields_phone_Update"],
	delete?:ResolverInputTypes["RedemptionCodesFields_phone_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesFields_phone_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesFields_phone_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesFields_phone_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesFields_phone_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesFields_pdf"]: AliasType<{
	create?:ResolverInputTypes["RedemptionCodesFields_pdf_Create"],
	read?:ResolverInputTypes["RedemptionCodesFields_pdf_Read"],
	update?:ResolverInputTypes["RedemptionCodesFields_pdf_Update"],
	delete?:ResolverInputTypes["RedemptionCodesFields_pdf_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesFields_pdf_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesFields_pdf_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesFields_pdf_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesFields_pdf_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesFields_variants"]: AliasType<{
	create?:ResolverInputTypes["RedemptionCodesFields_variants_Create"],
	read?:ResolverInputTypes["RedemptionCodesFields_variants_Read"],
	update?:ResolverInputTypes["RedemptionCodesFields_variants_Update"],
	delete?:ResolverInputTypes["RedemptionCodesFields_variants_Delete"],
	fields?:ResolverInputTypes["RedemptionCodesFields_variants_Fields"],
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesFields_variants_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesFields_variants_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesFields_variants_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesFields_variants_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesFields_variants_Fields"]: AliasType<{
	variantId?:ResolverInputTypes["RedemptionCodesFields_variants_variantId"],
	name?:ResolverInputTypes["RedemptionCodesFields_variants_name"],
	quantity?:ResolverInputTypes["RedemptionCodesFields_variants_quantity"],
	totalCost?:ResolverInputTypes["RedemptionCodesFields_variants_totalCost"],
	id?:ResolverInputTypes["RedemptionCodesFields_variants_id"],
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesFields_variants_variantId"]: AliasType<{
	create?:ResolverInputTypes["RedemptionCodesFields_variants_variantId_Create"],
	read?:ResolverInputTypes["RedemptionCodesFields_variants_variantId_Read"],
	update?:ResolverInputTypes["RedemptionCodesFields_variants_variantId_Update"],
	delete?:ResolverInputTypes["RedemptionCodesFields_variants_variantId_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesFields_variants_variantId_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesFields_variants_variantId_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesFields_variants_variantId_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesFields_variants_variantId_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesFields_variants_name"]: AliasType<{
	create?:ResolverInputTypes["RedemptionCodesFields_variants_name_Create"],
	read?:ResolverInputTypes["RedemptionCodesFields_variants_name_Read"],
	update?:ResolverInputTypes["RedemptionCodesFields_variants_name_Update"],
	delete?:ResolverInputTypes["RedemptionCodesFields_variants_name_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesFields_variants_name_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesFields_variants_name_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesFields_variants_name_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesFields_variants_name_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesFields_variants_quantity"]: AliasType<{
	create?:ResolverInputTypes["RedemptionCodesFields_variants_quantity_Create"],
	read?:ResolverInputTypes["RedemptionCodesFields_variants_quantity_Read"],
	update?:ResolverInputTypes["RedemptionCodesFields_variants_quantity_Update"],
	delete?:ResolverInputTypes["RedemptionCodesFields_variants_quantity_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesFields_variants_quantity_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesFields_variants_quantity_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesFields_variants_quantity_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesFields_variants_quantity_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesFields_variants_totalCost"]: AliasType<{
	create?:ResolverInputTypes["RedemptionCodesFields_variants_totalCost_Create"],
	read?:ResolverInputTypes["RedemptionCodesFields_variants_totalCost_Read"],
	update?:ResolverInputTypes["RedemptionCodesFields_variants_totalCost_Update"],
	delete?:ResolverInputTypes["RedemptionCodesFields_variants_totalCost_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesFields_variants_totalCost_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesFields_variants_totalCost_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesFields_variants_totalCost_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesFields_variants_totalCost_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesFields_variants_id"]: AliasType<{
	create?:ResolverInputTypes["RedemptionCodesFields_variants_id_Create"],
	read?:ResolverInputTypes["RedemptionCodesFields_variants_id_Read"],
	update?:ResolverInputTypes["RedemptionCodesFields_variants_id_Update"],
	delete?:ResolverInputTypes["RedemptionCodesFields_variants_id_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesFields_variants_id_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesFields_variants_id_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesFields_variants_id_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesFields_variants_id_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesFields_productId"]: AliasType<{
	create?:ResolverInputTypes["RedemptionCodesFields_productId_Create"],
	read?:ResolverInputTypes["RedemptionCodesFields_productId_Read"],
	update?:ResolverInputTypes["RedemptionCodesFields_productId_Update"],
	delete?:ResolverInputTypes["RedemptionCodesFields_productId_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesFields_productId_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesFields_productId_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesFields_productId_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesFields_productId_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesFields_referenceId"]: AliasType<{
	create?:ResolverInputTypes["RedemptionCodesFields_referenceId_Create"],
	read?:ResolverInputTypes["RedemptionCodesFields_referenceId_Read"],
	update?:ResolverInputTypes["RedemptionCodesFields_referenceId_Update"],
	delete?:ResolverInputTypes["RedemptionCodesFields_referenceId_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesFields_referenceId_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesFields_referenceId_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesFields_referenceId_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesFields_referenceId_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesFields_productType"]: AliasType<{
	create?:ResolverInputTypes["RedemptionCodesFields_productType_Create"],
	read?:ResolverInputTypes["RedemptionCodesFields_productType_Read"],
	update?:ResolverInputTypes["RedemptionCodesFields_productType_Update"],
	delete?:ResolverInputTypes["RedemptionCodesFields_productType_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesFields_productType_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesFields_productType_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesFields_productType_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesFields_productType_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesFields_productName"]: AliasType<{
	create?:ResolverInputTypes["RedemptionCodesFields_productName_Create"],
	read?:ResolverInputTypes["RedemptionCodesFields_productName_Read"],
	update?:ResolverInputTypes["RedemptionCodesFields_productName_Update"],
	delete?:ResolverInputTypes["RedemptionCodesFields_productName_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesFields_productName_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesFields_productName_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesFields_productName_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesFields_productName_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesFields_linkedEvent"]: AliasType<{
	create?:ResolverInputTypes["RedemptionCodesFields_linkedEvent_Create"],
	read?:ResolverInputTypes["RedemptionCodesFields_linkedEvent_Read"],
	update?:ResolverInputTypes["RedemptionCodesFields_linkedEvent_Update"],
	delete?:ResolverInputTypes["RedemptionCodesFields_linkedEvent_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesFields_linkedEvent_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesFields_linkedEvent_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesFields_linkedEvent_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesFields_linkedEvent_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesFields_timableOrderId"]: AliasType<{
	create?:ResolverInputTypes["RedemptionCodesFields_timableOrderId_Create"],
	read?:ResolverInputTypes["RedemptionCodesFields_timableOrderId_Read"],
	update?:ResolverInputTypes["RedemptionCodesFields_timableOrderId_Update"],
	delete?:ResolverInputTypes["RedemptionCodesFields_timableOrderId_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesFields_timableOrderId_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesFields_timableOrderId_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesFields_timableOrderId_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesFields_timableOrderId_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesFields_purchaseDateTime"]: AliasType<{
	create?:ResolverInputTypes["RedemptionCodesFields_purchaseDateTime_Create"],
	read?:ResolverInputTypes["RedemptionCodesFields_purchaseDateTime_Read"],
	update?:ResolverInputTypes["RedemptionCodesFields_purchaseDateTime_Update"],
	delete?:ResolverInputTypes["RedemptionCodesFields_purchaseDateTime_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesFields_purchaseDateTime_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesFields_purchaseDateTime_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesFields_purchaseDateTime_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesFields_purchaseDateTime_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesFields_changeHistory"]: AliasType<{
	create?:ResolverInputTypes["RedemptionCodesFields_changeHistory_Create"],
	read?:ResolverInputTypes["RedemptionCodesFields_changeHistory_Read"],
	update?:ResolverInputTypes["RedemptionCodesFields_changeHistory_Update"],
	delete?:ResolverInputTypes["RedemptionCodesFields_changeHistory_Delete"],
	fields?:ResolverInputTypes["RedemptionCodesFields_changeHistory_Fields"],
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesFields_changeHistory_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesFields_changeHistory_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesFields_changeHistory_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesFields_changeHistory_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesFields_changeHistory_Fields"]: AliasType<{
	timestamp?:ResolverInputTypes["RedemptionCodesFields_changeHistory_timestamp"],
	field?:ResolverInputTypes["RedemptionCodesFields_changeHistory_field"],
	oldValue?:ResolverInputTypes["RedemptionCodesFields_changeHistory_oldValue"],
	newValue?:ResolverInputTypes["RedemptionCodesFields_changeHistory_newValue"],
	id?:ResolverInputTypes["RedemptionCodesFields_changeHistory_id"],
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesFields_changeHistory_timestamp"]: AliasType<{
	create?:ResolverInputTypes["RedemptionCodesFields_changeHistory_timestamp_Create"],
	read?:ResolverInputTypes["RedemptionCodesFields_changeHistory_timestamp_Read"],
	update?:ResolverInputTypes["RedemptionCodesFields_changeHistory_timestamp_Update"],
	delete?:ResolverInputTypes["RedemptionCodesFields_changeHistory_timestamp_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesFields_changeHistory_timestamp_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesFields_changeHistory_timestamp_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesFields_changeHistory_timestamp_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesFields_changeHistory_timestamp_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesFields_changeHistory_field"]: AliasType<{
	create?:ResolverInputTypes["RedemptionCodesFields_changeHistory_field_Create"],
	read?:ResolverInputTypes["RedemptionCodesFields_changeHistory_field_Read"],
	update?:ResolverInputTypes["RedemptionCodesFields_changeHistory_field_Update"],
	delete?:ResolverInputTypes["RedemptionCodesFields_changeHistory_field_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesFields_changeHistory_field_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesFields_changeHistory_field_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesFields_changeHistory_field_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesFields_changeHistory_field_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesFields_changeHistory_oldValue"]: AliasType<{
	create?:ResolverInputTypes["RedemptionCodesFields_changeHistory_oldValue_Create"],
	read?:ResolverInputTypes["RedemptionCodesFields_changeHistory_oldValue_Read"],
	update?:ResolverInputTypes["RedemptionCodesFields_changeHistory_oldValue_Update"],
	delete?:ResolverInputTypes["RedemptionCodesFields_changeHistory_oldValue_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesFields_changeHistory_oldValue_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesFields_changeHistory_oldValue_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesFields_changeHistory_oldValue_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesFields_changeHistory_oldValue_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesFields_changeHistory_newValue"]: AliasType<{
	create?:ResolverInputTypes["RedemptionCodesFields_changeHistory_newValue_Create"],
	read?:ResolverInputTypes["RedemptionCodesFields_changeHistory_newValue_Read"],
	update?:ResolverInputTypes["RedemptionCodesFields_changeHistory_newValue_Update"],
	delete?:ResolverInputTypes["RedemptionCodesFields_changeHistory_newValue_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesFields_changeHistory_newValue_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesFields_changeHistory_newValue_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesFields_changeHistory_newValue_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesFields_changeHistory_newValue_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesFields_changeHistory_id"]: AliasType<{
	create?:ResolverInputTypes["RedemptionCodesFields_changeHistory_id_Create"],
	read?:ResolverInputTypes["RedemptionCodesFields_changeHistory_id_Read"],
	update?:ResolverInputTypes["RedemptionCodesFields_changeHistory_id_Update"],
	delete?:ResolverInputTypes["RedemptionCodesFields_changeHistory_id_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesFields_changeHistory_id_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesFields_changeHistory_id_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesFields_changeHistory_id_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesFields_changeHistory_id_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesFields_updatedAt"]: AliasType<{
	create?:ResolverInputTypes["RedemptionCodesFields_updatedAt_Create"],
	read?:ResolverInputTypes["RedemptionCodesFields_updatedAt_Read"],
	update?:ResolverInputTypes["RedemptionCodesFields_updatedAt_Update"],
	delete?:ResolverInputTypes["RedemptionCodesFields_updatedAt_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesFields_updatedAt_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesFields_updatedAt_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesFields_updatedAt_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesFields_updatedAt_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesFields_createdAt"]: AliasType<{
	create?:ResolverInputTypes["RedemptionCodesFields_createdAt_Create"],
	read?:ResolverInputTypes["RedemptionCodesFields_createdAt_Read"],
	update?:ResolverInputTypes["RedemptionCodesFields_createdAt_Update"],
	delete?:ResolverInputTypes["RedemptionCodesFields_createdAt_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesFields_createdAt_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesFields_createdAt_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesFields_createdAt_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesFields_createdAt_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesCreateAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesReadAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesUpdateAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodesDeleteAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["redemption_pdfAccess"]: AliasType<{
	fields?:ResolverInputTypes["RedemptionPdfFields"],
	create?:ResolverInputTypes["RedemptionPdfCreateAccess"],
	read?:ResolverInputTypes["RedemptionPdfReadAccess"],
	update?:ResolverInputTypes["RedemptionPdfUpdateAccess"],
	delete?:ResolverInputTypes["RedemptionPdfDeleteAccess"],
		__typename?: boolean | `@${string}`
}>;
	["RedemptionPdfFields"]: AliasType<{
	updatedAt?:ResolverInputTypes["RedemptionPdfFields_updatedAt"],
	createdAt?:ResolverInputTypes["RedemptionPdfFields_createdAt"],
	url?:ResolverInputTypes["RedemptionPdfFields_url"],
	filename?:ResolverInputTypes["RedemptionPdfFields_filename"],
	mimeType?:ResolverInputTypes["RedemptionPdfFields_mimeType"],
	filesize?:ResolverInputTypes["RedemptionPdfFields_filesize"],
	width?:ResolverInputTypes["RedemptionPdfFields_width"],
	height?:ResolverInputTypes["RedemptionPdfFields_height"],
	focalX?:ResolverInputTypes["RedemptionPdfFields_focalX"],
	focalY?:ResolverInputTypes["RedemptionPdfFields_focalY"],
		__typename?: boolean | `@${string}`
}>;
	["RedemptionPdfFields_updatedAt"]: AliasType<{
	create?:ResolverInputTypes["RedemptionPdfFields_updatedAt_Create"],
	read?:ResolverInputTypes["RedemptionPdfFields_updatedAt_Read"],
	update?:ResolverInputTypes["RedemptionPdfFields_updatedAt_Update"],
	delete?:ResolverInputTypes["RedemptionPdfFields_updatedAt_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["RedemptionPdfFields_updatedAt_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionPdfFields_updatedAt_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionPdfFields_updatedAt_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionPdfFields_updatedAt_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionPdfFields_createdAt"]: AliasType<{
	create?:ResolverInputTypes["RedemptionPdfFields_createdAt_Create"],
	read?:ResolverInputTypes["RedemptionPdfFields_createdAt_Read"],
	update?:ResolverInputTypes["RedemptionPdfFields_createdAt_Update"],
	delete?:ResolverInputTypes["RedemptionPdfFields_createdAt_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["RedemptionPdfFields_createdAt_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionPdfFields_createdAt_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionPdfFields_createdAt_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionPdfFields_createdAt_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionPdfFields_url"]: AliasType<{
	create?:ResolverInputTypes["RedemptionPdfFields_url_Create"],
	read?:ResolverInputTypes["RedemptionPdfFields_url_Read"],
	update?:ResolverInputTypes["RedemptionPdfFields_url_Update"],
	delete?:ResolverInputTypes["RedemptionPdfFields_url_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["RedemptionPdfFields_url_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionPdfFields_url_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionPdfFields_url_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionPdfFields_url_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionPdfFields_filename"]: AliasType<{
	create?:ResolverInputTypes["RedemptionPdfFields_filename_Create"],
	read?:ResolverInputTypes["RedemptionPdfFields_filename_Read"],
	update?:ResolverInputTypes["RedemptionPdfFields_filename_Update"],
	delete?:ResolverInputTypes["RedemptionPdfFields_filename_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["RedemptionPdfFields_filename_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionPdfFields_filename_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionPdfFields_filename_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionPdfFields_filename_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionPdfFields_mimeType"]: AliasType<{
	create?:ResolverInputTypes["RedemptionPdfFields_mimeType_Create"],
	read?:ResolverInputTypes["RedemptionPdfFields_mimeType_Read"],
	update?:ResolverInputTypes["RedemptionPdfFields_mimeType_Update"],
	delete?:ResolverInputTypes["RedemptionPdfFields_mimeType_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["RedemptionPdfFields_mimeType_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionPdfFields_mimeType_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionPdfFields_mimeType_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionPdfFields_mimeType_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionPdfFields_filesize"]: AliasType<{
	create?:ResolverInputTypes["RedemptionPdfFields_filesize_Create"],
	read?:ResolverInputTypes["RedemptionPdfFields_filesize_Read"],
	update?:ResolverInputTypes["RedemptionPdfFields_filesize_Update"],
	delete?:ResolverInputTypes["RedemptionPdfFields_filesize_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["RedemptionPdfFields_filesize_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionPdfFields_filesize_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionPdfFields_filesize_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionPdfFields_filesize_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionPdfFields_width"]: AliasType<{
	create?:ResolverInputTypes["RedemptionPdfFields_width_Create"],
	read?:ResolverInputTypes["RedemptionPdfFields_width_Read"],
	update?:ResolverInputTypes["RedemptionPdfFields_width_Update"],
	delete?:ResolverInputTypes["RedemptionPdfFields_width_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["RedemptionPdfFields_width_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionPdfFields_width_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionPdfFields_width_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionPdfFields_width_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionPdfFields_height"]: AliasType<{
	create?:ResolverInputTypes["RedemptionPdfFields_height_Create"],
	read?:ResolverInputTypes["RedemptionPdfFields_height_Read"],
	update?:ResolverInputTypes["RedemptionPdfFields_height_Update"],
	delete?:ResolverInputTypes["RedemptionPdfFields_height_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["RedemptionPdfFields_height_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionPdfFields_height_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionPdfFields_height_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionPdfFields_height_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionPdfFields_focalX"]: AliasType<{
	create?:ResolverInputTypes["RedemptionPdfFields_focalX_Create"],
	read?:ResolverInputTypes["RedemptionPdfFields_focalX_Read"],
	update?:ResolverInputTypes["RedemptionPdfFields_focalX_Update"],
	delete?:ResolverInputTypes["RedemptionPdfFields_focalX_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["RedemptionPdfFields_focalX_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionPdfFields_focalX_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionPdfFields_focalX_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionPdfFields_focalX_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionPdfFields_focalY"]: AliasType<{
	create?:ResolverInputTypes["RedemptionPdfFields_focalY_Create"],
	read?:ResolverInputTypes["RedemptionPdfFields_focalY_Read"],
	update?:ResolverInputTypes["RedemptionPdfFields_focalY_Update"],
	delete?:ResolverInputTypes["RedemptionPdfFields_focalY_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["RedemptionPdfFields_focalY_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionPdfFields_focalY_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionPdfFields_focalY_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionPdfFields_focalY_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionPdfCreateAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionPdfReadAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionPdfUpdateAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedemptionPdfDeleteAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["check_in_redemptionAccess"]: AliasType<{
	fields?:ResolverInputTypes["CheckInRedemptionFields"],
	create?:ResolverInputTypes["CheckInRedemptionCreateAccess"],
	read?:ResolverInputTypes["CheckInRedemptionReadAccess"],
	update?:ResolverInputTypes["CheckInRedemptionUpdateAccess"],
	delete?:ResolverInputTypes["CheckInRedemptionDeleteAccess"],
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionFields"]: AliasType<{
	creator?:ResolverInputTypes["CheckInRedemptionFields_creator"],
	qrCodeId?:ResolverInputTypes["CheckInRedemptionFields_qrCodeId"],
	codeownerName?:ResolverInputTypes["CheckInRedemptionFields_codeownerName"],
	name?:ResolverInputTypes["CheckInRedemptionFields_name"],
	phone?:ResolverInputTypes["CheckInRedemptionFields_phone"],
	linkedEvent?:ResolverInputTypes["CheckInRedemptionFields_linkedEvent"],
	entryNumber?:ResolverInputTypes["CheckInRedemptionFields_entryNumber"],
	scanAt?:ResolverInputTypes["CheckInRedemptionFields_scanAt"],
	uploadAt?:ResolverInputTypes["CheckInRedemptionFields_uploadAt"],
	status?:ResolverInputTypes["CheckInRedemptionFields_status"],
	failReason?:ResolverInputTypes["CheckInRedemptionFields_failReason"],
	variants?:ResolverInputTypes["CheckInRedemptionFields_variants"],
	checkInAppSelectionId?:ResolverInputTypes["CheckInRedemptionFields_checkInAppSelectionId"],
	qrCodeStatus?:ResolverInputTypes["CheckInRedemptionFields_qrCodeStatus"],
	productType?:ResolverInputTypes["CheckInRedemptionFields_productType"],
	updatedAt?:ResolverInputTypes["CheckInRedemptionFields_updatedAt"],
	createdAt?:ResolverInputTypes["CheckInRedemptionFields_createdAt"],
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionFields_creator"]: AliasType<{
	create?:ResolverInputTypes["CheckInRedemptionFields_creator_Create"],
	read?:ResolverInputTypes["CheckInRedemptionFields_creator_Read"],
	update?:ResolverInputTypes["CheckInRedemptionFields_creator_Update"],
	delete?:ResolverInputTypes["CheckInRedemptionFields_creator_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionFields_creator_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionFields_creator_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionFields_creator_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionFields_creator_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionFields_qrCodeId"]: AliasType<{
	create?:ResolverInputTypes["CheckInRedemptionFields_qrCodeId_Create"],
	read?:ResolverInputTypes["CheckInRedemptionFields_qrCodeId_Read"],
	update?:ResolverInputTypes["CheckInRedemptionFields_qrCodeId_Update"],
	delete?:ResolverInputTypes["CheckInRedemptionFields_qrCodeId_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionFields_qrCodeId_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionFields_qrCodeId_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionFields_qrCodeId_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionFields_qrCodeId_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionFields_codeownerName"]: AliasType<{
	create?:ResolverInputTypes["CheckInRedemptionFields_codeownerName_Create"],
	read?:ResolverInputTypes["CheckInRedemptionFields_codeownerName_Read"],
	update?:ResolverInputTypes["CheckInRedemptionFields_codeownerName_Update"],
	delete?:ResolverInputTypes["CheckInRedemptionFields_codeownerName_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionFields_codeownerName_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionFields_codeownerName_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionFields_codeownerName_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionFields_codeownerName_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionFields_name"]: AliasType<{
	create?:ResolverInputTypes["CheckInRedemptionFields_name_Create"],
	read?:ResolverInputTypes["CheckInRedemptionFields_name_Read"],
	update?:ResolverInputTypes["CheckInRedemptionFields_name_Update"],
	delete?:ResolverInputTypes["CheckInRedemptionFields_name_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionFields_name_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionFields_name_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionFields_name_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionFields_name_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionFields_phone"]: AliasType<{
	create?:ResolverInputTypes["CheckInRedemptionFields_phone_Create"],
	read?:ResolverInputTypes["CheckInRedemptionFields_phone_Read"],
	update?:ResolverInputTypes["CheckInRedemptionFields_phone_Update"],
	delete?:ResolverInputTypes["CheckInRedemptionFields_phone_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionFields_phone_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionFields_phone_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionFields_phone_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionFields_phone_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionFields_linkedEvent"]: AliasType<{
	create?:ResolverInputTypes["CheckInRedemptionFields_linkedEvent_Create"],
	read?:ResolverInputTypes["CheckInRedemptionFields_linkedEvent_Read"],
	update?:ResolverInputTypes["CheckInRedemptionFields_linkedEvent_Update"],
	delete?:ResolverInputTypes["CheckInRedemptionFields_linkedEvent_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionFields_linkedEvent_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionFields_linkedEvent_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionFields_linkedEvent_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionFields_linkedEvent_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionFields_entryNumber"]: AliasType<{
	create?:ResolverInputTypes["CheckInRedemptionFields_entryNumber_Create"],
	read?:ResolverInputTypes["CheckInRedemptionFields_entryNumber_Read"],
	update?:ResolverInputTypes["CheckInRedemptionFields_entryNumber_Update"],
	delete?:ResolverInputTypes["CheckInRedemptionFields_entryNumber_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionFields_entryNumber_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionFields_entryNumber_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionFields_entryNumber_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionFields_entryNumber_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionFields_scanAt"]: AliasType<{
	create?:ResolverInputTypes["CheckInRedemptionFields_scanAt_Create"],
	read?:ResolverInputTypes["CheckInRedemptionFields_scanAt_Read"],
	update?:ResolverInputTypes["CheckInRedemptionFields_scanAt_Update"],
	delete?:ResolverInputTypes["CheckInRedemptionFields_scanAt_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionFields_scanAt_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionFields_scanAt_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionFields_scanAt_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionFields_scanAt_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionFields_uploadAt"]: AliasType<{
	create?:ResolverInputTypes["CheckInRedemptionFields_uploadAt_Create"],
	read?:ResolverInputTypes["CheckInRedemptionFields_uploadAt_Read"],
	update?:ResolverInputTypes["CheckInRedemptionFields_uploadAt_Update"],
	delete?:ResolverInputTypes["CheckInRedemptionFields_uploadAt_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionFields_uploadAt_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionFields_uploadAt_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionFields_uploadAt_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionFields_uploadAt_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionFields_status"]: AliasType<{
	create?:ResolverInputTypes["CheckInRedemptionFields_status_Create"],
	read?:ResolverInputTypes["CheckInRedemptionFields_status_Read"],
	update?:ResolverInputTypes["CheckInRedemptionFields_status_Update"],
	delete?:ResolverInputTypes["CheckInRedemptionFields_status_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionFields_status_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionFields_status_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionFields_status_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionFields_status_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionFields_failReason"]: AliasType<{
	create?:ResolverInputTypes["CheckInRedemptionFields_failReason_Create"],
	read?:ResolverInputTypes["CheckInRedemptionFields_failReason_Read"],
	update?:ResolverInputTypes["CheckInRedemptionFields_failReason_Update"],
	delete?:ResolverInputTypes["CheckInRedemptionFields_failReason_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionFields_failReason_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionFields_failReason_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionFields_failReason_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionFields_failReason_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionFields_variants"]: AliasType<{
	create?:ResolverInputTypes["CheckInRedemptionFields_variants_Create"],
	read?:ResolverInputTypes["CheckInRedemptionFields_variants_Read"],
	update?:ResolverInputTypes["CheckInRedemptionFields_variants_Update"],
	delete?:ResolverInputTypes["CheckInRedemptionFields_variants_Delete"],
	fields?:ResolverInputTypes["CheckInRedemptionFields_variants_Fields"],
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionFields_variants_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionFields_variants_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionFields_variants_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionFields_variants_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionFields_variants_Fields"]: AliasType<{
	variantId?:ResolverInputTypes["CheckInRedemptionFields_variants_variantId"],
	name?:ResolverInputTypes["CheckInRedemptionFields_variants_name"],
	quantity?:ResolverInputTypes["CheckInRedemptionFields_variants_quantity"],
	totalCost?:ResolverInputTypes["CheckInRedemptionFields_variants_totalCost"],
	id?:ResolverInputTypes["CheckInRedemptionFields_variants_id"],
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionFields_variants_variantId"]: AliasType<{
	create?:ResolverInputTypes["CheckInRedemptionFields_variants_variantId_Create"],
	read?:ResolverInputTypes["CheckInRedemptionFields_variants_variantId_Read"],
	update?:ResolverInputTypes["CheckInRedemptionFields_variants_variantId_Update"],
	delete?:ResolverInputTypes["CheckInRedemptionFields_variants_variantId_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionFields_variants_variantId_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionFields_variants_variantId_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionFields_variants_variantId_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionFields_variants_variantId_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionFields_variants_name"]: AliasType<{
	create?:ResolverInputTypes["CheckInRedemptionFields_variants_name_Create"],
	read?:ResolverInputTypes["CheckInRedemptionFields_variants_name_Read"],
	update?:ResolverInputTypes["CheckInRedemptionFields_variants_name_Update"],
	delete?:ResolverInputTypes["CheckInRedemptionFields_variants_name_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionFields_variants_name_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionFields_variants_name_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionFields_variants_name_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionFields_variants_name_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionFields_variants_quantity"]: AliasType<{
	create?:ResolverInputTypes["CheckInRedemptionFields_variants_quantity_Create"],
	read?:ResolverInputTypes["CheckInRedemptionFields_variants_quantity_Read"],
	update?:ResolverInputTypes["CheckInRedemptionFields_variants_quantity_Update"],
	delete?:ResolverInputTypes["CheckInRedemptionFields_variants_quantity_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionFields_variants_quantity_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionFields_variants_quantity_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionFields_variants_quantity_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionFields_variants_quantity_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionFields_variants_totalCost"]: AliasType<{
	create?:ResolverInputTypes["CheckInRedemptionFields_variants_totalCost_Create"],
	read?:ResolverInputTypes["CheckInRedemptionFields_variants_totalCost_Read"],
	update?:ResolverInputTypes["CheckInRedemptionFields_variants_totalCost_Update"],
	delete?:ResolverInputTypes["CheckInRedemptionFields_variants_totalCost_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionFields_variants_totalCost_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionFields_variants_totalCost_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionFields_variants_totalCost_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionFields_variants_totalCost_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionFields_variants_id"]: AliasType<{
	create?:ResolverInputTypes["CheckInRedemptionFields_variants_id_Create"],
	read?:ResolverInputTypes["CheckInRedemptionFields_variants_id_Read"],
	update?:ResolverInputTypes["CheckInRedemptionFields_variants_id_Update"],
	delete?:ResolverInputTypes["CheckInRedemptionFields_variants_id_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionFields_variants_id_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionFields_variants_id_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionFields_variants_id_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionFields_variants_id_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionFields_checkInAppSelectionId"]: AliasType<{
	create?:ResolverInputTypes["CheckInRedemptionFields_checkInAppSelectionId_Create"],
	read?:ResolverInputTypes["CheckInRedemptionFields_checkInAppSelectionId_Read"],
	update?:ResolverInputTypes["CheckInRedemptionFields_checkInAppSelectionId_Update"],
	delete?:ResolverInputTypes["CheckInRedemptionFields_checkInAppSelectionId_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionFields_checkInAppSelectionId_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionFields_checkInAppSelectionId_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionFields_checkInAppSelectionId_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionFields_checkInAppSelectionId_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionFields_qrCodeStatus"]: AliasType<{
	create?:ResolverInputTypes["CheckInRedemptionFields_qrCodeStatus_Create"],
	read?:ResolverInputTypes["CheckInRedemptionFields_qrCodeStatus_Read"],
	update?:ResolverInputTypes["CheckInRedemptionFields_qrCodeStatus_Update"],
	delete?:ResolverInputTypes["CheckInRedemptionFields_qrCodeStatus_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionFields_qrCodeStatus_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionFields_qrCodeStatus_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionFields_qrCodeStatus_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionFields_qrCodeStatus_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionFields_productType"]: AliasType<{
	create?:ResolverInputTypes["CheckInRedemptionFields_productType_Create"],
	read?:ResolverInputTypes["CheckInRedemptionFields_productType_Read"],
	update?:ResolverInputTypes["CheckInRedemptionFields_productType_Update"],
	delete?:ResolverInputTypes["CheckInRedemptionFields_productType_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionFields_productType_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionFields_productType_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionFields_productType_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionFields_productType_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionFields_updatedAt"]: AliasType<{
	create?:ResolverInputTypes["CheckInRedemptionFields_updatedAt_Create"],
	read?:ResolverInputTypes["CheckInRedemptionFields_updatedAt_Read"],
	update?:ResolverInputTypes["CheckInRedemptionFields_updatedAt_Update"],
	delete?:ResolverInputTypes["CheckInRedemptionFields_updatedAt_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionFields_updatedAt_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionFields_updatedAt_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionFields_updatedAt_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionFields_updatedAt_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionFields_createdAt"]: AliasType<{
	create?:ResolverInputTypes["CheckInRedemptionFields_createdAt_Create"],
	read?:ResolverInputTypes["CheckInRedemptionFields_createdAt_Read"],
	update?:ResolverInputTypes["CheckInRedemptionFields_createdAt_Update"],
	delete?:ResolverInputTypes["CheckInRedemptionFields_createdAt_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionFields_createdAt_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionFields_createdAt_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionFields_createdAt_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionFields_createdAt_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionCreateAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionReadAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionUpdateAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CheckInRedemptionDeleteAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["usersAccess"]: AliasType<{
	fields?:ResolverInputTypes["UsersFields"],
	create?:ResolverInputTypes["UsersCreateAccess"],
	read?:ResolverInputTypes["UsersReadAccess"],
	update?:ResolverInputTypes["UsersUpdateAccess"],
	delete?:ResolverInputTypes["UsersDeleteAccess"],
	unlock?:ResolverInputTypes["UsersUnlockAccess"],
		__typename?: boolean | `@${string}`
}>;
	["UsersFields"]: AliasType<{
	region?:ResolverInputTypes["UsersFields_region"],
	name?:ResolverInputTypes["UsersFields_name"],
	displayEmail?:ResolverInputTypes["UsersFields_displayEmail"],
	legacyUsername?:ResolverInputTypes["UsersFields_legacyUsername"],
	roles?:ResolverInputTypes["UsersFields_roles"],
	member?:ResolverInputTypes["UsersFields_member"],
	organiser?:ResolverInputTypes["UsersFields_organiser"],
	emailVerified?:ResolverInputTypes["UsersFields_emailVerified"],
	sso?:ResolverInputTypes["UsersFields_sso"],
	oldPassword?:ResolverInputTypes["UsersFields_oldPassword"],
	status?:ResolverInputTypes["UsersFields_status"],
	updatedAt?:ResolverInputTypes["UsersFields_updatedAt"],
	createdAt?:ResolverInputTypes["UsersFields_createdAt"],
	email?:ResolverInputTypes["UsersFields_email"],
	password?:ResolverInputTypes["UsersFields_password"],
		__typename?: boolean | `@${string}`
}>;
	["UsersFields_region"]: AliasType<{
	create?:ResolverInputTypes["UsersFields_region_Create"],
	read?:ResolverInputTypes["UsersFields_region_Read"],
	update?:ResolverInputTypes["UsersFields_region_Update"],
	delete?:ResolverInputTypes["UsersFields_region_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["UsersFields_region_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersFields_region_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersFields_region_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersFields_region_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersFields_name"]: AliasType<{
	create?:ResolverInputTypes["UsersFields_name_Create"],
	read?:ResolverInputTypes["UsersFields_name_Read"],
	update?:ResolverInputTypes["UsersFields_name_Update"],
	delete?:ResolverInputTypes["UsersFields_name_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["UsersFields_name_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersFields_name_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersFields_name_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersFields_name_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersFields_displayEmail"]: AliasType<{
	create?:ResolverInputTypes["UsersFields_displayEmail_Create"],
	read?:ResolverInputTypes["UsersFields_displayEmail_Read"],
	update?:ResolverInputTypes["UsersFields_displayEmail_Update"],
	delete?:ResolverInputTypes["UsersFields_displayEmail_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["UsersFields_displayEmail_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersFields_displayEmail_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersFields_displayEmail_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersFields_displayEmail_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersFields_legacyUsername"]: AliasType<{
	create?:ResolverInputTypes["UsersFields_legacyUsername_Create"],
	read?:ResolverInputTypes["UsersFields_legacyUsername_Read"],
	update?:ResolverInputTypes["UsersFields_legacyUsername_Update"],
	delete?:ResolverInputTypes["UsersFields_legacyUsername_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["UsersFields_legacyUsername_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersFields_legacyUsername_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersFields_legacyUsername_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersFields_legacyUsername_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersFields_roles"]: AliasType<{
	create?:ResolverInputTypes["UsersFields_roles_Create"],
	read?:ResolverInputTypes["UsersFields_roles_Read"],
	update?:ResolverInputTypes["UsersFields_roles_Update"],
	delete?:ResolverInputTypes["UsersFields_roles_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["UsersFields_roles_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersFields_roles_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersFields_roles_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersFields_roles_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersFields_member"]: AliasType<{
	create?:ResolverInputTypes["UsersFields_member_Create"],
	read?:ResolverInputTypes["UsersFields_member_Read"],
	update?:ResolverInputTypes["UsersFields_member_Update"],
	delete?:ResolverInputTypes["UsersFields_member_Delete"],
	fields?:ResolverInputTypes["UsersFields_member_Fields"],
		__typename?: boolean | `@${string}`
}>;
	["UsersFields_member_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersFields_member_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersFields_member_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersFields_member_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersFields_member_Fields"]: AliasType<{
	region?:ResolverInputTypes["UsersFields_member_region"],
	language?:ResolverInputTypes["UsersFields_member_language"],
	phone?:ResolverInputTypes["UsersFields_member_phone"],
	avatar?:ResolverInputTypes["UsersFields_member_avatar"],
	birthday?:ResolverInputTypes["UsersFields_member_birthday"],
	gender?:ResolverInputTypes["UsersFields_member_gender"],
	categories?:ResolverInputTypes["UsersFields_member_categories"],
		__typename?: boolean | `@${string}`
}>;
	["UsersFields_member_region"]: AliasType<{
	create?:ResolverInputTypes["UsersFields_member_region_Create"],
	read?:ResolverInputTypes["UsersFields_member_region_Read"],
	update?:ResolverInputTypes["UsersFields_member_region_Update"],
	delete?:ResolverInputTypes["UsersFields_member_region_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["UsersFields_member_region_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersFields_member_region_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersFields_member_region_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersFields_member_region_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersFields_member_language"]: AliasType<{
	create?:ResolverInputTypes["UsersFields_member_language_Create"],
	read?:ResolverInputTypes["UsersFields_member_language_Read"],
	update?:ResolverInputTypes["UsersFields_member_language_Update"],
	delete?:ResolverInputTypes["UsersFields_member_language_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["UsersFields_member_language_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersFields_member_language_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersFields_member_language_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersFields_member_language_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersFields_member_phone"]: AliasType<{
	create?:ResolverInputTypes["UsersFields_member_phone_Create"],
	read?:ResolverInputTypes["UsersFields_member_phone_Read"],
	update?:ResolverInputTypes["UsersFields_member_phone_Update"],
	delete?:ResolverInputTypes["UsersFields_member_phone_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["UsersFields_member_phone_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersFields_member_phone_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersFields_member_phone_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersFields_member_phone_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersFields_member_avatar"]: AliasType<{
	create?:ResolverInputTypes["UsersFields_member_avatar_Create"],
	read?:ResolverInputTypes["UsersFields_member_avatar_Read"],
	update?:ResolverInputTypes["UsersFields_member_avatar_Update"],
	delete?:ResolverInputTypes["UsersFields_member_avatar_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["UsersFields_member_avatar_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersFields_member_avatar_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersFields_member_avatar_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersFields_member_avatar_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersFields_member_birthday"]: AliasType<{
	create?:ResolverInputTypes["UsersFields_member_birthday_Create"],
	read?:ResolverInputTypes["UsersFields_member_birthday_Read"],
	update?:ResolverInputTypes["UsersFields_member_birthday_Update"],
	delete?:ResolverInputTypes["UsersFields_member_birthday_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["UsersFields_member_birthday_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersFields_member_birthday_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersFields_member_birthday_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersFields_member_birthday_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersFields_member_gender"]: AliasType<{
	create?:ResolverInputTypes["UsersFields_member_gender_Create"],
	read?:ResolverInputTypes["UsersFields_member_gender_Read"],
	update?:ResolverInputTypes["UsersFields_member_gender_Update"],
	delete?:ResolverInputTypes["UsersFields_member_gender_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["UsersFields_member_gender_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersFields_member_gender_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersFields_member_gender_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersFields_member_gender_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersFields_member_categories"]: AliasType<{
	create?:ResolverInputTypes["UsersFields_member_categories_Create"],
	read?:ResolverInputTypes["UsersFields_member_categories_Read"],
	update?:ResolverInputTypes["UsersFields_member_categories_Update"],
	delete?:ResolverInputTypes["UsersFields_member_categories_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["UsersFields_member_categories_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersFields_member_categories_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersFields_member_categories_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersFields_member_categories_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersFields_organiser"]: AliasType<{
	create?:ResolverInputTypes["UsersFields_organiser_Create"],
	read?:ResolverInputTypes["UsersFields_organiser_Read"],
	update?:ResolverInputTypes["UsersFields_organiser_Update"],
	delete?:ResolverInputTypes["UsersFields_organiser_Delete"],
	fields?:ResolverInputTypes["UsersFields_organiser_Fields"],
		__typename?: boolean | `@${string}`
}>;
	["UsersFields_organiser_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersFields_organiser_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersFields_organiser_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersFields_organiser_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersFields_organiser_Fields"]: AliasType<{
	logo?:ResolverInputTypes["UsersFields_organiser_logo"],
	banner?:ResolverInputTypes["UsersFields_organiser_banner"],
	description?:ResolverInputTypes["UsersFields_organiser_description"],
	foundedIn?:ResolverInputTypes["UsersFields_organiser_foundedIn"],
	address?:ResolverInputTypes["UsersFields_organiser_address"],
	website?:ResolverInputTypes["UsersFields_organiser_website"],
	facebook?:ResolverInputTypes["UsersFields_organiser_facebook"],
	instagram?:ResolverInputTypes["UsersFields_organiser_instagram"],
	language?:ResolverInputTypes["UsersFields_organiser_language"],
	phone?:ResolverInputTypes["UsersFields_organiser_phone"],
	followers?:ResolverInputTypes["UsersFields_organiser_followers"],
		__typename?: boolean | `@${string}`
}>;
	["UsersFields_organiser_logo"]: AliasType<{
	create?:ResolverInputTypes["UsersFields_organiser_logo_Create"],
	read?:ResolverInputTypes["UsersFields_organiser_logo_Read"],
	update?:ResolverInputTypes["UsersFields_organiser_logo_Update"],
	delete?:ResolverInputTypes["UsersFields_organiser_logo_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["UsersFields_organiser_logo_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersFields_organiser_logo_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersFields_organiser_logo_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersFields_organiser_logo_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersFields_organiser_banner"]: AliasType<{
	create?:ResolverInputTypes["UsersFields_organiser_banner_Create"],
	read?:ResolverInputTypes["UsersFields_organiser_banner_Read"],
	update?:ResolverInputTypes["UsersFields_organiser_banner_Update"],
	delete?:ResolverInputTypes["UsersFields_organiser_banner_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["UsersFields_organiser_banner_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersFields_organiser_banner_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersFields_organiser_banner_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersFields_organiser_banner_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersFields_organiser_description"]: AliasType<{
	create?:ResolverInputTypes["UsersFields_organiser_description_Create"],
	read?:ResolverInputTypes["UsersFields_organiser_description_Read"],
	update?:ResolverInputTypes["UsersFields_organiser_description_Update"],
	delete?:ResolverInputTypes["UsersFields_organiser_description_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["UsersFields_organiser_description_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersFields_organiser_description_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersFields_organiser_description_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersFields_organiser_description_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersFields_organiser_foundedIn"]: AliasType<{
	create?:ResolverInputTypes["UsersFields_organiser_foundedIn_Create"],
	read?:ResolverInputTypes["UsersFields_organiser_foundedIn_Read"],
	update?:ResolverInputTypes["UsersFields_organiser_foundedIn_Update"],
	delete?:ResolverInputTypes["UsersFields_organiser_foundedIn_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["UsersFields_organiser_foundedIn_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersFields_organiser_foundedIn_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersFields_organiser_foundedIn_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersFields_organiser_foundedIn_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersFields_organiser_address"]: AliasType<{
	create?:ResolverInputTypes["UsersFields_organiser_address_Create"],
	read?:ResolverInputTypes["UsersFields_organiser_address_Read"],
	update?:ResolverInputTypes["UsersFields_organiser_address_Update"],
	delete?:ResolverInputTypes["UsersFields_organiser_address_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["UsersFields_organiser_address_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersFields_organiser_address_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersFields_organiser_address_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersFields_organiser_address_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersFields_organiser_website"]: AliasType<{
	create?:ResolverInputTypes["UsersFields_organiser_website_Create"],
	read?:ResolverInputTypes["UsersFields_organiser_website_Read"],
	update?:ResolverInputTypes["UsersFields_organiser_website_Update"],
	delete?:ResolverInputTypes["UsersFields_organiser_website_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["UsersFields_organiser_website_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersFields_organiser_website_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersFields_organiser_website_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersFields_organiser_website_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersFields_organiser_facebook"]: AliasType<{
	create?:ResolverInputTypes["UsersFields_organiser_facebook_Create"],
	read?:ResolverInputTypes["UsersFields_organiser_facebook_Read"],
	update?:ResolverInputTypes["UsersFields_organiser_facebook_Update"],
	delete?:ResolverInputTypes["UsersFields_organiser_facebook_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["UsersFields_organiser_facebook_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersFields_organiser_facebook_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersFields_organiser_facebook_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersFields_organiser_facebook_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersFields_organiser_instagram"]: AliasType<{
	create?:ResolverInputTypes["UsersFields_organiser_instagram_Create"],
	read?:ResolverInputTypes["UsersFields_organiser_instagram_Read"],
	update?:ResolverInputTypes["UsersFields_organiser_instagram_Update"],
	delete?:ResolverInputTypes["UsersFields_organiser_instagram_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["UsersFields_organiser_instagram_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersFields_organiser_instagram_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersFields_organiser_instagram_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersFields_organiser_instagram_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersFields_organiser_language"]: AliasType<{
	create?:ResolverInputTypes["UsersFields_organiser_language_Create"],
	read?:ResolverInputTypes["UsersFields_organiser_language_Read"],
	update?:ResolverInputTypes["UsersFields_organiser_language_Update"],
	delete?:ResolverInputTypes["UsersFields_organiser_language_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["UsersFields_organiser_language_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersFields_organiser_language_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersFields_organiser_language_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersFields_organiser_language_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersFields_organiser_phone"]: AliasType<{
	create?:ResolverInputTypes["UsersFields_organiser_phone_Create"],
	read?:ResolverInputTypes["UsersFields_organiser_phone_Read"],
	update?:ResolverInputTypes["UsersFields_organiser_phone_Update"],
	delete?:ResolverInputTypes["UsersFields_organiser_phone_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["UsersFields_organiser_phone_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersFields_organiser_phone_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersFields_organiser_phone_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersFields_organiser_phone_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersFields_organiser_followers"]: AliasType<{
	create?:ResolverInputTypes["UsersFields_organiser_followers_Create"],
	read?:ResolverInputTypes["UsersFields_organiser_followers_Read"],
	update?:ResolverInputTypes["UsersFields_organiser_followers_Update"],
	delete?:ResolverInputTypes["UsersFields_organiser_followers_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["UsersFields_organiser_followers_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersFields_organiser_followers_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersFields_organiser_followers_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersFields_organiser_followers_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersFields_emailVerified"]: AliasType<{
	create?:ResolverInputTypes["UsersFields_emailVerified_Create"],
	read?:ResolverInputTypes["UsersFields_emailVerified_Read"],
	update?:ResolverInputTypes["UsersFields_emailVerified_Update"],
	delete?:ResolverInputTypes["UsersFields_emailVerified_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["UsersFields_emailVerified_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersFields_emailVerified_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersFields_emailVerified_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersFields_emailVerified_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersFields_sso"]: AliasType<{
	create?:ResolverInputTypes["UsersFields_sso_Create"],
	read?:ResolverInputTypes["UsersFields_sso_Read"],
	update?:ResolverInputTypes["UsersFields_sso_Update"],
	delete?:ResolverInputTypes["UsersFields_sso_Delete"],
	fields?:ResolverInputTypes["UsersFields_sso_Fields"],
		__typename?: boolean | `@${string}`
}>;
	["UsersFields_sso_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersFields_sso_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersFields_sso_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersFields_sso_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersFields_sso_Fields"]: AliasType<{
	googleId?:ResolverInputTypes["UsersFields_sso_googleId"],
	facebookId?:ResolverInputTypes["UsersFields_sso_facebookId"],
	appleId?:ResolverInputTypes["UsersFields_sso_appleId"],
		__typename?: boolean | `@${string}`
}>;
	["UsersFields_sso_googleId"]: AliasType<{
	create?:ResolverInputTypes["UsersFields_sso_googleId_Create"],
	read?:ResolverInputTypes["UsersFields_sso_googleId_Read"],
	update?:ResolverInputTypes["UsersFields_sso_googleId_Update"],
	delete?:ResolverInputTypes["UsersFields_sso_googleId_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["UsersFields_sso_googleId_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersFields_sso_googleId_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersFields_sso_googleId_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersFields_sso_googleId_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersFields_sso_facebookId"]: AliasType<{
	create?:ResolverInputTypes["UsersFields_sso_facebookId_Create"],
	read?:ResolverInputTypes["UsersFields_sso_facebookId_Read"],
	update?:ResolverInputTypes["UsersFields_sso_facebookId_Update"],
	delete?:ResolverInputTypes["UsersFields_sso_facebookId_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["UsersFields_sso_facebookId_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersFields_sso_facebookId_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersFields_sso_facebookId_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersFields_sso_facebookId_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersFields_sso_appleId"]: AliasType<{
	create?:ResolverInputTypes["UsersFields_sso_appleId_Create"],
	read?:ResolverInputTypes["UsersFields_sso_appleId_Read"],
	update?:ResolverInputTypes["UsersFields_sso_appleId_Update"],
	delete?:ResolverInputTypes["UsersFields_sso_appleId_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["UsersFields_sso_appleId_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersFields_sso_appleId_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersFields_sso_appleId_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersFields_sso_appleId_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersFields_oldPassword"]: AliasType<{
	create?:ResolverInputTypes["UsersFields_oldPassword_Create"],
	read?:ResolverInputTypes["UsersFields_oldPassword_Read"],
	update?:ResolverInputTypes["UsersFields_oldPassword_Update"],
	delete?:ResolverInputTypes["UsersFields_oldPassword_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["UsersFields_oldPassword_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersFields_oldPassword_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersFields_oldPassword_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersFields_oldPassword_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersFields_status"]: AliasType<{
	create?:ResolverInputTypes["UsersFields_status_Create"],
	read?:ResolverInputTypes["UsersFields_status_Read"],
	update?:ResolverInputTypes["UsersFields_status_Update"],
	delete?:ResolverInputTypes["UsersFields_status_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["UsersFields_status_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersFields_status_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersFields_status_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersFields_status_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersFields_updatedAt"]: AliasType<{
	create?:ResolverInputTypes["UsersFields_updatedAt_Create"],
	read?:ResolverInputTypes["UsersFields_updatedAt_Read"],
	update?:ResolverInputTypes["UsersFields_updatedAt_Update"],
	delete?:ResolverInputTypes["UsersFields_updatedAt_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["UsersFields_updatedAt_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersFields_updatedAt_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersFields_updatedAt_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersFields_updatedAt_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersFields_createdAt"]: AliasType<{
	create?:ResolverInputTypes["UsersFields_createdAt_Create"],
	read?:ResolverInputTypes["UsersFields_createdAt_Read"],
	update?:ResolverInputTypes["UsersFields_createdAt_Update"],
	delete?:ResolverInputTypes["UsersFields_createdAt_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["UsersFields_createdAt_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersFields_createdAt_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersFields_createdAt_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersFields_createdAt_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersFields_email"]: AliasType<{
	create?:ResolverInputTypes["UsersFields_email_Create"],
	read?:ResolverInputTypes["UsersFields_email_Read"],
	update?:ResolverInputTypes["UsersFields_email_Update"],
	delete?:ResolverInputTypes["UsersFields_email_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["UsersFields_email_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersFields_email_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersFields_email_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersFields_email_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersFields_password"]: AliasType<{
	create?:ResolverInputTypes["UsersFields_password_Create"],
	read?:ResolverInputTypes["UsersFields_password_Read"],
	update?:ResolverInputTypes["UsersFields_password_Update"],
	delete?:ResolverInputTypes["UsersFields_password_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["UsersFields_password_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersFields_password_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersFields_password_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersFields_password_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersCreateAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersReadAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersUpdateAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersDeleteAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UsersUnlockAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["bookmarksAccess"]: AliasType<{
	fields?:ResolverInputTypes["BookmarksFields"],
	create?:ResolverInputTypes["BookmarksCreateAccess"],
	read?:ResolverInputTypes["BookmarksReadAccess"],
	update?:ResolverInputTypes["BookmarksUpdateAccess"],
	delete?:ResolverInputTypes["BookmarksDeleteAccess"],
		__typename?: boolean | `@${string}`
}>;
	["BookmarksFields"]: AliasType<{
	user?:ResolverInputTypes["BookmarksFields_user"],
	item?:ResolverInputTypes["BookmarksFields_item"],
	updatedAt?:ResolverInputTypes["BookmarksFields_updatedAt"],
	createdAt?:ResolverInputTypes["BookmarksFields_createdAt"],
		__typename?: boolean | `@${string}`
}>;
	["BookmarksFields_user"]: AliasType<{
	create?:ResolverInputTypes["BookmarksFields_user_Create"],
	read?:ResolverInputTypes["BookmarksFields_user_Read"],
	update?:ResolverInputTypes["BookmarksFields_user_Update"],
	delete?:ResolverInputTypes["BookmarksFields_user_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["BookmarksFields_user_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BookmarksFields_user_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BookmarksFields_user_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BookmarksFields_user_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BookmarksFields_item"]: AliasType<{
	create?:ResolverInputTypes["BookmarksFields_item_Create"],
	read?:ResolverInputTypes["BookmarksFields_item_Read"],
	update?:ResolverInputTypes["BookmarksFields_item_Update"],
	delete?:ResolverInputTypes["BookmarksFields_item_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["BookmarksFields_item_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BookmarksFields_item_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BookmarksFields_item_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BookmarksFields_item_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BookmarksFields_updatedAt"]: AliasType<{
	create?:ResolverInputTypes["BookmarksFields_updatedAt_Create"],
	read?:ResolverInputTypes["BookmarksFields_updatedAt_Read"],
	update?:ResolverInputTypes["BookmarksFields_updatedAt_Update"],
	delete?:ResolverInputTypes["BookmarksFields_updatedAt_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["BookmarksFields_updatedAt_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BookmarksFields_updatedAt_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BookmarksFields_updatedAt_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BookmarksFields_updatedAt_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BookmarksFields_createdAt"]: AliasType<{
	create?:ResolverInputTypes["BookmarksFields_createdAt_Create"],
	read?:ResolverInputTypes["BookmarksFields_createdAt_Read"],
	update?:ResolverInputTypes["BookmarksFields_createdAt_Update"],
	delete?:ResolverInputTypes["BookmarksFields_createdAt_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["BookmarksFields_createdAt_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BookmarksFields_createdAt_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BookmarksFields_createdAt_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BookmarksFields_createdAt_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BookmarksCreateAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BookmarksReadAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BookmarksUpdateAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BookmarksDeleteAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["webAppBookmarksAccess"]: AliasType<{
	fields?:ResolverInputTypes["WebAppBookmarksFields"],
	create?:ResolverInputTypes["WebAppBookmarksCreateAccess"],
	read?:ResolverInputTypes["WebAppBookmarksReadAccess"],
	update?:ResolverInputTypes["WebAppBookmarksUpdateAccess"],
	delete?:ResolverInputTypes["WebAppBookmarksDeleteAccess"],
		__typename?: boolean | `@${string}`
}>;
	["WebAppBookmarksFields"]: AliasType<{
	user?:ResolverInputTypes["WebAppBookmarksFields_user"],
	event?:ResolverInputTypes["WebAppBookmarksFields_event"],
	productType?:ResolverInputTypes["WebAppBookmarksFields_productType"],
	item?:ResolverInputTypes["WebAppBookmarksFields_item"],
	entryNumber?:ResolverInputTypes["WebAppBookmarksFields_entryNumber"],
	updatedAt?:ResolverInputTypes["WebAppBookmarksFields_updatedAt"],
	createdAt?:ResolverInputTypes["WebAppBookmarksFields_createdAt"],
		__typename?: boolean | `@${string}`
}>;
	["WebAppBookmarksFields_user"]: AliasType<{
	create?:ResolverInputTypes["WebAppBookmarksFields_user_Create"],
	read?:ResolverInputTypes["WebAppBookmarksFields_user_Read"],
	update?:ResolverInputTypes["WebAppBookmarksFields_user_Update"],
	delete?:ResolverInputTypes["WebAppBookmarksFields_user_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["WebAppBookmarksFields_user_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["WebAppBookmarksFields_user_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["WebAppBookmarksFields_user_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["WebAppBookmarksFields_user_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["WebAppBookmarksFields_event"]: AliasType<{
	create?:ResolverInputTypes["WebAppBookmarksFields_event_Create"],
	read?:ResolverInputTypes["WebAppBookmarksFields_event_Read"],
	update?:ResolverInputTypes["WebAppBookmarksFields_event_Update"],
	delete?:ResolverInputTypes["WebAppBookmarksFields_event_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["WebAppBookmarksFields_event_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["WebAppBookmarksFields_event_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["WebAppBookmarksFields_event_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["WebAppBookmarksFields_event_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["WebAppBookmarksFields_productType"]: AliasType<{
	create?:ResolverInputTypes["WebAppBookmarksFields_productType_Create"],
	read?:ResolverInputTypes["WebAppBookmarksFields_productType_Read"],
	update?:ResolverInputTypes["WebAppBookmarksFields_productType_Update"],
	delete?:ResolverInputTypes["WebAppBookmarksFields_productType_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["WebAppBookmarksFields_productType_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["WebAppBookmarksFields_productType_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["WebAppBookmarksFields_productType_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["WebAppBookmarksFields_productType_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["WebAppBookmarksFields_item"]: AliasType<{
	create?:ResolverInputTypes["WebAppBookmarksFields_item_Create"],
	read?:ResolverInputTypes["WebAppBookmarksFields_item_Read"],
	update?:ResolverInputTypes["WebAppBookmarksFields_item_Update"],
	delete?:ResolverInputTypes["WebAppBookmarksFields_item_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["WebAppBookmarksFields_item_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["WebAppBookmarksFields_item_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["WebAppBookmarksFields_item_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["WebAppBookmarksFields_item_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["WebAppBookmarksFields_entryNumber"]: AliasType<{
	create?:ResolverInputTypes["WebAppBookmarksFields_entryNumber_Create"],
	read?:ResolverInputTypes["WebAppBookmarksFields_entryNumber_Read"],
	update?:ResolverInputTypes["WebAppBookmarksFields_entryNumber_Update"],
	delete?:ResolverInputTypes["WebAppBookmarksFields_entryNumber_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["WebAppBookmarksFields_entryNumber_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["WebAppBookmarksFields_entryNumber_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["WebAppBookmarksFields_entryNumber_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["WebAppBookmarksFields_entryNumber_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["WebAppBookmarksFields_updatedAt"]: AliasType<{
	create?:ResolverInputTypes["WebAppBookmarksFields_updatedAt_Create"],
	read?:ResolverInputTypes["WebAppBookmarksFields_updatedAt_Read"],
	update?:ResolverInputTypes["WebAppBookmarksFields_updatedAt_Update"],
	delete?:ResolverInputTypes["WebAppBookmarksFields_updatedAt_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["WebAppBookmarksFields_updatedAt_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["WebAppBookmarksFields_updatedAt_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["WebAppBookmarksFields_updatedAt_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["WebAppBookmarksFields_updatedAt_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["WebAppBookmarksFields_createdAt"]: AliasType<{
	create?:ResolverInputTypes["WebAppBookmarksFields_createdAt_Create"],
	read?:ResolverInputTypes["WebAppBookmarksFields_createdAt_Read"],
	update?:ResolverInputTypes["WebAppBookmarksFields_createdAt_Update"],
	delete?:ResolverInputTypes["WebAppBookmarksFields_createdAt_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["WebAppBookmarksFields_createdAt_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["WebAppBookmarksFields_createdAt_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["WebAppBookmarksFields_createdAt_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["WebAppBookmarksFields_createdAt_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["WebAppBookmarksCreateAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["WebAppBookmarksReadAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["WebAppBookmarksUpdateAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["WebAppBookmarksDeleteAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["view_recordsAccess"]: AliasType<{
	fields?:ResolverInputTypes["ViewRecordsFields"],
	create?:ResolverInputTypes["ViewRecordsCreateAccess"],
	read?:ResolverInputTypes["ViewRecordsReadAccess"],
	update?:ResolverInputTypes["ViewRecordsUpdateAccess"],
	delete?:ResolverInputTypes["ViewRecordsDeleteAccess"],
		__typename?: boolean | `@${string}`
}>;
	["ViewRecordsFields"]: AliasType<{
	user?:ResolverInputTypes["ViewRecordsFields_user"],
	item?:ResolverInputTypes["ViewRecordsFields_item"],
	updatedAt?:ResolverInputTypes["ViewRecordsFields_updatedAt"],
	createdAt?:ResolverInputTypes["ViewRecordsFields_createdAt"],
		__typename?: boolean | `@${string}`
}>;
	["ViewRecordsFields_user"]: AliasType<{
	create?:ResolverInputTypes["ViewRecordsFields_user_Create"],
	read?:ResolverInputTypes["ViewRecordsFields_user_Read"],
	update?:ResolverInputTypes["ViewRecordsFields_user_Update"],
	delete?:ResolverInputTypes["ViewRecordsFields_user_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["ViewRecordsFields_user_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ViewRecordsFields_user_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ViewRecordsFields_user_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ViewRecordsFields_user_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ViewRecordsFields_item"]: AliasType<{
	create?:ResolverInputTypes["ViewRecordsFields_item_Create"],
	read?:ResolverInputTypes["ViewRecordsFields_item_Read"],
	update?:ResolverInputTypes["ViewRecordsFields_item_Update"],
	delete?:ResolverInputTypes["ViewRecordsFields_item_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["ViewRecordsFields_item_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ViewRecordsFields_item_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ViewRecordsFields_item_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ViewRecordsFields_item_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ViewRecordsFields_updatedAt"]: AliasType<{
	create?:ResolverInputTypes["ViewRecordsFields_updatedAt_Create"],
	read?:ResolverInputTypes["ViewRecordsFields_updatedAt_Read"],
	update?:ResolverInputTypes["ViewRecordsFields_updatedAt_Update"],
	delete?:ResolverInputTypes["ViewRecordsFields_updatedAt_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["ViewRecordsFields_updatedAt_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ViewRecordsFields_updatedAt_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ViewRecordsFields_updatedAt_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ViewRecordsFields_updatedAt_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ViewRecordsFields_createdAt"]: AliasType<{
	create?:ResolverInputTypes["ViewRecordsFields_createdAt_Create"],
	read?:ResolverInputTypes["ViewRecordsFields_createdAt_Read"],
	update?:ResolverInputTypes["ViewRecordsFields_createdAt_Update"],
	delete?:ResolverInputTypes["ViewRecordsFields_createdAt_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["ViewRecordsFields_createdAt_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ViewRecordsFields_createdAt_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ViewRecordsFields_createdAt_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ViewRecordsFields_createdAt_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ViewRecordsCreateAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ViewRecordsReadAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ViewRecordsUpdateAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ViewRecordsDeleteAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["commentsAccess"]: AliasType<{
	fields?:ResolverInputTypes["CommentsFields"],
	create?:ResolverInputTypes["CommentsCreateAccess"],
	read?:ResolverInputTypes["CommentsReadAccess"],
	update?:ResolverInputTypes["CommentsUpdateAccess"],
	delete?:ResolverInputTypes["CommentsDeleteAccess"],
		__typename?: boolean | `@${string}`
}>;
	["CommentsFields"]: AliasType<{
	commenter?:ResolverInputTypes["CommentsFields_commenter"],
	content?:ResolverInputTypes["CommentsFields_content"],
	image?:ResolverInputTypes["CommentsFields_image"],
	target?:ResolverInputTypes["CommentsFields_target"],
	parent?:ResolverInputTypes["CommentsFields_parent"],
	isReviewed?:ResolverInputTypes["CommentsFields_isReviewed"],
	reviewedBy?:ResolverInputTypes["CommentsFields_reviewedBy"],
	banned?:ResolverInputTypes["CommentsFields_banned"],
	type?:ResolverInputTypes["CommentsFields_type"],
	updatedAt?:ResolverInputTypes["CommentsFields_updatedAt"],
	createdAt?:ResolverInputTypes["CommentsFields_createdAt"],
		__typename?: boolean | `@${string}`
}>;
	["CommentsFields_commenter"]: AliasType<{
	create?:ResolverInputTypes["CommentsFields_commenter_Create"],
	read?:ResolverInputTypes["CommentsFields_commenter_Read"],
	update?:ResolverInputTypes["CommentsFields_commenter_Update"],
	delete?:ResolverInputTypes["CommentsFields_commenter_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["CommentsFields_commenter_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CommentsFields_commenter_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CommentsFields_commenter_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CommentsFields_commenter_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CommentsFields_content"]: AliasType<{
	create?:ResolverInputTypes["CommentsFields_content_Create"],
	read?:ResolverInputTypes["CommentsFields_content_Read"],
	update?:ResolverInputTypes["CommentsFields_content_Update"],
	delete?:ResolverInputTypes["CommentsFields_content_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["CommentsFields_content_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CommentsFields_content_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CommentsFields_content_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CommentsFields_content_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CommentsFields_image"]: AliasType<{
	create?:ResolverInputTypes["CommentsFields_image_Create"],
	read?:ResolverInputTypes["CommentsFields_image_Read"],
	update?:ResolverInputTypes["CommentsFields_image_Update"],
	delete?:ResolverInputTypes["CommentsFields_image_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["CommentsFields_image_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CommentsFields_image_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CommentsFields_image_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CommentsFields_image_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CommentsFields_target"]: AliasType<{
	create?:ResolverInputTypes["CommentsFields_target_Create"],
	read?:ResolverInputTypes["CommentsFields_target_Read"],
	update?:ResolverInputTypes["CommentsFields_target_Update"],
	delete?:ResolverInputTypes["CommentsFields_target_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["CommentsFields_target_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CommentsFields_target_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CommentsFields_target_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CommentsFields_target_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CommentsFields_parent"]: AliasType<{
	create?:ResolverInputTypes["CommentsFields_parent_Create"],
	read?:ResolverInputTypes["CommentsFields_parent_Read"],
	update?:ResolverInputTypes["CommentsFields_parent_Update"],
	delete?:ResolverInputTypes["CommentsFields_parent_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["CommentsFields_parent_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CommentsFields_parent_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CommentsFields_parent_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CommentsFields_parent_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CommentsFields_isReviewed"]: AliasType<{
	create?:ResolverInputTypes["CommentsFields_isReviewed_Create"],
	read?:ResolverInputTypes["CommentsFields_isReviewed_Read"],
	update?:ResolverInputTypes["CommentsFields_isReviewed_Update"],
	delete?:ResolverInputTypes["CommentsFields_isReviewed_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["CommentsFields_isReviewed_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CommentsFields_isReviewed_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CommentsFields_isReviewed_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CommentsFields_isReviewed_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CommentsFields_reviewedBy"]: AliasType<{
	create?:ResolverInputTypes["CommentsFields_reviewedBy_Create"],
	read?:ResolverInputTypes["CommentsFields_reviewedBy_Read"],
	update?:ResolverInputTypes["CommentsFields_reviewedBy_Update"],
	delete?:ResolverInputTypes["CommentsFields_reviewedBy_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["CommentsFields_reviewedBy_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CommentsFields_reviewedBy_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CommentsFields_reviewedBy_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CommentsFields_reviewedBy_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CommentsFields_banned"]: AliasType<{
	create?:ResolverInputTypes["CommentsFields_banned_Create"],
	read?:ResolverInputTypes["CommentsFields_banned_Read"],
	update?:ResolverInputTypes["CommentsFields_banned_Update"],
	delete?:ResolverInputTypes["CommentsFields_banned_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["CommentsFields_banned_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CommentsFields_banned_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CommentsFields_banned_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CommentsFields_banned_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CommentsFields_type"]: AliasType<{
	create?:ResolverInputTypes["CommentsFields_type_Create"],
	read?:ResolverInputTypes["CommentsFields_type_Read"],
	update?:ResolverInputTypes["CommentsFields_type_Update"],
	delete?:ResolverInputTypes["CommentsFields_type_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["CommentsFields_type_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CommentsFields_type_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CommentsFields_type_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CommentsFields_type_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CommentsFields_updatedAt"]: AliasType<{
	create?:ResolverInputTypes["CommentsFields_updatedAt_Create"],
	read?:ResolverInputTypes["CommentsFields_updatedAt_Read"],
	update?:ResolverInputTypes["CommentsFields_updatedAt_Update"],
	delete?:ResolverInputTypes["CommentsFields_updatedAt_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["CommentsFields_updatedAt_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CommentsFields_updatedAt_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CommentsFields_updatedAt_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CommentsFields_updatedAt_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CommentsFields_createdAt"]: AliasType<{
	create?:ResolverInputTypes["CommentsFields_createdAt_Create"],
	read?:ResolverInputTypes["CommentsFields_createdAt_Read"],
	update?:ResolverInputTypes["CommentsFields_createdAt_Update"],
	delete?:ResolverInputTypes["CommentsFields_createdAt_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["CommentsFields_createdAt_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CommentsFields_createdAt_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CommentsFields_createdAt_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CommentsFields_createdAt_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CommentsCreateAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CommentsReadAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CommentsUpdateAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CommentsDeleteAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["subscriptionsAccess"]: AliasType<{
	fields?:ResolverInputTypes["SubscriptionsFields"],
	create?:ResolverInputTypes["SubscriptionsCreateAccess"],
	read?:ResolverInputTypes["SubscriptionsReadAccess"],
	update?:ResolverInputTypes["SubscriptionsUpdateAccess"],
	delete?:ResolverInputTypes["SubscriptionsDeleteAccess"],
		__typename?: boolean | `@${string}`
}>;
	["SubscriptionsFields"]: AliasType<{
	user?:ResolverInputTypes["SubscriptionsFields_user"],
	organiser?:ResolverInputTypes["SubscriptionsFields_organiser"],
	updatedAt?:ResolverInputTypes["SubscriptionsFields_updatedAt"],
	createdAt?:ResolverInputTypes["SubscriptionsFields_createdAt"],
		__typename?: boolean | `@${string}`
}>;
	["SubscriptionsFields_user"]: AliasType<{
	create?:ResolverInputTypes["SubscriptionsFields_user_Create"],
	read?:ResolverInputTypes["SubscriptionsFields_user_Read"],
	update?:ResolverInputTypes["SubscriptionsFields_user_Update"],
	delete?:ResolverInputTypes["SubscriptionsFields_user_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["SubscriptionsFields_user_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["SubscriptionsFields_user_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["SubscriptionsFields_user_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["SubscriptionsFields_user_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["SubscriptionsFields_organiser"]: AliasType<{
	create?:ResolverInputTypes["SubscriptionsFields_organiser_Create"],
	read?:ResolverInputTypes["SubscriptionsFields_organiser_Read"],
	update?:ResolverInputTypes["SubscriptionsFields_organiser_Update"],
	delete?:ResolverInputTypes["SubscriptionsFields_organiser_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["SubscriptionsFields_organiser_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["SubscriptionsFields_organiser_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["SubscriptionsFields_organiser_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["SubscriptionsFields_organiser_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["SubscriptionsFields_updatedAt"]: AliasType<{
	create?:ResolverInputTypes["SubscriptionsFields_updatedAt_Create"],
	read?:ResolverInputTypes["SubscriptionsFields_updatedAt_Read"],
	update?:ResolverInputTypes["SubscriptionsFields_updatedAt_Update"],
	delete?:ResolverInputTypes["SubscriptionsFields_updatedAt_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["SubscriptionsFields_updatedAt_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["SubscriptionsFields_updatedAt_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["SubscriptionsFields_updatedAt_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["SubscriptionsFields_updatedAt_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["SubscriptionsFields_createdAt"]: AliasType<{
	create?:ResolverInputTypes["SubscriptionsFields_createdAt_Create"],
	read?:ResolverInputTypes["SubscriptionsFields_createdAt_Read"],
	update?:ResolverInputTypes["SubscriptionsFields_createdAt_Update"],
	delete?:ResolverInputTypes["SubscriptionsFields_createdAt_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["SubscriptionsFields_createdAt_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["SubscriptionsFields_createdAt_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["SubscriptionsFields_createdAt_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["SubscriptionsFields_createdAt_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["SubscriptionsCreateAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["SubscriptionsReadAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["SubscriptionsUpdateAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["SubscriptionsDeleteAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["newsletterAccess"]: AliasType<{
	fields?:ResolverInputTypes["NewsletterFields"],
	create?:ResolverInputTypes["NewsletterCreateAccess"],
	read?:ResolverInputTypes["NewsletterReadAccess"],
	update?:ResolverInputTypes["NewsletterUpdateAccess"],
	delete?:ResolverInputTypes["NewsletterDeleteAccess"],
		__typename?: boolean | `@${string}`
}>;
	["NewsletterFields"]: AliasType<{
	email?:ResolverInputTypes["NewsletterFields_email"],
	region?:ResolverInputTypes["NewsletterFields_region"],
	updatedAt?:ResolverInputTypes["NewsletterFields_updatedAt"],
	createdAt?:ResolverInputTypes["NewsletterFields_createdAt"],
		__typename?: boolean | `@${string}`
}>;
	["NewsletterFields_email"]: AliasType<{
	create?:ResolverInputTypes["NewsletterFields_email_Create"],
	read?:ResolverInputTypes["NewsletterFields_email_Read"],
	update?:ResolverInputTypes["NewsletterFields_email_Update"],
	delete?:ResolverInputTypes["NewsletterFields_email_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["NewsletterFields_email_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["NewsletterFields_email_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["NewsletterFields_email_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["NewsletterFields_email_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["NewsletterFields_region"]: AliasType<{
	create?:ResolverInputTypes["NewsletterFields_region_Create"],
	read?:ResolverInputTypes["NewsletterFields_region_Read"],
	update?:ResolverInputTypes["NewsletterFields_region_Update"],
	delete?:ResolverInputTypes["NewsletterFields_region_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["NewsletterFields_region_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["NewsletterFields_region_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["NewsletterFields_region_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["NewsletterFields_region_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["NewsletterFields_updatedAt"]: AliasType<{
	create?:ResolverInputTypes["NewsletterFields_updatedAt_Create"],
	read?:ResolverInputTypes["NewsletterFields_updatedAt_Read"],
	update?:ResolverInputTypes["NewsletterFields_updatedAt_Update"],
	delete?:ResolverInputTypes["NewsletterFields_updatedAt_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["NewsletterFields_updatedAt_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["NewsletterFields_updatedAt_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["NewsletterFields_updatedAt_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["NewsletterFields_updatedAt_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["NewsletterFields_createdAt"]: AliasType<{
	create?:ResolverInputTypes["NewsletterFields_createdAt_Create"],
	read?:ResolverInputTypes["NewsletterFields_createdAt_Read"],
	update?:ResolverInputTypes["NewsletterFields_createdAt_Update"],
	delete?:ResolverInputTypes["NewsletterFields_createdAt_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["NewsletterFields_createdAt_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["NewsletterFields_createdAt_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["NewsletterFields_createdAt_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["NewsletterFields_createdAt_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["NewsletterCreateAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["NewsletterReadAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["NewsletterUpdateAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["NewsletterDeleteAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["blog_categoriesAccess"]: AliasType<{
	fields?:ResolverInputTypes["BlogCategoriesFields"],
	create?:ResolverInputTypes["BlogCategoriesCreateAccess"],
	read?:ResolverInputTypes["BlogCategoriesReadAccess"],
	update?:ResolverInputTypes["BlogCategoriesUpdateAccess"],
	delete?:ResolverInputTypes["BlogCategoriesDeleteAccess"],
		__typename?: boolean | `@${string}`
}>;
	["BlogCategoriesFields"]: AliasType<{
	name?:ResolverInputTypes["BlogCategoriesFields_name"],
	value?:ResolverInputTypes["BlogCategoriesFields_value"],
	icon?:ResolverInputTypes["BlogCategoriesFields_icon"],
	region?:ResolverInputTypes["BlogCategoriesFields_region"],
	updatedAt?:ResolverInputTypes["BlogCategoriesFields_updatedAt"],
	createdAt?:ResolverInputTypes["BlogCategoriesFields_createdAt"],
		__typename?: boolean | `@${string}`
}>;
	["BlogCategoriesFields_name"]: AliasType<{
	create?:ResolverInputTypes["BlogCategoriesFields_name_Create"],
	read?:ResolverInputTypes["BlogCategoriesFields_name_Read"],
	update?:ResolverInputTypes["BlogCategoriesFields_name_Update"],
	delete?:ResolverInputTypes["BlogCategoriesFields_name_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["BlogCategoriesFields_name_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BlogCategoriesFields_name_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BlogCategoriesFields_name_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BlogCategoriesFields_name_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BlogCategoriesFields_value"]: AliasType<{
	create?:ResolverInputTypes["BlogCategoriesFields_value_Create"],
	read?:ResolverInputTypes["BlogCategoriesFields_value_Read"],
	update?:ResolverInputTypes["BlogCategoriesFields_value_Update"],
	delete?:ResolverInputTypes["BlogCategoriesFields_value_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["BlogCategoriesFields_value_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BlogCategoriesFields_value_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BlogCategoriesFields_value_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BlogCategoriesFields_value_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BlogCategoriesFields_icon"]: AliasType<{
	create?:ResolverInputTypes["BlogCategoriesFields_icon_Create"],
	read?:ResolverInputTypes["BlogCategoriesFields_icon_Read"],
	update?:ResolverInputTypes["BlogCategoriesFields_icon_Update"],
	delete?:ResolverInputTypes["BlogCategoriesFields_icon_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["BlogCategoriesFields_icon_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BlogCategoriesFields_icon_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BlogCategoriesFields_icon_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BlogCategoriesFields_icon_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BlogCategoriesFields_region"]: AliasType<{
	create?:ResolverInputTypes["BlogCategoriesFields_region_Create"],
	read?:ResolverInputTypes["BlogCategoriesFields_region_Read"],
	update?:ResolverInputTypes["BlogCategoriesFields_region_Update"],
	delete?:ResolverInputTypes["BlogCategoriesFields_region_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["BlogCategoriesFields_region_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BlogCategoriesFields_region_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BlogCategoriesFields_region_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BlogCategoriesFields_region_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BlogCategoriesFields_updatedAt"]: AliasType<{
	create?:ResolverInputTypes["BlogCategoriesFields_updatedAt_Create"],
	read?:ResolverInputTypes["BlogCategoriesFields_updatedAt_Read"],
	update?:ResolverInputTypes["BlogCategoriesFields_updatedAt_Update"],
	delete?:ResolverInputTypes["BlogCategoriesFields_updatedAt_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["BlogCategoriesFields_updatedAt_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BlogCategoriesFields_updatedAt_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BlogCategoriesFields_updatedAt_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BlogCategoriesFields_updatedAt_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BlogCategoriesFields_createdAt"]: AliasType<{
	create?:ResolverInputTypes["BlogCategoriesFields_createdAt_Create"],
	read?:ResolverInputTypes["BlogCategoriesFields_createdAt_Read"],
	update?:ResolverInputTypes["BlogCategoriesFields_createdAt_Update"],
	delete?:ResolverInputTypes["BlogCategoriesFields_createdAt_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["BlogCategoriesFields_createdAt_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BlogCategoriesFields_createdAt_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BlogCategoriesFields_createdAt_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BlogCategoriesFields_createdAt_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BlogCategoriesCreateAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BlogCategoriesReadAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BlogCategoriesUpdateAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BlogCategoriesDeleteAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["tagsAccess"]: AliasType<{
	fields?:ResolverInputTypes["TagsFields"],
	create?:ResolverInputTypes["TagsCreateAccess"],
	read?:ResolverInputTypes["TagsReadAccess"],
	update?:ResolverInputTypes["TagsUpdateAccess"],
	delete?:ResolverInputTypes["TagsDeleteAccess"],
		__typename?: boolean | `@${string}`
}>;
	["TagsFields"]: AliasType<{
	name?:ResolverInputTypes["TagsFields_name"],
	value?:ResolverInputTypes["TagsFields_value"],
	region?:ResolverInputTypes["TagsFields_region"],
	count?:ResolverInputTypes["TagsFields_count"],
	skipCountCheck?:ResolverInputTypes["TagsFields_skipCountCheck"],
	removeNextCron?:ResolverInputTypes["TagsFields_removeNextCron"],
		__typename?: boolean | `@${string}`
}>;
	["TagsFields_name"]: AliasType<{
	create?:ResolverInputTypes["TagsFields_name_Create"],
	read?:ResolverInputTypes["TagsFields_name_Read"],
	update?:ResolverInputTypes["TagsFields_name_Update"],
	delete?:ResolverInputTypes["TagsFields_name_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["TagsFields_name_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TagsFields_name_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TagsFields_name_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TagsFields_name_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TagsFields_value"]: AliasType<{
	create?:ResolverInputTypes["TagsFields_value_Create"],
	read?:ResolverInputTypes["TagsFields_value_Read"],
	update?:ResolverInputTypes["TagsFields_value_Update"],
	delete?:ResolverInputTypes["TagsFields_value_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["TagsFields_value_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TagsFields_value_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TagsFields_value_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TagsFields_value_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TagsFields_region"]: AliasType<{
	create?:ResolverInputTypes["TagsFields_region_Create"],
	read?:ResolverInputTypes["TagsFields_region_Read"],
	update?:ResolverInputTypes["TagsFields_region_Update"],
	delete?:ResolverInputTypes["TagsFields_region_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["TagsFields_region_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TagsFields_region_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TagsFields_region_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TagsFields_region_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TagsFields_count"]: AliasType<{
	create?:ResolverInputTypes["TagsFields_count_Create"],
	read?:ResolverInputTypes["TagsFields_count_Read"],
	update?:ResolverInputTypes["TagsFields_count_Update"],
	delete?:ResolverInputTypes["TagsFields_count_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["TagsFields_count_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TagsFields_count_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TagsFields_count_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TagsFields_count_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TagsFields_skipCountCheck"]: AliasType<{
	create?:ResolverInputTypes["TagsFields_skipCountCheck_Create"],
	read?:ResolverInputTypes["TagsFields_skipCountCheck_Read"],
	update?:ResolverInputTypes["TagsFields_skipCountCheck_Update"],
	delete?:ResolverInputTypes["TagsFields_skipCountCheck_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["TagsFields_skipCountCheck_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TagsFields_skipCountCheck_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TagsFields_skipCountCheck_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TagsFields_skipCountCheck_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TagsFields_removeNextCron"]: AliasType<{
	create?:ResolverInputTypes["TagsFields_removeNextCron_Create"],
	read?:ResolverInputTypes["TagsFields_removeNextCron_Read"],
	update?:ResolverInputTypes["TagsFields_removeNextCron_Update"],
	delete?:ResolverInputTypes["TagsFields_removeNextCron_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["TagsFields_removeNextCron_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TagsFields_removeNextCron_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TagsFields_removeNextCron_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TagsFields_removeNextCron_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TagsCreateAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TagsReadAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TagsUpdateAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TagsDeleteAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["specialTagsAccess"]: AliasType<{
	fields?:ResolverInputTypes["SpecialTagsFields"],
	create?:ResolverInputTypes["SpecialTagsCreateAccess"],
	read?:ResolverInputTypes["SpecialTagsReadAccess"],
	update?:ResolverInputTypes["SpecialTagsUpdateAccess"],
	delete?:ResolverInputTypes["SpecialTagsDeleteAccess"],
		__typename?: boolean | `@${string}`
}>;
	["SpecialTagsFields"]: AliasType<{
	name?:ResolverInputTypes["SpecialTagsFields_name"],
	value?:ResolverInputTypes["SpecialTagsFields_value"],
	region?:ResolverInputTypes["SpecialTagsFields_region"],
		__typename?: boolean | `@${string}`
}>;
	["SpecialTagsFields_name"]: AliasType<{
	create?:ResolverInputTypes["SpecialTagsFields_name_Create"],
	read?:ResolverInputTypes["SpecialTagsFields_name_Read"],
	update?:ResolverInputTypes["SpecialTagsFields_name_Update"],
	delete?:ResolverInputTypes["SpecialTagsFields_name_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["SpecialTagsFields_name_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["SpecialTagsFields_name_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["SpecialTagsFields_name_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["SpecialTagsFields_name_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["SpecialTagsFields_value"]: AliasType<{
	create?:ResolverInputTypes["SpecialTagsFields_value_Create"],
	read?:ResolverInputTypes["SpecialTagsFields_value_Read"],
	update?:ResolverInputTypes["SpecialTagsFields_value_Update"],
	delete?:ResolverInputTypes["SpecialTagsFields_value_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["SpecialTagsFields_value_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["SpecialTagsFields_value_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["SpecialTagsFields_value_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["SpecialTagsFields_value_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["SpecialTagsFields_region"]: AliasType<{
	create?:ResolverInputTypes["SpecialTagsFields_region_Create"],
	read?:ResolverInputTypes["SpecialTagsFields_region_Read"],
	update?:ResolverInputTypes["SpecialTagsFields_region_Update"],
	delete?:ResolverInputTypes["SpecialTagsFields_region_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["SpecialTagsFields_region_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["SpecialTagsFields_region_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["SpecialTagsFields_region_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["SpecialTagsFields_region_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["SpecialTagsCreateAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["SpecialTagsReadAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["SpecialTagsUpdateAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["SpecialTagsDeleteAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["dateAccess"]: AliasType<{
	fields?:ResolverInputTypes["DateFields"],
	create?:ResolverInputTypes["DateCreateAccess"],
	read?:ResolverInputTypes["DateReadAccess"],
	update?:ResolverInputTypes["DateUpdateAccess"],
	delete?:ResolverInputTypes["DateDeleteAccess"],
		__typename?: boolean | `@${string}`
}>;
	["DateFields"]: AliasType<{
	name?:ResolverInputTypes["DateFields_name"],
	value?:ResolverInputTypes["DateFields_value"],
	type?:ResolverInputTypes["DateFields_type"],
	start?:ResolverInputTypes["DateFields_start"],
	end?:ResolverInputTypes["DateFields_end"],
	_title?:ResolverInputTypes["DateFields__title"],
	region?:ResolverInputTypes["DateFields_region"],
	updatedAt?:ResolverInputTypes["DateFields_updatedAt"],
	createdAt?:ResolverInputTypes["DateFields_createdAt"],
		__typename?: boolean | `@${string}`
}>;
	["DateFields_name"]: AliasType<{
	create?:ResolverInputTypes["DateFields_name_Create"],
	read?:ResolverInputTypes["DateFields_name_Read"],
	update?:ResolverInputTypes["DateFields_name_Update"],
	delete?:ResolverInputTypes["DateFields_name_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["DateFields_name_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DateFields_name_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DateFields_name_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DateFields_name_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DateFields_value"]: AliasType<{
	create?:ResolverInputTypes["DateFields_value_Create"],
	read?:ResolverInputTypes["DateFields_value_Read"],
	update?:ResolverInputTypes["DateFields_value_Update"],
	delete?:ResolverInputTypes["DateFields_value_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["DateFields_value_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DateFields_value_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DateFields_value_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DateFields_value_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DateFields_type"]: AliasType<{
	create?:ResolverInputTypes["DateFields_type_Create"],
	read?:ResolverInputTypes["DateFields_type_Read"],
	update?:ResolverInputTypes["DateFields_type_Update"],
	delete?:ResolverInputTypes["DateFields_type_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["DateFields_type_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DateFields_type_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DateFields_type_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DateFields_type_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DateFields_start"]: AliasType<{
	create?:ResolverInputTypes["DateFields_start_Create"],
	read?:ResolverInputTypes["DateFields_start_Read"],
	update?:ResolverInputTypes["DateFields_start_Update"],
	delete?:ResolverInputTypes["DateFields_start_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["DateFields_start_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DateFields_start_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DateFields_start_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DateFields_start_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DateFields_end"]: AliasType<{
	create?:ResolverInputTypes["DateFields_end_Create"],
	read?:ResolverInputTypes["DateFields_end_Read"],
	update?:ResolverInputTypes["DateFields_end_Update"],
	delete?:ResolverInputTypes["DateFields_end_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["DateFields_end_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DateFields_end_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DateFields_end_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DateFields_end_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DateFields__title"]: AliasType<{
	create?:ResolverInputTypes["DateFields__title_Create"],
	read?:ResolverInputTypes["DateFields__title_Read"],
	update?:ResolverInputTypes["DateFields__title_Update"],
	delete?:ResolverInputTypes["DateFields__title_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["DateFields__title_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DateFields__title_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DateFields__title_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DateFields__title_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DateFields_region"]: AliasType<{
	create?:ResolverInputTypes["DateFields_region_Create"],
	read?:ResolverInputTypes["DateFields_region_Read"],
	update?:ResolverInputTypes["DateFields_region_Update"],
	delete?:ResolverInputTypes["DateFields_region_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["DateFields_region_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DateFields_region_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DateFields_region_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DateFields_region_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DateFields_updatedAt"]: AliasType<{
	create?:ResolverInputTypes["DateFields_updatedAt_Create"],
	read?:ResolverInputTypes["DateFields_updatedAt_Read"],
	update?:ResolverInputTypes["DateFields_updatedAt_Update"],
	delete?:ResolverInputTypes["DateFields_updatedAt_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["DateFields_updatedAt_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DateFields_updatedAt_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DateFields_updatedAt_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DateFields_updatedAt_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DateFields_createdAt"]: AliasType<{
	create?:ResolverInputTypes["DateFields_createdAt_Create"],
	read?:ResolverInputTypes["DateFields_createdAt_Read"],
	update?:ResolverInputTypes["DateFields_createdAt_Update"],
	delete?:ResolverInputTypes["DateFields_createdAt_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["DateFields_createdAt_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DateFields_createdAt_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DateFields_createdAt_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DateFields_createdAt_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DateCreateAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DateReadAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DateUpdateAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DateDeleteAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["districtsAccess"]: AliasType<{
	fields?:ResolverInputTypes["DistrictsFields"],
	create?:ResolverInputTypes["DistrictsCreateAccess"],
	read?:ResolverInputTypes["DistrictsReadAccess"],
	update?:ResolverInputTypes["DistrictsUpdateAccess"],
	delete?:ResolverInputTypes["DistrictsDeleteAccess"],
		__typename?: boolean | `@${string}`
}>;
	["DistrictsFields"]: AliasType<{
	name?:ResolverInputTypes["DistrictsFields_name"],
	level?:ResolverInputTypes["DistrictsFields_level"],
	children?:ResolverInputTypes["DistrictsFields_children"],
	value?:ResolverInputTypes["DistrictsFields_value"],
	lat?:ResolverInputTypes["DistrictsFields_lat"],
	long?:ResolverInputTypes["DistrictsFields_long"],
	radius?:ResolverInputTypes["DistrictsFields_radius"],
	region?:ResolverInputTypes["DistrictsFields_region"],
	updatedAt?:ResolverInputTypes["DistrictsFields_updatedAt"],
	createdAt?:ResolverInputTypes["DistrictsFields_createdAt"],
		__typename?: boolean | `@${string}`
}>;
	["DistrictsFields_name"]: AliasType<{
	create?:ResolverInputTypes["DistrictsFields_name_Create"],
	read?:ResolverInputTypes["DistrictsFields_name_Read"],
	update?:ResolverInputTypes["DistrictsFields_name_Update"],
	delete?:ResolverInputTypes["DistrictsFields_name_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["DistrictsFields_name_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DistrictsFields_name_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DistrictsFields_name_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DistrictsFields_name_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DistrictsFields_level"]: AliasType<{
	create?:ResolverInputTypes["DistrictsFields_level_Create"],
	read?:ResolverInputTypes["DistrictsFields_level_Read"],
	update?:ResolverInputTypes["DistrictsFields_level_Update"],
	delete?:ResolverInputTypes["DistrictsFields_level_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["DistrictsFields_level_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DistrictsFields_level_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DistrictsFields_level_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DistrictsFields_level_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DistrictsFields_children"]: AliasType<{
	create?:ResolverInputTypes["DistrictsFields_children_Create"],
	read?:ResolverInputTypes["DistrictsFields_children_Read"],
	update?:ResolverInputTypes["DistrictsFields_children_Update"],
	delete?:ResolverInputTypes["DistrictsFields_children_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["DistrictsFields_children_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DistrictsFields_children_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DistrictsFields_children_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DistrictsFields_children_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DistrictsFields_value"]: AliasType<{
	create?:ResolverInputTypes["DistrictsFields_value_Create"],
	read?:ResolverInputTypes["DistrictsFields_value_Read"],
	update?:ResolverInputTypes["DistrictsFields_value_Update"],
	delete?:ResolverInputTypes["DistrictsFields_value_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["DistrictsFields_value_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DistrictsFields_value_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DistrictsFields_value_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DistrictsFields_value_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DistrictsFields_lat"]: AliasType<{
	create?:ResolverInputTypes["DistrictsFields_lat_Create"],
	read?:ResolverInputTypes["DistrictsFields_lat_Read"],
	update?:ResolverInputTypes["DistrictsFields_lat_Update"],
	delete?:ResolverInputTypes["DistrictsFields_lat_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["DistrictsFields_lat_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DistrictsFields_lat_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DistrictsFields_lat_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DistrictsFields_lat_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DistrictsFields_long"]: AliasType<{
	create?:ResolverInputTypes["DistrictsFields_long_Create"],
	read?:ResolverInputTypes["DistrictsFields_long_Read"],
	update?:ResolverInputTypes["DistrictsFields_long_Update"],
	delete?:ResolverInputTypes["DistrictsFields_long_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["DistrictsFields_long_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DistrictsFields_long_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DistrictsFields_long_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DistrictsFields_long_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DistrictsFields_radius"]: AliasType<{
	create?:ResolverInputTypes["DistrictsFields_radius_Create"],
	read?:ResolverInputTypes["DistrictsFields_radius_Read"],
	update?:ResolverInputTypes["DistrictsFields_radius_Update"],
	delete?:ResolverInputTypes["DistrictsFields_radius_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["DistrictsFields_radius_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DistrictsFields_radius_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DistrictsFields_radius_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DistrictsFields_radius_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DistrictsFields_region"]: AliasType<{
	create?:ResolverInputTypes["DistrictsFields_region_Create"],
	read?:ResolverInputTypes["DistrictsFields_region_Read"],
	update?:ResolverInputTypes["DistrictsFields_region_Update"],
	delete?:ResolverInputTypes["DistrictsFields_region_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["DistrictsFields_region_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DistrictsFields_region_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DistrictsFields_region_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DistrictsFields_region_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DistrictsFields_updatedAt"]: AliasType<{
	create?:ResolverInputTypes["DistrictsFields_updatedAt_Create"],
	read?:ResolverInputTypes["DistrictsFields_updatedAt_Read"],
	update?:ResolverInputTypes["DistrictsFields_updatedAt_Update"],
	delete?:ResolverInputTypes["DistrictsFields_updatedAt_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["DistrictsFields_updatedAt_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DistrictsFields_updatedAt_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DistrictsFields_updatedAt_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DistrictsFields_updatedAt_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DistrictsFields_createdAt"]: AliasType<{
	create?:ResolverInputTypes["DistrictsFields_createdAt_Create"],
	read?:ResolverInputTypes["DistrictsFields_createdAt_Read"],
	update?:ResolverInputTypes["DistrictsFields_createdAt_Update"],
	delete?:ResolverInputTypes["DistrictsFields_createdAt_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["DistrictsFields_createdAt_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DistrictsFields_createdAt_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DistrictsFields_createdAt_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DistrictsFields_createdAt_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DistrictsCreateAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DistrictsReadAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DistrictsUpdateAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DistrictsDeleteAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["audienceAccess"]: AliasType<{
	fields?:ResolverInputTypes["AudienceFields"],
	create?:ResolverInputTypes["AudienceCreateAccess"],
	read?:ResolverInputTypes["AudienceReadAccess"],
	update?:ResolverInputTypes["AudienceUpdateAccess"],
	delete?:ResolverInputTypes["AudienceDeleteAccess"],
		__typename?: boolean | `@${string}`
}>;
	["AudienceFields"]: AliasType<{
	name?:ResolverInputTypes["AudienceFields_name"],
	value?:ResolverInputTypes["AudienceFields_value"],
	icon?:ResolverInputTypes["AudienceFields_icon"],
	region?:ResolverInputTypes["AudienceFields_region"],
	updatedAt?:ResolverInputTypes["AudienceFields_updatedAt"],
	createdAt?:ResolverInputTypes["AudienceFields_createdAt"],
		__typename?: boolean | `@${string}`
}>;
	["AudienceFields_name"]: AliasType<{
	create?:ResolverInputTypes["AudienceFields_name_Create"],
	read?:ResolverInputTypes["AudienceFields_name_Read"],
	update?:ResolverInputTypes["AudienceFields_name_Update"],
	delete?:ResolverInputTypes["AudienceFields_name_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["AudienceFields_name_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AudienceFields_name_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AudienceFields_name_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AudienceFields_name_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AudienceFields_value"]: AliasType<{
	create?:ResolverInputTypes["AudienceFields_value_Create"],
	read?:ResolverInputTypes["AudienceFields_value_Read"],
	update?:ResolverInputTypes["AudienceFields_value_Update"],
	delete?:ResolverInputTypes["AudienceFields_value_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["AudienceFields_value_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AudienceFields_value_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AudienceFields_value_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AudienceFields_value_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AudienceFields_icon"]: AliasType<{
	create?:ResolverInputTypes["AudienceFields_icon_Create"],
	read?:ResolverInputTypes["AudienceFields_icon_Read"],
	update?:ResolverInputTypes["AudienceFields_icon_Update"],
	delete?:ResolverInputTypes["AudienceFields_icon_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["AudienceFields_icon_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AudienceFields_icon_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AudienceFields_icon_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AudienceFields_icon_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AudienceFields_region"]: AliasType<{
	create?:ResolverInputTypes["AudienceFields_region_Create"],
	read?:ResolverInputTypes["AudienceFields_region_Read"],
	update?:ResolverInputTypes["AudienceFields_region_Update"],
	delete?:ResolverInputTypes["AudienceFields_region_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["AudienceFields_region_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AudienceFields_region_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AudienceFields_region_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AudienceFields_region_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AudienceFields_updatedAt"]: AliasType<{
	create?:ResolverInputTypes["AudienceFields_updatedAt_Create"],
	read?:ResolverInputTypes["AudienceFields_updatedAt_Read"],
	update?:ResolverInputTypes["AudienceFields_updatedAt_Update"],
	delete?:ResolverInputTypes["AudienceFields_updatedAt_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["AudienceFields_updatedAt_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AudienceFields_updatedAt_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AudienceFields_updatedAt_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AudienceFields_updatedAt_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AudienceFields_createdAt"]: AliasType<{
	create?:ResolverInputTypes["AudienceFields_createdAt_Create"],
	read?:ResolverInputTypes["AudienceFields_createdAt_Read"],
	update?:ResolverInputTypes["AudienceFields_createdAt_Update"],
	delete?:ResolverInputTypes["AudienceFields_createdAt_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["AudienceFields_createdAt_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AudienceFields_createdAt_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AudienceFields_createdAt_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AudienceFields_createdAt_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AudienceCreateAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AudienceReadAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AudienceUpdateAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AudienceDeleteAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["categoryAccess"]: AliasType<{
	fields?:ResolverInputTypes["CategoryFields"],
	create?:ResolverInputTypes["CategoryCreateAccess"],
	read?:ResolverInputTypes["CategoryReadAccess"],
	update?:ResolverInputTypes["CategoryUpdateAccess"],
	delete?:ResolverInputTypes["CategoryDeleteAccess"],
		__typename?: boolean | `@${string}`
}>;
	["CategoryFields"]: AliasType<{
	name?:ResolverInputTypes["CategoryFields_name"],
	value?:ResolverInputTypes["CategoryFields_value"],
	tags?:ResolverInputTypes["CategoryFields_tags"],
	icon?:ResolverInputTypes["CategoryFields_icon"],
	region?:ResolverInputTypes["CategoryFields_region"],
	updatedAt?:ResolverInputTypes["CategoryFields_updatedAt"],
	createdAt?:ResolverInputTypes["CategoryFields_createdAt"],
		__typename?: boolean | `@${string}`
}>;
	["CategoryFields_name"]: AliasType<{
	create?:ResolverInputTypes["CategoryFields_name_Create"],
	read?:ResolverInputTypes["CategoryFields_name_Read"],
	update?:ResolverInputTypes["CategoryFields_name_Update"],
	delete?:ResolverInputTypes["CategoryFields_name_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["CategoryFields_name_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CategoryFields_name_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CategoryFields_name_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CategoryFields_name_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CategoryFields_value"]: AliasType<{
	create?:ResolverInputTypes["CategoryFields_value_Create"],
	read?:ResolverInputTypes["CategoryFields_value_Read"],
	update?:ResolverInputTypes["CategoryFields_value_Update"],
	delete?:ResolverInputTypes["CategoryFields_value_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["CategoryFields_value_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CategoryFields_value_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CategoryFields_value_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CategoryFields_value_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CategoryFields_tags"]: AliasType<{
	create?:ResolverInputTypes["CategoryFields_tags_Create"],
	read?:ResolverInputTypes["CategoryFields_tags_Read"],
	update?:ResolverInputTypes["CategoryFields_tags_Update"],
	delete?:ResolverInputTypes["CategoryFields_tags_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["CategoryFields_tags_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CategoryFields_tags_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CategoryFields_tags_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CategoryFields_tags_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CategoryFields_icon"]: AliasType<{
	create?:ResolverInputTypes["CategoryFields_icon_Create"],
	read?:ResolverInputTypes["CategoryFields_icon_Read"],
	update?:ResolverInputTypes["CategoryFields_icon_Update"],
	delete?:ResolverInputTypes["CategoryFields_icon_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["CategoryFields_icon_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CategoryFields_icon_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CategoryFields_icon_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CategoryFields_icon_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CategoryFields_region"]: AliasType<{
	create?:ResolverInputTypes["CategoryFields_region_Create"],
	read?:ResolverInputTypes["CategoryFields_region_Read"],
	update?:ResolverInputTypes["CategoryFields_region_Update"],
	delete?:ResolverInputTypes["CategoryFields_region_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["CategoryFields_region_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CategoryFields_region_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CategoryFields_region_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CategoryFields_region_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CategoryFields_updatedAt"]: AliasType<{
	create?:ResolverInputTypes["CategoryFields_updatedAt_Create"],
	read?:ResolverInputTypes["CategoryFields_updatedAt_Read"],
	update?:ResolverInputTypes["CategoryFields_updatedAt_Update"],
	delete?:ResolverInputTypes["CategoryFields_updatedAt_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["CategoryFields_updatedAt_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CategoryFields_updatedAt_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CategoryFields_updatedAt_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CategoryFields_updatedAt_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CategoryFields_createdAt"]: AliasType<{
	create?:ResolverInputTypes["CategoryFields_createdAt_Create"],
	read?:ResolverInputTypes["CategoryFields_createdAt_Read"],
	update?:ResolverInputTypes["CategoryFields_createdAt_Update"],
	delete?:ResolverInputTypes["CategoryFields_createdAt_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["CategoryFields_createdAt_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CategoryFields_createdAt_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CategoryFields_createdAt_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CategoryFields_createdAt_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CategoryCreateAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CategoryReadAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CategoryUpdateAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CategoryDeleteAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["private_mediaAccess"]: AliasType<{
	fields?:ResolverInputTypes["PrivateMediaFields"],
	create?:ResolverInputTypes["PrivateMediaCreateAccess"],
	read?:ResolverInputTypes["PrivateMediaReadAccess"],
	update?:ResolverInputTypes["PrivateMediaUpdateAccess"],
	delete?:ResolverInputTypes["PrivateMediaDeleteAccess"],
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields"]: AliasType<{
	alt?:ResolverInputTypes["PrivateMediaFields_alt"],
	updatedAt?:ResolverInputTypes["PrivateMediaFields_updatedAt"],
	createdAt?:ResolverInputTypes["PrivateMediaFields_createdAt"],
	url?:ResolverInputTypes["PrivateMediaFields_url"],
	filename?:ResolverInputTypes["PrivateMediaFields_filename"],
	mimeType?:ResolverInputTypes["PrivateMediaFields_mimeType"],
	filesize?:ResolverInputTypes["PrivateMediaFields_filesize"],
	width?:ResolverInputTypes["PrivateMediaFields_width"],
	height?:ResolverInputTypes["PrivateMediaFields_height"],
	focalX?:ResolverInputTypes["PrivateMediaFields_focalX"],
	focalY?:ResolverInputTypes["PrivateMediaFields_focalY"],
	sizes?:ResolverInputTypes["PrivateMediaFields_sizes"],
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_alt"]: AliasType<{
	create?:ResolverInputTypes["PrivateMediaFields_alt_Create"],
	read?:ResolverInputTypes["PrivateMediaFields_alt_Read"],
	update?:ResolverInputTypes["PrivateMediaFields_alt_Update"],
	delete?:ResolverInputTypes["PrivateMediaFields_alt_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_alt_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_alt_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_alt_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_alt_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_updatedAt"]: AliasType<{
	create?:ResolverInputTypes["PrivateMediaFields_updatedAt_Create"],
	read?:ResolverInputTypes["PrivateMediaFields_updatedAt_Read"],
	update?:ResolverInputTypes["PrivateMediaFields_updatedAt_Update"],
	delete?:ResolverInputTypes["PrivateMediaFields_updatedAt_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_updatedAt_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_updatedAt_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_updatedAt_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_updatedAt_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_createdAt"]: AliasType<{
	create?:ResolverInputTypes["PrivateMediaFields_createdAt_Create"],
	read?:ResolverInputTypes["PrivateMediaFields_createdAt_Read"],
	update?:ResolverInputTypes["PrivateMediaFields_createdAt_Update"],
	delete?:ResolverInputTypes["PrivateMediaFields_createdAt_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_createdAt_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_createdAt_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_createdAt_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_createdAt_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_url"]: AliasType<{
	create?:ResolverInputTypes["PrivateMediaFields_url_Create"],
	read?:ResolverInputTypes["PrivateMediaFields_url_Read"],
	update?:ResolverInputTypes["PrivateMediaFields_url_Update"],
	delete?:ResolverInputTypes["PrivateMediaFields_url_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_url_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_url_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_url_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_url_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_filename"]: AliasType<{
	create?:ResolverInputTypes["PrivateMediaFields_filename_Create"],
	read?:ResolverInputTypes["PrivateMediaFields_filename_Read"],
	update?:ResolverInputTypes["PrivateMediaFields_filename_Update"],
	delete?:ResolverInputTypes["PrivateMediaFields_filename_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_filename_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_filename_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_filename_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_filename_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_mimeType"]: AliasType<{
	create?:ResolverInputTypes["PrivateMediaFields_mimeType_Create"],
	read?:ResolverInputTypes["PrivateMediaFields_mimeType_Read"],
	update?:ResolverInputTypes["PrivateMediaFields_mimeType_Update"],
	delete?:ResolverInputTypes["PrivateMediaFields_mimeType_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_mimeType_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_mimeType_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_mimeType_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_mimeType_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_filesize"]: AliasType<{
	create?:ResolverInputTypes["PrivateMediaFields_filesize_Create"],
	read?:ResolverInputTypes["PrivateMediaFields_filesize_Read"],
	update?:ResolverInputTypes["PrivateMediaFields_filesize_Update"],
	delete?:ResolverInputTypes["PrivateMediaFields_filesize_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_filesize_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_filesize_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_filesize_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_filesize_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_width"]: AliasType<{
	create?:ResolverInputTypes["PrivateMediaFields_width_Create"],
	read?:ResolverInputTypes["PrivateMediaFields_width_Read"],
	update?:ResolverInputTypes["PrivateMediaFields_width_Update"],
	delete?:ResolverInputTypes["PrivateMediaFields_width_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_width_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_width_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_width_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_width_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_height"]: AliasType<{
	create?:ResolverInputTypes["PrivateMediaFields_height_Create"],
	read?:ResolverInputTypes["PrivateMediaFields_height_Read"],
	update?:ResolverInputTypes["PrivateMediaFields_height_Update"],
	delete?:ResolverInputTypes["PrivateMediaFields_height_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_height_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_height_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_height_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_height_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_focalX"]: AliasType<{
	create?:ResolverInputTypes["PrivateMediaFields_focalX_Create"],
	read?:ResolverInputTypes["PrivateMediaFields_focalX_Read"],
	update?:ResolverInputTypes["PrivateMediaFields_focalX_Update"],
	delete?:ResolverInputTypes["PrivateMediaFields_focalX_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_focalX_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_focalX_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_focalX_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_focalX_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_focalY"]: AliasType<{
	create?:ResolverInputTypes["PrivateMediaFields_focalY_Create"],
	read?:ResolverInputTypes["PrivateMediaFields_focalY_Read"],
	update?:ResolverInputTypes["PrivateMediaFields_focalY_Update"],
	delete?:ResolverInputTypes["PrivateMediaFields_focalY_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_focalY_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_focalY_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_focalY_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_focalY_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes"]: AliasType<{
	create?:ResolverInputTypes["PrivateMediaFields_sizes_Create"],
	read?:ResolverInputTypes["PrivateMediaFields_sizes_Read"],
	update?:ResolverInputTypes["PrivateMediaFields_sizes_Update"],
	delete?:ResolverInputTypes["PrivateMediaFields_sizes_Delete"],
	fields?:ResolverInputTypes["PrivateMediaFields_sizes_Fields"],
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_Fields"]: AliasType<{
	thumbnail?:ResolverInputTypes["PrivateMediaFields_sizes_thumbnail"],
	portrait?:ResolverInputTypes["PrivateMediaFields_sizes_portrait"],
	hero?:ResolverInputTypes["PrivateMediaFields_sizes_hero"],
	thumbnail_jpeg?:ResolverInputTypes["PrivateMediaFields_sizes_thumbnail_jpeg"],
	portrait_jpeg?:ResolverInputTypes["PrivateMediaFields_sizes_portrait_jpeg"],
	hero_jpeg?:ResolverInputTypes["PrivateMediaFields_sizes_hero_jpeg"],
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_thumbnail"]: AliasType<{
	create?:ResolverInputTypes["PrivateMediaFields_sizes_thumbnail_Create"],
	read?:ResolverInputTypes["PrivateMediaFields_sizes_thumbnail_Read"],
	update?:ResolverInputTypes["PrivateMediaFields_sizes_thumbnail_Update"],
	delete?:ResolverInputTypes["PrivateMediaFields_sizes_thumbnail_Delete"],
	fields?:ResolverInputTypes["PrivateMediaFields_sizes_thumbnail_Fields"],
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_thumbnail_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_thumbnail_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_thumbnail_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_thumbnail_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_thumbnail_Fields"]: AliasType<{
	url?:ResolverInputTypes["PrivateMediaFields_sizes_thumbnail_url"],
	width?:ResolverInputTypes["PrivateMediaFields_sizes_thumbnail_width"],
	height?:ResolverInputTypes["PrivateMediaFields_sizes_thumbnail_height"],
	mimeType?:ResolverInputTypes["PrivateMediaFields_sizes_thumbnail_mimeType"],
	filesize?:ResolverInputTypes["PrivateMediaFields_sizes_thumbnail_filesize"],
	filename?:ResolverInputTypes["PrivateMediaFields_sizes_thumbnail_filename"],
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_thumbnail_url"]: AliasType<{
	create?:ResolverInputTypes["PrivateMediaFields_sizes_thumbnail_url_Create"],
	read?:ResolverInputTypes["PrivateMediaFields_sizes_thumbnail_url_Read"],
	update?:ResolverInputTypes["PrivateMediaFields_sizes_thumbnail_url_Update"],
	delete?:ResolverInputTypes["PrivateMediaFields_sizes_thumbnail_url_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_thumbnail_url_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_thumbnail_url_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_thumbnail_url_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_thumbnail_url_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_thumbnail_width"]: AliasType<{
	create?:ResolverInputTypes["PrivateMediaFields_sizes_thumbnail_width_Create"],
	read?:ResolverInputTypes["PrivateMediaFields_sizes_thumbnail_width_Read"],
	update?:ResolverInputTypes["PrivateMediaFields_sizes_thumbnail_width_Update"],
	delete?:ResolverInputTypes["PrivateMediaFields_sizes_thumbnail_width_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_thumbnail_width_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_thumbnail_width_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_thumbnail_width_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_thumbnail_width_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_thumbnail_height"]: AliasType<{
	create?:ResolverInputTypes["PrivateMediaFields_sizes_thumbnail_height_Create"],
	read?:ResolverInputTypes["PrivateMediaFields_sizes_thumbnail_height_Read"],
	update?:ResolverInputTypes["PrivateMediaFields_sizes_thumbnail_height_Update"],
	delete?:ResolverInputTypes["PrivateMediaFields_sizes_thumbnail_height_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_thumbnail_height_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_thumbnail_height_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_thumbnail_height_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_thumbnail_height_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_thumbnail_mimeType"]: AliasType<{
	create?:ResolverInputTypes["PrivateMediaFields_sizes_thumbnail_mimeType_Create"],
	read?:ResolverInputTypes["PrivateMediaFields_sizes_thumbnail_mimeType_Read"],
	update?:ResolverInputTypes["PrivateMediaFields_sizes_thumbnail_mimeType_Update"],
	delete?:ResolverInputTypes["PrivateMediaFields_sizes_thumbnail_mimeType_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_thumbnail_mimeType_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_thumbnail_mimeType_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_thumbnail_mimeType_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_thumbnail_mimeType_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_thumbnail_filesize"]: AliasType<{
	create?:ResolverInputTypes["PrivateMediaFields_sizes_thumbnail_filesize_Create"],
	read?:ResolverInputTypes["PrivateMediaFields_sizes_thumbnail_filesize_Read"],
	update?:ResolverInputTypes["PrivateMediaFields_sizes_thumbnail_filesize_Update"],
	delete?:ResolverInputTypes["PrivateMediaFields_sizes_thumbnail_filesize_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_thumbnail_filesize_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_thumbnail_filesize_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_thumbnail_filesize_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_thumbnail_filesize_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_thumbnail_filename"]: AliasType<{
	create?:ResolverInputTypes["PrivateMediaFields_sizes_thumbnail_filename_Create"],
	read?:ResolverInputTypes["PrivateMediaFields_sizes_thumbnail_filename_Read"],
	update?:ResolverInputTypes["PrivateMediaFields_sizes_thumbnail_filename_Update"],
	delete?:ResolverInputTypes["PrivateMediaFields_sizes_thumbnail_filename_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_thumbnail_filename_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_thumbnail_filename_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_thumbnail_filename_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_thumbnail_filename_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_portrait"]: AliasType<{
	create?:ResolverInputTypes["PrivateMediaFields_sizes_portrait_Create"],
	read?:ResolverInputTypes["PrivateMediaFields_sizes_portrait_Read"],
	update?:ResolverInputTypes["PrivateMediaFields_sizes_portrait_Update"],
	delete?:ResolverInputTypes["PrivateMediaFields_sizes_portrait_Delete"],
	fields?:ResolverInputTypes["PrivateMediaFields_sizes_portrait_Fields"],
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_portrait_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_portrait_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_portrait_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_portrait_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_portrait_Fields"]: AliasType<{
	url?:ResolverInputTypes["PrivateMediaFields_sizes_portrait_url"],
	width?:ResolverInputTypes["PrivateMediaFields_sizes_portrait_width"],
	height?:ResolverInputTypes["PrivateMediaFields_sizes_portrait_height"],
	mimeType?:ResolverInputTypes["PrivateMediaFields_sizes_portrait_mimeType"],
	filesize?:ResolverInputTypes["PrivateMediaFields_sizes_portrait_filesize"],
	filename?:ResolverInputTypes["PrivateMediaFields_sizes_portrait_filename"],
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_portrait_url"]: AliasType<{
	create?:ResolverInputTypes["PrivateMediaFields_sizes_portrait_url_Create"],
	read?:ResolverInputTypes["PrivateMediaFields_sizes_portrait_url_Read"],
	update?:ResolverInputTypes["PrivateMediaFields_sizes_portrait_url_Update"],
	delete?:ResolverInputTypes["PrivateMediaFields_sizes_portrait_url_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_portrait_url_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_portrait_url_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_portrait_url_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_portrait_url_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_portrait_width"]: AliasType<{
	create?:ResolverInputTypes["PrivateMediaFields_sizes_portrait_width_Create"],
	read?:ResolverInputTypes["PrivateMediaFields_sizes_portrait_width_Read"],
	update?:ResolverInputTypes["PrivateMediaFields_sizes_portrait_width_Update"],
	delete?:ResolverInputTypes["PrivateMediaFields_sizes_portrait_width_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_portrait_width_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_portrait_width_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_portrait_width_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_portrait_width_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_portrait_height"]: AliasType<{
	create?:ResolverInputTypes["PrivateMediaFields_sizes_portrait_height_Create"],
	read?:ResolverInputTypes["PrivateMediaFields_sizes_portrait_height_Read"],
	update?:ResolverInputTypes["PrivateMediaFields_sizes_portrait_height_Update"],
	delete?:ResolverInputTypes["PrivateMediaFields_sizes_portrait_height_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_portrait_height_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_portrait_height_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_portrait_height_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_portrait_height_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_portrait_mimeType"]: AliasType<{
	create?:ResolverInputTypes["PrivateMediaFields_sizes_portrait_mimeType_Create"],
	read?:ResolverInputTypes["PrivateMediaFields_sizes_portrait_mimeType_Read"],
	update?:ResolverInputTypes["PrivateMediaFields_sizes_portrait_mimeType_Update"],
	delete?:ResolverInputTypes["PrivateMediaFields_sizes_portrait_mimeType_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_portrait_mimeType_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_portrait_mimeType_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_portrait_mimeType_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_portrait_mimeType_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_portrait_filesize"]: AliasType<{
	create?:ResolverInputTypes["PrivateMediaFields_sizes_portrait_filesize_Create"],
	read?:ResolverInputTypes["PrivateMediaFields_sizes_portrait_filesize_Read"],
	update?:ResolverInputTypes["PrivateMediaFields_sizes_portrait_filesize_Update"],
	delete?:ResolverInputTypes["PrivateMediaFields_sizes_portrait_filesize_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_portrait_filesize_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_portrait_filesize_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_portrait_filesize_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_portrait_filesize_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_portrait_filename"]: AliasType<{
	create?:ResolverInputTypes["PrivateMediaFields_sizes_portrait_filename_Create"],
	read?:ResolverInputTypes["PrivateMediaFields_sizes_portrait_filename_Read"],
	update?:ResolverInputTypes["PrivateMediaFields_sizes_portrait_filename_Update"],
	delete?:ResolverInputTypes["PrivateMediaFields_sizes_portrait_filename_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_portrait_filename_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_portrait_filename_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_portrait_filename_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_portrait_filename_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_hero"]: AliasType<{
	create?:ResolverInputTypes["PrivateMediaFields_sizes_hero_Create"],
	read?:ResolverInputTypes["PrivateMediaFields_sizes_hero_Read"],
	update?:ResolverInputTypes["PrivateMediaFields_sizes_hero_Update"],
	delete?:ResolverInputTypes["PrivateMediaFields_sizes_hero_Delete"],
	fields?:ResolverInputTypes["PrivateMediaFields_sizes_hero_Fields"],
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_hero_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_hero_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_hero_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_hero_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_hero_Fields"]: AliasType<{
	url?:ResolverInputTypes["PrivateMediaFields_sizes_hero_url"],
	width?:ResolverInputTypes["PrivateMediaFields_sizes_hero_width"],
	height?:ResolverInputTypes["PrivateMediaFields_sizes_hero_height"],
	mimeType?:ResolverInputTypes["PrivateMediaFields_sizes_hero_mimeType"],
	filesize?:ResolverInputTypes["PrivateMediaFields_sizes_hero_filesize"],
	filename?:ResolverInputTypes["PrivateMediaFields_sizes_hero_filename"],
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_hero_url"]: AliasType<{
	create?:ResolverInputTypes["PrivateMediaFields_sizes_hero_url_Create"],
	read?:ResolverInputTypes["PrivateMediaFields_sizes_hero_url_Read"],
	update?:ResolverInputTypes["PrivateMediaFields_sizes_hero_url_Update"],
	delete?:ResolverInputTypes["PrivateMediaFields_sizes_hero_url_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_hero_url_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_hero_url_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_hero_url_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_hero_url_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_hero_width"]: AliasType<{
	create?:ResolverInputTypes["PrivateMediaFields_sizes_hero_width_Create"],
	read?:ResolverInputTypes["PrivateMediaFields_sizes_hero_width_Read"],
	update?:ResolverInputTypes["PrivateMediaFields_sizes_hero_width_Update"],
	delete?:ResolverInputTypes["PrivateMediaFields_sizes_hero_width_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_hero_width_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_hero_width_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_hero_width_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_hero_width_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_hero_height"]: AliasType<{
	create?:ResolverInputTypes["PrivateMediaFields_sizes_hero_height_Create"],
	read?:ResolverInputTypes["PrivateMediaFields_sizes_hero_height_Read"],
	update?:ResolverInputTypes["PrivateMediaFields_sizes_hero_height_Update"],
	delete?:ResolverInputTypes["PrivateMediaFields_sizes_hero_height_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_hero_height_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_hero_height_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_hero_height_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_hero_height_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_hero_mimeType"]: AliasType<{
	create?:ResolverInputTypes["PrivateMediaFields_sizes_hero_mimeType_Create"],
	read?:ResolverInputTypes["PrivateMediaFields_sizes_hero_mimeType_Read"],
	update?:ResolverInputTypes["PrivateMediaFields_sizes_hero_mimeType_Update"],
	delete?:ResolverInputTypes["PrivateMediaFields_sizes_hero_mimeType_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_hero_mimeType_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_hero_mimeType_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_hero_mimeType_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_hero_mimeType_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_hero_filesize"]: AliasType<{
	create?:ResolverInputTypes["PrivateMediaFields_sizes_hero_filesize_Create"],
	read?:ResolverInputTypes["PrivateMediaFields_sizes_hero_filesize_Read"],
	update?:ResolverInputTypes["PrivateMediaFields_sizes_hero_filesize_Update"],
	delete?:ResolverInputTypes["PrivateMediaFields_sizes_hero_filesize_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_hero_filesize_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_hero_filesize_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_hero_filesize_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_hero_filesize_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_hero_filename"]: AliasType<{
	create?:ResolverInputTypes["PrivateMediaFields_sizes_hero_filename_Create"],
	read?:ResolverInputTypes["PrivateMediaFields_sizes_hero_filename_Read"],
	update?:ResolverInputTypes["PrivateMediaFields_sizes_hero_filename_Update"],
	delete?:ResolverInputTypes["PrivateMediaFields_sizes_hero_filename_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_hero_filename_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_hero_filename_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_hero_filename_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_hero_filename_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_thumbnail_jpeg"]: AliasType<{
	create?:ResolverInputTypes["PrivateMediaFields_sizes_thumbnail_jpeg_Create"],
	read?:ResolverInputTypes["PrivateMediaFields_sizes_thumbnail_jpeg_Read"],
	update?:ResolverInputTypes["PrivateMediaFields_sizes_thumbnail_jpeg_Update"],
	delete?:ResolverInputTypes["PrivateMediaFields_sizes_thumbnail_jpeg_Delete"],
	fields?:ResolverInputTypes["PrivateMediaFields_sizes_thumbnail_jpeg_Fields"],
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_thumbnail_jpeg_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_thumbnail_jpeg_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_thumbnail_jpeg_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_thumbnail_jpeg_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_thumbnail_jpeg_Fields"]: AliasType<{
	url?:ResolverInputTypes["PrivateMediaFields_sizes_thumbnail_jpeg_url"],
	width?:ResolverInputTypes["PrivateMediaFields_sizes_thumbnail_jpeg_width"],
	height?:ResolverInputTypes["PrivateMediaFields_sizes_thumbnail_jpeg_height"],
	mimeType?:ResolverInputTypes["PrivateMediaFields_sizes_thumbnail_jpeg_mimeType"],
	filesize?:ResolverInputTypes["PrivateMediaFields_sizes_thumbnail_jpeg_filesize"],
	filename?:ResolverInputTypes["PrivateMediaFields_sizes_thumbnail_jpeg_filename"],
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_thumbnail_jpeg_url"]: AliasType<{
	create?:ResolverInputTypes["PrivateMediaFields_sizes_thumbnail_jpeg_url_Create"],
	read?:ResolverInputTypes["PrivateMediaFields_sizes_thumbnail_jpeg_url_Read"],
	update?:ResolverInputTypes["PrivateMediaFields_sizes_thumbnail_jpeg_url_Update"],
	delete?:ResolverInputTypes["PrivateMediaFields_sizes_thumbnail_jpeg_url_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_thumbnail_jpeg_url_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_thumbnail_jpeg_url_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_thumbnail_jpeg_url_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_thumbnail_jpeg_url_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_thumbnail_jpeg_width"]: AliasType<{
	create?:ResolverInputTypes["PrivateMediaFields_sizes_thumbnail_jpeg_width_Create"],
	read?:ResolverInputTypes["PrivateMediaFields_sizes_thumbnail_jpeg_width_Read"],
	update?:ResolverInputTypes["PrivateMediaFields_sizes_thumbnail_jpeg_width_Update"],
	delete?:ResolverInputTypes["PrivateMediaFields_sizes_thumbnail_jpeg_width_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_thumbnail_jpeg_width_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_thumbnail_jpeg_width_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_thumbnail_jpeg_width_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_thumbnail_jpeg_width_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_thumbnail_jpeg_height"]: AliasType<{
	create?:ResolverInputTypes["PrivateMediaFields_sizes_thumbnail_jpeg_height_Create"],
	read?:ResolverInputTypes["PrivateMediaFields_sizes_thumbnail_jpeg_height_Read"],
	update?:ResolverInputTypes["PrivateMediaFields_sizes_thumbnail_jpeg_height_Update"],
	delete?:ResolverInputTypes["PrivateMediaFields_sizes_thumbnail_jpeg_height_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_thumbnail_jpeg_height_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_thumbnail_jpeg_height_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_thumbnail_jpeg_height_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_thumbnail_jpeg_height_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_thumbnail_jpeg_mimeType"]: AliasType<{
	create?:ResolverInputTypes["PrivateMediaFields_sizes_thumbnail_jpeg_mimeType_Create"],
	read?:ResolverInputTypes["PrivateMediaFields_sizes_thumbnail_jpeg_mimeType_Read"],
	update?:ResolverInputTypes["PrivateMediaFields_sizes_thumbnail_jpeg_mimeType_Update"],
	delete?:ResolverInputTypes["PrivateMediaFields_sizes_thumbnail_jpeg_mimeType_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_thumbnail_jpeg_mimeType_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_thumbnail_jpeg_mimeType_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_thumbnail_jpeg_mimeType_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_thumbnail_jpeg_mimeType_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_thumbnail_jpeg_filesize"]: AliasType<{
	create?:ResolverInputTypes["PrivateMediaFields_sizes_thumbnail_jpeg_filesize_Create"],
	read?:ResolverInputTypes["PrivateMediaFields_sizes_thumbnail_jpeg_filesize_Read"],
	update?:ResolverInputTypes["PrivateMediaFields_sizes_thumbnail_jpeg_filesize_Update"],
	delete?:ResolverInputTypes["PrivateMediaFields_sizes_thumbnail_jpeg_filesize_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_thumbnail_jpeg_filesize_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_thumbnail_jpeg_filesize_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_thumbnail_jpeg_filesize_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_thumbnail_jpeg_filesize_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_thumbnail_jpeg_filename"]: AliasType<{
	create?:ResolverInputTypes["PrivateMediaFields_sizes_thumbnail_jpeg_filename_Create"],
	read?:ResolverInputTypes["PrivateMediaFields_sizes_thumbnail_jpeg_filename_Read"],
	update?:ResolverInputTypes["PrivateMediaFields_sizes_thumbnail_jpeg_filename_Update"],
	delete?:ResolverInputTypes["PrivateMediaFields_sizes_thumbnail_jpeg_filename_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_thumbnail_jpeg_filename_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_thumbnail_jpeg_filename_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_thumbnail_jpeg_filename_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_thumbnail_jpeg_filename_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_portrait_jpeg"]: AliasType<{
	create?:ResolverInputTypes["PrivateMediaFields_sizes_portrait_jpeg_Create"],
	read?:ResolverInputTypes["PrivateMediaFields_sizes_portrait_jpeg_Read"],
	update?:ResolverInputTypes["PrivateMediaFields_sizes_portrait_jpeg_Update"],
	delete?:ResolverInputTypes["PrivateMediaFields_sizes_portrait_jpeg_Delete"],
	fields?:ResolverInputTypes["PrivateMediaFields_sizes_portrait_jpeg_Fields"],
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_portrait_jpeg_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_portrait_jpeg_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_portrait_jpeg_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_portrait_jpeg_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_portrait_jpeg_Fields"]: AliasType<{
	url?:ResolverInputTypes["PrivateMediaFields_sizes_portrait_jpeg_url"],
	width?:ResolverInputTypes["PrivateMediaFields_sizes_portrait_jpeg_width"],
	height?:ResolverInputTypes["PrivateMediaFields_sizes_portrait_jpeg_height"],
	mimeType?:ResolverInputTypes["PrivateMediaFields_sizes_portrait_jpeg_mimeType"],
	filesize?:ResolverInputTypes["PrivateMediaFields_sizes_portrait_jpeg_filesize"],
	filename?:ResolverInputTypes["PrivateMediaFields_sizes_portrait_jpeg_filename"],
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_portrait_jpeg_url"]: AliasType<{
	create?:ResolverInputTypes["PrivateMediaFields_sizes_portrait_jpeg_url_Create"],
	read?:ResolverInputTypes["PrivateMediaFields_sizes_portrait_jpeg_url_Read"],
	update?:ResolverInputTypes["PrivateMediaFields_sizes_portrait_jpeg_url_Update"],
	delete?:ResolverInputTypes["PrivateMediaFields_sizes_portrait_jpeg_url_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_portrait_jpeg_url_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_portrait_jpeg_url_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_portrait_jpeg_url_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_portrait_jpeg_url_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_portrait_jpeg_width"]: AliasType<{
	create?:ResolverInputTypes["PrivateMediaFields_sizes_portrait_jpeg_width_Create"],
	read?:ResolverInputTypes["PrivateMediaFields_sizes_portrait_jpeg_width_Read"],
	update?:ResolverInputTypes["PrivateMediaFields_sizes_portrait_jpeg_width_Update"],
	delete?:ResolverInputTypes["PrivateMediaFields_sizes_portrait_jpeg_width_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_portrait_jpeg_width_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_portrait_jpeg_width_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_portrait_jpeg_width_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_portrait_jpeg_width_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_portrait_jpeg_height"]: AliasType<{
	create?:ResolverInputTypes["PrivateMediaFields_sizes_portrait_jpeg_height_Create"],
	read?:ResolverInputTypes["PrivateMediaFields_sizes_portrait_jpeg_height_Read"],
	update?:ResolverInputTypes["PrivateMediaFields_sizes_portrait_jpeg_height_Update"],
	delete?:ResolverInputTypes["PrivateMediaFields_sizes_portrait_jpeg_height_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_portrait_jpeg_height_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_portrait_jpeg_height_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_portrait_jpeg_height_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_portrait_jpeg_height_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_portrait_jpeg_mimeType"]: AliasType<{
	create?:ResolverInputTypes["PrivateMediaFields_sizes_portrait_jpeg_mimeType_Create"],
	read?:ResolverInputTypes["PrivateMediaFields_sizes_portrait_jpeg_mimeType_Read"],
	update?:ResolverInputTypes["PrivateMediaFields_sizes_portrait_jpeg_mimeType_Update"],
	delete?:ResolverInputTypes["PrivateMediaFields_sizes_portrait_jpeg_mimeType_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_portrait_jpeg_mimeType_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_portrait_jpeg_mimeType_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_portrait_jpeg_mimeType_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_portrait_jpeg_mimeType_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_portrait_jpeg_filesize"]: AliasType<{
	create?:ResolverInputTypes["PrivateMediaFields_sizes_portrait_jpeg_filesize_Create"],
	read?:ResolverInputTypes["PrivateMediaFields_sizes_portrait_jpeg_filesize_Read"],
	update?:ResolverInputTypes["PrivateMediaFields_sizes_portrait_jpeg_filesize_Update"],
	delete?:ResolverInputTypes["PrivateMediaFields_sizes_portrait_jpeg_filesize_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_portrait_jpeg_filesize_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_portrait_jpeg_filesize_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_portrait_jpeg_filesize_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_portrait_jpeg_filesize_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_portrait_jpeg_filename"]: AliasType<{
	create?:ResolverInputTypes["PrivateMediaFields_sizes_portrait_jpeg_filename_Create"],
	read?:ResolverInputTypes["PrivateMediaFields_sizes_portrait_jpeg_filename_Read"],
	update?:ResolverInputTypes["PrivateMediaFields_sizes_portrait_jpeg_filename_Update"],
	delete?:ResolverInputTypes["PrivateMediaFields_sizes_portrait_jpeg_filename_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_portrait_jpeg_filename_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_portrait_jpeg_filename_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_portrait_jpeg_filename_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_portrait_jpeg_filename_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_hero_jpeg"]: AliasType<{
	create?:ResolverInputTypes["PrivateMediaFields_sizes_hero_jpeg_Create"],
	read?:ResolverInputTypes["PrivateMediaFields_sizes_hero_jpeg_Read"],
	update?:ResolverInputTypes["PrivateMediaFields_sizes_hero_jpeg_Update"],
	delete?:ResolverInputTypes["PrivateMediaFields_sizes_hero_jpeg_Delete"],
	fields?:ResolverInputTypes["PrivateMediaFields_sizes_hero_jpeg_Fields"],
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_hero_jpeg_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_hero_jpeg_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_hero_jpeg_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_hero_jpeg_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_hero_jpeg_Fields"]: AliasType<{
	url?:ResolverInputTypes["PrivateMediaFields_sizes_hero_jpeg_url"],
	width?:ResolverInputTypes["PrivateMediaFields_sizes_hero_jpeg_width"],
	height?:ResolverInputTypes["PrivateMediaFields_sizes_hero_jpeg_height"],
	mimeType?:ResolverInputTypes["PrivateMediaFields_sizes_hero_jpeg_mimeType"],
	filesize?:ResolverInputTypes["PrivateMediaFields_sizes_hero_jpeg_filesize"],
	filename?:ResolverInputTypes["PrivateMediaFields_sizes_hero_jpeg_filename"],
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_hero_jpeg_url"]: AliasType<{
	create?:ResolverInputTypes["PrivateMediaFields_sizes_hero_jpeg_url_Create"],
	read?:ResolverInputTypes["PrivateMediaFields_sizes_hero_jpeg_url_Read"],
	update?:ResolverInputTypes["PrivateMediaFields_sizes_hero_jpeg_url_Update"],
	delete?:ResolverInputTypes["PrivateMediaFields_sizes_hero_jpeg_url_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_hero_jpeg_url_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_hero_jpeg_url_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_hero_jpeg_url_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_hero_jpeg_url_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_hero_jpeg_width"]: AliasType<{
	create?:ResolverInputTypes["PrivateMediaFields_sizes_hero_jpeg_width_Create"],
	read?:ResolverInputTypes["PrivateMediaFields_sizes_hero_jpeg_width_Read"],
	update?:ResolverInputTypes["PrivateMediaFields_sizes_hero_jpeg_width_Update"],
	delete?:ResolverInputTypes["PrivateMediaFields_sizes_hero_jpeg_width_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_hero_jpeg_width_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_hero_jpeg_width_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_hero_jpeg_width_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_hero_jpeg_width_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_hero_jpeg_height"]: AliasType<{
	create?:ResolverInputTypes["PrivateMediaFields_sizes_hero_jpeg_height_Create"],
	read?:ResolverInputTypes["PrivateMediaFields_sizes_hero_jpeg_height_Read"],
	update?:ResolverInputTypes["PrivateMediaFields_sizes_hero_jpeg_height_Update"],
	delete?:ResolverInputTypes["PrivateMediaFields_sizes_hero_jpeg_height_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_hero_jpeg_height_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_hero_jpeg_height_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_hero_jpeg_height_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_hero_jpeg_height_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_hero_jpeg_mimeType"]: AliasType<{
	create?:ResolverInputTypes["PrivateMediaFields_sizes_hero_jpeg_mimeType_Create"],
	read?:ResolverInputTypes["PrivateMediaFields_sizes_hero_jpeg_mimeType_Read"],
	update?:ResolverInputTypes["PrivateMediaFields_sizes_hero_jpeg_mimeType_Update"],
	delete?:ResolverInputTypes["PrivateMediaFields_sizes_hero_jpeg_mimeType_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_hero_jpeg_mimeType_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_hero_jpeg_mimeType_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_hero_jpeg_mimeType_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_hero_jpeg_mimeType_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_hero_jpeg_filesize"]: AliasType<{
	create?:ResolverInputTypes["PrivateMediaFields_sizes_hero_jpeg_filesize_Create"],
	read?:ResolverInputTypes["PrivateMediaFields_sizes_hero_jpeg_filesize_Read"],
	update?:ResolverInputTypes["PrivateMediaFields_sizes_hero_jpeg_filesize_Update"],
	delete?:ResolverInputTypes["PrivateMediaFields_sizes_hero_jpeg_filesize_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_hero_jpeg_filesize_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_hero_jpeg_filesize_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_hero_jpeg_filesize_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_hero_jpeg_filesize_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_hero_jpeg_filename"]: AliasType<{
	create?:ResolverInputTypes["PrivateMediaFields_sizes_hero_jpeg_filename_Create"],
	read?:ResolverInputTypes["PrivateMediaFields_sizes_hero_jpeg_filename_Read"],
	update?:ResolverInputTypes["PrivateMediaFields_sizes_hero_jpeg_filename_Update"],
	delete?:ResolverInputTypes["PrivateMediaFields_sizes_hero_jpeg_filename_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_hero_jpeg_filename_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_hero_jpeg_filename_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_hero_jpeg_filename_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaFields_sizes_hero_jpeg_filename_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaCreateAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaReadAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaUpdateAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PrivateMediaDeleteAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["mediaAccess"]: AliasType<{
	fields?:ResolverInputTypes["MediaFields"],
	create?:ResolverInputTypes["MediaCreateAccess"],
	read?:ResolverInputTypes["MediaReadAccess"],
	update?:ResolverInputTypes["MediaUpdateAccess"],
	delete?:ResolverInputTypes["MediaDeleteAccess"],
		__typename?: boolean | `@${string}`
}>;
	["MediaFields"]: AliasType<{
	alt?:ResolverInputTypes["MediaFields_alt"],
	updatedAt?:ResolverInputTypes["MediaFields_updatedAt"],
	createdAt?:ResolverInputTypes["MediaFields_createdAt"],
	url?:ResolverInputTypes["MediaFields_url"],
	filename?:ResolverInputTypes["MediaFields_filename"],
	mimeType?:ResolverInputTypes["MediaFields_mimeType"],
	filesize?:ResolverInputTypes["MediaFields_filesize"],
	width?:ResolverInputTypes["MediaFields_width"],
	height?:ResolverInputTypes["MediaFields_height"],
	focalX?:ResolverInputTypes["MediaFields_focalX"],
	focalY?:ResolverInputTypes["MediaFields_focalY"],
	sizes?:ResolverInputTypes["MediaFields_sizes"],
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_alt"]: AliasType<{
	create?:ResolverInputTypes["MediaFields_alt_Create"],
	read?:ResolverInputTypes["MediaFields_alt_Read"],
	update?:ResolverInputTypes["MediaFields_alt_Update"],
	delete?:ResolverInputTypes["MediaFields_alt_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_alt_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_alt_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_alt_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_alt_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_updatedAt"]: AliasType<{
	create?:ResolverInputTypes["MediaFields_updatedAt_Create"],
	read?:ResolverInputTypes["MediaFields_updatedAt_Read"],
	update?:ResolverInputTypes["MediaFields_updatedAt_Update"],
	delete?:ResolverInputTypes["MediaFields_updatedAt_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_updatedAt_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_updatedAt_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_updatedAt_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_updatedAt_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_createdAt"]: AliasType<{
	create?:ResolverInputTypes["MediaFields_createdAt_Create"],
	read?:ResolverInputTypes["MediaFields_createdAt_Read"],
	update?:ResolverInputTypes["MediaFields_createdAt_Update"],
	delete?:ResolverInputTypes["MediaFields_createdAt_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_createdAt_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_createdAt_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_createdAt_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_createdAt_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_url"]: AliasType<{
	create?:ResolverInputTypes["MediaFields_url_Create"],
	read?:ResolverInputTypes["MediaFields_url_Read"],
	update?:ResolverInputTypes["MediaFields_url_Update"],
	delete?:ResolverInputTypes["MediaFields_url_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_url_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_url_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_url_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_url_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_filename"]: AliasType<{
	create?:ResolverInputTypes["MediaFields_filename_Create"],
	read?:ResolverInputTypes["MediaFields_filename_Read"],
	update?:ResolverInputTypes["MediaFields_filename_Update"],
	delete?:ResolverInputTypes["MediaFields_filename_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_filename_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_filename_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_filename_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_filename_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_mimeType"]: AliasType<{
	create?:ResolverInputTypes["MediaFields_mimeType_Create"],
	read?:ResolverInputTypes["MediaFields_mimeType_Read"],
	update?:ResolverInputTypes["MediaFields_mimeType_Update"],
	delete?:ResolverInputTypes["MediaFields_mimeType_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_mimeType_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_mimeType_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_mimeType_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_mimeType_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_filesize"]: AliasType<{
	create?:ResolverInputTypes["MediaFields_filesize_Create"],
	read?:ResolverInputTypes["MediaFields_filesize_Read"],
	update?:ResolverInputTypes["MediaFields_filesize_Update"],
	delete?:ResolverInputTypes["MediaFields_filesize_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_filesize_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_filesize_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_filesize_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_filesize_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_width"]: AliasType<{
	create?:ResolverInputTypes["MediaFields_width_Create"],
	read?:ResolverInputTypes["MediaFields_width_Read"],
	update?:ResolverInputTypes["MediaFields_width_Update"],
	delete?:ResolverInputTypes["MediaFields_width_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_width_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_width_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_width_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_width_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_height"]: AliasType<{
	create?:ResolverInputTypes["MediaFields_height_Create"],
	read?:ResolverInputTypes["MediaFields_height_Read"],
	update?:ResolverInputTypes["MediaFields_height_Update"],
	delete?:ResolverInputTypes["MediaFields_height_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_height_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_height_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_height_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_height_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_focalX"]: AliasType<{
	create?:ResolverInputTypes["MediaFields_focalX_Create"],
	read?:ResolverInputTypes["MediaFields_focalX_Read"],
	update?:ResolverInputTypes["MediaFields_focalX_Update"],
	delete?:ResolverInputTypes["MediaFields_focalX_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_focalX_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_focalX_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_focalX_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_focalX_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_focalY"]: AliasType<{
	create?:ResolverInputTypes["MediaFields_focalY_Create"],
	read?:ResolverInputTypes["MediaFields_focalY_Read"],
	update?:ResolverInputTypes["MediaFields_focalY_Update"],
	delete?:ResolverInputTypes["MediaFields_focalY_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_focalY_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_focalY_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_focalY_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_focalY_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes"]: AliasType<{
	create?:ResolverInputTypes["MediaFields_sizes_Create"],
	read?:ResolverInputTypes["MediaFields_sizes_Read"],
	update?:ResolverInputTypes["MediaFields_sizes_Update"],
	delete?:ResolverInputTypes["MediaFields_sizes_Delete"],
	fields?:ResolverInputTypes["MediaFields_sizes_Fields"],
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_Fields"]: AliasType<{
	small?:ResolverInputTypes["MediaFields_sizes_small"],
	medium?:ResolverInputTypes["MediaFields_sizes_medium"],
	large?:ResolverInputTypes["MediaFields_sizes_large"],
	small_jpeg?:ResolverInputTypes["MediaFields_sizes_small_jpeg"],
	medium_jpeg?:ResolverInputTypes["MediaFields_sizes_medium_jpeg"],
	large_jpeg?:ResolverInputTypes["MediaFields_sizes_large_jpeg"],
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_small"]: AliasType<{
	create?:ResolverInputTypes["MediaFields_sizes_small_Create"],
	read?:ResolverInputTypes["MediaFields_sizes_small_Read"],
	update?:ResolverInputTypes["MediaFields_sizes_small_Update"],
	delete?:ResolverInputTypes["MediaFields_sizes_small_Delete"],
	fields?:ResolverInputTypes["MediaFields_sizes_small_Fields"],
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_small_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_small_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_small_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_small_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_small_Fields"]: AliasType<{
	url?:ResolverInputTypes["MediaFields_sizes_small_url"],
	width?:ResolverInputTypes["MediaFields_sizes_small_width"],
	height?:ResolverInputTypes["MediaFields_sizes_small_height"],
	mimeType?:ResolverInputTypes["MediaFields_sizes_small_mimeType"],
	filesize?:ResolverInputTypes["MediaFields_sizes_small_filesize"],
	filename?:ResolverInputTypes["MediaFields_sizes_small_filename"],
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_small_url"]: AliasType<{
	create?:ResolverInputTypes["MediaFields_sizes_small_url_Create"],
	read?:ResolverInputTypes["MediaFields_sizes_small_url_Read"],
	update?:ResolverInputTypes["MediaFields_sizes_small_url_Update"],
	delete?:ResolverInputTypes["MediaFields_sizes_small_url_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_small_url_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_small_url_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_small_url_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_small_url_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_small_width"]: AliasType<{
	create?:ResolverInputTypes["MediaFields_sizes_small_width_Create"],
	read?:ResolverInputTypes["MediaFields_sizes_small_width_Read"],
	update?:ResolverInputTypes["MediaFields_sizes_small_width_Update"],
	delete?:ResolverInputTypes["MediaFields_sizes_small_width_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_small_width_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_small_width_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_small_width_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_small_width_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_small_height"]: AliasType<{
	create?:ResolverInputTypes["MediaFields_sizes_small_height_Create"],
	read?:ResolverInputTypes["MediaFields_sizes_small_height_Read"],
	update?:ResolverInputTypes["MediaFields_sizes_small_height_Update"],
	delete?:ResolverInputTypes["MediaFields_sizes_small_height_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_small_height_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_small_height_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_small_height_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_small_height_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_small_mimeType"]: AliasType<{
	create?:ResolverInputTypes["MediaFields_sizes_small_mimeType_Create"],
	read?:ResolverInputTypes["MediaFields_sizes_small_mimeType_Read"],
	update?:ResolverInputTypes["MediaFields_sizes_small_mimeType_Update"],
	delete?:ResolverInputTypes["MediaFields_sizes_small_mimeType_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_small_mimeType_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_small_mimeType_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_small_mimeType_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_small_mimeType_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_small_filesize"]: AliasType<{
	create?:ResolverInputTypes["MediaFields_sizes_small_filesize_Create"],
	read?:ResolverInputTypes["MediaFields_sizes_small_filesize_Read"],
	update?:ResolverInputTypes["MediaFields_sizes_small_filesize_Update"],
	delete?:ResolverInputTypes["MediaFields_sizes_small_filesize_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_small_filesize_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_small_filesize_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_small_filesize_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_small_filesize_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_small_filename"]: AliasType<{
	create?:ResolverInputTypes["MediaFields_sizes_small_filename_Create"],
	read?:ResolverInputTypes["MediaFields_sizes_small_filename_Read"],
	update?:ResolverInputTypes["MediaFields_sizes_small_filename_Update"],
	delete?:ResolverInputTypes["MediaFields_sizes_small_filename_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_small_filename_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_small_filename_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_small_filename_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_small_filename_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_medium"]: AliasType<{
	create?:ResolverInputTypes["MediaFields_sizes_medium_Create"],
	read?:ResolverInputTypes["MediaFields_sizes_medium_Read"],
	update?:ResolverInputTypes["MediaFields_sizes_medium_Update"],
	delete?:ResolverInputTypes["MediaFields_sizes_medium_Delete"],
	fields?:ResolverInputTypes["MediaFields_sizes_medium_Fields"],
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_medium_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_medium_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_medium_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_medium_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_medium_Fields"]: AliasType<{
	url?:ResolverInputTypes["MediaFields_sizes_medium_url"],
	width?:ResolverInputTypes["MediaFields_sizes_medium_width"],
	height?:ResolverInputTypes["MediaFields_sizes_medium_height"],
	mimeType?:ResolverInputTypes["MediaFields_sizes_medium_mimeType"],
	filesize?:ResolverInputTypes["MediaFields_sizes_medium_filesize"],
	filename?:ResolverInputTypes["MediaFields_sizes_medium_filename"],
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_medium_url"]: AliasType<{
	create?:ResolverInputTypes["MediaFields_sizes_medium_url_Create"],
	read?:ResolverInputTypes["MediaFields_sizes_medium_url_Read"],
	update?:ResolverInputTypes["MediaFields_sizes_medium_url_Update"],
	delete?:ResolverInputTypes["MediaFields_sizes_medium_url_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_medium_url_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_medium_url_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_medium_url_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_medium_url_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_medium_width"]: AliasType<{
	create?:ResolverInputTypes["MediaFields_sizes_medium_width_Create"],
	read?:ResolverInputTypes["MediaFields_sizes_medium_width_Read"],
	update?:ResolverInputTypes["MediaFields_sizes_medium_width_Update"],
	delete?:ResolverInputTypes["MediaFields_sizes_medium_width_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_medium_width_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_medium_width_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_medium_width_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_medium_width_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_medium_height"]: AliasType<{
	create?:ResolverInputTypes["MediaFields_sizes_medium_height_Create"],
	read?:ResolverInputTypes["MediaFields_sizes_medium_height_Read"],
	update?:ResolverInputTypes["MediaFields_sizes_medium_height_Update"],
	delete?:ResolverInputTypes["MediaFields_sizes_medium_height_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_medium_height_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_medium_height_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_medium_height_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_medium_height_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_medium_mimeType"]: AliasType<{
	create?:ResolverInputTypes["MediaFields_sizes_medium_mimeType_Create"],
	read?:ResolverInputTypes["MediaFields_sizes_medium_mimeType_Read"],
	update?:ResolverInputTypes["MediaFields_sizes_medium_mimeType_Update"],
	delete?:ResolverInputTypes["MediaFields_sizes_medium_mimeType_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_medium_mimeType_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_medium_mimeType_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_medium_mimeType_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_medium_mimeType_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_medium_filesize"]: AliasType<{
	create?:ResolverInputTypes["MediaFields_sizes_medium_filesize_Create"],
	read?:ResolverInputTypes["MediaFields_sizes_medium_filesize_Read"],
	update?:ResolverInputTypes["MediaFields_sizes_medium_filesize_Update"],
	delete?:ResolverInputTypes["MediaFields_sizes_medium_filesize_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_medium_filesize_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_medium_filesize_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_medium_filesize_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_medium_filesize_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_medium_filename"]: AliasType<{
	create?:ResolverInputTypes["MediaFields_sizes_medium_filename_Create"],
	read?:ResolverInputTypes["MediaFields_sizes_medium_filename_Read"],
	update?:ResolverInputTypes["MediaFields_sizes_medium_filename_Update"],
	delete?:ResolverInputTypes["MediaFields_sizes_medium_filename_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_medium_filename_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_medium_filename_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_medium_filename_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_medium_filename_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_large"]: AliasType<{
	create?:ResolverInputTypes["MediaFields_sizes_large_Create"],
	read?:ResolverInputTypes["MediaFields_sizes_large_Read"],
	update?:ResolverInputTypes["MediaFields_sizes_large_Update"],
	delete?:ResolverInputTypes["MediaFields_sizes_large_Delete"],
	fields?:ResolverInputTypes["MediaFields_sizes_large_Fields"],
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_large_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_large_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_large_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_large_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_large_Fields"]: AliasType<{
	url?:ResolverInputTypes["MediaFields_sizes_large_url"],
	width?:ResolverInputTypes["MediaFields_sizes_large_width"],
	height?:ResolverInputTypes["MediaFields_sizes_large_height"],
	mimeType?:ResolverInputTypes["MediaFields_sizes_large_mimeType"],
	filesize?:ResolverInputTypes["MediaFields_sizes_large_filesize"],
	filename?:ResolverInputTypes["MediaFields_sizes_large_filename"],
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_large_url"]: AliasType<{
	create?:ResolverInputTypes["MediaFields_sizes_large_url_Create"],
	read?:ResolverInputTypes["MediaFields_sizes_large_url_Read"],
	update?:ResolverInputTypes["MediaFields_sizes_large_url_Update"],
	delete?:ResolverInputTypes["MediaFields_sizes_large_url_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_large_url_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_large_url_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_large_url_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_large_url_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_large_width"]: AliasType<{
	create?:ResolverInputTypes["MediaFields_sizes_large_width_Create"],
	read?:ResolverInputTypes["MediaFields_sizes_large_width_Read"],
	update?:ResolverInputTypes["MediaFields_sizes_large_width_Update"],
	delete?:ResolverInputTypes["MediaFields_sizes_large_width_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_large_width_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_large_width_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_large_width_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_large_width_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_large_height"]: AliasType<{
	create?:ResolverInputTypes["MediaFields_sizes_large_height_Create"],
	read?:ResolverInputTypes["MediaFields_sizes_large_height_Read"],
	update?:ResolverInputTypes["MediaFields_sizes_large_height_Update"],
	delete?:ResolverInputTypes["MediaFields_sizes_large_height_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_large_height_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_large_height_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_large_height_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_large_height_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_large_mimeType"]: AliasType<{
	create?:ResolverInputTypes["MediaFields_sizes_large_mimeType_Create"],
	read?:ResolverInputTypes["MediaFields_sizes_large_mimeType_Read"],
	update?:ResolverInputTypes["MediaFields_sizes_large_mimeType_Update"],
	delete?:ResolverInputTypes["MediaFields_sizes_large_mimeType_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_large_mimeType_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_large_mimeType_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_large_mimeType_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_large_mimeType_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_large_filesize"]: AliasType<{
	create?:ResolverInputTypes["MediaFields_sizes_large_filesize_Create"],
	read?:ResolverInputTypes["MediaFields_sizes_large_filesize_Read"],
	update?:ResolverInputTypes["MediaFields_sizes_large_filesize_Update"],
	delete?:ResolverInputTypes["MediaFields_sizes_large_filesize_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_large_filesize_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_large_filesize_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_large_filesize_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_large_filesize_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_large_filename"]: AliasType<{
	create?:ResolverInputTypes["MediaFields_sizes_large_filename_Create"],
	read?:ResolverInputTypes["MediaFields_sizes_large_filename_Read"],
	update?:ResolverInputTypes["MediaFields_sizes_large_filename_Update"],
	delete?:ResolverInputTypes["MediaFields_sizes_large_filename_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_large_filename_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_large_filename_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_large_filename_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_large_filename_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_small_jpeg"]: AliasType<{
	create?:ResolverInputTypes["MediaFields_sizes_small_jpeg_Create"],
	read?:ResolverInputTypes["MediaFields_sizes_small_jpeg_Read"],
	update?:ResolverInputTypes["MediaFields_sizes_small_jpeg_Update"],
	delete?:ResolverInputTypes["MediaFields_sizes_small_jpeg_Delete"],
	fields?:ResolverInputTypes["MediaFields_sizes_small_jpeg_Fields"],
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_small_jpeg_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_small_jpeg_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_small_jpeg_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_small_jpeg_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_small_jpeg_Fields"]: AliasType<{
	url?:ResolverInputTypes["MediaFields_sizes_small_jpeg_url"],
	width?:ResolverInputTypes["MediaFields_sizes_small_jpeg_width"],
	height?:ResolverInputTypes["MediaFields_sizes_small_jpeg_height"],
	mimeType?:ResolverInputTypes["MediaFields_sizes_small_jpeg_mimeType"],
	filesize?:ResolverInputTypes["MediaFields_sizes_small_jpeg_filesize"],
	filename?:ResolverInputTypes["MediaFields_sizes_small_jpeg_filename"],
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_small_jpeg_url"]: AliasType<{
	create?:ResolverInputTypes["MediaFields_sizes_small_jpeg_url_Create"],
	read?:ResolverInputTypes["MediaFields_sizes_small_jpeg_url_Read"],
	update?:ResolverInputTypes["MediaFields_sizes_small_jpeg_url_Update"],
	delete?:ResolverInputTypes["MediaFields_sizes_small_jpeg_url_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_small_jpeg_url_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_small_jpeg_url_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_small_jpeg_url_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_small_jpeg_url_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_small_jpeg_width"]: AliasType<{
	create?:ResolverInputTypes["MediaFields_sizes_small_jpeg_width_Create"],
	read?:ResolverInputTypes["MediaFields_sizes_small_jpeg_width_Read"],
	update?:ResolverInputTypes["MediaFields_sizes_small_jpeg_width_Update"],
	delete?:ResolverInputTypes["MediaFields_sizes_small_jpeg_width_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_small_jpeg_width_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_small_jpeg_width_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_small_jpeg_width_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_small_jpeg_width_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_small_jpeg_height"]: AliasType<{
	create?:ResolverInputTypes["MediaFields_sizes_small_jpeg_height_Create"],
	read?:ResolverInputTypes["MediaFields_sizes_small_jpeg_height_Read"],
	update?:ResolverInputTypes["MediaFields_sizes_small_jpeg_height_Update"],
	delete?:ResolverInputTypes["MediaFields_sizes_small_jpeg_height_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_small_jpeg_height_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_small_jpeg_height_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_small_jpeg_height_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_small_jpeg_height_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_small_jpeg_mimeType"]: AliasType<{
	create?:ResolverInputTypes["MediaFields_sizes_small_jpeg_mimeType_Create"],
	read?:ResolverInputTypes["MediaFields_sizes_small_jpeg_mimeType_Read"],
	update?:ResolverInputTypes["MediaFields_sizes_small_jpeg_mimeType_Update"],
	delete?:ResolverInputTypes["MediaFields_sizes_small_jpeg_mimeType_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_small_jpeg_mimeType_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_small_jpeg_mimeType_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_small_jpeg_mimeType_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_small_jpeg_mimeType_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_small_jpeg_filesize"]: AliasType<{
	create?:ResolverInputTypes["MediaFields_sizes_small_jpeg_filesize_Create"],
	read?:ResolverInputTypes["MediaFields_sizes_small_jpeg_filesize_Read"],
	update?:ResolverInputTypes["MediaFields_sizes_small_jpeg_filesize_Update"],
	delete?:ResolverInputTypes["MediaFields_sizes_small_jpeg_filesize_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_small_jpeg_filesize_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_small_jpeg_filesize_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_small_jpeg_filesize_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_small_jpeg_filesize_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_small_jpeg_filename"]: AliasType<{
	create?:ResolverInputTypes["MediaFields_sizes_small_jpeg_filename_Create"],
	read?:ResolverInputTypes["MediaFields_sizes_small_jpeg_filename_Read"],
	update?:ResolverInputTypes["MediaFields_sizes_small_jpeg_filename_Update"],
	delete?:ResolverInputTypes["MediaFields_sizes_small_jpeg_filename_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_small_jpeg_filename_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_small_jpeg_filename_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_small_jpeg_filename_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_small_jpeg_filename_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_medium_jpeg"]: AliasType<{
	create?:ResolverInputTypes["MediaFields_sizes_medium_jpeg_Create"],
	read?:ResolverInputTypes["MediaFields_sizes_medium_jpeg_Read"],
	update?:ResolverInputTypes["MediaFields_sizes_medium_jpeg_Update"],
	delete?:ResolverInputTypes["MediaFields_sizes_medium_jpeg_Delete"],
	fields?:ResolverInputTypes["MediaFields_sizes_medium_jpeg_Fields"],
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_medium_jpeg_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_medium_jpeg_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_medium_jpeg_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_medium_jpeg_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_medium_jpeg_Fields"]: AliasType<{
	url?:ResolverInputTypes["MediaFields_sizes_medium_jpeg_url"],
	width?:ResolverInputTypes["MediaFields_sizes_medium_jpeg_width"],
	height?:ResolverInputTypes["MediaFields_sizes_medium_jpeg_height"],
	mimeType?:ResolverInputTypes["MediaFields_sizes_medium_jpeg_mimeType"],
	filesize?:ResolverInputTypes["MediaFields_sizes_medium_jpeg_filesize"],
	filename?:ResolverInputTypes["MediaFields_sizes_medium_jpeg_filename"],
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_medium_jpeg_url"]: AliasType<{
	create?:ResolverInputTypes["MediaFields_sizes_medium_jpeg_url_Create"],
	read?:ResolverInputTypes["MediaFields_sizes_medium_jpeg_url_Read"],
	update?:ResolverInputTypes["MediaFields_sizes_medium_jpeg_url_Update"],
	delete?:ResolverInputTypes["MediaFields_sizes_medium_jpeg_url_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_medium_jpeg_url_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_medium_jpeg_url_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_medium_jpeg_url_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_medium_jpeg_url_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_medium_jpeg_width"]: AliasType<{
	create?:ResolverInputTypes["MediaFields_sizes_medium_jpeg_width_Create"],
	read?:ResolverInputTypes["MediaFields_sizes_medium_jpeg_width_Read"],
	update?:ResolverInputTypes["MediaFields_sizes_medium_jpeg_width_Update"],
	delete?:ResolverInputTypes["MediaFields_sizes_medium_jpeg_width_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_medium_jpeg_width_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_medium_jpeg_width_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_medium_jpeg_width_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_medium_jpeg_width_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_medium_jpeg_height"]: AliasType<{
	create?:ResolverInputTypes["MediaFields_sizes_medium_jpeg_height_Create"],
	read?:ResolverInputTypes["MediaFields_sizes_medium_jpeg_height_Read"],
	update?:ResolverInputTypes["MediaFields_sizes_medium_jpeg_height_Update"],
	delete?:ResolverInputTypes["MediaFields_sizes_medium_jpeg_height_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_medium_jpeg_height_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_medium_jpeg_height_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_medium_jpeg_height_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_medium_jpeg_height_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_medium_jpeg_mimeType"]: AliasType<{
	create?:ResolverInputTypes["MediaFields_sizes_medium_jpeg_mimeType_Create"],
	read?:ResolverInputTypes["MediaFields_sizes_medium_jpeg_mimeType_Read"],
	update?:ResolverInputTypes["MediaFields_sizes_medium_jpeg_mimeType_Update"],
	delete?:ResolverInputTypes["MediaFields_sizes_medium_jpeg_mimeType_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_medium_jpeg_mimeType_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_medium_jpeg_mimeType_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_medium_jpeg_mimeType_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_medium_jpeg_mimeType_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_medium_jpeg_filesize"]: AliasType<{
	create?:ResolverInputTypes["MediaFields_sizes_medium_jpeg_filesize_Create"],
	read?:ResolverInputTypes["MediaFields_sizes_medium_jpeg_filesize_Read"],
	update?:ResolverInputTypes["MediaFields_sizes_medium_jpeg_filesize_Update"],
	delete?:ResolverInputTypes["MediaFields_sizes_medium_jpeg_filesize_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_medium_jpeg_filesize_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_medium_jpeg_filesize_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_medium_jpeg_filesize_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_medium_jpeg_filesize_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_medium_jpeg_filename"]: AliasType<{
	create?:ResolverInputTypes["MediaFields_sizes_medium_jpeg_filename_Create"],
	read?:ResolverInputTypes["MediaFields_sizes_medium_jpeg_filename_Read"],
	update?:ResolverInputTypes["MediaFields_sizes_medium_jpeg_filename_Update"],
	delete?:ResolverInputTypes["MediaFields_sizes_medium_jpeg_filename_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_medium_jpeg_filename_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_medium_jpeg_filename_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_medium_jpeg_filename_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_medium_jpeg_filename_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_large_jpeg"]: AliasType<{
	create?:ResolverInputTypes["MediaFields_sizes_large_jpeg_Create"],
	read?:ResolverInputTypes["MediaFields_sizes_large_jpeg_Read"],
	update?:ResolverInputTypes["MediaFields_sizes_large_jpeg_Update"],
	delete?:ResolverInputTypes["MediaFields_sizes_large_jpeg_Delete"],
	fields?:ResolverInputTypes["MediaFields_sizes_large_jpeg_Fields"],
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_large_jpeg_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_large_jpeg_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_large_jpeg_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_large_jpeg_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_large_jpeg_Fields"]: AliasType<{
	url?:ResolverInputTypes["MediaFields_sizes_large_jpeg_url"],
	width?:ResolverInputTypes["MediaFields_sizes_large_jpeg_width"],
	height?:ResolverInputTypes["MediaFields_sizes_large_jpeg_height"],
	mimeType?:ResolverInputTypes["MediaFields_sizes_large_jpeg_mimeType"],
	filesize?:ResolverInputTypes["MediaFields_sizes_large_jpeg_filesize"],
	filename?:ResolverInputTypes["MediaFields_sizes_large_jpeg_filename"],
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_large_jpeg_url"]: AliasType<{
	create?:ResolverInputTypes["MediaFields_sizes_large_jpeg_url_Create"],
	read?:ResolverInputTypes["MediaFields_sizes_large_jpeg_url_Read"],
	update?:ResolverInputTypes["MediaFields_sizes_large_jpeg_url_Update"],
	delete?:ResolverInputTypes["MediaFields_sizes_large_jpeg_url_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_large_jpeg_url_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_large_jpeg_url_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_large_jpeg_url_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_large_jpeg_url_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_large_jpeg_width"]: AliasType<{
	create?:ResolverInputTypes["MediaFields_sizes_large_jpeg_width_Create"],
	read?:ResolverInputTypes["MediaFields_sizes_large_jpeg_width_Read"],
	update?:ResolverInputTypes["MediaFields_sizes_large_jpeg_width_Update"],
	delete?:ResolverInputTypes["MediaFields_sizes_large_jpeg_width_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_large_jpeg_width_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_large_jpeg_width_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_large_jpeg_width_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_large_jpeg_width_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_large_jpeg_height"]: AliasType<{
	create?:ResolverInputTypes["MediaFields_sizes_large_jpeg_height_Create"],
	read?:ResolverInputTypes["MediaFields_sizes_large_jpeg_height_Read"],
	update?:ResolverInputTypes["MediaFields_sizes_large_jpeg_height_Update"],
	delete?:ResolverInputTypes["MediaFields_sizes_large_jpeg_height_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_large_jpeg_height_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_large_jpeg_height_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_large_jpeg_height_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_large_jpeg_height_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_large_jpeg_mimeType"]: AliasType<{
	create?:ResolverInputTypes["MediaFields_sizes_large_jpeg_mimeType_Create"],
	read?:ResolverInputTypes["MediaFields_sizes_large_jpeg_mimeType_Read"],
	update?:ResolverInputTypes["MediaFields_sizes_large_jpeg_mimeType_Update"],
	delete?:ResolverInputTypes["MediaFields_sizes_large_jpeg_mimeType_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_large_jpeg_mimeType_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_large_jpeg_mimeType_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_large_jpeg_mimeType_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_large_jpeg_mimeType_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_large_jpeg_filesize"]: AliasType<{
	create?:ResolverInputTypes["MediaFields_sizes_large_jpeg_filesize_Create"],
	read?:ResolverInputTypes["MediaFields_sizes_large_jpeg_filesize_Read"],
	update?:ResolverInputTypes["MediaFields_sizes_large_jpeg_filesize_Update"],
	delete?:ResolverInputTypes["MediaFields_sizes_large_jpeg_filesize_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_large_jpeg_filesize_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_large_jpeg_filesize_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_large_jpeg_filesize_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_large_jpeg_filesize_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_large_jpeg_filename"]: AliasType<{
	create?:ResolverInputTypes["MediaFields_sizes_large_jpeg_filename_Create"],
	read?:ResolverInputTypes["MediaFields_sizes_large_jpeg_filename_Read"],
	update?:ResolverInputTypes["MediaFields_sizes_large_jpeg_filename_Update"],
	delete?:ResolverInputTypes["MediaFields_sizes_large_jpeg_filename_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_large_jpeg_filename_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_large_jpeg_filename_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_large_jpeg_filename_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaFields_sizes_large_jpeg_filename_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaCreateAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaReadAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaUpdateAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MediaDeleteAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["backupMediaAccess"]: AliasType<{
	fields?:ResolverInputTypes["BackupMediaFields"],
	create?:ResolverInputTypes["BackupMediaCreateAccess"],
	read?:ResolverInputTypes["BackupMediaReadAccess"],
	update?:ResolverInputTypes["BackupMediaUpdateAccess"],
	delete?:ResolverInputTypes["BackupMediaDeleteAccess"],
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaFields"]: AliasType<{
	alt?:ResolverInputTypes["BackupMediaFields_alt"],
	creator?:ResolverInputTypes["BackupMediaFields_creator"],
	updatedAt?:ResolverInputTypes["BackupMediaFields_updatedAt"],
	createdAt?:ResolverInputTypes["BackupMediaFields_createdAt"],
	url?:ResolverInputTypes["BackupMediaFields_url"],
	filename?:ResolverInputTypes["BackupMediaFields_filename"],
	mimeType?:ResolverInputTypes["BackupMediaFields_mimeType"],
	filesize?:ResolverInputTypes["BackupMediaFields_filesize"],
	width?:ResolverInputTypes["BackupMediaFields_width"],
	height?:ResolverInputTypes["BackupMediaFields_height"],
	focalX?:ResolverInputTypes["BackupMediaFields_focalX"],
	focalY?:ResolverInputTypes["BackupMediaFields_focalY"],
	sizes?:ResolverInputTypes["BackupMediaFields_sizes"],
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaFields_alt"]: AliasType<{
	create?:ResolverInputTypes["BackupMediaFields_alt_Create"],
	read?:ResolverInputTypes["BackupMediaFields_alt_Read"],
	update?:ResolverInputTypes["BackupMediaFields_alt_Update"],
	delete?:ResolverInputTypes["BackupMediaFields_alt_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaFields_alt_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaFields_alt_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaFields_alt_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaFields_alt_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaFields_creator"]: AliasType<{
	create?:ResolverInputTypes["BackupMediaFields_creator_Create"],
	read?:ResolverInputTypes["BackupMediaFields_creator_Read"],
	update?:ResolverInputTypes["BackupMediaFields_creator_Update"],
	delete?:ResolverInputTypes["BackupMediaFields_creator_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaFields_creator_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaFields_creator_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaFields_creator_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaFields_creator_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaFields_updatedAt"]: AliasType<{
	create?:ResolverInputTypes["BackupMediaFields_updatedAt_Create"],
	read?:ResolverInputTypes["BackupMediaFields_updatedAt_Read"],
	update?:ResolverInputTypes["BackupMediaFields_updatedAt_Update"],
	delete?:ResolverInputTypes["BackupMediaFields_updatedAt_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaFields_updatedAt_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaFields_updatedAt_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaFields_updatedAt_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaFields_updatedAt_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaFields_createdAt"]: AliasType<{
	create?:ResolverInputTypes["BackupMediaFields_createdAt_Create"],
	read?:ResolverInputTypes["BackupMediaFields_createdAt_Read"],
	update?:ResolverInputTypes["BackupMediaFields_createdAt_Update"],
	delete?:ResolverInputTypes["BackupMediaFields_createdAt_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaFields_createdAt_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaFields_createdAt_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaFields_createdAt_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaFields_createdAt_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaFields_url"]: AliasType<{
	create?:ResolverInputTypes["BackupMediaFields_url_Create"],
	read?:ResolverInputTypes["BackupMediaFields_url_Read"],
	update?:ResolverInputTypes["BackupMediaFields_url_Update"],
	delete?:ResolverInputTypes["BackupMediaFields_url_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaFields_url_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaFields_url_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaFields_url_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaFields_url_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaFields_filename"]: AliasType<{
	create?:ResolverInputTypes["BackupMediaFields_filename_Create"],
	read?:ResolverInputTypes["BackupMediaFields_filename_Read"],
	update?:ResolverInputTypes["BackupMediaFields_filename_Update"],
	delete?:ResolverInputTypes["BackupMediaFields_filename_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaFields_filename_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaFields_filename_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaFields_filename_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaFields_filename_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaFields_mimeType"]: AliasType<{
	create?:ResolverInputTypes["BackupMediaFields_mimeType_Create"],
	read?:ResolverInputTypes["BackupMediaFields_mimeType_Read"],
	update?:ResolverInputTypes["BackupMediaFields_mimeType_Update"],
	delete?:ResolverInputTypes["BackupMediaFields_mimeType_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaFields_mimeType_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaFields_mimeType_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaFields_mimeType_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaFields_mimeType_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaFields_filesize"]: AliasType<{
	create?:ResolverInputTypes["BackupMediaFields_filesize_Create"],
	read?:ResolverInputTypes["BackupMediaFields_filesize_Read"],
	update?:ResolverInputTypes["BackupMediaFields_filesize_Update"],
	delete?:ResolverInputTypes["BackupMediaFields_filesize_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaFields_filesize_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaFields_filesize_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaFields_filesize_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaFields_filesize_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaFields_width"]: AliasType<{
	create?:ResolverInputTypes["BackupMediaFields_width_Create"],
	read?:ResolverInputTypes["BackupMediaFields_width_Read"],
	update?:ResolverInputTypes["BackupMediaFields_width_Update"],
	delete?:ResolverInputTypes["BackupMediaFields_width_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaFields_width_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaFields_width_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaFields_width_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaFields_width_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaFields_height"]: AliasType<{
	create?:ResolverInputTypes["BackupMediaFields_height_Create"],
	read?:ResolverInputTypes["BackupMediaFields_height_Read"],
	update?:ResolverInputTypes["BackupMediaFields_height_Update"],
	delete?:ResolverInputTypes["BackupMediaFields_height_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaFields_height_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaFields_height_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaFields_height_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaFields_height_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaFields_focalX"]: AliasType<{
	create?:ResolverInputTypes["BackupMediaFields_focalX_Create"],
	read?:ResolverInputTypes["BackupMediaFields_focalX_Read"],
	update?:ResolverInputTypes["BackupMediaFields_focalX_Update"],
	delete?:ResolverInputTypes["BackupMediaFields_focalX_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaFields_focalX_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaFields_focalX_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaFields_focalX_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaFields_focalX_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaFields_focalY"]: AliasType<{
	create?:ResolverInputTypes["BackupMediaFields_focalY_Create"],
	read?:ResolverInputTypes["BackupMediaFields_focalY_Read"],
	update?:ResolverInputTypes["BackupMediaFields_focalY_Update"],
	delete?:ResolverInputTypes["BackupMediaFields_focalY_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaFields_focalY_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaFields_focalY_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaFields_focalY_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaFields_focalY_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaFields_sizes"]: AliasType<{
	create?:ResolverInputTypes["BackupMediaFields_sizes_Create"],
	read?:ResolverInputTypes["BackupMediaFields_sizes_Read"],
	update?:ResolverInputTypes["BackupMediaFields_sizes_Update"],
	delete?:ResolverInputTypes["BackupMediaFields_sizes_Delete"],
	fields?:ResolverInputTypes["BackupMediaFields_sizes_Fields"],
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaFields_sizes_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaFields_sizes_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaFields_sizes_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaFields_sizes_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaFields_sizes_Fields"]: AliasType<{
	thumbnail?:ResolverInputTypes["BackupMediaFields_sizes_thumbnail"],
	sixteenByNineMedium?:ResolverInputTypes["BackupMediaFields_sizes_sixteenByNineMedium"],
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaFields_sizes_thumbnail"]: AliasType<{
	create?:ResolverInputTypes["BackupMediaFields_sizes_thumbnail_Create"],
	read?:ResolverInputTypes["BackupMediaFields_sizes_thumbnail_Read"],
	update?:ResolverInputTypes["BackupMediaFields_sizes_thumbnail_Update"],
	delete?:ResolverInputTypes["BackupMediaFields_sizes_thumbnail_Delete"],
	fields?:ResolverInputTypes["BackupMediaFields_sizes_thumbnail_Fields"],
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaFields_sizes_thumbnail_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaFields_sizes_thumbnail_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaFields_sizes_thumbnail_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaFields_sizes_thumbnail_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaFields_sizes_thumbnail_Fields"]: AliasType<{
	url?:ResolverInputTypes["BackupMediaFields_sizes_thumbnail_url"],
	width?:ResolverInputTypes["BackupMediaFields_sizes_thumbnail_width"],
	height?:ResolverInputTypes["BackupMediaFields_sizes_thumbnail_height"],
	mimeType?:ResolverInputTypes["BackupMediaFields_sizes_thumbnail_mimeType"],
	filesize?:ResolverInputTypes["BackupMediaFields_sizes_thumbnail_filesize"],
	filename?:ResolverInputTypes["BackupMediaFields_sizes_thumbnail_filename"],
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaFields_sizes_thumbnail_url"]: AliasType<{
	create?:ResolverInputTypes["BackupMediaFields_sizes_thumbnail_url_Create"],
	read?:ResolverInputTypes["BackupMediaFields_sizes_thumbnail_url_Read"],
	update?:ResolverInputTypes["BackupMediaFields_sizes_thumbnail_url_Update"],
	delete?:ResolverInputTypes["BackupMediaFields_sizes_thumbnail_url_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaFields_sizes_thumbnail_url_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaFields_sizes_thumbnail_url_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaFields_sizes_thumbnail_url_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaFields_sizes_thumbnail_url_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaFields_sizes_thumbnail_width"]: AliasType<{
	create?:ResolverInputTypes["BackupMediaFields_sizes_thumbnail_width_Create"],
	read?:ResolverInputTypes["BackupMediaFields_sizes_thumbnail_width_Read"],
	update?:ResolverInputTypes["BackupMediaFields_sizes_thumbnail_width_Update"],
	delete?:ResolverInputTypes["BackupMediaFields_sizes_thumbnail_width_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaFields_sizes_thumbnail_width_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaFields_sizes_thumbnail_width_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaFields_sizes_thumbnail_width_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaFields_sizes_thumbnail_width_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaFields_sizes_thumbnail_height"]: AliasType<{
	create?:ResolverInputTypes["BackupMediaFields_sizes_thumbnail_height_Create"],
	read?:ResolverInputTypes["BackupMediaFields_sizes_thumbnail_height_Read"],
	update?:ResolverInputTypes["BackupMediaFields_sizes_thumbnail_height_Update"],
	delete?:ResolverInputTypes["BackupMediaFields_sizes_thumbnail_height_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaFields_sizes_thumbnail_height_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaFields_sizes_thumbnail_height_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaFields_sizes_thumbnail_height_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaFields_sizes_thumbnail_height_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaFields_sizes_thumbnail_mimeType"]: AliasType<{
	create?:ResolverInputTypes["BackupMediaFields_sizes_thumbnail_mimeType_Create"],
	read?:ResolverInputTypes["BackupMediaFields_sizes_thumbnail_mimeType_Read"],
	update?:ResolverInputTypes["BackupMediaFields_sizes_thumbnail_mimeType_Update"],
	delete?:ResolverInputTypes["BackupMediaFields_sizes_thumbnail_mimeType_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaFields_sizes_thumbnail_mimeType_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaFields_sizes_thumbnail_mimeType_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaFields_sizes_thumbnail_mimeType_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaFields_sizes_thumbnail_mimeType_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaFields_sizes_thumbnail_filesize"]: AliasType<{
	create?:ResolverInputTypes["BackupMediaFields_sizes_thumbnail_filesize_Create"],
	read?:ResolverInputTypes["BackupMediaFields_sizes_thumbnail_filesize_Read"],
	update?:ResolverInputTypes["BackupMediaFields_sizes_thumbnail_filesize_Update"],
	delete?:ResolverInputTypes["BackupMediaFields_sizes_thumbnail_filesize_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaFields_sizes_thumbnail_filesize_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaFields_sizes_thumbnail_filesize_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaFields_sizes_thumbnail_filesize_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaFields_sizes_thumbnail_filesize_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaFields_sizes_thumbnail_filename"]: AliasType<{
	create?:ResolverInputTypes["BackupMediaFields_sizes_thumbnail_filename_Create"],
	read?:ResolverInputTypes["BackupMediaFields_sizes_thumbnail_filename_Read"],
	update?:ResolverInputTypes["BackupMediaFields_sizes_thumbnail_filename_Update"],
	delete?:ResolverInputTypes["BackupMediaFields_sizes_thumbnail_filename_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaFields_sizes_thumbnail_filename_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaFields_sizes_thumbnail_filename_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaFields_sizes_thumbnail_filename_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaFields_sizes_thumbnail_filename_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaFields_sizes_sixteenByNineMedium"]: AliasType<{
	create?:ResolverInputTypes["BackupMediaFields_sizes_sixteenByNineMedium_Create"],
	read?:ResolverInputTypes["BackupMediaFields_sizes_sixteenByNineMedium_Read"],
	update?:ResolverInputTypes["BackupMediaFields_sizes_sixteenByNineMedium_Update"],
	delete?:ResolverInputTypes["BackupMediaFields_sizes_sixteenByNineMedium_Delete"],
	fields?:ResolverInputTypes["BackupMediaFields_sizes_sixteenByNineMedium_Fields"],
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaFields_sizes_sixteenByNineMedium_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaFields_sizes_sixteenByNineMedium_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaFields_sizes_sixteenByNineMedium_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaFields_sizes_sixteenByNineMedium_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaFields_sizes_sixteenByNineMedium_Fields"]: AliasType<{
	url?:ResolverInputTypes["BackupMediaFields_sizes_sixteenByNineMedium_url"],
	width?:ResolverInputTypes["BackupMediaFields_sizes_sixteenByNineMedium_width"],
	height?:ResolverInputTypes["BackupMediaFields_sizes_sixteenByNineMedium_height"],
	mimeType?:ResolverInputTypes["BackupMediaFields_sizes_sixteenByNineMedium_mimeType"],
	filesize?:ResolverInputTypes["BackupMediaFields_sizes_sixteenByNineMedium_filesize"],
	filename?:ResolverInputTypes["BackupMediaFields_sizes_sixteenByNineMedium_filename"],
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaFields_sizes_sixteenByNineMedium_url"]: AliasType<{
	create?:ResolverInputTypes["BackupMediaFields_sizes_sixteenByNineMedium_url_Create"],
	read?:ResolverInputTypes["BackupMediaFields_sizes_sixteenByNineMedium_url_Read"],
	update?:ResolverInputTypes["BackupMediaFields_sizes_sixteenByNineMedium_url_Update"],
	delete?:ResolverInputTypes["BackupMediaFields_sizes_sixteenByNineMedium_url_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaFields_sizes_sixteenByNineMedium_url_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaFields_sizes_sixteenByNineMedium_url_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaFields_sizes_sixteenByNineMedium_url_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaFields_sizes_sixteenByNineMedium_url_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaFields_sizes_sixteenByNineMedium_width"]: AliasType<{
	create?:ResolverInputTypes["BackupMediaFields_sizes_sixteenByNineMedium_width_Create"],
	read?:ResolverInputTypes["BackupMediaFields_sizes_sixteenByNineMedium_width_Read"],
	update?:ResolverInputTypes["BackupMediaFields_sizes_sixteenByNineMedium_width_Update"],
	delete?:ResolverInputTypes["BackupMediaFields_sizes_sixteenByNineMedium_width_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaFields_sizes_sixteenByNineMedium_width_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaFields_sizes_sixteenByNineMedium_width_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaFields_sizes_sixteenByNineMedium_width_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaFields_sizes_sixteenByNineMedium_width_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaFields_sizes_sixteenByNineMedium_height"]: AliasType<{
	create?:ResolverInputTypes["BackupMediaFields_sizes_sixteenByNineMedium_height_Create"],
	read?:ResolverInputTypes["BackupMediaFields_sizes_sixteenByNineMedium_height_Read"],
	update?:ResolverInputTypes["BackupMediaFields_sizes_sixteenByNineMedium_height_Update"],
	delete?:ResolverInputTypes["BackupMediaFields_sizes_sixteenByNineMedium_height_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaFields_sizes_sixteenByNineMedium_height_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaFields_sizes_sixteenByNineMedium_height_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaFields_sizes_sixteenByNineMedium_height_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaFields_sizes_sixteenByNineMedium_height_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaFields_sizes_sixteenByNineMedium_mimeType"]: AliasType<{
	create?:ResolverInputTypes["BackupMediaFields_sizes_sixteenByNineMedium_mimeType_Create"],
	read?:ResolverInputTypes["BackupMediaFields_sizes_sixteenByNineMedium_mimeType_Read"],
	update?:ResolverInputTypes["BackupMediaFields_sizes_sixteenByNineMedium_mimeType_Update"],
	delete?:ResolverInputTypes["BackupMediaFields_sizes_sixteenByNineMedium_mimeType_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaFields_sizes_sixteenByNineMedium_mimeType_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaFields_sizes_sixteenByNineMedium_mimeType_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaFields_sizes_sixteenByNineMedium_mimeType_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaFields_sizes_sixteenByNineMedium_mimeType_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaFields_sizes_sixteenByNineMedium_filesize"]: AliasType<{
	create?:ResolverInputTypes["BackupMediaFields_sizes_sixteenByNineMedium_filesize_Create"],
	read?:ResolverInputTypes["BackupMediaFields_sizes_sixteenByNineMedium_filesize_Read"],
	update?:ResolverInputTypes["BackupMediaFields_sizes_sixteenByNineMedium_filesize_Update"],
	delete?:ResolverInputTypes["BackupMediaFields_sizes_sixteenByNineMedium_filesize_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaFields_sizes_sixteenByNineMedium_filesize_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaFields_sizes_sixteenByNineMedium_filesize_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaFields_sizes_sixteenByNineMedium_filesize_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaFields_sizes_sixteenByNineMedium_filesize_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaFields_sizes_sixteenByNineMedium_filename"]: AliasType<{
	create?:ResolverInputTypes["BackupMediaFields_sizes_sixteenByNineMedium_filename_Create"],
	read?:ResolverInputTypes["BackupMediaFields_sizes_sixteenByNineMedium_filename_Read"],
	update?:ResolverInputTypes["BackupMediaFields_sizes_sixteenByNineMedium_filename_Update"],
	delete?:ResolverInputTypes["BackupMediaFields_sizes_sixteenByNineMedium_filename_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaFields_sizes_sixteenByNineMedium_filename_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaFields_sizes_sixteenByNineMedium_filename_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaFields_sizes_sixteenByNineMedium_filename_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaFields_sizes_sixteenByNineMedium_filename_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaCreateAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaReadAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaUpdateAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BackupMediaDeleteAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["account_suspend_requestsAccess"]: AliasType<{
	fields?:ResolverInputTypes["AccountSuspendRequestsFields"],
	create?:ResolverInputTypes["AccountSuspendRequestsCreateAccess"],
	read?:ResolverInputTypes["AccountSuspendRequestsReadAccess"],
	update?:ResolverInputTypes["AccountSuspendRequestsUpdateAccess"],
	delete?:ResolverInputTypes["AccountSuspendRequestsDeleteAccess"],
		__typename?: boolean | `@${string}`
}>;
	["AccountSuspendRequestsFields"]: AliasType<{
	user?:ResolverInputTypes["AccountSuspendRequestsFields_user"],
	handledBy?:ResolverInputTypes["AccountSuspendRequestsFields_handledBy"],
	updatedAt?:ResolverInputTypes["AccountSuspendRequestsFields_updatedAt"],
	createdAt?:ResolverInputTypes["AccountSuspendRequestsFields_createdAt"],
		__typename?: boolean | `@${string}`
}>;
	["AccountSuspendRequestsFields_user"]: AliasType<{
	create?:ResolverInputTypes["AccountSuspendRequestsFields_user_Create"],
	read?:ResolverInputTypes["AccountSuspendRequestsFields_user_Read"],
	update?:ResolverInputTypes["AccountSuspendRequestsFields_user_Update"],
	delete?:ResolverInputTypes["AccountSuspendRequestsFields_user_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["AccountSuspendRequestsFields_user_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AccountSuspendRequestsFields_user_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AccountSuspendRequestsFields_user_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AccountSuspendRequestsFields_user_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AccountSuspendRequestsFields_handledBy"]: AliasType<{
	create?:ResolverInputTypes["AccountSuspendRequestsFields_handledBy_Create"],
	read?:ResolverInputTypes["AccountSuspendRequestsFields_handledBy_Read"],
	update?:ResolverInputTypes["AccountSuspendRequestsFields_handledBy_Update"],
	delete?:ResolverInputTypes["AccountSuspendRequestsFields_handledBy_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["AccountSuspendRequestsFields_handledBy_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AccountSuspendRequestsFields_handledBy_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AccountSuspendRequestsFields_handledBy_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AccountSuspendRequestsFields_handledBy_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AccountSuspendRequestsFields_updatedAt"]: AliasType<{
	create?:ResolverInputTypes["AccountSuspendRequestsFields_updatedAt_Create"],
	read?:ResolverInputTypes["AccountSuspendRequestsFields_updatedAt_Read"],
	update?:ResolverInputTypes["AccountSuspendRequestsFields_updatedAt_Update"],
	delete?:ResolverInputTypes["AccountSuspendRequestsFields_updatedAt_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["AccountSuspendRequestsFields_updatedAt_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AccountSuspendRequestsFields_updatedAt_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AccountSuspendRequestsFields_updatedAt_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AccountSuspendRequestsFields_updatedAt_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AccountSuspendRequestsFields_createdAt"]: AliasType<{
	create?:ResolverInputTypes["AccountSuspendRequestsFields_createdAt_Create"],
	read?:ResolverInputTypes["AccountSuspendRequestsFields_createdAt_Read"],
	update?:ResolverInputTypes["AccountSuspendRequestsFields_createdAt_Update"],
	delete?:ResolverInputTypes["AccountSuspendRequestsFields_createdAt_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["AccountSuspendRequestsFields_createdAt_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AccountSuspendRequestsFields_createdAt_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AccountSuspendRequestsFields_createdAt_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AccountSuspendRequestsFields_createdAt_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AccountSuspendRequestsCreateAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AccountSuspendRequestsReadAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AccountSuspendRequestsUpdateAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AccountSuspendRequestsDeleteAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["devicesAccess"]: AliasType<{
	fields?:ResolverInputTypes["DevicesFields"],
	create?:ResolverInputTypes["DevicesCreateAccess"],
	read?:ResolverInputTypes["DevicesReadAccess"],
	update?:ResolverInputTypes["DevicesUpdateAccess"],
	delete?:ResolverInputTypes["DevicesDeleteAccess"],
		__typename?: boolean | `@${string}`
}>;
	["DevicesFields"]: AliasType<{
	user?:ResolverInputTypes["DevicesFields_user"],
	udid?:ResolverInputTypes["DevicesFields_udid"],
	pushToken?:ResolverInputTypes["DevicesFields_pushToken"],
	updatedAt?:ResolverInputTypes["DevicesFields_updatedAt"],
	createdAt?:ResolverInputTypes["DevicesFields_createdAt"],
		__typename?: boolean | `@${string}`
}>;
	["DevicesFields_user"]: AliasType<{
	create?:ResolverInputTypes["DevicesFields_user_Create"],
	read?:ResolverInputTypes["DevicesFields_user_Read"],
	update?:ResolverInputTypes["DevicesFields_user_Update"],
	delete?:ResolverInputTypes["DevicesFields_user_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["DevicesFields_user_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DevicesFields_user_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DevicesFields_user_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DevicesFields_user_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DevicesFields_udid"]: AliasType<{
	create?:ResolverInputTypes["DevicesFields_udid_Create"],
	read?:ResolverInputTypes["DevicesFields_udid_Read"],
	update?:ResolverInputTypes["DevicesFields_udid_Update"],
	delete?:ResolverInputTypes["DevicesFields_udid_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["DevicesFields_udid_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DevicesFields_udid_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DevicesFields_udid_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DevicesFields_udid_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DevicesFields_pushToken"]: AliasType<{
	create?:ResolverInputTypes["DevicesFields_pushToken_Create"],
	read?:ResolverInputTypes["DevicesFields_pushToken_Read"],
	update?:ResolverInputTypes["DevicesFields_pushToken_Update"],
	delete?:ResolverInputTypes["DevicesFields_pushToken_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["DevicesFields_pushToken_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DevicesFields_pushToken_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DevicesFields_pushToken_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DevicesFields_pushToken_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DevicesFields_updatedAt"]: AliasType<{
	create?:ResolverInputTypes["DevicesFields_updatedAt_Create"],
	read?:ResolverInputTypes["DevicesFields_updatedAt_Read"],
	update?:ResolverInputTypes["DevicesFields_updatedAt_Update"],
	delete?:ResolverInputTypes["DevicesFields_updatedAt_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["DevicesFields_updatedAt_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DevicesFields_updatedAt_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DevicesFields_updatedAt_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DevicesFields_updatedAt_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DevicesFields_createdAt"]: AliasType<{
	create?:ResolverInputTypes["DevicesFields_createdAt_Create"],
	read?:ResolverInputTypes["DevicesFields_createdAt_Read"],
	update?:ResolverInputTypes["DevicesFields_createdAt_Update"],
	delete?:ResolverInputTypes["DevicesFields_createdAt_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["DevicesFields_createdAt_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DevicesFields_createdAt_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DevicesFields_createdAt_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DevicesFields_createdAt_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DevicesCreateAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DevicesReadAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DevicesUpdateAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DevicesDeleteAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["notificationsAccess"]: AliasType<{
	fields?:ResolverInputTypes["NotificationsFields"],
	create?:ResolverInputTypes["NotificationsCreateAccess"],
	read?:ResolverInputTypes["NotificationsReadAccess"],
	update?:ResolverInputTypes["NotificationsUpdateAccess"],
	delete?:ResolverInputTypes["NotificationsDeleteAccess"],
		__typename?: boolean | `@${string}`
}>;
	["NotificationsFields"]: AliasType<{
	receiver?:ResolverInputTypes["NotificationsFields_receiver"],
	type?:ResolverInputTypes["NotificationsFields_type"],
	meta?:ResolverInputTypes["NotificationsFields_meta"],
	message?:ResolverInputTypes["NotificationsFields_message"],
	sender?:ResolverInputTypes["NotificationsFields_sender"],
	readed?:ResolverInputTypes["NotificationsFields_readed"],
	deletedByUser?:ResolverInputTypes["NotificationsFields_deletedByUser"],
	updatedAt?:ResolverInputTypes["NotificationsFields_updatedAt"],
	createdAt?:ResolverInputTypes["NotificationsFields_createdAt"],
		__typename?: boolean | `@${string}`
}>;
	["NotificationsFields_receiver"]: AliasType<{
	create?:ResolverInputTypes["NotificationsFields_receiver_Create"],
	read?:ResolverInputTypes["NotificationsFields_receiver_Read"],
	update?:ResolverInputTypes["NotificationsFields_receiver_Update"],
	delete?:ResolverInputTypes["NotificationsFields_receiver_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["NotificationsFields_receiver_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["NotificationsFields_receiver_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["NotificationsFields_receiver_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["NotificationsFields_receiver_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["NotificationsFields_type"]: AliasType<{
	create?:ResolverInputTypes["NotificationsFields_type_Create"],
	read?:ResolverInputTypes["NotificationsFields_type_Read"],
	update?:ResolverInputTypes["NotificationsFields_type_Update"],
	delete?:ResolverInputTypes["NotificationsFields_type_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["NotificationsFields_type_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["NotificationsFields_type_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["NotificationsFields_type_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["NotificationsFields_type_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["NotificationsFields_meta"]: AliasType<{
	create?:ResolverInputTypes["NotificationsFields_meta_Create"],
	read?:ResolverInputTypes["NotificationsFields_meta_Read"],
	update?:ResolverInputTypes["NotificationsFields_meta_Update"],
	delete?:ResolverInputTypes["NotificationsFields_meta_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["NotificationsFields_meta_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["NotificationsFields_meta_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["NotificationsFields_meta_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["NotificationsFields_meta_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["NotificationsFields_message"]: AliasType<{
	create?:ResolverInputTypes["NotificationsFields_message_Create"],
	read?:ResolverInputTypes["NotificationsFields_message_Read"],
	update?:ResolverInputTypes["NotificationsFields_message_Update"],
	delete?:ResolverInputTypes["NotificationsFields_message_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["NotificationsFields_message_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["NotificationsFields_message_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["NotificationsFields_message_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["NotificationsFields_message_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["NotificationsFields_sender"]: AliasType<{
	create?:ResolverInputTypes["NotificationsFields_sender_Create"],
	read?:ResolverInputTypes["NotificationsFields_sender_Read"],
	update?:ResolverInputTypes["NotificationsFields_sender_Update"],
	delete?:ResolverInputTypes["NotificationsFields_sender_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["NotificationsFields_sender_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["NotificationsFields_sender_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["NotificationsFields_sender_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["NotificationsFields_sender_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["NotificationsFields_readed"]: AliasType<{
	create?:ResolverInputTypes["NotificationsFields_readed_Create"],
	read?:ResolverInputTypes["NotificationsFields_readed_Read"],
	update?:ResolverInputTypes["NotificationsFields_readed_Update"],
	delete?:ResolverInputTypes["NotificationsFields_readed_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["NotificationsFields_readed_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["NotificationsFields_readed_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["NotificationsFields_readed_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["NotificationsFields_readed_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["NotificationsFields_deletedByUser"]: AliasType<{
	create?:ResolverInputTypes["NotificationsFields_deletedByUser_Create"],
	read?:ResolverInputTypes["NotificationsFields_deletedByUser_Read"],
	update?:ResolverInputTypes["NotificationsFields_deletedByUser_Update"],
	delete?:ResolverInputTypes["NotificationsFields_deletedByUser_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["NotificationsFields_deletedByUser_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["NotificationsFields_deletedByUser_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["NotificationsFields_deletedByUser_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["NotificationsFields_deletedByUser_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["NotificationsFields_updatedAt"]: AliasType<{
	create?:ResolverInputTypes["NotificationsFields_updatedAt_Create"],
	read?:ResolverInputTypes["NotificationsFields_updatedAt_Read"],
	update?:ResolverInputTypes["NotificationsFields_updatedAt_Update"],
	delete?:ResolverInputTypes["NotificationsFields_updatedAt_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["NotificationsFields_updatedAt_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["NotificationsFields_updatedAt_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["NotificationsFields_updatedAt_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["NotificationsFields_updatedAt_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["NotificationsFields_createdAt"]: AliasType<{
	create?:ResolverInputTypes["NotificationsFields_createdAt_Create"],
	read?:ResolverInputTypes["NotificationsFields_createdAt_Read"],
	update?:ResolverInputTypes["NotificationsFields_createdAt_Update"],
	delete?:ResolverInputTypes["NotificationsFields_createdAt_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["NotificationsFields_createdAt_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["NotificationsFields_createdAt_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["NotificationsFields_createdAt_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["NotificationsFields_createdAt_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["NotificationsCreateAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["NotificationsReadAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["NotificationsUpdateAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["NotificationsDeleteAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["redirectSettingAccess"]: AliasType<{
	fields?:ResolverInputTypes["RedirectSettingFields"],
	create?:ResolverInputTypes["RedirectSettingCreateAccess"],
	read?:ResolverInputTypes["RedirectSettingReadAccess"],
	update?:ResolverInputTypes["RedirectSettingUpdateAccess"],
	delete?:ResolverInputTypes["RedirectSettingDeleteAccess"],
		__typename?: boolean | `@${string}`
}>;
	["RedirectSettingFields"]: AliasType<{
	from?:ResolverInputTypes["RedirectSettingFields_from"],
	to?:ResolverInputTypes["RedirectSettingFields_to"],
	updatedAt?:ResolverInputTypes["RedirectSettingFields_updatedAt"],
	createdAt?:ResolverInputTypes["RedirectSettingFields_createdAt"],
		__typename?: boolean | `@${string}`
}>;
	["RedirectSettingFields_from"]: AliasType<{
	create?:ResolverInputTypes["RedirectSettingFields_from_Create"],
	read?:ResolverInputTypes["RedirectSettingFields_from_Read"],
	update?:ResolverInputTypes["RedirectSettingFields_from_Update"],
	delete?:ResolverInputTypes["RedirectSettingFields_from_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["RedirectSettingFields_from_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedirectSettingFields_from_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedirectSettingFields_from_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedirectSettingFields_from_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedirectSettingFields_to"]: AliasType<{
	create?:ResolverInputTypes["RedirectSettingFields_to_Create"],
	read?:ResolverInputTypes["RedirectSettingFields_to_Read"],
	update?:ResolverInputTypes["RedirectSettingFields_to_Update"],
	delete?:ResolverInputTypes["RedirectSettingFields_to_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["RedirectSettingFields_to_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedirectSettingFields_to_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedirectSettingFields_to_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedirectSettingFields_to_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedirectSettingFields_updatedAt"]: AliasType<{
	create?:ResolverInputTypes["RedirectSettingFields_updatedAt_Create"],
	read?:ResolverInputTypes["RedirectSettingFields_updatedAt_Read"],
	update?:ResolverInputTypes["RedirectSettingFields_updatedAt_Update"],
	delete?:ResolverInputTypes["RedirectSettingFields_updatedAt_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["RedirectSettingFields_updatedAt_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedirectSettingFields_updatedAt_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedirectSettingFields_updatedAt_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedirectSettingFields_updatedAt_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedirectSettingFields_createdAt"]: AliasType<{
	create?:ResolverInputTypes["RedirectSettingFields_createdAt_Create"],
	read?:ResolverInputTypes["RedirectSettingFields_createdAt_Read"],
	update?:ResolverInputTypes["RedirectSettingFields_createdAt_Update"],
	delete?:ResolverInputTypes["RedirectSettingFields_createdAt_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["RedirectSettingFields_createdAt_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedirectSettingFields_createdAt_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedirectSettingFields_createdAt_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedirectSettingFields_createdAt_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedirectSettingCreateAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedirectSettingReadAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedirectSettingUpdateAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedirectSettingDeleteAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ranking_eventsAccess"]: AliasType<{
	fields?:ResolverInputTypes["RankingEventsFields"],
	create?:ResolverInputTypes["RankingEventsCreateAccess"],
	read?:ResolverInputTypes["RankingEventsReadAccess"],
	update?:ResolverInputTypes["RankingEventsUpdateAccess"],
	delete?:ResolverInputTypes["RankingEventsDeleteAccess"],
		__typename?: boolean | `@${string}`
}>;
	["RankingEventsFields"]: AliasType<{
	name?:ResolverInputTypes["RankingEventsFields_name"],
	visible?:ResolverInputTypes["RankingEventsFields_visible"],
	maxItems?:ResolverInputTypes["RankingEventsFields_maxItems"],
	template?:ResolverInputTypes["RankingEventsFields_template"],
	list?:ResolverInputTypes["RankingEventsFields_list"],
	region?:ResolverInputTypes["RankingEventsFields_region"],
	updatedAt?:ResolverInputTypes["RankingEventsFields_updatedAt"],
	createdAt?:ResolverInputTypes["RankingEventsFields_createdAt"],
		__typename?: boolean | `@${string}`
}>;
	["RankingEventsFields_name"]: AliasType<{
	create?:ResolverInputTypes["RankingEventsFields_name_Create"],
	read?:ResolverInputTypes["RankingEventsFields_name_Read"],
	update?:ResolverInputTypes["RankingEventsFields_name_Update"],
	delete?:ResolverInputTypes["RankingEventsFields_name_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["RankingEventsFields_name_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RankingEventsFields_name_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RankingEventsFields_name_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RankingEventsFields_name_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RankingEventsFields_visible"]: AliasType<{
	create?:ResolverInputTypes["RankingEventsFields_visible_Create"],
	read?:ResolverInputTypes["RankingEventsFields_visible_Read"],
	update?:ResolverInputTypes["RankingEventsFields_visible_Update"],
	delete?:ResolverInputTypes["RankingEventsFields_visible_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["RankingEventsFields_visible_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RankingEventsFields_visible_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RankingEventsFields_visible_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RankingEventsFields_visible_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RankingEventsFields_maxItems"]: AliasType<{
	create?:ResolverInputTypes["RankingEventsFields_maxItems_Create"],
	read?:ResolverInputTypes["RankingEventsFields_maxItems_Read"],
	update?:ResolverInputTypes["RankingEventsFields_maxItems_Update"],
	delete?:ResolverInputTypes["RankingEventsFields_maxItems_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["RankingEventsFields_maxItems_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RankingEventsFields_maxItems_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RankingEventsFields_maxItems_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RankingEventsFields_maxItems_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RankingEventsFields_template"]: AliasType<{
	create?:ResolverInputTypes["RankingEventsFields_template_Create"],
	read?:ResolverInputTypes["RankingEventsFields_template_Read"],
	update?:ResolverInputTypes["RankingEventsFields_template_Update"],
	delete?:ResolverInputTypes["RankingEventsFields_template_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["RankingEventsFields_template_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RankingEventsFields_template_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RankingEventsFields_template_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RankingEventsFields_template_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RankingEventsFields_list"]: AliasType<{
	create?:ResolverInputTypes["RankingEventsFields_list_Create"],
	read?:ResolverInputTypes["RankingEventsFields_list_Read"],
	update?:ResolverInputTypes["RankingEventsFields_list_Update"],
	delete?:ResolverInputTypes["RankingEventsFields_list_Delete"],
	fields?:ResolverInputTypes["RankingEventsFields_list_Fields"],
		__typename?: boolean | `@${string}`
}>;
	["RankingEventsFields_list_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RankingEventsFields_list_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RankingEventsFields_list_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RankingEventsFields_list_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RankingEventsFields_list_Fields"]: AliasType<{
	item?:ResolverInputTypes["RankingEventsFields_list_item"],
	id?:ResolverInputTypes["RankingEventsFields_list_id"],
		__typename?: boolean | `@${string}`
}>;
	["RankingEventsFields_list_item"]: AliasType<{
	create?:ResolverInputTypes["RankingEventsFields_list_item_Create"],
	read?:ResolverInputTypes["RankingEventsFields_list_item_Read"],
	update?:ResolverInputTypes["RankingEventsFields_list_item_Update"],
	delete?:ResolverInputTypes["RankingEventsFields_list_item_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["RankingEventsFields_list_item_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RankingEventsFields_list_item_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RankingEventsFields_list_item_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RankingEventsFields_list_item_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RankingEventsFields_list_id"]: AliasType<{
	create?:ResolverInputTypes["RankingEventsFields_list_id_Create"],
	read?:ResolverInputTypes["RankingEventsFields_list_id_Read"],
	update?:ResolverInputTypes["RankingEventsFields_list_id_Update"],
	delete?:ResolverInputTypes["RankingEventsFields_list_id_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["RankingEventsFields_list_id_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RankingEventsFields_list_id_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RankingEventsFields_list_id_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RankingEventsFields_list_id_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RankingEventsFields_region"]: AliasType<{
	create?:ResolverInputTypes["RankingEventsFields_region_Create"],
	read?:ResolverInputTypes["RankingEventsFields_region_Read"],
	update?:ResolverInputTypes["RankingEventsFields_region_Update"],
	delete?:ResolverInputTypes["RankingEventsFields_region_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["RankingEventsFields_region_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RankingEventsFields_region_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RankingEventsFields_region_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RankingEventsFields_region_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RankingEventsFields_updatedAt"]: AliasType<{
	create?:ResolverInputTypes["RankingEventsFields_updatedAt_Create"],
	read?:ResolverInputTypes["RankingEventsFields_updatedAt_Read"],
	update?:ResolverInputTypes["RankingEventsFields_updatedAt_Update"],
	delete?:ResolverInputTypes["RankingEventsFields_updatedAt_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["RankingEventsFields_updatedAt_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RankingEventsFields_updatedAt_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RankingEventsFields_updatedAt_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RankingEventsFields_updatedAt_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RankingEventsFields_createdAt"]: AliasType<{
	create?:ResolverInputTypes["RankingEventsFields_createdAt_Create"],
	read?:ResolverInputTypes["RankingEventsFields_createdAt_Read"],
	update?:ResolverInputTypes["RankingEventsFields_createdAt_Update"],
	delete?:ResolverInputTypes["RankingEventsFields_createdAt_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["RankingEventsFields_createdAt_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RankingEventsFields_createdAt_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RankingEventsFields_createdAt_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RankingEventsFields_createdAt_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RankingEventsCreateAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RankingEventsReadAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RankingEventsUpdateAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RankingEventsDeleteAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ranking_locationsAccess"]: AliasType<{
	fields?:ResolverInputTypes["RankingLocationsFields"],
	create?:ResolverInputTypes["RankingLocationsCreateAccess"],
	read?:ResolverInputTypes["RankingLocationsReadAccess"],
	update?:ResolverInputTypes["RankingLocationsUpdateAccess"],
	delete?:ResolverInputTypes["RankingLocationsDeleteAccess"],
		__typename?: boolean | `@${string}`
}>;
	["RankingLocationsFields"]: AliasType<{
	name?:ResolverInputTypes["RankingLocationsFields_name"],
	visible?:ResolverInputTypes["RankingLocationsFields_visible"],
	maxItems?:ResolverInputTypes["RankingLocationsFields_maxItems"],
	template?:ResolverInputTypes["RankingLocationsFields_template"],
	list?:ResolverInputTypes["RankingLocationsFields_list"],
	region?:ResolverInputTypes["RankingLocationsFields_region"],
	updatedAt?:ResolverInputTypes["RankingLocationsFields_updatedAt"],
	createdAt?:ResolverInputTypes["RankingLocationsFields_createdAt"],
		__typename?: boolean | `@${string}`
}>;
	["RankingLocationsFields_name"]: AliasType<{
	create?:ResolverInputTypes["RankingLocationsFields_name_Create"],
	read?:ResolverInputTypes["RankingLocationsFields_name_Read"],
	update?:ResolverInputTypes["RankingLocationsFields_name_Update"],
	delete?:ResolverInputTypes["RankingLocationsFields_name_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["RankingLocationsFields_name_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RankingLocationsFields_name_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RankingLocationsFields_name_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RankingLocationsFields_name_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RankingLocationsFields_visible"]: AliasType<{
	create?:ResolverInputTypes["RankingLocationsFields_visible_Create"],
	read?:ResolverInputTypes["RankingLocationsFields_visible_Read"],
	update?:ResolverInputTypes["RankingLocationsFields_visible_Update"],
	delete?:ResolverInputTypes["RankingLocationsFields_visible_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["RankingLocationsFields_visible_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RankingLocationsFields_visible_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RankingLocationsFields_visible_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RankingLocationsFields_visible_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RankingLocationsFields_maxItems"]: AliasType<{
	create?:ResolverInputTypes["RankingLocationsFields_maxItems_Create"],
	read?:ResolverInputTypes["RankingLocationsFields_maxItems_Read"],
	update?:ResolverInputTypes["RankingLocationsFields_maxItems_Update"],
	delete?:ResolverInputTypes["RankingLocationsFields_maxItems_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["RankingLocationsFields_maxItems_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RankingLocationsFields_maxItems_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RankingLocationsFields_maxItems_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RankingLocationsFields_maxItems_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RankingLocationsFields_template"]: AliasType<{
	create?:ResolverInputTypes["RankingLocationsFields_template_Create"],
	read?:ResolverInputTypes["RankingLocationsFields_template_Read"],
	update?:ResolverInputTypes["RankingLocationsFields_template_Update"],
	delete?:ResolverInputTypes["RankingLocationsFields_template_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["RankingLocationsFields_template_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RankingLocationsFields_template_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RankingLocationsFields_template_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RankingLocationsFields_template_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RankingLocationsFields_list"]: AliasType<{
	create?:ResolverInputTypes["RankingLocationsFields_list_Create"],
	read?:ResolverInputTypes["RankingLocationsFields_list_Read"],
	update?:ResolverInputTypes["RankingLocationsFields_list_Update"],
	delete?:ResolverInputTypes["RankingLocationsFields_list_Delete"],
	fields?:ResolverInputTypes["RankingLocationsFields_list_Fields"],
		__typename?: boolean | `@${string}`
}>;
	["RankingLocationsFields_list_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RankingLocationsFields_list_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RankingLocationsFields_list_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RankingLocationsFields_list_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RankingLocationsFields_list_Fields"]: AliasType<{
	item?:ResolverInputTypes["RankingLocationsFields_list_item"],
	id?:ResolverInputTypes["RankingLocationsFields_list_id"],
		__typename?: boolean | `@${string}`
}>;
	["RankingLocationsFields_list_item"]: AliasType<{
	create?:ResolverInputTypes["RankingLocationsFields_list_item_Create"],
	read?:ResolverInputTypes["RankingLocationsFields_list_item_Read"],
	update?:ResolverInputTypes["RankingLocationsFields_list_item_Update"],
	delete?:ResolverInputTypes["RankingLocationsFields_list_item_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["RankingLocationsFields_list_item_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RankingLocationsFields_list_item_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RankingLocationsFields_list_item_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RankingLocationsFields_list_item_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RankingLocationsFields_list_id"]: AliasType<{
	create?:ResolverInputTypes["RankingLocationsFields_list_id_Create"],
	read?:ResolverInputTypes["RankingLocationsFields_list_id_Read"],
	update?:ResolverInputTypes["RankingLocationsFields_list_id_Update"],
	delete?:ResolverInputTypes["RankingLocationsFields_list_id_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["RankingLocationsFields_list_id_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RankingLocationsFields_list_id_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RankingLocationsFields_list_id_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RankingLocationsFields_list_id_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RankingLocationsFields_region"]: AliasType<{
	create?:ResolverInputTypes["RankingLocationsFields_region_Create"],
	read?:ResolverInputTypes["RankingLocationsFields_region_Read"],
	update?:ResolverInputTypes["RankingLocationsFields_region_Update"],
	delete?:ResolverInputTypes["RankingLocationsFields_region_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["RankingLocationsFields_region_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RankingLocationsFields_region_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RankingLocationsFields_region_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RankingLocationsFields_region_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RankingLocationsFields_updatedAt"]: AliasType<{
	create?:ResolverInputTypes["RankingLocationsFields_updatedAt_Create"],
	read?:ResolverInputTypes["RankingLocationsFields_updatedAt_Read"],
	update?:ResolverInputTypes["RankingLocationsFields_updatedAt_Update"],
	delete?:ResolverInputTypes["RankingLocationsFields_updatedAt_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["RankingLocationsFields_updatedAt_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RankingLocationsFields_updatedAt_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RankingLocationsFields_updatedAt_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RankingLocationsFields_updatedAt_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RankingLocationsFields_createdAt"]: AliasType<{
	create?:ResolverInputTypes["RankingLocationsFields_createdAt_Create"],
	read?:ResolverInputTypes["RankingLocationsFields_createdAt_Read"],
	update?:ResolverInputTypes["RankingLocationsFields_createdAt_Update"],
	delete?:ResolverInputTypes["RankingLocationsFields_createdAt_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["RankingLocationsFields_createdAt_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RankingLocationsFields_createdAt_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RankingLocationsFields_createdAt_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RankingLocationsFields_createdAt_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RankingLocationsCreateAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RankingLocationsReadAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RankingLocationsUpdateAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RankingLocationsDeleteAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["event_calendarAccess"]: AliasType<{
	fields?:ResolverInputTypes["EventCalendarFields"],
	create?:ResolverInputTypes["EventCalendarCreateAccess"],
	read?:ResolverInputTypes["EventCalendarReadAccess"],
	update?:ResolverInputTypes["EventCalendarUpdateAccess"],
	delete?:ResolverInputTypes["EventCalendarDeleteAccess"],
		__typename?: boolean | `@${string}`
}>;
	["EventCalendarFields"]: AliasType<{
	date?:ResolverInputTypes["EventCalendarFields_date"],
	item?:ResolverInputTypes["EventCalendarFields_item"],
	title?:ResolverInputTypes["EventCalendarFields_title"],
	region?:ResolverInputTypes["EventCalendarFields_region"],
	updatedAt?:ResolverInputTypes["EventCalendarFields_updatedAt"],
	createdAt?:ResolverInputTypes["EventCalendarFields_createdAt"],
		__typename?: boolean | `@${string}`
}>;
	["EventCalendarFields_date"]: AliasType<{
	create?:ResolverInputTypes["EventCalendarFields_date_Create"],
	read?:ResolverInputTypes["EventCalendarFields_date_Read"],
	update?:ResolverInputTypes["EventCalendarFields_date_Update"],
	delete?:ResolverInputTypes["EventCalendarFields_date_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventCalendarFields_date_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventCalendarFields_date_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventCalendarFields_date_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventCalendarFields_date_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventCalendarFields_item"]: AliasType<{
	create?:ResolverInputTypes["EventCalendarFields_item_Create"],
	read?:ResolverInputTypes["EventCalendarFields_item_Read"],
	update?:ResolverInputTypes["EventCalendarFields_item_Update"],
	delete?:ResolverInputTypes["EventCalendarFields_item_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventCalendarFields_item_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventCalendarFields_item_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventCalendarFields_item_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventCalendarFields_item_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventCalendarFields_title"]: AliasType<{
	create?:ResolverInputTypes["EventCalendarFields_title_Create"],
	read?:ResolverInputTypes["EventCalendarFields_title_Read"],
	update?:ResolverInputTypes["EventCalendarFields_title_Update"],
	delete?:ResolverInputTypes["EventCalendarFields_title_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventCalendarFields_title_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventCalendarFields_title_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventCalendarFields_title_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventCalendarFields_title_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventCalendarFields_region"]: AliasType<{
	create?:ResolverInputTypes["EventCalendarFields_region_Create"],
	read?:ResolverInputTypes["EventCalendarFields_region_Read"],
	update?:ResolverInputTypes["EventCalendarFields_region_Update"],
	delete?:ResolverInputTypes["EventCalendarFields_region_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventCalendarFields_region_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventCalendarFields_region_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventCalendarFields_region_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventCalendarFields_region_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventCalendarFields_updatedAt"]: AliasType<{
	create?:ResolverInputTypes["EventCalendarFields_updatedAt_Create"],
	read?:ResolverInputTypes["EventCalendarFields_updatedAt_Read"],
	update?:ResolverInputTypes["EventCalendarFields_updatedAt_Update"],
	delete?:ResolverInputTypes["EventCalendarFields_updatedAt_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventCalendarFields_updatedAt_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventCalendarFields_updatedAt_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventCalendarFields_updatedAt_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventCalendarFields_updatedAt_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventCalendarFields_createdAt"]: AliasType<{
	create?:ResolverInputTypes["EventCalendarFields_createdAt_Create"],
	read?:ResolverInputTypes["EventCalendarFields_createdAt_Read"],
	update?:ResolverInputTypes["EventCalendarFields_createdAt_Update"],
	delete?:ResolverInputTypes["EventCalendarFields_createdAt_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventCalendarFields_createdAt_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventCalendarFields_createdAt_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventCalendarFields_createdAt_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventCalendarFields_createdAt_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventCalendarCreateAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventCalendarReadAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventCalendarUpdateAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventCalendarDeleteAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["editor_choicesAccess"]: AliasType<{
	fields?:ResolverInputTypes["EditorChoicesFields"],
	create?:ResolverInputTypes["EditorChoicesCreateAccess"],
	read?:ResolverInputTypes["EditorChoicesReadAccess"],
	update?:ResolverInputTypes["EditorChoicesUpdateAccess"],
	delete?:ResolverInputTypes["EditorChoicesDeleteAccess"],
		__typename?: boolean | `@${string}`
}>;
	["EditorChoicesFields"]: AliasType<{
	name?:ResolverInputTypes["EditorChoicesFields_name"],
	visible?:ResolverInputTypes["EditorChoicesFields_visible"],
	editorialPicks?:ResolverInputTypes["EditorChoicesFields_editorialPicks"],
	list?:ResolverInputTypes["EditorChoicesFields_list"],
	region?:ResolverInputTypes["EditorChoicesFields_region"],
	updatedAt?:ResolverInputTypes["EditorChoicesFields_updatedAt"],
	createdAt?:ResolverInputTypes["EditorChoicesFields_createdAt"],
		__typename?: boolean | `@${string}`
}>;
	["EditorChoicesFields_name"]: AliasType<{
	create?:ResolverInputTypes["EditorChoicesFields_name_Create"],
	read?:ResolverInputTypes["EditorChoicesFields_name_Read"],
	update?:ResolverInputTypes["EditorChoicesFields_name_Update"],
	delete?:ResolverInputTypes["EditorChoicesFields_name_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EditorChoicesFields_name_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EditorChoicesFields_name_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EditorChoicesFields_name_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EditorChoicesFields_name_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EditorChoicesFields_visible"]: AliasType<{
	create?:ResolverInputTypes["EditorChoicesFields_visible_Create"],
	read?:ResolverInputTypes["EditorChoicesFields_visible_Read"],
	update?:ResolverInputTypes["EditorChoicesFields_visible_Update"],
	delete?:ResolverInputTypes["EditorChoicesFields_visible_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EditorChoicesFields_visible_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EditorChoicesFields_visible_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EditorChoicesFields_visible_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EditorChoicesFields_visible_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EditorChoicesFields_editorialPicks"]: AliasType<{
	create?:ResolverInputTypes["EditorChoicesFields_editorialPicks_Create"],
	read?:ResolverInputTypes["EditorChoicesFields_editorialPicks_Read"],
	update?:ResolverInputTypes["EditorChoicesFields_editorialPicks_Update"],
	delete?:ResolverInputTypes["EditorChoicesFields_editorialPicks_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EditorChoicesFields_editorialPicks_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EditorChoicesFields_editorialPicks_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EditorChoicesFields_editorialPicks_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EditorChoicesFields_editorialPicks_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EditorChoicesFields_list"]: AliasType<{
	create?:ResolverInputTypes["EditorChoicesFields_list_Create"],
	read?:ResolverInputTypes["EditorChoicesFields_list_Read"],
	update?:ResolverInputTypes["EditorChoicesFields_list_Update"],
	delete?:ResolverInputTypes["EditorChoicesFields_list_Delete"],
	fields?:ResolverInputTypes["EditorChoicesFields_list_Fields"],
		__typename?: boolean | `@${string}`
}>;
	["EditorChoicesFields_list_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EditorChoicesFields_list_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EditorChoicesFields_list_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EditorChoicesFields_list_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EditorChoicesFields_list_Fields"]: AliasType<{
	item?:ResolverInputTypes["EditorChoicesFields_list_item"],
	id?:ResolverInputTypes["EditorChoicesFields_list_id"],
		__typename?: boolean | `@${string}`
}>;
	["EditorChoicesFields_list_item"]: AliasType<{
	create?:ResolverInputTypes["EditorChoicesFields_list_item_Create"],
	read?:ResolverInputTypes["EditorChoicesFields_list_item_Read"],
	update?:ResolverInputTypes["EditorChoicesFields_list_item_Update"],
	delete?:ResolverInputTypes["EditorChoicesFields_list_item_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EditorChoicesFields_list_item_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EditorChoicesFields_list_item_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EditorChoicesFields_list_item_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EditorChoicesFields_list_item_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EditorChoicesFields_list_id"]: AliasType<{
	create?:ResolverInputTypes["EditorChoicesFields_list_id_Create"],
	read?:ResolverInputTypes["EditorChoicesFields_list_id_Read"],
	update?:ResolverInputTypes["EditorChoicesFields_list_id_Update"],
	delete?:ResolverInputTypes["EditorChoicesFields_list_id_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EditorChoicesFields_list_id_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EditorChoicesFields_list_id_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EditorChoicesFields_list_id_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EditorChoicesFields_list_id_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EditorChoicesFields_region"]: AliasType<{
	create?:ResolverInputTypes["EditorChoicesFields_region_Create"],
	read?:ResolverInputTypes["EditorChoicesFields_region_Read"],
	update?:ResolverInputTypes["EditorChoicesFields_region_Update"],
	delete?:ResolverInputTypes["EditorChoicesFields_region_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EditorChoicesFields_region_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EditorChoicesFields_region_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EditorChoicesFields_region_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EditorChoicesFields_region_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EditorChoicesFields_updatedAt"]: AliasType<{
	create?:ResolverInputTypes["EditorChoicesFields_updatedAt_Create"],
	read?:ResolverInputTypes["EditorChoicesFields_updatedAt_Read"],
	update?:ResolverInputTypes["EditorChoicesFields_updatedAt_Update"],
	delete?:ResolverInputTypes["EditorChoicesFields_updatedAt_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EditorChoicesFields_updatedAt_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EditorChoicesFields_updatedAt_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EditorChoicesFields_updatedAt_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EditorChoicesFields_updatedAt_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EditorChoicesFields_createdAt"]: AliasType<{
	create?:ResolverInputTypes["EditorChoicesFields_createdAt_Create"],
	read?:ResolverInputTypes["EditorChoicesFields_createdAt_Read"],
	update?:ResolverInputTypes["EditorChoicesFields_createdAt_Update"],
	delete?:ResolverInputTypes["EditorChoicesFields_createdAt_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EditorChoicesFields_createdAt_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EditorChoicesFields_createdAt_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EditorChoicesFields_createdAt_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EditorChoicesFields_createdAt_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EditorChoicesCreateAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EditorChoicesReadAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EditorChoicesUpdateAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EditorChoicesDeleteAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["criteria_settingAccess"]: AliasType<{
	fields?:ResolverInputTypes["CriteriaSettingFields"],
	create?:ResolverInputTypes["CriteriaSettingCreateAccess"],
	read?:ResolverInputTypes["CriteriaSettingReadAccess"],
	update?:ResolverInputTypes["CriteriaSettingUpdateAccess"],
	delete?:ResolverInputTypes["CriteriaSettingDeleteAccess"],
		__typename?: boolean | `@${string}`
}>;
	["CriteriaSettingFields"]: AliasType<{
	criteria?:ResolverInputTypes["CriteriaSettingFields_criteria"],
	updatedAt?:ResolverInputTypes["CriteriaSettingFields_updatedAt"],
	createdAt?:ResolverInputTypes["CriteriaSettingFields_createdAt"],
		__typename?: boolean | `@${string}`
}>;
	["CriteriaSettingFields_criteria"]: AliasType<{
	create?:ResolverInputTypes["CriteriaSettingFields_criteria_Create"],
	read?:ResolverInputTypes["CriteriaSettingFields_criteria_Read"],
	update?:ResolverInputTypes["CriteriaSettingFields_criteria_Update"],
	delete?:ResolverInputTypes["CriteriaSettingFields_criteria_Delete"],
	fields?:ResolverInputTypes["CriteriaSettingFields_criteria_Fields"],
		__typename?: boolean | `@${string}`
}>;
	["CriteriaSettingFields_criteria_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CriteriaSettingFields_criteria_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CriteriaSettingFields_criteria_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CriteriaSettingFields_criteria_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CriteriaSettingFields_criteria_Fields"]: AliasType<{
	date?:ResolverInputTypes["CriteriaSettingFields_criteria_date"],
	audience?:ResolverInputTypes["CriteriaSettingFields_criteria_audience"],
	category?:ResolverInputTypes["CriteriaSettingFields_criteria_category"],
	tag?:ResolverInputTypes["CriteriaSettingFields_criteria_tag"],
	region?:ResolverInputTypes["CriteriaSettingFields_criteria_region"],
		__typename?: boolean | `@${string}`
}>;
	["CriteriaSettingFields_criteria_date"]: AliasType<{
	create?:ResolverInputTypes["CriteriaSettingFields_criteria_date_Create"],
	read?:ResolverInputTypes["CriteriaSettingFields_criteria_date_Read"],
	update?:ResolverInputTypes["CriteriaSettingFields_criteria_date_Update"],
	delete?:ResolverInputTypes["CriteriaSettingFields_criteria_date_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["CriteriaSettingFields_criteria_date_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CriteriaSettingFields_criteria_date_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CriteriaSettingFields_criteria_date_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CriteriaSettingFields_criteria_date_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CriteriaSettingFields_criteria_audience"]: AliasType<{
	create?:ResolverInputTypes["CriteriaSettingFields_criteria_audience_Create"],
	read?:ResolverInputTypes["CriteriaSettingFields_criteria_audience_Read"],
	update?:ResolverInputTypes["CriteriaSettingFields_criteria_audience_Update"],
	delete?:ResolverInputTypes["CriteriaSettingFields_criteria_audience_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["CriteriaSettingFields_criteria_audience_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CriteriaSettingFields_criteria_audience_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CriteriaSettingFields_criteria_audience_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CriteriaSettingFields_criteria_audience_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CriteriaSettingFields_criteria_category"]: AliasType<{
	create?:ResolverInputTypes["CriteriaSettingFields_criteria_category_Create"],
	read?:ResolverInputTypes["CriteriaSettingFields_criteria_category_Read"],
	update?:ResolverInputTypes["CriteriaSettingFields_criteria_category_Update"],
	delete?:ResolverInputTypes["CriteriaSettingFields_criteria_category_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["CriteriaSettingFields_criteria_category_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CriteriaSettingFields_criteria_category_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CriteriaSettingFields_criteria_category_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CriteriaSettingFields_criteria_category_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CriteriaSettingFields_criteria_tag"]: AliasType<{
	create?:ResolverInputTypes["CriteriaSettingFields_criteria_tag_Create"],
	read?:ResolverInputTypes["CriteriaSettingFields_criteria_tag_Read"],
	update?:ResolverInputTypes["CriteriaSettingFields_criteria_tag_Update"],
	delete?:ResolverInputTypes["CriteriaSettingFields_criteria_tag_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["CriteriaSettingFields_criteria_tag_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CriteriaSettingFields_criteria_tag_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CriteriaSettingFields_criteria_tag_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CriteriaSettingFields_criteria_tag_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CriteriaSettingFields_criteria_region"]: AliasType<{
	create?:ResolverInputTypes["CriteriaSettingFields_criteria_region_Create"],
	read?:ResolverInputTypes["CriteriaSettingFields_criteria_region_Read"],
	update?:ResolverInputTypes["CriteriaSettingFields_criteria_region_Update"],
	delete?:ResolverInputTypes["CriteriaSettingFields_criteria_region_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["CriteriaSettingFields_criteria_region_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CriteriaSettingFields_criteria_region_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CriteriaSettingFields_criteria_region_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CriteriaSettingFields_criteria_region_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CriteriaSettingFields_updatedAt"]: AliasType<{
	create?:ResolverInputTypes["CriteriaSettingFields_updatedAt_Create"],
	read?:ResolverInputTypes["CriteriaSettingFields_updatedAt_Read"],
	update?:ResolverInputTypes["CriteriaSettingFields_updatedAt_Update"],
	delete?:ResolverInputTypes["CriteriaSettingFields_updatedAt_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["CriteriaSettingFields_updatedAt_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CriteriaSettingFields_updatedAt_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CriteriaSettingFields_updatedAt_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CriteriaSettingFields_updatedAt_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CriteriaSettingFields_createdAt"]: AliasType<{
	create?:ResolverInputTypes["CriteriaSettingFields_createdAt_Create"],
	read?:ResolverInputTypes["CriteriaSettingFields_createdAt_Read"],
	update?:ResolverInputTypes["CriteriaSettingFields_createdAt_Update"],
	delete?:ResolverInputTypes["CriteriaSettingFields_createdAt_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["CriteriaSettingFields_createdAt_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CriteriaSettingFields_createdAt_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CriteriaSettingFields_createdAt_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CriteriaSettingFields_createdAt_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CriteriaSettingCreateAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CriteriaSettingReadAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CriteriaSettingUpdateAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CriteriaSettingDeleteAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["organiserSettingAccess"]: AliasType<{
	fields?:ResolverInputTypes["OrganiserSettingFields"],
	create?:ResolverInputTypes["OrganiserSettingCreateAccess"],
	read?:ResolverInputTypes["OrganiserSettingReadAccess"],
	update?:ResolverInputTypes["OrganiserSettingUpdateAccess"],
	delete?:ResolverInputTypes["OrganiserSettingDeleteAccess"],
		__typename?: boolean | `@${string}`
}>;
	["OrganiserSettingFields"]: AliasType<{
	name?:ResolverInputTypes["OrganiserSettingFields_name"],
	organiserList?:ResolverInputTypes["OrganiserSettingFields_organiserList"],
	region?:ResolverInputTypes["OrganiserSettingFields_region"],
	updatedAt?:ResolverInputTypes["OrganiserSettingFields_updatedAt"],
	createdAt?:ResolverInputTypes["OrganiserSettingFields_createdAt"],
		__typename?: boolean | `@${string}`
}>;
	["OrganiserSettingFields_name"]: AliasType<{
	create?:ResolverInputTypes["OrganiserSettingFields_name_Create"],
	read?:ResolverInputTypes["OrganiserSettingFields_name_Read"],
	update?:ResolverInputTypes["OrganiserSettingFields_name_Update"],
	delete?:ResolverInputTypes["OrganiserSettingFields_name_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["OrganiserSettingFields_name_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["OrganiserSettingFields_name_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["OrganiserSettingFields_name_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["OrganiserSettingFields_name_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["OrganiserSettingFields_organiserList"]: AliasType<{
	create?:ResolverInputTypes["OrganiserSettingFields_organiserList_Create"],
	read?:ResolverInputTypes["OrganiserSettingFields_organiserList_Read"],
	update?:ResolverInputTypes["OrganiserSettingFields_organiserList_Update"],
	delete?:ResolverInputTypes["OrganiserSettingFields_organiserList_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["OrganiserSettingFields_organiserList_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["OrganiserSettingFields_organiserList_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["OrganiserSettingFields_organiserList_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["OrganiserSettingFields_organiserList_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["OrganiserSettingFields_region"]: AliasType<{
	create?:ResolverInputTypes["OrganiserSettingFields_region_Create"],
	read?:ResolverInputTypes["OrganiserSettingFields_region_Read"],
	update?:ResolverInputTypes["OrganiserSettingFields_region_Update"],
	delete?:ResolverInputTypes["OrganiserSettingFields_region_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["OrganiserSettingFields_region_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["OrganiserSettingFields_region_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["OrganiserSettingFields_region_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["OrganiserSettingFields_region_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["OrganiserSettingFields_updatedAt"]: AliasType<{
	create?:ResolverInputTypes["OrganiserSettingFields_updatedAt_Create"],
	read?:ResolverInputTypes["OrganiserSettingFields_updatedAt_Read"],
	update?:ResolverInputTypes["OrganiserSettingFields_updatedAt_Update"],
	delete?:ResolverInputTypes["OrganiserSettingFields_updatedAt_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["OrganiserSettingFields_updatedAt_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["OrganiserSettingFields_updatedAt_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["OrganiserSettingFields_updatedAt_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["OrganiserSettingFields_updatedAt_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["OrganiserSettingFields_createdAt"]: AliasType<{
	create?:ResolverInputTypes["OrganiserSettingFields_createdAt_Create"],
	read?:ResolverInputTypes["OrganiserSettingFields_createdAt_Read"],
	update?:ResolverInputTypes["OrganiserSettingFields_createdAt_Update"],
	delete?:ResolverInputTypes["OrganiserSettingFields_createdAt_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["OrganiserSettingFields_createdAt_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["OrganiserSettingFields_createdAt_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["OrganiserSettingFields_createdAt_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["OrganiserSettingFields_createdAt_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["OrganiserSettingCreateAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["OrganiserSettingReadAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["OrganiserSettingUpdateAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["OrganiserSettingDeleteAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["post_settingAccess"]: AliasType<{
	fields?:ResolverInputTypes["PostSettingFields"],
	create?:ResolverInputTypes["PostSettingCreateAccess"],
	read?:ResolverInputTypes["PostSettingReadAccess"],
	update?:ResolverInputTypes["PostSettingUpdateAccess"],
	delete?:ResolverInputTypes["PostSettingDeleteAccess"],
		__typename?: boolean | `@${string}`
}>;
	["PostSettingFields"]: AliasType<{
	name?:ResolverInputTypes["PostSettingFields_name"],
	featuredPosts?:ResolverInputTypes["PostSettingFields_featuredPosts"],
	region?:ResolverInputTypes["PostSettingFields_region"],
	updatedAt?:ResolverInputTypes["PostSettingFields_updatedAt"],
	createdAt?:ResolverInputTypes["PostSettingFields_createdAt"],
		__typename?: boolean | `@${string}`
}>;
	["PostSettingFields_name"]: AliasType<{
	create?:ResolverInputTypes["PostSettingFields_name_Create"],
	read?:ResolverInputTypes["PostSettingFields_name_Read"],
	update?:ResolverInputTypes["PostSettingFields_name_Update"],
	delete?:ResolverInputTypes["PostSettingFields_name_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["PostSettingFields_name_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostSettingFields_name_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostSettingFields_name_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostSettingFields_name_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostSettingFields_featuredPosts"]: AliasType<{
	create?:ResolverInputTypes["PostSettingFields_featuredPosts_Create"],
	read?:ResolverInputTypes["PostSettingFields_featuredPosts_Read"],
	update?:ResolverInputTypes["PostSettingFields_featuredPosts_Update"],
	delete?:ResolverInputTypes["PostSettingFields_featuredPosts_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["PostSettingFields_featuredPosts_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostSettingFields_featuredPosts_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostSettingFields_featuredPosts_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostSettingFields_featuredPosts_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostSettingFields_region"]: AliasType<{
	create?:ResolverInputTypes["PostSettingFields_region_Create"],
	read?:ResolverInputTypes["PostSettingFields_region_Read"],
	update?:ResolverInputTypes["PostSettingFields_region_Update"],
	delete?:ResolverInputTypes["PostSettingFields_region_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["PostSettingFields_region_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostSettingFields_region_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostSettingFields_region_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostSettingFields_region_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostSettingFields_updatedAt"]: AliasType<{
	create?:ResolverInputTypes["PostSettingFields_updatedAt_Create"],
	read?:ResolverInputTypes["PostSettingFields_updatedAt_Read"],
	update?:ResolverInputTypes["PostSettingFields_updatedAt_Update"],
	delete?:ResolverInputTypes["PostSettingFields_updatedAt_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["PostSettingFields_updatedAt_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostSettingFields_updatedAt_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostSettingFields_updatedAt_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostSettingFields_updatedAt_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostSettingFields_createdAt"]: AliasType<{
	create?:ResolverInputTypes["PostSettingFields_createdAt_Create"],
	read?:ResolverInputTypes["PostSettingFields_createdAt_Read"],
	update?:ResolverInputTypes["PostSettingFields_createdAt_Update"],
	delete?:ResolverInputTypes["PostSettingFields_createdAt_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["PostSettingFields_createdAt_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostSettingFields_createdAt_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostSettingFields_createdAt_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostSettingFields_createdAt_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostSettingCreateAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostSettingReadAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostSettingUpdateAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostSettingDeleteAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["homepageSettingAccess"]: AliasType<{
	fields?:ResolverInputTypes["HomepageSettingFields"],
	create?:ResolverInputTypes["HomepageSettingCreateAccess"],
	read?:ResolverInputTypes["HomepageSettingReadAccess"],
	update?:ResolverInputTypes["HomepageSettingUpdateAccess"],
	delete?:ResolverInputTypes["HomepageSettingDeleteAccess"],
		__typename?: boolean | `@${string}`
}>;
	["HomepageSettingFields"]: AliasType<{
	name?:ResolverInputTypes["HomepageSettingFields_name"],
	region?:ResolverInputTypes["HomepageSettingFields_region"],
	sections?:ResolverInputTypes["HomepageSettingFields_sections"],
	updatedAt?:ResolverInputTypes["HomepageSettingFields_updatedAt"],
	createdAt?:ResolverInputTypes["HomepageSettingFields_createdAt"],
		__typename?: boolean | `@${string}`
}>;
	["HomepageSettingFields_name"]: AliasType<{
	create?:ResolverInputTypes["HomepageSettingFields_name_Create"],
	read?:ResolverInputTypes["HomepageSettingFields_name_Read"],
	update?:ResolverInputTypes["HomepageSettingFields_name_Update"],
	delete?:ResolverInputTypes["HomepageSettingFields_name_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["HomepageSettingFields_name_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["HomepageSettingFields_name_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["HomepageSettingFields_name_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["HomepageSettingFields_name_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["HomepageSettingFields_region"]: AliasType<{
	create?:ResolverInputTypes["HomepageSettingFields_region_Create"],
	read?:ResolverInputTypes["HomepageSettingFields_region_Read"],
	update?:ResolverInputTypes["HomepageSettingFields_region_Update"],
	delete?:ResolverInputTypes["HomepageSettingFields_region_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["HomepageSettingFields_region_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["HomepageSettingFields_region_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["HomepageSettingFields_region_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["HomepageSettingFields_region_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["HomepageSettingFields_sections"]: AliasType<{
	create?:ResolverInputTypes["HomepageSettingFields_sections_Create"],
	read?:ResolverInputTypes["HomepageSettingFields_sections_Read"],
	update?:ResolverInputTypes["HomepageSettingFields_sections_Update"],
	delete?:ResolverInputTypes["HomepageSettingFields_sections_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["HomepageSettingFields_sections_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["HomepageSettingFields_sections_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["HomepageSettingFields_sections_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["HomepageSettingFields_sections_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["HomepageSettingFields_updatedAt"]: AliasType<{
	create?:ResolverInputTypes["HomepageSettingFields_updatedAt_Create"],
	read?:ResolverInputTypes["HomepageSettingFields_updatedAt_Read"],
	update?:ResolverInputTypes["HomepageSettingFields_updatedAt_Update"],
	delete?:ResolverInputTypes["HomepageSettingFields_updatedAt_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["HomepageSettingFields_updatedAt_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["HomepageSettingFields_updatedAt_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["HomepageSettingFields_updatedAt_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["HomepageSettingFields_updatedAt_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["HomepageSettingFields_createdAt"]: AliasType<{
	create?:ResolverInputTypes["HomepageSettingFields_createdAt_Create"],
	read?:ResolverInputTypes["HomepageSettingFields_createdAt_Read"],
	update?:ResolverInputTypes["HomepageSettingFields_createdAt_Update"],
	delete?:ResolverInputTypes["HomepageSettingFields_createdAt_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["HomepageSettingFields_createdAt_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["HomepageSettingFields_createdAt_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["HomepageSettingFields_createdAt_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["HomepageSettingFields_createdAt_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["HomepageSettingCreateAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["HomepageSettingReadAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["HomepageSettingUpdateAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["HomepageSettingDeleteAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["banner_sectionAccess"]: AliasType<{
	fields?:ResolverInputTypes["BannerSectionFields"],
	create?:ResolverInputTypes["BannerSectionCreateAccess"],
	read?:ResolverInputTypes["BannerSectionReadAccess"],
	update?:ResolverInputTypes["BannerSectionUpdateAccess"],
	delete?:ResolverInputTypes["BannerSectionDeleteAccess"],
		__typename?: boolean | `@${string}`
}>;
	["BannerSectionFields"]: AliasType<{
	name?:ResolverInputTypes["BannerSectionFields_name"],
	slug?:ResolverInputTypes["BannerSectionFields_slug"],
	banners?:ResolverInputTypes["BannerSectionFields_banners"],
	updatedAt?:ResolverInputTypes["BannerSectionFields_updatedAt"],
	createdAt?:ResolverInputTypes["BannerSectionFields_createdAt"],
		__typename?: boolean | `@${string}`
}>;
	["BannerSectionFields_name"]: AliasType<{
	create?:ResolverInputTypes["BannerSectionFields_name_Create"],
	read?:ResolverInputTypes["BannerSectionFields_name_Read"],
	update?:ResolverInputTypes["BannerSectionFields_name_Update"],
	delete?:ResolverInputTypes["BannerSectionFields_name_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["BannerSectionFields_name_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannerSectionFields_name_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannerSectionFields_name_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannerSectionFields_name_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannerSectionFields_slug"]: AliasType<{
	create?:ResolverInputTypes["BannerSectionFields_slug_Create"],
	read?:ResolverInputTypes["BannerSectionFields_slug_Read"],
	update?:ResolverInputTypes["BannerSectionFields_slug_Update"],
	delete?:ResolverInputTypes["BannerSectionFields_slug_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["BannerSectionFields_slug_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannerSectionFields_slug_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannerSectionFields_slug_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannerSectionFields_slug_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannerSectionFields_banners"]: AliasType<{
	create?:ResolverInputTypes["BannerSectionFields_banners_Create"],
	read?:ResolverInputTypes["BannerSectionFields_banners_Read"],
	update?:ResolverInputTypes["BannerSectionFields_banners_Update"],
	delete?:ResolverInputTypes["BannerSectionFields_banners_Delete"],
	fields?:ResolverInputTypes["BannerSectionFields_banners_Fields"],
		__typename?: boolean | `@${string}`
}>;
	["BannerSectionFields_banners_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannerSectionFields_banners_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannerSectionFields_banners_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannerSectionFields_banners_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannerSectionFields_banners_Fields"]: AliasType<{
	name?:ResolverInputTypes["BannerSectionFields_banners_name"],
	status?:ResolverInputTypes["BannerSectionFields_banners_status"],
	desktop?:ResolverInputTypes["BannerSectionFields_banners_desktop"],
	bannerStartDate?:ResolverInputTypes["BannerSectionFields_banners_bannerStartDate"],
	bannerEndDate?:ResolverInputTypes["BannerSectionFields_banners_bannerEndDate"],
	region?:ResolverInputTypes["BannerSectionFields_banners_region"],
	id?:ResolverInputTypes["BannerSectionFields_banners_id"],
		__typename?: boolean | `@${string}`
}>;
	["BannerSectionFields_banners_name"]: AliasType<{
	create?:ResolverInputTypes["BannerSectionFields_banners_name_Create"],
	read?:ResolverInputTypes["BannerSectionFields_banners_name_Read"],
	update?:ResolverInputTypes["BannerSectionFields_banners_name_Update"],
	delete?:ResolverInputTypes["BannerSectionFields_banners_name_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["BannerSectionFields_banners_name_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannerSectionFields_banners_name_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannerSectionFields_banners_name_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannerSectionFields_banners_name_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannerSectionFields_banners_status"]: AliasType<{
	create?:ResolverInputTypes["BannerSectionFields_banners_status_Create"],
	read?:ResolverInputTypes["BannerSectionFields_banners_status_Read"],
	update?:ResolverInputTypes["BannerSectionFields_banners_status_Update"],
	delete?:ResolverInputTypes["BannerSectionFields_banners_status_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["BannerSectionFields_banners_status_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannerSectionFields_banners_status_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannerSectionFields_banners_status_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannerSectionFields_banners_status_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannerSectionFields_banners_desktop"]: AliasType<{
	create?:ResolverInputTypes["BannerSectionFields_banners_desktop_Create"],
	read?:ResolverInputTypes["BannerSectionFields_banners_desktop_Read"],
	update?:ResolverInputTypes["BannerSectionFields_banners_desktop_Update"],
	delete?:ResolverInputTypes["BannerSectionFields_banners_desktop_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["BannerSectionFields_banners_desktop_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannerSectionFields_banners_desktop_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannerSectionFields_banners_desktop_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannerSectionFields_banners_desktop_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannerSectionFields_banners_bannerStartDate"]: AliasType<{
	create?:ResolverInputTypes["BannerSectionFields_banners_bannerStartDate_Create"],
	read?:ResolverInputTypes["BannerSectionFields_banners_bannerStartDate_Read"],
	update?:ResolverInputTypes["BannerSectionFields_banners_bannerStartDate_Update"],
	delete?:ResolverInputTypes["BannerSectionFields_banners_bannerStartDate_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["BannerSectionFields_banners_bannerStartDate_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannerSectionFields_banners_bannerStartDate_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannerSectionFields_banners_bannerStartDate_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannerSectionFields_banners_bannerStartDate_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannerSectionFields_banners_bannerEndDate"]: AliasType<{
	create?:ResolverInputTypes["BannerSectionFields_banners_bannerEndDate_Create"],
	read?:ResolverInputTypes["BannerSectionFields_banners_bannerEndDate_Read"],
	update?:ResolverInputTypes["BannerSectionFields_banners_bannerEndDate_Update"],
	delete?:ResolverInputTypes["BannerSectionFields_banners_bannerEndDate_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["BannerSectionFields_banners_bannerEndDate_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannerSectionFields_banners_bannerEndDate_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannerSectionFields_banners_bannerEndDate_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannerSectionFields_banners_bannerEndDate_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannerSectionFields_banners_region"]: AliasType<{
	create?:ResolverInputTypes["BannerSectionFields_banners_region_Create"],
	read?:ResolverInputTypes["BannerSectionFields_banners_region_Read"],
	update?:ResolverInputTypes["BannerSectionFields_banners_region_Update"],
	delete?:ResolverInputTypes["BannerSectionFields_banners_region_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["BannerSectionFields_banners_region_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannerSectionFields_banners_region_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannerSectionFields_banners_region_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannerSectionFields_banners_region_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannerSectionFields_banners_id"]: AliasType<{
	create?:ResolverInputTypes["BannerSectionFields_banners_id_Create"],
	read?:ResolverInputTypes["BannerSectionFields_banners_id_Read"],
	update?:ResolverInputTypes["BannerSectionFields_banners_id_Update"],
	delete?:ResolverInputTypes["BannerSectionFields_banners_id_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["BannerSectionFields_banners_id_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannerSectionFields_banners_id_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannerSectionFields_banners_id_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannerSectionFields_banners_id_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannerSectionFields_updatedAt"]: AliasType<{
	create?:ResolverInputTypes["BannerSectionFields_updatedAt_Create"],
	read?:ResolverInputTypes["BannerSectionFields_updatedAt_Read"],
	update?:ResolverInputTypes["BannerSectionFields_updatedAt_Update"],
	delete?:ResolverInputTypes["BannerSectionFields_updatedAt_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["BannerSectionFields_updatedAt_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannerSectionFields_updatedAt_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannerSectionFields_updatedAt_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannerSectionFields_updatedAt_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannerSectionFields_createdAt"]: AliasType<{
	create?:ResolverInputTypes["BannerSectionFields_createdAt_Create"],
	read?:ResolverInputTypes["BannerSectionFields_createdAt_Read"],
	update?:ResolverInputTypes["BannerSectionFields_createdAt_Update"],
	delete?:ResolverInputTypes["BannerSectionFields_createdAt_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["BannerSectionFields_createdAt_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannerSectionFields_createdAt_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannerSectionFields_createdAt_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannerSectionFields_createdAt_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannerSectionCreateAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannerSectionReadAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannerSectionUpdateAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannerSectionDeleteAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["macao_banner_sectionAccess"]: AliasType<{
	fields?:ResolverInputTypes["MacaoBannerSectionFields"],
	create?:ResolverInputTypes["MacaoBannerSectionCreateAccess"],
	read?:ResolverInputTypes["MacaoBannerSectionReadAccess"],
	update?:ResolverInputTypes["MacaoBannerSectionUpdateAccess"],
	delete?:ResolverInputTypes["MacaoBannerSectionDeleteAccess"],
		__typename?: boolean | `@${string}`
}>;
	["MacaoBannerSectionFields"]: AliasType<{
	name?:ResolverInputTypes["MacaoBannerSectionFields_name"],
	slug?:ResolverInputTypes["MacaoBannerSectionFields_slug"],
	banners?:ResolverInputTypes["MacaoBannerSectionFields_banners"],
	updatedAt?:ResolverInputTypes["MacaoBannerSectionFields_updatedAt"],
	createdAt?:ResolverInputTypes["MacaoBannerSectionFields_createdAt"],
		__typename?: boolean | `@${string}`
}>;
	["MacaoBannerSectionFields_name"]: AliasType<{
	create?:ResolverInputTypes["MacaoBannerSectionFields_name_Create"],
	read?:ResolverInputTypes["MacaoBannerSectionFields_name_Read"],
	update?:ResolverInputTypes["MacaoBannerSectionFields_name_Update"],
	delete?:ResolverInputTypes["MacaoBannerSectionFields_name_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["MacaoBannerSectionFields_name_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MacaoBannerSectionFields_name_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MacaoBannerSectionFields_name_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MacaoBannerSectionFields_name_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MacaoBannerSectionFields_slug"]: AliasType<{
	create?:ResolverInputTypes["MacaoBannerSectionFields_slug_Create"],
	read?:ResolverInputTypes["MacaoBannerSectionFields_slug_Read"],
	update?:ResolverInputTypes["MacaoBannerSectionFields_slug_Update"],
	delete?:ResolverInputTypes["MacaoBannerSectionFields_slug_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["MacaoBannerSectionFields_slug_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MacaoBannerSectionFields_slug_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MacaoBannerSectionFields_slug_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MacaoBannerSectionFields_slug_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MacaoBannerSectionFields_banners"]: AliasType<{
	create?:ResolverInputTypes["MacaoBannerSectionFields_banners_Create"],
	read?:ResolverInputTypes["MacaoBannerSectionFields_banners_Read"],
	update?:ResolverInputTypes["MacaoBannerSectionFields_banners_Update"],
	delete?:ResolverInputTypes["MacaoBannerSectionFields_banners_Delete"],
	fields?:ResolverInputTypes["MacaoBannerSectionFields_banners_Fields"],
		__typename?: boolean | `@${string}`
}>;
	["MacaoBannerSectionFields_banners_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MacaoBannerSectionFields_banners_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MacaoBannerSectionFields_banners_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MacaoBannerSectionFields_banners_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MacaoBannerSectionFields_banners_Fields"]: AliasType<{
	name?:ResolverInputTypes["MacaoBannerSectionFields_banners_name"],
	status?:ResolverInputTypes["MacaoBannerSectionFields_banners_status"],
	desktop?:ResolverInputTypes["MacaoBannerSectionFields_banners_desktop"],
	bannerStartDate?:ResolverInputTypes["MacaoBannerSectionFields_banners_bannerStartDate"],
	bannerEndDate?:ResolverInputTypes["MacaoBannerSectionFields_banners_bannerEndDate"],
	region?:ResolverInputTypes["MacaoBannerSectionFields_banners_region"],
	id?:ResolverInputTypes["MacaoBannerSectionFields_banners_id"],
		__typename?: boolean | `@${string}`
}>;
	["MacaoBannerSectionFields_banners_name"]: AliasType<{
	create?:ResolverInputTypes["MacaoBannerSectionFields_banners_name_Create"],
	read?:ResolverInputTypes["MacaoBannerSectionFields_banners_name_Read"],
	update?:ResolverInputTypes["MacaoBannerSectionFields_banners_name_Update"],
	delete?:ResolverInputTypes["MacaoBannerSectionFields_banners_name_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["MacaoBannerSectionFields_banners_name_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MacaoBannerSectionFields_banners_name_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MacaoBannerSectionFields_banners_name_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MacaoBannerSectionFields_banners_name_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MacaoBannerSectionFields_banners_status"]: AliasType<{
	create?:ResolverInputTypes["MacaoBannerSectionFields_banners_status_Create"],
	read?:ResolverInputTypes["MacaoBannerSectionFields_banners_status_Read"],
	update?:ResolverInputTypes["MacaoBannerSectionFields_banners_status_Update"],
	delete?:ResolverInputTypes["MacaoBannerSectionFields_banners_status_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["MacaoBannerSectionFields_banners_status_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MacaoBannerSectionFields_banners_status_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MacaoBannerSectionFields_banners_status_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MacaoBannerSectionFields_banners_status_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MacaoBannerSectionFields_banners_desktop"]: AliasType<{
	create?:ResolverInputTypes["MacaoBannerSectionFields_banners_desktop_Create"],
	read?:ResolverInputTypes["MacaoBannerSectionFields_banners_desktop_Read"],
	update?:ResolverInputTypes["MacaoBannerSectionFields_banners_desktop_Update"],
	delete?:ResolverInputTypes["MacaoBannerSectionFields_banners_desktop_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["MacaoBannerSectionFields_banners_desktop_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MacaoBannerSectionFields_banners_desktop_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MacaoBannerSectionFields_banners_desktop_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MacaoBannerSectionFields_banners_desktop_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MacaoBannerSectionFields_banners_bannerStartDate"]: AliasType<{
	create?:ResolverInputTypes["MacaoBannerSectionFields_banners_bannerStartDate_Create"],
	read?:ResolverInputTypes["MacaoBannerSectionFields_banners_bannerStartDate_Read"],
	update?:ResolverInputTypes["MacaoBannerSectionFields_banners_bannerStartDate_Update"],
	delete?:ResolverInputTypes["MacaoBannerSectionFields_banners_bannerStartDate_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["MacaoBannerSectionFields_banners_bannerStartDate_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MacaoBannerSectionFields_banners_bannerStartDate_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MacaoBannerSectionFields_banners_bannerStartDate_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MacaoBannerSectionFields_banners_bannerStartDate_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MacaoBannerSectionFields_banners_bannerEndDate"]: AliasType<{
	create?:ResolverInputTypes["MacaoBannerSectionFields_banners_bannerEndDate_Create"],
	read?:ResolverInputTypes["MacaoBannerSectionFields_banners_bannerEndDate_Read"],
	update?:ResolverInputTypes["MacaoBannerSectionFields_banners_bannerEndDate_Update"],
	delete?:ResolverInputTypes["MacaoBannerSectionFields_banners_bannerEndDate_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["MacaoBannerSectionFields_banners_bannerEndDate_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MacaoBannerSectionFields_banners_bannerEndDate_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MacaoBannerSectionFields_banners_bannerEndDate_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MacaoBannerSectionFields_banners_bannerEndDate_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MacaoBannerSectionFields_banners_region"]: AliasType<{
	create?:ResolverInputTypes["MacaoBannerSectionFields_banners_region_Create"],
	read?:ResolverInputTypes["MacaoBannerSectionFields_banners_region_Read"],
	update?:ResolverInputTypes["MacaoBannerSectionFields_banners_region_Update"],
	delete?:ResolverInputTypes["MacaoBannerSectionFields_banners_region_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["MacaoBannerSectionFields_banners_region_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MacaoBannerSectionFields_banners_region_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MacaoBannerSectionFields_banners_region_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MacaoBannerSectionFields_banners_region_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MacaoBannerSectionFields_banners_id"]: AliasType<{
	create?:ResolverInputTypes["MacaoBannerSectionFields_banners_id_Create"],
	read?:ResolverInputTypes["MacaoBannerSectionFields_banners_id_Read"],
	update?:ResolverInputTypes["MacaoBannerSectionFields_banners_id_Update"],
	delete?:ResolverInputTypes["MacaoBannerSectionFields_banners_id_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["MacaoBannerSectionFields_banners_id_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MacaoBannerSectionFields_banners_id_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MacaoBannerSectionFields_banners_id_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MacaoBannerSectionFields_banners_id_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MacaoBannerSectionFields_updatedAt"]: AliasType<{
	create?:ResolverInputTypes["MacaoBannerSectionFields_updatedAt_Create"],
	read?:ResolverInputTypes["MacaoBannerSectionFields_updatedAt_Read"],
	update?:ResolverInputTypes["MacaoBannerSectionFields_updatedAt_Update"],
	delete?:ResolverInputTypes["MacaoBannerSectionFields_updatedAt_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["MacaoBannerSectionFields_updatedAt_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MacaoBannerSectionFields_updatedAt_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MacaoBannerSectionFields_updatedAt_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MacaoBannerSectionFields_updatedAt_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MacaoBannerSectionFields_createdAt"]: AliasType<{
	create?:ResolverInputTypes["MacaoBannerSectionFields_createdAt_Create"],
	read?:ResolverInputTypes["MacaoBannerSectionFields_createdAt_Read"],
	update?:ResolverInputTypes["MacaoBannerSectionFields_createdAt_Update"],
	delete?:ResolverInputTypes["MacaoBannerSectionFields_createdAt_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["MacaoBannerSectionFields_createdAt_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MacaoBannerSectionFields_createdAt_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MacaoBannerSectionFields_createdAt_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MacaoBannerSectionFields_createdAt_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MacaoBannerSectionCreateAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MacaoBannerSectionReadAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MacaoBannerSectionUpdateAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MacaoBannerSectionDeleteAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["bannersAccess"]: AliasType<{
	fields?:ResolverInputTypes["BannersFields"],
	create?:ResolverInputTypes["BannersCreateAccess"],
	read?:ResolverInputTypes["BannersReadAccess"],
	update?:ResolverInputTypes["BannersUpdateAccess"],
	delete?:ResolverInputTypes["BannersDeleteAccess"],
		__typename?: boolean | `@${string}`
}>;
	["BannersFields"]: AliasType<{
	name?:ResolverInputTypes["BannersFields_name"],
	desktop?:ResolverInputTypes["BannersFields_desktop"],
	mobile?:ResolverInputTypes["BannersFields_mobile"],
	bannerStartDate?:ResolverInputTypes["BannersFields_bannerStartDate"],
	bannerEndDate?:ResolverInputTypes["BannersFields_bannerEndDate"],
	location?:ResolverInputTypes["BannersFields_location"],
	link?:ResolverInputTypes["BannersFields_link"],
	external?:ResolverInputTypes["BannersFields_external"],
	type?:ResolverInputTypes["BannersFields_type"],
	status?:ResolverInputTypes["BannersFields_status"],
	advertise?:ResolverInputTypes["BannersFields_advertise"],
	_locations?:ResolverInputTypes["BannersFields__locations"],
	region?:ResolverInputTypes["BannersFields_region"],
	homePageClick?:ResolverInputTypes["BannersFields_homePageClick"],
	homePageImpression?:ResolverInputTypes["BannersFields_homePageImpression"],
	latestEventClick?:ResolverInputTypes["BannersFields_latestEventClick"],
	latestEventImpression?:ResolverInputTypes["BannersFields_latestEventImpression"],
	editorChoiceClick?:ResolverInputTypes["BannersFields_editorChoiceClick"],
	editorChoiceImpression?:ResolverInputTypes["BannersFields_editorChoiceImpression"],
	eventListClick?:ResolverInputTypes["BannersFields_eventListClick"],
	eventListImpression?:ResolverInputTypes["BannersFields_eventListImpression"],
	topTenClick?:ResolverInputTypes["BannersFields_topTenClick"],
	topTenImpression?:ResolverInputTypes["BannersFields_topTenImpression"],
	miscClick?:ResolverInputTypes["BannersFields_miscClick"],
	miscImpression?:ResolverInputTypes["BannersFields_miscImpression"],
	clickTotal?:ResolverInputTypes["BannersFields_clickTotal"],
	totalImpression?:ResolverInputTypes["BannersFields_totalImpression"],
	updatedAt?:ResolverInputTypes["BannersFields_updatedAt"],
	createdAt?:ResolverInputTypes["BannersFields_createdAt"],
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_name"]: AliasType<{
	create?:ResolverInputTypes["BannersFields_name_Create"],
	read?:ResolverInputTypes["BannersFields_name_Read"],
	update?:ResolverInputTypes["BannersFields_name_Update"],
	delete?:ResolverInputTypes["BannersFields_name_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_name_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_name_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_name_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_name_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_desktop"]: AliasType<{
	create?:ResolverInputTypes["BannersFields_desktop_Create"],
	read?:ResolverInputTypes["BannersFields_desktop_Read"],
	update?:ResolverInputTypes["BannersFields_desktop_Update"],
	delete?:ResolverInputTypes["BannersFields_desktop_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_desktop_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_desktop_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_desktop_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_desktop_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_mobile"]: AliasType<{
	create?:ResolverInputTypes["BannersFields_mobile_Create"],
	read?:ResolverInputTypes["BannersFields_mobile_Read"],
	update?:ResolverInputTypes["BannersFields_mobile_Update"],
	delete?:ResolverInputTypes["BannersFields_mobile_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_mobile_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_mobile_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_mobile_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_mobile_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_bannerStartDate"]: AliasType<{
	create?:ResolverInputTypes["BannersFields_bannerStartDate_Create"],
	read?:ResolverInputTypes["BannersFields_bannerStartDate_Read"],
	update?:ResolverInputTypes["BannersFields_bannerStartDate_Update"],
	delete?:ResolverInputTypes["BannersFields_bannerStartDate_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_bannerStartDate_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_bannerStartDate_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_bannerStartDate_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_bannerStartDate_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_bannerEndDate"]: AliasType<{
	create?:ResolverInputTypes["BannersFields_bannerEndDate_Create"],
	read?:ResolverInputTypes["BannersFields_bannerEndDate_Read"],
	update?:ResolverInputTypes["BannersFields_bannerEndDate_Update"],
	delete?:ResolverInputTypes["BannersFields_bannerEndDate_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_bannerEndDate_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_bannerEndDate_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_bannerEndDate_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_bannerEndDate_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_location"]: AliasType<{
	create?:ResolverInputTypes["BannersFields_location_Create"],
	read?:ResolverInputTypes["BannersFields_location_Read"],
	update?:ResolverInputTypes["BannersFields_location_Update"],
	delete?:ResolverInputTypes["BannersFields_location_Delete"],
	fields?:ResolverInputTypes["BannersFields_location_Fields"],
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_location_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_location_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_location_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_location_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_location_Fields"]: AliasType<{
	page?:ResolverInputTypes["BannersFields_location_page"],
	eventRankingList?:ResolverInputTypes["BannersFields_location_eventRankingList"],
	locationRankingList?:ResolverInputTypes["BannersFields_location_locationRankingList"],
	categories?:ResolverInputTypes["BannersFields_location_categories"],
	time?:ResolverInputTypes["BannersFields_location_time"],
	district?:ResolverInputTypes["BannersFields_location_district"],
	audience?:ResolverInputTypes["BannersFields_location_audience"],
	multipleCriteriaSearch?:ResolverInputTypes["BannersFields_location_multipleCriteriaSearch"],
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_location_page"]: AliasType<{
	create?:ResolverInputTypes["BannersFields_location_page_Create"],
	read?:ResolverInputTypes["BannersFields_location_page_Read"],
	update?:ResolverInputTypes["BannersFields_location_page_Update"],
	delete?:ResolverInputTypes["BannersFields_location_page_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_location_page_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_location_page_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_location_page_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_location_page_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_location_eventRankingList"]: AliasType<{
	create?:ResolverInputTypes["BannersFields_location_eventRankingList_Create"],
	read?:ResolverInputTypes["BannersFields_location_eventRankingList_Read"],
	update?:ResolverInputTypes["BannersFields_location_eventRankingList_Update"],
	delete?:ResolverInputTypes["BannersFields_location_eventRankingList_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_location_eventRankingList_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_location_eventRankingList_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_location_eventRankingList_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_location_eventRankingList_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_location_locationRankingList"]: AliasType<{
	create?:ResolverInputTypes["BannersFields_location_locationRankingList_Create"],
	read?:ResolverInputTypes["BannersFields_location_locationRankingList_Read"],
	update?:ResolverInputTypes["BannersFields_location_locationRankingList_Update"],
	delete?:ResolverInputTypes["BannersFields_location_locationRankingList_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_location_locationRankingList_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_location_locationRankingList_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_location_locationRankingList_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_location_locationRankingList_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_location_categories"]: AliasType<{
	create?:ResolverInputTypes["BannersFields_location_categories_Create"],
	read?:ResolverInputTypes["BannersFields_location_categories_Read"],
	update?:ResolverInputTypes["BannersFields_location_categories_Update"],
	delete?:ResolverInputTypes["BannersFields_location_categories_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_location_categories_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_location_categories_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_location_categories_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_location_categories_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_location_time"]: AliasType<{
	create?:ResolverInputTypes["BannersFields_location_time_Create"],
	read?:ResolverInputTypes["BannersFields_location_time_Read"],
	update?:ResolverInputTypes["BannersFields_location_time_Update"],
	delete?:ResolverInputTypes["BannersFields_location_time_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_location_time_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_location_time_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_location_time_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_location_time_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_location_district"]: AliasType<{
	create?:ResolverInputTypes["BannersFields_location_district_Create"],
	read?:ResolverInputTypes["BannersFields_location_district_Read"],
	update?:ResolverInputTypes["BannersFields_location_district_Update"],
	delete?:ResolverInputTypes["BannersFields_location_district_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_location_district_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_location_district_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_location_district_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_location_district_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_location_audience"]: AliasType<{
	create?:ResolverInputTypes["BannersFields_location_audience_Create"],
	read?:ResolverInputTypes["BannersFields_location_audience_Read"],
	update?:ResolverInputTypes["BannersFields_location_audience_Update"],
	delete?:ResolverInputTypes["BannersFields_location_audience_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_location_audience_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_location_audience_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_location_audience_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_location_audience_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_location_multipleCriteriaSearch"]: AliasType<{
	create?:ResolverInputTypes["BannersFields_location_multipleCriteriaSearch_Create"],
	read?:ResolverInputTypes["BannersFields_location_multipleCriteriaSearch_Read"],
	update?:ResolverInputTypes["BannersFields_location_multipleCriteriaSearch_Update"],
	delete?:ResolverInputTypes["BannersFields_location_multipleCriteriaSearch_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_location_multipleCriteriaSearch_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_location_multipleCriteriaSearch_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_location_multipleCriteriaSearch_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_location_multipleCriteriaSearch_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_link"]: AliasType<{
	create?:ResolverInputTypes["BannersFields_link_Create"],
	read?:ResolverInputTypes["BannersFields_link_Read"],
	update?:ResolverInputTypes["BannersFields_link_Update"],
	delete?:ResolverInputTypes["BannersFields_link_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_link_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_link_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_link_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_link_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_external"]: AliasType<{
	create?:ResolverInputTypes["BannersFields_external_Create"],
	read?:ResolverInputTypes["BannersFields_external_Read"],
	update?:ResolverInputTypes["BannersFields_external_Update"],
	delete?:ResolverInputTypes["BannersFields_external_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_external_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_external_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_external_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_external_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_type"]: AliasType<{
	create?:ResolverInputTypes["BannersFields_type_Create"],
	read?:ResolverInputTypes["BannersFields_type_Read"],
	update?:ResolverInputTypes["BannersFields_type_Update"],
	delete?:ResolverInputTypes["BannersFields_type_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_type_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_type_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_type_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_type_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_status"]: AliasType<{
	create?:ResolverInputTypes["BannersFields_status_Create"],
	read?:ResolverInputTypes["BannersFields_status_Read"],
	update?:ResolverInputTypes["BannersFields_status_Update"],
	delete?:ResolverInputTypes["BannersFields_status_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_status_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_status_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_status_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_status_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_advertise"]: AliasType<{
	create?:ResolverInputTypes["BannersFields_advertise_Create"],
	read?:ResolverInputTypes["BannersFields_advertise_Read"],
	update?:ResolverInputTypes["BannersFields_advertise_Update"],
	delete?:ResolverInputTypes["BannersFields_advertise_Delete"],
	fields?:ResolverInputTypes["BannersFields_advertise_Fields"],
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_advertise_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_advertise_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_advertise_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_advertise_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_advertise_Fields"]: AliasType<{
	campaign?:ResolverInputTypes["BannersFields_advertise_campaign"],
	invoiceDescription?:ResolverInputTypes["BannersFields_advertise_invoiceDescription"],
	standardPrice?:ResolverInputTypes["BannersFields_advertise_standardPrice"],
	discount?:ResolverInputTypes["BannersFields_advertise_discount"],
	timableCost?:ResolverInputTypes["BannersFields_advertise_timableCost"],
	remark?:ResolverInputTypes["BannersFields_advertise_remark"],
	public?:ResolverInputTypes["BannersFields_advertise_public"],
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_advertise_campaign"]: AliasType<{
	create?:ResolverInputTypes["BannersFields_advertise_campaign_Create"],
	read?:ResolverInputTypes["BannersFields_advertise_campaign_Read"],
	update?:ResolverInputTypes["BannersFields_advertise_campaign_Update"],
	delete?:ResolverInputTypes["BannersFields_advertise_campaign_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_advertise_campaign_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_advertise_campaign_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_advertise_campaign_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_advertise_campaign_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_advertise_invoiceDescription"]: AliasType<{
	create?:ResolverInputTypes["BannersFields_advertise_invoiceDescription_Create"],
	read?:ResolverInputTypes["BannersFields_advertise_invoiceDescription_Read"],
	update?:ResolverInputTypes["BannersFields_advertise_invoiceDescription_Update"],
	delete?:ResolverInputTypes["BannersFields_advertise_invoiceDescription_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_advertise_invoiceDescription_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_advertise_invoiceDescription_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_advertise_invoiceDescription_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_advertise_invoiceDescription_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_advertise_standardPrice"]: AliasType<{
	create?:ResolverInputTypes["BannersFields_advertise_standardPrice_Create"],
	read?:ResolverInputTypes["BannersFields_advertise_standardPrice_Read"],
	update?:ResolverInputTypes["BannersFields_advertise_standardPrice_Update"],
	delete?:ResolverInputTypes["BannersFields_advertise_standardPrice_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_advertise_standardPrice_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_advertise_standardPrice_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_advertise_standardPrice_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_advertise_standardPrice_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_advertise_discount"]: AliasType<{
	create?:ResolverInputTypes["BannersFields_advertise_discount_Create"],
	read?:ResolverInputTypes["BannersFields_advertise_discount_Read"],
	update?:ResolverInputTypes["BannersFields_advertise_discount_Update"],
	delete?:ResolverInputTypes["BannersFields_advertise_discount_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_advertise_discount_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_advertise_discount_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_advertise_discount_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_advertise_discount_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_advertise_timableCost"]: AliasType<{
	create?:ResolverInputTypes["BannersFields_advertise_timableCost_Create"],
	read?:ResolverInputTypes["BannersFields_advertise_timableCost_Read"],
	update?:ResolverInputTypes["BannersFields_advertise_timableCost_Update"],
	delete?:ResolverInputTypes["BannersFields_advertise_timableCost_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_advertise_timableCost_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_advertise_timableCost_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_advertise_timableCost_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_advertise_timableCost_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_advertise_remark"]: AliasType<{
	create?:ResolverInputTypes["BannersFields_advertise_remark_Create"],
	read?:ResolverInputTypes["BannersFields_advertise_remark_Read"],
	update?:ResolverInputTypes["BannersFields_advertise_remark_Update"],
	delete?:ResolverInputTypes["BannersFields_advertise_remark_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_advertise_remark_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_advertise_remark_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_advertise_remark_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_advertise_remark_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_advertise_public"]: AliasType<{
	create?:ResolverInputTypes["BannersFields_advertise_public_Create"],
	read?:ResolverInputTypes["BannersFields_advertise_public_Read"],
	update?:ResolverInputTypes["BannersFields_advertise_public_Update"],
	delete?:ResolverInputTypes["BannersFields_advertise_public_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_advertise_public_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_advertise_public_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_advertise_public_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_advertise_public_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersFields__locations"]: AliasType<{
	create?:ResolverInputTypes["BannersFields__locations_Create"],
	read?:ResolverInputTypes["BannersFields__locations_Read"],
	update?:ResolverInputTypes["BannersFields__locations_Update"],
	delete?:ResolverInputTypes["BannersFields__locations_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["BannersFields__locations_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersFields__locations_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersFields__locations_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersFields__locations_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_region"]: AliasType<{
	create?:ResolverInputTypes["BannersFields_region_Create"],
	read?:ResolverInputTypes["BannersFields_region_Read"],
	update?:ResolverInputTypes["BannersFields_region_Update"],
	delete?:ResolverInputTypes["BannersFields_region_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_region_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_region_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_region_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_region_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_homePageClick"]: AliasType<{
	create?:ResolverInputTypes["BannersFields_homePageClick_Create"],
	read?:ResolverInputTypes["BannersFields_homePageClick_Read"],
	update?:ResolverInputTypes["BannersFields_homePageClick_Update"],
	delete?:ResolverInputTypes["BannersFields_homePageClick_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_homePageClick_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_homePageClick_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_homePageClick_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_homePageClick_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_homePageImpression"]: AliasType<{
	create?:ResolverInputTypes["BannersFields_homePageImpression_Create"],
	read?:ResolverInputTypes["BannersFields_homePageImpression_Read"],
	update?:ResolverInputTypes["BannersFields_homePageImpression_Update"],
	delete?:ResolverInputTypes["BannersFields_homePageImpression_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_homePageImpression_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_homePageImpression_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_homePageImpression_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_homePageImpression_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_latestEventClick"]: AliasType<{
	create?:ResolverInputTypes["BannersFields_latestEventClick_Create"],
	read?:ResolverInputTypes["BannersFields_latestEventClick_Read"],
	update?:ResolverInputTypes["BannersFields_latestEventClick_Update"],
	delete?:ResolverInputTypes["BannersFields_latestEventClick_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_latestEventClick_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_latestEventClick_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_latestEventClick_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_latestEventClick_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_latestEventImpression"]: AliasType<{
	create?:ResolverInputTypes["BannersFields_latestEventImpression_Create"],
	read?:ResolverInputTypes["BannersFields_latestEventImpression_Read"],
	update?:ResolverInputTypes["BannersFields_latestEventImpression_Update"],
	delete?:ResolverInputTypes["BannersFields_latestEventImpression_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_latestEventImpression_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_latestEventImpression_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_latestEventImpression_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_latestEventImpression_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_editorChoiceClick"]: AliasType<{
	create?:ResolverInputTypes["BannersFields_editorChoiceClick_Create"],
	read?:ResolverInputTypes["BannersFields_editorChoiceClick_Read"],
	update?:ResolverInputTypes["BannersFields_editorChoiceClick_Update"],
	delete?:ResolverInputTypes["BannersFields_editorChoiceClick_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_editorChoiceClick_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_editorChoiceClick_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_editorChoiceClick_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_editorChoiceClick_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_editorChoiceImpression"]: AliasType<{
	create?:ResolverInputTypes["BannersFields_editorChoiceImpression_Create"],
	read?:ResolverInputTypes["BannersFields_editorChoiceImpression_Read"],
	update?:ResolverInputTypes["BannersFields_editorChoiceImpression_Update"],
	delete?:ResolverInputTypes["BannersFields_editorChoiceImpression_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_editorChoiceImpression_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_editorChoiceImpression_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_editorChoiceImpression_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_editorChoiceImpression_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_eventListClick"]: AliasType<{
	create?:ResolverInputTypes["BannersFields_eventListClick_Create"],
	read?:ResolverInputTypes["BannersFields_eventListClick_Read"],
	update?:ResolverInputTypes["BannersFields_eventListClick_Update"],
	delete?:ResolverInputTypes["BannersFields_eventListClick_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_eventListClick_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_eventListClick_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_eventListClick_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_eventListClick_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_eventListImpression"]: AliasType<{
	create?:ResolverInputTypes["BannersFields_eventListImpression_Create"],
	read?:ResolverInputTypes["BannersFields_eventListImpression_Read"],
	update?:ResolverInputTypes["BannersFields_eventListImpression_Update"],
	delete?:ResolverInputTypes["BannersFields_eventListImpression_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_eventListImpression_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_eventListImpression_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_eventListImpression_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_eventListImpression_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_topTenClick"]: AliasType<{
	create?:ResolverInputTypes["BannersFields_topTenClick_Create"],
	read?:ResolverInputTypes["BannersFields_topTenClick_Read"],
	update?:ResolverInputTypes["BannersFields_topTenClick_Update"],
	delete?:ResolverInputTypes["BannersFields_topTenClick_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_topTenClick_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_topTenClick_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_topTenClick_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_topTenClick_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_topTenImpression"]: AliasType<{
	create?:ResolverInputTypes["BannersFields_topTenImpression_Create"],
	read?:ResolverInputTypes["BannersFields_topTenImpression_Read"],
	update?:ResolverInputTypes["BannersFields_topTenImpression_Update"],
	delete?:ResolverInputTypes["BannersFields_topTenImpression_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_topTenImpression_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_topTenImpression_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_topTenImpression_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_topTenImpression_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_miscClick"]: AliasType<{
	create?:ResolverInputTypes["BannersFields_miscClick_Create"],
	read?:ResolverInputTypes["BannersFields_miscClick_Read"],
	update?:ResolverInputTypes["BannersFields_miscClick_Update"],
	delete?:ResolverInputTypes["BannersFields_miscClick_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_miscClick_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_miscClick_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_miscClick_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_miscClick_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_miscImpression"]: AliasType<{
	create?:ResolverInputTypes["BannersFields_miscImpression_Create"],
	read?:ResolverInputTypes["BannersFields_miscImpression_Read"],
	update?:ResolverInputTypes["BannersFields_miscImpression_Update"],
	delete?:ResolverInputTypes["BannersFields_miscImpression_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_miscImpression_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_miscImpression_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_miscImpression_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_miscImpression_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_clickTotal"]: AliasType<{
	create?:ResolverInputTypes["BannersFields_clickTotal_Create"],
	read?:ResolverInputTypes["BannersFields_clickTotal_Read"],
	update?:ResolverInputTypes["BannersFields_clickTotal_Update"],
	delete?:ResolverInputTypes["BannersFields_clickTotal_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_clickTotal_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_clickTotal_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_clickTotal_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_clickTotal_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_totalImpression"]: AliasType<{
	create?:ResolverInputTypes["BannersFields_totalImpression_Create"],
	read?:ResolverInputTypes["BannersFields_totalImpression_Read"],
	update?:ResolverInputTypes["BannersFields_totalImpression_Update"],
	delete?:ResolverInputTypes["BannersFields_totalImpression_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_totalImpression_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_totalImpression_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_totalImpression_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_totalImpression_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_updatedAt"]: AliasType<{
	create?:ResolverInputTypes["BannersFields_updatedAt_Create"],
	read?:ResolverInputTypes["BannersFields_updatedAt_Read"],
	update?:ResolverInputTypes["BannersFields_updatedAt_Update"],
	delete?:ResolverInputTypes["BannersFields_updatedAt_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_updatedAt_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_updatedAt_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_updatedAt_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_updatedAt_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_createdAt"]: AliasType<{
	create?:ResolverInputTypes["BannersFields_createdAt_Create"],
	read?:ResolverInputTypes["BannersFields_createdAt_Read"],
	update?:ResolverInputTypes["BannersFields_createdAt_Update"],
	delete?:ResolverInputTypes["BannersFields_createdAt_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_createdAt_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_createdAt_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_createdAt_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersFields_createdAt_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersCreateAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersReadAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersUpdateAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BannersDeleteAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["campaignsAccess"]: AliasType<{
	fields?:ResolverInputTypes["CampaignsFields"],
	create?:ResolverInputTypes["CampaignsCreateAccess"],
	read?:ResolverInputTypes["CampaignsReadAccess"],
	update?:ResolverInputTypes["CampaignsUpdateAccess"],
	delete?:ResolverInputTypes["CampaignsDeleteAccess"],
		__typename?: boolean | `@${string}`
}>;
	["CampaignsFields"]: AliasType<{
	creator?:ResolverInputTypes["CampaignsFields_creator"],
	invoiceNumber?:ResolverInputTypes["CampaignsFields_invoiceNumber"],
	quotationNumber?:ResolverInputTypes["CampaignsFields_quotationNumber"],
	campaignName?:ResolverInputTypes["CampaignsFields_campaignName"],
	client?:ResolverInputTypes["CampaignsFields_client"],
	contactPerson?:ResolverInputTypes["CampaignsFields_contactPerson"],
	email?:ResolverInputTypes["CampaignsFields_email"],
	phone?:ResolverInputTypes["CampaignsFields_phone"],
	agentName?:ResolverInputTypes["CampaignsFields_agentName"],
	agentCommission?:ResolverInputTypes["CampaignsFields_agentCommission"],
	agentOrderNumber?:ResolverInputTypes["CampaignsFields_agentOrderNumber"],
	_firstPublishedTime?:ResolverInputTypes["CampaignsFields__firstPublishedTime"],
	updatedAt?:ResolverInputTypes["CampaignsFields_updatedAt"],
	createdAt?:ResolverInputTypes["CampaignsFields_createdAt"],
		__typename?: boolean | `@${string}`
}>;
	["CampaignsFields_creator"]: AliasType<{
	create?:ResolverInputTypes["CampaignsFields_creator_Create"],
	read?:ResolverInputTypes["CampaignsFields_creator_Read"],
	update?:ResolverInputTypes["CampaignsFields_creator_Update"],
	delete?:ResolverInputTypes["CampaignsFields_creator_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["CampaignsFields_creator_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CampaignsFields_creator_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CampaignsFields_creator_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CampaignsFields_creator_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CampaignsFields_invoiceNumber"]: AliasType<{
	create?:ResolverInputTypes["CampaignsFields_invoiceNumber_Create"],
	read?:ResolverInputTypes["CampaignsFields_invoiceNumber_Read"],
	update?:ResolverInputTypes["CampaignsFields_invoiceNumber_Update"],
	delete?:ResolverInputTypes["CampaignsFields_invoiceNumber_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["CampaignsFields_invoiceNumber_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CampaignsFields_invoiceNumber_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CampaignsFields_invoiceNumber_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CampaignsFields_invoiceNumber_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CampaignsFields_quotationNumber"]: AliasType<{
	create?:ResolverInputTypes["CampaignsFields_quotationNumber_Create"],
	read?:ResolverInputTypes["CampaignsFields_quotationNumber_Read"],
	update?:ResolverInputTypes["CampaignsFields_quotationNumber_Update"],
	delete?:ResolverInputTypes["CampaignsFields_quotationNumber_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["CampaignsFields_quotationNumber_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CampaignsFields_quotationNumber_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CampaignsFields_quotationNumber_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CampaignsFields_quotationNumber_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CampaignsFields_campaignName"]: AliasType<{
	create?:ResolverInputTypes["CampaignsFields_campaignName_Create"],
	read?:ResolverInputTypes["CampaignsFields_campaignName_Read"],
	update?:ResolverInputTypes["CampaignsFields_campaignName_Update"],
	delete?:ResolverInputTypes["CampaignsFields_campaignName_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["CampaignsFields_campaignName_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CampaignsFields_campaignName_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CampaignsFields_campaignName_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CampaignsFields_campaignName_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CampaignsFields_client"]: AliasType<{
	create?:ResolverInputTypes["CampaignsFields_client_Create"],
	read?:ResolverInputTypes["CampaignsFields_client_Read"],
	update?:ResolverInputTypes["CampaignsFields_client_Update"],
	delete?:ResolverInputTypes["CampaignsFields_client_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["CampaignsFields_client_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CampaignsFields_client_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CampaignsFields_client_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CampaignsFields_client_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CampaignsFields_contactPerson"]: AliasType<{
	create?:ResolverInputTypes["CampaignsFields_contactPerson_Create"],
	read?:ResolverInputTypes["CampaignsFields_contactPerson_Read"],
	update?:ResolverInputTypes["CampaignsFields_contactPerson_Update"],
	delete?:ResolverInputTypes["CampaignsFields_contactPerson_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["CampaignsFields_contactPerson_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CampaignsFields_contactPerson_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CampaignsFields_contactPerson_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CampaignsFields_contactPerson_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CampaignsFields_email"]: AliasType<{
	create?:ResolverInputTypes["CampaignsFields_email_Create"],
	read?:ResolverInputTypes["CampaignsFields_email_Read"],
	update?:ResolverInputTypes["CampaignsFields_email_Update"],
	delete?:ResolverInputTypes["CampaignsFields_email_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["CampaignsFields_email_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CampaignsFields_email_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CampaignsFields_email_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CampaignsFields_email_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CampaignsFields_phone"]: AliasType<{
	create?:ResolverInputTypes["CampaignsFields_phone_Create"],
	read?:ResolverInputTypes["CampaignsFields_phone_Read"],
	update?:ResolverInputTypes["CampaignsFields_phone_Update"],
	delete?:ResolverInputTypes["CampaignsFields_phone_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["CampaignsFields_phone_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CampaignsFields_phone_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CampaignsFields_phone_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CampaignsFields_phone_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CampaignsFields_agentName"]: AliasType<{
	create?:ResolverInputTypes["CampaignsFields_agentName_Create"],
	read?:ResolverInputTypes["CampaignsFields_agentName_Read"],
	update?:ResolverInputTypes["CampaignsFields_agentName_Update"],
	delete?:ResolverInputTypes["CampaignsFields_agentName_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["CampaignsFields_agentName_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CampaignsFields_agentName_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CampaignsFields_agentName_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CampaignsFields_agentName_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CampaignsFields_agentCommission"]: AliasType<{
	create?:ResolverInputTypes["CampaignsFields_agentCommission_Create"],
	read?:ResolverInputTypes["CampaignsFields_agentCommission_Read"],
	update?:ResolverInputTypes["CampaignsFields_agentCommission_Update"],
	delete?:ResolverInputTypes["CampaignsFields_agentCommission_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["CampaignsFields_agentCommission_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CampaignsFields_agentCommission_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CampaignsFields_agentCommission_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CampaignsFields_agentCommission_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CampaignsFields_agentOrderNumber"]: AliasType<{
	create?:ResolverInputTypes["CampaignsFields_agentOrderNumber_Create"],
	read?:ResolverInputTypes["CampaignsFields_agentOrderNumber_Read"],
	update?:ResolverInputTypes["CampaignsFields_agentOrderNumber_Update"],
	delete?:ResolverInputTypes["CampaignsFields_agentOrderNumber_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["CampaignsFields_agentOrderNumber_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CampaignsFields_agentOrderNumber_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CampaignsFields_agentOrderNumber_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CampaignsFields_agentOrderNumber_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CampaignsFields__firstPublishedTime"]: AliasType<{
	create?:ResolverInputTypes["CampaignsFields__firstPublishedTime_Create"],
	read?:ResolverInputTypes["CampaignsFields__firstPublishedTime_Read"],
	update?:ResolverInputTypes["CampaignsFields__firstPublishedTime_Update"],
	delete?:ResolverInputTypes["CampaignsFields__firstPublishedTime_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["CampaignsFields__firstPublishedTime_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CampaignsFields__firstPublishedTime_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CampaignsFields__firstPublishedTime_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CampaignsFields__firstPublishedTime_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CampaignsFields_updatedAt"]: AliasType<{
	create?:ResolverInputTypes["CampaignsFields_updatedAt_Create"],
	read?:ResolverInputTypes["CampaignsFields_updatedAt_Read"],
	update?:ResolverInputTypes["CampaignsFields_updatedAt_Update"],
	delete?:ResolverInputTypes["CampaignsFields_updatedAt_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["CampaignsFields_updatedAt_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CampaignsFields_updatedAt_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CampaignsFields_updatedAt_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CampaignsFields_updatedAt_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CampaignsFields_createdAt"]: AliasType<{
	create?:ResolverInputTypes["CampaignsFields_createdAt_Create"],
	read?:ResolverInputTypes["CampaignsFields_createdAt_Read"],
	update?:ResolverInputTypes["CampaignsFields_createdAt_Update"],
	delete?:ResolverInputTypes["CampaignsFields_createdAt_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["CampaignsFields_createdAt_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CampaignsFields_createdAt_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CampaignsFields_createdAt_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CampaignsFields_createdAt_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CampaignsCreateAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CampaignsReadAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CampaignsUpdateAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CampaignsDeleteAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["advertisesAccess"]: AliasType<{
	fields?:ResolverInputTypes["AdvertisesFields"],
	create?:ResolverInputTypes["AdvertisesCreateAccess"],
	read?:ResolverInputTypes["AdvertisesReadAccess"],
	update?:ResolverInputTypes["AdvertisesUpdateAccess"],
	delete?:ResolverInputTypes["AdvertisesDeleteAccess"],
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesFields"]: AliasType<{
	advertise?:ResolverInputTypes["AdvertisesFields_advertise"],
	advertiseStartDate?:ResolverInputTypes["AdvertisesFields_advertiseStartDate"],
	advertiseEndDate?:ResolverInputTypes["AdvertisesFields_advertiseEndDate"],
	type?:ResolverInputTypes["AdvertisesFields_type"],
	invoiceDescription?:ResolverInputTypes["AdvertisesFields_invoiceDescription"],
	standardPrice?:ResolverInputTypes["AdvertisesFields_standardPrice"],
	discount?:ResolverInputTypes["AdvertisesFields_discount"],
	discountPercentage?:ResolverInputTypes["AdvertisesFields_discountPercentage"],
	_calculateByDiscount?:ResolverInputTypes["AdvertisesFields__calculateByDiscount"],
	timableCost?:ResolverInputTypes["AdvertisesFields_timableCost"],
	remark?:ResolverInputTypes["AdvertisesFields_remark"],
	public?:ResolverInputTypes["AdvertisesFields_public"],
	campaignName?:ResolverInputTypes["AdvertisesFields_campaignName"],
	campaignId?:ResolverInputTypes["AdvertisesFields_campaignId"],
	status?:ResolverInputTypes["AdvertisesFields_status"],
	updatedAt?:ResolverInputTypes["AdvertisesFields_updatedAt"],
	createdAt?:ResolverInputTypes["AdvertisesFields_createdAt"],
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesFields_advertise"]: AliasType<{
	create?:ResolverInputTypes["AdvertisesFields_advertise_Create"],
	read?:ResolverInputTypes["AdvertisesFields_advertise_Read"],
	update?:ResolverInputTypes["AdvertisesFields_advertise_Update"],
	delete?:ResolverInputTypes["AdvertisesFields_advertise_Delete"],
	fields?:ResolverInputTypes["AdvertisesFields_advertise_Fields"],
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesFields_advertise_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesFields_advertise_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesFields_advertise_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesFields_advertise_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesFields_advertise_Fields"]: AliasType<{
	type?:ResolverInputTypes["AdvertisesFields_advertise_type"],
	event?:ResolverInputTypes["AdvertisesFields_advertise_event"],
	keyword?:ResolverInputTypes["AdvertisesFields_advertise_keyword"],
	location?:ResolverInputTypes["AdvertisesFields_advertise_location"],
	displayPosition?:ResolverInputTypes["AdvertisesFields_advertise_displayPosition"],
	rankingListRemark?:ResolverInputTypes["AdvertisesFields_advertise_rankingListRemark"],
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesFields_advertise_type"]: AliasType<{
	create?:ResolverInputTypes["AdvertisesFields_advertise_type_Create"],
	read?:ResolverInputTypes["AdvertisesFields_advertise_type_Read"],
	update?:ResolverInputTypes["AdvertisesFields_advertise_type_Update"],
	delete?:ResolverInputTypes["AdvertisesFields_advertise_type_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesFields_advertise_type_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesFields_advertise_type_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesFields_advertise_type_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesFields_advertise_type_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesFields_advertise_event"]: AliasType<{
	create?:ResolverInputTypes["AdvertisesFields_advertise_event_Create"],
	read?:ResolverInputTypes["AdvertisesFields_advertise_event_Read"],
	update?:ResolverInputTypes["AdvertisesFields_advertise_event_Update"],
	delete?:ResolverInputTypes["AdvertisesFields_advertise_event_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesFields_advertise_event_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesFields_advertise_event_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesFields_advertise_event_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesFields_advertise_event_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesFields_advertise_keyword"]: AliasType<{
	create?:ResolverInputTypes["AdvertisesFields_advertise_keyword_Create"],
	read?:ResolverInputTypes["AdvertisesFields_advertise_keyword_Read"],
	update?:ResolverInputTypes["AdvertisesFields_advertise_keyword_Update"],
	delete?:ResolverInputTypes["AdvertisesFields_advertise_keyword_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesFields_advertise_keyword_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesFields_advertise_keyword_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesFields_advertise_keyword_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesFields_advertise_keyword_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesFields_advertise_location"]: AliasType<{
	create?:ResolverInputTypes["AdvertisesFields_advertise_location_Create"],
	read?:ResolverInputTypes["AdvertisesFields_advertise_location_Read"],
	update?:ResolverInputTypes["AdvertisesFields_advertise_location_Update"],
	delete?:ResolverInputTypes["AdvertisesFields_advertise_location_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesFields_advertise_location_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesFields_advertise_location_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesFields_advertise_location_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesFields_advertise_location_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesFields_advertise_displayPosition"]: AliasType<{
	create?:ResolverInputTypes["AdvertisesFields_advertise_displayPosition_Create"],
	read?:ResolverInputTypes["AdvertisesFields_advertise_displayPosition_Read"],
	update?:ResolverInputTypes["AdvertisesFields_advertise_displayPosition_Update"],
	delete?:ResolverInputTypes["AdvertisesFields_advertise_displayPosition_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesFields_advertise_displayPosition_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesFields_advertise_displayPosition_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesFields_advertise_displayPosition_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesFields_advertise_displayPosition_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesFields_advertise_rankingListRemark"]: AliasType<{
	create?:ResolverInputTypes["AdvertisesFields_advertise_rankingListRemark_Create"],
	read?:ResolverInputTypes["AdvertisesFields_advertise_rankingListRemark_Read"],
	update?:ResolverInputTypes["AdvertisesFields_advertise_rankingListRemark_Update"],
	delete?:ResolverInputTypes["AdvertisesFields_advertise_rankingListRemark_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesFields_advertise_rankingListRemark_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesFields_advertise_rankingListRemark_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesFields_advertise_rankingListRemark_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesFields_advertise_rankingListRemark_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesFields_advertiseStartDate"]: AliasType<{
	create?:ResolverInputTypes["AdvertisesFields_advertiseStartDate_Create"],
	read?:ResolverInputTypes["AdvertisesFields_advertiseStartDate_Read"],
	update?:ResolverInputTypes["AdvertisesFields_advertiseStartDate_Update"],
	delete?:ResolverInputTypes["AdvertisesFields_advertiseStartDate_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesFields_advertiseStartDate_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesFields_advertiseStartDate_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesFields_advertiseStartDate_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesFields_advertiseStartDate_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesFields_advertiseEndDate"]: AliasType<{
	create?:ResolverInputTypes["AdvertisesFields_advertiseEndDate_Create"],
	read?:ResolverInputTypes["AdvertisesFields_advertiseEndDate_Read"],
	update?:ResolverInputTypes["AdvertisesFields_advertiseEndDate_Update"],
	delete?:ResolverInputTypes["AdvertisesFields_advertiseEndDate_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesFields_advertiseEndDate_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesFields_advertiseEndDate_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesFields_advertiseEndDate_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesFields_advertiseEndDate_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesFields_type"]: AliasType<{
	create?:ResolverInputTypes["AdvertisesFields_type_Create"],
	read?:ResolverInputTypes["AdvertisesFields_type_Read"],
	update?:ResolverInputTypes["AdvertisesFields_type_Update"],
	delete?:ResolverInputTypes["AdvertisesFields_type_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesFields_type_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesFields_type_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesFields_type_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesFields_type_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesFields_invoiceDescription"]: AliasType<{
	create?:ResolverInputTypes["AdvertisesFields_invoiceDescription_Create"],
	read?:ResolverInputTypes["AdvertisesFields_invoiceDescription_Read"],
	update?:ResolverInputTypes["AdvertisesFields_invoiceDescription_Update"],
	delete?:ResolverInputTypes["AdvertisesFields_invoiceDescription_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesFields_invoiceDescription_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesFields_invoiceDescription_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesFields_invoiceDescription_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesFields_invoiceDescription_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesFields_standardPrice"]: AliasType<{
	create?:ResolverInputTypes["AdvertisesFields_standardPrice_Create"],
	read?:ResolverInputTypes["AdvertisesFields_standardPrice_Read"],
	update?:ResolverInputTypes["AdvertisesFields_standardPrice_Update"],
	delete?:ResolverInputTypes["AdvertisesFields_standardPrice_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesFields_standardPrice_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesFields_standardPrice_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesFields_standardPrice_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesFields_standardPrice_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesFields_discount"]: AliasType<{
	create?:ResolverInputTypes["AdvertisesFields_discount_Create"],
	read?:ResolverInputTypes["AdvertisesFields_discount_Read"],
	update?:ResolverInputTypes["AdvertisesFields_discount_Update"],
	delete?:ResolverInputTypes["AdvertisesFields_discount_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesFields_discount_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesFields_discount_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesFields_discount_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesFields_discount_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesFields_discountPercentage"]: AliasType<{
	create?:ResolverInputTypes["AdvertisesFields_discountPercentage_Create"],
	read?:ResolverInputTypes["AdvertisesFields_discountPercentage_Read"],
	update?:ResolverInputTypes["AdvertisesFields_discountPercentage_Update"],
	delete?:ResolverInputTypes["AdvertisesFields_discountPercentage_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesFields_discountPercentage_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesFields_discountPercentage_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesFields_discountPercentage_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesFields_discountPercentage_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesFields__calculateByDiscount"]: AliasType<{
	create?:ResolverInputTypes["AdvertisesFields__calculateByDiscount_Create"],
	read?:ResolverInputTypes["AdvertisesFields__calculateByDiscount_Read"],
	update?:ResolverInputTypes["AdvertisesFields__calculateByDiscount_Update"],
	delete?:ResolverInputTypes["AdvertisesFields__calculateByDiscount_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesFields__calculateByDiscount_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesFields__calculateByDiscount_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesFields__calculateByDiscount_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesFields__calculateByDiscount_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesFields_timableCost"]: AliasType<{
	create?:ResolverInputTypes["AdvertisesFields_timableCost_Create"],
	read?:ResolverInputTypes["AdvertisesFields_timableCost_Read"],
	update?:ResolverInputTypes["AdvertisesFields_timableCost_Update"],
	delete?:ResolverInputTypes["AdvertisesFields_timableCost_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesFields_timableCost_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesFields_timableCost_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesFields_timableCost_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesFields_timableCost_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesFields_remark"]: AliasType<{
	create?:ResolverInputTypes["AdvertisesFields_remark_Create"],
	read?:ResolverInputTypes["AdvertisesFields_remark_Read"],
	update?:ResolverInputTypes["AdvertisesFields_remark_Update"],
	delete?:ResolverInputTypes["AdvertisesFields_remark_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesFields_remark_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesFields_remark_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesFields_remark_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesFields_remark_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesFields_public"]: AliasType<{
	create?:ResolverInputTypes["AdvertisesFields_public_Create"],
	read?:ResolverInputTypes["AdvertisesFields_public_Read"],
	update?:ResolverInputTypes["AdvertisesFields_public_Update"],
	delete?:ResolverInputTypes["AdvertisesFields_public_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesFields_public_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesFields_public_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesFields_public_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesFields_public_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesFields_campaignName"]: AliasType<{
	create?:ResolverInputTypes["AdvertisesFields_campaignName_Create"],
	read?:ResolverInputTypes["AdvertisesFields_campaignName_Read"],
	update?:ResolverInputTypes["AdvertisesFields_campaignName_Update"],
	delete?:ResolverInputTypes["AdvertisesFields_campaignName_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesFields_campaignName_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesFields_campaignName_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesFields_campaignName_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesFields_campaignName_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesFields_campaignId"]: AliasType<{
	create?:ResolverInputTypes["AdvertisesFields_campaignId_Create"],
	read?:ResolverInputTypes["AdvertisesFields_campaignId_Read"],
	update?:ResolverInputTypes["AdvertisesFields_campaignId_Update"],
	delete?:ResolverInputTypes["AdvertisesFields_campaignId_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesFields_campaignId_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesFields_campaignId_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesFields_campaignId_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesFields_campaignId_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesFields_status"]: AliasType<{
	create?:ResolverInputTypes["AdvertisesFields_status_Create"],
	read?:ResolverInputTypes["AdvertisesFields_status_Read"],
	update?:ResolverInputTypes["AdvertisesFields_status_Update"],
	delete?:ResolverInputTypes["AdvertisesFields_status_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesFields_status_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesFields_status_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesFields_status_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesFields_status_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesFields_updatedAt"]: AliasType<{
	create?:ResolverInputTypes["AdvertisesFields_updatedAt_Create"],
	read?:ResolverInputTypes["AdvertisesFields_updatedAt_Read"],
	update?:ResolverInputTypes["AdvertisesFields_updatedAt_Update"],
	delete?:ResolverInputTypes["AdvertisesFields_updatedAt_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesFields_updatedAt_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesFields_updatedAt_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesFields_updatedAt_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesFields_updatedAt_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesFields_createdAt"]: AliasType<{
	create?:ResolverInputTypes["AdvertisesFields_createdAt_Create"],
	read?:ResolverInputTypes["AdvertisesFields_createdAt_Read"],
	update?:ResolverInputTypes["AdvertisesFields_createdAt_Update"],
	delete?:ResolverInputTypes["AdvertisesFields_createdAt_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesFields_createdAt_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesFields_createdAt_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesFields_createdAt_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesFields_createdAt_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesCreateAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesReadAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesUpdateAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdvertisesDeleteAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["staticsAccess"]: AliasType<{
	fields?:ResolverInputTypes["StaticsFields"],
	create?:ResolverInputTypes["StaticsCreateAccess"],
	read?:ResolverInputTypes["StaticsReadAccess"],
	update?:ResolverInputTypes["StaticsUpdateAccess"],
	delete?:ResolverInputTypes["StaticsDeleteAccess"],
		__typename?: boolean | `@${string}`
}>;
	["StaticsFields"]: AliasType<{
	name?:ResolverInputTypes["StaticsFields_name"],
	key?:ResolverInputTypes["StaticsFields_key"],
	content?:ResolverInputTypes["StaticsFields_content"],
	region?:ResolverInputTypes["StaticsFields_region"],
	updatedAt?:ResolverInputTypes["StaticsFields_updatedAt"],
	createdAt?:ResolverInputTypes["StaticsFields_createdAt"],
		__typename?: boolean | `@${string}`
}>;
	["StaticsFields_name"]: AliasType<{
	create?:ResolverInputTypes["StaticsFields_name_Create"],
	read?:ResolverInputTypes["StaticsFields_name_Read"],
	update?:ResolverInputTypes["StaticsFields_name_Update"],
	delete?:ResolverInputTypes["StaticsFields_name_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["StaticsFields_name_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["StaticsFields_name_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["StaticsFields_name_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["StaticsFields_name_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["StaticsFields_key"]: AliasType<{
	create?:ResolverInputTypes["StaticsFields_key_Create"],
	read?:ResolverInputTypes["StaticsFields_key_Read"],
	update?:ResolverInputTypes["StaticsFields_key_Update"],
	delete?:ResolverInputTypes["StaticsFields_key_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["StaticsFields_key_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["StaticsFields_key_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["StaticsFields_key_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["StaticsFields_key_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["StaticsFields_content"]: AliasType<{
	create?:ResolverInputTypes["StaticsFields_content_Create"],
	read?:ResolverInputTypes["StaticsFields_content_Read"],
	update?:ResolverInputTypes["StaticsFields_content_Update"],
	delete?:ResolverInputTypes["StaticsFields_content_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["StaticsFields_content_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["StaticsFields_content_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["StaticsFields_content_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["StaticsFields_content_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["StaticsFields_region"]: AliasType<{
	create?:ResolverInputTypes["StaticsFields_region_Create"],
	read?:ResolverInputTypes["StaticsFields_region_Read"],
	update?:ResolverInputTypes["StaticsFields_region_Update"],
	delete?:ResolverInputTypes["StaticsFields_region_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["StaticsFields_region_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["StaticsFields_region_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["StaticsFields_region_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["StaticsFields_region_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["StaticsFields_updatedAt"]: AliasType<{
	create?:ResolverInputTypes["StaticsFields_updatedAt_Create"],
	read?:ResolverInputTypes["StaticsFields_updatedAt_Read"],
	update?:ResolverInputTypes["StaticsFields_updatedAt_Update"],
	delete?:ResolverInputTypes["StaticsFields_updatedAt_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["StaticsFields_updatedAt_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["StaticsFields_updatedAt_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["StaticsFields_updatedAt_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["StaticsFields_updatedAt_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["StaticsFields_createdAt"]: AliasType<{
	create?:ResolverInputTypes["StaticsFields_createdAt_Create"],
	read?:ResolverInputTypes["StaticsFields_createdAt_Read"],
	update?:ResolverInputTypes["StaticsFields_createdAt_Update"],
	delete?:ResolverInputTypes["StaticsFields_createdAt_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["StaticsFields_createdAt_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["StaticsFields_createdAt_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["StaticsFields_createdAt_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["StaticsFields_createdAt_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["StaticsCreateAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["StaticsReadAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["StaticsUpdateAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["StaticsDeleteAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["event_viewsAccess"]: AliasType<{
	fields?:ResolverInputTypes["EventViewsFields"],
	create?:ResolverInputTypes["EventViewsCreateAccess"],
	read?:ResolverInputTypes["EventViewsReadAccess"],
	update?:ResolverInputTypes["EventViewsUpdateAccess"],
	delete?:ResolverInputTypes["EventViewsDeleteAccess"],
		__typename?: boolean | `@${string}`
}>;
	["EventViewsFields"]: AliasType<{
	date?:ResolverInputTypes["EventViewsFields_date"],
	eventId?:ResolverInputTypes["EventViewsFields_eventId"],
	viewCount?:ResolverInputTypes["EventViewsFields_viewCount"],
	updatedAt?:ResolverInputTypes["EventViewsFields_updatedAt"],
	createdAt?:ResolverInputTypes["EventViewsFields_createdAt"],
		__typename?: boolean | `@${string}`
}>;
	["EventViewsFields_date"]: AliasType<{
	create?:ResolverInputTypes["EventViewsFields_date_Create"],
	read?:ResolverInputTypes["EventViewsFields_date_Read"],
	update?:ResolverInputTypes["EventViewsFields_date_Update"],
	delete?:ResolverInputTypes["EventViewsFields_date_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventViewsFields_date_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventViewsFields_date_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventViewsFields_date_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventViewsFields_date_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventViewsFields_eventId"]: AliasType<{
	create?:ResolverInputTypes["EventViewsFields_eventId_Create"],
	read?:ResolverInputTypes["EventViewsFields_eventId_Read"],
	update?:ResolverInputTypes["EventViewsFields_eventId_Update"],
	delete?:ResolverInputTypes["EventViewsFields_eventId_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventViewsFields_eventId_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventViewsFields_eventId_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventViewsFields_eventId_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventViewsFields_eventId_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventViewsFields_viewCount"]: AliasType<{
	create?:ResolverInputTypes["EventViewsFields_viewCount_Create"],
	read?:ResolverInputTypes["EventViewsFields_viewCount_Read"],
	update?:ResolverInputTypes["EventViewsFields_viewCount_Update"],
	delete?:ResolverInputTypes["EventViewsFields_viewCount_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventViewsFields_viewCount_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventViewsFields_viewCount_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventViewsFields_viewCount_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventViewsFields_viewCount_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventViewsFields_updatedAt"]: AliasType<{
	create?:ResolverInputTypes["EventViewsFields_updatedAt_Create"],
	read?:ResolverInputTypes["EventViewsFields_updatedAt_Read"],
	update?:ResolverInputTypes["EventViewsFields_updatedAt_Update"],
	delete?:ResolverInputTypes["EventViewsFields_updatedAt_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventViewsFields_updatedAt_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventViewsFields_updatedAt_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventViewsFields_updatedAt_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventViewsFields_updatedAt_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventViewsFields_createdAt"]: AliasType<{
	create?:ResolverInputTypes["EventViewsFields_createdAt_Create"],
	read?:ResolverInputTypes["EventViewsFields_createdAt_Read"],
	update?:ResolverInputTypes["EventViewsFields_createdAt_Update"],
	delete?:ResolverInputTypes["EventViewsFields_createdAt_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["EventViewsFields_createdAt_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventViewsFields_createdAt_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventViewsFields_createdAt_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventViewsFields_createdAt_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventViewsCreateAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventViewsReadAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventViewsUpdateAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EventViewsDeleteAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["post_viewsAccess"]: AliasType<{
	fields?:ResolverInputTypes["PostViewsFields"],
	create?:ResolverInputTypes["PostViewsCreateAccess"],
	read?:ResolverInputTypes["PostViewsReadAccess"],
	update?:ResolverInputTypes["PostViewsUpdateAccess"],
	delete?:ResolverInputTypes["PostViewsDeleteAccess"],
		__typename?: boolean | `@${string}`
}>;
	["PostViewsFields"]: AliasType<{
	date?:ResolverInputTypes["PostViewsFields_date"],
	postId?:ResolverInputTypes["PostViewsFields_postId"],
	viewCount?:ResolverInputTypes["PostViewsFields_viewCount"],
	updatedAt?:ResolverInputTypes["PostViewsFields_updatedAt"],
	createdAt?:ResolverInputTypes["PostViewsFields_createdAt"],
		__typename?: boolean | `@${string}`
}>;
	["PostViewsFields_date"]: AliasType<{
	create?:ResolverInputTypes["PostViewsFields_date_Create"],
	read?:ResolverInputTypes["PostViewsFields_date_Read"],
	update?:ResolverInputTypes["PostViewsFields_date_Update"],
	delete?:ResolverInputTypes["PostViewsFields_date_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["PostViewsFields_date_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostViewsFields_date_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostViewsFields_date_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostViewsFields_date_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostViewsFields_postId"]: AliasType<{
	create?:ResolverInputTypes["PostViewsFields_postId_Create"],
	read?:ResolverInputTypes["PostViewsFields_postId_Read"],
	update?:ResolverInputTypes["PostViewsFields_postId_Update"],
	delete?:ResolverInputTypes["PostViewsFields_postId_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["PostViewsFields_postId_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostViewsFields_postId_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostViewsFields_postId_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostViewsFields_postId_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostViewsFields_viewCount"]: AliasType<{
	create?:ResolverInputTypes["PostViewsFields_viewCount_Create"],
	read?:ResolverInputTypes["PostViewsFields_viewCount_Read"],
	update?:ResolverInputTypes["PostViewsFields_viewCount_Update"],
	delete?:ResolverInputTypes["PostViewsFields_viewCount_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["PostViewsFields_viewCount_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostViewsFields_viewCount_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostViewsFields_viewCount_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostViewsFields_viewCount_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostViewsFields_updatedAt"]: AliasType<{
	create?:ResolverInputTypes["PostViewsFields_updatedAt_Create"],
	read?:ResolverInputTypes["PostViewsFields_updatedAt_Read"],
	update?:ResolverInputTypes["PostViewsFields_updatedAt_Update"],
	delete?:ResolverInputTypes["PostViewsFields_updatedAt_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["PostViewsFields_updatedAt_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostViewsFields_updatedAt_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostViewsFields_updatedAt_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostViewsFields_updatedAt_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostViewsFields_createdAt"]: AliasType<{
	create?:ResolverInputTypes["PostViewsFields_createdAt_Create"],
	read?:ResolverInputTypes["PostViewsFields_createdAt_Read"],
	update?:ResolverInputTypes["PostViewsFields_createdAt_Update"],
	delete?:ResolverInputTypes["PostViewsFields_createdAt_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["PostViewsFields_createdAt_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostViewsFields_createdAt_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostViewsFields_createdAt_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostViewsFields_createdAt_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostViewsCreateAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostViewsReadAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostViewsUpdateAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PostViewsDeleteAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["payload_preferencesAccess"]: AliasType<{
	fields?:ResolverInputTypes["PayloadPreferencesFields"],
	create?:ResolverInputTypes["PayloadPreferencesCreateAccess"],
	read?:ResolverInputTypes["PayloadPreferencesReadAccess"],
	update?:ResolverInputTypes["PayloadPreferencesUpdateAccess"],
	delete?:ResolverInputTypes["PayloadPreferencesDeleteAccess"],
		__typename?: boolean | `@${string}`
}>;
	["PayloadPreferencesFields"]: AliasType<{
	user?:ResolverInputTypes["PayloadPreferencesFields_user"],
	key?:ResolverInputTypes["PayloadPreferencesFields_key"],
	value?:ResolverInputTypes["PayloadPreferencesFields_value"],
	updatedAt?:ResolverInputTypes["PayloadPreferencesFields_updatedAt"],
	createdAt?:ResolverInputTypes["PayloadPreferencesFields_createdAt"],
		__typename?: boolean | `@${string}`
}>;
	["PayloadPreferencesFields_user"]: AliasType<{
	create?:ResolverInputTypes["PayloadPreferencesFields_user_Create"],
	read?:ResolverInputTypes["PayloadPreferencesFields_user_Read"],
	update?:ResolverInputTypes["PayloadPreferencesFields_user_Update"],
	delete?:ResolverInputTypes["PayloadPreferencesFields_user_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["PayloadPreferencesFields_user_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PayloadPreferencesFields_user_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PayloadPreferencesFields_user_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PayloadPreferencesFields_user_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PayloadPreferencesFields_key"]: AliasType<{
	create?:ResolverInputTypes["PayloadPreferencesFields_key_Create"],
	read?:ResolverInputTypes["PayloadPreferencesFields_key_Read"],
	update?:ResolverInputTypes["PayloadPreferencesFields_key_Update"],
	delete?:ResolverInputTypes["PayloadPreferencesFields_key_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["PayloadPreferencesFields_key_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PayloadPreferencesFields_key_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PayloadPreferencesFields_key_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PayloadPreferencesFields_key_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PayloadPreferencesFields_value"]: AliasType<{
	create?:ResolverInputTypes["PayloadPreferencesFields_value_Create"],
	read?:ResolverInputTypes["PayloadPreferencesFields_value_Read"],
	update?:ResolverInputTypes["PayloadPreferencesFields_value_Update"],
	delete?:ResolverInputTypes["PayloadPreferencesFields_value_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["PayloadPreferencesFields_value_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PayloadPreferencesFields_value_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PayloadPreferencesFields_value_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PayloadPreferencesFields_value_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PayloadPreferencesFields_updatedAt"]: AliasType<{
	create?:ResolverInputTypes["PayloadPreferencesFields_updatedAt_Create"],
	read?:ResolverInputTypes["PayloadPreferencesFields_updatedAt_Read"],
	update?:ResolverInputTypes["PayloadPreferencesFields_updatedAt_Update"],
	delete?:ResolverInputTypes["PayloadPreferencesFields_updatedAt_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["PayloadPreferencesFields_updatedAt_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PayloadPreferencesFields_updatedAt_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PayloadPreferencesFields_updatedAt_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PayloadPreferencesFields_updatedAt_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PayloadPreferencesFields_createdAt"]: AliasType<{
	create?:ResolverInputTypes["PayloadPreferencesFields_createdAt_Create"],
	read?:ResolverInputTypes["PayloadPreferencesFields_createdAt_Read"],
	update?:ResolverInputTypes["PayloadPreferencesFields_createdAt_Update"],
	delete?:ResolverInputTypes["PayloadPreferencesFields_createdAt_Delete"],
		__typename?: boolean | `@${string}`
}>;
	["PayloadPreferencesFields_createdAt_Create"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PayloadPreferencesFields_createdAt_Read"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PayloadPreferencesFields_createdAt_Update"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PayloadPreferencesFields_createdAt_Delete"]: AliasType<{
	permission?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PayloadPreferencesCreateAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PayloadPreferencesReadAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PayloadPreferencesUpdateAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PayloadPreferencesDeleteAccess"]: AliasType<{
	permission?:boolean | `@${string}`,
	where?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["GetEventMaxUsedTag"]: AliasType<{
	tag?:ResolverInputTypes["maxUsedTag"],
		__typename?: boolean | `@${string}`
}>;
	["maxUsedTag"]: AliasType<{
	id?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ChatbotStocks"]: AliasType<{
	product?:ResolverInputTypes["Product"],
	ticketing?:ResolverInputTypes["Ticketing"],
		__typename?: boolean | `@${string}`
}>;
	["ChatbotEvents"]: AliasType<{
	events?:ResolverInputTypes["Event"],
		__typename?: boolean | `@${string}`
}>;
	["WebAppCheckInCountGetResponse"]: AliasType<{
	checkInCount?:boolean | `@${string}`,
	totalQrCode?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductWhereInput"]: {
	id?: ResolverInputTypes["ProductDetailOperator"] | undefined | null
};
	["ProductDetailOperator"]: {
	equals?: string | undefined | null
};
	["ProductList"]: AliasType<{
	products?:ResolverInputTypes["Product"],
		__typename?: boolean | `@${string}`
}>;
	["TicketingWhereInput"]: {
	id?: ResolverInputTypes["TicketingDetailOperator"] | undefined | null
};
	["TicketingDetailOperator"]: {
	equals?: string | undefined | null
};
	["TicketingList"]: AliasType<{
	ticketings?:ResolverInputTypes["Ticketing"],
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodeList"]: AliasType<{
	docs?:ResolverInputTypes["RedemptionCode"],
		__typename?: boolean | `@${string}`
}>;
	["RedemptionCodeWhereInput"]: {
	qrcodeId?: ResolverInputTypes["RedemptionCodeDetailOperator"] | undefined | null
};
	["RedemptionCodeDetailOperator"]: {
	equals?: string | undefined | null
};
	["BookmarkList"]: AliasType<{
	docs?:ResolverInputTypes["WebAppBookmark"],
		__typename?: boolean | `@${string}`
}>;
	["Mutation"]: AliasType<{
createEvent?: [{	data: ResolverInputTypes["mutationEventInput"],	locale?: ResolverInputTypes["LocaleInputType"] | undefined | null},ResolverInputTypes["Event"]],
updateEvent?: [{	id: string,	autosave?: boolean | undefined | null,	data: ResolverInputTypes["mutationEventUpdateInput"],	locale?: ResolverInputTypes["LocaleInputType"] | undefined | null},ResolverInputTypes["Event"]],
deleteEvent?: [{	id: string},ResolverInputTypes["Event"]],
createLocation?: [{	data: ResolverInputTypes["mutationLocationInput"],	locale?: ResolverInputTypes["LocaleInputType"] | undefined | null},ResolverInputTypes["Location"]],
updateLocation?: [{	id: string,	autosave?: boolean | undefined | null,	data: ResolverInputTypes["mutationLocationUpdateInput"],	locale?: ResolverInputTypes["LocaleInputType"] | undefined | null},ResolverInputTypes["Location"]],
deleteLocation?: [{	id: string},ResolverInputTypes["Location"]],
createPost?: [{	data: ResolverInputTypes["mutationPostInput"],	locale?: ResolverInputTypes["LocaleInputType"] | undefined | null},ResolverInputTypes["Post"]],
updatePost?: [{	id: string,	autosave?: boolean | undefined | null,	data: ResolverInputTypes["mutationPostUpdateInput"],	locale?: ResolverInputTypes["LocaleInputType"] | undefined | null},ResolverInputTypes["Post"]],
deletePost?: [{	id: string},ResolverInputTypes["Post"]],
createTicketing?: [{	data: ResolverInputTypes["mutationTicketingInput"],	locale?: ResolverInputTypes["LocaleInputType"] | undefined | null},ResolverInputTypes["Ticketing"]],
updateTicketing?: [{	id: string,	autosave?: boolean | undefined | null,	data: ResolverInputTypes["mutationTicketingUpdateInput"],	locale?: ResolverInputTypes["LocaleInputType"] | undefined | null},ResolverInputTypes["Ticketing"]],
deleteTicketing?: [{	id: string},ResolverInputTypes["Ticketing"]],
createProduct?: [{	data: ResolverInputTypes["mutationProductInput"],	locale?: ResolverInputTypes["LocaleInputType"] | undefined | null},ResolverInputTypes["Product"]],
updateProduct?: [{	id: string,	autosave?: boolean | undefined | null,	data: ResolverInputTypes["mutationProductUpdateInput"],	locale?: ResolverInputTypes["LocaleInputType"] | undefined | null},ResolverInputTypes["Product"]],
deleteProduct?: [{	id: string},ResolverInputTypes["Product"]],
createInventoryStatus?: [{	data: ResolverInputTypes["mutationInventoryStatusInput"],	locale?: ResolverInputTypes["LocaleInputType"] | undefined | null},ResolverInputTypes["InventoryStatus"]],
updateInventoryStatus?: [{	id: string,	autosave?: boolean | undefined | null,	data: ResolverInputTypes["mutationInventoryStatusUpdateInput"],	locale?: ResolverInputTypes["LocaleInputType"] | undefined | null},ResolverInputTypes["InventoryStatus"]],
deleteInventoryStatus?: [{	id: string},ResolverInputTypes["InventoryStatus"]],
createTransaction?: [{	data: ResolverInputTypes["mutationTransactionInput"],	locale?: ResolverInputTypes["LocaleInputType"] | undefined | null},ResolverInputTypes["Transaction"]],
updateTransaction?: [{	id: string,	autosave?: boolean | undefined | null,	data: ResolverInputTypes["mutationTransactionUpdateInput"],	locale?: ResolverInputTypes["LocaleInputType"] | undefined | null},ResolverInputTypes["Transaction"]],
deleteTransaction?: [{	id: string},ResolverInputTypes["Transaction"]],
createRedemptionCode?: [{	data: ResolverInputTypes["mutationRedemptionCodeInput"],	locale?: ResolverInputTypes["LocaleInputType"] | undefined | null},ResolverInputTypes["RedemptionCode"]],
updateRedemptionCode?: [{	id: string,	autosave?: boolean | undefined | null,	data: ResolverInputTypes["mutationRedemptionCodeUpdateInput"],	locale?: ResolverInputTypes["LocaleInputType"] | undefined | null},ResolverInputTypes["RedemptionCode"]],
deleteRedemptionCode?: [{	id: string},ResolverInputTypes["RedemptionCode"]],
createRedemptionPdf?: [{	data: ResolverInputTypes["mutationRedemptionPdfInput"],	locale?: ResolverInputTypes["LocaleInputType"] | undefined | null},ResolverInputTypes["RedemptionPdf"]],
updateRedemptionPdf?: [{	id: string,	autosave?: boolean | undefined | null,	data: ResolverInputTypes["mutationRedemptionPdfUpdateInput"],	locale?: ResolverInputTypes["LocaleInputType"] | undefined | null},ResolverInputTypes["RedemptionPdf"]],
deleteRedemptionPdf?: [{	id: string},ResolverInputTypes["RedemptionPdf"]],
createCheckInRedemption?: [{	data: ResolverInputTypes["mutationCheckInRedemptionInput"],	locale?: ResolverInputTypes["LocaleInputType"] | undefined | null},ResolverInputTypes["CheckInRedemption"]],
updateCheckInRedemption?: [{	id: string,	autosave?: boolean | undefined | null,	data: ResolverInputTypes["mutationCheckInRedemptionUpdateInput"],	locale?: ResolverInputTypes["LocaleInputType"] | undefined | null},ResolverInputTypes["CheckInRedemption"]],
deleteCheckInRedemption?: [{	id: string},ResolverInputTypes["CheckInRedemption"]],
createUser?: [{	data: ResolverInputTypes["mutationUserInput"],	locale?: ResolverInputTypes["LocaleInputType"] | undefined | null},ResolverInputTypes["User"]],
updateUser?: [{	id?: string | undefined | null,	autosave?: boolean | undefined | null,	data: ResolverInputTypes["mutationUserUpdateInput"],	locale?: ResolverInputTypes["LocaleInputType"] | undefined | null},ResolverInputTypes["User"]],
deleteUser?: [{	id: string},ResolverInputTypes["User"]],
	refreshTokenUser?:ResolverInputTypes["usersRefreshedUser"],
	logoutUser?:boolean | `@${string}`,
unlockUser?: [{	email: string},boolean | `@${string}`],
loginUser?: [{	email?: string | undefined | null,	password?: string | undefined | null},ResolverInputTypes["usersLoginResult"]],
forgotPasswordUser?: [{	disableEmail?: boolean | undefined | null,	email: string,	expiration?: number | undefined | null},boolean | `@${string}`],
resetPasswordUser?: [{	password?: string | undefined | null,	token?: string | undefined | null},ResolverInputTypes["usersResetPassword"]],
verifyEmailUser?: [{	token?: string | undefined | null},boolean | `@${string}`],
createBookmark?: [{	data: ResolverInputTypes["mutationBookmarkInput"],	locale?: ResolverInputTypes["LocaleInputType"] | undefined | null},ResolverInputTypes["Bookmark"]],
updateBookmark?: [{	id: string,	autosave?: boolean | undefined | null,	data: ResolverInputTypes["mutationBookmarkUpdateInput"],	locale?: ResolverInputTypes["LocaleInputType"] | undefined | null},ResolverInputTypes["Bookmark"]],
deleteBookmark?: [{	id: string},ResolverInputTypes["Bookmark"]],
createWebAppBookmark?: [{	data: ResolverInputTypes["mutationWebAppBookmarkInput"],	locale?: ResolverInputTypes["LocaleInputType"] | undefined | null},ResolverInputTypes["WebAppBookmark"]],
updateWebAppBookmark?: [{	id: string,	autosave?: boolean | undefined | null,	data: ResolverInputTypes["mutationWebAppBookmarkUpdateInput"],	locale?: ResolverInputTypes["LocaleInputType"] | undefined | null},ResolverInputTypes["WebAppBookmark"]],
deleteWebAppBookmark?: [{	id: string},ResolverInputTypes["WebAppBookmark"]],
createViewRecord?: [{	data: ResolverInputTypes["mutationViewRecordInput"],	locale?: ResolverInputTypes["LocaleInputType"] | undefined | null},ResolverInputTypes["ViewRecord"]],
updateViewRecord?: [{	id: string,	autosave?: boolean | undefined | null,	data: ResolverInputTypes["mutationViewRecordUpdateInput"],	locale?: ResolverInputTypes["LocaleInputType"] | undefined | null},ResolverInputTypes["ViewRecord"]],
deleteViewRecord?: [{	id: string},ResolverInputTypes["ViewRecord"]],
createComment?: [{	data: ResolverInputTypes["mutationCommentInput"],	locale?: ResolverInputTypes["LocaleInputType"] | undefined | null},ResolverInputTypes["Comment"]],
updateComment?: [{	id: string,	autosave?: boolean | undefined | null,	data: ResolverInputTypes["mutationCommentUpdateInput"],	locale?: ResolverInputTypes["LocaleInputType"] | undefined | null},ResolverInputTypes["Comment"]],
deleteComment?: [{	id: string},ResolverInputTypes["Comment"]],
createSubscription?: [{	data: ResolverInputTypes["mutationSubscriptionInput"],	locale?: ResolverInputTypes["LocaleInputType"] | undefined | null},ResolverInputTypes["Subscription"]],
updateSubscription?: [{	id: string,	autosave?: boolean | undefined | null,	data: ResolverInputTypes["mutationSubscriptionUpdateInput"],	locale?: ResolverInputTypes["LocaleInputType"] | undefined | null},ResolverInputTypes["Subscription"]],
deleteSubscription?: [{	id: string},ResolverInputTypes["Subscription"]],
createNewsletter?: [{	data: ResolverInputTypes["mutationNewsletterInput"],	locale?: ResolverInputTypes["LocaleInputType"] | undefined | null},ResolverInputTypes["Newsletter"]],
updateNewsletter?: [{	id: string,	autosave?: boolean | undefined | null,	data: ResolverInputTypes["mutationNewsletterUpdateInput"],	locale?: ResolverInputTypes["LocaleInputType"] | undefined | null},ResolverInputTypes["Newsletter"]],
deleteNewsletter?: [{	id: string},ResolverInputTypes["Newsletter"]],
createBlogCategory?: [{	data: ResolverInputTypes["mutationBlogCategoryInput"],	locale?: ResolverInputTypes["LocaleInputType"] | undefined | null},ResolverInputTypes["BlogCategory"]],
updateBlogCategory?: [{	id: string,	autosave?: boolean | undefined | null,	data: ResolverInputTypes["mutationBlogCategoryUpdateInput"],	locale?: ResolverInputTypes["LocaleInputType"] | undefined | null},ResolverInputTypes["BlogCategory"]],
deleteBlogCategory?: [{	id: string},ResolverInputTypes["BlogCategory"]],
createTag?: [{	data: ResolverInputTypes["mutationTagInput"],	locale?: ResolverInputTypes["LocaleInputType"] | undefined | null},ResolverInputTypes["Tag"]],
updateTag?: [{	id: string,	autosave?: boolean | undefined | null,	data: ResolverInputTypes["mutationTagUpdateInput"],	locale?: ResolverInputTypes["LocaleInputType"] | undefined | null},ResolverInputTypes["Tag"]],
deleteTag?: [{	id: string},ResolverInputTypes["Tag"]],
createSpecialTag?: [{	data: ResolverInputTypes["mutationSpecialTagInput"],	locale?: ResolverInputTypes["LocaleInputType"] | undefined | null},ResolverInputTypes["SpecialTag"]],
updateSpecialTag?: [{	id: string,	autosave?: boolean | undefined | null,	data: ResolverInputTypes["mutationSpecialTagUpdateInput"],	locale?: ResolverInputTypes["LocaleInputType"] | undefined | null},ResolverInputTypes["SpecialTag"]],
deleteSpecialTag?: [{	id: string},ResolverInputTypes["SpecialTag"]],
createDate?: [{	data: ResolverInputTypes["mutationDateInput"],	locale?: ResolverInputTypes["LocaleInputType"] | undefined | null},ResolverInputTypes["Date"]],
updateDate?: [{	id: string,	autosave?: boolean | undefined | null,	data: ResolverInputTypes["mutationDateUpdateInput"],	locale?: ResolverInputTypes["LocaleInputType"] | undefined | null},ResolverInputTypes["Date"]],
deleteDate?: [{	id: string},ResolverInputTypes["Date"]],
createDistrict?: [{	data: ResolverInputTypes["mutationDistrictInput"],	locale?: ResolverInputTypes["LocaleInputType"] | undefined | null},ResolverInputTypes["District"]],
updateDistrict?: [{	id: string,	autosave?: boolean | undefined | null,	data: ResolverInputTypes["mutationDistrictUpdateInput"],	locale?: ResolverInputTypes["LocaleInputType"] | undefined | null},ResolverInputTypes["District"]],
deleteDistrict?: [{	id: string},ResolverInputTypes["District"]],
createAudience?: [{	data: ResolverInputTypes["mutationAudienceInput"],	locale?: ResolverInputTypes["LocaleInputType"] | undefined | null},ResolverInputTypes["Audience"]],
updateAudience?: [{	id: string,	autosave?: boolean | undefined | null,	data: ResolverInputTypes["mutationAudienceUpdateInput"],	locale?: ResolverInputTypes["LocaleInputType"] | undefined | null},ResolverInputTypes["Audience"]],
deleteAudience?: [{	id: string},ResolverInputTypes["Audience"]],
createEventCategory?: [{	data: ResolverInputTypes["mutationEventCategoryInput"],	locale?: ResolverInputTypes["LocaleInputType"] | undefined | null},ResolverInputTypes["EventCategory"]],
updateEventCategory?: [{	id: string,	autosave?: boolean | undefined | null,	data: ResolverInputTypes["mutationEventCategoryUpdateInput"],	locale?: ResolverInputTypes["LocaleInputType"] | undefined | null},ResolverInputTypes["EventCategory"]],
deleteEventCategory?: [{	id: string},ResolverInputTypes["EventCategory"]],
createPrivateMedia?: [{	data: ResolverInputTypes["mutationPrivateMediaInput"],	locale?: ResolverInputTypes["LocaleInputType"] | undefined | null},ResolverInputTypes["PrivateMedia"]],
updatePrivateMedia?: [{	id: string,	autosave?: boolean | undefined | null,	data: ResolverInputTypes["mutationPrivateMediaUpdateInput"],	locale?: ResolverInputTypes["LocaleInputType"] | undefined | null},ResolverInputTypes["PrivateMedia"]],
deletePrivateMedia?: [{	id: string},ResolverInputTypes["PrivateMedia"]],
createMedia?: [{	data: ResolverInputTypes["mutationMediaInput"],	locale?: ResolverInputTypes["LocaleInputType"] | undefined | null},ResolverInputTypes["Media"]],
updateMedia?: [{	id: string,	autosave?: boolean | undefined | null,	data: ResolverInputTypes["mutationMediaUpdateInput"],	locale?: ResolverInputTypes["LocaleInputType"] | undefined | null},ResolverInputTypes["Media"]],
deleteMedia?: [{	id: string},ResolverInputTypes["Media"]],
createBackupMedia?: [{	data: ResolverInputTypes["mutationBackupMediaInput"],	locale?: ResolverInputTypes["LocaleInputType"] | undefined | null},ResolverInputTypes["BackupMedia"]],
updateBackupMedia?: [{	id: string,	autosave?: boolean | undefined | null,	data: ResolverInputTypes["mutationBackupMediaUpdateInput"],	locale?: ResolverInputTypes["LocaleInputType"] | undefined | null},ResolverInputTypes["BackupMedia"]],
deleteBackupMedia?: [{	id: string},ResolverInputTypes["BackupMedia"]],
createAccountSuspendRequest?: [{	data: ResolverInputTypes["mutationAccountSuspendRequestInput"],	locale?: ResolverInputTypes["LocaleInputType"] | undefined | null},ResolverInputTypes["AccountSuspendRequest"]],
updateAccountSuspendRequest?: [{	id: string,	autosave?: boolean | undefined | null,	data: ResolverInputTypes["mutationAccountSuspendRequestUpdateInput"],	locale?: ResolverInputTypes["LocaleInputType"] | undefined | null},ResolverInputTypes["AccountSuspendRequest"]],
deleteAccountSuspendRequest?: [{	id: string},ResolverInputTypes["AccountSuspendRequest"]],
createDevice?: [{	data: ResolverInputTypes["mutationDeviceInput"],	locale?: ResolverInputTypes["LocaleInputType"] | undefined | null},ResolverInputTypes["Device"]],
updateDevice?: [{	id: string,	autosave?: boolean | undefined | null,	data: ResolverInputTypes["mutationDeviceUpdateInput"],	locale?: ResolverInputTypes["LocaleInputType"] | undefined | null},ResolverInputTypes["Device"]],
deleteDevice?: [{	id: string},ResolverInputTypes["Device"]],
createNotification?: [{	data: ResolverInputTypes["mutationNotificationInput"],	locale?: ResolverInputTypes["LocaleInputType"] | undefined | null},ResolverInputTypes["Notification"]],
updateNotification?: [{	id: string,	autosave?: boolean | undefined | null,	data: ResolverInputTypes["mutationNotificationUpdateInput"],	locale?: ResolverInputTypes["LocaleInputType"] | undefined | null},ResolverInputTypes["Notification"]],
deleteNotification?: [{	id: string},ResolverInputTypes["Notification"]],
createRedirectSetting?: [{	data: ResolverInputTypes["mutationRedirectSettingInput"],	locale?: ResolverInputTypes["LocaleInputType"] | undefined | null},ResolverInputTypes["RedirectSetting"]],
updateRedirectSetting?: [{	id: string,	autosave?: boolean | undefined | null,	data: ResolverInputTypes["mutationRedirectSettingUpdateInput"],	locale?: ResolverInputTypes["LocaleInputType"] | undefined | null},ResolverInputTypes["RedirectSetting"]],
deleteRedirectSetting?: [{	id: string},ResolverInputTypes["RedirectSetting"]],
createEventRanking?: [{	data: ResolverInputTypes["mutationEventRankingInput"],	locale?: ResolverInputTypes["LocaleInputType"] | undefined | null},ResolverInputTypes["EventRanking"]],
updateEventRanking?: [{	id: string,	autosave?: boolean | undefined | null,	data: ResolverInputTypes["mutationEventRankingUpdateInput"],	locale?: ResolverInputTypes["LocaleInputType"] | undefined | null},ResolverInputTypes["EventRanking"]],
deleteEventRanking?: [{	id: string},ResolverInputTypes["EventRanking"]],
createLocationRanking?: [{	data: ResolverInputTypes["mutationLocationRankingInput"],	locale?: ResolverInputTypes["LocaleInputType"] | undefined | null},ResolverInputTypes["LocationRanking"]],
updateLocationRanking?: [{	id: string,	autosave?: boolean | undefined | null,	data: ResolverInputTypes["mutationLocationRankingUpdateInput"],	locale?: ResolverInputTypes["LocaleInputType"] | undefined | null},ResolverInputTypes["LocationRanking"]],
deleteLocationRanking?: [{	id: string},ResolverInputTypes["LocationRanking"]],
createEventCalendar?: [{	data: ResolverInputTypes["mutationEventCalendarInput"],	locale?: ResolverInputTypes["LocaleInputType"] | undefined | null},ResolverInputTypes["EventCalendar"]],
updateEventCalendar?: [{	id: string,	autosave?: boolean | undefined | null,	data: ResolverInputTypes["mutationEventCalendarUpdateInput"],	locale?: ResolverInputTypes["LocaleInputType"] | undefined | null},ResolverInputTypes["EventCalendar"]],
deleteEventCalendar?: [{	id: string},ResolverInputTypes["EventCalendar"]],
createEditorChoice?: [{	data: ResolverInputTypes["mutationEditorChoiceInput"],	locale?: ResolverInputTypes["LocaleInputType"] | undefined | null},ResolverInputTypes["EditorChoice"]],
updateEditorChoice?: [{	id: string,	autosave?: boolean | undefined | null,	data: ResolverInputTypes["mutationEditorChoiceUpdateInput"],	locale?: ResolverInputTypes["LocaleInputType"] | undefined | null},ResolverInputTypes["EditorChoice"]],
deleteEditorChoice?: [{	id: string},ResolverInputTypes["EditorChoice"]],
createCriteriaSetting?: [{	data: ResolverInputTypes["mutationCriteriaSettingInput"],	locale?: ResolverInputTypes["LocaleInputType"] | undefined | null},ResolverInputTypes["CriteriaSetting"]],
updateCriteriaSetting?: [{	id: string,	autosave?: boolean | undefined | null,	data: ResolverInputTypes["mutationCriteriaSettingUpdateInput"],	locale?: ResolverInputTypes["LocaleInputType"] | undefined | null},ResolverInputTypes["CriteriaSetting"]],
deleteCriteriaSetting?: [{	id: string},ResolverInputTypes["CriteriaSetting"]],
createOrganiserSetting?: [{	data: ResolverInputTypes["mutationOrganiserSettingInput"],	locale?: ResolverInputTypes["LocaleInputType"] | undefined | null},ResolverInputTypes["OrganiserSetting"]],
updateOrganiserSetting?: [{	id: string,	autosave?: boolean | undefined | null,	data: ResolverInputTypes["mutationOrganiserSettingUpdateInput"],	locale?: ResolverInputTypes["LocaleInputType"] | undefined | null},ResolverInputTypes["OrganiserSetting"]],
deleteOrganiserSetting?: [{	id: string},ResolverInputTypes["OrganiserSetting"]],
createPostSetting?: [{	data: ResolverInputTypes["mutationPostSettingInput"],	locale?: ResolverInputTypes["LocaleInputType"] | undefined | null},ResolverInputTypes["PostSetting"]],
updatePostSetting?: [{	id: string,	autosave?: boolean | undefined | null,	data: ResolverInputTypes["mutationPostSettingUpdateInput"],	locale?: ResolverInputTypes["LocaleInputType"] | undefined | null},ResolverInputTypes["PostSetting"]],
deletePostSetting?: [{	id: string},ResolverInputTypes["PostSetting"]],
createHomepageSetting?: [{	data: ResolverInputTypes["mutationHomepageSettingInput"],	locale?: ResolverInputTypes["LocaleInputType"] | undefined | null},ResolverInputTypes["HomepageSetting"]],
updateHomepageSetting?: [{	id: string,	autosave?: boolean | undefined | null,	data: ResolverInputTypes["mutationHomepageSettingUpdateInput"],	locale?: ResolverInputTypes["LocaleInputType"] | undefined | null},ResolverInputTypes["HomepageSetting"]],
deleteHomepageSetting?: [{	id: string},ResolverInputTypes["HomepageSetting"]],
createBannerSection?: [{	data: ResolverInputTypes["mutationBannerSectionInput"],	locale?: ResolverInputTypes["LocaleInputType"] | undefined | null},ResolverInputTypes["BannerSection"]],
updateBannerSection?: [{	id: string,	autosave?: boolean | undefined | null,	data: ResolverInputTypes["mutationBannerSectionUpdateInput"],	locale?: ResolverInputTypes["LocaleInputType"] | undefined | null},ResolverInputTypes["BannerSection"]],
deleteBannerSection?: [{	id: string},ResolverInputTypes["BannerSection"]],
createMacaoBannerSection?: [{	data: ResolverInputTypes["mutationMacaoBannerSectionInput"],	locale?: ResolverInputTypes["LocaleInputType"] | undefined | null},ResolverInputTypes["MacaoBannerSection"]],
updateMacaoBannerSection?: [{	id: string,	autosave?: boolean | undefined | null,	data: ResolverInputTypes["mutationMacaoBannerSectionUpdateInput"],	locale?: ResolverInputTypes["LocaleInputType"] | undefined | null},ResolverInputTypes["MacaoBannerSection"]],
deleteMacaoBannerSection?: [{	id: string},ResolverInputTypes["MacaoBannerSection"]],
createBanner?: [{	data: ResolverInputTypes["mutationBannerInput"],	locale?: ResolverInputTypes["LocaleInputType"] | undefined | null},ResolverInputTypes["Banner"]],
updateBanner?: [{	id: string,	autosave?: boolean | undefined | null,	data: ResolverInputTypes["mutationBannerUpdateInput"],	locale?: ResolverInputTypes["LocaleInputType"] | undefined | null},ResolverInputTypes["Banner"]],
deleteBanner?: [{	id: string},ResolverInputTypes["Banner"]],
createCampaign?: [{	data: ResolverInputTypes["mutationCampaignInput"],	locale?: ResolverInputTypes["LocaleInputType"] | undefined | null},ResolverInputTypes["Campaign"]],
updateCampaign?: [{	id: string,	autosave?: boolean | undefined | null,	data: ResolverInputTypes["mutationCampaignUpdateInput"],	locale?: ResolverInputTypes["LocaleInputType"] | undefined | null},ResolverInputTypes["Campaign"]],
deleteCampaign?: [{	id: string},ResolverInputTypes["Campaign"]],
createAdvertise?: [{	data: ResolverInputTypes["mutationAdvertiseInput"],	locale?: ResolverInputTypes["LocaleInputType"] | undefined | null},ResolverInputTypes["Advertise"]],
updateAdvertise?: [{	id: string,	autosave?: boolean | undefined | null,	data: ResolverInputTypes["mutationAdvertiseUpdateInput"],	locale?: ResolverInputTypes["LocaleInputType"] | undefined | null},ResolverInputTypes["Advertise"]],
deleteAdvertise?: [{	id: string},ResolverInputTypes["Advertise"]],
createStatic?: [{	data: ResolverInputTypes["mutationStaticInput"],	locale?: ResolverInputTypes["LocaleInputType"] | undefined | null},ResolverInputTypes["Static"]],
updateStatic?: [{	id: string,	autosave?: boolean | undefined | null,	data: ResolverInputTypes["mutationStaticUpdateInput"],	locale?: ResolverInputTypes["LocaleInputType"] | undefined | null},ResolverInputTypes["Static"]],
deleteStatic?: [{	id: string},ResolverInputTypes["Static"]],
createEventView?: [{	data: ResolverInputTypes["mutationEventViewInput"],	locale?: ResolverInputTypes["LocaleInputType"] | undefined | null},ResolverInputTypes["EventView"]],
updateEventView?: [{	id: string,	autosave?: boolean | undefined | null,	data: ResolverInputTypes["mutationEventViewUpdateInput"],	locale?: ResolverInputTypes["LocaleInputType"] | undefined | null},ResolverInputTypes["EventView"]],
deleteEventView?: [{	id: string},ResolverInputTypes["EventView"]],
createPostView?: [{	data: ResolverInputTypes["mutationPostViewInput"],	locale?: ResolverInputTypes["LocaleInputType"] | undefined | null},ResolverInputTypes["PostView"]],
updatePostView?: [{	id: string,	autosave?: boolean | undefined | null,	data: ResolverInputTypes["mutationPostViewUpdateInput"],	locale?: ResolverInputTypes["LocaleInputType"] | undefined | null},ResolverInputTypes["PostView"]],
deletePostView?: [{	id: string},ResolverInputTypes["PostView"]],
createPayloadPreference?: [{	data: ResolverInputTypes["mutationPayloadPreferenceInput"],	locale?: ResolverInputTypes["LocaleInputType"] | undefined | null},ResolverInputTypes["PayloadPreference"]],
updatePayloadPreference?: [{	id: string,	autosave?: boolean | undefined | null,	data: ResolverInputTypes["mutationPayloadPreferenceUpdateInput"],	locale?: ResolverInputTypes["LocaleInputType"] | undefined | null},ResolverInputTypes["PayloadPreference"]],
deletePayloadPreference?: [{	id: string},ResolverInputTypes["PayloadPreference"]],
AddEventView?: [{	id?: string | undefined | null,	type?: string | undefined | null},boolean | `@${string}`],
ItemBookmark?: [{	id?: string | undefined | null,	relationTo?: string | undefined | null},boolean | `@${string}`],
ItemUnbookmark?: [{	id?: string | undefined | null,	relationTo?: string | undefined | null},boolean | `@${string}`],
readItem?: [{	relationTo?: ResolverInputTypes["ViewRecord_ItemRelationshipInputRelationTo"] | undefined | null,	value?: ResolverInputTypes["JSON"] | undefined | null},boolean | `@${string}`],
AddPostView?: [{	id?: string | undefined | null,	type?: string | undefined | null},boolean | `@${string}`],
LoginUser?: [{	email?: string | undefined | null,	password?: string | undefined | null,	sha1Password?: string | undefined | null},ResolverInputTypes["usersLoginResult"]],
RegisterUser?: [{	data: ResolverInputTypes["mutationUserInput"],	locale?: ResolverInputTypes["LocaleInputType"] | undefined | null,	subscribeNewsletter?: boolean | undefined | null},ResolverInputTypes["User"]],
ActiveUser?: [{	email?: string | undefined | null},boolean | `@${string}`],
ReActivePasswordUser?: [{	username?: string | undefined | null,	email?: string | undefined | null},boolean | `@${string}`],
SSOLoginUser?: [{	accessToken?: string | undefined | null,	type?: string | undefined | null,	appleJwtClaims?: string | undefined | null},ResolverInputTypes["usersLoginResult"]],
	SuspendUser?:boolean | `@${string}`,
UpdateUserPassword?: [{	email?: string | undefined | null,	password?: string | undefined | null},boolean | `@${string}`],
SubscribeOrganiser?: [{	id?: string | undefined | null,	relationTo?: string | undefined | null},boolean | `@${string}`],
UnSubscribeOrganiser?: [{	id?: string | undefined | null,	relationTo?: string | undefined | null},boolean | `@${string}`],
PostMessage?: [{	relationTo?: string | undefined | null,	content?: string | undefined | null,	image?: string | undefined | null,	parent?: string | undefined | null,	type?: string | undefined | null},boolean | `@${string}`],
AddBannerView?: [{	id?: string | undefined | null,	type?: string | undefined | null},boolean | `@${string}`],
AddDevice?: [{	id?: string | undefined | null,	udid?: string | undefined | null,	pushToken?: string | undefined | null},boolean | `@${string}`],
RemoveDevice?: [{	udid?: string | undefined | null,	pushToken?: string | undefined | null},boolean | `@${string}`],
UpdateNotifications?: [{	userId?: string | undefined | null},boolean | `@${string}`],
DeleteNotification?: [{	userId?: string | undefined | null,	notificationId?: string | undefined | null},boolean | `@${string}`],
CreateNewsletter?: [{	email?: string | undefined | null},boolean | `@${string}`],
ChatbotInventoryHold?: [{	publicUserPhone: string,	variantId: string,	quantity: number},ResolverInputTypes["InventoryStatus"]],
ChatbotInventoryRelease?: [{	inventoryStatusIds?: Array<string | undefined | null> | undefined | null},boolean | `@${string}`],
ChatbotInventoryPayment?: [{	inventoryStatusIds: Array<string>},ResolverInputTypes["ChatbotInventoryPayment"]],
ChatbotTransactionCreate?: [{	inventoryStatusIds: Array<string>,	purchaseDateTime: string,	stripePaymentId: string,	totalAmount: number,	contactPersonName: string,	contactPersonPhone: string,	remarks?: string | undefined | null},ResolverInputTypes["Transaction"]],
ChatbotRedemptionCodeUpdate?: [{	code: string,	ownerName: string},boolean | `@${string}`],
ChatbotTransactionPdfGet?: [{	code: string},ResolverInputTypes["ChatbotTransactionPdfGet"]],
WebAppQrCodeBatchScan?: [{	scans: Array<ResolverInputTypes["WebAppQrCodeBatchScanItem"]>},ResolverInputTypes["WebAppQrCodeBatchScanResult"]],
WebAppQrCodeScan?: [{	checkInAppSelectionId: string,	checkInAppSelectionProductType: string,	qrCodeId: string,	scanAt: string,	uploadAt?: string | undefined | null,	entryNumber: string},ResolverInputTypes["WebAppQrCodeScanResponse"]],
WebAppItemBookmark?: [{	userId: string,	eventId: string,	productType: string,	purchaseItemId: string,	entryNumber: string},ResolverInputTypes["WebAppBookmark"]],
WebAppItemUnBookmark?: [{	userId: string,	eventId: string,	productType: string,	purchaseItemId: string,	entryNumber: string},ResolverInputTypes["WebAppBookmark"]],
		__typename?: boolean | `@${string}`
}>;
	["mutationEventInput"]: {
	region?: ResolverInputTypes["Event_region_MutationInput"] | undefined | null,
	creator?: string | undefined | null,
	permalink?: string | undefined | null,
	status: string,
	level?: ResolverInputTypes["Event_level_MutationInput"] | undefined | null,
	parent?: Array<string | undefined | null> | undefined | null,
	name?: string | undefined | null,
	chatbotEventTitle?: string | undefined | null,
	chatbotSequence?: number | undefined | null,
	thumbnail?: string | undefined | null,
	banner?: string | undefined | null,
	mobileBanner?: string | undefined | null,
	sections?: Array<ResolverInputTypes["mutationEvent_SectionsInput"] | undefined | null> | undefined | null,
	ticketing?: Array<ResolverInputTypes["mutationEvent_TicketingInput"] | undefined | null> | undefined | null,
	content?: ResolverInputTypes["JSON"] | undefined | null,
	legacyContent?: string | undefined | null,
	showLegacyContent?: boolean | undefined | null,
	criteria: ResolverInputTypes["mutationEvent_CriteriaInput"],
	walkIn?: boolean | undefined | null,
	contact?: ResolverInputTypes["mutationEvent_ContactInput"] | undefined | null,
	views?: number | undefined | null,
	free?: boolean | undefined | null,
	fees?: Array<ResolverInputTypes["mutationEvent_FeesInput"] | undefined | null> | undefined | null,
	homePageInternalClick?: number | undefined | null,
	homePageImpression?: number | undefined | null,
	eodClickRate?: number | undefined | null,
	eodImpressionRate?: number | undefined | null,
	top10ClickRateHomepage?: number | undefined | null,
	top10ImpressionkRateHomepage?: number | undefined | null,
	otherEventRankingClickRateHomepage?: number | undefined | null,
	otherEventRankingImpressionHomePage?: number | undefined | null,
	userPreferenceClickRate?: number | undefined | null,
	userPreferenceImpression?: number | undefined | null,
	eventSearchInternalClick?: number | undefined | null,
	eventListImpression?: number | undefined | null,
	latestEventInternalClick?: number | undefined | null,
	latestEventImpression?: number | undefined | null,
	topTenEventRankingInternalClick?: number | undefined | null,
	topTenImpression?: number | undefined | null,
	editorChoiceInternalClick?: number | undefined | null,
	editorChoiceImpression?: number | undefined | null,
	otherEventRankingInternalClick?: number | undefined | null,
	otherEventRankingImpression?: number | undefined | null,
	bannerInternalClick?: number | undefined | null,
	miscImpression?: number | undefined | null,
	miscInternalClick?: number | undefined | null,
	totalImpression?: number | undefined | null,
	internalClick?: number | undefined | null,
	outboundClick?: number | undefined | null,
	externalClick?: number | undefined | null,
	_viewsCountByMonth?: number | undefined | null,
	products?: Array<ResolverInputTypes["mutationEvent_ProductsInput"] | undefined | null> | undefined | null,
	legacyGallery?: Array<string | undefined | null> | undefined | null,
	earliestStartDate?: string | undefined | null,
	latestEndDate?: string | undefined | null,
	latestStartDateTime?: string | undefined | null,
	hasTicketing?: boolean | undefined | null,
	hasProducts?: boolean | undefined | null,
	updatedAt?: string | undefined | null,
	createdAt?: string | undefined | null
};
	["Event_region_MutationInput"]:Event_region_MutationInput;
	["Event_level_MutationInput"]:Event_level_MutationInput;
	["mutationEvent_SectionsInput"]: {
	startDatetime: string,
	endDatetime?: string | undefined | null,
	fullDay?: boolean | undefined | null,
	repeat?: boolean | undefined | null,
	toThisDay?: string | undefined | null,
	recurrance: ResolverInputTypes["mutationEvent_Sections_RecurranceInput"],
	sameLocation?: boolean | undefined | null,
	location?: string | undefined | null,
	district?: string | undefined | null,
	coordinate?: Array<number | undefined | null> | undefined | null,
	linkAddress?: boolean | undefined | null,
	address?: string | undefined | null,
	addressCoordinate?: Array<number | undefined | null> | undefined | null,
	id?: string | undefined | null
};
	["mutationEvent_Sections_RecurranceInput"]: {
	type: ResolverInputTypes["Event_Sections_Recurrance_type_MutationInput"],
	weekday?: Array<ResolverInputTypes["Event_Sections_Recurrance_weekday_MutationInput"] | undefined | null> | undefined | null
};
	["Event_Sections_Recurrance_type_MutationInput"]:Event_Sections_Recurrance_type_MutationInput;
	["Event_Sections_Recurrance_weekday_MutationInput"]:Event_Sections_Recurrance_weekday_MutationInput;
	["mutationEvent_TicketingInput"]: {
	startDatetime: string,
	endDatetime?: string | undefined | null,
	fullDay?: boolean | undefined | null,
	type: ResolverInputTypes["Event_Ticketing_type_MutationInput"],
	ticketingType?: ResolverInputTypes["Event_Ticketing_ticketingType_MutationInput"] | undefined | null,
	linkedTicketing?: string | undefined | null,
	promotionType?: ResolverInputTypes["Event_Ticketing_promotionType_MutationInput"] | undefined | null,
	location?: string | undefined | null,
	website?: string | undefined | null,
	description?: string | undefined | null,
	id?: string | undefined | null
};
	["Event_Ticketing_type_MutationInput"]:Event_Ticketing_type_MutationInput;
	["Event_Ticketing_ticketingType_MutationInput"]:Event_Ticketing_ticketingType_MutationInput;
	["Event_Ticketing_promotionType_MutationInput"]:Event_Ticketing_promotionType_MutationInput;
	["mutationEvent_CriteriaInput"]: {
	categories?: Array<string | undefined | null> | undefined | null,
	audiences?: Array<string | undefined | null> | undefined | null,
	tags?: Array<string | undefined | null> | undefined | null,
	specialTags?: Array<string | undefined | null> | undefined | null
};
	["mutationEvent_ContactInput"]: {
	code?: number | undefined | null,
	phone?: string | undefined | null,
	page?: string | undefined | null,
	email?: string | undefined | null,
	privacy?: ResolverInputTypes["JSON"] | undefined | null
};
	["mutationEvent_FeesInput"]: {
	fee?: number | undefined | null,
	remark?: string | undefined | null,
	id?: string | undefined | null
};
	["mutationEvent_ProductsInput"]: {
	startDateTime: string,
	endDateTime?: string | undefined | null,
	fullDay?: boolean | undefined | null,
	linkedProduct?: string | undefined | null,
	id?: string | undefined | null
};
	["mutationEventUpdateInput"]: {
	region?: ResolverInputTypes["EventUpdate_region_MutationInput"] | undefined | null,
	creator?: string | undefined | null,
	permalink?: string | undefined | null,
	status?: string | undefined | null,
	level?: ResolverInputTypes["EventUpdate_level_MutationInput"] | undefined | null,
	parent?: Array<string | undefined | null> | undefined | null,
	name?: string | undefined | null,
	chatbotEventTitle?: string | undefined | null,
	chatbotSequence?: number | undefined | null,
	thumbnail?: string | undefined | null,
	banner?: string | undefined | null,
	mobileBanner?: string | undefined | null,
	sections?: Array<ResolverInputTypes["mutationEventUpdate_SectionsInput"] | undefined | null> | undefined | null,
	ticketing?: Array<ResolverInputTypes["mutationEventUpdate_TicketingInput"] | undefined | null> | undefined | null,
	content?: ResolverInputTypes["JSON"] | undefined | null,
	legacyContent?: string | undefined | null,
	showLegacyContent?: boolean | undefined | null,
	criteria: ResolverInputTypes["mutationEventUpdate_CriteriaInput"],
	walkIn?: boolean | undefined | null,
	contact?: ResolverInputTypes["mutationEventUpdate_ContactInput"] | undefined | null,
	views?: number | undefined | null,
	free?: boolean | undefined | null,
	fees?: Array<ResolverInputTypes["mutationEventUpdate_FeesInput"] | undefined | null> | undefined | null,
	homePageInternalClick?: number | undefined | null,
	homePageImpression?: number | undefined | null,
	eodClickRate?: number | undefined | null,
	eodImpressionRate?: number | undefined | null,
	top10ClickRateHomepage?: number | undefined | null,
	top10ImpressionkRateHomepage?: number | undefined | null,
	otherEventRankingClickRateHomepage?: number | undefined | null,
	otherEventRankingImpressionHomePage?: number | undefined | null,
	userPreferenceClickRate?: number | undefined | null,
	userPreferenceImpression?: number | undefined | null,
	eventSearchInternalClick?: number | undefined | null,
	eventListImpression?: number | undefined | null,
	latestEventInternalClick?: number | undefined | null,
	latestEventImpression?: number | undefined | null,
	topTenEventRankingInternalClick?: number | undefined | null,
	topTenImpression?: number | undefined | null,
	editorChoiceInternalClick?: number | undefined | null,
	editorChoiceImpression?: number | undefined | null,
	otherEventRankingInternalClick?: number | undefined | null,
	otherEventRankingImpression?: number | undefined | null,
	bannerInternalClick?: number | undefined | null,
	miscImpression?: number | undefined | null,
	miscInternalClick?: number | undefined | null,
	totalImpression?: number | undefined | null,
	internalClick?: number | undefined | null,
	outboundClick?: number | undefined | null,
	externalClick?: number | undefined | null,
	_viewsCountByMonth?: number | undefined | null,
	products?: Array<ResolverInputTypes["mutationEventUpdate_ProductsInput"] | undefined | null> | undefined | null,
	legacyGallery?: Array<string | undefined | null> | undefined | null,
	earliestStartDate?: string | undefined | null,
	latestEndDate?: string | undefined | null,
	latestStartDateTime?: string | undefined | null,
	hasTicketing?: boolean | undefined | null,
	hasProducts?: boolean | undefined | null,
	updatedAt?: string | undefined | null,
	createdAt?: string | undefined | null
};
	["EventUpdate_region_MutationInput"]:EventUpdate_region_MutationInput;
	["EventUpdate_level_MutationInput"]:EventUpdate_level_MutationInput;
	["mutationEventUpdate_SectionsInput"]: {
	startDatetime: string,
	endDatetime?: string | undefined | null,
	fullDay?: boolean | undefined | null,
	repeat?: boolean | undefined | null,
	toThisDay?: string | undefined | null,
	recurrance: ResolverInputTypes["mutationEventUpdate_Sections_RecurranceInput"],
	sameLocation?: boolean | undefined | null,
	location?: string | undefined | null,
	district?: string | undefined | null,
	coordinate?: Array<number | undefined | null> | undefined | null,
	linkAddress?: boolean | undefined | null,
	address?: string | undefined | null,
	addressCoordinate?: Array<number | undefined | null> | undefined | null,
	id?: string | undefined | null
};
	["mutationEventUpdate_Sections_RecurranceInput"]: {
	type: ResolverInputTypes["EventUpdate_Sections_Recurrance_type_MutationInput"],
	weekday?: Array<ResolverInputTypes["EventUpdate_Sections_Recurrance_weekday_MutationInput"] | undefined | null> | undefined | null
};
	["EventUpdate_Sections_Recurrance_type_MutationInput"]:EventUpdate_Sections_Recurrance_type_MutationInput;
	["EventUpdate_Sections_Recurrance_weekday_MutationInput"]:EventUpdate_Sections_Recurrance_weekday_MutationInput;
	["mutationEventUpdate_TicketingInput"]: {
	startDatetime: string,
	endDatetime?: string | undefined | null,
	fullDay?: boolean | undefined | null,
	type: ResolverInputTypes["EventUpdate_Ticketing_type_MutationInput"],
	ticketingType?: ResolverInputTypes["EventUpdate_Ticketing_ticketingType_MutationInput"] | undefined | null,
	linkedTicketing?: string | undefined | null,
	promotionType?: ResolverInputTypes["EventUpdate_Ticketing_promotionType_MutationInput"] | undefined | null,
	location?: string | undefined | null,
	website?: string | undefined | null,
	description?: string | undefined | null,
	id?: string | undefined | null
};
	["EventUpdate_Ticketing_type_MutationInput"]:EventUpdate_Ticketing_type_MutationInput;
	["EventUpdate_Ticketing_ticketingType_MutationInput"]:EventUpdate_Ticketing_ticketingType_MutationInput;
	["EventUpdate_Ticketing_promotionType_MutationInput"]:EventUpdate_Ticketing_promotionType_MutationInput;
	["mutationEventUpdate_CriteriaInput"]: {
	categories?: Array<string | undefined | null> | undefined | null,
	audiences?: Array<string | undefined | null> | undefined | null,
	tags?: Array<string | undefined | null> | undefined | null,
	specialTags?: Array<string | undefined | null> | undefined | null
};
	["mutationEventUpdate_ContactInput"]: {
	code?: number | undefined | null,
	phone?: string | undefined | null,
	page?: string | undefined | null,
	email?: string | undefined | null,
	privacy?: ResolverInputTypes["JSON"] | undefined | null
};
	["mutationEventUpdate_FeesInput"]: {
	fee?: number | undefined | null,
	remark?: string | undefined | null,
	id?: string | undefined | null
};
	["mutationEventUpdate_ProductsInput"]: {
	startDateTime: string,
	endDateTime?: string | undefined | null,
	fullDay?: boolean | undefined | null,
	linkedProduct?: string | undefined | null,
	id?: string | undefined | null
};
	["mutationLocationInput"]: {
	region?: ResolverInputTypes["Location_region_MutationInput"] | undefined | null,
	creator?: string | undefined | null,
	permalink?: string | undefined | null,
	status: string,
	level?: ResolverInputTypes["Location_level_MutationInput"] | undefined | null,
	parent?: Array<string | undefined | null> | undefined | null,
	name?: string | undefined | null,
	thumbnail?: string | undefined | null,
	banner?: string | undefined | null,
	type: ResolverInputTypes["Location_type_MutationInput"],
	category: ResolverInputTypes["Location_category_MutationInput"],
	district?: string | undefined | null,
	coordinate?: Array<number | undefined | null> | undefined | null,
	linkAddress?: boolean | undefined | null,
	address?: string | undefined | null,
	addressCoordinate?: Array<number | undefined | null> | undefined | null,
	permanant?: boolean | undefined | null,
	Details?: ResolverInputTypes["mutationLocation_DetailsInput"] | undefined | null,
	content?: ResolverInputTypes["JSON"] | undefined | null,
	legacyContent?: string | undefined | null,
	showLegacyContent?: boolean | undefined | null,
	criteria?: ResolverInputTypes["mutationLocation_CriteriaInput"] | undefined | null,
	sections?: Array<ResolverInputTypes["mutationLocation_SectionsInput"] | undefined | null> | undefined | null,
	views?: number | undefined | null,
	_title?: string | undefined | null,
	legacyGallery?: Array<string | undefined | null> | undefined | null,
	updatedAt?: string | undefined | null,
	createdAt?: string | undefined | null
};
	["Location_region_MutationInput"]:Location_region_MutationInput;
	["Location_level_MutationInput"]:Location_level_MutationInput;
	["Location_type_MutationInput"]:Location_type_MutationInput;
	["Location_category_MutationInput"]:Location_category_MutationInput;
	["mutationLocation_DetailsInput"]: {
	code?: number | undefined | null,
	phone?: string | undefined | null,
	page?: string | undefined | null,
	email?: string | undefined | null
};
	["mutationLocation_CriteriaInput"]: {
	audiences?: Array<string | undefined | null> | undefined | null,
	dates?: Array<string | undefined | null> | undefined | null,
	categories?: Array<string | undefined | null> | undefined | null,
	tags?: Array<string | undefined | null> | undefined | null
};
	["mutationLocation_SectionsInput"]: {
	startDatetime?: string | undefined | null,
	endDatetime?: string | undefined | null,
	fullDay?: boolean | undefined | null,
	repeat?: boolean | undefined | null,
	recurrance: ResolverInputTypes["mutationLocation_Sections_RecurranceInput"],
	id?: string | undefined | null
};
	["mutationLocation_Sections_RecurranceInput"]: {
	type: ResolverInputTypes["Location_Sections_Recurrance_type_MutationInput"],
	weekday?: Array<ResolverInputTypes["Location_Sections_Recurrance_weekday_MutationInput"] | undefined | null> | undefined | null
};
	["Location_Sections_Recurrance_type_MutationInput"]:Location_Sections_Recurrance_type_MutationInput;
	["Location_Sections_Recurrance_weekday_MutationInput"]:Location_Sections_Recurrance_weekday_MutationInput;
	["mutationLocationUpdateInput"]: {
	region?: ResolverInputTypes["LocationUpdate_region_MutationInput"] | undefined | null,
	creator?: string | undefined | null,
	permalink?: string | undefined | null,
	status?: string | undefined | null,
	level?: ResolverInputTypes["LocationUpdate_level_MutationInput"] | undefined | null,
	parent?: Array<string | undefined | null> | undefined | null,
	name?: string | undefined | null,
	thumbnail?: string | undefined | null,
	banner?: string | undefined | null,
	type?: ResolverInputTypes["LocationUpdate_type_MutationInput"] | undefined | null,
	category?: ResolverInputTypes["LocationUpdate_category_MutationInput"] | undefined | null,
	district?: string | undefined | null,
	coordinate?: Array<number | undefined | null> | undefined | null,
	linkAddress?: boolean | undefined | null,
	address?: string | undefined | null,
	addressCoordinate?: Array<number | undefined | null> | undefined | null,
	permanant?: boolean | undefined | null,
	Details?: ResolverInputTypes["mutationLocationUpdate_DetailsInput"] | undefined | null,
	content?: ResolverInputTypes["JSON"] | undefined | null,
	legacyContent?: string | undefined | null,
	showLegacyContent?: boolean | undefined | null,
	criteria?: ResolverInputTypes["mutationLocationUpdate_CriteriaInput"] | undefined | null,
	sections?: Array<ResolverInputTypes["mutationLocationUpdate_SectionsInput"] | undefined | null> | undefined | null,
	views?: number | undefined | null,
	_title?: string | undefined | null,
	legacyGallery?: Array<string | undefined | null> | undefined | null,
	updatedAt?: string | undefined | null,
	createdAt?: string | undefined | null
};
	["LocationUpdate_region_MutationInput"]:LocationUpdate_region_MutationInput;
	["LocationUpdate_level_MutationInput"]:LocationUpdate_level_MutationInput;
	["LocationUpdate_type_MutationInput"]:LocationUpdate_type_MutationInput;
	["LocationUpdate_category_MutationInput"]:LocationUpdate_category_MutationInput;
	["mutationLocationUpdate_DetailsInput"]: {
	code?: number | undefined | null,
	phone?: string | undefined | null,
	page?: string | undefined | null,
	email?: string | undefined | null
};
	["mutationLocationUpdate_CriteriaInput"]: {
	audiences?: Array<string | undefined | null> | undefined | null,
	dates?: Array<string | undefined | null> | undefined | null,
	categories?: Array<string | undefined | null> | undefined | null,
	tags?: Array<string | undefined | null> | undefined | null
};
	["mutationLocationUpdate_SectionsInput"]: {
	startDatetime?: string | undefined | null,
	endDatetime?: string | undefined | null,
	fullDay?: boolean | undefined | null,
	repeat?: boolean | undefined | null,
	recurrance: ResolverInputTypes["mutationLocationUpdate_Sections_RecurranceInput"],
	id?: string | undefined | null
};
	["mutationLocationUpdate_Sections_RecurranceInput"]: {
	type: ResolverInputTypes["LocationUpdate_Sections_Recurrance_type_MutationInput"],
	weekday?: Array<ResolverInputTypes["LocationUpdate_Sections_Recurrance_weekday_MutationInput"] | undefined | null> | undefined | null
};
	["LocationUpdate_Sections_Recurrance_type_MutationInput"]:LocationUpdate_Sections_Recurrance_type_MutationInput;
	["LocationUpdate_Sections_Recurrance_weekday_MutationInput"]:LocationUpdate_Sections_Recurrance_weekday_MutationInput;
	["mutationPostInput"]: {
	title?: string | undefined | null,
	region?: ResolverInputTypes["Post_region_MutationInput"] | undefined | null,
	authur?: string | undefined | null,
	publishDateTime?: string | undefined | null,
	permalink?: string | undefined | null,
	status: string,
	content?: ResolverInputTypes["JSON"] | undefined | null,
	legacyContent?: string | undefined | null,
	showLegacyContent?: boolean | undefined | null,
	blogCategories?: Array<string | undefined | null> | undefined | null,
	thumbnail?: string | undefined | null,
	banner?: string | undefined | null,
	criteria?: ResolverInputTypes["mutationPost_CriteriaInput"] | undefined | null,
	location?: string | undefined | null,
	views?: number | undefined | null,
	homePageInternalClick?: number | undefined | null,
	homePageImpression?: number | undefined | null,
	blogListInternalClick?: number | undefined | null,
	blogListImpression?: number | undefined | null,
	blogDetailInternalClick?: number | undefined | null,
	blogDetailImpression?: number | undefined | null,
	relatedEventInternalClick?: number | undefined | null,
	relatedEventImpression?: number | undefined | null,
	latestEventInternalClick?: number | undefined | null,
	latestEventImpression?: number | undefined | null,
	internalClick?: number | undefined | null,
	totalImpression?: number | undefined | null,
	externalClick?: number | undefined | null,
	outboundClick?: number | undefined | null,
	legacyGallery?: Array<string | undefined | null> | undefined | null,
	updatedAt?: string | undefined | null,
	createdAt?: string | undefined | null
};
	["Post_region_MutationInput"]:Post_region_MutationInput;
	["mutationPost_CriteriaInput"]: {
	audiences?: Array<string | undefined | null> | undefined | null,
	dates?: Array<string | undefined | null> | undefined | null,
	categories?: Array<string | undefined | null> | undefined | null,
	tags?: Array<string | undefined | null> | undefined | null
};
	["mutationPostUpdateInput"]: {
	title?: string | undefined | null,
	region?: ResolverInputTypes["PostUpdate_region_MutationInput"] | undefined | null,
	authur?: string | undefined | null,
	publishDateTime?: string | undefined | null,
	permalink?: string | undefined | null,
	status?: string | undefined | null,
	content?: ResolverInputTypes["JSON"] | undefined | null,
	legacyContent?: string | undefined | null,
	showLegacyContent?: boolean | undefined | null,
	blogCategories?: Array<string | undefined | null> | undefined | null,
	thumbnail?: string | undefined | null,
	banner?: string | undefined | null,
	criteria?: ResolverInputTypes["mutationPostUpdate_CriteriaInput"] | undefined | null,
	location?: string | undefined | null,
	views?: number | undefined | null,
	homePageInternalClick?: number | undefined | null,
	homePageImpression?: number | undefined | null,
	blogListInternalClick?: number | undefined | null,
	blogListImpression?: number | undefined | null,
	blogDetailInternalClick?: number | undefined | null,
	blogDetailImpression?: number | undefined | null,
	relatedEventInternalClick?: number | undefined | null,
	relatedEventImpression?: number | undefined | null,
	latestEventInternalClick?: number | undefined | null,
	latestEventImpression?: number | undefined | null,
	internalClick?: number | undefined | null,
	totalImpression?: number | undefined | null,
	externalClick?: number | undefined | null,
	outboundClick?: number | undefined | null,
	legacyGallery?: Array<string | undefined | null> | undefined | null,
	updatedAt?: string | undefined | null,
	createdAt?: string | undefined | null
};
	["PostUpdate_region_MutationInput"]:PostUpdate_region_MutationInput;
	["mutationPostUpdate_CriteriaInput"]: {
	audiences?: Array<string | undefined | null> | undefined | null,
	dates?: Array<string | undefined | null> | undefined | null,
	categories?: Array<string | undefined | null> | undefined | null,
	tags?: Array<string | undefined | null> | undefined | null
};
	["mutationTicketingInput"]: {
	status?: ResolverInputTypes["Ticketing_status_MutationInput"] | undefined | null,
	creator?: string | undefined | null,
	productType: string,
	productName?: string | undefined | null,
	referenceId?: string | undefined | null,
	linkedEvent?: string | undefined | null,
	CheckInLevel: ResolverInputTypes["Ticketing_CheckInLevel_MutationInput"],
	displayPrice: string,
	tnc?: string | undefined | null,
	cutoffHours: number,
	sessions?: Array<ResolverInputTypes["mutationTicketing_SessionsInput"] | undefined | null> | undefined | null,
	updatedAt?: string | undefined | null,
	createdAt?: string | undefined | null
};
	["Ticketing_status_MutationInput"]:Ticketing_status_MutationInput;
	["Ticketing_CheckInLevel_MutationInput"]:Ticketing_CheckInLevel_MutationInput;
	["mutationTicketing_SessionsInput"]: {
	variantId?: string | undefined | null,
	productId?: string | undefined | null,
	startDate: string,
	endDate: string,
	inventories: ResolverInputTypes["mutationTicketing_Sessions_InventoriesInput"],
	id?: string | undefined | null
};
	["mutationTicketing_Sessions_InventoriesInput"]: {
	name?: string | undefined | null,
	quantity: number,
	unitPrice: number,
	handlingFee: number,
	totalCost?: number | undefined | null,
	minPurchase: number,
	maxPurchase: number,
	stocks?: ResolverInputTypes["mutationTicketing_Sessions_Inventories_StocksInput"] | undefined | null,
	remarks?: string | undefined | null
};
	["mutationTicketing_Sessions_Inventories_StocksInput"]: {
	stockId?: string | undefined | null,
	remaining?: number | undefined | null,
	sold?: number | undefined | null,
	onHold?: number | undefined | null,
	checkedIn?: number | undefined | null
};
	["mutationTicketingUpdateInput"]: {
	status?: ResolverInputTypes["TicketingUpdate_status_MutationInput"] | undefined | null,
	creator?: string | undefined | null,
	productType?: string | undefined | null,
	productName?: string | undefined | null,
	referenceId?: string | undefined | null,
	linkedEvent?: string | undefined | null,
	CheckInLevel?: ResolverInputTypes["TicketingUpdate_CheckInLevel_MutationInput"] | undefined | null,
	displayPrice?: string | undefined | null,
	tnc?: string | undefined | null,
	cutoffHours?: number | undefined | null,
	sessions?: Array<ResolverInputTypes["mutationTicketingUpdate_SessionsInput"] | undefined | null> | undefined | null,
	updatedAt?: string | undefined | null,
	createdAt?: string | undefined | null
};
	["TicketingUpdate_status_MutationInput"]:TicketingUpdate_status_MutationInput;
	["TicketingUpdate_CheckInLevel_MutationInput"]:TicketingUpdate_CheckInLevel_MutationInput;
	["mutationTicketingUpdate_SessionsInput"]: {
	variantId?: string | undefined | null,
	productId?: string | undefined | null,
	startDate: string,
	endDate: string,
	inventories: ResolverInputTypes["mutationTicketingUpdate_Sessions_InventoriesInput"],
	id?: string | undefined | null
};
	["mutationTicketingUpdate_Sessions_InventoriesInput"]: {
	name?: string | undefined | null,
	quantity: number,
	unitPrice: number,
	handlingFee: number,
	totalCost?: number | undefined | null,
	minPurchase: number,
	maxPurchase: number,
	stocks?: ResolverInputTypes["mutationTicketingUpdate_Sessions_Inventories_StocksInput"] | undefined | null,
	remarks?: string | undefined | null
};
	["mutationTicketingUpdate_Sessions_Inventories_StocksInput"]: {
	stockId?: string | undefined | null,
	remaining?: number | undefined | null,
	sold?: number | undefined | null,
	onHold?: number | undefined | null,
	checkedIn?: number | undefined | null
};
	["mutationProductInput"]: {
	status?: ResolverInputTypes["Product_status_MutationInput"] | undefined | null,
	creator?: string | undefined | null,
	productType: string,
	productName?: string | undefined | null,
	referenceId?: string | undefined | null,
	linkedEvent?: string | undefined | null,
	CheckInLevel: ResolverInputTypes["Product_CheckInLevel_MutationInput"],
	thumbnail: string,
	displayPrice: string,
	transactionType: string,
	website?: string | undefined | null,
	tnc?: string | undefined | null,
	remarks?: string | undefined | null,
	sessions?: Array<ResolverInputTypes["mutationProduct_SessionsInput"] | undefined | null> | undefined | null,
	updatedAt?: string | undefined | null,
	createdAt?: string | undefined | null
};
	["Product_status_MutationInput"]:Product_status_MutationInput;
	["Product_CheckInLevel_MutationInput"]:Product_CheckInLevel_MutationInput;
	["mutationProduct_SessionsInput"]: {
	variantId?: string | undefined | null,
	productId?: string | undefined | null,
	name?: string | undefined | null,
	inventories?: ResolverInputTypes["mutationProduct_Sessions_InventoriesInput"] | undefined | null,
	id?: string | undefined | null
};
	["mutationProduct_Sessions_InventoriesInput"]: {
	quantity: number,
	unitPrice: number,
	handlingFee: number,
	totalCost?: number | undefined | null,
	minPurchase: number,
	maxPurchase: number,
	stocks?: ResolverInputTypes["mutationProduct_Sessions_Inventories_StocksInput"] | undefined | null
};
	["mutationProduct_Sessions_Inventories_StocksInput"]: {
	stockId?: string | undefined | null,
	remaining?: number | undefined | null,
	sold?: number | undefined | null,
	onHold?: number | undefined | null,
	checkedIn?: number | undefined | null
};
	["mutationProductUpdateInput"]: {
	status?: ResolverInputTypes["ProductUpdate_status_MutationInput"] | undefined | null,
	creator?: string | undefined | null,
	productType?: string | undefined | null,
	productName?: string | undefined | null,
	referenceId?: string | undefined | null,
	linkedEvent?: string | undefined | null,
	CheckInLevel?: ResolverInputTypes["ProductUpdate_CheckInLevel_MutationInput"] | undefined | null,
	thumbnail?: string | undefined | null,
	displayPrice?: string | undefined | null,
	transactionType?: string | undefined | null,
	website?: string | undefined | null,
	tnc?: string | undefined | null,
	remarks?: string | undefined | null,
	sessions?: Array<ResolverInputTypes["mutationProductUpdate_SessionsInput"] | undefined | null> | undefined | null,
	updatedAt?: string | undefined | null,
	createdAt?: string | undefined | null
};
	["ProductUpdate_status_MutationInput"]:ProductUpdate_status_MutationInput;
	["ProductUpdate_CheckInLevel_MutationInput"]:ProductUpdate_CheckInLevel_MutationInput;
	["mutationProductUpdate_SessionsInput"]: {
	variantId?: string | undefined | null,
	productId?: string | undefined | null,
	name?: string | undefined | null,
	inventories?: ResolverInputTypes["mutationProductUpdate_Sessions_InventoriesInput"] | undefined | null,
	id?: string | undefined | null
};
	["mutationProductUpdate_Sessions_InventoriesInput"]: {
	quantity: number,
	unitPrice: number,
	handlingFee: number,
	totalCost?: number | undefined | null,
	minPurchase: number,
	maxPurchase: number,
	stocks?: ResolverInputTypes["mutationProductUpdate_Sessions_Inventories_StocksInput"] | undefined | null
};
	["mutationProductUpdate_Sessions_Inventories_StocksInput"]: {
	stockId?: string | undefined | null,
	remaining?: number | undefined | null,
	sold?: number | undefined | null,
	onHold?: number | undefined | null,
	checkedIn?: number | undefined | null
};
	["mutationInventoryStatusInput"]: {
	variantId?: string | undefined | null,
	variantName?: string | undefined | null,
	productType?: string | undefined | null,
	stockUpdateStatus: ResolverInputTypes["InventoryStatus_stockUpdateStatus_MutationInput"],
	stockUpdateAmount: number,
	user?: string | undefined | null,
	publicUserPhone?: string | undefined | null,
	expiredAt?: string | undefined | null,
	updatedAt?: string | undefined | null,
	createdAt?: string | undefined | null
};
	["InventoryStatus_stockUpdateStatus_MutationInput"]:InventoryStatus_stockUpdateStatus_MutationInput;
	["mutationInventoryStatusUpdateInput"]: {
	variantId?: string | undefined | null,
	variantName?: string | undefined | null,
	productType?: string | undefined | null,
	stockUpdateStatus?: ResolverInputTypes["InventoryStatusUpdate_stockUpdateStatus_MutationInput"] | undefined | null,
	stockUpdateAmount?: number | undefined | null,
	user?: string | undefined | null,
	publicUserPhone?: string | undefined | null,
	expiredAt?: string | undefined | null,
	updatedAt?: string | undefined | null,
	createdAt?: string | undefined | null
};
	["InventoryStatusUpdate_stockUpdateStatus_MutationInput"]:InventoryStatusUpdate_stockUpdateStatus_MutationInput;
	["mutationTransactionInput"]: {
	creator?: string | undefined | null,
	productName?: string | undefined | null,
	referenceId?: string | undefined | null,
	linkedEvent?: string | undefined | null,
	purchaseDateTime?: string | undefined | null,
	timableOrderId?: string | undefined | null,
	stripePaymentId?: string | undefined | null,
	totalAmount?: number | undefined | null,
	contactPersonName?: string | undefined | null,
	contactPersonPhone?: string | undefined | null,
	remarks?: string | undefined | null,
	redemptionCodes?: Array<ResolverInputTypes["mutationTransaction_RedemptionCodesInput"] | undefined | null> | undefined | null,
	updatedAt?: string | undefined | null,
	createdAt?: string | undefined | null
};
	["mutationTransaction_RedemptionCodesInput"]: {
	code?: string | undefined | null,
	id?: string | undefined | null
};
	["mutationTransactionUpdateInput"]: {
	creator?: string | undefined | null,
	productName?: string | undefined | null,
	referenceId?: string | undefined | null,
	linkedEvent?: string | undefined | null,
	purchaseDateTime?: string | undefined | null,
	timableOrderId?: string | undefined | null,
	stripePaymentId?: string | undefined | null,
	totalAmount?: number | undefined | null,
	contactPersonName?: string | undefined | null,
	contactPersonPhone?: string | undefined | null,
	remarks?: string | undefined | null,
	redemptionCodes?: Array<ResolverInputTypes["mutationTransactionUpdate_RedemptionCodesInput"] | undefined | null> | undefined | null,
	updatedAt?: string | undefined | null,
	createdAt?: string | undefined | null
};
	["mutationTransactionUpdate_RedemptionCodesInput"]: {
	code?: string | undefined | null,
	id?: string | undefined | null
};
	["mutationRedemptionCodeInput"]: {
	creator?: string | undefined | null,
	status: ResolverInputTypes["RedemptionCode_status_MutationInput"],
	qrCodeId?: string | undefined | null,
	lastCheckedInDateTime?: string | undefined | null,
	voidedDateTime?: string | undefined | null,
	refundedDateTime?: string | undefined | null,
	eventStartTime?: string | undefined | null,
	eventEndTime?: string | undefined | null,
	eventPriceZone?: string | undefined | null,
	ownerName: string,
	phone: string,
	pdf?: string | undefined | null,
	variants?: Array<ResolverInputTypes["mutationRedemptionCode_VariantsInput"] | undefined | null> | undefined | null,
	productId?: string | undefined | null,
	referenceId?: string | undefined | null,
	productType?: string | undefined | null,
	productName?: string | undefined | null,
	linkedEvent?: string | undefined | null,
	timableOrderId?: string | undefined | null,
	purchaseDateTime?: string | undefined | null,
	changeHistory?: Array<ResolverInputTypes["mutationRedemptionCode_ChangeHistoryInput"] | undefined | null> | undefined | null,
	updatedAt?: string | undefined | null,
	createdAt?: string | undefined | null
};
	["RedemptionCode_status_MutationInput"]:RedemptionCode_status_MutationInput;
	["mutationRedemptionCode_VariantsInput"]: {
	variantId?: string | undefined | null,
	name?: string | undefined | null,
	quantity?: string | undefined | null,
	totalCost?: string | undefined | null,
	id?: string | undefined | null
};
	["mutationRedemptionCode_ChangeHistoryInput"]: {
	timestamp: string,
	field: string,
	oldValue: string,
	newValue: string,
	id?: string | undefined | null
};
	["mutationRedemptionCodeUpdateInput"]: {
	creator?: string | undefined | null,
	status?: ResolverInputTypes["RedemptionCodeUpdate_status_MutationInput"] | undefined | null,
	qrCodeId?: string | undefined | null,
	lastCheckedInDateTime?: string | undefined | null,
	voidedDateTime?: string | undefined | null,
	refundedDateTime?: string | undefined | null,
	eventStartTime?: string | undefined | null,
	eventEndTime?: string | undefined | null,
	eventPriceZone?: string | undefined | null,
	ownerName?: string | undefined | null,
	phone?: string | undefined | null,
	pdf?: string | undefined | null,
	variants?: Array<ResolverInputTypes["mutationRedemptionCodeUpdate_VariantsInput"] | undefined | null> | undefined | null,
	productId?: string | undefined | null,
	referenceId?: string | undefined | null,
	productType?: string | undefined | null,
	productName?: string | undefined | null,
	linkedEvent?: string | undefined | null,
	timableOrderId?: string | undefined | null,
	purchaseDateTime?: string | undefined | null,
	changeHistory?: Array<ResolverInputTypes["mutationRedemptionCodeUpdate_ChangeHistoryInput"] | undefined | null> | undefined | null,
	updatedAt?: string | undefined | null,
	createdAt?: string | undefined | null
};
	["RedemptionCodeUpdate_status_MutationInput"]:RedemptionCodeUpdate_status_MutationInput;
	["mutationRedemptionCodeUpdate_VariantsInput"]: {
	variantId?: string | undefined | null,
	name?: string | undefined | null,
	quantity?: string | undefined | null,
	totalCost?: string | undefined | null,
	id?: string | undefined | null
};
	["mutationRedemptionCodeUpdate_ChangeHistoryInput"]: {
	timestamp: string,
	field: string,
	oldValue: string,
	newValue: string,
	id?: string | undefined | null
};
	["mutationRedemptionPdfInput"]: {
	updatedAt?: string | undefined | null,
	createdAt?: string | undefined | null,
	url?: string | undefined | null,
	filename?: string | undefined | null,
	mimeType?: string | undefined | null,
	filesize?: number | undefined | null,
	width?: number | undefined | null,
	height?: number | undefined | null,
	focalX?: number | undefined | null,
	focalY?: number | undefined | null
};
	["mutationRedemptionPdfUpdateInput"]: {
	updatedAt?: string | undefined | null,
	createdAt?: string | undefined | null,
	url?: string | undefined | null,
	filename?: string | undefined | null,
	mimeType?: string | undefined | null,
	filesize?: number | undefined | null,
	width?: number | undefined | null,
	height?: number | undefined | null,
	focalX?: number | undefined | null,
	focalY?: number | undefined | null
};
	["mutationCheckInRedemptionInput"]: {
	creator?: string | undefined | null,
	qrCodeId?: string | undefined | null,
	codeownerName?: string | undefined | null,
	name?: string | undefined | null,
	phone?: string | undefined | null,
	linkedEvent?: string | undefined | null,
	entryNumber?: string | undefined | null,
	scanAt?: string | undefined | null,
	uploadAt?: string | undefined | null,
	status?: string | undefined | null,
	failReason?: string | undefined | null,
	variants?: Array<ResolverInputTypes["mutationCheckInRedemption_VariantsInput"] | undefined | null> | undefined | null,
	checkInAppSelectionId?: string | undefined | null,
	qrCodeStatus?: string | undefined | null,
	productType?: string | undefined | null,
	updatedAt?: string | undefined | null,
	createdAt?: string | undefined | null
};
	["mutationCheckInRedemption_VariantsInput"]: {
	variantId?: string | undefined | null,
	name?: string | undefined | null,
	quantity?: string | undefined | null,
	totalCost?: string | undefined | null,
	id?: string | undefined | null
};
	["mutationCheckInRedemptionUpdateInput"]: {
	creator?: string | undefined | null,
	qrCodeId?: string | undefined | null,
	codeownerName?: string | undefined | null,
	name?: string | undefined | null,
	phone?: string | undefined | null,
	linkedEvent?: string | undefined | null,
	entryNumber?: string | undefined | null,
	scanAt?: string | undefined | null,
	uploadAt?: string | undefined | null,
	status?: string | undefined | null,
	failReason?: string | undefined | null,
	variants?: Array<ResolverInputTypes["mutationCheckInRedemptionUpdate_VariantsInput"] | undefined | null> | undefined | null,
	checkInAppSelectionId?: string | undefined | null,
	qrCodeStatus?: string | undefined | null,
	productType?: string | undefined | null,
	updatedAt?: string | undefined | null,
	createdAt?: string | undefined | null
};
	["mutationCheckInRedemptionUpdate_VariantsInput"]: {
	variantId?: string | undefined | null,
	name?: string | undefined | null,
	quantity?: string | undefined | null,
	totalCost?: string | undefined | null,
	id?: string | undefined | null
};
	["mutationUserInput"]: {
	region?: ResolverInputTypes["User_region_MutationInput"] | undefined | null,
	name?: string | undefined | null,
	displayEmail?: string | undefined | null,
	legacyUsername?: string | undefined | null,
	roles: Array<ResolverInputTypes["User_roles_MutationInput"] | undefined | null>,
	member?: ResolverInputTypes["mutationUser_MemberInput"] | undefined | null,
	organiser?: ResolverInputTypes["mutationUser_OrganiserInput"] | undefined | null,
	emailVerified?: boolean | undefined | null,
	sso?: ResolverInputTypes["mutationUser_SsoInput"] | undefined | null,
	oldPassword?: string | undefined | null,
	status?: ResolverInputTypes["User_status_MutationInput"] | undefined | null,
	updatedAt?: string | undefined | null,
	createdAt?: string | undefined | null,
	email: string,
	resetPasswordToken?: string | undefined | null,
	resetPasswordExpiration?: string | undefined | null,
	salt?: string | undefined | null,
	hash?: string | undefined | null,
	loginAttempts?: number | undefined | null,
	lockUntil?: string | undefined | null,
	password: string
};
	["User_region_MutationInput"]:User_region_MutationInput;
	["User_roles_MutationInput"]:User_roles_MutationInput;
	["mutationUser_MemberInput"]: {
	region?: ResolverInputTypes["User_Member_region_MutationInput"] | undefined | null,
	language?: ResolverInputTypes["User_Member_language_MutationInput"] | undefined | null,
	phone?: string | undefined | null,
	avatar?: string | undefined | null,
	birthday?: string | undefined | null,
	gender?: ResolverInputTypes["User_Member_gender_MutationInput"] | undefined | null,
	categories?: Array<string | undefined | null> | undefined | null
};
	["User_Member_region_MutationInput"]:User_Member_region_MutationInput;
	["User_Member_language_MutationInput"]:User_Member_language_MutationInput;
	["User_Member_gender_MutationInput"]:User_Member_gender_MutationInput;
	["mutationUser_OrganiserInput"]: {
	logo?: string | undefined | null,
	banner?: string | undefined | null,
	description?: string | undefined | null,
	foundedIn?: string | undefined | null,
	address?: string | undefined | null,
	website?: string | undefined | null,
	facebook?: string | undefined | null,
	instagram?: string | undefined | null,
	language?: ResolverInputTypes["User_Organiser_language_MutationInput"] | undefined | null,
	phone?: string | undefined | null,
	followers?: number | undefined | null
};
	["User_Organiser_language_MutationInput"]:User_Organiser_language_MutationInput;
	["mutationUser_SsoInput"]: {
	googleId?: string | undefined | null,
	facebookId?: string | undefined | null,
	appleId?: string | undefined | null
};
	["User_status_MutationInput"]:User_status_MutationInput;
	["mutationUserUpdateInput"]: {
	region?: ResolverInputTypes["UserUpdate_region_MutationInput"] | undefined | null,
	name?: string | undefined | null,
	displayEmail?: string | undefined | null,
	legacyUsername?: string | undefined | null,
	roles?: Array<ResolverInputTypes["UserUpdate_roles_MutationInput"] | undefined | null> | undefined | null,
	member?: ResolverInputTypes["mutationUserUpdate_MemberInput"] | undefined | null,
	organiser?: ResolverInputTypes["mutationUserUpdate_OrganiserInput"] | undefined | null,
	emailVerified?: boolean | undefined | null,
	sso?: ResolverInputTypes["mutationUserUpdate_SsoInput"] | undefined | null,
	oldPassword?: string | undefined | null,
	status?: ResolverInputTypes["UserUpdate_status_MutationInput"] | undefined | null,
	updatedAt?: string | undefined | null,
	createdAt?: string | undefined | null,
	email?: string | undefined | null,
	resetPasswordToken?: string | undefined | null,
	resetPasswordExpiration?: string | undefined | null,
	salt?: string | undefined | null,
	hash?: string | undefined | null,
	loginAttempts?: number | undefined | null,
	lockUntil?: string | undefined | null,
	password?: string | undefined | null
};
	["UserUpdate_region_MutationInput"]:UserUpdate_region_MutationInput;
	["UserUpdate_roles_MutationInput"]:UserUpdate_roles_MutationInput;
	["mutationUserUpdate_MemberInput"]: {
	region?: ResolverInputTypes["UserUpdate_Member_region_MutationInput"] | undefined | null,
	language?: ResolverInputTypes["UserUpdate_Member_language_MutationInput"] | undefined | null,
	phone?: string | undefined | null,
	avatar?: string | undefined | null,
	birthday?: string | undefined | null,
	gender?: ResolverInputTypes["UserUpdate_Member_gender_MutationInput"] | undefined | null,
	categories?: Array<string | undefined | null> | undefined | null
};
	["UserUpdate_Member_region_MutationInput"]:UserUpdate_Member_region_MutationInput;
	["UserUpdate_Member_language_MutationInput"]:UserUpdate_Member_language_MutationInput;
	["UserUpdate_Member_gender_MutationInput"]:UserUpdate_Member_gender_MutationInput;
	["mutationUserUpdate_OrganiserInput"]: {
	logo?: string | undefined | null,
	banner?: string | undefined | null,
	description?: string | undefined | null,
	foundedIn?: string | undefined | null,
	address?: string | undefined | null,
	website?: string | undefined | null,
	facebook?: string | undefined | null,
	instagram?: string | undefined | null,
	language?: ResolverInputTypes["UserUpdate_Organiser_language_MutationInput"] | undefined | null,
	phone?: string | undefined | null,
	followers?: number | undefined | null
};
	["UserUpdate_Organiser_language_MutationInput"]:UserUpdate_Organiser_language_MutationInput;
	["mutationUserUpdate_SsoInput"]: {
	googleId?: string | undefined | null,
	facebookId?: string | undefined | null,
	appleId?: string | undefined | null
};
	["UserUpdate_status_MutationInput"]:UserUpdate_status_MutationInput;
	["usersRefreshedUser"]: AliasType<{
	exp?:boolean | `@${string}`,
	refreshedToken?:boolean | `@${string}`,
	strategy?:boolean | `@${string}`,
	user?:ResolverInputTypes["usersJWT"],
		__typename?: boolean | `@${string}`
}>;
	["usersJWT"]: AliasType<{
	name?:boolean | `@${string}`,
	email?:boolean | `@${string}`,
	collection?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["usersLoginResult"]: AliasType<{
	exp?:boolean | `@${string}`,
	token?:boolean | `@${string}`,
	user?:ResolverInputTypes["User"],
		__typename?: boolean | `@${string}`
}>;
	["usersResetPassword"]: AliasType<{
	token?:boolean | `@${string}`,
	user?:ResolverInputTypes["User"],
		__typename?: boolean | `@${string}`
}>;
	["mutationBookmarkInput"]: {
	user?: ResolverInputTypes["Bookmark_UserRelationshipInput"] | undefined | null,
	item?: ResolverInputTypes["Bookmark_ItemRelationshipInput"] | undefined | null,
	updatedAt?: string | undefined | null,
	createdAt?: string | undefined | null
};
	["Bookmark_UserRelationshipInput"]: {
	relationTo?: ResolverInputTypes["Bookmark_UserRelationshipInputRelationTo"] | undefined | null,
	value?: ResolverInputTypes["JSON"] | undefined | null
};
	["Bookmark_UserRelationshipInputRelationTo"]:Bookmark_UserRelationshipInputRelationTo;
	["Bookmark_ItemRelationshipInput"]: {
	relationTo?: ResolverInputTypes["Bookmark_ItemRelationshipInputRelationTo"] | undefined | null,
	value?: ResolverInputTypes["JSON"] | undefined | null
};
	["Bookmark_ItemRelationshipInputRelationTo"]:Bookmark_ItemRelationshipInputRelationTo;
	["mutationBookmarkUpdateInput"]: {
	user?: ResolverInputTypes["BookmarkUpdate_UserRelationshipInput"] | undefined | null,
	item?: ResolverInputTypes["BookmarkUpdate_ItemRelationshipInput"] | undefined | null,
	updatedAt?: string | undefined | null,
	createdAt?: string | undefined | null
};
	["BookmarkUpdate_UserRelationshipInput"]: {
	relationTo?: ResolverInputTypes["BookmarkUpdate_UserRelationshipInputRelationTo"] | undefined | null,
	value?: ResolverInputTypes["JSON"] | undefined | null
};
	["BookmarkUpdate_UserRelationshipInputRelationTo"]:BookmarkUpdate_UserRelationshipInputRelationTo;
	["BookmarkUpdate_ItemRelationshipInput"]: {
	relationTo?: ResolverInputTypes["BookmarkUpdate_ItemRelationshipInputRelationTo"] | undefined | null,
	value?: ResolverInputTypes["JSON"] | undefined | null
};
	["BookmarkUpdate_ItemRelationshipInputRelationTo"]:BookmarkUpdate_ItemRelationshipInputRelationTo;
	["mutationWebAppBookmarkInput"]: {
	user?: ResolverInputTypes["WebAppBookmark_UserRelationshipInput"] | undefined | null,
	event?: ResolverInputTypes["WebAppBookmark_EventRelationshipInput"] | undefined | null,
	productType?: ResolverInputTypes["WebAppBookmark_productType_MutationInput"] | undefined | null,
	item?: string | undefined | null,
	entryNumber?: string | undefined | null,
	updatedAt?: string | undefined | null,
	createdAt?: string | undefined | null
};
	["WebAppBookmark_UserRelationshipInput"]: {
	relationTo?: ResolverInputTypes["WebAppBookmark_UserRelationshipInputRelationTo"] | undefined | null,
	value?: ResolverInputTypes["JSON"] | undefined | null
};
	["WebAppBookmark_UserRelationshipInputRelationTo"]:WebAppBookmark_UserRelationshipInputRelationTo;
	["WebAppBookmark_EventRelationshipInput"]: {
	relationTo?: ResolverInputTypes["WebAppBookmark_EventRelationshipInputRelationTo"] | undefined | null,
	value?: ResolverInputTypes["JSON"] | undefined | null
};
	["WebAppBookmark_EventRelationshipInputRelationTo"]:WebAppBookmark_EventRelationshipInputRelationTo;
	["WebAppBookmark_productType_MutationInput"]:WebAppBookmark_productType_MutationInput;
	["mutationWebAppBookmarkUpdateInput"]: {
	user?: ResolverInputTypes["WebAppBookmarkUpdate_UserRelationshipInput"] | undefined | null,
	event?: ResolverInputTypes["WebAppBookmarkUpdate_EventRelationshipInput"] | undefined | null,
	productType?: ResolverInputTypes["WebAppBookmarkUpdate_productType_MutationInput"] | undefined | null,
	item?: string | undefined | null,
	entryNumber?: string | undefined | null,
	updatedAt?: string | undefined | null,
	createdAt?: string | undefined | null
};
	["WebAppBookmarkUpdate_UserRelationshipInput"]: {
	relationTo?: ResolverInputTypes["WebAppBookmarkUpdate_UserRelationshipInputRelationTo"] | undefined | null,
	value?: ResolverInputTypes["JSON"] | undefined | null
};
	["WebAppBookmarkUpdate_UserRelationshipInputRelationTo"]:WebAppBookmarkUpdate_UserRelationshipInputRelationTo;
	["WebAppBookmarkUpdate_EventRelationshipInput"]: {
	relationTo?: ResolverInputTypes["WebAppBookmarkUpdate_EventRelationshipInputRelationTo"] | undefined | null,
	value?: ResolverInputTypes["JSON"] | undefined | null
};
	["WebAppBookmarkUpdate_EventRelationshipInputRelationTo"]:WebAppBookmarkUpdate_EventRelationshipInputRelationTo;
	["WebAppBookmarkUpdate_productType_MutationInput"]:WebAppBookmarkUpdate_productType_MutationInput;
	["mutationViewRecordInput"]: {
	user?: ResolverInputTypes["ViewRecord_UserRelationshipInput"] | undefined | null,
	item?: ResolverInputTypes["ViewRecord_ItemRelationshipInput"] | undefined | null,
	updatedAt?: string | undefined | null,
	createdAt?: string | undefined | null
};
	["ViewRecord_UserRelationshipInput"]: {
	relationTo?: ResolverInputTypes["ViewRecord_UserRelationshipInputRelationTo"] | undefined | null,
	value?: ResolverInputTypes["JSON"] | undefined | null
};
	["ViewRecord_UserRelationshipInputRelationTo"]:ViewRecord_UserRelationshipInputRelationTo;
	["ViewRecord_ItemRelationshipInput"]: {
	relationTo?: ResolverInputTypes["ViewRecord_ItemRelationshipInputRelationTo"] | undefined | null,
	value?: ResolverInputTypes["JSON"] | undefined | null
};
	["ViewRecord_ItemRelationshipInputRelationTo"]:ViewRecord_ItemRelationshipInputRelationTo;
	["mutationViewRecordUpdateInput"]: {
	user?: ResolverInputTypes["ViewRecordUpdate_UserRelationshipInput"] | undefined | null,
	item?: ResolverInputTypes["ViewRecordUpdate_ItemRelationshipInput"] | undefined | null,
	updatedAt?: string | undefined | null,
	createdAt?: string | undefined | null
};
	["ViewRecordUpdate_UserRelationshipInput"]: {
	relationTo?: ResolverInputTypes["ViewRecordUpdate_UserRelationshipInputRelationTo"] | undefined | null,
	value?: ResolverInputTypes["JSON"] | undefined | null
};
	["ViewRecordUpdate_UserRelationshipInputRelationTo"]:ViewRecordUpdate_UserRelationshipInputRelationTo;
	["ViewRecordUpdate_ItemRelationshipInput"]: {
	relationTo?: ResolverInputTypes["ViewRecordUpdate_ItemRelationshipInputRelationTo"] | undefined | null,
	value?: ResolverInputTypes["JSON"] | undefined | null
};
	["ViewRecordUpdate_ItemRelationshipInputRelationTo"]:ViewRecordUpdate_ItemRelationshipInputRelationTo;
	["mutationCommentInput"]: {
	commenter?: string | undefined | null,
	content?: string | undefined | null,
	image?: string | undefined | null,
	target?: ResolverInputTypes["Comment_TargetRelationshipInput"] | undefined | null,
	parent?: ResolverInputTypes["Comment_ParentRelationshipInput"] | undefined | null,
	isReviewed?: boolean | undefined | null,
	reviewedBy?: string | undefined | null,
	banned?: boolean | undefined | null,
	type?: string | undefined | null,
	updatedAt?: string | undefined | null,
	createdAt?: string | undefined | null
};
	["Comment_TargetRelationshipInput"]: {
	relationTo?: ResolverInputTypes["Comment_TargetRelationshipInputRelationTo"] | undefined | null,
	value?: ResolverInputTypes["JSON"] | undefined | null
};
	["Comment_TargetRelationshipInputRelationTo"]:Comment_TargetRelationshipInputRelationTo;
	["Comment_ParentRelationshipInput"]: {
	relationTo?: ResolverInputTypes["Comment_ParentRelationshipInputRelationTo"] | undefined | null,
	value?: ResolverInputTypes["JSON"] | undefined | null
};
	["Comment_ParentRelationshipInputRelationTo"]:Comment_ParentRelationshipInputRelationTo;
	["mutationCommentUpdateInput"]: {
	commenter?: string | undefined | null,
	content?: string | undefined | null,
	image?: string | undefined | null,
	target?: ResolverInputTypes["CommentUpdate_TargetRelationshipInput"] | undefined | null,
	parent?: ResolverInputTypes["CommentUpdate_ParentRelationshipInput"] | undefined | null,
	isReviewed?: boolean | undefined | null,
	reviewedBy?: string | undefined | null,
	banned?: boolean | undefined | null,
	type?: string | undefined | null,
	updatedAt?: string | undefined | null,
	createdAt?: string | undefined | null
};
	["CommentUpdate_TargetRelationshipInput"]: {
	relationTo?: ResolverInputTypes["CommentUpdate_TargetRelationshipInputRelationTo"] | undefined | null,
	value?: ResolverInputTypes["JSON"] | undefined | null
};
	["CommentUpdate_TargetRelationshipInputRelationTo"]:CommentUpdate_TargetRelationshipInputRelationTo;
	["CommentUpdate_ParentRelationshipInput"]: {
	relationTo?: ResolverInputTypes["CommentUpdate_ParentRelationshipInputRelationTo"] | undefined | null,
	value?: ResolverInputTypes["JSON"] | undefined | null
};
	["CommentUpdate_ParentRelationshipInputRelationTo"]:CommentUpdate_ParentRelationshipInputRelationTo;
	["mutationSubscriptionInput"]: {
	user?: ResolverInputTypes["Subscription_UserRelationshipInput"] | undefined | null,
	organiser?: ResolverInputTypes["Subscription_OrganiserRelationshipInput"] | undefined | null,
	updatedAt?: string | undefined | null,
	createdAt?: string | undefined | null
};
	["Subscription_UserRelationshipInput"]: {
	relationTo?: ResolverInputTypes["Subscription_UserRelationshipInputRelationTo"] | undefined | null,
	value?: ResolverInputTypes["JSON"] | undefined | null
};
	["Subscription_UserRelationshipInputRelationTo"]:Subscription_UserRelationshipInputRelationTo;
	["Subscription_OrganiserRelationshipInput"]: {
	relationTo?: ResolverInputTypes["Subscription_OrganiserRelationshipInputRelationTo"] | undefined | null,
	value?: ResolverInputTypes["JSON"] | undefined | null
};
	["Subscription_OrganiserRelationshipInputRelationTo"]:Subscription_OrganiserRelationshipInputRelationTo;
	["mutationSubscriptionUpdateInput"]: {
	user?: ResolverInputTypes["SubscriptionUpdate_UserRelationshipInput"] | undefined | null,
	organiser?: ResolverInputTypes["SubscriptionUpdate_OrganiserRelationshipInput"] | undefined | null,
	updatedAt?: string | undefined | null,
	createdAt?: string | undefined | null
};
	["SubscriptionUpdate_UserRelationshipInput"]: {
	relationTo?: ResolverInputTypes["SubscriptionUpdate_UserRelationshipInputRelationTo"] | undefined | null,
	value?: ResolverInputTypes["JSON"] | undefined | null
};
	["SubscriptionUpdate_UserRelationshipInputRelationTo"]:SubscriptionUpdate_UserRelationshipInputRelationTo;
	["SubscriptionUpdate_OrganiserRelationshipInput"]: {
	relationTo?: ResolverInputTypes["SubscriptionUpdate_OrganiserRelationshipInputRelationTo"] | undefined | null,
	value?: ResolverInputTypes["JSON"] | undefined | null
};
	["SubscriptionUpdate_OrganiserRelationshipInputRelationTo"]:SubscriptionUpdate_OrganiserRelationshipInputRelationTo;
	["mutationNewsletterInput"]: {
	email: string,
	region?: ResolverInputTypes["Newsletter_region_MutationInput"] | undefined | null,
	updatedAt?: string | undefined | null,
	createdAt?: string | undefined | null
};
	["Newsletter_region_MutationInput"]:Newsletter_region_MutationInput;
	["mutationNewsletterUpdateInput"]: {
	email?: string | undefined | null,
	region?: ResolverInputTypes["NewsletterUpdate_region_MutationInput"] | undefined | null,
	updatedAt?: string | undefined | null,
	createdAt?: string | undefined | null
};
	["NewsletterUpdate_region_MutationInput"]:NewsletterUpdate_region_MutationInput;
	["mutationBlogCategoryInput"]: {
	name?: string | undefined | null,
	value: string,
	icon?: string | undefined | null,
	region?: ResolverInputTypes["BlogCategory_region_MutationInput"] | undefined | null,
	updatedAt?: string | undefined | null,
	createdAt?: string | undefined | null
};
	["BlogCategory_region_MutationInput"]:BlogCategory_region_MutationInput;
	["mutationBlogCategoryUpdateInput"]: {
	name?: string | undefined | null,
	value?: string | undefined | null,
	icon?: string | undefined | null,
	region?: ResolverInputTypes["BlogCategoryUpdate_region_MutationInput"] | undefined | null,
	updatedAt?: string | undefined | null,
	createdAt?: string | undefined | null
};
	["BlogCategoryUpdate_region_MutationInput"]:BlogCategoryUpdate_region_MutationInput;
	["mutationTagInput"]: {
	name: string,
	value: string,
	region?: ResolverInputTypes["Tag_region_MutationInput"] | undefined | null,
	count?: number | undefined | null,
	skipCountCheck?: boolean | undefined | null,
	removeNextCron?: boolean | undefined | null
};
	["Tag_region_MutationInput"]:Tag_region_MutationInput;
	["mutationTagUpdateInput"]: {
	name?: string | undefined | null,
	value?: string | undefined | null,
	region?: ResolverInputTypes["TagUpdate_region_MutationInput"] | undefined | null,
	count?: number | undefined | null,
	skipCountCheck?: boolean | undefined | null,
	removeNextCron?: boolean | undefined | null
};
	["TagUpdate_region_MutationInput"]:TagUpdate_region_MutationInput;
	["mutationSpecialTagInput"]: {
	name: string,
	value: string,
	region?: ResolverInputTypes["SpecialTag_region_MutationInput"] | undefined | null
};
	["SpecialTag_region_MutationInput"]:SpecialTag_region_MutationInput;
	["mutationSpecialTagUpdateInput"]: {
	name?: string | undefined | null,
	value?: string | undefined | null,
	region?: ResolverInputTypes["SpecialTagUpdate_region_MutationInput"] | undefined | null
};
	["SpecialTagUpdate_region_MutationInput"]:SpecialTagUpdate_region_MutationInput;
	["mutationDateInput"]: {
	name?: string | undefined | null,
	value: string,
	type: ResolverInputTypes["Date_type_MutationInput"],
	start?: string | undefined | null,
	end?: string | undefined | null,
	_title?: string | undefined | null,
	region?: ResolverInputTypes["Date_region_MutationInput"] | undefined | null,
	updatedAt?: string | undefined | null,
	createdAt?: string | undefined | null
};
	["Date_type_MutationInput"]:Date_type_MutationInput;
	["Date_region_MutationInput"]:Date_region_MutationInput;
	["mutationDateUpdateInput"]: {
	name?: string | undefined | null,
	value?: string | undefined | null,
	type?: ResolverInputTypes["DateUpdate_type_MutationInput"] | undefined | null,
	start?: string | undefined | null,
	end?: string | undefined | null,
	_title?: string | undefined | null,
	region?: ResolverInputTypes["DateUpdate_region_MutationInput"] | undefined | null,
	updatedAt?: string | undefined | null,
	createdAt?: string | undefined | null
};
	["DateUpdate_type_MutationInput"]:DateUpdate_type_MutationInput;
	["DateUpdate_region_MutationInput"]:DateUpdate_region_MutationInput;
	["mutationDistrictInput"]: {
	name?: string | undefined | null,
	level: number,
	children?: Array<string | undefined | null> | undefined | null,
	value: string,
	lat?: number | undefined | null,
	long?: number | undefined | null,
	radius?: number | undefined | null,
	region?: ResolverInputTypes["District_region_MutationInput"] | undefined | null,
	updatedAt?: string | undefined | null,
	createdAt?: string | undefined | null
};
	["District_region_MutationInput"]:District_region_MutationInput;
	["mutationDistrictUpdateInput"]: {
	name?: string | undefined | null,
	level?: number | undefined | null,
	children?: Array<string | undefined | null> | undefined | null,
	value?: string | undefined | null,
	lat?: number | undefined | null,
	long?: number | undefined | null,
	radius?: number | undefined | null,
	region?: ResolverInputTypes["DistrictUpdate_region_MutationInput"] | undefined | null,
	updatedAt?: string | undefined | null,
	createdAt?: string | undefined | null
};
	["DistrictUpdate_region_MutationInput"]:DistrictUpdate_region_MutationInput;
	["mutationAudienceInput"]: {
	name?: string | undefined | null,
	value: string,
	icon?: string | undefined | null,
	region?: ResolverInputTypes["Audience_region_MutationInput"] | undefined | null,
	updatedAt?: string | undefined | null,
	createdAt?: string | undefined | null
};
	["Audience_region_MutationInput"]:Audience_region_MutationInput;
	["mutationAudienceUpdateInput"]: {
	name?: string | undefined | null,
	value?: string | undefined | null,
	icon?: string | undefined | null,
	region?: ResolverInputTypes["AudienceUpdate_region_MutationInput"] | undefined | null,
	updatedAt?: string | undefined | null,
	createdAt?: string | undefined | null
};
	["AudienceUpdate_region_MutationInput"]:AudienceUpdate_region_MutationInput;
	["mutationEventCategoryInput"]: {
	name?: string | undefined | null,
	value: string,
	tags?: Array<string | undefined | null> | undefined | null,
	icon?: string | undefined | null,
	region?: ResolverInputTypes["EventCategory_region_MutationInput"] | undefined | null,
	updatedAt?: string | undefined | null,
	createdAt?: string | undefined | null
};
	["EventCategory_region_MutationInput"]:EventCategory_region_MutationInput;
	["mutationEventCategoryUpdateInput"]: {
	name?: string | undefined | null,
	value?: string | undefined | null,
	tags?: Array<string | undefined | null> | undefined | null,
	icon?: string | undefined | null,
	region?: ResolverInputTypes["EventCategoryUpdate_region_MutationInput"] | undefined | null,
	updatedAt?: string | undefined | null,
	createdAt?: string | undefined | null
};
	["EventCategoryUpdate_region_MutationInput"]:EventCategoryUpdate_region_MutationInput;
	["mutationPrivateMediaInput"]: {
	alt?: string | undefined | null,
	updatedAt?: string | undefined | null,
	createdAt?: string | undefined | null,
	url?: string | undefined | null,
	filename?: string | undefined | null,
	mimeType?: string | undefined | null,
	filesize?: number | undefined | null,
	width?: number | undefined | null,
	height?: number | undefined | null,
	focalX?: number | undefined | null,
	focalY?: number | undefined | null,
	sizes?: ResolverInputTypes["mutationPrivateMedia_SizesInput"] | undefined | null
};
	["mutationPrivateMedia_SizesInput"]: {
	thumbnail?: ResolverInputTypes["mutationPrivateMedia_Sizes_ThumbnailInput"] | undefined | null,
	portrait?: ResolverInputTypes["mutationPrivateMedia_Sizes_PortraitInput"] | undefined | null,
	hero?: ResolverInputTypes["mutationPrivateMedia_Sizes_HeroInput"] | undefined | null,
	thumbnail_jpeg?: ResolverInputTypes["mutationPrivateMedia_Sizes_Thumbnail_jpegInput"] | undefined | null,
	portrait_jpeg?: ResolverInputTypes["mutationPrivateMedia_Sizes_Portrait_jpegInput"] | undefined | null,
	hero_jpeg?: ResolverInputTypes["mutationPrivateMedia_Sizes_Hero_jpegInput"] | undefined | null
};
	["mutationPrivateMedia_Sizes_ThumbnailInput"]: {
	url?: string | undefined | null,
	width?: number | undefined | null,
	height?: number | undefined | null,
	mimeType?: string | undefined | null,
	filesize?: number | undefined | null,
	filename?: string | undefined | null
};
	["mutationPrivateMedia_Sizes_PortraitInput"]: {
	url?: string | undefined | null,
	width?: number | undefined | null,
	height?: number | undefined | null,
	mimeType?: string | undefined | null,
	filesize?: number | undefined | null,
	filename?: string | undefined | null
};
	["mutationPrivateMedia_Sizes_HeroInput"]: {
	url?: string | undefined | null,
	width?: number | undefined | null,
	height?: number | undefined | null,
	mimeType?: string | undefined | null,
	filesize?: number | undefined | null,
	filename?: string | undefined | null
};
	["mutationPrivateMedia_Sizes_Thumbnail_jpegInput"]: {
	url?: string | undefined | null,
	width?: number | undefined | null,
	height?: number | undefined | null,
	mimeType?: string | undefined | null,
	filesize?: number | undefined | null,
	filename?: string | undefined | null
};
	["mutationPrivateMedia_Sizes_Portrait_jpegInput"]: {
	url?: string | undefined | null,
	width?: number | undefined | null,
	height?: number | undefined | null,
	mimeType?: string | undefined | null,
	filesize?: number | undefined | null,
	filename?: string | undefined | null
};
	["mutationPrivateMedia_Sizes_Hero_jpegInput"]: {
	url?: string | undefined | null,
	width?: number | undefined | null,
	height?: number | undefined | null,
	mimeType?: string | undefined | null,
	filesize?: number | undefined | null,
	filename?: string | undefined | null
};
	["mutationPrivateMediaUpdateInput"]: {
	alt?: string | undefined | null,
	updatedAt?: string | undefined | null,
	createdAt?: string | undefined | null,
	url?: string | undefined | null,
	filename?: string | undefined | null,
	mimeType?: string | undefined | null,
	filesize?: number | undefined | null,
	width?: number | undefined | null,
	height?: number | undefined | null,
	focalX?: number | undefined | null,
	focalY?: number | undefined | null,
	sizes?: ResolverInputTypes["mutationPrivateMediaUpdate_SizesInput"] | undefined | null
};
	["mutationPrivateMediaUpdate_SizesInput"]: {
	thumbnail?: ResolverInputTypes["mutationPrivateMediaUpdate_Sizes_ThumbnailInput"] | undefined | null,
	portrait?: ResolverInputTypes["mutationPrivateMediaUpdate_Sizes_PortraitInput"] | undefined | null,
	hero?: ResolverInputTypes["mutationPrivateMediaUpdate_Sizes_HeroInput"] | undefined | null,
	thumbnail_jpeg?: ResolverInputTypes["mutationPrivateMediaUpdate_Sizes_Thumbnail_jpegInput"] | undefined | null,
	portrait_jpeg?: ResolverInputTypes["mutationPrivateMediaUpdate_Sizes_Portrait_jpegInput"] | undefined | null,
	hero_jpeg?: ResolverInputTypes["mutationPrivateMediaUpdate_Sizes_Hero_jpegInput"] | undefined | null
};
	["mutationPrivateMediaUpdate_Sizes_ThumbnailInput"]: {
	url?: string | undefined | null,
	width?: number | undefined | null,
	height?: number | undefined | null,
	mimeType?: string | undefined | null,
	filesize?: number | undefined | null,
	filename?: string | undefined | null
};
	["mutationPrivateMediaUpdate_Sizes_PortraitInput"]: {
	url?: string | undefined | null,
	width?: number | undefined | null,
	height?: number | undefined | null,
	mimeType?: string | undefined | null,
	filesize?: number | undefined | null,
	filename?: string | undefined | null
};
	["mutationPrivateMediaUpdate_Sizes_HeroInput"]: {
	url?: string | undefined | null,
	width?: number | undefined | null,
	height?: number | undefined | null,
	mimeType?: string | undefined | null,
	filesize?: number | undefined | null,
	filename?: string | undefined | null
};
	["mutationPrivateMediaUpdate_Sizes_Thumbnail_jpegInput"]: {
	url?: string | undefined | null,
	width?: number | undefined | null,
	height?: number | undefined | null,
	mimeType?: string | undefined | null,
	filesize?: number | undefined | null,
	filename?: string | undefined | null
};
	["mutationPrivateMediaUpdate_Sizes_Portrait_jpegInput"]: {
	url?: string | undefined | null,
	width?: number | undefined | null,
	height?: number | undefined | null,
	mimeType?: string | undefined | null,
	filesize?: number | undefined | null,
	filename?: string | undefined | null
};
	["mutationPrivateMediaUpdate_Sizes_Hero_jpegInput"]: {
	url?: string | undefined | null,
	width?: number | undefined | null,
	height?: number | undefined | null,
	mimeType?: string | undefined | null,
	filesize?: number | undefined | null,
	filename?: string | undefined | null
};
	["mutationMediaInput"]: {
	alt?: string | undefined | null,
	updatedAt?: string | undefined | null,
	createdAt?: string | undefined | null,
	url?: string | undefined | null,
	filename?: string | undefined | null,
	mimeType?: string | undefined | null,
	filesize?: number | undefined | null,
	width?: number | undefined | null,
	height?: number | undefined | null,
	focalX?: number | undefined | null,
	focalY?: number | undefined | null,
	sizes?: ResolverInputTypes["mutationMedia_SizesInput"] | undefined | null
};
	["mutationMedia_SizesInput"]: {
	small?: ResolverInputTypes["mutationMedia_Sizes_SmallInput"] | undefined | null,
	medium?: ResolverInputTypes["mutationMedia_Sizes_MediumInput"] | undefined | null,
	large?: ResolverInputTypes["mutationMedia_Sizes_LargeInput"] | undefined | null,
	small_jpeg?: ResolverInputTypes["mutationMedia_Sizes_Small_jpegInput"] | undefined | null,
	medium_jpeg?: ResolverInputTypes["mutationMedia_Sizes_Medium_jpegInput"] | undefined | null,
	large_jpeg?: ResolverInputTypes["mutationMedia_Sizes_Large_jpegInput"] | undefined | null
};
	["mutationMedia_Sizes_SmallInput"]: {
	url?: string | undefined | null,
	width?: number | undefined | null,
	height?: number | undefined | null,
	mimeType?: string | undefined | null,
	filesize?: number | undefined | null,
	filename?: string | undefined | null
};
	["mutationMedia_Sizes_MediumInput"]: {
	url?: string | undefined | null,
	width?: number | undefined | null,
	height?: number | undefined | null,
	mimeType?: string | undefined | null,
	filesize?: number | undefined | null,
	filename?: string | undefined | null
};
	["mutationMedia_Sizes_LargeInput"]: {
	url?: string | undefined | null,
	width?: number | undefined | null,
	height?: number | undefined | null,
	mimeType?: string | undefined | null,
	filesize?: number | undefined | null,
	filename?: string | undefined | null
};
	["mutationMedia_Sizes_Small_jpegInput"]: {
	url?: string | undefined | null,
	width?: number | undefined | null,
	height?: number | undefined | null,
	mimeType?: string | undefined | null,
	filesize?: number | undefined | null,
	filename?: string | undefined | null
};
	["mutationMedia_Sizes_Medium_jpegInput"]: {
	url?: string | undefined | null,
	width?: number | undefined | null,
	height?: number | undefined | null,
	mimeType?: string | undefined | null,
	filesize?: number | undefined | null,
	filename?: string | undefined | null
};
	["mutationMedia_Sizes_Large_jpegInput"]: {
	url?: string | undefined | null,
	width?: number | undefined | null,
	height?: number | undefined | null,
	mimeType?: string | undefined | null,
	filesize?: number | undefined | null,
	filename?: string | undefined | null
};
	["mutationMediaUpdateInput"]: {
	alt?: string | undefined | null,
	updatedAt?: string | undefined | null,
	createdAt?: string | undefined | null,
	url?: string | undefined | null,
	filename?: string | undefined | null,
	mimeType?: string | undefined | null,
	filesize?: number | undefined | null,
	width?: number | undefined | null,
	height?: number | undefined | null,
	focalX?: number | undefined | null,
	focalY?: number | undefined | null,
	sizes?: ResolverInputTypes["mutationMediaUpdate_SizesInput"] | undefined | null
};
	["mutationMediaUpdate_SizesInput"]: {
	small?: ResolverInputTypes["mutationMediaUpdate_Sizes_SmallInput"] | undefined | null,
	medium?: ResolverInputTypes["mutationMediaUpdate_Sizes_MediumInput"] | undefined | null,
	large?: ResolverInputTypes["mutationMediaUpdate_Sizes_LargeInput"] | undefined | null,
	small_jpeg?: ResolverInputTypes["mutationMediaUpdate_Sizes_Small_jpegInput"] | undefined | null,
	medium_jpeg?: ResolverInputTypes["mutationMediaUpdate_Sizes_Medium_jpegInput"] | undefined | null,
	large_jpeg?: ResolverInputTypes["mutationMediaUpdate_Sizes_Large_jpegInput"] | undefined | null
};
	["mutationMediaUpdate_Sizes_SmallInput"]: {
	url?: string | undefined | null,
	width?: number | undefined | null,
	height?: number | undefined | null,
	mimeType?: string | undefined | null,
	filesize?: number | undefined | null,
	filename?: string | undefined | null
};
	["mutationMediaUpdate_Sizes_MediumInput"]: {
	url?: string | undefined | null,
	width?: number | undefined | null,
	height?: number | undefined | null,
	mimeType?: string | undefined | null,
	filesize?: number | undefined | null,
	filename?: string | undefined | null
};
	["mutationMediaUpdate_Sizes_LargeInput"]: {
	url?: string | undefined | null,
	width?: number | undefined | null,
	height?: number | undefined | null,
	mimeType?: string | undefined | null,
	filesize?: number | undefined | null,
	filename?: string | undefined | null
};
	["mutationMediaUpdate_Sizes_Small_jpegInput"]: {
	url?: string | undefined | null,
	width?: number | undefined | null,
	height?: number | undefined | null,
	mimeType?: string | undefined | null,
	filesize?: number | undefined | null,
	filename?: string | undefined | null
};
	["mutationMediaUpdate_Sizes_Medium_jpegInput"]: {
	url?: string | undefined | null,
	width?: number | undefined | null,
	height?: number | undefined | null,
	mimeType?: string | undefined | null,
	filesize?: number | undefined | null,
	filename?: string | undefined | null
};
	["mutationMediaUpdate_Sizes_Large_jpegInput"]: {
	url?: string | undefined | null,
	width?: number | undefined | null,
	height?: number | undefined | null,
	mimeType?: string | undefined | null,
	filesize?: number | undefined | null,
	filename?: string | undefined | null
};
	["mutationBackupMediaInput"]: {
	alt?: string | undefined | null,
	creator?: string | undefined | null,
	updatedAt?: string | undefined | null,
	createdAt?: string | undefined | null,
	url?: string | undefined | null,
	filename?: string | undefined | null,
	mimeType?: string | undefined | null,
	filesize?: number | undefined | null,
	width?: number | undefined | null,
	height?: number | undefined | null,
	focalX?: number | undefined | null,
	focalY?: number | undefined | null,
	sizes?: ResolverInputTypes["mutationBackupMedia_SizesInput"] | undefined | null
};
	["mutationBackupMedia_SizesInput"]: {
	thumbnail?: ResolverInputTypes["mutationBackupMedia_Sizes_ThumbnailInput"] | undefined | null,
	sixteenByNineMedium?: ResolverInputTypes["mutationBackupMedia_Sizes_SixteenByNineMediumInput"] | undefined | null
};
	["mutationBackupMedia_Sizes_ThumbnailInput"]: {
	url?: string | undefined | null,
	width?: number | undefined | null,
	height?: number | undefined | null,
	mimeType?: string | undefined | null,
	filesize?: number | undefined | null,
	filename?: string | undefined | null
};
	["mutationBackupMedia_Sizes_SixteenByNineMediumInput"]: {
	url?: string | undefined | null,
	width?: number | undefined | null,
	height?: number | undefined | null,
	mimeType?: string | undefined | null,
	filesize?: number | undefined | null,
	filename?: string | undefined | null
};
	["mutationBackupMediaUpdateInput"]: {
	alt?: string | undefined | null,
	creator?: string | undefined | null,
	updatedAt?: string | undefined | null,
	createdAt?: string | undefined | null,
	url?: string | undefined | null,
	filename?: string | undefined | null,
	mimeType?: string | undefined | null,
	filesize?: number | undefined | null,
	width?: number | undefined | null,
	height?: number | undefined | null,
	focalX?: number | undefined | null,
	focalY?: number | undefined | null,
	sizes?: ResolverInputTypes["mutationBackupMediaUpdate_SizesInput"] | undefined | null
};
	["mutationBackupMediaUpdate_SizesInput"]: {
	thumbnail?: ResolverInputTypes["mutationBackupMediaUpdate_Sizes_ThumbnailInput"] | undefined | null,
	sixteenByNineMedium?: ResolverInputTypes["mutationBackupMediaUpdate_Sizes_SixteenByNineMediumInput"] | undefined | null
};
	["mutationBackupMediaUpdate_Sizes_ThumbnailInput"]: {
	url?: string | undefined | null,
	width?: number | undefined | null,
	height?: number | undefined | null,
	mimeType?: string | undefined | null,
	filesize?: number | undefined | null,
	filename?: string | undefined | null
};
	["mutationBackupMediaUpdate_Sizes_SixteenByNineMediumInput"]: {
	url?: string | undefined | null,
	width?: number | undefined | null,
	height?: number | undefined | null,
	mimeType?: string | undefined | null,
	filesize?: number | undefined | null,
	filename?: string | undefined | null
};
	["mutationAccountSuspendRequestInput"]: {
	user?: string | undefined | null,
	handledBy?: string | undefined | null,
	updatedAt?: string | undefined | null,
	createdAt?: string | undefined | null
};
	["mutationAccountSuspendRequestUpdateInput"]: {
	user?: string | undefined | null,
	handledBy?: string | undefined | null,
	updatedAt?: string | undefined | null,
	createdAt?: string | undefined | null
};
	["mutationDeviceInput"]: {
	user?: ResolverInputTypes["Device_UserRelationshipInput"] | undefined | null,
	udid?: string | undefined | null,
	pushToken?: string | undefined | null,
	updatedAt?: string | undefined | null,
	createdAt?: string | undefined | null
};
	["Device_UserRelationshipInput"]: {
	relationTo?: ResolverInputTypes["Device_UserRelationshipInputRelationTo"] | undefined | null,
	value?: ResolverInputTypes["JSON"] | undefined | null
};
	["Device_UserRelationshipInputRelationTo"]:Device_UserRelationshipInputRelationTo;
	["mutationDeviceUpdateInput"]: {
	user?: ResolverInputTypes["DeviceUpdate_UserRelationshipInput"] | undefined | null,
	udid?: string | undefined | null,
	pushToken?: string | undefined | null,
	updatedAt?: string | undefined | null,
	createdAt?: string | undefined | null
};
	["DeviceUpdate_UserRelationshipInput"]: {
	relationTo?: ResolverInputTypes["DeviceUpdate_UserRelationshipInputRelationTo"] | undefined | null,
	value?: ResolverInputTypes["JSON"] | undefined | null
};
	["DeviceUpdate_UserRelationshipInputRelationTo"]:DeviceUpdate_UserRelationshipInputRelationTo;
	["mutationNotificationInput"]: {
	receiver?: Array<string | undefined | null> | undefined | null,
	type?: ResolverInputTypes["Notification_type_MutationInput"] | undefined | null,
	meta?: ResolverInputTypes["Notification_MetaRelationshipInput"] | undefined | null,
	message?: string | undefined | null,
	sender?: string | undefined | null,
	readed?: boolean | undefined | null,
	deletedByUser?: boolean | undefined | null,
	updatedAt?: string | undefined | null,
	createdAt?: string | undefined | null
};
	["Notification_type_MutationInput"]:Notification_type_MutationInput;
	["Notification_MetaRelationshipInput"]: {
	relationTo?: ResolverInputTypes["Notification_MetaRelationshipInputRelationTo"] | undefined | null,
	value?: ResolverInputTypes["JSON"] | undefined | null
};
	["Notification_MetaRelationshipInputRelationTo"]:Notification_MetaRelationshipInputRelationTo;
	["mutationNotificationUpdateInput"]: {
	receiver?: Array<string | undefined | null> | undefined | null,
	type?: ResolverInputTypes["NotificationUpdate_type_MutationInput"] | undefined | null,
	meta?: ResolverInputTypes["NotificationUpdate_MetaRelationshipInput"] | undefined | null,
	message?: string | undefined | null,
	sender?: string | undefined | null,
	readed?: boolean | undefined | null,
	deletedByUser?: boolean | undefined | null,
	updatedAt?: string | undefined | null,
	createdAt?: string | undefined | null
};
	["NotificationUpdate_type_MutationInput"]:NotificationUpdate_type_MutationInput;
	["NotificationUpdate_MetaRelationshipInput"]: {
	relationTo?: ResolverInputTypes["NotificationUpdate_MetaRelationshipInputRelationTo"] | undefined | null,
	value?: ResolverInputTypes["JSON"] | undefined | null
};
	["NotificationUpdate_MetaRelationshipInputRelationTo"]:NotificationUpdate_MetaRelationshipInputRelationTo;
	["mutationRedirectSettingInput"]: {
	from?: string | undefined | null,
	to?: string | undefined | null,
	updatedAt?: string | undefined | null,
	createdAt?: string | undefined | null
};
	["mutationRedirectSettingUpdateInput"]: {
	from?: string | undefined | null,
	to?: string | undefined | null,
	updatedAt?: string | undefined | null,
	createdAt?: string | undefined | null
};
	["mutationEventRankingInput"]: {
	name?: string | undefined | null,
	visible?: boolean | undefined | null,
	maxItems: number,
	template: ResolverInputTypes["EventRanking_template_MutationInput"],
	list?: Array<ResolverInputTypes["mutationEventRanking_ListInput"] | undefined | null> | undefined | null,
	region?: ResolverInputTypes["EventRanking_region_MutationInput"] | undefined | null,
	updatedAt?: string | undefined | null,
	createdAt?: string | undefined | null
};
	["EventRanking_template_MutationInput"]:EventRanking_template_MutationInput;
	["mutationEventRanking_ListInput"]: {
	item?: string | undefined | null,
	id?: string | undefined | null
};
	["EventRanking_region_MutationInput"]:EventRanking_region_MutationInput;
	["mutationEventRankingUpdateInput"]: {
	name?: string | undefined | null,
	visible?: boolean | undefined | null,
	maxItems?: number | undefined | null,
	template?: ResolverInputTypes["EventRankingUpdate_template_MutationInput"] | undefined | null,
	list?: Array<ResolverInputTypes["mutationEventRankingUpdate_ListInput"] | undefined | null> | undefined | null,
	region?: ResolverInputTypes["EventRankingUpdate_region_MutationInput"] | undefined | null,
	updatedAt?: string | undefined | null,
	createdAt?: string | undefined | null
};
	["EventRankingUpdate_template_MutationInput"]:EventRankingUpdate_template_MutationInput;
	["mutationEventRankingUpdate_ListInput"]: {
	item?: string | undefined | null,
	id?: string | undefined | null
};
	["EventRankingUpdate_region_MutationInput"]:EventRankingUpdate_region_MutationInput;
	["mutationLocationRankingInput"]: {
	name?: string | undefined | null,
	visible?: boolean | undefined | null,
	maxItems?: number | undefined | null,
	template: ResolverInputTypes["LocationRanking_template_MutationInput"],
	list?: Array<ResolverInputTypes["mutationLocationRanking_ListInput"] | undefined | null> | undefined | null,
	region?: ResolverInputTypes["LocationRanking_region_MutationInput"] | undefined | null,
	updatedAt?: string | undefined | null,
	createdAt?: string | undefined | null
};
	["LocationRanking_template_MutationInput"]:LocationRanking_template_MutationInput;
	["mutationLocationRanking_ListInput"]: {
	item?: string | undefined | null,
	id?: string | undefined | null
};
	["LocationRanking_region_MutationInput"]:LocationRanking_region_MutationInput;
	["mutationLocationRankingUpdateInput"]: {
	name?: string | undefined | null,
	visible?: boolean | undefined | null,
	maxItems?: number | undefined | null,
	template?: ResolverInputTypes["LocationRankingUpdate_template_MutationInput"] | undefined | null,
	list?: Array<ResolverInputTypes["mutationLocationRankingUpdate_ListInput"] | undefined | null> | undefined | null,
	region?: ResolverInputTypes["LocationRankingUpdate_region_MutationInput"] | undefined | null,
	updatedAt?: string | undefined | null,
	createdAt?: string | undefined | null
};
	["LocationRankingUpdate_template_MutationInput"]:LocationRankingUpdate_template_MutationInput;
	["mutationLocationRankingUpdate_ListInput"]: {
	item?: string | undefined | null,
	id?: string | undefined | null
};
	["LocationRankingUpdate_region_MutationInput"]:LocationRankingUpdate_region_MutationInput;
	["mutationEventCalendarInput"]: {
	date: string,
	item?: string | undefined | null,
	title?: string | undefined | null,
	region?: ResolverInputTypes["EventCalendar_region_MutationInput"] | undefined | null,
	updatedAt?: string | undefined | null,
	createdAt?: string | undefined | null
};
	["EventCalendar_region_MutationInput"]:EventCalendar_region_MutationInput;
	["mutationEventCalendarUpdateInput"]: {
	date?: string | undefined | null,
	item?: string | undefined | null,
	title?: string | undefined | null,
	region?: ResolverInputTypes["EventCalendarUpdate_region_MutationInput"] | undefined | null,
	updatedAt?: string | undefined | null,
	createdAt?: string | undefined | null
};
	["EventCalendarUpdate_region_MutationInput"]:EventCalendarUpdate_region_MutationInput;
	["mutationEditorChoiceInput"]: {
	name?: string | undefined | null,
	visible?: boolean | undefined | null,
	editorialPicks?: Array<string | undefined | null> | undefined | null,
	list?: Array<ResolverInputTypes["mutationEditorChoice_ListInput"] | undefined | null> | undefined | null,
	region?: ResolverInputTypes["EditorChoice_region_MutationInput"] | undefined | null,
	updatedAt?: string | undefined | null,
	createdAt?: string | undefined | null
};
	["mutationEditorChoice_ListInput"]: {
	item?: string | undefined | null,
	id?: string | undefined | null
};
	["EditorChoice_region_MutationInput"]:EditorChoice_region_MutationInput;
	["mutationEditorChoiceUpdateInput"]: {
	name?: string | undefined | null,
	visible?: boolean | undefined | null,
	editorialPicks?: Array<string | undefined | null> | undefined | null,
	list?: Array<ResolverInputTypes["mutationEditorChoiceUpdate_ListInput"] | undefined | null> | undefined | null,
	region?: ResolverInputTypes["EditorChoiceUpdate_region_MutationInput"] | undefined | null,
	updatedAt?: string | undefined | null,
	createdAt?: string | undefined | null
};
	["mutationEditorChoiceUpdate_ListInput"]: {
	item?: string | undefined | null,
	id?: string | undefined | null
};
	["EditorChoiceUpdate_region_MutationInput"]:EditorChoiceUpdate_region_MutationInput;
	["mutationCriteriaSettingInput"]: {
	criteria?: ResolverInputTypes["mutationCriteriaSetting_CriteriaInput"] | undefined | null,
	updatedAt?: string | undefined | null,
	createdAt?: string | undefined | null
};
	["mutationCriteriaSetting_CriteriaInput"]: {
	date?: Array<string | undefined | null> | undefined | null,
	audience?: Array<string | undefined | null> | undefined | null,
	category?: Array<string | undefined | null> | undefined | null,
	tag?: Array<string | undefined | null> | undefined | null,
	region?: ResolverInputTypes["CriteriaSetting_Criteria_region_MutationInput"] | undefined | null
};
	["CriteriaSetting_Criteria_region_MutationInput"]:CriteriaSetting_Criteria_region_MutationInput;
	["mutationCriteriaSettingUpdateInput"]: {
	criteria?: ResolverInputTypes["mutationCriteriaSettingUpdate_CriteriaInput"] | undefined | null,
	updatedAt?: string | undefined | null,
	createdAt?: string | undefined | null
};
	["mutationCriteriaSettingUpdate_CriteriaInput"]: {
	date?: Array<string | undefined | null> | undefined | null,
	audience?: Array<string | undefined | null> | undefined | null,
	category?: Array<string | undefined | null> | undefined | null,
	tag?: Array<string | undefined | null> | undefined | null,
	region?: ResolverInputTypes["CriteriaSettingUpdate_Criteria_region_MutationInput"] | undefined | null
};
	["CriteriaSettingUpdate_Criteria_region_MutationInput"]:CriteriaSettingUpdate_Criteria_region_MutationInput;
	["mutationOrganiserSettingInput"]: {
	name?: string | undefined | null,
	organiserList?: Array<string | undefined | null> | undefined | null,
	region?: ResolverInputTypes["OrganiserSetting_region_MutationInput"] | undefined | null,
	updatedAt?: string | undefined | null,
	createdAt?: string | undefined | null
};
	["OrganiserSetting_region_MutationInput"]:OrganiserSetting_region_MutationInput;
	["mutationOrganiserSettingUpdateInput"]: {
	name?: string | undefined | null,
	organiserList?: Array<string | undefined | null> | undefined | null,
	region?: ResolverInputTypes["OrganiserSettingUpdate_region_MutationInput"] | undefined | null,
	updatedAt?: string | undefined | null,
	createdAt?: string | undefined | null
};
	["OrganiserSettingUpdate_region_MutationInput"]:OrganiserSettingUpdate_region_MutationInput;
	["mutationPostSettingInput"]: {
	name?: string | undefined | null,
	featuredPosts?: Array<string | undefined | null> | undefined | null,
	region?: ResolverInputTypes["PostSetting_region_MutationInput"] | undefined | null,
	updatedAt?: string | undefined | null,
	createdAt?: string | undefined | null
};
	["PostSetting_region_MutationInput"]:PostSetting_region_MutationInput;
	["mutationPostSettingUpdateInput"]: {
	name?: string | undefined | null,
	featuredPosts?: Array<string | undefined | null> | undefined | null,
	region?: ResolverInputTypes["PostSettingUpdate_region_MutationInput"] | undefined | null,
	updatedAt?: string | undefined | null,
	createdAt?: string | undefined | null
};
	["PostSettingUpdate_region_MutationInput"]:PostSettingUpdate_region_MutationInput;
	["mutationHomepageSettingInput"]: {
	name?: string | undefined | null,
	region?: ResolverInputTypes["HomepageSetting_region_MutationInput"] | undefined | null,
	sections?: ResolverInputTypes["JSON"] | undefined | null,
	updatedAt?: string | undefined | null,
	createdAt?: string | undefined | null
};
	["HomepageSetting_region_MutationInput"]:HomepageSetting_region_MutationInput;
	["mutationHomepageSettingUpdateInput"]: {
	name?: string | undefined | null,
	region?: ResolverInputTypes["HomepageSettingUpdate_region_MutationInput"] | undefined | null,
	sections?: ResolverInputTypes["JSON"] | undefined | null,
	updatedAt?: string | undefined | null,
	createdAt?: string | undefined | null
};
	["HomepageSettingUpdate_region_MutationInput"]:HomepageSettingUpdate_region_MutationInput;
	["mutationBannerSectionInput"]: {
	name?: string | undefined | null,
	slug?: string | undefined | null,
	banners?: Array<ResolverInputTypes["mutationBannerSection_BannersInput"] | undefined | null> | undefined | null,
	updatedAt?: string | undefined | null,
	createdAt?: string | undefined | null
};
	["mutationBannerSection_BannersInput"]: {
	name?: string | undefined | null,
	status?: string | undefined | null,
	desktop: string,
	bannerStartDate?: string | undefined | null,
	bannerEndDate?: string | undefined | null,
	region?: ResolverInputTypes["BannerSection_Banners_region_MutationInput"] | undefined | null,
	id?: string | undefined | null
};
	["BannerSection_Banners_region_MutationInput"]:BannerSection_Banners_region_MutationInput;
	["mutationBannerSectionUpdateInput"]: {
	name?: string | undefined | null,
	slug?: string | undefined | null,
	banners?: Array<ResolverInputTypes["mutationBannerSectionUpdate_BannersInput"] | undefined | null> | undefined | null,
	updatedAt?: string | undefined | null,
	createdAt?: string | undefined | null
};
	["mutationBannerSectionUpdate_BannersInput"]: {
	name?: string | undefined | null,
	status?: string | undefined | null,
	desktop: string,
	bannerStartDate?: string | undefined | null,
	bannerEndDate?: string | undefined | null,
	region?: ResolverInputTypes["BannerSectionUpdate_Banners_region_MutationInput"] | undefined | null,
	id?: string | undefined | null
};
	["BannerSectionUpdate_Banners_region_MutationInput"]:BannerSectionUpdate_Banners_region_MutationInput;
	["mutationMacaoBannerSectionInput"]: {
	name?: string | undefined | null,
	slug?: string | undefined | null,
	banners?: Array<ResolverInputTypes["mutationMacaoBannerSection_BannersInput"] | undefined | null> | undefined | null,
	updatedAt?: string | undefined | null,
	createdAt?: string | undefined | null
};
	["mutationMacaoBannerSection_BannersInput"]: {
	name?: string | undefined | null,
	status?: string | undefined | null,
	desktop: string,
	bannerStartDate?: string | undefined | null,
	bannerEndDate?: string | undefined | null,
	region?: ResolverInputTypes["MacaoBannerSection_Banners_region_MutationInput"] | undefined | null,
	id?: string | undefined | null
};
	["MacaoBannerSection_Banners_region_MutationInput"]:MacaoBannerSection_Banners_region_MutationInput;
	["mutationMacaoBannerSectionUpdateInput"]: {
	name?: string | undefined | null,
	slug?: string | undefined | null,
	banners?: Array<ResolverInputTypes["mutationMacaoBannerSectionUpdate_BannersInput"] | undefined | null> | undefined | null,
	updatedAt?: string | undefined | null,
	createdAt?: string | undefined | null
};
	["mutationMacaoBannerSectionUpdate_BannersInput"]: {
	name?: string | undefined | null,
	status?: string | undefined | null,
	desktop: string,
	bannerStartDate?: string | undefined | null,
	bannerEndDate?: string | undefined | null,
	region?: ResolverInputTypes["MacaoBannerSectionUpdate_Banners_region_MutationInput"] | undefined | null,
	id?: string | undefined | null
};
	["MacaoBannerSectionUpdate_Banners_region_MutationInput"]:MacaoBannerSectionUpdate_Banners_region_MutationInput;
	["mutationBannerInput"]: {
	name: string,
	desktop: string,
	mobile: string,
	bannerStartDate: string,
	bannerEndDate: string,
	location?: ResolverInputTypes["mutationBanner_LocationInput"] | undefined | null,
	link?: string | undefined | null,
	external?: boolean | undefined | null,
	type?: ResolverInputTypes["Banner_type_MutationInput"] | undefined | null,
	status?: ResolverInputTypes["Banner_status_MutationInput"] | undefined | null,
	advertise: ResolverInputTypes["mutationBanner_AdvertiseInput"],
	_locations?: string | undefined | null,
	region?: ResolverInputTypes["Banner_region_MutationInput"] | undefined | null,
	homePageClick?: number | undefined | null,
	homePageImpression?: number | undefined | null,
	latestEventClick?: number | undefined | null,
	latestEventImpression?: number | undefined | null,
	editorChoiceClick?: number | undefined | null,
	editorChoiceImpression?: number | undefined | null,
	eventListClick?: number | undefined | null,
	eventListImpression?: number | undefined | null,
	topTenClick?: number | undefined | null,
	topTenImpression?: number | undefined | null,
	miscClick?: number | undefined | null,
	miscImpression?: number | undefined | null,
	clickTotal?: number | undefined | null,
	totalImpression?: number | undefined | null,
	updatedAt?: string | undefined | null,
	createdAt?: string | undefined | null
};
	["mutationBanner_LocationInput"]: {
	page?: Array<ResolverInputTypes["Banner_Location_page_MutationInput"] | undefined | null> | undefined | null,
	eventRankingList?: Array<string | undefined | null> | undefined | null,
	locationRankingList?: Array<string | undefined | null> | undefined | null,
	categories?: Array<string | undefined | null> | undefined | null,
	time?: boolean | undefined | null,
	district?: boolean | undefined | null,
	audience?: boolean | undefined | null,
	multipleCriteriaSearch?: boolean | undefined | null
};
	["Banner_Location_page_MutationInput"]:Banner_Location_page_MutationInput;
	["Banner_type_MutationInput"]:Banner_type_MutationInput;
	["Banner_status_MutationInput"]:Banner_status_MutationInput;
	["mutationBanner_AdvertiseInput"]: {
	campaign?: string | undefined | null,
	invoiceDescription?: string | undefined | null,
	standardPrice: number,
	discount?: number | undefined | null,
	timableCost?: number | undefined | null,
	remark?: string | undefined | null,
	public?: boolean | undefined | null
};
	["Banner_region_MutationInput"]:Banner_region_MutationInput;
	["mutationBannerUpdateInput"]: {
	name?: string | undefined | null,
	desktop?: string | undefined | null,
	mobile?: string | undefined | null,
	bannerStartDate?: string | undefined | null,
	bannerEndDate?: string | undefined | null,
	location?: ResolverInputTypes["mutationBannerUpdate_LocationInput"] | undefined | null,
	link?: string | undefined | null,
	external?: boolean | undefined | null,
	type?: ResolverInputTypes["BannerUpdate_type_MutationInput"] | undefined | null,
	status?: ResolverInputTypes["BannerUpdate_status_MutationInput"] | undefined | null,
	advertise: ResolverInputTypes["mutationBannerUpdate_AdvertiseInput"],
	_locations?: string | undefined | null,
	region?: ResolverInputTypes["BannerUpdate_region_MutationInput"] | undefined | null,
	homePageClick?: number | undefined | null,
	homePageImpression?: number | undefined | null,
	latestEventClick?: number | undefined | null,
	latestEventImpression?: number | undefined | null,
	editorChoiceClick?: number | undefined | null,
	editorChoiceImpression?: number | undefined | null,
	eventListClick?: number | undefined | null,
	eventListImpression?: number | undefined | null,
	topTenClick?: number | undefined | null,
	topTenImpression?: number | undefined | null,
	miscClick?: number | undefined | null,
	miscImpression?: number | undefined | null,
	clickTotal?: number | undefined | null,
	totalImpression?: number | undefined | null,
	updatedAt?: string | undefined | null,
	createdAt?: string | undefined | null
};
	["mutationBannerUpdate_LocationInput"]: {
	page?: Array<ResolverInputTypes["BannerUpdate_Location_page_MutationInput"] | undefined | null> | undefined | null,
	eventRankingList?: Array<string | undefined | null> | undefined | null,
	locationRankingList?: Array<string | undefined | null> | undefined | null,
	categories?: Array<string | undefined | null> | undefined | null,
	time?: boolean | undefined | null,
	district?: boolean | undefined | null,
	audience?: boolean | undefined | null,
	multipleCriteriaSearch?: boolean | undefined | null
};
	["BannerUpdate_Location_page_MutationInput"]:BannerUpdate_Location_page_MutationInput;
	["BannerUpdate_type_MutationInput"]:BannerUpdate_type_MutationInput;
	["BannerUpdate_status_MutationInput"]:BannerUpdate_status_MutationInput;
	["mutationBannerUpdate_AdvertiseInput"]: {
	campaign?: string | undefined | null,
	invoiceDescription?: string | undefined | null,
	standardPrice: number,
	discount?: number | undefined | null,
	timableCost?: number | undefined | null,
	remark?: string | undefined | null,
	public?: boolean | undefined | null
};
	["BannerUpdate_region_MutationInput"]:BannerUpdate_region_MutationInput;
	["mutationCampaignInput"]: {
	creator?: ResolverInputTypes["Campaign_CreatorRelationshipInput"] | undefined | null,
	invoiceNumber?: string | undefined | null,
	quotationNumber?: string | undefined | null,
	campaignName: string,
	client: string,
	contactPerson: string,
	email: string,
	phone: string,
	agentName?: string | undefined | null,
	agentCommission?: number | undefined | null,
	agentOrderNumber?: string | undefined | null,
	_firstPublishedTime?: string | undefined | null,
	updatedAt?: string | undefined | null,
	createdAt?: string | undefined | null
};
	["Campaign_CreatorRelationshipInput"]: {
	relationTo?: ResolverInputTypes["Campaign_CreatorRelationshipInputRelationTo"] | undefined | null,
	value?: ResolverInputTypes["JSON"] | undefined | null
};
	["Campaign_CreatorRelationshipInputRelationTo"]:Campaign_CreatorRelationshipInputRelationTo;
	["mutationCampaignUpdateInput"]: {
	creator?: ResolverInputTypes["CampaignUpdate_CreatorRelationshipInput"] | undefined | null,
	invoiceNumber?: string | undefined | null,
	quotationNumber?: string | undefined | null,
	campaignName?: string | undefined | null,
	client?: string | undefined | null,
	contactPerson?: string | undefined | null,
	email?: string | undefined | null,
	phone?: string | undefined | null,
	agentName?: string | undefined | null,
	agentCommission?: number | undefined | null,
	agentOrderNumber?: string | undefined | null,
	_firstPublishedTime?: string | undefined | null,
	updatedAt?: string | undefined | null,
	createdAt?: string | undefined | null
};
	["CampaignUpdate_CreatorRelationshipInput"]: {
	relationTo?: ResolverInputTypes["CampaignUpdate_CreatorRelationshipInputRelationTo"] | undefined | null,
	value?: ResolverInputTypes["JSON"] | undefined | null
};
	["CampaignUpdate_CreatorRelationshipInputRelationTo"]:CampaignUpdate_CreatorRelationshipInputRelationTo;
	["mutationAdvertiseInput"]: {
	advertise: ResolverInputTypes["mutationAdvertise_AdvertiseInput"],
	advertiseStartDate: string,
	advertiseEndDate: string,
	type?: ResolverInputTypes["Advertise_type_MutationInput"] | undefined | null,
	invoiceDescription?: string | undefined | null,
	standardPrice: number,
	discount?: number | undefined | null,
	discountPercentage?: number | undefined | null,
	_calculateByDiscount?: boolean | undefined | null,
	timableCost?: number | undefined | null,
	remark?: string | undefined | null,
	public?: boolean | undefined | null,
	campaignName?: string | undefined | null,
	campaignId?: string | undefined | null,
	status?: string | undefined | null,
	updatedAt?: string | undefined | null,
	createdAt?: string | undefined | null
};
	["mutationAdvertise_AdvertiseInput"]: {
	type?: ResolverInputTypes["Advertise_Advertise_type_MutationInput"] | undefined | null,
	event?: string | undefined | null,
	keyword?: string | undefined | null,
	location?: string | undefined | null,
	displayPosition?: string | undefined | null,
	rankingListRemark?: string | undefined | null
};
	["Advertise_Advertise_type_MutationInput"]:Advertise_Advertise_type_MutationInput;
	["Advertise_type_MutationInput"]:Advertise_type_MutationInput;
	["mutationAdvertiseUpdateInput"]: {
	advertise: ResolverInputTypes["mutationAdvertiseUpdate_AdvertiseInput"],
	advertiseStartDate?: string | undefined | null,
	advertiseEndDate?: string | undefined | null,
	type?: ResolverInputTypes["AdvertiseUpdate_type_MutationInput"] | undefined | null,
	invoiceDescription?: string | undefined | null,
	standardPrice?: number | undefined | null,
	discount?: number | undefined | null,
	discountPercentage?: number | undefined | null,
	_calculateByDiscount?: boolean | undefined | null,
	timableCost?: number | undefined | null,
	remark?: string | undefined | null,
	public?: boolean | undefined | null,
	campaignName?: string | undefined | null,
	campaignId?: string | undefined | null,
	status?: string | undefined | null,
	updatedAt?: string | undefined | null,
	createdAt?: string | undefined | null
};
	["mutationAdvertiseUpdate_AdvertiseInput"]: {
	type?: ResolverInputTypes["AdvertiseUpdate_Advertise_type_MutationInput"] | undefined | null,
	event?: string | undefined | null,
	keyword?: string | undefined | null,
	location?: string | undefined | null,
	displayPosition?: string | undefined | null,
	rankingListRemark?: string | undefined | null
};
	["AdvertiseUpdate_Advertise_type_MutationInput"]:AdvertiseUpdate_Advertise_type_MutationInput;
	["AdvertiseUpdate_type_MutationInput"]:AdvertiseUpdate_type_MutationInput;
	["mutationStaticInput"]: {
	name?: string | undefined | null,
	key?: string | undefined | null,
	content?: ResolverInputTypes["JSON"] | undefined | null,
	region?: ResolverInputTypes["Static_region_MutationInput"] | undefined | null,
	updatedAt?: string | undefined | null,
	createdAt?: string | undefined | null
};
	["Static_region_MutationInput"]:Static_region_MutationInput;
	["mutationStaticUpdateInput"]: {
	name?: string | undefined | null,
	key?: string | undefined | null,
	content?: ResolverInputTypes["JSON"] | undefined | null,
	region?: ResolverInputTypes["StaticUpdate_region_MutationInput"] | undefined | null,
	updatedAt?: string | undefined | null,
	createdAt?: string | undefined | null
};
	["StaticUpdate_region_MutationInput"]:StaticUpdate_region_MutationInput;
	["mutationEventViewInput"]: {
	date: string,
	eventId?: string | undefined | null,
	viewCount: number,
	updatedAt?: string | undefined | null,
	createdAt?: string | undefined | null
};
	["mutationEventViewUpdateInput"]: {
	date?: string | undefined | null,
	eventId?: string | undefined | null,
	viewCount?: number | undefined | null,
	updatedAt?: string | undefined | null,
	createdAt?: string | undefined | null
};
	["mutationPostViewInput"]: {
	date: string,
	postId?: string | undefined | null,
	viewCount: number,
	updatedAt?: string | undefined | null,
	createdAt?: string | undefined | null
};
	["mutationPostViewUpdateInput"]: {
	date?: string | undefined | null,
	postId?: string | undefined | null,
	viewCount?: number | undefined | null,
	updatedAt?: string | undefined | null,
	createdAt?: string | undefined | null
};
	["mutationPayloadPreferenceInput"]: {
	user?: ResolverInputTypes["PayloadPreference_UserRelationshipInput"] | undefined | null,
	key?: string | undefined | null,
	value?: ResolverInputTypes["JSON"] | undefined | null,
	updatedAt?: string | undefined | null,
	createdAt?: string | undefined | null
};
	["PayloadPreference_UserRelationshipInput"]: {
	relationTo?: ResolverInputTypes["PayloadPreference_UserRelationshipInputRelationTo"] | undefined | null,
	value?: ResolverInputTypes["JSON"] | undefined | null
};
	["PayloadPreference_UserRelationshipInputRelationTo"]:PayloadPreference_UserRelationshipInputRelationTo;
	["mutationPayloadPreferenceUpdateInput"]: {
	user?: ResolverInputTypes["PayloadPreferenceUpdate_UserRelationshipInput"] | undefined | null,
	key?: string | undefined | null,
	value?: ResolverInputTypes["JSON"] | undefined | null,
	updatedAt?: string | undefined | null,
	createdAt?: string | undefined | null
};
	["PayloadPreferenceUpdate_UserRelationshipInput"]: {
	relationTo?: ResolverInputTypes["PayloadPreferenceUpdate_UserRelationshipInputRelationTo"] | undefined | null,
	value?: ResolverInputTypes["JSON"] | undefined | null
};
	["PayloadPreferenceUpdate_UserRelationshipInputRelationTo"]:PayloadPreferenceUpdate_UserRelationshipInputRelationTo;
	["ChatbotInventoryPayment"]: AliasType<{
	expiredAt?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ChatbotTransactionPdfGet"]: AliasType<{
	url?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["WebAppQrCodeBatchScanResult"]: AliasType<{
	result?:ResolverInputTypes["WebAppQrCodeScanResponse"],
		__typename?: boolean | `@${string}`
}>;
	["WebAppQrCodeScanResponse"]: AliasType<{
	status?:boolean | `@${string}`,
	qrCodeId?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	phone?:boolean | `@${string}`,
	scanAt?:boolean | `@${string}`,
	uploadAt?:boolean | `@${string}`,
	failReason?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["WebAppQrCodeBatchScanItem"]: {
	checkInAppSelectionId?: string | undefined | null,
	checkInAppSelectionProductType?: string | undefined | null,
	qrCodeId?: string | undefined | null,
	scanAt?: string | undefined | null,
	entryNumber?: string | undefined | null
};
	["schema"]: AliasType<{
	query?:ResolverInputTypes["Query"],
	mutation?:ResolverInputTypes["Mutation"],
		__typename?: boolean | `@${string}`
}>
  }

export type ModelTypes = {
    ["Query"]: {
		Event?: ModelTypes["Event"] | undefined,
	Events?: ModelTypes["Events"] | undefined,
	countEvents?: ModelTypes["countEvents"] | undefined,
	docAccessEvent?: ModelTypes["eventsDocAccess"] | undefined,
	Location?: ModelTypes["Location"] | undefined,
	Locations?: ModelTypes["Locations"] | undefined,
	countLocations?: ModelTypes["countLocations"] | undefined,
	docAccessLocation?: ModelTypes["locationsDocAccess"] | undefined,
	Post?: ModelTypes["Post"] | undefined,
	Posts?: ModelTypes["Posts"] | undefined,
	countPosts?: ModelTypes["countPosts"] | undefined,
	docAccessPost?: ModelTypes["postsDocAccess"] | undefined,
	Ticketing?: ModelTypes["Ticketing"] | undefined,
	Ticketings?: ModelTypes["Ticketings"] | undefined,
	countTicketings?: ModelTypes["countTicketings"] | undefined,
	docAccessTicketing?: ModelTypes["ticketingDocAccess"] | undefined,
	Product?: ModelTypes["Product"] | undefined,
	Products?: ModelTypes["Products"] | undefined,
	countProducts?: ModelTypes["countProducts"] | undefined,
	docAccessProduct?: ModelTypes["productsDocAccess"] | undefined,
	InventoryStatus?: ModelTypes["InventoryStatus"] | undefined,
	InventoryStatuses?: ModelTypes["InventoryStatuses"] | undefined,
	countInventoryStatuses?: ModelTypes["countInventoryStatuses"] | undefined,
	docAccessInventoryStatus?: ModelTypes["inventory_statusDocAccess"] | undefined,
	Transaction?: ModelTypes["Transaction"] | undefined,
	Transactions?: ModelTypes["Transactions"] | undefined,
	countTransactions?: ModelTypes["countTransactions"] | undefined,
	docAccessTransaction?: ModelTypes["transactionsDocAccess"] | undefined,
	RedemptionCode?: ModelTypes["RedemptionCode"] | undefined,
	RedemptionCodes?: ModelTypes["RedemptionCodes"] | undefined,
	countRedemptionCodes?: ModelTypes["countRedemptionCodes"] | undefined,
	docAccessRedemptionCode?: ModelTypes["redemption_codesDocAccess"] | undefined,
	RedemptionPdf?: ModelTypes["RedemptionPdf"] | undefined,
	RedemptionPdfs?: ModelTypes["RedemptionPdfs"] | undefined,
	countRedemptionPdfs?: ModelTypes["countRedemptionPdfs"] | undefined,
	docAccessRedemptionPdf?: ModelTypes["redemption_pdfDocAccess"] | undefined,
	CheckInRedemption?: ModelTypes["CheckInRedemption"] | undefined,
	CheckInRedemptions?: ModelTypes["CheckInRedemptions"] | undefined,
	countCheckInRedemptions?: ModelTypes["countCheckInRedemptions"] | undefined,
	docAccessCheckInRedemption?: ModelTypes["check_in_redemptionDocAccess"] | undefined,
	User?: ModelTypes["User"] | undefined,
	Users?: ModelTypes["Users"] | undefined,
	countUsers?: ModelTypes["countUsers"] | undefined,
	docAccessUser?: ModelTypes["usersDocAccess"] | undefined,
	meUser?: ModelTypes["usersMe"] | undefined,
	initializedUser?: boolean | undefined,
	Bookmark?: ModelTypes["Bookmark"] | undefined,
	Bookmarks?: ModelTypes["Bookmarks"] | undefined,
	countBookmarks?: ModelTypes["countBookmarks"] | undefined,
	docAccessBookmark?: ModelTypes["bookmarksDocAccess"] | undefined,
	WebAppBookmark?: ModelTypes["WebAppBookmark"] | undefined,
	WebAppBookmarks?: ModelTypes["WebAppBookmarks"] | undefined,
	countWebAppBookmarks?: ModelTypes["countWebAppBookmarks"] | undefined,
	docAccessWebAppBookmark?: ModelTypes["webAppBookmarksDocAccess"] | undefined,
	ViewRecord?: ModelTypes["ViewRecord"] | undefined,
	ViewRecords?: ModelTypes["ViewRecords"] | undefined,
	countViewRecords?: ModelTypes["countViewRecords"] | undefined,
	docAccessViewRecord?: ModelTypes["view_recordsDocAccess"] | undefined,
	Comment?: ModelTypes["Comment"] | undefined,
	Comments?: ModelTypes["Comments"] | undefined,
	countComments?: ModelTypes["countComments"] | undefined,
	docAccessComment?: ModelTypes["commentsDocAccess"] | undefined,
	Subscription?: ModelTypes["Subscription"] | undefined,
	Subscriptions?: ModelTypes["Subscriptions"] | undefined,
	countSubscriptions?: ModelTypes["countSubscriptions"] | undefined,
	docAccessSubscription?: ModelTypes["subscriptionsDocAccess"] | undefined,
	Newsletter?: ModelTypes["Newsletter"] | undefined,
	Newsletters?: ModelTypes["Newsletters"] | undefined,
	countNewsletters?: ModelTypes["countNewsletters"] | undefined,
	docAccessNewsletter?: ModelTypes["newsletterDocAccess"] | undefined,
	BlogCategory?: ModelTypes["BlogCategory"] | undefined,
	BlogCategories?: ModelTypes["BlogCategories"] | undefined,
	countBlogCategories?: ModelTypes["countBlogCategories"] | undefined,
	docAccessBlogCategory?: ModelTypes["blog_categoriesDocAccess"] | undefined,
	Tag?: ModelTypes["Tag"] | undefined,
	Tags?: ModelTypes["Tags"] | undefined,
	countTags?: ModelTypes["countTags"] | undefined,
	docAccessTag?: ModelTypes["tagsDocAccess"] | undefined,
	SpecialTag?: ModelTypes["SpecialTag"] | undefined,
	SpecialTags?: ModelTypes["SpecialTags"] | undefined,
	countSpecialTags?: ModelTypes["countSpecialTags"] | undefined,
	docAccessSpecialTag?: ModelTypes["specialTagsDocAccess"] | undefined,
	Date?: ModelTypes["Date"] | undefined,
	Dates?: ModelTypes["Dates"] | undefined,
	countDates?: ModelTypes["countDates"] | undefined,
	docAccessDate?: ModelTypes["dateDocAccess"] | undefined,
	District?: ModelTypes["District"] | undefined,
	Districts?: ModelTypes["Districts"] | undefined,
	countDistricts?: ModelTypes["countDistricts"] | undefined,
	docAccessDistrict?: ModelTypes["districtsDocAccess"] | undefined,
	Audience?: ModelTypes["Audience"] | undefined,
	Audiences?: ModelTypes["Audiences"] | undefined,
	countAudiences?: ModelTypes["countAudiences"] | undefined,
	docAccessAudience?: ModelTypes["audienceDocAccess"] | undefined,
	EventCategory?: ModelTypes["EventCategory"] | undefined,
	EventCategories?: ModelTypes["EventCategories"] | undefined,
	countEventCategories?: ModelTypes["countEventCategories"] | undefined,
	docAccessEventCategory?: ModelTypes["categoryDocAccess"] | undefined,
	PrivateMedia?: ModelTypes["PrivateMedia"] | undefined,
	PrivateMedias?: ModelTypes["PrivateMedias"] | undefined,
	countPrivateMedias?: ModelTypes["countPrivateMedias"] | undefined,
	docAccessPrivateMedia?: ModelTypes["private_mediaDocAccess"] | undefined,
	Media?: ModelTypes["Media"] | undefined,
	allMedia?: ModelTypes["allMedia"] | undefined,
	countallMedia?: ModelTypes["countallMedia"] | undefined,
	docAccessMedia?: ModelTypes["mediaDocAccess"] | undefined,
	BackupMedia?: ModelTypes["BackupMedia"] | undefined,
	BackupMedias?: ModelTypes["BackupMedias"] | undefined,
	countBackupMedias?: ModelTypes["countBackupMedias"] | undefined,
	docAccessBackupMedia?: ModelTypes["backupMediaDocAccess"] | undefined,
	AccountSuspendRequest?: ModelTypes["AccountSuspendRequest"] | undefined,
	AccountSuspendRequests?: ModelTypes["AccountSuspendRequests"] | undefined,
	countAccountSuspendRequests?: ModelTypes["countAccountSuspendRequests"] | undefined,
	docAccessAccountSuspendRequest?: ModelTypes["account_suspend_requestsDocAccess"] | undefined,
	Device?: ModelTypes["Device"] | undefined,
	Devices?: ModelTypes["Devices"] | undefined,
	countDevices?: ModelTypes["countDevices"] | undefined,
	docAccessDevice?: ModelTypes["devicesDocAccess"] | undefined,
	Notification?: ModelTypes["Notification"] | undefined,
	Notifications?: ModelTypes["Notifications"] | undefined,
	countNotifications?: ModelTypes["countNotifications"] | undefined,
	docAccessNotification?: ModelTypes["notificationsDocAccess"] | undefined,
	RedirectSetting?: ModelTypes["RedirectSetting"] | undefined,
	RedirectSettings?: ModelTypes["RedirectSettings"] | undefined,
	countRedirectSettings?: ModelTypes["countRedirectSettings"] | undefined,
	docAccessRedirectSetting?: ModelTypes["redirectSettingDocAccess"] | undefined,
	EventRanking?: ModelTypes["EventRanking"] | undefined,
	EventRankings?: ModelTypes["EventRankings"] | undefined,
	countEventRankings?: ModelTypes["countEventRankings"] | undefined,
	docAccessEventRanking?: ModelTypes["ranking_eventsDocAccess"] | undefined,
	LocationRanking?: ModelTypes["LocationRanking"] | undefined,
	LocationRankings?: ModelTypes["LocationRankings"] | undefined,
	countLocationRankings?: ModelTypes["countLocationRankings"] | undefined,
	docAccessLocationRanking?: ModelTypes["ranking_locationsDocAccess"] | undefined,
	EventCalendar?: ModelTypes["EventCalendar"] | undefined,
	EventCalendars?: ModelTypes["EventCalendars"] | undefined,
	countEventCalendars?: ModelTypes["countEventCalendars"] | undefined,
	docAccessEventCalendar?: ModelTypes["event_calendarDocAccess"] | undefined,
	EditorChoice?: ModelTypes["EditorChoice"] | undefined,
	EditorChoices?: ModelTypes["EditorChoices"] | undefined,
	countEditorChoices?: ModelTypes["countEditorChoices"] | undefined,
	docAccessEditorChoice?: ModelTypes["editor_choicesDocAccess"] | undefined,
	CriteriaSetting?: ModelTypes["CriteriaSetting"] | undefined,
	CriteriaSettings?: ModelTypes["CriteriaSettings"] | undefined,
	countCriteriaSettings?: ModelTypes["countCriteriaSettings"] | undefined,
	docAccessCriteriaSetting?: ModelTypes["criteria_settingDocAccess"] | undefined,
	OrganiserSetting?: ModelTypes["OrganiserSetting"] | undefined,
	OrganiserSettings?: ModelTypes["OrganiserSettings"] | undefined,
	countOrganiserSettings?: ModelTypes["countOrganiserSettings"] | undefined,
	docAccessOrganiserSetting?: ModelTypes["organiserSettingDocAccess"] | undefined,
	PostSetting?: ModelTypes["PostSetting"] | undefined,
	PostSettings?: ModelTypes["PostSettings"] | undefined,
	countPostSettings?: ModelTypes["countPostSettings"] | undefined,
	docAccessPostSetting?: ModelTypes["post_settingDocAccess"] | undefined,
	HomepageSetting?: ModelTypes["HomepageSetting"] | undefined,
	HomepageSettings?: ModelTypes["HomepageSettings"] | undefined,
	countHomepageSettings?: ModelTypes["countHomepageSettings"] | undefined,
	docAccessHomepageSetting?: ModelTypes["homepageSettingDocAccess"] | undefined,
	BannerSection?: ModelTypes["BannerSection"] | undefined,
	BannerSections?: ModelTypes["BannerSections"] | undefined,
	countBannerSections?: ModelTypes["countBannerSections"] | undefined,
	docAccessBannerSection?: ModelTypes["banner_sectionDocAccess"] | undefined,
	MacaoBannerSection?: ModelTypes["MacaoBannerSection"] | undefined,
	MacaoBannerSections?: ModelTypes["MacaoBannerSections"] | undefined,
	countMacaoBannerSections?: ModelTypes["countMacaoBannerSections"] | undefined,
	docAccessMacaoBannerSection?: ModelTypes["macao_banner_sectionDocAccess"] | undefined,
	Banner?: ModelTypes["Banner"] | undefined,
	Banners?: ModelTypes["Banners"] | undefined,
	countBanners?: ModelTypes["countBanners"] | undefined,
	docAccessBanner?: ModelTypes["bannersDocAccess"] | undefined,
	Campaign?: ModelTypes["Campaign"] | undefined,
	Campaigns?: ModelTypes["Campaigns"] | undefined,
	countCampaigns?: ModelTypes["countCampaigns"] | undefined,
	docAccessCampaign?: ModelTypes["campaignsDocAccess"] | undefined,
	Advertise?: ModelTypes["Advertise"] | undefined,
	Advertises?: ModelTypes["Advertises"] | undefined,
	countAdvertises?: ModelTypes["countAdvertises"] | undefined,
	docAccessAdvertise?: ModelTypes["advertisesDocAccess"] | undefined,
	Static?: ModelTypes["Static"] | undefined,
	Statics?: ModelTypes["Statics"] | undefined,
	countStatics?: ModelTypes["countStatics"] | undefined,
	docAccessStatic?: ModelTypes["staticsDocAccess"] | undefined,
	EventView?: ModelTypes["EventView"] | undefined,
	EventViews?: ModelTypes["EventViews"] | undefined,
	countEventViews?: ModelTypes["countEventViews"] | undefined,
	docAccessEventView?: ModelTypes["event_viewsDocAccess"] | undefined,
	PostView?: ModelTypes["PostView"] | undefined,
	PostViews?: ModelTypes["PostViews"] | undefined,
	countPostViews?: ModelTypes["countPostViews"] | undefined,
	docAccessPostView?: ModelTypes["post_viewsDocAccess"] | undefined,
	PayloadPreference?: ModelTypes["PayloadPreference"] | undefined,
	PayloadPreferences?: ModelTypes["PayloadPreferences"] | undefined,
	countPayloadPreferences?: ModelTypes["countPayloadPreferences"] | undefined,
	docAccessPayloadPreference?: ModelTypes["payload_preferencesDocAccess"] | undefined,
	Access?: ModelTypes["Access"] | undefined,
	GetEvents?: ModelTypes["Events"] | undefined,
	GetFilterEvents?: ModelTypes["Events"] | undefined,
	GetFilterPastEvents?: ModelTypes["Events"] | undefined,
	GetEventMaxUsedTag?: ModelTypes["GetEventMaxUsedTag"] | undefined,
	GetSortEvent?: ModelTypes["Events"] | undefined,
	BookmarkEvents?: ModelTypes["Events"] | undefined,
	GetBookmarks?: ModelTypes["Bookmarks"] | undefined,
	BookmarkItems?: ModelTypes["Bookmarks"] | undefined,
	ViewedEvents?: ModelTypes["Events"] | undefined,
	NonFeaturedPosts?: ModelTypes["Posts"] | undefined,
	CheckEmailUniqueness?: boolean | undefined,
	GetOrganisers?: ModelTypes["Users"] | undefined,
	GetOrganiser?: ModelTypes["User"] | undefined,
	GetSubscriptions?: ModelTypes["Subscriptions"] | undefined,
	GetFollowed?: ModelTypes["Subscriptions"] | undefined,
	GetComments?: ModelTypes["Comments"] | undefined,
	CommentsCount?: ModelTypes["Comments"] | undefined,
	GetBanners?: ModelTypes["Banners"] | undefined,
	GetBannerSection?: ModelTypes["Banners"] | undefined,
	GetNotifications?: ModelTypes["Notifications"] | undefined,
	GetCriteriaSettings?: ModelTypes["CriteriaSettings"] | undefined,
	GetFilterLocations?: ModelTypes["Locations"] | undefined,
	GetContainEventTagLocations?: ModelTypes["Locations"] | undefined,
	GetEventRankings?: ModelTypes["EventRankings"] | undefined,
	ChatbotStocks?: ModelTypes["ChatbotStocks"] | undefined,
	ChatbotProduct?: ModelTypes["Product"] | undefined,
	ChatbotTicketing?: ModelTypes["Ticketing"] | undefined,
	ChatbotEvents?: ModelTypes["ChatbotEvents"] | undefined,
	FilterExpiredEvent?: ModelTypes["EditorChoices"] | undefined,
	WebAppCheckInCountGet?: ModelTypes["WebAppCheckInCountGetResponse"] | undefined,
	WebAppCheckInList?: ModelTypes["CheckInRedemptions"] | undefined,
	WebAppEventList?: ModelTypes["Events"] | undefined,
	WebAppProductDetail?: ModelTypes["Product"] | undefined,
	WebAppProductList?: ModelTypes["ProductList"] | undefined,
	WebAppTicketingDetail?: ModelTypes["Ticketing"] | undefined,
	WebAppTicketingList?: ModelTypes["TicketingList"] | undefined,
	WebAppRedemptionCodeList?: ModelTypes["RedemptionCodeList"] | undefined,
	WebAppRedemptionCodeDetail?: ModelTypes["RedemptionCode"] | undefined,
	WebAppBookmarkList?: ModelTypes["BookmarkList"] | undefined
};
	["Event"]: {
		id?: string | undefined,
	region?: ModelTypes["Event_region"] | undefined,
	creator?: ModelTypes["User"] | undefined,
	permalink?: string | undefined,
	status: string,
	level?: ModelTypes["Event_level"] | undefined,
	parent?: Array<ModelTypes["Event"]> | undefined,
	name?: string | undefined,
	chatbotEventTitle?: string | undefined,
	chatbotSequence?: number | undefined,
	thumbnail?: ModelTypes["Media"] | undefined,
	banner?: ModelTypes["Media"] | undefined,
	mobileBanner?: ModelTypes["Media"] | undefined,
	sections?: Array<ModelTypes["Event_Sections"]> | undefined,
	ticketing?: Array<ModelTypes["Event_Ticketing"]> | undefined,
	content?: Array<ModelTypes["Event_Content"]> | undefined,
	legacyContent?: string | undefined,
	showLegacyContent?: boolean | undefined,
	criteria?: ModelTypes["Event_Criteria"] | undefined,
	walkIn?: boolean | undefined,
	contact?: ModelTypes["Event_Contact"] | undefined,
	views?: number | undefined,
	free?: boolean | undefined,
	fees?: Array<ModelTypes["Event_Fees"]> | undefined,
	homePageInternalClick?: number | undefined,
	homePageImpression?: number | undefined,
	eodClickRate?: number | undefined,
	eodImpressionRate?: number | undefined,
	top10ClickRateHomepage?: number | undefined,
	top10ImpressionkRateHomepage?: number | undefined,
	otherEventRankingClickRateHomepage?: number | undefined,
	otherEventRankingImpressionHomePage?: number | undefined,
	userPreferenceClickRate?: number | undefined,
	userPreferenceImpression?: number | undefined,
	eventSearchInternalClick?: number | undefined,
	eventListImpression?: number | undefined,
	latestEventInternalClick?: number | undefined,
	latestEventImpression?: number | undefined,
	topTenEventRankingInternalClick?: number | undefined,
	topTenImpression?: number | undefined,
	editorChoiceInternalClick?: number | undefined,
	editorChoiceImpression?: number | undefined,
	otherEventRankingInternalClick?: number | undefined,
	otherEventRankingImpression?: number | undefined,
	bannerInternalClick?: number | undefined,
	miscImpression?: number | undefined,
	miscInternalClick?: number | undefined,
	totalImpression?: number | undefined,
	internalClick?: number | undefined,
	outboundClick?: number | undefined,
	externalClick?: number | undefined,
	_viewsCountByMonth?: number | undefined,
	products?: Array<ModelTypes["Event_Products"]> | undefined,
	legacyGallery?: Array<ModelTypes["Media"]> | undefined,
	earliestStartDate?: ModelTypes["DateTime"] | undefined,
	latestEndDate?: ModelTypes["DateTime"] | undefined,
	latestStartDateTime?: ModelTypes["DateTime"] | undefined,
	hasTicketing?: boolean | undefined,
	hasProducts?: boolean | undefined,
	updatedAt?: ModelTypes["DateTime"] | undefined,
	createdAt?: ModelTypes["DateTime"] | undefined
};
	["Event_region"]:Event_region;
	["User"]: {
		id?: string | undefined,
	region?: ModelTypes["User_region"] | undefined,
	name?: string | undefined,
	displayEmail?: string | undefined,
	legacyUsername?: string | undefined,
	roles: Array<ModelTypes["User_roles"]>,
	member?: ModelTypes["User_Member"] | undefined,
	organiser?: ModelTypes["User_Organiser"] | undefined,
	emailVerified?: boolean | undefined,
	sso?: ModelTypes["User_Sso"] | undefined,
	oldPassword?: string | undefined,
	status?: ModelTypes["User_status"] | undefined,
	updatedAt?: ModelTypes["DateTime"] | undefined,
	createdAt?: ModelTypes["DateTime"] | undefined,
	email: ModelTypes["EmailAddress"],
	resetPasswordToken?: string | undefined,
	resetPasswordExpiration?: ModelTypes["DateTime"] | undefined,
	salt?: string | undefined,
	hash?: string | undefined,
	loginAttempts?: number | undefined,
	lockUntil?: ModelTypes["DateTime"] | undefined,
	password: string
};
	["User_region"]:User_region;
	["User_roles"]:User_roles;
	["User_Member"]: {
		region?: ModelTypes["User_Member_region"] | undefined,
	language?: ModelTypes["User_Member_language"] | undefined,
	phone?: string | undefined,
	avatar?: ModelTypes["Media"] | undefined,
	birthday?: ModelTypes["DateTime"] | undefined,
	gender?: ModelTypes["User_Member_gender"] | undefined,
	categories?: Array<ModelTypes["EventCategory"]> | undefined
};
	["User_Member_region"]:User_Member_region;
	["User_Member_language"]:User_Member_language;
	["Media"]: {
		id?: string | undefined,
	alt?: string | undefined,
	updatedAt?: ModelTypes["DateTime"] | undefined,
	createdAt?: ModelTypes["DateTime"] | undefined,
	url?: string | undefined,
	filename?: string | undefined,
	mimeType?: string | undefined,
	filesize?: number | undefined,
	width?: number | undefined,
	height?: number | undefined,
	focalX?: number | undefined,
	focalY?: number | undefined,
	sizes?: ModelTypes["Media_Sizes"] | undefined
};
	/** A date-time string at UTC, such as 2007-12-03T10:15:30Z, compliant with the `date-time` format outlined in section 5.6 of the RFC 3339 profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar. */
["DateTime"]:any;
	["Media_Sizes"]: {
		small?: ModelTypes["Media_Sizes_Small"] | undefined,
	medium?: ModelTypes["Media_Sizes_Medium"] | undefined,
	large?: ModelTypes["Media_Sizes_Large"] | undefined,
	small_jpeg?: ModelTypes["Media_Sizes_Small_jpeg"] | undefined,
	medium_jpeg?: ModelTypes["Media_Sizes_Medium_jpeg"] | undefined,
	large_jpeg?: ModelTypes["Media_Sizes_Large_jpeg"] | undefined
};
	["Media_Sizes_Small"]: {
		url?: string | undefined,
	width?: number | undefined,
	height?: number | undefined,
	mimeType?: string | undefined,
	filesize?: number | undefined,
	filename?: string | undefined
};
	["Media_Sizes_Medium"]: {
		url?: string | undefined,
	width?: number | undefined,
	height?: number | undefined,
	mimeType?: string | undefined,
	filesize?: number | undefined,
	filename?: string | undefined
};
	["Media_Sizes_Large"]: {
		url?: string | undefined,
	width?: number | undefined,
	height?: number | undefined,
	mimeType?: string | undefined,
	filesize?: number | undefined,
	filename?: string | undefined
};
	["Media_Sizes_Small_jpeg"]: {
		url?: string | undefined,
	width?: number | undefined,
	height?: number | undefined,
	mimeType?: string | undefined,
	filesize?: number | undefined,
	filename?: string | undefined
};
	["Media_Sizes_Medium_jpeg"]: {
		url?: string | undefined,
	width?: number | undefined,
	height?: number | undefined,
	mimeType?: string | undefined,
	filesize?: number | undefined,
	filename?: string | undefined
};
	["Media_Sizes_Large_jpeg"]: {
		url?: string | undefined,
	width?: number | undefined,
	height?: number | undefined,
	mimeType?: string | undefined,
	filesize?: number | undefined,
	filename?: string | undefined
};
	["LocaleInputType"]:LocaleInputType;
	["FallbackLocaleInputType"]:FallbackLocaleInputType;
	["User_Member_Avatar_where"]: {
	alt?: ModelTypes["User_Member_Avatar_alt_operator"] | undefined,
	updatedAt?: ModelTypes["User_Member_Avatar_updatedAt_operator"] | undefined,
	createdAt?: ModelTypes["User_Member_Avatar_createdAt_operator"] | undefined,
	url?: ModelTypes["User_Member_Avatar_url_operator"] | undefined,
	filename?: ModelTypes["User_Member_Avatar_filename_operator"] | undefined,
	mimeType?: ModelTypes["User_Member_Avatar_mimeType_operator"] | undefined,
	filesize?: ModelTypes["User_Member_Avatar_filesize_operator"] | undefined,
	width?: ModelTypes["User_Member_Avatar_width_operator"] | undefined,
	height?: ModelTypes["User_Member_Avatar_height_operator"] | undefined,
	focalX?: ModelTypes["User_Member_Avatar_focalX_operator"] | undefined,
	focalY?: ModelTypes["User_Member_Avatar_focalY_operator"] | undefined,
	sizes__small__url?: ModelTypes["User_Member_Avatar_sizes__small__url_operator"] | undefined,
	sizes__small__width?: ModelTypes["User_Member_Avatar_sizes__small__width_operator"] | undefined,
	sizes__small__height?: ModelTypes["User_Member_Avatar_sizes__small__height_operator"] | undefined,
	sizes__small__mimeType?: ModelTypes["User_Member_Avatar_sizes__small__mimeType_operator"] | undefined,
	sizes__small__filesize?: ModelTypes["User_Member_Avatar_sizes__small__filesize_operator"] | undefined,
	sizes__small__filename?: ModelTypes["User_Member_Avatar_sizes__small__filename_operator"] | undefined,
	sizes__medium__url?: ModelTypes["User_Member_Avatar_sizes__medium__url_operator"] | undefined,
	sizes__medium__width?: ModelTypes["User_Member_Avatar_sizes__medium__width_operator"] | undefined,
	sizes__medium__height?: ModelTypes["User_Member_Avatar_sizes__medium__height_operator"] | undefined,
	sizes__medium__mimeType?: ModelTypes["User_Member_Avatar_sizes__medium__mimeType_operator"] | undefined,
	sizes__medium__filesize?: ModelTypes["User_Member_Avatar_sizes__medium__filesize_operator"] | undefined,
	sizes__medium__filename?: ModelTypes["User_Member_Avatar_sizes__medium__filename_operator"] | undefined,
	sizes__large__url?: ModelTypes["User_Member_Avatar_sizes__large__url_operator"] | undefined,
	sizes__large__width?: ModelTypes["User_Member_Avatar_sizes__large__width_operator"] | undefined,
	sizes__large__height?: ModelTypes["User_Member_Avatar_sizes__large__height_operator"] | undefined,
	sizes__large__mimeType?: ModelTypes["User_Member_Avatar_sizes__large__mimeType_operator"] | undefined,
	sizes__large__filesize?: ModelTypes["User_Member_Avatar_sizes__large__filesize_operator"] | undefined,
	sizes__large__filename?: ModelTypes["User_Member_Avatar_sizes__large__filename_operator"] | undefined,
	sizes__small_jpeg__url?: ModelTypes["User_Member_Avatar_sizes__small_jpeg__url_operator"] | undefined,
	sizes__small_jpeg__width?: ModelTypes["User_Member_Avatar_sizes__small_jpeg__width_operator"] | undefined,
	sizes__small_jpeg__height?: ModelTypes["User_Member_Avatar_sizes__small_jpeg__height_operator"] | undefined,
	sizes__small_jpeg__mimeType?: ModelTypes["User_Member_Avatar_sizes__small_jpeg__mimeType_operator"] | undefined,
	sizes__small_jpeg__filesize?: ModelTypes["User_Member_Avatar_sizes__small_jpeg__filesize_operator"] | undefined,
	sizes__small_jpeg__filename?: ModelTypes["User_Member_Avatar_sizes__small_jpeg__filename_operator"] | undefined,
	sizes__medium_jpeg__url?: ModelTypes["User_Member_Avatar_sizes__medium_jpeg__url_operator"] | undefined,
	sizes__medium_jpeg__width?: ModelTypes["User_Member_Avatar_sizes__medium_jpeg__width_operator"] | undefined,
	sizes__medium_jpeg__height?: ModelTypes["User_Member_Avatar_sizes__medium_jpeg__height_operator"] | undefined,
	sizes__medium_jpeg__mimeType?: ModelTypes["User_Member_Avatar_sizes__medium_jpeg__mimeType_operator"] | undefined,
	sizes__medium_jpeg__filesize?: ModelTypes["User_Member_Avatar_sizes__medium_jpeg__filesize_operator"] | undefined,
	sizes__medium_jpeg__filename?: ModelTypes["User_Member_Avatar_sizes__medium_jpeg__filename_operator"] | undefined,
	sizes__large_jpeg__url?: ModelTypes["User_Member_Avatar_sizes__large_jpeg__url_operator"] | undefined,
	sizes__large_jpeg__width?: ModelTypes["User_Member_Avatar_sizes__large_jpeg__width_operator"] | undefined,
	sizes__large_jpeg__height?: ModelTypes["User_Member_Avatar_sizes__large_jpeg__height_operator"] | undefined,
	sizes__large_jpeg__mimeType?: ModelTypes["User_Member_Avatar_sizes__large_jpeg__mimeType_operator"] | undefined,
	sizes__large_jpeg__filesize?: ModelTypes["User_Member_Avatar_sizes__large_jpeg__filesize_operator"] | undefined,
	sizes__large_jpeg__filename?: ModelTypes["User_Member_Avatar_sizes__large_jpeg__filename_operator"] | undefined,
	id?: ModelTypes["User_Member_Avatar_id_operator"] | undefined,
	AND?: Array<ModelTypes["User_Member_Avatar_where_and"] | undefined> | undefined,
	OR?: Array<ModelTypes["User_Member_Avatar_where_or"] | undefined> | undefined
};
	["User_Member_Avatar_alt_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["User_Member_Avatar_updatedAt_operator"]: {
	equals?: ModelTypes["DateTime"] | undefined,
	not_equals?: ModelTypes["DateTime"] | undefined,
	greater_than_equal?: ModelTypes["DateTime"] | undefined,
	greater_than?: ModelTypes["DateTime"] | undefined,
	less_than_equal?: ModelTypes["DateTime"] | undefined,
	less_than?: ModelTypes["DateTime"] | undefined,
	like?: ModelTypes["DateTime"] | undefined,
	exists?: boolean | undefined
};
	["User_Member_Avatar_createdAt_operator"]: {
	equals?: ModelTypes["DateTime"] | undefined,
	not_equals?: ModelTypes["DateTime"] | undefined,
	greater_than_equal?: ModelTypes["DateTime"] | undefined,
	greater_than?: ModelTypes["DateTime"] | undefined,
	less_than_equal?: ModelTypes["DateTime"] | undefined,
	less_than?: ModelTypes["DateTime"] | undefined,
	like?: ModelTypes["DateTime"] | undefined,
	exists?: boolean | undefined
};
	["User_Member_Avatar_url_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["User_Member_Avatar_filename_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["User_Member_Avatar_mimeType_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["User_Member_Avatar_filesize_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["User_Member_Avatar_width_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["User_Member_Avatar_height_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["User_Member_Avatar_focalX_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["User_Member_Avatar_focalY_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["User_Member_Avatar_sizes__small__url_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["User_Member_Avatar_sizes__small__width_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["User_Member_Avatar_sizes__small__height_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["User_Member_Avatar_sizes__small__mimeType_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["User_Member_Avatar_sizes__small__filesize_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["User_Member_Avatar_sizes__small__filename_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["User_Member_Avatar_sizes__medium__url_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["User_Member_Avatar_sizes__medium__width_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["User_Member_Avatar_sizes__medium__height_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["User_Member_Avatar_sizes__medium__mimeType_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["User_Member_Avatar_sizes__medium__filesize_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["User_Member_Avatar_sizes__medium__filename_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["User_Member_Avatar_sizes__large__url_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["User_Member_Avatar_sizes__large__width_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["User_Member_Avatar_sizes__large__height_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["User_Member_Avatar_sizes__large__mimeType_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["User_Member_Avatar_sizes__large__filesize_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["User_Member_Avatar_sizes__large__filename_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["User_Member_Avatar_sizes__small_jpeg__url_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["User_Member_Avatar_sizes__small_jpeg__width_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["User_Member_Avatar_sizes__small_jpeg__height_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["User_Member_Avatar_sizes__small_jpeg__mimeType_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["User_Member_Avatar_sizes__small_jpeg__filesize_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["User_Member_Avatar_sizes__small_jpeg__filename_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["User_Member_Avatar_sizes__medium_jpeg__url_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["User_Member_Avatar_sizes__medium_jpeg__width_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["User_Member_Avatar_sizes__medium_jpeg__height_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["User_Member_Avatar_sizes__medium_jpeg__mimeType_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["User_Member_Avatar_sizes__medium_jpeg__filesize_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["User_Member_Avatar_sizes__medium_jpeg__filename_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["User_Member_Avatar_sizes__large_jpeg__url_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["User_Member_Avatar_sizes__large_jpeg__width_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["User_Member_Avatar_sizes__large_jpeg__height_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["User_Member_Avatar_sizes__large_jpeg__mimeType_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["User_Member_Avatar_sizes__large_jpeg__filesize_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["User_Member_Avatar_sizes__large_jpeg__filename_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["User_Member_Avatar_id_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["User_Member_Avatar_where_and"]: {
	alt?: ModelTypes["User_Member_Avatar_alt_operator"] | undefined,
	updatedAt?: ModelTypes["User_Member_Avatar_updatedAt_operator"] | undefined,
	createdAt?: ModelTypes["User_Member_Avatar_createdAt_operator"] | undefined,
	url?: ModelTypes["User_Member_Avatar_url_operator"] | undefined,
	filename?: ModelTypes["User_Member_Avatar_filename_operator"] | undefined,
	mimeType?: ModelTypes["User_Member_Avatar_mimeType_operator"] | undefined,
	filesize?: ModelTypes["User_Member_Avatar_filesize_operator"] | undefined,
	width?: ModelTypes["User_Member_Avatar_width_operator"] | undefined,
	height?: ModelTypes["User_Member_Avatar_height_operator"] | undefined,
	focalX?: ModelTypes["User_Member_Avatar_focalX_operator"] | undefined,
	focalY?: ModelTypes["User_Member_Avatar_focalY_operator"] | undefined,
	sizes__small__url?: ModelTypes["User_Member_Avatar_sizes__small__url_operator"] | undefined,
	sizes__small__width?: ModelTypes["User_Member_Avatar_sizes__small__width_operator"] | undefined,
	sizes__small__height?: ModelTypes["User_Member_Avatar_sizes__small__height_operator"] | undefined,
	sizes__small__mimeType?: ModelTypes["User_Member_Avatar_sizes__small__mimeType_operator"] | undefined,
	sizes__small__filesize?: ModelTypes["User_Member_Avatar_sizes__small__filesize_operator"] | undefined,
	sizes__small__filename?: ModelTypes["User_Member_Avatar_sizes__small__filename_operator"] | undefined,
	sizes__medium__url?: ModelTypes["User_Member_Avatar_sizes__medium__url_operator"] | undefined,
	sizes__medium__width?: ModelTypes["User_Member_Avatar_sizes__medium__width_operator"] | undefined,
	sizes__medium__height?: ModelTypes["User_Member_Avatar_sizes__medium__height_operator"] | undefined,
	sizes__medium__mimeType?: ModelTypes["User_Member_Avatar_sizes__medium__mimeType_operator"] | undefined,
	sizes__medium__filesize?: ModelTypes["User_Member_Avatar_sizes__medium__filesize_operator"] | undefined,
	sizes__medium__filename?: ModelTypes["User_Member_Avatar_sizes__medium__filename_operator"] | undefined,
	sizes__large__url?: ModelTypes["User_Member_Avatar_sizes__large__url_operator"] | undefined,
	sizes__large__width?: ModelTypes["User_Member_Avatar_sizes__large__width_operator"] | undefined,
	sizes__large__height?: ModelTypes["User_Member_Avatar_sizes__large__height_operator"] | undefined,
	sizes__large__mimeType?: ModelTypes["User_Member_Avatar_sizes__large__mimeType_operator"] | undefined,
	sizes__large__filesize?: ModelTypes["User_Member_Avatar_sizes__large__filesize_operator"] | undefined,
	sizes__large__filename?: ModelTypes["User_Member_Avatar_sizes__large__filename_operator"] | undefined,
	sizes__small_jpeg__url?: ModelTypes["User_Member_Avatar_sizes__small_jpeg__url_operator"] | undefined,
	sizes__small_jpeg__width?: ModelTypes["User_Member_Avatar_sizes__small_jpeg__width_operator"] | undefined,
	sizes__small_jpeg__height?: ModelTypes["User_Member_Avatar_sizes__small_jpeg__height_operator"] | undefined,
	sizes__small_jpeg__mimeType?: ModelTypes["User_Member_Avatar_sizes__small_jpeg__mimeType_operator"] | undefined,
	sizes__small_jpeg__filesize?: ModelTypes["User_Member_Avatar_sizes__small_jpeg__filesize_operator"] | undefined,
	sizes__small_jpeg__filename?: ModelTypes["User_Member_Avatar_sizes__small_jpeg__filename_operator"] | undefined,
	sizes__medium_jpeg__url?: ModelTypes["User_Member_Avatar_sizes__medium_jpeg__url_operator"] | undefined,
	sizes__medium_jpeg__width?: ModelTypes["User_Member_Avatar_sizes__medium_jpeg__width_operator"] | undefined,
	sizes__medium_jpeg__height?: ModelTypes["User_Member_Avatar_sizes__medium_jpeg__height_operator"] | undefined,
	sizes__medium_jpeg__mimeType?: ModelTypes["User_Member_Avatar_sizes__medium_jpeg__mimeType_operator"] | undefined,
	sizes__medium_jpeg__filesize?: ModelTypes["User_Member_Avatar_sizes__medium_jpeg__filesize_operator"] | undefined,
	sizes__medium_jpeg__filename?: ModelTypes["User_Member_Avatar_sizes__medium_jpeg__filename_operator"] | undefined,
	sizes__large_jpeg__url?: ModelTypes["User_Member_Avatar_sizes__large_jpeg__url_operator"] | undefined,
	sizes__large_jpeg__width?: ModelTypes["User_Member_Avatar_sizes__large_jpeg__width_operator"] | undefined,
	sizes__large_jpeg__height?: ModelTypes["User_Member_Avatar_sizes__large_jpeg__height_operator"] | undefined,
	sizes__large_jpeg__mimeType?: ModelTypes["User_Member_Avatar_sizes__large_jpeg__mimeType_operator"] | undefined,
	sizes__large_jpeg__filesize?: ModelTypes["User_Member_Avatar_sizes__large_jpeg__filesize_operator"] | undefined,
	sizes__large_jpeg__filename?: ModelTypes["User_Member_Avatar_sizes__large_jpeg__filename_operator"] | undefined,
	id?: ModelTypes["User_Member_Avatar_id_operator"] | undefined,
	AND?: Array<ModelTypes["User_Member_Avatar_where_and"] | undefined> | undefined,
	OR?: Array<ModelTypes["User_Member_Avatar_where_or"] | undefined> | undefined
};
	["User_Member_Avatar_where_or"]: {
	alt?: ModelTypes["User_Member_Avatar_alt_operator"] | undefined,
	updatedAt?: ModelTypes["User_Member_Avatar_updatedAt_operator"] | undefined,
	createdAt?: ModelTypes["User_Member_Avatar_createdAt_operator"] | undefined,
	url?: ModelTypes["User_Member_Avatar_url_operator"] | undefined,
	filename?: ModelTypes["User_Member_Avatar_filename_operator"] | undefined,
	mimeType?: ModelTypes["User_Member_Avatar_mimeType_operator"] | undefined,
	filesize?: ModelTypes["User_Member_Avatar_filesize_operator"] | undefined,
	width?: ModelTypes["User_Member_Avatar_width_operator"] | undefined,
	height?: ModelTypes["User_Member_Avatar_height_operator"] | undefined,
	focalX?: ModelTypes["User_Member_Avatar_focalX_operator"] | undefined,
	focalY?: ModelTypes["User_Member_Avatar_focalY_operator"] | undefined,
	sizes__small__url?: ModelTypes["User_Member_Avatar_sizes__small__url_operator"] | undefined,
	sizes__small__width?: ModelTypes["User_Member_Avatar_sizes__small__width_operator"] | undefined,
	sizes__small__height?: ModelTypes["User_Member_Avatar_sizes__small__height_operator"] | undefined,
	sizes__small__mimeType?: ModelTypes["User_Member_Avatar_sizes__small__mimeType_operator"] | undefined,
	sizes__small__filesize?: ModelTypes["User_Member_Avatar_sizes__small__filesize_operator"] | undefined,
	sizes__small__filename?: ModelTypes["User_Member_Avatar_sizes__small__filename_operator"] | undefined,
	sizes__medium__url?: ModelTypes["User_Member_Avatar_sizes__medium__url_operator"] | undefined,
	sizes__medium__width?: ModelTypes["User_Member_Avatar_sizes__medium__width_operator"] | undefined,
	sizes__medium__height?: ModelTypes["User_Member_Avatar_sizes__medium__height_operator"] | undefined,
	sizes__medium__mimeType?: ModelTypes["User_Member_Avatar_sizes__medium__mimeType_operator"] | undefined,
	sizes__medium__filesize?: ModelTypes["User_Member_Avatar_sizes__medium__filesize_operator"] | undefined,
	sizes__medium__filename?: ModelTypes["User_Member_Avatar_sizes__medium__filename_operator"] | undefined,
	sizes__large__url?: ModelTypes["User_Member_Avatar_sizes__large__url_operator"] | undefined,
	sizes__large__width?: ModelTypes["User_Member_Avatar_sizes__large__width_operator"] | undefined,
	sizes__large__height?: ModelTypes["User_Member_Avatar_sizes__large__height_operator"] | undefined,
	sizes__large__mimeType?: ModelTypes["User_Member_Avatar_sizes__large__mimeType_operator"] | undefined,
	sizes__large__filesize?: ModelTypes["User_Member_Avatar_sizes__large__filesize_operator"] | undefined,
	sizes__large__filename?: ModelTypes["User_Member_Avatar_sizes__large__filename_operator"] | undefined,
	sizes__small_jpeg__url?: ModelTypes["User_Member_Avatar_sizes__small_jpeg__url_operator"] | undefined,
	sizes__small_jpeg__width?: ModelTypes["User_Member_Avatar_sizes__small_jpeg__width_operator"] | undefined,
	sizes__small_jpeg__height?: ModelTypes["User_Member_Avatar_sizes__small_jpeg__height_operator"] | undefined,
	sizes__small_jpeg__mimeType?: ModelTypes["User_Member_Avatar_sizes__small_jpeg__mimeType_operator"] | undefined,
	sizes__small_jpeg__filesize?: ModelTypes["User_Member_Avatar_sizes__small_jpeg__filesize_operator"] | undefined,
	sizes__small_jpeg__filename?: ModelTypes["User_Member_Avatar_sizes__small_jpeg__filename_operator"] | undefined,
	sizes__medium_jpeg__url?: ModelTypes["User_Member_Avatar_sizes__medium_jpeg__url_operator"] | undefined,
	sizes__medium_jpeg__width?: ModelTypes["User_Member_Avatar_sizes__medium_jpeg__width_operator"] | undefined,
	sizes__medium_jpeg__height?: ModelTypes["User_Member_Avatar_sizes__medium_jpeg__height_operator"] | undefined,
	sizes__medium_jpeg__mimeType?: ModelTypes["User_Member_Avatar_sizes__medium_jpeg__mimeType_operator"] | undefined,
	sizes__medium_jpeg__filesize?: ModelTypes["User_Member_Avatar_sizes__medium_jpeg__filesize_operator"] | undefined,
	sizes__medium_jpeg__filename?: ModelTypes["User_Member_Avatar_sizes__medium_jpeg__filename_operator"] | undefined,
	sizes__large_jpeg__url?: ModelTypes["User_Member_Avatar_sizes__large_jpeg__url_operator"] | undefined,
	sizes__large_jpeg__width?: ModelTypes["User_Member_Avatar_sizes__large_jpeg__width_operator"] | undefined,
	sizes__large_jpeg__height?: ModelTypes["User_Member_Avatar_sizes__large_jpeg__height_operator"] | undefined,
	sizes__large_jpeg__mimeType?: ModelTypes["User_Member_Avatar_sizes__large_jpeg__mimeType_operator"] | undefined,
	sizes__large_jpeg__filesize?: ModelTypes["User_Member_Avatar_sizes__large_jpeg__filesize_operator"] | undefined,
	sizes__large_jpeg__filename?: ModelTypes["User_Member_Avatar_sizes__large_jpeg__filename_operator"] | undefined,
	id?: ModelTypes["User_Member_Avatar_id_operator"] | undefined,
	AND?: Array<ModelTypes["User_Member_Avatar_where_and"] | undefined> | undefined,
	OR?: Array<ModelTypes["User_Member_Avatar_where_or"] | undefined> | undefined
};
	["User_Member_gender"]:User_Member_gender;
	["EventCategory"]: {
		id?: string | undefined,
	name?: string | undefined,
	value: string,
	tags?: Array<ModelTypes["Tag"]> | undefined,
	icon?: ModelTypes["Media"] | undefined,
	region?: ModelTypes["EventCategory_region"] | undefined,
	updatedAt?: ModelTypes["DateTime"] | undefined,
	createdAt?: ModelTypes["DateTime"] | undefined
};
	["Tag"]: {
		id?: string | undefined,
	name: string,
	value: string,
	region?: ModelTypes["Tag_region"] | undefined,
	count?: number | undefined,
	skipCountCheck?: boolean | undefined,
	removeNextCron?: boolean | undefined
};
	["Tag_region"]:Tag_region;
	["EventCategory_Icon_where"]: {
	alt?: ModelTypes["EventCategory_Icon_alt_operator"] | undefined,
	updatedAt?: ModelTypes["EventCategory_Icon_updatedAt_operator"] | undefined,
	createdAt?: ModelTypes["EventCategory_Icon_createdAt_operator"] | undefined,
	url?: ModelTypes["EventCategory_Icon_url_operator"] | undefined,
	filename?: ModelTypes["EventCategory_Icon_filename_operator"] | undefined,
	mimeType?: ModelTypes["EventCategory_Icon_mimeType_operator"] | undefined,
	filesize?: ModelTypes["EventCategory_Icon_filesize_operator"] | undefined,
	width?: ModelTypes["EventCategory_Icon_width_operator"] | undefined,
	height?: ModelTypes["EventCategory_Icon_height_operator"] | undefined,
	focalX?: ModelTypes["EventCategory_Icon_focalX_operator"] | undefined,
	focalY?: ModelTypes["EventCategory_Icon_focalY_operator"] | undefined,
	sizes__small__url?: ModelTypes["EventCategory_Icon_sizes__small__url_operator"] | undefined,
	sizes__small__width?: ModelTypes["EventCategory_Icon_sizes__small__width_operator"] | undefined,
	sizes__small__height?: ModelTypes["EventCategory_Icon_sizes__small__height_operator"] | undefined,
	sizes__small__mimeType?: ModelTypes["EventCategory_Icon_sizes__small__mimeType_operator"] | undefined,
	sizes__small__filesize?: ModelTypes["EventCategory_Icon_sizes__small__filesize_operator"] | undefined,
	sizes__small__filename?: ModelTypes["EventCategory_Icon_sizes__small__filename_operator"] | undefined,
	sizes__medium__url?: ModelTypes["EventCategory_Icon_sizes__medium__url_operator"] | undefined,
	sizes__medium__width?: ModelTypes["EventCategory_Icon_sizes__medium__width_operator"] | undefined,
	sizes__medium__height?: ModelTypes["EventCategory_Icon_sizes__medium__height_operator"] | undefined,
	sizes__medium__mimeType?: ModelTypes["EventCategory_Icon_sizes__medium__mimeType_operator"] | undefined,
	sizes__medium__filesize?: ModelTypes["EventCategory_Icon_sizes__medium__filesize_operator"] | undefined,
	sizes__medium__filename?: ModelTypes["EventCategory_Icon_sizes__medium__filename_operator"] | undefined,
	sizes__large__url?: ModelTypes["EventCategory_Icon_sizes__large__url_operator"] | undefined,
	sizes__large__width?: ModelTypes["EventCategory_Icon_sizes__large__width_operator"] | undefined,
	sizes__large__height?: ModelTypes["EventCategory_Icon_sizes__large__height_operator"] | undefined,
	sizes__large__mimeType?: ModelTypes["EventCategory_Icon_sizes__large__mimeType_operator"] | undefined,
	sizes__large__filesize?: ModelTypes["EventCategory_Icon_sizes__large__filesize_operator"] | undefined,
	sizes__large__filename?: ModelTypes["EventCategory_Icon_sizes__large__filename_operator"] | undefined,
	sizes__small_jpeg__url?: ModelTypes["EventCategory_Icon_sizes__small_jpeg__url_operator"] | undefined,
	sizes__small_jpeg__width?: ModelTypes["EventCategory_Icon_sizes__small_jpeg__width_operator"] | undefined,
	sizes__small_jpeg__height?: ModelTypes["EventCategory_Icon_sizes__small_jpeg__height_operator"] | undefined,
	sizes__small_jpeg__mimeType?: ModelTypes["EventCategory_Icon_sizes__small_jpeg__mimeType_operator"] | undefined,
	sizes__small_jpeg__filesize?: ModelTypes["EventCategory_Icon_sizes__small_jpeg__filesize_operator"] | undefined,
	sizes__small_jpeg__filename?: ModelTypes["EventCategory_Icon_sizes__small_jpeg__filename_operator"] | undefined,
	sizes__medium_jpeg__url?: ModelTypes["EventCategory_Icon_sizes__medium_jpeg__url_operator"] | undefined,
	sizes__medium_jpeg__width?: ModelTypes["EventCategory_Icon_sizes__medium_jpeg__width_operator"] | undefined,
	sizes__medium_jpeg__height?: ModelTypes["EventCategory_Icon_sizes__medium_jpeg__height_operator"] | undefined,
	sizes__medium_jpeg__mimeType?: ModelTypes["EventCategory_Icon_sizes__medium_jpeg__mimeType_operator"] | undefined,
	sizes__medium_jpeg__filesize?: ModelTypes["EventCategory_Icon_sizes__medium_jpeg__filesize_operator"] | undefined,
	sizes__medium_jpeg__filename?: ModelTypes["EventCategory_Icon_sizes__medium_jpeg__filename_operator"] | undefined,
	sizes__large_jpeg__url?: ModelTypes["EventCategory_Icon_sizes__large_jpeg__url_operator"] | undefined,
	sizes__large_jpeg__width?: ModelTypes["EventCategory_Icon_sizes__large_jpeg__width_operator"] | undefined,
	sizes__large_jpeg__height?: ModelTypes["EventCategory_Icon_sizes__large_jpeg__height_operator"] | undefined,
	sizes__large_jpeg__mimeType?: ModelTypes["EventCategory_Icon_sizes__large_jpeg__mimeType_operator"] | undefined,
	sizes__large_jpeg__filesize?: ModelTypes["EventCategory_Icon_sizes__large_jpeg__filesize_operator"] | undefined,
	sizes__large_jpeg__filename?: ModelTypes["EventCategory_Icon_sizes__large_jpeg__filename_operator"] | undefined,
	id?: ModelTypes["EventCategory_Icon_id_operator"] | undefined,
	AND?: Array<ModelTypes["EventCategory_Icon_where_and"] | undefined> | undefined,
	OR?: Array<ModelTypes["EventCategory_Icon_where_or"] | undefined> | undefined
};
	["EventCategory_Icon_alt_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["EventCategory_Icon_updatedAt_operator"]: {
	equals?: ModelTypes["DateTime"] | undefined,
	not_equals?: ModelTypes["DateTime"] | undefined,
	greater_than_equal?: ModelTypes["DateTime"] | undefined,
	greater_than?: ModelTypes["DateTime"] | undefined,
	less_than_equal?: ModelTypes["DateTime"] | undefined,
	less_than?: ModelTypes["DateTime"] | undefined,
	like?: ModelTypes["DateTime"] | undefined,
	exists?: boolean | undefined
};
	["EventCategory_Icon_createdAt_operator"]: {
	equals?: ModelTypes["DateTime"] | undefined,
	not_equals?: ModelTypes["DateTime"] | undefined,
	greater_than_equal?: ModelTypes["DateTime"] | undefined,
	greater_than?: ModelTypes["DateTime"] | undefined,
	less_than_equal?: ModelTypes["DateTime"] | undefined,
	less_than?: ModelTypes["DateTime"] | undefined,
	like?: ModelTypes["DateTime"] | undefined,
	exists?: boolean | undefined
};
	["EventCategory_Icon_url_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["EventCategory_Icon_filename_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["EventCategory_Icon_mimeType_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["EventCategory_Icon_filesize_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["EventCategory_Icon_width_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["EventCategory_Icon_height_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["EventCategory_Icon_focalX_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["EventCategory_Icon_focalY_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["EventCategory_Icon_sizes__small__url_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["EventCategory_Icon_sizes__small__width_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["EventCategory_Icon_sizes__small__height_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["EventCategory_Icon_sizes__small__mimeType_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["EventCategory_Icon_sizes__small__filesize_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["EventCategory_Icon_sizes__small__filename_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["EventCategory_Icon_sizes__medium__url_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["EventCategory_Icon_sizes__medium__width_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["EventCategory_Icon_sizes__medium__height_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["EventCategory_Icon_sizes__medium__mimeType_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["EventCategory_Icon_sizes__medium__filesize_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["EventCategory_Icon_sizes__medium__filename_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["EventCategory_Icon_sizes__large__url_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["EventCategory_Icon_sizes__large__width_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["EventCategory_Icon_sizes__large__height_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["EventCategory_Icon_sizes__large__mimeType_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["EventCategory_Icon_sizes__large__filesize_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["EventCategory_Icon_sizes__large__filename_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["EventCategory_Icon_sizes__small_jpeg__url_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["EventCategory_Icon_sizes__small_jpeg__width_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["EventCategory_Icon_sizes__small_jpeg__height_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["EventCategory_Icon_sizes__small_jpeg__mimeType_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["EventCategory_Icon_sizes__small_jpeg__filesize_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["EventCategory_Icon_sizes__small_jpeg__filename_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["EventCategory_Icon_sizes__medium_jpeg__url_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["EventCategory_Icon_sizes__medium_jpeg__width_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["EventCategory_Icon_sizes__medium_jpeg__height_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["EventCategory_Icon_sizes__medium_jpeg__mimeType_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["EventCategory_Icon_sizes__medium_jpeg__filesize_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["EventCategory_Icon_sizes__medium_jpeg__filename_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["EventCategory_Icon_sizes__large_jpeg__url_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["EventCategory_Icon_sizes__large_jpeg__width_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["EventCategory_Icon_sizes__large_jpeg__height_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["EventCategory_Icon_sizes__large_jpeg__mimeType_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["EventCategory_Icon_sizes__large_jpeg__filesize_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["EventCategory_Icon_sizes__large_jpeg__filename_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["EventCategory_Icon_id_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["EventCategory_Icon_where_and"]: {
	alt?: ModelTypes["EventCategory_Icon_alt_operator"] | undefined,
	updatedAt?: ModelTypes["EventCategory_Icon_updatedAt_operator"] | undefined,
	createdAt?: ModelTypes["EventCategory_Icon_createdAt_operator"] | undefined,
	url?: ModelTypes["EventCategory_Icon_url_operator"] | undefined,
	filename?: ModelTypes["EventCategory_Icon_filename_operator"] | undefined,
	mimeType?: ModelTypes["EventCategory_Icon_mimeType_operator"] | undefined,
	filesize?: ModelTypes["EventCategory_Icon_filesize_operator"] | undefined,
	width?: ModelTypes["EventCategory_Icon_width_operator"] | undefined,
	height?: ModelTypes["EventCategory_Icon_height_operator"] | undefined,
	focalX?: ModelTypes["EventCategory_Icon_focalX_operator"] | undefined,
	focalY?: ModelTypes["EventCategory_Icon_focalY_operator"] | undefined,
	sizes__small__url?: ModelTypes["EventCategory_Icon_sizes__small__url_operator"] | undefined,
	sizes__small__width?: ModelTypes["EventCategory_Icon_sizes__small__width_operator"] | undefined,
	sizes__small__height?: ModelTypes["EventCategory_Icon_sizes__small__height_operator"] | undefined,
	sizes__small__mimeType?: ModelTypes["EventCategory_Icon_sizes__small__mimeType_operator"] | undefined,
	sizes__small__filesize?: ModelTypes["EventCategory_Icon_sizes__small__filesize_operator"] | undefined,
	sizes__small__filename?: ModelTypes["EventCategory_Icon_sizes__small__filename_operator"] | undefined,
	sizes__medium__url?: ModelTypes["EventCategory_Icon_sizes__medium__url_operator"] | undefined,
	sizes__medium__width?: ModelTypes["EventCategory_Icon_sizes__medium__width_operator"] | undefined,
	sizes__medium__height?: ModelTypes["EventCategory_Icon_sizes__medium__height_operator"] | undefined,
	sizes__medium__mimeType?: ModelTypes["EventCategory_Icon_sizes__medium__mimeType_operator"] | undefined,
	sizes__medium__filesize?: ModelTypes["EventCategory_Icon_sizes__medium__filesize_operator"] | undefined,
	sizes__medium__filename?: ModelTypes["EventCategory_Icon_sizes__medium__filename_operator"] | undefined,
	sizes__large__url?: ModelTypes["EventCategory_Icon_sizes__large__url_operator"] | undefined,
	sizes__large__width?: ModelTypes["EventCategory_Icon_sizes__large__width_operator"] | undefined,
	sizes__large__height?: ModelTypes["EventCategory_Icon_sizes__large__height_operator"] | undefined,
	sizes__large__mimeType?: ModelTypes["EventCategory_Icon_sizes__large__mimeType_operator"] | undefined,
	sizes__large__filesize?: ModelTypes["EventCategory_Icon_sizes__large__filesize_operator"] | undefined,
	sizes__large__filename?: ModelTypes["EventCategory_Icon_sizes__large__filename_operator"] | undefined,
	sizes__small_jpeg__url?: ModelTypes["EventCategory_Icon_sizes__small_jpeg__url_operator"] | undefined,
	sizes__small_jpeg__width?: ModelTypes["EventCategory_Icon_sizes__small_jpeg__width_operator"] | undefined,
	sizes__small_jpeg__height?: ModelTypes["EventCategory_Icon_sizes__small_jpeg__height_operator"] | undefined,
	sizes__small_jpeg__mimeType?: ModelTypes["EventCategory_Icon_sizes__small_jpeg__mimeType_operator"] | undefined,
	sizes__small_jpeg__filesize?: ModelTypes["EventCategory_Icon_sizes__small_jpeg__filesize_operator"] | undefined,
	sizes__small_jpeg__filename?: ModelTypes["EventCategory_Icon_sizes__small_jpeg__filename_operator"] | undefined,
	sizes__medium_jpeg__url?: ModelTypes["EventCategory_Icon_sizes__medium_jpeg__url_operator"] | undefined,
	sizes__medium_jpeg__width?: ModelTypes["EventCategory_Icon_sizes__medium_jpeg__width_operator"] | undefined,
	sizes__medium_jpeg__height?: ModelTypes["EventCategory_Icon_sizes__medium_jpeg__height_operator"] | undefined,
	sizes__medium_jpeg__mimeType?: ModelTypes["EventCategory_Icon_sizes__medium_jpeg__mimeType_operator"] | undefined,
	sizes__medium_jpeg__filesize?: ModelTypes["EventCategory_Icon_sizes__medium_jpeg__filesize_operator"] | undefined,
	sizes__medium_jpeg__filename?: ModelTypes["EventCategory_Icon_sizes__medium_jpeg__filename_operator"] | undefined,
	sizes__large_jpeg__url?: ModelTypes["EventCategory_Icon_sizes__large_jpeg__url_operator"] | undefined,
	sizes__large_jpeg__width?: ModelTypes["EventCategory_Icon_sizes__large_jpeg__width_operator"] | undefined,
	sizes__large_jpeg__height?: ModelTypes["EventCategory_Icon_sizes__large_jpeg__height_operator"] | undefined,
	sizes__large_jpeg__mimeType?: ModelTypes["EventCategory_Icon_sizes__large_jpeg__mimeType_operator"] | undefined,
	sizes__large_jpeg__filesize?: ModelTypes["EventCategory_Icon_sizes__large_jpeg__filesize_operator"] | undefined,
	sizes__large_jpeg__filename?: ModelTypes["EventCategory_Icon_sizes__large_jpeg__filename_operator"] | undefined,
	id?: ModelTypes["EventCategory_Icon_id_operator"] | undefined,
	AND?: Array<ModelTypes["EventCategory_Icon_where_and"] | undefined> | undefined,
	OR?: Array<ModelTypes["EventCategory_Icon_where_or"] | undefined> | undefined
};
	["EventCategory_Icon_where_or"]: {
	alt?: ModelTypes["EventCategory_Icon_alt_operator"] | undefined,
	updatedAt?: ModelTypes["EventCategory_Icon_updatedAt_operator"] | undefined,
	createdAt?: ModelTypes["EventCategory_Icon_createdAt_operator"] | undefined,
	url?: ModelTypes["EventCategory_Icon_url_operator"] | undefined,
	filename?: ModelTypes["EventCategory_Icon_filename_operator"] | undefined,
	mimeType?: ModelTypes["EventCategory_Icon_mimeType_operator"] | undefined,
	filesize?: ModelTypes["EventCategory_Icon_filesize_operator"] | undefined,
	width?: ModelTypes["EventCategory_Icon_width_operator"] | undefined,
	height?: ModelTypes["EventCategory_Icon_height_operator"] | undefined,
	focalX?: ModelTypes["EventCategory_Icon_focalX_operator"] | undefined,
	focalY?: ModelTypes["EventCategory_Icon_focalY_operator"] | undefined,
	sizes__small__url?: ModelTypes["EventCategory_Icon_sizes__small__url_operator"] | undefined,
	sizes__small__width?: ModelTypes["EventCategory_Icon_sizes__small__width_operator"] | undefined,
	sizes__small__height?: ModelTypes["EventCategory_Icon_sizes__small__height_operator"] | undefined,
	sizes__small__mimeType?: ModelTypes["EventCategory_Icon_sizes__small__mimeType_operator"] | undefined,
	sizes__small__filesize?: ModelTypes["EventCategory_Icon_sizes__small__filesize_operator"] | undefined,
	sizes__small__filename?: ModelTypes["EventCategory_Icon_sizes__small__filename_operator"] | undefined,
	sizes__medium__url?: ModelTypes["EventCategory_Icon_sizes__medium__url_operator"] | undefined,
	sizes__medium__width?: ModelTypes["EventCategory_Icon_sizes__medium__width_operator"] | undefined,
	sizes__medium__height?: ModelTypes["EventCategory_Icon_sizes__medium__height_operator"] | undefined,
	sizes__medium__mimeType?: ModelTypes["EventCategory_Icon_sizes__medium__mimeType_operator"] | undefined,
	sizes__medium__filesize?: ModelTypes["EventCategory_Icon_sizes__medium__filesize_operator"] | undefined,
	sizes__medium__filename?: ModelTypes["EventCategory_Icon_sizes__medium__filename_operator"] | undefined,
	sizes__large__url?: ModelTypes["EventCategory_Icon_sizes__large__url_operator"] | undefined,
	sizes__large__width?: ModelTypes["EventCategory_Icon_sizes__large__width_operator"] | undefined,
	sizes__large__height?: ModelTypes["EventCategory_Icon_sizes__large__height_operator"] | undefined,
	sizes__large__mimeType?: ModelTypes["EventCategory_Icon_sizes__large__mimeType_operator"] | undefined,
	sizes__large__filesize?: ModelTypes["EventCategory_Icon_sizes__large__filesize_operator"] | undefined,
	sizes__large__filename?: ModelTypes["EventCategory_Icon_sizes__large__filename_operator"] | undefined,
	sizes__small_jpeg__url?: ModelTypes["EventCategory_Icon_sizes__small_jpeg__url_operator"] | undefined,
	sizes__small_jpeg__width?: ModelTypes["EventCategory_Icon_sizes__small_jpeg__width_operator"] | undefined,
	sizes__small_jpeg__height?: ModelTypes["EventCategory_Icon_sizes__small_jpeg__height_operator"] | undefined,
	sizes__small_jpeg__mimeType?: ModelTypes["EventCategory_Icon_sizes__small_jpeg__mimeType_operator"] | undefined,
	sizes__small_jpeg__filesize?: ModelTypes["EventCategory_Icon_sizes__small_jpeg__filesize_operator"] | undefined,
	sizes__small_jpeg__filename?: ModelTypes["EventCategory_Icon_sizes__small_jpeg__filename_operator"] | undefined,
	sizes__medium_jpeg__url?: ModelTypes["EventCategory_Icon_sizes__medium_jpeg__url_operator"] | undefined,
	sizes__medium_jpeg__width?: ModelTypes["EventCategory_Icon_sizes__medium_jpeg__width_operator"] | undefined,
	sizes__medium_jpeg__height?: ModelTypes["EventCategory_Icon_sizes__medium_jpeg__height_operator"] | undefined,
	sizes__medium_jpeg__mimeType?: ModelTypes["EventCategory_Icon_sizes__medium_jpeg__mimeType_operator"] | undefined,
	sizes__medium_jpeg__filesize?: ModelTypes["EventCategory_Icon_sizes__medium_jpeg__filesize_operator"] | undefined,
	sizes__medium_jpeg__filename?: ModelTypes["EventCategory_Icon_sizes__medium_jpeg__filename_operator"] | undefined,
	sizes__large_jpeg__url?: ModelTypes["EventCategory_Icon_sizes__large_jpeg__url_operator"] | undefined,
	sizes__large_jpeg__width?: ModelTypes["EventCategory_Icon_sizes__large_jpeg__width_operator"] | undefined,
	sizes__large_jpeg__height?: ModelTypes["EventCategory_Icon_sizes__large_jpeg__height_operator"] | undefined,
	sizes__large_jpeg__mimeType?: ModelTypes["EventCategory_Icon_sizes__large_jpeg__mimeType_operator"] | undefined,
	sizes__large_jpeg__filesize?: ModelTypes["EventCategory_Icon_sizes__large_jpeg__filesize_operator"] | undefined,
	sizes__large_jpeg__filename?: ModelTypes["EventCategory_Icon_sizes__large_jpeg__filename_operator"] | undefined,
	id?: ModelTypes["EventCategory_Icon_id_operator"] | undefined,
	AND?: Array<ModelTypes["EventCategory_Icon_where_and"] | undefined> | undefined,
	OR?: Array<ModelTypes["EventCategory_Icon_where_or"] | undefined> | undefined
};
	["EventCategory_region"]:EventCategory_region;
	["User_Organiser"]: {
		logo?: ModelTypes["Media"] | undefined,
	banner?: ModelTypes["Media"] | undefined,
	description?: string | undefined,
	foundedIn?: ModelTypes["DateTime"] | undefined,
	address?: string | undefined,
	website?: string | undefined,
	facebook?: string | undefined,
	instagram?: string | undefined,
	language?: ModelTypes["User_Organiser_language"] | undefined,
	phone?: string | undefined,
	followers?: number | undefined
};
	["User_Organiser_Logo_where"]: {
	alt?: ModelTypes["User_Organiser_Logo_alt_operator"] | undefined,
	updatedAt?: ModelTypes["User_Organiser_Logo_updatedAt_operator"] | undefined,
	createdAt?: ModelTypes["User_Organiser_Logo_createdAt_operator"] | undefined,
	url?: ModelTypes["User_Organiser_Logo_url_operator"] | undefined,
	filename?: ModelTypes["User_Organiser_Logo_filename_operator"] | undefined,
	mimeType?: ModelTypes["User_Organiser_Logo_mimeType_operator"] | undefined,
	filesize?: ModelTypes["User_Organiser_Logo_filesize_operator"] | undefined,
	width?: ModelTypes["User_Organiser_Logo_width_operator"] | undefined,
	height?: ModelTypes["User_Organiser_Logo_height_operator"] | undefined,
	focalX?: ModelTypes["User_Organiser_Logo_focalX_operator"] | undefined,
	focalY?: ModelTypes["User_Organiser_Logo_focalY_operator"] | undefined,
	sizes__small__url?: ModelTypes["User_Organiser_Logo_sizes__small__url_operator"] | undefined,
	sizes__small__width?: ModelTypes["User_Organiser_Logo_sizes__small__width_operator"] | undefined,
	sizes__small__height?: ModelTypes["User_Organiser_Logo_sizes__small__height_operator"] | undefined,
	sizes__small__mimeType?: ModelTypes["User_Organiser_Logo_sizes__small__mimeType_operator"] | undefined,
	sizes__small__filesize?: ModelTypes["User_Organiser_Logo_sizes__small__filesize_operator"] | undefined,
	sizes__small__filename?: ModelTypes["User_Organiser_Logo_sizes__small__filename_operator"] | undefined,
	sizes__medium__url?: ModelTypes["User_Organiser_Logo_sizes__medium__url_operator"] | undefined,
	sizes__medium__width?: ModelTypes["User_Organiser_Logo_sizes__medium__width_operator"] | undefined,
	sizes__medium__height?: ModelTypes["User_Organiser_Logo_sizes__medium__height_operator"] | undefined,
	sizes__medium__mimeType?: ModelTypes["User_Organiser_Logo_sizes__medium__mimeType_operator"] | undefined,
	sizes__medium__filesize?: ModelTypes["User_Organiser_Logo_sizes__medium__filesize_operator"] | undefined,
	sizes__medium__filename?: ModelTypes["User_Organiser_Logo_sizes__medium__filename_operator"] | undefined,
	sizes__large__url?: ModelTypes["User_Organiser_Logo_sizes__large__url_operator"] | undefined,
	sizes__large__width?: ModelTypes["User_Organiser_Logo_sizes__large__width_operator"] | undefined,
	sizes__large__height?: ModelTypes["User_Organiser_Logo_sizes__large__height_operator"] | undefined,
	sizes__large__mimeType?: ModelTypes["User_Organiser_Logo_sizes__large__mimeType_operator"] | undefined,
	sizes__large__filesize?: ModelTypes["User_Organiser_Logo_sizes__large__filesize_operator"] | undefined,
	sizes__large__filename?: ModelTypes["User_Organiser_Logo_sizes__large__filename_operator"] | undefined,
	sizes__small_jpeg__url?: ModelTypes["User_Organiser_Logo_sizes__small_jpeg__url_operator"] | undefined,
	sizes__small_jpeg__width?: ModelTypes["User_Organiser_Logo_sizes__small_jpeg__width_operator"] | undefined,
	sizes__small_jpeg__height?: ModelTypes["User_Organiser_Logo_sizes__small_jpeg__height_operator"] | undefined,
	sizes__small_jpeg__mimeType?: ModelTypes["User_Organiser_Logo_sizes__small_jpeg__mimeType_operator"] | undefined,
	sizes__small_jpeg__filesize?: ModelTypes["User_Organiser_Logo_sizes__small_jpeg__filesize_operator"] | undefined,
	sizes__small_jpeg__filename?: ModelTypes["User_Organiser_Logo_sizes__small_jpeg__filename_operator"] | undefined,
	sizes__medium_jpeg__url?: ModelTypes["User_Organiser_Logo_sizes__medium_jpeg__url_operator"] | undefined,
	sizes__medium_jpeg__width?: ModelTypes["User_Organiser_Logo_sizes__medium_jpeg__width_operator"] | undefined,
	sizes__medium_jpeg__height?: ModelTypes["User_Organiser_Logo_sizes__medium_jpeg__height_operator"] | undefined,
	sizes__medium_jpeg__mimeType?: ModelTypes["User_Organiser_Logo_sizes__medium_jpeg__mimeType_operator"] | undefined,
	sizes__medium_jpeg__filesize?: ModelTypes["User_Organiser_Logo_sizes__medium_jpeg__filesize_operator"] | undefined,
	sizes__medium_jpeg__filename?: ModelTypes["User_Organiser_Logo_sizes__medium_jpeg__filename_operator"] | undefined,
	sizes__large_jpeg__url?: ModelTypes["User_Organiser_Logo_sizes__large_jpeg__url_operator"] | undefined,
	sizes__large_jpeg__width?: ModelTypes["User_Organiser_Logo_sizes__large_jpeg__width_operator"] | undefined,
	sizes__large_jpeg__height?: ModelTypes["User_Organiser_Logo_sizes__large_jpeg__height_operator"] | undefined,
	sizes__large_jpeg__mimeType?: ModelTypes["User_Organiser_Logo_sizes__large_jpeg__mimeType_operator"] | undefined,
	sizes__large_jpeg__filesize?: ModelTypes["User_Organiser_Logo_sizes__large_jpeg__filesize_operator"] | undefined,
	sizes__large_jpeg__filename?: ModelTypes["User_Organiser_Logo_sizes__large_jpeg__filename_operator"] | undefined,
	id?: ModelTypes["User_Organiser_Logo_id_operator"] | undefined,
	AND?: Array<ModelTypes["User_Organiser_Logo_where_and"] | undefined> | undefined,
	OR?: Array<ModelTypes["User_Organiser_Logo_where_or"] | undefined> | undefined
};
	["User_Organiser_Logo_alt_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["User_Organiser_Logo_updatedAt_operator"]: {
	equals?: ModelTypes["DateTime"] | undefined,
	not_equals?: ModelTypes["DateTime"] | undefined,
	greater_than_equal?: ModelTypes["DateTime"] | undefined,
	greater_than?: ModelTypes["DateTime"] | undefined,
	less_than_equal?: ModelTypes["DateTime"] | undefined,
	less_than?: ModelTypes["DateTime"] | undefined,
	like?: ModelTypes["DateTime"] | undefined,
	exists?: boolean | undefined
};
	["User_Organiser_Logo_createdAt_operator"]: {
	equals?: ModelTypes["DateTime"] | undefined,
	not_equals?: ModelTypes["DateTime"] | undefined,
	greater_than_equal?: ModelTypes["DateTime"] | undefined,
	greater_than?: ModelTypes["DateTime"] | undefined,
	less_than_equal?: ModelTypes["DateTime"] | undefined,
	less_than?: ModelTypes["DateTime"] | undefined,
	like?: ModelTypes["DateTime"] | undefined,
	exists?: boolean | undefined
};
	["User_Organiser_Logo_url_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["User_Organiser_Logo_filename_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["User_Organiser_Logo_mimeType_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["User_Organiser_Logo_filesize_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["User_Organiser_Logo_width_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["User_Organiser_Logo_height_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["User_Organiser_Logo_focalX_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["User_Organiser_Logo_focalY_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["User_Organiser_Logo_sizes__small__url_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["User_Organiser_Logo_sizes__small__width_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["User_Organiser_Logo_sizes__small__height_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["User_Organiser_Logo_sizes__small__mimeType_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["User_Organiser_Logo_sizes__small__filesize_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["User_Organiser_Logo_sizes__small__filename_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["User_Organiser_Logo_sizes__medium__url_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["User_Organiser_Logo_sizes__medium__width_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["User_Organiser_Logo_sizes__medium__height_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["User_Organiser_Logo_sizes__medium__mimeType_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["User_Organiser_Logo_sizes__medium__filesize_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["User_Organiser_Logo_sizes__medium__filename_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["User_Organiser_Logo_sizes__large__url_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["User_Organiser_Logo_sizes__large__width_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["User_Organiser_Logo_sizes__large__height_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["User_Organiser_Logo_sizes__large__mimeType_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["User_Organiser_Logo_sizes__large__filesize_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["User_Organiser_Logo_sizes__large__filename_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["User_Organiser_Logo_sizes__small_jpeg__url_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["User_Organiser_Logo_sizes__small_jpeg__width_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["User_Organiser_Logo_sizes__small_jpeg__height_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["User_Organiser_Logo_sizes__small_jpeg__mimeType_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["User_Organiser_Logo_sizes__small_jpeg__filesize_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["User_Organiser_Logo_sizes__small_jpeg__filename_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["User_Organiser_Logo_sizes__medium_jpeg__url_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["User_Organiser_Logo_sizes__medium_jpeg__width_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["User_Organiser_Logo_sizes__medium_jpeg__height_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["User_Organiser_Logo_sizes__medium_jpeg__mimeType_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["User_Organiser_Logo_sizes__medium_jpeg__filesize_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["User_Organiser_Logo_sizes__medium_jpeg__filename_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["User_Organiser_Logo_sizes__large_jpeg__url_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["User_Organiser_Logo_sizes__large_jpeg__width_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["User_Organiser_Logo_sizes__large_jpeg__height_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["User_Organiser_Logo_sizes__large_jpeg__mimeType_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["User_Organiser_Logo_sizes__large_jpeg__filesize_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["User_Organiser_Logo_sizes__large_jpeg__filename_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["User_Organiser_Logo_id_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["User_Organiser_Logo_where_and"]: {
	alt?: ModelTypes["User_Organiser_Logo_alt_operator"] | undefined,
	updatedAt?: ModelTypes["User_Organiser_Logo_updatedAt_operator"] | undefined,
	createdAt?: ModelTypes["User_Organiser_Logo_createdAt_operator"] | undefined,
	url?: ModelTypes["User_Organiser_Logo_url_operator"] | undefined,
	filename?: ModelTypes["User_Organiser_Logo_filename_operator"] | undefined,
	mimeType?: ModelTypes["User_Organiser_Logo_mimeType_operator"] | undefined,
	filesize?: ModelTypes["User_Organiser_Logo_filesize_operator"] | undefined,
	width?: ModelTypes["User_Organiser_Logo_width_operator"] | undefined,
	height?: ModelTypes["User_Organiser_Logo_height_operator"] | undefined,
	focalX?: ModelTypes["User_Organiser_Logo_focalX_operator"] | undefined,
	focalY?: ModelTypes["User_Organiser_Logo_focalY_operator"] | undefined,
	sizes__small__url?: ModelTypes["User_Organiser_Logo_sizes__small__url_operator"] | undefined,
	sizes__small__width?: ModelTypes["User_Organiser_Logo_sizes__small__width_operator"] | undefined,
	sizes__small__height?: ModelTypes["User_Organiser_Logo_sizes__small__height_operator"] | undefined,
	sizes__small__mimeType?: ModelTypes["User_Organiser_Logo_sizes__small__mimeType_operator"] | undefined,
	sizes__small__filesize?: ModelTypes["User_Organiser_Logo_sizes__small__filesize_operator"] | undefined,
	sizes__small__filename?: ModelTypes["User_Organiser_Logo_sizes__small__filename_operator"] | undefined,
	sizes__medium__url?: ModelTypes["User_Organiser_Logo_sizes__medium__url_operator"] | undefined,
	sizes__medium__width?: ModelTypes["User_Organiser_Logo_sizes__medium__width_operator"] | undefined,
	sizes__medium__height?: ModelTypes["User_Organiser_Logo_sizes__medium__height_operator"] | undefined,
	sizes__medium__mimeType?: ModelTypes["User_Organiser_Logo_sizes__medium__mimeType_operator"] | undefined,
	sizes__medium__filesize?: ModelTypes["User_Organiser_Logo_sizes__medium__filesize_operator"] | undefined,
	sizes__medium__filename?: ModelTypes["User_Organiser_Logo_sizes__medium__filename_operator"] | undefined,
	sizes__large__url?: ModelTypes["User_Organiser_Logo_sizes__large__url_operator"] | undefined,
	sizes__large__width?: ModelTypes["User_Organiser_Logo_sizes__large__width_operator"] | undefined,
	sizes__large__height?: ModelTypes["User_Organiser_Logo_sizes__large__height_operator"] | undefined,
	sizes__large__mimeType?: ModelTypes["User_Organiser_Logo_sizes__large__mimeType_operator"] | undefined,
	sizes__large__filesize?: ModelTypes["User_Organiser_Logo_sizes__large__filesize_operator"] | undefined,
	sizes__large__filename?: ModelTypes["User_Organiser_Logo_sizes__large__filename_operator"] | undefined,
	sizes__small_jpeg__url?: ModelTypes["User_Organiser_Logo_sizes__small_jpeg__url_operator"] | undefined,
	sizes__small_jpeg__width?: ModelTypes["User_Organiser_Logo_sizes__small_jpeg__width_operator"] | undefined,
	sizes__small_jpeg__height?: ModelTypes["User_Organiser_Logo_sizes__small_jpeg__height_operator"] | undefined,
	sizes__small_jpeg__mimeType?: ModelTypes["User_Organiser_Logo_sizes__small_jpeg__mimeType_operator"] | undefined,
	sizes__small_jpeg__filesize?: ModelTypes["User_Organiser_Logo_sizes__small_jpeg__filesize_operator"] | undefined,
	sizes__small_jpeg__filename?: ModelTypes["User_Organiser_Logo_sizes__small_jpeg__filename_operator"] | undefined,
	sizes__medium_jpeg__url?: ModelTypes["User_Organiser_Logo_sizes__medium_jpeg__url_operator"] | undefined,
	sizes__medium_jpeg__width?: ModelTypes["User_Organiser_Logo_sizes__medium_jpeg__width_operator"] | undefined,
	sizes__medium_jpeg__height?: ModelTypes["User_Organiser_Logo_sizes__medium_jpeg__height_operator"] | undefined,
	sizes__medium_jpeg__mimeType?: ModelTypes["User_Organiser_Logo_sizes__medium_jpeg__mimeType_operator"] | undefined,
	sizes__medium_jpeg__filesize?: ModelTypes["User_Organiser_Logo_sizes__medium_jpeg__filesize_operator"] | undefined,
	sizes__medium_jpeg__filename?: ModelTypes["User_Organiser_Logo_sizes__medium_jpeg__filename_operator"] | undefined,
	sizes__large_jpeg__url?: ModelTypes["User_Organiser_Logo_sizes__large_jpeg__url_operator"] | undefined,
	sizes__large_jpeg__width?: ModelTypes["User_Organiser_Logo_sizes__large_jpeg__width_operator"] | undefined,
	sizes__large_jpeg__height?: ModelTypes["User_Organiser_Logo_sizes__large_jpeg__height_operator"] | undefined,
	sizes__large_jpeg__mimeType?: ModelTypes["User_Organiser_Logo_sizes__large_jpeg__mimeType_operator"] | undefined,
	sizes__large_jpeg__filesize?: ModelTypes["User_Organiser_Logo_sizes__large_jpeg__filesize_operator"] | undefined,
	sizes__large_jpeg__filename?: ModelTypes["User_Organiser_Logo_sizes__large_jpeg__filename_operator"] | undefined,
	id?: ModelTypes["User_Organiser_Logo_id_operator"] | undefined,
	AND?: Array<ModelTypes["User_Organiser_Logo_where_and"] | undefined> | undefined,
	OR?: Array<ModelTypes["User_Organiser_Logo_where_or"] | undefined> | undefined
};
	["User_Organiser_Logo_where_or"]: {
	alt?: ModelTypes["User_Organiser_Logo_alt_operator"] | undefined,
	updatedAt?: ModelTypes["User_Organiser_Logo_updatedAt_operator"] | undefined,
	createdAt?: ModelTypes["User_Organiser_Logo_createdAt_operator"] | undefined,
	url?: ModelTypes["User_Organiser_Logo_url_operator"] | undefined,
	filename?: ModelTypes["User_Organiser_Logo_filename_operator"] | undefined,
	mimeType?: ModelTypes["User_Organiser_Logo_mimeType_operator"] | undefined,
	filesize?: ModelTypes["User_Organiser_Logo_filesize_operator"] | undefined,
	width?: ModelTypes["User_Organiser_Logo_width_operator"] | undefined,
	height?: ModelTypes["User_Organiser_Logo_height_operator"] | undefined,
	focalX?: ModelTypes["User_Organiser_Logo_focalX_operator"] | undefined,
	focalY?: ModelTypes["User_Organiser_Logo_focalY_operator"] | undefined,
	sizes__small__url?: ModelTypes["User_Organiser_Logo_sizes__small__url_operator"] | undefined,
	sizes__small__width?: ModelTypes["User_Organiser_Logo_sizes__small__width_operator"] | undefined,
	sizes__small__height?: ModelTypes["User_Organiser_Logo_sizes__small__height_operator"] | undefined,
	sizes__small__mimeType?: ModelTypes["User_Organiser_Logo_sizes__small__mimeType_operator"] | undefined,
	sizes__small__filesize?: ModelTypes["User_Organiser_Logo_sizes__small__filesize_operator"] | undefined,
	sizes__small__filename?: ModelTypes["User_Organiser_Logo_sizes__small__filename_operator"] | undefined,
	sizes__medium__url?: ModelTypes["User_Organiser_Logo_sizes__medium__url_operator"] | undefined,
	sizes__medium__width?: ModelTypes["User_Organiser_Logo_sizes__medium__width_operator"] | undefined,
	sizes__medium__height?: ModelTypes["User_Organiser_Logo_sizes__medium__height_operator"] | undefined,
	sizes__medium__mimeType?: ModelTypes["User_Organiser_Logo_sizes__medium__mimeType_operator"] | undefined,
	sizes__medium__filesize?: ModelTypes["User_Organiser_Logo_sizes__medium__filesize_operator"] | undefined,
	sizes__medium__filename?: ModelTypes["User_Organiser_Logo_sizes__medium__filename_operator"] | undefined,
	sizes__large__url?: ModelTypes["User_Organiser_Logo_sizes__large__url_operator"] | undefined,
	sizes__large__width?: ModelTypes["User_Organiser_Logo_sizes__large__width_operator"] | undefined,
	sizes__large__height?: ModelTypes["User_Organiser_Logo_sizes__large__height_operator"] | undefined,
	sizes__large__mimeType?: ModelTypes["User_Organiser_Logo_sizes__large__mimeType_operator"] | undefined,
	sizes__large__filesize?: ModelTypes["User_Organiser_Logo_sizes__large__filesize_operator"] | undefined,
	sizes__large__filename?: ModelTypes["User_Organiser_Logo_sizes__large__filename_operator"] | undefined,
	sizes__small_jpeg__url?: ModelTypes["User_Organiser_Logo_sizes__small_jpeg__url_operator"] | undefined,
	sizes__small_jpeg__width?: ModelTypes["User_Organiser_Logo_sizes__small_jpeg__width_operator"] | undefined,
	sizes__small_jpeg__height?: ModelTypes["User_Organiser_Logo_sizes__small_jpeg__height_operator"] | undefined,
	sizes__small_jpeg__mimeType?: ModelTypes["User_Organiser_Logo_sizes__small_jpeg__mimeType_operator"] | undefined,
	sizes__small_jpeg__filesize?: ModelTypes["User_Organiser_Logo_sizes__small_jpeg__filesize_operator"] | undefined,
	sizes__small_jpeg__filename?: ModelTypes["User_Organiser_Logo_sizes__small_jpeg__filename_operator"] | undefined,
	sizes__medium_jpeg__url?: ModelTypes["User_Organiser_Logo_sizes__medium_jpeg__url_operator"] | undefined,
	sizes__medium_jpeg__width?: ModelTypes["User_Organiser_Logo_sizes__medium_jpeg__width_operator"] | undefined,
	sizes__medium_jpeg__height?: ModelTypes["User_Organiser_Logo_sizes__medium_jpeg__height_operator"] | undefined,
	sizes__medium_jpeg__mimeType?: ModelTypes["User_Organiser_Logo_sizes__medium_jpeg__mimeType_operator"] | undefined,
	sizes__medium_jpeg__filesize?: ModelTypes["User_Organiser_Logo_sizes__medium_jpeg__filesize_operator"] | undefined,
	sizes__medium_jpeg__filename?: ModelTypes["User_Organiser_Logo_sizes__medium_jpeg__filename_operator"] | undefined,
	sizes__large_jpeg__url?: ModelTypes["User_Organiser_Logo_sizes__large_jpeg__url_operator"] | undefined,
	sizes__large_jpeg__width?: ModelTypes["User_Organiser_Logo_sizes__large_jpeg__width_operator"] | undefined,
	sizes__large_jpeg__height?: ModelTypes["User_Organiser_Logo_sizes__large_jpeg__height_operator"] | undefined,
	sizes__large_jpeg__mimeType?: ModelTypes["User_Organiser_Logo_sizes__large_jpeg__mimeType_operator"] | undefined,
	sizes__large_jpeg__filesize?: ModelTypes["User_Organiser_Logo_sizes__large_jpeg__filesize_operator"] | undefined,
	sizes__large_jpeg__filename?: ModelTypes["User_Organiser_Logo_sizes__large_jpeg__filename_operator"] | undefined,
	id?: ModelTypes["User_Organiser_Logo_id_operator"] | undefined,
	AND?: Array<ModelTypes["User_Organiser_Logo_where_and"] | undefined> | undefined,
	OR?: Array<ModelTypes["User_Organiser_Logo_where_or"] | undefined> | undefined
};
	["User_Organiser_Banner_where"]: {
	alt?: ModelTypes["User_Organiser_Banner_alt_operator"] | undefined,
	updatedAt?: ModelTypes["User_Organiser_Banner_updatedAt_operator"] | undefined,
	createdAt?: ModelTypes["User_Organiser_Banner_createdAt_operator"] | undefined,
	url?: ModelTypes["User_Organiser_Banner_url_operator"] | undefined,
	filename?: ModelTypes["User_Organiser_Banner_filename_operator"] | undefined,
	mimeType?: ModelTypes["User_Organiser_Banner_mimeType_operator"] | undefined,
	filesize?: ModelTypes["User_Organiser_Banner_filesize_operator"] | undefined,
	width?: ModelTypes["User_Organiser_Banner_width_operator"] | undefined,
	height?: ModelTypes["User_Organiser_Banner_height_operator"] | undefined,
	focalX?: ModelTypes["User_Organiser_Banner_focalX_operator"] | undefined,
	focalY?: ModelTypes["User_Organiser_Banner_focalY_operator"] | undefined,
	sizes__small__url?: ModelTypes["User_Organiser_Banner_sizes__small__url_operator"] | undefined,
	sizes__small__width?: ModelTypes["User_Organiser_Banner_sizes__small__width_operator"] | undefined,
	sizes__small__height?: ModelTypes["User_Organiser_Banner_sizes__small__height_operator"] | undefined,
	sizes__small__mimeType?: ModelTypes["User_Organiser_Banner_sizes__small__mimeType_operator"] | undefined,
	sizes__small__filesize?: ModelTypes["User_Organiser_Banner_sizes__small__filesize_operator"] | undefined,
	sizes__small__filename?: ModelTypes["User_Organiser_Banner_sizes__small__filename_operator"] | undefined,
	sizes__medium__url?: ModelTypes["User_Organiser_Banner_sizes__medium__url_operator"] | undefined,
	sizes__medium__width?: ModelTypes["User_Organiser_Banner_sizes__medium__width_operator"] | undefined,
	sizes__medium__height?: ModelTypes["User_Organiser_Banner_sizes__medium__height_operator"] | undefined,
	sizes__medium__mimeType?: ModelTypes["User_Organiser_Banner_sizes__medium__mimeType_operator"] | undefined,
	sizes__medium__filesize?: ModelTypes["User_Organiser_Banner_sizes__medium__filesize_operator"] | undefined,
	sizes__medium__filename?: ModelTypes["User_Organiser_Banner_sizes__medium__filename_operator"] | undefined,
	sizes__large__url?: ModelTypes["User_Organiser_Banner_sizes__large__url_operator"] | undefined,
	sizes__large__width?: ModelTypes["User_Organiser_Banner_sizes__large__width_operator"] | undefined,
	sizes__large__height?: ModelTypes["User_Organiser_Banner_sizes__large__height_operator"] | undefined,
	sizes__large__mimeType?: ModelTypes["User_Organiser_Banner_sizes__large__mimeType_operator"] | undefined,
	sizes__large__filesize?: ModelTypes["User_Organiser_Banner_sizes__large__filesize_operator"] | undefined,
	sizes__large__filename?: ModelTypes["User_Organiser_Banner_sizes__large__filename_operator"] | undefined,
	sizes__small_jpeg__url?: ModelTypes["User_Organiser_Banner_sizes__small_jpeg__url_operator"] | undefined,
	sizes__small_jpeg__width?: ModelTypes["User_Organiser_Banner_sizes__small_jpeg__width_operator"] | undefined,
	sizes__small_jpeg__height?: ModelTypes["User_Organiser_Banner_sizes__small_jpeg__height_operator"] | undefined,
	sizes__small_jpeg__mimeType?: ModelTypes["User_Organiser_Banner_sizes__small_jpeg__mimeType_operator"] | undefined,
	sizes__small_jpeg__filesize?: ModelTypes["User_Organiser_Banner_sizes__small_jpeg__filesize_operator"] | undefined,
	sizes__small_jpeg__filename?: ModelTypes["User_Organiser_Banner_sizes__small_jpeg__filename_operator"] | undefined,
	sizes__medium_jpeg__url?: ModelTypes["User_Organiser_Banner_sizes__medium_jpeg__url_operator"] | undefined,
	sizes__medium_jpeg__width?: ModelTypes["User_Organiser_Banner_sizes__medium_jpeg__width_operator"] | undefined,
	sizes__medium_jpeg__height?: ModelTypes["User_Organiser_Banner_sizes__medium_jpeg__height_operator"] | undefined,
	sizes__medium_jpeg__mimeType?: ModelTypes["User_Organiser_Banner_sizes__medium_jpeg__mimeType_operator"] | undefined,
	sizes__medium_jpeg__filesize?: ModelTypes["User_Organiser_Banner_sizes__medium_jpeg__filesize_operator"] | undefined,
	sizes__medium_jpeg__filename?: ModelTypes["User_Organiser_Banner_sizes__medium_jpeg__filename_operator"] | undefined,
	sizes__large_jpeg__url?: ModelTypes["User_Organiser_Banner_sizes__large_jpeg__url_operator"] | undefined,
	sizes__large_jpeg__width?: ModelTypes["User_Organiser_Banner_sizes__large_jpeg__width_operator"] | undefined,
	sizes__large_jpeg__height?: ModelTypes["User_Organiser_Banner_sizes__large_jpeg__height_operator"] | undefined,
	sizes__large_jpeg__mimeType?: ModelTypes["User_Organiser_Banner_sizes__large_jpeg__mimeType_operator"] | undefined,
	sizes__large_jpeg__filesize?: ModelTypes["User_Organiser_Banner_sizes__large_jpeg__filesize_operator"] | undefined,
	sizes__large_jpeg__filename?: ModelTypes["User_Organiser_Banner_sizes__large_jpeg__filename_operator"] | undefined,
	id?: ModelTypes["User_Organiser_Banner_id_operator"] | undefined,
	AND?: Array<ModelTypes["User_Organiser_Banner_where_and"] | undefined> | undefined,
	OR?: Array<ModelTypes["User_Organiser_Banner_where_or"] | undefined> | undefined
};
	["User_Organiser_Banner_alt_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["User_Organiser_Banner_updatedAt_operator"]: {
	equals?: ModelTypes["DateTime"] | undefined,
	not_equals?: ModelTypes["DateTime"] | undefined,
	greater_than_equal?: ModelTypes["DateTime"] | undefined,
	greater_than?: ModelTypes["DateTime"] | undefined,
	less_than_equal?: ModelTypes["DateTime"] | undefined,
	less_than?: ModelTypes["DateTime"] | undefined,
	like?: ModelTypes["DateTime"] | undefined,
	exists?: boolean | undefined
};
	["User_Organiser_Banner_createdAt_operator"]: {
	equals?: ModelTypes["DateTime"] | undefined,
	not_equals?: ModelTypes["DateTime"] | undefined,
	greater_than_equal?: ModelTypes["DateTime"] | undefined,
	greater_than?: ModelTypes["DateTime"] | undefined,
	less_than_equal?: ModelTypes["DateTime"] | undefined,
	less_than?: ModelTypes["DateTime"] | undefined,
	like?: ModelTypes["DateTime"] | undefined,
	exists?: boolean | undefined
};
	["User_Organiser_Banner_url_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["User_Organiser_Banner_filename_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["User_Organiser_Banner_mimeType_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["User_Organiser_Banner_filesize_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["User_Organiser_Banner_width_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["User_Organiser_Banner_height_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["User_Organiser_Banner_focalX_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["User_Organiser_Banner_focalY_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["User_Organiser_Banner_sizes__small__url_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["User_Organiser_Banner_sizes__small__width_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["User_Organiser_Banner_sizes__small__height_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["User_Organiser_Banner_sizes__small__mimeType_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["User_Organiser_Banner_sizes__small__filesize_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["User_Organiser_Banner_sizes__small__filename_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["User_Organiser_Banner_sizes__medium__url_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["User_Organiser_Banner_sizes__medium__width_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["User_Organiser_Banner_sizes__medium__height_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["User_Organiser_Banner_sizes__medium__mimeType_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["User_Organiser_Banner_sizes__medium__filesize_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["User_Organiser_Banner_sizes__medium__filename_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["User_Organiser_Banner_sizes__large__url_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["User_Organiser_Banner_sizes__large__width_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["User_Organiser_Banner_sizes__large__height_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["User_Organiser_Banner_sizes__large__mimeType_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["User_Organiser_Banner_sizes__large__filesize_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["User_Organiser_Banner_sizes__large__filename_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["User_Organiser_Banner_sizes__small_jpeg__url_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["User_Organiser_Banner_sizes__small_jpeg__width_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["User_Organiser_Banner_sizes__small_jpeg__height_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["User_Organiser_Banner_sizes__small_jpeg__mimeType_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["User_Organiser_Banner_sizes__small_jpeg__filesize_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["User_Organiser_Banner_sizes__small_jpeg__filename_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["User_Organiser_Banner_sizes__medium_jpeg__url_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["User_Organiser_Banner_sizes__medium_jpeg__width_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["User_Organiser_Banner_sizes__medium_jpeg__height_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["User_Organiser_Banner_sizes__medium_jpeg__mimeType_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["User_Organiser_Banner_sizes__medium_jpeg__filesize_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["User_Organiser_Banner_sizes__medium_jpeg__filename_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["User_Organiser_Banner_sizes__large_jpeg__url_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["User_Organiser_Banner_sizes__large_jpeg__width_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["User_Organiser_Banner_sizes__large_jpeg__height_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["User_Organiser_Banner_sizes__large_jpeg__mimeType_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["User_Organiser_Banner_sizes__large_jpeg__filesize_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["User_Organiser_Banner_sizes__large_jpeg__filename_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["User_Organiser_Banner_id_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["User_Organiser_Banner_where_and"]: {
	alt?: ModelTypes["User_Organiser_Banner_alt_operator"] | undefined,
	updatedAt?: ModelTypes["User_Organiser_Banner_updatedAt_operator"] | undefined,
	createdAt?: ModelTypes["User_Organiser_Banner_createdAt_operator"] | undefined,
	url?: ModelTypes["User_Organiser_Banner_url_operator"] | undefined,
	filename?: ModelTypes["User_Organiser_Banner_filename_operator"] | undefined,
	mimeType?: ModelTypes["User_Organiser_Banner_mimeType_operator"] | undefined,
	filesize?: ModelTypes["User_Organiser_Banner_filesize_operator"] | undefined,
	width?: ModelTypes["User_Organiser_Banner_width_operator"] | undefined,
	height?: ModelTypes["User_Organiser_Banner_height_operator"] | undefined,
	focalX?: ModelTypes["User_Organiser_Banner_focalX_operator"] | undefined,
	focalY?: ModelTypes["User_Organiser_Banner_focalY_operator"] | undefined,
	sizes__small__url?: ModelTypes["User_Organiser_Banner_sizes__small__url_operator"] | undefined,
	sizes__small__width?: ModelTypes["User_Organiser_Banner_sizes__small__width_operator"] | undefined,
	sizes__small__height?: ModelTypes["User_Organiser_Banner_sizes__small__height_operator"] | undefined,
	sizes__small__mimeType?: ModelTypes["User_Organiser_Banner_sizes__small__mimeType_operator"] | undefined,
	sizes__small__filesize?: ModelTypes["User_Organiser_Banner_sizes__small__filesize_operator"] | undefined,
	sizes__small__filename?: ModelTypes["User_Organiser_Banner_sizes__small__filename_operator"] | undefined,
	sizes__medium__url?: ModelTypes["User_Organiser_Banner_sizes__medium__url_operator"] | undefined,
	sizes__medium__width?: ModelTypes["User_Organiser_Banner_sizes__medium__width_operator"] | undefined,
	sizes__medium__height?: ModelTypes["User_Organiser_Banner_sizes__medium__height_operator"] | undefined,
	sizes__medium__mimeType?: ModelTypes["User_Organiser_Banner_sizes__medium__mimeType_operator"] | undefined,
	sizes__medium__filesize?: ModelTypes["User_Organiser_Banner_sizes__medium__filesize_operator"] | undefined,
	sizes__medium__filename?: ModelTypes["User_Organiser_Banner_sizes__medium__filename_operator"] | undefined,
	sizes__large__url?: ModelTypes["User_Organiser_Banner_sizes__large__url_operator"] | undefined,
	sizes__large__width?: ModelTypes["User_Organiser_Banner_sizes__large__width_operator"] | undefined,
	sizes__large__height?: ModelTypes["User_Organiser_Banner_sizes__large__height_operator"] | undefined,
	sizes__large__mimeType?: ModelTypes["User_Organiser_Banner_sizes__large__mimeType_operator"] | undefined,
	sizes__large__filesize?: ModelTypes["User_Organiser_Banner_sizes__large__filesize_operator"] | undefined,
	sizes__large__filename?: ModelTypes["User_Organiser_Banner_sizes__large__filename_operator"] | undefined,
	sizes__small_jpeg__url?: ModelTypes["User_Organiser_Banner_sizes__small_jpeg__url_operator"] | undefined,
	sizes__small_jpeg__width?: ModelTypes["User_Organiser_Banner_sizes__small_jpeg__width_operator"] | undefined,
	sizes__small_jpeg__height?: ModelTypes["User_Organiser_Banner_sizes__small_jpeg__height_operator"] | undefined,
	sizes__small_jpeg__mimeType?: ModelTypes["User_Organiser_Banner_sizes__small_jpeg__mimeType_operator"] | undefined,
	sizes__small_jpeg__filesize?: ModelTypes["User_Organiser_Banner_sizes__small_jpeg__filesize_operator"] | undefined,
	sizes__small_jpeg__filename?: ModelTypes["User_Organiser_Banner_sizes__small_jpeg__filename_operator"] | undefined,
	sizes__medium_jpeg__url?: ModelTypes["User_Organiser_Banner_sizes__medium_jpeg__url_operator"] | undefined,
	sizes__medium_jpeg__width?: ModelTypes["User_Organiser_Banner_sizes__medium_jpeg__width_operator"] | undefined,
	sizes__medium_jpeg__height?: ModelTypes["User_Organiser_Banner_sizes__medium_jpeg__height_operator"] | undefined,
	sizes__medium_jpeg__mimeType?: ModelTypes["User_Organiser_Banner_sizes__medium_jpeg__mimeType_operator"] | undefined,
	sizes__medium_jpeg__filesize?: ModelTypes["User_Organiser_Banner_sizes__medium_jpeg__filesize_operator"] | undefined,
	sizes__medium_jpeg__filename?: ModelTypes["User_Organiser_Banner_sizes__medium_jpeg__filename_operator"] | undefined,
	sizes__large_jpeg__url?: ModelTypes["User_Organiser_Banner_sizes__large_jpeg__url_operator"] | undefined,
	sizes__large_jpeg__width?: ModelTypes["User_Organiser_Banner_sizes__large_jpeg__width_operator"] | undefined,
	sizes__large_jpeg__height?: ModelTypes["User_Organiser_Banner_sizes__large_jpeg__height_operator"] | undefined,
	sizes__large_jpeg__mimeType?: ModelTypes["User_Organiser_Banner_sizes__large_jpeg__mimeType_operator"] | undefined,
	sizes__large_jpeg__filesize?: ModelTypes["User_Organiser_Banner_sizes__large_jpeg__filesize_operator"] | undefined,
	sizes__large_jpeg__filename?: ModelTypes["User_Organiser_Banner_sizes__large_jpeg__filename_operator"] | undefined,
	id?: ModelTypes["User_Organiser_Banner_id_operator"] | undefined,
	AND?: Array<ModelTypes["User_Organiser_Banner_where_and"] | undefined> | undefined,
	OR?: Array<ModelTypes["User_Organiser_Banner_where_or"] | undefined> | undefined
};
	["User_Organiser_Banner_where_or"]: {
	alt?: ModelTypes["User_Organiser_Banner_alt_operator"] | undefined,
	updatedAt?: ModelTypes["User_Organiser_Banner_updatedAt_operator"] | undefined,
	createdAt?: ModelTypes["User_Organiser_Banner_createdAt_operator"] | undefined,
	url?: ModelTypes["User_Organiser_Banner_url_operator"] | undefined,
	filename?: ModelTypes["User_Organiser_Banner_filename_operator"] | undefined,
	mimeType?: ModelTypes["User_Organiser_Banner_mimeType_operator"] | undefined,
	filesize?: ModelTypes["User_Organiser_Banner_filesize_operator"] | undefined,
	width?: ModelTypes["User_Organiser_Banner_width_operator"] | undefined,
	height?: ModelTypes["User_Organiser_Banner_height_operator"] | undefined,
	focalX?: ModelTypes["User_Organiser_Banner_focalX_operator"] | undefined,
	focalY?: ModelTypes["User_Organiser_Banner_focalY_operator"] | undefined,
	sizes__small__url?: ModelTypes["User_Organiser_Banner_sizes__small__url_operator"] | undefined,
	sizes__small__width?: ModelTypes["User_Organiser_Banner_sizes__small__width_operator"] | undefined,
	sizes__small__height?: ModelTypes["User_Organiser_Banner_sizes__small__height_operator"] | undefined,
	sizes__small__mimeType?: ModelTypes["User_Organiser_Banner_sizes__small__mimeType_operator"] | undefined,
	sizes__small__filesize?: ModelTypes["User_Organiser_Banner_sizes__small__filesize_operator"] | undefined,
	sizes__small__filename?: ModelTypes["User_Organiser_Banner_sizes__small__filename_operator"] | undefined,
	sizes__medium__url?: ModelTypes["User_Organiser_Banner_sizes__medium__url_operator"] | undefined,
	sizes__medium__width?: ModelTypes["User_Organiser_Banner_sizes__medium__width_operator"] | undefined,
	sizes__medium__height?: ModelTypes["User_Organiser_Banner_sizes__medium__height_operator"] | undefined,
	sizes__medium__mimeType?: ModelTypes["User_Organiser_Banner_sizes__medium__mimeType_operator"] | undefined,
	sizes__medium__filesize?: ModelTypes["User_Organiser_Banner_sizes__medium__filesize_operator"] | undefined,
	sizes__medium__filename?: ModelTypes["User_Organiser_Banner_sizes__medium__filename_operator"] | undefined,
	sizes__large__url?: ModelTypes["User_Organiser_Banner_sizes__large__url_operator"] | undefined,
	sizes__large__width?: ModelTypes["User_Organiser_Banner_sizes__large__width_operator"] | undefined,
	sizes__large__height?: ModelTypes["User_Organiser_Banner_sizes__large__height_operator"] | undefined,
	sizes__large__mimeType?: ModelTypes["User_Organiser_Banner_sizes__large__mimeType_operator"] | undefined,
	sizes__large__filesize?: ModelTypes["User_Organiser_Banner_sizes__large__filesize_operator"] | undefined,
	sizes__large__filename?: ModelTypes["User_Organiser_Banner_sizes__large__filename_operator"] | undefined,
	sizes__small_jpeg__url?: ModelTypes["User_Organiser_Banner_sizes__small_jpeg__url_operator"] | undefined,
	sizes__small_jpeg__width?: ModelTypes["User_Organiser_Banner_sizes__small_jpeg__width_operator"] | undefined,
	sizes__small_jpeg__height?: ModelTypes["User_Organiser_Banner_sizes__small_jpeg__height_operator"] | undefined,
	sizes__small_jpeg__mimeType?: ModelTypes["User_Organiser_Banner_sizes__small_jpeg__mimeType_operator"] | undefined,
	sizes__small_jpeg__filesize?: ModelTypes["User_Organiser_Banner_sizes__small_jpeg__filesize_operator"] | undefined,
	sizes__small_jpeg__filename?: ModelTypes["User_Organiser_Banner_sizes__small_jpeg__filename_operator"] | undefined,
	sizes__medium_jpeg__url?: ModelTypes["User_Organiser_Banner_sizes__medium_jpeg__url_operator"] | undefined,
	sizes__medium_jpeg__width?: ModelTypes["User_Organiser_Banner_sizes__medium_jpeg__width_operator"] | undefined,
	sizes__medium_jpeg__height?: ModelTypes["User_Organiser_Banner_sizes__medium_jpeg__height_operator"] | undefined,
	sizes__medium_jpeg__mimeType?: ModelTypes["User_Organiser_Banner_sizes__medium_jpeg__mimeType_operator"] | undefined,
	sizes__medium_jpeg__filesize?: ModelTypes["User_Organiser_Banner_sizes__medium_jpeg__filesize_operator"] | undefined,
	sizes__medium_jpeg__filename?: ModelTypes["User_Organiser_Banner_sizes__medium_jpeg__filename_operator"] | undefined,
	sizes__large_jpeg__url?: ModelTypes["User_Organiser_Banner_sizes__large_jpeg__url_operator"] | undefined,
	sizes__large_jpeg__width?: ModelTypes["User_Organiser_Banner_sizes__large_jpeg__width_operator"] | undefined,
	sizes__large_jpeg__height?: ModelTypes["User_Organiser_Banner_sizes__large_jpeg__height_operator"] | undefined,
	sizes__large_jpeg__mimeType?: ModelTypes["User_Organiser_Banner_sizes__large_jpeg__mimeType_operator"] | undefined,
	sizes__large_jpeg__filesize?: ModelTypes["User_Organiser_Banner_sizes__large_jpeg__filesize_operator"] | undefined,
	sizes__large_jpeg__filename?: ModelTypes["User_Organiser_Banner_sizes__large_jpeg__filename_operator"] | undefined,
	id?: ModelTypes["User_Organiser_Banner_id_operator"] | undefined,
	AND?: Array<ModelTypes["User_Organiser_Banner_where_and"] | undefined> | undefined,
	OR?: Array<ModelTypes["User_Organiser_Banner_where_or"] | undefined> | undefined
};
	["User_Organiser_language"]:User_Organiser_language;
	["User_Sso"]: {
		googleId?: string | undefined,
	facebookId?: string | undefined,
	appleId?: string | undefined
};
	["User_status"]:User_status;
	/** A field whose value conforms to the standard internet email address format as specified in HTML Spec: https://html.spec.whatwg.org/multipage/input.html#valid-e-mail-address. */
["EmailAddress"]:any;
	["Event_level"]:Event_level;
	["Event_Thumbnail_where"]: {
	alt?: ModelTypes["Event_Thumbnail_alt_operator"] | undefined,
	updatedAt?: ModelTypes["Event_Thumbnail_updatedAt_operator"] | undefined,
	createdAt?: ModelTypes["Event_Thumbnail_createdAt_operator"] | undefined,
	url?: ModelTypes["Event_Thumbnail_url_operator"] | undefined,
	filename?: ModelTypes["Event_Thumbnail_filename_operator"] | undefined,
	mimeType?: ModelTypes["Event_Thumbnail_mimeType_operator"] | undefined,
	filesize?: ModelTypes["Event_Thumbnail_filesize_operator"] | undefined,
	width?: ModelTypes["Event_Thumbnail_width_operator"] | undefined,
	height?: ModelTypes["Event_Thumbnail_height_operator"] | undefined,
	focalX?: ModelTypes["Event_Thumbnail_focalX_operator"] | undefined,
	focalY?: ModelTypes["Event_Thumbnail_focalY_operator"] | undefined,
	sizes__small__url?: ModelTypes["Event_Thumbnail_sizes__small__url_operator"] | undefined,
	sizes__small__width?: ModelTypes["Event_Thumbnail_sizes__small__width_operator"] | undefined,
	sizes__small__height?: ModelTypes["Event_Thumbnail_sizes__small__height_operator"] | undefined,
	sizes__small__mimeType?: ModelTypes["Event_Thumbnail_sizes__small__mimeType_operator"] | undefined,
	sizes__small__filesize?: ModelTypes["Event_Thumbnail_sizes__small__filesize_operator"] | undefined,
	sizes__small__filename?: ModelTypes["Event_Thumbnail_sizes__small__filename_operator"] | undefined,
	sizes__medium__url?: ModelTypes["Event_Thumbnail_sizes__medium__url_operator"] | undefined,
	sizes__medium__width?: ModelTypes["Event_Thumbnail_sizes__medium__width_operator"] | undefined,
	sizes__medium__height?: ModelTypes["Event_Thumbnail_sizes__medium__height_operator"] | undefined,
	sizes__medium__mimeType?: ModelTypes["Event_Thumbnail_sizes__medium__mimeType_operator"] | undefined,
	sizes__medium__filesize?: ModelTypes["Event_Thumbnail_sizes__medium__filesize_operator"] | undefined,
	sizes__medium__filename?: ModelTypes["Event_Thumbnail_sizes__medium__filename_operator"] | undefined,
	sizes__large__url?: ModelTypes["Event_Thumbnail_sizes__large__url_operator"] | undefined,
	sizes__large__width?: ModelTypes["Event_Thumbnail_sizes__large__width_operator"] | undefined,
	sizes__large__height?: ModelTypes["Event_Thumbnail_sizes__large__height_operator"] | undefined,
	sizes__large__mimeType?: ModelTypes["Event_Thumbnail_sizes__large__mimeType_operator"] | undefined,
	sizes__large__filesize?: ModelTypes["Event_Thumbnail_sizes__large__filesize_operator"] | undefined,
	sizes__large__filename?: ModelTypes["Event_Thumbnail_sizes__large__filename_operator"] | undefined,
	sizes__small_jpeg__url?: ModelTypes["Event_Thumbnail_sizes__small_jpeg__url_operator"] | undefined,
	sizes__small_jpeg__width?: ModelTypes["Event_Thumbnail_sizes__small_jpeg__width_operator"] | undefined,
	sizes__small_jpeg__height?: ModelTypes["Event_Thumbnail_sizes__small_jpeg__height_operator"] | undefined,
	sizes__small_jpeg__mimeType?: ModelTypes["Event_Thumbnail_sizes__small_jpeg__mimeType_operator"] | undefined,
	sizes__small_jpeg__filesize?: ModelTypes["Event_Thumbnail_sizes__small_jpeg__filesize_operator"] | undefined,
	sizes__small_jpeg__filename?: ModelTypes["Event_Thumbnail_sizes__small_jpeg__filename_operator"] | undefined,
	sizes__medium_jpeg__url?: ModelTypes["Event_Thumbnail_sizes__medium_jpeg__url_operator"] | undefined,
	sizes__medium_jpeg__width?: ModelTypes["Event_Thumbnail_sizes__medium_jpeg__width_operator"] | undefined,
	sizes__medium_jpeg__height?: ModelTypes["Event_Thumbnail_sizes__medium_jpeg__height_operator"] | undefined,
	sizes__medium_jpeg__mimeType?: ModelTypes["Event_Thumbnail_sizes__medium_jpeg__mimeType_operator"] | undefined,
	sizes__medium_jpeg__filesize?: ModelTypes["Event_Thumbnail_sizes__medium_jpeg__filesize_operator"] | undefined,
	sizes__medium_jpeg__filename?: ModelTypes["Event_Thumbnail_sizes__medium_jpeg__filename_operator"] | undefined,
	sizes__large_jpeg__url?: ModelTypes["Event_Thumbnail_sizes__large_jpeg__url_operator"] | undefined,
	sizes__large_jpeg__width?: ModelTypes["Event_Thumbnail_sizes__large_jpeg__width_operator"] | undefined,
	sizes__large_jpeg__height?: ModelTypes["Event_Thumbnail_sizes__large_jpeg__height_operator"] | undefined,
	sizes__large_jpeg__mimeType?: ModelTypes["Event_Thumbnail_sizes__large_jpeg__mimeType_operator"] | undefined,
	sizes__large_jpeg__filesize?: ModelTypes["Event_Thumbnail_sizes__large_jpeg__filesize_operator"] | undefined,
	sizes__large_jpeg__filename?: ModelTypes["Event_Thumbnail_sizes__large_jpeg__filename_operator"] | undefined,
	id?: ModelTypes["Event_Thumbnail_id_operator"] | undefined,
	AND?: Array<ModelTypes["Event_Thumbnail_where_and"] | undefined> | undefined,
	OR?: Array<ModelTypes["Event_Thumbnail_where_or"] | undefined> | undefined
};
	["Event_Thumbnail_alt_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Event_Thumbnail_updatedAt_operator"]: {
	equals?: ModelTypes["DateTime"] | undefined,
	not_equals?: ModelTypes["DateTime"] | undefined,
	greater_than_equal?: ModelTypes["DateTime"] | undefined,
	greater_than?: ModelTypes["DateTime"] | undefined,
	less_than_equal?: ModelTypes["DateTime"] | undefined,
	less_than?: ModelTypes["DateTime"] | undefined,
	like?: ModelTypes["DateTime"] | undefined,
	exists?: boolean | undefined
};
	["Event_Thumbnail_createdAt_operator"]: {
	equals?: ModelTypes["DateTime"] | undefined,
	not_equals?: ModelTypes["DateTime"] | undefined,
	greater_than_equal?: ModelTypes["DateTime"] | undefined,
	greater_than?: ModelTypes["DateTime"] | undefined,
	less_than_equal?: ModelTypes["DateTime"] | undefined,
	less_than?: ModelTypes["DateTime"] | undefined,
	like?: ModelTypes["DateTime"] | undefined,
	exists?: boolean | undefined
};
	["Event_Thumbnail_url_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Event_Thumbnail_filename_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Event_Thumbnail_mimeType_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Event_Thumbnail_filesize_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Event_Thumbnail_width_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Event_Thumbnail_height_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Event_Thumbnail_focalX_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Event_Thumbnail_focalY_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Event_Thumbnail_sizes__small__url_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Event_Thumbnail_sizes__small__width_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Event_Thumbnail_sizes__small__height_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Event_Thumbnail_sizes__small__mimeType_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Event_Thumbnail_sizes__small__filesize_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Event_Thumbnail_sizes__small__filename_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Event_Thumbnail_sizes__medium__url_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Event_Thumbnail_sizes__medium__width_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Event_Thumbnail_sizes__medium__height_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Event_Thumbnail_sizes__medium__mimeType_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Event_Thumbnail_sizes__medium__filesize_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Event_Thumbnail_sizes__medium__filename_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Event_Thumbnail_sizes__large__url_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Event_Thumbnail_sizes__large__width_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Event_Thumbnail_sizes__large__height_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Event_Thumbnail_sizes__large__mimeType_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Event_Thumbnail_sizes__large__filesize_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Event_Thumbnail_sizes__large__filename_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Event_Thumbnail_sizes__small_jpeg__url_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Event_Thumbnail_sizes__small_jpeg__width_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Event_Thumbnail_sizes__small_jpeg__height_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Event_Thumbnail_sizes__small_jpeg__mimeType_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Event_Thumbnail_sizes__small_jpeg__filesize_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Event_Thumbnail_sizes__small_jpeg__filename_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Event_Thumbnail_sizes__medium_jpeg__url_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Event_Thumbnail_sizes__medium_jpeg__width_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Event_Thumbnail_sizes__medium_jpeg__height_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Event_Thumbnail_sizes__medium_jpeg__mimeType_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Event_Thumbnail_sizes__medium_jpeg__filesize_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Event_Thumbnail_sizes__medium_jpeg__filename_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Event_Thumbnail_sizes__large_jpeg__url_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Event_Thumbnail_sizes__large_jpeg__width_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Event_Thumbnail_sizes__large_jpeg__height_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Event_Thumbnail_sizes__large_jpeg__mimeType_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Event_Thumbnail_sizes__large_jpeg__filesize_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Event_Thumbnail_sizes__large_jpeg__filename_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Event_Thumbnail_id_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Event_Thumbnail_where_and"]: {
	alt?: ModelTypes["Event_Thumbnail_alt_operator"] | undefined,
	updatedAt?: ModelTypes["Event_Thumbnail_updatedAt_operator"] | undefined,
	createdAt?: ModelTypes["Event_Thumbnail_createdAt_operator"] | undefined,
	url?: ModelTypes["Event_Thumbnail_url_operator"] | undefined,
	filename?: ModelTypes["Event_Thumbnail_filename_operator"] | undefined,
	mimeType?: ModelTypes["Event_Thumbnail_mimeType_operator"] | undefined,
	filesize?: ModelTypes["Event_Thumbnail_filesize_operator"] | undefined,
	width?: ModelTypes["Event_Thumbnail_width_operator"] | undefined,
	height?: ModelTypes["Event_Thumbnail_height_operator"] | undefined,
	focalX?: ModelTypes["Event_Thumbnail_focalX_operator"] | undefined,
	focalY?: ModelTypes["Event_Thumbnail_focalY_operator"] | undefined,
	sizes__small__url?: ModelTypes["Event_Thumbnail_sizes__small__url_operator"] | undefined,
	sizes__small__width?: ModelTypes["Event_Thumbnail_sizes__small__width_operator"] | undefined,
	sizes__small__height?: ModelTypes["Event_Thumbnail_sizes__small__height_operator"] | undefined,
	sizes__small__mimeType?: ModelTypes["Event_Thumbnail_sizes__small__mimeType_operator"] | undefined,
	sizes__small__filesize?: ModelTypes["Event_Thumbnail_sizes__small__filesize_operator"] | undefined,
	sizes__small__filename?: ModelTypes["Event_Thumbnail_sizes__small__filename_operator"] | undefined,
	sizes__medium__url?: ModelTypes["Event_Thumbnail_sizes__medium__url_operator"] | undefined,
	sizes__medium__width?: ModelTypes["Event_Thumbnail_sizes__medium__width_operator"] | undefined,
	sizes__medium__height?: ModelTypes["Event_Thumbnail_sizes__medium__height_operator"] | undefined,
	sizes__medium__mimeType?: ModelTypes["Event_Thumbnail_sizes__medium__mimeType_operator"] | undefined,
	sizes__medium__filesize?: ModelTypes["Event_Thumbnail_sizes__medium__filesize_operator"] | undefined,
	sizes__medium__filename?: ModelTypes["Event_Thumbnail_sizes__medium__filename_operator"] | undefined,
	sizes__large__url?: ModelTypes["Event_Thumbnail_sizes__large__url_operator"] | undefined,
	sizes__large__width?: ModelTypes["Event_Thumbnail_sizes__large__width_operator"] | undefined,
	sizes__large__height?: ModelTypes["Event_Thumbnail_sizes__large__height_operator"] | undefined,
	sizes__large__mimeType?: ModelTypes["Event_Thumbnail_sizes__large__mimeType_operator"] | undefined,
	sizes__large__filesize?: ModelTypes["Event_Thumbnail_sizes__large__filesize_operator"] | undefined,
	sizes__large__filename?: ModelTypes["Event_Thumbnail_sizes__large__filename_operator"] | undefined,
	sizes__small_jpeg__url?: ModelTypes["Event_Thumbnail_sizes__small_jpeg__url_operator"] | undefined,
	sizes__small_jpeg__width?: ModelTypes["Event_Thumbnail_sizes__small_jpeg__width_operator"] | undefined,
	sizes__small_jpeg__height?: ModelTypes["Event_Thumbnail_sizes__small_jpeg__height_operator"] | undefined,
	sizes__small_jpeg__mimeType?: ModelTypes["Event_Thumbnail_sizes__small_jpeg__mimeType_operator"] | undefined,
	sizes__small_jpeg__filesize?: ModelTypes["Event_Thumbnail_sizes__small_jpeg__filesize_operator"] | undefined,
	sizes__small_jpeg__filename?: ModelTypes["Event_Thumbnail_sizes__small_jpeg__filename_operator"] | undefined,
	sizes__medium_jpeg__url?: ModelTypes["Event_Thumbnail_sizes__medium_jpeg__url_operator"] | undefined,
	sizes__medium_jpeg__width?: ModelTypes["Event_Thumbnail_sizes__medium_jpeg__width_operator"] | undefined,
	sizes__medium_jpeg__height?: ModelTypes["Event_Thumbnail_sizes__medium_jpeg__height_operator"] | undefined,
	sizes__medium_jpeg__mimeType?: ModelTypes["Event_Thumbnail_sizes__medium_jpeg__mimeType_operator"] | undefined,
	sizes__medium_jpeg__filesize?: ModelTypes["Event_Thumbnail_sizes__medium_jpeg__filesize_operator"] | undefined,
	sizes__medium_jpeg__filename?: ModelTypes["Event_Thumbnail_sizes__medium_jpeg__filename_operator"] | undefined,
	sizes__large_jpeg__url?: ModelTypes["Event_Thumbnail_sizes__large_jpeg__url_operator"] | undefined,
	sizes__large_jpeg__width?: ModelTypes["Event_Thumbnail_sizes__large_jpeg__width_operator"] | undefined,
	sizes__large_jpeg__height?: ModelTypes["Event_Thumbnail_sizes__large_jpeg__height_operator"] | undefined,
	sizes__large_jpeg__mimeType?: ModelTypes["Event_Thumbnail_sizes__large_jpeg__mimeType_operator"] | undefined,
	sizes__large_jpeg__filesize?: ModelTypes["Event_Thumbnail_sizes__large_jpeg__filesize_operator"] | undefined,
	sizes__large_jpeg__filename?: ModelTypes["Event_Thumbnail_sizes__large_jpeg__filename_operator"] | undefined,
	id?: ModelTypes["Event_Thumbnail_id_operator"] | undefined,
	AND?: Array<ModelTypes["Event_Thumbnail_where_and"] | undefined> | undefined,
	OR?: Array<ModelTypes["Event_Thumbnail_where_or"] | undefined> | undefined
};
	["Event_Thumbnail_where_or"]: {
	alt?: ModelTypes["Event_Thumbnail_alt_operator"] | undefined,
	updatedAt?: ModelTypes["Event_Thumbnail_updatedAt_operator"] | undefined,
	createdAt?: ModelTypes["Event_Thumbnail_createdAt_operator"] | undefined,
	url?: ModelTypes["Event_Thumbnail_url_operator"] | undefined,
	filename?: ModelTypes["Event_Thumbnail_filename_operator"] | undefined,
	mimeType?: ModelTypes["Event_Thumbnail_mimeType_operator"] | undefined,
	filesize?: ModelTypes["Event_Thumbnail_filesize_operator"] | undefined,
	width?: ModelTypes["Event_Thumbnail_width_operator"] | undefined,
	height?: ModelTypes["Event_Thumbnail_height_operator"] | undefined,
	focalX?: ModelTypes["Event_Thumbnail_focalX_operator"] | undefined,
	focalY?: ModelTypes["Event_Thumbnail_focalY_operator"] | undefined,
	sizes__small__url?: ModelTypes["Event_Thumbnail_sizes__small__url_operator"] | undefined,
	sizes__small__width?: ModelTypes["Event_Thumbnail_sizes__small__width_operator"] | undefined,
	sizes__small__height?: ModelTypes["Event_Thumbnail_sizes__small__height_operator"] | undefined,
	sizes__small__mimeType?: ModelTypes["Event_Thumbnail_sizes__small__mimeType_operator"] | undefined,
	sizes__small__filesize?: ModelTypes["Event_Thumbnail_sizes__small__filesize_operator"] | undefined,
	sizes__small__filename?: ModelTypes["Event_Thumbnail_sizes__small__filename_operator"] | undefined,
	sizes__medium__url?: ModelTypes["Event_Thumbnail_sizes__medium__url_operator"] | undefined,
	sizes__medium__width?: ModelTypes["Event_Thumbnail_sizes__medium__width_operator"] | undefined,
	sizes__medium__height?: ModelTypes["Event_Thumbnail_sizes__medium__height_operator"] | undefined,
	sizes__medium__mimeType?: ModelTypes["Event_Thumbnail_sizes__medium__mimeType_operator"] | undefined,
	sizes__medium__filesize?: ModelTypes["Event_Thumbnail_sizes__medium__filesize_operator"] | undefined,
	sizes__medium__filename?: ModelTypes["Event_Thumbnail_sizes__medium__filename_operator"] | undefined,
	sizes__large__url?: ModelTypes["Event_Thumbnail_sizes__large__url_operator"] | undefined,
	sizes__large__width?: ModelTypes["Event_Thumbnail_sizes__large__width_operator"] | undefined,
	sizes__large__height?: ModelTypes["Event_Thumbnail_sizes__large__height_operator"] | undefined,
	sizes__large__mimeType?: ModelTypes["Event_Thumbnail_sizes__large__mimeType_operator"] | undefined,
	sizes__large__filesize?: ModelTypes["Event_Thumbnail_sizes__large__filesize_operator"] | undefined,
	sizes__large__filename?: ModelTypes["Event_Thumbnail_sizes__large__filename_operator"] | undefined,
	sizes__small_jpeg__url?: ModelTypes["Event_Thumbnail_sizes__small_jpeg__url_operator"] | undefined,
	sizes__small_jpeg__width?: ModelTypes["Event_Thumbnail_sizes__small_jpeg__width_operator"] | undefined,
	sizes__small_jpeg__height?: ModelTypes["Event_Thumbnail_sizes__small_jpeg__height_operator"] | undefined,
	sizes__small_jpeg__mimeType?: ModelTypes["Event_Thumbnail_sizes__small_jpeg__mimeType_operator"] | undefined,
	sizes__small_jpeg__filesize?: ModelTypes["Event_Thumbnail_sizes__small_jpeg__filesize_operator"] | undefined,
	sizes__small_jpeg__filename?: ModelTypes["Event_Thumbnail_sizes__small_jpeg__filename_operator"] | undefined,
	sizes__medium_jpeg__url?: ModelTypes["Event_Thumbnail_sizes__medium_jpeg__url_operator"] | undefined,
	sizes__medium_jpeg__width?: ModelTypes["Event_Thumbnail_sizes__medium_jpeg__width_operator"] | undefined,
	sizes__medium_jpeg__height?: ModelTypes["Event_Thumbnail_sizes__medium_jpeg__height_operator"] | undefined,
	sizes__medium_jpeg__mimeType?: ModelTypes["Event_Thumbnail_sizes__medium_jpeg__mimeType_operator"] | undefined,
	sizes__medium_jpeg__filesize?: ModelTypes["Event_Thumbnail_sizes__medium_jpeg__filesize_operator"] | undefined,
	sizes__medium_jpeg__filename?: ModelTypes["Event_Thumbnail_sizes__medium_jpeg__filename_operator"] | undefined,
	sizes__large_jpeg__url?: ModelTypes["Event_Thumbnail_sizes__large_jpeg__url_operator"] | undefined,
	sizes__large_jpeg__width?: ModelTypes["Event_Thumbnail_sizes__large_jpeg__width_operator"] | undefined,
	sizes__large_jpeg__height?: ModelTypes["Event_Thumbnail_sizes__large_jpeg__height_operator"] | undefined,
	sizes__large_jpeg__mimeType?: ModelTypes["Event_Thumbnail_sizes__large_jpeg__mimeType_operator"] | undefined,
	sizes__large_jpeg__filesize?: ModelTypes["Event_Thumbnail_sizes__large_jpeg__filesize_operator"] | undefined,
	sizes__large_jpeg__filename?: ModelTypes["Event_Thumbnail_sizes__large_jpeg__filename_operator"] | undefined,
	id?: ModelTypes["Event_Thumbnail_id_operator"] | undefined,
	AND?: Array<ModelTypes["Event_Thumbnail_where_and"] | undefined> | undefined,
	OR?: Array<ModelTypes["Event_Thumbnail_where_or"] | undefined> | undefined
};
	["Event_Banner_where"]: {
	alt?: ModelTypes["Event_Banner_alt_operator"] | undefined,
	updatedAt?: ModelTypes["Event_Banner_updatedAt_operator"] | undefined,
	createdAt?: ModelTypes["Event_Banner_createdAt_operator"] | undefined,
	url?: ModelTypes["Event_Banner_url_operator"] | undefined,
	filename?: ModelTypes["Event_Banner_filename_operator"] | undefined,
	mimeType?: ModelTypes["Event_Banner_mimeType_operator"] | undefined,
	filesize?: ModelTypes["Event_Banner_filesize_operator"] | undefined,
	width?: ModelTypes["Event_Banner_width_operator"] | undefined,
	height?: ModelTypes["Event_Banner_height_operator"] | undefined,
	focalX?: ModelTypes["Event_Banner_focalX_operator"] | undefined,
	focalY?: ModelTypes["Event_Banner_focalY_operator"] | undefined,
	sizes__small__url?: ModelTypes["Event_Banner_sizes__small__url_operator"] | undefined,
	sizes__small__width?: ModelTypes["Event_Banner_sizes__small__width_operator"] | undefined,
	sizes__small__height?: ModelTypes["Event_Banner_sizes__small__height_operator"] | undefined,
	sizes__small__mimeType?: ModelTypes["Event_Banner_sizes__small__mimeType_operator"] | undefined,
	sizes__small__filesize?: ModelTypes["Event_Banner_sizes__small__filesize_operator"] | undefined,
	sizes__small__filename?: ModelTypes["Event_Banner_sizes__small__filename_operator"] | undefined,
	sizes__medium__url?: ModelTypes["Event_Banner_sizes__medium__url_operator"] | undefined,
	sizes__medium__width?: ModelTypes["Event_Banner_sizes__medium__width_operator"] | undefined,
	sizes__medium__height?: ModelTypes["Event_Banner_sizes__medium__height_operator"] | undefined,
	sizes__medium__mimeType?: ModelTypes["Event_Banner_sizes__medium__mimeType_operator"] | undefined,
	sizes__medium__filesize?: ModelTypes["Event_Banner_sizes__medium__filesize_operator"] | undefined,
	sizes__medium__filename?: ModelTypes["Event_Banner_sizes__medium__filename_operator"] | undefined,
	sizes__large__url?: ModelTypes["Event_Banner_sizes__large__url_operator"] | undefined,
	sizes__large__width?: ModelTypes["Event_Banner_sizes__large__width_operator"] | undefined,
	sizes__large__height?: ModelTypes["Event_Banner_sizes__large__height_operator"] | undefined,
	sizes__large__mimeType?: ModelTypes["Event_Banner_sizes__large__mimeType_operator"] | undefined,
	sizes__large__filesize?: ModelTypes["Event_Banner_sizes__large__filesize_operator"] | undefined,
	sizes__large__filename?: ModelTypes["Event_Banner_sizes__large__filename_operator"] | undefined,
	sizes__small_jpeg__url?: ModelTypes["Event_Banner_sizes__small_jpeg__url_operator"] | undefined,
	sizes__small_jpeg__width?: ModelTypes["Event_Banner_sizes__small_jpeg__width_operator"] | undefined,
	sizes__small_jpeg__height?: ModelTypes["Event_Banner_sizes__small_jpeg__height_operator"] | undefined,
	sizes__small_jpeg__mimeType?: ModelTypes["Event_Banner_sizes__small_jpeg__mimeType_operator"] | undefined,
	sizes__small_jpeg__filesize?: ModelTypes["Event_Banner_sizes__small_jpeg__filesize_operator"] | undefined,
	sizes__small_jpeg__filename?: ModelTypes["Event_Banner_sizes__small_jpeg__filename_operator"] | undefined,
	sizes__medium_jpeg__url?: ModelTypes["Event_Banner_sizes__medium_jpeg__url_operator"] | undefined,
	sizes__medium_jpeg__width?: ModelTypes["Event_Banner_sizes__medium_jpeg__width_operator"] | undefined,
	sizes__medium_jpeg__height?: ModelTypes["Event_Banner_sizes__medium_jpeg__height_operator"] | undefined,
	sizes__medium_jpeg__mimeType?: ModelTypes["Event_Banner_sizes__medium_jpeg__mimeType_operator"] | undefined,
	sizes__medium_jpeg__filesize?: ModelTypes["Event_Banner_sizes__medium_jpeg__filesize_operator"] | undefined,
	sizes__medium_jpeg__filename?: ModelTypes["Event_Banner_sizes__medium_jpeg__filename_operator"] | undefined,
	sizes__large_jpeg__url?: ModelTypes["Event_Banner_sizes__large_jpeg__url_operator"] | undefined,
	sizes__large_jpeg__width?: ModelTypes["Event_Banner_sizes__large_jpeg__width_operator"] | undefined,
	sizes__large_jpeg__height?: ModelTypes["Event_Banner_sizes__large_jpeg__height_operator"] | undefined,
	sizes__large_jpeg__mimeType?: ModelTypes["Event_Banner_sizes__large_jpeg__mimeType_operator"] | undefined,
	sizes__large_jpeg__filesize?: ModelTypes["Event_Banner_sizes__large_jpeg__filesize_operator"] | undefined,
	sizes__large_jpeg__filename?: ModelTypes["Event_Banner_sizes__large_jpeg__filename_operator"] | undefined,
	id?: ModelTypes["Event_Banner_id_operator"] | undefined,
	AND?: Array<ModelTypes["Event_Banner_where_and"] | undefined> | undefined,
	OR?: Array<ModelTypes["Event_Banner_where_or"] | undefined> | undefined
};
	["Event_Banner_alt_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Event_Banner_updatedAt_operator"]: {
	equals?: ModelTypes["DateTime"] | undefined,
	not_equals?: ModelTypes["DateTime"] | undefined,
	greater_than_equal?: ModelTypes["DateTime"] | undefined,
	greater_than?: ModelTypes["DateTime"] | undefined,
	less_than_equal?: ModelTypes["DateTime"] | undefined,
	less_than?: ModelTypes["DateTime"] | undefined,
	like?: ModelTypes["DateTime"] | undefined,
	exists?: boolean | undefined
};
	["Event_Banner_createdAt_operator"]: {
	equals?: ModelTypes["DateTime"] | undefined,
	not_equals?: ModelTypes["DateTime"] | undefined,
	greater_than_equal?: ModelTypes["DateTime"] | undefined,
	greater_than?: ModelTypes["DateTime"] | undefined,
	less_than_equal?: ModelTypes["DateTime"] | undefined,
	less_than?: ModelTypes["DateTime"] | undefined,
	like?: ModelTypes["DateTime"] | undefined,
	exists?: boolean | undefined
};
	["Event_Banner_url_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Event_Banner_filename_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Event_Banner_mimeType_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Event_Banner_filesize_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Event_Banner_width_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Event_Banner_height_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Event_Banner_focalX_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Event_Banner_focalY_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Event_Banner_sizes__small__url_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Event_Banner_sizes__small__width_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Event_Banner_sizes__small__height_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Event_Banner_sizes__small__mimeType_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Event_Banner_sizes__small__filesize_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Event_Banner_sizes__small__filename_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Event_Banner_sizes__medium__url_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Event_Banner_sizes__medium__width_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Event_Banner_sizes__medium__height_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Event_Banner_sizes__medium__mimeType_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Event_Banner_sizes__medium__filesize_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Event_Banner_sizes__medium__filename_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Event_Banner_sizes__large__url_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Event_Banner_sizes__large__width_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Event_Banner_sizes__large__height_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Event_Banner_sizes__large__mimeType_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Event_Banner_sizes__large__filesize_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Event_Banner_sizes__large__filename_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Event_Banner_sizes__small_jpeg__url_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Event_Banner_sizes__small_jpeg__width_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Event_Banner_sizes__small_jpeg__height_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Event_Banner_sizes__small_jpeg__mimeType_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Event_Banner_sizes__small_jpeg__filesize_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Event_Banner_sizes__small_jpeg__filename_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Event_Banner_sizes__medium_jpeg__url_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Event_Banner_sizes__medium_jpeg__width_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Event_Banner_sizes__medium_jpeg__height_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Event_Banner_sizes__medium_jpeg__mimeType_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Event_Banner_sizes__medium_jpeg__filesize_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Event_Banner_sizes__medium_jpeg__filename_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Event_Banner_sizes__large_jpeg__url_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Event_Banner_sizes__large_jpeg__width_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Event_Banner_sizes__large_jpeg__height_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Event_Banner_sizes__large_jpeg__mimeType_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Event_Banner_sizes__large_jpeg__filesize_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Event_Banner_sizes__large_jpeg__filename_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Event_Banner_id_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Event_Banner_where_and"]: {
	alt?: ModelTypes["Event_Banner_alt_operator"] | undefined,
	updatedAt?: ModelTypes["Event_Banner_updatedAt_operator"] | undefined,
	createdAt?: ModelTypes["Event_Banner_createdAt_operator"] | undefined,
	url?: ModelTypes["Event_Banner_url_operator"] | undefined,
	filename?: ModelTypes["Event_Banner_filename_operator"] | undefined,
	mimeType?: ModelTypes["Event_Banner_mimeType_operator"] | undefined,
	filesize?: ModelTypes["Event_Banner_filesize_operator"] | undefined,
	width?: ModelTypes["Event_Banner_width_operator"] | undefined,
	height?: ModelTypes["Event_Banner_height_operator"] | undefined,
	focalX?: ModelTypes["Event_Banner_focalX_operator"] | undefined,
	focalY?: ModelTypes["Event_Banner_focalY_operator"] | undefined,
	sizes__small__url?: ModelTypes["Event_Banner_sizes__small__url_operator"] | undefined,
	sizes__small__width?: ModelTypes["Event_Banner_sizes__small__width_operator"] | undefined,
	sizes__small__height?: ModelTypes["Event_Banner_sizes__small__height_operator"] | undefined,
	sizes__small__mimeType?: ModelTypes["Event_Banner_sizes__small__mimeType_operator"] | undefined,
	sizes__small__filesize?: ModelTypes["Event_Banner_sizes__small__filesize_operator"] | undefined,
	sizes__small__filename?: ModelTypes["Event_Banner_sizes__small__filename_operator"] | undefined,
	sizes__medium__url?: ModelTypes["Event_Banner_sizes__medium__url_operator"] | undefined,
	sizes__medium__width?: ModelTypes["Event_Banner_sizes__medium__width_operator"] | undefined,
	sizes__medium__height?: ModelTypes["Event_Banner_sizes__medium__height_operator"] | undefined,
	sizes__medium__mimeType?: ModelTypes["Event_Banner_sizes__medium__mimeType_operator"] | undefined,
	sizes__medium__filesize?: ModelTypes["Event_Banner_sizes__medium__filesize_operator"] | undefined,
	sizes__medium__filename?: ModelTypes["Event_Banner_sizes__medium__filename_operator"] | undefined,
	sizes__large__url?: ModelTypes["Event_Banner_sizes__large__url_operator"] | undefined,
	sizes__large__width?: ModelTypes["Event_Banner_sizes__large__width_operator"] | undefined,
	sizes__large__height?: ModelTypes["Event_Banner_sizes__large__height_operator"] | undefined,
	sizes__large__mimeType?: ModelTypes["Event_Banner_sizes__large__mimeType_operator"] | undefined,
	sizes__large__filesize?: ModelTypes["Event_Banner_sizes__large__filesize_operator"] | undefined,
	sizes__large__filename?: ModelTypes["Event_Banner_sizes__large__filename_operator"] | undefined,
	sizes__small_jpeg__url?: ModelTypes["Event_Banner_sizes__small_jpeg__url_operator"] | undefined,
	sizes__small_jpeg__width?: ModelTypes["Event_Banner_sizes__small_jpeg__width_operator"] | undefined,
	sizes__small_jpeg__height?: ModelTypes["Event_Banner_sizes__small_jpeg__height_operator"] | undefined,
	sizes__small_jpeg__mimeType?: ModelTypes["Event_Banner_sizes__small_jpeg__mimeType_operator"] | undefined,
	sizes__small_jpeg__filesize?: ModelTypes["Event_Banner_sizes__small_jpeg__filesize_operator"] | undefined,
	sizes__small_jpeg__filename?: ModelTypes["Event_Banner_sizes__small_jpeg__filename_operator"] | undefined,
	sizes__medium_jpeg__url?: ModelTypes["Event_Banner_sizes__medium_jpeg__url_operator"] | undefined,
	sizes__medium_jpeg__width?: ModelTypes["Event_Banner_sizes__medium_jpeg__width_operator"] | undefined,
	sizes__medium_jpeg__height?: ModelTypes["Event_Banner_sizes__medium_jpeg__height_operator"] | undefined,
	sizes__medium_jpeg__mimeType?: ModelTypes["Event_Banner_sizes__medium_jpeg__mimeType_operator"] | undefined,
	sizes__medium_jpeg__filesize?: ModelTypes["Event_Banner_sizes__medium_jpeg__filesize_operator"] | undefined,
	sizes__medium_jpeg__filename?: ModelTypes["Event_Banner_sizes__medium_jpeg__filename_operator"] | undefined,
	sizes__large_jpeg__url?: ModelTypes["Event_Banner_sizes__large_jpeg__url_operator"] | undefined,
	sizes__large_jpeg__width?: ModelTypes["Event_Banner_sizes__large_jpeg__width_operator"] | undefined,
	sizes__large_jpeg__height?: ModelTypes["Event_Banner_sizes__large_jpeg__height_operator"] | undefined,
	sizes__large_jpeg__mimeType?: ModelTypes["Event_Banner_sizes__large_jpeg__mimeType_operator"] | undefined,
	sizes__large_jpeg__filesize?: ModelTypes["Event_Banner_sizes__large_jpeg__filesize_operator"] | undefined,
	sizes__large_jpeg__filename?: ModelTypes["Event_Banner_sizes__large_jpeg__filename_operator"] | undefined,
	id?: ModelTypes["Event_Banner_id_operator"] | undefined,
	AND?: Array<ModelTypes["Event_Banner_where_and"] | undefined> | undefined,
	OR?: Array<ModelTypes["Event_Banner_where_or"] | undefined> | undefined
};
	["Event_Banner_where_or"]: {
	alt?: ModelTypes["Event_Banner_alt_operator"] | undefined,
	updatedAt?: ModelTypes["Event_Banner_updatedAt_operator"] | undefined,
	createdAt?: ModelTypes["Event_Banner_createdAt_operator"] | undefined,
	url?: ModelTypes["Event_Banner_url_operator"] | undefined,
	filename?: ModelTypes["Event_Banner_filename_operator"] | undefined,
	mimeType?: ModelTypes["Event_Banner_mimeType_operator"] | undefined,
	filesize?: ModelTypes["Event_Banner_filesize_operator"] | undefined,
	width?: ModelTypes["Event_Banner_width_operator"] | undefined,
	height?: ModelTypes["Event_Banner_height_operator"] | undefined,
	focalX?: ModelTypes["Event_Banner_focalX_operator"] | undefined,
	focalY?: ModelTypes["Event_Banner_focalY_operator"] | undefined,
	sizes__small__url?: ModelTypes["Event_Banner_sizes__small__url_operator"] | undefined,
	sizes__small__width?: ModelTypes["Event_Banner_sizes__small__width_operator"] | undefined,
	sizes__small__height?: ModelTypes["Event_Banner_sizes__small__height_operator"] | undefined,
	sizes__small__mimeType?: ModelTypes["Event_Banner_sizes__small__mimeType_operator"] | undefined,
	sizes__small__filesize?: ModelTypes["Event_Banner_sizes__small__filesize_operator"] | undefined,
	sizes__small__filename?: ModelTypes["Event_Banner_sizes__small__filename_operator"] | undefined,
	sizes__medium__url?: ModelTypes["Event_Banner_sizes__medium__url_operator"] | undefined,
	sizes__medium__width?: ModelTypes["Event_Banner_sizes__medium__width_operator"] | undefined,
	sizes__medium__height?: ModelTypes["Event_Banner_sizes__medium__height_operator"] | undefined,
	sizes__medium__mimeType?: ModelTypes["Event_Banner_sizes__medium__mimeType_operator"] | undefined,
	sizes__medium__filesize?: ModelTypes["Event_Banner_sizes__medium__filesize_operator"] | undefined,
	sizes__medium__filename?: ModelTypes["Event_Banner_sizes__medium__filename_operator"] | undefined,
	sizes__large__url?: ModelTypes["Event_Banner_sizes__large__url_operator"] | undefined,
	sizes__large__width?: ModelTypes["Event_Banner_sizes__large__width_operator"] | undefined,
	sizes__large__height?: ModelTypes["Event_Banner_sizes__large__height_operator"] | undefined,
	sizes__large__mimeType?: ModelTypes["Event_Banner_sizes__large__mimeType_operator"] | undefined,
	sizes__large__filesize?: ModelTypes["Event_Banner_sizes__large__filesize_operator"] | undefined,
	sizes__large__filename?: ModelTypes["Event_Banner_sizes__large__filename_operator"] | undefined,
	sizes__small_jpeg__url?: ModelTypes["Event_Banner_sizes__small_jpeg__url_operator"] | undefined,
	sizes__small_jpeg__width?: ModelTypes["Event_Banner_sizes__small_jpeg__width_operator"] | undefined,
	sizes__small_jpeg__height?: ModelTypes["Event_Banner_sizes__small_jpeg__height_operator"] | undefined,
	sizes__small_jpeg__mimeType?: ModelTypes["Event_Banner_sizes__small_jpeg__mimeType_operator"] | undefined,
	sizes__small_jpeg__filesize?: ModelTypes["Event_Banner_sizes__small_jpeg__filesize_operator"] | undefined,
	sizes__small_jpeg__filename?: ModelTypes["Event_Banner_sizes__small_jpeg__filename_operator"] | undefined,
	sizes__medium_jpeg__url?: ModelTypes["Event_Banner_sizes__medium_jpeg__url_operator"] | undefined,
	sizes__medium_jpeg__width?: ModelTypes["Event_Banner_sizes__medium_jpeg__width_operator"] | undefined,
	sizes__medium_jpeg__height?: ModelTypes["Event_Banner_sizes__medium_jpeg__height_operator"] | undefined,
	sizes__medium_jpeg__mimeType?: ModelTypes["Event_Banner_sizes__medium_jpeg__mimeType_operator"] | undefined,
	sizes__medium_jpeg__filesize?: ModelTypes["Event_Banner_sizes__medium_jpeg__filesize_operator"] | undefined,
	sizes__medium_jpeg__filename?: ModelTypes["Event_Banner_sizes__medium_jpeg__filename_operator"] | undefined,
	sizes__large_jpeg__url?: ModelTypes["Event_Banner_sizes__large_jpeg__url_operator"] | undefined,
	sizes__large_jpeg__width?: ModelTypes["Event_Banner_sizes__large_jpeg__width_operator"] | undefined,
	sizes__large_jpeg__height?: ModelTypes["Event_Banner_sizes__large_jpeg__height_operator"] | undefined,
	sizes__large_jpeg__mimeType?: ModelTypes["Event_Banner_sizes__large_jpeg__mimeType_operator"] | undefined,
	sizes__large_jpeg__filesize?: ModelTypes["Event_Banner_sizes__large_jpeg__filesize_operator"] | undefined,
	sizes__large_jpeg__filename?: ModelTypes["Event_Banner_sizes__large_jpeg__filename_operator"] | undefined,
	id?: ModelTypes["Event_Banner_id_operator"] | undefined,
	AND?: Array<ModelTypes["Event_Banner_where_and"] | undefined> | undefined,
	OR?: Array<ModelTypes["Event_Banner_where_or"] | undefined> | undefined
};
	["Event_MobileBanner_where"]: {
	alt?: ModelTypes["Event_MobileBanner_alt_operator"] | undefined,
	updatedAt?: ModelTypes["Event_MobileBanner_updatedAt_operator"] | undefined,
	createdAt?: ModelTypes["Event_MobileBanner_createdAt_operator"] | undefined,
	url?: ModelTypes["Event_MobileBanner_url_operator"] | undefined,
	filename?: ModelTypes["Event_MobileBanner_filename_operator"] | undefined,
	mimeType?: ModelTypes["Event_MobileBanner_mimeType_operator"] | undefined,
	filesize?: ModelTypes["Event_MobileBanner_filesize_operator"] | undefined,
	width?: ModelTypes["Event_MobileBanner_width_operator"] | undefined,
	height?: ModelTypes["Event_MobileBanner_height_operator"] | undefined,
	focalX?: ModelTypes["Event_MobileBanner_focalX_operator"] | undefined,
	focalY?: ModelTypes["Event_MobileBanner_focalY_operator"] | undefined,
	sizes__small__url?: ModelTypes["Event_MobileBanner_sizes__small__url_operator"] | undefined,
	sizes__small__width?: ModelTypes["Event_MobileBanner_sizes__small__width_operator"] | undefined,
	sizes__small__height?: ModelTypes["Event_MobileBanner_sizes__small__height_operator"] | undefined,
	sizes__small__mimeType?: ModelTypes["Event_MobileBanner_sizes__small__mimeType_operator"] | undefined,
	sizes__small__filesize?: ModelTypes["Event_MobileBanner_sizes__small__filesize_operator"] | undefined,
	sizes__small__filename?: ModelTypes["Event_MobileBanner_sizes__small__filename_operator"] | undefined,
	sizes__medium__url?: ModelTypes["Event_MobileBanner_sizes__medium__url_operator"] | undefined,
	sizes__medium__width?: ModelTypes["Event_MobileBanner_sizes__medium__width_operator"] | undefined,
	sizes__medium__height?: ModelTypes["Event_MobileBanner_sizes__medium__height_operator"] | undefined,
	sizes__medium__mimeType?: ModelTypes["Event_MobileBanner_sizes__medium__mimeType_operator"] | undefined,
	sizes__medium__filesize?: ModelTypes["Event_MobileBanner_sizes__medium__filesize_operator"] | undefined,
	sizes__medium__filename?: ModelTypes["Event_MobileBanner_sizes__medium__filename_operator"] | undefined,
	sizes__large__url?: ModelTypes["Event_MobileBanner_sizes__large__url_operator"] | undefined,
	sizes__large__width?: ModelTypes["Event_MobileBanner_sizes__large__width_operator"] | undefined,
	sizes__large__height?: ModelTypes["Event_MobileBanner_sizes__large__height_operator"] | undefined,
	sizes__large__mimeType?: ModelTypes["Event_MobileBanner_sizes__large__mimeType_operator"] | undefined,
	sizes__large__filesize?: ModelTypes["Event_MobileBanner_sizes__large__filesize_operator"] | undefined,
	sizes__large__filename?: ModelTypes["Event_MobileBanner_sizes__large__filename_operator"] | undefined,
	sizes__small_jpeg__url?: ModelTypes["Event_MobileBanner_sizes__small_jpeg__url_operator"] | undefined,
	sizes__small_jpeg__width?: ModelTypes["Event_MobileBanner_sizes__small_jpeg__width_operator"] | undefined,
	sizes__small_jpeg__height?: ModelTypes["Event_MobileBanner_sizes__small_jpeg__height_operator"] | undefined,
	sizes__small_jpeg__mimeType?: ModelTypes["Event_MobileBanner_sizes__small_jpeg__mimeType_operator"] | undefined,
	sizes__small_jpeg__filesize?: ModelTypes["Event_MobileBanner_sizes__small_jpeg__filesize_operator"] | undefined,
	sizes__small_jpeg__filename?: ModelTypes["Event_MobileBanner_sizes__small_jpeg__filename_operator"] | undefined,
	sizes__medium_jpeg__url?: ModelTypes["Event_MobileBanner_sizes__medium_jpeg__url_operator"] | undefined,
	sizes__medium_jpeg__width?: ModelTypes["Event_MobileBanner_sizes__medium_jpeg__width_operator"] | undefined,
	sizes__medium_jpeg__height?: ModelTypes["Event_MobileBanner_sizes__medium_jpeg__height_operator"] | undefined,
	sizes__medium_jpeg__mimeType?: ModelTypes["Event_MobileBanner_sizes__medium_jpeg__mimeType_operator"] | undefined,
	sizes__medium_jpeg__filesize?: ModelTypes["Event_MobileBanner_sizes__medium_jpeg__filesize_operator"] | undefined,
	sizes__medium_jpeg__filename?: ModelTypes["Event_MobileBanner_sizes__medium_jpeg__filename_operator"] | undefined,
	sizes__large_jpeg__url?: ModelTypes["Event_MobileBanner_sizes__large_jpeg__url_operator"] | undefined,
	sizes__large_jpeg__width?: ModelTypes["Event_MobileBanner_sizes__large_jpeg__width_operator"] | undefined,
	sizes__large_jpeg__height?: ModelTypes["Event_MobileBanner_sizes__large_jpeg__height_operator"] | undefined,
	sizes__large_jpeg__mimeType?: ModelTypes["Event_MobileBanner_sizes__large_jpeg__mimeType_operator"] | undefined,
	sizes__large_jpeg__filesize?: ModelTypes["Event_MobileBanner_sizes__large_jpeg__filesize_operator"] | undefined,
	sizes__large_jpeg__filename?: ModelTypes["Event_MobileBanner_sizes__large_jpeg__filename_operator"] | undefined,
	id?: ModelTypes["Event_MobileBanner_id_operator"] | undefined,
	AND?: Array<ModelTypes["Event_MobileBanner_where_and"] | undefined> | undefined,
	OR?: Array<ModelTypes["Event_MobileBanner_where_or"] | undefined> | undefined
};
	["Event_MobileBanner_alt_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Event_MobileBanner_updatedAt_operator"]: {
	equals?: ModelTypes["DateTime"] | undefined,
	not_equals?: ModelTypes["DateTime"] | undefined,
	greater_than_equal?: ModelTypes["DateTime"] | undefined,
	greater_than?: ModelTypes["DateTime"] | undefined,
	less_than_equal?: ModelTypes["DateTime"] | undefined,
	less_than?: ModelTypes["DateTime"] | undefined,
	like?: ModelTypes["DateTime"] | undefined,
	exists?: boolean | undefined
};
	["Event_MobileBanner_createdAt_operator"]: {
	equals?: ModelTypes["DateTime"] | undefined,
	not_equals?: ModelTypes["DateTime"] | undefined,
	greater_than_equal?: ModelTypes["DateTime"] | undefined,
	greater_than?: ModelTypes["DateTime"] | undefined,
	less_than_equal?: ModelTypes["DateTime"] | undefined,
	less_than?: ModelTypes["DateTime"] | undefined,
	like?: ModelTypes["DateTime"] | undefined,
	exists?: boolean | undefined
};
	["Event_MobileBanner_url_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Event_MobileBanner_filename_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Event_MobileBanner_mimeType_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Event_MobileBanner_filesize_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Event_MobileBanner_width_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Event_MobileBanner_height_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Event_MobileBanner_focalX_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Event_MobileBanner_focalY_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Event_MobileBanner_sizes__small__url_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Event_MobileBanner_sizes__small__width_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Event_MobileBanner_sizes__small__height_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Event_MobileBanner_sizes__small__mimeType_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Event_MobileBanner_sizes__small__filesize_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Event_MobileBanner_sizes__small__filename_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Event_MobileBanner_sizes__medium__url_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Event_MobileBanner_sizes__medium__width_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Event_MobileBanner_sizes__medium__height_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Event_MobileBanner_sizes__medium__mimeType_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Event_MobileBanner_sizes__medium__filesize_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Event_MobileBanner_sizes__medium__filename_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Event_MobileBanner_sizes__large__url_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Event_MobileBanner_sizes__large__width_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Event_MobileBanner_sizes__large__height_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Event_MobileBanner_sizes__large__mimeType_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Event_MobileBanner_sizes__large__filesize_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Event_MobileBanner_sizes__large__filename_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Event_MobileBanner_sizes__small_jpeg__url_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Event_MobileBanner_sizes__small_jpeg__width_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Event_MobileBanner_sizes__small_jpeg__height_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Event_MobileBanner_sizes__small_jpeg__mimeType_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Event_MobileBanner_sizes__small_jpeg__filesize_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Event_MobileBanner_sizes__small_jpeg__filename_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Event_MobileBanner_sizes__medium_jpeg__url_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Event_MobileBanner_sizes__medium_jpeg__width_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Event_MobileBanner_sizes__medium_jpeg__height_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Event_MobileBanner_sizes__medium_jpeg__mimeType_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Event_MobileBanner_sizes__medium_jpeg__filesize_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Event_MobileBanner_sizes__medium_jpeg__filename_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Event_MobileBanner_sizes__large_jpeg__url_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Event_MobileBanner_sizes__large_jpeg__width_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Event_MobileBanner_sizes__large_jpeg__height_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Event_MobileBanner_sizes__large_jpeg__mimeType_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Event_MobileBanner_sizes__large_jpeg__filesize_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Event_MobileBanner_sizes__large_jpeg__filename_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Event_MobileBanner_id_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Event_MobileBanner_where_and"]: {
	alt?: ModelTypes["Event_MobileBanner_alt_operator"] | undefined,
	updatedAt?: ModelTypes["Event_MobileBanner_updatedAt_operator"] | undefined,
	createdAt?: ModelTypes["Event_MobileBanner_createdAt_operator"] | undefined,
	url?: ModelTypes["Event_MobileBanner_url_operator"] | undefined,
	filename?: ModelTypes["Event_MobileBanner_filename_operator"] | undefined,
	mimeType?: ModelTypes["Event_MobileBanner_mimeType_operator"] | undefined,
	filesize?: ModelTypes["Event_MobileBanner_filesize_operator"] | undefined,
	width?: ModelTypes["Event_MobileBanner_width_operator"] | undefined,
	height?: ModelTypes["Event_MobileBanner_height_operator"] | undefined,
	focalX?: ModelTypes["Event_MobileBanner_focalX_operator"] | undefined,
	focalY?: ModelTypes["Event_MobileBanner_focalY_operator"] | undefined,
	sizes__small__url?: ModelTypes["Event_MobileBanner_sizes__small__url_operator"] | undefined,
	sizes__small__width?: ModelTypes["Event_MobileBanner_sizes__small__width_operator"] | undefined,
	sizes__small__height?: ModelTypes["Event_MobileBanner_sizes__small__height_operator"] | undefined,
	sizes__small__mimeType?: ModelTypes["Event_MobileBanner_sizes__small__mimeType_operator"] | undefined,
	sizes__small__filesize?: ModelTypes["Event_MobileBanner_sizes__small__filesize_operator"] | undefined,
	sizes__small__filename?: ModelTypes["Event_MobileBanner_sizes__small__filename_operator"] | undefined,
	sizes__medium__url?: ModelTypes["Event_MobileBanner_sizes__medium__url_operator"] | undefined,
	sizes__medium__width?: ModelTypes["Event_MobileBanner_sizes__medium__width_operator"] | undefined,
	sizes__medium__height?: ModelTypes["Event_MobileBanner_sizes__medium__height_operator"] | undefined,
	sizes__medium__mimeType?: ModelTypes["Event_MobileBanner_sizes__medium__mimeType_operator"] | undefined,
	sizes__medium__filesize?: ModelTypes["Event_MobileBanner_sizes__medium__filesize_operator"] | undefined,
	sizes__medium__filename?: ModelTypes["Event_MobileBanner_sizes__medium__filename_operator"] | undefined,
	sizes__large__url?: ModelTypes["Event_MobileBanner_sizes__large__url_operator"] | undefined,
	sizes__large__width?: ModelTypes["Event_MobileBanner_sizes__large__width_operator"] | undefined,
	sizes__large__height?: ModelTypes["Event_MobileBanner_sizes__large__height_operator"] | undefined,
	sizes__large__mimeType?: ModelTypes["Event_MobileBanner_sizes__large__mimeType_operator"] | undefined,
	sizes__large__filesize?: ModelTypes["Event_MobileBanner_sizes__large__filesize_operator"] | undefined,
	sizes__large__filename?: ModelTypes["Event_MobileBanner_sizes__large__filename_operator"] | undefined,
	sizes__small_jpeg__url?: ModelTypes["Event_MobileBanner_sizes__small_jpeg__url_operator"] | undefined,
	sizes__small_jpeg__width?: ModelTypes["Event_MobileBanner_sizes__small_jpeg__width_operator"] | undefined,
	sizes__small_jpeg__height?: ModelTypes["Event_MobileBanner_sizes__small_jpeg__height_operator"] | undefined,
	sizes__small_jpeg__mimeType?: ModelTypes["Event_MobileBanner_sizes__small_jpeg__mimeType_operator"] | undefined,
	sizes__small_jpeg__filesize?: ModelTypes["Event_MobileBanner_sizes__small_jpeg__filesize_operator"] | undefined,
	sizes__small_jpeg__filename?: ModelTypes["Event_MobileBanner_sizes__small_jpeg__filename_operator"] | undefined,
	sizes__medium_jpeg__url?: ModelTypes["Event_MobileBanner_sizes__medium_jpeg__url_operator"] | undefined,
	sizes__medium_jpeg__width?: ModelTypes["Event_MobileBanner_sizes__medium_jpeg__width_operator"] | undefined,
	sizes__medium_jpeg__height?: ModelTypes["Event_MobileBanner_sizes__medium_jpeg__height_operator"] | undefined,
	sizes__medium_jpeg__mimeType?: ModelTypes["Event_MobileBanner_sizes__medium_jpeg__mimeType_operator"] | undefined,
	sizes__medium_jpeg__filesize?: ModelTypes["Event_MobileBanner_sizes__medium_jpeg__filesize_operator"] | undefined,
	sizes__medium_jpeg__filename?: ModelTypes["Event_MobileBanner_sizes__medium_jpeg__filename_operator"] | undefined,
	sizes__large_jpeg__url?: ModelTypes["Event_MobileBanner_sizes__large_jpeg__url_operator"] | undefined,
	sizes__large_jpeg__width?: ModelTypes["Event_MobileBanner_sizes__large_jpeg__width_operator"] | undefined,
	sizes__large_jpeg__height?: ModelTypes["Event_MobileBanner_sizes__large_jpeg__height_operator"] | undefined,
	sizes__large_jpeg__mimeType?: ModelTypes["Event_MobileBanner_sizes__large_jpeg__mimeType_operator"] | undefined,
	sizes__large_jpeg__filesize?: ModelTypes["Event_MobileBanner_sizes__large_jpeg__filesize_operator"] | undefined,
	sizes__large_jpeg__filename?: ModelTypes["Event_MobileBanner_sizes__large_jpeg__filename_operator"] | undefined,
	id?: ModelTypes["Event_MobileBanner_id_operator"] | undefined,
	AND?: Array<ModelTypes["Event_MobileBanner_where_and"] | undefined> | undefined,
	OR?: Array<ModelTypes["Event_MobileBanner_where_or"] | undefined> | undefined
};
	["Event_MobileBanner_where_or"]: {
	alt?: ModelTypes["Event_MobileBanner_alt_operator"] | undefined,
	updatedAt?: ModelTypes["Event_MobileBanner_updatedAt_operator"] | undefined,
	createdAt?: ModelTypes["Event_MobileBanner_createdAt_operator"] | undefined,
	url?: ModelTypes["Event_MobileBanner_url_operator"] | undefined,
	filename?: ModelTypes["Event_MobileBanner_filename_operator"] | undefined,
	mimeType?: ModelTypes["Event_MobileBanner_mimeType_operator"] | undefined,
	filesize?: ModelTypes["Event_MobileBanner_filesize_operator"] | undefined,
	width?: ModelTypes["Event_MobileBanner_width_operator"] | undefined,
	height?: ModelTypes["Event_MobileBanner_height_operator"] | undefined,
	focalX?: ModelTypes["Event_MobileBanner_focalX_operator"] | undefined,
	focalY?: ModelTypes["Event_MobileBanner_focalY_operator"] | undefined,
	sizes__small__url?: ModelTypes["Event_MobileBanner_sizes__small__url_operator"] | undefined,
	sizes__small__width?: ModelTypes["Event_MobileBanner_sizes__small__width_operator"] | undefined,
	sizes__small__height?: ModelTypes["Event_MobileBanner_sizes__small__height_operator"] | undefined,
	sizes__small__mimeType?: ModelTypes["Event_MobileBanner_sizes__small__mimeType_operator"] | undefined,
	sizes__small__filesize?: ModelTypes["Event_MobileBanner_sizes__small__filesize_operator"] | undefined,
	sizes__small__filename?: ModelTypes["Event_MobileBanner_sizes__small__filename_operator"] | undefined,
	sizes__medium__url?: ModelTypes["Event_MobileBanner_sizes__medium__url_operator"] | undefined,
	sizes__medium__width?: ModelTypes["Event_MobileBanner_sizes__medium__width_operator"] | undefined,
	sizes__medium__height?: ModelTypes["Event_MobileBanner_sizes__medium__height_operator"] | undefined,
	sizes__medium__mimeType?: ModelTypes["Event_MobileBanner_sizes__medium__mimeType_operator"] | undefined,
	sizes__medium__filesize?: ModelTypes["Event_MobileBanner_sizes__medium__filesize_operator"] | undefined,
	sizes__medium__filename?: ModelTypes["Event_MobileBanner_sizes__medium__filename_operator"] | undefined,
	sizes__large__url?: ModelTypes["Event_MobileBanner_sizes__large__url_operator"] | undefined,
	sizes__large__width?: ModelTypes["Event_MobileBanner_sizes__large__width_operator"] | undefined,
	sizes__large__height?: ModelTypes["Event_MobileBanner_sizes__large__height_operator"] | undefined,
	sizes__large__mimeType?: ModelTypes["Event_MobileBanner_sizes__large__mimeType_operator"] | undefined,
	sizes__large__filesize?: ModelTypes["Event_MobileBanner_sizes__large__filesize_operator"] | undefined,
	sizes__large__filename?: ModelTypes["Event_MobileBanner_sizes__large__filename_operator"] | undefined,
	sizes__small_jpeg__url?: ModelTypes["Event_MobileBanner_sizes__small_jpeg__url_operator"] | undefined,
	sizes__small_jpeg__width?: ModelTypes["Event_MobileBanner_sizes__small_jpeg__width_operator"] | undefined,
	sizes__small_jpeg__height?: ModelTypes["Event_MobileBanner_sizes__small_jpeg__height_operator"] | undefined,
	sizes__small_jpeg__mimeType?: ModelTypes["Event_MobileBanner_sizes__small_jpeg__mimeType_operator"] | undefined,
	sizes__small_jpeg__filesize?: ModelTypes["Event_MobileBanner_sizes__small_jpeg__filesize_operator"] | undefined,
	sizes__small_jpeg__filename?: ModelTypes["Event_MobileBanner_sizes__small_jpeg__filename_operator"] | undefined,
	sizes__medium_jpeg__url?: ModelTypes["Event_MobileBanner_sizes__medium_jpeg__url_operator"] | undefined,
	sizes__medium_jpeg__width?: ModelTypes["Event_MobileBanner_sizes__medium_jpeg__width_operator"] | undefined,
	sizes__medium_jpeg__height?: ModelTypes["Event_MobileBanner_sizes__medium_jpeg__height_operator"] | undefined,
	sizes__medium_jpeg__mimeType?: ModelTypes["Event_MobileBanner_sizes__medium_jpeg__mimeType_operator"] | undefined,
	sizes__medium_jpeg__filesize?: ModelTypes["Event_MobileBanner_sizes__medium_jpeg__filesize_operator"] | undefined,
	sizes__medium_jpeg__filename?: ModelTypes["Event_MobileBanner_sizes__medium_jpeg__filename_operator"] | undefined,
	sizes__large_jpeg__url?: ModelTypes["Event_MobileBanner_sizes__large_jpeg__url_operator"] | undefined,
	sizes__large_jpeg__width?: ModelTypes["Event_MobileBanner_sizes__large_jpeg__width_operator"] | undefined,
	sizes__large_jpeg__height?: ModelTypes["Event_MobileBanner_sizes__large_jpeg__height_operator"] | undefined,
	sizes__large_jpeg__mimeType?: ModelTypes["Event_MobileBanner_sizes__large_jpeg__mimeType_operator"] | undefined,
	sizes__large_jpeg__filesize?: ModelTypes["Event_MobileBanner_sizes__large_jpeg__filesize_operator"] | undefined,
	sizes__large_jpeg__filename?: ModelTypes["Event_MobileBanner_sizes__large_jpeg__filename_operator"] | undefined,
	id?: ModelTypes["Event_MobileBanner_id_operator"] | undefined,
	AND?: Array<ModelTypes["Event_MobileBanner_where_and"] | undefined> | undefined,
	OR?: Array<ModelTypes["Event_MobileBanner_where_or"] | undefined> | undefined
};
	["Event_Sections"]: {
		startDatetime?: ModelTypes["DateTime"] | undefined,
	endDatetime?: ModelTypes["DateTime"] | undefined,
	fullDay?: boolean | undefined,
	repeat?: boolean | undefined,
	toThisDay?: ModelTypes["DateTime"] | undefined,
	recurrance?: ModelTypes["Event_Sections_Recurrance"] | undefined,
	sameLocation?: boolean | undefined,
	location?: ModelTypes["Location"] | undefined,
	district?: ModelTypes["District"] | undefined,
	coordinate?: Array<number> | undefined,
	linkAddress?: boolean | undefined,
	address?: string | undefined,
	addressCoordinate?: Array<number> | undefined,
	id?: string | undefined
};
	["Event_Sections_Recurrance"]: {
		type?: ModelTypes["Event_Sections_Recurrance_type"] | undefined,
	weekday?: Array<ModelTypes["Event_Sections_Recurrance_weekday"]> | undefined
};
	["Event_Sections_Recurrance_type"]:Event_Sections_Recurrance_type;
	["Event_Sections_Recurrance_weekday"]:Event_Sections_Recurrance_weekday;
	["Location"]: {
		id?: string | undefined,
	region?: ModelTypes["Location_region"] | undefined,
	creator?: ModelTypes["User"] | undefined,
	permalink?: string | undefined,
	status: string,
	level?: ModelTypes["Location_level"] | undefined,
	parent?: Array<ModelTypes["Location"]> | undefined,
	name?: string | undefined,
	thumbnail?: ModelTypes["Media"] | undefined,
	banner?: ModelTypes["Media"] | undefined,
	type: ModelTypes["Location_type"],
	category: ModelTypes["Location_category"],
	district?: ModelTypes["District"] | undefined,
	coordinate?: Array<number> | undefined,
	linkAddress?: boolean | undefined,
	address?: string | undefined,
	addressCoordinate?: Array<number> | undefined,
	permanant?: boolean | undefined,
	Details?: ModelTypes["Location_Details"] | undefined,
	content?: Array<ModelTypes["Location_Content"]> | undefined,
	legacyContent?: string | undefined,
	showLegacyContent?: boolean | undefined,
	criteria?: ModelTypes["Location_Criteria"] | undefined,
	sections?: Array<ModelTypes["Location_Sections"]> | undefined,
	views?: number | undefined,
	_title?: string | undefined,
	legacyGallery?: Array<ModelTypes["Media"]> | undefined,
	updatedAt?: ModelTypes["DateTime"] | undefined,
	createdAt?: ModelTypes["DateTime"] | undefined
};
	["Location_region"]:Location_region;
	["Location_level"]:Location_level;
	["Location_Thumbnail_where"]: {
	alt?: ModelTypes["Location_Thumbnail_alt_operator"] | undefined,
	updatedAt?: ModelTypes["Location_Thumbnail_updatedAt_operator"] | undefined,
	createdAt?: ModelTypes["Location_Thumbnail_createdAt_operator"] | undefined,
	url?: ModelTypes["Location_Thumbnail_url_operator"] | undefined,
	filename?: ModelTypes["Location_Thumbnail_filename_operator"] | undefined,
	mimeType?: ModelTypes["Location_Thumbnail_mimeType_operator"] | undefined,
	filesize?: ModelTypes["Location_Thumbnail_filesize_operator"] | undefined,
	width?: ModelTypes["Location_Thumbnail_width_operator"] | undefined,
	height?: ModelTypes["Location_Thumbnail_height_operator"] | undefined,
	focalX?: ModelTypes["Location_Thumbnail_focalX_operator"] | undefined,
	focalY?: ModelTypes["Location_Thumbnail_focalY_operator"] | undefined,
	sizes__small__url?: ModelTypes["Location_Thumbnail_sizes__small__url_operator"] | undefined,
	sizes__small__width?: ModelTypes["Location_Thumbnail_sizes__small__width_operator"] | undefined,
	sizes__small__height?: ModelTypes["Location_Thumbnail_sizes__small__height_operator"] | undefined,
	sizes__small__mimeType?: ModelTypes["Location_Thumbnail_sizes__small__mimeType_operator"] | undefined,
	sizes__small__filesize?: ModelTypes["Location_Thumbnail_sizes__small__filesize_operator"] | undefined,
	sizes__small__filename?: ModelTypes["Location_Thumbnail_sizes__small__filename_operator"] | undefined,
	sizes__medium__url?: ModelTypes["Location_Thumbnail_sizes__medium__url_operator"] | undefined,
	sizes__medium__width?: ModelTypes["Location_Thumbnail_sizes__medium__width_operator"] | undefined,
	sizes__medium__height?: ModelTypes["Location_Thumbnail_sizes__medium__height_operator"] | undefined,
	sizes__medium__mimeType?: ModelTypes["Location_Thumbnail_sizes__medium__mimeType_operator"] | undefined,
	sizes__medium__filesize?: ModelTypes["Location_Thumbnail_sizes__medium__filesize_operator"] | undefined,
	sizes__medium__filename?: ModelTypes["Location_Thumbnail_sizes__medium__filename_operator"] | undefined,
	sizes__large__url?: ModelTypes["Location_Thumbnail_sizes__large__url_operator"] | undefined,
	sizes__large__width?: ModelTypes["Location_Thumbnail_sizes__large__width_operator"] | undefined,
	sizes__large__height?: ModelTypes["Location_Thumbnail_sizes__large__height_operator"] | undefined,
	sizes__large__mimeType?: ModelTypes["Location_Thumbnail_sizes__large__mimeType_operator"] | undefined,
	sizes__large__filesize?: ModelTypes["Location_Thumbnail_sizes__large__filesize_operator"] | undefined,
	sizes__large__filename?: ModelTypes["Location_Thumbnail_sizes__large__filename_operator"] | undefined,
	sizes__small_jpeg__url?: ModelTypes["Location_Thumbnail_sizes__small_jpeg__url_operator"] | undefined,
	sizes__small_jpeg__width?: ModelTypes["Location_Thumbnail_sizes__small_jpeg__width_operator"] | undefined,
	sizes__small_jpeg__height?: ModelTypes["Location_Thumbnail_sizes__small_jpeg__height_operator"] | undefined,
	sizes__small_jpeg__mimeType?: ModelTypes["Location_Thumbnail_sizes__small_jpeg__mimeType_operator"] | undefined,
	sizes__small_jpeg__filesize?: ModelTypes["Location_Thumbnail_sizes__small_jpeg__filesize_operator"] | undefined,
	sizes__small_jpeg__filename?: ModelTypes["Location_Thumbnail_sizes__small_jpeg__filename_operator"] | undefined,
	sizes__medium_jpeg__url?: ModelTypes["Location_Thumbnail_sizes__medium_jpeg__url_operator"] | undefined,
	sizes__medium_jpeg__width?: ModelTypes["Location_Thumbnail_sizes__medium_jpeg__width_operator"] | undefined,
	sizes__medium_jpeg__height?: ModelTypes["Location_Thumbnail_sizes__medium_jpeg__height_operator"] | undefined,
	sizes__medium_jpeg__mimeType?: ModelTypes["Location_Thumbnail_sizes__medium_jpeg__mimeType_operator"] | undefined,
	sizes__medium_jpeg__filesize?: ModelTypes["Location_Thumbnail_sizes__medium_jpeg__filesize_operator"] | undefined,
	sizes__medium_jpeg__filename?: ModelTypes["Location_Thumbnail_sizes__medium_jpeg__filename_operator"] | undefined,
	sizes__large_jpeg__url?: ModelTypes["Location_Thumbnail_sizes__large_jpeg__url_operator"] | undefined,
	sizes__large_jpeg__width?: ModelTypes["Location_Thumbnail_sizes__large_jpeg__width_operator"] | undefined,
	sizes__large_jpeg__height?: ModelTypes["Location_Thumbnail_sizes__large_jpeg__height_operator"] | undefined,
	sizes__large_jpeg__mimeType?: ModelTypes["Location_Thumbnail_sizes__large_jpeg__mimeType_operator"] | undefined,
	sizes__large_jpeg__filesize?: ModelTypes["Location_Thumbnail_sizes__large_jpeg__filesize_operator"] | undefined,
	sizes__large_jpeg__filename?: ModelTypes["Location_Thumbnail_sizes__large_jpeg__filename_operator"] | undefined,
	id?: ModelTypes["Location_Thumbnail_id_operator"] | undefined,
	AND?: Array<ModelTypes["Location_Thumbnail_where_and"] | undefined> | undefined,
	OR?: Array<ModelTypes["Location_Thumbnail_where_or"] | undefined> | undefined
};
	["Location_Thumbnail_alt_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Location_Thumbnail_updatedAt_operator"]: {
	equals?: ModelTypes["DateTime"] | undefined,
	not_equals?: ModelTypes["DateTime"] | undefined,
	greater_than_equal?: ModelTypes["DateTime"] | undefined,
	greater_than?: ModelTypes["DateTime"] | undefined,
	less_than_equal?: ModelTypes["DateTime"] | undefined,
	less_than?: ModelTypes["DateTime"] | undefined,
	like?: ModelTypes["DateTime"] | undefined,
	exists?: boolean | undefined
};
	["Location_Thumbnail_createdAt_operator"]: {
	equals?: ModelTypes["DateTime"] | undefined,
	not_equals?: ModelTypes["DateTime"] | undefined,
	greater_than_equal?: ModelTypes["DateTime"] | undefined,
	greater_than?: ModelTypes["DateTime"] | undefined,
	less_than_equal?: ModelTypes["DateTime"] | undefined,
	less_than?: ModelTypes["DateTime"] | undefined,
	like?: ModelTypes["DateTime"] | undefined,
	exists?: boolean | undefined
};
	["Location_Thumbnail_url_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Location_Thumbnail_filename_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Location_Thumbnail_mimeType_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Location_Thumbnail_filesize_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Location_Thumbnail_width_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Location_Thumbnail_height_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Location_Thumbnail_focalX_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Location_Thumbnail_focalY_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Location_Thumbnail_sizes__small__url_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Location_Thumbnail_sizes__small__width_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Location_Thumbnail_sizes__small__height_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Location_Thumbnail_sizes__small__mimeType_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Location_Thumbnail_sizes__small__filesize_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Location_Thumbnail_sizes__small__filename_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Location_Thumbnail_sizes__medium__url_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Location_Thumbnail_sizes__medium__width_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Location_Thumbnail_sizes__medium__height_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Location_Thumbnail_sizes__medium__mimeType_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Location_Thumbnail_sizes__medium__filesize_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Location_Thumbnail_sizes__medium__filename_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Location_Thumbnail_sizes__large__url_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Location_Thumbnail_sizes__large__width_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Location_Thumbnail_sizes__large__height_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Location_Thumbnail_sizes__large__mimeType_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Location_Thumbnail_sizes__large__filesize_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Location_Thumbnail_sizes__large__filename_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Location_Thumbnail_sizes__small_jpeg__url_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Location_Thumbnail_sizes__small_jpeg__width_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Location_Thumbnail_sizes__small_jpeg__height_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Location_Thumbnail_sizes__small_jpeg__mimeType_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Location_Thumbnail_sizes__small_jpeg__filesize_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Location_Thumbnail_sizes__small_jpeg__filename_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Location_Thumbnail_sizes__medium_jpeg__url_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Location_Thumbnail_sizes__medium_jpeg__width_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Location_Thumbnail_sizes__medium_jpeg__height_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Location_Thumbnail_sizes__medium_jpeg__mimeType_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Location_Thumbnail_sizes__medium_jpeg__filesize_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Location_Thumbnail_sizes__medium_jpeg__filename_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Location_Thumbnail_sizes__large_jpeg__url_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Location_Thumbnail_sizes__large_jpeg__width_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Location_Thumbnail_sizes__large_jpeg__height_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Location_Thumbnail_sizes__large_jpeg__mimeType_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Location_Thumbnail_sizes__large_jpeg__filesize_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Location_Thumbnail_sizes__large_jpeg__filename_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Location_Thumbnail_id_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Location_Thumbnail_where_and"]: {
	alt?: ModelTypes["Location_Thumbnail_alt_operator"] | undefined,
	updatedAt?: ModelTypes["Location_Thumbnail_updatedAt_operator"] | undefined,
	createdAt?: ModelTypes["Location_Thumbnail_createdAt_operator"] | undefined,
	url?: ModelTypes["Location_Thumbnail_url_operator"] | undefined,
	filename?: ModelTypes["Location_Thumbnail_filename_operator"] | undefined,
	mimeType?: ModelTypes["Location_Thumbnail_mimeType_operator"] | undefined,
	filesize?: ModelTypes["Location_Thumbnail_filesize_operator"] | undefined,
	width?: ModelTypes["Location_Thumbnail_width_operator"] | undefined,
	height?: ModelTypes["Location_Thumbnail_height_operator"] | undefined,
	focalX?: ModelTypes["Location_Thumbnail_focalX_operator"] | undefined,
	focalY?: ModelTypes["Location_Thumbnail_focalY_operator"] | undefined,
	sizes__small__url?: ModelTypes["Location_Thumbnail_sizes__small__url_operator"] | undefined,
	sizes__small__width?: ModelTypes["Location_Thumbnail_sizes__small__width_operator"] | undefined,
	sizes__small__height?: ModelTypes["Location_Thumbnail_sizes__small__height_operator"] | undefined,
	sizes__small__mimeType?: ModelTypes["Location_Thumbnail_sizes__small__mimeType_operator"] | undefined,
	sizes__small__filesize?: ModelTypes["Location_Thumbnail_sizes__small__filesize_operator"] | undefined,
	sizes__small__filename?: ModelTypes["Location_Thumbnail_sizes__small__filename_operator"] | undefined,
	sizes__medium__url?: ModelTypes["Location_Thumbnail_sizes__medium__url_operator"] | undefined,
	sizes__medium__width?: ModelTypes["Location_Thumbnail_sizes__medium__width_operator"] | undefined,
	sizes__medium__height?: ModelTypes["Location_Thumbnail_sizes__medium__height_operator"] | undefined,
	sizes__medium__mimeType?: ModelTypes["Location_Thumbnail_sizes__medium__mimeType_operator"] | undefined,
	sizes__medium__filesize?: ModelTypes["Location_Thumbnail_sizes__medium__filesize_operator"] | undefined,
	sizes__medium__filename?: ModelTypes["Location_Thumbnail_sizes__medium__filename_operator"] | undefined,
	sizes__large__url?: ModelTypes["Location_Thumbnail_sizes__large__url_operator"] | undefined,
	sizes__large__width?: ModelTypes["Location_Thumbnail_sizes__large__width_operator"] | undefined,
	sizes__large__height?: ModelTypes["Location_Thumbnail_sizes__large__height_operator"] | undefined,
	sizes__large__mimeType?: ModelTypes["Location_Thumbnail_sizes__large__mimeType_operator"] | undefined,
	sizes__large__filesize?: ModelTypes["Location_Thumbnail_sizes__large__filesize_operator"] | undefined,
	sizes__large__filename?: ModelTypes["Location_Thumbnail_sizes__large__filename_operator"] | undefined,
	sizes__small_jpeg__url?: ModelTypes["Location_Thumbnail_sizes__small_jpeg__url_operator"] | undefined,
	sizes__small_jpeg__width?: ModelTypes["Location_Thumbnail_sizes__small_jpeg__width_operator"] | undefined,
	sizes__small_jpeg__height?: ModelTypes["Location_Thumbnail_sizes__small_jpeg__height_operator"] | undefined,
	sizes__small_jpeg__mimeType?: ModelTypes["Location_Thumbnail_sizes__small_jpeg__mimeType_operator"] | undefined,
	sizes__small_jpeg__filesize?: ModelTypes["Location_Thumbnail_sizes__small_jpeg__filesize_operator"] | undefined,
	sizes__small_jpeg__filename?: ModelTypes["Location_Thumbnail_sizes__small_jpeg__filename_operator"] | undefined,
	sizes__medium_jpeg__url?: ModelTypes["Location_Thumbnail_sizes__medium_jpeg__url_operator"] | undefined,
	sizes__medium_jpeg__width?: ModelTypes["Location_Thumbnail_sizes__medium_jpeg__width_operator"] | undefined,
	sizes__medium_jpeg__height?: ModelTypes["Location_Thumbnail_sizes__medium_jpeg__height_operator"] | undefined,
	sizes__medium_jpeg__mimeType?: ModelTypes["Location_Thumbnail_sizes__medium_jpeg__mimeType_operator"] | undefined,
	sizes__medium_jpeg__filesize?: ModelTypes["Location_Thumbnail_sizes__medium_jpeg__filesize_operator"] | undefined,
	sizes__medium_jpeg__filename?: ModelTypes["Location_Thumbnail_sizes__medium_jpeg__filename_operator"] | undefined,
	sizes__large_jpeg__url?: ModelTypes["Location_Thumbnail_sizes__large_jpeg__url_operator"] | undefined,
	sizes__large_jpeg__width?: ModelTypes["Location_Thumbnail_sizes__large_jpeg__width_operator"] | undefined,
	sizes__large_jpeg__height?: ModelTypes["Location_Thumbnail_sizes__large_jpeg__height_operator"] | undefined,
	sizes__large_jpeg__mimeType?: ModelTypes["Location_Thumbnail_sizes__large_jpeg__mimeType_operator"] | undefined,
	sizes__large_jpeg__filesize?: ModelTypes["Location_Thumbnail_sizes__large_jpeg__filesize_operator"] | undefined,
	sizes__large_jpeg__filename?: ModelTypes["Location_Thumbnail_sizes__large_jpeg__filename_operator"] | undefined,
	id?: ModelTypes["Location_Thumbnail_id_operator"] | undefined,
	AND?: Array<ModelTypes["Location_Thumbnail_where_and"] | undefined> | undefined,
	OR?: Array<ModelTypes["Location_Thumbnail_where_or"] | undefined> | undefined
};
	["Location_Thumbnail_where_or"]: {
	alt?: ModelTypes["Location_Thumbnail_alt_operator"] | undefined,
	updatedAt?: ModelTypes["Location_Thumbnail_updatedAt_operator"] | undefined,
	createdAt?: ModelTypes["Location_Thumbnail_createdAt_operator"] | undefined,
	url?: ModelTypes["Location_Thumbnail_url_operator"] | undefined,
	filename?: ModelTypes["Location_Thumbnail_filename_operator"] | undefined,
	mimeType?: ModelTypes["Location_Thumbnail_mimeType_operator"] | undefined,
	filesize?: ModelTypes["Location_Thumbnail_filesize_operator"] | undefined,
	width?: ModelTypes["Location_Thumbnail_width_operator"] | undefined,
	height?: ModelTypes["Location_Thumbnail_height_operator"] | undefined,
	focalX?: ModelTypes["Location_Thumbnail_focalX_operator"] | undefined,
	focalY?: ModelTypes["Location_Thumbnail_focalY_operator"] | undefined,
	sizes__small__url?: ModelTypes["Location_Thumbnail_sizes__small__url_operator"] | undefined,
	sizes__small__width?: ModelTypes["Location_Thumbnail_sizes__small__width_operator"] | undefined,
	sizes__small__height?: ModelTypes["Location_Thumbnail_sizes__small__height_operator"] | undefined,
	sizes__small__mimeType?: ModelTypes["Location_Thumbnail_sizes__small__mimeType_operator"] | undefined,
	sizes__small__filesize?: ModelTypes["Location_Thumbnail_sizes__small__filesize_operator"] | undefined,
	sizes__small__filename?: ModelTypes["Location_Thumbnail_sizes__small__filename_operator"] | undefined,
	sizes__medium__url?: ModelTypes["Location_Thumbnail_sizes__medium__url_operator"] | undefined,
	sizes__medium__width?: ModelTypes["Location_Thumbnail_sizes__medium__width_operator"] | undefined,
	sizes__medium__height?: ModelTypes["Location_Thumbnail_sizes__medium__height_operator"] | undefined,
	sizes__medium__mimeType?: ModelTypes["Location_Thumbnail_sizes__medium__mimeType_operator"] | undefined,
	sizes__medium__filesize?: ModelTypes["Location_Thumbnail_sizes__medium__filesize_operator"] | undefined,
	sizes__medium__filename?: ModelTypes["Location_Thumbnail_sizes__medium__filename_operator"] | undefined,
	sizes__large__url?: ModelTypes["Location_Thumbnail_sizes__large__url_operator"] | undefined,
	sizes__large__width?: ModelTypes["Location_Thumbnail_sizes__large__width_operator"] | undefined,
	sizes__large__height?: ModelTypes["Location_Thumbnail_sizes__large__height_operator"] | undefined,
	sizes__large__mimeType?: ModelTypes["Location_Thumbnail_sizes__large__mimeType_operator"] | undefined,
	sizes__large__filesize?: ModelTypes["Location_Thumbnail_sizes__large__filesize_operator"] | undefined,
	sizes__large__filename?: ModelTypes["Location_Thumbnail_sizes__large__filename_operator"] | undefined,
	sizes__small_jpeg__url?: ModelTypes["Location_Thumbnail_sizes__small_jpeg__url_operator"] | undefined,
	sizes__small_jpeg__width?: ModelTypes["Location_Thumbnail_sizes__small_jpeg__width_operator"] | undefined,
	sizes__small_jpeg__height?: ModelTypes["Location_Thumbnail_sizes__small_jpeg__height_operator"] | undefined,
	sizes__small_jpeg__mimeType?: ModelTypes["Location_Thumbnail_sizes__small_jpeg__mimeType_operator"] | undefined,
	sizes__small_jpeg__filesize?: ModelTypes["Location_Thumbnail_sizes__small_jpeg__filesize_operator"] | undefined,
	sizes__small_jpeg__filename?: ModelTypes["Location_Thumbnail_sizes__small_jpeg__filename_operator"] | undefined,
	sizes__medium_jpeg__url?: ModelTypes["Location_Thumbnail_sizes__medium_jpeg__url_operator"] | undefined,
	sizes__medium_jpeg__width?: ModelTypes["Location_Thumbnail_sizes__medium_jpeg__width_operator"] | undefined,
	sizes__medium_jpeg__height?: ModelTypes["Location_Thumbnail_sizes__medium_jpeg__height_operator"] | undefined,
	sizes__medium_jpeg__mimeType?: ModelTypes["Location_Thumbnail_sizes__medium_jpeg__mimeType_operator"] | undefined,
	sizes__medium_jpeg__filesize?: ModelTypes["Location_Thumbnail_sizes__medium_jpeg__filesize_operator"] | undefined,
	sizes__medium_jpeg__filename?: ModelTypes["Location_Thumbnail_sizes__medium_jpeg__filename_operator"] | undefined,
	sizes__large_jpeg__url?: ModelTypes["Location_Thumbnail_sizes__large_jpeg__url_operator"] | undefined,
	sizes__large_jpeg__width?: ModelTypes["Location_Thumbnail_sizes__large_jpeg__width_operator"] | undefined,
	sizes__large_jpeg__height?: ModelTypes["Location_Thumbnail_sizes__large_jpeg__height_operator"] | undefined,
	sizes__large_jpeg__mimeType?: ModelTypes["Location_Thumbnail_sizes__large_jpeg__mimeType_operator"] | undefined,
	sizes__large_jpeg__filesize?: ModelTypes["Location_Thumbnail_sizes__large_jpeg__filesize_operator"] | undefined,
	sizes__large_jpeg__filename?: ModelTypes["Location_Thumbnail_sizes__large_jpeg__filename_operator"] | undefined,
	id?: ModelTypes["Location_Thumbnail_id_operator"] | undefined,
	AND?: Array<ModelTypes["Location_Thumbnail_where_and"] | undefined> | undefined,
	OR?: Array<ModelTypes["Location_Thumbnail_where_or"] | undefined> | undefined
};
	["Location_Banner_where"]: {
	alt?: ModelTypes["Location_Banner_alt_operator"] | undefined,
	updatedAt?: ModelTypes["Location_Banner_updatedAt_operator"] | undefined,
	createdAt?: ModelTypes["Location_Banner_createdAt_operator"] | undefined,
	url?: ModelTypes["Location_Banner_url_operator"] | undefined,
	filename?: ModelTypes["Location_Banner_filename_operator"] | undefined,
	mimeType?: ModelTypes["Location_Banner_mimeType_operator"] | undefined,
	filesize?: ModelTypes["Location_Banner_filesize_operator"] | undefined,
	width?: ModelTypes["Location_Banner_width_operator"] | undefined,
	height?: ModelTypes["Location_Banner_height_operator"] | undefined,
	focalX?: ModelTypes["Location_Banner_focalX_operator"] | undefined,
	focalY?: ModelTypes["Location_Banner_focalY_operator"] | undefined,
	sizes__small__url?: ModelTypes["Location_Banner_sizes__small__url_operator"] | undefined,
	sizes__small__width?: ModelTypes["Location_Banner_sizes__small__width_operator"] | undefined,
	sizes__small__height?: ModelTypes["Location_Banner_sizes__small__height_operator"] | undefined,
	sizes__small__mimeType?: ModelTypes["Location_Banner_sizes__small__mimeType_operator"] | undefined,
	sizes__small__filesize?: ModelTypes["Location_Banner_sizes__small__filesize_operator"] | undefined,
	sizes__small__filename?: ModelTypes["Location_Banner_sizes__small__filename_operator"] | undefined,
	sizes__medium__url?: ModelTypes["Location_Banner_sizes__medium__url_operator"] | undefined,
	sizes__medium__width?: ModelTypes["Location_Banner_sizes__medium__width_operator"] | undefined,
	sizes__medium__height?: ModelTypes["Location_Banner_sizes__medium__height_operator"] | undefined,
	sizes__medium__mimeType?: ModelTypes["Location_Banner_sizes__medium__mimeType_operator"] | undefined,
	sizes__medium__filesize?: ModelTypes["Location_Banner_sizes__medium__filesize_operator"] | undefined,
	sizes__medium__filename?: ModelTypes["Location_Banner_sizes__medium__filename_operator"] | undefined,
	sizes__large__url?: ModelTypes["Location_Banner_sizes__large__url_operator"] | undefined,
	sizes__large__width?: ModelTypes["Location_Banner_sizes__large__width_operator"] | undefined,
	sizes__large__height?: ModelTypes["Location_Banner_sizes__large__height_operator"] | undefined,
	sizes__large__mimeType?: ModelTypes["Location_Banner_sizes__large__mimeType_operator"] | undefined,
	sizes__large__filesize?: ModelTypes["Location_Banner_sizes__large__filesize_operator"] | undefined,
	sizes__large__filename?: ModelTypes["Location_Banner_sizes__large__filename_operator"] | undefined,
	sizes__small_jpeg__url?: ModelTypes["Location_Banner_sizes__small_jpeg__url_operator"] | undefined,
	sizes__small_jpeg__width?: ModelTypes["Location_Banner_sizes__small_jpeg__width_operator"] | undefined,
	sizes__small_jpeg__height?: ModelTypes["Location_Banner_sizes__small_jpeg__height_operator"] | undefined,
	sizes__small_jpeg__mimeType?: ModelTypes["Location_Banner_sizes__small_jpeg__mimeType_operator"] | undefined,
	sizes__small_jpeg__filesize?: ModelTypes["Location_Banner_sizes__small_jpeg__filesize_operator"] | undefined,
	sizes__small_jpeg__filename?: ModelTypes["Location_Banner_sizes__small_jpeg__filename_operator"] | undefined,
	sizes__medium_jpeg__url?: ModelTypes["Location_Banner_sizes__medium_jpeg__url_operator"] | undefined,
	sizes__medium_jpeg__width?: ModelTypes["Location_Banner_sizes__medium_jpeg__width_operator"] | undefined,
	sizes__medium_jpeg__height?: ModelTypes["Location_Banner_sizes__medium_jpeg__height_operator"] | undefined,
	sizes__medium_jpeg__mimeType?: ModelTypes["Location_Banner_sizes__medium_jpeg__mimeType_operator"] | undefined,
	sizes__medium_jpeg__filesize?: ModelTypes["Location_Banner_sizes__medium_jpeg__filesize_operator"] | undefined,
	sizes__medium_jpeg__filename?: ModelTypes["Location_Banner_sizes__medium_jpeg__filename_operator"] | undefined,
	sizes__large_jpeg__url?: ModelTypes["Location_Banner_sizes__large_jpeg__url_operator"] | undefined,
	sizes__large_jpeg__width?: ModelTypes["Location_Banner_sizes__large_jpeg__width_operator"] | undefined,
	sizes__large_jpeg__height?: ModelTypes["Location_Banner_sizes__large_jpeg__height_operator"] | undefined,
	sizes__large_jpeg__mimeType?: ModelTypes["Location_Banner_sizes__large_jpeg__mimeType_operator"] | undefined,
	sizes__large_jpeg__filesize?: ModelTypes["Location_Banner_sizes__large_jpeg__filesize_operator"] | undefined,
	sizes__large_jpeg__filename?: ModelTypes["Location_Banner_sizes__large_jpeg__filename_operator"] | undefined,
	id?: ModelTypes["Location_Banner_id_operator"] | undefined,
	AND?: Array<ModelTypes["Location_Banner_where_and"] | undefined> | undefined,
	OR?: Array<ModelTypes["Location_Banner_where_or"] | undefined> | undefined
};
	["Location_Banner_alt_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Location_Banner_updatedAt_operator"]: {
	equals?: ModelTypes["DateTime"] | undefined,
	not_equals?: ModelTypes["DateTime"] | undefined,
	greater_than_equal?: ModelTypes["DateTime"] | undefined,
	greater_than?: ModelTypes["DateTime"] | undefined,
	less_than_equal?: ModelTypes["DateTime"] | undefined,
	less_than?: ModelTypes["DateTime"] | undefined,
	like?: ModelTypes["DateTime"] | undefined,
	exists?: boolean | undefined
};
	["Location_Banner_createdAt_operator"]: {
	equals?: ModelTypes["DateTime"] | undefined,
	not_equals?: ModelTypes["DateTime"] | undefined,
	greater_than_equal?: ModelTypes["DateTime"] | undefined,
	greater_than?: ModelTypes["DateTime"] | undefined,
	less_than_equal?: ModelTypes["DateTime"] | undefined,
	less_than?: ModelTypes["DateTime"] | undefined,
	like?: ModelTypes["DateTime"] | undefined,
	exists?: boolean | undefined
};
	["Location_Banner_url_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Location_Banner_filename_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Location_Banner_mimeType_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Location_Banner_filesize_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Location_Banner_width_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Location_Banner_height_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Location_Banner_focalX_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Location_Banner_focalY_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Location_Banner_sizes__small__url_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Location_Banner_sizes__small__width_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Location_Banner_sizes__small__height_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Location_Banner_sizes__small__mimeType_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Location_Banner_sizes__small__filesize_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Location_Banner_sizes__small__filename_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Location_Banner_sizes__medium__url_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Location_Banner_sizes__medium__width_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Location_Banner_sizes__medium__height_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Location_Banner_sizes__medium__mimeType_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Location_Banner_sizes__medium__filesize_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Location_Banner_sizes__medium__filename_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Location_Banner_sizes__large__url_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Location_Banner_sizes__large__width_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Location_Banner_sizes__large__height_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Location_Banner_sizes__large__mimeType_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Location_Banner_sizes__large__filesize_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Location_Banner_sizes__large__filename_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Location_Banner_sizes__small_jpeg__url_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Location_Banner_sizes__small_jpeg__width_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Location_Banner_sizes__small_jpeg__height_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Location_Banner_sizes__small_jpeg__mimeType_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Location_Banner_sizes__small_jpeg__filesize_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Location_Banner_sizes__small_jpeg__filename_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Location_Banner_sizes__medium_jpeg__url_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Location_Banner_sizes__medium_jpeg__width_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Location_Banner_sizes__medium_jpeg__height_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Location_Banner_sizes__medium_jpeg__mimeType_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Location_Banner_sizes__medium_jpeg__filesize_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Location_Banner_sizes__medium_jpeg__filename_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Location_Banner_sizes__large_jpeg__url_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Location_Banner_sizes__large_jpeg__width_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Location_Banner_sizes__large_jpeg__height_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Location_Banner_sizes__large_jpeg__mimeType_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Location_Banner_sizes__large_jpeg__filesize_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Location_Banner_sizes__large_jpeg__filename_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Location_Banner_id_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Location_Banner_where_and"]: {
	alt?: ModelTypes["Location_Banner_alt_operator"] | undefined,
	updatedAt?: ModelTypes["Location_Banner_updatedAt_operator"] | undefined,
	createdAt?: ModelTypes["Location_Banner_createdAt_operator"] | undefined,
	url?: ModelTypes["Location_Banner_url_operator"] | undefined,
	filename?: ModelTypes["Location_Banner_filename_operator"] | undefined,
	mimeType?: ModelTypes["Location_Banner_mimeType_operator"] | undefined,
	filesize?: ModelTypes["Location_Banner_filesize_operator"] | undefined,
	width?: ModelTypes["Location_Banner_width_operator"] | undefined,
	height?: ModelTypes["Location_Banner_height_operator"] | undefined,
	focalX?: ModelTypes["Location_Banner_focalX_operator"] | undefined,
	focalY?: ModelTypes["Location_Banner_focalY_operator"] | undefined,
	sizes__small__url?: ModelTypes["Location_Banner_sizes__small__url_operator"] | undefined,
	sizes__small__width?: ModelTypes["Location_Banner_sizes__small__width_operator"] | undefined,
	sizes__small__height?: ModelTypes["Location_Banner_sizes__small__height_operator"] | undefined,
	sizes__small__mimeType?: ModelTypes["Location_Banner_sizes__small__mimeType_operator"] | undefined,
	sizes__small__filesize?: ModelTypes["Location_Banner_sizes__small__filesize_operator"] | undefined,
	sizes__small__filename?: ModelTypes["Location_Banner_sizes__small__filename_operator"] | undefined,
	sizes__medium__url?: ModelTypes["Location_Banner_sizes__medium__url_operator"] | undefined,
	sizes__medium__width?: ModelTypes["Location_Banner_sizes__medium__width_operator"] | undefined,
	sizes__medium__height?: ModelTypes["Location_Banner_sizes__medium__height_operator"] | undefined,
	sizes__medium__mimeType?: ModelTypes["Location_Banner_sizes__medium__mimeType_operator"] | undefined,
	sizes__medium__filesize?: ModelTypes["Location_Banner_sizes__medium__filesize_operator"] | undefined,
	sizes__medium__filename?: ModelTypes["Location_Banner_sizes__medium__filename_operator"] | undefined,
	sizes__large__url?: ModelTypes["Location_Banner_sizes__large__url_operator"] | undefined,
	sizes__large__width?: ModelTypes["Location_Banner_sizes__large__width_operator"] | undefined,
	sizes__large__height?: ModelTypes["Location_Banner_sizes__large__height_operator"] | undefined,
	sizes__large__mimeType?: ModelTypes["Location_Banner_sizes__large__mimeType_operator"] | undefined,
	sizes__large__filesize?: ModelTypes["Location_Banner_sizes__large__filesize_operator"] | undefined,
	sizes__large__filename?: ModelTypes["Location_Banner_sizes__large__filename_operator"] | undefined,
	sizes__small_jpeg__url?: ModelTypes["Location_Banner_sizes__small_jpeg__url_operator"] | undefined,
	sizes__small_jpeg__width?: ModelTypes["Location_Banner_sizes__small_jpeg__width_operator"] | undefined,
	sizes__small_jpeg__height?: ModelTypes["Location_Banner_sizes__small_jpeg__height_operator"] | undefined,
	sizes__small_jpeg__mimeType?: ModelTypes["Location_Banner_sizes__small_jpeg__mimeType_operator"] | undefined,
	sizes__small_jpeg__filesize?: ModelTypes["Location_Banner_sizes__small_jpeg__filesize_operator"] | undefined,
	sizes__small_jpeg__filename?: ModelTypes["Location_Banner_sizes__small_jpeg__filename_operator"] | undefined,
	sizes__medium_jpeg__url?: ModelTypes["Location_Banner_sizes__medium_jpeg__url_operator"] | undefined,
	sizes__medium_jpeg__width?: ModelTypes["Location_Banner_sizes__medium_jpeg__width_operator"] | undefined,
	sizes__medium_jpeg__height?: ModelTypes["Location_Banner_sizes__medium_jpeg__height_operator"] | undefined,
	sizes__medium_jpeg__mimeType?: ModelTypes["Location_Banner_sizes__medium_jpeg__mimeType_operator"] | undefined,
	sizes__medium_jpeg__filesize?: ModelTypes["Location_Banner_sizes__medium_jpeg__filesize_operator"] | undefined,
	sizes__medium_jpeg__filename?: ModelTypes["Location_Banner_sizes__medium_jpeg__filename_operator"] | undefined,
	sizes__large_jpeg__url?: ModelTypes["Location_Banner_sizes__large_jpeg__url_operator"] | undefined,
	sizes__large_jpeg__width?: ModelTypes["Location_Banner_sizes__large_jpeg__width_operator"] | undefined,
	sizes__large_jpeg__height?: ModelTypes["Location_Banner_sizes__large_jpeg__height_operator"] | undefined,
	sizes__large_jpeg__mimeType?: ModelTypes["Location_Banner_sizes__large_jpeg__mimeType_operator"] | undefined,
	sizes__large_jpeg__filesize?: ModelTypes["Location_Banner_sizes__large_jpeg__filesize_operator"] | undefined,
	sizes__large_jpeg__filename?: ModelTypes["Location_Banner_sizes__large_jpeg__filename_operator"] | undefined,
	id?: ModelTypes["Location_Banner_id_operator"] | undefined,
	AND?: Array<ModelTypes["Location_Banner_where_and"] | undefined> | undefined,
	OR?: Array<ModelTypes["Location_Banner_where_or"] | undefined> | undefined
};
	["Location_Banner_where_or"]: {
	alt?: ModelTypes["Location_Banner_alt_operator"] | undefined,
	updatedAt?: ModelTypes["Location_Banner_updatedAt_operator"] | undefined,
	createdAt?: ModelTypes["Location_Banner_createdAt_operator"] | undefined,
	url?: ModelTypes["Location_Banner_url_operator"] | undefined,
	filename?: ModelTypes["Location_Banner_filename_operator"] | undefined,
	mimeType?: ModelTypes["Location_Banner_mimeType_operator"] | undefined,
	filesize?: ModelTypes["Location_Banner_filesize_operator"] | undefined,
	width?: ModelTypes["Location_Banner_width_operator"] | undefined,
	height?: ModelTypes["Location_Banner_height_operator"] | undefined,
	focalX?: ModelTypes["Location_Banner_focalX_operator"] | undefined,
	focalY?: ModelTypes["Location_Banner_focalY_operator"] | undefined,
	sizes__small__url?: ModelTypes["Location_Banner_sizes__small__url_operator"] | undefined,
	sizes__small__width?: ModelTypes["Location_Banner_sizes__small__width_operator"] | undefined,
	sizes__small__height?: ModelTypes["Location_Banner_sizes__small__height_operator"] | undefined,
	sizes__small__mimeType?: ModelTypes["Location_Banner_sizes__small__mimeType_operator"] | undefined,
	sizes__small__filesize?: ModelTypes["Location_Banner_sizes__small__filesize_operator"] | undefined,
	sizes__small__filename?: ModelTypes["Location_Banner_sizes__small__filename_operator"] | undefined,
	sizes__medium__url?: ModelTypes["Location_Banner_sizes__medium__url_operator"] | undefined,
	sizes__medium__width?: ModelTypes["Location_Banner_sizes__medium__width_operator"] | undefined,
	sizes__medium__height?: ModelTypes["Location_Banner_sizes__medium__height_operator"] | undefined,
	sizes__medium__mimeType?: ModelTypes["Location_Banner_sizes__medium__mimeType_operator"] | undefined,
	sizes__medium__filesize?: ModelTypes["Location_Banner_sizes__medium__filesize_operator"] | undefined,
	sizes__medium__filename?: ModelTypes["Location_Banner_sizes__medium__filename_operator"] | undefined,
	sizes__large__url?: ModelTypes["Location_Banner_sizes__large__url_operator"] | undefined,
	sizes__large__width?: ModelTypes["Location_Banner_sizes__large__width_operator"] | undefined,
	sizes__large__height?: ModelTypes["Location_Banner_sizes__large__height_operator"] | undefined,
	sizes__large__mimeType?: ModelTypes["Location_Banner_sizes__large__mimeType_operator"] | undefined,
	sizes__large__filesize?: ModelTypes["Location_Banner_sizes__large__filesize_operator"] | undefined,
	sizes__large__filename?: ModelTypes["Location_Banner_sizes__large__filename_operator"] | undefined,
	sizes__small_jpeg__url?: ModelTypes["Location_Banner_sizes__small_jpeg__url_operator"] | undefined,
	sizes__small_jpeg__width?: ModelTypes["Location_Banner_sizes__small_jpeg__width_operator"] | undefined,
	sizes__small_jpeg__height?: ModelTypes["Location_Banner_sizes__small_jpeg__height_operator"] | undefined,
	sizes__small_jpeg__mimeType?: ModelTypes["Location_Banner_sizes__small_jpeg__mimeType_operator"] | undefined,
	sizes__small_jpeg__filesize?: ModelTypes["Location_Banner_sizes__small_jpeg__filesize_operator"] | undefined,
	sizes__small_jpeg__filename?: ModelTypes["Location_Banner_sizes__small_jpeg__filename_operator"] | undefined,
	sizes__medium_jpeg__url?: ModelTypes["Location_Banner_sizes__medium_jpeg__url_operator"] | undefined,
	sizes__medium_jpeg__width?: ModelTypes["Location_Banner_sizes__medium_jpeg__width_operator"] | undefined,
	sizes__medium_jpeg__height?: ModelTypes["Location_Banner_sizes__medium_jpeg__height_operator"] | undefined,
	sizes__medium_jpeg__mimeType?: ModelTypes["Location_Banner_sizes__medium_jpeg__mimeType_operator"] | undefined,
	sizes__medium_jpeg__filesize?: ModelTypes["Location_Banner_sizes__medium_jpeg__filesize_operator"] | undefined,
	sizes__medium_jpeg__filename?: ModelTypes["Location_Banner_sizes__medium_jpeg__filename_operator"] | undefined,
	sizes__large_jpeg__url?: ModelTypes["Location_Banner_sizes__large_jpeg__url_operator"] | undefined,
	sizes__large_jpeg__width?: ModelTypes["Location_Banner_sizes__large_jpeg__width_operator"] | undefined,
	sizes__large_jpeg__height?: ModelTypes["Location_Banner_sizes__large_jpeg__height_operator"] | undefined,
	sizes__large_jpeg__mimeType?: ModelTypes["Location_Banner_sizes__large_jpeg__mimeType_operator"] | undefined,
	sizes__large_jpeg__filesize?: ModelTypes["Location_Banner_sizes__large_jpeg__filesize_operator"] | undefined,
	sizes__large_jpeg__filename?: ModelTypes["Location_Banner_sizes__large_jpeg__filename_operator"] | undefined,
	id?: ModelTypes["Location_Banner_id_operator"] | undefined,
	AND?: Array<ModelTypes["Location_Banner_where_and"] | undefined> | undefined,
	OR?: Array<ModelTypes["Location_Banner_where_or"] | undefined> | undefined
};
	["Location_type"]:Location_type;
	["Location_category"]:Location_category;
	["District"]: {
		id?: string | undefined,
	name?: string | undefined,
	level: number,
	children?: Array<ModelTypes["District"]> | undefined,
	value: string,
	lat?: number | undefined,
	long?: number | undefined,
	radius?: number | undefined,
	region?: ModelTypes["District_region"] | undefined,
	updatedAt?: ModelTypes["DateTime"] | undefined,
	createdAt?: ModelTypes["DateTime"] | undefined
};
	["District_region"]:District_region;
	["Location_Details"]: {
		code?: number | undefined,
	phone?: string | undefined,
	page?: string | undefined,
	email?: ModelTypes["EmailAddress"] | undefined
};
	["Location_Content"]:ModelTypes["RichText"] | ModelTypes["Gallery"] | ModelTypes["Image"] | ModelTypes["Video"];
	["RichText"]: {
		richText?: ModelTypes["JSON"] | undefined,
	id?: string | undefined,
	blockName?: string | undefined,
	blockType?: string | undefined
};
	/** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
["JSON"]:any;
	["Gallery"]: {
		gallery?: Array<ModelTypes["Gallery_Gallery"]> | undefined,
	id?: string | undefined,
	blockName?: string | undefined,
	blockType?: string | undefined
};
	["Gallery_Gallery"]: {
		item?: ModelTypes["Media"] | undefined,
	caption?: string | undefined,
	id?: string | undefined
};
	["Gallery_Gallery_Item_where"]: {
	alt?: ModelTypes["Gallery_Gallery_Item_alt_operator"] | undefined,
	updatedAt?: ModelTypes["Gallery_Gallery_Item_updatedAt_operator"] | undefined,
	createdAt?: ModelTypes["Gallery_Gallery_Item_createdAt_operator"] | undefined,
	url?: ModelTypes["Gallery_Gallery_Item_url_operator"] | undefined,
	filename?: ModelTypes["Gallery_Gallery_Item_filename_operator"] | undefined,
	mimeType?: ModelTypes["Gallery_Gallery_Item_mimeType_operator"] | undefined,
	filesize?: ModelTypes["Gallery_Gallery_Item_filesize_operator"] | undefined,
	width?: ModelTypes["Gallery_Gallery_Item_width_operator"] | undefined,
	height?: ModelTypes["Gallery_Gallery_Item_height_operator"] | undefined,
	focalX?: ModelTypes["Gallery_Gallery_Item_focalX_operator"] | undefined,
	focalY?: ModelTypes["Gallery_Gallery_Item_focalY_operator"] | undefined,
	sizes__small__url?: ModelTypes["Gallery_Gallery_Item_sizes__small__url_operator"] | undefined,
	sizes__small__width?: ModelTypes["Gallery_Gallery_Item_sizes__small__width_operator"] | undefined,
	sizes__small__height?: ModelTypes["Gallery_Gallery_Item_sizes__small__height_operator"] | undefined,
	sizes__small__mimeType?: ModelTypes["Gallery_Gallery_Item_sizes__small__mimeType_operator"] | undefined,
	sizes__small__filesize?: ModelTypes["Gallery_Gallery_Item_sizes__small__filesize_operator"] | undefined,
	sizes__small__filename?: ModelTypes["Gallery_Gallery_Item_sizes__small__filename_operator"] | undefined,
	sizes__medium__url?: ModelTypes["Gallery_Gallery_Item_sizes__medium__url_operator"] | undefined,
	sizes__medium__width?: ModelTypes["Gallery_Gallery_Item_sizes__medium__width_operator"] | undefined,
	sizes__medium__height?: ModelTypes["Gallery_Gallery_Item_sizes__medium__height_operator"] | undefined,
	sizes__medium__mimeType?: ModelTypes["Gallery_Gallery_Item_sizes__medium__mimeType_operator"] | undefined,
	sizes__medium__filesize?: ModelTypes["Gallery_Gallery_Item_sizes__medium__filesize_operator"] | undefined,
	sizes__medium__filename?: ModelTypes["Gallery_Gallery_Item_sizes__medium__filename_operator"] | undefined,
	sizes__large__url?: ModelTypes["Gallery_Gallery_Item_sizes__large__url_operator"] | undefined,
	sizes__large__width?: ModelTypes["Gallery_Gallery_Item_sizes__large__width_operator"] | undefined,
	sizes__large__height?: ModelTypes["Gallery_Gallery_Item_sizes__large__height_operator"] | undefined,
	sizes__large__mimeType?: ModelTypes["Gallery_Gallery_Item_sizes__large__mimeType_operator"] | undefined,
	sizes__large__filesize?: ModelTypes["Gallery_Gallery_Item_sizes__large__filesize_operator"] | undefined,
	sizes__large__filename?: ModelTypes["Gallery_Gallery_Item_sizes__large__filename_operator"] | undefined,
	sizes__small_jpeg__url?: ModelTypes["Gallery_Gallery_Item_sizes__small_jpeg__url_operator"] | undefined,
	sizes__small_jpeg__width?: ModelTypes["Gallery_Gallery_Item_sizes__small_jpeg__width_operator"] | undefined,
	sizes__small_jpeg__height?: ModelTypes["Gallery_Gallery_Item_sizes__small_jpeg__height_operator"] | undefined,
	sizes__small_jpeg__mimeType?: ModelTypes["Gallery_Gallery_Item_sizes__small_jpeg__mimeType_operator"] | undefined,
	sizes__small_jpeg__filesize?: ModelTypes["Gallery_Gallery_Item_sizes__small_jpeg__filesize_operator"] | undefined,
	sizes__small_jpeg__filename?: ModelTypes["Gallery_Gallery_Item_sizes__small_jpeg__filename_operator"] | undefined,
	sizes__medium_jpeg__url?: ModelTypes["Gallery_Gallery_Item_sizes__medium_jpeg__url_operator"] | undefined,
	sizes__medium_jpeg__width?: ModelTypes["Gallery_Gallery_Item_sizes__medium_jpeg__width_operator"] | undefined,
	sizes__medium_jpeg__height?: ModelTypes["Gallery_Gallery_Item_sizes__medium_jpeg__height_operator"] | undefined,
	sizes__medium_jpeg__mimeType?: ModelTypes["Gallery_Gallery_Item_sizes__medium_jpeg__mimeType_operator"] | undefined,
	sizes__medium_jpeg__filesize?: ModelTypes["Gallery_Gallery_Item_sizes__medium_jpeg__filesize_operator"] | undefined,
	sizes__medium_jpeg__filename?: ModelTypes["Gallery_Gallery_Item_sizes__medium_jpeg__filename_operator"] | undefined,
	sizes__large_jpeg__url?: ModelTypes["Gallery_Gallery_Item_sizes__large_jpeg__url_operator"] | undefined,
	sizes__large_jpeg__width?: ModelTypes["Gallery_Gallery_Item_sizes__large_jpeg__width_operator"] | undefined,
	sizes__large_jpeg__height?: ModelTypes["Gallery_Gallery_Item_sizes__large_jpeg__height_operator"] | undefined,
	sizes__large_jpeg__mimeType?: ModelTypes["Gallery_Gallery_Item_sizes__large_jpeg__mimeType_operator"] | undefined,
	sizes__large_jpeg__filesize?: ModelTypes["Gallery_Gallery_Item_sizes__large_jpeg__filesize_operator"] | undefined,
	sizes__large_jpeg__filename?: ModelTypes["Gallery_Gallery_Item_sizes__large_jpeg__filename_operator"] | undefined,
	id?: ModelTypes["Gallery_Gallery_Item_id_operator"] | undefined,
	AND?: Array<ModelTypes["Gallery_Gallery_Item_where_and"] | undefined> | undefined,
	OR?: Array<ModelTypes["Gallery_Gallery_Item_where_or"] | undefined> | undefined
};
	["Gallery_Gallery_Item_alt_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Gallery_Gallery_Item_updatedAt_operator"]: {
	equals?: ModelTypes["DateTime"] | undefined,
	not_equals?: ModelTypes["DateTime"] | undefined,
	greater_than_equal?: ModelTypes["DateTime"] | undefined,
	greater_than?: ModelTypes["DateTime"] | undefined,
	less_than_equal?: ModelTypes["DateTime"] | undefined,
	less_than?: ModelTypes["DateTime"] | undefined,
	like?: ModelTypes["DateTime"] | undefined,
	exists?: boolean | undefined
};
	["Gallery_Gallery_Item_createdAt_operator"]: {
	equals?: ModelTypes["DateTime"] | undefined,
	not_equals?: ModelTypes["DateTime"] | undefined,
	greater_than_equal?: ModelTypes["DateTime"] | undefined,
	greater_than?: ModelTypes["DateTime"] | undefined,
	less_than_equal?: ModelTypes["DateTime"] | undefined,
	less_than?: ModelTypes["DateTime"] | undefined,
	like?: ModelTypes["DateTime"] | undefined,
	exists?: boolean | undefined
};
	["Gallery_Gallery_Item_url_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Gallery_Gallery_Item_filename_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Gallery_Gallery_Item_mimeType_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Gallery_Gallery_Item_filesize_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Gallery_Gallery_Item_width_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Gallery_Gallery_Item_height_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Gallery_Gallery_Item_focalX_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Gallery_Gallery_Item_focalY_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Gallery_Gallery_Item_sizes__small__url_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Gallery_Gallery_Item_sizes__small__width_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Gallery_Gallery_Item_sizes__small__height_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Gallery_Gallery_Item_sizes__small__mimeType_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Gallery_Gallery_Item_sizes__small__filesize_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Gallery_Gallery_Item_sizes__small__filename_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Gallery_Gallery_Item_sizes__medium__url_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Gallery_Gallery_Item_sizes__medium__width_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Gallery_Gallery_Item_sizes__medium__height_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Gallery_Gallery_Item_sizes__medium__mimeType_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Gallery_Gallery_Item_sizes__medium__filesize_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Gallery_Gallery_Item_sizes__medium__filename_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Gallery_Gallery_Item_sizes__large__url_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Gallery_Gallery_Item_sizes__large__width_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Gallery_Gallery_Item_sizes__large__height_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Gallery_Gallery_Item_sizes__large__mimeType_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Gallery_Gallery_Item_sizes__large__filesize_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Gallery_Gallery_Item_sizes__large__filename_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Gallery_Gallery_Item_sizes__small_jpeg__url_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Gallery_Gallery_Item_sizes__small_jpeg__width_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Gallery_Gallery_Item_sizes__small_jpeg__height_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Gallery_Gallery_Item_sizes__small_jpeg__mimeType_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Gallery_Gallery_Item_sizes__small_jpeg__filesize_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Gallery_Gallery_Item_sizes__small_jpeg__filename_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Gallery_Gallery_Item_sizes__medium_jpeg__url_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Gallery_Gallery_Item_sizes__medium_jpeg__width_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Gallery_Gallery_Item_sizes__medium_jpeg__height_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Gallery_Gallery_Item_sizes__medium_jpeg__mimeType_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Gallery_Gallery_Item_sizes__medium_jpeg__filesize_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Gallery_Gallery_Item_sizes__medium_jpeg__filename_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Gallery_Gallery_Item_sizes__large_jpeg__url_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Gallery_Gallery_Item_sizes__large_jpeg__width_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Gallery_Gallery_Item_sizes__large_jpeg__height_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Gallery_Gallery_Item_sizes__large_jpeg__mimeType_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Gallery_Gallery_Item_sizes__large_jpeg__filesize_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Gallery_Gallery_Item_sizes__large_jpeg__filename_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Gallery_Gallery_Item_id_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Gallery_Gallery_Item_where_and"]: {
	alt?: ModelTypes["Gallery_Gallery_Item_alt_operator"] | undefined,
	updatedAt?: ModelTypes["Gallery_Gallery_Item_updatedAt_operator"] | undefined,
	createdAt?: ModelTypes["Gallery_Gallery_Item_createdAt_operator"] | undefined,
	url?: ModelTypes["Gallery_Gallery_Item_url_operator"] | undefined,
	filename?: ModelTypes["Gallery_Gallery_Item_filename_operator"] | undefined,
	mimeType?: ModelTypes["Gallery_Gallery_Item_mimeType_operator"] | undefined,
	filesize?: ModelTypes["Gallery_Gallery_Item_filesize_operator"] | undefined,
	width?: ModelTypes["Gallery_Gallery_Item_width_operator"] | undefined,
	height?: ModelTypes["Gallery_Gallery_Item_height_operator"] | undefined,
	focalX?: ModelTypes["Gallery_Gallery_Item_focalX_operator"] | undefined,
	focalY?: ModelTypes["Gallery_Gallery_Item_focalY_operator"] | undefined,
	sizes__small__url?: ModelTypes["Gallery_Gallery_Item_sizes__small__url_operator"] | undefined,
	sizes__small__width?: ModelTypes["Gallery_Gallery_Item_sizes__small__width_operator"] | undefined,
	sizes__small__height?: ModelTypes["Gallery_Gallery_Item_sizes__small__height_operator"] | undefined,
	sizes__small__mimeType?: ModelTypes["Gallery_Gallery_Item_sizes__small__mimeType_operator"] | undefined,
	sizes__small__filesize?: ModelTypes["Gallery_Gallery_Item_sizes__small__filesize_operator"] | undefined,
	sizes__small__filename?: ModelTypes["Gallery_Gallery_Item_sizes__small__filename_operator"] | undefined,
	sizes__medium__url?: ModelTypes["Gallery_Gallery_Item_sizes__medium__url_operator"] | undefined,
	sizes__medium__width?: ModelTypes["Gallery_Gallery_Item_sizes__medium__width_operator"] | undefined,
	sizes__medium__height?: ModelTypes["Gallery_Gallery_Item_sizes__medium__height_operator"] | undefined,
	sizes__medium__mimeType?: ModelTypes["Gallery_Gallery_Item_sizes__medium__mimeType_operator"] | undefined,
	sizes__medium__filesize?: ModelTypes["Gallery_Gallery_Item_sizes__medium__filesize_operator"] | undefined,
	sizes__medium__filename?: ModelTypes["Gallery_Gallery_Item_sizes__medium__filename_operator"] | undefined,
	sizes__large__url?: ModelTypes["Gallery_Gallery_Item_sizes__large__url_operator"] | undefined,
	sizes__large__width?: ModelTypes["Gallery_Gallery_Item_sizes__large__width_operator"] | undefined,
	sizes__large__height?: ModelTypes["Gallery_Gallery_Item_sizes__large__height_operator"] | undefined,
	sizes__large__mimeType?: ModelTypes["Gallery_Gallery_Item_sizes__large__mimeType_operator"] | undefined,
	sizes__large__filesize?: ModelTypes["Gallery_Gallery_Item_sizes__large__filesize_operator"] | undefined,
	sizes__large__filename?: ModelTypes["Gallery_Gallery_Item_sizes__large__filename_operator"] | undefined,
	sizes__small_jpeg__url?: ModelTypes["Gallery_Gallery_Item_sizes__small_jpeg__url_operator"] | undefined,
	sizes__small_jpeg__width?: ModelTypes["Gallery_Gallery_Item_sizes__small_jpeg__width_operator"] | undefined,
	sizes__small_jpeg__height?: ModelTypes["Gallery_Gallery_Item_sizes__small_jpeg__height_operator"] | undefined,
	sizes__small_jpeg__mimeType?: ModelTypes["Gallery_Gallery_Item_sizes__small_jpeg__mimeType_operator"] | undefined,
	sizes__small_jpeg__filesize?: ModelTypes["Gallery_Gallery_Item_sizes__small_jpeg__filesize_operator"] | undefined,
	sizes__small_jpeg__filename?: ModelTypes["Gallery_Gallery_Item_sizes__small_jpeg__filename_operator"] | undefined,
	sizes__medium_jpeg__url?: ModelTypes["Gallery_Gallery_Item_sizes__medium_jpeg__url_operator"] | undefined,
	sizes__medium_jpeg__width?: ModelTypes["Gallery_Gallery_Item_sizes__medium_jpeg__width_operator"] | undefined,
	sizes__medium_jpeg__height?: ModelTypes["Gallery_Gallery_Item_sizes__medium_jpeg__height_operator"] | undefined,
	sizes__medium_jpeg__mimeType?: ModelTypes["Gallery_Gallery_Item_sizes__medium_jpeg__mimeType_operator"] | undefined,
	sizes__medium_jpeg__filesize?: ModelTypes["Gallery_Gallery_Item_sizes__medium_jpeg__filesize_operator"] | undefined,
	sizes__medium_jpeg__filename?: ModelTypes["Gallery_Gallery_Item_sizes__medium_jpeg__filename_operator"] | undefined,
	sizes__large_jpeg__url?: ModelTypes["Gallery_Gallery_Item_sizes__large_jpeg__url_operator"] | undefined,
	sizes__large_jpeg__width?: ModelTypes["Gallery_Gallery_Item_sizes__large_jpeg__width_operator"] | undefined,
	sizes__large_jpeg__height?: ModelTypes["Gallery_Gallery_Item_sizes__large_jpeg__height_operator"] | undefined,
	sizes__large_jpeg__mimeType?: ModelTypes["Gallery_Gallery_Item_sizes__large_jpeg__mimeType_operator"] | undefined,
	sizes__large_jpeg__filesize?: ModelTypes["Gallery_Gallery_Item_sizes__large_jpeg__filesize_operator"] | undefined,
	sizes__large_jpeg__filename?: ModelTypes["Gallery_Gallery_Item_sizes__large_jpeg__filename_operator"] | undefined,
	id?: ModelTypes["Gallery_Gallery_Item_id_operator"] | undefined,
	AND?: Array<ModelTypes["Gallery_Gallery_Item_where_and"] | undefined> | undefined,
	OR?: Array<ModelTypes["Gallery_Gallery_Item_where_or"] | undefined> | undefined
};
	["Gallery_Gallery_Item_where_or"]: {
	alt?: ModelTypes["Gallery_Gallery_Item_alt_operator"] | undefined,
	updatedAt?: ModelTypes["Gallery_Gallery_Item_updatedAt_operator"] | undefined,
	createdAt?: ModelTypes["Gallery_Gallery_Item_createdAt_operator"] | undefined,
	url?: ModelTypes["Gallery_Gallery_Item_url_operator"] | undefined,
	filename?: ModelTypes["Gallery_Gallery_Item_filename_operator"] | undefined,
	mimeType?: ModelTypes["Gallery_Gallery_Item_mimeType_operator"] | undefined,
	filesize?: ModelTypes["Gallery_Gallery_Item_filesize_operator"] | undefined,
	width?: ModelTypes["Gallery_Gallery_Item_width_operator"] | undefined,
	height?: ModelTypes["Gallery_Gallery_Item_height_operator"] | undefined,
	focalX?: ModelTypes["Gallery_Gallery_Item_focalX_operator"] | undefined,
	focalY?: ModelTypes["Gallery_Gallery_Item_focalY_operator"] | undefined,
	sizes__small__url?: ModelTypes["Gallery_Gallery_Item_sizes__small__url_operator"] | undefined,
	sizes__small__width?: ModelTypes["Gallery_Gallery_Item_sizes__small__width_operator"] | undefined,
	sizes__small__height?: ModelTypes["Gallery_Gallery_Item_sizes__small__height_operator"] | undefined,
	sizes__small__mimeType?: ModelTypes["Gallery_Gallery_Item_sizes__small__mimeType_operator"] | undefined,
	sizes__small__filesize?: ModelTypes["Gallery_Gallery_Item_sizes__small__filesize_operator"] | undefined,
	sizes__small__filename?: ModelTypes["Gallery_Gallery_Item_sizes__small__filename_operator"] | undefined,
	sizes__medium__url?: ModelTypes["Gallery_Gallery_Item_sizes__medium__url_operator"] | undefined,
	sizes__medium__width?: ModelTypes["Gallery_Gallery_Item_sizes__medium__width_operator"] | undefined,
	sizes__medium__height?: ModelTypes["Gallery_Gallery_Item_sizes__medium__height_operator"] | undefined,
	sizes__medium__mimeType?: ModelTypes["Gallery_Gallery_Item_sizes__medium__mimeType_operator"] | undefined,
	sizes__medium__filesize?: ModelTypes["Gallery_Gallery_Item_sizes__medium__filesize_operator"] | undefined,
	sizes__medium__filename?: ModelTypes["Gallery_Gallery_Item_sizes__medium__filename_operator"] | undefined,
	sizes__large__url?: ModelTypes["Gallery_Gallery_Item_sizes__large__url_operator"] | undefined,
	sizes__large__width?: ModelTypes["Gallery_Gallery_Item_sizes__large__width_operator"] | undefined,
	sizes__large__height?: ModelTypes["Gallery_Gallery_Item_sizes__large__height_operator"] | undefined,
	sizes__large__mimeType?: ModelTypes["Gallery_Gallery_Item_sizes__large__mimeType_operator"] | undefined,
	sizes__large__filesize?: ModelTypes["Gallery_Gallery_Item_sizes__large__filesize_operator"] | undefined,
	sizes__large__filename?: ModelTypes["Gallery_Gallery_Item_sizes__large__filename_operator"] | undefined,
	sizes__small_jpeg__url?: ModelTypes["Gallery_Gallery_Item_sizes__small_jpeg__url_operator"] | undefined,
	sizes__small_jpeg__width?: ModelTypes["Gallery_Gallery_Item_sizes__small_jpeg__width_operator"] | undefined,
	sizes__small_jpeg__height?: ModelTypes["Gallery_Gallery_Item_sizes__small_jpeg__height_operator"] | undefined,
	sizes__small_jpeg__mimeType?: ModelTypes["Gallery_Gallery_Item_sizes__small_jpeg__mimeType_operator"] | undefined,
	sizes__small_jpeg__filesize?: ModelTypes["Gallery_Gallery_Item_sizes__small_jpeg__filesize_operator"] | undefined,
	sizes__small_jpeg__filename?: ModelTypes["Gallery_Gallery_Item_sizes__small_jpeg__filename_operator"] | undefined,
	sizes__medium_jpeg__url?: ModelTypes["Gallery_Gallery_Item_sizes__medium_jpeg__url_operator"] | undefined,
	sizes__medium_jpeg__width?: ModelTypes["Gallery_Gallery_Item_sizes__medium_jpeg__width_operator"] | undefined,
	sizes__medium_jpeg__height?: ModelTypes["Gallery_Gallery_Item_sizes__medium_jpeg__height_operator"] | undefined,
	sizes__medium_jpeg__mimeType?: ModelTypes["Gallery_Gallery_Item_sizes__medium_jpeg__mimeType_operator"] | undefined,
	sizes__medium_jpeg__filesize?: ModelTypes["Gallery_Gallery_Item_sizes__medium_jpeg__filesize_operator"] | undefined,
	sizes__medium_jpeg__filename?: ModelTypes["Gallery_Gallery_Item_sizes__medium_jpeg__filename_operator"] | undefined,
	sizes__large_jpeg__url?: ModelTypes["Gallery_Gallery_Item_sizes__large_jpeg__url_operator"] | undefined,
	sizes__large_jpeg__width?: ModelTypes["Gallery_Gallery_Item_sizes__large_jpeg__width_operator"] | undefined,
	sizes__large_jpeg__height?: ModelTypes["Gallery_Gallery_Item_sizes__large_jpeg__height_operator"] | undefined,
	sizes__large_jpeg__mimeType?: ModelTypes["Gallery_Gallery_Item_sizes__large_jpeg__mimeType_operator"] | undefined,
	sizes__large_jpeg__filesize?: ModelTypes["Gallery_Gallery_Item_sizes__large_jpeg__filesize_operator"] | undefined,
	sizes__large_jpeg__filename?: ModelTypes["Gallery_Gallery_Item_sizes__large_jpeg__filename_operator"] | undefined,
	id?: ModelTypes["Gallery_Gallery_Item_id_operator"] | undefined,
	AND?: Array<ModelTypes["Gallery_Gallery_Item_where_and"] | undefined> | undefined,
	OR?: Array<ModelTypes["Gallery_Gallery_Item_where_or"] | undefined> | undefined
};
	["Image"]: {
		media: ModelTypes["Media"],
	caption?: string | undefined,
	id?: string | undefined,
	blockName?: string | undefined,
	blockType?: string | undefined
};
	["Image_Media_where"]: {
	alt?: ModelTypes["Image_Media_alt_operator"] | undefined,
	updatedAt?: ModelTypes["Image_Media_updatedAt_operator"] | undefined,
	createdAt?: ModelTypes["Image_Media_createdAt_operator"] | undefined,
	url?: ModelTypes["Image_Media_url_operator"] | undefined,
	filename?: ModelTypes["Image_Media_filename_operator"] | undefined,
	mimeType?: ModelTypes["Image_Media_mimeType_operator"] | undefined,
	filesize?: ModelTypes["Image_Media_filesize_operator"] | undefined,
	width?: ModelTypes["Image_Media_width_operator"] | undefined,
	height?: ModelTypes["Image_Media_height_operator"] | undefined,
	focalX?: ModelTypes["Image_Media_focalX_operator"] | undefined,
	focalY?: ModelTypes["Image_Media_focalY_operator"] | undefined,
	sizes__small__url?: ModelTypes["Image_Media_sizes__small__url_operator"] | undefined,
	sizes__small__width?: ModelTypes["Image_Media_sizes__small__width_operator"] | undefined,
	sizes__small__height?: ModelTypes["Image_Media_sizes__small__height_operator"] | undefined,
	sizes__small__mimeType?: ModelTypes["Image_Media_sizes__small__mimeType_operator"] | undefined,
	sizes__small__filesize?: ModelTypes["Image_Media_sizes__small__filesize_operator"] | undefined,
	sizes__small__filename?: ModelTypes["Image_Media_sizes__small__filename_operator"] | undefined,
	sizes__medium__url?: ModelTypes["Image_Media_sizes__medium__url_operator"] | undefined,
	sizes__medium__width?: ModelTypes["Image_Media_sizes__medium__width_operator"] | undefined,
	sizes__medium__height?: ModelTypes["Image_Media_sizes__medium__height_operator"] | undefined,
	sizes__medium__mimeType?: ModelTypes["Image_Media_sizes__medium__mimeType_operator"] | undefined,
	sizes__medium__filesize?: ModelTypes["Image_Media_sizes__medium__filesize_operator"] | undefined,
	sizes__medium__filename?: ModelTypes["Image_Media_sizes__medium__filename_operator"] | undefined,
	sizes__large__url?: ModelTypes["Image_Media_sizes__large__url_operator"] | undefined,
	sizes__large__width?: ModelTypes["Image_Media_sizes__large__width_operator"] | undefined,
	sizes__large__height?: ModelTypes["Image_Media_sizes__large__height_operator"] | undefined,
	sizes__large__mimeType?: ModelTypes["Image_Media_sizes__large__mimeType_operator"] | undefined,
	sizes__large__filesize?: ModelTypes["Image_Media_sizes__large__filesize_operator"] | undefined,
	sizes__large__filename?: ModelTypes["Image_Media_sizes__large__filename_operator"] | undefined,
	sizes__small_jpeg__url?: ModelTypes["Image_Media_sizes__small_jpeg__url_operator"] | undefined,
	sizes__small_jpeg__width?: ModelTypes["Image_Media_sizes__small_jpeg__width_operator"] | undefined,
	sizes__small_jpeg__height?: ModelTypes["Image_Media_sizes__small_jpeg__height_operator"] | undefined,
	sizes__small_jpeg__mimeType?: ModelTypes["Image_Media_sizes__small_jpeg__mimeType_operator"] | undefined,
	sizes__small_jpeg__filesize?: ModelTypes["Image_Media_sizes__small_jpeg__filesize_operator"] | undefined,
	sizes__small_jpeg__filename?: ModelTypes["Image_Media_sizes__small_jpeg__filename_operator"] | undefined,
	sizes__medium_jpeg__url?: ModelTypes["Image_Media_sizes__medium_jpeg__url_operator"] | undefined,
	sizes__medium_jpeg__width?: ModelTypes["Image_Media_sizes__medium_jpeg__width_operator"] | undefined,
	sizes__medium_jpeg__height?: ModelTypes["Image_Media_sizes__medium_jpeg__height_operator"] | undefined,
	sizes__medium_jpeg__mimeType?: ModelTypes["Image_Media_sizes__medium_jpeg__mimeType_operator"] | undefined,
	sizes__medium_jpeg__filesize?: ModelTypes["Image_Media_sizes__medium_jpeg__filesize_operator"] | undefined,
	sizes__medium_jpeg__filename?: ModelTypes["Image_Media_sizes__medium_jpeg__filename_operator"] | undefined,
	sizes__large_jpeg__url?: ModelTypes["Image_Media_sizes__large_jpeg__url_operator"] | undefined,
	sizes__large_jpeg__width?: ModelTypes["Image_Media_sizes__large_jpeg__width_operator"] | undefined,
	sizes__large_jpeg__height?: ModelTypes["Image_Media_sizes__large_jpeg__height_operator"] | undefined,
	sizes__large_jpeg__mimeType?: ModelTypes["Image_Media_sizes__large_jpeg__mimeType_operator"] | undefined,
	sizes__large_jpeg__filesize?: ModelTypes["Image_Media_sizes__large_jpeg__filesize_operator"] | undefined,
	sizes__large_jpeg__filename?: ModelTypes["Image_Media_sizes__large_jpeg__filename_operator"] | undefined,
	id?: ModelTypes["Image_Media_id_operator"] | undefined,
	AND?: Array<ModelTypes["Image_Media_where_and"] | undefined> | undefined,
	OR?: Array<ModelTypes["Image_Media_where_or"] | undefined> | undefined
};
	["Image_Media_alt_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Image_Media_updatedAt_operator"]: {
	equals?: ModelTypes["DateTime"] | undefined,
	not_equals?: ModelTypes["DateTime"] | undefined,
	greater_than_equal?: ModelTypes["DateTime"] | undefined,
	greater_than?: ModelTypes["DateTime"] | undefined,
	less_than_equal?: ModelTypes["DateTime"] | undefined,
	less_than?: ModelTypes["DateTime"] | undefined,
	like?: ModelTypes["DateTime"] | undefined,
	exists?: boolean | undefined
};
	["Image_Media_createdAt_operator"]: {
	equals?: ModelTypes["DateTime"] | undefined,
	not_equals?: ModelTypes["DateTime"] | undefined,
	greater_than_equal?: ModelTypes["DateTime"] | undefined,
	greater_than?: ModelTypes["DateTime"] | undefined,
	less_than_equal?: ModelTypes["DateTime"] | undefined,
	less_than?: ModelTypes["DateTime"] | undefined,
	like?: ModelTypes["DateTime"] | undefined,
	exists?: boolean | undefined
};
	["Image_Media_url_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Image_Media_filename_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Image_Media_mimeType_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Image_Media_filesize_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Image_Media_width_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Image_Media_height_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Image_Media_focalX_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Image_Media_focalY_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Image_Media_sizes__small__url_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Image_Media_sizes__small__width_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Image_Media_sizes__small__height_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Image_Media_sizes__small__mimeType_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Image_Media_sizes__small__filesize_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Image_Media_sizes__small__filename_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Image_Media_sizes__medium__url_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Image_Media_sizes__medium__width_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Image_Media_sizes__medium__height_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Image_Media_sizes__medium__mimeType_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Image_Media_sizes__medium__filesize_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Image_Media_sizes__medium__filename_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Image_Media_sizes__large__url_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Image_Media_sizes__large__width_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Image_Media_sizes__large__height_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Image_Media_sizes__large__mimeType_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Image_Media_sizes__large__filesize_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Image_Media_sizes__large__filename_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Image_Media_sizes__small_jpeg__url_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Image_Media_sizes__small_jpeg__width_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Image_Media_sizes__small_jpeg__height_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Image_Media_sizes__small_jpeg__mimeType_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Image_Media_sizes__small_jpeg__filesize_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Image_Media_sizes__small_jpeg__filename_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Image_Media_sizes__medium_jpeg__url_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Image_Media_sizes__medium_jpeg__width_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Image_Media_sizes__medium_jpeg__height_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Image_Media_sizes__medium_jpeg__mimeType_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Image_Media_sizes__medium_jpeg__filesize_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Image_Media_sizes__medium_jpeg__filename_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Image_Media_sizes__large_jpeg__url_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Image_Media_sizes__large_jpeg__width_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Image_Media_sizes__large_jpeg__height_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Image_Media_sizes__large_jpeg__mimeType_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Image_Media_sizes__large_jpeg__filesize_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Image_Media_sizes__large_jpeg__filename_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Image_Media_id_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Image_Media_where_and"]: {
	alt?: ModelTypes["Image_Media_alt_operator"] | undefined,
	updatedAt?: ModelTypes["Image_Media_updatedAt_operator"] | undefined,
	createdAt?: ModelTypes["Image_Media_createdAt_operator"] | undefined,
	url?: ModelTypes["Image_Media_url_operator"] | undefined,
	filename?: ModelTypes["Image_Media_filename_operator"] | undefined,
	mimeType?: ModelTypes["Image_Media_mimeType_operator"] | undefined,
	filesize?: ModelTypes["Image_Media_filesize_operator"] | undefined,
	width?: ModelTypes["Image_Media_width_operator"] | undefined,
	height?: ModelTypes["Image_Media_height_operator"] | undefined,
	focalX?: ModelTypes["Image_Media_focalX_operator"] | undefined,
	focalY?: ModelTypes["Image_Media_focalY_operator"] | undefined,
	sizes__small__url?: ModelTypes["Image_Media_sizes__small__url_operator"] | undefined,
	sizes__small__width?: ModelTypes["Image_Media_sizes__small__width_operator"] | undefined,
	sizes__small__height?: ModelTypes["Image_Media_sizes__small__height_operator"] | undefined,
	sizes__small__mimeType?: ModelTypes["Image_Media_sizes__small__mimeType_operator"] | undefined,
	sizes__small__filesize?: ModelTypes["Image_Media_sizes__small__filesize_operator"] | undefined,
	sizes__small__filename?: ModelTypes["Image_Media_sizes__small__filename_operator"] | undefined,
	sizes__medium__url?: ModelTypes["Image_Media_sizes__medium__url_operator"] | undefined,
	sizes__medium__width?: ModelTypes["Image_Media_sizes__medium__width_operator"] | undefined,
	sizes__medium__height?: ModelTypes["Image_Media_sizes__medium__height_operator"] | undefined,
	sizes__medium__mimeType?: ModelTypes["Image_Media_sizes__medium__mimeType_operator"] | undefined,
	sizes__medium__filesize?: ModelTypes["Image_Media_sizes__medium__filesize_operator"] | undefined,
	sizes__medium__filename?: ModelTypes["Image_Media_sizes__medium__filename_operator"] | undefined,
	sizes__large__url?: ModelTypes["Image_Media_sizes__large__url_operator"] | undefined,
	sizes__large__width?: ModelTypes["Image_Media_sizes__large__width_operator"] | undefined,
	sizes__large__height?: ModelTypes["Image_Media_sizes__large__height_operator"] | undefined,
	sizes__large__mimeType?: ModelTypes["Image_Media_sizes__large__mimeType_operator"] | undefined,
	sizes__large__filesize?: ModelTypes["Image_Media_sizes__large__filesize_operator"] | undefined,
	sizes__large__filename?: ModelTypes["Image_Media_sizes__large__filename_operator"] | undefined,
	sizes__small_jpeg__url?: ModelTypes["Image_Media_sizes__small_jpeg__url_operator"] | undefined,
	sizes__small_jpeg__width?: ModelTypes["Image_Media_sizes__small_jpeg__width_operator"] | undefined,
	sizes__small_jpeg__height?: ModelTypes["Image_Media_sizes__small_jpeg__height_operator"] | undefined,
	sizes__small_jpeg__mimeType?: ModelTypes["Image_Media_sizes__small_jpeg__mimeType_operator"] | undefined,
	sizes__small_jpeg__filesize?: ModelTypes["Image_Media_sizes__small_jpeg__filesize_operator"] | undefined,
	sizes__small_jpeg__filename?: ModelTypes["Image_Media_sizes__small_jpeg__filename_operator"] | undefined,
	sizes__medium_jpeg__url?: ModelTypes["Image_Media_sizes__medium_jpeg__url_operator"] | undefined,
	sizes__medium_jpeg__width?: ModelTypes["Image_Media_sizes__medium_jpeg__width_operator"] | undefined,
	sizes__medium_jpeg__height?: ModelTypes["Image_Media_sizes__medium_jpeg__height_operator"] | undefined,
	sizes__medium_jpeg__mimeType?: ModelTypes["Image_Media_sizes__medium_jpeg__mimeType_operator"] | undefined,
	sizes__medium_jpeg__filesize?: ModelTypes["Image_Media_sizes__medium_jpeg__filesize_operator"] | undefined,
	sizes__medium_jpeg__filename?: ModelTypes["Image_Media_sizes__medium_jpeg__filename_operator"] | undefined,
	sizes__large_jpeg__url?: ModelTypes["Image_Media_sizes__large_jpeg__url_operator"] | undefined,
	sizes__large_jpeg__width?: ModelTypes["Image_Media_sizes__large_jpeg__width_operator"] | undefined,
	sizes__large_jpeg__height?: ModelTypes["Image_Media_sizes__large_jpeg__height_operator"] | undefined,
	sizes__large_jpeg__mimeType?: ModelTypes["Image_Media_sizes__large_jpeg__mimeType_operator"] | undefined,
	sizes__large_jpeg__filesize?: ModelTypes["Image_Media_sizes__large_jpeg__filesize_operator"] | undefined,
	sizes__large_jpeg__filename?: ModelTypes["Image_Media_sizes__large_jpeg__filename_operator"] | undefined,
	id?: ModelTypes["Image_Media_id_operator"] | undefined,
	AND?: Array<ModelTypes["Image_Media_where_and"] | undefined> | undefined,
	OR?: Array<ModelTypes["Image_Media_where_or"] | undefined> | undefined
};
	["Image_Media_where_or"]: {
	alt?: ModelTypes["Image_Media_alt_operator"] | undefined,
	updatedAt?: ModelTypes["Image_Media_updatedAt_operator"] | undefined,
	createdAt?: ModelTypes["Image_Media_createdAt_operator"] | undefined,
	url?: ModelTypes["Image_Media_url_operator"] | undefined,
	filename?: ModelTypes["Image_Media_filename_operator"] | undefined,
	mimeType?: ModelTypes["Image_Media_mimeType_operator"] | undefined,
	filesize?: ModelTypes["Image_Media_filesize_operator"] | undefined,
	width?: ModelTypes["Image_Media_width_operator"] | undefined,
	height?: ModelTypes["Image_Media_height_operator"] | undefined,
	focalX?: ModelTypes["Image_Media_focalX_operator"] | undefined,
	focalY?: ModelTypes["Image_Media_focalY_operator"] | undefined,
	sizes__small__url?: ModelTypes["Image_Media_sizes__small__url_operator"] | undefined,
	sizes__small__width?: ModelTypes["Image_Media_sizes__small__width_operator"] | undefined,
	sizes__small__height?: ModelTypes["Image_Media_sizes__small__height_operator"] | undefined,
	sizes__small__mimeType?: ModelTypes["Image_Media_sizes__small__mimeType_operator"] | undefined,
	sizes__small__filesize?: ModelTypes["Image_Media_sizes__small__filesize_operator"] | undefined,
	sizes__small__filename?: ModelTypes["Image_Media_sizes__small__filename_operator"] | undefined,
	sizes__medium__url?: ModelTypes["Image_Media_sizes__medium__url_operator"] | undefined,
	sizes__medium__width?: ModelTypes["Image_Media_sizes__medium__width_operator"] | undefined,
	sizes__medium__height?: ModelTypes["Image_Media_sizes__medium__height_operator"] | undefined,
	sizes__medium__mimeType?: ModelTypes["Image_Media_sizes__medium__mimeType_operator"] | undefined,
	sizes__medium__filesize?: ModelTypes["Image_Media_sizes__medium__filesize_operator"] | undefined,
	sizes__medium__filename?: ModelTypes["Image_Media_sizes__medium__filename_operator"] | undefined,
	sizes__large__url?: ModelTypes["Image_Media_sizes__large__url_operator"] | undefined,
	sizes__large__width?: ModelTypes["Image_Media_sizes__large__width_operator"] | undefined,
	sizes__large__height?: ModelTypes["Image_Media_sizes__large__height_operator"] | undefined,
	sizes__large__mimeType?: ModelTypes["Image_Media_sizes__large__mimeType_operator"] | undefined,
	sizes__large__filesize?: ModelTypes["Image_Media_sizes__large__filesize_operator"] | undefined,
	sizes__large__filename?: ModelTypes["Image_Media_sizes__large__filename_operator"] | undefined,
	sizes__small_jpeg__url?: ModelTypes["Image_Media_sizes__small_jpeg__url_operator"] | undefined,
	sizes__small_jpeg__width?: ModelTypes["Image_Media_sizes__small_jpeg__width_operator"] | undefined,
	sizes__small_jpeg__height?: ModelTypes["Image_Media_sizes__small_jpeg__height_operator"] | undefined,
	sizes__small_jpeg__mimeType?: ModelTypes["Image_Media_sizes__small_jpeg__mimeType_operator"] | undefined,
	sizes__small_jpeg__filesize?: ModelTypes["Image_Media_sizes__small_jpeg__filesize_operator"] | undefined,
	sizes__small_jpeg__filename?: ModelTypes["Image_Media_sizes__small_jpeg__filename_operator"] | undefined,
	sizes__medium_jpeg__url?: ModelTypes["Image_Media_sizes__medium_jpeg__url_operator"] | undefined,
	sizes__medium_jpeg__width?: ModelTypes["Image_Media_sizes__medium_jpeg__width_operator"] | undefined,
	sizes__medium_jpeg__height?: ModelTypes["Image_Media_sizes__medium_jpeg__height_operator"] | undefined,
	sizes__medium_jpeg__mimeType?: ModelTypes["Image_Media_sizes__medium_jpeg__mimeType_operator"] | undefined,
	sizes__medium_jpeg__filesize?: ModelTypes["Image_Media_sizes__medium_jpeg__filesize_operator"] | undefined,
	sizes__medium_jpeg__filename?: ModelTypes["Image_Media_sizes__medium_jpeg__filename_operator"] | undefined,
	sizes__large_jpeg__url?: ModelTypes["Image_Media_sizes__large_jpeg__url_operator"] | undefined,
	sizes__large_jpeg__width?: ModelTypes["Image_Media_sizes__large_jpeg__width_operator"] | undefined,
	sizes__large_jpeg__height?: ModelTypes["Image_Media_sizes__large_jpeg__height_operator"] | undefined,
	sizes__large_jpeg__mimeType?: ModelTypes["Image_Media_sizes__large_jpeg__mimeType_operator"] | undefined,
	sizes__large_jpeg__filesize?: ModelTypes["Image_Media_sizes__large_jpeg__filesize_operator"] | undefined,
	sizes__large_jpeg__filename?: ModelTypes["Image_Media_sizes__large_jpeg__filename_operator"] | undefined,
	id?: ModelTypes["Image_Media_id_operator"] | undefined,
	AND?: Array<ModelTypes["Image_Media_where_and"] | undefined> | undefined,
	OR?: Array<ModelTypes["Image_Media_where_or"] | undefined> | undefined
};
	["Video"]: {
		url: string,
	id?: string | undefined,
	blockName?: string | undefined,
	blockType?: string | undefined
};
	["Location_Criteria"]: {
		audiences?: Array<ModelTypes["Audience"]> | undefined,
	dates?: Array<ModelTypes["Date"]> | undefined,
	categories?: Array<ModelTypes["EventCategory"]> | undefined,
	tags?: Array<ModelTypes["Tag"]> | undefined
};
	["Audience"]: {
		id?: string | undefined,
	name?: string | undefined,
	value: string,
	icon?: ModelTypes["Media"] | undefined,
	region?: ModelTypes["Audience_region"] | undefined,
	updatedAt?: ModelTypes["DateTime"] | undefined,
	createdAt?: ModelTypes["DateTime"] | undefined
};
	["Audience_Icon_where"]: {
	alt?: ModelTypes["Audience_Icon_alt_operator"] | undefined,
	updatedAt?: ModelTypes["Audience_Icon_updatedAt_operator"] | undefined,
	createdAt?: ModelTypes["Audience_Icon_createdAt_operator"] | undefined,
	url?: ModelTypes["Audience_Icon_url_operator"] | undefined,
	filename?: ModelTypes["Audience_Icon_filename_operator"] | undefined,
	mimeType?: ModelTypes["Audience_Icon_mimeType_operator"] | undefined,
	filesize?: ModelTypes["Audience_Icon_filesize_operator"] | undefined,
	width?: ModelTypes["Audience_Icon_width_operator"] | undefined,
	height?: ModelTypes["Audience_Icon_height_operator"] | undefined,
	focalX?: ModelTypes["Audience_Icon_focalX_operator"] | undefined,
	focalY?: ModelTypes["Audience_Icon_focalY_operator"] | undefined,
	sizes__small__url?: ModelTypes["Audience_Icon_sizes__small__url_operator"] | undefined,
	sizes__small__width?: ModelTypes["Audience_Icon_sizes__small__width_operator"] | undefined,
	sizes__small__height?: ModelTypes["Audience_Icon_sizes__small__height_operator"] | undefined,
	sizes__small__mimeType?: ModelTypes["Audience_Icon_sizes__small__mimeType_operator"] | undefined,
	sizes__small__filesize?: ModelTypes["Audience_Icon_sizes__small__filesize_operator"] | undefined,
	sizes__small__filename?: ModelTypes["Audience_Icon_sizes__small__filename_operator"] | undefined,
	sizes__medium__url?: ModelTypes["Audience_Icon_sizes__medium__url_operator"] | undefined,
	sizes__medium__width?: ModelTypes["Audience_Icon_sizes__medium__width_operator"] | undefined,
	sizes__medium__height?: ModelTypes["Audience_Icon_sizes__medium__height_operator"] | undefined,
	sizes__medium__mimeType?: ModelTypes["Audience_Icon_sizes__medium__mimeType_operator"] | undefined,
	sizes__medium__filesize?: ModelTypes["Audience_Icon_sizes__medium__filesize_operator"] | undefined,
	sizes__medium__filename?: ModelTypes["Audience_Icon_sizes__medium__filename_operator"] | undefined,
	sizes__large__url?: ModelTypes["Audience_Icon_sizes__large__url_operator"] | undefined,
	sizes__large__width?: ModelTypes["Audience_Icon_sizes__large__width_operator"] | undefined,
	sizes__large__height?: ModelTypes["Audience_Icon_sizes__large__height_operator"] | undefined,
	sizes__large__mimeType?: ModelTypes["Audience_Icon_sizes__large__mimeType_operator"] | undefined,
	sizes__large__filesize?: ModelTypes["Audience_Icon_sizes__large__filesize_operator"] | undefined,
	sizes__large__filename?: ModelTypes["Audience_Icon_sizes__large__filename_operator"] | undefined,
	sizes__small_jpeg__url?: ModelTypes["Audience_Icon_sizes__small_jpeg__url_operator"] | undefined,
	sizes__small_jpeg__width?: ModelTypes["Audience_Icon_sizes__small_jpeg__width_operator"] | undefined,
	sizes__small_jpeg__height?: ModelTypes["Audience_Icon_sizes__small_jpeg__height_operator"] | undefined,
	sizes__small_jpeg__mimeType?: ModelTypes["Audience_Icon_sizes__small_jpeg__mimeType_operator"] | undefined,
	sizes__small_jpeg__filesize?: ModelTypes["Audience_Icon_sizes__small_jpeg__filesize_operator"] | undefined,
	sizes__small_jpeg__filename?: ModelTypes["Audience_Icon_sizes__small_jpeg__filename_operator"] | undefined,
	sizes__medium_jpeg__url?: ModelTypes["Audience_Icon_sizes__medium_jpeg__url_operator"] | undefined,
	sizes__medium_jpeg__width?: ModelTypes["Audience_Icon_sizes__medium_jpeg__width_operator"] | undefined,
	sizes__medium_jpeg__height?: ModelTypes["Audience_Icon_sizes__medium_jpeg__height_operator"] | undefined,
	sizes__medium_jpeg__mimeType?: ModelTypes["Audience_Icon_sizes__medium_jpeg__mimeType_operator"] | undefined,
	sizes__medium_jpeg__filesize?: ModelTypes["Audience_Icon_sizes__medium_jpeg__filesize_operator"] | undefined,
	sizes__medium_jpeg__filename?: ModelTypes["Audience_Icon_sizes__medium_jpeg__filename_operator"] | undefined,
	sizes__large_jpeg__url?: ModelTypes["Audience_Icon_sizes__large_jpeg__url_operator"] | undefined,
	sizes__large_jpeg__width?: ModelTypes["Audience_Icon_sizes__large_jpeg__width_operator"] | undefined,
	sizes__large_jpeg__height?: ModelTypes["Audience_Icon_sizes__large_jpeg__height_operator"] | undefined,
	sizes__large_jpeg__mimeType?: ModelTypes["Audience_Icon_sizes__large_jpeg__mimeType_operator"] | undefined,
	sizes__large_jpeg__filesize?: ModelTypes["Audience_Icon_sizes__large_jpeg__filesize_operator"] | undefined,
	sizes__large_jpeg__filename?: ModelTypes["Audience_Icon_sizes__large_jpeg__filename_operator"] | undefined,
	id?: ModelTypes["Audience_Icon_id_operator"] | undefined,
	AND?: Array<ModelTypes["Audience_Icon_where_and"] | undefined> | undefined,
	OR?: Array<ModelTypes["Audience_Icon_where_or"] | undefined> | undefined
};
	["Audience_Icon_alt_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Audience_Icon_updatedAt_operator"]: {
	equals?: ModelTypes["DateTime"] | undefined,
	not_equals?: ModelTypes["DateTime"] | undefined,
	greater_than_equal?: ModelTypes["DateTime"] | undefined,
	greater_than?: ModelTypes["DateTime"] | undefined,
	less_than_equal?: ModelTypes["DateTime"] | undefined,
	less_than?: ModelTypes["DateTime"] | undefined,
	like?: ModelTypes["DateTime"] | undefined,
	exists?: boolean | undefined
};
	["Audience_Icon_createdAt_operator"]: {
	equals?: ModelTypes["DateTime"] | undefined,
	not_equals?: ModelTypes["DateTime"] | undefined,
	greater_than_equal?: ModelTypes["DateTime"] | undefined,
	greater_than?: ModelTypes["DateTime"] | undefined,
	less_than_equal?: ModelTypes["DateTime"] | undefined,
	less_than?: ModelTypes["DateTime"] | undefined,
	like?: ModelTypes["DateTime"] | undefined,
	exists?: boolean | undefined
};
	["Audience_Icon_url_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Audience_Icon_filename_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Audience_Icon_mimeType_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Audience_Icon_filesize_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Audience_Icon_width_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Audience_Icon_height_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Audience_Icon_focalX_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Audience_Icon_focalY_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Audience_Icon_sizes__small__url_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Audience_Icon_sizes__small__width_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Audience_Icon_sizes__small__height_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Audience_Icon_sizes__small__mimeType_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Audience_Icon_sizes__small__filesize_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Audience_Icon_sizes__small__filename_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Audience_Icon_sizes__medium__url_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Audience_Icon_sizes__medium__width_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Audience_Icon_sizes__medium__height_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Audience_Icon_sizes__medium__mimeType_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Audience_Icon_sizes__medium__filesize_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Audience_Icon_sizes__medium__filename_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Audience_Icon_sizes__large__url_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Audience_Icon_sizes__large__width_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Audience_Icon_sizes__large__height_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Audience_Icon_sizes__large__mimeType_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Audience_Icon_sizes__large__filesize_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Audience_Icon_sizes__large__filename_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Audience_Icon_sizes__small_jpeg__url_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Audience_Icon_sizes__small_jpeg__width_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Audience_Icon_sizes__small_jpeg__height_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Audience_Icon_sizes__small_jpeg__mimeType_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Audience_Icon_sizes__small_jpeg__filesize_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Audience_Icon_sizes__small_jpeg__filename_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Audience_Icon_sizes__medium_jpeg__url_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Audience_Icon_sizes__medium_jpeg__width_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Audience_Icon_sizes__medium_jpeg__height_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Audience_Icon_sizes__medium_jpeg__mimeType_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Audience_Icon_sizes__medium_jpeg__filesize_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Audience_Icon_sizes__medium_jpeg__filename_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Audience_Icon_sizes__large_jpeg__url_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Audience_Icon_sizes__large_jpeg__width_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Audience_Icon_sizes__large_jpeg__height_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Audience_Icon_sizes__large_jpeg__mimeType_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Audience_Icon_sizes__large_jpeg__filesize_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Audience_Icon_sizes__large_jpeg__filename_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Audience_Icon_id_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Audience_Icon_where_and"]: {
	alt?: ModelTypes["Audience_Icon_alt_operator"] | undefined,
	updatedAt?: ModelTypes["Audience_Icon_updatedAt_operator"] | undefined,
	createdAt?: ModelTypes["Audience_Icon_createdAt_operator"] | undefined,
	url?: ModelTypes["Audience_Icon_url_operator"] | undefined,
	filename?: ModelTypes["Audience_Icon_filename_operator"] | undefined,
	mimeType?: ModelTypes["Audience_Icon_mimeType_operator"] | undefined,
	filesize?: ModelTypes["Audience_Icon_filesize_operator"] | undefined,
	width?: ModelTypes["Audience_Icon_width_operator"] | undefined,
	height?: ModelTypes["Audience_Icon_height_operator"] | undefined,
	focalX?: ModelTypes["Audience_Icon_focalX_operator"] | undefined,
	focalY?: ModelTypes["Audience_Icon_focalY_operator"] | undefined,
	sizes__small__url?: ModelTypes["Audience_Icon_sizes__small__url_operator"] | undefined,
	sizes__small__width?: ModelTypes["Audience_Icon_sizes__small__width_operator"] | undefined,
	sizes__small__height?: ModelTypes["Audience_Icon_sizes__small__height_operator"] | undefined,
	sizes__small__mimeType?: ModelTypes["Audience_Icon_sizes__small__mimeType_operator"] | undefined,
	sizes__small__filesize?: ModelTypes["Audience_Icon_sizes__small__filesize_operator"] | undefined,
	sizes__small__filename?: ModelTypes["Audience_Icon_sizes__small__filename_operator"] | undefined,
	sizes__medium__url?: ModelTypes["Audience_Icon_sizes__medium__url_operator"] | undefined,
	sizes__medium__width?: ModelTypes["Audience_Icon_sizes__medium__width_operator"] | undefined,
	sizes__medium__height?: ModelTypes["Audience_Icon_sizes__medium__height_operator"] | undefined,
	sizes__medium__mimeType?: ModelTypes["Audience_Icon_sizes__medium__mimeType_operator"] | undefined,
	sizes__medium__filesize?: ModelTypes["Audience_Icon_sizes__medium__filesize_operator"] | undefined,
	sizes__medium__filename?: ModelTypes["Audience_Icon_sizes__medium__filename_operator"] | undefined,
	sizes__large__url?: ModelTypes["Audience_Icon_sizes__large__url_operator"] | undefined,
	sizes__large__width?: ModelTypes["Audience_Icon_sizes__large__width_operator"] | undefined,
	sizes__large__height?: ModelTypes["Audience_Icon_sizes__large__height_operator"] | undefined,
	sizes__large__mimeType?: ModelTypes["Audience_Icon_sizes__large__mimeType_operator"] | undefined,
	sizes__large__filesize?: ModelTypes["Audience_Icon_sizes__large__filesize_operator"] | undefined,
	sizes__large__filename?: ModelTypes["Audience_Icon_sizes__large__filename_operator"] | undefined,
	sizes__small_jpeg__url?: ModelTypes["Audience_Icon_sizes__small_jpeg__url_operator"] | undefined,
	sizes__small_jpeg__width?: ModelTypes["Audience_Icon_sizes__small_jpeg__width_operator"] | undefined,
	sizes__small_jpeg__height?: ModelTypes["Audience_Icon_sizes__small_jpeg__height_operator"] | undefined,
	sizes__small_jpeg__mimeType?: ModelTypes["Audience_Icon_sizes__small_jpeg__mimeType_operator"] | undefined,
	sizes__small_jpeg__filesize?: ModelTypes["Audience_Icon_sizes__small_jpeg__filesize_operator"] | undefined,
	sizes__small_jpeg__filename?: ModelTypes["Audience_Icon_sizes__small_jpeg__filename_operator"] | undefined,
	sizes__medium_jpeg__url?: ModelTypes["Audience_Icon_sizes__medium_jpeg__url_operator"] | undefined,
	sizes__medium_jpeg__width?: ModelTypes["Audience_Icon_sizes__medium_jpeg__width_operator"] | undefined,
	sizes__medium_jpeg__height?: ModelTypes["Audience_Icon_sizes__medium_jpeg__height_operator"] | undefined,
	sizes__medium_jpeg__mimeType?: ModelTypes["Audience_Icon_sizes__medium_jpeg__mimeType_operator"] | undefined,
	sizes__medium_jpeg__filesize?: ModelTypes["Audience_Icon_sizes__medium_jpeg__filesize_operator"] | undefined,
	sizes__medium_jpeg__filename?: ModelTypes["Audience_Icon_sizes__medium_jpeg__filename_operator"] | undefined,
	sizes__large_jpeg__url?: ModelTypes["Audience_Icon_sizes__large_jpeg__url_operator"] | undefined,
	sizes__large_jpeg__width?: ModelTypes["Audience_Icon_sizes__large_jpeg__width_operator"] | undefined,
	sizes__large_jpeg__height?: ModelTypes["Audience_Icon_sizes__large_jpeg__height_operator"] | undefined,
	sizes__large_jpeg__mimeType?: ModelTypes["Audience_Icon_sizes__large_jpeg__mimeType_operator"] | undefined,
	sizes__large_jpeg__filesize?: ModelTypes["Audience_Icon_sizes__large_jpeg__filesize_operator"] | undefined,
	sizes__large_jpeg__filename?: ModelTypes["Audience_Icon_sizes__large_jpeg__filename_operator"] | undefined,
	id?: ModelTypes["Audience_Icon_id_operator"] | undefined,
	AND?: Array<ModelTypes["Audience_Icon_where_and"] | undefined> | undefined,
	OR?: Array<ModelTypes["Audience_Icon_where_or"] | undefined> | undefined
};
	["Audience_Icon_where_or"]: {
	alt?: ModelTypes["Audience_Icon_alt_operator"] | undefined,
	updatedAt?: ModelTypes["Audience_Icon_updatedAt_operator"] | undefined,
	createdAt?: ModelTypes["Audience_Icon_createdAt_operator"] | undefined,
	url?: ModelTypes["Audience_Icon_url_operator"] | undefined,
	filename?: ModelTypes["Audience_Icon_filename_operator"] | undefined,
	mimeType?: ModelTypes["Audience_Icon_mimeType_operator"] | undefined,
	filesize?: ModelTypes["Audience_Icon_filesize_operator"] | undefined,
	width?: ModelTypes["Audience_Icon_width_operator"] | undefined,
	height?: ModelTypes["Audience_Icon_height_operator"] | undefined,
	focalX?: ModelTypes["Audience_Icon_focalX_operator"] | undefined,
	focalY?: ModelTypes["Audience_Icon_focalY_operator"] | undefined,
	sizes__small__url?: ModelTypes["Audience_Icon_sizes__small__url_operator"] | undefined,
	sizes__small__width?: ModelTypes["Audience_Icon_sizes__small__width_operator"] | undefined,
	sizes__small__height?: ModelTypes["Audience_Icon_sizes__small__height_operator"] | undefined,
	sizes__small__mimeType?: ModelTypes["Audience_Icon_sizes__small__mimeType_operator"] | undefined,
	sizes__small__filesize?: ModelTypes["Audience_Icon_sizes__small__filesize_operator"] | undefined,
	sizes__small__filename?: ModelTypes["Audience_Icon_sizes__small__filename_operator"] | undefined,
	sizes__medium__url?: ModelTypes["Audience_Icon_sizes__medium__url_operator"] | undefined,
	sizes__medium__width?: ModelTypes["Audience_Icon_sizes__medium__width_operator"] | undefined,
	sizes__medium__height?: ModelTypes["Audience_Icon_sizes__medium__height_operator"] | undefined,
	sizes__medium__mimeType?: ModelTypes["Audience_Icon_sizes__medium__mimeType_operator"] | undefined,
	sizes__medium__filesize?: ModelTypes["Audience_Icon_sizes__medium__filesize_operator"] | undefined,
	sizes__medium__filename?: ModelTypes["Audience_Icon_sizes__medium__filename_operator"] | undefined,
	sizes__large__url?: ModelTypes["Audience_Icon_sizes__large__url_operator"] | undefined,
	sizes__large__width?: ModelTypes["Audience_Icon_sizes__large__width_operator"] | undefined,
	sizes__large__height?: ModelTypes["Audience_Icon_sizes__large__height_operator"] | undefined,
	sizes__large__mimeType?: ModelTypes["Audience_Icon_sizes__large__mimeType_operator"] | undefined,
	sizes__large__filesize?: ModelTypes["Audience_Icon_sizes__large__filesize_operator"] | undefined,
	sizes__large__filename?: ModelTypes["Audience_Icon_sizes__large__filename_operator"] | undefined,
	sizes__small_jpeg__url?: ModelTypes["Audience_Icon_sizes__small_jpeg__url_operator"] | undefined,
	sizes__small_jpeg__width?: ModelTypes["Audience_Icon_sizes__small_jpeg__width_operator"] | undefined,
	sizes__small_jpeg__height?: ModelTypes["Audience_Icon_sizes__small_jpeg__height_operator"] | undefined,
	sizes__small_jpeg__mimeType?: ModelTypes["Audience_Icon_sizes__small_jpeg__mimeType_operator"] | undefined,
	sizes__small_jpeg__filesize?: ModelTypes["Audience_Icon_sizes__small_jpeg__filesize_operator"] | undefined,
	sizes__small_jpeg__filename?: ModelTypes["Audience_Icon_sizes__small_jpeg__filename_operator"] | undefined,
	sizes__medium_jpeg__url?: ModelTypes["Audience_Icon_sizes__medium_jpeg__url_operator"] | undefined,
	sizes__medium_jpeg__width?: ModelTypes["Audience_Icon_sizes__medium_jpeg__width_operator"] | undefined,
	sizes__medium_jpeg__height?: ModelTypes["Audience_Icon_sizes__medium_jpeg__height_operator"] | undefined,
	sizes__medium_jpeg__mimeType?: ModelTypes["Audience_Icon_sizes__medium_jpeg__mimeType_operator"] | undefined,
	sizes__medium_jpeg__filesize?: ModelTypes["Audience_Icon_sizes__medium_jpeg__filesize_operator"] | undefined,
	sizes__medium_jpeg__filename?: ModelTypes["Audience_Icon_sizes__medium_jpeg__filename_operator"] | undefined,
	sizes__large_jpeg__url?: ModelTypes["Audience_Icon_sizes__large_jpeg__url_operator"] | undefined,
	sizes__large_jpeg__width?: ModelTypes["Audience_Icon_sizes__large_jpeg__width_operator"] | undefined,
	sizes__large_jpeg__height?: ModelTypes["Audience_Icon_sizes__large_jpeg__height_operator"] | undefined,
	sizes__large_jpeg__mimeType?: ModelTypes["Audience_Icon_sizes__large_jpeg__mimeType_operator"] | undefined,
	sizes__large_jpeg__filesize?: ModelTypes["Audience_Icon_sizes__large_jpeg__filesize_operator"] | undefined,
	sizes__large_jpeg__filename?: ModelTypes["Audience_Icon_sizes__large_jpeg__filename_operator"] | undefined,
	id?: ModelTypes["Audience_Icon_id_operator"] | undefined,
	AND?: Array<ModelTypes["Audience_Icon_where_and"] | undefined> | undefined,
	OR?: Array<ModelTypes["Audience_Icon_where_or"] | undefined> | undefined
};
	["Audience_region"]:Audience_region;
	["Date"]: {
		id?: string | undefined,
	name?: string | undefined,
	value: string,
	type: ModelTypes["Date_type"],
	start?: ModelTypes["DateTime"] | undefined,
	end?: ModelTypes["DateTime"] | undefined,
	_title?: string | undefined,
	region?: ModelTypes["Date_region"] | undefined,
	updatedAt?: ModelTypes["DateTime"] | undefined,
	createdAt?: ModelTypes["DateTime"] | undefined
};
	["Date_type"]:Date_type;
	["Date_region"]:Date_region;
	["Location_Sections"]: {
		startDatetime?: ModelTypes["DateTime"] | undefined,
	endDatetime?: ModelTypes["DateTime"] | undefined,
	fullDay?: boolean | undefined,
	repeat?: boolean | undefined,
	recurrance?: ModelTypes["Location_Sections_Recurrance"] | undefined,
	id?: string | undefined
};
	["Location_Sections_Recurrance"]: {
		type?: ModelTypes["Location_Sections_Recurrance_type"] | undefined,
	weekday?: Array<ModelTypes["Location_Sections_Recurrance_weekday"]> | undefined
};
	["Location_Sections_Recurrance_type"]:Location_Sections_Recurrance_type;
	["Location_Sections_Recurrance_weekday"]:Location_Sections_Recurrance_weekday;
	["Event_Ticketing"]: {
		startDatetime?: ModelTypes["DateTime"] | undefined,
	endDatetime?: ModelTypes["DateTime"] | undefined,
	fullDay?: boolean | undefined,
	type?: ModelTypes["Event_Ticketing_type"] | undefined,
	ticketingType?: ModelTypes["Event_Ticketing_ticketingType"] | undefined,
	linkedTicketing?: ModelTypes["Ticketing"] | undefined,
	promotionType?: ModelTypes["Event_Ticketing_promotionType"] | undefined,
	location?: ModelTypes["Location"] | undefined,
	website?: string | undefined,
	description?: string | undefined,
	id?: string | undefined
};
	["Event_Ticketing_type"]:Event_Ticketing_type;
	["Event_Ticketing_ticketingType"]:Event_Ticketing_ticketingType;
	["Ticketing"]: {
		id?: string | undefined,
	status?: ModelTypes["Ticketing_status"] | undefined,
	creator?: ModelTypes["User"] | undefined,
	productType: ModelTypes["Ticketing_productType"],
	productName?: string | undefined,
	referenceId?: string | undefined,
	linkedEvent?: ModelTypes["Event"] | undefined,
	CheckInLevel: ModelTypes["Ticketing_CheckInLevel"],
	displayPrice: string,
	tnc?: string | undefined,
	cutoffHours: number,
	sessions?: Array<ModelTypes["TicketingVariants"]> | undefined,
	updatedAt?: ModelTypes["DateTime"] | undefined,
	createdAt?: ModelTypes["DateTime"] | undefined
};
	["Ticketing_status"]:Ticketing_status;
	["Ticketing_productType"]:Ticketing_productType;
	["Ticketing_CheckInLevel"]:Ticketing_CheckInLevel;
	["TicketingVariants"]: {
		variantId?: string | undefined,
	productId?: string | undefined,
	startDate?: ModelTypes["DateTime"] | undefined,
	endDate?: ModelTypes["DateTime"] | undefined,
	inventories?: ModelTypes["Inventories"] | undefined,
	id?: string | undefined
};
	["Inventories"]: {
		name?: string | undefined,
	quantity?: number | undefined,
	unitPrice?: number | undefined,
	handlingFee?: number | undefined,
	totalCost?: number | undefined,
	minPurchase?: number | undefined,
	maxPurchase?: number | undefined,
	stocks?: ModelTypes["Inventories_Stocks"] | undefined,
	remarks?: string | undefined
};
	["Inventories_Stocks"]: {
		stockId?: string | undefined,
	remaining?: number | undefined,
	sold?: number | undefined,
	onHold?: number | undefined,
	checkedIn?: number | undefined
};
	["Event_Ticketing_promotionType"]:Event_Ticketing_promotionType;
	["Event_Content"]:ModelTypes["RichText"] | ModelTypes["Gallery"] | ModelTypes["Image"] | ModelTypes["Video"];
	["Event_Criteria"]: {
		categories?: Array<ModelTypes["EventCategory"]> | undefined,
	audiences?: Array<ModelTypes["Audience"]> | undefined,
	tags?: Array<ModelTypes["Tag"]> | undefined,
	specialTags?: Array<ModelTypes["SpecialTag"]> | undefined
};
	["SpecialTag"]: {
		id?: string | undefined,
	name: string,
	value: string,
	region?: ModelTypes["SpecialTag_region"] | undefined
};
	["SpecialTag_region"]:SpecialTag_region;
	["Event_Contact"]: {
		code?: number | undefined,
	phone?: string | undefined,
	page?: string | undefined,
	email?: ModelTypes["EmailAddress"] | undefined,
	privacy?: ModelTypes["JSON"] | undefined
};
	["Event_Fees"]: {
		fee?: number | undefined,
	remark?: string | undefined,
	id?: string | undefined
};
	["Event_Products"]: {
		startDateTime?: ModelTypes["DateTime"] | undefined,
	endDateTime?: ModelTypes["DateTime"] | undefined,
	fullDay?: boolean | undefined,
	linkedProduct?: ModelTypes["Product"] | undefined,
	id?: string | undefined
};
	["Product"]: {
		id?: string | undefined,
	status?: ModelTypes["Product_status"] | undefined,
	creator?: ModelTypes["User"] | undefined,
	productType: ModelTypes["Product_productType"],
	productName?: string | undefined,
	referenceId?: string | undefined,
	linkedEvent: ModelTypes["Event"],
	CheckInLevel: ModelTypes["Product_CheckInLevel"],
	thumbnail: ModelTypes["Media"],
	displayPrice: string,
	transactionType: ModelTypes["Product_transactionType"],
	website?: string | undefined,
	tnc?: string | undefined,
	remarks?: string | undefined,
	sessions?: Array<ModelTypes["ProductVariants"]> | undefined,
	updatedAt?: ModelTypes["DateTime"] | undefined,
	createdAt?: ModelTypes["DateTime"] | undefined
};
	["Product_status"]:Product_status;
	["Product_productType"]:Product_productType;
	["Product_CheckInLevel"]:Product_CheckInLevel;
	["Product_Thumbnail_where"]: {
	alt?: ModelTypes["Product_Thumbnail_alt_operator"] | undefined,
	updatedAt?: ModelTypes["Product_Thumbnail_updatedAt_operator"] | undefined,
	createdAt?: ModelTypes["Product_Thumbnail_createdAt_operator"] | undefined,
	url?: ModelTypes["Product_Thumbnail_url_operator"] | undefined,
	filename?: ModelTypes["Product_Thumbnail_filename_operator"] | undefined,
	mimeType?: ModelTypes["Product_Thumbnail_mimeType_operator"] | undefined,
	filesize?: ModelTypes["Product_Thumbnail_filesize_operator"] | undefined,
	width?: ModelTypes["Product_Thumbnail_width_operator"] | undefined,
	height?: ModelTypes["Product_Thumbnail_height_operator"] | undefined,
	focalX?: ModelTypes["Product_Thumbnail_focalX_operator"] | undefined,
	focalY?: ModelTypes["Product_Thumbnail_focalY_operator"] | undefined,
	sizes__small__url?: ModelTypes["Product_Thumbnail_sizes__small__url_operator"] | undefined,
	sizes__small__width?: ModelTypes["Product_Thumbnail_sizes__small__width_operator"] | undefined,
	sizes__small__height?: ModelTypes["Product_Thumbnail_sizes__small__height_operator"] | undefined,
	sizes__small__mimeType?: ModelTypes["Product_Thumbnail_sizes__small__mimeType_operator"] | undefined,
	sizes__small__filesize?: ModelTypes["Product_Thumbnail_sizes__small__filesize_operator"] | undefined,
	sizes__small__filename?: ModelTypes["Product_Thumbnail_sizes__small__filename_operator"] | undefined,
	sizes__medium__url?: ModelTypes["Product_Thumbnail_sizes__medium__url_operator"] | undefined,
	sizes__medium__width?: ModelTypes["Product_Thumbnail_sizes__medium__width_operator"] | undefined,
	sizes__medium__height?: ModelTypes["Product_Thumbnail_sizes__medium__height_operator"] | undefined,
	sizes__medium__mimeType?: ModelTypes["Product_Thumbnail_sizes__medium__mimeType_operator"] | undefined,
	sizes__medium__filesize?: ModelTypes["Product_Thumbnail_sizes__medium__filesize_operator"] | undefined,
	sizes__medium__filename?: ModelTypes["Product_Thumbnail_sizes__medium__filename_operator"] | undefined,
	sizes__large__url?: ModelTypes["Product_Thumbnail_sizes__large__url_operator"] | undefined,
	sizes__large__width?: ModelTypes["Product_Thumbnail_sizes__large__width_operator"] | undefined,
	sizes__large__height?: ModelTypes["Product_Thumbnail_sizes__large__height_operator"] | undefined,
	sizes__large__mimeType?: ModelTypes["Product_Thumbnail_sizes__large__mimeType_operator"] | undefined,
	sizes__large__filesize?: ModelTypes["Product_Thumbnail_sizes__large__filesize_operator"] | undefined,
	sizes__large__filename?: ModelTypes["Product_Thumbnail_sizes__large__filename_operator"] | undefined,
	sizes__small_jpeg__url?: ModelTypes["Product_Thumbnail_sizes__small_jpeg__url_operator"] | undefined,
	sizes__small_jpeg__width?: ModelTypes["Product_Thumbnail_sizes__small_jpeg__width_operator"] | undefined,
	sizes__small_jpeg__height?: ModelTypes["Product_Thumbnail_sizes__small_jpeg__height_operator"] | undefined,
	sizes__small_jpeg__mimeType?: ModelTypes["Product_Thumbnail_sizes__small_jpeg__mimeType_operator"] | undefined,
	sizes__small_jpeg__filesize?: ModelTypes["Product_Thumbnail_sizes__small_jpeg__filesize_operator"] | undefined,
	sizes__small_jpeg__filename?: ModelTypes["Product_Thumbnail_sizes__small_jpeg__filename_operator"] | undefined,
	sizes__medium_jpeg__url?: ModelTypes["Product_Thumbnail_sizes__medium_jpeg__url_operator"] | undefined,
	sizes__medium_jpeg__width?: ModelTypes["Product_Thumbnail_sizes__medium_jpeg__width_operator"] | undefined,
	sizes__medium_jpeg__height?: ModelTypes["Product_Thumbnail_sizes__medium_jpeg__height_operator"] | undefined,
	sizes__medium_jpeg__mimeType?: ModelTypes["Product_Thumbnail_sizes__medium_jpeg__mimeType_operator"] | undefined,
	sizes__medium_jpeg__filesize?: ModelTypes["Product_Thumbnail_sizes__medium_jpeg__filesize_operator"] | undefined,
	sizes__medium_jpeg__filename?: ModelTypes["Product_Thumbnail_sizes__medium_jpeg__filename_operator"] | undefined,
	sizes__large_jpeg__url?: ModelTypes["Product_Thumbnail_sizes__large_jpeg__url_operator"] | undefined,
	sizes__large_jpeg__width?: ModelTypes["Product_Thumbnail_sizes__large_jpeg__width_operator"] | undefined,
	sizes__large_jpeg__height?: ModelTypes["Product_Thumbnail_sizes__large_jpeg__height_operator"] | undefined,
	sizes__large_jpeg__mimeType?: ModelTypes["Product_Thumbnail_sizes__large_jpeg__mimeType_operator"] | undefined,
	sizes__large_jpeg__filesize?: ModelTypes["Product_Thumbnail_sizes__large_jpeg__filesize_operator"] | undefined,
	sizes__large_jpeg__filename?: ModelTypes["Product_Thumbnail_sizes__large_jpeg__filename_operator"] | undefined,
	id?: ModelTypes["Product_Thumbnail_id_operator"] | undefined,
	AND?: Array<ModelTypes["Product_Thumbnail_where_and"] | undefined> | undefined,
	OR?: Array<ModelTypes["Product_Thumbnail_where_or"] | undefined> | undefined
};
	["Product_Thumbnail_alt_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Product_Thumbnail_updatedAt_operator"]: {
	equals?: ModelTypes["DateTime"] | undefined,
	not_equals?: ModelTypes["DateTime"] | undefined,
	greater_than_equal?: ModelTypes["DateTime"] | undefined,
	greater_than?: ModelTypes["DateTime"] | undefined,
	less_than_equal?: ModelTypes["DateTime"] | undefined,
	less_than?: ModelTypes["DateTime"] | undefined,
	like?: ModelTypes["DateTime"] | undefined,
	exists?: boolean | undefined
};
	["Product_Thumbnail_createdAt_operator"]: {
	equals?: ModelTypes["DateTime"] | undefined,
	not_equals?: ModelTypes["DateTime"] | undefined,
	greater_than_equal?: ModelTypes["DateTime"] | undefined,
	greater_than?: ModelTypes["DateTime"] | undefined,
	less_than_equal?: ModelTypes["DateTime"] | undefined,
	less_than?: ModelTypes["DateTime"] | undefined,
	like?: ModelTypes["DateTime"] | undefined,
	exists?: boolean | undefined
};
	["Product_Thumbnail_url_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Product_Thumbnail_filename_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Product_Thumbnail_mimeType_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Product_Thumbnail_filesize_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Product_Thumbnail_width_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Product_Thumbnail_height_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Product_Thumbnail_focalX_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Product_Thumbnail_focalY_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Product_Thumbnail_sizes__small__url_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Product_Thumbnail_sizes__small__width_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Product_Thumbnail_sizes__small__height_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Product_Thumbnail_sizes__small__mimeType_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Product_Thumbnail_sizes__small__filesize_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Product_Thumbnail_sizes__small__filename_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Product_Thumbnail_sizes__medium__url_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Product_Thumbnail_sizes__medium__width_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Product_Thumbnail_sizes__medium__height_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Product_Thumbnail_sizes__medium__mimeType_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Product_Thumbnail_sizes__medium__filesize_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Product_Thumbnail_sizes__medium__filename_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Product_Thumbnail_sizes__large__url_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Product_Thumbnail_sizes__large__width_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Product_Thumbnail_sizes__large__height_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Product_Thumbnail_sizes__large__mimeType_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Product_Thumbnail_sizes__large__filesize_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Product_Thumbnail_sizes__large__filename_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Product_Thumbnail_sizes__small_jpeg__url_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Product_Thumbnail_sizes__small_jpeg__width_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Product_Thumbnail_sizes__small_jpeg__height_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Product_Thumbnail_sizes__small_jpeg__mimeType_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Product_Thumbnail_sizes__small_jpeg__filesize_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Product_Thumbnail_sizes__small_jpeg__filename_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Product_Thumbnail_sizes__medium_jpeg__url_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Product_Thumbnail_sizes__medium_jpeg__width_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Product_Thumbnail_sizes__medium_jpeg__height_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Product_Thumbnail_sizes__medium_jpeg__mimeType_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Product_Thumbnail_sizes__medium_jpeg__filesize_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Product_Thumbnail_sizes__medium_jpeg__filename_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Product_Thumbnail_sizes__large_jpeg__url_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Product_Thumbnail_sizes__large_jpeg__width_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Product_Thumbnail_sizes__large_jpeg__height_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Product_Thumbnail_sizes__large_jpeg__mimeType_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Product_Thumbnail_sizes__large_jpeg__filesize_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Product_Thumbnail_sizes__large_jpeg__filename_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Product_Thumbnail_id_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Product_Thumbnail_where_and"]: {
	alt?: ModelTypes["Product_Thumbnail_alt_operator"] | undefined,
	updatedAt?: ModelTypes["Product_Thumbnail_updatedAt_operator"] | undefined,
	createdAt?: ModelTypes["Product_Thumbnail_createdAt_operator"] | undefined,
	url?: ModelTypes["Product_Thumbnail_url_operator"] | undefined,
	filename?: ModelTypes["Product_Thumbnail_filename_operator"] | undefined,
	mimeType?: ModelTypes["Product_Thumbnail_mimeType_operator"] | undefined,
	filesize?: ModelTypes["Product_Thumbnail_filesize_operator"] | undefined,
	width?: ModelTypes["Product_Thumbnail_width_operator"] | undefined,
	height?: ModelTypes["Product_Thumbnail_height_operator"] | undefined,
	focalX?: ModelTypes["Product_Thumbnail_focalX_operator"] | undefined,
	focalY?: ModelTypes["Product_Thumbnail_focalY_operator"] | undefined,
	sizes__small__url?: ModelTypes["Product_Thumbnail_sizes__small__url_operator"] | undefined,
	sizes__small__width?: ModelTypes["Product_Thumbnail_sizes__small__width_operator"] | undefined,
	sizes__small__height?: ModelTypes["Product_Thumbnail_sizes__small__height_operator"] | undefined,
	sizes__small__mimeType?: ModelTypes["Product_Thumbnail_sizes__small__mimeType_operator"] | undefined,
	sizes__small__filesize?: ModelTypes["Product_Thumbnail_sizes__small__filesize_operator"] | undefined,
	sizes__small__filename?: ModelTypes["Product_Thumbnail_sizes__small__filename_operator"] | undefined,
	sizes__medium__url?: ModelTypes["Product_Thumbnail_sizes__medium__url_operator"] | undefined,
	sizes__medium__width?: ModelTypes["Product_Thumbnail_sizes__medium__width_operator"] | undefined,
	sizes__medium__height?: ModelTypes["Product_Thumbnail_sizes__medium__height_operator"] | undefined,
	sizes__medium__mimeType?: ModelTypes["Product_Thumbnail_sizes__medium__mimeType_operator"] | undefined,
	sizes__medium__filesize?: ModelTypes["Product_Thumbnail_sizes__medium__filesize_operator"] | undefined,
	sizes__medium__filename?: ModelTypes["Product_Thumbnail_sizes__medium__filename_operator"] | undefined,
	sizes__large__url?: ModelTypes["Product_Thumbnail_sizes__large__url_operator"] | undefined,
	sizes__large__width?: ModelTypes["Product_Thumbnail_sizes__large__width_operator"] | undefined,
	sizes__large__height?: ModelTypes["Product_Thumbnail_sizes__large__height_operator"] | undefined,
	sizes__large__mimeType?: ModelTypes["Product_Thumbnail_sizes__large__mimeType_operator"] | undefined,
	sizes__large__filesize?: ModelTypes["Product_Thumbnail_sizes__large__filesize_operator"] | undefined,
	sizes__large__filename?: ModelTypes["Product_Thumbnail_sizes__large__filename_operator"] | undefined,
	sizes__small_jpeg__url?: ModelTypes["Product_Thumbnail_sizes__small_jpeg__url_operator"] | undefined,
	sizes__small_jpeg__width?: ModelTypes["Product_Thumbnail_sizes__small_jpeg__width_operator"] | undefined,
	sizes__small_jpeg__height?: ModelTypes["Product_Thumbnail_sizes__small_jpeg__height_operator"] | undefined,
	sizes__small_jpeg__mimeType?: ModelTypes["Product_Thumbnail_sizes__small_jpeg__mimeType_operator"] | undefined,
	sizes__small_jpeg__filesize?: ModelTypes["Product_Thumbnail_sizes__small_jpeg__filesize_operator"] | undefined,
	sizes__small_jpeg__filename?: ModelTypes["Product_Thumbnail_sizes__small_jpeg__filename_operator"] | undefined,
	sizes__medium_jpeg__url?: ModelTypes["Product_Thumbnail_sizes__medium_jpeg__url_operator"] | undefined,
	sizes__medium_jpeg__width?: ModelTypes["Product_Thumbnail_sizes__medium_jpeg__width_operator"] | undefined,
	sizes__medium_jpeg__height?: ModelTypes["Product_Thumbnail_sizes__medium_jpeg__height_operator"] | undefined,
	sizes__medium_jpeg__mimeType?: ModelTypes["Product_Thumbnail_sizes__medium_jpeg__mimeType_operator"] | undefined,
	sizes__medium_jpeg__filesize?: ModelTypes["Product_Thumbnail_sizes__medium_jpeg__filesize_operator"] | undefined,
	sizes__medium_jpeg__filename?: ModelTypes["Product_Thumbnail_sizes__medium_jpeg__filename_operator"] | undefined,
	sizes__large_jpeg__url?: ModelTypes["Product_Thumbnail_sizes__large_jpeg__url_operator"] | undefined,
	sizes__large_jpeg__width?: ModelTypes["Product_Thumbnail_sizes__large_jpeg__width_operator"] | undefined,
	sizes__large_jpeg__height?: ModelTypes["Product_Thumbnail_sizes__large_jpeg__height_operator"] | undefined,
	sizes__large_jpeg__mimeType?: ModelTypes["Product_Thumbnail_sizes__large_jpeg__mimeType_operator"] | undefined,
	sizes__large_jpeg__filesize?: ModelTypes["Product_Thumbnail_sizes__large_jpeg__filesize_operator"] | undefined,
	sizes__large_jpeg__filename?: ModelTypes["Product_Thumbnail_sizes__large_jpeg__filename_operator"] | undefined,
	id?: ModelTypes["Product_Thumbnail_id_operator"] | undefined,
	AND?: Array<ModelTypes["Product_Thumbnail_where_and"] | undefined> | undefined,
	OR?: Array<ModelTypes["Product_Thumbnail_where_or"] | undefined> | undefined
};
	["Product_Thumbnail_where_or"]: {
	alt?: ModelTypes["Product_Thumbnail_alt_operator"] | undefined,
	updatedAt?: ModelTypes["Product_Thumbnail_updatedAt_operator"] | undefined,
	createdAt?: ModelTypes["Product_Thumbnail_createdAt_operator"] | undefined,
	url?: ModelTypes["Product_Thumbnail_url_operator"] | undefined,
	filename?: ModelTypes["Product_Thumbnail_filename_operator"] | undefined,
	mimeType?: ModelTypes["Product_Thumbnail_mimeType_operator"] | undefined,
	filesize?: ModelTypes["Product_Thumbnail_filesize_operator"] | undefined,
	width?: ModelTypes["Product_Thumbnail_width_operator"] | undefined,
	height?: ModelTypes["Product_Thumbnail_height_operator"] | undefined,
	focalX?: ModelTypes["Product_Thumbnail_focalX_operator"] | undefined,
	focalY?: ModelTypes["Product_Thumbnail_focalY_operator"] | undefined,
	sizes__small__url?: ModelTypes["Product_Thumbnail_sizes__small__url_operator"] | undefined,
	sizes__small__width?: ModelTypes["Product_Thumbnail_sizes__small__width_operator"] | undefined,
	sizes__small__height?: ModelTypes["Product_Thumbnail_sizes__small__height_operator"] | undefined,
	sizes__small__mimeType?: ModelTypes["Product_Thumbnail_sizes__small__mimeType_operator"] | undefined,
	sizes__small__filesize?: ModelTypes["Product_Thumbnail_sizes__small__filesize_operator"] | undefined,
	sizes__small__filename?: ModelTypes["Product_Thumbnail_sizes__small__filename_operator"] | undefined,
	sizes__medium__url?: ModelTypes["Product_Thumbnail_sizes__medium__url_operator"] | undefined,
	sizes__medium__width?: ModelTypes["Product_Thumbnail_sizes__medium__width_operator"] | undefined,
	sizes__medium__height?: ModelTypes["Product_Thumbnail_sizes__medium__height_operator"] | undefined,
	sizes__medium__mimeType?: ModelTypes["Product_Thumbnail_sizes__medium__mimeType_operator"] | undefined,
	sizes__medium__filesize?: ModelTypes["Product_Thumbnail_sizes__medium__filesize_operator"] | undefined,
	sizes__medium__filename?: ModelTypes["Product_Thumbnail_sizes__medium__filename_operator"] | undefined,
	sizes__large__url?: ModelTypes["Product_Thumbnail_sizes__large__url_operator"] | undefined,
	sizes__large__width?: ModelTypes["Product_Thumbnail_sizes__large__width_operator"] | undefined,
	sizes__large__height?: ModelTypes["Product_Thumbnail_sizes__large__height_operator"] | undefined,
	sizes__large__mimeType?: ModelTypes["Product_Thumbnail_sizes__large__mimeType_operator"] | undefined,
	sizes__large__filesize?: ModelTypes["Product_Thumbnail_sizes__large__filesize_operator"] | undefined,
	sizes__large__filename?: ModelTypes["Product_Thumbnail_sizes__large__filename_operator"] | undefined,
	sizes__small_jpeg__url?: ModelTypes["Product_Thumbnail_sizes__small_jpeg__url_operator"] | undefined,
	sizes__small_jpeg__width?: ModelTypes["Product_Thumbnail_sizes__small_jpeg__width_operator"] | undefined,
	sizes__small_jpeg__height?: ModelTypes["Product_Thumbnail_sizes__small_jpeg__height_operator"] | undefined,
	sizes__small_jpeg__mimeType?: ModelTypes["Product_Thumbnail_sizes__small_jpeg__mimeType_operator"] | undefined,
	sizes__small_jpeg__filesize?: ModelTypes["Product_Thumbnail_sizes__small_jpeg__filesize_operator"] | undefined,
	sizes__small_jpeg__filename?: ModelTypes["Product_Thumbnail_sizes__small_jpeg__filename_operator"] | undefined,
	sizes__medium_jpeg__url?: ModelTypes["Product_Thumbnail_sizes__medium_jpeg__url_operator"] | undefined,
	sizes__medium_jpeg__width?: ModelTypes["Product_Thumbnail_sizes__medium_jpeg__width_operator"] | undefined,
	sizes__medium_jpeg__height?: ModelTypes["Product_Thumbnail_sizes__medium_jpeg__height_operator"] | undefined,
	sizes__medium_jpeg__mimeType?: ModelTypes["Product_Thumbnail_sizes__medium_jpeg__mimeType_operator"] | undefined,
	sizes__medium_jpeg__filesize?: ModelTypes["Product_Thumbnail_sizes__medium_jpeg__filesize_operator"] | undefined,
	sizes__medium_jpeg__filename?: ModelTypes["Product_Thumbnail_sizes__medium_jpeg__filename_operator"] | undefined,
	sizes__large_jpeg__url?: ModelTypes["Product_Thumbnail_sizes__large_jpeg__url_operator"] | undefined,
	sizes__large_jpeg__width?: ModelTypes["Product_Thumbnail_sizes__large_jpeg__width_operator"] | undefined,
	sizes__large_jpeg__height?: ModelTypes["Product_Thumbnail_sizes__large_jpeg__height_operator"] | undefined,
	sizes__large_jpeg__mimeType?: ModelTypes["Product_Thumbnail_sizes__large_jpeg__mimeType_operator"] | undefined,
	sizes__large_jpeg__filesize?: ModelTypes["Product_Thumbnail_sizes__large_jpeg__filesize_operator"] | undefined,
	sizes__large_jpeg__filename?: ModelTypes["Product_Thumbnail_sizes__large_jpeg__filename_operator"] | undefined,
	id?: ModelTypes["Product_Thumbnail_id_operator"] | undefined,
	AND?: Array<ModelTypes["Product_Thumbnail_where_and"] | undefined> | undefined,
	OR?: Array<ModelTypes["Product_Thumbnail_where_or"] | undefined> | undefined
};
	["Product_transactionType"]:Product_transactionType;
	["ProductVariants"]: {
		variantId?: string | undefined,
	productId?: string | undefined,
	name?: string | undefined,
	inventories?: ModelTypes["Inventories"] | undefined,
	id?: string | undefined
};
	["Events"]: {
		docs?: Array<ModelTypes["Event"] | undefined> | undefined,
	hasNextPage?: boolean | undefined,
	hasPrevPage?: boolean | undefined,
	limit?: number | undefined,
	nextPage?: number | undefined,
	offset?: number | undefined,
	page?: number | undefined,
	pagingCounter?: number | undefined,
	prevPage?: number | undefined,
	totalDocs?: number | undefined,
	totalPages?: number | undefined
};
	["Event_where"]: {
	region?: ModelTypes["Event_region_operator"] | undefined,
	creator?: ModelTypes["Event_creator_operator"] | undefined,
	permalink?: ModelTypes["Event_permalink_operator"] | undefined,
	status?: ModelTypes["Event_status_operator"] | undefined,
	level?: ModelTypes["Event_level_operator"] | undefined,
	parent?: ModelTypes["Event_parent_operator"] | undefined,
	name?: ModelTypes["Event_name_operator"] | undefined,
	chatbotEventTitle?: ModelTypes["Event_chatbotEventTitle_operator"] | undefined,
	chatbotSequence?: ModelTypes["Event_chatbotSequence_operator"] | undefined,
	thumbnail?: ModelTypes["Event_thumbnail_operator"] | undefined,
	banner?: ModelTypes["Event_banner_operator"] | undefined,
	mobileBanner?: ModelTypes["Event_mobileBanner_operator"] | undefined,
	sections__startDatetime?: ModelTypes["Event_sections__startDatetime_operator"] | undefined,
	sections__endDatetime?: ModelTypes["Event_sections__endDatetime_operator"] | undefined,
	sections__fullDay?: ModelTypes["Event_sections__fullDay_operator"] | undefined,
	sections__repeat?: ModelTypes["Event_sections__repeat_operator"] | undefined,
	sections__toThisDay?: ModelTypes["Event_sections__toThisDay_operator"] | undefined,
	sections__recurrance__type?: ModelTypes["Event_sections__recurrance__type_operator"] | undefined,
	sections__recurrance__weekday?: ModelTypes["Event_sections__recurrance__weekday_operator"] | undefined,
	sections__sameLocation?: ModelTypes["Event_sections__sameLocation_operator"] | undefined,
	sections__location?: ModelTypes["Event_sections__location_operator"] | undefined,
	sections__district?: ModelTypes["Event_sections__district_operator"] | undefined,
	sections__coordinate?: ModelTypes["Event_sections__coordinate_operator"] | undefined,
	sections__linkAddress?: ModelTypes["Event_sections__linkAddress_operator"] | undefined,
	sections__address?: ModelTypes["Event_sections__address_operator"] | undefined,
	sections__addressCoordinate?: ModelTypes["Event_sections__addressCoordinate_operator"] | undefined,
	sections__id?: ModelTypes["Event_sections__id_operator"] | undefined,
	ticketing__startDatetime?: ModelTypes["Event_ticketing__startDatetime_operator"] | undefined,
	ticketing__endDatetime?: ModelTypes["Event_ticketing__endDatetime_operator"] | undefined,
	ticketing__fullDay?: ModelTypes["Event_ticketing__fullDay_operator"] | undefined,
	ticketing__type?: ModelTypes["Event_ticketing__type_operator"] | undefined,
	ticketing__ticketingType?: ModelTypes["Event_ticketing__ticketingType_operator"] | undefined,
	ticketing__linkedTicketing?: ModelTypes["Event_ticketing__linkedTicketing_operator"] | undefined,
	ticketing__promotionType?: ModelTypes["Event_ticketing__promotionType_operator"] | undefined,
	ticketing__location?: ModelTypes["Event_ticketing__location_operator"] | undefined,
	ticketing__website?: ModelTypes["Event_ticketing__website_operator"] | undefined,
	ticketing__description?: ModelTypes["Event_ticketing__description_operator"] | undefined,
	ticketing__id?: ModelTypes["Event_ticketing__id_operator"] | undefined,
	legacyContent?: ModelTypes["Event_legacyContent_operator"] | undefined,
	showLegacyContent?: ModelTypes["Event_showLegacyContent_operator"] | undefined,
	criteria__categories?: ModelTypes["Event_criteria__categories_operator"] | undefined,
	criteria__audiences?: ModelTypes["Event_criteria__audiences_operator"] | undefined,
	criteria__tags?: ModelTypes["Event_criteria__tags_operator"] | undefined,
	criteria__specialTags?: ModelTypes["Event_criteria__specialTags_operator"] | undefined,
	walkIn?: ModelTypes["Event_walkIn_operator"] | undefined,
	contact__code?: ModelTypes["Event_contact__code_operator"] | undefined,
	contact__phone?: ModelTypes["Event_contact__phone_operator"] | undefined,
	contact__page?: ModelTypes["Event_contact__page_operator"] | undefined,
	contact__email?: ModelTypes["Event_contact__email_operator"] | undefined,
	contact__privacy?: ModelTypes["Event_contact__privacy_operator"] | undefined,
	views?: ModelTypes["Event_views_operator"] | undefined,
	free?: ModelTypes["Event_free_operator"] | undefined,
	fees__fee?: ModelTypes["Event_fees__fee_operator"] | undefined,
	fees__remark?: ModelTypes["Event_fees__remark_operator"] | undefined,
	fees__id?: ModelTypes["Event_fees__id_operator"] | undefined,
	homePageInternalClick?: ModelTypes["Event_homePageInternalClick_operator"] | undefined,
	homePageImpression?: ModelTypes["Event_homePageImpression_operator"] | undefined,
	eodClickRate?: ModelTypes["Event_eodClickRate_operator"] | undefined,
	eodImpressionRate?: ModelTypes["Event_eodImpressionRate_operator"] | undefined,
	top10ClickRateHomepage?: ModelTypes["Event_top10ClickRateHomepage_operator"] | undefined,
	top10ImpressionkRateHomepage?: ModelTypes["Event_top10ImpressionkRateHomepage_operator"] | undefined,
	otherEventRankingClickRateHomepage?: ModelTypes["Event_otherEventRankingClickRateHomepage_operator"] | undefined,
	otherEventRankingImpressionHomePage?: ModelTypes["Event_otherEventRankingImpressionHomePage_operator"] | undefined,
	userPreferenceClickRate?: ModelTypes["Event_userPreferenceClickRate_operator"] | undefined,
	userPreferenceImpression?: ModelTypes["Event_userPreferenceImpression_operator"] | undefined,
	eventSearchInternalClick?: ModelTypes["Event_eventSearchInternalClick_operator"] | undefined,
	eventListImpression?: ModelTypes["Event_eventListImpression_operator"] | undefined,
	latestEventInternalClick?: ModelTypes["Event_latestEventInternalClick_operator"] | undefined,
	latestEventImpression?: ModelTypes["Event_latestEventImpression_operator"] | undefined,
	topTenEventRankingInternalClick?: ModelTypes["Event_topTenEventRankingInternalClick_operator"] | undefined,
	topTenImpression?: ModelTypes["Event_topTenImpression_operator"] | undefined,
	editorChoiceInternalClick?: ModelTypes["Event_editorChoiceInternalClick_operator"] | undefined,
	editorChoiceImpression?: ModelTypes["Event_editorChoiceImpression_operator"] | undefined,
	otherEventRankingInternalClick?: ModelTypes["Event_otherEventRankingInternalClick_operator"] | undefined,
	otherEventRankingImpression?: ModelTypes["Event_otherEventRankingImpression_operator"] | undefined,
	bannerInternalClick?: ModelTypes["Event_bannerInternalClick_operator"] | undefined,
	miscImpression?: ModelTypes["Event_miscImpression_operator"] | undefined,
	miscInternalClick?: ModelTypes["Event_miscInternalClick_operator"] | undefined,
	totalImpression?: ModelTypes["Event_totalImpression_operator"] | undefined,
	internalClick?: ModelTypes["Event_internalClick_operator"] | undefined,
	outboundClick?: ModelTypes["Event_outboundClick_operator"] | undefined,
	externalClick?: ModelTypes["Event_externalClick_operator"] | undefined,
	_viewsCountByMonth?: ModelTypes["Event__viewsCountByMonth_operator"] | undefined,
	products__startDateTime?: ModelTypes["Event_products__startDateTime_operator"] | undefined,
	products__endDateTime?: ModelTypes["Event_products__endDateTime_operator"] | undefined,
	products__fullDay?: ModelTypes["Event_products__fullDay_operator"] | undefined,
	products__linkedProduct?: ModelTypes["Event_products__linkedProduct_operator"] | undefined,
	products__id?: ModelTypes["Event_products__id_operator"] | undefined,
	legacyGallery?: ModelTypes["Event_legacyGallery_operator"] | undefined,
	earliestStartDate?: ModelTypes["Event_earliestStartDate_operator"] | undefined,
	latestEndDate?: ModelTypes["Event_latestEndDate_operator"] | undefined,
	latestStartDateTime?: ModelTypes["Event_latestStartDateTime_operator"] | undefined,
	hasTicketing?: ModelTypes["Event_hasTicketing_operator"] | undefined,
	hasProducts?: ModelTypes["Event_hasProducts_operator"] | undefined,
	updatedAt?: ModelTypes["Event_updatedAt_operator"] | undefined,
	createdAt?: ModelTypes["Event_createdAt_operator"] | undefined,
	id?: ModelTypes["Event_id_operator"] | undefined,
	AND?: Array<ModelTypes["Event_where_and"] | undefined> | undefined,
	OR?: Array<ModelTypes["Event_where_or"] | undefined> | undefined
};
	["Event_region_operator"]: {
	equals?: ModelTypes["Event_region_Input"] | undefined,
	not_equals?: ModelTypes["Event_region_Input"] | undefined,
	in?: Array<ModelTypes["Event_region_Input"] | undefined> | undefined,
	not_in?: Array<ModelTypes["Event_region_Input"] | undefined> | undefined,
	all?: Array<ModelTypes["Event_region_Input"] | undefined> | undefined,
	exists?: boolean | undefined
};
	["Event_region_Input"]:Event_region_Input;
	["Event_creator_operator"]: {
	equals?: ModelTypes["JSON"] | undefined,
	not_equals?: ModelTypes["JSON"] | undefined,
	in?: Array<ModelTypes["JSON"] | undefined> | undefined,
	not_in?: Array<ModelTypes["JSON"] | undefined> | undefined,
	all?: Array<ModelTypes["JSON"] | undefined> | undefined,
	exists?: boolean | undefined
};
	["Event_permalink_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Event_status_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined
};
	["Event_level_operator"]: {
	equals?: ModelTypes["Event_level_Input"] | undefined,
	not_equals?: ModelTypes["Event_level_Input"] | undefined,
	in?: Array<ModelTypes["Event_level_Input"] | undefined> | undefined,
	not_in?: Array<ModelTypes["Event_level_Input"] | undefined> | undefined,
	all?: Array<ModelTypes["Event_level_Input"] | undefined> | undefined
};
	["Event_level_Input"]:Event_level_Input;
	["Event_parent_operator"]: {
	equals?: ModelTypes["JSON"] | undefined,
	not_equals?: ModelTypes["JSON"] | undefined,
	in?: Array<ModelTypes["JSON"] | undefined> | undefined,
	not_in?: Array<ModelTypes["JSON"] | undefined> | undefined,
	all?: Array<ModelTypes["JSON"] | undefined> | undefined,
	exists?: boolean | undefined
};
	["Event_name_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined
};
	["Event_chatbotEventTitle_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined
};
	["Event_chatbotSequence_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Event_thumbnail_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	exists?: boolean | undefined
};
	["Event_banner_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	exists?: boolean | undefined
};
	["Event_mobileBanner_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	exists?: boolean | undefined
};
	["Event_sections__startDatetime_operator"]: {
	equals?: ModelTypes["DateTime"] | undefined,
	not_equals?: ModelTypes["DateTime"] | undefined,
	greater_than_equal?: ModelTypes["DateTime"] | undefined,
	greater_than?: ModelTypes["DateTime"] | undefined,
	less_than_equal?: ModelTypes["DateTime"] | undefined,
	less_than?: ModelTypes["DateTime"] | undefined,
	like?: ModelTypes["DateTime"] | undefined
};
	["Event_sections__endDatetime_operator"]: {
	equals?: ModelTypes["DateTime"] | undefined,
	not_equals?: ModelTypes["DateTime"] | undefined,
	greater_than_equal?: ModelTypes["DateTime"] | undefined,
	greater_than?: ModelTypes["DateTime"] | undefined,
	less_than_equal?: ModelTypes["DateTime"] | undefined,
	less_than?: ModelTypes["DateTime"] | undefined,
	like?: ModelTypes["DateTime"] | undefined
};
	["Event_sections__fullDay_operator"]: {
	equals?: boolean | undefined,
	not_equals?: boolean | undefined,
	exists?: boolean | undefined
};
	["Event_sections__repeat_operator"]: {
	equals?: boolean | undefined,
	not_equals?: boolean | undefined,
	exists?: boolean | undefined
};
	["Event_sections__toThisDay_operator"]: {
	equals?: ModelTypes["DateTime"] | undefined,
	not_equals?: ModelTypes["DateTime"] | undefined,
	greater_than_equal?: ModelTypes["DateTime"] | undefined,
	greater_than?: ModelTypes["DateTime"] | undefined,
	less_than_equal?: ModelTypes["DateTime"] | undefined,
	less_than?: ModelTypes["DateTime"] | undefined,
	like?: ModelTypes["DateTime"] | undefined
};
	["Event_sections__recurrance__type_operator"]: {
	equals?: ModelTypes["Event_sections__recurrance__type_Input"] | undefined,
	not_equals?: ModelTypes["Event_sections__recurrance__type_Input"] | undefined,
	in?: Array<ModelTypes["Event_sections__recurrance__type_Input"] | undefined> | undefined,
	not_in?: Array<ModelTypes["Event_sections__recurrance__type_Input"] | undefined> | undefined,
	all?: Array<ModelTypes["Event_sections__recurrance__type_Input"] | undefined> | undefined
};
	["Event_sections__recurrance__type_Input"]:Event_sections__recurrance__type_Input;
	["Event_sections__recurrance__weekday_operator"]: {
	equals?: ModelTypes["Event_sections__recurrance__weekday_Input"] | undefined,
	not_equals?: ModelTypes["Event_sections__recurrance__weekday_Input"] | undefined,
	in?: Array<ModelTypes["Event_sections__recurrance__weekday_Input"] | undefined> | undefined,
	not_in?: Array<ModelTypes["Event_sections__recurrance__weekday_Input"] | undefined> | undefined,
	all?: Array<ModelTypes["Event_sections__recurrance__weekday_Input"] | undefined> | undefined
};
	["Event_sections__recurrance__weekday_Input"]:Event_sections__recurrance__weekday_Input;
	["Event_sections__sameLocation_operator"]: {
	equals?: boolean | undefined,
	not_equals?: boolean | undefined,
	exists?: boolean | undefined
};
	["Event_sections__location_operator"]: {
	equals?: ModelTypes["JSON"] | undefined,
	not_equals?: ModelTypes["JSON"] | undefined,
	in?: Array<ModelTypes["JSON"] | undefined> | undefined,
	not_in?: Array<ModelTypes["JSON"] | undefined> | undefined,
	all?: Array<ModelTypes["JSON"] | undefined> | undefined,
	exists?: boolean | undefined
};
	["Event_sections__district_operator"]: {
	equals?: ModelTypes["JSON"] | undefined,
	not_equals?: ModelTypes["JSON"] | undefined,
	in?: Array<ModelTypes["JSON"] | undefined> | undefined,
	not_in?: Array<ModelTypes["JSON"] | undefined> | undefined,
	all?: Array<ModelTypes["JSON"] | undefined> | undefined,
	exists?: boolean | undefined
};
	["Event_sections__coordinate_operator"]: {
	equals?: Array<number | undefined> | undefined,
	not_equals?: Array<number | undefined> | undefined,
	greater_than_equal?: Array<number | undefined> | undefined,
	greater_than?: Array<number | undefined> | undefined,
	less_than_equal?: Array<number | undefined> | undefined,
	less_than?: Array<number | undefined> | undefined,
	near?: Array<number | undefined> | undefined,
	within?: ModelTypes["GeoJSONObject"] | undefined,
	intersects?: ModelTypes["GeoJSONObject"] | undefined,
	exists?: boolean | undefined
};
	["GeoJSONObject"]: {
	coordinates?: ModelTypes["JSON"] | undefined,
	type?: string | undefined
};
	["Event_sections__linkAddress_operator"]: {
	equals?: boolean | undefined,
	not_equals?: boolean | undefined,
	exists?: boolean | undefined
};
	["Event_sections__address_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Event_sections__addressCoordinate_operator"]: {
	equals?: Array<number | undefined> | undefined,
	not_equals?: Array<number | undefined> | undefined,
	greater_than_equal?: Array<number | undefined> | undefined,
	greater_than?: Array<number | undefined> | undefined,
	less_than_equal?: Array<number | undefined> | undefined,
	less_than?: Array<number | undefined> | undefined,
	near?: Array<number | undefined> | undefined,
	within?: ModelTypes["GeoJSONObject"] | undefined,
	intersects?: ModelTypes["GeoJSONObject"] | undefined,
	exists?: boolean | undefined
};
	["Event_sections__id_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Event_ticketing__startDatetime_operator"]: {
	equals?: ModelTypes["DateTime"] | undefined,
	not_equals?: ModelTypes["DateTime"] | undefined,
	greater_than_equal?: ModelTypes["DateTime"] | undefined,
	greater_than?: ModelTypes["DateTime"] | undefined,
	less_than_equal?: ModelTypes["DateTime"] | undefined,
	less_than?: ModelTypes["DateTime"] | undefined,
	like?: ModelTypes["DateTime"] | undefined
};
	["Event_ticketing__endDatetime_operator"]: {
	equals?: ModelTypes["DateTime"] | undefined,
	not_equals?: ModelTypes["DateTime"] | undefined,
	greater_than_equal?: ModelTypes["DateTime"] | undefined,
	greater_than?: ModelTypes["DateTime"] | undefined,
	less_than_equal?: ModelTypes["DateTime"] | undefined,
	less_than?: ModelTypes["DateTime"] | undefined,
	like?: ModelTypes["DateTime"] | undefined
};
	["Event_ticketing__fullDay_operator"]: {
	equals?: boolean | undefined,
	not_equals?: boolean | undefined,
	exists?: boolean | undefined
};
	["Event_ticketing__type_operator"]: {
	equals?: ModelTypes["Event_ticketing__type_Input"] | undefined,
	not_equals?: ModelTypes["Event_ticketing__type_Input"] | undefined,
	in?: Array<ModelTypes["Event_ticketing__type_Input"] | undefined> | undefined,
	not_in?: Array<ModelTypes["Event_ticketing__type_Input"] | undefined> | undefined,
	all?: Array<ModelTypes["Event_ticketing__type_Input"] | undefined> | undefined
};
	["Event_ticketing__type_Input"]:Event_ticketing__type_Input;
	["Event_ticketing__ticketingType_operator"]: {
	equals?: ModelTypes["Event_ticketing__ticketingType_Input"] | undefined,
	not_equals?: ModelTypes["Event_ticketing__ticketingType_Input"] | undefined,
	in?: Array<ModelTypes["Event_ticketing__ticketingType_Input"] | undefined> | undefined,
	not_in?: Array<ModelTypes["Event_ticketing__ticketingType_Input"] | undefined> | undefined,
	all?: Array<ModelTypes["Event_ticketing__ticketingType_Input"] | undefined> | undefined
};
	["Event_ticketing__ticketingType_Input"]:Event_ticketing__ticketingType_Input;
	["Event_ticketing__linkedTicketing_operator"]: {
	equals?: ModelTypes["JSON"] | undefined,
	not_equals?: ModelTypes["JSON"] | undefined,
	in?: Array<ModelTypes["JSON"] | undefined> | undefined,
	not_in?: Array<ModelTypes["JSON"] | undefined> | undefined,
	all?: Array<ModelTypes["JSON"] | undefined> | undefined,
	exists?: boolean | undefined
};
	["Event_ticketing__promotionType_operator"]: {
	equals?: ModelTypes["Event_ticketing__promotionType_Input"] | undefined,
	not_equals?: ModelTypes["Event_ticketing__promotionType_Input"] | undefined,
	in?: Array<ModelTypes["Event_ticketing__promotionType_Input"] | undefined> | undefined,
	not_in?: Array<ModelTypes["Event_ticketing__promotionType_Input"] | undefined> | undefined,
	all?: Array<ModelTypes["Event_ticketing__promotionType_Input"] | undefined> | undefined,
	exists?: boolean | undefined
};
	["Event_ticketing__promotionType_Input"]:Event_ticketing__promotionType_Input;
	["Event_ticketing__location_operator"]: {
	equals?: ModelTypes["JSON"] | undefined,
	not_equals?: ModelTypes["JSON"] | undefined,
	in?: Array<ModelTypes["JSON"] | undefined> | undefined,
	not_in?: Array<ModelTypes["JSON"] | undefined> | undefined,
	all?: Array<ModelTypes["JSON"] | undefined> | undefined
};
	["Event_ticketing__website_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Event_ticketing__description_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	exists?: boolean | undefined
};
	["Event_ticketing__id_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Event_legacyContent_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	exists?: boolean | undefined
};
	["Event_showLegacyContent_operator"]: {
	equals?: boolean | undefined,
	not_equals?: boolean | undefined,
	exists?: boolean | undefined
};
	["Event_criteria__categories_operator"]: {
	equals?: ModelTypes["JSON"] | undefined,
	not_equals?: ModelTypes["JSON"] | undefined,
	in?: Array<ModelTypes["JSON"] | undefined> | undefined,
	not_in?: Array<ModelTypes["JSON"] | undefined> | undefined,
	all?: Array<ModelTypes["JSON"] | undefined> | undefined
};
	["Event_criteria__audiences_operator"]: {
	equals?: ModelTypes["JSON"] | undefined,
	not_equals?: ModelTypes["JSON"] | undefined,
	in?: Array<ModelTypes["JSON"] | undefined> | undefined,
	not_in?: Array<ModelTypes["JSON"] | undefined> | undefined,
	all?: Array<ModelTypes["JSON"] | undefined> | undefined,
	exists?: boolean | undefined
};
	["Event_criteria__tags_operator"]: {
	equals?: ModelTypes["JSON"] | undefined,
	not_equals?: ModelTypes["JSON"] | undefined,
	in?: Array<ModelTypes["JSON"] | undefined> | undefined,
	not_in?: Array<ModelTypes["JSON"] | undefined> | undefined,
	all?: Array<ModelTypes["JSON"] | undefined> | undefined,
	exists?: boolean | undefined
};
	["Event_criteria__specialTags_operator"]: {
	equals?: ModelTypes["JSON"] | undefined,
	not_equals?: ModelTypes["JSON"] | undefined,
	in?: Array<ModelTypes["JSON"] | undefined> | undefined,
	not_in?: Array<ModelTypes["JSON"] | undefined> | undefined,
	all?: Array<ModelTypes["JSON"] | undefined> | undefined,
	exists?: boolean | undefined
};
	["Event_walkIn_operator"]: {
	equals?: boolean | undefined,
	not_equals?: boolean | undefined,
	exists?: boolean | undefined
};
	["Event_contact__code_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Event_contact__phone_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Event_contact__page_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Event_contact__email_operator"]: {
	equals?: ModelTypes["EmailAddress"] | undefined,
	not_equals?: ModelTypes["EmailAddress"] | undefined,
	like?: ModelTypes["EmailAddress"] | undefined,
	contains?: ModelTypes["EmailAddress"] | undefined,
	in?: Array<ModelTypes["EmailAddress"] | undefined> | undefined,
	not_in?: Array<ModelTypes["EmailAddress"] | undefined> | undefined,
	all?: Array<ModelTypes["EmailAddress"] | undefined> | undefined,
	exists?: boolean | undefined
};
	["Event_contact__privacy_operator"]: {
	equals?: ModelTypes["JSON"] | undefined,
	not_equals?: ModelTypes["JSON"] | undefined,
	like?: ModelTypes["JSON"] | undefined,
	contains?: ModelTypes["JSON"] | undefined,
	exists?: boolean | undefined
};
	["Event_views_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Event_free_operator"]: {
	equals?: boolean | undefined,
	not_equals?: boolean | undefined,
	exists?: boolean | undefined
};
	["Event_fees__fee_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Event_fees__remark_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Event_fees__id_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Event_homePageInternalClick_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Event_homePageImpression_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Event_eodClickRate_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Event_eodImpressionRate_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Event_top10ClickRateHomepage_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Event_top10ImpressionkRateHomepage_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Event_otherEventRankingClickRateHomepage_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Event_otherEventRankingImpressionHomePage_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Event_userPreferenceClickRate_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Event_userPreferenceImpression_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Event_eventSearchInternalClick_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Event_eventListImpression_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Event_latestEventInternalClick_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Event_latestEventImpression_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Event_topTenEventRankingInternalClick_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Event_topTenImpression_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Event_editorChoiceInternalClick_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Event_editorChoiceImpression_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Event_otherEventRankingInternalClick_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Event_otherEventRankingImpression_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Event_bannerInternalClick_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Event_miscImpression_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Event_miscInternalClick_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Event_totalImpression_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Event_internalClick_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Event_outboundClick_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Event_externalClick_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Event__viewsCountByMonth_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Event_products__startDateTime_operator"]: {
	equals?: ModelTypes["DateTime"] | undefined,
	not_equals?: ModelTypes["DateTime"] | undefined,
	greater_than_equal?: ModelTypes["DateTime"] | undefined,
	greater_than?: ModelTypes["DateTime"] | undefined,
	less_than_equal?: ModelTypes["DateTime"] | undefined,
	less_than?: ModelTypes["DateTime"] | undefined,
	like?: ModelTypes["DateTime"] | undefined
};
	["Event_products__endDateTime_operator"]: {
	equals?: ModelTypes["DateTime"] | undefined,
	not_equals?: ModelTypes["DateTime"] | undefined,
	greater_than_equal?: ModelTypes["DateTime"] | undefined,
	greater_than?: ModelTypes["DateTime"] | undefined,
	less_than_equal?: ModelTypes["DateTime"] | undefined,
	less_than?: ModelTypes["DateTime"] | undefined,
	like?: ModelTypes["DateTime"] | undefined
};
	["Event_products__fullDay_operator"]: {
	equals?: boolean | undefined,
	not_equals?: boolean | undefined,
	exists?: boolean | undefined
};
	["Event_products__linkedProduct_operator"]: {
	equals?: ModelTypes["JSON"] | undefined,
	not_equals?: ModelTypes["JSON"] | undefined,
	in?: Array<ModelTypes["JSON"] | undefined> | undefined,
	not_in?: Array<ModelTypes["JSON"] | undefined> | undefined,
	all?: Array<ModelTypes["JSON"] | undefined> | undefined
};
	["Event_products__id_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Event_legacyGallery_operator"]: {
	equals?: ModelTypes["JSON"] | undefined,
	not_equals?: ModelTypes["JSON"] | undefined,
	in?: Array<ModelTypes["JSON"] | undefined> | undefined,
	not_in?: Array<ModelTypes["JSON"] | undefined> | undefined,
	all?: Array<ModelTypes["JSON"] | undefined> | undefined,
	exists?: boolean | undefined
};
	["Event_earliestStartDate_operator"]: {
	equals?: ModelTypes["DateTime"] | undefined,
	not_equals?: ModelTypes["DateTime"] | undefined,
	greater_than_equal?: ModelTypes["DateTime"] | undefined,
	greater_than?: ModelTypes["DateTime"] | undefined,
	less_than_equal?: ModelTypes["DateTime"] | undefined,
	less_than?: ModelTypes["DateTime"] | undefined,
	like?: ModelTypes["DateTime"] | undefined,
	exists?: boolean | undefined
};
	["Event_latestEndDate_operator"]: {
	equals?: ModelTypes["DateTime"] | undefined,
	not_equals?: ModelTypes["DateTime"] | undefined,
	greater_than_equal?: ModelTypes["DateTime"] | undefined,
	greater_than?: ModelTypes["DateTime"] | undefined,
	less_than_equal?: ModelTypes["DateTime"] | undefined,
	less_than?: ModelTypes["DateTime"] | undefined,
	like?: ModelTypes["DateTime"] | undefined,
	exists?: boolean | undefined
};
	["Event_latestStartDateTime_operator"]: {
	equals?: ModelTypes["DateTime"] | undefined,
	not_equals?: ModelTypes["DateTime"] | undefined,
	greater_than_equal?: ModelTypes["DateTime"] | undefined,
	greater_than?: ModelTypes["DateTime"] | undefined,
	less_than_equal?: ModelTypes["DateTime"] | undefined,
	less_than?: ModelTypes["DateTime"] | undefined,
	like?: ModelTypes["DateTime"] | undefined,
	exists?: boolean | undefined
};
	["Event_hasTicketing_operator"]: {
	equals?: boolean | undefined,
	not_equals?: boolean | undefined,
	exists?: boolean | undefined
};
	["Event_hasProducts_operator"]: {
	equals?: boolean | undefined,
	not_equals?: boolean | undefined,
	exists?: boolean | undefined
};
	["Event_updatedAt_operator"]: {
	equals?: ModelTypes["DateTime"] | undefined,
	not_equals?: ModelTypes["DateTime"] | undefined,
	greater_than_equal?: ModelTypes["DateTime"] | undefined,
	greater_than?: ModelTypes["DateTime"] | undefined,
	less_than_equal?: ModelTypes["DateTime"] | undefined,
	less_than?: ModelTypes["DateTime"] | undefined,
	like?: ModelTypes["DateTime"] | undefined,
	exists?: boolean | undefined
};
	["Event_createdAt_operator"]: {
	equals?: ModelTypes["DateTime"] | undefined,
	not_equals?: ModelTypes["DateTime"] | undefined,
	greater_than_equal?: ModelTypes["DateTime"] | undefined,
	greater_than?: ModelTypes["DateTime"] | undefined,
	less_than_equal?: ModelTypes["DateTime"] | undefined,
	less_than?: ModelTypes["DateTime"] | undefined,
	like?: ModelTypes["DateTime"] | undefined,
	exists?: boolean | undefined
};
	["Event_id_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Event_where_and"]: {
	region?: ModelTypes["Event_region_operator"] | undefined,
	creator?: ModelTypes["Event_creator_operator"] | undefined,
	permalink?: ModelTypes["Event_permalink_operator"] | undefined,
	status?: ModelTypes["Event_status_operator"] | undefined,
	level?: ModelTypes["Event_level_operator"] | undefined,
	parent?: ModelTypes["Event_parent_operator"] | undefined,
	name?: ModelTypes["Event_name_operator"] | undefined,
	chatbotEventTitle?: ModelTypes["Event_chatbotEventTitle_operator"] | undefined,
	chatbotSequence?: ModelTypes["Event_chatbotSequence_operator"] | undefined,
	thumbnail?: ModelTypes["Event_thumbnail_operator"] | undefined,
	banner?: ModelTypes["Event_banner_operator"] | undefined,
	mobileBanner?: ModelTypes["Event_mobileBanner_operator"] | undefined,
	sections__startDatetime?: ModelTypes["Event_sections__startDatetime_operator"] | undefined,
	sections__endDatetime?: ModelTypes["Event_sections__endDatetime_operator"] | undefined,
	sections__fullDay?: ModelTypes["Event_sections__fullDay_operator"] | undefined,
	sections__repeat?: ModelTypes["Event_sections__repeat_operator"] | undefined,
	sections__toThisDay?: ModelTypes["Event_sections__toThisDay_operator"] | undefined,
	sections__recurrance__type?: ModelTypes["Event_sections__recurrance__type_operator"] | undefined,
	sections__recurrance__weekday?: ModelTypes["Event_sections__recurrance__weekday_operator"] | undefined,
	sections__sameLocation?: ModelTypes["Event_sections__sameLocation_operator"] | undefined,
	sections__location?: ModelTypes["Event_sections__location_operator"] | undefined,
	sections__district?: ModelTypes["Event_sections__district_operator"] | undefined,
	sections__coordinate?: ModelTypes["Event_sections__coordinate_operator"] | undefined,
	sections__linkAddress?: ModelTypes["Event_sections__linkAddress_operator"] | undefined,
	sections__address?: ModelTypes["Event_sections__address_operator"] | undefined,
	sections__addressCoordinate?: ModelTypes["Event_sections__addressCoordinate_operator"] | undefined,
	sections__id?: ModelTypes["Event_sections__id_operator"] | undefined,
	ticketing__startDatetime?: ModelTypes["Event_ticketing__startDatetime_operator"] | undefined,
	ticketing__endDatetime?: ModelTypes["Event_ticketing__endDatetime_operator"] | undefined,
	ticketing__fullDay?: ModelTypes["Event_ticketing__fullDay_operator"] | undefined,
	ticketing__type?: ModelTypes["Event_ticketing__type_operator"] | undefined,
	ticketing__ticketingType?: ModelTypes["Event_ticketing__ticketingType_operator"] | undefined,
	ticketing__linkedTicketing?: ModelTypes["Event_ticketing__linkedTicketing_operator"] | undefined,
	ticketing__promotionType?: ModelTypes["Event_ticketing__promotionType_operator"] | undefined,
	ticketing__location?: ModelTypes["Event_ticketing__location_operator"] | undefined,
	ticketing__website?: ModelTypes["Event_ticketing__website_operator"] | undefined,
	ticketing__description?: ModelTypes["Event_ticketing__description_operator"] | undefined,
	ticketing__id?: ModelTypes["Event_ticketing__id_operator"] | undefined,
	legacyContent?: ModelTypes["Event_legacyContent_operator"] | undefined,
	showLegacyContent?: ModelTypes["Event_showLegacyContent_operator"] | undefined,
	criteria__categories?: ModelTypes["Event_criteria__categories_operator"] | undefined,
	criteria__audiences?: ModelTypes["Event_criteria__audiences_operator"] | undefined,
	criteria__tags?: ModelTypes["Event_criteria__tags_operator"] | undefined,
	criteria__specialTags?: ModelTypes["Event_criteria__specialTags_operator"] | undefined,
	walkIn?: ModelTypes["Event_walkIn_operator"] | undefined,
	contact__code?: ModelTypes["Event_contact__code_operator"] | undefined,
	contact__phone?: ModelTypes["Event_contact__phone_operator"] | undefined,
	contact__page?: ModelTypes["Event_contact__page_operator"] | undefined,
	contact__email?: ModelTypes["Event_contact__email_operator"] | undefined,
	contact__privacy?: ModelTypes["Event_contact__privacy_operator"] | undefined,
	views?: ModelTypes["Event_views_operator"] | undefined,
	free?: ModelTypes["Event_free_operator"] | undefined,
	fees__fee?: ModelTypes["Event_fees__fee_operator"] | undefined,
	fees__remark?: ModelTypes["Event_fees__remark_operator"] | undefined,
	fees__id?: ModelTypes["Event_fees__id_operator"] | undefined,
	homePageInternalClick?: ModelTypes["Event_homePageInternalClick_operator"] | undefined,
	homePageImpression?: ModelTypes["Event_homePageImpression_operator"] | undefined,
	eodClickRate?: ModelTypes["Event_eodClickRate_operator"] | undefined,
	eodImpressionRate?: ModelTypes["Event_eodImpressionRate_operator"] | undefined,
	top10ClickRateHomepage?: ModelTypes["Event_top10ClickRateHomepage_operator"] | undefined,
	top10ImpressionkRateHomepage?: ModelTypes["Event_top10ImpressionkRateHomepage_operator"] | undefined,
	otherEventRankingClickRateHomepage?: ModelTypes["Event_otherEventRankingClickRateHomepage_operator"] | undefined,
	otherEventRankingImpressionHomePage?: ModelTypes["Event_otherEventRankingImpressionHomePage_operator"] | undefined,
	userPreferenceClickRate?: ModelTypes["Event_userPreferenceClickRate_operator"] | undefined,
	userPreferenceImpression?: ModelTypes["Event_userPreferenceImpression_operator"] | undefined,
	eventSearchInternalClick?: ModelTypes["Event_eventSearchInternalClick_operator"] | undefined,
	eventListImpression?: ModelTypes["Event_eventListImpression_operator"] | undefined,
	latestEventInternalClick?: ModelTypes["Event_latestEventInternalClick_operator"] | undefined,
	latestEventImpression?: ModelTypes["Event_latestEventImpression_operator"] | undefined,
	topTenEventRankingInternalClick?: ModelTypes["Event_topTenEventRankingInternalClick_operator"] | undefined,
	topTenImpression?: ModelTypes["Event_topTenImpression_operator"] | undefined,
	editorChoiceInternalClick?: ModelTypes["Event_editorChoiceInternalClick_operator"] | undefined,
	editorChoiceImpression?: ModelTypes["Event_editorChoiceImpression_operator"] | undefined,
	otherEventRankingInternalClick?: ModelTypes["Event_otherEventRankingInternalClick_operator"] | undefined,
	otherEventRankingImpression?: ModelTypes["Event_otherEventRankingImpression_operator"] | undefined,
	bannerInternalClick?: ModelTypes["Event_bannerInternalClick_operator"] | undefined,
	miscImpression?: ModelTypes["Event_miscImpression_operator"] | undefined,
	miscInternalClick?: ModelTypes["Event_miscInternalClick_operator"] | undefined,
	totalImpression?: ModelTypes["Event_totalImpression_operator"] | undefined,
	internalClick?: ModelTypes["Event_internalClick_operator"] | undefined,
	outboundClick?: ModelTypes["Event_outboundClick_operator"] | undefined,
	externalClick?: ModelTypes["Event_externalClick_operator"] | undefined,
	_viewsCountByMonth?: ModelTypes["Event__viewsCountByMonth_operator"] | undefined,
	products__startDateTime?: ModelTypes["Event_products__startDateTime_operator"] | undefined,
	products__endDateTime?: ModelTypes["Event_products__endDateTime_operator"] | undefined,
	products__fullDay?: ModelTypes["Event_products__fullDay_operator"] | undefined,
	products__linkedProduct?: ModelTypes["Event_products__linkedProduct_operator"] | undefined,
	products__id?: ModelTypes["Event_products__id_operator"] | undefined,
	legacyGallery?: ModelTypes["Event_legacyGallery_operator"] | undefined,
	earliestStartDate?: ModelTypes["Event_earliestStartDate_operator"] | undefined,
	latestEndDate?: ModelTypes["Event_latestEndDate_operator"] | undefined,
	latestStartDateTime?: ModelTypes["Event_latestStartDateTime_operator"] | undefined,
	hasTicketing?: ModelTypes["Event_hasTicketing_operator"] | undefined,
	hasProducts?: ModelTypes["Event_hasProducts_operator"] | undefined,
	updatedAt?: ModelTypes["Event_updatedAt_operator"] | undefined,
	createdAt?: ModelTypes["Event_createdAt_operator"] | undefined,
	id?: ModelTypes["Event_id_operator"] | undefined,
	AND?: Array<ModelTypes["Event_where_and"] | undefined> | undefined,
	OR?: Array<ModelTypes["Event_where_or"] | undefined> | undefined
};
	["Event_where_or"]: {
	region?: ModelTypes["Event_region_operator"] | undefined,
	creator?: ModelTypes["Event_creator_operator"] | undefined,
	permalink?: ModelTypes["Event_permalink_operator"] | undefined,
	status?: ModelTypes["Event_status_operator"] | undefined,
	level?: ModelTypes["Event_level_operator"] | undefined,
	parent?: ModelTypes["Event_parent_operator"] | undefined,
	name?: ModelTypes["Event_name_operator"] | undefined,
	chatbotEventTitle?: ModelTypes["Event_chatbotEventTitle_operator"] | undefined,
	chatbotSequence?: ModelTypes["Event_chatbotSequence_operator"] | undefined,
	thumbnail?: ModelTypes["Event_thumbnail_operator"] | undefined,
	banner?: ModelTypes["Event_banner_operator"] | undefined,
	mobileBanner?: ModelTypes["Event_mobileBanner_operator"] | undefined,
	sections__startDatetime?: ModelTypes["Event_sections__startDatetime_operator"] | undefined,
	sections__endDatetime?: ModelTypes["Event_sections__endDatetime_operator"] | undefined,
	sections__fullDay?: ModelTypes["Event_sections__fullDay_operator"] | undefined,
	sections__repeat?: ModelTypes["Event_sections__repeat_operator"] | undefined,
	sections__toThisDay?: ModelTypes["Event_sections__toThisDay_operator"] | undefined,
	sections__recurrance__type?: ModelTypes["Event_sections__recurrance__type_operator"] | undefined,
	sections__recurrance__weekday?: ModelTypes["Event_sections__recurrance__weekday_operator"] | undefined,
	sections__sameLocation?: ModelTypes["Event_sections__sameLocation_operator"] | undefined,
	sections__location?: ModelTypes["Event_sections__location_operator"] | undefined,
	sections__district?: ModelTypes["Event_sections__district_operator"] | undefined,
	sections__coordinate?: ModelTypes["Event_sections__coordinate_operator"] | undefined,
	sections__linkAddress?: ModelTypes["Event_sections__linkAddress_operator"] | undefined,
	sections__address?: ModelTypes["Event_sections__address_operator"] | undefined,
	sections__addressCoordinate?: ModelTypes["Event_sections__addressCoordinate_operator"] | undefined,
	sections__id?: ModelTypes["Event_sections__id_operator"] | undefined,
	ticketing__startDatetime?: ModelTypes["Event_ticketing__startDatetime_operator"] | undefined,
	ticketing__endDatetime?: ModelTypes["Event_ticketing__endDatetime_operator"] | undefined,
	ticketing__fullDay?: ModelTypes["Event_ticketing__fullDay_operator"] | undefined,
	ticketing__type?: ModelTypes["Event_ticketing__type_operator"] | undefined,
	ticketing__ticketingType?: ModelTypes["Event_ticketing__ticketingType_operator"] | undefined,
	ticketing__linkedTicketing?: ModelTypes["Event_ticketing__linkedTicketing_operator"] | undefined,
	ticketing__promotionType?: ModelTypes["Event_ticketing__promotionType_operator"] | undefined,
	ticketing__location?: ModelTypes["Event_ticketing__location_operator"] | undefined,
	ticketing__website?: ModelTypes["Event_ticketing__website_operator"] | undefined,
	ticketing__description?: ModelTypes["Event_ticketing__description_operator"] | undefined,
	ticketing__id?: ModelTypes["Event_ticketing__id_operator"] | undefined,
	legacyContent?: ModelTypes["Event_legacyContent_operator"] | undefined,
	showLegacyContent?: ModelTypes["Event_showLegacyContent_operator"] | undefined,
	criteria__categories?: ModelTypes["Event_criteria__categories_operator"] | undefined,
	criteria__audiences?: ModelTypes["Event_criteria__audiences_operator"] | undefined,
	criteria__tags?: ModelTypes["Event_criteria__tags_operator"] | undefined,
	criteria__specialTags?: ModelTypes["Event_criteria__specialTags_operator"] | undefined,
	walkIn?: ModelTypes["Event_walkIn_operator"] | undefined,
	contact__code?: ModelTypes["Event_contact__code_operator"] | undefined,
	contact__phone?: ModelTypes["Event_contact__phone_operator"] | undefined,
	contact__page?: ModelTypes["Event_contact__page_operator"] | undefined,
	contact__email?: ModelTypes["Event_contact__email_operator"] | undefined,
	contact__privacy?: ModelTypes["Event_contact__privacy_operator"] | undefined,
	views?: ModelTypes["Event_views_operator"] | undefined,
	free?: ModelTypes["Event_free_operator"] | undefined,
	fees__fee?: ModelTypes["Event_fees__fee_operator"] | undefined,
	fees__remark?: ModelTypes["Event_fees__remark_operator"] | undefined,
	fees__id?: ModelTypes["Event_fees__id_operator"] | undefined,
	homePageInternalClick?: ModelTypes["Event_homePageInternalClick_operator"] | undefined,
	homePageImpression?: ModelTypes["Event_homePageImpression_operator"] | undefined,
	eodClickRate?: ModelTypes["Event_eodClickRate_operator"] | undefined,
	eodImpressionRate?: ModelTypes["Event_eodImpressionRate_operator"] | undefined,
	top10ClickRateHomepage?: ModelTypes["Event_top10ClickRateHomepage_operator"] | undefined,
	top10ImpressionkRateHomepage?: ModelTypes["Event_top10ImpressionkRateHomepage_operator"] | undefined,
	otherEventRankingClickRateHomepage?: ModelTypes["Event_otherEventRankingClickRateHomepage_operator"] | undefined,
	otherEventRankingImpressionHomePage?: ModelTypes["Event_otherEventRankingImpressionHomePage_operator"] | undefined,
	userPreferenceClickRate?: ModelTypes["Event_userPreferenceClickRate_operator"] | undefined,
	userPreferenceImpression?: ModelTypes["Event_userPreferenceImpression_operator"] | undefined,
	eventSearchInternalClick?: ModelTypes["Event_eventSearchInternalClick_operator"] | undefined,
	eventListImpression?: ModelTypes["Event_eventListImpression_operator"] | undefined,
	latestEventInternalClick?: ModelTypes["Event_latestEventInternalClick_operator"] | undefined,
	latestEventImpression?: ModelTypes["Event_latestEventImpression_operator"] | undefined,
	topTenEventRankingInternalClick?: ModelTypes["Event_topTenEventRankingInternalClick_operator"] | undefined,
	topTenImpression?: ModelTypes["Event_topTenImpression_operator"] | undefined,
	editorChoiceInternalClick?: ModelTypes["Event_editorChoiceInternalClick_operator"] | undefined,
	editorChoiceImpression?: ModelTypes["Event_editorChoiceImpression_operator"] | undefined,
	otherEventRankingInternalClick?: ModelTypes["Event_otherEventRankingInternalClick_operator"] | undefined,
	otherEventRankingImpression?: ModelTypes["Event_otherEventRankingImpression_operator"] | undefined,
	bannerInternalClick?: ModelTypes["Event_bannerInternalClick_operator"] | undefined,
	miscImpression?: ModelTypes["Event_miscImpression_operator"] | undefined,
	miscInternalClick?: ModelTypes["Event_miscInternalClick_operator"] | undefined,
	totalImpression?: ModelTypes["Event_totalImpression_operator"] | undefined,
	internalClick?: ModelTypes["Event_internalClick_operator"] | undefined,
	outboundClick?: ModelTypes["Event_outboundClick_operator"] | undefined,
	externalClick?: ModelTypes["Event_externalClick_operator"] | undefined,
	_viewsCountByMonth?: ModelTypes["Event__viewsCountByMonth_operator"] | undefined,
	products__startDateTime?: ModelTypes["Event_products__startDateTime_operator"] | undefined,
	products__endDateTime?: ModelTypes["Event_products__endDateTime_operator"] | undefined,
	products__fullDay?: ModelTypes["Event_products__fullDay_operator"] | undefined,
	products__linkedProduct?: ModelTypes["Event_products__linkedProduct_operator"] | undefined,
	products__id?: ModelTypes["Event_products__id_operator"] | undefined,
	legacyGallery?: ModelTypes["Event_legacyGallery_operator"] | undefined,
	earliestStartDate?: ModelTypes["Event_earliestStartDate_operator"] | undefined,
	latestEndDate?: ModelTypes["Event_latestEndDate_operator"] | undefined,
	latestStartDateTime?: ModelTypes["Event_latestStartDateTime_operator"] | undefined,
	hasTicketing?: ModelTypes["Event_hasTicketing_operator"] | undefined,
	hasProducts?: ModelTypes["Event_hasProducts_operator"] | undefined,
	updatedAt?: ModelTypes["Event_updatedAt_operator"] | undefined,
	createdAt?: ModelTypes["Event_createdAt_operator"] | undefined,
	id?: ModelTypes["Event_id_operator"] | undefined,
	AND?: Array<ModelTypes["Event_where_and"] | undefined> | undefined,
	OR?: Array<ModelTypes["Event_where_or"] | undefined> | undefined
};
	["countEvents"]: {
		totalDocs?: number | undefined
};
	["eventsDocAccess"]: {
		fields?: ModelTypes["EventsDocAccessFields"] | undefined,
	create?: ModelTypes["EventsCreateDocAccess"] | undefined,
	read?: ModelTypes["EventsReadDocAccess"] | undefined,
	update?: ModelTypes["EventsUpdateDocAccess"] | undefined,
	delete?: ModelTypes["EventsDeleteDocAccess"] | undefined
};
	["EventsDocAccessFields"]: {
		region?: ModelTypes["EventsDocAccessFields_region"] | undefined,
	creator?: ModelTypes["EventsDocAccessFields_creator"] | undefined,
	permalink?: ModelTypes["EventsDocAccessFields_permalink"] | undefined,
	status?: ModelTypes["EventsDocAccessFields_status"] | undefined,
	level?: ModelTypes["EventsDocAccessFields_level"] | undefined,
	parent?: ModelTypes["EventsDocAccessFields_parent"] | undefined,
	name?: ModelTypes["EventsDocAccessFields_name"] | undefined,
	chatbotEventTitle?: ModelTypes["EventsDocAccessFields_chatbotEventTitle"] | undefined,
	chatbotSequence?: ModelTypes["EventsDocAccessFields_chatbotSequence"] | undefined,
	thumbnail?: ModelTypes["EventsDocAccessFields_thumbnail"] | undefined,
	banner?: ModelTypes["EventsDocAccessFields_banner"] | undefined,
	mobileBanner?: ModelTypes["EventsDocAccessFields_mobileBanner"] | undefined,
	sections?: ModelTypes["EventsDocAccessFields_sections"] | undefined,
	ticketing?: ModelTypes["EventsDocAccessFields_ticketing"] | undefined,
	content?: ModelTypes["EventsDocAccessFields_content"] | undefined,
	legacyContent?: ModelTypes["EventsDocAccessFields_legacyContent"] | undefined,
	showLegacyContent?: ModelTypes["EventsDocAccessFields_showLegacyContent"] | undefined,
	criteria?: ModelTypes["EventsDocAccessFields_criteria"] | undefined,
	walkIn?: ModelTypes["EventsDocAccessFields_walkIn"] | undefined,
	contact?: ModelTypes["EventsDocAccessFields_contact"] | undefined,
	views?: ModelTypes["EventsDocAccessFields_views"] | undefined,
	free?: ModelTypes["EventsDocAccessFields_free"] | undefined,
	fees?: ModelTypes["EventsDocAccessFields_fees"] | undefined,
	homePageInternalClick?: ModelTypes["EventsDocAccessFields_homePageInternalClick"] | undefined,
	homePageImpression?: ModelTypes["EventsDocAccessFields_homePageImpression"] | undefined,
	eodClickRate?: ModelTypes["EventsDocAccessFields_eodClickRate"] | undefined,
	eodImpressionRate?: ModelTypes["EventsDocAccessFields_eodImpressionRate"] | undefined,
	top10ClickRateHomepage?: ModelTypes["EventsDocAccessFields_top10ClickRateHomepage"] | undefined,
	top10ImpressionkRateHomepage?: ModelTypes["EventsDocAccessFields_top10ImpressionkRateHomepage"] | undefined,
	otherEventRankingClickRateHomepage?: ModelTypes["EventsDocAccessFields_otherEventRankingClickRateHomepage"] | undefined,
	otherEventRankingImpressionHomePage?: ModelTypes["EventsDocAccessFields_otherEventRankingImpressionHomePage"] | undefined,
	userPreferenceClickRate?: ModelTypes["EventsDocAccessFields_userPreferenceClickRate"] | undefined,
	userPreferenceImpression?: ModelTypes["EventsDocAccessFields_userPreferenceImpression"] | undefined,
	eventSearchInternalClick?: ModelTypes["EventsDocAccessFields_eventSearchInternalClick"] | undefined,
	eventListImpression?: ModelTypes["EventsDocAccessFields_eventListImpression"] | undefined,
	latestEventInternalClick?: ModelTypes["EventsDocAccessFields_latestEventInternalClick"] | undefined,
	latestEventImpression?: ModelTypes["EventsDocAccessFields_latestEventImpression"] | undefined,
	topTenEventRankingInternalClick?: ModelTypes["EventsDocAccessFields_topTenEventRankingInternalClick"] | undefined,
	topTenImpression?: ModelTypes["EventsDocAccessFields_topTenImpression"] | undefined,
	editorChoiceInternalClick?: ModelTypes["EventsDocAccessFields_editorChoiceInternalClick"] | undefined,
	editorChoiceImpression?: ModelTypes["EventsDocAccessFields_editorChoiceImpression"] | undefined,
	otherEventRankingInternalClick?: ModelTypes["EventsDocAccessFields_otherEventRankingInternalClick"] | undefined,
	otherEventRankingImpression?: ModelTypes["EventsDocAccessFields_otherEventRankingImpression"] | undefined,
	bannerInternalClick?: ModelTypes["EventsDocAccessFields_bannerInternalClick"] | undefined,
	miscImpression?: ModelTypes["EventsDocAccessFields_miscImpression"] | undefined,
	miscInternalClick?: ModelTypes["EventsDocAccessFields_miscInternalClick"] | undefined,
	totalImpression?: ModelTypes["EventsDocAccessFields_totalImpression"] | undefined,
	internalClick?: ModelTypes["EventsDocAccessFields_internalClick"] | undefined,
	outboundClick?: ModelTypes["EventsDocAccessFields_outboundClick"] | undefined,
	externalClick?: ModelTypes["EventsDocAccessFields_externalClick"] | undefined,
	_viewsCountByMonth?: ModelTypes["EventsDocAccessFields__viewsCountByMonth"] | undefined,
	products?: ModelTypes["EventsDocAccessFields_products"] | undefined,
	legacyGallery?: ModelTypes["EventsDocAccessFields_legacyGallery"] | undefined,
	earliestStartDate?: ModelTypes["EventsDocAccessFields_earliestStartDate"] | undefined,
	latestEndDate?: ModelTypes["EventsDocAccessFields_latestEndDate"] | undefined,
	latestStartDateTime?: ModelTypes["EventsDocAccessFields_latestStartDateTime"] | undefined,
	hasTicketing?: ModelTypes["EventsDocAccessFields_hasTicketing"] | undefined,
	hasProducts?: ModelTypes["EventsDocAccessFields_hasProducts"] | undefined,
	updatedAt?: ModelTypes["EventsDocAccessFields_updatedAt"] | undefined,
	createdAt?: ModelTypes["EventsDocAccessFields_createdAt"] | undefined
};
	["EventsDocAccessFields_region"]: {
		create?: ModelTypes["EventsDocAccessFields_region_Create"] | undefined,
	read?: ModelTypes["EventsDocAccessFields_region_Read"] | undefined,
	update?: ModelTypes["EventsDocAccessFields_region_Update"] | undefined,
	delete?: ModelTypes["EventsDocAccessFields_region_Delete"] | undefined
};
	["EventsDocAccessFields_region_Create"]: {
		permission: boolean
};
	["EventsDocAccessFields_region_Read"]: {
		permission: boolean
};
	["EventsDocAccessFields_region_Update"]: {
		permission: boolean
};
	["EventsDocAccessFields_region_Delete"]: {
		permission: boolean
};
	["EventsDocAccessFields_creator"]: {
		create?: ModelTypes["EventsDocAccessFields_creator_Create"] | undefined,
	read?: ModelTypes["EventsDocAccessFields_creator_Read"] | undefined,
	update?: ModelTypes["EventsDocAccessFields_creator_Update"] | undefined,
	delete?: ModelTypes["EventsDocAccessFields_creator_Delete"] | undefined
};
	["EventsDocAccessFields_creator_Create"]: {
		permission: boolean
};
	["EventsDocAccessFields_creator_Read"]: {
		permission: boolean
};
	["EventsDocAccessFields_creator_Update"]: {
		permission: boolean
};
	["EventsDocAccessFields_creator_Delete"]: {
		permission: boolean
};
	["EventsDocAccessFields_permalink"]: {
		create?: ModelTypes["EventsDocAccessFields_permalink_Create"] | undefined,
	read?: ModelTypes["EventsDocAccessFields_permalink_Read"] | undefined,
	update?: ModelTypes["EventsDocAccessFields_permalink_Update"] | undefined,
	delete?: ModelTypes["EventsDocAccessFields_permalink_Delete"] | undefined
};
	["EventsDocAccessFields_permalink_Create"]: {
		permission: boolean
};
	["EventsDocAccessFields_permalink_Read"]: {
		permission: boolean
};
	["EventsDocAccessFields_permalink_Update"]: {
		permission: boolean
};
	["EventsDocAccessFields_permalink_Delete"]: {
		permission: boolean
};
	["EventsDocAccessFields_status"]: {
		create?: ModelTypes["EventsDocAccessFields_status_Create"] | undefined,
	read?: ModelTypes["EventsDocAccessFields_status_Read"] | undefined,
	update?: ModelTypes["EventsDocAccessFields_status_Update"] | undefined,
	delete?: ModelTypes["EventsDocAccessFields_status_Delete"] | undefined
};
	["EventsDocAccessFields_status_Create"]: {
		permission: boolean
};
	["EventsDocAccessFields_status_Read"]: {
		permission: boolean
};
	["EventsDocAccessFields_status_Update"]: {
		permission: boolean
};
	["EventsDocAccessFields_status_Delete"]: {
		permission: boolean
};
	["EventsDocAccessFields_level"]: {
		create?: ModelTypes["EventsDocAccessFields_level_Create"] | undefined,
	read?: ModelTypes["EventsDocAccessFields_level_Read"] | undefined,
	update?: ModelTypes["EventsDocAccessFields_level_Update"] | undefined,
	delete?: ModelTypes["EventsDocAccessFields_level_Delete"] | undefined
};
	["EventsDocAccessFields_level_Create"]: {
		permission: boolean
};
	["EventsDocAccessFields_level_Read"]: {
		permission: boolean
};
	["EventsDocAccessFields_level_Update"]: {
		permission: boolean
};
	["EventsDocAccessFields_level_Delete"]: {
		permission: boolean
};
	["EventsDocAccessFields_parent"]: {
		create?: ModelTypes["EventsDocAccessFields_parent_Create"] | undefined,
	read?: ModelTypes["EventsDocAccessFields_parent_Read"] | undefined,
	update?: ModelTypes["EventsDocAccessFields_parent_Update"] | undefined,
	delete?: ModelTypes["EventsDocAccessFields_parent_Delete"] | undefined
};
	["EventsDocAccessFields_parent_Create"]: {
		permission: boolean
};
	["EventsDocAccessFields_parent_Read"]: {
		permission: boolean
};
	["EventsDocAccessFields_parent_Update"]: {
		permission: boolean
};
	["EventsDocAccessFields_parent_Delete"]: {
		permission: boolean
};
	["EventsDocAccessFields_name"]: {
		create?: ModelTypes["EventsDocAccessFields_name_Create"] | undefined,
	read?: ModelTypes["EventsDocAccessFields_name_Read"] | undefined,
	update?: ModelTypes["EventsDocAccessFields_name_Update"] | undefined,
	delete?: ModelTypes["EventsDocAccessFields_name_Delete"] | undefined
};
	["EventsDocAccessFields_name_Create"]: {
		permission: boolean
};
	["EventsDocAccessFields_name_Read"]: {
		permission: boolean
};
	["EventsDocAccessFields_name_Update"]: {
		permission: boolean
};
	["EventsDocAccessFields_name_Delete"]: {
		permission: boolean
};
	["EventsDocAccessFields_chatbotEventTitle"]: {
		create?: ModelTypes["EventsDocAccessFields_chatbotEventTitle_Create"] | undefined,
	read?: ModelTypes["EventsDocAccessFields_chatbotEventTitle_Read"] | undefined,
	update?: ModelTypes["EventsDocAccessFields_chatbotEventTitle_Update"] | undefined,
	delete?: ModelTypes["EventsDocAccessFields_chatbotEventTitle_Delete"] | undefined
};
	["EventsDocAccessFields_chatbotEventTitle_Create"]: {
		permission: boolean
};
	["EventsDocAccessFields_chatbotEventTitle_Read"]: {
		permission: boolean
};
	["EventsDocAccessFields_chatbotEventTitle_Update"]: {
		permission: boolean
};
	["EventsDocAccessFields_chatbotEventTitle_Delete"]: {
		permission: boolean
};
	["EventsDocAccessFields_chatbotSequence"]: {
		create?: ModelTypes["EventsDocAccessFields_chatbotSequence_Create"] | undefined,
	read?: ModelTypes["EventsDocAccessFields_chatbotSequence_Read"] | undefined,
	update?: ModelTypes["EventsDocAccessFields_chatbotSequence_Update"] | undefined,
	delete?: ModelTypes["EventsDocAccessFields_chatbotSequence_Delete"] | undefined
};
	["EventsDocAccessFields_chatbotSequence_Create"]: {
		permission: boolean
};
	["EventsDocAccessFields_chatbotSequence_Read"]: {
		permission: boolean
};
	["EventsDocAccessFields_chatbotSequence_Update"]: {
		permission: boolean
};
	["EventsDocAccessFields_chatbotSequence_Delete"]: {
		permission: boolean
};
	["EventsDocAccessFields_thumbnail"]: {
		create?: ModelTypes["EventsDocAccessFields_thumbnail_Create"] | undefined,
	read?: ModelTypes["EventsDocAccessFields_thumbnail_Read"] | undefined,
	update?: ModelTypes["EventsDocAccessFields_thumbnail_Update"] | undefined,
	delete?: ModelTypes["EventsDocAccessFields_thumbnail_Delete"] | undefined
};
	["EventsDocAccessFields_thumbnail_Create"]: {
		permission: boolean
};
	["EventsDocAccessFields_thumbnail_Read"]: {
		permission: boolean
};
	["EventsDocAccessFields_thumbnail_Update"]: {
		permission: boolean
};
	["EventsDocAccessFields_thumbnail_Delete"]: {
		permission: boolean
};
	["EventsDocAccessFields_banner"]: {
		create?: ModelTypes["EventsDocAccessFields_banner_Create"] | undefined,
	read?: ModelTypes["EventsDocAccessFields_banner_Read"] | undefined,
	update?: ModelTypes["EventsDocAccessFields_banner_Update"] | undefined,
	delete?: ModelTypes["EventsDocAccessFields_banner_Delete"] | undefined
};
	["EventsDocAccessFields_banner_Create"]: {
		permission: boolean
};
	["EventsDocAccessFields_banner_Read"]: {
		permission: boolean
};
	["EventsDocAccessFields_banner_Update"]: {
		permission: boolean
};
	["EventsDocAccessFields_banner_Delete"]: {
		permission: boolean
};
	["EventsDocAccessFields_mobileBanner"]: {
		create?: ModelTypes["EventsDocAccessFields_mobileBanner_Create"] | undefined,
	read?: ModelTypes["EventsDocAccessFields_mobileBanner_Read"] | undefined,
	update?: ModelTypes["EventsDocAccessFields_mobileBanner_Update"] | undefined,
	delete?: ModelTypes["EventsDocAccessFields_mobileBanner_Delete"] | undefined
};
	["EventsDocAccessFields_mobileBanner_Create"]: {
		permission: boolean
};
	["EventsDocAccessFields_mobileBanner_Read"]: {
		permission: boolean
};
	["EventsDocAccessFields_mobileBanner_Update"]: {
		permission: boolean
};
	["EventsDocAccessFields_mobileBanner_Delete"]: {
		permission: boolean
};
	["EventsDocAccessFields_sections"]: {
		create?: ModelTypes["EventsDocAccessFields_sections_Create"] | undefined,
	read?: ModelTypes["EventsDocAccessFields_sections_Read"] | undefined,
	update?: ModelTypes["EventsDocAccessFields_sections_Update"] | undefined,
	delete?: ModelTypes["EventsDocAccessFields_sections_Delete"] | undefined,
	fields?: ModelTypes["EventsDocAccessFields_sections_Fields"] | undefined
};
	["EventsDocAccessFields_sections_Create"]: {
		permission: boolean
};
	["EventsDocAccessFields_sections_Read"]: {
		permission: boolean
};
	["EventsDocAccessFields_sections_Update"]: {
		permission: boolean
};
	["EventsDocAccessFields_sections_Delete"]: {
		permission: boolean
};
	["EventsDocAccessFields_sections_Fields"]: {
		startDatetime?: ModelTypes["EventsDocAccessFields_sections_startDatetime"] | undefined,
	endDatetime?: ModelTypes["EventsDocAccessFields_sections_endDatetime"] | undefined,
	fullDay?: ModelTypes["EventsDocAccessFields_sections_fullDay"] | undefined,
	repeat?: ModelTypes["EventsDocAccessFields_sections_repeat"] | undefined,
	toThisDay?: ModelTypes["EventsDocAccessFields_sections_toThisDay"] | undefined,
	recurrance?: ModelTypes["EventsDocAccessFields_sections_recurrance"] | undefined,
	sameLocation?: ModelTypes["EventsDocAccessFields_sections_sameLocation"] | undefined,
	location?: ModelTypes["EventsDocAccessFields_sections_location"] | undefined,
	district?: ModelTypes["EventsDocAccessFields_sections_district"] | undefined,
	coordinate?: ModelTypes["EventsDocAccessFields_sections_coordinate"] | undefined,
	linkAddress?: ModelTypes["EventsDocAccessFields_sections_linkAddress"] | undefined,
	address?: ModelTypes["EventsDocAccessFields_sections_address"] | undefined,
	id?: ModelTypes["EventsDocAccessFields_sections_id"] | undefined
};
	["EventsDocAccessFields_sections_startDatetime"]: {
		create?: ModelTypes["EventsDocAccessFields_sections_startDatetime_Create"] | undefined,
	read?: ModelTypes["EventsDocAccessFields_sections_startDatetime_Read"] | undefined,
	update?: ModelTypes["EventsDocAccessFields_sections_startDatetime_Update"] | undefined,
	delete?: ModelTypes["EventsDocAccessFields_sections_startDatetime_Delete"] | undefined
};
	["EventsDocAccessFields_sections_startDatetime_Create"]: {
		permission: boolean
};
	["EventsDocAccessFields_sections_startDatetime_Read"]: {
		permission: boolean
};
	["EventsDocAccessFields_sections_startDatetime_Update"]: {
		permission: boolean
};
	["EventsDocAccessFields_sections_startDatetime_Delete"]: {
		permission: boolean
};
	["EventsDocAccessFields_sections_endDatetime"]: {
		create?: ModelTypes["EventsDocAccessFields_sections_endDatetime_Create"] | undefined,
	read?: ModelTypes["EventsDocAccessFields_sections_endDatetime_Read"] | undefined,
	update?: ModelTypes["EventsDocAccessFields_sections_endDatetime_Update"] | undefined,
	delete?: ModelTypes["EventsDocAccessFields_sections_endDatetime_Delete"] | undefined
};
	["EventsDocAccessFields_sections_endDatetime_Create"]: {
		permission: boolean
};
	["EventsDocAccessFields_sections_endDatetime_Read"]: {
		permission: boolean
};
	["EventsDocAccessFields_sections_endDatetime_Update"]: {
		permission: boolean
};
	["EventsDocAccessFields_sections_endDatetime_Delete"]: {
		permission: boolean
};
	["EventsDocAccessFields_sections_fullDay"]: {
		create?: ModelTypes["EventsDocAccessFields_sections_fullDay_Create"] | undefined,
	read?: ModelTypes["EventsDocAccessFields_sections_fullDay_Read"] | undefined,
	update?: ModelTypes["EventsDocAccessFields_sections_fullDay_Update"] | undefined,
	delete?: ModelTypes["EventsDocAccessFields_sections_fullDay_Delete"] | undefined
};
	["EventsDocAccessFields_sections_fullDay_Create"]: {
		permission: boolean
};
	["EventsDocAccessFields_sections_fullDay_Read"]: {
		permission: boolean
};
	["EventsDocAccessFields_sections_fullDay_Update"]: {
		permission: boolean
};
	["EventsDocAccessFields_sections_fullDay_Delete"]: {
		permission: boolean
};
	["EventsDocAccessFields_sections_repeat"]: {
		create?: ModelTypes["EventsDocAccessFields_sections_repeat_Create"] | undefined,
	read?: ModelTypes["EventsDocAccessFields_sections_repeat_Read"] | undefined,
	update?: ModelTypes["EventsDocAccessFields_sections_repeat_Update"] | undefined,
	delete?: ModelTypes["EventsDocAccessFields_sections_repeat_Delete"] | undefined
};
	["EventsDocAccessFields_sections_repeat_Create"]: {
		permission: boolean
};
	["EventsDocAccessFields_sections_repeat_Read"]: {
		permission: boolean
};
	["EventsDocAccessFields_sections_repeat_Update"]: {
		permission: boolean
};
	["EventsDocAccessFields_sections_repeat_Delete"]: {
		permission: boolean
};
	["EventsDocAccessFields_sections_toThisDay"]: {
		create?: ModelTypes["EventsDocAccessFields_sections_toThisDay_Create"] | undefined,
	read?: ModelTypes["EventsDocAccessFields_sections_toThisDay_Read"] | undefined,
	update?: ModelTypes["EventsDocAccessFields_sections_toThisDay_Update"] | undefined,
	delete?: ModelTypes["EventsDocAccessFields_sections_toThisDay_Delete"] | undefined
};
	["EventsDocAccessFields_sections_toThisDay_Create"]: {
		permission: boolean
};
	["EventsDocAccessFields_sections_toThisDay_Read"]: {
		permission: boolean
};
	["EventsDocAccessFields_sections_toThisDay_Update"]: {
		permission: boolean
};
	["EventsDocAccessFields_sections_toThisDay_Delete"]: {
		permission: boolean
};
	["EventsDocAccessFields_sections_recurrance"]: {
		create?: ModelTypes["EventsDocAccessFields_sections_recurrance_Create"] | undefined,
	read?: ModelTypes["EventsDocAccessFields_sections_recurrance_Read"] | undefined,
	update?: ModelTypes["EventsDocAccessFields_sections_recurrance_Update"] | undefined,
	delete?: ModelTypes["EventsDocAccessFields_sections_recurrance_Delete"] | undefined,
	fields?: ModelTypes["EventsDocAccessFields_sections_recurrance_Fields"] | undefined
};
	["EventsDocAccessFields_sections_recurrance_Create"]: {
		permission: boolean
};
	["EventsDocAccessFields_sections_recurrance_Read"]: {
		permission: boolean
};
	["EventsDocAccessFields_sections_recurrance_Update"]: {
		permission: boolean
};
	["EventsDocAccessFields_sections_recurrance_Delete"]: {
		permission: boolean
};
	["EventsDocAccessFields_sections_recurrance_Fields"]: {
		type?: ModelTypes["EventsDocAccessFields_sections_recurrance_type"] | undefined,
	weekday?: ModelTypes["EventsDocAccessFields_sections_recurrance_weekday"] | undefined
};
	["EventsDocAccessFields_sections_recurrance_type"]: {
		create?: ModelTypes["EventsDocAccessFields_sections_recurrance_type_Create"] | undefined,
	read?: ModelTypes["EventsDocAccessFields_sections_recurrance_type_Read"] | undefined,
	update?: ModelTypes["EventsDocAccessFields_sections_recurrance_type_Update"] | undefined,
	delete?: ModelTypes["EventsDocAccessFields_sections_recurrance_type_Delete"] | undefined
};
	["EventsDocAccessFields_sections_recurrance_type_Create"]: {
		permission: boolean
};
	["EventsDocAccessFields_sections_recurrance_type_Read"]: {
		permission: boolean
};
	["EventsDocAccessFields_sections_recurrance_type_Update"]: {
		permission: boolean
};
	["EventsDocAccessFields_sections_recurrance_type_Delete"]: {
		permission: boolean
};
	["EventsDocAccessFields_sections_recurrance_weekday"]: {
		create?: ModelTypes["EventsDocAccessFields_sections_recurrance_weekday_Create"] | undefined,
	read?: ModelTypes["EventsDocAccessFields_sections_recurrance_weekday_Read"] | undefined,
	update?: ModelTypes["EventsDocAccessFields_sections_recurrance_weekday_Update"] | undefined,
	delete?: ModelTypes["EventsDocAccessFields_sections_recurrance_weekday_Delete"] | undefined
};
	["EventsDocAccessFields_sections_recurrance_weekday_Create"]: {
		permission: boolean
};
	["EventsDocAccessFields_sections_recurrance_weekday_Read"]: {
		permission: boolean
};
	["EventsDocAccessFields_sections_recurrance_weekday_Update"]: {
		permission: boolean
};
	["EventsDocAccessFields_sections_recurrance_weekday_Delete"]: {
		permission: boolean
};
	["EventsDocAccessFields_sections_sameLocation"]: {
		create?: ModelTypes["EventsDocAccessFields_sections_sameLocation_Create"] | undefined,
	read?: ModelTypes["EventsDocAccessFields_sections_sameLocation_Read"] | undefined,
	update?: ModelTypes["EventsDocAccessFields_sections_sameLocation_Update"] | undefined,
	delete?: ModelTypes["EventsDocAccessFields_sections_sameLocation_Delete"] | undefined
};
	["EventsDocAccessFields_sections_sameLocation_Create"]: {
		permission: boolean
};
	["EventsDocAccessFields_sections_sameLocation_Read"]: {
		permission: boolean
};
	["EventsDocAccessFields_sections_sameLocation_Update"]: {
		permission: boolean
};
	["EventsDocAccessFields_sections_sameLocation_Delete"]: {
		permission: boolean
};
	["EventsDocAccessFields_sections_location"]: {
		create?: ModelTypes["EventsDocAccessFields_sections_location_Create"] | undefined,
	read?: ModelTypes["EventsDocAccessFields_sections_location_Read"] | undefined,
	update?: ModelTypes["EventsDocAccessFields_sections_location_Update"] | undefined,
	delete?: ModelTypes["EventsDocAccessFields_sections_location_Delete"] | undefined
};
	["EventsDocAccessFields_sections_location_Create"]: {
		permission: boolean
};
	["EventsDocAccessFields_sections_location_Read"]: {
		permission: boolean
};
	["EventsDocAccessFields_sections_location_Update"]: {
		permission: boolean
};
	["EventsDocAccessFields_sections_location_Delete"]: {
		permission: boolean
};
	["EventsDocAccessFields_sections_district"]: {
		create?: ModelTypes["EventsDocAccessFields_sections_district_Create"] | undefined,
	read?: ModelTypes["EventsDocAccessFields_sections_district_Read"] | undefined,
	update?: ModelTypes["EventsDocAccessFields_sections_district_Update"] | undefined,
	delete?: ModelTypes["EventsDocAccessFields_sections_district_Delete"] | undefined
};
	["EventsDocAccessFields_sections_district_Create"]: {
		permission: boolean
};
	["EventsDocAccessFields_sections_district_Read"]: {
		permission: boolean
};
	["EventsDocAccessFields_sections_district_Update"]: {
		permission: boolean
};
	["EventsDocAccessFields_sections_district_Delete"]: {
		permission: boolean
};
	["EventsDocAccessFields_sections_coordinate"]: {
		create?: ModelTypes["EventsDocAccessFields_sections_coordinate_Create"] | undefined,
	read?: ModelTypes["EventsDocAccessFields_sections_coordinate_Read"] | undefined,
	update?: ModelTypes["EventsDocAccessFields_sections_coordinate_Update"] | undefined,
	delete?: ModelTypes["EventsDocAccessFields_sections_coordinate_Delete"] | undefined
};
	["EventsDocAccessFields_sections_coordinate_Create"]: {
		permission: boolean
};
	["EventsDocAccessFields_sections_coordinate_Read"]: {
		permission: boolean
};
	["EventsDocAccessFields_sections_coordinate_Update"]: {
		permission: boolean
};
	["EventsDocAccessFields_sections_coordinate_Delete"]: {
		permission: boolean
};
	["EventsDocAccessFields_sections_linkAddress"]: {
		create?: ModelTypes["EventsDocAccessFields_sections_linkAddress_Create"] | undefined,
	read?: ModelTypes["EventsDocAccessFields_sections_linkAddress_Read"] | undefined,
	update?: ModelTypes["EventsDocAccessFields_sections_linkAddress_Update"] | undefined,
	delete?: ModelTypes["EventsDocAccessFields_sections_linkAddress_Delete"] | undefined
};
	["EventsDocAccessFields_sections_linkAddress_Create"]: {
		permission: boolean
};
	["EventsDocAccessFields_sections_linkAddress_Read"]: {
		permission: boolean
};
	["EventsDocAccessFields_sections_linkAddress_Update"]: {
		permission: boolean
};
	["EventsDocAccessFields_sections_linkAddress_Delete"]: {
		permission: boolean
};
	["EventsDocAccessFields_sections_address"]: {
		create?: ModelTypes["EventsDocAccessFields_sections_address_Create"] | undefined,
	read?: ModelTypes["EventsDocAccessFields_sections_address_Read"] | undefined,
	update?: ModelTypes["EventsDocAccessFields_sections_address_Update"] | undefined,
	delete?: ModelTypes["EventsDocAccessFields_sections_address_Delete"] | undefined
};
	["EventsDocAccessFields_sections_address_Create"]: {
		permission: boolean
};
	["EventsDocAccessFields_sections_address_Read"]: {
		permission: boolean
};
	["EventsDocAccessFields_sections_address_Update"]: {
		permission: boolean
};
	["EventsDocAccessFields_sections_address_Delete"]: {
		permission: boolean
};
	["EventsDocAccessFields_sections_id"]: {
		create?: ModelTypes["EventsDocAccessFields_sections_id_Create"] | undefined,
	read?: ModelTypes["EventsDocAccessFields_sections_id_Read"] | undefined,
	update?: ModelTypes["EventsDocAccessFields_sections_id_Update"] | undefined,
	delete?: ModelTypes["EventsDocAccessFields_sections_id_Delete"] | undefined
};
	["EventsDocAccessFields_sections_id_Create"]: {
		permission: boolean
};
	["EventsDocAccessFields_sections_id_Read"]: {
		permission: boolean
};
	["EventsDocAccessFields_sections_id_Update"]: {
		permission: boolean
};
	["EventsDocAccessFields_sections_id_Delete"]: {
		permission: boolean
};
	["EventsDocAccessFields_ticketing"]: {
		create?: ModelTypes["EventsDocAccessFields_ticketing_Create"] | undefined,
	read?: ModelTypes["EventsDocAccessFields_ticketing_Read"] | undefined,
	update?: ModelTypes["EventsDocAccessFields_ticketing_Update"] | undefined,
	delete?: ModelTypes["EventsDocAccessFields_ticketing_Delete"] | undefined,
	fields?: ModelTypes["EventsDocAccessFields_ticketing_Fields"] | undefined
};
	["EventsDocAccessFields_ticketing_Create"]: {
		permission: boolean
};
	["EventsDocAccessFields_ticketing_Read"]: {
		permission: boolean
};
	["EventsDocAccessFields_ticketing_Update"]: {
		permission: boolean
};
	["EventsDocAccessFields_ticketing_Delete"]: {
		permission: boolean
};
	["EventsDocAccessFields_ticketing_Fields"]: {
		startDatetime?: ModelTypes["EventsDocAccessFields_ticketing_startDatetime"] | undefined,
	endDatetime?: ModelTypes["EventsDocAccessFields_ticketing_endDatetime"] | undefined,
	fullDay?: ModelTypes["EventsDocAccessFields_ticketing_fullDay"] | undefined,
	type?: ModelTypes["EventsDocAccessFields_ticketing_type"] | undefined,
	ticketingType?: ModelTypes["EventsDocAccessFields_ticketing_ticketingType"] | undefined,
	linkedTicketing?: ModelTypes["EventsDocAccessFields_ticketing_linkedTicketing"] | undefined,
	promotionType?: ModelTypes["EventsDocAccessFields_ticketing_promotionType"] | undefined,
	location?: ModelTypes["EventsDocAccessFields_ticketing_location"] | undefined,
	website?: ModelTypes["EventsDocAccessFields_ticketing_website"] | undefined,
	description?: ModelTypes["EventsDocAccessFields_ticketing_description"] | undefined,
	id?: ModelTypes["EventsDocAccessFields_ticketing_id"] | undefined
};
	["EventsDocAccessFields_ticketing_startDatetime"]: {
		create?: ModelTypes["EventsDocAccessFields_ticketing_startDatetime_Create"] | undefined,
	read?: ModelTypes["EventsDocAccessFields_ticketing_startDatetime_Read"] | undefined,
	update?: ModelTypes["EventsDocAccessFields_ticketing_startDatetime_Update"] | undefined,
	delete?: ModelTypes["EventsDocAccessFields_ticketing_startDatetime_Delete"] | undefined
};
	["EventsDocAccessFields_ticketing_startDatetime_Create"]: {
		permission: boolean
};
	["EventsDocAccessFields_ticketing_startDatetime_Read"]: {
		permission: boolean
};
	["EventsDocAccessFields_ticketing_startDatetime_Update"]: {
		permission: boolean
};
	["EventsDocAccessFields_ticketing_startDatetime_Delete"]: {
		permission: boolean
};
	["EventsDocAccessFields_ticketing_endDatetime"]: {
		create?: ModelTypes["EventsDocAccessFields_ticketing_endDatetime_Create"] | undefined,
	read?: ModelTypes["EventsDocAccessFields_ticketing_endDatetime_Read"] | undefined,
	update?: ModelTypes["EventsDocAccessFields_ticketing_endDatetime_Update"] | undefined,
	delete?: ModelTypes["EventsDocAccessFields_ticketing_endDatetime_Delete"] | undefined
};
	["EventsDocAccessFields_ticketing_endDatetime_Create"]: {
		permission: boolean
};
	["EventsDocAccessFields_ticketing_endDatetime_Read"]: {
		permission: boolean
};
	["EventsDocAccessFields_ticketing_endDatetime_Update"]: {
		permission: boolean
};
	["EventsDocAccessFields_ticketing_endDatetime_Delete"]: {
		permission: boolean
};
	["EventsDocAccessFields_ticketing_fullDay"]: {
		create?: ModelTypes["EventsDocAccessFields_ticketing_fullDay_Create"] | undefined,
	read?: ModelTypes["EventsDocAccessFields_ticketing_fullDay_Read"] | undefined,
	update?: ModelTypes["EventsDocAccessFields_ticketing_fullDay_Update"] | undefined,
	delete?: ModelTypes["EventsDocAccessFields_ticketing_fullDay_Delete"] | undefined
};
	["EventsDocAccessFields_ticketing_fullDay_Create"]: {
		permission: boolean
};
	["EventsDocAccessFields_ticketing_fullDay_Read"]: {
		permission: boolean
};
	["EventsDocAccessFields_ticketing_fullDay_Update"]: {
		permission: boolean
};
	["EventsDocAccessFields_ticketing_fullDay_Delete"]: {
		permission: boolean
};
	["EventsDocAccessFields_ticketing_type"]: {
		create?: ModelTypes["EventsDocAccessFields_ticketing_type_Create"] | undefined,
	read?: ModelTypes["EventsDocAccessFields_ticketing_type_Read"] | undefined,
	update?: ModelTypes["EventsDocAccessFields_ticketing_type_Update"] | undefined,
	delete?: ModelTypes["EventsDocAccessFields_ticketing_type_Delete"] | undefined
};
	["EventsDocAccessFields_ticketing_type_Create"]: {
		permission: boolean
};
	["EventsDocAccessFields_ticketing_type_Read"]: {
		permission: boolean
};
	["EventsDocAccessFields_ticketing_type_Update"]: {
		permission: boolean
};
	["EventsDocAccessFields_ticketing_type_Delete"]: {
		permission: boolean
};
	["EventsDocAccessFields_ticketing_ticketingType"]: {
		create?: ModelTypes["EventsDocAccessFields_ticketing_ticketingType_Create"] | undefined,
	read?: ModelTypes["EventsDocAccessFields_ticketing_ticketingType_Read"] | undefined,
	update?: ModelTypes["EventsDocAccessFields_ticketing_ticketingType_Update"] | undefined,
	delete?: ModelTypes["EventsDocAccessFields_ticketing_ticketingType_Delete"] | undefined
};
	["EventsDocAccessFields_ticketing_ticketingType_Create"]: {
		permission: boolean
};
	["EventsDocAccessFields_ticketing_ticketingType_Read"]: {
		permission: boolean
};
	["EventsDocAccessFields_ticketing_ticketingType_Update"]: {
		permission: boolean
};
	["EventsDocAccessFields_ticketing_ticketingType_Delete"]: {
		permission: boolean
};
	["EventsDocAccessFields_ticketing_linkedTicketing"]: {
		create?: ModelTypes["EventsDocAccessFields_ticketing_linkedTicketing_Create"] | undefined,
	read?: ModelTypes["EventsDocAccessFields_ticketing_linkedTicketing_Read"] | undefined,
	update?: ModelTypes["EventsDocAccessFields_ticketing_linkedTicketing_Update"] | undefined,
	delete?: ModelTypes["EventsDocAccessFields_ticketing_linkedTicketing_Delete"] | undefined
};
	["EventsDocAccessFields_ticketing_linkedTicketing_Create"]: {
		permission: boolean
};
	["EventsDocAccessFields_ticketing_linkedTicketing_Read"]: {
		permission: boolean
};
	["EventsDocAccessFields_ticketing_linkedTicketing_Update"]: {
		permission: boolean
};
	["EventsDocAccessFields_ticketing_linkedTicketing_Delete"]: {
		permission: boolean
};
	["EventsDocAccessFields_ticketing_promotionType"]: {
		create?: ModelTypes["EventsDocAccessFields_ticketing_promotionType_Create"] | undefined,
	read?: ModelTypes["EventsDocAccessFields_ticketing_promotionType_Read"] | undefined,
	update?: ModelTypes["EventsDocAccessFields_ticketing_promotionType_Update"] | undefined,
	delete?: ModelTypes["EventsDocAccessFields_ticketing_promotionType_Delete"] | undefined
};
	["EventsDocAccessFields_ticketing_promotionType_Create"]: {
		permission: boolean
};
	["EventsDocAccessFields_ticketing_promotionType_Read"]: {
		permission: boolean
};
	["EventsDocAccessFields_ticketing_promotionType_Update"]: {
		permission: boolean
};
	["EventsDocAccessFields_ticketing_promotionType_Delete"]: {
		permission: boolean
};
	["EventsDocAccessFields_ticketing_location"]: {
		create?: ModelTypes["EventsDocAccessFields_ticketing_location_Create"] | undefined,
	read?: ModelTypes["EventsDocAccessFields_ticketing_location_Read"] | undefined,
	update?: ModelTypes["EventsDocAccessFields_ticketing_location_Update"] | undefined,
	delete?: ModelTypes["EventsDocAccessFields_ticketing_location_Delete"] | undefined
};
	["EventsDocAccessFields_ticketing_location_Create"]: {
		permission: boolean
};
	["EventsDocAccessFields_ticketing_location_Read"]: {
		permission: boolean
};
	["EventsDocAccessFields_ticketing_location_Update"]: {
		permission: boolean
};
	["EventsDocAccessFields_ticketing_location_Delete"]: {
		permission: boolean
};
	["EventsDocAccessFields_ticketing_website"]: {
		create?: ModelTypes["EventsDocAccessFields_ticketing_website_Create"] | undefined,
	read?: ModelTypes["EventsDocAccessFields_ticketing_website_Read"] | undefined,
	update?: ModelTypes["EventsDocAccessFields_ticketing_website_Update"] | undefined,
	delete?: ModelTypes["EventsDocAccessFields_ticketing_website_Delete"] | undefined
};
	["EventsDocAccessFields_ticketing_website_Create"]: {
		permission: boolean
};
	["EventsDocAccessFields_ticketing_website_Read"]: {
		permission: boolean
};
	["EventsDocAccessFields_ticketing_website_Update"]: {
		permission: boolean
};
	["EventsDocAccessFields_ticketing_website_Delete"]: {
		permission: boolean
};
	["EventsDocAccessFields_ticketing_description"]: {
		create?: ModelTypes["EventsDocAccessFields_ticketing_description_Create"] | undefined,
	read?: ModelTypes["EventsDocAccessFields_ticketing_description_Read"] | undefined,
	update?: ModelTypes["EventsDocAccessFields_ticketing_description_Update"] | undefined,
	delete?: ModelTypes["EventsDocAccessFields_ticketing_description_Delete"] | undefined
};
	["EventsDocAccessFields_ticketing_description_Create"]: {
		permission: boolean
};
	["EventsDocAccessFields_ticketing_description_Read"]: {
		permission: boolean
};
	["EventsDocAccessFields_ticketing_description_Update"]: {
		permission: boolean
};
	["EventsDocAccessFields_ticketing_description_Delete"]: {
		permission: boolean
};
	["EventsDocAccessFields_ticketing_id"]: {
		create?: ModelTypes["EventsDocAccessFields_ticketing_id_Create"] | undefined,
	read?: ModelTypes["EventsDocAccessFields_ticketing_id_Read"] | undefined,
	update?: ModelTypes["EventsDocAccessFields_ticketing_id_Update"] | undefined,
	delete?: ModelTypes["EventsDocAccessFields_ticketing_id_Delete"] | undefined
};
	["EventsDocAccessFields_ticketing_id_Create"]: {
		permission: boolean
};
	["EventsDocAccessFields_ticketing_id_Read"]: {
		permission: boolean
};
	["EventsDocAccessFields_ticketing_id_Update"]: {
		permission: boolean
};
	["EventsDocAccessFields_ticketing_id_Delete"]: {
		permission: boolean
};
	["EventsDocAccessFields_content"]: {
		create?: ModelTypes["EventsDocAccessFields_content_Create"] | undefined,
	read?: ModelTypes["EventsDocAccessFields_content_Read"] | undefined,
	update?: ModelTypes["EventsDocAccessFields_content_Update"] | undefined,
	delete?: ModelTypes["EventsDocAccessFields_content_Delete"] | undefined
};
	["EventsDocAccessFields_content_Create"]: {
		permission: boolean
};
	["EventsDocAccessFields_content_Read"]: {
		permission: boolean
};
	["EventsDocAccessFields_content_Update"]: {
		permission: boolean
};
	["EventsDocAccessFields_content_Delete"]: {
		permission: boolean
};
	["EventsDocAccessFields_legacyContent"]: {
		create?: ModelTypes["EventsDocAccessFields_legacyContent_Create"] | undefined,
	read?: ModelTypes["EventsDocAccessFields_legacyContent_Read"] | undefined,
	update?: ModelTypes["EventsDocAccessFields_legacyContent_Update"] | undefined,
	delete?: ModelTypes["EventsDocAccessFields_legacyContent_Delete"] | undefined
};
	["EventsDocAccessFields_legacyContent_Create"]: {
		permission: boolean
};
	["EventsDocAccessFields_legacyContent_Read"]: {
		permission: boolean
};
	["EventsDocAccessFields_legacyContent_Update"]: {
		permission: boolean
};
	["EventsDocAccessFields_legacyContent_Delete"]: {
		permission: boolean
};
	["EventsDocAccessFields_showLegacyContent"]: {
		create?: ModelTypes["EventsDocAccessFields_showLegacyContent_Create"] | undefined,
	read?: ModelTypes["EventsDocAccessFields_showLegacyContent_Read"] | undefined,
	update?: ModelTypes["EventsDocAccessFields_showLegacyContent_Update"] | undefined,
	delete?: ModelTypes["EventsDocAccessFields_showLegacyContent_Delete"] | undefined
};
	["EventsDocAccessFields_showLegacyContent_Create"]: {
		permission: boolean
};
	["EventsDocAccessFields_showLegacyContent_Read"]: {
		permission: boolean
};
	["EventsDocAccessFields_showLegacyContent_Update"]: {
		permission: boolean
};
	["EventsDocAccessFields_showLegacyContent_Delete"]: {
		permission: boolean
};
	["EventsDocAccessFields_criteria"]: {
		create?: ModelTypes["EventsDocAccessFields_criteria_Create"] | undefined,
	read?: ModelTypes["EventsDocAccessFields_criteria_Read"] | undefined,
	update?: ModelTypes["EventsDocAccessFields_criteria_Update"] | undefined,
	delete?: ModelTypes["EventsDocAccessFields_criteria_Delete"] | undefined,
	fields?: ModelTypes["EventsDocAccessFields_criteria_Fields"] | undefined
};
	["EventsDocAccessFields_criteria_Create"]: {
		permission: boolean
};
	["EventsDocAccessFields_criteria_Read"]: {
		permission: boolean
};
	["EventsDocAccessFields_criteria_Update"]: {
		permission: boolean
};
	["EventsDocAccessFields_criteria_Delete"]: {
		permission: boolean
};
	["EventsDocAccessFields_criteria_Fields"]: {
		categories?: ModelTypes["EventsDocAccessFields_criteria_categories"] | undefined,
	audiences?: ModelTypes["EventsDocAccessFields_criteria_audiences"] | undefined,
	tags?: ModelTypes["EventsDocAccessFields_criteria_tags"] | undefined,
	specialTags?: ModelTypes["EventsDocAccessFields_criteria_specialTags"] | undefined
};
	["EventsDocAccessFields_criteria_categories"]: {
		create?: ModelTypes["EventsDocAccessFields_criteria_categories_Create"] | undefined,
	read?: ModelTypes["EventsDocAccessFields_criteria_categories_Read"] | undefined,
	update?: ModelTypes["EventsDocAccessFields_criteria_categories_Update"] | undefined,
	delete?: ModelTypes["EventsDocAccessFields_criteria_categories_Delete"] | undefined
};
	["EventsDocAccessFields_criteria_categories_Create"]: {
		permission: boolean
};
	["EventsDocAccessFields_criteria_categories_Read"]: {
		permission: boolean
};
	["EventsDocAccessFields_criteria_categories_Update"]: {
		permission: boolean
};
	["EventsDocAccessFields_criteria_categories_Delete"]: {
		permission: boolean
};
	["EventsDocAccessFields_criteria_audiences"]: {
		create?: ModelTypes["EventsDocAccessFields_criteria_audiences_Create"] | undefined,
	read?: ModelTypes["EventsDocAccessFields_criteria_audiences_Read"] | undefined,
	update?: ModelTypes["EventsDocAccessFields_criteria_audiences_Update"] | undefined,
	delete?: ModelTypes["EventsDocAccessFields_criteria_audiences_Delete"] | undefined
};
	["EventsDocAccessFields_criteria_audiences_Create"]: {
		permission: boolean
};
	["EventsDocAccessFields_criteria_audiences_Read"]: {
		permission: boolean
};
	["EventsDocAccessFields_criteria_audiences_Update"]: {
		permission: boolean
};
	["EventsDocAccessFields_criteria_audiences_Delete"]: {
		permission: boolean
};
	["EventsDocAccessFields_criteria_tags"]: {
		create?: ModelTypes["EventsDocAccessFields_criteria_tags_Create"] | undefined,
	read?: ModelTypes["EventsDocAccessFields_criteria_tags_Read"] | undefined,
	update?: ModelTypes["EventsDocAccessFields_criteria_tags_Update"] | undefined,
	delete?: ModelTypes["EventsDocAccessFields_criteria_tags_Delete"] | undefined
};
	["EventsDocAccessFields_criteria_tags_Create"]: {
		permission: boolean
};
	["EventsDocAccessFields_criteria_tags_Read"]: {
		permission: boolean
};
	["EventsDocAccessFields_criteria_tags_Update"]: {
		permission: boolean
};
	["EventsDocAccessFields_criteria_tags_Delete"]: {
		permission: boolean
};
	["EventsDocAccessFields_criteria_specialTags"]: {
		create?: ModelTypes["EventsDocAccessFields_criteria_specialTags_Create"] | undefined,
	read?: ModelTypes["EventsDocAccessFields_criteria_specialTags_Read"] | undefined,
	update?: ModelTypes["EventsDocAccessFields_criteria_specialTags_Update"] | undefined,
	delete?: ModelTypes["EventsDocAccessFields_criteria_specialTags_Delete"] | undefined
};
	["EventsDocAccessFields_criteria_specialTags_Create"]: {
		permission: boolean
};
	["EventsDocAccessFields_criteria_specialTags_Read"]: {
		permission: boolean
};
	["EventsDocAccessFields_criteria_specialTags_Update"]: {
		permission: boolean
};
	["EventsDocAccessFields_criteria_specialTags_Delete"]: {
		permission: boolean
};
	["EventsDocAccessFields_walkIn"]: {
		create?: ModelTypes["EventsDocAccessFields_walkIn_Create"] | undefined,
	read?: ModelTypes["EventsDocAccessFields_walkIn_Read"] | undefined,
	update?: ModelTypes["EventsDocAccessFields_walkIn_Update"] | undefined,
	delete?: ModelTypes["EventsDocAccessFields_walkIn_Delete"] | undefined
};
	["EventsDocAccessFields_walkIn_Create"]: {
		permission: boolean
};
	["EventsDocAccessFields_walkIn_Read"]: {
		permission: boolean
};
	["EventsDocAccessFields_walkIn_Update"]: {
		permission: boolean
};
	["EventsDocAccessFields_walkIn_Delete"]: {
		permission: boolean
};
	["EventsDocAccessFields_contact"]: {
		create?: ModelTypes["EventsDocAccessFields_contact_Create"] | undefined,
	read?: ModelTypes["EventsDocAccessFields_contact_Read"] | undefined,
	update?: ModelTypes["EventsDocAccessFields_contact_Update"] | undefined,
	delete?: ModelTypes["EventsDocAccessFields_contact_Delete"] | undefined,
	fields?: ModelTypes["EventsDocAccessFields_contact_Fields"] | undefined
};
	["EventsDocAccessFields_contact_Create"]: {
		permission: boolean
};
	["EventsDocAccessFields_contact_Read"]: {
		permission: boolean
};
	["EventsDocAccessFields_contact_Update"]: {
		permission: boolean
};
	["EventsDocAccessFields_contact_Delete"]: {
		permission: boolean
};
	["EventsDocAccessFields_contact_Fields"]: {
		code?: ModelTypes["EventsDocAccessFields_contact_code"] | undefined,
	phone?: ModelTypes["EventsDocAccessFields_contact_phone"] | undefined,
	page?: ModelTypes["EventsDocAccessFields_contact_page"] | undefined,
	email?: ModelTypes["EventsDocAccessFields_contact_email"] | undefined,
	privacy?: ModelTypes["EventsDocAccessFields_contact_privacy"] | undefined
};
	["EventsDocAccessFields_contact_code"]: {
		create?: ModelTypes["EventsDocAccessFields_contact_code_Create"] | undefined,
	read?: ModelTypes["EventsDocAccessFields_contact_code_Read"] | undefined,
	update?: ModelTypes["EventsDocAccessFields_contact_code_Update"] | undefined,
	delete?: ModelTypes["EventsDocAccessFields_contact_code_Delete"] | undefined
};
	["EventsDocAccessFields_contact_code_Create"]: {
		permission: boolean
};
	["EventsDocAccessFields_contact_code_Read"]: {
		permission: boolean
};
	["EventsDocAccessFields_contact_code_Update"]: {
		permission: boolean
};
	["EventsDocAccessFields_contact_code_Delete"]: {
		permission: boolean
};
	["EventsDocAccessFields_contact_phone"]: {
		create?: ModelTypes["EventsDocAccessFields_contact_phone_Create"] | undefined,
	read?: ModelTypes["EventsDocAccessFields_contact_phone_Read"] | undefined,
	update?: ModelTypes["EventsDocAccessFields_contact_phone_Update"] | undefined,
	delete?: ModelTypes["EventsDocAccessFields_contact_phone_Delete"] | undefined
};
	["EventsDocAccessFields_contact_phone_Create"]: {
		permission: boolean
};
	["EventsDocAccessFields_contact_phone_Read"]: {
		permission: boolean
};
	["EventsDocAccessFields_contact_phone_Update"]: {
		permission: boolean
};
	["EventsDocAccessFields_contact_phone_Delete"]: {
		permission: boolean
};
	["EventsDocAccessFields_contact_page"]: {
		create?: ModelTypes["EventsDocAccessFields_contact_page_Create"] | undefined,
	read?: ModelTypes["EventsDocAccessFields_contact_page_Read"] | undefined,
	update?: ModelTypes["EventsDocAccessFields_contact_page_Update"] | undefined,
	delete?: ModelTypes["EventsDocAccessFields_contact_page_Delete"] | undefined
};
	["EventsDocAccessFields_contact_page_Create"]: {
		permission: boolean
};
	["EventsDocAccessFields_contact_page_Read"]: {
		permission: boolean
};
	["EventsDocAccessFields_contact_page_Update"]: {
		permission: boolean
};
	["EventsDocAccessFields_contact_page_Delete"]: {
		permission: boolean
};
	["EventsDocAccessFields_contact_email"]: {
		create?: ModelTypes["EventsDocAccessFields_contact_email_Create"] | undefined,
	read?: ModelTypes["EventsDocAccessFields_contact_email_Read"] | undefined,
	update?: ModelTypes["EventsDocAccessFields_contact_email_Update"] | undefined,
	delete?: ModelTypes["EventsDocAccessFields_contact_email_Delete"] | undefined
};
	["EventsDocAccessFields_contact_email_Create"]: {
		permission: boolean
};
	["EventsDocAccessFields_contact_email_Read"]: {
		permission: boolean
};
	["EventsDocAccessFields_contact_email_Update"]: {
		permission: boolean
};
	["EventsDocAccessFields_contact_email_Delete"]: {
		permission: boolean
};
	["EventsDocAccessFields_contact_privacy"]: {
		create?: ModelTypes["EventsDocAccessFields_contact_privacy_Create"] | undefined,
	read?: ModelTypes["EventsDocAccessFields_contact_privacy_Read"] | undefined,
	update?: ModelTypes["EventsDocAccessFields_contact_privacy_Update"] | undefined,
	delete?: ModelTypes["EventsDocAccessFields_contact_privacy_Delete"] | undefined
};
	["EventsDocAccessFields_contact_privacy_Create"]: {
		permission: boolean
};
	["EventsDocAccessFields_contact_privacy_Read"]: {
		permission: boolean
};
	["EventsDocAccessFields_contact_privacy_Update"]: {
		permission: boolean
};
	["EventsDocAccessFields_contact_privacy_Delete"]: {
		permission: boolean
};
	["EventsDocAccessFields_views"]: {
		create?: ModelTypes["EventsDocAccessFields_views_Create"] | undefined,
	read?: ModelTypes["EventsDocAccessFields_views_Read"] | undefined,
	update?: ModelTypes["EventsDocAccessFields_views_Update"] | undefined,
	delete?: ModelTypes["EventsDocAccessFields_views_Delete"] | undefined
};
	["EventsDocAccessFields_views_Create"]: {
		permission: boolean
};
	["EventsDocAccessFields_views_Read"]: {
		permission: boolean
};
	["EventsDocAccessFields_views_Update"]: {
		permission: boolean
};
	["EventsDocAccessFields_views_Delete"]: {
		permission: boolean
};
	["EventsDocAccessFields_free"]: {
		create?: ModelTypes["EventsDocAccessFields_free_Create"] | undefined,
	read?: ModelTypes["EventsDocAccessFields_free_Read"] | undefined,
	update?: ModelTypes["EventsDocAccessFields_free_Update"] | undefined,
	delete?: ModelTypes["EventsDocAccessFields_free_Delete"] | undefined
};
	["EventsDocAccessFields_free_Create"]: {
		permission: boolean
};
	["EventsDocAccessFields_free_Read"]: {
		permission: boolean
};
	["EventsDocAccessFields_free_Update"]: {
		permission: boolean
};
	["EventsDocAccessFields_free_Delete"]: {
		permission: boolean
};
	["EventsDocAccessFields_fees"]: {
		create?: ModelTypes["EventsDocAccessFields_fees_Create"] | undefined,
	read?: ModelTypes["EventsDocAccessFields_fees_Read"] | undefined,
	update?: ModelTypes["EventsDocAccessFields_fees_Update"] | undefined,
	delete?: ModelTypes["EventsDocAccessFields_fees_Delete"] | undefined,
	fields?: ModelTypes["EventsDocAccessFields_fees_Fields"] | undefined
};
	["EventsDocAccessFields_fees_Create"]: {
		permission: boolean
};
	["EventsDocAccessFields_fees_Read"]: {
		permission: boolean
};
	["EventsDocAccessFields_fees_Update"]: {
		permission: boolean
};
	["EventsDocAccessFields_fees_Delete"]: {
		permission: boolean
};
	["EventsDocAccessFields_fees_Fields"]: {
		fee?: ModelTypes["EventsDocAccessFields_fees_fee"] | undefined,
	remark?: ModelTypes["EventsDocAccessFields_fees_remark"] | undefined,
	id?: ModelTypes["EventsDocAccessFields_fees_id"] | undefined
};
	["EventsDocAccessFields_fees_fee"]: {
		create?: ModelTypes["EventsDocAccessFields_fees_fee_Create"] | undefined,
	read?: ModelTypes["EventsDocAccessFields_fees_fee_Read"] | undefined,
	update?: ModelTypes["EventsDocAccessFields_fees_fee_Update"] | undefined,
	delete?: ModelTypes["EventsDocAccessFields_fees_fee_Delete"] | undefined
};
	["EventsDocAccessFields_fees_fee_Create"]: {
		permission: boolean
};
	["EventsDocAccessFields_fees_fee_Read"]: {
		permission: boolean
};
	["EventsDocAccessFields_fees_fee_Update"]: {
		permission: boolean
};
	["EventsDocAccessFields_fees_fee_Delete"]: {
		permission: boolean
};
	["EventsDocAccessFields_fees_remark"]: {
		create?: ModelTypes["EventsDocAccessFields_fees_remark_Create"] | undefined,
	read?: ModelTypes["EventsDocAccessFields_fees_remark_Read"] | undefined,
	update?: ModelTypes["EventsDocAccessFields_fees_remark_Update"] | undefined,
	delete?: ModelTypes["EventsDocAccessFields_fees_remark_Delete"] | undefined
};
	["EventsDocAccessFields_fees_remark_Create"]: {
		permission: boolean
};
	["EventsDocAccessFields_fees_remark_Read"]: {
		permission: boolean
};
	["EventsDocAccessFields_fees_remark_Update"]: {
		permission: boolean
};
	["EventsDocAccessFields_fees_remark_Delete"]: {
		permission: boolean
};
	["EventsDocAccessFields_fees_id"]: {
		create?: ModelTypes["EventsDocAccessFields_fees_id_Create"] | undefined,
	read?: ModelTypes["EventsDocAccessFields_fees_id_Read"] | undefined,
	update?: ModelTypes["EventsDocAccessFields_fees_id_Update"] | undefined,
	delete?: ModelTypes["EventsDocAccessFields_fees_id_Delete"] | undefined
};
	["EventsDocAccessFields_fees_id_Create"]: {
		permission: boolean
};
	["EventsDocAccessFields_fees_id_Read"]: {
		permission: boolean
};
	["EventsDocAccessFields_fees_id_Update"]: {
		permission: boolean
};
	["EventsDocAccessFields_fees_id_Delete"]: {
		permission: boolean
};
	["EventsDocAccessFields_homePageInternalClick"]: {
		create?: ModelTypes["EventsDocAccessFields_homePageInternalClick_Create"] | undefined,
	read?: ModelTypes["EventsDocAccessFields_homePageInternalClick_Read"] | undefined,
	update?: ModelTypes["EventsDocAccessFields_homePageInternalClick_Update"] | undefined,
	delete?: ModelTypes["EventsDocAccessFields_homePageInternalClick_Delete"] | undefined
};
	["EventsDocAccessFields_homePageInternalClick_Create"]: {
		permission: boolean
};
	["EventsDocAccessFields_homePageInternalClick_Read"]: {
		permission: boolean
};
	["EventsDocAccessFields_homePageInternalClick_Update"]: {
		permission: boolean
};
	["EventsDocAccessFields_homePageInternalClick_Delete"]: {
		permission: boolean
};
	["EventsDocAccessFields_homePageImpression"]: {
		create?: ModelTypes["EventsDocAccessFields_homePageImpression_Create"] | undefined,
	read?: ModelTypes["EventsDocAccessFields_homePageImpression_Read"] | undefined,
	update?: ModelTypes["EventsDocAccessFields_homePageImpression_Update"] | undefined,
	delete?: ModelTypes["EventsDocAccessFields_homePageImpression_Delete"] | undefined
};
	["EventsDocAccessFields_homePageImpression_Create"]: {
		permission: boolean
};
	["EventsDocAccessFields_homePageImpression_Read"]: {
		permission: boolean
};
	["EventsDocAccessFields_homePageImpression_Update"]: {
		permission: boolean
};
	["EventsDocAccessFields_homePageImpression_Delete"]: {
		permission: boolean
};
	["EventsDocAccessFields_eodClickRate"]: {
		create?: ModelTypes["EventsDocAccessFields_eodClickRate_Create"] | undefined,
	read?: ModelTypes["EventsDocAccessFields_eodClickRate_Read"] | undefined,
	update?: ModelTypes["EventsDocAccessFields_eodClickRate_Update"] | undefined,
	delete?: ModelTypes["EventsDocAccessFields_eodClickRate_Delete"] | undefined
};
	["EventsDocAccessFields_eodClickRate_Create"]: {
		permission: boolean
};
	["EventsDocAccessFields_eodClickRate_Read"]: {
		permission: boolean
};
	["EventsDocAccessFields_eodClickRate_Update"]: {
		permission: boolean
};
	["EventsDocAccessFields_eodClickRate_Delete"]: {
		permission: boolean
};
	["EventsDocAccessFields_eodImpressionRate"]: {
		create?: ModelTypes["EventsDocAccessFields_eodImpressionRate_Create"] | undefined,
	read?: ModelTypes["EventsDocAccessFields_eodImpressionRate_Read"] | undefined,
	update?: ModelTypes["EventsDocAccessFields_eodImpressionRate_Update"] | undefined,
	delete?: ModelTypes["EventsDocAccessFields_eodImpressionRate_Delete"] | undefined
};
	["EventsDocAccessFields_eodImpressionRate_Create"]: {
		permission: boolean
};
	["EventsDocAccessFields_eodImpressionRate_Read"]: {
		permission: boolean
};
	["EventsDocAccessFields_eodImpressionRate_Update"]: {
		permission: boolean
};
	["EventsDocAccessFields_eodImpressionRate_Delete"]: {
		permission: boolean
};
	["EventsDocAccessFields_top10ClickRateHomepage"]: {
		create?: ModelTypes["EventsDocAccessFields_top10ClickRateHomepage_Create"] | undefined,
	read?: ModelTypes["EventsDocAccessFields_top10ClickRateHomepage_Read"] | undefined,
	update?: ModelTypes["EventsDocAccessFields_top10ClickRateHomepage_Update"] | undefined,
	delete?: ModelTypes["EventsDocAccessFields_top10ClickRateHomepage_Delete"] | undefined
};
	["EventsDocAccessFields_top10ClickRateHomepage_Create"]: {
		permission: boolean
};
	["EventsDocAccessFields_top10ClickRateHomepage_Read"]: {
		permission: boolean
};
	["EventsDocAccessFields_top10ClickRateHomepage_Update"]: {
		permission: boolean
};
	["EventsDocAccessFields_top10ClickRateHomepage_Delete"]: {
		permission: boolean
};
	["EventsDocAccessFields_top10ImpressionkRateHomepage"]: {
		create?: ModelTypes["EventsDocAccessFields_top10ImpressionkRateHomepage_Create"] | undefined,
	read?: ModelTypes["EventsDocAccessFields_top10ImpressionkRateHomepage_Read"] | undefined,
	update?: ModelTypes["EventsDocAccessFields_top10ImpressionkRateHomepage_Update"] | undefined,
	delete?: ModelTypes["EventsDocAccessFields_top10ImpressionkRateHomepage_Delete"] | undefined
};
	["EventsDocAccessFields_top10ImpressionkRateHomepage_Create"]: {
		permission: boolean
};
	["EventsDocAccessFields_top10ImpressionkRateHomepage_Read"]: {
		permission: boolean
};
	["EventsDocAccessFields_top10ImpressionkRateHomepage_Update"]: {
		permission: boolean
};
	["EventsDocAccessFields_top10ImpressionkRateHomepage_Delete"]: {
		permission: boolean
};
	["EventsDocAccessFields_otherEventRankingClickRateHomepage"]: {
		create?: ModelTypes["EventsDocAccessFields_otherEventRankingClickRateHomepage_Create"] | undefined,
	read?: ModelTypes["EventsDocAccessFields_otherEventRankingClickRateHomepage_Read"] | undefined,
	update?: ModelTypes["EventsDocAccessFields_otherEventRankingClickRateHomepage_Update"] | undefined,
	delete?: ModelTypes["EventsDocAccessFields_otherEventRankingClickRateHomepage_Delete"] | undefined
};
	["EventsDocAccessFields_otherEventRankingClickRateHomepage_Create"]: {
		permission: boolean
};
	["EventsDocAccessFields_otherEventRankingClickRateHomepage_Read"]: {
		permission: boolean
};
	["EventsDocAccessFields_otherEventRankingClickRateHomepage_Update"]: {
		permission: boolean
};
	["EventsDocAccessFields_otherEventRankingClickRateHomepage_Delete"]: {
		permission: boolean
};
	["EventsDocAccessFields_otherEventRankingImpressionHomePage"]: {
		create?: ModelTypes["EventsDocAccessFields_otherEventRankingImpressionHomePage_Create"] | undefined,
	read?: ModelTypes["EventsDocAccessFields_otherEventRankingImpressionHomePage_Read"] | undefined,
	update?: ModelTypes["EventsDocAccessFields_otherEventRankingImpressionHomePage_Update"] | undefined,
	delete?: ModelTypes["EventsDocAccessFields_otherEventRankingImpressionHomePage_Delete"] | undefined
};
	["EventsDocAccessFields_otherEventRankingImpressionHomePage_Create"]: {
		permission: boolean
};
	["EventsDocAccessFields_otherEventRankingImpressionHomePage_Read"]: {
		permission: boolean
};
	["EventsDocAccessFields_otherEventRankingImpressionHomePage_Update"]: {
		permission: boolean
};
	["EventsDocAccessFields_otherEventRankingImpressionHomePage_Delete"]: {
		permission: boolean
};
	["EventsDocAccessFields_userPreferenceClickRate"]: {
		create?: ModelTypes["EventsDocAccessFields_userPreferenceClickRate_Create"] | undefined,
	read?: ModelTypes["EventsDocAccessFields_userPreferenceClickRate_Read"] | undefined,
	update?: ModelTypes["EventsDocAccessFields_userPreferenceClickRate_Update"] | undefined,
	delete?: ModelTypes["EventsDocAccessFields_userPreferenceClickRate_Delete"] | undefined
};
	["EventsDocAccessFields_userPreferenceClickRate_Create"]: {
		permission: boolean
};
	["EventsDocAccessFields_userPreferenceClickRate_Read"]: {
		permission: boolean
};
	["EventsDocAccessFields_userPreferenceClickRate_Update"]: {
		permission: boolean
};
	["EventsDocAccessFields_userPreferenceClickRate_Delete"]: {
		permission: boolean
};
	["EventsDocAccessFields_userPreferenceImpression"]: {
		create?: ModelTypes["EventsDocAccessFields_userPreferenceImpression_Create"] | undefined,
	read?: ModelTypes["EventsDocAccessFields_userPreferenceImpression_Read"] | undefined,
	update?: ModelTypes["EventsDocAccessFields_userPreferenceImpression_Update"] | undefined,
	delete?: ModelTypes["EventsDocAccessFields_userPreferenceImpression_Delete"] | undefined
};
	["EventsDocAccessFields_userPreferenceImpression_Create"]: {
		permission: boolean
};
	["EventsDocAccessFields_userPreferenceImpression_Read"]: {
		permission: boolean
};
	["EventsDocAccessFields_userPreferenceImpression_Update"]: {
		permission: boolean
};
	["EventsDocAccessFields_userPreferenceImpression_Delete"]: {
		permission: boolean
};
	["EventsDocAccessFields_eventSearchInternalClick"]: {
		create?: ModelTypes["EventsDocAccessFields_eventSearchInternalClick_Create"] | undefined,
	read?: ModelTypes["EventsDocAccessFields_eventSearchInternalClick_Read"] | undefined,
	update?: ModelTypes["EventsDocAccessFields_eventSearchInternalClick_Update"] | undefined,
	delete?: ModelTypes["EventsDocAccessFields_eventSearchInternalClick_Delete"] | undefined
};
	["EventsDocAccessFields_eventSearchInternalClick_Create"]: {
		permission: boolean
};
	["EventsDocAccessFields_eventSearchInternalClick_Read"]: {
		permission: boolean
};
	["EventsDocAccessFields_eventSearchInternalClick_Update"]: {
		permission: boolean
};
	["EventsDocAccessFields_eventSearchInternalClick_Delete"]: {
		permission: boolean
};
	["EventsDocAccessFields_eventListImpression"]: {
		create?: ModelTypes["EventsDocAccessFields_eventListImpression_Create"] | undefined,
	read?: ModelTypes["EventsDocAccessFields_eventListImpression_Read"] | undefined,
	update?: ModelTypes["EventsDocAccessFields_eventListImpression_Update"] | undefined,
	delete?: ModelTypes["EventsDocAccessFields_eventListImpression_Delete"] | undefined
};
	["EventsDocAccessFields_eventListImpression_Create"]: {
		permission: boolean
};
	["EventsDocAccessFields_eventListImpression_Read"]: {
		permission: boolean
};
	["EventsDocAccessFields_eventListImpression_Update"]: {
		permission: boolean
};
	["EventsDocAccessFields_eventListImpression_Delete"]: {
		permission: boolean
};
	["EventsDocAccessFields_latestEventInternalClick"]: {
		create?: ModelTypes["EventsDocAccessFields_latestEventInternalClick_Create"] | undefined,
	read?: ModelTypes["EventsDocAccessFields_latestEventInternalClick_Read"] | undefined,
	update?: ModelTypes["EventsDocAccessFields_latestEventInternalClick_Update"] | undefined,
	delete?: ModelTypes["EventsDocAccessFields_latestEventInternalClick_Delete"] | undefined
};
	["EventsDocAccessFields_latestEventInternalClick_Create"]: {
		permission: boolean
};
	["EventsDocAccessFields_latestEventInternalClick_Read"]: {
		permission: boolean
};
	["EventsDocAccessFields_latestEventInternalClick_Update"]: {
		permission: boolean
};
	["EventsDocAccessFields_latestEventInternalClick_Delete"]: {
		permission: boolean
};
	["EventsDocAccessFields_latestEventImpression"]: {
		create?: ModelTypes["EventsDocAccessFields_latestEventImpression_Create"] | undefined,
	read?: ModelTypes["EventsDocAccessFields_latestEventImpression_Read"] | undefined,
	update?: ModelTypes["EventsDocAccessFields_latestEventImpression_Update"] | undefined,
	delete?: ModelTypes["EventsDocAccessFields_latestEventImpression_Delete"] | undefined
};
	["EventsDocAccessFields_latestEventImpression_Create"]: {
		permission: boolean
};
	["EventsDocAccessFields_latestEventImpression_Read"]: {
		permission: boolean
};
	["EventsDocAccessFields_latestEventImpression_Update"]: {
		permission: boolean
};
	["EventsDocAccessFields_latestEventImpression_Delete"]: {
		permission: boolean
};
	["EventsDocAccessFields_topTenEventRankingInternalClick"]: {
		create?: ModelTypes["EventsDocAccessFields_topTenEventRankingInternalClick_Create"] | undefined,
	read?: ModelTypes["EventsDocAccessFields_topTenEventRankingInternalClick_Read"] | undefined,
	update?: ModelTypes["EventsDocAccessFields_topTenEventRankingInternalClick_Update"] | undefined,
	delete?: ModelTypes["EventsDocAccessFields_topTenEventRankingInternalClick_Delete"] | undefined
};
	["EventsDocAccessFields_topTenEventRankingInternalClick_Create"]: {
		permission: boolean
};
	["EventsDocAccessFields_topTenEventRankingInternalClick_Read"]: {
		permission: boolean
};
	["EventsDocAccessFields_topTenEventRankingInternalClick_Update"]: {
		permission: boolean
};
	["EventsDocAccessFields_topTenEventRankingInternalClick_Delete"]: {
		permission: boolean
};
	["EventsDocAccessFields_topTenImpression"]: {
		create?: ModelTypes["EventsDocAccessFields_topTenImpression_Create"] | undefined,
	read?: ModelTypes["EventsDocAccessFields_topTenImpression_Read"] | undefined,
	update?: ModelTypes["EventsDocAccessFields_topTenImpression_Update"] | undefined,
	delete?: ModelTypes["EventsDocAccessFields_topTenImpression_Delete"] | undefined
};
	["EventsDocAccessFields_topTenImpression_Create"]: {
		permission: boolean
};
	["EventsDocAccessFields_topTenImpression_Read"]: {
		permission: boolean
};
	["EventsDocAccessFields_topTenImpression_Update"]: {
		permission: boolean
};
	["EventsDocAccessFields_topTenImpression_Delete"]: {
		permission: boolean
};
	["EventsDocAccessFields_editorChoiceInternalClick"]: {
		create?: ModelTypes["EventsDocAccessFields_editorChoiceInternalClick_Create"] | undefined,
	read?: ModelTypes["EventsDocAccessFields_editorChoiceInternalClick_Read"] | undefined,
	update?: ModelTypes["EventsDocAccessFields_editorChoiceInternalClick_Update"] | undefined,
	delete?: ModelTypes["EventsDocAccessFields_editorChoiceInternalClick_Delete"] | undefined
};
	["EventsDocAccessFields_editorChoiceInternalClick_Create"]: {
		permission: boolean
};
	["EventsDocAccessFields_editorChoiceInternalClick_Read"]: {
		permission: boolean
};
	["EventsDocAccessFields_editorChoiceInternalClick_Update"]: {
		permission: boolean
};
	["EventsDocAccessFields_editorChoiceInternalClick_Delete"]: {
		permission: boolean
};
	["EventsDocAccessFields_editorChoiceImpression"]: {
		create?: ModelTypes["EventsDocAccessFields_editorChoiceImpression_Create"] | undefined,
	read?: ModelTypes["EventsDocAccessFields_editorChoiceImpression_Read"] | undefined,
	update?: ModelTypes["EventsDocAccessFields_editorChoiceImpression_Update"] | undefined,
	delete?: ModelTypes["EventsDocAccessFields_editorChoiceImpression_Delete"] | undefined
};
	["EventsDocAccessFields_editorChoiceImpression_Create"]: {
		permission: boolean
};
	["EventsDocAccessFields_editorChoiceImpression_Read"]: {
		permission: boolean
};
	["EventsDocAccessFields_editorChoiceImpression_Update"]: {
		permission: boolean
};
	["EventsDocAccessFields_editorChoiceImpression_Delete"]: {
		permission: boolean
};
	["EventsDocAccessFields_otherEventRankingInternalClick"]: {
		create?: ModelTypes["EventsDocAccessFields_otherEventRankingInternalClick_Create"] | undefined,
	read?: ModelTypes["EventsDocAccessFields_otherEventRankingInternalClick_Read"] | undefined,
	update?: ModelTypes["EventsDocAccessFields_otherEventRankingInternalClick_Update"] | undefined,
	delete?: ModelTypes["EventsDocAccessFields_otherEventRankingInternalClick_Delete"] | undefined
};
	["EventsDocAccessFields_otherEventRankingInternalClick_Create"]: {
		permission: boolean
};
	["EventsDocAccessFields_otherEventRankingInternalClick_Read"]: {
		permission: boolean
};
	["EventsDocAccessFields_otherEventRankingInternalClick_Update"]: {
		permission: boolean
};
	["EventsDocAccessFields_otherEventRankingInternalClick_Delete"]: {
		permission: boolean
};
	["EventsDocAccessFields_otherEventRankingImpression"]: {
		create?: ModelTypes["EventsDocAccessFields_otherEventRankingImpression_Create"] | undefined,
	read?: ModelTypes["EventsDocAccessFields_otherEventRankingImpression_Read"] | undefined,
	update?: ModelTypes["EventsDocAccessFields_otherEventRankingImpression_Update"] | undefined,
	delete?: ModelTypes["EventsDocAccessFields_otherEventRankingImpression_Delete"] | undefined
};
	["EventsDocAccessFields_otherEventRankingImpression_Create"]: {
		permission: boolean
};
	["EventsDocAccessFields_otherEventRankingImpression_Read"]: {
		permission: boolean
};
	["EventsDocAccessFields_otherEventRankingImpression_Update"]: {
		permission: boolean
};
	["EventsDocAccessFields_otherEventRankingImpression_Delete"]: {
		permission: boolean
};
	["EventsDocAccessFields_bannerInternalClick"]: {
		create?: ModelTypes["EventsDocAccessFields_bannerInternalClick_Create"] | undefined,
	read?: ModelTypes["EventsDocAccessFields_bannerInternalClick_Read"] | undefined,
	update?: ModelTypes["EventsDocAccessFields_bannerInternalClick_Update"] | undefined,
	delete?: ModelTypes["EventsDocAccessFields_bannerInternalClick_Delete"] | undefined
};
	["EventsDocAccessFields_bannerInternalClick_Create"]: {
		permission: boolean
};
	["EventsDocAccessFields_bannerInternalClick_Read"]: {
		permission: boolean
};
	["EventsDocAccessFields_bannerInternalClick_Update"]: {
		permission: boolean
};
	["EventsDocAccessFields_bannerInternalClick_Delete"]: {
		permission: boolean
};
	["EventsDocAccessFields_miscImpression"]: {
		create?: ModelTypes["EventsDocAccessFields_miscImpression_Create"] | undefined,
	read?: ModelTypes["EventsDocAccessFields_miscImpression_Read"] | undefined,
	update?: ModelTypes["EventsDocAccessFields_miscImpression_Update"] | undefined,
	delete?: ModelTypes["EventsDocAccessFields_miscImpression_Delete"] | undefined
};
	["EventsDocAccessFields_miscImpression_Create"]: {
		permission: boolean
};
	["EventsDocAccessFields_miscImpression_Read"]: {
		permission: boolean
};
	["EventsDocAccessFields_miscImpression_Update"]: {
		permission: boolean
};
	["EventsDocAccessFields_miscImpression_Delete"]: {
		permission: boolean
};
	["EventsDocAccessFields_miscInternalClick"]: {
		create?: ModelTypes["EventsDocAccessFields_miscInternalClick_Create"] | undefined,
	read?: ModelTypes["EventsDocAccessFields_miscInternalClick_Read"] | undefined,
	update?: ModelTypes["EventsDocAccessFields_miscInternalClick_Update"] | undefined,
	delete?: ModelTypes["EventsDocAccessFields_miscInternalClick_Delete"] | undefined
};
	["EventsDocAccessFields_miscInternalClick_Create"]: {
		permission: boolean
};
	["EventsDocAccessFields_miscInternalClick_Read"]: {
		permission: boolean
};
	["EventsDocAccessFields_miscInternalClick_Update"]: {
		permission: boolean
};
	["EventsDocAccessFields_miscInternalClick_Delete"]: {
		permission: boolean
};
	["EventsDocAccessFields_totalImpression"]: {
		create?: ModelTypes["EventsDocAccessFields_totalImpression_Create"] | undefined,
	read?: ModelTypes["EventsDocAccessFields_totalImpression_Read"] | undefined,
	update?: ModelTypes["EventsDocAccessFields_totalImpression_Update"] | undefined,
	delete?: ModelTypes["EventsDocAccessFields_totalImpression_Delete"] | undefined
};
	["EventsDocAccessFields_totalImpression_Create"]: {
		permission: boolean
};
	["EventsDocAccessFields_totalImpression_Read"]: {
		permission: boolean
};
	["EventsDocAccessFields_totalImpression_Update"]: {
		permission: boolean
};
	["EventsDocAccessFields_totalImpression_Delete"]: {
		permission: boolean
};
	["EventsDocAccessFields_internalClick"]: {
		create?: ModelTypes["EventsDocAccessFields_internalClick_Create"] | undefined,
	read?: ModelTypes["EventsDocAccessFields_internalClick_Read"] | undefined,
	update?: ModelTypes["EventsDocAccessFields_internalClick_Update"] | undefined,
	delete?: ModelTypes["EventsDocAccessFields_internalClick_Delete"] | undefined
};
	["EventsDocAccessFields_internalClick_Create"]: {
		permission: boolean
};
	["EventsDocAccessFields_internalClick_Read"]: {
		permission: boolean
};
	["EventsDocAccessFields_internalClick_Update"]: {
		permission: boolean
};
	["EventsDocAccessFields_internalClick_Delete"]: {
		permission: boolean
};
	["EventsDocAccessFields_outboundClick"]: {
		create?: ModelTypes["EventsDocAccessFields_outboundClick_Create"] | undefined,
	read?: ModelTypes["EventsDocAccessFields_outboundClick_Read"] | undefined,
	update?: ModelTypes["EventsDocAccessFields_outboundClick_Update"] | undefined,
	delete?: ModelTypes["EventsDocAccessFields_outboundClick_Delete"] | undefined
};
	["EventsDocAccessFields_outboundClick_Create"]: {
		permission: boolean
};
	["EventsDocAccessFields_outboundClick_Read"]: {
		permission: boolean
};
	["EventsDocAccessFields_outboundClick_Update"]: {
		permission: boolean
};
	["EventsDocAccessFields_outboundClick_Delete"]: {
		permission: boolean
};
	["EventsDocAccessFields_externalClick"]: {
		create?: ModelTypes["EventsDocAccessFields_externalClick_Create"] | undefined,
	read?: ModelTypes["EventsDocAccessFields_externalClick_Read"] | undefined,
	update?: ModelTypes["EventsDocAccessFields_externalClick_Update"] | undefined,
	delete?: ModelTypes["EventsDocAccessFields_externalClick_Delete"] | undefined
};
	["EventsDocAccessFields_externalClick_Create"]: {
		permission: boolean
};
	["EventsDocAccessFields_externalClick_Read"]: {
		permission: boolean
};
	["EventsDocAccessFields_externalClick_Update"]: {
		permission: boolean
};
	["EventsDocAccessFields_externalClick_Delete"]: {
		permission: boolean
};
	["EventsDocAccessFields__viewsCountByMonth"]: {
		create?: ModelTypes["EventsDocAccessFields__viewsCountByMonth_Create"] | undefined,
	read?: ModelTypes["EventsDocAccessFields__viewsCountByMonth_Read"] | undefined,
	update?: ModelTypes["EventsDocAccessFields__viewsCountByMonth_Update"] | undefined,
	delete?: ModelTypes["EventsDocAccessFields__viewsCountByMonth_Delete"] | undefined
};
	["EventsDocAccessFields__viewsCountByMonth_Create"]: {
		permission: boolean
};
	["EventsDocAccessFields__viewsCountByMonth_Read"]: {
		permission: boolean
};
	["EventsDocAccessFields__viewsCountByMonth_Update"]: {
		permission: boolean
};
	["EventsDocAccessFields__viewsCountByMonth_Delete"]: {
		permission: boolean
};
	["EventsDocAccessFields_products"]: {
		create?: ModelTypes["EventsDocAccessFields_products_Create"] | undefined,
	read?: ModelTypes["EventsDocAccessFields_products_Read"] | undefined,
	update?: ModelTypes["EventsDocAccessFields_products_Update"] | undefined,
	delete?: ModelTypes["EventsDocAccessFields_products_Delete"] | undefined,
	fields?: ModelTypes["EventsDocAccessFields_products_Fields"] | undefined
};
	["EventsDocAccessFields_products_Create"]: {
		permission: boolean
};
	["EventsDocAccessFields_products_Read"]: {
		permission: boolean
};
	["EventsDocAccessFields_products_Update"]: {
		permission: boolean
};
	["EventsDocAccessFields_products_Delete"]: {
		permission: boolean
};
	["EventsDocAccessFields_products_Fields"]: {
		startDateTime?: ModelTypes["EventsDocAccessFields_products_startDateTime"] | undefined,
	endDateTime?: ModelTypes["EventsDocAccessFields_products_endDateTime"] | undefined,
	fullDay?: ModelTypes["EventsDocAccessFields_products_fullDay"] | undefined,
	linkedProduct?: ModelTypes["EventsDocAccessFields_products_linkedProduct"] | undefined,
	id?: ModelTypes["EventsDocAccessFields_products_id"] | undefined
};
	["EventsDocAccessFields_products_startDateTime"]: {
		create?: ModelTypes["EventsDocAccessFields_products_startDateTime_Create"] | undefined,
	read?: ModelTypes["EventsDocAccessFields_products_startDateTime_Read"] | undefined,
	update?: ModelTypes["EventsDocAccessFields_products_startDateTime_Update"] | undefined,
	delete?: ModelTypes["EventsDocAccessFields_products_startDateTime_Delete"] | undefined
};
	["EventsDocAccessFields_products_startDateTime_Create"]: {
		permission: boolean
};
	["EventsDocAccessFields_products_startDateTime_Read"]: {
		permission: boolean
};
	["EventsDocAccessFields_products_startDateTime_Update"]: {
		permission: boolean
};
	["EventsDocAccessFields_products_startDateTime_Delete"]: {
		permission: boolean
};
	["EventsDocAccessFields_products_endDateTime"]: {
		create?: ModelTypes["EventsDocAccessFields_products_endDateTime_Create"] | undefined,
	read?: ModelTypes["EventsDocAccessFields_products_endDateTime_Read"] | undefined,
	update?: ModelTypes["EventsDocAccessFields_products_endDateTime_Update"] | undefined,
	delete?: ModelTypes["EventsDocAccessFields_products_endDateTime_Delete"] | undefined
};
	["EventsDocAccessFields_products_endDateTime_Create"]: {
		permission: boolean
};
	["EventsDocAccessFields_products_endDateTime_Read"]: {
		permission: boolean
};
	["EventsDocAccessFields_products_endDateTime_Update"]: {
		permission: boolean
};
	["EventsDocAccessFields_products_endDateTime_Delete"]: {
		permission: boolean
};
	["EventsDocAccessFields_products_fullDay"]: {
		create?: ModelTypes["EventsDocAccessFields_products_fullDay_Create"] | undefined,
	read?: ModelTypes["EventsDocAccessFields_products_fullDay_Read"] | undefined,
	update?: ModelTypes["EventsDocAccessFields_products_fullDay_Update"] | undefined,
	delete?: ModelTypes["EventsDocAccessFields_products_fullDay_Delete"] | undefined
};
	["EventsDocAccessFields_products_fullDay_Create"]: {
		permission: boolean
};
	["EventsDocAccessFields_products_fullDay_Read"]: {
		permission: boolean
};
	["EventsDocAccessFields_products_fullDay_Update"]: {
		permission: boolean
};
	["EventsDocAccessFields_products_fullDay_Delete"]: {
		permission: boolean
};
	["EventsDocAccessFields_products_linkedProduct"]: {
		create?: ModelTypes["EventsDocAccessFields_products_linkedProduct_Create"] | undefined,
	read?: ModelTypes["EventsDocAccessFields_products_linkedProduct_Read"] | undefined,
	update?: ModelTypes["EventsDocAccessFields_products_linkedProduct_Update"] | undefined,
	delete?: ModelTypes["EventsDocAccessFields_products_linkedProduct_Delete"] | undefined
};
	["EventsDocAccessFields_products_linkedProduct_Create"]: {
		permission: boolean
};
	["EventsDocAccessFields_products_linkedProduct_Read"]: {
		permission: boolean
};
	["EventsDocAccessFields_products_linkedProduct_Update"]: {
		permission: boolean
};
	["EventsDocAccessFields_products_linkedProduct_Delete"]: {
		permission: boolean
};
	["EventsDocAccessFields_products_id"]: {
		create?: ModelTypes["EventsDocAccessFields_products_id_Create"] | undefined,
	read?: ModelTypes["EventsDocAccessFields_products_id_Read"] | undefined,
	update?: ModelTypes["EventsDocAccessFields_products_id_Update"] | undefined,
	delete?: ModelTypes["EventsDocAccessFields_products_id_Delete"] | undefined
};
	["EventsDocAccessFields_products_id_Create"]: {
		permission: boolean
};
	["EventsDocAccessFields_products_id_Read"]: {
		permission: boolean
};
	["EventsDocAccessFields_products_id_Update"]: {
		permission: boolean
};
	["EventsDocAccessFields_products_id_Delete"]: {
		permission: boolean
};
	["EventsDocAccessFields_legacyGallery"]: {
		create?: ModelTypes["EventsDocAccessFields_legacyGallery_Create"] | undefined,
	read?: ModelTypes["EventsDocAccessFields_legacyGallery_Read"] | undefined,
	update?: ModelTypes["EventsDocAccessFields_legacyGallery_Update"] | undefined,
	delete?: ModelTypes["EventsDocAccessFields_legacyGallery_Delete"] | undefined
};
	["EventsDocAccessFields_legacyGallery_Create"]: {
		permission: boolean
};
	["EventsDocAccessFields_legacyGallery_Read"]: {
		permission: boolean
};
	["EventsDocAccessFields_legacyGallery_Update"]: {
		permission: boolean
};
	["EventsDocAccessFields_legacyGallery_Delete"]: {
		permission: boolean
};
	["EventsDocAccessFields_earliestStartDate"]: {
		create?: ModelTypes["EventsDocAccessFields_earliestStartDate_Create"] | undefined,
	read?: ModelTypes["EventsDocAccessFields_earliestStartDate_Read"] | undefined,
	update?: ModelTypes["EventsDocAccessFields_earliestStartDate_Update"] | undefined,
	delete?: ModelTypes["EventsDocAccessFields_earliestStartDate_Delete"] | undefined
};
	["EventsDocAccessFields_earliestStartDate_Create"]: {
		permission: boolean
};
	["EventsDocAccessFields_earliestStartDate_Read"]: {
		permission: boolean
};
	["EventsDocAccessFields_earliestStartDate_Update"]: {
		permission: boolean
};
	["EventsDocAccessFields_earliestStartDate_Delete"]: {
		permission: boolean
};
	["EventsDocAccessFields_latestEndDate"]: {
		create?: ModelTypes["EventsDocAccessFields_latestEndDate_Create"] | undefined,
	read?: ModelTypes["EventsDocAccessFields_latestEndDate_Read"] | undefined,
	update?: ModelTypes["EventsDocAccessFields_latestEndDate_Update"] | undefined,
	delete?: ModelTypes["EventsDocAccessFields_latestEndDate_Delete"] | undefined
};
	["EventsDocAccessFields_latestEndDate_Create"]: {
		permission: boolean
};
	["EventsDocAccessFields_latestEndDate_Read"]: {
		permission: boolean
};
	["EventsDocAccessFields_latestEndDate_Update"]: {
		permission: boolean
};
	["EventsDocAccessFields_latestEndDate_Delete"]: {
		permission: boolean
};
	["EventsDocAccessFields_latestStartDateTime"]: {
		create?: ModelTypes["EventsDocAccessFields_latestStartDateTime_Create"] | undefined,
	read?: ModelTypes["EventsDocAccessFields_latestStartDateTime_Read"] | undefined,
	update?: ModelTypes["EventsDocAccessFields_latestStartDateTime_Update"] | undefined,
	delete?: ModelTypes["EventsDocAccessFields_latestStartDateTime_Delete"] | undefined
};
	["EventsDocAccessFields_latestStartDateTime_Create"]: {
		permission: boolean
};
	["EventsDocAccessFields_latestStartDateTime_Read"]: {
		permission: boolean
};
	["EventsDocAccessFields_latestStartDateTime_Update"]: {
		permission: boolean
};
	["EventsDocAccessFields_latestStartDateTime_Delete"]: {
		permission: boolean
};
	["EventsDocAccessFields_hasTicketing"]: {
		create?: ModelTypes["EventsDocAccessFields_hasTicketing_Create"] | undefined,
	read?: ModelTypes["EventsDocAccessFields_hasTicketing_Read"] | undefined,
	update?: ModelTypes["EventsDocAccessFields_hasTicketing_Update"] | undefined,
	delete?: ModelTypes["EventsDocAccessFields_hasTicketing_Delete"] | undefined
};
	["EventsDocAccessFields_hasTicketing_Create"]: {
		permission: boolean
};
	["EventsDocAccessFields_hasTicketing_Read"]: {
		permission: boolean
};
	["EventsDocAccessFields_hasTicketing_Update"]: {
		permission: boolean
};
	["EventsDocAccessFields_hasTicketing_Delete"]: {
		permission: boolean
};
	["EventsDocAccessFields_hasProducts"]: {
		create?: ModelTypes["EventsDocAccessFields_hasProducts_Create"] | undefined,
	read?: ModelTypes["EventsDocAccessFields_hasProducts_Read"] | undefined,
	update?: ModelTypes["EventsDocAccessFields_hasProducts_Update"] | undefined,
	delete?: ModelTypes["EventsDocAccessFields_hasProducts_Delete"] | undefined
};
	["EventsDocAccessFields_hasProducts_Create"]: {
		permission: boolean
};
	["EventsDocAccessFields_hasProducts_Read"]: {
		permission: boolean
};
	["EventsDocAccessFields_hasProducts_Update"]: {
		permission: boolean
};
	["EventsDocAccessFields_hasProducts_Delete"]: {
		permission: boolean
};
	["EventsDocAccessFields_updatedAt"]: {
		create?: ModelTypes["EventsDocAccessFields_updatedAt_Create"] | undefined,
	read?: ModelTypes["EventsDocAccessFields_updatedAt_Read"] | undefined,
	update?: ModelTypes["EventsDocAccessFields_updatedAt_Update"] | undefined,
	delete?: ModelTypes["EventsDocAccessFields_updatedAt_Delete"] | undefined
};
	["EventsDocAccessFields_updatedAt_Create"]: {
		permission: boolean
};
	["EventsDocAccessFields_updatedAt_Read"]: {
		permission: boolean
};
	["EventsDocAccessFields_updatedAt_Update"]: {
		permission: boolean
};
	["EventsDocAccessFields_updatedAt_Delete"]: {
		permission: boolean
};
	["EventsDocAccessFields_createdAt"]: {
		create?: ModelTypes["EventsDocAccessFields_createdAt_Create"] | undefined,
	read?: ModelTypes["EventsDocAccessFields_createdAt_Read"] | undefined,
	update?: ModelTypes["EventsDocAccessFields_createdAt_Update"] | undefined,
	delete?: ModelTypes["EventsDocAccessFields_createdAt_Delete"] | undefined
};
	["EventsDocAccessFields_createdAt_Create"]: {
		permission: boolean
};
	["EventsDocAccessFields_createdAt_Read"]: {
		permission: boolean
};
	["EventsDocAccessFields_createdAt_Update"]: {
		permission: boolean
};
	["EventsDocAccessFields_createdAt_Delete"]: {
		permission: boolean
};
	["EventsCreateDocAccess"]: {
		permission: boolean,
	where?: ModelTypes["JSONObject"] | undefined
};
	/** The `JSONObject` scalar type represents JSON objects as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
["JSONObject"]:any;
	["EventsReadDocAccess"]: {
		permission: boolean,
	where?: ModelTypes["JSONObject"] | undefined
};
	["EventsUpdateDocAccess"]: {
		permission: boolean,
	where?: ModelTypes["JSONObject"] | undefined
};
	["EventsDeleteDocAccess"]: {
		permission: boolean,
	where?: ModelTypes["JSONObject"] | undefined
};
	["Locations"]: {
		docs?: Array<ModelTypes["Location"] | undefined> | undefined,
	hasNextPage?: boolean | undefined,
	hasPrevPage?: boolean | undefined,
	limit?: number | undefined,
	nextPage?: number | undefined,
	offset?: number | undefined,
	page?: number | undefined,
	pagingCounter?: number | undefined,
	prevPage?: number | undefined,
	totalDocs?: number | undefined,
	totalPages?: number | undefined
};
	["Location_where"]: {
	region?: ModelTypes["Location_region_operator"] | undefined,
	creator?: ModelTypes["Location_creator_operator"] | undefined,
	permalink?: ModelTypes["Location_permalink_operator"] | undefined,
	status?: ModelTypes["Location_status_operator"] | undefined,
	level?: ModelTypes["Location_level_operator"] | undefined,
	parent?: ModelTypes["Location_parent_operator"] | undefined,
	name?: ModelTypes["Location_name_operator"] | undefined,
	thumbnail?: ModelTypes["Location_thumbnail_operator"] | undefined,
	banner?: ModelTypes["Location_banner_operator"] | undefined,
	type?: ModelTypes["Location_type_operator"] | undefined,
	category?: ModelTypes["Location_category_operator"] | undefined,
	district?: ModelTypes["Location_district_operator"] | undefined,
	coordinate?: ModelTypes["Location_coordinate_operator"] | undefined,
	linkAddress?: ModelTypes["Location_linkAddress_operator"] | undefined,
	address?: ModelTypes["Location_address_operator"] | undefined,
	Details__code?: ModelTypes["Location_Details__code_operator"] | undefined,
	Details__phone?: ModelTypes["Location_Details__phone_operator"] | undefined,
	Details__page?: ModelTypes["Location_Details__page_operator"] | undefined,
	Details__email?: ModelTypes["Location_Details__email_operator"] | undefined,
	legacyContent?: ModelTypes["Location_legacyContent_operator"] | undefined,
	showLegacyContent?: ModelTypes["Location_showLegacyContent_operator"] | undefined,
	criteria__audiences?: ModelTypes["Location_criteria__audiences_operator"] | undefined,
	criteria__dates?: ModelTypes["Location_criteria__dates_operator"] | undefined,
	criteria__categories?: ModelTypes["Location_criteria__categories_operator"] | undefined,
	criteria__tags?: ModelTypes["Location_criteria__tags_operator"] | undefined,
	sections__startDatetime?: ModelTypes["Location_sections__startDatetime_operator"] | undefined,
	sections__endDatetime?: ModelTypes["Location_sections__endDatetime_operator"] | undefined,
	sections__fullDay?: ModelTypes["Location_sections__fullDay_operator"] | undefined,
	sections__repeat?: ModelTypes["Location_sections__repeat_operator"] | undefined,
	sections__recurrance__type?: ModelTypes["Location_sections__recurrance__type_operator"] | undefined,
	sections__recurrance__weekday?: ModelTypes["Location_sections__recurrance__weekday_operator"] | undefined,
	sections__id?: ModelTypes["Location_sections__id_operator"] | undefined,
	_title?: ModelTypes["Location__title_operator"] | undefined,
	legacyGallery?: ModelTypes["Location_legacyGallery_operator"] | undefined,
	updatedAt?: ModelTypes["Location_updatedAt_operator"] | undefined,
	createdAt?: ModelTypes["Location_createdAt_operator"] | undefined,
	id?: ModelTypes["Location_id_operator"] | undefined,
	AND?: Array<ModelTypes["Location_where_and"] | undefined> | undefined,
	OR?: Array<ModelTypes["Location_where_or"] | undefined> | undefined
};
	["Location_region_operator"]: {
	equals?: ModelTypes["Location_region_Input"] | undefined,
	not_equals?: ModelTypes["Location_region_Input"] | undefined,
	in?: Array<ModelTypes["Location_region_Input"] | undefined> | undefined,
	not_in?: Array<ModelTypes["Location_region_Input"] | undefined> | undefined,
	all?: Array<ModelTypes["Location_region_Input"] | undefined> | undefined,
	exists?: boolean | undefined
};
	["Location_region_Input"]:Location_region_Input;
	["Location_creator_operator"]: {
	equals?: ModelTypes["JSON"] | undefined,
	not_equals?: ModelTypes["JSON"] | undefined,
	in?: Array<ModelTypes["JSON"] | undefined> | undefined,
	not_in?: Array<ModelTypes["JSON"] | undefined> | undefined,
	all?: Array<ModelTypes["JSON"] | undefined> | undefined,
	exists?: boolean | undefined
};
	["Location_permalink_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Location_status_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined
};
	["Location_level_operator"]: {
	equals?: ModelTypes["Location_level_Input"] | undefined,
	not_equals?: ModelTypes["Location_level_Input"] | undefined,
	in?: Array<ModelTypes["Location_level_Input"] | undefined> | undefined,
	not_in?: Array<ModelTypes["Location_level_Input"] | undefined> | undefined,
	all?: Array<ModelTypes["Location_level_Input"] | undefined> | undefined
};
	["Location_level_Input"]:Location_level_Input;
	["Location_parent_operator"]: {
	equals?: ModelTypes["JSON"] | undefined,
	not_equals?: ModelTypes["JSON"] | undefined,
	in?: Array<ModelTypes["JSON"] | undefined> | undefined,
	not_in?: Array<ModelTypes["JSON"] | undefined> | undefined,
	all?: Array<ModelTypes["JSON"] | undefined> | undefined,
	exists?: boolean | undefined
};
	["Location_name_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined
};
	["Location_thumbnail_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	exists?: boolean | undefined
};
	["Location_banner_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	exists?: boolean | undefined
};
	["Location_type_operator"]: {
	equals?: ModelTypes["Location_type_Input"] | undefined,
	not_equals?: ModelTypes["Location_type_Input"] | undefined,
	in?: Array<ModelTypes["Location_type_Input"] | undefined> | undefined,
	not_in?: Array<ModelTypes["Location_type_Input"] | undefined> | undefined,
	all?: Array<ModelTypes["Location_type_Input"] | undefined> | undefined
};
	["Location_type_Input"]:Location_type_Input;
	["Location_category_operator"]: {
	equals?: ModelTypes["Location_category_Input"] | undefined,
	not_equals?: ModelTypes["Location_category_Input"] | undefined,
	in?: Array<ModelTypes["Location_category_Input"] | undefined> | undefined,
	not_in?: Array<ModelTypes["Location_category_Input"] | undefined> | undefined,
	all?: Array<ModelTypes["Location_category_Input"] | undefined> | undefined
};
	["Location_category_Input"]:Location_category_Input;
	["Location_district_operator"]: {
	equals?: ModelTypes["JSON"] | undefined,
	not_equals?: ModelTypes["JSON"] | undefined,
	in?: Array<ModelTypes["JSON"] | undefined> | undefined,
	not_in?: Array<ModelTypes["JSON"] | undefined> | undefined,
	all?: Array<ModelTypes["JSON"] | undefined> | undefined,
	exists?: boolean | undefined
};
	["Location_coordinate_operator"]: {
	equals?: Array<number | undefined> | undefined,
	not_equals?: Array<number | undefined> | undefined,
	greater_than_equal?: Array<number | undefined> | undefined,
	greater_than?: Array<number | undefined> | undefined,
	less_than_equal?: Array<number | undefined> | undefined,
	less_than?: Array<number | undefined> | undefined,
	near?: Array<number | undefined> | undefined,
	within?: ModelTypes["GeoJSONObject"] | undefined,
	intersects?: ModelTypes["GeoJSONObject"] | undefined,
	exists?: boolean | undefined
};
	["Location_linkAddress_operator"]: {
	equals?: boolean | undefined,
	not_equals?: boolean | undefined,
	exists?: boolean | undefined
};
	["Location_address_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Location_Details__code_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Location_Details__phone_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Location_Details__page_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Location_Details__email_operator"]: {
	equals?: ModelTypes["EmailAddress"] | undefined,
	not_equals?: ModelTypes["EmailAddress"] | undefined,
	like?: ModelTypes["EmailAddress"] | undefined,
	contains?: ModelTypes["EmailAddress"] | undefined,
	in?: Array<ModelTypes["EmailAddress"] | undefined> | undefined,
	not_in?: Array<ModelTypes["EmailAddress"] | undefined> | undefined,
	all?: Array<ModelTypes["EmailAddress"] | undefined> | undefined,
	exists?: boolean | undefined
};
	["Location_legacyContent_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	exists?: boolean | undefined
};
	["Location_showLegacyContent_operator"]: {
	equals?: boolean | undefined,
	not_equals?: boolean | undefined,
	exists?: boolean | undefined
};
	["Location_criteria__audiences_operator"]: {
	equals?: ModelTypes["JSON"] | undefined,
	not_equals?: ModelTypes["JSON"] | undefined,
	in?: Array<ModelTypes["JSON"] | undefined> | undefined,
	not_in?: Array<ModelTypes["JSON"] | undefined> | undefined,
	all?: Array<ModelTypes["JSON"] | undefined> | undefined,
	exists?: boolean | undefined
};
	["Location_criteria__dates_operator"]: {
	equals?: ModelTypes["JSON"] | undefined,
	not_equals?: ModelTypes["JSON"] | undefined,
	in?: Array<ModelTypes["JSON"] | undefined> | undefined,
	not_in?: Array<ModelTypes["JSON"] | undefined> | undefined,
	all?: Array<ModelTypes["JSON"] | undefined> | undefined,
	exists?: boolean | undefined
};
	["Location_criteria__categories_operator"]: {
	equals?: ModelTypes["JSON"] | undefined,
	not_equals?: ModelTypes["JSON"] | undefined,
	in?: Array<ModelTypes["JSON"] | undefined> | undefined,
	not_in?: Array<ModelTypes["JSON"] | undefined> | undefined,
	all?: Array<ModelTypes["JSON"] | undefined> | undefined,
	exists?: boolean | undefined
};
	["Location_criteria__tags_operator"]: {
	equals?: ModelTypes["JSON"] | undefined,
	not_equals?: ModelTypes["JSON"] | undefined,
	in?: Array<ModelTypes["JSON"] | undefined> | undefined,
	not_in?: Array<ModelTypes["JSON"] | undefined> | undefined,
	all?: Array<ModelTypes["JSON"] | undefined> | undefined,
	exists?: boolean | undefined
};
	["Location_sections__startDatetime_operator"]: {
	equals?: ModelTypes["DateTime"] | undefined,
	not_equals?: ModelTypes["DateTime"] | undefined,
	greater_than_equal?: ModelTypes["DateTime"] | undefined,
	greater_than?: ModelTypes["DateTime"] | undefined,
	less_than_equal?: ModelTypes["DateTime"] | undefined,
	less_than?: ModelTypes["DateTime"] | undefined,
	like?: ModelTypes["DateTime"] | undefined
};
	["Location_sections__endDatetime_operator"]: {
	equals?: ModelTypes["DateTime"] | undefined,
	not_equals?: ModelTypes["DateTime"] | undefined,
	greater_than_equal?: ModelTypes["DateTime"] | undefined,
	greater_than?: ModelTypes["DateTime"] | undefined,
	less_than_equal?: ModelTypes["DateTime"] | undefined,
	less_than?: ModelTypes["DateTime"] | undefined,
	like?: ModelTypes["DateTime"] | undefined
};
	["Location_sections__fullDay_operator"]: {
	equals?: boolean | undefined,
	not_equals?: boolean | undefined,
	exists?: boolean | undefined
};
	["Location_sections__repeat_operator"]: {
	equals?: boolean | undefined,
	not_equals?: boolean | undefined,
	exists?: boolean | undefined
};
	["Location_sections__recurrance__type_operator"]: {
	equals?: ModelTypes["Location_sections__recurrance__type_Input"] | undefined,
	not_equals?: ModelTypes["Location_sections__recurrance__type_Input"] | undefined,
	in?: Array<ModelTypes["Location_sections__recurrance__type_Input"] | undefined> | undefined,
	not_in?: Array<ModelTypes["Location_sections__recurrance__type_Input"] | undefined> | undefined,
	all?: Array<ModelTypes["Location_sections__recurrance__type_Input"] | undefined> | undefined
};
	["Location_sections__recurrance__type_Input"]:Location_sections__recurrance__type_Input;
	["Location_sections__recurrance__weekday_operator"]: {
	equals?: ModelTypes["Location_sections__recurrance__weekday_Input"] | undefined,
	not_equals?: ModelTypes["Location_sections__recurrance__weekday_Input"] | undefined,
	in?: Array<ModelTypes["Location_sections__recurrance__weekday_Input"] | undefined> | undefined,
	not_in?: Array<ModelTypes["Location_sections__recurrance__weekday_Input"] | undefined> | undefined,
	all?: Array<ModelTypes["Location_sections__recurrance__weekday_Input"] | undefined> | undefined
};
	["Location_sections__recurrance__weekday_Input"]:Location_sections__recurrance__weekday_Input;
	["Location_sections__id_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Location__title_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Location_legacyGallery_operator"]: {
	equals?: ModelTypes["JSON"] | undefined,
	not_equals?: ModelTypes["JSON"] | undefined,
	in?: Array<ModelTypes["JSON"] | undefined> | undefined,
	not_in?: Array<ModelTypes["JSON"] | undefined> | undefined,
	all?: Array<ModelTypes["JSON"] | undefined> | undefined,
	exists?: boolean | undefined
};
	["Location_updatedAt_operator"]: {
	equals?: ModelTypes["DateTime"] | undefined,
	not_equals?: ModelTypes["DateTime"] | undefined,
	greater_than_equal?: ModelTypes["DateTime"] | undefined,
	greater_than?: ModelTypes["DateTime"] | undefined,
	less_than_equal?: ModelTypes["DateTime"] | undefined,
	less_than?: ModelTypes["DateTime"] | undefined,
	like?: ModelTypes["DateTime"] | undefined,
	exists?: boolean | undefined
};
	["Location_createdAt_operator"]: {
	equals?: ModelTypes["DateTime"] | undefined,
	not_equals?: ModelTypes["DateTime"] | undefined,
	greater_than_equal?: ModelTypes["DateTime"] | undefined,
	greater_than?: ModelTypes["DateTime"] | undefined,
	less_than_equal?: ModelTypes["DateTime"] | undefined,
	less_than?: ModelTypes["DateTime"] | undefined,
	like?: ModelTypes["DateTime"] | undefined,
	exists?: boolean | undefined
};
	["Location_id_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Location_where_and"]: {
	region?: ModelTypes["Location_region_operator"] | undefined,
	creator?: ModelTypes["Location_creator_operator"] | undefined,
	permalink?: ModelTypes["Location_permalink_operator"] | undefined,
	status?: ModelTypes["Location_status_operator"] | undefined,
	level?: ModelTypes["Location_level_operator"] | undefined,
	parent?: ModelTypes["Location_parent_operator"] | undefined,
	name?: ModelTypes["Location_name_operator"] | undefined,
	thumbnail?: ModelTypes["Location_thumbnail_operator"] | undefined,
	banner?: ModelTypes["Location_banner_operator"] | undefined,
	type?: ModelTypes["Location_type_operator"] | undefined,
	category?: ModelTypes["Location_category_operator"] | undefined,
	district?: ModelTypes["Location_district_operator"] | undefined,
	coordinate?: ModelTypes["Location_coordinate_operator"] | undefined,
	linkAddress?: ModelTypes["Location_linkAddress_operator"] | undefined,
	address?: ModelTypes["Location_address_operator"] | undefined,
	Details__code?: ModelTypes["Location_Details__code_operator"] | undefined,
	Details__phone?: ModelTypes["Location_Details__phone_operator"] | undefined,
	Details__page?: ModelTypes["Location_Details__page_operator"] | undefined,
	Details__email?: ModelTypes["Location_Details__email_operator"] | undefined,
	legacyContent?: ModelTypes["Location_legacyContent_operator"] | undefined,
	showLegacyContent?: ModelTypes["Location_showLegacyContent_operator"] | undefined,
	criteria__audiences?: ModelTypes["Location_criteria__audiences_operator"] | undefined,
	criteria__dates?: ModelTypes["Location_criteria__dates_operator"] | undefined,
	criteria__categories?: ModelTypes["Location_criteria__categories_operator"] | undefined,
	criteria__tags?: ModelTypes["Location_criteria__tags_operator"] | undefined,
	sections__startDatetime?: ModelTypes["Location_sections__startDatetime_operator"] | undefined,
	sections__endDatetime?: ModelTypes["Location_sections__endDatetime_operator"] | undefined,
	sections__fullDay?: ModelTypes["Location_sections__fullDay_operator"] | undefined,
	sections__repeat?: ModelTypes["Location_sections__repeat_operator"] | undefined,
	sections__recurrance__type?: ModelTypes["Location_sections__recurrance__type_operator"] | undefined,
	sections__recurrance__weekday?: ModelTypes["Location_sections__recurrance__weekday_operator"] | undefined,
	sections__id?: ModelTypes["Location_sections__id_operator"] | undefined,
	_title?: ModelTypes["Location__title_operator"] | undefined,
	legacyGallery?: ModelTypes["Location_legacyGallery_operator"] | undefined,
	updatedAt?: ModelTypes["Location_updatedAt_operator"] | undefined,
	createdAt?: ModelTypes["Location_createdAt_operator"] | undefined,
	id?: ModelTypes["Location_id_operator"] | undefined,
	AND?: Array<ModelTypes["Location_where_and"] | undefined> | undefined,
	OR?: Array<ModelTypes["Location_where_or"] | undefined> | undefined
};
	["Location_where_or"]: {
	region?: ModelTypes["Location_region_operator"] | undefined,
	creator?: ModelTypes["Location_creator_operator"] | undefined,
	permalink?: ModelTypes["Location_permalink_operator"] | undefined,
	status?: ModelTypes["Location_status_operator"] | undefined,
	level?: ModelTypes["Location_level_operator"] | undefined,
	parent?: ModelTypes["Location_parent_operator"] | undefined,
	name?: ModelTypes["Location_name_operator"] | undefined,
	thumbnail?: ModelTypes["Location_thumbnail_operator"] | undefined,
	banner?: ModelTypes["Location_banner_operator"] | undefined,
	type?: ModelTypes["Location_type_operator"] | undefined,
	category?: ModelTypes["Location_category_operator"] | undefined,
	district?: ModelTypes["Location_district_operator"] | undefined,
	coordinate?: ModelTypes["Location_coordinate_operator"] | undefined,
	linkAddress?: ModelTypes["Location_linkAddress_operator"] | undefined,
	address?: ModelTypes["Location_address_operator"] | undefined,
	Details__code?: ModelTypes["Location_Details__code_operator"] | undefined,
	Details__phone?: ModelTypes["Location_Details__phone_operator"] | undefined,
	Details__page?: ModelTypes["Location_Details__page_operator"] | undefined,
	Details__email?: ModelTypes["Location_Details__email_operator"] | undefined,
	legacyContent?: ModelTypes["Location_legacyContent_operator"] | undefined,
	showLegacyContent?: ModelTypes["Location_showLegacyContent_operator"] | undefined,
	criteria__audiences?: ModelTypes["Location_criteria__audiences_operator"] | undefined,
	criteria__dates?: ModelTypes["Location_criteria__dates_operator"] | undefined,
	criteria__categories?: ModelTypes["Location_criteria__categories_operator"] | undefined,
	criteria__tags?: ModelTypes["Location_criteria__tags_operator"] | undefined,
	sections__startDatetime?: ModelTypes["Location_sections__startDatetime_operator"] | undefined,
	sections__endDatetime?: ModelTypes["Location_sections__endDatetime_operator"] | undefined,
	sections__fullDay?: ModelTypes["Location_sections__fullDay_operator"] | undefined,
	sections__repeat?: ModelTypes["Location_sections__repeat_operator"] | undefined,
	sections__recurrance__type?: ModelTypes["Location_sections__recurrance__type_operator"] | undefined,
	sections__recurrance__weekday?: ModelTypes["Location_sections__recurrance__weekday_operator"] | undefined,
	sections__id?: ModelTypes["Location_sections__id_operator"] | undefined,
	_title?: ModelTypes["Location__title_operator"] | undefined,
	legacyGallery?: ModelTypes["Location_legacyGallery_operator"] | undefined,
	updatedAt?: ModelTypes["Location_updatedAt_operator"] | undefined,
	createdAt?: ModelTypes["Location_createdAt_operator"] | undefined,
	id?: ModelTypes["Location_id_operator"] | undefined,
	AND?: Array<ModelTypes["Location_where_and"] | undefined> | undefined,
	OR?: Array<ModelTypes["Location_where_or"] | undefined> | undefined
};
	["countLocations"]: {
		totalDocs?: number | undefined
};
	["locationsDocAccess"]: {
		fields?: ModelTypes["LocationsDocAccessFields"] | undefined,
	create?: ModelTypes["LocationsCreateDocAccess"] | undefined,
	read?: ModelTypes["LocationsReadDocAccess"] | undefined,
	update?: ModelTypes["LocationsUpdateDocAccess"] | undefined,
	delete?: ModelTypes["LocationsDeleteDocAccess"] | undefined
};
	["LocationsDocAccessFields"]: {
		region?: ModelTypes["LocationsDocAccessFields_region"] | undefined,
	creator?: ModelTypes["LocationsDocAccessFields_creator"] | undefined,
	permalink?: ModelTypes["LocationsDocAccessFields_permalink"] | undefined,
	status?: ModelTypes["LocationsDocAccessFields_status"] | undefined,
	level?: ModelTypes["LocationsDocAccessFields_level"] | undefined,
	parent?: ModelTypes["LocationsDocAccessFields_parent"] | undefined,
	name?: ModelTypes["LocationsDocAccessFields_name"] | undefined,
	thumbnail?: ModelTypes["LocationsDocAccessFields_thumbnail"] | undefined,
	banner?: ModelTypes["LocationsDocAccessFields_banner"] | undefined,
	type?: ModelTypes["LocationsDocAccessFields_type"] | undefined,
	category?: ModelTypes["LocationsDocAccessFields_category"] | undefined,
	district?: ModelTypes["LocationsDocAccessFields_district"] | undefined,
	coordinate?: ModelTypes["LocationsDocAccessFields_coordinate"] | undefined,
	linkAddress?: ModelTypes["LocationsDocAccessFields_linkAddress"] | undefined,
	address?: ModelTypes["LocationsDocAccessFields_address"] | undefined,
	Details?: ModelTypes["LocationsDocAccessFields_Details"] | undefined,
	content?: ModelTypes["LocationsDocAccessFields_content"] | undefined,
	legacyContent?: ModelTypes["LocationsDocAccessFields_legacyContent"] | undefined,
	showLegacyContent?: ModelTypes["LocationsDocAccessFields_showLegacyContent"] | undefined,
	criteria?: ModelTypes["LocationsDocAccessFields_criteria"] | undefined,
	sections?: ModelTypes["LocationsDocAccessFields_sections"] | undefined,
	_title?: ModelTypes["LocationsDocAccessFields__title"] | undefined,
	legacyGallery?: ModelTypes["LocationsDocAccessFields_legacyGallery"] | undefined,
	updatedAt?: ModelTypes["LocationsDocAccessFields_updatedAt"] | undefined,
	createdAt?: ModelTypes["LocationsDocAccessFields_createdAt"] | undefined
};
	["LocationsDocAccessFields_region"]: {
		create?: ModelTypes["LocationsDocAccessFields_region_Create"] | undefined,
	read?: ModelTypes["LocationsDocAccessFields_region_Read"] | undefined,
	update?: ModelTypes["LocationsDocAccessFields_region_Update"] | undefined,
	delete?: ModelTypes["LocationsDocAccessFields_region_Delete"] | undefined
};
	["LocationsDocAccessFields_region_Create"]: {
		permission: boolean
};
	["LocationsDocAccessFields_region_Read"]: {
		permission: boolean
};
	["LocationsDocAccessFields_region_Update"]: {
		permission: boolean
};
	["LocationsDocAccessFields_region_Delete"]: {
		permission: boolean
};
	["LocationsDocAccessFields_creator"]: {
		create?: ModelTypes["LocationsDocAccessFields_creator_Create"] | undefined,
	read?: ModelTypes["LocationsDocAccessFields_creator_Read"] | undefined,
	update?: ModelTypes["LocationsDocAccessFields_creator_Update"] | undefined,
	delete?: ModelTypes["LocationsDocAccessFields_creator_Delete"] | undefined
};
	["LocationsDocAccessFields_creator_Create"]: {
		permission: boolean
};
	["LocationsDocAccessFields_creator_Read"]: {
		permission: boolean
};
	["LocationsDocAccessFields_creator_Update"]: {
		permission: boolean
};
	["LocationsDocAccessFields_creator_Delete"]: {
		permission: boolean
};
	["LocationsDocAccessFields_permalink"]: {
		create?: ModelTypes["LocationsDocAccessFields_permalink_Create"] | undefined,
	read?: ModelTypes["LocationsDocAccessFields_permalink_Read"] | undefined,
	update?: ModelTypes["LocationsDocAccessFields_permalink_Update"] | undefined,
	delete?: ModelTypes["LocationsDocAccessFields_permalink_Delete"] | undefined
};
	["LocationsDocAccessFields_permalink_Create"]: {
		permission: boolean
};
	["LocationsDocAccessFields_permalink_Read"]: {
		permission: boolean
};
	["LocationsDocAccessFields_permalink_Update"]: {
		permission: boolean
};
	["LocationsDocAccessFields_permalink_Delete"]: {
		permission: boolean
};
	["LocationsDocAccessFields_status"]: {
		create?: ModelTypes["LocationsDocAccessFields_status_Create"] | undefined,
	read?: ModelTypes["LocationsDocAccessFields_status_Read"] | undefined,
	update?: ModelTypes["LocationsDocAccessFields_status_Update"] | undefined,
	delete?: ModelTypes["LocationsDocAccessFields_status_Delete"] | undefined
};
	["LocationsDocAccessFields_status_Create"]: {
		permission: boolean
};
	["LocationsDocAccessFields_status_Read"]: {
		permission: boolean
};
	["LocationsDocAccessFields_status_Update"]: {
		permission: boolean
};
	["LocationsDocAccessFields_status_Delete"]: {
		permission: boolean
};
	["LocationsDocAccessFields_level"]: {
		create?: ModelTypes["LocationsDocAccessFields_level_Create"] | undefined,
	read?: ModelTypes["LocationsDocAccessFields_level_Read"] | undefined,
	update?: ModelTypes["LocationsDocAccessFields_level_Update"] | undefined,
	delete?: ModelTypes["LocationsDocAccessFields_level_Delete"] | undefined
};
	["LocationsDocAccessFields_level_Create"]: {
		permission: boolean
};
	["LocationsDocAccessFields_level_Read"]: {
		permission: boolean
};
	["LocationsDocAccessFields_level_Update"]: {
		permission: boolean
};
	["LocationsDocAccessFields_level_Delete"]: {
		permission: boolean
};
	["LocationsDocAccessFields_parent"]: {
		create?: ModelTypes["LocationsDocAccessFields_parent_Create"] | undefined,
	read?: ModelTypes["LocationsDocAccessFields_parent_Read"] | undefined,
	update?: ModelTypes["LocationsDocAccessFields_parent_Update"] | undefined,
	delete?: ModelTypes["LocationsDocAccessFields_parent_Delete"] | undefined
};
	["LocationsDocAccessFields_parent_Create"]: {
		permission: boolean
};
	["LocationsDocAccessFields_parent_Read"]: {
		permission: boolean
};
	["LocationsDocAccessFields_parent_Update"]: {
		permission: boolean
};
	["LocationsDocAccessFields_parent_Delete"]: {
		permission: boolean
};
	["LocationsDocAccessFields_name"]: {
		create?: ModelTypes["LocationsDocAccessFields_name_Create"] | undefined,
	read?: ModelTypes["LocationsDocAccessFields_name_Read"] | undefined,
	update?: ModelTypes["LocationsDocAccessFields_name_Update"] | undefined,
	delete?: ModelTypes["LocationsDocAccessFields_name_Delete"] | undefined
};
	["LocationsDocAccessFields_name_Create"]: {
		permission: boolean
};
	["LocationsDocAccessFields_name_Read"]: {
		permission: boolean
};
	["LocationsDocAccessFields_name_Update"]: {
		permission: boolean
};
	["LocationsDocAccessFields_name_Delete"]: {
		permission: boolean
};
	["LocationsDocAccessFields_thumbnail"]: {
		create?: ModelTypes["LocationsDocAccessFields_thumbnail_Create"] | undefined,
	read?: ModelTypes["LocationsDocAccessFields_thumbnail_Read"] | undefined,
	update?: ModelTypes["LocationsDocAccessFields_thumbnail_Update"] | undefined,
	delete?: ModelTypes["LocationsDocAccessFields_thumbnail_Delete"] | undefined
};
	["LocationsDocAccessFields_thumbnail_Create"]: {
		permission: boolean
};
	["LocationsDocAccessFields_thumbnail_Read"]: {
		permission: boolean
};
	["LocationsDocAccessFields_thumbnail_Update"]: {
		permission: boolean
};
	["LocationsDocAccessFields_thumbnail_Delete"]: {
		permission: boolean
};
	["LocationsDocAccessFields_banner"]: {
		create?: ModelTypes["LocationsDocAccessFields_banner_Create"] | undefined,
	read?: ModelTypes["LocationsDocAccessFields_banner_Read"] | undefined,
	update?: ModelTypes["LocationsDocAccessFields_banner_Update"] | undefined,
	delete?: ModelTypes["LocationsDocAccessFields_banner_Delete"] | undefined
};
	["LocationsDocAccessFields_banner_Create"]: {
		permission: boolean
};
	["LocationsDocAccessFields_banner_Read"]: {
		permission: boolean
};
	["LocationsDocAccessFields_banner_Update"]: {
		permission: boolean
};
	["LocationsDocAccessFields_banner_Delete"]: {
		permission: boolean
};
	["LocationsDocAccessFields_type"]: {
		create?: ModelTypes["LocationsDocAccessFields_type_Create"] | undefined,
	read?: ModelTypes["LocationsDocAccessFields_type_Read"] | undefined,
	update?: ModelTypes["LocationsDocAccessFields_type_Update"] | undefined,
	delete?: ModelTypes["LocationsDocAccessFields_type_Delete"] | undefined
};
	["LocationsDocAccessFields_type_Create"]: {
		permission: boolean
};
	["LocationsDocAccessFields_type_Read"]: {
		permission: boolean
};
	["LocationsDocAccessFields_type_Update"]: {
		permission: boolean
};
	["LocationsDocAccessFields_type_Delete"]: {
		permission: boolean
};
	["LocationsDocAccessFields_category"]: {
		create?: ModelTypes["LocationsDocAccessFields_category_Create"] | undefined,
	read?: ModelTypes["LocationsDocAccessFields_category_Read"] | undefined,
	update?: ModelTypes["LocationsDocAccessFields_category_Update"] | undefined,
	delete?: ModelTypes["LocationsDocAccessFields_category_Delete"] | undefined
};
	["LocationsDocAccessFields_category_Create"]: {
		permission: boolean
};
	["LocationsDocAccessFields_category_Read"]: {
		permission: boolean
};
	["LocationsDocAccessFields_category_Update"]: {
		permission: boolean
};
	["LocationsDocAccessFields_category_Delete"]: {
		permission: boolean
};
	["LocationsDocAccessFields_district"]: {
		create?: ModelTypes["LocationsDocAccessFields_district_Create"] | undefined,
	read?: ModelTypes["LocationsDocAccessFields_district_Read"] | undefined,
	update?: ModelTypes["LocationsDocAccessFields_district_Update"] | undefined,
	delete?: ModelTypes["LocationsDocAccessFields_district_Delete"] | undefined
};
	["LocationsDocAccessFields_district_Create"]: {
		permission: boolean
};
	["LocationsDocAccessFields_district_Read"]: {
		permission: boolean
};
	["LocationsDocAccessFields_district_Update"]: {
		permission: boolean
};
	["LocationsDocAccessFields_district_Delete"]: {
		permission: boolean
};
	["LocationsDocAccessFields_coordinate"]: {
		create?: ModelTypes["LocationsDocAccessFields_coordinate_Create"] | undefined,
	read?: ModelTypes["LocationsDocAccessFields_coordinate_Read"] | undefined,
	update?: ModelTypes["LocationsDocAccessFields_coordinate_Update"] | undefined,
	delete?: ModelTypes["LocationsDocAccessFields_coordinate_Delete"] | undefined
};
	["LocationsDocAccessFields_coordinate_Create"]: {
		permission: boolean
};
	["LocationsDocAccessFields_coordinate_Read"]: {
		permission: boolean
};
	["LocationsDocAccessFields_coordinate_Update"]: {
		permission: boolean
};
	["LocationsDocAccessFields_coordinate_Delete"]: {
		permission: boolean
};
	["LocationsDocAccessFields_linkAddress"]: {
		create?: ModelTypes["LocationsDocAccessFields_linkAddress_Create"] | undefined,
	read?: ModelTypes["LocationsDocAccessFields_linkAddress_Read"] | undefined,
	update?: ModelTypes["LocationsDocAccessFields_linkAddress_Update"] | undefined,
	delete?: ModelTypes["LocationsDocAccessFields_linkAddress_Delete"] | undefined
};
	["LocationsDocAccessFields_linkAddress_Create"]: {
		permission: boolean
};
	["LocationsDocAccessFields_linkAddress_Read"]: {
		permission: boolean
};
	["LocationsDocAccessFields_linkAddress_Update"]: {
		permission: boolean
};
	["LocationsDocAccessFields_linkAddress_Delete"]: {
		permission: boolean
};
	["LocationsDocAccessFields_address"]: {
		create?: ModelTypes["LocationsDocAccessFields_address_Create"] | undefined,
	read?: ModelTypes["LocationsDocAccessFields_address_Read"] | undefined,
	update?: ModelTypes["LocationsDocAccessFields_address_Update"] | undefined,
	delete?: ModelTypes["LocationsDocAccessFields_address_Delete"] | undefined
};
	["LocationsDocAccessFields_address_Create"]: {
		permission: boolean
};
	["LocationsDocAccessFields_address_Read"]: {
		permission: boolean
};
	["LocationsDocAccessFields_address_Update"]: {
		permission: boolean
};
	["LocationsDocAccessFields_address_Delete"]: {
		permission: boolean
};
	["LocationsDocAccessFields_Details"]: {
		create?: ModelTypes["LocationsDocAccessFields_Details_Create"] | undefined,
	read?: ModelTypes["LocationsDocAccessFields_Details_Read"] | undefined,
	update?: ModelTypes["LocationsDocAccessFields_Details_Update"] | undefined,
	delete?: ModelTypes["LocationsDocAccessFields_Details_Delete"] | undefined,
	fields?: ModelTypes["LocationsDocAccessFields_Details_Fields"] | undefined
};
	["LocationsDocAccessFields_Details_Create"]: {
		permission: boolean
};
	["LocationsDocAccessFields_Details_Read"]: {
		permission: boolean
};
	["LocationsDocAccessFields_Details_Update"]: {
		permission: boolean
};
	["LocationsDocAccessFields_Details_Delete"]: {
		permission: boolean
};
	["LocationsDocAccessFields_Details_Fields"]: {
		code?: ModelTypes["LocationsDocAccessFields_Details_code"] | undefined,
	phone?: ModelTypes["LocationsDocAccessFields_Details_phone"] | undefined,
	page?: ModelTypes["LocationsDocAccessFields_Details_page"] | undefined,
	email?: ModelTypes["LocationsDocAccessFields_Details_email"] | undefined
};
	["LocationsDocAccessFields_Details_code"]: {
		create?: ModelTypes["LocationsDocAccessFields_Details_code_Create"] | undefined,
	read?: ModelTypes["LocationsDocAccessFields_Details_code_Read"] | undefined,
	update?: ModelTypes["LocationsDocAccessFields_Details_code_Update"] | undefined,
	delete?: ModelTypes["LocationsDocAccessFields_Details_code_Delete"] | undefined
};
	["LocationsDocAccessFields_Details_code_Create"]: {
		permission: boolean
};
	["LocationsDocAccessFields_Details_code_Read"]: {
		permission: boolean
};
	["LocationsDocAccessFields_Details_code_Update"]: {
		permission: boolean
};
	["LocationsDocAccessFields_Details_code_Delete"]: {
		permission: boolean
};
	["LocationsDocAccessFields_Details_phone"]: {
		create?: ModelTypes["LocationsDocAccessFields_Details_phone_Create"] | undefined,
	read?: ModelTypes["LocationsDocAccessFields_Details_phone_Read"] | undefined,
	update?: ModelTypes["LocationsDocAccessFields_Details_phone_Update"] | undefined,
	delete?: ModelTypes["LocationsDocAccessFields_Details_phone_Delete"] | undefined
};
	["LocationsDocAccessFields_Details_phone_Create"]: {
		permission: boolean
};
	["LocationsDocAccessFields_Details_phone_Read"]: {
		permission: boolean
};
	["LocationsDocAccessFields_Details_phone_Update"]: {
		permission: boolean
};
	["LocationsDocAccessFields_Details_phone_Delete"]: {
		permission: boolean
};
	["LocationsDocAccessFields_Details_page"]: {
		create?: ModelTypes["LocationsDocAccessFields_Details_page_Create"] | undefined,
	read?: ModelTypes["LocationsDocAccessFields_Details_page_Read"] | undefined,
	update?: ModelTypes["LocationsDocAccessFields_Details_page_Update"] | undefined,
	delete?: ModelTypes["LocationsDocAccessFields_Details_page_Delete"] | undefined
};
	["LocationsDocAccessFields_Details_page_Create"]: {
		permission: boolean
};
	["LocationsDocAccessFields_Details_page_Read"]: {
		permission: boolean
};
	["LocationsDocAccessFields_Details_page_Update"]: {
		permission: boolean
};
	["LocationsDocAccessFields_Details_page_Delete"]: {
		permission: boolean
};
	["LocationsDocAccessFields_Details_email"]: {
		create?: ModelTypes["LocationsDocAccessFields_Details_email_Create"] | undefined,
	read?: ModelTypes["LocationsDocAccessFields_Details_email_Read"] | undefined,
	update?: ModelTypes["LocationsDocAccessFields_Details_email_Update"] | undefined,
	delete?: ModelTypes["LocationsDocAccessFields_Details_email_Delete"] | undefined
};
	["LocationsDocAccessFields_Details_email_Create"]: {
		permission: boolean
};
	["LocationsDocAccessFields_Details_email_Read"]: {
		permission: boolean
};
	["LocationsDocAccessFields_Details_email_Update"]: {
		permission: boolean
};
	["LocationsDocAccessFields_Details_email_Delete"]: {
		permission: boolean
};
	["LocationsDocAccessFields_content"]: {
		create?: ModelTypes["LocationsDocAccessFields_content_Create"] | undefined,
	read?: ModelTypes["LocationsDocAccessFields_content_Read"] | undefined,
	update?: ModelTypes["LocationsDocAccessFields_content_Update"] | undefined,
	delete?: ModelTypes["LocationsDocAccessFields_content_Delete"] | undefined
};
	["LocationsDocAccessFields_content_Create"]: {
		permission: boolean
};
	["LocationsDocAccessFields_content_Read"]: {
		permission: boolean
};
	["LocationsDocAccessFields_content_Update"]: {
		permission: boolean
};
	["LocationsDocAccessFields_content_Delete"]: {
		permission: boolean
};
	["LocationsDocAccessFields_legacyContent"]: {
		create?: ModelTypes["LocationsDocAccessFields_legacyContent_Create"] | undefined,
	read?: ModelTypes["LocationsDocAccessFields_legacyContent_Read"] | undefined,
	update?: ModelTypes["LocationsDocAccessFields_legacyContent_Update"] | undefined,
	delete?: ModelTypes["LocationsDocAccessFields_legacyContent_Delete"] | undefined
};
	["LocationsDocAccessFields_legacyContent_Create"]: {
		permission: boolean
};
	["LocationsDocAccessFields_legacyContent_Read"]: {
		permission: boolean
};
	["LocationsDocAccessFields_legacyContent_Update"]: {
		permission: boolean
};
	["LocationsDocAccessFields_legacyContent_Delete"]: {
		permission: boolean
};
	["LocationsDocAccessFields_showLegacyContent"]: {
		create?: ModelTypes["LocationsDocAccessFields_showLegacyContent_Create"] | undefined,
	read?: ModelTypes["LocationsDocAccessFields_showLegacyContent_Read"] | undefined,
	update?: ModelTypes["LocationsDocAccessFields_showLegacyContent_Update"] | undefined,
	delete?: ModelTypes["LocationsDocAccessFields_showLegacyContent_Delete"] | undefined
};
	["LocationsDocAccessFields_showLegacyContent_Create"]: {
		permission: boolean
};
	["LocationsDocAccessFields_showLegacyContent_Read"]: {
		permission: boolean
};
	["LocationsDocAccessFields_showLegacyContent_Update"]: {
		permission: boolean
};
	["LocationsDocAccessFields_showLegacyContent_Delete"]: {
		permission: boolean
};
	["LocationsDocAccessFields_criteria"]: {
		create?: ModelTypes["LocationsDocAccessFields_criteria_Create"] | undefined,
	read?: ModelTypes["LocationsDocAccessFields_criteria_Read"] | undefined,
	update?: ModelTypes["LocationsDocAccessFields_criteria_Update"] | undefined,
	delete?: ModelTypes["LocationsDocAccessFields_criteria_Delete"] | undefined,
	fields?: ModelTypes["LocationsDocAccessFields_criteria_Fields"] | undefined
};
	["LocationsDocAccessFields_criteria_Create"]: {
		permission: boolean
};
	["LocationsDocAccessFields_criteria_Read"]: {
		permission: boolean
};
	["LocationsDocAccessFields_criteria_Update"]: {
		permission: boolean
};
	["LocationsDocAccessFields_criteria_Delete"]: {
		permission: boolean
};
	["LocationsDocAccessFields_criteria_Fields"]: {
		audiences?: ModelTypes["LocationsDocAccessFields_criteria_audiences"] | undefined,
	dates?: ModelTypes["LocationsDocAccessFields_criteria_dates"] | undefined,
	categories?: ModelTypes["LocationsDocAccessFields_criteria_categories"] | undefined,
	tags?: ModelTypes["LocationsDocAccessFields_criteria_tags"] | undefined
};
	["LocationsDocAccessFields_criteria_audiences"]: {
		create?: ModelTypes["LocationsDocAccessFields_criteria_audiences_Create"] | undefined,
	read?: ModelTypes["LocationsDocAccessFields_criteria_audiences_Read"] | undefined,
	update?: ModelTypes["LocationsDocAccessFields_criteria_audiences_Update"] | undefined,
	delete?: ModelTypes["LocationsDocAccessFields_criteria_audiences_Delete"] | undefined
};
	["LocationsDocAccessFields_criteria_audiences_Create"]: {
		permission: boolean
};
	["LocationsDocAccessFields_criteria_audiences_Read"]: {
		permission: boolean
};
	["LocationsDocAccessFields_criteria_audiences_Update"]: {
		permission: boolean
};
	["LocationsDocAccessFields_criteria_audiences_Delete"]: {
		permission: boolean
};
	["LocationsDocAccessFields_criteria_dates"]: {
		create?: ModelTypes["LocationsDocAccessFields_criteria_dates_Create"] | undefined,
	read?: ModelTypes["LocationsDocAccessFields_criteria_dates_Read"] | undefined,
	update?: ModelTypes["LocationsDocAccessFields_criteria_dates_Update"] | undefined,
	delete?: ModelTypes["LocationsDocAccessFields_criteria_dates_Delete"] | undefined
};
	["LocationsDocAccessFields_criteria_dates_Create"]: {
		permission: boolean
};
	["LocationsDocAccessFields_criteria_dates_Read"]: {
		permission: boolean
};
	["LocationsDocAccessFields_criteria_dates_Update"]: {
		permission: boolean
};
	["LocationsDocAccessFields_criteria_dates_Delete"]: {
		permission: boolean
};
	["LocationsDocAccessFields_criteria_categories"]: {
		create?: ModelTypes["LocationsDocAccessFields_criteria_categories_Create"] | undefined,
	read?: ModelTypes["LocationsDocAccessFields_criteria_categories_Read"] | undefined,
	update?: ModelTypes["LocationsDocAccessFields_criteria_categories_Update"] | undefined,
	delete?: ModelTypes["LocationsDocAccessFields_criteria_categories_Delete"] | undefined
};
	["LocationsDocAccessFields_criteria_categories_Create"]: {
		permission: boolean
};
	["LocationsDocAccessFields_criteria_categories_Read"]: {
		permission: boolean
};
	["LocationsDocAccessFields_criteria_categories_Update"]: {
		permission: boolean
};
	["LocationsDocAccessFields_criteria_categories_Delete"]: {
		permission: boolean
};
	["LocationsDocAccessFields_criteria_tags"]: {
		create?: ModelTypes["LocationsDocAccessFields_criteria_tags_Create"] | undefined,
	read?: ModelTypes["LocationsDocAccessFields_criteria_tags_Read"] | undefined,
	update?: ModelTypes["LocationsDocAccessFields_criteria_tags_Update"] | undefined,
	delete?: ModelTypes["LocationsDocAccessFields_criteria_tags_Delete"] | undefined
};
	["LocationsDocAccessFields_criteria_tags_Create"]: {
		permission: boolean
};
	["LocationsDocAccessFields_criteria_tags_Read"]: {
		permission: boolean
};
	["LocationsDocAccessFields_criteria_tags_Update"]: {
		permission: boolean
};
	["LocationsDocAccessFields_criteria_tags_Delete"]: {
		permission: boolean
};
	["LocationsDocAccessFields_sections"]: {
		create?: ModelTypes["LocationsDocAccessFields_sections_Create"] | undefined,
	read?: ModelTypes["LocationsDocAccessFields_sections_Read"] | undefined,
	update?: ModelTypes["LocationsDocAccessFields_sections_Update"] | undefined,
	delete?: ModelTypes["LocationsDocAccessFields_sections_Delete"] | undefined,
	fields?: ModelTypes["LocationsDocAccessFields_sections_Fields"] | undefined
};
	["LocationsDocAccessFields_sections_Create"]: {
		permission: boolean
};
	["LocationsDocAccessFields_sections_Read"]: {
		permission: boolean
};
	["LocationsDocAccessFields_sections_Update"]: {
		permission: boolean
};
	["LocationsDocAccessFields_sections_Delete"]: {
		permission: boolean
};
	["LocationsDocAccessFields_sections_Fields"]: {
		startDatetime?: ModelTypes["LocationsDocAccessFields_sections_startDatetime"] | undefined,
	endDatetime?: ModelTypes["LocationsDocAccessFields_sections_endDatetime"] | undefined,
	fullDay?: ModelTypes["LocationsDocAccessFields_sections_fullDay"] | undefined,
	repeat?: ModelTypes["LocationsDocAccessFields_sections_repeat"] | undefined,
	recurrance?: ModelTypes["LocationsDocAccessFields_sections_recurrance"] | undefined,
	id?: ModelTypes["LocationsDocAccessFields_sections_id"] | undefined
};
	["LocationsDocAccessFields_sections_startDatetime"]: {
		create?: ModelTypes["LocationsDocAccessFields_sections_startDatetime_Create"] | undefined,
	read?: ModelTypes["LocationsDocAccessFields_sections_startDatetime_Read"] | undefined,
	update?: ModelTypes["LocationsDocAccessFields_sections_startDatetime_Update"] | undefined,
	delete?: ModelTypes["LocationsDocAccessFields_sections_startDatetime_Delete"] | undefined
};
	["LocationsDocAccessFields_sections_startDatetime_Create"]: {
		permission: boolean
};
	["LocationsDocAccessFields_sections_startDatetime_Read"]: {
		permission: boolean
};
	["LocationsDocAccessFields_sections_startDatetime_Update"]: {
		permission: boolean
};
	["LocationsDocAccessFields_sections_startDatetime_Delete"]: {
		permission: boolean
};
	["LocationsDocAccessFields_sections_endDatetime"]: {
		create?: ModelTypes["LocationsDocAccessFields_sections_endDatetime_Create"] | undefined,
	read?: ModelTypes["LocationsDocAccessFields_sections_endDatetime_Read"] | undefined,
	update?: ModelTypes["LocationsDocAccessFields_sections_endDatetime_Update"] | undefined,
	delete?: ModelTypes["LocationsDocAccessFields_sections_endDatetime_Delete"] | undefined
};
	["LocationsDocAccessFields_sections_endDatetime_Create"]: {
		permission: boolean
};
	["LocationsDocAccessFields_sections_endDatetime_Read"]: {
		permission: boolean
};
	["LocationsDocAccessFields_sections_endDatetime_Update"]: {
		permission: boolean
};
	["LocationsDocAccessFields_sections_endDatetime_Delete"]: {
		permission: boolean
};
	["LocationsDocAccessFields_sections_fullDay"]: {
		create?: ModelTypes["LocationsDocAccessFields_sections_fullDay_Create"] | undefined,
	read?: ModelTypes["LocationsDocAccessFields_sections_fullDay_Read"] | undefined,
	update?: ModelTypes["LocationsDocAccessFields_sections_fullDay_Update"] | undefined,
	delete?: ModelTypes["LocationsDocAccessFields_sections_fullDay_Delete"] | undefined
};
	["LocationsDocAccessFields_sections_fullDay_Create"]: {
		permission: boolean
};
	["LocationsDocAccessFields_sections_fullDay_Read"]: {
		permission: boolean
};
	["LocationsDocAccessFields_sections_fullDay_Update"]: {
		permission: boolean
};
	["LocationsDocAccessFields_sections_fullDay_Delete"]: {
		permission: boolean
};
	["LocationsDocAccessFields_sections_repeat"]: {
		create?: ModelTypes["LocationsDocAccessFields_sections_repeat_Create"] | undefined,
	read?: ModelTypes["LocationsDocAccessFields_sections_repeat_Read"] | undefined,
	update?: ModelTypes["LocationsDocAccessFields_sections_repeat_Update"] | undefined,
	delete?: ModelTypes["LocationsDocAccessFields_sections_repeat_Delete"] | undefined
};
	["LocationsDocAccessFields_sections_repeat_Create"]: {
		permission: boolean
};
	["LocationsDocAccessFields_sections_repeat_Read"]: {
		permission: boolean
};
	["LocationsDocAccessFields_sections_repeat_Update"]: {
		permission: boolean
};
	["LocationsDocAccessFields_sections_repeat_Delete"]: {
		permission: boolean
};
	["LocationsDocAccessFields_sections_recurrance"]: {
		create?: ModelTypes["LocationsDocAccessFields_sections_recurrance_Create"] | undefined,
	read?: ModelTypes["LocationsDocAccessFields_sections_recurrance_Read"] | undefined,
	update?: ModelTypes["LocationsDocAccessFields_sections_recurrance_Update"] | undefined,
	delete?: ModelTypes["LocationsDocAccessFields_sections_recurrance_Delete"] | undefined,
	fields?: ModelTypes["LocationsDocAccessFields_sections_recurrance_Fields"] | undefined
};
	["LocationsDocAccessFields_sections_recurrance_Create"]: {
		permission: boolean
};
	["LocationsDocAccessFields_sections_recurrance_Read"]: {
		permission: boolean
};
	["LocationsDocAccessFields_sections_recurrance_Update"]: {
		permission: boolean
};
	["LocationsDocAccessFields_sections_recurrance_Delete"]: {
		permission: boolean
};
	["LocationsDocAccessFields_sections_recurrance_Fields"]: {
		type?: ModelTypes["LocationsDocAccessFields_sections_recurrance_type"] | undefined,
	weekday?: ModelTypes["LocationsDocAccessFields_sections_recurrance_weekday"] | undefined
};
	["LocationsDocAccessFields_sections_recurrance_type"]: {
		create?: ModelTypes["LocationsDocAccessFields_sections_recurrance_type_Create"] | undefined,
	read?: ModelTypes["LocationsDocAccessFields_sections_recurrance_type_Read"] | undefined,
	update?: ModelTypes["LocationsDocAccessFields_sections_recurrance_type_Update"] | undefined,
	delete?: ModelTypes["LocationsDocAccessFields_sections_recurrance_type_Delete"] | undefined
};
	["LocationsDocAccessFields_sections_recurrance_type_Create"]: {
		permission: boolean
};
	["LocationsDocAccessFields_sections_recurrance_type_Read"]: {
		permission: boolean
};
	["LocationsDocAccessFields_sections_recurrance_type_Update"]: {
		permission: boolean
};
	["LocationsDocAccessFields_sections_recurrance_type_Delete"]: {
		permission: boolean
};
	["LocationsDocAccessFields_sections_recurrance_weekday"]: {
		create?: ModelTypes["LocationsDocAccessFields_sections_recurrance_weekday_Create"] | undefined,
	read?: ModelTypes["LocationsDocAccessFields_sections_recurrance_weekday_Read"] | undefined,
	update?: ModelTypes["LocationsDocAccessFields_sections_recurrance_weekday_Update"] | undefined,
	delete?: ModelTypes["LocationsDocAccessFields_sections_recurrance_weekday_Delete"] | undefined
};
	["LocationsDocAccessFields_sections_recurrance_weekday_Create"]: {
		permission: boolean
};
	["LocationsDocAccessFields_sections_recurrance_weekday_Read"]: {
		permission: boolean
};
	["LocationsDocAccessFields_sections_recurrance_weekday_Update"]: {
		permission: boolean
};
	["LocationsDocAccessFields_sections_recurrance_weekday_Delete"]: {
		permission: boolean
};
	["LocationsDocAccessFields_sections_id"]: {
		create?: ModelTypes["LocationsDocAccessFields_sections_id_Create"] | undefined,
	read?: ModelTypes["LocationsDocAccessFields_sections_id_Read"] | undefined,
	update?: ModelTypes["LocationsDocAccessFields_sections_id_Update"] | undefined,
	delete?: ModelTypes["LocationsDocAccessFields_sections_id_Delete"] | undefined
};
	["LocationsDocAccessFields_sections_id_Create"]: {
		permission: boolean
};
	["LocationsDocAccessFields_sections_id_Read"]: {
		permission: boolean
};
	["LocationsDocAccessFields_sections_id_Update"]: {
		permission: boolean
};
	["LocationsDocAccessFields_sections_id_Delete"]: {
		permission: boolean
};
	["LocationsDocAccessFields__title"]: {
		create?: ModelTypes["LocationsDocAccessFields__title_Create"] | undefined,
	read?: ModelTypes["LocationsDocAccessFields__title_Read"] | undefined,
	update?: ModelTypes["LocationsDocAccessFields__title_Update"] | undefined,
	delete?: ModelTypes["LocationsDocAccessFields__title_Delete"] | undefined
};
	["LocationsDocAccessFields__title_Create"]: {
		permission: boolean
};
	["LocationsDocAccessFields__title_Read"]: {
		permission: boolean
};
	["LocationsDocAccessFields__title_Update"]: {
		permission: boolean
};
	["LocationsDocAccessFields__title_Delete"]: {
		permission: boolean
};
	["LocationsDocAccessFields_legacyGallery"]: {
		create?: ModelTypes["LocationsDocAccessFields_legacyGallery_Create"] | undefined,
	read?: ModelTypes["LocationsDocAccessFields_legacyGallery_Read"] | undefined,
	update?: ModelTypes["LocationsDocAccessFields_legacyGallery_Update"] | undefined,
	delete?: ModelTypes["LocationsDocAccessFields_legacyGallery_Delete"] | undefined
};
	["LocationsDocAccessFields_legacyGallery_Create"]: {
		permission: boolean
};
	["LocationsDocAccessFields_legacyGallery_Read"]: {
		permission: boolean
};
	["LocationsDocAccessFields_legacyGallery_Update"]: {
		permission: boolean
};
	["LocationsDocAccessFields_legacyGallery_Delete"]: {
		permission: boolean
};
	["LocationsDocAccessFields_updatedAt"]: {
		create?: ModelTypes["LocationsDocAccessFields_updatedAt_Create"] | undefined,
	read?: ModelTypes["LocationsDocAccessFields_updatedAt_Read"] | undefined,
	update?: ModelTypes["LocationsDocAccessFields_updatedAt_Update"] | undefined,
	delete?: ModelTypes["LocationsDocAccessFields_updatedAt_Delete"] | undefined
};
	["LocationsDocAccessFields_updatedAt_Create"]: {
		permission: boolean
};
	["LocationsDocAccessFields_updatedAt_Read"]: {
		permission: boolean
};
	["LocationsDocAccessFields_updatedAt_Update"]: {
		permission: boolean
};
	["LocationsDocAccessFields_updatedAt_Delete"]: {
		permission: boolean
};
	["LocationsDocAccessFields_createdAt"]: {
		create?: ModelTypes["LocationsDocAccessFields_createdAt_Create"] | undefined,
	read?: ModelTypes["LocationsDocAccessFields_createdAt_Read"] | undefined,
	update?: ModelTypes["LocationsDocAccessFields_createdAt_Update"] | undefined,
	delete?: ModelTypes["LocationsDocAccessFields_createdAt_Delete"] | undefined
};
	["LocationsDocAccessFields_createdAt_Create"]: {
		permission: boolean
};
	["LocationsDocAccessFields_createdAt_Read"]: {
		permission: boolean
};
	["LocationsDocAccessFields_createdAt_Update"]: {
		permission: boolean
};
	["LocationsDocAccessFields_createdAt_Delete"]: {
		permission: boolean
};
	["LocationsCreateDocAccess"]: {
		permission: boolean,
	where?: ModelTypes["JSONObject"] | undefined
};
	["LocationsReadDocAccess"]: {
		permission: boolean,
	where?: ModelTypes["JSONObject"] | undefined
};
	["LocationsUpdateDocAccess"]: {
		permission: boolean,
	where?: ModelTypes["JSONObject"] | undefined
};
	["LocationsDeleteDocAccess"]: {
		permission: boolean,
	where?: ModelTypes["JSONObject"] | undefined
};
	["Post"]: {
		id?: string | undefined,
	title?: string | undefined,
	region?: ModelTypes["Post_region"] | undefined,
	authur?: ModelTypes["User"] | undefined,
	publishDateTime?: ModelTypes["DateTime"] | undefined,
	permalink?: string | undefined,
	status: string,
	content?: Array<ModelTypes["Post_Content"]> | undefined,
	legacyContent?: string | undefined,
	showLegacyContent?: boolean | undefined,
	blogCategories?: Array<ModelTypes["BlogCategory"]> | undefined,
	thumbnail?: ModelTypes["Media"] | undefined,
	banner?: ModelTypes["Media"] | undefined,
	criteria?: ModelTypes["Post_Criteria"] | undefined,
	location?: ModelTypes["Location"] | undefined,
	views?: number | undefined,
	homePageInternalClick?: number | undefined,
	homePageImpression?: number | undefined,
	blogListInternalClick?: number | undefined,
	blogListImpression?: number | undefined,
	blogDetailInternalClick?: number | undefined,
	blogDetailImpression?: number | undefined,
	relatedEventInternalClick?: number | undefined,
	relatedEventImpression?: number | undefined,
	latestEventInternalClick?: number | undefined,
	latestEventImpression?: number | undefined,
	internalClick?: number | undefined,
	totalImpression?: number | undefined,
	externalClick?: number | undefined,
	outboundClick?: number | undefined,
	legacyGallery?: Array<ModelTypes["Media"]> | undefined,
	updatedAt?: ModelTypes["DateTime"] | undefined,
	createdAt?: ModelTypes["DateTime"] | undefined
};
	["Post_region"]:Post_region;
	["Post_Content"]:ModelTypes["RichText"] | ModelTypes["Gallery"] | ModelTypes["Image"] | ModelTypes["Video"];
	["BlogCategory"]: {
		id?: string | undefined,
	name?: string | undefined,
	value: string,
	icon?: ModelTypes["Media"] | undefined,
	region?: ModelTypes["BlogCategory_region"] | undefined,
	updatedAt?: ModelTypes["DateTime"] | undefined,
	createdAt?: ModelTypes["DateTime"] | undefined
};
	["BlogCategory_Icon_where"]: {
	alt?: ModelTypes["BlogCategory_Icon_alt_operator"] | undefined,
	updatedAt?: ModelTypes["BlogCategory_Icon_updatedAt_operator"] | undefined,
	createdAt?: ModelTypes["BlogCategory_Icon_createdAt_operator"] | undefined,
	url?: ModelTypes["BlogCategory_Icon_url_operator"] | undefined,
	filename?: ModelTypes["BlogCategory_Icon_filename_operator"] | undefined,
	mimeType?: ModelTypes["BlogCategory_Icon_mimeType_operator"] | undefined,
	filesize?: ModelTypes["BlogCategory_Icon_filesize_operator"] | undefined,
	width?: ModelTypes["BlogCategory_Icon_width_operator"] | undefined,
	height?: ModelTypes["BlogCategory_Icon_height_operator"] | undefined,
	focalX?: ModelTypes["BlogCategory_Icon_focalX_operator"] | undefined,
	focalY?: ModelTypes["BlogCategory_Icon_focalY_operator"] | undefined,
	sizes__small__url?: ModelTypes["BlogCategory_Icon_sizes__small__url_operator"] | undefined,
	sizes__small__width?: ModelTypes["BlogCategory_Icon_sizes__small__width_operator"] | undefined,
	sizes__small__height?: ModelTypes["BlogCategory_Icon_sizes__small__height_operator"] | undefined,
	sizes__small__mimeType?: ModelTypes["BlogCategory_Icon_sizes__small__mimeType_operator"] | undefined,
	sizes__small__filesize?: ModelTypes["BlogCategory_Icon_sizes__small__filesize_operator"] | undefined,
	sizes__small__filename?: ModelTypes["BlogCategory_Icon_sizes__small__filename_operator"] | undefined,
	sizes__medium__url?: ModelTypes["BlogCategory_Icon_sizes__medium__url_operator"] | undefined,
	sizes__medium__width?: ModelTypes["BlogCategory_Icon_sizes__medium__width_operator"] | undefined,
	sizes__medium__height?: ModelTypes["BlogCategory_Icon_sizes__medium__height_operator"] | undefined,
	sizes__medium__mimeType?: ModelTypes["BlogCategory_Icon_sizes__medium__mimeType_operator"] | undefined,
	sizes__medium__filesize?: ModelTypes["BlogCategory_Icon_sizes__medium__filesize_operator"] | undefined,
	sizes__medium__filename?: ModelTypes["BlogCategory_Icon_sizes__medium__filename_operator"] | undefined,
	sizes__large__url?: ModelTypes["BlogCategory_Icon_sizes__large__url_operator"] | undefined,
	sizes__large__width?: ModelTypes["BlogCategory_Icon_sizes__large__width_operator"] | undefined,
	sizes__large__height?: ModelTypes["BlogCategory_Icon_sizes__large__height_operator"] | undefined,
	sizes__large__mimeType?: ModelTypes["BlogCategory_Icon_sizes__large__mimeType_operator"] | undefined,
	sizes__large__filesize?: ModelTypes["BlogCategory_Icon_sizes__large__filesize_operator"] | undefined,
	sizes__large__filename?: ModelTypes["BlogCategory_Icon_sizes__large__filename_operator"] | undefined,
	sizes__small_jpeg__url?: ModelTypes["BlogCategory_Icon_sizes__small_jpeg__url_operator"] | undefined,
	sizes__small_jpeg__width?: ModelTypes["BlogCategory_Icon_sizes__small_jpeg__width_operator"] | undefined,
	sizes__small_jpeg__height?: ModelTypes["BlogCategory_Icon_sizes__small_jpeg__height_operator"] | undefined,
	sizes__small_jpeg__mimeType?: ModelTypes["BlogCategory_Icon_sizes__small_jpeg__mimeType_operator"] | undefined,
	sizes__small_jpeg__filesize?: ModelTypes["BlogCategory_Icon_sizes__small_jpeg__filesize_operator"] | undefined,
	sizes__small_jpeg__filename?: ModelTypes["BlogCategory_Icon_sizes__small_jpeg__filename_operator"] | undefined,
	sizes__medium_jpeg__url?: ModelTypes["BlogCategory_Icon_sizes__medium_jpeg__url_operator"] | undefined,
	sizes__medium_jpeg__width?: ModelTypes["BlogCategory_Icon_sizes__medium_jpeg__width_operator"] | undefined,
	sizes__medium_jpeg__height?: ModelTypes["BlogCategory_Icon_sizes__medium_jpeg__height_operator"] | undefined,
	sizes__medium_jpeg__mimeType?: ModelTypes["BlogCategory_Icon_sizes__medium_jpeg__mimeType_operator"] | undefined,
	sizes__medium_jpeg__filesize?: ModelTypes["BlogCategory_Icon_sizes__medium_jpeg__filesize_operator"] | undefined,
	sizes__medium_jpeg__filename?: ModelTypes["BlogCategory_Icon_sizes__medium_jpeg__filename_operator"] | undefined,
	sizes__large_jpeg__url?: ModelTypes["BlogCategory_Icon_sizes__large_jpeg__url_operator"] | undefined,
	sizes__large_jpeg__width?: ModelTypes["BlogCategory_Icon_sizes__large_jpeg__width_operator"] | undefined,
	sizes__large_jpeg__height?: ModelTypes["BlogCategory_Icon_sizes__large_jpeg__height_operator"] | undefined,
	sizes__large_jpeg__mimeType?: ModelTypes["BlogCategory_Icon_sizes__large_jpeg__mimeType_operator"] | undefined,
	sizes__large_jpeg__filesize?: ModelTypes["BlogCategory_Icon_sizes__large_jpeg__filesize_operator"] | undefined,
	sizes__large_jpeg__filename?: ModelTypes["BlogCategory_Icon_sizes__large_jpeg__filename_operator"] | undefined,
	id?: ModelTypes["BlogCategory_Icon_id_operator"] | undefined,
	AND?: Array<ModelTypes["BlogCategory_Icon_where_and"] | undefined> | undefined,
	OR?: Array<ModelTypes["BlogCategory_Icon_where_or"] | undefined> | undefined
};
	["BlogCategory_Icon_alt_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["BlogCategory_Icon_updatedAt_operator"]: {
	equals?: ModelTypes["DateTime"] | undefined,
	not_equals?: ModelTypes["DateTime"] | undefined,
	greater_than_equal?: ModelTypes["DateTime"] | undefined,
	greater_than?: ModelTypes["DateTime"] | undefined,
	less_than_equal?: ModelTypes["DateTime"] | undefined,
	less_than?: ModelTypes["DateTime"] | undefined,
	like?: ModelTypes["DateTime"] | undefined,
	exists?: boolean | undefined
};
	["BlogCategory_Icon_createdAt_operator"]: {
	equals?: ModelTypes["DateTime"] | undefined,
	not_equals?: ModelTypes["DateTime"] | undefined,
	greater_than_equal?: ModelTypes["DateTime"] | undefined,
	greater_than?: ModelTypes["DateTime"] | undefined,
	less_than_equal?: ModelTypes["DateTime"] | undefined,
	less_than?: ModelTypes["DateTime"] | undefined,
	like?: ModelTypes["DateTime"] | undefined,
	exists?: boolean | undefined
};
	["BlogCategory_Icon_url_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["BlogCategory_Icon_filename_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["BlogCategory_Icon_mimeType_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["BlogCategory_Icon_filesize_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["BlogCategory_Icon_width_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["BlogCategory_Icon_height_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["BlogCategory_Icon_focalX_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["BlogCategory_Icon_focalY_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["BlogCategory_Icon_sizes__small__url_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["BlogCategory_Icon_sizes__small__width_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["BlogCategory_Icon_sizes__small__height_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["BlogCategory_Icon_sizes__small__mimeType_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["BlogCategory_Icon_sizes__small__filesize_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["BlogCategory_Icon_sizes__small__filename_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["BlogCategory_Icon_sizes__medium__url_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["BlogCategory_Icon_sizes__medium__width_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["BlogCategory_Icon_sizes__medium__height_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["BlogCategory_Icon_sizes__medium__mimeType_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["BlogCategory_Icon_sizes__medium__filesize_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["BlogCategory_Icon_sizes__medium__filename_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["BlogCategory_Icon_sizes__large__url_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["BlogCategory_Icon_sizes__large__width_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["BlogCategory_Icon_sizes__large__height_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["BlogCategory_Icon_sizes__large__mimeType_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["BlogCategory_Icon_sizes__large__filesize_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["BlogCategory_Icon_sizes__large__filename_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["BlogCategory_Icon_sizes__small_jpeg__url_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["BlogCategory_Icon_sizes__small_jpeg__width_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["BlogCategory_Icon_sizes__small_jpeg__height_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["BlogCategory_Icon_sizes__small_jpeg__mimeType_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["BlogCategory_Icon_sizes__small_jpeg__filesize_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["BlogCategory_Icon_sizes__small_jpeg__filename_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["BlogCategory_Icon_sizes__medium_jpeg__url_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["BlogCategory_Icon_sizes__medium_jpeg__width_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["BlogCategory_Icon_sizes__medium_jpeg__height_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["BlogCategory_Icon_sizes__medium_jpeg__mimeType_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["BlogCategory_Icon_sizes__medium_jpeg__filesize_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["BlogCategory_Icon_sizes__medium_jpeg__filename_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["BlogCategory_Icon_sizes__large_jpeg__url_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["BlogCategory_Icon_sizes__large_jpeg__width_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["BlogCategory_Icon_sizes__large_jpeg__height_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["BlogCategory_Icon_sizes__large_jpeg__mimeType_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["BlogCategory_Icon_sizes__large_jpeg__filesize_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["BlogCategory_Icon_sizes__large_jpeg__filename_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["BlogCategory_Icon_id_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["BlogCategory_Icon_where_and"]: {
	alt?: ModelTypes["BlogCategory_Icon_alt_operator"] | undefined,
	updatedAt?: ModelTypes["BlogCategory_Icon_updatedAt_operator"] | undefined,
	createdAt?: ModelTypes["BlogCategory_Icon_createdAt_operator"] | undefined,
	url?: ModelTypes["BlogCategory_Icon_url_operator"] | undefined,
	filename?: ModelTypes["BlogCategory_Icon_filename_operator"] | undefined,
	mimeType?: ModelTypes["BlogCategory_Icon_mimeType_operator"] | undefined,
	filesize?: ModelTypes["BlogCategory_Icon_filesize_operator"] | undefined,
	width?: ModelTypes["BlogCategory_Icon_width_operator"] | undefined,
	height?: ModelTypes["BlogCategory_Icon_height_operator"] | undefined,
	focalX?: ModelTypes["BlogCategory_Icon_focalX_operator"] | undefined,
	focalY?: ModelTypes["BlogCategory_Icon_focalY_operator"] | undefined,
	sizes__small__url?: ModelTypes["BlogCategory_Icon_sizes__small__url_operator"] | undefined,
	sizes__small__width?: ModelTypes["BlogCategory_Icon_sizes__small__width_operator"] | undefined,
	sizes__small__height?: ModelTypes["BlogCategory_Icon_sizes__small__height_operator"] | undefined,
	sizes__small__mimeType?: ModelTypes["BlogCategory_Icon_sizes__small__mimeType_operator"] | undefined,
	sizes__small__filesize?: ModelTypes["BlogCategory_Icon_sizes__small__filesize_operator"] | undefined,
	sizes__small__filename?: ModelTypes["BlogCategory_Icon_sizes__small__filename_operator"] | undefined,
	sizes__medium__url?: ModelTypes["BlogCategory_Icon_sizes__medium__url_operator"] | undefined,
	sizes__medium__width?: ModelTypes["BlogCategory_Icon_sizes__medium__width_operator"] | undefined,
	sizes__medium__height?: ModelTypes["BlogCategory_Icon_sizes__medium__height_operator"] | undefined,
	sizes__medium__mimeType?: ModelTypes["BlogCategory_Icon_sizes__medium__mimeType_operator"] | undefined,
	sizes__medium__filesize?: ModelTypes["BlogCategory_Icon_sizes__medium__filesize_operator"] | undefined,
	sizes__medium__filename?: ModelTypes["BlogCategory_Icon_sizes__medium__filename_operator"] | undefined,
	sizes__large__url?: ModelTypes["BlogCategory_Icon_sizes__large__url_operator"] | undefined,
	sizes__large__width?: ModelTypes["BlogCategory_Icon_sizes__large__width_operator"] | undefined,
	sizes__large__height?: ModelTypes["BlogCategory_Icon_sizes__large__height_operator"] | undefined,
	sizes__large__mimeType?: ModelTypes["BlogCategory_Icon_sizes__large__mimeType_operator"] | undefined,
	sizes__large__filesize?: ModelTypes["BlogCategory_Icon_sizes__large__filesize_operator"] | undefined,
	sizes__large__filename?: ModelTypes["BlogCategory_Icon_sizes__large__filename_operator"] | undefined,
	sizes__small_jpeg__url?: ModelTypes["BlogCategory_Icon_sizes__small_jpeg__url_operator"] | undefined,
	sizes__small_jpeg__width?: ModelTypes["BlogCategory_Icon_sizes__small_jpeg__width_operator"] | undefined,
	sizes__small_jpeg__height?: ModelTypes["BlogCategory_Icon_sizes__small_jpeg__height_operator"] | undefined,
	sizes__small_jpeg__mimeType?: ModelTypes["BlogCategory_Icon_sizes__small_jpeg__mimeType_operator"] | undefined,
	sizes__small_jpeg__filesize?: ModelTypes["BlogCategory_Icon_sizes__small_jpeg__filesize_operator"] | undefined,
	sizes__small_jpeg__filename?: ModelTypes["BlogCategory_Icon_sizes__small_jpeg__filename_operator"] | undefined,
	sizes__medium_jpeg__url?: ModelTypes["BlogCategory_Icon_sizes__medium_jpeg__url_operator"] | undefined,
	sizes__medium_jpeg__width?: ModelTypes["BlogCategory_Icon_sizes__medium_jpeg__width_operator"] | undefined,
	sizes__medium_jpeg__height?: ModelTypes["BlogCategory_Icon_sizes__medium_jpeg__height_operator"] | undefined,
	sizes__medium_jpeg__mimeType?: ModelTypes["BlogCategory_Icon_sizes__medium_jpeg__mimeType_operator"] | undefined,
	sizes__medium_jpeg__filesize?: ModelTypes["BlogCategory_Icon_sizes__medium_jpeg__filesize_operator"] | undefined,
	sizes__medium_jpeg__filename?: ModelTypes["BlogCategory_Icon_sizes__medium_jpeg__filename_operator"] | undefined,
	sizes__large_jpeg__url?: ModelTypes["BlogCategory_Icon_sizes__large_jpeg__url_operator"] | undefined,
	sizes__large_jpeg__width?: ModelTypes["BlogCategory_Icon_sizes__large_jpeg__width_operator"] | undefined,
	sizes__large_jpeg__height?: ModelTypes["BlogCategory_Icon_sizes__large_jpeg__height_operator"] | undefined,
	sizes__large_jpeg__mimeType?: ModelTypes["BlogCategory_Icon_sizes__large_jpeg__mimeType_operator"] | undefined,
	sizes__large_jpeg__filesize?: ModelTypes["BlogCategory_Icon_sizes__large_jpeg__filesize_operator"] | undefined,
	sizes__large_jpeg__filename?: ModelTypes["BlogCategory_Icon_sizes__large_jpeg__filename_operator"] | undefined,
	id?: ModelTypes["BlogCategory_Icon_id_operator"] | undefined,
	AND?: Array<ModelTypes["BlogCategory_Icon_where_and"] | undefined> | undefined,
	OR?: Array<ModelTypes["BlogCategory_Icon_where_or"] | undefined> | undefined
};
	["BlogCategory_Icon_where_or"]: {
	alt?: ModelTypes["BlogCategory_Icon_alt_operator"] | undefined,
	updatedAt?: ModelTypes["BlogCategory_Icon_updatedAt_operator"] | undefined,
	createdAt?: ModelTypes["BlogCategory_Icon_createdAt_operator"] | undefined,
	url?: ModelTypes["BlogCategory_Icon_url_operator"] | undefined,
	filename?: ModelTypes["BlogCategory_Icon_filename_operator"] | undefined,
	mimeType?: ModelTypes["BlogCategory_Icon_mimeType_operator"] | undefined,
	filesize?: ModelTypes["BlogCategory_Icon_filesize_operator"] | undefined,
	width?: ModelTypes["BlogCategory_Icon_width_operator"] | undefined,
	height?: ModelTypes["BlogCategory_Icon_height_operator"] | undefined,
	focalX?: ModelTypes["BlogCategory_Icon_focalX_operator"] | undefined,
	focalY?: ModelTypes["BlogCategory_Icon_focalY_operator"] | undefined,
	sizes__small__url?: ModelTypes["BlogCategory_Icon_sizes__small__url_operator"] | undefined,
	sizes__small__width?: ModelTypes["BlogCategory_Icon_sizes__small__width_operator"] | undefined,
	sizes__small__height?: ModelTypes["BlogCategory_Icon_sizes__small__height_operator"] | undefined,
	sizes__small__mimeType?: ModelTypes["BlogCategory_Icon_sizes__small__mimeType_operator"] | undefined,
	sizes__small__filesize?: ModelTypes["BlogCategory_Icon_sizes__small__filesize_operator"] | undefined,
	sizes__small__filename?: ModelTypes["BlogCategory_Icon_sizes__small__filename_operator"] | undefined,
	sizes__medium__url?: ModelTypes["BlogCategory_Icon_sizes__medium__url_operator"] | undefined,
	sizes__medium__width?: ModelTypes["BlogCategory_Icon_sizes__medium__width_operator"] | undefined,
	sizes__medium__height?: ModelTypes["BlogCategory_Icon_sizes__medium__height_operator"] | undefined,
	sizes__medium__mimeType?: ModelTypes["BlogCategory_Icon_sizes__medium__mimeType_operator"] | undefined,
	sizes__medium__filesize?: ModelTypes["BlogCategory_Icon_sizes__medium__filesize_operator"] | undefined,
	sizes__medium__filename?: ModelTypes["BlogCategory_Icon_sizes__medium__filename_operator"] | undefined,
	sizes__large__url?: ModelTypes["BlogCategory_Icon_sizes__large__url_operator"] | undefined,
	sizes__large__width?: ModelTypes["BlogCategory_Icon_sizes__large__width_operator"] | undefined,
	sizes__large__height?: ModelTypes["BlogCategory_Icon_sizes__large__height_operator"] | undefined,
	sizes__large__mimeType?: ModelTypes["BlogCategory_Icon_sizes__large__mimeType_operator"] | undefined,
	sizes__large__filesize?: ModelTypes["BlogCategory_Icon_sizes__large__filesize_operator"] | undefined,
	sizes__large__filename?: ModelTypes["BlogCategory_Icon_sizes__large__filename_operator"] | undefined,
	sizes__small_jpeg__url?: ModelTypes["BlogCategory_Icon_sizes__small_jpeg__url_operator"] | undefined,
	sizes__small_jpeg__width?: ModelTypes["BlogCategory_Icon_sizes__small_jpeg__width_operator"] | undefined,
	sizes__small_jpeg__height?: ModelTypes["BlogCategory_Icon_sizes__small_jpeg__height_operator"] | undefined,
	sizes__small_jpeg__mimeType?: ModelTypes["BlogCategory_Icon_sizes__small_jpeg__mimeType_operator"] | undefined,
	sizes__small_jpeg__filesize?: ModelTypes["BlogCategory_Icon_sizes__small_jpeg__filesize_operator"] | undefined,
	sizes__small_jpeg__filename?: ModelTypes["BlogCategory_Icon_sizes__small_jpeg__filename_operator"] | undefined,
	sizes__medium_jpeg__url?: ModelTypes["BlogCategory_Icon_sizes__medium_jpeg__url_operator"] | undefined,
	sizes__medium_jpeg__width?: ModelTypes["BlogCategory_Icon_sizes__medium_jpeg__width_operator"] | undefined,
	sizes__medium_jpeg__height?: ModelTypes["BlogCategory_Icon_sizes__medium_jpeg__height_operator"] | undefined,
	sizes__medium_jpeg__mimeType?: ModelTypes["BlogCategory_Icon_sizes__medium_jpeg__mimeType_operator"] | undefined,
	sizes__medium_jpeg__filesize?: ModelTypes["BlogCategory_Icon_sizes__medium_jpeg__filesize_operator"] | undefined,
	sizes__medium_jpeg__filename?: ModelTypes["BlogCategory_Icon_sizes__medium_jpeg__filename_operator"] | undefined,
	sizes__large_jpeg__url?: ModelTypes["BlogCategory_Icon_sizes__large_jpeg__url_operator"] | undefined,
	sizes__large_jpeg__width?: ModelTypes["BlogCategory_Icon_sizes__large_jpeg__width_operator"] | undefined,
	sizes__large_jpeg__height?: ModelTypes["BlogCategory_Icon_sizes__large_jpeg__height_operator"] | undefined,
	sizes__large_jpeg__mimeType?: ModelTypes["BlogCategory_Icon_sizes__large_jpeg__mimeType_operator"] | undefined,
	sizes__large_jpeg__filesize?: ModelTypes["BlogCategory_Icon_sizes__large_jpeg__filesize_operator"] | undefined,
	sizes__large_jpeg__filename?: ModelTypes["BlogCategory_Icon_sizes__large_jpeg__filename_operator"] | undefined,
	id?: ModelTypes["BlogCategory_Icon_id_operator"] | undefined,
	AND?: Array<ModelTypes["BlogCategory_Icon_where_and"] | undefined> | undefined,
	OR?: Array<ModelTypes["BlogCategory_Icon_where_or"] | undefined> | undefined
};
	["BlogCategory_region"]:BlogCategory_region;
	["Post_Thumbnail_where"]: {
	alt?: ModelTypes["Post_Thumbnail_alt_operator"] | undefined,
	updatedAt?: ModelTypes["Post_Thumbnail_updatedAt_operator"] | undefined,
	createdAt?: ModelTypes["Post_Thumbnail_createdAt_operator"] | undefined,
	url?: ModelTypes["Post_Thumbnail_url_operator"] | undefined,
	filename?: ModelTypes["Post_Thumbnail_filename_operator"] | undefined,
	mimeType?: ModelTypes["Post_Thumbnail_mimeType_operator"] | undefined,
	filesize?: ModelTypes["Post_Thumbnail_filesize_operator"] | undefined,
	width?: ModelTypes["Post_Thumbnail_width_operator"] | undefined,
	height?: ModelTypes["Post_Thumbnail_height_operator"] | undefined,
	focalX?: ModelTypes["Post_Thumbnail_focalX_operator"] | undefined,
	focalY?: ModelTypes["Post_Thumbnail_focalY_operator"] | undefined,
	sizes__small__url?: ModelTypes["Post_Thumbnail_sizes__small__url_operator"] | undefined,
	sizes__small__width?: ModelTypes["Post_Thumbnail_sizes__small__width_operator"] | undefined,
	sizes__small__height?: ModelTypes["Post_Thumbnail_sizes__small__height_operator"] | undefined,
	sizes__small__mimeType?: ModelTypes["Post_Thumbnail_sizes__small__mimeType_operator"] | undefined,
	sizes__small__filesize?: ModelTypes["Post_Thumbnail_sizes__small__filesize_operator"] | undefined,
	sizes__small__filename?: ModelTypes["Post_Thumbnail_sizes__small__filename_operator"] | undefined,
	sizes__medium__url?: ModelTypes["Post_Thumbnail_sizes__medium__url_operator"] | undefined,
	sizes__medium__width?: ModelTypes["Post_Thumbnail_sizes__medium__width_operator"] | undefined,
	sizes__medium__height?: ModelTypes["Post_Thumbnail_sizes__medium__height_operator"] | undefined,
	sizes__medium__mimeType?: ModelTypes["Post_Thumbnail_sizes__medium__mimeType_operator"] | undefined,
	sizes__medium__filesize?: ModelTypes["Post_Thumbnail_sizes__medium__filesize_operator"] | undefined,
	sizes__medium__filename?: ModelTypes["Post_Thumbnail_sizes__medium__filename_operator"] | undefined,
	sizes__large__url?: ModelTypes["Post_Thumbnail_sizes__large__url_operator"] | undefined,
	sizes__large__width?: ModelTypes["Post_Thumbnail_sizes__large__width_operator"] | undefined,
	sizes__large__height?: ModelTypes["Post_Thumbnail_sizes__large__height_operator"] | undefined,
	sizes__large__mimeType?: ModelTypes["Post_Thumbnail_sizes__large__mimeType_operator"] | undefined,
	sizes__large__filesize?: ModelTypes["Post_Thumbnail_sizes__large__filesize_operator"] | undefined,
	sizes__large__filename?: ModelTypes["Post_Thumbnail_sizes__large__filename_operator"] | undefined,
	sizes__small_jpeg__url?: ModelTypes["Post_Thumbnail_sizes__small_jpeg__url_operator"] | undefined,
	sizes__small_jpeg__width?: ModelTypes["Post_Thumbnail_sizes__small_jpeg__width_operator"] | undefined,
	sizes__small_jpeg__height?: ModelTypes["Post_Thumbnail_sizes__small_jpeg__height_operator"] | undefined,
	sizes__small_jpeg__mimeType?: ModelTypes["Post_Thumbnail_sizes__small_jpeg__mimeType_operator"] | undefined,
	sizes__small_jpeg__filesize?: ModelTypes["Post_Thumbnail_sizes__small_jpeg__filesize_operator"] | undefined,
	sizes__small_jpeg__filename?: ModelTypes["Post_Thumbnail_sizes__small_jpeg__filename_operator"] | undefined,
	sizes__medium_jpeg__url?: ModelTypes["Post_Thumbnail_sizes__medium_jpeg__url_operator"] | undefined,
	sizes__medium_jpeg__width?: ModelTypes["Post_Thumbnail_sizes__medium_jpeg__width_operator"] | undefined,
	sizes__medium_jpeg__height?: ModelTypes["Post_Thumbnail_sizes__medium_jpeg__height_operator"] | undefined,
	sizes__medium_jpeg__mimeType?: ModelTypes["Post_Thumbnail_sizes__medium_jpeg__mimeType_operator"] | undefined,
	sizes__medium_jpeg__filesize?: ModelTypes["Post_Thumbnail_sizes__medium_jpeg__filesize_operator"] | undefined,
	sizes__medium_jpeg__filename?: ModelTypes["Post_Thumbnail_sizes__medium_jpeg__filename_operator"] | undefined,
	sizes__large_jpeg__url?: ModelTypes["Post_Thumbnail_sizes__large_jpeg__url_operator"] | undefined,
	sizes__large_jpeg__width?: ModelTypes["Post_Thumbnail_sizes__large_jpeg__width_operator"] | undefined,
	sizes__large_jpeg__height?: ModelTypes["Post_Thumbnail_sizes__large_jpeg__height_operator"] | undefined,
	sizes__large_jpeg__mimeType?: ModelTypes["Post_Thumbnail_sizes__large_jpeg__mimeType_operator"] | undefined,
	sizes__large_jpeg__filesize?: ModelTypes["Post_Thumbnail_sizes__large_jpeg__filesize_operator"] | undefined,
	sizes__large_jpeg__filename?: ModelTypes["Post_Thumbnail_sizes__large_jpeg__filename_operator"] | undefined,
	id?: ModelTypes["Post_Thumbnail_id_operator"] | undefined,
	AND?: Array<ModelTypes["Post_Thumbnail_where_and"] | undefined> | undefined,
	OR?: Array<ModelTypes["Post_Thumbnail_where_or"] | undefined> | undefined
};
	["Post_Thumbnail_alt_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Post_Thumbnail_updatedAt_operator"]: {
	equals?: ModelTypes["DateTime"] | undefined,
	not_equals?: ModelTypes["DateTime"] | undefined,
	greater_than_equal?: ModelTypes["DateTime"] | undefined,
	greater_than?: ModelTypes["DateTime"] | undefined,
	less_than_equal?: ModelTypes["DateTime"] | undefined,
	less_than?: ModelTypes["DateTime"] | undefined,
	like?: ModelTypes["DateTime"] | undefined,
	exists?: boolean | undefined
};
	["Post_Thumbnail_createdAt_operator"]: {
	equals?: ModelTypes["DateTime"] | undefined,
	not_equals?: ModelTypes["DateTime"] | undefined,
	greater_than_equal?: ModelTypes["DateTime"] | undefined,
	greater_than?: ModelTypes["DateTime"] | undefined,
	less_than_equal?: ModelTypes["DateTime"] | undefined,
	less_than?: ModelTypes["DateTime"] | undefined,
	like?: ModelTypes["DateTime"] | undefined,
	exists?: boolean | undefined
};
	["Post_Thumbnail_url_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Post_Thumbnail_filename_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Post_Thumbnail_mimeType_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Post_Thumbnail_filesize_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Post_Thumbnail_width_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Post_Thumbnail_height_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Post_Thumbnail_focalX_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Post_Thumbnail_focalY_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Post_Thumbnail_sizes__small__url_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Post_Thumbnail_sizes__small__width_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Post_Thumbnail_sizes__small__height_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Post_Thumbnail_sizes__small__mimeType_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Post_Thumbnail_sizes__small__filesize_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Post_Thumbnail_sizes__small__filename_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Post_Thumbnail_sizes__medium__url_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Post_Thumbnail_sizes__medium__width_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Post_Thumbnail_sizes__medium__height_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Post_Thumbnail_sizes__medium__mimeType_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Post_Thumbnail_sizes__medium__filesize_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Post_Thumbnail_sizes__medium__filename_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Post_Thumbnail_sizes__large__url_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Post_Thumbnail_sizes__large__width_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Post_Thumbnail_sizes__large__height_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Post_Thumbnail_sizes__large__mimeType_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Post_Thumbnail_sizes__large__filesize_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Post_Thumbnail_sizes__large__filename_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Post_Thumbnail_sizes__small_jpeg__url_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Post_Thumbnail_sizes__small_jpeg__width_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Post_Thumbnail_sizes__small_jpeg__height_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Post_Thumbnail_sizes__small_jpeg__mimeType_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Post_Thumbnail_sizes__small_jpeg__filesize_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Post_Thumbnail_sizes__small_jpeg__filename_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Post_Thumbnail_sizes__medium_jpeg__url_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Post_Thumbnail_sizes__medium_jpeg__width_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Post_Thumbnail_sizes__medium_jpeg__height_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Post_Thumbnail_sizes__medium_jpeg__mimeType_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Post_Thumbnail_sizes__medium_jpeg__filesize_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Post_Thumbnail_sizes__medium_jpeg__filename_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Post_Thumbnail_sizes__large_jpeg__url_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Post_Thumbnail_sizes__large_jpeg__width_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Post_Thumbnail_sizes__large_jpeg__height_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Post_Thumbnail_sizes__large_jpeg__mimeType_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Post_Thumbnail_sizes__large_jpeg__filesize_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Post_Thumbnail_sizes__large_jpeg__filename_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Post_Thumbnail_id_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Post_Thumbnail_where_and"]: {
	alt?: ModelTypes["Post_Thumbnail_alt_operator"] | undefined,
	updatedAt?: ModelTypes["Post_Thumbnail_updatedAt_operator"] | undefined,
	createdAt?: ModelTypes["Post_Thumbnail_createdAt_operator"] | undefined,
	url?: ModelTypes["Post_Thumbnail_url_operator"] | undefined,
	filename?: ModelTypes["Post_Thumbnail_filename_operator"] | undefined,
	mimeType?: ModelTypes["Post_Thumbnail_mimeType_operator"] | undefined,
	filesize?: ModelTypes["Post_Thumbnail_filesize_operator"] | undefined,
	width?: ModelTypes["Post_Thumbnail_width_operator"] | undefined,
	height?: ModelTypes["Post_Thumbnail_height_operator"] | undefined,
	focalX?: ModelTypes["Post_Thumbnail_focalX_operator"] | undefined,
	focalY?: ModelTypes["Post_Thumbnail_focalY_operator"] | undefined,
	sizes__small__url?: ModelTypes["Post_Thumbnail_sizes__small__url_operator"] | undefined,
	sizes__small__width?: ModelTypes["Post_Thumbnail_sizes__small__width_operator"] | undefined,
	sizes__small__height?: ModelTypes["Post_Thumbnail_sizes__small__height_operator"] | undefined,
	sizes__small__mimeType?: ModelTypes["Post_Thumbnail_sizes__small__mimeType_operator"] | undefined,
	sizes__small__filesize?: ModelTypes["Post_Thumbnail_sizes__small__filesize_operator"] | undefined,
	sizes__small__filename?: ModelTypes["Post_Thumbnail_sizes__small__filename_operator"] | undefined,
	sizes__medium__url?: ModelTypes["Post_Thumbnail_sizes__medium__url_operator"] | undefined,
	sizes__medium__width?: ModelTypes["Post_Thumbnail_sizes__medium__width_operator"] | undefined,
	sizes__medium__height?: ModelTypes["Post_Thumbnail_sizes__medium__height_operator"] | undefined,
	sizes__medium__mimeType?: ModelTypes["Post_Thumbnail_sizes__medium__mimeType_operator"] | undefined,
	sizes__medium__filesize?: ModelTypes["Post_Thumbnail_sizes__medium__filesize_operator"] | undefined,
	sizes__medium__filename?: ModelTypes["Post_Thumbnail_sizes__medium__filename_operator"] | undefined,
	sizes__large__url?: ModelTypes["Post_Thumbnail_sizes__large__url_operator"] | undefined,
	sizes__large__width?: ModelTypes["Post_Thumbnail_sizes__large__width_operator"] | undefined,
	sizes__large__height?: ModelTypes["Post_Thumbnail_sizes__large__height_operator"] | undefined,
	sizes__large__mimeType?: ModelTypes["Post_Thumbnail_sizes__large__mimeType_operator"] | undefined,
	sizes__large__filesize?: ModelTypes["Post_Thumbnail_sizes__large__filesize_operator"] | undefined,
	sizes__large__filename?: ModelTypes["Post_Thumbnail_sizes__large__filename_operator"] | undefined,
	sizes__small_jpeg__url?: ModelTypes["Post_Thumbnail_sizes__small_jpeg__url_operator"] | undefined,
	sizes__small_jpeg__width?: ModelTypes["Post_Thumbnail_sizes__small_jpeg__width_operator"] | undefined,
	sizes__small_jpeg__height?: ModelTypes["Post_Thumbnail_sizes__small_jpeg__height_operator"] | undefined,
	sizes__small_jpeg__mimeType?: ModelTypes["Post_Thumbnail_sizes__small_jpeg__mimeType_operator"] | undefined,
	sizes__small_jpeg__filesize?: ModelTypes["Post_Thumbnail_sizes__small_jpeg__filesize_operator"] | undefined,
	sizes__small_jpeg__filename?: ModelTypes["Post_Thumbnail_sizes__small_jpeg__filename_operator"] | undefined,
	sizes__medium_jpeg__url?: ModelTypes["Post_Thumbnail_sizes__medium_jpeg__url_operator"] | undefined,
	sizes__medium_jpeg__width?: ModelTypes["Post_Thumbnail_sizes__medium_jpeg__width_operator"] | undefined,
	sizes__medium_jpeg__height?: ModelTypes["Post_Thumbnail_sizes__medium_jpeg__height_operator"] | undefined,
	sizes__medium_jpeg__mimeType?: ModelTypes["Post_Thumbnail_sizes__medium_jpeg__mimeType_operator"] | undefined,
	sizes__medium_jpeg__filesize?: ModelTypes["Post_Thumbnail_sizes__medium_jpeg__filesize_operator"] | undefined,
	sizes__medium_jpeg__filename?: ModelTypes["Post_Thumbnail_sizes__medium_jpeg__filename_operator"] | undefined,
	sizes__large_jpeg__url?: ModelTypes["Post_Thumbnail_sizes__large_jpeg__url_operator"] | undefined,
	sizes__large_jpeg__width?: ModelTypes["Post_Thumbnail_sizes__large_jpeg__width_operator"] | undefined,
	sizes__large_jpeg__height?: ModelTypes["Post_Thumbnail_sizes__large_jpeg__height_operator"] | undefined,
	sizes__large_jpeg__mimeType?: ModelTypes["Post_Thumbnail_sizes__large_jpeg__mimeType_operator"] | undefined,
	sizes__large_jpeg__filesize?: ModelTypes["Post_Thumbnail_sizes__large_jpeg__filesize_operator"] | undefined,
	sizes__large_jpeg__filename?: ModelTypes["Post_Thumbnail_sizes__large_jpeg__filename_operator"] | undefined,
	id?: ModelTypes["Post_Thumbnail_id_operator"] | undefined,
	AND?: Array<ModelTypes["Post_Thumbnail_where_and"] | undefined> | undefined,
	OR?: Array<ModelTypes["Post_Thumbnail_where_or"] | undefined> | undefined
};
	["Post_Thumbnail_where_or"]: {
	alt?: ModelTypes["Post_Thumbnail_alt_operator"] | undefined,
	updatedAt?: ModelTypes["Post_Thumbnail_updatedAt_operator"] | undefined,
	createdAt?: ModelTypes["Post_Thumbnail_createdAt_operator"] | undefined,
	url?: ModelTypes["Post_Thumbnail_url_operator"] | undefined,
	filename?: ModelTypes["Post_Thumbnail_filename_operator"] | undefined,
	mimeType?: ModelTypes["Post_Thumbnail_mimeType_operator"] | undefined,
	filesize?: ModelTypes["Post_Thumbnail_filesize_operator"] | undefined,
	width?: ModelTypes["Post_Thumbnail_width_operator"] | undefined,
	height?: ModelTypes["Post_Thumbnail_height_operator"] | undefined,
	focalX?: ModelTypes["Post_Thumbnail_focalX_operator"] | undefined,
	focalY?: ModelTypes["Post_Thumbnail_focalY_operator"] | undefined,
	sizes__small__url?: ModelTypes["Post_Thumbnail_sizes__small__url_operator"] | undefined,
	sizes__small__width?: ModelTypes["Post_Thumbnail_sizes__small__width_operator"] | undefined,
	sizes__small__height?: ModelTypes["Post_Thumbnail_sizes__small__height_operator"] | undefined,
	sizes__small__mimeType?: ModelTypes["Post_Thumbnail_sizes__small__mimeType_operator"] | undefined,
	sizes__small__filesize?: ModelTypes["Post_Thumbnail_sizes__small__filesize_operator"] | undefined,
	sizes__small__filename?: ModelTypes["Post_Thumbnail_sizes__small__filename_operator"] | undefined,
	sizes__medium__url?: ModelTypes["Post_Thumbnail_sizes__medium__url_operator"] | undefined,
	sizes__medium__width?: ModelTypes["Post_Thumbnail_sizes__medium__width_operator"] | undefined,
	sizes__medium__height?: ModelTypes["Post_Thumbnail_sizes__medium__height_operator"] | undefined,
	sizes__medium__mimeType?: ModelTypes["Post_Thumbnail_sizes__medium__mimeType_operator"] | undefined,
	sizes__medium__filesize?: ModelTypes["Post_Thumbnail_sizes__medium__filesize_operator"] | undefined,
	sizes__medium__filename?: ModelTypes["Post_Thumbnail_sizes__medium__filename_operator"] | undefined,
	sizes__large__url?: ModelTypes["Post_Thumbnail_sizes__large__url_operator"] | undefined,
	sizes__large__width?: ModelTypes["Post_Thumbnail_sizes__large__width_operator"] | undefined,
	sizes__large__height?: ModelTypes["Post_Thumbnail_sizes__large__height_operator"] | undefined,
	sizes__large__mimeType?: ModelTypes["Post_Thumbnail_sizes__large__mimeType_operator"] | undefined,
	sizes__large__filesize?: ModelTypes["Post_Thumbnail_sizes__large__filesize_operator"] | undefined,
	sizes__large__filename?: ModelTypes["Post_Thumbnail_sizes__large__filename_operator"] | undefined,
	sizes__small_jpeg__url?: ModelTypes["Post_Thumbnail_sizes__small_jpeg__url_operator"] | undefined,
	sizes__small_jpeg__width?: ModelTypes["Post_Thumbnail_sizes__small_jpeg__width_operator"] | undefined,
	sizes__small_jpeg__height?: ModelTypes["Post_Thumbnail_sizes__small_jpeg__height_operator"] | undefined,
	sizes__small_jpeg__mimeType?: ModelTypes["Post_Thumbnail_sizes__small_jpeg__mimeType_operator"] | undefined,
	sizes__small_jpeg__filesize?: ModelTypes["Post_Thumbnail_sizes__small_jpeg__filesize_operator"] | undefined,
	sizes__small_jpeg__filename?: ModelTypes["Post_Thumbnail_sizes__small_jpeg__filename_operator"] | undefined,
	sizes__medium_jpeg__url?: ModelTypes["Post_Thumbnail_sizes__medium_jpeg__url_operator"] | undefined,
	sizes__medium_jpeg__width?: ModelTypes["Post_Thumbnail_sizes__medium_jpeg__width_operator"] | undefined,
	sizes__medium_jpeg__height?: ModelTypes["Post_Thumbnail_sizes__medium_jpeg__height_operator"] | undefined,
	sizes__medium_jpeg__mimeType?: ModelTypes["Post_Thumbnail_sizes__medium_jpeg__mimeType_operator"] | undefined,
	sizes__medium_jpeg__filesize?: ModelTypes["Post_Thumbnail_sizes__medium_jpeg__filesize_operator"] | undefined,
	sizes__medium_jpeg__filename?: ModelTypes["Post_Thumbnail_sizes__medium_jpeg__filename_operator"] | undefined,
	sizes__large_jpeg__url?: ModelTypes["Post_Thumbnail_sizes__large_jpeg__url_operator"] | undefined,
	sizes__large_jpeg__width?: ModelTypes["Post_Thumbnail_sizes__large_jpeg__width_operator"] | undefined,
	sizes__large_jpeg__height?: ModelTypes["Post_Thumbnail_sizes__large_jpeg__height_operator"] | undefined,
	sizes__large_jpeg__mimeType?: ModelTypes["Post_Thumbnail_sizes__large_jpeg__mimeType_operator"] | undefined,
	sizes__large_jpeg__filesize?: ModelTypes["Post_Thumbnail_sizes__large_jpeg__filesize_operator"] | undefined,
	sizes__large_jpeg__filename?: ModelTypes["Post_Thumbnail_sizes__large_jpeg__filename_operator"] | undefined,
	id?: ModelTypes["Post_Thumbnail_id_operator"] | undefined,
	AND?: Array<ModelTypes["Post_Thumbnail_where_and"] | undefined> | undefined,
	OR?: Array<ModelTypes["Post_Thumbnail_where_or"] | undefined> | undefined
};
	["Post_Banner_where"]: {
	alt?: ModelTypes["Post_Banner_alt_operator"] | undefined,
	updatedAt?: ModelTypes["Post_Banner_updatedAt_operator"] | undefined,
	createdAt?: ModelTypes["Post_Banner_createdAt_operator"] | undefined,
	url?: ModelTypes["Post_Banner_url_operator"] | undefined,
	filename?: ModelTypes["Post_Banner_filename_operator"] | undefined,
	mimeType?: ModelTypes["Post_Banner_mimeType_operator"] | undefined,
	filesize?: ModelTypes["Post_Banner_filesize_operator"] | undefined,
	width?: ModelTypes["Post_Banner_width_operator"] | undefined,
	height?: ModelTypes["Post_Banner_height_operator"] | undefined,
	focalX?: ModelTypes["Post_Banner_focalX_operator"] | undefined,
	focalY?: ModelTypes["Post_Banner_focalY_operator"] | undefined,
	sizes__small__url?: ModelTypes["Post_Banner_sizes__small__url_operator"] | undefined,
	sizes__small__width?: ModelTypes["Post_Banner_sizes__small__width_operator"] | undefined,
	sizes__small__height?: ModelTypes["Post_Banner_sizes__small__height_operator"] | undefined,
	sizes__small__mimeType?: ModelTypes["Post_Banner_sizes__small__mimeType_operator"] | undefined,
	sizes__small__filesize?: ModelTypes["Post_Banner_sizes__small__filesize_operator"] | undefined,
	sizes__small__filename?: ModelTypes["Post_Banner_sizes__small__filename_operator"] | undefined,
	sizes__medium__url?: ModelTypes["Post_Banner_sizes__medium__url_operator"] | undefined,
	sizes__medium__width?: ModelTypes["Post_Banner_sizes__medium__width_operator"] | undefined,
	sizes__medium__height?: ModelTypes["Post_Banner_sizes__medium__height_operator"] | undefined,
	sizes__medium__mimeType?: ModelTypes["Post_Banner_sizes__medium__mimeType_operator"] | undefined,
	sizes__medium__filesize?: ModelTypes["Post_Banner_sizes__medium__filesize_operator"] | undefined,
	sizes__medium__filename?: ModelTypes["Post_Banner_sizes__medium__filename_operator"] | undefined,
	sizes__large__url?: ModelTypes["Post_Banner_sizes__large__url_operator"] | undefined,
	sizes__large__width?: ModelTypes["Post_Banner_sizes__large__width_operator"] | undefined,
	sizes__large__height?: ModelTypes["Post_Banner_sizes__large__height_operator"] | undefined,
	sizes__large__mimeType?: ModelTypes["Post_Banner_sizes__large__mimeType_operator"] | undefined,
	sizes__large__filesize?: ModelTypes["Post_Banner_sizes__large__filesize_operator"] | undefined,
	sizes__large__filename?: ModelTypes["Post_Banner_sizes__large__filename_operator"] | undefined,
	sizes__small_jpeg__url?: ModelTypes["Post_Banner_sizes__small_jpeg__url_operator"] | undefined,
	sizes__small_jpeg__width?: ModelTypes["Post_Banner_sizes__small_jpeg__width_operator"] | undefined,
	sizes__small_jpeg__height?: ModelTypes["Post_Banner_sizes__small_jpeg__height_operator"] | undefined,
	sizes__small_jpeg__mimeType?: ModelTypes["Post_Banner_sizes__small_jpeg__mimeType_operator"] | undefined,
	sizes__small_jpeg__filesize?: ModelTypes["Post_Banner_sizes__small_jpeg__filesize_operator"] | undefined,
	sizes__small_jpeg__filename?: ModelTypes["Post_Banner_sizes__small_jpeg__filename_operator"] | undefined,
	sizes__medium_jpeg__url?: ModelTypes["Post_Banner_sizes__medium_jpeg__url_operator"] | undefined,
	sizes__medium_jpeg__width?: ModelTypes["Post_Banner_sizes__medium_jpeg__width_operator"] | undefined,
	sizes__medium_jpeg__height?: ModelTypes["Post_Banner_sizes__medium_jpeg__height_operator"] | undefined,
	sizes__medium_jpeg__mimeType?: ModelTypes["Post_Banner_sizes__medium_jpeg__mimeType_operator"] | undefined,
	sizes__medium_jpeg__filesize?: ModelTypes["Post_Banner_sizes__medium_jpeg__filesize_operator"] | undefined,
	sizes__medium_jpeg__filename?: ModelTypes["Post_Banner_sizes__medium_jpeg__filename_operator"] | undefined,
	sizes__large_jpeg__url?: ModelTypes["Post_Banner_sizes__large_jpeg__url_operator"] | undefined,
	sizes__large_jpeg__width?: ModelTypes["Post_Banner_sizes__large_jpeg__width_operator"] | undefined,
	sizes__large_jpeg__height?: ModelTypes["Post_Banner_sizes__large_jpeg__height_operator"] | undefined,
	sizes__large_jpeg__mimeType?: ModelTypes["Post_Banner_sizes__large_jpeg__mimeType_operator"] | undefined,
	sizes__large_jpeg__filesize?: ModelTypes["Post_Banner_sizes__large_jpeg__filesize_operator"] | undefined,
	sizes__large_jpeg__filename?: ModelTypes["Post_Banner_sizes__large_jpeg__filename_operator"] | undefined,
	id?: ModelTypes["Post_Banner_id_operator"] | undefined,
	AND?: Array<ModelTypes["Post_Banner_where_and"] | undefined> | undefined,
	OR?: Array<ModelTypes["Post_Banner_where_or"] | undefined> | undefined
};
	["Post_Banner_alt_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Post_Banner_updatedAt_operator"]: {
	equals?: ModelTypes["DateTime"] | undefined,
	not_equals?: ModelTypes["DateTime"] | undefined,
	greater_than_equal?: ModelTypes["DateTime"] | undefined,
	greater_than?: ModelTypes["DateTime"] | undefined,
	less_than_equal?: ModelTypes["DateTime"] | undefined,
	less_than?: ModelTypes["DateTime"] | undefined,
	like?: ModelTypes["DateTime"] | undefined,
	exists?: boolean | undefined
};
	["Post_Banner_createdAt_operator"]: {
	equals?: ModelTypes["DateTime"] | undefined,
	not_equals?: ModelTypes["DateTime"] | undefined,
	greater_than_equal?: ModelTypes["DateTime"] | undefined,
	greater_than?: ModelTypes["DateTime"] | undefined,
	less_than_equal?: ModelTypes["DateTime"] | undefined,
	less_than?: ModelTypes["DateTime"] | undefined,
	like?: ModelTypes["DateTime"] | undefined,
	exists?: boolean | undefined
};
	["Post_Banner_url_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Post_Banner_filename_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Post_Banner_mimeType_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Post_Banner_filesize_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Post_Banner_width_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Post_Banner_height_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Post_Banner_focalX_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Post_Banner_focalY_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Post_Banner_sizes__small__url_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Post_Banner_sizes__small__width_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Post_Banner_sizes__small__height_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Post_Banner_sizes__small__mimeType_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Post_Banner_sizes__small__filesize_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Post_Banner_sizes__small__filename_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Post_Banner_sizes__medium__url_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Post_Banner_sizes__medium__width_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Post_Banner_sizes__medium__height_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Post_Banner_sizes__medium__mimeType_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Post_Banner_sizes__medium__filesize_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Post_Banner_sizes__medium__filename_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Post_Banner_sizes__large__url_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Post_Banner_sizes__large__width_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Post_Banner_sizes__large__height_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Post_Banner_sizes__large__mimeType_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Post_Banner_sizes__large__filesize_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Post_Banner_sizes__large__filename_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Post_Banner_sizes__small_jpeg__url_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Post_Banner_sizes__small_jpeg__width_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Post_Banner_sizes__small_jpeg__height_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Post_Banner_sizes__small_jpeg__mimeType_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Post_Banner_sizes__small_jpeg__filesize_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Post_Banner_sizes__small_jpeg__filename_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Post_Banner_sizes__medium_jpeg__url_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Post_Banner_sizes__medium_jpeg__width_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Post_Banner_sizes__medium_jpeg__height_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Post_Banner_sizes__medium_jpeg__mimeType_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Post_Banner_sizes__medium_jpeg__filesize_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Post_Banner_sizes__medium_jpeg__filename_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Post_Banner_sizes__large_jpeg__url_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Post_Banner_sizes__large_jpeg__width_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Post_Banner_sizes__large_jpeg__height_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Post_Banner_sizes__large_jpeg__mimeType_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Post_Banner_sizes__large_jpeg__filesize_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Post_Banner_sizes__large_jpeg__filename_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Post_Banner_id_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Post_Banner_where_and"]: {
	alt?: ModelTypes["Post_Banner_alt_operator"] | undefined,
	updatedAt?: ModelTypes["Post_Banner_updatedAt_operator"] | undefined,
	createdAt?: ModelTypes["Post_Banner_createdAt_operator"] | undefined,
	url?: ModelTypes["Post_Banner_url_operator"] | undefined,
	filename?: ModelTypes["Post_Banner_filename_operator"] | undefined,
	mimeType?: ModelTypes["Post_Banner_mimeType_operator"] | undefined,
	filesize?: ModelTypes["Post_Banner_filesize_operator"] | undefined,
	width?: ModelTypes["Post_Banner_width_operator"] | undefined,
	height?: ModelTypes["Post_Banner_height_operator"] | undefined,
	focalX?: ModelTypes["Post_Banner_focalX_operator"] | undefined,
	focalY?: ModelTypes["Post_Banner_focalY_operator"] | undefined,
	sizes__small__url?: ModelTypes["Post_Banner_sizes__small__url_operator"] | undefined,
	sizes__small__width?: ModelTypes["Post_Banner_sizes__small__width_operator"] | undefined,
	sizes__small__height?: ModelTypes["Post_Banner_sizes__small__height_operator"] | undefined,
	sizes__small__mimeType?: ModelTypes["Post_Banner_sizes__small__mimeType_operator"] | undefined,
	sizes__small__filesize?: ModelTypes["Post_Banner_sizes__small__filesize_operator"] | undefined,
	sizes__small__filename?: ModelTypes["Post_Banner_sizes__small__filename_operator"] | undefined,
	sizes__medium__url?: ModelTypes["Post_Banner_sizes__medium__url_operator"] | undefined,
	sizes__medium__width?: ModelTypes["Post_Banner_sizes__medium__width_operator"] | undefined,
	sizes__medium__height?: ModelTypes["Post_Banner_sizes__medium__height_operator"] | undefined,
	sizes__medium__mimeType?: ModelTypes["Post_Banner_sizes__medium__mimeType_operator"] | undefined,
	sizes__medium__filesize?: ModelTypes["Post_Banner_sizes__medium__filesize_operator"] | undefined,
	sizes__medium__filename?: ModelTypes["Post_Banner_sizes__medium__filename_operator"] | undefined,
	sizes__large__url?: ModelTypes["Post_Banner_sizes__large__url_operator"] | undefined,
	sizes__large__width?: ModelTypes["Post_Banner_sizes__large__width_operator"] | undefined,
	sizes__large__height?: ModelTypes["Post_Banner_sizes__large__height_operator"] | undefined,
	sizes__large__mimeType?: ModelTypes["Post_Banner_sizes__large__mimeType_operator"] | undefined,
	sizes__large__filesize?: ModelTypes["Post_Banner_sizes__large__filesize_operator"] | undefined,
	sizes__large__filename?: ModelTypes["Post_Banner_sizes__large__filename_operator"] | undefined,
	sizes__small_jpeg__url?: ModelTypes["Post_Banner_sizes__small_jpeg__url_operator"] | undefined,
	sizes__small_jpeg__width?: ModelTypes["Post_Banner_sizes__small_jpeg__width_operator"] | undefined,
	sizes__small_jpeg__height?: ModelTypes["Post_Banner_sizes__small_jpeg__height_operator"] | undefined,
	sizes__small_jpeg__mimeType?: ModelTypes["Post_Banner_sizes__small_jpeg__mimeType_operator"] | undefined,
	sizes__small_jpeg__filesize?: ModelTypes["Post_Banner_sizes__small_jpeg__filesize_operator"] | undefined,
	sizes__small_jpeg__filename?: ModelTypes["Post_Banner_sizes__small_jpeg__filename_operator"] | undefined,
	sizes__medium_jpeg__url?: ModelTypes["Post_Banner_sizes__medium_jpeg__url_operator"] | undefined,
	sizes__medium_jpeg__width?: ModelTypes["Post_Banner_sizes__medium_jpeg__width_operator"] | undefined,
	sizes__medium_jpeg__height?: ModelTypes["Post_Banner_sizes__medium_jpeg__height_operator"] | undefined,
	sizes__medium_jpeg__mimeType?: ModelTypes["Post_Banner_sizes__medium_jpeg__mimeType_operator"] | undefined,
	sizes__medium_jpeg__filesize?: ModelTypes["Post_Banner_sizes__medium_jpeg__filesize_operator"] | undefined,
	sizes__medium_jpeg__filename?: ModelTypes["Post_Banner_sizes__medium_jpeg__filename_operator"] | undefined,
	sizes__large_jpeg__url?: ModelTypes["Post_Banner_sizes__large_jpeg__url_operator"] | undefined,
	sizes__large_jpeg__width?: ModelTypes["Post_Banner_sizes__large_jpeg__width_operator"] | undefined,
	sizes__large_jpeg__height?: ModelTypes["Post_Banner_sizes__large_jpeg__height_operator"] | undefined,
	sizes__large_jpeg__mimeType?: ModelTypes["Post_Banner_sizes__large_jpeg__mimeType_operator"] | undefined,
	sizes__large_jpeg__filesize?: ModelTypes["Post_Banner_sizes__large_jpeg__filesize_operator"] | undefined,
	sizes__large_jpeg__filename?: ModelTypes["Post_Banner_sizes__large_jpeg__filename_operator"] | undefined,
	id?: ModelTypes["Post_Banner_id_operator"] | undefined,
	AND?: Array<ModelTypes["Post_Banner_where_and"] | undefined> | undefined,
	OR?: Array<ModelTypes["Post_Banner_where_or"] | undefined> | undefined
};
	["Post_Banner_where_or"]: {
	alt?: ModelTypes["Post_Banner_alt_operator"] | undefined,
	updatedAt?: ModelTypes["Post_Banner_updatedAt_operator"] | undefined,
	createdAt?: ModelTypes["Post_Banner_createdAt_operator"] | undefined,
	url?: ModelTypes["Post_Banner_url_operator"] | undefined,
	filename?: ModelTypes["Post_Banner_filename_operator"] | undefined,
	mimeType?: ModelTypes["Post_Banner_mimeType_operator"] | undefined,
	filesize?: ModelTypes["Post_Banner_filesize_operator"] | undefined,
	width?: ModelTypes["Post_Banner_width_operator"] | undefined,
	height?: ModelTypes["Post_Banner_height_operator"] | undefined,
	focalX?: ModelTypes["Post_Banner_focalX_operator"] | undefined,
	focalY?: ModelTypes["Post_Banner_focalY_operator"] | undefined,
	sizes__small__url?: ModelTypes["Post_Banner_sizes__small__url_operator"] | undefined,
	sizes__small__width?: ModelTypes["Post_Banner_sizes__small__width_operator"] | undefined,
	sizes__small__height?: ModelTypes["Post_Banner_sizes__small__height_operator"] | undefined,
	sizes__small__mimeType?: ModelTypes["Post_Banner_sizes__small__mimeType_operator"] | undefined,
	sizes__small__filesize?: ModelTypes["Post_Banner_sizes__small__filesize_operator"] | undefined,
	sizes__small__filename?: ModelTypes["Post_Banner_sizes__small__filename_operator"] | undefined,
	sizes__medium__url?: ModelTypes["Post_Banner_sizes__medium__url_operator"] | undefined,
	sizes__medium__width?: ModelTypes["Post_Banner_sizes__medium__width_operator"] | undefined,
	sizes__medium__height?: ModelTypes["Post_Banner_sizes__medium__height_operator"] | undefined,
	sizes__medium__mimeType?: ModelTypes["Post_Banner_sizes__medium__mimeType_operator"] | undefined,
	sizes__medium__filesize?: ModelTypes["Post_Banner_sizes__medium__filesize_operator"] | undefined,
	sizes__medium__filename?: ModelTypes["Post_Banner_sizes__medium__filename_operator"] | undefined,
	sizes__large__url?: ModelTypes["Post_Banner_sizes__large__url_operator"] | undefined,
	sizes__large__width?: ModelTypes["Post_Banner_sizes__large__width_operator"] | undefined,
	sizes__large__height?: ModelTypes["Post_Banner_sizes__large__height_operator"] | undefined,
	sizes__large__mimeType?: ModelTypes["Post_Banner_sizes__large__mimeType_operator"] | undefined,
	sizes__large__filesize?: ModelTypes["Post_Banner_sizes__large__filesize_operator"] | undefined,
	sizes__large__filename?: ModelTypes["Post_Banner_sizes__large__filename_operator"] | undefined,
	sizes__small_jpeg__url?: ModelTypes["Post_Banner_sizes__small_jpeg__url_operator"] | undefined,
	sizes__small_jpeg__width?: ModelTypes["Post_Banner_sizes__small_jpeg__width_operator"] | undefined,
	sizes__small_jpeg__height?: ModelTypes["Post_Banner_sizes__small_jpeg__height_operator"] | undefined,
	sizes__small_jpeg__mimeType?: ModelTypes["Post_Banner_sizes__small_jpeg__mimeType_operator"] | undefined,
	sizes__small_jpeg__filesize?: ModelTypes["Post_Banner_sizes__small_jpeg__filesize_operator"] | undefined,
	sizes__small_jpeg__filename?: ModelTypes["Post_Banner_sizes__small_jpeg__filename_operator"] | undefined,
	sizes__medium_jpeg__url?: ModelTypes["Post_Banner_sizes__medium_jpeg__url_operator"] | undefined,
	sizes__medium_jpeg__width?: ModelTypes["Post_Banner_sizes__medium_jpeg__width_operator"] | undefined,
	sizes__medium_jpeg__height?: ModelTypes["Post_Banner_sizes__medium_jpeg__height_operator"] | undefined,
	sizes__medium_jpeg__mimeType?: ModelTypes["Post_Banner_sizes__medium_jpeg__mimeType_operator"] | undefined,
	sizes__medium_jpeg__filesize?: ModelTypes["Post_Banner_sizes__medium_jpeg__filesize_operator"] | undefined,
	sizes__medium_jpeg__filename?: ModelTypes["Post_Banner_sizes__medium_jpeg__filename_operator"] | undefined,
	sizes__large_jpeg__url?: ModelTypes["Post_Banner_sizes__large_jpeg__url_operator"] | undefined,
	sizes__large_jpeg__width?: ModelTypes["Post_Banner_sizes__large_jpeg__width_operator"] | undefined,
	sizes__large_jpeg__height?: ModelTypes["Post_Banner_sizes__large_jpeg__height_operator"] | undefined,
	sizes__large_jpeg__mimeType?: ModelTypes["Post_Banner_sizes__large_jpeg__mimeType_operator"] | undefined,
	sizes__large_jpeg__filesize?: ModelTypes["Post_Banner_sizes__large_jpeg__filesize_operator"] | undefined,
	sizes__large_jpeg__filename?: ModelTypes["Post_Banner_sizes__large_jpeg__filename_operator"] | undefined,
	id?: ModelTypes["Post_Banner_id_operator"] | undefined,
	AND?: Array<ModelTypes["Post_Banner_where_and"] | undefined> | undefined,
	OR?: Array<ModelTypes["Post_Banner_where_or"] | undefined> | undefined
};
	["Post_Criteria"]: {
		audiences?: Array<ModelTypes["Audience"]> | undefined,
	dates?: Array<ModelTypes["Date"]> | undefined,
	categories?: Array<ModelTypes["EventCategory"]> | undefined,
	tags?: Array<ModelTypes["Tag"]> | undefined
};
	["Posts"]: {
		docs?: Array<ModelTypes["Post"] | undefined> | undefined,
	hasNextPage?: boolean | undefined,
	hasPrevPage?: boolean | undefined,
	limit?: number | undefined,
	nextPage?: number | undefined,
	offset?: number | undefined,
	page?: number | undefined,
	pagingCounter?: number | undefined,
	prevPage?: number | undefined,
	totalDocs?: number | undefined,
	totalPages?: number | undefined
};
	["Post_where"]: {
	title?: ModelTypes["Post_title_operator"] | undefined,
	region?: ModelTypes["Post_region_operator"] | undefined,
	authur?: ModelTypes["Post_authur_operator"] | undefined,
	publishDateTime?: ModelTypes["Post_publishDateTime_operator"] | undefined,
	permalink?: ModelTypes["Post_permalink_operator"] | undefined,
	status?: ModelTypes["Post_status_operator"] | undefined,
	legacyContent?: ModelTypes["Post_legacyContent_operator"] | undefined,
	showLegacyContent?: ModelTypes["Post_showLegacyContent_operator"] | undefined,
	blogCategories?: ModelTypes["Post_blogCategories_operator"] | undefined,
	thumbnail?: ModelTypes["Post_thumbnail_operator"] | undefined,
	banner?: ModelTypes["Post_banner_operator"] | undefined,
	criteria__audiences?: ModelTypes["Post_criteria__audiences_operator"] | undefined,
	criteria__dates?: ModelTypes["Post_criteria__dates_operator"] | undefined,
	criteria__categories?: ModelTypes["Post_criteria__categories_operator"] | undefined,
	criteria__tags?: ModelTypes["Post_criteria__tags_operator"] | undefined,
	location?: ModelTypes["Post_location_operator"] | undefined,
	views?: ModelTypes["Post_views_operator"] | undefined,
	homePageInternalClick?: ModelTypes["Post_homePageInternalClick_operator"] | undefined,
	homePageImpression?: ModelTypes["Post_homePageImpression_operator"] | undefined,
	blogListInternalClick?: ModelTypes["Post_blogListInternalClick_operator"] | undefined,
	blogListImpression?: ModelTypes["Post_blogListImpression_operator"] | undefined,
	blogDetailInternalClick?: ModelTypes["Post_blogDetailInternalClick_operator"] | undefined,
	blogDetailImpression?: ModelTypes["Post_blogDetailImpression_operator"] | undefined,
	relatedEventInternalClick?: ModelTypes["Post_relatedEventInternalClick_operator"] | undefined,
	relatedEventImpression?: ModelTypes["Post_relatedEventImpression_operator"] | undefined,
	latestEventInternalClick?: ModelTypes["Post_latestEventInternalClick_operator"] | undefined,
	latestEventImpression?: ModelTypes["Post_latestEventImpression_operator"] | undefined,
	internalClick?: ModelTypes["Post_internalClick_operator"] | undefined,
	totalImpression?: ModelTypes["Post_totalImpression_operator"] | undefined,
	externalClick?: ModelTypes["Post_externalClick_operator"] | undefined,
	outboundClick?: ModelTypes["Post_outboundClick_operator"] | undefined,
	legacyGallery?: ModelTypes["Post_legacyGallery_operator"] | undefined,
	updatedAt?: ModelTypes["Post_updatedAt_operator"] | undefined,
	createdAt?: ModelTypes["Post_createdAt_operator"] | undefined,
	id?: ModelTypes["Post_id_operator"] | undefined,
	AND?: Array<ModelTypes["Post_where_and"] | undefined> | undefined,
	OR?: Array<ModelTypes["Post_where_or"] | undefined> | undefined
};
	["Post_title_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Post_region_operator"]: {
	equals?: ModelTypes["Post_region_Input"] | undefined,
	not_equals?: ModelTypes["Post_region_Input"] | undefined,
	in?: Array<ModelTypes["Post_region_Input"] | undefined> | undefined,
	not_in?: Array<ModelTypes["Post_region_Input"] | undefined> | undefined,
	all?: Array<ModelTypes["Post_region_Input"] | undefined> | undefined,
	exists?: boolean | undefined
};
	["Post_region_Input"]:Post_region_Input;
	["Post_authur_operator"]: {
	equals?: ModelTypes["JSON"] | undefined,
	not_equals?: ModelTypes["JSON"] | undefined,
	in?: Array<ModelTypes["JSON"] | undefined> | undefined,
	not_in?: Array<ModelTypes["JSON"] | undefined> | undefined,
	all?: Array<ModelTypes["JSON"] | undefined> | undefined,
	exists?: boolean | undefined
};
	["Post_publishDateTime_operator"]: {
	equals?: ModelTypes["DateTime"] | undefined,
	not_equals?: ModelTypes["DateTime"] | undefined,
	greater_than_equal?: ModelTypes["DateTime"] | undefined,
	greater_than?: ModelTypes["DateTime"] | undefined,
	less_than_equal?: ModelTypes["DateTime"] | undefined,
	less_than?: ModelTypes["DateTime"] | undefined,
	like?: ModelTypes["DateTime"] | undefined,
	exists?: boolean | undefined
};
	["Post_permalink_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Post_status_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined
};
	["Post_legacyContent_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	exists?: boolean | undefined
};
	["Post_showLegacyContent_operator"]: {
	equals?: boolean | undefined,
	not_equals?: boolean | undefined,
	exists?: boolean | undefined
};
	["Post_blogCategories_operator"]: {
	equals?: ModelTypes["JSON"] | undefined,
	not_equals?: ModelTypes["JSON"] | undefined,
	in?: Array<ModelTypes["JSON"] | undefined> | undefined,
	not_in?: Array<ModelTypes["JSON"] | undefined> | undefined,
	all?: Array<ModelTypes["JSON"] | undefined> | undefined,
	exists?: boolean | undefined
};
	["Post_thumbnail_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	exists?: boolean | undefined
};
	["Post_banner_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	exists?: boolean | undefined
};
	["Post_criteria__audiences_operator"]: {
	equals?: ModelTypes["JSON"] | undefined,
	not_equals?: ModelTypes["JSON"] | undefined,
	in?: Array<ModelTypes["JSON"] | undefined> | undefined,
	not_in?: Array<ModelTypes["JSON"] | undefined> | undefined,
	all?: Array<ModelTypes["JSON"] | undefined> | undefined,
	exists?: boolean | undefined
};
	["Post_criteria__dates_operator"]: {
	equals?: ModelTypes["JSON"] | undefined,
	not_equals?: ModelTypes["JSON"] | undefined,
	in?: Array<ModelTypes["JSON"] | undefined> | undefined,
	not_in?: Array<ModelTypes["JSON"] | undefined> | undefined,
	all?: Array<ModelTypes["JSON"] | undefined> | undefined,
	exists?: boolean | undefined
};
	["Post_criteria__categories_operator"]: {
	equals?: ModelTypes["JSON"] | undefined,
	not_equals?: ModelTypes["JSON"] | undefined,
	in?: Array<ModelTypes["JSON"] | undefined> | undefined,
	not_in?: Array<ModelTypes["JSON"] | undefined> | undefined,
	all?: Array<ModelTypes["JSON"] | undefined> | undefined,
	exists?: boolean | undefined
};
	["Post_criteria__tags_operator"]: {
	equals?: ModelTypes["JSON"] | undefined,
	not_equals?: ModelTypes["JSON"] | undefined,
	in?: Array<ModelTypes["JSON"] | undefined> | undefined,
	not_in?: Array<ModelTypes["JSON"] | undefined> | undefined,
	all?: Array<ModelTypes["JSON"] | undefined> | undefined,
	exists?: boolean | undefined
};
	["Post_location_operator"]: {
	equals?: ModelTypes["JSON"] | undefined,
	not_equals?: ModelTypes["JSON"] | undefined,
	in?: Array<ModelTypes["JSON"] | undefined> | undefined,
	not_in?: Array<ModelTypes["JSON"] | undefined> | undefined,
	all?: Array<ModelTypes["JSON"] | undefined> | undefined,
	exists?: boolean | undefined
};
	["Post_views_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Post_homePageInternalClick_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Post_homePageImpression_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Post_blogListInternalClick_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Post_blogListImpression_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Post_blogDetailInternalClick_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Post_blogDetailImpression_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Post_relatedEventInternalClick_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Post_relatedEventImpression_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Post_latestEventInternalClick_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Post_latestEventImpression_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Post_internalClick_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Post_totalImpression_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Post_externalClick_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Post_outboundClick_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Post_legacyGallery_operator"]: {
	equals?: ModelTypes["JSON"] | undefined,
	not_equals?: ModelTypes["JSON"] | undefined,
	in?: Array<ModelTypes["JSON"] | undefined> | undefined,
	not_in?: Array<ModelTypes["JSON"] | undefined> | undefined,
	all?: Array<ModelTypes["JSON"] | undefined> | undefined,
	exists?: boolean | undefined
};
	["Post_updatedAt_operator"]: {
	equals?: ModelTypes["DateTime"] | undefined,
	not_equals?: ModelTypes["DateTime"] | undefined,
	greater_than_equal?: ModelTypes["DateTime"] | undefined,
	greater_than?: ModelTypes["DateTime"] | undefined,
	less_than_equal?: ModelTypes["DateTime"] | undefined,
	less_than?: ModelTypes["DateTime"] | undefined,
	like?: ModelTypes["DateTime"] | undefined,
	exists?: boolean | undefined
};
	["Post_createdAt_operator"]: {
	equals?: ModelTypes["DateTime"] | undefined,
	not_equals?: ModelTypes["DateTime"] | undefined,
	greater_than_equal?: ModelTypes["DateTime"] | undefined,
	greater_than?: ModelTypes["DateTime"] | undefined,
	less_than_equal?: ModelTypes["DateTime"] | undefined,
	less_than?: ModelTypes["DateTime"] | undefined,
	like?: ModelTypes["DateTime"] | undefined,
	exists?: boolean | undefined
};
	["Post_id_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Post_where_and"]: {
	title?: ModelTypes["Post_title_operator"] | undefined,
	region?: ModelTypes["Post_region_operator"] | undefined,
	authur?: ModelTypes["Post_authur_operator"] | undefined,
	publishDateTime?: ModelTypes["Post_publishDateTime_operator"] | undefined,
	permalink?: ModelTypes["Post_permalink_operator"] | undefined,
	status?: ModelTypes["Post_status_operator"] | undefined,
	legacyContent?: ModelTypes["Post_legacyContent_operator"] | undefined,
	showLegacyContent?: ModelTypes["Post_showLegacyContent_operator"] | undefined,
	blogCategories?: ModelTypes["Post_blogCategories_operator"] | undefined,
	thumbnail?: ModelTypes["Post_thumbnail_operator"] | undefined,
	banner?: ModelTypes["Post_banner_operator"] | undefined,
	criteria__audiences?: ModelTypes["Post_criteria__audiences_operator"] | undefined,
	criteria__dates?: ModelTypes["Post_criteria__dates_operator"] | undefined,
	criteria__categories?: ModelTypes["Post_criteria__categories_operator"] | undefined,
	criteria__tags?: ModelTypes["Post_criteria__tags_operator"] | undefined,
	location?: ModelTypes["Post_location_operator"] | undefined,
	views?: ModelTypes["Post_views_operator"] | undefined,
	homePageInternalClick?: ModelTypes["Post_homePageInternalClick_operator"] | undefined,
	homePageImpression?: ModelTypes["Post_homePageImpression_operator"] | undefined,
	blogListInternalClick?: ModelTypes["Post_blogListInternalClick_operator"] | undefined,
	blogListImpression?: ModelTypes["Post_blogListImpression_operator"] | undefined,
	blogDetailInternalClick?: ModelTypes["Post_blogDetailInternalClick_operator"] | undefined,
	blogDetailImpression?: ModelTypes["Post_blogDetailImpression_operator"] | undefined,
	relatedEventInternalClick?: ModelTypes["Post_relatedEventInternalClick_operator"] | undefined,
	relatedEventImpression?: ModelTypes["Post_relatedEventImpression_operator"] | undefined,
	latestEventInternalClick?: ModelTypes["Post_latestEventInternalClick_operator"] | undefined,
	latestEventImpression?: ModelTypes["Post_latestEventImpression_operator"] | undefined,
	internalClick?: ModelTypes["Post_internalClick_operator"] | undefined,
	totalImpression?: ModelTypes["Post_totalImpression_operator"] | undefined,
	externalClick?: ModelTypes["Post_externalClick_operator"] | undefined,
	outboundClick?: ModelTypes["Post_outboundClick_operator"] | undefined,
	legacyGallery?: ModelTypes["Post_legacyGallery_operator"] | undefined,
	updatedAt?: ModelTypes["Post_updatedAt_operator"] | undefined,
	createdAt?: ModelTypes["Post_createdAt_operator"] | undefined,
	id?: ModelTypes["Post_id_operator"] | undefined,
	AND?: Array<ModelTypes["Post_where_and"] | undefined> | undefined,
	OR?: Array<ModelTypes["Post_where_or"] | undefined> | undefined
};
	["Post_where_or"]: {
	title?: ModelTypes["Post_title_operator"] | undefined,
	region?: ModelTypes["Post_region_operator"] | undefined,
	authur?: ModelTypes["Post_authur_operator"] | undefined,
	publishDateTime?: ModelTypes["Post_publishDateTime_operator"] | undefined,
	permalink?: ModelTypes["Post_permalink_operator"] | undefined,
	status?: ModelTypes["Post_status_operator"] | undefined,
	legacyContent?: ModelTypes["Post_legacyContent_operator"] | undefined,
	showLegacyContent?: ModelTypes["Post_showLegacyContent_operator"] | undefined,
	blogCategories?: ModelTypes["Post_blogCategories_operator"] | undefined,
	thumbnail?: ModelTypes["Post_thumbnail_operator"] | undefined,
	banner?: ModelTypes["Post_banner_operator"] | undefined,
	criteria__audiences?: ModelTypes["Post_criteria__audiences_operator"] | undefined,
	criteria__dates?: ModelTypes["Post_criteria__dates_operator"] | undefined,
	criteria__categories?: ModelTypes["Post_criteria__categories_operator"] | undefined,
	criteria__tags?: ModelTypes["Post_criteria__tags_operator"] | undefined,
	location?: ModelTypes["Post_location_operator"] | undefined,
	views?: ModelTypes["Post_views_operator"] | undefined,
	homePageInternalClick?: ModelTypes["Post_homePageInternalClick_operator"] | undefined,
	homePageImpression?: ModelTypes["Post_homePageImpression_operator"] | undefined,
	blogListInternalClick?: ModelTypes["Post_blogListInternalClick_operator"] | undefined,
	blogListImpression?: ModelTypes["Post_blogListImpression_operator"] | undefined,
	blogDetailInternalClick?: ModelTypes["Post_blogDetailInternalClick_operator"] | undefined,
	blogDetailImpression?: ModelTypes["Post_blogDetailImpression_operator"] | undefined,
	relatedEventInternalClick?: ModelTypes["Post_relatedEventInternalClick_operator"] | undefined,
	relatedEventImpression?: ModelTypes["Post_relatedEventImpression_operator"] | undefined,
	latestEventInternalClick?: ModelTypes["Post_latestEventInternalClick_operator"] | undefined,
	latestEventImpression?: ModelTypes["Post_latestEventImpression_operator"] | undefined,
	internalClick?: ModelTypes["Post_internalClick_operator"] | undefined,
	totalImpression?: ModelTypes["Post_totalImpression_operator"] | undefined,
	externalClick?: ModelTypes["Post_externalClick_operator"] | undefined,
	outboundClick?: ModelTypes["Post_outboundClick_operator"] | undefined,
	legacyGallery?: ModelTypes["Post_legacyGallery_operator"] | undefined,
	updatedAt?: ModelTypes["Post_updatedAt_operator"] | undefined,
	createdAt?: ModelTypes["Post_createdAt_operator"] | undefined,
	id?: ModelTypes["Post_id_operator"] | undefined,
	AND?: Array<ModelTypes["Post_where_and"] | undefined> | undefined,
	OR?: Array<ModelTypes["Post_where_or"] | undefined> | undefined
};
	["countPosts"]: {
		totalDocs?: number | undefined
};
	["postsDocAccess"]: {
		fields?: ModelTypes["PostsDocAccessFields"] | undefined,
	create?: ModelTypes["PostsCreateDocAccess"] | undefined,
	read?: ModelTypes["PostsReadDocAccess"] | undefined,
	update?: ModelTypes["PostsUpdateDocAccess"] | undefined,
	delete?: ModelTypes["PostsDeleteDocAccess"] | undefined
};
	["PostsDocAccessFields"]: {
		title?: ModelTypes["PostsDocAccessFields_title"] | undefined,
	region?: ModelTypes["PostsDocAccessFields_region"] | undefined,
	authur?: ModelTypes["PostsDocAccessFields_authur"] | undefined,
	publishDateTime?: ModelTypes["PostsDocAccessFields_publishDateTime"] | undefined,
	permalink?: ModelTypes["PostsDocAccessFields_permalink"] | undefined,
	status?: ModelTypes["PostsDocAccessFields_status"] | undefined,
	content?: ModelTypes["PostsDocAccessFields_content"] | undefined,
	legacyContent?: ModelTypes["PostsDocAccessFields_legacyContent"] | undefined,
	showLegacyContent?: ModelTypes["PostsDocAccessFields_showLegacyContent"] | undefined,
	blogCategories?: ModelTypes["PostsDocAccessFields_blogCategories"] | undefined,
	thumbnail?: ModelTypes["PostsDocAccessFields_thumbnail"] | undefined,
	banner?: ModelTypes["PostsDocAccessFields_banner"] | undefined,
	criteria?: ModelTypes["PostsDocAccessFields_criteria"] | undefined,
	location?: ModelTypes["PostsDocAccessFields_location"] | undefined,
	homePageInternalClick?: ModelTypes["PostsDocAccessFields_homePageInternalClick"] | undefined,
	homePageImpression?: ModelTypes["PostsDocAccessFields_homePageImpression"] | undefined,
	blogListInternalClick?: ModelTypes["PostsDocAccessFields_blogListInternalClick"] | undefined,
	blogListImpression?: ModelTypes["PostsDocAccessFields_blogListImpression"] | undefined,
	blogDetailInternalClick?: ModelTypes["PostsDocAccessFields_blogDetailInternalClick"] | undefined,
	blogDetailImpression?: ModelTypes["PostsDocAccessFields_blogDetailImpression"] | undefined,
	relatedEventInternalClick?: ModelTypes["PostsDocAccessFields_relatedEventInternalClick"] | undefined,
	relatedEventImpression?: ModelTypes["PostsDocAccessFields_relatedEventImpression"] | undefined,
	latestEventInternalClick?: ModelTypes["PostsDocAccessFields_latestEventInternalClick"] | undefined,
	latestEventImpression?: ModelTypes["PostsDocAccessFields_latestEventImpression"] | undefined,
	internalClick?: ModelTypes["PostsDocAccessFields_internalClick"] | undefined,
	totalImpression?: ModelTypes["PostsDocAccessFields_totalImpression"] | undefined,
	externalClick?: ModelTypes["PostsDocAccessFields_externalClick"] | undefined,
	outboundClick?: ModelTypes["PostsDocAccessFields_outboundClick"] | undefined,
	legacyGallery?: ModelTypes["PostsDocAccessFields_legacyGallery"] | undefined,
	updatedAt?: ModelTypes["PostsDocAccessFields_updatedAt"] | undefined,
	createdAt?: ModelTypes["PostsDocAccessFields_createdAt"] | undefined
};
	["PostsDocAccessFields_title"]: {
		create?: ModelTypes["PostsDocAccessFields_title_Create"] | undefined,
	read?: ModelTypes["PostsDocAccessFields_title_Read"] | undefined,
	update?: ModelTypes["PostsDocAccessFields_title_Update"] | undefined,
	delete?: ModelTypes["PostsDocAccessFields_title_Delete"] | undefined
};
	["PostsDocAccessFields_title_Create"]: {
		permission: boolean
};
	["PostsDocAccessFields_title_Read"]: {
		permission: boolean
};
	["PostsDocAccessFields_title_Update"]: {
		permission: boolean
};
	["PostsDocAccessFields_title_Delete"]: {
		permission: boolean
};
	["PostsDocAccessFields_region"]: {
		create?: ModelTypes["PostsDocAccessFields_region_Create"] | undefined,
	read?: ModelTypes["PostsDocAccessFields_region_Read"] | undefined,
	update?: ModelTypes["PostsDocAccessFields_region_Update"] | undefined,
	delete?: ModelTypes["PostsDocAccessFields_region_Delete"] | undefined
};
	["PostsDocAccessFields_region_Create"]: {
		permission: boolean
};
	["PostsDocAccessFields_region_Read"]: {
		permission: boolean
};
	["PostsDocAccessFields_region_Update"]: {
		permission: boolean
};
	["PostsDocAccessFields_region_Delete"]: {
		permission: boolean
};
	["PostsDocAccessFields_authur"]: {
		create?: ModelTypes["PostsDocAccessFields_authur_Create"] | undefined,
	read?: ModelTypes["PostsDocAccessFields_authur_Read"] | undefined,
	update?: ModelTypes["PostsDocAccessFields_authur_Update"] | undefined,
	delete?: ModelTypes["PostsDocAccessFields_authur_Delete"] | undefined
};
	["PostsDocAccessFields_authur_Create"]: {
		permission: boolean
};
	["PostsDocAccessFields_authur_Read"]: {
		permission: boolean
};
	["PostsDocAccessFields_authur_Update"]: {
		permission: boolean
};
	["PostsDocAccessFields_authur_Delete"]: {
		permission: boolean
};
	["PostsDocAccessFields_publishDateTime"]: {
		create?: ModelTypes["PostsDocAccessFields_publishDateTime_Create"] | undefined,
	read?: ModelTypes["PostsDocAccessFields_publishDateTime_Read"] | undefined,
	update?: ModelTypes["PostsDocAccessFields_publishDateTime_Update"] | undefined,
	delete?: ModelTypes["PostsDocAccessFields_publishDateTime_Delete"] | undefined
};
	["PostsDocAccessFields_publishDateTime_Create"]: {
		permission: boolean
};
	["PostsDocAccessFields_publishDateTime_Read"]: {
		permission: boolean
};
	["PostsDocAccessFields_publishDateTime_Update"]: {
		permission: boolean
};
	["PostsDocAccessFields_publishDateTime_Delete"]: {
		permission: boolean
};
	["PostsDocAccessFields_permalink"]: {
		create?: ModelTypes["PostsDocAccessFields_permalink_Create"] | undefined,
	read?: ModelTypes["PostsDocAccessFields_permalink_Read"] | undefined,
	update?: ModelTypes["PostsDocAccessFields_permalink_Update"] | undefined,
	delete?: ModelTypes["PostsDocAccessFields_permalink_Delete"] | undefined
};
	["PostsDocAccessFields_permalink_Create"]: {
		permission: boolean
};
	["PostsDocAccessFields_permalink_Read"]: {
		permission: boolean
};
	["PostsDocAccessFields_permalink_Update"]: {
		permission: boolean
};
	["PostsDocAccessFields_permalink_Delete"]: {
		permission: boolean
};
	["PostsDocAccessFields_status"]: {
		create?: ModelTypes["PostsDocAccessFields_status_Create"] | undefined,
	read?: ModelTypes["PostsDocAccessFields_status_Read"] | undefined,
	update?: ModelTypes["PostsDocAccessFields_status_Update"] | undefined,
	delete?: ModelTypes["PostsDocAccessFields_status_Delete"] | undefined
};
	["PostsDocAccessFields_status_Create"]: {
		permission: boolean
};
	["PostsDocAccessFields_status_Read"]: {
		permission: boolean
};
	["PostsDocAccessFields_status_Update"]: {
		permission: boolean
};
	["PostsDocAccessFields_status_Delete"]: {
		permission: boolean
};
	["PostsDocAccessFields_content"]: {
		create?: ModelTypes["PostsDocAccessFields_content_Create"] | undefined,
	read?: ModelTypes["PostsDocAccessFields_content_Read"] | undefined,
	update?: ModelTypes["PostsDocAccessFields_content_Update"] | undefined,
	delete?: ModelTypes["PostsDocAccessFields_content_Delete"] | undefined
};
	["PostsDocAccessFields_content_Create"]: {
		permission: boolean
};
	["PostsDocAccessFields_content_Read"]: {
		permission: boolean
};
	["PostsDocAccessFields_content_Update"]: {
		permission: boolean
};
	["PostsDocAccessFields_content_Delete"]: {
		permission: boolean
};
	["PostsDocAccessFields_legacyContent"]: {
		create?: ModelTypes["PostsDocAccessFields_legacyContent_Create"] | undefined,
	read?: ModelTypes["PostsDocAccessFields_legacyContent_Read"] | undefined,
	update?: ModelTypes["PostsDocAccessFields_legacyContent_Update"] | undefined,
	delete?: ModelTypes["PostsDocAccessFields_legacyContent_Delete"] | undefined
};
	["PostsDocAccessFields_legacyContent_Create"]: {
		permission: boolean
};
	["PostsDocAccessFields_legacyContent_Read"]: {
		permission: boolean
};
	["PostsDocAccessFields_legacyContent_Update"]: {
		permission: boolean
};
	["PostsDocAccessFields_legacyContent_Delete"]: {
		permission: boolean
};
	["PostsDocAccessFields_showLegacyContent"]: {
		create?: ModelTypes["PostsDocAccessFields_showLegacyContent_Create"] | undefined,
	read?: ModelTypes["PostsDocAccessFields_showLegacyContent_Read"] | undefined,
	update?: ModelTypes["PostsDocAccessFields_showLegacyContent_Update"] | undefined,
	delete?: ModelTypes["PostsDocAccessFields_showLegacyContent_Delete"] | undefined
};
	["PostsDocAccessFields_showLegacyContent_Create"]: {
		permission: boolean
};
	["PostsDocAccessFields_showLegacyContent_Read"]: {
		permission: boolean
};
	["PostsDocAccessFields_showLegacyContent_Update"]: {
		permission: boolean
};
	["PostsDocAccessFields_showLegacyContent_Delete"]: {
		permission: boolean
};
	["PostsDocAccessFields_blogCategories"]: {
		create?: ModelTypes["PostsDocAccessFields_blogCategories_Create"] | undefined,
	read?: ModelTypes["PostsDocAccessFields_blogCategories_Read"] | undefined,
	update?: ModelTypes["PostsDocAccessFields_blogCategories_Update"] | undefined,
	delete?: ModelTypes["PostsDocAccessFields_blogCategories_Delete"] | undefined
};
	["PostsDocAccessFields_blogCategories_Create"]: {
		permission: boolean
};
	["PostsDocAccessFields_blogCategories_Read"]: {
		permission: boolean
};
	["PostsDocAccessFields_blogCategories_Update"]: {
		permission: boolean
};
	["PostsDocAccessFields_blogCategories_Delete"]: {
		permission: boolean
};
	["PostsDocAccessFields_thumbnail"]: {
		create?: ModelTypes["PostsDocAccessFields_thumbnail_Create"] | undefined,
	read?: ModelTypes["PostsDocAccessFields_thumbnail_Read"] | undefined,
	update?: ModelTypes["PostsDocAccessFields_thumbnail_Update"] | undefined,
	delete?: ModelTypes["PostsDocAccessFields_thumbnail_Delete"] | undefined
};
	["PostsDocAccessFields_thumbnail_Create"]: {
		permission: boolean
};
	["PostsDocAccessFields_thumbnail_Read"]: {
		permission: boolean
};
	["PostsDocAccessFields_thumbnail_Update"]: {
		permission: boolean
};
	["PostsDocAccessFields_thumbnail_Delete"]: {
		permission: boolean
};
	["PostsDocAccessFields_banner"]: {
		create?: ModelTypes["PostsDocAccessFields_banner_Create"] | undefined,
	read?: ModelTypes["PostsDocAccessFields_banner_Read"] | undefined,
	update?: ModelTypes["PostsDocAccessFields_banner_Update"] | undefined,
	delete?: ModelTypes["PostsDocAccessFields_banner_Delete"] | undefined
};
	["PostsDocAccessFields_banner_Create"]: {
		permission: boolean
};
	["PostsDocAccessFields_banner_Read"]: {
		permission: boolean
};
	["PostsDocAccessFields_banner_Update"]: {
		permission: boolean
};
	["PostsDocAccessFields_banner_Delete"]: {
		permission: boolean
};
	["PostsDocAccessFields_criteria"]: {
		create?: ModelTypes["PostsDocAccessFields_criteria_Create"] | undefined,
	read?: ModelTypes["PostsDocAccessFields_criteria_Read"] | undefined,
	update?: ModelTypes["PostsDocAccessFields_criteria_Update"] | undefined,
	delete?: ModelTypes["PostsDocAccessFields_criteria_Delete"] | undefined,
	fields?: ModelTypes["PostsDocAccessFields_criteria_Fields"] | undefined
};
	["PostsDocAccessFields_criteria_Create"]: {
		permission: boolean
};
	["PostsDocAccessFields_criteria_Read"]: {
		permission: boolean
};
	["PostsDocAccessFields_criteria_Update"]: {
		permission: boolean
};
	["PostsDocAccessFields_criteria_Delete"]: {
		permission: boolean
};
	["PostsDocAccessFields_criteria_Fields"]: {
		audiences?: ModelTypes["PostsDocAccessFields_criteria_audiences"] | undefined,
	dates?: ModelTypes["PostsDocAccessFields_criteria_dates"] | undefined,
	categories?: ModelTypes["PostsDocAccessFields_criteria_categories"] | undefined,
	tags?: ModelTypes["PostsDocAccessFields_criteria_tags"] | undefined
};
	["PostsDocAccessFields_criteria_audiences"]: {
		create?: ModelTypes["PostsDocAccessFields_criteria_audiences_Create"] | undefined,
	read?: ModelTypes["PostsDocAccessFields_criteria_audiences_Read"] | undefined,
	update?: ModelTypes["PostsDocAccessFields_criteria_audiences_Update"] | undefined,
	delete?: ModelTypes["PostsDocAccessFields_criteria_audiences_Delete"] | undefined
};
	["PostsDocAccessFields_criteria_audiences_Create"]: {
		permission: boolean
};
	["PostsDocAccessFields_criteria_audiences_Read"]: {
		permission: boolean
};
	["PostsDocAccessFields_criteria_audiences_Update"]: {
		permission: boolean
};
	["PostsDocAccessFields_criteria_audiences_Delete"]: {
		permission: boolean
};
	["PostsDocAccessFields_criteria_dates"]: {
		create?: ModelTypes["PostsDocAccessFields_criteria_dates_Create"] | undefined,
	read?: ModelTypes["PostsDocAccessFields_criteria_dates_Read"] | undefined,
	update?: ModelTypes["PostsDocAccessFields_criteria_dates_Update"] | undefined,
	delete?: ModelTypes["PostsDocAccessFields_criteria_dates_Delete"] | undefined
};
	["PostsDocAccessFields_criteria_dates_Create"]: {
		permission: boolean
};
	["PostsDocAccessFields_criteria_dates_Read"]: {
		permission: boolean
};
	["PostsDocAccessFields_criteria_dates_Update"]: {
		permission: boolean
};
	["PostsDocAccessFields_criteria_dates_Delete"]: {
		permission: boolean
};
	["PostsDocAccessFields_criteria_categories"]: {
		create?: ModelTypes["PostsDocAccessFields_criteria_categories_Create"] | undefined,
	read?: ModelTypes["PostsDocAccessFields_criteria_categories_Read"] | undefined,
	update?: ModelTypes["PostsDocAccessFields_criteria_categories_Update"] | undefined,
	delete?: ModelTypes["PostsDocAccessFields_criteria_categories_Delete"] | undefined
};
	["PostsDocAccessFields_criteria_categories_Create"]: {
		permission: boolean
};
	["PostsDocAccessFields_criteria_categories_Read"]: {
		permission: boolean
};
	["PostsDocAccessFields_criteria_categories_Update"]: {
		permission: boolean
};
	["PostsDocAccessFields_criteria_categories_Delete"]: {
		permission: boolean
};
	["PostsDocAccessFields_criteria_tags"]: {
		create?: ModelTypes["PostsDocAccessFields_criteria_tags_Create"] | undefined,
	read?: ModelTypes["PostsDocAccessFields_criteria_tags_Read"] | undefined,
	update?: ModelTypes["PostsDocAccessFields_criteria_tags_Update"] | undefined,
	delete?: ModelTypes["PostsDocAccessFields_criteria_tags_Delete"] | undefined
};
	["PostsDocAccessFields_criteria_tags_Create"]: {
		permission: boolean
};
	["PostsDocAccessFields_criteria_tags_Read"]: {
		permission: boolean
};
	["PostsDocAccessFields_criteria_tags_Update"]: {
		permission: boolean
};
	["PostsDocAccessFields_criteria_tags_Delete"]: {
		permission: boolean
};
	["PostsDocAccessFields_location"]: {
		create?: ModelTypes["PostsDocAccessFields_location_Create"] | undefined,
	read?: ModelTypes["PostsDocAccessFields_location_Read"] | undefined,
	update?: ModelTypes["PostsDocAccessFields_location_Update"] | undefined,
	delete?: ModelTypes["PostsDocAccessFields_location_Delete"] | undefined
};
	["PostsDocAccessFields_location_Create"]: {
		permission: boolean
};
	["PostsDocAccessFields_location_Read"]: {
		permission: boolean
};
	["PostsDocAccessFields_location_Update"]: {
		permission: boolean
};
	["PostsDocAccessFields_location_Delete"]: {
		permission: boolean
};
	["PostsDocAccessFields_homePageInternalClick"]: {
		create?: ModelTypes["PostsDocAccessFields_homePageInternalClick_Create"] | undefined,
	read?: ModelTypes["PostsDocAccessFields_homePageInternalClick_Read"] | undefined,
	update?: ModelTypes["PostsDocAccessFields_homePageInternalClick_Update"] | undefined,
	delete?: ModelTypes["PostsDocAccessFields_homePageInternalClick_Delete"] | undefined
};
	["PostsDocAccessFields_homePageInternalClick_Create"]: {
		permission: boolean
};
	["PostsDocAccessFields_homePageInternalClick_Read"]: {
		permission: boolean
};
	["PostsDocAccessFields_homePageInternalClick_Update"]: {
		permission: boolean
};
	["PostsDocAccessFields_homePageInternalClick_Delete"]: {
		permission: boolean
};
	["PostsDocAccessFields_homePageImpression"]: {
		create?: ModelTypes["PostsDocAccessFields_homePageImpression_Create"] | undefined,
	read?: ModelTypes["PostsDocAccessFields_homePageImpression_Read"] | undefined,
	update?: ModelTypes["PostsDocAccessFields_homePageImpression_Update"] | undefined,
	delete?: ModelTypes["PostsDocAccessFields_homePageImpression_Delete"] | undefined
};
	["PostsDocAccessFields_homePageImpression_Create"]: {
		permission: boolean
};
	["PostsDocAccessFields_homePageImpression_Read"]: {
		permission: boolean
};
	["PostsDocAccessFields_homePageImpression_Update"]: {
		permission: boolean
};
	["PostsDocAccessFields_homePageImpression_Delete"]: {
		permission: boolean
};
	["PostsDocAccessFields_blogListInternalClick"]: {
		create?: ModelTypes["PostsDocAccessFields_blogListInternalClick_Create"] | undefined,
	read?: ModelTypes["PostsDocAccessFields_blogListInternalClick_Read"] | undefined,
	update?: ModelTypes["PostsDocAccessFields_blogListInternalClick_Update"] | undefined,
	delete?: ModelTypes["PostsDocAccessFields_blogListInternalClick_Delete"] | undefined
};
	["PostsDocAccessFields_blogListInternalClick_Create"]: {
		permission: boolean
};
	["PostsDocAccessFields_blogListInternalClick_Read"]: {
		permission: boolean
};
	["PostsDocAccessFields_blogListInternalClick_Update"]: {
		permission: boolean
};
	["PostsDocAccessFields_blogListInternalClick_Delete"]: {
		permission: boolean
};
	["PostsDocAccessFields_blogListImpression"]: {
		create?: ModelTypes["PostsDocAccessFields_blogListImpression_Create"] | undefined,
	read?: ModelTypes["PostsDocAccessFields_blogListImpression_Read"] | undefined,
	update?: ModelTypes["PostsDocAccessFields_blogListImpression_Update"] | undefined,
	delete?: ModelTypes["PostsDocAccessFields_blogListImpression_Delete"] | undefined
};
	["PostsDocAccessFields_blogListImpression_Create"]: {
		permission: boolean
};
	["PostsDocAccessFields_blogListImpression_Read"]: {
		permission: boolean
};
	["PostsDocAccessFields_blogListImpression_Update"]: {
		permission: boolean
};
	["PostsDocAccessFields_blogListImpression_Delete"]: {
		permission: boolean
};
	["PostsDocAccessFields_blogDetailInternalClick"]: {
		create?: ModelTypes["PostsDocAccessFields_blogDetailInternalClick_Create"] | undefined,
	read?: ModelTypes["PostsDocAccessFields_blogDetailInternalClick_Read"] | undefined,
	update?: ModelTypes["PostsDocAccessFields_blogDetailInternalClick_Update"] | undefined,
	delete?: ModelTypes["PostsDocAccessFields_blogDetailInternalClick_Delete"] | undefined
};
	["PostsDocAccessFields_blogDetailInternalClick_Create"]: {
		permission: boolean
};
	["PostsDocAccessFields_blogDetailInternalClick_Read"]: {
		permission: boolean
};
	["PostsDocAccessFields_blogDetailInternalClick_Update"]: {
		permission: boolean
};
	["PostsDocAccessFields_blogDetailInternalClick_Delete"]: {
		permission: boolean
};
	["PostsDocAccessFields_blogDetailImpression"]: {
		create?: ModelTypes["PostsDocAccessFields_blogDetailImpression_Create"] | undefined,
	read?: ModelTypes["PostsDocAccessFields_blogDetailImpression_Read"] | undefined,
	update?: ModelTypes["PostsDocAccessFields_blogDetailImpression_Update"] | undefined,
	delete?: ModelTypes["PostsDocAccessFields_blogDetailImpression_Delete"] | undefined
};
	["PostsDocAccessFields_blogDetailImpression_Create"]: {
		permission: boolean
};
	["PostsDocAccessFields_blogDetailImpression_Read"]: {
		permission: boolean
};
	["PostsDocAccessFields_blogDetailImpression_Update"]: {
		permission: boolean
};
	["PostsDocAccessFields_blogDetailImpression_Delete"]: {
		permission: boolean
};
	["PostsDocAccessFields_relatedEventInternalClick"]: {
		create?: ModelTypes["PostsDocAccessFields_relatedEventInternalClick_Create"] | undefined,
	read?: ModelTypes["PostsDocAccessFields_relatedEventInternalClick_Read"] | undefined,
	update?: ModelTypes["PostsDocAccessFields_relatedEventInternalClick_Update"] | undefined,
	delete?: ModelTypes["PostsDocAccessFields_relatedEventInternalClick_Delete"] | undefined
};
	["PostsDocAccessFields_relatedEventInternalClick_Create"]: {
		permission: boolean
};
	["PostsDocAccessFields_relatedEventInternalClick_Read"]: {
		permission: boolean
};
	["PostsDocAccessFields_relatedEventInternalClick_Update"]: {
		permission: boolean
};
	["PostsDocAccessFields_relatedEventInternalClick_Delete"]: {
		permission: boolean
};
	["PostsDocAccessFields_relatedEventImpression"]: {
		create?: ModelTypes["PostsDocAccessFields_relatedEventImpression_Create"] | undefined,
	read?: ModelTypes["PostsDocAccessFields_relatedEventImpression_Read"] | undefined,
	update?: ModelTypes["PostsDocAccessFields_relatedEventImpression_Update"] | undefined,
	delete?: ModelTypes["PostsDocAccessFields_relatedEventImpression_Delete"] | undefined
};
	["PostsDocAccessFields_relatedEventImpression_Create"]: {
		permission: boolean
};
	["PostsDocAccessFields_relatedEventImpression_Read"]: {
		permission: boolean
};
	["PostsDocAccessFields_relatedEventImpression_Update"]: {
		permission: boolean
};
	["PostsDocAccessFields_relatedEventImpression_Delete"]: {
		permission: boolean
};
	["PostsDocAccessFields_latestEventInternalClick"]: {
		create?: ModelTypes["PostsDocAccessFields_latestEventInternalClick_Create"] | undefined,
	read?: ModelTypes["PostsDocAccessFields_latestEventInternalClick_Read"] | undefined,
	update?: ModelTypes["PostsDocAccessFields_latestEventInternalClick_Update"] | undefined,
	delete?: ModelTypes["PostsDocAccessFields_latestEventInternalClick_Delete"] | undefined
};
	["PostsDocAccessFields_latestEventInternalClick_Create"]: {
		permission: boolean
};
	["PostsDocAccessFields_latestEventInternalClick_Read"]: {
		permission: boolean
};
	["PostsDocAccessFields_latestEventInternalClick_Update"]: {
		permission: boolean
};
	["PostsDocAccessFields_latestEventInternalClick_Delete"]: {
		permission: boolean
};
	["PostsDocAccessFields_latestEventImpression"]: {
		create?: ModelTypes["PostsDocAccessFields_latestEventImpression_Create"] | undefined,
	read?: ModelTypes["PostsDocAccessFields_latestEventImpression_Read"] | undefined,
	update?: ModelTypes["PostsDocAccessFields_latestEventImpression_Update"] | undefined,
	delete?: ModelTypes["PostsDocAccessFields_latestEventImpression_Delete"] | undefined
};
	["PostsDocAccessFields_latestEventImpression_Create"]: {
		permission: boolean
};
	["PostsDocAccessFields_latestEventImpression_Read"]: {
		permission: boolean
};
	["PostsDocAccessFields_latestEventImpression_Update"]: {
		permission: boolean
};
	["PostsDocAccessFields_latestEventImpression_Delete"]: {
		permission: boolean
};
	["PostsDocAccessFields_internalClick"]: {
		create?: ModelTypes["PostsDocAccessFields_internalClick_Create"] | undefined,
	read?: ModelTypes["PostsDocAccessFields_internalClick_Read"] | undefined,
	update?: ModelTypes["PostsDocAccessFields_internalClick_Update"] | undefined,
	delete?: ModelTypes["PostsDocAccessFields_internalClick_Delete"] | undefined
};
	["PostsDocAccessFields_internalClick_Create"]: {
		permission: boolean
};
	["PostsDocAccessFields_internalClick_Read"]: {
		permission: boolean
};
	["PostsDocAccessFields_internalClick_Update"]: {
		permission: boolean
};
	["PostsDocAccessFields_internalClick_Delete"]: {
		permission: boolean
};
	["PostsDocAccessFields_totalImpression"]: {
		create?: ModelTypes["PostsDocAccessFields_totalImpression_Create"] | undefined,
	read?: ModelTypes["PostsDocAccessFields_totalImpression_Read"] | undefined,
	update?: ModelTypes["PostsDocAccessFields_totalImpression_Update"] | undefined,
	delete?: ModelTypes["PostsDocAccessFields_totalImpression_Delete"] | undefined
};
	["PostsDocAccessFields_totalImpression_Create"]: {
		permission: boolean
};
	["PostsDocAccessFields_totalImpression_Read"]: {
		permission: boolean
};
	["PostsDocAccessFields_totalImpression_Update"]: {
		permission: boolean
};
	["PostsDocAccessFields_totalImpression_Delete"]: {
		permission: boolean
};
	["PostsDocAccessFields_externalClick"]: {
		create?: ModelTypes["PostsDocAccessFields_externalClick_Create"] | undefined,
	read?: ModelTypes["PostsDocAccessFields_externalClick_Read"] | undefined,
	update?: ModelTypes["PostsDocAccessFields_externalClick_Update"] | undefined,
	delete?: ModelTypes["PostsDocAccessFields_externalClick_Delete"] | undefined
};
	["PostsDocAccessFields_externalClick_Create"]: {
		permission: boolean
};
	["PostsDocAccessFields_externalClick_Read"]: {
		permission: boolean
};
	["PostsDocAccessFields_externalClick_Update"]: {
		permission: boolean
};
	["PostsDocAccessFields_externalClick_Delete"]: {
		permission: boolean
};
	["PostsDocAccessFields_outboundClick"]: {
		create?: ModelTypes["PostsDocAccessFields_outboundClick_Create"] | undefined,
	read?: ModelTypes["PostsDocAccessFields_outboundClick_Read"] | undefined,
	update?: ModelTypes["PostsDocAccessFields_outboundClick_Update"] | undefined,
	delete?: ModelTypes["PostsDocAccessFields_outboundClick_Delete"] | undefined
};
	["PostsDocAccessFields_outboundClick_Create"]: {
		permission: boolean
};
	["PostsDocAccessFields_outboundClick_Read"]: {
		permission: boolean
};
	["PostsDocAccessFields_outboundClick_Update"]: {
		permission: boolean
};
	["PostsDocAccessFields_outboundClick_Delete"]: {
		permission: boolean
};
	["PostsDocAccessFields_legacyGallery"]: {
		create?: ModelTypes["PostsDocAccessFields_legacyGallery_Create"] | undefined,
	read?: ModelTypes["PostsDocAccessFields_legacyGallery_Read"] | undefined,
	update?: ModelTypes["PostsDocAccessFields_legacyGallery_Update"] | undefined,
	delete?: ModelTypes["PostsDocAccessFields_legacyGallery_Delete"] | undefined
};
	["PostsDocAccessFields_legacyGallery_Create"]: {
		permission: boolean
};
	["PostsDocAccessFields_legacyGallery_Read"]: {
		permission: boolean
};
	["PostsDocAccessFields_legacyGallery_Update"]: {
		permission: boolean
};
	["PostsDocAccessFields_legacyGallery_Delete"]: {
		permission: boolean
};
	["PostsDocAccessFields_updatedAt"]: {
		create?: ModelTypes["PostsDocAccessFields_updatedAt_Create"] | undefined,
	read?: ModelTypes["PostsDocAccessFields_updatedAt_Read"] | undefined,
	update?: ModelTypes["PostsDocAccessFields_updatedAt_Update"] | undefined,
	delete?: ModelTypes["PostsDocAccessFields_updatedAt_Delete"] | undefined
};
	["PostsDocAccessFields_updatedAt_Create"]: {
		permission: boolean
};
	["PostsDocAccessFields_updatedAt_Read"]: {
		permission: boolean
};
	["PostsDocAccessFields_updatedAt_Update"]: {
		permission: boolean
};
	["PostsDocAccessFields_updatedAt_Delete"]: {
		permission: boolean
};
	["PostsDocAccessFields_createdAt"]: {
		create?: ModelTypes["PostsDocAccessFields_createdAt_Create"] | undefined,
	read?: ModelTypes["PostsDocAccessFields_createdAt_Read"] | undefined,
	update?: ModelTypes["PostsDocAccessFields_createdAt_Update"] | undefined,
	delete?: ModelTypes["PostsDocAccessFields_createdAt_Delete"] | undefined
};
	["PostsDocAccessFields_createdAt_Create"]: {
		permission: boolean
};
	["PostsDocAccessFields_createdAt_Read"]: {
		permission: boolean
};
	["PostsDocAccessFields_createdAt_Update"]: {
		permission: boolean
};
	["PostsDocAccessFields_createdAt_Delete"]: {
		permission: boolean
};
	["PostsCreateDocAccess"]: {
		permission: boolean,
	where?: ModelTypes["JSONObject"] | undefined
};
	["PostsReadDocAccess"]: {
		permission: boolean,
	where?: ModelTypes["JSONObject"] | undefined
};
	["PostsUpdateDocAccess"]: {
		permission: boolean,
	where?: ModelTypes["JSONObject"] | undefined
};
	["PostsDeleteDocAccess"]: {
		permission: boolean,
	where?: ModelTypes["JSONObject"] | undefined
};
	["Ticketings"]: {
		docs?: Array<ModelTypes["Ticketing"] | undefined> | undefined,
	hasNextPage?: boolean | undefined,
	hasPrevPage?: boolean | undefined,
	limit?: number | undefined,
	nextPage?: number | undefined,
	offset?: number | undefined,
	page?: number | undefined,
	pagingCounter?: number | undefined,
	prevPage?: number | undefined,
	totalDocs?: number | undefined,
	totalPages?: number | undefined
};
	["Ticketing_where"]: {
	status?: ModelTypes["Ticketing_status_operator"] | undefined,
	creator?: ModelTypes["Ticketing_creator_operator"] | undefined,
	productType?: ModelTypes["Ticketing_productType_operator"] | undefined,
	productName?: ModelTypes["Ticketing_productName_operator"] | undefined,
	referenceId?: ModelTypes["Ticketing_referenceId_operator"] | undefined,
	linkedEvent?: ModelTypes["Ticketing_linkedEvent_operator"] | undefined,
	CheckInLevel?: ModelTypes["Ticketing_CheckInLevel_operator"] | undefined,
	displayPrice?: ModelTypes["Ticketing_displayPrice_operator"] | undefined,
	tnc?: ModelTypes["Ticketing_tnc_operator"] | undefined,
	cutoffHours?: ModelTypes["Ticketing_cutoffHours_operator"] | undefined,
	sessions__variantId?: ModelTypes["Ticketing_sessions__variantId_operator"] | undefined,
	sessions__productId?: ModelTypes["Ticketing_sessions__productId_operator"] | undefined,
	sessions__startDate?: ModelTypes["Ticketing_sessions__startDate_operator"] | undefined,
	sessions__endDate?: ModelTypes["Ticketing_sessions__endDate_operator"] | undefined,
	sessions__inventories__name?: ModelTypes["Ticketing_sessions__inventories__name_operator"] | undefined,
	sessions__inventories__quantity?: ModelTypes["Ticketing_sessions__inventories__quantity_operator"] | undefined,
	sessions__inventories__unitPrice?: ModelTypes["Ticketing_sessions__inventories__unitPrice_operator"] | undefined,
	sessions__inventories__handlingFee?: ModelTypes["Ticketing_sessions__inventories__handlingFee_operator"] | undefined,
	sessions__inventories__totalCost?: ModelTypes["Ticketing_sessions__inventories__totalCost_operator"] | undefined,
	sessions__inventories__minPurchase?: ModelTypes["Ticketing_sessions__inventories__minPurchase_operator"] | undefined,
	sessions__inventories__maxPurchase?: ModelTypes["Ticketing_sessions__inventories__maxPurchase_operator"] | undefined,
	sessions__inventories__stocks__stockId?: ModelTypes["Ticketing_sessions__inventories__stocks__stockId_operator"] | undefined,
	sessions__inventories__stocks__remaining?: ModelTypes["Ticketing_sessions__inventories__stocks__remaining_operator"] | undefined,
	sessions__inventories__stocks__sold?: ModelTypes["Ticketing_sessions__inventories__stocks__sold_operator"] | undefined,
	sessions__inventories__stocks__onHold?: ModelTypes["Ticketing_sessions__inventories__stocks__onHold_operator"] | undefined,
	sessions__inventories__stocks__checkedIn?: ModelTypes["Ticketing_sessions__inventories__stocks__checkedIn_operator"] | undefined,
	sessions__inventories__remarks?: ModelTypes["Ticketing_sessions__inventories__remarks_operator"] | undefined,
	sessions__id?: ModelTypes["Ticketing_sessions__id_operator"] | undefined,
	updatedAt?: ModelTypes["Ticketing_updatedAt_operator"] | undefined,
	createdAt?: ModelTypes["Ticketing_createdAt_operator"] | undefined,
	id?: ModelTypes["Ticketing_id_operator"] | undefined,
	AND?: Array<ModelTypes["Ticketing_where_and"] | undefined> | undefined,
	OR?: Array<ModelTypes["Ticketing_where_or"] | undefined> | undefined
};
	["Ticketing_status_operator"]: {
	equals?: ModelTypes["Ticketing_status_Input"] | undefined,
	not_equals?: ModelTypes["Ticketing_status_Input"] | undefined,
	in?: Array<ModelTypes["Ticketing_status_Input"] | undefined> | undefined,
	not_in?: Array<ModelTypes["Ticketing_status_Input"] | undefined> | undefined,
	all?: Array<ModelTypes["Ticketing_status_Input"] | undefined> | undefined,
	exists?: boolean | undefined
};
	["Ticketing_status_Input"]:Ticketing_status_Input;
	["Ticketing_creator_operator"]: {
	equals?: ModelTypes["JSON"] | undefined,
	not_equals?: ModelTypes["JSON"] | undefined,
	in?: Array<ModelTypes["JSON"] | undefined> | undefined,
	not_in?: Array<ModelTypes["JSON"] | undefined> | undefined,
	all?: Array<ModelTypes["JSON"] | undefined> | undefined,
	exists?: boolean | undefined
};
	["Ticketing_productType_operator"]: {
	equals?: ModelTypes["Ticketing_productType_Input"] | undefined,
	not_equals?: ModelTypes["Ticketing_productType_Input"] | undefined,
	like?: ModelTypes["Ticketing_productType_Input"] | undefined,
	contains?: ModelTypes["Ticketing_productType_Input"] | undefined
};
	["Ticketing_productType_Input"]:Ticketing_productType_Input;
	["Ticketing_productName_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined
};
	["Ticketing_referenceId_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Ticketing_linkedEvent_operator"]: {
	equals?: ModelTypes["JSON"] | undefined,
	not_equals?: ModelTypes["JSON"] | undefined,
	in?: Array<ModelTypes["JSON"] | undefined> | undefined,
	not_in?: Array<ModelTypes["JSON"] | undefined> | undefined,
	all?: Array<ModelTypes["JSON"] | undefined> | undefined,
	exists?: boolean | undefined
};
	["Ticketing_CheckInLevel_operator"]: {
	equals?: ModelTypes["Ticketing_CheckInLevel_Input"] | undefined,
	not_equals?: ModelTypes["Ticketing_CheckInLevel_Input"] | undefined,
	in?: Array<ModelTypes["Ticketing_CheckInLevel_Input"] | undefined> | undefined,
	not_in?: Array<ModelTypes["Ticketing_CheckInLevel_Input"] | undefined> | undefined,
	all?: Array<ModelTypes["Ticketing_CheckInLevel_Input"] | undefined> | undefined
};
	["Ticketing_CheckInLevel_Input"]:Ticketing_CheckInLevel_Input;
	["Ticketing_displayPrice_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined
};
	["Ticketing_tnc_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined
};
	["Ticketing_cutoffHours_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined
};
	["Ticketing_sessions__variantId_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Ticketing_sessions__productId_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Ticketing_sessions__startDate_operator"]: {
	equals?: ModelTypes["DateTime"] | undefined,
	not_equals?: ModelTypes["DateTime"] | undefined,
	greater_than_equal?: ModelTypes["DateTime"] | undefined,
	greater_than?: ModelTypes["DateTime"] | undefined,
	less_than_equal?: ModelTypes["DateTime"] | undefined,
	less_than?: ModelTypes["DateTime"] | undefined,
	like?: ModelTypes["DateTime"] | undefined
};
	["Ticketing_sessions__endDate_operator"]: {
	equals?: ModelTypes["DateTime"] | undefined,
	not_equals?: ModelTypes["DateTime"] | undefined,
	greater_than_equal?: ModelTypes["DateTime"] | undefined,
	greater_than?: ModelTypes["DateTime"] | undefined,
	less_than_equal?: ModelTypes["DateTime"] | undefined,
	less_than?: ModelTypes["DateTime"] | undefined,
	like?: ModelTypes["DateTime"] | undefined
};
	["Ticketing_sessions__inventories__name_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined
};
	["Ticketing_sessions__inventories__quantity_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined
};
	["Ticketing_sessions__inventories__unitPrice_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined
};
	["Ticketing_sessions__inventories__handlingFee_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined
};
	["Ticketing_sessions__inventories__totalCost_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Ticketing_sessions__inventories__minPurchase_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined
};
	["Ticketing_sessions__inventories__maxPurchase_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined
};
	["Ticketing_sessions__inventories__stocks__stockId_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Ticketing_sessions__inventories__stocks__remaining_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Ticketing_sessions__inventories__stocks__sold_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Ticketing_sessions__inventories__stocks__onHold_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Ticketing_sessions__inventories__stocks__checkedIn_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Ticketing_sessions__inventories__remarks_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	exists?: boolean | undefined
};
	["Ticketing_sessions__id_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Ticketing_updatedAt_operator"]: {
	equals?: ModelTypes["DateTime"] | undefined,
	not_equals?: ModelTypes["DateTime"] | undefined,
	greater_than_equal?: ModelTypes["DateTime"] | undefined,
	greater_than?: ModelTypes["DateTime"] | undefined,
	less_than_equal?: ModelTypes["DateTime"] | undefined,
	less_than?: ModelTypes["DateTime"] | undefined,
	like?: ModelTypes["DateTime"] | undefined,
	exists?: boolean | undefined
};
	["Ticketing_createdAt_operator"]: {
	equals?: ModelTypes["DateTime"] | undefined,
	not_equals?: ModelTypes["DateTime"] | undefined,
	greater_than_equal?: ModelTypes["DateTime"] | undefined,
	greater_than?: ModelTypes["DateTime"] | undefined,
	less_than_equal?: ModelTypes["DateTime"] | undefined,
	less_than?: ModelTypes["DateTime"] | undefined,
	like?: ModelTypes["DateTime"] | undefined,
	exists?: boolean | undefined
};
	["Ticketing_id_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Ticketing_where_and"]: {
	status?: ModelTypes["Ticketing_status_operator"] | undefined,
	creator?: ModelTypes["Ticketing_creator_operator"] | undefined,
	productType?: ModelTypes["Ticketing_productType_operator"] | undefined,
	productName?: ModelTypes["Ticketing_productName_operator"] | undefined,
	referenceId?: ModelTypes["Ticketing_referenceId_operator"] | undefined,
	linkedEvent?: ModelTypes["Ticketing_linkedEvent_operator"] | undefined,
	CheckInLevel?: ModelTypes["Ticketing_CheckInLevel_operator"] | undefined,
	displayPrice?: ModelTypes["Ticketing_displayPrice_operator"] | undefined,
	tnc?: ModelTypes["Ticketing_tnc_operator"] | undefined,
	cutoffHours?: ModelTypes["Ticketing_cutoffHours_operator"] | undefined,
	sessions__variantId?: ModelTypes["Ticketing_sessions__variantId_operator"] | undefined,
	sessions__productId?: ModelTypes["Ticketing_sessions__productId_operator"] | undefined,
	sessions__startDate?: ModelTypes["Ticketing_sessions__startDate_operator"] | undefined,
	sessions__endDate?: ModelTypes["Ticketing_sessions__endDate_operator"] | undefined,
	sessions__inventories__name?: ModelTypes["Ticketing_sessions__inventories__name_operator"] | undefined,
	sessions__inventories__quantity?: ModelTypes["Ticketing_sessions__inventories__quantity_operator"] | undefined,
	sessions__inventories__unitPrice?: ModelTypes["Ticketing_sessions__inventories__unitPrice_operator"] | undefined,
	sessions__inventories__handlingFee?: ModelTypes["Ticketing_sessions__inventories__handlingFee_operator"] | undefined,
	sessions__inventories__totalCost?: ModelTypes["Ticketing_sessions__inventories__totalCost_operator"] | undefined,
	sessions__inventories__minPurchase?: ModelTypes["Ticketing_sessions__inventories__minPurchase_operator"] | undefined,
	sessions__inventories__maxPurchase?: ModelTypes["Ticketing_sessions__inventories__maxPurchase_operator"] | undefined,
	sessions__inventories__stocks__stockId?: ModelTypes["Ticketing_sessions__inventories__stocks__stockId_operator"] | undefined,
	sessions__inventories__stocks__remaining?: ModelTypes["Ticketing_sessions__inventories__stocks__remaining_operator"] | undefined,
	sessions__inventories__stocks__sold?: ModelTypes["Ticketing_sessions__inventories__stocks__sold_operator"] | undefined,
	sessions__inventories__stocks__onHold?: ModelTypes["Ticketing_sessions__inventories__stocks__onHold_operator"] | undefined,
	sessions__inventories__stocks__checkedIn?: ModelTypes["Ticketing_sessions__inventories__stocks__checkedIn_operator"] | undefined,
	sessions__inventories__remarks?: ModelTypes["Ticketing_sessions__inventories__remarks_operator"] | undefined,
	sessions__id?: ModelTypes["Ticketing_sessions__id_operator"] | undefined,
	updatedAt?: ModelTypes["Ticketing_updatedAt_operator"] | undefined,
	createdAt?: ModelTypes["Ticketing_createdAt_operator"] | undefined,
	id?: ModelTypes["Ticketing_id_operator"] | undefined,
	AND?: Array<ModelTypes["Ticketing_where_and"] | undefined> | undefined,
	OR?: Array<ModelTypes["Ticketing_where_or"] | undefined> | undefined
};
	["Ticketing_where_or"]: {
	status?: ModelTypes["Ticketing_status_operator"] | undefined,
	creator?: ModelTypes["Ticketing_creator_operator"] | undefined,
	productType?: ModelTypes["Ticketing_productType_operator"] | undefined,
	productName?: ModelTypes["Ticketing_productName_operator"] | undefined,
	referenceId?: ModelTypes["Ticketing_referenceId_operator"] | undefined,
	linkedEvent?: ModelTypes["Ticketing_linkedEvent_operator"] | undefined,
	CheckInLevel?: ModelTypes["Ticketing_CheckInLevel_operator"] | undefined,
	displayPrice?: ModelTypes["Ticketing_displayPrice_operator"] | undefined,
	tnc?: ModelTypes["Ticketing_tnc_operator"] | undefined,
	cutoffHours?: ModelTypes["Ticketing_cutoffHours_operator"] | undefined,
	sessions__variantId?: ModelTypes["Ticketing_sessions__variantId_operator"] | undefined,
	sessions__productId?: ModelTypes["Ticketing_sessions__productId_operator"] | undefined,
	sessions__startDate?: ModelTypes["Ticketing_sessions__startDate_operator"] | undefined,
	sessions__endDate?: ModelTypes["Ticketing_sessions__endDate_operator"] | undefined,
	sessions__inventories__name?: ModelTypes["Ticketing_sessions__inventories__name_operator"] | undefined,
	sessions__inventories__quantity?: ModelTypes["Ticketing_sessions__inventories__quantity_operator"] | undefined,
	sessions__inventories__unitPrice?: ModelTypes["Ticketing_sessions__inventories__unitPrice_operator"] | undefined,
	sessions__inventories__handlingFee?: ModelTypes["Ticketing_sessions__inventories__handlingFee_operator"] | undefined,
	sessions__inventories__totalCost?: ModelTypes["Ticketing_sessions__inventories__totalCost_operator"] | undefined,
	sessions__inventories__minPurchase?: ModelTypes["Ticketing_sessions__inventories__minPurchase_operator"] | undefined,
	sessions__inventories__maxPurchase?: ModelTypes["Ticketing_sessions__inventories__maxPurchase_operator"] | undefined,
	sessions__inventories__stocks__stockId?: ModelTypes["Ticketing_sessions__inventories__stocks__stockId_operator"] | undefined,
	sessions__inventories__stocks__remaining?: ModelTypes["Ticketing_sessions__inventories__stocks__remaining_operator"] | undefined,
	sessions__inventories__stocks__sold?: ModelTypes["Ticketing_sessions__inventories__stocks__sold_operator"] | undefined,
	sessions__inventories__stocks__onHold?: ModelTypes["Ticketing_sessions__inventories__stocks__onHold_operator"] | undefined,
	sessions__inventories__stocks__checkedIn?: ModelTypes["Ticketing_sessions__inventories__stocks__checkedIn_operator"] | undefined,
	sessions__inventories__remarks?: ModelTypes["Ticketing_sessions__inventories__remarks_operator"] | undefined,
	sessions__id?: ModelTypes["Ticketing_sessions__id_operator"] | undefined,
	updatedAt?: ModelTypes["Ticketing_updatedAt_operator"] | undefined,
	createdAt?: ModelTypes["Ticketing_createdAt_operator"] | undefined,
	id?: ModelTypes["Ticketing_id_operator"] | undefined,
	AND?: Array<ModelTypes["Ticketing_where_and"] | undefined> | undefined,
	OR?: Array<ModelTypes["Ticketing_where_or"] | undefined> | undefined
};
	["countTicketings"]: {
		totalDocs?: number | undefined
};
	["ticketingDocAccess"]: {
		fields?: ModelTypes["TicketingDocAccessFields"] | undefined,
	create?: ModelTypes["TicketingCreateDocAccess"] | undefined,
	read?: ModelTypes["TicketingReadDocAccess"] | undefined,
	update?: ModelTypes["TicketingUpdateDocAccess"] | undefined,
	delete?: ModelTypes["TicketingDeleteDocAccess"] | undefined
};
	["TicketingDocAccessFields"]: {
		status?: ModelTypes["TicketingDocAccessFields_status"] | undefined,
	creator?: ModelTypes["TicketingDocAccessFields_creator"] | undefined,
	productType?: ModelTypes["TicketingDocAccessFields_productType"] | undefined,
	productName?: ModelTypes["TicketingDocAccessFields_productName"] | undefined,
	referenceId?: ModelTypes["TicketingDocAccessFields_referenceId"] | undefined,
	linkedEvent?: ModelTypes["TicketingDocAccessFields_linkedEvent"] | undefined,
	CheckInLevel?: ModelTypes["TicketingDocAccessFields_CheckInLevel"] | undefined,
	displayPrice?: ModelTypes["TicketingDocAccessFields_displayPrice"] | undefined,
	tnc?: ModelTypes["TicketingDocAccessFields_tnc"] | undefined,
	cutoffHours?: ModelTypes["TicketingDocAccessFields_cutoffHours"] | undefined,
	sessions?: ModelTypes["TicketingDocAccessFields_sessions"] | undefined,
	updatedAt?: ModelTypes["TicketingDocAccessFields_updatedAt"] | undefined,
	createdAt?: ModelTypes["TicketingDocAccessFields_createdAt"] | undefined
};
	["TicketingDocAccessFields_status"]: {
		create?: ModelTypes["TicketingDocAccessFields_status_Create"] | undefined,
	read?: ModelTypes["TicketingDocAccessFields_status_Read"] | undefined,
	update?: ModelTypes["TicketingDocAccessFields_status_Update"] | undefined,
	delete?: ModelTypes["TicketingDocAccessFields_status_Delete"] | undefined
};
	["TicketingDocAccessFields_status_Create"]: {
		permission: boolean
};
	["TicketingDocAccessFields_status_Read"]: {
		permission: boolean
};
	["TicketingDocAccessFields_status_Update"]: {
		permission: boolean
};
	["TicketingDocAccessFields_status_Delete"]: {
		permission: boolean
};
	["TicketingDocAccessFields_creator"]: {
		create?: ModelTypes["TicketingDocAccessFields_creator_Create"] | undefined,
	read?: ModelTypes["TicketingDocAccessFields_creator_Read"] | undefined,
	update?: ModelTypes["TicketingDocAccessFields_creator_Update"] | undefined,
	delete?: ModelTypes["TicketingDocAccessFields_creator_Delete"] | undefined
};
	["TicketingDocAccessFields_creator_Create"]: {
		permission: boolean
};
	["TicketingDocAccessFields_creator_Read"]: {
		permission: boolean
};
	["TicketingDocAccessFields_creator_Update"]: {
		permission: boolean
};
	["TicketingDocAccessFields_creator_Delete"]: {
		permission: boolean
};
	["TicketingDocAccessFields_productType"]: {
		create?: ModelTypes["TicketingDocAccessFields_productType_Create"] | undefined,
	read?: ModelTypes["TicketingDocAccessFields_productType_Read"] | undefined,
	update?: ModelTypes["TicketingDocAccessFields_productType_Update"] | undefined,
	delete?: ModelTypes["TicketingDocAccessFields_productType_Delete"] | undefined
};
	["TicketingDocAccessFields_productType_Create"]: {
		permission: boolean
};
	["TicketingDocAccessFields_productType_Read"]: {
		permission: boolean
};
	["TicketingDocAccessFields_productType_Update"]: {
		permission: boolean
};
	["TicketingDocAccessFields_productType_Delete"]: {
		permission: boolean
};
	["TicketingDocAccessFields_productName"]: {
		create?: ModelTypes["TicketingDocAccessFields_productName_Create"] | undefined,
	read?: ModelTypes["TicketingDocAccessFields_productName_Read"] | undefined,
	update?: ModelTypes["TicketingDocAccessFields_productName_Update"] | undefined,
	delete?: ModelTypes["TicketingDocAccessFields_productName_Delete"] | undefined
};
	["TicketingDocAccessFields_productName_Create"]: {
		permission: boolean
};
	["TicketingDocAccessFields_productName_Read"]: {
		permission: boolean
};
	["TicketingDocAccessFields_productName_Update"]: {
		permission: boolean
};
	["TicketingDocAccessFields_productName_Delete"]: {
		permission: boolean
};
	["TicketingDocAccessFields_referenceId"]: {
		create?: ModelTypes["TicketingDocAccessFields_referenceId_Create"] | undefined,
	read?: ModelTypes["TicketingDocAccessFields_referenceId_Read"] | undefined,
	update?: ModelTypes["TicketingDocAccessFields_referenceId_Update"] | undefined,
	delete?: ModelTypes["TicketingDocAccessFields_referenceId_Delete"] | undefined
};
	["TicketingDocAccessFields_referenceId_Create"]: {
		permission: boolean
};
	["TicketingDocAccessFields_referenceId_Read"]: {
		permission: boolean
};
	["TicketingDocAccessFields_referenceId_Update"]: {
		permission: boolean
};
	["TicketingDocAccessFields_referenceId_Delete"]: {
		permission: boolean
};
	["TicketingDocAccessFields_linkedEvent"]: {
		create?: ModelTypes["TicketingDocAccessFields_linkedEvent_Create"] | undefined,
	read?: ModelTypes["TicketingDocAccessFields_linkedEvent_Read"] | undefined,
	update?: ModelTypes["TicketingDocAccessFields_linkedEvent_Update"] | undefined,
	delete?: ModelTypes["TicketingDocAccessFields_linkedEvent_Delete"] | undefined
};
	["TicketingDocAccessFields_linkedEvent_Create"]: {
		permission: boolean
};
	["TicketingDocAccessFields_linkedEvent_Read"]: {
		permission: boolean
};
	["TicketingDocAccessFields_linkedEvent_Update"]: {
		permission: boolean
};
	["TicketingDocAccessFields_linkedEvent_Delete"]: {
		permission: boolean
};
	["TicketingDocAccessFields_CheckInLevel"]: {
		create?: ModelTypes["TicketingDocAccessFields_CheckInLevel_Create"] | undefined,
	read?: ModelTypes["TicketingDocAccessFields_CheckInLevel_Read"] | undefined,
	update?: ModelTypes["TicketingDocAccessFields_CheckInLevel_Update"] | undefined,
	delete?: ModelTypes["TicketingDocAccessFields_CheckInLevel_Delete"] | undefined
};
	["TicketingDocAccessFields_CheckInLevel_Create"]: {
		permission: boolean
};
	["TicketingDocAccessFields_CheckInLevel_Read"]: {
		permission: boolean
};
	["TicketingDocAccessFields_CheckInLevel_Update"]: {
		permission: boolean
};
	["TicketingDocAccessFields_CheckInLevel_Delete"]: {
		permission: boolean
};
	["TicketingDocAccessFields_displayPrice"]: {
		create?: ModelTypes["TicketingDocAccessFields_displayPrice_Create"] | undefined,
	read?: ModelTypes["TicketingDocAccessFields_displayPrice_Read"] | undefined,
	update?: ModelTypes["TicketingDocAccessFields_displayPrice_Update"] | undefined,
	delete?: ModelTypes["TicketingDocAccessFields_displayPrice_Delete"] | undefined
};
	["TicketingDocAccessFields_displayPrice_Create"]: {
		permission: boolean
};
	["TicketingDocAccessFields_displayPrice_Read"]: {
		permission: boolean
};
	["TicketingDocAccessFields_displayPrice_Update"]: {
		permission: boolean
};
	["TicketingDocAccessFields_displayPrice_Delete"]: {
		permission: boolean
};
	["TicketingDocAccessFields_tnc"]: {
		create?: ModelTypes["TicketingDocAccessFields_tnc_Create"] | undefined,
	read?: ModelTypes["TicketingDocAccessFields_tnc_Read"] | undefined,
	update?: ModelTypes["TicketingDocAccessFields_tnc_Update"] | undefined,
	delete?: ModelTypes["TicketingDocAccessFields_tnc_Delete"] | undefined
};
	["TicketingDocAccessFields_tnc_Create"]: {
		permission: boolean
};
	["TicketingDocAccessFields_tnc_Read"]: {
		permission: boolean
};
	["TicketingDocAccessFields_tnc_Update"]: {
		permission: boolean
};
	["TicketingDocAccessFields_tnc_Delete"]: {
		permission: boolean
};
	["TicketingDocAccessFields_cutoffHours"]: {
		create?: ModelTypes["TicketingDocAccessFields_cutoffHours_Create"] | undefined,
	read?: ModelTypes["TicketingDocAccessFields_cutoffHours_Read"] | undefined,
	update?: ModelTypes["TicketingDocAccessFields_cutoffHours_Update"] | undefined,
	delete?: ModelTypes["TicketingDocAccessFields_cutoffHours_Delete"] | undefined
};
	["TicketingDocAccessFields_cutoffHours_Create"]: {
		permission: boolean
};
	["TicketingDocAccessFields_cutoffHours_Read"]: {
		permission: boolean
};
	["TicketingDocAccessFields_cutoffHours_Update"]: {
		permission: boolean
};
	["TicketingDocAccessFields_cutoffHours_Delete"]: {
		permission: boolean
};
	["TicketingDocAccessFields_sessions"]: {
		create?: ModelTypes["TicketingDocAccessFields_sessions_Create"] | undefined,
	read?: ModelTypes["TicketingDocAccessFields_sessions_Read"] | undefined,
	update?: ModelTypes["TicketingDocAccessFields_sessions_Update"] | undefined,
	delete?: ModelTypes["TicketingDocAccessFields_sessions_Delete"] | undefined,
	fields?: ModelTypes["TicketingDocAccessFields_sessions_Fields"] | undefined
};
	["TicketingDocAccessFields_sessions_Create"]: {
		permission: boolean
};
	["TicketingDocAccessFields_sessions_Read"]: {
		permission: boolean
};
	["TicketingDocAccessFields_sessions_Update"]: {
		permission: boolean
};
	["TicketingDocAccessFields_sessions_Delete"]: {
		permission: boolean
};
	["TicketingDocAccessFields_sessions_Fields"]: {
		productId?: ModelTypes["TicketingDocAccessFields_sessions_productId"] | undefined,
	startDate?: ModelTypes["TicketingDocAccessFields_sessions_startDate"] | undefined,
	endDate?: ModelTypes["TicketingDocAccessFields_sessions_endDate"] | undefined,
	inventories?: ModelTypes["TicketingDocAccessFields_sessions_inventories"] | undefined,
	id?: ModelTypes["TicketingDocAccessFields_sessions_id"] | undefined
};
	["TicketingDocAccessFields_sessions_productId"]: {
		create?: ModelTypes["TicketingDocAccessFields_sessions_productId_Create"] | undefined,
	read?: ModelTypes["TicketingDocAccessFields_sessions_productId_Read"] | undefined,
	update?: ModelTypes["TicketingDocAccessFields_sessions_productId_Update"] | undefined,
	delete?: ModelTypes["TicketingDocAccessFields_sessions_productId_Delete"] | undefined
};
	["TicketingDocAccessFields_sessions_productId_Create"]: {
		permission: boolean
};
	["TicketingDocAccessFields_sessions_productId_Read"]: {
		permission: boolean
};
	["TicketingDocAccessFields_sessions_productId_Update"]: {
		permission: boolean
};
	["TicketingDocAccessFields_sessions_productId_Delete"]: {
		permission: boolean
};
	["TicketingDocAccessFields_sessions_startDate"]: {
		create?: ModelTypes["TicketingDocAccessFields_sessions_startDate_Create"] | undefined,
	read?: ModelTypes["TicketingDocAccessFields_sessions_startDate_Read"] | undefined,
	update?: ModelTypes["TicketingDocAccessFields_sessions_startDate_Update"] | undefined,
	delete?: ModelTypes["TicketingDocAccessFields_sessions_startDate_Delete"] | undefined
};
	["TicketingDocAccessFields_sessions_startDate_Create"]: {
		permission: boolean
};
	["TicketingDocAccessFields_sessions_startDate_Read"]: {
		permission: boolean
};
	["TicketingDocAccessFields_sessions_startDate_Update"]: {
		permission: boolean
};
	["TicketingDocAccessFields_sessions_startDate_Delete"]: {
		permission: boolean
};
	["TicketingDocAccessFields_sessions_endDate"]: {
		create?: ModelTypes["TicketingDocAccessFields_sessions_endDate_Create"] | undefined,
	read?: ModelTypes["TicketingDocAccessFields_sessions_endDate_Read"] | undefined,
	update?: ModelTypes["TicketingDocAccessFields_sessions_endDate_Update"] | undefined,
	delete?: ModelTypes["TicketingDocAccessFields_sessions_endDate_Delete"] | undefined
};
	["TicketingDocAccessFields_sessions_endDate_Create"]: {
		permission: boolean
};
	["TicketingDocAccessFields_sessions_endDate_Read"]: {
		permission: boolean
};
	["TicketingDocAccessFields_sessions_endDate_Update"]: {
		permission: boolean
};
	["TicketingDocAccessFields_sessions_endDate_Delete"]: {
		permission: boolean
};
	["TicketingDocAccessFields_sessions_inventories"]: {
		create?: ModelTypes["TicketingDocAccessFields_sessions_inventories_Create"] | undefined,
	read?: ModelTypes["TicketingDocAccessFields_sessions_inventories_Read"] | undefined,
	update?: ModelTypes["TicketingDocAccessFields_sessions_inventories_Update"] | undefined,
	delete?: ModelTypes["TicketingDocAccessFields_sessions_inventories_Delete"] | undefined,
	fields?: ModelTypes["TicketingDocAccessFields_sessions_inventories_Fields"] | undefined
};
	["TicketingDocAccessFields_sessions_inventories_Create"]: {
		permission: boolean
};
	["TicketingDocAccessFields_sessions_inventories_Read"]: {
		permission: boolean
};
	["TicketingDocAccessFields_sessions_inventories_Update"]: {
		permission: boolean
};
	["TicketingDocAccessFields_sessions_inventories_Delete"]: {
		permission: boolean
};
	["TicketingDocAccessFields_sessions_inventories_Fields"]: {
		name?: ModelTypes["TicketingDocAccessFields_sessions_inventories_name"] | undefined,
	quantity?: ModelTypes["TicketingDocAccessFields_sessions_inventories_quantity"] | undefined,
	unitPrice?: ModelTypes["TicketingDocAccessFields_sessions_inventories_unitPrice"] | undefined,
	handlingFee?: ModelTypes["TicketingDocAccessFields_sessions_inventories_handlingFee"] | undefined,
	totalCost?: ModelTypes["TicketingDocAccessFields_sessions_inventories_totalCost"] | undefined,
	minPurchase?: ModelTypes["TicketingDocAccessFields_sessions_inventories_minPurchase"] | undefined,
	maxPurchase?: ModelTypes["TicketingDocAccessFields_sessions_inventories_maxPurchase"] | undefined,
	stocks?: ModelTypes["TicketingDocAccessFields_sessions_inventories_stocks"] | undefined,
	remarks?: ModelTypes["TicketingDocAccessFields_sessions_inventories_remarks"] | undefined
};
	["TicketingDocAccessFields_sessions_inventories_name"]: {
		create?: ModelTypes["TicketingDocAccessFields_sessions_inventories_name_Create"] | undefined,
	read?: ModelTypes["TicketingDocAccessFields_sessions_inventories_name_Read"] | undefined,
	update?: ModelTypes["TicketingDocAccessFields_sessions_inventories_name_Update"] | undefined,
	delete?: ModelTypes["TicketingDocAccessFields_sessions_inventories_name_Delete"] | undefined
};
	["TicketingDocAccessFields_sessions_inventories_name_Create"]: {
		permission: boolean
};
	["TicketingDocAccessFields_sessions_inventories_name_Read"]: {
		permission: boolean
};
	["TicketingDocAccessFields_sessions_inventories_name_Update"]: {
		permission: boolean
};
	["TicketingDocAccessFields_sessions_inventories_name_Delete"]: {
		permission: boolean
};
	["TicketingDocAccessFields_sessions_inventories_quantity"]: {
		create?: ModelTypes["TicketingDocAccessFields_sessions_inventories_quantity_Create"] | undefined,
	read?: ModelTypes["TicketingDocAccessFields_sessions_inventories_quantity_Read"] | undefined,
	update?: ModelTypes["TicketingDocAccessFields_sessions_inventories_quantity_Update"] | undefined,
	delete?: ModelTypes["TicketingDocAccessFields_sessions_inventories_quantity_Delete"] | undefined
};
	["TicketingDocAccessFields_sessions_inventories_quantity_Create"]: {
		permission: boolean
};
	["TicketingDocAccessFields_sessions_inventories_quantity_Read"]: {
		permission: boolean
};
	["TicketingDocAccessFields_sessions_inventories_quantity_Update"]: {
		permission: boolean
};
	["TicketingDocAccessFields_sessions_inventories_quantity_Delete"]: {
		permission: boolean
};
	["TicketingDocAccessFields_sessions_inventories_unitPrice"]: {
		create?: ModelTypes["TicketingDocAccessFields_sessions_inventories_unitPrice_Create"] | undefined,
	read?: ModelTypes["TicketingDocAccessFields_sessions_inventories_unitPrice_Read"] | undefined,
	update?: ModelTypes["TicketingDocAccessFields_sessions_inventories_unitPrice_Update"] | undefined,
	delete?: ModelTypes["TicketingDocAccessFields_sessions_inventories_unitPrice_Delete"] | undefined
};
	["TicketingDocAccessFields_sessions_inventories_unitPrice_Create"]: {
		permission: boolean
};
	["TicketingDocAccessFields_sessions_inventories_unitPrice_Read"]: {
		permission: boolean
};
	["TicketingDocAccessFields_sessions_inventories_unitPrice_Update"]: {
		permission: boolean
};
	["TicketingDocAccessFields_sessions_inventories_unitPrice_Delete"]: {
		permission: boolean
};
	["TicketingDocAccessFields_sessions_inventories_handlingFee"]: {
		create?: ModelTypes["TicketingDocAccessFields_sessions_inventories_handlingFee_Create"] | undefined,
	read?: ModelTypes["TicketingDocAccessFields_sessions_inventories_handlingFee_Read"] | undefined,
	update?: ModelTypes["TicketingDocAccessFields_sessions_inventories_handlingFee_Update"] | undefined,
	delete?: ModelTypes["TicketingDocAccessFields_sessions_inventories_handlingFee_Delete"] | undefined
};
	["TicketingDocAccessFields_sessions_inventories_handlingFee_Create"]: {
		permission: boolean
};
	["TicketingDocAccessFields_sessions_inventories_handlingFee_Read"]: {
		permission: boolean
};
	["TicketingDocAccessFields_sessions_inventories_handlingFee_Update"]: {
		permission: boolean
};
	["TicketingDocAccessFields_sessions_inventories_handlingFee_Delete"]: {
		permission: boolean
};
	["TicketingDocAccessFields_sessions_inventories_totalCost"]: {
		create?: ModelTypes["TicketingDocAccessFields_sessions_inventories_totalCost_Create"] | undefined,
	read?: ModelTypes["TicketingDocAccessFields_sessions_inventories_totalCost_Read"] | undefined,
	update?: ModelTypes["TicketingDocAccessFields_sessions_inventories_totalCost_Update"] | undefined,
	delete?: ModelTypes["TicketingDocAccessFields_sessions_inventories_totalCost_Delete"] | undefined
};
	["TicketingDocAccessFields_sessions_inventories_totalCost_Create"]: {
		permission: boolean
};
	["TicketingDocAccessFields_sessions_inventories_totalCost_Read"]: {
		permission: boolean
};
	["TicketingDocAccessFields_sessions_inventories_totalCost_Update"]: {
		permission: boolean
};
	["TicketingDocAccessFields_sessions_inventories_totalCost_Delete"]: {
		permission: boolean
};
	["TicketingDocAccessFields_sessions_inventories_minPurchase"]: {
		create?: ModelTypes["TicketingDocAccessFields_sessions_inventories_minPurchase_Create"] | undefined,
	read?: ModelTypes["TicketingDocAccessFields_sessions_inventories_minPurchase_Read"] | undefined,
	update?: ModelTypes["TicketingDocAccessFields_sessions_inventories_minPurchase_Update"] | undefined,
	delete?: ModelTypes["TicketingDocAccessFields_sessions_inventories_minPurchase_Delete"] | undefined
};
	["TicketingDocAccessFields_sessions_inventories_minPurchase_Create"]: {
		permission: boolean
};
	["TicketingDocAccessFields_sessions_inventories_minPurchase_Read"]: {
		permission: boolean
};
	["TicketingDocAccessFields_sessions_inventories_minPurchase_Update"]: {
		permission: boolean
};
	["TicketingDocAccessFields_sessions_inventories_minPurchase_Delete"]: {
		permission: boolean
};
	["TicketingDocAccessFields_sessions_inventories_maxPurchase"]: {
		create?: ModelTypes["TicketingDocAccessFields_sessions_inventories_maxPurchase_Create"] | undefined,
	read?: ModelTypes["TicketingDocAccessFields_sessions_inventories_maxPurchase_Read"] | undefined,
	update?: ModelTypes["TicketingDocAccessFields_sessions_inventories_maxPurchase_Update"] | undefined,
	delete?: ModelTypes["TicketingDocAccessFields_sessions_inventories_maxPurchase_Delete"] | undefined
};
	["TicketingDocAccessFields_sessions_inventories_maxPurchase_Create"]: {
		permission: boolean
};
	["TicketingDocAccessFields_sessions_inventories_maxPurchase_Read"]: {
		permission: boolean
};
	["TicketingDocAccessFields_sessions_inventories_maxPurchase_Update"]: {
		permission: boolean
};
	["TicketingDocAccessFields_sessions_inventories_maxPurchase_Delete"]: {
		permission: boolean
};
	["TicketingDocAccessFields_sessions_inventories_stocks"]: {
		create?: ModelTypes["TicketingDocAccessFields_sessions_inventories_stocks_Create"] | undefined,
	read?: ModelTypes["TicketingDocAccessFields_sessions_inventories_stocks_Read"] | undefined,
	update?: ModelTypes["TicketingDocAccessFields_sessions_inventories_stocks_Update"] | undefined,
	delete?: ModelTypes["TicketingDocAccessFields_sessions_inventories_stocks_Delete"] | undefined,
	fields?: ModelTypes["TicketingDocAccessFields_sessions_inventories_stocks_Fields"] | undefined
};
	["TicketingDocAccessFields_sessions_inventories_stocks_Create"]: {
		permission: boolean
};
	["TicketingDocAccessFields_sessions_inventories_stocks_Read"]: {
		permission: boolean
};
	["TicketingDocAccessFields_sessions_inventories_stocks_Update"]: {
		permission: boolean
};
	["TicketingDocAccessFields_sessions_inventories_stocks_Delete"]: {
		permission: boolean
};
	["TicketingDocAccessFields_sessions_inventories_stocks_Fields"]: {
		stockId?: ModelTypes["TicketingDocAccessFields_sessions_inventories_stocks_stockId"] | undefined,
	remaining?: ModelTypes["TicketingDocAccessFields_sessions_inventories_stocks_remaining"] | undefined,
	sold?: ModelTypes["TicketingDocAccessFields_sessions_inventories_stocks_sold"] | undefined,
	onHold?: ModelTypes["TicketingDocAccessFields_sessions_inventories_stocks_onHold"] | undefined,
	checkedIn?: ModelTypes["TicketingDocAccessFields_sessions_inventories_stocks_checkedIn"] | undefined
};
	["TicketingDocAccessFields_sessions_inventories_stocks_stockId"]: {
		create?: ModelTypes["TicketingDocAccessFields_sessions_inventories_stocks_stockId_Create"] | undefined,
	read?: ModelTypes["TicketingDocAccessFields_sessions_inventories_stocks_stockId_Read"] | undefined,
	update?: ModelTypes["TicketingDocAccessFields_sessions_inventories_stocks_stockId_Update"] | undefined,
	delete?: ModelTypes["TicketingDocAccessFields_sessions_inventories_stocks_stockId_Delete"] | undefined
};
	["TicketingDocAccessFields_sessions_inventories_stocks_stockId_Create"]: {
		permission: boolean
};
	["TicketingDocAccessFields_sessions_inventories_stocks_stockId_Read"]: {
		permission: boolean
};
	["TicketingDocAccessFields_sessions_inventories_stocks_stockId_Update"]: {
		permission: boolean
};
	["TicketingDocAccessFields_sessions_inventories_stocks_stockId_Delete"]: {
		permission: boolean
};
	["TicketingDocAccessFields_sessions_inventories_stocks_remaining"]: {
		create?: ModelTypes["TicketingDocAccessFields_sessions_inventories_stocks_remaining_Create"] | undefined,
	read?: ModelTypes["TicketingDocAccessFields_sessions_inventories_stocks_remaining_Read"] | undefined,
	update?: ModelTypes["TicketingDocAccessFields_sessions_inventories_stocks_remaining_Update"] | undefined,
	delete?: ModelTypes["TicketingDocAccessFields_sessions_inventories_stocks_remaining_Delete"] | undefined
};
	["TicketingDocAccessFields_sessions_inventories_stocks_remaining_Create"]: {
		permission: boolean
};
	["TicketingDocAccessFields_sessions_inventories_stocks_remaining_Read"]: {
		permission: boolean
};
	["TicketingDocAccessFields_sessions_inventories_stocks_remaining_Update"]: {
		permission: boolean
};
	["TicketingDocAccessFields_sessions_inventories_stocks_remaining_Delete"]: {
		permission: boolean
};
	["TicketingDocAccessFields_sessions_inventories_stocks_sold"]: {
		create?: ModelTypes["TicketingDocAccessFields_sessions_inventories_stocks_sold_Create"] | undefined,
	read?: ModelTypes["TicketingDocAccessFields_sessions_inventories_stocks_sold_Read"] | undefined,
	update?: ModelTypes["TicketingDocAccessFields_sessions_inventories_stocks_sold_Update"] | undefined,
	delete?: ModelTypes["TicketingDocAccessFields_sessions_inventories_stocks_sold_Delete"] | undefined
};
	["TicketingDocAccessFields_sessions_inventories_stocks_sold_Create"]: {
		permission: boolean
};
	["TicketingDocAccessFields_sessions_inventories_stocks_sold_Read"]: {
		permission: boolean
};
	["TicketingDocAccessFields_sessions_inventories_stocks_sold_Update"]: {
		permission: boolean
};
	["TicketingDocAccessFields_sessions_inventories_stocks_sold_Delete"]: {
		permission: boolean
};
	["TicketingDocAccessFields_sessions_inventories_stocks_onHold"]: {
		create?: ModelTypes["TicketingDocAccessFields_sessions_inventories_stocks_onHold_Create"] | undefined,
	read?: ModelTypes["TicketingDocAccessFields_sessions_inventories_stocks_onHold_Read"] | undefined,
	update?: ModelTypes["TicketingDocAccessFields_sessions_inventories_stocks_onHold_Update"] | undefined,
	delete?: ModelTypes["TicketingDocAccessFields_sessions_inventories_stocks_onHold_Delete"] | undefined
};
	["TicketingDocAccessFields_sessions_inventories_stocks_onHold_Create"]: {
		permission: boolean
};
	["TicketingDocAccessFields_sessions_inventories_stocks_onHold_Read"]: {
		permission: boolean
};
	["TicketingDocAccessFields_sessions_inventories_stocks_onHold_Update"]: {
		permission: boolean
};
	["TicketingDocAccessFields_sessions_inventories_stocks_onHold_Delete"]: {
		permission: boolean
};
	["TicketingDocAccessFields_sessions_inventories_stocks_checkedIn"]: {
		create?: ModelTypes["TicketingDocAccessFields_sessions_inventories_stocks_checkedIn_Create"] | undefined,
	read?: ModelTypes["TicketingDocAccessFields_sessions_inventories_stocks_checkedIn_Read"] | undefined,
	update?: ModelTypes["TicketingDocAccessFields_sessions_inventories_stocks_checkedIn_Update"] | undefined,
	delete?: ModelTypes["TicketingDocAccessFields_sessions_inventories_stocks_checkedIn_Delete"] | undefined
};
	["TicketingDocAccessFields_sessions_inventories_stocks_checkedIn_Create"]: {
		permission: boolean
};
	["TicketingDocAccessFields_sessions_inventories_stocks_checkedIn_Read"]: {
		permission: boolean
};
	["TicketingDocAccessFields_sessions_inventories_stocks_checkedIn_Update"]: {
		permission: boolean
};
	["TicketingDocAccessFields_sessions_inventories_stocks_checkedIn_Delete"]: {
		permission: boolean
};
	["TicketingDocAccessFields_sessions_inventories_remarks"]: {
		create?: ModelTypes["TicketingDocAccessFields_sessions_inventories_remarks_Create"] | undefined,
	read?: ModelTypes["TicketingDocAccessFields_sessions_inventories_remarks_Read"] | undefined,
	update?: ModelTypes["TicketingDocAccessFields_sessions_inventories_remarks_Update"] | undefined,
	delete?: ModelTypes["TicketingDocAccessFields_sessions_inventories_remarks_Delete"] | undefined
};
	["TicketingDocAccessFields_sessions_inventories_remarks_Create"]: {
		permission: boolean
};
	["TicketingDocAccessFields_sessions_inventories_remarks_Read"]: {
		permission: boolean
};
	["TicketingDocAccessFields_sessions_inventories_remarks_Update"]: {
		permission: boolean
};
	["TicketingDocAccessFields_sessions_inventories_remarks_Delete"]: {
		permission: boolean
};
	["TicketingDocAccessFields_sessions_id"]: {
		create?: ModelTypes["TicketingDocAccessFields_sessions_id_Create"] | undefined,
	read?: ModelTypes["TicketingDocAccessFields_sessions_id_Read"] | undefined,
	update?: ModelTypes["TicketingDocAccessFields_sessions_id_Update"] | undefined,
	delete?: ModelTypes["TicketingDocAccessFields_sessions_id_Delete"] | undefined
};
	["TicketingDocAccessFields_sessions_id_Create"]: {
		permission: boolean
};
	["TicketingDocAccessFields_sessions_id_Read"]: {
		permission: boolean
};
	["TicketingDocAccessFields_sessions_id_Update"]: {
		permission: boolean
};
	["TicketingDocAccessFields_sessions_id_Delete"]: {
		permission: boolean
};
	["TicketingDocAccessFields_updatedAt"]: {
		create?: ModelTypes["TicketingDocAccessFields_updatedAt_Create"] | undefined,
	read?: ModelTypes["TicketingDocAccessFields_updatedAt_Read"] | undefined,
	update?: ModelTypes["TicketingDocAccessFields_updatedAt_Update"] | undefined,
	delete?: ModelTypes["TicketingDocAccessFields_updatedAt_Delete"] | undefined
};
	["TicketingDocAccessFields_updatedAt_Create"]: {
		permission: boolean
};
	["TicketingDocAccessFields_updatedAt_Read"]: {
		permission: boolean
};
	["TicketingDocAccessFields_updatedAt_Update"]: {
		permission: boolean
};
	["TicketingDocAccessFields_updatedAt_Delete"]: {
		permission: boolean
};
	["TicketingDocAccessFields_createdAt"]: {
		create?: ModelTypes["TicketingDocAccessFields_createdAt_Create"] | undefined,
	read?: ModelTypes["TicketingDocAccessFields_createdAt_Read"] | undefined,
	update?: ModelTypes["TicketingDocAccessFields_createdAt_Update"] | undefined,
	delete?: ModelTypes["TicketingDocAccessFields_createdAt_Delete"] | undefined
};
	["TicketingDocAccessFields_createdAt_Create"]: {
		permission: boolean
};
	["TicketingDocAccessFields_createdAt_Read"]: {
		permission: boolean
};
	["TicketingDocAccessFields_createdAt_Update"]: {
		permission: boolean
};
	["TicketingDocAccessFields_createdAt_Delete"]: {
		permission: boolean
};
	["TicketingCreateDocAccess"]: {
		permission: boolean,
	where?: ModelTypes["JSONObject"] | undefined
};
	["TicketingReadDocAccess"]: {
		permission: boolean,
	where?: ModelTypes["JSONObject"] | undefined
};
	["TicketingUpdateDocAccess"]: {
		permission: boolean,
	where?: ModelTypes["JSONObject"] | undefined
};
	["TicketingDeleteDocAccess"]: {
		permission: boolean,
	where?: ModelTypes["JSONObject"] | undefined
};
	["Products"]: {
		docs?: Array<ModelTypes["Product"] | undefined> | undefined,
	hasNextPage?: boolean | undefined,
	hasPrevPage?: boolean | undefined,
	limit?: number | undefined,
	nextPage?: number | undefined,
	offset?: number | undefined,
	page?: number | undefined,
	pagingCounter?: number | undefined,
	prevPage?: number | undefined,
	totalDocs?: number | undefined,
	totalPages?: number | undefined
};
	["Product_where"]: {
	status?: ModelTypes["Product_status_operator"] | undefined,
	creator?: ModelTypes["Product_creator_operator"] | undefined,
	productType?: ModelTypes["Product_productType_operator"] | undefined,
	productName?: ModelTypes["Product_productName_operator"] | undefined,
	referenceId?: ModelTypes["Product_referenceId_operator"] | undefined,
	linkedEvent?: ModelTypes["Product_linkedEvent_operator"] | undefined,
	CheckInLevel?: ModelTypes["Product_CheckInLevel_operator"] | undefined,
	thumbnail?: ModelTypes["Product_thumbnail_operator"] | undefined,
	displayPrice?: ModelTypes["Product_displayPrice_operator"] | undefined,
	transactionType?: ModelTypes["Product_transactionType_operator"] | undefined,
	website?: ModelTypes["Product_website_operator"] | undefined,
	tnc?: ModelTypes["Product_tnc_operator"] | undefined,
	remarks?: ModelTypes["Product_remarks_operator"] | undefined,
	sessions__variantId?: ModelTypes["Product_sessions__variantId_operator"] | undefined,
	sessions__productId?: ModelTypes["Product_sessions__productId_operator"] | undefined,
	sessions__name?: ModelTypes["Product_sessions__name_operator"] | undefined,
	sessions__inventories__quantity?: ModelTypes["Product_sessions__inventories__quantity_operator"] | undefined,
	sessions__inventories__unitPrice?: ModelTypes["Product_sessions__inventories__unitPrice_operator"] | undefined,
	sessions__inventories__handlingFee?: ModelTypes["Product_sessions__inventories__handlingFee_operator"] | undefined,
	sessions__inventories__totalCost?: ModelTypes["Product_sessions__inventories__totalCost_operator"] | undefined,
	sessions__inventories__minPurchase?: ModelTypes["Product_sessions__inventories__minPurchase_operator"] | undefined,
	sessions__inventories__maxPurchase?: ModelTypes["Product_sessions__inventories__maxPurchase_operator"] | undefined,
	sessions__inventories__stocks__stockId?: ModelTypes["Product_sessions__inventories__stocks__stockId_operator"] | undefined,
	sessions__inventories__stocks__remaining?: ModelTypes["Product_sessions__inventories__stocks__remaining_operator"] | undefined,
	sessions__inventories__stocks__sold?: ModelTypes["Product_sessions__inventories__stocks__sold_operator"] | undefined,
	sessions__inventories__stocks__onHold?: ModelTypes["Product_sessions__inventories__stocks__onHold_operator"] | undefined,
	sessions__inventories__stocks__checkedIn?: ModelTypes["Product_sessions__inventories__stocks__checkedIn_operator"] | undefined,
	sessions__id?: ModelTypes["Product_sessions__id_operator"] | undefined,
	updatedAt?: ModelTypes["Product_updatedAt_operator"] | undefined,
	createdAt?: ModelTypes["Product_createdAt_operator"] | undefined,
	id?: ModelTypes["Product_id_operator"] | undefined,
	AND?: Array<ModelTypes["Product_where_and"] | undefined> | undefined,
	OR?: Array<ModelTypes["Product_where_or"] | undefined> | undefined
};
	["Product_status_operator"]: {
	equals?: ModelTypes["Product_status_Input"] | undefined,
	not_equals?: ModelTypes["Product_status_Input"] | undefined,
	in?: Array<ModelTypes["Product_status_Input"] | undefined> | undefined,
	not_in?: Array<ModelTypes["Product_status_Input"] | undefined> | undefined,
	all?: Array<ModelTypes["Product_status_Input"] | undefined> | undefined,
	exists?: boolean | undefined
};
	["Product_status_Input"]:Product_status_Input;
	["Product_creator_operator"]: {
	equals?: ModelTypes["JSON"] | undefined,
	not_equals?: ModelTypes["JSON"] | undefined,
	in?: Array<ModelTypes["JSON"] | undefined> | undefined,
	not_in?: Array<ModelTypes["JSON"] | undefined> | undefined,
	all?: Array<ModelTypes["JSON"] | undefined> | undefined,
	exists?: boolean | undefined
};
	["Product_productType_operator"]: {
	equals?: ModelTypes["Product_productType_Input"] | undefined,
	not_equals?: ModelTypes["Product_productType_Input"] | undefined,
	like?: ModelTypes["Product_productType_Input"] | undefined,
	contains?: ModelTypes["Product_productType_Input"] | undefined
};
	["Product_productType_Input"]:Product_productType_Input;
	["Product_productName_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined
};
	["Product_referenceId_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Product_linkedEvent_operator"]: {
	equals?: ModelTypes["JSON"] | undefined,
	not_equals?: ModelTypes["JSON"] | undefined,
	in?: Array<ModelTypes["JSON"] | undefined> | undefined,
	not_in?: Array<ModelTypes["JSON"] | undefined> | undefined,
	all?: Array<ModelTypes["JSON"] | undefined> | undefined
};
	["Product_CheckInLevel_operator"]: {
	equals?: ModelTypes["Product_CheckInLevel_Input"] | undefined,
	not_equals?: ModelTypes["Product_CheckInLevel_Input"] | undefined,
	in?: Array<ModelTypes["Product_CheckInLevel_Input"] | undefined> | undefined,
	not_in?: Array<ModelTypes["Product_CheckInLevel_Input"] | undefined> | undefined,
	all?: Array<ModelTypes["Product_CheckInLevel_Input"] | undefined> | undefined
};
	["Product_CheckInLevel_Input"]:Product_CheckInLevel_Input;
	["Product_thumbnail_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined
};
	["Product_displayPrice_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined
};
	["Product_transactionType_operator"]: {
	equals?: ModelTypes["Product_transactionType_Input"] | undefined,
	not_equals?: ModelTypes["Product_transactionType_Input"] | undefined,
	like?: ModelTypes["Product_transactionType_Input"] | undefined,
	contains?: ModelTypes["Product_transactionType_Input"] | undefined
};
	["Product_transactionType_Input"]:Product_transactionType_Input;
	["Product_website_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined
};
	["Product_tnc_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined
};
	["Product_remarks_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	exists?: boolean | undefined
};
	["Product_sessions__variantId_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Product_sessions__productId_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Product_sessions__name_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined
};
	["Product_sessions__inventories__quantity_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined
};
	["Product_sessions__inventories__unitPrice_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined
};
	["Product_sessions__inventories__handlingFee_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined
};
	["Product_sessions__inventories__totalCost_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Product_sessions__inventories__minPurchase_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined
};
	["Product_sessions__inventories__maxPurchase_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined
};
	["Product_sessions__inventories__stocks__stockId_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Product_sessions__inventories__stocks__remaining_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Product_sessions__inventories__stocks__sold_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Product_sessions__inventories__stocks__onHold_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Product_sessions__inventories__stocks__checkedIn_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Product_sessions__id_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Product_updatedAt_operator"]: {
	equals?: ModelTypes["DateTime"] | undefined,
	not_equals?: ModelTypes["DateTime"] | undefined,
	greater_than_equal?: ModelTypes["DateTime"] | undefined,
	greater_than?: ModelTypes["DateTime"] | undefined,
	less_than_equal?: ModelTypes["DateTime"] | undefined,
	less_than?: ModelTypes["DateTime"] | undefined,
	like?: ModelTypes["DateTime"] | undefined,
	exists?: boolean | undefined
};
	["Product_createdAt_operator"]: {
	equals?: ModelTypes["DateTime"] | undefined,
	not_equals?: ModelTypes["DateTime"] | undefined,
	greater_than_equal?: ModelTypes["DateTime"] | undefined,
	greater_than?: ModelTypes["DateTime"] | undefined,
	less_than_equal?: ModelTypes["DateTime"] | undefined,
	less_than?: ModelTypes["DateTime"] | undefined,
	like?: ModelTypes["DateTime"] | undefined,
	exists?: boolean | undefined
};
	["Product_id_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Product_where_and"]: {
	status?: ModelTypes["Product_status_operator"] | undefined,
	creator?: ModelTypes["Product_creator_operator"] | undefined,
	productType?: ModelTypes["Product_productType_operator"] | undefined,
	productName?: ModelTypes["Product_productName_operator"] | undefined,
	referenceId?: ModelTypes["Product_referenceId_operator"] | undefined,
	linkedEvent?: ModelTypes["Product_linkedEvent_operator"] | undefined,
	CheckInLevel?: ModelTypes["Product_CheckInLevel_operator"] | undefined,
	thumbnail?: ModelTypes["Product_thumbnail_operator"] | undefined,
	displayPrice?: ModelTypes["Product_displayPrice_operator"] | undefined,
	transactionType?: ModelTypes["Product_transactionType_operator"] | undefined,
	website?: ModelTypes["Product_website_operator"] | undefined,
	tnc?: ModelTypes["Product_tnc_operator"] | undefined,
	remarks?: ModelTypes["Product_remarks_operator"] | undefined,
	sessions__variantId?: ModelTypes["Product_sessions__variantId_operator"] | undefined,
	sessions__productId?: ModelTypes["Product_sessions__productId_operator"] | undefined,
	sessions__name?: ModelTypes["Product_sessions__name_operator"] | undefined,
	sessions__inventories__quantity?: ModelTypes["Product_sessions__inventories__quantity_operator"] | undefined,
	sessions__inventories__unitPrice?: ModelTypes["Product_sessions__inventories__unitPrice_operator"] | undefined,
	sessions__inventories__handlingFee?: ModelTypes["Product_sessions__inventories__handlingFee_operator"] | undefined,
	sessions__inventories__totalCost?: ModelTypes["Product_sessions__inventories__totalCost_operator"] | undefined,
	sessions__inventories__minPurchase?: ModelTypes["Product_sessions__inventories__minPurchase_operator"] | undefined,
	sessions__inventories__maxPurchase?: ModelTypes["Product_sessions__inventories__maxPurchase_operator"] | undefined,
	sessions__inventories__stocks__stockId?: ModelTypes["Product_sessions__inventories__stocks__stockId_operator"] | undefined,
	sessions__inventories__stocks__remaining?: ModelTypes["Product_sessions__inventories__stocks__remaining_operator"] | undefined,
	sessions__inventories__stocks__sold?: ModelTypes["Product_sessions__inventories__stocks__sold_operator"] | undefined,
	sessions__inventories__stocks__onHold?: ModelTypes["Product_sessions__inventories__stocks__onHold_operator"] | undefined,
	sessions__inventories__stocks__checkedIn?: ModelTypes["Product_sessions__inventories__stocks__checkedIn_operator"] | undefined,
	sessions__id?: ModelTypes["Product_sessions__id_operator"] | undefined,
	updatedAt?: ModelTypes["Product_updatedAt_operator"] | undefined,
	createdAt?: ModelTypes["Product_createdAt_operator"] | undefined,
	id?: ModelTypes["Product_id_operator"] | undefined,
	AND?: Array<ModelTypes["Product_where_and"] | undefined> | undefined,
	OR?: Array<ModelTypes["Product_where_or"] | undefined> | undefined
};
	["Product_where_or"]: {
	status?: ModelTypes["Product_status_operator"] | undefined,
	creator?: ModelTypes["Product_creator_operator"] | undefined,
	productType?: ModelTypes["Product_productType_operator"] | undefined,
	productName?: ModelTypes["Product_productName_operator"] | undefined,
	referenceId?: ModelTypes["Product_referenceId_operator"] | undefined,
	linkedEvent?: ModelTypes["Product_linkedEvent_operator"] | undefined,
	CheckInLevel?: ModelTypes["Product_CheckInLevel_operator"] | undefined,
	thumbnail?: ModelTypes["Product_thumbnail_operator"] | undefined,
	displayPrice?: ModelTypes["Product_displayPrice_operator"] | undefined,
	transactionType?: ModelTypes["Product_transactionType_operator"] | undefined,
	website?: ModelTypes["Product_website_operator"] | undefined,
	tnc?: ModelTypes["Product_tnc_operator"] | undefined,
	remarks?: ModelTypes["Product_remarks_operator"] | undefined,
	sessions__variantId?: ModelTypes["Product_sessions__variantId_operator"] | undefined,
	sessions__productId?: ModelTypes["Product_sessions__productId_operator"] | undefined,
	sessions__name?: ModelTypes["Product_sessions__name_operator"] | undefined,
	sessions__inventories__quantity?: ModelTypes["Product_sessions__inventories__quantity_operator"] | undefined,
	sessions__inventories__unitPrice?: ModelTypes["Product_sessions__inventories__unitPrice_operator"] | undefined,
	sessions__inventories__handlingFee?: ModelTypes["Product_sessions__inventories__handlingFee_operator"] | undefined,
	sessions__inventories__totalCost?: ModelTypes["Product_sessions__inventories__totalCost_operator"] | undefined,
	sessions__inventories__minPurchase?: ModelTypes["Product_sessions__inventories__minPurchase_operator"] | undefined,
	sessions__inventories__maxPurchase?: ModelTypes["Product_sessions__inventories__maxPurchase_operator"] | undefined,
	sessions__inventories__stocks__stockId?: ModelTypes["Product_sessions__inventories__stocks__stockId_operator"] | undefined,
	sessions__inventories__stocks__remaining?: ModelTypes["Product_sessions__inventories__stocks__remaining_operator"] | undefined,
	sessions__inventories__stocks__sold?: ModelTypes["Product_sessions__inventories__stocks__sold_operator"] | undefined,
	sessions__inventories__stocks__onHold?: ModelTypes["Product_sessions__inventories__stocks__onHold_operator"] | undefined,
	sessions__inventories__stocks__checkedIn?: ModelTypes["Product_sessions__inventories__stocks__checkedIn_operator"] | undefined,
	sessions__id?: ModelTypes["Product_sessions__id_operator"] | undefined,
	updatedAt?: ModelTypes["Product_updatedAt_operator"] | undefined,
	createdAt?: ModelTypes["Product_createdAt_operator"] | undefined,
	id?: ModelTypes["Product_id_operator"] | undefined,
	AND?: Array<ModelTypes["Product_where_and"] | undefined> | undefined,
	OR?: Array<ModelTypes["Product_where_or"] | undefined> | undefined
};
	["countProducts"]: {
		totalDocs?: number | undefined
};
	["productsDocAccess"]: {
		fields?: ModelTypes["ProductsDocAccessFields"] | undefined,
	create?: ModelTypes["ProductsCreateDocAccess"] | undefined,
	read?: ModelTypes["ProductsReadDocAccess"] | undefined,
	update?: ModelTypes["ProductsUpdateDocAccess"] | undefined,
	delete?: ModelTypes["ProductsDeleteDocAccess"] | undefined
};
	["ProductsDocAccessFields"]: {
		status?: ModelTypes["ProductsDocAccessFields_status"] | undefined,
	creator?: ModelTypes["ProductsDocAccessFields_creator"] | undefined,
	productType?: ModelTypes["ProductsDocAccessFields_productType"] | undefined,
	productName?: ModelTypes["ProductsDocAccessFields_productName"] | undefined,
	referenceId?: ModelTypes["ProductsDocAccessFields_referenceId"] | undefined,
	linkedEvent?: ModelTypes["ProductsDocAccessFields_linkedEvent"] | undefined,
	CheckInLevel?: ModelTypes["ProductsDocAccessFields_CheckInLevel"] | undefined,
	thumbnail?: ModelTypes["ProductsDocAccessFields_thumbnail"] | undefined,
	displayPrice?: ModelTypes["ProductsDocAccessFields_displayPrice"] | undefined,
	transactionType?: ModelTypes["ProductsDocAccessFields_transactionType"] | undefined,
	website?: ModelTypes["ProductsDocAccessFields_website"] | undefined,
	tnc?: ModelTypes["ProductsDocAccessFields_tnc"] | undefined,
	remarks?: ModelTypes["ProductsDocAccessFields_remarks"] | undefined,
	sessions?: ModelTypes["ProductsDocAccessFields_sessions"] | undefined,
	updatedAt?: ModelTypes["ProductsDocAccessFields_updatedAt"] | undefined,
	createdAt?: ModelTypes["ProductsDocAccessFields_createdAt"] | undefined
};
	["ProductsDocAccessFields_status"]: {
		create?: ModelTypes["ProductsDocAccessFields_status_Create"] | undefined,
	read?: ModelTypes["ProductsDocAccessFields_status_Read"] | undefined,
	update?: ModelTypes["ProductsDocAccessFields_status_Update"] | undefined,
	delete?: ModelTypes["ProductsDocAccessFields_status_Delete"] | undefined
};
	["ProductsDocAccessFields_status_Create"]: {
		permission: boolean
};
	["ProductsDocAccessFields_status_Read"]: {
		permission: boolean
};
	["ProductsDocAccessFields_status_Update"]: {
		permission: boolean
};
	["ProductsDocAccessFields_status_Delete"]: {
		permission: boolean
};
	["ProductsDocAccessFields_creator"]: {
		create?: ModelTypes["ProductsDocAccessFields_creator_Create"] | undefined,
	read?: ModelTypes["ProductsDocAccessFields_creator_Read"] | undefined,
	update?: ModelTypes["ProductsDocAccessFields_creator_Update"] | undefined,
	delete?: ModelTypes["ProductsDocAccessFields_creator_Delete"] | undefined
};
	["ProductsDocAccessFields_creator_Create"]: {
		permission: boolean
};
	["ProductsDocAccessFields_creator_Read"]: {
		permission: boolean
};
	["ProductsDocAccessFields_creator_Update"]: {
		permission: boolean
};
	["ProductsDocAccessFields_creator_Delete"]: {
		permission: boolean
};
	["ProductsDocAccessFields_productType"]: {
		create?: ModelTypes["ProductsDocAccessFields_productType_Create"] | undefined,
	read?: ModelTypes["ProductsDocAccessFields_productType_Read"] | undefined,
	update?: ModelTypes["ProductsDocAccessFields_productType_Update"] | undefined,
	delete?: ModelTypes["ProductsDocAccessFields_productType_Delete"] | undefined
};
	["ProductsDocAccessFields_productType_Create"]: {
		permission: boolean
};
	["ProductsDocAccessFields_productType_Read"]: {
		permission: boolean
};
	["ProductsDocAccessFields_productType_Update"]: {
		permission: boolean
};
	["ProductsDocAccessFields_productType_Delete"]: {
		permission: boolean
};
	["ProductsDocAccessFields_productName"]: {
		create?: ModelTypes["ProductsDocAccessFields_productName_Create"] | undefined,
	read?: ModelTypes["ProductsDocAccessFields_productName_Read"] | undefined,
	update?: ModelTypes["ProductsDocAccessFields_productName_Update"] | undefined,
	delete?: ModelTypes["ProductsDocAccessFields_productName_Delete"] | undefined
};
	["ProductsDocAccessFields_productName_Create"]: {
		permission: boolean
};
	["ProductsDocAccessFields_productName_Read"]: {
		permission: boolean
};
	["ProductsDocAccessFields_productName_Update"]: {
		permission: boolean
};
	["ProductsDocAccessFields_productName_Delete"]: {
		permission: boolean
};
	["ProductsDocAccessFields_referenceId"]: {
		create?: ModelTypes["ProductsDocAccessFields_referenceId_Create"] | undefined,
	read?: ModelTypes["ProductsDocAccessFields_referenceId_Read"] | undefined,
	update?: ModelTypes["ProductsDocAccessFields_referenceId_Update"] | undefined,
	delete?: ModelTypes["ProductsDocAccessFields_referenceId_Delete"] | undefined
};
	["ProductsDocAccessFields_referenceId_Create"]: {
		permission: boolean
};
	["ProductsDocAccessFields_referenceId_Read"]: {
		permission: boolean
};
	["ProductsDocAccessFields_referenceId_Update"]: {
		permission: boolean
};
	["ProductsDocAccessFields_referenceId_Delete"]: {
		permission: boolean
};
	["ProductsDocAccessFields_linkedEvent"]: {
		create?: ModelTypes["ProductsDocAccessFields_linkedEvent_Create"] | undefined,
	read?: ModelTypes["ProductsDocAccessFields_linkedEvent_Read"] | undefined,
	update?: ModelTypes["ProductsDocAccessFields_linkedEvent_Update"] | undefined,
	delete?: ModelTypes["ProductsDocAccessFields_linkedEvent_Delete"] | undefined
};
	["ProductsDocAccessFields_linkedEvent_Create"]: {
		permission: boolean
};
	["ProductsDocAccessFields_linkedEvent_Read"]: {
		permission: boolean
};
	["ProductsDocAccessFields_linkedEvent_Update"]: {
		permission: boolean
};
	["ProductsDocAccessFields_linkedEvent_Delete"]: {
		permission: boolean
};
	["ProductsDocAccessFields_CheckInLevel"]: {
		create?: ModelTypes["ProductsDocAccessFields_CheckInLevel_Create"] | undefined,
	read?: ModelTypes["ProductsDocAccessFields_CheckInLevel_Read"] | undefined,
	update?: ModelTypes["ProductsDocAccessFields_CheckInLevel_Update"] | undefined,
	delete?: ModelTypes["ProductsDocAccessFields_CheckInLevel_Delete"] | undefined
};
	["ProductsDocAccessFields_CheckInLevel_Create"]: {
		permission: boolean
};
	["ProductsDocAccessFields_CheckInLevel_Read"]: {
		permission: boolean
};
	["ProductsDocAccessFields_CheckInLevel_Update"]: {
		permission: boolean
};
	["ProductsDocAccessFields_CheckInLevel_Delete"]: {
		permission: boolean
};
	["ProductsDocAccessFields_thumbnail"]: {
		create?: ModelTypes["ProductsDocAccessFields_thumbnail_Create"] | undefined,
	read?: ModelTypes["ProductsDocAccessFields_thumbnail_Read"] | undefined,
	update?: ModelTypes["ProductsDocAccessFields_thumbnail_Update"] | undefined,
	delete?: ModelTypes["ProductsDocAccessFields_thumbnail_Delete"] | undefined
};
	["ProductsDocAccessFields_thumbnail_Create"]: {
		permission: boolean
};
	["ProductsDocAccessFields_thumbnail_Read"]: {
		permission: boolean
};
	["ProductsDocAccessFields_thumbnail_Update"]: {
		permission: boolean
};
	["ProductsDocAccessFields_thumbnail_Delete"]: {
		permission: boolean
};
	["ProductsDocAccessFields_displayPrice"]: {
		create?: ModelTypes["ProductsDocAccessFields_displayPrice_Create"] | undefined,
	read?: ModelTypes["ProductsDocAccessFields_displayPrice_Read"] | undefined,
	update?: ModelTypes["ProductsDocAccessFields_displayPrice_Update"] | undefined,
	delete?: ModelTypes["ProductsDocAccessFields_displayPrice_Delete"] | undefined
};
	["ProductsDocAccessFields_displayPrice_Create"]: {
		permission: boolean
};
	["ProductsDocAccessFields_displayPrice_Read"]: {
		permission: boolean
};
	["ProductsDocAccessFields_displayPrice_Update"]: {
		permission: boolean
};
	["ProductsDocAccessFields_displayPrice_Delete"]: {
		permission: boolean
};
	["ProductsDocAccessFields_transactionType"]: {
		create?: ModelTypes["ProductsDocAccessFields_transactionType_Create"] | undefined,
	read?: ModelTypes["ProductsDocAccessFields_transactionType_Read"] | undefined,
	update?: ModelTypes["ProductsDocAccessFields_transactionType_Update"] | undefined,
	delete?: ModelTypes["ProductsDocAccessFields_transactionType_Delete"] | undefined
};
	["ProductsDocAccessFields_transactionType_Create"]: {
		permission: boolean
};
	["ProductsDocAccessFields_transactionType_Read"]: {
		permission: boolean
};
	["ProductsDocAccessFields_transactionType_Update"]: {
		permission: boolean
};
	["ProductsDocAccessFields_transactionType_Delete"]: {
		permission: boolean
};
	["ProductsDocAccessFields_website"]: {
		create?: ModelTypes["ProductsDocAccessFields_website_Create"] | undefined,
	read?: ModelTypes["ProductsDocAccessFields_website_Read"] | undefined,
	update?: ModelTypes["ProductsDocAccessFields_website_Update"] | undefined,
	delete?: ModelTypes["ProductsDocAccessFields_website_Delete"] | undefined
};
	["ProductsDocAccessFields_website_Create"]: {
		permission: boolean
};
	["ProductsDocAccessFields_website_Read"]: {
		permission: boolean
};
	["ProductsDocAccessFields_website_Update"]: {
		permission: boolean
};
	["ProductsDocAccessFields_website_Delete"]: {
		permission: boolean
};
	["ProductsDocAccessFields_tnc"]: {
		create?: ModelTypes["ProductsDocAccessFields_tnc_Create"] | undefined,
	read?: ModelTypes["ProductsDocAccessFields_tnc_Read"] | undefined,
	update?: ModelTypes["ProductsDocAccessFields_tnc_Update"] | undefined,
	delete?: ModelTypes["ProductsDocAccessFields_tnc_Delete"] | undefined
};
	["ProductsDocAccessFields_tnc_Create"]: {
		permission: boolean
};
	["ProductsDocAccessFields_tnc_Read"]: {
		permission: boolean
};
	["ProductsDocAccessFields_tnc_Update"]: {
		permission: boolean
};
	["ProductsDocAccessFields_tnc_Delete"]: {
		permission: boolean
};
	["ProductsDocAccessFields_remarks"]: {
		create?: ModelTypes["ProductsDocAccessFields_remarks_Create"] | undefined,
	read?: ModelTypes["ProductsDocAccessFields_remarks_Read"] | undefined,
	update?: ModelTypes["ProductsDocAccessFields_remarks_Update"] | undefined,
	delete?: ModelTypes["ProductsDocAccessFields_remarks_Delete"] | undefined
};
	["ProductsDocAccessFields_remarks_Create"]: {
		permission: boolean
};
	["ProductsDocAccessFields_remarks_Read"]: {
		permission: boolean
};
	["ProductsDocAccessFields_remarks_Update"]: {
		permission: boolean
};
	["ProductsDocAccessFields_remarks_Delete"]: {
		permission: boolean
};
	["ProductsDocAccessFields_sessions"]: {
		create?: ModelTypes["ProductsDocAccessFields_sessions_Create"] | undefined,
	read?: ModelTypes["ProductsDocAccessFields_sessions_Read"] | undefined,
	update?: ModelTypes["ProductsDocAccessFields_sessions_Update"] | undefined,
	delete?: ModelTypes["ProductsDocAccessFields_sessions_Delete"] | undefined,
	fields?: ModelTypes["ProductsDocAccessFields_sessions_Fields"] | undefined
};
	["ProductsDocAccessFields_sessions_Create"]: {
		permission: boolean
};
	["ProductsDocAccessFields_sessions_Read"]: {
		permission: boolean
};
	["ProductsDocAccessFields_sessions_Update"]: {
		permission: boolean
};
	["ProductsDocAccessFields_sessions_Delete"]: {
		permission: boolean
};
	["ProductsDocAccessFields_sessions_Fields"]: {
		productId?: ModelTypes["ProductsDocAccessFields_sessions_productId"] | undefined,
	name?: ModelTypes["ProductsDocAccessFields_sessions_name"] | undefined,
	inventories?: ModelTypes["ProductsDocAccessFields_sessions_inventories"] | undefined,
	id?: ModelTypes["ProductsDocAccessFields_sessions_id"] | undefined
};
	["ProductsDocAccessFields_sessions_productId"]: {
		create?: ModelTypes["ProductsDocAccessFields_sessions_productId_Create"] | undefined,
	read?: ModelTypes["ProductsDocAccessFields_sessions_productId_Read"] | undefined,
	update?: ModelTypes["ProductsDocAccessFields_sessions_productId_Update"] | undefined,
	delete?: ModelTypes["ProductsDocAccessFields_sessions_productId_Delete"] | undefined
};
	["ProductsDocAccessFields_sessions_productId_Create"]: {
		permission: boolean
};
	["ProductsDocAccessFields_sessions_productId_Read"]: {
		permission: boolean
};
	["ProductsDocAccessFields_sessions_productId_Update"]: {
		permission: boolean
};
	["ProductsDocAccessFields_sessions_productId_Delete"]: {
		permission: boolean
};
	["ProductsDocAccessFields_sessions_name"]: {
		create?: ModelTypes["ProductsDocAccessFields_sessions_name_Create"] | undefined,
	read?: ModelTypes["ProductsDocAccessFields_sessions_name_Read"] | undefined,
	update?: ModelTypes["ProductsDocAccessFields_sessions_name_Update"] | undefined,
	delete?: ModelTypes["ProductsDocAccessFields_sessions_name_Delete"] | undefined
};
	["ProductsDocAccessFields_sessions_name_Create"]: {
		permission: boolean
};
	["ProductsDocAccessFields_sessions_name_Read"]: {
		permission: boolean
};
	["ProductsDocAccessFields_sessions_name_Update"]: {
		permission: boolean
};
	["ProductsDocAccessFields_sessions_name_Delete"]: {
		permission: boolean
};
	["ProductsDocAccessFields_sessions_inventories"]: {
		create?: ModelTypes["ProductsDocAccessFields_sessions_inventories_Create"] | undefined,
	read?: ModelTypes["ProductsDocAccessFields_sessions_inventories_Read"] | undefined,
	update?: ModelTypes["ProductsDocAccessFields_sessions_inventories_Update"] | undefined,
	delete?: ModelTypes["ProductsDocAccessFields_sessions_inventories_Delete"] | undefined,
	fields?: ModelTypes["ProductsDocAccessFields_sessions_inventories_Fields"] | undefined
};
	["ProductsDocAccessFields_sessions_inventories_Create"]: {
		permission: boolean
};
	["ProductsDocAccessFields_sessions_inventories_Read"]: {
		permission: boolean
};
	["ProductsDocAccessFields_sessions_inventories_Update"]: {
		permission: boolean
};
	["ProductsDocAccessFields_sessions_inventories_Delete"]: {
		permission: boolean
};
	["ProductsDocAccessFields_sessions_inventories_Fields"]: {
		quantity?: ModelTypes["ProductsDocAccessFields_sessions_inventories_quantity"] | undefined,
	unitPrice?: ModelTypes["ProductsDocAccessFields_sessions_inventories_unitPrice"] | undefined,
	handlingFee?: ModelTypes["ProductsDocAccessFields_sessions_inventories_handlingFee"] | undefined,
	totalCost?: ModelTypes["ProductsDocAccessFields_sessions_inventories_totalCost"] | undefined,
	minPurchase?: ModelTypes["ProductsDocAccessFields_sessions_inventories_minPurchase"] | undefined,
	maxPurchase?: ModelTypes["ProductsDocAccessFields_sessions_inventories_maxPurchase"] | undefined,
	stocks?: ModelTypes["ProductsDocAccessFields_sessions_inventories_stocks"] | undefined
};
	["ProductsDocAccessFields_sessions_inventories_quantity"]: {
		create?: ModelTypes["ProductsDocAccessFields_sessions_inventories_quantity_Create"] | undefined,
	read?: ModelTypes["ProductsDocAccessFields_sessions_inventories_quantity_Read"] | undefined,
	update?: ModelTypes["ProductsDocAccessFields_sessions_inventories_quantity_Update"] | undefined,
	delete?: ModelTypes["ProductsDocAccessFields_sessions_inventories_quantity_Delete"] | undefined
};
	["ProductsDocAccessFields_sessions_inventories_quantity_Create"]: {
		permission: boolean
};
	["ProductsDocAccessFields_sessions_inventories_quantity_Read"]: {
		permission: boolean
};
	["ProductsDocAccessFields_sessions_inventories_quantity_Update"]: {
		permission: boolean
};
	["ProductsDocAccessFields_sessions_inventories_quantity_Delete"]: {
		permission: boolean
};
	["ProductsDocAccessFields_sessions_inventories_unitPrice"]: {
		create?: ModelTypes["ProductsDocAccessFields_sessions_inventories_unitPrice_Create"] | undefined,
	read?: ModelTypes["ProductsDocAccessFields_sessions_inventories_unitPrice_Read"] | undefined,
	update?: ModelTypes["ProductsDocAccessFields_sessions_inventories_unitPrice_Update"] | undefined,
	delete?: ModelTypes["ProductsDocAccessFields_sessions_inventories_unitPrice_Delete"] | undefined
};
	["ProductsDocAccessFields_sessions_inventories_unitPrice_Create"]: {
		permission: boolean
};
	["ProductsDocAccessFields_sessions_inventories_unitPrice_Read"]: {
		permission: boolean
};
	["ProductsDocAccessFields_sessions_inventories_unitPrice_Update"]: {
		permission: boolean
};
	["ProductsDocAccessFields_sessions_inventories_unitPrice_Delete"]: {
		permission: boolean
};
	["ProductsDocAccessFields_sessions_inventories_handlingFee"]: {
		create?: ModelTypes["ProductsDocAccessFields_sessions_inventories_handlingFee_Create"] | undefined,
	read?: ModelTypes["ProductsDocAccessFields_sessions_inventories_handlingFee_Read"] | undefined,
	update?: ModelTypes["ProductsDocAccessFields_sessions_inventories_handlingFee_Update"] | undefined,
	delete?: ModelTypes["ProductsDocAccessFields_sessions_inventories_handlingFee_Delete"] | undefined
};
	["ProductsDocAccessFields_sessions_inventories_handlingFee_Create"]: {
		permission: boolean
};
	["ProductsDocAccessFields_sessions_inventories_handlingFee_Read"]: {
		permission: boolean
};
	["ProductsDocAccessFields_sessions_inventories_handlingFee_Update"]: {
		permission: boolean
};
	["ProductsDocAccessFields_sessions_inventories_handlingFee_Delete"]: {
		permission: boolean
};
	["ProductsDocAccessFields_sessions_inventories_totalCost"]: {
		create?: ModelTypes["ProductsDocAccessFields_sessions_inventories_totalCost_Create"] | undefined,
	read?: ModelTypes["ProductsDocAccessFields_sessions_inventories_totalCost_Read"] | undefined,
	update?: ModelTypes["ProductsDocAccessFields_sessions_inventories_totalCost_Update"] | undefined,
	delete?: ModelTypes["ProductsDocAccessFields_sessions_inventories_totalCost_Delete"] | undefined
};
	["ProductsDocAccessFields_sessions_inventories_totalCost_Create"]: {
		permission: boolean
};
	["ProductsDocAccessFields_sessions_inventories_totalCost_Read"]: {
		permission: boolean
};
	["ProductsDocAccessFields_sessions_inventories_totalCost_Update"]: {
		permission: boolean
};
	["ProductsDocAccessFields_sessions_inventories_totalCost_Delete"]: {
		permission: boolean
};
	["ProductsDocAccessFields_sessions_inventories_minPurchase"]: {
		create?: ModelTypes["ProductsDocAccessFields_sessions_inventories_minPurchase_Create"] | undefined,
	read?: ModelTypes["ProductsDocAccessFields_sessions_inventories_minPurchase_Read"] | undefined,
	update?: ModelTypes["ProductsDocAccessFields_sessions_inventories_minPurchase_Update"] | undefined,
	delete?: ModelTypes["ProductsDocAccessFields_sessions_inventories_minPurchase_Delete"] | undefined
};
	["ProductsDocAccessFields_sessions_inventories_minPurchase_Create"]: {
		permission: boolean
};
	["ProductsDocAccessFields_sessions_inventories_minPurchase_Read"]: {
		permission: boolean
};
	["ProductsDocAccessFields_sessions_inventories_minPurchase_Update"]: {
		permission: boolean
};
	["ProductsDocAccessFields_sessions_inventories_minPurchase_Delete"]: {
		permission: boolean
};
	["ProductsDocAccessFields_sessions_inventories_maxPurchase"]: {
		create?: ModelTypes["ProductsDocAccessFields_sessions_inventories_maxPurchase_Create"] | undefined,
	read?: ModelTypes["ProductsDocAccessFields_sessions_inventories_maxPurchase_Read"] | undefined,
	update?: ModelTypes["ProductsDocAccessFields_sessions_inventories_maxPurchase_Update"] | undefined,
	delete?: ModelTypes["ProductsDocAccessFields_sessions_inventories_maxPurchase_Delete"] | undefined
};
	["ProductsDocAccessFields_sessions_inventories_maxPurchase_Create"]: {
		permission: boolean
};
	["ProductsDocAccessFields_sessions_inventories_maxPurchase_Read"]: {
		permission: boolean
};
	["ProductsDocAccessFields_sessions_inventories_maxPurchase_Update"]: {
		permission: boolean
};
	["ProductsDocAccessFields_sessions_inventories_maxPurchase_Delete"]: {
		permission: boolean
};
	["ProductsDocAccessFields_sessions_inventories_stocks"]: {
		create?: ModelTypes["ProductsDocAccessFields_sessions_inventories_stocks_Create"] | undefined,
	read?: ModelTypes["ProductsDocAccessFields_sessions_inventories_stocks_Read"] | undefined,
	update?: ModelTypes["ProductsDocAccessFields_sessions_inventories_stocks_Update"] | undefined,
	delete?: ModelTypes["ProductsDocAccessFields_sessions_inventories_stocks_Delete"] | undefined,
	fields?: ModelTypes["ProductsDocAccessFields_sessions_inventories_stocks_Fields"] | undefined
};
	["ProductsDocAccessFields_sessions_inventories_stocks_Create"]: {
		permission: boolean
};
	["ProductsDocAccessFields_sessions_inventories_stocks_Read"]: {
		permission: boolean
};
	["ProductsDocAccessFields_sessions_inventories_stocks_Update"]: {
		permission: boolean
};
	["ProductsDocAccessFields_sessions_inventories_stocks_Delete"]: {
		permission: boolean
};
	["ProductsDocAccessFields_sessions_inventories_stocks_Fields"]: {
		stockId?: ModelTypes["ProductsDocAccessFields_sessions_inventories_stocks_stockId"] | undefined,
	remaining?: ModelTypes["ProductsDocAccessFields_sessions_inventories_stocks_remaining"] | undefined,
	sold?: ModelTypes["ProductsDocAccessFields_sessions_inventories_stocks_sold"] | undefined,
	onHold?: ModelTypes["ProductsDocAccessFields_sessions_inventories_stocks_onHold"] | undefined,
	checkedIn?: ModelTypes["ProductsDocAccessFields_sessions_inventories_stocks_checkedIn"] | undefined
};
	["ProductsDocAccessFields_sessions_inventories_stocks_stockId"]: {
		create?: ModelTypes["ProductsDocAccessFields_sessions_inventories_stocks_stockId_Create"] | undefined,
	read?: ModelTypes["ProductsDocAccessFields_sessions_inventories_stocks_stockId_Read"] | undefined,
	update?: ModelTypes["ProductsDocAccessFields_sessions_inventories_stocks_stockId_Update"] | undefined,
	delete?: ModelTypes["ProductsDocAccessFields_sessions_inventories_stocks_stockId_Delete"] | undefined
};
	["ProductsDocAccessFields_sessions_inventories_stocks_stockId_Create"]: {
		permission: boolean
};
	["ProductsDocAccessFields_sessions_inventories_stocks_stockId_Read"]: {
		permission: boolean
};
	["ProductsDocAccessFields_sessions_inventories_stocks_stockId_Update"]: {
		permission: boolean
};
	["ProductsDocAccessFields_sessions_inventories_stocks_stockId_Delete"]: {
		permission: boolean
};
	["ProductsDocAccessFields_sessions_inventories_stocks_remaining"]: {
		create?: ModelTypes["ProductsDocAccessFields_sessions_inventories_stocks_remaining_Create"] | undefined,
	read?: ModelTypes["ProductsDocAccessFields_sessions_inventories_stocks_remaining_Read"] | undefined,
	update?: ModelTypes["ProductsDocAccessFields_sessions_inventories_stocks_remaining_Update"] | undefined,
	delete?: ModelTypes["ProductsDocAccessFields_sessions_inventories_stocks_remaining_Delete"] | undefined
};
	["ProductsDocAccessFields_sessions_inventories_stocks_remaining_Create"]: {
		permission: boolean
};
	["ProductsDocAccessFields_sessions_inventories_stocks_remaining_Read"]: {
		permission: boolean
};
	["ProductsDocAccessFields_sessions_inventories_stocks_remaining_Update"]: {
		permission: boolean
};
	["ProductsDocAccessFields_sessions_inventories_stocks_remaining_Delete"]: {
		permission: boolean
};
	["ProductsDocAccessFields_sessions_inventories_stocks_sold"]: {
		create?: ModelTypes["ProductsDocAccessFields_sessions_inventories_stocks_sold_Create"] | undefined,
	read?: ModelTypes["ProductsDocAccessFields_sessions_inventories_stocks_sold_Read"] | undefined,
	update?: ModelTypes["ProductsDocAccessFields_sessions_inventories_stocks_sold_Update"] | undefined,
	delete?: ModelTypes["ProductsDocAccessFields_sessions_inventories_stocks_sold_Delete"] | undefined
};
	["ProductsDocAccessFields_sessions_inventories_stocks_sold_Create"]: {
		permission: boolean
};
	["ProductsDocAccessFields_sessions_inventories_stocks_sold_Read"]: {
		permission: boolean
};
	["ProductsDocAccessFields_sessions_inventories_stocks_sold_Update"]: {
		permission: boolean
};
	["ProductsDocAccessFields_sessions_inventories_stocks_sold_Delete"]: {
		permission: boolean
};
	["ProductsDocAccessFields_sessions_inventories_stocks_onHold"]: {
		create?: ModelTypes["ProductsDocAccessFields_sessions_inventories_stocks_onHold_Create"] | undefined,
	read?: ModelTypes["ProductsDocAccessFields_sessions_inventories_stocks_onHold_Read"] | undefined,
	update?: ModelTypes["ProductsDocAccessFields_sessions_inventories_stocks_onHold_Update"] | undefined,
	delete?: ModelTypes["ProductsDocAccessFields_sessions_inventories_stocks_onHold_Delete"] | undefined
};
	["ProductsDocAccessFields_sessions_inventories_stocks_onHold_Create"]: {
		permission: boolean
};
	["ProductsDocAccessFields_sessions_inventories_stocks_onHold_Read"]: {
		permission: boolean
};
	["ProductsDocAccessFields_sessions_inventories_stocks_onHold_Update"]: {
		permission: boolean
};
	["ProductsDocAccessFields_sessions_inventories_stocks_onHold_Delete"]: {
		permission: boolean
};
	["ProductsDocAccessFields_sessions_inventories_stocks_checkedIn"]: {
		create?: ModelTypes["ProductsDocAccessFields_sessions_inventories_stocks_checkedIn_Create"] | undefined,
	read?: ModelTypes["ProductsDocAccessFields_sessions_inventories_stocks_checkedIn_Read"] | undefined,
	update?: ModelTypes["ProductsDocAccessFields_sessions_inventories_stocks_checkedIn_Update"] | undefined,
	delete?: ModelTypes["ProductsDocAccessFields_sessions_inventories_stocks_checkedIn_Delete"] | undefined
};
	["ProductsDocAccessFields_sessions_inventories_stocks_checkedIn_Create"]: {
		permission: boolean
};
	["ProductsDocAccessFields_sessions_inventories_stocks_checkedIn_Read"]: {
		permission: boolean
};
	["ProductsDocAccessFields_sessions_inventories_stocks_checkedIn_Update"]: {
		permission: boolean
};
	["ProductsDocAccessFields_sessions_inventories_stocks_checkedIn_Delete"]: {
		permission: boolean
};
	["ProductsDocAccessFields_sessions_id"]: {
		create?: ModelTypes["ProductsDocAccessFields_sessions_id_Create"] | undefined,
	read?: ModelTypes["ProductsDocAccessFields_sessions_id_Read"] | undefined,
	update?: ModelTypes["ProductsDocAccessFields_sessions_id_Update"] | undefined,
	delete?: ModelTypes["ProductsDocAccessFields_sessions_id_Delete"] | undefined
};
	["ProductsDocAccessFields_sessions_id_Create"]: {
		permission: boolean
};
	["ProductsDocAccessFields_sessions_id_Read"]: {
		permission: boolean
};
	["ProductsDocAccessFields_sessions_id_Update"]: {
		permission: boolean
};
	["ProductsDocAccessFields_sessions_id_Delete"]: {
		permission: boolean
};
	["ProductsDocAccessFields_updatedAt"]: {
		create?: ModelTypes["ProductsDocAccessFields_updatedAt_Create"] | undefined,
	read?: ModelTypes["ProductsDocAccessFields_updatedAt_Read"] | undefined,
	update?: ModelTypes["ProductsDocAccessFields_updatedAt_Update"] | undefined,
	delete?: ModelTypes["ProductsDocAccessFields_updatedAt_Delete"] | undefined
};
	["ProductsDocAccessFields_updatedAt_Create"]: {
		permission: boolean
};
	["ProductsDocAccessFields_updatedAt_Read"]: {
		permission: boolean
};
	["ProductsDocAccessFields_updatedAt_Update"]: {
		permission: boolean
};
	["ProductsDocAccessFields_updatedAt_Delete"]: {
		permission: boolean
};
	["ProductsDocAccessFields_createdAt"]: {
		create?: ModelTypes["ProductsDocAccessFields_createdAt_Create"] | undefined,
	read?: ModelTypes["ProductsDocAccessFields_createdAt_Read"] | undefined,
	update?: ModelTypes["ProductsDocAccessFields_createdAt_Update"] | undefined,
	delete?: ModelTypes["ProductsDocAccessFields_createdAt_Delete"] | undefined
};
	["ProductsDocAccessFields_createdAt_Create"]: {
		permission: boolean
};
	["ProductsDocAccessFields_createdAt_Read"]: {
		permission: boolean
};
	["ProductsDocAccessFields_createdAt_Update"]: {
		permission: boolean
};
	["ProductsDocAccessFields_createdAt_Delete"]: {
		permission: boolean
};
	["ProductsCreateDocAccess"]: {
		permission: boolean,
	where?: ModelTypes["JSONObject"] | undefined
};
	["ProductsReadDocAccess"]: {
		permission: boolean,
	where?: ModelTypes["JSONObject"] | undefined
};
	["ProductsUpdateDocAccess"]: {
		permission: boolean,
	where?: ModelTypes["JSONObject"] | undefined
};
	["ProductsDeleteDocAccess"]: {
		permission: boolean,
	where?: ModelTypes["JSONObject"] | undefined
};
	["InventoryStatus"]: {
		id?: string | undefined,
	variantId?: string | undefined,
	variantName?: string | undefined,
	productType?: ModelTypes["InventoryStatus_productType"] | undefined,
	stockUpdateStatus: ModelTypes["InventoryStatus_stockUpdateStatus"],
	stockUpdateAmount: number,
	user?: ModelTypes["User"] | undefined,
	publicUserPhone?: string | undefined,
	expiredAt?: ModelTypes["DateTime"] | undefined,
	updatedAt?: ModelTypes["DateTime"] | undefined,
	createdAt?: ModelTypes["DateTime"] | undefined
};
	["InventoryStatus_productType"]:InventoryStatus_productType;
	["InventoryStatus_stockUpdateStatus"]:InventoryStatus_stockUpdateStatus;
	["InventoryStatuses"]: {
		docs?: Array<ModelTypes["InventoryStatus"] | undefined> | undefined,
	hasNextPage?: boolean | undefined,
	hasPrevPage?: boolean | undefined,
	limit?: number | undefined,
	nextPage?: number | undefined,
	offset?: number | undefined,
	page?: number | undefined,
	pagingCounter?: number | undefined,
	prevPage?: number | undefined,
	totalDocs?: number | undefined,
	totalPages?: number | undefined
};
	["InventoryStatus_where"]: {
	variantId?: ModelTypes["InventoryStatus_variantId_operator"] | undefined,
	variantName?: ModelTypes["InventoryStatus_variantName_operator"] | undefined,
	productType?: ModelTypes["InventoryStatus_productType_operator"] | undefined,
	stockUpdateStatus?: ModelTypes["InventoryStatus_stockUpdateStatus_operator"] | undefined,
	stockUpdateAmount?: ModelTypes["InventoryStatus_stockUpdateAmount_operator"] | undefined,
	user?: ModelTypes["InventoryStatus_user_operator"] | undefined,
	publicUserPhone?: ModelTypes["InventoryStatus_publicUserPhone_operator"] | undefined,
	expiredAt?: ModelTypes["InventoryStatus_expiredAt_operator"] | undefined,
	updatedAt?: ModelTypes["InventoryStatus_updatedAt_operator"] | undefined,
	createdAt?: ModelTypes["InventoryStatus_createdAt_operator"] | undefined,
	id?: ModelTypes["InventoryStatus_id_operator"] | undefined,
	AND?: Array<ModelTypes["InventoryStatus_where_and"] | undefined> | undefined,
	OR?: Array<ModelTypes["InventoryStatus_where_or"] | undefined> | undefined
};
	["InventoryStatus_variantId_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["InventoryStatus_variantName_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["InventoryStatus_productType_operator"]: {
	equals?: ModelTypes["InventoryStatus_productType_Input"] | undefined,
	not_equals?: ModelTypes["InventoryStatus_productType_Input"] | undefined,
	like?: ModelTypes["InventoryStatus_productType_Input"] | undefined,
	contains?: ModelTypes["InventoryStatus_productType_Input"] | undefined,
	exists?: boolean | undefined
};
	["InventoryStatus_productType_Input"]:InventoryStatus_productType_Input;
	["InventoryStatus_stockUpdateStatus_operator"]: {
	equals?: ModelTypes["InventoryStatus_stockUpdateStatus_Input"] | undefined,
	not_equals?: ModelTypes["InventoryStatus_stockUpdateStatus_Input"] | undefined,
	in?: Array<ModelTypes["InventoryStatus_stockUpdateStatus_Input"] | undefined> | undefined,
	not_in?: Array<ModelTypes["InventoryStatus_stockUpdateStatus_Input"] | undefined> | undefined,
	all?: Array<ModelTypes["InventoryStatus_stockUpdateStatus_Input"] | undefined> | undefined
};
	["InventoryStatus_stockUpdateStatus_Input"]:InventoryStatus_stockUpdateStatus_Input;
	["InventoryStatus_stockUpdateAmount_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined
};
	["InventoryStatus_user_operator"]: {
	equals?: ModelTypes["JSON"] | undefined,
	not_equals?: ModelTypes["JSON"] | undefined,
	in?: Array<ModelTypes["JSON"] | undefined> | undefined,
	not_in?: Array<ModelTypes["JSON"] | undefined> | undefined,
	all?: Array<ModelTypes["JSON"] | undefined> | undefined,
	exists?: boolean | undefined
};
	["InventoryStatus_publicUserPhone_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["InventoryStatus_expiredAt_operator"]: {
	equals?: ModelTypes["DateTime"] | undefined,
	not_equals?: ModelTypes["DateTime"] | undefined,
	greater_than_equal?: ModelTypes["DateTime"] | undefined,
	greater_than?: ModelTypes["DateTime"] | undefined,
	less_than_equal?: ModelTypes["DateTime"] | undefined,
	less_than?: ModelTypes["DateTime"] | undefined,
	like?: ModelTypes["DateTime"] | undefined,
	exists?: boolean | undefined
};
	["InventoryStatus_updatedAt_operator"]: {
	equals?: ModelTypes["DateTime"] | undefined,
	not_equals?: ModelTypes["DateTime"] | undefined,
	greater_than_equal?: ModelTypes["DateTime"] | undefined,
	greater_than?: ModelTypes["DateTime"] | undefined,
	less_than_equal?: ModelTypes["DateTime"] | undefined,
	less_than?: ModelTypes["DateTime"] | undefined,
	like?: ModelTypes["DateTime"] | undefined,
	exists?: boolean | undefined
};
	["InventoryStatus_createdAt_operator"]: {
	equals?: ModelTypes["DateTime"] | undefined,
	not_equals?: ModelTypes["DateTime"] | undefined,
	greater_than_equal?: ModelTypes["DateTime"] | undefined,
	greater_than?: ModelTypes["DateTime"] | undefined,
	less_than_equal?: ModelTypes["DateTime"] | undefined,
	less_than?: ModelTypes["DateTime"] | undefined,
	like?: ModelTypes["DateTime"] | undefined,
	exists?: boolean | undefined
};
	["InventoryStatus_id_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["InventoryStatus_where_and"]: {
	variantId?: ModelTypes["InventoryStatus_variantId_operator"] | undefined,
	variantName?: ModelTypes["InventoryStatus_variantName_operator"] | undefined,
	productType?: ModelTypes["InventoryStatus_productType_operator"] | undefined,
	stockUpdateStatus?: ModelTypes["InventoryStatus_stockUpdateStatus_operator"] | undefined,
	stockUpdateAmount?: ModelTypes["InventoryStatus_stockUpdateAmount_operator"] | undefined,
	user?: ModelTypes["InventoryStatus_user_operator"] | undefined,
	publicUserPhone?: ModelTypes["InventoryStatus_publicUserPhone_operator"] | undefined,
	expiredAt?: ModelTypes["InventoryStatus_expiredAt_operator"] | undefined,
	updatedAt?: ModelTypes["InventoryStatus_updatedAt_operator"] | undefined,
	createdAt?: ModelTypes["InventoryStatus_createdAt_operator"] | undefined,
	id?: ModelTypes["InventoryStatus_id_operator"] | undefined,
	AND?: Array<ModelTypes["InventoryStatus_where_and"] | undefined> | undefined,
	OR?: Array<ModelTypes["InventoryStatus_where_or"] | undefined> | undefined
};
	["InventoryStatus_where_or"]: {
	variantId?: ModelTypes["InventoryStatus_variantId_operator"] | undefined,
	variantName?: ModelTypes["InventoryStatus_variantName_operator"] | undefined,
	productType?: ModelTypes["InventoryStatus_productType_operator"] | undefined,
	stockUpdateStatus?: ModelTypes["InventoryStatus_stockUpdateStatus_operator"] | undefined,
	stockUpdateAmount?: ModelTypes["InventoryStatus_stockUpdateAmount_operator"] | undefined,
	user?: ModelTypes["InventoryStatus_user_operator"] | undefined,
	publicUserPhone?: ModelTypes["InventoryStatus_publicUserPhone_operator"] | undefined,
	expiredAt?: ModelTypes["InventoryStatus_expiredAt_operator"] | undefined,
	updatedAt?: ModelTypes["InventoryStatus_updatedAt_operator"] | undefined,
	createdAt?: ModelTypes["InventoryStatus_createdAt_operator"] | undefined,
	id?: ModelTypes["InventoryStatus_id_operator"] | undefined,
	AND?: Array<ModelTypes["InventoryStatus_where_and"] | undefined> | undefined,
	OR?: Array<ModelTypes["InventoryStatus_where_or"] | undefined> | undefined
};
	["countInventoryStatuses"]: {
		totalDocs?: number | undefined
};
	["inventory_statusDocAccess"]: {
		fields?: ModelTypes["InventoryStatusDocAccessFields"] | undefined,
	create?: ModelTypes["InventoryStatusCreateDocAccess"] | undefined,
	read?: ModelTypes["InventoryStatusReadDocAccess"] | undefined,
	update?: ModelTypes["InventoryStatusUpdateDocAccess"] | undefined,
	delete?: ModelTypes["InventoryStatusDeleteDocAccess"] | undefined
};
	["InventoryStatusDocAccessFields"]: {
		variantId?: ModelTypes["InventoryStatusDocAccessFields_variantId"] | undefined,
	variantName?: ModelTypes["InventoryStatusDocAccessFields_variantName"] | undefined,
	productType?: ModelTypes["InventoryStatusDocAccessFields_productType"] | undefined,
	stockUpdateStatus?: ModelTypes["InventoryStatusDocAccessFields_stockUpdateStatus"] | undefined,
	stockUpdateAmount?: ModelTypes["InventoryStatusDocAccessFields_stockUpdateAmount"] | undefined,
	user?: ModelTypes["InventoryStatusDocAccessFields_user"] | undefined,
	publicUserPhone?: ModelTypes["InventoryStatusDocAccessFields_publicUserPhone"] | undefined,
	expiredAt?: ModelTypes["InventoryStatusDocAccessFields_expiredAt"] | undefined,
	updatedAt?: ModelTypes["InventoryStatusDocAccessFields_updatedAt"] | undefined,
	createdAt?: ModelTypes["InventoryStatusDocAccessFields_createdAt"] | undefined
};
	["InventoryStatusDocAccessFields_variantId"]: {
		create?: ModelTypes["InventoryStatusDocAccessFields_variantId_Create"] | undefined,
	read?: ModelTypes["InventoryStatusDocAccessFields_variantId_Read"] | undefined,
	update?: ModelTypes["InventoryStatusDocAccessFields_variantId_Update"] | undefined,
	delete?: ModelTypes["InventoryStatusDocAccessFields_variantId_Delete"] | undefined
};
	["InventoryStatusDocAccessFields_variantId_Create"]: {
		permission: boolean
};
	["InventoryStatusDocAccessFields_variantId_Read"]: {
		permission: boolean
};
	["InventoryStatusDocAccessFields_variantId_Update"]: {
		permission: boolean
};
	["InventoryStatusDocAccessFields_variantId_Delete"]: {
		permission: boolean
};
	["InventoryStatusDocAccessFields_variantName"]: {
		create?: ModelTypes["InventoryStatusDocAccessFields_variantName_Create"] | undefined,
	read?: ModelTypes["InventoryStatusDocAccessFields_variantName_Read"] | undefined,
	update?: ModelTypes["InventoryStatusDocAccessFields_variantName_Update"] | undefined,
	delete?: ModelTypes["InventoryStatusDocAccessFields_variantName_Delete"] | undefined
};
	["InventoryStatusDocAccessFields_variantName_Create"]: {
		permission: boolean
};
	["InventoryStatusDocAccessFields_variantName_Read"]: {
		permission: boolean
};
	["InventoryStatusDocAccessFields_variantName_Update"]: {
		permission: boolean
};
	["InventoryStatusDocAccessFields_variantName_Delete"]: {
		permission: boolean
};
	["InventoryStatusDocAccessFields_productType"]: {
		create?: ModelTypes["InventoryStatusDocAccessFields_productType_Create"] | undefined,
	read?: ModelTypes["InventoryStatusDocAccessFields_productType_Read"] | undefined,
	update?: ModelTypes["InventoryStatusDocAccessFields_productType_Update"] | undefined,
	delete?: ModelTypes["InventoryStatusDocAccessFields_productType_Delete"] | undefined
};
	["InventoryStatusDocAccessFields_productType_Create"]: {
		permission: boolean
};
	["InventoryStatusDocAccessFields_productType_Read"]: {
		permission: boolean
};
	["InventoryStatusDocAccessFields_productType_Update"]: {
		permission: boolean
};
	["InventoryStatusDocAccessFields_productType_Delete"]: {
		permission: boolean
};
	["InventoryStatusDocAccessFields_stockUpdateStatus"]: {
		create?: ModelTypes["InventoryStatusDocAccessFields_stockUpdateStatus_Create"] | undefined,
	read?: ModelTypes["InventoryStatusDocAccessFields_stockUpdateStatus_Read"] | undefined,
	update?: ModelTypes["InventoryStatusDocAccessFields_stockUpdateStatus_Update"] | undefined,
	delete?: ModelTypes["InventoryStatusDocAccessFields_stockUpdateStatus_Delete"] | undefined
};
	["InventoryStatusDocAccessFields_stockUpdateStatus_Create"]: {
		permission: boolean
};
	["InventoryStatusDocAccessFields_stockUpdateStatus_Read"]: {
		permission: boolean
};
	["InventoryStatusDocAccessFields_stockUpdateStatus_Update"]: {
		permission: boolean
};
	["InventoryStatusDocAccessFields_stockUpdateStatus_Delete"]: {
		permission: boolean
};
	["InventoryStatusDocAccessFields_stockUpdateAmount"]: {
		create?: ModelTypes["InventoryStatusDocAccessFields_stockUpdateAmount_Create"] | undefined,
	read?: ModelTypes["InventoryStatusDocAccessFields_stockUpdateAmount_Read"] | undefined,
	update?: ModelTypes["InventoryStatusDocAccessFields_stockUpdateAmount_Update"] | undefined,
	delete?: ModelTypes["InventoryStatusDocAccessFields_stockUpdateAmount_Delete"] | undefined
};
	["InventoryStatusDocAccessFields_stockUpdateAmount_Create"]: {
		permission: boolean
};
	["InventoryStatusDocAccessFields_stockUpdateAmount_Read"]: {
		permission: boolean
};
	["InventoryStatusDocAccessFields_stockUpdateAmount_Update"]: {
		permission: boolean
};
	["InventoryStatusDocAccessFields_stockUpdateAmount_Delete"]: {
		permission: boolean
};
	["InventoryStatusDocAccessFields_user"]: {
		create?: ModelTypes["InventoryStatusDocAccessFields_user_Create"] | undefined,
	read?: ModelTypes["InventoryStatusDocAccessFields_user_Read"] | undefined,
	update?: ModelTypes["InventoryStatusDocAccessFields_user_Update"] | undefined,
	delete?: ModelTypes["InventoryStatusDocAccessFields_user_Delete"] | undefined
};
	["InventoryStatusDocAccessFields_user_Create"]: {
		permission: boolean
};
	["InventoryStatusDocAccessFields_user_Read"]: {
		permission: boolean
};
	["InventoryStatusDocAccessFields_user_Update"]: {
		permission: boolean
};
	["InventoryStatusDocAccessFields_user_Delete"]: {
		permission: boolean
};
	["InventoryStatusDocAccessFields_publicUserPhone"]: {
		create?: ModelTypes["InventoryStatusDocAccessFields_publicUserPhone_Create"] | undefined,
	read?: ModelTypes["InventoryStatusDocAccessFields_publicUserPhone_Read"] | undefined,
	update?: ModelTypes["InventoryStatusDocAccessFields_publicUserPhone_Update"] | undefined,
	delete?: ModelTypes["InventoryStatusDocAccessFields_publicUserPhone_Delete"] | undefined
};
	["InventoryStatusDocAccessFields_publicUserPhone_Create"]: {
		permission: boolean
};
	["InventoryStatusDocAccessFields_publicUserPhone_Read"]: {
		permission: boolean
};
	["InventoryStatusDocAccessFields_publicUserPhone_Update"]: {
		permission: boolean
};
	["InventoryStatusDocAccessFields_publicUserPhone_Delete"]: {
		permission: boolean
};
	["InventoryStatusDocAccessFields_expiredAt"]: {
		create?: ModelTypes["InventoryStatusDocAccessFields_expiredAt_Create"] | undefined,
	read?: ModelTypes["InventoryStatusDocAccessFields_expiredAt_Read"] | undefined,
	update?: ModelTypes["InventoryStatusDocAccessFields_expiredAt_Update"] | undefined,
	delete?: ModelTypes["InventoryStatusDocAccessFields_expiredAt_Delete"] | undefined
};
	["InventoryStatusDocAccessFields_expiredAt_Create"]: {
		permission: boolean
};
	["InventoryStatusDocAccessFields_expiredAt_Read"]: {
		permission: boolean
};
	["InventoryStatusDocAccessFields_expiredAt_Update"]: {
		permission: boolean
};
	["InventoryStatusDocAccessFields_expiredAt_Delete"]: {
		permission: boolean
};
	["InventoryStatusDocAccessFields_updatedAt"]: {
		create?: ModelTypes["InventoryStatusDocAccessFields_updatedAt_Create"] | undefined,
	read?: ModelTypes["InventoryStatusDocAccessFields_updatedAt_Read"] | undefined,
	update?: ModelTypes["InventoryStatusDocAccessFields_updatedAt_Update"] | undefined,
	delete?: ModelTypes["InventoryStatusDocAccessFields_updatedAt_Delete"] | undefined
};
	["InventoryStatusDocAccessFields_updatedAt_Create"]: {
		permission: boolean
};
	["InventoryStatusDocAccessFields_updatedAt_Read"]: {
		permission: boolean
};
	["InventoryStatusDocAccessFields_updatedAt_Update"]: {
		permission: boolean
};
	["InventoryStatusDocAccessFields_updatedAt_Delete"]: {
		permission: boolean
};
	["InventoryStatusDocAccessFields_createdAt"]: {
		create?: ModelTypes["InventoryStatusDocAccessFields_createdAt_Create"] | undefined,
	read?: ModelTypes["InventoryStatusDocAccessFields_createdAt_Read"] | undefined,
	update?: ModelTypes["InventoryStatusDocAccessFields_createdAt_Update"] | undefined,
	delete?: ModelTypes["InventoryStatusDocAccessFields_createdAt_Delete"] | undefined
};
	["InventoryStatusDocAccessFields_createdAt_Create"]: {
		permission: boolean
};
	["InventoryStatusDocAccessFields_createdAt_Read"]: {
		permission: boolean
};
	["InventoryStatusDocAccessFields_createdAt_Update"]: {
		permission: boolean
};
	["InventoryStatusDocAccessFields_createdAt_Delete"]: {
		permission: boolean
};
	["InventoryStatusCreateDocAccess"]: {
		permission: boolean,
	where?: ModelTypes["JSONObject"] | undefined
};
	["InventoryStatusReadDocAccess"]: {
		permission: boolean,
	where?: ModelTypes["JSONObject"] | undefined
};
	["InventoryStatusUpdateDocAccess"]: {
		permission: boolean,
	where?: ModelTypes["JSONObject"] | undefined
};
	["InventoryStatusDeleteDocAccess"]: {
		permission: boolean,
	where?: ModelTypes["JSONObject"] | undefined
};
	["Transaction"]: {
		id?: string | undefined,
	creator?: ModelTypes["User"] | undefined,
	productName?: string | undefined,
	referenceId?: string | undefined,
	linkedEvent?: ModelTypes["Event"] | undefined,
	purchaseDateTime?: ModelTypes["DateTime"] | undefined,
	timableOrderId?: string | undefined,
	stripePaymentId?: string | undefined,
	totalAmount?: number | undefined,
	contactPersonName?: string | undefined,
	contactPersonPhone?: string | undefined,
	remarks?: string | undefined,
	redemptionCodes?: Array<ModelTypes["Transaction_RedemptionCodes"]> | undefined,
	updatedAt?: ModelTypes["DateTime"] | undefined,
	createdAt?: ModelTypes["DateTime"] | undefined
};
	["Transaction_RedemptionCodes"]: {
		code?: string | undefined,
	id?: string | undefined
};
	["Transactions"]: {
		docs?: Array<ModelTypes["Transaction"] | undefined> | undefined,
	hasNextPage?: boolean | undefined,
	hasPrevPage?: boolean | undefined,
	limit?: number | undefined,
	nextPage?: number | undefined,
	offset?: number | undefined,
	page?: number | undefined,
	pagingCounter?: number | undefined,
	prevPage?: number | undefined,
	totalDocs?: number | undefined,
	totalPages?: number | undefined
};
	["Transaction_where"]: {
	creator?: ModelTypes["Transaction_creator_operator"] | undefined,
	productName?: ModelTypes["Transaction_productName_operator"] | undefined,
	referenceId?: ModelTypes["Transaction_referenceId_operator"] | undefined,
	linkedEvent?: ModelTypes["Transaction_linkedEvent_operator"] | undefined,
	purchaseDateTime?: ModelTypes["Transaction_purchaseDateTime_operator"] | undefined,
	timableOrderId?: ModelTypes["Transaction_timableOrderId_operator"] | undefined,
	stripePaymentId?: ModelTypes["Transaction_stripePaymentId_operator"] | undefined,
	totalAmount?: ModelTypes["Transaction_totalAmount_operator"] | undefined,
	contactPersonName?: ModelTypes["Transaction_contactPersonName_operator"] | undefined,
	contactPersonPhone?: ModelTypes["Transaction_contactPersonPhone_operator"] | undefined,
	remarks?: ModelTypes["Transaction_remarks_operator"] | undefined,
	redemptionCodes__code?: ModelTypes["Transaction_redemptionCodes__code_operator"] | undefined,
	redemptionCodes__id?: ModelTypes["Transaction_redemptionCodes__id_operator"] | undefined,
	updatedAt?: ModelTypes["Transaction_updatedAt_operator"] | undefined,
	createdAt?: ModelTypes["Transaction_createdAt_operator"] | undefined,
	id?: ModelTypes["Transaction_id_operator"] | undefined,
	AND?: Array<ModelTypes["Transaction_where_and"] | undefined> | undefined,
	OR?: Array<ModelTypes["Transaction_where_or"] | undefined> | undefined
};
	["Transaction_creator_operator"]: {
	equals?: ModelTypes["JSON"] | undefined,
	not_equals?: ModelTypes["JSON"] | undefined,
	in?: Array<ModelTypes["JSON"] | undefined> | undefined,
	not_in?: Array<ModelTypes["JSON"] | undefined> | undefined,
	all?: Array<ModelTypes["JSON"] | undefined> | undefined,
	exists?: boolean | undefined
};
	["Transaction_productName_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Transaction_referenceId_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Transaction_linkedEvent_operator"]: {
	equals?: ModelTypes["JSON"] | undefined,
	not_equals?: ModelTypes["JSON"] | undefined,
	in?: Array<ModelTypes["JSON"] | undefined> | undefined,
	not_in?: Array<ModelTypes["JSON"] | undefined> | undefined,
	all?: Array<ModelTypes["JSON"] | undefined> | undefined,
	exists?: boolean | undefined
};
	["Transaction_purchaseDateTime_operator"]: {
	equals?: ModelTypes["DateTime"] | undefined,
	not_equals?: ModelTypes["DateTime"] | undefined,
	greater_than_equal?: ModelTypes["DateTime"] | undefined,
	greater_than?: ModelTypes["DateTime"] | undefined,
	less_than_equal?: ModelTypes["DateTime"] | undefined,
	less_than?: ModelTypes["DateTime"] | undefined,
	like?: ModelTypes["DateTime"] | undefined,
	exists?: boolean | undefined
};
	["Transaction_timableOrderId_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Transaction_stripePaymentId_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Transaction_totalAmount_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Transaction_contactPersonName_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Transaction_contactPersonPhone_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Transaction_remarks_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	exists?: boolean | undefined
};
	["Transaction_redemptionCodes__code_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Transaction_redemptionCodes__id_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Transaction_updatedAt_operator"]: {
	equals?: ModelTypes["DateTime"] | undefined,
	not_equals?: ModelTypes["DateTime"] | undefined,
	greater_than_equal?: ModelTypes["DateTime"] | undefined,
	greater_than?: ModelTypes["DateTime"] | undefined,
	less_than_equal?: ModelTypes["DateTime"] | undefined,
	less_than?: ModelTypes["DateTime"] | undefined,
	like?: ModelTypes["DateTime"] | undefined,
	exists?: boolean | undefined
};
	["Transaction_createdAt_operator"]: {
	equals?: ModelTypes["DateTime"] | undefined,
	not_equals?: ModelTypes["DateTime"] | undefined,
	greater_than_equal?: ModelTypes["DateTime"] | undefined,
	greater_than?: ModelTypes["DateTime"] | undefined,
	less_than_equal?: ModelTypes["DateTime"] | undefined,
	less_than?: ModelTypes["DateTime"] | undefined,
	like?: ModelTypes["DateTime"] | undefined,
	exists?: boolean | undefined
};
	["Transaction_id_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Transaction_where_and"]: {
	creator?: ModelTypes["Transaction_creator_operator"] | undefined,
	productName?: ModelTypes["Transaction_productName_operator"] | undefined,
	referenceId?: ModelTypes["Transaction_referenceId_operator"] | undefined,
	linkedEvent?: ModelTypes["Transaction_linkedEvent_operator"] | undefined,
	purchaseDateTime?: ModelTypes["Transaction_purchaseDateTime_operator"] | undefined,
	timableOrderId?: ModelTypes["Transaction_timableOrderId_operator"] | undefined,
	stripePaymentId?: ModelTypes["Transaction_stripePaymentId_operator"] | undefined,
	totalAmount?: ModelTypes["Transaction_totalAmount_operator"] | undefined,
	contactPersonName?: ModelTypes["Transaction_contactPersonName_operator"] | undefined,
	contactPersonPhone?: ModelTypes["Transaction_contactPersonPhone_operator"] | undefined,
	remarks?: ModelTypes["Transaction_remarks_operator"] | undefined,
	redemptionCodes__code?: ModelTypes["Transaction_redemptionCodes__code_operator"] | undefined,
	redemptionCodes__id?: ModelTypes["Transaction_redemptionCodes__id_operator"] | undefined,
	updatedAt?: ModelTypes["Transaction_updatedAt_operator"] | undefined,
	createdAt?: ModelTypes["Transaction_createdAt_operator"] | undefined,
	id?: ModelTypes["Transaction_id_operator"] | undefined,
	AND?: Array<ModelTypes["Transaction_where_and"] | undefined> | undefined,
	OR?: Array<ModelTypes["Transaction_where_or"] | undefined> | undefined
};
	["Transaction_where_or"]: {
	creator?: ModelTypes["Transaction_creator_operator"] | undefined,
	productName?: ModelTypes["Transaction_productName_operator"] | undefined,
	referenceId?: ModelTypes["Transaction_referenceId_operator"] | undefined,
	linkedEvent?: ModelTypes["Transaction_linkedEvent_operator"] | undefined,
	purchaseDateTime?: ModelTypes["Transaction_purchaseDateTime_operator"] | undefined,
	timableOrderId?: ModelTypes["Transaction_timableOrderId_operator"] | undefined,
	stripePaymentId?: ModelTypes["Transaction_stripePaymentId_operator"] | undefined,
	totalAmount?: ModelTypes["Transaction_totalAmount_operator"] | undefined,
	contactPersonName?: ModelTypes["Transaction_contactPersonName_operator"] | undefined,
	contactPersonPhone?: ModelTypes["Transaction_contactPersonPhone_operator"] | undefined,
	remarks?: ModelTypes["Transaction_remarks_operator"] | undefined,
	redemptionCodes__code?: ModelTypes["Transaction_redemptionCodes__code_operator"] | undefined,
	redemptionCodes__id?: ModelTypes["Transaction_redemptionCodes__id_operator"] | undefined,
	updatedAt?: ModelTypes["Transaction_updatedAt_operator"] | undefined,
	createdAt?: ModelTypes["Transaction_createdAt_operator"] | undefined,
	id?: ModelTypes["Transaction_id_operator"] | undefined,
	AND?: Array<ModelTypes["Transaction_where_and"] | undefined> | undefined,
	OR?: Array<ModelTypes["Transaction_where_or"] | undefined> | undefined
};
	["countTransactions"]: {
		totalDocs?: number | undefined
};
	["transactionsDocAccess"]: {
		fields?: ModelTypes["TransactionsDocAccessFields"] | undefined,
	create?: ModelTypes["TransactionsCreateDocAccess"] | undefined,
	read?: ModelTypes["TransactionsReadDocAccess"] | undefined,
	update?: ModelTypes["TransactionsUpdateDocAccess"] | undefined,
	delete?: ModelTypes["TransactionsDeleteDocAccess"] | undefined
};
	["TransactionsDocAccessFields"]: {
		creator?: ModelTypes["TransactionsDocAccessFields_creator"] | undefined,
	productName?: ModelTypes["TransactionsDocAccessFields_productName"] | undefined,
	referenceId?: ModelTypes["TransactionsDocAccessFields_referenceId"] | undefined,
	linkedEvent?: ModelTypes["TransactionsDocAccessFields_linkedEvent"] | undefined,
	purchaseDateTime?: ModelTypes["TransactionsDocAccessFields_purchaseDateTime"] | undefined,
	timableOrderId?: ModelTypes["TransactionsDocAccessFields_timableOrderId"] | undefined,
	stripePaymentId?: ModelTypes["TransactionsDocAccessFields_stripePaymentId"] | undefined,
	totalAmount?: ModelTypes["TransactionsDocAccessFields_totalAmount"] | undefined,
	contactPersonName?: ModelTypes["TransactionsDocAccessFields_contactPersonName"] | undefined,
	contactPersonPhone?: ModelTypes["TransactionsDocAccessFields_contactPersonPhone"] | undefined,
	remarks?: ModelTypes["TransactionsDocAccessFields_remarks"] | undefined,
	redemptionCodes?: ModelTypes["TransactionsDocAccessFields_redemptionCodes"] | undefined,
	updatedAt?: ModelTypes["TransactionsDocAccessFields_updatedAt"] | undefined,
	createdAt?: ModelTypes["TransactionsDocAccessFields_createdAt"] | undefined
};
	["TransactionsDocAccessFields_creator"]: {
		create?: ModelTypes["TransactionsDocAccessFields_creator_Create"] | undefined,
	read?: ModelTypes["TransactionsDocAccessFields_creator_Read"] | undefined,
	update?: ModelTypes["TransactionsDocAccessFields_creator_Update"] | undefined,
	delete?: ModelTypes["TransactionsDocAccessFields_creator_Delete"] | undefined
};
	["TransactionsDocAccessFields_creator_Create"]: {
		permission: boolean
};
	["TransactionsDocAccessFields_creator_Read"]: {
		permission: boolean
};
	["TransactionsDocAccessFields_creator_Update"]: {
		permission: boolean
};
	["TransactionsDocAccessFields_creator_Delete"]: {
		permission: boolean
};
	["TransactionsDocAccessFields_productName"]: {
		create?: ModelTypes["TransactionsDocAccessFields_productName_Create"] | undefined,
	read?: ModelTypes["TransactionsDocAccessFields_productName_Read"] | undefined,
	update?: ModelTypes["TransactionsDocAccessFields_productName_Update"] | undefined,
	delete?: ModelTypes["TransactionsDocAccessFields_productName_Delete"] | undefined
};
	["TransactionsDocAccessFields_productName_Create"]: {
		permission: boolean
};
	["TransactionsDocAccessFields_productName_Read"]: {
		permission: boolean
};
	["TransactionsDocAccessFields_productName_Update"]: {
		permission: boolean
};
	["TransactionsDocAccessFields_productName_Delete"]: {
		permission: boolean
};
	["TransactionsDocAccessFields_referenceId"]: {
		create?: ModelTypes["TransactionsDocAccessFields_referenceId_Create"] | undefined,
	read?: ModelTypes["TransactionsDocAccessFields_referenceId_Read"] | undefined,
	update?: ModelTypes["TransactionsDocAccessFields_referenceId_Update"] | undefined,
	delete?: ModelTypes["TransactionsDocAccessFields_referenceId_Delete"] | undefined
};
	["TransactionsDocAccessFields_referenceId_Create"]: {
		permission: boolean
};
	["TransactionsDocAccessFields_referenceId_Read"]: {
		permission: boolean
};
	["TransactionsDocAccessFields_referenceId_Update"]: {
		permission: boolean
};
	["TransactionsDocAccessFields_referenceId_Delete"]: {
		permission: boolean
};
	["TransactionsDocAccessFields_linkedEvent"]: {
		create?: ModelTypes["TransactionsDocAccessFields_linkedEvent_Create"] | undefined,
	read?: ModelTypes["TransactionsDocAccessFields_linkedEvent_Read"] | undefined,
	update?: ModelTypes["TransactionsDocAccessFields_linkedEvent_Update"] | undefined,
	delete?: ModelTypes["TransactionsDocAccessFields_linkedEvent_Delete"] | undefined
};
	["TransactionsDocAccessFields_linkedEvent_Create"]: {
		permission: boolean
};
	["TransactionsDocAccessFields_linkedEvent_Read"]: {
		permission: boolean
};
	["TransactionsDocAccessFields_linkedEvent_Update"]: {
		permission: boolean
};
	["TransactionsDocAccessFields_linkedEvent_Delete"]: {
		permission: boolean
};
	["TransactionsDocAccessFields_purchaseDateTime"]: {
		create?: ModelTypes["TransactionsDocAccessFields_purchaseDateTime_Create"] | undefined,
	read?: ModelTypes["TransactionsDocAccessFields_purchaseDateTime_Read"] | undefined,
	update?: ModelTypes["TransactionsDocAccessFields_purchaseDateTime_Update"] | undefined,
	delete?: ModelTypes["TransactionsDocAccessFields_purchaseDateTime_Delete"] | undefined
};
	["TransactionsDocAccessFields_purchaseDateTime_Create"]: {
		permission: boolean
};
	["TransactionsDocAccessFields_purchaseDateTime_Read"]: {
		permission: boolean
};
	["TransactionsDocAccessFields_purchaseDateTime_Update"]: {
		permission: boolean
};
	["TransactionsDocAccessFields_purchaseDateTime_Delete"]: {
		permission: boolean
};
	["TransactionsDocAccessFields_timableOrderId"]: {
		create?: ModelTypes["TransactionsDocAccessFields_timableOrderId_Create"] | undefined,
	read?: ModelTypes["TransactionsDocAccessFields_timableOrderId_Read"] | undefined,
	update?: ModelTypes["TransactionsDocAccessFields_timableOrderId_Update"] | undefined,
	delete?: ModelTypes["TransactionsDocAccessFields_timableOrderId_Delete"] | undefined
};
	["TransactionsDocAccessFields_timableOrderId_Create"]: {
		permission: boolean
};
	["TransactionsDocAccessFields_timableOrderId_Read"]: {
		permission: boolean
};
	["TransactionsDocAccessFields_timableOrderId_Update"]: {
		permission: boolean
};
	["TransactionsDocAccessFields_timableOrderId_Delete"]: {
		permission: boolean
};
	["TransactionsDocAccessFields_stripePaymentId"]: {
		create?: ModelTypes["TransactionsDocAccessFields_stripePaymentId_Create"] | undefined,
	read?: ModelTypes["TransactionsDocAccessFields_stripePaymentId_Read"] | undefined,
	update?: ModelTypes["TransactionsDocAccessFields_stripePaymentId_Update"] | undefined,
	delete?: ModelTypes["TransactionsDocAccessFields_stripePaymentId_Delete"] | undefined
};
	["TransactionsDocAccessFields_stripePaymentId_Create"]: {
		permission: boolean
};
	["TransactionsDocAccessFields_stripePaymentId_Read"]: {
		permission: boolean
};
	["TransactionsDocAccessFields_stripePaymentId_Update"]: {
		permission: boolean
};
	["TransactionsDocAccessFields_stripePaymentId_Delete"]: {
		permission: boolean
};
	["TransactionsDocAccessFields_totalAmount"]: {
		create?: ModelTypes["TransactionsDocAccessFields_totalAmount_Create"] | undefined,
	read?: ModelTypes["TransactionsDocAccessFields_totalAmount_Read"] | undefined,
	update?: ModelTypes["TransactionsDocAccessFields_totalAmount_Update"] | undefined,
	delete?: ModelTypes["TransactionsDocAccessFields_totalAmount_Delete"] | undefined
};
	["TransactionsDocAccessFields_totalAmount_Create"]: {
		permission: boolean
};
	["TransactionsDocAccessFields_totalAmount_Read"]: {
		permission: boolean
};
	["TransactionsDocAccessFields_totalAmount_Update"]: {
		permission: boolean
};
	["TransactionsDocAccessFields_totalAmount_Delete"]: {
		permission: boolean
};
	["TransactionsDocAccessFields_contactPersonName"]: {
		create?: ModelTypes["TransactionsDocAccessFields_contactPersonName_Create"] | undefined,
	read?: ModelTypes["TransactionsDocAccessFields_contactPersonName_Read"] | undefined,
	update?: ModelTypes["TransactionsDocAccessFields_contactPersonName_Update"] | undefined,
	delete?: ModelTypes["TransactionsDocAccessFields_contactPersonName_Delete"] | undefined
};
	["TransactionsDocAccessFields_contactPersonName_Create"]: {
		permission: boolean
};
	["TransactionsDocAccessFields_contactPersonName_Read"]: {
		permission: boolean
};
	["TransactionsDocAccessFields_contactPersonName_Update"]: {
		permission: boolean
};
	["TransactionsDocAccessFields_contactPersonName_Delete"]: {
		permission: boolean
};
	["TransactionsDocAccessFields_contactPersonPhone"]: {
		create?: ModelTypes["TransactionsDocAccessFields_contactPersonPhone_Create"] | undefined,
	read?: ModelTypes["TransactionsDocAccessFields_contactPersonPhone_Read"] | undefined,
	update?: ModelTypes["TransactionsDocAccessFields_contactPersonPhone_Update"] | undefined,
	delete?: ModelTypes["TransactionsDocAccessFields_contactPersonPhone_Delete"] | undefined
};
	["TransactionsDocAccessFields_contactPersonPhone_Create"]: {
		permission: boolean
};
	["TransactionsDocAccessFields_contactPersonPhone_Read"]: {
		permission: boolean
};
	["TransactionsDocAccessFields_contactPersonPhone_Update"]: {
		permission: boolean
};
	["TransactionsDocAccessFields_contactPersonPhone_Delete"]: {
		permission: boolean
};
	["TransactionsDocAccessFields_remarks"]: {
		create?: ModelTypes["TransactionsDocAccessFields_remarks_Create"] | undefined,
	read?: ModelTypes["TransactionsDocAccessFields_remarks_Read"] | undefined,
	update?: ModelTypes["TransactionsDocAccessFields_remarks_Update"] | undefined,
	delete?: ModelTypes["TransactionsDocAccessFields_remarks_Delete"] | undefined
};
	["TransactionsDocAccessFields_remarks_Create"]: {
		permission: boolean
};
	["TransactionsDocAccessFields_remarks_Read"]: {
		permission: boolean
};
	["TransactionsDocAccessFields_remarks_Update"]: {
		permission: boolean
};
	["TransactionsDocAccessFields_remarks_Delete"]: {
		permission: boolean
};
	["TransactionsDocAccessFields_redemptionCodes"]: {
		create?: ModelTypes["TransactionsDocAccessFields_redemptionCodes_Create"] | undefined,
	read?: ModelTypes["TransactionsDocAccessFields_redemptionCodes_Read"] | undefined,
	update?: ModelTypes["TransactionsDocAccessFields_redemptionCodes_Update"] | undefined,
	delete?: ModelTypes["TransactionsDocAccessFields_redemptionCodes_Delete"] | undefined,
	fields?: ModelTypes["TransactionsDocAccessFields_redemptionCodes_Fields"] | undefined
};
	["TransactionsDocAccessFields_redemptionCodes_Create"]: {
		permission: boolean
};
	["TransactionsDocAccessFields_redemptionCodes_Read"]: {
		permission: boolean
};
	["TransactionsDocAccessFields_redemptionCodes_Update"]: {
		permission: boolean
};
	["TransactionsDocAccessFields_redemptionCodes_Delete"]: {
		permission: boolean
};
	["TransactionsDocAccessFields_redemptionCodes_Fields"]: {
		code?: ModelTypes["TransactionsDocAccessFields_redemptionCodes_code"] | undefined,
	id?: ModelTypes["TransactionsDocAccessFields_redemptionCodes_id"] | undefined
};
	["TransactionsDocAccessFields_redemptionCodes_code"]: {
		create?: ModelTypes["TransactionsDocAccessFields_redemptionCodes_code_Create"] | undefined,
	read?: ModelTypes["TransactionsDocAccessFields_redemptionCodes_code_Read"] | undefined,
	update?: ModelTypes["TransactionsDocAccessFields_redemptionCodes_code_Update"] | undefined,
	delete?: ModelTypes["TransactionsDocAccessFields_redemptionCodes_code_Delete"] | undefined
};
	["TransactionsDocAccessFields_redemptionCodes_code_Create"]: {
		permission: boolean
};
	["TransactionsDocAccessFields_redemptionCodes_code_Read"]: {
		permission: boolean
};
	["TransactionsDocAccessFields_redemptionCodes_code_Update"]: {
		permission: boolean
};
	["TransactionsDocAccessFields_redemptionCodes_code_Delete"]: {
		permission: boolean
};
	["TransactionsDocAccessFields_redemptionCodes_id"]: {
		create?: ModelTypes["TransactionsDocAccessFields_redemptionCodes_id_Create"] | undefined,
	read?: ModelTypes["TransactionsDocAccessFields_redemptionCodes_id_Read"] | undefined,
	update?: ModelTypes["TransactionsDocAccessFields_redemptionCodes_id_Update"] | undefined,
	delete?: ModelTypes["TransactionsDocAccessFields_redemptionCodes_id_Delete"] | undefined
};
	["TransactionsDocAccessFields_redemptionCodes_id_Create"]: {
		permission: boolean
};
	["TransactionsDocAccessFields_redemptionCodes_id_Read"]: {
		permission: boolean
};
	["TransactionsDocAccessFields_redemptionCodes_id_Update"]: {
		permission: boolean
};
	["TransactionsDocAccessFields_redemptionCodes_id_Delete"]: {
		permission: boolean
};
	["TransactionsDocAccessFields_updatedAt"]: {
		create?: ModelTypes["TransactionsDocAccessFields_updatedAt_Create"] | undefined,
	read?: ModelTypes["TransactionsDocAccessFields_updatedAt_Read"] | undefined,
	update?: ModelTypes["TransactionsDocAccessFields_updatedAt_Update"] | undefined,
	delete?: ModelTypes["TransactionsDocAccessFields_updatedAt_Delete"] | undefined
};
	["TransactionsDocAccessFields_updatedAt_Create"]: {
		permission: boolean
};
	["TransactionsDocAccessFields_updatedAt_Read"]: {
		permission: boolean
};
	["TransactionsDocAccessFields_updatedAt_Update"]: {
		permission: boolean
};
	["TransactionsDocAccessFields_updatedAt_Delete"]: {
		permission: boolean
};
	["TransactionsDocAccessFields_createdAt"]: {
		create?: ModelTypes["TransactionsDocAccessFields_createdAt_Create"] | undefined,
	read?: ModelTypes["TransactionsDocAccessFields_createdAt_Read"] | undefined,
	update?: ModelTypes["TransactionsDocAccessFields_createdAt_Update"] | undefined,
	delete?: ModelTypes["TransactionsDocAccessFields_createdAt_Delete"] | undefined
};
	["TransactionsDocAccessFields_createdAt_Create"]: {
		permission: boolean
};
	["TransactionsDocAccessFields_createdAt_Read"]: {
		permission: boolean
};
	["TransactionsDocAccessFields_createdAt_Update"]: {
		permission: boolean
};
	["TransactionsDocAccessFields_createdAt_Delete"]: {
		permission: boolean
};
	["TransactionsCreateDocAccess"]: {
		permission: boolean,
	where?: ModelTypes["JSONObject"] | undefined
};
	["TransactionsReadDocAccess"]: {
		permission: boolean,
	where?: ModelTypes["JSONObject"] | undefined
};
	["TransactionsUpdateDocAccess"]: {
		permission: boolean,
	where?: ModelTypes["JSONObject"] | undefined
};
	["TransactionsDeleteDocAccess"]: {
		permission: boolean,
	where?: ModelTypes["JSONObject"] | undefined
};
	["RedemptionCode"]: {
		id?: string | undefined,
	creator?: ModelTypes["User"] | undefined,
	status: ModelTypes["RedemptionCode_status"],
	qrCodeId?: string | undefined,
	lastCheckedInDateTime?: ModelTypes["DateTime"] | undefined,
	voidedDateTime?: ModelTypes["DateTime"] | undefined,
	refundedDateTime?: ModelTypes["DateTime"] | undefined,
	eventStartTime?: ModelTypes["DateTime"] | undefined,
	eventEndTime?: ModelTypes["DateTime"] | undefined,
	eventPriceZone?: string | undefined,
	ownerName: string,
	phone: string,
	pdf?: ModelTypes["RedemptionPdf"] | undefined,
	variants?: Array<ModelTypes["RedemptionCode_Variants"]> | undefined,
	productId?: string | undefined,
	referenceId?: string | undefined,
	productType?: ModelTypes["RedemptionCode_productType"] | undefined,
	productName?: string | undefined,
	linkedEvent?: ModelTypes["Event"] | undefined,
	timableOrderId?: string | undefined,
	purchaseDateTime?: ModelTypes["DateTime"] | undefined,
	changeHistory?: Array<ModelTypes["RedemptionCode_ChangeHistory"]> | undefined,
	updatedAt?: ModelTypes["DateTime"] | undefined,
	createdAt?: ModelTypes["DateTime"] | undefined
};
	["RedemptionCode_status"]:RedemptionCode_status;
	["RedemptionPdf"]: {
		id?: string | undefined,
	updatedAt?: ModelTypes["DateTime"] | undefined,
	createdAt?: ModelTypes["DateTime"] | undefined,
	url?: string | undefined,
	filename?: string | undefined,
	mimeType?: string | undefined,
	filesize?: number | undefined,
	width?: number | undefined,
	height?: number | undefined,
	focalX?: number | undefined,
	focalY?: number | undefined
};
	["RedemptionCode_Variants"]: {
		variantId?: string | undefined,
	name?: string | undefined,
	quantity?: string | undefined,
	totalCost?: string | undefined,
	id?: string | undefined
};
	["RedemptionCode_productType"]:RedemptionCode_productType;
	["RedemptionCode_ChangeHistory"]: {
		timestamp?: ModelTypes["DateTime"] | undefined,
	field?: string | undefined,
	oldValue?: string | undefined,
	newValue?: string | undefined,
	id?: string | undefined
};
	["RedemptionCodes"]: {
		docs?: Array<ModelTypes["RedemptionCode"] | undefined> | undefined,
	hasNextPage?: boolean | undefined,
	hasPrevPage?: boolean | undefined,
	limit?: number | undefined,
	nextPage?: number | undefined,
	offset?: number | undefined,
	page?: number | undefined,
	pagingCounter?: number | undefined,
	prevPage?: number | undefined,
	totalDocs?: number | undefined,
	totalPages?: number | undefined
};
	["RedemptionCode_where"]: {
	creator?: ModelTypes["RedemptionCode_creator_operator"] | undefined,
	status?: ModelTypes["RedemptionCode_status_operator"] | undefined,
	qrCodeId?: ModelTypes["RedemptionCode_qrCodeId_operator"] | undefined,
	lastCheckedInDateTime?: ModelTypes["RedemptionCode_lastCheckedInDateTime_operator"] | undefined,
	voidedDateTime?: ModelTypes["RedemptionCode_voidedDateTime_operator"] | undefined,
	refundedDateTime?: ModelTypes["RedemptionCode_refundedDateTime_operator"] | undefined,
	eventStartTime?: ModelTypes["RedemptionCode_eventStartTime_operator"] | undefined,
	eventEndTime?: ModelTypes["RedemptionCode_eventEndTime_operator"] | undefined,
	eventPriceZone?: ModelTypes["RedemptionCode_eventPriceZone_operator"] | undefined,
	ownerName?: ModelTypes["RedemptionCode_ownerName_operator"] | undefined,
	phone?: ModelTypes["RedemptionCode_phone_operator"] | undefined,
	pdf?: ModelTypes["RedemptionCode_pdf_operator"] | undefined,
	variants__variantId?: ModelTypes["RedemptionCode_variants__variantId_operator"] | undefined,
	variants__name?: ModelTypes["RedemptionCode_variants__name_operator"] | undefined,
	variants__quantity?: ModelTypes["RedemptionCode_variants__quantity_operator"] | undefined,
	variants__totalCost?: ModelTypes["RedemptionCode_variants__totalCost_operator"] | undefined,
	variants__id?: ModelTypes["RedemptionCode_variants__id_operator"] | undefined,
	productId?: ModelTypes["RedemptionCode_productId_operator"] | undefined,
	referenceId?: ModelTypes["RedemptionCode_referenceId_operator"] | undefined,
	productType?: ModelTypes["RedemptionCode_productType_operator"] | undefined,
	productName?: ModelTypes["RedemptionCode_productName_operator"] | undefined,
	linkedEvent?: ModelTypes["RedemptionCode_linkedEvent_operator"] | undefined,
	timableOrderId?: ModelTypes["RedemptionCode_timableOrderId_operator"] | undefined,
	purchaseDateTime?: ModelTypes["RedemptionCode_purchaseDateTime_operator"] | undefined,
	changeHistory__timestamp?: ModelTypes["RedemptionCode_changeHistory__timestamp_operator"] | undefined,
	changeHistory__field?: ModelTypes["RedemptionCode_changeHistory__field_operator"] | undefined,
	changeHistory__oldValue?: ModelTypes["RedemptionCode_changeHistory__oldValue_operator"] | undefined,
	changeHistory__newValue?: ModelTypes["RedemptionCode_changeHistory__newValue_operator"] | undefined,
	changeHistory__id?: ModelTypes["RedemptionCode_changeHistory__id_operator"] | undefined,
	updatedAt?: ModelTypes["RedemptionCode_updatedAt_operator"] | undefined,
	createdAt?: ModelTypes["RedemptionCode_createdAt_operator"] | undefined,
	id?: ModelTypes["RedemptionCode_id_operator"] | undefined,
	AND?: Array<ModelTypes["RedemptionCode_where_and"] | undefined> | undefined,
	OR?: Array<ModelTypes["RedemptionCode_where_or"] | undefined> | undefined
};
	["RedemptionCode_creator_operator"]: {
	equals?: ModelTypes["JSON"] | undefined,
	not_equals?: ModelTypes["JSON"] | undefined,
	in?: Array<ModelTypes["JSON"] | undefined> | undefined,
	not_in?: Array<ModelTypes["JSON"] | undefined> | undefined,
	all?: Array<ModelTypes["JSON"] | undefined> | undefined,
	exists?: boolean | undefined
};
	["RedemptionCode_status_operator"]: {
	equals?: ModelTypes["RedemptionCode_status_Input"] | undefined,
	not_equals?: ModelTypes["RedemptionCode_status_Input"] | undefined,
	in?: Array<ModelTypes["RedemptionCode_status_Input"] | undefined> | undefined,
	not_in?: Array<ModelTypes["RedemptionCode_status_Input"] | undefined> | undefined,
	all?: Array<ModelTypes["RedemptionCode_status_Input"] | undefined> | undefined
};
	["RedemptionCode_status_Input"]:RedemptionCode_status_Input;
	["RedemptionCode_qrCodeId_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["RedemptionCode_lastCheckedInDateTime_operator"]: {
	equals?: ModelTypes["DateTime"] | undefined,
	not_equals?: ModelTypes["DateTime"] | undefined,
	greater_than_equal?: ModelTypes["DateTime"] | undefined,
	greater_than?: ModelTypes["DateTime"] | undefined,
	less_than_equal?: ModelTypes["DateTime"] | undefined,
	less_than?: ModelTypes["DateTime"] | undefined,
	like?: ModelTypes["DateTime"] | undefined,
	exists?: boolean | undefined
};
	["RedemptionCode_voidedDateTime_operator"]: {
	equals?: ModelTypes["DateTime"] | undefined,
	not_equals?: ModelTypes["DateTime"] | undefined,
	greater_than_equal?: ModelTypes["DateTime"] | undefined,
	greater_than?: ModelTypes["DateTime"] | undefined,
	less_than_equal?: ModelTypes["DateTime"] | undefined,
	less_than?: ModelTypes["DateTime"] | undefined,
	like?: ModelTypes["DateTime"] | undefined,
	exists?: boolean | undefined
};
	["RedemptionCode_refundedDateTime_operator"]: {
	equals?: ModelTypes["DateTime"] | undefined,
	not_equals?: ModelTypes["DateTime"] | undefined,
	greater_than_equal?: ModelTypes["DateTime"] | undefined,
	greater_than?: ModelTypes["DateTime"] | undefined,
	less_than_equal?: ModelTypes["DateTime"] | undefined,
	less_than?: ModelTypes["DateTime"] | undefined,
	like?: ModelTypes["DateTime"] | undefined,
	exists?: boolean | undefined
};
	["RedemptionCode_eventStartTime_operator"]: {
	equals?: ModelTypes["DateTime"] | undefined,
	not_equals?: ModelTypes["DateTime"] | undefined,
	greater_than_equal?: ModelTypes["DateTime"] | undefined,
	greater_than?: ModelTypes["DateTime"] | undefined,
	less_than_equal?: ModelTypes["DateTime"] | undefined,
	less_than?: ModelTypes["DateTime"] | undefined,
	like?: ModelTypes["DateTime"] | undefined,
	exists?: boolean | undefined
};
	["RedemptionCode_eventEndTime_operator"]: {
	equals?: ModelTypes["DateTime"] | undefined,
	not_equals?: ModelTypes["DateTime"] | undefined,
	greater_than_equal?: ModelTypes["DateTime"] | undefined,
	greater_than?: ModelTypes["DateTime"] | undefined,
	less_than_equal?: ModelTypes["DateTime"] | undefined,
	less_than?: ModelTypes["DateTime"] | undefined,
	like?: ModelTypes["DateTime"] | undefined,
	exists?: boolean | undefined
};
	["RedemptionCode_eventPriceZone_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["RedemptionCode_ownerName_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined
};
	["RedemptionCode_phone_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined
};
	["RedemptionCode_pdf_operator"]: {
	equals?: ModelTypes["JSON"] | undefined,
	not_equals?: ModelTypes["JSON"] | undefined,
	in?: Array<ModelTypes["JSON"] | undefined> | undefined,
	not_in?: Array<ModelTypes["JSON"] | undefined> | undefined,
	all?: Array<ModelTypes["JSON"] | undefined> | undefined,
	exists?: boolean | undefined
};
	["RedemptionCode_variants__variantId_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["RedemptionCode_variants__name_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["RedemptionCode_variants__quantity_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["RedemptionCode_variants__totalCost_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["RedemptionCode_variants__id_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["RedemptionCode_productId_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["RedemptionCode_referenceId_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["RedemptionCode_productType_operator"]: {
	equals?: ModelTypes["RedemptionCode_productType_Input"] | undefined,
	not_equals?: ModelTypes["RedemptionCode_productType_Input"] | undefined,
	like?: ModelTypes["RedemptionCode_productType_Input"] | undefined,
	contains?: ModelTypes["RedemptionCode_productType_Input"] | undefined,
	exists?: boolean | undefined
};
	["RedemptionCode_productType_Input"]:RedemptionCode_productType_Input;
	["RedemptionCode_productName_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["RedemptionCode_linkedEvent_operator"]: {
	equals?: ModelTypes["JSON"] | undefined,
	not_equals?: ModelTypes["JSON"] | undefined,
	in?: Array<ModelTypes["JSON"] | undefined> | undefined,
	not_in?: Array<ModelTypes["JSON"] | undefined> | undefined,
	all?: Array<ModelTypes["JSON"] | undefined> | undefined,
	exists?: boolean | undefined
};
	["RedemptionCode_timableOrderId_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["RedemptionCode_purchaseDateTime_operator"]: {
	equals?: ModelTypes["DateTime"] | undefined,
	not_equals?: ModelTypes["DateTime"] | undefined,
	greater_than_equal?: ModelTypes["DateTime"] | undefined,
	greater_than?: ModelTypes["DateTime"] | undefined,
	less_than_equal?: ModelTypes["DateTime"] | undefined,
	less_than?: ModelTypes["DateTime"] | undefined,
	like?: ModelTypes["DateTime"] | undefined,
	exists?: boolean | undefined
};
	["RedemptionCode_changeHistory__timestamp_operator"]: {
	equals?: ModelTypes["DateTime"] | undefined,
	not_equals?: ModelTypes["DateTime"] | undefined,
	greater_than_equal?: ModelTypes["DateTime"] | undefined,
	greater_than?: ModelTypes["DateTime"] | undefined,
	less_than_equal?: ModelTypes["DateTime"] | undefined,
	less_than?: ModelTypes["DateTime"] | undefined,
	like?: ModelTypes["DateTime"] | undefined
};
	["RedemptionCode_changeHistory__field_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined
};
	["RedemptionCode_changeHistory__oldValue_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined
};
	["RedemptionCode_changeHistory__newValue_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined
};
	["RedemptionCode_changeHistory__id_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["RedemptionCode_updatedAt_operator"]: {
	equals?: ModelTypes["DateTime"] | undefined,
	not_equals?: ModelTypes["DateTime"] | undefined,
	greater_than_equal?: ModelTypes["DateTime"] | undefined,
	greater_than?: ModelTypes["DateTime"] | undefined,
	less_than_equal?: ModelTypes["DateTime"] | undefined,
	less_than?: ModelTypes["DateTime"] | undefined,
	like?: ModelTypes["DateTime"] | undefined,
	exists?: boolean | undefined
};
	["RedemptionCode_createdAt_operator"]: {
	equals?: ModelTypes["DateTime"] | undefined,
	not_equals?: ModelTypes["DateTime"] | undefined,
	greater_than_equal?: ModelTypes["DateTime"] | undefined,
	greater_than?: ModelTypes["DateTime"] | undefined,
	less_than_equal?: ModelTypes["DateTime"] | undefined,
	less_than?: ModelTypes["DateTime"] | undefined,
	like?: ModelTypes["DateTime"] | undefined,
	exists?: boolean | undefined
};
	["RedemptionCode_id_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["RedemptionCode_where_and"]: {
	creator?: ModelTypes["RedemptionCode_creator_operator"] | undefined,
	status?: ModelTypes["RedemptionCode_status_operator"] | undefined,
	qrCodeId?: ModelTypes["RedemptionCode_qrCodeId_operator"] | undefined,
	lastCheckedInDateTime?: ModelTypes["RedemptionCode_lastCheckedInDateTime_operator"] | undefined,
	voidedDateTime?: ModelTypes["RedemptionCode_voidedDateTime_operator"] | undefined,
	refundedDateTime?: ModelTypes["RedemptionCode_refundedDateTime_operator"] | undefined,
	eventStartTime?: ModelTypes["RedemptionCode_eventStartTime_operator"] | undefined,
	eventEndTime?: ModelTypes["RedemptionCode_eventEndTime_operator"] | undefined,
	eventPriceZone?: ModelTypes["RedemptionCode_eventPriceZone_operator"] | undefined,
	ownerName?: ModelTypes["RedemptionCode_ownerName_operator"] | undefined,
	phone?: ModelTypes["RedemptionCode_phone_operator"] | undefined,
	pdf?: ModelTypes["RedemptionCode_pdf_operator"] | undefined,
	variants__variantId?: ModelTypes["RedemptionCode_variants__variantId_operator"] | undefined,
	variants__name?: ModelTypes["RedemptionCode_variants__name_operator"] | undefined,
	variants__quantity?: ModelTypes["RedemptionCode_variants__quantity_operator"] | undefined,
	variants__totalCost?: ModelTypes["RedemptionCode_variants__totalCost_operator"] | undefined,
	variants__id?: ModelTypes["RedemptionCode_variants__id_operator"] | undefined,
	productId?: ModelTypes["RedemptionCode_productId_operator"] | undefined,
	referenceId?: ModelTypes["RedemptionCode_referenceId_operator"] | undefined,
	productType?: ModelTypes["RedemptionCode_productType_operator"] | undefined,
	productName?: ModelTypes["RedemptionCode_productName_operator"] | undefined,
	linkedEvent?: ModelTypes["RedemptionCode_linkedEvent_operator"] | undefined,
	timableOrderId?: ModelTypes["RedemptionCode_timableOrderId_operator"] | undefined,
	purchaseDateTime?: ModelTypes["RedemptionCode_purchaseDateTime_operator"] | undefined,
	changeHistory__timestamp?: ModelTypes["RedemptionCode_changeHistory__timestamp_operator"] | undefined,
	changeHistory__field?: ModelTypes["RedemptionCode_changeHistory__field_operator"] | undefined,
	changeHistory__oldValue?: ModelTypes["RedemptionCode_changeHistory__oldValue_operator"] | undefined,
	changeHistory__newValue?: ModelTypes["RedemptionCode_changeHistory__newValue_operator"] | undefined,
	changeHistory__id?: ModelTypes["RedemptionCode_changeHistory__id_operator"] | undefined,
	updatedAt?: ModelTypes["RedemptionCode_updatedAt_operator"] | undefined,
	createdAt?: ModelTypes["RedemptionCode_createdAt_operator"] | undefined,
	id?: ModelTypes["RedemptionCode_id_operator"] | undefined,
	AND?: Array<ModelTypes["RedemptionCode_where_and"] | undefined> | undefined,
	OR?: Array<ModelTypes["RedemptionCode_where_or"] | undefined> | undefined
};
	["RedemptionCode_where_or"]: {
	creator?: ModelTypes["RedemptionCode_creator_operator"] | undefined,
	status?: ModelTypes["RedemptionCode_status_operator"] | undefined,
	qrCodeId?: ModelTypes["RedemptionCode_qrCodeId_operator"] | undefined,
	lastCheckedInDateTime?: ModelTypes["RedemptionCode_lastCheckedInDateTime_operator"] | undefined,
	voidedDateTime?: ModelTypes["RedemptionCode_voidedDateTime_operator"] | undefined,
	refundedDateTime?: ModelTypes["RedemptionCode_refundedDateTime_operator"] | undefined,
	eventStartTime?: ModelTypes["RedemptionCode_eventStartTime_operator"] | undefined,
	eventEndTime?: ModelTypes["RedemptionCode_eventEndTime_operator"] | undefined,
	eventPriceZone?: ModelTypes["RedemptionCode_eventPriceZone_operator"] | undefined,
	ownerName?: ModelTypes["RedemptionCode_ownerName_operator"] | undefined,
	phone?: ModelTypes["RedemptionCode_phone_operator"] | undefined,
	pdf?: ModelTypes["RedemptionCode_pdf_operator"] | undefined,
	variants__variantId?: ModelTypes["RedemptionCode_variants__variantId_operator"] | undefined,
	variants__name?: ModelTypes["RedemptionCode_variants__name_operator"] | undefined,
	variants__quantity?: ModelTypes["RedemptionCode_variants__quantity_operator"] | undefined,
	variants__totalCost?: ModelTypes["RedemptionCode_variants__totalCost_operator"] | undefined,
	variants__id?: ModelTypes["RedemptionCode_variants__id_operator"] | undefined,
	productId?: ModelTypes["RedemptionCode_productId_operator"] | undefined,
	referenceId?: ModelTypes["RedemptionCode_referenceId_operator"] | undefined,
	productType?: ModelTypes["RedemptionCode_productType_operator"] | undefined,
	productName?: ModelTypes["RedemptionCode_productName_operator"] | undefined,
	linkedEvent?: ModelTypes["RedemptionCode_linkedEvent_operator"] | undefined,
	timableOrderId?: ModelTypes["RedemptionCode_timableOrderId_operator"] | undefined,
	purchaseDateTime?: ModelTypes["RedemptionCode_purchaseDateTime_operator"] | undefined,
	changeHistory__timestamp?: ModelTypes["RedemptionCode_changeHistory__timestamp_operator"] | undefined,
	changeHistory__field?: ModelTypes["RedemptionCode_changeHistory__field_operator"] | undefined,
	changeHistory__oldValue?: ModelTypes["RedemptionCode_changeHistory__oldValue_operator"] | undefined,
	changeHistory__newValue?: ModelTypes["RedemptionCode_changeHistory__newValue_operator"] | undefined,
	changeHistory__id?: ModelTypes["RedemptionCode_changeHistory__id_operator"] | undefined,
	updatedAt?: ModelTypes["RedemptionCode_updatedAt_operator"] | undefined,
	createdAt?: ModelTypes["RedemptionCode_createdAt_operator"] | undefined,
	id?: ModelTypes["RedemptionCode_id_operator"] | undefined,
	AND?: Array<ModelTypes["RedemptionCode_where_and"] | undefined> | undefined,
	OR?: Array<ModelTypes["RedemptionCode_where_or"] | undefined> | undefined
};
	["countRedemptionCodes"]: {
		totalDocs?: number | undefined
};
	["redemption_codesDocAccess"]: {
		fields?: ModelTypes["RedemptionCodesDocAccessFields"] | undefined,
	create?: ModelTypes["RedemptionCodesCreateDocAccess"] | undefined,
	read?: ModelTypes["RedemptionCodesReadDocAccess"] | undefined,
	update?: ModelTypes["RedemptionCodesUpdateDocAccess"] | undefined,
	delete?: ModelTypes["RedemptionCodesDeleteDocAccess"] | undefined
};
	["RedemptionCodesDocAccessFields"]: {
		creator?: ModelTypes["RedemptionCodesDocAccessFields_creator"] | undefined,
	status?: ModelTypes["RedemptionCodesDocAccessFields_status"] | undefined,
	qrCodeId?: ModelTypes["RedemptionCodesDocAccessFields_qrCodeId"] | undefined,
	lastCheckedInDateTime?: ModelTypes["RedemptionCodesDocAccessFields_lastCheckedInDateTime"] | undefined,
	voidedDateTime?: ModelTypes["RedemptionCodesDocAccessFields_voidedDateTime"] | undefined,
	refundedDateTime?: ModelTypes["RedemptionCodesDocAccessFields_refundedDateTime"] | undefined,
	eventStartTime?: ModelTypes["RedemptionCodesDocAccessFields_eventStartTime"] | undefined,
	eventEndTime?: ModelTypes["RedemptionCodesDocAccessFields_eventEndTime"] | undefined,
	eventPriceZone?: ModelTypes["RedemptionCodesDocAccessFields_eventPriceZone"] | undefined,
	ownerName?: ModelTypes["RedemptionCodesDocAccessFields_ownerName"] | undefined,
	phone?: ModelTypes["RedemptionCodesDocAccessFields_phone"] | undefined,
	pdf?: ModelTypes["RedemptionCodesDocAccessFields_pdf"] | undefined,
	variants?: ModelTypes["RedemptionCodesDocAccessFields_variants"] | undefined,
	productId?: ModelTypes["RedemptionCodesDocAccessFields_productId"] | undefined,
	referenceId?: ModelTypes["RedemptionCodesDocAccessFields_referenceId"] | undefined,
	productType?: ModelTypes["RedemptionCodesDocAccessFields_productType"] | undefined,
	productName?: ModelTypes["RedemptionCodesDocAccessFields_productName"] | undefined,
	linkedEvent?: ModelTypes["RedemptionCodesDocAccessFields_linkedEvent"] | undefined,
	timableOrderId?: ModelTypes["RedemptionCodesDocAccessFields_timableOrderId"] | undefined,
	purchaseDateTime?: ModelTypes["RedemptionCodesDocAccessFields_purchaseDateTime"] | undefined,
	changeHistory?: ModelTypes["RedemptionCodesDocAccessFields_changeHistory"] | undefined,
	updatedAt?: ModelTypes["RedemptionCodesDocAccessFields_updatedAt"] | undefined,
	createdAt?: ModelTypes["RedemptionCodesDocAccessFields_createdAt"] | undefined
};
	["RedemptionCodesDocAccessFields_creator"]: {
		create?: ModelTypes["RedemptionCodesDocAccessFields_creator_Create"] | undefined,
	read?: ModelTypes["RedemptionCodesDocAccessFields_creator_Read"] | undefined,
	update?: ModelTypes["RedemptionCodesDocAccessFields_creator_Update"] | undefined,
	delete?: ModelTypes["RedemptionCodesDocAccessFields_creator_Delete"] | undefined
};
	["RedemptionCodesDocAccessFields_creator_Create"]: {
		permission: boolean
};
	["RedemptionCodesDocAccessFields_creator_Read"]: {
		permission: boolean
};
	["RedemptionCodesDocAccessFields_creator_Update"]: {
		permission: boolean
};
	["RedemptionCodesDocAccessFields_creator_Delete"]: {
		permission: boolean
};
	["RedemptionCodesDocAccessFields_status"]: {
		create?: ModelTypes["RedemptionCodesDocAccessFields_status_Create"] | undefined,
	read?: ModelTypes["RedemptionCodesDocAccessFields_status_Read"] | undefined,
	update?: ModelTypes["RedemptionCodesDocAccessFields_status_Update"] | undefined,
	delete?: ModelTypes["RedemptionCodesDocAccessFields_status_Delete"] | undefined
};
	["RedemptionCodesDocAccessFields_status_Create"]: {
		permission: boolean
};
	["RedemptionCodesDocAccessFields_status_Read"]: {
		permission: boolean
};
	["RedemptionCodesDocAccessFields_status_Update"]: {
		permission: boolean
};
	["RedemptionCodesDocAccessFields_status_Delete"]: {
		permission: boolean
};
	["RedemptionCodesDocAccessFields_qrCodeId"]: {
		create?: ModelTypes["RedemptionCodesDocAccessFields_qrCodeId_Create"] | undefined,
	read?: ModelTypes["RedemptionCodesDocAccessFields_qrCodeId_Read"] | undefined,
	update?: ModelTypes["RedemptionCodesDocAccessFields_qrCodeId_Update"] | undefined,
	delete?: ModelTypes["RedemptionCodesDocAccessFields_qrCodeId_Delete"] | undefined
};
	["RedemptionCodesDocAccessFields_qrCodeId_Create"]: {
		permission: boolean
};
	["RedemptionCodesDocAccessFields_qrCodeId_Read"]: {
		permission: boolean
};
	["RedemptionCodesDocAccessFields_qrCodeId_Update"]: {
		permission: boolean
};
	["RedemptionCodesDocAccessFields_qrCodeId_Delete"]: {
		permission: boolean
};
	["RedemptionCodesDocAccessFields_lastCheckedInDateTime"]: {
		create?: ModelTypes["RedemptionCodesDocAccessFields_lastCheckedInDateTime_Create"] | undefined,
	read?: ModelTypes["RedemptionCodesDocAccessFields_lastCheckedInDateTime_Read"] | undefined,
	update?: ModelTypes["RedemptionCodesDocAccessFields_lastCheckedInDateTime_Update"] | undefined,
	delete?: ModelTypes["RedemptionCodesDocAccessFields_lastCheckedInDateTime_Delete"] | undefined
};
	["RedemptionCodesDocAccessFields_lastCheckedInDateTime_Create"]: {
		permission: boolean
};
	["RedemptionCodesDocAccessFields_lastCheckedInDateTime_Read"]: {
		permission: boolean
};
	["RedemptionCodesDocAccessFields_lastCheckedInDateTime_Update"]: {
		permission: boolean
};
	["RedemptionCodesDocAccessFields_lastCheckedInDateTime_Delete"]: {
		permission: boolean
};
	["RedemptionCodesDocAccessFields_voidedDateTime"]: {
		create?: ModelTypes["RedemptionCodesDocAccessFields_voidedDateTime_Create"] | undefined,
	read?: ModelTypes["RedemptionCodesDocAccessFields_voidedDateTime_Read"] | undefined,
	update?: ModelTypes["RedemptionCodesDocAccessFields_voidedDateTime_Update"] | undefined,
	delete?: ModelTypes["RedemptionCodesDocAccessFields_voidedDateTime_Delete"] | undefined
};
	["RedemptionCodesDocAccessFields_voidedDateTime_Create"]: {
		permission: boolean
};
	["RedemptionCodesDocAccessFields_voidedDateTime_Read"]: {
		permission: boolean
};
	["RedemptionCodesDocAccessFields_voidedDateTime_Update"]: {
		permission: boolean
};
	["RedemptionCodesDocAccessFields_voidedDateTime_Delete"]: {
		permission: boolean
};
	["RedemptionCodesDocAccessFields_refundedDateTime"]: {
		create?: ModelTypes["RedemptionCodesDocAccessFields_refundedDateTime_Create"] | undefined,
	read?: ModelTypes["RedemptionCodesDocAccessFields_refundedDateTime_Read"] | undefined,
	update?: ModelTypes["RedemptionCodesDocAccessFields_refundedDateTime_Update"] | undefined,
	delete?: ModelTypes["RedemptionCodesDocAccessFields_refundedDateTime_Delete"] | undefined
};
	["RedemptionCodesDocAccessFields_refundedDateTime_Create"]: {
		permission: boolean
};
	["RedemptionCodesDocAccessFields_refundedDateTime_Read"]: {
		permission: boolean
};
	["RedemptionCodesDocAccessFields_refundedDateTime_Update"]: {
		permission: boolean
};
	["RedemptionCodesDocAccessFields_refundedDateTime_Delete"]: {
		permission: boolean
};
	["RedemptionCodesDocAccessFields_eventStartTime"]: {
		create?: ModelTypes["RedemptionCodesDocAccessFields_eventStartTime_Create"] | undefined,
	read?: ModelTypes["RedemptionCodesDocAccessFields_eventStartTime_Read"] | undefined,
	update?: ModelTypes["RedemptionCodesDocAccessFields_eventStartTime_Update"] | undefined,
	delete?: ModelTypes["RedemptionCodesDocAccessFields_eventStartTime_Delete"] | undefined
};
	["RedemptionCodesDocAccessFields_eventStartTime_Create"]: {
		permission: boolean
};
	["RedemptionCodesDocAccessFields_eventStartTime_Read"]: {
		permission: boolean
};
	["RedemptionCodesDocAccessFields_eventStartTime_Update"]: {
		permission: boolean
};
	["RedemptionCodesDocAccessFields_eventStartTime_Delete"]: {
		permission: boolean
};
	["RedemptionCodesDocAccessFields_eventEndTime"]: {
		create?: ModelTypes["RedemptionCodesDocAccessFields_eventEndTime_Create"] | undefined,
	read?: ModelTypes["RedemptionCodesDocAccessFields_eventEndTime_Read"] | undefined,
	update?: ModelTypes["RedemptionCodesDocAccessFields_eventEndTime_Update"] | undefined,
	delete?: ModelTypes["RedemptionCodesDocAccessFields_eventEndTime_Delete"] | undefined
};
	["RedemptionCodesDocAccessFields_eventEndTime_Create"]: {
		permission: boolean
};
	["RedemptionCodesDocAccessFields_eventEndTime_Read"]: {
		permission: boolean
};
	["RedemptionCodesDocAccessFields_eventEndTime_Update"]: {
		permission: boolean
};
	["RedemptionCodesDocAccessFields_eventEndTime_Delete"]: {
		permission: boolean
};
	["RedemptionCodesDocAccessFields_eventPriceZone"]: {
		create?: ModelTypes["RedemptionCodesDocAccessFields_eventPriceZone_Create"] | undefined,
	read?: ModelTypes["RedemptionCodesDocAccessFields_eventPriceZone_Read"] | undefined,
	update?: ModelTypes["RedemptionCodesDocAccessFields_eventPriceZone_Update"] | undefined,
	delete?: ModelTypes["RedemptionCodesDocAccessFields_eventPriceZone_Delete"] | undefined
};
	["RedemptionCodesDocAccessFields_eventPriceZone_Create"]: {
		permission: boolean
};
	["RedemptionCodesDocAccessFields_eventPriceZone_Read"]: {
		permission: boolean
};
	["RedemptionCodesDocAccessFields_eventPriceZone_Update"]: {
		permission: boolean
};
	["RedemptionCodesDocAccessFields_eventPriceZone_Delete"]: {
		permission: boolean
};
	["RedemptionCodesDocAccessFields_ownerName"]: {
		create?: ModelTypes["RedemptionCodesDocAccessFields_ownerName_Create"] | undefined,
	read?: ModelTypes["RedemptionCodesDocAccessFields_ownerName_Read"] | undefined,
	update?: ModelTypes["RedemptionCodesDocAccessFields_ownerName_Update"] | undefined,
	delete?: ModelTypes["RedemptionCodesDocAccessFields_ownerName_Delete"] | undefined
};
	["RedemptionCodesDocAccessFields_ownerName_Create"]: {
		permission: boolean
};
	["RedemptionCodesDocAccessFields_ownerName_Read"]: {
		permission: boolean
};
	["RedemptionCodesDocAccessFields_ownerName_Update"]: {
		permission: boolean
};
	["RedemptionCodesDocAccessFields_ownerName_Delete"]: {
		permission: boolean
};
	["RedemptionCodesDocAccessFields_phone"]: {
		create?: ModelTypes["RedemptionCodesDocAccessFields_phone_Create"] | undefined,
	read?: ModelTypes["RedemptionCodesDocAccessFields_phone_Read"] | undefined,
	update?: ModelTypes["RedemptionCodesDocAccessFields_phone_Update"] | undefined,
	delete?: ModelTypes["RedemptionCodesDocAccessFields_phone_Delete"] | undefined
};
	["RedemptionCodesDocAccessFields_phone_Create"]: {
		permission: boolean
};
	["RedemptionCodesDocAccessFields_phone_Read"]: {
		permission: boolean
};
	["RedemptionCodesDocAccessFields_phone_Update"]: {
		permission: boolean
};
	["RedemptionCodesDocAccessFields_phone_Delete"]: {
		permission: boolean
};
	["RedemptionCodesDocAccessFields_pdf"]: {
		create?: ModelTypes["RedemptionCodesDocAccessFields_pdf_Create"] | undefined,
	read?: ModelTypes["RedemptionCodesDocAccessFields_pdf_Read"] | undefined,
	update?: ModelTypes["RedemptionCodesDocAccessFields_pdf_Update"] | undefined,
	delete?: ModelTypes["RedemptionCodesDocAccessFields_pdf_Delete"] | undefined
};
	["RedemptionCodesDocAccessFields_pdf_Create"]: {
		permission: boolean
};
	["RedemptionCodesDocAccessFields_pdf_Read"]: {
		permission: boolean
};
	["RedemptionCodesDocAccessFields_pdf_Update"]: {
		permission: boolean
};
	["RedemptionCodesDocAccessFields_pdf_Delete"]: {
		permission: boolean
};
	["RedemptionCodesDocAccessFields_variants"]: {
		create?: ModelTypes["RedemptionCodesDocAccessFields_variants_Create"] | undefined,
	read?: ModelTypes["RedemptionCodesDocAccessFields_variants_Read"] | undefined,
	update?: ModelTypes["RedemptionCodesDocAccessFields_variants_Update"] | undefined,
	delete?: ModelTypes["RedemptionCodesDocAccessFields_variants_Delete"] | undefined,
	fields?: ModelTypes["RedemptionCodesDocAccessFields_variants_Fields"] | undefined
};
	["RedemptionCodesDocAccessFields_variants_Create"]: {
		permission: boolean
};
	["RedemptionCodesDocAccessFields_variants_Read"]: {
		permission: boolean
};
	["RedemptionCodesDocAccessFields_variants_Update"]: {
		permission: boolean
};
	["RedemptionCodesDocAccessFields_variants_Delete"]: {
		permission: boolean
};
	["RedemptionCodesDocAccessFields_variants_Fields"]: {
		variantId?: ModelTypes["RedemptionCodesDocAccessFields_variants_variantId"] | undefined,
	name?: ModelTypes["RedemptionCodesDocAccessFields_variants_name"] | undefined,
	quantity?: ModelTypes["RedemptionCodesDocAccessFields_variants_quantity"] | undefined,
	totalCost?: ModelTypes["RedemptionCodesDocAccessFields_variants_totalCost"] | undefined,
	id?: ModelTypes["RedemptionCodesDocAccessFields_variants_id"] | undefined
};
	["RedemptionCodesDocAccessFields_variants_variantId"]: {
		create?: ModelTypes["RedemptionCodesDocAccessFields_variants_variantId_Create"] | undefined,
	read?: ModelTypes["RedemptionCodesDocAccessFields_variants_variantId_Read"] | undefined,
	update?: ModelTypes["RedemptionCodesDocAccessFields_variants_variantId_Update"] | undefined,
	delete?: ModelTypes["RedemptionCodesDocAccessFields_variants_variantId_Delete"] | undefined
};
	["RedemptionCodesDocAccessFields_variants_variantId_Create"]: {
		permission: boolean
};
	["RedemptionCodesDocAccessFields_variants_variantId_Read"]: {
		permission: boolean
};
	["RedemptionCodesDocAccessFields_variants_variantId_Update"]: {
		permission: boolean
};
	["RedemptionCodesDocAccessFields_variants_variantId_Delete"]: {
		permission: boolean
};
	["RedemptionCodesDocAccessFields_variants_name"]: {
		create?: ModelTypes["RedemptionCodesDocAccessFields_variants_name_Create"] | undefined,
	read?: ModelTypes["RedemptionCodesDocAccessFields_variants_name_Read"] | undefined,
	update?: ModelTypes["RedemptionCodesDocAccessFields_variants_name_Update"] | undefined,
	delete?: ModelTypes["RedemptionCodesDocAccessFields_variants_name_Delete"] | undefined
};
	["RedemptionCodesDocAccessFields_variants_name_Create"]: {
		permission: boolean
};
	["RedemptionCodesDocAccessFields_variants_name_Read"]: {
		permission: boolean
};
	["RedemptionCodesDocAccessFields_variants_name_Update"]: {
		permission: boolean
};
	["RedemptionCodesDocAccessFields_variants_name_Delete"]: {
		permission: boolean
};
	["RedemptionCodesDocAccessFields_variants_quantity"]: {
		create?: ModelTypes["RedemptionCodesDocAccessFields_variants_quantity_Create"] | undefined,
	read?: ModelTypes["RedemptionCodesDocAccessFields_variants_quantity_Read"] | undefined,
	update?: ModelTypes["RedemptionCodesDocAccessFields_variants_quantity_Update"] | undefined,
	delete?: ModelTypes["RedemptionCodesDocAccessFields_variants_quantity_Delete"] | undefined
};
	["RedemptionCodesDocAccessFields_variants_quantity_Create"]: {
		permission: boolean
};
	["RedemptionCodesDocAccessFields_variants_quantity_Read"]: {
		permission: boolean
};
	["RedemptionCodesDocAccessFields_variants_quantity_Update"]: {
		permission: boolean
};
	["RedemptionCodesDocAccessFields_variants_quantity_Delete"]: {
		permission: boolean
};
	["RedemptionCodesDocAccessFields_variants_totalCost"]: {
		create?: ModelTypes["RedemptionCodesDocAccessFields_variants_totalCost_Create"] | undefined,
	read?: ModelTypes["RedemptionCodesDocAccessFields_variants_totalCost_Read"] | undefined,
	update?: ModelTypes["RedemptionCodesDocAccessFields_variants_totalCost_Update"] | undefined,
	delete?: ModelTypes["RedemptionCodesDocAccessFields_variants_totalCost_Delete"] | undefined
};
	["RedemptionCodesDocAccessFields_variants_totalCost_Create"]: {
		permission: boolean
};
	["RedemptionCodesDocAccessFields_variants_totalCost_Read"]: {
		permission: boolean
};
	["RedemptionCodesDocAccessFields_variants_totalCost_Update"]: {
		permission: boolean
};
	["RedemptionCodesDocAccessFields_variants_totalCost_Delete"]: {
		permission: boolean
};
	["RedemptionCodesDocAccessFields_variants_id"]: {
		create?: ModelTypes["RedemptionCodesDocAccessFields_variants_id_Create"] | undefined,
	read?: ModelTypes["RedemptionCodesDocAccessFields_variants_id_Read"] | undefined,
	update?: ModelTypes["RedemptionCodesDocAccessFields_variants_id_Update"] | undefined,
	delete?: ModelTypes["RedemptionCodesDocAccessFields_variants_id_Delete"] | undefined
};
	["RedemptionCodesDocAccessFields_variants_id_Create"]: {
		permission: boolean
};
	["RedemptionCodesDocAccessFields_variants_id_Read"]: {
		permission: boolean
};
	["RedemptionCodesDocAccessFields_variants_id_Update"]: {
		permission: boolean
};
	["RedemptionCodesDocAccessFields_variants_id_Delete"]: {
		permission: boolean
};
	["RedemptionCodesDocAccessFields_productId"]: {
		create?: ModelTypes["RedemptionCodesDocAccessFields_productId_Create"] | undefined,
	read?: ModelTypes["RedemptionCodesDocAccessFields_productId_Read"] | undefined,
	update?: ModelTypes["RedemptionCodesDocAccessFields_productId_Update"] | undefined,
	delete?: ModelTypes["RedemptionCodesDocAccessFields_productId_Delete"] | undefined
};
	["RedemptionCodesDocAccessFields_productId_Create"]: {
		permission: boolean
};
	["RedemptionCodesDocAccessFields_productId_Read"]: {
		permission: boolean
};
	["RedemptionCodesDocAccessFields_productId_Update"]: {
		permission: boolean
};
	["RedemptionCodesDocAccessFields_productId_Delete"]: {
		permission: boolean
};
	["RedemptionCodesDocAccessFields_referenceId"]: {
		create?: ModelTypes["RedemptionCodesDocAccessFields_referenceId_Create"] | undefined,
	read?: ModelTypes["RedemptionCodesDocAccessFields_referenceId_Read"] | undefined,
	update?: ModelTypes["RedemptionCodesDocAccessFields_referenceId_Update"] | undefined,
	delete?: ModelTypes["RedemptionCodesDocAccessFields_referenceId_Delete"] | undefined
};
	["RedemptionCodesDocAccessFields_referenceId_Create"]: {
		permission: boolean
};
	["RedemptionCodesDocAccessFields_referenceId_Read"]: {
		permission: boolean
};
	["RedemptionCodesDocAccessFields_referenceId_Update"]: {
		permission: boolean
};
	["RedemptionCodesDocAccessFields_referenceId_Delete"]: {
		permission: boolean
};
	["RedemptionCodesDocAccessFields_productType"]: {
		create?: ModelTypes["RedemptionCodesDocAccessFields_productType_Create"] | undefined,
	read?: ModelTypes["RedemptionCodesDocAccessFields_productType_Read"] | undefined,
	update?: ModelTypes["RedemptionCodesDocAccessFields_productType_Update"] | undefined,
	delete?: ModelTypes["RedemptionCodesDocAccessFields_productType_Delete"] | undefined
};
	["RedemptionCodesDocAccessFields_productType_Create"]: {
		permission: boolean
};
	["RedemptionCodesDocAccessFields_productType_Read"]: {
		permission: boolean
};
	["RedemptionCodesDocAccessFields_productType_Update"]: {
		permission: boolean
};
	["RedemptionCodesDocAccessFields_productType_Delete"]: {
		permission: boolean
};
	["RedemptionCodesDocAccessFields_productName"]: {
		create?: ModelTypes["RedemptionCodesDocAccessFields_productName_Create"] | undefined,
	read?: ModelTypes["RedemptionCodesDocAccessFields_productName_Read"] | undefined,
	update?: ModelTypes["RedemptionCodesDocAccessFields_productName_Update"] | undefined,
	delete?: ModelTypes["RedemptionCodesDocAccessFields_productName_Delete"] | undefined
};
	["RedemptionCodesDocAccessFields_productName_Create"]: {
		permission: boolean
};
	["RedemptionCodesDocAccessFields_productName_Read"]: {
		permission: boolean
};
	["RedemptionCodesDocAccessFields_productName_Update"]: {
		permission: boolean
};
	["RedemptionCodesDocAccessFields_productName_Delete"]: {
		permission: boolean
};
	["RedemptionCodesDocAccessFields_linkedEvent"]: {
		create?: ModelTypes["RedemptionCodesDocAccessFields_linkedEvent_Create"] | undefined,
	read?: ModelTypes["RedemptionCodesDocAccessFields_linkedEvent_Read"] | undefined,
	update?: ModelTypes["RedemptionCodesDocAccessFields_linkedEvent_Update"] | undefined,
	delete?: ModelTypes["RedemptionCodesDocAccessFields_linkedEvent_Delete"] | undefined
};
	["RedemptionCodesDocAccessFields_linkedEvent_Create"]: {
		permission: boolean
};
	["RedemptionCodesDocAccessFields_linkedEvent_Read"]: {
		permission: boolean
};
	["RedemptionCodesDocAccessFields_linkedEvent_Update"]: {
		permission: boolean
};
	["RedemptionCodesDocAccessFields_linkedEvent_Delete"]: {
		permission: boolean
};
	["RedemptionCodesDocAccessFields_timableOrderId"]: {
		create?: ModelTypes["RedemptionCodesDocAccessFields_timableOrderId_Create"] | undefined,
	read?: ModelTypes["RedemptionCodesDocAccessFields_timableOrderId_Read"] | undefined,
	update?: ModelTypes["RedemptionCodesDocAccessFields_timableOrderId_Update"] | undefined,
	delete?: ModelTypes["RedemptionCodesDocAccessFields_timableOrderId_Delete"] | undefined
};
	["RedemptionCodesDocAccessFields_timableOrderId_Create"]: {
		permission: boolean
};
	["RedemptionCodesDocAccessFields_timableOrderId_Read"]: {
		permission: boolean
};
	["RedemptionCodesDocAccessFields_timableOrderId_Update"]: {
		permission: boolean
};
	["RedemptionCodesDocAccessFields_timableOrderId_Delete"]: {
		permission: boolean
};
	["RedemptionCodesDocAccessFields_purchaseDateTime"]: {
		create?: ModelTypes["RedemptionCodesDocAccessFields_purchaseDateTime_Create"] | undefined,
	read?: ModelTypes["RedemptionCodesDocAccessFields_purchaseDateTime_Read"] | undefined,
	update?: ModelTypes["RedemptionCodesDocAccessFields_purchaseDateTime_Update"] | undefined,
	delete?: ModelTypes["RedemptionCodesDocAccessFields_purchaseDateTime_Delete"] | undefined
};
	["RedemptionCodesDocAccessFields_purchaseDateTime_Create"]: {
		permission: boolean
};
	["RedemptionCodesDocAccessFields_purchaseDateTime_Read"]: {
		permission: boolean
};
	["RedemptionCodesDocAccessFields_purchaseDateTime_Update"]: {
		permission: boolean
};
	["RedemptionCodesDocAccessFields_purchaseDateTime_Delete"]: {
		permission: boolean
};
	["RedemptionCodesDocAccessFields_changeHistory"]: {
		create?: ModelTypes["RedemptionCodesDocAccessFields_changeHistory_Create"] | undefined,
	read?: ModelTypes["RedemptionCodesDocAccessFields_changeHistory_Read"] | undefined,
	update?: ModelTypes["RedemptionCodesDocAccessFields_changeHistory_Update"] | undefined,
	delete?: ModelTypes["RedemptionCodesDocAccessFields_changeHistory_Delete"] | undefined,
	fields?: ModelTypes["RedemptionCodesDocAccessFields_changeHistory_Fields"] | undefined
};
	["RedemptionCodesDocAccessFields_changeHistory_Create"]: {
		permission: boolean
};
	["RedemptionCodesDocAccessFields_changeHistory_Read"]: {
		permission: boolean
};
	["RedemptionCodesDocAccessFields_changeHistory_Update"]: {
		permission: boolean
};
	["RedemptionCodesDocAccessFields_changeHistory_Delete"]: {
		permission: boolean
};
	["RedemptionCodesDocAccessFields_changeHistory_Fields"]: {
		timestamp?: ModelTypes["RedemptionCodesDocAccessFields_changeHistory_timestamp"] | undefined,
	field?: ModelTypes["RedemptionCodesDocAccessFields_changeHistory_field"] | undefined,
	oldValue?: ModelTypes["RedemptionCodesDocAccessFields_changeHistory_oldValue"] | undefined,
	newValue?: ModelTypes["RedemptionCodesDocAccessFields_changeHistory_newValue"] | undefined,
	id?: ModelTypes["RedemptionCodesDocAccessFields_changeHistory_id"] | undefined
};
	["RedemptionCodesDocAccessFields_changeHistory_timestamp"]: {
		create?: ModelTypes["RedemptionCodesDocAccessFields_changeHistory_timestamp_Create"] | undefined,
	read?: ModelTypes["RedemptionCodesDocAccessFields_changeHistory_timestamp_Read"] | undefined,
	update?: ModelTypes["RedemptionCodesDocAccessFields_changeHistory_timestamp_Update"] | undefined,
	delete?: ModelTypes["RedemptionCodesDocAccessFields_changeHistory_timestamp_Delete"] | undefined
};
	["RedemptionCodesDocAccessFields_changeHistory_timestamp_Create"]: {
		permission: boolean
};
	["RedemptionCodesDocAccessFields_changeHistory_timestamp_Read"]: {
		permission: boolean
};
	["RedemptionCodesDocAccessFields_changeHistory_timestamp_Update"]: {
		permission: boolean
};
	["RedemptionCodesDocAccessFields_changeHistory_timestamp_Delete"]: {
		permission: boolean
};
	["RedemptionCodesDocAccessFields_changeHistory_field"]: {
		create?: ModelTypes["RedemptionCodesDocAccessFields_changeHistory_field_Create"] | undefined,
	read?: ModelTypes["RedemptionCodesDocAccessFields_changeHistory_field_Read"] | undefined,
	update?: ModelTypes["RedemptionCodesDocAccessFields_changeHistory_field_Update"] | undefined,
	delete?: ModelTypes["RedemptionCodesDocAccessFields_changeHistory_field_Delete"] | undefined
};
	["RedemptionCodesDocAccessFields_changeHistory_field_Create"]: {
		permission: boolean
};
	["RedemptionCodesDocAccessFields_changeHistory_field_Read"]: {
		permission: boolean
};
	["RedemptionCodesDocAccessFields_changeHistory_field_Update"]: {
		permission: boolean
};
	["RedemptionCodesDocAccessFields_changeHistory_field_Delete"]: {
		permission: boolean
};
	["RedemptionCodesDocAccessFields_changeHistory_oldValue"]: {
		create?: ModelTypes["RedemptionCodesDocAccessFields_changeHistory_oldValue_Create"] | undefined,
	read?: ModelTypes["RedemptionCodesDocAccessFields_changeHistory_oldValue_Read"] | undefined,
	update?: ModelTypes["RedemptionCodesDocAccessFields_changeHistory_oldValue_Update"] | undefined,
	delete?: ModelTypes["RedemptionCodesDocAccessFields_changeHistory_oldValue_Delete"] | undefined
};
	["RedemptionCodesDocAccessFields_changeHistory_oldValue_Create"]: {
		permission: boolean
};
	["RedemptionCodesDocAccessFields_changeHistory_oldValue_Read"]: {
		permission: boolean
};
	["RedemptionCodesDocAccessFields_changeHistory_oldValue_Update"]: {
		permission: boolean
};
	["RedemptionCodesDocAccessFields_changeHistory_oldValue_Delete"]: {
		permission: boolean
};
	["RedemptionCodesDocAccessFields_changeHistory_newValue"]: {
		create?: ModelTypes["RedemptionCodesDocAccessFields_changeHistory_newValue_Create"] | undefined,
	read?: ModelTypes["RedemptionCodesDocAccessFields_changeHistory_newValue_Read"] | undefined,
	update?: ModelTypes["RedemptionCodesDocAccessFields_changeHistory_newValue_Update"] | undefined,
	delete?: ModelTypes["RedemptionCodesDocAccessFields_changeHistory_newValue_Delete"] | undefined
};
	["RedemptionCodesDocAccessFields_changeHistory_newValue_Create"]: {
		permission: boolean
};
	["RedemptionCodesDocAccessFields_changeHistory_newValue_Read"]: {
		permission: boolean
};
	["RedemptionCodesDocAccessFields_changeHistory_newValue_Update"]: {
		permission: boolean
};
	["RedemptionCodesDocAccessFields_changeHistory_newValue_Delete"]: {
		permission: boolean
};
	["RedemptionCodesDocAccessFields_changeHistory_id"]: {
		create?: ModelTypes["RedemptionCodesDocAccessFields_changeHistory_id_Create"] | undefined,
	read?: ModelTypes["RedemptionCodesDocAccessFields_changeHistory_id_Read"] | undefined,
	update?: ModelTypes["RedemptionCodesDocAccessFields_changeHistory_id_Update"] | undefined,
	delete?: ModelTypes["RedemptionCodesDocAccessFields_changeHistory_id_Delete"] | undefined
};
	["RedemptionCodesDocAccessFields_changeHistory_id_Create"]: {
		permission: boolean
};
	["RedemptionCodesDocAccessFields_changeHistory_id_Read"]: {
		permission: boolean
};
	["RedemptionCodesDocAccessFields_changeHistory_id_Update"]: {
		permission: boolean
};
	["RedemptionCodesDocAccessFields_changeHistory_id_Delete"]: {
		permission: boolean
};
	["RedemptionCodesDocAccessFields_updatedAt"]: {
		create?: ModelTypes["RedemptionCodesDocAccessFields_updatedAt_Create"] | undefined,
	read?: ModelTypes["RedemptionCodesDocAccessFields_updatedAt_Read"] | undefined,
	update?: ModelTypes["RedemptionCodesDocAccessFields_updatedAt_Update"] | undefined,
	delete?: ModelTypes["RedemptionCodesDocAccessFields_updatedAt_Delete"] | undefined
};
	["RedemptionCodesDocAccessFields_updatedAt_Create"]: {
		permission: boolean
};
	["RedemptionCodesDocAccessFields_updatedAt_Read"]: {
		permission: boolean
};
	["RedemptionCodesDocAccessFields_updatedAt_Update"]: {
		permission: boolean
};
	["RedemptionCodesDocAccessFields_updatedAt_Delete"]: {
		permission: boolean
};
	["RedemptionCodesDocAccessFields_createdAt"]: {
		create?: ModelTypes["RedemptionCodesDocAccessFields_createdAt_Create"] | undefined,
	read?: ModelTypes["RedemptionCodesDocAccessFields_createdAt_Read"] | undefined,
	update?: ModelTypes["RedemptionCodesDocAccessFields_createdAt_Update"] | undefined,
	delete?: ModelTypes["RedemptionCodesDocAccessFields_createdAt_Delete"] | undefined
};
	["RedemptionCodesDocAccessFields_createdAt_Create"]: {
		permission: boolean
};
	["RedemptionCodesDocAccessFields_createdAt_Read"]: {
		permission: boolean
};
	["RedemptionCodesDocAccessFields_createdAt_Update"]: {
		permission: boolean
};
	["RedemptionCodesDocAccessFields_createdAt_Delete"]: {
		permission: boolean
};
	["RedemptionCodesCreateDocAccess"]: {
		permission: boolean,
	where?: ModelTypes["JSONObject"] | undefined
};
	["RedemptionCodesReadDocAccess"]: {
		permission: boolean,
	where?: ModelTypes["JSONObject"] | undefined
};
	["RedemptionCodesUpdateDocAccess"]: {
		permission: boolean,
	where?: ModelTypes["JSONObject"] | undefined
};
	["RedemptionCodesDeleteDocAccess"]: {
		permission: boolean,
	where?: ModelTypes["JSONObject"] | undefined
};
	["RedemptionPdfs"]: {
		docs?: Array<ModelTypes["RedemptionPdf"] | undefined> | undefined,
	hasNextPage?: boolean | undefined,
	hasPrevPage?: boolean | undefined,
	limit?: number | undefined,
	nextPage?: number | undefined,
	offset?: number | undefined,
	page?: number | undefined,
	pagingCounter?: number | undefined,
	prevPage?: number | undefined,
	totalDocs?: number | undefined,
	totalPages?: number | undefined
};
	["RedemptionPdf_where"]: {
	updatedAt?: ModelTypes["RedemptionPdf_updatedAt_operator"] | undefined,
	createdAt?: ModelTypes["RedemptionPdf_createdAt_operator"] | undefined,
	url?: ModelTypes["RedemptionPdf_url_operator"] | undefined,
	filename?: ModelTypes["RedemptionPdf_filename_operator"] | undefined,
	mimeType?: ModelTypes["RedemptionPdf_mimeType_operator"] | undefined,
	filesize?: ModelTypes["RedemptionPdf_filesize_operator"] | undefined,
	width?: ModelTypes["RedemptionPdf_width_operator"] | undefined,
	height?: ModelTypes["RedemptionPdf_height_operator"] | undefined,
	focalX?: ModelTypes["RedemptionPdf_focalX_operator"] | undefined,
	focalY?: ModelTypes["RedemptionPdf_focalY_operator"] | undefined,
	id?: ModelTypes["RedemptionPdf_id_operator"] | undefined,
	AND?: Array<ModelTypes["RedemptionPdf_where_and"] | undefined> | undefined,
	OR?: Array<ModelTypes["RedemptionPdf_where_or"] | undefined> | undefined
};
	["RedemptionPdf_updatedAt_operator"]: {
	equals?: ModelTypes["DateTime"] | undefined,
	not_equals?: ModelTypes["DateTime"] | undefined,
	greater_than_equal?: ModelTypes["DateTime"] | undefined,
	greater_than?: ModelTypes["DateTime"] | undefined,
	less_than_equal?: ModelTypes["DateTime"] | undefined,
	less_than?: ModelTypes["DateTime"] | undefined,
	like?: ModelTypes["DateTime"] | undefined,
	exists?: boolean | undefined
};
	["RedemptionPdf_createdAt_operator"]: {
	equals?: ModelTypes["DateTime"] | undefined,
	not_equals?: ModelTypes["DateTime"] | undefined,
	greater_than_equal?: ModelTypes["DateTime"] | undefined,
	greater_than?: ModelTypes["DateTime"] | undefined,
	less_than_equal?: ModelTypes["DateTime"] | undefined,
	less_than?: ModelTypes["DateTime"] | undefined,
	like?: ModelTypes["DateTime"] | undefined,
	exists?: boolean | undefined
};
	["RedemptionPdf_url_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["RedemptionPdf_filename_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["RedemptionPdf_mimeType_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["RedemptionPdf_filesize_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["RedemptionPdf_width_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["RedemptionPdf_height_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["RedemptionPdf_focalX_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["RedemptionPdf_focalY_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["RedemptionPdf_id_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["RedemptionPdf_where_and"]: {
	updatedAt?: ModelTypes["RedemptionPdf_updatedAt_operator"] | undefined,
	createdAt?: ModelTypes["RedemptionPdf_createdAt_operator"] | undefined,
	url?: ModelTypes["RedemptionPdf_url_operator"] | undefined,
	filename?: ModelTypes["RedemptionPdf_filename_operator"] | undefined,
	mimeType?: ModelTypes["RedemptionPdf_mimeType_operator"] | undefined,
	filesize?: ModelTypes["RedemptionPdf_filesize_operator"] | undefined,
	width?: ModelTypes["RedemptionPdf_width_operator"] | undefined,
	height?: ModelTypes["RedemptionPdf_height_operator"] | undefined,
	focalX?: ModelTypes["RedemptionPdf_focalX_operator"] | undefined,
	focalY?: ModelTypes["RedemptionPdf_focalY_operator"] | undefined,
	id?: ModelTypes["RedemptionPdf_id_operator"] | undefined,
	AND?: Array<ModelTypes["RedemptionPdf_where_and"] | undefined> | undefined,
	OR?: Array<ModelTypes["RedemptionPdf_where_or"] | undefined> | undefined
};
	["RedemptionPdf_where_or"]: {
	updatedAt?: ModelTypes["RedemptionPdf_updatedAt_operator"] | undefined,
	createdAt?: ModelTypes["RedemptionPdf_createdAt_operator"] | undefined,
	url?: ModelTypes["RedemptionPdf_url_operator"] | undefined,
	filename?: ModelTypes["RedemptionPdf_filename_operator"] | undefined,
	mimeType?: ModelTypes["RedemptionPdf_mimeType_operator"] | undefined,
	filesize?: ModelTypes["RedemptionPdf_filesize_operator"] | undefined,
	width?: ModelTypes["RedemptionPdf_width_operator"] | undefined,
	height?: ModelTypes["RedemptionPdf_height_operator"] | undefined,
	focalX?: ModelTypes["RedemptionPdf_focalX_operator"] | undefined,
	focalY?: ModelTypes["RedemptionPdf_focalY_operator"] | undefined,
	id?: ModelTypes["RedemptionPdf_id_operator"] | undefined,
	AND?: Array<ModelTypes["RedemptionPdf_where_and"] | undefined> | undefined,
	OR?: Array<ModelTypes["RedemptionPdf_where_or"] | undefined> | undefined
};
	["countRedemptionPdfs"]: {
		totalDocs?: number | undefined
};
	["redemption_pdfDocAccess"]: {
		fields?: ModelTypes["RedemptionPdfDocAccessFields"] | undefined,
	create?: ModelTypes["RedemptionPdfCreateDocAccess"] | undefined,
	read?: ModelTypes["RedemptionPdfReadDocAccess"] | undefined,
	update?: ModelTypes["RedemptionPdfUpdateDocAccess"] | undefined,
	delete?: ModelTypes["RedemptionPdfDeleteDocAccess"] | undefined
};
	["RedemptionPdfDocAccessFields"]: {
		updatedAt?: ModelTypes["RedemptionPdfDocAccessFields_updatedAt"] | undefined,
	createdAt?: ModelTypes["RedemptionPdfDocAccessFields_createdAt"] | undefined,
	url?: ModelTypes["RedemptionPdfDocAccessFields_url"] | undefined,
	filename?: ModelTypes["RedemptionPdfDocAccessFields_filename"] | undefined,
	mimeType?: ModelTypes["RedemptionPdfDocAccessFields_mimeType"] | undefined,
	filesize?: ModelTypes["RedemptionPdfDocAccessFields_filesize"] | undefined,
	width?: ModelTypes["RedemptionPdfDocAccessFields_width"] | undefined,
	height?: ModelTypes["RedemptionPdfDocAccessFields_height"] | undefined,
	focalX?: ModelTypes["RedemptionPdfDocAccessFields_focalX"] | undefined,
	focalY?: ModelTypes["RedemptionPdfDocAccessFields_focalY"] | undefined
};
	["RedemptionPdfDocAccessFields_updatedAt"]: {
		create?: ModelTypes["RedemptionPdfDocAccessFields_updatedAt_Create"] | undefined,
	read?: ModelTypes["RedemptionPdfDocAccessFields_updatedAt_Read"] | undefined,
	update?: ModelTypes["RedemptionPdfDocAccessFields_updatedAt_Update"] | undefined,
	delete?: ModelTypes["RedemptionPdfDocAccessFields_updatedAt_Delete"] | undefined
};
	["RedemptionPdfDocAccessFields_updatedAt_Create"]: {
		permission: boolean
};
	["RedemptionPdfDocAccessFields_updatedAt_Read"]: {
		permission: boolean
};
	["RedemptionPdfDocAccessFields_updatedAt_Update"]: {
		permission: boolean
};
	["RedemptionPdfDocAccessFields_updatedAt_Delete"]: {
		permission: boolean
};
	["RedemptionPdfDocAccessFields_createdAt"]: {
		create?: ModelTypes["RedemptionPdfDocAccessFields_createdAt_Create"] | undefined,
	read?: ModelTypes["RedemptionPdfDocAccessFields_createdAt_Read"] | undefined,
	update?: ModelTypes["RedemptionPdfDocAccessFields_createdAt_Update"] | undefined,
	delete?: ModelTypes["RedemptionPdfDocAccessFields_createdAt_Delete"] | undefined
};
	["RedemptionPdfDocAccessFields_createdAt_Create"]: {
		permission: boolean
};
	["RedemptionPdfDocAccessFields_createdAt_Read"]: {
		permission: boolean
};
	["RedemptionPdfDocAccessFields_createdAt_Update"]: {
		permission: boolean
};
	["RedemptionPdfDocAccessFields_createdAt_Delete"]: {
		permission: boolean
};
	["RedemptionPdfDocAccessFields_url"]: {
		create?: ModelTypes["RedemptionPdfDocAccessFields_url_Create"] | undefined,
	read?: ModelTypes["RedemptionPdfDocAccessFields_url_Read"] | undefined,
	update?: ModelTypes["RedemptionPdfDocAccessFields_url_Update"] | undefined,
	delete?: ModelTypes["RedemptionPdfDocAccessFields_url_Delete"] | undefined
};
	["RedemptionPdfDocAccessFields_url_Create"]: {
		permission: boolean
};
	["RedemptionPdfDocAccessFields_url_Read"]: {
		permission: boolean
};
	["RedemptionPdfDocAccessFields_url_Update"]: {
		permission: boolean
};
	["RedemptionPdfDocAccessFields_url_Delete"]: {
		permission: boolean
};
	["RedemptionPdfDocAccessFields_filename"]: {
		create?: ModelTypes["RedemptionPdfDocAccessFields_filename_Create"] | undefined,
	read?: ModelTypes["RedemptionPdfDocAccessFields_filename_Read"] | undefined,
	update?: ModelTypes["RedemptionPdfDocAccessFields_filename_Update"] | undefined,
	delete?: ModelTypes["RedemptionPdfDocAccessFields_filename_Delete"] | undefined
};
	["RedemptionPdfDocAccessFields_filename_Create"]: {
		permission: boolean
};
	["RedemptionPdfDocAccessFields_filename_Read"]: {
		permission: boolean
};
	["RedemptionPdfDocAccessFields_filename_Update"]: {
		permission: boolean
};
	["RedemptionPdfDocAccessFields_filename_Delete"]: {
		permission: boolean
};
	["RedemptionPdfDocAccessFields_mimeType"]: {
		create?: ModelTypes["RedemptionPdfDocAccessFields_mimeType_Create"] | undefined,
	read?: ModelTypes["RedemptionPdfDocAccessFields_mimeType_Read"] | undefined,
	update?: ModelTypes["RedemptionPdfDocAccessFields_mimeType_Update"] | undefined,
	delete?: ModelTypes["RedemptionPdfDocAccessFields_mimeType_Delete"] | undefined
};
	["RedemptionPdfDocAccessFields_mimeType_Create"]: {
		permission: boolean
};
	["RedemptionPdfDocAccessFields_mimeType_Read"]: {
		permission: boolean
};
	["RedemptionPdfDocAccessFields_mimeType_Update"]: {
		permission: boolean
};
	["RedemptionPdfDocAccessFields_mimeType_Delete"]: {
		permission: boolean
};
	["RedemptionPdfDocAccessFields_filesize"]: {
		create?: ModelTypes["RedemptionPdfDocAccessFields_filesize_Create"] | undefined,
	read?: ModelTypes["RedemptionPdfDocAccessFields_filesize_Read"] | undefined,
	update?: ModelTypes["RedemptionPdfDocAccessFields_filesize_Update"] | undefined,
	delete?: ModelTypes["RedemptionPdfDocAccessFields_filesize_Delete"] | undefined
};
	["RedemptionPdfDocAccessFields_filesize_Create"]: {
		permission: boolean
};
	["RedemptionPdfDocAccessFields_filesize_Read"]: {
		permission: boolean
};
	["RedemptionPdfDocAccessFields_filesize_Update"]: {
		permission: boolean
};
	["RedemptionPdfDocAccessFields_filesize_Delete"]: {
		permission: boolean
};
	["RedemptionPdfDocAccessFields_width"]: {
		create?: ModelTypes["RedemptionPdfDocAccessFields_width_Create"] | undefined,
	read?: ModelTypes["RedemptionPdfDocAccessFields_width_Read"] | undefined,
	update?: ModelTypes["RedemptionPdfDocAccessFields_width_Update"] | undefined,
	delete?: ModelTypes["RedemptionPdfDocAccessFields_width_Delete"] | undefined
};
	["RedemptionPdfDocAccessFields_width_Create"]: {
		permission: boolean
};
	["RedemptionPdfDocAccessFields_width_Read"]: {
		permission: boolean
};
	["RedemptionPdfDocAccessFields_width_Update"]: {
		permission: boolean
};
	["RedemptionPdfDocAccessFields_width_Delete"]: {
		permission: boolean
};
	["RedemptionPdfDocAccessFields_height"]: {
		create?: ModelTypes["RedemptionPdfDocAccessFields_height_Create"] | undefined,
	read?: ModelTypes["RedemptionPdfDocAccessFields_height_Read"] | undefined,
	update?: ModelTypes["RedemptionPdfDocAccessFields_height_Update"] | undefined,
	delete?: ModelTypes["RedemptionPdfDocAccessFields_height_Delete"] | undefined
};
	["RedemptionPdfDocAccessFields_height_Create"]: {
		permission: boolean
};
	["RedemptionPdfDocAccessFields_height_Read"]: {
		permission: boolean
};
	["RedemptionPdfDocAccessFields_height_Update"]: {
		permission: boolean
};
	["RedemptionPdfDocAccessFields_height_Delete"]: {
		permission: boolean
};
	["RedemptionPdfDocAccessFields_focalX"]: {
		create?: ModelTypes["RedemptionPdfDocAccessFields_focalX_Create"] | undefined,
	read?: ModelTypes["RedemptionPdfDocAccessFields_focalX_Read"] | undefined,
	update?: ModelTypes["RedemptionPdfDocAccessFields_focalX_Update"] | undefined,
	delete?: ModelTypes["RedemptionPdfDocAccessFields_focalX_Delete"] | undefined
};
	["RedemptionPdfDocAccessFields_focalX_Create"]: {
		permission: boolean
};
	["RedemptionPdfDocAccessFields_focalX_Read"]: {
		permission: boolean
};
	["RedemptionPdfDocAccessFields_focalX_Update"]: {
		permission: boolean
};
	["RedemptionPdfDocAccessFields_focalX_Delete"]: {
		permission: boolean
};
	["RedemptionPdfDocAccessFields_focalY"]: {
		create?: ModelTypes["RedemptionPdfDocAccessFields_focalY_Create"] | undefined,
	read?: ModelTypes["RedemptionPdfDocAccessFields_focalY_Read"] | undefined,
	update?: ModelTypes["RedemptionPdfDocAccessFields_focalY_Update"] | undefined,
	delete?: ModelTypes["RedemptionPdfDocAccessFields_focalY_Delete"] | undefined
};
	["RedemptionPdfDocAccessFields_focalY_Create"]: {
		permission: boolean
};
	["RedemptionPdfDocAccessFields_focalY_Read"]: {
		permission: boolean
};
	["RedemptionPdfDocAccessFields_focalY_Update"]: {
		permission: boolean
};
	["RedemptionPdfDocAccessFields_focalY_Delete"]: {
		permission: boolean
};
	["RedemptionPdfCreateDocAccess"]: {
		permission: boolean,
	where?: ModelTypes["JSONObject"] | undefined
};
	["RedemptionPdfReadDocAccess"]: {
		permission: boolean,
	where?: ModelTypes["JSONObject"] | undefined
};
	["RedemptionPdfUpdateDocAccess"]: {
		permission: boolean,
	where?: ModelTypes["JSONObject"] | undefined
};
	["RedemptionPdfDeleteDocAccess"]: {
		permission: boolean,
	where?: ModelTypes["JSONObject"] | undefined
};
	["CheckInRedemption"]: {
		id?: string | undefined,
	creator?: ModelTypes["User"] | undefined,
	qrCodeId?: string | undefined,
	codeownerName?: string | undefined,
	name?: string | undefined,
	phone?: string | undefined,
	linkedEvent?: ModelTypes["Event"] | undefined,
	entryNumber?: string | undefined,
	scanAt?: ModelTypes["DateTime"] | undefined,
	uploadAt?: ModelTypes["DateTime"] | undefined,
	status?: string | undefined,
	failReason?: string | undefined,
	variants?: Array<ModelTypes["CheckInRedemption_Variants"]> | undefined,
	checkInAppSelectionId?: string | undefined,
	qrCodeStatus?: string | undefined,
	productType?: ModelTypes["CheckInRedemption_productType"] | undefined,
	updatedAt?: ModelTypes["DateTime"] | undefined,
	createdAt?: ModelTypes["DateTime"] | undefined
};
	["CheckInRedemption_Variants"]: {
		variantId?: string | undefined,
	name?: string | undefined,
	quantity?: string | undefined,
	totalCost?: string | undefined,
	id?: string | undefined
};
	["CheckInRedemption_productType"]:CheckInRedemption_productType;
	["CheckInRedemptions"]: {
		docs?: Array<ModelTypes["CheckInRedemption"] | undefined> | undefined,
	hasNextPage?: boolean | undefined,
	hasPrevPage?: boolean | undefined,
	limit?: number | undefined,
	nextPage?: number | undefined,
	offset?: number | undefined,
	page?: number | undefined,
	pagingCounter?: number | undefined,
	prevPage?: number | undefined,
	totalDocs?: number | undefined,
	totalPages?: number | undefined
};
	["CheckInRedemption_where"]: {
	creator?: ModelTypes["CheckInRedemption_creator_operator"] | undefined,
	qrCodeId?: ModelTypes["CheckInRedemption_qrCodeId_operator"] | undefined,
	codeownerName?: ModelTypes["CheckInRedemption_codeownerName_operator"] | undefined,
	name?: ModelTypes["CheckInRedemption_name_operator"] | undefined,
	phone?: ModelTypes["CheckInRedemption_phone_operator"] | undefined,
	linkedEvent?: ModelTypes["CheckInRedemption_linkedEvent_operator"] | undefined,
	entryNumber?: ModelTypes["CheckInRedemption_entryNumber_operator"] | undefined,
	scanAt?: ModelTypes["CheckInRedemption_scanAt_operator"] | undefined,
	uploadAt?: ModelTypes["CheckInRedemption_uploadAt_operator"] | undefined,
	status?: ModelTypes["CheckInRedemption_status_operator"] | undefined,
	failReason?: ModelTypes["CheckInRedemption_failReason_operator"] | undefined,
	variants__variantId?: ModelTypes["CheckInRedemption_variants__variantId_operator"] | undefined,
	variants__name?: ModelTypes["CheckInRedemption_variants__name_operator"] | undefined,
	variants__quantity?: ModelTypes["CheckInRedemption_variants__quantity_operator"] | undefined,
	variants__totalCost?: ModelTypes["CheckInRedemption_variants__totalCost_operator"] | undefined,
	variants__id?: ModelTypes["CheckInRedemption_variants__id_operator"] | undefined,
	checkInAppSelectionId?: ModelTypes["CheckInRedemption_checkInAppSelectionId_operator"] | undefined,
	qrCodeStatus?: ModelTypes["CheckInRedemption_qrCodeStatus_operator"] | undefined,
	productType?: ModelTypes["CheckInRedemption_productType_operator"] | undefined,
	updatedAt?: ModelTypes["CheckInRedemption_updatedAt_operator"] | undefined,
	createdAt?: ModelTypes["CheckInRedemption_createdAt_operator"] | undefined,
	id?: ModelTypes["CheckInRedemption_id_operator"] | undefined,
	AND?: Array<ModelTypes["CheckInRedemption_where_and"] | undefined> | undefined,
	OR?: Array<ModelTypes["CheckInRedemption_where_or"] | undefined> | undefined
};
	["CheckInRedemption_creator_operator"]: {
	equals?: ModelTypes["JSON"] | undefined,
	not_equals?: ModelTypes["JSON"] | undefined,
	in?: Array<ModelTypes["JSON"] | undefined> | undefined,
	not_in?: Array<ModelTypes["JSON"] | undefined> | undefined,
	all?: Array<ModelTypes["JSON"] | undefined> | undefined,
	exists?: boolean | undefined
};
	["CheckInRedemption_qrCodeId_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["CheckInRedemption_codeownerName_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["CheckInRedemption_name_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["CheckInRedemption_phone_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["CheckInRedemption_linkedEvent_operator"]: {
	equals?: ModelTypes["JSON"] | undefined,
	not_equals?: ModelTypes["JSON"] | undefined,
	in?: Array<ModelTypes["JSON"] | undefined> | undefined,
	not_in?: Array<ModelTypes["JSON"] | undefined> | undefined,
	all?: Array<ModelTypes["JSON"] | undefined> | undefined,
	exists?: boolean | undefined
};
	["CheckInRedemption_entryNumber_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["CheckInRedemption_scanAt_operator"]: {
	equals?: ModelTypes["DateTime"] | undefined,
	not_equals?: ModelTypes["DateTime"] | undefined,
	greater_than_equal?: ModelTypes["DateTime"] | undefined,
	greater_than?: ModelTypes["DateTime"] | undefined,
	less_than_equal?: ModelTypes["DateTime"] | undefined,
	less_than?: ModelTypes["DateTime"] | undefined,
	like?: ModelTypes["DateTime"] | undefined,
	exists?: boolean | undefined
};
	["CheckInRedemption_uploadAt_operator"]: {
	equals?: ModelTypes["DateTime"] | undefined,
	not_equals?: ModelTypes["DateTime"] | undefined,
	greater_than_equal?: ModelTypes["DateTime"] | undefined,
	greater_than?: ModelTypes["DateTime"] | undefined,
	less_than_equal?: ModelTypes["DateTime"] | undefined,
	less_than?: ModelTypes["DateTime"] | undefined,
	like?: ModelTypes["DateTime"] | undefined,
	exists?: boolean | undefined
};
	["CheckInRedemption_status_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["CheckInRedemption_failReason_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["CheckInRedemption_variants__variantId_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["CheckInRedemption_variants__name_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["CheckInRedemption_variants__quantity_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["CheckInRedemption_variants__totalCost_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["CheckInRedemption_variants__id_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["CheckInRedemption_checkInAppSelectionId_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["CheckInRedemption_qrCodeStatus_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["CheckInRedemption_productType_operator"]: {
	equals?: ModelTypes["CheckInRedemption_productType_Input"] | undefined,
	not_equals?: ModelTypes["CheckInRedemption_productType_Input"] | undefined,
	like?: ModelTypes["CheckInRedemption_productType_Input"] | undefined,
	contains?: ModelTypes["CheckInRedemption_productType_Input"] | undefined,
	exists?: boolean | undefined
};
	["CheckInRedemption_productType_Input"]:CheckInRedemption_productType_Input;
	["CheckInRedemption_updatedAt_operator"]: {
	equals?: ModelTypes["DateTime"] | undefined,
	not_equals?: ModelTypes["DateTime"] | undefined,
	greater_than_equal?: ModelTypes["DateTime"] | undefined,
	greater_than?: ModelTypes["DateTime"] | undefined,
	less_than_equal?: ModelTypes["DateTime"] | undefined,
	less_than?: ModelTypes["DateTime"] | undefined,
	like?: ModelTypes["DateTime"] | undefined,
	exists?: boolean | undefined
};
	["CheckInRedemption_createdAt_operator"]: {
	equals?: ModelTypes["DateTime"] | undefined,
	not_equals?: ModelTypes["DateTime"] | undefined,
	greater_than_equal?: ModelTypes["DateTime"] | undefined,
	greater_than?: ModelTypes["DateTime"] | undefined,
	less_than_equal?: ModelTypes["DateTime"] | undefined,
	less_than?: ModelTypes["DateTime"] | undefined,
	like?: ModelTypes["DateTime"] | undefined,
	exists?: boolean | undefined
};
	["CheckInRedemption_id_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["CheckInRedemption_where_and"]: {
	creator?: ModelTypes["CheckInRedemption_creator_operator"] | undefined,
	qrCodeId?: ModelTypes["CheckInRedemption_qrCodeId_operator"] | undefined,
	codeownerName?: ModelTypes["CheckInRedemption_codeownerName_operator"] | undefined,
	name?: ModelTypes["CheckInRedemption_name_operator"] | undefined,
	phone?: ModelTypes["CheckInRedemption_phone_operator"] | undefined,
	linkedEvent?: ModelTypes["CheckInRedemption_linkedEvent_operator"] | undefined,
	entryNumber?: ModelTypes["CheckInRedemption_entryNumber_operator"] | undefined,
	scanAt?: ModelTypes["CheckInRedemption_scanAt_operator"] | undefined,
	uploadAt?: ModelTypes["CheckInRedemption_uploadAt_operator"] | undefined,
	status?: ModelTypes["CheckInRedemption_status_operator"] | undefined,
	failReason?: ModelTypes["CheckInRedemption_failReason_operator"] | undefined,
	variants__variantId?: ModelTypes["CheckInRedemption_variants__variantId_operator"] | undefined,
	variants__name?: ModelTypes["CheckInRedemption_variants__name_operator"] | undefined,
	variants__quantity?: ModelTypes["CheckInRedemption_variants__quantity_operator"] | undefined,
	variants__totalCost?: ModelTypes["CheckInRedemption_variants__totalCost_operator"] | undefined,
	variants__id?: ModelTypes["CheckInRedemption_variants__id_operator"] | undefined,
	checkInAppSelectionId?: ModelTypes["CheckInRedemption_checkInAppSelectionId_operator"] | undefined,
	qrCodeStatus?: ModelTypes["CheckInRedemption_qrCodeStatus_operator"] | undefined,
	productType?: ModelTypes["CheckInRedemption_productType_operator"] | undefined,
	updatedAt?: ModelTypes["CheckInRedemption_updatedAt_operator"] | undefined,
	createdAt?: ModelTypes["CheckInRedemption_createdAt_operator"] | undefined,
	id?: ModelTypes["CheckInRedemption_id_operator"] | undefined,
	AND?: Array<ModelTypes["CheckInRedemption_where_and"] | undefined> | undefined,
	OR?: Array<ModelTypes["CheckInRedemption_where_or"] | undefined> | undefined
};
	["CheckInRedemption_where_or"]: {
	creator?: ModelTypes["CheckInRedemption_creator_operator"] | undefined,
	qrCodeId?: ModelTypes["CheckInRedemption_qrCodeId_operator"] | undefined,
	codeownerName?: ModelTypes["CheckInRedemption_codeownerName_operator"] | undefined,
	name?: ModelTypes["CheckInRedemption_name_operator"] | undefined,
	phone?: ModelTypes["CheckInRedemption_phone_operator"] | undefined,
	linkedEvent?: ModelTypes["CheckInRedemption_linkedEvent_operator"] | undefined,
	entryNumber?: ModelTypes["CheckInRedemption_entryNumber_operator"] | undefined,
	scanAt?: ModelTypes["CheckInRedemption_scanAt_operator"] | undefined,
	uploadAt?: ModelTypes["CheckInRedemption_uploadAt_operator"] | undefined,
	status?: ModelTypes["CheckInRedemption_status_operator"] | undefined,
	failReason?: ModelTypes["CheckInRedemption_failReason_operator"] | undefined,
	variants__variantId?: ModelTypes["CheckInRedemption_variants__variantId_operator"] | undefined,
	variants__name?: ModelTypes["CheckInRedemption_variants__name_operator"] | undefined,
	variants__quantity?: ModelTypes["CheckInRedemption_variants__quantity_operator"] | undefined,
	variants__totalCost?: ModelTypes["CheckInRedemption_variants__totalCost_operator"] | undefined,
	variants__id?: ModelTypes["CheckInRedemption_variants__id_operator"] | undefined,
	checkInAppSelectionId?: ModelTypes["CheckInRedemption_checkInAppSelectionId_operator"] | undefined,
	qrCodeStatus?: ModelTypes["CheckInRedemption_qrCodeStatus_operator"] | undefined,
	productType?: ModelTypes["CheckInRedemption_productType_operator"] | undefined,
	updatedAt?: ModelTypes["CheckInRedemption_updatedAt_operator"] | undefined,
	createdAt?: ModelTypes["CheckInRedemption_createdAt_operator"] | undefined,
	id?: ModelTypes["CheckInRedemption_id_operator"] | undefined,
	AND?: Array<ModelTypes["CheckInRedemption_where_and"] | undefined> | undefined,
	OR?: Array<ModelTypes["CheckInRedemption_where_or"] | undefined> | undefined
};
	["countCheckInRedemptions"]: {
		totalDocs?: number | undefined
};
	["check_in_redemptionDocAccess"]: {
		fields?: ModelTypes["CheckInRedemptionDocAccessFields"] | undefined,
	create?: ModelTypes["CheckInRedemptionCreateDocAccess"] | undefined,
	read?: ModelTypes["CheckInRedemptionReadDocAccess"] | undefined,
	update?: ModelTypes["CheckInRedemptionUpdateDocAccess"] | undefined,
	delete?: ModelTypes["CheckInRedemptionDeleteDocAccess"] | undefined
};
	["CheckInRedemptionDocAccessFields"]: {
		creator?: ModelTypes["CheckInRedemptionDocAccessFields_creator"] | undefined,
	qrCodeId?: ModelTypes["CheckInRedemptionDocAccessFields_qrCodeId"] | undefined,
	codeownerName?: ModelTypes["CheckInRedemptionDocAccessFields_codeownerName"] | undefined,
	name?: ModelTypes["CheckInRedemptionDocAccessFields_name"] | undefined,
	phone?: ModelTypes["CheckInRedemptionDocAccessFields_phone"] | undefined,
	linkedEvent?: ModelTypes["CheckInRedemptionDocAccessFields_linkedEvent"] | undefined,
	entryNumber?: ModelTypes["CheckInRedemptionDocAccessFields_entryNumber"] | undefined,
	scanAt?: ModelTypes["CheckInRedemptionDocAccessFields_scanAt"] | undefined,
	uploadAt?: ModelTypes["CheckInRedemptionDocAccessFields_uploadAt"] | undefined,
	status?: ModelTypes["CheckInRedemptionDocAccessFields_status"] | undefined,
	failReason?: ModelTypes["CheckInRedemptionDocAccessFields_failReason"] | undefined,
	variants?: ModelTypes["CheckInRedemptionDocAccessFields_variants"] | undefined,
	checkInAppSelectionId?: ModelTypes["CheckInRedemptionDocAccessFields_checkInAppSelectionId"] | undefined,
	qrCodeStatus?: ModelTypes["CheckInRedemptionDocAccessFields_qrCodeStatus"] | undefined,
	productType?: ModelTypes["CheckInRedemptionDocAccessFields_productType"] | undefined,
	updatedAt?: ModelTypes["CheckInRedemptionDocAccessFields_updatedAt"] | undefined,
	createdAt?: ModelTypes["CheckInRedemptionDocAccessFields_createdAt"] | undefined
};
	["CheckInRedemptionDocAccessFields_creator"]: {
		create?: ModelTypes["CheckInRedemptionDocAccessFields_creator_Create"] | undefined,
	read?: ModelTypes["CheckInRedemptionDocAccessFields_creator_Read"] | undefined,
	update?: ModelTypes["CheckInRedemptionDocAccessFields_creator_Update"] | undefined,
	delete?: ModelTypes["CheckInRedemptionDocAccessFields_creator_Delete"] | undefined
};
	["CheckInRedemptionDocAccessFields_creator_Create"]: {
		permission: boolean
};
	["CheckInRedemptionDocAccessFields_creator_Read"]: {
		permission: boolean
};
	["CheckInRedemptionDocAccessFields_creator_Update"]: {
		permission: boolean
};
	["CheckInRedemptionDocAccessFields_creator_Delete"]: {
		permission: boolean
};
	["CheckInRedemptionDocAccessFields_qrCodeId"]: {
		create?: ModelTypes["CheckInRedemptionDocAccessFields_qrCodeId_Create"] | undefined,
	read?: ModelTypes["CheckInRedemptionDocAccessFields_qrCodeId_Read"] | undefined,
	update?: ModelTypes["CheckInRedemptionDocAccessFields_qrCodeId_Update"] | undefined,
	delete?: ModelTypes["CheckInRedemptionDocAccessFields_qrCodeId_Delete"] | undefined
};
	["CheckInRedemptionDocAccessFields_qrCodeId_Create"]: {
		permission: boolean
};
	["CheckInRedemptionDocAccessFields_qrCodeId_Read"]: {
		permission: boolean
};
	["CheckInRedemptionDocAccessFields_qrCodeId_Update"]: {
		permission: boolean
};
	["CheckInRedemptionDocAccessFields_qrCodeId_Delete"]: {
		permission: boolean
};
	["CheckInRedemptionDocAccessFields_codeownerName"]: {
		create?: ModelTypes["CheckInRedemptionDocAccessFields_codeownerName_Create"] | undefined,
	read?: ModelTypes["CheckInRedemptionDocAccessFields_codeownerName_Read"] | undefined,
	update?: ModelTypes["CheckInRedemptionDocAccessFields_codeownerName_Update"] | undefined,
	delete?: ModelTypes["CheckInRedemptionDocAccessFields_codeownerName_Delete"] | undefined
};
	["CheckInRedemptionDocAccessFields_codeownerName_Create"]: {
		permission: boolean
};
	["CheckInRedemptionDocAccessFields_codeownerName_Read"]: {
		permission: boolean
};
	["CheckInRedemptionDocAccessFields_codeownerName_Update"]: {
		permission: boolean
};
	["CheckInRedemptionDocAccessFields_codeownerName_Delete"]: {
		permission: boolean
};
	["CheckInRedemptionDocAccessFields_name"]: {
		create?: ModelTypes["CheckInRedemptionDocAccessFields_name_Create"] | undefined,
	read?: ModelTypes["CheckInRedemptionDocAccessFields_name_Read"] | undefined,
	update?: ModelTypes["CheckInRedemptionDocAccessFields_name_Update"] | undefined,
	delete?: ModelTypes["CheckInRedemptionDocAccessFields_name_Delete"] | undefined
};
	["CheckInRedemptionDocAccessFields_name_Create"]: {
		permission: boolean
};
	["CheckInRedemptionDocAccessFields_name_Read"]: {
		permission: boolean
};
	["CheckInRedemptionDocAccessFields_name_Update"]: {
		permission: boolean
};
	["CheckInRedemptionDocAccessFields_name_Delete"]: {
		permission: boolean
};
	["CheckInRedemptionDocAccessFields_phone"]: {
		create?: ModelTypes["CheckInRedemptionDocAccessFields_phone_Create"] | undefined,
	read?: ModelTypes["CheckInRedemptionDocAccessFields_phone_Read"] | undefined,
	update?: ModelTypes["CheckInRedemptionDocAccessFields_phone_Update"] | undefined,
	delete?: ModelTypes["CheckInRedemptionDocAccessFields_phone_Delete"] | undefined
};
	["CheckInRedemptionDocAccessFields_phone_Create"]: {
		permission: boolean
};
	["CheckInRedemptionDocAccessFields_phone_Read"]: {
		permission: boolean
};
	["CheckInRedemptionDocAccessFields_phone_Update"]: {
		permission: boolean
};
	["CheckInRedemptionDocAccessFields_phone_Delete"]: {
		permission: boolean
};
	["CheckInRedemptionDocAccessFields_linkedEvent"]: {
		create?: ModelTypes["CheckInRedemptionDocAccessFields_linkedEvent_Create"] | undefined,
	read?: ModelTypes["CheckInRedemptionDocAccessFields_linkedEvent_Read"] | undefined,
	update?: ModelTypes["CheckInRedemptionDocAccessFields_linkedEvent_Update"] | undefined,
	delete?: ModelTypes["CheckInRedemptionDocAccessFields_linkedEvent_Delete"] | undefined
};
	["CheckInRedemptionDocAccessFields_linkedEvent_Create"]: {
		permission: boolean
};
	["CheckInRedemptionDocAccessFields_linkedEvent_Read"]: {
		permission: boolean
};
	["CheckInRedemptionDocAccessFields_linkedEvent_Update"]: {
		permission: boolean
};
	["CheckInRedemptionDocAccessFields_linkedEvent_Delete"]: {
		permission: boolean
};
	["CheckInRedemptionDocAccessFields_entryNumber"]: {
		create?: ModelTypes["CheckInRedemptionDocAccessFields_entryNumber_Create"] | undefined,
	read?: ModelTypes["CheckInRedemptionDocAccessFields_entryNumber_Read"] | undefined,
	update?: ModelTypes["CheckInRedemptionDocAccessFields_entryNumber_Update"] | undefined,
	delete?: ModelTypes["CheckInRedemptionDocAccessFields_entryNumber_Delete"] | undefined
};
	["CheckInRedemptionDocAccessFields_entryNumber_Create"]: {
		permission: boolean
};
	["CheckInRedemptionDocAccessFields_entryNumber_Read"]: {
		permission: boolean
};
	["CheckInRedemptionDocAccessFields_entryNumber_Update"]: {
		permission: boolean
};
	["CheckInRedemptionDocAccessFields_entryNumber_Delete"]: {
		permission: boolean
};
	["CheckInRedemptionDocAccessFields_scanAt"]: {
		create?: ModelTypes["CheckInRedemptionDocAccessFields_scanAt_Create"] | undefined,
	read?: ModelTypes["CheckInRedemptionDocAccessFields_scanAt_Read"] | undefined,
	update?: ModelTypes["CheckInRedemptionDocAccessFields_scanAt_Update"] | undefined,
	delete?: ModelTypes["CheckInRedemptionDocAccessFields_scanAt_Delete"] | undefined
};
	["CheckInRedemptionDocAccessFields_scanAt_Create"]: {
		permission: boolean
};
	["CheckInRedemptionDocAccessFields_scanAt_Read"]: {
		permission: boolean
};
	["CheckInRedemptionDocAccessFields_scanAt_Update"]: {
		permission: boolean
};
	["CheckInRedemptionDocAccessFields_scanAt_Delete"]: {
		permission: boolean
};
	["CheckInRedemptionDocAccessFields_uploadAt"]: {
		create?: ModelTypes["CheckInRedemptionDocAccessFields_uploadAt_Create"] | undefined,
	read?: ModelTypes["CheckInRedemptionDocAccessFields_uploadAt_Read"] | undefined,
	update?: ModelTypes["CheckInRedemptionDocAccessFields_uploadAt_Update"] | undefined,
	delete?: ModelTypes["CheckInRedemptionDocAccessFields_uploadAt_Delete"] | undefined
};
	["CheckInRedemptionDocAccessFields_uploadAt_Create"]: {
		permission: boolean
};
	["CheckInRedemptionDocAccessFields_uploadAt_Read"]: {
		permission: boolean
};
	["CheckInRedemptionDocAccessFields_uploadAt_Update"]: {
		permission: boolean
};
	["CheckInRedemptionDocAccessFields_uploadAt_Delete"]: {
		permission: boolean
};
	["CheckInRedemptionDocAccessFields_status"]: {
		create?: ModelTypes["CheckInRedemptionDocAccessFields_status_Create"] | undefined,
	read?: ModelTypes["CheckInRedemptionDocAccessFields_status_Read"] | undefined,
	update?: ModelTypes["CheckInRedemptionDocAccessFields_status_Update"] | undefined,
	delete?: ModelTypes["CheckInRedemptionDocAccessFields_status_Delete"] | undefined
};
	["CheckInRedemptionDocAccessFields_status_Create"]: {
		permission: boolean
};
	["CheckInRedemptionDocAccessFields_status_Read"]: {
		permission: boolean
};
	["CheckInRedemptionDocAccessFields_status_Update"]: {
		permission: boolean
};
	["CheckInRedemptionDocAccessFields_status_Delete"]: {
		permission: boolean
};
	["CheckInRedemptionDocAccessFields_failReason"]: {
		create?: ModelTypes["CheckInRedemptionDocAccessFields_failReason_Create"] | undefined,
	read?: ModelTypes["CheckInRedemptionDocAccessFields_failReason_Read"] | undefined,
	update?: ModelTypes["CheckInRedemptionDocAccessFields_failReason_Update"] | undefined,
	delete?: ModelTypes["CheckInRedemptionDocAccessFields_failReason_Delete"] | undefined
};
	["CheckInRedemptionDocAccessFields_failReason_Create"]: {
		permission: boolean
};
	["CheckInRedemptionDocAccessFields_failReason_Read"]: {
		permission: boolean
};
	["CheckInRedemptionDocAccessFields_failReason_Update"]: {
		permission: boolean
};
	["CheckInRedemptionDocAccessFields_failReason_Delete"]: {
		permission: boolean
};
	["CheckInRedemptionDocAccessFields_variants"]: {
		create?: ModelTypes["CheckInRedemptionDocAccessFields_variants_Create"] | undefined,
	read?: ModelTypes["CheckInRedemptionDocAccessFields_variants_Read"] | undefined,
	update?: ModelTypes["CheckInRedemptionDocAccessFields_variants_Update"] | undefined,
	delete?: ModelTypes["CheckInRedemptionDocAccessFields_variants_Delete"] | undefined,
	fields?: ModelTypes["CheckInRedemptionDocAccessFields_variants_Fields"] | undefined
};
	["CheckInRedemptionDocAccessFields_variants_Create"]: {
		permission: boolean
};
	["CheckInRedemptionDocAccessFields_variants_Read"]: {
		permission: boolean
};
	["CheckInRedemptionDocAccessFields_variants_Update"]: {
		permission: boolean
};
	["CheckInRedemptionDocAccessFields_variants_Delete"]: {
		permission: boolean
};
	["CheckInRedemptionDocAccessFields_variants_Fields"]: {
		variantId?: ModelTypes["CheckInRedemptionDocAccessFields_variants_variantId"] | undefined,
	name?: ModelTypes["CheckInRedemptionDocAccessFields_variants_name"] | undefined,
	quantity?: ModelTypes["CheckInRedemptionDocAccessFields_variants_quantity"] | undefined,
	totalCost?: ModelTypes["CheckInRedemptionDocAccessFields_variants_totalCost"] | undefined,
	id?: ModelTypes["CheckInRedemptionDocAccessFields_variants_id"] | undefined
};
	["CheckInRedemptionDocAccessFields_variants_variantId"]: {
		create?: ModelTypes["CheckInRedemptionDocAccessFields_variants_variantId_Create"] | undefined,
	read?: ModelTypes["CheckInRedemptionDocAccessFields_variants_variantId_Read"] | undefined,
	update?: ModelTypes["CheckInRedemptionDocAccessFields_variants_variantId_Update"] | undefined,
	delete?: ModelTypes["CheckInRedemptionDocAccessFields_variants_variantId_Delete"] | undefined
};
	["CheckInRedemptionDocAccessFields_variants_variantId_Create"]: {
		permission: boolean
};
	["CheckInRedemptionDocAccessFields_variants_variantId_Read"]: {
		permission: boolean
};
	["CheckInRedemptionDocAccessFields_variants_variantId_Update"]: {
		permission: boolean
};
	["CheckInRedemptionDocAccessFields_variants_variantId_Delete"]: {
		permission: boolean
};
	["CheckInRedemptionDocAccessFields_variants_name"]: {
		create?: ModelTypes["CheckInRedemptionDocAccessFields_variants_name_Create"] | undefined,
	read?: ModelTypes["CheckInRedemptionDocAccessFields_variants_name_Read"] | undefined,
	update?: ModelTypes["CheckInRedemptionDocAccessFields_variants_name_Update"] | undefined,
	delete?: ModelTypes["CheckInRedemptionDocAccessFields_variants_name_Delete"] | undefined
};
	["CheckInRedemptionDocAccessFields_variants_name_Create"]: {
		permission: boolean
};
	["CheckInRedemptionDocAccessFields_variants_name_Read"]: {
		permission: boolean
};
	["CheckInRedemptionDocAccessFields_variants_name_Update"]: {
		permission: boolean
};
	["CheckInRedemptionDocAccessFields_variants_name_Delete"]: {
		permission: boolean
};
	["CheckInRedemptionDocAccessFields_variants_quantity"]: {
		create?: ModelTypes["CheckInRedemptionDocAccessFields_variants_quantity_Create"] | undefined,
	read?: ModelTypes["CheckInRedemptionDocAccessFields_variants_quantity_Read"] | undefined,
	update?: ModelTypes["CheckInRedemptionDocAccessFields_variants_quantity_Update"] | undefined,
	delete?: ModelTypes["CheckInRedemptionDocAccessFields_variants_quantity_Delete"] | undefined
};
	["CheckInRedemptionDocAccessFields_variants_quantity_Create"]: {
		permission: boolean
};
	["CheckInRedemptionDocAccessFields_variants_quantity_Read"]: {
		permission: boolean
};
	["CheckInRedemptionDocAccessFields_variants_quantity_Update"]: {
		permission: boolean
};
	["CheckInRedemptionDocAccessFields_variants_quantity_Delete"]: {
		permission: boolean
};
	["CheckInRedemptionDocAccessFields_variants_totalCost"]: {
		create?: ModelTypes["CheckInRedemptionDocAccessFields_variants_totalCost_Create"] | undefined,
	read?: ModelTypes["CheckInRedemptionDocAccessFields_variants_totalCost_Read"] | undefined,
	update?: ModelTypes["CheckInRedemptionDocAccessFields_variants_totalCost_Update"] | undefined,
	delete?: ModelTypes["CheckInRedemptionDocAccessFields_variants_totalCost_Delete"] | undefined
};
	["CheckInRedemptionDocAccessFields_variants_totalCost_Create"]: {
		permission: boolean
};
	["CheckInRedemptionDocAccessFields_variants_totalCost_Read"]: {
		permission: boolean
};
	["CheckInRedemptionDocAccessFields_variants_totalCost_Update"]: {
		permission: boolean
};
	["CheckInRedemptionDocAccessFields_variants_totalCost_Delete"]: {
		permission: boolean
};
	["CheckInRedemptionDocAccessFields_variants_id"]: {
		create?: ModelTypes["CheckInRedemptionDocAccessFields_variants_id_Create"] | undefined,
	read?: ModelTypes["CheckInRedemptionDocAccessFields_variants_id_Read"] | undefined,
	update?: ModelTypes["CheckInRedemptionDocAccessFields_variants_id_Update"] | undefined,
	delete?: ModelTypes["CheckInRedemptionDocAccessFields_variants_id_Delete"] | undefined
};
	["CheckInRedemptionDocAccessFields_variants_id_Create"]: {
		permission: boolean
};
	["CheckInRedemptionDocAccessFields_variants_id_Read"]: {
		permission: boolean
};
	["CheckInRedemptionDocAccessFields_variants_id_Update"]: {
		permission: boolean
};
	["CheckInRedemptionDocAccessFields_variants_id_Delete"]: {
		permission: boolean
};
	["CheckInRedemptionDocAccessFields_checkInAppSelectionId"]: {
		create?: ModelTypes["CheckInRedemptionDocAccessFields_checkInAppSelectionId_Create"] | undefined,
	read?: ModelTypes["CheckInRedemptionDocAccessFields_checkInAppSelectionId_Read"] | undefined,
	update?: ModelTypes["CheckInRedemptionDocAccessFields_checkInAppSelectionId_Update"] | undefined,
	delete?: ModelTypes["CheckInRedemptionDocAccessFields_checkInAppSelectionId_Delete"] | undefined
};
	["CheckInRedemptionDocAccessFields_checkInAppSelectionId_Create"]: {
		permission: boolean
};
	["CheckInRedemptionDocAccessFields_checkInAppSelectionId_Read"]: {
		permission: boolean
};
	["CheckInRedemptionDocAccessFields_checkInAppSelectionId_Update"]: {
		permission: boolean
};
	["CheckInRedemptionDocAccessFields_checkInAppSelectionId_Delete"]: {
		permission: boolean
};
	["CheckInRedemptionDocAccessFields_qrCodeStatus"]: {
		create?: ModelTypes["CheckInRedemptionDocAccessFields_qrCodeStatus_Create"] | undefined,
	read?: ModelTypes["CheckInRedemptionDocAccessFields_qrCodeStatus_Read"] | undefined,
	update?: ModelTypes["CheckInRedemptionDocAccessFields_qrCodeStatus_Update"] | undefined,
	delete?: ModelTypes["CheckInRedemptionDocAccessFields_qrCodeStatus_Delete"] | undefined
};
	["CheckInRedemptionDocAccessFields_qrCodeStatus_Create"]: {
		permission: boolean
};
	["CheckInRedemptionDocAccessFields_qrCodeStatus_Read"]: {
		permission: boolean
};
	["CheckInRedemptionDocAccessFields_qrCodeStatus_Update"]: {
		permission: boolean
};
	["CheckInRedemptionDocAccessFields_qrCodeStatus_Delete"]: {
		permission: boolean
};
	["CheckInRedemptionDocAccessFields_productType"]: {
		create?: ModelTypes["CheckInRedemptionDocAccessFields_productType_Create"] | undefined,
	read?: ModelTypes["CheckInRedemptionDocAccessFields_productType_Read"] | undefined,
	update?: ModelTypes["CheckInRedemptionDocAccessFields_productType_Update"] | undefined,
	delete?: ModelTypes["CheckInRedemptionDocAccessFields_productType_Delete"] | undefined
};
	["CheckInRedemptionDocAccessFields_productType_Create"]: {
		permission: boolean
};
	["CheckInRedemptionDocAccessFields_productType_Read"]: {
		permission: boolean
};
	["CheckInRedemptionDocAccessFields_productType_Update"]: {
		permission: boolean
};
	["CheckInRedemptionDocAccessFields_productType_Delete"]: {
		permission: boolean
};
	["CheckInRedemptionDocAccessFields_updatedAt"]: {
		create?: ModelTypes["CheckInRedemptionDocAccessFields_updatedAt_Create"] | undefined,
	read?: ModelTypes["CheckInRedemptionDocAccessFields_updatedAt_Read"] | undefined,
	update?: ModelTypes["CheckInRedemptionDocAccessFields_updatedAt_Update"] | undefined,
	delete?: ModelTypes["CheckInRedemptionDocAccessFields_updatedAt_Delete"] | undefined
};
	["CheckInRedemptionDocAccessFields_updatedAt_Create"]: {
		permission: boolean
};
	["CheckInRedemptionDocAccessFields_updatedAt_Read"]: {
		permission: boolean
};
	["CheckInRedemptionDocAccessFields_updatedAt_Update"]: {
		permission: boolean
};
	["CheckInRedemptionDocAccessFields_updatedAt_Delete"]: {
		permission: boolean
};
	["CheckInRedemptionDocAccessFields_createdAt"]: {
		create?: ModelTypes["CheckInRedemptionDocAccessFields_createdAt_Create"] | undefined,
	read?: ModelTypes["CheckInRedemptionDocAccessFields_createdAt_Read"] | undefined,
	update?: ModelTypes["CheckInRedemptionDocAccessFields_createdAt_Update"] | undefined,
	delete?: ModelTypes["CheckInRedemptionDocAccessFields_createdAt_Delete"] | undefined
};
	["CheckInRedemptionDocAccessFields_createdAt_Create"]: {
		permission: boolean
};
	["CheckInRedemptionDocAccessFields_createdAt_Read"]: {
		permission: boolean
};
	["CheckInRedemptionDocAccessFields_createdAt_Update"]: {
		permission: boolean
};
	["CheckInRedemptionDocAccessFields_createdAt_Delete"]: {
		permission: boolean
};
	["CheckInRedemptionCreateDocAccess"]: {
		permission: boolean,
	where?: ModelTypes["JSONObject"] | undefined
};
	["CheckInRedemptionReadDocAccess"]: {
		permission: boolean,
	where?: ModelTypes["JSONObject"] | undefined
};
	["CheckInRedemptionUpdateDocAccess"]: {
		permission: boolean,
	where?: ModelTypes["JSONObject"] | undefined
};
	["CheckInRedemptionDeleteDocAccess"]: {
		permission: boolean,
	where?: ModelTypes["JSONObject"] | undefined
};
	["Users"]: {
		docs?: Array<ModelTypes["User"] | undefined> | undefined,
	hasNextPage?: boolean | undefined,
	hasPrevPage?: boolean | undefined,
	limit?: number | undefined,
	nextPage?: number | undefined,
	offset?: number | undefined,
	page?: number | undefined,
	pagingCounter?: number | undefined,
	prevPage?: number | undefined,
	totalDocs?: number | undefined,
	totalPages?: number | undefined
};
	["User_where"]: {
	region?: ModelTypes["User_region_operator"] | undefined,
	name?: ModelTypes["User_name_operator"] | undefined,
	displayEmail?: ModelTypes["User_displayEmail_operator"] | undefined,
	legacyUsername?: ModelTypes["User_legacyUsername_operator"] | undefined,
	roles?: ModelTypes["User_roles_operator"] | undefined,
	member__region?: ModelTypes["User_member__region_operator"] | undefined,
	member__language?: ModelTypes["User_member__language_operator"] | undefined,
	member__phone?: ModelTypes["User_member__phone_operator"] | undefined,
	member__avatar?: ModelTypes["User_member__avatar_operator"] | undefined,
	member__birthday?: ModelTypes["User_member__birthday_operator"] | undefined,
	member__gender?: ModelTypes["User_member__gender_operator"] | undefined,
	member__categories?: ModelTypes["User_member__categories_operator"] | undefined,
	organiser__logo?: ModelTypes["User_organiser__logo_operator"] | undefined,
	organiser__banner?: ModelTypes["User_organiser__banner_operator"] | undefined,
	organiser__description?: ModelTypes["User_organiser__description_operator"] | undefined,
	organiser__foundedIn?: ModelTypes["User_organiser__foundedIn_operator"] | undefined,
	organiser__address?: ModelTypes["User_organiser__address_operator"] | undefined,
	organiser__website?: ModelTypes["User_organiser__website_operator"] | undefined,
	organiser__facebook?: ModelTypes["User_organiser__facebook_operator"] | undefined,
	organiser__instagram?: ModelTypes["User_organiser__instagram_operator"] | undefined,
	organiser__language?: ModelTypes["User_organiser__language_operator"] | undefined,
	organiser__phone?: ModelTypes["User_organiser__phone_operator"] | undefined,
	organiser__followers?: ModelTypes["User_organiser__followers_operator"] | undefined,
	emailVerified?: ModelTypes["User_emailVerified_operator"] | undefined,
	sso__googleId?: ModelTypes["User_sso__googleId_operator"] | undefined,
	sso__facebookId?: ModelTypes["User_sso__facebookId_operator"] | undefined,
	sso__appleId?: ModelTypes["User_sso__appleId_operator"] | undefined,
	oldPassword?: ModelTypes["User_oldPassword_operator"] | undefined,
	status?: ModelTypes["User_status_operator"] | undefined,
	updatedAt?: ModelTypes["User_updatedAt_operator"] | undefined,
	createdAt?: ModelTypes["User_createdAt_operator"] | undefined,
	email?: ModelTypes["User_email_operator"] | undefined,
	id?: ModelTypes["User_id_operator"] | undefined,
	AND?: Array<ModelTypes["User_where_and"] | undefined> | undefined,
	OR?: Array<ModelTypes["User_where_or"] | undefined> | undefined
};
	["User_region_operator"]: {
	equals?: ModelTypes["User_region_Input"] | undefined,
	not_equals?: ModelTypes["User_region_Input"] | undefined,
	in?: Array<ModelTypes["User_region_Input"] | undefined> | undefined,
	not_in?: Array<ModelTypes["User_region_Input"] | undefined> | undefined,
	all?: Array<ModelTypes["User_region_Input"] | undefined> | undefined,
	exists?: boolean | undefined
};
	["User_region_Input"]:User_region_Input;
	["User_name_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["User_displayEmail_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["User_legacyUsername_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["User_roles_operator"]: {
	equals?: ModelTypes["User_roles_Input"] | undefined,
	not_equals?: ModelTypes["User_roles_Input"] | undefined,
	in?: Array<ModelTypes["User_roles_Input"] | undefined> | undefined,
	not_in?: Array<ModelTypes["User_roles_Input"] | undefined> | undefined,
	all?: Array<ModelTypes["User_roles_Input"] | undefined> | undefined
};
	["User_roles_Input"]:User_roles_Input;
	["User_member__region_operator"]: {
	equals?: ModelTypes["User_member__region_Input"] | undefined,
	not_equals?: ModelTypes["User_member__region_Input"] | undefined,
	in?: Array<ModelTypes["User_member__region_Input"] | undefined> | undefined,
	not_in?: Array<ModelTypes["User_member__region_Input"] | undefined> | undefined,
	all?: Array<ModelTypes["User_member__region_Input"] | undefined> | undefined,
	exists?: boolean | undefined
};
	["User_member__region_Input"]:User_member__region_Input;
	["User_member__language_operator"]: {
	equals?: ModelTypes["User_member__language_Input"] | undefined,
	not_equals?: ModelTypes["User_member__language_Input"] | undefined,
	in?: Array<ModelTypes["User_member__language_Input"] | undefined> | undefined,
	not_in?: Array<ModelTypes["User_member__language_Input"] | undefined> | undefined,
	all?: Array<ModelTypes["User_member__language_Input"] | undefined> | undefined,
	exists?: boolean | undefined
};
	["User_member__language_Input"]:User_member__language_Input;
	["User_member__phone_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["User_member__avatar_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	exists?: boolean | undefined
};
	["User_member__birthday_operator"]: {
	equals?: ModelTypes["DateTime"] | undefined,
	not_equals?: ModelTypes["DateTime"] | undefined,
	greater_than_equal?: ModelTypes["DateTime"] | undefined,
	greater_than?: ModelTypes["DateTime"] | undefined,
	less_than_equal?: ModelTypes["DateTime"] | undefined,
	less_than?: ModelTypes["DateTime"] | undefined,
	like?: ModelTypes["DateTime"] | undefined,
	exists?: boolean | undefined
};
	["User_member__gender_operator"]: {
	equals?: ModelTypes["User_member__gender_Input"] | undefined,
	not_equals?: ModelTypes["User_member__gender_Input"] | undefined,
	in?: Array<ModelTypes["User_member__gender_Input"] | undefined> | undefined,
	not_in?: Array<ModelTypes["User_member__gender_Input"] | undefined> | undefined,
	all?: Array<ModelTypes["User_member__gender_Input"] | undefined> | undefined,
	exists?: boolean | undefined
};
	["User_member__gender_Input"]:User_member__gender_Input;
	["User_member__categories_operator"]: {
	equals?: ModelTypes["JSON"] | undefined,
	not_equals?: ModelTypes["JSON"] | undefined,
	in?: Array<ModelTypes["JSON"] | undefined> | undefined,
	not_in?: Array<ModelTypes["JSON"] | undefined> | undefined,
	all?: Array<ModelTypes["JSON"] | undefined> | undefined,
	exists?: boolean | undefined
};
	["User_organiser__logo_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	exists?: boolean | undefined
};
	["User_organiser__banner_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	exists?: boolean | undefined
};
	["User_organiser__description_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	exists?: boolean | undefined
};
	["User_organiser__foundedIn_operator"]: {
	equals?: ModelTypes["DateTime"] | undefined,
	not_equals?: ModelTypes["DateTime"] | undefined,
	greater_than_equal?: ModelTypes["DateTime"] | undefined,
	greater_than?: ModelTypes["DateTime"] | undefined,
	less_than_equal?: ModelTypes["DateTime"] | undefined,
	less_than?: ModelTypes["DateTime"] | undefined,
	like?: ModelTypes["DateTime"] | undefined,
	exists?: boolean | undefined
};
	["User_organiser__address_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["User_organiser__website_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["User_organiser__facebook_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["User_organiser__instagram_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["User_organiser__language_operator"]: {
	equals?: ModelTypes["User_organiser__language_Input"] | undefined,
	not_equals?: ModelTypes["User_organiser__language_Input"] | undefined,
	in?: Array<ModelTypes["User_organiser__language_Input"] | undefined> | undefined,
	not_in?: Array<ModelTypes["User_organiser__language_Input"] | undefined> | undefined,
	all?: Array<ModelTypes["User_organiser__language_Input"] | undefined> | undefined,
	exists?: boolean | undefined
};
	["User_organiser__language_Input"]:User_organiser__language_Input;
	["User_organiser__phone_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["User_organiser__followers_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["User_emailVerified_operator"]: {
	equals?: boolean | undefined,
	not_equals?: boolean | undefined,
	exists?: boolean | undefined
};
	["User_sso__googleId_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["User_sso__facebookId_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["User_sso__appleId_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["User_oldPassword_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["User_status_operator"]: {
	equals?: ModelTypes["User_status_Input"] | undefined,
	not_equals?: ModelTypes["User_status_Input"] | undefined,
	in?: Array<ModelTypes["User_status_Input"] | undefined> | undefined,
	not_in?: Array<ModelTypes["User_status_Input"] | undefined> | undefined,
	all?: Array<ModelTypes["User_status_Input"] | undefined> | undefined,
	exists?: boolean | undefined
};
	["User_status_Input"]:User_status_Input;
	["User_updatedAt_operator"]: {
	equals?: ModelTypes["DateTime"] | undefined,
	not_equals?: ModelTypes["DateTime"] | undefined,
	greater_than_equal?: ModelTypes["DateTime"] | undefined,
	greater_than?: ModelTypes["DateTime"] | undefined,
	less_than_equal?: ModelTypes["DateTime"] | undefined,
	less_than?: ModelTypes["DateTime"] | undefined,
	like?: ModelTypes["DateTime"] | undefined,
	exists?: boolean | undefined
};
	["User_createdAt_operator"]: {
	equals?: ModelTypes["DateTime"] | undefined,
	not_equals?: ModelTypes["DateTime"] | undefined,
	greater_than_equal?: ModelTypes["DateTime"] | undefined,
	greater_than?: ModelTypes["DateTime"] | undefined,
	less_than_equal?: ModelTypes["DateTime"] | undefined,
	less_than?: ModelTypes["DateTime"] | undefined,
	like?: ModelTypes["DateTime"] | undefined,
	exists?: boolean | undefined
};
	["User_email_operator"]: {
	equals?: ModelTypes["EmailAddress"] | undefined,
	not_equals?: ModelTypes["EmailAddress"] | undefined,
	like?: ModelTypes["EmailAddress"] | undefined,
	contains?: ModelTypes["EmailAddress"] | undefined,
	in?: Array<ModelTypes["EmailAddress"] | undefined> | undefined,
	not_in?: Array<ModelTypes["EmailAddress"] | undefined> | undefined,
	all?: Array<ModelTypes["EmailAddress"] | undefined> | undefined
};
	["User_id_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["User_where_and"]: {
	region?: ModelTypes["User_region_operator"] | undefined,
	name?: ModelTypes["User_name_operator"] | undefined,
	displayEmail?: ModelTypes["User_displayEmail_operator"] | undefined,
	legacyUsername?: ModelTypes["User_legacyUsername_operator"] | undefined,
	roles?: ModelTypes["User_roles_operator"] | undefined,
	member__region?: ModelTypes["User_member__region_operator"] | undefined,
	member__language?: ModelTypes["User_member__language_operator"] | undefined,
	member__phone?: ModelTypes["User_member__phone_operator"] | undefined,
	member__avatar?: ModelTypes["User_member__avatar_operator"] | undefined,
	member__birthday?: ModelTypes["User_member__birthday_operator"] | undefined,
	member__gender?: ModelTypes["User_member__gender_operator"] | undefined,
	member__categories?: ModelTypes["User_member__categories_operator"] | undefined,
	organiser__logo?: ModelTypes["User_organiser__logo_operator"] | undefined,
	organiser__banner?: ModelTypes["User_organiser__banner_operator"] | undefined,
	organiser__description?: ModelTypes["User_organiser__description_operator"] | undefined,
	organiser__foundedIn?: ModelTypes["User_organiser__foundedIn_operator"] | undefined,
	organiser__address?: ModelTypes["User_organiser__address_operator"] | undefined,
	organiser__website?: ModelTypes["User_organiser__website_operator"] | undefined,
	organiser__facebook?: ModelTypes["User_organiser__facebook_operator"] | undefined,
	organiser__instagram?: ModelTypes["User_organiser__instagram_operator"] | undefined,
	organiser__language?: ModelTypes["User_organiser__language_operator"] | undefined,
	organiser__phone?: ModelTypes["User_organiser__phone_operator"] | undefined,
	organiser__followers?: ModelTypes["User_organiser__followers_operator"] | undefined,
	emailVerified?: ModelTypes["User_emailVerified_operator"] | undefined,
	sso__googleId?: ModelTypes["User_sso__googleId_operator"] | undefined,
	sso__facebookId?: ModelTypes["User_sso__facebookId_operator"] | undefined,
	sso__appleId?: ModelTypes["User_sso__appleId_operator"] | undefined,
	oldPassword?: ModelTypes["User_oldPassword_operator"] | undefined,
	status?: ModelTypes["User_status_operator"] | undefined,
	updatedAt?: ModelTypes["User_updatedAt_operator"] | undefined,
	createdAt?: ModelTypes["User_createdAt_operator"] | undefined,
	email?: ModelTypes["User_email_operator"] | undefined,
	id?: ModelTypes["User_id_operator"] | undefined,
	AND?: Array<ModelTypes["User_where_and"] | undefined> | undefined,
	OR?: Array<ModelTypes["User_where_or"] | undefined> | undefined
};
	["User_where_or"]: {
	region?: ModelTypes["User_region_operator"] | undefined,
	name?: ModelTypes["User_name_operator"] | undefined,
	displayEmail?: ModelTypes["User_displayEmail_operator"] | undefined,
	legacyUsername?: ModelTypes["User_legacyUsername_operator"] | undefined,
	roles?: ModelTypes["User_roles_operator"] | undefined,
	member__region?: ModelTypes["User_member__region_operator"] | undefined,
	member__language?: ModelTypes["User_member__language_operator"] | undefined,
	member__phone?: ModelTypes["User_member__phone_operator"] | undefined,
	member__avatar?: ModelTypes["User_member__avatar_operator"] | undefined,
	member__birthday?: ModelTypes["User_member__birthday_operator"] | undefined,
	member__gender?: ModelTypes["User_member__gender_operator"] | undefined,
	member__categories?: ModelTypes["User_member__categories_operator"] | undefined,
	organiser__logo?: ModelTypes["User_organiser__logo_operator"] | undefined,
	organiser__banner?: ModelTypes["User_organiser__banner_operator"] | undefined,
	organiser__description?: ModelTypes["User_organiser__description_operator"] | undefined,
	organiser__foundedIn?: ModelTypes["User_organiser__foundedIn_operator"] | undefined,
	organiser__address?: ModelTypes["User_organiser__address_operator"] | undefined,
	organiser__website?: ModelTypes["User_organiser__website_operator"] | undefined,
	organiser__facebook?: ModelTypes["User_organiser__facebook_operator"] | undefined,
	organiser__instagram?: ModelTypes["User_organiser__instagram_operator"] | undefined,
	organiser__language?: ModelTypes["User_organiser__language_operator"] | undefined,
	organiser__phone?: ModelTypes["User_organiser__phone_operator"] | undefined,
	organiser__followers?: ModelTypes["User_organiser__followers_operator"] | undefined,
	emailVerified?: ModelTypes["User_emailVerified_operator"] | undefined,
	sso__googleId?: ModelTypes["User_sso__googleId_operator"] | undefined,
	sso__facebookId?: ModelTypes["User_sso__facebookId_operator"] | undefined,
	sso__appleId?: ModelTypes["User_sso__appleId_operator"] | undefined,
	oldPassword?: ModelTypes["User_oldPassword_operator"] | undefined,
	status?: ModelTypes["User_status_operator"] | undefined,
	updatedAt?: ModelTypes["User_updatedAt_operator"] | undefined,
	createdAt?: ModelTypes["User_createdAt_operator"] | undefined,
	email?: ModelTypes["User_email_operator"] | undefined,
	id?: ModelTypes["User_id_operator"] | undefined,
	AND?: Array<ModelTypes["User_where_and"] | undefined> | undefined,
	OR?: Array<ModelTypes["User_where_or"] | undefined> | undefined
};
	["countUsers"]: {
		totalDocs?: number | undefined
};
	["usersDocAccess"]: {
		fields?: ModelTypes["UsersDocAccessFields"] | undefined,
	create?: ModelTypes["UsersCreateDocAccess"] | undefined,
	read?: ModelTypes["UsersReadDocAccess"] | undefined,
	update?: ModelTypes["UsersUpdateDocAccess"] | undefined,
	delete?: ModelTypes["UsersDeleteDocAccess"] | undefined,
	unlock?: ModelTypes["UsersUnlockDocAccess"] | undefined
};
	["UsersDocAccessFields"]: {
		region?: ModelTypes["UsersDocAccessFields_region"] | undefined,
	name?: ModelTypes["UsersDocAccessFields_name"] | undefined,
	displayEmail?: ModelTypes["UsersDocAccessFields_displayEmail"] | undefined,
	legacyUsername?: ModelTypes["UsersDocAccessFields_legacyUsername"] | undefined,
	roles?: ModelTypes["UsersDocAccessFields_roles"] | undefined,
	member?: ModelTypes["UsersDocAccessFields_member"] | undefined,
	organiser?: ModelTypes["UsersDocAccessFields_organiser"] | undefined,
	emailVerified?: ModelTypes["UsersDocAccessFields_emailVerified"] | undefined,
	sso?: ModelTypes["UsersDocAccessFields_sso"] | undefined,
	oldPassword?: ModelTypes["UsersDocAccessFields_oldPassword"] | undefined,
	status?: ModelTypes["UsersDocAccessFields_status"] | undefined,
	updatedAt?: ModelTypes["UsersDocAccessFields_updatedAt"] | undefined,
	createdAt?: ModelTypes["UsersDocAccessFields_createdAt"] | undefined,
	email?: ModelTypes["UsersDocAccessFields_email"] | undefined,
	password?: ModelTypes["UsersDocAccessFields_password"] | undefined
};
	["UsersDocAccessFields_region"]: {
		create?: ModelTypes["UsersDocAccessFields_region_Create"] | undefined,
	read?: ModelTypes["UsersDocAccessFields_region_Read"] | undefined,
	update?: ModelTypes["UsersDocAccessFields_region_Update"] | undefined,
	delete?: ModelTypes["UsersDocAccessFields_region_Delete"] | undefined
};
	["UsersDocAccessFields_region_Create"]: {
		permission: boolean
};
	["UsersDocAccessFields_region_Read"]: {
		permission: boolean
};
	["UsersDocAccessFields_region_Update"]: {
		permission: boolean
};
	["UsersDocAccessFields_region_Delete"]: {
		permission: boolean
};
	["UsersDocAccessFields_name"]: {
		create?: ModelTypes["UsersDocAccessFields_name_Create"] | undefined,
	read?: ModelTypes["UsersDocAccessFields_name_Read"] | undefined,
	update?: ModelTypes["UsersDocAccessFields_name_Update"] | undefined,
	delete?: ModelTypes["UsersDocAccessFields_name_Delete"] | undefined
};
	["UsersDocAccessFields_name_Create"]: {
		permission: boolean
};
	["UsersDocAccessFields_name_Read"]: {
		permission: boolean
};
	["UsersDocAccessFields_name_Update"]: {
		permission: boolean
};
	["UsersDocAccessFields_name_Delete"]: {
		permission: boolean
};
	["UsersDocAccessFields_displayEmail"]: {
		create?: ModelTypes["UsersDocAccessFields_displayEmail_Create"] | undefined,
	read?: ModelTypes["UsersDocAccessFields_displayEmail_Read"] | undefined,
	update?: ModelTypes["UsersDocAccessFields_displayEmail_Update"] | undefined,
	delete?: ModelTypes["UsersDocAccessFields_displayEmail_Delete"] | undefined
};
	["UsersDocAccessFields_displayEmail_Create"]: {
		permission: boolean
};
	["UsersDocAccessFields_displayEmail_Read"]: {
		permission: boolean
};
	["UsersDocAccessFields_displayEmail_Update"]: {
		permission: boolean
};
	["UsersDocAccessFields_displayEmail_Delete"]: {
		permission: boolean
};
	["UsersDocAccessFields_legacyUsername"]: {
		create?: ModelTypes["UsersDocAccessFields_legacyUsername_Create"] | undefined,
	read?: ModelTypes["UsersDocAccessFields_legacyUsername_Read"] | undefined,
	update?: ModelTypes["UsersDocAccessFields_legacyUsername_Update"] | undefined,
	delete?: ModelTypes["UsersDocAccessFields_legacyUsername_Delete"] | undefined
};
	["UsersDocAccessFields_legacyUsername_Create"]: {
		permission: boolean
};
	["UsersDocAccessFields_legacyUsername_Read"]: {
		permission: boolean
};
	["UsersDocAccessFields_legacyUsername_Update"]: {
		permission: boolean
};
	["UsersDocAccessFields_legacyUsername_Delete"]: {
		permission: boolean
};
	["UsersDocAccessFields_roles"]: {
		create?: ModelTypes["UsersDocAccessFields_roles_Create"] | undefined,
	read?: ModelTypes["UsersDocAccessFields_roles_Read"] | undefined,
	update?: ModelTypes["UsersDocAccessFields_roles_Update"] | undefined,
	delete?: ModelTypes["UsersDocAccessFields_roles_Delete"] | undefined
};
	["UsersDocAccessFields_roles_Create"]: {
		permission: boolean
};
	["UsersDocAccessFields_roles_Read"]: {
		permission: boolean
};
	["UsersDocAccessFields_roles_Update"]: {
		permission: boolean
};
	["UsersDocAccessFields_roles_Delete"]: {
		permission: boolean
};
	["UsersDocAccessFields_member"]: {
		create?: ModelTypes["UsersDocAccessFields_member_Create"] | undefined,
	read?: ModelTypes["UsersDocAccessFields_member_Read"] | undefined,
	update?: ModelTypes["UsersDocAccessFields_member_Update"] | undefined,
	delete?: ModelTypes["UsersDocAccessFields_member_Delete"] | undefined,
	fields?: ModelTypes["UsersDocAccessFields_member_Fields"] | undefined
};
	["UsersDocAccessFields_member_Create"]: {
		permission: boolean
};
	["UsersDocAccessFields_member_Read"]: {
		permission: boolean
};
	["UsersDocAccessFields_member_Update"]: {
		permission: boolean
};
	["UsersDocAccessFields_member_Delete"]: {
		permission: boolean
};
	["UsersDocAccessFields_member_Fields"]: {
		region?: ModelTypes["UsersDocAccessFields_member_region"] | undefined,
	language?: ModelTypes["UsersDocAccessFields_member_language"] | undefined,
	phone?: ModelTypes["UsersDocAccessFields_member_phone"] | undefined,
	avatar?: ModelTypes["UsersDocAccessFields_member_avatar"] | undefined,
	birthday?: ModelTypes["UsersDocAccessFields_member_birthday"] | undefined,
	gender?: ModelTypes["UsersDocAccessFields_member_gender"] | undefined,
	categories?: ModelTypes["UsersDocAccessFields_member_categories"] | undefined
};
	["UsersDocAccessFields_member_region"]: {
		create?: ModelTypes["UsersDocAccessFields_member_region_Create"] | undefined,
	read?: ModelTypes["UsersDocAccessFields_member_region_Read"] | undefined,
	update?: ModelTypes["UsersDocAccessFields_member_region_Update"] | undefined,
	delete?: ModelTypes["UsersDocAccessFields_member_region_Delete"] | undefined
};
	["UsersDocAccessFields_member_region_Create"]: {
		permission: boolean
};
	["UsersDocAccessFields_member_region_Read"]: {
		permission: boolean
};
	["UsersDocAccessFields_member_region_Update"]: {
		permission: boolean
};
	["UsersDocAccessFields_member_region_Delete"]: {
		permission: boolean
};
	["UsersDocAccessFields_member_language"]: {
		create?: ModelTypes["UsersDocAccessFields_member_language_Create"] | undefined,
	read?: ModelTypes["UsersDocAccessFields_member_language_Read"] | undefined,
	update?: ModelTypes["UsersDocAccessFields_member_language_Update"] | undefined,
	delete?: ModelTypes["UsersDocAccessFields_member_language_Delete"] | undefined
};
	["UsersDocAccessFields_member_language_Create"]: {
		permission: boolean
};
	["UsersDocAccessFields_member_language_Read"]: {
		permission: boolean
};
	["UsersDocAccessFields_member_language_Update"]: {
		permission: boolean
};
	["UsersDocAccessFields_member_language_Delete"]: {
		permission: boolean
};
	["UsersDocAccessFields_member_phone"]: {
		create?: ModelTypes["UsersDocAccessFields_member_phone_Create"] | undefined,
	read?: ModelTypes["UsersDocAccessFields_member_phone_Read"] | undefined,
	update?: ModelTypes["UsersDocAccessFields_member_phone_Update"] | undefined,
	delete?: ModelTypes["UsersDocAccessFields_member_phone_Delete"] | undefined
};
	["UsersDocAccessFields_member_phone_Create"]: {
		permission: boolean
};
	["UsersDocAccessFields_member_phone_Read"]: {
		permission: boolean
};
	["UsersDocAccessFields_member_phone_Update"]: {
		permission: boolean
};
	["UsersDocAccessFields_member_phone_Delete"]: {
		permission: boolean
};
	["UsersDocAccessFields_member_avatar"]: {
		create?: ModelTypes["UsersDocAccessFields_member_avatar_Create"] | undefined,
	read?: ModelTypes["UsersDocAccessFields_member_avatar_Read"] | undefined,
	update?: ModelTypes["UsersDocAccessFields_member_avatar_Update"] | undefined,
	delete?: ModelTypes["UsersDocAccessFields_member_avatar_Delete"] | undefined
};
	["UsersDocAccessFields_member_avatar_Create"]: {
		permission: boolean
};
	["UsersDocAccessFields_member_avatar_Read"]: {
		permission: boolean
};
	["UsersDocAccessFields_member_avatar_Update"]: {
		permission: boolean
};
	["UsersDocAccessFields_member_avatar_Delete"]: {
		permission: boolean
};
	["UsersDocAccessFields_member_birthday"]: {
		create?: ModelTypes["UsersDocAccessFields_member_birthday_Create"] | undefined,
	read?: ModelTypes["UsersDocAccessFields_member_birthday_Read"] | undefined,
	update?: ModelTypes["UsersDocAccessFields_member_birthday_Update"] | undefined,
	delete?: ModelTypes["UsersDocAccessFields_member_birthday_Delete"] | undefined
};
	["UsersDocAccessFields_member_birthday_Create"]: {
		permission: boolean
};
	["UsersDocAccessFields_member_birthday_Read"]: {
		permission: boolean
};
	["UsersDocAccessFields_member_birthday_Update"]: {
		permission: boolean
};
	["UsersDocAccessFields_member_birthday_Delete"]: {
		permission: boolean
};
	["UsersDocAccessFields_member_gender"]: {
		create?: ModelTypes["UsersDocAccessFields_member_gender_Create"] | undefined,
	read?: ModelTypes["UsersDocAccessFields_member_gender_Read"] | undefined,
	update?: ModelTypes["UsersDocAccessFields_member_gender_Update"] | undefined,
	delete?: ModelTypes["UsersDocAccessFields_member_gender_Delete"] | undefined
};
	["UsersDocAccessFields_member_gender_Create"]: {
		permission: boolean
};
	["UsersDocAccessFields_member_gender_Read"]: {
		permission: boolean
};
	["UsersDocAccessFields_member_gender_Update"]: {
		permission: boolean
};
	["UsersDocAccessFields_member_gender_Delete"]: {
		permission: boolean
};
	["UsersDocAccessFields_member_categories"]: {
		create?: ModelTypes["UsersDocAccessFields_member_categories_Create"] | undefined,
	read?: ModelTypes["UsersDocAccessFields_member_categories_Read"] | undefined,
	update?: ModelTypes["UsersDocAccessFields_member_categories_Update"] | undefined,
	delete?: ModelTypes["UsersDocAccessFields_member_categories_Delete"] | undefined
};
	["UsersDocAccessFields_member_categories_Create"]: {
		permission: boolean
};
	["UsersDocAccessFields_member_categories_Read"]: {
		permission: boolean
};
	["UsersDocAccessFields_member_categories_Update"]: {
		permission: boolean
};
	["UsersDocAccessFields_member_categories_Delete"]: {
		permission: boolean
};
	["UsersDocAccessFields_organiser"]: {
		create?: ModelTypes["UsersDocAccessFields_organiser_Create"] | undefined,
	read?: ModelTypes["UsersDocAccessFields_organiser_Read"] | undefined,
	update?: ModelTypes["UsersDocAccessFields_organiser_Update"] | undefined,
	delete?: ModelTypes["UsersDocAccessFields_organiser_Delete"] | undefined,
	fields?: ModelTypes["UsersDocAccessFields_organiser_Fields"] | undefined
};
	["UsersDocAccessFields_organiser_Create"]: {
		permission: boolean
};
	["UsersDocAccessFields_organiser_Read"]: {
		permission: boolean
};
	["UsersDocAccessFields_organiser_Update"]: {
		permission: boolean
};
	["UsersDocAccessFields_organiser_Delete"]: {
		permission: boolean
};
	["UsersDocAccessFields_organiser_Fields"]: {
		logo?: ModelTypes["UsersDocAccessFields_organiser_logo"] | undefined,
	banner?: ModelTypes["UsersDocAccessFields_organiser_banner"] | undefined,
	description?: ModelTypes["UsersDocAccessFields_organiser_description"] | undefined,
	foundedIn?: ModelTypes["UsersDocAccessFields_organiser_foundedIn"] | undefined,
	address?: ModelTypes["UsersDocAccessFields_organiser_address"] | undefined,
	website?: ModelTypes["UsersDocAccessFields_organiser_website"] | undefined,
	facebook?: ModelTypes["UsersDocAccessFields_organiser_facebook"] | undefined,
	instagram?: ModelTypes["UsersDocAccessFields_organiser_instagram"] | undefined,
	language?: ModelTypes["UsersDocAccessFields_organiser_language"] | undefined,
	phone?: ModelTypes["UsersDocAccessFields_organiser_phone"] | undefined,
	followers?: ModelTypes["UsersDocAccessFields_organiser_followers"] | undefined
};
	["UsersDocAccessFields_organiser_logo"]: {
		create?: ModelTypes["UsersDocAccessFields_organiser_logo_Create"] | undefined,
	read?: ModelTypes["UsersDocAccessFields_organiser_logo_Read"] | undefined,
	update?: ModelTypes["UsersDocAccessFields_organiser_logo_Update"] | undefined,
	delete?: ModelTypes["UsersDocAccessFields_organiser_logo_Delete"] | undefined
};
	["UsersDocAccessFields_organiser_logo_Create"]: {
		permission: boolean
};
	["UsersDocAccessFields_organiser_logo_Read"]: {
		permission: boolean
};
	["UsersDocAccessFields_organiser_logo_Update"]: {
		permission: boolean
};
	["UsersDocAccessFields_organiser_logo_Delete"]: {
		permission: boolean
};
	["UsersDocAccessFields_organiser_banner"]: {
		create?: ModelTypes["UsersDocAccessFields_organiser_banner_Create"] | undefined,
	read?: ModelTypes["UsersDocAccessFields_organiser_banner_Read"] | undefined,
	update?: ModelTypes["UsersDocAccessFields_organiser_banner_Update"] | undefined,
	delete?: ModelTypes["UsersDocAccessFields_organiser_banner_Delete"] | undefined
};
	["UsersDocAccessFields_organiser_banner_Create"]: {
		permission: boolean
};
	["UsersDocAccessFields_organiser_banner_Read"]: {
		permission: boolean
};
	["UsersDocAccessFields_organiser_banner_Update"]: {
		permission: boolean
};
	["UsersDocAccessFields_organiser_banner_Delete"]: {
		permission: boolean
};
	["UsersDocAccessFields_organiser_description"]: {
		create?: ModelTypes["UsersDocAccessFields_organiser_description_Create"] | undefined,
	read?: ModelTypes["UsersDocAccessFields_organiser_description_Read"] | undefined,
	update?: ModelTypes["UsersDocAccessFields_organiser_description_Update"] | undefined,
	delete?: ModelTypes["UsersDocAccessFields_organiser_description_Delete"] | undefined
};
	["UsersDocAccessFields_organiser_description_Create"]: {
		permission: boolean
};
	["UsersDocAccessFields_organiser_description_Read"]: {
		permission: boolean
};
	["UsersDocAccessFields_organiser_description_Update"]: {
		permission: boolean
};
	["UsersDocAccessFields_organiser_description_Delete"]: {
		permission: boolean
};
	["UsersDocAccessFields_organiser_foundedIn"]: {
		create?: ModelTypes["UsersDocAccessFields_organiser_foundedIn_Create"] | undefined,
	read?: ModelTypes["UsersDocAccessFields_organiser_foundedIn_Read"] | undefined,
	update?: ModelTypes["UsersDocAccessFields_organiser_foundedIn_Update"] | undefined,
	delete?: ModelTypes["UsersDocAccessFields_organiser_foundedIn_Delete"] | undefined
};
	["UsersDocAccessFields_organiser_foundedIn_Create"]: {
		permission: boolean
};
	["UsersDocAccessFields_organiser_foundedIn_Read"]: {
		permission: boolean
};
	["UsersDocAccessFields_organiser_foundedIn_Update"]: {
		permission: boolean
};
	["UsersDocAccessFields_organiser_foundedIn_Delete"]: {
		permission: boolean
};
	["UsersDocAccessFields_organiser_address"]: {
		create?: ModelTypes["UsersDocAccessFields_organiser_address_Create"] | undefined,
	read?: ModelTypes["UsersDocAccessFields_organiser_address_Read"] | undefined,
	update?: ModelTypes["UsersDocAccessFields_organiser_address_Update"] | undefined,
	delete?: ModelTypes["UsersDocAccessFields_organiser_address_Delete"] | undefined
};
	["UsersDocAccessFields_organiser_address_Create"]: {
		permission: boolean
};
	["UsersDocAccessFields_organiser_address_Read"]: {
		permission: boolean
};
	["UsersDocAccessFields_organiser_address_Update"]: {
		permission: boolean
};
	["UsersDocAccessFields_organiser_address_Delete"]: {
		permission: boolean
};
	["UsersDocAccessFields_organiser_website"]: {
		create?: ModelTypes["UsersDocAccessFields_organiser_website_Create"] | undefined,
	read?: ModelTypes["UsersDocAccessFields_organiser_website_Read"] | undefined,
	update?: ModelTypes["UsersDocAccessFields_organiser_website_Update"] | undefined,
	delete?: ModelTypes["UsersDocAccessFields_organiser_website_Delete"] | undefined
};
	["UsersDocAccessFields_organiser_website_Create"]: {
		permission: boolean
};
	["UsersDocAccessFields_organiser_website_Read"]: {
		permission: boolean
};
	["UsersDocAccessFields_organiser_website_Update"]: {
		permission: boolean
};
	["UsersDocAccessFields_organiser_website_Delete"]: {
		permission: boolean
};
	["UsersDocAccessFields_organiser_facebook"]: {
		create?: ModelTypes["UsersDocAccessFields_organiser_facebook_Create"] | undefined,
	read?: ModelTypes["UsersDocAccessFields_organiser_facebook_Read"] | undefined,
	update?: ModelTypes["UsersDocAccessFields_organiser_facebook_Update"] | undefined,
	delete?: ModelTypes["UsersDocAccessFields_organiser_facebook_Delete"] | undefined
};
	["UsersDocAccessFields_organiser_facebook_Create"]: {
		permission: boolean
};
	["UsersDocAccessFields_organiser_facebook_Read"]: {
		permission: boolean
};
	["UsersDocAccessFields_organiser_facebook_Update"]: {
		permission: boolean
};
	["UsersDocAccessFields_organiser_facebook_Delete"]: {
		permission: boolean
};
	["UsersDocAccessFields_organiser_instagram"]: {
		create?: ModelTypes["UsersDocAccessFields_organiser_instagram_Create"] | undefined,
	read?: ModelTypes["UsersDocAccessFields_organiser_instagram_Read"] | undefined,
	update?: ModelTypes["UsersDocAccessFields_organiser_instagram_Update"] | undefined,
	delete?: ModelTypes["UsersDocAccessFields_organiser_instagram_Delete"] | undefined
};
	["UsersDocAccessFields_organiser_instagram_Create"]: {
		permission: boolean
};
	["UsersDocAccessFields_organiser_instagram_Read"]: {
		permission: boolean
};
	["UsersDocAccessFields_organiser_instagram_Update"]: {
		permission: boolean
};
	["UsersDocAccessFields_organiser_instagram_Delete"]: {
		permission: boolean
};
	["UsersDocAccessFields_organiser_language"]: {
		create?: ModelTypes["UsersDocAccessFields_organiser_language_Create"] | undefined,
	read?: ModelTypes["UsersDocAccessFields_organiser_language_Read"] | undefined,
	update?: ModelTypes["UsersDocAccessFields_organiser_language_Update"] | undefined,
	delete?: ModelTypes["UsersDocAccessFields_organiser_language_Delete"] | undefined
};
	["UsersDocAccessFields_organiser_language_Create"]: {
		permission: boolean
};
	["UsersDocAccessFields_organiser_language_Read"]: {
		permission: boolean
};
	["UsersDocAccessFields_organiser_language_Update"]: {
		permission: boolean
};
	["UsersDocAccessFields_organiser_language_Delete"]: {
		permission: boolean
};
	["UsersDocAccessFields_organiser_phone"]: {
		create?: ModelTypes["UsersDocAccessFields_organiser_phone_Create"] | undefined,
	read?: ModelTypes["UsersDocAccessFields_organiser_phone_Read"] | undefined,
	update?: ModelTypes["UsersDocAccessFields_organiser_phone_Update"] | undefined,
	delete?: ModelTypes["UsersDocAccessFields_organiser_phone_Delete"] | undefined
};
	["UsersDocAccessFields_organiser_phone_Create"]: {
		permission: boolean
};
	["UsersDocAccessFields_organiser_phone_Read"]: {
		permission: boolean
};
	["UsersDocAccessFields_organiser_phone_Update"]: {
		permission: boolean
};
	["UsersDocAccessFields_organiser_phone_Delete"]: {
		permission: boolean
};
	["UsersDocAccessFields_organiser_followers"]: {
		create?: ModelTypes["UsersDocAccessFields_organiser_followers_Create"] | undefined,
	read?: ModelTypes["UsersDocAccessFields_organiser_followers_Read"] | undefined,
	update?: ModelTypes["UsersDocAccessFields_organiser_followers_Update"] | undefined,
	delete?: ModelTypes["UsersDocAccessFields_organiser_followers_Delete"] | undefined
};
	["UsersDocAccessFields_organiser_followers_Create"]: {
		permission: boolean
};
	["UsersDocAccessFields_organiser_followers_Read"]: {
		permission: boolean
};
	["UsersDocAccessFields_organiser_followers_Update"]: {
		permission: boolean
};
	["UsersDocAccessFields_organiser_followers_Delete"]: {
		permission: boolean
};
	["UsersDocAccessFields_emailVerified"]: {
		create?: ModelTypes["UsersDocAccessFields_emailVerified_Create"] | undefined,
	read?: ModelTypes["UsersDocAccessFields_emailVerified_Read"] | undefined,
	update?: ModelTypes["UsersDocAccessFields_emailVerified_Update"] | undefined,
	delete?: ModelTypes["UsersDocAccessFields_emailVerified_Delete"] | undefined
};
	["UsersDocAccessFields_emailVerified_Create"]: {
		permission: boolean
};
	["UsersDocAccessFields_emailVerified_Read"]: {
		permission: boolean
};
	["UsersDocAccessFields_emailVerified_Update"]: {
		permission: boolean
};
	["UsersDocAccessFields_emailVerified_Delete"]: {
		permission: boolean
};
	["UsersDocAccessFields_sso"]: {
		create?: ModelTypes["UsersDocAccessFields_sso_Create"] | undefined,
	read?: ModelTypes["UsersDocAccessFields_sso_Read"] | undefined,
	update?: ModelTypes["UsersDocAccessFields_sso_Update"] | undefined,
	delete?: ModelTypes["UsersDocAccessFields_sso_Delete"] | undefined,
	fields?: ModelTypes["UsersDocAccessFields_sso_Fields"] | undefined
};
	["UsersDocAccessFields_sso_Create"]: {
		permission: boolean
};
	["UsersDocAccessFields_sso_Read"]: {
		permission: boolean
};
	["UsersDocAccessFields_sso_Update"]: {
		permission: boolean
};
	["UsersDocAccessFields_sso_Delete"]: {
		permission: boolean
};
	["UsersDocAccessFields_sso_Fields"]: {
		googleId?: ModelTypes["UsersDocAccessFields_sso_googleId"] | undefined,
	facebookId?: ModelTypes["UsersDocAccessFields_sso_facebookId"] | undefined,
	appleId?: ModelTypes["UsersDocAccessFields_sso_appleId"] | undefined
};
	["UsersDocAccessFields_sso_googleId"]: {
		create?: ModelTypes["UsersDocAccessFields_sso_googleId_Create"] | undefined,
	read?: ModelTypes["UsersDocAccessFields_sso_googleId_Read"] | undefined,
	update?: ModelTypes["UsersDocAccessFields_sso_googleId_Update"] | undefined,
	delete?: ModelTypes["UsersDocAccessFields_sso_googleId_Delete"] | undefined
};
	["UsersDocAccessFields_sso_googleId_Create"]: {
		permission: boolean
};
	["UsersDocAccessFields_sso_googleId_Read"]: {
		permission: boolean
};
	["UsersDocAccessFields_sso_googleId_Update"]: {
		permission: boolean
};
	["UsersDocAccessFields_sso_googleId_Delete"]: {
		permission: boolean
};
	["UsersDocAccessFields_sso_facebookId"]: {
		create?: ModelTypes["UsersDocAccessFields_sso_facebookId_Create"] | undefined,
	read?: ModelTypes["UsersDocAccessFields_sso_facebookId_Read"] | undefined,
	update?: ModelTypes["UsersDocAccessFields_sso_facebookId_Update"] | undefined,
	delete?: ModelTypes["UsersDocAccessFields_sso_facebookId_Delete"] | undefined
};
	["UsersDocAccessFields_sso_facebookId_Create"]: {
		permission: boolean
};
	["UsersDocAccessFields_sso_facebookId_Read"]: {
		permission: boolean
};
	["UsersDocAccessFields_sso_facebookId_Update"]: {
		permission: boolean
};
	["UsersDocAccessFields_sso_facebookId_Delete"]: {
		permission: boolean
};
	["UsersDocAccessFields_sso_appleId"]: {
		create?: ModelTypes["UsersDocAccessFields_sso_appleId_Create"] | undefined,
	read?: ModelTypes["UsersDocAccessFields_sso_appleId_Read"] | undefined,
	update?: ModelTypes["UsersDocAccessFields_sso_appleId_Update"] | undefined,
	delete?: ModelTypes["UsersDocAccessFields_sso_appleId_Delete"] | undefined
};
	["UsersDocAccessFields_sso_appleId_Create"]: {
		permission: boolean
};
	["UsersDocAccessFields_sso_appleId_Read"]: {
		permission: boolean
};
	["UsersDocAccessFields_sso_appleId_Update"]: {
		permission: boolean
};
	["UsersDocAccessFields_sso_appleId_Delete"]: {
		permission: boolean
};
	["UsersDocAccessFields_oldPassword"]: {
		create?: ModelTypes["UsersDocAccessFields_oldPassword_Create"] | undefined,
	read?: ModelTypes["UsersDocAccessFields_oldPassword_Read"] | undefined,
	update?: ModelTypes["UsersDocAccessFields_oldPassword_Update"] | undefined,
	delete?: ModelTypes["UsersDocAccessFields_oldPassword_Delete"] | undefined
};
	["UsersDocAccessFields_oldPassword_Create"]: {
		permission: boolean
};
	["UsersDocAccessFields_oldPassword_Read"]: {
		permission: boolean
};
	["UsersDocAccessFields_oldPassword_Update"]: {
		permission: boolean
};
	["UsersDocAccessFields_oldPassword_Delete"]: {
		permission: boolean
};
	["UsersDocAccessFields_status"]: {
		create?: ModelTypes["UsersDocAccessFields_status_Create"] | undefined,
	read?: ModelTypes["UsersDocAccessFields_status_Read"] | undefined,
	update?: ModelTypes["UsersDocAccessFields_status_Update"] | undefined,
	delete?: ModelTypes["UsersDocAccessFields_status_Delete"] | undefined
};
	["UsersDocAccessFields_status_Create"]: {
		permission: boolean
};
	["UsersDocAccessFields_status_Read"]: {
		permission: boolean
};
	["UsersDocAccessFields_status_Update"]: {
		permission: boolean
};
	["UsersDocAccessFields_status_Delete"]: {
		permission: boolean
};
	["UsersDocAccessFields_updatedAt"]: {
		create?: ModelTypes["UsersDocAccessFields_updatedAt_Create"] | undefined,
	read?: ModelTypes["UsersDocAccessFields_updatedAt_Read"] | undefined,
	update?: ModelTypes["UsersDocAccessFields_updatedAt_Update"] | undefined,
	delete?: ModelTypes["UsersDocAccessFields_updatedAt_Delete"] | undefined
};
	["UsersDocAccessFields_updatedAt_Create"]: {
		permission: boolean
};
	["UsersDocAccessFields_updatedAt_Read"]: {
		permission: boolean
};
	["UsersDocAccessFields_updatedAt_Update"]: {
		permission: boolean
};
	["UsersDocAccessFields_updatedAt_Delete"]: {
		permission: boolean
};
	["UsersDocAccessFields_createdAt"]: {
		create?: ModelTypes["UsersDocAccessFields_createdAt_Create"] | undefined,
	read?: ModelTypes["UsersDocAccessFields_createdAt_Read"] | undefined,
	update?: ModelTypes["UsersDocAccessFields_createdAt_Update"] | undefined,
	delete?: ModelTypes["UsersDocAccessFields_createdAt_Delete"] | undefined
};
	["UsersDocAccessFields_createdAt_Create"]: {
		permission: boolean
};
	["UsersDocAccessFields_createdAt_Read"]: {
		permission: boolean
};
	["UsersDocAccessFields_createdAt_Update"]: {
		permission: boolean
};
	["UsersDocAccessFields_createdAt_Delete"]: {
		permission: boolean
};
	["UsersDocAccessFields_email"]: {
		create?: ModelTypes["UsersDocAccessFields_email_Create"] | undefined,
	read?: ModelTypes["UsersDocAccessFields_email_Read"] | undefined,
	update?: ModelTypes["UsersDocAccessFields_email_Update"] | undefined,
	delete?: ModelTypes["UsersDocAccessFields_email_Delete"] | undefined
};
	["UsersDocAccessFields_email_Create"]: {
		permission: boolean
};
	["UsersDocAccessFields_email_Read"]: {
		permission: boolean
};
	["UsersDocAccessFields_email_Update"]: {
		permission: boolean
};
	["UsersDocAccessFields_email_Delete"]: {
		permission: boolean
};
	["UsersDocAccessFields_password"]: {
		create?: ModelTypes["UsersDocAccessFields_password_Create"] | undefined,
	read?: ModelTypes["UsersDocAccessFields_password_Read"] | undefined,
	update?: ModelTypes["UsersDocAccessFields_password_Update"] | undefined,
	delete?: ModelTypes["UsersDocAccessFields_password_Delete"] | undefined
};
	["UsersDocAccessFields_password_Create"]: {
		permission: boolean
};
	["UsersDocAccessFields_password_Read"]: {
		permission: boolean
};
	["UsersDocAccessFields_password_Update"]: {
		permission: boolean
};
	["UsersDocAccessFields_password_Delete"]: {
		permission: boolean
};
	["UsersCreateDocAccess"]: {
		permission: boolean,
	where?: ModelTypes["JSONObject"] | undefined
};
	["UsersReadDocAccess"]: {
		permission: boolean,
	where?: ModelTypes["JSONObject"] | undefined
};
	["UsersUpdateDocAccess"]: {
		permission: boolean,
	where?: ModelTypes["JSONObject"] | undefined
};
	["UsersDeleteDocAccess"]: {
		permission: boolean,
	where?: ModelTypes["JSONObject"] | undefined
};
	["UsersUnlockDocAccess"]: {
		permission: boolean,
	where?: ModelTypes["JSONObject"] | undefined
};
	["usersMe"]: {
		collection?: string | undefined,
	exp?: number | undefined,
	strategy?: string | undefined,
	token?: string | undefined,
	user?: ModelTypes["User"] | undefined
};
	["Bookmark"]: {
		id?: string | undefined,
	user?: ModelTypes["Bookmark_User_Relationship"] | undefined,
	item?: ModelTypes["Bookmark_Item_Relationship"] | undefined,
	updatedAt?: ModelTypes["DateTime"] | undefined,
	createdAt?: ModelTypes["DateTime"] | undefined
};
	["Bookmark_User_Relationship"]: {
		relationTo?: ModelTypes["Bookmark_User_RelationTo"] | undefined,
	value?: ModelTypes["Bookmark_User"] | undefined
};
	["Bookmark_User_RelationTo"]:Bookmark_User_RelationTo;
	["Bookmark_User"]:ModelTypes["User"];
	["Bookmark_Item_Relationship"]: {
		relationTo?: ModelTypes["Bookmark_Item_RelationTo"] | undefined,
	value?: ModelTypes["Bookmark_Item"] | undefined
};
	["Bookmark_Item_RelationTo"]:Bookmark_Item_RelationTo;
	["Bookmark_Item"]:ModelTypes["Event"] | ModelTypes["Location"] | ModelTypes["Post"];
	["Bookmarks"]: {
		docs?: Array<ModelTypes["Bookmark"] | undefined> | undefined,
	hasNextPage?: boolean | undefined,
	hasPrevPage?: boolean | undefined,
	limit?: number | undefined,
	nextPage?: number | undefined,
	offset?: number | undefined,
	page?: number | undefined,
	pagingCounter?: number | undefined,
	prevPage?: number | undefined,
	totalDocs?: number | undefined,
	totalPages?: number | undefined
};
	["Bookmark_where"]: {
	user?: ModelTypes["Bookmark_user_Relation"] | undefined,
	item?: ModelTypes["Bookmark_item_Relation"] | undefined,
	updatedAt?: ModelTypes["Bookmark_updatedAt_operator"] | undefined,
	createdAt?: ModelTypes["Bookmark_createdAt_operator"] | undefined,
	id?: ModelTypes["Bookmark_id_operator"] | undefined,
	AND?: Array<ModelTypes["Bookmark_where_and"] | undefined> | undefined,
	OR?: Array<ModelTypes["Bookmark_where_or"] | undefined> | undefined
};
	["Bookmark_user_Relation"]: {
	relationTo?: ModelTypes["Bookmark_user_Relation_RelationTo"] | undefined,
	value?: ModelTypes["JSON"] | undefined
};
	["Bookmark_user_Relation_RelationTo"]:Bookmark_user_Relation_RelationTo;
	["Bookmark_item_Relation"]: {
	relationTo?: ModelTypes["Bookmark_item_Relation_RelationTo"] | undefined,
	value?: ModelTypes["JSON"] | undefined
};
	["Bookmark_item_Relation_RelationTo"]:Bookmark_item_Relation_RelationTo;
	["Bookmark_updatedAt_operator"]: {
	equals?: ModelTypes["DateTime"] | undefined,
	not_equals?: ModelTypes["DateTime"] | undefined,
	greater_than_equal?: ModelTypes["DateTime"] | undefined,
	greater_than?: ModelTypes["DateTime"] | undefined,
	less_than_equal?: ModelTypes["DateTime"] | undefined,
	less_than?: ModelTypes["DateTime"] | undefined,
	like?: ModelTypes["DateTime"] | undefined,
	exists?: boolean | undefined
};
	["Bookmark_createdAt_operator"]: {
	equals?: ModelTypes["DateTime"] | undefined,
	not_equals?: ModelTypes["DateTime"] | undefined,
	greater_than_equal?: ModelTypes["DateTime"] | undefined,
	greater_than?: ModelTypes["DateTime"] | undefined,
	less_than_equal?: ModelTypes["DateTime"] | undefined,
	less_than?: ModelTypes["DateTime"] | undefined,
	like?: ModelTypes["DateTime"] | undefined,
	exists?: boolean | undefined
};
	["Bookmark_id_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Bookmark_where_and"]: {
	user?: ModelTypes["Bookmark_user_Relation"] | undefined,
	item?: ModelTypes["Bookmark_item_Relation"] | undefined,
	updatedAt?: ModelTypes["Bookmark_updatedAt_operator"] | undefined,
	createdAt?: ModelTypes["Bookmark_createdAt_operator"] | undefined,
	id?: ModelTypes["Bookmark_id_operator"] | undefined,
	AND?: Array<ModelTypes["Bookmark_where_and"] | undefined> | undefined,
	OR?: Array<ModelTypes["Bookmark_where_or"] | undefined> | undefined
};
	["Bookmark_where_or"]: {
	user?: ModelTypes["Bookmark_user_Relation"] | undefined,
	item?: ModelTypes["Bookmark_item_Relation"] | undefined,
	updatedAt?: ModelTypes["Bookmark_updatedAt_operator"] | undefined,
	createdAt?: ModelTypes["Bookmark_createdAt_operator"] | undefined,
	id?: ModelTypes["Bookmark_id_operator"] | undefined,
	AND?: Array<ModelTypes["Bookmark_where_and"] | undefined> | undefined,
	OR?: Array<ModelTypes["Bookmark_where_or"] | undefined> | undefined
};
	["countBookmarks"]: {
		totalDocs?: number | undefined
};
	["bookmarksDocAccess"]: {
		fields?: ModelTypes["BookmarksDocAccessFields"] | undefined,
	create?: ModelTypes["BookmarksCreateDocAccess"] | undefined,
	read?: ModelTypes["BookmarksReadDocAccess"] | undefined,
	update?: ModelTypes["BookmarksUpdateDocAccess"] | undefined,
	delete?: ModelTypes["BookmarksDeleteDocAccess"] | undefined
};
	["BookmarksDocAccessFields"]: {
		user?: ModelTypes["BookmarksDocAccessFields_user"] | undefined,
	item?: ModelTypes["BookmarksDocAccessFields_item"] | undefined,
	updatedAt?: ModelTypes["BookmarksDocAccessFields_updatedAt"] | undefined,
	createdAt?: ModelTypes["BookmarksDocAccessFields_createdAt"] | undefined
};
	["BookmarksDocAccessFields_user"]: {
		create?: ModelTypes["BookmarksDocAccessFields_user_Create"] | undefined,
	read?: ModelTypes["BookmarksDocAccessFields_user_Read"] | undefined,
	update?: ModelTypes["BookmarksDocAccessFields_user_Update"] | undefined,
	delete?: ModelTypes["BookmarksDocAccessFields_user_Delete"] | undefined
};
	["BookmarksDocAccessFields_user_Create"]: {
		permission: boolean
};
	["BookmarksDocAccessFields_user_Read"]: {
		permission: boolean
};
	["BookmarksDocAccessFields_user_Update"]: {
		permission: boolean
};
	["BookmarksDocAccessFields_user_Delete"]: {
		permission: boolean
};
	["BookmarksDocAccessFields_item"]: {
		create?: ModelTypes["BookmarksDocAccessFields_item_Create"] | undefined,
	read?: ModelTypes["BookmarksDocAccessFields_item_Read"] | undefined,
	update?: ModelTypes["BookmarksDocAccessFields_item_Update"] | undefined,
	delete?: ModelTypes["BookmarksDocAccessFields_item_Delete"] | undefined
};
	["BookmarksDocAccessFields_item_Create"]: {
		permission: boolean
};
	["BookmarksDocAccessFields_item_Read"]: {
		permission: boolean
};
	["BookmarksDocAccessFields_item_Update"]: {
		permission: boolean
};
	["BookmarksDocAccessFields_item_Delete"]: {
		permission: boolean
};
	["BookmarksDocAccessFields_updatedAt"]: {
		create?: ModelTypes["BookmarksDocAccessFields_updatedAt_Create"] | undefined,
	read?: ModelTypes["BookmarksDocAccessFields_updatedAt_Read"] | undefined,
	update?: ModelTypes["BookmarksDocAccessFields_updatedAt_Update"] | undefined,
	delete?: ModelTypes["BookmarksDocAccessFields_updatedAt_Delete"] | undefined
};
	["BookmarksDocAccessFields_updatedAt_Create"]: {
		permission: boolean
};
	["BookmarksDocAccessFields_updatedAt_Read"]: {
		permission: boolean
};
	["BookmarksDocAccessFields_updatedAt_Update"]: {
		permission: boolean
};
	["BookmarksDocAccessFields_updatedAt_Delete"]: {
		permission: boolean
};
	["BookmarksDocAccessFields_createdAt"]: {
		create?: ModelTypes["BookmarksDocAccessFields_createdAt_Create"] | undefined,
	read?: ModelTypes["BookmarksDocAccessFields_createdAt_Read"] | undefined,
	update?: ModelTypes["BookmarksDocAccessFields_createdAt_Update"] | undefined,
	delete?: ModelTypes["BookmarksDocAccessFields_createdAt_Delete"] | undefined
};
	["BookmarksDocAccessFields_createdAt_Create"]: {
		permission: boolean
};
	["BookmarksDocAccessFields_createdAt_Read"]: {
		permission: boolean
};
	["BookmarksDocAccessFields_createdAt_Update"]: {
		permission: boolean
};
	["BookmarksDocAccessFields_createdAt_Delete"]: {
		permission: boolean
};
	["BookmarksCreateDocAccess"]: {
		permission: boolean,
	where?: ModelTypes["JSONObject"] | undefined
};
	["BookmarksReadDocAccess"]: {
		permission: boolean,
	where?: ModelTypes["JSONObject"] | undefined
};
	["BookmarksUpdateDocAccess"]: {
		permission: boolean,
	where?: ModelTypes["JSONObject"] | undefined
};
	["BookmarksDeleteDocAccess"]: {
		permission: boolean,
	where?: ModelTypes["JSONObject"] | undefined
};
	["WebAppBookmark"]: {
		id?: string | undefined,
	user?: ModelTypes["WebAppBookmark_User_Relationship"] | undefined,
	event?: ModelTypes["WebAppBookmark_Event_Relationship"] | undefined,
	productType?: ModelTypes["WebAppBookmark_productType"] | undefined,
	item?: string | undefined,
	entryNumber?: string | undefined,
	updatedAt?: ModelTypes["DateTime"] | undefined,
	createdAt?: ModelTypes["DateTime"] | undefined
};
	["WebAppBookmark_User_Relationship"]: {
		relationTo?: ModelTypes["WebAppBookmark_User_RelationTo"] | undefined,
	value?: ModelTypes["WebAppBookmark_User"] | undefined
};
	["WebAppBookmark_User_RelationTo"]:WebAppBookmark_User_RelationTo;
	["WebAppBookmark_User"]:ModelTypes["User"];
	["WebAppBookmark_Event_Relationship"]: {
		relationTo?: ModelTypes["WebAppBookmark_Event_RelationTo"] | undefined,
	value?: ModelTypes["WebAppBookmark_Event"] | undefined
};
	["WebAppBookmark_Event_RelationTo"]:WebAppBookmark_Event_RelationTo;
	["WebAppBookmark_Event"]:ModelTypes["Event"];
	["WebAppBookmark_productType"]:WebAppBookmark_productType;
	["WebAppBookmarks"]: {
		docs?: Array<ModelTypes["WebAppBookmark"] | undefined> | undefined,
	hasNextPage?: boolean | undefined,
	hasPrevPage?: boolean | undefined,
	limit?: number | undefined,
	nextPage?: number | undefined,
	offset?: number | undefined,
	page?: number | undefined,
	pagingCounter?: number | undefined,
	prevPage?: number | undefined,
	totalDocs?: number | undefined,
	totalPages?: number | undefined
};
	["WebAppBookmark_where"]: {
	user?: ModelTypes["WebAppBookmark_user_Relation"] | undefined,
	event?: ModelTypes["WebAppBookmark_event_Relation"] | undefined,
	productType?: ModelTypes["WebAppBookmark_productType_operator"] | undefined,
	item?: ModelTypes["WebAppBookmark_item_operator"] | undefined,
	entryNumber?: ModelTypes["WebAppBookmark_entryNumber_operator"] | undefined,
	updatedAt?: ModelTypes["WebAppBookmark_updatedAt_operator"] | undefined,
	createdAt?: ModelTypes["WebAppBookmark_createdAt_operator"] | undefined,
	id?: ModelTypes["WebAppBookmark_id_operator"] | undefined,
	AND?: Array<ModelTypes["WebAppBookmark_where_and"] | undefined> | undefined,
	OR?: Array<ModelTypes["WebAppBookmark_where_or"] | undefined> | undefined
};
	["WebAppBookmark_user_Relation"]: {
	relationTo?: ModelTypes["WebAppBookmark_user_Relation_RelationTo"] | undefined,
	value?: ModelTypes["JSON"] | undefined
};
	["WebAppBookmark_user_Relation_RelationTo"]:WebAppBookmark_user_Relation_RelationTo;
	["WebAppBookmark_event_Relation"]: {
	relationTo?: ModelTypes["WebAppBookmark_event_Relation_RelationTo"] | undefined,
	value?: ModelTypes["JSON"] | undefined
};
	["WebAppBookmark_event_Relation_RelationTo"]:WebAppBookmark_event_Relation_RelationTo;
	["WebAppBookmark_productType_operator"]: {
	equals?: ModelTypes["WebAppBookmark_productType_Input"] | undefined,
	not_equals?: ModelTypes["WebAppBookmark_productType_Input"] | undefined,
	in?: Array<ModelTypes["WebAppBookmark_productType_Input"] | undefined> | undefined,
	not_in?: Array<ModelTypes["WebAppBookmark_productType_Input"] | undefined> | undefined,
	all?: Array<ModelTypes["WebAppBookmark_productType_Input"] | undefined> | undefined,
	exists?: boolean | undefined
};
	["WebAppBookmark_productType_Input"]:WebAppBookmark_productType_Input;
	["WebAppBookmark_item_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["WebAppBookmark_entryNumber_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["WebAppBookmark_updatedAt_operator"]: {
	equals?: ModelTypes["DateTime"] | undefined,
	not_equals?: ModelTypes["DateTime"] | undefined,
	greater_than_equal?: ModelTypes["DateTime"] | undefined,
	greater_than?: ModelTypes["DateTime"] | undefined,
	less_than_equal?: ModelTypes["DateTime"] | undefined,
	less_than?: ModelTypes["DateTime"] | undefined,
	like?: ModelTypes["DateTime"] | undefined,
	exists?: boolean | undefined
};
	["WebAppBookmark_createdAt_operator"]: {
	equals?: ModelTypes["DateTime"] | undefined,
	not_equals?: ModelTypes["DateTime"] | undefined,
	greater_than_equal?: ModelTypes["DateTime"] | undefined,
	greater_than?: ModelTypes["DateTime"] | undefined,
	less_than_equal?: ModelTypes["DateTime"] | undefined,
	less_than?: ModelTypes["DateTime"] | undefined,
	like?: ModelTypes["DateTime"] | undefined,
	exists?: boolean | undefined
};
	["WebAppBookmark_id_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["WebAppBookmark_where_and"]: {
	user?: ModelTypes["WebAppBookmark_user_Relation"] | undefined,
	event?: ModelTypes["WebAppBookmark_event_Relation"] | undefined,
	productType?: ModelTypes["WebAppBookmark_productType_operator"] | undefined,
	item?: ModelTypes["WebAppBookmark_item_operator"] | undefined,
	entryNumber?: ModelTypes["WebAppBookmark_entryNumber_operator"] | undefined,
	updatedAt?: ModelTypes["WebAppBookmark_updatedAt_operator"] | undefined,
	createdAt?: ModelTypes["WebAppBookmark_createdAt_operator"] | undefined,
	id?: ModelTypes["WebAppBookmark_id_operator"] | undefined,
	AND?: Array<ModelTypes["WebAppBookmark_where_and"] | undefined> | undefined,
	OR?: Array<ModelTypes["WebAppBookmark_where_or"] | undefined> | undefined
};
	["WebAppBookmark_where_or"]: {
	user?: ModelTypes["WebAppBookmark_user_Relation"] | undefined,
	event?: ModelTypes["WebAppBookmark_event_Relation"] | undefined,
	productType?: ModelTypes["WebAppBookmark_productType_operator"] | undefined,
	item?: ModelTypes["WebAppBookmark_item_operator"] | undefined,
	entryNumber?: ModelTypes["WebAppBookmark_entryNumber_operator"] | undefined,
	updatedAt?: ModelTypes["WebAppBookmark_updatedAt_operator"] | undefined,
	createdAt?: ModelTypes["WebAppBookmark_createdAt_operator"] | undefined,
	id?: ModelTypes["WebAppBookmark_id_operator"] | undefined,
	AND?: Array<ModelTypes["WebAppBookmark_where_and"] | undefined> | undefined,
	OR?: Array<ModelTypes["WebAppBookmark_where_or"] | undefined> | undefined
};
	["countWebAppBookmarks"]: {
		totalDocs?: number | undefined
};
	["webAppBookmarksDocAccess"]: {
		fields?: ModelTypes["WebAppBookmarksDocAccessFields"] | undefined,
	create?: ModelTypes["WebAppBookmarksCreateDocAccess"] | undefined,
	read?: ModelTypes["WebAppBookmarksReadDocAccess"] | undefined,
	update?: ModelTypes["WebAppBookmarksUpdateDocAccess"] | undefined,
	delete?: ModelTypes["WebAppBookmarksDeleteDocAccess"] | undefined
};
	["WebAppBookmarksDocAccessFields"]: {
		user?: ModelTypes["WebAppBookmarksDocAccessFields_user"] | undefined,
	event?: ModelTypes["WebAppBookmarksDocAccessFields_event"] | undefined,
	productType?: ModelTypes["WebAppBookmarksDocAccessFields_productType"] | undefined,
	item?: ModelTypes["WebAppBookmarksDocAccessFields_item"] | undefined,
	entryNumber?: ModelTypes["WebAppBookmarksDocAccessFields_entryNumber"] | undefined,
	updatedAt?: ModelTypes["WebAppBookmarksDocAccessFields_updatedAt"] | undefined,
	createdAt?: ModelTypes["WebAppBookmarksDocAccessFields_createdAt"] | undefined
};
	["WebAppBookmarksDocAccessFields_user"]: {
		create?: ModelTypes["WebAppBookmarksDocAccessFields_user_Create"] | undefined,
	read?: ModelTypes["WebAppBookmarksDocAccessFields_user_Read"] | undefined,
	update?: ModelTypes["WebAppBookmarksDocAccessFields_user_Update"] | undefined,
	delete?: ModelTypes["WebAppBookmarksDocAccessFields_user_Delete"] | undefined
};
	["WebAppBookmarksDocAccessFields_user_Create"]: {
		permission: boolean
};
	["WebAppBookmarksDocAccessFields_user_Read"]: {
		permission: boolean
};
	["WebAppBookmarksDocAccessFields_user_Update"]: {
		permission: boolean
};
	["WebAppBookmarksDocAccessFields_user_Delete"]: {
		permission: boolean
};
	["WebAppBookmarksDocAccessFields_event"]: {
		create?: ModelTypes["WebAppBookmarksDocAccessFields_event_Create"] | undefined,
	read?: ModelTypes["WebAppBookmarksDocAccessFields_event_Read"] | undefined,
	update?: ModelTypes["WebAppBookmarksDocAccessFields_event_Update"] | undefined,
	delete?: ModelTypes["WebAppBookmarksDocAccessFields_event_Delete"] | undefined
};
	["WebAppBookmarksDocAccessFields_event_Create"]: {
		permission: boolean
};
	["WebAppBookmarksDocAccessFields_event_Read"]: {
		permission: boolean
};
	["WebAppBookmarksDocAccessFields_event_Update"]: {
		permission: boolean
};
	["WebAppBookmarksDocAccessFields_event_Delete"]: {
		permission: boolean
};
	["WebAppBookmarksDocAccessFields_productType"]: {
		create?: ModelTypes["WebAppBookmarksDocAccessFields_productType_Create"] | undefined,
	read?: ModelTypes["WebAppBookmarksDocAccessFields_productType_Read"] | undefined,
	update?: ModelTypes["WebAppBookmarksDocAccessFields_productType_Update"] | undefined,
	delete?: ModelTypes["WebAppBookmarksDocAccessFields_productType_Delete"] | undefined
};
	["WebAppBookmarksDocAccessFields_productType_Create"]: {
		permission: boolean
};
	["WebAppBookmarksDocAccessFields_productType_Read"]: {
		permission: boolean
};
	["WebAppBookmarksDocAccessFields_productType_Update"]: {
		permission: boolean
};
	["WebAppBookmarksDocAccessFields_productType_Delete"]: {
		permission: boolean
};
	["WebAppBookmarksDocAccessFields_item"]: {
		create?: ModelTypes["WebAppBookmarksDocAccessFields_item_Create"] | undefined,
	read?: ModelTypes["WebAppBookmarksDocAccessFields_item_Read"] | undefined,
	update?: ModelTypes["WebAppBookmarksDocAccessFields_item_Update"] | undefined,
	delete?: ModelTypes["WebAppBookmarksDocAccessFields_item_Delete"] | undefined
};
	["WebAppBookmarksDocAccessFields_item_Create"]: {
		permission: boolean
};
	["WebAppBookmarksDocAccessFields_item_Read"]: {
		permission: boolean
};
	["WebAppBookmarksDocAccessFields_item_Update"]: {
		permission: boolean
};
	["WebAppBookmarksDocAccessFields_item_Delete"]: {
		permission: boolean
};
	["WebAppBookmarksDocAccessFields_entryNumber"]: {
		create?: ModelTypes["WebAppBookmarksDocAccessFields_entryNumber_Create"] | undefined,
	read?: ModelTypes["WebAppBookmarksDocAccessFields_entryNumber_Read"] | undefined,
	update?: ModelTypes["WebAppBookmarksDocAccessFields_entryNumber_Update"] | undefined,
	delete?: ModelTypes["WebAppBookmarksDocAccessFields_entryNumber_Delete"] | undefined
};
	["WebAppBookmarksDocAccessFields_entryNumber_Create"]: {
		permission: boolean
};
	["WebAppBookmarksDocAccessFields_entryNumber_Read"]: {
		permission: boolean
};
	["WebAppBookmarksDocAccessFields_entryNumber_Update"]: {
		permission: boolean
};
	["WebAppBookmarksDocAccessFields_entryNumber_Delete"]: {
		permission: boolean
};
	["WebAppBookmarksDocAccessFields_updatedAt"]: {
		create?: ModelTypes["WebAppBookmarksDocAccessFields_updatedAt_Create"] | undefined,
	read?: ModelTypes["WebAppBookmarksDocAccessFields_updatedAt_Read"] | undefined,
	update?: ModelTypes["WebAppBookmarksDocAccessFields_updatedAt_Update"] | undefined,
	delete?: ModelTypes["WebAppBookmarksDocAccessFields_updatedAt_Delete"] | undefined
};
	["WebAppBookmarksDocAccessFields_updatedAt_Create"]: {
		permission: boolean
};
	["WebAppBookmarksDocAccessFields_updatedAt_Read"]: {
		permission: boolean
};
	["WebAppBookmarksDocAccessFields_updatedAt_Update"]: {
		permission: boolean
};
	["WebAppBookmarksDocAccessFields_updatedAt_Delete"]: {
		permission: boolean
};
	["WebAppBookmarksDocAccessFields_createdAt"]: {
		create?: ModelTypes["WebAppBookmarksDocAccessFields_createdAt_Create"] | undefined,
	read?: ModelTypes["WebAppBookmarksDocAccessFields_createdAt_Read"] | undefined,
	update?: ModelTypes["WebAppBookmarksDocAccessFields_createdAt_Update"] | undefined,
	delete?: ModelTypes["WebAppBookmarksDocAccessFields_createdAt_Delete"] | undefined
};
	["WebAppBookmarksDocAccessFields_createdAt_Create"]: {
		permission: boolean
};
	["WebAppBookmarksDocAccessFields_createdAt_Read"]: {
		permission: boolean
};
	["WebAppBookmarksDocAccessFields_createdAt_Update"]: {
		permission: boolean
};
	["WebAppBookmarksDocAccessFields_createdAt_Delete"]: {
		permission: boolean
};
	["WebAppBookmarksCreateDocAccess"]: {
		permission: boolean,
	where?: ModelTypes["JSONObject"] | undefined
};
	["WebAppBookmarksReadDocAccess"]: {
		permission: boolean,
	where?: ModelTypes["JSONObject"] | undefined
};
	["WebAppBookmarksUpdateDocAccess"]: {
		permission: boolean,
	where?: ModelTypes["JSONObject"] | undefined
};
	["WebAppBookmarksDeleteDocAccess"]: {
		permission: boolean,
	where?: ModelTypes["JSONObject"] | undefined
};
	["ViewRecord"]: {
		id?: string | undefined,
	user?: ModelTypes["ViewRecord_User_Relationship"] | undefined,
	item?: ModelTypes["ViewRecord_Item_Relationship"] | undefined,
	updatedAt?: ModelTypes["DateTime"] | undefined,
	createdAt?: ModelTypes["DateTime"] | undefined
};
	["ViewRecord_User_Relationship"]: {
		relationTo?: ModelTypes["ViewRecord_User_RelationTo"] | undefined,
	value?: ModelTypes["ViewRecord_User"] | undefined
};
	["ViewRecord_User_RelationTo"]:ViewRecord_User_RelationTo;
	["ViewRecord_User"]:ModelTypes["User"];
	["ViewRecord_Item_Relationship"]: {
		relationTo?: ModelTypes["ViewRecord_Item_RelationTo"] | undefined,
	value?: ModelTypes["ViewRecord_Item"] | undefined
};
	["ViewRecord_Item_RelationTo"]:ViewRecord_Item_RelationTo;
	["ViewRecord_Item"]:ModelTypes["Event"] | ModelTypes["Location"] | ModelTypes["Post"];
	["ViewRecords"]: {
		docs?: Array<ModelTypes["ViewRecord"] | undefined> | undefined,
	hasNextPage?: boolean | undefined,
	hasPrevPage?: boolean | undefined,
	limit?: number | undefined,
	nextPage?: number | undefined,
	offset?: number | undefined,
	page?: number | undefined,
	pagingCounter?: number | undefined,
	prevPage?: number | undefined,
	totalDocs?: number | undefined,
	totalPages?: number | undefined
};
	["ViewRecord_where"]: {
	user?: ModelTypes["ViewRecord_user_Relation"] | undefined,
	item?: ModelTypes["ViewRecord_item_Relation"] | undefined,
	updatedAt?: ModelTypes["ViewRecord_updatedAt_operator"] | undefined,
	createdAt?: ModelTypes["ViewRecord_createdAt_operator"] | undefined,
	id?: ModelTypes["ViewRecord_id_operator"] | undefined,
	AND?: Array<ModelTypes["ViewRecord_where_and"] | undefined> | undefined,
	OR?: Array<ModelTypes["ViewRecord_where_or"] | undefined> | undefined
};
	["ViewRecord_user_Relation"]: {
	relationTo?: ModelTypes["ViewRecord_user_Relation_RelationTo"] | undefined,
	value?: ModelTypes["JSON"] | undefined
};
	["ViewRecord_user_Relation_RelationTo"]:ViewRecord_user_Relation_RelationTo;
	["ViewRecord_item_Relation"]: {
	relationTo?: ModelTypes["ViewRecord_item_Relation_RelationTo"] | undefined,
	value?: ModelTypes["JSON"] | undefined
};
	["ViewRecord_item_Relation_RelationTo"]:ViewRecord_item_Relation_RelationTo;
	["ViewRecord_updatedAt_operator"]: {
	equals?: ModelTypes["DateTime"] | undefined,
	not_equals?: ModelTypes["DateTime"] | undefined,
	greater_than_equal?: ModelTypes["DateTime"] | undefined,
	greater_than?: ModelTypes["DateTime"] | undefined,
	less_than_equal?: ModelTypes["DateTime"] | undefined,
	less_than?: ModelTypes["DateTime"] | undefined,
	like?: ModelTypes["DateTime"] | undefined,
	exists?: boolean | undefined
};
	["ViewRecord_createdAt_operator"]: {
	equals?: ModelTypes["DateTime"] | undefined,
	not_equals?: ModelTypes["DateTime"] | undefined,
	greater_than_equal?: ModelTypes["DateTime"] | undefined,
	greater_than?: ModelTypes["DateTime"] | undefined,
	less_than_equal?: ModelTypes["DateTime"] | undefined,
	less_than?: ModelTypes["DateTime"] | undefined,
	like?: ModelTypes["DateTime"] | undefined,
	exists?: boolean | undefined
};
	["ViewRecord_id_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["ViewRecord_where_and"]: {
	user?: ModelTypes["ViewRecord_user_Relation"] | undefined,
	item?: ModelTypes["ViewRecord_item_Relation"] | undefined,
	updatedAt?: ModelTypes["ViewRecord_updatedAt_operator"] | undefined,
	createdAt?: ModelTypes["ViewRecord_createdAt_operator"] | undefined,
	id?: ModelTypes["ViewRecord_id_operator"] | undefined,
	AND?: Array<ModelTypes["ViewRecord_where_and"] | undefined> | undefined,
	OR?: Array<ModelTypes["ViewRecord_where_or"] | undefined> | undefined
};
	["ViewRecord_where_or"]: {
	user?: ModelTypes["ViewRecord_user_Relation"] | undefined,
	item?: ModelTypes["ViewRecord_item_Relation"] | undefined,
	updatedAt?: ModelTypes["ViewRecord_updatedAt_operator"] | undefined,
	createdAt?: ModelTypes["ViewRecord_createdAt_operator"] | undefined,
	id?: ModelTypes["ViewRecord_id_operator"] | undefined,
	AND?: Array<ModelTypes["ViewRecord_where_and"] | undefined> | undefined,
	OR?: Array<ModelTypes["ViewRecord_where_or"] | undefined> | undefined
};
	["countViewRecords"]: {
		totalDocs?: number | undefined
};
	["view_recordsDocAccess"]: {
		fields?: ModelTypes["ViewRecordsDocAccessFields"] | undefined,
	create?: ModelTypes["ViewRecordsCreateDocAccess"] | undefined,
	read?: ModelTypes["ViewRecordsReadDocAccess"] | undefined,
	update?: ModelTypes["ViewRecordsUpdateDocAccess"] | undefined,
	delete?: ModelTypes["ViewRecordsDeleteDocAccess"] | undefined
};
	["ViewRecordsDocAccessFields"]: {
		user?: ModelTypes["ViewRecordsDocAccessFields_user"] | undefined,
	item?: ModelTypes["ViewRecordsDocAccessFields_item"] | undefined,
	updatedAt?: ModelTypes["ViewRecordsDocAccessFields_updatedAt"] | undefined,
	createdAt?: ModelTypes["ViewRecordsDocAccessFields_createdAt"] | undefined
};
	["ViewRecordsDocAccessFields_user"]: {
		create?: ModelTypes["ViewRecordsDocAccessFields_user_Create"] | undefined,
	read?: ModelTypes["ViewRecordsDocAccessFields_user_Read"] | undefined,
	update?: ModelTypes["ViewRecordsDocAccessFields_user_Update"] | undefined,
	delete?: ModelTypes["ViewRecordsDocAccessFields_user_Delete"] | undefined
};
	["ViewRecordsDocAccessFields_user_Create"]: {
		permission: boolean
};
	["ViewRecordsDocAccessFields_user_Read"]: {
		permission: boolean
};
	["ViewRecordsDocAccessFields_user_Update"]: {
		permission: boolean
};
	["ViewRecordsDocAccessFields_user_Delete"]: {
		permission: boolean
};
	["ViewRecordsDocAccessFields_item"]: {
		create?: ModelTypes["ViewRecordsDocAccessFields_item_Create"] | undefined,
	read?: ModelTypes["ViewRecordsDocAccessFields_item_Read"] | undefined,
	update?: ModelTypes["ViewRecordsDocAccessFields_item_Update"] | undefined,
	delete?: ModelTypes["ViewRecordsDocAccessFields_item_Delete"] | undefined
};
	["ViewRecordsDocAccessFields_item_Create"]: {
		permission: boolean
};
	["ViewRecordsDocAccessFields_item_Read"]: {
		permission: boolean
};
	["ViewRecordsDocAccessFields_item_Update"]: {
		permission: boolean
};
	["ViewRecordsDocAccessFields_item_Delete"]: {
		permission: boolean
};
	["ViewRecordsDocAccessFields_updatedAt"]: {
		create?: ModelTypes["ViewRecordsDocAccessFields_updatedAt_Create"] | undefined,
	read?: ModelTypes["ViewRecordsDocAccessFields_updatedAt_Read"] | undefined,
	update?: ModelTypes["ViewRecordsDocAccessFields_updatedAt_Update"] | undefined,
	delete?: ModelTypes["ViewRecordsDocAccessFields_updatedAt_Delete"] | undefined
};
	["ViewRecordsDocAccessFields_updatedAt_Create"]: {
		permission: boolean
};
	["ViewRecordsDocAccessFields_updatedAt_Read"]: {
		permission: boolean
};
	["ViewRecordsDocAccessFields_updatedAt_Update"]: {
		permission: boolean
};
	["ViewRecordsDocAccessFields_updatedAt_Delete"]: {
		permission: boolean
};
	["ViewRecordsDocAccessFields_createdAt"]: {
		create?: ModelTypes["ViewRecordsDocAccessFields_createdAt_Create"] | undefined,
	read?: ModelTypes["ViewRecordsDocAccessFields_createdAt_Read"] | undefined,
	update?: ModelTypes["ViewRecordsDocAccessFields_createdAt_Update"] | undefined,
	delete?: ModelTypes["ViewRecordsDocAccessFields_createdAt_Delete"] | undefined
};
	["ViewRecordsDocAccessFields_createdAt_Create"]: {
		permission: boolean
};
	["ViewRecordsDocAccessFields_createdAt_Read"]: {
		permission: boolean
};
	["ViewRecordsDocAccessFields_createdAt_Update"]: {
		permission: boolean
};
	["ViewRecordsDocAccessFields_createdAt_Delete"]: {
		permission: boolean
};
	["ViewRecordsCreateDocAccess"]: {
		permission: boolean,
	where?: ModelTypes["JSONObject"] | undefined
};
	["ViewRecordsReadDocAccess"]: {
		permission: boolean,
	where?: ModelTypes["JSONObject"] | undefined
};
	["ViewRecordsUpdateDocAccess"]: {
		permission: boolean,
	where?: ModelTypes["JSONObject"] | undefined
};
	["ViewRecordsDeleteDocAccess"]: {
		permission: boolean,
	where?: ModelTypes["JSONObject"] | undefined
};
	["Comment"]: {
		id?: string | undefined,
	commenter?: ModelTypes["User"] | undefined,
	content?: string | undefined,
	image?: ModelTypes["Media"] | undefined,
	target?: ModelTypes["Comment_Target_Relationship"] | undefined,
	parent?: ModelTypes["Comment_Parent_Relationship"] | undefined,
	isReviewed?: boolean | undefined,
	reviewedBy?: ModelTypes["User"] | undefined,
	banned?: boolean | undefined,
	type?: string | undefined,
	updatedAt?: ModelTypes["DateTime"] | undefined,
	createdAt?: ModelTypes["DateTime"] | undefined
};
	["Comment_Image_where"]: {
	alt?: ModelTypes["Comment_Image_alt_operator"] | undefined,
	updatedAt?: ModelTypes["Comment_Image_updatedAt_operator"] | undefined,
	createdAt?: ModelTypes["Comment_Image_createdAt_operator"] | undefined,
	url?: ModelTypes["Comment_Image_url_operator"] | undefined,
	filename?: ModelTypes["Comment_Image_filename_operator"] | undefined,
	mimeType?: ModelTypes["Comment_Image_mimeType_operator"] | undefined,
	filesize?: ModelTypes["Comment_Image_filesize_operator"] | undefined,
	width?: ModelTypes["Comment_Image_width_operator"] | undefined,
	height?: ModelTypes["Comment_Image_height_operator"] | undefined,
	focalX?: ModelTypes["Comment_Image_focalX_operator"] | undefined,
	focalY?: ModelTypes["Comment_Image_focalY_operator"] | undefined,
	sizes__small__url?: ModelTypes["Comment_Image_sizes__small__url_operator"] | undefined,
	sizes__small__width?: ModelTypes["Comment_Image_sizes__small__width_operator"] | undefined,
	sizes__small__height?: ModelTypes["Comment_Image_sizes__small__height_operator"] | undefined,
	sizes__small__mimeType?: ModelTypes["Comment_Image_sizes__small__mimeType_operator"] | undefined,
	sizes__small__filesize?: ModelTypes["Comment_Image_sizes__small__filesize_operator"] | undefined,
	sizes__small__filename?: ModelTypes["Comment_Image_sizes__small__filename_operator"] | undefined,
	sizes__medium__url?: ModelTypes["Comment_Image_sizes__medium__url_operator"] | undefined,
	sizes__medium__width?: ModelTypes["Comment_Image_sizes__medium__width_operator"] | undefined,
	sizes__medium__height?: ModelTypes["Comment_Image_sizes__medium__height_operator"] | undefined,
	sizes__medium__mimeType?: ModelTypes["Comment_Image_sizes__medium__mimeType_operator"] | undefined,
	sizes__medium__filesize?: ModelTypes["Comment_Image_sizes__medium__filesize_operator"] | undefined,
	sizes__medium__filename?: ModelTypes["Comment_Image_sizes__medium__filename_operator"] | undefined,
	sizes__large__url?: ModelTypes["Comment_Image_sizes__large__url_operator"] | undefined,
	sizes__large__width?: ModelTypes["Comment_Image_sizes__large__width_operator"] | undefined,
	sizes__large__height?: ModelTypes["Comment_Image_sizes__large__height_operator"] | undefined,
	sizes__large__mimeType?: ModelTypes["Comment_Image_sizes__large__mimeType_operator"] | undefined,
	sizes__large__filesize?: ModelTypes["Comment_Image_sizes__large__filesize_operator"] | undefined,
	sizes__large__filename?: ModelTypes["Comment_Image_sizes__large__filename_operator"] | undefined,
	sizes__small_jpeg__url?: ModelTypes["Comment_Image_sizes__small_jpeg__url_operator"] | undefined,
	sizes__small_jpeg__width?: ModelTypes["Comment_Image_sizes__small_jpeg__width_operator"] | undefined,
	sizes__small_jpeg__height?: ModelTypes["Comment_Image_sizes__small_jpeg__height_operator"] | undefined,
	sizes__small_jpeg__mimeType?: ModelTypes["Comment_Image_sizes__small_jpeg__mimeType_operator"] | undefined,
	sizes__small_jpeg__filesize?: ModelTypes["Comment_Image_sizes__small_jpeg__filesize_operator"] | undefined,
	sizes__small_jpeg__filename?: ModelTypes["Comment_Image_sizes__small_jpeg__filename_operator"] | undefined,
	sizes__medium_jpeg__url?: ModelTypes["Comment_Image_sizes__medium_jpeg__url_operator"] | undefined,
	sizes__medium_jpeg__width?: ModelTypes["Comment_Image_sizes__medium_jpeg__width_operator"] | undefined,
	sizes__medium_jpeg__height?: ModelTypes["Comment_Image_sizes__medium_jpeg__height_operator"] | undefined,
	sizes__medium_jpeg__mimeType?: ModelTypes["Comment_Image_sizes__medium_jpeg__mimeType_operator"] | undefined,
	sizes__medium_jpeg__filesize?: ModelTypes["Comment_Image_sizes__medium_jpeg__filesize_operator"] | undefined,
	sizes__medium_jpeg__filename?: ModelTypes["Comment_Image_sizes__medium_jpeg__filename_operator"] | undefined,
	sizes__large_jpeg__url?: ModelTypes["Comment_Image_sizes__large_jpeg__url_operator"] | undefined,
	sizes__large_jpeg__width?: ModelTypes["Comment_Image_sizes__large_jpeg__width_operator"] | undefined,
	sizes__large_jpeg__height?: ModelTypes["Comment_Image_sizes__large_jpeg__height_operator"] | undefined,
	sizes__large_jpeg__mimeType?: ModelTypes["Comment_Image_sizes__large_jpeg__mimeType_operator"] | undefined,
	sizes__large_jpeg__filesize?: ModelTypes["Comment_Image_sizes__large_jpeg__filesize_operator"] | undefined,
	sizes__large_jpeg__filename?: ModelTypes["Comment_Image_sizes__large_jpeg__filename_operator"] | undefined,
	id?: ModelTypes["Comment_Image_id_operator"] | undefined,
	AND?: Array<ModelTypes["Comment_Image_where_and"] | undefined> | undefined,
	OR?: Array<ModelTypes["Comment_Image_where_or"] | undefined> | undefined
};
	["Comment_Image_alt_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Comment_Image_updatedAt_operator"]: {
	equals?: ModelTypes["DateTime"] | undefined,
	not_equals?: ModelTypes["DateTime"] | undefined,
	greater_than_equal?: ModelTypes["DateTime"] | undefined,
	greater_than?: ModelTypes["DateTime"] | undefined,
	less_than_equal?: ModelTypes["DateTime"] | undefined,
	less_than?: ModelTypes["DateTime"] | undefined,
	like?: ModelTypes["DateTime"] | undefined,
	exists?: boolean | undefined
};
	["Comment_Image_createdAt_operator"]: {
	equals?: ModelTypes["DateTime"] | undefined,
	not_equals?: ModelTypes["DateTime"] | undefined,
	greater_than_equal?: ModelTypes["DateTime"] | undefined,
	greater_than?: ModelTypes["DateTime"] | undefined,
	less_than_equal?: ModelTypes["DateTime"] | undefined,
	less_than?: ModelTypes["DateTime"] | undefined,
	like?: ModelTypes["DateTime"] | undefined,
	exists?: boolean | undefined
};
	["Comment_Image_url_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Comment_Image_filename_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Comment_Image_mimeType_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Comment_Image_filesize_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Comment_Image_width_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Comment_Image_height_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Comment_Image_focalX_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Comment_Image_focalY_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Comment_Image_sizes__small__url_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Comment_Image_sizes__small__width_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Comment_Image_sizes__small__height_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Comment_Image_sizes__small__mimeType_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Comment_Image_sizes__small__filesize_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Comment_Image_sizes__small__filename_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Comment_Image_sizes__medium__url_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Comment_Image_sizes__medium__width_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Comment_Image_sizes__medium__height_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Comment_Image_sizes__medium__mimeType_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Comment_Image_sizes__medium__filesize_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Comment_Image_sizes__medium__filename_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Comment_Image_sizes__large__url_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Comment_Image_sizes__large__width_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Comment_Image_sizes__large__height_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Comment_Image_sizes__large__mimeType_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Comment_Image_sizes__large__filesize_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Comment_Image_sizes__large__filename_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Comment_Image_sizes__small_jpeg__url_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Comment_Image_sizes__small_jpeg__width_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Comment_Image_sizes__small_jpeg__height_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Comment_Image_sizes__small_jpeg__mimeType_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Comment_Image_sizes__small_jpeg__filesize_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Comment_Image_sizes__small_jpeg__filename_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Comment_Image_sizes__medium_jpeg__url_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Comment_Image_sizes__medium_jpeg__width_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Comment_Image_sizes__medium_jpeg__height_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Comment_Image_sizes__medium_jpeg__mimeType_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Comment_Image_sizes__medium_jpeg__filesize_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Comment_Image_sizes__medium_jpeg__filename_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Comment_Image_sizes__large_jpeg__url_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Comment_Image_sizes__large_jpeg__width_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Comment_Image_sizes__large_jpeg__height_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Comment_Image_sizes__large_jpeg__mimeType_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Comment_Image_sizes__large_jpeg__filesize_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Comment_Image_sizes__large_jpeg__filename_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Comment_Image_id_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Comment_Image_where_and"]: {
	alt?: ModelTypes["Comment_Image_alt_operator"] | undefined,
	updatedAt?: ModelTypes["Comment_Image_updatedAt_operator"] | undefined,
	createdAt?: ModelTypes["Comment_Image_createdAt_operator"] | undefined,
	url?: ModelTypes["Comment_Image_url_operator"] | undefined,
	filename?: ModelTypes["Comment_Image_filename_operator"] | undefined,
	mimeType?: ModelTypes["Comment_Image_mimeType_operator"] | undefined,
	filesize?: ModelTypes["Comment_Image_filesize_operator"] | undefined,
	width?: ModelTypes["Comment_Image_width_operator"] | undefined,
	height?: ModelTypes["Comment_Image_height_operator"] | undefined,
	focalX?: ModelTypes["Comment_Image_focalX_operator"] | undefined,
	focalY?: ModelTypes["Comment_Image_focalY_operator"] | undefined,
	sizes__small__url?: ModelTypes["Comment_Image_sizes__small__url_operator"] | undefined,
	sizes__small__width?: ModelTypes["Comment_Image_sizes__small__width_operator"] | undefined,
	sizes__small__height?: ModelTypes["Comment_Image_sizes__small__height_operator"] | undefined,
	sizes__small__mimeType?: ModelTypes["Comment_Image_sizes__small__mimeType_operator"] | undefined,
	sizes__small__filesize?: ModelTypes["Comment_Image_sizes__small__filesize_operator"] | undefined,
	sizes__small__filename?: ModelTypes["Comment_Image_sizes__small__filename_operator"] | undefined,
	sizes__medium__url?: ModelTypes["Comment_Image_sizes__medium__url_operator"] | undefined,
	sizes__medium__width?: ModelTypes["Comment_Image_sizes__medium__width_operator"] | undefined,
	sizes__medium__height?: ModelTypes["Comment_Image_sizes__medium__height_operator"] | undefined,
	sizes__medium__mimeType?: ModelTypes["Comment_Image_sizes__medium__mimeType_operator"] | undefined,
	sizes__medium__filesize?: ModelTypes["Comment_Image_sizes__medium__filesize_operator"] | undefined,
	sizes__medium__filename?: ModelTypes["Comment_Image_sizes__medium__filename_operator"] | undefined,
	sizes__large__url?: ModelTypes["Comment_Image_sizes__large__url_operator"] | undefined,
	sizes__large__width?: ModelTypes["Comment_Image_sizes__large__width_operator"] | undefined,
	sizes__large__height?: ModelTypes["Comment_Image_sizes__large__height_operator"] | undefined,
	sizes__large__mimeType?: ModelTypes["Comment_Image_sizes__large__mimeType_operator"] | undefined,
	sizes__large__filesize?: ModelTypes["Comment_Image_sizes__large__filesize_operator"] | undefined,
	sizes__large__filename?: ModelTypes["Comment_Image_sizes__large__filename_operator"] | undefined,
	sizes__small_jpeg__url?: ModelTypes["Comment_Image_sizes__small_jpeg__url_operator"] | undefined,
	sizes__small_jpeg__width?: ModelTypes["Comment_Image_sizes__small_jpeg__width_operator"] | undefined,
	sizes__small_jpeg__height?: ModelTypes["Comment_Image_sizes__small_jpeg__height_operator"] | undefined,
	sizes__small_jpeg__mimeType?: ModelTypes["Comment_Image_sizes__small_jpeg__mimeType_operator"] | undefined,
	sizes__small_jpeg__filesize?: ModelTypes["Comment_Image_sizes__small_jpeg__filesize_operator"] | undefined,
	sizes__small_jpeg__filename?: ModelTypes["Comment_Image_sizes__small_jpeg__filename_operator"] | undefined,
	sizes__medium_jpeg__url?: ModelTypes["Comment_Image_sizes__medium_jpeg__url_operator"] | undefined,
	sizes__medium_jpeg__width?: ModelTypes["Comment_Image_sizes__medium_jpeg__width_operator"] | undefined,
	sizes__medium_jpeg__height?: ModelTypes["Comment_Image_sizes__medium_jpeg__height_operator"] | undefined,
	sizes__medium_jpeg__mimeType?: ModelTypes["Comment_Image_sizes__medium_jpeg__mimeType_operator"] | undefined,
	sizes__medium_jpeg__filesize?: ModelTypes["Comment_Image_sizes__medium_jpeg__filesize_operator"] | undefined,
	sizes__medium_jpeg__filename?: ModelTypes["Comment_Image_sizes__medium_jpeg__filename_operator"] | undefined,
	sizes__large_jpeg__url?: ModelTypes["Comment_Image_sizes__large_jpeg__url_operator"] | undefined,
	sizes__large_jpeg__width?: ModelTypes["Comment_Image_sizes__large_jpeg__width_operator"] | undefined,
	sizes__large_jpeg__height?: ModelTypes["Comment_Image_sizes__large_jpeg__height_operator"] | undefined,
	sizes__large_jpeg__mimeType?: ModelTypes["Comment_Image_sizes__large_jpeg__mimeType_operator"] | undefined,
	sizes__large_jpeg__filesize?: ModelTypes["Comment_Image_sizes__large_jpeg__filesize_operator"] | undefined,
	sizes__large_jpeg__filename?: ModelTypes["Comment_Image_sizes__large_jpeg__filename_operator"] | undefined,
	id?: ModelTypes["Comment_Image_id_operator"] | undefined,
	AND?: Array<ModelTypes["Comment_Image_where_and"] | undefined> | undefined,
	OR?: Array<ModelTypes["Comment_Image_where_or"] | undefined> | undefined
};
	["Comment_Image_where_or"]: {
	alt?: ModelTypes["Comment_Image_alt_operator"] | undefined,
	updatedAt?: ModelTypes["Comment_Image_updatedAt_operator"] | undefined,
	createdAt?: ModelTypes["Comment_Image_createdAt_operator"] | undefined,
	url?: ModelTypes["Comment_Image_url_operator"] | undefined,
	filename?: ModelTypes["Comment_Image_filename_operator"] | undefined,
	mimeType?: ModelTypes["Comment_Image_mimeType_operator"] | undefined,
	filesize?: ModelTypes["Comment_Image_filesize_operator"] | undefined,
	width?: ModelTypes["Comment_Image_width_operator"] | undefined,
	height?: ModelTypes["Comment_Image_height_operator"] | undefined,
	focalX?: ModelTypes["Comment_Image_focalX_operator"] | undefined,
	focalY?: ModelTypes["Comment_Image_focalY_operator"] | undefined,
	sizes__small__url?: ModelTypes["Comment_Image_sizes__small__url_operator"] | undefined,
	sizes__small__width?: ModelTypes["Comment_Image_sizes__small__width_operator"] | undefined,
	sizes__small__height?: ModelTypes["Comment_Image_sizes__small__height_operator"] | undefined,
	sizes__small__mimeType?: ModelTypes["Comment_Image_sizes__small__mimeType_operator"] | undefined,
	sizes__small__filesize?: ModelTypes["Comment_Image_sizes__small__filesize_operator"] | undefined,
	sizes__small__filename?: ModelTypes["Comment_Image_sizes__small__filename_operator"] | undefined,
	sizes__medium__url?: ModelTypes["Comment_Image_sizes__medium__url_operator"] | undefined,
	sizes__medium__width?: ModelTypes["Comment_Image_sizes__medium__width_operator"] | undefined,
	sizes__medium__height?: ModelTypes["Comment_Image_sizes__medium__height_operator"] | undefined,
	sizes__medium__mimeType?: ModelTypes["Comment_Image_sizes__medium__mimeType_operator"] | undefined,
	sizes__medium__filesize?: ModelTypes["Comment_Image_sizes__medium__filesize_operator"] | undefined,
	sizes__medium__filename?: ModelTypes["Comment_Image_sizes__medium__filename_operator"] | undefined,
	sizes__large__url?: ModelTypes["Comment_Image_sizes__large__url_operator"] | undefined,
	sizes__large__width?: ModelTypes["Comment_Image_sizes__large__width_operator"] | undefined,
	sizes__large__height?: ModelTypes["Comment_Image_sizes__large__height_operator"] | undefined,
	sizes__large__mimeType?: ModelTypes["Comment_Image_sizes__large__mimeType_operator"] | undefined,
	sizes__large__filesize?: ModelTypes["Comment_Image_sizes__large__filesize_operator"] | undefined,
	sizes__large__filename?: ModelTypes["Comment_Image_sizes__large__filename_operator"] | undefined,
	sizes__small_jpeg__url?: ModelTypes["Comment_Image_sizes__small_jpeg__url_operator"] | undefined,
	sizes__small_jpeg__width?: ModelTypes["Comment_Image_sizes__small_jpeg__width_operator"] | undefined,
	sizes__small_jpeg__height?: ModelTypes["Comment_Image_sizes__small_jpeg__height_operator"] | undefined,
	sizes__small_jpeg__mimeType?: ModelTypes["Comment_Image_sizes__small_jpeg__mimeType_operator"] | undefined,
	sizes__small_jpeg__filesize?: ModelTypes["Comment_Image_sizes__small_jpeg__filesize_operator"] | undefined,
	sizes__small_jpeg__filename?: ModelTypes["Comment_Image_sizes__small_jpeg__filename_operator"] | undefined,
	sizes__medium_jpeg__url?: ModelTypes["Comment_Image_sizes__medium_jpeg__url_operator"] | undefined,
	sizes__medium_jpeg__width?: ModelTypes["Comment_Image_sizes__medium_jpeg__width_operator"] | undefined,
	sizes__medium_jpeg__height?: ModelTypes["Comment_Image_sizes__medium_jpeg__height_operator"] | undefined,
	sizes__medium_jpeg__mimeType?: ModelTypes["Comment_Image_sizes__medium_jpeg__mimeType_operator"] | undefined,
	sizes__medium_jpeg__filesize?: ModelTypes["Comment_Image_sizes__medium_jpeg__filesize_operator"] | undefined,
	sizes__medium_jpeg__filename?: ModelTypes["Comment_Image_sizes__medium_jpeg__filename_operator"] | undefined,
	sizes__large_jpeg__url?: ModelTypes["Comment_Image_sizes__large_jpeg__url_operator"] | undefined,
	sizes__large_jpeg__width?: ModelTypes["Comment_Image_sizes__large_jpeg__width_operator"] | undefined,
	sizes__large_jpeg__height?: ModelTypes["Comment_Image_sizes__large_jpeg__height_operator"] | undefined,
	sizes__large_jpeg__mimeType?: ModelTypes["Comment_Image_sizes__large_jpeg__mimeType_operator"] | undefined,
	sizes__large_jpeg__filesize?: ModelTypes["Comment_Image_sizes__large_jpeg__filesize_operator"] | undefined,
	sizes__large_jpeg__filename?: ModelTypes["Comment_Image_sizes__large_jpeg__filename_operator"] | undefined,
	id?: ModelTypes["Comment_Image_id_operator"] | undefined,
	AND?: Array<ModelTypes["Comment_Image_where_and"] | undefined> | undefined,
	OR?: Array<ModelTypes["Comment_Image_where_or"] | undefined> | undefined
};
	["Comment_Target_Relationship"]: {
		relationTo?: ModelTypes["Comment_Target_RelationTo"] | undefined,
	value?: ModelTypes["Comment_Target"] | undefined
};
	["Comment_Target_RelationTo"]:Comment_Target_RelationTo;
	["Comment_Target"]:ModelTypes["Event"] | ModelTypes["Post"] | ModelTypes["Location"];
	["Comment_Parent_Relationship"]: {
		relationTo?: ModelTypes["Comment_Parent_RelationTo"] | undefined,
	value?: ModelTypes["Comment_Parent"] | undefined
};
	["Comment_Parent_RelationTo"]:Comment_Parent_RelationTo;
	["Comment_Parent"]:ModelTypes["Comment"];
	["Comments"]: {
		docs?: Array<ModelTypes["Comment"] | undefined> | undefined,
	hasNextPage?: boolean | undefined,
	hasPrevPage?: boolean | undefined,
	limit?: number | undefined,
	nextPage?: number | undefined,
	offset?: number | undefined,
	page?: number | undefined,
	pagingCounter?: number | undefined,
	prevPage?: number | undefined,
	totalDocs?: number | undefined,
	totalPages?: number | undefined
};
	["Comment_where"]: {
	commenter?: ModelTypes["Comment_commenter_operator"] | undefined,
	content?: ModelTypes["Comment_content_operator"] | undefined,
	image?: ModelTypes["Comment_image_operator"] | undefined,
	target?: ModelTypes["Comment_target_Relation"] | undefined,
	parent?: ModelTypes["Comment_parent_Relation"] | undefined,
	isReviewed?: ModelTypes["Comment_isReviewed_operator"] | undefined,
	reviewedBy?: ModelTypes["Comment_reviewedBy_operator"] | undefined,
	banned?: ModelTypes["Comment_banned_operator"] | undefined,
	type?: ModelTypes["Comment_type_operator"] | undefined,
	updatedAt?: ModelTypes["Comment_updatedAt_operator"] | undefined,
	createdAt?: ModelTypes["Comment_createdAt_operator"] | undefined,
	id?: ModelTypes["Comment_id_operator"] | undefined,
	AND?: Array<ModelTypes["Comment_where_and"] | undefined> | undefined,
	OR?: Array<ModelTypes["Comment_where_or"] | undefined> | undefined
};
	["Comment_commenter_operator"]: {
	equals?: ModelTypes["JSON"] | undefined,
	not_equals?: ModelTypes["JSON"] | undefined,
	in?: Array<ModelTypes["JSON"] | undefined> | undefined,
	not_in?: Array<ModelTypes["JSON"] | undefined> | undefined,
	all?: Array<ModelTypes["JSON"] | undefined> | undefined,
	exists?: boolean | undefined
};
	["Comment_content_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Comment_image_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	exists?: boolean | undefined
};
	["Comment_target_Relation"]: {
	relationTo?: ModelTypes["Comment_target_Relation_RelationTo"] | undefined,
	value?: ModelTypes["JSON"] | undefined
};
	["Comment_target_Relation_RelationTo"]:Comment_target_Relation_RelationTo;
	["Comment_parent_Relation"]: {
	relationTo?: ModelTypes["Comment_parent_Relation_RelationTo"] | undefined,
	value?: ModelTypes["JSON"] | undefined
};
	["Comment_parent_Relation_RelationTo"]:Comment_parent_Relation_RelationTo;
	["Comment_isReviewed_operator"]: {
	equals?: boolean | undefined,
	not_equals?: boolean | undefined,
	exists?: boolean | undefined
};
	["Comment_reviewedBy_operator"]: {
	equals?: ModelTypes["JSON"] | undefined,
	not_equals?: ModelTypes["JSON"] | undefined,
	in?: Array<ModelTypes["JSON"] | undefined> | undefined,
	not_in?: Array<ModelTypes["JSON"] | undefined> | undefined,
	all?: Array<ModelTypes["JSON"] | undefined> | undefined,
	exists?: boolean | undefined
};
	["Comment_banned_operator"]: {
	equals?: boolean | undefined,
	not_equals?: boolean | undefined,
	exists?: boolean | undefined
};
	["Comment_type_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Comment_updatedAt_operator"]: {
	equals?: ModelTypes["DateTime"] | undefined,
	not_equals?: ModelTypes["DateTime"] | undefined,
	greater_than_equal?: ModelTypes["DateTime"] | undefined,
	greater_than?: ModelTypes["DateTime"] | undefined,
	less_than_equal?: ModelTypes["DateTime"] | undefined,
	less_than?: ModelTypes["DateTime"] | undefined,
	like?: ModelTypes["DateTime"] | undefined,
	exists?: boolean | undefined
};
	["Comment_createdAt_operator"]: {
	equals?: ModelTypes["DateTime"] | undefined,
	not_equals?: ModelTypes["DateTime"] | undefined,
	greater_than_equal?: ModelTypes["DateTime"] | undefined,
	greater_than?: ModelTypes["DateTime"] | undefined,
	less_than_equal?: ModelTypes["DateTime"] | undefined,
	less_than?: ModelTypes["DateTime"] | undefined,
	like?: ModelTypes["DateTime"] | undefined,
	exists?: boolean | undefined
};
	["Comment_id_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Comment_where_and"]: {
	commenter?: ModelTypes["Comment_commenter_operator"] | undefined,
	content?: ModelTypes["Comment_content_operator"] | undefined,
	image?: ModelTypes["Comment_image_operator"] | undefined,
	target?: ModelTypes["Comment_target_Relation"] | undefined,
	parent?: ModelTypes["Comment_parent_Relation"] | undefined,
	isReviewed?: ModelTypes["Comment_isReviewed_operator"] | undefined,
	reviewedBy?: ModelTypes["Comment_reviewedBy_operator"] | undefined,
	banned?: ModelTypes["Comment_banned_operator"] | undefined,
	type?: ModelTypes["Comment_type_operator"] | undefined,
	updatedAt?: ModelTypes["Comment_updatedAt_operator"] | undefined,
	createdAt?: ModelTypes["Comment_createdAt_operator"] | undefined,
	id?: ModelTypes["Comment_id_operator"] | undefined,
	AND?: Array<ModelTypes["Comment_where_and"] | undefined> | undefined,
	OR?: Array<ModelTypes["Comment_where_or"] | undefined> | undefined
};
	["Comment_where_or"]: {
	commenter?: ModelTypes["Comment_commenter_operator"] | undefined,
	content?: ModelTypes["Comment_content_operator"] | undefined,
	image?: ModelTypes["Comment_image_operator"] | undefined,
	target?: ModelTypes["Comment_target_Relation"] | undefined,
	parent?: ModelTypes["Comment_parent_Relation"] | undefined,
	isReviewed?: ModelTypes["Comment_isReviewed_operator"] | undefined,
	reviewedBy?: ModelTypes["Comment_reviewedBy_operator"] | undefined,
	banned?: ModelTypes["Comment_banned_operator"] | undefined,
	type?: ModelTypes["Comment_type_operator"] | undefined,
	updatedAt?: ModelTypes["Comment_updatedAt_operator"] | undefined,
	createdAt?: ModelTypes["Comment_createdAt_operator"] | undefined,
	id?: ModelTypes["Comment_id_operator"] | undefined,
	AND?: Array<ModelTypes["Comment_where_and"] | undefined> | undefined,
	OR?: Array<ModelTypes["Comment_where_or"] | undefined> | undefined
};
	["countComments"]: {
		totalDocs?: number | undefined
};
	["commentsDocAccess"]: {
		fields?: ModelTypes["CommentsDocAccessFields"] | undefined,
	create?: ModelTypes["CommentsCreateDocAccess"] | undefined,
	read?: ModelTypes["CommentsReadDocAccess"] | undefined,
	update?: ModelTypes["CommentsUpdateDocAccess"] | undefined,
	delete?: ModelTypes["CommentsDeleteDocAccess"] | undefined
};
	["CommentsDocAccessFields"]: {
		commenter?: ModelTypes["CommentsDocAccessFields_commenter"] | undefined,
	content?: ModelTypes["CommentsDocAccessFields_content"] | undefined,
	image?: ModelTypes["CommentsDocAccessFields_image"] | undefined,
	target?: ModelTypes["CommentsDocAccessFields_target"] | undefined,
	parent?: ModelTypes["CommentsDocAccessFields_parent"] | undefined,
	isReviewed?: ModelTypes["CommentsDocAccessFields_isReviewed"] | undefined,
	reviewedBy?: ModelTypes["CommentsDocAccessFields_reviewedBy"] | undefined,
	banned?: ModelTypes["CommentsDocAccessFields_banned"] | undefined,
	type?: ModelTypes["CommentsDocAccessFields_type"] | undefined,
	updatedAt?: ModelTypes["CommentsDocAccessFields_updatedAt"] | undefined,
	createdAt?: ModelTypes["CommentsDocAccessFields_createdAt"] | undefined
};
	["CommentsDocAccessFields_commenter"]: {
		create?: ModelTypes["CommentsDocAccessFields_commenter_Create"] | undefined,
	read?: ModelTypes["CommentsDocAccessFields_commenter_Read"] | undefined,
	update?: ModelTypes["CommentsDocAccessFields_commenter_Update"] | undefined,
	delete?: ModelTypes["CommentsDocAccessFields_commenter_Delete"] | undefined
};
	["CommentsDocAccessFields_commenter_Create"]: {
		permission: boolean
};
	["CommentsDocAccessFields_commenter_Read"]: {
		permission: boolean
};
	["CommentsDocAccessFields_commenter_Update"]: {
		permission: boolean
};
	["CommentsDocAccessFields_commenter_Delete"]: {
		permission: boolean
};
	["CommentsDocAccessFields_content"]: {
		create?: ModelTypes["CommentsDocAccessFields_content_Create"] | undefined,
	read?: ModelTypes["CommentsDocAccessFields_content_Read"] | undefined,
	update?: ModelTypes["CommentsDocAccessFields_content_Update"] | undefined,
	delete?: ModelTypes["CommentsDocAccessFields_content_Delete"] | undefined
};
	["CommentsDocAccessFields_content_Create"]: {
		permission: boolean
};
	["CommentsDocAccessFields_content_Read"]: {
		permission: boolean
};
	["CommentsDocAccessFields_content_Update"]: {
		permission: boolean
};
	["CommentsDocAccessFields_content_Delete"]: {
		permission: boolean
};
	["CommentsDocAccessFields_image"]: {
		create?: ModelTypes["CommentsDocAccessFields_image_Create"] | undefined,
	read?: ModelTypes["CommentsDocAccessFields_image_Read"] | undefined,
	update?: ModelTypes["CommentsDocAccessFields_image_Update"] | undefined,
	delete?: ModelTypes["CommentsDocAccessFields_image_Delete"] | undefined
};
	["CommentsDocAccessFields_image_Create"]: {
		permission: boolean
};
	["CommentsDocAccessFields_image_Read"]: {
		permission: boolean
};
	["CommentsDocAccessFields_image_Update"]: {
		permission: boolean
};
	["CommentsDocAccessFields_image_Delete"]: {
		permission: boolean
};
	["CommentsDocAccessFields_target"]: {
		create?: ModelTypes["CommentsDocAccessFields_target_Create"] | undefined,
	read?: ModelTypes["CommentsDocAccessFields_target_Read"] | undefined,
	update?: ModelTypes["CommentsDocAccessFields_target_Update"] | undefined,
	delete?: ModelTypes["CommentsDocAccessFields_target_Delete"] | undefined
};
	["CommentsDocAccessFields_target_Create"]: {
		permission: boolean
};
	["CommentsDocAccessFields_target_Read"]: {
		permission: boolean
};
	["CommentsDocAccessFields_target_Update"]: {
		permission: boolean
};
	["CommentsDocAccessFields_target_Delete"]: {
		permission: boolean
};
	["CommentsDocAccessFields_parent"]: {
		create?: ModelTypes["CommentsDocAccessFields_parent_Create"] | undefined,
	read?: ModelTypes["CommentsDocAccessFields_parent_Read"] | undefined,
	update?: ModelTypes["CommentsDocAccessFields_parent_Update"] | undefined,
	delete?: ModelTypes["CommentsDocAccessFields_parent_Delete"] | undefined
};
	["CommentsDocAccessFields_parent_Create"]: {
		permission: boolean
};
	["CommentsDocAccessFields_parent_Read"]: {
		permission: boolean
};
	["CommentsDocAccessFields_parent_Update"]: {
		permission: boolean
};
	["CommentsDocAccessFields_parent_Delete"]: {
		permission: boolean
};
	["CommentsDocAccessFields_isReviewed"]: {
		create?: ModelTypes["CommentsDocAccessFields_isReviewed_Create"] | undefined,
	read?: ModelTypes["CommentsDocAccessFields_isReviewed_Read"] | undefined,
	update?: ModelTypes["CommentsDocAccessFields_isReviewed_Update"] | undefined,
	delete?: ModelTypes["CommentsDocAccessFields_isReviewed_Delete"] | undefined
};
	["CommentsDocAccessFields_isReviewed_Create"]: {
		permission: boolean
};
	["CommentsDocAccessFields_isReviewed_Read"]: {
		permission: boolean
};
	["CommentsDocAccessFields_isReviewed_Update"]: {
		permission: boolean
};
	["CommentsDocAccessFields_isReviewed_Delete"]: {
		permission: boolean
};
	["CommentsDocAccessFields_reviewedBy"]: {
		create?: ModelTypes["CommentsDocAccessFields_reviewedBy_Create"] | undefined,
	read?: ModelTypes["CommentsDocAccessFields_reviewedBy_Read"] | undefined,
	update?: ModelTypes["CommentsDocAccessFields_reviewedBy_Update"] | undefined,
	delete?: ModelTypes["CommentsDocAccessFields_reviewedBy_Delete"] | undefined
};
	["CommentsDocAccessFields_reviewedBy_Create"]: {
		permission: boolean
};
	["CommentsDocAccessFields_reviewedBy_Read"]: {
		permission: boolean
};
	["CommentsDocAccessFields_reviewedBy_Update"]: {
		permission: boolean
};
	["CommentsDocAccessFields_reviewedBy_Delete"]: {
		permission: boolean
};
	["CommentsDocAccessFields_banned"]: {
		create?: ModelTypes["CommentsDocAccessFields_banned_Create"] | undefined,
	read?: ModelTypes["CommentsDocAccessFields_banned_Read"] | undefined,
	update?: ModelTypes["CommentsDocAccessFields_banned_Update"] | undefined,
	delete?: ModelTypes["CommentsDocAccessFields_banned_Delete"] | undefined
};
	["CommentsDocAccessFields_banned_Create"]: {
		permission: boolean
};
	["CommentsDocAccessFields_banned_Read"]: {
		permission: boolean
};
	["CommentsDocAccessFields_banned_Update"]: {
		permission: boolean
};
	["CommentsDocAccessFields_banned_Delete"]: {
		permission: boolean
};
	["CommentsDocAccessFields_type"]: {
		create?: ModelTypes["CommentsDocAccessFields_type_Create"] | undefined,
	read?: ModelTypes["CommentsDocAccessFields_type_Read"] | undefined,
	update?: ModelTypes["CommentsDocAccessFields_type_Update"] | undefined,
	delete?: ModelTypes["CommentsDocAccessFields_type_Delete"] | undefined
};
	["CommentsDocAccessFields_type_Create"]: {
		permission: boolean
};
	["CommentsDocAccessFields_type_Read"]: {
		permission: boolean
};
	["CommentsDocAccessFields_type_Update"]: {
		permission: boolean
};
	["CommentsDocAccessFields_type_Delete"]: {
		permission: boolean
};
	["CommentsDocAccessFields_updatedAt"]: {
		create?: ModelTypes["CommentsDocAccessFields_updatedAt_Create"] | undefined,
	read?: ModelTypes["CommentsDocAccessFields_updatedAt_Read"] | undefined,
	update?: ModelTypes["CommentsDocAccessFields_updatedAt_Update"] | undefined,
	delete?: ModelTypes["CommentsDocAccessFields_updatedAt_Delete"] | undefined
};
	["CommentsDocAccessFields_updatedAt_Create"]: {
		permission: boolean
};
	["CommentsDocAccessFields_updatedAt_Read"]: {
		permission: boolean
};
	["CommentsDocAccessFields_updatedAt_Update"]: {
		permission: boolean
};
	["CommentsDocAccessFields_updatedAt_Delete"]: {
		permission: boolean
};
	["CommentsDocAccessFields_createdAt"]: {
		create?: ModelTypes["CommentsDocAccessFields_createdAt_Create"] | undefined,
	read?: ModelTypes["CommentsDocAccessFields_createdAt_Read"] | undefined,
	update?: ModelTypes["CommentsDocAccessFields_createdAt_Update"] | undefined,
	delete?: ModelTypes["CommentsDocAccessFields_createdAt_Delete"] | undefined
};
	["CommentsDocAccessFields_createdAt_Create"]: {
		permission: boolean
};
	["CommentsDocAccessFields_createdAt_Read"]: {
		permission: boolean
};
	["CommentsDocAccessFields_createdAt_Update"]: {
		permission: boolean
};
	["CommentsDocAccessFields_createdAt_Delete"]: {
		permission: boolean
};
	["CommentsCreateDocAccess"]: {
		permission: boolean,
	where?: ModelTypes["JSONObject"] | undefined
};
	["CommentsReadDocAccess"]: {
		permission: boolean,
	where?: ModelTypes["JSONObject"] | undefined
};
	["CommentsUpdateDocAccess"]: {
		permission: boolean,
	where?: ModelTypes["JSONObject"] | undefined
};
	["CommentsDeleteDocAccess"]: {
		permission: boolean,
	where?: ModelTypes["JSONObject"] | undefined
};
	["Subscription"]: {
		id?: string | undefined,
	user?: ModelTypes["Subscription_User_Relationship"] | undefined,
	organiser?: ModelTypes["Subscription_Organiser_Relationship"] | undefined,
	updatedAt?: ModelTypes["DateTime"] | undefined,
	createdAt?: ModelTypes["DateTime"] | undefined
};
	["Subscription_User_Relationship"]: {
		relationTo?: ModelTypes["Subscription_User_RelationTo"] | undefined,
	value?: ModelTypes["Subscription_User"] | undefined
};
	["Subscription_User_RelationTo"]:Subscription_User_RelationTo;
	["Subscription_User"]:ModelTypes["User"];
	["Subscription_Organiser_Relationship"]: {
		relationTo?: ModelTypes["Subscription_Organiser_RelationTo"] | undefined,
	value?: ModelTypes["Subscription_Organiser"] | undefined
};
	["Subscription_Organiser_RelationTo"]:Subscription_Organiser_RelationTo;
	["Subscription_Organiser"]:ModelTypes["User"];
	["Subscriptions"]: {
		docs?: Array<ModelTypes["Subscription"] | undefined> | undefined,
	hasNextPage?: boolean | undefined,
	hasPrevPage?: boolean | undefined,
	limit?: number | undefined,
	nextPage?: number | undefined,
	offset?: number | undefined,
	page?: number | undefined,
	pagingCounter?: number | undefined,
	prevPage?: number | undefined,
	totalDocs?: number | undefined,
	totalPages?: number | undefined
};
	["Subscription_where"]: {
	user?: ModelTypes["Subscription_user_Relation"] | undefined,
	organiser?: ModelTypes["Subscription_organiser_Relation"] | undefined,
	updatedAt?: ModelTypes["Subscription_updatedAt_operator"] | undefined,
	createdAt?: ModelTypes["Subscription_createdAt_operator"] | undefined,
	id?: ModelTypes["Subscription_id_operator"] | undefined,
	AND?: Array<ModelTypes["Subscription_where_and"] | undefined> | undefined,
	OR?: Array<ModelTypes["Subscription_where_or"] | undefined> | undefined
};
	["Subscription_user_Relation"]: {
	relationTo?: ModelTypes["Subscription_user_Relation_RelationTo"] | undefined,
	value?: ModelTypes["JSON"] | undefined
};
	["Subscription_user_Relation_RelationTo"]:Subscription_user_Relation_RelationTo;
	["Subscription_organiser_Relation"]: {
	relationTo?: ModelTypes["Subscription_organiser_Relation_RelationTo"] | undefined,
	value?: ModelTypes["JSON"] | undefined
};
	["Subscription_organiser_Relation_RelationTo"]:Subscription_organiser_Relation_RelationTo;
	["Subscription_updatedAt_operator"]: {
	equals?: ModelTypes["DateTime"] | undefined,
	not_equals?: ModelTypes["DateTime"] | undefined,
	greater_than_equal?: ModelTypes["DateTime"] | undefined,
	greater_than?: ModelTypes["DateTime"] | undefined,
	less_than_equal?: ModelTypes["DateTime"] | undefined,
	less_than?: ModelTypes["DateTime"] | undefined,
	like?: ModelTypes["DateTime"] | undefined,
	exists?: boolean | undefined
};
	["Subscription_createdAt_operator"]: {
	equals?: ModelTypes["DateTime"] | undefined,
	not_equals?: ModelTypes["DateTime"] | undefined,
	greater_than_equal?: ModelTypes["DateTime"] | undefined,
	greater_than?: ModelTypes["DateTime"] | undefined,
	less_than_equal?: ModelTypes["DateTime"] | undefined,
	less_than?: ModelTypes["DateTime"] | undefined,
	like?: ModelTypes["DateTime"] | undefined,
	exists?: boolean | undefined
};
	["Subscription_id_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Subscription_where_and"]: {
	user?: ModelTypes["Subscription_user_Relation"] | undefined,
	organiser?: ModelTypes["Subscription_organiser_Relation"] | undefined,
	updatedAt?: ModelTypes["Subscription_updatedAt_operator"] | undefined,
	createdAt?: ModelTypes["Subscription_createdAt_operator"] | undefined,
	id?: ModelTypes["Subscription_id_operator"] | undefined,
	AND?: Array<ModelTypes["Subscription_where_and"] | undefined> | undefined,
	OR?: Array<ModelTypes["Subscription_where_or"] | undefined> | undefined
};
	["Subscription_where_or"]: {
	user?: ModelTypes["Subscription_user_Relation"] | undefined,
	organiser?: ModelTypes["Subscription_organiser_Relation"] | undefined,
	updatedAt?: ModelTypes["Subscription_updatedAt_operator"] | undefined,
	createdAt?: ModelTypes["Subscription_createdAt_operator"] | undefined,
	id?: ModelTypes["Subscription_id_operator"] | undefined,
	AND?: Array<ModelTypes["Subscription_where_and"] | undefined> | undefined,
	OR?: Array<ModelTypes["Subscription_where_or"] | undefined> | undefined
};
	["countSubscriptions"]: {
		totalDocs?: number | undefined
};
	["subscriptionsDocAccess"]: {
		fields?: ModelTypes["SubscriptionsDocAccessFields"] | undefined,
	create?: ModelTypes["SubscriptionsCreateDocAccess"] | undefined,
	read?: ModelTypes["SubscriptionsReadDocAccess"] | undefined,
	update?: ModelTypes["SubscriptionsUpdateDocAccess"] | undefined,
	delete?: ModelTypes["SubscriptionsDeleteDocAccess"] | undefined
};
	["SubscriptionsDocAccessFields"]: {
		user?: ModelTypes["SubscriptionsDocAccessFields_user"] | undefined,
	organiser?: ModelTypes["SubscriptionsDocAccessFields_organiser"] | undefined,
	updatedAt?: ModelTypes["SubscriptionsDocAccessFields_updatedAt"] | undefined,
	createdAt?: ModelTypes["SubscriptionsDocAccessFields_createdAt"] | undefined
};
	["SubscriptionsDocAccessFields_user"]: {
		create?: ModelTypes["SubscriptionsDocAccessFields_user_Create"] | undefined,
	read?: ModelTypes["SubscriptionsDocAccessFields_user_Read"] | undefined,
	update?: ModelTypes["SubscriptionsDocAccessFields_user_Update"] | undefined,
	delete?: ModelTypes["SubscriptionsDocAccessFields_user_Delete"] | undefined
};
	["SubscriptionsDocAccessFields_user_Create"]: {
		permission: boolean
};
	["SubscriptionsDocAccessFields_user_Read"]: {
		permission: boolean
};
	["SubscriptionsDocAccessFields_user_Update"]: {
		permission: boolean
};
	["SubscriptionsDocAccessFields_user_Delete"]: {
		permission: boolean
};
	["SubscriptionsDocAccessFields_organiser"]: {
		create?: ModelTypes["SubscriptionsDocAccessFields_organiser_Create"] | undefined,
	read?: ModelTypes["SubscriptionsDocAccessFields_organiser_Read"] | undefined,
	update?: ModelTypes["SubscriptionsDocAccessFields_organiser_Update"] | undefined,
	delete?: ModelTypes["SubscriptionsDocAccessFields_organiser_Delete"] | undefined
};
	["SubscriptionsDocAccessFields_organiser_Create"]: {
		permission: boolean
};
	["SubscriptionsDocAccessFields_organiser_Read"]: {
		permission: boolean
};
	["SubscriptionsDocAccessFields_organiser_Update"]: {
		permission: boolean
};
	["SubscriptionsDocAccessFields_organiser_Delete"]: {
		permission: boolean
};
	["SubscriptionsDocAccessFields_updatedAt"]: {
		create?: ModelTypes["SubscriptionsDocAccessFields_updatedAt_Create"] | undefined,
	read?: ModelTypes["SubscriptionsDocAccessFields_updatedAt_Read"] | undefined,
	update?: ModelTypes["SubscriptionsDocAccessFields_updatedAt_Update"] | undefined,
	delete?: ModelTypes["SubscriptionsDocAccessFields_updatedAt_Delete"] | undefined
};
	["SubscriptionsDocAccessFields_updatedAt_Create"]: {
		permission: boolean
};
	["SubscriptionsDocAccessFields_updatedAt_Read"]: {
		permission: boolean
};
	["SubscriptionsDocAccessFields_updatedAt_Update"]: {
		permission: boolean
};
	["SubscriptionsDocAccessFields_updatedAt_Delete"]: {
		permission: boolean
};
	["SubscriptionsDocAccessFields_createdAt"]: {
		create?: ModelTypes["SubscriptionsDocAccessFields_createdAt_Create"] | undefined,
	read?: ModelTypes["SubscriptionsDocAccessFields_createdAt_Read"] | undefined,
	update?: ModelTypes["SubscriptionsDocAccessFields_createdAt_Update"] | undefined,
	delete?: ModelTypes["SubscriptionsDocAccessFields_createdAt_Delete"] | undefined
};
	["SubscriptionsDocAccessFields_createdAt_Create"]: {
		permission: boolean
};
	["SubscriptionsDocAccessFields_createdAt_Read"]: {
		permission: boolean
};
	["SubscriptionsDocAccessFields_createdAt_Update"]: {
		permission: boolean
};
	["SubscriptionsDocAccessFields_createdAt_Delete"]: {
		permission: boolean
};
	["SubscriptionsCreateDocAccess"]: {
		permission: boolean,
	where?: ModelTypes["JSONObject"] | undefined
};
	["SubscriptionsReadDocAccess"]: {
		permission: boolean,
	where?: ModelTypes["JSONObject"] | undefined
};
	["SubscriptionsUpdateDocAccess"]: {
		permission: boolean,
	where?: ModelTypes["JSONObject"] | undefined
};
	["SubscriptionsDeleteDocAccess"]: {
		permission: boolean,
	where?: ModelTypes["JSONObject"] | undefined
};
	["Newsletter"]: {
		id?: string | undefined,
	email: string,
	region?: ModelTypes["Newsletter_region"] | undefined,
	updatedAt?: ModelTypes["DateTime"] | undefined,
	createdAt?: ModelTypes["DateTime"] | undefined
};
	["Newsletter_region"]:Newsletter_region;
	["Newsletters"]: {
		docs?: Array<ModelTypes["Newsletter"] | undefined> | undefined,
	hasNextPage?: boolean | undefined,
	hasPrevPage?: boolean | undefined,
	limit?: number | undefined,
	nextPage?: number | undefined,
	offset?: number | undefined,
	page?: number | undefined,
	pagingCounter?: number | undefined,
	prevPage?: number | undefined,
	totalDocs?: number | undefined,
	totalPages?: number | undefined
};
	["Newsletter_where"]: {
	email?: ModelTypes["Newsletter_email_operator"] | undefined,
	region?: ModelTypes["Newsletter_region_operator"] | undefined,
	updatedAt?: ModelTypes["Newsletter_updatedAt_operator"] | undefined,
	createdAt?: ModelTypes["Newsletter_createdAt_operator"] | undefined,
	id?: ModelTypes["Newsletter_id_operator"] | undefined,
	AND?: Array<ModelTypes["Newsletter_where_and"] | undefined> | undefined,
	OR?: Array<ModelTypes["Newsletter_where_or"] | undefined> | undefined
};
	["Newsletter_email_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined
};
	["Newsletter_region_operator"]: {
	equals?: ModelTypes["Newsletter_region_Input"] | undefined,
	not_equals?: ModelTypes["Newsletter_region_Input"] | undefined,
	in?: Array<ModelTypes["Newsletter_region_Input"] | undefined> | undefined,
	not_in?: Array<ModelTypes["Newsletter_region_Input"] | undefined> | undefined,
	all?: Array<ModelTypes["Newsletter_region_Input"] | undefined> | undefined,
	exists?: boolean | undefined
};
	["Newsletter_region_Input"]:Newsletter_region_Input;
	["Newsletter_updatedAt_operator"]: {
	equals?: ModelTypes["DateTime"] | undefined,
	not_equals?: ModelTypes["DateTime"] | undefined,
	greater_than_equal?: ModelTypes["DateTime"] | undefined,
	greater_than?: ModelTypes["DateTime"] | undefined,
	less_than_equal?: ModelTypes["DateTime"] | undefined,
	less_than?: ModelTypes["DateTime"] | undefined,
	like?: ModelTypes["DateTime"] | undefined,
	exists?: boolean | undefined
};
	["Newsletter_createdAt_operator"]: {
	equals?: ModelTypes["DateTime"] | undefined,
	not_equals?: ModelTypes["DateTime"] | undefined,
	greater_than_equal?: ModelTypes["DateTime"] | undefined,
	greater_than?: ModelTypes["DateTime"] | undefined,
	less_than_equal?: ModelTypes["DateTime"] | undefined,
	less_than?: ModelTypes["DateTime"] | undefined,
	like?: ModelTypes["DateTime"] | undefined,
	exists?: boolean | undefined
};
	["Newsletter_id_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Newsletter_where_and"]: {
	email?: ModelTypes["Newsletter_email_operator"] | undefined,
	region?: ModelTypes["Newsletter_region_operator"] | undefined,
	updatedAt?: ModelTypes["Newsletter_updatedAt_operator"] | undefined,
	createdAt?: ModelTypes["Newsletter_createdAt_operator"] | undefined,
	id?: ModelTypes["Newsletter_id_operator"] | undefined,
	AND?: Array<ModelTypes["Newsletter_where_and"] | undefined> | undefined,
	OR?: Array<ModelTypes["Newsletter_where_or"] | undefined> | undefined
};
	["Newsletter_where_or"]: {
	email?: ModelTypes["Newsletter_email_operator"] | undefined,
	region?: ModelTypes["Newsletter_region_operator"] | undefined,
	updatedAt?: ModelTypes["Newsletter_updatedAt_operator"] | undefined,
	createdAt?: ModelTypes["Newsletter_createdAt_operator"] | undefined,
	id?: ModelTypes["Newsletter_id_operator"] | undefined,
	AND?: Array<ModelTypes["Newsletter_where_and"] | undefined> | undefined,
	OR?: Array<ModelTypes["Newsletter_where_or"] | undefined> | undefined
};
	["countNewsletters"]: {
		totalDocs?: number | undefined
};
	["newsletterDocAccess"]: {
		fields?: ModelTypes["NewsletterDocAccessFields"] | undefined,
	create?: ModelTypes["NewsletterCreateDocAccess"] | undefined,
	read?: ModelTypes["NewsletterReadDocAccess"] | undefined,
	update?: ModelTypes["NewsletterUpdateDocAccess"] | undefined,
	delete?: ModelTypes["NewsletterDeleteDocAccess"] | undefined
};
	["NewsletterDocAccessFields"]: {
		email?: ModelTypes["NewsletterDocAccessFields_email"] | undefined,
	region?: ModelTypes["NewsletterDocAccessFields_region"] | undefined,
	updatedAt?: ModelTypes["NewsletterDocAccessFields_updatedAt"] | undefined,
	createdAt?: ModelTypes["NewsletterDocAccessFields_createdAt"] | undefined
};
	["NewsletterDocAccessFields_email"]: {
		create?: ModelTypes["NewsletterDocAccessFields_email_Create"] | undefined,
	read?: ModelTypes["NewsletterDocAccessFields_email_Read"] | undefined,
	update?: ModelTypes["NewsletterDocAccessFields_email_Update"] | undefined,
	delete?: ModelTypes["NewsletterDocAccessFields_email_Delete"] | undefined
};
	["NewsletterDocAccessFields_email_Create"]: {
		permission: boolean
};
	["NewsletterDocAccessFields_email_Read"]: {
		permission: boolean
};
	["NewsletterDocAccessFields_email_Update"]: {
		permission: boolean
};
	["NewsletterDocAccessFields_email_Delete"]: {
		permission: boolean
};
	["NewsletterDocAccessFields_region"]: {
		create?: ModelTypes["NewsletterDocAccessFields_region_Create"] | undefined,
	read?: ModelTypes["NewsletterDocAccessFields_region_Read"] | undefined,
	update?: ModelTypes["NewsletterDocAccessFields_region_Update"] | undefined,
	delete?: ModelTypes["NewsletterDocAccessFields_region_Delete"] | undefined
};
	["NewsletterDocAccessFields_region_Create"]: {
		permission: boolean
};
	["NewsletterDocAccessFields_region_Read"]: {
		permission: boolean
};
	["NewsletterDocAccessFields_region_Update"]: {
		permission: boolean
};
	["NewsletterDocAccessFields_region_Delete"]: {
		permission: boolean
};
	["NewsletterDocAccessFields_updatedAt"]: {
		create?: ModelTypes["NewsletterDocAccessFields_updatedAt_Create"] | undefined,
	read?: ModelTypes["NewsletterDocAccessFields_updatedAt_Read"] | undefined,
	update?: ModelTypes["NewsletterDocAccessFields_updatedAt_Update"] | undefined,
	delete?: ModelTypes["NewsletterDocAccessFields_updatedAt_Delete"] | undefined
};
	["NewsletterDocAccessFields_updatedAt_Create"]: {
		permission: boolean
};
	["NewsletterDocAccessFields_updatedAt_Read"]: {
		permission: boolean
};
	["NewsletterDocAccessFields_updatedAt_Update"]: {
		permission: boolean
};
	["NewsletterDocAccessFields_updatedAt_Delete"]: {
		permission: boolean
};
	["NewsletterDocAccessFields_createdAt"]: {
		create?: ModelTypes["NewsletterDocAccessFields_createdAt_Create"] | undefined,
	read?: ModelTypes["NewsletterDocAccessFields_createdAt_Read"] | undefined,
	update?: ModelTypes["NewsletterDocAccessFields_createdAt_Update"] | undefined,
	delete?: ModelTypes["NewsletterDocAccessFields_createdAt_Delete"] | undefined
};
	["NewsletterDocAccessFields_createdAt_Create"]: {
		permission: boolean
};
	["NewsletterDocAccessFields_createdAt_Read"]: {
		permission: boolean
};
	["NewsletterDocAccessFields_createdAt_Update"]: {
		permission: boolean
};
	["NewsletterDocAccessFields_createdAt_Delete"]: {
		permission: boolean
};
	["NewsletterCreateDocAccess"]: {
		permission: boolean,
	where?: ModelTypes["JSONObject"] | undefined
};
	["NewsletterReadDocAccess"]: {
		permission: boolean,
	where?: ModelTypes["JSONObject"] | undefined
};
	["NewsletterUpdateDocAccess"]: {
		permission: boolean,
	where?: ModelTypes["JSONObject"] | undefined
};
	["NewsletterDeleteDocAccess"]: {
		permission: boolean,
	where?: ModelTypes["JSONObject"] | undefined
};
	["BlogCategories"]: {
		docs?: Array<ModelTypes["BlogCategory"] | undefined> | undefined,
	hasNextPage?: boolean | undefined,
	hasPrevPage?: boolean | undefined,
	limit?: number | undefined,
	nextPage?: number | undefined,
	offset?: number | undefined,
	page?: number | undefined,
	pagingCounter?: number | undefined,
	prevPage?: number | undefined,
	totalDocs?: number | undefined,
	totalPages?: number | undefined
};
	["BlogCategory_where"]: {
	name?: ModelTypes["BlogCategory_name_operator"] | undefined,
	value?: ModelTypes["BlogCategory_value_operator"] | undefined,
	icon?: ModelTypes["BlogCategory_icon_operator"] | undefined,
	region?: ModelTypes["BlogCategory_region_operator"] | undefined,
	updatedAt?: ModelTypes["BlogCategory_updatedAt_operator"] | undefined,
	createdAt?: ModelTypes["BlogCategory_createdAt_operator"] | undefined,
	id?: ModelTypes["BlogCategory_id_operator"] | undefined,
	AND?: Array<ModelTypes["BlogCategory_where_and"] | undefined> | undefined,
	OR?: Array<ModelTypes["BlogCategory_where_or"] | undefined> | undefined
};
	["BlogCategory_name_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined
};
	["BlogCategory_value_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined
};
	["BlogCategory_icon_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	exists?: boolean | undefined
};
	["BlogCategory_region_operator"]: {
	equals?: ModelTypes["BlogCategory_region_Input"] | undefined,
	not_equals?: ModelTypes["BlogCategory_region_Input"] | undefined,
	in?: Array<ModelTypes["BlogCategory_region_Input"] | undefined> | undefined,
	not_in?: Array<ModelTypes["BlogCategory_region_Input"] | undefined> | undefined,
	all?: Array<ModelTypes["BlogCategory_region_Input"] | undefined> | undefined,
	exists?: boolean | undefined
};
	["BlogCategory_region_Input"]:BlogCategory_region_Input;
	["BlogCategory_updatedAt_operator"]: {
	equals?: ModelTypes["DateTime"] | undefined,
	not_equals?: ModelTypes["DateTime"] | undefined,
	greater_than_equal?: ModelTypes["DateTime"] | undefined,
	greater_than?: ModelTypes["DateTime"] | undefined,
	less_than_equal?: ModelTypes["DateTime"] | undefined,
	less_than?: ModelTypes["DateTime"] | undefined,
	like?: ModelTypes["DateTime"] | undefined,
	exists?: boolean | undefined
};
	["BlogCategory_createdAt_operator"]: {
	equals?: ModelTypes["DateTime"] | undefined,
	not_equals?: ModelTypes["DateTime"] | undefined,
	greater_than_equal?: ModelTypes["DateTime"] | undefined,
	greater_than?: ModelTypes["DateTime"] | undefined,
	less_than_equal?: ModelTypes["DateTime"] | undefined,
	less_than?: ModelTypes["DateTime"] | undefined,
	like?: ModelTypes["DateTime"] | undefined,
	exists?: boolean | undefined
};
	["BlogCategory_id_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["BlogCategory_where_and"]: {
	name?: ModelTypes["BlogCategory_name_operator"] | undefined,
	value?: ModelTypes["BlogCategory_value_operator"] | undefined,
	icon?: ModelTypes["BlogCategory_icon_operator"] | undefined,
	region?: ModelTypes["BlogCategory_region_operator"] | undefined,
	updatedAt?: ModelTypes["BlogCategory_updatedAt_operator"] | undefined,
	createdAt?: ModelTypes["BlogCategory_createdAt_operator"] | undefined,
	id?: ModelTypes["BlogCategory_id_operator"] | undefined,
	AND?: Array<ModelTypes["BlogCategory_where_and"] | undefined> | undefined,
	OR?: Array<ModelTypes["BlogCategory_where_or"] | undefined> | undefined
};
	["BlogCategory_where_or"]: {
	name?: ModelTypes["BlogCategory_name_operator"] | undefined,
	value?: ModelTypes["BlogCategory_value_operator"] | undefined,
	icon?: ModelTypes["BlogCategory_icon_operator"] | undefined,
	region?: ModelTypes["BlogCategory_region_operator"] | undefined,
	updatedAt?: ModelTypes["BlogCategory_updatedAt_operator"] | undefined,
	createdAt?: ModelTypes["BlogCategory_createdAt_operator"] | undefined,
	id?: ModelTypes["BlogCategory_id_operator"] | undefined,
	AND?: Array<ModelTypes["BlogCategory_where_and"] | undefined> | undefined,
	OR?: Array<ModelTypes["BlogCategory_where_or"] | undefined> | undefined
};
	["countBlogCategories"]: {
		totalDocs?: number | undefined
};
	["blog_categoriesDocAccess"]: {
		fields?: ModelTypes["BlogCategoriesDocAccessFields"] | undefined,
	create?: ModelTypes["BlogCategoriesCreateDocAccess"] | undefined,
	read?: ModelTypes["BlogCategoriesReadDocAccess"] | undefined,
	update?: ModelTypes["BlogCategoriesUpdateDocAccess"] | undefined,
	delete?: ModelTypes["BlogCategoriesDeleteDocAccess"] | undefined
};
	["BlogCategoriesDocAccessFields"]: {
		name?: ModelTypes["BlogCategoriesDocAccessFields_name"] | undefined,
	value?: ModelTypes["BlogCategoriesDocAccessFields_value"] | undefined,
	icon?: ModelTypes["BlogCategoriesDocAccessFields_icon"] | undefined,
	region?: ModelTypes["BlogCategoriesDocAccessFields_region"] | undefined,
	updatedAt?: ModelTypes["BlogCategoriesDocAccessFields_updatedAt"] | undefined,
	createdAt?: ModelTypes["BlogCategoriesDocAccessFields_createdAt"] | undefined
};
	["BlogCategoriesDocAccessFields_name"]: {
		create?: ModelTypes["BlogCategoriesDocAccessFields_name_Create"] | undefined,
	read?: ModelTypes["BlogCategoriesDocAccessFields_name_Read"] | undefined,
	update?: ModelTypes["BlogCategoriesDocAccessFields_name_Update"] | undefined,
	delete?: ModelTypes["BlogCategoriesDocAccessFields_name_Delete"] | undefined
};
	["BlogCategoriesDocAccessFields_name_Create"]: {
		permission: boolean
};
	["BlogCategoriesDocAccessFields_name_Read"]: {
		permission: boolean
};
	["BlogCategoriesDocAccessFields_name_Update"]: {
		permission: boolean
};
	["BlogCategoriesDocAccessFields_name_Delete"]: {
		permission: boolean
};
	["BlogCategoriesDocAccessFields_value"]: {
		create?: ModelTypes["BlogCategoriesDocAccessFields_value_Create"] | undefined,
	read?: ModelTypes["BlogCategoriesDocAccessFields_value_Read"] | undefined,
	update?: ModelTypes["BlogCategoriesDocAccessFields_value_Update"] | undefined,
	delete?: ModelTypes["BlogCategoriesDocAccessFields_value_Delete"] | undefined
};
	["BlogCategoriesDocAccessFields_value_Create"]: {
		permission: boolean
};
	["BlogCategoriesDocAccessFields_value_Read"]: {
		permission: boolean
};
	["BlogCategoriesDocAccessFields_value_Update"]: {
		permission: boolean
};
	["BlogCategoriesDocAccessFields_value_Delete"]: {
		permission: boolean
};
	["BlogCategoriesDocAccessFields_icon"]: {
		create?: ModelTypes["BlogCategoriesDocAccessFields_icon_Create"] | undefined,
	read?: ModelTypes["BlogCategoriesDocAccessFields_icon_Read"] | undefined,
	update?: ModelTypes["BlogCategoriesDocAccessFields_icon_Update"] | undefined,
	delete?: ModelTypes["BlogCategoriesDocAccessFields_icon_Delete"] | undefined
};
	["BlogCategoriesDocAccessFields_icon_Create"]: {
		permission: boolean
};
	["BlogCategoriesDocAccessFields_icon_Read"]: {
		permission: boolean
};
	["BlogCategoriesDocAccessFields_icon_Update"]: {
		permission: boolean
};
	["BlogCategoriesDocAccessFields_icon_Delete"]: {
		permission: boolean
};
	["BlogCategoriesDocAccessFields_region"]: {
		create?: ModelTypes["BlogCategoriesDocAccessFields_region_Create"] | undefined,
	read?: ModelTypes["BlogCategoriesDocAccessFields_region_Read"] | undefined,
	update?: ModelTypes["BlogCategoriesDocAccessFields_region_Update"] | undefined,
	delete?: ModelTypes["BlogCategoriesDocAccessFields_region_Delete"] | undefined
};
	["BlogCategoriesDocAccessFields_region_Create"]: {
		permission: boolean
};
	["BlogCategoriesDocAccessFields_region_Read"]: {
		permission: boolean
};
	["BlogCategoriesDocAccessFields_region_Update"]: {
		permission: boolean
};
	["BlogCategoriesDocAccessFields_region_Delete"]: {
		permission: boolean
};
	["BlogCategoriesDocAccessFields_updatedAt"]: {
		create?: ModelTypes["BlogCategoriesDocAccessFields_updatedAt_Create"] | undefined,
	read?: ModelTypes["BlogCategoriesDocAccessFields_updatedAt_Read"] | undefined,
	update?: ModelTypes["BlogCategoriesDocAccessFields_updatedAt_Update"] | undefined,
	delete?: ModelTypes["BlogCategoriesDocAccessFields_updatedAt_Delete"] | undefined
};
	["BlogCategoriesDocAccessFields_updatedAt_Create"]: {
		permission: boolean
};
	["BlogCategoriesDocAccessFields_updatedAt_Read"]: {
		permission: boolean
};
	["BlogCategoriesDocAccessFields_updatedAt_Update"]: {
		permission: boolean
};
	["BlogCategoriesDocAccessFields_updatedAt_Delete"]: {
		permission: boolean
};
	["BlogCategoriesDocAccessFields_createdAt"]: {
		create?: ModelTypes["BlogCategoriesDocAccessFields_createdAt_Create"] | undefined,
	read?: ModelTypes["BlogCategoriesDocAccessFields_createdAt_Read"] | undefined,
	update?: ModelTypes["BlogCategoriesDocAccessFields_createdAt_Update"] | undefined,
	delete?: ModelTypes["BlogCategoriesDocAccessFields_createdAt_Delete"] | undefined
};
	["BlogCategoriesDocAccessFields_createdAt_Create"]: {
		permission: boolean
};
	["BlogCategoriesDocAccessFields_createdAt_Read"]: {
		permission: boolean
};
	["BlogCategoriesDocAccessFields_createdAt_Update"]: {
		permission: boolean
};
	["BlogCategoriesDocAccessFields_createdAt_Delete"]: {
		permission: boolean
};
	["BlogCategoriesCreateDocAccess"]: {
		permission: boolean,
	where?: ModelTypes["JSONObject"] | undefined
};
	["BlogCategoriesReadDocAccess"]: {
		permission: boolean,
	where?: ModelTypes["JSONObject"] | undefined
};
	["BlogCategoriesUpdateDocAccess"]: {
		permission: boolean,
	where?: ModelTypes["JSONObject"] | undefined
};
	["BlogCategoriesDeleteDocAccess"]: {
		permission: boolean,
	where?: ModelTypes["JSONObject"] | undefined
};
	["Tags"]: {
		docs?: Array<ModelTypes["Tag"] | undefined> | undefined,
	hasNextPage?: boolean | undefined,
	hasPrevPage?: boolean | undefined,
	limit?: number | undefined,
	nextPage?: number | undefined,
	offset?: number | undefined,
	page?: number | undefined,
	pagingCounter?: number | undefined,
	prevPage?: number | undefined,
	totalDocs?: number | undefined,
	totalPages?: number | undefined
};
	["Tag_where"]: {
	name?: ModelTypes["Tag_name_operator"] | undefined,
	value?: ModelTypes["Tag_value_operator"] | undefined,
	region?: ModelTypes["Tag_region_operator"] | undefined,
	count?: ModelTypes["Tag_count_operator"] | undefined,
	skipCountCheck?: ModelTypes["Tag_skipCountCheck_operator"] | undefined,
	removeNextCron?: ModelTypes["Tag_removeNextCron_operator"] | undefined,
	id?: ModelTypes["Tag_id_operator"] | undefined,
	AND?: Array<ModelTypes["Tag_where_and"] | undefined> | undefined,
	OR?: Array<ModelTypes["Tag_where_or"] | undefined> | undefined
};
	["Tag_name_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined
};
	["Tag_value_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined
};
	["Tag_region_operator"]: {
	equals?: ModelTypes["Tag_region_Input"] | undefined,
	not_equals?: ModelTypes["Tag_region_Input"] | undefined,
	in?: Array<ModelTypes["Tag_region_Input"] | undefined> | undefined,
	not_in?: Array<ModelTypes["Tag_region_Input"] | undefined> | undefined,
	all?: Array<ModelTypes["Tag_region_Input"] | undefined> | undefined,
	exists?: boolean | undefined
};
	["Tag_region_Input"]:Tag_region_Input;
	["Tag_count_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Tag_skipCountCheck_operator"]: {
	equals?: boolean | undefined,
	not_equals?: boolean | undefined,
	exists?: boolean | undefined
};
	["Tag_removeNextCron_operator"]: {
	equals?: boolean | undefined,
	not_equals?: boolean | undefined,
	exists?: boolean | undefined
};
	["Tag_id_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Tag_where_and"]: {
	name?: ModelTypes["Tag_name_operator"] | undefined,
	value?: ModelTypes["Tag_value_operator"] | undefined,
	region?: ModelTypes["Tag_region_operator"] | undefined,
	count?: ModelTypes["Tag_count_operator"] | undefined,
	skipCountCheck?: ModelTypes["Tag_skipCountCheck_operator"] | undefined,
	removeNextCron?: ModelTypes["Tag_removeNextCron_operator"] | undefined,
	id?: ModelTypes["Tag_id_operator"] | undefined,
	AND?: Array<ModelTypes["Tag_where_and"] | undefined> | undefined,
	OR?: Array<ModelTypes["Tag_where_or"] | undefined> | undefined
};
	["Tag_where_or"]: {
	name?: ModelTypes["Tag_name_operator"] | undefined,
	value?: ModelTypes["Tag_value_operator"] | undefined,
	region?: ModelTypes["Tag_region_operator"] | undefined,
	count?: ModelTypes["Tag_count_operator"] | undefined,
	skipCountCheck?: ModelTypes["Tag_skipCountCheck_operator"] | undefined,
	removeNextCron?: ModelTypes["Tag_removeNextCron_operator"] | undefined,
	id?: ModelTypes["Tag_id_operator"] | undefined,
	AND?: Array<ModelTypes["Tag_where_and"] | undefined> | undefined,
	OR?: Array<ModelTypes["Tag_where_or"] | undefined> | undefined
};
	["countTags"]: {
		totalDocs?: number | undefined
};
	["tagsDocAccess"]: {
		fields?: ModelTypes["TagsDocAccessFields"] | undefined,
	create?: ModelTypes["TagsCreateDocAccess"] | undefined,
	read?: ModelTypes["TagsReadDocAccess"] | undefined,
	update?: ModelTypes["TagsUpdateDocAccess"] | undefined,
	delete?: ModelTypes["TagsDeleteDocAccess"] | undefined
};
	["TagsDocAccessFields"]: {
		name?: ModelTypes["TagsDocAccessFields_name"] | undefined,
	value?: ModelTypes["TagsDocAccessFields_value"] | undefined,
	region?: ModelTypes["TagsDocAccessFields_region"] | undefined,
	count?: ModelTypes["TagsDocAccessFields_count"] | undefined,
	skipCountCheck?: ModelTypes["TagsDocAccessFields_skipCountCheck"] | undefined,
	removeNextCron?: ModelTypes["TagsDocAccessFields_removeNextCron"] | undefined
};
	["TagsDocAccessFields_name"]: {
		create?: ModelTypes["TagsDocAccessFields_name_Create"] | undefined,
	read?: ModelTypes["TagsDocAccessFields_name_Read"] | undefined,
	update?: ModelTypes["TagsDocAccessFields_name_Update"] | undefined,
	delete?: ModelTypes["TagsDocAccessFields_name_Delete"] | undefined
};
	["TagsDocAccessFields_name_Create"]: {
		permission: boolean
};
	["TagsDocAccessFields_name_Read"]: {
		permission: boolean
};
	["TagsDocAccessFields_name_Update"]: {
		permission: boolean
};
	["TagsDocAccessFields_name_Delete"]: {
		permission: boolean
};
	["TagsDocAccessFields_value"]: {
		create?: ModelTypes["TagsDocAccessFields_value_Create"] | undefined,
	read?: ModelTypes["TagsDocAccessFields_value_Read"] | undefined,
	update?: ModelTypes["TagsDocAccessFields_value_Update"] | undefined,
	delete?: ModelTypes["TagsDocAccessFields_value_Delete"] | undefined
};
	["TagsDocAccessFields_value_Create"]: {
		permission: boolean
};
	["TagsDocAccessFields_value_Read"]: {
		permission: boolean
};
	["TagsDocAccessFields_value_Update"]: {
		permission: boolean
};
	["TagsDocAccessFields_value_Delete"]: {
		permission: boolean
};
	["TagsDocAccessFields_region"]: {
		create?: ModelTypes["TagsDocAccessFields_region_Create"] | undefined,
	read?: ModelTypes["TagsDocAccessFields_region_Read"] | undefined,
	update?: ModelTypes["TagsDocAccessFields_region_Update"] | undefined,
	delete?: ModelTypes["TagsDocAccessFields_region_Delete"] | undefined
};
	["TagsDocAccessFields_region_Create"]: {
		permission: boolean
};
	["TagsDocAccessFields_region_Read"]: {
		permission: boolean
};
	["TagsDocAccessFields_region_Update"]: {
		permission: boolean
};
	["TagsDocAccessFields_region_Delete"]: {
		permission: boolean
};
	["TagsDocAccessFields_count"]: {
		create?: ModelTypes["TagsDocAccessFields_count_Create"] | undefined,
	read?: ModelTypes["TagsDocAccessFields_count_Read"] | undefined,
	update?: ModelTypes["TagsDocAccessFields_count_Update"] | undefined,
	delete?: ModelTypes["TagsDocAccessFields_count_Delete"] | undefined
};
	["TagsDocAccessFields_count_Create"]: {
		permission: boolean
};
	["TagsDocAccessFields_count_Read"]: {
		permission: boolean
};
	["TagsDocAccessFields_count_Update"]: {
		permission: boolean
};
	["TagsDocAccessFields_count_Delete"]: {
		permission: boolean
};
	["TagsDocAccessFields_skipCountCheck"]: {
		create?: ModelTypes["TagsDocAccessFields_skipCountCheck_Create"] | undefined,
	read?: ModelTypes["TagsDocAccessFields_skipCountCheck_Read"] | undefined,
	update?: ModelTypes["TagsDocAccessFields_skipCountCheck_Update"] | undefined,
	delete?: ModelTypes["TagsDocAccessFields_skipCountCheck_Delete"] | undefined
};
	["TagsDocAccessFields_skipCountCheck_Create"]: {
		permission: boolean
};
	["TagsDocAccessFields_skipCountCheck_Read"]: {
		permission: boolean
};
	["TagsDocAccessFields_skipCountCheck_Update"]: {
		permission: boolean
};
	["TagsDocAccessFields_skipCountCheck_Delete"]: {
		permission: boolean
};
	["TagsDocAccessFields_removeNextCron"]: {
		create?: ModelTypes["TagsDocAccessFields_removeNextCron_Create"] | undefined,
	read?: ModelTypes["TagsDocAccessFields_removeNextCron_Read"] | undefined,
	update?: ModelTypes["TagsDocAccessFields_removeNextCron_Update"] | undefined,
	delete?: ModelTypes["TagsDocAccessFields_removeNextCron_Delete"] | undefined
};
	["TagsDocAccessFields_removeNextCron_Create"]: {
		permission: boolean
};
	["TagsDocAccessFields_removeNextCron_Read"]: {
		permission: boolean
};
	["TagsDocAccessFields_removeNextCron_Update"]: {
		permission: boolean
};
	["TagsDocAccessFields_removeNextCron_Delete"]: {
		permission: boolean
};
	["TagsCreateDocAccess"]: {
		permission: boolean,
	where?: ModelTypes["JSONObject"] | undefined
};
	["TagsReadDocAccess"]: {
		permission: boolean,
	where?: ModelTypes["JSONObject"] | undefined
};
	["TagsUpdateDocAccess"]: {
		permission: boolean,
	where?: ModelTypes["JSONObject"] | undefined
};
	["TagsDeleteDocAccess"]: {
		permission: boolean,
	where?: ModelTypes["JSONObject"] | undefined
};
	["SpecialTags"]: {
		docs?: Array<ModelTypes["SpecialTag"] | undefined> | undefined,
	hasNextPage?: boolean | undefined,
	hasPrevPage?: boolean | undefined,
	limit?: number | undefined,
	nextPage?: number | undefined,
	offset?: number | undefined,
	page?: number | undefined,
	pagingCounter?: number | undefined,
	prevPage?: number | undefined,
	totalDocs?: number | undefined,
	totalPages?: number | undefined
};
	["SpecialTag_where"]: {
	name?: ModelTypes["SpecialTag_name_operator"] | undefined,
	value?: ModelTypes["SpecialTag_value_operator"] | undefined,
	region?: ModelTypes["SpecialTag_region_operator"] | undefined,
	id?: ModelTypes["SpecialTag_id_operator"] | undefined,
	AND?: Array<ModelTypes["SpecialTag_where_and"] | undefined> | undefined,
	OR?: Array<ModelTypes["SpecialTag_where_or"] | undefined> | undefined
};
	["SpecialTag_name_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined
};
	["SpecialTag_value_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined
};
	["SpecialTag_region_operator"]: {
	equals?: ModelTypes["SpecialTag_region_Input"] | undefined,
	not_equals?: ModelTypes["SpecialTag_region_Input"] | undefined,
	in?: Array<ModelTypes["SpecialTag_region_Input"] | undefined> | undefined,
	not_in?: Array<ModelTypes["SpecialTag_region_Input"] | undefined> | undefined,
	all?: Array<ModelTypes["SpecialTag_region_Input"] | undefined> | undefined,
	exists?: boolean | undefined
};
	["SpecialTag_region_Input"]:SpecialTag_region_Input;
	["SpecialTag_id_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["SpecialTag_where_and"]: {
	name?: ModelTypes["SpecialTag_name_operator"] | undefined,
	value?: ModelTypes["SpecialTag_value_operator"] | undefined,
	region?: ModelTypes["SpecialTag_region_operator"] | undefined,
	id?: ModelTypes["SpecialTag_id_operator"] | undefined,
	AND?: Array<ModelTypes["SpecialTag_where_and"] | undefined> | undefined,
	OR?: Array<ModelTypes["SpecialTag_where_or"] | undefined> | undefined
};
	["SpecialTag_where_or"]: {
	name?: ModelTypes["SpecialTag_name_operator"] | undefined,
	value?: ModelTypes["SpecialTag_value_operator"] | undefined,
	region?: ModelTypes["SpecialTag_region_operator"] | undefined,
	id?: ModelTypes["SpecialTag_id_operator"] | undefined,
	AND?: Array<ModelTypes["SpecialTag_where_and"] | undefined> | undefined,
	OR?: Array<ModelTypes["SpecialTag_where_or"] | undefined> | undefined
};
	["countSpecialTags"]: {
		totalDocs?: number | undefined
};
	["specialTagsDocAccess"]: {
		fields?: ModelTypes["SpecialTagsDocAccessFields"] | undefined,
	create?: ModelTypes["SpecialTagsCreateDocAccess"] | undefined,
	read?: ModelTypes["SpecialTagsReadDocAccess"] | undefined,
	update?: ModelTypes["SpecialTagsUpdateDocAccess"] | undefined,
	delete?: ModelTypes["SpecialTagsDeleteDocAccess"] | undefined
};
	["SpecialTagsDocAccessFields"]: {
		name?: ModelTypes["SpecialTagsDocAccessFields_name"] | undefined,
	value?: ModelTypes["SpecialTagsDocAccessFields_value"] | undefined,
	region?: ModelTypes["SpecialTagsDocAccessFields_region"] | undefined
};
	["SpecialTagsDocAccessFields_name"]: {
		create?: ModelTypes["SpecialTagsDocAccessFields_name_Create"] | undefined,
	read?: ModelTypes["SpecialTagsDocAccessFields_name_Read"] | undefined,
	update?: ModelTypes["SpecialTagsDocAccessFields_name_Update"] | undefined,
	delete?: ModelTypes["SpecialTagsDocAccessFields_name_Delete"] | undefined
};
	["SpecialTagsDocAccessFields_name_Create"]: {
		permission: boolean
};
	["SpecialTagsDocAccessFields_name_Read"]: {
		permission: boolean
};
	["SpecialTagsDocAccessFields_name_Update"]: {
		permission: boolean
};
	["SpecialTagsDocAccessFields_name_Delete"]: {
		permission: boolean
};
	["SpecialTagsDocAccessFields_value"]: {
		create?: ModelTypes["SpecialTagsDocAccessFields_value_Create"] | undefined,
	read?: ModelTypes["SpecialTagsDocAccessFields_value_Read"] | undefined,
	update?: ModelTypes["SpecialTagsDocAccessFields_value_Update"] | undefined,
	delete?: ModelTypes["SpecialTagsDocAccessFields_value_Delete"] | undefined
};
	["SpecialTagsDocAccessFields_value_Create"]: {
		permission: boolean
};
	["SpecialTagsDocAccessFields_value_Read"]: {
		permission: boolean
};
	["SpecialTagsDocAccessFields_value_Update"]: {
		permission: boolean
};
	["SpecialTagsDocAccessFields_value_Delete"]: {
		permission: boolean
};
	["SpecialTagsDocAccessFields_region"]: {
		create?: ModelTypes["SpecialTagsDocAccessFields_region_Create"] | undefined,
	read?: ModelTypes["SpecialTagsDocAccessFields_region_Read"] | undefined,
	update?: ModelTypes["SpecialTagsDocAccessFields_region_Update"] | undefined,
	delete?: ModelTypes["SpecialTagsDocAccessFields_region_Delete"] | undefined
};
	["SpecialTagsDocAccessFields_region_Create"]: {
		permission: boolean
};
	["SpecialTagsDocAccessFields_region_Read"]: {
		permission: boolean
};
	["SpecialTagsDocAccessFields_region_Update"]: {
		permission: boolean
};
	["SpecialTagsDocAccessFields_region_Delete"]: {
		permission: boolean
};
	["SpecialTagsCreateDocAccess"]: {
		permission: boolean,
	where?: ModelTypes["JSONObject"] | undefined
};
	["SpecialTagsReadDocAccess"]: {
		permission: boolean,
	where?: ModelTypes["JSONObject"] | undefined
};
	["SpecialTagsUpdateDocAccess"]: {
		permission: boolean,
	where?: ModelTypes["JSONObject"] | undefined
};
	["SpecialTagsDeleteDocAccess"]: {
		permission: boolean,
	where?: ModelTypes["JSONObject"] | undefined
};
	["Dates"]: {
		docs?: Array<ModelTypes["Date"] | undefined> | undefined,
	hasNextPage?: boolean | undefined,
	hasPrevPage?: boolean | undefined,
	limit?: number | undefined,
	nextPage?: number | undefined,
	offset?: number | undefined,
	page?: number | undefined,
	pagingCounter?: number | undefined,
	prevPage?: number | undefined,
	totalDocs?: number | undefined,
	totalPages?: number | undefined
};
	["Date_where"]: {
	name?: ModelTypes["Date_name_operator"] | undefined,
	value?: ModelTypes["Date_value_operator"] | undefined,
	type?: ModelTypes["Date_type_operator"] | undefined,
	start?: ModelTypes["Date_start_operator"] | undefined,
	end?: ModelTypes["Date_end_operator"] | undefined,
	_title?: ModelTypes["Date__title_operator"] | undefined,
	region?: ModelTypes["Date_region_operator"] | undefined,
	updatedAt?: ModelTypes["Date_updatedAt_operator"] | undefined,
	createdAt?: ModelTypes["Date_createdAt_operator"] | undefined,
	id?: ModelTypes["Date_id_operator"] | undefined,
	AND?: Array<ModelTypes["Date_where_and"] | undefined> | undefined,
	OR?: Array<ModelTypes["Date_where_or"] | undefined> | undefined
};
	["Date_name_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined
};
	["Date_value_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined
};
	["Date_type_operator"]: {
	equals?: ModelTypes["Date_type_Input"] | undefined,
	not_equals?: ModelTypes["Date_type_Input"] | undefined,
	in?: Array<ModelTypes["Date_type_Input"] | undefined> | undefined,
	not_in?: Array<ModelTypes["Date_type_Input"] | undefined> | undefined,
	all?: Array<ModelTypes["Date_type_Input"] | undefined> | undefined
};
	["Date_type_Input"]:Date_type_Input;
	["Date_start_operator"]: {
	equals?: ModelTypes["DateTime"] | undefined,
	not_equals?: ModelTypes["DateTime"] | undefined,
	greater_than_equal?: ModelTypes["DateTime"] | undefined,
	greater_than?: ModelTypes["DateTime"] | undefined,
	less_than_equal?: ModelTypes["DateTime"] | undefined,
	less_than?: ModelTypes["DateTime"] | undefined,
	like?: ModelTypes["DateTime"] | undefined,
	exists?: boolean | undefined
};
	["Date_end_operator"]: {
	equals?: ModelTypes["DateTime"] | undefined,
	not_equals?: ModelTypes["DateTime"] | undefined,
	greater_than_equal?: ModelTypes["DateTime"] | undefined,
	greater_than?: ModelTypes["DateTime"] | undefined,
	less_than_equal?: ModelTypes["DateTime"] | undefined,
	less_than?: ModelTypes["DateTime"] | undefined,
	like?: ModelTypes["DateTime"] | undefined,
	exists?: boolean | undefined
};
	["Date__title_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Date_region_operator"]: {
	equals?: ModelTypes["Date_region_Input"] | undefined,
	not_equals?: ModelTypes["Date_region_Input"] | undefined,
	in?: Array<ModelTypes["Date_region_Input"] | undefined> | undefined,
	not_in?: Array<ModelTypes["Date_region_Input"] | undefined> | undefined,
	all?: Array<ModelTypes["Date_region_Input"] | undefined> | undefined,
	exists?: boolean | undefined
};
	["Date_region_Input"]:Date_region_Input;
	["Date_updatedAt_operator"]: {
	equals?: ModelTypes["DateTime"] | undefined,
	not_equals?: ModelTypes["DateTime"] | undefined,
	greater_than_equal?: ModelTypes["DateTime"] | undefined,
	greater_than?: ModelTypes["DateTime"] | undefined,
	less_than_equal?: ModelTypes["DateTime"] | undefined,
	less_than?: ModelTypes["DateTime"] | undefined,
	like?: ModelTypes["DateTime"] | undefined,
	exists?: boolean | undefined
};
	["Date_createdAt_operator"]: {
	equals?: ModelTypes["DateTime"] | undefined,
	not_equals?: ModelTypes["DateTime"] | undefined,
	greater_than_equal?: ModelTypes["DateTime"] | undefined,
	greater_than?: ModelTypes["DateTime"] | undefined,
	less_than_equal?: ModelTypes["DateTime"] | undefined,
	less_than?: ModelTypes["DateTime"] | undefined,
	like?: ModelTypes["DateTime"] | undefined,
	exists?: boolean | undefined
};
	["Date_id_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Date_where_and"]: {
	name?: ModelTypes["Date_name_operator"] | undefined,
	value?: ModelTypes["Date_value_operator"] | undefined,
	type?: ModelTypes["Date_type_operator"] | undefined,
	start?: ModelTypes["Date_start_operator"] | undefined,
	end?: ModelTypes["Date_end_operator"] | undefined,
	_title?: ModelTypes["Date__title_operator"] | undefined,
	region?: ModelTypes["Date_region_operator"] | undefined,
	updatedAt?: ModelTypes["Date_updatedAt_operator"] | undefined,
	createdAt?: ModelTypes["Date_createdAt_operator"] | undefined,
	id?: ModelTypes["Date_id_operator"] | undefined,
	AND?: Array<ModelTypes["Date_where_and"] | undefined> | undefined,
	OR?: Array<ModelTypes["Date_where_or"] | undefined> | undefined
};
	["Date_where_or"]: {
	name?: ModelTypes["Date_name_operator"] | undefined,
	value?: ModelTypes["Date_value_operator"] | undefined,
	type?: ModelTypes["Date_type_operator"] | undefined,
	start?: ModelTypes["Date_start_operator"] | undefined,
	end?: ModelTypes["Date_end_operator"] | undefined,
	_title?: ModelTypes["Date__title_operator"] | undefined,
	region?: ModelTypes["Date_region_operator"] | undefined,
	updatedAt?: ModelTypes["Date_updatedAt_operator"] | undefined,
	createdAt?: ModelTypes["Date_createdAt_operator"] | undefined,
	id?: ModelTypes["Date_id_operator"] | undefined,
	AND?: Array<ModelTypes["Date_where_and"] | undefined> | undefined,
	OR?: Array<ModelTypes["Date_where_or"] | undefined> | undefined
};
	["countDates"]: {
		totalDocs?: number | undefined
};
	["dateDocAccess"]: {
		fields?: ModelTypes["DateDocAccessFields"] | undefined,
	create?: ModelTypes["DateCreateDocAccess"] | undefined,
	read?: ModelTypes["DateReadDocAccess"] | undefined,
	update?: ModelTypes["DateUpdateDocAccess"] | undefined,
	delete?: ModelTypes["DateDeleteDocAccess"] | undefined
};
	["DateDocAccessFields"]: {
		name?: ModelTypes["DateDocAccessFields_name"] | undefined,
	value?: ModelTypes["DateDocAccessFields_value"] | undefined,
	type?: ModelTypes["DateDocAccessFields_type"] | undefined,
	start?: ModelTypes["DateDocAccessFields_start"] | undefined,
	end?: ModelTypes["DateDocAccessFields_end"] | undefined,
	_title?: ModelTypes["DateDocAccessFields__title"] | undefined,
	region?: ModelTypes["DateDocAccessFields_region"] | undefined,
	updatedAt?: ModelTypes["DateDocAccessFields_updatedAt"] | undefined,
	createdAt?: ModelTypes["DateDocAccessFields_createdAt"] | undefined
};
	["DateDocAccessFields_name"]: {
		create?: ModelTypes["DateDocAccessFields_name_Create"] | undefined,
	read?: ModelTypes["DateDocAccessFields_name_Read"] | undefined,
	update?: ModelTypes["DateDocAccessFields_name_Update"] | undefined,
	delete?: ModelTypes["DateDocAccessFields_name_Delete"] | undefined
};
	["DateDocAccessFields_name_Create"]: {
		permission: boolean
};
	["DateDocAccessFields_name_Read"]: {
		permission: boolean
};
	["DateDocAccessFields_name_Update"]: {
		permission: boolean
};
	["DateDocAccessFields_name_Delete"]: {
		permission: boolean
};
	["DateDocAccessFields_value"]: {
		create?: ModelTypes["DateDocAccessFields_value_Create"] | undefined,
	read?: ModelTypes["DateDocAccessFields_value_Read"] | undefined,
	update?: ModelTypes["DateDocAccessFields_value_Update"] | undefined,
	delete?: ModelTypes["DateDocAccessFields_value_Delete"] | undefined
};
	["DateDocAccessFields_value_Create"]: {
		permission: boolean
};
	["DateDocAccessFields_value_Read"]: {
		permission: boolean
};
	["DateDocAccessFields_value_Update"]: {
		permission: boolean
};
	["DateDocAccessFields_value_Delete"]: {
		permission: boolean
};
	["DateDocAccessFields_type"]: {
		create?: ModelTypes["DateDocAccessFields_type_Create"] | undefined,
	read?: ModelTypes["DateDocAccessFields_type_Read"] | undefined,
	update?: ModelTypes["DateDocAccessFields_type_Update"] | undefined,
	delete?: ModelTypes["DateDocAccessFields_type_Delete"] | undefined
};
	["DateDocAccessFields_type_Create"]: {
		permission: boolean
};
	["DateDocAccessFields_type_Read"]: {
		permission: boolean
};
	["DateDocAccessFields_type_Update"]: {
		permission: boolean
};
	["DateDocAccessFields_type_Delete"]: {
		permission: boolean
};
	["DateDocAccessFields_start"]: {
		create?: ModelTypes["DateDocAccessFields_start_Create"] | undefined,
	read?: ModelTypes["DateDocAccessFields_start_Read"] | undefined,
	update?: ModelTypes["DateDocAccessFields_start_Update"] | undefined,
	delete?: ModelTypes["DateDocAccessFields_start_Delete"] | undefined
};
	["DateDocAccessFields_start_Create"]: {
		permission: boolean
};
	["DateDocAccessFields_start_Read"]: {
		permission: boolean
};
	["DateDocAccessFields_start_Update"]: {
		permission: boolean
};
	["DateDocAccessFields_start_Delete"]: {
		permission: boolean
};
	["DateDocAccessFields_end"]: {
		create?: ModelTypes["DateDocAccessFields_end_Create"] | undefined,
	read?: ModelTypes["DateDocAccessFields_end_Read"] | undefined,
	update?: ModelTypes["DateDocAccessFields_end_Update"] | undefined,
	delete?: ModelTypes["DateDocAccessFields_end_Delete"] | undefined
};
	["DateDocAccessFields_end_Create"]: {
		permission: boolean
};
	["DateDocAccessFields_end_Read"]: {
		permission: boolean
};
	["DateDocAccessFields_end_Update"]: {
		permission: boolean
};
	["DateDocAccessFields_end_Delete"]: {
		permission: boolean
};
	["DateDocAccessFields__title"]: {
		create?: ModelTypes["DateDocAccessFields__title_Create"] | undefined,
	read?: ModelTypes["DateDocAccessFields__title_Read"] | undefined,
	update?: ModelTypes["DateDocAccessFields__title_Update"] | undefined,
	delete?: ModelTypes["DateDocAccessFields__title_Delete"] | undefined
};
	["DateDocAccessFields__title_Create"]: {
		permission: boolean
};
	["DateDocAccessFields__title_Read"]: {
		permission: boolean
};
	["DateDocAccessFields__title_Update"]: {
		permission: boolean
};
	["DateDocAccessFields__title_Delete"]: {
		permission: boolean
};
	["DateDocAccessFields_region"]: {
		create?: ModelTypes["DateDocAccessFields_region_Create"] | undefined,
	read?: ModelTypes["DateDocAccessFields_region_Read"] | undefined,
	update?: ModelTypes["DateDocAccessFields_region_Update"] | undefined,
	delete?: ModelTypes["DateDocAccessFields_region_Delete"] | undefined
};
	["DateDocAccessFields_region_Create"]: {
		permission: boolean
};
	["DateDocAccessFields_region_Read"]: {
		permission: boolean
};
	["DateDocAccessFields_region_Update"]: {
		permission: boolean
};
	["DateDocAccessFields_region_Delete"]: {
		permission: boolean
};
	["DateDocAccessFields_updatedAt"]: {
		create?: ModelTypes["DateDocAccessFields_updatedAt_Create"] | undefined,
	read?: ModelTypes["DateDocAccessFields_updatedAt_Read"] | undefined,
	update?: ModelTypes["DateDocAccessFields_updatedAt_Update"] | undefined,
	delete?: ModelTypes["DateDocAccessFields_updatedAt_Delete"] | undefined
};
	["DateDocAccessFields_updatedAt_Create"]: {
		permission: boolean
};
	["DateDocAccessFields_updatedAt_Read"]: {
		permission: boolean
};
	["DateDocAccessFields_updatedAt_Update"]: {
		permission: boolean
};
	["DateDocAccessFields_updatedAt_Delete"]: {
		permission: boolean
};
	["DateDocAccessFields_createdAt"]: {
		create?: ModelTypes["DateDocAccessFields_createdAt_Create"] | undefined,
	read?: ModelTypes["DateDocAccessFields_createdAt_Read"] | undefined,
	update?: ModelTypes["DateDocAccessFields_createdAt_Update"] | undefined,
	delete?: ModelTypes["DateDocAccessFields_createdAt_Delete"] | undefined
};
	["DateDocAccessFields_createdAt_Create"]: {
		permission: boolean
};
	["DateDocAccessFields_createdAt_Read"]: {
		permission: boolean
};
	["DateDocAccessFields_createdAt_Update"]: {
		permission: boolean
};
	["DateDocAccessFields_createdAt_Delete"]: {
		permission: boolean
};
	["DateCreateDocAccess"]: {
		permission: boolean,
	where?: ModelTypes["JSONObject"] | undefined
};
	["DateReadDocAccess"]: {
		permission: boolean,
	where?: ModelTypes["JSONObject"] | undefined
};
	["DateUpdateDocAccess"]: {
		permission: boolean,
	where?: ModelTypes["JSONObject"] | undefined
};
	["DateDeleteDocAccess"]: {
		permission: boolean,
	where?: ModelTypes["JSONObject"] | undefined
};
	["Districts"]: {
		docs?: Array<ModelTypes["District"] | undefined> | undefined,
	hasNextPage?: boolean | undefined,
	hasPrevPage?: boolean | undefined,
	limit?: number | undefined,
	nextPage?: number | undefined,
	offset?: number | undefined,
	page?: number | undefined,
	pagingCounter?: number | undefined,
	prevPage?: number | undefined,
	totalDocs?: number | undefined,
	totalPages?: number | undefined
};
	["District_where"]: {
	name?: ModelTypes["District_name_operator"] | undefined,
	level?: ModelTypes["District_level_operator"] | undefined,
	children?: ModelTypes["District_children_operator"] | undefined,
	value?: ModelTypes["District_value_operator"] | undefined,
	lat?: ModelTypes["District_lat_operator"] | undefined,
	long?: ModelTypes["District_long_operator"] | undefined,
	radius?: ModelTypes["District_radius_operator"] | undefined,
	region?: ModelTypes["District_region_operator"] | undefined,
	updatedAt?: ModelTypes["District_updatedAt_operator"] | undefined,
	createdAt?: ModelTypes["District_createdAt_operator"] | undefined,
	id?: ModelTypes["District_id_operator"] | undefined,
	AND?: Array<ModelTypes["District_where_and"] | undefined> | undefined,
	OR?: Array<ModelTypes["District_where_or"] | undefined> | undefined
};
	["District_name_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined
};
	["District_level_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined
};
	["District_children_operator"]: {
	equals?: ModelTypes["JSON"] | undefined,
	not_equals?: ModelTypes["JSON"] | undefined,
	in?: Array<ModelTypes["JSON"] | undefined> | undefined,
	not_in?: Array<ModelTypes["JSON"] | undefined> | undefined,
	all?: Array<ModelTypes["JSON"] | undefined> | undefined,
	exists?: boolean | undefined
};
	["District_value_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined
};
	["District_lat_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["District_long_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["District_radius_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["District_region_operator"]: {
	equals?: ModelTypes["District_region_Input"] | undefined,
	not_equals?: ModelTypes["District_region_Input"] | undefined,
	in?: Array<ModelTypes["District_region_Input"] | undefined> | undefined,
	not_in?: Array<ModelTypes["District_region_Input"] | undefined> | undefined,
	all?: Array<ModelTypes["District_region_Input"] | undefined> | undefined,
	exists?: boolean | undefined
};
	["District_region_Input"]:District_region_Input;
	["District_updatedAt_operator"]: {
	equals?: ModelTypes["DateTime"] | undefined,
	not_equals?: ModelTypes["DateTime"] | undefined,
	greater_than_equal?: ModelTypes["DateTime"] | undefined,
	greater_than?: ModelTypes["DateTime"] | undefined,
	less_than_equal?: ModelTypes["DateTime"] | undefined,
	less_than?: ModelTypes["DateTime"] | undefined,
	like?: ModelTypes["DateTime"] | undefined,
	exists?: boolean | undefined
};
	["District_createdAt_operator"]: {
	equals?: ModelTypes["DateTime"] | undefined,
	not_equals?: ModelTypes["DateTime"] | undefined,
	greater_than_equal?: ModelTypes["DateTime"] | undefined,
	greater_than?: ModelTypes["DateTime"] | undefined,
	less_than_equal?: ModelTypes["DateTime"] | undefined,
	less_than?: ModelTypes["DateTime"] | undefined,
	like?: ModelTypes["DateTime"] | undefined,
	exists?: boolean | undefined
};
	["District_id_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["District_where_and"]: {
	name?: ModelTypes["District_name_operator"] | undefined,
	level?: ModelTypes["District_level_operator"] | undefined,
	children?: ModelTypes["District_children_operator"] | undefined,
	value?: ModelTypes["District_value_operator"] | undefined,
	lat?: ModelTypes["District_lat_operator"] | undefined,
	long?: ModelTypes["District_long_operator"] | undefined,
	radius?: ModelTypes["District_radius_operator"] | undefined,
	region?: ModelTypes["District_region_operator"] | undefined,
	updatedAt?: ModelTypes["District_updatedAt_operator"] | undefined,
	createdAt?: ModelTypes["District_createdAt_operator"] | undefined,
	id?: ModelTypes["District_id_operator"] | undefined,
	AND?: Array<ModelTypes["District_where_and"] | undefined> | undefined,
	OR?: Array<ModelTypes["District_where_or"] | undefined> | undefined
};
	["District_where_or"]: {
	name?: ModelTypes["District_name_operator"] | undefined,
	level?: ModelTypes["District_level_operator"] | undefined,
	children?: ModelTypes["District_children_operator"] | undefined,
	value?: ModelTypes["District_value_operator"] | undefined,
	lat?: ModelTypes["District_lat_operator"] | undefined,
	long?: ModelTypes["District_long_operator"] | undefined,
	radius?: ModelTypes["District_radius_operator"] | undefined,
	region?: ModelTypes["District_region_operator"] | undefined,
	updatedAt?: ModelTypes["District_updatedAt_operator"] | undefined,
	createdAt?: ModelTypes["District_createdAt_operator"] | undefined,
	id?: ModelTypes["District_id_operator"] | undefined,
	AND?: Array<ModelTypes["District_where_and"] | undefined> | undefined,
	OR?: Array<ModelTypes["District_where_or"] | undefined> | undefined
};
	["countDistricts"]: {
		totalDocs?: number | undefined
};
	["districtsDocAccess"]: {
		fields?: ModelTypes["DistrictsDocAccessFields"] | undefined,
	create?: ModelTypes["DistrictsCreateDocAccess"] | undefined,
	read?: ModelTypes["DistrictsReadDocAccess"] | undefined,
	update?: ModelTypes["DistrictsUpdateDocAccess"] | undefined,
	delete?: ModelTypes["DistrictsDeleteDocAccess"] | undefined
};
	["DistrictsDocAccessFields"]: {
		name?: ModelTypes["DistrictsDocAccessFields_name"] | undefined,
	level?: ModelTypes["DistrictsDocAccessFields_level"] | undefined,
	children?: ModelTypes["DistrictsDocAccessFields_children"] | undefined,
	value?: ModelTypes["DistrictsDocAccessFields_value"] | undefined,
	lat?: ModelTypes["DistrictsDocAccessFields_lat"] | undefined,
	long?: ModelTypes["DistrictsDocAccessFields_long"] | undefined,
	radius?: ModelTypes["DistrictsDocAccessFields_radius"] | undefined,
	region?: ModelTypes["DistrictsDocAccessFields_region"] | undefined,
	updatedAt?: ModelTypes["DistrictsDocAccessFields_updatedAt"] | undefined,
	createdAt?: ModelTypes["DistrictsDocAccessFields_createdAt"] | undefined
};
	["DistrictsDocAccessFields_name"]: {
		create?: ModelTypes["DistrictsDocAccessFields_name_Create"] | undefined,
	read?: ModelTypes["DistrictsDocAccessFields_name_Read"] | undefined,
	update?: ModelTypes["DistrictsDocAccessFields_name_Update"] | undefined,
	delete?: ModelTypes["DistrictsDocAccessFields_name_Delete"] | undefined
};
	["DistrictsDocAccessFields_name_Create"]: {
		permission: boolean
};
	["DistrictsDocAccessFields_name_Read"]: {
		permission: boolean
};
	["DistrictsDocAccessFields_name_Update"]: {
		permission: boolean
};
	["DistrictsDocAccessFields_name_Delete"]: {
		permission: boolean
};
	["DistrictsDocAccessFields_level"]: {
		create?: ModelTypes["DistrictsDocAccessFields_level_Create"] | undefined,
	read?: ModelTypes["DistrictsDocAccessFields_level_Read"] | undefined,
	update?: ModelTypes["DistrictsDocAccessFields_level_Update"] | undefined,
	delete?: ModelTypes["DistrictsDocAccessFields_level_Delete"] | undefined
};
	["DistrictsDocAccessFields_level_Create"]: {
		permission: boolean
};
	["DistrictsDocAccessFields_level_Read"]: {
		permission: boolean
};
	["DistrictsDocAccessFields_level_Update"]: {
		permission: boolean
};
	["DistrictsDocAccessFields_level_Delete"]: {
		permission: boolean
};
	["DistrictsDocAccessFields_children"]: {
		create?: ModelTypes["DistrictsDocAccessFields_children_Create"] | undefined,
	read?: ModelTypes["DistrictsDocAccessFields_children_Read"] | undefined,
	update?: ModelTypes["DistrictsDocAccessFields_children_Update"] | undefined,
	delete?: ModelTypes["DistrictsDocAccessFields_children_Delete"] | undefined
};
	["DistrictsDocAccessFields_children_Create"]: {
		permission: boolean
};
	["DistrictsDocAccessFields_children_Read"]: {
		permission: boolean
};
	["DistrictsDocAccessFields_children_Update"]: {
		permission: boolean
};
	["DistrictsDocAccessFields_children_Delete"]: {
		permission: boolean
};
	["DistrictsDocAccessFields_value"]: {
		create?: ModelTypes["DistrictsDocAccessFields_value_Create"] | undefined,
	read?: ModelTypes["DistrictsDocAccessFields_value_Read"] | undefined,
	update?: ModelTypes["DistrictsDocAccessFields_value_Update"] | undefined,
	delete?: ModelTypes["DistrictsDocAccessFields_value_Delete"] | undefined
};
	["DistrictsDocAccessFields_value_Create"]: {
		permission: boolean
};
	["DistrictsDocAccessFields_value_Read"]: {
		permission: boolean
};
	["DistrictsDocAccessFields_value_Update"]: {
		permission: boolean
};
	["DistrictsDocAccessFields_value_Delete"]: {
		permission: boolean
};
	["DistrictsDocAccessFields_lat"]: {
		create?: ModelTypes["DistrictsDocAccessFields_lat_Create"] | undefined,
	read?: ModelTypes["DistrictsDocAccessFields_lat_Read"] | undefined,
	update?: ModelTypes["DistrictsDocAccessFields_lat_Update"] | undefined,
	delete?: ModelTypes["DistrictsDocAccessFields_lat_Delete"] | undefined
};
	["DistrictsDocAccessFields_lat_Create"]: {
		permission: boolean
};
	["DistrictsDocAccessFields_lat_Read"]: {
		permission: boolean
};
	["DistrictsDocAccessFields_lat_Update"]: {
		permission: boolean
};
	["DistrictsDocAccessFields_lat_Delete"]: {
		permission: boolean
};
	["DistrictsDocAccessFields_long"]: {
		create?: ModelTypes["DistrictsDocAccessFields_long_Create"] | undefined,
	read?: ModelTypes["DistrictsDocAccessFields_long_Read"] | undefined,
	update?: ModelTypes["DistrictsDocAccessFields_long_Update"] | undefined,
	delete?: ModelTypes["DistrictsDocAccessFields_long_Delete"] | undefined
};
	["DistrictsDocAccessFields_long_Create"]: {
		permission: boolean
};
	["DistrictsDocAccessFields_long_Read"]: {
		permission: boolean
};
	["DistrictsDocAccessFields_long_Update"]: {
		permission: boolean
};
	["DistrictsDocAccessFields_long_Delete"]: {
		permission: boolean
};
	["DistrictsDocAccessFields_radius"]: {
		create?: ModelTypes["DistrictsDocAccessFields_radius_Create"] | undefined,
	read?: ModelTypes["DistrictsDocAccessFields_radius_Read"] | undefined,
	update?: ModelTypes["DistrictsDocAccessFields_radius_Update"] | undefined,
	delete?: ModelTypes["DistrictsDocAccessFields_radius_Delete"] | undefined
};
	["DistrictsDocAccessFields_radius_Create"]: {
		permission: boolean
};
	["DistrictsDocAccessFields_radius_Read"]: {
		permission: boolean
};
	["DistrictsDocAccessFields_radius_Update"]: {
		permission: boolean
};
	["DistrictsDocAccessFields_radius_Delete"]: {
		permission: boolean
};
	["DistrictsDocAccessFields_region"]: {
		create?: ModelTypes["DistrictsDocAccessFields_region_Create"] | undefined,
	read?: ModelTypes["DistrictsDocAccessFields_region_Read"] | undefined,
	update?: ModelTypes["DistrictsDocAccessFields_region_Update"] | undefined,
	delete?: ModelTypes["DistrictsDocAccessFields_region_Delete"] | undefined
};
	["DistrictsDocAccessFields_region_Create"]: {
		permission: boolean
};
	["DistrictsDocAccessFields_region_Read"]: {
		permission: boolean
};
	["DistrictsDocAccessFields_region_Update"]: {
		permission: boolean
};
	["DistrictsDocAccessFields_region_Delete"]: {
		permission: boolean
};
	["DistrictsDocAccessFields_updatedAt"]: {
		create?: ModelTypes["DistrictsDocAccessFields_updatedAt_Create"] | undefined,
	read?: ModelTypes["DistrictsDocAccessFields_updatedAt_Read"] | undefined,
	update?: ModelTypes["DistrictsDocAccessFields_updatedAt_Update"] | undefined,
	delete?: ModelTypes["DistrictsDocAccessFields_updatedAt_Delete"] | undefined
};
	["DistrictsDocAccessFields_updatedAt_Create"]: {
		permission: boolean
};
	["DistrictsDocAccessFields_updatedAt_Read"]: {
		permission: boolean
};
	["DistrictsDocAccessFields_updatedAt_Update"]: {
		permission: boolean
};
	["DistrictsDocAccessFields_updatedAt_Delete"]: {
		permission: boolean
};
	["DistrictsDocAccessFields_createdAt"]: {
		create?: ModelTypes["DistrictsDocAccessFields_createdAt_Create"] | undefined,
	read?: ModelTypes["DistrictsDocAccessFields_createdAt_Read"] | undefined,
	update?: ModelTypes["DistrictsDocAccessFields_createdAt_Update"] | undefined,
	delete?: ModelTypes["DistrictsDocAccessFields_createdAt_Delete"] | undefined
};
	["DistrictsDocAccessFields_createdAt_Create"]: {
		permission: boolean
};
	["DistrictsDocAccessFields_createdAt_Read"]: {
		permission: boolean
};
	["DistrictsDocAccessFields_createdAt_Update"]: {
		permission: boolean
};
	["DistrictsDocAccessFields_createdAt_Delete"]: {
		permission: boolean
};
	["DistrictsCreateDocAccess"]: {
		permission: boolean,
	where?: ModelTypes["JSONObject"] | undefined
};
	["DistrictsReadDocAccess"]: {
		permission: boolean,
	where?: ModelTypes["JSONObject"] | undefined
};
	["DistrictsUpdateDocAccess"]: {
		permission: boolean,
	where?: ModelTypes["JSONObject"] | undefined
};
	["DistrictsDeleteDocAccess"]: {
		permission: boolean,
	where?: ModelTypes["JSONObject"] | undefined
};
	["Audiences"]: {
		docs?: Array<ModelTypes["Audience"] | undefined> | undefined,
	hasNextPage?: boolean | undefined,
	hasPrevPage?: boolean | undefined,
	limit?: number | undefined,
	nextPage?: number | undefined,
	offset?: number | undefined,
	page?: number | undefined,
	pagingCounter?: number | undefined,
	prevPage?: number | undefined,
	totalDocs?: number | undefined,
	totalPages?: number | undefined
};
	["Audience_where"]: {
	name?: ModelTypes["Audience_name_operator"] | undefined,
	value?: ModelTypes["Audience_value_operator"] | undefined,
	icon?: ModelTypes["Audience_icon_operator"] | undefined,
	region?: ModelTypes["Audience_region_operator"] | undefined,
	updatedAt?: ModelTypes["Audience_updatedAt_operator"] | undefined,
	createdAt?: ModelTypes["Audience_createdAt_operator"] | undefined,
	id?: ModelTypes["Audience_id_operator"] | undefined,
	AND?: Array<ModelTypes["Audience_where_and"] | undefined> | undefined,
	OR?: Array<ModelTypes["Audience_where_or"] | undefined> | undefined
};
	["Audience_name_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined
};
	["Audience_value_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined
};
	["Audience_icon_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	exists?: boolean | undefined
};
	["Audience_region_operator"]: {
	equals?: ModelTypes["Audience_region_Input"] | undefined,
	not_equals?: ModelTypes["Audience_region_Input"] | undefined,
	in?: Array<ModelTypes["Audience_region_Input"] | undefined> | undefined,
	not_in?: Array<ModelTypes["Audience_region_Input"] | undefined> | undefined,
	all?: Array<ModelTypes["Audience_region_Input"] | undefined> | undefined,
	exists?: boolean | undefined
};
	["Audience_region_Input"]:Audience_region_Input;
	["Audience_updatedAt_operator"]: {
	equals?: ModelTypes["DateTime"] | undefined,
	not_equals?: ModelTypes["DateTime"] | undefined,
	greater_than_equal?: ModelTypes["DateTime"] | undefined,
	greater_than?: ModelTypes["DateTime"] | undefined,
	less_than_equal?: ModelTypes["DateTime"] | undefined,
	less_than?: ModelTypes["DateTime"] | undefined,
	like?: ModelTypes["DateTime"] | undefined,
	exists?: boolean | undefined
};
	["Audience_createdAt_operator"]: {
	equals?: ModelTypes["DateTime"] | undefined,
	not_equals?: ModelTypes["DateTime"] | undefined,
	greater_than_equal?: ModelTypes["DateTime"] | undefined,
	greater_than?: ModelTypes["DateTime"] | undefined,
	less_than_equal?: ModelTypes["DateTime"] | undefined,
	less_than?: ModelTypes["DateTime"] | undefined,
	like?: ModelTypes["DateTime"] | undefined,
	exists?: boolean | undefined
};
	["Audience_id_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Audience_where_and"]: {
	name?: ModelTypes["Audience_name_operator"] | undefined,
	value?: ModelTypes["Audience_value_operator"] | undefined,
	icon?: ModelTypes["Audience_icon_operator"] | undefined,
	region?: ModelTypes["Audience_region_operator"] | undefined,
	updatedAt?: ModelTypes["Audience_updatedAt_operator"] | undefined,
	createdAt?: ModelTypes["Audience_createdAt_operator"] | undefined,
	id?: ModelTypes["Audience_id_operator"] | undefined,
	AND?: Array<ModelTypes["Audience_where_and"] | undefined> | undefined,
	OR?: Array<ModelTypes["Audience_where_or"] | undefined> | undefined
};
	["Audience_where_or"]: {
	name?: ModelTypes["Audience_name_operator"] | undefined,
	value?: ModelTypes["Audience_value_operator"] | undefined,
	icon?: ModelTypes["Audience_icon_operator"] | undefined,
	region?: ModelTypes["Audience_region_operator"] | undefined,
	updatedAt?: ModelTypes["Audience_updatedAt_operator"] | undefined,
	createdAt?: ModelTypes["Audience_createdAt_operator"] | undefined,
	id?: ModelTypes["Audience_id_operator"] | undefined,
	AND?: Array<ModelTypes["Audience_where_and"] | undefined> | undefined,
	OR?: Array<ModelTypes["Audience_where_or"] | undefined> | undefined
};
	["countAudiences"]: {
		totalDocs?: number | undefined
};
	["audienceDocAccess"]: {
		fields?: ModelTypes["AudienceDocAccessFields"] | undefined,
	create?: ModelTypes["AudienceCreateDocAccess"] | undefined,
	read?: ModelTypes["AudienceReadDocAccess"] | undefined,
	update?: ModelTypes["AudienceUpdateDocAccess"] | undefined,
	delete?: ModelTypes["AudienceDeleteDocAccess"] | undefined
};
	["AudienceDocAccessFields"]: {
		name?: ModelTypes["AudienceDocAccessFields_name"] | undefined,
	value?: ModelTypes["AudienceDocAccessFields_value"] | undefined,
	icon?: ModelTypes["AudienceDocAccessFields_icon"] | undefined,
	region?: ModelTypes["AudienceDocAccessFields_region"] | undefined,
	updatedAt?: ModelTypes["AudienceDocAccessFields_updatedAt"] | undefined,
	createdAt?: ModelTypes["AudienceDocAccessFields_createdAt"] | undefined
};
	["AudienceDocAccessFields_name"]: {
		create?: ModelTypes["AudienceDocAccessFields_name_Create"] | undefined,
	read?: ModelTypes["AudienceDocAccessFields_name_Read"] | undefined,
	update?: ModelTypes["AudienceDocAccessFields_name_Update"] | undefined,
	delete?: ModelTypes["AudienceDocAccessFields_name_Delete"] | undefined
};
	["AudienceDocAccessFields_name_Create"]: {
		permission: boolean
};
	["AudienceDocAccessFields_name_Read"]: {
		permission: boolean
};
	["AudienceDocAccessFields_name_Update"]: {
		permission: boolean
};
	["AudienceDocAccessFields_name_Delete"]: {
		permission: boolean
};
	["AudienceDocAccessFields_value"]: {
		create?: ModelTypes["AudienceDocAccessFields_value_Create"] | undefined,
	read?: ModelTypes["AudienceDocAccessFields_value_Read"] | undefined,
	update?: ModelTypes["AudienceDocAccessFields_value_Update"] | undefined,
	delete?: ModelTypes["AudienceDocAccessFields_value_Delete"] | undefined
};
	["AudienceDocAccessFields_value_Create"]: {
		permission: boolean
};
	["AudienceDocAccessFields_value_Read"]: {
		permission: boolean
};
	["AudienceDocAccessFields_value_Update"]: {
		permission: boolean
};
	["AudienceDocAccessFields_value_Delete"]: {
		permission: boolean
};
	["AudienceDocAccessFields_icon"]: {
		create?: ModelTypes["AudienceDocAccessFields_icon_Create"] | undefined,
	read?: ModelTypes["AudienceDocAccessFields_icon_Read"] | undefined,
	update?: ModelTypes["AudienceDocAccessFields_icon_Update"] | undefined,
	delete?: ModelTypes["AudienceDocAccessFields_icon_Delete"] | undefined
};
	["AudienceDocAccessFields_icon_Create"]: {
		permission: boolean
};
	["AudienceDocAccessFields_icon_Read"]: {
		permission: boolean
};
	["AudienceDocAccessFields_icon_Update"]: {
		permission: boolean
};
	["AudienceDocAccessFields_icon_Delete"]: {
		permission: boolean
};
	["AudienceDocAccessFields_region"]: {
		create?: ModelTypes["AudienceDocAccessFields_region_Create"] | undefined,
	read?: ModelTypes["AudienceDocAccessFields_region_Read"] | undefined,
	update?: ModelTypes["AudienceDocAccessFields_region_Update"] | undefined,
	delete?: ModelTypes["AudienceDocAccessFields_region_Delete"] | undefined
};
	["AudienceDocAccessFields_region_Create"]: {
		permission: boolean
};
	["AudienceDocAccessFields_region_Read"]: {
		permission: boolean
};
	["AudienceDocAccessFields_region_Update"]: {
		permission: boolean
};
	["AudienceDocAccessFields_region_Delete"]: {
		permission: boolean
};
	["AudienceDocAccessFields_updatedAt"]: {
		create?: ModelTypes["AudienceDocAccessFields_updatedAt_Create"] | undefined,
	read?: ModelTypes["AudienceDocAccessFields_updatedAt_Read"] | undefined,
	update?: ModelTypes["AudienceDocAccessFields_updatedAt_Update"] | undefined,
	delete?: ModelTypes["AudienceDocAccessFields_updatedAt_Delete"] | undefined
};
	["AudienceDocAccessFields_updatedAt_Create"]: {
		permission: boolean
};
	["AudienceDocAccessFields_updatedAt_Read"]: {
		permission: boolean
};
	["AudienceDocAccessFields_updatedAt_Update"]: {
		permission: boolean
};
	["AudienceDocAccessFields_updatedAt_Delete"]: {
		permission: boolean
};
	["AudienceDocAccessFields_createdAt"]: {
		create?: ModelTypes["AudienceDocAccessFields_createdAt_Create"] | undefined,
	read?: ModelTypes["AudienceDocAccessFields_createdAt_Read"] | undefined,
	update?: ModelTypes["AudienceDocAccessFields_createdAt_Update"] | undefined,
	delete?: ModelTypes["AudienceDocAccessFields_createdAt_Delete"] | undefined
};
	["AudienceDocAccessFields_createdAt_Create"]: {
		permission: boolean
};
	["AudienceDocAccessFields_createdAt_Read"]: {
		permission: boolean
};
	["AudienceDocAccessFields_createdAt_Update"]: {
		permission: boolean
};
	["AudienceDocAccessFields_createdAt_Delete"]: {
		permission: boolean
};
	["AudienceCreateDocAccess"]: {
		permission: boolean,
	where?: ModelTypes["JSONObject"] | undefined
};
	["AudienceReadDocAccess"]: {
		permission: boolean,
	where?: ModelTypes["JSONObject"] | undefined
};
	["AudienceUpdateDocAccess"]: {
		permission: boolean,
	where?: ModelTypes["JSONObject"] | undefined
};
	["AudienceDeleteDocAccess"]: {
		permission: boolean,
	where?: ModelTypes["JSONObject"] | undefined
};
	["EventCategories"]: {
		docs?: Array<ModelTypes["EventCategory"] | undefined> | undefined,
	hasNextPage?: boolean | undefined,
	hasPrevPage?: boolean | undefined,
	limit?: number | undefined,
	nextPage?: number | undefined,
	offset?: number | undefined,
	page?: number | undefined,
	pagingCounter?: number | undefined,
	prevPage?: number | undefined,
	totalDocs?: number | undefined,
	totalPages?: number | undefined
};
	["EventCategory_where"]: {
	name?: ModelTypes["EventCategory_name_operator"] | undefined,
	value?: ModelTypes["EventCategory_value_operator"] | undefined,
	tags?: ModelTypes["EventCategory_tags_operator"] | undefined,
	icon?: ModelTypes["EventCategory_icon_operator"] | undefined,
	region?: ModelTypes["EventCategory_region_operator"] | undefined,
	updatedAt?: ModelTypes["EventCategory_updatedAt_operator"] | undefined,
	createdAt?: ModelTypes["EventCategory_createdAt_operator"] | undefined,
	id?: ModelTypes["EventCategory_id_operator"] | undefined,
	AND?: Array<ModelTypes["EventCategory_where_and"] | undefined> | undefined,
	OR?: Array<ModelTypes["EventCategory_where_or"] | undefined> | undefined
};
	["EventCategory_name_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined
};
	["EventCategory_value_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined
};
	["EventCategory_tags_operator"]: {
	equals?: ModelTypes["JSON"] | undefined,
	not_equals?: ModelTypes["JSON"] | undefined,
	in?: Array<ModelTypes["JSON"] | undefined> | undefined,
	not_in?: Array<ModelTypes["JSON"] | undefined> | undefined,
	all?: Array<ModelTypes["JSON"] | undefined> | undefined,
	exists?: boolean | undefined
};
	["EventCategory_icon_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	exists?: boolean | undefined
};
	["EventCategory_region_operator"]: {
	equals?: ModelTypes["EventCategory_region_Input"] | undefined,
	not_equals?: ModelTypes["EventCategory_region_Input"] | undefined,
	in?: Array<ModelTypes["EventCategory_region_Input"] | undefined> | undefined,
	not_in?: Array<ModelTypes["EventCategory_region_Input"] | undefined> | undefined,
	all?: Array<ModelTypes["EventCategory_region_Input"] | undefined> | undefined,
	exists?: boolean | undefined
};
	["EventCategory_region_Input"]:EventCategory_region_Input;
	["EventCategory_updatedAt_operator"]: {
	equals?: ModelTypes["DateTime"] | undefined,
	not_equals?: ModelTypes["DateTime"] | undefined,
	greater_than_equal?: ModelTypes["DateTime"] | undefined,
	greater_than?: ModelTypes["DateTime"] | undefined,
	less_than_equal?: ModelTypes["DateTime"] | undefined,
	less_than?: ModelTypes["DateTime"] | undefined,
	like?: ModelTypes["DateTime"] | undefined,
	exists?: boolean | undefined
};
	["EventCategory_createdAt_operator"]: {
	equals?: ModelTypes["DateTime"] | undefined,
	not_equals?: ModelTypes["DateTime"] | undefined,
	greater_than_equal?: ModelTypes["DateTime"] | undefined,
	greater_than?: ModelTypes["DateTime"] | undefined,
	less_than_equal?: ModelTypes["DateTime"] | undefined,
	less_than?: ModelTypes["DateTime"] | undefined,
	like?: ModelTypes["DateTime"] | undefined,
	exists?: boolean | undefined
};
	["EventCategory_id_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["EventCategory_where_and"]: {
	name?: ModelTypes["EventCategory_name_operator"] | undefined,
	value?: ModelTypes["EventCategory_value_operator"] | undefined,
	tags?: ModelTypes["EventCategory_tags_operator"] | undefined,
	icon?: ModelTypes["EventCategory_icon_operator"] | undefined,
	region?: ModelTypes["EventCategory_region_operator"] | undefined,
	updatedAt?: ModelTypes["EventCategory_updatedAt_operator"] | undefined,
	createdAt?: ModelTypes["EventCategory_createdAt_operator"] | undefined,
	id?: ModelTypes["EventCategory_id_operator"] | undefined,
	AND?: Array<ModelTypes["EventCategory_where_and"] | undefined> | undefined,
	OR?: Array<ModelTypes["EventCategory_where_or"] | undefined> | undefined
};
	["EventCategory_where_or"]: {
	name?: ModelTypes["EventCategory_name_operator"] | undefined,
	value?: ModelTypes["EventCategory_value_operator"] | undefined,
	tags?: ModelTypes["EventCategory_tags_operator"] | undefined,
	icon?: ModelTypes["EventCategory_icon_operator"] | undefined,
	region?: ModelTypes["EventCategory_region_operator"] | undefined,
	updatedAt?: ModelTypes["EventCategory_updatedAt_operator"] | undefined,
	createdAt?: ModelTypes["EventCategory_createdAt_operator"] | undefined,
	id?: ModelTypes["EventCategory_id_operator"] | undefined,
	AND?: Array<ModelTypes["EventCategory_where_and"] | undefined> | undefined,
	OR?: Array<ModelTypes["EventCategory_where_or"] | undefined> | undefined
};
	["countEventCategories"]: {
		totalDocs?: number | undefined
};
	["categoryDocAccess"]: {
		fields?: ModelTypes["CategoryDocAccessFields"] | undefined,
	create?: ModelTypes["CategoryCreateDocAccess"] | undefined,
	read?: ModelTypes["CategoryReadDocAccess"] | undefined,
	update?: ModelTypes["CategoryUpdateDocAccess"] | undefined,
	delete?: ModelTypes["CategoryDeleteDocAccess"] | undefined
};
	["CategoryDocAccessFields"]: {
		name?: ModelTypes["CategoryDocAccessFields_name"] | undefined,
	value?: ModelTypes["CategoryDocAccessFields_value"] | undefined,
	tags?: ModelTypes["CategoryDocAccessFields_tags"] | undefined,
	icon?: ModelTypes["CategoryDocAccessFields_icon"] | undefined,
	region?: ModelTypes["CategoryDocAccessFields_region"] | undefined,
	updatedAt?: ModelTypes["CategoryDocAccessFields_updatedAt"] | undefined,
	createdAt?: ModelTypes["CategoryDocAccessFields_createdAt"] | undefined
};
	["CategoryDocAccessFields_name"]: {
		create?: ModelTypes["CategoryDocAccessFields_name_Create"] | undefined,
	read?: ModelTypes["CategoryDocAccessFields_name_Read"] | undefined,
	update?: ModelTypes["CategoryDocAccessFields_name_Update"] | undefined,
	delete?: ModelTypes["CategoryDocAccessFields_name_Delete"] | undefined
};
	["CategoryDocAccessFields_name_Create"]: {
		permission: boolean
};
	["CategoryDocAccessFields_name_Read"]: {
		permission: boolean
};
	["CategoryDocAccessFields_name_Update"]: {
		permission: boolean
};
	["CategoryDocAccessFields_name_Delete"]: {
		permission: boolean
};
	["CategoryDocAccessFields_value"]: {
		create?: ModelTypes["CategoryDocAccessFields_value_Create"] | undefined,
	read?: ModelTypes["CategoryDocAccessFields_value_Read"] | undefined,
	update?: ModelTypes["CategoryDocAccessFields_value_Update"] | undefined,
	delete?: ModelTypes["CategoryDocAccessFields_value_Delete"] | undefined
};
	["CategoryDocAccessFields_value_Create"]: {
		permission: boolean
};
	["CategoryDocAccessFields_value_Read"]: {
		permission: boolean
};
	["CategoryDocAccessFields_value_Update"]: {
		permission: boolean
};
	["CategoryDocAccessFields_value_Delete"]: {
		permission: boolean
};
	["CategoryDocAccessFields_tags"]: {
		create?: ModelTypes["CategoryDocAccessFields_tags_Create"] | undefined,
	read?: ModelTypes["CategoryDocAccessFields_tags_Read"] | undefined,
	update?: ModelTypes["CategoryDocAccessFields_tags_Update"] | undefined,
	delete?: ModelTypes["CategoryDocAccessFields_tags_Delete"] | undefined
};
	["CategoryDocAccessFields_tags_Create"]: {
		permission: boolean
};
	["CategoryDocAccessFields_tags_Read"]: {
		permission: boolean
};
	["CategoryDocAccessFields_tags_Update"]: {
		permission: boolean
};
	["CategoryDocAccessFields_tags_Delete"]: {
		permission: boolean
};
	["CategoryDocAccessFields_icon"]: {
		create?: ModelTypes["CategoryDocAccessFields_icon_Create"] | undefined,
	read?: ModelTypes["CategoryDocAccessFields_icon_Read"] | undefined,
	update?: ModelTypes["CategoryDocAccessFields_icon_Update"] | undefined,
	delete?: ModelTypes["CategoryDocAccessFields_icon_Delete"] | undefined
};
	["CategoryDocAccessFields_icon_Create"]: {
		permission: boolean
};
	["CategoryDocAccessFields_icon_Read"]: {
		permission: boolean
};
	["CategoryDocAccessFields_icon_Update"]: {
		permission: boolean
};
	["CategoryDocAccessFields_icon_Delete"]: {
		permission: boolean
};
	["CategoryDocAccessFields_region"]: {
		create?: ModelTypes["CategoryDocAccessFields_region_Create"] | undefined,
	read?: ModelTypes["CategoryDocAccessFields_region_Read"] | undefined,
	update?: ModelTypes["CategoryDocAccessFields_region_Update"] | undefined,
	delete?: ModelTypes["CategoryDocAccessFields_region_Delete"] | undefined
};
	["CategoryDocAccessFields_region_Create"]: {
		permission: boolean
};
	["CategoryDocAccessFields_region_Read"]: {
		permission: boolean
};
	["CategoryDocAccessFields_region_Update"]: {
		permission: boolean
};
	["CategoryDocAccessFields_region_Delete"]: {
		permission: boolean
};
	["CategoryDocAccessFields_updatedAt"]: {
		create?: ModelTypes["CategoryDocAccessFields_updatedAt_Create"] | undefined,
	read?: ModelTypes["CategoryDocAccessFields_updatedAt_Read"] | undefined,
	update?: ModelTypes["CategoryDocAccessFields_updatedAt_Update"] | undefined,
	delete?: ModelTypes["CategoryDocAccessFields_updatedAt_Delete"] | undefined
};
	["CategoryDocAccessFields_updatedAt_Create"]: {
		permission: boolean
};
	["CategoryDocAccessFields_updatedAt_Read"]: {
		permission: boolean
};
	["CategoryDocAccessFields_updatedAt_Update"]: {
		permission: boolean
};
	["CategoryDocAccessFields_updatedAt_Delete"]: {
		permission: boolean
};
	["CategoryDocAccessFields_createdAt"]: {
		create?: ModelTypes["CategoryDocAccessFields_createdAt_Create"] | undefined,
	read?: ModelTypes["CategoryDocAccessFields_createdAt_Read"] | undefined,
	update?: ModelTypes["CategoryDocAccessFields_createdAt_Update"] | undefined,
	delete?: ModelTypes["CategoryDocAccessFields_createdAt_Delete"] | undefined
};
	["CategoryDocAccessFields_createdAt_Create"]: {
		permission: boolean
};
	["CategoryDocAccessFields_createdAt_Read"]: {
		permission: boolean
};
	["CategoryDocAccessFields_createdAt_Update"]: {
		permission: boolean
};
	["CategoryDocAccessFields_createdAt_Delete"]: {
		permission: boolean
};
	["CategoryCreateDocAccess"]: {
		permission: boolean,
	where?: ModelTypes["JSONObject"] | undefined
};
	["CategoryReadDocAccess"]: {
		permission: boolean,
	where?: ModelTypes["JSONObject"] | undefined
};
	["CategoryUpdateDocAccess"]: {
		permission: boolean,
	where?: ModelTypes["JSONObject"] | undefined
};
	["CategoryDeleteDocAccess"]: {
		permission: boolean,
	where?: ModelTypes["JSONObject"] | undefined
};
	["PrivateMedia"]: {
		id?: string | undefined,
	alt?: string | undefined,
	updatedAt?: ModelTypes["DateTime"] | undefined,
	createdAt?: ModelTypes["DateTime"] | undefined,
	url?: string | undefined,
	filename?: string | undefined,
	mimeType?: string | undefined,
	filesize?: number | undefined,
	width?: number | undefined,
	height?: number | undefined,
	focalX?: number | undefined,
	focalY?: number | undefined,
	sizes?: ModelTypes["PrivateMedia_Sizes"] | undefined
};
	["PrivateMedia_Sizes"]: {
		thumbnail?: ModelTypes["PrivateMedia_Sizes_Thumbnail"] | undefined,
	portrait?: ModelTypes["PrivateMedia_Sizes_Portrait"] | undefined,
	hero?: ModelTypes["PrivateMedia_Sizes_Hero"] | undefined,
	thumbnail_jpeg?: ModelTypes["PrivateMedia_Sizes_Thumbnail_jpeg"] | undefined,
	portrait_jpeg?: ModelTypes["PrivateMedia_Sizes_Portrait_jpeg"] | undefined,
	hero_jpeg?: ModelTypes["PrivateMedia_Sizes_Hero_jpeg"] | undefined
};
	["PrivateMedia_Sizes_Thumbnail"]: {
		url?: string | undefined,
	width?: number | undefined,
	height?: number | undefined,
	mimeType?: string | undefined,
	filesize?: number | undefined,
	filename?: string | undefined
};
	["PrivateMedia_Sizes_Portrait"]: {
		url?: string | undefined,
	width?: number | undefined,
	height?: number | undefined,
	mimeType?: string | undefined,
	filesize?: number | undefined,
	filename?: string | undefined
};
	["PrivateMedia_Sizes_Hero"]: {
		url?: string | undefined,
	width?: number | undefined,
	height?: number | undefined,
	mimeType?: string | undefined,
	filesize?: number | undefined,
	filename?: string | undefined
};
	["PrivateMedia_Sizes_Thumbnail_jpeg"]: {
		url?: string | undefined,
	width?: number | undefined,
	height?: number | undefined,
	mimeType?: string | undefined,
	filesize?: number | undefined,
	filename?: string | undefined
};
	["PrivateMedia_Sizes_Portrait_jpeg"]: {
		url?: string | undefined,
	width?: number | undefined,
	height?: number | undefined,
	mimeType?: string | undefined,
	filesize?: number | undefined,
	filename?: string | undefined
};
	["PrivateMedia_Sizes_Hero_jpeg"]: {
		url?: string | undefined,
	width?: number | undefined,
	height?: number | undefined,
	mimeType?: string | undefined,
	filesize?: number | undefined,
	filename?: string | undefined
};
	["PrivateMedias"]: {
		docs?: Array<ModelTypes["PrivateMedia"] | undefined> | undefined,
	hasNextPage?: boolean | undefined,
	hasPrevPage?: boolean | undefined,
	limit?: number | undefined,
	nextPage?: number | undefined,
	offset?: number | undefined,
	page?: number | undefined,
	pagingCounter?: number | undefined,
	prevPage?: number | undefined,
	totalDocs?: number | undefined,
	totalPages?: number | undefined
};
	["PrivateMedia_where"]: {
	alt?: ModelTypes["PrivateMedia_alt_operator"] | undefined,
	updatedAt?: ModelTypes["PrivateMedia_updatedAt_operator"] | undefined,
	createdAt?: ModelTypes["PrivateMedia_createdAt_operator"] | undefined,
	url?: ModelTypes["PrivateMedia_url_operator"] | undefined,
	filename?: ModelTypes["PrivateMedia_filename_operator"] | undefined,
	mimeType?: ModelTypes["PrivateMedia_mimeType_operator"] | undefined,
	filesize?: ModelTypes["PrivateMedia_filesize_operator"] | undefined,
	width?: ModelTypes["PrivateMedia_width_operator"] | undefined,
	height?: ModelTypes["PrivateMedia_height_operator"] | undefined,
	focalX?: ModelTypes["PrivateMedia_focalX_operator"] | undefined,
	focalY?: ModelTypes["PrivateMedia_focalY_operator"] | undefined,
	sizes__thumbnail__url?: ModelTypes["PrivateMedia_sizes__thumbnail__url_operator"] | undefined,
	sizes__thumbnail__width?: ModelTypes["PrivateMedia_sizes__thumbnail__width_operator"] | undefined,
	sizes__thumbnail__height?: ModelTypes["PrivateMedia_sizes__thumbnail__height_operator"] | undefined,
	sizes__thumbnail__mimeType?: ModelTypes["PrivateMedia_sizes__thumbnail__mimeType_operator"] | undefined,
	sizes__thumbnail__filesize?: ModelTypes["PrivateMedia_sizes__thumbnail__filesize_operator"] | undefined,
	sizes__thumbnail__filename?: ModelTypes["PrivateMedia_sizes__thumbnail__filename_operator"] | undefined,
	sizes__portrait__url?: ModelTypes["PrivateMedia_sizes__portrait__url_operator"] | undefined,
	sizes__portrait__width?: ModelTypes["PrivateMedia_sizes__portrait__width_operator"] | undefined,
	sizes__portrait__height?: ModelTypes["PrivateMedia_sizes__portrait__height_operator"] | undefined,
	sizes__portrait__mimeType?: ModelTypes["PrivateMedia_sizes__portrait__mimeType_operator"] | undefined,
	sizes__portrait__filesize?: ModelTypes["PrivateMedia_sizes__portrait__filesize_operator"] | undefined,
	sizes__portrait__filename?: ModelTypes["PrivateMedia_sizes__portrait__filename_operator"] | undefined,
	sizes__hero__url?: ModelTypes["PrivateMedia_sizes__hero__url_operator"] | undefined,
	sizes__hero__width?: ModelTypes["PrivateMedia_sizes__hero__width_operator"] | undefined,
	sizes__hero__height?: ModelTypes["PrivateMedia_sizes__hero__height_operator"] | undefined,
	sizes__hero__mimeType?: ModelTypes["PrivateMedia_sizes__hero__mimeType_operator"] | undefined,
	sizes__hero__filesize?: ModelTypes["PrivateMedia_sizes__hero__filesize_operator"] | undefined,
	sizes__hero__filename?: ModelTypes["PrivateMedia_sizes__hero__filename_operator"] | undefined,
	sizes__thumbnail_jpeg__url?: ModelTypes["PrivateMedia_sizes__thumbnail_jpeg__url_operator"] | undefined,
	sizes__thumbnail_jpeg__width?: ModelTypes["PrivateMedia_sizes__thumbnail_jpeg__width_operator"] | undefined,
	sizes__thumbnail_jpeg__height?: ModelTypes["PrivateMedia_sizes__thumbnail_jpeg__height_operator"] | undefined,
	sizes__thumbnail_jpeg__mimeType?: ModelTypes["PrivateMedia_sizes__thumbnail_jpeg__mimeType_operator"] | undefined,
	sizes__thumbnail_jpeg__filesize?: ModelTypes["PrivateMedia_sizes__thumbnail_jpeg__filesize_operator"] | undefined,
	sizes__thumbnail_jpeg__filename?: ModelTypes["PrivateMedia_sizes__thumbnail_jpeg__filename_operator"] | undefined,
	sizes__portrait_jpeg__url?: ModelTypes["PrivateMedia_sizes__portrait_jpeg__url_operator"] | undefined,
	sizes__portrait_jpeg__width?: ModelTypes["PrivateMedia_sizes__portrait_jpeg__width_operator"] | undefined,
	sizes__portrait_jpeg__height?: ModelTypes["PrivateMedia_sizes__portrait_jpeg__height_operator"] | undefined,
	sizes__portrait_jpeg__mimeType?: ModelTypes["PrivateMedia_sizes__portrait_jpeg__mimeType_operator"] | undefined,
	sizes__portrait_jpeg__filesize?: ModelTypes["PrivateMedia_sizes__portrait_jpeg__filesize_operator"] | undefined,
	sizes__portrait_jpeg__filename?: ModelTypes["PrivateMedia_sizes__portrait_jpeg__filename_operator"] | undefined,
	sizes__hero_jpeg__url?: ModelTypes["PrivateMedia_sizes__hero_jpeg__url_operator"] | undefined,
	sizes__hero_jpeg__width?: ModelTypes["PrivateMedia_sizes__hero_jpeg__width_operator"] | undefined,
	sizes__hero_jpeg__height?: ModelTypes["PrivateMedia_sizes__hero_jpeg__height_operator"] | undefined,
	sizes__hero_jpeg__mimeType?: ModelTypes["PrivateMedia_sizes__hero_jpeg__mimeType_operator"] | undefined,
	sizes__hero_jpeg__filesize?: ModelTypes["PrivateMedia_sizes__hero_jpeg__filesize_operator"] | undefined,
	sizes__hero_jpeg__filename?: ModelTypes["PrivateMedia_sizes__hero_jpeg__filename_operator"] | undefined,
	id?: ModelTypes["PrivateMedia_id_operator"] | undefined,
	AND?: Array<ModelTypes["PrivateMedia_where_and"] | undefined> | undefined,
	OR?: Array<ModelTypes["PrivateMedia_where_or"] | undefined> | undefined
};
	["PrivateMedia_alt_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["PrivateMedia_updatedAt_operator"]: {
	equals?: ModelTypes["DateTime"] | undefined,
	not_equals?: ModelTypes["DateTime"] | undefined,
	greater_than_equal?: ModelTypes["DateTime"] | undefined,
	greater_than?: ModelTypes["DateTime"] | undefined,
	less_than_equal?: ModelTypes["DateTime"] | undefined,
	less_than?: ModelTypes["DateTime"] | undefined,
	like?: ModelTypes["DateTime"] | undefined,
	exists?: boolean | undefined
};
	["PrivateMedia_createdAt_operator"]: {
	equals?: ModelTypes["DateTime"] | undefined,
	not_equals?: ModelTypes["DateTime"] | undefined,
	greater_than_equal?: ModelTypes["DateTime"] | undefined,
	greater_than?: ModelTypes["DateTime"] | undefined,
	less_than_equal?: ModelTypes["DateTime"] | undefined,
	less_than?: ModelTypes["DateTime"] | undefined,
	like?: ModelTypes["DateTime"] | undefined,
	exists?: boolean | undefined
};
	["PrivateMedia_url_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["PrivateMedia_filename_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["PrivateMedia_mimeType_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["PrivateMedia_filesize_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["PrivateMedia_width_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["PrivateMedia_height_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["PrivateMedia_focalX_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["PrivateMedia_focalY_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["PrivateMedia_sizes__thumbnail__url_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["PrivateMedia_sizes__thumbnail__width_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["PrivateMedia_sizes__thumbnail__height_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["PrivateMedia_sizes__thumbnail__mimeType_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["PrivateMedia_sizes__thumbnail__filesize_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["PrivateMedia_sizes__thumbnail__filename_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["PrivateMedia_sizes__portrait__url_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["PrivateMedia_sizes__portrait__width_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["PrivateMedia_sizes__portrait__height_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["PrivateMedia_sizes__portrait__mimeType_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["PrivateMedia_sizes__portrait__filesize_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["PrivateMedia_sizes__portrait__filename_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["PrivateMedia_sizes__hero__url_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["PrivateMedia_sizes__hero__width_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["PrivateMedia_sizes__hero__height_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["PrivateMedia_sizes__hero__mimeType_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["PrivateMedia_sizes__hero__filesize_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["PrivateMedia_sizes__hero__filename_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["PrivateMedia_sizes__thumbnail_jpeg__url_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["PrivateMedia_sizes__thumbnail_jpeg__width_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["PrivateMedia_sizes__thumbnail_jpeg__height_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["PrivateMedia_sizes__thumbnail_jpeg__mimeType_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["PrivateMedia_sizes__thumbnail_jpeg__filesize_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["PrivateMedia_sizes__thumbnail_jpeg__filename_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["PrivateMedia_sizes__portrait_jpeg__url_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["PrivateMedia_sizes__portrait_jpeg__width_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["PrivateMedia_sizes__portrait_jpeg__height_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["PrivateMedia_sizes__portrait_jpeg__mimeType_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["PrivateMedia_sizes__portrait_jpeg__filesize_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["PrivateMedia_sizes__portrait_jpeg__filename_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["PrivateMedia_sizes__hero_jpeg__url_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["PrivateMedia_sizes__hero_jpeg__width_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["PrivateMedia_sizes__hero_jpeg__height_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["PrivateMedia_sizes__hero_jpeg__mimeType_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["PrivateMedia_sizes__hero_jpeg__filesize_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["PrivateMedia_sizes__hero_jpeg__filename_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["PrivateMedia_id_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["PrivateMedia_where_and"]: {
	alt?: ModelTypes["PrivateMedia_alt_operator"] | undefined,
	updatedAt?: ModelTypes["PrivateMedia_updatedAt_operator"] | undefined,
	createdAt?: ModelTypes["PrivateMedia_createdAt_operator"] | undefined,
	url?: ModelTypes["PrivateMedia_url_operator"] | undefined,
	filename?: ModelTypes["PrivateMedia_filename_operator"] | undefined,
	mimeType?: ModelTypes["PrivateMedia_mimeType_operator"] | undefined,
	filesize?: ModelTypes["PrivateMedia_filesize_operator"] | undefined,
	width?: ModelTypes["PrivateMedia_width_operator"] | undefined,
	height?: ModelTypes["PrivateMedia_height_operator"] | undefined,
	focalX?: ModelTypes["PrivateMedia_focalX_operator"] | undefined,
	focalY?: ModelTypes["PrivateMedia_focalY_operator"] | undefined,
	sizes__thumbnail__url?: ModelTypes["PrivateMedia_sizes__thumbnail__url_operator"] | undefined,
	sizes__thumbnail__width?: ModelTypes["PrivateMedia_sizes__thumbnail__width_operator"] | undefined,
	sizes__thumbnail__height?: ModelTypes["PrivateMedia_sizes__thumbnail__height_operator"] | undefined,
	sizes__thumbnail__mimeType?: ModelTypes["PrivateMedia_sizes__thumbnail__mimeType_operator"] | undefined,
	sizes__thumbnail__filesize?: ModelTypes["PrivateMedia_sizes__thumbnail__filesize_operator"] | undefined,
	sizes__thumbnail__filename?: ModelTypes["PrivateMedia_sizes__thumbnail__filename_operator"] | undefined,
	sizes__portrait__url?: ModelTypes["PrivateMedia_sizes__portrait__url_operator"] | undefined,
	sizes__portrait__width?: ModelTypes["PrivateMedia_sizes__portrait__width_operator"] | undefined,
	sizes__portrait__height?: ModelTypes["PrivateMedia_sizes__portrait__height_operator"] | undefined,
	sizes__portrait__mimeType?: ModelTypes["PrivateMedia_sizes__portrait__mimeType_operator"] | undefined,
	sizes__portrait__filesize?: ModelTypes["PrivateMedia_sizes__portrait__filesize_operator"] | undefined,
	sizes__portrait__filename?: ModelTypes["PrivateMedia_sizes__portrait__filename_operator"] | undefined,
	sizes__hero__url?: ModelTypes["PrivateMedia_sizes__hero__url_operator"] | undefined,
	sizes__hero__width?: ModelTypes["PrivateMedia_sizes__hero__width_operator"] | undefined,
	sizes__hero__height?: ModelTypes["PrivateMedia_sizes__hero__height_operator"] | undefined,
	sizes__hero__mimeType?: ModelTypes["PrivateMedia_sizes__hero__mimeType_operator"] | undefined,
	sizes__hero__filesize?: ModelTypes["PrivateMedia_sizes__hero__filesize_operator"] | undefined,
	sizes__hero__filename?: ModelTypes["PrivateMedia_sizes__hero__filename_operator"] | undefined,
	sizes__thumbnail_jpeg__url?: ModelTypes["PrivateMedia_sizes__thumbnail_jpeg__url_operator"] | undefined,
	sizes__thumbnail_jpeg__width?: ModelTypes["PrivateMedia_sizes__thumbnail_jpeg__width_operator"] | undefined,
	sizes__thumbnail_jpeg__height?: ModelTypes["PrivateMedia_sizes__thumbnail_jpeg__height_operator"] | undefined,
	sizes__thumbnail_jpeg__mimeType?: ModelTypes["PrivateMedia_sizes__thumbnail_jpeg__mimeType_operator"] | undefined,
	sizes__thumbnail_jpeg__filesize?: ModelTypes["PrivateMedia_sizes__thumbnail_jpeg__filesize_operator"] | undefined,
	sizes__thumbnail_jpeg__filename?: ModelTypes["PrivateMedia_sizes__thumbnail_jpeg__filename_operator"] | undefined,
	sizes__portrait_jpeg__url?: ModelTypes["PrivateMedia_sizes__portrait_jpeg__url_operator"] | undefined,
	sizes__portrait_jpeg__width?: ModelTypes["PrivateMedia_sizes__portrait_jpeg__width_operator"] | undefined,
	sizes__portrait_jpeg__height?: ModelTypes["PrivateMedia_sizes__portrait_jpeg__height_operator"] | undefined,
	sizes__portrait_jpeg__mimeType?: ModelTypes["PrivateMedia_sizes__portrait_jpeg__mimeType_operator"] | undefined,
	sizes__portrait_jpeg__filesize?: ModelTypes["PrivateMedia_sizes__portrait_jpeg__filesize_operator"] | undefined,
	sizes__portrait_jpeg__filename?: ModelTypes["PrivateMedia_sizes__portrait_jpeg__filename_operator"] | undefined,
	sizes__hero_jpeg__url?: ModelTypes["PrivateMedia_sizes__hero_jpeg__url_operator"] | undefined,
	sizes__hero_jpeg__width?: ModelTypes["PrivateMedia_sizes__hero_jpeg__width_operator"] | undefined,
	sizes__hero_jpeg__height?: ModelTypes["PrivateMedia_sizes__hero_jpeg__height_operator"] | undefined,
	sizes__hero_jpeg__mimeType?: ModelTypes["PrivateMedia_sizes__hero_jpeg__mimeType_operator"] | undefined,
	sizes__hero_jpeg__filesize?: ModelTypes["PrivateMedia_sizes__hero_jpeg__filesize_operator"] | undefined,
	sizes__hero_jpeg__filename?: ModelTypes["PrivateMedia_sizes__hero_jpeg__filename_operator"] | undefined,
	id?: ModelTypes["PrivateMedia_id_operator"] | undefined,
	AND?: Array<ModelTypes["PrivateMedia_where_and"] | undefined> | undefined,
	OR?: Array<ModelTypes["PrivateMedia_where_or"] | undefined> | undefined
};
	["PrivateMedia_where_or"]: {
	alt?: ModelTypes["PrivateMedia_alt_operator"] | undefined,
	updatedAt?: ModelTypes["PrivateMedia_updatedAt_operator"] | undefined,
	createdAt?: ModelTypes["PrivateMedia_createdAt_operator"] | undefined,
	url?: ModelTypes["PrivateMedia_url_operator"] | undefined,
	filename?: ModelTypes["PrivateMedia_filename_operator"] | undefined,
	mimeType?: ModelTypes["PrivateMedia_mimeType_operator"] | undefined,
	filesize?: ModelTypes["PrivateMedia_filesize_operator"] | undefined,
	width?: ModelTypes["PrivateMedia_width_operator"] | undefined,
	height?: ModelTypes["PrivateMedia_height_operator"] | undefined,
	focalX?: ModelTypes["PrivateMedia_focalX_operator"] | undefined,
	focalY?: ModelTypes["PrivateMedia_focalY_operator"] | undefined,
	sizes__thumbnail__url?: ModelTypes["PrivateMedia_sizes__thumbnail__url_operator"] | undefined,
	sizes__thumbnail__width?: ModelTypes["PrivateMedia_sizes__thumbnail__width_operator"] | undefined,
	sizes__thumbnail__height?: ModelTypes["PrivateMedia_sizes__thumbnail__height_operator"] | undefined,
	sizes__thumbnail__mimeType?: ModelTypes["PrivateMedia_sizes__thumbnail__mimeType_operator"] | undefined,
	sizes__thumbnail__filesize?: ModelTypes["PrivateMedia_sizes__thumbnail__filesize_operator"] | undefined,
	sizes__thumbnail__filename?: ModelTypes["PrivateMedia_sizes__thumbnail__filename_operator"] | undefined,
	sizes__portrait__url?: ModelTypes["PrivateMedia_sizes__portrait__url_operator"] | undefined,
	sizes__portrait__width?: ModelTypes["PrivateMedia_sizes__portrait__width_operator"] | undefined,
	sizes__portrait__height?: ModelTypes["PrivateMedia_sizes__portrait__height_operator"] | undefined,
	sizes__portrait__mimeType?: ModelTypes["PrivateMedia_sizes__portrait__mimeType_operator"] | undefined,
	sizes__portrait__filesize?: ModelTypes["PrivateMedia_sizes__portrait__filesize_operator"] | undefined,
	sizes__portrait__filename?: ModelTypes["PrivateMedia_sizes__portrait__filename_operator"] | undefined,
	sizes__hero__url?: ModelTypes["PrivateMedia_sizes__hero__url_operator"] | undefined,
	sizes__hero__width?: ModelTypes["PrivateMedia_sizes__hero__width_operator"] | undefined,
	sizes__hero__height?: ModelTypes["PrivateMedia_sizes__hero__height_operator"] | undefined,
	sizes__hero__mimeType?: ModelTypes["PrivateMedia_sizes__hero__mimeType_operator"] | undefined,
	sizes__hero__filesize?: ModelTypes["PrivateMedia_sizes__hero__filesize_operator"] | undefined,
	sizes__hero__filename?: ModelTypes["PrivateMedia_sizes__hero__filename_operator"] | undefined,
	sizes__thumbnail_jpeg__url?: ModelTypes["PrivateMedia_sizes__thumbnail_jpeg__url_operator"] | undefined,
	sizes__thumbnail_jpeg__width?: ModelTypes["PrivateMedia_sizes__thumbnail_jpeg__width_operator"] | undefined,
	sizes__thumbnail_jpeg__height?: ModelTypes["PrivateMedia_sizes__thumbnail_jpeg__height_operator"] | undefined,
	sizes__thumbnail_jpeg__mimeType?: ModelTypes["PrivateMedia_sizes__thumbnail_jpeg__mimeType_operator"] | undefined,
	sizes__thumbnail_jpeg__filesize?: ModelTypes["PrivateMedia_sizes__thumbnail_jpeg__filesize_operator"] | undefined,
	sizes__thumbnail_jpeg__filename?: ModelTypes["PrivateMedia_sizes__thumbnail_jpeg__filename_operator"] | undefined,
	sizes__portrait_jpeg__url?: ModelTypes["PrivateMedia_sizes__portrait_jpeg__url_operator"] | undefined,
	sizes__portrait_jpeg__width?: ModelTypes["PrivateMedia_sizes__portrait_jpeg__width_operator"] | undefined,
	sizes__portrait_jpeg__height?: ModelTypes["PrivateMedia_sizes__portrait_jpeg__height_operator"] | undefined,
	sizes__portrait_jpeg__mimeType?: ModelTypes["PrivateMedia_sizes__portrait_jpeg__mimeType_operator"] | undefined,
	sizes__portrait_jpeg__filesize?: ModelTypes["PrivateMedia_sizes__portrait_jpeg__filesize_operator"] | undefined,
	sizes__portrait_jpeg__filename?: ModelTypes["PrivateMedia_sizes__portrait_jpeg__filename_operator"] | undefined,
	sizes__hero_jpeg__url?: ModelTypes["PrivateMedia_sizes__hero_jpeg__url_operator"] | undefined,
	sizes__hero_jpeg__width?: ModelTypes["PrivateMedia_sizes__hero_jpeg__width_operator"] | undefined,
	sizes__hero_jpeg__height?: ModelTypes["PrivateMedia_sizes__hero_jpeg__height_operator"] | undefined,
	sizes__hero_jpeg__mimeType?: ModelTypes["PrivateMedia_sizes__hero_jpeg__mimeType_operator"] | undefined,
	sizes__hero_jpeg__filesize?: ModelTypes["PrivateMedia_sizes__hero_jpeg__filesize_operator"] | undefined,
	sizes__hero_jpeg__filename?: ModelTypes["PrivateMedia_sizes__hero_jpeg__filename_operator"] | undefined,
	id?: ModelTypes["PrivateMedia_id_operator"] | undefined,
	AND?: Array<ModelTypes["PrivateMedia_where_and"] | undefined> | undefined,
	OR?: Array<ModelTypes["PrivateMedia_where_or"] | undefined> | undefined
};
	["countPrivateMedias"]: {
		totalDocs?: number | undefined
};
	["private_mediaDocAccess"]: {
		fields?: ModelTypes["PrivateMediaDocAccessFields"] | undefined,
	create?: ModelTypes["PrivateMediaCreateDocAccess"] | undefined,
	read?: ModelTypes["PrivateMediaReadDocAccess"] | undefined,
	update?: ModelTypes["PrivateMediaUpdateDocAccess"] | undefined,
	delete?: ModelTypes["PrivateMediaDeleteDocAccess"] | undefined
};
	["PrivateMediaDocAccessFields"]: {
		alt?: ModelTypes["PrivateMediaDocAccessFields_alt"] | undefined,
	updatedAt?: ModelTypes["PrivateMediaDocAccessFields_updatedAt"] | undefined,
	createdAt?: ModelTypes["PrivateMediaDocAccessFields_createdAt"] | undefined,
	url?: ModelTypes["PrivateMediaDocAccessFields_url"] | undefined,
	filename?: ModelTypes["PrivateMediaDocAccessFields_filename"] | undefined,
	mimeType?: ModelTypes["PrivateMediaDocAccessFields_mimeType"] | undefined,
	filesize?: ModelTypes["PrivateMediaDocAccessFields_filesize"] | undefined,
	width?: ModelTypes["PrivateMediaDocAccessFields_width"] | undefined,
	height?: ModelTypes["PrivateMediaDocAccessFields_height"] | undefined,
	focalX?: ModelTypes["PrivateMediaDocAccessFields_focalX"] | undefined,
	focalY?: ModelTypes["PrivateMediaDocAccessFields_focalY"] | undefined,
	sizes?: ModelTypes["PrivateMediaDocAccessFields_sizes"] | undefined
};
	["PrivateMediaDocAccessFields_alt"]: {
		create?: ModelTypes["PrivateMediaDocAccessFields_alt_Create"] | undefined,
	read?: ModelTypes["PrivateMediaDocAccessFields_alt_Read"] | undefined,
	update?: ModelTypes["PrivateMediaDocAccessFields_alt_Update"] | undefined,
	delete?: ModelTypes["PrivateMediaDocAccessFields_alt_Delete"] | undefined
};
	["PrivateMediaDocAccessFields_alt_Create"]: {
		permission: boolean
};
	["PrivateMediaDocAccessFields_alt_Read"]: {
		permission: boolean
};
	["PrivateMediaDocAccessFields_alt_Update"]: {
		permission: boolean
};
	["PrivateMediaDocAccessFields_alt_Delete"]: {
		permission: boolean
};
	["PrivateMediaDocAccessFields_updatedAt"]: {
		create?: ModelTypes["PrivateMediaDocAccessFields_updatedAt_Create"] | undefined,
	read?: ModelTypes["PrivateMediaDocAccessFields_updatedAt_Read"] | undefined,
	update?: ModelTypes["PrivateMediaDocAccessFields_updatedAt_Update"] | undefined,
	delete?: ModelTypes["PrivateMediaDocAccessFields_updatedAt_Delete"] | undefined
};
	["PrivateMediaDocAccessFields_updatedAt_Create"]: {
		permission: boolean
};
	["PrivateMediaDocAccessFields_updatedAt_Read"]: {
		permission: boolean
};
	["PrivateMediaDocAccessFields_updatedAt_Update"]: {
		permission: boolean
};
	["PrivateMediaDocAccessFields_updatedAt_Delete"]: {
		permission: boolean
};
	["PrivateMediaDocAccessFields_createdAt"]: {
		create?: ModelTypes["PrivateMediaDocAccessFields_createdAt_Create"] | undefined,
	read?: ModelTypes["PrivateMediaDocAccessFields_createdAt_Read"] | undefined,
	update?: ModelTypes["PrivateMediaDocAccessFields_createdAt_Update"] | undefined,
	delete?: ModelTypes["PrivateMediaDocAccessFields_createdAt_Delete"] | undefined
};
	["PrivateMediaDocAccessFields_createdAt_Create"]: {
		permission: boolean
};
	["PrivateMediaDocAccessFields_createdAt_Read"]: {
		permission: boolean
};
	["PrivateMediaDocAccessFields_createdAt_Update"]: {
		permission: boolean
};
	["PrivateMediaDocAccessFields_createdAt_Delete"]: {
		permission: boolean
};
	["PrivateMediaDocAccessFields_url"]: {
		create?: ModelTypes["PrivateMediaDocAccessFields_url_Create"] | undefined,
	read?: ModelTypes["PrivateMediaDocAccessFields_url_Read"] | undefined,
	update?: ModelTypes["PrivateMediaDocAccessFields_url_Update"] | undefined,
	delete?: ModelTypes["PrivateMediaDocAccessFields_url_Delete"] | undefined
};
	["PrivateMediaDocAccessFields_url_Create"]: {
		permission: boolean
};
	["PrivateMediaDocAccessFields_url_Read"]: {
		permission: boolean
};
	["PrivateMediaDocAccessFields_url_Update"]: {
		permission: boolean
};
	["PrivateMediaDocAccessFields_url_Delete"]: {
		permission: boolean
};
	["PrivateMediaDocAccessFields_filename"]: {
		create?: ModelTypes["PrivateMediaDocAccessFields_filename_Create"] | undefined,
	read?: ModelTypes["PrivateMediaDocAccessFields_filename_Read"] | undefined,
	update?: ModelTypes["PrivateMediaDocAccessFields_filename_Update"] | undefined,
	delete?: ModelTypes["PrivateMediaDocAccessFields_filename_Delete"] | undefined
};
	["PrivateMediaDocAccessFields_filename_Create"]: {
		permission: boolean
};
	["PrivateMediaDocAccessFields_filename_Read"]: {
		permission: boolean
};
	["PrivateMediaDocAccessFields_filename_Update"]: {
		permission: boolean
};
	["PrivateMediaDocAccessFields_filename_Delete"]: {
		permission: boolean
};
	["PrivateMediaDocAccessFields_mimeType"]: {
		create?: ModelTypes["PrivateMediaDocAccessFields_mimeType_Create"] | undefined,
	read?: ModelTypes["PrivateMediaDocAccessFields_mimeType_Read"] | undefined,
	update?: ModelTypes["PrivateMediaDocAccessFields_mimeType_Update"] | undefined,
	delete?: ModelTypes["PrivateMediaDocAccessFields_mimeType_Delete"] | undefined
};
	["PrivateMediaDocAccessFields_mimeType_Create"]: {
		permission: boolean
};
	["PrivateMediaDocAccessFields_mimeType_Read"]: {
		permission: boolean
};
	["PrivateMediaDocAccessFields_mimeType_Update"]: {
		permission: boolean
};
	["PrivateMediaDocAccessFields_mimeType_Delete"]: {
		permission: boolean
};
	["PrivateMediaDocAccessFields_filesize"]: {
		create?: ModelTypes["PrivateMediaDocAccessFields_filesize_Create"] | undefined,
	read?: ModelTypes["PrivateMediaDocAccessFields_filesize_Read"] | undefined,
	update?: ModelTypes["PrivateMediaDocAccessFields_filesize_Update"] | undefined,
	delete?: ModelTypes["PrivateMediaDocAccessFields_filesize_Delete"] | undefined
};
	["PrivateMediaDocAccessFields_filesize_Create"]: {
		permission: boolean
};
	["PrivateMediaDocAccessFields_filesize_Read"]: {
		permission: boolean
};
	["PrivateMediaDocAccessFields_filesize_Update"]: {
		permission: boolean
};
	["PrivateMediaDocAccessFields_filesize_Delete"]: {
		permission: boolean
};
	["PrivateMediaDocAccessFields_width"]: {
		create?: ModelTypes["PrivateMediaDocAccessFields_width_Create"] | undefined,
	read?: ModelTypes["PrivateMediaDocAccessFields_width_Read"] | undefined,
	update?: ModelTypes["PrivateMediaDocAccessFields_width_Update"] | undefined,
	delete?: ModelTypes["PrivateMediaDocAccessFields_width_Delete"] | undefined
};
	["PrivateMediaDocAccessFields_width_Create"]: {
		permission: boolean
};
	["PrivateMediaDocAccessFields_width_Read"]: {
		permission: boolean
};
	["PrivateMediaDocAccessFields_width_Update"]: {
		permission: boolean
};
	["PrivateMediaDocAccessFields_width_Delete"]: {
		permission: boolean
};
	["PrivateMediaDocAccessFields_height"]: {
		create?: ModelTypes["PrivateMediaDocAccessFields_height_Create"] | undefined,
	read?: ModelTypes["PrivateMediaDocAccessFields_height_Read"] | undefined,
	update?: ModelTypes["PrivateMediaDocAccessFields_height_Update"] | undefined,
	delete?: ModelTypes["PrivateMediaDocAccessFields_height_Delete"] | undefined
};
	["PrivateMediaDocAccessFields_height_Create"]: {
		permission: boolean
};
	["PrivateMediaDocAccessFields_height_Read"]: {
		permission: boolean
};
	["PrivateMediaDocAccessFields_height_Update"]: {
		permission: boolean
};
	["PrivateMediaDocAccessFields_height_Delete"]: {
		permission: boolean
};
	["PrivateMediaDocAccessFields_focalX"]: {
		create?: ModelTypes["PrivateMediaDocAccessFields_focalX_Create"] | undefined,
	read?: ModelTypes["PrivateMediaDocAccessFields_focalX_Read"] | undefined,
	update?: ModelTypes["PrivateMediaDocAccessFields_focalX_Update"] | undefined,
	delete?: ModelTypes["PrivateMediaDocAccessFields_focalX_Delete"] | undefined
};
	["PrivateMediaDocAccessFields_focalX_Create"]: {
		permission: boolean
};
	["PrivateMediaDocAccessFields_focalX_Read"]: {
		permission: boolean
};
	["PrivateMediaDocAccessFields_focalX_Update"]: {
		permission: boolean
};
	["PrivateMediaDocAccessFields_focalX_Delete"]: {
		permission: boolean
};
	["PrivateMediaDocAccessFields_focalY"]: {
		create?: ModelTypes["PrivateMediaDocAccessFields_focalY_Create"] | undefined,
	read?: ModelTypes["PrivateMediaDocAccessFields_focalY_Read"] | undefined,
	update?: ModelTypes["PrivateMediaDocAccessFields_focalY_Update"] | undefined,
	delete?: ModelTypes["PrivateMediaDocAccessFields_focalY_Delete"] | undefined
};
	["PrivateMediaDocAccessFields_focalY_Create"]: {
		permission: boolean
};
	["PrivateMediaDocAccessFields_focalY_Read"]: {
		permission: boolean
};
	["PrivateMediaDocAccessFields_focalY_Update"]: {
		permission: boolean
};
	["PrivateMediaDocAccessFields_focalY_Delete"]: {
		permission: boolean
};
	["PrivateMediaDocAccessFields_sizes"]: {
		create?: ModelTypes["PrivateMediaDocAccessFields_sizes_Create"] | undefined,
	read?: ModelTypes["PrivateMediaDocAccessFields_sizes_Read"] | undefined,
	update?: ModelTypes["PrivateMediaDocAccessFields_sizes_Update"] | undefined,
	delete?: ModelTypes["PrivateMediaDocAccessFields_sizes_Delete"] | undefined,
	fields?: ModelTypes["PrivateMediaDocAccessFields_sizes_Fields"] | undefined
};
	["PrivateMediaDocAccessFields_sizes_Create"]: {
		permission: boolean
};
	["PrivateMediaDocAccessFields_sizes_Read"]: {
		permission: boolean
};
	["PrivateMediaDocAccessFields_sizes_Update"]: {
		permission: boolean
};
	["PrivateMediaDocAccessFields_sizes_Delete"]: {
		permission: boolean
};
	["PrivateMediaDocAccessFields_sizes_Fields"]: {
		thumbnail?: ModelTypes["PrivateMediaDocAccessFields_sizes_thumbnail"] | undefined,
	portrait?: ModelTypes["PrivateMediaDocAccessFields_sizes_portrait"] | undefined,
	hero?: ModelTypes["PrivateMediaDocAccessFields_sizes_hero"] | undefined,
	thumbnail_jpeg?: ModelTypes["PrivateMediaDocAccessFields_sizes_thumbnail_jpeg"] | undefined,
	portrait_jpeg?: ModelTypes["PrivateMediaDocAccessFields_sizes_portrait_jpeg"] | undefined,
	hero_jpeg?: ModelTypes["PrivateMediaDocAccessFields_sizes_hero_jpeg"] | undefined
};
	["PrivateMediaDocAccessFields_sizes_thumbnail"]: {
		create?: ModelTypes["PrivateMediaDocAccessFields_sizes_thumbnail_Create"] | undefined,
	read?: ModelTypes["PrivateMediaDocAccessFields_sizes_thumbnail_Read"] | undefined,
	update?: ModelTypes["PrivateMediaDocAccessFields_sizes_thumbnail_Update"] | undefined,
	delete?: ModelTypes["PrivateMediaDocAccessFields_sizes_thumbnail_Delete"] | undefined,
	fields?: ModelTypes["PrivateMediaDocAccessFields_sizes_thumbnail_Fields"] | undefined
};
	["PrivateMediaDocAccessFields_sizes_thumbnail_Create"]: {
		permission: boolean
};
	["PrivateMediaDocAccessFields_sizes_thumbnail_Read"]: {
		permission: boolean
};
	["PrivateMediaDocAccessFields_sizes_thumbnail_Update"]: {
		permission: boolean
};
	["PrivateMediaDocAccessFields_sizes_thumbnail_Delete"]: {
		permission: boolean
};
	["PrivateMediaDocAccessFields_sizes_thumbnail_Fields"]: {
		url?: ModelTypes["PrivateMediaDocAccessFields_sizes_thumbnail_url"] | undefined,
	width?: ModelTypes["PrivateMediaDocAccessFields_sizes_thumbnail_width"] | undefined,
	height?: ModelTypes["PrivateMediaDocAccessFields_sizes_thumbnail_height"] | undefined,
	mimeType?: ModelTypes["PrivateMediaDocAccessFields_sizes_thumbnail_mimeType"] | undefined,
	filesize?: ModelTypes["PrivateMediaDocAccessFields_sizes_thumbnail_filesize"] | undefined,
	filename?: ModelTypes["PrivateMediaDocAccessFields_sizes_thumbnail_filename"] | undefined
};
	["PrivateMediaDocAccessFields_sizes_thumbnail_url"]: {
		create?: ModelTypes["PrivateMediaDocAccessFields_sizes_thumbnail_url_Create"] | undefined,
	read?: ModelTypes["PrivateMediaDocAccessFields_sizes_thumbnail_url_Read"] | undefined,
	update?: ModelTypes["PrivateMediaDocAccessFields_sizes_thumbnail_url_Update"] | undefined,
	delete?: ModelTypes["PrivateMediaDocAccessFields_sizes_thumbnail_url_Delete"] | undefined
};
	["PrivateMediaDocAccessFields_sizes_thumbnail_url_Create"]: {
		permission: boolean
};
	["PrivateMediaDocAccessFields_sizes_thumbnail_url_Read"]: {
		permission: boolean
};
	["PrivateMediaDocAccessFields_sizes_thumbnail_url_Update"]: {
		permission: boolean
};
	["PrivateMediaDocAccessFields_sizes_thumbnail_url_Delete"]: {
		permission: boolean
};
	["PrivateMediaDocAccessFields_sizes_thumbnail_width"]: {
		create?: ModelTypes["PrivateMediaDocAccessFields_sizes_thumbnail_width_Create"] | undefined,
	read?: ModelTypes["PrivateMediaDocAccessFields_sizes_thumbnail_width_Read"] | undefined,
	update?: ModelTypes["PrivateMediaDocAccessFields_sizes_thumbnail_width_Update"] | undefined,
	delete?: ModelTypes["PrivateMediaDocAccessFields_sizes_thumbnail_width_Delete"] | undefined
};
	["PrivateMediaDocAccessFields_sizes_thumbnail_width_Create"]: {
		permission: boolean
};
	["PrivateMediaDocAccessFields_sizes_thumbnail_width_Read"]: {
		permission: boolean
};
	["PrivateMediaDocAccessFields_sizes_thumbnail_width_Update"]: {
		permission: boolean
};
	["PrivateMediaDocAccessFields_sizes_thumbnail_width_Delete"]: {
		permission: boolean
};
	["PrivateMediaDocAccessFields_sizes_thumbnail_height"]: {
		create?: ModelTypes["PrivateMediaDocAccessFields_sizes_thumbnail_height_Create"] | undefined,
	read?: ModelTypes["PrivateMediaDocAccessFields_sizes_thumbnail_height_Read"] | undefined,
	update?: ModelTypes["PrivateMediaDocAccessFields_sizes_thumbnail_height_Update"] | undefined,
	delete?: ModelTypes["PrivateMediaDocAccessFields_sizes_thumbnail_height_Delete"] | undefined
};
	["PrivateMediaDocAccessFields_sizes_thumbnail_height_Create"]: {
		permission: boolean
};
	["PrivateMediaDocAccessFields_sizes_thumbnail_height_Read"]: {
		permission: boolean
};
	["PrivateMediaDocAccessFields_sizes_thumbnail_height_Update"]: {
		permission: boolean
};
	["PrivateMediaDocAccessFields_sizes_thumbnail_height_Delete"]: {
		permission: boolean
};
	["PrivateMediaDocAccessFields_sizes_thumbnail_mimeType"]: {
		create?: ModelTypes["PrivateMediaDocAccessFields_sizes_thumbnail_mimeType_Create"] | undefined,
	read?: ModelTypes["PrivateMediaDocAccessFields_sizes_thumbnail_mimeType_Read"] | undefined,
	update?: ModelTypes["PrivateMediaDocAccessFields_sizes_thumbnail_mimeType_Update"] | undefined,
	delete?: ModelTypes["PrivateMediaDocAccessFields_sizes_thumbnail_mimeType_Delete"] | undefined
};
	["PrivateMediaDocAccessFields_sizes_thumbnail_mimeType_Create"]: {
		permission: boolean
};
	["PrivateMediaDocAccessFields_sizes_thumbnail_mimeType_Read"]: {
		permission: boolean
};
	["PrivateMediaDocAccessFields_sizes_thumbnail_mimeType_Update"]: {
		permission: boolean
};
	["PrivateMediaDocAccessFields_sizes_thumbnail_mimeType_Delete"]: {
		permission: boolean
};
	["PrivateMediaDocAccessFields_sizes_thumbnail_filesize"]: {
		create?: ModelTypes["PrivateMediaDocAccessFields_sizes_thumbnail_filesize_Create"] | undefined,
	read?: ModelTypes["PrivateMediaDocAccessFields_sizes_thumbnail_filesize_Read"] | undefined,
	update?: ModelTypes["PrivateMediaDocAccessFields_sizes_thumbnail_filesize_Update"] | undefined,
	delete?: ModelTypes["PrivateMediaDocAccessFields_sizes_thumbnail_filesize_Delete"] | undefined
};
	["PrivateMediaDocAccessFields_sizes_thumbnail_filesize_Create"]: {
		permission: boolean
};
	["PrivateMediaDocAccessFields_sizes_thumbnail_filesize_Read"]: {
		permission: boolean
};
	["PrivateMediaDocAccessFields_sizes_thumbnail_filesize_Update"]: {
		permission: boolean
};
	["PrivateMediaDocAccessFields_sizes_thumbnail_filesize_Delete"]: {
		permission: boolean
};
	["PrivateMediaDocAccessFields_sizes_thumbnail_filename"]: {
		create?: ModelTypes["PrivateMediaDocAccessFields_sizes_thumbnail_filename_Create"] | undefined,
	read?: ModelTypes["PrivateMediaDocAccessFields_sizes_thumbnail_filename_Read"] | undefined,
	update?: ModelTypes["PrivateMediaDocAccessFields_sizes_thumbnail_filename_Update"] | undefined,
	delete?: ModelTypes["PrivateMediaDocAccessFields_sizes_thumbnail_filename_Delete"] | undefined
};
	["PrivateMediaDocAccessFields_sizes_thumbnail_filename_Create"]: {
		permission: boolean
};
	["PrivateMediaDocAccessFields_sizes_thumbnail_filename_Read"]: {
		permission: boolean
};
	["PrivateMediaDocAccessFields_sizes_thumbnail_filename_Update"]: {
		permission: boolean
};
	["PrivateMediaDocAccessFields_sizes_thumbnail_filename_Delete"]: {
		permission: boolean
};
	["PrivateMediaDocAccessFields_sizes_portrait"]: {
		create?: ModelTypes["PrivateMediaDocAccessFields_sizes_portrait_Create"] | undefined,
	read?: ModelTypes["PrivateMediaDocAccessFields_sizes_portrait_Read"] | undefined,
	update?: ModelTypes["PrivateMediaDocAccessFields_sizes_portrait_Update"] | undefined,
	delete?: ModelTypes["PrivateMediaDocAccessFields_sizes_portrait_Delete"] | undefined,
	fields?: ModelTypes["PrivateMediaDocAccessFields_sizes_portrait_Fields"] | undefined
};
	["PrivateMediaDocAccessFields_sizes_portrait_Create"]: {
		permission: boolean
};
	["PrivateMediaDocAccessFields_sizes_portrait_Read"]: {
		permission: boolean
};
	["PrivateMediaDocAccessFields_sizes_portrait_Update"]: {
		permission: boolean
};
	["PrivateMediaDocAccessFields_sizes_portrait_Delete"]: {
		permission: boolean
};
	["PrivateMediaDocAccessFields_sizes_portrait_Fields"]: {
		url?: ModelTypes["PrivateMediaDocAccessFields_sizes_portrait_url"] | undefined,
	width?: ModelTypes["PrivateMediaDocAccessFields_sizes_portrait_width"] | undefined,
	height?: ModelTypes["PrivateMediaDocAccessFields_sizes_portrait_height"] | undefined,
	mimeType?: ModelTypes["PrivateMediaDocAccessFields_sizes_portrait_mimeType"] | undefined,
	filesize?: ModelTypes["PrivateMediaDocAccessFields_sizes_portrait_filesize"] | undefined,
	filename?: ModelTypes["PrivateMediaDocAccessFields_sizes_portrait_filename"] | undefined
};
	["PrivateMediaDocAccessFields_sizes_portrait_url"]: {
		create?: ModelTypes["PrivateMediaDocAccessFields_sizes_portrait_url_Create"] | undefined,
	read?: ModelTypes["PrivateMediaDocAccessFields_sizes_portrait_url_Read"] | undefined,
	update?: ModelTypes["PrivateMediaDocAccessFields_sizes_portrait_url_Update"] | undefined,
	delete?: ModelTypes["PrivateMediaDocAccessFields_sizes_portrait_url_Delete"] | undefined
};
	["PrivateMediaDocAccessFields_sizes_portrait_url_Create"]: {
		permission: boolean
};
	["PrivateMediaDocAccessFields_sizes_portrait_url_Read"]: {
		permission: boolean
};
	["PrivateMediaDocAccessFields_sizes_portrait_url_Update"]: {
		permission: boolean
};
	["PrivateMediaDocAccessFields_sizes_portrait_url_Delete"]: {
		permission: boolean
};
	["PrivateMediaDocAccessFields_sizes_portrait_width"]: {
		create?: ModelTypes["PrivateMediaDocAccessFields_sizes_portrait_width_Create"] | undefined,
	read?: ModelTypes["PrivateMediaDocAccessFields_sizes_portrait_width_Read"] | undefined,
	update?: ModelTypes["PrivateMediaDocAccessFields_sizes_portrait_width_Update"] | undefined,
	delete?: ModelTypes["PrivateMediaDocAccessFields_sizes_portrait_width_Delete"] | undefined
};
	["PrivateMediaDocAccessFields_sizes_portrait_width_Create"]: {
		permission: boolean
};
	["PrivateMediaDocAccessFields_sizes_portrait_width_Read"]: {
		permission: boolean
};
	["PrivateMediaDocAccessFields_sizes_portrait_width_Update"]: {
		permission: boolean
};
	["PrivateMediaDocAccessFields_sizes_portrait_width_Delete"]: {
		permission: boolean
};
	["PrivateMediaDocAccessFields_sizes_portrait_height"]: {
		create?: ModelTypes["PrivateMediaDocAccessFields_sizes_portrait_height_Create"] | undefined,
	read?: ModelTypes["PrivateMediaDocAccessFields_sizes_portrait_height_Read"] | undefined,
	update?: ModelTypes["PrivateMediaDocAccessFields_sizes_portrait_height_Update"] | undefined,
	delete?: ModelTypes["PrivateMediaDocAccessFields_sizes_portrait_height_Delete"] | undefined
};
	["PrivateMediaDocAccessFields_sizes_portrait_height_Create"]: {
		permission: boolean
};
	["PrivateMediaDocAccessFields_sizes_portrait_height_Read"]: {
		permission: boolean
};
	["PrivateMediaDocAccessFields_sizes_portrait_height_Update"]: {
		permission: boolean
};
	["PrivateMediaDocAccessFields_sizes_portrait_height_Delete"]: {
		permission: boolean
};
	["PrivateMediaDocAccessFields_sizes_portrait_mimeType"]: {
		create?: ModelTypes["PrivateMediaDocAccessFields_sizes_portrait_mimeType_Create"] | undefined,
	read?: ModelTypes["PrivateMediaDocAccessFields_sizes_portrait_mimeType_Read"] | undefined,
	update?: ModelTypes["PrivateMediaDocAccessFields_sizes_portrait_mimeType_Update"] | undefined,
	delete?: ModelTypes["PrivateMediaDocAccessFields_sizes_portrait_mimeType_Delete"] | undefined
};
	["PrivateMediaDocAccessFields_sizes_portrait_mimeType_Create"]: {
		permission: boolean
};
	["PrivateMediaDocAccessFields_sizes_portrait_mimeType_Read"]: {
		permission: boolean
};
	["PrivateMediaDocAccessFields_sizes_portrait_mimeType_Update"]: {
		permission: boolean
};
	["PrivateMediaDocAccessFields_sizes_portrait_mimeType_Delete"]: {
		permission: boolean
};
	["PrivateMediaDocAccessFields_sizes_portrait_filesize"]: {
		create?: ModelTypes["PrivateMediaDocAccessFields_sizes_portrait_filesize_Create"] | undefined,
	read?: ModelTypes["PrivateMediaDocAccessFields_sizes_portrait_filesize_Read"] | undefined,
	update?: ModelTypes["PrivateMediaDocAccessFields_sizes_portrait_filesize_Update"] | undefined,
	delete?: ModelTypes["PrivateMediaDocAccessFields_sizes_portrait_filesize_Delete"] | undefined
};
	["PrivateMediaDocAccessFields_sizes_portrait_filesize_Create"]: {
		permission: boolean
};
	["PrivateMediaDocAccessFields_sizes_portrait_filesize_Read"]: {
		permission: boolean
};
	["PrivateMediaDocAccessFields_sizes_portrait_filesize_Update"]: {
		permission: boolean
};
	["PrivateMediaDocAccessFields_sizes_portrait_filesize_Delete"]: {
		permission: boolean
};
	["PrivateMediaDocAccessFields_sizes_portrait_filename"]: {
		create?: ModelTypes["PrivateMediaDocAccessFields_sizes_portrait_filename_Create"] | undefined,
	read?: ModelTypes["PrivateMediaDocAccessFields_sizes_portrait_filename_Read"] | undefined,
	update?: ModelTypes["PrivateMediaDocAccessFields_sizes_portrait_filename_Update"] | undefined,
	delete?: ModelTypes["PrivateMediaDocAccessFields_sizes_portrait_filename_Delete"] | undefined
};
	["PrivateMediaDocAccessFields_sizes_portrait_filename_Create"]: {
		permission: boolean
};
	["PrivateMediaDocAccessFields_sizes_portrait_filename_Read"]: {
		permission: boolean
};
	["PrivateMediaDocAccessFields_sizes_portrait_filename_Update"]: {
		permission: boolean
};
	["PrivateMediaDocAccessFields_sizes_portrait_filename_Delete"]: {
		permission: boolean
};
	["PrivateMediaDocAccessFields_sizes_hero"]: {
		create?: ModelTypes["PrivateMediaDocAccessFields_sizes_hero_Create"] | undefined,
	read?: ModelTypes["PrivateMediaDocAccessFields_sizes_hero_Read"] | undefined,
	update?: ModelTypes["PrivateMediaDocAccessFields_sizes_hero_Update"] | undefined,
	delete?: ModelTypes["PrivateMediaDocAccessFields_sizes_hero_Delete"] | undefined,
	fields?: ModelTypes["PrivateMediaDocAccessFields_sizes_hero_Fields"] | undefined
};
	["PrivateMediaDocAccessFields_sizes_hero_Create"]: {
		permission: boolean
};
	["PrivateMediaDocAccessFields_sizes_hero_Read"]: {
		permission: boolean
};
	["PrivateMediaDocAccessFields_sizes_hero_Update"]: {
		permission: boolean
};
	["PrivateMediaDocAccessFields_sizes_hero_Delete"]: {
		permission: boolean
};
	["PrivateMediaDocAccessFields_sizes_hero_Fields"]: {
		url?: ModelTypes["PrivateMediaDocAccessFields_sizes_hero_url"] | undefined,
	width?: ModelTypes["PrivateMediaDocAccessFields_sizes_hero_width"] | undefined,
	height?: ModelTypes["PrivateMediaDocAccessFields_sizes_hero_height"] | undefined,
	mimeType?: ModelTypes["PrivateMediaDocAccessFields_sizes_hero_mimeType"] | undefined,
	filesize?: ModelTypes["PrivateMediaDocAccessFields_sizes_hero_filesize"] | undefined,
	filename?: ModelTypes["PrivateMediaDocAccessFields_sizes_hero_filename"] | undefined
};
	["PrivateMediaDocAccessFields_sizes_hero_url"]: {
		create?: ModelTypes["PrivateMediaDocAccessFields_sizes_hero_url_Create"] | undefined,
	read?: ModelTypes["PrivateMediaDocAccessFields_sizes_hero_url_Read"] | undefined,
	update?: ModelTypes["PrivateMediaDocAccessFields_sizes_hero_url_Update"] | undefined,
	delete?: ModelTypes["PrivateMediaDocAccessFields_sizes_hero_url_Delete"] | undefined
};
	["PrivateMediaDocAccessFields_sizes_hero_url_Create"]: {
		permission: boolean
};
	["PrivateMediaDocAccessFields_sizes_hero_url_Read"]: {
		permission: boolean
};
	["PrivateMediaDocAccessFields_sizes_hero_url_Update"]: {
		permission: boolean
};
	["PrivateMediaDocAccessFields_sizes_hero_url_Delete"]: {
		permission: boolean
};
	["PrivateMediaDocAccessFields_sizes_hero_width"]: {
		create?: ModelTypes["PrivateMediaDocAccessFields_sizes_hero_width_Create"] | undefined,
	read?: ModelTypes["PrivateMediaDocAccessFields_sizes_hero_width_Read"] | undefined,
	update?: ModelTypes["PrivateMediaDocAccessFields_sizes_hero_width_Update"] | undefined,
	delete?: ModelTypes["PrivateMediaDocAccessFields_sizes_hero_width_Delete"] | undefined
};
	["PrivateMediaDocAccessFields_sizes_hero_width_Create"]: {
		permission: boolean
};
	["PrivateMediaDocAccessFields_sizes_hero_width_Read"]: {
		permission: boolean
};
	["PrivateMediaDocAccessFields_sizes_hero_width_Update"]: {
		permission: boolean
};
	["PrivateMediaDocAccessFields_sizes_hero_width_Delete"]: {
		permission: boolean
};
	["PrivateMediaDocAccessFields_sizes_hero_height"]: {
		create?: ModelTypes["PrivateMediaDocAccessFields_sizes_hero_height_Create"] | undefined,
	read?: ModelTypes["PrivateMediaDocAccessFields_sizes_hero_height_Read"] | undefined,
	update?: ModelTypes["PrivateMediaDocAccessFields_sizes_hero_height_Update"] | undefined,
	delete?: ModelTypes["PrivateMediaDocAccessFields_sizes_hero_height_Delete"] | undefined
};
	["PrivateMediaDocAccessFields_sizes_hero_height_Create"]: {
		permission: boolean
};
	["PrivateMediaDocAccessFields_sizes_hero_height_Read"]: {
		permission: boolean
};
	["PrivateMediaDocAccessFields_sizes_hero_height_Update"]: {
		permission: boolean
};
	["PrivateMediaDocAccessFields_sizes_hero_height_Delete"]: {
		permission: boolean
};
	["PrivateMediaDocAccessFields_sizes_hero_mimeType"]: {
		create?: ModelTypes["PrivateMediaDocAccessFields_sizes_hero_mimeType_Create"] | undefined,
	read?: ModelTypes["PrivateMediaDocAccessFields_sizes_hero_mimeType_Read"] | undefined,
	update?: ModelTypes["PrivateMediaDocAccessFields_sizes_hero_mimeType_Update"] | undefined,
	delete?: ModelTypes["PrivateMediaDocAccessFields_sizes_hero_mimeType_Delete"] | undefined
};
	["PrivateMediaDocAccessFields_sizes_hero_mimeType_Create"]: {
		permission: boolean
};
	["PrivateMediaDocAccessFields_sizes_hero_mimeType_Read"]: {
		permission: boolean
};
	["PrivateMediaDocAccessFields_sizes_hero_mimeType_Update"]: {
		permission: boolean
};
	["PrivateMediaDocAccessFields_sizes_hero_mimeType_Delete"]: {
		permission: boolean
};
	["PrivateMediaDocAccessFields_sizes_hero_filesize"]: {
		create?: ModelTypes["PrivateMediaDocAccessFields_sizes_hero_filesize_Create"] | undefined,
	read?: ModelTypes["PrivateMediaDocAccessFields_sizes_hero_filesize_Read"] | undefined,
	update?: ModelTypes["PrivateMediaDocAccessFields_sizes_hero_filesize_Update"] | undefined,
	delete?: ModelTypes["PrivateMediaDocAccessFields_sizes_hero_filesize_Delete"] | undefined
};
	["PrivateMediaDocAccessFields_sizes_hero_filesize_Create"]: {
		permission: boolean
};
	["PrivateMediaDocAccessFields_sizes_hero_filesize_Read"]: {
		permission: boolean
};
	["PrivateMediaDocAccessFields_sizes_hero_filesize_Update"]: {
		permission: boolean
};
	["PrivateMediaDocAccessFields_sizes_hero_filesize_Delete"]: {
		permission: boolean
};
	["PrivateMediaDocAccessFields_sizes_hero_filename"]: {
		create?: ModelTypes["PrivateMediaDocAccessFields_sizes_hero_filename_Create"] | undefined,
	read?: ModelTypes["PrivateMediaDocAccessFields_sizes_hero_filename_Read"] | undefined,
	update?: ModelTypes["PrivateMediaDocAccessFields_sizes_hero_filename_Update"] | undefined,
	delete?: ModelTypes["PrivateMediaDocAccessFields_sizes_hero_filename_Delete"] | undefined
};
	["PrivateMediaDocAccessFields_sizes_hero_filename_Create"]: {
		permission: boolean
};
	["PrivateMediaDocAccessFields_sizes_hero_filename_Read"]: {
		permission: boolean
};
	["PrivateMediaDocAccessFields_sizes_hero_filename_Update"]: {
		permission: boolean
};
	["PrivateMediaDocAccessFields_sizes_hero_filename_Delete"]: {
		permission: boolean
};
	["PrivateMediaDocAccessFields_sizes_thumbnail_jpeg"]: {
		create?: ModelTypes["PrivateMediaDocAccessFields_sizes_thumbnail_jpeg_Create"] | undefined,
	read?: ModelTypes["PrivateMediaDocAccessFields_sizes_thumbnail_jpeg_Read"] | undefined,
	update?: ModelTypes["PrivateMediaDocAccessFields_sizes_thumbnail_jpeg_Update"] | undefined,
	delete?: ModelTypes["PrivateMediaDocAccessFields_sizes_thumbnail_jpeg_Delete"] | undefined,
	fields?: ModelTypes["PrivateMediaDocAccessFields_sizes_thumbnail_jpeg_Fields"] | undefined
};
	["PrivateMediaDocAccessFields_sizes_thumbnail_jpeg_Create"]: {
		permission: boolean
};
	["PrivateMediaDocAccessFields_sizes_thumbnail_jpeg_Read"]: {
		permission: boolean
};
	["PrivateMediaDocAccessFields_sizes_thumbnail_jpeg_Update"]: {
		permission: boolean
};
	["PrivateMediaDocAccessFields_sizes_thumbnail_jpeg_Delete"]: {
		permission: boolean
};
	["PrivateMediaDocAccessFields_sizes_thumbnail_jpeg_Fields"]: {
		url?: ModelTypes["PrivateMediaDocAccessFields_sizes_thumbnail_jpeg_url"] | undefined,
	width?: ModelTypes["PrivateMediaDocAccessFields_sizes_thumbnail_jpeg_width"] | undefined,
	height?: ModelTypes["PrivateMediaDocAccessFields_sizes_thumbnail_jpeg_height"] | undefined,
	mimeType?: ModelTypes["PrivateMediaDocAccessFields_sizes_thumbnail_jpeg_mimeType"] | undefined,
	filesize?: ModelTypes["PrivateMediaDocAccessFields_sizes_thumbnail_jpeg_filesize"] | undefined,
	filename?: ModelTypes["PrivateMediaDocAccessFields_sizes_thumbnail_jpeg_filename"] | undefined
};
	["PrivateMediaDocAccessFields_sizes_thumbnail_jpeg_url"]: {
		create?: ModelTypes["PrivateMediaDocAccessFields_sizes_thumbnail_jpeg_url_Create"] | undefined,
	read?: ModelTypes["PrivateMediaDocAccessFields_sizes_thumbnail_jpeg_url_Read"] | undefined,
	update?: ModelTypes["PrivateMediaDocAccessFields_sizes_thumbnail_jpeg_url_Update"] | undefined,
	delete?: ModelTypes["PrivateMediaDocAccessFields_sizes_thumbnail_jpeg_url_Delete"] | undefined
};
	["PrivateMediaDocAccessFields_sizes_thumbnail_jpeg_url_Create"]: {
		permission: boolean
};
	["PrivateMediaDocAccessFields_sizes_thumbnail_jpeg_url_Read"]: {
		permission: boolean
};
	["PrivateMediaDocAccessFields_sizes_thumbnail_jpeg_url_Update"]: {
		permission: boolean
};
	["PrivateMediaDocAccessFields_sizes_thumbnail_jpeg_url_Delete"]: {
		permission: boolean
};
	["PrivateMediaDocAccessFields_sizes_thumbnail_jpeg_width"]: {
		create?: ModelTypes["PrivateMediaDocAccessFields_sizes_thumbnail_jpeg_width_Create"] | undefined,
	read?: ModelTypes["PrivateMediaDocAccessFields_sizes_thumbnail_jpeg_width_Read"] | undefined,
	update?: ModelTypes["PrivateMediaDocAccessFields_sizes_thumbnail_jpeg_width_Update"] | undefined,
	delete?: ModelTypes["PrivateMediaDocAccessFields_sizes_thumbnail_jpeg_width_Delete"] | undefined
};
	["PrivateMediaDocAccessFields_sizes_thumbnail_jpeg_width_Create"]: {
		permission: boolean
};
	["PrivateMediaDocAccessFields_sizes_thumbnail_jpeg_width_Read"]: {
		permission: boolean
};
	["PrivateMediaDocAccessFields_sizes_thumbnail_jpeg_width_Update"]: {
		permission: boolean
};
	["PrivateMediaDocAccessFields_sizes_thumbnail_jpeg_width_Delete"]: {
		permission: boolean
};
	["PrivateMediaDocAccessFields_sizes_thumbnail_jpeg_height"]: {
		create?: ModelTypes["PrivateMediaDocAccessFields_sizes_thumbnail_jpeg_height_Create"] | undefined,
	read?: ModelTypes["PrivateMediaDocAccessFields_sizes_thumbnail_jpeg_height_Read"] | undefined,
	update?: ModelTypes["PrivateMediaDocAccessFields_sizes_thumbnail_jpeg_height_Update"] | undefined,
	delete?: ModelTypes["PrivateMediaDocAccessFields_sizes_thumbnail_jpeg_height_Delete"] | undefined
};
	["PrivateMediaDocAccessFields_sizes_thumbnail_jpeg_height_Create"]: {
		permission: boolean
};
	["PrivateMediaDocAccessFields_sizes_thumbnail_jpeg_height_Read"]: {
		permission: boolean
};
	["PrivateMediaDocAccessFields_sizes_thumbnail_jpeg_height_Update"]: {
		permission: boolean
};
	["PrivateMediaDocAccessFields_sizes_thumbnail_jpeg_height_Delete"]: {
		permission: boolean
};
	["PrivateMediaDocAccessFields_sizes_thumbnail_jpeg_mimeType"]: {
		create?: ModelTypes["PrivateMediaDocAccessFields_sizes_thumbnail_jpeg_mimeType_Create"] | undefined,
	read?: ModelTypes["PrivateMediaDocAccessFields_sizes_thumbnail_jpeg_mimeType_Read"] | undefined,
	update?: ModelTypes["PrivateMediaDocAccessFields_sizes_thumbnail_jpeg_mimeType_Update"] | undefined,
	delete?: ModelTypes["PrivateMediaDocAccessFields_sizes_thumbnail_jpeg_mimeType_Delete"] | undefined
};
	["PrivateMediaDocAccessFields_sizes_thumbnail_jpeg_mimeType_Create"]: {
		permission: boolean
};
	["PrivateMediaDocAccessFields_sizes_thumbnail_jpeg_mimeType_Read"]: {
		permission: boolean
};
	["PrivateMediaDocAccessFields_sizes_thumbnail_jpeg_mimeType_Update"]: {
		permission: boolean
};
	["PrivateMediaDocAccessFields_sizes_thumbnail_jpeg_mimeType_Delete"]: {
		permission: boolean
};
	["PrivateMediaDocAccessFields_sizes_thumbnail_jpeg_filesize"]: {
		create?: ModelTypes["PrivateMediaDocAccessFields_sizes_thumbnail_jpeg_filesize_Create"] | undefined,
	read?: ModelTypes["PrivateMediaDocAccessFields_sizes_thumbnail_jpeg_filesize_Read"] | undefined,
	update?: ModelTypes["PrivateMediaDocAccessFields_sizes_thumbnail_jpeg_filesize_Update"] | undefined,
	delete?: ModelTypes["PrivateMediaDocAccessFields_sizes_thumbnail_jpeg_filesize_Delete"] | undefined
};
	["PrivateMediaDocAccessFields_sizes_thumbnail_jpeg_filesize_Create"]: {
		permission: boolean
};
	["PrivateMediaDocAccessFields_sizes_thumbnail_jpeg_filesize_Read"]: {
		permission: boolean
};
	["PrivateMediaDocAccessFields_sizes_thumbnail_jpeg_filesize_Update"]: {
		permission: boolean
};
	["PrivateMediaDocAccessFields_sizes_thumbnail_jpeg_filesize_Delete"]: {
		permission: boolean
};
	["PrivateMediaDocAccessFields_sizes_thumbnail_jpeg_filename"]: {
		create?: ModelTypes["PrivateMediaDocAccessFields_sizes_thumbnail_jpeg_filename_Create"] | undefined,
	read?: ModelTypes["PrivateMediaDocAccessFields_sizes_thumbnail_jpeg_filename_Read"] | undefined,
	update?: ModelTypes["PrivateMediaDocAccessFields_sizes_thumbnail_jpeg_filename_Update"] | undefined,
	delete?: ModelTypes["PrivateMediaDocAccessFields_sizes_thumbnail_jpeg_filename_Delete"] | undefined
};
	["PrivateMediaDocAccessFields_sizes_thumbnail_jpeg_filename_Create"]: {
		permission: boolean
};
	["PrivateMediaDocAccessFields_sizes_thumbnail_jpeg_filename_Read"]: {
		permission: boolean
};
	["PrivateMediaDocAccessFields_sizes_thumbnail_jpeg_filename_Update"]: {
		permission: boolean
};
	["PrivateMediaDocAccessFields_sizes_thumbnail_jpeg_filename_Delete"]: {
		permission: boolean
};
	["PrivateMediaDocAccessFields_sizes_portrait_jpeg"]: {
		create?: ModelTypes["PrivateMediaDocAccessFields_sizes_portrait_jpeg_Create"] | undefined,
	read?: ModelTypes["PrivateMediaDocAccessFields_sizes_portrait_jpeg_Read"] | undefined,
	update?: ModelTypes["PrivateMediaDocAccessFields_sizes_portrait_jpeg_Update"] | undefined,
	delete?: ModelTypes["PrivateMediaDocAccessFields_sizes_portrait_jpeg_Delete"] | undefined,
	fields?: ModelTypes["PrivateMediaDocAccessFields_sizes_portrait_jpeg_Fields"] | undefined
};
	["PrivateMediaDocAccessFields_sizes_portrait_jpeg_Create"]: {
		permission: boolean
};
	["PrivateMediaDocAccessFields_sizes_portrait_jpeg_Read"]: {
		permission: boolean
};
	["PrivateMediaDocAccessFields_sizes_portrait_jpeg_Update"]: {
		permission: boolean
};
	["PrivateMediaDocAccessFields_sizes_portrait_jpeg_Delete"]: {
		permission: boolean
};
	["PrivateMediaDocAccessFields_sizes_portrait_jpeg_Fields"]: {
		url?: ModelTypes["PrivateMediaDocAccessFields_sizes_portrait_jpeg_url"] | undefined,
	width?: ModelTypes["PrivateMediaDocAccessFields_sizes_portrait_jpeg_width"] | undefined,
	height?: ModelTypes["PrivateMediaDocAccessFields_sizes_portrait_jpeg_height"] | undefined,
	mimeType?: ModelTypes["PrivateMediaDocAccessFields_sizes_portrait_jpeg_mimeType"] | undefined,
	filesize?: ModelTypes["PrivateMediaDocAccessFields_sizes_portrait_jpeg_filesize"] | undefined,
	filename?: ModelTypes["PrivateMediaDocAccessFields_sizes_portrait_jpeg_filename"] | undefined
};
	["PrivateMediaDocAccessFields_sizes_portrait_jpeg_url"]: {
		create?: ModelTypes["PrivateMediaDocAccessFields_sizes_portrait_jpeg_url_Create"] | undefined,
	read?: ModelTypes["PrivateMediaDocAccessFields_sizes_portrait_jpeg_url_Read"] | undefined,
	update?: ModelTypes["PrivateMediaDocAccessFields_sizes_portrait_jpeg_url_Update"] | undefined,
	delete?: ModelTypes["PrivateMediaDocAccessFields_sizes_portrait_jpeg_url_Delete"] | undefined
};
	["PrivateMediaDocAccessFields_sizes_portrait_jpeg_url_Create"]: {
		permission: boolean
};
	["PrivateMediaDocAccessFields_sizes_portrait_jpeg_url_Read"]: {
		permission: boolean
};
	["PrivateMediaDocAccessFields_sizes_portrait_jpeg_url_Update"]: {
		permission: boolean
};
	["PrivateMediaDocAccessFields_sizes_portrait_jpeg_url_Delete"]: {
		permission: boolean
};
	["PrivateMediaDocAccessFields_sizes_portrait_jpeg_width"]: {
		create?: ModelTypes["PrivateMediaDocAccessFields_sizes_portrait_jpeg_width_Create"] | undefined,
	read?: ModelTypes["PrivateMediaDocAccessFields_sizes_portrait_jpeg_width_Read"] | undefined,
	update?: ModelTypes["PrivateMediaDocAccessFields_sizes_portrait_jpeg_width_Update"] | undefined,
	delete?: ModelTypes["PrivateMediaDocAccessFields_sizes_portrait_jpeg_width_Delete"] | undefined
};
	["PrivateMediaDocAccessFields_sizes_portrait_jpeg_width_Create"]: {
		permission: boolean
};
	["PrivateMediaDocAccessFields_sizes_portrait_jpeg_width_Read"]: {
		permission: boolean
};
	["PrivateMediaDocAccessFields_sizes_portrait_jpeg_width_Update"]: {
		permission: boolean
};
	["PrivateMediaDocAccessFields_sizes_portrait_jpeg_width_Delete"]: {
		permission: boolean
};
	["PrivateMediaDocAccessFields_sizes_portrait_jpeg_height"]: {
		create?: ModelTypes["PrivateMediaDocAccessFields_sizes_portrait_jpeg_height_Create"] | undefined,
	read?: ModelTypes["PrivateMediaDocAccessFields_sizes_portrait_jpeg_height_Read"] | undefined,
	update?: ModelTypes["PrivateMediaDocAccessFields_sizes_portrait_jpeg_height_Update"] | undefined,
	delete?: ModelTypes["PrivateMediaDocAccessFields_sizes_portrait_jpeg_height_Delete"] | undefined
};
	["PrivateMediaDocAccessFields_sizes_portrait_jpeg_height_Create"]: {
		permission: boolean
};
	["PrivateMediaDocAccessFields_sizes_portrait_jpeg_height_Read"]: {
		permission: boolean
};
	["PrivateMediaDocAccessFields_sizes_portrait_jpeg_height_Update"]: {
		permission: boolean
};
	["PrivateMediaDocAccessFields_sizes_portrait_jpeg_height_Delete"]: {
		permission: boolean
};
	["PrivateMediaDocAccessFields_sizes_portrait_jpeg_mimeType"]: {
		create?: ModelTypes["PrivateMediaDocAccessFields_sizes_portrait_jpeg_mimeType_Create"] | undefined,
	read?: ModelTypes["PrivateMediaDocAccessFields_sizes_portrait_jpeg_mimeType_Read"] | undefined,
	update?: ModelTypes["PrivateMediaDocAccessFields_sizes_portrait_jpeg_mimeType_Update"] | undefined,
	delete?: ModelTypes["PrivateMediaDocAccessFields_sizes_portrait_jpeg_mimeType_Delete"] | undefined
};
	["PrivateMediaDocAccessFields_sizes_portrait_jpeg_mimeType_Create"]: {
		permission: boolean
};
	["PrivateMediaDocAccessFields_sizes_portrait_jpeg_mimeType_Read"]: {
		permission: boolean
};
	["PrivateMediaDocAccessFields_sizes_portrait_jpeg_mimeType_Update"]: {
		permission: boolean
};
	["PrivateMediaDocAccessFields_sizes_portrait_jpeg_mimeType_Delete"]: {
		permission: boolean
};
	["PrivateMediaDocAccessFields_sizes_portrait_jpeg_filesize"]: {
		create?: ModelTypes["PrivateMediaDocAccessFields_sizes_portrait_jpeg_filesize_Create"] | undefined,
	read?: ModelTypes["PrivateMediaDocAccessFields_sizes_portrait_jpeg_filesize_Read"] | undefined,
	update?: ModelTypes["PrivateMediaDocAccessFields_sizes_portrait_jpeg_filesize_Update"] | undefined,
	delete?: ModelTypes["PrivateMediaDocAccessFields_sizes_portrait_jpeg_filesize_Delete"] | undefined
};
	["PrivateMediaDocAccessFields_sizes_portrait_jpeg_filesize_Create"]: {
		permission: boolean
};
	["PrivateMediaDocAccessFields_sizes_portrait_jpeg_filesize_Read"]: {
		permission: boolean
};
	["PrivateMediaDocAccessFields_sizes_portrait_jpeg_filesize_Update"]: {
		permission: boolean
};
	["PrivateMediaDocAccessFields_sizes_portrait_jpeg_filesize_Delete"]: {
		permission: boolean
};
	["PrivateMediaDocAccessFields_sizes_portrait_jpeg_filename"]: {
		create?: ModelTypes["PrivateMediaDocAccessFields_sizes_portrait_jpeg_filename_Create"] | undefined,
	read?: ModelTypes["PrivateMediaDocAccessFields_sizes_portrait_jpeg_filename_Read"] | undefined,
	update?: ModelTypes["PrivateMediaDocAccessFields_sizes_portrait_jpeg_filename_Update"] | undefined,
	delete?: ModelTypes["PrivateMediaDocAccessFields_sizes_portrait_jpeg_filename_Delete"] | undefined
};
	["PrivateMediaDocAccessFields_sizes_portrait_jpeg_filename_Create"]: {
		permission: boolean
};
	["PrivateMediaDocAccessFields_sizes_portrait_jpeg_filename_Read"]: {
		permission: boolean
};
	["PrivateMediaDocAccessFields_sizes_portrait_jpeg_filename_Update"]: {
		permission: boolean
};
	["PrivateMediaDocAccessFields_sizes_portrait_jpeg_filename_Delete"]: {
		permission: boolean
};
	["PrivateMediaDocAccessFields_sizes_hero_jpeg"]: {
		create?: ModelTypes["PrivateMediaDocAccessFields_sizes_hero_jpeg_Create"] | undefined,
	read?: ModelTypes["PrivateMediaDocAccessFields_sizes_hero_jpeg_Read"] | undefined,
	update?: ModelTypes["PrivateMediaDocAccessFields_sizes_hero_jpeg_Update"] | undefined,
	delete?: ModelTypes["PrivateMediaDocAccessFields_sizes_hero_jpeg_Delete"] | undefined,
	fields?: ModelTypes["PrivateMediaDocAccessFields_sizes_hero_jpeg_Fields"] | undefined
};
	["PrivateMediaDocAccessFields_sizes_hero_jpeg_Create"]: {
		permission: boolean
};
	["PrivateMediaDocAccessFields_sizes_hero_jpeg_Read"]: {
		permission: boolean
};
	["PrivateMediaDocAccessFields_sizes_hero_jpeg_Update"]: {
		permission: boolean
};
	["PrivateMediaDocAccessFields_sizes_hero_jpeg_Delete"]: {
		permission: boolean
};
	["PrivateMediaDocAccessFields_sizes_hero_jpeg_Fields"]: {
		url?: ModelTypes["PrivateMediaDocAccessFields_sizes_hero_jpeg_url"] | undefined,
	width?: ModelTypes["PrivateMediaDocAccessFields_sizes_hero_jpeg_width"] | undefined,
	height?: ModelTypes["PrivateMediaDocAccessFields_sizes_hero_jpeg_height"] | undefined,
	mimeType?: ModelTypes["PrivateMediaDocAccessFields_sizes_hero_jpeg_mimeType"] | undefined,
	filesize?: ModelTypes["PrivateMediaDocAccessFields_sizes_hero_jpeg_filesize"] | undefined,
	filename?: ModelTypes["PrivateMediaDocAccessFields_sizes_hero_jpeg_filename"] | undefined
};
	["PrivateMediaDocAccessFields_sizes_hero_jpeg_url"]: {
		create?: ModelTypes["PrivateMediaDocAccessFields_sizes_hero_jpeg_url_Create"] | undefined,
	read?: ModelTypes["PrivateMediaDocAccessFields_sizes_hero_jpeg_url_Read"] | undefined,
	update?: ModelTypes["PrivateMediaDocAccessFields_sizes_hero_jpeg_url_Update"] | undefined,
	delete?: ModelTypes["PrivateMediaDocAccessFields_sizes_hero_jpeg_url_Delete"] | undefined
};
	["PrivateMediaDocAccessFields_sizes_hero_jpeg_url_Create"]: {
		permission: boolean
};
	["PrivateMediaDocAccessFields_sizes_hero_jpeg_url_Read"]: {
		permission: boolean
};
	["PrivateMediaDocAccessFields_sizes_hero_jpeg_url_Update"]: {
		permission: boolean
};
	["PrivateMediaDocAccessFields_sizes_hero_jpeg_url_Delete"]: {
		permission: boolean
};
	["PrivateMediaDocAccessFields_sizes_hero_jpeg_width"]: {
		create?: ModelTypes["PrivateMediaDocAccessFields_sizes_hero_jpeg_width_Create"] | undefined,
	read?: ModelTypes["PrivateMediaDocAccessFields_sizes_hero_jpeg_width_Read"] | undefined,
	update?: ModelTypes["PrivateMediaDocAccessFields_sizes_hero_jpeg_width_Update"] | undefined,
	delete?: ModelTypes["PrivateMediaDocAccessFields_sizes_hero_jpeg_width_Delete"] | undefined
};
	["PrivateMediaDocAccessFields_sizes_hero_jpeg_width_Create"]: {
		permission: boolean
};
	["PrivateMediaDocAccessFields_sizes_hero_jpeg_width_Read"]: {
		permission: boolean
};
	["PrivateMediaDocAccessFields_sizes_hero_jpeg_width_Update"]: {
		permission: boolean
};
	["PrivateMediaDocAccessFields_sizes_hero_jpeg_width_Delete"]: {
		permission: boolean
};
	["PrivateMediaDocAccessFields_sizes_hero_jpeg_height"]: {
		create?: ModelTypes["PrivateMediaDocAccessFields_sizes_hero_jpeg_height_Create"] | undefined,
	read?: ModelTypes["PrivateMediaDocAccessFields_sizes_hero_jpeg_height_Read"] | undefined,
	update?: ModelTypes["PrivateMediaDocAccessFields_sizes_hero_jpeg_height_Update"] | undefined,
	delete?: ModelTypes["PrivateMediaDocAccessFields_sizes_hero_jpeg_height_Delete"] | undefined
};
	["PrivateMediaDocAccessFields_sizes_hero_jpeg_height_Create"]: {
		permission: boolean
};
	["PrivateMediaDocAccessFields_sizes_hero_jpeg_height_Read"]: {
		permission: boolean
};
	["PrivateMediaDocAccessFields_sizes_hero_jpeg_height_Update"]: {
		permission: boolean
};
	["PrivateMediaDocAccessFields_sizes_hero_jpeg_height_Delete"]: {
		permission: boolean
};
	["PrivateMediaDocAccessFields_sizes_hero_jpeg_mimeType"]: {
		create?: ModelTypes["PrivateMediaDocAccessFields_sizes_hero_jpeg_mimeType_Create"] | undefined,
	read?: ModelTypes["PrivateMediaDocAccessFields_sizes_hero_jpeg_mimeType_Read"] | undefined,
	update?: ModelTypes["PrivateMediaDocAccessFields_sizes_hero_jpeg_mimeType_Update"] | undefined,
	delete?: ModelTypes["PrivateMediaDocAccessFields_sizes_hero_jpeg_mimeType_Delete"] | undefined
};
	["PrivateMediaDocAccessFields_sizes_hero_jpeg_mimeType_Create"]: {
		permission: boolean
};
	["PrivateMediaDocAccessFields_sizes_hero_jpeg_mimeType_Read"]: {
		permission: boolean
};
	["PrivateMediaDocAccessFields_sizes_hero_jpeg_mimeType_Update"]: {
		permission: boolean
};
	["PrivateMediaDocAccessFields_sizes_hero_jpeg_mimeType_Delete"]: {
		permission: boolean
};
	["PrivateMediaDocAccessFields_sizes_hero_jpeg_filesize"]: {
		create?: ModelTypes["PrivateMediaDocAccessFields_sizes_hero_jpeg_filesize_Create"] | undefined,
	read?: ModelTypes["PrivateMediaDocAccessFields_sizes_hero_jpeg_filesize_Read"] | undefined,
	update?: ModelTypes["PrivateMediaDocAccessFields_sizes_hero_jpeg_filesize_Update"] | undefined,
	delete?: ModelTypes["PrivateMediaDocAccessFields_sizes_hero_jpeg_filesize_Delete"] | undefined
};
	["PrivateMediaDocAccessFields_sizes_hero_jpeg_filesize_Create"]: {
		permission: boolean
};
	["PrivateMediaDocAccessFields_sizes_hero_jpeg_filesize_Read"]: {
		permission: boolean
};
	["PrivateMediaDocAccessFields_sizes_hero_jpeg_filesize_Update"]: {
		permission: boolean
};
	["PrivateMediaDocAccessFields_sizes_hero_jpeg_filesize_Delete"]: {
		permission: boolean
};
	["PrivateMediaDocAccessFields_sizes_hero_jpeg_filename"]: {
		create?: ModelTypes["PrivateMediaDocAccessFields_sizes_hero_jpeg_filename_Create"] | undefined,
	read?: ModelTypes["PrivateMediaDocAccessFields_sizes_hero_jpeg_filename_Read"] | undefined,
	update?: ModelTypes["PrivateMediaDocAccessFields_sizes_hero_jpeg_filename_Update"] | undefined,
	delete?: ModelTypes["PrivateMediaDocAccessFields_sizes_hero_jpeg_filename_Delete"] | undefined
};
	["PrivateMediaDocAccessFields_sizes_hero_jpeg_filename_Create"]: {
		permission: boolean
};
	["PrivateMediaDocAccessFields_sizes_hero_jpeg_filename_Read"]: {
		permission: boolean
};
	["PrivateMediaDocAccessFields_sizes_hero_jpeg_filename_Update"]: {
		permission: boolean
};
	["PrivateMediaDocAccessFields_sizes_hero_jpeg_filename_Delete"]: {
		permission: boolean
};
	["PrivateMediaCreateDocAccess"]: {
		permission: boolean,
	where?: ModelTypes["JSONObject"] | undefined
};
	["PrivateMediaReadDocAccess"]: {
		permission: boolean,
	where?: ModelTypes["JSONObject"] | undefined
};
	["PrivateMediaUpdateDocAccess"]: {
		permission: boolean,
	where?: ModelTypes["JSONObject"] | undefined
};
	["PrivateMediaDeleteDocAccess"]: {
		permission: boolean,
	where?: ModelTypes["JSONObject"] | undefined
};
	["allMedia"]: {
		docs?: Array<ModelTypes["Media"] | undefined> | undefined,
	hasNextPage?: boolean | undefined,
	hasPrevPage?: boolean | undefined,
	limit?: number | undefined,
	nextPage?: number | undefined,
	offset?: number | undefined,
	page?: number | undefined,
	pagingCounter?: number | undefined,
	prevPage?: number | undefined,
	totalDocs?: number | undefined,
	totalPages?: number | undefined
};
	["Media_where"]: {
	alt?: ModelTypes["Media_alt_operator"] | undefined,
	updatedAt?: ModelTypes["Media_updatedAt_operator"] | undefined,
	createdAt?: ModelTypes["Media_createdAt_operator"] | undefined,
	url?: ModelTypes["Media_url_operator"] | undefined,
	filename?: ModelTypes["Media_filename_operator"] | undefined,
	mimeType?: ModelTypes["Media_mimeType_operator"] | undefined,
	filesize?: ModelTypes["Media_filesize_operator"] | undefined,
	width?: ModelTypes["Media_width_operator"] | undefined,
	height?: ModelTypes["Media_height_operator"] | undefined,
	focalX?: ModelTypes["Media_focalX_operator"] | undefined,
	focalY?: ModelTypes["Media_focalY_operator"] | undefined,
	sizes__small__url?: ModelTypes["Media_sizes__small__url_operator"] | undefined,
	sizes__small__width?: ModelTypes["Media_sizes__small__width_operator"] | undefined,
	sizes__small__height?: ModelTypes["Media_sizes__small__height_operator"] | undefined,
	sizes__small__mimeType?: ModelTypes["Media_sizes__small__mimeType_operator"] | undefined,
	sizes__small__filesize?: ModelTypes["Media_sizes__small__filesize_operator"] | undefined,
	sizes__small__filename?: ModelTypes["Media_sizes__small__filename_operator"] | undefined,
	sizes__medium__url?: ModelTypes["Media_sizes__medium__url_operator"] | undefined,
	sizes__medium__width?: ModelTypes["Media_sizes__medium__width_operator"] | undefined,
	sizes__medium__height?: ModelTypes["Media_sizes__medium__height_operator"] | undefined,
	sizes__medium__mimeType?: ModelTypes["Media_sizes__medium__mimeType_operator"] | undefined,
	sizes__medium__filesize?: ModelTypes["Media_sizes__medium__filesize_operator"] | undefined,
	sizes__medium__filename?: ModelTypes["Media_sizes__medium__filename_operator"] | undefined,
	sizes__large__url?: ModelTypes["Media_sizes__large__url_operator"] | undefined,
	sizes__large__width?: ModelTypes["Media_sizes__large__width_operator"] | undefined,
	sizes__large__height?: ModelTypes["Media_sizes__large__height_operator"] | undefined,
	sizes__large__mimeType?: ModelTypes["Media_sizes__large__mimeType_operator"] | undefined,
	sizes__large__filesize?: ModelTypes["Media_sizes__large__filesize_operator"] | undefined,
	sizes__large__filename?: ModelTypes["Media_sizes__large__filename_operator"] | undefined,
	sizes__small_jpeg__url?: ModelTypes["Media_sizes__small_jpeg__url_operator"] | undefined,
	sizes__small_jpeg__width?: ModelTypes["Media_sizes__small_jpeg__width_operator"] | undefined,
	sizes__small_jpeg__height?: ModelTypes["Media_sizes__small_jpeg__height_operator"] | undefined,
	sizes__small_jpeg__mimeType?: ModelTypes["Media_sizes__small_jpeg__mimeType_operator"] | undefined,
	sizes__small_jpeg__filesize?: ModelTypes["Media_sizes__small_jpeg__filesize_operator"] | undefined,
	sizes__small_jpeg__filename?: ModelTypes["Media_sizes__small_jpeg__filename_operator"] | undefined,
	sizes__medium_jpeg__url?: ModelTypes["Media_sizes__medium_jpeg__url_operator"] | undefined,
	sizes__medium_jpeg__width?: ModelTypes["Media_sizes__medium_jpeg__width_operator"] | undefined,
	sizes__medium_jpeg__height?: ModelTypes["Media_sizes__medium_jpeg__height_operator"] | undefined,
	sizes__medium_jpeg__mimeType?: ModelTypes["Media_sizes__medium_jpeg__mimeType_operator"] | undefined,
	sizes__medium_jpeg__filesize?: ModelTypes["Media_sizes__medium_jpeg__filesize_operator"] | undefined,
	sizes__medium_jpeg__filename?: ModelTypes["Media_sizes__medium_jpeg__filename_operator"] | undefined,
	sizes__large_jpeg__url?: ModelTypes["Media_sizes__large_jpeg__url_operator"] | undefined,
	sizes__large_jpeg__width?: ModelTypes["Media_sizes__large_jpeg__width_operator"] | undefined,
	sizes__large_jpeg__height?: ModelTypes["Media_sizes__large_jpeg__height_operator"] | undefined,
	sizes__large_jpeg__mimeType?: ModelTypes["Media_sizes__large_jpeg__mimeType_operator"] | undefined,
	sizes__large_jpeg__filesize?: ModelTypes["Media_sizes__large_jpeg__filesize_operator"] | undefined,
	sizes__large_jpeg__filename?: ModelTypes["Media_sizes__large_jpeg__filename_operator"] | undefined,
	id?: ModelTypes["Media_id_operator"] | undefined,
	AND?: Array<ModelTypes["Media_where_and"] | undefined> | undefined,
	OR?: Array<ModelTypes["Media_where_or"] | undefined> | undefined
};
	["Media_alt_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Media_updatedAt_operator"]: {
	equals?: ModelTypes["DateTime"] | undefined,
	not_equals?: ModelTypes["DateTime"] | undefined,
	greater_than_equal?: ModelTypes["DateTime"] | undefined,
	greater_than?: ModelTypes["DateTime"] | undefined,
	less_than_equal?: ModelTypes["DateTime"] | undefined,
	less_than?: ModelTypes["DateTime"] | undefined,
	like?: ModelTypes["DateTime"] | undefined,
	exists?: boolean | undefined
};
	["Media_createdAt_operator"]: {
	equals?: ModelTypes["DateTime"] | undefined,
	not_equals?: ModelTypes["DateTime"] | undefined,
	greater_than_equal?: ModelTypes["DateTime"] | undefined,
	greater_than?: ModelTypes["DateTime"] | undefined,
	less_than_equal?: ModelTypes["DateTime"] | undefined,
	less_than?: ModelTypes["DateTime"] | undefined,
	like?: ModelTypes["DateTime"] | undefined,
	exists?: boolean | undefined
};
	["Media_url_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Media_filename_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Media_mimeType_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Media_filesize_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Media_width_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Media_height_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Media_focalX_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Media_focalY_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Media_sizes__small__url_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Media_sizes__small__width_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Media_sizes__small__height_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Media_sizes__small__mimeType_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Media_sizes__small__filesize_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Media_sizes__small__filename_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Media_sizes__medium__url_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Media_sizes__medium__width_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Media_sizes__medium__height_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Media_sizes__medium__mimeType_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Media_sizes__medium__filesize_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Media_sizes__medium__filename_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Media_sizes__large__url_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Media_sizes__large__width_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Media_sizes__large__height_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Media_sizes__large__mimeType_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Media_sizes__large__filesize_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Media_sizes__large__filename_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Media_sizes__small_jpeg__url_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Media_sizes__small_jpeg__width_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Media_sizes__small_jpeg__height_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Media_sizes__small_jpeg__mimeType_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Media_sizes__small_jpeg__filesize_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Media_sizes__small_jpeg__filename_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Media_sizes__medium_jpeg__url_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Media_sizes__medium_jpeg__width_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Media_sizes__medium_jpeg__height_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Media_sizes__medium_jpeg__mimeType_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Media_sizes__medium_jpeg__filesize_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Media_sizes__medium_jpeg__filename_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Media_sizes__large_jpeg__url_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Media_sizes__large_jpeg__width_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Media_sizes__large_jpeg__height_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Media_sizes__large_jpeg__mimeType_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Media_sizes__large_jpeg__filesize_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Media_sizes__large_jpeg__filename_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Media_id_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Media_where_and"]: {
	alt?: ModelTypes["Media_alt_operator"] | undefined,
	updatedAt?: ModelTypes["Media_updatedAt_operator"] | undefined,
	createdAt?: ModelTypes["Media_createdAt_operator"] | undefined,
	url?: ModelTypes["Media_url_operator"] | undefined,
	filename?: ModelTypes["Media_filename_operator"] | undefined,
	mimeType?: ModelTypes["Media_mimeType_operator"] | undefined,
	filesize?: ModelTypes["Media_filesize_operator"] | undefined,
	width?: ModelTypes["Media_width_operator"] | undefined,
	height?: ModelTypes["Media_height_operator"] | undefined,
	focalX?: ModelTypes["Media_focalX_operator"] | undefined,
	focalY?: ModelTypes["Media_focalY_operator"] | undefined,
	sizes__small__url?: ModelTypes["Media_sizes__small__url_operator"] | undefined,
	sizes__small__width?: ModelTypes["Media_sizes__small__width_operator"] | undefined,
	sizes__small__height?: ModelTypes["Media_sizes__small__height_operator"] | undefined,
	sizes__small__mimeType?: ModelTypes["Media_sizes__small__mimeType_operator"] | undefined,
	sizes__small__filesize?: ModelTypes["Media_sizes__small__filesize_operator"] | undefined,
	sizes__small__filename?: ModelTypes["Media_sizes__small__filename_operator"] | undefined,
	sizes__medium__url?: ModelTypes["Media_sizes__medium__url_operator"] | undefined,
	sizes__medium__width?: ModelTypes["Media_sizes__medium__width_operator"] | undefined,
	sizes__medium__height?: ModelTypes["Media_sizes__medium__height_operator"] | undefined,
	sizes__medium__mimeType?: ModelTypes["Media_sizes__medium__mimeType_operator"] | undefined,
	sizes__medium__filesize?: ModelTypes["Media_sizes__medium__filesize_operator"] | undefined,
	sizes__medium__filename?: ModelTypes["Media_sizes__medium__filename_operator"] | undefined,
	sizes__large__url?: ModelTypes["Media_sizes__large__url_operator"] | undefined,
	sizes__large__width?: ModelTypes["Media_sizes__large__width_operator"] | undefined,
	sizes__large__height?: ModelTypes["Media_sizes__large__height_operator"] | undefined,
	sizes__large__mimeType?: ModelTypes["Media_sizes__large__mimeType_operator"] | undefined,
	sizes__large__filesize?: ModelTypes["Media_sizes__large__filesize_operator"] | undefined,
	sizes__large__filename?: ModelTypes["Media_sizes__large__filename_operator"] | undefined,
	sizes__small_jpeg__url?: ModelTypes["Media_sizes__small_jpeg__url_operator"] | undefined,
	sizes__small_jpeg__width?: ModelTypes["Media_sizes__small_jpeg__width_operator"] | undefined,
	sizes__small_jpeg__height?: ModelTypes["Media_sizes__small_jpeg__height_operator"] | undefined,
	sizes__small_jpeg__mimeType?: ModelTypes["Media_sizes__small_jpeg__mimeType_operator"] | undefined,
	sizes__small_jpeg__filesize?: ModelTypes["Media_sizes__small_jpeg__filesize_operator"] | undefined,
	sizes__small_jpeg__filename?: ModelTypes["Media_sizes__small_jpeg__filename_operator"] | undefined,
	sizes__medium_jpeg__url?: ModelTypes["Media_sizes__medium_jpeg__url_operator"] | undefined,
	sizes__medium_jpeg__width?: ModelTypes["Media_sizes__medium_jpeg__width_operator"] | undefined,
	sizes__medium_jpeg__height?: ModelTypes["Media_sizes__medium_jpeg__height_operator"] | undefined,
	sizes__medium_jpeg__mimeType?: ModelTypes["Media_sizes__medium_jpeg__mimeType_operator"] | undefined,
	sizes__medium_jpeg__filesize?: ModelTypes["Media_sizes__medium_jpeg__filesize_operator"] | undefined,
	sizes__medium_jpeg__filename?: ModelTypes["Media_sizes__medium_jpeg__filename_operator"] | undefined,
	sizes__large_jpeg__url?: ModelTypes["Media_sizes__large_jpeg__url_operator"] | undefined,
	sizes__large_jpeg__width?: ModelTypes["Media_sizes__large_jpeg__width_operator"] | undefined,
	sizes__large_jpeg__height?: ModelTypes["Media_sizes__large_jpeg__height_operator"] | undefined,
	sizes__large_jpeg__mimeType?: ModelTypes["Media_sizes__large_jpeg__mimeType_operator"] | undefined,
	sizes__large_jpeg__filesize?: ModelTypes["Media_sizes__large_jpeg__filesize_operator"] | undefined,
	sizes__large_jpeg__filename?: ModelTypes["Media_sizes__large_jpeg__filename_operator"] | undefined,
	id?: ModelTypes["Media_id_operator"] | undefined,
	AND?: Array<ModelTypes["Media_where_and"] | undefined> | undefined,
	OR?: Array<ModelTypes["Media_where_or"] | undefined> | undefined
};
	["Media_where_or"]: {
	alt?: ModelTypes["Media_alt_operator"] | undefined,
	updatedAt?: ModelTypes["Media_updatedAt_operator"] | undefined,
	createdAt?: ModelTypes["Media_createdAt_operator"] | undefined,
	url?: ModelTypes["Media_url_operator"] | undefined,
	filename?: ModelTypes["Media_filename_operator"] | undefined,
	mimeType?: ModelTypes["Media_mimeType_operator"] | undefined,
	filesize?: ModelTypes["Media_filesize_operator"] | undefined,
	width?: ModelTypes["Media_width_operator"] | undefined,
	height?: ModelTypes["Media_height_operator"] | undefined,
	focalX?: ModelTypes["Media_focalX_operator"] | undefined,
	focalY?: ModelTypes["Media_focalY_operator"] | undefined,
	sizes__small__url?: ModelTypes["Media_sizes__small__url_operator"] | undefined,
	sizes__small__width?: ModelTypes["Media_sizes__small__width_operator"] | undefined,
	sizes__small__height?: ModelTypes["Media_sizes__small__height_operator"] | undefined,
	sizes__small__mimeType?: ModelTypes["Media_sizes__small__mimeType_operator"] | undefined,
	sizes__small__filesize?: ModelTypes["Media_sizes__small__filesize_operator"] | undefined,
	sizes__small__filename?: ModelTypes["Media_sizes__small__filename_operator"] | undefined,
	sizes__medium__url?: ModelTypes["Media_sizes__medium__url_operator"] | undefined,
	sizes__medium__width?: ModelTypes["Media_sizes__medium__width_operator"] | undefined,
	sizes__medium__height?: ModelTypes["Media_sizes__medium__height_operator"] | undefined,
	sizes__medium__mimeType?: ModelTypes["Media_sizes__medium__mimeType_operator"] | undefined,
	sizes__medium__filesize?: ModelTypes["Media_sizes__medium__filesize_operator"] | undefined,
	sizes__medium__filename?: ModelTypes["Media_sizes__medium__filename_operator"] | undefined,
	sizes__large__url?: ModelTypes["Media_sizes__large__url_operator"] | undefined,
	sizes__large__width?: ModelTypes["Media_sizes__large__width_operator"] | undefined,
	sizes__large__height?: ModelTypes["Media_sizes__large__height_operator"] | undefined,
	sizes__large__mimeType?: ModelTypes["Media_sizes__large__mimeType_operator"] | undefined,
	sizes__large__filesize?: ModelTypes["Media_sizes__large__filesize_operator"] | undefined,
	sizes__large__filename?: ModelTypes["Media_sizes__large__filename_operator"] | undefined,
	sizes__small_jpeg__url?: ModelTypes["Media_sizes__small_jpeg__url_operator"] | undefined,
	sizes__small_jpeg__width?: ModelTypes["Media_sizes__small_jpeg__width_operator"] | undefined,
	sizes__small_jpeg__height?: ModelTypes["Media_sizes__small_jpeg__height_operator"] | undefined,
	sizes__small_jpeg__mimeType?: ModelTypes["Media_sizes__small_jpeg__mimeType_operator"] | undefined,
	sizes__small_jpeg__filesize?: ModelTypes["Media_sizes__small_jpeg__filesize_operator"] | undefined,
	sizes__small_jpeg__filename?: ModelTypes["Media_sizes__small_jpeg__filename_operator"] | undefined,
	sizes__medium_jpeg__url?: ModelTypes["Media_sizes__medium_jpeg__url_operator"] | undefined,
	sizes__medium_jpeg__width?: ModelTypes["Media_sizes__medium_jpeg__width_operator"] | undefined,
	sizes__medium_jpeg__height?: ModelTypes["Media_sizes__medium_jpeg__height_operator"] | undefined,
	sizes__medium_jpeg__mimeType?: ModelTypes["Media_sizes__medium_jpeg__mimeType_operator"] | undefined,
	sizes__medium_jpeg__filesize?: ModelTypes["Media_sizes__medium_jpeg__filesize_operator"] | undefined,
	sizes__medium_jpeg__filename?: ModelTypes["Media_sizes__medium_jpeg__filename_operator"] | undefined,
	sizes__large_jpeg__url?: ModelTypes["Media_sizes__large_jpeg__url_operator"] | undefined,
	sizes__large_jpeg__width?: ModelTypes["Media_sizes__large_jpeg__width_operator"] | undefined,
	sizes__large_jpeg__height?: ModelTypes["Media_sizes__large_jpeg__height_operator"] | undefined,
	sizes__large_jpeg__mimeType?: ModelTypes["Media_sizes__large_jpeg__mimeType_operator"] | undefined,
	sizes__large_jpeg__filesize?: ModelTypes["Media_sizes__large_jpeg__filesize_operator"] | undefined,
	sizes__large_jpeg__filename?: ModelTypes["Media_sizes__large_jpeg__filename_operator"] | undefined,
	id?: ModelTypes["Media_id_operator"] | undefined,
	AND?: Array<ModelTypes["Media_where_and"] | undefined> | undefined,
	OR?: Array<ModelTypes["Media_where_or"] | undefined> | undefined
};
	["countallMedia"]: {
		totalDocs?: number | undefined
};
	["mediaDocAccess"]: {
		fields?: ModelTypes["MediaDocAccessFields"] | undefined,
	create?: ModelTypes["MediaCreateDocAccess"] | undefined,
	read?: ModelTypes["MediaReadDocAccess"] | undefined,
	update?: ModelTypes["MediaUpdateDocAccess"] | undefined,
	delete?: ModelTypes["MediaDeleteDocAccess"] | undefined
};
	["MediaDocAccessFields"]: {
		alt?: ModelTypes["MediaDocAccessFields_alt"] | undefined,
	updatedAt?: ModelTypes["MediaDocAccessFields_updatedAt"] | undefined,
	createdAt?: ModelTypes["MediaDocAccessFields_createdAt"] | undefined,
	url?: ModelTypes["MediaDocAccessFields_url"] | undefined,
	filename?: ModelTypes["MediaDocAccessFields_filename"] | undefined,
	mimeType?: ModelTypes["MediaDocAccessFields_mimeType"] | undefined,
	filesize?: ModelTypes["MediaDocAccessFields_filesize"] | undefined,
	width?: ModelTypes["MediaDocAccessFields_width"] | undefined,
	height?: ModelTypes["MediaDocAccessFields_height"] | undefined,
	focalX?: ModelTypes["MediaDocAccessFields_focalX"] | undefined,
	focalY?: ModelTypes["MediaDocAccessFields_focalY"] | undefined,
	sizes?: ModelTypes["MediaDocAccessFields_sizes"] | undefined
};
	["MediaDocAccessFields_alt"]: {
		create?: ModelTypes["MediaDocAccessFields_alt_Create"] | undefined,
	read?: ModelTypes["MediaDocAccessFields_alt_Read"] | undefined,
	update?: ModelTypes["MediaDocAccessFields_alt_Update"] | undefined,
	delete?: ModelTypes["MediaDocAccessFields_alt_Delete"] | undefined
};
	["MediaDocAccessFields_alt_Create"]: {
		permission: boolean
};
	["MediaDocAccessFields_alt_Read"]: {
		permission: boolean
};
	["MediaDocAccessFields_alt_Update"]: {
		permission: boolean
};
	["MediaDocAccessFields_alt_Delete"]: {
		permission: boolean
};
	["MediaDocAccessFields_updatedAt"]: {
		create?: ModelTypes["MediaDocAccessFields_updatedAt_Create"] | undefined,
	read?: ModelTypes["MediaDocAccessFields_updatedAt_Read"] | undefined,
	update?: ModelTypes["MediaDocAccessFields_updatedAt_Update"] | undefined,
	delete?: ModelTypes["MediaDocAccessFields_updatedAt_Delete"] | undefined
};
	["MediaDocAccessFields_updatedAt_Create"]: {
		permission: boolean
};
	["MediaDocAccessFields_updatedAt_Read"]: {
		permission: boolean
};
	["MediaDocAccessFields_updatedAt_Update"]: {
		permission: boolean
};
	["MediaDocAccessFields_updatedAt_Delete"]: {
		permission: boolean
};
	["MediaDocAccessFields_createdAt"]: {
		create?: ModelTypes["MediaDocAccessFields_createdAt_Create"] | undefined,
	read?: ModelTypes["MediaDocAccessFields_createdAt_Read"] | undefined,
	update?: ModelTypes["MediaDocAccessFields_createdAt_Update"] | undefined,
	delete?: ModelTypes["MediaDocAccessFields_createdAt_Delete"] | undefined
};
	["MediaDocAccessFields_createdAt_Create"]: {
		permission: boolean
};
	["MediaDocAccessFields_createdAt_Read"]: {
		permission: boolean
};
	["MediaDocAccessFields_createdAt_Update"]: {
		permission: boolean
};
	["MediaDocAccessFields_createdAt_Delete"]: {
		permission: boolean
};
	["MediaDocAccessFields_url"]: {
		create?: ModelTypes["MediaDocAccessFields_url_Create"] | undefined,
	read?: ModelTypes["MediaDocAccessFields_url_Read"] | undefined,
	update?: ModelTypes["MediaDocAccessFields_url_Update"] | undefined,
	delete?: ModelTypes["MediaDocAccessFields_url_Delete"] | undefined
};
	["MediaDocAccessFields_url_Create"]: {
		permission: boolean
};
	["MediaDocAccessFields_url_Read"]: {
		permission: boolean
};
	["MediaDocAccessFields_url_Update"]: {
		permission: boolean
};
	["MediaDocAccessFields_url_Delete"]: {
		permission: boolean
};
	["MediaDocAccessFields_filename"]: {
		create?: ModelTypes["MediaDocAccessFields_filename_Create"] | undefined,
	read?: ModelTypes["MediaDocAccessFields_filename_Read"] | undefined,
	update?: ModelTypes["MediaDocAccessFields_filename_Update"] | undefined,
	delete?: ModelTypes["MediaDocAccessFields_filename_Delete"] | undefined
};
	["MediaDocAccessFields_filename_Create"]: {
		permission: boolean
};
	["MediaDocAccessFields_filename_Read"]: {
		permission: boolean
};
	["MediaDocAccessFields_filename_Update"]: {
		permission: boolean
};
	["MediaDocAccessFields_filename_Delete"]: {
		permission: boolean
};
	["MediaDocAccessFields_mimeType"]: {
		create?: ModelTypes["MediaDocAccessFields_mimeType_Create"] | undefined,
	read?: ModelTypes["MediaDocAccessFields_mimeType_Read"] | undefined,
	update?: ModelTypes["MediaDocAccessFields_mimeType_Update"] | undefined,
	delete?: ModelTypes["MediaDocAccessFields_mimeType_Delete"] | undefined
};
	["MediaDocAccessFields_mimeType_Create"]: {
		permission: boolean
};
	["MediaDocAccessFields_mimeType_Read"]: {
		permission: boolean
};
	["MediaDocAccessFields_mimeType_Update"]: {
		permission: boolean
};
	["MediaDocAccessFields_mimeType_Delete"]: {
		permission: boolean
};
	["MediaDocAccessFields_filesize"]: {
		create?: ModelTypes["MediaDocAccessFields_filesize_Create"] | undefined,
	read?: ModelTypes["MediaDocAccessFields_filesize_Read"] | undefined,
	update?: ModelTypes["MediaDocAccessFields_filesize_Update"] | undefined,
	delete?: ModelTypes["MediaDocAccessFields_filesize_Delete"] | undefined
};
	["MediaDocAccessFields_filesize_Create"]: {
		permission: boolean
};
	["MediaDocAccessFields_filesize_Read"]: {
		permission: boolean
};
	["MediaDocAccessFields_filesize_Update"]: {
		permission: boolean
};
	["MediaDocAccessFields_filesize_Delete"]: {
		permission: boolean
};
	["MediaDocAccessFields_width"]: {
		create?: ModelTypes["MediaDocAccessFields_width_Create"] | undefined,
	read?: ModelTypes["MediaDocAccessFields_width_Read"] | undefined,
	update?: ModelTypes["MediaDocAccessFields_width_Update"] | undefined,
	delete?: ModelTypes["MediaDocAccessFields_width_Delete"] | undefined
};
	["MediaDocAccessFields_width_Create"]: {
		permission: boolean
};
	["MediaDocAccessFields_width_Read"]: {
		permission: boolean
};
	["MediaDocAccessFields_width_Update"]: {
		permission: boolean
};
	["MediaDocAccessFields_width_Delete"]: {
		permission: boolean
};
	["MediaDocAccessFields_height"]: {
		create?: ModelTypes["MediaDocAccessFields_height_Create"] | undefined,
	read?: ModelTypes["MediaDocAccessFields_height_Read"] | undefined,
	update?: ModelTypes["MediaDocAccessFields_height_Update"] | undefined,
	delete?: ModelTypes["MediaDocAccessFields_height_Delete"] | undefined
};
	["MediaDocAccessFields_height_Create"]: {
		permission: boolean
};
	["MediaDocAccessFields_height_Read"]: {
		permission: boolean
};
	["MediaDocAccessFields_height_Update"]: {
		permission: boolean
};
	["MediaDocAccessFields_height_Delete"]: {
		permission: boolean
};
	["MediaDocAccessFields_focalX"]: {
		create?: ModelTypes["MediaDocAccessFields_focalX_Create"] | undefined,
	read?: ModelTypes["MediaDocAccessFields_focalX_Read"] | undefined,
	update?: ModelTypes["MediaDocAccessFields_focalX_Update"] | undefined,
	delete?: ModelTypes["MediaDocAccessFields_focalX_Delete"] | undefined
};
	["MediaDocAccessFields_focalX_Create"]: {
		permission: boolean
};
	["MediaDocAccessFields_focalX_Read"]: {
		permission: boolean
};
	["MediaDocAccessFields_focalX_Update"]: {
		permission: boolean
};
	["MediaDocAccessFields_focalX_Delete"]: {
		permission: boolean
};
	["MediaDocAccessFields_focalY"]: {
		create?: ModelTypes["MediaDocAccessFields_focalY_Create"] | undefined,
	read?: ModelTypes["MediaDocAccessFields_focalY_Read"] | undefined,
	update?: ModelTypes["MediaDocAccessFields_focalY_Update"] | undefined,
	delete?: ModelTypes["MediaDocAccessFields_focalY_Delete"] | undefined
};
	["MediaDocAccessFields_focalY_Create"]: {
		permission: boolean
};
	["MediaDocAccessFields_focalY_Read"]: {
		permission: boolean
};
	["MediaDocAccessFields_focalY_Update"]: {
		permission: boolean
};
	["MediaDocAccessFields_focalY_Delete"]: {
		permission: boolean
};
	["MediaDocAccessFields_sizes"]: {
		create?: ModelTypes["MediaDocAccessFields_sizes_Create"] | undefined,
	read?: ModelTypes["MediaDocAccessFields_sizes_Read"] | undefined,
	update?: ModelTypes["MediaDocAccessFields_sizes_Update"] | undefined,
	delete?: ModelTypes["MediaDocAccessFields_sizes_Delete"] | undefined,
	fields?: ModelTypes["MediaDocAccessFields_sizes_Fields"] | undefined
};
	["MediaDocAccessFields_sizes_Create"]: {
		permission: boolean
};
	["MediaDocAccessFields_sizes_Read"]: {
		permission: boolean
};
	["MediaDocAccessFields_sizes_Update"]: {
		permission: boolean
};
	["MediaDocAccessFields_sizes_Delete"]: {
		permission: boolean
};
	["MediaDocAccessFields_sizes_Fields"]: {
		small?: ModelTypes["MediaDocAccessFields_sizes_small"] | undefined,
	medium?: ModelTypes["MediaDocAccessFields_sizes_medium"] | undefined,
	large?: ModelTypes["MediaDocAccessFields_sizes_large"] | undefined,
	small_jpeg?: ModelTypes["MediaDocAccessFields_sizes_small_jpeg"] | undefined,
	medium_jpeg?: ModelTypes["MediaDocAccessFields_sizes_medium_jpeg"] | undefined,
	large_jpeg?: ModelTypes["MediaDocAccessFields_sizes_large_jpeg"] | undefined
};
	["MediaDocAccessFields_sizes_small"]: {
		create?: ModelTypes["MediaDocAccessFields_sizes_small_Create"] | undefined,
	read?: ModelTypes["MediaDocAccessFields_sizes_small_Read"] | undefined,
	update?: ModelTypes["MediaDocAccessFields_sizes_small_Update"] | undefined,
	delete?: ModelTypes["MediaDocAccessFields_sizes_small_Delete"] | undefined,
	fields?: ModelTypes["MediaDocAccessFields_sizes_small_Fields"] | undefined
};
	["MediaDocAccessFields_sizes_small_Create"]: {
		permission: boolean
};
	["MediaDocAccessFields_sizes_small_Read"]: {
		permission: boolean
};
	["MediaDocAccessFields_sizes_small_Update"]: {
		permission: boolean
};
	["MediaDocAccessFields_sizes_small_Delete"]: {
		permission: boolean
};
	["MediaDocAccessFields_sizes_small_Fields"]: {
		url?: ModelTypes["MediaDocAccessFields_sizes_small_url"] | undefined,
	width?: ModelTypes["MediaDocAccessFields_sizes_small_width"] | undefined,
	height?: ModelTypes["MediaDocAccessFields_sizes_small_height"] | undefined,
	mimeType?: ModelTypes["MediaDocAccessFields_sizes_small_mimeType"] | undefined,
	filesize?: ModelTypes["MediaDocAccessFields_sizes_small_filesize"] | undefined,
	filename?: ModelTypes["MediaDocAccessFields_sizes_small_filename"] | undefined
};
	["MediaDocAccessFields_sizes_small_url"]: {
		create?: ModelTypes["MediaDocAccessFields_sizes_small_url_Create"] | undefined,
	read?: ModelTypes["MediaDocAccessFields_sizes_small_url_Read"] | undefined,
	update?: ModelTypes["MediaDocAccessFields_sizes_small_url_Update"] | undefined,
	delete?: ModelTypes["MediaDocAccessFields_sizes_small_url_Delete"] | undefined
};
	["MediaDocAccessFields_sizes_small_url_Create"]: {
		permission: boolean
};
	["MediaDocAccessFields_sizes_small_url_Read"]: {
		permission: boolean
};
	["MediaDocAccessFields_sizes_small_url_Update"]: {
		permission: boolean
};
	["MediaDocAccessFields_sizes_small_url_Delete"]: {
		permission: boolean
};
	["MediaDocAccessFields_sizes_small_width"]: {
		create?: ModelTypes["MediaDocAccessFields_sizes_small_width_Create"] | undefined,
	read?: ModelTypes["MediaDocAccessFields_sizes_small_width_Read"] | undefined,
	update?: ModelTypes["MediaDocAccessFields_sizes_small_width_Update"] | undefined,
	delete?: ModelTypes["MediaDocAccessFields_sizes_small_width_Delete"] | undefined
};
	["MediaDocAccessFields_sizes_small_width_Create"]: {
		permission: boolean
};
	["MediaDocAccessFields_sizes_small_width_Read"]: {
		permission: boolean
};
	["MediaDocAccessFields_sizes_small_width_Update"]: {
		permission: boolean
};
	["MediaDocAccessFields_sizes_small_width_Delete"]: {
		permission: boolean
};
	["MediaDocAccessFields_sizes_small_height"]: {
		create?: ModelTypes["MediaDocAccessFields_sizes_small_height_Create"] | undefined,
	read?: ModelTypes["MediaDocAccessFields_sizes_small_height_Read"] | undefined,
	update?: ModelTypes["MediaDocAccessFields_sizes_small_height_Update"] | undefined,
	delete?: ModelTypes["MediaDocAccessFields_sizes_small_height_Delete"] | undefined
};
	["MediaDocAccessFields_sizes_small_height_Create"]: {
		permission: boolean
};
	["MediaDocAccessFields_sizes_small_height_Read"]: {
		permission: boolean
};
	["MediaDocAccessFields_sizes_small_height_Update"]: {
		permission: boolean
};
	["MediaDocAccessFields_sizes_small_height_Delete"]: {
		permission: boolean
};
	["MediaDocAccessFields_sizes_small_mimeType"]: {
		create?: ModelTypes["MediaDocAccessFields_sizes_small_mimeType_Create"] | undefined,
	read?: ModelTypes["MediaDocAccessFields_sizes_small_mimeType_Read"] | undefined,
	update?: ModelTypes["MediaDocAccessFields_sizes_small_mimeType_Update"] | undefined,
	delete?: ModelTypes["MediaDocAccessFields_sizes_small_mimeType_Delete"] | undefined
};
	["MediaDocAccessFields_sizes_small_mimeType_Create"]: {
		permission: boolean
};
	["MediaDocAccessFields_sizes_small_mimeType_Read"]: {
		permission: boolean
};
	["MediaDocAccessFields_sizes_small_mimeType_Update"]: {
		permission: boolean
};
	["MediaDocAccessFields_sizes_small_mimeType_Delete"]: {
		permission: boolean
};
	["MediaDocAccessFields_sizes_small_filesize"]: {
		create?: ModelTypes["MediaDocAccessFields_sizes_small_filesize_Create"] | undefined,
	read?: ModelTypes["MediaDocAccessFields_sizes_small_filesize_Read"] | undefined,
	update?: ModelTypes["MediaDocAccessFields_sizes_small_filesize_Update"] | undefined,
	delete?: ModelTypes["MediaDocAccessFields_sizes_small_filesize_Delete"] | undefined
};
	["MediaDocAccessFields_sizes_small_filesize_Create"]: {
		permission: boolean
};
	["MediaDocAccessFields_sizes_small_filesize_Read"]: {
		permission: boolean
};
	["MediaDocAccessFields_sizes_small_filesize_Update"]: {
		permission: boolean
};
	["MediaDocAccessFields_sizes_small_filesize_Delete"]: {
		permission: boolean
};
	["MediaDocAccessFields_sizes_small_filename"]: {
		create?: ModelTypes["MediaDocAccessFields_sizes_small_filename_Create"] | undefined,
	read?: ModelTypes["MediaDocAccessFields_sizes_small_filename_Read"] | undefined,
	update?: ModelTypes["MediaDocAccessFields_sizes_small_filename_Update"] | undefined,
	delete?: ModelTypes["MediaDocAccessFields_sizes_small_filename_Delete"] | undefined
};
	["MediaDocAccessFields_sizes_small_filename_Create"]: {
		permission: boolean
};
	["MediaDocAccessFields_sizes_small_filename_Read"]: {
		permission: boolean
};
	["MediaDocAccessFields_sizes_small_filename_Update"]: {
		permission: boolean
};
	["MediaDocAccessFields_sizes_small_filename_Delete"]: {
		permission: boolean
};
	["MediaDocAccessFields_sizes_medium"]: {
		create?: ModelTypes["MediaDocAccessFields_sizes_medium_Create"] | undefined,
	read?: ModelTypes["MediaDocAccessFields_sizes_medium_Read"] | undefined,
	update?: ModelTypes["MediaDocAccessFields_sizes_medium_Update"] | undefined,
	delete?: ModelTypes["MediaDocAccessFields_sizes_medium_Delete"] | undefined,
	fields?: ModelTypes["MediaDocAccessFields_sizes_medium_Fields"] | undefined
};
	["MediaDocAccessFields_sizes_medium_Create"]: {
		permission: boolean
};
	["MediaDocAccessFields_sizes_medium_Read"]: {
		permission: boolean
};
	["MediaDocAccessFields_sizes_medium_Update"]: {
		permission: boolean
};
	["MediaDocAccessFields_sizes_medium_Delete"]: {
		permission: boolean
};
	["MediaDocAccessFields_sizes_medium_Fields"]: {
		url?: ModelTypes["MediaDocAccessFields_sizes_medium_url"] | undefined,
	width?: ModelTypes["MediaDocAccessFields_sizes_medium_width"] | undefined,
	height?: ModelTypes["MediaDocAccessFields_sizes_medium_height"] | undefined,
	mimeType?: ModelTypes["MediaDocAccessFields_sizes_medium_mimeType"] | undefined,
	filesize?: ModelTypes["MediaDocAccessFields_sizes_medium_filesize"] | undefined,
	filename?: ModelTypes["MediaDocAccessFields_sizes_medium_filename"] | undefined
};
	["MediaDocAccessFields_sizes_medium_url"]: {
		create?: ModelTypes["MediaDocAccessFields_sizes_medium_url_Create"] | undefined,
	read?: ModelTypes["MediaDocAccessFields_sizes_medium_url_Read"] | undefined,
	update?: ModelTypes["MediaDocAccessFields_sizes_medium_url_Update"] | undefined,
	delete?: ModelTypes["MediaDocAccessFields_sizes_medium_url_Delete"] | undefined
};
	["MediaDocAccessFields_sizes_medium_url_Create"]: {
		permission: boolean
};
	["MediaDocAccessFields_sizes_medium_url_Read"]: {
		permission: boolean
};
	["MediaDocAccessFields_sizes_medium_url_Update"]: {
		permission: boolean
};
	["MediaDocAccessFields_sizes_medium_url_Delete"]: {
		permission: boolean
};
	["MediaDocAccessFields_sizes_medium_width"]: {
		create?: ModelTypes["MediaDocAccessFields_sizes_medium_width_Create"] | undefined,
	read?: ModelTypes["MediaDocAccessFields_sizes_medium_width_Read"] | undefined,
	update?: ModelTypes["MediaDocAccessFields_sizes_medium_width_Update"] | undefined,
	delete?: ModelTypes["MediaDocAccessFields_sizes_medium_width_Delete"] | undefined
};
	["MediaDocAccessFields_sizes_medium_width_Create"]: {
		permission: boolean
};
	["MediaDocAccessFields_sizes_medium_width_Read"]: {
		permission: boolean
};
	["MediaDocAccessFields_sizes_medium_width_Update"]: {
		permission: boolean
};
	["MediaDocAccessFields_sizes_medium_width_Delete"]: {
		permission: boolean
};
	["MediaDocAccessFields_sizes_medium_height"]: {
		create?: ModelTypes["MediaDocAccessFields_sizes_medium_height_Create"] | undefined,
	read?: ModelTypes["MediaDocAccessFields_sizes_medium_height_Read"] | undefined,
	update?: ModelTypes["MediaDocAccessFields_sizes_medium_height_Update"] | undefined,
	delete?: ModelTypes["MediaDocAccessFields_sizes_medium_height_Delete"] | undefined
};
	["MediaDocAccessFields_sizes_medium_height_Create"]: {
		permission: boolean
};
	["MediaDocAccessFields_sizes_medium_height_Read"]: {
		permission: boolean
};
	["MediaDocAccessFields_sizes_medium_height_Update"]: {
		permission: boolean
};
	["MediaDocAccessFields_sizes_medium_height_Delete"]: {
		permission: boolean
};
	["MediaDocAccessFields_sizes_medium_mimeType"]: {
		create?: ModelTypes["MediaDocAccessFields_sizes_medium_mimeType_Create"] | undefined,
	read?: ModelTypes["MediaDocAccessFields_sizes_medium_mimeType_Read"] | undefined,
	update?: ModelTypes["MediaDocAccessFields_sizes_medium_mimeType_Update"] | undefined,
	delete?: ModelTypes["MediaDocAccessFields_sizes_medium_mimeType_Delete"] | undefined
};
	["MediaDocAccessFields_sizes_medium_mimeType_Create"]: {
		permission: boolean
};
	["MediaDocAccessFields_sizes_medium_mimeType_Read"]: {
		permission: boolean
};
	["MediaDocAccessFields_sizes_medium_mimeType_Update"]: {
		permission: boolean
};
	["MediaDocAccessFields_sizes_medium_mimeType_Delete"]: {
		permission: boolean
};
	["MediaDocAccessFields_sizes_medium_filesize"]: {
		create?: ModelTypes["MediaDocAccessFields_sizes_medium_filesize_Create"] | undefined,
	read?: ModelTypes["MediaDocAccessFields_sizes_medium_filesize_Read"] | undefined,
	update?: ModelTypes["MediaDocAccessFields_sizes_medium_filesize_Update"] | undefined,
	delete?: ModelTypes["MediaDocAccessFields_sizes_medium_filesize_Delete"] | undefined
};
	["MediaDocAccessFields_sizes_medium_filesize_Create"]: {
		permission: boolean
};
	["MediaDocAccessFields_sizes_medium_filesize_Read"]: {
		permission: boolean
};
	["MediaDocAccessFields_sizes_medium_filesize_Update"]: {
		permission: boolean
};
	["MediaDocAccessFields_sizes_medium_filesize_Delete"]: {
		permission: boolean
};
	["MediaDocAccessFields_sizes_medium_filename"]: {
		create?: ModelTypes["MediaDocAccessFields_sizes_medium_filename_Create"] | undefined,
	read?: ModelTypes["MediaDocAccessFields_sizes_medium_filename_Read"] | undefined,
	update?: ModelTypes["MediaDocAccessFields_sizes_medium_filename_Update"] | undefined,
	delete?: ModelTypes["MediaDocAccessFields_sizes_medium_filename_Delete"] | undefined
};
	["MediaDocAccessFields_sizes_medium_filename_Create"]: {
		permission: boolean
};
	["MediaDocAccessFields_sizes_medium_filename_Read"]: {
		permission: boolean
};
	["MediaDocAccessFields_sizes_medium_filename_Update"]: {
		permission: boolean
};
	["MediaDocAccessFields_sizes_medium_filename_Delete"]: {
		permission: boolean
};
	["MediaDocAccessFields_sizes_large"]: {
		create?: ModelTypes["MediaDocAccessFields_sizes_large_Create"] | undefined,
	read?: ModelTypes["MediaDocAccessFields_sizes_large_Read"] | undefined,
	update?: ModelTypes["MediaDocAccessFields_sizes_large_Update"] | undefined,
	delete?: ModelTypes["MediaDocAccessFields_sizes_large_Delete"] | undefined,
	fields?: ModelTypes["MediaDocAccessFields_sizes_large_Fields"] | undefined
};
	["MediaDocAccessFields_sizes_large_Create"]: {
		permission: boolean
};
	["MediaDocAccessFields_sizes_large_Read"]: {
		permission: boolean
};
	["MediaDocAccessFields_sizes_large_Update"]: {
		permission: boolean
};
	["MediaDocAccessFields_sizes_large_Delete"]: {
		permission: boolean
};
	["MediaDocAccessFields_sizes_large_Fields"]: {
		url?: ModelTypes["MediaDocAccessFields_sizes_large_url"] | undefined,
	width?: ModelTypes["MediaDocAccessFields_sizes_large_width"] | undefined,
	height?: ModelTypes["MediaDocAccessFields_sizes_large_height"] | undefined,
	mimeType?: ModelTypes["MediaDocAccessFields_sizes_large_mimeType"] | undefined,
	filesize?: ModelTypes["MediaDocAccessFields_sizes_large_filesize"] | undefined,
	filename?: ModelTypes["MediaDocAccessFields_sizes_large_filename"] | undefined
};
	["MediaDocAccessFields_sizes_large_url"]: {
		create?: ModelTypes["MediaDocAccessFields_sizes_large_url_Create"] | undefined,
	read?: ModelTypes["MediaDocAccessFields_sizes_large_url_Read"] | undefined,
	update?: ModelTypes["MediaDocAccessFields_sizes_large_url_Update"] | undefined,
	delete?: ModelTypes["MediaDocAccessFields_sizes_large_url_Delete"] | undefined
};
	["MediaDocAccessFields_sizes_large_url_Create"]: {
		permission: boolean
};
	["MediaDocAccessFields_sizes_large_url_Read"]: {
		permission: boolean
};
	["MediaDocAccessFields_sizes_large_url_Update"]: {
		permission: boolean
};
	["MediaDocAccessFields_sizes_large_url_Delete"]: {
		permission: boolean
};
	["MediaDocAccessFields_sizes_large_width"]: {
		create?: ModelTypes["MediaDocAccessFields_sizes_large_width_Create"] | undefined,
	read?: ModelTypes["MediaDocAccessFields_sizes_large_width_Read"] | undefined,
	update?: ModelTypes["MediaDocAccessFields_sizes_large_width_Update"] | undefined,
	delete?: ModelTypes["MediaDocAccessFields_sizes_large_width_Delete"] | undefined
};
	["MediaDocAccessFields_sizes_large_width_Create"]: {
		permission: boolean
};
	["MediaDocAccessFields_sizes_large_width_Read"]: {
		permission: boolean
};
	["MediaDocAccessFields_sizes_large_width_Update"]: {
		permission: boolean
};
	["MediaDocAccessFields_sizes_large_width_Delete"]: {
		permission: boolean
};
	["MediaDocAccessFields_sizes_large_height"]: {
		create?: ModelTypes["MediaDocAccessFields_sizes_large_height_Create"] | undefined,
	read?: ModelTypes["MediaDocAccessFields_sizes_large_height_Read"] | undefined,
	update?: ModelTypes["MediaDocAccessFields_sizes_large_height_Update"] | undefined,
	delete?: ModelTypes["MediaDocAccessFields_sizes_large_height_Delete"] | undefined
};
	["MediaDocAccessFields_sizes_large_height_Create"]: {
		permission: boolean
};
	["MediaDocAccessFields_sizes_large_height_Read"]: {
		permission: boolean
};
	["MediaDocAccessFields_sizes_large_height_Update"]: {
		permission: boolean
};
	["MediaDocAccessFields_sizes_large_height_Delete"]: {
		permission: boolean
};
	["MediaDocAccessFields_sizes_large_mimeType"]: {
		create?: ModelTypes["MediaDocAccessFields_sizes_large_mimeType_Create"] | undefined,
	read?: ModelTypes["MediaDocAccessFields_sizes_large_mimeType_Read"] | undefined,
	update?: ModelTypes["MediaDocAccessFields_sizes_large_mimeType_Update"] | undefined,
	delete?: ModelTypes["MediaDocAccessFields_sizes_large_mimeType_Delete"] | undefined
};
	["MediaDocAccessFields_sizes_large_mimeType_Create"]: {
		permission: boolean
};
	["MediaDocAccessFields_sizes_large_mimeType_Read"]: {
		permission: boolean
};
	["MediaDocAccessFields_sizes_large_mimeType_Update"]: {
		permission: boolean
};
	["MediaDocAccessFields_sizes_large_mimeType_Delete"]: {
		permission: boolean
};
	["MediaDocAccessFields_sizes_large_filesize"]: {
		create?: ModelTypes["MediaDocAccessFields_sizes_large_filesize_Create"] | undefined,
	read?: ModelTypes["MediaDocAccessFields_sizes_large_filesize_Read"] | undefined,
	update?: ModelTypes["MediaDocAccessFields_sizes_large_filesize_Update"] | undefined,
	delete?: ModelTypes["MediaDocAccessFields_sizes_large_filesize_Delete"] | undefined
};
	["MediaDocAccessFields_sizes_large_filesize_Create"]: {
		permission: boolean
};
	["MediaDocAccessFields_sizes_large_filesize_Read"]: {
		permission: boolean
};
	["MediaDocAccessFields_sizes_large_filesize_Update"]: {
		permission: boolean
};
	["MediaDocAccessFields_sizes_large_filesize_Delete"]: {
		permission: boolean
};
	["MediaDocAccessFields_sizes_large_filename"]: {
		create?: ModelTypes["MediaDocAccessFields_sizes_large_filename_Create"] | undefined,
	read?: ModelTypes["MediaDocAccessFields_sizes_large_filename_Read"] | undefined,
	update?: ModelTypes["MediaDocAccessFields_sizes_large_filename_Update"] | undefined,
	delete?: ModelTypes["MediaDocAccessFields_sizes_large_filename_Delete"] | undefined
};
	["MediaDocAccessFields_sizes_large_filename_Create"]: {
		permission: boolean
};
	["MediaDocAccessFields_sizes_large_filename_Read"]: {
		permission: boolean
};
	["MediaDocAccessFields_sizes_large_filename_Update"]: {
		permission: boolean
};
	["MediaDocAccessFields_sizes_large_filename_Delete"]: {
		permission: boolean
};
	["MediaDocAccessFields_sizes_small_jpeg"]: {
		create?: ModelTypes["MediaDocAccessFields_sizes_small_jpeg_Create"] | undefined,
	read?: ModelTypes["MediaDocAccessFields_sizes_small_jpeg_Read"] | undefined,
	update?: ModelTypes["MediaDocAccessFields_sizes_small_jpeg_Update"] | undefined,
	delete?: ModelTypes["MediaDocAccessFields_sizes_small_jpeg_Delete"] | undefined,
	fields?: ModelTypes["MediaDocAccessFields_sizes_small_jpeg_Fields"] | undefined
};
	["MediaDocAccessFields_sizes_small_jpeg_Create"]: {
		permission: boolean
};
	["MediaDocAccessFields_sizes_small_jpeg_Read"]: {
		permission: boolean
};
	["MediaDocAccessFields_sizes_small_jpeg_Update"]: {
		permission: boolean
};
	["MediaDocAccessFields_sizes_small_jpeg_Delete"]: {
		permission: boolean
};
	["MediaDocAccessFields_sizes_small_jpeg_Fields"]: {
		url?: ModelTypes["MediaDocAccessFields_sizes_small_jpeg_url"] | undefined,
	width?: ModelTypes["MediaDocAccessFields_sizes_small_jpeg_width"] | undefined,
	height?: ModelTypes["MediaDocAccessFields_sizes_small_jpeg_height"] | undefined,
	mimeType?: ModelTypes["MediaDocAccessFields_sizes_small_jpeg_mimeType"] | undefined,
	filesize?: ModelTypes["MediaDocAccessFields_sizes_small_jpeg_filesize"] | undefined,
	filename?: ModelTypes["MediaDocAccessFields_sizes_small_jpeg_filename"] | undefined
};
	["MediaDocAccessFields_sizes_small_jpeg_url"]: {
		create?: ModelTypes["MediaDocAccessFields_sizes_small_jpeg_url_Create"] | undefined,
	read?: ModelTypes["MediaDocAccessFields_sizes_small_jpeg_url_Read"] | undefined,
	update?: ModelTypes["MediaDocAccessFields_sizes_small_jpeg_url_Update"] | undefined,
	delete?: ModelTypes["MediaDocAccessFields_sizes_small_jpeg_url_Delete"] | undefined
};
	["MediaDocAccessFields_sizes_small_jpeg_url_Create"]: {
		permission: boolean
};
	["MediaDocAccessFields_sizes_small_jpeg_url_Read"]: {
		permission: boolean
};
	["MediaDocAccessFields_sizes_small_jpeg_url_Update"]: {
		permission: boolean
};
	["MediaDocAccessFields_sizes_small_jpeg_url_Delete"]: {
		permission: boolean
};
	["MediaDocAccessFields_sizes_small_jpeg_width"]: {
		create?: ModelTypes["MediaDocAccessFields_sizes_small_jpeg_width_Create"] | undefined,
	read?: ModelTypes["MediaDocAccessFields_sizes_small_jpeg_width_Read"] | undefined,
	update?: ModelTypes["MediaDocAccessFields_sizes_small_jpeg_width_Update"] | undefined,
	delete?: ModelTypes["MediaDocAccessFields_sizes_small_jpeg_width_Delete"] | undefined
};
	["MediaDocAccessFields_sizes_small_jpeg_width_Create"]: {
		permission: boolean
};
	["MediaDocAccessFields_sizes_small_jpeg_width_Read"]: {
		permission: boolean
};
	["MediaDocAccessFields_sizes_small_jpeg_width_Update"]: {
		permission: boolean
};
	["MediaDocAccessFields_sizes_small_jpeg_width_Delete"]: {
		permission: boolean
};
	["MediaDocAccessFields_sizes_small_jpeg_height"]: {
		create?: ModelTypes["MediaDocAccessFields_sizes_small_jpeg_height_Create"] | undefined,
	read?: ModelTypes["MediaDocAccessFields_sizes_small_jpeg_height_Read"] | undefined,
	update?: ModelTypes["MediaDocAccessFields_sizes_small_jpeg_height_Update"] | undefined,
	delete?: ModelTypes["MediaDocAccessFields_sizes_small_jpeg_height_Delete"] | undefined
};
	["MediaDocAccessFields_sizes_small_jpeg_height_Create"]: {
		permission: boolean
};
	["MediaDocAccessFields_sizes_small_jpeg_height_Read"]: {
		permission: boolean
};
	["MediaDocAccessFields_sizes_small_jpeg_height_Update"]: {
		permission: boolean
};
	["MediaDocAccessFields_sizes_small_jpeg_height_Delete"]: {
		permission: boolean
};
	["MediaDocAccessFields_sizes_small_jpeg_mimeType"]: {
		create?: ModelTypes["MediaDocAccessFields_sizes_small_jpeg_mimeType_Create"] | undefined,
	read?: ModelTypes["MediaDocAccessFields_sizes_small_jpeg_mimeType_Read"] | undefined,
	update?: ModelTypes["MediaDocAccessFields_sizes_small_jpeg_mimeType_Update"] | undefined,
	delete?: ModelTypes["MediaDocAccessFields_sizes_small_jpeg_mimeType_Delete"] | undefined
};
	["MediaDocAccessFields_sizes_small_jpeg_mimeType_Create"]: {
		permission: boolean
};
	["MediaDocAccessFields_sizes_small_jpeg_mimeType_Read"]: {
		permission: boolean
};
	["MediaDocAccessFields_sizes_small_jpeg_mimeType_Update"]: {
		permission: boolean
};
	["MediaDocAccessFields_sizes_small_jpeg_mimeType_Delete"]: {
		permission: boolean
};
	["MediaDocAccessFields_sizes_small_jpeg_filesize"]: {
		create?: ModelTypes["MediaDocAccessFields_sizes_small_jpeg_filesize_Create"] | undefined,
	read?: ModelTypes["MediaDocAccessFields_sizes_small_jpeg_filesize_Read"] | undefined,
	update?: ModelTypes["MediaDocAccessFields_sizes_small_jpeg_filesize_Update"] | undefined,
	delete?: ModelTypes["MediaDocAccessFields_sizes_small_jpeg_filesize_Delete"] | undefined
};
	["MediaDocAccessFields_sizes_small_jpeg_filesize_Create"]: {
		permission: boolean
};
	["MediaDocAccessFields_sizes_small_jpeg_filesize_Read"]: {
		permission: boolean
};
	["MediaDocAccessFields_sizes_small_jpeg_filesize_Update"]: {
		permission: boolean
};
	["MediaDocAccessFields_sizes_small_jpeg_filesize_Delete"]: {
		permission: boolean
};
	["MediaDocAccessFields_sizes_small_jpeg_filename"]: {
		create?: ModelTypes["MediaDocAccessFields_sizes_small_jpeg_filename_Create"] | undefined,
	read?: ModelTypes["MediaDocAccessFields_sizes_small_jpeg_filename_Read"] | undefined,
	update?: ModelTypes["MediaDocAccessFields_sizes_small_jpeg_filename_Update"] | undefined,
	delete?: ModelTypes["MediaDocAccessFields_sizes_small_jpeg_filename_Delete"] | undefined
};
	["MediaDocAccessFields_sizes_small_jpeg_filename_Create"]: {
		permission: boolean
};
	["MediaDocAccessFields_sizes_small_jpeg_filename_Read"]: {
		permission: boolean
};
	["MediaDocAccessFields_sizes_small_jpeg_filename_Update"]: {
		permission: boolean
};
	["MediaDocAccessFields_sizes_small_jpeg_filename_Delete"]: {
		permission: boolean
};
	["MediaDocAccessFields_sizes_medium_jpeg"]: {
		create?: ModelTypes["MediaDocAccessFields_sizes_medium_jpeg_Create"] | undefined,
	read?: ModelTypes["MediaDocAccessFields_sizes_medium_jpeg_Read"] | undefined,
	update?: ModelTypes["MediaDocAccessFields_sizes_medium_jpeg_Update"] | undefined,
	delete?: ModelTypes["MediaDocAccessFields_sizes_medium_jpeg_Delete"] | undefined,
	fields?: ModelTypes["MediaDocAccessFields_sizes_medium_jpeg_Fields"] | undefined
};
	["MediaDocAccessFields_sizes_medium_jpeg_Create"]: {
		permission: boolean
};
	["MediaDocAccessFields_sizes_medium_jpeg_Read"]: {
		permission: boolean
};
	["MediaDocAccessFields_sizes_medium_jpeg_Update"]: {
		permission: boolean
};
	["MediaDocAccessFields_sizes_medium_jpeg_Delete"]: {
		permission: boolean
};
	["MediaDocAccessFields_sizes_medium_jpeg_Fields"]: {
		url?: ModelTypes["MediaDocAccessFields_sizes_medium_jpeg_url"] | undefined,
	width?: ModelTypes["MediaDocAccessFields_sizes_medium_jpeg_width"] | undefined,
	height?: ModelTypes["MediaDocAccessFields_sizes_medium_jpeg_height"] | undefined,
	mimeType?: ModelTypes["MediaDocAccessFields_sizes_medium_jpeg_mimeType"] | undefined,
	filesize?: ModelTypes["MediaDocAccessFields_sizes_medium_jpeg_filesize"] | undefined,
	filename?: ModelTypes["MediaDocAccessFields_sizes_medium_jpeg_filename"] | undefined
};
	["MediaDocAccessFields_sizes_medium_jpeg_url"]: {
		create?: ModelTypes["MediaDocAccessFields_sizes_medium_jpeg_url_Create"] | undefined,
	read?: ModelTypes["MediaDocAccessFields_sizes_medium_jpeg_url_Read"] | undefined,
	update?: ModelTypes["MediaDocAccessFields_sizes_medium_jpeg_url_Update"] | undefined,
	delete?: ModelTypes["MediaDocAccessFields_sizes_medium_jpeg_url_Delete"] | undefined
};
	["MediaDocAccessFields_sizes_medium_jpeg_url_Create"]: {
		permission: boolean
};
	["MediaDocAccessFields_sizes_medium_jpeg_url_Read"]: {
		permission: boolean
};
	["MediaDocAccessFields_sizes_medium_jpeg_url_Update"]: {
		permission: boolean
};
	["MediaDocAccessFields_sizes_medium_jpeg_url_Delete"]: {
		permission: boolean
};
	["MediaDocAccessFields_sizes_medium_jpeg_width"]: {
		create?: ModelTypes["MediaDocAccessFields_sizes_medium_jpeg_width_Create"] | undefined,
	read?: ModelTypes["MediaDocAccessFields_sizes_medium_jpeg_width_Read"] | undefined,
	update?: ModelTypes["MediaDocAccessFields_sizes_medium_jpeg_width_Update"] | undefined,
	delete?: ModelTypes["MediaDocAccessFields_sizes_medium_jpeg_width_Delete"] | undefined
};
	["MediaDocAccessFields_sizes_medium_jpeg_width_Create"]: {
		permission: boolean
};
	["MediaDocAccessFields_sizes_medium_jpeg_width_Read"]: {
		permission: boolean
};
	["MediaDocAccessFields_sizes_medium_jpeg_width_Update"]: {
		permission: boolean
};
	["MediaDocAccessFields_sizes_medium_jpeg_width_Delete"]: {
		permission: boolean
};
	["MediaDocAccessFields_sizes_medium_jpeg_height"]: {
		create?: ModelTypes["MediaDocAccessFields_sizes_medium_jpeg_height_Create"] | undefined,
	read?: ModelTypes["MediaDocAccessFields_sizes_medium_jpeg_height_Read"] | undefined,
	update?: ModelTypes["MediaDocAccessFields_sizes_medium_jpeg_height_Update"] | undefined,
	delete?: ModelTypes["MediaDocAccessFields_sizes_medium_jpeg_height_Delete"] | undefined
};
	["MediaDocAccessFields_sizes_medium_jpeg_height_Create"]: {
		permission: boolean
};
	["MediaDocAccessFields_sizes_medium_jpeg_height_Read"]: {
		permission: boolean
};
	["MediaDocAccessFields_sizes_medium_jpeg_height_Update"]: {
		permission: boolean
};
	["MediaDocAccessFields_sizes_medium_jpeg_height_Delete"]: {
		permission: boolean
};
	["MediaDocAccessFields_sizes_medium_jpeg_mimeType"]: {
		create?: ModelTypes["MediaDocAccessFields_sizes_medium_jpeg_mimeType_Create"] | undefined,
	read?: ModelTypes["MediaDocAccessFields_sizes_medium_jpeg_mimeType_Read"] | undefined,
	update?: ModelTypes["MediaDocAccessFields_sizes_medium_jpeg_mimeType_Update"] | undefined,
	delete?: ModelTypes["MediaDocAccessFields_sizes_medium_jpeg_mimeType_Delete"] | undefined
};
	["MediaDocAccessFields_sizes_medium_jpeg_mimeType_Create"]: {
		permission: boolean
};
	["MediaDocAccessFields_sizes_medium_jpeg_mimeType_Read"]: {
		permission: boolean
};
	["MediaDocAccessFields_sizes_medium_jpeg_mimeType_Update"]: {
		permission: boolean
};
	["MediaDocAccessFields_sizes_medium_jpeg_mimeType_Delete"]: {
		permission: boolean
};
	["MediaDocAccessFields_sizes_medium_jpeg_filesize"]: {
		create?: ModelTypes["MediaDocAccessFields_sizes_medium_jpeg_filesize_Create"] | undefined,
	read?: ModelTypes["MediaDocAccessFields_sizes_medium_jpeg_filesize_Read"] | undefined,
	update?: ModelTypes["MediaDocAccessFields_sizes_medium_jpeg_filesize_Update"] | undefined,
	delete?: ModelTypes["MediaDocAccessFields_sizes_medium_jpeg_filesize_Delete"] | undefined
};
	["MediaDocAccessFields_sizes_medium_jpeg_filesize_Create"]: {
		permission: boolean
};
	["MediaDocAccessFields_sizes_medium_jpeg_filesize_Read"]: {
		permission: boolean
};
	["MediaDocAccessFields_sizes_medium_jpeg_filesize_Update"]: {
		permission: boolean
};
	["MediaDocAccessFields_sizes_medium_jpeg_filesize_Delete"]: {
		permission: boolean
};
	["MediaDocAccessFields_sizes_medium_jpeg_filename"]: {
		create?: ModelTypes["MediaDocAccessFields_sizes_medium_jpeg_filename_Create"] | undefined,
	read?: ModelTypes["MediaDocAccessFields_sizes_medium_jpeg_filename_Read"] | undefined,
	update?: ModelTypes["MediaDocAccessFields_sizes_medium_jpeg_filename_Update"] | undefined,
	delete?: ModelTypes["MediaDocAccessFields_sizes_medium_jpeg_filename_Delete"] | undefined
};
	["MediaDocAccessFields_sizes_medium_jpeg_filename_Create"]: {
		permission: boolean
};
	["MediaDocAccessFields_sizes_medium_jpeg_filename_Read"]: {
		permission: boolean
};
	["MediaDocAccessFields_sizes_medium_jpeg_filename_Update"]: {
		permission: boolean
};
	["MediaDocAccessFields_sizes_medium_jpeg_filename_Delete"]: {
		permission: boolean
};
	["MediaDocAccessFields_sizes_large_jpeg"]: {
		create?: ModelTypes["MediaDocAccessFields_sizes_large_jpeg_Create"] | undefined,
	read?: ModelTypes["MediaDocAccessFields_sizes_large_jpeg_Read"] | undefined,
	update?: ModelTypes["MediaDocAccessFields_sizes_large_jpeg_Update"] | undefined,
	delete?: ModelTypes["MediaDocAccessFields_sizes_large_jpeg_Delete"] | undefined,
	fields?: ModelTypes["MediaDocAccessFields_sizes_large_jpeg_Fields"] | undefined
};
	["MediaDocAccessFields_sizes_large_jpeg_Create"]: {
		permission: boolean
};
	["MediaDocAccessFields_sizes_large_jpeg_Read"]: {
		permission: boolean
};
	["MediaDocAccessFields_sizes_large_jpeg_Update"]: {
		permission: boolean
};
	["MediaDocAccessFields_sizes_large_jpeg_Delete"]: {
		permission: boolean
};
	["MediaDocAccessFields_sizes_large_jpeg_Fields"]: {
		url?: ModelTypes["MediaDocAccessFields_sizes_large_jpeg_url"] | undefined,
	width?: ModelTypes["MediaDocAccessFields_sizes_large_jpeg_width"] | undefined,
	height?: ModelTypes["MediaDocAccessFields_sizes_large_jpeg_height"] | undefined,
	mimeType?: ModelTypes["MediaDocAccessFields_sizes_large_jpeg_mimeType"] | undefined,
	filesize?: ModelTypes["MediaDocAccessFields_sizes_large_jpeg_filesize"] | undefined,
	filename?: ModelTypes["MediaDocAccessFields_sizes_large_jpeg_filename"] | undefined
};
	["MediaDocAccessFields_sizes_large_jpeg_url"]: {
		create?: ModelTypes["MediaDocAccessFields_sizes_large_jpeg_url_Create"] | undefined,
	read?: ModelTypes["MediaDocAccessFields_sizes_large_jpeg_url_Read"] | undefined,
	update?: ModelTypes["MediaDocAccessFields_sizes_large_jpeg_url_Update"] | undefined,
	delete?: ModelTypes["MediaDocAccessFields_sizes_large_jpeg_url_Delete"] | undefined
};
	["MediaDocAccessFields_sizes_large_jpeg_url_Create"]: {
		permission: boolean
};
	["MediaDocAccessFields_sizes_large_jpeg_url_Read"]: {
		permission: boolean
};
	["MediaDocAccessFields_sizes_large_jpeg_url_Update"]: {
		permission: boolean
};
	["MediaDocAccessFields_sizes_large_jpeg_url_Delete"]: {
		permission: boolean
};
	["MediaDocAccessFields_sizes_large_jpeg_width"]: {
		create?: ModelTypes["MediaDocAccessFields_sizes_large_jpeg_width_Create"] | undefined,
	read?: ModelTypes["MediaDocAccessFields_sizes_large_jpeg_width_Read"] | undefined,
	update?: ModelTypes["MediaDocAccessFields_sizes_large_jpeg_width_Update"] | undefined,
	delete?: ModelTypes["MediaDocAccessFields_sizes_large_jpeg_width_Delete"] | undefined
};
	["MediaDocAccessFields_sizes_large_jpeg_width_Create"]: {
		permission: boolean
};
	["MediaDocAccessFields_sizes_large_jpeg_width_Read"]: {
		permission: boolean
};
	["MediaDocAccessFields_sizes_large_jpeg_width_Update"]: {
		permission: boolean
};
	["MediaDocAccessFields_sizes_large_jpeg_width_Delete"]: {
		permission: boolean
};
	["MediaDocAccessFields_sizes_large_jpeg_height"]: {
		create?: ModelTypes["MediaDocAccessFields_sizes_large_jpeg_height_Create"] | undefined,
	read?: ModelTypes["MediaDocAccessFields_sizes_large_jpeg_height_Read"] | undefined,
	update?: ModelTypes["MediaDocAccessFields_sizes_large_jpeg_height_Update"] | undefined,
	delete?: ModelTypes["MediaDocAccessFields_sizes_large_jpeg_height_Delete"] | undefined
};
	["MediaDocAccessFields_sizes_large_jpeg_height_Create"]: {
		permission: boolean
};
	["MediaDocAccessFields_sizes_large_jpeg_height_Read"]: {
		permission: boolean
};
	["MediaDocAccessFields_sizes_large_jpeg_height_Update"]: {
		permission: boolean
};
	["MediaDocAccessFields_sizes_large_jpeg_height_Delete"]: {
		permission: boolean
};
	["MediaDocAccessFields_sizes_large_jpeg_mimeType"]: {
		create?: ModelTypes["MediaDocAccessFields_sizes_large_jpeg_mimeType_Create"] | undefined,
	read?: ModelTypes["MediaDocAccessFields_sizes_large_jpeg_mimeType_Read"] | undefined,
	update?: ModelTypes["MediaDocAccessFields_sizes_large_jpeg_mimeType_Update"] | undefined,
	delete?: ModelTypes["MediaDocAccessFields_sizes_large_jpeg_mimeType_Delete"] | undefined
};
	["MediaDocAccessFields_sizes_large_jpeg_mimeType_Create"]: {
		permission: boolean
};
	["MediaDocAccessFields_sizes_large_jpeg_mimeType_Read"]: {
		permission: boolean
};
	["MediaDocAccessFields_sizes_large_jpeg_mimeType_Update"]: {
		permission: boolean
};
	["MediaDocAccessFields_sizes_large_jpeg_mimeType_Delete"]: {
		permission: boolean
};
	["MediaDocAccessFields_sizes_large_jpeg_filesize"]: {
		create?: ModelTypes["MediaDocAccessFields_sizes_large_jpeg_filesize_Create"] | undefined,
	read?: ModelTypes["MediaDocAccessFields_sizes_large_jpeg_filesize_Read"] | undefined,
	update?: ModelTypes["MediaDocAccessFields_sizes_large_jpeg_filesize_Update"] | undefined,
	delete?: ModelTypes["MediaDocAccessFields_sizes_large_jpeg_filesize_Delete"] | undefined
};
	["MediaDocAccessFields_sizes_large_jpeg_filesize_Create"]: {
		permission: boolean
};
	["MediaDocAccessFields_sizes_large_jpeg_filesize_Read"]: {
		permission: boolean
};
	["MediaDocAccessFields_sizes_large_jpeg_filesize_Update"]: {
		permission: boolean
};
	["MediaDocAccessFields_sizes_large_jpeg_filesize_Delete"]: {
		permission: boolean
};
	["MediaDocAccessFields_sizes_large_jpeg_filename"]: {
		create?: ModelTypes["MediaDocAccessFields_sizes_large_jpeg_filename_Create"] | undefined,
	read?: ModelTypes["MediaDocAccessFields_sizes_large_jpeg_filename_Read"] | undefined,
	update?: ModelTypes["MediaDocAccessFields_sizes_large_jpeg_filename_Update"] | undefined,
	delete?: ModelTypes["MediaDocAccessFields_sizes_large_jpeg_filename_Delete"] | undefined
};
	["MediaDocAccessFields_sizes_large_jpeg_filename_Create"]: {
		permission: boolean
};
	["MediaDocAccessFields_sizes_large_jpeg_filename_Read"]: {
		permission: boolean
};
	["MediaDocAccessFields_sizes_large_jpeg_filename_Update"]: {
		permission: boolean
};
	["MediaDocAccessFields_sizes_large_jpeg_filename_Delete"]: {
		permission: boolean
};
	["MediaCreateDocAccess"]: {
		permission: boolean,
	where?: ModelTypes["JSONObject"] | undefined
};
	["MediaReadDocAccess"]: {
		permission: boolean,
	where?: ModelTypes["JSONObject"] | undefined
};
	["MediaUpdateDocAccess"]: {
		permission: boolean,
	where?: ModelTypes["JSONObject"] | undefined
};
	["MediaDeleteDocAccess"]: {
		permission: boolean,
	where?: ModelTypes["JSONObject"] | undefined
};
	["BackupMedia"]: {
		id?: string | undefined,
	alt?: string | undefined,
	creator?: ModelTypes["User"] | undefined,
	updatedAt?: ModelTypes["DateTime"] | undefined,
	createdAt?: ModelTypes["DateTime"] | undefined,
	url?: string | undefined,
	filename?: string | undefined,
	mimeType?: string | undefined,
	filesize?: number | undefined,
	width?: number | undefined,
	height?: number | undefined,
	focalX?: number | undefined,
	focalY?: number | undefined,
	sizes?: ModelTypes["BackupMedia_Sizes"] | undefined
};
	["BackupMedia_Sizes"]: {
		thumbnail?: ModelTypes["BackupMedia_Sizes_Thumbnail"] | undefined,
	sixteenByNineMedium?: ModelTypes["BackupMedia_Sizes_SixteenByNineMedium"] | undefined
};
	["BackupMedia_Sizes_Thumbnail"]: {
		url?: string | undefined,
	width?: number | undefined,
	height?: number | undefined,
	mimeType?: string | undefined,
	filesize?: number | undefined,
	filename?: string | undefined
};
	["BackupMedia_Sizes_SixteenByNineMedium"]: {
		url?: string | undefined,
	width?: number | undefined,
	height?: number | undefined,
	mimeType?: string | undefined,
	filesize?: number | undefined,
	filename?: string | undefined
};
	["BackupMedias"]: {
		docs?: Array<ModelTypes["BackupMedia"] | undefined> | undefined,
	hasNextPage?: boolean | undefined,
	hasPrevPage?: boolean | undefined,
	limit?: number | undefined,
	nextPage?: number | undefined,
	offset?: number | undefined,
	page?: number | undefined,
	pagingCounter?: number | undefined,
	prevPage?: number | undefined,
	totalDocs?: number | undefined,
	totalPages?: number | undefined
};
	["BackupMedia_where"]: {
	alt?: ModelTypes["BackupMedia_alt_operator"] | undefined,
	creator?: ModelTypes["BackupMedia_creator_operator"] | undefined,
	updatedAt?: ModelTypes["BackupMedia_updatedAt_operator"] | undefined,
	createdAt?: ModelTypes["BackupMedia_createdAt_operator"] | undefined,
	url?: ModelTypes["BackupMedia_url_operator"] | undefined,
	filename?: ModelTypes["BackupMedia_filename_operator"] | undefined,
	mimeType?: ModelTypes["BackupMedia_mimeType_operator"] | undefined,
	filesize?: ModelTypes["BackupMedia_filesize_operator"] | undefined,
	width?: ModelTypes["BackupMedia_width_operator"] | undefined,
	height?: ModelTypes["BackupMedia_height_operator"] | undefined,
	focalX?: ModelTypes["BackupMedia_focalX_operator"] | undefined,
	focalY?: ModelTypes["BackupMedia_focalY_operator"] | undefined,
	sizes__thumbnail__url?: ModelTypes["BackupMedia_sizes__thumbnail__url_operator"] | undefined,
	sizes__thumbnail__width?: ModelTypes["BackupMedia_sizes__thumbnail__width_operator"] | undefined,
	sizes__thumbnail__height?: ModelTypes["BackupMedia_sizes__thumbnail__height_operator"] | undefined,
	sizes__thumbnail__mimeType?: ModelTypes["BackupMedia_sizes__thumbnail__mimeType_operator"] | undefined,
	sizes__thumbnail__filesize?: ModelTypes["BackupMedia_sizes__thumbnail__filesize_operator"] | undefined,
	sizes__thumbnail__filename?: ModelTypes["BackupMedia_sizes__thumbnail__filename_operator"] | undefined,
	sizes__sixteenByNineMedium__url?: ModelTypes["BackupMedia_sizes__sixteenByNineMedium__url_operator"] | undefined,
	sizes__sixteenByNineMedium__width?: ModelTypes["BackupMedia_sizes__sixteenByNineMedium__width_operator"] | undefined,
	sizes__sixteenByNineMedium__height?: ModelTypes["BackupMedia_sizes__sixteenByNineMedium__height_operator"] | undefined,
	sizes__sixteenByNineMedium__mimeType?: ModelTypes["BackupMedia_sizes__sixteenByNineMedium__mimeType_operator"] | undefined,
	sizes__sixteenByNineMedium__filesize?: ModelTypes["BackupMedia_sizes__sixteenByNineMedium__filesize_operator"] | undefined,
	sizes__sixteenByNineMedium__filename?: ModelTypes["BackupMedia_sizes__sixteenByNineMedium__filename_operator"] | undefined,
	id?: ModelTypes["BackupMedia_id_operator"] | undefined,
	AND?: Array<ModelTypes["BackupMedia_where_and"] | undefined> | undefined,
	OR?: Array<ModelTypes["BackupMedia_where_or"] | undefined> | undefined
};
	["BackupMedia_alt_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["BackupMedia_creator_operator"]: {
	equals?: ModelTypes["JSON"] | undefined,
	not_equals?: ModelTypes["JSON"] | undefined,
	in?: Array<ModelTypes["JSON"] | undefined> | undefined,
	not_in?: Array<ModelTypes["JSON"] | undefined> | undefined,
	all?: Array<ModelTypes["JSON"] | undefined> | undefined,
	exists?: boolean | undefined
};
	["BackupMedia_updatedAt_operator"]: {
	equals?: ModelTypes["DateTime"] | undefined,
	not_equals?: ModelTypes["DateTime"] | undefined,
	greater_than_equal?: ModelTypes["DateTime"] | undefined,
	greater_than?: ModelTypes["DateTime"] | undefined,
	less_than_equal?: ModelTypes["DateTime"] | undefined,
	less_than?: ModelTypes["DateTime"] | undefined,
	like?: ModelTypes["DateTime"] | undefined,
	exists?: boolean | undefined
};
	["BackupMedia_createdAt_operator"]: {
	equals?: ModelTypes["DateTime"] | undefined,
	not_equals?: ModelTypes["DateTime"] | undefined,
	greater_than_equal?: ModelTypes["DateTime"] | undefined,
	greater_than?: ModelTypes["DateTime"] | undefined,
	less_than_equal?: ModelTypes["DateTime"] | undefined,
	less_than?: ModelTypes["DateTime"] | undefined,
	like?: ModelTypes["DateTime"] | undefined,
	exists?: boolean | undefined
};
	["BackupMedia_url_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["BackupMedia_filename_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["BackupMedia_mimeType_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["BackupMedia_filesize_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["BackupMedia_width_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["BackupMedia_height_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["BackupMedia_focalX_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["BackupMedia_focalY_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["BackupMedia_sizes__thumbnail__url_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["BackupMedia_sizes__thumbnail__width_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["BackupMedia_sizes__thumbnail__height_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["BackupMedia_sizes__thumbnail__mimeType_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["BackupMedia_sizes__thumbnail__filesize_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["BackupMedia_sizes__thumbnail__filename_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["BackupMedia_sizes__sixteenByNineMedium__url_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["BackupMedia_sizes__sixteenByNineMedium__width_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["BackupMedia_sizes__sixteenByNineMedium__height_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["BackupMedia_sizes__sixteenByNineMedium__mimeType_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["BackupMedia_sizes__sixteenByNineMedium__filesize_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["BackupMedia_sizes__sixteenByNineMedium__filename_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["BackupMedia_id_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["BackupMedia_where_and"]: {
	alt?: ModelTypes["BackupMedia_alt_operator"] | undefined,
	creator?: ModelTypes["BackupMedia_creator_operator"] | undefined,
	updatedAt?: ModelTypes["BackupMedia_updatedAt_operator"] | undefined,
	createdAt?: ModelTypes["BackupMedia_createdAt_operator"] | undefined,
	url?: ModelTypes["BackupMedia_url_operator"] | undefined,
	filename?: ModelTypes["BackupMedia_filename_operator"] | undefined,
	mimeType?: ModelTypes["BackupMedia_mimeType_operator"] | undefined,
	filesize?: ModelTypes["BackupMedia_filesize_operator"] | undefined,
	width?: ModelTypes["BackupMedia_width_operator"] | undefined,
	height?: ModelTypes["BackupMedia_height_operator"] | undefined,
	focalX?: ModelTypes["BackupMedia_focalX_operator"] | undefined,
	focalY?: ModelTypes["BackupMedia_focalY_operator"] | undefined,
	sizes__thumbnail__url?: ModelTypes["BackupMedia_sizes__thumbnail__url_operator"] | undefined,
	sizes__thumbnail__width?: ModelTypes["BackupMedia_sizes__thumbnail__width_operator"] | undefined,
	sizes__thumbnail__height?: ModelTypes["BackupMedia_sizes__thumbnail__height_operator"] | undefined,
	sizes__thumbnail__mimeType?: ModelTypes["BackupMedia_sizes__thumbnail__mimeType_operator"] | undefined,
	sizes__thumbnail__filesize?: ModelTypes["BackupMedia_sizes__thumbnail__filesize_operator"] | undefined,
	sizes__thumbnail__filename?: ModelTypes["BackupMedia_sizes__thumbnail__filename_operator"] | undefined,
	sizes__sixteenByNineMedium__url?: ModelTypes["BackupMedia_sizes__sixteenByNineMedium__url_operator"] | undefined,
	sizes__sixteenByNineMedium__width?: ModelTypes["BackupMedia_sizes__sixteenByNineMedium__width_operator"] | undefined,
	sizes__sixteenByNineMedium__height?: ModelTypes["BackupMedia_sizes__sixteenByNineMedium__height_operator"] | undefined,
	sizes__sixteenByNineMedium__mimeType?: ModelTypes["BackupMedia_sizes__sixteenByNineMedium__mimeType_operator"] | undefined,
	sizes__sixteenByNineMedium__filesize?: ModelTypes["BackupMedia_sizes__sixteenByNineMedium__filesize_operator"] | undefined,
	sizes__sixteenByNineMedium__filename?: ModelTypes["BackupMedia_sizes__sixteenByNineMedium__filename_operator"] | undefined,
	id?: ModelTypes["BackupMedia_id_operator"] | undefined,
	AND?: Array<ModelTypes["BackupMedia_where_and"] | undefined> | undefined,
	OR?: Array<ModelTypes["BackupMedia_where_or"] | undefined> | undefined
};
	["BackupMedia_where_or"]: {
	alt?: ModelTypes["BackupMedia_alt_operator"] | undefined,
	creator?: ModelTypes["BackupMedia_creator_operator"] | undefined,
	updatedAt?: ModelTypes["BackupMedia_updatedAt_operator"] | undefined,
	createdAt?: ModelTypes["BackupMedia_createdAt_operator"] | undefined,
	url?: ModelTypes["BackupMedia_url_operator"] | undefined,
	filename?: ModelTypes["BackupMedia_filename_operator"] | undefined,
	mimeType?: ModelTypes["BackupMedia_mimeType_operator"] | undefined,
	filesize?: ModelTypes["BackupMedia_filesize_operator"] | undefined,
	width?: ModelTypes["BackupMedia_width_operator"] | undefined,
	height?: ModelTypes["BackupMedia_height_operator"] | undefined,
	focalX?: ModelTypes["BackupMedia_focalX_operator"] | undefined,
	focalY?: ModelTypes["BackupMedia_focalY_operator"] | undefined,
	sizes__thumbnail__url?: ModelTypes["BackupMedia_sizes__thumbnail__url_operator"] | undefined,
	sizes__thumbnail__width?: ModelTypes["BackupMedia_sizes__thumbnail__width_operator"] | undefined,
	sizes__thumbnail__height?: ModelTypes["BackupMedia_sizes__thumbnail__height_operator"] | undefined,
	sizes__thumbnail__mimeType?: ModelTypes["BackupMedia_sizes__thumbnail__mimeType_operator"] | undefined,
	sizes__thumbnail__filesize?: ModelTypes["BackupMedia_sizes__thumbnail__filesize_operator"] | undefined,
	sizes__thumbnail__filename?: ModelTypes["BackupMedia_sizes__thumbnail__filename_operator"] | undefined,
	sizes__sixteenByNineMedium__url?: ModelTypes["BackupMedia_sizes__sixteenByNineMedium__url_operator"] | undefined,
	sizes__sixteenByNineMedium__width?: ModelTypes["BackupMedia_sizes__sixteenByNineMedium__width_operator"] | undefined,
	sizes__sixteenByNineMedium__height?: ModelTypes["BackupMedia_sizes__sixteenByNineMedium__height_operator"] | undefined,
	sizes__sixteenByNineMedium__mimeType?: ModelTypes["BackupMedia_sizes__sixteenByNineMedium__mimeType_operator"] | undefined,
	sizes__sixteenByNineMedium__filesize?: ModelTypes["BackupMedia_sizes__sixteenByNineMedium__filesize_operator"] | undefined,
	sizes__sixteenByNineMedium__filename?: ModelTypes["BackupMedia_sizes__sixteenByNineMedium__filename_operator"] | undefined,
	id?: ModelTypes["BackupMedia_id_operator"] | undefined,
	AND?: Array<ModelTypes["BackupMedia_where_and"] | undefined> | undefined,
	OR?: Array<ModelTypes["BackupMedia_where_or"] | undefined> | undefined
};
	["countBackupMedias"]: {
		totalDocs?: number | undefined
};
	["backupMediaDocAccess"]: {
		fields?: ModelTypes["BackupMediaDocAccessFields"] | undefined,
	create?: ModelTypes["BackupMediaCreateDocAccess"] | undefined,
	read?: ModelTypes["BackupMediaReadDocAccess"] | undefined,
	update?: ModelTypes["BackupMediaUpdateDocAccess"] | undefined,
	delete?: ModelTypes["BackupMediaDeleteDocAccess"] | undefined
};
	["BackupMediaDocAccessFields"]: {
		alt?: ModelTypes["BackupMediaDocAccessFields_alt"] | undefined,
	creator?: ModelTypes["BackupMediaDocAccessFields_creator"] | undefined,
	updatedAt?: ModelTypes["BackupMediaDocAccessFields_updatedAt"] | undefined,
	createdAt?: ModelTypes["BackupMediaDocAccessFields_createdAt"] | undefined,
	url?: ModelTypes["BackupMediaDocAccessFields_url"] | undefined,
	filename?: ModelTypes["BackupMediaDocAccessFields_filename"] | undefined,
	mimeType?: ModelTypes["BackupMediaDocAccessFields_mimeType"] | undefined,
	filesize?: ModelTypes["BackupMediaDocAccessFields_filesize"] | undefined,
	width?: ModelTypes["BackupMediaDocAccessFields_width"] | undefined,
	height?: ModelTypes["BackupMediaDocAccessFields_height"] | undefined,
	focalX?: ModelTypes["BackupMediaDocAccessFields_focalX"] | undefined,
	focalY?: ModelTypes["BackupMediaDocAccessFields_focalY"] | undefined,
	sizes?: ModelTypes["BackupMediaDocAccessFields_sizes"] | undefined
};
	["BackupMediaDocAccessFields_alt"]: {
		create?: ModelTypes["BackupMediaDocAccessFields_alt_Create"] | undefined,
	read?: ModelTypes["BackupMediaDocAccessFields_alt_Read"] | undefined,
	update?: ModelTypes["BackupMediaDocAccessFields_alt_Update"] | undefined,
	delete?: ModelTypes["BackupMediaDocAccessFields_alt_Delete"] | undefined
};
	["BackupMediaDocAccessFields_alt_Create"]: {
		permission: boolean
};
	["BackupMediaDocAccessFields_alt_Read"]: {
		permission: boolean
};
	["BackupMediaDocAccessFields_alt_Update"]: {
		permission: boolean
};
	["BackupMediaDocAccessFields_alt_Delete"]: {
		permission: boolean
};
	["BackupMediaDocAccessFields_creator"]: {
		create?: ModelTypes["BackupMediaDocAccessFields_creator_Create"] | undefined,
	read?: ModelTypes["BackupMediaDocAccessFields_creator_Read"] | undefined,
	update?: ModelTypes["BackupMediaDocAccessFields_creator_Update"] | undefined,
	delete?: ModelTypes["BackupMediaDocAccessFields_creator_Delete"] | undefined
};
	["BackupMediaDocAccessFields_creator_Create"]: {
		permission: boolean
};
	["BackupMediaDocAccessFields_creator_Read"]: {
		permission: boolean
};
	["BackupMediaDocAccessFields_creator_Update"]: {
		permission: boolean
};
	["BackupMediaDocAccessFields_creator_Delete"]: {
		permission: boolean
};
	["BackupMediaDocAccessFields_updatedAt"]: {
		create?: ModelTypes["BackupMediaDocAccessFields_updatedAt_Create"] | undefined,
	read?: ModelTypes["BackupMediaDocAccessFields_updatedAt_Read"] | undefined,
	update?: ModelTypes["BackupMediaDocAccessFields_updatedAt_Update"] | undefined,
	delete?: ModelTypes["BackupMediaDocAccessFields_updatedAt_Delete"] | undefined
};
	["BackupMediaDocAccessFields_updatedAt_Create"]: {
		permission: boolean
};
	["BackupMediaDocAccessFields_updatedAt_Read"]: {
		permission: boolean
};
	["BackupMediaDocAccessFields_updatedAt_Update"]: {
		permission: boolean
};
	["BackupMediaDocAccessFields_updatedAt_Delete"]: {
		permission: boolean
};
	["BackupMediaDocAccessFields_createdAt"]: {
		create?: ModelTypes["BackupMediaDocAccessFields_createdAt_Create"] | undefined,
	read?: ModelTypes["BackupMediaDocAccessFields_createdAt_Read"] | undefined,
	update?: ModelTypes["BackupMediaDocAccessFields_createdAt_Update"] | undefined,
	delete?: ModelTypes["BackupMediaDocAccessFields_createdAt_Delete"] | undefined
};
	["BackupMediaDocAccessFields_createdAt_Create"]: {
		permission: boolean
};
	["BackupMediaDocAccessFields_createdAt_Read"]: {
		permission: boolean
};
	["BackupMediaDocAccessFields_createdAt_Update"]: {
		permission: boolean
};
	["BackupMediaDocAccessFields_createdAt_Delete"]: {
		permission: boolean
};
	["BackupMediaDocAccessFields_url"]: {
		create?: ModelTypes["BackupMediaDocAccessFields_url_Create"] | undefined,
	read?: ModelTypes["BackupMediaDocAccessFields_url_Read"] | undefined,
	update?: ModelTypes["BackupMediaDocAccessFields_url_Update"] | undefined,
	delete?: ModelTypes["BackupMediaDocAccessFields_url_Delete"] | undefined
};
	["BackupMediaDocAccessFields_url_Create"]: {
		permission: boolean
};
	["BackupMediaDocAccessFields_url_Read"]: {
		permission: boolean
};
	["BackupMediaDocAccessFields_url_Update"]: {
		permission: boolean
};
	["BackupMediaDocAccessFields_url_Delete"]: {
		permission: boolean
};
	["BackupMediaDocAccessFields_filename"]: {
		create?: ModelTypes["BackupMediaDocAccessFields_filename_Create"] | undefined,
	read?: ModelTypes["BackupMediaDocAccessFields_filename_Read"] | undefined,
	update?: ModelTypes["BackupMediaDocAccessFields_filename_Update"] | undefined,
	delete?: ModelTypes["BackupMediaDocAccessFields_filename_Delete"] | undefined
};
	["BackupMediaDocAccessFields_filename_Create"]: {
		permission: boolean
};
	["BackupMediaDocAccessFields_filename_Read"]: {
		permission: boolean
};
	["BackupMediaDocAccessFields_filename_Update"]: {
		permission: boolean
};
	["BackupMediaDocAccessFields_filename_Delete"]: {
		permission: boolean
};
	["BackupMediaDocAccessFields_mimeType"]: {
		create?: ModelTypes["BackupMediaDocAccessFields_mimeType_Create"] | undefined,
	read?: ModelTypes["BackupMediaDocAccessFields_mimeType_Read"] | undefined,
	update?: ModelTypes["BackupMediaDocAccessFields_mimeType_Update"] | undefined,
	delete?: ModelTypes["BackupMediaDocAccessFields_mimeType_Delete"] | undefined
};
	["BackupMediaDocAccessFields_mimeType_Create"]: {
		permission: boolean
};
	["BackupMediaDocAccessFields_mimeType_Read"]: {
		permission: boolean
};
	["BackupMediaDocAccessFields_mimeType_Update"]: {
		permission: boolean
};
	["BackupMediaDocAccessFields_mimeType_Delete"]: {
		permission: boolean
};
	["BackupMediaDocAccessFields_filesize"]: {
		create?: ModelTypes["BackupMediaDocAccessFields_filesize_Create"] | undefined,
	read?: ModelTypes["BackupMediaDocAccessFields_filesize_Read"] | undefined,
	update?: ModelTypes["BackupMediaDocAccessFields_filesize_Update"] | undefined,
	delete?: ModelTypes["BackupMediaDocAccessFields_filesize_Delete"] | undefined
};
	["BackupMediaDocAccessFields_filesize_Create"]: {
		permission: boolean
};
	["BackupMediaDocAccessFields_filesize_Read"]: {
		permission: boolean
};
	["BackupMediaDocAccessFields_filesize_Update"]: {
		permission: boolean
};
	["BackupMediaDocAccessFields_filesize_Delete"]: {
		permission: boolean
};
	["BackupMediaDocAccessFields_width"]: {
		create?: ModelTypes["BackupMediaDocAccessFields_width_Create"] | undefined,
	read?: ModelTypes["BackupMediaDocAccessFields_width_Read"] | undefined,
	update?: ModelTypes["BackupMediaDocAccessFields_width_Update"] | undefined,
	delete?: ModelTypes["BackupMediaDocAccessFields_width_Delete"] | undefined
};
	["BackupMediaDocAccessFields_width_Create"]: {
		permission: boolean
};
	["BackupMediaDocAccessFields_width_Read"]: {
		permission: boolean
};
	["BackupMediaDocAccessFields_width_Update"]: {
		permission: boolean
};
	["BackupMediaDocAccessFields_width_Delete"]: {
		permission: boolean
};
	["BackupMediaDocAccessFields_height"]: {
		create?: ModelTypes["BackupMediaDocAccessFields_height_Create"] | undefined,
	read?: ModelTypes["BackupMediaDocAccessFields_height_Read"] | undefined,
	update?: ModelTypes["BackupMediaDocAccessFields_height_Update"] | undefined,
	delete?: ModelTypes["BackupMediaDocAccessFields_height_Delete"] | undefined
};
	["BackupMediaDocAccessFields_height_Create"]: {
		permission: boolean
};
	["BackupMediaDocAccessFields_height_Read"]: {
		permission: boolean
};
	["BackupMediaDocAccessFields_height_Update"]: {
		permission: boolean
};
	["BackupMediaDocAccessFields_height_Delete"]: {
		permission: boolean
};
	["BackupMediaDocAccessFields_focalX"]: {
		create?: ModelTypes["BackupMediaDocAccessFields_focalX_Create"] | undefined,
	read?: ModelTypes["BackupMediaDocAccessFields_focalX_Read"] | undefined,
	update?: ModelTypes["BackupMediaDocAccessFields_focalX_Update"] | undefined,
	delete?: ModelTypes["BackupMediaDocAccessFields_focalX_Delete"] | undefined
};
	["BackupMediaDocAccessFields_focalX_Create"]: {
		permission: boolean
};
	["BackupMediaDocAccessFields_focalX_Read"]: {
		permission: boolean
};
	["BackupMediaDocAccessFields_focalX_Update"]: {
		permission: boolean
};
	["BackupMediaDocAccessFields_focalX_Delete"]: {
		permission: boolean
};
	["BackupMediaDocAccessFields_focalY"]: {
		create?: ModelTypes["BackupMediaDocAccessFields_focalY_Create"] | undefined,
	read?: ModelTypes["BackupMediaDocAccessFields_focalY_Read"] | undefined,
	update?: ModelTypes["BackupMediaDocAccessFields_focalY_Update"] | undefined,
	delete?: ModelTypes["BackupMediaDocAccessFields_focalY_Delete"] | undefined
};
	["BackupMediaDocAccessFields_focalY_Create"]: {
		permission: boolean
};
	["BackupMediaDocAccessFields_focalY_Read"]: {
		permission: boolean
};
	["BackupMediaDocAccessFields_focalY_Update"]: {
		permission: boolean
};
	["BackupMediaDocAccessFields_focalY_Delete"]: {
		permission: boolean
};
	["BackupMediaDocAccessFields_sizes"]: {
		create?: ModelTypes["BackupMediaDocAccessFields_sizes_Create"] | undefined,
	read?: ModelTypes["BackupMediaDocAccessFields_sizes_Read"] | undefined,
	update?: ModelTypes["BackupMediaDocAccessFields_sizes_Update"] | undefined,
	delete?: ModelTypes["BackupMediaDocAccessFields_sizes_Delete"] | undefined,
	fields?: ModelTypes["BackupMediaDocAccessFields_sizes_Fields"] | undefined
};
	["BackupMediaDocAccessFields_sizes_Create"]: {
		permission: boolean
};
	["BackupMediaDocAccessFields_sizes_Read"]: {
		permission: boolean
};
	["BackupMediaDocAccessFields_sizes_Update"]: {
		permission: boolean
};
	["BackupMediaDocAccessFields_sizes_Delete"]: {
		permission: boolean
};
	["BackupMediaDocAccessFields_sizes_Fields"]: {
		thumbnail?: ModelTypes["BackupMediaDocAccessFields_sizes_thumbnail"] | undefined,
	sixteenByNineMedium?: ModelTypes["BackupMediaDocAccessFields_sizes_sixteenByNineMedium"] | undefined
};
	["BackupMediaDocAccessFields_sizes_thumbnail"]: {
		create?: ModelTypes["BackupMediaDocAccessFields_sizes_thumbnail_Create"] | undefined,
	read?: ModelTypes["BackupMediaDocAccessFields_sizes_thumbnail_Read"] | undefined,
	update?: ModelTypes["BackupMediaDocAccessFields_sizes_thumbnail_Update"] | undefined,
	delete?: ModelTypes["BackupMediaDocAccessFields_sizes_thumbnail_Delete"] | undefined,
	fields?: ModelTypes["BackupMediaDocAccessFields_sizes_thumbnail_Fields"] | undefined
};
	["BackupMediaDocAccessFields_sizes_thumbnail_Create"]: {
		permission: boolean
};
	["BackupMediaDocAccessFields_sizes_thumbnail_Read"]: {
		permission: boolean
};
	["BackupMediaDocAccessFields_sizes_thumbnail_Update"]: {
		permission: boolean
};
	["BackupMediaDocAccessFields_sizes_thumbnail_Delete"]: {
		permission: boolean
};
	["BackupMediaDocAccessFields_sizes_thumbnail_Fields"]: {
		url?: ModelTypes["BackupMediaDocAccessFields_sizes_thumbnail_url"] | undefined,
	width?: ModelTypes["BackupMediaDocAccessFields_sizes_thumbnail_width"] | undefined,
	height?: ModelTypes["BackupMediaDocAccessFields_sizes_thumbnail_height"] | undefined,
	mimeType?: ModelTypes["BackupMediaDocAccessFields_sizes_thumbnail_mimeType"] | undefined,
	filesize?: ModelTypes["BackupMediaDocAccessFields_sizes_thumbnail_filesize"] | undefined,
	filename?: ModelTypes["BackupMediaDocAccessFields_sizes_thumbnail_filename"] | undefined
};
	["BackupMediaDocAccessFields_sizes_thumbnail_url"]: {
		create?: ModelTypes["BackupMediaDocAccessFields_sizes_thumbnail_url_Create"] | undefined,
	read?: ModelTypes["BackupMediaDocAccessFields_sizes_thumbnail_url_Read"] | undefined,
	update?: ModelTypes["BackupMediaDocAccessFields_sizes_thumbnail_url_Update"] | undefined,
	delete?: ModelTypes["BackupMediaDocAccessFields_sizes_thumbnail_url_Delete"] | undefined
};
	["BackupMediaDocAccessFields_sizes_thumbnail_url_Create"]: {
		permission: boolean
};
	["BackupMediaDocAccessFields_sizes_thumbnail_url_Read"]: {
		permission: boolean
};
	["BackupMediaDocAccessFields_sizes_thumbnail_url_Update"]: {
		permission: boolean
};
	["BackupMediaDocAccessFields_sizes_thumbnail_url_Delete"]: {
		permission: boolean
};
	["BackupMediaDocAccessFields_sizes_thumbnail_width"]: {
		create?: ModelTypes["BackupMediaDocAccessFields_sizes_thumbnail_width_Create"] | undefined,
	read?: ModelTypes["BackupMediaDocAccessFields_sizes_thumbnail_width_Read"] | undefined,
	update?: ModelTypes["BackupMediaDocAccessFields_sizes_thumbnail_width_Update"] | undefined,
	delete?: ModelTypes["BackupMediaDocAccessFields_sizes_thumbnail_width_Delete"] | undefined
};
	["BackupMediaDocAccessFields_sizes_thumbnail_width_Create"]: {
		permission: boolean
};
	["BackupMediaDocAccessFields_sizes_thumbnail_width_Read"]: {
		permission: boolean
};
	["BackupMediaDocAccessFields_sizes_thumbnail_width_Update"]: {
		permission: boolean
};
	["BackupMediaDocAccessFields_sizes_thumbnail_width_Delete"]: {
		permission: boolean
};
	["BackupMediaDocAccessFields_sizes_thumbnail_height"]: {
		create?: ModelTypes["BackupMediaDocAccessFields_sizes_thumbnail_height_Create"] | undefined,
	read?: ModelTypes["BackupMediaDocAccessFields_sizes_thumbnail_height_Read"] | undefined,
	update?: ModelTypes["BackupMediaDocAccessFields_sizes_thumbnail_height_Update"] | undefined,
	delete?: ModelTypes["BackupMediaDocAccessFields_sizes_thumbnail_height_Delete"] | undefined
};
	["BackupMediaDocAccessFields_sizes_thumbnail_height_Create"]: {
		permission: boolean
};
	["BackupMediaDocAccessFields_sizes_thumbnail_height_Read"]: {
		permission: boolean
};
	["BackupMediaDocAccessFields_sizes_thumbnail_height_Update"]: {
		permission: boolean
};
	["BackupMediaDocAccessFields_sizes_thumbnail_height_Delete"]: {
		permission: boolean
};
	["BackupMediaDocAccessFields_sizes_thumbnail_mimeType"]: {
		create?: ModelTypes["BackupMediaDocAccessFields_sizes_thumbnail_mimeType_Create"] | undefined,
	read?: ModelTypes["BackupMediaDocAccessFields_sizes_thumbnail_mimeType_Read"] | undefined,
	update?: ModelTypes["BackupMediaDocAccessFields_sizes_thumbnail_mimeType_Update"] | undefined,
	delete?: ModelTypes["BackupMediaDocAccessFields_sizes_thumbnail_mimeType_Delete"] | undefined
};
	["BackupMediaDocAccessFields_sizes_thumbnail_mimeType_Create"]: {
		permission: boolean
};
	["BackupMediaDocAccessFields_sizes_thumbnail_mimeType_Read"]: {
		permission: boolean
};
	["BackupMediaDocAccessFields_sizes_thumbnail_mimeType_Update"]: {
		permission: boolean
};
	["BackupMediaDocAccessFields_sizes_thumbnail_mimeType_Delete"]: {
		permission: boolean
};
	["BackupMediaDocAccessFields_sizes_thumbnail_filesize"]: {
		create?: ModelTypes["BackupMediaDocAccessFields_sizes_thumbnail_filesize_Create"] | undefined,
	read?: ModelTypes["BackupMediaDocAccessFields_sizes_thumbnail_filesize_Read"] | undefined,
	update?: ModelTypes["BackupMediaDocAccessFields_sizes_thumbnail_filesize_Update"] | undefined,
	delete?: ModelTypes["BackupMediaDocAccessFields_sizes_thumbnail_filesize_Delete"] | undefined
};
	["BackupMediaDocAccessFields_sizes_thumbnail_filesize_Create"]: {
		permission: boolean
};
	["BackupMediaDocAccessFields_sizes_thumbnail_filesize_Read"]: {
		permission: boolean
};
	["BackupMediaDocAccessFields_sizes_thumbnail_filesize_Update"]: {
		permission: boolean
};
	["BackupMediaDocAccessFields_sizes_thumbnail_filesize_Delete"]: {
		permission: boolean
};
	["BackupMediaDocAccessFields_sizes_thumbnail_filename"]: {
		create?: ModelTypes["BackupMediaDocAccessFields_sizes_thumbnail_filename_Create"] | undefined,
	read?: ModelTypes["BackupMediaDocAccessFields_sizes_thumbnail_filename_Read"] | undefined,
	update?: ModelTypes["BackupMediaDocAccessFields_sizes_thumbnail_filename_Update"] | undefined,
	delete?: ModelTypes["BackupMediaDocAccessFields_sizes_thumbnail_filename_Delete"] | undefined
};
	["BackupMediaDocAccessFields_sizes_thumbnail_filename_Create"]: {
		permission: boolean
};
	["BackupMediaDocAccessFields_sizes_thumbnail_filename_Read"]: {
		permission: boolean
};
	["BackupMediaDocAccessFields_sizes_thumbnail_filename_Update"]: {
		permission: boolean
};
	["BackupMediaDocAccessFields_sizes_thumbnail_filename_Delete"]: {
		permission: boolean
};
	["BackupMediaDocAccessFields_sizes_sixteenByNineMedium"]: {
		create?: ModelTypes["BackupMediaDocAccessFields_sizes_sixteenByNineMedium_Create"] | undefined,
	read?: ModelTypes["BackupMediaDocAccessFields_sizes_sixteenByNineMedium_Read"] | undefined,
	update?: ModelTypes["BackupMediaDocAccessFields_sizes_sixteenByNineMedium_Update"] | undefined,
	delete?: ModelTypes["BackupMediaDocAccessFields_sizes_sixteenByNineMedium_Delete"] | undefined,
	fields?: ModelTypes["BackupMediaDocAccessFields_sizes_sixteenByNineMedium_Fields"] | undefined
};
	["BackupMediaDocAccessFields_sizes_sixteenByNineMedium_Create"]: {
		permission: boolean
};
	["BackupMediaDocAccessFields_sizes_sixteenByNineMedium_Read"]: {
		permission: boolean
};
	["BackupMediaDocAccessFields_sizes_sixteenByNineMedium_Update"]: {
		permission: boolean
};
	["BackupMediaDocAccessFields_sizes_sixteenByNineMedium_Delete"]: {
		permission: boolean
};
	["BackupMediaDocAccessFields_sizes_sixteenByNineMedium_Fields"]: {
		url?: ModelTypes["BackupMediaDocAccessFields_sizes_sixteenByNineMedium_url"] | undefined,
	width?: ModelTypes["BackupMediaDocAccessFields_sizes_sixteenByNineMedium_width"] | undefined,
	height?: ModelTypes["BackupMediaDocAccessFields_sizes_sixteenByNineMedium_height"] | undefined,
	mimeType?: ModelTypes["BackupMediaDocAccessFields_sizes_sixteenByNineMedium_mimeType"] | undefined,
	filesize?: ModelTypes["BackupMediaDocAccessFields_sizes_sixteenByNineMedium_filesize"] | undefined,
	filename?: ModelTypes["BackupMediaDocAccessFields_sizes_sixteenByNineMedium_filename"] | undefined
};
	["BackupMediaDocAccessFields_sizes_sixteenByNineMedium_url"]: {
		create?: ModelTypes["BackupMediaDocAccessFields_sizes_sixteenByNineMedium_url_Create"] | undefined,
	read?: ModelTypes["BackupMediaDocAccessFields_sizes_sixteenByNineMedium_url_Read"] | undefined,
	update?: ModelTypes["BackupMediaDocAccessFields_sizes_sixteenByNineMedium_url_Update"] | undefined,
	delete?: ModelTypes["BackupMediaDocAccessFields_sizes_sixteenByNineMedium_url_Delete"] | undefined
};
	["BackupMediaDocAccessFields_sizes_sixteenByNineMedium_url_Create"]: {
		permission: boolean
};
	["BackupMediaDocAccessFields_sizes_sixteenByNineMedium_url_Read"]: {
		permission: boolean
};
	["BackupMediaDocAccessFields_sizes_sixteenByNineMedium_url_Update"]: {
		permission: boolean
};
	["BackupMediaDocAccessFields_sizes_sixteenByNineMedium_url_Delete"]: {
		permission: boolean
};
	["BackupMediaDocAccessFields_sizes_sixteenByNineMedium_width"]: {
		create?: ModelTypes["BackupMediaDocAccessFields_sizes_sixteenByNineMedium_width_Create"] | undefined,
	read?: ModelTypes["BackupMediaDocAccessFields_sizes_sixteenByNineMedium_width_Read"] | undefined,
	update?: ModelTypes["BackupMediaDocAccessFields_sizes_sixteenByNineMedium_width_Update"] | undefined,
	delete?: ModelTypes["BackupMediaDocAccessFields_sizes_sixteenByNineMedium_width_Delete"] | undefined
};
	["BackupMediaDocAccessFields_sizes_sixteenByNineMedium_width_Create"]: {
		permission: boolean
};
	["BackupMediaDocAccessFields_sizes_sixteenByNineMedium_width_Read"]: {
		permission: boolean
};
	["BackupMediaDocAccessFields_sizes_sixteenByNineMedium_width_Update"]: {
		permission: boolean
};
	["BackupMediaDocAccessFields_sizes_sixteenByNineMedium_width_Delete"]: {
		permission: boolean
};
	["BackupMediaDocAccessFields_sizes_sixteenByNineMedium_height"]: {
		create?: ModelTypes["BackupMediaDocAccessFields_sizes_sixteenByNineMedium_height_Create"] | undefined,
	read?: ModelTypes["BackupMediaDocAccessFields_sizes_sixteenByNineMedium_height_Read"] | undefined,
	update?: ModelTypes["BackupMediaDocAccessFields_sizes_sixteenByNineMedium_height_Update"] | undefined,
	delete?: ModelTypes["BackupMediaDocAccessFields_sizes_sixteenByNineMedium_height_Delete"] | undefined
};
	["BackupMediaDocAccessFields_sizes_sixteenByNineMedium_height_Create"]: {
		permission: boolean
};
	["BackupMediaDocAccessFields_sizes_sixteenByNineMedium_height_Read"]: {
		permission: boolean
};
	["BackupMediaDocAccessFields_sizes_sixteenByNineMedium_height_Update"]: {
		permission: boolean
};
	["BackupMediaDocAccessFields_sizes_sixteenByNineMedium_height_Delete"]: {
		permission: boolean
};
	["BackupMediaDocAccessFields_sizes_sixteenByNineMedium_mimeType"]: {
		create?: ModelTypes["BackupMediaDocAccessFields_sizes_sixteenByNineMedium_mimeType_Create"] | undefined,
	read?: ModelTypes["BackupMediaDocAccessFields_sizes_sixteenByNineMedium_mimeType_Read"] | undefined,
	update?: ModelTypes["BackupMediaDocAccessFields_sizes_sixteenByNineMedium_mimeType_Update"] | undefined,
	delete?: ModelTypes["BackupMediaDocAccessFields_sizes_sixteenByNineMedium_mimeType_Delete"] | undefined
};
	["BackupMediaDocAccessFields_sizes_sixteenByNineMedium_mimeType_Create"]: {
		permission: boolean
};
	["BackupMediaDocAccessFields_sizes_sixteenByNineMedium_mimeType_Read"]: {
		permission: boolean
};
	["BackupMediaDocAccessFields_sizes_sixteenByNineMedium_mimeType_Update"]: {
		permission: boolean
};
	["BackupMediaDocAccessFields_sizes_sixteenByNineMedium_mimeType_Delete"]: {
		permission: boolean
};
	["BackupMediaDocAccessFields_sizes_sixteenByNineMedium_filesize"]: {
		create?: ModelTypes["BackupMediaDocAccessFields_sizes_sixteenByNineMedium_filesize_Create"] | undefined,
	read?: ModelTypes["BackupMediaDocAccessFields_sizes_sixteenByNineMedium_filesize_Read"] | undefined,
	update?: ModelTypes["BackupMediaDocAccessFields_sizes_sixteenByNineMedium_filesize_Update"] | undefined,
	delete?: ModelTypes["BackupMediaDocAccessFields_sizes_sixteenByNineMedium_filesize_Delete"] | undefined
};
	["BackupMediaDocAccessFields_sizes_sixteenByNineMedium_filesize_Create"]: {
		permission: boolean
};
	["BackupMediaDocAccessFields_sizes_sixteenByNineMedium_filesize_Read"]: {
		permission: boolean
};
	["BackupMediaDocAccessFields_sizes_sixteenByNineMedium_filesize_Update"]: {
		permission: boolean
};
	["BackupMediaDocAccessFields_sizes_sixteenByNineMedium_filesize_Delete"]: {
		permission: boolean
};
	["BackupMediaDocAccessFields_sizes_sixteenByNineMedium_filename"]: {
		create?: ModelTypes["BackupMediaDocAccessFields_sizes_sixteenByNineMedium_filename_Create"] | undefined,
	read?: ModelTypes["BackupMediaDocAccessFields_sizes_sixteenByNineMedium_filename_Read"] | undefined,
	update?: ModelTypes["BackupMediaDocAccessFields_sizes_sixteenByNineMedium_filename_Update"] | undefined,
	delete?: ModelTypes["BackupMediaDocAccessFields_sizes_sixteenByNineMedium_filename_Delete"] | undefined
};
	["BackupMediaDocAccessFields_sizes_sixteenByNineMedium_filename_Create"]: {
		permission: boolean
};
	["BackupMediaDocAccessFields_sizes_sixteenByNineMedium_filename_Read"]: {
		permission: boolean
};
	["BackupMediaDocAccessFields_sizes_sixteenByNineMedium_filename_Update"]: {
		permission: boolean
};
	["BackupMediaDocAccessFields_sizes_sixteenByNineMedium_filename_Delete"]: {
		permission: boolean
};
	["BackupMediaCreateDocAccess"]: {
		permission: boolean,
	where?: ModelTypes["JSONObject"] | undefined
};
	["BackupMediaReadDocAccess"]: {
		permission: boolean,
	where?: ModelTypes["JSONObject"] | undefined
};
	["BackupMediaUpdateDocAccess"]: {
		permission: boolean,
	where?: ModelTypes["JSONObject"] | undefined
};
	["BackupMediaDeleteDocAccess"]: {
		permission: boolean,
	where?: ModelTypes["JSONObject"] | undefined
};
	["AccountSuspendRequest"]: {
		id?: string | undefined,
	user?: ModelTypes["User"] | undefined,
	handledBy?: ModelTypes["User"] | undefined,
	updatedAt?: ModelTypes["DateTime"] | undefined,
	createdAt?: ModelTypes["DateTime"] | undefined
};
	["AccountSuspendRequests"]: {
		docs?: Array<ModelTypes["AccountSuspendRequest"] | undefined> | undefined,
	hasNextPage?: boolean | undefined,
	hasPrevPage?: boolean | undefined,
	limit?: number | undefined,
	nextPage?: number | undefined,
	offset?: number | undefined,
	page?: number | undefined,
	pagingCounter?: number | undefined,
	prevPage?: number | undefined,
	totalDocs?: number | undefined,
	totalPages?: number | undefined
};
	["AccountSuspendRequest_where"]: {
	user?: ModelTypes["AccountSuspendRequest_user_operator"] | undefined,
	handledBy?: ModelTypes["AccountSuspendRequest_handledBy_operator"] | undefined,
	updatedAt?: ModelTypes["AccountSuspendRequest_updatedAt_operator"] | undefined,
	createdAt?: ModelTypes["AccountSuspendRequest_createdAt_operator"] | undefined,
	id?: ModelTypes["AccountSuspendRequest_id_operator"] | undefined,
	AND?: Array<ModelTypes["AccountSuspendRequest_where_and"] | undefined> | undefined,
	OR?: Array<ModelTypes["AccountSuspendRequest_where_or"] | undefined> | undefined
};
	["AccountSuspendRequest_user_operator"]: {
	equals?: ModelTypes["JSON"] | undefined,
	not_equals?: ModelTypes["JSON"] | undefined,
	in?: Array<ModelTypes["JSON"] | undefined> | undefined,
	not_in?: Array<ModelTypes["JSON"] | undefined> | undefined,
	all?: Array<ModelTypes["JSON"] | undefined> | undefined,
	exists?: boolean | undefined
};
	["AccountSuspendRequest_handledBy_operator"]: {
	equals?: ModelTypes["JSON"] | undefined,
	not_equals?: ModelTypes["JSON"] | undefined,
	in?: Array<ModelTypes["JSON"] | undefined> | undefined,
	not_in?: Array<ModelTypes["JSON"] | undefined> | undefined,
	all?: Array<ModelTypes["JSON"] | undefined> | undefined,
	exists?: boolean | undefined
};
	["AccountSuspendRequest_updatedAt_operator"]: {
	equals?: ModelTypes["DateTime"] | undefined,
	not_equals?: ModelTypes["DateTime"] | undefined,
	greater_than_equal?: ModelTypes["DateTime"] | undefined,
	greater_than?: ModelTypes["DateTime"] | undefined,
	less_than_equal?: ModelTypes["DateTime"] | undefined,
	less_than?: ModelTypes["DateTime"] | undefined,
	like?: ModelTypes["DateTime"] | undefined,
	exists?: boolean | undefined
};
	["AccountSuspendRequest_createdAt_operator"]: {
	equals?: ModelTypes["DateTime"] | undefined,
	not_equals?: ModelTypes["DateTime"] | undefined,
	greater_than_equal?: ModelTypes["DateTime"] | undefined,
	greater_than?: ModelTypes["DateTime"] | undefined,
	less_than_equal?: ModelTypes["DateTime"] | undefined,
	less_than?: ModelTypes["DateTime"] | undefined,
	like?: ModelTypes["DateTime"] | undefined,
	exists?: boolean | undefined
};
	["AccountSuspendRequest_id_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["AccountSuspendRequest_where_and"]: {
	user?: ModelTypes["AccountSuspendRequest_user_operator"] | undefined,
	handledBy?: ModelTypes["AccountSuspendRequest_handledBy_operator"] | undefined,
	updatedAt?: ModelTypes["AccountSuspendRequest_updatedAt_operator"] | undefined,
	createdAt?: ModelTypes["AccountSuspendRequest_createdAt_operator"] | undefined,
	id?: ModelTypes["AccountSuspendRequest_id_operator"] | undefined,
	AND?: Array<ModelTypes["AccountSuspendRequest_where_and"] | undefined> | undefined,
	OR?: Array<ModelTypes["AccountSuspendRequest_where_or"] | undefined> | undefined
};
	["AccountSuspendRequest_where_or"]: {
	user?: ModelTypes["AccountSuspendRequest_user_operator"] | undefined,
	handledBy?: ModelTypes["AccountSuspendRequest_handledBy_operator"] | undefined,
	updatedAt?: ModelTypes["AccountSuspendRequest_updatedAt_operator"] | undefined,
	createdAt?: ModelTypes["AccountSuspendRequest_createdAt_operator"] | undefined,
	id?: ModelTypes["AccountSuspendRequest_id_operator"] | undefined,
	AND?: Array<ModelTypes["AccountSuspendRequest_where_and"] | undefined> | undefined,
	OR?: Array<ModelTypes["AccountSuspendRequest_where_or"] | undefined> | undefined
};
	["countAccountSuspendRequests"]: {
		totalDocs?: number | undefined
};
	["account_suspend_requestsDocAccess"]: {
		fields?: ModelTypes["AccountSuspendRequestsDocAccessFields"] | undefined,
	create?: ModelTypes["AccountSuspendRequestsCreateDocAccess"] | undefined,
	read?: ModelTypes["AccountSuspendRequestsReadDocAccess"] | undefined,
	update?: ModelTypes["AccountSuspendRequestsUpdateDocAccess"] | undefined,
	delete?: ModelTypes["AccountSuspendRequestsDeleteDocAccess"] | undefined
};
	["AccountSuspendRequestsDocAccessFields"]: {
		user?: ModelTypes["AccountSuspendRequestsDocAccessFields_user"] | undefined,
	handledBy?: ModelTypes["AccountSuspendRequestsDocAccessFields_handledBy"] | undefined,
	updatedAt?: ModelTypes["AccountSuspendRequestsDocAccessFields_updatedAt"] | undefined,
	createdAt?: ModelTypes["AccountSuspendRequestsDocAccessFields_createdAt"] | undefined
};
	["AccountSuspendRequestsDocAccessFields_user"]: {
		create?: ModelTypes["AccountSuspendRequestsDocAccessFields_user_Create"] | undefined,
	read?: ModelTypes["AccountSuspendRequestsDocAccessFields_user_Read"] | undefined,
	update?: ModelTypes["AccountSuspendRequestsDocAccessFields_user_Update"] | undefined,
	delete?: ModelTypes["AccountSuspendRequestsDocAccessFields_user_Delete"] | undefined
};
	["AccountSuspendRequestsDocAccessFields_user_Create"]: {
		permission: boolean
};
	["AccountSuspendRequestsDocAccessFields_user_Read"]: {
		permission: boolean
};
	["AccountSuspendRequestsDocAccessFields_user_Update"]: {
		permission: boolean
};
	["AccountSuspendRequestsDocAccessFields_user_Delete"]: {
		permission: boolean
};
	["AccountSuspendRequestsDocAccessFields_handledBy"]: {
		create?: ModelTypes["AccountSuspendRequestsDocAccessFields_handledBy_Create"] | undefined,
	read?: ModelTypes["AccountSuspendRequestsDocAccessFields_handledBy_Read"] | undefined,
	update?: ModelTypes["AccountSuspendRequestsDocAccessFields_handledBy_Update"] | undefined,
	delete?: ModelTypes["AccountSuspendRequestsDocAccessFields_handledBy_Delete"] | undefined
};
	["AccountSuspendRequestsDocAccessFields_handledBy_Create"]: {
		permission: boolean
};
	["AccountSuspendRequestsDocAccessFields_handledBy_Read"]: {
		permission: boolean
};
	["AccountSuspendRequestsDocAccessFields_handledBy_Update"]: {
		permission: boolean
};
	["AccountSuspendRequestsDocAccessFields_handledBy_Delete"]: {
		permission: boolean
};
	["AccountSuspendRequestsDocAccessFields_updatedAt"]: {
		create?: ModelTypes["AccountSuspendRequestsDocAccessFields_updatedAt_Create"] | undefined,
	read?: ModelTypes["AccountSuspendRequestsDocAccessFields_updatedAt_Read"] | undefined,
	update?: ModelTypes["AccountSuspendRequestsDocAccessFields_updatedAt_Update"] | undefined,
	delete?: ModelTypes["AccountSuspendRequestsDocAccessFields_updatedAt_Delete"] | undefined
};
	["AccountSuspendRequestsDocAccessFields_updatedAt_Create"]: {
		permission: boolean
};
	["AccountSuspendRequestsDocAccessFields_updatedAt_Read"]: {
		permission: boolean
};
	["AccountSuspendRequestsDocAccessFields_updatedAt_Update"]: {
		permission: boolean
};
	["AccountSuspendRequestsDocAccessFields_updatedAt_Delete"]: {
		permission: boolean
};
	["AccountSuspendRequestsDocAccessFields_createdAt"]: {
		create?: ModelTypes["AccountSuspendRequestsDocAccessFields_createdAt_Create"] | undefined,
	read?: ModelTypes["AccountSuspendRequestsDocAccessFields_createdAt_Read"] | undefined,
	update?: ModelTypes["AccountSuspendRequestsDocAccessFields_createdAt_Update"] | undefined,
	delete?: ModelTypes["AccountSuspendRequestsDocAccessFields_createdAt_Delete"] | undefined
};
	["AccountSuspendRequestsDocAccessFields_createdAt_Create"]: {
		permission: boolean
};
	["AccountSuspendRequestsDocAccessFields_createdAt_Read"]: {
		permission: boolean
};
	["AccountSuspendRequestsDocAccessFields_createdAt_Update"]: {
		permission: boolean
};
	["AccountSuspendRequestsDocAccessFields_createdAt_Delete"]: {
		permission: boolean
};
	["AccountSuspendRequestsCreateDocAccess"]: {
		permission: boolean,
	where?: ModelTypes["JSONObject"] | undefined
};
	["AccountSuspendRequestsReadDocAccess"]: {
		permission: boolean,
	where?: ModelTypes["JSONObject"] | undefined
};
	["AccountSuspendRequestsUpdateDocAccess"]: {
		permission: boolean,
	where?: ModelTypes["JSONObject"] | undefined
};
	["AccountSuspendRequestsDeleteDocAccess"]: {
		permission: boolean,
	where?: ModelTypes["JSONObject"] | undefined
};
	["Device"]: {
		id?: string | undefined,
	user?: ModelTypes["Device_User_Relationship"] | undefined,
	udid?: string | undefined,
	pushToken?: string | undefined,
	updatedAt?: ModelTypes["DateTime"] | undefined,
	createdAt?: ModelTypes["DateTime"] | undefined
};
	["Device_User_Relationship"]: {
		relationTo?: ModelTypes["Device_User_RelationTo"] | undefined,
	value?: ModelTypes["Device_User"] | undefined
};
	["Device_User_RelationTo"]:Device_User_RelationTo;
	["Device_User"]:ModelTypes["User"];
	["Devices"]: {
		docs?: Array<ModelTypes["Device"] | undefined> | undefined,
	hasNextPage?: boolean | undefined,
	hasPrevPage?: boolean | undefined,
	limit?: number | undefined,
	nextPage?: number | undefined,
	offset?: number | undefined,
	page?: number | undefined,
	pagingCounter?: number | undefined,
	prevPage?: number | undefined,
	totalDocs?: number | undefined,
	totalPages?: number | undefined
};
	["Device_where"]: {
	user?: ModelTypes["Device_user_Relation"] | undefined,
	udid?: ModelTypes["Device_udid_operator"] | undefined,
	pushToken?: ModelTypes["Device_pushToken_operator"] | undefined,
	updatedAt?: ModelTypes["Device_updatedAt_operator"] | undefined,
	createdAt?: ModelTypes["Device_createdAt_operator"] | undefined,
	id?: ModelTypes["Device_id_operator"] | undefined,
	AND?: Array<ModelTypes["Device_where_and"] | undefined> | undefined,
	OR?: Array<ModelTypes["Device_where_or"] | undefined> | undefined
};
	["Device_user_Relation"]: {
	relationTo?: ModelTypes["Device_user_Relation_RelationTo"] | undefined,
	value?: ModelTypes["JSON"] | undefined
};
	["Device_user_Relation_RelationTo"]:Device_user_Relation_RelationTo;
	["Device_udid_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Device_pushToken_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Device_updatedAt_operator"]: {
	equals?: ModelTypes["DateTime"] | undefined,
	not_equals?: ModelTypes["DateTime"] | undefined,
	greater_than_equal?: ModelTypes["DateTime"] | undefined,
	greater_than?: ModelTypes["DateTime"] | undefined,
	less_than_equal?: ModelTypes["DateTime"] | undefined,
	less_than?: ModelTypes["DateTime"] | undefined,
	like?: ModelTypes["DateTime"] | undefined,
	exists?: boolean | undefined
};
	["Device_createdAt_operator"]: {
	equals?: ModelTypes["DateTime"] | undefined,
	not_equals?: ModelTypes["DateTime"] | undefined,
	greater_than_equal?: ModelTypes["DateTime"] | undefined,
	greater_than?: ModelTypes["DateTime"] | undefined,
	less_than_equal?: ModelTypes["DateTime"] | undefined,
	less_than?: ModelTypes["DateTime"] | undefined,
	like?: ModelTypes["DateTime"] | undefined,
	exists?: boolean | undefined
};
	["Device_id_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Device_where_and"]: {
	user?: ModelTypes["Device_user_Relation"] | undefined,
	udid?: ModelTypes["Device_udid_operator"] | undefined,
	pushToken?: ModelTypes["Device_pushToken_operator"] | undefined,
	updatedAt?: ModelTypes["Device_updatedAt_operator"] | undefined,
	createdAt?: ModelTypes["Device_createdAt_operator"] | undefined,
	id?: ModelTypes["Device_id_operator"] | undefined,
	AND?: Array<ModelTypes["Device_where_and"] | undefined> | undefined,
	OR?: Array<ModelTypes["Device_where_or"] | undefined> | undefined
};
	["Device_where_or"]: {
	user?: ModelTypes["Device_user_Relation"] | undefined,
	udid?: ModelTypes["Device_udid_operator"] | undefined,
	pushToken?: ModelTypes["Device_pushToken_operator"] | undefined,
	updatedAt?: ModelTypes["Device_updatedAt_operator"] | undefined,
	createdAt?: ModelTypes["Device_createdAt_operator"] | undefined,
	id?: ModelTypes["Device_id_operator"] | undefined,
	AND?: Array<ModelTypes["Device_where_and"] | undefined> | undefined,
	OR?: Array<ModelTypes["Device_where_or"] | undefined> | undefined
};
	["countDevices"]: {
		totalDocs?: number | undefined
};
	["devicesDocAccess"]: {
		fields?: ModelTypes["DevicesDocAccessFields"] | undefined,
	create?: ModelTypes["DevicesCreateDocAccess"] | undefined,
	read?: ModelTypes["DevicesReadDocAccess"] | undefined,
	update?: ModelTypes["DevicesUpdateDocAccess"] | undefined,
	delete?: ModelTypes["DevicesDeleteDocAccess"] | undefined
};
	["DevicesDocAccessFields"]: {
		user?: ModelTypes["DevicesDocAccessFields_user"] | undefined,
	udid?: ModelTypes["DevicesDocAccessFields_udid"] | undefined,
	pushToken?: ModelTypes["DevicesDocAccessFields_pushToken"] | undefined,
	updatedAt?: ModelTypes["DevicesDocAccessFields_updatedAt"] | undefined,
	createdAt?: ModelTypes["DevicesDocAccessFields_createdAt"] | undefined
};
	["DevicesDocAccessFields_user"]: {
		create?: ModelTypes["DevicesDocAccessFields_user_Create"] | undefined,
	read?: ModelTypes["DevicesDocAccessFields_user_Read"] | undefined,
	update?: ModelTypes["DevicesDocAccessFields_user_Update"] | undefined,
	delete?: ModelTypes["DevicesDocAccessFields_user_Delete"] | undefined
};
	["DevicesDocAccessFields_user_Create"]: {
		permission: boolean
};
	["DevicesDocAccessFields_user_Read"]: {
		permission: boolean
};
	["DevicesDocAccessFields_user_Update"]: {
		permission: boolean
};
	["DevicesDocAccessFields_user_Delete"]: {
		permission: boolean
};
	["DevicesDocAccessFields_udid"]: {
		create?: ModelTypes["DevicesDocAccessFields_udid_Create"] | undefined,
	read?: ModelTypes["DevicesDocAccessFields_udid_Read"] | undefined,
	update?: ModelTypes["DevicesDocAccessFields_udid_Update"] | undefined,
	delete?: ModelTypes["DevicesDocAccessFields_udid_Delete"] | undefined
};
	["DevicesDocAccessFields_udid_Create"]: {
		permission: boolean
};
	["DevicesDocAccessFields_udid_Read"]: {
		permission: boolean
};
	["DevicesDocAccessFields_udid_Update"]: {
		permission: boolean
};
	["DevicesDocAccessFields_udid_Delete"]: {
		permission: boolean
};
	["DevicesDocAccessFields_pushToken"]: {
		create?: ModelTypes["DevicesDocAccessFields_pushToken_Create"] | undefined,
	read?: ModelTypes["DevicesDocAccessFields_pushToken_Read"] | undefined,
	update?: ModelTypes["DevicesDocAccessFields_pushToken_Update"] | undefined,
	delete?: ModelTypes["DevicesDocAccessFields_pushToken_Delete"] | undefined
};
	["DevicesDocAccessFields_pushToken_Create"]: {
		permission: boolean
};
	["DevicesDocAccessFields_pushToken_Read"]: {
		permission: boolean
};
	["DevicesDocAccessFields_pushToken_Update"]: {
		permission: boolean
};
	["DevicesDocAccessFields_pushToken_Delete"]: {
		permission: boolean
};
	["DevicesDocAccessFields_updatedAt"]: {
		create?: ModelTypes["DevicesDocAccessFields_updatedAt_Create"] | undefined,
	read?: ModelTypes["DevicesDocAccessFields_updatedAt_Read"] | undefined,
	update?: ModelTypes["DevicesDocAccessFields_updatedAt_Update"] | undefined,
	delete?: ModelTypes["DevicesDocAccessFields_updatedAt_Delete"] | undefined
};
	["DevicesDocAccessFields_updatedAt_Create"]: {
		permission: boolean
};
	["DevicesDocAccessFields_updatedAt_Read"]: {
		permission: boolean
};
	["DevicesDocAccessFields_updatedAt_Update"]: {
		permission: boolean
};
	["DevicesDocAccessFields_updatedAt_Delete"]: {
		permission: boolean
};
	["DevicesDocAccessFields_createdAt"]: {
		create?: ModelTypes["DevicesDocAccessFields_createdAt_Create"] | undefined,
	read?: ModelTypes["DevicesDocAccessFields_createdAt_Read"] | undefined,
	update?: ModelTypes["DevicesDocAccessFields_createdAt_Update"] | undefined,
	delete?: ModelTypes["DevicesDocAccessFields_createdAt_Delete"] | undefined
};
	["DevicesDocAccessFields_createdAt_Create"]: {
		permission: boolean
};
	["DevicesDocAccessFields_createdAt_Read"]: {
		permission: boolean
};
	["DevicesDocAccessFields_createdAt_Update"]: {
		permission: boolean
};
	["DevicesDocAccessFields_createdAt_Delete"]: {
		permission: boolean
};
	["DevicesCreateDocAccess"]: {
		permission: boolean,
	where?: ModelTypes["JSONObject"] | undefined
};
	["DevicesReadDocAccess"]: {
		permission: boolean,
	where?: ModelTypes["JSONObject"] | undefined
};
	["DevicesUpdateDocAccess"]: {
		permission: boolean,
	where?: ModelTypes["JSONObject"] | undefined
};
	["DevicesDeleteDocAccess"]: {
		permission: boolean,
	where?: ModelTypes["JSONObject"] | undefined
};
	["Notification"]: {
		id?: string | undefined,
	receiver?: Array<ModelTypes["User"]> | undefined,
	type?: ModelTypes["Notification_type"] | undefined,
	meta?: ModelTypes["Notification_Meta_Relationship"] | undefined,
	message?: string | undefined,
	sender?: ModelTypes["User"] | undefined,
	readed?: boolean | undefined,
	deletedByUser?: boolean | undefined,
	updatedAt?: ModelTypes["DateTime"] | undefined,
	createdAt?: ModelTypes["DateTime"] | undefined
};
	["Notification_type"]:Notification_type;
	["Notification_Meta_Relationship"]: {
		relationTo?: ModelTypes["Notification_Meta_RelationTo"] | undefined,
	value?: ModelTypes["Notification_Meta"] | undefined
};
	["Notification_Meta_RelationTo"]:Notification_Meta_RelationTo;
	["Notification_Meta"]:ModelTypes["Event"] | ModelTypes["Location"] | ModelTypes["Post"] | ModelTypes["Comment"];
	["Notifications"]: {
		docs?: Array<ModelTypes["Notification"] | undefined> | undefined,
	hasNextPage?: boolean | undefined,
	hasPrevPage?: boolean | undefined,
	limit?: number | undefined,
	nextPage?: number | undefined,
	offset?: number | undefined,
	page?: number | undefined,
	pagingCounter?: number | undefined,
	prevPage?: number | undefined,
	totalDocs?: number | undefined,
	totalPages?: number | undefined
};
	["Notification_where"]: {
	receiver?: ModelTypes["Notification_receiver_operator"] | undefined,
	type?: ModelTypes["Notification_type_operator"] | undefined,
	meta?: ModelTypes["Notification_meta_Relation"] | undefined,
	message?: ModelTypes["Notification_message_operator"] | undefined,
	sender?: ModelTypes["Notification_sender_operator"] | undefined,
	readed?: ModelTypes["Notification_readed_operator"] | undefined,
	deletedByUser?: ModelTypes["Notification_deletedByUser_operator"] | undefined,
	updatedAt?: ModelTypes["Notification_updatedAt_operator"] | undefined,
	createdAt?: ModelTypes["Notification_createdAt_operator"] | undefined,
	id?: ModelTypes["Notification_id_operator"] | undefined,
	AND?: Array<ModelTypes["Notification_where_and"] | undefined> | undefined,
	OR?: Array<ModelTypes["Notification_where_or"] | undefined> | undefined
};
	["Notification_receiver_operator"]: {
	equals?: ModelTypes["JSON"] | undefined,
	not_equals?: ModelTypes["JSON"] | undefined,
	in?: Array<ModelTypes["JSON"] | undefined> | undefined,
	not_in?: Array<ModelTypes["JSON"] | undefined> | undefined,
	all?: Array<ModelTypes["JSON"] | undefined> | undefined,
	exists?: boolean | undefined
};
	["Notification_type_operator"]: {
	equals?: ModelTypes["Notification_type_Input"] | undefined,
	not_equals?: ModelTypes["Notification_type_Input"] | undefined,
	in?: Array<ModelTypes["Notification_type_Input"] | undefined> | undefined,
	not_in?: Array<ModelTypes["Notification_type_Input"] | undefined> | undefined,
	all?: Array<ModelTypes["Notification_type_Input"] | undefined> | undefined,
	exists?: boolean | undefined
};
	["Notification_type_Input"]:Notification_type_Input;
	["Notification_meta_Relation"]: {
	relationTo?: ModelTypes["Notification_meta_Relation_RelationTo"] | undefined,
	value?: ModelTypes["JSON"] | undefined
};
	["Notification_meta_Relation_RelationTo"]:Notification_meta_Relation_RelationTo;
	["Notification_message_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Notification_sender_operator"]: {
	equals?: ModelTypes["JSON"] | undefined,
	not_equals?: ModelTypes["JSON"] | undefined,
	in?: Array<ModelTypes["JSON"] | undefined> | undefined,
	not_in?: Array<ModelTypes["JSON"] | undefined> | undefined,
	all?: Array<ModelTypes["JSON"] | undefined> | undefined,
	exists?: boolean | undefined
};
	["Notification_readed_operator"]: {
	equals?: boolean | undefined,
	not_equals?: boolean | undefined,
	exists?: boolean | undefined
};
	["Notification_deletedByUser_operator"]: {
	equals?: boolean | undefined,
	not_equals?: boolean | undefined,
	exists?: boolean | undefined
};
	["Notification_updatedAt_operator"]: {
	equals?: ModelTypes["DateTime"] | undefined,
	not_equals?: ModelTypes["DateTime"] | undefined,
	greater_than_equal?: ModelTypes["DateTime"] | undefined,
	greater_than?: ModelTypes["DateTime"] | undefined,
	less_than_equal?: ModelTypes["DateTime"] | undefined,
	less_than?: ModelTypes["DateTime"] | undefined,
	like?: ModelTypes["DateTime"] | undefined,
	exists?: boolean | undefined
};
	["Notification_createdAt_operator"]: {
	equals?: ModelTypes["DateTime"] | undefined,
	not_equals?: ModelTypes["DateTime"] | undefined,
	greater_than_equal?: ModelTypes["DateTime"] | undefined,
	greater_than?: ModelTypes["DateTime"] | undefined,
	less_than_equal?: ModelTypes["DateTime"] | undefined,
	less_than?: ModelTypes["DateTime"] | undefined,
	like?: ModelTypes["DateTime"] | undefined,
	exists?: boolean | undefined
};
	["Notification_id_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Notification_where_and"]: {
	receiver?: ModelTypes["Notification_receiver_operator"] | undefined,
	type?: ModelTypes["Notification_type_operator"] | undefined,
	meta?: ModelTypes["Notification_meta_Relation"] | undefined,
	message?: ModelTypes["Notification_message_operator"] | undefined,
	sender?: ModelTypes["Notification_sender_operator"] | undefined,
	readed?: ModelTypes["Notification_readed_operator"] | undefined,
	deletedByUser?: ModelTypes["Notification_deletedByUser_operator"] | undefined,
	updatedAt?: ModelTypes["Notification_updatedAt_operator"] | undefined,
	createdAt?: ModelTypes["Notification_createdAt_operator"] | undefined,
	id?: ModelTypes["Notification_id_operator"] | undefined,
	AND?: Array<ModelTypes["Notification_where_and"] | undefined> | undefined,
	OR?: Array<ModelTypes["Notification_where_or"] | undefined> | undefined
};
	["Notification_where_or"]: {
	receiver?: ModelTypes["Notification_receiver_operator"] | undefined,
	type?: ModelTypes["Notification_type_operator"] | undefined,
	meta?: ModelTypes["Notification_meta_Relation"] | undefined,
	message?: ModelTypes["Notification_message_operator"] | undefined,
	sender?: ModelTypes["Notification_sender_operator"] | undefined,
	readed?: ModelTypes["Notification_readed_operator"] | undefined,
	deletedByUser?: ModelTypes["Notification_deletedByUser_operator"] | undefined,
	updatedAt?: ModelTypes["Notification_updatedAt_operator"] | undefined,
	createdAt?: ModelTypes["Notification_createdAt_operator"] | undefined,
	id?: ModelTypes["Notification_id_operator"] | undefined,
	AND?: Array<ModelTypes["Notification_where_and"] | undefined> | undefined,
	OR?: Array<ModelTypes["Notification_where_or"] | undefined> | undefined
};
	["countNotifications"]: {
		totalDocs?: number | undefined
};
	["notificationsDocAccess"]: {
		fields?: ModelTypes["NotificationsDocAccessFields"] | undefined,
	create?: ModelTypes["NotificationsCreateDocAccess"] | undefined,
	read?: ModelTypes["NotificationsReadDocAccess"] | undefined,
	update?: ModelTypes["NotificationsUpdateDocAccess"] | undefined,
	delete?: ModelTypes["NotificationsDeleteDocAccess"] | undefined
};
	["NotificationsDocAccessFields"]: {
		receiver?: ModelTypes["NotificationsDocAccessFields_receiver"] | undefined,
	type?: ModelTypes["NotificationsDocAccessFields_type"] | undefined,
	meta?: ModelTypes["NotificationsDocAccessFields_meta"] | undefined,
	message?: ModelTypes["NotificationsDocAccessFields_message"] | undefined,
	sender?: ModelTypes["NotificationsDocAccessFields_sender"] | undefined,
	readed?: ModelTypes["NotificationsDocAccessFields_readed"] | undefined,
	deletedByUser?: ModelTypes["NotificationsDocAccessFields_deletedByUser"] | undefined,
	updatedAt?: ModelTypes["NotificationsDocAccessFields_updatedAt"] | undefined,
	createdAt?: ModelTypes["NotificationsDocAccessFields_createdAt"] | undefined
};
	["NotificationsDocAccessFields_receiver"]: {
		create?: ModelTypes["NotificationsDocAccessFields_receiver_Create"] | undefined,
	read?: ModelTypes["NotificationsDocAccessFields_receiver_Read"] | undefined,
	update?: ModelTypes["NotificationsDocAccessFields_receiver_Update"] | undefined,
	delete?: ModelTypes["NotificationsDocAccessFields_receiver_Delete"] | undefined
};
	["NotificationsDocAccessFields_receiver_Create"]: {
		permission: boolean
};
	["NotificationsDocAccessFields_receiver_Read"]: {
		permission: boolean
};
	["NotificationsDocAccessFields_receiver_Update"]: {
		permission: boolean
};
	["NotificationsDocAccessFields_receiver_Delete"]: {
		permission: boolean
};
	["NotificationsDocAccessFields_type"]: {
		create?: ModelTypes["NotificationsDocAccessFields_type_Create"] | undefined,
	read?: ModelTypes["NotificationsDocAccessFields_type_Read"] | undefined,
	update?: ModelTypes["NotificationsDocAccessFields_type_Update"] | undefined,
	delete?: ModelTypes["NotificationsDocAccessFields_type_Delete"] | undefined
};
	["NotificationsDocAccessFields_type_Create"]: {
		permission: boolean
};
	["NotificationsDocAccessFields_type_Read"]: {
		permission: boolean
};
	["NotificationsDocAccessFields_type_Update"]: {
		permission: boolean
};
	["NotificationsDocAccessFields_type_Delete"]: {
		permission: boolean
};
	["NotificationsDocAccessFields_meta"]: {
		create?: ModelTypes["NotificationsDocAccessFields_meta_Create"] | undefined,
	read?: ModelTypes["NotificationsDocAccessFields_meta_Read"] | undefined,
	update?: ModelTypes["NotificationsDocAccessFields_meta_Update"] | undefined,
	delete?: ModelTypes["NotificationsDocAccessFields_meta_Delete"] | undefined
};
	["NotificationsDocAccessFields_meta_Create"]: {
		permission: boolean
};
	["NotificationsDocAccessFields_meta_Read"]: {
		permission: boolean
};
	["NotificationsDocAccessFields_meta_Update"]: {
		permission: boolean
};
	["NotificationsDocAccessFields_meta_Delete"]: {
		permission: boolean
};
	["NotificationsDocAccessFields_message"]: {
		create?: ModelTypes["NotificationsDocAccessFields_message_Create"] | undefined,
	read?: ModelTypes["NotificationsDocAccessFields_message_Read"] | undefined,
	update?: ModelTypes["NotificationsDocAccessFields_message_Update"] | undefined,
	delete?: ModelTypes["NotificationsDocAccessFields_message_Delete"] | undefined
};
	["NotificationsDocAccessFields_message_Create"]: {
		permission: boolean
};
	["NotificationsDocAccessFields_message_Read"]: {
		permission: boolean
};
	["NotificationsDocAccessFields_message_Update"]: {
		permission: boolean
};
	["NotificationsDocAccessFields_message_Delete"]: {
		permission: boolean
};
	["NotificationsDocAccessFields_sender"]: {
		create?: ModelTypes["NotificationsDocAccessFields_sender_Create"] | undefined,
	read?: ModelTypes["NotificationsDocAccessFields_sender_Read"] | undefined,
	update?: ModelTypes["NotificationsDocAccessFields_sender_Update"] | undefined,
	delete?: ModelTypes["NotificationsDocAccessFields_sender_Delete"] | undefined
};
	["NotificationsDocAccessFields_sender_Create"]: {
		permission: boolean
};
	["NotificationsDocAccessFields_sender_Read"]: {
		permission: boolean
};
	["NotificationsDocAccessFields_sender_Update"]: {
		permission: boolean
};
	["NotificationsDocAccessFields_sender_Delete"]: {
		permission: boolean
};
	["NotificationsDocAccessFields_readed"]: {
		create?: ModelTypes["NotificationsDocAccessFields_readed_Create"] | undefined,
	read?: ModelTypes["NotificationsDocAccessFields_readed_Read"] | undefined,
	update?: ModelTypes["NotificationsDocAccessFields_readed_Update"] | undefined,
	delete?: ModelTypes["NotificationsDocAccessFields_readed_Delete"] | undefined
};
	["NotificationsDocAccessFields_readed_Create"]: {
		permission: boolean
};
	["NotificationsDocAccessFields_readed_Read"]: {
		permission: boolean
};
	["NotificationsDocAccessFields_readed_Update"]: {
		permission: boolean
};
	["NotificationsDocAccessFields_readed_Delete"]: {
		permission: boolean
};
	["NotificationsDocAccessFields_deletedByUser"]: {
		create?: ModelTypes["NotificationsDocAccessFields_deletedByUser_Create"] | undefined,
	read?: ModelTypes["NotificationsDocAccessFields_deletedByUser_Read"] | undefined,
	update?: ModelTypes["NotificationsDocAccessFields_deletedByUser_Update"] | undefined,
	delete?: ModelTypes["NotificationsDocAccessFields_deletedByUser_Delete"] | undefined
};
	["NotificationsDocAccessFields_deletedByUser_Create"]: {
		permission: boolean
};
	["NotificationsDocAccessFields_deletedByUser_Read"]: {
		permission: boolean
};
	["NotificationsDocAccessFields_deletedByUser_Update"]: {
		permission: boolean
};
	["NotificationsDocAccessFields_deletedByUser_Delete"]: {
		permission: boolean
};
	["NotificationsDocAccessFields_updatedAt"]: {
		create?: ModelTypes["NotificationsDocAccessFields_updatedAt_Create"] | undefined,
	read?: ModelTypes["NotificationsDocAccessFields_updatedAt_Read"] | undefined,
	update?: ModelTypes["NotificationsDocAccessFields_updatedAt_Update"] | undefined,
	delete?: ModelTypes["NotificationsDocAccessFields_updatedAt_Delete"] | undefined
};
	["NotificationsDocAccessFields_updatedAt_Create"]: {
		permission: boolean
};
	["NotificationsDocAccessFields_updatedAt_Read"]: {
		permission: boolean
};
	["NotificationsDocAccessFields_updatedAt_Update"]: {
		permission: boolean
};
	["NotificationsDocAccessFields_updatedAt_Delete"]: {
		permission: boolean
};
	["NotificationsDocAccessFields_createdAt"]: {
		create?: ModelTypes["NotificationsDocAccessFields_createdAt_Create"] | undefined,
	read?: ModelTypes["NotificationsDocAccessFields_createdAt_Read"] | undefined,
	update?: ModelTypes["NotificationsDocAccessFields_createdAt_Update"] | undefined,
	delete?: ModelTypes["NotificationsDocAccessFields_createdAt_Delete"] | undefined
};
	["NotificationsDocAccessFields_createdAt_Create"]: {
		permission: boolean
};
	["NotificationsDocAccessFields_createdAt_Read"]: {
		permission: boolean
};
	["NotificationsDocAccessFields_createdAt_Update"]: {
		permission: boolean
};
	["NotificationsDocAccessFields_createdAt_Delete"]: {
		permission: boolean
};
	["NotificationsCreateDocAccess"]: {
		permission: boolean,
	where?: ModelTypes["JSONObject"] | undefined
};
	["NotificationsReadDocAccess"]: {
		permission: boolean,
	where?: ModelTypes["JSONObject"] | undefined
};
	["NotificationsUpdateDocAccess"]: {
		permission: boolean,
	where?: ModelTypes["JSONObject"] | undefined
};
	["NotificationsDeleteDocAccess"]: {
		permission: boolean,
	where?: ModelTypes["JSONObject"] | undefined
};
	["RedirectSetting"]: {
		id?: string | undefined,
	from?: string | undefined,
	to?: string | undefined,
	updatedAt?: ModelTypes["DateTime"] | undefined,
	createdAt?: ModelTypes["DateTime"] | undefined
};
	["RedirectSettings"]: {
		docs?: Array<ModelTypes["RedirectSetting"] | undefined> | undefined,
	hasNextPage?: boolean | undefined,
	hasPrevPage?: boolean | undefined,
	limit?: number | undefined,
	nextPage?: number | undefined,
	offset?: number | undefined,
	page?: number | undefined,
	pagingCounter?: number | undefined,
	prevPage?: number | undefined,
	totalDocs?: number | undefined,
	totalPages?: number | undefined
};
	["RedirectSetting_where"]: {
	from?: ModelTypes["RedirectSetting_from_operator"] | undefined,
	to?: ModelTypes["RedirectSetting_to_operator"] | undefined,
	updatedAt?: ModelTypes["RedirectSetting_updatedAt_operator"] | undefined,
	createdAt?: ModelTypes["RedirectSetting_createdAt_operator"] | undefined,
	id?: ModelTypes["RedirectSetting_id_operator"] | undefined,
	AND?: Array<ModelTypes["RedirectSetting_where_and"] | undefined> | undefined,
	OR?: Array<ModelTypes["RedirectSetting_where_or"] | undefined> | undefined
};
	["RedirectSetting_from_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["RedirectSetting_to_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["RedirectSetting_updatedAt_operator"]: {
	equals?: ModelTypes["DateTime"] | undefined,
	not_equals?: ModelTypes["DateTime"] | undefined,
	greater_than_equal?: ModelTypes["DateTime"] | undefined,
	greater_than?: ModelTypes["DateTime"] | undefined,
	less_than_equal?: ModelTypes["DateTime"] | undefined,
	less_than?: ModelTypes["DateTime"] | undefined,
	like?: ModelTypes["DateTime"] | undefined,
	exists?: boolean | undefined
};
	["RedirectSetting_createdAt_operator"]: {
	equals?: ModelTypes["DateTime"] | undefined,
	not_equals?: ModelTypes["DateTime"] | undefined,
	greater_than_equal?: ModelTypes["DateTime"] | undefined,
	greater_than?: ModelTypes["DateTime"] | undefined,
	less_than_equal?: ModelTypes["DateTime"] | undefined,
	less_than?: ModelTypes["DateTime"] | undefined,
	like?: ModelTypes["DateTime"] | undefined,
	exists?: boolean | undefined
};
	["RedirectSetting_id_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["RedirectSetting_where_and"]: {
	from?: ModelTypes["RedirectSetting_from_operator"] | undefined,
	to?: ModelTypes["RedirectSetting_to_operator"] | undefined,
	updatedAt?: ModelTypes["RedirectSetting_updatedAt_operator"] | undefined,
	createdAt?: ModelTypes["RedirectSetting_createdAt_operator"] | undefined,
	id?: ModelTypes["RedirectSetting_id_operator"] | undefined,
	AND?: Array<ModelTypes["RedirectSetting_where_and"] | undefined> | undefined,
	OR?: Array<ModelTypes["RedirectSetting_where_or"] | undefined> | undefined
};
	["RedirectSetting_where_or"]: {
	from?: ModelTypes["RedirectSetting_from_operator"] | undefined,
	to?: ModelTypes["RedirectSetting_to_operator"] | undefined,
	updatedAt?: ModelTypes["RedirectSetting_updatedAt_operator"] | undefined,
	createdAt?: ModelTypes["RedirectSetting_createdAt_operator"] | undefined,
	id?: ModelTypes["RedirectSetting_id_operator"] | undefined,
	AND?: Array<ModelTypes["RedirectSetting_where_and"] | undefined> | undefined,
	OR?: Array<ModelTypes["RedirectSetting_where_or"] | undefined> | undefined
};
	["countRedirectSettings"]: {
		totalDocs?: number | undefined
};
	["redirectSettingDocAccess"]: {
		fields?: ModelTypes["RedirectSettingDocAccessFields"] | undefined,
	create?: ModelTypes["RedirectSettingCreateDocAccess"] | undefined,
	read?: ModelTypes["RedirectSettingReadDocAccess"] | undefined,
	update?: ModelTypes["RedirectSettingUpdateDocAccess"] | undefined,
	delete?: ModelTypes["RedirectSettingDeleteDocAccess"] | undefined
};
	["RedirectSettingDocAccessFields"]: {
		from?: ModelTypes["RedirectSettingDocAccessFields_from"] | undefined,
	to?: ModelTypes["RedirectSettingDocAccessFields_to"] | undefined,
	updatedAt?: ModelTypes["RedirectSettingDocAccessFields_updatedAt"] | undefined,
	createdAt?: ModelTypes["RedirectSettingDocAccessFields_createdAt"] | undefined
};
	["RedirectSettingDocAccessFields_from"]: {
		create?: ModelTypes["RedirectSettingDocAccessFields_from_Create"] | undefined,
	read?: ModelTypes["RedirectSettingDocAccessFields_from_Read"] | undefined,
	update?: ModelTypes["RedirectSettingDocAccessFields_from_Update"] | undefined,
	delete?: ModelTypes["RedirectSettingDocAccessFields_from_Delete"] | undefined
};
	["RedirectSettingDocAccessFields_from_Create"]: {
		permission: boolean
};
	["RedirectSettingDocAccessFields_from_Read"]: {
		permission: boolean
};
	["RedirectSettingDocAccessFields_from_Update"]: {
		permission: boolean
};
	["RedirectSettingDocAccessFields_from_Delete"]: {
		permission: boolean
};
	["RedirectSettingDocAccessFields_to"]: {
		create?: ModelTypes["RedirectSettingDocAccessFields_to_Create"] | undefined,
	read?: ModelTypes["RedirectSettingDocAccessFields_to_Read"] | undefined,
	update?: ModelTypes["RedirectSettingDocAccessFields_to_Update"] | undefined,
	delete?: ModelTypes["RedirectSettingDocAccessFields_to_Delete"] | undefined
};
	["RedirectSettingDocAccessFields_to_Create"]: {
		permission: boolean
};
	["RedirectSettingDocAccessFields_to_Read"]: {
		permission: boolean
};
	["RedirectSettingDocAccessFields_to_Update"]: {
		permission: boolean
};
	["RedirectSettingDocAccessFields_to_Delete"]: {
		permission: boolean
};
	["RedirectSettingDocAccessFields_updatedAt"]: {
		create?: ModelTypes["RedirectSettingDocAccessFields_updatedAt_Create"] | undefined,
	read?: ModelTypes["RedirectSettingDocAccessFields_updatedAt_Read"] | undefined,
	update?: ModelTypes["RedirectSettingDocAccessFields_updatedAt_Update"] | undefined,
	delete?: ModelTypes["RedirectSettingDocAccessFields_updatedAt_Delete"] | undefined
};
	["RedirectSettingDocAccessFields_updatedAt_Create"]: {
		permission: boolean
};
	["RedirectSettingDocAccessFields_updatedAt_Read"]: {
		permission: boolean
};
	["RedirectSettingDocAccessFields_updatedAt_Update"]: {
		permission: boolean
};
	["RedirectSettingDocAccessFields_updatedAt_Delete"]: {
		permission: boolean
};
	["RedirectSettingDocAccessFields_createdAt"]: {
		create?: ModelTypes["RedirectSettingDocAccessFields_createdAt_Create"] | undefined,
	read?: ModelTypes["RedirectSettingDocAccessFields_createdAt_Read"] | undefined,
	update?: ModelTypes["RedirectSettingDocAccessFields_createdAt_Update"] | undefined,
	delete?: ModelTypes["RedirectSettingDocAccessFields_createdAt_Delete"] | undefined
};
	["RedirectSettingDocAccessFields_createdAt_Create"]: {
		permission: boolean
};
	["RedirectSettingDocAccessFields_createdAt_Read"]: {
		permission: boolean
};
	["RedirectSettingDocAccessFields_createdAt_Update"]: {
		permission: boolean
};
	["RedirectSettingDocAccessFields_createdAt_Delete"]: {
		permission: boolean
};
	["RedirectSettingCreateDocAccess"]: {
		permission: boolean,
	where?: ModelTypes["JSONObject"] | undefined
};
	["RedirectSettingReadDocAccess"]: {
		permission: boolean,
	where?: ModelTypes["JSONObject"] | undefined
};
	["RedirectSettingUpdateDocAccess"]: {
		permission: boolean,
	where?: ModelTypes["JSONObject"] | undefined
};
	["RedirectSettingDeleteDocAccess"]: {
		permission: boolean,
	where?: ModelTypes["JSONObject"] | undefined
};
	["EventRanking"]: {
		id?: string | undefined,
	name?: string | undefined,
	visible?: boolean | undefined,
	maxItems: number,
	template: ModelTypes["EventRanking_template"],
	list?: Array<ModelTypes["EventRanking_List"]> | undefined,
	region?: ModelTypes["EventRanking_region"] | undefined,
	updatedAt?: ModelTypes["DateTime"] | undefined,
	createdAt?: ModelTypes["DateTime"] | undefined
};
	["EventRanking_template"]:EventRanking_template;
	["EventRanking_List"]: {
		item?: ModelTypes["Event"] | undefined,
	id?: string | undefined
};
	["EventRanking_region"]:EventRanking_region;
	["EventRankings"]: {
		docs?: Array<ModelTypes["EventRanking"] | undefined> | undefined,
	hasNextPage?: boolean | undefined,
	hasPrevPage?: boolean | undefined,
	limit?: number | undefined,
	nextPage?: number | undefined,
	offset?: number | undefined,
	page?: number | undefined,
	pagingCounter?: number | undefined,
	prevPage?: number | undefined,
	totalDocs?: number | undefined,
	totalPages?: number | undefined
};
	["EventRanking_where"]: {
	name?: ModelTypes["EventRanking_name_operator"] | undefined,
	visible?: ModelTypes["EventRanking_visible_operator"] | undefined,
	maxItems?: ModelTypes["EventRanking_maxItems_operator"] | undefined,
	template?: ModelTypes["EventRanking_template_operator"] | undefined,
	list__item?: ModelTypes["EventRanking_list__item_operator"] | undefined,
	list__id?: ModelTypes["EventRanking_list__id_operator"] | undefined,
	region?: ModelTypes["EventRanking_region_operator"] | undefined,
	updatedAt?: ModelTypes["EventRanking_updatedAt_operator"] | undefined,
	createdAt?: ModelTypes["EventRanking_createdAt_operator"] | undefined,
	id?: ModelTypes["EventRanking_id_operator"] | undefined,
	AND?: Array<ModelTypes["EventRanking_where_and"] | undefined> | undefined,
	OR?: Array<ModelTypes["EventRanking_where_or"] | undefined> | undefined
};
	["EventRanking_name_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined
};
	["EventRanking_visible_operator"]: {
	equals?: boolean | undefined,
	not_equals?: boolean | undefined,
	exists?: boolean | undefined
};
	["EventRanking_maxItems_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined
};
	["EventRanking_template_operator"]: {
	equals?: ModelTypes["EventRanking_template_Input"] | undefined,
	not_equals?: ModelTypes["EventRanking_template_Input"] | undefined,
	in?: Array<ModelTypes["EventRanking_template_Input"] | undefined> | undefined,
	not_in?: Array<ModelTypes["EventRanking_template_Input"] | undefined> | undefined,
	all?: Array<ModelTypes["EventRanking_template_Input"] | undefined> | undefined
};
	["EventRanking_template_Input"]:EventRanking_template_Input;
	["EventRanking_list__item_operator"]: {
	equals?: ModelTypes["JSON"] | undefined,
	not_equals?: ModelTypes["JSON"] | undefined,
	in?: Array<ModelTypes["JSON"] | undefined> | undefined,
	not_in?: Array<ModelTypes["JSON"] | undefined> | undefined,
	all?: Array<ModelTypes["JSON"] | undefined> | undefined
};
	["EventRanking_list__id_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["EventRanking_region_operator"]: {
	equals?: ModelTypes["EventRanking_region_Input"] | undefined,
	not_equals?: ModelTypes["EventRanking_region_Input"] | undefined,
	in?: Array<ModelTypes["EventRanking_region_Input"] | undefined> | undefined,
	not_in?: Array<ModelTypes["EventRanking_region_Input"] | undefined> | undefined,
	all?: Array<ModelTypes["EventRanking_region_Input"] | undefined> | undefined,
	exists?: boolean | undefined
};
	["EventRanking_region_Input"]:EventRanking_region_Input;
	["EventRanking_updatedAt_operator"]: {
	equals?: ModelTypes["DateTime"] | undefined,
	not_equals?: ModelTypes["DateTime"] | undefined,
	greater_than_equal?: ModelTypes["DateTime"] | undefined,
	greater_than?: ModelTypes["DateTime"] | undefined,
	less_than_equal?: ModelTypes["DateTime"] | undefined,
	less_than?: ModelTypes["DateTime"] | undefined,
	like?: ModelTypes["DateTime"] | undefined,
	exists?: boolean | undefined
};
	["EventRanking_createdAt_operator"]: {
	equals?: ModelTypes["DateTime"] | undefined,
	not_equals?: ModelTypes["DateTime"] | undefined,
	greater_than_equal?: ModelTypes["DateTime"] | undefined,
	greater_than?: ModelTypes["DateTime"] | undefined,
	less_than_equal?: ModelTypes["DateTime"] | undefined,
	less_than?: ModelTypes["DateTime"] | undefined,
	like?: ModelTypes["DateTime"] | undefined,
	exists?: boolean | undefined
};
	["EventRanking_id_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["EventRanking_where_and"]: {
	name?: ModelTypes["EventRanking_name_operator"] | undefined,
	visible?: ModelTypes["EventRanking_visible_operator"] | undefined,
	maxItems?: ModelTypes["EventRanking_maxItems_operator"] | undefined,
	template?: ModelTypes["EventRanking_template_operator"] | undefined,
	list__item?: ModelTypes["EventRanking_list__item_operator"] | undefined,
	list__id?: ModelTypes["EventRanking_list__id_operator"] | undefined,
	region?: ModelTypes["EventRanking_region_operator"] | undefined,
	updatedAt?: ModelTypes["EventRanking_updatedAt_operator"] | undefined,
	createdAt?: ModelTypes["EventRanking_createdAt_operator"] | undefined,
	id?: ModelTypes["EventRanking_id_operator"] | undefined,
	AND?: Array<ModelTypes["EventRanking_where_and"] | undefined> | undefined,
	OR?: Array<ModelTypes["EventRanking_where_or"] | undefined> | undefined
};
	["EventRanking_where_or"]: {
	name?: ModelTypes["EventRanking_name_operator"] | undefined,
	visible?: ModelTypes["EventRanking_visible_operator"] | undefined,
	maxItems?: ModelTypes["EventRanking_maxItems_operator"] | undefined,
	template?: ModelTypes["EventRanking_template_operator"] | undefined,
	list__item?: ModelTypes["EventRanking_list__item_operator"] | undefined,
	list__id?: ModelTypes["EventRanking_list__id_operator"] | undefined,
	region?: ModelTypes["EventRanking_region_operator"] | undefined,
	updatedAt?: ModelTypes["EventRanking_updatedAt_operator"] | undefined,
	createdAt?: ModelTypes["EventRanking_createdAt_operator"] | undefined,
	id?: ModelTypes["EventRanking_id_operator"] | undefined,
	AND?: Array<ModelTypes["EventRanking_where_and"] | undefined> | undefined,
	OR?: Array<ModelTypes["EventRanking_where_or"] | undefined> | undefined
};
	["countEventRankings"]: {
		totalDocs?: number | undefined
};
	["ranking_eventsDocAccess"]: {
		fields?: ModelTypes["RankingEventsDocAccessFields"] | undefined,
	create?: ModelTypes["RankingEventsCreateDocAccess"] | undefined,
	read?: ModelTypes["RankingEventsReadDocAccess"] | undefined,
	update?: ModelTypes["RankingEventsUpdateDocAccess"] | undefined,
	delete?: ModelTypes["RankingEventsDeleteDocAccess"] | undefined
};
	["RankingEventsDocAccessFields"]: {
		name?: ModelTypes["RankingEventsDocAccessFields_name"] | undefined,
	visible?: ModelTypes["RankingEventsDocAccessFields_visible"] | undefined,
	maxItems?: ModelTypes["RankingEventsDocAccessFields_maxItems"] | undefined,
	template?: ModelTypes["RankingEventsDocAccessFields_template"] | undefined,
	list?: ModelTypes["RankingEventsDocAccessFields_list"] | undefined,
	region?: ModelTypes["RankingEventsDocAccessFields_region"] | undefined,
	updatedAt?: ModelTypes["RankingEventsDocAccessFields_updatedAt"] | undefined,
	createdAt?: ModelTypes["RankingEventsDocAccessFields_createdAt"] | undefined
};
	["RankingEventsDocAccessFields_name"]: {
		create?: ModelTypes["RankingEventsDocAccessFields_name_Create"] | undefined,
	read?: ModelTypes["RankingEventsDocAccessFields_name_Read"] | undefined,
	update?: ModelTypes["RankingEventsDocAccessFields_name_Update"] | undefined,
	delete?: ModelTypes["RankingEventsDocAccessFields_name_Delete"] | undefined
};
	["RankingEventsDocAccessFields_name_Create"]: {
		permission: boolean
};
	["RankingEventsDocAccessFields_name_Read"]: {
		permission: boolean
};
	["RankingEventsDocAccessFields_name_Update"]: {
		permission: boolean
};
	["RankingEventsDocAccessFields_name_Delete"]: {
		permission: boolean
};
	["RankingEventsDocAccessFields_visible"]: {
		create?: ModelTypes["RankingEventsDocAccessFields_visible_Create"] | undefined,
	read?: ModelTypes["RankingEventsDocAccessFields_visible_Read"] | undefined,
	update?: ModelTypes["RankingEventsDocAccessFields_visible_Update"] | undefined,
	delete?: ModelTypes["RankingEventsDocAccessFields_visible_Delete"] | undefined
};
	["RankingEventsDocAccessFields_visible_Create"]: {
		permission: boolean
};
	["RankingEventsDocAccessFields_visible_Read"]: {
		permission: boolean
};
	["RankingEventsDocAccessFields_visible_Update"]: {
		permission: boolean
};
	["RankingEventsDocAccessFields_visible_Delete"]: {
		permission: boolean
};
	["RankingEventsDocAccessFields_maxItems"]: {
		create?: ModelTypes["RankingEventsDocAccessFields_maxItems_Create"] | undefined,
	read?: ModelTypes["RankingEventsDocAccessFields_maxItems_Read"] | undefined,
	update?: ModelTypes["RankingEventsDocAccessFields_maxItems_Update"] | undefined,
	delete?: ModelTypes["RankingEventsDocAccessFields_maxItems_Delete"] | undefined
};
	["RankingEventsDocAccessFields_maxItems_Create"]: {
		permission: boolean
};
	["RankingEventsDocAccessFields_maxItems_Read"]: {
		permission: boolean
};
	["RankingEventsDocAccessFields_maxItems_Update"]: {
		permission: boolean
};
	["RankingEventsDocAccessFields_maxItems_Delete"]: {
		permission: boolean
};
	["RankingEventsDocAccessFields_template"]: {
		create?: ModelTypes["RankingEventsDocAccessFields_template_Create"] | undefined,
	read?: ModelTypes["RankingEventsDocAccessFields_template_Read"] | undefined,
	update?: ModelTypes["RankingEventsDocAccessFields_template_Update"] | undefined,
	delete?: ModelTypes["RankingEventsDocAccessFields_template_Delete"] | undefined
};
	["RankingEventsDocAccessFields_template_Create"]: {
		permission: boolean
};
	["RankingEventsDocAccessFields_template_Read"]: {
		permission: boolean
};
	["RankingEventsDocAccessFields_template_Update"]: {
		permission: boolean
};
	["RankingEventsDocAccessFields_template_Delete"]: {
		permission: boolean
};
	["RankingEventsDocAccessFields_list"]: {
		create?: ModelTypes["RankingEventsDocAccessFields_list_Create"] | undefined,
	read?: ModelTypes["RankingEventsDocAccessFields_list_Read"] | undefined,
	update?: ModelTypes["RankingEventsDocAccessFields_list_Update"] | undefined,
	delete?: ModelTypes["RankingEventsDocAccessFields_list_Delete"] | undefined,
	fields?: ModelTypes["RankingEventsDocAccessFields_list_Fields"] | undefined
};
	["RankingEventsDocAccessFields_list_Create"]: {
		permission: boolean
};
	["RankingEventsDocAccessFields_list_Read"]: {
		permission: boolean
};
	["RankingEventsDocAccessFields_list_Update"]: {
		permission: boolean
};
	["RankingEventsDocAccessFields_list_Delete"]: {
		permission: boolean
};
	["RankingEventsDocAccessFields_list_Fields"]: {
		item?: ModelTypes["RankingEventsDocAccessFields_list_item"] | undefined,
	id?: ModelTypes["RankingEventsDocAccessFields_list_id"] | undefined
};
	["RankingEventsDocAccessFields_list_item"]: {
		create?: ModelTypes["RankingEventsDocAccessFields_list_item_Create"] | undefined,
	read?: ModelTypes["RankingEventsDocAccessFields_list_item_Read"] | undefined,
	update?: ModelTypes["RankingEventsDocAccessFields_list_item_Update"] | undefined,
	delete?: ModelTypes["RankingEventsDocAccessFields_list_item_Delete"] | undefined
};
	["RankingEventsDocAccessFields_list_item_Create"]: {
		permission: boolean
};
	["RankingEventsDocAccessFields_list_item_Read"]: {
		permission: boolean
};
	["RankingEventsDocAccessFields_list_item_Update"]: {
		permission: boolean
};
	["RankingEventsDocAccessFields_list_item_Delete"]: {
		permission: boolean
};
	["RankingEventsDocAccessFields_list_id"]: {
		create?: ModelTypes["RankingEventsDocAccessFields_list_id_Create"] | undefined,
	read?: ModelTypes["RankingEventsDocAccessFields_list_id_Read"] | undefined,
	update?: ModelTypes["RankingEventsDocAccessFields_list_id_Update"] | undefined,
	delete?: ModelTypes["RankingEventsDocAccessFields_list_id_Delete"] | undefined
};
	["RankingEventsDocAccessFields_list_id_Create"]: {
		permission: boolean
};
	["RankingEventsDocAccessFields_list_id_Read"]: {
		permission: boolean
};
	["RankingEventsDocAccessFields_list_id_Update"]: {
		permission: boolean
};
	["RankingEventsDocAccessFields_list_id_Delete"]: {
		permission: boolean
};
	["RankingEventsDocAccessFields_region"]: {
		create?: ModelTypes["RankingEventsDocAccessFields_region_Create"] | undefined,
	read?: ModelTypes["RankingEventsDocAccessFields_region_Read"] | undefined,
	update?: ModelTypes["RankingEventsDocAccessFields_region_Update"] | undefined,
	delete?: ModelTypes["RankingEventsDocAccessFields_region_Delete"] | undefined
};
	["RankingEventsDocAccessFields_region_Create"]: {
		permission: boolean
};
	["RankingEventsDocAccessFields_region_Read"]: {
		permission: boolean
};
	["RankingEventsDocAccessFields_region_Update"]: {
		permission: boolean
};
	["RankingEventsDocAccessFields_region_Delete"]: {
		permission: boolean
};
	["RankingEventsDocAccessFields_updatedAt"]: {
		create?: ModelTypes["RankingEventsDocAccessFields_updatedAt_Create"] | undefined,
	read?: ModelTypes["RankingEventsDocAccessFields_updatedAt_Read"] | undefined,
	update?: ModelTypes["RankingEventsDocAccessFields_updatedAt_Update"] | undefined,
	delete?: ModelTypes["RankingEventsDocAccessFields_updatedAt_Delete"] | undefined
};
	["RankingEventsDocAccessFields_updatedAt_Create"]: {
		permission: boolean
};
	["RankingEventsDocAccessFields_updatedAt_Read"]: {
		permission: boolean
};
	["RankingEventsDocAccessFields_updatedAt_Update"]: {
		permission: boolean
};
	["RankingEventsDocAccessFields_updatedAt_Delete"]: {
		permission: boolean
};
	["RankingEventsDocAccessFields_createdAt"]: {
		create?: ModelTypes["RankingEventsDocAccessFields_createdAt_Create"] | undefined,
	read?: ModelTypes["RankingEventsDocAccessFields_createdAt_Read"] | undefined,
	update?: ModelTypes["RankingEventsDocAccessFields_createdAt_Update"] | undefined,
	delete?: ModelTypes["RankingEventsDocAccessFields_createdAt_Delete"] | undefined
};
	["RankingEventsDocAccessFields_createdAt_Create"]: {
		permission: boolean
};
	["RankingEventsDocAccessFields_createdAt_Read"]: {
		permission: boolean
};
	["RankingEventsDocAccessFields_createdAt_Update"]: {
		permission: boolean
};
	["RankingEventsDocAccessFields_createdAt_Delete"]: {
		permission: boolean
};
	["RankingEventsCreateDocAccess"]: {
		permission: boolean,
	where?: ModelTypes["JSONObject"] | undefined
};
	["RankingEventsReadDocAccess"]: {
		permission: boolean,
	where?: ModelTypes["JSONObject"] | undefined
};
	["RankingEventsUpdateDocAccess"]: {
		permission: boolean,
	where?: ModelTypes["JSONObject"] | undefined
};
	["RankingEventsDeleteDocAccess"]: {
		permission: boolean,
	where?: ModelTypes["JSONObject"] | undefined
};
	["LocationRanking"]: {
		id?: string | undefined,
	name?: string | undefined,
	visible?: boolean | undefined,
	maxItems?: number | undefined,
	template: ModelTypes["LocationRanking_template"],
	list?: Array<ModelTypes["LocationRanking_List"]> | undefined,
	region?: ModelTypes["LocationRanking_region"] | undefined,
	updatedAt?: ModelTypes["DateTime"] | undefined,
	createdAt?: ModelTypes["DateTime"] | undefined
};
	["LocationRanking_template"]:LocationRanking_template;
	["LocationRanking_List"]: {
		item?: ModelTypes["Location"] | undefined,
	id?: string | undefined
};
	["LocationRanking_region"]:LocationRanking_region;
	["LocationRankings"]: {
		docs?: Array<ModelTypes["LocationRanking"] | undefined> | undefined,
	hasNextPage?: boolean | undefined,
	hasPrevPage?: boolean | undefined,
	limit?: number | undefined,
	nextPage?: number | undefined,
	offset?: number | undefined,
	page?: number | undefined,
	pagingCounter?: number | undefined,
	prevPage?: number | undefined,
	totalDocs?: number | undefined,
	totalPages?: number | undefined
};
	["LocationRanking_where"]: {
	name?: ModelTypes["LocationRanking_name_operator"] | undefined,
	visible?: ModelTypes["LocationRanking_visible_operator"] | undefined,
	maxItems?: ModelTypes["LocationRanking_maxItems_operator"] | undefined,
	template?: ModelTypes["LocationRanking_template_operator"] | undefined,
	list__item?: ModelTypes["LocationRanking_list__item_operator"] | undefined,
	list__id?: ModelTypes["LocationRanking_list__id_operator"] | undefined,
	region?: ModelTypes["LocationRanking_region_operator"] | undefined,
	updatedAt?: ModelTypes["LocationRanking_updatedAt_operator"] | undefined,
	createdAt?: ModelTypes["LocationRanking_createdAt_operator"] | undefined,
	id?: ModelTypes["LocationRanking_id_operator"] | undefined,
	AND?: Array<ModelTypes["LocationRanking_where_and"] | undefined> | undefined,
	OR?: Array<ModelTypes["LocationRanking_where_or"] | undefined> | undefined
};
	["LocationRanking_name_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined
};
	["LocationRanking_visible_operator"]: {
	equals?: boolean | undefined,
	not_equals?: boolean | undefined,
	exists?: boolean | undefined
};
	["LocationRanking_maxItems_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["LocationRanking_template_operator"]: {
	equals?: ModelTypes["LocationRanking_template_Input"] | undefined,
	not_equals?: ModelTypes["LocationRanking_template_Input"] | undefined,
	in?: Array<ModelTypes["LocationRanking_template_Input"] | undefined> | undefined,
	not_in?: Array<ModelTypes["LocationRanking_template_Input"] | undefined> | undefined,
	all?: Array<ModelTypes["LocationRanking_template_Input"] | undefined> | undefined
};
	["LocationRanking_template_Input"]:LocationRanking_template_Input;
	["LocationRanking_list__item_operator"]: {
	equals?: ModelTypes["JSON"] | undefined,
	not_equals?: ModelTypes["JSON"] | undefined,
	in?: Array<ModelTypes["JSON"] | undefined> | undefined,
	not_in?: Array<ModelTypes["JSON"] | undefined> | undefined,
	all?: Array<ModelTypes["JSON"] | undefined> | undefined
};
	["LocationRanking_list__id_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["LocationRanking_region_operator"]: {
	equals?: ModelTypes["LocationRanking_region_Input"] | undefined,
	not_equals?: ModelTypes["LocationRanking_region_Input"] | undefined,
	in?: Array<ModelTypes["LocationRanking_region_Input"] | undefined> | undefined,
	not_in?: Array<ModelTypes["LocationRanking_region_Input"] | undefined> | undefined,
	all?: Array<ModelTypes["LocationRanking_region_Input"] | undefined> | undefined,
	exists?: boolean | undefined
};
	["LocationRanking_region_Input"]:LocationRanking_region_Input;
	["LocationRanking_updatedAt_operator"]: {
	equals?: ModelTypes["DateTime"] | undefined,
	not_equals?: ModelTypes["DateTime"] | undefined,
	greater_than_equal?: ModelTypes["DateTime"] | undefined,
	greater_than?: ModelTypes["DateTime"] | undefined,
	less_than_equal?: ModelTypes["DateTime"] | undefined,
	less_than?: ModelTypes["DateTime"] | undefined,
	like?: ModelTypes["DateTime"] | undefined,
	exists?: boolean | undefined
};
	["LocationRanking_createdAt_operator"]: {
	equals?: ModelTypes["DateTime"] | undefined,
	not_equals?: ModelTypes["DateTime"] | undefined,
	greater_than_equal?: ModelTypes["DateTime"] | undefined,
	greater_than?: ModelTypes["DateTime"] | undefined,
	less_than_equal?: ModelTypes["DateTime"] | undefined,
	less_than?: ModelTypes["DateTime"] | undefined,
	like?: ModelTypes["DateTime"] | undefined,
	exists?: boolean | undefined
};
	["LocationRanking_id_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["LocationRanking_where_and"]: {
	name?: ModelTypes["LocationRanking_name_operator"] | undefined,
	visible?: ModelTypes["LocationRanking_visible_operator"] | undefined,
	maxItems?: ModelTypes["LocationRanking_maxItems_operator"] | undefined,
	template?: ModelTypes["LocationRanking_template_operator"] | undefined,
	list__item?: ModelTypes["LocationRanking_list__item_operator"] | undefined,
	list__id?: ModelTypes["LocationRanking_list__id_operator"] | undefined,
	region?: ModelTypes["LocationRanking_region_operator"] | undefined,
	updatedAt?: ModelTypes["LocationRanking_updatedAt_operator"] | undefined,
	createdAt?: ModelTypes["LocationRanking_createdAt_operator"] | undefined,
	id?: ModelTypes["LocationRanking_id_operator"] | undefined,
	AND?: Array<ModelTypes["LocationRanking_where_and"] | undefined> | undefined,
	OR?: Array<ModelTypes["LocationRanking_where_or"] | undefined> | undefined
};
	["LocationRanking_where_or"]: {
	name?: ModelTypes["LocationRanking_name_operator"] | undefined,
	visible?: ModelTypes["LocationRanking_visible_operator"] | undefined,
	maxItems?: ModelTypes["LocationRanking_maxItems_operator"] | undefined,
	template?: ModelTypes["LocationRanking_template_operator"] | undefined,
	list__item?: ModelTypes["LocationRanking_list__item_operator"] | undefined,
	list__id?: ModelTypes["LocationRanking_list__id_operator"] | undefined,
	region?: ModelTypes["LocationRanking_region_operator"] | undefined,
	updatedAt?: ModelTypes["LocationRanking_updatedAt_operator"] | undefined,
	createdAt?: ModelTypes["LocationRanking_createdAt_operator"] | undefined,
	id?: ModelTypes["LocationRanking_id_operator"] | undefined,
	AND?: Array<ModelTypes["LocationRanking_where_and"] | undefined> | undefined,
	OR?: Array<ModelTypes["LocationRanking_where_or"] | undefined> | undefined
};
	["countLocationRankings"]: {
		totalDocs?: number | undefined
};
	["ranking_locationsDocAccess"]: {
		fields?: ModelTypes["RankingLocationsDocAccessFields"] | undefined,
	create?: ModelTypes["RankingLocationsCreateDocAccess"] | undefined,
	read?: ModelTypes["RankingLocationsReadDocAccess"] | undefined,
	update?: ModelTypes["RankingLocationsUpdateDocAccess"] | undefined,
	delete?: ModelTypes["RankingLocationsDeleteDocAccess"] | undefined
};
	["RankingLocationsDocAccessFields"]: {
		name?: ModelTypes["RankingLocationsDocAccessFields_name"] | undefined,
	visible?: ModelTypes["RankingLocationsDocAccessFields_visible"] | undefined,
	maxItems?: ModelTypes["RankingLocationsDocAccessFields_maxItems"] | undefined,
	template?: ModelTypes["RankingLocationsDocAccessFields_template"] | undefined,
	list?: ModelTypes["RankingLocationsDocAccessFields_list"] | undefined,
	region?: ModelTypes["RankingLocationsDocAccessFields_region"] | undefined,
	updatedAt?: ModelTypes["RankingLocationsDocAccessFields_updatedAt"] | undefined,
	createdAt?: ModelTypes["RankingLocationsDocAccessFields_createdAt"] | undefined
};
	["RankingLocationsDocAccessFields_name"]: {
		create?: ModelTypes["RankingLocationsDocAccessFields_name_Create"] | undefined,
	read?: ModelTypes["RankingLocationsDocAccessFields_name_Read"] | undefined,
	update?: ModelTypes["RankingLocationsDocAccessFields_name_Update"] | undefined,
	delete?: ModelTypes["RankingLocationsDocAccessFields_name_Delete"] | undefined
};
	["RankingLocationsDocAccessFields_name_Create"]: {
		permission: boolean
};
	["RankingLocationsDocAccessFields_name_Read"]: {
		permission: boolean
};
	["RankingLocationsDocAccessFields_name_Update"]: {
		permission: boolean
};
	["RankingLocationsDocAccessFields_name_Delete"]: {
		permission: boolean
};
	["RankingLocationsDocAccessFields_visible"]: {
		create?: ModelTypes["RankingLocationsDocAccessFields_visible_Create"] | undefined,
	read?: ModelTypes["RankingLocationsDocAccessFields_visible_Read"] | undefined,
	update?: ModelTypes["RankingLocationsDocAccessFields_visible_Update"] | undefined,
	delete?: ModelTypes["RankingLocationsDocAccessFields_visible_Delete"] | undefined
};
	["RankingLocationsDocAccessFields_visible_Create"]: {
		permission: boolean
};
	["RankingLocationsDocAccessFields_visible_Read"]: {
		permission: boolean
};
	["RankingLocationsDocAccessFields_visible_Update"]: {
		permission: boolean
};
	["RankingLocationsDocAccessFields_visible_Delete"]: {
		permission: boolean
};
	["RankingLocationsDocAccessFields_maxItems"]: {
		create?: ModelTypes["RankingLocationsDocAccessFields_maxItems_Create"] | undefined,
	read?: ModelTypes["RankingLocationsDocAccessFields_maxItems_Read"] | undefined,
	update?: ModelTypes["RankingLocationsDocAccessFields_maxItems_Update"] | undefined,
	delete?: ModelTypes["RankingLocationsDocAccessFields_maxItems_Delete"] | undefined
};
	["RankingLocationsDocAccessFields_maxItems_Create"]: {
		permission: boolean
};
	["RankingLocationsDocAccessFields_maxItems_Read"]: {
		permission: boolean
};
	["RankingLocationsDocAccessFields_maxItems_Update"]: {
		permission: boolean
};
	["RankingLocationsDocAccessFields_maxItems_Delete"]: {
		permission: boolean
};
	["RankingLocationsDocAccessFields_template"]: {
		create?: ModelTypes["RankingLocationsDocAccessFields_template_Create"] | undefined,
	read?: ModelTypes["RankingLocationsDocAccessFields_template_Read"] | undefined,
	update?: ModelTypes["RankingLocationsDocAccessFields_template_Update"] | undefined,
	delete?: ModelTypes["RankingLocationsDocAccessFields_template_Delete"] | undefined
};
	["RankingLocationsDocAccessFields_template_Create"]: {
		permission: boolean
};
	["RankingLocationsDocAccessFields_template_Read"]: {
		permission: boolean
};
	["RankingLocationsDocAccessFields_template_Update"]: {
		permission: boolean
};
	["RankingLocationsDocAccessFields_template_Delete"]: {
		permission: boolean
};
	["RankingLocationsDocAccessFields_list"]: {
		create?: ModelTypes["RankingLocationsDocAccessFields_list_Create"] | undefined,
	read?: ModelTypes["RankingLocationsDocAccessFields_list_Read"] | undefined,
	update?: ModelTypes["RankingLocationsDocAccessFields_list_Update"] | undefined,
	delete?: ModelTypes["RankingLocationsDocAccessFields_list_Delete"] | undefined,
	fields?: ModelTypes["RankingLocationsDocAccessFields_list_Fields"] | undefined
};
	["RankingLocationsDocAccessFields_list_Create"]: {
		permission: boolean
};
	["RankingLocationsDocAccessFields_list_Read"]: {
		permission: boolean
};
	["RankingLocationsDocAccessFields_list_Update"]: {
		permission: boolean
};
	["RankingLocationsDocAccessFields_list_Delete"]: {
		permission: boolean
};
	["RankingLocationsDocAccessFields_list_Fields"]: {
		item?: ModelTypes["RankingLocationsDocAccessFields_list_item"] | undefined,
	id?: ModelTypes["RankingLocationsDocAccessFields_list_id"] | undefined
};
	["RankingLocationsDocAccessFields_list_item"]: {
		create?: ModelTypes["RankingLocationsDocAccessFields_list_item_Create"] | undefined,
	read?: ModelTypes["RankingLocationsDocAccessFields_list_item_Read"] | undefined,
	update?: ModelTypes["RankingLocationsDocAccessFields_list_item_Update"] | undefined,
	delete?: ModelTypes["RankingLocationsDocAccessFields_list_item_Delete"] | undefined
};
	["RankingLocationsDocAccessFields_list_item_Create"]: {
		permission: boolean
};
	["RankingLocationsDocAccessFields_list_item_Read"]: {
		permission: boolean
};
	["RankingLocationsDocAccessFields_list_item_Update"]: {
		permission: boolean
};
	["RankingLocationsDocAccessFields_list_item_Delete"]: {
		permission: boolean
};
	["RankingLocationsDocAccessFields_list_id"]: {
		create?: ModelTypes["RankingLocationsDocAccessFields_list_id_Create"] | undefined,
	read?: ModelTypes["RankingLocationsDocAccessFields_list_id_Read"] | undefined,
	update?: ModelTypes["RankingLocationsDocAccessFields_list_id_Update"] | undefined,
	delete?: ModelTypes["RankingLocationsDocAccessFields_list_id_Delete"] | undefined
};
	["RankingLocationsDocAccessFields_list_id_Create"]: {
		permission: boolean
};
	["RankingLocationsDocAccessFields_list_id_Read"]: {
		permission: boolean
};
	["RankingLocationsDocAccessFields_list_id_Update"]: {
		permission: boolean
};
	["RankingLocationsDocAccessFields_list_id_Delete"]: {
		permission: boolean
};
	["RankingLocationsDocAccessFields_region"]: {
		create?: ModelTypes["RankingLocationsDocAccessFields_region_Create"] | undefined,
	read?: ModelTypes["RankingLocationsDocAccessFields_region_Read"] | undefined,
	update?: ModelTypes["RankingLocationsDocAccessFields_region_Update"] | undefined,
	delete?: ModelTypes["RankingLocationsDocAccessFields_region_Delete"] | undefined
};
	["RankingLocationsDocAccessFields_region_Create"]: {
		permission: boolean
};
	["RankingLocationsDocAccessFields_region_Read"]: {
		permission: boolean
};
	["RankingLocationsDocAccessFields_region_Update"]: {
		permission: boolean
};
	["RankingLocationsDocAccessFields_region_Delete"]: {
		permission: boolean
};
	["RankingLocationsDocAccessFields_updatedAt"]: {
		create?: ModelTypes["RankingLocationsDocAccessFields_updatedAt_Create"] | undefined,
	read?: ModelTypes["RankingLocationsDocAccessFields_updatedAt_Read"] | undefined,
	update?: ModelTypes["RankingLocationsDocAccessFields_updatedAt_Update"] | undefined,
	delete?: ModelTypes["RankingLocationsDocAccessFields_updatedAt_Delete"] | undefined
};
	["RankingLocationsDocAccessFields_updatedAt_Create"]: {
		permission: boolean
};
	["RankingLocationsDocAccessFields_updatedAt_Read"]: {
		permission: boolean
};
	["RankingLocationsDocAccessFields_updatedAt_Update"]: {
		permission: boolean
};
	["RankingLocationsDocAccessFields_updatedAt_Delete"]: {
		permission: boolean
};
	["RankingLocationsDocAccessFields_createdAt"]: {
		create?: ModelTypes["RankingLocationsDocAccessFields_createdAt_Create"] | undefined,
	read?: ModelTypes["RankingLocationsDocAccessFields_createdAt_Read"] | undefined,
	update?: ModelTypes["RankingLocationsDocAccessFields_createdAt_Update"] | undefined,
	delete?: ModelTypes["RankingLocationsDocAccessFields_createdAt_Delete"] | undefined
};
	["RankingLocationsDocAccessFields_createdAt_Create"]: {
		permission: boolean
};
	["RankingLocationsDocAccessFields_createdAt_Read"]: {
		permission: boolean
};
	["RankingLocationsDocAccessFields_createdAt_Update"]: {
		permission: boolean
};
	["RankingLocationsDocAccessFields_createdAt_Delete"]: {
		permission: boolean
};
	["RankingLocationsCreateDocAccess"]: {
		permission: boolean,
	where?: ModelTypes["JSONObject"] | undefined
};
	["RankingLocationsReadDocAccess"]: {
		permission: boolean,
	where?: ModelTypes["JSONObject"] | undefined
};
	["RankingLocationsUpdateDocAccess"]: {
		permission: boolean,
	where?: ModelTypes["JSONObject"] | undefined
};
	["RankingLocationsDeleteDocAccess"]: {
		permission: boolean,
	where?: ModelTypes["JSONObject"] | undefined
};
	["EventCalendar"]: {
		id?: string | undefined,
	date: ModelTypes["DateTime"],
	item: ModelTypes["Event"],
	title?: string | undefined,
	region?: ModelTypes["EventCalendar_region"] | undefined,
	updatedAt?: ModelTypes["DateTime"] | undefined,
	createdAt?: ModelTypes["DateTime"] | undefined
};
	["EventCalendar_region"]:EventCalendar_region;
	["EventCalendars"]: {
		docs?: Array<ModelTypes["EventCalendar"] | undefined> | undefined,
	hasNextPage?: boolean | undefined,
	hasPrevPage?: boolean | undefined,
	limit?: number | undefined,
	nextPage?: number | undefined,
	offset?: number | undefined,
	page?: number | undefined,
	pagingCounter?: number | undefined,
	prevPage?: number | undefined,
	totalDocs?: number | undefined,
	totalPages?: number | undefined
};
	["EventCalendar_where"]: {
	date?: ModelTypes["EventCalendar_date_operator"] | undefined,
	item?: ModelTypes["EventCalendar_item_operator"] | undefined,
	title?: ModelTypes["EventCalendar_title_operator"] | undefined,
	region?: ModelTypes["EventCalendar_region_operator"] | undefined,
	updatedAt?: ModelTypes["EventCalendar_updatedAt_operator"] | undefined,
	createdAt?: ModelTypes["EventCalendar_createdAt_operator"] | undefined,
	id?: ModelTypes["EventCalendar_id_operator"] | undefined,
	AND?: Array<ModelTypes["EventCalendar_where_and"] | undefined> | undefined,
	OR?: Array<ModelTypes["EventCalendar_where_or"] | undefined> | undefined
};
	["EventCalendar_date_operator"]: {
	equals?: ModelTypes["DateTime"] | undefined,
	not_equals?: ModelTypes["DateTime"] | undefined,
	greater_than_equal?: ModelTypes["DateTime"] | undefined,
	greater_than?: ModelTypes["DateTime"] | undefined,
	less_than_equal?: ModelTypes["DateTime"] | undefined,
	less_than?: ModelTypes["DateTime"] | undefined,
	like?: ModelTypes["DateTime"] | undefined
};
	["EventCalendar_item_operator"]: {
	equals?: ModelTypes["JSON"] | undefined,
	not_equals?: ModelTypes["JSON"] | undefined,
	in?: Array<ModelTypes["JSON"] | undefined> | undefined,
	not_in?: Array<ModelTypes["JSON"] | undefined> | undefined,
	all?: Array<ModelTypes["JSON"] | undefined> | undefined
};
	["EventCalendar_title_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["EventCalendar_region_operator"]: {
	equals?: ModelTypes["EventCalendar_region_Input"] | undefined,
	not_equals?: ModelTypes["EventCalendar_region_Input"] | undefined,
	in?: Array<ModelTypes["EventCalendar_region_Input"] | undefined> | undefined,
	not_in?: Array<ModelTypes["EventCalendar_region_Input"] | undefined> | undefined,
	all?: Array<ModelTypes["EventCalendar_region_Input"] | undefined> | undefined,
	exists?: boolean | undefined
};
	["EventCalendar_region_Input"]:EventCalendar_region_Input;
	["EventCalendar_updatedAt_operator"]: {
	equals?: ModelTypes["DateTime"] | undefined,
	not_equals?: ModelTypes["DateTime"] | undefined,
	greater_than_equal?: ModelTypes["DateTime"] | undefined,
	greater_than?: ModelTypes["DateTime"] | undefined,
	less_than_equal?: ModelTypes["DateTime"] | undefined,
	less_than?: ModelTypes["DateTime"] | undefined,
	like?: ModelTypes["DateTime"] | undefined,
	exists?: boolean | undefined
};
	["EventCalendar_createdAt_operator"]: {
	equals?: ModelTypes["DateTime"] | undefined,
	not_equals?: ModelTypes["DateTime"] | undefined,
	greater_than_equal?: ModelTypes["DateTime"] | undefined,
	greater_than?: ModelTypes["DateTime"] | undefined,
	less_than_equal?: ModelTypes["DateTime"] | undefined,
	less_than?: ModelTypes["DateTime"] | undefined,
	like?: ModelTypes["DateTime"] | undefined,
	exists?: boolean | undefined
};
	["EventCalendar_id_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["EventCalendar_where_and"]: {
	date?: ModelTypes["EventCalendar_date_operator"] | undefined,
	item?: ModelTypes["EventCalendar_item_operator"] | undefined,
	title?: ModelTypes["EventCalendar_title_operator"] | undefined,
	region?: ModelTypes["EventCalendar_region_operator"] | undefined,
	updatedAt?: ModelTypes["EventCalendar_updatedAt_operator"] | undefined,
	createdAt?: ModelTypes["EventCalendar_createdAt_operator"] | undefined,
	id?: ModelTypes["EventCalendar_id_operator"] | undefined,
	AND?: Array<ModelTypes["EventCalendar_where_and"] | undefined> | undefined,
	OR?: Array<ModelTypes["EventCalendar_where_or"] | undefined> | undefined
};
	["EventCalendar_where_or"]: {
	date?: ModelTypes["EventCalendar_date_operator"] | undefined,
	item?: ModelTypes["EventCalendar_item_operator"] | undefined,
	title?: ModelTypes["EventCalendar_title_operator"] | undefined,
	region?: ModelTypes["EventCalendar_region_operator"] | undefined,
	updatedAt?: ModelTypes["EventCalendar_updatedAt_operator"] | undefined,
	createdAt?: ModelTypes["EventCalendar_createdAt_operator"] | undefined,
	id?: ModelTypes["EventCalendar_id_operator"] | undefined,
	AND?: Array<ModelTypes["EventCalendar_where_and"] | undefined> | undefined,
	OR?: Array<ModelTypes["EventCalendar_where_or"] | undefined> | undefined
};
	["countEventCalendars"]: {
		totalDocs?: number | undefined
};
	["event_calendarDocAccess"]: {
		fields?: ModelTypes["EventCalendarDocAccessFields"] | undefined,
	create?: ModelTypes["EventCalendarCreateDocAccess"] | undefined,
	read?: ModelTypes["EventCalendarReadDocAccess"] | undefined,
	update?: ModelTypes["EventCalendarUpdateDocAccess"] | undefined,
	delete?: ModelTypes["EventCalendarDeleteDocAccess"] | undefined
};
	["EventCalendarDocAccessFields"]: {
		date?: ModelTypes["EventCalendarDocAccessFields_date"] | undefined,
	item?: ModelTypes["EventCalendarDocAccessFields_item"] | undefined,
	title?: ModelTypes["EventCalendarDocAccessFields_title"] | undefined,
	region?: ModelTypes["EventCalendarDocAccessFields_region"] | undefined,
	updatedAt?: ModelTypes["EventCalendarDocAccessFields_updatedAt"] | undefined,
	createdAt?: ModelTypes["EventCalendarDocAccessFields_createdAt"] | undefined
};
	["EventCalendarDocAccessFields_date"]: {
		create?: ModelTypes["EventCalendarDocAccessFields_date_Create"] | undefined,
	read?: ModelTypes["EventCalendarDocAccessFields_date_Read"] | undefined,
	update?: ModelTypes["EventCalendarDocAccessFields_date_Update"] | undefined,
	delete?: ModelTypes["EventCalendarDocAccessFields_date_Delete"] | undefined
};
	["EventCalendarDocAccessFields_date_Create"]: {
		permission: boolean
};
	["EventCalendarDocAccessFields_date_Read"]: {
		permission: boolean
};
	["EventCalendarDocAccessFields_date_Update"]: {
		permission: boolean
};
	["EventCalendarDocAccessFields_date_Delete"]: {
		permission: boolean
};
	["EventCalendarDocAccessFields_item"]: {
		create?: ModelTypes["EventCalendarDocAccessFields_item_Create"] | undefined,
	read?: ModelTypes["EventCalendarDocAccessFields_item_Read"] | undefined,
	update?: ModelTypes["EventCalendarDocAccessFields_item_Update"] | undefined,
	delete?: ModelTypes["EventCalendarDocAccessFields_item_Delete"] | undefined
};
	["EventCalendarDocAccessFields_item_Create"]: {
		permission: boolean
};
	["EventCalendarDocAccessFields_item_Read"]: {
		permission: boolean
};
	["EventCalendarDocAccessFields_item_Update"]: {
		permission: boolean
};
	["EventCalendarDocAccessFields_item_Delete"]: {
		permission: boolean
};
	["EventCalendarDocAccessFields_title"]: {
		create?: ModelTypes["EventCalendarDocAccessFields_title_Create"] | undefined,
	read?: ModelTypes["EventCalendarDocAccessFields_title_Read"] | undefined,
	update?: ModelTypes["EventCalendarDocAccessFields_title_Update"] | undefined,
	delete?: ModelTypes["EventCalendarDocAccessFields_title_Delete"] | undefined
};
	["EventCalendarDocAccessFields_title_Create"]: {
		permission: boolean
};
	["EventCalendarDocAccessFields_title_Read"]: {
		permission: boolean
};
	["EventCalendarDocAccessFields_title_Update"]: {
		permission: boolean
};
	["EventCalendarDocAccessFields_title_Delete"]: {
		permission: boolean
};
	["EventCalendarDocAccessFields_region"]: {
		create?: ModelTypes["EventCalendarDocAccessFields_region_Create"] | undefined,
	read?: ModelTypes["EventCalendarDocAccessFields_region_Read"] | undefined,
	update?: ModelTypes["EventCalendarDocAccessFields_region_Update"] | undefined,
	delete?: ModelTypes["EventCalendarDocAccessFields_region_Delete"] | undefined
};
	["EventCalendarDocAccessFields_region_Create"]: {
		permission: boolean
};
	["EventCalendarDocAccessFields_region_Read"]: {
		permission: boolean
};
	["EventCalendarDocAccessFields_region_Update"]: {
		permission: boolean
};
	["EventCalendarDocAccessFields_region_Delete"]: {
		permission: boolean
};
	["EventCalendarDocAccessFields_updatedAt"]: {
		create?: ModelTypes["EventCalendarDocAccessFields_updatedAt_Create"] | undefined,
	read?: ModelTypes["EventCalendarDocAccessFields_updatedAt_Read"] | undefined,
	update?: ModelTypes["EventCalendarDocAccessFields_updatedAt_Update"] | undefined,
	delete?: ModelTypes["EventCalendarDocAccessFields_updatedAt_Delete"] | undefined
};
	["EventCalendarDocAccessFields_updatedAt_Create"]: {
		permission: boolean
};
	["EventCalendarDocAccessFields_updatedAt_Read"]: {
		permission: boolean
};
	["EventCalendarDocAccessFields_updatedAt_Update"]: {
		permission: boolean
};
	["EventCalendarDocAccessFields_updatedAt_Delete"]: {
		permission: boolean
};
	["EventCalendarDocAccessFields_createdAt"]: {
		create?: ModelTypes["EventCalendarDocAccessFields_createdAt_Create"] | undefined,
	read?: ModelTypes["EventCalendarDocAccessFields_createdAt_Read"] | undefined,
	update?: ModelTypes["EventCalendarDocAccessFields_createdAt_Update"] | undefined,
	delete?: ModelTypes["EventCalendarDocAccessFields_createdAt_Delete"] | undefined
};
	["EventCalendarDocAccessFields_createdAt_Create"]: {
		permission: boolean
};
	["EventCalendarDocAccessFields_createdAt_Read"]: {
		permission: boolean
};
	["EventCalendarDocAccessFields_createdAt_Update"]: {
		permission: boolean
};
	["EventCalendarDocAccessFields_createdAt_Delete"]: {
		permission: boolean
};
	["EventCalendarCreateDocAccess"]: {
		permission: boolean,
	where?: ModelTypes["JSONObject"] | undefined
};
	["EventCalendarReadDocAccess"]: {
		permission: boolean,
	where?: ModelTypes["JSONObject"] | undefined
};
	["EventCalendarUpdateDocAccess"]: {
		permission: boolean,
	where?: ModelTypes["JSONObject"] | undefined
};
	["EventCalendarDeleteDocAccess"]: {
		permission: boolean,
	where?: ModelTypes["JSONObject"] | undefined
};
	["EditorChoice"]: {
		id?: string | undefined,
	name?: string | undefined,
	visible?: boolean | undefined,
	editorialPicks?: Array<ModelTypes["Event"]> | undefined,
	list?: Array<ModelTypes["EditorChoice_List"]> | undefined,
	region?: ModelTypes["EditorChoice_region"] | undefined,
	updatedAt?: ModelTypes["DateTime"] | undefined,
	createdAt?: ModelTypes["DateTime"] | undefined
};
	["EditorChoice_List"]: {
		item?: ModelTypes["Event"] | undefined,
	id?: string | undefined
};
	["EditorChoice_region"]:EditorChoice_region;
	["EditorChoices"]: {
		docs?: Array<ModelTypes["EditorChoice"] | undefined> | undefined,
	hasNextPage?: boolean | undefined,
	hasPrevPage?: boolean | undefined,
	limit?: number | undefined,
	nextPage?: number | undefined,
	offset?: number | undefined,
	page?: number | undefined,
	pagingCounter?: number | undefined,
	prevPage?: number | undefined,
	totalDocs?: number | undefined,
	totalPages?: number | undefined
};
	["EditorChoice_where"]: {
	name?: ModelTypes["EditorChoice_name_operator"] | undefined,
	visible?: ModelTypes["EditorChoice_visible_operator"] | undefined,
	editorialPicks?: ModelTypes["EditorChoice_editorialPicks_operator"] | undefined,
	list__item?: ModelTypes["EditorChoice_list__item_operator"] | undefined,
	list__id?: ModelTypes["EditorChoice_list__id_operator"] | undefined,
	region?: ModelTypes["EditorChoice_region_operator"] | undefined,
	updatedAt?: ModelTypes["EditorChoice_updatedAt_operator"] | undefined,
	createdAt?: ModelTypes["EditorChoice_createdAt_operator"] | undefined,
	id?: ModelTypes["EditorChoice_id_operator"] | undefined,
	AND?: Array<ModelTypes["EditorChoice_where_and"] | undefined> | undefined,
	OR?: Array<ModelTypes["EditorChoice_where_or"] | undefined> | undefined
};
	["EditorChoice_name_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined
};
	["EditorChoice_visible_operator"]: {
	equals?: boolean | undefined,
	not_equals?: boolean | undefined,
	exists?: boolean | undefined
};
	["EditorChoice_editorialPicks_operator"]: {
	equals?: ModelTypes["JSON"] | undefined,
	not_equals?: ModelTypes["JSON"] | undefined,
	in?: Array<ModelTypes["JSON"] | undefined> | undefined,
	not_in?: Array<ModelTypes["JSON"] | undefined> | undefined,
	all?: Array<ModelTypes["JSON"] | undefined> | undefined,
	exists?: boolean | undefined
};
	["EditorChoice_list__item_operator"]: {
	equals?: ModelTypes["JSON"] | undefined,
	not_equals?: ModelTypes["JSON"] | undefined,
	in?: Array<ModelTypes["JSON"] | undefined> | undefined,
	not_in?: Array<ModelTypes["JSON"] | undefined> | undefined,
	all?: Array<ModelTypes["JSON"] | undefined> | undefined
};
	["EditorChoice_list__id_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["EditorChoice_region_operator"]: {
	equals?: ModelTypes["EditorChoice_region_Input"] | undefined,
	not_equals?: ModelTypes["EditorChoice_region_Input"] | undefined,
	in?: Array<ModelTypes["EditorChoice_region_Input"] | undefined> | undefined,
	not_in?: Array<ModelTypes["EditorChoice_region_Input"] | undefined> | undefined,
	all?: Array<ModelTypes["EditorChoice_region_Input"] | undefined> | undefined,
	exists?: boolean | undefined
};
	["EditorChoice_region_Input"]:EditorChoice_region_Input;
	["EditorChoice_updatedAt_operator"]: {
	equals?: ModelTypes["DateTime"] | undefined,
	not_equals?: ModelTypes["DateTime"] | undefined,
	greater_than_equal?: ModelTypes["DateTime"] | undefined,
	greater_than?: ModelTypes["DateTime"] | undefined,
	less_than_equal?: ModelTypes["DateTime"] | undefined,
	less_than?: ModelTypes["DateTime"] | undefined,
	like?: ModelTypes["DateTime"] | undefined,
	exists?: boolean | undefined
};
	["EditorChoice_createdAt_operator"]: {
	equals?: ModelTypes["DateTime"] | undefined,
	not_equals?: ModelTypes["DateTime"] | undefined,
	greater_than_equal?: ModelTypes["DateTime"] | undefined,
	greater_than?: ModelTypes["DateTime"] | undefined,
	less_than_equal?: ModelTypes["DateTime"] | undefined,
	less_than?: ModelTypes["DateTime"] | undefined,
	like?: ModelTypes["DateTime"] | undefined,
	exists?: boolean | undefined
};
	["EditorChoice_id_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["EditorChoice_where_and"]: {
	name?: ModelTypes["EditorChoice_name_operator"] | undefined,
	visible?: ModelTypes["EditorChoice_visible_operator"] | undefined,
	editorialPicks?: ModelTypes["EditorChoice_editorialPicks_operator"] | undefined,
	list__item?: ModelTypes["EditorChoice_list__item_operator"] | undefined,
	list__id?: ModelTypes["EditorChoice_list__id_operator"] | undefined,
	region?: ModelTypes["EditorChoice_region_operator"] | undefined,
	updatedAt?: ModelTypes["EditorChoice_updatedAt_operator"] | undefined,
	createdAt?: ModelTypes["EditorChoice_createdAt_operator"] | undefined,
	id?: ModelTypes["EditorChoice_id_operator"] | undefined,
	AND?: Array<ModelTypes["EditorChoice_where_and"] | undefined> | undefined,
	OR?: Array<ModelTypes["EditorChoice_where_or"] | undefined> | undefined
};
	["EditorChoice_where_or"]: {
	name?: ModelTypes["EditorChoice_name_operator"] | undefined,
	visible?: ModelTypes["EditorChoice_visible_operator"] | undefined,
	editorialPicks?: ModelTypes["EditorChoice_editorialPicks_operator"] | undefined,
	list__item?: ModelTypes["EditorChoice_list__item_operator"] | undefined,
	list__id?: ModelTypes["EditorChoice_list__id_operator"] | undefined,
	region?: ModelTypes["EditorChoice_region_operator"] | undefined,
	updatedAt?: ModelTypes["EditorChoice_updatedAt_operator"] | undefined,
	createdAt?: ModelTypes["EditorChoice_createdAt_operator"] | undefined,
	id?: ModelTypes["EditorChoice_id_operator"] | undefined,
	AND?: Array<ModelTypes["EditorChoice_where_and"] | undefined> | undefined,
	OR?: Array<ModelTypes["EditorChoice_where_or"] | undefined> | undefined
};
	["countEditorChoices"]: {
		totalDocs?: number | undefined
};
	["editor_choicesDocAccess"]: {
		fields?: ModelTypes["EditorChoicesDocAccessFields"] | undefined,
	create?: ModelTypes["EditorChoicesCreateDocAccess"] | undefined,
	read?: ModelTypes["EditorChoicesReadDocAccess"] | undefined,
	update?: ModelTypes["EditorChoicesUpdateDocAccess"] | undefined,
	delete?: ModelTypes["EditorChoicesDeleteDocAccess"] | undefined
};
	["EditorChoicesDocAccessFields"]: {
		name?: ModelTypes["EditorChoicesDocAccessFields_name"] | undefined,
	visible?: ModelTypes["EditorChoicesDocAccessFields_visible"] | undefined,
	editorialPicks?: ModelTypes["EditorChoicesDocAccessFields_editorialPicks"] | undefined,
	list?: ModelTypes["EditorChoicesDocAccessFields_list"] | undefined,
	region?: ModelTypes["EditorChoicesDocAccessFields_region"] | undefined,
	updatedAt?: ModelTypes["EditorChoicesDocAccessFields_updatedAt"] | undefined,
	createdAt?: ModelTypes["EditorChoicesDocAccessFields_createdAt"] | undefined
};
	["EditorChoicesDocAccessFields_name"]: {
		create?: ModelTypes["EditorChoicesDocAccessFields_name_Create"] | undefined,
	read?: ModelTypes["EditorChoicesDocAccessFields_name_Read"] | undefined,
	update?: ModelTypes["EditorChoicesDocAccessFields_name_Update"] | undefined,
	delete?: ModelTypes["EditorChoicesDocAccessFields_name_Delete"] | undefined
};
	["EditorChoicesDocAccessFields_name_Create"]: {
		permission: boolean
};
	["EditorChoicesDocAccessFields_name_Read"]: {
		permission: boolean
};
	["EditorChoicesDocAccessFields_name_Update"]: {
		permission: boolean
};
	["EditorChoicesDocAccessFields_name_Delete"]: {
		permission: boolean
};
	["EditorChoicesDocAccessFields_visible"]: {
		create?: ModelTypes["EditorChoicesDocAccessFields_visible_Create"] | undefined,
	read?: ModelTypes["EditorChoicesDocAccessFields_visible_Read"] | undefined,
	update?: ModelTypes["EditorChoicesDocAccessFields_visible_Update"] | undefined,
	delete?: ModelTypes["EditorChoicesDocAccessFields_visible_Delete"] | undefined
};
	["EditorChoicesDocAccessFields_visible_Create"]: {
		permission: boolean
};
	["EditorChoicesDocAccessFields_visible_Read"]: {
		permission: boolean
};
	["EditorChoicesDocAccessFields_visible_Update"]: {
		permission: boolean
};
	["EditorChoicesDocAccessFields_visible_Delete"]: {
		permission: boolean
};
	["EditorChoicesDocAccessFields_editorialPicks"]: {
		create?: ModelTypes["EditorChoicesDocAccessFields_editorialPicks_Create"] | undefined,
	read?: ModelTypes["EditorChoicesDocAccessFields_editorialPicks_Read"] | undefined,
	update?: ModelTypes["EditorChoicesDocAccessFields_editorialPicks_Update"] | undefined,
	delete?: ModelTypes["EditorChoicesDocAccessFields_editorialPicks_Delete"] | undefined
};
	["EditorChoicesDocAccessFields_editorialPicks_Create"]: {
		permission: boolean
};
	["EditorChoicesDocAccessFields_editorialPicks_Read"]: {
		permission: boolean
};
	["EditorChoicesDocAccessFields_editorialPicks_Update"]: {
		permission: boolean
};
	["EditorChoicesDocAccessFields_editorialPicks_Delete"]: {
		permission: boolean
};
	["EditorChoicesDocAccessFields_list"]: {
		create?: ModelTypes["EditorChoicesDocAccessFields_list_Create"] | undefined,
	read?: ModelTypes["EditorChoicesDocAccessFields_list_Read"] | undefined,
	update?: ModelTypes["EditorChoicesDocAccessFields_list_Update"] | undefined,
	delete?: ModelTypes["EditorChoicesDocAccessFields_list_Delete"] | undefined,
	fields?: ModelTypes["EditorChoicesDocAccessFields_list_Fields"] | undefined
};
	["EditorChoicesDocAccessFields_list_Create"]: {
		permission: boolean
};
	["EditorChoicesDocAccessFields_list_Read"]: {
		permission: boolean
};
	["EditorChoicesDocAccessFields_list_Update"]: {
		permission: boolean
};
	["EditorChoicesDocAccessFields_list_Delete"]: {
		permission: boolean
};
	["EditorChoicesDocAccessFields_list_Fields"]: {
		item?: ModelTypes["EditorChoicesDocAccessFields_list_item"] | undefined,
	id?: ModelTypes["EditorChoicesDocAccessFields_list_id"] | undefined
};
	["EditorChoicesDocAccessFields_list_item"]: {
		create?: ModelTypes["EditorChoicesDocAccessFields_list_item_Create"] | undefined,
	read?: ModelTypes["EditorChoicesDocAccessFields_list_item_Read"] | undefined,
	update?: ModelTypes["EditorChoicesDocAccessFields_list_item_Update"] | undefined,
	delete?: ModelTypes["EditorChoicesDocAccessFields_list_item_Delete"] | undefined
};
	["EditorChoicesDocAccessFields_list_item_Create"]: {
		permission: boolean
};
	["EditorChoicesDocAccessFields_list_item_Read"]: {
		permission: boolean
};
	["EditorChoicesDocAccessFields_list_item_Update"]: {
		permission: boolean
};
	["EditorChoicesDocAccessFields_list_item_Delete"]: {
		permission: boolean
};
	["EditorChoicesDocAccessFields_list_id"]: {
		create?: ModelTypes["EditorChoicesDocAccessFields_list_id_Create"] | undefined,
	read?: ModelTypes["EditorChoicesDocAccessFields_list_id_Read"] | undefined,
	update?: ModelTypes["EditorChoicesDocAccessFields_list_id_Update"] | undefined,
	delete?: ModelTypes["EditorChoicesDocAccessFields_list_id_Delete"] | undefined
};
	["EditorChoicesDocAccessFields_list_id_Create"]: {
		permission: boolean
};
	["EditorChoicesDocAccessFields_list_id_Read"]: {
		permission: boolean
};
	["EditorChoicesDocAccessFields_list_id_Update"]: {
		permission: boolean
};
	["EditorChoicesDocAccessFields_list_id_Delete"]: {
		permission: boolean
};
	["EditorChoicesDocAccessFields_region"]: {
		create?: ModelTypes["EditorChoicesDocAccessFields_region_Create"] | undefined,
	read?: ModelTypes["EditorChoicesDocAccessFields_region_Read"] | undefined,
	update?: ModelTypes["EditorChoicesDocAccessFields_region_Update"] | undefined,
	delete?: ModelTypes["EditorChoicesDocAccessFields_region_Delete"] | undefined
};
	["EditorChoicesDocAccessFields_region_Create"]: {
		permission: boolean
};
	["EditorChoicesDocAccessFields_region_Read"]: {
		permission: boolean
};
	["EditorChoicesDocAccessFields_region_Update"]: {
		permission: boolean
};
	["EditorChoicesDocAccessFields_region_Delete"]: {
		permission: boolean
};
	["EditorChoicesDocAccessFields_updatedAt"]: {
		create?: ModelTypes["EditorChoicesDocAccessFields_updatedAt_Create"] | undefined,
	read?: ModelTypes["EditorChoicesDocAccessFields_updatedAt_Read"] | undefined,
	update?: ModelTypes["EditorChoicesDocAccessFields_updatedAt_Update"] | undefined,
	delete?: ModelTypes["EditorChoicesDocAccessFields_updatedAt_Delete"] | undefined
};
	["EditorChoicesDocAccessFields_updatedAt_Create"]: {
		permission: boolean
};
	["EditorChoicesDocAccessFields_updatedAt_Read"]: {
		permission: boolean
};
	["EditorChoicesDocAccessFields_updatedAt_Update"]: {
		permission: boolean
};
	["EditorChoicesDocAccessFields_updatedAt_Delete"]: {
		permission: boolean
};
	["EditorChoicesDocAccessFields_createdAt"]: {
		create?: ModelTypes["EditorChoicesDocAccessFields_createdAt_Create"] | undefined,
	read?: ModelTypes["EditorChoicesDocAccessFields_createdAt_Read"] | undefined,
	update?: ModelTypes["EditorChoicesDocAccessFields_createdAt_Update"] | undefined,
	delete?: ModelTypes["EditorChoicesDocAccessFields_createdAt_Delete"] | undefined
};
	["EditorChoicesDocAccessFields_createdAt_Create"]: {
		permission: boolean
};
	["EditorChoicesDocAccessFields_createdAt_Read"]: {
		permission: boolean
};
	["EditorChoicesDocAccessFields_createdAt_Update"]: {
		permission: boolean
};
	["EditorChoicesDocAccessFields_createdAt_Delete"]: {
		permission: boolean
};
	["EditorChoicesCreateDocAccess"]: {
		permission: boolean,
	where?: ModelTypes["JSONObject"] | undefined
};
	["EditorChoicesReadDocAccess"]: {
		permission: boolean,
	where?: ModelTypes["JSONObject"] | undefined
};
	["EditorChoicesUpdateDocAccess"]: {
		permission: boolean,
	where?: ModelTypes["JSONObject"] | undefined
};
	["EditorChoicesDeleteDocAccess"]: {
		permission: boolean,
	where?: ModelTypes["JSONObject"] | undefined
};
	["CriteriaSetting"]: {
		id?: string | undefined,
	criteria?: ModelTypes["CriteriaSetting_Criteria"] | undefined,
	updatedAt?: ModelTypes["DateTime"] | undefined,
	createdAt?: ModelTypes["DateTime"] | undefined
};
	["CriteriaSetting_Criteria"]: {
		date?: Array<ModelTypes["Date"]> | undefined,
	audience?: Array<ModelTypes["Audience"]> | undefined,
	category?: Array<ModelTypes["EventCategory"]> | undefined,
	tag?: Array<ModelTypes["Tag"]> | undefined,
	region?: ModelTypes["CriteriaSetting_Criteria_region"] | undefined
};
	["CriteriaSetting_Criteria_region"]:CriteriaSetting_Criteria_region;
	["CriteriaSettings"]: {
		docs?: Array<ModelTypes["CriteriaSetting"] | undefined> | undefined,
	hasNextPage?: boolean | undefined,
	hasPrevPage?: boolean | undefined,
	limit?: number | undefined,
	nextPage?: number | undefined,
	offset?: number | undefined,
	page?: number | undefined,
	pagingCounter?: number | undefined,
	prevPage?: number | undefined,
	totalDocs?: number | undefined,
	totalPages?: number | undefined
};
	["CriteriaSetting_where"]: {
	criteria__date?: ModelTypes["CriteriaSetting_criteria__date_operator"] | undefined,
	criteria__audience?: ModelTypes["CriteriaSetting_criteria__audience_operator"] | undefined,
	criteria__category?: ModelTypes["CriteriaSetting_criteria__category_operator"] | undefined,
	criteria__tag?: ModelTypes["CriteriaSetting_criteria__tag_operator"] | undefined,
	criteria__region?: ModelTypes["CriteriaSetting_criteria__region_operator"] | undefined,
	updatedAt?: ModelTypes["CriteriaSetting_updatedAt_operator"] | undefined,
	createdAt?: ModelTypes["CriteriaSetting_createdAt_operator"] | undefined,
	id?: ModelTypes["CriteriaSetting_id_operator"] | undefined,
	AND?: Array<ModelTypes["CriteriaSetting_where_and"] | undefined> | undefined,
	OR?: Array<ModelTypes["CriteriaSetting_where_or"] | undefined> | undefined
};
	["CriteriaSetting_criteria__date_operator"]: {
	equals?: ModelTypes["JSON"] | undefined,
	not_equals?: ModelTypes["JSON"] | undefined,
	in?: Array<ModelTypes["JSON"] | undefined> | undefined,
	not_in?: Array<ModelTypes["JSON"] | undefined> | undefined,
	all?: Array<ModelTypes["JSON"] | undefined> | undefined,
	exists?: boolean | undefined
};
	["CriteriaSetting_criteria__audience_operator"]: {
	equals?: ModelTypes["JSON"] | undefined,
	not_equals?: ModelTypes["JSON"] | undefined,
	in?: Array<ModelTypes["JSON"] | undefined> | undefined,
	not_in?: Array<ModelTypes["JSON"] | undefined> | undefined,
	all?: Array<ModelTypes["JSON"] | undefined> | undefined,
	exists?: boolean | undefined
};
	["CriteriaSetting_criteria__category_operator"]: {
	equals?: ModelTypes["JSON"] | undefined,
	not_equals?: ModelTypes["JSON"] | undefined,
	in?: Array<ModelTypes["JSON"] | undefined> | undefined,
	not_in?: Array<ModelTypes["JSON"] | undefined> | undefined,
	all?: Array<ModelTypes["JSON"] | undefined> | undefined,
	exists?: boolean | undefined
};
	["CriteriaSetting_criteria__tag_operator"]: {
	equals?: ModelTypes["JSON"] | undefined,
	not_equals?: ModelTypes["JSON"] | undefined,
	in?: Array<ModelTypes["JSON"] | undefined> | undefined,
	not_in?: Array<ModelTypes["JSON"] | undefined> | undefined,
	all?: Array<ModelTypes["JSON"] | undefined> | undefined,
	exists?: boolean | undefined
};
	["CriteriaSetting_criteria__region_operator"]: {
	equals?: ModelTypes["CriteriaSetting_criteria__region_Input"] | undefined,
	not_equals?: ModelTypes["CriteriaSetting_criteria__region_Input"] | undefined,
	in?: Array<ModelTypes["CriteriaSetting_criteria__region_Input"] | undefined> | undefined,
	not_in?: Array<ModelTypes["CriteriaSetting_criteria__region_Input"] | undefined> | undefined,
	all?: Array<ModelTypes["CriteriaSetting_criteria__region_Input"] | undefined> | undefined,
	exists?: boolean | undefined
};
	["CriteriaSetting_criteria__region_Input"]:CriteriaSetting_criteria__region_Input;
	["CriteriaSetting_updatedAt_operator"]: {
	equals?: ModelTypes["DateTime"] | undefined,
	not_equals?: ModelTypes["DateTime"] | undefined,
	greater_than_equal?: ModelTypes["DateTime"] | undefined,
	greater_than?: ModelTypes["DateTime"] | undefined,
	less_than_equal?: ModelTypes["DateTime"] | undefined,
	less_than?: ModelTypes["DateTime"] | undefined,
	like?: ModelTypes["DateTime"] | undefined,
	exists?: boolean | undefined
};
	["CriteriaSetting_createdAt_operator"]: {
	equals?: ModelTypes["DateTime"] | undefined,
	not_equals?: ModelTypes["DateTime"] | undefined,
	greater_than_equal?: ModelTypes["DateTime"] | undefined,
	greater_than?: ModelTypes["DateTime"] | undefined,
	less_than_equal?: ModelTypes["DateTime"] | undefined,
	less_than?: ModelTypes["DateTime"] | undefined,
	like?: ModelTypes["DateTime"] | undefined,
	exists?: boolean | undefined
};
	["CriteriaSetting_id_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["CriteriaSetting_where_and"]: {
	criteria__date?: ModelTypes["CriteriaSetting_criteria__date_operator"] | undefined,
	criteria__audience?: ModelTypes["CriteriaSetting_criteria__audience_operator"] | undefined,
	criteria__category?: ModelTypes["CriteriaSetting_criteria__category_operator"] | undefined,
	criteria__tag?: ModelTypes["CriteriaSetting_criteria__tag_operator"] | undefined,
	criteria__region?: ModelTypes["CriteriaSetting_criteria__region_operator"] | undefined,
	updatedAt?: ModelTypes["CriteriaSetting_updatedAt_operator"] | undefined,
	createdAt?: ModelTypes["CriteriaSetting_createdAt_operator"] | undefined,
	id?: ModelTypes["CriteriaSetting_id_operator"] | undefined,
	AND?: Array<ModelTypes["CriteriaSetting_where_and"] | undefined> | undefined,
	OR?: Array<ModelTypes["CriteriaSetting_where_or"] | undefined> | undefined
};
	["CriteriaSetting_where_or"]: {
	criteria__date?: ModelTypes["CriteriaSetting_criteria__date_operator"] | undefined,
	criteria__audience?: ModelTypes["CriteriaSetting_criteria__audience_operator"] | undefined,
	criteria__category?: ModelTypes["CriteriaSetting_criteria__category_operator"] | undefined,
	criteria__tag?: ModelTypes["CriteriaSetting_criteria__tag_operator"] | undefined,
	criteria__region?: ModelTypes["CriteriaSetting_criteria__region_operator"] | undefined,
	updatedAt?: ModelTypes["CriteriaSetting_updatedAt_operator"] | undefined,
	createdAt?: ModelTypes["CriteriaSetting_createdAt_operator"] | undefined,
	id?: ModelTypes["CriteriaSetting_id_operator"] | undefined,
	AND?: Array<ModelTypes["CriteriaSetting_where_and"] | undefined> | undefined,
	OR?: Array<ModelTypes["CriteriaSetting_where_or"] | undefined> | undefined
};
	["countCriteriaSettings"]: {
		totalDocs?: number | undefined
};
	["criteria_settingDocAccess"]: {
		fields?: ModelTypes["CriteriaSettingDocAccessFields"] | undefined,
	create?: ModelTypes["CriteriaSettingCreateDocAccess"] | undefined,
	read?: ModelTypes["CriteriaSettingReadDocAccess"] | undefined,
	update?: ModelTypes["CriteriaSettingUpdateDocAccess"] | undefined,
	delete?: ModelTypes["CriteriaSettingDeleteDocAccess"] | undefined
};
	["CriteriaSettingDocAccessFields"]: {
		criteria?: ModelTypes["CriteriaSettingDocAccessFields_criteria"] | undefined,
	updatedAt?: ModelTypes["CriteriaSettingDocAccessFields_updatedAt"] | undefined,
	createdAt?: ModelTypes["CriteriaSettingDocAccessFields_createdAt"] | undefined
};
	["CriteriaSettingDocAccessFields_criteria"]: {
		create?: ModelTypes["CriteriaSettingDocAccessFields_criteria_Create"] | undefined,
	read?: ModelTypes["CriteriaSettingDocAccessFields_criteria_Read"] | undefined,
	update?: ModelTypes["CriteriaSettingDocAccessFields_criteria_Update"] | undefined,
	delete?: ModelTypes["CriteriaSettingDocAccessFields_criteria_Delete"] | undefined,
	fields?: ModelTypes["CriteriaSettingDocAccessFields_criteria_Fields"] | undefined
};
	["CriteriaSettingDocAccessFields_criteria_Create"]: {
		permission: boolean
};
	["CriteriaSettingDocAccessFields_criteria_Read"]: {
		permission: boolean
};
	["CriteriaSettingDocAccessFields_criteria_Update"]: {
		permission: boolean
};
	["CriteriaSettingDocAccessFields_criteria_Delete"]: {
		permission: boolean
};
	["CriteriaSettingDocAccessFields_criteria_Fields"]: {
		date?: ModelTypes["CriteriaSettingDocAccessFields_criteria_date"] | undefined,
	audience?: ModelTypes["CriteriaSettingDocAccessFields_criteria_audience"] | undefined,
	category?: ModelTypes["CriteriaSettingDocAccessFields_criteria_category"] | undefined,
	tag?: ModelTypes["CriteriaSettingDocAccessFields_criteria_tag"] | undefined,
	region?: ModelTypes["CriteriaSettingDocAccessFields_criteria_region"] | undefined
};
	["CriteriaSettingDocAccessFields_criteria_date"]: {
		create?: ModelTypes["CriteriaSettingDocAccessFields_criteria_date_Create"] | undefined,
	read?: ModelTypes["CriteriaSettingDocAccessFields_criteria_date_Read"] | undefined,
	update?: ModelTypes["CriteriaSettingDocAccessFields_criteria_date_Update"] | undefined,
	delete?: ModelTypes["CriteriaSettingDocAccessFields_criteria_date_Delete"] | undefined
};
	["CriteriaSettingDocAccessFields_criteria_date_Create"]: {
		permission: boolean
};
	["CriteriaSettingDocAccessFields_criteria_date_Read"]: {
		permission: boolean
};
	["CriteriaSettingDocAccessFields_criteria_date_Update"]: {
		permission: boolean
};
	["CriteriaSettingDocAccessFields_criteria_date_Delete"]: {
		permission: boolean
};
	["CriteriaSettingDocAccessFields_criteria_audience"]: {
		create?: ModelTypes["CriteriaSettingDocAccessFields_criteria_audience_Create"] | undefined,
	read?: ModelTypes["CriteriaSettingDocAccessFields_criteria_audience_Read"] | undefined,
	update?: ModelTypes["CriteriaSettingDocAccessFields_criteria_audience_Update"] | undefined,
	delete?: ModelTypes["CriteriaSettingDocAccessFields_criteria_audience_Delete"] | undefined
};
	["CriteriaSettingDocAccessFields_criteria_audience_Create"]: {
		permission: boolean
};
	["CriteriaSettingDocAccessFields_criteria_audience_Read"]: {
		permission: boolean
};
	["CriteriaSettingDocAccessFields_criteria_audience_Update"]: {
		permission: boolean
};
	["CriteriaSettingDocAccessFields_criteria_audience_Delete"]: {
		permission: boolean
};
	["CriteriaSettingDocAccessFields_criteria_category"]: {
		create?: ModelTypes["CriteriaSettingDocAccessFields_criteria_category_Create"] | undefined,
	read?: ModelTypes["CriteriaSettingDocAccessFields_criteria_category_Read"] | undefined,
	update?: ModelTypes["CriteriaSettingDocAccessFields_criteria_category_Update"] | undefined,
	delete?: ModelTypes["CriteriaSettingDocAccessFields_criteria_category_Delete"] | undefined
};
	["CriteriaSettingDocAccessFields_criteria_category_Create"]: {
		permission: boolean
};
	["CriteriaSettingDocAccessFields_criteria_category_Read"]: {
		permission: boolean
};
	["CriteriaSettingDocAccessFields_criteria_category_Update"]: {
		permission: boolean
};
	["CriteriaSettingDocAccessFields_criteria_category_Delete"]: {
		permission: boolean
};
	["CriteriaSettingDocAccessFields_criteria_tag"]: {
		create?: ModelTypes["CriteriaSettingDocAccessFields_criteria_tag_Create"] | undefined,
	read?: ModelTypes["CriteriaSettingDocAccessFields_criteria_tag_Read"] | undefined,
	update?: ModelTypes["CriteriaSettingDocAccessFields_criteria_tag_Update"] | undefined,
	delete?: ModelTypes["CriteriaSettingDocAccessFields_criteria_tag_Delete"] | undefined
};
	["CriteriaSettingDocAccessFields_criteria_tag_Create"]: {
		permission: boolean
};
	["CriteriaSettingDocAccessFields_criteria_tag_Read"]: {
		permission: boolean
};
	["CriteriaSettingDocAccessFields_criteria_tag_Update"]: {
		permission: boolean
};
	["CriteriaSettingDocAccessFields_criteria_tag_Delete"]: {
		permission: boolean
};
	["CriteriaSettingDocAccessFields_criteria_region"]: {
		create?: ModelTypes["CriteriaSettingDocAccessFields_criteria_region_Create"] | undefined,
	read?: ModelTypes["CriteriaSettingDocAccessFields_criteria_region_Read"] | undefined,
	update?: ModelTypes["CriteriaSettingDocAccessFields_criteria_region_Update"] | undefined,
	delete?: ModelTypes["CriteriaSettingDocAccessFields_criteria_region_Delete"] | undefined
};
	["CriteriaSettingDocAccessFields_criteria_region_Create"]: {
		permission: boolean
};
	["CriteriaSettingDocAccessFields_criteria_region_Read"]: {
		permission: boolean
};
	["CriteriaSettingDocAccessFields_criteria_region_Update"]: {
		permission: boolean
};
	["CriteriaSettingDocAccessFields_criteria_region_Delete"]: {
		permission: boolean
};
	["CriteriaSettingDocAccessFields_updatedAt"]: {
		create?: ModelTypes["CriteriaSettingDocAccessFields_updatedAt_Create"] | undefined,
	read?: ModelTypes["CriteriaSettingDocAccessFields_updatedAt_Read"] | undefined,
	update?: ModelTypes["CriteriaSettingDocAccessFields_updatedAt_Update"] | undefined,
	delete?: ModelTypes["CriteriaSettingDocAccessFields_updatedAt_Delete"] | undefined
};
	["CriteriaSettingDocAccessFields_updatedAt_Create"]: {
		permission: boolean
};
	["CriteriaSettingDocAccessFields_updatedAt_Read"]: {
		permission: boolean
};
	["CriteriaSettingDocAccessFields_updatedAt_Update"]: {
		permission: boolean
};
	["CriteriaSettingDocAccessFields_updatedAt_Delete"]: {
		permission: boolean
};
	["CriteriaSettingDocAccessFields_createdAt"]: {
		create?: ModelTypes["CriteriaSettingDocAccessFields_createdAt_Create"] | undefined,
	read?: ModelTypes["CriteriaSettingDocAccessFields_createdAt_Read"] | undefined,
	update?: ModelTypes["CriteriaSettingDocAccessFields_createdAt_Update"] | undefined,
	delete?: ModelTypes["CriteriaSettingDocAccessFields_createdAt_Delete"] | undefined
};
	["CriteriaSettingDocAccessFields_createdAt_Create"]: {
		permission: boolean
};
	["CriteriaSettingDocAccessFields_createdAt_Read"]: {
		permission: boolean
};
	["CriteriaSettingDocAccessFields_createdAt_Update"]: {
		permission: boolean
};
	["CriteriaSettingDocAccessFields_createdAt_Delete"]: {
		permission: boolean
};
	["CriteriaSettingCreateDocAccess"]: {
		permission: boolean,
	where?: ModelTypes["JSONObject"] | undefined
};
	["CriteriaSettingReadDocAccess"]: {
		permission: boolean,
	where?: ModelTypes["JSONObject"] | undefined
};
	["CriteriaSettingUpdateDocAccess"]: {
		permission: boolean,
	where?: ModelTypes["JSONObject"] | undefined
};
	["CriteriaSettingDeleteDocAccess"]: {
		permission: boolean,
	where?: ModelTypes["JSONObject"] | undefined
};
	["OrganiserSetting"]: {
		id?: string | undefined,
	name?: string | undefined,
	organiserList?: Array<ModelTypes["User"]> | undefined,
	region?: ModelTypes["OrganiserSetting_region"] | undefined,
	updatedAt?: ModelTypes["DateTime"] | undefined,
	createdAt?: ModelTypes["DateTime"] | undefined
};
	["OrganiserSetting_region"]:OrganiserSetting_region;
	["OrganiserSettings"]: {
		docs?: Array<ModelTypes["OrganiserSetting"] | undefined> | undefined,
	hasNextPage?: boolean | undefined,
	hasPrevPage?: boolean | undefined,
	limit?: number | undefined,
	nextPage?: number | undefined,
	offset?: number | undefined,
	page?: number | undefined,
	pagingCounter?: number | undefined,
	prevPage?: number | undefined,
	totalDocs?: number | undefined,
	totalPages?: number | undefined
};
	["OrganiserSetting_where"]: {
	name?: ModelTypes["OrganiserSetting_name_operator"] | undefined,
	organiserList?: ModelTypes["OrganiserSetting_organiserList_operator"] | undefined,
	region?: ModelTypes["OrganiserSetting_region_operator"] | undefined,
	updatedAt?: ModelTypes["OrganiserSetting_updatedAt_operator"] | undefined,
	createdAt?: ModelTypes["OrganiserSetting_createdAt_operator"] | undefined,
	id?: ModelTypes["OrganiserSetting_id_operator"] | undefined,
	AND?: Array<ModelTypes["OrganiserSetting_where_and"] | undefined> | undefined,
	OR?: Array<ModelTypes["OrganiserSetting_where_or"] | undefined> | undefined
};
	["OrganiserSetting_name_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["OrganiserSetting_organiserList_operator"]: {
	equals?: ModelTypes["JSON"] | undefined,
	not_equals?: ModelTypes["JSON"] | undefined,
	in?: Array<ModelTypes["JSON"] | undefined> | undefined,
	not_in?: Array<ModelTypes["JSON"] | undefined> | undefined,
	all?: Array<ModelTypes["JSON"] | undefined> | undefined,
	exists?: boolean | undefined
};
	["OrganiserSetting_region_operator"]: {
	equals?: ModelTypes["OrganiserSetting_region_Input"] | undefined,
	not_equals?: ModelTypes["OrganiserSetting_region_Input"] | undefined,
	in?: Array<ModelTypes["OrganiserSetting_region_Input"] | undefined> | undefined,
	not_in?: Array<ModelTypes["OrganiserSetting_region_Input"] | undefined> | undefined,
	all?: Array<ModelTypes["OrganiserSetting_region_Input"] | undefined> | undefined,
	exists?: boolean | undefined
};
	["OrganiserSetting_region_Input"]:OrganiserSetting_region_Input;
	["OrganiserSetting_updatedAt_operator"]: {
	equals?: ModelTypes["DateTime"] | undefined,
	not_equals?: ModelTypes["DateTime"] | undefined,
	greater_than_equal?: ModelTypes["DateTime"] | undefined,
	greater_than?: ModelTypes["DateTime"] | undefined,
	less_than_equal?: ModelTypes["DateTime"] | undefined,
	less_than?: ModelTypes["DateTime"] | undefined,
	like?: ModelTypes["DateTime"] | undefined,
	exists?: boolean | undefined
};
	["OrganiserSetting_createdAt_operator"]: {
	equals?: ModelTypes["DateTime"] | undefined,
	not_equals?: ModelTypes["DateTime"] | undefined,
	greater_than_equal?: ModelTypes["DateTime"] | undefined,
	greater_than?: ModelTypes["DateTime"] | undefined,
	less_than_equal?: ModelTypes["DateTime"] | undefined,
	less_than?: ModelTypes["DateTime"] | undefined,
	like?: ModelTypes["DateTime"] | undefined,
	exists?: boolean | undefined
};
	["OrganiserSetting_id_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["OrganiserSetting_where_and"]: {
	name?: ModelTypes["OrganiserSetting_name_operator"] | undefined,
	organiserList?: ModelTypes["OrganiserSetting_organiserList_operator"] | undefined,
	region?: ModelTypes["OrganiserSetting_region_operator"] | undefined,
	updatedAt?: ModelTypes["OrganiserSetting_updatedAt_operator"] | undefined,
	createdAt?: ModelTypes["OrganiserSetting_createdAt_operator"] | undefined,
	id?: ModelTypes["OrganiserSetting_id_operator"] | undefined,
	AND?: Array<ModelTypes["OrganiserSetting_where_and"] | undefined> | undefined,
	OR?: Array<ModelTypes["OrganiserSetting_where_or"] | undefined> | undefined
};
	["OrganiserSetting_where_or"]: {
	name?: ModelTypes["OrganiserSetting_name_operator"] | undefined,
	organiserList?: ModelTypes["OrganiserSetting_organiserList_operator"] | undefined,
	region?: ModelTypes["OrganiserSetting_region_operator"] | undefined,
	updatedAt?: ModelTypes["OrganiserSetting_updatedAt_operator"] | undefined,
	createdAt?: ModelTypes["OrganiserSetting_createdAt_operator"] | undefined,
	id?: ModelTypes["OrganiserSetting_id_operator"] | undefined,
	AND?: Array<ModelTypes["OrganiserSetting_where_and"] | undefined> | undefined,
	OR?: Array<ModelTypes["OrganiserSetting_where_or"] | undefined> | undefined
};
	["countOrganiserSettings"]: {
		totalDocs?: number | undefined
};
	["organiserSettingDocAccess"]: {
		fields?: ModelTypes["OrganiserSettingDocAccessFields"] | undefined,
	create?: ModelTypes["OrganiserSettingCreateDocAccess"] | undefined,
	read?: ModelTypes["OrganiserSettingReadDocAccess"] | undefined,
	update?: ModelTypes["OrganiserSettingUpdateDocAccess"] | undefined,
	delete?: ModelTypes["OrganiserSettingDeleteDocAccess"] | undefined
};
	["OrganiserSettingDocAccessFields"]: {
		name?: ModelTypes["OrganiserSettingDocAccessFields_name"] | undefined,
	organiserList?: ModelTypes["OrganiserSettingDocAccessFields_organiserList"] | undefined,
	region?: ModelTypes["OrganiserSettingDocAccessFields_region"] | undefined,
	updatedAt?: ModelTypes["OrganiserSettingDocAccessFields_updatedAt"] | undefined,
	createdAt?: ModelTypes["OrganiserSettingDocAccessFields_createdAt"] | undefined
};
	["OrganiserSettingDocAccessFields_name"]: {
		create?: ModelTypes["OrganiserSettingDocAccessFields_name_Create"] | undefined,
	read?: ModelTypes["OrganiserSettingDocAccessFields_name_Read"] | undefined,
	update?: ModelTypes["OrganiserSettingDocAccessFields_name_Update"] | undefined,
	delete?: ModelTypes["OrganiserSettingDocAccessFields_name_Delete"] | undefined
};
	["OrganiserSettingDocAccessFields_name_Create"]: {
		permission: boolean
};
	["OrganiserSettingDocAccessFields_name_Read"]: {
		permission: boolean
};
	["OrganiserSettingDocAccessFields_name_Update"]: {
		permission: boolean
};
	["OrganiserSettingDocAccessFields_name_Delete"]: {
		permission: boolean
};
	["OrganiserSettingDocAccessFields_organiserList"]: {
		create?: ModelTypes["OrganiserSettingDocAccessFields_organiserList_Create"] | undefined,
	read?: ModelTypes["OrganiserSettingDocAccessFields_organiserList_Read"] | undefined,
	update?: ModelTypes["OrganiserSettingDocAccessFields_organiserList_Update"] | undefined,
	delete?: ModelTypes["OrganiserSettingDocAccessFields_organiserList_Delete"] | undefined
};
	["OrganiserSettingDocAccessFields_organiserList_Create"]: {
		permission: boolean
};
	["OrganiserSettingDocAccessFields_organiserList_Read"]: {
		permission: boolean
};
	["OrganiserSettingDocAccessFields_organiserList_Update"]: {
		permission: boolean
};
	["OrganiserSettingDocAccessFields_organiserList_Delete"]: {
		permission: boolean
};
	["OrganiserSettingDocAccessFields_region"]: {
		create?: ModelTypes["OrganiserSettingDocAccessFields_region_Create"] | undefined,
	read?: ModelTypes["OrganiserSettingDocAccessFields_region_Read"] | undefined,
	update?: ModelTypes["OrganiserSettingDocAccessFields_region_Update"] | undefined,
	delete?: ModelTypes["OrganiserSettingDocAccessFields_region_Delete"] | undefined
};
	["OrganiserSettingDocAccessFields_region_Create"]: {
		permission: boolean
};
	["OrganiserSettingDocAccessFields_region_Read"]: {
		permission: boolean
};
	["OrganiserSettingDocAccessFields_region_Update"]: {
		permission: boolean
};
	["OrganiserSettingDocAccessFields_region_Delete"]: {
		permission: boolean
};
	["OrganiserSettingDocAccessFields_updatedAt"]: {
		create?: ModelTypes["OrganiserSettingDocAccessFields_updatedAt_Create"] | undefined,
	read?: ModelTypes["OrganiserSettingDocAccessFields_updatedAt_Read"] | undefined,
	update?: ModelTypes["OrganiserSettingDocAccessFields_updatedAt_Update"] | undefined,
	delete?: ModelTypes["OrganiserSettingDocAccessFields_updatedAt_Delete"] | undefined
};
	["OrganiserSettingDocAccessFields_updatedAt_Create"]: {
		permission: boolean
};
	["OrganiserSettingDocAccessFields_updatedAt_Read"]: {
		permission: boolean
};
	["OrganiserSettingDocAccessFields_updatedAt_Update"]: {
		permission: boolean
};
	["OrganiserSettingDocAccessFields_updatedAt_Delete"]: {
		permission: boolean
};
	["OrganiserSettingDocAccessFields_createdAt"]: {
		create?: ModelTypes["OrganiserSettingDocAccessFields_createdAt_Create"] | undefined,
	read?: ModelTypes["OrganiserSettingDocAccessFields_createdAt_Read"] | undefined,
	update?: ModelTypes["OrganiserSettingDocAccessFields_createdAt_Update"] | undefined,
	delete?: ModelTypes["OrganiserSettingDocAccessFields_createdAt_Delete"] | undefined
};
	["OrganiserSettingDocAccessFields_createdAt_Create"]: {
		permission: boolean
};
	["OrganiserSettingDocAccessFields_createdAt_Read"]: {
		permission: boolean
};
	["OrganiserSettingDocAccessFields_createdAt_Update"]: {
		permission: boolean
};
	["OrganiserSettingDocAccessFields_createdAt_Delete"]: {
		permission: boolean
};
	["OrganiserSettingCreateDocAccess"]: {
		permission: boolean,
	where?: ModelTypes["JSONObject"] | undefined
};
	["OrganiserSettingReadDocAccess"]: {
		permission: boolean,
	where?: ModelTypes["JSONObject"] | undefined
};
	["OrganiserSettingUpdateDocAccess"]: {
		permission: boolean,
	where?: ModelTypes["JSONObject"] | undefined
};
	["OrganiserSettingDeleteDocAccess"]: {
		permission: boolean,
	where?: ModelTypes["JSONObject"] | undefined
};
	["PostSetting"]: {
		id?: string | undefined,
	name?: string | undefined,
	featuredPosts?: Array<ModelTypes["Post"]> | undefined,
	region?: ModelTypes["PostSetting_region"] | undefined,
	updatedAt?: ModelTypes["DateTime"] | undefined,
	createdAt?: ModelTypes["DateTime"] | undefined
};
	["PostSetting_region"]:PostSetting_region;
	["PostSettings"]: {
		docs?: Array<ModelTypes["PostSetting"] | undefined> | undefined,
	hasNextPage?: boolean | undefined,
	hasPrevPage?: boolean | undefined,
	limit?: number | undefined,
	nextPage?: number | undefined,
	offset?: number | undefined,
	page?: number | undefined,
	pagingCounter?: number | undefined,
	prevPage?: number | undefined,
	totalDocs?: number | undefined,
	totalPages?: number | undefined
};
	["PostSetting_where"]: {
	name?: ModelTypes["PostSetting_name_operator"] | undefined,
	featuredPosts?: ModelTypes["PostSetting_featuredPosts_operator"] | undefined,
	region?: ModelTypes["PostSetting_region_operator"] | undefined,
	updatedAt?: ModelTypes["PostSetting_updatedAt_operator"] | undefined,
	createdAt?: ModelTypes["PostSetting_createdAt_operator"] | undefined,
	id?: ModelTypes["PostSetting_id_operator"] | undefined,
	AND?: Array<ModelTypes["PostSetting_where_and"] | undefined> | undefined,
	OR?: Array<ModelTypes["PostSetting_where_or"] | undefined> | undefined
};
	["PostSetting_name_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["PostSetting_featuredPosts_operator"]: {
	equals?: ModelTypes["JSON"] | undefined,
	not_equals?: ModelTypes["JSON"] | undefined,
	in?: Array<ModelTypes["JSON"] | undefined> | undefined,
	not_in?: Array<ModelTypes["JSON"] | undefined> | undefined,
	all?: Array<ModelTypes["JSON"] | undefined> | undefined,
	exists?: boolean | undefined
};
	["PostSetting_region_operator"]: {
	equals?: ModelTypes["PostSetting_region_Input"] | undefined,
	not_equals?: ModelTypes["PostSetting_region_Input"] | undefined,
	in?: Array<ModelTypes["PostSetting_region_Input"] | undefined> | undefined,
	not_in?: Array<ModelTypes["PostSetting_region_Input"] | undefined> | undefined,
	all?: Array<ModelTypes["PostSetting_region_Input"] | undefined> | undefined,
	exists?: boolean | undefined
};
	["PostSetting_region_Input"]:PostSetting_region_Input;
	["PostSetting_updatedAt_operator"]: {
	equals?: ModelTypes["DateTime"] | undefined,
	not_equals?: ModelTypes["DateTime"] | undefined,
	greater_than_equal?: ModelTypes["DateTime"] | undefined,
	greater_than?: ModelTypes["DateTime"] | undefined,
	less_than_equal?: ModelTypes["DateTime"] | undefined,
	less_than?: ModelTypes["DateTime"] | undefined,
	like?: ModelTypes["DateTime"] | undefined,
	exists?: boolean | undefined
};
	["PostSetting_createdAt_operator"]: {
	equals?: ModelTypes["DateTime"] | undefined,
	not_equals?: ModelTypes["DateTime"] | undefined,
	greater_than_equal?: ModelTypes["DateTime"] | undefined,
	greater_than?: ModelTypes["DateTime"] | undefined,
	less_than_equal?: ModelTypes["DateTime"] | undefined,
	less_than?: ModelTypes["DateTime"] | undefined,
	like?: ModelTypes["DateTime"] | undefined,
	exists?: boolean | undefined
};
	["PostSetting_id_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["PostSetting_where_and"]: {
	name?: ModelTypes["PostSetting_name_operator"] | undefined,
	featuredPosts?: ModelTypes["PostSetting_featuredPosts_operator"] | undefined,
	region?: ModelTypes["PostSetting_region_operator"] | undefined,
	updatedAt?: ModelTypes["PostSetting_updatedAt_operator"] | undefined,
	createdAt?: ModelTypes["PostSetting_createdAt_operator"] | undefined,
	id?: ModelTypes["PostSetting_id_operator"] | undefined,
	AND?: Array<ModelTypes["PostSetting_where_and"] | undefined> | undefined,
	OR?: Array<ModelTypes["PostSetting_where_or"] | undefined> | undefined
};
	["PostSetting_where_or"]: {
	name?: ModelTypes["PostSetting_name_operator"] | undefined,
	featuredPosts?: ModelTypes["PostSetting_featuredPosts_operator"] | undefined,
	region?: ModelTypes["PostSetting_region_operator"] | undefined,
	updatedAt?: ModelTypes["PostSetting_updatedAt_operator"] | undefined,
	createdAt?: ModelTypes["PostSetting_createdAt_operator"] | undefined,
	id?: ModelTypes["PostSetting_id_operator"] | undefined,
	AND?: Array<ModelTypes["PostSetting_where_and"] | undefined> | undefined,
	OR?: Array<ModelTypes["PostSetting_where_or"] | undefined> | undefined
};
	["countPostSettings"]: {
		totalDocs?: number | undefined
};
	["post_settingDocAccess"]: {
		fields?: ModelTypes["PostSettingDocAccessFields"] | undefined,
	create?: ModelTypes["PostSettingCreateDocAccess"] | undefined,
	read?: ModelTypes["PostSettingReadDocAccess"] | undefined,
	update?: ModelTypes["PostSettingUpdateDocAccess"] | undefined,
	delete?: ModelTypes["PostSettingDeleteDocAccess"] | undefined
};
	["PostSettingDocAccessFields"]: {
		name?: ModelTypes["PostSettingDocAccessFields_name"] | undefined,
	featuredPosts?: ModelTypes["PostSettingDocAccessFields_featuredPosts"] | undefined,
	region?: ModelTypes["PostSettingDocAccessFields_region"] | undefined,
	updatedAt?: ModelTypes["PostSettingDocAccessFields_updatedAt"] | undefined,
	createdAt?: ModelTypes["PostSettingDocAccessFields_createdAt"] | undefined
};
	["PostSettingDocAccessFields_name"]: {
		create?: ModelTypes["PostSettingDocAccessFields_name_Create"] | undefined,
	read?: ModelTypes["PostSettingDocAccessFields_name_Read"] | undefined,
	update?: ModelTypes["PostSettingDocAccessFields_name_Update"] | undefined,
	delete?: ModelTypes["PostSettingDocAccessFields_name_Delete"] | undefined
};
	["PostSettingDocAccessFields_name_Create"]: {
		permission: boolean
};
	["PostSettingDocAccessFields_name_Read"]: {
		permission: boolean
};
	["PostSettingDocAccessFields_name_Update"]: {
		permission: boolean
};
	["PostSettingDocAccessFields_name_Delete"]: {
		permission: boolean
};
	["PostSettingDocAccessFields_featuredPosts"]: {
		create?: ModelTypes["PostSettingDocAccessFields_featuredPosts_Create"] | undefined,
	read?: ModelTypes["PostSettingDocAccessFields_featuredPosts_Read"] | undefined,
	update?: ModelTypes["PostSettingDocAccessFields_featuredPosts_Update"] | undefined,
	delete?: ModelTypes["PostSettingDocAccessFields_featuredPosts_Delete"] | undefined
};
	["PostSettingDocAccessFields_featuredPosts_Create"]: {
		permission: boolean
};
	["PostSettingDocAccessFields_featuredPosts_Read"]: {
		permission: boolean
};
	["PostSettingDocAccessFields_featuredPosts_Update"]: {
		permission: boolean
};
	["PostSettingDocAccessFields_featuredPosts_Delete"]: {
		permission: boolean
};
	["PostSettingDocAccessFields_region"]: {
		create?: ModelTypes["PostSettingDocAccessFields_region_Create"] | undefined,
	read?: ModelTypes["PostSettingDocAccessFields_region_Read"] | undefined,
	update?: ModelTypes["PostSettingDocAccessFields_region_Update"] | undefined,
	delete?: ModelTypes["PostSettingDocAccessFields_region_Delete"] | undefined
};
	["PostSettingDocAccessFields_region_Create"]: {
		permission: boolean
};
	["PostSettingDocAccessFields_region_Read"]: {
		permission: boolean
};
	["PostSettingDocAccessFields_region_Update"]: {
		permission: boolean
};
	["PostSettingDocAccessFields_region_Delete"]: {
		permission: boolean
};
	["PostSettingDocAccessFields_updatedAt"]: {
		create?: ModelTypes["PostSettingDocAccessFields_updatedAt_Create"] | undefined,
	read?: ModelTypes["PostSettingDocAccessFields_updatedAt_Read"] | undefined,
	update?: ModelTypes["PostSettingDocAccessFields_updatedAt_Update"] | undefined,
	delete?: ModelTypes["PostSettingDocAccessFields_updatedAt_Delete"] | undefined
};
	["PostSettingDocAccessFields_updatedAt_Create"]: {
		permission: boolean
};
	["PostSettingDocAccessFields_updatedAt_Read"]: {
		permission: boolean
};
	["PostSettingDocAccessFields_updatedAt_Update"]: {
		permission: boolean
};
	["PostSettingDocAccessFields_updatedAt_Delete"]: {
		permission: boolean
};
	["PostSettingDocAccessFields_createdAt"]: {
		create?: ModelTypes["PostSettingDocAccessFields_createdAt_Create"] | undefined,
	read?: ModelTypes["PostSettingDocAccessFields_createdAt_Read"] | undefined,
	update?: ModelTypes["PostSettingDocAccessFields_createdAt_Update"] | undefined,
	delete?: ModelTypes["PostSettingDocAccessFields_createdAt_Delete"] | undefined
};
	["PostSettingDocAccessFields_createdAt_Create"]: {
		permission: boolean
};
	["PostSettingDocAccessFields_createdAt_Read"]: {
		permission: boolean
};
	["PostSettingDocAccessFields_createdAt_Update"]: {
		permission: boolean
};
	["PostSettingDocAccessFields_createdAt_Delete"]: {
		permission: boolean
};
	["PostSettingCreateDocAccess"]: {
		permission: boolean,
	where?: ModelTypes["JSONObject"] | undefined
};
	["PostSettingReadDocAccess"]: {
		permission: boolean,
	where?: ModelTypes["JSONObject"] | undefined
};
	["PostSettingUpdateDocAccess"]: {
		permission: boolean,
	where?: ModelTypes["JSONObject"] | undefined
};
	["PostSettingDeleteDocAccess"]: {
		permission: boolean,
	where?: ModelTypes["JSONObject"] | undefined
};
	["HomepageSetting"]: {
		id?: string | undefined,
	name?: string | undefined,
	region?: ModelTypes["HomepageSetting_region"] | undefined,
	sections?: Array<ModelTypes["HomepageSetting_Sections"]> | undefined,
	updatedAt?: ModelTypes["DateTime"] | undefined,
	createdAt?: ModelTypes["DateTime"] | undefined
};
	["HomepageSetting_region"]:HomepageSetting_region;
	["HomepageSetting_Sections"]:ModelTypes["ComingWeekEventAndLocationRankingBlock"] | ModelTypes["CategoryListBlock"] | ModelTypes["UserPreferenceBlock"] | ModelTypes["EventRankingBlock"] | ModelTypes["EventTrendBlock"] | ModelTypes["LocationRankingBlock"] | ModelTypes["OrganizarBlock"] | ModelTypes["SubscriptionBlock"] | ModelTypes["TimableRankingBlock"] | ModelTypes["MultipleEventRankingBlock"] | ModelTypes["TicketingBlock"] | ModelTypes["BlogBlock"] | ModelTypes["EditorChoiceBlock"];
	["ComingWeekEventAndLocationRankingBlock"]: {
		title?: string | undefined,
	locationRankingTitle?: string | undefined,
	appearance: ModelTypes["ComingWeekEventAndLocationRankingBlock_appearance"],
	byRegion?: ModelTypes["ComingWeekEventAndLocationRankingBlock_byRegion"] | undefined,
	rankings?: Array<ModelTypes["LocationRanking"]> | undefined,
	id?: string | undefined,
	blockName?: string | undefined,
	blockType?: string | undefined
};
	["ComingWeekEventAndLocationRankingBlock_appearance"]:ComingWeekEventAndLocationRankingBlock_appearance;
	["ComingWeekEventAndLocationRankingBlock_byRegion"]:ComingWeekEventAndLocationRankingBlock_byRegion;
	["CategoryListBlock"]: {
		title?: string | undefined,
	id?: string | undefined,
	blockName?: string | undefined,
	blockType?: string | undefined
};
	["UserPreferenceBlock"]: {
		title?: string | undefined,
	id?: string | undefined,
	blockName?: string | undefined,
	blockType?: string | undefined
};
	["EventRankingBlock"]: {
		title?: string | undefined,
	appearance: ModelTypes["EventRankingBlock_appearance"],
	ranking: ModelTypes["EventRanking"],
	id?: string | undefined,
	blockName?: string | undefined,
	blockType?: string | undefined
};
	["EventRankingBlock_appearance"]:EventRankingBlock_appearance;
	["EventTrendBlock"]: {
		title?: string | undefined,
	id?: string | undefined,
	blockName?: string | undefined,
	blockType?: string | undefined
};
	["LocationRankingBlock"]: {
		title?: string | undefined,
	appearance: ModelTypes["LocationRankingBlock_appearance"],
	ranking: ModelTypes["LocationRanking"],
	id?: string | undefined,
	blockName?: string | undefined,
	blockType?: string | undefined
};
	["LocationRankingBlock_appearance"]:LocationRankingBlock_appearance;
	["OrganizarBlock"]: {
		title?: string | undefined,
	id?: string | undefined,
	blockName?: string | undefined,
	blockType?: string | undefined
};
	["SubscriptionBlock"]: {
		title?: string | undefined,
	id?: string | undefined,
	blockName?: string | undefined,
	blockType?: string | undefined
};
	["TimableRankingBlock"]: {
		title?: string | undefined,
	ranking?: Array<ModelTypes["LocationRanking"]> | undefined,
	id?: string | undefined,
	blockName?: string | undefined,
	blockType?: string | undefined
};
	["MultipleEventRankingBlock"]: {
		title?: string | undefined,
	ranking?: Array<ModelTypes["EventRanking"]> | undefined,
	id?: string | undefined,
	blockName?: string | undefined,
	blockType?: string | undefined
};
	["TicketingBlock"]: {
		title?: string | undefined,
	id?: string | undefined,
	blockName?: string | undefined,
	blockType?: string | undefined
};
	["BlogBlock"]: {
		title?: string | undefined,
	id?: string | undefined,
	blockName?: string | undefined,
	blockType?: string | undefined
};
	["EditorChoiceBlock"]: {
		title?: string | undefined,
	id?: string | undefined,
	blockName?: string | undefined,
	blockType?: string | undefined
};
	["HomepageSettings"]: {
		docs?: Array<ModelTypes["HomepageSetting"] | undefined> | undefined,
	hasNextPage?: boolean | undefined,
	hasPrevPage?: boolean | undefined,
	limit?: number | undefined,
	nextPage?: number | undefined,
	offset?: number | undefined,
	page?: number | undefined,
	pagingCounter?: number | undefined,
	prevPage?: number | undefined,
	totalDocs?: number | undefined,
	totalPages?: number | undefined
};
	["HomepageSetting_where"]: {
	name?: ModelTypes["HomepageSetting_name_operator"] | undefined,
	region?: ModelTypes["HomepageSetting_region_operator"] | undefined,
	updatedAt?: ModelTypes["HomepageSetting_updatedAt_operator"] | undefined,
	createdAt?: ModelTypes["HomepageSetting_createdAt_operator"] | undefined,
	id?: ModelTypes["HomepageSetting_id_operator"] | undefined,
	AND?: Array<ModelTypes["HomepageSetting_where_and"] | undefined> | undefined,
	OR?: Array<ModelTypes["HomepageSetting_where_or"] | undefined> | undefined
};
	["HomepageSetting_name_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined
};
	["HomepageSetting_region_operator"]: {
	equals?: ModelTypes["HomepageSetting_region_Input"] | undefined,
	not_equals?: ModelTypes["HomepageSetting_region_Input"] | undefined,
	in?: Array<ModelTypes["HomepageSetting_region_Input"] | undefined> | undefined,
	not_in?: Array<ModelTypes["HomepageSetting_region_Input"] | undefined> | undefined,
	all?: Array<ModelTypes["HomepageSetting_region_Input"] | undefined> | undefined,
	exists?: boolean | undefined
};
	["HomepageSetting_region_Input"]:HomepageSetting_region_Input;
	["HomepageSetting_updatedAt_operator"]: {
	equals?: ModelTypes["DateTime"] | undefined,
	not_equals?: ModelTypes["DateTime"] | undefined,
	greater_than_equal?: ModelTypes["DateTime"] | undefined,
	greater_than?: ModelTypes["DateTime"] | undefined,
	less_than_equal?: ModelTypes["DateTime"] | undefined,
	less_than?: ModelTypes["DateTime"] | undefined,
	like?: ModelTypes["DateTime"] | undefined,
	exists?: boolean | undefined
};
	["HomepageSetting_createdAt_operator"]: {
	equals?: ModelTypes["DateTime"] | undefined,
	not_equals?: ModelTypes["DateTime"] | undefined,
	greater_than_equal?: ModelTypes["DateTime"] | undefined,
	greater_than?: ModelTypes["DateTime"] | undefined,
	less_than_equal?: ModelTypes["DateTime"] | undefined,
	less_than?: ModelTypes["DateTime"] | undefined,
	like?: ModelTypes["DateTime"] | undefined,
	exists?: boolean | undefined
};
	["HomepageSetting_id_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["HomepageSetting_where_and"]: {
	name?: ModelTypes["HomepageSetting_name_operator"] | undefined,
	region?: ModelTypes["HomepageSetting_region_operator"] | undefined,
	updatedAt?: ModelTypes["HomepageSetting_updatedAt_operator"] | undefined,
	createdAt?: ModelTypes["HomepageSetting_createdAt_operator"] | undefined,
	id?: ModelTypes["HomepageSetting_id_operator"] | undefined,
	AND?: Array<ModelTypes["HomepageSetting_where_and"] | undefined> | undefined,
	OR?: Array<ModelTypes["HomepageSetting_where_or"] | undefined> | undefined
};
	["HomepageSetting_where_or"]: {
	name?: ModelTypes["HomepageSetting_name_operator"] | undefined,
	region?: ModelTypes["HomepageSetting_region_operator"] | undefined,
	updatedAt?: ModelTypes["HomepageSetting_updatedAt_operator"] | undefined,
	createdAt?: ModelTypes["HomepageSetting_createdAt_operator"] | undefined,
	id?: ModelTypes["HomepageSetting_id_operator"] | undefined,
	AND?: Array<ModelTypes["HomepageSetting_where_and"] | undefined> | undefined,
	OR?: Array<ModelTypes["HomepageSetting_where_or"] | undefined> | undefined
};
	["countHomepageSettings"]: {
		totalDocs?: number | undefined
};
	["homepageSettingDocAccess"]: {
		fields?: ModelTypes["HomepageSettingDocAccessFields"] | undefined,
	create?: ModelTypes["HomepageSettingCreateDocAccess"] | undefined,
	read?: ModelTypes["HomepageSettingReadDocAccess"] | undefined,
	update?: ModelTypes["HomepageSettingUpdateDocAccess"] | undefined,
	delete?: ModelTypes["HomepageSettingDeleteDocAccess"] | undefined
};
	["HomepageSettingDocAccessFields"]: {
		name?: ModelTypes["HomepageSettingDocAccessFields_name"] | undefined,
	region?: ModelTypes["HomepageSettingDocAccessFields_region"] | undefined,
	sections?: ModelTypes["HomepageSettingDocAccessFields_sections"] | undefined,
	updatedAt?: ModelTypes["HomepageSettingDocAccessFields_updatedAt"] | undefined,
	createdAt?: ModelTypes["HomepageSettingDocAccessFields_createdAt"] | undefined
};
	["HomepageSettingDocAccessFields_name"]: {
		create?: ModelTypes["HomepageSettingDocAccessFields_name_Create"] | undefined,
	read?: ModelTypes["HomepageSettingDocAccessFields_name_Read"] | undefined,
	update?: ModelTypes["HomepageSettingDocAccessFields_name_Update"] | undefined,
	delete?: ModelTypes["HomepageSettingDocAccessFields_name_Delete"] | undefined
};
	["HomepageSettingDocAccessFields_name_Create"]: {
		permission: boolean
};
	["HomepageSettingDocAccessFields_name_Read"]: {
		permission: boolean
};
	["HomepageSettingDocAccessFields_name_Update"]: {
		permission: boolean
};
	["HomepageSettingDocAccessFields_name_Delete"]: {
		permission: boolean
};
	["HomepageSettingDocAccessFields_region"]: {
		create?: ModelTypes["HomepageSettingDocAccessFields_region_Create"] | undefined,
	read?: ModelTypes["HomepageSettingDocAccessFields_region_Read"] | undefined,
	update?: ModelTypes["HomepageSettingDocAccessFields_region_Update"] | undefined,
	delete?: ModelTypes["HomepageSettingDocAccessFields_region_Delete"] | undefined
};
	["HomepageSettingDocAccessFields_region_Create"]: {
		permission: boolean
};
	["HomepageSettingDocAccessFields_region_Read"]: {
		permission: boolean
};
	["HomepageSettingDocAccessFields_region_Update"]: {
		permission: boolean
};
	["HomepageSettingDocAccessFields_region_Delete"]: {
		permission: boolean
};
	["HomepageSettingDocAccessFields_sections"]: {
		create?: ModelTypes["HomepageSettingDocAccessFields_sections_Create"] | undefined,
	read?: ModelTypes["HomepageSettingDocAccessFields_sections_Read"] | undefined,
	update?: ModelTypes["HomepageSettingDocAccessFields_sections_Update"] | undefined,
	delete?: ModelTypes["HomepageSettingDocAccessFields_sections_Delete"] | undefined
};
	["HomepageSettingDocAccessFields_sections_Create"]: {
		permission: boolean
};
	["HomepageSettingDocAccessFields_sections_Read"]: {
		permission: boolean
};
	["HomepageSettingDocAccessFields_sections_Update"]: {
		permission: boolean
};
	["HomepageSettingDocAccessFields_sections_Delete"]: {
		permission: boolean
};
	["HomepageSettingDocAccessFields_updatedAt"]: {
		create?: ModelTypes["HomepageSettingDocAccessFields_updatedAt_Create"] | undefined,
	read?: ModelTypes["HomepageSettingDocAccessFields_updatedAt_Read"] | undefined,
	update?: ModelTypes["HomepageSettingDocAccessFields_updatedAt_Update"] | undefined,
	delete?: ModelTypes["HomepageSettingDocAccessFields_updatedAt_Delete"] | undefined
};
	["HomepageSettingDocAccessFields_updatedAt_Create"]: {
		permission: boolean
};
	["HomepageSettingDocAccessFields_updatedAt_Read"]: {
		permission: boolean
};
	["HomepageSettingDocAccessFields_updatedAt_Update"]: {
		permission: boolean
};
	["HomepageSettingDocAccessFields_updatedAt_Delete"]: {
		permission: boolean
};
	["HomepageSettingDocAccessFields_createdAt"]: {
		create?: ModelTypes["HomepageSettingDocAccessFields_createdAt_Create"] | undefined,
	read?: ModelTypes["HomepageSettingDocAccessFields_createdAt_Read"] | undefined,
	update?: ModelTypes["HomepageSettingDocAccessFields_createdAt_Update"] | undefined,
	delete?: ModelTypes["HomepageSettingDocAccessFields_createdAt_Delete"] | undefined
};
	["HomepageSettingDocAccessFields_createdAt_Create"]: {
		permission: boolean
};
	["HomepageSettingDocAccessFields_createdAt_Read"]: {
		permission: boolean
};
	["HomepageSettingDocAccessFields_createdAt_Update"]: {
		permission: boolean
};
	["HomepageSettingDocAccessFields_createdAt_Delete"]: {
		permission: boolean
};
	["HomepageSettingCreateDocAccess"]: {
		permission: boolean,
	where?: ModelTypes["JSONObject"] | undefined
};
	["HomepageSettingReadDocAccess"]: {
		permission: boolean,
	where?: ModelTypes["JSONObject"] | undefined
};
	["HomepageSettingUpdateDocAccess"]: {
		permission: boolean,
	where?: ModelTypes["JSONObject"] | undefined
};
	["HomepageSettingDeleteDocAccess"]: {
		permission: boolean,
	where?: ModelTypes["JSONObject"] | undefined
};
	["BannerSection"]: {
		id?: string | undefined,
	name?: string | undefined,
	slug?: string | undefined,
	banners?: Array<ModelTypes["BannerSection_Banners"]> | undefined,
	updatedAt?: ModelTypes["DateTime"] | undefined,
	createdAt?: ModelTypes["DateTime"] | undefined
};
	["BannerSection_Banners"]: {
		name?: string | undefined,
	status?: string | undefined,
	desktop?: ModelTypes["Media"] | undefined,
	bannerStartDate?: ModelTypes["DateTime"] | undefined,
	bannerEndDate?: ModelTypes["DateTime"] | undefined,
	region?: ModelTypes["BannerSection_Banners_region"] | undefined,
	id?: string | undefined
};
	["BannerSection_Banners_Desktop_where"]: {
	alt?: ModelTypes["BannerSection_Banners_Desktop_alt_operator"] | undefined,
	updatedAt?: ModelTypes["BannerSection_Banners_Desktop_updatedAt_operator"] | undefined,
	createdAt?: ModelTypes["BannerSection_Banners_Desktop_createdAt_operator"] | undefined,
	url?: ModelTypes["BannerSection_Banners_Desktop_url_operator"] | undefined,
	filename?: ModelTypes["BannerSection_Banners_Desktop_filename_operator"] | undefined,
	mimeType?: ModelTypes["BannerSection_Banners_Desktop_mimeType_operator"] | undefined,
	filesize?: ModelTypes["BannerSection_Banners_Desktop_filesize_operator"] | undefined,
	width?: ModelTypes["BannerSection_Banners_Desktop_width_operator"] | undefined,
	height?: ModelTypes["BannerSection_Banners_Desktop_height_operator"] | undefined,
	focalX?: ModelTypes["BannerSection_Banners_Desktop_focalX_operator"] | undefined,
	focalY?: ModelTypes["BannerSection_Banners_Desktop_focalY_operator"] | undefined,
	sizes__small__url?: ModelTypes["BannerSection_Banners_Desktop_sizes__small__url_operator"] | undefined,
	sizes__small__width?: ModelTypes["BannerSection_Banners_Desktop_sizes__small__width_operator"] | undefined,
	sizes__small__height?: ModelTypes["BannerSection_Banners_Desktop_sizes__small__height_operator"] | undefined,
	sizes__small__mimeType?: ModelTypes["BannerSection_Banners_Desktop_sizes__small__mimeType_operator"] | undefined,
	sizes__small__filesize?: ModelTypes["BannerSection_Banners_Desktop_sizes__small__filesize_operator"] | undefined,
	sizes__small__filename?: ModelTypes["BannerSection_Banners_Desktop_sizes__small__filename_operator"] | undefined,
	sizes__medium__url?: ModelTypes["BannerSection_Banners_Desktop_sizes__medium__url_operator"] | undefined,
	sizes__medium__width?: ModelTypes["BannerSection_Banners_Desktop_sizes__medium__width_operator"] | undefined,
	sizes__medium__height?: ModelTypes["BannerSection_Banners_Desktop_sizes__medium__height_operator"] | undefined,
	sizes__medium__mimeType?: ModelTypes["BannerSection_Banners_Desktop_sizes__medium__mimeType_operator"] | undefined,
	sizes__medium__filesize?: ModelTypes["BannerSection_Banners_Desktop_sizes__medium__filesize_operator"] | undefined,
	sizes__medium__filename?: ModelTypes["BannerSection_Banners_Desktop_sizes__medium__filename_operator"] | undefined,
	sizes__large__url?: ModelTypes["BannerSection_Banners_Desktop_sizes__large__url_operator"] | undefined,
	sizes__large__width?: ModelTypes["BannerSection_Banners_Desktop_sizes__large__width_operator"] | undefined,
	sizes__large__height?: ModelTypes["BannerSection_Banners_Desktop_sizes__large__height_operator"] | undefined,
	sizes__large__mimeType?: ModelTypes["BannerSection_Banners_Desktop_sizes__large__mimeType_operator"] | undefined,
	sizes__large__filesize?: ModelTypes["BannerSection_Banners_Desktop_sizes__large__filesize_operator"] | undefined,
	sizes__large__filename?: ModelTypes["BannerSection_Banners_Desktop_sizes__large__filename_operator"] | undefined,
	sizes__small_jpeg__url?: ModelTypes["BannerSection_Banners_Desktop_sizes__small_jpeg__url_operator"] | undefined,
	sizes__small_jpeg__width?: ModelTypes["BannerSection_Banners_Desktop_sizes__small_jpeg__width_operator"] | undefined,
	sizes__small_jpeg__height?: ModelTypes["BannerSection_Banners_Desktop_sizes__small_jpeg__height_operator"] | undefined,
	sizes__small_jpeg__mimeType?: ModelTypes["BannerSection_Banners_Desktop_sizes__small_jpeg__mimeType_operator"] | undefined,
	sizes__small_jpeg__filesize?: ModelTypes["BannerSection_Banners_Desktop_sizes__small_jpeg__filesize_operator"] | undefined,
	sizes__small_jpeg__filename?: ModelTypes["BannerSection_Banners_Desktop_sizes__small_jpeg__filename_operator"] | undefined,
	sizes__medium_jpeg__url?: ModelTypes["BannerSection_Banners_Desktop_sizes__medium_jpeg__url_operator"] | undefined,
	sizes__medium_jpeg__width?: ModelTypes["BannerSection_Banners_Desktop_sizes__medium_jpeg__width_operator"] | undefined,
	sizes__medium_jpeg__height?: ModelTypes["BannerSection_Banners_Desktop_sizes__medium_jpeg__height_operator"] | undefined,
	sizes__medium_jpeg__mimeType?: ModelTypes["BannerSection_Banners_Desktop_sizes__medium_jpeg__mimeType_operator"] | undefined,
	sizes__medium_jpeg__filesize?: ModelTypes["BannerSection_Banners_Desktop_sizes__medium_jpeg__filesize_operator"] | undefined,
	sizes__medium_jpeg__filename?: ModelTypes["BannerSection_Banners_Desktop_sizes__medium_jpeg__filename_operator"] | undefined,
	sizes__large_jpeg__url?: ModelTypes["BannerSection_Banners_Desktop_sizes__large_jpeg__url_operator"] | undefined,
	sizes__large_jpeg__width?: ModelTypes["BannerSection_Banners_Desktop_sizes__large_jpeg__width_operator"] | undefined,
	sizes__large_jpeg__height?: ModelTypes["BannerSection_Banners_Desktop_sizes__large_jpeg__height_operator"] | undefined,
	sizes__large_jpeg__mimeType?: ModelTypes["BannerSection_Banners_Desktop_sizes__large_jpeg__mimeType_operator"] | undefined,
	sizes__large_jpeg__filesize?: ModelTypes["BannerSection_Banners_Desktop_sizes__large_jpeg__filesize_operator"] | undefined,
	sizes__large_jpeg__filename?: ModelTypes["BannerSection_Banners_Desktop_sizes__large_jpeg__filename_operator"] | undefined,
	id?: ModelTypes["BannerSection_Banners_Desktop_id_operator"] | undefined,
	AND?: Array<ModelTypes["BannerSection_Banners_Desktop_where_and"] | undefined> | undefined,
	OR?: Array<ModelTypes["BannerSection_Banners_Desktop_where_or"] | undefined> | undefined
};
	["BannerSection_Banners_Desktop_alt_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["BannerSection_Banners_Desktop_updatedAt_operator"]: {
	equals?: ModelTypes["DateTime"] | undefined,
	not_equals?: ModelTypes["DateTime"] | undefined,
	greater_than_equal?: ModelTypes["DateTime"] | undefined,
	greater_than?: ModelTypes["DateTime"] | undefined,
	less_than_equal?: ModelTypes["DateTime"] | undefined,
	less_than?: ModelTypes["DateTime"] | undefined,
	like?: ModelTypes["DateTime"] | undefined,
	exists?: boolean | undefined
};
	["BannerSection_Banners_Desktop_createdAt_operator"]: {
	equals?: ModelTypes["DateTime"] | undefined,
	not_equals?: ModelTypes["DateTime"] | undefined,
	greater_than_equal?: ModelTypes["DateTime"] | undefined,
	greater_than?: ModelTypes["DateTime"] | undefined,
	less_than_equal?: ModelTypes["DateTime"] | undefined,
	less_than?: ModelTypes["DateTime"] | undefined,
	like?: ModelTypes["DateTime"] | undefined,
	exists?: boolean | undefined
};
	["BannerSection_Banners_Desktop_url_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["BannerSection_Banners_Desktop_filename_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["BannerSection_Banners_Desktop_mimeType_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["BannerSection_Banners_Desktop_filesize_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["BannerSection_Banners_Desktop_width_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["BannerSection_Banners_Desktop_height_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["BannerSection_Banners_Desktop_focalX_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["BannerSection_Banners_Desktop_focalY_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["BannerSection_Banners_Desktop_sizes__small__url_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["BannerSection_Banners_Desktop_sizes__small__width_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["BannerSection_Banners_Desktop_sizes__small__height_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["BannerSection_Banners_Desktop_sizes__small__mimeType_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["BannerSection_Banners_Desktop_sizes__small__filesize_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["BannerSection_Banners_Desktop_sizes__small__filename_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["BannerSection_Banners_Desktop_sizes__medium__url_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["BannerSection_Banners_Desktop_sizes__medium__width_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["BannerSection_Banners_Desktop_sizes__medium__height_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["BannerSection_Banners_Desktop_sizes__medium__mimeType_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["BannerSection_Banners_Desktop_sizes__medium__filesize_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["BannerSection_Banners_Desktop_sizes__medium__filename_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["BannerSection_Banners_Desktop_sizes__large__url_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["BannerSection_Banners_Desktop_sizes__large__width_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["BannerSection_Banners_Desktop_sizes__large__height_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["BannerSection_Banners_Desktop_sizes__large__mimeType_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["BannerSection_Banners_Desktop_sizes__large__filesize_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["BannerSection_Banners_Desktop_sizes__large__filename_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["BannerSection_Banners_Desktop_sizes__small_jpeg__url_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["BannerSection_Banners_Desktop_sizes__small_jpeg__width_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["BannerSection_Banners_Desktop_sizes__small_jpeg__height_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["BannerSection_Banners_Desktop_sizes__small_jpeg__mimeType_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["BannerSection_Banners_Desktop_sizes__small_jpeg__filesize_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["BannerSection_Banners_Desktop_sizes__small_jpeg__filename_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["BannerSection_Banners_Desktop_sizes__medium_jpeg__url_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["BannerSection_Banners_Desktop_sizes__medium_jpeg__width_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["BannerSection_Banners_Desktop_sizes__medium_jpeg__height_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["BannerSection_Banners_Desktop_sizes__medium_jpeg__mimeType_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["BannerSection_Banners_Desktop_sizes__medium_jpeg__filesize_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["BannerSection_Banners_Desktop_sizes__medium_jpeg__filename_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["BannerSection_Banners_Desktop_sizes__large_jpeg__url_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["BannerSection_Banners_Desktop_sizes__large_jpeg__width_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["BannerSection_Banners_Desktop_sizes__large_jpeg__height_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["BannerSection_Banners_Desktop_sizes__large_jpeg__mimeType_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["BannerSection_Banners_Desktop_sizes__large_jpeg__filesize_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["BannerSection_Banners_Desktop_sizes__large_jpeg__filename_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["BannerSection_Banners_Desktop_id_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["BannerSection_Banners_Desktop_where_and"]: {
	alt?: ModelTypes["BannerSection_Banners_Desktop_alt_operator"] | undefined,
	updatedAt?: ModelTypes["BannerSection_Banners_Desktop_updatedAt_operator"] | undefined,
	createdAt?: ModelTypes["BannerSection_Banners_Desktop_createdAt_operator"] | undefined,
	url?: ModelTypes["BannerSection_Banners_Desktop_url_operator"] | undefined,
	filename?: ModelTypes["BannerSection_Banners_Desktop_filename_operator"] | undefined,
	mimeType?: ModelTypes["BannerSection_Banners_Desktop_mimeType_operator"] | undefined,
	filesize?: ModelTypes["BannerSection_Banners_Desktop_filesize_operator"] | undefined,
	width?: ModelTypes["BannerSection_Banners_Desktop_width_operator"] | undefined,
	height?: ModelTypes["BannerSection_Banners_Desktop_height_operator"] | undefined,
	focalX?: ModelTypes["BannerSection_Banners_Desktop_focalX_operator"] | undefined,
	focalY?: ModelTypes["BannerSection_Banners_Desktop_focalY_operator"] | undefined,
	sizes__small__url?: ModelTypes["BannerSection_Banners_Desktop_sizes__small__url_operator"] | undefined,
	sizes__small__width?: ModelTypes["BannerSection_Banners_Desktop_sizes__small__width_operator"] | undefined,
	sizes__small__height?: ModelTypes["BannerSection_Banners_Desktop_sizes__small__height_operator"] | undefined,
	sizes__small__mimeType?: ModelTypes["BannerSection_Banners_Desktop_sizes__small__mimeType_operator"] | undefined,
	sizes__small__filesize?: ModelTypes["BannerSection_Banners_Desktop_sizes__small__filesize_operator"] | undefined,
	sizes__small__filename?: ModelTypes["BannerSection_Banners_Desktop_sizes__small__filename_operator"] | undefined,
	sizes__medium__url?: ModelTypes["BannerSection_Banners_Desktop_sizes__medium__url_operator"] | undefined,
	sizes__medium__width?: ModelTypes["BannerSection_Banners_Desktop_sizes__medium__width_operator"] | undefined,
	sizes__medium__height?: ModelTypes["BannerSection_Banners_Desktop_sizes__medium__height_operator"] | undefined,
	sizes__medium__mimeType?: ModelTypes["BannerSection_Banners_Desktop_sizes__medium__mimeType_operator"] | undefined,
	sizes__medium__filesize?: ModelTypes["BannerSection_Banners_Desktop_sizes__medium__filesize_operator"] | undefined,
	sizes__medium__filename?: ModelTypes["BannerSection_Banners_Desktop_sizes__medium__filename_operator"] | undefined,
	sizes__large__url?: ModelTypes["BannerSection_Banners_Desktop_sizes__large__url_operator"] | undefined,
	sizes__large__width?: ModelTypes["BannerSection_Banners_Desktop_sizes__large__width_operator"] | undefined,
	sizes__large__height?: ModelTypes["BannerSection_Banners_Desktop_sizes__large__height_operator"] | undefined,
	sizes__large__mimeType?: ModelTypes["BannerSection_Banners_Desktop_sizes__large__mimeType_operator"] | undefined,
	sizes__large__filesize?: ModelTypes["BannerSection_Banners_Desktop_sizes__large__filesize_operator"] | undefined,
	sizes__large__filename?: ModelTypes["BannerSection_Banners_Desktop_sizes__large__filename_operator"] | undefined,
	sizes__small_jpeg__url?: ModelTypes["BannerSection_Banners_Desktop_sizes__small_jpeg__url_operator"] | undefined,
	sizes__small_jpeg__width?: ModelTypes["BannerSection_Banners_Desktop_sizes__small_jpeg__width_operator"] | undefined,
	sizes__small_jpeg__height?: ModelTypes["BannerSection_Banners_Desktop_sizes__small_jpeg__height_operator"] | undefined,
	sizes__small_jpeg__mimeType?: ModelTypes["BannerSection_Banners_Desktop_sizes__small_jpeg__mimeType_operator"] | undefined,
	sizes__small_jpeg__filesize?: ModelTypes["BannerSection_Banners_Desktop_sizes__small_jpeg__filesize_operator"] | undefined,
	sizes__small_jpeg__filename?: ModelTypes["BannerSection_Banners_Desktop_sizes__small_jpeg__filename_operator"] | undefined,
	sizes__medium_jpeg__url?: ModelTypes["BannerSection_Banners_Desktop_sizes__medium_jpeg__url_operator"] | undefined,
	sizes__medium_jpeg__width?: ModelTypes["BannerSection_Banners_Desktop_sizes__medium_jpeg__width_operator"] | undefined,
	sizes__medium_jpeg__height?: ModelTypes["BannerSection_Banners_Desktop_sizes__medium_jpeg__height_operator"] | undefined,
	sizes__medium_jpeg__mimeType?: ModelTypes["BannerSection_Banners_Desktop_sizes__medium_jpeg__mimeType_operator"] | undefined,
	sizes__medium_jpeg__filesize?: ModelTypes["BannerSection_Banners_Desktop_sizes__medium_jpeg__filesize_operator"] | undefined,
	sizes__medium_jpeg__filename?: ModelTypes["BannerSection_Banners_Desktop_sizes__medium_jpeg__filename_operator"] | undefined,
	sizes__large_jpeg__url?: ModelTypes["BannerSection_Banners_Desktop_sizes__large_jpeg__url_operator"] | undefined,
	sizes__large_jpeg__width?: ModelTypes["BannerSection_Banners_Desktop_sizes__large_jpeg__width_operator"] | undefined,
	sizes__large_jpeg__height?: ModelTypes["BannerSection_Banners_Desktop_sizes__large_jpeg__height_operator"] | undefined,
	sizes__large_jpeg__mimeType?: ModelTypes["BannerSection_Banners_Desktop_sizes__large_jpeg__mimeType_operator"] | undefined,
	sizes__large_jpeg__filesize?: ModelTypes["BannerSection_Banners_Desktop_sizes__large_jpeg__filesize_operator"] | undefined,
	sizes__large_jpeg__filename?: ModelTypes["BannerSection_Banners_Desktop_sizes__large_jpeg__filename_operator"] | undefined,
	id?: ModelTypes["BannerSection_Banners_Desktop_id_operator"] | undefined,
	AND?: Array<ModelTypes["BannerSection_Banners_Desktop_where_and"] | undefined> | undefined,
	OR?: Array<ModelTypes["BannerSection_Banners_Desktop_where_or"] | undefined> | undefined
};
	["BannerSection_Banners_Desktop_where_or"]: {
	alt?: ModelTypes["BannerSection_Banners_Desktop_alt_operator"] | undefined,
	updatedAt?: ModelTypes["BannerSection_Banners_Desktop_updatedAt_operator"] | undefined,
	createdAt?: ModelTypes["BannerSection_Banners_Desktop_createdAt_operator"] | undefined,
	url?: ModelTypes["BannerSection_Banners_Desktop_url_operator"] | undefined,
	filename?: ModelTypes["BannerSection_Banners_Desktop_filename_operator"] | undefined,
	mimeType?: ModelTypes["BannerSection_Banners_Desktop_mimeType_operator"] | undefined,
	filesize?: ModelTypes["BannerSection_Banners_Desktop_filesize_operator"] | undefined,
	width?: ModelTypes["BannerSection_Banners_Desktop_width_operator"] | undefined,
	height?: ModelTypes["BannerSection_Banners_Desktop_height_operator"] | undefined,
	focalX?: ModelTypes["BannerSection_Banners_Desktop_focalX_operator"] | undefined,
	focalY?: ModelTypes["BannerSection_Banners_Desktop_focalY_operator"] | undefined,
	sizes__small__url?: ModelTypes["BannerSection_Banners_Desktop_sizes__small__url_operator"] | undefined,
	sizes__small__width?: ModelTypes["BannerSection_Banners_Desktop_sizes__small__width_operator"] | undefined,
	sizes__small__height?: ModelTypes["BannerSection_Banners_Desktop_sizes__small__height_operator"] | undefined,
	sizes__small__mimeType?: ModelTypes["BannerSection_Banners_Desktop_sizes__small__mimeType_operator"] | undefined,
	sizes__small__filesize?: ModelTypes["BannerSection_Banners_Desktop_sizes__small__filesize_operator"] | undefined,
	sizes__small__filename?: ModelTypes["BannerSection_Banners_Desktop_sizes__small__filename_operator"] | undefined,
	sizes__medium__url?: ModelTypes["BannerSection_Banners_Desktop_sizes__medium__url_operator"] | undefined,
	sizes__medium__width?: ModelTypes["BannerSection_Banners_Desktop_sizes__medium__width_operator"] | undefined,
	sizes__medium__height?: ModelTypes["BannerSection_Banners_Desktop_sizes__medium__height_operator"] | undefined,
	sizes__medium__mimeType?: ModelTypes["BannerSection_Banners_Desktop_sizes__medium__mimeType_operator"] | undefined,
	sizes__medium__filesize?: ModelTypes["BannerSection_Banners_Desktop_sizes__medium__filesize_operator"] | undefined,
	sizes__medium__filename?: ModelTypes["BannerSection_Banners_Desktop_sizes__medium__filename_operator"] | undefined,
	sizes__large__url?: ModelTypes["BannerSection_Banners_Desktop_sizes__large__url_operator"] | undefined,
	sizes__large__width?: ModelTypes["BannerSection_Banners_Desktop_sizes__large__width_operator"] | undefined,
	sizes__large__height?: ModelTypes["BannerSection_Banners_Desktop_sizes__large__height_operator"] | undefined,
	sizes__large__mimeType?: ModelTypes["BannerSection_Banners_Desktop_sizes__large__mimeType_operator"] | undefined,
	sizes__large__filesize?: ModelTypes["BannerSection_Banners_Desktop_sizes__large__filesize_operator"] | undefined,
	sizes__large__filename?: ModelTypes["BannerSection_Banners_Desktop_sizes__large__filename_operator"] | undefined,
	sizes__small_jpeg__url?: ModelTypes["BannerSection_Banners_Desktop_sizes__small_jpeg__url_operator"] | undefined,
	sizes__small_jpeg__width?: ModelTypes["BannerSection_Banners_Desktop_sizes__small_jpeg__width_operator"] | undefined,
	sizes__small_jpeg__height?: ModelTypes["BannerSection_Banners_Desktop_sizes__small_jpeg__height_operator"] | undefined,
	sizes__small_jpeg__mimeType?: ModelTypes["BannerSection_Banners_Desktop_sizes__small_jpeg__mimeType_operator"] | undefined,
	sizes__small_jpeg__filesize?: ModelTypes["BannerSection_Banners_Desktop_sizes__small_jpeg__filesize_operator"] | undefined,
	sizes__small_jpeg__filename?: ModelTypes["BannerSection_Banners_Desktop_sizes__small_jpeg__filename_operator"] | undefined,
	sizes__medium_jpeg__url?: ModelTypes["BannerSection_Banners_Desktop_sizes__medium_jpeg__url_operator"] | undefined,
	sizes__medium_jpeg__width?: ModelTypes["BannerSection_Banners_Desktop_sizes__medium_jpeg__width_operator"] | undefined,
	sizes__medium_jpeg__height?: ModelTypes["BannerSection_Banners_Desktop_sizes__medium_jpeg__height_operator"] | undefined,
	sizes__medium_jpeg__mimeType?: ModelTypes["BannerSection_Banners_Desktop_sizes__medium_jpeg__mimeType_operator"] | undefined,
	sizes__medium_jpeg__filesize?: ModelTypes["BannerSection_Banners_Desktop_sizes__medium_jpeg__filesize_operator"] | undefined,
	sizes__medium_jpeg__filename?: ModelTypes["BannerSection_Banners_Desktop_sizes__medium_jpeg__filename_operator"] | undefined,
	sizes__large_jpeg__url?: ModelTypes["BannerSection_Banners_Desktop_sizes__large_jpeg__url_operator"] | undefined,
	sizes__large_jpeg__width?: ModelTypes["BannerSection_Banners_Desktop_sizes__large_jpeg__width_operator"] | undefined,
	sizes__large_jpeg__height?: ModelTypes["BannerSection_Banners_Desktop_sizes__large_jpeg__height_operator"] | undefined,
	sizes__large_jpeg__mimeType?: ModelTypes["BannerSection_Banners_Desktop_sizes__large_jpeg__mimeType_operator"] | undefined,
	sizes__large_jpeg__filesize?: ModelTypes["BannerSection_Banners_Desktop_sizes__large_jpeg__filesize_operator"] | undefined,
	sizes__large_jpeg__filename?: ModelTypes["BannerSection_Banners_Desktop_sizes__large_jpeg__filename_operator"] | undefined,
	id?: ModelTypes["BannerSection_Banners_Desktop_id_operator"] | undefined,
	AND?: Array<ModelTypes["BannerSection_Banners_Desktop_where_and"] | undefined> | undefined,
	OR?: Array<ModelTypes["BannerSection_Banners_Desktop_where_or"] | undefined> | undefined
};
	["BannerSection_Banners_region"]:BannerSection_Banners_region;
	["BannerSections"]: {
		docs?: Array<ModelTypes["BannerSection"] | undefined> | undefined,
	hasNextPage?: boolean | undefined,
	hasPrevPage?: boolean | undefined,
	limit?: number | undefined,
	nextPage?: number | undefined,
	offset?: number | undefined,
	page?: number | undefined,
	pagingCounter?: number | undefined,
	prevPage?: number | undefined,
	totalDocs?: number | undefined,
	totalPages?: number | undefined
};
	["BannerSection_where"]: {
	name?: ModelTypes["BannerSection_name_operator"] | undefined,
	slug?: ModelTypes["BannerSection_slug_operator"] | undefined,
	banners__name?: ModelTypes["BannerSection_banners__name_operator"] | undefined,
	banners__status?: ModelTypes["BannerSection_banners__status_operator"] | undefined,
	banners__desktop?: ModelTypes["BannerSection_banners__desktop_operator"] | undefined,
	banners__bannerStartDate?: ModelTypes["BannerSection_banners__bannerStartDate_operator"] | undefined,
	banners__bannerEndDate?: ModelTypes["BannerSection_banners__bannerEndDate_operator"] | undefined,
	banners__region?: ModelTypes["BannerSection_banners__region_operator"] | undefined,
	banners__id?: ModelTypes["BannerSection_banners__id_operator"] | undefined,
	updatedAt?: ModelTypes["BannerSection_updatedAt_operator"] | undefined,
	createdAt?: ModelTypes["BannerSection_createdAt_operator"] | undefined,
	id?: ModelTypes["BannerSection_id_operator"] | undefined,
	AND?: Array<ModelTypes["BannerSection_where_and"] | undefined> | undefined,
	OR?: Array<ModelTypes["BannerSection_where_or"] | undefined> | undefined
};
	["BannerSection_name_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["BannerSection_slug_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["BannerSection_banners__name_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["BannerSection_banners__status_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["BannerSection_banners__desktop_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined
};
	["BannerSection_banners__bannerStartDate_operator"]: {
	equals?: ModelTypes["DateTime"] | undefined,
	not_equals?: ModelTypes["DateTime"] | undefined,
	greater_than_equal?: ModelTypes["DateTime"] | undefined,
	greater_than?: ModelTypes["DateTime"] | undefined,
	less_than_equal?: ModelTypes["DateTime"] | undefined,
	less_than?: ModelTypes["DateTime"] | undefined,
	like?: ModelTypes["DateTime"] | undefined,
	exists?: boolean | undefined
};
	["BannerSection_banners__bannerEndDate_operator"]: {
	equals?: ModelTypes["DateTime"] | undefined,
	not_equals?: ModelTypes["DateTime"] | undefined,
	greater_than_equal?: ModelTypes["DateTime"] | undefined,
	greater_than?: ModelTypes["DateTime"] | undefined,
	less_than_equal?: ModelTypes["DateTime"] | undefined,
	less_than?: ModelTypes["DateTime"] | undefined,
	like?: ModelTypes["DateTime"] | undefined,
	exists?: boolean | undefined
};
	["BannerSection_banners__region_operator"]: {
	equals?: ModelTypes["BannerSection_banners__region_Input"] | undefined,
	not_equals?: ModelTypes["BannerSection_banners__region_Input"] | undefined,
	in?: Array<ModelTypes["BannerSection_banners__region_Input"] | undefined> | undefined,
	not_in?: Array<ModelTypes["BannerSection_banners__region_Input"] | undefined> | undefined,
	all?: Array<ModelTypes["BannerSection_banners__region_Input"] | undefined> | undefined,
	exists?: boolean | undefined
};
	["BannerSection_banners__region_Input"]:BannerSection_banners__region_Input;
	["BannerSection_banners__id_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["BannerSection_updatedAt_operator"]: {
	equals?: ModelTypes["DateTime"] | undefined,
	not_equals?: ModelTypes["DateTime"] | undefined,
	greater_than_equal?: ModelTypes["DateTime"] | undefined,
	greater_than?: ModelTypes["DateTime"] | undefined,
	less_than_equal?: ModelTypes["DateTime"] | undefined,
	less_than?: ModelTypes["DateTime"] | undefined,
	like?: ModelTypes["DateTime"] | undefined,
	exists?: boolean | undefined
};
	["BannerSection_createdAt_operator"]: {
	equals?: ModelTypes["DateTime"] | undefined,
	not_equals?: ModelTypes["DateTime"] | undefined,
	greater_than_equal?: ModelTypes["DateTime"] | undefined,
	greater_than?: ModelTypes["DateTime"] | undefined,
	less_than_equal?: ModelTypes["DateTime"] | undefined,
	less_than?: ModelTypes["DateTime"] | undefined,
	like?: ModelTypes["DateTime"] | undefined,
	exists?: boolean | undefined
};
	["BannerSection_id_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["BannerSection_where_and"]: {
	name?: ModelTypes["BannerSection_name_operator"] | undefined,
	slug?: ModelTypes["BannerSection_slug_operator"] | undefined,
	banners__name?: ModelTypes["BannerSection_banners__name_operator"] | undefined,
	banners__status?: ModelTypes["BannerSection_banners__status_operator"] | undefined,
	banners__desktop?: ModelTypes["BannerSection_banners__desktop_operator"] | undefined,
	banners__bannerStartDate?: ModelTypes["BannerSection_banners__bannerStartDate_operator"] | undefined,
	banners__bannerEndDate?: ModelTypes["BannerSection_banners__bannerEndDate_operator"] | undefined,
	banners__region?: ModelTypes["BannerSection_banners__region_operator"] | undefined,
	banners__id?: ModelTypes["BannerSection_banners__id_operator"] | undefined,
	updatedAt?: ModelTypes["BannerSection_updatedAt_operator"] | undefined,
	createdAt?: ModelTypes["BannerSection_createdAt_operator"] | undefined,
	id?: ModelTypes["BannerSection_id_operator"] | undefined,
	AND?: Array<ModelTypes["BannerSection_where_and"] | undefined> | undefined,
	OR?: Array<ModelTypes["BannerSection_where_or"] | undefined> | undefined
};
	["BannerSection_where_or"]: {
	name?: ModelTypes["BannerSection_name_operator"] | undefined,
	slug?: ModelTypes["BannerSection_slug_operator"] | undefined,
	banners__name?: ModelTypes["BannerSection_banners__name_operator"] | undefined,
	banners__status?: ModelTypes["BannerSection_banners__status_operator"] | undefined,
	banners__desktop?: ModelTypes["BannerSection_banners__desktop_operator"] | undefined,
	banners__bannerStartDate?: ModelTypes["BannerSection_banners__bannerStartDate_operator"] | undefined,
	banners__bannerEndDate?: ModelTypes["BannerSection_banners__bannerEndDate_operator"] | undefined,
	banners__region?: ModelTypes["BannerSection_banners__region_operator"] | undefined,
	banners__id?: ModelTypes["BannerSection_banners__id_operator"] | undefined,
	updatedAt?: ModelTypes["BannerSection_updatedAt_operator"] | undefined,
	createdAt?: ModelTypes["BannerSection_createdAt_operator"] | undefined,
	id?: ModelTypes["BannerSection_id_operator"] | undefined,
	AND?: Array<ModelTypes["BannerSection_where_and"] | undefined> | undefined,
	OR?: Array<ModelTypes["BannerSection_where_or"] | undefined> | undefined
};
	["countBannerSections"]: {
		totalDocs?: number | undefined
};
	["banner_sectionDocAccess"]: {
		fields?: ModelTypes["BannerSectionDocAccessFields"] | undefined,
	create?: ModelTypes["BannerSectionCreateDocAccess"] | undefined,
	read?: ModelTypes["BannerSectionReadDocAccess"] | undefined,
	update?: ModelTypes["BannerSectionUpdateDocAccess"] | undefined,
	delete?: ModelTypes["BannerSectionDeleteDocAccess"] | undefined
};
	["BannerSectionDocAccessFields"]: {
		name?: ModelTypes["BannerSectionDocAccessFields_name"] | undefined,
	slug?: ModelTypes["BannerSectionDocAccessFields_slug"] | undefined,
	banners?: ModelTypes["BannerSectionDocAccessFields_banners"] | undefined,
	updatedAt?: ModelTypes["BannerSectionDocAccessFields_updatedAt"] | undefined,
	createdAt?: ModelTypes["BannerSectionDocAccessFields_createdAt"] | undefined
};
	["BannerSectionDocAccessFields_name"]: {
		create?: ModelTypes["BannerSectionDocAccessFields_name_Create"] | undefined,
	read?: ModelTypes["BannerSectionDocAccessFields_name_Read"] | undefined,
	update?: ModelTypes["BannerSectionDocAccessFields_name_Update"] | undefined,
	delete?: ModelTypes["BannerSectionDocAccessFields_name_Delete"] | undefined
};
	["BannerSectionDocAccessFields_name_Create"]: {
		permission: boolean
};
	["BannerSectionDocAccessFields_name_Read"]: {
		permission: boolean
};
	["BannerSectionDocAccessFields_name_Update"]: {
		permission: boolean
};
	["BannerSectionDocAccessFields_name_Delete"]: {
		permission: boolean
};
	["BannerSectionDocAccessFields_slug"]: {
		create?: ModelTypes["BannerSectionDocAccessFields_slug_Create"] | undefined,
	read?: ModelTypes["BannerSectionDocAccessFields_slug_Read"] | undefined,
	update?: ModelTypes["BannerSectionDocAccessFields_slug_Update"] | undefined,
	delete?: ModelTypes["BannerSectionDocAccessFields_slug_Delete"] | undefined
};
	["BannerSectionDocAccessFields_slug_Create"]: {
		permission: boolean
};
	["BannerSectionDocAccessFields_slug_Read"]: {
		permission: boolean
};
	["BannerSectionDocAccessFields_slug_Update"]: {
		permission: boolean
};
	["BannerSectionDocAccessFields_slug_Delete"]: {
		permission: boolean
};
	["BannerSectionDocAccessFields_banners"]: {
		create?: ModelTypes["BannerSectionDocAccessFields_banners_Create"] | undefined,
	read?: ModelTypes["BannerSectionDocAccessFields_banners_Read"] | undefined,
	update?: ModelTypes["BannerSectionDocAccessFields_banners_Update"] | undefined,
	delete?: ModelTypes["BannerSectionDocAccessFields_banners_Delete"] | undefined,
	fields?: ModelTypes["BannerSectionDocAccessFields_banners_Fields"] | undefined
};
	["BannerSectionDocAccessFields_banners_Create"]: {
		permission: boolean
};
	["BannerSectionDocAccessFields_banners_Read"]: {
		permission: boolean
};
	["BannerSectionDocAccessFields_banners_Update"]: {
		permission: boolean
};
	["BannerSectionDocAccessFields_banners_Delete"]: {
		permission: boolean
};
	["BannerSectionDocAccessFields_banners_Fields"]: {
		name?: ModelTypes["BannerSectionDocAccessFields_banners_name"] | undefined,
	status?: ModelTypes["BannerSectionDocAccessFields_banners_status"] | undefined,
	desktop?: ModelTypes["BannerSectionDocAccessFields_banners_desktop"] | undefined,
	bannerStartDate?: ModelTypes["BannerSectionDocAccessFields_banners_bannerStartDate"] | undefined,
	bannerEndDate?: ModelTypes["BannerSectionDocAccessFields_banners_bannerEndDate"] | undefined,
	region?: ModelTypes["BannerSectionDocAccessFields_banners_region"] | undefined,
	id?: ModelTypes["BannerSectionDocAccessFields_banners_id"] | undefined
};
	["BannerSectionDocAccessFields_banners_name"]: {
		create?: ModelTypes["BannerSectionDocAccessFields_banners_name_Create"] | undefined,
	read?: ModelTypes["BannerSectionDocAccessFields_banners_name_Read"] | undefined,
	update?: ModelTypes["BannerSectionDocAccessFields_banners_name_Update"] | undefined,
	delete?: ModelTypes["BannerSectionDocAccessFields_banners_name_Delete"] | undefined
};
	["BannerSectionDocAccessFields_banners_name_Create"]: {
		permission: boolean
};
	["BannerSectionDocAccessFields_banners_name_Read"]: {
		permission: boolean
};
	["BannerSectionDocAccessFields_banners_name_Update"]: {
		permission: boolean
};
	["BannerSectionDocAccessFields_banners_name_Delete"]: {
		permission: boolean
};
	["BannerSectionDocAccessFields_banners_status"]: {
		create?: ModelTypes["BannerSectionDocAccessFields_banners_status_Create"] | undefined,
	read?: ModelTypes["BannerSectionDocAccessFields_banners_status_Read"] | undefined,
	update?: ModelTypes["BannerSectionDocAccessFields_banners_status_Update"] | undefined,
	delete?: ModelTypes["BannerSectionDocAccessFields_banners_status_Delete"] | undefined
};
	["BannerSectionDocAccessFields_banners_status_Create"]: {
		permission: boolean
};
	["BannerSectionDocAccessFields_banners_status_Read"]: {
		permission: boolean
};
	["BannerSectionDocAccessFields_banners_status_Update"]: {
		permission: boolean
};
	["BannerSectionDocAccessFields_banners_status_Delete"]: {
		permission: boolean
};
	["BannerSectionDocAccessFields_banners_desktop"]: {
		create?: ModelTypes["BannerSectionDocAccessFields_banners_desktop_Create"] | undefined,
	read?: ModelTypes["BannerSectionDocAccessFields_banners_desktop_Read"] | undefined,
	update?: ModelTypes["BannerSectionDocAccessFields_banners_desktop_Update"] | undefined,
	delete?: ModelTypes["BannerSectionDocAccessFields_banners_desktop_Delete"] | undefined
};
	["BannerSectionDocAccessFields_banners_desktop_Create"]: {
		permission: boolean
};
	["BannerSectionDocAccessFields_banners_desktop_Read"]: {
		permission: boolean
};
	["BannerSectionDocAccessFields_banners_desktop_Update"]: {
		permission: boolean
};
	["BannerSectionDocAccessFields_banners_desktop_Delete"]: {
		permission: boolean
};
	["BannerSectionDocAccessFields_banners_bannerStartDate"]: {
		create?: ModelTypes["BannerSectionDocAccessFields_banners_bannerStartDate_Create"] | undefined,
	read?: ModelTypes["BannerSectionDocAccessFields_banners_bannerStartDate_Read"] | undefined,
	update?: ModelTypes["BannerSectionDocAccessFields_banners_bannerStartDate_Update"] | undefined,
	delete?: ModelTypes["BannerSectionDocAccessFields_banners_bannerStartDate_Delete"] | undefined
};
	["BannerSectionDocAccessFields_banners_bannerStartDate_Create"]: {
		permission: boolean
};
	["BannerSectionDocAccessFields_banners_bannerStartDate_Read"]: {
		permission: boolean
};
	["BannerSectionDocAccessFields_banners_bannerStartDate_Update"]: {
		permission: boolean
};
	["BannerSectionDocAccessFields_banners_bannerStartDate_Delete"]: {
		permission: boolean
};
	["BannerSectionDocAccessFields_banners_bannerEndDate"]: {
		create?: ModelTypes["BannerSectionDocAccessFields_banners_bannerEndDate_Create"] | undefined,
	read?: ModelTypes["BannerSectionDocAccessFields_banners_bannerEndDate_Read"] | undefined,
	update?: ModelTypes["BannerSectionDocAccessFields_banners_bannerEndDate_Update"] | undefined,
	delete?: ModelTypes["BannerSectionDocAccessFields_banners_bannerEndDate_Delete"] | undefined
};
	["BannerSectionDocAccessFields_banners_bannerEndDate_Create"]: {
		permission: boolean
};
	["BannerSectionDocAccessFields_banners_bannerEndDate_Read"]: {
		permission: boolean
};
	["BannerSectionDocAccessFields_banners_bannerEndDate_Update"]: {
		permission: boolean
};
	["BannerSectionDocAccessFields_banners_bannerEndDate_Delete"]: {
		permission: boolean
};
	["BannerSectionDocAccessFields_banners_region"]: {
		create?: ModelTypes["BannerSectionDocAccessFields_banners_region_Create"] | undefined,
	read?: ModelTypes["BannerSectionDocAccessFields_banners_region_Read"] | undefined,
	update?: ModelTypes["BannerSectionDocAccessFields_banners_region_Update"] | undefined,
	delete?: ModelTypes["BannerSectionDocAccessFields_banners_region_Delete"] | undefined
};
	["BannerSectionDocAccessFields_banners_region_Create"]: {
		permission: boolean
};
	["BannerSectionDocAccessFields_banners_region_Read"]: {
		permission: boolean
};
	["BannerSectionDocAccessFields_banners_region_Update"]: {
		permission: boolean
};
	["BannerSectionDocAccessFields_banners_region_Delete"]: {
		permission: boolean
};
	["BannerSectionDocAccessFields_banners_id"]: {
		create?: ModelTypes["BannerSectionDocAccessFields_banners_id_Create"] | undefined,
	read?: ModelTypes["BannerSectionDocAccessFields_banners_id_Read"] | undefined,
	update?: ModelTypes["BannerSectionDocAccessFields_banners_id_Update"] | undefined,
	delete?: ModelTypes["BannerSectionDocAccessFields_banners_id_Delete"] | undefined
};
	["BannerSectionDocAccessFields_banners_id_Create"]: {
		permission: boolean
};
	["BannerSectionDocAccessFields_banners_id_Read"]: {
		permission: boolean
};
	["BannerSectionDocAccessFields_banners_id_Update"]: {
		permission: boolean
};
	["BannerSectionDocAccessFields_banners_id_Delete"]: {
		permission: boolean
};
	["BannerSectionDocAccessFields_updatedAt"]: {
		create?: ModelTypes["BannerSectionDocAccessFields_updatedAt_Create"] | undefined,
	read?: ModelTypes["BannerSectionDocAccessFields_updatedAt_Read"] | undefined,
	update?: ModelTypes["BannerSectionDocAccessFields_updatedAt_Update"] | undefined,
	delete?: ModelTypes["BannerSectionDocAccessFields_updatedAt_Delete"] | undefined
};
	["BannerSectionDocAccessFields_updatedAt_Create"]: {
		permission: boolean
};
	["BannerSectionDocAccessFields_updatedAt_Read"]: {
		permission: boolean
};
	["BannerSectionDocAccessFields_updatedAt_Update"]: {
		permission: boolean
};
	["BannerSectionDocAccessFields_updatedAt_Delete"]: {
		permission: boolean
};
	["BannerSectionDocAccessFields_createdAt"]: {
		create?: ModelTypes["BannerSectionDocAccessFields_createdAt_Create"] | undefined,
	read?: ModelTypes["BannerSectionDocAccessFields_createdAt_Read"] | undefined,
	update?: ModelTypes["BannerSectionDocAccessFields_createdAt_Update"] | undefined,
	delete?: ModelTypes["BannerSectionDocAccessFields_createdAt_Delete"] | undefined
};
	["BannerSectionDocAccessFields_createdAt_Create"]: {
		permission: boolean
};
	["BannerSectionDocAccessFields_createdAt_Read"]: {
		permission: boolean
};
	["BannerSectionDocAccessFields_createdAt_Update"]: {
		permission: boolean
};
	["BannerSectionDocAccessFields_createdAt_Delete"]: {
		permission: boolean
};
	["BannerSectionCreateDocAccess"]: {
		permission: boolean,
	where?: ModelTypes["JSONObject"] | undefined
};
	["BannerSectionReadDocAccess"]: {
		permission: boolean,
	where?: ModelTypes["JSONObject"] | undefined
};
	["BannerSectionUpdateDocAccess"]: {
		permission: boolean,
	where?: ModelTypes["JSONObject"] | undefined
};
	["BannerSectionDeleteDocAccess"]: {
		permission: boolean,
	where?: ModelTypes["JSONObject"] | undefined
};
	["MacaoBannerSection"]: {
		id?: string | undefined,
	name?: string | undefined,
	slug?: string | undefined,
	banners?: Array<ModelTypes["MacaoBannerSection_Banners"]> | undefined,
	updatedAt?: ModelTypes["DateTime"] | undefined,
	createdAt?: ModelTypes["DateTime"] | undefined
};
	["MacaoBannerSection_Banners"]: {
		name?: string | undefined,
	status?: string | undefined,
	desktop?: ModelTypes["Media"] | undefined,
	bannerStartDate?: ModelTypes["DateTime"] | undefined,
	bannerEndDate?: ModelTypes["DateTime"] | undefined,
	region?: ModelTypes["MacaoBannerSection_Banners_region"] | undefined,
	id?: string | undefined
};
	["MacaoBannerSection_Banners_Desktop_where"]: {
	alt?: ModelTypes["MacaoBannerSection_Banners_Desktop_alt_operator"] | undefined,
	updatedAt?: ModelTypes["MacaoBannerSection_Banners_Desktop_updatedAt_operator"] | undefined,
	createdAt?: ModelTypes["MacaoBannerSection_Banners_Desktop_createdAt_operator"] | undefined,
	url?: ModelTypes["MacaoBannerSection_Banners_Desktop_url_operator"] | undefined,
	filename?: ModelTypes["MacaoBannerSection_Banners_Desktop_filename_operator"] | undefined,
	mimeType?: ModelTypes["MacaoBannerSection_Banners_Desktop_mimeType_operator"] | undefined,
	filesize?: ModelTypes["MacaoBannerSection_Banners_Desktop_filesize_operator"] | undefined,
	width?: ModelTypes["MacaoBannerSection_Banners_Desktop_width_operator"] | undefined,
	height?: ModelTypes["MacaoBannerSection_Banners_Desktop_height_operator"] | undefined,
	focalX?: ModelTypes["MacaoBannerSection_Banners_Desktop_focalX_operator"] | undefined,
	focalY?: ModelTypes["MacaoBannerSection_Banners_Desktop_focalY_operator"] | undefined,
	sizes__small__url?: ModelTypes["MacaoBannerSection_Banners_Desktop_sizes__small__url_operator"] | undefined,
	sizes__small__width?: ModelTypes["MacaoBannerSection_Banners_Desktop_sizes__small__width_operator"] | undefined,
	sizes__small__height?: ModelTypes["MacaoBannerSection_Banners_Desktop_sizes__small__height_operator"] | undefined,
	sizes__small__mimeType?: ModelTypes["MacaoBannerSection_Banners_Desktop_sizes__small__mimeType_operator"] | undefined,
	sizes__small__filesize?: ModelTypes["MacaoBannerSection_Banners_Desktop_sizes__small__filesize_operator"] | undefined,
	sizes__small__filename?: ModelTypes["MacaoBannerSection_Banners_Desktop_sizes__small__filename_operator"] | undefined,
	sizes__medium__url?: ModelTypes["MacaoBannerSection_Banners_Desktop_sizes__medium__url_operator"] | undefined,
	sizes__medium__width?: ModelTypes["MacaoBannerSection_Banners_Desktop_sizes__medium__width_operator"] | undefined,
	sizes__medium__height?: ModelTypes["MacaoBannerSection_Banners_Desktop_sizes__medium__height_operator"] | undefined,
	sizes__medium__mimeType?: ModelTypes["MacaoBannerSection_Banners_Desktop_sizes__medium__mimeType_operator"] | undefined,
	sizes__medium__filesize?: ModelTypes["MacaoBannerSection_Banners_Desktop_sizes__medium__filesize_operator"] | undefined,
	sizes__medium__filename?: ModelTypes["MacaoBannerSection_Banners_Desktop_sizes__medium__filename_operator"] | undefined,
	sizes__large__url?: ModelTypes["MacaoBannerSection_Banners_Desktop_sizes__large__url_operator"] | undefined,
	sizes__large__width?: ModelTypes["MacaoBannerSection_Banners_Desktop_sizes__large__width_operator"] | undefined,
	sizes__large__height?: ModelTypes["MacaoBannerSection_Banners_Desktop_sizes__large__height_operator"] | undefined,
	sizes__large__mimeType?: ModelTypes["MacaoBannerSection_Banners_Desktop_sizes__large__mimeType_operator"] | undefined,
	sizes__large__filesize?: ModelTypes["MacaoBannerSection_Banners_Desktop_sizes__large__filesize_operator"] | undefined,
	sizes__large__filename?: ModelTypes["MacaoBannerSection_Banners_Desktop_sizes__large__filename_operator"] | undefined,
	sizes__small_jpeg__url?: ModelTypes["MacaoBannerSection_Banners_Desktop_sizes__small_jpeg__url_operator"] | undefined,
	sizes__small_jpeg__width?: ModelTypes["MacaoBannerSection_Banners_Desktop_sizes__small_jpeg__width_operator"] | undefined,
	sizes__small_jpeg__height?: ModelTypes["MacaoBannerSection_Banners_Desktop_sizes__small_jpeg__height_operator"] | undefined,
	sizes__small_jpeg__mimeType?: ModelTypes["MacaoBannerSection_Banners_Desktop_sizes__small_jpeg__mimeType_operator"] | undefined,
	sizes__small_jpeg__filesize?: ModelTypes["MacaoBannerSection_Banners_Desktop_sizes__small_jpeg__filesize_operator"] | undefined,
	sizes__small_jpeg__filename?: ModelTypes["MacaoBannerSection_Banners_Desktop_sizes__small_jpeg__filename_operator"] | undefined,
	sizes__medium_jpeg__url?: ModelTypes["MacaoBannerSection_Banners_Desktop_sizes__medium_jpeg__url_operator"] | undefined,
	sizes__medium_jpeg__width?: ModelTypes["MacaoBannerSection_Banners_Desktop_sizes__medium_jpeg__width_operator"] | undefined,
	sizes__medium_jpeg__height?: ModelTypes["MacaoBannerSection_Banners_Desktop_sizes__medium_jpeg__height_operator"] | undefined,
	sizes__medium_jpeg__mimeType?: ModelTypes["MacaoBannerSection_Banners_Desktop_sizes__medium_jpeg__mimeType_operator"] | undefined,
	sizes__medium_jpeg__filesize?: ModelTypes["MacaoBannerSection_Banners_Desktop_sizes__medium_jpeg__filesize_operator"] | undefined,
	sizes__medium_jpeg__filename?: ModelTypes["MacaoBannerSection_Banners_Desktop_sizes__medium_jpeg__filename_operator"] | undefined,
	sizes__large_jpeg__url?: ModelTypes["MacaoBannerSection_Banners_Desktop_sizes__large_jpeg__url_operator"] | undefined,
	sizes__large_jpeg__width?: ModelTypes["MacaoBannerSection_Banners_Desktop_sizes__large_jpeg__width_operator"] | undefined,
	sizes__large_jpeg__height?: ModelTypes["MacaoBannerSection_Banners_Desktop_sizes__large_jpeg__height_operator"] | undefined,
	sizes__large_jpeg__mimeType?: ModelTypes["MacaoBannerSection_Banners_Desktop_sizes__large_jpeg__mimeType_operator"] | undefined,
	sizes__large_jpeg__filesize?: ModelTypes["MacaoBannerSection_Banners_Desktop_sizes__large_jpeg__filesize_operator"] | undefined,
	sizes__large_jpeg__filename?: ModelTypes["MacaoBannerSection_Banners_Desktop_sizes__large_jpeg__filename_operator"] | undefined,
	id?: ModelTypes["MacaoBannerSection_Banners_Desktop_id_operator"] | undefined,
	AND?: Array<ModelTypes["MacaoBannerSection_Banners_Desktop_where_and"] | undefined> | undefined,
	OR?: Array<ModelTypes["MacaoBannerSection_Banners_Desktop_where_or"] | undefined> | undefined
};
	["MacaoBannerSection_Banners_Desktop_alt_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["MacaoBannerSection_Banners_Desktop_updatedAt_operator"]: {
	equals?: ModelTypes["DateTime"] | undefined,
	not_equals?: ModelTypes["DateTime"] | undefined,
	greater_than_equal?: ModelTypes["DateTime"] | undefined,
	greater_than?: ModelTypes["DateTime"] | undefined,
	less_than_equal?: ModelTypes["DateTime"] | undefined,
	less_than?: ModelTypes["DateTime"] | undefined,
	like?: ModelTypes["DateTime"] | undefined,
	exists?: boolean | undefined
};
	["MacaoBannerSection_Banners_Desktop_createdAt_operator"]: {
	equals?: ModelTypes["DateTime"] | undefined,
	not_equals?: ModelTypes["DateTime"] | undefined,
	greater_than_equal?: ModelTypes["DateTime"] | undefined,
	greater_than?: ModelTypes["DateTime"] | undefined,
	less_than_equal?: ModelTypes["DateTime"] | undefined,
	less_than?: ModelTypes["DateTime"] | undefined,
	like?: ModelTypes["DateTime"] | undefined,
	exists?: boolean | undefined
};
	["MacaoBannerSection_Banners_Desktop_url_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["MacaoBannerSection_Banners_Desktop_filename_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["MacaoBannerSection_Banners_Desktop_mimeType_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["MacaoBannerSection_Banners_Desktop_filesize_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["MacaoBannerSection_Banners_Desktop_width_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["MacaoBannerSection_Banners_Desktop_height_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["MacaoBannerSection_Banners_Desktop_focalX_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["MacaoBannerSection_Banners_Desktop_focalY_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["MacaoBannerSection_Banners_Desktop_sizes__small__url_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["MacaoBannerSection_Banners_Desktop_sizes__small__width_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["MacaoBannerSection_Banners_Desktop_sizes__small__height_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["MacaoBannerSection_Banners_Desktop_sizes__small__mimeType_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["MacaoBannerSection_Banners_Desktop_sizes__small__filesize_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["MacaoBannerSection_Banners_Desktop_sizes__small__filename_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["MacaoBannerSection_Banners_Desktop_sizes__medium__url_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["MacaoBannerSection_Banners_Desktop_sizes__medium__width_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["MacaoBannerSection_Banners_Desktop_sizes__medium__height_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["MacaoBannerSection_Banners_Desktop_sizes__medium__mimeType_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["MacaoBannerSection_Banners_Desktop_sizes__medium__filesize_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["MacaoBannerSection_Banners_Desktop_sizes__medium__filename_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["MacaoBannerSection_Banners_Desktop_sizes__large__url_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["MacaoBannerSection_Banners_Desktop_sizes__large__width_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["MacaoBannerSection_Banners_Desktop_sizes__large__height_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["MacaoBannerSection_Banners_Desktop_sizes__large__mimeType_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["MacaoBannerSection_Banners_Desktop_sizes__large__filesize_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["MacaoBannerSection_Banners_Desktop_sizes__large__filename_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["MacaoBannerSection_Banners_Desktop_sizes__small_jpeg__url_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["MacaoBannerSection_Banners_Desktop_sizes__small_jpeg__width_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["MacaoBannerSection_Banners_Desktop_sizes__small_jpeg__height_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["MacaoBannerSection_Banners_Desktop_sizes__small_jpeg__mimeType_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["MacaoBannerSection_Banners_Desktop_sizes__small_jpeg__filesize_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["MacaoBannerSection_Banners_Desktop_sizes__small_jpeg__filename_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["MacaoBannerSection_Banners_Desktop_sizes__medium_jpeg__url_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["MacaoBannerSection_Banners_Desktop_sizes__medium_jpeg__width_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["MacaoBannerSection_Banners_Desktop_sizes__medium_jpeg__height_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["MacaoBannerSection_Banners_Desktop_sizes__medium_jpeg__mimeType_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["MacaoBannerSection_Banners_Desktop_sizes__medium_jpeg__filesize_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["MacaoBannerSection_Banners_Desktop_sizes__medium_jpeg__filename_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["MacaoBannerSection_Banners_Desktop_sizes__large_jpeg__url_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["MacaoBannerSection_Banners_Desktop_sizes__large_jpeg__width_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["MacaoBannerSection_Banners_Desktop_sizes__large_jpeg__height_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["MacaoBannerSection_Banners_Desktop_sizes__large_jpeg__mimeType_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["MacaoBannerSection_Banners_Desktop_sizes__large_jpeg__filesize_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["MacaoBannerSection_Banners_Desktop_sizes__large_jpeg__filename_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["MacaoBannerSection_Banners_Desktop_id_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["MacaoBannerSection_Banners_Desktop_where_and"]: {
	alt?: ModelTypes["MacaoBannerSection_Banners_Desktop_alt_operator"] | undefined,
	updatedAt?: ModelTypes["MacaoBannerSection_Banners_Desktop_updatedAt_operator"] | undefined,
	createdAt?: ModelTypes["MacaoBannerSection_Banners_Desktop_createdAt_operator"] | undefined,
	url?: ModelTypes["MacaoBannerSection_Banners_Desktop_url_operator"] | undefined,
	filename?: ModelTypes["MacaoBannerSection_Banners_Desktop_filename_operator"] | undefined,
	mimeType?: ModelTypes["MacaoBannerSection_Banners_Desktop_mimeType_operator"] | undefined,
	filesize?: ModelTypes["MacaoBannerSection_Banners_Desktop_filesize_operator"] | undefined,
	width?: ModelTypes["MacaoBannerSection_Banners_Desktop_width_operator"] | undefined,
	height?: ModelTypes["MacaoBannerSection_Banners_Desktop_height_operator"] | undefined,
	focalX?: ModelTypes["MacaoBannerSection_Banners_Desktop_focalX_operator"] | undefined,
	focalY?: ModelTypes["MacaoBannerSection_Banners_Desktop_focalY_operator"] | undefined,
	sizes__small__url?: ModelTypes["MacaoBannerSection_Banners_Desktop_sizes__small__url_operator"] | undefined,
	sizes__small__width?: ModelTypes["MacaoBannerSection_Banners_Desktop_sizes__small__width_operator"] | undefined,
	sizes__small__height?: ModelTypes["MacaoBannerSection_Banners_Desktop_sizes__small__height_operator"] | undefined,
	sizes__small__mimeType?: ModelTypes["MacaoBannerSection_Banners_Desktop_sizes__small__mimeType_operator"] | undefined,
	sizes__small__filesize?: ModelTypes["MacaoBannerSection_Banners_Desktop_sizes__small__filesize_operator"] | undefined,
	sizes__small__filename?: ModelTypes["MacaoBannerSection_Banners_Desktop_sizes__small__filename_operator"] | undefined,
	sizes__medium__url?: ModelTypes["MacaoBannerSection_Banners_Desktop_sizes__medium__url_operator"] | undefined,
	sizes__medium__width?: ModelTypes["MacaoBannerSection_Banners_Desktop_sizes__medium__width_operator"] | undefined,
	sizes__medium__height?: ModelTypes["MacaoBannerSection_Banners_Desktop_sizes__medium__height_operator"] | undefined,
	sizes__medium__mimeType?: ModelTypes["MacaoBannerSection_Banners_Desktop_sizes__medium__mimeType_operator"] | undefined,
	sizes__medium__filesize?: ModelTypes["MacaoBannerSection_Banners_Desktop_sizes__medium__filesize_operator"] | undefined,
	sizes__medium__filename?: ModelTypes["MacaoBannerSection_Banners_Desktop_sizes__medium__filename_operator"] | undefined,
	sizes__large__url?: ModelTypes["MacaoBannerSection_Banners_Desktop_sizes__large__url_operator"] | undefined,
	sizes__large__width?: ModelTypes["MacaoBannerSection_Banners_Desktop_sizes__large__width_operator"] | undefined,
	sizes__large__height?: ModelTypes["MacaoBannerSection_Banners_Desktop_sizes__large__height_operator"] | undefined,
	sizes__large__mimeType?: ModelTypes["MacaoBannerSection_Banners_Desktop_sizes__large__mimeType_operator"] | undefined,
	sizes__large__filesize?: ModelTypes["MacaoBannerSection_Banners_Desktop_sizes__large__filesize_operator"] | undefined,
	sizes__large__filename?: ModelTypes["MacaoBannerSection_Banners_Desktop_sizes__large__filename_operator"] | undefined,
	sizes__small_jpeg__url?: ModelTypes["MacaoBannerSection_Banners_Desktop_sizes__small_jpeg__url_operator"] | undefined,
	sizes__small_jpeg__width?: ModelTypes["MacaoBannerSection_Banners_Desktop_sizes__small_jpeg__width_operator"] | undefined,
	sizes__small_jpeg__height?: ModelTypes["MacaoBannerSection_Banners_Desktop_sizes__small_jpeg__height_operator"] | undefined,
	sizes__small_jpeg__mimeType?: ModelTypes["MacaoBannerSection_Banners_Desktop_sizes__small_jpeg__mimeType_operator"] | undefined,
	sizes__small_jpeg__filesize?: ModelTypes["MacaoBannerSection_Banners_Desktop_sizes__small_jpeg__filesize_operator"] | undefined,
	sizes__small_jpeg__filename?: ModelTypes["MacaoBannerSection_Banners_Desktop_sizes__small_jpeg__filename_operator"] | undefined,
	sizes__medium_jpeg__url?: ModelTypes["MacaoBannerSection_Banners_Desktop_sizes__medium_jpeg__url_operator"] | undefined,
	sizes__medium_jpeg__width?: ModelTypes["MacaoBannerSection_Banners_Desktop_sizes__medium_jpeg__width_operator"] | undefined,
	sizes__medium_jpeg__height?: ModelTypes["MacaoBannerSection_Banners_Desktop_sizes__medium_jpeg__height_operator"] | undefined,
	sizes__medium_jpeg__mimeType?: ModelTypes["MacaoBannerSection_Banners_Desktop_sizes__medium_jpeg__mimeType_operator"] | undefined,
	sizes__medium_jpeg__filesize?: ModelTypes["MacaoBannerSection_Banners_Desktop_sizes__medium_jpeg__filesize_operator"] | undefined,
	sizes__medium_jpeg__filename?: ModelTypes["MacaoBannerSection_Banners_Desktop_sizes__medium_jpeg__filename_operator"] | undefined,
	sizes__large_jpeg__url?: ModelTypes["MacaoBannerSection_Banners_Desktop_sizes__large_jpeg__url_operator"] | undefined,
	sizes__large_jpeg__width?: ModelTypes["MacaoBannerSection_Banners_Desktop_sizes__large_jpeg__width_operator"] | undefined,
	sizes__large_jpeg__height?: ModelTypes["MacaoBannerSection_Banners_Desktop_sizes__large_jpeg__height_operator"] | undefined,
	sizes__large_jpeg__mimeType?: ModelTypes["MacaoBannerSection_Banners_Desktop_sizes__large_jpeg__mimeType_operator"] | undefined,
	sizes__large_jpeg__filesize?: ModelTypes["MacaoBannerSection_Banners_Desktop_sizes__large_jpeg__filesize_operator"] | undefined,
	sizes__large_jpeg__filename?: ModelTypes["MacaoBannerSection_Banners_Desktop_sizes__large_jpeg__filename_operator"] | undefined,
	id?: ModelTypes["MacaoBannerSection_Banners_Desktop_id_operator"] | undefined,
	AND?: Array<ModelTypes["MacaoBannerSection_Banners_Desktop_where_and"] | undefined> | undefined,
	OR?: Array<ModelTypes["MacaoBannerSection_Banners_Desktop_where_or"] | undefined> | undefined
};
	["MacaoBannerSection_Banners_Desktop_where_or"]: {
	alt?: ModelTypes["MacaoBannerSection_Banners_Desktop_alt_operator"] | undefined,
	updatedAt?: ModelTypes["MacaoBannerSection_Banners_Desktop_updatedAt_operator"] | undefined,
	createdAt?: ModelTypes["MacaoBannerSection_Banners_Desktop_createdAt_operator"] | undefined,
	url?: ModelTypes["MacaoBannerSection_Banners_Desktop_url_operator"] | undefined,
	filename?: ModelTypes["MacaoBannerSection_Banners_Desktop_filename_operator"] | undefined,
	mimeType?: ModelTypes["MacaoBannerSection_Banners_Desktop_mimeType_operator"] | undefined,
	filesize?: ModelTypes["MacaoBannerSection_Banners_Desktop_filesize_operator"] | undefined,
	width?: ModelTypes["MacaoBannerSection_Banners_Desktop_width_operator"] | undefined,
	height?: ModelTypes["MacaoBannerSection_Banners_Desktop_height_operator"] | undefined,
	focalX?: ModelTypes["MacaoBannerSection_Banners_Desktop_focalX_operator"] | undefined,
	focalY?: ModelTypes["MacaoBannerSection_Banners_Desktop_focalY_operator"] | undefined,
	sizes__small__url?: ModelTypes["MacaoBannerSection_Banners_Desktop_sizes__small__url_operator"] | undefined,
	sizes__small__width?: ModelTypes["MacaoBannerSection_Banners_Desktop_sizes__small__width_operator"] | undefined,
	sizes__small__height?: ModelTypes["MacaoBannerSection_Banners_Desktop_sizes__small__height_operator"] | undefined,
	sizes__small__mimeType?: ModelTypes["MacaoBannerSection_Banners_Desktop_sizes__small__mimeType_operator"] | undefined,
	sizes__small__filesize?: ModelTypes["MacaoBannerSection_Banners_Desktop_sizes__small__filesize_operator"] | undefined,
	sizes__small__filename?: ModelTypes["MacaoBannerSection_Banners_Desktop_sizes__small__filename_operator"] | undefined,
	sizes__medium__url?: ModelTypes["MacaoBannerSection_Banners_Desktop_sizes__medium__url_operator"] | undefined,
	sizes__medium__width?: ModelTypes["MacaoBannerSection_Banners_Desktop_sizes__medium__width_operator"] | undefined,
	sizes__medium__height?: ModelTypes["MacaoBannerSection_Banners_Desktop_sizes__medium__height_operator"] | undefined,
	sizes__medium__mimeType?: ModelTypes["MacaoBannerSection_Banners_Desktop_sizes__medium__mimeType_operator"] | undefined,
	sizes__medium__filesize?: ModelTypes["MacaoBannerSection_Banners_Desktop_sizes__medium__filesize_operator"] | undefined,
	sizes__medium__filename?: ModelTypes["MacaoBannerSection_Banners_Desktop_sizes__medium__filename_operator"] | undefined,
	sizes__large__url?: ModelTypes["MacaoBannerSection_Banners_Desktop_sizes__large__url_operator"] | undefined,
	sizes__large__width?: ModelTypes["MacaoBannerSection_Banners_Desktop_sizes__large__width_operator"] | undefined,
	sizes__large__height?: ModelTypes["MacaoBannerSection_Banners_Desktop_sizes__large__height_operator"] | undefined,
	sizes__large__mimeType?: ModelTypes["MacaoBannerSection_Banners_Desktop_sizes__large__mimeType_operator"] | undefined,
	sizes__large__filesize?: ModelTypes["MacaoBannerSection_Banners_Desktop_sizes__large__filesize_operator"] | undefined,
	sizes__large__filename?: ModelTypes["MacaoBannerSection_Banners_Desktop_sizes__large__filename_operator"] | undefined,
	sizes__small_jpeg__url?: ModelTypes["MacaoBannerSection_Banners_Desktop_sizes__small_jpeg__url_operator"] | undefined,
	sizes__small_jpeg__width?: ModelTypes["MacaoBannerSection_Banners_Desktop_sizes__small_jpeg__width_operator"] | undefined,
	sizes__small_jpeg__height?: ModelTypes["MacaoBannerSection_Banners_Desktop_sizes__small_jpeg__height_operator"] | undefined,
	sizes__small_jpeg__mimeType?: ModelTypes["MacaoBannerSection_Banners_Desktop_sizes__small_jpeg__mimeType_operator"] | undefined,
	sizes__small_jpeg__filesize?: ModelTypes["MacaoBannerSection_Banners_Desktop_sizes__small_jpeg__filesize_operator"] | undefined,
	sizes__small_jpeg__filename?: ModelTypes["MacaoBannerSection_Banners_Desktop_sizes__small_jpeg__filename_operator"] | undefined,
	sizes__medium_jpeg__url?: ModelTypes["MacaoBannerSection_Banners_Desktop_sizes__medium_jpeg__url_operator"] | undefined,
	sizes__medium_jpeg__width?: ModelTypes["MacaoBannerSection_Banners_Desktop_sizes__medium_jpeg__width_operator"] | undefined,
	sizes__medium_jpeg__height?: ModelTypes["MacaoBannerSection_Banners_Desktop_sizes__medium_jpeg__height_operator"] | undefined,
	sizes__medium_jpeg__mimeType?: ModelTypes["MacaoBannerSection_Banners_Desktop_sizes__medium_jpeg__mimeType_operator"] | undefined,
	sizes__medium_jpeg__filesize?: ModelTypes["MacaoBannerSection_Banners_Desktop_sizes__medium_jpeg__filesize_operator"] | undefined,
	sizes__medium_jpeg__filename?: ModelTypes["MacaoBannerSection_Banners_Desktop_sizes__medium_jpeg__filename_operator"] | undefined,
	sizes__large_jpeg__url?: ModelTypes["MacaoBannerSection_Banners_Desktop_sizes__large_jpeg__url_operator"] | undefined,
	sizes__large_jpeg__width?: ModelTypes["MacaoBannerSection_Banners_Desktop_sizes__large_jpeg__width_operator"] | undefined,
	sizes__large_jpeg__height?: ModelTypes["MacaoBannerSection_Banners_Desktop_sizes__large_jpeg__height_operator"] | undefined,
	sizes__large_jpeg__mimeType?: ModelTypes["MacaoBannerSection_Banners_Desktop_sizes__large_jpeg__mimeType_operator"] | undefined,
	sizes__large_jpeg__filesize?: ModelTypes["MacaoBannerSection_Banners_Desktop_sizes__large_jpeg__filesize_operator"] | undefined,
	sizes__large_jpeg__filename?: ModelTypes["MacaoBannerSection_Banners_Desktop_sizes__large_jpeg__filename_operator"] | undefined,
	id?: ModelTypes["MacaoBannerSection_Banners_Desktop_id_operator"] | undefined,
	AND?: Array<ModelTypes["MacaoBannerSection_Banners_Desktop_where_and"] | undefined> | undefined,
	OR?: Array<ModelTypes["MacaoBannerSection_Banners_Desktop_where_or"] | undefined> | undefined
};
	["MacaoBannerSection_Banners_region"]:MacaoBannerSection_Banners_region;
	["MacaoBannerSections"]: {
		docs?: Array<ModelTypes["MacaoBannerSection"] | undefined> | undefined,
	hasNextPage?: boolean | undefined,
	hasPrevPage?: boolean | undefined,
	limit?: number | undefined,
	nextPage?: number | undefined,
	offset?: number | undefined,
	page?: number | undefined,
	pagingCounter?: number | undefined,
	prevPage?: number | undefined,
	totalDocs?: number | undefined,
	totalPages?: number | undefined
};
	["MacaoBannerSection_where"]: {
	name?: ModelTypes["MacaoBannerSection_name_operator"] | undefined,
	slug?: ModelTypes["MacaoBannerSection_slug_operator"] | undefined,
	banners__name?: ModelTypes["MacaoBannerSection_banners__name_operator"] | undefined,
	banners__status?: ModelTypes["MacaoBannerSection_banners__status_operator"] | undefined,
	banners__desktop?: ModelTypes["MacaoBannerSection_banners__desktop_operator"] | undefined,
	banners__bannerStartDate?: ModelTypes["MacaoBannerSection_banners__bannerStartDate_operator"] | undefined,
	banners__bannerEndDate?: ModelTypes["MacaoBannerSection_banners__bannerEndDate_operator"] | undefined,
	banners__region?: ModelTypes["MacaoBannerSection_banners__region_operator"] | undefined,
	banners__id?: ModelTypes["MacaoBannerSection_banners__id_operator"] | undefined,
	updatedAt?: ModelTypes["MacaoBannerSection_updatedAt_operator"] | undefined,
	createdAt?: ModelTypes["MacaoBannerSection_createdAt_operator"] | undefined,
	id?: ModelTypes["MacaoBannerSection_id_operator"] | undefined,
	AND?: Array<ModelTypes["MacaoBannerSection_where_and"] | undefined> | undefined,
	OR?: Array<ModelTypes["MacaoBannerSection_where_or"] | undefined> | undefined
};
	["MacaoBannerSection_name_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["MacaoBannerSection_slug_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["MacaoBannerSection_banners__name_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["MacaoBannerSection_banners__status_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["MacaoBannerSection_banners__desktop_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined
};
	["MacaoBannerSection_banners__bannerStartDate_operator"]: {
	equals?: ModelTypes["DateTime"] | undefined,
	not_equals?: ModelTypes["DateTime"] | undefined,
	greater_than_equal?: ModelTypes["DateTime"] | undefined,
	greater_than?: ModelTypes["DateTime"] | undefined,
	less_than_equal?: ModelTypes["DateTime"] | undefined,
	less_than?: ModelTypes["DateTime"] | undefined,
	like?: ModelTypes["DateTime"] | undefined,
	exists?: boolean | undefined
};
	["MacaoBannerSection_banners__bannerEndDate_operator"]: {
	equals?: ModelTypes["DateTime"] | undefined,
	not_equals?: ModelTypes["DateTime"] | undefined,
	greater_than_equal?: ModelTypes["DateTime"] | undefined,
	greater_than?: ModelTypes["DateTime"] | undefined,
	less_than_equal?: ModelTypes["DateTime"] | undefined,
	less_than?: ModelTypes["DateTime"] | undefined,
	like?: ModelTypes["DateTime"] | undefined,
	exists?: boolean | undefined
};
	["MacaoBannerSection_banners__region_operator"]: {
	equals?: ModelTypes["MacaoBannerSection_banners__region_Input"] | undefined,
	not_equals?: ModelTypes["MacaoBannerSection_banners__region_Input"] | undefined,
	in?: Array<ModelTypes["MacaoBannerSection_banners__region_Input"] | undefined> | undefined,
	not_in?: Array<ModelTypes["MacaoBannerSection_banners__region_Input"] | undefined> | undefined,
	all?: Array<ModelTypes["MacaoBannerSection_banners__region_Input"] | undefined> | undefined,
	exists?: boolean | undefined
};
	["MacaoBannerSection_banners__region_Input"]:MacaoBannerSection_banners__region_Input;
	["MacaoBannerSection_banners__id_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["MacaoBannerSection_updatedAt_operator"]: {
	equals?: ModelTypes["DateTime"] | undefined,
	not_equals?: ModelTypes["DateTime"] | undefined,
	greater_than_equal?: ModelTypes["DateTime"] | undefined,
	greater_than?: ModelTypes["DateTime"] | undefined,
	less_than_equal?: ModelTypes["DateTime"] | undefined,
	less_than?: ModelTypes["DateTime"] | undefined,
	like?: ModelTypes["DateTime"] | undefined,
	exists?: boolean | undefined
};
	["MacaoBannerSection_createdAt_operator"]: {
	equals?: ModelTypes["DateTime"] | undefined,
	not_equals?: ModelTypes["DateTime"] | undefined,
	greater_than_equal?: ModelTypes["DateTime"] | undefined,
	greater_than?: ModelTypes["DateTime"] | undefined,
	less_than_equal?: ModelTypes["DateTime"] | undefined,
	less_than?: ModelTypes["DateTime"] | undefined,
	like?: ModelTypes["DateTime"] | undefined,
	exists?: boolean | undefined
};
	["MacaoBannerSection_id_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["MacaoBannerSection_where_and"]: {
	name?: ModelTypes["MacaoBannerSection_name_operator"] | undefined,
	slug?: ModelTypes["MacaoBannerSection_slug_operator"] | undefined,
	banners__name?: ModelTypes["MacaoBannerSection_banners__name_operator"] | undefined,
	banners__status?: ModelTypes["MacaoBannerSection_banners__status_operator"] | undefined,
	banners__desktop?: ModelTypes["MacaoBannerSection_banners__desktop_operator"] | undefined,
	banners__bannerStartDate?: ModelTypes["MacaoBannerSection_banners__bannerStartDate_operator"] | undefined,
	banners__bannerEndDate?: ModelTypes["MacaoBannerSection_banners__bannerEndDate_operator"] | undefined,
	banners__region?: ModelTypes["MacaoBannerSection_banners__region_operator"] | undefined,
	banners__id?: ModelTypes["MacaoBannerSection_banners__id_operator"] | undefined,
	updatedAt?: ModelTypes["MacaoBannerSection_updatedAt_operator"] | undefined,
	createdAt?: ModelTypes["MacaoBannerSection_createdAt_operator"] | undefined,
	id?: ModelTypes["MacaoBannerSection_id_operator"] | undefined,
	AND?: Array<ModelTypes["MacaoBannerSection_where_and"] | undefined> | undefined,
	OR?: Array<ModelTypes["MacaoBannerSection_where_or"] | undefined> | undefined
};
	["MacaoBannerSection_where_or"]: {
	name?: ModelTypes["MacaoBannerSection_name_operator"] | undefined,
	slug?: ModelTypes["MacaoBannerSection_slug_operator"] | undefined,
	banners__name?: ModelTypes["MacaoBannerSection_banners__name_operator"] | undefined,
	banners__status?: ModelTypes["MacaoBannerSection_banners__status_operator"] | undefined,
	banners__desktop?: ModelTypes["MacaoBannerSection_banners__desktop_operator"] | undefined,
	banners__bannerStartDate?: ModelTypes["MacaoBannerSection_banners__bannerStartDate_operator"] | undefined,
	banners__bannerEndDate?: ModelTypes["MacaoBannerSection_banners__bannerEndDate_operator"] | undefined,
	banners__region?: ModelTypes["MacaoBannerSection_banners__region_operator"] | undefined,
	banners__id?: ModelTypes["MacaoBannerSection_banners__id_operator"] | undefined,
	updatedAt?: ModelTypes["MacaoBannerSection_updatedAt_operator"] | undefined,
	createdAt?: ModelTypes["MacaoBannerSection_createdAt_operator"] | undefined,
	id?: ModelTypes["MacaoBannerSection_id_operator"] | undefined,
	AND?: Array<ModelTypes["MacaoBannerSection_where_and"] | undefined> | undefined,
	OR?: Array<ModelTypes["MacaoBannerSection_where_or"] | undefined> | undefined
};
	["countMacaoBannerSections"]: {
		totalDocs?: number | undefined
};
	["macao_banner_sectionDocAccess"]: {
		fields?: ModelTypes["MacaoBannerSectionDocAccessFields"] | undefined,
	create?: ModelTypes["MacaoBannerSectionCreateDocAccess"] | undefined,
	read?: ModelTypes["MacaoBannerSectionReadDocAccess"] | undefined,
	update?: ModelTypes["MacaoBannerSectionUpdateDocAccess"] | undefined,
	delete?: ModelTypes["MacaoBannerSectionDeleteDocAccess"] | undefined
};
	["MacaoBannerSectionDocAccessFields"]: {
		name?: ModelTypes["MacaoBannerSectionDocAccessFields_name"] | undefined,
	slug?: ModelTypes["MacaoBannerSectionDocAccessFields_slug"] | undefined,
	banners?: ModelTypes["MacaoBannerSectionDocAccessFields_banners"] | undefined,
	updatedAt?: ModelTypes["MacaoBannerSectionDocAccessFields_updatedAt"] | undefined,
	createdAt?: ModelTypes["MacaoBannerSectionDocAccessFields_createdAt"] | undefined
};
	["MacaoBannerSectionDocAccessFields_name"]: {
		create?: ModelTypes["MacaoBannerSectionDocAccessFields_name_Create"] | undefined,
	read?: ModelTypes["MacaoBannerSectionDocAccessFields_name_Read"] | undefined,
	update?: ModelTypes["MacaoBannerSectionDocAccessFields_name_Update"] | undefined,
	delete?: ModelTypes["MacaoBannerSectionDocAccessFields_name_Delete"] | undefined
};
	["MacaoBannerSectionDocAccessFields_name_Create"]: {
		permission: boolean
};
	["MacaoBannerSectionDocAccessFields_name_Read"]: {
		permission: boolean
};
	["MacaoBannerSectionDocAccessFields_name_Update"]: {
		permission: boolean
};
	["MacaoBannerSectionDocAccessFields_name_Delete"]: {
		permission: boolean
};
	["MacaoBannerSectionDocAccessFields_slug"]: {
		create?: ModelTypes["MacaoBannerSectionDocAccessFields_slug_Create"] | undefined,
	read?: ModelTypes["MacaoBannerSectionDocAccessFields_slug_Read"] | undefined,
	update?: ModelTypes["MacaoBannerSectionDocAccessFields_slug_Update"] | undefined,
	delete?: ModelTypes["MacaoBannerSectionDocAccessFields_slug_Delete"] | undefined
};
	["MacaoBannerSectionDocAccessFields_slug_Create"]: {
		permission: boolean
};
	["MacaoBannerSectionDocAccessFields_slug_Read"]: {
		permission: boolean
};
	["MacaoBannerSectionDocAccessFields_slug_Update"]: {
		permission: boolean
};
	["MacaoBannerSectionDocAccessFields_slug_Delete"]: {
		permission: boolean
};
	["MacaoBannerSectionDocAccessFields_banners"]: {
		create?: ModelTypes["MacaoBannerSectionDocAccessFields_banners_Create"] | undefined,
	read?: ModelTypes["MacaoBannerSectionDocAccessFields_banners_Read"] | undefined,
	update?: ModelTypes["MacaoBannerSectionDocAccessFields_banners_Update"] | undefined,
	delete?: ModelTypes["MacaoBannerSectionDocAccessFields_banners_Delete"] | undefined,
	fields?: ModelTypes["MacaoBannerSectionDocAccessFields_banners_Fields"] | undefined
};
	["MacaoBannerSectionDocAccessFields_banners_Create"]: {
		permission: boolean
};
	["MacaoBannerSectionDocAccessFields_banners_Read"]: {
		permission: boolean
};
	["MacaoBannerSectionDocAccessFields_banners_Update"]: {
		permission: boolean
};
	["MacaoBannerSectionDocAccessFields_banners_Delete"]: {
		permission: boolean
};
	["MacaoBannerSectionDocAccessFields_banners_Fields"]: {
		name?: ModelTypes["MacaoBannerSectionDocAccessFields_banners_name"] | undefined,
	status?: ModelTypes["MacaoBannerSectionDocAccessFields_banners_status"] | undefined,
	desktop?: ModelTypes["MacaoBannerSectionDocAccessFields_banners_desktop"] | undefined,
	bannerStartDate?: ModelTypes["MacaoBannerSectionDocAccessFields_banners_bannerStartDate"] | undefined,
	bannerEndDate?: ModelTypes["MacaoBannerSectionDocAccessFields_banners_bannerEndDate"] | undefined,
	region?: ModelTypes["MacaoBannerSectionDocAccessFields_banners_region"] | undefined,
	id?: ModelTypes["MacaoBannerSectionDocAccessFields_banners_id"] | undefined
};
	["MacaoBannerSectionDocAccessFields_banners_name"]: {
		create?: ModelTypes["MacaoBannerSectionDocAccessFields_banners_name_Create"] | undefined,
	read?: ModelTypes["MacaoBannerSectionDocAccessFields_banners_name_Read"] | undefined,
	update?: ModelTypes["MacaoBannerSectionDocAccessFields_banners_name_Update"] | undefined,
	delete?: ModelTypes["MacaoBannerSectionDocAccessFields_banners_name_Delete"] | undefined
};
	["MacaoBannerSectionDocAccessFields_banners_name_Create"]: {
		permission: boolean
};
	["MacaoBannerSectionDocAccessFields_banners_name_Read"]: {
		permission: boolean
};
	["MacaoBannerSectionDocAccessFields_banners_name_Update"]: {
		permission: boolean
};
	["MacaoBannerSectionDocAccessFields_banners_name_Delete"]: {
		permission: boolean
};
	["MacaoBannerSectionDocAccessFields_banners_status"]: {
		create?: ModelTypes["MacaoBannerSectionDocAccessFields_banners_status_Create"] | undefined,
	read?: ModelTypes["MacaoBannerSectionDocAccessFields_banners_status_Read"] | undefined,
	update?: ModelTypes["MacaoBannerSectionDocAccessFields_banners_status_Update"] | undefined,
	delete?: ModelTypes["MacaoBannerSectionDocAccessFields_banners_status_Delete"] | undefined
};
	["MacaoBannerSectionDocAccessFields_banners_status_Create"]: {
		permission: boolean
};
	["MacaoBannerSectionDocAccessFields_banners_status_Read"]: {
		permission: boolean
};
	["MacaoBannerSectionDocAccessFields_banners_status_Update"]: {
		permission: boolean
};
	["MacaoBannerSectionDocAccessFields_banners_status_Delete"]: {
		permission: boolean
};
	["MacaoBannerSectionDocAccessFields_banners_desktop"]: {
		create?: ModelTypes["MacaoBannerSectionDocAccessFields_banners_desktop_Create"] | undefined,
	read?: ModelTypes["MacaoBannerSectionDocAccessFields_banners_desktop_Read"] | undefined,
	update?: ModelTypes["MacaoBannerSectionDocAccessFields_banners_desktop_Update"] | undefined,
	delete?: ModelTypes["MacaoBannerSectionDocAccessFields_banners_desktop_Delete"] | undefined
};
	["MacaoBannerSectionDocAccessFields_banners_desktop_Create"]: {
		permission: boolean
};
	["MacaoBannerSectionDocAccessFields_banners_desktop_Read"]: {
		permission: boolean
};
	["MacaoBannerSectionDocAccessFields_banners_desktop_Update"]: {
		permission: boolean
};
	["MacaoBannerSectionDocAccessFields_banners_desktop_Delete"]: {
		permission: boolean
};
	["MacaoBannerSectionDocAccessFields_banners_bannerStartDate"]: {
		create?: ModelTypes["MacaoBannerSectionDocAccessFields_banners_bannerStartDate_Create"] | undefined,
	read?: ModelTypes["MacaoBannerSectionDocAccessFields_banners_bannerStartDate_Read"] | undefined,
	update?: ModelTypes["MacaoBannerSectionDocAccessFields_banners_bannerStartDate_Update"] | undefined,
	delete?: ModelTypes["MacaoBannerSectionDocAccessFields_banners_bannerStartDate_Delete"] | undefined
};
	["MacaoBannerSectionDocAccessFields_banners_bannerStartDate_Create"]: {
		permission: boolean
};
	["MacaoBannerSectionDocAccessFields_banners_bannerStartDate_Read"]: {
		permission: boolean
};
	["MacaoBannerSectionDocAccessFields_banners_bannerStartDate_Update"]: {
		permission: boolean
};
	["MacaoBannerSectionDocAccessFields_banners_bannerStartDate_Delete"]: {
		permission: boolean
};
	["MacaoBannerSectionDocAccessFields_banners_bannerEndDate"]: {
		create?: ModelTypes["MacaoBannerSectionDocAccessFields_banners_bannerEndDate_Create"] | undefined,
	read?: ModelTypes["MacaoBannerSectionDocAccessFields_banners_bannerEndDate_Read"] | undefined,
	update?: ModelTypes["MacaoBannerSectionDocAccessFields_banners_bannerEndDate_Update"] | undefined,
	delete?: ModelTypes["MacaoBannerSectionDocAccessFields_banners_bannerEndDate_Delete"] | undefined
};
	["MacaoBannerSectionDocAccessFields_banners_bannerEndDate_Create"]: {
		permission: boolean
};
	["MacaoBannerSectionDocAccessFields_banners_bannerEndDate_Read"]: {
		permission: boolean
};
	["MacaoBannerSectionDocAccessFields_banners_bannerEndDate_Update"]: {
		permission: boolean
};
	["MacaoBannerSectionDocAccessFields_banners_bannerEndDate_Delete"]: {
		permission: boolean
};
	["MacaoBannerSectionDocAccessFields_banners_region"]: {
		create?: ModelTypes["MacaoBannerSectionDocAccessFields_banners_region_Create"] | undefined,
	read?: ModelTypes["MacaoBannerSectionDocAccessFields_banners_region_Read"] | undefined,
	update?: ModelTypes["MacaoBannerSectionDocAccessFields_banners_region_Update"] | undefined,
	delete?: ModelTypes["MacaoBannerSectionDocAccessFields_banners_region_Delete"] | undefined
};
	["MacaoBannerSectionDocAccessFields_banners_region_Create"]: {
		permission: boolean
};
	["MacaoBannerSectionDocAccessFields_banners_region_Read"]: {
		permission: boolean
};
	["MacaoBannerSectionDocAccessFields_banners_region_Update"]: {
		permission: boolean
};
	["MacaoBannerSectionDocAccessFields_banners_region_Delete"]: {
		permission: boolean
};
	["MacaoBannerSectionDocAccessFields_banners_id"]: {
		create?: ModelTypes["MacaoBannerSectionDocAccessFields_banners_id_Create"] | undefined,
	read?: ModelTypes["MacaoBannerSectionDocAccessFields_banners_id_Read"] | undefined,
	update?: ModelTypes["MacaoBannerSectionDocAccessFields_banners_id_Update"] | undefined,
	delete?: ModelTypes["MacaoBannerSectionDocAccessFields_banners_id_Delete"] | undefined
};
	["MacaoBannerSectionDocAccessFields_banners_id_Create"]: {
		permission: boolean
};
	["MacaoBannerSectionDocAccessFields_banners_id_Read"]: {
		permission: boolean
};
	["MacaoBannerSectionDocAccessFields_banners_id_Update"]: {
		permission: boolean
};
	["MacaoBannerSectionDocAccessFields_banners_id_Delete"]: {
		permission: boolean
};
	["MacaoBannerSectionDocAccessFields_updatedAt"]: {
		create?: ModelTypes["MacaoBannerSectionDocAccessFields_updatedAt_Create"] | undefined,
	read?: ModelTypes["MacaoBannerSectionDocAccessFields_updatedAt_Read"] | undefined,
	update?: ModelTypes["MacaoBannerSectionDocAccessFields_updatedAt_Update"] | undefined,
	delete?: ModelTypes["MacaoBannerSectionDocAccessFields_updatedAt_Delete"] | undefined
};
	["MacaoBannerSectionDocAccessFields_updatedAt_Create"]: {
		permission: boolean
};
	["MacaoBannerSectionDocAccessFields_updatedAt_Read"]: {
		permission: boolean
};
	["MacaoBannerSectionDocAccessFields_updatedAt_Update"]: {
		permission: boolean
};
	["MacaoBannerSectionDocAccessFields_updatedAt_Delete"]: {
		permission: boolean
};
	["MacaoBannerSectionDocAccessFields_createdAt"]: {
		create?: ModelTypes["MacaoBannerSectionDocAccessFields_createdAt_Create"] | undefined,
	read?: ModelTypes["MacaoBannerSectionDocAccessFields_createdAt_Read"] | undefined,
	update?: ModelTypes["MacaoBannerSectionDocAccessFields_createdAt_Update"] | undefined,
	delete?: ModelTypes["MacaoBannerSectionDocAccessFields_createdAt_Delete"] | undefined
};
	["MacaoBannerSectionDocAccessFields_createdAt_Create"]: {
		permission: boolean
};
	["MacaoBannerSectionDocAccessFields_createdAt_Read"]: {
		permission: boolean
};
	["MacaoBannerSectionDocAccessFields_createdAt_Update"]: {
		permission: boolean
};
	["MacaoBannerSectionDocAccessFields_createdAt_Delete"]: {
		permission: boolean
};
	["MacaoBannerSectionCreateDocAccess"]: {
		permission: boolean,
	where?: ModelTypes["JSONObject"] | undefined
};
	["MacaoBannerSectionReadDocAccess"]: {
		permission: boolean,
	where?: ModelTypes["JSONObject"] | undefined
};
	["MacaoBannerSectionUpdateDocAccess"]: {
		permission: boolean,
	where?: ModelTypes["JSONObject"] | undefined
};
	["MacaoBannerSectionDeleteDocAccess"]: {
		permission: boolean,
	where?: ModelTypes["JSONObject"] | undefined
};
	["Banner"]: {
		id?: string | undefined,
	name: string,
	desktop: ModelTypes["Media"],
	mobile: ModelTypes["Media"],
	bannerStartDate: ModelTypes["DateTime"],
	bannerEndDate: ModelTypes["DateTime"],
	location?: ModelTypes["Banner_Location"] | undefined,
	link?: string | undefined,
	external?: boolean | undefined,
	type?: ModelTypes["Banner_type"] | undefined,
	status?: ModelTypes["Banner_status"] | undefined,
	advertise?: ModelTypes["Banner_Advertise"] | undefined,
	_locations?: string | undefined,
	region?: ModelTypes["Banner_region"] | undefined,
	homePageClick?: number | undefined,
	homePageImpression?: number | undefined,
	latestEventClick?: number | undefined,
	latestEventImpression?: number | undefined,
	editorChoiceClick?: number | undefined,
	editorChoiceImpression?: number | undefined,
	eventListClick?: number | undefined,
	eventListImpression?: number | undefined,
	topTenClick?: number | undefined,
	topTenImpression?: number | undefined,
	miscClick?: number | undefined,
	miscImpression?: number | undefined,
	clickTotal?: number | undefined,
	totalImpression?: number | undefined,
	updatedAt?: ModelTypes["DateTime"] | undefined,
	createdAt?: ModelTypes["DateTime"] | undefined
};
	["Banner_Desktop_where"]: {
	alt?: ModelTypes["Banner_Desktop_alt_operator"] | undefined,
	updatedAt?: ModelTypes["Banner_Desktop_updatedAt_operator"] | undefined,
	createdAt?: ModelTypes["Banner_Desktop_createdAt_operator"] | undefined,
	url?: ModelTypes["Banner_Desktop_url_operator"] | undefined,
	filename?: ModelTypes["Banner_Desktop_filename_operator"] | undefined,
	mimeType?: ModelTypes["Banner_Desktop_mimeType_operator"] | undefined,
	filesize?: ModelTypes["Banner_Desktop_filesize_operator"] | undefined,
	width?: ModelTypes["Banner_Desktop_width_operator"] | undefined,
	height?: ModelTypes["Banner_Desktop_height_operator"] | undefined,
	focalX?: ModelTypes["Banner_Desktop_focalX_operator"] | undefined,
	focalY?: ModelTypes["Banner_Desktop_focalY_operator"] | undefined,
	sizes__small__url?: ModelTypes["Banner_Desktop_sizes__small__url_operator"] | undefined,
	sizes__small__width?: ModelTypes["Banner_Desktop_sizes__small__width_operator"] | undefined,
	sizes__small__height?: ModelTypes["Banner_Desktop_sizes__small__height_operator"] | undefined,
	sizes__small__mimeType?: ModelTypes["Banner_Desktop_sizes__small__mimeType_operator"] | undefined,
	sizes__small__filesize?: ModelTypes["Banner_Desktop_sizes__small__filesize_operator"] | undefined,
	sizes__small__filename?: ModelTypes["Banner_Desktop_sizes__small__filename_operator"] | undefined,
	sizes__medium__url?: ModelTypes["Banner_Desktop_sizes__medium__url_operator"] | undefined,
	sizes__medium__width?: ModelTypes["Banner_Desktop_sizes__medium__width_operator"] | undefined,
	sizes__medium__height?: ModelTypes["Banner_Desktop_sizes__medium__height_operator"] | undefined,
	sizes__medium__mimeType?: ModelTypes["Banner_Desktop_sizes__medium__mimeType_operator"] | undefined,
	sizes__medium__filesize?: ModelTypes["Banner_Desktop_sizes__medium__filesize_operator"] | undefined,
	sizes__medium__filename?: ModelTypes["Banner_Desktop_sizes__medium__filename_operator"] | undefined,
	sizes__large__url?: ModelTypes["Banner_Desktop_sizes__large__url_operator"] | undefined,
	sizes__large__width?: ModelTypes["Banner_Desktop_sizes__large__width_operator"] | undefined,
	sizes__large__height?: ModelTypes["Banner_Desktop_sizes__large__height_operator"] | undefined,
	sizes__large__mimeType?: ModelTypes["Banner_Desktop_sizes__large__mimeType_operator"] | undefined,
	sizes__large__filesize?: ModelTypes["Banner_Desktop_sizes__large__filesize_operator"] | undefined,
	sizes__large__filename?: ModelTypes["Banner_Desktop_sizes__large__filename_operator"] | undefined,
	sizes__small_jpeg__url?: ModelTypes["Banner_Desktop_sizes__small_jpeg__url_operator"] | undefined,
	sizes__small_jpeg__width?: ModelTypes["Banner_Desktop_sizes__small_jpeg__width_operator"] | undefined,
	sizes__small_jpeg__height?: ModelTypes["Banner_Desktop_sizes__small_jpeg__height_operator"] | undefined,
	sizes__small_jpeg__mimeType?: ModelTypes["Banner_Desktop_sizes__small_jpeg__mimeType_operator"] | undefined,
	sizes__small_jpeg__filesize?: ModelTypes["Banner_Desktop_sizes__small_jpeg__filesize_operator"] | undefined,
	sizes__small_jpeg__filename?: ModelTypes["Banner_Desktop_sizes__small_jpeg__filename_operator"] | undefined,
	sizes__medium_jpeg__url?: ModelTypes["Banner_Desktop_sizes__medium_jpeg__url_operator"] | undefined,
	sizes__medium_jpeg__width?: ModelTypes["Banner_Desktop_sizes__medium_jpeg__width_operator"] | undefined,
	sizes__medium_jpeg__height?: ModelTypes["Banner_Desktop_sizes__medium_jpeg__height_operator"] | undefined,
	sizes__medium_jpeg__mimeType?: ModelTypes["Banner_Desktop_sizes__medium_jpeg__mimeType_operator"] | undefined,
	sizes__medium_jpeg__filesize?: ModelTypes["Banner_Desktop_sizes__medium_jpeg__filesize_operator"] | undefined,
	sizes__medium_jpeg__filename?: ModelTypes["Banner_Desktop_sizes__medium_jpeg__filename_operator"] | undefined,
	sizes__large_jpeg__url?: ModelTypes["Banner_Desktop_sizes__large_jpeg__url_operator"] | undefined,
	sizes__large_jpeg__width?: ModelTypes["Banner_Desktop_sizes__large_jpeg__width_operator"] | undefined,
	sizes__large_jpeg__height?: ModelTypes["Banner_Desktop_sizes__large_jpeg__height_operator"] | undefined,
	sizes__large_jpeg__mimeType?: ModelTypes["Banner_Desktop_sizes__large_jpeg__mimeType_operator"] | undefined,
	sizes__large_jpeg__filesize?: ModelTypes["Banner_Desktop_sizes__large_jpeg__filesize_operator"] | undefined,
	sizes__large_jpeg__filename?: ModelTypes["Banner_Desktop_sizes__large_jpeg__filename_operator"] | undefined,
	id?: ModelTypes["Banner_Desktop_id_operator"] | undefined,
	AND?: Array<ModelTypes["Banner_Desktop_where_and"] | undefined> | undefined,
	OR?: Array<ModelTypes["Banner_Desktop_where_or"] | undefined> | undefined
};
	["Banner_Desktop_alt_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Banner_Desktop_updatedAt_operator"]: {
	equals?: ModelTypes["DateTime"] | undefined,
	not_equals?: ModelTypes["DateTime"] | undefined,
	greater_than_equal?: ModelTypes["DateTime"] | undefined,
	greater_than?: ModelTypes["DateTime"] | undefined,
	less_than_equal?: ModelTypes["DateTime"] | undefined,
	less_than?: ModelTypes["DateTime"] | undefined,
	like?: ModelTypes["DateTime"] | undefined,
	exists?: boolean | undefined
};
	["Banner_Desktop_createdAt_operator"]: {
	equals?: ModelTypes["DateTime"] | undefined,
	not_equals?: ModelTypes["DateTime"] | undefined,
	greater_than_equal?: ModelTypes["DateTime"] | undefined,
	greater_than?: ModelTypes["DateTime"] | undefined,
	less_than_equal?: ModelTypes["DateTime"] | undefined,
	less_than?: ModelTypes["DateTime"] | undefined,
	like?: ModelTypes["DateTime"] | undefined,
	exists?: boolean | undefined
};
	["Banner_Desktop_url_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Banner_Desktop_filename_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Banner_Desktop_mimeType_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Banner_Desktop_filesize_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Banner_Desktop_width_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Banner_Desktop_height_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Banner_Desktop_focalX_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Banner_Desktop_focalY_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Banner_Desktop_sizes__small__url_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Banner_Desktop_sizes__small__width_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Banner_Desktop_sizes__small__height_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Banner_Desktop_sizes__small__mimeType_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Banner_Desktop_sizes__small__filesize_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Banner_Desktop_sizes__small__filename_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Banner_Desktop_sizes__medium__url_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Banner_Desktop_sizes__medium__width_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Banner_Desktop_sizes__medium__height_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Banner_Desktop_sizes__medium__mimeType_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Banner_Desktop_sizes__medium__filesize_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Banner_Desktop_sizes__medium__filename_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Banner_Desktop_sizes__large__url_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Banner_Desktop_sizes__large__width_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Banner_Desktop_sizes__large__height_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Banner_Desktop_sizes__large__mimeType_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Banner_Desktop_sizes__large__filesize_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Banner_Desktop_sizes__large__filename_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Banner_Desktop_sizes__small_jpeg__url_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Banner_Desktop_sizes__small_jpeg__width_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Banner_Desktop_sizes__small_jpeg__height_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Banner_Desktop_sizes__small_jpeg__mimeType_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Banner_Desktop_sizes__small_jpeg__filesize_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Banner_Desktop_sizes__small_jpeg__filename_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Banner_Desktop_sizes__medium_jpeg__url_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Banner_Desktop_sizes__medium_jpeg__width_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Banner_Desktop_sizes__medium_jpeg__height_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Banner_Desktop_sizes__medium_jpeg__mimeType_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Banner_Desktop_sizes__medium_jpeg__filesize_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Banner_Desktop_sizes__medium_jpeg__filename_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Banner_Desktop_sizes__large_jpeg__url_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Banner_Desktop_sizes__large_jpeg__width_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Banner_Desktop_sizes__large_jpeg__height_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Banner_Desktop_sizes__large_jpeg__mimeType_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Banner_Desktop_sizes__large_jpeg__filesize_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Banner_Desktop_sizes__large_jpeg__filename_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Banner_Desktop_id_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Banner_Desktop_where_and"]: {
	alt?: ModelTypes["Banner_Desktop_alt_operator"] | undefined,
	updatedAt?: ModelTypes["Banner_Desktop_updatedAt_operator"] | undefined,
	createdAt?: ModelTypes["Banner_Desktop_createdAt_operator"] | undefined,
	url?: ModelTypes["Banner_Desktop_url_operator"] | undefined,
	filename?: ModelTypes["Banner_Desktop_filename_operator"] | undefined,
	mimeType?: ModelTypes["Banner_Desktop_mimeType_operator"] | undefined,
	filesize?: ModelTypes["Banner_Desktop_filesize_operator"] | undefined,
	width?: ModelTypes["Banner_Desktop_width_operator"] | undefined,
	height?: ModelTypes["Banner_Desktop_height_operator"] | undefined,
	focalX?: ModelTypes["Banner_Desktop_focalX_operator"] | undefined,
	focalY?: ModelTypes["Banner_Desktop_focalY_operator"] | undefined,
	sizes__small__url?: ModelTypes["Banner_Desktop_sizes__small__url_operator"] | undefined,
	sizes__small__width?: ModelTypes["Banner_Desktop_sizes__small__width_operator"] | undefined,
	sizes__small__height?: ModelTypes["Banner_Desktop_sizes__small__height_operator"] | undefined,
	sizes__small__mimeType?: ModelTypes["Banner_Desktop_sizes__small__mimeType_operator"] | undefined,
	sizes__small__filesize?: ModelTypes["Banner_Desktop_sizes__small__filesize_operator"] | undefined,
	sizes__small__filename?: ModelTypes["Banner_Desktop_sizes__small__filename_operator"] | undefined,
	sizes__medium__url?: ModelTypes["Banner_Desktop_sizes__medium__url_operator"] | undefined,
	sizes__medium__width?: ModelTypes["Banner_Desktop_sizes__medium__width_operator"] | undefined,
	sizes__medium__height?: ModelTypes["Banner_Desktop_sizes__medium__height_operator"] | undefined,
	sizes__medium__mimeType?: ModelTypes["Banner_Desktop_sizes__medium__mimeType_operator"] | undefined,
	sizes__medium__filesize?: ModelTypes["Banner_Desktop_sizes__medium__filesize_operator"] | undefined,
	sizes__medium__filename?: ModelTypes["Banner_Desktop_sizes__medium__filename_operator"] | undefined,
	sizes__large__url?: ModelTypes["Banner_Desktop_sizes__large__url_operator"] | undefined,
	sizes__large__width?: ModelTypes["Banner_Desktop_sizes__large__width_operator"] | undefined,
	sizes__large__height?: ModelTypes["Banner_Desktop_sizes__large__height_operator"] | undefined,
	sizes__large__mimeType?: ModelTypes["Banner_Desktop_sizes__large__mimeType_operator"] | undefined,
	sizes__large__filesize?: ModelTypes["Banner_Desktop_sizes__large__filesize_operator"] | undefined,
	sizes__large__filename?: ModelTypes["Banner_Desktop_sizes__large__filename_operator"] | undefined,
	sizes__small_jpeg__url?: ModelTypes["Banner_Desktop_sizes__small_jpeg__url_operator"] | undefined,
	sizes__small_jpeg__width?: ModelTypes["Banner_Desktop_sizes__small_jpeg__width_operator"] | undefined,
	sizes__small_jpeg__height?: ModelTypes["Banner_Desktop_sizes__small_jpeg__height_operator"] | undefined,
	sizes__small_jpeg__mimeType?: ModelTypes["Banner_Desktop_sizes__small_jpeg__mimeType_operator"] | undefined,
	sizes__small_jpeg__filesize?: ModelTypes["Banner_Desktop_sizes__small_jpeg__filesize_operator"] | undefined,
	sizes__small_jpeg__filename?: ModelTypes["Banner_Desktop_sizes__small_jpeg__filename_operator"] | undefined,
	sizes__medium_jpeg__url?: ModelTypes["Banner_Desktop_sizes__medium_jpeg__url_operator"] | undefined,
	sizes__medium_jpeg__width?: ModelTypes["Banner_Desktop_sizes__medium_jpeg__width_operator"] | undefined,
	sizes__medium_jpeg__height?: ModelTypes["Banner_Desktop_sizes__medium_jpeg__height_operator"] | undefined,
	sizes__medium_jpeg__mimeType?: ModelTypes["Banner_Desktop_sizes__medium_jpeg__mimeType_operator"] | undefined,
	sizes__medium_jpeg__filesize?: ModelTypes["Banner_Desktop_sizes__medium_jpeg__filesize_operator"] | undefined,
	sizes__medium_jpeg__filename?: ModelTypes["Banner_Desktop_sizes__medium_jpeg__filename_operator"] | undefined,
	sizes__large_jpeg__url?: ModelTypes["Banner_Desktop_sizes__large_jpeg__url_operator"] | undefined,
	sizes__large_jpeg__width?: ModelTypes["Banner_Desktop_sizes__large_jpeg__width_operator"] | undefined,
	sizes__large_jpeg__height?: ModelTypes["Banner_Desktop_sizes__large_jpeg__height_operator"] | undefined,
	sizes__large_jpeg__mimeType?: ModelTypes["Banner_Desktop_sizes__large_jpeg__mimeType_operator"] | undefined,
	sizes__large_jpeg__filesize?: ModelTypes["Banner_Desktop_sizes__large_jpeg__filesize_operator"] | undefined,
	sizes__large_jpeg__filename?: ModelTypes["Banner_Desktop_sizes__large_jpeg__filename_operator"] | undefined,
	id?: ModelTypes["Banner_Desktop_id_operator"] | undefined,
	AND?: Array<ModelTypes["Banner_Desktop_where_and"] | undefined> | undefined,
	OR?: Array<ModelTypes["Banner_Desktop_where_or"] | undefined> | undefined
};
	["Banner_Desktop_where_or"]: {
	alt?: ModelTypes["Banner_Desktop_alt_operator"] | undefined,
	updatedAt?: ModelTypes["Banner_Desktop_updatedAt_operator"] | undefined,
	createdAt?: ModelTypes["Banner_Desktop_createdAt_operator"] | undefined,
	url?: ModelTypes["Banner_Desktop_url_operator"] | undefined,
	filename?: ModelTypes["Banner_Desktop_filename_operator"] | undefined,
	mimeType?: ModelTypes["Banner_Desktop_mimeType_operator"] | undefined,
	filesize?: ModelTypes["Banner_Desktop_filesize_operator"] | undefined,
	width?: ModelTypes["Banner_Desktop_width_operator"] | undefined,
	height?: ModelTypes["Banner_Desktop_height_operator"] | undefined,
	focalX?: ModelTypes["Banner_Desktop_focalX_operator"] | undefined,
	focalY?: ModelTypes["Banner_Desktop_focalY_operator"] | undefined,
	sizes__small__url?: ModelTypes["Banner_Desktop_sizes__small__url_operator"] | undefined,
	sizes__small__width?: ModelTypes["Banner_Desktop_sizes__small__width_operator"] | undefined,
	sizes__small__height?: ModelTypes["Banner_Desktop_sizes__small__height_operator"] | undefined,
	sizes__small__mimeType?: ModelTypes["Banner_Desktop_sizes__small__mimeType_operator"] | undefined,
	sizes__small__filesize?: ModelTypes["Banner_Desktop_sizes__small__filesize_operator"] | undefined,
	sizes__small__filename?: ModelTypes["Banner_Desktop_sizes__small__filename_operator"] | undefined,
	sizes__medium__url?: ModelTypes["Banner_Desktop_sizes__medium__url_operator"] | undefined,
	sizes__medium__width?: ModelTypes["Banner_Desktop_sizes__medium__width_operator"] | undefined,
	sizes__medium__height?: ModelTypes["Banner_Desktop_sizes__medium__height_operator"] | undefined,
	sizes__medium__mimeType?: ModelTypes["Banner_Desktop_sizes__medium__mimeType_operator"] | undefined,
	sizes__medium__filesize?: ModelTypes["Banner_Desktop_sizes__medium__filesize_operator"] | undefined,
	sizes__medium__filename?: ModelTypes["Banner_Desktop_sizes__medium__filename_operator"] | undefined,
	sizes__large__url?: ModelTypes["Banner_Desktop_sizes__large__url_operator"] | undefined,
	sizes__large__width?: ModelTypes["Banner_Desktop_sizes__large__width_operator"] | undefined,
	sizes__large__height?: ModelTypes["Banner_Desktop_sizes__large__height_operator"] | undefined,
	sizes__large__mimeType?: ModelTypes["Banner_Desktop_sizes__large__mimeType_operator"] | undefined,
	sizes__large__filesize?: ModelTypes["Banner_Desktop_sizes__large__filesize_operator"] | undefined,
	sizes__large__filename?: ModelTypes["Banner_Desktop_sizes__large__filename_operator"] | undefined,
	sizes__small_jpeg__url?: ModelTypes["Banner_Desktop_sizes__small_jpeg__url_operator"] | undefined,
	sizes__small_jpeg__width?: ModelTypes["Banner_Desktop_sizes__small_jpeg__width_operator"] | undefined,
	sizes__small_jpeg__height?: ModelTypes["Banner_Desktop_sizes__small_jpeg__height_operator"] | undefined,
	sizes__small_jpeg__mimeType?: ModelTypes["Banner_Desktop_sizes__small_jpeg__mimeType_operator"] | undefined,
	sizes__small_jpeg__filesize?: ModelTypes["Banner_Desktop_sizes__small_jpeg__filesize_operator"] | undefined,
	sizes__small_jpeg__filename?: ModelTypes["Banner_Desktop_sizes__small_jpeg__filename_operator"] | undefined,
	sizes__medium_jpeg__url?: ModelTypes["Banner_Desktop_sizes__medium_jpeg__url_operator"] | undefined,
	sizes__medium_jpeg__width?: ModelTypes["Banner_Desktop_sizes__medium_jpeg__width_operator"] | undefined,
	sizes__medium_jpeg__height?: ModelTypes["Banner_Desktop_sizes__medium_jpeg__height_operator"] | undefined,
	sizes__medium_jpeg__mimeType?: ModelTypes["Banner_Desktop_sizes__medium_jpeg__mimeType_operator"] | undefined,
	sizes__medium_jpeg__filesize?: ModelTypes["Banner_Desktop_sizes__medium_jpeg__filesize_operator"] | undefined,
	sizes__medium_jpeg__filename?: ModelTypes["Banner_Desktop_sizes__medium_jpeg__filename_operator"] | undefined,
	sizes__large_jpeg__url?: ModelTypes["Banner_Desktop_sizes__large_jpeg__url_operator"] | undefined,
	sizes__large_jpeg__width?: ModelTypes["Banner_Desktop_sizes__large_jpeg__width_operator"] | undefined,
	sizes__large_jpeg__height?: ModelTypes["Banner_Desktop_sizes__large_jpeg__height_operator"] | undefined,
	sizes__large_jpeg__mimeType?: ModelTypes["Banner_Desktop_sizes__large_jpeg__mimeType_operator"] | undefined,
	sizes__large_jpeg__filesize?: ModelTypes["Banner_Desktop_sizes__large_jpeg__filesize_operator"] | undefined,
	sizes__large_jpeg__filename?: ModelTypes["Banner_Desktop_sizes__large_jpeg__filename_operator"] | undefined,
	id?: ModelTypes["Banner_Desktop_id_operator"] | undefined,
	AND?: Array<ModelTypes["Banner_Desktop_where_and"] | undefined> | undefined,
	OR?: Array<ModelTypes["Banner_Desktop_where_or"] | undefined> | undefined
};
	["Banner_Mobile_where"]: {
	alt?: ModelTypes["Banner_Mobile_alt_operator"] | undefined,
	updatedAt?: ModelTypes["Banner_Mobile_updatedAt_operator"] | undefined,
	createdAt?: ModelTypes["Banner_Mobile_createdAt_operator"] | undefined,
	url?: ModelTypes["Banner_Mobile_url_operator"] | undefined,
	filename?: ModelTypes["Banner_Mobile_filename_operator"] | undefined,
	mimeType?: ModelTypes["Banner_Mobile_mimeType_operator"] | undefined,
	filesize?: ModelTypes["Banner_Mobile_filesize_operator"] | undefined,
	width?: ModelTypes["Banner_Mobile_width_operator"] | undefined,
	height?: ModelTypes["Banner_Mobile_height_operator"] | undefined,
	focalX?: ModelTypes["Banner_Mobile_focalX_operator"] | undefined,
	focalY?: ModelTypes["Banner_Mobile_focalY_operator"] | undefined,
	sizes__small__url?: ModelTypes["Banner_Mobile_sizes__small__url_operator"] | undefined,
	sizes__small__width?: ModelTypes["Banner_Mobile_sizes__small__width_operator"] | undefined,
	sizes__small__height?: ModelTypes["Banner_Mobile_sizes__small__height_operator"] | undefined,
	sizes__small__mimeType?: ModelTypes["Banner_Mobile_sizes__small__mimeType_operator"] | undefined,
	sizes__small__filesize?: ModelTypes["Banner_Mobile_sizes__small__filesize_operator"] | undefined,
	sizes__small__filename?: ModelTypes["Banner_Mobile_sizes__small__filename_operator"] | undefined,
	sizes__medium__url?: ModelTypes["Banner_Mobile_sizes__medium__url_operator"] | undefined,
	sizes__medium__width?: ModelTypes["Banner_Mobile_sizes__medium__width_operator"] | undefined,
	sizes__medium__height?: ModelTypes["Banner_Mobile_sizes__medium__height_operator"] | undefined,
	sizes__medium__mimeType?: ModelTypes["Banner_Mobile_sizes__medium__mimeType_operator"] | undefined,
	sizes__medium__filesize?: ModelTypes["Banner_Mobile_sizes__medium__filesize_operator"] | undefined,
	sizes__medium__filename?: ModelTypes["Banner_Mobile_sizes__medium__filename_operator"] | undefined,
	sizes__large__url?: ModelTypes["Banner_Mobile_sizes__large__url_operator"] | undefined,
	sizes__large__width?: ModelTypes["Banner_Mobile_sizes__large__width_operator"] | undefined,
	sizes__large__height?: ModelTypes["Banner_Mobile_sizes__large__height_operator"] | undefined,
	sizes__large__mimeType?: ModelTypes["Banner_Mobile_sizes__large__mimeType_operator"] | undefined,
	sizes__large__filesize?: ModelTypes["Banner_Mobile_sizes__large__filesize_operator"] | undefined,
	sizes__large__filename?: ModelTypes["Banner_Mobile_sizes__large__filename_operator"] | undefined,
	sizes__small_jpeg__url?: ModelTypes["Banner_Mobile_sizes__small_jpeg__url_operator"] | undefined,
	sizes__small_jpeg__width?: ModelTypes["Banner_Mobile_sizes__small_jpeg__width_operator"] | undefined,
	sizes__small_jpeg__height?: ModelTypes["Banner_Mobile_sizes__small_jpeg__height_operator"] | undefined,
	sizes__small_jpeg__mimeType?: ModelTypes["Banner_Mobile_sizes__small_jpeg__mimeType_operator"] | undefined,
	sizes__small_jpeg__filesize?: ModelTypes["Banner_Mobile_sizes__small_jpeg__filesize_operator"] | undefined,
	sizes__small_jpeg__filename?: ModelTypes["Banner_Mobile_sizes__small_jpeg__filename_operator"] | undefined,
	sizes__medium_jpeg__url?: ModelTypes["Banner_Mobile_sizes__medium_jpeg__url_operator"] | undefined,
	sizes__medium_jpeg__width?: ModelTypes["Banner_Mobile_sizes__medium_jpeg__width_operator"] | undefined,
	sizes__medium_jpeg__height?: ModelTypes["Banner_Mobile_sizes__medium_jpeg__height_operator"] | undefined,
	sizes__medium_jpeg__mimeType?: ModelTypes["Banner_Mobile_sizes__medium_jpeg__mimeType_operator"] | undefined,
	sizes__medium_jpeg__filesize?: ModelTypes["Banner_Mobile_sizes__medium_jpeg__filesize_operator"] | undefined,
	sizes__medium_jpeg__filename?: ModelTypes["Banner_Mobile_sizes__medium_jpeg__filename_operator"] | undefined,
	sizes__large_jpeg__url?: ModelTypes["Banner_Mobile_sizes__large_jpeg__url_operator"] | undefined,
	sizes__large_jpeg__width?: ModelTypes["Banner_Mobile_sizes__large_jpeg__width_operator"] | undefined,
	sizes__large_jpeg__height?: ModelTypes["Banner_Mobile_sizes__large_jpeg__height_operator"] | undefined,
	sizes__large_jpeg__mimeType?: ModelTypes["Banner_Mobile_sizes__large_jpeg__mimeType_operator"] | undefined,
	sizes__large_jpeg__filesize?: ModelTypes["Banner_Mobile_sizes__large_jpeg__filesize_operator"] | undefined,
	sizes__large_jpeg__filename?: ModelTypes["Banner_Mobile_sizes__large_jpeg__filename_operator"] | undefined,
	id?: ModelTypes["Banner_Mobile_id_operator"] | undefined,
	AND?: Array<ModelTypes["Banner_Mobile_where_and"] | undefined> | undefined,
	OR?: Array<ModelTypes["Banner_Mobile_where_or"] | undefined> | undefined
};
	["Banner_Mobile_alt_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Banner_Mobile_updatedAt_operator"]: {
	equals?: ModelTypes["DateTime"] | undefined,
	not_equals?: ModelTypes["DateTime"] | undefined,
	greater_than_equal?: ModelTypes["DateTime"] | undefined,
	greater_than?: ModelTypes["DateTime"] | undefined,
	less_than_equal?: ModelTypes["DateTime"] | undefined,
	less_than?: ModelTypes["DateTime"] | undefined,
	like?: ModelTypes["DateTime"] | undefined,
	exists?: boolean | undefined
};
	["Banner_Mobile_createdAt_operator"]: {
	equals?: ModelTypes["DateTime"] | undefined,
	not_equals?: ModelTypes["DateTime"] | undefined,
	greater_than_equal?: ModelTypes["DateTime"] | undefined,
	greater_than?: ModelTypes["DateTime"] | undefined,
	less_than_equal?: ModelTypes["DateTime"] | undefined,
	less_than?: ModelTypes["DateTime"] | undefined,
	like?: ModelTypes["DateTime"] | undefined,
	exists?: boolean | undefined
};
	["Banner_Mobile_url_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Banner_Mobile_filename_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Banner_Mobile_mimeType_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Banner_Mobile_filesize_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Banner_Mobile_width_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Banner_Mobile_height_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Banner_Mobile_focalX_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Banner_Mobile_focalY_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Banner_Mobile_sizes__small__url_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Banner_Mobile_sizes__small__width_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Banner_Mobile_sizes__small__height_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Banner_Mobile_sizes__small__mimeType_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Banner_Mobile_sizes__small__filesize_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Banner_Mobile_sizes__small__filename_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Banner_Mobile_sizes__medium__url_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Banner_Mobile_sizes__medium__width_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Banner_Mobile_sizes__medium__height_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Banner_Mobile_sizes__medium__mimeType_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Banner_Mobile_sizes__medium__filesize_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Banner_Mobile_sizes__medium__filename_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Banner_Mobile_sizes__large__url_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Banner_Mobile_sizes__large__width_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Banner_Mobile_sizes__large__height_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Banner_Mobile_sizes__large__mimeType_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Banner_Mobile_sizes__large__filesize_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Banner_Mobile_sizes__large__filename_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Banner_Mobile_sizes__small_jpeg__url_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Banner_Mobile_sizes__small_jpeg__width_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Banner_Mobile_sizes__small_jpeg__height_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Banner_Mobile_sizes__small_jpeg__mimeType_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Banner_Mobile_sizes__small_jpeg__filesize_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Banner_Mobile_sizes__small_jpeg__filename_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Banner_Mobile_sizes__medium_jpeg__url_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Banner_Mobile_sizes__medium_jpeg__width_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Banner_Mobile_sizes__medium_jpeg__height_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Banner_Mobile_sizes__medium_jpeg__mimeType_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Banner_Mobile_sizes__medium_jpeg__filesize_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Banner_Mobile_sizes__medium_jpeg__filename_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Banner_Mobile_sizes__large_jpeg__url_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Banner_Mobile_sizes__large_jpeg__width_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Banner_Mobile_sizes__large_jpeg__height_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Banner_Mobile_sizes__large_jpeg__mimeType_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Banner_Mobile_sizes__large_jpeg__filesize_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Banner_Mobile_sizes__large_jpeg__filename_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Banner_Mobile_id_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Banner_Mobile_where_and"]: {
	alt?: ModelTypes["Banner_Mobile_alt_operator"] | undefined,
	updatedAt?: ModelTypes["Banner_Mobile_updatedAt_operator"] | undefined,
	createdAt?: ModelTypes["Banner_Mobile_createdAt_operator"] | undefined,
	url?: ModelTypes["Banner_Mobile_url_operator"] | undefined,
	filename?: ModelTypes["Banner_Mobile_filename_operator"] | undefined,
	mimeType?: ModelTypes["Banner_Mobile_mimeType_operator"] | undefined,
	filesize?: ModelTypes["Banner_Mobile_filesize_operator"] | undefined,
	width?: ModelTypes["Banner_Mobile_width_operator"] | undefined,
	height?: ModelTypes["Banner_Mobile_height_operator"] | undefined,
	focalX?: ModelTypes["Banner_Mobile_focalX_operator"] | undefined,
	focalY?: ModelTypes["Banner_Mobile_focalY_operator"] | undefined,
	sizes__small__url?: ModelTypes["Banner_Mobile_sizes__small__url_operator"] | undefined,
	sizes__small__width?: ModelTypes["Banner_Mobile_sizes__small__width_operator"] | undefined,
	sizes__small__height?: ModelTypes["Banner_Mobile_sizes__small__height_operator"] | undefined,
	sizes__small__mimeType?: ModelTypes["Banner_Mobile_sizes__small__mimeType_operator"] | undefined,
	sizes__small__filesize?: ModelTypes["Banner_Mobile_sizes__small__filesize_operator"] | undefined,
	sizes__small__filename?: ModelTypes["Banner_Mobile_sizes__small__filename_operator"] | undefined,
	sizes__medium__url?: ModelTypes["Banner_Mobile_sizes__medium__url_operator"] | undefined,
	sizes__medium__width?: ModelTypes["Banner_Mobile_sizes__medium__width_operator"] | undefined,
	sizes__medium__height?: ModelTypes["Banner_Mobile_sizes__medium__height_operator"] | undefined,
	sizes__medium__mimeType?: ModelTypes["Banner_Mobile_sizes__medium__mimeType_operator"] | undefined,
	sizes__medium__filesize?: ModelTypes["Banner_Mobile_sizes__medium__filesize_operator"] | undefined,
	sizes__medium__filename?: ModelTypes["Banner_Mobile_sizes__medium__filename_operator"] | undefined,
	sizes__large__url?: ModelTypes["Banner_Mobile_sizes__large__url_operator"] | undefined,
	sizes__large__width?: ModelTypes["Banner_Mobile_sizes__large__width_operator"] | undefined,
	sizes__large__height?: ModelTypes["Banner_Mobile_sizes__large__height_operator"] | undefined,
	sizes__large__mimeType?: ModelTypes["Banner_Mobile_sizes__large__mimeType_operator"] | undefined,
	sizes__large__filesize?: ModelTypes["Banner_Mobile_sizes__large__filesize_operator"] | undefined,
	sizes__large__filename?: ModelTypes["Banner_Mobile_sizes__large__filename_operator"] | undefined,
	sizes__small_jpeg__url?: ModelTypes["Banner_Mobile_sizes__small_jpeg__url_operator"] | undefined,
	sizes__small_jpeg__width?: ModelTypes["Banner_Mobile_sizes__small_jpeg__width_operator"] | undefined,
	sizes__small_jpeg__height?: ModelTypes["Banner_Mobile_sizes__small_jpeg__height_operator"] | undefined,
	sizes__small_jpeg__mimeType?: ModelTypes["Banner_Mobile_sizes__small_jpeg__mimeType_operator"] | undefined,
	sizes__small_jpeg__filesize?: ModelTypes["Banner_Mobile_sizes__small_jpeg__filesize_operator"] | undefined,
	sizes__small_jpeg__filename?: ModelTypes["Banner_Mobile_sizes__small_jpeg__filename_operator"] | undefined,
	sizes__medium_jpeg__url?: ModelTypes["Banner_Mobile_sizes__medium_jpeg__url_operator"] | undefined,
	sizes__medium_jpeg__width?: ModelTypes["Banner_Mobile_sizes__medium_jpeg__width_operator"] | undefined,
	sizes__medium_jpeg__height?: ModelTypes["Banner_Mobile_sizes__medium_jpeg__height_operator"] | undefined,
	sizes__medium_jpeg__mimeType?: ModelTypes["Banner_Mobile_sizes__medium_jpeg__mimeType_operator"] | undefined,
	sizes__medium_jpeg__filesize?: ModelTypes["Banner_Mobile_sizes__medium_jpeg__filesize_operator"] | undefined,
	sizes__medium_jpeg__filename?: ModelTypes["Banner_Mobile_sizes__medium_jpeg__filename_operator"] | undefined,
	sizes__large_jpeg__url?: ModelTypes["Banner_Mobile_sizes__large_jpeg__url_operator"] | undefined,
	sizes__large_jpeg__width?: ModelTypes["Banner_Mobile_sizes__large_jpeg__width_operator"] | undefined,
	sizes__large_jpeg__height?: ModelTypes["Banner_Mobile_sizes__large_jpeg__height_operator"] | undefined,
	sizes__large_jpeg__mimeType?: ModelTypes["Banner_Mobile_sizes__large_jpeg__mimeType_operator"] | undefined,
	sizes__large_jpeg__filesize?: ModelTypes["Banner_Mobile_sizes__large_jpeg__filesize_operator"] | undefined,
	sizes__large_jpeg__filename?: ModelTypes["Banner_Mobile_sizes__large_jpeg__filename_operator"] | undefined,
	id?: ModelTypes["Banner_Mobile_id_operator"] | undefined,
	AND?: Array<ModelTypes["Banner_Mobile_where_and"] | undefined> | undefined,
	OR?: Array<ModelTypes["Banner_Mobile_where_or"] | undefined> | undefined
};
	["Banner_Mobile_where_or"]: {
	alt?: ModelTypes["Banner_Mobile_alt_operator"] | undefined,
	updatedAt?: ModelTypes["Banner_Mobile_updatedAt_operator"] | undefined,
	createdAt?: ModelTypes["Banner_Mobile_createdAt_operator"] | undefined,
	url?: ModelTypes["Banner_Mobile_url_operator"] | undefined,
	filename?: ModelTypes["Banner_Mobile_filename_operator"] | undefined,
	mimeType?: ModelTypes["Banner_Mobile_mimeType_operator"] | undefined,
	filesize?: ModelTypes["Banner_Mobile_filesize_operator"] | undefined,
	width?: ModelTypes["Banner_Mobile_width_operator"] | undefined,
	height?: ModelTypes["Banner_Mobile_height_operator"] | undefined,
	focalX?: ModelTypes["Banner_Mobile_focalX_operator"] | undefined,
	focalY?: ModelTypes["Banner_Mobile_focalY_operator"] | undefined,
	sizes__small__url?: ModelTypes["Banner_Mobile_sizes__small__url_operator"] | undefined,
	sizes__small__width?: ModelTypes["Banner_Mobile_sizes__small__width_operator"] | undefined,
	sizes__small__height?: ModelTypes["Banner_Mobile_sizes__small__height_operator"] | undefined,
	sizes__small__mimeType?: ModelTypes["Banner_Mobile_sizes__small__mimeType_operator"] | undefined,
	sizes__small__filesize?: ModelTypes["Banner_Mobile_sizes__small__filesize_operator"] | undefined,
	sizes__small__filename?: ModelTypes["Banner_Mobile_sizes__small__filename_operator"] | undefined,
	sizes__medium__url?: ModelTypes["Banner_Mobile_sizes__medium__url_operator"] | undefined,
	sizes__medium__width?: ModelTypes["Banner_Mobile_sizes__medium__width_operator"] | undefined,
	sizes__medium__height?: ModelTypes["Banner_Mobile_sizes__medium__height_operator"] | undefined,
	sizes__medium__mimeType?: ModelTypes["Banner_Mobile_sizes__medium__mimeType_operator"] | undefined,
	sizes__medium__filesize?: ModelTypes["Banner_Mobile_sizes__medium__filesize_operator"] | undefined,
	sizes__medium__filename?: ModelTypes["Banner_Mobile_sizes__medium__filename_operator"] | undefined,
	sizes__large__url?: ModelTypes["Banner_Mobile_sizes__large__url_operator"] | undefined,
	sizes__large__width?: ModelTypes["Banner_Mobile_sizes__large__width_operator"] | undefined,
	sizes__large__height?: ModelTypes["Banner_Mobile_sizes__large__height_operator"] | undefined,
	sizes__large__mimeType?: ModelTypes["Banner_Mobile_sizes__large__mimeType_operator"] | undefined,
	sizes__large__filesize?: ModelTypes["Banner_Mobile_sizes__large__filesize_operator"] | undefined,
	sizes__large__filename?: ModelTypes["Banner_Mobile_sizes__large__filename_operator"] | undefined,
	sizes__small_jpeg__url?: ModelTypes["Banner_Mobile_sizes__small_jpeg__url_operator"] | undefined,
	sizes__small_jpeg__width?: ModelTypes["Banner_Mobile_sizes__small_jpeg__width_operator"] | undefined,
	sizes__small_jpeg__height?: ModelTypes["Banner_Mobile_sizes__small_jpeg__height_operator"] | undefined,
	sizes__small_jpeg__mimeType?: ModelTypes["Banner_Mobile_sizes__small_jpeg__mimeType_operator"] | undefined,
	sizes__small_jpeg__filesize?: ModelTypes["Banner_Mobile_sizes__small_jpeg__filesize_operator"] | undefined,
	sizes__small_jpeg__filename?: ModelTypes["Banner_Mobile_sizes__small_jpeg__filename_operator"] | undefined,
	sizes__medium_jpeg__url?: ModelTypes["Banner_Mobile_sizes__medium_jpeg__url_operator"] | undefined,
	sizes__medium_jpeg__width?: ModelTypes["Banner_Mobile_sizes__medium_jpeg__width_operator"] | undefined,
	sizes__medium_jpeg__height?: ModelTypes["Banner_Mobile_sizes__medium_jpeg__height_operator"] | undefined,
	sizes__medium_jpeg__mimeType?: ModelTypes["Banner_Mobile_sizes__medium_jpeg__mimeType_operator"] | undefined,
	sizes__medium_jpeg__filesize?: ModelTypes["Banner_Mobile_sizes__medium_jpeg__filesize_operator"] | undefined,
	sizes__medium_jpeg__filename?: ModelTypes["Banner_Mobile_sizes__medium_jpeg__filename_operator"] | undefined,
	sizes__large_jpeg__url?: ModelTypes["Banner_Mobile_sizes__large_jpeg__url_operator"] | undefined,
	sizes__large_jpeg__width?: ModelTypes["Banner_Mobile_sizes__large_jpeg__width_operator"] | undefined,
	sizes__large_jpeg__height?: ModelTypes["Banner_Mobile_sizes__large_jpeg__height_operator"] | undefined,
	sizes__large_jpeg__mimeType?: ModelTypes["Banner_Mobile_sizes__large_jpeg__mimeType_operator"] | undefined,
	sizes__large_jpeg__filesize?: ModelTypes["Banner_Mobile_sizes__large_jpeg__filesize_operator"] | undefined,
	sizes__large_jpeg__filename?: ModelTypes["Banner_Mobile_sizes__large_jpeg__filename_operator"] | undefined,
	id?: ModelTypes["Banner_Mobile_id_operator"] | undefined,
	AND?: Array<ModelTypes["Banner_Mobile_where_and"] | undefined> | undefined,
	OR?: Array<ModelTypes["Banner_Mobile_where_or"] | undefined> | undefined
};
	["Banner_Location"]: {
		page?: Array<ModelTypes["Banner_Location_page"]> | undefined,
	eventRankingList?: Array<ModelTypes["EventRanking"]> | undefined,
	locationRankingList?: Array<ModelTypes["LocationRanking"]> | undefined,
	categories?: Array<ModelTypes["EventCategory"]> | undefined,
	time?: boolean | undefined,
	district?: boolean | undefined,
	audience?: boolean | undefined,
	multipleCriteriaSearch?: boolean | undefined
};
	["Banner_Location_page"]:Banner_Location_page;
	["Banner_type"]:Banner_type;
	["Banner_status"]:Banner_status;
	["Banner_Advertise"]: {
		campaign?: ModelTypes["Campaign"] | undefined,
	invoiceDescription?: string | undefined,
	standardPrice?: number | undefined,
	discount?: number | undefined,
	timableCost?: number | undefined,
	remark?: string | undefined,
	public?: boolean | undefined
};
	["Campaign"]: {
		id?: string | undefined,
	creator: ModelTypes["Campaign_Creator_Relationship"],
	invoiceNumber?: string | undefined,
	quotationNumber?: string | undefined,
	campaignName: string,
	client: string,
	contactPerson: string,
	email: string,
	phone: string,
	agentName?: string | undefined,
	agentCommission?: number | undefined,
	agentOrderNumber?: string | undefined,
	_firstPublishedTime?: ModelTypes["DateTime"] | undefined,
	updatedAt?: ModelTypes["DateTime"] | undefined,
	createdAt?: ModelTypes["DateTime"] | undefined
};
	["Campaign_Creator_Relationship"]: {
		relationTo?: ModelTypes["Campaign_Creator_RelationTo"] | undefined,
	value?: ModelTypes["Campaign_Creator"] | undefined
};
	["Campaign_Creator_RelationTo"]:Campaign_Creator_RelationTo;
	["Campaign_Creator"]:ModelTypes["User"];
	["Banner_region"]:Banner_region;
	["Banners"]: {
		docs?: Array<ModelTypes["Banner"] | undefined> | undefined,
	hasNextPage?: boolean | undefined,
	hasPrevPage?: boolean | undefined,
	limit?: number | undefined,
	nextPage?: number | undefined,
	offset?: number | undefined,
	page?: number | undefined,
	pagingCounter?: number | undefined,
	prevPage?: number | undefined,
	totalDocs?: number | undefined,
	totalPages?: number | undefined
};
	["Banner_where"]: {
	name?: ModelTypes["Banner_name_operator"] | undefined,
	desktop?: ModelTypes["Banner_desktop_operator"] | undefined,
	mobile?: ModelTypes["Banner_mobile_operator"] | undefined,
	bannerStartDate?: ModelTypes["Banner_bannerStartDate_operator"] | undefined,
	bannerEndDate?: ModelTypes["Banner_bannerEndDate_operator"] | undefined,
	location__page?: ModelTypes["Banner_location__page_operator"] | undefined,
	location__eventRankingList?: ModelTypes["Banner_location__eventRankingList_operator"] | undefined,
	location__locationRankingList?: ModelTypes["Banner_location__locationRankingList_operator"] | undefined,
	location__categories?: ModelTypes["Banner_location__categories_operator"] | undefined,
	location__time?: ModelTypes["Banner_location__time_operator"] | undefined,
	location__district?: ModelTypes["Banner_location__district_operator"] | undefined,
	location__audience?: ModelTypes["Banner_location__audience_operator"] | undefined,
	location__multipleCriteriaSearch?: ModelTypes["Banner_location__multipleCriteriaSearch_operator"] | undefined,
	link?: ModelTypes["Banner_link_operator"] | undefined,
	external?: ModelTypes["Banner_external_operator"] | undefined,
	type?: ModelTypes["Banner_type_operator"] | undefined,
	status?: ModelTypes["Banner_status_operator"] | undefined,
	advertise__campaign?: ModelTypes["Banner_advertise__campaign_operator"] | undefined,
	advertise__invoiceDescription?: ModelTypes["Banner_advertise__invoiceDescription_operator"] | undefined,
	advertise__standardPrice?: ModelTypes["Banner_advertise__standardPrice_operator"] | undefined,
	advertise__discount?: ModelTypes["Banner_advertise__discount_operator"] | undefined,
	advertise__timableCost?: ModelTypes["Banner_advertise__timableCost_operator"] | undefined,
	advertise__remark?: ModelTypes["Banner_advertise__remark_operator"] | undefined,
	advertise__public?: ModelTypes["Banner_advertise__public_operator"] | undefined,
	_locations?: ModelTypes["Banner__locations_operator"] | undefined,
	region?: ModelTypes["Banner_region_operator"] | undefined,
	homePageClick?: ModelTypes["Banner_homePageClick_operator"] | undefined,
	homePageImpression?: ModelTypes["Banner_homePageImpression_operator"] | undefined,
	latestEventClick?: ModelTypes["Banner_latestEventClick_operator"] | undefined,
	latestEventImpression?: ModelTypes["Banner_latestEventImpression_operator"] | undefined,
	editorChoiceClick?: ModelTypes["Banner_editorChoiceClick_operator"] | undefined,
	editorChoiceImpression?: ModelTypes["Banner_editorChoiceImpression_operator"] | undefined,
	eventListClick?: ModelTypes["Banner_eventListClick_operator"] | undefined,
	eventListImpression?: ModelTypes["Banner_eventListImpression_operator"] | undefined,
	topTenClick?: ModelTypes["Banner_topTenClick_operator"] | undefined,
	topTenImpression?: ModelTypes["Banner_topTenImpression_operator"] | undefined,
	miscClick?: ModelTypes["Banner_miscClick_operator"] | undefined,
	miscImpression?: ModelTypes["Banner_miscImpression_operator"] | undefined,
	clickTotal?: ModelTypes["Banner_clickTotal_operator"] | undefined,
	totalImpression?: ModelTypes["Banner_totalImpression_operator"] | undefined,
	updatedAt?: ModelTypes["Banner_updatedAt_operator"] | undefined,
	createdAt?: ModelTypes["Banner_createdAt_operator"] | undefined,
	id?: ModelTypes["Banner_id_operator"] | undefined,
	AND?: Array<ModelTypes["Banner_where_and"] | undefined> | undefined,
	OR?: Array<ModelTypes["Banner_where_or"] | undefined> | undefined
};
	["Banner_name_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined
};
	["Banner_desktop_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined
};
	["Banner_mobile_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined
};
	["Banner_bannerStartDate_operator"]: {
	equals?: ModelTypes["DateTime"] | undefined,
	not_equals?: ModelTypes["DateTime"] | undefined,
	greater_than_equal?: ModelTypes["DateTime"] | undefined,
	greater_than?: ModelTypes["DateTime"] | undefined,
	less_than_equal?: ModelTypes["DateTime"] | undefined,
	less_than?: ModelTypes["DateTime"] | undefined,
	like?: ModelTypes["DateTime"] | undefined
};
	["Banner_bannerEndDate_operator"]: {
	equals?: ModelTypes["DateTime"] | undefined,
	not_equals?: ModelTypes["DateTime"] | undefined,
	greater_than_equal?: ModelTypes["DateTime"] | undefined,
	greater_than?: ModelTypes["DateTime"] | undefined,
	less_than_equal?: ModelTypes["DateTime"] | undefined,
	less_than?: ModelTypes["DateTime"] | undefined,
	like?: ModelTypes["DateTime"] | undefined
};
	["Banner_location__page_operator"]: {
	equals?: ModelTypes["Banner_location__page_Input"] | undefined,
	not_equals?: ModelTypes["Banner_location__page_Input"] | undefined,
	in?: Array<ModelTypes["Banner_location__page_Input"] | undefined> | undefined,
	not_in?: Array<ModelTypes["Banner_location__page_Input"] | undefined> | undefined,
	all?: Array<ModelTypes["Banner_location__page_Input"] | undefined> | undefined,
	exists?: boolean | undefined
};
	["Banner_location__page_Input"]:Banner_location__page_Input;
	["Banner_location__eventRankingList_operator"]: {
	equals?: ModelTypes["JSON"] | undefined,
	not_equals?: ModelTypes["JSON"] | undefined,
	in?: Array<ModelTypes["JSON"] | undefined> | undefined,
	not_in?: Array<ModelTypes["JSON"] | undefined> | undefined,
	all?: Array<ModelTypes["JSON"] | undefined> | undefined,
	exists?: boolean | undefined
};
	["Banner_location__locationRankingList_operator"]: {
	equals?: ModelTypes["JSON"] | undefined,
	not_equals?: ModelTypes["JSON"] | undefined,
	in?: Array<ModelTypes["JSON"] | undefined> | undefined,
	not_in?: Array<ModelTypes["JSON"] | undefined> | undefined,
	all?: Array<ModelTypes["JSON"] | undefined> | undefined,
	exists?: boolean | undefined
};
	["Banner_location__categories_operator"]: {
	equals?: ModelTypes["JSON"] | undefined,
	not_equals?: ModelTypes["JSON"] | undefined,
	in?: Array<ModelTypes["JSON"] | undefined> | undefined,
	not_in?: Array<ModelTypes["JSON"] | undefined> | undefined,
	all?: Array<ModelTypes["JSON"] | undefined> | undefined,
	exists?: boolean | undefined
};
	["Banner_location__time_operator"]: {
	equals?: boolean | undefined,
	not_equals?: boolean | undefined,
	exists?: boolean | undefined
};
	["Banner_location__district_operator"]: {
	equals?: boolean | undefined,
	not_equals?: boolean | undefined,
	exists?: boolean | undefined
};
	["Banner_location__audience_operator"]: {
	equals?: boolean | undefined,
	not_equals?: boolean | undefined,
	exists?: boolean | undefined
};
	["Banner_location__multipleCriteriaSearch_operator"]: {
	equals?: boolean | undefined,
	not_equals?: boolean | undefined,
	exists?: boolean | undefined
};
	["Banner_link_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Banner_external_operator"]: {
	equals?: boolean | undefined,
	not_equals?: boolean | undefined,
	exists?: boolean | undefined
};
	["Banner_type_operator"]: {
	equals?: ModelTypes["Banner_type_Input"] | undefined,
	not_equals?: ModelTypes["Banner_type_Input"] | undefined,
	in?: Array<ModelTypes["Banner_type_Input"] | undefined> | undefined,
	not_in?: Array<ModelTypes["Banner_type_Input"] | undefined> | undefined,
	all?: Array<ModelTypes["Banner_type_Input"] | undefined> | undefined,
	exists?: boolean | undefined
};
	["Banner_type_Input"]:Banner_type_Input;
	["Banner_status_operator"]: {
	equals?: ModelTypes["Banner_status_Input"] | undefined,
	not_equals?: ModelTypes["Banner_status_Input"] | undefined,
	in?: Array<ModelTypes["Banner_status_Input"] | undefined> | undefined,
	not_in?: Array<ModelTypes["Banner_status_Input"] | undefined> | undefined,
	all?: Array<ModelTypes["Banner_status_Input"] | undefined> | undefined,
	exists?: boolean | undefined
};
	["Banner_status_Input"]:Banner_status_Input;
	["Banner_advertise__campaign_operator"]: {
	equals?: ModelTypes["JSON"] | undefined,
	not_equals?: ModelTypes["JSON"] | undefined,
	in?: Array<ModelTypes["JSON"] | undefined> | undefined,
	not_in?: Array<ModelTypes["JSON"] | undefined> | undefined,
	all?: Array<ModelTypes["JSON"] | undefined> | undefined
};
	["Banner_advertise__invoiceDescription_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	exists?: boolean | undefined
};
	["Banner_advertise__standardPrice_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined
};
	["Banner_advertise__discount_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Banner_advertise__timableCost_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Banner_advertise__remark_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	exists?: boolean | undefined
};
	["Banner_advertise__public_operator"]: {
	equals?: boolean | undefined,
	not_equals?: boolean | undefined,
	exists?: boolean | undefined
};
	["Banner__locations_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Banner_region_operator"]: {
	equals?: ModelTypes["Banner_region_Input"] | undefined,
	not_equals?: ModelTypes["Banner_region_Input"] | undefined,
	in?: Array<ModelTypes["Banner_region_Input"] | undefined> | undefined,
	not_in?: Array<ModelTypes["Banner_region_Input"] | undefined> | undefined,
	all?: Array<ModelTypes["Banner_region_Input"] | undefined> | undefined,
	exists?: boolean | undefined
};
	["Banner_region_Input"]:Banner_region_Input;
	["Banner_homePageClick_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Banner_homePageImpression_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Banner_latestEventClick_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Banner_latestEventImpression_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Banner_editorChoiceClick_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Banner_editorChoiceImpression_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Banner_eventListClick_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Banner_eventListImpression_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Banner_topTenClick_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Banner_topTenImpression_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Banner_miscClick_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Banner_miscImpression_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Banner_clickTotal_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Banner_totalImpression_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Banner_updatedAt_operator"]: {
	equals?: ModelTypes["DateTime"] | undefined,
	not_equals?: ModelTypes["DateTime"] | undefined,
	greater_than_equal?: ModelTypes["DateTime"] | undefined,
	greater_than?: ModelTypes["DateTime"] | undefined,
	less_than_equal?: ModelTypes["DateTime"] | undefined,
	less_than?: ModelTypes["DateTime"] | undefined,
	like?: ModelTypes["DateTime"] | undefined,
	exists?: boolean | undefined
};
	["Banner_createdAt_operator"]: {
	equals?: ModelTypes["DateTime"] | undefined,
	not_equals?: ModelTypes["DateTime"] | undefined,
	greater_than_equal?: ModelTypes["DateTime"] | undefined,
	greater_than?: ModelTypes["DateTime"] | undefined,
	less_than_equal?: ModelTypes["DateTime"] | undefined,
	less_than?: ModelTypes["DateTime"] | undefined,
	like?: ModelTypes["DateTime"] | undefined,
	exists?: boolean | undefined
};
	["Banner_id_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Banner_where_and"]: {
	name?: ModelTypes["Banner_name_operator"] | undefined,
	desktop?: ModelTypes["Banner_desktop_operator"] | undefined,
	mobile?: ModelTypes["Banner_mobile_operator"] | undefined,
	bannerStartDate?: ModelTypes["Banner_bannerStartDate_operator"] | undefined,
	bannerEndDate?: ModelTypes["Banner_bannerEndDate_operator"] | undefined,
	location__page?: ModelTypes["Banner_location__page_operator"] | undefined,
	location__eventRankingList?: ModelTypes["Banner_location__eventRankingList_operator"] | undefined,
	location__locationRankingList?: ModelTypes["Banner_location__locationRankingList_operator"] | undefined,
	location__categories?: ModelTypes["Banner_location__categories_operator"] | undefined,
	location__time?: ModelTypes["Banner_location__time_operator"] | undefined,
	location__district?: ModelTypes["Banner_location__district_operator"] | undefined,
	location__audience?: ModelTypes["Banner_location__audience_operator"] | undefined,
	location__multipleCriteriaSearch?: ModelTypes["Banner_location__multipleCriteriaSearch_operator"] | undefined,
	link?: ModelTypes["Banner_link_operator"] | undefined,
	external?: ModelTypes["Banner_external_operator"] | undefined,
	type?: ModelTypes["Banner_type_operator"] | undefined,
	status?: ModelTypes["Banner_status_operator"] | undefined,
	advertise__campaign?: ModelTypes["Banner_advertise__campaign_operator"] | undefined,
	advertise__invoiceDescription?: ModelTypes["Banner_advertise__invoiceDescription_operator"] | undefined,
	advertise__standardPrice?: ModelTypes["Banner_advertise__standardPrice_operator"] | undefined,
	advertise__discount?: ModelTypes["Banner_advertise__discount_operator"] | undefined,
	advertise__timableCost?: ModelTypes["Banner_advertise__timableCost_operator"] | undefined,
	advertise__remark?: ModelTypes["Banner_advertise__remark_operator"] | undefined,
	advertise__public?: ModelTypes["Banner_advertise__public_operator"] | undefined,
	_locations?: ModelTypes["Banner__locations_operator"] | undefined,
	region?: ModelTypes["Banner_region_operator"] | undefined,
	homePageClick?: ModelTypes["Banner_homePageClick_operator"] | undefined,
	homePageImpression?: ModelTypes["Banner_homePageImpression_operator"] | undefined,
	latestEventClick?: ModelTypes["Banner_latestEventClick_operator"] | undefined,
	latestEventImpression?: ModelTypes["Banner_latestEventImpression_operator"] | undefined,
	editorChoiceClick?: ModelTypes["Banner_editorChoiceClick_operator"] | undefined,
	editorChoiceImpression?: ModelTypes["Banner_editorChoiceImpression_operator"] | undefined,
	eventListClick?: ModelTypes["Banner_eventListClick_operator"] | undefined,
	eventListImpression?: ModelTypes["Banner_eventListImpression_operator"] | undefined,
	topTenClick?: ModelTypes["Banner_topTenClick_operator"] | undefined,
	topTenImpression?: ModelTypes["Banner_topTenImpression_operator"] | undefined,
	miscClick?: ModelTypes["Banner_miscClick_operator"] | undefined,
	miscImpression?: ModelTypes["Banner_miscImpression_operator"] | undefined,
	clickTotal?: ModelTypes["Banner_clickTotal_operator"] | undefined,
	totalImpression?: ModelTypes["Banner_totalImpression_operator"] | undefined,
	updatedAt?: ModelTypes["Banner_updatedAt_operator"] | undefined,
	createdAt?: ModelTypes["Banner_createdAt_operator"] | undefined,
	id?: ModelTypes["Banner_id_operator"] | undefined,
	AND?: Array<ModelTypes["Banner_where_and"] | undefined> | undefined,
	OR?: Array<ModelTypes["Banner_where_or"] | undefined> | undefined
};
	["Banner_where_or"]: {
	name?: ModelTypes["Banner_name_operator"] | undefined,
	desktop?: ModelTypes["Banner_desktop_operator"] | undefined,
	mobile?: ModelTypes["Banner_mobile_operator"] | undefined,
	bannerStartDate?: ModelTypes["Banner_bannerStartDate_operator"] | undefined,
	bannerEndDate?: ModelTypes["Banner_bannerEndDate_operator"] | undefined,
	location__page?: ModelTypes["Banner_location__page_operator"] | undefined,
	location__eventRankingList?: ModelTypes["Banner_location__eventRankingList_operator"] | undefined,
	location__locationRankingList?: ModelTypes["Banner_location__locationRankingList_operator"] | undefined,
	location__categories?: ModelTypes["Banner_location__categories_operator"] | undefined,
	location__time?: ModelTypes["Banner_location__time_operator"] | undefined,
	location__district?: ModelTypes["Banner_location__district_operator"] | undefined,
	location__audience?: ModelTypes["Banner_location__audience_operator"] | undefined,
	location__multipleCriteriaSearch?: ModelTypes["Banner_location__multipleCriteriaSearch_operator"] | undefined,
	link?: ModelTypes["Banner_link_operator"] | undefined,
	external?: ModelTypes["Banner_external_operator"] | undefined,
	type?: ModelTypes["Banner_type_operator"] | undefined,
	status?: ModelTypes["Banner_status_operator"] | undefined,
	advertise__campaign?: ModelTypes["Banner_advertise__campaign_operator"] | undefined,
	advertise__invoiceDescription?: ModelTypes["Banner_advertise__invoiceDescription_operator"] | undefined,
	advertise__standardPrice?: ModelTypes["Banner_advertise__standardPrice_operator"] | undefined,
	advertise__discount?: ModelTypes["Banner_advertise__discount_operator"] | undefined,
	advertise__timableCost?: ModelTypes["Banner_advertise__timableCost_operator"] | undefined,
	advertise__remark?: ModelTypes["Banner_advertise__remark_operator"] | undefined,
	advertise__public?: ModelTypes["Banner_advertise__public_operator"] | undefined,
	_locations?: ModelTypes["Banner__locations_operator"] | undefined,
	region?: ModelTypes["Banner_region_operator"] | undefined,
	homePageClick?: ModelTypes["Banner_homePageClick_operator"] | undefined,
	homePageImpression?: ModelTypes["Banner_homePageImpression_operator"] | undefined,
	latestEventClick?: ModelTypes["Banner_latestEventClick_operator"] | undefined,
	latestEventImpression?: ModelTypes["Banner_latestEventImpression_operator"] | undefined,
	editorChoiceClick?: ModelTypes["Banner_editorChoiceClick_operator"] | undefined,
	editorChoiceImpression?: ModelTypes["Banner_editorChoiceImpression_operator"] | undefined,
	eventListClick?: ModelTypes["Banner_eventListClick_operator"] | undefined,
	eventListImpression?: ModelTypes["Banner_eventListImpression_operator"] | undefined,
	topTenClick?: ModelTypes["Banner_topTenClick_operator"] | undefined,
	topTenImpression?: ModelTypes["Banner_topTenImpression_operator"] | undefined,
	miscClick?: ModelTypes["Banner_miscClick_operator"] | undefined,
	miscImpression?: ModelTypes["Banner_miscImpression_operator"] | undefined,
	clickTotal?: ModelTypes["Banner_clickTotal_operator"] | undefined,
	totalImpression?: ModelTypes["Banner_totalImpression_operator"] | undefined,
	updatedAt?: ModelTypes["Banner_updatedAt_operator"] | undefined,
	createdAt?: ModelTypes["Banner_createdAt_operator"] | undefined,
	id?: ModelTypes["Banner_id_operator"] | undefined,
	AND?: Array<ModelTypes["Banner_where_and"] | undefined> | undefined,
	OR?: Array<ModelTypes["Banner_where_or"] | undefined> | undefined
};
	["countBanners"]: {
		totalDocs?: number | undefined
};
	["bannersDocAccess"]: {
		fields?: ModelTypes["BannersDocAccessFields"] | undefined,
	create?: ModelTypes["BannersCreateDocAccess"] | undefined,
	read?: ModelTypes["BannersReadDocAccess"] | undefined,
	update?: ModelTypes["BannersUpdateDocAccess"] | undefined,
	delete?: ModelTypes["BannersDeleteDocAccess"] | undefined
};
	["BannersDocAccessFields"]: {
		name?: ModelTypes["BannersDocAccessFields_name"] | undefined,
	desktop?: ModelTypes["BannersDocAccessFields_desktop"] | undefined,
	mobile?: ModelTypes["BannersDocAccessFields_mobile"] | undefined,
	bannerStartDate?: ModelTypes["BannersDocAccessFields_bannerStartDate"] | undefined,
	bannerEndDate?: ModelTypes["BannersDocAccessFields_bannerEndDate"] | undefined,
	location?: ModelTypes["BannersDocAccessFields_location"] | undefined,
	link?: ModelTypes["BannersDocAccessFields_link"] | undefined,
	external?: ModelTypes["BannersDocAccessFields_external"] | undefined,
	type?: ModelTypes["BannersDocAccessFields_type"] | undefined,
	status?: ModelTypes["BannersDocAccessFields_status"] | undefined,
	advertise?: ModelTypes["BannersDocAccessFields_advertise"] | undefined,
	_locations?: ModelTypes["BannersDocAccessFields__locations"] | undefined,
	region?: ModelTypes["BannersDocAccessFields_region"] | undefined,
	homePageClick?: ModelTypes["BannersDocAccessFields_homePageClick"] | undefined,
	homePageImpression?: ModelTypes["BannersDocAccessFields_homePageImpression"] | undefined,
	latestEventClick?: ModelTypes["BannersDocAccessFields_latestEventClick"] | undefined,
	latestEventImpression?: ModelTypes["BannersDocAccessFields_latestEventImpression"] | undefined,
	editorChoiceClick?: ModelTypes["BannersDocAccessFields_editorChoiceClick"] | undefined,
	editorChoiceImpression?: ModelTypes["BannersDocAccessFields_editorChoiceImpression"] | undefined,
	eventListClick?: ModelTypes["BannersDocAccessFields_eventListClick"] | undefined,
	eventListImpression?: ModelTypes["BannersDocAccessFields_eventListImpression"] | undefined,
	topTenClick?: ModelTypes["BannersDocAccessFields_topTenClick"] | undefined,
	topTenImpression?: ModelTypes["BannersDocAccessFields_topTenImpression"] | undefined,
	miscClick?: ModelTypes["BannersDocAccessFields_miscClick"] | undefined,
	miscImpression?: ModelTypes["BannersDocAccessFields_miscImpression"] | undefined,
	clickTotal?: ModelTypes["BannersDocAccessFields_clickTotal"] | undefined,
	totalImpression?: ModelTypes["BannersDocAccessFields_totalImpression"] | undefined,
	updatedAt?: ModelTypes["BannersDocAccessFields_updatedAt"] | undefined,
	createdAt?: ModelTypes["BannersDocAccessFields_createdAt"] | undefined
};
	["BannersDocAccessFields_name"]: {
		create?: ModelTypes["BannersDocAccessFields_name_Create"] | undefined,
	read?: ModelTypes["BannersDocAccessFields_name_Read"] | undefined,
	update?: ModelTypes["BannersDocAccessFields_name_Update"] | undefined,
	delete?: ModelTypes["BannersDocAccessFields_name_Delete"] | undefined
};
	["BannersDocAccessFields_name_Create"]: {
		permission: boolean
};
	["BannersDocAccessFields_name_Read"]: {
		permission: boolean
};
	["BannersDocAccessFields_name_Update"]: {
		permission: boolean
};
	["BannersDocAccessFields_name_Delete"]: {
		permission: boolean
};
	["BannersDocAccessFields_desktop"]: {
		create?: ModelTypes["BannersDocAccessFields_desktop_Create"] | undefined,
	read?: ModelTypes["BannersDocAccessFields_desktop_Read"] | undefined,
	update?: ModelTypes["BannersDocAccessFields_desktop_Update"] | undefined,
	delete?: ModelTypes["BannersDocAccessFields_desktop_Delete"] | undefined
};
	["BannersDocAccessFields_desktop_Create"]: {
		permission: boolean
};
	["BannersDocAccessFields_desktop_Read"]: {
		permission: boolean
};
	["BannersDocAccessFields_desktop_Update"]: {
		permission: boolean
};
	["BannersDocAccessFields_desktop_Delete"]: {
		permission: boolean
};
	["BannersDocAccessFields_mobile"]: {
		create?: ModelTypes["BannersDocAccessFields_mobile_Create"] | undefined,
	read?: ModelTypes["BannersDocAccessFields_mobile_Read"] | undefined,
	update?: ModelTypes["BannersDocAccessFields_mobile_Update"] | undefined,
	delete?: ModelTypes["BannersDocAccessFields_mobile_Delete"] | undefined
};
	["BannersDocAccessFields_mobile_Create"]: {
		permission: boolean
};
	["BannersDocAccessFields_mobile_Read"]: {
		permission: boolean
};
	["BannersDocAccessFields_mobile_Update"]: {
		permission: boolean
};
	["BannersDocAccessFields_mobile_Delete"]: {
		permission: boolean
};
	["BannersDocAccessFields_bannerStartDate"]: {
		create?: ModelTypes["BannersDocAccessFields_bannerStartDate_Create"] | undefined,
	read?: ModelTypes["BannersDocAccessFields_bannerStartDate_Read"] | undefined,
	update?: ModelTypes["BannersDocAccessFields_bannerStartDate_Update"] | undefined,
	delete?: ModelTypes["BannersDocAccessFields_bannerStartDate_Delete"] | undefined
};
	["BannersDocAccessFields_bannerStartDate_Create"]: {
		permission: boolean
};
	["BannersDocAccessFields_bannerStartDate_Read"]: {
		permission: boolean
};
	["BannersDocAccessFields_bannerStartDate_Update"]: {
		permission: boolean
};
	["BannersDocAccessFields_bannerStartDate_Delete"]: {
		permission: boolean
};
	["BannersDocAccessFields_bannerEndDate"]: {
		create?: ModelTypes["BannersDocAccessFields_bannerEndDate_Create"] | undefined,
	read?: ModelTypes["BannersDocAccessFields_bannerEndDate_Read"] | undefined,
	update?: ModelTypes["BannersDocAccessFields_bannerEndDate_Update"] | undefined,
	delete?: ModelTypes["BannersDocAccessFields_bannerEndDate_Delete"] | undefined
};
	["BannersDocAccessFields_bannerEndDate_Create"]: {
		permission: boolean
};
	["BannersDocAccessFields_bannerEndDate_Read"]: {
		permission: boolean
};
	["BannersDocAccessFields_bannerEndDate_Update"]: {
		permission: boolean
};
	["BannersDocAccessFields_bannerEndDate_Delete"]: {
		permission: boolean
};
	["BannersDocAccessFields_location"]: {
		create?: ModelTypes["BannersDocAccessFields_location_Create"] | undefined,
	read?: ModelTypes["BannersDocAccessFields_location_Read"] | undefined,
	update?: ModelTypes["BannersDocAccessFields_location_Update"] | undefined,
	delete?: ModelTypes["BannersDocAccessFields_location_Delete"] | undefined,
	fields?: ModelTypes["BannersDocAccessFields_location_Fields"] | undefined
};
	["BannersDocAccessFields_location_Create"]: {
		permission: boolean
};
	["BannersDocAccessFields_location_Read"]: {
		permission: boolean
};
	["BannersDocAccessFields_location_Update"]: {
		permission: boolean
};
	["BannersDocAccessFields_location_Delete"]: {
		permission: boolean
};
	["BannersDocAccessFields_location_Fields"]: {
		page?: ModelTypes["BannersDocAccessFields_location_page"] | undefined,
	eventRankingList?: ModelTypes["BannersDocAccessFields_location_eventRankingList"] | undefined,
	locationRankingList?: ModelTypes["BannersDocAccessFields_location_locationRankingList"] | undefined,
	categories?: ModelTypes["BannersDocAccessFields_location_categories"] | undefined,
	time?: ModelTypes["BannersDocAccessFields_location_time"] | undefined,
	district?: ModelTypes["BannersDocAccessFields_location_district"] | undefined,
	audience?: ModelTypes["BannersDocAccessFields_location_audience"] | undefined,
	multipleCriteriaSearch?: ModelTypes["BannersDocAccessFields_location_multipleCriteriaSearch"] | undefined
};
	["BannersDocAccessFields_location_page"]: {
		create?: ModelTypes["BannersDocAccessFields_location_page_Create"] | undefined,
	read?: ModelTypes["BannersDocAccessFields_location_page_Read"] | undefined,
	update?: ModelTypes["BannersDocAccessFields_location_page_Update"] | undefined,
	delete?: ModelTypes["BannersDocAccessFields_location_page_Delete"] | undefined
};
	["BannersDocAccessFields_location_page_Create"]: {
		permission: boolean
};
	["BannersDocAccessFields_location_page_Read"]: {
		permission: boolean
};
	["BannersDocAccessFields_location_page_Update"]: {
		permission: boolean
};
	["BannersDocAccessFields_location_page_Delete"]: {
		permission: boolean
};
	["BannersDocAccessFields_location_eventRankingList"]: {
		create?: ModelTypes["BannersDocAccessFields_location_eventRankingList_Create"] | undefined,
	read?: ModelTypes["BannersDocAccessFields_location_eventRankingList_Read"] | undefined,
	update?: ModelTypes["BannersDocAccessFields_location_eventRankingList_Update"] | undefined,
	delete?: ModelTypes["BannersDocAccessFields_location_eventRankingList_Delete"] | undefined
};
	["BannersDocAccessFields_location_eventRankingList_Create"]: {
		permission: boolean
};
	["BannersDocAccessFields_location_eventRankingList_Read"]: {
		permission: boolean
};
	["BannersDocAccessFields_location_eventRankingList_Update"]: {
		permission: boolean
};
	["BannersDocAccessFields_location_eventRankingList_Delete"]: {
		permission: boolean
};
	["BannersDocAccessFields_location_locationRankingList"]: {
		create?: ModelTypes["BannersDocAccessFields_location_locationRankingList_Create"] | undefined,
	read?: ModelTypes["BannersDocAccessFields_location_locationRankingList_Read"] | undefined,
	update?: ModelTypes["BannersDocAccessFields_location_locationRankingList_Update"] | undefined,
	delete?: ModelTypes["BannersDocAccessFields_location_locationRankingList_Delete"] | undefined
};
	["BannersDocAccessFields_location_locationRankingList_Create"]: {
		permission: boolean
};
	["BannersDocAccessFields_location_locationRankingList_Read"]: {
		permission: boolean
};
	["BannersDocAccessFields_location_locationRankingList_Update"]: {
		permission: boolean
};
	["BannersDocAccessFields_location_locationRankingList_Delete"]: {
		permission: boolean
};
	["BannersDocAccessFields_location_categories"]: {
		create?: ModelTypes["BannersDocAccessFields_location_categories_Create"] | undefined,
	read?: ModelTypes["BannersDocAccessFields_location_categories_Read"] | undefined,
	update?: ModelTypes["BannersDocAccessFields_location_categories_Update"] | undefined,
	delete?: ModelTypes["BannersDocAccessFields_location_categories_Delete"] | undefined
};
	["BannersDocAccessFields_location_categories_Create"]: {
		permission: boolean
};
	["BannersDocAccessFields_location_categories_Read"]: {
		permission: boolean
};
	["BannersDocAccessFields_location_categories_Update"]: {
		permission: boolean
};
	["BannersDocAccessFields_location_categories_Delete"]: {
		permission: boolean
};
	["BannersDocAccessFields_location_time"]: {
		create?: ModelTypes["BannersDocAccessFields_location_time_Create"] | undefined,
	read?: ModelTypes["BannersDocAccessFields_location_time_Read"] | undefined,
	update?: ModelTypes["BannersDocAccessFields_location_time_Update"] | undefined,
	delete?: ModelTypes["BannersDocAccessFields_location_time_Delete"] | undefined
};
	["BannersDocAccessFields_location_time_Create"]: {
		permission: boolean
};
	["BannersDocAccessFields_location_time_Read"]: {
		permission: boolean
};
	["BannersDocAccessFields_location_time_Update"]: {
		permission: boolean
};
	["BannersDocAccessFields_location_time_Delete"]: {
		permission: boolean
};
	["BannersDocAccessFields_location_district"]: {
		create?: ModelTypes["BannersDocAccessFields_location_district_Create"] | undefined,
	read?: ModelTypes["BannersDocAccessFields_location_district_Read"] | undefined,
	update?: ModelTypes["BannersDocAccessFields_location_district_Update"] | undefined,
	delete?: ModelTypes["BannersDocAccessFields_location_district_Delete"] | undefined
};
	["BannersDocAccessFields_location_district_Create"]: {
		permission: boolean
};
	["BannersDocAccessFields_location_district_Read"]: {
		permission: boolean
};
	["BannersDocAccessFields_location_district_Update"]: {
		permission: boolean
};
	["BannersDocAccessFields_location_district_Delete"]: {
		permission: boolean
};
	["BannersDocAccessFields_location_audience"]: {
		create?: ModelTypes["BannersDocAccessFields_location_audience_Create"] | undefined,
	read?: ModelTypes["BannersDocAccessFields_location_audience_Read"] | undefined,
	update?: ModelTypes["BannersDocAccessFields_location_audience_Update"] | undefined,
	delete?: ModelTypes["BannersDocAccessFields_location_audience_Delete"] | undefined
};
	["BannersDocAccessFields_location_audience_Create"]: {
		permission: boolean
};
	["BannersDocAccessFields_location_audience_Read"]: {
		permission: boolean
};
	["BannersDocAccessFields_location_audience_Update"]: {
		permission: boolean
};
	["BannersDocAccessFields_location_audience_Delete"]: {
		permission: boolean
};
	["BannersDocAccessFields_location_multipleCriteriaSearch"]: {
		create?: ModelTypes["BannersDocAccessFields_location_multipleCriteriaSearch_Create"] | undefined,
	read?: ModelTypes["BannersDocAccessFields_location_multipleCriteriaSearch_Read"] | undefined,
	update?: ModelTypes["BannersDocAccessFields_location_multipleCriteriaSearch_Update"] | undefined,
	delete?: ModelTypes["BannersDocAccessFields_location_multipleCriteriaSearch_Delete"] | undefined
};
	["BannersDocAccessFields_location_multipleCriteriaSearch_Create"]: {
		permission: boolean
};
	["BannersDocAccessFields_location_multipleCriteriaSearch_Read"]: {
		permission: boolean
};
	["BannersDocAccessFields_location_multipleCriteriaSearch_Update"]: {
		permission: boolean
};
	["BannersDocAccessFields_location_multipleCriteriaSearch_Delete"]: {
		permission: boolean
};
	["BannersDocAccessFields_link"]: {
		create?: ModelTypes["BannersDocAccessFields_link_Create"] | undefined,
	read?: ModelTypes["BannersDocAccessFields_link_Read"] | undefined,
	update?: ModelTypes["BannersDocAccessFields_link_Update"] | undefined,
	delete?: ModelTypes["BannersDocAccessFields_link_Delete"] | undefined
};
	["BannersDocAccessFields_link_Create"]: {
		permission: boolean
};
	["BannersDocAccessFields_link_Read"]: {
		permission: boolean
};
	["BannersDocAccessFields_link_Update"]: {
		permission: boolean
};
	["BannersDocAccessFields_link_Delete"]: {
		permission: boolean
};
	["BannersDocAccessFields_external"]: {
		create?: ModelTypes["BannersDocAccessFields_external_Create"] | undefined,
	read?: ModelTypes["BannersDocAccessFields_external_Read"] | undefined,
	update?: ModelTypes["BannersDocAccessFields_external_Update"] | undefined,
	delete?: ModelTypes["BannersDocAccessFields_external_Delete"] | undefined
};
	["BannersDocAccessFields_external_Create"]: {
		permission: boolean
};
	["BannersDocAccessFields_external_Read"]: {
		permission: boolean
};
	["BannersDocAccessFields_external_Update"]: {
		permission: boolean
};
	["BannersDocAccessFields_external_Delete"]: {
		permission: boolean
};
	["BannersDocAccessFields_type"]: {
		create?: ModelTypes["BannersDocAccessFields_type_Create"] | undefined,
	read?: ModelTypes["BannersDocAccessFields_type_Read"] | undefined,
	update?: ModelTypes["BannersDocAccessFields_type_Update"] | undefined,
	delete?: ModelTypes["BannersDocAccessFields_type_Delete"] | undefined
};
	["BannersDocAccessFields_type_Create"]: {
		permission: boolean
};
	["BannersDocAccessFields_type_Read"]: {
		permission: boolean
};
	["BannersDocAccessFields_type_Update"]: {
		permission: boolean
};
	["BannersDocAccessFields_type_Delete"]: {
		permission: boolean
};
	["BannersDocAccessFields_status"]: {
		create?: ModelTypes["BannersDocAccessFields_status_Create"] | undefined,
	read?: ModelTypes["BannersDocAccessFields_status_Read"] | undefined,
	update?: ModelTypes["BannersDocAccessFields_status_Update"] | undefined,
	delete?: ModelTypes["BannersDocAccessFields_status_Delete"] | undefined
};
	["BannersDocAccessFields_status_Create"]: {
		permission: boolean
};
	["BannersDocAccessFields_status_Read"]: {
		permission: boolean
};
	["BannersDocAccessFields_status_Update"]: {
		permission: boolean
};
	["BannersDocAccessFields_status_Delete"]: {
		permission: boolean
};
	["BannersDocAccessFields_advertise"]: {
		create?: ModelTypes["BannersDocAccessFields_advertise_Create"] | undefined,
	read?: ModelTypes["BannersDocAccessFields_advertise_Read"] | undefined,
	update?: ModelTypes["BannersDocAccessFields_advertise_Update"] | undefined,
	delete?: ModelTypes["BannersDocAccessFields_advertise_Delete"] | undefined,
	fields?: ModelTypes["BannersDocAccessFields_advertise_Fields"] | undefined
};
	["BannersDocAccessFields_advertise_Create"]: {
		permission: boolean
};
	["BannersDocAccessFields_advertise_Read"]: {
		permission: boolean
};
	["BannersDocAccessFields_advertise_Update"]: {
		permission: boolean
};
	["BannersDocAccessFields_advertise_Delete"]: {
		permission: boolean
};
	["BannersDocAccessFields_advertise_Fields"]: {
		campaign?: ModelTypes["BannersDocAccessFields_advertise_campaign"] | undefined,
	invoiceDescription?: ModelTypes["BannersDocAccessFields_advertise_invoiceDescription"] | undefined,
	standardPrice?: ModelTypes["BannersDocAccessFields_advertise_standardPrice"] | undefined,
	discount?: ModelTypes["BannersDocAccessFields_advertise_discount"] | undefined,
	timableCost?: ModelTypes["BannersDocAccessFields_advertise_timableCost"] | undefined,
	remark?: ModelTypes["BannersDocAccessFields_advertise_remark"] | undefined,
	public?: ModelTypes["BannersDocAccessFields_advertise_public"] | undefined
};
	["BannersDocAccessFields_advertise_campaign"]: {
		create?: ModelTypes["BannersDocAccessFields_advertise_campaign_Create"] | undefined,
	read?: ModelTypes["BannersDocAccessFields_advertise_campaign_Read"] | undefined,
	update?: ModelTypes["BannersDocAccessFields_advertise_campaign_Update"] | undefined,
	delete?: ModelTypes["BannersDocAccessFields_advertise_campaign_Delete"] | undefined
};
	["BannersDocAccessFields_advertise_campaign_Create"]: {
		permission: boolean
};
	["BannersDocAccessFields_advertise_campaign_Read"]: {
		permission: boolean
};
	["BannersDocAccessFields_advertise_campaign_Update"]: {
		permission: boolean
};
	["BannersDocAccessFields_advertise_campaign_Delete"]: {
		permission: boolean
};
	["BannersDocAccessFields_advertise_invoiceDescription"]: {
		create?: ModelTypes["BannersDocAccessFields_advertise_invoiceDescription_Create"] | undefined,
	read?: ModelTypes["BannersDocAccessFields_advertise_invoiceDescription_Read"] | undefined,
	update?: ModelTypes["BannersDocAccessFields_advertise_invoiceDescription_Update"] | undefined,
	delete?: ModelTypes["BannersDocAccessFields_advertise_invoiceDescription_Delete"] | undefined
};
	["BannersDocAccessFields_advertise_invoiceDescription_Create"]: {
		permission: boolean
};
	["BannersDocAccessFields_advertise_invoiceDescription_Read"]: {
		permission: boolean
};
	["BannersDocAccessFields_advertise_invoiceDescription_Update"]: {
		permission: boolean
};
	["BannersDocAccessFields_advertise_invoiceDescription_Delete"]: {
		permission: boolean
};
	["BannersDocAccessFields_advertise_standardPrice"]: {
		create?: ModelTypes["BannersDocAccessFields_advertise_standardPrice_Create"] | undefined,
	read?: ModelTypes["BannersDocAccessFields_advertise_standardPrice_Read"] | undefined,
	update?: ModelTypes["BannersDocAccessFields_advertise_standardPrice_Update"] | undefined,
	delete?: ModelTypes["BannersDocAccessFields_advertise_standardPrice_Delete"] | undefined
};
	["BannersDocAccessFields_advertise_standardPrice_Create"]: {
		permission: boolean
};
	["BannersDocAccessFields_advertise_standardPrice_Read"]: {
		permission: boolean
};
	["BannersDocAccessFields_advertise_standardPrice_Update"]: {
		permission: boolean
};
	["BannersDocAccessFields_advertise_standardPrice_Delete"]: {
		permission: boolean
};
	["BannersDocAccessFields_advertise_discount"]: {
		create?: ModelTypes["BannersDocAccessFields_advertise_discount_Create"] | undefined,
	read?: ModelTypes["BannersDocAccessFields_advertise_discount_Read"] | undefined,
	update?: ModelTypes["BannersDocAccessFields_advertise_discount_Update"] | undefined,
	delete?: ModelTypes["BannersDocAccessFields_advertise_discount_Delete"] | undefined
};
	["BannersDocAccessFields_advertise_discount_Create"]: {
		permission: boolean
};
	["BannersDocAccessFields_advertise_discount_Read"]: {
		permission: boolean
};
	["BannersDocAccessFields_advertise_discount_Update"]: {
		permission: boolean
};
	["BannersDocAccessFields_advertise_discount_Delete"]: {
		permission: boolean
};
	["BannersDocAccessFields_advertise_timableCost"]: {
		create?: ModelTypes["BannersDocAccessFields_advertise_timableCost_Create"] | undefined,
	read?: ModelTypes["BannersDocAccessFields_advertise_timableCost_Read"] | undefined,
	update?: ModelTypes["BannersDocAccessFields_advertise_timableCost_Update"] | undefined,
	delete?: ModelTypes["BannersDocAccessFields_advertise_timableCost_Delete"] | undefined
};
	["BannersDocAccessFields_advertise_timableCost_Create"]: {
		permission: boolean
};
	["BannersDocAccessFields_advertise_timableCost_Read"]: {
		permission: boolean
};
	["BannersDocAccessFields_advertise_timableCost_Update"]: {
		permission: boolean
};
	["BannersDocAccessFields_advertise_timableCost_Delete"]: {
		permission: boolean
};
	["BannersDocAccessFields_advertise_remark"]: {
		create?: ModelTypes["BannersDocAccessFields_advertise_remark_Create"] | undefined,
	read?: ModelTypes["BannersDocAccessFields_advertise_remark_Read"] | undefined,
	update?: ModelTypes["BannersDocAccessFields_advertise_remark_Update"] | undefined,
	delete?: ModelTypes["BannersDocAccessFields_advertise_remark_Delete"] | undefined
};
	["BannersDocAccessFields_advertise_remark_Create"]: {
		permission: boolean
};
	["BannersDocAccessFields_advertise_remark_Read"]: {
		permission: boolean
};
	["BannersDocAccessFields_advertise_remark_Update"]: {
		permission: boolean
};
	["BannersDocAccessFields_advertise_remark_Delete"]: {
		permission: boolean
};
	["BannersDocAccessFields_advertise_public"]: {
		create?: ModelTypes["BannersDocAccessFields_advertise_public_Create"] | undefined,
	read?: ModelTypes["BannersDocAccessFields_advertise_public_Read"] | undefined,
	update?: ModelTypes["BannersDocAccessFields_advertise_public_Update"] | undefined,
	delete?: ModelTypes["BannersDocAccessFields_advertise_public_Delete"] | undefined
};
	["BannersDocAccessFields_advertise_public_Create"]: {
		permission: boolean
};
	["BannersDocAccessFields_advertise_public_Read"]: {
		permission: boolean
};
	["BannersDocAccessFields_advertise_public_Update"]: {
		permission: boolean
};
	["BannersDocAccessFields_advertise_public_Delete"]: {
		permission: boolean
};
	["BannersDocAccessFields__locations"]: {
		create?: ModelTypes["BannersDocAccessFields__locations_Create"] | undefined,
	read?: ModelTypes["BannersDocAccessFields__locations_Read"] | undefined,
	update?: ModelTypes["BannersDocAccessFields__locations_Update"] | undefined,
	delete?: ModelTypes["BannersDocAccessFields__locations_Delete"] | undefined
};
	["BannersDocAccessFields__locations_Create"]: {
		permission: boolean
};
	["BannersDocAccessFields__locations_Read"]: {
		permission: boolean
};
	["BannersDocAccessFields__locations_Update"]: {
		permission: boolean
};
	["BannersDocAccessFields__locations_Delete"]: {
		permission: boolean
};
	["BannersDocAccessFields_region"]: {
		create?: ModelTypes["BannersDocAccessFields_region_Create"] | undefined,
	read?: ModelTypes["BannersDocAccessFields_region_Read"] | undefined,
	update?: ModelTypes["BannersDocAccessFields_region_Update"] | undefined,
	delete?: ModelTypes["BannersDocAccessFields_region_Delete"] | undefined
};
	["BannersDocAccessFields_region_Create"]: {
		permission: boolean
};
	["BannersDocAccessFields_region_Read"]: {
		permission: boolean
};
	["BannersDocAccessFields_region_Update"]: {
		permission: boolean
};
	["BannersDocAccessFields_region_Delete"]: {
		permission: boolean
};
	["BannersDocAccessFields_homePageClick"]: {
		create?: ModelTypes["BannersDocAccessFields_homePageClick_Create"] | undefined,
	read?: ModelTypes["BannersDocAccessFields_homePageClick_Read"] | undefined,
	update?: ModelTypes["BannersDocAccessFields_homePageClick_Update"] | undefined,
	delete?: ModelTypes["BannersDocAccessFields_homePageClick_Delete"] | undefined
};
	["BannersDocAccessFields_homePageClick_Create"]: {
		permission: boolean
};
	["BannersDocAccessFields_homePageClick_Read"]: {
		permission: boolean
};
	["BannersDocAccessFields_homePageClick_Update"]: {
		permission: boolean
};
	["BannersDocAccessFields_homePageClick_Delete"]: {
		permission: boolean
};
	["BannersDocAccessFields_homePageImpression"]: {
		create?: ModelTypes["BannersDocAccessFields_homePageImpression_Create"] | undefined,
	read?: ModelTypes["BannersDocAccessFields_homePageImpression_Read"] | undefined,
	update?: ModelTypes["BannersDocAccessFields_homePageImpression_Update"] | undefined,
	delete?: ModelTypes["BannersDocAccessFields_homePageImpression_Delete"] | undefined
};
	["BannersDocAccessFields_homePageImpression_Create"]: {
		permission: boolean
};
	["BannersDocAccessFields_homePageImpression_Read"]: {
		permission: boolean
};
	["BannersDocAccessFields_homePageImpression_Update"]: {
		permission: boolean
};
	["BannersDocAccessFields_homePageImpression_Delete"]: {
		permission: boolean
};
	["BannersDocAccessFields_latestEventClick"]: {
		create?: ModelTypes["BannersDocAccessFields_latestEventClick_Create"] | undefined,
	read?: ModelTypes["BannersDocAccessFields_latestEventClick_Read"] | undefined,
	update?: ModelTypes["BannersDocAccessFields_latestEventClick_Update"] | undefined,
	delete?: ModelTypes["BannersDocAccessFields_latestEventClick_Delete"] | undefined
};
	["BannersDocAccessFields_latestEventClick_Create"]: {
		permission: boolean
};
	["BannersDocAccessFields_latestEventClick_Read"]: {
		permission: boolean
};
	["BannersDocAccessFields_latestEventClick_Update"]: {
		permission: boolean
};
	["BannersDocAccessFields_latestEventClick_Delete"]: {
		permission: boolean
};
	["BannersDocAccessFields_latestEventImpression"]: {
		create?: ModelTypes["BannersDocAccessFields_latestEventImpression_Create"] | undefined,
	read?: ModelTypes["BannersDocAccessFields_latestEventImpression_Read"] | undefined,
	update?: ModelTypes["BannersDocAccessFields_latestEventImpression_Update"] | undefined,
	delete?: ModelTypes["BannersDocAccessFields_latestEventImpression_Delete"] | undefined
};
	["BannersDocAccessFields_latestEventImpression_Create"]: {
		permission: boolean
};
	["BannersDocAccessFields_latestEventImpression_Read"]: {
		permission: boolean
};
	["BannersDocAccessFields_latestEventImpression_Update"]: {
		permission: boolean
};
	["BannersDocAccessFields_latestEventImpression_Delete"]: {
		permission: boolean
};
	["BannersDocAccessFields_editorChoiceClick"]: {
		create?: ModelTypes["BannersDocAccessFields_editorChoiceClick_Create"] | undefined,
	read?: ModelTypes["BannersDocAccessFields_editorChoiceClick_Read"] | undefined,
	update?: ModelTypes["BannersDocAccessFields_editorChoiceClick_Update"] | undefined,
	delete?: ModelTypes["BannersDocAccessFields_editorChoiceClick_Delete"] | undefined
};
	["BannersDocAccessFields_editorChoiceClick_Create"]: {
		permission: boolean
};
	["BannersDocAccessFields_editorChoiceClick_Read"]: {
		permission: boolean
};
	["BannersDocAccessFields_editorChoiceClick_Update"]: {
		permission: boolean
};
	["BannersDocAccessFields_editorChoiceClick_Delete"]: {
		permission: boolean
};
	["BannersDocAccessFields_editorChoiceImpression"]: {
		create?: ModelTypes["BannersDocAccessFields_editorChoiceImpression_Create"] | undefined,
	read?: ModelTypes["BannersDocAccessFields_editorChoiceImpression_Read"] | undefined,
	update?: ModelTypes["BannersDocAccessFields_editorChoiceImpression_Update"] | undefined,
	delete?: ModelTypes["BannersDocAccessFields_editorChoiceImpression_Delete"] | undefined
};
	["BannersDocAccessFields_editorChoiceImpression_Create"]: {
		permission: boolean
};
	["BannersDocAccessFields_editorChoiceImpression_Read"]: {
		permission: boolean
};
	["BannersDocAccessFields_editorChoiceImpression_Update"]: {
		permission: boolean
};
	["BannersDocAccessFields_editorChoiceImpression_Delete"]: {
		permission: boolean
};
	["BannersDocAccessFields_eventListClick"]: {
		create?: ModelTypes["BannersDocAccessFields_eventListClick_Create"] | undefined,
	read?: ModelTypes["BannersDocAccessFields_eventListClick_Read"] | undefined,
	update?: ModelTypes["BannersDocAccessFields_eventListClick_Update"] | undefined,
	delete?: ModelTypes["BannersDocAccessFields_eventListClick_Delete"] | undefined
};
	["BannersDocAccessFields_eventListClick_Create"]: {
		permission: boolean
};
	["BannersDocAccessFields_eventListClick_Read"]: {
		permission: boolean
};
	["BannersDocAccessFields_eventListClick_Update"]: {
		permission: boolean
};
	["BannersDocAccessFields_eventListClick_Delete"]: {
		permission: boolean
};
	["BannersDocAccessFields_eventListImpression"]: {
		create?: ModelTypes["BannersDocAccessFields_eventListImpression_Create"] | undefined,
	read?: ModelTypes["BannersDocAccessFields_eventListImpression_Read"] | undefined,
	update?: ModelTypes["BannersDocAccessFields_eventListImpression_Update"] | undefined,
	delete?: ModelTypes["BannersDocAccessFields_eventListImpression_Delete"] | undefined
};
	["BannersDocAccessFields_eventListImpression_Create"]: {
		permission: boolean
};
	["BannersDocAccessFields_eventListImpression_Read"]: {
		permission: boolean
};
	["BannersDocAccessFields_eventListImpression_Update"]: {
		permission: boolean
};
	["BannersDocAccessFields_eventListImpression_Delete"]: {
		permission: boolean
};
	["BannersDocAccessFields_topTenClick"]: {
		create?: ModelTypes["BannersDocAccessFields_topTenClick_Create"] | undefined,
	read?: ModelTypes["BannersDocAccessFields_topTenClick_Read"] | undefined,
	update?: ModelTypes["BannersDocAccessFields_topTenClick_Update"] | undefined,
	delete?: ModelTypes["BannersDocAccessFields_topTenClick_Delete"] | undefined
};
	["BannersDocAccessFields_topTenClick_Create"]: {
		permission: boolean
};
	["BannersDocAccessFields_topTenClick_Read"]: {
		permission: boolean
};
	["BannersDocAccessFields_topTenClick_Update"]: {
		permission: boolean
};
	["BannersDocAccessFields_topTenClick_Delete"]: {
		permission: boolean
};
	["BannersDocAccessFields_topTenImpression"]: {
		create?: ModelTypes["BannersDocAccessFields_topTenImpression_Create"] | undefined,
	read?: ModelTypes["BannersDocAccessFields_topTenImpression_Read"] | undefined,
	update?: ModelTypes["BannersDocAccessFields_topTenImpression_Update"] | undefined,
	delete?: ModelTypes["BannersDocAccessFields_topTenImpression_Delete"] | undefined
};
	["BannersDocAccessFields_topTenImpression_Create"]: {
		permission: boolean
};
	["BannersDocAccessFields_topTenImpression_Read"]: {
		permission: boolean
};
	["BannersDocAccessFields_topTenImpression_Update"]: {
		permission: boolean
};
	["BannersDocAccessFields_topTenImpression_Delete"]: {
		permission: boolean
};
	["BannersDocAccessFields_miscClick"]: {
		create?: ModelTypes["BannersDocAccessFields_miscClick_Create"] | undefined,
	read?: ModelTypes["BannersDocAccessFields_miscClick_Read"] | undefined,
	update?: ModelTypes["BannersDocAccessFields_miscClick_Update"] | undefined,
	delete?: ModelTypes["BannersDocAccessFields_miscClick_Delete"] | undefined
};
	["BannersDocAccessFields_miscClick_Create"]: {
		permission: boolean
};
	["BannersDocAccessFields_miscClick_Read"]: {
		permission: boolean
};
	["BannersDocAccessFields_miscClick_Update"]: {
		permission: boolean
};
	["BannersDocAccessFields_miscClick_Delete"]: {
		permission: boolean
};
	["BannersDocAccessFields_miscImpression"]: {
		create?: ModelTypes["BannersDocAccessFields_miscImpression_Create"] | undefined,
	read?: ModelTypes["BannersDocAccessFields_miscImpression_Read"] | undefined,
	update?: ModelTypes["BannersDocAccessFields_miscImpression_Update"] | undefined,
	delete?: ModelTypes["BannersDocAccessFields_miscImpression_Delete"] | undefined
};
	["BannersDocAccessFields_miscImpression_Create"]: {
		permission: boolean
};
	["BannersDocAccessFields_miscImpression_Read"]: {
		permission: boolean
};
	["BannersDocAccessFields_miscImpression_Update"]: {
		permission: boolean
};
	["BannersDocAccessFields_miscImpression_Delete"]: {
		permission: boolean
};
	["BannersDocAccessFields_clickTotal"]: {
		create?: ModelTypes["BannersDocAccessFields_clickTotal_Create"] | undefined,
	read?: ModelTypes["BannersDocAccessFields_clickTotal_Read"] | undefined,
	update?: ModelTypes["BannersDocAccessFields_clickTotal_Update"] | undefined,
	delete?: ModelTypes["BannersDocAccessFields_clickTotal_Delete"] | undefined
};
	["BannersDocAccessFields_clickTotal_Create"]: {
		permission: boolean
};
	["BannersDocAccessFields_clickTotal_Read"]: {
		permission: boolean
};
	["BannersDocAccessFields_clickTotal_Update"]: {
		permission: boolean
};
	["BannersDocAccessFields_clickTotal_Delete"]: {
		permission: boolean
};
	["BannersDocAccessFields_totalImpression"]: {
		create?: ModelTypes["BannersDocAccessFields_totalImpression_Create"] | undefined,
	read?: ModelTypes["BannersDocAccessFields_totalImpression_Read"] | undefined,
	update?: ModelTypes["BannersDocAccessFields_totalImpression_Update"] | undefined,
	delete?: ModelTypes["BannersDocAccessFields_totalImpression_Delete"] | undefined
};
	["BannersDocAccessFields_totalImpression_Create"]: {
		permission: boolean
};
	["BannersDocAccessFields_totalImpression_Read"]: {
		permission: boolean
};
	["BannersDocAccessFields_totalImpression_Update"]: {
		permission: boolean
};
	["BannersDocAccessFields_totalImpression_Delete"]: {
		permission: boolean
};
	["BannersDocAccessFields_updatedAt"]: {
		create?: ModelTypes["BannersDocAccessFields_updatedAt_Create"] | undefined,
	read?: ModelTypes["BannersDocAccessFields_updatedAt_Read"] | undefined,
	update?: ModelTypes["BannersDocAccessFields_updatedAt_Update"] | undefined,
	delete?: ModelTypes["BannersDocAccessFields_updatedAt_Delete"] | undefined
};
	["BannersDocAccessFields_updatedAt_Create"]: {
		permission: boolean
};
	["BannersDocAccessFields_updatedAt_Read"]: {
		permission: boolean
};
	["BannersDocAccessFields_updatedAt_Update"]: {
		permission: boolean
};
	["BannersDocAccessFields_updatedAt_Delete"]: {
		permission: boolean
};
	["BannersDocAccessFields_createdAt"]: {
		create?: ModelTypes["BannersDocAccessFields_createdAt_Create"] | undefined,
	read?: ModelTypes["BannersDocAccessFields_createdAt_Read"] | undefined,
	update?: ModelTypes["BannersDocAccessFields_createdAt_Update"] | undefined,
	delete?: ModelTypes["BannersDocAccessFields_createdAt_Delete"] | undefined
};
	["BannersDocAccessFields_createdAt_Create"]: {
		permission: boolean
};
	["BannersDocAccessFields_createdAt_Read"]: {
		permission: boolean
};
	["BannersDocAccessFields_createdAt_Update"]: {
		permission: boolean
};
	["BannersDocAccessFields_createdAt_Delete"]: {
		permission: boolean
};
	["BannersCreateDocAccess"]: {
		permission: boolean,
	where?: ModelTypes["JSONObject"] | undefined
};
	["BannersReadDocAccess"]: {
		permission: boolean,
	where?: ModelTypes["JSONObject"] | undefined
};
	["BannersUpdateDocAccess"]: {
		permission: boolean,
	where?: ModelTypes["JSONObject"] | undefined
};
	["BannersDeleteDocAccess"]: {
		permission: boolean,
	where?: ModelTypes["JSONObject"] | undefined
};
	["Campaigns"]: {
		docs?: Array<ModelTypes["Campaign"] | undefined> | undefined,
	hasNextPage?: boolean | undefined,
	hasPrevPage?: boolean | undefined,
	limit?: number | undefined,
	nextPage?: number | undefined,
	offset?: number | undefined,
	page?: number | undefined,
	pagingCounter?: number | undefined,
	prevPage?: number | undefined,
	totalDocs?: number | undefined,
	totalPages?: number | undefined
};
	["Campaign_where"]: {
	creator?: ModelTypes["Campaign_creator_Relation"] | undefined,
	invoiceNumber?: ModelTypes["Campaign_invoiceNumber_operator"] | undefined,
	quotationNumber?: ModelTypes["Campaign_quotationNumber_operator"] | undefined,
	campaignName?: ModelTypes["Campaign_campaignName_operator"] | undefined,
	client?: ModelTypes["Campaign_client_operator"] | undefined,
	contactPerson?: ModelTypes["Campaign_contactPerson_operator"] | undefined,
	email?: ModelTypes["Campaign_email_operator"] | undefined,
	phone?: ModelTypes["Campaign_phone_operator"] | undefined,
	agentName?: ModelTypes["Campaign_agentName_operator"] | undefined,
	agentCommission?: ModelTypes["Campaign_agentCommission_operator"] | undefined,
	agentOrderNumber?: ModelTypes["Campaign_agentOrderNumber_operator"] | undefined,
	_firstPublishedTime?: ModelTypes["Campaign__firstPublishedTime_operator"] | undefined,
	updatedAt?: ModelTypes["Campaign_updatedAt_operator"] | undefined,
	createdAt?: ModelTypes["Campaign_createdAt_operator"] | undefined,
	id?: ModelTypes["Campaign_id_operator"] | undefined,
	AND?: Array<ModelTypes["Campaign_where_and"] | undefined> | undefined,
	OR?: Array<ModelTypes["Campaign_where_or"] | undefined> | undefined
};
	["Campaign_creator_Relation"]: {
	relationTo?: ModelTypes["Campaign_creator_Relation_RelationTo"] | undefined,
	value?: ModelTypes["JSON"] | undefined
};
	["Campaign_creator_Relation_RelationTo"]:Campaign_creator_Relation_RelationTo;
	["Campaign_invoiceNumber_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Campaign_quotationNumber_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Campaign_campaignName_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined
};
	["Campaign_client_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined
};
	["Campaign_contactPerson_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined
};
	["Campaign_email_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined
};
	["Campaign_phone_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined
};
	["Campaign_agentName_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Campaign_agentCommission_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined
};
	["Campaign_agentOrderNumber_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Campaign__firstPublishedTime_operator"]: {
	equals?: ModelTypes["DateTime"] | undefined,
	not_equals?: ModelTypes["DateTime"] | undefined,
	greater_than_equal?: ModelTypes["DateTime"] | undefined,
	greater_than?: ModelTypes["DateTime"] | undefined,
	less_than_equal?: ModelTypes["DateTime"] | undefined,
	less_than?: ModelTypes["DateTime"] | undefined,
	like?: ModelTypes["DateTime"] | undefined,
	exists?: boolean | undefined
};
	["Campaign_updatedAt_operator"]: {
	equals?: ModelTypes["DateTime"] | undefined,
	not_equals?: ModelTypes["DateTime"] | undefined,
	greater_than_equal?: ModelTypes["DateTime"] | undefined,
	greater_than?: ModelTypes["DateTime"] | undefined,
	less_than_equal?: ModelTypes["DateTime"] | undefined,
	less_than?: ModelTypes["DateTime"] | undefined,
	like?: ModelTypes["DateTime"] | undefined,
	exists?: boolean | undefined
};
	["Campaign_createdAt_operator"]: {
	equals?: ModelTypes["DateTime"] | undefined,
	not_equals?: ModelTypes["DateTime"] | undefined,
	greater_than_equal?: ModelTypes["DateTime"] | undefined,
	greater_than?: ModelTypes["DateTime"] | undefined,
	less_than_equal?: ModelTypes["DateTime"] | undefined,
	less_than?: ModelTypes["DateTime"] | undefined,
	like?: ModelTypes["DateTime"] | undefined,
	exists?: boolean | undefined
};
	["Campaign_id_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Campaign_where_and"]: {
	creator?: ModelTypes["Campaign_creator_Relation"] | undefined,
	invoiceNumber?: ModelTypes["Campaign_invoiceNumber_operator"] | undefined,
	quotationNumber?: ModelTypes["Campaign_quotationNumber_operator"] | undefined,
	campaignName?: ModelTypes["Campaign_campaignName_operator"] | undefined,
	client?: ModelTypes["Campaign_client_operator"] | undefined,
	contactPerson?: ModelTypes["Campaign_contactPerson_operator"] | undefined,
	email?: ModelTypes["Campaign_email_operator"] | undefined,
	phone?: ModelTypes["Campaign_phone_operator"] | undefined,
	agentName?: ModelTypes["Campaign_agentName_operator"] | undefined,
	agentCommission?: ModelTypes["Campaign_agentCommission_operator"] | undefined,
	agentOrderNumber?: ModelTypes["Campaign_agentOrderNumber_operator"] | undefined,
	_firstPublishedTime?: ModelTypes["Campaign__firstPublishedTime_operator"] | undefined,
	updatedAt?: ModelTypes["Campaign_updatedAt_operator"] | undefined,
	createdAt?: ModelTypes["Campaign_createdAt_operator"] | undefined,
	id?: ModelTypes["Campaign_id_operator"] | undefined,
	AND?: Array<ModelTypes["Campaign_where_and"] | undefined> | undefined,
	OR?: Array<ModelTypes["Campaign_where_or"] | undefined> | undefined
};
	["Campaign_where_or"]: {
	creator?: ModelTypes["Campaign_creator_Relation"] | undefined,
	invoiceNumber?: ModelTypes["Campaign_invoiceNumber_operator"] | undefined,
	quotationNumber?: ModelTypes["Campaign_quotationNumber_operator"] | undefined,
	campaignName?: ModelTypes["Campaign_campaignName_operator"] | undefined,
	client?: ModelTypes["Campaign_client_operator"] | undefined,
	contactPerson?: ModelTypes["Campaign_contactPerson_operator"] | undefined,
	email?: ModelTypes["Campaign_email_operator"] | undefined,
	phone?: ModelTypes["Campaign_phone_operator"] | undefined,
	agentName?: ModelTypes["Campaign_agentName_operator"] | undefined,
	agentCommission?: ModelTypes["Campaign_agentCommission_operator"] | undefined,
	agentOrderNumber?: ModelTypes["Campaign_agentOrderNumber_operator"] | undefined,
	_firstPublishedTime?: ModelTypes["Campaign__firstPublishedTime_operator"] | undefined,
	updatedAt?: ModelTypes["Campaign_updatedAt_operator"] | undefined,
	createdAt?: ModelTypes["Campaign_createdAt_operator"] | undefined,
	id?: ModelTypes["Campaign_id_operator"] | undefined,
	AND?: Array<ModelTypes["Campaign_where_and"] | undefined> | undefined,
	OR?: Array<ModelTypes["Campaign_where_or"] | undefined> | undefined
};
	["countCampaigns"]: {
		totalDocs?: number | undefined
};
	["campaignsDocAccess"]: {
		fields?: ModelTypes["CampaignsDocAccessFields"] | undefined,
	create?: ModelTypes["CampaignsCreateDocAccess"] | undefined,
	read?: ModelTypes["CampaignsReadDocAccess"] | undefined,
	update?: ModelTypes["CampaignsUpdateDocAccess"] | undefined,
	delete?: ModelTypes["CampaignsDeleteDocAccess"] | undefined
};
	["CampaignsDocAccessFields"]: {
		creator?: ModelTypes["CampaignsDocAccessFields_creator"] | undefined,
	invoiceNumber?: ModelTypes["CampaignsDocAccessFields_invoiceNumber"] | undefined,
	quotationNumber?: ModelTypes["CampaignsDocAccessFields_quotationNumber"] | undefined,
	campaignName?: ModelTypes["CampaignsDocAccessFields_campaignName"] | undefined,
	client?: ModelTypes["CampaignsDocAccessFields_client"] | undefined,
	contactPerson?: ModelTypes["CampaignsDocAccessFields_contactPerson"] | undefined,
	email?: ModelTypes["CampaignsDocAccessFields_email"] | undefined,
	phone?: ModelTypes["CampaignsDocAccessFields_phone"] | undefined,
	agentName?: ModelTypes["CampaignsDocAccessFields_agentName"] | undefined,
	agentCommission?: ModelTypes["CampaignsDocAccessFields_agentCommission"] | undefined,
	agentOrderNumber?: ModelTypes["CampaignsDocAccessFields_agentOrderNumber"] | undefined,
	_firstPublishedTime?: ModelTypes["CampaignsDocAccessFields__firstPublishedTime"] | undefined,
	updatedAt?: ModelTypes["CampaignsDocAccessFields_updatedAt"] | undefined,
	createdAt?: ModelTypes["CampaignsDocAccessFields_createdAt"] | undefined
};
	["CampaignsDocAccessFields_creator"]: {
		create?: ModelTypes["CampaignsDocAccessFields_creator_Create"] | undefined,
	read?: ModelTypes["CampaignsDocAccessFields_creator_Read"] | undefined,
	update?: ModelTypes["CampaignsDocAccessFields_creator_Update"] | undefined,
	delete?: ModelTypes["CampaignsDocAccessFields_creator_Delete"] | undefined
};
	["CampaignsDocAccessFields_creator_Create"]: {
		permission: boolean
};
	["CampaignsDocAccessFields_creator_Read"]: {
		permission: boolean
};
	["CampaignsDocAccessFields_creator_Update"]: {
		permission: boolean
};
	["CampaignsDocAccessFields_creator_Delete"]: {
		permission: boolean
};
	["CampaignsDocAccessFields_invoiceNumber"]: {
		create?: ModelTypes["CampaignsDocAccessFields_invoiceNumber_Create"] | undefined,
	read?: ModelTypes["CampaignsDocAccessFields_invoiceNumber_Read"] | undefined,
	update?: ModelTypes["CampaignsDocAccessFields_invoiceNumber_Update"] | undefined,
	delete?: ModelTypes["CampaignsDocAccessFields_invoiceNumber_Delete"] | undefined
};
	["CampaignsDocAccessFields_invoiceNumber_Create"]: {
		permission: boolean
};
	["CampaignsDocAccessFields_invoiceNumber_Read"]: {
		permission: boolean
};
	["CampaignsDocAccessFields_invoiceNumber_Update"]: {
		permission: boolean
};
	["CampaignsDocAccessFields_invoiceNumber_Delete"]: {
		permission: boolean
};
	["CampaignsDocAccessFields_quotationNumber"]: {
		create?: ModelTypes["CampaignsDocAccessFields_quotationNumber_Create"] | undefined,
	read?: ModelTypes["CampaignsDocAccessFields_quotationNumber_Read"] | undefined,
	update?: ModelTypes["CampaignsDocAccessFields_quotationNumber_Update"] | undefined,
	delete?: ModelTypes["CampaignsDocAccessFields_quotationNumber_Delete"] | undefined
};
	["CampaignsDocAccessFields_quotationNumber_Create"]: {
		permission: boolean
};
	["CampaignsDocAccessFields_quotationNumber_Read"]: {
		permission: boolean
};
	["CampaignsDocAccessFields_quotationNumber_Update"]: {
		permission: boolean
};
	["CampaignsDocAccessFields_quotationNumber_Delete"]: {
		permission: boolean
};
	["CampaignsDocAccessFields_campaignName"]: {
		create?: ModelTypes["CampaignsDocAccessFields_campaignName_Create"] | undefined,
	read?: ModelTypes["CampaignsDocAccessFields_campaignName_Read"] | undefined,
	update?: ModelTypes["CampaignsDocAccessFields_campaignName_Update"] | undefined,
	delete?: ModelTypes["CampaignsDocAccessFields_campaignName_Delete"] | undefined
};
	["CampaignsDocAccessFields_campaignName_Create"]: {
		permission: boolean
};
	["CampaignsDocAccessFields_campaignName_Read"]: {
		permission: boolean
};
	["CampaignsDocAccessFields_campaignName_Update"]: {
		permission: boolean
};
	["CampaignsDocAccessFields_campaignName_Delete"]: {
		permission: boolean
};
	["CampaignsDocAccessFields_client"]: {
		create?: ModelTypes["CampaignsDocAccessFields_client_Create"] | undefined,
	read?: ModelTypes["CampaignsDocAccessFields_client_Read"] | undefined,
	update?: ModelTypes["CampaignsDocAccessFields_client_Update"] | undefined,
	delete?: ModelTypes["CampaignsDocAccessFields_client_Delete"] | undefined
};
	["CampaignsDocAccessFields_client_Create"]: {
		permission: boolean
};
	["CampaignsDocAccessFields_client_Read"]: {
		permission: boolean
};
	["CampaignsDocAccessFields_client_Update"]: {
		permission: boolean
};
	["CampaignsDocAccessFields_client_Delete"]: {
		permission: boolean
};
	["CampaignsDocAccessFields_contactPerson"]: {
		create?: ModelTypes["CampaignsDocAccessFields_contactPerson_Create"] | undefined,
	read?: ModelTypes["CampaignsDocAccessFields_contactPerson_Read"] | undefined,
	update?: ModelTypes["CampaignsDocAccessFields_contactPerson_Update"] | undefined,
	delete?: ModelTypes["CampaignsDocAccessFields_contactPerson_Delete"] | undefined
};
	["CampaignsDocAccessFields_contactPerson_Create"]: {
		permission: boolean
};
	["CampaignsDocAccessFields_contactPerson_Read"]: {
		permission: boolean
};
	["CampaignsDocAccessFields_contactPerson_Update"]: {
		permission: boolean
};
	["CampaignsDocAccessFields_contactPerson_Delete"]: {
		permission: boolean
};
	["CampaignsDocAccessFields_email"]: {
		create?: ModelTypes["CampaignsDocAccessFields_email_Create"] | undefined,
	read?: ModelTypes["CampaignsDocAccessFields_email_Read"] | undefined,
	update?: ModelTypes["CampaignsDocAccessFields_email_Update"] | undefined,
	delete?: ModelTypes["CampaignsDocAccessFields_email_Delete"] | undefined
};
	["CampaignsDocAccessFields_email_Create"]: {
		permission: boolean
};
	["CampaignsDocAccessFields_email_Read"]: {
		permission: boolean
};
	["CampaignsDocAccessFields_email_Update"]: {
		permission: boolean
};
	["CampaignsDocAccessFields_email_Delete"]: {
		permission: boolean
};
	["CampaignsDocAccessFields_phone"]: {
		create?: ModelTypes["CampaignsDocAccessFields_phone_Create"] | undefined,
	read?: ModelTypes["CampaignsDocAccessFields_phone_Read"] | undefined,
	update?: ModelTypes["CampaignsDocAccessFields_phone_Update"] | undefined,
	delete?: ModelTypes["CampaignsDocAccessFields_phone_Delete"] | undefined
};
	["CampaignsDocAccessFields_phone_Create"]: {
		permission: boolean
};
	["CampaignsDocAccessFields_phone_Read"]: {
		permission: boolean
};
	["CampaignsDocAccessFields_phone_Update"]: {
		permission: boolean
};
	["CampaignsDocAccessFields_phone_Delete"]: {
		permission: boolean
};
	["CampaignsDocAccessFields_agentName"]: {
		create?: ModelTypes["CampaignsDocAccessFields_agentName_Create"] | undefined,
	read?: ModelTypes["CampaignsDocAccessFields_agentName_Read"] | undefined,
	update?: ModelTypes["CampaignsDocAccessFields_agentName_Update"] | undefined,
	delete?: ModelTypes["CampaignsDocAccessFields_agentName_Delete"] | undefined
};
	["CampaignsDocAccessFields_agentName_Create"]: {
		permission: boolean
};
	["CampaignsDocAccessFields_agentName_Read"]: {
		permission: boolean
};
	["CampaignsDocAccessFields_agentName_Update"]: {
		permission: boolean
};
	["CampaignsDocAccessFields_agentName_Delete"]: {
		permission: boolean
};
	["CampaignsDocAccessFields_agentCommission"]: {
		create?: ModelTypes["CampaignsDocAccessFields_agentCommission_Create"] | undefined,
	read?: ModelTypes["CampaignsDocAccessFields_agentCommission_Read"] | undefined,
	update?: ModelTypes["CampaignsDocAccessFields_agentCommission_Update"] | undefined,
	delete?: ModelTypes["CampaignsDocAccessFields_agentCommission_Delete"] | undefined
};
	["CampaignsDocAccessFields_agentCommission_Create"]: {
		permission: boolean
};
	["CampaignsDocAccessFields_agentCommission_Read"]: {
		permission: boolean
};
	["CampaignsDocAccessFields_agentCommission_Update"]: {
		permission: boolean
};
	["CampaignsDocAccessFields_agentCommission_Delete"]: {
		permission: boolean
};
	["CampaignsDocAccessFields_agentOrderNumber"]: {
		create?: ModelTypes["CampaignsDocAccessFields_agentOrderNumber_Create"] | undefined,
	read?: ModelTypes["CampaignsDocAccessFields_agentOrderNumber_Read"] | undefined,
	update?: ModelTypes["CampaignsDocAccessFields_agentOrderNumber_Update"] | undefined,
	delete?: ModelTypes["CampaignsDocAccessFields_agentOrderNumber_Delete"] | undefined
};
	["CampaignsDocAccessFields_agentOrderNumber_Create"]: {
		permission: boolean
};
	["CampaignsDocAccessFields_agentOrderNumber_Read"]: {
		permission: boolean
};
	["CampaignsDocAccessFields_agentOrderNumber_Update"]: {
		permission: boolean
};
	["CampaignsDocAccessFields_agentOrderNumber_Delete"]: {
		permission: boolean
};
	["CampaignsDocAccessFields__firstPublishedTime"]: {
		create?: ModelTypes["CampaignsDocAccessFields__firstPublishedTime_Create"] | undefined,
	read?: ModelTypes["CampaignsDocAccessFields__firstPublishedTime_Read"] | undefined,
	update?: ModelTypes["CampaignsDocAccessFields__firstPublishedTime_Update"] | undefined,
	delete?: ModelTypes["CampaignsDocAccessFields__firstPublishedTime_Delete"] | undefined
};
	["CampaignsDocAccessFields__firstPublishedTime_Create"]: {
		permission: boolean
};
	["CampaignsDocAccessFields__firstPublishedTime_Read"]: {
		permission: boolean
};
	["CampaignsDocAccessFields__firstPublishedTime_Update"]: {
		permission: boolean
};
	["CampaignsDocAccessFields__firstPublishedTime_Delete"]: {
		permission: boolean
};
	["CampaignsDocAccessFields_updatedAt"]: {
		create?: ModelTypes["CampaignsDocAccessFields_updatedAt_Create"] | undefined,
	read?: ModelTypes["CampaignsDocAccessFields_updatedAt_Read"] | undefined,
	update?: ModelTypes["CampaignsDocAccessFields_updatedAt_Update"] | undefined,
	delete?: ModelTypes["CampaignsDocAccessFields_updatedAt_Delete"] | undefined
};
	["CampaignsDocAccessFields_updatedAt_Create"]: {
		permission: boolean
};
	["CampaignsDocAccessFields_updatedAt_Read"]: {
		permission: boolean
};
	["CampaignsDocAccessFields_updatedAt_Update"]: {
		permission: boolean
};
	["CampaignsDocAccessFields_updatedAt_Delete"]: {
		permission: boolean
};
	["CampaignsDocAccessFields_createdAt"]: {
		create?: ModelTypes["CampaignsDocAccessFields_createdAt_Create"] | undefined,
	read?: ModelTypes["CampaignsDocAccessFields_createdAt_Read"] | undefined,
	update?: ModelTypes["CampaignsDocAccessFields_createdAt_Update"] | undefined,
	delete?: ModelTypes["CampaignsDocAccessFields_createdAt_Delete"] | undefined
};
	["CampaignsDocAccessFields_createdAt_Create"]: {
		permission: boolean
};
	["CampaignsDocAccessFields_createdAt_Read"]: {
		permission: boolean
};
	["CampaignsDocAccessFields_createdAt_Update"]: {
		permission: boolean
};
	["CampaignsDocAccessFields_createdAt_Delete"]: {
		permission: boolean
};
	["CampaignsCreateDocAccess"]: {
		permission: boolean,
	where?: ModelTypes["JSONObject"] | undefined
};
	["CampaignsReadDocAccess"]: {
		permission: boolean,
	where?: ModelTypes["JSONObject"] | undefined
};
	["CampaignsUpdateDocAccess"]: {
		permission: boolean,
	where?: ModelTypes["JSONObject"] | undefined
};
	["CampaignsDeleteDocAccess"]: {
		permission: boolean,
	where?: ModelTypes["JSONObject"] | undefined
};
	["Advertise"]: {
		id?: string | undefined,
	advertise?: ModelTypes["Advertise_Advertise"] | undefined,
	advertiseStartDate: ModelTypes["DateTime"],
	advertiseEndDate: ModelTypes["DateTime"],
	type?: ModelTypes["Advertise_type"] | undefined,
	invoiceDescription?: string | undefined,
	standardPrice: number,
	discount?: number | undefined,
	discountPercentage?: number | undefined,
	_calculateByDiscount?: boolean | undefined,
	timableCost?: number | undefined,
	remark?: string | undefined,
	public?: boolean | undefined,
	campaignName?: string | undefined,
	campaignId?: string | undefined,
	status?: string | undefined,
	updatedAt?: ModelTypes["DateTime"] | undefined,
	createdAt?: ModelTypes["DateTime"] | undefined
};
	["Advertise_Advertise"]: {
		type?: ModelTypes["Advertise_Advertise_type"] | undefined,
	event?: ModelTypes["Event"] | undefined,
	keyword?: string | undefined,
	location?: ModelTypes["Location"] | undefined,
	displayPosition?: ModelTypes["Advertise_Advertise_displayPosition"] | undefined,
	rankingListRemark?: string | undefined
};
	["Advertise_Advertise_type"]:Advertise_Advertise_type;
	["Advertise_Advertise_displayPosition"]:Advertise_Advertise_displayPosition;
	["Advertise_type"]:Advertise_type;
	["Advertises"]: {
		docs?: Array<ModelTypes["Advertise"] | undefined> | undefined,
	hasNextPage?: boolean | undefined,
	hasPrevPage?: boolean | undefined,
	limit?: number | undefined,
	nextPage?: number | undefined,
	offset?: number | undefined,
	page?: number | undefined,
	pagingCounter?: number | undefined,
	prevPage?: number | undefined,
	totalDocs?: number | undefined,
	totalPages?: number | undefined
};
	["Advertise_where"]: {
	advertise__type?: ModelTypes["Advertise_advertise__type_operator"] | undefined,
	advertise__event?: ModelTypes["Advertise_advertise__event_operator"] | undefined,
	advertise__keyword?: ModelTypes["Advertise_advertise__keyword_operator"] | undefined,
	advertise__location?: ModelTypes["Advertise_advertise__location_operator"] | undefined,
	advertise__displayPosition?: ModelTypes["Advertise_advertise__displayPosition_operator"] | undefined,
	advertise__rankingListRemark?: ModelTypes["Advertise_advertise__rankingListRemark_operator"] | undefined,
	advertiseStartDate?: ModelTypes["Advertise_advertiseStartDate_operator"] | undefined,
	advertiseEndDate?: ModelTypes["Advertise_advertiseEndDate_operator"] | undefined,
	type?: ModelTypes["Advertise_type_operator"] | undefined,
	invoiceDescription?: ModelTypes["Advertise_invoiceDescription_operator"] | undefined,
	standardPrice?: ModelTypes["Advertise_standardPrice_operator"] | undefined,
	discount?: ModelTypes["Advertise_discount_operator"] | undefined,
	discountPercentage?: ModelTypes["Advertise_discountPercentage_operator"] | undefined,
	_calculateByDiscount?: ModelTypes["Advertise__calculateByDiscount_operator"] | undefined,
	timableCost?: ModelTypes["Advertise_timableCost_operator"] | undefined,
	remark?: ModelTypes["Advertise_remark_operator"] | undefined,
	public?: ModelTypes["Advertise_public_operator"] | undefined,
	campaignName?: ModelTypes["Advertise_campaignName_operator"] | undefined,
	campaignId?: ModelTypes["Advertise_campaignId_operator"] | undefined,
	status?: ModelTypes["Advertise_status_operator"] | undefined,
	updatedAt?: ModelTypes["Advertise_updatedAt_operator"] | undefined,
	createdAt?: ModelTypes["Advertise_createdAt_operator"] | undefined,
	id?: ModelTypes["Advertise_id_operator"] | undefined,
	AND?: Array<ModelTypes["Advertise_where_and"] | undefined> | undefined,
	OR?: Array<ModelTypes["Advertise_where_or"] | undefined> | undefined
};
	["Advertise_advertise__type_operator"]: {
	equals?: ModelTypes["Advertise_advertise__type_Input"] | undefined,
	not_equals?: ModelTypes["Advertise_advertise__type_Input"] | undefined,
	in?: Array<ModelTypes["Advertise_advertise__type_Input"] | undefined> | undefined,
	not_in?: Array<ModelTypes["Advertise_advertise__type_Input"] | undefined> | undefined,
	all?: Array<ModelTypes["Advertise_advertise__type_Input"] | undefined> | undefined,
	exists?: boolean | undefined
};
	["Advertise_advertise__type_Input"]:Advertise_advertise__type_Input;
	["Advertise_advertise__event_operator"]: {
	equals?: ModelTypes["JSON"] | undefined,
	not_equals?: ModelTypes["JSON"] | undefined,
	in?: Array<ModelTypes["JSON"] | undefined> | undefined,
	not_in?: Array<ModelTypes["JSON"] | undefined> | undefined,
	all?: Array<ModelTypes["JSON"] | undefined> | undefined
};
	["Advertise_advertise__keyword_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined
};
	["Advertise_advertise__location_operator"]: {
	equals?: ModelTypes["JSON"] | undefined,
	not_equals?: ModelTypes["JSON"] | undefined,
	in?: Array<ModelTypes["JSON"] | undefined> | undefined,
	not_in?: Array<ModelTypes["JSON"] | undefined> | undefined,
	all?: Array<ModelTypes["JSON"] | undefined> | undefined
};
	["Advertise_advertise__displayPosition_operator"]: {
	equals?: ModelTypes["Advertise_advertise__displayPosition_Input"] | undefined,
	not_equals?: ModelTypes["Advertise_advertise__displayPosition_Input"] | undefined,
	like?: ModelTypes["Advertise_advertise__displayPosition_Input"] | undefined,
	contains?: ModelTypes["Advertise_advertise__displayPosition_Input"] | undefined
};
	["Advertise_advertise__displayPosition_Input"]:Advertise_advertise__displayPosition_Input;
	["Advertise_advertise__rankingListRemark_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Advertise_advertiseStartDate_operator"]: {
	equals?: ModelTypes["DateTime"] | undefined,
	not_equals?: ModelTypes["DateTime"] | undefined,
	greater_than_equal?: ModelTypes["DateTime"] | undefined,
	greater_than?: ModelTypes["DateTime"] | undefined,
	less_than_equal?: ModelTypes["DateTime"] | undefined,
	less_than?: ModelTypes["DateTime"] | undefined,
	like?: ModelTypes["DateTime"] | undefined
};
	["Advertise_advertiseEndDate_operator"]: {
	equals?: ModelTypes["DateTime"] | undefined,
	not_equals?: ModelTypes["DateTime"] | undefined,
	greater_than_equal?: ModelTypes["DateTime"] | undefined,
	greater_than?: ModelTypes["DateTime"] | undefined,
	less_than_equal?: ModelTypes["DateTime"] | undefined,
	less_than?: ModelTypes["DateTime"] | undefined,
	like?: ModelTypes["DateTime"] | undefined
};
	["Advertise_type_operator"]: {
	equals?: ModelTypes["Advertise_type_Input"] | undefined,
	not_equals?: ModelTypes["Advertise_type_Input"] | undefined,
	in?: Array<ModelTypes["Advertise_type_Input"] | undefined> | undefined,
	not_in?: Array<ModelTypes["Advertise_type_Input"] | undefined> | undefined,
	all?: Array<ModelTypes["Advertise_type_Input"] | undefined> | undefined,
	exists?: boolean | undefined
};
	["Advertise_type_Input"]:Advertise_type_Input;
	["Advertise_invoiceDescription_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	exists?: boolean | undefined
};
	["Advertise_standardPrice_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined
};
	["Advertise_discount_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Advertise_discountPercentage_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Advertise__calculateByDiscount_operator"]: {
	equals?: boolean | undefined,
	not_equals?: boolean | undefined,
	exists?: boolean | undefined
};
	["Advertise_timableCost_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Advertise_remark_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	exists?: boolean | undefined
};
	["Advertise_public_operator"]: {
	equals?: boolean | undefined,
	not_equals?: boolean | undefined,
	exists?: boolean | undefined
};
	["Advertise_campaignName_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Advertise_campaignId_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Advertise_status_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Advertise_updatedAt_operator"]: {
	equals?: ModelTypes["DateTime"] | undefined,
	not_equals?: ModelTypes["DateTime"] | undefined,
	greater_than_equal?: ModelTypes["DateTime"] | undefined,
	greater_than?: ModelTypes["DateTime"] | undefined,
	less_than_equal?: ModelTypes["DateTime"] | undefined,
	less_than?: ModelTypes["DateTime"] | undefined,
	like?: ModelTypes["DateTime"] | undefined,
	exists?: boolean | undefined
};
	["Advertise_createdAt_operator"]: {
	equals?: ModelTypes["DateTime"] | undefined,
	not_equals?: ModelTypes["DateTime"] | undefined,
	greater_than_equal?: ModelTypes["DateTime"] | undefined,
	greater_than?: ModelTypes["DateTime"] | undefined,
	less_than_equal?: ModelTypes["DateTime"] | undefined,
	less_than?: ModelTypes["DateTime"] | undefined,
	like?: ModelTypes["DateTime"] | undefined,
	exists?: boolean | undefined
};
	["Advertise_id_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Advertise_where_and"]: {
	advertise__type?: ModelTypes["Advertise_advertise__type_operator"] | undefined,
	advertise__event?: ModelTypes["Advertise_advertise__event_operator"] | undefined,
	advertise__keyword?: ModelTypes["Advertise_advertise__keyword_operator"] | undefined,
	advertise__location?: ModelTypes["Advertise_advertise__location_operator"] | undefined,
	advertise__displayPosition?: ModelTypes["Advertise_advertise__displayPosition_operator"] | undefined,
	advertise__rankingListRemark?: ModelTypes["Advertise_advertise__rankingListRemark_operator"] | undefined,
	advertiseStartDate?: ModelTypes["Advertise_advertiseStartDate_operator"] | undefined,
	advertiseEndDate?: ModelTypes["Advertise_advertiseEndDate_operator"] | undefined,
	type?: ModelTypes["Advertise_type_operator"] | undefined,
	invoiceDescription?: ModelTypes["Advertise_invoiceDescription_operator"] | undefined,
	standardPrice?: ModelTypes["Advertise_standardPrice_operator"] | undefined,
	discount?: ModelTypes["Advertise_discount_operator"] | undefined,
	discountPercentage?: ModelTypes["Advertise_discountPercentage_operator"] | undefined,
	_calculateByDiscount?: ModelTypes["Advertise__calculateByDiscount_operator"] | undefined,
	timableCost?: ModelTypes["Advertise_timableCost_operator"] | undefined,
	remark?: ModelTypes["Advertise_remark_operator"] | undefined,
	public?: ModelTypes["Advertise_public_operator"] | undefined,
	campaignName?: ModelTypes["Advertise_campaignName_operator"] | undefined,
	campaignId?: ModelTypes["Advertise_campaignId_operator"] | undefined,
	status?: ModelTypes["Advertise_status_operator"] | undefined,
	updatedAt?: ModelTypes["Advertise_updatedAt_operator"] | undefined,
	createdAt?: ModelTypes["Advertise_createdAt_operator"] | undefined,
	id?: ModelTypes["Advertise_id_operator"] | undefined,
	AND?: Array<ModelTypes["Advertise_where_and"] | undefined> | undefined,
	OR?: Array<ModelTypes["Advertise_where_or"] | undefined> | undefined
};
	["Advertise_where_or"]: {
	advertise__type?: ModelTypes["Advertise_advertise__type_operator"] | undefined,
	advertise__event?: ModelTypes["Advertise_advertise__event_operator"] | undefined,
	advertise__keyword?: ModelTypes["Advertise_advertise__keyword_operator"] | undefined,
	advertise__location?: ModelTypes["Advertise_advertise__location_operator"] | undefined,
	advertise__displayPosition?: ModelTypes["Advertise_advertise__displayPosition_operator"] | undefined,
	advertise__rankingListRemark?: ModelTypes["Advertise_advertise__rankingListRemark_operator"] | undefined,
	advertiseStartDate?: ModelTypes["Advertise_advertiseStartDate_operator"] | undefined,
	advertiseEndDate?: ModelTypes["Advertise_advertiseEndDate_operator"] | undefined,
	type?: ModelTypes["Advertise_type_operator"] | undefined,
	invoiceDescription?: ModelTypes["Advertise_invoiceDescription_operator"] | undefined,
	standardPrice?: ModelTypes["Advertise_standardPrice_operator"] | undefined,
	discount?: ModelTypes["Advertise_discount_operator"] | undefined,
	discountPercentage?: ModelTypes["Advertise_discountPercentage_operator"] | undefined,
	_calculateByDiscount?: ModelTypes["Advertise__calculateByDiscount_operator"] | undefined,
	timableCost?: ModelTypes["Advertise_timableCost_operator"] | undefined,
	remark?: ModelTypes["Advertise_remark_operator"] | undefined,
	public?: ModelTypes["Advertise_public_operator"] | undefined,
	campaignName?: ModelTypes["Advertise_campaignName_operator"] | undefined,
	campaignId?: ModelTypes["Advertise_campaignId_operator"] | undefined,
	status?: ModelTypes["Advertise_status_operator"] | undefined,
	updatedAt?: ModelTypes["Advertise_updatedAt_operator"] | undefined,
	createdAt?: ModelTypes["Advertise_createdAt_operator"] | undefined,
	id?: ModelTypes["Advertise_id_operator"] | undefined,
	AND?: Array<ModelTypes["Advertise_where_and"] | undefined> | undefined,
	OR?: Array<ModelTypes["Advertise_where_or"] | undefined> | undefined
};
	["countAdvertises"]: {
		totalDocs?: number | undefined
};
	["advertisesDocAccess"]: {
		fields?: ModelTypes["AdvertisesDocAccessFields"] | undefined,
	create?: ModelTypes["AdvertisesCreateDocAccess"] | undefined,
	read?: ModelTypes["AdvertisesReadDocAccess"] | undefined,
	update?: ModelTypes["AdvertisesUpdateDocAccess"] | undefined,
	delete?: ModelTypes["AdvertisesDeleteDocAccess"] | undefined
};
	["AdvertisesDocAccessFields"]: {
		advertise?: ModelTypes["AdvertisesDocAccessFields_advertise"] | undefined,
	advertiseStartDate?: ModelTypes["AdvertisesDocAccessFields_advertiseStartDate"] | undefined,
	advertiseEndDate?: ModelTypes["AdvertisesDocAccessFields_advertiseEndDate"] | undefined,
	type?: ModelTypes["AdvertisesDocAccessFields_type"] | undefined,
	invoiceDescription?: ModelTypes["AdvertisesDocAccessFields_invoiceDescription"] | undefined,
	standardPrice?: ModelTypes["AdvertisesDocAccessFields_standardPrice"] | undefined,
	discount?: ModelTypes["AdvertisesDocAccessFields_discount"] | undefined,
	discountPercentage?: ModelTypes["AdvertisesDocAccessFields_discountPercentage"] | undefined,
	_calculateByDiscount?: ModelTypes["AdvertisesDocAccessFields__calculateByDiscount"] | undefined,
	timableCost?: ModelTypes["AdvertisesDocAccessFields_timableCost"] | undefined,
	remark?: ModelTypes["AdvertisesDocAccessFields_remark"] | undefined,
	public?: ModelTypes["AdvertisesDocAccessFields_public"] | undefined,
	campaignName?: ModelTypes["AdvertisesDocAccessFields_campaignName"] | undefined,
	campaignId?: ModelTypes["AdvertisesDocAccessFields_campaignId"] | undefined,
	status?: ModelTypes["AdvertisesDocAccessFields_status"] | undefined,
	updatedAt?: ModelTypes["AdvertisesDocAccessFields_updatedAt"] | undefined,
	createdAt?: ModelTypes["AdvertisesDocAccessFields_createdAt"] | undefined
};
	["AdvertisesDocAccessFields_advertise"]: {
		create?: ModelTypes["AdvertisesDocAccessFields_advertise_Create"] | undefined,
	read?: ModelTypes["AdvertisesDocAccessFields_advertise_Read"] | undefined,
	update?: ModelTypes["AdvertisesDocAccessFields_advertise_Update"] | undefined,
	delete?: ModelTypes["AdvertisesDocAccessFields_advertise_Delete"] | undefined,
	fields?: ModelTypes["AdvertisesDocAccessFields_advertise_Fields"] | undefined
};
	["AdvertisesDocAccessFields_advertise_Create"]: {
		permission: boolean
};
	["AdvertisesDocAccessFields_advertise_Read"]: {
		permission: boolean
};
	["AdvertisesDocAccessFields_advertise_Update"]: {
		permission: boolean
};
	["AdvertisesDocAccessFields_advertise_Delete"]: {
		permission: boolean
};
	["AdvertisesDocAccessFields_advertise_Fields"]: {
		type?: ModelTypes["AdvertisesDocAccessFields_advertise_type"] | undefined,
	event?: ModelTypes["AdvertisesDocAccessFields_advertise_event"] | undefined,
	keyword?: ModelTypes["AdvertisesDocAccessFields_advertise_keyword"] | undefined,
	location?: ModelTypes["AdvertisesDocAccessFields_advertise_location"] | undefined,
	displayPosition?: ModelTypes["AdvertisesDocAccessFields_advertise_displayPosition"] | undefined,
	rankingListRemark?: ModelTypes["AdvertisesDocAccessFields_advertise_rankingListRemark"] | undefined
};
	["AdvertisesDocAccessFields_advertise_type"]: {
		create?: ModelTypes["AdvertisesDocAccessFields_advertise_type_Create"] | undefined,
	read?: ModelTypes["AdvertisesDocAccessFields_advertise_type_Read"] | undefined,
	update?: ModelTypes["AdvertisesDocAccessFields_advertise_type_Update"] | undefined,
	delete?: ModelTypes["AdvertisesDocAccessFields_advertise_type_Delete"] | undefined
};
	["AdvertisesDocAccessFields_advertise_type_Create"]: {
		permission: boolean
};
	["AdvertisesDocAccessFields_advertise_type_Read"]: {
		permission: boolean
};
	["AdvertisesDocAccessFields_advertise_type_Update"]: {
		permission: boolean
};
	["AdvertisesDocAccessFields_advertise_type_Delete"]: {
		permission: boolean
};
	["AdvertisesDocAccessFields_advertise_event"]: {
		create?: ModelTypes["AdvertisesDocAccessFields_advertise_event_Create"] | undefined,
	read?: ModelTypes["AdvertisesDocAccessFields_advertise_event_Read"] | undefined,
	update?: ModelTypes["AdvertisesDocAccessFields_advertise_event_Update"] | undefined,
	delete?: ModelTypes["AdvertisesDocAccessFields_advertise_event_Delete"] | undefined
};
	["AdvertisesDocAccessFields_advertise_event_Create"]: {
		permission: boolean
};
	["AdvertisesDocAccessFields_advertise_event_Read"]: {
		permission: boolean
};
	["AdvertisesDocAccessFields_advertise_event_Update"]: {
		permission: boolean
};
	["AdvertisesDocAccessFields_advertise_event_Delete"]: {
		permission: boolean
};
	["AdvertisesDocAccessFields_advertise_keyword"]: {
		create?: ModelTypes["AdvertisesDocAccessFields_advertise_keyword_Create"] | undefined,
	read?: ModelTypes["AdvertisesDocAccessFields_advertise_keyword_Read"] | undefined,
	update?: ModelTypes["AdvertisesDocAccessFields_advertise_keyword_Update"] | undefined,
	delete?: ModelTypes["AdvertisesDocAccessFields_advertise_keyword_Delete"] | undefined
};
	["AdvertisesDocAccessFields_advertise_keyword_Create"]: {
		permission: boolean
};
	["AdvertisesDocAccessFields_advertise_keyword_Read"]: {
		permission: boolean
};
	["AdvertisesDocAccessFields_advertise_keyword_Update"]: {
		permission: boolean
};
	["AdvertisesDocAccessFields_advertise_keyword_Delete"]: {
		permission: boolean
};
	["AdvertisesDocAccessFields_advertise_location"]: {
		create?: ModelTypes["AdvertisesDocAccessFields_advertise_location_Create"] | undefined,
	read?: ModelTypes["AdvertisesDocAccessFields_advertise_location_Read"] | undefined,
	update?: ModelTypes["AdvertisesDocAccessFields_advertise_location_Update"] | undefined,
	delete?: ModelTypes["AdvertisesDocAccessFields_advertise_location_Delete"] | undefined
};
	["AdvertisesDocAccessFields_advertise_location_Create"]: {
		permission: boolean
};
	["AdvertisesDocAccessFields_advertise_location_Read"]: {
		permission: boolean
};
	["AdvertisesDocAccessFields_advertise_location_Update"]: {
		permission: boolean
};
	["AdvertisesDocAccessFields_advertise_location_Delete"]: {
		permission: boolean
};
	["AdvertisesDocAccessFields_advertise_displayPosition"]: {
		create?: ModelTypes["AdvertisesDocAccessFields_advertise_displayPosition_Create"] | undefined,
	read?: ModelTypes["AdvertisesDocAccessFields_advertise_displayPosition_Read"] | undefined,
	update?: ModelTypes["AdvertisesDocAccessFields_advertise_displayPosition_Update"] | undefined,
	delete?: ModelTypes["AdvertisesDocAccessFields_advertise_displayPosition_Delete"] | undefined
};
	["AdvertisesDocAccessFields_advertise_displayPosition_Create"]: {
		permission: boolean
};
	["AdvertisesDocAccessFields_advertise_displayPosition_Read"]: {
		permission: boolean
};
	["AdvertisesDocAccessFields_advertise_displayPosition_Update"]: {
		permission: boolean
};
	["AdvertisesDocAccessFields_advertise_displayPosition_Delete"]: {
		permission: boolean
};
	["AdvertisesDocAccessFields_advertise_rankingListRemark"]: {
		create?: ModelTypes["AdvertisesDocAccessFields_advertise_rankingListRemark_Create"] | undefined,
	read?: ModelTypes["AdvertisesDocAccessFields_advertise_rankingListRemark_Read"] | undefined,
	update?: ModelTypes["AdvertisesDocAccessFields_advertise_rankingListRemark_Update"] | undefined,
	delete?: ModelTypes["AdvertisesDocAccessFields_advertise_rankingListRemark_Delete"] | undefined
};
	["AdvertisesDocAccessFields_advertise_rankingListRemark_Create"]: {
		permission: boolean
};
	["AdvertisesDocAccessFields_advertise_rankingListRemark_Read"]: {
		permission: boolean
};
	["AdvertisesDocAccessFields_advertise_rankingListRemark_Update"]: {
		permission: boolean
};
	["AdvertisesDocAccessFields_advertise_rankingListRemark_Delete"]: {
		permission: boolean
};
	["AdvertisesDocAccessFields_advertiseStartDate"]: {
		create?: ModelTypes["AdvertisesDocAccessFields_advertiseStartDate_Create"] | undefined,
	read?: ModelTypes["AdvertisesDocAccessFields_advertiseStartDate_Read"] | undefined,
	update?: ModelTypes["AdvertisesDocAccessFields_advertiseStartDate_Update"] | undefined,
	delete?: ModelTypes["AdvertisesDocAccessFields_advertiseStartDate_Delete"] | undefined
};
	["AdvertisesDocAccessFields_advertiseStartDate_Create"]: {
		permission: boolean
};
	["AdvertisesDocAccessFields_advertiseStartDate_Read"]: {
		permission: boolean
};
	["AdvertisesDocAccessFields_advertiseStartDate_Update"]: {
		permission: boolean
};
	["AdvertisesDocAccessFields_advertiseStartDate_Delete"]: {
		permission: boolean
};
	["AdvertisesDocAccessFields_advertiseEndDate"]: {
		create?: ModelTypes["AdvertisesDocAccessFields_advertiseEndDate_Create"] | undefined,
	read?: ModelTypes["AdvertisesDocAccessFields_advertiseEndDate_Read"] | undefined,
	update?: ModelTypes["AdvertisesDocAccessFields_advertiseEndDate_Update"] | undefined,
	delete?: ModelTypes["AdvertisesDocAccessFields_advertiseEndDate_Delete"] | undefined
};
	["AdvertisesDocAccessFields_advertiseEndDate_Create"]: {
		permission: boolean
};
	["AdvertisesDocAccessFields_advertiseEndDate_Read"]: {
		permission: boolean
};
	["AdvertisesDocAccessFields_advertiseEndDate_Update"]: {
		permission: boolean
};
	["AdvertisesDocAccessFields_advertiseEndDate_Delete"]: {
		permission: boolean
};
	["AdvertisesDocAccessFields_type"]: {
		create?: ModelTypes["AdvertisesDocAccessFields_type_Create"] | undefined,
	read?: ModelTypes["AdvertisesDocAccessFields_type_Read"] | undefined,
	update?: ModelTypes["AdvertisesDocAccessFields_type_Update"] | undefined,
	delete?: ModelTypes["AdvertisesDocAccessFields_type_Delete"] | undefined
};
	["AdvertisesDocAccessFields_type_Create"]: {
		permission: boolean
};
	["AdvertisesDocAccessFields_type_Read"]: {
		permission: boolean
};
	["AdvertisesDocAccessFields_type_Update"]: {
		permission: boolean
};
	["AdvertisesDocAccessFields_type_Delete"]: {
		permission: boolean
};
	["AdvertisesDocAccessFields_invoiceDescription"]: {
		create?: ModelTypes["AdvertisesDocAccessFields_invoiceDescription_Create"] | undefined,
	read?: ModelTypes["AdvertisesDocAccessFields_invoiceDescription_Read"] | undefined,
	update?: ModelTypes["AdvertisesDocAccessFields_invoiceDescription_Update"] | undefined,
	delete?: ModelTypes["AdvertisesDocAccessFields_invoiceDescription_Delete"] | undefined
};
	["AdvertisesDocAccessFields_invoiceDescription_Create"]: {
		permission: boolean
};
	["AdvertisesDocAccessFields_invoiceDescription_Read"]: {
		permission: boolean
};
	["AdvertisesDocAccessFields_invoiceDescription_Update"]: {
		permission: boolean
};
	["AdvertisesDocAccessFields_invoiceDescription_Delete"]: {
		permission: boolean
};
	["AdvertisesDocAccessFields_standardPrice"]: {
		create?: ModelTypes["AdvertisesDocAccessFields_standardPrice_Create"] | undefined,
	read?: ModelTypes["AdvertisesDocAccessFields_standardPrice_Read"] | undefined,
	update?: ModelTypes["AdvertisesDocAccessFields_standardPrice_Update"] | undefined,
	delete?: ModelTypes["AdvertisesDocAccessFields_standardPrice_Delete"] | undefined
};
	["AdvertisesDocAccessFields_standardPrice_Create"]: {
		permission: boolean
};
	["AdvertisesDocAccessFields_standardPrice_Read"]: {
		permission: boolean
};
	["AdvertisesDocAccessFields_standardPrice_Update"]: {
		permission: boolean
};
	["AdvertisesDocAccessFields_standardPrice_Delete"]: {
		permission: boolean
};
	["AdvertisesDocAccessFields_discount"]: {
		create?: ModelTypes["AdvertisesDocAccessFields_discount_Create"] | undefined,
	read?: ModelTypes["AdvertisesDocAccessFields_discount_Read"] | undefined,
	update?: ModelTypes["AdvertisesDocAccessFields_discount_Update"] | undefined,
	delete?: ModelTypes["AdvertisesDocAccessFields_discount_Delete"] | undefined
};
	["AdvertisesDocAccessFields_discount_Create"]: {
		permission: boolean
};
	["AdvertisesDocAccessFields_discount_Read"]: {
		permission: boolean
};
	["AdvertisesDocAccessFields_discount_Update"]: {
		permission: boolean
};
	["AdvertisesDocAccessFields_discount_Delete"]: {
		permission: boolean
};
	["AdvertisesDocAccessFields_discountPercentage"]: {
		create?: ModelTypes["AdvertisesDocAccessFields_discountPercentage_Create"] | undefined,
	read?: ModelTypes["AdvertisesDocAccessFields_discountPercentage_Read"] | undefined,
	update?: ModelTypes["AdvertisesDocAccessFields_discountPercentage_Update"] | undefined,
	delete?: ModelTypes["AdvertisesDocAccessFields_discountPercentage_Delete"] | undefined
};
	["AdvertisesDocAccessFields_discountPercentage_Create"]: {
		permission: boolean
};
	["AdvertisesDocAccessFields_discountPercentage_Read"]: {
		permission: boolean
};
	["AdvertisesDocAccessFields_discountPercentage_Update"]: {
		permission: boolean
};
	["AdvertisesDocAccessFields_discountPercentage_Delete"]: {
		permission: boolean
};
	["AdvertisesDocAccessFields__calculateByDiscount"]: {
		create?: ModelTypes["AdvertisesDocAccessFields__calculateByDiscount_Create"] | undefined,
	read?: ModelTypes["AdvertisesDocAccessFields__calculateByDiscount_Read"] | undefined,
	update?: ModelTypes["AdvertisesDocAccessFields__calculateByDiscount_Update"] | undefined,
	delete?: ModelTypes["AdvertisesDocAccessFields__calculateByDiscount_Delete"] | undefined
};
	["AdvertisesDocAccessFields__calculateByDiscount_Create"]: {
		permission: boolean
};
	["AdvertisesDocAccessFields__calculateByDiscount_Read"]: {
		permission: boolean
};
	["AdvertisesDocAccessFields__calculateByDiscount_Update"]: {
		permission: boolean
};
	["AdvertisesDocAccessFields__calculateByDiscount_Delete"]: {
		permission: boolean
};
	["AdvertisesDocAccessFields_timableCost"]: {
		create?: ModelTypes["AdvertisesDocAccessFields_timableCost_Create"] | undefined,
	read?: ModelTypes["AdvertisesDocAccessFields_timableCost_Read"] | undefined,
	update?: ModelTypes["AdvertisesDocAccessFields_timableCost_Update"] | undefined,
	delete?: ModelTypes["AdvertisesDocAccessFields_timableCost_Delete"] | undefined
};
	["AdvertisesDocAccessFields_timableCost_Create"]: {
		permission: boolean
};
	["AdvertisesDocAccessFields_timableCost_Read"]: {
		permission: boolean
};
	["AdvertisesDocAccessFields_timableCost_Update"]: {
		permission: boolean
};
	["AdvertisesDocAccessFields_timableCost_Delete"]: {
		permission: boolean
};
	["AdvertisesDocAccessFields_remark"]: {
		create?: ModelTypes["AdvertisesDocAccessFields_remark_Create"] | undefined,
	read?: ModelTypes["AdvertisesDocAccessFields_remark_Read"] | undefined,
	update?: ModelTypes["AdvertisesDocAccessFields_remark_Update"] | undefined,
	delete?: ModelTypes["AdvertisesDocAccessFields_remark_Delete"] | undefined
};
	["AdvertisesDocAccessFields_remark_Create"]: {
		permission: boolean
};
	["AdvertisesDocAccessFields_remark_Read"]: {
		permission: boolean
};
	["AdvertisesDocAccessFields_remark_Update"]: {
		permission: boolean
};
	["AdvertisesDocAccessFields_remark_Delete"]: {
		permission: boolean
};
	["AdvertisesDocAccessFields_public"]: {
		create?: ModelTypes["AdvertisesDocAccessFields_public_Create"] | undefined,
	read?: ModelTypes["AdvertisesDocAccessFields_public_Read"] | undefined,
	update?: ModelTypes["AdvertisesDocAccessFields_public_Update"] | undefined,
	delete?: ModelTypes["AdvertisesDocAccessFields_public_Delete"] | undefined
};
	["AdvertisesDocAccessFields_public_Create"]: {
		permission: boolean
};
	["AdvertisesDocAccessFields_public_Read"]: {
		permission: boolean
};
	["AdvertisesDocAccessFields_public_Update"]: {
		permission: boolean
};
	["AdvertisesDocAccessFields_public_Delete"]: {
		permission: boolean
};
	["AdvertisesDocAccessFields_campaignName"]: {
		create?: ModelTypes["AdvertisesDocAccessFields_campaignName_Create"] | undefined,
	read?: ModelTypes["AdvertisesDocAccessFields_campaignName_Read"] | undefined,
	update?: ModelTypes["AdvertisesDocAccessFields_campaignName_Update"] | undefined,
	delete?: ModelTypes["AdvertisesDocAccessFields_campaignName_Delete"] | undefined
};
	["AdvertisesDocAccessFields_campaignName_Create"]: {
		permission: boolean
};
	["AdvertisesDocAccessFields_campaignName_Read"]: {
		permission: boolean
};
	["AdvertisesDocAccessFields_campaignName_Update"]: {
		permission: boolean
};
	["AdvertisesDocAccessFields_campaignName_Delete"]: {
		permission: boolean
};
	["AdvertisesDocAccessFields_campaignId"]: {
		create?: ModelTypes["AdvertisesDocAccessFields_campaignId_Create"] | undefined,
	read?: ModelTypes["AdvertisesDocAccessFields_campaignId_Read"] | undefined,
	update?: ModelTypes["AdvertisesDocAccessFields_campaignId_Update"] | undefined,
	delete?: ModelTypes["AdvertisesDocAccessFields_campaignId_Delete"] | undefined
};
	["AdvertisesDocAccessFields_campaignId_Create"]: {
		permission: boolean
};
	["AdvertisesDocAccessFields_campaignId_Read"]: {
		permission: boolean
};
	["AdvertisesDocAccessFields_campaignId_Update"]: {
		permission: boolean
};
	["AdvertisesDocAccessFields_campaignId_Delete"]: {
		permission: boolean
};
	["AdvertisesDocAccessFields_status"]: {
		create?: ModelTypes["AdvertisesDocAccessFields_status_Create"] | undefined,
	read?: ModelTypes["AdvertisesDocAccessFields_status_Read"] | undefined,
	update?: ModelTypes["AdvertisesDocAccessFields_status_Update"] | undefined,
	delete?: ModelTypes["AdvertisesDocAccessFields_status_Delete"] | undefined
};
	["AdvertisesDocAccessFields_status_Create"]: {
		permission: boolean
};
	["AdvertisesDocAccessFields_status_Read"]: {
		permission: boolean
};
	["AdvertisesDocAccessFields_status_Update"]: {
		permission: boolean
};
	["AdvertisesDocAccessFields_status_Delete"]: {
		permission: boolean
};
	["AdvertisesDocAccessFields_updatedAt"]: {
		create?: ModelTypes["AdvertisesDocAccessFields_updatedAt_Create"] | undefined,
	read?: ModelTypes["AdvertisesDocAccessFields_updatedAt_Read"] | undefined,
	update?: ModelTypes["AdvertisesDocAccessFields_updatedAt_Update"] | undefined,
	delete?: ModelTypes["AdvertisesDocAccessFields_updatedAt_Delete"] | undefined
};
	["AdvertisesDocAccessFields_updatedAt_Create"]: {
		permission: boolean
};
	["AdvertisesDocAccessFields_updatedAt_Read"]: {
		permission: boolean
};
	["AdvertisesDocAccessFields_updatedAt_Update"]: {
		permission: boolean
};
	["AdvertisesDocAccessFields_updatedAt_Delete"]: {
		permission: boolean
};
	["AdvertisesDocAccessFields_createdAt"]: {
		create?: ModelTypes["AdvertisesDocAccessFields_createdAt_Create"] | undefined,
	read?: ModelTypes["AdvertisesDocAccessFields_createdAt_Read"] | undefined,
	update?: ModelTypes["AdvertisesDocAccessFields_createdAt_Update"] | undefined,
	delete?: ModelTypes["AdvertisesDocAccessFields_createdAt_Delete"] | undefined
};
	["AdvertisesDocAccessFields_createdAt_Create"]: {
		permission: boolean
};
	["AdvertisesDocAccessFields_createdAt_Read"]: {
		permission: boolean
};
	["AdvertisesDocAccessFields_createdAt_Update"]: {
		permission: boolean
};
	["AdvertisesDocAccessFields_createdAt_Delete"]: {
		permission: boolean
};
	["AdvertisesCreateDocAccess"]: {
		permission: boolean,
	where?: ModelTypes["JSONObject"] | undefined
};
	["AdvertisesReadDocAccess"]: {
		permission: boolean,
	where?: ModelTypes["JSONObject"] | undefined
};
	["AdvertisesUpdateDocAccess"]: {
		permission: boolean,
	where?: ModelTypes["JSONObject"] | undefined
};
	["AdvertisesDeleteDocAccess"]: {
		permission: boolean,
	where?: ModelTypes["JSONObject"] | undefined
};
	["Static"]: {
		id?: string | undefined,
	name?: string | undefined,
	key?: string | undefined,
	content?: Array<ModelTypes["Static_Content"]> | undefined,
	region?: ModelTypes["Static_region"] | undefined,
	updatedAt?: ModelTypes["DateTime"] | undefined,
	createdAt?: ModelTypes["DateTime"] | undefined
};
	["Static_Content"]:ModelTypes["RichText"];
	["Static_region"]:Static_region;
	["Statics"]: {
		docs?: Array<ModelTypes["Static"] | undefined> | undefined,
	hasNextPage?: boolean | undefined,
	hasPrevPage?: boolean | undefined,
	limit?: number | undefined,
	nextPage?: number | undefined,
	offset?: number | undefined,
	page?: number | undefined,
	pagingCounter?: number | undefined,
	prevPage?: number | undefined,
	totalDocs?: number | undefined,
	totalPages?: number | undefined
};
	["Static_where"]: {
	name?: ModelTypes["Static_name_operator"] | undefined,
	key?: ModelTypes["Static_key_operator"] | undefined,
	region?: ModelTypes["Static_region_operator"] | undefined,
	updatedAt?: ModelTypes["Static_updatedAt_operator"] | undefined,
	createdAt?: ModelTypes["Static_createdAt_operator"] | undefined,
	id?: ModelTypes["Static_id_operator"] | undefined,
	AND?: Array<ModelTypes["Static_where_and"] | undefined> | undefined,
	OR?: Array<ModelTypes["Static_where_or"] | undefined> | undefined
};
	["Static_name_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Static_key_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Static_region_operator"]: {
	equals?: ModelTypes["Static_region_Input"] | undefined,
	not_equals?: ModelTypes["Static_region_Input"] | undefined,
	in?: Array<ModelTypes["Static_region_Input"] | undefined> | undefined,
	not_in?: Array<ModelTypes["Static_region_Input"] | undefined> | undefined,
	all?: Array<ModelTypes["Static_region_Input"] | undefined> | undefined,
	exists?: boolean | undefined
};
	["Static_region_Input"]:Static_region_Input;
	["Static_updatedAt_operator"]: {
	equals?: ModelTypes["DateTime"] | undefined,
	not_equals?: ModelTypes["DateTime"] | undefined,
	greater_than_equal?: ModelTypes["DateTime"] | undefined,
	greater_than?: ModelTypes["DateTime"] | undefined,
	less_than_equal?: ModelTypes["DateTime"] | undefined,
	less_than?: ModelTypes["DateTime"] | undefined,
	like?: ModelTypes["DateTime"] | undefined,
	exists?: boolean | undefined
};
	["Static_createdAt_operator"]: {
	equals?: ModelTypes["DateTime"] | undefined,
	not_equals?: ModelTypes["DateTime"] | undefined,
	greater_than_equal?: ModelTypes["DateTime"] | undefined,
	greater_than?: ModelTypes["DateTime"] | undefined,
	less_than_equal?: ModelTypes["DateTime"] | undefined,
	less_than?: ModelTypes["DateTime"] | undefined,
	like?: ModelTypes["DateTime"] | undefined,
	exists?: boolean | undefined
};
	["Static_id_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Static_where_and"]: {
	name?: ModelTypes["Static_name_operator"] | undefined,
	key?: ModelTypes["Static_key_operator"] | undefined,
	region?: ModelTypes["Static_region_operator"] | undefined,
	updatedAt?: ModelTypes["Static_updatedAt_operator"] | undefined,
	createdAt?: ModelTypes["Static_createdAt_operator"] | undefined,
	id?: ModelTypes["Static_id_operator"] | undefined,
	AND?: Array<ModelTypes["Static_where_and"] | undefined> | undefined,
	OR?: Array<ModelTypes["Static_where_or"] | undefined> | undefined
};
	["Static_where_or"]: {
	name?: ModelTypes["Static_name_operator"] | undefined,
	key?: ModelTypes["Static_key_operator"] | undefined,
	region?: ModelTypes["Static_region_operator"] | undefined,
	updatedAt?: ModelTypes["Static_updatedAt_operator"] | undefined,
	createdAt?: ModelTypes["Static_createdAt_operator"] | undefined,
	id?: ModelTypes["Static_id_operator"] | undefined,
	AND?: Array<ModelTypes["Static_where_and"] | undefined> | undefined,
	OR?: Array<ModelTypes["Static_where_or"] | undefined> | undefined
};
	["countStatics"]: {
		totalDocs?: number | undefined
};
	["staticsDocAccess"]: {
		fields?: ModelTypes["StaticsDocAccessFields"] | undefined,
	create?: ModelTypes["StaticsCreateDocAccess"] | undefined,
	read?: ModelTypes["StaticsReadDocAccess"] | undefined,
	update?: ModelTypes["StaticsUpdateDocAccess"] | undefined,
	delete?: ModelTypes["StaticsDeleteDocAccess"] | undefined
};
	["StaticsDocAccessFields"]: {
		name?: ModelTypes["StaticsDocAccessFields_name"] | undefined,
	key?: ModelTypes["StaticsDocAccessFields_key"] | undefined,
	content?: ModelTypes["StaticsDocAccessFields_content"] | undefined,
	region?: ModelTypes["StaticsDocAccessFields_region"] | undefined,
	updatedAt?: ModelTypes["StaticsDocAccessFields_updatedAt"] | undefined,
	createdAt?: ModelTypes["StaticsDocAccessFields_createdAt"] | undefined
};
	["StaticsDocAccessFields_name"]: {
		create?: ModelTypes["StaticsDocAccessFields_name_Create"] | undefined,
	read?: ModelTypes["StaticsDocAccessFields_name_Read"] | undefined,
	update?: ModelTypes["StaticsDocAccessFields_name_Update"] | undefined,
	delete?: ModelTypes["StaticsDocAccessFields_name_Delete"] | undefined
};
	["StaticsDocAccessFields_name_Create"]: {
		permission: boolean
};
	["StaticsDocAccessFields_name_Read"]: {
		permission: boolean
};
	["StaticsDocAccessFields_name_Update"]: {
		permission: boolean
};
	["StaticsDocAccessFields_name_Delete"]: {
		permission: boolean
};
	["StaticsDocAccessFields_key"]: {
		create?: ModelTypes["StaticsDocAccessFields_key_Create"] | undefined,
	read?: ModelTypes["StaticsDocAccessFields_key_Read"] | undefined,
	update?: ModelTypes["StaticsDocAccessFields_key_Update"] | undefined,
	delete?: ModelTypes["StaticsDocAccessFields_key_Delete"] | undefined
};
	["StaticsDocAccessFields_key_Create"]: {
		permission: boolean
};
	["StaticsDocAccessFields_key_Read"]: {
		permission: boolean
};
	["StaticsDocAccessFields_key_Update"]: {
		permission: boolean
};
	["StaticsDocAccessFields_key_Delete"]: {
		permission: boolean
};
	["StaticsDocAccessFields_content"]: {
		create?: ModelTypes["StaticsDocAccessFields_content_Create"] | undefined,
	read?: ModelTypes["StaticsDocAccessFields_content_Read"] | undefined,
	update?: ModelTypes["StaticsDocAccessFields_content_Update"] | undefined,
	delete?: ModelTypes["StaticsDocAccessFields_content_Delete"] | undefined
};
	["StaticsDocAccessFields_content_Create"]: {
		permission: boolean
};
	["StaticsDocAccessFields_content_Read"]: {
		permission: boolean
};
	["StaticsDocAccessFields_content_Update"]: {
		permission: boolean
};
	["StaticsDocAccessFields_content_Delete"]: {
		permission: boolean
};
	["StaticsDocAccessFields_region"]: {
		create?: ModelTypes["StaticsDocAccessFields_region_Create"] | undefined,
	read?: ModelTypes["StaticsDocAccessFields_region_Read"] | undefined,
	update?: ModelTypes["StaticsDocAccessFields_region_Update"] | undefined,
	delete?: ModelTypes["StaticsDocAccessFields_region_Delete"] | undefined
};
	["StaticsDocAccessFields_region_Create"]: {
		permission: boolean
};
	["StaticsDocAccessFields_region_Read"]: {
		permission: boolean
};
	["StaticsDocAccessFields_region_Update"]: {
		permission: boolean
};
	["StaticsDocAccessFields_region_Delete"]: {
		permission: boolean
};
	["StaticsDocAccessFields_updatedAt"]: {
		create?: ModelTypes["StaticsDocAccessFields_updatedAt_Create"] | undefined,
	read?: ModelTypes["StaticsDocAccessFields_updatedAt_Read"] | undefined,
	update?: ModelTypes["StaticsDocAccessFields_updatedAt_Update"] | undefined,
	delete?: ModelTypes["StaticsDocAccessFields_updatedAt_Delete"] | undefined
};
	["StaticsDocAccessFields_updatedAt_Create"]: {
		permission: boolean
};
	["StaticsDocAccessFields_updatedAt_Read"]: {
		permission: boolean
};
	["StaticsDocAccessFields_updatedAt_Update"]: {
		permission: boolean
};
	["StaticsDocAccessFields_updatedAt_Delete"]: {
		permission: boolean
};
	["StaticsDocAccessFields_createdAt"]: {
		create?: ModelTypes["StaticsDocAccessFields_createdAt_Create"] | undefined,
	read?: ModelTypes["StaticsDocAccessFields_createdAt_Read"] | undefined,
	update?: ModelTypes["StaticsDocAccessFields_createdAt_Update"] | undefined,
	delete?: ModelTypes["StaticsDocAccessFields_createdAt_Delete"] | undefined
};
	["StaticsDocAccessFields_createdAt_Create"]: {
		permission: boolean
};
	["StaticsDocAccessFields_createdAt_Read"]: {
		permission: boolean
};
	["StaticsDocAccessFields_createdAt_Update"]: {
		permission: boolean
};
	["StaticsDocAccessFields_createdAt_Delete"]: {
		permission: boolean
};
	["StaticsCreateDocAccess"]: {
		permission: boolean,
	where?: ModelTypes["JSONObject"] | undefined
};
	["StaticsReadDocAccess"]: {
		permission: boolean,
	where?: ModelTypes["JSONObject"] | undefined
};
	["StaticsUpdateDocAccess"]: {
		permission: boolean,
	where?: ModelTypes["JSONObject"] | undefined
};
	["StaticsDeleteDocAccess"]: {
		permission: boolean,
	where?: ModelTypes["JSONObject"] | undefined
};
	["EventView"]: {
		id?: string | undefined,
	date: ModelTypes["DateTime"],
	eventId: ModelTypes["Event"],
	viewCount: number,
	updatedAt?: ModelTypes["DateTime"] | undefined,
	createdAt?: ModelTypes["DateTime"] | undefined
};
	["EventViews"]: {
		docs?: Array<ModelTypes["EventView"] | undefined> | undefined,
	hasNextPage?: boolean | undefined,
	hasPrevPage?: boolean | undefined,
	limit?: number | undefined,
	nextPage?: number | undefined,
	offset?: number | undefined,
	page?: number | undefined,
	pagingCounter?: number | undefined,
	prevPage?: number | undefined,
	totalDocs?: number | undefined,
	totalPages?: number | undefined
};
	["EventView_where"]: {
	date?: ModelTypes["EventView_date_operator"] | undefined,
	eventId?: ModelTypes["EventView_eventId_operator"] | undefined,
	viewCount?: ModelTypes["EventView_viewCount_operator"] | undefined,
	updatedAt?: ModelTypes["EventView_updatedAt_operator"] | undefined,
	createdAt?: ModelTypes["EventView_createdAt_operator"] | undefined,
	id?: ModelTypes["EventView_id_operator"] | undefined,
	AND?: Array<ModelTypes["EventView_where_and"] | undefined> | undefined,
	OR?: Array<ModelTypes["EventView_where_or"] | undefined> | undefined
};
	["EventView_date_operator"]: {
	equals?: ModelTypes["DateTime"] | undefined,
	not_equals?: ModelTypes["DateTime"] | undefined,
	greater_than_equal?: ModelTypes["DateTime"] | undefined,
	greater_than?: ModelTypes["DateTime"] | undefined,
	less_than_equal?: ModelTypes["DateTime"] | undefined,
	less_than?: ModelTypes["DateTime"] | undefined,
	like?: ModelTypes["DateTime"] | undefined
};
	["EventView_eventId_operator"]: {
	equals?: ModelTypes["JSON"] | undefined,
	not_equals?: ModelTypes["JSON"] | undefined,
	in?: Array<ModelTypes["JSON"] | undefined> | undefined,
	not_in?: Array<ModelTypes["JSON"] | undefined> | undefined,
	all?: Array<ModelTypes["JSON"] | undefined> | undefined
};
	["EventView_viewCount_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined
};
	["EventView_updatedAt_operator"]: {
	equals?: ModelTypes["DateTime"] | undefined,
	not_equals?: ModelTypes["DateTime"] | undefined,
	greater_than_equal?: ModelTypes["DateTime"] | undefined,
	greater_than?: ModelTypes["DateTime"] | undefined,
	less_than_equal?: ModelTypes["DateTime"] | undefined,
	less_than?: ModelTypes["DateTime"] | undefined,
	like?: ModelTypes["DateTime"] | undefined,
	exists?: boolean | undefined
};
	["EventView_createdAt_operator"]: {
	equals?: ModelTypes["DateTime"] | undefined,
	not_equals?: ModelTypes["DateTime"] | undefined,
	greater_than_equal?: ModelTypes["DateTime"] | undefined,
	greater_than?: ModelTypes["DateTime"] | undefined,
	less_than_equal?: ModelTypes["DateTime"] | undefined,
	less_than?: ModelTypes["DateTime"] | undefined,
	like?: ModelTypes["DateTime"] | undefined,
	exists?: boolean | undefined
};
	["EventView_id_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["EventView_where_and"]: {
	date?: ModelTypes["EventView_date_operator"] | undefined,
	eventId?: ModelTypes["EventView_eventId_operator"] | undefined,
	viewCount?: ModelTypes["EventView_viewCount_operator"] | undefined,
	updatedAt?: ModelTypes["EventView_updatedAt_operator"] | undefined,
	createdAt?: ModelTypes["EventView_createdAt_operator"] | undefined,
	id?: ModelTypes["EventView_id_operator"] | undefined,
	AND?: Array<ModelTypes["EventView_where_and"] | undefined> | undefined,
	OR?: Array<ModelTypes["EventView_where_or"] | undefined> | undefined
};
	["EventView_where_or"]: {
	date?: ModelTypes["EventView_date_operator"] | undefined,
	eventId?: ModelTypes["EventView_eventId_operator"] | undefined,
	viewCount?: ModelTypes["EventView_viewCount_operator"] | undefined,
	updatedAt?: ModelTypes["EventView_updatedAt_operator"] | undefined,
	createdAt?: ModelTypes["EventView_createdAt_operator"] | undefined,
	id?: ModelTypes["EventView_id_operator"] | undefined,
	AND?: Array<ModelTypes["EventView_where_and"] | undefined> | undefined,
	OR?: Array<ModelTypes["EventView_where_or"] | undefined> | undefined
};
	["countEventViews"]: {
		totalDocs?: number | undefined
};
	["event_viewsDocAccess"]: {
		fields?: ModelTypes["EventViewsDocAccessFields"] | undefined,
	create?: ModelTypes["EventViewsCreateDocAccess"] | undefined,
	read?: ModelTypes["EventViewsReadDocAccess"] | undefined,
	update?: ModelTypes["EventViewsUpdateDocAccess"] | undefined,
	delete?: ModelTypes["EventViewsDeleteDocAccess"] | undefined
};
	["EventViewsDocAccessFields"]: {
		date?: ModelTypes["EventViewsDocAccessFields_date"] | undefined,
	eventId?: ModelTypes["EventViewsDocAccessFields_eventId"] | undefined,
	viewCount?: ModelTypes["EventViewsDocAccessFields_viewCount"] | undefined,
	updatedAt?: ModelTypes["EventViewsDocAccessFields_updatedAt"] | undefined,
	createdAt?: ModelTypes["EventViewsDocAccessFields_createdAt"] | undefined
};
	["EventViewsDocAccessFields_date"]: {
		create?: ModelTypes["EventViewsDocAccessFields_date_Create"] | undefined,
	read?: ModelTypes["EventViewsDocAccessFields_date_Read"] | undefined,
	update?: ModelTypes["EventViewsDocAccessFields_date_Update"] | undefined,
	delete?: ModelTypes["EventViewsDocAccessFields_date_Delete"] | undefined
};
	["EventViewsDocAccessFields_date_Create"]: {
		permission: boolean
};
	["EventViewsDocAccessFields_date_Read"]: {
		permission: boolean
};
	["EventViewsDocAccessFields_date_Update"]: {
		permission: boolean
};
	["EventViewsDocAccessFields_date_Delete"]: {
		permission: boolean
};
	["EventViewsDocAccessFields_eventId"]: {
		create?: ModelTypes["EventViewsDocAccessFields_eventId_Create"] | undefined,
	read?: ModelTypes["EventViewsDocAccessFields_eventId_Read"] | undefined,
	update?: ModelTypes["EventViewsDocAccessFields_eventId_Update"] | undefined,
	delete?: ModelTypes["EventViewsDocAccessFields_eventId_Delete"] | undefined
};
	["EventViewsDocAccessFields_eventId_Create"]: {
		permission: boolean
};
	["EventViewsDocAccessFields_eventId_Read"]: {
		permission: boolean
};
	["EventViewsDocAccessFields_eventId_Update"]: {
		permission: boolean
};
	["EventViewsDocAccessFields_eventId_Delete"]: {
		permission: boolean
};
	["EventViewsDocAccessFields_viewCount"]: {
		create?: ModelTypes["EventViewsDocAccessFields_viewCount_Create"] | undefined,
	read?: ModelTypes["EventViewsDocAccessFields_viewCount_Read"] | undefined,
	update?: ModelTypes["EventViewsDocAccessFields_viewCount_Update"] | undefined,
	delete?: ModelTypes["EventViewsDocAccessFields_viewCount_Delete"] | undefined
};
	["EventViewsDocAccessFields_viewCount_Create"]: {
		permission: boolean
};
	["EventViewsDocAccessFields_viewCount_Read"]: {
		permission: boolean
};
	["EventViewsDocAccessFields_viewCount_Update"]: {
		permission: boolean
};
	["EventViewsDocAccessFields_viewCount_Delete"]: {
		permission: boolean
};
	["EventViewsDocAccessFields_updatedAt"]: {
		create?: ModelTypes["EventViewsDocAccessFields_updatedAt_Create"] | undefined,
	read?: ModelTypes["EventViewsDocAccessFields_updatedAt_Read"] | undefined,
	update?: ModelTypes["EventViewsDocAccessFields_updatedAt_Update"] | undefined,
	delete?: ModelTypes["EventViewsDocAccessFields_updatedAt_Delete"] | undefined
};
	["EventViewsDocAccessFields_updatedAt_Create"]: {
		permission: boolean
};
	["EventViewsDocAccessFields_updatedAt_Read"]: {
		permission: boolean
};
	["EventViewsDocAccessFields_updatedAt_Update"]: {
		permission: boolean
};
	["EventViewsDocAccessFields_updatedAt_Delete"]: {
		permission: boolean
};
	["EventViewsDocAccessFields_createdAt"]: {
		create?: ModelTypes["EventViewsDocAccessFields_createdAt_Create"] | undefined,
	read?: ModelTypes["EventViewsDocAccessFields_createdAt_Read"] | undefined,
	update?: ModelTypes["EventViewsDocAccessFields_createdAt_Update"] | undefined,
	delete?: ModelTypes["EventViewsDocAccessFields_createdAt_Delete"] | undefined
};
	["EventViewsDocAccessFields_createdAt_Create"]: {
		permission: boolean
};
	["EventViewsDocAccessFields_createdAt_Read"]: {
		permission: boolean
};
	["EventViewsDocAccessFields_createdAt_Update"]: {
		permission: boolean
};
	["EventViewsDocAccessFields_createdAt_Delete"]: {
		permission: boolean
};
	["EventViewsCreateDocAccess"]: {
		permission: boolean,
	where?: ModelTypes["JSONObject"] | undefined
};
	["EventViewsReadDocAccess"]: {
		permission: boolean,
	where?: ModelTypes["JSONObject"] | undefined
};
	["EventViewsUpdateDocAccess"]: {
		permission: boolean,
	where?: ModelTypes["JSONObject"] | undefined
};
	["EventViewsDeleteDocAccess"]: {
		permission: boolean,
	where?: ModelTypes["JSONObject"] | undefined
};
	["PostView"]: {
		id?: string | undefined,
	date: ModelTypes["DateTime"],
	postId: ModelTypes["Post"],
	viewCount: number,
	updatedAt?: ModelTypes["DateTime"] | undefined,
	createdAt?: ModelTypes["DateTime"] | undefined
};
	["PostViews"]: {
		docs?: Array<ModelTypes["PostView"] | undefined> | undefined,
	hasNextPage?: boolean | undefined,
	hasPrevPage?: boolean | undefined,
	limit?: number | undefined,
	nextPage?: number | undefined,
	offset?: number | undefined,
	page?: number | undefined,
	pagingCounter?: number | undefined,
	prevPage?: number | undefined,
	totalDocs?: number | undefined,
	totalPages?: number | undefined
};
	["PostView_where"]: {
	date?: ModelTypes["PostView_date_operator"] | undefined,
	postId?: ModelTypes["PostView_postId_operator"] | undefined,
	viewCount?: ModelTypes["PostView_viewCount_operator"] | undefined,
	updatedAt?: ModelTypes["PostView_updatedAt_operator"] | undefined,
	createdAt?: ModelTypes["PostView_createdAt_operator"] | undefined,
	id?: ModelTypes["PostView_id_operator"] | undefined,
	AND?: Array<ModelTypes["PostView_where_and"] | undefined> | undefined,
	OR?: Array<ModelTypes["PostView_where_or"] | undefined> | undefined
};
	["PostView_date_operator"]: {
	equals?: ModelTypes["DateTime"] | undefined,
	not_equals?: ModelTypes["DateTime"] | undefined,
	greater_than_equal?: ModelTypes["DateTime"] | undefined,
	greater_than?: ModelTypes["DateTime"] | undefined,
	less_than_equal?: ModelTypes["DateTime"] | undefined,
	less_than?: ModelTypes["DateTime"] | undefined,
	like?: ModelTypes["DateTime"] | undefined
};
	["PostView_postId_operator"]: {
	equals?: ModelTypes["JSON"] | undefined,
	not_equals?: ModelTypes["JSON"] | undefined,
	in?: Array<ModelTypes["JSON"] | undefined> | undefined,
	not_in?: Array<ModelTypes["JSON"] | undefined> | undefined,
	all?: Array<ModelTypes["JSON"] | undefined> | undefined
};
	["PostView_viewCount_operator"]: {
	equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined
};
	["PostView_updatedAt_operator"]: {
	equals?: ModelTypes["DateTime"] | undefined,
	not_equals?: ModelTypes["DateTime"] | undefined,
	greater_than_equal?: ModelTypes["DateTime"] | undefined,
	greater_than?: ModelTypes["DateTime"] | undefined,
	less_than_equal?: ModelTypes["DateTime"] | undefined,
	less_than?: ModelTypes["DateTime"] | undefined,
	like?: ModelTypes["DateTime"] | undefined,
	exists?: boolean | undefined
};
	["PostView_createdAt_operator"]: {
	equals?: ModelTypes["DateTime"] | undefined,
	not_equals?: ModelTypes["DateTime"] | undefined,
	greater_than_equal?: ModelTypes["DateTime"] | undefined,
	greater_than?: ModelTypes["DateTime"] | undefined,
	less_than_equal?: ModelTypes["DateTime"] | undefined,
	less_than?: ModelTypes["DateTime"] | undefined,
	like?: ModelTypes["DateTime"] | undefined,
	exists?: boolean | undefined
};
	["PostView_id_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["PostView_where_and"]: {
	date?: ModelTypes["PostView_date_operator"] | undefined,
	postId?: ModelTypes["PostView_postId_operator"] | undefined,
	viewCount?: ModelTypes["PostView_viewCount_operator"] | undefined,
	updatedAt?: ModelTypes["PostView_updatedAt_operator"] | undefined,
	createdAt?: ModelTypes["PostView_createdAt_operator"] | undefined,
	id?: ModelTypes["PostView_id_operator"] | undefined,
	AND?: Array<ModelTypes["PostView_where_and"] | undefined> | undefined,
	OR?: Array<ModelTypes["PostView_where_or"] | undefined> | undefined
};
	["PostView_where_or"]: {
	date?: ModelTypes["PostView_date_operator"] | undefined,
	postId?: ModelTypes["PostView_postId_operator"] | undefined,
	viewCount?: ModelTypes["PostView_viewCount_operator"] | undefined,
	updatedAt?: ModelTypes["PostView_updatedAt_operator"] | undefined,
	createdAt?: ModelTypes["PostView_createdAt_operator"] | undefined,
	id?: ModelTypes["PostView_id_operator"] | undefined,
	AND?: Array<ModelTypes["PostView_where_and"] | undefined> | undefined,
	OR?: Array<ModelTypes["PostView_where_or"] | undefined> | undefined
};
	["countPostViews"]: {
		totalDocs?: number | undefined
};
	["post_viewsDocAccess"]: {
		fields?: ModelTypes["PostViewsDocAccessFields"] | undefined,
	create?: ModelTypes["PostViewsCreateDocAccess"] | undefined,
	read?: ModelTypes["PostViewsReadDocAccess"] | undefined,
	update?: ModelTypes["PostViewsUpdateDocAccess"] | undefined,
	delete?: ModelTypes["PostViewsDeleteDocAccess"] | undefined
};
	["PostViewsDocAccessFields"]: {
		date?: ModelTypes["PostViewsDocAccessFields_date"] | undefined,
	postId?: ModelTypes["PostViewsDocAccessFields_postId"] | undefined,
	viewCount?: ModelTypes["PostViewsDocAccessFields_viewCount"] | undefined,
	updatedAt?: ModelTypes["PostViewsDocAccessFields_updatedAt"] | undefined,
	createdAt?: ModelTypes["PostViewsDocAccessFields_createdAt"] | undefined
};
	["PostViewsDocAccessFields_date"]: {
		create?: ModelTypes["PostViewsDocAccessFields_date_Create"] | undefined,
	read?: ModelTypes["PostViewsDocAccessFields_date_Read"] | undefined,
	update?: ModelTypes["PostViewsDocAccessFields_date_Update"] | undefined,
	delete?: ModelTypes["PostViewsDocAccessFields_date_Delete"] | undefined
};
	["PostViewsDocAccessFields_date_Create"]: {
		permission: boolean
};
	["PostViewsDocAccessFields_date_Read"]: {
		permission: boolean
};
	["PostViewsDocAccessFields_date_Update"]: {
		permission: boolean
};
	["PostViewsDocAccessFields_date_Delete"]: {
		permission: boolean
};
	["PostViewsDocAccessFields_postId"]: {
		create?: ModelTypes["PostViewsDocAccessFields_postId_Create"] | undefined,
	read?: ModelTypes["PostViewsDocAccessFields_postId_Read"] | undefined,
	update?: ModelTypes["PostViewsDocAccessFields_postId_Update"] | undefined,
	delete?: ModelTypes["PostViewsDocAccessFields_postId_Delete"] | undefined
};
	["PostViewsDocAccessFields_postId_Create"]: {
		permission: boolean
};
	["PostViewsDocAccessFields_postId_Read"]: {
		permission: boolean
};
	["PostViewsDocAccessFields_postId_Update"]: {
		permission: boolean
};
	["PostViewsDocAccessFields_postId_Delete"]: {
		permission: boolean
};
	["PostViewsDocAccessFields_viewCount"]: {
		create?: ModelTypes["PostViewsDocAccessFields_viewCount_Create"] | undefined,
	read?: ModelTypes["PostViewsDocAccessFields_viewCount_Read"] | undefined,
	update?: ModelTypes["PostViewsDocAccessFields_viewCount_Update"] | undefined,
	delete?: ModelTypes["PostViewsDocAccessFields_viewCount_Delete"] | undefined
};
	["PostViewsDocAccessFields_viewCount_Create"]: {
		permission: boolean
};
	["PostViewsDocAccessFields_viewCount_Read"]: {
		permission: boolean
};
	["PostViewsDocAccessFields_viewCount_Update"]: {
		permission: boolean
};
	["PostViewsDocAccessFields_viewCount_Delete"]: {
		permission: boolean
};
	["PostViewsDocAccessFields_updatedAt"]: {
		create?: ModelTypes["PostViewsDocAccessFields_updatedAt_Create"] | undefined,
	read?: ModelTypes["PostViewsDocAccessFields_updatedAt_Read"] | undefined,
	update?: ModelTypes["PostViewsDocAccessFields_updatedAt_Update"] | undefined,
	delete?: ModelTypes["PostViewsDocAccessFields_updatedAt_Delete"] | undefined
};
	["PostViewsDocAccessFields_updatedAt_Create"]: {
		permission: boolean
};
	["PostViewsDocAccessFields_updatedAt_Read"]: {
		permission: boolean
};
	["PostViewsDocAccessFields_updatedAt_Update"]: {
		permission: boolean
};
	["PostViewsDocAccessFields_updatedAt_Delete"]: {
		permission: boolean
};
	["PostViewsDocAccessFields_createdAt"]: {
		create?: ModelTypes["PostViewsDocAccessFields_createdAt_Create"] | undefined,
	read?: ModelTypes["PostViewsDocAccessFields_createdAt_Read"] | undefined,
	update?: ModelTypes["PostViewsDocAccessFields_createdAt_Update"] | undefined,
	delete?: ModelTypes["PostViewsDocAccessFields_createdAt_Delete"] | undefined
};
	["PostViewsDocAccessFields_createdAt_Create"]: {
		permission: boolean
};
	["PostViewsDocAccessFields_createdAt_Read"]: {
		permission: boolean
};
	["PostViewsDocAccessFields_createdAt_Update"]: {
		permission: boolean
};
	["PostViewsDocAccessFields_createdAt_Delete"]: {
		permission: boolean
};
	["PostViewsCreateDocAccess"]: {
		permission: boolean,
	where?: ModelTypes["JSONObject"] | undefined
};
	["PostViewsReadDocAccess"]: {
		permission: boolean,
	where?: ModelTypes["JSONObject"] | undefined
};
	["PostViewsUpdateDocAccess"]: {
		permission: boolean,
	where?: ModelTypes["JSONObject"] | undefined
};
	["PostViewsDeleteDocAccess"]: {
		permission: boolean,
	where?: ModelTypes["JSONObject"] | undefined
};
	["PayloadPreference"]: {
		id?: string | undefined,
	user: ModelTypes["PayloadPreference_User_Relationship"],
	key?: string | undefined,
	value?: ModelTypes["JSON"] | undefined,
	updatedAt?: ModelTypes["DateTime"] | undefined,
	createdAt?: ModelTypes["DateTime"] | undefined
};
	["PayloadPreference_User_Relationship"]: {
		relationTo?: ModelTypes["PayloadPreference_User_RelationTo"] | undefined,
	value?: ModelTypes["PayloadPreference_User"] | undefined
};
	["PayloadPreference_User_RelationTo"]:PayloadPreference_User_RelationTo;
	["PayloadPreference_User"]:ModelTypes["User"];
	["PayloadPreferences"]: {
		docs?: Array<ModelTypes["PayloadPreference"] | undefined> | undefined,
	hasNextPage?: boolean | undefined,
	hasPrevPage?: boolean | undefined,
	limit?: number | undefined,
	nextPage?: number | undefined,
	offset?: number | undefined,
	page?: number | undefined,
	pagingCounter?: number | undefined,
	prevPage?: number | undefined,
	totalDocs?: number | undefined,
	totalPages?: number | undefined
};
	["PayloadPreference_where"]: {
	user?: ModelTypes["PayloadPreference_user_Relation"] | undefined,
	key?: ModelTypes["PayloadPreference_key_operator"] | undefined,
	value?: ModelTypes["PayloadPreference_value_operator"] | undefined,
	updatedAt?: ModelTypes["PayloadPreference_updatedAt_operator"] | undefined,
	createdAt?: ModelTypes["PayloadPreference_createdAt_operator"] | undefined,
	id?: ModelTypes["PayloadPreference_id_operator"] | undefined,
	AND?: Array<ModelTypes["PayloadPreference_where_and"] | undefined> | undefined,
	OR?: Array<ModelTypes["PayloadPreference_where_or"] | undefined> | undefined
};
	["PayloadPreference_user_Relation"]: {
	relationTo?: ModelTypes["PayloadPreference_user_Relation_RelationTo"] | undefined,
	value?: ModelTypes["JSON"] | undefined
};
	["PayloadPreference_user_Relation_RelationTo"]:PayloadPreference_user_Relation_RelationTo;
	["PayloadPreference_key_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["PayloadPreference_value_operator"]: {
	equals?: ModelTypes["JSON"] | undefined,
	not_equals?: ModelTypes["JSON"] | undefined,
	like?: ModelTypes["JSON"] | undefined,
	contains?: ModelTypes["JSON"] | undefined,
	within?: ModelTypes["JSON"] | undefined,
	intersects?: ModelTypes["JSON"] | undefined,
	exists?: boolean | undefined
};
	["PayloadPreference_updatedAt_operator"]: {
	equals?: ModelTypes["DateTime"] | undefined,
	not_equals?: ModelTypes["DateTime"] | undefined,
	greater_than_equal?: ModelTypes["DateTime"] | undefined,
	greater_than?: ModelTypes["DateTime"] | undefined,
	less_than_equal?: ModelTypes["DateTime"] | undefined,
	less_than?: ModelTypes["DateTime"] | undefined,
	like?: ModelTypes["DateTime"] | undefined,
	exists?: boolean | undefined
};
	["PayloadPreference_createdAt_operator"]: {
	equals?: ModelTypes["DateTime"] | undefined,
	not_equals?: ModelTypes["DateTime"] | undefined,
	greater_than_equal?: ModelTypes["DateTime"] | undefined,
	greater_than?: ModelTypes["DateTime"] | undefined,
	less_than_equal?: ModelTypes["DateTime"] | undefined,
	less_than?: ModelTypes["DateTime"] | undefined,
	like?: ModelTypes["DateTime"] | undefined,
	exists?: boolean | undefined
};
	["PayloadPreference_id_operator"]: {
	equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["PayloadPreference_where_and"]: {
	user?: ModelTypes["PayloadPreference_user_Relation"] | undefined,
	key?: ModelTypes["PayloadPreference_key_operator"] | undefined,
	value?: ModelTypes["PayloadPreference_value_operator"] | undefined,
	updatedAt?: ModelTypes["PayloadPreference_updatedAt_operator"] | undefined,
	createdAt?: ModelTypes["PayloadPreference_createdAt_operator"] | undefined,
	id?: ModelTypes["PayloadPreference_id_operator"] | undefined,
	AND?: Array<ModelTypes["PayloadPreference_where_and"] | undefined> | undefined,
	OR?: Array<ModelTypes["PayloadPreference_where_or"] | undefined> | undefined
};
	["PayloadPreference_where_or"]: {
	user?: ModelTypes["PayloadPreference_user_Relation"] | undefined,
	key?: ModelTypes["PayloadPreference_key_operator"] | undefined,
	value?: ModelTypes["PayloadPreference_value_operator"] | undefined,
	updatedAt?: ModelTypes["PayloadPreference_updatedAt_operator"] | undefined,
	createdAt?: ModelTypes["PayloadPreference_createdAt_operator"] | undefined,
	id?: ModelTypes["PayloadPreference_id_operator"] | undefined,
	AND?: Array<ModelTypes["PayloadPreference_where_and"] | undefined> | undefined,
	OR?: Array<ModelTypes["PayloadPreference_where_or"] | undefined> | undefined
};
	["countPayloadPreferences"]: {
		totalDocs?: number | undefined
};
	["payload_preferencesDocAccess"]: {
		fields?: ModelTypes["PayloadPreferencesDocAccessFields"] | undefined,
	create?: ModelTypes["PayloadPreferencesCreateDocAccess"] | undefined,
	read?: ModelTypes["PayloadPreferencesReadDocAccess"] | undefined,
	update?: ModelTypes["PayloadPreferencesUpdateDocAccess"] | undefined,
	delete?: ModelTypes["PayloadPreferencesDeleteDocAccess"] | undefined
};
	["PayloadPreferencesDocAccessFields"]: {
		user?: ModelTypes["PayloadPreferencesDocAccessFields_user"] | undefined,
	key?: ModelTypes["PayloadPreferencesDocAccessFields_key"] | undefined,
	value?: ModelTypes["PayloadPreferencesDocAccessFields_value"] | undefined,
	updatedAt?: ModelTypes["PayloadPreferencesDocAccessFields_updatedAt"] | undefined,
	createdAt?: ModelTypes["PayloadPreferencesDocAccessFields_createdAt"] | undefined
};
	["PayloadPreferencesDocAccessFields_user"]: {
		create?: ModelTypes["PayloadPreferencesDocAccessFields_user_Create"] | undefined,
	read?: ModelTypes["PayloadPreferencesDocAccessFields_user_Read"] | undefined,
	update?: ModelTypes["PayloadPreferencesDocAccessFields_user_Update"] | undefined,
	delete?: ModelTypes["PayloadPreferencesDocAccessFields_user_Delete"] | undefined
};
	["PayloadPreferencesDocAccessFields_user_Create"]: {
		permission: boolean
};
	["PayloadPreferencesDocAccessFields_user_Read"]: {
		permission: boolean
};
	["PayloadPreferencesDocAccessFields_user_Update"]: {
		permission: boolean
};
	["PayloadPreferencesDocAccessFields_user_Delete"]: {
		permission: boolean
};
	["PayloadPreferencesDocAccessFields_key"]: {
		create?: ModelTypes["PayloadPreferencesDocAccessFields_key_Create"] | undefined,
	read?: ModelTypes["PayloadPreferencesDocAccessFields_key_Read"] | undefined,
	update?: ModelTypes["PayloadPreferencesDocAccessFields_key_Update"] | undefined,
	delete?: ModelTypes["PayloadPreferencesDocAccessFields_key_Delete"] | undefined
};
	["PayloadPreferencesDocAccessFields_key_Create"]: {
		permission: boolean
};
	["PayloadPreferencesDocAccessFields_key_Read"]: {
		permission: boolean
};
	["PayloadPreferencesDocAccessFields_key_Update"]: {
		permission: boolean
};
	["PayloadPreferencesDocAccessFields_key_Delete"]: {
		permission: boolean
};
	["PayloadPreferencesDocAccessFields_value"]: {
		create?: ModelTypes["PayloadPreferencesDocAccessFields_value_Create"] | undefined,
	read?: ModelTypes["PayloadPreferencesDocAccessFields_value_Read"] | undefined,
	update?: ModelTypes["PayloadPreferencesDocAccessFields_value_Update"] | undefined,
	delete?: ModelTypes["PayloadPreferencesDocAccessFields_value_Delete"] | undefined
};
	["PayloadPreferencesDocAccessFields_value_Create"]: {
		permission: boolean
};
	["PayloadPreferencesDocAccessFields_value_Read"]: {
		permission: boolean
};
	["PayloadPreferencesDocAccessFields_value_Update"]: {
		permission: boolean
};
	["PayloadPreferencesDocAccessFields_value_Delete"]: {
		permission: boolean
};
	["PayloadPreferencesDocAccessFields_updatedAt"]: {
		create?: ModelTypes["PayloadPreferencesDocAccessFields_updatedAt_Create"] | undefined,
	read?: ModelTypes["PayloadPreferencesDocAccessFields_updatedAt_Read"] | undefined,
	update?: ModelTypes["PayloadPreferencesDocAccessFields_updatedAt_Update"] | undefined,
	delete?: ModelTypes["PayloadPreferencesDocAccessFields_updatedAt_Delete"] | undefined
};
	["PayloadPreferencesDocAccessFields_updatedAt_Create"]: {
		permission: boolean
};
	["PayloadPreferencesDocAccessFields_updatedAt_Read"]: {
		permission: boolean
};
	["PayloadPreferencesDocAccessFields_updatedAt_Update"]: {
		permission: boolean
};
	["PayloadPreferencesDocAccessFields_updatedAt_Delete"]: {
		permission: boolean
};
	["PayloadPreferencesDocAccessFields_createdAt"]: {
		create?: ModelTypes["PayloadPreferencesDocAccessFields_createdAt_Create"] | undefined,
	read?: ModelTypes["PayloadPreferencesDocAccessFields_createdAt_Read"] | undefined,
	update?: ModelTypes["PayloadPreferencesDocAccessFields_createdAt_Update"] | undefined,
	delete?: ModelTypes["PayloadPreferencesDocAccessFields_createdAt_Delete"] | undefined
};
	["PayloadPreferencesDocAccessFields_createdAt_Create"]: {
		permission: boolean
};
	["PayloadPreferencesDocAccessFields_createdAt_Read"]: {
		permission: boolean
};
	["PayloadPreferencesDocAccessFields_createdAt_Update"]: {
		permission: boolean
};
	["PayloadPreferencesDocAccessFields_createdAt_Delete"]: {
		permission: boolean
};
	["PayloadPreferencesCreateDocAccess"]: {
		permission: boolean,
	where?: ModelTypes["JSONObject"] | undefined
};
	["PayloadPreferencesReadDocAccess"]: {
		permission: boolean,
	where?: ModelTypes["JSONObject"] | undefined
};
	["PayloadPreferencesUpdateDocAccess"]: {
		permission: boolean,
	where?: ModelTypes["JSONObject"] | undefined
};
	["PayloadPreferencesDeleteDocAccess"]: {
		permission: boolean,
	where?: ModelTypes["JSONObject"] | undefined
};
	["Access"]: {
		canAccessAdmin: boolean,
	events?: ModelTypes["eventsAccess"] | undefined,
	locations?: ModelTypes["locationsAccess"] | undefined,
	posts?: ModelTypes["postsAccess"] | undefined,
	ticketing?: ModelTypes["ticketingAccess"] | undefined,
	products?: ModelTypes["productsAccess"] | undefined,
	inventory_status?: ModelTypes["inventory_statusAccess"] | undefined,
	transactions?: ModelTypes["transactionsAccess"] | undefined,
	redemption_codes?: ModelTypes["redemption_codesAccess"] | undefined,
	redemption_pdf?: ModelTypes["redemption_pdfAccess"] | undefined,
	check_in_redemption?: ModelTypes["check_in_redemptionAccess"] | undefined,
	users?: ModelTypes["usersAccess"] | undefined,
	bookmarks?: ModelTypes["bookmarksAccess"] | undefined,
	webAppBookmarks?: ModelTypes["webAppBookmarksAccess"] | undefined,
	view_records?: ModelTypes["view_recordsAccess"] | undefined,
	comments?: ModelTypes["commentsAccess"] | undefined,
	subscriptions?: ModelTypes["subscriptionsAccess"] | undefined,
	newsletter?: ModelTypes["newsletterAccess"] | undefined,
	blog_categories?: ModelTypes["blog_categoriesAccess"] | undefined,
	tags?: ModelTypes["tagsAccess"] | undefined,
	specialTags?: ModelTypes["specialTagsAccess"] | undefined,
	date?: ModelTypes["dateAccess"] | undefined,
	districts?: ModelTypes["districtsAccess"] | undefined,
	audience?: ModelTypes["audienceAccess"] | undefined,
	category?: ModelTypes["categoryAccess"] | undefined,
	private_media?: ModelTypes["private_mediaAccess"] | undefined,
	media?: ModelTypes["mediaAccess"] | undefined,
	backupMedia?: ModelTypes["backupMediaAccess"] | undefined,
	account_suspend_requests?: ModelTypes["account_suspend_requestsAccess"] | undefined,
	devices?: ModelTypes["devicesAccess"] | undefined,
	notifications?: ModelTypes["notificationsAccess"] | undefined,
	redirectSetting?: ModelTypes["redirectSettingAccess"] | undefined,
	ranking_events?: ModelTypes["ranking_eventsAccess"] | undefined,
	ranking_locations?: ModelTypes["ranking_locationsAccess"] | undefined,
	event_calendar?: ModelTypes["event_calendarAccess"] | undefined,
	editor_choices?: ModelTypes["editor_choicesAccess"] | undefined,
	criteria_setting?: ModelTypes["criteria_settingAccess"] | undefined,
	organiserSetting?: ModelTypes["organiserSettingAccess"] | undefined,
	post_setting?: ModelTypes["post_settingAccess"] | undefined,
	homepageSetting?: ModelTypes["homepageSettingAccess"] | undefined,
	banner_section?: ModelTypes["banner_sectionAccess"] | undefined,
	macao_banner_section?: ModelTypes["macao_banner_sectionAccess"] | undefined,
	banners?: ModelTypes["bannersAccess"] | undefined,
	campaigns?: ModelTypes["campaignsAccess"] | undefined,
	advertises?: ModelTypes["advertisesAccess"] | undefined,
	statics?: ModelTypes["staticsAccess"] | undefined,
	event_views?: ModelTypes["event_viewsAccess"] | undefined,
	post_views?: ModelTypes["post_viewsAccess"] | undefined,
	payload_preferences?: ModelTypes["payload_preferencesAccess"] | undefined
};
	["eventsAccess"]: {
		fields?: ModelTypes["EventsFields"] | undefined,
	create?: ModelTypes["EventsCreateAccess"] | undefined,
	read?: ModelTypes["EventsReadAccess"] | undefined,
	update?: ModelTypes["EventsUpdateAccess"] | undefined,
	delete?: ModelTypes["EventsDeleteAccess"] | undefined
};
	["EventsFields"]: {
		region?: ModelTypes["EventsFields_region"] | undefined,
	creator?: ModelTypes["EventsFields_creator"] | undefined,
	permalink?: ModelTypes["EventsFields_permalink"] | undefined,
	status?: ModelTypes["EventsFields_status"] | undefined,
	level?: ModelTypes["EventsFields_level"] | undefined,
	parent?: ModelTypes["EventsFields_parent"] | undefined,
	name?: ModelTypes["EventsFields_name"] | undefined,
	chatbotEventTitle?: ModelTypes["EventsFields_chatbotEventTitle"] | undefined,
	chatbotSequence?: ModelTypes["EventsFields_chatbotSequence"] | undefined,
	thumbnail?: ModelTypes["EventsFields_thumbnail"] | undefined,
	banner?: ModelTypes["EventsFields_banner"] | undefined,
	mobileBanner?: ModelTypes["EventsFields_mobileBanner"] | undefined,
	sections?: ModelTypes["EventsFields_sections"] | undefined,
	ticketing?: ModelTypes["EventsFields_ticketing"] | undefined,
	content?: ModelTypes["EventsFields_content"] | undefined,
	legacyContent?: ModelTypes["EventsFields_legacyContent"] | undefined,
	showLegacyContent?: ModelTypes["EventsFields_showLegacyContent"] | undefined,
	criteria?: ModelTypes["EventsFields_criteria"] | undefined,
	walkIn?: ModelTypes["EventsFields_walkIn"] | undefined,
	contact?: ModelTypes["EventsFields_contact"] | undefined,
	views?: ModelTypes["EventsFields_views"] | undefined,
	free?: ModelTypes["EventsFields_free"] | undefined,
	fees?: ModelTypes["EventsFields_fees"] | undefined,
	homePageInternalClick?: ModelTypes["EventsFields_homePageInternalClick"] | undefined,
	homePageImpression?: ModelTypes["EventsFields_homePageImpression"] | undefined,
	eodClickRate?: ModelTypes["EventsFields_eodClickRate"] | undefined,
	eodImpressionRate?: ModelTypes["EventsFields_eodImpressionRate"] | undefined,
	top10ClickRateHomepage?: ModelTypes["EventsFields_top10ClickRateHomepage"] | undefined,
	top10ImpressionkRateHomepage?: ModelTypes["EventsFields_top10ImpressionkRateHomepage"] | undefined,
	otherEventRankingClickRateHomepage?: ModelTypes["EventsFields_otherEventRankingClickRateHomepage"] | undefined,
	otherEventRankingImpressionHomePage?: ModelTypes["EventsFields_otherEventRankingImpressionHomePage"] | undefined,
	userPreferenceClickRate?: ModelTypes["EventsFields_userPreferenceClickRate"] | undefined,
	userPreferenceImpression?: ModelTypes["EventsFields_userPreferenceImpression"] | undefined,
	eventSearchInternalClick?: ModelTypes["EventsFields_eventSearchInternalClick"] | undefined,
	eventListImpression?: ModelTypes["EventsFields_eventListImpression"] | undefined,
	latestEventInternalClick?: ModelTypes["EventsFields_latestEventInternalClick"] | undefined,
	latestEventImpression?: ModelTypes["EventsFields_latestEventImpression"] | undefined,
	topTenEventRankingInternalClick?: ModelTypes["EventsFields_topTenEventRankingInternalClick"] | undefined,
	topTenImpression?: ModelTypes["EventsFields_topTenImpression"] | undefined,
	editorChoiceInternalClick?: ModelTypes["EventsFields_editorChoiceInternalClick"] | undefined,
	editorChoiceImpression?: ModelTypes["EventsFields_editorChoiceImpression"] | undefined,
	otherEventRankingInternalClick?: ModelTypes["EventsFields_otherEventRankingInternalClick"] | undefined,
	otherEventRankingImpression?: ModelTypes["EventsFields_otherEventRankingImpression"] | undefined,
	bannerInternalClick?: ModelTypes["EventsFields_bannerInternalClick"] | undefined,
	miscImpression?: ModelTypes["EventsFields_miscImpression"] | undefined,
	miscInternalClick?: ModelTypes["EventsFields_miscInternalClick"] | undefined,
	totalImpression?: ModelTypes["EventsFields_totalImpression"] | undefined,
	internalClick?: ModelTypes["EventsFields_internalClick"] | undefined,
	outboundClick?: ModelTypes["EventsFields_outboundClick"] | undefined,
	externalClick?: ModelTypes["EventsFields_externalClick"] | undefined,
	_viewsCountByMonth?: ModelTypes["EventsFields__viewsCountByMonth"] | undefined,
	products?: ModelTypes["EventsFields_products"] | undefined,
	legacyGallery?: ModelTypes["EventsFields_legacyGallery"] | undefined,
	earliestStartDate?: ModelTypes["EventsFields_earliestStartDate"] | undefined,
	latestEndDate?: ModelTypes["EventsFields_latestEndDate"] | undefined,
	latestStartDateTime?: ModelTypes["EventsFields_latestStartDateTime"] | undefined,
	hasTicketing?: ModelTypes["EventsFields_hasTicketing"] | undefined,
	hasProducts?: ModelTypes["EventsFields_hasProducts"] | undefined,
	updatedAt?: ModelTypes["EventsFields_updatedAt"] | undefined,
	createdAt?: ModelTypes["EventsFields_createdAt"] | undefined
};
	["EventsFields_region"]: {
		create?: ModelTypes["EventsFields_region_Create"] | undefined,
	read?: ModelTypes["EventsFields_region_Read"] | undefined,
	update?: ModelTypes["EventsFields_region_Update"] | undefined,
	delete?: ModelTypes["EventsFields_region_Delete"] | undefined
};
	["EventsFields_region_Create"]: {
		permission: boolean
};
	["EventsFields_region_Read"]: {
		permission: boolean
};
	["EventsFields_region_Update"]: {
		permission: boolean
};
	["EventsFields_region_Delete"]: {
		permission: boolean
};
	["EventsFields_creator"]: {
		create?: ModelTypes["EventsFields_creator_Create"] | undefined,
	read?: ModelTypes["EventsFields_creator_Read"] | undefined,
	update?: ModelTypes["EventsFields_creator_Update"] | undefined,
	delete?: ModelTypes["EventsFields_creator_Delete"] | undefined
};
	["EventsFields_creator_Create"]: {
		permission: boolean
};
	["EventsFields_creator_Read"]: {
		permission: boolean
};
	["EventsFields_creator_Update"]: {
		permission: boolean
};
	["EventsFields_creator_Delete"]: {
		permission: boolean
};
	["EventsFields_permalink"]: {
		create?: ModelTypes["EventsFields_permalink_Create"] | undefined,
	read?: ModelTypes["EventsFields_permalink_Read"] | undefined,
	update?: ModelTypes["EventsFields_permalink_Update"] | undefined,
	delete?: ModelTypes["EventsFields_permalink_Delete"] | undefined
};
	["EventsFields_permalink_Create"]: {
		permission: boolean
};
	["EventsFields_permalink_Read"]: {
		permission: boolean
};
	["EventsFields_permalink_Update"]: {
		permission: boolean
};
	["EventsFields_permalink_Delete"]: {
		permission: boolean
};
	["EventsFields_status"]: {
		create?: ModelTypes["EventsFields_status_Create"] | undefined,
	read?: ModelTypes["EventsFields_status_Read"] | undefined,
	update?: ModelTypes["EventsFields_status_Update"] | undefined,
	delete?: ModelTypes["EventsFields_status_Delete"] | undefined
};
	["EventsFields_status_Create"]: {
		permission: boolean
};
	["EventsFields_status_Read"]: {
		permission: boolean
};
	["EventsFields_status_Update"]: {
		permission: boolean
};
	["EventsFields_status_Delete"]: {
		permission: boolean
};
	["EventsFields_level"]: {
		create?: ModelTypes["EventsFields_level_Create"] | undefined,
	read?: ModelTypes["EventsFields_level_Read"] | undefined,
	update?: ModelTypes["EventsFields_level_Update"] | undefined,
	delete?: ModelTypes["EventsFields_level_Delete"] | undefined
};
	["EventsFields_level_Create"]: {
		permission: boolean
};
	["EventsFields_level_Read"]: {
		permission: boolean
};
	["EventsFields_level_Update"]: {
		permission: boolean
};
	["EventsFields_level_Delete"]: {
		permission: boolean
};
	["EventsFields_parent"]: {
		create?: ModelTypes["EventsFields_parent_Create"] | undefined,
	read?: ModelTypes["EventsFields_parent_Read"] | undefined,
	update?: ModelTypes["EventsFields_parent_Update"] | undefined,
	delete?: ModelTypes["EventsFields_parent_Delete"] | undefined
};
	["EventsFields_parent_Create"]: {
		permission: boolean
};
	["EventsFields_parent_Read"]: {
		permission: boolean
};
	["EventsFields_parent_Update"]: {
		permission: boolean
};
	["EventsFields_parent_Delete"]: {
		permission: boolean
};
	["EventsFields_name"]: {
		create?: ModelTypes["EventsFields_name_Create"] | undefined,
	read?: ModelTypes["EventsFields_name_Read"] | undefined,
	update?: ModelTypes["EventsFields_name_Update"] | undefined,
	delete?: ModelTypes["EventsFields_name_Delete"] | undefined
};
	["EventsFields_name_Create"]: {
		permission: boolean
};
	["EventsFields_name_Read"]: {
		permission: boolean
};
	["EventsFields_name_Update"]: {
		permission: boolean
};
	["EventsFields_name_Delete"]: {
		permission: boolean
};
	["EventsFields_chatbotEventTitle"]: {
		create?: ModelTypes["EventsFields_chatbotEventTitle_Create"] | undefined,
	read?: ModelTypes["EventsFields_chatbotEventTitle_Read"] | undefined,
	update?: ModelTypes["EventsFields_chatbotEventTitle_Update"] | undefined,
	delete?: ModelTypes["EventsFields_chatbotEventTitle_Delete"] | undefined
};
	["EventsFields_chatbotEventTitle_Create"]: {
		permission: boolean
};
	["EventsFields_chatbotEventTitle_Read"]: {
		permission: boolean
};
	["EventsFields_chatbotEventTitle_Update"]: {
		permission: boolean
};
	["EventsFields_chatbotEventTitle_Delete"]: {
		permission: boolean
};
	["EventsFields_chatbotSequence"]: {
		create?: ModelTypes["EventsFields_chatbotSequence_Create"] | undefined,
	read?: ModelTypes["EventsFields_chatbotSequence_Read"] | undefined,
	update?: ModelTypes["EventsFields_chatbotSequence_Update"] | undefined,
	delete?: ModelTypes["EventsFields_chatbotSequence_Delete"] | undefined
};
	["EventsFields_chatbotSequence_Create"]: {
		permission: boolean
};
	["EventsFields_chatbotSequence_Read"]: {
		permission: boolean
};
	["EventsFields_chatbotSequence_Update"]: {
		permission: boolean
};
	["EventsFields_chatbotSequence_Delete"]: {
		permission: boolean
};
	["EventsFields_thumbnail"]: {
		create?: ModelTypes["EventsFields_thumbnail_Create"] | undefined,
	read?: ModelTypes["EventsFields_thumbnail_Read"] | undefined,
	update?: ModelTypes["EventsFields_thumbnail_Update"] | undefined,
	delete?: ModelTypes["EventsFields_thumbnail_Delete"] | undefined
};
	["EventsFields_thumbnail_Create"]: {
		permission: boolean
};
	["EventsFields_thumbnail_Read"]: {
		permission: boolean
};
	["EventsFields_thumbnail_Update"]: {
		permission: boolean
};
	["EventsFields_thumbnail_Delete"]: {
		permission: boolean
};
	["EventsFields_banner"]: {
		create?: ModelTypes["EventsFields_banner_Create"] | undefined,
	read?: ModelTypes["EventsFields_banner_Read"] | undefined,
	update?: ModelTypes["EventsFields_banner_Update"] | undefined,
	delete?: ModelTypes["EventsFields_banner_Delete"] | undefined
};
	["EventsFields_banner_Create"]: {
		permission: boolean
};
	["EventsFields_banner_Read"]: {
		permission: boolean
};
	["EventsFields_banner_Update"]: {
		permission: boolean
};
	["EventsFields_banner_Delete"]: {
		permission: boolean
};
	["EventsFields_mobileBanner"]: {
		create?: ModelTypes["EventsFields_mobileBanner_Create"] | undefined,
	read?: ModelTypes["EventsFields_mobileBanner_Read"] | undefined,
	update?: ModelTypes["EventsFields_mobileBanner_Update"] | undefined,
	delete?: ModelTypes["EventsFields_mobileBanner_Delete"] | undefined
};
	["EventsFields_mobileBanner_Create"]: {
		permission: boolean
};
	["EventsFields_mobileBanner_Read"]: {
		permission: boolean
};
	["EventsFields_mobileBanner_Update"]: {
		permission: boolean
};
	["EventsFields_mobileBanner_Delete"]: {
		permission: boolean
};
	["EventsFields_sections"]: {
		create?: ModelTypes["EventsFields_sections_Create"] | undefined,
	read?: ModelTypes["EventsFields_sections_Read"] | undefined,
	update?: ModelTypes["EventsFields_sections_Update"] | undefined,
	delete?: ModelTypes["EventsFields_sections_Delete"] | undefined,
	fields?: ModelTypes["EventsFields_sections_Fields"] | undefined
};
	["EventsFields_sections_Create"]: {
		permission: boolean
};
	["EventsFields_sections_Read"]: {
		permission: boolean
};
	["EventsFields_sections_Update"]: {
		permission: boolean
};
	["EventsFields_sections_Delete"]: {
		permission: boolean
};
	["EventsFields_sections_Fields"]: {
		startDatetime?: ModelTypes["EventsFields_sections_startDatetime"] | undefined,
	endDatetime?: ModelTypes["EventsFields_sections_endDatetime"] | undefined,
	fullDay?: ModelTypes["EventsFields_sections_fullDay"] | undefined,
	repeat?: ModelTypes["EventsFields_sections_repeat"] | undefined,
	toThisDay?: ModelTypes["EventsFields_sections_toThisDay"] | undefined,
	recurrance?: ModelTypes["EventsFields_sections_recurrance"] | undefined,
	sameLocation?: ModelTypes["EventsFields_sections_sameLocation"] | undefined,
	location?: ModelTypes["EventsFields_sections_location"] | undefined,
	district?: ModelTypes["EventsFields_sections_district"] | undefined,
	coordinate?: ModelTypes["EventsFields_sections_coordinate"] | undefined,
	linkAddress?: ModelTypes["EventsFields_sections_linkAddress"] | undefined,
	address?: ModelTypes["EventsFields_sections_address"] | undefined,
	id?: ModelTypes["EventsFields_sections_id"] | undefined
};
	["EventsFields_sections_startDatetime"]: {
		create?: ModelTypes["EventsFields_sections_startDatetime_Create"] | undefined,
	read?: ModelTypes["EventsFields_sections_startDatetime_Read"] | undefined,
	update?: ModelTypes["EventsFields_sections_startDatetime_Update"] | undefined,
	delete?: ModelTypes["EventsFields_sections_startDatetime_Delete"] | undefined
};
	["EventsFields_sections_startDatetime_Create"]: {
		permission: boolean
};
	["EventsFields_sections_startDatetime_Read"]: {
		permission: boolean
};
	["EventsFields_sections_startDatetime_Update"]: {
		permission: boolean
};
	["EventsFields_sections_startDatetime_Delete"]: {
		permission: boolean
};
	["EventsFields_sections_endDatetime"]: {
		create?: ModelTypes["EventsFields_sections_endDatetime_Create"] | undefined,
	read?: ModelTypes["EventsFields_sections_endDatetime_Read"] | undefined,
	update?: ModelTypes["EventsFields_sections_endDatetime_Update"] | undefined,
	delete?: ModelTypes["EventsFields_sections_endDatetime_Delete"] | undefined
};
	["EventsFields_sections_endDatetime_Create"]: {
		permission: boolean
};
	["EventsFields_sections_endDatetime_Read"]: {
		permission: boolean
};
	["EventsFields_sections_endDatetime_Update"]: {
		permission: boolean
};
	["EventsFields_sections_endDatetime_Delete"]: {
		permission: boolean
};
	["EventsFields_sections_fullDay"]: {
		create?: ModelTypes["EventsFields_sections_fullDay_Create"] | undefined,
	read?: ModelTypes["EventsFields_sections_fullDay_Read"] | undefined,
	update?: ModelTypes["EventsFields_sections_fullDay_Update"] | undefined,
	delete?: ModelTypes["EventsFields_sections_fullDay_Delete"] | undefined
};
	["EventsFields_sections_fullDay_Create"]: {
		permission: boolean
};
	["EventsFields_sections_fullDay_Read"]: {
		permission: boolean
};
	["EventsFields_sections_fullDay_Update"]: {
		permission: boolean
};
	["EventsFields_sections_fullDay_Delete"]: {
		permission: boolean
};
	["EventsFields_sections_repeat"]: {
		create?: ModelTypes["EventsFields_sections_repeat_Create"] | undefined,
	read?: ModelTypes["EventsFields_sections_repeat_Read"] | undefined,
	update?: ModelTypes["EventsFields_sections_repeat_Update"] | undefined,
	delete?: ModelTypes["EventsFields_sections_repeat_Delete"] | undefined
};
	["EventsFields_sections_repeat_Create"]: {
		permission: boolean
};
	["EventsFields_sections_repeat_Read"]: {
		permission: boolean
};
	["EventsFields_sections_repeat_Update"]: {
		permission: boolean
};
	["EventsFields_sections_repeat_Delete"]: {
		permission: boolean
};
	["EventsFields_sections_toThisDay"]: {
		create?: ModelTypes["EventsFields_sections_toThisDay_Create"] | undefined,
	read?: ModelTypes["EventsFields_sections_toThisDay_Read"] | undefined,
	update?: ModelTypes["EventsFields_sections_toThisDay_Update"] | undefined,
	delete?: ModelTypes["EventsFields_sections_toThisDay_Delete"] | undefined
};
	["EventsFields_sections_toThisDay_Create"]: {
		permission: boolean
};
	["EventsFields_sections_toThisDay_Read"]: {
		permission: boolean
};
	["EventsFields_sections_toThisDay_Update"]: {
		permission: boolean
};
	["EventsFields_sections_toThisDay_Delete"]: {
		permission: boolean
};
	["EventsFields_sections_recurrance"]: {
		create?: ModelTypes["EventsFields_sections_recurrance_Create"] | undefined,
	read?: ModelTypes["EventsFields_sections_recurrance_Read"] | undefined,
	update?: ModelTypes["EventsFields_sections_recurrance_Update"] | undefined,
	delete?: ModelTypes["EventsFields_sections_recurrance_Delete"] | undefined,
	fields?: ModelTypes["EventsFields_sections_recurrance_Fields"] | undefined
};
	["EventsFields_sections_recurrance_Create"]: {
		permission: boolean
};
	["EventsFields_sections_recurrance_Read"]: {
		permission: boolean
};
	["EventsFields_sections_recurrance_Update"]: {
		permission: boolean
};
	["EventsFields_sections_recurrance_Delete"]: {
		permission: boolean
};
	["EventsFields_sections_recurrance_Fields"]: {
		type?: ModelTypes["EventsFields_sections_recurrance_type"] | undefined,
	weekday?: ModelTypes["EventsFields_sections_recurrance_weekday"] | undefined
};
	["EventsFields_sections_recurrance_type"]: {
		create?: ModelTypes["EventsFields_sections_recurrance_type_Create"] | undefined,
	read?: ModelTypes["EventsFields_sections_recurrance_type_Read"] | undefined,
	update?: ModelTypes["EventsFields_sections_recurrance_type_Update"] | undefined,
	delete?: ModelTypes["EventsFields_sections_recurrance_type_Delete"] | undefined
};
	["EventsFields_sections_recurrance_type_Create"]: {
		permission: boolean
};
	["EventsFields_sections_recurrance_type_Read"]: {
		permission: boolean
};
	["EventsFields_sections_recurrance_type_Update"]: {
		permission: boolean
};
	["EventsFields_sections_recurrance_type_Delete"]: {
		permission: boolean
};
	["EventsFields_sections_recurrance_weekday"]: {
		create?: ModelTypes["EventsFields_sections_recurrance_weekday_Create"] | undefined,
	read?: ModelTypes["EventsFields_sections_recurrance_weekday_Read"] | undefined,
	update?: ModelTypes["EventsFields_sections_recurrance_weekday_Update"] | undefined,
	delete?: ModelTypes["EventsFields_sections_recurrance_weekday_Delete"] | undefined
};
	["EventsFields_sections_recurrance_weekday_Create"]: {
		permission: boolean
};
	["EventsFields_sections_recurrance_weekday_Read"]: {
		permission: boolean
};
	["EventsFields_sections_recurrance_weekday_Update"]: {
		permission: boolean
};
	["EventsFields_sections_recurrance_weekday_Delete"]: {
		permission: boolean
};
	["EventsFields_sections_sameLocation"]: {
		create?: ModelTypes["EventsFields_sections_sameLocation_Create"] | undefined,
	read?: ModelTypes["EventsFields_sections_sameLocation_Read"] | undefined,
	update?: ModelTypes["EventsFields_sections_sameLocation_Update"] | undefined,
	delete?: ModelTypes["EventsFields_sections_sameLocation_Delete"] | undefined
};
	["EventsFields_sections_sameLocation_Create"]: {
		permission: boolean
};
	["EventsFields_sections_sameLocation_Read"]: {
		permission: boolean
};
	["EventsFields_sections_sameLocation_Update"]: {
		permission: boolean
};
	["EventsFields_sections_sameLocation_Delete"]: {
		permission: boolean
};
	["EventsFields_sections_location"]: {
		create?: ModelTypes["EventsFields_sections_location_Create"] | undefined,
	read?: ModelTypes["EventsFields_sections_location_Read"] | undefined,
	update?: ModelTypes["EventsFields_sections_location_Update"] | undefined,
	delete?: ModelTypes["EventsFields_sections_location_Delete"] | undefined
};
	["EventsFields_sections_location_Create"]: {
		permission: boolean
};
	["EventsFields_sections_location_Read"]: {
		permission: boolean
};
	["EventsFields_sections_location_Update"]: {
		permission: boolean
};
	["EventsFields_sections_location_Delete"]: {
		permission: boolean
};
	["EventsFields_sections_district"]: {
		create?: ModelTypes["EventsFields_sections_district_Create"] | undefined,
	read?: ModelTypes["EventsFields_sections_district_Read"] | undefined,
	update?: ModelTypes["EventsFields_sections_district_Update"] | undefined,
	delete?: ModelTypes["EventsFields_sections_district_Delete"] | undefined
};
	["EventsFields_sections_district_Create"]: {
		permission: boolean
};
	["EventsFields_sections_district_Read"]: {
		permission: boolean
};
	["EventsFields_sections_district_Update"]: {
		permission: boolean
};
	["EventsFields_sections_district_Delete"]: {
		permission: boolean
};
	["EventsFields_sections_coordinate"]: {
		create?: ModelTypes["EventsFields_sections_coordinate_Create"] | undefined,
	read?: ModelTypes["EventsFields_sections_coordinate_Read"] | undefined,
	update?: ModelTypes["EventsFields_sections_coordinate_Update"] | undefined,
	delete?: ModelTypes["EventsFields_sections_coordinate_Delete"] | undefined
};
	["EventsFields_sections_coordinate_Create"]: {
		permission: boolean
};
	["EventsFields_sections_coordinate_Read"]: {
		permission: boolean
};
	["EventsFields_sections_coordinate_Update"]: {
		permission: boolean
};
	["EventsFields_sections_coordinate_Delete"]: {
		permission: boolean
};
	["EventsFields_sections_linkAddress"]: {
		create?: ModelTypes["EventsFields_sections_linkAddress_Create"] | undefined,
	read?: ModelTypes["EventsFields_sections_linkAddress_Read"] | undefined,
	update?: ModelTypes["EventsFields_sections_linkAddress_Update"] | undefined,
	delete?: ModelTypes["EventsFields_sections_linkAddress_Delete"] | undefined
};
	["EventsFields_sections_linkAddress_Create"]: {
		permission: boolean
};
	["EventsFields_sections_linkAddress_Read"]: {
		permission: boolean
};
	["EventsFields_sections_linkAddress_Update"]: {
		permission: boolean
};
	["EventsFields_sections_linkAddress_Delete"]: {
		permission: boolean
};
	["EventsFields_sections_address"]: {
		create?: ModelTypes["EventsFields_sections_address_Create"] | undefined,
	read?: ModelTypes["EventsFields_sections_address_Read"] | undefined,
	update?: ModelTypes["EventsFields_sections_address_Update"] | undefined,
	delete?: ModelTypes["EventsFields_sections_address_Delete"] | undefined
};
	["EventsFields_sections_address_Create"]: {
		permission: boolean
};
	["EventsFields_sections_address_Read"]: {
		permission: boolean
};
	["EventsFields_sections_address_Update"]: {
		permission: boolean
};
	["EventsFields_sections_address_Delete"]: {
		permission: boolean
};
	["EventsFields_sections_id"]: {
		create?: ModelTypes["EventsFields_sections_id_Create"] | undefined,
	read?: ModelTypes["EventsFields_sections_id_Read"] | undefined,
	update?: ModelTypes["EventsFields_sections_id_Update"] | undefined,
	delete?: ModelTypes["EventsFields_sections_id_Delete"] | undefined
};
	["EventsFields_sections_id_Create"]: {
		permission: boolean
};
	["EventsFields_sections_id_Read"]: {
		permission: boolean
};
	["EventsFields_sections_id_Update"]: {
		permission: boolean
};
	["EventsFields_sections_id_Delete"]: {
		permission: boolean
};
	["EventsFields_ticketing"]: {
		create?: ModelTypes["EventsFields_ticketing_Create"] | undefined,
	read?: ModelTypes["EventsFields_ticketing_Read"] | undefined,
	update?: ModelTypes["EventsFields_ticketing_Update"] | undefined,
	delete?: ModelTypes["EventsFields_ticketing_Delete"] | undefined,
	fields?: ModelTypes["EventsFields_ticketing_Fields"] | undefined
};
	["EventsFields_ticketing_Create"]: {
		permission: boolean
};
	["EventsFields_ticketing_Read"]: {
		permission: boolean
};
	["EventsFields_ticketing_Update"]: {
		permission: boolean
};
	["EventsFields_ticketing_Delete"]: {
		permission: boolean
};
	["EventsFields_ticketing_Fields"]: {
		startDatetime?: ModelTypes["EventsFields_ticketing_startDatetime"] | undefined,
	endDatetime?: ModelTypes["EventsFields_ticketing_endDatetime"] | undefined,
	fullDay?: ModelTypes["EventsFields_ticketing_fullDay"] | undefined,
	type?: ModelTypes["EventsFields_ticketing_type"] | undefined,
	ticketingType?: ModelTypes["EventsFields_ticketing_ticketingType"] | undefined,
	linkedTicketing?: ModelTypes["EventsFields_ticketing_linkedTicketing"] | undefined,
	promotionType?: ModelTypes["EventsFields_ticketing_promotionType"] | undefined,
	location?: ModelTypes["EventsFields_ticketing_location"] | undefined,
	website?: ModelTypes["EventsFields_ticketing_website"] | undefined,
	description?: ModelTypes["EventsFields_ticketing_description"] | undefined,
	id?: ModelTypes["EventsFields_ticketing_id"] | undefined
};
	["EventsFields_ticketing_startDatetime"]: {
		create?: ModelTypes["EventsFields_ticketing_startDatetime_Create"] | undefined,
	read?: ModelTypes["EventsFields_ticketing_startDatetime_Read"] | undefined,
	update?: ModelTypes["EventsFields_ticketing_startDatetime_Update"] | undefined,
	delete?: ModelTypes["EventsFields_ticketing_startDatetime_Delete"] | undefined
};
	["EventsFields_ticketing_startDatetime_Create"]: {
		permission: boolean
};
	["EventsFields_ticketing_startDatetime_Read"]: {
		permission: boolean
};
	["EventsFields_ticketing_startDatetime_Update"]: {
		permission: boolean
};
	["EventsFields_ticketing_startDatetime_Delete"]: {
		permission: boolean
};
	["EventsFields_ticketing_endDatetime"]: {
		create?: ModelTypes["EventsFields_ticketing_endDatetime_Create"] | undefined,
	read?: ModelTypes["EventsFields_ticketing_endDatetime_Read"] | undefined,
	update?: ModelTypes["EventsFields_ticketing_endDatetime_Update"] | undefined,
	delete?: ModelTypes["EventsFields_ticketing_endDatetime_Delete"] | undefined
};
	["EventsFields_ticketing_endDatetime_Create"]: {
		permission: boolean
};
	["EventsFields_ticketing_endDatetime_Read"]: {
		permission: boolean
};
	["EventsFields_ticketing_endDatetime_Update"]: {
		permission: boolean
};
	["EventsFields_ticketing_endDatetime_Delete"]: {
		permission: boolean
};
	["EventsFields_ticketing_fullDay"]: {
		create?: ModelTypes["EventsFields_ticketing_fullDay_Create"] | undefined,
	read?: ModelTypes["EventsFields_ticketing_fullDay_Read"] | undefined,
	update?: ModelTypes["EventsFields_ticketing_fullDay_Update"] | undefined,
	delete?: ModelTypes["EventsFields_ticketing_fullDay_Delete"] | undefined
};
	["EventsFields_ticketing_fullDay_Create"]: {
		permission: boolean
};
	["EventsFields_ticketing_fullDay_Read"]: {
		permission: boolean
};
	["EventsFields_ticketing_fullDay_Update"]: {
		permission: boolean
};
	["EventsFields_ticketing_fullDay_Delete"]: {
		permission: boolean
};
	["EventsFields_ticketing_type"]: {
		create?: ModelTypes["EventsFields_ticketing_type_Create"] | undefined,
	read?: ModelTypes["EventsFields_ticketing_type_Read"] | undefined,
	update?: ModelTypes["EventsFields_ticketing_type_Update"] | undefined,
	delete?: ModelTypes["EventsFields_ticketing_type_Delete"] | undefined
};
	["EventsFields_ticketing_type_Create"]: {
		permission: boolean
};
	["EventsFields_ticketing_type_Read"]: {
		permission: boolean
};
	["EventsFields_ticketing_type_Update"]: {
		permission: boolean
};
	["EventsFields_ticketing_type_Delete"]: {
		permission: boolean
};
	["EventsFields_ticketing_ticketingType"]: {
		create?: ModelTypes["EventsFields_ticketing_ticketingType_Create"] | undefined,
	read?: ModelTypes["EventsFields_ticketing_ticketingType_Read"] | undefined,
	update?: ModelTypes["EventsFields_ticketing_ticketingType_Update"] | undefined,
	delete?: ModelTypes["EventsFields_ticketing_ticketingType_Delete"] | undefined
};
	["EventsFields_ticketing_ticketingType_Create"]: {
		permission: boolean
};
	["EventsFields_ticketing_ticketingType_Read"]: {
		permission: boolean
};
	["EventsFields_ticketing_ticketingType_Update"]: {
		permission: boolean
};
	["EventsFields_ticketing_ticketingType_Delete"]: {
		permission: boolean
};
	["EventsFields_ticketing_linkedTicketing"]: {
		create?: ModelTypes["EventsFields_ticketing_linkedTicketing_Create"] | undefined,
	read?: ModelTypes["EventsFields_ticketing_linkedTicketing_Read"] | undefined,
	update?: ModelTypes["EventsFields_ticketing_linkedTicketing_Update"] | undefined,
	delete?: ModelTypes["EventsFields_ticketing_linkedTicketing_Delete"] | undefined
};
	["EventsFields_ticketing_linkedTicketing_Create"]: {
		permission: boolean
};
	["EventsFields_ticketing_linkedTicketing_Read"]: {
		permission: boolean
};
	["EventsFields_ticketing_linkedTicketing_Update"]: {
		permission: boolean
};
	["EventsFields_ticketing_linkedTicketing_Delete"]: {
		permission: boolean
};
	["EventsFields_ticketing_promotionType"]: {
		create?: ModelTypes["EventsFields_ticketing_promotionType_Create"] | undefined,
	read?: ModelTypes["EventsFields_ticketing_promotionType_Read"] | undefined,
	update?: ModelTypes["EventsFields_ticketing_promotionType_Update"] | undefined,
	delete?: ModelTypes["EventsFields_ticketing_promotionType_Delete"] | undefined
};
	["EventsFields_ticketing_promotionType_Create"]: {
		permission: boolean
};
	["EventsFields_ticketing_promotionType_Read"]: {
		permission: boolean
};
	["EventsFields_ticketing_promotionType_Update"]: {
		permission: boolean
};
	["EventsFields_ticketing_promotionType_Delete"]: {
		permission: boolean
};
	["EventsFields_ticketing_location"]: {
		create?: ModelTypes["EventsFields_ticketing_location_Create"] | undefined,
	read?: ModelTypes["EventsFields_ticketing_location_Read"] | undefined,
	update?: ModelTypes["EventsFields_ticketing_location_Update"] | undefined,
	delete?: ModelTypes["EventsFields_ticketing_location_Delete"] | undefined
};
	["EventsFields_ticketing_location_Create"]: {
		permission: boolean
};
	["EventsFields_ticketing_location_Read"]: {
		permission: boolean
};
	["EventsFields_ticketing_location_Update"]: {
		permission: boolean
};
	["EventsFields_ticketing_location_Delete"]: {
		permission: boolean
};
	["EventsFields_ticketing_website"]: {
		create?: ModelTypes["EventsFields_ticketing_website_Create"] | undefined,
	read?: ModelTypes["EventsFields_ticketing_website_Read"] | undefined,
	update?: ModelTypes["EventsFields_ticketing_website_Update"] | undefined,
	delete?: ModelTypes["EventsFields_ticketing_website_Delete"] | undefined
};
	["EventsFields_ticketing_website_Create"]: {
		permission: boolean
};
	["EventsFields_ticketing_website_Read"]: {
		permission: boolean
};
	["EventsFields_ticketing_website_Update"]: {
		permission: boolean
};
	["EventsFields_ticketing_website_Delete"]: {
		permission: boolean
};
	["EventsFields_ticketing_description"]: {
		create?: ModelTypes["EventsFields_ticketing_description_Create"] | undefined,
	read?: ModelTypes["EventsFields_ticketing_description_Read"] | undefined,
	update?: ModelTypes["EventsFields_ticketing_description_Update"] | undefined,
	delete?: ModelTypes["EventsFields_ticketing_description_Delete"] | undefined
};
	["EventsFields_ticketing_description_Create"]: {
		permission: boolean
};
	["EventsFields_ticketing_description_Read"]: {
		permission: boolean
};
	["EventsFields_ticketing_description_Update"]: {
		permission: boolean
};
	["EventsFields_ticketing_description_Delete"]: {
		permission: boolean
};
	["EventsFields_ticketing_id"]: {
		create?: ModelTypes["EventsFields_ticketing_id_Create"] | undefined,
	read?: ModelTypes["EventsFields_ticketing_id_Read"] | undefined,
	update?: ModelTypes["EventsFields_ticketing_id_Update"] | undefined,
	delete?: ModelTypes["EventsFields_ticketing_id_Delete"] | undefined
};
	["EventsFields_ticketing_id_Create"]: {
		permission: boolean
};
	["EventsFields_ticketing_id_Read"]: {
		permission: boolean
};
	["EventsFields_ticketing_id_Update"]: {
		permission: boolean
};
	["EventsFields_ticketing_id_Delete"]: {
		permission: boolean
};
	["EventsFields_content"]: {
		create?: ModelTypes["EventsFields_content_Create"] | undefined,
	read?: ModelTypes["EventsFields_content_Read"] | undefined,
	update?: ModelTypes["EventsFields_content_Update"] | undefined,
	delete?: ModelTypes["EventsFields_content_Delete"] | undefined
};
	["EventsFields_content_Create"]: {
		permission: boolean
};
	["EventsFields_content_Read"]: {
		permission: boolean
};
	["EventsFields_content_Update"]: {
		permission: boolean
};
	["EventsFields_content_Delete"]: {
		permission: boolean
};
	["EventsFields_legacyContent"]: {
		create?: ModelTypes["EventsFields_legacyContent_Create"] | undefined,
	read?: ModelTypes["EventsFields_legacyContent_Read"] | undefined,
	update?: ModelTypes["EventsFields_legacyContent_Update"] | undefined,
	delete?: ModelTypes["EventsFields_legacyContent_Delete"] | undefined
};
	["EventsFields_legacyContent_Create"]: {
		permission: boolean
};
	["EventsFields_legacyContent_Read"]: {
		permission: boolean
};
	["EventsFields_legacyContent_Update"]: {
		permission: boolean
};
	["EventsFields_legacyContent_Delete"]: {
		permission: boolean
};
	["EventsFields_showLegacyContent"]: {
		create?: ModelTypes["EventsFields_showLegacyContent_Create"] | undefined,
	read?: ModelTypes["EventsFields_showLegacyContent_Read"] | undefined,
	update?: ModelTypes["EventsFields_showLegacyContent_Update"] | undefined,
	delete?: ModelTypes["EventsFields_showLegacyContent_Delete"] | undefined
};
	["EventsFields_showLegacyContent_Create"]: {
		permission: boolean
};
	["EventsFields_showLegacyContent_Read"]: {
		permission: boolean
};
	["EventsFields_showLegacyContent_Update"]: {
		permission: boolean
};
	["EventsFields_showLegacyContent_Delete"]: {
		permission: boolean
};
	["EventsFields_criteria"]: {
		create?: ModelTypes["EventsFields_criteria_Create"] | undefined,
	read?: ModelTypes["EventsFields_criteria_Read"] | undefined,
	update?: ModelTypes["EventsFields_criteria_Update"] | undefined,
	delete?: ModelTypes["EventsFields_criteria_Delete"] | undefined,
	fields?: ModelTypes["EventsFields_criteria_Fields"] | undefined
};
	["EventsFields_criteria_Create"]: {
		permission: boolean
};
	["EventsFields_criteria_Read"]: {
		permission: boolean
};
	["EventsFields_criteria_Update"]: {
		permission: boolean
};
	["EventsFields_criteria_Delete"]: {
		permission: boolean
};
	["EventsFields_criteria_Fields"]: {
		categories?: ModelTypes["EventsFields_criteria_categories"] | undefined,
	audiences?: ModelTypes["EventsFields_criteria_audiences"] | undefined,
	tags?: ModelTypes["EventsFields_criteria_tags"] | undefined,
	specialTags?: ModelTypes["EventsFields_criteria_specialTags"] | undefined
};
	["EventsFields_criteria_categories"]: {
		create?: ModelTypes["EventsFields_criteria_categories_Create"] | undefined,
	read?: ModelTypes["EventsFields_criteria_categories_Read"] | undefined,
	update?: ModelTypes["EventsFields_criteria_categories_Update"] | undefined,
	delete?: ModelTypes["EventsFields_criteria_categories_Delete"] | undefined
};
	["EventsFields_criteria_categories_Create"]: {
		permission: boolean
};
	["EventsFields_criteria_categories_Read"]: {
		permission: boolean
};
	["EventsFields_criteria_categories_Update"]: {
		permission: boolean
};
	["EventsFields_criteria_categories_Delete"]: {
		permission: boolean
};
	["EventsFields_criteria_audiences"]: {
		create?: ModelTypes["EventsFields_criteria_audiences_Create"] | undefined,
	read?: ModelTypes["EventsFields_criteria_audiences_Read"] | undefined,
	update?: ModelTypes["EventsFields_criteria_audiences_Update"] | undefined,
	delete?: ModelTypes["EventsFields_criteria_audiences_Delete"] | undefined
};
	["EventsFields_criteria_audiences_Create"]: {
		permission: boolean
};
	["EventsFields_criteria_audiences_Read"]: {
		permission: boolean
};
	["EventsFields_criteria_audiences_Update"]: {
		permission: boolean
};
	["EventsFields_criteria_audiences_Delete"]: {
		permission: boolean
};
	["EventsFields_criteria_tags"]: {
		create?: ModelTypes["EventsFields_criteria_tags_Create"] | undefined,
	read?: ModelTypes["EventsFields_criteria_tags_Read"] | undefined,
	update?: ModelTypes["EventsFields_criteria_tags_Update"] | undefined,
	delete?: ModelTypes["EventsFields_criteria_tags_Delete"] | undefined
};
	["EventsFields_criteria_tags_Create"]: {
		permission: boolean
};
	["EventsFields_criteria_tags_Read"]: {
		permission: boolean
};
	["EventsFields_criteria_tags_Update"]: {
		permission: boolean
};
	["EventsFields_criteria_tags_Delete"]: {
		permission: boolean
};
	["EventsFields_criteria_specialTags"]: {
		create?: ModelTypes["EventsFields_criteria_specialTags_Create"] | undefined,
	read?: ModelTypes["EventsFields_criteria_specialTags_Read"] | undefined,
	update?: ModelTypes["EventsFields_criteria_specialTags_Update"] | undefined,
	delete?: ModelTypes["EventsFields_criteria_specialTags_Delete"] | undefined
};
	["EventsFields_criteria_specialTags_Create"]: {
		permission: boolean
};
	["EventsFields_criteria_specialTags_Read"]: {
		permission: boolean
};
	["EventsFields_criteria_specialTags_Update"]: {
		permission: boolean
};
	["EventsFields_criteria_specialTags_Delete"]: {
		permission: boolean
};
	["EventsFields_walkIn"]: {
		create?: ModelTypes["EventsFields_walkIn_Create"] | undefined,
	read?: ModelTypes["EventsFields_walkIn_Read"] | undefined,
	update?: ModelTypes["EventsFields_walkIn_Update"] | undefined,
	delete?: ModelTypes["EventsFields_walkIn_Delete"] | undefined
};
	["EventsFields_walkIn_Create"]: {
		permission: boolean
};
	["EventsFields_walkIn_Read"]: {
		permission: boolean
};
	["EventsFields_walkIn_Update"]: {
		permission: boolean
};
	["EventsFields_walkIn_Delete"]: {
		permission: boolean
};
	["EventsFields_contact"]: {
		create?: ModelTypes["EventsFields_contact_Create"] | undefined,
	read?: ModelTypes["EventsFields_contact_Read"] | undefined,
	update?: ModelTypes["EventsFields_contact_Update"] | undefined,
	delete?: ModelTypes["EventsFields_contact_Delete"] | undefined,
	fields?: ModelTypes["EventsFields_contact_Fields"] | undefined
};
	["EventsFields_contact_Create"]: {
		permission: boolean
};
	["EventsFields_contact_Read"]: {
		permission: boolean
};
	["EventsFields_contact_Update"]: {
		permission: boolean
};
	["EventsFields_contact_Delete"]: {
		permission: boolean
};
	["EventsFields_contact_Fields"]: {
		code?: ModelTypes["EventsFields_contact_code"] | undefined,
	phone?: ModelTypes["EventsFields_contact_phone"] | undefined,
	page?: ModelTypes["EventsFields_contact_page"] | undefined,
	email?: ModelTypes["EventsFields_contact_email"] | undefined,
	privacy?: ModelTypes["EventsFields_contact_privacy"] | undefined
};
	["EventsFields_contact_code"]: {
		create?: ModelTypes["EventsFields_contact_code_Create"] | undefined,
	read?: ModelTypes["EventsFields_contact_code_Read"] | undefined,
	update?: ModelTypes["EventsFields_contact_code_Update"] | undefined,
	delete?: ModelTypes["EventsFields_contact_code_Delete"] | undefined
};
	["EventsFields_contact_code_Create"]: {
		permission: boolean
};
	["EventsFields_contact_code_Read"]: {
		permission: boolean
};
	["EventsFields_contact_code_Update"]: {
		permission: boolean
};
	["EventsFields_contact_code_Delete"]: {
		permission: boolean
};
	["EventsFields_contact_phone"]: {
		create?: ModelTypes["EventsFields_contact_phone_Create"] | undefined,
	read?: ModelTypes["EventsFields_contact_phone_Read"] | undefined,
	update?: ModelTypes["EventsFields_contact_phone_Update"] | undefined,
	delete?: ModelTypes["EventsFields_contact_phone_Delete"] | undefined
};
	["EventsFields_contact_phone_Create"]: {
		permission: boolean
};
	["EventsFields_contact_phone_Read"]: {
		permission: boolean
};
	["EventsFields_contact_phone_Update"]: {
		permission: boolean
};
	["EventsFields_contact_phone_Delete"]: {
		permission: boolean
};
	["EventsFields_contact_page"]: {
		create?: ModelTypes["EventsFields_contact_page_Create"] | undefined,
	read?: ModelTypes["EventsFields_contact_page_Read"] | undefined,
	update?: ModelTypes["EventsFields_contact_page_Update"] | undefined,
	delete?: ModelTypes["EventsFields_contact_page_Delete"] | undefined
};
	["EventsFields_contact_page_Create"]: {
		permission: boolean
};
	["EventsFields_contact_page_Read"]: {
		permission: boolean
};
	["EventsFields_contact_page_Update"]: {
		permission: boolean
};
	["EventsFields_contact_page_Delete"]: {
		permission: boolean
};
	["EventsFields_contact_email"]: {
		create?: ModelTypes["EventsFields_contact_email_Create"] | undefined,
	read?: ModelTypes["EventsFields_contact_email_Read"] | undefined,
	update?: ModelTypes["EventsFields_contact_email_Update"] | undefined,
	delete?: ModelTypes["EventsFields_contact_email_Delete"] | undefined
};
	["EventsFields_contact_email_Create"]: {
		permission: boolean
};
	["EventsFields_contact_email_Read"]: {
		permission: boolean
};
	["EventsFields_contact_email_Update"]: {
		permission: boolean
};
	["EventsFields_contact_email_Delete"]: {
		permission: boolean
};
	["EventsFields_contact_privacy"]: {
		create?: ModelTypes["EventsFields_contact_privacy_Create"] | undefined,
	read?: ModelTypes["EventsFields_contact_privacy_Read"] | undefined,
	update?: ModelTypes["EventsFields_contact_privacy_Update"] | undefined,
	delete?: ModelTypes["EventsFields_contact_privacy_Delete"] | undefined
};
	["EventsFields_contact_privacy_Create"]: {
		permission: boolean
};
	["EventsFields_contact_privacy_Read"]: {
		permission: boolean
};
	["EventsFields_contact_privacy_Update"]: {
		permission: boolean
};
	["EventsFields_contact_privacy_Delete"]: {
		permission: boolean
};
	["EventsFields_views"]: {
		create?: ModelTypes["EventsFields_views_Create"] | undefined,
	read?: ModelTypes["EventsFields_views_Read"] | undefined,
	update?: ModelTypes["EventsFields_views_Update"] | undefined,
	delete?: ModelTypes["EventsFields_views_Delete"] | undefined
};
	["EventsFields_views_Create"]: {
		permission: boolean
};
	["EventsFields_views_Read"]: {
		permission: boolean
};
	["EventsFields_views_Update"]: {
		permission: boolean
};
	["EventsFields_views_Delete"]: {
		permission: boolean
};
	["EventsFields_free"]: {
		create?: ModelTypes["EventsFields_free_Create"] | undefined,
	read?: ModelTypes["EventsFields_free_Read"] | undefined,
	update?: ModelTypes["EventsFields_free_Update"] | undefined,
	delete?: ModelTypes["EventsFields_free_Delete"] | undefined
};
	["EventsFields_free_Create"]: {
		permission: boolean
};
	["EventsFields_free_Read"]: {
		permission: boolean
};
	["EventsFields_free_Update"]: {
		permission: boolean
};
	["EventsFields_free_Delete"]: {
		permission: boolean
};
	["EventsFields_fees"]: {
		create?: ModelTypes["EventsFields_fees_Create"] | undefined,
	read?: ModelTypes["EventsFields_fees_Read"] | undefined,
	update?: ModelTypes["EventsFields_fees_Update"] | undefined,
	delete?: ModelTypes["EventsFields_fees_Delete"] | undefined,
	fields?: ModelTypes["EventsFields_fees_Fields"] | undefined
};
	["EventsFields_fees_Create"]: {
		permission: boolean
};
	["EventsFields_fees_Read"]: {
		permission: boolean
};
	["EventsFields_fees_Update"]: {
		permission: boolean
};
	["EventsFields_fees_Delete"]: {
		permission: boolean
};
	["EventsFields_fees_Fields"]: {
		fee?: ModelTypes["EventsFields_fees_fee"] | undefined,
	remark?: ModelTypes["EventsFields_fees_remark"] | undefined,
	id?: ModelTypes["EventsFields_fees_id"] | undefined
};
	["EventsFields_fees_fee"]: {
		create?: ModelTypes["EventsFields_fees_fee_Create"] | undefined,
	read?: ModelTypes["EventsFields_fees_fee_Read"] | undefined,
	update?: ModelTypes["EventsFields_fees_fee_Update"] | undefined,
	delete?: ModelTypes["EventsFields_fees_fee_Delete"] | undefined
};
	["EventsFields_fees_fee_Create"]: {
		permission: boolean
};
	["EventsFields_fees_fee_Read"]: {
		permission: boolean
};
	["EventsFields_fees_fee_Update"]: {
		permission: boolean
};
	["EventsFields_fees_fee_Delete"]: {
		permission: boolean
};
	["EventsFields_fees_remark"]: {
		create?: ModelTypes["EventsFields_fees_remark_Create"] | undefined,
	read?: ModelTypes["EventsFields_fees_remark_Read"] | undefined,
	update?: ModelTypes["EventsFields_fees_remark_Update"] | undefined,
	delete?: ModelTypes["EventsFields_fees_remark_Delete"] | undefined
};
	["EventsFields_fees_remark_Create"]: {
		permission: boolean
};
	["EventsFields_fees_remark_Read"]: {
		permission: boolean
};
	["EventsFields_fees_remark_Update"]: {
		permission: boolean
};
	["EventsFields_fees_remark_Delete"]: {
		permission: boolean
};
	["EventsFields_fees_id"]: {
		create?: ModelTypes["EventsFields_fees_id_Create"] | undefined,
	read?: ModelTypes["EventsFields_fees_id_Read"] | undefined,
	update?: ModelTypes["EventsFields_fees_id_Update"] | undefined,
	delete?: ModelTypes["EventsFields_fees_id_Delete"] | undefined
};
	["EventsFields_fees_id_Create"]: {
		permission: boolean
};
	["EventsFields_fees_id_Read"]: {
		permission: boolean
};
	["EventsFields_fees_id_Update"]: {
		permission: boolean
};
	["EventsFields_fees_id_Delete"]: {
		permission: boolean
};
	["EventsFields_homePageInternalClick"]: {
		create?: ModelTypes["EventsFields_homePageInternalClick_Create"] | undefined,
	read?: ModelTypes["EventsFields_homePageInternalClick_Read"] | undefined,
	update?: ModelTypes["EventsFields_homePageInternalClick_Update"] | undefined,
	delete?: ModelTypes["EventsFields_homePageInternalClick_Delete"] | undefined
};
	["EventsFields_homePageInternalClick_Create"]: {
		permission: boolean
};
	["EventsFields_homePageInternalClick_Read"]: {
		permission: boolean
};
	["EventsFields_homePageInternalClick_Update"]: {
		permission: boolean
};
	["EventsFields_homePageInternalClick_Delete"]: {
		permission: boolean
};
	["EventsFields_homePageImpression"]: {
		create?: ModelTypes["EventsFields_homePageImpression_Create"] | undefined,
	read?: ModelTypes["EventsFields_homePageImpression_Read"] | undefined,
	update?: ModelTypes["EventsFields_homePageImpression_Update"] | undefined,
	delete?: ModelTypes["EventsFields_homePageImpression_Delete"] | undefined
};
	["EventsFields_homePageImpression_Create"]: {
		permission: boolean
};
	["EventsFields_homePageImpression_Read"]: {
		permission: boolean
};
	["EventsFields_homePageImpression_Update"]: {
		permission: boolean
};
	["EventsFields_homePageImpression_Delete"]: {
		permission: boolean
};
	["EventsFields_eodClickRate"]: {
		create?: ModelTypes["EventsFields_eodClickRate_Create"] | undefined,
	read?: ModelTypes["EventsFields_eodClickRate_Read"] | undefined,
	update?: ModelTypes["EventsFields_eodClickRate_Update"] | undefined,
	delete?: ModelTypes["EventsFields_eodClickRate_Delete"] | undefined
};
	["EventsFields_eodClickRate_Create"]: {
		permission: boolean
};
	["EventsFields_eodClickRate_Read"]: {
		permission: boolean
};
	["EventsFields_eodClickRate_Update"]: {
		permission: boolean
};
	["EventsFields_eodClickRate_Delete"]: {
		permission: boolean
};
	["EventsFields_eodImpressionRate"]: {
		create?: ModelTypes["EventsFields_eodImpressionRate_Create"] | undefined,
	read?: ModelTypes["EventsFields_eodImpressionRate_Read"] | undefined,
	update?: ModelTypes["EventsFields_eodImpressionRate_Update"] | undefined,
	delete?: ModelTypes["EventsFields_eodImpressionRate_Delete"] | undefined
};
	["EventsFields_eodImpressionRate_Create"]: {
		permission: boolean
};
	["EventsFields_eodImpressionRate_Read"]: {
		permission: boolean
};
	["EventsFields_eodImpressionRate_Update"]: {
		permission: boolean
};
	["EventsFields_eodImpressionRate_Delete"]: {
		permission: boolean
};
	["EventsFields_top10ClickRateHomepage"]: {
		create?: ModelTypes["EventsFields_top10ClickRateHomepage_Create"] | undefined,
	read?: ModelTypes["EventsFields_top10ClickRateHomepage_Read"] | undefined,
	update?: ModelTypes["EventsFields_top10ClickRateHomepage_Update"] | undefined,
	delete?: ModelTypes["EventsFields_top10ClickRateHomepage_Delete"] | undefined
};
	["EventsFields_top10ClickRateHomepage_Create"]: {
		permission: boolean
};
	["EventsFields_top10ClickRateHomepage_Read"]: {
		permission: boolean
};
	["EventsFields_top10ClickRateHomepage_Update"]: {
		permission: boolean
};
	["EventsFields_top10ClickRateHomepage_Delete"]: {
		permission: boolean
};
	["EventsFields_top10ImpressionkRateHomepage"]: {
		create?: ModelTypes["EventsFields_top10ImpressionkRateHomepage_Create"] | undefined,
	read?: ModelTypes["EventsFields_top10ImpressionkRateHomepage_Read"] | undefined,
	update?: ModelTypes["EventsFields_top10ImpressionkRateHomepage_Update"] | undefined,
	delete?: ModelTypes["EventsFields_top10ImpressionkRateHomepage_Delete"] | undefined
};
	["EventsFields_top10ImpressionkRateHomepage_Create"]: {
		permission: boolean
};
	["EventsFields_top10ImpressionkRateHomepage_Read"]: {
		permission: boolean
};
	["EventsFields_top10ImpressionkRateHomepage_Update"]: {
		permission: boolean
};
	["EventsFields_top10ImpressionkRateHomepage_Delete"]: {
		permission: boolean
};
	["EventsFields_otherEventRankingClickRateHomepage"]: {
		create?: ModelTypes["EventsFields_otherEventRankingClickRateHomepage_Create"] | undefined,
	read?: ModelTypes["EventsFields_otherEventRankingClickRateHomepage_Read"] | undefined,
	update?: ModelTypes["EventsFields_otherEventRankingClickRateHomepage_Update"] | undefined,
	delete?: ModelTypes["EventsFields_otherEventRankingClickRateHomepage_Delete"] | undefined
};
	["EventsFields_otherEventRankingClickRateHomepage_Create"]: {
		permission: boolean
};
	["EventsFields_otherEventRankingClickRateHomepage_Read"]: {
		permission: boolean
};
	["EventsFields_otherEventRankingClickRateHomepage_Update"]: {
		permission: boolean
};
	["EventsFields_otherEventRankingClickRateHomepage_Delete"]: {
		permission: boolean
};
	["EventsFields_otherEventRankingImpressionHomePage"]: {
		create?: ModelTypes["EventsFields_otherEventRankingImpressionHomePage_Create"] | undefined,
	read?: ModelTypes["EventsFields_otherEventRankingImpressionHomePage_Read"] | undefined,
	update?: ModelTypes["EventsFields_otherEventRankingImpressionHomePage_Update"] | undefined,
	delete?: ModelTypes["EventsFields_otherEventRankingImpressionHomePage_Delete"] | undefined
};
	["EventsFields_otherEventRankingImpressionHomePage_Create"]: {
		permission: boolean
};
	["EventsFields_otherEventRankingImpressionHomePage_Read"]: {
		permission: boolean
};
	["EventsFields_otherEventRankingImpressionHomePage_Update"]: {
		permission: boolean
};
	["EventsFields_otherEventRankingImpressionHomePage_Delete"]: {
		permission: boolean
};
	["EventsFields_userPreferenceClickRate"]: {
		create?: ModelTypes["EventsFields_userPreferenceClickRate_Create"] | undefined,
	read?: ModelTypes["EventsFields_userPreferenceClickRate_Read"] | undefined,
	update?: ModelTypes["EventsFields_userPreferenceClickRate_Update"] | undefined,
	delete?: ModelTypes["EventsFields_userPreferenceClickRate_Delete"] | undefined
};
	["EventsFields_userPreferenceClickRate_Create"]: {
		permission: boolean
};
	["EventsFields_userPreferenceClickRate_Read"]: {
		permission: boolean
};
	["EventsFields_userPreferenceClickRate_Update"]: {
		permission: boolean
};
	["EventsFields_userPreferenceClickRate_Delete"]: {
		permission: boolean
};
	["EventsFields_userPreferenceImpression"]: {
		create?: ModelTypes["EventsFields_userPreferenceImpression_Create"] | undefined,
	read?: ModelTypes["EventsFields_userPreferenceImpression_Read"] | undefined,
	update?: ModelTypes["EventsFields_userPreferenceImpression_Update"] | undefined,
	delete?: ModelTypes["EventsFields_userPreferenceImpression_Delete"] | undefined
};
	["EventsFields_userPreferenceImpression_Create"]: {
		permission: boolean
};
	["EventsFields_userPreferenceImpression_Read"]: {
		permission: boolean
};
	["EventsFields_userPreferenceImpression_Update"]: {
		permission: boolean
};
	["EventsFields_userPreferenceImpression_Delete"]: {
		permission: boolean
};
	["EventsFields_eventSearchInternalClick"]: {
		create?: ModelTypes["EventsFields_eventSearchInternalClick_Create"] | undefined,
	read?: ModelTypes["EventsFields_eventSearchInternalClick_Read"] | undefined,
	update?: ModelTypes["EventsFields_eventSearchInternalClick_Update"] | undefined,
	delete?: ModelTypes["EventsFields_eventSearchInternalClick_Delete"] | undefined
};
	["EventsFields_eventSearchInternalClick_Create"]: {
		permission: boolean
};
	["EventsFields_eventSearchInternalClick_Read"]: {
		permission: boolean
};
	["EventsFields_eventSearchInternalClick_Update"]: {
		permission: boolean
};
	["EventsFields_eventSearchInternalClick_Delete"]: {
		permission: boolean
};
	["EventsFields_eventListImpression"]: {
		create?: ModelTypes["EventsFields_eventListImpression_Create"] | undefined,
	read?: ModelTypes["EventsFields_eventListImpression_Read"] | undefined,
	update?: ModelTypes["EventsFields_eventListImpression_Update"] | undefined,
	delete?: ModelTypes["EventsFields_eventListImpression_Delete"] | undefined
};
	["EventsFields_eventListImpression_Create"]: {
		permission: boolean
};
	["EventsFields_eventListImpression_Read"]: {
		permission: boolean
};
	["EventsFields_eventListImpression_Update"]: {
		permission: boolean
};
	["EventsFields_eventListImpression_Delete"]: {
		permission: boolean
};
	["EventsFields_latestEventInternalClick"]: {
		create?: ModelTypes["EventsFields_latestEventInternalClick_Create"] | undefined,
	read?: ModelTypes["EventsFields_latestEventInternalClick_Read"] | undefined,
	update?: ModelTypes["EventsFields_latestEventInternalClick_Update"] | undefined,
	delete?: ModelTypes["EventsFields_latestEventInternalClick_Delete"] | undefined
};
	["EventsFields_latestEventInternalClick_Create"]: {
		permission: boolean
};
	["EventsFields_latestEventInternalClick_Read"]: {
		permission: boolean
};
	["EventsFields_latestEventInternalClick_Update"]: {
		permission: boolean
};
	["EventsFields_latestEventInternalClick_Delete"]: {
		permission: boolean
};
	["EventsFields_latestEventImpression"]: {
		create?: ModelTypes["EventsFields_latestEventImpression_Create"] | undefined,
	read?: ModelTypes["EventsFields_latestEventImpression_Read"] | undefined,
	update?: ModelTypes["EventsFields_latestEventImpression_Update"] | undefined,
	delete?: ModelTypes["EventsFields_latestEventImpression_Delete"] | undefined
};
	["EventsFields_latestEventImpression_Create"]: {
		permission: boolean
};
	["EventsFields_latestEventImpression_Read"]: {
		permission: boolean
};
	["EventsFields_latestEventImpression_Update"]: {
		permission: boolean
};
	["EventsFields_latestEventImpression_Delete"]: {
		permission: boolean
};
	["EventsFields_topTenEventRankingInternalClick"]: {
		create?: ModelTypes["EventsFields_topTenEventRankingInternalClick_Create"] | undefined,
	read?: ModelTypes["EventsFields_topTenEventRankingInternalClick_Read"] | undefined,
	update?: ModelTypes["EventsFields_topTenEventRankingInternalClick_Update"] | undefined,
	delete?: ModelTypes["EventsFields_topTenEventRankingInternalClick_Delete"] | undefined
};
	["EventsFields_topTenEventRankingInternalClick_Create"]: {
		permission: boolean
};
	["EventsFields_topTenEventRankingInternalClick_Read"]: {
		permission: boolean
};
	["EventsFields_topTenEventRankingInternalClick_Update"]: {
		permission: boolean
};
	["EventsFields_topTenEventRankingInternalClick_Delete"]: {
		permission: boolean
};
	["EventsFields_topTenImpression"]: {
		create?: ModelTypes["EventsFields_topTenImpression_Create"] | undefined,
	read?: ModelTypes["EventsFields_topTenImpression_Read"] | undefined,
	update?: ModelTypes["EventsFields_topTenImpression_Update"] | undefined,
	delete?: ModelTypes["EventsFields_topTenImpression_Delete"] | undefined
};
	["EventsFields_topTenImpression_Create"]: {
		permission: boolean
};
	["EventsFields_topTenImpression_Read"]: {
		permission: boolean
};
	["EventsFields_topTenImpression_Update"]: {
		permission: boolean
};
	["EventsFields_topTenImpression_Delete"]: {
		permission: boolean
};
	["EventsFields_editorChoiceInternalClick"]: {
		create?: ModelTypes["EventsFields_editorChoiceInternalClick_Create"] | undefined,
	read?: ModelTypes["EventsFields_editorChoiceInternalClick_Read"] | undefined,
	update?: ModelTypes["EventsFields_editorChoiceInternalClick_Update"] | undefined,
	delete?: ModelTypes["EventsFields_editorChoiceInternalClick_Delete"] | undefined
};
	["EventsFields_editorChoiceInternalClick_Create"]: {
		permission: boolean
};
	["EventsFields_editorChoiceInternalClick_Read"]: {
		permission: boolean
};
	["EventsFields_editorChoiceInternalClick_Update"]: {
		permission: boolean
};
	["EventsFields_editorChoiceInternalClick_Delete"]: {
		permission: boolean
};
	["EventsFields_editorChoiceImpression"]: {
		create?: ModelTypes["EventsFields_editorChoiceImpression_Create"] | undefined,
	read?: ModelTypes["EventsFields_editorChoiceImpression_Read"] | undefined,
	update?: ModelTypes["EventsFields_editorChoiceImpression_Update"] | undefined,
	delete?: ModelTypes["EventsFields_editorChoiceImpression_Delete"] | undefined
};
	["EventsFields_editorChoiceImpression_Create"]: {
		permission: boolean
};
	["EventsFields_editorChoiceImpression_Read"]: {
		permission: boolean
};
	["EventsFields_editorChoiceImpression_Update"]: {
		permission: boolean
};
	["EventsFields_editorChoiceImpression_Delete"]: {
		permission: boolean
};
	["EventsFields_otherEventRankingInternalClick"]: {
		create?: ModelTypes["EventsFields_otherEventRankingInternalClick_Create"] | undefined,
	read?: ModelTypes["EventsFields_otherEventRankingInternalClick_Read"] | undefined,
	update?: ModelTypes["EventsFields_otherEventRankingInternalClick_Update"] | undefined,
	delete?: ModelTypes["EventsFields_otherEventRankingInternalClick_Delete"] | undefined
};
	["EventsFields_otherEventRankingInternalClick_Create"]: {
		permission: boolean
};
	["EventsFields_otherEventRankingInternalClick_Read"]: {
		permission: boolean
};
	["EventsFields_otherEventRankingInternalClick_Update"]: {
		permission: boolean
};
	["EventsFields_otherEventRankingInternalClick_Delete"]: {
		permission: boolean
};
	["EventsFields_otherEventRankingImpression"]: {
		create?: ModelTypes["EventsFields_otherEventRankingImpression_Create"] | undefined,
	read?: ModelTypes["EventsFields_otherEventRankingImpression_Read"] | undefined,
	update?: ModelTypes["EventsFields_otherEventRankingImpression_Update"] | undefined,
	delete?: ModelTypes["EventsFields_otherEventRankingImpression_Delete"] | undefined
};
	["EventsFields_otherEventRankingImpression_Create"]: {
		permission: boolean
};
	["EventsFields_otherEventRankingImpression_Read"]: {
		permission: boolean
};
	["EventsFields_otherEventRankingImpression_Update"]: {
		permission: boolean
};
	["EventsFields_otherEventRankingImpression_Delete"]: {
		permission: boolean
};
	["EventsFields_bannerInternalClick"]: {
		create?: ModelTypes["EventsFields_bannerInternalClick_Create"] | undefined,
	read?: ModelTypes["EventsFields_bannerInternalClick_Read"] | undefined,
	update?: ModelTypes["EventsFields_bannerInternalClick_Update"] | undefined,
	delete?: ModelTypes["EventsFields_bannerInternalClick_Delete"] | undefined
};
	["EventsFields_bannerInternalClick_Create"]: {
		permission: boolean
};
	["EventsFields_bannerInternalClick_Read"]: {
		permission: boolean
};
	["EventsFields_bannerInternalClick_Update"]: {
		permission: boolean
};
	["EventsFields_bannerInternalClick_Delete"]: {
		permission: boolean
};
	["EventsFields_miscImpression"]: {
		create?: ModelTypes["EventsFields_miscImpression_Create"] | undefined,
	read?: ModelTypes["EventsFields_miscImpression_Read"] | undefined,
	update?: ModelTypes["EventsFields_miscImpression_Update"] | undefined,
	delete?: ModelTypes["EventsFields_miscImpression_Delete"] | undefined
};
	["EventsFields_miscImpression_Create"]: {
		permission: boolean
};
	["EventsFields_miscImpression_Read"]: {
		permission: boolean
};
	["EventsFields_miscImpression_Update"]: {
		permission: boolean
};
	["EventsFields_miscImpression_Delete"]: {
		permission: boolean
};
	["EventsFields_miscInternalClick"]: {
		create?: ModelTypes["EventsFields_miscInternalClick_Create"] | undefined,
	read?: ModelTypes["EventsFields_miscInternalClick_Read"] | undefined,
	update?: ModelTypes["EventsFields_miscInternalClick_Update"] | undefined,
	delete?: ModelTypes["EventsFields_miscInternalClick_Delete"] | undefined
};
	["EventsFields_miscInternalClick_Create"]: {
		permission: boolean
};
	["EventsFields_miscInternalClick_Read"]: {
		permission: boolean
};
	["EventsFields_miscInternalClick_Update"]: {
		permission: boolean
};
	["EventsFields_miscInternalClick_Delete"]: {
		permission: boolean
};
	["EventsFields_totalImpression"]: {
		create?: ModelTypes["EventsFields_totalImpression_Create"] | undefined,
	read?: ModelTypes["EventsFields_totalImpression_Read"] | undefined,
	update?: ModelTypes["EventsFields_totalImpression_Update"] | undefined,
	delete?: ModelTypes["EventsFields_totalImpression_Delete"] | undefined
};
	["EventsFields_totalImpression_Create"]: {
		permission: boolean
};
	["EventsFields_totalImpression_Read"]: {
		permission: boolean
};
	["EventsFields_totalImpression_Update"]: {
		permission: boolean
};
	["EventsFields_totalImpression_Delete"]: {
		permission: boolean
};
	["EventsFields_internalClick"]: {
		create?: ModelTypes["EventsFields_internalClick_Create"] | undefined,
	read?: ModelTypes["EventsFields_internalClick_Read"] | undefined,
	update?: ModelTypes["EventsFields_internalClick_Update"] | undefined,
	delete?: ModelTypes["EventsFields_internalClick_Delete"] | undefined
};
	["EventsFields_internalClick_Create"]: {
		permission: boolean
};
	["EventsFields_internalClick_Read"]: {
		permission: boolean
};
	["EventsFields_internalClick_Update"]: {
		permission: boolean
};
	["EventsFields_internalClick_Delete"]: {
		permission: boolean
};
	["EventsFields_outboundClick"]: {
		create?: ModelTypes["EventsFields_outboundClick_Create"] | undefined,
	read?: ModelTypes["EventsFields_outboundClick_Read"] | undefined,
	update?: ModelTypes["EventsFields_outboundClick_Update"] | undefined,
	delete?: ModelTypes["EventsFields_outboundClick_Delete"] | undefined
};
	["EventsFields_outboundClick_Create"]: {
		permission: boolean
};
	["EventsFields_outboundClick_Read"]: {
		permission: boolean
};
	["EventsFields_outboundClick_Update"]: {
		permission: boolean
};
	["EventsFields_outboundClick_Delete"]: {
		permission: boolean
};
	["EventsFields_externalClick"]: {
		create?: ModelTypes["EventsFields_externalClick_Create"] | undefined,
	read?: ModelTypes["EventsFields_externalClick_Read"] | undefined,
	update?: ModelTypes["EventsFields_externalClick_Update"] | undefined,
	delete?: ModelTypes["EventsFields_externalClick_Delete"] | undefined
};
	["EventsFields_externalClick_Create"]: {
		permission: boolean
};
	["EventsFields_externalClick_Read"]: {
		permission: boolean
};
	["EventsFields_externalClick_Update"]: {
		permission: boolean
};
	["EventsFields_externalClick_Delete"]: {
		permission: boolean
};
	["EventsFields__viewsCountByMonth"]: {
		create?: ModelTypes["EventsFields__viewsCountByMonth_Create"] | undefined,
	read?: ModelTypes["EventsFields__viewsCountByMonth_Read"] | undefined,
	update?: ModelTypes["EventsFields__viewsCountByMonth_Update"] | undefined,
	delete?: ModelTypes["EventsFields__viewsCountByMonth_Delete"] | undefined
};
	["EventsFields__viewsCountByMonth_Create"]: {
		permission: boolean
};
	["EventsFields__viewsCountByMonth_Read"]: {
		permission: boolean
};
	["EventsFields__viewsCountByMonth_Update"]: {
		permission: boolean
};
	["EventsFields__viewsCountByMonth_Delete"]: {
		permission: boolean
};
	["EventsFields_products"]: {
		create?: ModelTypes["EventsFields_products_Create"] | undefined,
	read?: ModelTypes["EventsFields_products_Read"] | undefined,
	update?: ModelTypes["EventsFields_products_Update"] | undefined,
	delete?: ModelTypes["EventsFields_products_Delete"] | undefined,
	fields?: ModelTypes["EventsFields_products_Fields"] | undefined
};
	["EventsFields_products_Create"]: {
		permission: boolean
};
	["EventsFields_products_Read"]: {
		permission: boolean
};
	["EventsFields_products_Update"]: {
		permission: boolean
};
	["EventsFields_products_Delete"]: {
		permission: boolean
};
	["EventsFields_products_Fields"]: {
		startDateTime?: ModelTypes["EventsFields_products_startDateTime"] | undefined,
	endDateTime?: ModelTypes["EventsFields_products_endDateTime"] | undefined,
	fullDay?: ModelTypes["EventsFields_products_fullDay"] | undefined,
	linkedProduct?: ModelTypes["EventsFields_products_linkedProduct"] | undefined,
	id?: ModelTypes["EventsFields_products_id"] | undefined
};
	["EventsFields_products_startDateTime"]: {
		create?: ModelTypes["EventsFields_products_startDateTime_Create"] | undefined,
	read?: ModelTypes["EventsFields_products_startDateTime_Read"] | undefined,
	update?: ModelTypes["EventsFields_products_startDateTime_Update"] | undefined,
	delete?: ModelTypes["EventsFields_products_startDateTime_Delete"] | undefined
};
	["EventsFields_products_startDateTime_Create"]: {
		permission: boolean
};
	["EventsFields_products_startDateTime_Read"]: {
		permission: boolean
};
	["EventsFields_products_startDateTime_Update"]: {
		permission: boolean
};
	["EventsFields_products_startDateTime_Delete"]: {
		permission: boolean
};
	["EventsFields_products_endDateTime"]: {
		create?: ModelTypes["EventsFields_products_endDateTime_Create"] | undefined,
	read?: ModelTypes["EventsFields_products_endDateTime_Read"] | undefined,
	update?: ModelTypes["EventsFields_products_endDateTime_Update"] | undefined,
	delete?: ModelTypes["EventsFields_products_endDateTime_Delete"] | undefined
};
	["EventsFields_products_endDateTime_Create"]: {
		permission: boolean
};
	["EventsFields_products_endDateTime_Read"]: {
		permission: boolean
};
	["EventsFields_products_endDateTime_Update"]: {
		permission: boolean
};
	["EventsFields_products_endDateTime_Delete"]: {
		permission: boolean
};
	["EventsFields_products_fullDay"]: {
		create?: ModelTypes["EventsFields_products_fullDay_Create"] | undefined,
	read?: ModelTypes["EventsFields_products_fullDay_Read"] | undefined,
	update?: ModelTypes["EventsFields_products_fullDay_Update"] | undefined,
	delete?: ModelTypes["EventsFields_products_fullDay_Delete"] | undefined
};
	["EventsFields_products_fullDay_Create"]: {
		permission: boolean
};
	["EventsFields_products_fullDay_Read"]: {
		permission: boolean
};
	["EventsFields_products_fullDay_Update"]: {
		permission: boolean
};
	["EventsFields_products_fullDay_Delete"]: {
		permission: boolean
};
	["EventsFields_products_linkedProduct"]: {
		create?: ModelTypes["EventsFields_products_linkedProduct_Create"] | undefined,
	read?: ModelTypes["EventsFields_products_linkedProduct_Read"] | undefined,
	update?: ModelTypes["EventsFields_products_linkedProduct_Update"] | undefined,
	delete?: ModelTypes["EventsFields_products_linkedProduct_Delete"] | undefined
};
	["EventsFields_products_linkedProduct_Create"]: {
		permission: boolean
};
	["EventsFields_products_linkedProduct_Read"]: {
		permission: boolean
};
	["EventsFields_products_linkedProduct_Update"]: {
		permission: boolean
};
	["EventsFields_products_linkedProduct_Delete"]: {
		permission: boolean
};
	["EventsFields_products_id"]: {
		create?: ModelTypes["EventsFields_products_id_Create"] | undefined,
	read?: ModelTypes["EventsFields_products_id_Read"] | undefined,
	update?: ModelTypes["EventsFields_products_id_Update"] | undefined,
	delete?: ModelTypes["EventsFields_products_id_Delete"] | undefined
};
	["EventsFields_products_id_Create"]: {
		permission: boolean
};
	["EventsFields_products_id_Read"]: {
		permission: boolean
};
	["EventsFields_products_id_Update"]: {
		permission: boolean
};
	["EventsFields_products_id_Delete"]: {
		permission: boolean
};
	["EventsFields_legacyGallery"]: {
		create?: ModelTypes["EventsFields_legacyGallery_Create"] | undefined,
	read?: ModelTypes["EventsFields_legacyGallery_Read"] | undefined,
	update?: ModelTypes["EventsFields_legacyGallery_Update"] | undefined,
	delete?: ModelTypes["EventsFields_legacyGallery_Delete"] | undefined
};
	["EventsFields_legacyGallery_Create"]: {
		permission: boolean
};
	["EventsFields_legacyGallery_Read"]: {
		permission: boolean
};
	["EventsFields_legacyGallery_Update"]: {
		permission: boolean
};
	["EventsFields_legacyGallery_Delete"]: {
		permission: boolean
};
	["EventsFields_earliestStartDate"]: {
		create?: ModelTypes["EventsFields_earliestStartDate_Create"] | undefined,
	read?: ModelTypes["EventsFields_earliestStartDate_Read"] | undefined,
	update?: ModelTypes["EventsFields_earliestStartDate_Update"] | undefined,
	delete?: ModelTypes["EventsFields_earliestStartDate_Delete"] | undefined
};
	["EventsFields_earliestStartDate_Create"]: {
		permission: boolean
};
	["EventsFields_earliestStartDate_Read"]: {
		permission: boolean
};
	["EventsFields_earliestStartDate_Update"]: {
		permission: boolean
};
	["EventsFields_earliestStartDate_Delete"]: {
		permission: boolean
};
	["EventsFields_latestEndDate"]: {
		create?: ModelTypes["EventsFields_latestEndDate_Create"] | undefined,
	read?: ModelTypes["EventsFields_latestEndDate_Read"] | undefined,
	update?: ModelTypes["EventsFields_latestEndDate_Update"] | undefined,
	delete?: ModelTypes["EventsFields_latestEndDate_Delete"] | undefined
};
	["EventsFields_latestEndDate_Create"]: {
		permission: boolean
};
	["EventsFields_latestEndDate_Read"]: {
		permission: boolean
};
	["EventsFields_latestEndDate_Update"]: {
		permission: boolean
};
	["EventsFields_latestEndDate_Delete"]: {
		permission: boolean
};
	["EventsFields_latestStartDateTime"]: {
		create?: ModelTypes["EventsFields_latestStartDateTime_Create"] | undefined,
	read?: ModelTypes["EventsFields_latestStartDateTime_Read"] | undefined,
	update?: ModelTypes["EventsFields_latestStartDateTime_Update"] | undefined,
	delete?: ModelTypes["EventsFields_latestStartDateTime_Delete"] | undefined
};
	["EventsFields_latestStartDateTime_Create"]: {
		permission: boolean
};
	["EventsFields_latestStartDateTime_Read"]: {
		permission: boolean
};
	["EventsFields_latestStartDateTime_Update"]: {
		permission: boolean
};
	["EventsFields_latestStartDateTime_Delete"]: {
		permission: boolean
};
	["EventsFields_hasTicketing"]: {
		create?: ModelTypes["EventsFields_hasTicketing_Create"] | undefined,
	read?: ModelTypes["EventsFields_hasTicketing_Read"] | undefined,
	update?: ModelTypes["EventsFields_hasTicketing_Update"] | undefined,
	delete?: ModelTypes["EventsFields_hasTicketing_Delete"] | undefined
};
	["EventsFields_hasTicketing_Create"]: {
		permission: boolean
};
	["EventsFields_hasTicketing_Read"]: {
		permission: boolean
};
	["EventsFields_hasTicketing_Update"]: {
		permission: boolean
};
	["EventsFields_hasTicketing_Delete"]: {
		permission: boolean
};
	["EventsFields_hasProducts"]: {
		create?: ModelTypes["EventsFields_hasProducts_Create"] | undefined,
	read?: ModelTypes["EventsFields_hasProducts_Read"] | undefined,
	update?: ModelTypes["EventsFields_hasProducts_Update"] | undefined,
	delete?: ModelTypes["EventsFields_hasProducts_Delete"] | undefined
};
	["EventsFields_hasProducts_Create"]: {
		permission: boolean
};
	["EventsFields_hasProducts_Read"]: {
		permission: boolean
};
	["EventsFields_hasProducts_Update"]: {
		permission: boolean
};
	["EventsFields_hasProducts_Delete"]: {
		permission: boolean
};
	["EventsFields_updatedAt"]: {
		create?: ModelTypes["EventsFields_updatedAt_Create"] | undefined,
	read?: ModelTypes["EventsFields_updatedAt_Read"] | undefined,
	update?: ModelTypes["EventsFields_updatedAt_Update"] | undefined,
	delete?: ModelTypes["EventsFields_updatedAt_Delete"] | undefined
};
	["EventsFields_updatedAt_Create"]: {
		permission: boolean
};
	["EventsFields_updatedAt_Read"]: {
		permission: boolean
};
	["EventsFields_updatedAt_Update"]: {
		permission: boolean
};
	["EventsFields_updatedAt_Delete"]: {
		permission: boolean
};
	["EventsFields_createdAt"]: {
		create?: ModelTypes["EventsFields_createdAt_Create"] | undefined,
	read?: ModelTypes["EventsFields_createdAt_Read"] | undefined,
	update?: ModelTypes["EventsFields_createdAt_Update"] | undefined,
	delete?: ModelTypes["EventsFields_createdAt_Delete"] | undefined
};
	["EventsFields_createdAt_Create"]: {
		permission: boolean
};
	["EventsFields_createdAt_Read"]: {
		permission: boolean
};
	["EventsFields_createdAt_Update"]: {
		permission: boolean
};
	["EventsFields_createdAt_Delete"]: {
		permission: boolean
};
	["EventsCreateAccess"]: {
		permission: boolean,
	where?: ModelTypes["JSONObject"] | undefined
};
	["EventsReadAccess"]: {
		permission: boolean,
	where?: ModelTypes["JSONObject"] | undefined
};
	["EventsUpdateAccess"]: {
		permission: boolean,
	where?: ModelTypes["JSONObject"] | undefined
};
	["EventsDeleteAccess"]: {
		permission: boolean,
	where?: ModelTypes["JSONObject"] | undefined
};
	["locationsAccess"]: {
		fields?: ModelTypes["LocationsFields"] | undefined,
	create?: ModelTypes["LocationsCreateAccess"] | undefined,
	read?: ModelTypes["LocationsReadAccess"] | undefined,
	update?: ModelTypes["LocationsUpdateAccess"] | undefined,
	delete?: ModelTypes["LocationsDeleteAccess"] | undefined
};
	["LocationsFields"]: {
		region?: ModelTypes["LocationsFields_region"] | undefined,
	creator?: ModelTypes["LocationsFields_creator"] | undefined,
	permalink?: ModelTypes["LocationsFields_permalink"] | undefined,
	status?: ModelTypes["LocationsFields_status"] | undefined,
	level?: ModelTypes["LocationsFields_level"] | undefined,
	parent?: ModelTypes["LocationsFields_parent"] | undefined,
	name?: ModelTypes["LocationsFields_name"] | undefined,
	thumbnail?: ModelTypes["LocationsFields_thumbnail"] | undefined,
	banner?: ModelTypes["LocationsFields_banner"] | undefined,
	type?: ModelTypes["LocationsFields_type"] | undefined,
	category?: ModelTypes["LocationsFields_category"] | undefined,
	district?: ModelTypes["LocationsFields_district"] | undefined,
	coordinate?: ModelTypes["LocationsFields_coordinate"] | undefined,
	linkAddress?: ModelTypes["LocationsFields_linkAddress"] | undefined,
	address?: ModelTypes["LocationsFields_address"] | undefined,
	Details?: ModelTypes["LocationsFields_Details"] | undefined,
	content?: ModelTypes["LocationsFields_content"] | undefined,
	legacyContent?: ModelTypes["LocationsFields_legacyContent"] | undefined,
	showLegacyContent?: ModelTypes["LocationsFields_showLegacyContent"] | undefined,
	criteria?: ModelTypes["LocationsFields_criteria"] | undefined,
	sections?: ModelTypes["LocationsFields_sections"] | undefined,
	_title?: ModelTypes["LocationsFields__title"] | undefined,
	legacyGallery?: ModelTypes["LocationsFields_legacyGallery"] | undefined,
	updatedAt?: ModelTypes["LocationsFields_updatedAt"] | undefined,
	createdAt?: ModelTypes["LocationsFields_createdAt"] | undefined
};
	["LocationsFields_region"]: {
		create?: ModelTypes["LocationsFields_region_Create"] | undefined,
	read?: ModelTypes["LocationsFields_region_Read"] | undefined,
	update?: ModelTypes["LocationsFields_region_Update"] | undefined,
	delete?: ModelTypes["LocationsFields_region_Delete"] | undefined
};
	["LocationsFields_region_Create"]: {
		permission: boolean
};
	["LocationsFields_region_Read"]: {
		permission: boolean
};
	["LocationsFields_region_Update"]: {
		permission: boolean
};
	["LocationsFields_region_Delete"]: {
		permission: boolean
};
	["LocationsFields_creator"]: {
		create?: ModelTypes["LocationsFields_creator_Create"] | undefined,
	read?: ModelTypes["LocationsFields_creator_Read"] | undefined,
	update?: ModelTypes["LocationsFields_creator_Update"] | undefined,
	delete?: ModelTypes["LocationsFields_creator_Delete"] | undefined
};
	["LocationsFields_creator_Create"]: {
		permission: boolean
};
	["LocationsFields_creator_Read"]: {
		permission: boolean
};
	["LocationsFields_creator_Update"]: {
		permission: boolean
};
	["LocationsFields_creator_Delete"]: {
		permission: boolean
};
	["LocationsFields_permalink"]: {
		create?: ModelTypes["LocationsFields_permalink_Create"] | undefined,
	read?: ModelTypes["LocationsFields_permalink_Read"] | undefined,
	update?: ModelTypes["LocationsFields_permalink_Update"] | undefined,
	delete?: ModelTypes["LocationsFields_permalink_Delete"] | undefined
};
	["LocationsFields_permalink_Create"]: {
		permission: boolean
};
	["LocationsFields_permalink_Read"]: {
		permission: boolean
};
	["LocationsFields_permalink_Update"]: {
		permission: boolean
};
	["LocationsFields_permalink_Delete"]: {
		permission: boolean
};
	["LocationsFields_status"]: {
		create?: ModelTypes["LocationsFields_status_Create"] | undefined,
	read?: ModelTypes["LocationsFields_status_Read"] | undefined,
	update?: ModelTypes["LocationsFields_status_Update"] | undefined,
	delete?: ModelTypes["LocationsFields_status_Delete"] | undefined
};
	["LocationsFields_status_Create"]: {
		permission: boolean
};
	["LocationsFields_status_Read"]: {
		permission: boolean
};
	["LocationsFields_status_Update"]: {
		permission: boolean
};
	["LocationsFields_status_Delete"]: {
		permission: boolean
};
	["LocationsFields_level"]: {
		create?: ModelTypes["LocationsFields_level_Create"] | undefined,
	read?: ModelTypes["LocationsFields_level_Read"] | undefined,
	update?: ModelTypes["LocationsFields_level_Update"] | undefined,
	delete?: ModelTypes["LocationsFields_level_Delete"] | undefined
};
	["LocationsFields_level_Create"]: {
		permission: boolean
};
	["LocationsFields_level_Read"]: {
		permission: boolean
};
	["LocationsFields_level_Update"]: {
		permission: boolean
};
	["LocationsFields_level_Delete"]: {
		permission: boolean
};
	["LocationsFields_parent"]: {
		create?: ModelTypes["LocationsFields_parent_Create"] | undefined,
	read?: ModelTypes["LocationsFields_parent_Read"] | undefined,
	update?: ModelTypes["LocationsFields_parent_Update"] | undefined,
	delete?: ModelTypes["LocationsFields_parent_Delete"] | undefined
};
	["LocationsFields_parent_Create"]: {
		permission: boolean
};
	["LocationsFields_parent_Read"]: {
		permission: boolean
};
	["LocationsFields_parent_Update"]: {
		permission: boolean
};
	["LocationsFields_parent_Delete"]: {
		permission: boolean
};
	["LocationsFields_name"]: {
		create?: ModelTypes["LocationsFields_name_Create"] | undefined,
	read?: ModelTypes["LocationsFields_name_Read"] | undefined,
	update?: ModelTypes["LocationsFields_name_Update"] | undefined,
	delete?: ModelTypes["LocationsFields_name_Delete"] | undefined
};
	["LocationsFields_name_Create"]: {
		permission: boolean
};
	["LocationsFields_name_Read"]: {
		permission: boolean
};
	["LocationsFields_name_Update"]: {
		permission: boolean
};
	["LocationsFields_name_Delete"]: {
		permission: boolean
};
	["LocationsFields_thumbnail"]: {
		create?: ModelTypes["LocationsFields_thumbnail_Create"] | undefined,
	read?: ModelTypes["LocationsFields_thumbnail_Read"] | undefined,
	update?: ModelTypes["LocationsFields_thumbnail_Update"] | undefined,
	delete?: ModelTypes["LocationsFields_thumbnail_Delete"] | undefined
};
	["LocationsFields_thumbnail_Create"]: {
		permission: boolean
};
	["LocationsFields_thumbnail_Read"]: {
		permission: boolean
};
	["LocationsFields_thumbnail_Update"]: {
		permission: boolean
};
	["LocationsFields_thumbnail_Delete"]: {
		permission: boolean
};
	["LocationsFields_banner"]: {
		create?: ModelTypes["LocationsFields_banner_Create"] | undefined,
	read?: ModelTypes["LocationsFields_banner_Read"] | undefined,
	update?: ModelTypes["LocationsFields_banner_Update"] | undefined,
	delete?: ModelTypes["LocationsFields_banner_Delete"] | undefined
};
	["LocationsFields_banner_Create"]: {
		permission: boolean
};
	["LocationsFields_banner_Read"]: {
		permission: boolean
};
	["LocationsFields_banner_Update"]: {
		permission: boolean
};
	["LocationsFields_banner_Delete"]: {
		permission: boolean
};
	["LocationsFields_type"]: {
		create?: ModelTypes["LocationsFields_type_Create"] | undefined,
	read?: ModelTypes["LocationsFields_type_Read"] | undefined,
	update?: ModelTypes["LocationsFields_type_Update"] | undefined,
	delete?: ModelTypes["LocationsFields_type_Delete"] | undefined
};
	["LocationsFields_type_Create"]: {
		permission: boolean
};
	["LocationsFields_type_Read"]: {
		permission: boolean
};
	["LocationsFields_type_Update"]: {
		permission: boolean
};
	["LocationsFields_type_Delete"]: {
		permission: boolean
};
	["LocationsFields_category"]: {
		create?: ModelTypes["LocationsFields_category_Create"] | undefined,
	read?: ModelTypes["LocationsFields_category_Read"] | undefined,
	update?: ModelTypes["LocationsFields_category_Update"] | undefined,
	delete?: ModelTypes["LocationsFields_category_Delete"] | undefined
};
	["LocationsFields_category_Create"]: {
		permission: boolean
};
	["LocationsFields_category_Read"]: {
		permission: boolean
};
	["LocationsFields_category_Update"]: {
		permission: boolean
};
	["LocationsFields_category_Delete"]: {
		permission: boolean
};
	["LocationsFields_district"]: {
		create?: ModelTypes["LocationsFields_district_Create"] | undefined,
	read?: ModelTypes["LocationsFields_district_Read"] | undefined,
	update?: ModelTypes["LocationsFields_district_Update"] | undefined,
	delete?: ModelTypes["LocationsFields_district_Delete"] | undefined
};
	["LocationsFields_district_Create"]: {
		permission: boolean
};
	["LocationsFields_district_Read"]: {
		permission: boolean
};
	["LocationsFields_district_Update"]: {
		permission: boolean
};
	["LocationsFields_district_Delete"]: {
		permission: boolean
};
	["LocationsFields_coordinate"]: {
		create?: ModelTypes["LocationsFields_coordinate_Create"] | undefined,
	read?: ModelTypes["LocationsFields_coordinate_Read"] | undefined,
	update?: ModelTypes["LocationsFields_coordinate_Update"] | undefined,
	delete?: ModelTypes["LocationsFields_coordinate_Delete"] | undefined
};
	["LocationsFields_coordinate_Create"]: {
		permission: boolean
};
	["LocationsFields_coordinate_Read"]: {
		permission: boolean
};
	["LocationsFields_coordinate_Update"]: {
		permission: boolean
};
	["LocationsFields_coordinate_Delete"]: {
		permission: boolean
};
	["LocationsFields_linkAddress"]: {
		create?: ModelTypes["LocationsFields_linkAddress_Create"] | undefined,
	read?: ModelTypes["LocationsFields_linkAddress_Read"] | undefined,
	update?: ModelTypes["LocationsFields_linkAddress_Update"] | undefined,
	delete?: ModelTypes["LocationsFields_linkAddress_Delete"] | undefined
};
	["LocationsFields_linkAddress_Create"]: {
		permission: boolean
};
	["LocationsFields_linkAddress_Read"]: {
		permission: boolean
};
	["LocationsFields_linkAddress_Update"]: {
		permission: boolean
};
	["LocationsFields_linkAddress_Delete"]: {
		permission: boolean
};
	["LocationsFields_address"]: {
		create?: ModelTypes["LocationsFields_address_Create"] | undefined,
	read?: ModelTypes["LocationsFields_address_Read"] | undefined,
	update?: ModelTypes["LocationsFields_address_Update"] | undefined,
	delete?: ModelTypes["LocationsFields_address_Delete"] | undefined
};
	["LocationsFields_address_Create"]: {
		permission: boolean
};
	["LocationsFields_address_Read"]: {
		permission: boolean
};
	["LocationsFields_address_Update"]: {
		permission: boolean
};
	["LocationsFields_address_Delete"]: {
		permission: boolean
};
	["LocationsFields_Details"]: {
		create?: ModelTypes["LocationsFields_Details_Create"] | undefined,
	read?: ModelTypes["LocationsFields_Details_Read"] | undefined,
	update?: ModelTypes["LocationsFields_Details_Update"] | undefined,
	delete?: ModelTypes["LocationsFields_Details_Delete"] | undefined,
	fields?: ModelTypes["LocationsFields_Details_Fields"] | undefined
};
	["LocationsFields_Details_Create"]: {
		permission: boolean
};
	["LocationsFields_Details_Read"]: {
		permission: boolean
};
	["LocationsFields_Details_Update"]: {
		permission: boolean
};
	["LocationsFields_Details_Delete"]: {
		permission: boolean
};
	["LocationsFields_Details_Fields"]: {
		code?: ModelTypes["LocationsFields_Details_code"] | undefined,
	phone?: ModelTypes["LocationsFields_Details_phone"] | undefined,
	page?: ModelTypes["LocationsFields_Details_page"] | undefined,
	email?: ModelTypes["LocationsFields_Details_email"] | undefined
};
	["LocationsFields_Details_code"]: {
		create?: ModelTypes["LocationsFields_Details_code_Create"] | undefined,
	read?: ModelTypes["LocationsFields_Details_code_Read"] | undefined,
	update?: ModelTypes["LocationsFields_Details_code_Update"] | undefined,
	delete?: ModelTypes["LocationsFields_Details_code_Delete"] | undefined
};
	["LocationsFields_Details_code_Create"]: {
		permission: boolean
};
	["LocationsFields_Details_code_Read"]: {
		permission: boolean
};
	["LocationsFields_Details_code_Update"]: {
		permission: boolean
};
	["LocationsFields_Details_code_Delete"]: {
		permission: boolean
};
	["LocationsFields_Details_phone"]: {
		create?: ModelTypes["LocationsFields_Details_phone_Create"] | undefined,
	read?: ModelTypes["LocationsFields_Details_phone_Read"] | undefined,
	update?: ModelTypes["LocationsFields_Details_phone_Update"] | undefined,
	delete?: ModelTypes["LocationsFields_Details_phone_Delete"] | undefined
};
	["LocationsFields_Details_phone_Create"]: {
		permission: boolean
};
	["LocationsFields_Details_phone_Read"]: {
		permission: boolean
};
	["LocationsFields_Details_phone_Update"]: {
		permission: boolean
};
	["LocationsFields_Details_phone_Delete"]: {
		permission: boolean
};
	["LocationsFields_Details_page"]: {
		create?: ModelTypes["LocationsFields_Details_page_Create"] | undefined,
	read?: ModelTypes["LocationsFields_Details_page_Read"] | undefined,
	update?: ModelTypes["LocationsFields_Details_page_Update"] | undefined,
	delete?: ModelTypes["LocationsFields_Details_page_Delete"] | undefined
};
	["LocationsFields_Details_page_Create"]: {
		permission: boolean
};
	["LocationsFields_Details_page_Read"]: {
		permission: boolean
};
	["LocationsFields_Details_page_Update"]: {
		permission: boolean
};
	["LocationsFields_Details_page_Delete"]: {
		permission: boolean
};
	["LocationsFields_Details_email"]: {
		create?: ModelTypes["LocationsFields_Details_email_Create"] | undefined,
	read?: ModelTypes["LocationsFields_Details_email_Read"] | undefined,
	update?: ModelTypes["LocationsFields_Details_email_Update"] | undefined,
	delete?: ModelTypes["LocationsFields_Details_email_Delete"] | undefined
};
	["LocationsFields_Details_email_Create"]: {
		permission: boolean
};
	["LocationsFields_Details_email_Read"]: {
		permission: boolean
};
	["LocationsFields_Details_email_Update"]: {
		permission: boolean
};
	["LocationsFields_Details_email_Delete"]: {
		permission: boolean
};
	["LocationsFields_content"]: {
		create?: ModelTypes["LocationsFields_content_Create"] | undefined,
	read?: ModelTypes["LocationsFields_content_Read"] | undefined,
	update?: ModelTypes["LocationsFields_content_Update"] | undefined,
	delete?: ModelTypes["LocationsFields_content_Delete"] | undefined
};
	["LocationsFields_content_Create"]: {
		permission: boolean
};
	["LocationsFields_content_Read"]: {
		permission: boolean
};
	["LocationsFields_content_Update"]: {
		permission: boolean
};
	["LocationsFields_content_Delete"]: {
		permission: boolean
};
	["LocationsFields_legacyContent"]: {
		create?: ModelTypes["LocationsFields_legacyContent_Create"] | undefined,
	read?: ModelTypes["LocationsFields_legacyContent_Read"] | undefined,
	update?: ModelTypes["LocationsFields_legacyContent_Update"] | undefined,
	delete?: ModelTypes["LocationsFields_legacyContent_Delete"] | undefined
};
	["LocationsFields_legacyContent_Create"]: {
		permission: boolean
};
	["LocationsFields_legacyContent_Read"]: {
		permission: boolean
};
	["LocationsFields_legacyContent_Update"]: {
		permission: boolean
};
	["LocationsFields_legacyContent_Delete"]: {
		permission: boolean
};
	["LocationsFields_showLegacyContent"]: {
		create?: ModelTypes["LocationsFields_showLegacyContent_Create"] | undefined,
	read?: ModelTypes["LocationsFields_showLegacyContent_Read"] | undefined,
	update?: ModelTypes["LocationsFields_showLegacyContent_Update"] | undefined,
	delete?: ModelTypes["LocationsFields_showLegacyContent_Delete"] | undefined
};
	["LocationsFields_showLegacyContent_Create"]: {
		permission: boolean
};
	["LocationsFields_showLegacyContent_Read"]: {
		permission: boolean
};
	["LocationsFields_showLegacyContent_Update"]: {
		permission: boolean
};
	["LocationsFields_showLegacyContent_Delete"]: {
		permission: boolean
};
	["LocationsFields_criteria"]: {
		create?: ModelTypes["LocationsFields_criteria_Create"] | undefined,
	read?: ModelTypes["LocationsFields_criteria_Read"] | undefined,
	update?: ModelTypes["LocationsFields_criteria_Update"] | undefined,
	delete?: ModelTypes["LocationsFields_criteria_Delete"] | undefined,
	fields?: ModelTypes["LocationsFields_criteria_Fields"] | undefined
};
	["LocationsFields_criteria_Create"]: {
		permission: boolean
};
	["LocationsFields_criteria_Read"]: {
		permission: boolean
};
	["LocationsFields_criteria_Update"]: {
		permission: boolean
};
	["LocationsFields_criteria_Delete"]: {
		permission: boolean
};
	["LocationsFields_criteria_Fields"]: {
		audiences?: ModelTypes["LocationsFields_criteria_audiences"] | undefined,
	dates?: ModelTypes["LocationsFields_criteria_dates"] | undefined,
	categories?: ModelTypes["LocationsFields_criteria_categories"] | undefined,
	tags?: ModelTypes["LocationsFields_criteria_tags"] | undefined
};
	["LocationsFields_criteria_audiences"]: {
		create?: ModelTypes["LocationsFields_criteria_audiences_Create"] | undefined,
	read?: ModelTypes["LocationsFields_criteria_audiences_Read"] | undefined,
	update?: ModelTypes["LocationsFields_criteria_audiences_Update"] | undefined,
	delete?: ModelTypes["LocationsFields_criteria_audiences_Delete"] | undefined
};
	["LocationsFields_criteria_audiences_Create"]: {
		permission: boolean
};
	["LocationsFields_criteria_audiences_Read"]: {
		permission: boolean
};
	["LocationsFields_criteria_audiences_Update"]: {
		permission: boolean
};
	["LocationsFields_criteria_audiences_Delete"]: {
		permission: boolean
};
	["LocationsFields_criteria_dates"]: {
		create?: ModelTypes["LocationsFields_criteria_dates_Create"] | undefined,
	read?: ModelTypes["LocationsFields_criteria_dates_Read"] | undefined,
	update?: ModelTypes["LocationsFields_criteria_dates_Update"] | undefined,
	delete?: ModelTypes["LocationsFields_criteria_dates_Delete"] | undefined
};
	["LocationsFields_criteria_dates_Create"]: {
		permission: boolean
};
	["LocationsFields_criteria_dates_Read"]: {
		permission: boolean
};
	["LocationsFields_criteria_dates_Update"]: {
		permission: boolean
};
	["LocationsFields_criteria_dates_Delete"]: {
		permission: boolean
};
	["LocationsFields_criteria_categories"]: {
		create?: ModelTypes["LocationsFields_criteria_categories_Create"] | undefined,
	read?: ModelTypes["LocationsFields_criteria_categories_Read"] | undefined,
	update?: ModelTypes["LocationsFields_criteria_categories_Update"] | undefined,
	delete?: ModelTypes["LocationsFields_criteria_categories_Delete"] | undefined
};
	["LocationsFields_criteria_categories_Create"]: {
		permission: boolean
};
	["LocationsFields_criteria_categories_Read"]: {
		permission: boolean
};
	["LocationsFields_criteria_categories_Update"]: {
		permission: boolean
};
	["LocationsFields_criteria_categories_Delete"]: {
		permission: boolean
};
	["LocationsFields_criteria_tags"]: {
		create?: ModelTypes["LocationsFields_criteria_tags_Create"] | undefined,
	read?: ModelTypes["LocationsFields_criteria_tags_Read"] | undefined,
	update?: ModelTypes["LocationsFields_criteria_tags_Update"] | undefined,
	delete?: ModelTypes["LocationsFields_criteria_tags_Delete"] | undefined
};
	["LocationsFields_criteria_tags_Create"]: {
		permission: boolean
};
	["LocationsFields_criteria_tags_Read"]: {
		permission: boolean
};
	["LocationsFields_criteria_tags_Update"]: {
		permission: boolean
};
	["LocationsFields_criteria_tags_Delete"]: {
		permission: boolean
};
	["LocationsFields_sections"]: {
		create?: ModelTypes["LocationsFields_sections_Create"] | undefined,
	read?: ModelTypes["LocationsFields_sections_Read"] | undefined,
	update?: ModelTypes["LocationsFields_sections_Update"] | undefined,
	delete?: ModelTypes["LocationsFields_sections_Delete"] | undefined,
	fields?: ModelTypes["LocationsFields_sections_Fields"] | undefined
};
	["LocationsFields_sections_Create"]: {
		permission: boolean
};
	["LocationsFields_sections_Read"]: {
		permission: boolean
};
	["LocationsFields_sections_Update"]: {
		permission: boolean
};
	["LocationsFields_sections_Delete"]: {
		permission: boolean
};
	["LocationsFields_sections_Fields"]: {
		startDatetime?: ModelTypes["LocationsFields_sections_startDatetime"] | undefined,
	endDatetime?: ModelTypes["LocationsFields_sections_endDatetime"] | undefined,
	fullDay?: ModelTypes["LocationsFields_sections_fullDay"] | undefined,
	repeat?: ModelTypes["LocationsFields_sections_repeat"] | undefined,
	recurrance?: ModelTypes["LocationsFields_sections_recurrance"] | undefined,
	id?: ModelTypes["LocationsFields_sections_id"] | undefined
};
	["LocationsFields_sections_startDatetime"]: {
		create?: ModelTypes["LocationsFields_sections_startDatetime_Create"] | undefined,
	read?: ModelTypes["LocationsFields_sections_startDatetime_Read"] | undefined,
	update?: ModelTypes["LocationsFields_sections_startDatetime_Update"] | undefined,
	delete?: ModelTypes["LocationsFields_sections_startDatetime_Delete"] | undefined
};
	["LocationsFields_sections_startDatetime_Create"]: {
		permission: boolean
};
	["LocationsFields_sections_startDatetime_Read"]: {
		permission: boolean
};
	["LocationsFields_sections_startDatetime_Update"]: {
		permission: boolean
};
	["LocationsFields_sections_startDatetime_Delete"]: {
		permission: boolean
};
	["LocationsFields_sections_endDatetime"]: {
		create?: ModelTypes["LocationsFields_sections_endDatetime_Create"] | undefined,
	read?: ModelTypes["LocationsFields_sections_endDatetime_Read"] | undefined,
	update?: ModelTypes["LocationsFields_sections_endDatetime_Update"] | undefined,
	delete?: ModelTypes["LocationsFields_sections_endDatetime_Delete"] | undefined
};
	["LocationsFields_sections_endDatetime_Create"]: {
		permission: boolean
};
	["LocationsFields_sections_endDatetime_Read"]: {
		permission: boolean
};
	["LocationsFields_sections_endDatetime_Update"]: {
		permission: boolean
};
	["LocationsFields_sections_endDatetime_Delete"]: {
		permission: boolean
};
	["LocationsFields_sections_fullDay"]: {
		create?: ModelTypes["LocationsFields_sections_fullDay_Create"] | undefined,
	read?: ModelTypes["LocationsFields_sections_fullDay_Read"] | undefined,
	update?: ModelTypes["LocationsFields_sections_fullDay_Update"] | undefined,
	delete?: ModelTypes["LocationsFields_sections_fullDay_Delete"] | undefined
};
	["LocationsFields_sections_fullDay_Create"]: {
		permission: boolean
};
	["LocationsFields_sections_fullDay_Read"]: {
		permission: boolean
};
	["LocationsFields_sections_fullDay_Update"]: {
		permission: boolean
};
	["LocationsFields_sections_fullDay_Delete"]: {
		permission: boolean
};
	["LocationsFields_sections_repeat"]: {
		create?: ModelTypes["LocationsFields_sections_repeat_Create"] | undefined,
	read?: ModelTypes["LocationsFields_sections_repeat_Read"] | undefined,
	update?: ModelTypes["LocationsFields_sections_repeat_Update"] | undefined,
	delete?: ModelTypes["LocationsFields_sections_repeat_Delete"] | undefined
};
	["LocationsFields_sections_repeat_Create"]: {
		permission: boolean
};
	["LocationsFields_sections_repeat_Read"]: {
		permission: boolean
};
	["LocationsFields_sections_repeat_Update"]: {
		permission: boolean
};
	["LocationsFields_sections_repeat_Delete"]: {
		permission: boolean
};
	["LocationsFields_sections_recurrance"]: {
		create?: ModelTypes["LocationsFields_sections_recurrance_Create"] | undefined,
	read?: ModelTypes["LocationsFields_sections_recurrance_Read"] | undefined,
	update?: ModelTypes["LocationsFields_sections_recurrance_Update"] | undefined,
	delete?: ModelTypes["LocationsFields_sections_recurrance_Delete"] | undefined,
	fields?: ModelTypes["LocationsFields_sections_recurrance_Fields"] | undefined
};
	["LocationsFields_sections_recurrance_Create"]: {
		permission: boolean
};
	["LocationsFields_sections_recurrance_Read"]: {
		permission: boolean
};
	["LocationsFields_sections_recurrance_Update"]: {
		permission: boolean
};
	["LocationsFields_sections_recurrance_Delete"]: {
		permission: boolean
};
	["LocationsFields_sections_recurrance_Fields"]: {
		type?: ModelTypes["LocationsFields_sections_recurrance_type"] | undefined,
	weekday?: ModelTypes["LocationsFields_sections_recurrance_weekday"] | undefined
};
	["LocationsFields_sections_recurrance_type"]: {
		create?: ModelTypes["LocationsFields_sections_recurrance_type_Create"] | undefined,
	read?: ModelTypes["LocationsFields_sections_recurrance_type_Read"] | undefined,
	update?: ModelTypes["LocationsFields_sections_recurrance_type_Update"] | undefined,
	delete?: ModelTypes["LocationsFields_sections_recurrance_type_Delete"] | undefined
};
	["LocationsFields_sections_recurrance_type_Create"]: {
		permission: boolean
};
	["LocationsFields_sections_recurrance_type_Read"]: {
		permission: boolean
};
	["LocationsFields_sections_recurrance_type_Update"]: {
		permission: boolean
};
	["LocationsFields_sections_recurrance_type_Delete"]: {
		permission: boolean
};
	["LocationsFields_sections_recurrance_weekday"]: {
		create?: ModelTypes["LocationsFields_sections_recurrance_weekday_Create"] | undefined,
	read?: ModelTypes["LocationsFields_sections_recurrance_weekday_Read"] | undefined,
	update?: ModelTypes["LocationsFields_sections_recurrance_weekday_Update"] | undefined,
	delete?: ModelTypes["LocationsFields_sections_recurrance_weekday_Delete"] | undefined
};
	["LocationsFields_sections_recurrance_weekday_Create"]: {
		permission: boolean
};
	["LocationsFields_sections_recurrance_weekday_Read"]: {
		permission: boolean
};
	["LocationsFields_sections_recurrance_weekday_Update"]: {
		permission: boolean
};
	["LocationsFields_sections_recurrance_weekday_Delete"]: {
		permission: boolean
};
	["LocationsFields_sections_id"]: {
		create?: ModelTypes["LocationsFields_sections_id_Create"] | undefined,
	read?: ModelTypes["LocationsFields_sections_id_Read"] | undefined,
	update?: ModelTypes["LocationsFields_sections_id_Update"] | undefined,
	delete?: ModelTypes["LocationsFields_sections_id_Delete"] | undefined
};
	["LocationsFields_sections_id_Create"]: {
		permission: boolean
};
	["LocationsFields_sections_id_Read"]: {
		permission: boolean
};
	["LocationsFields_sections_id_Update"]: {
		permission: boolean
};
	["LocationsFields_sections_id_Delete"]: {
		permission: boolean
};
	["LocationsFields__title"]: {
		create?: ModelTypes["LocationsFields__title_Create"] | undefined,
	read?: ModelTypes["LocationsFields__title_Read"] | undefined,
	update?: ModelTypes["LocationsFields__title_Update"] | undefined,
	delete?: ModelTypes["LocationsFields__title_Delete"] | undefined
};
	["LocationsFields__title_Create"]: {
		permission: boolean
};
	["LocationsFields__title_Read"]: {
		permission: boolean
};
	["LocationsFields__title_Update"]: {
		permission: boolean
};
	["LocationsFields__title_Delete"]: {
		permission: boolean
};
	["LocationsFields_legacyGallery"]: {
		create?: ModelTypes["LocationsFields_legacyGallery_Create"] | undefined,
	read?: ModelTypes["LocationsFields_legacyGallery_Read"] | undefined,
	update?: ModelTypes["LocationsFields_legacyGallery_Update"] | undefined,
	delete?: ModelTypes["LocationsFields_legacyGallery_Delete"] | undefined
};
	["LocationsFields_legacyGallery_Create"]: {
		permission: boolean
};
	["LocationsFields_legacyGallery_Read"]: {
		permission: boolean
};
	["LocationsFields_legacyGallery_Update"]: {
		permission: boolean
};
	["LocationsFields_legacyGallery_Delete"]: {
		permission: boolean
};
	["LocationsFields_updatedAt"]: {
		create?: ModelTypes["LocationsFields_updatedAt_Create"] | undefined,
	read?: ModelTypes["LocationsFields_updatedAt_Read"] | undefined,
	update?: ModelTypes["LocationsFields_updatedAt_Update"] | undefined,
	delete?: ModelTypes["LocationsFields_updatedAt_Delete"] | undefined
};
	["LocationsFields_updatedAt_Create"]: {
		permission: boolean
};
	["LocationsFields_updatedAt_Read"]: {
		permission: boolean
};
	["LocationsFields_updatedAt_Update"]: {
		permission: boolean
};
	["LocationsFields_updatedAt_Delete"]: {
		permission: boolean
};
	["LocationsFields_createdAt"]: {
		create?: ModelTypes["LocationsFields_createdAt_Create"] | undefined,
	read?: ModelTypes["LocationsFields_createdAt_Read"] | undefined,
	update?: ModelTypes["LocationsFields_createdAt_Update"] | undefined,
	delete?: ModelTypes["LocationsFields_createdAt_Delete"] | undefined
};
	["LocationsFields_createdAt_Create"]: {
		permission: boolean
};
	["LocationsFields_createdAt_Read"]: {
		permission: boolean
};
	["LocationsFields_createdAt_Update"]: {
		permission: boolean
};
	["LocationsFields_createdAt_Delete"]: {
		permission: boolean
};
	["LocationsCreateAccess"]: {
		permission: boolean,
	where?: ModelTypes["JSONObject"] | undefined
};
	["LocationsReadAccess"]: {
		permission: boolean,
	where?: ModelTypes["JSONObject"] | undefined
};
	["LocationsUpdateAccess"]: {
		permission: boolean,
	where?: ModelTypes["JSONObject"] | undefined
};
	["LocationsDeleteAccess"]: {
		permission: boolean,
	where?: ModelTypes["JSONObject"] | undefined
};
	["postsAccess"]: {
		fields?: ModelTypes["PostsFields"] | undefined,
	create?: ModelTypes["PostsCreateAccess"] | undefined,
	read?: ModelTypes["PostsReadAccess"] | undefined,
	update?: ModelTypes["PostsUpdateAccess"] | undefined,
	delete?: ModelTypes["PostsDeleteAccess"] | undefined
};
	["PostsFields"]: {
		title?: ModelTypes["PostsFields_title"] | undefined,
	region?: ModelTypes["PostsFields_region"] | undefined,
	authur?: ModelTypes["PostsFields_authur"] | undefined,
	publishDateTime?: ModelTypes["PostsFields_publishDateTime"] | undefined,
	permalink?: ModelTypes["PostsFields_permalink"] | undefined,
	status?: ModelTypes["PostsFields_status"] | undefined,
	content?: ModelTypes["PostsFields_content"] | undefined,
	legacyContent?: ModelTypes["PostsFields_legacyContent"] | undefined,
	showLegacyContent?: ModelTypes["PostsFields_showLegacyContent"] | undefined,
	blogCategories?: ModelTypes["PostsFields_blogCategories"] | undefined,
	thumbnail?: ModelTypes["PostsFields_thumbnail"] | undefined,
	banner?: ModelTypes["PostsFields_banner"] | undefined,
	criteria?: ModelTypes["PostsFields_criteria"] | undefined,
	location?: ModelTypes["PostsFields_location"] | undefined,
	homePageInternalClick?: ModelTypes["PostsFields_homePageInternalClick"] | undefined,
	homePageImpression?: ModelTypes["PostsFields_homePageImpression"] | undefined,
	blogListInternalClick?: ModelTypes["PostsFields_blogListInternalClick"] | undefined,
	blogListImpression?: ModelTypes["PostsFields_blogListImpression"] | undefined,
	blogDetailInternalClick?: ModelTypes["PostsFields_blogDetailInternalClick"] | undefined,
	blogDetailImpression?: ModelTypes["PostsFields_blogDetailImpression"] | undefined,
	relatedEventInternalClick?: ModelTypes["PostsFields_relatedEventInternalClick"] | undefined,
	relatedEventImpression?: ModelTypes["PostsFields_relatedEventImpression"] | undefined,
	latestEventInternalClick?: ModelTypes["PostsFields_latestEventInternalClick"] | undefined,
	latestEventImpression?: ModelTypes["PostsFields_latestEventImpression"] | undefined,
	internalClick?: ModelTypes["PostsFields_internalClick"] | undefined,
	totalImpression?: ModelTypes["PostsFields_totalImpression"] | undefined,
	externalClick?: ModelTypes["PostsFields_externalClick"] | undefined,
	outboundClick?: ModelTypes["PostsFields_outboundClick"] | undefined,
	legacyGallery?: ModelTypes["PostsFields_legacyGallery"] | undefined,
	updatedAt?: ModelTypes["PostsFields_updatedAt"] | undefined,
	createdAt?: ModelTypes["PostsFields_createdAt"] | undefined
};
	["PostsFields_title"]: {
		create?: ModelTypes["PostsFields_title_Create"] | undefined,
	read?: ModelTypes["PostsFields_title_Read"] | undefined,
	update?: ModelTypes["PostsFields_title_Update"] | undefined,
	delete?: ModelTypes["PostsFields_title_Delete"] | undefined
};
	["PostsFields_title_Create"]: {
		permission: boolean
};
	["PostsFields_title_Read"]: {
		permission: boolean
};
	["PostsFields_title_Update"]: {
		permission: boolean
};
	["PostsFields_title_Delete"]: {
		permission: boolean
};
	["PostsFields_region"]: {
		create?: ModelTypes["PostsFields_region_Create"] | undefined,
	read?: ModelTypes["PostsFields_region_Read"] | undefined,
	update?: ModelTypes["PostsFields_region_Update"] | undefined,
	delete?: ModelTypes["PostsFields_region_Delete"] | undefined
};
	["PostsFields_region_Create"]: {
		permission: boolean
};
	["PostsFields_region_Read"]: {
		permission: boolean
};
	["PostsFields_region_Update"]: {
		permission: boolean
};
	["PostsFields_region_Delete"]: {
		permission: boolean
};
	["PostsFields_authur"]: {
		create?: ModelTypes["PostsFields_authur_Create"] | undefined,
	read?: ModelTypes["PostsFields_authur_Read"] | undefined,
	update?: ModelTypes["PostsFields_authur_Update"] | undefined,
	delete?: ModelTypes["PostsFields_authur_Delete"] | undefined
};
	["PostsFields_authur_Create"]: {
		permission: boolean
};
	["PostsFields_authur_Read"]: {
		permission: boolean
};
	["PostsFields_authur_Update"]: {
		permission: boolean
};
	["PostsFields_authur_Delete"]: {
		permission: boolean
};
	["PostsFields_publishDateTime"]: {
		create?: ModelTypes["PostsFields_publishDateTime_Create"] | undefined,
	read?: ModelTypes["PostsFields_publishDateTime_Read"] | undefined,
	update?: ModelTypes["PostsFields_publishDateTime_Update"] | undefined,
	delete?: ModelTypes["PostsFields_publishDateTime_Delete"] | undefined
};
	["PostsFields_publishDateTime_Create"]: {
		permission: boolean
};
	["PostsFields_publishDateTime_Read"]: {
		permission: boolean
};
	["PostsFields_publishDateTime_Update"]: {
		permission: boolean
};
	["PostsFields_publishDateTime_Delete"]: {
		permission: boolean
};
	["PostsFields_permalink"]: {
		create?: ModelTypes["PostsFields_permalink_Create"] | undefined,
	read?: ModelTypes["PostsFields_permalink_Read"] | undefined,
	update?: ModelTypes["PostsFields_permalink_Update"] | undefined,
	delete?: ModelTypes["PostsFields_permalink_Delete"] | undefined
};
	["PostsFields_permalink_Create"]: {
		permission: boolean
};
	["PostsFields_permalink_Read"]: {
		permission: boolean
};
	["PostsFields_permalink_Update"]: {
		permission: boolean
};
	["PostsFields_permalink_Delete"]: {
		permission: boolean
};
	["PostsFields_status"]: {
		create?: ModelTypes["PostsFields_status_Create"] | undefined,
	read?: ModelTypes["PostsFields_status_Read"] | undefined,
	update?: ModelTypes["PostsFields_status_Update"] | undefined,
	delete?: ModelTypes["PostsFields_status_Delete"] | undefined
};
	["PostsFields_status_Create"]: {
		permission: boolean
};
	["PostsFields_status_Read"]: {
		permission: boolean
};
	["PostsFields_status_Update"]: {
		permission: boolean
};
	["PostsFields_status_Delete"]: {
		permission: boolean
};
	["PostsFields_content"]: {
		create?: ModelTypes["PostsFields_content_Create"] | undefined,
	read?: ModelTypes["PostsFields_content_Read"] | undefined,
	update?: ModelTypes["PostsFields_content_Update"] | undefined,
	delete?: ModelTypes["PostsFields_content_Delete"] | undefined
};
	["PostsFields_content_Create"]: {
		permission: boolean
};
	["PostsFields_content_Read"]: {
		permission: boolean
};
	["PostsFields_content_Update"]: {
		permission: boolean
};
	["PostsFields_content_Delete"]: {
		permission: boolean
};
	["PostsFields_legacyContent"]: {
		create?: ModelTypes["PostsFields_legacyContent_Create"] | undefined,
	read?: ModelTypes["PostsFields_legacyContent_Read"] | undefined,
	update?: ModelTypes["PostsFields_legacyContent_Update"] | undefined,
	delete?: ModelTypes["PostsFields_legacyContent_Delete"] | undefined
};
	["PostsFields_legacyContent_Create"]: {
		permission: boolean
};
	["PostsFields_legacyContent_Read"]: {
		permission: boolean
};
	["PostsFields_legacyContent_Update"]: {
		permission: boolean
};
	["PostsFields_legacyContent_Delete"]: {
		permission: boolean
};
	["PostsFields_showLegacyContent"]: {
		create?: ModelTypes["PostsFields_showLegacyContent_Create"] | undefined,
	read?: ModelTypes["PostsFields_showLegacyContent_Read"] | undefined,
	update?: ModelTypes["PostsFields_showLegacyContent_Update"] | undefined,
	delete?: ModelTypes["PostsFields_showLegacyContent_Delete"] | undefined
};
	["PostsFields_showLegacyContent_Create"]: {
		permission: boolean
};
	["PostsFields_showLegacyContent_Read"]: {
		permission: boolean
};
	["PostsFields_showLegacyContent_Update"]: {
		permission: boolean
};
	["PostsFields_showLegacyContent_Delete"]: {
		permission: boolean
};
	["PostsFields_blogCategories"]: {
		create?: ModelTypes["PostsFields_blogCategories_Create"] | undefined,
	read?: ModelTypes["PostsFields_blogCategories_Read"] | undefined,
	update?: ModelTypes["PostsFields_blogCategories_Update"] | undefined,
	delete?: ModelTypes["PostsFields_blogCategories_Delete"] | undefined
};
	["PostsFields_blogCategories_Create"]: {
		permission: boolean
};
	["PostsFields_blogCategories_Read"]: {
		permission: boolean
};
	["PostsFields_blogCategories_Update"]: {
		permission: boolean
};
	["PostsFields_blogCategories_Delete"]: {
		permission: boolean
};
	["PostsFields_thumbnail"]: {
		create?: ModelTypes["PostsFields_thumbnail_Create"] | undefined,
	read?: ModelTypes["PostsFields_thumbnail_Read"] | undefined,
	update?: ModelTypes["PostsFields_thumbnail_Update"] | undefined,
	delete?: ModelTypes["PostsFields_thumbnail_Delete"] | undefined
};
	["PostsFields_thumbnail_Create"]: {
		permission: boolean
};
	["PostsFields_thumbnail_Read"]: {
		permission: boolean
};
	["PostsFields_thumbnail_Update"]: {
		permission: boolean
};
	["PostsFields_thumbnail_Delete"]: {
		permission: boolean
};
	["PostsFields_banner"]: {
		create?: ModelTypes["PostsFields_banner_Create"] | undefined,
	read?: ModelTypes["PostsFields_banner_Read"] | undefined,
	update?: ModelTypes["PostsFields_banner_Update"] | undefined,
	delete?: ModelTypes["PostsFields_banner_Delete"] | undefined
};
	["PostsFields_banner_Create"]: {
		permission: boolean
};
	["PostsFields_banner_Read"]: {
		permission: boolean
};
	["PostsFields_banner_Update"]: {
		permission: boolean
};
	["PostsFields_banner_Delete"]: {
		permission: boolean
};
	["PostsFields_criteria"]: {
		create?: ModelTypes["PostsFields_criteria_Create"] | undefined,
	read?: ModelTypes["PostsFields_criteria_Read"] | undefined,
	update?: ModelTypes["PostsFields_criteria_Update"] | undefined,
	delete?: ModelTypes["PostsFields_criteria_Delete"] | undefined,
	fields?: ModelTypes["PostsFields_criteria_Fields"] | undefined
};
	["PostsFields_criteria_Create"]: {
		permission: boolean
};
	["PostsFields_criteria_Read"]: {
		permission: boolean
};
	["PostsFields_criteria_Update"]: {
		permission: boolean
};
	["PostsFields_criteria_Delete"]: {
		permission: boolean
};
	["PostsFields_criteria_Fields"]: {
		audiences?: ModelTypes["PostsFields_criteria_audiences"] | undefined,
	dates?: ModelTypes["PostsFields_criteria_dates"] | undefined,
	categories?: ModelTypes["PostsFields_criteria_categories"] | undefined,
	tags?: ModelTypes["PostsFields_criteria_tags"] | undefined
};
	["PostsFields_criteria_audiences"]: {
		create?: ModelTypes["PostsFields_criteria_audiences_Create"] | undefined,
	read?: ModelTypes["PostsFields_criteria_audiences_Read"] | undefined,
	update?: ModelTypes["PostsFields_criteria_audiences_Update"] | undefined,
	delete?: ModelTypes["PostsFields_criteria_audiences_Delete"] | undefined
};
	["PostsFields_criteria_audiences_Create"]: {
		permission: boolean
};
	["PostsFields_criteria_audiences_Read"]: {
		permission: boolean
};
	["PostsFields_criteria_audiences_Update"]: {
		permission: boolean
};
	["PostsFields_criteria_audiences_Delete"]: {
		permission: boolean
};
	["PostsFields_criteria_dates"]: {
		create?: ModelTypes["PostsFields_criteria_dates_Create"] | undefined,
	read?: ModelTypes["PostsFields_criteria_dates_Read"] | undefined,
	update?: ModelTypes["PostsFields_criteria_dates_Update"] | undefined,
	delete?: ModelTypes["PostsFields_criteria_dates_Delete"] | undefined
};
	["PostsFields_criteria_dates_Create"]: {
		permission: boolean
};
	["PostsFields_criteria_dates_Read"]: {
		permission: boolean
};
	["PostsFields_criteria_dates_Update"]: {
		permission: boolean
};
	["PostsFields_criteria_dates_Delete"]: {
		permission: boolean
};
	["PostsFields_criteria_categories"]: {
		create?: ModelTypes["PostsFields_criteria_categories_Create"] | undefined,
	read?: ModelTypes["PostsFields_criteria_categories_Read"] | undefined,
	update?: ModelTypes["PostsFields_criteria_categories_Update"] | undefined,
	delete?: ModelTypes["PostsFields_criteria_categories_Delete"] | undefined
};
	["PostsFields_criteria_categories_Create"]: {
		permission: boolean
};
	["PostsFields_criteria_categories_Read"]: {
		permission: boolean
};
	["PostsFields_criteria_categories_Update"]: {
		permission: boolean
};
	["PostsFields_criteria_categories_Delete"]: {
		permission: boolean
};
	["PostsFields_criteria_tags"]: {
		create?: ModelTypes["PostsFields_criteria_tags_Create"] | undefined,
	read?: ModelTypes["PostsFields_criteria_tags_Read"] | undefined,
	update?: ModelTypes["PostsFields_criteria_tags_Update"] | undefined,
	delete?: ModelTypes["PostsFields_criteria_tags_Delete"] | undefined
};
	["PostsFields_criteria_tags_Create"]: {
		permission: boolean
};
	["PostsFields_criteria_tags_Read"]: {
		permission: boolean
};
	["PostsFields_criteria_tags_Update"]: {
		permission: boolean
};
	["PostsFields_criteria_tags_Delete"]: {
		permission: boolean
};
	["PostsFields_location"]: {
		create?: ModelTypes["PostsFields_location_Create"] | undefined,
	read?: ModelTypes["PostsFields_location_Read"] | undefined,
	update?: ModelTypes["PostsFields_location_Update"] | undefined,
	delete?: ModelTypes["PostsFields_location_Delete"] | undefined
};
	["PostsFields_location_Create"]: {
		permission: boolean
};
	["PostsFields_location_Read"]: {
		permission: boolean
};
	["PostsFields_location_Update"]: {
		permission: boolean
};
	["PostsFields_location_Delete"]: {
		permission: boolean
};
	["PostsFields_homePageInternalClick"]: {
		create?: ModelTypes["PostsFields_homePageInternalClick_Create"] | undefined,
	read?: ModelTypes["PostsFields_homePageInternalClick_Read"] | undefined,
	update?: ModelTypes["PostsFields_homePageInternalClick_Update"] | undefined,
	delete?: ModelTypes["PostsFields_homePageInternalClick_Delete"] | undefined
};
	["PostsFields_homePageInternalClick_Create"]: {
		permission: boolean
};
	["PostsFields_homePageInternalClick_Read"]: {
		permission: boolean
};
	["PostsFields_homePageInternalClick_Update"]: {
		permission: boolean
};
	["PostsFields_homePageInternalClick_Delete"]: {
		permission: boolean
};
	["PostsFields_homePageImpression"]: {
		create?: ModelTypes["PostsFields_homePageImpression_Create"] | undefined,
	read?: ModelTypes["PostsFields_homePageImpression_Read"] | undefined,
	update?: ModelTypes["PostsFields_homePageImpression_Update"] | undefined,
	delete?: ModelTypes["PostsFields_homePageImpression_Delete"] | undefined
};
	["PostsFields_homePageImpression_Create"]: {
		permission: boolean
};
	["PostsFields_homePageImpression_Read"]: {
		permission: boolean
};
	["PostsFields_homePageImpression_Update"]: {
		permission: boolean
};
	["PostsFields_homePageImpression_Delete"]: {
		permission: boolean
};
	["PostsFields_blogListInternalClick"]: {
		create?: ModelTypes["PostsFields_blogListInternalClick_Create"] | undefined,
	read?: ModelTypes["PostsFields_blogListInternalClick_Read"] | undefined,
	update?: ModelTypes["PostsFields_blogListInternalClick_Update"] | undefined,
	delete?: ModelTypes["PostsFields_blogListInternalClick_Delete"] | undefined
};
	["PostsFields_blogListInternalClick_Create"]: {
		permission: boolean
};
	["PostsFields_blogListInternalClick_Read"]: {
		permission: boolean
};
	["PostsFields_blogListInternalClick_Update"]: {
		permission: boolean
};
	["PostsFields_blogListInternalClick_Delete"]: {
		permission: boolean
};
	["PostsFields_blogListImpression"]: {
		create?: ModelTypes["PostsFields_blogListImpression_Create"] | undefined,
	read?: ModelTypes["PostsFields_blogListImpression_Read"] | undefined,
	update?: ModelTypes["PostsFields_blogListImpression_Update"] | undefined,
	delete?: ModelTypes["PostsFields_blogListImpression_Delete"] | undefined
};
	["PostsFields_blogListImpression_Create"]: {
		permission: boolean
};
	["PostsFields_blogListImpression_Read"]: {
		permission: boolean
};
	["PostsFields_blogListImpression_Update"]: {
		permission: boolean
};
	["PostsFields_blogListImpression_Delete"]: {
		permission: boolean
};
	["PostsFields_blogDetailInternalClick"]: {
		create?: ModelTypes["PostsFields_blogDetailInternalClick_Create"] | undefined,
	read?: ModelTypes["PostsFields_blogDetailInternalClick_Read"] | undefined,
	update?: ModelTypes["PostsFields_blogDetailInternalClick_Update"] | undefined,
	delete?: ModelTypes["PostsFields_blogDetailInternalClick_Delete"] | undefined
};
	["PostsFields_blogDetailInternalClick_Create"]: {
		permission: boolean
};
	["PostsFields_blogDetailInternalClick_Read"]: {
		permission: boolean
};
	["PostsFields_blogDetailInternalClick_Update"]: {
		permission: boolean
};
	["PostsFields_blogDetailInternalClick_Delete"]: {
		permission: boolean
};
	["PostsFields_blogDetailImpression"]: {
		create?: ModelTypes["PostsFields_blogDetailImpression_Create"] | undefined,
	read?: ModelTypes["PostsFields_blogDetailImpression_Read"] | undefined,
	update?: ModelTypes["PostsFields_blogDetailImpression_Update"] | undefined,
	delete?: ModelTypes["PostsFields_blogDetailImpression_Delete"] | undefined
};
	["PostsFields_blogDetailImpression_Create"]: {
		permission: boolean
};
	["PostsFields_blogDetailImpression_Read"]: {
		permission: boolean
};
	["PostsFields_blogDetailImpression_Update"]: {
		permission: boolean
};
	["PostsFields_blogDetailImpression_Delete"]: {
		permission: boolean
};
	["PostsFields_relatedEventInternalClick"]: {
		create?: ModelTypes["PostsFields_relatedEventInternalClick_Create"] | undefined,
	read?: ModelTypes["PostsFields_relatedEventInternalClick_Read"] | undefined,
	update?: ModelTypes["PostsFields_relatedEventInternalClick_Update"] | undefined,
	delete?: ModelTypes["PostsFields_relatedEventInternalClick_Delete"] | undefined
};
	["PostsFields_relatedEventInternalClick_Create"]: {
		permission: boolean
};
	["PostsFields_relatedEventInternalClick_Read"]: {
		permission: boolean
};
	["PostsFields_relatedEventInternalClick_Update"]: {
		permission: boolean
};
	["PostsFields_relatedEventInternalClick_Delete"]: {
		permission: boolean
};
	["PostsFields_relatedEventImpression"]: {
		create?: ModelTypes["PostsFields_relatedEventImpression_Create"] | undefined,
	read?: ModelTypes["PostsFields_relatedEventImpression_Read"] | undefined,
	update?: ModelTypes["PostsFields_relatedEventImpression_Update"] | undefined,
	delete?: ModelTypes["PostsFields_relatedEventImpression_Delete"] | undefined
};
	["PostsFields_relatedEventImpression_Create"]: {
		permission: boolean
};
	["PostsFields_relatedEventImpression_Read"]: {
		permission: boolean
};
	["PostsFields_relatedEventImpression_Update"]: {
		permission: boolean
};
	["PostsFields_relatedEventImpression_Delete"]: {
		permission: boolean
};
	["PostsFields_latestEventInternalClick"]: {
		create?: ModelTypes["PostsFields_latestEventInternalClick_Create"] | undefined,
	read?: ModelTypes["PostsFields_latestEventInternalClick_Read"] | undefined,
	update?: ModelTypes["PostsFields_latestEventInternalClick_Update"] | undefined,
	delete?: ModelTypes["PostsFields_latestEventInternalClick_Delete"] | undefined
};
	["PostsFields_latestEventInternalClick_Create"]: {
		permission: boolean
};
	["PostsFields_latestEventInternalClick_Read"]: {
		permission: boolean
};
	["PostsFields_latestEventInternalClick_Update"]: {
		permission: boolean
};
	["PostsFields_latestEventInternalClick_Delete"]: {
		permission: boolean
};
	["PostsFields_latestEventImpression"]: {
		create?: ModelTypes["PostsFields_latestEventImpression_Create"] | undefined,
	read?: ModelTypes["PostsFields_latestEventImpression_Read"] | undefined,
	update?: ModelTypes["PostsFields_latestEventImpression_Update"] | undefined,
	delete?: ModelTypes["PostsFields_latestEventImpression_Delete"] | undefined
};
	["PostsFields_latestEventImpression_Create"]: {
		permission: boolean
};
	["PostsFields_latestEventImpression_Read"]: {
		permission: boolean
};
	["PostsFields_latestEventImpression_Update"]: {
		permission: boolean
};
	["PostsFields_latestEventImpression_Delete"]: {
		permission: boolean
};
	["PostsFields_internalClick"]: {
		create?: ModelTypes["PostsFields_internalClick_Create"] | undefined,
	read?: ModelTypes["PostsFields_internalClick_Read"] | undefined,
	update?: ModelTypes["PostsFields_internalClick_Update"] | undefined,
	delete?: ModelTypes["PostsFields_internalClick_Delete"] | undefined
};
	["PostsFields_internalClick_Create"]: {
		permission: boolean
};
	["PostsFields_internalClick_Read"]: {
		permission: boolean
};
	["PostsFields_internalClick_Update"]: {
		permission: boolean
};
	["PostsFields_internalClick_Delete"]: {
		permission: boolean
};
	["PostsFields_totalImpression"]: {
		create?: ModelTypes["PostsFields_totalImpression_Create"] | undefined,
	read?: ModelTypes["PostsFields_totalImpression_Read"] | undefined,
	update?: ModelTypes["PostsFields_totalImpression_Update"] | undefined,
	delete?: ModelTypes["PostsFields_totalImpression_Delete"] | undefined
};
	["PostsFields_totalImpression_Create"]: {
		permission: boolean
};
	["PostsFields_totalImpression_Read"]: {
		permission: boolean
};
	["PostsFields_totalImpression_Update"]: {
		permission: boolean
};
	["PostsFields_totalImpression_Delete"]: {
		permission: boolean
};
	["PostsFields_externalClick"]: {
		create?: ModelTypes["PostsFields_externalClick_Create"] | undefined,
	read?: ModelTypes["PostsFields_externalClick_Read"] | undefined,
	update?: ModelTypes["PostsFields_externalClick_Update"] | undefined,
	delete?: ModelTypes["PostsFields_externalClick_Delete"] | undefined
};
	["PostsFields_externalClick_Create"]: {
		permission: boolean
};
	["PostsFields_externalClick_Read"]: {
		permission: boolean
};
	["PostsFields_externalClick_Update"]: {
		permission: boolean
};
	["PostsFields_externalClick_Delete"]: {
		permission: boolean
};
	["PostsFields_outboundClick"]: {
		create?: ModelTypes["PostsFields_outboundClick_Create"] | undefined,
	read?: ModelTypes["PostsFields_outboundClick_Read"] | undefined,
	update?: ModelTypes["PostsFields_outboundClick_Update"] | undefined,
	delete?: ModelTypes["PostsFields_outboundClick_Delete"] | undefined
};
	["PostsFields_outboundClick_Create"]: {
		permission: boolean
};
	["PostsFields_outboundClick_Read"]: {
		permission: boolean
};
	["PostsFields_outboundClick_Update"]: {
		permission: boolean
};
	["PostsFields_outboundClick_Delete"]: {
		permission: boolean
};
	["PostsFields_legacyGallery"]: {
		create?: ModelTypes["PostsFields_legacyGallery_Create"] | undefined,
	read?: ModelTypes["PostsFields_legacyGallery_Read"] | undefined,
	update?: ModelTypes["PostsFields_legacyGallery_Update"] | undefined,
	delete?: ModelTypes["PostsFields_legacyGallery_Delete"] | undefined
};
	["PostsFields_legacyGallery_Create"]: {
		permission: boolean
};
	["PostsFields_legacyGallery_Read"]: {
		permission: boolean
};
	["PostsFields_legacyGallery_Update"]: {
		permission: boolean
};
	["PostsFields_legacyGallery_Delete"]: {
		permission: boolean
};
	["PostsFields_updatedAt"]: {
		create?: ModelTypes["PostsFields_updatedAt_Create"] | undefined,
	read?: ModelTypes["PostsFields_updatedAt_Read"] | undefined,
	update?: ModelTypes["PostsFields_updatedAt_Update"] | undefined,
	delete?: ModelTypes["PostsFields_updatedAt_Delete"] | undefined
};
	["PostsFields_updatedAt_Create"]: {
		permission: boolean
};
	["PostsFields_updatedAt_Read"]: {
		permission: boolean
};
	["PostsFields_updatedAt_Update"]: {
		permission: boolean
};
	["PostsFields_updatedAt_Delete"]: {
		permission: boolean
};
	["PostsFields_createdAt"]: {
		create?: ModelTypes["PostsFields_createdAt_Create"] | undefined,
	read?: ModelTypes["PostsFields_createdAt_Read"] | undefined,
	update?: ModelTypes["PostsFields_createdAt_Update"] | undefined,
	delete?: ModelTypes["PostsFields_createdAt_Delete"] | undefined
};
	["PostsFields_createdAt_Create"]: {
		permission: boolean
};
	["PostsFields_createdAt_Read"]: {
		permission: boolean
};
	["PostsFields_createdAt_Update"]: {
		permission: boolean
};
	["PostsFields_createdAt_Delete"]: {
		permission: boolean
};
	["PostsCreateAccess"]: {
		permission: boolean,
	where?: ModelTypes["JSONObject"] | undefined
};
	["PostsReadAccess"]: {
		permission: boolean,
	where?: ModelTypes["JSONObject"] | undefined
};
	["PostsUpdateAccess"]: {
		permission: boolean,
	where?: ModelTypes["JSONObject"] | undefined
};
	["PostsDeleteAccess"]: {
		permission: boolean,
	where?: ModelTypes["JSONObject"] | undefined
};
	["ticketingAccess"]: {
		fields?: ModelTypes["TicketingFields"] | undefined,
	create?: ModelTypes["TicketingCreateAccess"] | undefined,
	read?: ModelTypes["TicketingReadAccess"] | undefined,
	update?: ModelTypes["TicketingUpdateAccess"] | undefined,
	delete?: ModelTypes["TicketingDeleteAccess"] | undefined
};
	["TicketingFields"]: {
		status?: ModelTypes["TicketingFields_status"] | undefined,
	creator?: ModelTypes["TicketingFields_creator"] | undefined,
	productType?: ModelTypes["TicketingFields_productType"] | undefined,
	productName?: ModelTypes["TicketingFields_productName"] | undefined,
	referenceId?: ModelTypes["TicketingFields_referenceId"] | undefined,
	linkedEvent?: ModelTypes["TicketingFields_linkedEvent"] | undefined,
	CheckInLevel?: ModelTypes["TicketingFields_CheckInLevel"] | undefined,
	displayPrice?: ModelTypes["TicketingFields_displayPrice"] | undefined,
	tnc?: ModelTypes["TicketingFields_tnc"] | undefined,
	cutoffHours?: ModelTypes["TicketingFields_cutoffHours"] | undefined,
	sessions?: ModelTypes["TicketingFields_sessions"] | undefined,
	updatedAt?: ModelTypes["TicketingFields_updatedAt"] | undefined,
	createdAt?: ModelTypes["TicketingFields_createdAt"] | undefined
};
	["TicketingFields_status"]: {
		create?: ModelTypes["TicketingFields_status_Create"] | undefined,
	read?: ModelTypes["TicketingFields_status_Read"] | undefined,
	update?: ModelTypes["TicketingFields_status_Update"] | undefined,
	delete?: ModelTypes["TicketingFields_status_Delete"] | undefined
};
	["TicketingFields_status_Create"]: {
		permission: boolean
};
	["TicketingFields_status_Read"]: {
		permission: boolean
};
	["TicketingFields_status_Update"]: {
		permission: boolean
};
	["TicketingFields_status_Delete"]: {
		permission: boolean
};
	["TicketingFields_creator"]: {
		create?: ModelTypes["TicketingFields_creator_Create"] | undefined,
	read?: ModelTypes["TicketingFields_creator_Read"] | undefined,
	update?: ModelTypes["TicketingFields_creator_Update"] | undefined,
	delete?: ModelTypes["TicketingFields_creator_Delete"] | undefined
};
	["TicketingFields_creator_Create"]: {
		permission: boolean
};
	["TicketingFields_creator_Read"]: {
		permission: boolean
};
	["TicketingFields_creator_Update"]: {
		permission: boolean
};
	["TicketingFields_creator_Delete"]: {
		permission: boolean
};
	["TicketingFields_productType"]: {
		create?: ModelTypes["TicketingFields_productType_Create"] | undefined,
	read?: ModelTypes["TicketingFields_productType_Read"] | undefined,
	update?: ModelTypes["TicketingFields_productType_Update"] | undefined,
	delete?: ModelTypes["TicketingFields_productType_Delete"] | undefined
};
	["TicketingFields_productType_Create"]: {
		permission: boolean
};
	["TicketingFields_productType_Read"]: {
		permission: boolean
};
	["TicketingFields_productType_Update"]: {
		permission: boolean
};
	["TicketingFields_productType_Delete"]: {
		permission: boolean
};
	["TicketingFields_productName"]: {
		create?: ModelTypes["TicketingFields_productName_Create"] | undefined,
	read?: ModelTypes["TicketingFields_productName_Read"] | undefined,
	update?: ModelTypes["TicketingFields_productName_Update"] | undefined,
	delete?: ModelTypes["TicketingFields_productName_Delete"] | undefined
};
	["TicketingFields_productName_Create"]: {
		permission: boolean
};
	["TicketingFields_productName_Read"]: {
		permission: boolean
};
	["TicketingFields_productName_Update"]: {
		permission: boolean
};
	["TicketingFields_productName_Delete"]: {
		permission: boolean
};
	["TicketingFields_referenceId"]: {
		create?: ModelTypes["TicketingFields_referenceId_Create"] | undefined,
	read?: ModelTypes["TicketingFields_referenceId_Read"] | undefined,
	update?: ModelTypes["TicketingFields_referenceId_Update"] | undefined,
	delete?: ModelTypes["TicketingFields_referenceId_Delete"] | undefined
};
	["TicketingFields_referenceId_Create"]: {
		permission: boolean
};
	["TicketingFields_referenceId_Read"]: {
		permission: boolean
};
	["TicketingFields_referenceId_Update"]: {
		permission: boolean
};
	["TicketingFields_referenceId_Delete"]: {
		permission: boolean
};
	["TicketingFields_linkedEvent"]: {
		create?: ModelTypes["TicketingFields_linkedEvent_Create"] | undefined,
	read?: ModelTypes["TicketingFields_linkedEvent_Read"] | undefined,
	update?: ModelTypes["TicketingFields_linkedEvent_Update"] | undefined,
	delete?: ModelTypes["TicketingFields_linkedEvent_Delete"] | undefined
};
	["TicketingFields_linkedEvent_Create"]: {
		permission: boolean
};
	["TicketingFields_linkedEvent_Read"]: {
		permission: boolean
};
	["TicketingFields_linkedEvent_Update"]: {
		permission: boolean
};
	["TicketingFields_linkedEvent_Delete"]: {
		permission: boolean
};
	["TicketingFields_CheckInLevel"]: {
		create?: ModelTypes["TicketingFields_CheckInLevel_Create"] | undefined,
	read?: ModelTypes["TicketingFields_CheckInLevel_Read"] | undefined,
	update?: ModelTypes["TicketingFields_CheckInLevel_Update"] | undefined,
	delete?: ModelTypes["TicketingFields_CheckInLevel_Delete"] | undefined
};
	["TicketingFields_CheckInLevel_Create"]: {
		permission: boolean
};
	["TicketingFields_CheckInLevel_Read"]: {
		permission: boolean
};
	["TicketingFields_CheckInLevel_Update"]: {
		permission: boolean
};
	["TicketingFields_CheckInLevel_Delete"]: {
		permission: boolean
};
	["TicketingFields_displayPrice"]: {
		create?: ModelTypes["TicketingFields_displayPrice_Create"] | undefined,
	read?: ModelTypes["TicketingFields_displayPrice_Read"] | undefined,
	update?: ModelTypes["TicketingFields_displayPrice_Update"] | undefined,
	delete?: ModelTypes["TicketingFields_displayPrice_Delete"] | undefined
};
	["TicketingFields_displayPrice_Create"]: {
		permission: boolean
};
	["TicketingFields_displayPrice_Read"]: {
		permission: boolean
};
	["TicketingFields_displayPrice_Update"]: {
		permission: boolean
};
	["TicketingFields_displayPrice_Delete"]: {
		permission: boolean
};
	["TicketingFields_tnc"]: {
		create?: ModelTypes["TicketingFields_tnc_Create"] | undefined,
	read?: ModelTypes["TicketingFields_tnc_Read"] | undefined,
	update?: ModelTypes["TicketingFields_tnc_Update"] | undefined,
	delete?: ModelTypes["TicketingFields_tnc_Delete"] | undefined
};
	["TicketingFields_tnc_Create"]: {
		permission: boolean
};
	["TicketingFields_tnc_Read"]: {
		permission: boolean
};
	["TicketingFields_tnc_Update"]: {
		permission: boolean
};
	["TicketingFields_tnc_Delete"]: {
		permission: boolean
};
	["TicketingFields_cutoffHours"]: {
		create?: ModelTypes["TicketingFields_cutoffHours_Create"] | undefined,
	read?: ModelTypes["TicketingFields_cutoffHours_Read"] | undefined,
	update?: ModelTypes["TicketingFields_cutoffHours_Update"] | undefined,
	delete?: ModelTypes["TicketingFields_cutoffHours_Delete"] | undefined
};
	["TicketingFields_cutoffHours_Create"]: {
		permission: boolean
};
	["TicketingFields_cutoffHours_Read"]: {
		permission: boolean
};
	["TicketingFields_cutoffHours_Update"]: {
		permission: boolean
};
	["TicketingFields_cutoffHours_Delete"]: {
		permission: boolean
};
	["TicketingFields_sessions"]: {
		create?: ModelTypes["TicketingFields_sessions_Create"] | undefined,
	read?: ModelTypes["TicketingFields_sessions_Read"] | undefined,
	update?: ModelTypes["TicketingFields_sessions_Update"] | undefined,
	delete?: ModelTypes["TicketingFields_sessions_Delete"] | undefined,
	fields?: ModelTypes["TicketingFields_sessions_Fields"] | undefined
};
	["TicketingFields_sessions_Create"]: {
		permission: boolean
};
	["TicketingFields_sessions_Read"]: {
		permission: boolean
};
	["TicketingFields_sessions_Update"]: {
		permission: boolean
};
	["TicketingFields_sessions_Delete"]: {
		permission: boolean
};
	["TicketingFields_sessions_Fields"]: {
		productId?: ModelTypes["TicketingFields_sessions_productId"] | undefined,
	startDate?: ModelTypes["TicketingFields_sessions_startDate"] | undefined,
	endDate?: ModelTypes["TicketingFields_sessions_endDate"] | undefined,
	inventories?: ModelTypes["TicketingFields_sessions_inventories"] | undefined,
	id?: ModelTypes["TicketingFields_sessions_id"] | undefined
};
	["TicketingFields_sessions_productId"]: {
		create?: ModelTypes["TicketingFields_sessions_productId_Create"] | undefined,
	read?: ModelTypes["TicketingFields_sessions_productId_Read"] | undefined,
	update?: ModelTypes["TicketingFields_sessions_productId_Update"] | undefined,
	delete?: ModelTypes["TicketingFields_sessions_productId_Delete"] | undefined
};
	["TicketingFields_sessions_productId_Create"]: {
		permission: boolean
};
	["TicketingFields_sessions_productId_Read"]: {
		permission: boolean
};
	["TicketingFields_sessions_productId_Update"]: {
		permission: boolean
};
	["TicketingFields_sessions_productId_Delete"]: {
		permission: boolean
};
	["TicketingFields_sessions_startDate"]: {
		create?: ModelTypes["TicketingFields_sessions_startDate_Create"] | undefined,
	read?: ModelTypes["TicketingFields_sessions_startDate_Read"] | undefined,
	update?: ModelTypes["TicketingFields_sessions_startDate_Update"] | undefined,
	delete?: ModelTypes["TicketingFields_sessions_startDate_Delete"] | undefined
};
	["TicketingFields_sessions_startDate_Create"]: {
		permission: boolean
};
	["TicketingFields_sessions_startDate_Read"]: {
		permission: boolean
};
	["TicketingFields_sessions_startDate_Update"]: {
		permission: boolean
};
	["TicketingFields_sessions_startDate_Delete"]: {
		permission: boolean
};
	["TicketingFields_sessions_endDate"]: {
		create?: ModelTypes["TicketingFields_sessions_endDate_Create"] | undefined,
	read?: ModelTypes["TicketingFields_sessions_endDate_Read"] | undefined,
	update?: ModelTypes["TicketingFields_sessions_endDate_Update"] | undefined,
	delete?: ModelTypes["TicketingFields_sessions_endDate_Delete"] | undefined
};
	["TicketingFields_sessions_endDate_Create"]: {
		permission: boolean
};
	["TicketingFields_sessions_endDate_Read"]: {
		permission: boolean
};
	["TicketingFields_sessions_endDate_Update"]: {
		permission: boolean
};
	["TicketingFields_sessions_endDate_Delete"]: {
		permission: boolean
};
	["TicketingFields_sessions_inventories"]: {
		create?: ModelTypes["TicketingFields_sessions_inventories_Create"] | undefined,
	read?: ModelTypes["TicketingFields_sessions_inventories_Read"] | undefined,
	update?: ModelTypes["TicketingFields_sessions_inventories_Update"] | undefined,
	delete?: ModelTypes["TicketingFields_sessions_inventories_Delete"] | undefined,
	fields?: ModelTypes["TicketingFields_sessions_inventories_Fields"] | undefined
};
	["TicketingFields_sessions_inventories_Create"]: {
		permission: boolean
};
	["TicketingFields_sessions_inventories_Read"]: {
		permission: boolean
};
	["TicketingFields_sessions_inventories_Update"]: {
		permission: boolean
};
	["TicketingFields_sessions_inventories_Delete"]: {
		permission: boolean
};
	["TicketingFields_sessions_inventories_Fields"]: {
		name?: ModelTypes["TicketingFields_sessions_inventories_name"] | undefined,
	quantity?: ModelTypes["TicketingFields_sessions_inventories_quantity"] | undefined,
	unitPrice?: ModelTypes["TicketingFields_sessions_inventories_unitPrice"] | undefined,
	handlingFee?: ModelTypes["TicketingFields_sessions_inventories_handlingFee"] | undefined,
	totalCost?: ModelTypes["TicketingFields_sessions_inventories_totalCost"] | undefined,
	minPurchase?: ModelTypes["TicketingFields_sessions_inventories_minPurchase"] | undefined,
	maxPurchase?: ModelTypes["TicketingFields_sessions_inventories_maxPurchase"] | undefined,
	stocks?: ModelTypes["TicketingFields_sessions_inventories_stocks"] | undefined,
	remarks?: ModelTypes["TicketingFields_sessions_inventories_remarks"] | undefined
};
	["TicketingFields_sessions_inventories_name"]: {
		create?: ModelTypes["TicketingFields_sessions_inventories_name_Create"] | undefined,
	read?: ModelTypes["TicketingFields_sessions_inventories_name_Read"] | undefined,
	update?: ModelTypes["TicketingFields_sessions_inventories_name_Update"] | undefined,
	delete?: ModelTypes["TicketingFields_sessions_inventories_name_Delete"] | undefined
};
	["TicketingFields_sessions_inventories_name_Create"]: {
		permission: boolean
};
	["TicketingFields_sessions_inventories_name_Read"]: {
		permission: boolean
};
	["TicketingFields_sessions_inventories_name_Update"]: {
		permission: boolean
};
	["TicketingFields_sessions_inventories_name_Delete"]: {
		permission: boolean
};
	["TicketingFields_sessions_inventories_quantity"]: {
		create?: ModelTypes["TicketingFields_sessions_inventories_quantity_Create"] | undefined,
	read?: ModelTypes["TicketingFields_sessions_inventories_quantity_Read"] | undefined,
	update?: ModelTypes["TicketingFields_sessions_inventories_quantity_Update"] | undefined,
	delete?: ModelTypes["TicketingFields_sessions_inventories_quantity_Delete"] | undefined
};
	["TicketingFields_sessions_inventories_quantity_Create"]: {
		permission: boolean
};
	["TicketingFields_sessions_inventories_quantity_Read"]: {
		permission: boolean
};
	["TicketingFields_sessions_inventories_quantity_Update"]: {
		permission: boolean
};
	["TicketingFields_sessions_inventories_quantity_Delete"]: {
		permission: boolean
};
	["TicketingFields_sessions_inventories_unitPrice"]: {
		create?: ModelTypes["TicketingFields_sessions_inventories_unitPrice_Create"] | undefined,
	read?: ModelTypes["TicketingFields_sessions_inventories_unitPrice_Read"] | undefined,
	update?: ModelTypes["TicketingFields_sessions_inventories_unitPrice_Update"] | undefined,
	delete?: ModelTypes["TicketingFields_sessions_inventories_unitPrice_Delete"] | undefined
};
	["TicketingFields_sessions_inventories_unitPrice_Create"]: {
		permission: boolean
};
	["TicketingFields_sessions_inventories_unitPrice_Read"]: {
		permission: boolean
};
	["TicketingFields_sessions_inventories_unitPrice_Update"]: {
		permission: boolean
};
	["TicketingFields_sessions_inventories_unitPrice_Delete"]: {
		permission: boolean
};
	["TicketingFields_sessions_inventories_handlingFee"]: {
		create?: ModelTypes["TicketingFields_sessions_inventories_handlingFee_Create"] | undefined,
	read?: ModelTypes["TicketingFields_sessions_inventories_handlingFee_Read"] | undefined,
	update?: ModelTypes["TicketingFields_sessions_inventories_handlingFee_Update"] | undefined,
	delete?: ModelTypes["TicketingFields_sessions_inventories_handlingFee_Delete"] | undefined
};
	["TicketingFields_sessions_inventories_handlingFee_Create"]: {
		permission: boolean
};
	["TicketingFields_sessions_inventories_handlingFee_Read"]: {
		permission: boolean
};
	["TicketingFields_sessions_inventories_handlingFee_Update"]: {
		permission: boolean
};
	["TicketingFields_sessions_inventories_handlingFee_Delete"]: {
		permission: boolean
};
	["TicketingFields_sessions_inventories_totalCost"]: {
		create?: ModelTypes["TicketingFields_sessions_inventories_totalCost_Create"] | undefined,
	read?: ModelTypes["TicketingFields_sessions_inventories_totalCost_Read"] | undefined,
	update?: ModelTypes["TicketingFields_sessions_inventories_totalCost_Update"] | undefined,
	delete?: ModelTypes["TicketingFields_sessions_inventories_totalCost_Delete"] | undefined
};
	["TicketingFields_sessions_inventories_totalCost_Create"]: {
		permission: boolean
};
	["TicketingFields_sessions_inventories_totalCost_Read"]: {
		permission: boolean
};
	["TicketingFields_sessions_inventories_totalCost_Update"]: {
		permission: boolean
};
	["TicketingFields_sessions_inventories_totalCost_Delete"]: {
		permission: boolean
};
	["TicketingFields_sessions_inventories_minPurchase"]: {
		create?: ModelTypes["TicketingFields_sessions_inventories_minPurchase_Create"] | undefined,
	read?: ModelTypes["TicketingFields_sessions_inventories_minPurchase_Read"] | undefined,
	update?: ModelTypes["TicketingFields_sessions_inventories_minPurchase_Update"] | undefined,
	delete?: ModelTypes["TicketingFields_sessions_inventories_minPurchase_Delete"] | undefined
};
	["TicketingFields_sessions_inventories_minPurchase_Create"]: {
		permission: boolean
};
	["TicketingFields_sessions_inventories_minPurchase_Read"]: {
		permission: boolean
};
	["TicketingFields_sessions_inventories_minPurchase_Update"]: {
		permission: boolean
};
	["TicketingFields_sessions_inventories_minPurchase_Delete"]: {
		permission: boolean
};
	["TicketingFields_sessions_inventories_maxPurchase"]: {
		create?: ModelTypes["TicketingFields_sessions_inventories_maxPurchase_Create"] | undefined,
	read?: ModelTypes["TicketingFields_sessions_inventories_maxPurchase_Read"] | undefined,
	update?: ModelTypes["TicketingFields_sessions_inventories_maxPurchase_Update"] | undefined,
	delete?: ModelTypes["TicketingFields_sessions_inventories_maxPurchase_Delete"] | undefined
};
	["TicketingFields_sessions_inventories_maxPurchase_Create"]: {
		permission: boolean
};
	["TicketingFields_sessions_inventories_maxPurchase_Read"]: {
		permission: boolean
};
	["TicketingFields_sessions_inventories_maxPurchase_Update"]: {
		permission: boolean
};
	["TicketingFields_sessions_inventories_maxPurchase_Delete"]: {
		permission: boolean
};
	["TicketingFields_sessions_inventories_stocks"]: {
		create?: ModelTypes["TicketingFields_sessions_inventories_stocks_Create"] | undefined,
	read?: ModelTypes["TicketingFields_sessions_inventories_stocks_Read"] | undefined,
	update?: ModelTypes["TicketingFields_sessions_inventories_stocks_Update"] | undefined,
	delete?: ModelTypes["TicketingFields_sessions_inventories_stocks_Delete"] | undefined,
	fields?: ModelTypes["TicketingFields_sessions_inventories_stocks_Fields"] | undefined
};
	["TicketingFields_sessions_inventories_stocks_Create"]: {
		permission: boolean
};
	["TicketingFields_sessions_inventories_stocks_Read"]: {
		permission: boolean
};
	["TicketingFields_sessions_inventories_stocks_Update"]: {
		permission: boolean
};
	["TicketingFields_sessions_inventories_stocks_Delete"]: {
		permission: boolean
};
	["TicketingFields_sessions_inventories_stocks_Fields"]: {
		stockId?: ModelTypes["TicketingFields_sessions_inventories_stocks_stockId"] | undefined,
	remaining?: ModelTypes["TicketingFields_sessions_inventories_stocks_remaining"] | undefined,
	sold?: ModelTypes["TicketingFields_sessions_inventories_stocks_sold"] | undefined,
	onHold?: ModelTypes["TicketingFields_sessions_inventories_stocks_onHold"] | undefined,
	checkedIn?: ModelTypes["TicketingFields_sessions_inventories_stocks_checkedIn"] | undefined
};
	["TicketingFields_sessions_inventories_stocks_stockId"]: {
		create?: ModelTypes["TicketingFields_sessions_inventories_stocks_stockId_Create"] | undefined,
	read?: ModelTypes["TicketingFields_sessions_inventories_stocks_stockId_Read"] | undefined,
	update?: ModelTypes["TicketingFields_sessions_inventories_stocks_stockId_Update"] | undefined,
	delete?: ModelTypes["TicketingFields_sessions_inventories_stocks_stockId_Delete"] | undefined
};
	["TicketingFields_sessions_inventories_stocks_stockId_Create"]: {
		permission: boolean
};
	["TicketingFields_sessions_inventories_stocks_stockId_Read"]: {
		permission: boolean
};
	["TicketingFields_sessions_inventories_stocks_stockId_Update"]: {
		permission: boolean
};
	["TicketingFields_sessions_inventories_stocks_stockId_Delete"]: {
		permission: boolean
};
	["TicketingFields_sessions_inventories_stocks_remaining"]: {
		create?: ModelTypes["TicketingFields_sessions_inventories_stocks_remaining_Create"] | undefined,
	read?: ModelTypes["TicketingFields_sessions_inventories_stocks_remaining_Read"] | undefined,
	update?: ModelTypes["TicketingFields_sessions_inventories_stocks_remaining_Update"] | undefined,
	delete?: ModelTypes["TicketingFields_sessions_inventories_stocks_remaining_Delete"] | undefined
};
	["TicketingFields_sessions_inventories_stocks_remaining_Create"]: {
		permission: boolean
};
	["TicketingFields_sessions_inventories_stocks_remaining_Read"]: {
		permission: boolean
};
	["TicketingFields_sessions_inventories_stocks_remaining_Update"]: {
		permission: boolean
};
	["TicketingFields_sessions_inventories_stocks_remaining_Delete"]: {
		permission: boolean
};
	["TicketingFields_sessions_inventories_stocks_sold"]: {
		create?: ModelTypes["TicketingFields_sessions_inventories_stocks_sold_Create"] | undefined,
	read?: ModelTypes["TicketingFields_sessions_inventories_stocks_sold_Read"] | undefined,
	update?: ModelTypes["TicketingFields_sessions_inventories_stocks_sold_Update"] | undefined,
	delete?: ModelTypes["TicketingFields_sessions_inventories_stocks_sold_Delete"] | undefined
};
	["TicketingFields_sessions_inventories_stocks_sold_Create"]: {
		permission: boolean
};
	["TicketingFields_sessions_inventories_stocks_sold_Read"]: {
		permission: boolean
};
	["TicketingFields_sessions_inventories_stocks_sold_Update"]: {
		permission: boolean
};
	["TicketingFields_sessions_inventories_stocks_sold_Delete"]: {
		permission: boolean
};
	["TicketingFields_sessions_inventories_stocks_onHold"]: {
		create?: ModelTypes["TicketingFields_sessions_inventories_stocks_onHold_Create"] | undefined,
	read?: ModelTypes["TicketingFields_sessions_inventories_stocks_onHold_Read"] | undefined,
	update?: ModelTypes["TicketingFields_sessions_inventories_stocks_onHold_Update"] | undefined,
	delete?: ModelTypes["TicketingFields_sessions_inventories_stocks_onHold_Delete"] | undefined
};
	["TicketingFields_sessions_inventories_stocks_onHold_Create"]: {
		permission: boolean
};
	["TicketingFields_sessions_inventories_stocks_onHold_Read"]: {
		permission: boolean
};
	["TicketingFields_sessions_inventories_stocks_onHold_Update"]: {
		permission: boolean
};
	["TicketingFields_sessions_inventories_stocks_onHold_Delete"]: {
		permission: boolean
};
	["TicketingFields_sessions_inventories_stocks_checkedIn"]: {
		create?: ModelTypes["TicketingFields_sessions_inventories_stocks_checkedIn_Create"] | undefined,
	read?: ModelTypes["TicketingFields_sessions_inventories_stocks_checkedIn_Read"] | undefined,
	update?: ModelTypes["TicketingFields_sessions_inventories_stocks_checkedIn_Update"] | undefined,
	delete?: ModelTypes["TicketingFields_sessions_inventories_stocks_checkedIn_Delete"] | undefined
};
	["TicketingFields_sessions_inventories_stocks_checkedIn_Create"]: {
		permission: boolean
};
	["TicketingFields_sessions_inventories_stocks_checkedIn_Read"]: {
		permission: boolean
};
	["TicketingFields_sessions_inventories_stocks_checkedIn_Update"]: {
		permission: boolean
};
	["TicketingFields_sessions_inventories_stocks_checkedIn_Delete"]: {
		permission: boolean
};
	["TicketingFields_sessions_inventories_remarks"]: {
		create?: ModelTypes["TicketingFields_sessions_inventories_remarks_Create"] | undefined,
	read?: ModelTypes["TicketingFields_sessions_inventories_remarks_Read"] | undefined,
	update?: ModelTypes["TicketingFields_sessions_inventories_remarks_Update"] | undefined,
	delete?: ModelTypes["TicketingFields_sessions_inventories_remarks_Delete"] | undefined
};
	["TicketingFields_sessions_inventories_remarks_Create"]: {
		permission: boolean
};
	["TicketingFields_sessions_inventories_remarks_Read"]: {
		permission: boolean
};
	["TicketingFields_sessions_inventories_remarks_Update"]: {
		permission: boolean
};
	["TicketingFields_sessions_inventories_remarks_Delete"]: {
		permission: boolean
};
	["TicketingFields_sessions_id"]: {
		create?: ModelTypes["TicketingFields_sessions_id_Create"] | undefined,
	read?: ModelTypes["TicketingFields_sessions_id_Read"] | undefined,
	update?: ModelTypes["TicketingFields_sessions_id_Update"] | undefined,
	delete?: ModelTypes["TicketingFields_sessions_id_Delete"] | undefined
};
	["TicketingFields_sessions_id_Create"]: {
		permission: boolean
};
	["TicketingFields_sessions_id_Read"]: {
		permission: boolean
};
	["TicketingFields_sessions_id_Update"]: {
		permission: boolean
};
	["TicketingFields_sessions_id_Delete"]: {
		permission: boolean
};
	["TicketingFields_updatedAt"]: {
		create?: ModelTypes["TicketingFields_updatedAt_Create"] | undefined,
	read?: ModelTypes["TicketingFields_updatedAt_Read"] | undefined,
	update?: ModelTypes["TicketingFields_updatedAt_Update"] | undefined,
	delete?: ModelTypes["TicketingFields_updatedAt_Delete"] | undefined
};
	["TicketingFields_updatedAt_Create"]: {
		permission: boolean
};
	["TicketingFields_updatedAt_Read"]: {
		permission: boolean
};
	["TicketingFields_updatedAt_Update"]: {
		permission: boolean
};
	["TicketingFields_updatedAt_Delete"]: {
		permission: boolean
};
	["TicketingFields_createdAt"]: {
		create?: ModelTypes["TicketingFields_createdAt_Create"] | undefined,
	read?: ModelTypes["TicketingFields_createdAt_Read"] | undefined,
	update?: ModelTypes["TicketingFields_createdAt_Update"] | undefined,
	delete?: ModelTypes["TicketingFields_createdAt_Delete"] | undefined
};
	["TicketingFields_createdAt_Create"]: {
		permission: boolean
};
	["TicketingFields_createdAt_Read"]: {
		permission: boolean
};
	["TicketingFields_createdAt_Update"]: {
		permission: boolean
};
	["TicketingFields_createdAt_Delete"]: {
		permission: boolean
};
	["TicketingCreateAccess"]: {
		permission: boolean,
	where?: ModelTypes["JSONObject"] | undefined
};
	["TicketingReadAccess"]: {
		permission: boolean,
	where?: ModelTypes["JSONObject"] | undefined
};
	["TicketingUpdateAccess"]: {
		permission: boolean,
	where?: ModelTypes["JSONObject"] | undefined
};
	["TicketingDeleteAccess"]: {
		permission: boolean,
	where?: ModelTypes["JSONObject"] | undefined
};
	["productsAccess"]: {
		fields?: ModelTypes["ProductsFields"] | undefined,
	create?: ModelTypes["ProductsCreateAccess"] | undefined,
	read?: ModelTypes["ProductsReadAccess"] | undefined,
	update?: ModelTypes["ProductsUpdateAccess"] | undefined,
	delete?: ModelTypes["ProductsDeleteAccess"] | undefined
};
	["ProductsFields"]: {
		status?: ModelTypes["ProductsFields_status"] | undefined,
	creator?: ModelTypes["ProductsFields_creator"] | undefined,
	productType?: ModelTypes["ProductsFields_productType"] | undefined,
	productName?: ModelTypes["ProductsFields_productName"] | undefined,
	referenceId?: ModelTypes["ProductsFields_referenceId"] | undefined,
	linkedEvent?: ModelTypes["ProductsFields_linkedEvent"] | undefined,
	CheckInLevel?: ModelTypes["ProductsFields_CheckInLevel"] | undefined,
	thumbnail?: ModelTypes["ProductsFields_thumbnail"] | undefined,
	displayPrice?: ModelTypes["ProductsFields_displayPrice"] | undefined,
	transactionType?: ModelTypes["ProductsFields_transactionType"] | undefined,
	website?: ModelTypes["ProductsFields_website"] | undefined,
	tnc?: ModelTypes["ProductsFields_tnc"] | undefined,
	remarks?: ModelTypes["ProductsFields_remarks"] | undefined,
	sessions?: ModelTypes["ProductsFields_sessions"] | undefined,
	updatedAt?: ModelTypes["ProductsFields_updatedAt"] | undefined,
	createdAt?: ModelTypes["ProductsFields_createdAt"] | undefined
};
	["ProductsFields_status"]: {
		create?: ModelTypes["ProductsFields_status_Create"] | undefined,
	read?: ModelTypes["ProductsFields_status_Read"] | undefined,
	update?: ModelTypes["ProductsFields_status_Update"] | undefined,
	delete?: ModelTypes["ProductsFields_status_Delete"] | undefined
};
	["ProductsFields_status_Create"]: {
		permission: boolean
};
	["ProductsFields_status_Read"]: {
		permission: boolean
};
	["ProductsFields_status_Update"]: {
		permission: boolean
};
	["ProductsFields_status_Delete"]: {
		permission: boolean
};
	["ProductsFields_creator"]: {
		create?: ModelTypes["ProductsFields_creator_Create"] | undefined,
	read?: ModelTypes["ProductsFields_creator_Read"] | undefined,
	update?: ModelTypes["ProductsFields_creator_Update"] | undefined,
	delete?: ModelTypes["ProductsFields_creator_Delete"] | undefined
};
	["ProductsFields_creator_Create"]: {
		permission: boolean
};
	["ProductsFields_creator_Read"]: {
		permission: boolean
};
	["ProductsFields_creator_Update"]: {
		permission: boolean
};
	["ProductsFields_creator_Delete"]: {
		permission: boolean
};
	["ProductsFields_productType"]: {
		create?: ModelTypes["ProductsFields_productType_Create"] | undefined,
	read?: ModelTypes["ProductsFields_productType_Read"] | undefined,
	update?: ModelTypes["ProductsFields_productType_Update"] | undefined,
	delete?: ModelTypes["ProductsFields_productType_Delete"] | undefined
};
	["ProductsFields_productType_Create"]: {
		permission: boolean
};
	["ProductsFields_productType_Read"]: {
		permission: boolean
};
	["ProductsFields_productType_Update"]: {
		permission: boolean
};
	["ProductsFields_productType_Delete"]: {
		permission: boolean
};
	["ProductsFields_productName"]: {
		create?: ModelTypes["ProductsFields_productName_Create"] | undefined,
	read?: ModelTypes["ProductsFields_productName_Read"] | undefined,
	update?: ModelTypes["ProductsFields_productName_Update"] | undefined,
	delete?: ModelTypes["ProductsFields_productName_Delete"] | undefined
};
	["ProductsFields_productName_Create"]: {
		permission: boolean
};
	["ProductsFields_productName_Read"]: {
		permission: boolean
};
	["ProductsFields_productName_Update"]: {
		permission: boolean
};
	["ProductsFields_productName_Delete"]: {
		permission: boolean
};
	["ProductsFields_referenceId"]: {
		create?: ModelTypes["ProductsFields_referenceId_Create"] | undefined,
	read?: ModelTypes["ProductsFields_referenceId_Read"] | undefined,
	update?: ModelTypes["ProductsFields_referenceId_Update"] | undefined,
	delete?: ModelTypes["ProductsFields_referenceId_Delete"] | undefined
};
	["ProductsFields_referenceId_Create"]: {
		permission: boolean
};
	["ProductsFields_referenceId_Read"]: {
		permission: boolean
};
	["ProductsFields_referenceId_Update"]: {
		permission: boolean
};
	["ProductsFields_referenceId_Delete"]: {
		permission: boolean
};
	["ProductsFields_linkedEvent"]: {
		create?: ModelTypes["ProductsFields_linkedEvent_Create"] | undefined,
	read?: ModelTypes["ProductsFields_linkedEvent_Read"] | undefined,
	update?: ModelTypes["ProductsFields_linkedEvent_Update"] | undefined,
	delete?: ModelTypes["ProductsFields_linkedEvent_Delete"] | undefined
};
	["ProductsFields_linkedEvent_Create"]: {
		permission: boolean
};
	["ProductsFields_linkedEvent_Read"]: {
		permission: boolean
};
	["ProductsFields_linkedEvent_Update"]: {
		permission: boolean
};
	["ProductsFields_linkedEvent_Delete"]: {
		permission: boolean
};
	["ProductsFields_CheckInLevel"]: {
		create?: ModelTypes["ProductsFields_CheckInLevel_Create"] | undefined,
	read?: ModelTypes["ProductsFields_CheckInLevel_Read"] | undefined,
	update?: ModelTypes["ProductsFields_CheckInLevel_Update"] | undefined,
	delete?: ModelTypes["ProductsFields_CheckInLevel_Delete"] | undefined
};
	["ProductsFields_CheckInLevel_Create"]: {
		permission: boolean
};
	["ProductsFields_CheckInLevel_Read"]: {
		permission: boolean
};
	["ProductsFields_CheckInLevel_Update"]: {
		permission: boolean
};
	["ProductsFields_CheckInLevel_Delete"]: {
		permission: boolean
};
	["ProductsFields_thumbnail"]: {
		create?: ModelTypes["ProductsFields_thumbnail_Create"] | undefined,
	read?: ModelTypes["ProductsFields_thumbnail_Read"] | undefined,
	update?: ModelTypes["ProductsFields_thumbnail_Update"] | undefined,
	delete?: ModelTypes["ProductsFields_thumbnail_Delete"] | undefined
};
	["ProductsFields_thumbnail_Create"]: {
		permission: boolean
};
	["ProductsFields_thumbnail_Read"]: {
		permission: boolean
};
	["ProductsFields_thumbnail_Update"]: {
		permission: boolean
};
	["ProductsFields_thumbnail_Delete"]: {
		permission: boolean
};
	["ProductsFields_displayPrice"]: {
		create?: ModelTypes["ProductsFields_displayPrice_Create"] | undefined,
	read?: ModelTypes["ProductsFields_displayPrice_Read"] | undefined,
	update?: ModelTypes["ProductsFields_displayPrice_Update"] | undefined,
	delete?: ModelTypes["ProductsFields_displayPrice_Delete"] | undefined
};
	["ProductsFields_displayPrice_Create"]: {
		permission: boolean
};
	["ProductsFields_displayPrice_Read"]: {
		permission: boolean
};
	["ProductsFields_displayPrice_Update"]: {
		permission: boolean
};
	["ProductsFields_displayPrice_Delete"]: {
		permission: boolean
};
	["ProductsFields_transactionType"]: {
		create?: ModelTypes["ProductsFields_transactionType_Create"] | undefined,
	read?: ModelTypes["ProductsFields_transactionType_Read"] | undefined,
	update?: ModelTypes["ProductsFields_transactionType_Update"] | undefined,
	delete?: ModelTypes["ProductsFields_transactionType_Delete"] | undefined
};
	["ProductsFields_transactionType_Create"]: {
		permission: boolean
};
	["ProductsFields_transactionType_Read"]: {
		permission: boolean
};
	["ProductsFields_transactionType_Update"]: {
		permission: boolean
};
	["ProductsFields_transactionType_Delete"]: {
		permission: boolean
};
	["ProductsFields_website"]: {
		create?: ModelTypes["ProductsFields_website_Create"] | undefined,
	read?: ModelTypes["ProductsFields_website_Read"] | undefined,
	update?: ModelTypes["ProductsFields_website_Update"] | undefined,
	delete?: ModelTypes["ProductsFields_website_Delete"] | undefined
};
	["ProductsFields_website_Create"]: {
		permission: boolean
};
	["ProductsFields_website_Read"]: {
		permission: boolean
};
	["ProductsFields_website_Update"]: {
		permission: boolean
};
	["ProductsFields_website_Delete"]: {
		permission: boolean
};
	["ProductsFields_tnc"]: {
		create?: ModelTypes["ProductsFields_tnc_Create"] | undefined,
	read?: ModelTypes["ProductsFields_tnc_Read"] | undefined,
	update?: ModelTypes["ProductsFields_tnc_Update"] | undefined,
	delete?: ModelTypes["ProductsFields_tnc_Delete"] | undefined
};
	["ProductsFields_tnc_Create"]: {
		permission: boolean
};
	["ProductsFields_tnc_Read"]: {
		permission: boolean
};
	["ProductsFields_tnc_Update"]: {
		permission: boolean
};
	["ProductsFields_tnc_Delete"]: {
		permission: boolean
};
	["ProductsFields_remarks"]: {
		create?: ModelTypes["ProductsFields_remarks_Create"] | undefined,
	read?: ModelTypes["ProductsFields_remarks_Read"] | undefined,
	update?: ModelTypes["ProductsFields_remarks_Update"] | undefined,
	delete?: ModelTypes["ProductsFields_remarks_Delete"] | undefined
};
	["ProductsFields_remarks_Create"]: {
		permission: boolean
};
	["ProductsFields_remarks_Read"]: {
		permission: boolean
};
	["ProductsFields_remarks_Update"]: {
		permission: boolean
};
	["ProductsFields_remarks_Delete"]: {
		permission: boolean
};
	["ProductsFields_sessions"]: {
		create?: ModelTypes["ProductsFields_sessions_Create"] | undefined,
	read?: ModelTypes["ProductsFields_sessions_Read"] | undefined,
	update?: ModelTypes["ProductsFields_sessions_Update"] | undefined,
	delete?: ModelTypes["ProductsFields_sessions_Delete"] | undefined,
	fields?: ModelTypes["ProductsFields_sessions_Fields"] | undefined
};
	["ProductsFields_sessions_Create"]: {
		permission: boolean
};
	["ProductsFields_sessions_Read"]: {
		permission: boolean
};
	["ProductsFields_sessions_Update"]: {
		permission: boolean
};
	["ProductsFields_sessions_Delete"]: {
		permission: boolean
};
	["ProductsFields_sessions_Fields"]: {
		productId?: ModelTypes["ProductsFields_sessions_productId"] | undefined,
	name?: ModelTypes["ProductsFields_sessions_name"] | undefined,
	inventories?: ModelTypes["ProductsFields_sessions_inventories"] | undefined,
	id?: ModelTypes["ProductsFields_sessions_id"] | undefined
};
	["ProductsFields_sessions_productId"]: {
		create?: ModelTypes["ProductsFields_sessions_productId_Create"] | undefined,
	read?: ModelTypes["ProductsFields_sessions_productId_Read"] | undefined,
	update?: ModelTypes["ProductsFields_sessions_productId_Update"] | undefined,
	delete?: ModelTypes["ProductsFields_sessions_productId_Delete"] | undefined
};
	["ProductsFields_sessions_productId_Create"]: {
		permission: boolean
};
	["ProductsFields_sessions_productId_Read"]: {
		permission: boolean
};
	["ProductsFields_sessions_productId_Update"]: {
		permission: boolean
};
	["ProductsFields_sessions_productId_Delete"]: {
		permission: boolean
};
	["ProductsFields_sessions_name"]: {
		create?: ModelTypes["ProductsFields_sessions_name_Create"] | undefined,
	read?: ModelTypes["ProductsFields_sessions_name_Read"] | undefined,
	update?: ModelTypes["ProductsFields_sessions_name_Update"] | undefined,
	delete?: ModelTypes["ProductsFields_sessions_name_Delete"] | undefined
};
	["ProductsFields_sessions_name_Create"]: {
		permission: boolean
};
	["ProductsFields_sessions_name_Read"]: {
		permission: boolean
};
	["ProductsFields_sessions_name_Update"]: {
		permission: boolean
};
	["ProductsFields_sessions_name_Delete"]: {
		permission: boolean
};
	["ProductsFields_sessions_inventories"]: {
		create?: ModelTypes["ProductsFields_sessions_inventories_Create"] | undefined,
	read?: ModelTypes["ProductsFields_sessions_inventories_Read"] | undefined,
	update?: ModelTypes["ProductsFields_sessions_inventories_Update"] | undefined,
	delete?: ModelTypes["ProductsFields_sessions_inventories_Delete"] | undefined,
	fields?: ModelTypes["ProductsFields_sessions_inventories_Fields"] | undefined
};
	["ProductsFields_sessions_inventories_Create"]: {
		permission: boolean
};
	["ProductsFields_sessions_inventories_Read"]: {
		permission: boolean
};
	["ProductsFields_sessions_inventories_Update"]: {
		permission: boolean
};
	["ProductsFields_sessions_inventories_Delete"]: {
		permission: boolean
};
	["ProductsFields_sessions_inventories_Fields"]: {
		quantity?: ModelTypes["ProductsFields_sessions_inventories_quantity"] | undefined,
	unitPrice?: ModelTypes["ProductsFields_sessions_inventories_unitPrice"] | undefined,
	handlingFee?: ModelTypes["ProductsFields_sessions_inventories_handlingFee"] | undefined,
	totalCost?: ModelTypes["ProductsFields_sessions_inventories_totalCost"] | undefined,
	minPurchase?: ModelTypes["ProductsFields_sessions_inventories_minPurchase"] | undefined,
	maxPurchase?: ModelTypes["ProductsFields_sessions_inventories_maxPurchase"] | undefined,
	stocks?: ModelTypes["ProductsFields_sessions_inventories_stocks"] | undefined
};
	["ProductsFields_sessions_inventories_quantity"]: {
		create?: ModelTypes["ProductsFields_sessions_inventories_quantity_Create"] | undefined,
	read?: ModelTypes["ProductsFields_sessions_inventories_quantity_Read"] | undefined,
	update?: ModelTypes["ProductsFields_sessions_inventories_quantity_Update"] | undefined,
	delete?: ModelTypes["ProductsFields_sessions_inventories_quantity_Delete"] | undefined
};
	["ProductsFields_sessions_inventories_quantity_Create"]: {
		permission: boolean
};
	["ProductsFields_sessions_inventories_quantity_Read"]: {
		permission: boolean
};
	["ProductsFields_sessions_inventories_quantity_Update"]: {
		permission: boolean
};
	["ProductsFields_sessions_inventories_quantity_Delete"]: {
		permission: boolean
};
	["ProductsFields_sessions_inventories_unitPrice"]: {
		create?: ModelTypes["ProductsFields_sessions_inventories_unitPrice_Create"] | undefined,
	read?: ModelTypes["ProductsFields_sessions_inventories_unitPrice_Read"] | undefined,
	update?: ModelTypes["ProductsFields_sessions_inventories_unitPrice_Update"] | undefined,
	delete?: ModelTypes["ProductsFields_sessions_inventories_unitPrice_Delete"] | undefined
};
	["ProductsFields_sessions_inventories_unitPrice_Create"]: {
		permission: boolean
};
	["ProductsFields_sessions_inventories_unitPrice_Read"]: {
		permission: boolean
};
	["ProductsFields_sessions_inventories_unitPrice_Update"]: {
		permission: boolean
};
	["ProductsFields_sessions_inventories_unitPrice_Delete"]: {
		permission: boolean
};
	["ProductsFields_sessions_inventories_handlingFee"]: {
		create?: ModelTypes["ProductsFields_sessions_inventories_handlingFee_Create"] | undefined,
	read?: ModelTypes["ProductsFields_sessions_inventories_handlingFee_Read"] | undefined,
	update?: ModelTypes["ProductsFields_sessions_inventories_handlingFee_Update"] | undefined,
	delete?: ModelTypes["ProductsFields_sessions_inventories_handlingFee_Delete"] | undefined
};
	["ProductsFields_sessions_inventories_handlingFee_Create"]: {
		permission: boolean
};
	["ProductsFields_sessions_inventories_handlingFee_Read"]: {
		permission: boolean
};
	["ProductsFields_sessions_inventories_handlingFee_Update"]: {
		permission: boolean
};
	["ProductsFields_sessions_inventories_handlingFee_Delete"]: {
		permission: boolean
};
	["ProductsFields_sessions_inventories_totalCost"]: {
		create?: ModelTypes["ProductsFields_sessions_inventories_totalCost_Create"] | undefined,
	read?: ModelTypes["ProductsFields_sessions_inventories_totalCost_Read"] | undefined,
	update?: ModelTypes["ProductsFields_sessions_inventories_totalCost_Update"] | undefined,
	delete?: ModelTypes["ProductsFields_sessions_inventories_totalCost_Delete"] | undefined
};
	["ProductsFields_sessions_inventories_totalCost_Create"]: {
		permission: boolean
};
	["ProductsFields_sessions_inventories_totalCost_Read"]: {
		permission: boolean
};
	["ProductsFields_sessions_inventories_totalCost_Update"]: {
		permission: boolean
};
	["ProductsFields_sessions_inventories_totalCost_Delete"]: {
		permission: boolean
};
	["ProductsFields_sessions_inventories_minPurchase"]: {
		create?: ModelTypes["ProductsFields_sessions_inventories_minPurchase_Create"] | undefined,
	read?: ModelTypes["ProductsFields_sessions_inventories_minPurchase_Read"] | undefined,
	update?: ModelTypes["ProductsFields_sessions_inventories_minPurchase_Update"] | undefined,
	delete?: ModelTypes["ProductsFields_sessions_inventories_minPurchase_Delete"] | undefined
};
	["ProductsFields_sessions_inventories_minPurchase_Create"]: {
		permission: boolean
};
	["ProductsFields_sessions_inventories_minPurchase_Read"]: {
		permission: boolean
};
	["ProductsFields_sessions_inventories_minPurchase_Update"]: {
		permission: boolean
};
	["ProductsFields_sessions_inventories_minPurchase_Delete"]: {
		permission: boolean
};
	["ProductsFields_sessions_inventories_maxPurchase"]: {
		create?: ModelTypes["ProductsFields_sessions_inventories_maxPurchase_Create"] | undefined,
	read?: ModelTypes["ProductsFields_sessions_inventories_maxPurchase_Read"] | undefined,
	update?: ModelTypes["ProductsFields_sessions_inventories_maxPurchase_Update"] | undefined,
	delete?: ModelTypes["ProductsFields_sessions_inventories_maxPurchase_Delete"] | undefined
};
	["ProductsFields_sessions_inventories_maxPurchase_Create"]: {
		permission: boolean
};
	["ProductsFields_sessions_inventories_maxPurchase_Read"]: {
		permission: boolean
};
	["ProductsFields_sessions_inventories_maxPurchase_Update"]: {
		permission: boolean
};
	["ProductsFields_sessions_inventories_maxPurchase_Delete"]: {
		permission: boolean
};
	["ProductsFields_sessions_inventories_stocks"]: {
		create?: ModelTypes["ProductsFields_sessions_inventories_stocks_Create"] | undefined,
	read?: ModelTypes["ProductsFields_sessions_inventories_stocks_Read"] | undefined,
	update?: ModelTypes["ProductsFields_sessions_inventories_stocks_Update"] | undefined,
	delete?: ModelTypes["ProductsFields_sessions_inventories_stocks_Delete"] | undefined,
	fields?: ModelTypes["ProductsFields_sessions_inventories_stocks_Fields"] | undefined
};
	["ProductsFields_sessions_inventories_stocks_Create"]: {
		permission: boolean
};
	["ProductsFields_sessions_inventories_stocks_Read"]: {
		permission: boolean
};
	["ProductsFields_sessions_inventories_stocks_Update"]: {
		permission: boolean
};
	["ProductsFields_sessions_inventories_stocks_Delete"]: {
		permission: boolean
};
	["ProductsFields_sessions_inventories_stocks_Fields"]: {
		stockId?: ModelTypes["ProductsFields_sessions_inventories_stocks_stockId"] | undefined,
	remaining?: ModelTypes["ProductsFields_sessions_inventories_stocks_remaining"] | undefined,
	sold?: ModelTypes["ProductsFields_sessions_inventories_stocks_sold"] | undefined,
	onHold?: ModelTypes["ProductsFields_sessions_inventories_stocks_onHold"] | undefined,
	checkedIn?: ModelTypes["ProductsFields_sessions_inventories_stocks_checkedIn"] | undefined
};
	["ProductsFields_sessions_inventories_stocks_stockId"]: {
		create?: ModelTypes["ProductsFields_sessions_inventories_stocks_stockId_Create"] | undefined,
	read?: ModelTypes["ProductsFields_sessions_inventories_stocks_stockId_Read"] | undefined,
	update?: ModelTypes["ProductsFields_sessions_inventories_stocks_stockId_Update"] | undefined,
	delete?: ModelTypes["ProductsFields_sessions_inventories_stocks_stockId_Delete"] | undefined
};
	["ProductsFields_sessions_inventories_stocks_stockId_Create"]: {
		permission: boolean
};
	["ProductsFields_sessions_inventories_stocks_stockId_Read"]: {
		permission: boolean
};
	["ProductsFields_sessions_inventories_stocks_stockId_Update"]: {
		permission: boolean
};
	["ProductsFields_sessions_inventories_stocks_stockId_Delete"]: {
		permission: boolean
};
	["ProductsFields_sessions_inventories_stocks_remaining"]: {
		create?: ModelTypes["ProductsFields_sessions_inventories_stocks_remaining_Create"] | undefined,
	read?: ModelTypes["ProductsFields_sessions_inventories_stocks_remaining_Read"] | undefined,
	update?: ModelTypes["ProductsFields_sessions_inventories_stocks_remaining_Update"] | undefined,
	delete?: ModelTypes["ProductsFields_sessions_inventories_stocks_remaining_Delete"] | undefined
};
	["ProductsFields_sessions_inventories_stocks_remaining_Create"]: {
		permission: boolean
};
	["ProductsFields_sessions_inventories_stocks_remaining_Read"]: {
		permission: boolean
};
	["ProductsFields_sessions_inventories_stocks_remaining_Update"]: {
		permission: boolean
};
	["ProductsFields_sessions_inventories_stocks_remaining_Delete"]: {
		permission: boolean
};
	["ProductsFields_sessions_inventories_stocks_sold"]: {
		create?: ModelTypes["ProductsFields_sessions_inventories_stocks_sold_Create"] | undefined,
	read?: ModelTypes["ProductsFields_sessions_inventories_stocks_sold_Read"] | undefined,
	update?: ModelTypes["ProductsFields_sessions_inventories_stocks_sold_Update"] | undefined,
	delete?: ModelTypes["ProductsFields_sessions_inventories_stocks_sold_Delete"] | undefined
};
	["ProductsFields_sessions_inventories_stocks_sold_Create"]: {
		permission: boolean
};
	["ProductsFields_sessions_inventories_stocks_sold_Read"]: {
		permission: boolean
};
	["ProductsFields_sessions_inventories_stocks_sold_Update"]: {
		permission: boolean
};
	["ProductsFields_sessions_inventories_stocks_sold_Delete"]: {
		permission: boolean
};
	["ProductsFields_sessions_inventories_stocks_onHold"]: {
		create?: ModelTypes["ProductsFields_sessions_inventories_stocks_onHold_Create"] | undefined,
	read?: ModelTypes["ProductsFields_sessions_inventories_stocks_onHold_Read"] | undefined,
	update?: ModelTypes["ProductsFields_sessions_inventories_stocks_onHold_Update"] | undefined,
	delete?: ModelTypes["ProductsFields_sessions_inventories_stocks_onHold_Delete"] | undefined
};
	["ProductsFields_sessions_inventories_stocks_onHold_Create"]: {
		permission: boolean
};
	["ProductsFields_sessions_inventories_stocks_onHold_Read"]: {
		permission: boolean
};
	["ProductsFields_sessions_inventories_stocks_onHold_Update"]: {
		permission: boolean
};
	["ProductsFields_sessions_inventories_stocks_onHold_Delete"]: {
		permission: boolean
};
	["ProductsFields_sessions_inventories_stocks_checkedIn"]: {
		create?: ModelTypes["ProductsFields_sessions_inventories_stocks_checkedIn_Create"] | undefined,
	read?: ModelTypes["ProductsFields_sessions_inventories_stocks_checkedIn_Read"] | undefined,
	update?: ModelTypes["ProductsFields_sessions_inventories_stocks_checkedIn_Update"] | undefined,
	delete?: ModelTypes["ProductsFields_sessions_inventories_stocks_checkedIn_Delete"] | undefined
};
	["ProductsFields_sessions_inventories_stocks_checkedIn_Create"]: {
		permission: boolean
};
	["ProductsFields_sessions_inventories_stocks_checkedIn_Read"]: {
		permission: boolean
};
	["ProductsFields_sessions_inventories_stocks_checkedIn_Update"]: {
		permission: boolean
};
	["ProductsFields_sessions_inventories_stocks_checkedIn_Delete"]: {
		permission: boolean
};
	["ProductsFields_sessions_id"]: {
		create?: ModelTypes["ProductsFields_sessions_id_Create"] | undefined,
	read?: ModelTypes["ProductsFields_sessions_id_Read"] | undefined,
	update?: ModelTypes["ProductsFields_sessions_id_Update"] | undefined,
	delete?: ModelTypes["ProductsFields_sessions_id_Delete"] | undefined
};
	["ProductsFields_sessions_id_Create"]: {
		permission: boolean
};
	["ProductsFields_sessions_id_Read"]: {
		permission: boolean
};
	["ProductsFields_sessions_id_Update"]: {
		permission: boolean
};
	["ProductsFields_sessions_id_Delete"]: {
		permission: boolean
};
	["ProductsFields_updatedAt"]: {
		create?: ModelTypes["ProductsFields_updatedAt_Create"] | undefined,
	read?: ModelTypes["ProductsFields_updatedAt_Read"] | undefined,
	update?: ModelTypes["ProductsFields_updatedAt_Update"] | undefined,
	delete?: ModelTypes["ProductsFields_updatedAt_Delete"] | undefined
};
	["ProductsFields_updatedAt_Create"]: {
		permission: boolean
};
	["ProductsFields_updatedAt_Read"]: {
		permission: boolean
};
	["ProductsFields_updatedAt_Update"]: {
		permission: boolean
};
	["ProductsFields_updatedAt_Delete"]: {
		permission: boolean
};
	["ProductsFields_createdAt"]: {
		create?: ModelTypes["ProductsFields_createdAt_Create"] | undefined,
	read?: ModelTypes["ProductsFields_createdAt_Read"] | undefined,
	update?: ModelTypes["ProductsFields_createdAt_Update"] | undefined,
	delete?: ModelTypes["ProductsFields_createdAt_Delete"] | undefined
};
	["ProductsFields_createdAt_Create"]: {
		permission: boolean
};
	["ProductsFields_createdAt_Read"]: {
		permission: boolean
};
	["ProductsFields_createdAt_Update"]: {
		permission: boolean
};
	["ProductsFields_createdAt_Delete"]: {
		permission: boolean
};
	["ProductsCreateAccess"]: {
		permission: boolean,
	where?: ModelTypes["JSONObject"] | undefined
};
	["ProductsReadAccess"]: {
		permission: boolean,
	where?: ModelTypes["JSONObject"] | undefined
};
	["ProductsUpdateAccess"]: {
		permission: boolean,
	where?: ModelTypes["JSONObject"] | undefined
};
	["ProductsDeleteAccess"]: {
		permission: boolean,
	where?: ModelTypes["JSONObject"] | undefined
};
	["inventory_statusAccess"]: {
		fields?: ModelTypes["InventoryStatusFields"] | undefined,
	create?: ModelTypes["InventoryStatusCreateAccess"] | undefined,
	read?: ModelTypes["InventoryStatusReadAccess"] | undefined,
	update?: ModelTypes["InventoryStatusUpdateAccess"] | undefined,
	delete?: ModelTypes["InventoryStatusDeleteAccess"] | undefined
};
	["InventoryStatusFields"]: {
		variantId?: ModelTypes["InventoryStatusFields_variantId"] | undefined,
	variantName?: ModelTypes["InventoryStatusFields_variantName"] | undefined,
	productType?: ModelTypes["InventoryStatusFields_productType"] | undefined,
	stockUpdateStatus?: ModelTypes["InventoryStatusFields_stockUpdateStatus"] | undefined,
	stockUpdateAmount?: ModelTypes["InventoryStatusFields_stockUpdateAmount"] | undefined,
	user?: ModelTypes["InventoryStatusFields_user"] | undefined,
	publicUserPhone?: ModelTypes["InventoryStatusFields_publicUserPhone"] | undefined,
	expiredAt?: ModelTypes["InventoryStatusFields_expiredAt"] | undefined,
	updatedAt?: ModelTypes["InventoryStatusFields_updatedAt"] | undefined,
	createdAt?: ModelTypes["InventoryStatusFields_createdAt"] | undefined
};
	["InventoryStatusFields_variantId"]: {
		create?: ModelTypes["InventoryStatusFields_variantId_Create"] | undefined,
	read?: ModelTypes["InventoryStatusFields_variantId_Read"] | undefined,
	update?: ModelTypes["InventoryStatusFields_variantId_Update"] | undefined,
	delete?: ModelTypes["InventoryStatusFields_variantId_Delete"] | undefined
};
	["InventoryStatusFields_variantId_Create"]: {
		permission: boolean
};
	["InventoryStatusFields_variantId_Read"]: {
		permission: boolean
};
	["InventoryStatusFields_variantId_Update"]: {
		permission: boolean
};
	["InventoryStatusFields_variantId_Delete"]: {
		permission: boolean
};
	["InventoryStatusFields_variantName"]: {
		create?: ModelTypes["InventoryStatusFields_variantName_Create"] | undefined,
	read?: ModelTypes["InventoryStatusFields_variantName_Read"] | undefined,
	update?: ModelTypes["InventoryStatusFields_variantName_Update"] | undefined,
	delete?: ModelTypes["InventoryStatusFields_variantName_Delete"] | undefined
};
	["InventoryStatusFields_variantName_Create"]: {
		permission: boolean
};
	["InventoryStatusFields_variantName_Read"]: {
		permission: boolean
};
	["InventoryStatusFields_variantName_Update"]: {
		permission: boolean
};
	["InventoryStatusFields_variantName_Delete"]: {
		permission: boolean
};
	["InventoryStatusFields_productType"]: {
		create?: ModelTypes["InventoryStatusFields_productType_Create"] | undefined,
	read?: ModelTypes["InventoryStatusFields_productType_Read"] | undefined,
	update?: ModelTypes["InventoryStatusFields_productType_Update"] | undefined,
	delete?: ModelTypes["InventoryStatusFields_productType_Delete"] | undefined
};
	["InventoryStatusFields_productType_Create"]: {
		permission: boolean
};
	["InventoryStatusFields_productType_Read"]: {
		permission: boolean
};
	["InventoryStatusFields_productType_Update"]: {
		permission: boolean
};
	["InventoryStatusFields_productType_Delete"]: {
		permission: boolean
};
	["InventoryStatusFields_stockUpdateStatus"]: {
		create?: ModelTypes["InventoryStatusFields_stockUpdateStatus_Create"] | undefined,
	read?: ModelTypes["InventoryStatusFields_stockUpdateStatus_Read"] | undefined,
	update?: ModelTypes["InventoryStatusFields_stockUpdateStatus_Update"] | undefined,
	delete?: ModelTypes["InventoryStatusFields_stockUpdateStatus_Delete"] | undefined
};
	["InventoryStatusFields_stockUpdateStatus_Create"]: {
		permission: boolean
};
	["InventoryStatusFields_stockUpdateStatus_Read"]: {
		permission: boolean
};
	["InventoryStatusFields_stockUpdateStatus_Update"]: {
		permission: boolean
};
	["InventoryStatusFields_stockUpdateStatus_Delete"]: {
		permission: boolean
};
	["InventoryStatusFields_stockUpdateAmount"]: {
		create?: ModelTypes["InventoryStatusFields_stockUpdateAmount_Create"] | undefined,
	read?: ModelTypes["InventoryStatusFields_stockUpdateAmount_Read"] | undefined,
	update?: ModelTypes["InventoryStatusFields_stockUpdateAmount_Update"] | undefined,
	delete?: ModelTypes["InventoryStatusFields_stockUpdateAmount_Delete"] | undefined
};
	["InventoryStatusFields_stockUpdateAmount_Create"]: {
		permission: boolean
};
	["InventoryStatusFields_stockUpdateAmount_Read"]: {
		permission: boolean
};
	["InventoryStatusFields_stockUpdateAmount_Update"]: {
		permission: boolean
};
	["InventoryStatusFields_stockUpdateAmount_Delete"]: {
		permission: boolean
};
	["InventoryStatusFields_user"]: {
		create?: ModelTypes["InventoryStatusFields_user_Create"] | undefined,
	read?: ModelTypes["InventoryStatusFields_user_Read"] | undefined,
	update?: ModelTypes["InventoryStatusFields_user_Update"] | undefined,
	delete?: ModelTypes["InventoryStatusFields_user_Delete"] | undefined
};
	["InventoryStatusFields_user_Create"]: {
		permission: boolean
};
	["InventoryStatusFields_user_Read"]: {
		permission: boolean
};
	["InventoryStatusFields_user_Update"]: {
		permission: boolean
};
	["InventoryStatusFields_user_Delete"]: {
		permission: boolean
};
	["InventoryStatusFields_publicUserPhone"]: {
		create?: ModelTypes["InventoryStatusFields_publicUserPhone_Create"] | undefined,
	read?: ModelTypes["InventoryStatusFields_publicUserPhone_Read"] | undefined,
	update?: ModelTypes["InventoryStatusFields_publicUserPhone_Update"] | undefined,
	delete?: ModelTypes["InventoryStatusFields_publicUserPhone_Delete"] | undefined
};
	["InventoryStatusFields_publicUserPhone_Create"]: {
		permission: boolean
};
	["InventoryStatusFields_publicUserPhone_Read"]: {
		permission: boolean
};
	["InventoryStatusFields_publicUserPhone_Update"]: {
		permission: boolean
};
	["InventoryStatusFields_publicUserPhone_Delete"]: {
		permission: boolean
};
	["InventoryStatusFields_expiredAt"]: {
		create?: ModelTypes["InventoryStatusFields_expiredAt_Create"] | undefined,
	read?: ModelTypes["InventoryStatusFields_expiredAt_Read"] | undefined,
	update?: ModelTypes["InventoryStatusFields_expiredAt_Update"] | undefined,
	delete?: ModelTypes["InventoryStatusFields_expiredAt_Delete"] | undefined
};
	["InventoryStatusFields_expiredAt_Create"]: {
		permission: boolean
};
	["InventoryStatusFields_expiredAt_Read"]: {
		permission: boolean
};
	["InventoryStatusFields_expiredAt_Update"]: {
		permission: boolean
};
	["InventoryStatusFields_expiredAt_Delete"]: {
		permission: boolean
};
	["InventoryStatusFields_updatedAt"]: {
		create?: ModelTypes["InventoryStatusFields_updatedAt_Create"] | undefined,
	read?: ModelTypes["InventoryStatusFields_updatedAt_Read"] | undefined,
	update?: ModelTypes["InventoryStatusFields_updatedAt_Update"] | undefined,
	delete?: ModelTypes["InventoryStatusFields_updatedAt_Delete"] | undefined
};
	["InventoryStatusFields_updatedAt_Create"]: {
		permission: boolean
};
	["InventoryStatusFields_updatedAt_Read"]: {
		permission: boolean
};
	["InventoryStatusFields_updatedAt_Update"]: {
		permission: boolean
};
	["InventoryStatusFields_updatedAt_Delete"]: {
		permission: boolean
};
	["InventoryStatusFields_createdAt"]: {
		create?: ModelTypes["InventoryStatusFields_createdAt_Create"] | undefined,
	read?: ModelTypes["InventoryStatusFields_createdAt_Read"] | undefined,
	update?: ModelTypes["InventoryStatusFields_createdAt_Update"] | undefined,
	delete?: ModelTypes["InventoryStatusFields_createdAt_Delete"] | undefined
};
	["InventoryStatusFields_createdAt_Create"]: {
		permission: boolean
};
	["InventoryStatusFields_createdAt_Read"]: {
		permission: boolean
};
	["InventoryStatusFields_createdAt_Update"]: {
		permission: boolean
};
	["InventoryStatusFields_createdAt_Delete"]: {
		permission: boolean
};
	["InventoryStatusCreateAccess"]: {
		permission: boolean,
	where?: ModelTypes["JSONObject"] | undefined
};
	["InventoryStatusReadAccess"]: {
		permission: boolean,
	where?: ModelTypes["JSONObject"] | undefined
};
	["InventoryStatusUpdateAccess"]: {
		permission: boolean,
	where?: ModelTypes["JSONObject"] | undefined
};
	["InventoryStatusDeleteAccess"]: {
		permission: boolean,
	where?: ModelTypes["JSONObject"] | undefined
};
	["transactionsAccess"]: {
		fields?: ModelTypes["TransactionsFields"] | undefined,
	create?: ModelTypes["TransactionsCreateAccess"] | undefined,
	read?: ModelTypes["TransactionsReadAccess"] | undefined,
	update?: ModelTypes["TransactionsUpdateAccess"] | undefined,
	delete?: ModelTypes["TransactionsDeleteAccess"] | undefined
};
	["TransactionsFields"]: {
		creator?: ModelTypes["TransactionsFields_creator"] | undefined,
	productName?: ModelTypes["TransactionsFields_productName"] | undefined,
	referenceId?: ModelTypes["TransactionsFields_referenceId"] | undefined,
	linkedEvent?: ModelTypes["TransactionsFields_linkedEvent"] | undefined,
	purchaseDateTime?: ModelTypes["TransactionsFields_purchaseDateTime"] | undefined,
	timableOrderId?: ModelTypes["TransactionsFields_timableOrderId"] | undefined,
	stripePaymentId?: ModelTypes["TransactionsFields_stripePaymentId"] | undefined,
	totalAmount?: ModelTypes["TransactionsFields_totalAmount"] | undefined,
	contactPersonName?: ModelTypes["TransactionsFields_contactPersonName"] | undefined,
	contactPersonPhone?: ModelTypes["TransactionsFields_contactPersonPhone"] | undefined,
	remarks?: ModelTypes["TransactionsFields_remarks"] | undefined,
	redemptionCodes?: ModelTypes["TransactionsFields_redemptionCodes"] | undefined,
	updatedAt?: ModelTypes["TransactionsFields_updatedAt"] | undefined,
	createdAt?: ModelTypes["TransactionsFields_createdAt"] | undefined
};
	["TransactionsFields_creator"]: {
		create?: ModelTypes["TransactionsFields_creator_Create"] | undefined,
	read?: ModelTypes["TransactionsFields_creator_Read"] | undefined,
	update?: ModelTypes["TransactionsFields_creator_Update"] | undefined,
	delete?: ModelTypes["TransactionsFields_creator_Delete"] | undefined
};
	["TransactionsFields_creator_Create"]: {
		permission: boolean
};
	["TransactionsFields_creator_Read"]: {
		permission: boolean
};
	["TransactionsFields_creator_Update"]: {
		permission: boolean
};
	["TransactionsFields_creator_Delete"]: {
		permission: boolean
};
	["TransactionsFields_productName"]: {
		create?: ModelTypes["TransactionsFields_productName_Create"] | undefined,
	read?: ModelTypes["TransactionsFields_productName_Read"] | undefined,
	update?: ModelTypes["TransactionsFields_productName_Update"] | undefined,
	delete?: ModelTypes["TransactionsFields_productName_Delete"] | undefined
};
	["TransactionsFields_productName_Create"]: {
		permission: boolean
};
	["TransactionsFields_productName_Read"]: {
		permission: boolean
};
	["TransactionsFields_productName_Update"]: {
		permission: boolean
};
	["TransactionsFields_productName_Delete"]: {
		permission: boolean
};
	["TransactionsFields_referenceId"]: {
		create?: ModelTypes["TransactionsFields_referenceId_Create"] | undefined,
	read?: ModelTypes["TransactionsFields_referenceId_Read"] | undefined,
	update?: ModelTypes["TransactionsFields_referenceId_Update"] | undefined,
	delete?: ModelTypes["TransactionsFields_referenceId_Delete"] | undefined
};
	["TransactionsFields_referenceId_Create"]: {
		permission: boolean
};
	["TransactionsFields_referenceId_Read"]: {
		permission: boolean
};
	["TransactionsFields_referenceId_Update"]: {
		permission: boolean
};
	["TransactionsFields_referenceId_Delete"]: {
		permission: boolean
};
	["TransactionsFields_linkedEvent"]: {
		create?: ModelTypes["TransactionsFields_linkedEvent_Create"] | undefined,
	read?: ModelTypes["TransactionsFields_linkedEvent_Read"] | undefined,
	update?: ModelTypes["TransactionsFields_linkedEvent_Update"] | undefined,
	delete?: ModelTypes["TransactionsFields_linkedEvent_Delete"] | undefined
};
	["TransactionsFields_linkedEvent_Create"]: {
		permission: boolean
};
	["TransactionsFields_linkedEvent_Read"]: {
		permission: boolean
};
	["TransactionsFields_linkedEvent_Update"]: {
		permission: boolean
};
	["TransactionsFields_linkedEvent_Delete"]: {
		permission: boolean
};
	["TransactionsFields_purchaseDateTime"]: {
		create?: ModelTypes["TransactionsFields_purchaseDateTime_Create"] | undefined,
	read?: ModelTypes["TransactionsFields_purchaseDateTime_Read"] | undefined,
	update?: ModelTypes["TransactionsFields_purchaseDateTime_Update"] | undefined,
	delete?: ModelTypes["TransactionsFields_purchaseDateTime_Delete"] | undefined
};
	["TransactionsFields_purchaseDateTime_Create"]: {
		permission: boolean
};
	["TransactionsFields_purchaseDateTime_Read"]: {
		permission: boolean
};
	["TransactionsFields_purchaseDateTime_Update"]: {
		permission: boolean
};
	["TransactionsFields_purchaseDateTime_Delete"]: {
		permission: boolean
};
	["TransactionsFields_timableOrderId"]: {
		create?: ModelTypes["TransactionsFields_timableOrderId_Create"] | undefined,
	read?: ModelTypes["TransactionsFields_timableOrderId_Read"] | undefined,
	update?: ModelTypes["TransactionsFields_timableOrderId_Update"] | undefined,
	delete?: ModelTypes["TransactionsFields_timableOrderId_Delete"] | undefined
};
	["TransactionsFields_timableOrderId_Create"]: {
		permission: boolean
};
	["TransactionsFields_timableOrderId_Read"]: {
		permission: boolean
};
	["TransactionsFields_timableOrderId_Update"]: {
		permission: boolean
};
	["TransactionsFields_timableOrderId_Delete"]: {
		permission: boolean
};
	["TransactionsFields_stripePaymentId"]: {
		create?: ModelTypes["TransactionsFields_stripePaymentId_Create"] | undefined,
	read?: ModelTypes["TransactionsFields_stripePaymentId_Read"] | undefined,
	update?: ModelTypes["TransactionsFields_stripePaymentId_Update"] | undefined,
	delete?: ModelTypes["TransactionsFields_stripePaymentId_Delete"] | undefined
};
	["TransactionsFields_stripePaymentId_Create"]: {
		permission: boolean
};
	["TransactionsFields_stripePaymentId_Read"]: {
		permission: boolean
};
	["TransactionsFields_stripePaymentId_Update"]: {
		permission: boolean
};
	["TransactionsFields_stripePaymentId_Delete"]: {
		permission: boolean
};
	["TransactionsFields_totalAmount"]: {
		create?: ModelTypes["TransactionsFields_totalAmount_Create"] | undefined,
	read?: ModelTypes["TransactionsFields_totalAmount_Read"] | undefined,
	update?: ModelTypes["TransactionsFields_totalAmount_Update"] | undefined,
	delete?: ModelTypes["TransactionsFields_totalAmount_Delete"] | undefined
};
	["TransactionsFields_totalAmount_Create"]: {
		permission: boolean
};
	["TransactionsFields_totalAmount_Read"]: {
		permission: boolean
};
	["TransactionsFields_totalAmount_Update"]: {
		permission: boolean
};
	["TransactionsFields_totalAmount_Delete"]: {
		permission: boolean
};
	["TransactionsFields_contactPersonName"]: {
		create?: ModelTypes["TransactionsFields_contactPersonName_Create"] | undefined,
	read?: ModelTypes["TransactionsFields_contactPersonName_Read"] | undefined,
	update?: ModelTypes["TransactionsFields_contactPersonName_Update"] | undefined,
	delete?: ModelTypes["TransactionsFields_contactPersonName_Delete"] | undefined
};
	["TransactionsFields_contactPersonName_Create"]: {
		permission: boolean
};
	["TransactionsFields_contactPersonName_Read"]: {
		permission: boolean
};
	["TransactionsFields_contactPersonName_Update"]: {
		permission: boolean
};
	["TransactionsFields_contactPersonName_Delete"]: {
		permission: boolean
};
	["TransactionsFields_contactPersonPhone"]: {
		create?: ModelTypes["TransactionsFields_contactPersonPhone_Create"] | undefined,
	read?: ModelTypes["TransactionsFields_contactPersonPhone_Read"] | undefined,
	update?: ModelTypes["TransactionsFields_contactPersonPhone_Update"] | undefined,
	delete?: ModelTypes["TransactionsFields_contactPersonPhone_Delete"] | undefined
};
	["TransactionsFields_contactPersonPhone_Create"]: {
		permission: boolean
};
	["TransactionsFields_contactPersonPhone_Read"]: {
		permission: boolean
};
	["TransactionsFields_contactPersonPhone_Update"]: {
		permission: boolean
};
	["TransactionsFields_contactPersonPhone_Delete"]: {
		permission: boolean
};
	["TransactionsFields_remarks"]: {
		create?: ModelTypes["TransactionsFields_remarks_Create"] | undefined,
	read?: ModelTypes["TransactionsFields_remarks_Read"] | undefined,
	update?: ModelTypes["TransactionsFields_remarks_Update"] | undefined,
	delete?: ModelTypes["TransactionsFields_remarks_Delete"] | undefined
};
	["TransactionsFields_remarks_Create"]: {
		permission: boolean
};
	["TransactionsFields_remarks_Read"]: {
		permission: boolean
};
	["TransactionsFields_remarks_Update"]: {
		permission: boolean
};
	["TransactionsFields_remarks_Delete"]: {
		permission: boolean
};
	["TransactionsFields_redemptionCodes"]: {
		create?: ModelTypes["TransactionsFields_redemptionCodes_Create"] | undefined,
	read?: ModelTypes["TransactionsFields_redemptionCodes_Read"] | undefined,
	update?: ModelTypes["TransactionsFields_redemptionCodes_Update"] | undefined,
	delete?: ModelTypes["TransactionsFields_redemptionCodes_Delete"] | undefined,
	fields?: ModelTypes["TransactionsFields_redemptionCodes_Fields"] | undefined
};
	["TransactionsFields_redemptionCodes_Create"]: {
		permission: boolean
};
	["TransactionsFields_redemptionCodes_Read"]: {
		permission: boolean
};
	["TransactionsFields_redemptionCodes_Update"]: {
		permission: boolean
};
	["TransactionsFields_redemptionCodes_Delete"]: {
		permission: boolean
};
	["TransactionsFields_redemptionCodes_Fields"]: {
		code?: ModelTypes["TransactionsFields_redemptionCodes_code"] | undefined,
	id?: ModelTypes["TransactionsFields_redemptionCodes_id"] | undefined
};
	["TransactionsFields_redemptionCodes_code"]: {
		create?: ModelTypes["TransactionsFields_redemptionCodes_code_Create"] | undefined,
	read?: ModelTypes["TransactionsFields_redemptionCodes_code_Read"] | undefined,
	update?: ModelTypes["TransactionsFields_redemptionCodes_code_Update"] | undefined,
	delete?: ModelTypes["TransactionsFields_redemptionCodes_code_Delete"] | undefined
};
	["TransactionsFields_redemptionCodes_code_Create"]: {
		permission: boolean
};
	["TransactionsFields_redemptionCodes_code_Read"]: {
		permission: boolean
};
	["TransactionsFields_redemptionCodes_code_Update"]: {
		permission: boolean
};
	["TransactionsFields_redemptionCodes_code_Delete"]: {
		permission: boolean
};
	["TransactionsFields_redemptionCodes_id"]: {
		create?: ModelTypes["TransactionsFields_redemptionCodes_id_Create"] | undefined,
	read?: ModelTypes["TransactionsFields_redemptionCodes_id_Read"] | undefined,
	update?: ModelTypes["TransactionsFields_redemptionCodes_id_Update"] | undefined,
	delete?: ModelTypes["TransactionsFields_redemptionCodes_id_Delete"] | undefined
};
	["TransactionsFields_redemptionCodes_id_Create"]: {
		permission: boolean
};
	["TransactionsFields_redemptionCodes_id_Read"]: {
		permission: boolean
};
	["TransactionsFields_redemptionCodes_id_Update"]: {
		permission: boolean
};
	["TransactionsFields_redemptionCodes_id_Delete"]: {
		permission: boolean
};
	["TransactionsFields_updatedAt"]: {
		create?: ModelTypes["TransactionsFields_updatedAt_Create"] | undefined,
	read?: ModelTypes["TransactionsFields_updatedAt_Read"] | undefined,
	update?: ModelTypes["TransactionsFields_updatedAt_Update"] | undefined,
	delete?: ModelTypes["TransactionsFields_updatedAt_Delete"] | undefined
};
	["TransactionsFields_updatedAt_Create"]: {
		permission: boolean
};
	["TransactionsFields_updatedAt_Read"]: {
		permission: boolean
};
	["TransactionsFields_updatedAt_Update"]: {
		permission: boolean
};
	["TransactionsFields_updatedAt_Delete"]: {
		permission: boolean
};
	["TransactionsFields_createdAt"]: {
		create?: ModelTypes["TransactionsFields_createdAt_Create"] | undefined,
	read?: ModelTypes["TransactionsFields_createdAt_Read"] | undefined,
	update?: ModelTypes["TransactionsFields_createdAt_Update"] | undefined,
	delete?: ModelTypes["TransactionsFields_createdAt_Delete"] | undefined
};
	["TransactionsFields_createdAt_Create"]: {
		permission: boolean
};
	["TransactionsFields_createdAt_Read"]: {
		permission: boolean
};
	["TransactionsFields_createdAt_Update"]: {
		permission: boolean
};
	["TransactionsFields_createdAt_Delete"]: {
		permission: boolean
};
	["TransactionsCreateAccess"]: {
		permission: boolean,
	where?: ModelTypes["JSONObject"] | undefined
};
	["TransactionsReadAccess"]: {
		permission: boolean,
	where?: ModelTypes["JSONObject"] | undefined
};
	["TransactionsUpdateAccess"]: {
		permission: boolean,
	where?: ModelTypes["JSONObject"] | undefined
};
	["TransactionsDeleteAccess"]: {
		permission: boolean,
	where?: ModelTypes["JSONObject"] | undefined
};
	["redemption_codesAccess"]: {
		fields?: ModelTypes["RedemptionCodesFields"] | undefined,
	create?: ModelTypes["RedemptionCodesCreateAccess"] | undefined,
	read?: ModelTypes["RedemptionCodesReadAccess"] | undefined,
	update?: ModelTypes["RedemptionCodesUpdateAccess"] | undefined,
	delete?: ModelTypes["RedemptionCodesDeleteAccess"] | undefined
};
	["RedemptionCodesFields"]: {
		creator?: ModelTypes["RedemptionCodesFields_creator"] | undefined,
	status?: ModelTypes["RedemptionCodesFields_status"] | undefined,
	qrCodeId?: ModelTypes["RedemptionCodesFields_qrCodeId"] | undefined,
	lastCheckedInDateTime?: ModelTypes["RedemptionCodesFields_lastCheckedInDateTime"] | undefined,
	voidedDateTime?: ModelTypes["RedemptionCodesFields_voidedDateTime"] | undefined,
	refundedDateTime?: ModelTypes["RedemptionCodesFields_refundedDateTime"] | undefined,
	eventStartTime?: ModelTypes["RedemptionCodesFields_eventStartTime"] | undefined,
	eventEndTime?: ModelTypes["RedemptionCodesFields_eventEndTime"] | undefined,
	eventPriceZone?: ModelTypes["RedemptionCodesFields_eventPriceZone"] | undefined,
	ownerName?: ModelTypes["RedemptionCodesFields_ownerName"] | undefined,
	phone?: ModelTypes["RedemptionCodesFields_phone"] | undefined,
	pdf?: ModelTypes["RedemptionCodesFields_pdf"] | undefined,
	variants?: ModelTypes["RedemptionCodesFields_variants"] | undefined,
	productId?: ModelTypes["RedemptionCodesFields_productId"] | undefined,
	referenceId?: ModelTypes["RedemptionCodesFields_referenceId"] | undefined,
	productType?: ModelTypes["RedemptionCodesFields_productType"] | undefined,
	productName?: ModelTypes["RedemptionCodesFields_productName"] | undefined,
	linkedEvent?: ModelTypes["RedemptionCodesFields_linkedEvent"] | undefined,
	timableOrderId?: ModelTypes["RedemptionCodesFields_timableOrderId"] | undefined,
	purchaseDateTime?: ModelTypes["RedemptionCodesFields_purchaseDateTime"] | undefined,
	changeHistory?: ModelTypes["RedemptionCodesFields_changeHistory"] | undefined,
	updatedAt?: ModelTypes["RedemptionCodesFields_updatedAt"] | undefined,
	createdAt?: ModelTypes["RedemptionCodesFields_createdAt"] | undefined
};
	["RedemptionCodesFields_creator"]: {
		create?: ModelTypes["RedemptionCodesFields_creator_Create"] | undefined,
	read?: ModelTypes["RedemptionCodesFields_creator_Read"] | undefined,
	update?: ModelTypes["RedemptionCodesFields_creator_Update"] | undefined,
	delete?: ModelTypes["RedemptionCodesFields_creator_Delete"] | undefined
};
	["RedemptionCodesFields_creator_Create"]: {
		permission: boolean
};
	["RedemptionCodesFields_creator_Read"]: {
		permission: boolean
};
	["RedemptionCodesFields_creator_Update"]: {
		permission: boolean
};
	["RedemptionCodesFields_creator_Delete"]: {
		permission: boolean
};
	["RedemptionCodesFields_status"]: {
		create?: ModelTypes["RedemptionCodesFields_status_Create"] | undefined,
	read?: ModelTypes["RedemptionCodesFields_status_Read"] | undefined,
	update?: ModelTypes["RedemptionCodesFields_status_Update"] | undefined,
	delete?: ModelTypes["RedemptionCodesFields_status_Delete"] | undefined
};
	["RedemptionCodesFields_status_Create"]: {
		permission: boolean
};
	["RedemptionCodesFields_status_Read"]: {
		permission: boolean
};
	["RedemptionCodesFields_status_Update"]: {
		permission: boolean
};
	["RedemptionCodesFields_status_Delete"]: {
		permission: boolean
};
	["RedemptionCodesFields_qrCodeId"]: {
		create?: ModelTypes["RedemptionCodesFields_qrCodeId_Create"] | undefined,
	read?: ModelTypes["RedemptionCodesFields_qrCodeId_Read"] | undefined,
	update?: ModelTypes["RedemptionCodesFields_qrCodeId_Update"] | undefined,
	delete?: ModelTypes["RedemptionCodesFields_qrCodeId_Delete"] | undefined
};
	["RedemptionCodesFields_qrCodeId_Create"]: {
		permission: boolean
};
	["RedemptionCodesFields_qrCodeId_Read"]: {
		permission: boolean
};
	["RedemptionCodesFields_qrCodeId_Update"]: {
		permission: boolean
};
	["RedemptionCodesFields_qrCodeId_Delete"]: {
		permission: boolean
};
	["RedemptionCodesFields_lastCheckedInDateTime"]: {
		create?: ModelTypes["RedemptionCodesFields_lastCheckedInDateTime_Create"] | undefined,
	read?: ModelTypes["RedemptionCodesFields_lastCheckedInDateTime_Read"] | undefined,
	update?: ModelTypes["RedemptionCodesFields_lastCheckedInDateTime_Update"] | undefined,
	delete?: ModelTypes["RedemptionCodesFields_lastCheckedInDateTime_Delete"] | undefined
};
	["RedemptionCodesFields_lastCheckedInDateTime_Create"]: {
		permission: boolean
};
	["RedemptionCodesFields_lastCheckedInDateTime_Read"]: {
		permission: boolean
};
	["RedemptionCodesFields_lastCheckedInDateTime_Update"]: {
		permission: boolean
};
	["RedemptionCodesFields_lastCheckedInDateTime_Delete"]: {
		permission: boolean
};
	["RedemptionCodesFields_voidedDateTime"]: {
		create?: ModelTypes["RedemptionCodesFields_voidedDateTime_Create"] | undefined,
	read?: ModelTypes["RedemptionCodesFields_voidedDateTime_Read"] | undefined,
	update?: ModelTypes["RedemptionCodesFields_voidedDateTime_Update"] | undefined,
	delete?: ModelTypes["RedemptionCodesFields_voidedDateTime_Delete"] | undefined
};
	["RedemptionCodesFields_voidedDateTime_Create"]: {
		permission: boolean
};
	["RedemptionCodesFields_voidedDateTime_Read"]: {
		permission: boolean
};
	["RedemptionCodesFields_voidedDateTime_Update"]: {
		permission: boolean
};
	["RedemptionCodesFields_voidedDateTime_Delete"]: {
		permission: boolean
};
	["RedemptionCodesFields_refundedDateTime"]: {
		create?: ModelTypes["RedemptionCodesFields_refundedDateTime_Create"] | undefined,
	read?: ModelTypes["RedemptionCodesFields_refundedDateTime_Read"] | undefined,
	update?: ModelTypes["RedemptionCodesFields_refundedDateTime_Update"] | undefined,
	delete?: ModelTypes["RedemptionCodesFields_refundedDateTime_Delete"] | undefined
};
	["RedemptionCodesFields_refundedDateTime_Create"]: {
		permission: boolean
};
	["RedemptionCodesFields_refundedDateTime_Read"]: {
		permission: boolean
};
	["RedemptionCodesFields_refundedDateTime_Update"]: {
		permission: boolean
};
	["RedemptionCodesFields_refundedDateTime_Delete"]: {
		permission: boolean
};
	["RedemptionCodesFields_eventStartTime"]: {
		create?: ModelTypes["RedemptionCodesFields_eventStartTime_Create"] | undefined,
	read?: ModelTypes["RedemptionCodesFields_eventStartTime_Read"] | undefined,
	update?: ModelTypes["RedemptionCodesFields_eventStartTime_Update"] | undefined,
	delete?: ModelTypes["RedemptionCodesFields_eventStartTime_Delete"] | undefined
};
	["RedemptionCodesFields_eventStartTime_Create"]: {
		permission: boolean
};
	["RedemptionCodesFields_eventStartTime_Read"]: {
		permission: boolean
};
	["RedemptionCodesFields_eventStartTime_Update"]: {
		permission: boolean
};
	["RedemptionCodesFields_eventStartTime_Delete"]: {
		permission: boolean
};
	["RedemptionCodesFields_eventEndTime"]: {
		create?: ModelTypes["RedemptionCodesFields_eventEndTime_Create"] | undefined,
	read?: ModelTypes["RedemptionCodesFields_eventEndTime_Read"] | undefined,
	update?: ModelTypes["RedemptionCodesFields_eventEndTime_Update"] | undefined,
	delete?: ModelTypes["RedemptionCodesFields_eventEndTime_Delete"] | undefined
};
	["RedemptionCodesFields_eventEndTime_Create"]: {
		permission: boolean
};
	["RedemptionCodesFields_eventEndTime_Read"]: {
		permission: boolean
};
	["RedemptionCodesFields_eventEndTime_Update"]: {
		permission: boolean
};
	["RedemptionCodesFields_eventEndTime_Delete"]: {
		permission: boolean
};
	["RedemptionCodesFields_eventPriceZone"]: {
		create?: ModelTypes["RedemptionCodesFields_eventPriceZone_Create"] | undefined,
	read?: ModelTypes["RedemptionCodesFields_eventPriceZone_Read"] | undefined,
	update?: ModelTypes["RedemptionCodesFields_eventPriceZone_Update"] | undefined,
	delete?: ModelTypes["RedemptionCodesFields_eventPriceZone_Delete"] | undefined
};
	["RedemptionCodesFields_eventPriceZone_Create"]: {
		permission: boolean
};
	["RedemptionCodesFields_eventPriceZone_Read"]: {
		permission: boolean
};
	["RedemptionCodesFields_eventPriceZone_Update"]: {
		permission: boolean
};
	["RedemptionCodesFields_eventPriceZone_Delete"]: {
		permission: boolean
};
	["RedemptionCodesFields_ownerName"]: {
		create?: ModelTypes["RedemptionCodesFields_ownerName_Create"] | undefined,
	read?: ModelTypes["RedemptionCodesFields_ownerName_Read"] | undefined,
	update?: ModelTypes["RedemptionCodesFields_ownerName_Update"] | undefined,
	delete?: ModelTypes["RedemptionCodesFields_ownerName_Delete"] | undefined
};
	["RedemptionCodesFields_ownerName_Create"]: {
		permission: boolean
};
	["RedemptionCodesFields_ownerName_Read"]: {
		permission: boolean
};
	["RedemptionCodesFields_ownerName_Update"]: {
		permission: boolean
};
	["RedemptionCodesFields_ownerName_Delete"]: {
		permission: boolean
};
	["RedemptionCodesFields_phone"]: {
		create?: ModelTypes["RedemptionCodesFields_phone_Create"] | undefined,
	read?: ModelTypes["RedemptionCodesFields_phone_Read"] | undefined,
	update?: ModelTypes["RedemptionCodesFields_phone_Update"] | undefined,
	delete?: ModelTypes["RedemptionCodesFields_phone_Delete"] | undefined
};
	["RedemptionCodesFields_phone_Create"]: {
		permission: boolean
};
	["RedemptionCodesFields_phone_Read"]: {
		permission: boolean
};
	["RedemptionCodesFields_phone_Update"]: {
		permission: boolean
};
	["RedemptionCodesFields_phone_Delete"]: {
		permission: boolean
};
	["RedemptionCodesFields_pdf"]: {
		create?: ModelTypes["RedemptionCodesFields_pdf_Create"] | undefined,
	read?: ModelTypes["RedemptionCodesFields_pdf_Read"] | undefined,
	update?: ModelTypes["RedemptionCodesFields_pdf_Update"] | undefined,
	delete?: ModelTypes["RedemptionCodesFields_pdf_Delete"] | undefined
};
	["RedemptionCodesFields_pdf_Create"]: {
		permission: boolean
};
	["RedemptionCodesFields_pdf_Read"]: {
		permission: boolean
};
	["RedemptionCodesFields_pdf_Update"]: {
		permission: boolean
};
	["RedemptionCodesFields_pdf_Delete"]: {
		permission: boolean
};
	["RedemptionCodesFields_variants"]: {
		create?: ModelTypes["RedemptionCodesFields_variants_Create"] | undefined,
	read?: ModelTypes["RedemptionCodesFields_variants_Read"] | undefined,
	update?: ModelTypes["RedemptionCodesFields_variants_Update"] | undefined,
	delete?: ModelTypes["RedemptionCodesFields_variants_Delete"] | undefined,
	fields?: ModelTypes["RedemptionCodesFields_variants_Fields"] | undefined
};
	["RedemptionCodesFields_variants_Create"]: {
		permission: boolean
};
	["RedemptionCodesFields_variants_Read"]: {
		permission: boolean
};
	["RedemptionCodesFields_variants_Update"]: {
		permission: boolean
};
	["RedemptionCodesFields_variants_Delete"]: {
		permission: boolean
};
	["RedemptionCodesFields_variants_Fields"]: {
		variantId?: ModelTypes["RedemptionCodesFields_variants_variantId"] | undefined,
	name?: ModelTypes["RedemptionCodesFields_variants_name"] | undefined,
	quantity?: ModelTypes["RedemptionCodesFields_variants_quantity"] | undefined,
	totalCost?: ModelTypes["RedemptionCodesFields_variants_totalCost"] | undefined,
	id?: ModelTypes["RedemptionCodesFields_variants_id"] | undefined
};
	["RedemptionCodesFields_variants_variantId"]: {
		create?: ModelTypes["RedemptionCodesFields_variants_variantId_Create"] | undefined,
	read?: ModelTypes["RedemptionCodesFields_variants_variantId_Read"] | undefined,
	update?: ModelTypes["RedemptionCodesFields_variants_variantId_Update"] | undefined,
	delete?: ModelTypes["RedemptionCodesFields_variants_variantId_Delete"] | undefined
};
	["RedemptionCodesFields_variants_variantId_Create"]: {
		permission: boolean
};
	["RedemptionCodesFields_variants_variantId_Read"]: {
		permission: boolean
};
	["RedemptionCodesFields_variants_variantId_Update"]: {
		permission: boolean
};
	["RedemptionCodesFields_variants_variantId_Delete"]: {
		permission: boolean
};
	["RedemptionCodesFields_variants_name"]: {
		create?: ModelTypes["RedemptionCodesFields_variants_name_Create"] | undefined,
	read?: ModelTypes["RedemptionCodesFields_variants_name_Read"] | undefined,
	update?: ModelTypes["RedemptionCodesFields_variants_name_Update"] | undefined,
	delete?: ModelTypes["RedemptionCodesFields_variants_name_Delete"] | undefined
};
	["RedemptionCodesFields_variants_name_Create"]: {
		permission: boolean
};
	["RedemptionCodesFields_variants_name_Read"]: {
		permission: boolean
};
	["RedemptionCodesFields_variants_name_Update"]: {
		permission: boolean
};
	["RedemptionCodesFields_variants_name_Delete"]: {
		permission: boolean
};
	["RedemptionCodesFields_variants_quantity"]: {
		create?: ModelTypes["RedemptionCodesFields_variants_quantity_Create"] | undefined,
	read?: ModelTypes["RedemptionCodesFields_variants_quantity_Read"] | undefined,
	update?: ModelTypes["RedemptionCodesFields_variants_quantity_Update"] | undefined,
	delete?: ModelTypes["RedemptionCodesFields_variants_quantity_Delete"] | undefined
};
	["RedemptionCodesFields_variants_quantity_Create"]: {
		permission: boolean
};
	["RedemptionCodesFields_variants_quantity_Read"]: {
		permission: boolean
};
	["RedemptionCodesFields_variants_quantity_Update"]: {
		permission: boolean
};
	["RedemptionCodesFields_variants_quantity_Delete"]: {
		permission: boolean
};
	["RedemptionCodesFields_variants_totalCost"]: {
		create?: ModelTypes["RedemptionCodesFields_variants_totalCost_Create"] | undefined,
	read?: ModelTypes["RedemptionCodesFields_variants_totalCost_Read"] | undefined,
	update?: ModelTypes["RedemptionCodesFields_variants_totalCost_Update"] | undefined,
	delete?: ModelTypes["RedemptionCodesFields_variants_totalCost_Delete"] | undefined
};
	["RedemptionCodesFields_variants_totalCost_Create"]: {
		permission: boolean
};
	["RedemptionCodesFields_variants_totalCost_Read"]: {
		permission: boolean
};
	["RedemptionCodesFields_variants_totalCost_Update"]: {
		permission: boolean
};
	["RedemptionCodesFields_variants_totalCost_Delete"]: {
		permission: boolean
};
	["RedemptionCodesFields_variants_id"]: {
		create?: ModelTypes["RedemptionCodesFields_variants_id_Create"] | undefined,
	read?: ModelTypes["RedemptionCodesFields_variants_id_Read"] | undefined,
	update?: ModelTypes["RedemptionCodesFields_variants_id_Update"] | undefined,
	delete?: ModelTypes["RedemptionCodesFields_variants_id_Delete"] | undefined
};
	["RedemptionCodesFields_variants_id_Create"]: {
		permission: boolean
};
	["RedemptionCodesFields_variants_id_Read"]: {
		permission: boolean
};
	["RedemptionCodesFields_variants_id_Update"]: {
		permission: boolean
};
	["RedemptionCodesFields_variants_id_Delete"]: {
		permission: boolean
};
	["RedemptionCodesFields_productId"]: {
		create?: ModelTypes["RedemptionCodesFields_productId_Create"] | undefined,
	read?: ModelTypes["RedemptionCodesFields_productId_Read"] | undefined,
	update?: ModelTypes["RedemptionCodesFields_productId_Update"] | undefined,
	delete?: ModelTypes["RedemptionCodesFields_productId_Delete"] | undefined
};
	["RedemptionCodesFields_productId_Create"]: {
		permission: boolean
};
	["RedemptionCodesFields_productId_Read"]: {
		permission: boolean
};
	["RedemptionCodesFields_productId_Update"]: {
		permission: boolean
};
	["RedemptionCodesFields_productId_Delete"]: {
		permission: boolean
};
	["RedemptionCodesFields_referenceId"]: {
		create?: ModelTypes["RedemptionCodesFields_referenceId_Create"] | undefined,
	read?: ModelTypes["RedemptionCodesFields_referenceId_Read"] | undefined,
	update?: ModelTypes["RedemptionCodesFields_referenceId_Update"] | undefined,
	delete?: ModelTypes["RedemptionCodesFields_referenceId_Delete"] | undefined
};
	["RedemptionCodesFields_referenceId_Create"]: {
		permission: boolean
};
	["RedemptionCodesFields_referenceId_Read"]: {
		permission: boolean
};
	["RedemptionCodesFields_referenceId_Update"]: {
		permission: boolean
};
	["RedemptionCodesFields_referenceId_Delete"]: {
		permission: boolean
};
	["RedemptionCodesFields_productType"]: {
		create?: ModelTypes["RedemptionCodesFields_productType_Create"] | undefined,
	read?: ModelTypes["RedemptionCodesFields_productType_Read"] | undefined,
	update?: ModelTypes["RedemptionCodesFields_productType_Update"] | undefined,
	delete?: ModelTypes["RedemptionCodesFields_productType_Delete"] | undefined
};
	["RedemptionCodesFields_productType_Create"]: {
		permission: boolean
};
	["RedemptionCodesFields_productType_Read"]: {
		permission: boolean
};
	["RedemptionCodesFields_productType_Update"]: {
		permission: boolean
};
	["RedemptionCodesFields_productType_Delete"]: {
		permission: boolean
};
	["RedemptionCodesFields_productName"]: {
		create?: ModelTypes["RedemptionCodesFields_productName_Create"] | undefined,
	read?: ModelTypes["RedemptionCodesFields_productName_Read"] | undefined,
	update?: ModelTypes["RedemptionCodesFields_productName_Update"] | undefined,
	delete?: ModelTypes["RedemptionCodesFields_productName_Delete"] | undefined
};
	["RedemptionCodesFields_productName_Create"]: {
		permission: boolean
};
	["RedemptionCodesFields_productName_Read"]: {
		permission: boolean
};
	["RedemptionCodesFields_productName_Update"]: {
		permission: boolean
};
	["RedemptionCodesFields_productName_Delete"]: {
		permission: boolean
};
	["RedemptionCodesFields_linkedEvent"]: {
		create?: ModelTypes["RedemptionCodesFields_linkedEvent_Create"] | undefined,
	read?: ModelTypes["RedemptionCodesFields_linkedEvent_Read"] | undefined,
	update?: ModelTypes["RedemptionCodesFields_linkedEvent_Update"] | undefined,
	delete?: ModelTypes["RedemptionCodesFields_linkedEvent_Delete"] | undefined
};
	["RedemptionCodesFields_linkedEvent_Create"]: {
		permission: boolean
};
	["RedemptionCodesFields_linkedEvent_Read"]: {
		permission: boolean
};
	["RedemptionCodesFields_linkedEvent_Update"]: {
		permission: boolean
};
	["RedemptionCodesFields_linkedEvent_Delete"]: {
		permission: boolean
};
	["RedemptionCodesFields_timableOrderId"]: {
		create?: ModelTypes["RedemptionCodesFields_timableOrderId_Create"] | undefined,
	read?: ModelTypes["RedemptionCodesFields_timableOrderId_Read"] | undefined,
	update?: ModelTypes["RedemptionCodesFields_timableOrderId_Update"] | undefined,
	delete?: ModelTypes["RedemptionCodesFields_timableOrderId_Delete"] | undefined
};
	["RedemptionCodesFields_timableOrderId_Create"]: {
		permission: boolean
};
	["RedemptionCodesFields_timableOrderId_Read"]: {
		permission: boolean
};
	["RedemptionCodesFields_timableOrderId_Update"]: {
		permission: boolean
};
	["RedemptionCodesFields_timableOrderId_Delete"]: {
		permission: boolean
};
	["RedemptionCodesFields_purchaseDateTime"]: {
		create?: ModelTypes["RedemptionCodesFields_purchaseDateTime_Create"] | undefined,
	read?: ModelTypes["RedemptionCodesFields_purchaseDateTime_Read"] | undefined,
	update?: ModelTypes["RedemptionCodesFields_purchaseDateTime_Update"] | undefined,
	delete?: ModelTypes["RedemptionCodesFields_purchaseDateTime_Delete"] | undefined
};
	["RedemptionCodesFields_purchaseDateTime_Create"]: {
		permission: boolean
};
	["RedemptionCodesFields_purchaseDateTime_Read"]: {
		permission: boolean
};
	["RedemptionCodesFields_purchaseDateTime_Update"]: {
		permission: boolean
};
	["RedemptionCodesFields_purchaseDateTime_Delete"]: {
		permission: boolean
};
	["RedemptionCodesFields_changeHistory"]: {
		create?: ModelTypes["RedemptionCodesFields_changeHistory_Create"] | undefined,
	read?: ModelTypes["RedemptionCodesFields_changeHistory_Read"] | undefined,
	update?: ModelTypes["RedemptionCodesFields_changeHistory_Update"] | undefined,
	delete?: ModelTypes["RedemptionCodesFields_changeHistory_Delete"] | undefined,
	fields?: ModelTypes["RedemptionCodesFields_changeHistory_Fields"] | undefined
};
	["RedemptionCodesFields_changeHistory_Create"]: {
		permission: boolean
};
	["RedemptionCodesFields_changeHistory_Read"]: {
		permission: boolean
};
	["RedemptionCodesFields_changeHistory_Update"]: {
		permission: boolean
};
	["RedemptionCodesFields_changeHistory_Delete"]: {
		permission: boolean
};
	["RedemptionCodesFields_changeHistory_Fields"]: {
		timestamp?: ModelTypes["RedemptionCodesFields_changeHistory_timestamp"] | undefined,
	field?: ModelTypes["RedemptionCodesFields_changeHistory_field"] | undefined,
	oldValue?: ModelTypes["RedemptionCodesFields_changeHistory_oldValue"] | undefined,
	newValue?: ModelTypes["RedemptionCodesFields_changeHistory_newValue"] | undefined,
	id?: ModelTypes["RedemptionCodesFields_changeHistory_id"] | undefined
};
	["RedemptionCodesFields_changeHistory_timestamp"]: {
		create?: ModelTypes["RedemptionCodesFields_changeHistory_timestamp_Create"] | undefined,
	read?: ModelTypes["RedemptionCodesFields_changeHistory_timestamp_Read"] | undefined,
	update?: ModelTypes["RedemptionCodesFields_changeHistory_timestamp_Update"] | undefined,
	delete?: ModelTypes["RedemptionCodesFields_changeHistory_timestamp_Delete"] | undefined
};
	["RedemptionCodesFields_changeHistory_timestamp_Create"]: {
		permission: boolean
};
	["RedemptionCodesFields_changeHistory_timestamp_Read"]: {
		permission: boolean
};
	["RedemptionCodesFields_changeHistory_timestamp_Update"]: {
		permission: boolean
};
	["RedemptionCodesFields_changeHistory_timestamp_Delete"]: {
		permission: boolean
};
	["RedemptionCodesFields_changeHistory_field"]: {
		create?: ModelTypes["RedemptionCodesFields_changeHistory_field_Create"] | undefined,
	read?: ModelTypes["RedemptionCodesFields_changeHistory_field_Read"] | undefined,
	update?: ModelTypes["RedemptionCodesFields_changeHistory_field_Update"] | undefined,
	delete?: ModelTypes["RedemptionCodesFields_changeHistory_field_Delete"] | undefined
};
	["RedemptionCodesFields_changeHistory_field_Create"]: {
		permission: boolean
};
	["RedemptionCodesFields_changeHistory_field_Read"]: {
		permission: boolean
};
	["RedemptionCodesFields_changeHistory_field_Update"]: {
		permission: boolean
};
	["RedemptionCodesFields_changeHistory_field_Delete"]: {
		permission: boolean
};
	["RedemptionCodesFields_changeHistory_oldValue"]: {
		create?: ModelTypes["RedemptionCodesFields_changeHistory_oldValue_Create"] | undefined,
	read?: ModelTypes["RedemptionCodesFields_changeHistory_oldValue_Read"] | undefined,
	update?: ModelTypes["RedemptionCodesFields_changeHistory_oldValue_Update"] | undefined,
	delete?: ModelTypes["RedemptionCodesFields_changeHistory_oldValue_Delete"] | undefined
};
	["RedemptionCodesFields_changeHistory_oldValue_Create"]: {
		permission: boolean
};
	["RedemptionCodesFields_changeHistory_oldValue_Read"]: {
		permission: boolean
};
	["RedemptionCodesFields_changeHistory_oldValue_Update"]: {
		permission: boolean
};
	["RedemptionCodesFields_changeHistory_oldValue_Delete"]: {
		permission: boolean
};
	["RedemptionCodesFields_changeHistory_newValue"]: {
		create?: ModelTypes["RedemptionCodesFields_changeHistory_newValue_Create"] | undefined,
	read?: ModelTypes["RedemptionCodesFields_changeHistory_newValue_Read"] | undefined,
	update?: ModelTypes["RedemptionCodesFields_changeHistory_newValue_Update"] | undefined,
	delete?: ModelTypes["RedemptionCodesFields_changeHistory_newValue_Delete"] | undefined
};
	["RedemptionCodesFields_changeHistory_newValue_Create"]: {
		permission: boolean
};
	["RedemptionCodesFields_changeHistory_newValue_Read"]: {
		permission: boolean
};
	["RedemptionCodesFields_changeHistory_newValue_Update"]: {
		permission: boolean
};
	["RedemptionCodesFields_changeHistory_newValue_Delete"]: {
		permission: boolean
};
	["RedemptionCodesFields_changeHistory_id"]: {
		create?: ModelTypes["RedemptionCodesFields_changeHistory_id_Create"] | undefined,
	read?: ModelTypes["RedemptionCodesFields_changeHistory_id_Read"] | undefined,
	update?: ModelTypes["RedemptionCodesFields_changeHistory_id_Update"] | undefined,
	delete?: ModelTypes["RedemptionCodesFields_changeHistory_id_Delete"] | undefined
};
	["RedemptionCodesFields_changeHistory_id_Create"]: {
		permission: boolean
};
	["RedemptionCodesFields_changeHistory_id_Read"]: {
		permission: boolean
};
	["RedemptionCodesFields_changeHistory_id_Update"]: {
		permission: boolean
};
	["RedemptionCodesFields_changeHistory_id_Delete"]: {
		permission: boolean
};
	["RedemptionCodesFields_updatedAt"]: {
		create?: ModelTypes["RedemptionCodesFields_updatedAt_Create"] | undefined,
	read?: ModelTypes["RedemptionCodesFields_updatedAt_Read"] | undefined,
	update?: ModelTypes["RedemptionCodesFields_updatedAt_Update"] | undefined,
	delete?: ModelTypes["RedemptionCodesFields_updatedAt_Delete"] | undefined
};
	["RedemptionCodesFields_updatedAt_Create"]: {
		permission: boolean
};
	["RedemptionCodesFields_updatedAt_Read"]: {
		permission: boolean
};
	["RedemptionCodesFields_updatedAt_Update"]: {
		permission: boolean
};
	["RedemptionCodesFields_updatedAt_Delete"]: {
		permission: boolean
};
	["RedemptionCodesFields_createdAt"]: {
		create?: ModelTypes["RedemptionCodesFields_createdAt_Create"] | undefined,
	read?: ModelTypes["RedemptionCodesFields_createdAt_Read"] | undefined,
	update?: ModelTypes["RedemptionCodesFields_createdAt_Update"] | undefined,
	delete?: ModelTypes["RedemptionCodesFields_createdAt_Delete"] | undefined
};
	["RedemptionCodesFields_createdAt_Create"]: {
		permission: boolean
};
	["RedemptionCodesFields_createdAt_Read"]: {
		permission: boolean
};
	["RedemptionCodesFields_createdAt_Update"]: {
		permission: boolean
};
	["RedemptionCodesFields_createdAt_Delete"]: {
		permission: boolean
};
	["RedemptionCodesCreateAccess"]: {
		permission: boolean,
	where?: ModelTypes["JSONObject"] | undefined
};
	["RedemptionCodesReadAccess"]: {
		permission: boolean,
	where?: ModelTypes["JSONObject"] | undefined
};
	["RedemptionCodesUpdateAccess"]: {
		permission: boolean,
	where?: ModelTypes["JSONObject"] | undefined
};
	["RedemptionCodesDeleteAccess"]: {
		permission: boolean,
	where?: ModelTypes["JSONObject"] | undefined
};
	["redemption_pdfAccess"]: {
		fields?: ModelTypes["RedemptionPdfFields"] | undefined,
	create?: ModelTypes["RedemptionPdfCreateAccess"] | undefined,
	read?: ModelTypes["RedemptionPdfReadAccess"] | undefined,
	update?: ModelTypes["RedemptionPdfUpdateAccess"] | undefined,
	delete?: ModelTypes["RedemptionPdfDeleteAccess"] | undefined
};
	["RedemptionPdfFields"]: {
		updatedAt?: ModelTypes["RedemptionPdfFields_updatedAt"] | undefined,
	createdAt?: ModelTypes["RedemptionPdfFields_createdAt"] | undefined,
	url?: ModelTypes["RedemptionPdfFields_url"] | undefined,
	filename?: ModelTypes["RedemptionPdfFields_filename"] | undefined,
	mimeType?: ModelTypes["RedemptionPdfFields_mimeType"] | undefined,
	filesize?: ModelTypes["RedemptionPdfFields_filesize"] | undefined,
	width?: ModelTypes["RedemptionPdfFields_width"] | undefined,
	height?: ModelTypes["RedemptionPdfFields_height"] | undefined,
	focalX?: ModelTypes["RedemptionPdfFields_focalX"] | undefined,
	focalY?: ModelTypes["RedemptionPdfFields_focalY"] | undefined
};
	["RedemptionPdfFields_updatedAt"]: {
		create?: ModelTypes["RedemptionPdfFields_updatedAt_Create"] | undefined,
	read?: ModelTypes["RedemptionPdfFields_updatedAt_Read"] | undefined,
	update?: ModelTypes["RedemptionPdfFields_updatedAt_Update"] | undefined,
	delete?: ModelTypes["RedemptionPdfFields_updatedAt_Delete"] | undefined
};
	["RedemptionPdfFields_updatedAt_Create"]: {
		permission: boolean
};
	["RedemptionPdfFields_updatedAt_Read"]: {
		permission: boolean
};
	["RedemptionPdfFields_updatedAt_Update"]: {
		permission: boolean
};
	["RedemptionPdfFields_updatedAt_Delete"]: {
		permission: boolean
};
	["RedemptionPdfFields_createdAt"]: {
		create?: ModelTypes["RedemptionPdfFields_createdAt_Create"] | undefined,
	read?: ModelTypes["RedemptionPdfFields_createdAt_Read"] | undefined,
	update?: ModelTypes["RedemptionPdfFields_createdAt_Update"] | undefined,
	delete?: ModelTypes["RedemptionPdfFields_createdAt_Delete"] | undefined
};
	["RedemptionPdfFields_createdAt_Create"]: {
		permission: boolean
};
	["RedemptionPdfFields_createdAt_Read"]: {
		permission: boolean
};
	["RedemptionPdfFields_createdAt_Update"]: {
		permission: boolean
};
	["RedemptionPdfFields_createdAt_Delete"]: {
		permission: boolean
};
	["RedemptionPdfFields_url"]: {
		create?: ModelTypes["RedemptionPdfFields_url_Create"] | undefined,
	read?: ModelTypes["RedemptionPdfFields_url_Read"] | undefined,
	update?: ModelTypes["RedemptionPdfFields_url_Update"] | undefined,
	delete?: ModelTypes["RedemptionPdfFields_url_Delete"] | undefined
};
	["RedemptionPdfFields_url_Create"]: {
		permission: boolean
};
	["RedemptionPdfFields_url_Read"]: {
		permission: boolean
};
	["RedemptionPdfFields_url_Update"]: {
		permission: boolean
};
	["RedemptionPdfFields_url_Delete"]: {
		permission: boolean
};
	["RedemptionPdfFields_filename"]: {
		create?: ModelTypes["RedemptionPdfFields_filename_Create"] | undefined,
	read?: ModelTypes["RedemptionPdfFields_filename_Read"] | undefined,
	update?: ModelTypes["RedemptionPdfFields_filename_Update"] | undefined,
	delete?: ModelTypes["RedemptionPdfFields_filename_Delete"] | undefined
};
	["RedemptionPdfFields_filename_Create"]: {
		permission: boolean
};
	["RedemptionPdfFields_filename_Read"]: {
		permission: boolean
};
	["RedemptionPdfFields_filename_Update"]: {
		permission: boolean
};
	["RedemptionPdfFields_filename_Delete"]: {
		permission: boolean
};
	["RedemptionPdfFields_mimeType"]: {
		create?: ModelTypes["RedemptionPdfFields_mimeType_Create"] | undefined,
	read?: ModelTypes["RedemptionPdfFields_mimeType_Read"] | undefined,
	update?: ModelTypes["RedemptionPdfFields_mimeType_Update"] | undefined,
	delete?: ModelTypes["RedemptionPdfFields_mimeType_Delete"] | undefined
};
	["RedemptionPdfFields_mimeType_Create"]: {
		permission: boolean
};
	["RedemptionPdfFields_mimeType_Read"]: {
		permission: boolean
};
	["RedemptionPdfFields_mimeType_Update"]: {
		permission: boolean
};
	["RedemptionPdfFields_mimeType_Delete"]: {
		permission: boolean
};
	["RedemptionPdfFields_filesize"]: {
		create?: ModelTypes["RedemptionPdfFields_filesize_Create"] | undefined,
	read?: ModelTypes["RedemptionPdfFields_filesize_Read"] | undefined,
	update?: ModelTypes["RedemptionPdfFields_filesize_Update"] | undefined,
	delete?: ModelTypes["RedemptionPdfFields_filesize_Delete"] | undefined
};
	["RedemptionPdfFields_filesize_Create"]: {
		permission: boolean
};
	["RedemptionPdfFields_filesize_Read"]: {
		permission: boolean
};
	["RedemptionPdfFields_filesize_Update"]: {
		permission: boolean
};
	["RedemptionPdfFields_filesize_Delete"]: {
		permission: boolean
};
	["RedemptionPdfFields_width"]: {
		create?: ModelTypes["RedemptionPdfFields_width_Create"] | undefined,
	read?: ModelTypes["RedemptionPdfFields_width_Read"] | undefined,
	update?: ModelTypes["RedemptionPdfFields_width_Update"] | undefined,
	delete?: ModelTypes["RedemptionPdfFields_width_Delete"] | undefined
};
	["RedemptionPdfFields_width_Create"]: {
		permission: boolean
};
	["RedemptionPdfFields_width_Read"]: {
		permission: boolean
};
	["RedemptionPdfFields_width_Update"]: {
		permission: boolean
};
	["RedemptionPdfFields_width_Delete"]: {
		permission: boolean
};
	["RedemptionPdfFields_height"]: {
		create?: ModelTypes["RedemptionPdfFields_height_Create"] | undefined,
	read?: ModelTypes["RedemptionPdfFields_height_Read"] | undefined,
	update?: ModelTypes["RedemptionPdfFields_height_Update"] | undefined,
	delete?: ModelTypes["RedemptionPdfFields_height_Delete"] | undefined
};
	["RedemptionPdfFields_height_Create"]: {
		permission: boolean
};
	["RedemptionPdfFields_height_Read"]: {
		permission: boolean
};
	["RedemptionPdfFields_height_Update"]: {
		permission: boolean
};
	["RedemptionPdfFields_height_Delete"]: {
		permission: boolean
};
	["RedemptionPdfFields_focalX"]: {
		create?: ModelTypes["RedemptionPdfFields_focalX_Create"] | undefined,
	read?: ModelTypes["RedemptionPdfFields_focalX_Read"] | undefined,
	update?: ModelTypes["RedemptionPdfFields_focalX_Update"] | undefined,
	delete?: ModelTypes["RedemptionPdfFields_focalX_Delete"] | undefined
};
	["RedemptionPdfFields_focalX_Create"]: {
		permission: boolean
};
	["RedemptionPdfFields_focalX_Read"]: {
		permission: boolean
};
	["RedemptionPdfFields_focalX_Update"]: {
		permission: boolean
};
	["RedemptionPdfFields_focalX_Delete"]: {
		permission: boolean
};
	["RedemptionPdfFields_focalY"]: {
		create?: ModelTypes["RedemptionPdfFields_focalY_Create"] | undefined,
	read?: ModelTypes["RedemptionPdfFields_focalY_Read"] | undefined,
	update?: ModelTypes["RedemptionPdfFields_focalY_Update"] | undefined,
	delete?: ModelTypes["RedemptionPdfFields_focalY_Delete"] | undefined
};
	["RedemptionPdfFields_focalY_Create"]: {
		permission: boolean
};
	["RedemptionPdfFields_focalY_Read"]: {
		permission: boolean
};
	["RedemptionPdfFields_focalY_Update"]: {
		permission: boolean
};
	["RedemptionPdfFields_focalY_Delete"]: {
		permission: boolean
};
	["RedemptionPdfCreateAccess"]: {
		permission: boolean,
	where?: ModelTypes["JSONObject"] | undefined
};
	["RedemptionPdfReadAccess"]: {
		permission: boolean,
	where?: ModelTypes["JSONObject"] | undefined
};
	["RedemptionPdfUpdateAccess"]: {
		permission: boolean,
	where?: ModelTypes["JSONObject"] | undefined
};
	["RedemptionPdfDeleteAccess"]: {
		permission: boolean,
	where?: ModelTypes["JSONObject"] | undefined
};
	["check_in_redemptionAccess"]: {
		fields?: ModelTypes["CheckInRedemptionFields"] | undefined,
	create?: ModelTypes["CheckInRedemptionCreateAccess"] | undefined,
	read?: ModelTypes["CheckInRedemptionReadAccess"] | undefined,
	update?: ModelTypes["CheckInRedemptionUpdateAccess"] | undefined,
	delete?: ModelTypes["CheckInRedemptionDeleteAccess"] | undefined
};
	["CheckInRedemptionFields"]: {
		creator?: ModelTypes["CheckInRedemptionFields_creator"] | undefined,
	qrCodeId?: ModelTypes["CheckInRedemptionFields_qrCodeId"] | undefined,
	codeownerName?: ModelTypes["CheckInRedemptionFields_codeownerName"] | undefined,
	name?: ModelTypes["CheckInRedemptionFields_name"] | undefined,
	phone?: ModelTypes["CheckInRedemptionFields_phone"] | undefined,
	linkedEvent?: ModelTypes["CheckInRedemptionFields_linkedEvent"] | undefined,
	entryNumber?: ModelTypes["CheckInRedemptionFields_entryNumber"] | undefined,
	scanAt?: ModelTypes["CheckInRedemptionFields_scanAt"] | undefined,
	uploadAt?: ModelTypes["CheckInRedemptionFields_uploadAt"] | undefined,
	status?: ModelTypes["CheckInRedemptionFields_status"] | undefined,
	failReason?: ModelTypes["CheckInRedemptionFields_failReason"] | undefined,
	variants?: ModelTypes["CheckInRedemptionFields_variants"] | undefined,
	checkInAppSelectionId?: ModelTypes["CheckInRedemptionFields_checkInAppSelectionId"] | undefined,
	qrCodeStatus?: ModelTypes["CheckInRedemptionFields_qrCodeStatus"] | undefined,
	productType?: ModelTypes["CheckInRedemptionFields_productType"] | undefined,
	updatedAt?: ModelTypes["CheckInRedemptionFields_updatedAt"] | undefined,
	createdAt?: ModelTypes["CheckInRedemptionFields_createdAt"] | undefined
};
	["CheckInRedemptionFields_creator"]: {
		create?: ModelTypes["CheckInRedemptionFields_creator_Create"] | undefined,
	read?: ModelTypes["CheckInRedemptionFields_creator_Read"] | undefined,
	update?: ModelTypes["CheckInRedemptionFields_creator_Update"] | undefined,
	delete?: ModelTypes["CheckInRedemptionFields_creator_Delete"] | undefined
};
	["CheckInRedemptionFields_creator_Create"]: {
		permission: boolean
};
	["CheckInRedemptionFields_creator_Read"]: {
		permission: boolean
};
	["CheckInRedemptionFields_creator_Update"]: {
		permission: boolean
};
	["CheckInRedemptionFields_creator_Delete"]: {
		permission: boolean
};
	["CheckInRedemptionFields_qrCodeId"]: {
		create?: ModelTypes["CheckInRedemptionFields_qrCodeId_Create"] | undefined,
	read?: ModelTypes["CheckInRedemptionFields_qrCodeId_Read"] | undefined,
	update?: ModelTypes["CheckInRedemptionFields_qrCodeId_Update"] | undefined,
	delete?: ModelTypes["CheckInRedemptionFields_qrCodeId_Delete"] | undefined
};
	["CheckInRedemptionFields_qrCodeId_Create"]: {
		permission: boolean
};
	["CheckInRedemptionFields_qrCodeId_Read"]: {
		permission: boolean
};
	["CheckInRedemptionFields_qrCodeId_Update"]: {
		permission: boolean
};
	["CheckInRedemptionFields_qrCodeId_Delete"]: {
		permission: boolean
};
	["CheckInRedemptionFields_codeownerName"]: {
		create?: ModelTypes["CheckInRedemptionFields_codeownerName_Create"] | undefined,
	read?: ModelTypes["CheckInRedemptionFields_codeownerName_Read"] | undefined,
	update?: ModelTypes["CheckInRedemptionFields_codeownerName_Update"] | undefined,
	delete?: ModelTypes["CheckInRedemptionFields_codeownerName_Delete"] | undefined
};
	["CheckInRedemptionFields_codeownerName_Create"]: {
		permission: boolean
};
	["CheckInRedemptionFields_codeownerName_Read"]: {
		permission: boolean
};
	["CheckInRedemptionFields_codeownerName_Update"]: {
		permission: boolean
};
	["CheckInRedemptionFields_codeownerName_Delete"]: {
		permission: boolean
};
	["CheckInRedemptionFields_name"]: {
		create?: ModelTypes["CheckInRedemptionFields_name_Create"] | undefined,
	read?: ModelTypes["CheckInRedemptionFields_name_Read"] | undefined,
	update?: ModelTypes["CheckInRedemptionFields_name_Update"] | undefined,
	delete?: ModelTypes["CheckInRedemptionFields_name_Delete"] | undefined
};
	["CheckInRedemptionFields_name_Create"]: {
		permission: boolean
};
	["CheckInRedemptionFields_name_Read"]: {
		permission: boolean
};
	["CheckInRedemptionFields_name_Update"]: {
		permission: boolean
};
	["CheckInRedemptionFields_name_Delete"]: {
		permission: boolean
};
	["CheckInRedemptionFields_phone"]: {
		create?: ModelTypes["CheckInRedemptionFields_phone_Create"] | undefined,
	read?: ModelTypes["CheckInRedemptionFields_phone_Read"] | undefined,
	update?: ModelTypes["CheckInRedemptionFields_phone_Update"] | undefined,
	delete?: ModelTypes["CheckInRedemptionFields_phone_Delete"] | undefined
};
	["CheckInRedemptionFields_phone_Create"]: {
		permission: boolean
};
	["CheckInRedemptionFields_phone_Read"]: {
		permission: boolean
};
	["CheckInRedemptionFields_phone_Update"]: {
		permission: boolean
};
	["CheckInRedemptionFields_phone_Delete"]: {
		permission: boolean
};
	["CheckInRedemptionFields_linkedEvent"]: {
		create?: ModelTypes["CheckInRedemptionFields_linkedEvent_Create"] | undefined,
	read?: ModelTypes["CheckInRedemptionFields_linkedEvent_Read"] | undefined,
	update?: ModelTypes["CheckInRedemptionFields_linkedEvent_Update"] | undefined,
	delete?: ModelTypes["CheckInRedemptionFields_linkedEvent_Delete"] | undefined
};
	["CheckInRedemptionFields_linkedEvent_Create"]: {
		permission: boolean
};
	["CheckInRedemptionFields_linkedEvent_Read"]: {
		permission: boolean
};
	["CheckInRedemptionFields_linkedEvent_Update"]: {
		permission: boolean
};
	["CheckInRedemptionFields_linkedEvent_Delete"]: {
		permission: boolean
};
	["CheckInRedemptionFields_entryNumber"]: {
		create?: ModelTypes["CheckInRedemptionFields_entryNumber_Create"] | undefined,
	read?: ModelTypes["CheckInRedemptionFields_entryNumber_Read"] | undefined,
	update?: ModelTypes["CheckInRedemptionFields_entryNumber_Update"] | undefined,
	delete?: ModelTypes["CheckInRedemptionFields_entryNumber_Delete"] | undefined
};
	["CheckInRedemptionFields_entryNumber_Create"]: {
		permission: boolean
};
	["CheckInRedemptionFields_entryNumber_Read"]: {
		permission: boolean
};
	["CheckInRedemptionFields_entryNumber_Update"]: {
		permission: boolean
};
	["CheckInRedemptionFields_entryNumber_Delete"]: {
		permission: boolean
};
	["CheckInRedemptionFields_scanAt"]: {
		create?: ModelTypes["CheckInRedemptionFields_scanAt_Create"] | undefined,
	read?: ModelTypes["CheckInRedemptionFields_scanAt_Read"] | undefined,
	update?: ModelTypes["CheckInRedemptionFields_scanAt_Update"] | undefined,
	delete?: ModelTypes["CheckInRedemptionFields_scanAt_Delete"] | undefined
};
	["CheckInRedemptionFields_scanAt_Create"]: {
		permission: boolean
};
	["CheckInRedemptionFields_scanAt_Read"]: {
		permission: boolean
};
	["CheckInRedemptionFields_scanAt_Update"]: {
		permission: boolean
};
	["CheckInRedemptionFields_scanAt_Delete"]: {
		permission: boolean
};
	["CheckInRedemptionFields_uploadAt"]: {
		create?: ModelTypes["CheckInRedemptionFields_uploadAt_Create"] | undefined,
	read?: ModelTypes["CheckInRedemptionFields_uploadAt_Read"] | undefined,
	update?: ModelTypes["CheckInRedemptionFields_uploadAt_Update"] | undefined,
	delete?: ModelTypes["CheckInRedemptionFields_uploadAt_Delete"] | undefined
};
	["CheckInRedemptionFields_uploadAt_Create"]: {
		permission: boolean
};
	["CheckInRedemptionFields_uploadAt_Read"]: {
		permission: boolean
};
	["CheckInRedemptionFields_uploadAt_Update"]: {
		permission: boolean
};
	["CheckInRedemptionFields_uploadAt_Delete"]: {
		permission: boolean
};
	["CheckInRedemptionFields_status"]: {
		create?: ModelTypes["CheckInRedemptionFields_status_Create"] | undefined,
	read?: ModelTypes["CheckInRedemptionFields_status_Read"] | undefined,
	update?: ModelTypes["CheckInRedemptionFields_status_Update"] | undefined,
	delete?: ModelTypes["CheckInRedemptionFields_status_Delete"] | undefined
};
	["CheckInRedemptionFields_status_Create"]: {
		permission: boolean
};
	["CheckInRedemptionFields_status_Read"]: {
		permission: boolean
};
	["CheckInRedemptionFields_status_Update"]: {
		permission: boolean
};
	["CheckInRedemptionFields_status_Delete"]: {
		permission: boolean
};
	["CheckInRedemptionFields_failReason"]: {
		create?: ModelTypes["CheckInRedemptionFields_failReason_Create"] | undefined,
	read?: ModelTypes["CheckInRedemptionFields_failReason_Read"] | undefined,
	update?: ModelTypes["CheckInRedemptionFields_failReason_Update"] | undefined,
	delete?: ModelTypes["CheckInRedemptionFields_failReason_Delete"] | undefined
};
	["CheckInRedemptionFields_failReason_Create"]: {
		permission: boolean
};
	["CheckInRedemptionFields_failReason_Read"]: {
		permission: boolean
};
	["CheckInRedemptionFields_failReason_Update"]: {
		permission: boolean
};
	["CheckInRedemptionFields_failReason_Delete"]: {
		permission: boolean
};
	["CheckInRedemptionFields_variants"]: {
		create?: ModelTypes["CheckInRedemptionFields_variants_Create"] | undefined,
	read?: ModelTypes["CheckInRedemptionFields_variants_Read"] | undefined,
	update?: ModelTypes["CheckInRedemptionFields_variants_Update"] | undefined,
	delete?: ModelTypes["CheckInRedemptionFields_variants_Delete"] | undefined,
	fields?: ModelTypes["CheckInRedemptionFields_variants_Fields"] | undefined
};
	["CheckInRedemptionFields_variants_Create"]: {
		permission: boolean
};
	["CheckInRedemptionFields_variants_Read"]: {
		permission: boolean
};
	["CheckInRedemptionFields_variants_Update"]: {
		permission: boolean
};
	["CheckInRedemptionFields_variants_Delete"]: {
		permission: boolean
};
	["CheckInRedemptionFields_variants_Fields"]: {
		variantId?: ModelTypes["CheckInRedemptionFields_variants_variantId"] | undefined,
	name?: ModelTypes["CheckInRedemptionFields_variants_name"] | undefined,
	quantity?: ModelTypes["CheckInRedemptionFields_variants_quantity"] | undefined,
	totalCost?: ModelTypes["CheckInRedemptionFields_variants_totalCost"] | undefined,
	id?: ModelTypes["CheckInRedemptionFields_variants_id"] | undefined
};
	["CheckInRedemptionFields_variants_variantId"]: {
		create?: ModelTypes["CheckInRedemptionFields_variants_variantId_Create"] | undefined,
	read?: ModelTypes["CheckInRedemptionFields_variants_variantId_Read"] | undefined,
	update?: ModelTypes["CheckInRedemptionFields_variants_variantId_Update"] | undefined,
	delete?: ModelTypes["CheckInRedemptionFields_variants_variantId_Delete"] | undefined
};
	["CheckInRedemptionFields_variants_variantId_Create"]: {
		permission: boolean
};
	["CheckInRedemptionFields_variants_variantId_Read"]: {
		permission: boolean
};
	["CheckInRedemptionFields_variants_variantId_Update"]: {
		permission: boolean
};
	["CheckInRedemptionFields_variants_variantId_Delete"]: {
		permission: boolean
};
	["CheckInRedemptionFields_variants_name"]: {
		create?: ModelTypes["CheckInRedemptionFields_variants_name_Create"] | undefined,
	read?: ModelTypes["CheckInRedemptionFields_variants_name_Read"] | undefined,
	update?: ModelTypes["CheckInRedemptionFields_variants_name_Update"] | undefined,
	delete?: ModelTypes["CheckInRedemptionFields_variants_name_Delete"] | undefined
};
	["CheckInRedemptionFields_variants_name_Create"]: {
		permission: boolean
};
	["CheckInRedemptionFields_variants_name_Read"]: {
		permission: boolean
};
	["CheckInRedemptionFields_variants_name_Update"]: {
		permission: boolean
};
	["CheckInRedemptionFields_variants_name_Delete"]: {
		permission: boolean
};
	["CheckInRedemptionFields_variants_quantity"]: {
		create?: ModelTypes["CheckInRedemptionFields_variants_quantity_Create"] | undefined,
	read?: ModelTypes["CheckInRedemptionFields_variants_quantity_Read"] | undefined,
	update?: ModelTypes["CheckInRedemptionFields_variants_quantity_Update"] | undefined,
	delete?: ModelTypes["CheckInRedemptionFields_variants_quantity_Delete"] | undefined
};
	["CheckInRedemptionFields_variants_quantity_Create"]: {
		permission: boolean
};
	["CheckInRedemptionFields_variants_quantity_Read"]: {
		permission: boolean
};
	["CheckInRedemptionFields_variants_quantity_Update"]: {
		permission: boolean
};
	["CheckInRedemptionFields_variants_quantity_Delete"]: {
		permission: boolean
};
	["CheckInRedemptionFields_variants_totalCost"]: {
		create?: ModelTypes["CheckInRedemptionFields_variants_totalCost_Create"] | undefined,
	read?: ModelTypes["CheckInRedemptionFields_variants_totalCost_Read"] | undefined,
	update?: ModelTypes["CheckInRedemptionFields_variants_totalCost_Update"] | undefined,
	delete?: ModelTypes["CheckInRedemptionFields_variants_totalCost_Delete"] | undefined
};
	["CheckInRedemptionFields_variants_totalCost_Create"]: {
		permission: boolean
};
	["CheckInRedemptionFields_variants_totalCost_Read"]: {
		permission: boolean
};
	["CheckInRedemptionFields_variants_totalCost_Update"]: {
		permission: boolean
};
	["CheckInRedemptionFields_variants_totalCost_Delete"]: {
		permission: boolean
};
	["CheckInRedemptionFields_variants_id"]: {
		create?: ModelTypes["CheckInRedemptionFields_variants_id_Create"] | undefined,
	read?: ModelTypes["CheckInRedemptionFields_variants_id_Read"] | undefined,
	update?: ModelTypes["CheckInRedemptionFields_variants_id_Update"] | undefined,
	delete?: ModelTypes["CheckInRedemptionFields_variants_id_Delete"] | undefined
};
	["CheckInRedemptionFields_variants_id_Create"]: {
		permission: boolean
};
	["CheckInRedemptionFields_variants_id_Read"]: {
		permission: boolean
};
	["CheckInRedemptionFields_variants_id_Update"]: {
		permission: boolean
};
	["CheckInRedemptionFields_variants_id_Delete"]: {
		permission: boolean
};
	["CheckInRedemptionFields_checkInAppSelectionId"]: {
		create?: ModelTypes["CheckInRedemptionFields_checkInAppSelectionId_Create"] | undefined,
	read?: ModelTypes["CheckInRedemptionFields_checkInAppSelectionId_Read"] | undefined,
	update?: ModelTypes["CheckInRedemptionFields_checkInAppSelectionId_Update"] | undefined,
	delete?: ModelTypes["CheckInRedemptionFields_checkInAppSelectionId_Delete"] | undefined
};
	["CheckInRedemptionFields_checkInAppSelectionId_Create"]: {
		permission: boolean
};
	["CheckInRedemptionFields_checkInAppSelectionId_Read"]: {
		permission: boolean
};
	["CheckInRedemptionFields_checkInAppSelectionId_Update"]: {
		permission: boolean
};
	["CheckInRedemptionFields_checkInAppSelectionId_Delete"]: {
		permission: boolean
};
	["CheckInRedemptionFields_qrCodeStatus"]: {
		create?: ModelTypes["CheckInRedemptionFields_qrCodeStatus_Create"] | undefined,
	read?: ModelTypes["CheckInRedemptionFields_qrCodeStatus_Read"] | undefined,
	update?: ModelTypes["CheckInRedemptionFields_qrCodeStatus_Update"] | undefined,
	delete?: ModelTypes["CheckInRedemptionFields_qrCodeStatus_Delete"] | undefined
};
	["CheckInRedemptionFields_qrCodeStatus_Create"]: {
		permission: boolean
};
	["CheckInRedemptionFields_qrCodeStatus_Read"]: {
		permission: boolean
};
	["CheckInRedemptionFields_qrCodeStatus_Update"]: {
		permission: boolean
};
	["CheckInRedemptionFields_qrCodeStatus_Delete"]: {
		permission: boolean
};
	["CheckInRedemptionFields_productType"]: {
		create?: ModelTypes["CheckInRedemptionFields_productType_Create"] | undefined,
	read?: ModelTypes["CheckInRedemptionFields_productType_Read"] | undefined,
	update?: ModelTypes["CheckInRedemptionFields_productType_Update"] | undefined,
	delete?: ModelTypes["CheckInRedemptionFields_productType_Delete"] | undefined
};
	["CheckInRedemptionFields_productType_Create"]: {
		permission: boolean
};
	["CheckInRedemptionFields_productType_Read"]: {
		permission: boolean
};
	["CheckInRedemptionFields_productType_Update"]: {
		permission: boolean
};
	["CheckInRedemptionFields_productType_Delete"]: {
		permission: boolean
};
	["CheckInRedemptionFields_updatedAt"]: {
		create?: ModelTypes["CheckInRedemptionFields_updatedAt_Create"] | undefined,
	read?: ModelTypes["CheckInRedemptionFields_updatedAt_Read"] | undefined,
	update?: ModelTypes["CheckInRedemptionFields_updatedAt_Update"] | undefined,
	delete?: ModelTypes["CheckInRedemptionFields_updatedAt_Delete"] | undefined
};
	["CheckInRedemptionFields_updatedAt_Create"]: {
		permission: boolean
};
	["CheckInRedemptionFields_updatedAt_Read"]: {
		permission: boolean
};
	["CheckInRedemptionFields_updatedAt_Update"]: {
		permission: boolean
};
	["CheckInRedemptionFields_updatedAt_Delete"]: {
		permission: boolean
};
	["CheckInRedemptionFields_createdAt"]: {
		create?: ModelTypes["CheckInRedemptionFields_createdAt_Create"] | undefined,
	read?: ModelTypes["CheckInRedemptionFields_createdAt_Read"] | undefined,
	update?: ModelTypes["CheckInRedemptionFields_createdAt_Update"] | undefined,
	delete?: ModelTypes["CheckInRedemptionFields_createdAt_Delete"] | undefined
};
	["CheckInRedemptionFields_createdAt_Create"]: {
		permission: boolean
};
	["CheckInRedemptionFields_createdAt_Read"]: {
		permission: boolean
};
	["CheckInRedemptionFields_createdAt_Update"]: {
		permission: boolean
};
	["CheckInRedemptionFields_createdAt_Delete"]: {
		permission: boolean
};
	["CheckInRedemptionCreateAccess"]: {
		permission: boolean,
	where?: ModelTypes["JSONObject"] | undefined
};
	["CheckInRedemptionReadAccess"]: {
		permission: boolean,
	where?: ModelTypes["JSONObject"] | undefined
};
	["CheckInRedemptionUpdateAccess"]: {
		permission: boolean,
	where?: ModelTypes["JSONObject"] | undefined
};
	["CheckInRedemptionDeleteAccess"]: {
		permission: boolean,
	where?: ModelTypes["JSONObject"] | undefined
};
	["usersAccess"]: {
		fields?: ModelTypes["UsersFields"] | undefined,
	create?: ModelTypes["UsersCreateAccess"] | undefined,
	read?: ModelTypes["UsersReadAccess"] | undefined,
	update?: ModelTypes["UsersUpdateAccess"] | undefined,
	delete?: ModelTypes["UsersDeleteAccess"] | undefined,
	unlock?: ModelTypes["UsersUnlockAccess"] | undefined
};
	["UsersFields"]: {
		region?: ModelTypes["UsersFields_region"] | undefined,
	name?: ModelTypes["UsersFields_name"] | undefined,
	displayEmail?: ModelTypes["UsersFields_displayEmail"] | undefined,
	legacyUsername?: ModelTypes["UsersFields_legacyUsername"] | undefined,
	roles?: ModelTypes["UsersFields_roles"] | undefined,
	member?: ModelTypes["UsersFields_member"] | undefined,
	organiser?: ModelTypes["UsersFields_organiser"] | undefined,
	emailVerified?: ModelTypes["UsersFields_emailVerified"] | undefined,
	sso?: ModelTypes["UsersFields_sso"] | undefined,
	oldPassword?: ModelTypes["UsersFields_oldPassword"] | undefined,
	status?: ModelTypes["UsersFields_status"] | undefined,
	updatedAt?: ModelTypes["UsersFields_updatedAt"] | undefined,
	createdAt?: ModelTypes["UsersFields_createdAt"] | undefined,
	email?: ModelTypes["UsersFields_email"] | undefined,
	password?: ModelTypes["UsersFields_password"] | undefined
};
	["UsersFields_region"]: {
		create?: ModelTypes["UsersFields_region_Create"] | undefined,
	read?: ModelTypes["UsersFields_region_Read"] | undefined,
	update?: ModelTypes["UsersFields_region_Update"] | undefined,
	delete?: ModelTypes["UsersFields_region_Delete"] | undefined
};
	["UsersFields_region_Create"]: {
		permission: boolean
};
	["UsersFields_region_Read"]: {
		permission: boolean
};
	["UsersFields_region_Update"]: {
		permission: boolean
};
	["UsersFields_region_Delete"]: {
		permission: boolean
};
	["UsersFields_name"]: {
		create?: ModelTypes["UsersFields_name_Create"] | undefined,
	read?: ModelTypes["UsersFields_name_Read"] | undefined,
	update?: ModelTypes["UsersFields_name_Update"] | undefined,
	delete?: ModelTypes["UsersFields_name_Delete"] | undefined
};
	["UsersFields_name_Create"]: {
		permission: boolean
};
	["UsersFields_name_Read"]: {
		permission: boolean
};
	["UsersFields_name_Update"]: {
		permission: boolean
};
	["UsersFields_name_Delete"]: {
		permission: boolean
};
	["UsersFields_displayEmail"]: {
		create?: ModelTypes["UsersFields_displayEmail_Create"] | undefined,
	read?: ModelTypes["UsersFields_displayEmail_Read"] | undefined,
	update?: ModelTypes["UsersFields_displayEmail_Update"] | undefined,
	delete?: ModelTypes["UsersFields_displayEmail_Delete"] | undefined
};
	["UsersFields_displayEmail_Create"]: {
		permission: boolean
};
	["UsersFields_displayEmail_Read"]: {
		permission: boolean
};
	["UsersFields_displayEmail_Update"]: {
		permission: boolean
};
	["UsersFields_displayEmail_Delete"]: {
		permission: boolean
};
	["UsersFields_legacyUsername"]: {
		create?: ModelTypes["UsersFields_legacyUsername_Create"] | undefined,
	read?: ModelTypes["UsersFields_legacyUsername_Read"] | undefined,
	update?: ModelTypes["UsersFields_legacyUsername_Update"] | undefined,
	delete?: ModelTypes["UsersFields_legacyUsername_Delete"] | undefined
};
	["UsersFields_legacyUsername_Create"]: {
		permission: boolean
};
	["UsersFields_legacyUsername_Read"]: {
		permission: boolean
};
	["UsersFields_legacyUsername_Update"]: {
		permission: boolean
};
	["UsersFields_legacyUsername_Delete"]: {
		permission: boolean
};
	["UsersFields_roles"]: {
		create?: ModelTypes["UsersFields_roles_Create"] | undefined,
	read?: ModelTypes["UsersFields_roles_Read"] | undefined,
	update?: ModelTypes["UsersFields_roles_Update"] | undefined,
	delete?: ModelTypes["UsersFields_roles_Delete"] | undefined
};
	["UsersFields_roles_Create"]: {
		permission: boolean
};
	["UsersFields_roles_Read"]: {
		permission: boolean
};
	["UsersFields_roles_Update"]: {
		permission: boolean
};
	["UsersFields_roles_Delete"]: {
		permission: boolean
};
	["UsersFields_member"]: {
		create?: ModelTypes["UsersFields_member_Create"] | undefined,
	read?: ModelTypes["UsersFields_member_Read"] | undefined,
	update?: ModelTypes["UsersFields_member_Update"] | undefined,
	delete?: ModelTypes["UsersFields_member_Delete"] | undefined,
	fields?: ModelTypes["UsersFields_member_Fields"] | undefined
};
	["UsersFields_member_Create"]: {
		permission: boolean
};
	["UsersFields_member_Read"]: {
		permission: boolean
};
	["UsersFields_member_Update"]: {
		permission: boolean
};
	["UsersFields_member_Delete"]: {
		permission: boolean
};
	["UsersFields_member_Fields"]: {
		region?: ModelTypes["UsersFields_member_region"] | undefined,
	language?: ModelTypes["UsersFields_member_language"] | undefined,
	phone?: ModelTypes["UsersFields_member_phone"] | undefined,
	avatar?: ModelTypes["UsersFields_member_avatar"] | undefined,
	birthday?: ModelTypes["UsersFields_member_birthday"] | undefined,
	gender?: ModelTypes["UsersFields_member_gender"] | undefined,
	categories?: ModelTypes["UsersFields_member_categories"] | undefined
};
	["UsersFields_member_region"]: {
		create?: ModelTypes["UsersFields_member_region_Create"] | undefined,
	read?: ModelTypes["UsersFields_member_region_Read"] | undefined,
	update?: ModelTypes["UsersFields_member_region_Update"] | undefined,
	delete?: ModelTypes["UsersFields_member_region_Delete"] | undefined
};
	["UsersFields_member_region_Create"]: {
		permission: boolean
};
	["UsersFields_member_region_Read"]: {
		permission: boolean
};
	["UsersFields_member_region_Update"]: {
		permission: boolean
};
	["UsersFields_member_region_Delete"]: {
		permission: boolean
};
	["UsersFields_member_language"]: {
		create?: ModelTypes["UsersFields_member_language_Create"] | undefined,
	read?: ModelTypes["UsersFields_member_language_Read"] | undefined,
	update?: ModelTypes["UsersFields_member_language_Update"] | undefined,
	delete?: ModelTypes["UsersFields_member_language_Delete"] | undefined
};
	["UsersFields_member_language_Create"]: {
		permission: boolean
};
	["UsersFields_member_language_Read"]: {
		permission: boolean
};
	["UsersFields_member_language_Update"]: {
		permission: boolean
};
	["UsersFields_member_language_Delete"]: {
		permission: boolean
};
	["UsersFields_member_phone"]: {
		create?: ModelTypes["UsersFields_member_phone_Create"] | undefined,
	read?: ModelTypes["UsersFields_member_phone_Read"] | undefined,
	update?: ModelTypes["UsersFields_member_phone_Update"] | undefined,
	delete?: ModelTypes["UsersFields_member_phone_Delete"] | undefined
};
	["UsersFields_member_phone_Create"]: {
		permission: boolean
};
	["UsersFields_member_phone_Read"]: {
		permission: boolean
};
	["UsersFields_member_phone_Update"]: {
		permission: boolean
};
	["UsersFields_member_phone_Delete"]: {
		permission: boolean
};
	["UsersFields_member_avatar"]: {
		create?: ModelTypes["UsersFields_member_avatar_Create"] | undefined,
	read?: ModelTypes["UsersFields_member_avatar_Read"] | undefined,
	update?: ModelTypes["UsersFields_member_avatar_Update"] | undefined,
	delete?: ModelTypes["UsersFields_member_avatar_Delete"] | undefined
};
	["UsersFields_member_avatar_Create"]: {
		permission: boolean
};
	["UsersFields_member_avatar_Read"]: {
		permission: boolean
};
	["UsersFields_member_avatar_Update"]: {
		permission: boolean
};
	["UsersFields_member_avatar_Delete"]: {
		permission: boolean
};
	["UsersFields_member_birthday"]: {
		create?: ModelTypes["UsersFields_member_birthday_Create"] | undefined,
	read?: ModelTypes["UsersFields_member_birthday_Read"] | undefined,
	update?: ModelTypes["UsersFields_member_birthday_Update"] | undefined,
	delete?: ModelTypes["UsersFields_member_birthday_Delete"] | undefined
};
	["UsersFields_member_birthday_Create"]: {
		permission: boolean
};
	["UsersFields_member_birthday_Read"]: {
		permission: boolean
};
	["UsersFields_member_birthday_Update"]: {
		permission: boolean
};
	["UsersFields_member_birthday_Delete"]: {
		permission: boolean
};
	["UsersFields_member_gender"]: {
		create?: ModelTypes["UsersFields_member_gender_Create"] | undefined,
	read?: ModelTypes["UsersFields_member_gender_Read"] | undefined,
	update?: ModelTypes["UsersFields_member_gender_Update"] | undefined,
	delete?: ModelTypes["UsersFields_member_gender_Delete"] | undefined
};
	["UsersFields_member_gender_Create"]: {
		permission: boolean
};
	["UsersFields_member_gender_Read"]: {
		permission: boolean
};
	["UsersFields_member_gender_Update"]: {
		permission: boolean
};
	["UsersFields_member_gender_Delete"]: {
		permission: boolean
};
	["UsersFields_member_categories"]: {
		create?: ModelTypes["UsersFields_member_categories_Create"] | undefined,
	read?: ModelTypes["UsersFields_member_categories_Read"] | undefined,
	update?: ModelTypes["UsersFields_member_categories_Update"] | undefined,
	delete?: ModelTypes["UsersFields_member_categories_Delete"] | undefined
};
	["UsersFields_member_categories_Create"]: {
		permission: boolean
};
	["UsersFields_member_categories_Read"]: {
		permission: boolean
};
	["UsersFields_member_categories_Update"]: {
		permission: boolean
};
	["UsersFields_member_categories_Delete"]: {
		permission: boolean
};
	["UsersFields_organiser"]: {
		create?: ModelTypes["UsersFields_organiser_Create"] | undefined,
	read?: ModelTypes["UsersFields_organiser_Read"] | undefined,
	update?: ModelTypes["UsersFields_organiser_Update"] | undefined,
	delete?: ModelTypes["UsersFields_organiser_Delete"] | undefined,
	fields?: ModelTypes["UsersFields_organiser_Fields"] | undefined
};
	["UsersFields_organiser_Create"]: {
		permission: boolean
};
	["UsersFields_organiser_Read"]: {
		permission: boolean
};
	["UsersFields_organiser_Update"]: {
		permission: boolean
};
	["UsersFields_organiser_Delete"]: {
		permission: boolean
};
	["UsersFields_organiser_Fields"]: {
		logo?: ModelTypes["UsersFields_organiser_logo"] | undefined,
	banner?: ModelTypes["UsersFields_organiser_banner"] | undefined,
	description?: ModelTypes["UsersFields_organiser_description"] | undefined,
	foundedIn?: ModelTypes["UsersFields_organiser_foundedIn"] | undefined,
	address?: ModelTypes["UsersFields_organiser_address"] | undefined,
	website?: ModelTypes["UsersFields_organiser_website"] | undefined,
	facebook?: ModelTypes["UsersFields_organiser_facebook"] | undefined,
	instagram?: ModelTypes["UsersFields_organiser_instagram"] | undefined,
	language?: ModelTypes["UsersFields_organiser_language"] | undefined,
	phone?: ModelTypes["UsersFields_organiser_phone"] | undefined,
	followers?: ModelTypes["UsersFields_organiser_followers"] | undefined
};
	["UsersFields_organiser_logo"]: {
		create?: ModelTypes["UsersFields_organiser_logo_Create"] | undefined,
	read?: ModelTypes["UsersFields_organiser_logo_Read"] | undefined,
	update?: ModelTypes["UsersFields_organiser_logo_Update"] | undefined,
	delete?: ModelTypes["UsersFields_organiser_logo_Delete"] | undefined
};
	["UsersFields_organiser_logo_Create"]: {
		permission: boolean
};
	["UsersFields_organiser_logo_Read"]: {
		permission: boolean
};
	["UsersFields_organiser_logo_Update"]: {
		permission: boolean
};
	["UsersFields_organiser_logo_Delete"]: {
		permission: boolean
};
	["UsersFields_organiser_banner"]: {
		create?: ModelTypes["UsersFields_organiser_banner_Create"] | undefined,
	read?: ModelTypes["UsersFields_organiser_banner_Read"] | undefined,
	update?: ModelTypes["UsersFields_organiser_banner_Update"] | undefined,
	delete?: ModelTypes["UsersFields_organiser_banner_Delete"] | undefined
};
	["UsersFields_organiser_banner_Create"]: {
		permission: boolean
};
	["UsersFields_organiser_banner_Read"]: {
		permission: boolean
};
	["UsersFields_organiser_banner_Update"]: {
		permission: boolean
};
	["UsersFields_organiser_banner_Delete"]: {
		permission: boolean
};
	["UsersFields_organiser_description"]: {
		create?: ModelTypes["UsersFields_organiser_description_Create"] | undefined,
	read?: ModelTypes["UsersFields_organiser_description_Read"] | undefined,
	update?: ModelTypes["UsersFields_organiser_description_Update"] | undefined,
	delete?: ModelTypes["UsersFields_organiser_description_Delete"] | undefined
};
	["UsersFields_organiser_description_Create"]: {
		permission: boolean
};
	["UsersFields_organiser_description_Read"]: {
		permission: boolean
};
	["UsersFields_organiser_description_Update"]: {
		permission: boolean
};
	["UsersFields_organiser_description_Delete"]: {
		permission: boolean
};
	["UsersFields_organiser_foundedIn"]: {
		create?: ModelTypes["UsersFields_organiser_foundedIn_Create"] | undefined,
	read?: ModelTypes["UsersFields_organiser_foundedIn_Read"] | undefined,
	update?: ModelTypes["UsersFields_organiser_foundedIn_Update"] | undefined,
	delete?: ModelTypes["UsersFields_organiser_foundedIn_Delete"] | undefined
};
	["UsersFields_organiser_foundedIn_Create"]: {
		permission: boolean
};
	["UsersFields_organiser_foundedIn_Read"]: {
		permission: boolean
};
	["UsersFields_organiser_foundedIn_Update"]: {
		permission: boolean
};
	["UsersFields_organiser_foundedIn_Delete"]: {
		permission: boolean
};
	["UsersFields_organiser_address"]: {
		create?: ModelTypes["UsersFields_organiser_address_Create"] | undefined,
	read?: ModelTypes["UsersFields_organiser_address_Read"] | undefined,
	update?: ModelTypes["UsersFields_organiser_address_Update"] | undefined,
	delete?: ModelTypes["UsersFields_organiser_address_Delete"] | undefined
};
	["UsersFields_organiser_address_Create"]: {
		permission: boolean
};
	["UsersFields_organiser_address_Read"]: {
		permission: boolean
};
	["UsersFields_organiser_address_Update"]: {
		permission: boolean
};
	["UsersFields_organiser_address_Delete"]: {
		permission: boolean
};
	["UsersFields_organiser_website"]: {
		create?: ModelTypes["UsersFields_organiser_website_Create"] | undefined,
	read?: ModelTypes["UsersFields_organiser_website_Read"] | undefined,
	update?: ModelTypes["UsersFields_organiser_website_Update"] | undefined,
	delete?: ModelTypes["UsersFields_organiser_website_Delete"] | undefined
};
	["UsersFields_organiser_website_Create"]: {
		permission: boolean
};
	["UsersFields_organiser_website_Read"]: {
		permission: boolean
};
	["UsersFields_organiser_website_Update"]: {
		permission: boolean
};
	["UsersFields_organiser_website_Delete"]: {
		permission: boolean
};
	["UsersFields_organiser_facebook"]: {
		create?: ModelTypes["UsersFields_organiser_facebook_Create"] | undefined,
	read?: ModelTypes["UsersFields_organiser_facebook_Read"] | undefined,
	update?: ModelTypes["UsersFields_organiser_facebook_Update"] | undefined,
	delete?: ModelTypes["UsersFields_organiser_facebook_Delete"] | undefined
};
	["UsersFields_organiser_facebook_Create"]: {
		permission: boolean
};
	["UsersFields_organiser_facebook_Read"]: {
		permission: boolean
};
	["UsersFields_organiser_facebook_Update"]: {
		permission: boolean
};
	["UsersFields_organiser_facebook_Delete"]: {
		permission: boolean
};
	["UsersFields_organiser_instagram"]: {
		create?: ModelTypes["UsersFields_organiser_instagram_Create"] | undefined,
	read?: ModelTypes["UsersFields_organiser_instagram_Read"] | undefined,
	update?: ModelTypes["UsersFields_organiser_instagram_Update"] | undefined,
	delete?: ModelTypes["UsersFields_organiser_instagram_Delete"] | undefined
};
	["UsersFields_organiser_instagram_Create"]: {
		permission: boolean
};
	["UsersFields_organiser_instagram_Read"]: {
		permission: boolean
};
	["UsersFields_organiser_instagram_Update"]: {
		permission: boolean
};
	["UsersFields_organiser_instagram_Delete"]: {
		permission: boolean
};
	["UsersFields_organiser_language"]: {
		create?: ModelTypes["UsersFields_organiser_language_Create"] | undefined,
	read?: ModelTypes["UsersFields_organiser_language_Read"] | undefined,
	update?: ModelTypes["UsersFields_organiser_language_Update"] | undefined,
	delete?: ModelTypes["UsersFields_organiser_language_Delete"] | undefined
};
	["UsersFields_organiser_language_Create"]: {
		permission: boolean
};
	["UsersFields_organiser_language_Read"]: {
		permission: boolean
};
	["UsersFields_organiser_language_Update"]: {
		permission: boolean
};
	["UsersFields_organiser_language_Delete"]: {
		permission: boolean
};
	["UsersFields_organiser_phone"]: {
		create?: ModelTypes["UsersFields_organiser_phone_Create"] | undefined,
	read?: ModelTypes["UsersFields_organiser_phone_Read"] | undefined,
	update?: ModelTypes["UsersFields_organiser_phone_Update"] | undefined,
	delete?: ModelTypes["UsersFields_organiser_phone_Delete"] | undefined
};
	["UsersFields_organiser_phone_Create"]: {
		permission: boolean
};
	["UsersFields_organiser_phone_Read"]: {
		permission: boolean
};
	["UsersFields_organiser_phone_Update"]: {
		permission: boolean
};
	["UsersFields_organiser_phone_Delete"]: {
		permission: boolean
};
	["UsersFields_organiser_followers"]: {
		create?: ModelTypes["UsersFields_organiser_followers_Create"] | undefined,
	read?: ModelTypes["UsersFields_organiser_followers_Read"] | undefined,
	update?: ModelTypes["UsersFields_organiser_followers_Update"] | undefined,
	delete?: ModelTypes["UsersFields_organiser_followers_Delete"] | undefined
};
	["UsersFields_organiser_followers_Create"]: {
		permission: boolean
};
	["UsersFields_organiser_followers_Read"]: {
		permission: boolean
};
	["UsersFields_organiser_followers_Update"]: {
		permission: boolean
};
	["UsersFields_organiser_followers_Delete"]: {
		permission: boolean
};
	["UsersFields_emailVerified"]: {
		create?: ModelTypes["UsersFields_emailVerified_Create"] | undefined,
	read?: ModelTypes["UsersFields_emailVerified_Read"] | undefined,
	update?: ModelTypes["UsersFields_emailVerified_Update"] | undefined,
	delete?: ModelTypes["UsersFields_emailVerified_Delete"] | undefined
};
	["UsersFields_emailVerified_Create"]: {
		permission: boolean
};
	["UsersFields_emailVerified_Read"]: {
		permission: boolean
};
	["UsersFields_emailVerified_Update"]: {
		permission: boolean
};
	["UsersFields_emailVerified_Delete"]: {
		permission: boolean
};
	["UsersFields_sso"]: {
		create?: ModelTypes["UsersFields_sso_Create"] | undefined,
	read?: ModelTypes["UsersFields_sso_Read"] | undefined,
	update?: ModelTypes["UsersFields_sso_Update"] | undefined,
	delete?: ModelTypes["UsersFields_sso_Delete"] | undefined,
	fields?: ModelTypes["UsersFields_sso_Fields"] | undefined
};
	["UsersFields_sso_Create"]: {
		permission: boolean
};
	["UsersFields_sso_Read"]: {
		permission: boolean
};
	["UsersFields_sso_Update"]: {
		permission: boolean
};
	["UsersFields_sso_Delete"]: {
		permission: boolean
};
	["UsersFields_sso_Fields"]: {
		googleId?: ModelTypes["UsersFields_sso_googleId"] | undefined,
	facebookId?: ModelTypes["UsersFields_sso_facebookId"] | undefined,
	appleId?: ModelTypes["UsersFields_sso_appleId"] | undefined
};
	["UsersFields_sso_googleId"]: {
		create?: ModelTypes["UsersFields_sso_googleId_Create"] | undefined,
	read?: ModelTypes["UsersFields_sso_googleId_Read"] | undefined,
	update?: ModelTypes["UsersFields_sso_googleId_Update"] | undefined,
	delete?: ModelTypes["UsersFields_sso_googleId_Delete"] | undefined
};
	["UsersFields_sso_googleId_Create"]: {
		permission: boolean
};
	["UsersFields_sso_googleId_Read"]: {
		permission: boolean
};
	["UsersFields_sso_googleId_Update"]: {
		permission: boolean
};
	["UsersFields_sso_googleId_Delete"]: {
		permission: boolean
};
	["UsersFields_sso_facebookId"]: {
		create?: ModelTypes["UsersFields_sso_facebookId_Create"] | undefined,
	read?: ModelTypes["UsersFields_sso_facebookId_Read"] | undefined,
	update?: ModelTypes["UsersFields_sso_facebookId_Update"] | undefined,
	delete?: ModelTypes["UsersFields_sso_facebookId_Delete"] | undefined
};
	["UsersFields_sso_facebookId_Create"]: {
		permission: boolean
};
	["UsersFields_sso_facebookId_Read"]: {
		permission: boolean
};
	["UsersFields_sso_facebookId_Update"]: {
		permission: boolean
};
	["UsersFields_sso_facebookId_Delete"]: {
		permission: boolean
};
	["UsersFields_sso_appleId"]: {
		create?: ModelTypes["UsersFields_sso_appleId_Create"] | undefined,
	read?: ModelTypes["UsersFields_sso_appleId_Read"] | undefined,
	update?: ModelTypes["UsersFields_sso_appleId_Update"] | undefined,
	delete?: ModelTypes["UsersFields_sso_appleId_Delete"] | undefined
};
	["UsersFields_sso_appleId_Create"]: {
		permission: boolean
};
	["UsersFields_sso_appleId_Read"]: {
		permission: boolean
};
	["UsersFields_sso_appleId_Update"]: {
		permission: boolean
};
	["UsersFields_sso_appleId_Delete"]: {
		permission: boolean
};
	["UsersFields_oldPassword"]: {
		create?: ModelTypes["UsersFields_oldPassword_Create"] | undefined,
	read?: ModelTypes["UsersFields_oldPassword_Read"] | undefined,
	update?: ModelTypes["UsersFields_oldPassword_Update"] | undefined,
	delete?: ModelTypes["UsersFields_oldPassword_Delete"] | undefined
};
	["UsersFields_oldPassword_Create"]: {
		permission: boolean
};
	["UsersFields_oldPassword_Read"]: {
		permission: boolean
};
	["UsersFields_oldPassword_Update"]: {
		permission: boolean
};
	["UsersFields_oldPassword_Delete"]: {
		permission: boolean
};
	["UsersFields_status"]: {
		create?: ModelTypes["UsersFields_status_Create"] | undefined,
	read?: ModelTypes["UsersFields_status_Read"] | undefined,
	update?: ModelTypes["UsersFields_status_Update"] | undefined,
	delete?: ModelTypes["UsersFields_status_Delete"] | undefined
};
	["UsersFields_status_Create"]: {
		permission: boolean
};
	["UsersFields_status_Read"]: {
		permission: boolean
};
	["UsersFields_status_Update"]: {
		permission: boolean
};
	["UsersFields_status_Delete"]: {
		permission: boolean
};
	["UsersFields_updatedAt"]: {
		create?: ModelTypes["UsersFields_updatedAt_Create"] | undefined,
	read?: ModelTypes["UsersFields_updatedAt_Read"] | undefined,
	update?: ModelTypes["UsersFields_updatedAt_Update"] | undefined,
	delete?: ModelTypes["UsersFields_updatedAt_Delete"] | undefined
};
	["UsersFields_updatedAt_Create"]: {
		permission: boolean
};
	["UsersFields_updatedAt_Read"]: {
		permission: boolean
};
	["UsersFields_updatedAt_Update"]: {
		permission: boolean
};
	["UsersFields_updatedAt_Delete"]: {
		permission: boolean
};
	["UsersFields_createdAt"]: {
		create?: ModelTypes["UsersFields_createdAt_Create"] | undefined,
	read?: ModelTypes["UsersFields_createdAt_Read"] | undefined,
	update?: ModelTypes["UsersFields_createdAt_Update"] | undefined,
	delete?: ModelTypes["UsersFields_createdAt_Delete"] | undefined
};
	["UsersFields_createdAt_Create"]: {
		permission: boolean
};
	["UsersFields_createdAt_Read"]: {
		permission: boolean
};
	["UsersFields_createdAt_Update"]: {
		permission: boolean
};
	["UsersFields_createdAt_Delete"]: {
		permission: boolean
};
	["UsersFields_email"]: {
		create?: ModelTypes["UsersFields_email_Create"] | undefined,
	read?: ModelTypes["UsersFields_email_Read"] | undefined,
	update?: ModelTypes["UsersFields_email_Update"] | undefined,
	delete?: ModelTypes["UsersFields_email_Delete"] | undefined
};
	["UsersFields_email_Create"]: {
		permission: boolean
};
	["UsersFields_email_Read"]: {
		permission: boolean
};
	["UsersFields_email_Update"]: {
		permission: boolean
};
	["UsersFields_email_Delete"]: {
		permission: boolean
};
	["UsersFields_password"]: {
		create?: ModelTypes["UsersFields_password_Create"] | undefined,
	read?: ModelTypes["UsersFields_password_Read"] | undefined,
	update?: ModelTypes["UsersFields_password_Update"] | undefined,
	delete?: ModelTypes["UsersFields_password_Delete"] | undefined
};
	["UsersFields_password_Create"]: {
		permission: boolean
};
	["UsersFields_password_Read"]: {
		permission: boolean
};
	["UsersFields_password_Update"]: {
		permission: boolean
};
	["UsersFields_password_Delete"]: {
		permission: boolean
};
	["UsersCreateAccess"]: {
		permission: boolean,
	where?: ModelTypes["JSONObject"] | undefined
};
	["UsersReadAccess"]: {
		permission: boolean,
	where?: ModelTypes["JSONObject"] | undefined
};
	["UsersUpdateAccess"]: {
		permission: boolean,
	where?: ModelTypes["JSONObject"] | undefined
};
	["UsersDeleteAccess"]: {
		permission: boolean,
	where?: ModelTypes["JSONObject"] | undefined
};
	["UsersUnlockAccess"]: {
		permission: boolean,
	where?: ModelTypes["JSONObject"] | undefined
};
	["bookmarksAccess"]: {
		fields?: ModelTypes["BookmarksFields"] | undefined,
	create?: ModelTypes["BookmarksCreateAccess"] | undefined,
	read?: ModelTypes["BookmarksReadAccess"] | undefined,
	update?: ModelTypes["BookmarksUpdateAccess"] | undefined,
	delete?: ModelTypes["BookmarksDeleteAccess"] | undefined
};
	["BookmarksFields"]: {
		user?: ModelTypes["BookmarksFields_user"] | undefined,
	item?: ModelTypes["BookmarksFields_item"] | undefined,
	updatedAt?: ModelTypes["BookmarksFields_updatedAt"] | undefined,
	createdAt?: ModelTypes["BookmarksFields_createdAt"] | undefined
};
	["BookmarksFields_user"]: {
		create?: ModelTypes["BookmarksFields_user_Create"] | undefined,
	read?: ModelTypes["BookmarksFields_user_Read"] | undefined,
	update?: ModelTypes["BookmarksFields_user_Update"] | undefined,
	delete?: ModelTypes["BookmarksFields_user_Delete"] | undefined
};
	["BookmarksFields_user_Create"]: {
		permission: boolean
};
	["BookmarksFields_user_Read"]: {
		permission: boolean
};
	["BookmarksFields_user_Update"]: {
		permission: boolean
};
	["BookmarksFields_user_Delete"]: {
		permission: boolean
};
	["BookmarksFields_item"]: {
		create?: ModelTypes["BookmarksFields_item_Create"] | undefined,
	read?: ModelTypes["BookmarksFields_item_Read"] | undefined,
	update?: ModelTypes["BookmarksFields_item_Update"] | undefined,
	delete?: ModelTypes["BookmarksFields_item_Delete"] | undefined
};
	["BookmarksFields_item_Create"]: {
		permission: boolean
};
	["BookmarksFields_item_Read"]: {
		permission: boolean
};
	["BookmarksFields_item_Update"]: {
		permission: boolean
};
	["BookmarksFields_item_Delete"]: {
		permission: boolean
};
	["BookmarksFields_updatedAt"]: {
		create?: ModelTypes["BookmarksFields_updatedAt_Create"] | undefined,
	read?: ModelTypes["BookmarksFields_updatedAt_Read"] | undefined,
	update?: ModelTypes["BookmarksFields_updatedAt_Update"] | undefined,
	delete?: ModelTypes["BookmarksFields_updatedAt_Delete"] | undefined
};
	["BookmarksFields_updatedAt_Create"]: {
		permission: boolean
};
	["BookmarksFields_updatedAt_Read"]: {
		permission: boolean
};
	["BookmarksFields_updatedAt_Update"]: {
		permission: boolean
};
	["BookmarksFields_updatedAt_Delete"]: {
		permission: boolean
};
	["BookmarksFields_createdAt"]: {
		create?: ModelTypes["BookmarksFields_createdAt_Create"] | undefined,
	read?: ModelTypes["BookmarksFields_createdAt_Read"] | undefined,
	update?: ModelTypes["BookmarksFields_createdAt_Update"] | undefined,
	delete?: ModelTypes["BookmarksFields_createdAt_Delete"] | undefined
};
	["BookmarksFields_createdAt_Create"]: {
		permission: boolean
};
	["BookmarksFields_createdAt_Read"]: {
		permission: boolean
};
	["BookmarksFields_createdAt_Update"]: {
		permission: boolean
};
	["BookmarksFields_createdAt_Delete"]: {
		permission: boolean
};
	["BookmarksCreateAccess"]: {
		permission: boolean,
	where?: ModelTypes["JSONObject"] | undefined
};
	["BookmarksReadAccess"]: {
		permission: boolean,
	where?: ModelTypes["JSONObject"] | undefined
};
	["BookmarksUpdateAccess"]: {
		permission: boolean,
	where?: ModelTypes["JSONObject"] | undefined
};
	["BookmarksDeleteAccess"]: {
		permission: boolean,
	where?: ModelTypes["JSONObject"] | undefined
};
	["webAppBookmarksAccess"]: {
		fields?: ModelTypes["WebAppBookmarksFields"] | undefined,
	create?: ModelTypes["WebAppBookmarksCreateAccess"] | undefined,
	read?: ModelTypes["WebAppBookmarksReadAccess"] | undefined,
	update?: ModelTypes["WebAppBookmarksUpdateAccess"] | undefined,
	delete?: ModelTypes["WebAppBookmarksDeleteAccess"] | undefined
};
	["WebAppBookmarksFields"]: {
		user?: ModelTypes["WebAppBookmarksFields_user"] | undefined,
	event?: ModelTypes["WebAppBookmarksFields_event"] | undefined,
	productType?: ModelTypes["WebAppBookmarksFields_productType"] | undefined,
	item?: ModelTypes["WebAppBookmarksFields_item"] | undefined,
	entryNumber?: ModelTypes["WebAppBookmarksFields_entryNumber"] | undefined,
	updatedAt?: ModelTypes["WebAppBookmarksFields_updatedAt"] | undefined,
	createdAt?: ModelTypes["WebAppBookmarksFields_createdAt"] | undefined
};
	["WebAppBookmarksFields_user"]: {
		create?: ModelTypes["WebAppBookmarksFields_user_Create"] | undefined,
	read?: ModelTypes["WebAppBookmarksFields_user_Read"] | undefined,
	update?: ModelTypes["WebAppBookmarksFields_user_Update"] | undefined,
	delete?: ModelTypes["WebAppBookmarksFields_user_Delete"] | undefined
};
	["WebAppBookmarksFields_user_Create"]: {
		permission: boolean
};
	["WebAppBookmarksFields_user_Read"]: {
		permission: boolean
};
	["WebAppBookmarksFields_user_Update"]: {
		permission: boolean
};
	["WebAppBookmarksFields_user_Delete"]: {
		permission: boolean
};
	["WebAppBookmarksFields_event"]: {
		create?: ModelTypes["WebAppBookmarksFields_event_Create"] | undefined,
	read?: ModelTypes["WebAppBookmarksFields_event_Read"] | undefined,
	update?: ModelTypes["WebAppBookmarksFields_event_Update"] | undefined,
	delete?: ModelTypes["WebAppBookmarksFields_event_Delete"] | undefined
};
	["WebAppBookmarksFields_event_Create"]: {
		permission: boolean
};
	["WebAppBookmarksFields_event_Read"]: {
		permission: boolean
};
	["WebAppBookmarksFields_event_Update"]: {
		permission: boolean
};
	["WebAppBookmarksFields_event_Delete"]: {
		permission: boolean
};
	["WebAppBookmarksFields_productType"]: {
		create?: ModelTypes["WebAppBookmarksFields_productType_Create"] | undefined,
	read?: ModelTypes["WebAppBookmarksFields_productType_Read"] | undefined,
	update?: ModelTypes["WebAppBookmarksFields_productType_Update"] | undefined,
	delete?: ModelTypes["WebAppBookmarksFields_productType_Delete"] | undefined
};
	["WebAppBookmarksFields_productType_Create"]: {
		permission: boolean
};
	["WebAppBookmarksFields_productType_Read"]: {
		permission: boolean
};
	["WebAppBookmarksFields_productType_Update"]: {
		permission: boolean
};
	["WebAppBookmarksFields_productType_Delete"]: {
		permission: boolean
};
	["WebAppBookmarksFields_item"]: {
		create?: ModelTypes["WebAppBookmarksFields_item_Create"] | undefined,
	read?: ModelTypes["WebAppBookmarksFields_item_Read"] | undefined,
	update?: ModelTypes["WebAppBookmarksFields_item_Update"] | undefined,
	delete?: ModelTypes["WebAppBookmarksFields_item_Delete"] | undefined
};
	["WebAppBookmarksFields_item_Create"]: {
		permission: boolean
};
	["WebAppBookmarksFields_item_Read"]: {
		permission: boolean
};
	["WebAppBookmarksFields_item_Update"]: {
		permission: boolean
};
	["WebAppBookmarksFields_item_Delete"]: {
		permission: boolean
};
	["WebAppBookmarksFields_entryNumber"]: {
		create?: ModelTypes["WebAppBookmarksFields_entryNumber_Create"] | undefined,
	read?: ModelTypes["WebAppBookmarksFields_entryNumber_Read"] | undefined,
	update?: ModelTypes["WebAppBookmarksFields_entryNumber_Update"] | undefined,
	delete?: ModelTypes["WebAppBookmarksFields_entryNumber_Delete"] | undefined
};
	["WebAppBookmarksFields_entryNumber_Create"]: {
		permission: boolean
};
	["WebAppBookmarksFields_entryNumber_Read"]: {
		permission: boolean
};
	["WebAppBookmarksFields_entryNumber_Update"]: {
		permission: boolean
};
	["WebAppBookmarksFields_entryNumber_Delete"]: {
		permission: boolean
};
	["WebAppBookmarksFields_updatedAt"]: {
		create?: ModelTypes["WebAppBookmarksFields_updatedAt_Create"] | undefined,
	read?: ModelTypes["WebAppBookmarksFields_updatedAt_Read"] | undefined,
	update?: ModelTypes["WebAppBookmarksFields_updatedAt_Update"] | undefined,
	delete?: ModelTypes["WebAppBookmarksFields_updatedAt_Delete"] | undefined
};
	["WebAppBookmarksFields_updatedAt_Create"]: {
		permission: boolean
};
	["WebAppBookmarksFields_updatedAt_Read"]: {
		permission: boolean
};
	["WebAppBookmarksFields_updatedAt_Update"]: {
		permission: boolean
};
	["WebAppBookmarksFields_updatedAt_Delete"]: {
		permission: boolean
};
	["WebAppBookmarksFields_createdAt"]: {
		create?: ModelTypes["WebAppBookmarksFields_createdAt_Create"] | undefined,
	read?: ModelTypes["WebAppBookmarksFields_createdAt_Read"] | undefined,
	update?: ModelTypes["WebAppBookmarksFields_createdAt_Update"] | undefined,
	delete?: ModelTypes["WebAppBookmarksFields_createdAt_Delete"] | undefined
};
	["WebAppBookmarksFields_createdAt_Create"]: {
		permission: boolean
};
	["WebAppBookmarksFields_createdAt_Read"]: {
		permission: boolean
};
	["WebAppBookmarksFields_createdAt_Update"]: {
		permission: boolean
};
	["WebAppBookmarksFields_createdAt_Delete"]: {
		permission: boolean
};
	["WebAppBookmarksCreateAccess"]: {
		permission: boolean,
	where?: ModelTypes["JSONObject"] | undefined
};
	["WebAppBookmarksReadAccess"]: {
		permission: boolean,
	where?: ModelTypes["JSONObject"] | undefined
};
	["WebAppBookmarksUpdateAccess"]: {
		permission: boolean,
	where?: ModelTypes["JSONObject"] | undefined
};
	["WebAppBookmarksDeleteAccess"]: {
		permission: boolean,
	where?: ModelTypes["JSONObject"] | undefined
};
	["view_recordsAccess"]: {
		fields?: ModelTypes["ViewRecordsFields"] | undefined,
	create?: ModelTypes["ViewRecordsCreateAccess"] | undefined,
	read?: ModelTypes["ViewRecordsReadAccess"] | undefined,
	update?: ModelTypes["ViewRecordsUpdateAccess"] | undefined,
	delete?: ModelTypes["ViewRecordsDeleteAccess"] | undefined
};
	["ViewRecordsFields"]: {
		user?: ModelTypes["ViewRecordsFields_user"] | undefined,
	item?: ModelTypes["ViewRecordsFields_item"] | undefined,
	updatedAt?: ModelTypes["ViewRecordsFields_updatedAt"] | undefined,
	createdAt?: ModelTypes["ViewRecordsFields_createdAt"] | undefined
};
	["ViewRecordsFields_user"]: {
		create?: ModelTypes["ViewRecordsFields_user_Create"] | undefined,
	read?: ModelTypes["ViewRecordsFields_user_Read"] | undefined,
	update?: ModelTypes["ViewRecordsFields_user_Update"] | undefined,
	delete?: ModelTypes["ViewRecordsFields_user_Delete"] | undefined
};
	["ViewRecordsFields_user_Create"]: {
		permission: boolean
};
	["ViewRecordsFields_user_Read"]: {
		permission: boolean
};
	["ViewRecordsFields_user_Update"]: {
		permission: boolean
};
	["ViewRecordsFields_user_Delete"]: {
		permission: boolean
};
	["ViewRecordsFields_item"]: {
		create?: ModelTypes["ViewRecordsFields_item_Create"] | undefined,
	read?: ModelTypes["ViewRecordsFields_item_Read"] | undefined,
	update?: ModelTypes["ViewRecordsFields_item_Update"] | undefined,
	delete?: ModelTypes["ViewRecordsFields_item_Delete"] | undefined
};
	["ViewRecordsFields_item_Create"]: {
		permission: boolean
};
	["ViewRecordsFields_item_Read"]: {
		permission: boolean
};
	["ViewRecordsFields_item_Update"]: {
		permission: boolean
};
	["ViewRecordsFields_item_Delete"]: {
		permission: boolean
};
	["ViewRecordsFields_updatedAt"]: {
		create?: ModelTypes["ViewRecordsFields_updatedAt_Create"] | undefined,
	read?: ModelTypes["ViewRecordsFields_updatedAt_Read"] | undefined,
	update?: ModelTypes["ViewRecordsFields_updatedAt_Update"] | undefined,
	delete?: ModelTypes["ViewRecordsFields_updatedAt_Delete"] | undefined
};
	["ViewRecordsFields_updatedAt_Create"]: {
		permission: boolean
};
	["ViewRecordsFields_updatedAt_Read"]: {
		permission: boolean
};
	["ViewRecordsFields_updatedAt_Update"]: {
		permission: boolean
};
	["ViewRecordsFields_updatedAt_Delete"]: {
		permission: boolean
};
	["ViewRecordsFields_createdAt"]: {
		create?: ModelTypes["ViewRecordsFields_createdAt_Create"] | undefined,
	read?: ModelTypes["ViewRecordsFields_createdAt_Read"] | undefined,
	update?: ModelTypes["ViewRecordsFields_createdAt_Update"] | undefined,
	delete?: ModelTypes["ViewRecordsFields_createdAt_Delete"] | undefined
};
	["ViewRecordsFields_createdAt_Create"]: {
		permission: boolean
};
	["ViewRecordsFields_createdAt_Read"]: {
		permission: boolean
};
	["ViewRecordsFields_createdAt_Update"]: {
		permission: boolean
};
	["ViewRecordsFields_createdAt_Delete"]: {
		permission: boolean
};
	["ViewRecordsCreateAccess"]: {
		permission: boolean,
	where?: ModelTypes["JSONObject"] | undefined
};
	["ViewRecordsReadAccess"]: {
		permission: boolean,
	where?: ModelTypes["JSONObject"] | undefined
};
	["ViewRecordsUpdateAccess"]: {
		permission: boolean,
	where?: ModelTypes["JSONObject"] | undefined
};
	["ViewRecordsDeleteAccess"]: {
		permission: boolean,
	where?: ModelTypes["JSONObject"] | undefined
};
	["commentsAccess"]: {
		fields?: ModelTypes["CommentsFields"] | undefined,
	create?: ModelTypes["CommentsCreateAccess"] | undefined,
	read?: ModelTypes["CommentsReadAccess"] | undefined,
	update?: ModelTypes["CommentsUpdateAccess"] | undefined,
	delete?: ModelTypes["CommentsDeleteAccess"] | undefined
};
	["CommentsFields"]: {
		commenter?: ModelTypes["CommentsFields_commenter"] | undefined,
	content?: ModelTypes["CommentsFields_content"] | undefined,
	image?: ModelTypes["CommentsFields_image"] | undefined,
	target?: ModelTypes["CommentsFields_target"] | undefined,
	parent?: ModelTypes["CommentsFields_parent"] | undefined,
	isReviewed?: ModelTypes["CommentsFields_isReviewed"] | undefined,
	reviewedBy?: ModelTypes["CommentsFields_reviewedBy"] | undefined,
	banned?: ModelTypes["CommentsFields_banned"] | undefined,
	type?: ModelTypes["CommentsFields_type"] | undefined,
	updatedAt?: ModelTypes["CommentsFields_updatedAt"] | undefined,
	createdAt?: ModelTypes["CommentsFields_createdAt"] | undefined
};
	["CommentsFields_commenter"]: {
		create?: ModelTypes["CommentsFields_commenter_Create"] | undefined,
	read?: ModelTypes["CommentsFields_commenter_Read"] | undefined,
	update?: ModelTypes["CommentsFields_commenter_Update"] | undefined,
	delete?: ModelTypes["CommentsFields_commenter_Delete"] | undefined
};
	["CommentsFields_commenter_Create"]: {
		permission: boolean
};
	["CommentsFields_commenter_Read"]: {
		permission: boolean
};
	["CommentsFields_commenter_Update"]: {
		permission: boolean
};
	["CommentsFields_commenter_Delete"]: {
		permission: boolean
};
	["CommentsFields_content"]: {
		create?: ModelTypes["CommentsFields_content_Create"] | undefined,
	read?: ModelTypes["CommentsFields_content_Read"] | undefined,
	update?: ModelTypes["CommentsFields_content_Update"] | undefined,
	delete?: ModelTypes["CommentsFields_content_Delete"] | undefined
};
	["CommentsFields_content_Create"]: {
		permission: boolean
};
	["CommentsFields_content_Read"]: {
		permission: boolean
};
	["CommentsFields_content_Update"]: {
		permission: boolean
};
	["CommentsFields_content_Delete"]: {
		permission: boolean
};
	["CommentsFields_image"]: {
		create?: ModelTypes["CommentsFields_image_Create"] | undefined,
	read?: ModelTypes["CommentsFields_image_Read"] | undefined,
	update?: ModelTypes["CommentsFields_image_Update"] | undefined,
	delete?: ModelTypes["CommentsFields_image_Delete"] | undefined
};
	["CommentsFields_image_Create"]: {
		permission: boolean
};
	["CommentsFields_image_Read"]: {
		permission: boolean
};
	["CommentsFields_image_Update"]: {
		permission: boolean
};
	["CommentsFields_image_Delete"]: {
		permission: boolean
};
	["CommentsFields_target"]: {
		create?: ModelTypes["CommentsFields_target_Create"] | undefined,
	read?: ModelTypes["CommentsFields_target_Read"] | undefined,
	update?: ModelTypes["CommentsFields_target_Update"] | undefined,
	delete?: ModelTypes["CommentsFields_target_Delete"] | undefined
};
	["CommentsFields_target_Create"]: {
		permission: boolean
};
	["CommentsFields_target_Read"]: {
		permission: boolean
};
	["CommentsFields_target_Update"]: {
		permission: boolean
};
	["CommentsFields_target_Delete"]: {
		permission: boolean
};
	["CommentsFields_parent"]: {
		create?: ModelTypes["CommentsFields_parent_Create"] | undefined,
	read?: ModelTypes["CommentsFields_parent_Read"] | undefined,
	update?: ModelTypes["CommentsFields_parent_Update"] | undefined,
	delete?: ModelTypes["CommentsFields_parent_Delete"] | undefined
};
	["CommentsFields_parent_Create"]: {
		permission: boolean
};
	["CommentsFields_parent_Read"]: {
		permission: boolean
};
	["CommentsFields_parent_Update"]: {
		permission: boolean
};
	["CommentsFields_parent_Delete"]: {
		permission: boolean
};
	["CommentsFields_isReviewed"]: {
		create?: ModelTypes["CommentsFields_isReviewed_Create"] | undefined,
	read?: ModelTypes["CommentsFields_isReviewed_Read"] | undefined,
	update?: ModelTypes["CommentsFields_isReviewed_Update"] | undefined,
	delete?: ModelTypes["CommentsFields_isReviewed_Delete"] | undefined
};
	["CommentsFields_isReviewed_Create"]: {
		permission: boolean
};
	["CommentsFields_isReviewed_Read"]: {
		permission: boolean
};
	["CommentsFields_isReviewed_Update"]: {
		permission: boolean
};
	["CommentsFields_isReviewed_Delete"]: {
		permission: boolean
};
	["CommentsFields_reviewedBy"]: {
		create?: ModelTypes["CommentsFields_reviewedBy_Create"] | undefined,
	read?: ModelTypes["CommentsFields_reviewedBy_Read"] | undefined,
	update?: ModelTypes["CommentsFields_reviewedBy_Update"] | undefined,
	delete?: ModelTypes["CommentsFields_reviewedBy_Delete"] | undefined
};
	["CommentsFields_reviewedBy_Create"]: {
		permission: boolean
};
	["CommentsFields_reviewedBy_Read"]: {
		permission: boolean
};
	["CommentsFields_reviewedBy_Update"]: {
		permission: boolean
};
	["CommentsFields_reviewedBy_Delete"]: {
		permission: boolean
};
	["CommentsFields_banned"]: {
		create?: ModelTypes["CommentsFields_banned_Create"] | undefined,
	read?: ModelTypes["CommentsFields_banned_Read"] | undefined,
	update?: ModelTypes["CommentsFields_banned_Update"] | undefined,
	delete?: ModelTypes["CommentsFields_banned_Delete"] | undefined
};
	["CommentsFields_banned_Create"]: {
		permission: boolean
};
	["CommentsFields_banned_Read"]: {
		permission: boolean
};
	["CommentsFields_banned_Update"]: {
		permission: boolean
};
	["CommentsFields_banned_Delete"]: {
		permission: boolean
};
	["CommentsFields_type"]: {
		create?: ModelTypes["CommentsFields_type_Create"] | undefined,
	read?: ModelTypes["CommentsFields_type_Read"] | undefined,
	update?: ModelTypes["CommentsFields_type_Update"] | undefined,
	delete?: ModelTypes["CommentsFields_type_Delete"] | undefined
};
	["CommentsFields_type_Create"]: {
		permission: boolean
};
	["CommentsFields_type_Read"]: {
		permission: boolean
};
	["CommentsFields_type_Update"]: {
		permission: boolean
};
	["CommentsFields_type_Delete"]: {
		permission: boolean
};
	["CommentsFields_updatedAt"]: {
		create?: ModelTypes["CommentsFields_updatedAt_Create"] | undefined,
	read?: ModelTypes["CommentsFields_updatedAt_Read"] | undefined,
	update?: ModelTypes["CommentsFields_updatedAt_Update"] | undefined,
	delete?: ModelTypes["CommentsFields_updatedAt_Delete"] | undefined
};
	["CommentsFields_updatedAt_Create"]: {
		permission: boolean
};
	["CommentsFields_updatedAt_Read"]: {
		permission: boolean
};
	["CommentsFields_updatedAt_Update"]: {
		permission: boolean
};
	["CommentsFields_updatedAt_Delete"]: {
		permission: boolean
};
	["CommentsFields_createdAt"]: {
		create?: ModelTypes["CommentsFields_createdAt_Create"] | undefined,
	read?: ModelTypes["CommentsFields_createdAt_Read"] | undefined,
	update?: ModelTypes["CommentsFields_createdAt_Update"] | undefined,
	delete?: ModelTypes["CommentsFields_createdAt_Delete"] | undefined
};
	["CommentsFields_createdAt_Create"]: {
		permission: boolean
};
	["CommentsFields_createdAt_Read"]: {
		permission: boolean
};
	["CommentsFields_createdAt_Update"]: {
		permission: boolean
};
	["CommentsFields_createdAt_Delete"]: {
		permission: boolean
};
	["CommentsCreateAccess"]: {
		permission: boolean,
	where?: ModelTypes["JSONObject"] | undefined
};
	["CommentsReadAccess"]: {
		permission: boolean,
	where?: ModelTypes["JSONObject"] | undefined
};
	["CommentsUpdateAccess"]: {
		permission: boolean,
	where?: ModelTypes["JSONObject"] | undefined
};
	["CommentsDeleteAccess"]: {
		permission: boolean,
	where?: ModelTypes["JSONObject"] | undefined
};
	["subscriptionsAccess"]: {
		fields?: ModelTypes["SubscriptionsFields"] | undefined,
	create?: ModelTypes["SubscriptionsCreateAccess"] | undefined,
	read?: ModelTypes["SubscriptionsReadAccess"] | undefined,
	update?: ModelTypes["SubscriptionsUpdateAccess"] | undefined,
	delete?: ModelTypes["SubscriptionsDeleteAccess"] | undefined
};
	["SubscriptionsFields"]: {
		user?: ModelTypes["SubscriptionsFields_user"] | undefined,
	organiser?: ModelTypes["SubscriptionsFields_organiser"] | undefined,
	updatedAt?: ModelTypes["SubscriptionsFields_updatedAt"] | undefined,
	createdAt?: ModelTypes["SubscriptionsFields_createdAt"] | undefined
};
	["SubscriptionsFields_user"]: {
		create?: ModelTypes["SubscriptionsFields_user_Create"] | undefined,
	read?: ModelTypes["SubscriptionsFields_user_Read"] | undefined,
	update?: ModelTypes["SubscriptionsFields_user_Update"] | undefined,
	delete?: ModelTypes["SubscriptionsFields_user_Delete"] | undefined
};
	["SubscriptionsFields_user_Create"]: {
		permission: boolean
};
	["SubscriptionsFields_user_Read"]: {
		permission: boolean
};
	["SubscriptionsFields_user_Update"]: {
		permission: boolean
};
	["SubscriptionsFields_user_Delete"]: {
		permission: boolean
};
	["SubscriptionsFields_organiser"]: {
		create?: ModelTypes["SubscriptionsFields_organiser_Create"] | undefined,
	read?: ModelTypes["SubscriptionsFields_organiser_Read"] | undefined,
	update?: ModelTypes["SubscriptionsFields_organiser_Update"] | undefined,
	delete?: ModelTypes["SubscriptionsFields_organiser_Delete"] | undefined
};
	["SubscriptionsFields_organiser_Create"]: {
		permission: boolean
};
	["SubscriptionsFields_organiser_Read"]: {
		permission: boolean
};
	["SubscriptionsFields_organiser_Update"]: {
		permission: boolean
};
	["SubscriptionsFields_organiser_Delete"]: {
		permission: boolean
};
	["SubscriptionsFields_updatedAt"]: {
		create?: ModelTypes["SubscriptionsFields_updatedAt_Create"] | undefined,
	read?: ModelTypes["SubscriptionsFields_updatedAt_Read"] | undefined,
	update?: ModelTypes["SubscriptionsFields_updatedAt_Update"] | undefined,
	delete?: ModelTypes["SubscriptionsFields_updatedAt_Delete"] | undefined
};
	["SubscriptionsFields_updatedAt_Create"]: {
		permission: boolean
};
	["SubscriptionsFields_updatedAt_Read"]: {
		permission: boolean
};
	["SubscriptionsFields_updatedAt_Update"]: {
		permission: boolean
};
	["SubscriptionsFields_updatedAt_Delete"]: {
		permission: boolean
};
	["SubscriptionsFields_createdAt"]: {
		create?: ModelTypes["SubscriptionsFields_createdAt_Create"] | undefined,
	read?: ModelTypes["SubscriptionsFields_createdAt_Read"] | undefined,
	update?: ModelTypes["SubscriptionsFields_createdAt_Update"] | undefined,
	delete?: ModelTypes["SubscriptionsFields_createdAt_Delete"] | undefined
};
	["SubscriptionsFields_createdAt_Create"]: {
		permission: boolean
};
	["SubscriptionsFields_createdAt_Read"]: {
		permission: boolean
};
	["SubscriptionsFields_createdAt_Update"]: {
		permission: boolean
};
	["SubscriptionsFields_createdAt_Delete"]: {
		permission: boolean
};
	["SubscriptionsCreateAccess"]: {
		permission: boolean,
	where?: ModelTypes["JSONObject"] | undefined
};
	["SubscriptionsReadAccess"]: {
		permission: boolean,
	where?: ModelTypes["JSONObject"] | undefined
};
	["SubscriptionsUpdateAccess"]: {
		permission: boolean,
	where?: ModelTypes["JSONObject"] | undefined
};
	["SubscriptionsDeleteAccess"]: {
		permission: boolean,
	where?: ModelTypes["JSONObject"] | undefined
};
	["newsletterAccess"]: {
		fields?: ModelTypes["NewsletterFields"] | undefined,
	create?: ModelTypes["NewsletterCreateAccess"] | undefined,
	read?: ModelTypes["NewsletterReadAccess"] | undefined,
	update?: ModelTypes["NewsletterUpdateAccess"] | undefined,
	delete?: ModelTypes["NewsletterDeleteAccess"] | undefined
};
	["NewsletterFields"]: {
		email?: ModelTypes["NewsletterFields_email"] | undefined,
	region?: ModelTypes["NewsletterFields_region"] | undefined,
	updatedAt?: ModelTypes["NewsletterFields_updatedAt"] | undefined,
	createdAt?: ModelTypes["NewsletterFields_createdAt"] | undefined
};
	["NewsletterFields_email"]: {
		create?: ModelTypes["NewsletterFields_email_Create"] | undefined,
	read?: ModelTypes["NewsletterFields_email_Read"] | undefined,
	update?: ModelTypes["NewsletterFields_email_Update"] | undefined,
	delete?: ModelTypes["NewsletterFields_email_Delete"] | undefined
};
	["NewsletterFields_email_Create"]: {
		permission: boolean
};
	["NewsletterFields_email_Read"]: {
		permission: boolean
};
	["NewsletterFields_email_Update"]: {
		permission: boolean
};
	["NewsletterFields_email_Delete"]: {
		permission: boolean
};
	["NewsletterFields_region"]: {
		create?: ModelTypes["NewsletterFields_region_Create"] | undefined,
	read?: ModelTypes["NewsletterFields_region_Read"] | undefined,
	update?: ModelTypes["NewsletterFields_region_Update"] | undefined,
	delete?: ModelTypes["NewsletterFields_region_Delete"] | undefined
};
	["NewsletterFields_region_Create"]: {
		permission: boolean
};
	["NewsletterFields_region_Read"]: {
		permission: boolean
};
	["NewsletterFields_region_Update"]: {
		permission: boolean
};
	["NewsletterFields_region_Delete"]: {
		permission: boolean
};
	["NewsletterFields_updatedAt"]: {
		create?: ModelTypes["NewsletterFields_updatedAt_Create"] | undefined,
	read?: ModelTypes["NewsletterFields_updatedAt_Read"] | undefined,
	update?: ModelTypes["NewsletterFields_updatedAt_Update"] | undefined,
	delete?: ModelTypes["NewsletterFields_updatedAt_Delete"] | undefined
};
	["NewsletterFields_updatedAt_Create"]: {
		permission: boolean
};
	["NewsletterFields_updatedAt_Read"]: {
		permission: boolean
};
	["NewsletterFields_updatedAt_Update"]: {
		permission: boolean
};
	["NewsletterFields_updatedAt_Delete"]: {
		permission: boolean
};
	["NewsletterFields_createdAt"]: {
		create?: ModelTypes["NewsletterFields_createdAt_Create"] | undefined,
	read?: ModelTypes["NewsletterFields_createdAt_Read"] | undefined,
	update?: ModelTypes["NewsletterFields_createdAt_Update"] | undefined,
	delete?: ModelTypes["NewsletterFields_createdAt_Delete"] | undefined
};
	["NewsletterFields_createdAt_Create"]: {
		permission: boolean
};
	["NewsletterFields_createdAt_Read"]: {
		permission: boolean
};
	["NewsletterFields_createdAt_Update"]: {
		permission: boolean
};
	["NewsletterFields_createdAt_Delete"]: {
		permission: boolean
};
	["NewsletterCreateAccess"]: {
		permission: boolean,
	where?: ModelTypes["JSONObject"] | undefined
};
	["NewsletterReadAccess"]: {
		permission: boolean,
	where?: ModelTypes["JSONObject"] | undefined
};
	["NewsletterUpdateAccess"]: {
		permission: boolean,
	where?: ModelTypes["JSONObject"] | undefined
};
	["NewsletterDeleteAccess"]: {
		permission: boolean,
	where?: ModelTypes["JSONObject"] | undefined
};
	["blog_categoriesAccess"]: {
		fields?: ModelTypes["BlogCategoriesFields"] | undefined,
	create?: ModelTypes["BlogCategoriesCreateAccess"] | undefined,
	read?: ModelTypes["BlogCategoriesReadAccess"] | undefined,
	update?: ModelTypes["BlogCategoriesUpdateAccess"] | undefined,
	delete?: ModelTypes["BlogCategoriesDeleteAccess"] | undefined
};
	["BlogCategoriesFields"]: {
		name?: ModelTypes["BlogCategoriesFields_name"] | undefined,
	value?: ModelTypes["BlogCategoriesFields_value"] | undefined,
	icon?: ModelTypes["BlogCategoriesFields_icon"] | undefined,
	region?: ModelTypes["BlogCategoriesFields_region"] | undefined,
	updatedAt?: ModelTypes["BlogCategoriesFields_updatedAt"] | undefined,
	createdAt?: ModelTypes["BlogCategoriesFields_createdAt"] | undefined
};
	["BlogCategoriesFields_name"]: {
		create?: ModelTypes["BlogCategoriesFields_name_Create"] | undefined,
	read?: ModelTypes["BlogCategoriesFields_name_Read"] | undefined,
	update?: ModelTypes["BlogCategoriesFields_name_Update"] | undefined,
	delete?: ModelTypes["BlogCategoriesFields_name_Delete"] | undefined
};
	["BlogCategoriesFields_name_Create"]: {
		permission: boolean
};
	["BlogCategoriesFields_name_Read"]: {
		permission: boolean
};
	["BlogCategoriesFields_name_Update"]: {
		permission: boolean
};
	["BlogCategoriesFields_name_Delete"]: {
		permission: boolean
};
	["BlogCategoriesFields_value"]: {
		create?: ModelTypes["BlogCategoriesFields_value_Create"] | undefined,
	read?: ModelTypes["BlogCategoriesFields_value_Read"] | undefined,
	update?: ModelTypes["BlogCategoriesFields_value_Update"] | undefined,
	delete?: ModelTypes["BlogCategoriesFields_value_Delete"] | undefined
};
	["BlogCategoriesFields_value_Create"]: {
		permission: boolean
};
	["BlogCategoriesFields_value_Read"]: {
		permission: boolean
};
	["BlogCategoriesFields_value_Update"]: {
		permission: boolean
};
	["BlogCategoriesFields_value_Delete"]: {
		permission: boolean
};
	["BlogCategoriesFields_icon"]: {
		create?: ModelTypes["BlogCategoriesFields_icon_Create"] | undefined,
	read?: ModelTypes["BlogCategoriesFields_icon_Read"] | undefined,
	update?: ModelTypes["BlogCategoriesFields_icon_Update"] | undefined,
	delete?: ModelTypes["BlogCategoriesFields_icon_Delete"] | undefined
};
	["BlogCategoriesFields_icon_Create"]: {
		permission: boolean
};
	["BlogCategoriesFields_icon_Read"]: {
		permission: boolean
};
	["BlogCategoriesFields_icon_Update"]: {
		permission: boolean
};
	["BlogCategoriesFields_icon_Delete"]: {
		permission: boolean
};
	["BlogCategoriesFields_region"]: {
		create?: ModelTypes["BlogCategoriesFields_region_Create"] | undefined,
	read?: ModelTypes["BlogCategoriesFields_region_Read"] | undefined,
	update?: ModelTypes["BlogCategoriesFields_region_Update"] | undefined,
	delete?: ModelTypes["BlogCategoriesFields_region_Delete"] | undefined
};
	["BlogCategoriesFields_region_Create"]: {
		permission: boolean
};
	["BlogCategoriesFields_region_Read"]: {
		permission: boolean
};
	["BlogCategoriesFields_region_Update"]: {
		permission: boolean
};
	["BlogCategoriesFields_region_Delete"]: {
		permission: boolean
};
	["BlogCategoriesFields_updatedAt"]: {
		create?: ModelTypes["BlogCategoriesFields_updatedAt_Create"] | undefined,
	read?: ModelTypes["BlogCategoriesFields_updatedAt_Read"] | undefined,
	update?: ModelTypes["BlogCategoriesFields_updatedAt_Update"] | undefined,
	delete?: ModelTypes["BlogCategoriesFields_updatedAt_Delete"] | undefined
};
	["BlogCategoriesFields_updatedAt_Create"]: {
		permission: boolean
};
	["BlogCategoriesFields_updatedAt_Read"]: {
		permission: boolean
};
	["BlogCategoriesFields_updatedAt_Update"]: {
		permission: boolean
};
	["BlogCategoriesFields_updatedAt_Delete"]: {
		permission: boolean
};
	["BlogCategoriesFields_createdAt"]: {
		create?: ModelTypes["BlogCategoriesFields_createdAt_Create"] | undefined,
	read?: ModelTypes["BlogCategoriesFields_createdAt_Read"] | undefined,
	update?: ModelTypes["BlogCategoriesFields_createdAt_Update"] | undefined,
	delete?: ModelTypes["BlogCategoriesFields_createdAt_Delete"] | undefined
};
	["BlogCategoriesFields_createdAt_Create"]: {
		permission: boolean
};
	["BlogCategoriesFields_createdAt_Read"]: {
		permission: boolean
};
	["BlogCategoriesFields_createdAt_Update"]: {
		permission: boolean
};
	["BlogCategoriesFields_createdAt_Delete"]: {
		permission: boolean
};
	["BlogCategoriesCreateAccess"]: {
		permission: boolean,
	where?: ModelTypes["JSONObject"] | undefined
};
	["BlogCategoriesReadAccess"]: {
		permission: boolean,
	where?: ModelTypes["JSONObject"] | undefined
};
	["BlogCategoriesUpdateAccess"]: {
		permission: boolean,
	where?: ModelTypes["JSONObject"] | undefined
};
	["BlogCategoriesDeleteAccess"]: {
		permission: boolean,
	where?: ModelTypes["JSONObject"] | undefined
};
	["tagsAccess"]: {
		fields?: ModelTypes["TagsFields"] | undefined,
	create?: ModelTypes["TagsCreateAccess"] | undefined,
	read?: ModelTypes["TagsReadAccess"] | undefined,
	update?: ModelTypes["TagsUpdateAccess"] | undefined,
	delete?: ModelTypes["TagsDeleteAccess"] | undefined
};
	["TagsFields"]: {
		name?: ModelTypes["TagsFields_name"] | undefined,
	value?: ModelTypes["TagsFields_value"] | undefined,
	region?: ModelTypes["TagsFields_region"] | undefined,
	count?: ModelTypes["TagsFields_count"] | undefined,
	skipCountCheck?: ModelTypes["TagsFields_skipCountCheck"] | undefined,
	removeNextCron?: ModelTypes["TagsFields_removeNextCron"] | undefined
};
	["TagsFields_name"]: {
		create?: ModelTypes["TagsFields_name_Create"] | undefined,
	read?: ModelTypes["TagsFields_name_Read"] | undefined,
	update?: ModelTypes["TagsFields_name_Update"] | undefined,
	delete?: ModelTypes["TagsFields_name_Delete"] | undefined
};
	["TagsFields_name_Create"]: {
		permission: boolean
};
	["TagsFields_name_Read"]: {
		permission: boolean
};
	["TagsFields_name_Update"]: {
		permission: boolean
};
	["TagsFields_name_Delete"]: {
		permission: boolean
};
	["TagsFields_value"]: {
		create?: ModelTypes["TagsFields_value_Create"] | undefined,
	read?: ModelTypes["TagsFields_value_Read"] | undefined,
	update?: ModelTypes["TagsFields_value_Update"] | undefined,
	delete?: ModelTypes["TagsFields_value_Delete"] | undefined
};
	["TagsFields_value_Create"]: {
		permission: boolean
};
	["TagsFields_value_Read"]: {
		permission: boolean
};
	["TagsFields_value_Update"]: {
		permission: boolean
};
	["TagsFields_value_Delete"]: {
		permission: boolean
};
	["TagsFields_region"]: {
		create?: ModelTypes["TagsFields_region_Create"] | undefined,
	read?: ModelTypes["TagsFields_region_Read"] | undefined,
	update?: ModelTypes["TagsFields_region_Update"] | undefined,
	delete?: ModelTypes["TagsFields_region_Delete"] | undefined
};
	["TagsFields_region_Create"]: {
		permission: boolean
};
	["TagsFields_region_Read"]: {
		permission: boolean
};
	["TagsFields_region_Update"]: {
		permission: boolean
};
	["TagsFields_region_Delete"]: {
		permission: boolean
};
	["TagsFields_count"]: {
		create?: ModelTypes["TagsFields_count_Create"] | undefined,
	read?: ModelTypes["TagsFields_count_Read"] | undefined,
	update?: ModelTypes["TagsFields_count_Update"] | undefined,
	delete?: ModelTypes["TagsFields_count_Delete"] | undefined
};
	["TagsFields_count_Create"]: {
		permission: boolean
};
	["TagsFields_count_Read"]: {
		permission: boolean
};
	["TagsFields_count_Update"]: {
		permission: boolean
};
	["TagsFields_count_Delete"]: {
		permission: boolean
};
	["TagsFields_skipCountCheck"]: {
		create?: ModelTypes["TagsFields_skipCountCheck_Create"] | undefined,
	read?: ModelTypes["TagsFields_skipCountCheck_Read"] | undefined,
	update?: ModelTypes["TagsFields_skipCountCheck_Update"] | undefined,
	delete?: ModelTypes["TagsFields_skipCountCheck_Delete"] | undefined
};
	["TagsFields_skipCountCheck_Create"]: {
		permission: boolean
};
	["TagsFields_skipCountCheck_Read"]: {
		permission: boolean
};
	["TagsFields_skipCountCheck_Update"]: {
		permission: boolean
};
	["TagsFields_skipCountCheck_Delete"]: {
		permission: boolean
};
	["TagsFields_removeNextCron"]: {
		create?: ModelTypes["TagsFields_removeNextCron_Create"] | undefined,
	read?: ModelTypes["TagsFields_removeNextCron_Read"] | undefined,
	update?: ModelTypes["TagsFields_removeNextCron_Update"] | undefined,
	delete?: ModelTypes["TagsFields_removeNextCron_Delete"] | undefined
};
	["TagsFields_removeNextCron_Create"]: {
		permission: boolean
};
	["TagsFields_removeNextCron_Read"]: {
		permission: boolean
};
	["TagsFields_removeNextCron_Update"]: {
		permission: boolean
};
	["TagsFields_removeNextCron_Delete"]: {
		permission: boolean
};
	["TagsCreateAccess"]: {
		permission: boolean,
	where?: ModelTypes["JSONObject"] | undefined
};
	["TagsReadAccess"]: {
		permission: boolean,
	where?: ModelTypes["JSONObject"] | undefined
};
	["TagsUpdateAccess"]: {
		permission: boolean,
	where?: ModelTypes["JSONObject"] | undefined
};
	["TagsDeleteAccess"]: {
		permission: boolean,
	where?: ModelTypes["JSONObject"] | undefined
};
	["specialTagsAccess"]: {
		fields?: ModelTypes["SpecialTagsFields"] | undefined,
	create?: ModelTypes["SpecialTagsCreateAccess"] | undefined,
	read?: ModelTypes["SpecialTagsReadAccess"] | undefined,
	update?: ModelTypes["SpecialTagsUpdateAccess"] | undefined,
	delete?: ModelTypes["SpecialTagsDeleteAccess"] | undefined
};
	["SpecialTagsFields"]: {
		name?: ModelTypes["SpecialTagsFields_name"] | undefined,
	value?: ModelTypes["SpecialTagsFields_value"] | undefined,
	region?: ModelTypes["SpecialTagsFields_region"] | undefined
};
	["SpecialTagsFields_name"]: {
		create?: ModelTypes["SpecialTagsFields_name_Create"] | undefined,
	read?: ModelTypes["SpecialTagsFields_name_Read"] | undefined,
	update?: ModelTypes["SpecialTagsFields_name_Update"] | undefined,
	delete?: ModelTypes["SpecialTagsFields_name_Delete"] | undefined
};
	["SpecialTagsFields_name_Create"]: {
		permission: boolean
};
	["SpecialTagsFields_name_Read"]: {
		permission: boolean
};
	["SpecialTagsFields_name_Update"]: {
		permission: boolean
};
	["SpecialTagsFields_name_Delete"]: {
		permission: boolean
};
	["SpecialTagsFields_value"]: {
		create?: ModelTypes["SpecialTagsFields_value_Create"] | undefined,
	read?: ModelTypes["SpecialTagsFields_value_Read"] | undefined,
	update?: ModelTypes["SpecialTagsFields_value_Update"] | undefined,
	delete?: ModelTypes["SpecialTagsFields_value_Delete"] | undefined
};
	["SpecialTagsFields_value_Create"]: {
		permission: boolean
};
	["SpecialTagsFields_value_Read"]: {
		permission: boolean
};
	["SpecialTagsFields_value_Update"]: {
		permission: boolean
};
	["SpecialTagsFields_value_Delete"]: {
		permission: boolean
};
	["SpecialTagsFields_region"]: {
		create?: ModelTypes["SpecialTagsFields_region_Create"] | undefined,
	read?: ModelTypes["SpecialTagsFields_region_Read"] | undefined,
	update?: ModelTypes["SpecialTagsFields_region_Update"] | undefined,
	delete?: ModelTypes["SpecialTagsFields_region_Delete"] | undefined
};
	["SpecialTagsFields_region_Create"]: {
		permission: boolean
};
	["SpecialTagsFields_region_Read"]: {
		permission: boolean
};
	["SpecialTagsFields_region_Update"]: {
		permission: boolean
};
	["SpecialTagsFields_region_Delete"]: {
		permission: boolean
};
	["SpecialTagsCreateAccess"]: {
		permission: boolean,
	where?: ModelTypes["JSONObject"] | undefined
};
	["SpecialTagsReadAccess"]: {
		permission: boolean,
	where?: ModelTypes["JSONObject"] | undefined
};
	["SpecialTagsUpdateAccess"]: {
		permission: boolean,
	where?: ModelTypes["JSONObject"] | undefined
};
	["SpecialTagsDeleteAccess"]: {
		permission: boolean,
	where?: ModelTypes["JSONObject"] | undefined
};
	["dateAccess"]: {
		fields?: ModelTypes["DateFields"] | undefined,
	create?: ModelTypes["DateCreateAccess"] | undefined,
	read?: ModelTypes["DateReadAccess"] | undefined,
	update?: ModelTypes["DateUpdateAccess"] | undefined,
	delete?: ModelTypes["DateDeleteAccess"] | undefined
};
	["DateFields"]: {
		name?: ModelTypes["DateFields_name"] | undefined,
	value?: ModelTypes["DateFields_value"] | undefined,
	type?: ModelTypes["DateFields_type"] | undefined,
	start?: ModelTypes["DateFields_start"] | undefined,
	end?: ModelTypes["DateFields_end"] | undefined,
	_title?: ModelTypes["DateFields__title"] | undefined,
	region?: ModelTypes["DateFields_region"] | undefined,
	updatedAt?: ModelTypes["DateFields_updatedAt"] | undefined,
	createdAt?: ModelTypes["DateFields_createdAt"] | undefined
};
	["DateFields_name"]: {
		create?: ModelTypes["DateFields_name_Create"] | undefined,
	read?: ModelTypes["DateFields_name_Read"] | undefined,
	update?: ModelTypes["DateFields_name_Update"] | undefined,
	delete?: ModelTypes["DateFields_name_Delete"] | undefined
};
	["DateFields_name_Create"]: {
		permission: boolean
};
	["DateFields_name_Read"]: {
		permission: boolean
};
	["DateFields_name_Update"]: {
		permission: boolean
};
	["DateFields_name_Delete"]: {
		permission: boolean
};
	["DateFields_value"]: {
		create?: ModelTypes["DateFields_value_Create"] | undefined,
	read?: ModelTypes["DateFields_value_Read"] | undefined,
	update?: ModelTypes["DateFields_value_Update"] | undefined,
	delete?: ModelTypes["DateFields_value_Delete"] | undefined
};
	["DateFields_value_Create"]: {
		permission: boolean
};
	["DateFields_value_Read"]: {
		permission: boolean
};
	["DateFields_value_Update"]: {
		permission: boolean
};
	["DateFields_value_Delete"]: {
		permission: boolean
};
	["DateFields_type"]: {
		create?: ModelTypes["DateFields_type_Create"] | undefined,
	read?: ModelTypes["DateFields_type_Read"] | undefined,
	update?: ModelTypes["DateFields_type_Update"] | undefined,
	delete?: ModelTypes["DateFields_type_Delete"] | undefined
};
	["DateFields_type_Create"]: {
		permission: boolean
};
	["DateFields_type_Read"]: {
		permission: boolean
};
	["DateFields_type_Update"]: {
		permission: boolean
};
	["DateFields_type_Delete"]: {
		permission: boolean
};
	["DateFields_start"]: {
		create?: ModelTypes["DateFields_start_Create"] | undefined,
	read?: ModelTypes["DateFields_start_Read"] | undefined,
	update?: ModelTypes["DateFields_start_Update"] | undefined,
	delete?: ModelTypes["DateFields_start_Delete"] | undefined
};
	["DateFields_start_Create"]: {
		permission: boolean
};
	["DateFields_start_Read"]: {
		permission: boolean
};
	["DateFields_start_Update"]: {
		permission: boolean
};
	["DateFields_start_Delete"]: {
		permission: boolean
};
	["DateFields_end"]: {
		create?: ModelTypes["DateFields_end_Create"] | undefined,
	read?: ModelTypes["DateFields_end_Read"] | undefined,
	update?: ModelTypes["DateFields_end_Update"] | undefined,
	delete?: ModelTypes["DateFields_end_Delete"] | undefined
};
	["DateFields_end_Create"]: {
		permission: boolean
};
	["DateFields_end_Read"]: {
		permission: boolean
};
	["DateFields_end_Update"]: {
		permission: boolean
};
	["DateFields_end_Delete"]: {
		permission: boolean
};
	["DateFields__title"]: {
		create?: ModelTypes["DateFields__title_Create"] | undefined,
	read?: ModelTypes["DateFields__title_Read"] | undefined,
	update?: ModelTypes["DateFields__title_Update"] | undefined,
	delete?: ModelTypes["DateFields__title_Delete"] | undefined
};
	["DateFields__title_Create"]: {
		permission: boolean
};
	["DateFields__title_Read"]: {
		permission: boolean
};
	["DateFields__title_Update"]: {
		permission: boolean
};
	["DateFields__title_Delete"]: {
		permission: boolean
};
	["DateFields_region"]: {
		create?: ModelTypes["DateFields_region_Create"] | undefined,
	read?: ModelTypes["DateFields_region_Read"] | undefined,
	update?: ModelTypes["DateFields_region_Update"] | undefined,
	delete?: ModelTypes["DateFields_region_Delete"] | undefined
};
	["DateFields_region_Create"]: {
		permission: boolean
};
	["DateFields_region_Read"]: {
		permission: boolean
};
	["DateFields_region_Update"]: {
		permission: boolean
};
	["DateFields_region_Delete"]: {
		permission: boolean
};
	["DateFields_updatedAt"]: {
		create?: ModelTypes["DateFields_updatedAt_Create"] | undefined,
	read?: ModelTypes["DateFields_updatedAt_Read"] | undefined,
	update?: ModelTypes["DateFields_updatedAt_Update"] | undefined,
	delete?: ModelTypes["DateFields_updatedAt_Delete"] | undefined
};
	["DateFields_updatedAt_Create"]: {
		permission: boolean
};
	["DateFields_updatedAt_Read"]: {
		permission: boolean
};
	["DateFields_updatedAt_Update"]: {
		permission: boolean
};
	["DateFields_updatedAt_Delete"]: {
		permission: boolean
};
	["DateFields_createdAt"]: {
		create?: ModelTypes["DateFields_createdAt_Create"] | undefined,
	read?: ModelTypes["DateFields_createdAt_Read"] | undefined,
	update?: ModelTypes["DateFields_createdAt_Update"] | undefined,
	delete?: ModelTypes["DateFields_createdAt_Delete"] | undefined
};
	["DateFields_createdAt_Create"]: {
		permission: boolean
};
	["DateFields_createdAt_Read"]: {
		permission: boolean
};
	["DateFields_createdAt_Update"]: {
		permission: boolean
};
	["DateFields_createdAt_Delete"]: {
		permission: boolean
};
	["DateCreateAccess"]: {
		permission: boolean,
	where?: ModelTypes["JSONObject"] | undefined
};
	["DateReadAccess"]: {
		permission: boolean,
	where?: ModelTypes["JSONObject"] | undefined
};
	["DateUpdateAccess"]: {
		permission: boolean,
	where?: ModelTypes["JSONObject"] | undefined
};
	["DateDeleteAccess"]: {
		permission: boolean,
	where?: ModelTypes["JSONObject"] | undefined
};
	["districtsAccess"]: {
		fields?: ModelTypes["DistrictsFields"] | undefined,
	create?: ModelTypes["DistrictsCreateAccess"] | undefined,
	read?: ModelTypes["DistrictsReadAccess"] | undefined,
	update?: ModelTypes["DistrictsUpdateAccess"] | undefined,
	delete?: ModelTypes["DistrictsDeleteAccess"] | undefined
};
	["DistrictsFields"]: {
		name?: ModelTypes["DistrictsFields_name"] | undefined,
	level?: ModelTypes["DistrictsFields_level"] | undefined,
	children?: ModelTypes["DistrictsFields_children"] | undefined,
	value?: ModelTypes["DistrictsFields_value"] | undefined,
	lat?: ModelTypes["DistrictsFields_lat"] | undefined,
	long?: ModelTypes["DistrictsFields_long"] | undefined,
	radius?: ModelTypes["DistrictsFields_radius"] | undefined,
	region?: ModelTypes["DistrictsFields_region"] | undefined,
	updatedAt?: ModelTypes["DistrictsFields_updatedAt"] | undefined,
	createdAt?: ModelTypes["DistrictsFields_createdAt"] | undefined
};
	["DistrictsFields_name"]: {
		create?: ModelTypes["DistrictsFields_name_Create"] | undefined,
	read?: ModelTypes["DistrictsFields_name_Read"] | undefined,
	update?: ModelTypes["DistrictsFields_name_Update"] | undefined,
	delete?: ModelTypes["DistrictsFields_name_Delete"] | undefined
};
	["DistrictsFields_name_Create"]: {
		permission: boolean
};
	["DistrictsFields_name_Read"]: {
		permission: boolean
};
	["DistrictsFields_name_Update"]: {
		permission: boolean
};
	["DistrictsFields_name_Delete"]: {
		permission: boolean
};
	["DistrictsFields_level"]: {
		create?: ModelTypes["DistrictsFields_level_Create"] | undefined,
	read?: ModelTypes["DistrictsFields_level_Read"] | undefined,
	update?: ModelTypes["DistrictsFields_level_Update"] | undefined,
	delete?: ModelTypes["DistrictsFields_level_Delete"] | undefined
};
	["DistrictsFields_level_Create"]: {
		permission: boolean
};
	["DistrictsFields_level_Read"]: {
		permission: boolean
};
	["DistrictsFields_level_Update"]: {
		permission: boolean
};
	["DistrictsFields_level_Delete"]: {
		permission: boolean
};
	["DistrictsFields_children"]: {
		create?: ModelTypes["DistrictsFields_children_Create"] | undefined,
	read?: ModelTypes["DistrictsFields_children_Read"] | undefined,
	update?: ModelTypes["DistrictsFields_children_Update"] | undefined,
	delete?: ModelTypes["DistrictsFields_children_Delete"] | undefined
};
	["DistrictsFields_children_Create"]: {
		permission: boolean
};
	["DistrictsFields_children_Read"]: {
		permission: boolean
};
	["DistrictsFields_children_Update"]: {
		permission: boolean
};
	["DistrictsFields_children_Delete"]: {
		permission: boolean
};
	["DistrictsFields_value"]: {
		create?: ModelTypes["DistrictsFields_value_Create"] | undefined,
	read?: ModelTypes["DistrictsFields_value_Read"] | undefined,
	update?: ModelTypes["DistrictsFields_value_Update"] | undefined,
	delete?: ModelTypes["DistrictsFields_value_Delete"] | undefined
};
	["DistrictsFields_value_Create"]: {
		permission: boolean
};
	["DistrictsFields_value_Read"]: {
		permission: boolean
};
	["DistrictsFields_value_Update"]: {
		permission: boolean
};
	["DistrictsFields_value_Delete"]: {
		permission: boolean
};
	["DistrictsFields_lat"]: {
		create?: ModelTypes["DistrictsFields_lat_Create"] | undefined,
	read?: ModelTypes["DistrictsFields_lat_Read"] | undefined,
	update?: ModelTypes["DistrictsFields_lat_Update"] | undefined,
	delete?: ModelTypes["DistrictsFields_lat_Delete"] | undefined
};
	["DistrictsFields_lat_Create"]: {
		permission: boolean
};
	["DistrictsFields_lat_Read"]: {
		permission: boolean
};
	["DistrictsFields_lat_Update"]: {
		permission: boolean
};
	["DistrictsFields_lat_Delete"]: {
		permission: boolean
};
	["DistrictsFields_long"]: {
		create?: ModelTypes["DistrictsFields_long_Create"] | undefined,
	read?: ModelTypes["DistrictsFields_long_Read"] | undefined,
	update?: ModelTypes["DistrictsFields_long_Update"] | undefined,
	delete?: ModelTypes["DistrictsFields_long_Delete"] | undefined
};
	["DistrictsFields_long_Create"]: {
		permission: boolean
};
	["DistrictsFields_long_Read"]: {
		permission: boolean
};
	["DistrictsFields_long_Update"]: {
		permission: boolean
};
	["DistrictsFields_long_Delete"]: {
		permission: boolean
};
	["DistrictsFields_radius"]: {
		create?: ModelTypes["DistrictsFields_radius_Create"] | undefined,
	read?: ModelTypes["DistrictsFields_radius_Read"] | undefined,
	update?: ModelTypes["DistrictsFields_radius_Update"] | undefined,
	delete?: ModelTypes["DistrictsFields_radius_Delete"] | undefined
};
	["DistrictsFields_radius_Create"]: {
		permission: boolean
};
	["DistrictsFields_radius_Read"]: {
		permission: boolean
};
	["DistrictsFields_radius_Update"]: {
		permission: boolean
};
	["DistrictsFields_radius_Delete"]: {
		permission: boolean
};
	["DistrictsFields_region"]: {
		create?: ModelTypes["DistrictsFields_region_Create"] | undefined,
	read?: ModelTypes["DistrictsFields_region_Read"] | undefined,
	update?: ModelTypes["DistrictsFields_region_Update"] | undefined,
	delete?: ModelTypes["DistrictsFields_region_Delete"] | undefined
};
	["DistrictsFields_region_Create"]: {
		permission: boolean
};
	["DistrictsFields_region_Read"]: {
		permission: boolean
};
	["DistrictsFields_region_Update"]: {
		permission: boolean
};
	["DistrictsFields_region_Delete"]: {
		permission: boolean
};
	["DistrictsFields_updatedAt"]: {
		create?: ModelTypes["DistrictsFields_updatedAt_Create"] | undefined,
	read?: ModelTypes["DistrictsFields_updatedAt_Read"] | undefined,
	update?: ModelTypes["DistrictsFields_updatedAt_Update"] | undefined,
	delete?: ModelTypes["DistrictsFields_updatedAt_Delete"] | undefined
};
	["DistrictsFields_updatedAt_Create"]: {
		permission: boolean
};
	["DistrictsFields_updatedAt_Read"]: {
		permission: boolean
};
	["DistrictsFields_updatedAt_Update"]: {
		permission: boolean
};
	["DistrictsFields_updatedAt_Delete"]: {
		permission: boolean
};
	["DistrictsFields_createdAt"]: {
		create?: ModelTypes["DistrictsFields_createdAt_Create"] | undefined,
	read?: ModelTypes["DistrictsFields_createdAt_Read"] | undefined,
	update?: ModelTypes["DistrictsFields_createdAt_Update"] | undefined,
	delete?: ModelTypes["DistrictsFields_createdAt_Delete"] | undefined
};
	["DistrictsFields_createdAt_Create"]: {
		permission: boolean
};
	["DistrictsFields_createdAt_Read"]: {
		permission: boolean
};
	["DistrictsFields_createdAt_Update"]: {
		permission: boolean
};
	["DistrictsFields_createdAt_Delete"]: {
		permission: boolean
};
	["DistrictsCreateAccess"]: {
		permission: boolean,
	where?: ModelTypes["JSONObject"] | undefined
};
	["DistrictsReadAccess"]: {
		permission: boolean,
	where?: ModelTypes["JSONObject"] | undefined
};
	["DistrictsUpdateAccess"]: {
		permission: boolean,
	where?: ModelTypes["JSONObject"] | undefined
};
	["DistrictsDeleteAccess"]: {
		permission: boolean,
	where?: ModelTypes["JSONObject"] | undefined
};
	["audienceAccess"]: {
		fields?: ModelTypes["AudienceFields"] | undefined,
	create?: ModelTypes["AudienceCreateAccess"] | undefined,
	read?: ModelTypes["AudienceReadAccess"] | undefined,
	update?: ModelTypes["AudienceUpdateAccess"] | undefined,
	delete?: ModelTypes["AudienceDeleteAccess"] | undefined
};
	["AudienceFields"]: {
		name?: ModelTypes["AudienceFields_name"] | undefined,
	value?: ModelTypes["AudienceFields_value"] | undefined,
	icon?: ModelTypes["AudienceFields_icon"] | undefined,
	region?: ModelTypes["AudienceFields_region"] | undefined,
	updatedAt?: ModelTypes["AudienceFields_updatedAt"] | undefined,
	createdAt?: ModelTypes["AudienceFields_createdAt"] | undefined
};
	["AudienceFields_name"]: {
		create?: ModelTypes["AudienceFields_name_Create"] | undefined,
	read?: ModelTypes["AudienceFields_name_Read"] | undefined,
	update?: ModelTypes["AudienceFields_name_Update"] | undefined,
	delete?: ModelTypes["AudienceFields_name_Delete"] | undefined
};
	["AudienceFields_name_Create"]: {
		permission: boolean
};
	["AudienceFields_name_Read"]: {
		permission: boolean
};
	["AudienceFields_name_Update"]: {
		permission: boolean
};
	["AudienceFields_name_Delete"]: {
		permission: boolean
};
	["AudienceFields_value"]: {
		create?: ModelTypes["AudienceFields_value_Create"] | undefined,
	read?: ModelTypes["AudienceFields_value_Read"] | undefined,
	update?: ModelTypes["AudienceFields_value_Update"] | undefined,
	delete?: ModelTypes["AudienceFields_value_Delete"] | undefined
};
	["AudienceFields_value_Create"]: {
		permission: boolean
};
	["AudienceFields_value_Read"]: {
		permission: boolean
};
	["AudienceFields_value_Update"]: {
		permission: boolean
};
	["AudienceFields_value_Delete"]: {
		permission: boolean
};
	["AudienceFields_icon"]: {
		create?: ModelTypes["AudienceFields_icon_Create"] | undefined,
	read?: ModelTypes["AudienceFields_icon_Read"] | undefined,
	update?: ModelTypes["AudienceFields_icon_Update"] | undefined,
	delete?: ModelTypes["AudienceFields_icon_Delete"] | undefined
};
	["AudienceFields_icon_Create"]: {
		permission: boolean
};
	["AudienceFields_icon_Read"]: {
		permission: boolean
};
	["AudienceFields_icon_Update"]: {
		permission: boolean
};
	["AudienceFields_icon_Delete"]: {
		permission: boolean
};
	["AudienceFields_region"]: {
		create?: ModelTypes["AudienceFields_region_Create"] | undefined,
	read?: ModelTypes["AudienceFields_region_Read"] | undefined,
	update?: ModelTypes["AudienceFields_region_Update"] | undefined,
	delete?: ModelTypes["AudienceFields_region_Delete"] | undefined
};
	["AudienceFields_region_Create"]: {
		permission: boolean
};
	["AudienceFields_region_Read"]: {
		permission: boolean
};
	["AudienceFields_region_Update"]: {
		permission: boolean
};
	["AudienceFields_region_Delete"]: {
		permission: boolean
};
	["AudienceFields_updatedAt"]: {
		create?: ModelTypes["AudienceFields_updatedAt_Create"] | undefined,
	read?: ModelTypes["AudienceFields_updatedAt_Read"] | undefined,
	update?: ModelTypes["AudienceFields_updatedAt_Update"] | undefined,
	delete?: ModelTypes["AudienceFields_updatedAt_Delete"] | undefined
};
	["AudienceFields_updatedAt_Create"]: {
		permission: boolean
};
	["AudienceFields_updatedAt_Read"]: {
		permission: boolean
};
	["AudienceFields_updatedAt_Update"]: {
		permission: boolean
};
	["AudienceFields_updatedAt_Delete"]: {
		permission: boolean
};
	["AudienceFields_createdAt"]: {
		create?: ModelTypes["AudienceFields_createdAt_Create"] | undefined,
	read?: ModelTypes["AudienceFields_createdAt_Read"] | undefined,
	update?: ModelTypes["AudienceFields_createdAt_Update"] | undefined,
	delete?: ModelTypes["AudienceFields_createdAt_Delete"] | undefined
};
	["AudienceFields_createdAt_Create"]: {
		permission: boolean
};
	["AudienceFields_createdAt_Read"]: {
		permission: boolean
};
	["AudienceFields_createdAt_Update"]: {
		permission: boolean
};
	["AudienceFields_createdAt_Delete"]: {
		permission: boolean
};
	["AudienceCreateAccess"]: {
		permission: boolean,
	where?: ModelTypes["JSONObject"] | undefined
};
	["AudienceReadAccess"]: {
		permission: boolean,
	where?: ModelTypes["JSONObject"] | undefined
};
	["AudienceUpdateAccess"]: {
		permission: boolean,
	where?: ModelTypes["JSONObject"] | undefined
};
	["AudienceDeleteAccess"]: {
		permission: boolean,
	where?: ModelTypes["JSONObject"] | undefined
};
	["categoryAccess"]: {
		fields?: ModelTypes["CategoryFields"] | undefined,
	create?: ModelTypes["CategoryCreateAccess"] | undefined,
	read?: ModelTypes["CategoryReadAccess"] | undefined,
	update?: ModelTypes["CategoryUpdateAccess"] | undefined,
	delete?: ModelTypes["CategoryDeleteAccess"] | undefined
};
	["CategoryFields"]: {
		name?: ModelTypes["CategoryFields_name"] | undefined,
	value?: ModelTypes["CategoryFields_value"] | undefined,
	tags?: ModelTypes["CategoryFields_tags"] | undefined,
	icon?: ModelTypes["CategoryFields_icon"] | undefined,
	region?: ModelTypes["CategoryFields_region"] | undefined,
	updatedAt?: ModelTypes["CategoryFields_updatedAt"] | undefined,
	createdAt?: ModelTypes["CategoryFields_createdAt"] | undefined
};
	["CategoryFields_name"]: {
		create?: ModelTypes["CategoryFields_name_Create"] | undefined,
	read?: ModelTypes["CategoryFields_name_Read"] | undefined,
	update?: ModelTypes["CategoryFields_name_Update"] | undefined,
	delete?: ModelTypes["CategoryFields_name_Delete"] | undefined
};
	["CategoryFields_name_Create"]: {
		permission: boolean
};
	["CategoryFields_name_Read"]: {
		permission: boolean
};
	["CategoryFields_name_Update"]: {
		permission: boolean
};
	["CategoryFields_name_Delete"]: {
		permission: boolean
};
	["CategoryFields_value"]: {
		create?: ModelTypes["CategoryFields_value_Create"] | undefined,
	read?: ModelTypes["CategoryFields_value_Read"] | undefined,
	update?: ModelTypes["CategoryFields_value_Update"] | undefined,
	delete?: ModelTypes["CategoryFields_value_Delete"] | undefined
};
	["CategoryFields_value_Create"]: {
		permission: boolean
};
	["CategoryFields_value_Read"]: {
		permission: boolean
};
	["CategoryFields_value_Update"]: {
		permission: boolean
};
	["CategoryFields_value_Delete"]: {
		permission: boolean
};
	["CategoryFields_tags"]: {
		create?: ModelTypes["CategoryFields_tags_Create"] | undefined,
	read?: ModelTypes["CategoryFields_tags_Read"] | undefined,
	update?: ModelTypes["CategoryFields_tags_Update"] | undefined,
	delete?: ModelTypes["CategoryFields_tags_Delete"] | undefined
};
	["CategoryFields_tags_Create"]: {
		permission: boolean
};
	["CategoryFields_tags_Read"]: {
		permission: boolean
};
	["CategoryFields_tags_Update"]: {
		permission: boolean
};
	["CategoryFields_tags_Delete"]: {
		permission: boolean
};
	["CategoryFields_icon"]: {
		create?: ModelTypes["CategoryFields_icon_Create"] | undefined,
	read?: ModelTypes["CategoryFields_icon_Read"] | undefined,
	update?: ModelTypes["CategoryFields_icon_Update"] | undefined,
	delete?: ModelTypes["CategoryFields_icon_Delete"] | undefined
};
	["CategoryFields_icon_Create"]: {
		permission: boolean
};
	["CategoryFields_icon_Read"]: {
		permission: boolean
};
	["CategoryFields_icon_Update"]: {
		permission: boolean
};
	["CategoryFields_icon_Delete"]: {
		permission: boolean
};
	["CategoryFields_region"]: {
		create?: ModelTypes["CategoryFields_region_Create"] | undefined,
	read?: ModelTypes["CategoryFields_region_Read"] | undefined,
	update?: ModelTypes["CategoryFields_region_Update"] | undefined,
	delete?: ModelTypes["CategoryFields_region_Delete"] | undefined
};
	["CategoryFields_region_Create"]: {
		permission: boolean
};
	["CategoryFields_region_Read"]: {
		permission: boolean
};
	["CategoryFields_region_Update"]: {
		permission: boolean
};
	["CategoryFields_region_Delete"]: {
		permission: boolean
};
	["CategoryFields_updatedAt"]: {
		create?: ModelTypes["CategoryFields_updatedAt_Create"] | undefined,
	read?: ModelTypes["CategoryFields_updatedAt_Read"] | undefined,
	update?: ModelTypes["CategoryFields_updatedAt_Update"] | undefined,
	delete?: ModelTypes["CategoryFields_updatedAt_Delete"] | undefined
};
	["CategoryFields_updatedAt_Create"]: {
		permission: boolean
};
	["CategoryFields_updatedAt_Read"]: {
		permission: boolean
};
	["CategoryFields_updatedAt_Update"]: {
		permission: boolean
};
	["CategoryFields_updatedAt_Delete"]: {
		permission: boolean
};
	["CategoryFields_createdAt"]: {
		create?: ModelTypes["CategoryFields_createdAt_Create"] | undefined,
	read?: ModelTypes["CategoryFields_createdAt_Read"] | undefined,
	update?: ModelTypes["CategoryFields_createdAt_Update"] | undefined,
	delete?: ModelTypes["CategoryFields_createdAt_Delete"] | undefined
};
	["CategoryFields_createdAt_Create"]: {
		permission: boolean
};
	["CategoryFields_createdAt_Read"]: {
		permission: boolean
};
	["CategoryFields_createdAt_Update"]: {
		permission: boolean
};
	["CategoryFields_createdAt_Delete"]: {
		permission: boolean
};
	["CategoryCreateAccess"]: {
		permission: boolean,
	where?: ModelTypes["JSONObject"] | undefined
};
	["CategoryReadAccess"]: {
		permission: boolean,
	where?: ModelTypes["JSONObject"] | undefined
};
	["CategoryUpdateAccess"]: {
		permission: boolean,
	where?: ModelTypes["JSONObject"] | undefined
};
	["CategoryDeleteAccess"]: {
		permission: boolean,
	where?: ModelTypes["JSONObject"] | undefined
};
	["private_mediaAccess"]: {
		fields?: ModelTypes["PrivateMediaFields"] | undefined,
	create?: ModelTypes["PrivateMediaCreateAccess"] | undefined,
	read?: ModelTypes["PrivateMediaReadAccess"] | undefined,
	update?: ModelTypes["PrivateMediaUpdateAccess"] | undefined,
	delete?: ModelTypes["PrivateMediaDeleteAccess"] | undefined
};
	["PrivateMediaFields"]: {
		alt?: ModelTypes["PrivateMediaFields_alt"] | undefined,
	updatedAt?: ModelTypes["PrivateMediaFields_updatedAt"] | undefined,
	createdAt?: ModelTypes["PrivateMediaFields_createdAt"] | undefined,
	url?: ModelTypes["PrivateMediaFields_url"] | undefined,
	filename?: ModelTypes["PrivateMediaFields_filename"] | undefined,
	mimeType?: ModelTypes["PrivateMediaFields_mimeType"] | undefined,
	filesize?: ModelTypes["PrivateMediaFields_filesize"] | undefined,
	width?: ModelTypes["PrivateMediaFields_width"] | undefined,
	height?: ModelTypes["PrivateMediaFields_height"] | undefined,
	focalX?: ModelTypes["PrivateMediaFields_focalX"] | undefined,
	focalY?: ModelTypes["PrivateMediaFields_focalY"] | undefined,
	sizes?: ModelTypes["PrivateMediaFields_sizes"] | undefined
};
	["PrivateMediaFields_alt"]: {
		create?: ModelTypes["PrivateMediaFields_alt_Create"] | undefined,
	read?: ModelTypes["PrivateMediaFields_alt_Read"] | undefined,
	update?: ModelTypes["PrivateMediaFields_alt_Update"] | undefined,
	delete?: ModelTypes["PrivateMediaFields_alt_Delete"] | undefined
};
	["PrivateMediaFields_alt_Create"]: {
		permission: boolean
};
	["PrivateMediaFields_alt_Read"]: {
		permission: boolean
};
	["PrivateMediaFields_alt_Update"]: {
		permission: boolean
};
	["PrivateMediaFields_alt_Delete"]: {
		permission: boolean
};
	["PrivateMediaFields_updatedAt"]: {
		create?: ModelTypes["PrivateMediaFields_updatedAt_Create"] | undefined,
	read?: ModelTypes["PrivateMediaFields_updatedAt_Read"] | undefined,
	update?: ModelTypes["PrivateMediaFields_updatedAt_Update"] | undefined,
	delete?: ModelTypes["PrivateMediaFields_updatedAt_Delete"] | undefined
};
	["PrivateMediaFields_updatedAt_Create"]: {
		permission: boolean
};
	["PrivateMediaFields_updatedAt_Read"]: {
		permission: boolean
};
	["PrivateMediaFields_updatedAt_Update"]: {
		permission: boolean
};
	["PrivateMediaFields_updatedAt_Delete"]: {
		permission: boolean
};
	["PrivateMediaFields_createdAt"]: {
		create?: ModelTypes["PrivateMediaFields_createdAt_Create"] | undefined,
	read?: ModelTypes["PrivateMediaFields_createdAt_Read"] | undefined,
	update?: ModelTypes["PrivateMediaFields_createdAt_Update"] | undefined,
	delete?: ModelTypes["PrivateMediaFields_createdAt_Delete"] | undefined
};
	["PrivateMediaFields_createdAt_Create"]: {
		permission: boolean
};
	["PrivateMediaFields_createdAt_Read"]: {
		permission: boolean
};
	["PrivateMediaFields_createdAt_Update"]: {
		permission: boolean
};
	["PrivateMediaFields_createdAt_Delete"]: {
		permission: boolean
};
	["PrivateMediaFields_url"]: {
		create?: ModelTypes["PrivateMediaFields_url_Create"] | undefined,
	read?: ModelTypes["PrivateMediaFields_url_Read"] | undefined,
	update?: ModelTypes["PrivateMediaFields_url_Update"] | undefined,
	delete?: ModelTypes["PrivateMediaFields_url_Delete"] | undefined
};
	["PrivateMediaFields_url_Create"]: {
		permission: boolean
};
	["PrivateMediaFields_url_Read"]: {
		permission: boolean
};
	["PrivateMediaFields_url_Update"]: {
		permission: boolean
};
	["PrivateMediaFields_url_Delete"]: {
		permission: boolean
};
	["PrivateMediaFields_filename"]: {
		create?: ModelTypes["PrivateMediaFields_filename_Create"] | undefined,
	read?: ModelTypes["PrivateMediaFields_filename_Read"] | undefined,
	update?: ModelTypes["PrivateMediaFields_filename_Update"] | undefined,
	delete?: ModelTypes["PrivateMediaFields_filename_Delete"] | undefined
};
	["PrivateMediaFields_filename_Create"]: {
		permission: boolean
};
	["PrivateMediaFields_filename_Read"]: {
		permission: boolean
};
	["PrivateMediaFields_filename_Update"]: {
		permission: boolean
};
	["PrivateMediaFields_filename_Delete"]: {
		permission: boolean
};
	["PrivateMediaFields_mimeType"]: {
		create?: ModelTypes["PrivateMediaFields_mimeType_Create"] | undefined,
	read?: ModelTypes["PrivateMediaFields_mimeType_Read"] | undefined,
	update?: ModelTypes["PrivateMediaFields_mimeType_Update"] | undefined,
	delete?: ModelTypes["PrivateMediaFields_mimeType_Delete"] | undefined
};
	["PrivateMediaFields_mimeType_Create"]: {
		permission: boolean
};
	["PrivateMediaFields_mimeType_Read"]: {
		permission: boolean
};
	["PrivateMediaFields_mimeType_Update"]: {
		permission: boolean
};
	["PrivateMediaFields_mimeType_Delete"]: {
		permission: boolean
};
	["PrivateMediaFields_filesize"]: {
		create?: ModelTypes["PrivateMediaFields_filesize_Create"] | undefined,
	read?: ModelTypes["PrivateMediaFields_filesize_Read"] | undefined,
	update?: ModelTypes["PrivateMediaFields_filesize_Update"] | undefined,
	delete?: ModelTypes["PrivateMediaFields_filesize_Delete"] | undefined
};
	["PrivateMediaFields_filesize_Create"]: {
		permission: boolean
};
	["PrivateMediaFields_filesize_Read"]: {
		permission: boolean
};
	["PrivateMediaFields_filesize_Update"]: {
		permission: boolean
};
	["PrivateMediaFields_filesize_Delete"]: {
		permission: boolean
};
	["PrivateMediaFields_width"]: {
		create?: ModelTypes["PrivateMediaFields_width_Create"] | undefined,
	read?: ModelTypes["PrivateMediaFields_width_Read"] | undefined,
	update?: ModelTypes["PrivateMediaFields_width_Update"] | undefined,
	delete?: ModelTypes["PrivateMediaFields_width_Delete"] | undefined
};
	["PrivateMediaFields_width_Create"]: {
		permission: boolean
};
	["PrivateMediaFields_width_Read"]: {
		permission: boolean
};
	["PrivateMediaFields_width_Update"]: {
		permission: boolean
};
	["PrivateMediaFields_width_Delete"]: {
		permission: boolean
};
	["PrivateMediaFields_height"]: {
		create?: ModelTypes["PrivateMediaFields_height_Create"] | undefined,
	read?: ModelTypes["PrivateMediaFields_height_Read"] | undefined,
	update?: ModelTypes["PrivateMediaFields_height_Update"] | undefined,
	delete?: ModelTypes["PrivateMediaFields_height_Delete"] | undefined
};
	["PrivateMediaFields_height_Create"]: {
		permission: boolean
};
	["PrivateMediaFields_height_Read"]: {
		permission: boolean
};
	["PrivateMediaFields_height_Update"]: {
		permission: boolean
};
	["PrivateMediaFields_height_Delete"]: {
		permission: boolean
};
	["PrivateMediaFields_focalX"]: {
		create?: ModelTypes["PrivateMediaFields_focalX_Create"] | undefined,
	read?: ModelTypes["PrivateMediaFields_focalX_Read"] | undefined,
	update?: ModelTypes["PrivateMediaFields_focalX_Update"] | undefined,
	delete?: ModelTypes["PrivateMediaFields_focalX_Delete"] | undefined
};
	["PrivateMediaFields_focalX_Create"]: {
		permission: boolean
};
	["PrivateMediaFields_focalX_Read"]: {
		permission: boolean
};
	["PrivateMediaFields_focalX_Update"]: {
		permission: boolean
};
	["PrivateMediaFields_focalX_Delete"]: {
		permission: boolean
};
	["PrivateMediaFields_focalY"]: {
		create?: ModelTypes["PrivateMediaFields_focalY_Create"] | undefined,
	read?: ModelTypes["PrivateMediaFields_focalY_Read"] | undefined,
	update?: ModelTypes["PrivateMediaFields_focalY_Update"] | undefined,
	delete?: ModelTypes["PrivateMediaFields_focalY_Delete"] | undefined
};
	["PrivateMediaFields_focalY_Create"]: {
		permission: boolean
};
	["PrivateMediaFields_focalY_Read"]: {
		permission: boolean
};
	["PrivateMediaFields_focalY_Update"]: {
		permission: boolean
};
	["PrivateMediaFields_focalY_Delete"]: {
		permission: boolean
};
	["PrivateMediaFields_sizes"]: {
		create?: ModelTypes["PrivateMediaFields_sizes_Create"] | undefined,
	read?: ModelTypes["PrivateMediaFields_sizes_Read"] | undefined,
	update?: ModelTypes["PrivateMediaFields_sizes_Update"] | undefined,
	delete?: ModelTypes["PrivateMediaFields_sizes_Delete"] | undefined,
	fields?: ModelTypes["PrivateMediaFields_sizes_Fields"] | undefined
};
	["PrivateMediaFields_sizes_Create"]: {
		permission: boolean
};
	["PrivateMediaFields_sizes_Read"]: {
		permission: boolean
};
	["PrivateMediaFields_sizes_Update"]: {
		permission: boolean
};
	["PrivateMediaFields_sizes_Delete"]: {
		permission: boolean
};
	["PrivateMediaFields_sizes_Fields"]: {
		thumbnail?: ModelTypes["PrivateMediaFields_sizes_thumbnail"] | undefined,
	portrait?: ModelTypes["PrivateMediaFields_sizes_portrait"] | undefined,
	hero?: ModelTypes["PrivateMediaFields_sizes_hero"] | undefined,
	thumbnail_jpeg?: ModelTypes["PrivateMediaFields_sizes_thumbnail_jpeg"] | undefined,
	portrait_jpeg?: ModelTypes["PrivateMediaFields_sizes_portrait_jpeg"] | undefined,
	hero_jpeg?: ModelTypes["PrivateMediaFields_sizes_hero_jpeg"] | undefined
};
	["PrivateMediaFields_sizes_thumbnail"]: {
		create?: ModelTypes["PrivateMediaFields_sizes_thumbnail_Create"] | undefined,
	read?: ModelTypes["PrivateMediaFields_sizes_thumbnail_Read"] | undefined,
	update?: ModelTypes["PrivateMediaFields_sizes_thumbnail_Update"] | undefined,
	delete?: ModelTypes["PrivateMediaFields_sizes_thumbnail_Delete"] | undefined,
	fields?: ModelTypes["PrivateMediaFields_sizes_thumbnail_Fields"] | undefined
};
	["PrivateMediaFields_sizes_thumbnail_Create"]: {
		permission: boolean
};
	["PrivateMediaFields_sizes_thumbnail_Read"]: {
		permission: boolean
};
	["PrivateMediaFields_sizes_thumbnail_Update"]: {
		permission: boolean
};
	["PrivateMediaFields_sizes_thumbnail_Delete"]: {
		permission: boolean
};
	["PrivateMediaFields_sizes_thumbnail_Fields"]: {
		url?: ModelTypes["PrivateMediaFields_sizes_thumbnail_url"] | undefined,
	width?: ModelTypes["PrivateMediaFields_sizes_thumbnail_width"] | undefined,
	height?: ModelTypes["PrivateMediaFields_sizes_thumbnail_height"] | undefined,
	mimeType?: ModelTypes["PrivateMediaFields_sizes_thumbnail_mimeType"] | undefined,
	filesize?: ModelTypes["PrivateMediaFields_sizes_thumbnail_filesize"] | undefined,
	filename?: ModelTypes["PrivateMediaFields_sizes_thumbnail_filename"] | undefined
};
	["PrivateMediaFields_sizes_thumbnail_url"]: {
		create?: ModelTypes["PrivateMediaFields_sizes_thumbnail_url_Create"] | undefined,
	read?: ModelTypes["PrivateMediaFields_sizes_thumbnail_url_Read"] | undefined,
	update?: ModelTypes["PrivateMediaFields_sizes_thumbnail_url_Update"] | undefined,
	delete?: ModelTypes["PrivateMediaFields_sizes_thumbnail_url_Delete"] | undefined
};
	["PrivateMediaFields_sizes_thumbnail_url_Create"]: {
		permission: boolean
};
	["PrivateMediaFields_sizes_thumbnail_url_Read"]: {
		permission: boolean
};
	["PrivateMediaFields_sizes_thumbnail_url_Update"]: {
		permission: boolean
};
	["PrivateMediaFields_sizes_thumbnail_url_Delete"]: {
		permission: boolean
};
	["PrivateMediaFields_sizes_thumbnail_width"]: {
		create?: ModelTypes["PrivateMediaFields_sizes_thumbnail_width_Create"] | undefined,
	read?: ModelTypes["PrivateMediaFields_sizes_thumbnail_width_Read"] | undefined,
	update?: ModelTypes["PrivateMediaFields_sizes_thumbnail_width_Update"] | undefined,
	delete?: ModelTypes["PrivateMediaFields_sizes_thumbnail_width_Delete"] | undefined
};
	["PrivateMediaFields_sizes_thumbnail_width_Create"]: {
		permission: boolean
};
	["PrivateMediaFields_sizes_thumbnail_width_Read"]: {
		permission: boolean
};
	["PrivateMediaFields_sizes_thumbnail_width_Update"]: {
		permission: boolean
};
	["PrivateMediaFields_sizes_thumbnail_width_Delete"]: {
		permission: boolean
};
	["PrivateMediaFields_sizes_thumbnail_height"]: {
		create?: ModelTypes["PrivateMediaFields_sizes_thumbnail_height_Create"] | undefined,
	read?: ModelTypes["PrivateMediaFields_sizes_thumbnail_height_Read"] | undefined,
	update?: ModelTypes["PrivateMediaFields_sizes_thumbnail_height_Update"] | undefined,
	delete?: ModelTypes["PrivateMediaFields_sizes_thumbnail_height_Delete"] | undefined
};
	["PrivateMediaFields_sizes_thumbnail_height_Create"]: {
		permission: boolean
};
	["PrivateMediaFields_sizes_thumbnail_height_Read"]: {
		permission: boolean
};
	["PrivateMediaFields_sizes_thumbnail_height_Update"]: {
		permission: boolean
};
	["PrivateMediaFields_sizes_thumbnail_height_Delete"]: {
		permission: boolean
};
	["PrivateMediaFields_sizes_thumbnail_mimeType"]: {
		create?: ModelTypes["PrivateMediaFields_sizes_thumbnail_mimeType_Create"] | undefined,
	read?: ModelTypes["PrivateMediaFields_sizes_thumbnail_mimeType_Read"] | undefined,
	update?: ModelTypes["PrivateMediaFields_sizes_thumbnail_mimeType_Update"] | undefined,
	delete?: ModelTypes["PrivateMediaFields_sizes_thumbnail_mimeType_Delete"] | undefined
};
	["PrivateMediaFields_sizes_thumbnail_mimeType_Create"]: {
		permission: boolean
};
	["PrivateMediaFields_sizes_thumbnail_mimeType_Read"]: {
		permission: boolean
};
	["PrivateMediaFields_sizes_thumbnail_mimeType_Update"]: {
		permission: boolean
};
	["PrivateMediaFields_sizes_thumbnail_mimeType_Delete"]: {
		permission: boolean
};
	["PrivateMediaFields_sizes_thumbnail_filesize"]: {
		create?: ModelTypes["PrivateMediaFields_sizes_thumbnail_filesize_Create"] | undefined,
	read?: ModelTypes["PrivateMediaFields_sizes_thumbnail_filesize_Read"] | undefined,
	update?: ModelTypes["PrivateMediaFields_sizes_thumbnail_filesize_Update"] | undefined,
	delete?: ModelTypes["PrivateMediaFields_sizes_thumbnail_filesize_Delete"] | undefined
};
	["PrivateMediaFields_sizes_thumbnail_filesize_Create"]: {
		permission: boolean
};
	["PrivateMediaFields_sizes_thumbnail_filesize_Read"]: {
		permission: boolean
};
	["PrivateMediaFields_sizes_thumbnail_filesize_Update"]: {
		permission: boolean
};
	["PrivateMediaFields_sizes_thumbnail_filesize_Delete"]: {
		permission: boolean
};
	["PrivateMediaFields_sizes_thumbnail_filename"]: {
		create?: ModelTypes["PrivateMediaFields_sizes_thumbnail_filename_Create"] | undefined,
	read?: ModelTypes["PrivateMediaFields_sizes_thumbnail_filename_Read"] | undefined,
	update?: ModelTypes["PrivateMediaFields_sizes_thumbnail_filename_Update"] | undefined,
	delete?: ModelTypes["PrivateMediaFields_sizes_thumbnail_filename_Delete"] | undefined
};
	["PrivateMediaFields_sizes_thumbnail_filename_Create"]: {
		permission: boolean
};
	["PrivateMediaFields_sizes_thumbnail_filename_Read"]: {
		permission: boolean
};
	["PrivateMediaFields_sizes_thumbnail_filename_Update"]: {
		permission: boolean
};
	["PrivateMediaFields_sizes_thumbnail_filename_Delete"]: {
		permission: boolean
};
	["PrivateMediaFields_sizes_portrait"]: {
		create?: ModelTypes["PrivateMediaFields_sizes_portrait_Create"] | undefined,
	read?: ModelTypes["PrivateMediaFields_sizes_portrait_Read"] | undefined,
	update?: ModelTypes["PrivateMediaFields_sizes_portrait_Update"] | undefined,
	delete?: ModelTypes["PrivateMediaFields_sizes_portrait_Delete"] | undefined,
	fields?: ModelTypes["PrivateMediaFields_sizes_portrait_Fields"] | undefined
};
	["PrivateMediaFields_sizes_portrait_Create"]: {
		permission: boolean
};
	["PrivateMediaFields_sizes_portrait_Read"]: {
		permission: boolean
};
	["PrivateMediaFields_sizes_portrait_Update"]: {
		permission: boolean
};
	["PrivateMediaFields_sizes_portrait_Delete"]: {
		permission: boolean
};
	["PrivateMediaFields_sizes_portrait_Fields"]: {
		url?: ModelTypes["PrivateMediaFields_sizes_portrait_url"] | undefined,
	width?: ModelTypes["PrivateMediaFields_sizes_portrait_width"] | undefined,
	height?: ModelTypes["PrivateMediaFields_sizes_portrait_height"] | undefined,
	mimeType?: ModelTypes["PrivateMediaFields_sizes_portrait_mimeType"] | undefined,
	filesize?: ModelTypes["PrivateMediaFields_sizes_portrait_filesize"] | undefined,
	filename?: ModelTypes["PrivateMediaFields_sizes_portrait_filename"] | undefined
};
	["PrivateMediaFields_sizes_portrait_url"]: {
		create?: ModelTypes["PrivateMediaFields_sizes_portrait_url_Create"] | undefined,
	read?: ModelTypes["PrivateMediaFields_sizes_portrait_url_Read"] | undefined,
	update?: ModelTypes["PrivateMediaFields_sizes_portrait_url_Update"] | undefined,
	delete?: ModelTypes["PrivateMediaFields_sizes_portrait_url_Delete"] | undefined
};
	["PrivateMediaFields_sizes_portrait_url_Create"]: {
		permission: boolean
};
	["PrivateMediaFields_sizes_portrait_url_Read"]: {
		permission: boolean
};
	["PrivateMediaFields_sizes_portrait_url_Update"]: {
		permission: boolean
};
	["PrivateMediaFields_sizes_portrait_url_Delete"]: {
		permission: boolean
};
	["PrivateMediaFields_sizes_portrait_width"]: {
		create?: ModelTypes["PrivateMediaFields_sizes_portrait_width_Create"] | undefined,
	read?: ModelTypes["PrivateMediaFields_sizes_portrait_width_Read"] | undefined,
	update?: ModelTypes["PrivateMediaFields_sizes_portrait_width_Update"] | undefined,
	delete?: ModelTypes["PrivateMediaFields_sizes_portrait_width_Delete"] | undefined
};
	["PrivateMediaFields_sizes_portrait_width_Create"]: {
		permission: boolean
};
	["PrivateMediaFields_sizes_portrait_width_Read"]: {
		permission: boolean
};
	["PrivateMediaFields_sizes_portrait_width_Update"]: {
		permission: boolean
};
	["PrivateMediaFields_sizes_portrait_width_Delete"]: {
		permission: boolean
};
	["PrivateMediaFields_sizes_portrait_height"]: {
		create?: ModelTypes["PrivateMediaFields_sizes_portrait_height_Create"] | undefined,
	read?: ModelTypes["PrivateMediaFields_sizes_portrait_height_Read"] | undefined,
	update?: ModelTypes["PrivateMediaFields_sizes_portrait_height_Update"] | undefined,
	delete?: ModelTypes["PrivateMediaFields_sizes_portrait_height_Delete"] | undefined
};
	["PrivateMediaFields_sizes_portrait_height_Create"]: {
		permission: boolean
};
	["PrivateMediaFields_sizes_portrait_height_Read"]: {
		permission: boolean
};
	["PrivateMediaFields_sizes_portrait_height_Update"]: {
		permission: boolean
};
	["PrivateMediaFields_sizes_portrait_height_Delete"]: {
		permission: boolean
};
	["PrivateMediaFields_sizes_portrait_mimeType"]: {
		create?: ModelTypes["PrivateMediaFields_sizes_portrait_mimeType_Create"] | undefined,
	read?: ModelTypes["PrivateMediaFields_sizes_portrait_mimeType_Read"] | undefined,
	update?: ModelTypes["PrivateMediaFields_sizes_portrait_mimeType_Update"] | undefined,
	delete?: ModelTypes["PrivateMediaFields_sizes_portrait_mimeType_Delete"] | undefined
};
	["PrivateMediaFields_sizes_portrait_mimeType_Create"]: {
		permission: boolean
};
	["PrivateMediaFields_sizes_portrait_mimeType_Read"]: {
		permission: boolean
};
	["PrivateMediaFields_sizes_portrait_mimeType_Update"]: {
		permission: boolean
};
	["PrivateMediaFields_sizes_portrait_mimeType_Delete"]: {
		permission: boolean
};
	["PrivateMediaFields_sizes_portrait_filesize"]: {
		create?: ModelTypes["PrivateMediaFields_sizes_portrait_filesize_Create"] | undefined,
	read?: ModelTypes["PrivateMediaFields_sizes_portrait_filesize_Read"] | undefined,
	update?: ModelTypes["PrivateMediaFields_sizes_portrait_filesize_Update"] | undefined,
	delete?: ModelTypes["PrivateMediaFields_sizes_portrait_filesize_Delete"] | undefined
};
	["PrivateMediaFields_sizes_portrait_filesize_Create"]: {
		permission: boolean
};
	["PrivateMediaFields_sizes_portrait_filesize_Read"]: {
		permission: boolean
};
	["PrivateMediaFields_sizes_portrait_filesize_Update"]: {
		permission: boolean
};
	["PrivateMediaFields_sizes_portrait_filesize_Delete"]: {
		permission: boolean
};
	["PrivateMediaFields_sizes_portrait_filename"]: {
		create?: ModelTypes["PrivateMediaFields_sizes_portrait_filename_Create"] | undefined,
	read?: ModelTypes["PrivateMediaFields_sizes_portrait_filename_Read"] | undefined,
	update?: ModelTypes["PrivateMediaFields_sizes_portrait_filename_Update"] | undefined,
	delete?: ModelTypes["PrivateMediaFields_sizes_portrait_filename_Delete"] | undefined
};
	["PrivateMediaFields_sizes_portrait_filename_Create"]: {
		permission: boolean
};
	["PrivateMediaFields_sizes_portrait_filename_Read"]: {
		permission: boolean
};
	["PrivateMediaFields_sizes_portrait_filename_Update"]: {
		permission: boolean
};
	["PrivateMediaFields_sizes_portrait_filename_Delete"]: {
		permission: boolean
};
	["PrivateMediaFields_sizes_hero"]: {
		create?: ModelTypes["PrivateMediaFields_sizes_hero_Create"] | undefined,
	read?: ModelTypes["PrivateMediaFields_sizes_hero_Read"] | undefined,
	update?: ModelTypes["PrivateMediaFields_sizes_hero_Update"] | undefined,
	delete?: ModelTypes["PrivateMediaFields_sizes_hero_Delete"] | undefined,
	fields?: ModelTypes["PrivateMediaFields_sizes_hero_Fields"] | undefined
};
	["PrivateMediaFields_sizes_hero_Create"]: {
		permission: boolean
};
	["PrivateMediaFields_sizes_hero_Read"]: {
		permission: boolean
};
	["PrivateMediaFields_sizes_hero_Update"]: {
		permission: boolean
};
	["PrivateMediaFields_sizes_hero_Delete"]: {
		permission: boolean
};
	["PrivateMediaFields_sizes_hero_Fields"]: {
		url?: ModelTypes["PrivateMediaFields_sizes_hero_url"] | undefined,
	width?: ModelTypes["PrivateMediaFields_sizes_hero_width"] | undefined,
	height?: ModelTypes["PrivateMediaFields_sizes_hero_height"] | undefined,
	mimeType?: ModelTypes["PrivateMediaFields_sizes_hero_mimeType"] | undefined,
	filesize?: ModelTypes["PrivateMediaFields_sizes_hero_filesize"] | undefined,
	filename?: ModelTypes["PrivateMediaFields_sizes_hero_filename"] | undefined
};
	["PrivateMediaFields_sizes_hero_url"]: {
		create?: ModelTypes["PrivateMediaFields_sizes_hero_url_Create"] | undefined,
	read?: ModelTypes["PrivateMediaFields_sizes_hero_url_Read"] | undefined,
	update?: ModelTypes["PrivateMediaFields_sizes_hero_url_Update"] | undefined,
	delete?: ModelTypes["PrivateMediaFields_sizes_hero_url_Delete"] | undefined
};
	["PrivateMediaFields_sizes_hero_url_Create"]: {
		permission: boolean
};
	["PrivateMediaFields_sizes_hero_url_Read"]: {
		permission: boolean
};
	["PrivateMediaFields_sizes_hero_url_Update"]: {
		permission: boolean
};
	["PrivateMediaFields_sizes_hero_url_Delete"]: {
		permission: boolean
};
	["PrivateMediaFields_sizes_hero_width"]: {
		create?: ModelTypes["PrivateMediaFields_sizes_hero_width_Create"] | undefined,
	read?: ModelTypes["PrivateMediaFields_sizes_hero_width_Read"] | undefined,
	update?: ModelTypes["PrivateMediaFields_sizes_hero_width_Update"] | undefined,
	delete?: ModelTypes["PrivateMediaFields_sizes_hero_width_Delete"] | undefined
};
	["PrivateMediaFields_sizes_hero_width_Create"]: {
		permission: boolean
};
	["PrivateMediaFields_sizes_hero_width_Read"]: {
		permission: boolean
};
	["PrivateMediaFields_sizes_hero_width_Update"]: {
		permission: boolean
};
	["PrivateMediaFields_sizes_hero_width_Delete"]: {
		permission: boolean
};
	["PrivateMediaFields_sizes_hero_height"]: {
		create?: ModelTypes["PrivateMediaFields_sizes_hero_height_Create"] | undefined,
	read?: ModelTypes["PrivateMediaFields_sizes_hero_height_Read"] | undefined,
	update?: ModelTypes["PrivateMediaFields_sizes_hero_height_Update"] | undefined,
	delete?: ModelTypes["PrivateMediaFields_sizes_hero_height_Delete"] | undefined
};
	["PrivateMediaFields_sizes_hero_height_Create"]: {
		permission: boolean
};
	["PrivateMediaFields_sizes_hero_height_Read"]: {
		permission: boolean
};
	["PrivateMediaFields_sizes_hero_height_Update"]: {
		permission: boolean
};
	["PrivateMediaFields_sizes_hero_height_Delete"]: {
		permission: boolean
};
	["PrivateMediaFields_sizes_hero_mimeType"]: {
		create?: ModelTypes["PrivateMediaFields_sizes_hero_mimeType_Create"] | undefined,
	read?: ModelTypes["PrivateMediaFields_sizes_hero_mimeType_Read"] | undefined,
	update?: ModelTypes["PrivateMediaFields_sizes_hero_mimeType_Update"] | undefined,
	delete?: ModelTypes["PrivateMediaFields_sizes_hero_mimeType_Delete"] | undefined
};
	["PrivateMediaFields_sizes_hero_mimeType_Create"]: {
		permission: boolean
};
	["PrivateMediaFields_sizes_hero_mimeType_Read"]: {
		permission: boolean
};
	["PrivateMediaFields_sizes_hero_mimeType_Update"]: {
		permission: boolean
};
	["PrivateMediaFields_sizes_hero_mimeType_Delete"]: {
		permission: boolean
};
	["PrivateMediaFields_sizes_hero_filesize"]: {
		create?: ModelTypes["PrivateMediaFields_sizes_hero_filesize_Create"] | undefined,
	read?: ModelTypes["PrivateMediaFields_sizes_hero_filesize_Read"] | undefined,
	update?: ModelTypes["PrivateMediaFields_sizes_hero_filesize_Update"] | undefined,
	delete?: ModelTypes["PrivateMediaFields_sizes_hero_filesize_Delete"] | undefined
};
	["PrivateMediaFields_sizes_hero_filesize_Create"]: {
		permission: boolean
};
	["PrivateMediaFields_sizes_hero_filesize_Read"]: {
		permission: boolean
};
	["PrivateMediaFields_sizes_hero_filesize_Update"]: {
		permission: boolean
};
	["PrivateMediaFields_sizes_hero_filesize_Delete"]: {
		permission: boolean
};
	["PrivateMediaFields_sizes_hero_filename"]: {
		create?: ModelTypes["PrivateMediaFields_sizes_hero_filename_Create"] | undefined,
	read?: ModelTypes["PrivateMediaFields_sizes_hero_filename_Read"] | undefined,
	update?: ModelTypes["PrivateMediaFields_sizes_hero_filename_Update"] | undefined,
	delete?: ModelTypes["PrivateMediaFields_sizes_hero_filename_Delete"] | undefined
};
	["PrivateMediaFields_sizes_hero_filename_Create"]: {
		permission: boolean
};
	["PrivateMediaFields_sizes_hero_filename_Read"]: {
		permission: boolean
};
	["PrivateMediaFields_sizes_hero_filename_Update"]: {
		permission: boolean
};
	["PrivateMediaFields_sizes_hero_filename_Delete"]: {
		permission: boolean
};
	["PrivateMediaFields_sizes_thumbnail_jpeg"]: {
		create?: ModelTypes["PrivateMediaFields_sizes_thumbnail_jpeg_Create"] | undefined,
	read?: ModelTypes["PrivateMediaFields_sizes_thumbnail_jpeg_Read"] | undefined,
	update?: ModelTypes["PrivateMediaFields_sizes_thumbnail_jpeg_Update"] | undefined,
	delete?: ModelTypes["PrivateMediaFields_sizes_thumbnail_jpeg_Delete"] | undefined,
	fields?: ModelTypes["PrivateMediaFields_sizes_thumbnail_jpeg_Fields"] | undefined
};
	["PrivateMediaFields_sizes_thumbnail_jpeg_Create"]: {
		permission: boolean
};
	["PrivateMediaFields_sizes_thumbnail_jpeg_Read"]: {
		permission: boolean
};
	["PrivateMediaFields_sizes_thumbnail_jpeg_Update"]: {
		permission: boolean
};
	["PrivateMediaFields_sizes_thumbnail_jpeg_Delete"]: {
		permission: boolean
};
	["PrivateMediaFields_sizes_thumbnail_jpeg_Fields"]: {
		url?: ModelTypes["PrivateMediaFields_sizes_thumbnail_jpeg_url"] | undefined,
	width?: ModelTypes["PrivateMediaFields_sizes_thumbnail_jpeg_width"] | undefined,
	height?: ModelTypes["PrivateMediaFields_sizes_thumbnail_jpeg_height"] | undefined,
	mimeType?: ModelTypes["PrivateMediaFields_sizes_thumbnail_jpeg_mimeType"] | undefined,
	filesize?: ModelTypes["PrivateMediaFields_sizes_thumbnail_jpeg_filesize"] | undefined,
	filename?: ModelTypes["PrivateMediaFields_sizes_thumbnail_jpeg_filename"] | undefined
};
	["PrivateMediaFields_sizes_thumbnail_jpeg_url"]: {
		create?: ModelTypes["PrivateMediaFields_sizes_thumbnail_jpeg_url_Create"] | undefined,
	read?: ModelTypes["PrivateMediaFields_sizes_thumbnail_jpeg_url_Read"] | undefined,
	update?: ModelTypes["PrivateMediaFields_sizes_thumbnail_jpeg_url_Update"] | undefined,
	delete?: ModelTypes["PrivateMediaFields_sizes_thumbnail_jpeg_url_Delete"] | undefined
};
	["PrivateMediaFields_sizes_thumbnail_jpeg_url_Create"]: {
		permission: boolean
};
	["PrivateMediaFields_sizes_thumbnail_jpeg_url_Read"]: {
		permission: boolean
};
	["PrivateMediaFields_sizes_thumbnail_jpeg_url_Update"]: {
		permission: boolean
};
	["PrivateMediaFields_sizes_thumbnail_jpeg_url_Delete"]: {
		permission: boolean
};
	["PrivateMediaFields_sizes_thumbnail_jpeg_width"]: {
		create?: ModelTypes["PrivateMediaFields_sizes_thumbnail_jpeg_width_Create"] | undefined,
	read?: ModelTypes["PrivateMediaFields_sizes_thumbnail_jpeg_width_Read"] | undefined,
	update?: ModelTypes["PrivateMediaFields_sizes_thumbnail_jpeg_width_Update"] | undefined,
	delete?: ModelTypes["PrivateMediaFields_sizes_thumbnail_jpeg_width_Delete"] | undefined
};
	["PrivateMediaFields_sizes_thumbnail_jpeg_width_Create"]: {
		permission: boolean
};
	["PrivateMediaFields_sizes_thumbnail_jpeg_width_Read"]: {
		permission: boolean
};
	["PrivateMediaFields_sizes_thumbnail_jpeg_width_Update"]: {
		permission: boolean
};
	["PrivateMediaFields_sizes_thumbnail_jpeg_width_Delete"]: {
		permission: boolean
};
	["PrivateMediaFields_sizes_thumbnail_jpeg_height"]: {
		create?: ModelTypes["PrivateMediaFields_sizes_thumbnail_jpeg_height_Create"] | undefined,
	read?: ModelTypes["PrivateMediaFields_sizes_thumbnail_jpeg_height_Read"] | undefined,
	update?: ModelTypes["PrivateMediaFields_sizes_thumbnail_jpeg_height_Update"] | undefined,
	delete?: ModelTypes["PrivateMediaFields_sizes_thumbnail_jpeg_height_Delete"] | undefined
};
	["PrivateMediaFields_sizes_thumbnail_jpeg_height_Create"]: {
		permission: boolean
};
	["PrivateMediaFields_sizes_thumbnail_jpeg_height_Read"]: {
		permission: boolean
};
	["PrivateMediaFields_sizes_thumbnail_jpeg_height_Update"]: {
		permission: boolean
};
	["PrivateMediaFields_sizes_thumbnail_jpeg_height_Delete"]: {
		permission: boolean
};
	["PrivateMediaFields_sizes_thumbnail_jpeg_mimeType"]: {
		create?: ModelTypes["PrivateMediaFields_sizes_thumbnail_jpeg_mimeType_Create"] | undefined,
	read?: ModelTypes["PrivateMediaFields_sizes_thumbnail_jpeg_mimeType_Read"] | undefined,
	update?: ModelTypes["PrivateMediaFields_sizes_thumbnail_jpeg_mimeType_Update"] | undefined,
	delete?: ModelTypes["PrivateMediaFields_sizes_thumbnail_jpeg_mimeType_Delete"] | undefined
};
	["PrivateMediaFields_sizes_thumbnail_jpeg_mimeType_Create"]: {
		permission: boolean
};
	["PrivateMediaFields_sizes_thumbnail_jpeg_mimeType_Read"]: {
		permission: boolean
};
	["PrivateMediaFields_sizes_thumbnail_jpeg_mimeType_Update"]: {
		permission: boolean
};
	["PrivateMediaFields_sizes_thumbnail_jpeg_mimeType_Delete"]: {
		permission: boolean
};
	["PrivateMediaFields_sizes_thumbnail_jpeg_filesize"]: {
		create?: ModelTypes["PrivateMediaFields_sizes_thumbnail_jpeg_filesize_Create"] | undefined,
	read?: ModelTypes["PrivateMediaFields_sizes_thumbnail_jpeg_filesize_Read"] | undefined,
	update?: ModelTypes["PrivateMediaFields_sizes_thumbnail_jpeg_filesize_Update"] | undefined,
	delete?: ModelTypes["PrivateMediaFields_sizes_thumbnail_jpeg_filesize_Delete"] | undefined
};
	["PrivateMediaFields_sizes_thumbnail_jpeg_filesize_Create"]: {
		permission: boolean
};
	["PrivateMediaFields_sizes_thumbnail_jpeg_filesize_Read"]: {
		permission: boolean
};
	["PrivateMediaFields_sizes_thumbnail_jpeg_filesize_Update"]: {
		permission: boolean
};
	["PrivateMediaFields_sizes_thumbnail_jpeg_filesize_Delete"]: {
		permission: boolean
};
	["PrivateMediaFields_sizes_thumbnail_jpeg_filename"]: {
		create?: ModelTypes["PrivateMediaFields_sizes_thumbnail_jpeg_filename_Create"] | undefined,
	read?: ModelTypes["PrivateMediaFields_sizes_thumbnail_jpeg_filename_Read"] | undefined,
	update?: ModelTypes["PrivateMediaFields_sizes_thumbnail_jpeg_filename_Update"] | undefined,
	delete?: ModelTypes["PrivateMediaFields_sizes_thumbnail_jpeg_filename_Delete"] | undefined
};
	["PrivateMediaFields_sizes_thumbnail_jpeg_filename_Create"]: {
		permission: boolean
};
	["PrivateMediaFields_sizes_thumbnail_jpeg_filename_Read"]: {
		permission: boolean
};
	["PrivateMediaFields_sizes_thumbnail_jpeg_filename_Update"]: {
		permission: boolean
};
	["PrivateMediaFields_sizes_thumbnail_jpeg_filename_Delete"]: {
		permission: boolean
};
	["PrivateMediaFields_sizes_portrait_jpeg"]: {
		create?: ModelTypes["PrivateMediaFields_sizes_portrait_jpeg_Create"] | undefined,
	read?: ModelTypes["PrivateMediaFields_sizes_portrait_jpeg_Read"] | undefined,
	update?: ModelTypes["PrivateMediaFields_sizes_portrait_jpeg_Update"] | undefined,
	delete?: ModelTypes["PrivateMediaFields_sizes_portrait_jpeg_Delete"] | undefined,
	fields?: ModelTypes["PrivateMediaFields_sizes_portrait_jpeg_Fields"] | undefined
};
	["PrivateMediaFields_sizes_portrait_jpeg_Create"]: {
		permission: boolean
};
	["PrivateMediaFields_sizes_portrait_jpeg_Read"]: {
		permission: boolean
};
	["PrivateMediaFields_sizes_portrait_jpeg_Update"]: {
		permission: boolean
};
	["PrivateMediaFields_sizes_portrait_jpeg_Delete"]: {
		permission: boolean
};
	["PrivateMediaFields_sizes_portrait_jpeg_Fields"]: {
		url?: ModelTypes["PrivateMediaFields_sizes_portrait_jpeg_url"] | undefined,
	width?: ModelTypes["PrivateMediaFields_sizes_portrait_jpeg_width"] | undefined,
	height?: ModelTypes["PrivateMediaFields_sizes_portrait_jpeg_height"] | undefined,
	mimeType?: ModelTypes["PrivateMediaFields_sizes_portrait_jpeg_mimeType"] | undefined,
	filesize?: ModelTypes["PrivateMediaFields_sizes_portrait_jpeg_filesize"] | undefined,
	filename?: ModelTypes["PrivateMediaFields_sizes_portrait_jpeg_filename"] | undefined
};
	["PrivateMediaFields_sizes_portrait_jpeg_url"]: {
		create?: ModelTypes["PrivateMediaFields_sizes_portrait_jpeg_url_Create"] | undefined,
	read?: ModelTypes["PrivateMediaFields_sizes_portrait_jpeg_url_Read"] | undefined,
	update?: ModelTypes["PrivateMediaFields_sizes_portrait_jpeg_url_Update"] | undefined,
	delete?: ModelTypes["PrivateMediaFields_sizes_portrait_jpeg_url_Delete"] | undefined
};
	["PrivateMediaFields_sizes_portrait_jpeg_url_Create"]: {
		permission: boolean
};
	["PrivateMediaFields_sizes_portrait_jpeg_url_Read"]: {
		permission: boolean
};
	["PrivateMediaFields_sizes_portrait_jpeg_url_Update"]: {
		permission: boolean
};
	["PrivateMediaFields_sizes_portrait_jpeg_url_Delete"]: {
		permission: boolean
};
	["PrivateMediaFields_sizes_portrait_jpeg_width"]: {
		create?: ModelTypes["PrivateMediaFields_sizes_portrait_jpeg_width_Create"] | undefined,
	read?: ModelTypes["PrivateMediaFields_sizes_portrait_jpeg_width_Read"] | undefined,
	update?: ModelTypes["PrivateMediaFields_sizes_portrait_jpeg_width_Update"] | undefined,
	delete?: ModelTypes["PrivateMediaFields_sizes_portrait_jpeg_width_Delete"] | undefined
};
	["PrivateMediaFields_sizes_portrait_jpeg_width_Create"]: {
		permission: boolean
};
	["PrivateMediaFields_sizes_portrait_jpeg_width_Read"]: {
		permission: boolean
};
	["PrivateMediaFields_sizes_portrait_jpeg_width_Update"]: {
		permission: boolean
};
	["PrivateMediaFields_sizes_portrait_jpeg_width_Delete"]: {
		permission: boolean
};
	["PrivateMediaFields_sizes_portrait_jpeg_height"]: {
		create?: ModelTypes["PrivateMediaFields_sizes_portrait_jpeg_height_Create"] | undefined,
	read?: ModelTypes["PrivateMediaFields_sizes_portrait_jpeg_height_Read"] | undefined,
	update?: ModelTypes["PrivateMediaFields_sizes_portrait_jpeg_height_Update"] | undefined,
	delete?: ModelTypes["PrivateMediaFields_sizes_portrait_jpeg_height_Delete"] | undefined
};
	["PrivateMediaFields_sizes_portrait_jpeg_height_Create"]: {
		permission: boolean
};
	["PrivateMediaFields_sizes_portrait_jpeg_height_Read"]: {
		permission: boolean
};
	["PrivateMediaFields_sizes_portrait_jpeg_height_Update"]: {
		permission: boolean
};
	["PrivateMediaFields_sizes_portrait_jpeg_height_Delete"]: {
		permission: boolean
};
	["PrivateMediaFields_sizes_portrait_jpeg_mimeType"]: {
		create?: ModelTypes["PrivateMediaFields_sizes_portrait_jpeg_mimeType_Create"] | undefined,
	read?: ModelTypes["PrivateMediaFields_sizes_portrait_jpeg_mimeType_Read"] | undefined,
	update?: ModelTypes["PrivateMediaFields_sizes_portrait_jpeg_mimeType_Update"] | undefined,
	delete?: ModelTypes["PrivateMediaFields_sizes_portrait_jpeg_mimeType_Delete"] | undefined
};
	["PrivateMediaFields_sizes_portrait_jpeg_mimeType_Create"]: {
		permission: boolean
};
	["PrivateMediaFields_sizes_portrait_jpeg_mimeType_Read"]: {
		permission: boolean
};
	["PrivateMediaFields_sizes_portrait_jpeg_mimeType_Update"]: {
		permission: boolean
};
	["PrivateMediaFields_sizes_portrait_jpeg_mimeType_Delete"]: {
		permission: boolean
};
	["PrivateMediaFields_sizes_portrait_jpeg_filesize"]: {
		create?: ModelTypes["PrivateMediaFields_sizes_portrait_jpeg_filesize_Create"] | undefined,
	read?: ModelTypes["PrivateMediaFields_sizes_portrait_jpeg_filesize_Read"] | undefined,
	update?: ModelTypes["PrivateMediaFields_sizes_portrait_jpeg_filesize_Update"] | undefined,
	delete?: ModelTypes["PrivateMediaFields_sizes_portrait_jpeg_filesize_Delete"] | undefined
};
	["PrivateMediaFields_sizes_portrait_jpeg_filesize_Create"]: {
		permission: boolean
};
	["PrivateMediaFields_sizes_portrait_jpeg_filesize_Read"]: {
		permission: boolean
};
	["PrivateMediaFields_sizes_portrait_jpeg_filesize_Update"]: {
		permission: boolean
};
	["PrivateMediaFields_sizes_portrait_jpeg_filesize_Delete"]: {
		permission: boolean
};
	["PrivateMediaFields_sizes_portrait_jpeg_filename"]: {
		create?: ModelTypes["PrivateMediaFields_sizes_portrait_jpeg_filename_Create"] | undefined,
	read?: ModelTypes["PrivateMediaFields_sizes_portrait_jpeg_filename_Read"] | undefined,
	update?: ModelTypes["PrivateMediaFields_sizes_portrait_jpeg_filename_Update"] | undefined,
	delete?: ModelTypes["PrivateMediaFields_sizes_portrait_jpeg_filename_Delete"] | undefined
};
	["PrivateMediaFields_sizes_portrait_jpeg_filename_Create"]: {
		permission: boolean
};
	["PrivateMediaFields_sizes_portrait_jpeg_filename_Read"]: {
		permission: boolean
};
	["PrivateMediaFields_sizes_portrait_jpeg_filename_Update"]: {
		permission: boolean
};
	["PrivateMediaFields_sizes_portrait_jpeg_filename_Delete"]: {
		permission: boolean
};
	["PrivateMediaFields_sizes_hero_jpeg"]: {
		create?: ModelTypes["PrivateMediaFields_sizes_hero_jpeg_Create"] | undefined,
	read?: ModelTypes["PrivateMediaFields_sizes_hero_jpeg_Read"] | undefined,
	update?: ModelTypes["PrivateMediaFields_sizes_hero_jpeg_Update"] | undefined,
	delete?: ModelTypes["PrivateMediaFields_sizes_hero_jpeg_Delete"] | undefined,
	fields?: ModelTypes["PrivateMediaFields_sizes_hero_jpeg_Fields"] | undefined
};
	["PrivateMediaFields_sizes_hero_jpeg_Create"]: {
		permission: boolean
};
	["PrivateMediaFields_sizes_hero_jpeg_Read"]: {
		permission: boolean
};
	["PrivateMediaFields_sizes_hero_jpeg_Update"]: {
		permission: boolean
};
	["PrivateMediaFields_sizes_hero_jpeg_Delete"]: {
		permission: boolean
};
	["PrivateMediaFields_sizes_hero_jpeg_Fields"]: {
		url?: ModelTypes["PrivateMediaFields_sizes_hero_jpeg_url"] | undefined,
	width?: ModelTypes["PrivateMediaFields_sizes_hero_jpeg_width"] | undefined,
	height?: ModelTypes["PrivateMediaFields_sizes_hero_jpeg_height"] | undefined,
	mimeType?: ModelTypes["PrivateMediaFields_sizes_hero_jpeg_mimeType"] | undefined,
	filesize?: ModelTypes["PrivateMediaFields_sizes_hero_jpeg_filesize"] | undefined,
	filename?: ModelTypes["PrivateMediaFields_sizes_hero_jpeg_filename"] | undefined
};
	["PrivateMediaFields_sizes_hero_jpeg_url"]: {
		create?: ModelTypes["PrivateMediaFields_sizes_hero_jpeg_url_Create"] | undefined,
	read?: ModelTypes["PrivateMediaFields_sizes_hero_jpeg_url_Read"] | undefined,
	update?: ModelTypes["PrivateMediaFields_sizes_hero_jpeg_url_Update"] | undefined,
	delete?: ModelTypes["PrivateMediaFields_sizes_hero_jpeg_url_Delete"] | undefined
};
	["PrivateMediaFields_sizes_hero_jpeg_url_Create"]: {
		permission: boolean
};
	["PrivateMediaFields_sizes_hero_jpeg_url_Read"]: {
		permission: boolean
};
	["PrivateMediaFields_sizes_hero_jpeg_url_Update"]: {
		permission: boolean
};
	["PrivateMediaFields_sizes_hero_jpeg_url_Delete"]: {
		permission: boolean
};
	["PrivateMediaFields_sizes_hero_jpeg_width"]: {
		create?: ModelTypes["PrivateMediaFields_sizes_hero_jpeg_width_Create"] | undefined,
	read?: ModelTypes["PrivateMediaFields_sizes_hero_jpeg_width_Read"] | undefined,
	update?: ModelTypes["PrivateMediaFields_sizes_hero_jpeg_width_Update"] | undefined,
	delete?: ModelTypes["PrivateMediaFields_sizes_hero_jpeg_width_Delete"] | undefined
};
	["PrivateMediaFields_sizes_hero_jpeg_width_Create"]: {
		permission: boolean
};
	["PrivateMediaFields_sizes_hero_jpeg_width_Read"]: {
		permission: boolean
};
	["PrivateMediaFields_sizes_hero_jpeg_width_Update"]: {
		permission: boolean
};
	["PrivateMediaFields_sizes_hero_jpeg_width_Delete"]: {
		permission: boolean
};
	["PrivateMediaFields_sizes_hero_jpeg_height"]: {
		create?: ModelTypes["PrivateMediaFields_sizes_hero_jpeg_height_Create"] | undefined,
	read?: ModelTypes["PrivateMediaFields_sizes_hero_jpeg_height_Read"] | undefined,
	update?: ModelTypes["PrivateMediaFields_sizes_hero_jpeg_height_Update"] | undefined,
	delete?: ModelTypes["PrivateMediaFields_sizes_hero_jpeg_height_Delete"] | undefined
};
	["PrivateMediaFields_sizes_hero_jpeg_height_Create"]: {
		permission: boolean
};
	["PrivateMediaFields_sizes_hero_jpeg_height_Read"]: {
		permission: boolean
};
	["PrivateMediaFields_sizes_hero_jpeg_height_Update"]: {
		permission: boolean
};
	["PrivateMediaFields_sizes_hero_jpeg_height_Delete"]: {
		permission: boolean
};
	["PrivateMediaFields_sizes_hero_jpeg_mimeType"]: {
		create?: ModelTypes["PrivateMediaFields_sizes_hero_jpeg_mimeType_Create"] | undefined,
	read?: ModelTypes["PrivateMediaFields_sizes_hero_jpeg_mimeType_Read"] | undefined,
	update?: ModelTypes["PrivateMediaFields_sizes_hero_jpeg_mimeType_Update"] | undefined,
	delete?: ModelTypes["PrivateMediaFields_sizes_hero_jpeg_mimeType_Delete"] | undefined
};
	["PrivateMediaFields_sizes_hero_jpeg_mimeType_Create"]: {
		permission: boolean
};
	["PrivateMediaFields_sizes_hero_jpeg_mimeType_Read"]: {
		permission: boolean
};
	["PrivateMediaFields_sizes_hero_jpeg_mimeType_Update"]: {
		permission: boolean
};
	["PrivateMediaFields_sizes_hero_jpeg_mimeType_Delete"]: {
		permission: boolean
};
	["PrivateMediaFields_sizes_hero_jpeg_filesize"]: {
		create?: ModelTypes["PrivateMediaFields_sizes_hero_jpeg_filesize_Create"] | undefined,
	read?: ModelTypes["PrivateMediaFields_sizes_hero_jpeg_filesize_Read"] | undefined,
	update?: ModelTypes["PrivateMediaFields_sizes_hero_jpeg_filesize_Update"] | undefined,
	delete?: ModelTypes["PrivateMediaFields_sizes_hero_jpeg_filesize_Delete"] | undefined
};
	["PrivateMediaFields_sizes_hero_jpeg_filesize_Create"]: {
		permission: boolean
};
	["PrivateMediaFields_sizes_hero_jpeg_filesize_Read"]: {
		permission: boolean
};
	["PrivateMediaFields_sizes_hero_jpeg_filesize_Update"]: {
		permission: boolean
};
	["PrivateMediaFields_sizes_hero_jpeg_filesize_Delete"]: {
		permission: boolean
};
	["PrivateMediaFields_sizes_hero_jpeg_filename"]: {
		create?: ModelTypes["PrivateMediaFields_sizes_hero_jpeg_filename_Create"] | undefined,
	read?: ModelTypes["PrivateMediaFields_sizes_hero_jpeg_filename_Read"] | undefined,
	update?: ModelTypes["PrivateMediaFields_sizes_hero_jpeg_filename_Update"] | undefined,
	delete?: ModelTypes["PrivateMediaFields_sizes_hero_jpeg_filename_Delete"] | undefined
};
	["PrivateMediaFields_sizes_hero_jpeg_filename_Create"]: {
		permission: boolean
};
	["PrivateMediaFields_sizes_hero_jpeg_filename_Read"]: {
		permission: boolean
};
	["PrivateMediaFields_sizes_hero_jpeg_filename_Update"]: {
		permission: boolean
};
	["PrivateMediaFields_sizes_hero_jpeg_filename_Delete"]: {
		permission: boolean
};
	["PrivateMediaCreateAccess"]: {
		permission: boolean,
	where?: ModelTypes["JSONObject"] | undefined
};
	["PrivateMediaReadAccess"]: {
		permission: boolean,
	where?: ModelTypes["JSONObject"] | undefined
};
	["PrivateMediaUpdateAccess"]: {
		permission: boolean,
	where?: ModelTypes["JSONObject"] | undefined
};
	["PrivateMediaDeleteAccess"]: {
		permission: boolean,
	where?: ModelTypes["JSONObject"] | undefined
};
	["mediaAccess"]: {
		fields?: ModelTypes["MediaFields"] | undefined,
	create?: ModelTypes["MediaCreateAccess"] | undefined,
	read?: ModelTypes["MediaReadAccess"] | undefined,
	update?: ModelTypes["MediaUpdateAccess"] | undefined,
	delete?: ModelTypes["MediaDeleteAccess"] | undefined
};
	["MediaFields"]: {
		alt?: ModelTypes["MediaFields_alt"] | undefined,
	updatedAt?: ModelTypes["MediaFields_updatedAt"] | undefined,
	createdAt?: ModelTypes["MediaFields_createdAt"] | undefined,
	url?: ModelTypes["MediaFields_url"] | undefined,
	filename?: ModelTypes["MediaFields_filename"] | undefined,
	mimeType?: ModelTypes["MediaFields_mimeType"] | undefined,
	filesize?: ModelTypes["MediaFields_filesize"] | undefined,
	width?: ModelTypes["MediaFields_width"] | undefined,
	height?: ModelTypes["MediaFields_height"] | undefined,
	focalX?: ModelTypes["MediaFields_focalX"] | undefined,
	focalY?: ModelTypes["MediaFields_focalY"] | undefined,
	sizes?: ModelTypes["MediaFields_sizes"] | undefined
};
	["MediaFields_alt"]: {
		create?: ModelTypes["MediaFields_alt_Create"] | undefined,
	read?: ModelTypes["MediaFields_alt_Read"] | undefined,
	update?: ModelTypes["MediaFields_alt_Update"] | undefined,
	delete?: ModelTypes["MediaFields_alt_Delete"] | undefined
};
	["MediaFields_alt_Create"]: {
		permission: boolean
};
	["MediaFields_alt_Read"]: {
		permission: boolean
};
	["MediaFields_alt_Update"]: {
		permission: boolean
};
	["MediaFields_alt_Delete"]: {
		permission: boolean
};
	["MediaFields_updatedAt"]: {
		create?: ModelTypes["MediaFields_updatedAt_Create"] | undefined,
	read?: ModelTypes["MediaFields_updatedAt_Read"] | undefined,
	update?: ModelTypes["MediaFields_updatedAt_Update"] | undefined,
	delete?: ModelTypes["MediaFields_updatedAt_Delete"] | undefined
};
	["MediaFields_updatedAt_Create"]: {
		permission: boolean
};
	["MediaFields_updatedAt_Read"]: {
		permission: boolean
};
	["MediaFields_updatedAt_Update"]: {
		permission: boolean
};
	["MediaFields_updatedAt_Delete"]: {
		permission: boolean
};
	["MediaFields_createdAt"]: {
		create?: ModelTypes["MediaFields_createdAt_Create"] | undefined,
	read?: ModelTypes["MediaFields_createdAt_Read"] | undefined,
	update?: ModelTypes["MediaFields_createdAt_Update"] | undefined,
	delete?: ModelTypes["MediaFields_createdAt_Delete"] | undefined
};
	["MediaFields_createdAt_Create"]: {
		permission: boolean
};
	["MediaFields_createdAt_Read"]: {
		permission: boolean
};
	["MediaFields_createdAt_Update"]: {
		permission: boolean
};
	["MediaFields_createdAt_Delete"]: {
		permission: boolean
};
	["MediaFields_url"]: {
		create?: ModelTypes["MediaFields_url_Create"] | undefined,
	read?: ModelTypes["MediaFields_url_Read"] | undefined,
	update?: ModelTypes["MediaFields_url_Update"] | undefined,
	delete?: ModelTypes["MediaFields_url_Delete"] | undefined
};
	["MediaFields_url_Create"]: {
		permission: boolean
};
	["MediaFields_url_Read"]: {
		permission: boolean
};
	["MediaFields_url_Update"]: {
		permission: boolean
};
	["MediaFields_url_Delete"]: {
		permission: boolean
};
	["MediaFields_filename"]: {
		create?: ModelTypes["MediaFields_filename_Create"] | undefined,
	read?: ModelTypes["MediaFields_filename_Read"] | undefined,
	update?: ModelTypes["MediaFields_filename_Update"] | undefined,
	delete?: ModelTypes["MediaFields_filename_Delete"] | undefined
};
	["MediaFields_filename_Create"]: {
		permission: boolean
};
	["MediaFields_filename_Read"]: {
		permission: boolean
};
	["MediaFields_filename_Update"]: {
		permission: boolean
};
	["MediaFields_filename_Delete"]: {
		permission: boolean
};
	["MediaFields_mimeType"]: {
		create?: ModelTypes["MediaFields_mimeType_Create"] | undefined,
	read?: ModelTypes["MediaFields_mimeType_Read"] | undefined,
	update?: ModelTypes["MediaFields_mimeType_Update"] | undefined,
	delete?: ModelTypes["MediaFields_mimeType_Delete"] | undefined
};
	["MediaFields_mimeType_Create"]: {
		permission: boolean
};
	["MediaFields_mimeType_Read"]: {
		permission: boolean
};
	["MediaFields_mimeType_Update"]: {
		permission: boolean
};
	["MediaFields_mimeType_Delete"]: {
		permission: boolean
};
	["MediaFields_filesize"]: {
		create?: ModelTypes["MediaFields_filesize_Create"] | undefined,
	read?: ModelTypes["MediaFields_filesize_Read"] | undefined,
	update?: ModelTypes["MediaFields_filesize_Update"] | undefined,
	delete?: ModelTypes["MediaFields_filesize_Delete"] | undefined
};
	["MediaFields_filesize_Create"]: {
		permission: boolean
};
	["MediaFields_filesize_Read"]: {
		permission: boolean
};
	["MediaFields_filesize_Update"]: {
		permission: boolean
};
	["MediaFields_filesize_Delete"]: {
		permission: boolean
};
	["MediaFields_width"]: {
		create?: ModelTypes["MediaFields_width_Create"] | undefined,
	read?: ModelTypes["MediaFields_width_Read"] | undefined,
	update?: ModelTypes["MediaFields_width_Update"] | undefined,
	delete?: ModelTypes["MediaFields_width_Delete"] | undefined
};
	["MediaFields_width_Create"]: {
		permission: boolean
};
	["MediaFields_width_Read"]: {
		permission: boolean
};
	["MediaFields_width_Update"]: {
		permission: boolean
};
	["MediaFields_width_Delete"]: {
		permission: boolean
};
	["MediaFields_height"]: {
		create?: ModelTypes["MediaFields_height_Create"] | undefined,
	read?: ModelTypes["MediaFields_height_Read"] | undefined,
	update?: ModelTypes["MediaFields_height_Update"] | undefined,
	delete?: ModelTypes["MediaFields_height_Delete"] | undefined
};
	["MediaFields_height_Create"]: {
		permission: boolean
};
	["MediaFields_height_Read"]: {
		permission: boolean
};
	["MediaFields_height_Update"]: {
		permission: boolean
};
	["MediaFields_height_Delete"]: {
		permission: boolean
};
	["MediaFields_focalX"]: {
		create?: ModelTypes["MediaFields_focalX_Create"] | undefined,
	read?: ModelTypes["MediaFields_focalX_Read"] | undefined,
	update?: ModelTypes["MediaFields_focalX_Update"] | undefined,
	delete?: ModelTypes["MediaFields_focalX_Delete"] | undefined
};
	["MediaFields_focalX_Create"]: {
		permission: boolean
};
	["MediaFields_focalX_Read"]: {
		permission: boolean
};
	["MediaFields_focalX_Update"]: {
		permission: boolean
};
	["MediaFields_focalX_Delete"]: {
		permission: boolean
};
	["MediaFields_focalY"]: {
		create?: ModelTypes["MediaFields_focalY_Create"] | undefined,
	read?: ModelTypes["MediaFields_focalY_Read"] | undefined,
	update?: ModelTypes["MediaFields_focalY_Update"] | undefined,
	delete?: ModelTypes["MediaFields_focalY_Delete"] | undefined
};
	["MediaFields_focalY_Create"]: {
		permission: boolean
};
	["MediaFields_focalY_Read"]: {
		permission: boolean
};
	["MediaFields_focalY_Update"]: {
		permission: boolean
};
	["MediaFields_focalY_Delete"]: {
		permission: boolean
};
	["MediaFields_sizes"]: {
		create?: ModelTypes["MediaFields_sizes_Create"] | undefined,
	read?: ModelTypes["MediaFields_sizes_Read"] | undefined,
	update?: ModelTypes["MediaFields_sizes_Update"] | undefined,
	delete?: ModelTypes["MediaFields_sizes_Delete"] | undefined,
	fields?: ModelTypes["MediaFields_sizes_Fields"] | undefined
};
	["MediaFields_sizes_Create"]: {
		permission: boolean
};
	["MediaFields_sizes_Read"]: {
		permission: boolean
};
	["MediaFields_sizes_Update"]: {
		permission: boolean
};
	["MediaFields_sizes_Delete"]: {
		permission: boolean
};
	["MediaFields_sizes_Fields"]: {
		small?: ModelTypes["MediaFields_sizes_small"] | undefined,
	medium?: ModelTypes["MediaFields_sizes_medium"] | undefined,
	large?: ModelTypes["MediaFields_sizes_large"] | undefined,
	small_jpeg?: ModelTypes["MediaFields_sizes_small_jpeg"] | undefined,
	medium_jpeg?: ModelTypes["MediaFields_sizes_medium_jpeg"] | undefined,
	large_jpeg?: ModelTypes["MediaFields_sizes_large_jpeg"] | undefined
};
	["MediaFields_sizes_small"]: {
		create?: ModelTypes["MediaFields_sizes_small_Create"] | undefined,
	read?: ModelTypes["MediaFields_sizes_small_Read"] | undefined,
	update?: ModelTypes["MediaFields_sizes_small_Update"] | undefined,
	delete?: ModelTypes["MediaFields_sizes_small_Delete"] | undefined,
	fields?: ModelTypes["MediaFields_sizes_small_Fields"] | undefined
};
	["MediaFields_sizes_small_Create"]: {
		permission: boolean
};
	["MediaFields_sizes_small_Read"]: {
		permission: boolean
};
	["MediaFields_sizes_small_Update"]: {
		permission: boolean
};
	["MediaFields_sizes_small_Delete"]: {
		permission: boolean
};
	["MediaFields_sizes_small_Fields"]: {
		url?: ModelTypes["MediaFields_sizes_small_url"] | undefined,
	width?: ModelTypes["MediaFields_sizes_small_width"] | undefined,
	height?: ModelTypes["MediaFields_sizes_small_height"] | undefined,
	mimeType?: ModelTypes["MediaFields_sizes_small_mimeType"] | undefined,
	filesize?: ModelTypes["MediaFields_sizes_small_filesize"] | undefined,
	filename?: ModelTypes["MediaFields_sizes_small_filename"] | undefined
};
	["MediaFields_sizes_small_url"]: {
		create?: ModelTypes["MediaFields_sizes_small_url_Create"] | undefined,
	read?: ModelTypes["MediaFields_sizes_small_url_Read"] | undefined,
	update?: ModelTypes["MediaFields_sizes_small_url_Update"] | undefined,
	delete?: ModelTypes["MediaFields_sizes_small_url_Delete"] | undefined
};
	["MediaFields_sizes_small_url_Create"]: {
		permission: boolean
};
	["MediaFields_sizes_small_url_Read"]: {
		permission: boolean
};
	["MediaFields_sizes_small_url_Update"]: {
		permission: boolean
};
	["MediaFields_sizes_small_url_Delete"]: {
		permission: boolean
};
	["MediaFields_sizes_small_width"]: {
		create?: ModelTypes["MediaFields_sizes_small_width_Create"] | undefined,
	read?: ModelTypes["MediaFields_sizes_small_width_Read"] | undefined,
	update?: ModelTypes["MediaFields_sizes_small_width_Update"] | undefined,
	delete?: ModelTypes["MediaFields_sizes_small_width_Delete"] | undefined
};
	["MediaFields_sizes_small_width_Create"]: {
		permission: boolean
};
	["MediaFields_sizes_small_width_Read"]: {
		permission: boolean
};
	["MediaFields_sizes_small_width_Update"]: {
		permission: boolean
};
	["MediaFields_sizes_small_width_Delete"]: {
		permission: boolean
};
	["MediaFields_sizes_small_height"]: {
		create?: ModelTypes["MediaFields_sizes_small_height_Create"] | undefined,
	read?: ModelTypes["MediaFields_sizes_small_height_Read"] | undefined,
	update?: ModelTypes["MediaFields_sizes_small_height_Update"] | undefined,
	delete?: ModelTypes["MediaFields_sizes_small_height_Delete"] | undefined
};
	["MediaFields_sizes_small_height_Create"]: {
		permission: boolean
};
	["MediaFields_sizes_small_height_Read"]: {
		permission: boolean
};
	["MediaFields_sizes_small_height_Update"]: {
		permission: boolean
};
	["MediaFields_sizes_small_height_Delete"]: {
		permission: boolean
};
	["MediaFields_sizes_small_mimeType"]: {
		create?: ModelTypes["MediaFields_sizes_small_mimeType_Create"] | undefined,
	read?: ModelTypes["MediaFields_sizes_small_mimeType_Read"] | undefined,
	update?: ModelTypes["MediaFields_sizes_small_mimeType_Update"] | undefined,
	delete?: ModelTypes["MediaFields_sizes_small_mimeType_Delete"] | undefined
};
	["MediaFields_sizes_small_mimeType_Create"]: {
		permission: boolean
};
	["MediaFields_sizes_small_mimeType_Read"]: {
		permission: boolean
};
	["MediaFields_sizes_small_mimeType_Update"]: {
		permission: boolean
};
	["MediaFields_sizes_small_mimeType_Delete"]: {
		permission: boolean
};
	["MediaFields_sizes_small_filesize"]: {
		create?: ModelTypes["MediaFields_sizes_small_filesize_Create"] | undefined,
	read?: ModelTypes["MediaFields_sizes_small_filesize_Read"] | undefined,
	update?: ModelTypes["MediaFields_sizes_small_filesize_Update"] | undefined,
	delete?: ModelTypes["MediaFields_sizes_small_filesize_Delete"] | undefined
};
	["MediaFields_sizes_small_filesize_Create"]: {
		permission: boolean
};
	["MediaFields_sizes_small_filesize_Read"]: {
		permission: boolean
};
	["MediaFields_sizes_small_filesize_Update"]: {
		permission: boolean
};
	["MediaFields_sizes_small_filesize_Delete"]: {
		permission: boolean
};
	["MediaFields_sizes_small_filename"]: {
		create?: ModelTypes["MediaFields_sizes_small_filename_Create"] | undefined,
	read?: ModelTypes["MediaFields_sizes_small_filename_Read"] | undefined,
	update?: ModelTypes["MediaFields_sizes_small_filename_Update"] | undefined,
	delete?: ModelTypes["MediaFields_sizes_small_filename_Delete"] | undefined
};
	["MediaFields_sizes_small_filename_Create"]: {
		permission: boolean
};
	["MediaFields_sizes_small_filename_Read"]: {
		permission: boolean
};
	["MediaFields_sizes_small_filename_Update"]: {
		permission: boolean
};
	["MediaFields_sizes_small_filename_Delete"]: {
		permission: boolean
};
	["MediaFields_sizes_medium"]: {
		create?: ModelTypes["MediaFields_sizes_medium_Create"] | undefined,
	read?: ModelTypes["MediaFields_sizes_medium_Read"] | undefined,
	update?: ModelTypes["MediaFields_sizes_medium_Update"] | undefined,
	delete?: ModelTypes["MediaFields_sizes_medium_Delete"] | undefined,
	fields?: ModelTypes["MediaFields_sizes_medium_Fields"] | undefined
};
	["MediaFields_sizes_medium_Create"]: {
		permission: boolean
};
	["MediaFields_sizes_medium_Read"]: {
		permission: boolean
};
	["MediaFields_sizes_medium_Update"]: {
		permission: boolean
};
	["MediaFields_sizes_medium_Delete"]: {
		permission: boolean
};
	["MediaFields_sizes_medium_Fields"]: {
		url?: ModelTypes["MediaFields_sizes_medium_url"] | undefined,
	width?: ModelTypes["MediaFields_sizes_medium_width"] | undefined,
	height?: ModelTypes["MediaFields_sizes_medium_height"] | undefined,
	mimeType?: ModelTypes["MediaFields_sizes_medium_mimeType"] | undefined,
	filesize?: ModelTypes["MediaFields_sizes_medium_filesize"] | undefined,
	filename?: ModelTypes["MediaFields_sizes_medium_filename"] | undefined
};
	["MediaFields_sizes_medium_url"]: {
		create?: ModelTypes["MediaFields_sizes_medium_url_Create"] | undefined,
	read?: ModelTypes["MediaFields_sizes_medium_url_Read"] | undefined,
	update?: ModelTypes["MediaFields_sizes_medium_url_Update"] | undefined,
	delete?: ModelTypes["MediaFields_sizes_medium_url_Delete"] | undefined
};
	["MediaFields_sizes_medium_url_Create"]: {
		permission: boolean
};
	["MediaFields_sizes_medium_url_Read"]: {
		permission: boolean
};
	["MediaFields_sizes_medium_url_Update"]: {
		permission: boolean
};
	["MediaFields_sizes_medium_url_Delete"]: {
		permission: boolean
};
	["MediaFields_sizes_medium_width"]: {
		create?: ModelTypes["MediaFields_sizes_medium_width_Create"] | undefined,
	read?: ModelTypes["MediaFields_sizes_medium_width_Read"] | undefined,
	update?: ModelTypes["MediaFields_sizes_medium_width_Update"] | undefined,
	delete?: ModelTypes["MediaFields_sizes_medium_width_Delete"] | undefined
};
	["MediaFields_sizes_medium_width_Create"]: {
		permission: boolean
};
	["MediaFields_sizes_medium_width_Read"]: {
		permission: boolean
};
	["MediaFields_sizes_medium_width_Update"]: {
		permission: boolean
};
	["MediaFields_sizes_medium_width_Delete"]: {
		permission: boolean
};
	["MediaFields_sizes_medium_height"]: {
		create?: ModelTypes["MediaFields_sizes_medium_height_Create"] | undefined,
	read?: ModelTypes["MediaFields_sizes_medium_height_Read"] | undefined,
	update?: ModelTypes["MediaFields_sizes_medium_height_Update"] | undefined,
	delete?: ModelTypes["MediaFields_sizes_medium_height_Delete"] | undefined
};
	["MediaFields_sizes_medium_height_Create"]: {
		permission: boolean
};
	["MediaFields_sizes_medium_height_Read"]: {
		permission: boolean
};
	["MediaFields_sizes_medium_height_Update"]: {
		permission: boolean
};
	["MediaFields_sizes_medium_height_Delete"]: {
		permission: boolean
};
	["MediaFields_sizes_medium_mimeType"]: {
		create?: ModelTypes["MediaFields_sizes_medium_mimeType_Create"] | undefined,
	read?: ModelTypes["MediaFields_sizes_medium_mimeType_Read"] | undefined,
	update?: ModelTypes["MediaFields_sizes_medium_mimeType_Update"] | undefined,
	delete?: ModelTypes["MediaFields_sizes_medium_mimeType_Delete"] | undefined
};
	["MediaFields_sizes_medium_mimeType_Create"]: {
		permission: boolean
};
	["MediaFields_sizes_medium_mimeType_Read"]: {
		permission: boolean
};
	["MediaFields_sizes_medium_mimeType_Update"]: {
		permission: boolean
};
	["MediaFields_sizes_medium_mimeType_Delete"]: {
		permission: boolean
};
	["MediaFields_sizes_medium_filesize"]: {
		create?: ModelTypes["MediaFields_sizes_medium_filesize_Create"] | undefined,
	read?: ModelTypes["MediaFields_sizes_medium_filesize_Read"] | undefined,
	update?: ModelTypes["MediaFields_sizes_medium_filesize_Update"] | undefined,
	delete?: ModelTypes["MediaFields_sizes_medium_filesize_Delete"] | undefined
};
	["MediaFields_sizes_medium_filesize_Create"]: {
		permission: boolean
};
	["MediaFields_sizes_medium_filesize_Read"]: {
		permission: boolean
};
	["MediaFields_sizes_medium_filesize_Update"]: {
		permission: boolean
};
	["MediaFields_sizes_medium_filesize_Delete"]: {
		permission: boolean
};
	["MediaFields_sizes_medium_filename"]: {
		create?: ModelTypes["MediaFields_sizes_medium_filename_Create"] | undefined,
	read?: ModelTypes["MediaFields_sizes_medium_filename_Read"] | undefined,
	update?: ModelTypes["MediaFields_sizes_medium_filename_Update"] | undefined,
	delete?: ModelTypes["MediaFields_sizes_medium_filename_Delete"] | undefined
};
	["MediaFields_sizes_medium_filename_Create"]: {
		permission: boolean
};
	["MediaFields_sizes_medium_filename_Read"]: {
		permission: boolean
};
	["MediaFields_sizes_medium_filename_Update"]: {
		permission: boolean
};
	["MediaFields_sizes_medium_filename_Delete"]: {
		permission: boolean
};
	["MediaFields_sizes_large"]: {
		create?: ModelTypes["MediaFields_sizes_large_Create"] | undefined,
	read?: ModelTypes["MediaFields_sizes_large_Read"] | undefined,
	update?: ModelTypes["MediaFields_sizes_large_Update"] | undefined,
	delete?: ModelTypes["MediaFields_sizes_large_Delete"] | undefined,
	fields?: ModelTypes["MediaFields_sizes_large_Fields"] | undefined
};
	["MediaFields_sizes_large_Create"]: {
		permission: boolean
};
	["MediaFields_sizes_large_Read"]: {
		permission: boolean
};
	["MediaFields_sizes_large_Update"]: {
		permission: boolean
};
	["MediaFields_sizes_large_Delete"]: {
		permission: boolean
};
	["MediaFields_sizes_large_Fields"]: {
		url?: ModelTypes["MediaFields_sizes_large_url"] | undefined,
	width?: ModelTypes["MediaFields_sizes_large_width"] | undefined,
	height?: ModelTypes["MediaFields_sizes_large_height"] | undefined,
	mimeType?: ModelTypes["MediaFields_sizes_large_mimeType"] | undefined,
	filesize?: ModelTypes["MediaFields_sizes_large_filesize"] | undefined,
	filename?: ModelTypes["MediaFields_sizes_large_filename"] | undefined
};
	["MediaFields_sizes_large_url"]: {
		create?: ModelTypes["MediaFields_sizes_large_url_Create"] | undefined,
	read?: ModelTypes["MediaFields_sizes_large_url_Read"] | undefined,
	update?: ModelTypes["MediaFields_sizes_large_url_Update"] | undefined,
	delete?: ModelTypes["MediaFields_sizes_large_url_Delete"] | undefined
};
	["MediaFields_sizes_large_url_Create"]: {
		permission: boolean
};
	["MediaFields_sizes_large_url_Read"]: {
		permission: boolean
};
	["MediaFields_sizes_large_url_Update"]: {
		permission: boolean
};
	["MediaFields_sizes_large_url_Delete"]: {
		permission: boolean
};
	["MediaFields_sizes_large_width"]: {
		create?: ModelTypes["MediaFields_sizes_large_width_Create"] | undefined,
	read?: ModelTypes["MediaFields_sizes_large_width_Read"] | undefined,
	update?: ModelTypes["MediaFields_sizes_large_width_Update"] | undefined,
	delete?: ModelTypes["MediaFields_sizes_large_width_Delete"] | undefined
};
	["MediaFields_sizes_large_width_Create"]: {
		permission: boolean
};
	["MediaFields_sizes_large_width_Read"]: {
		permission: boolean
};
	["MediaFields_sizes_large_width_Update"]: {
		permission: boolean
};
	["MediaFields_sizes_large_width_Delete"]: {
		permission: boolean
};
	["MediaFields_sizes_large_height"]: {
		create?: ModelTypes["MediaFields_sizes_large_height_Create"] | undefined,
	read?: ModelTypes["MediaFields_sizes_large_height_Read"] | undefined,
	update?: ModelTypes["MediaFields_sizes_large_height_Update"] | undefined,
	delete?: ModelTypes["MediaFields_sizes_large_height_Delete"] | undefined
};
	["MediaFields_sizes_large_height_Create"]: {
		permission: boolean
};
	["MediaFields_sizes_large_height_Read"]: {
		permission: boolean
};
	["MediaFields_sizes_large_height_Update"]: {
		permission: boolean
};
	["MediaFields_sizes_large_height_Delete"]: {
		permission: boolean
};
	["MediaFields_sizes_large_mimeType"]: {
		create?: ModelTypes["MediaFields_sizes_large_mimeType_Create"] | undefined,
	read?: ModelTypes["MediaFields_sizes_large_mimeType_Read"] | undefined,
	update?: ModelTypes["MediaFields_sizes_large_mimeType_Update"] | undefined,
	delete?: ModelTypes["MediaFields_sizes_large_mimeType_Delete"] | undefined
};
	["MediaFields_sizes_large_mimeType_Create"]: {
		permission: boolean
};
	["MediaFields_sizes_large_mimeType_Read"]: {
		permission: boolean
};
	["MediaFields_sizes_large_mimeType_Update"]: {
		permission: boolean
};
	["MediaFields_sizes_large_mimeType_Delete"]: {
		permission: boolean
};
	["MediaFields_sizes_large_filesize"]: {
		create?: ModelTypes["MediaFields_sizes_large_filesize_Create"] | undefined,
	read?: ModelTypes["MediaFields_sizes_large_filesize_Read"] | undefined,
	update?: ModelTypes["MediaFields_sizes_large_filesize_Update"] | undefined,
	delete?: ModelTypes["MediaFields_sizes_large_filesize_Delete"] | undefined
};
	["MediaFields_sizes_large_filesize_Create"]: {
		permission: boolean
};
	["MediaFields_sizes_large_filesize_Read"]: {
		permission: boolean
};
	["MediaFields_sizes_large_filesize_Update"]: {
		permission: boolean
};
	["MediaFields_sizes_large_filesize_Delete"]: {
		permission: boolean
};
	["MediaFields_sizes_large_filename"]: {
		create?: ModelTypes["MediaFields_sizes_large_filename_Create"] | undefined,
	read?: ModelTypes["MediaFields_sizes_large_filename_Read"] | undefined,
	update?: ModelTypes["MediaFields_sizes_large_filename_Update"] | undefined,
	delete?: ModelTypes["MediaFields_sizes_large_filename_Delete"] | undefined
};
	["MediaFields_sizes_large_filename_Create"]: {
		permission: boolean
};
	["MediaFields_sizes_large_filename_Read"]: {
		permission: boolean
};
	["MediaFields_sizes_large_filename_Update"]: {
		permission: boolean
};
	["MediaFields_sizes_large_filename_Delete"]: {
		permission: boolean
};
	["MediaFields_sizes_small_jpeg"]: {
		create?: ModelTypes["MediaFields_sizes_small_jpeg_Create"] | undefined,
	read?: ModelTypes["MediaFields_sizes_small_jpeg_Read"] | undefined,
	update?: ModelTypes["MediaFields_sizes_small_jpeg_Update"] | undefined,
	delete?: ModelTypes["MediaFields_sizes_small_jpeg_Delete"] | undefined,
	fields?: ModelTypes["MediaFields_sizes_small_jpeg_Fields"] | undefined
};
	["MediaFields_sizes_small_jpeg_Create"]: {
		permission: boolean
};
	["MediaFields_sizes_small_jpeg_Read"]: {
		permission: boolean
};
	["MediaFields_sizes_small_jpeg_Update"]: {
		permission: boolean
};
	["MediaFields_sizes_small_jpeg_Delete"]: {
		permission: boolean
};
	["MediaFields_sizes_small_jpeg_Fields"]: {
		url?: ModelTypes["MediaFields_sizes_small_jpeg_url"] | undefined,
	width?: ModelTypes["MediaFields_sizes_small_jpeg_width"] | undefined,
	height?: ModelTypes["MediaFields_sizes_small_jpeg_height"] | undefined,
	mimeType?: ModelTypes["MediaFields_sizes_small_jpeg_mimeType"] | undefined,
	filesize?: ModelTypes["MediaFields_sizes_small_jpeg_filesize"] | undefined,
	filename?: ModelTypes["MediaFields_sizes_small_jpeg_filename"] | undefined
};
	["MediaFields_sizes_small_jpeg_url"]: {
		create?: ModelTypes["MediaFields_sizes_small_jpeg_url_Create"] | undefined,
	read?: ModelTypes["MediaFields_sizes_small_jpeg_url_Read"] | undefined,
	update?: ModelTypes["MediaFields_sizes_small_jpeg_url_Update"] | undefined,
	delete?: ModelTypes["MediaFields_sizes_small_jpeg_url_Delete"] | undefined
};
	["MediaFields_sizes_small_jpeg_url_Create"]: {
		permission: boolean
};
	["MediaFields_sizes_small_jpeg_url_Read"]: {
		permission: boolean
};
	["MediaFields_sizes_small_jpeg_url_Update"]: {
		permission: boolean
};
	["MediaFields_sizes_small_jpeg_url_Delete"]: {
		permission: boolean
};
	["MediaFields_sizes_small_jpeg_width"]: {
		create?: ModelTypes["MediaFields_sizes_small_jpeg_width_Create"] | undefined,
	read?: ModelTypes["MediaFields_sizes_small_jpeg_width_Read"] | undefined,
	update?: ModelTypes["MediaFields_sizes_small_jpeg_width_Update"] | undefined,
	delete?: ModelTypes["MediaFields_sizes_small_jpeg_width_Delete"] | undefined
};
	["MediaFields_sizes_small_jpeg_width_Create"]: {
		permission: boolean
};
	["MediaFields_sizes_small_jpeg_width_Read"]: {
		permission: boolean
};
	["MediaFields_sizes_small_jpeg_width_Update"]: {
		permission: boolean
};
	["MediaFields_sizes_small_jpeg_width_Delete"]: {
		permission: boolean
};
	["MediaFields_sizes_small_jpeg_height"]: {
		create?: ModelTypes["MediaFields_sizes_small_jpeg_height_Create"] | undefined,
	read?: ModelTypes["MediaFields_sizes_small_jpeg_height_Read"] | undefined,
	update?: ModelTypes["MediaFields_sizes_small_jpeg_height_Update"] | undefined,
	delete?: ModelTypes["MediaFields_sizes_small_jpeg_height_Delete"] | undefined
};
	["MediaFields_sizes_small_jpeg_height_Create"]: {
		permission: boolean
};
	["MediaFields_sizes_small_jpeg_height_Read"]: {
		permission: boolean
};
	["MediaFields_sizes_small_jpeg_height_Update"]: {
		permission: boolean
};
	["MediaFields_sizes_small_jpeg_height_Delete"]: {
		permission: boolean
};
	["MediaFields_sizes_small_jpeg_mimeType"]: {
		create?: ModelTypes["MediaFields_sizes_small_jpeg_mimeType_Create"] | undefined,
	read?: ModelTypes["MediaFields_sizes_small_jpeg_mimeType_Read"] | undefined,
	update?: ModelTypes["MediaFields_sizes_small_jpeg_mimeType_Update"] | undefined,
	delete?: ModelTypes["MediaFields_sizes_small_jpeg_mimeType_Delete"] | undefined
};
	["MediaFields_sizes_small_jpeg_mimeType_Create"]: {
		permission: boolean
};
	["MediaFields_sizes_small_jpeg_mimeType_Read"]: {
		permission: boolean
};
	["MediaFields_sizes_small_jpeg_mimeType_Update"]: {
		permission: boolean
};
	["MediaFields_sizes_small_jpeg_mimeType_Delete"]: {
		permission: boolean
};
	["MediaFields_sizes_small_jpeg_filesize"]: {
		create?: ModelTypes["MediaFields_sizes_small_jpeg_filesize_Create"] | undefined,
	read?: ModelTypes["MediaFields_sizes_small_jpeg_filesize_Read"] | undefined,
	update?: ModelTypes["MediaFields_sizes_small_jpeg_filesize_Update"] | undefined,
	delete?: ModelTypes["MediaFields_sizes_small_jpeg_filesize_Delete"] | undefined
};
	["MediaFields_sizes_small_jpeg_filesize_Create"]: {
		permission: boolean
};
	["MediaFields_sizes_small_jpeg_filesize_Read"]: {
		permission: boolean
};
	["MediaFields_sizes_small_jpeg_filesize_Update"]: {
		permission: boolean
};
	["MediaFields_sizes_small_jpeg_filesize_Delete"]: {
		permission: boolean
};
	["MediaFields_sizes_small_jpeg_filename"]: {
		create?: ModelTypes["MediaFields_sizes_small_jpeg_filename_Create"] | undefined,
	read?: ModelTypes["MediaFields_sizes_small_jpeg_filename_Read"] | undefined,
	update?: ModelTypes["MediaFields_sizes_small_jpeg_filename_Update"] | undefined,
	delete?: ModelTypes["MediaFields_sizes_small_jpeg_filename_Delete"] | undefined
};
	["MediaFields_sizes_small_jpeg_filename_Create"]: {
		permission: boolean
};
	["MediaFields_sizes_small_jpeg_filename_Read"]: {
		permission: boolean
};
	["MediaFields_sizes_small_jpeg_filename_Update"]: {
		permission: boolean
};
	["MediaFields_sizes_small_jpeg_filename_Delete"]: {
		permission: boolean
};
	["MediaFields_sizes_medium_jpeg"]: {
		create?: ModelTypes["MediaFields_sizes_medium_jpeg_Create"] | undefined,
	read?: ModelTypes["MediaFields_sizes_medium_jpeg_Read"] | undefined,
	update?: ModelTypes["MediaFields_sizes_medium_jpeg_Update"] | undefined,
	delete?: ModelTypes["MediaFields_sizes_medium_jpeg_Delete"] | undefined,
	fields?: ModelTypes["MediaFields_sizes_medium_jpeg_Fields"] | undefined
};
	["MediaFields_sizes_medium_jpeg_Create"]: {
		permission: boolean
};
	["MediaFields_sizes_medium_jpeg_Read"]: {
		permission: boolean
};
	["MediaFields_sizes_medium_jpeg_Update"]: {
		permission: boolean
};
	["MediaFields_sizes_medium_jpeg_Delete"]: {
		permission: boolean
};
	["MediaFields_sizes_medium_jpeg_Fields"]: {
		url?: ModelTypes["MediaFields_sizes_medium_jpeg_url"] | undefined,
	width?: ModelTypes["MediaFields_sizes_medium_jpeg_width"] | undefined,
	height?: ModelTypes["MediaFields_sizes_medium_jpeg_height"] | undefined,
	mimeType?: ModelTypes["MediaFields_sizes_medium_jpeg_mimeType"] | undefined,
	filesize?: ModelTypes["MediaFields_sizes_medium_jpeg_filesize"] | undefined,
	filename?: ModelTypes["MediaFields_sizes_medium_jpeg_filename"] | undefined
};
	["MediaFields_sizes_medium_jpeg_url"]: {
		create?: ModelTypes["MediaFields_sizes_medium_jpeg_url_Create"] | undefined,
	read?: ModelTypes["MediaFields_sizes_medium_jpeg_url_Read"] | undefined,
	update?: ModelTypes["MediaFields_sizes_medium_jpeg_url_Update"] | undefined,
	delete?: ModelTypes["MediaFields_sizes_medium_jpeg_url_Delete"] | undefined
};
	["MediaFields_sizes_medium_jpeg_url_Create"]: {
		permission: boolean
};
	["MediaFields_sizes_medium_jpeg_url_Read"]: {
		permission: boolean
};
	["MediaFields_sizes_medium_jpeg_url_Update"]: {
		permission: boolean
};
	["MediaFields_sizes_medium_jpeg_url_Delete"]: {
		permission: boolean
};
	["MediaFields_sizes_medium_jpeg_width"]: {
		create?: ModelTypes["MediaFields_sizes_medium_jpeg_width_Create"] | undefined,
	read?: ModelTypes["MediaFields_sizes_medium_jpeg_width_Read"] | undefined,
	update?: ModelTypes["MediaFields_sizes_medium_jpeg_width_Update"] | undefined,
	delete?: ModelTypes["MediaFields_sizes_medium_jpeg_width_Delete"] | undefined
};
	["MediaFields_sizes_medium_jpeg_width_Create"]: {
		permission: boolean
};
	["MediaFields_sizes_medium_jpeg_width_Read"]: {
		permission: boolean
};
	["MediaFields_sizes_medium_jpeg_width_Update"]: {
		permission: boolean
};
	["MediaFields_sizes_medium_jpeg_width_Delete"]: {
		permission: boolean
};
	["MediaFields_sizes_medium_jpeg_height"]: {
		create?: ModelTypes["MediaFields_sizes_medium_jpeg_height_Create"] | undefined,
	read?: ModelTypes["MediaFields_sizes_medium_jpeg_height_Read"] | undefined,
	update?: ModelTypes["MediaFields_sizes_medium_jpeg_height_Update"] | undefined,
	delete?: ModelTypes["MediaFields_sizes_medium_jpeg_height_Delete"] | undefined
};
	["MediaFields_sizes_medium_jpeg_height_Create"]: {
		permission: boolean
};
	["MediaFields_sizes_medium_jpeg_height_Read"]: {
		permission: boolean
};
	["MediaFields_sizes_medium_jpeg_height_Update"]: {
		permission: boolean
};
	["MediaFields_sizes_medium_jpeg_height_Delete"]: {
		permission: boolean
};
	["MediaFields_sizes_medium_jpeg_mimeType"]: {
		create?: ModelTypes["MediaFields_sizes_medium_jpeg_mimeType_Create"] | undefined,
	read?: ModelTypes["MediaFields_sizes_medium_jpeg_mimeType_Read"] | undefined,
	update?: ModelTypes["MediaFields_sizes_medium_jpeg_mimeType_Update"] | undefined,
	delete?: ModelTypes["MediaFields_sizes_medium_jpeg_mimeType_Delete"] | undefined
};
	["MediaFields_sizes_medium_jpeg_mimeType_Create"]: {
		permission: boolean
};
	["MediaFields_sizes_medium_jpeg_mimeType_Read"]: {
		permission: boolean
};
	["MediaFields_sizes_medium_jpeg_mimeType_Update"]: {
		permission: boolean
};
	["MediaFields_sizes_medium_jpeg_mimeType_Delete"]: {
		permission: boolean
};
	["MediaFields_sizes_medium_jpeg_filesize"]: {
		create?: ModelTypes["MediaFields_sizes_medium_jpeg_filesize_Create"] | undefined,
	read?: ModelTypes["MediaFields_sizes_medium_jpeg_filesize_Read"] | undefined,
	update?: ModelTypes["MediaFields_sizes_medium_jpeg_filesize_Update"] | undefined,
	delete?: ModelTypes["MediaFields_sizes_medium_jpeg_filesize_Delete"] | undefined
};
	["MediaFields_sizes_medium_jpeg_filesize_Create"]: {
		permission: boolean
};
	["MediaFields_sizes_medium_jpeg_filesize_Read"]: {
		permission: boolean
};
	["MediaFields_sizes_medium_jpeg_filesize_Update"]: {
		permission: boolean
};
	["MediaFields_sizes_medium_jpeg_filesize_Delete"]: {
		permission: boolean
};
	["MediaFields_sizes_medium_jpeg_filename"]: {
		create?: ModelTypes["MediaFields_sizes_medium_jpeg_filename_Create"] | undefined,
	read?: ModelTypes["MediaFields_sizes_medium_jpeg_filename_Read"] | undefined,
	update?: ModelTypes["MediaFields_sizes_medium_jpeg_filename_Update"] | undefined,
	delete?: ModelTypes["MediaFields_sizes_medium_jpeg_filename_Delete"] | undefined
};
	["MediaFields_sizes_medium_jpeg_filename_Create"]: {
		permission: boolean
};
	["MediaFields_sizes_medium_jpeg_filename_Read"]: {
		permission: boolean
};
	["MediaFields_sizes_medium_jpeg_filename_Update"]: {
		permission: boolean
};
	["MediaFields_sizes_medium_jpeg_filename_Delete"]: {
		permission: boolean
};
	["MediaFields_sizes_large_jpeg"]: {
		create?: ModelTypes["MediaFields_sizes_large_jpeg_Create"] | undefined,
	read?: ModelTypes["MediaFields_sizes_large_jpeg_Read"] | undefined,
	update?: ModelTypes["MediaFields_sizes_large_jpeg_Update"] | undefined,
	delete?: ModelTypes["MediaFields_sizes_large_jpeg_Delete"] | undefined,
	fields?: ModelTypes["MediaFields_sizes_large_jpeg_Fields"] | undefined
};
	["MediaFields_sizes_large_jpeg_Create"]: {
		permission: boolean
};
	["MediaFields_sizes_large_jpeg_Read"]: {
		permission: boolean
};
	["MediaFields_sizes_large_jpeg_Update"]: {
		permission: boolean
};
	["MediaFields_sizes_large_jpeg_Delete"]: {
		permission: boolean
};
	["MediaFields_sizes_large_jpeg_Fields"]: {
		url?: ModelTypes["MediaFields_sizes_large_jpeg_url"] | undefined,
	width?: ModelTypes["MediaFields_sizes_large_jpeg_width"] | undefined,
	height?: ModelTypes["MediaFields_sizes_large_jpeg_height"] | undefined,
	mimeType?: ModelTypes["MediaFields_sizes_large_jpeg_mimeType"] | undefined,
	filesize?: ModelTypes["MediaFields_sizes_large_jpeg_filesize"] | undefined,
	filename?: ModelTypes["MediaFields_sizes_large_jpeg_filename"] | undefined
};
	["MediaFields_sizes_large_jpeg_url"]: {
		create?: ModelTypes["MediaFields_sizes_large_jpeg_url_Create"] | undefined,
	read?: ModelTypes["MediaFields_sizes_large_jpeg_url_Read"] | undefined,
	update?: ModelTypes["MediaFields_sizes_large_jpeg_url_Update"] | undefined,
	delete?: ModelTypes["MediaFields_sizes_large_jpeg_url_Delete"] | undefined
};
	["MediaFields_sizes_large_jpeg_url_Create"]: {
		permission: boolean
};
	["MediaFields_sizes_large_jpeg_url_Read"]: {
		permission: boolean
};
	["MediaFields_sizes_large_jpeg_url_Update"]: {
		permission: boolean
};
	["MediaFields_sizes_large_jpeg_url_Delete"]: {
		permission: boolean
};
	["MediaFields_sizes_large_jpeg_width"]: {
		create?: ModelTypes["MediaFields_sizes_large_jpeg_width_Create"] | undefined,
	read?: ModelTypes["MediaFields_sizes_large_jpeg_width_Read"] | undefined,
	update?: ModelTypes["MediaFields_sizes_large_jpeg_width_Update"] | undefined,
	delete?: ModelTypes["MediaFields_sizes_large_jpeg_width_Delete"] | undefined
};
	["MediaFields_sizes_large_jpeg_width_Create"]: {
		permission: boolean
};
	["MediaFields_sizes_large_jpeg_width_Read"]: {
		permission: boolean
};
	["MediaFields_sizes_large_jpeg_width_Update"]: {
		permission: boolean
};
	["MediaFields_sizes_large_jpeg_width_Delete"]: {
		permission: boolean
};
	["MediaFields_sizes_large_jpeg_height"]: {
		create?: ModelTypes["MediaFields_sizes_large_jpeg_height_Create"] | undefined,
	read?: ModelTypes["MediaFields_sizes_large_jpeg_height_Read"] | undefined,
	update?: ModelTypes["MediaFields_sizes_large_jpeg_height_Update"] | undefined,
	delete?: ModelTypes["MediaFields_sizes_large_jpeg_height_Delete"] | undefined
};
	["MediaFields_sizes_large_jpeg_height_Create"]: {
		permission: boolean
};
	["MediaFields_sizes_large_jpeg_height_Read"]: {
		permission: boolean
};
	["MediaFields_sizes_large_jpeg_height_Update"]: {
		permission: boolean
};
	["MediaFields_sizes_large_jpeg_height_Delete"]: {
		permission: boolean
};
	["MediaFields_sizes_large_jpeg_mimeType"]: {
		create?: ModelTypes["MediaFields_sizes_large_jpeg_mimeType_Create"] | undefined,
	read?: ModelTypes["MediaFields_sizes_large_jpeg_mimeType_Read"] | undefined,
	update?: ModelTypes["MediaFields_sizes_large_jpeg_mimeType_Update"] | undefined,
	delete?: ModelTypes["MediaFields_sizes_large_jpeg_mimeType_Delete"] | undefined
};
	["MediaFields_sizes_large_jpeg_mimeType_Create"]: {
		permission: boolean
};
	["MediaFields_sizes_large_jpeg_mimeType_Read"]: {
		permission: boolean
};
	["MediaFields_sizes_large_jpeg_mimeType_Update"]: {
		permission: boolean
};
	["MediaFields_sizes_large_jpeg_mimeType_Delete"]: {
		permission: boolean
};
	["MediaFields_sizes_large_jpeg_filesize"]: {
		create?: ModelTypes["MediaFields_sizes_large_jpeg_filesize_Create"] | undefined,
	read?: ModelTypes["MediaFields_sizes_large_jpeg_filesize_Read"] | undefined,
	update?: ModelTypes["MediaFields_sizes_large_jpeg_filesize_Update"] | undefined,
	delete?: ModelTypes["MediaFields_sizes_large_jpeg_filesize_Delete"] | undefined
};
	["MediaFields_sizes_large_jpeg_filesize_Create"]: {
		permission: boolean
};
	["MediaFields_sizes_large_jpeg_filesize_Read"]: {
		permission: boolean
};
	["MediaFields_sizes_large_jpeg_filesize_Update"]: {
		permission: boolean
};
	["MediaFields_sizes_large_jpeg_filesize_Delete"]: {
		permission: boolean
};
	["MediaFields_sizes_large_jpeg_filename"]: {
		create?: ModelTypes["MediaFields_sizes_large_jpeg_filename_Create"] | undefined,
	read?: ModelTypes["MediaFields_sizes_large_jpeg_filename_Read"] | undefined,
	update?: ModelTypes["MediaFields_sizes_large_jpeg_filename_Update"] | undefined,
	delete?: ModelTypes["MediaFields_sizes_large_jpeg_filename_Delete"] | undefined
};
	["MediaFields_sizes_large_jpeg_filename_Create"]: {
		permission: boolean
};
	["MediaFields_sizes_large_jpeg_filename_Read"]: {
		permission: boolean
};
	["MediaFields_sizes_large_jpeg_filename_Update"]: {
		permission: boolean
};
	["MediaFields_sizes_large_jpeg_filename_Delete"]: {
		permission: boolean
};
	["MediaCreateAccess"]: {
		permission: boolean,
	where?: ModelTypes["JSONObject"] | undefined
};
	["MediaReadAccess"]: {
		permission: boolean,
	where?: ModelTypes["JSONObject"] | undefined
};
	["MediaUpdateAccess"]: {
		permission: boolean,
	where?: ModelTypes["JSONObject"] | undefined
};
	["MediaDeleteAccess"]: {
		permission: boolean,
	where?: ModelTypes["JSONObject"] | undefined
};
	["backupMediaAccess"]: {
		fields?: ModelTypes["BackupMediaFields"] | undefined,
	create?: ModelTypes["BackupMediaCreateAccess"] | undefined,
	read?: ModelTypes["BackupMediaReadAccess"] | undefined,
	update?: ModelTypes["BackupMediaUpdateAccess"] | undefined,
	delete?: ModelTypes["BackupMediaDeleteAccess"] | undefined
};
	["BackupMediaFields"]: {
		alt?: ModelTypes["BackupMediaFields_alt"] | undefined,
	creator?: ModelTypes["BackupMediaFields_creator"] | undefined,
	updatedAt?: ModelTypes["BackupMediaFields_updatedAt"] | undefined,
	createdAt?: ModelTypes["BackupMediaFields_createdAt"] | undefined,
	url?: ModelTypes["BackupMediaFields_url"] | undefined,
	filename?: ModelTypes["BackupMediaFields_filename"] | undefined,
	mimeType?: ModelTypes["BackupMediaFields_mimeType"] | undefined,
	filesize?: ModelTypes["BackupMediaFields_filesize"] | undefined,
	width?: ModelTypes["BackupMediaFields_width"] | undefined,
	height?: ModelTypes["BackupMediaFields_height"] | undefined,
	focalX?: ModelTypes["BackupMediaFields_focalX"] | undefined,
	focalY?: ModelTypes["BackupMediaFields_focalY"] | undefined,
	sizes?: ModelTypes["BackupMediaFields_sizes"] | undefined
};
	["BackupMediaFields_alt"]: {
		create?: ModelTypes["BackupMediaFields_alt_Create"] | undefined,
	read?: ModelTypes["BackupMediaFields_alt_Read"] | undefined,
	update?: ModelTypes["BackupMediaFields_alt_Update"] | undefined,
	delete?: ModelTypes["BackupMediaFields_alt_Delete"] | undefined
};
	["BackupMediaFields_alt_Create"]: {
		permission: boolean
};
	["BackupMediaFields_alt_Read"]: {
		permission: boolean
};
	["BackupMediaFields_alt_Update"]: {
		permission: boolean
};
	["BackupMediaFields_alt_Delete"]: {
		permission: boolean
};
	["BackupMediaFields_creator"]: {
		create?: ModelTypes["BackupMediaFields_creator_Create"] | undefined,
	read?: ModelTypes["BackupMediaFields_creator_Read"] | undefined,
	update?: ModelTypes["BackupMediaFields_creator_Update"] | undefined,
	delete?: ModelTypes["BackupMediaFields_creator_Delete"] | undefined
};
	["BackupMediaFields_creator_Create"]: {
		permission: boolean
};
	["BackupMediaFields_creator_Read"]: {
		permission: boolean
};
	["BackupMediaFields_creator_Update"]: {
		permission: boolean
};
	["BackupMediaFields_creator_Delete"]: {
		permission: boolean
};
	["BackupMediaFields_updatedAt"]: {
		create?: ModelTypes["BackupMediaFields_updatedAt_Create"] | undefined,
	read?: ModelTypes["BackupMediaFields_updatedAt_Read"] | undefined,
	update?: ModelTypes["BackupMediaFields_updatedAt_Update"] | undefined,
	delete?: ModelTypes["BackupMediaFields_updatedAt_Delete"] | undefined
};
	["BackupMediaFields_updatedAt_Create"]: {
		permission: boolean
};
	["BackupMediaFields_updatedAt_Read"]: {
		permission: boolean
};
	["BackupMediaFields_updatedAt_Update"]: {
		permission: boolean
};
	["BackupMediaFields_updatedAt_Delete"]: {
		permission: boolean
};
	["BackupMediaFields_createdAt"]: {
		create?: ModelTypes["BackupMediaFields_createdAt_Create"] | undefined,
	read?: ModelTypes["BackupMediaFields_createdAt_Read"] | undefined,
	update?: ModelTypes["BackupMediaFields_createdAt_Update"] | undefined,
	delete?: ModelTypes["BackupMediaFields_createdAt_Delete"] | undefined
};
	["BackupMediaFields_createdAt_Create"]: {
		permission: boolean
};
	["BackupMediaFields_createdAt_Read"]: {
		permission: boolean
};
	["BackupMediaFields_createdAt_Update"]: {
		permission: boolean
};
	["BackupMediaFields_createdAt_Delete"]: {
		permission: boolean
};
	["BackupMediaFields_url"]: {
		create?: ModelTypes["BackupMediaFields_url_Create"] | undefined,
	read?: ModelTypes["BackupMediaFields_url_Read"] | undefined,
	update?: ModelTypes["BackupMediaFields_url_Update"] | undefined,
	delete?: ModelTypes["BackupMediaFields_url_Delete"] | undefined
};
	["BackupMediaFields_url_Create"]: {
		permission: boolean
};
	["BackupMediaFields_url_Read"]: {
		permission: boolean
};
	["BackupMediaFields_url_Update"]: {
		permission: boolean
};
	["BackupMediaFields_url_Delete"]: {
		permission: boolean
};
	["BackupMediaFields_filename"]: {
		create?: ModelTypes["BackupMediaFields_filename_Create"] | undefined,
	read?: ModelTypes["BackupMediaFields_filename_Read"] | undefined,
	update?: ModelTypes["BackupMediaFields_filename_Update"] | undefined,
	delete?: ModelTypes["BackupMediaFields_filename_Delete"] | undefined
};
	["BackupMediaFields_filename_Create"]: {
		permission: boolean
};
	["BackupMediaFields_filename_Read"]: {
		permission: boolean
};
	["BackupMediaFields_filename_Update"]: {
		permission: boolean
};
	["BackupMediaFields_filename_Delete"]: {
		permission: boolean
};
	["BackupMediaFields_mimeType"]: {
		create?: ModelTypes["BackupMediaFields_mimeType_Create"] | undefined,
	read?: ModelTypes["BackupMediaFields_mimeType_Read"] | undefined,
	update?: ModelTypes["BackupMediaFields_mimeType_Update"] | undefined,
	delete?: ModelTypes["BackupMediaFields_mimeType_Delete"] | undefined
};
	["BackupMediaFields_mimeType_Create"]: {
		permission: boolean
};
	["BackupMediaFields_mimeType_Read"]: {
		permission: boolean
};
	["BackupMediaFields_mimeType_Update"]: {
		permission: boolean
};
	["BackupMediaFields_mimeType_Delete"]: {
		permission: boolean
};
	["BackupMediaFields_filesize"]: {
		create?: ModelTypes["BackupMediaFields_filesize_Create"] | undefined,
	read?: ModelTypes["BackupMediaFields_filesize_Read"] | undefined,
	update?: ModelTypes["BackupMediaFields_filesize_Update"] | undefined,
	delete?: ModelTypes["BackupMediaFields_filesize_Delete"] | undefined
};
	["BackupMediaFields_filesize_Create"]: {
		permission: boolean
};
	["BackupMediaFields_filesize_Read"]: {
		permission: boolean
};
	["BackupMediaFields_filesize_Update"]: {
		permission: boolean
};
	["BackupMediaFields_filesize_Delete"]: {
		permission: boolean
};
	["BackupMediaFields_width"]: {
		create?: ModelTypes["BackupMediaFields_width_Create"] | undefined,
	read?: ModelTypes["BackupMediaFields_width_Read"] | undefined,
	update?: ModelTypes["BackupMediaFields_width_Update"] | undefined,
	delete?: ModelTypes["BackupMediaFields_width_Delete"] | undefined
};
	["BackupMediaFields_width_Create"]: {
		permission: boolean
};
	["BackupMediaFields_width_Read"]: {
		permission: boolean
};
	["BackupMediaFields_width_Update"]: {
		permission: boolean
};
	["BackupMediaFields_width_Delete"]: {
		permission: boolean
};
	["BackupMediaFields_height"]: {
		create?: ModelTypes["BackupMediaFields_height_Create"] | undefined,
	read?: ModelTypes["BackupMediaFields_height_Read"] | undefined,
	update?: ModelTypes["BackupMediaFields_height_Update"] | undefined,
	delete?: ModelTypes["BackupMediaFields_height_Delete"] | undefined
};
	["BackupMediaFields_height_Create"]: {
		permission: boolean
};
	["BackupMediaFields_height_Read"]: {
		permission: boolean
};
	["BackupMediaFields_height_Update"]: {
		permission: boolean
};
	["BackupMediaFields_height_Delete"]: {
		permission: boolean
};
	["BackupMediaFields_focalX"]: {
		create?: ModelTypes["BackupMediaFields_focalX_Create"] | undefined,
	read?: ModelTypes["BackupMediaFields_focalX_Read"] | undefined,
	update?: ModelTypes["BackupMediaFields_focalX_Update"] | undefined,
	delete?: ModelTypes["BackupMediaFields_focalX_Delete"] | undefined
};
	["BackupMediaFields_focalX_Create"]: {
		permission: boolean
};
	["BackupMediaFields_focalX_Read"]: {
		permission: boolean
};
	["BackupMediaFields_focalX_Update"]: {
		permission: boolean
};
	["BackupMediaFields_focalX_Delete"]: {
		permission: boolean
};
	["BackupMediaFields_focalY"]: {
		create?: ModelTypes["BackupMediaFields_focalY_Create"] | undefined,
	read?: ModelTypes["BackupMediaFields_focalY_Read"] | undefined,
	update?: ModelTypes["BackupMediaFields_focalY_Update"] | undefined,
	delete?: ModelTypes["BackupMediaFields_focalY_Delete"] | undefined
};
	["BackupMediaFields_focalY_Create"]: {
		permission: boolean
};
	["BackupMediaFields_focalY_Read"]: {
		permission: boolean
};
	["BackupMediaFields_focalY_Update"]: {
		permission: boolean
};
	["BackupMediaFields_focalY_Delete"]: {
		permission: boolean
};
	["BackupMediaFields_sizes"]: {
		create?: ModelTypes["BackupMediaFields_sizes_Create"] | undefined,
	read?: ModelTypes["BackupMediaFields_sizes_Read"] | undefined,
	update?: ModelTypes["BackupMediaFields_sizes_Update"] | undefined,
	delete?: ModelTypes["BackupMediaFields_sizes_Delete"] | undefined,
	fields?: ModelTypes["BackupMediaFields_sizes_Fields"] | undefined
};
	["BackupMediaFields_sizes_Create"]: {
		permission: boolean
};
	["BackupMediaFields_sizes_Read"]: {
		permission: boolean
};
	["BackupMediaFields_sizes_Update"]: {
		permission: boolean
};
	["BackupMediaFields_sizes_Delete"]: {
		permission: boolean
};
	["BackupMediaFields_sizes_Fields"]: {
		thumbnail?: ModelTypes["BackupMediaFields_sizes_thumbnail"] | undefined,
	sixteenByNineMedium?: ModelTypes["BackupMediaFields_sizes_sixteenByNineMedium"] | undefined
};
	["BackupMediaFields_sizes_thumbnail"]: {
		create?: ModelTypes["BackupMediaFields_sizes_thumbnail_Create"] | undefined,
	read?: ModelTypes["BackupMediaFields_sizes_thumbnail_Read"] | undefined,
	update?: ModelTypes["BackupMediaFields_sizes_thumbnail_Update"] | undefined,
	delete?: ModelTypes["BackupMediaFields_sizes_thumbnail_Delete"] | undefined,
	fields?: ModelTypes["BackupMediaFields_sizes_thumbnail_Fields"] | undefined
};
	["BackupMediaFields_sizes_thumbnail_Create"]: {
		permission: boolean
};
	["BackupMediaFields_sizes_thumbnail_Read"]: {
		permission: boolean
};
	["BackupMediaFields_sizes_thumbnail_Update"]: {
		permission: boolean
};
	["BackupMediaFields_sizes_thumbnail_Delete"]: {
		permission: boolean
};
	["BackupMediaFields_sizes_thumbnail_Fields"]: {
		url?: ModelTypes["BackupMediaFields_sizes_thumbnail_url"] | undefined,
	width?: ModelTypes["BackupMediaFields_sizes_thumbnail_width"] | undefined,
	height?: ModelTypes["BackupMediaFields_sizes_thumbnail_height"] | undefined,
	mimeType?: ModelTypes["BackupMediaFields_sizes_thumbnail_mimeType"] | undefined,
	filesize?: ModelTypes["BackupMediaFields_sizes_thumbnail_filesize"] | undefined,
	filename?: ModelTypes["BackupMediaFields_sizes_thumbnail_filename"] | undefined
};
	["BackupMediaFields_sizes_thumbnail_url"]: {
		create?: ModelTypes["BackupMediaFields_sizes_thumbnail_url_Create"] | undefined,
	read?: ModelTypes["BackupMediaFields_sizes_thumbnail_url_Read"] | undefined,
	update?: ModelTypes["BackupMediaFields_sizes_thumbnail_url_Update"] | undefined,
	delete?: ModelTypes["BackupMediaFields_sizes_thumbnail_url_Delete"] | undefined
};
	["BackupMediaFields_sizes_thumbnail_url_Create"]: {
		permission: boolean
};
	["BackupMediaFields_sizes_thumbnail_url_Read"]: {
		permission: boolean
};
	["BackupMediaFields_sizes_thumbnail_url_Update"]: {
		permission: boolean
};
	["BackupMediaFields_sizes_thumbnail_url_Delete"]: {
		permission: boolean
};
	["BackupMediaFields_sizes_thumbnail_width"]: {
		create?: ModelTypes["BackupMediaFields_sizes_thumbnail_width_Create"] | undefined,
	read?: ModelTypes["BackupMediaFields_sizes_thumbnail_width_Read"] | undefined,
	update?: ModelTypes["BackupMediaFields_sizes_thumbnail_width_Update"] | undefined,
	delete?: ModelTypes["BackupMediaFields_sizes_thumbnail_width_Delete"] | undefined
};
	["BackupMediaFields_sizes_thumbnail_width_Create"]: {
		permission: boolean
};
	["BackupMediaFields_sizes_thumbnail_width_Read"]: {
		permission: boolean
};
	["BackupMediaFields_sizes_thumbnail_width_Update"]: {
		permission: boolean
};
	["BackupMediaFields_sizes_thumbnail_width_Delete"]: {
		permission: boolean
};
	["BackupMediaFields_sizes_thumbnail_height"]: {
		create?: ModelTypes["BackupMediaFields_sizes_thumbnail_height_Create"] | undefined,
	read?: ModelTypes["BackupMediaFields_sizes_thumbnail_height_Read"] | undefined,
	update?: ModelTypes["BackupMediaFields_sizes_thumbnail_height_Update"] | undefined,
	delete?: ModelTypes["BackupMediaFields_sizes_thumbnail_height_Delete"] | undefined
};
	["BackupMediaFields_sizes_thumbnail_height_Create"]: {
		permission: boolean
};
	["BackupMediaFields_sizes_thumbnail_height_Read"]: {
		permission: boolean
};
	["BackupMediaFields_sizes_thumbnail_height_Update"]: {
		permission: boolean
};
	["BackupMediaFields_sizes_thumbnail_height_Delete"]: {
		permission: boolean
};
	["BackupMediaFields_sizes_thumbnail_mimeType"]: {
		create?: ModelTypes["BackupMediaFields_sizes_thumbnail_mimeType_Create"] | undefined,
	read?: ModelTypes["BackupMediaFields_sizes_thumbnail_mimeType_Read"] | undefined,
	update?: ModelTypes["BackupMediaFields_sizes_thumbnail_mimeType_Update"] | undefined,
	delete?: ModelTypes["BackupMediaFields_sizes_thumbnail_mimeType_Delete"] | undefined
};
	["BackupMediaFields_sizes_thumbnail_mimeType_Create"]: {
		permission: boolean
};
	["BackupMediaFields_sizes_thumbnail_mimeType_Read"]: {
		permission: boolean
};
	["BackupMediaFields_sizes_thumbnail_mimeType_Update"]: {
		permission: boolean
};
	["BackupMediaFields_sizes_thumbnail_mimeType_Delete"]: {
		permission: boolean
};
	["BackupMediaFields_sizes_thumbnail_filesize"]: {
		create?: ModelTypes["BackupMediaFields_sizes_thumbnail_filesize_Create"] | undefined,
	read?: ModelTypes["BackupMediaFields_sizes_thumbnail_filesize_Read"] | undefined,
	update?: ModelTypes["BackupMediaFields_sizes_thumbnail_filesize_Update"] | undefined,
	delete?: ModelTypes["BackupMediaFields_sizes_thumbnail_filesize_Delete"] | undefined
};
	["BackupMediaFields_sizes_thumbnail_filesize_Create"]: {
		permission: boolean
};
	["BackupMediaFields_sizes_thumbnail_filesize_Read"]: {
		permission: boolean
};
	["BackupMediaFields_sizes_thumbnail_filesize_Update"]: {
		permission: boolean
};
	["BackupMediaFields_sizes_thumbnail_filesize_Delete"]: {
		permission: boolean
};
	["BackupMediaFields_sizes_thumbnail_filename"]: {
		create?: ModelTypes["BackupMediaFields_sizes_thumbnail_filename_Create"] | undefined,
	read?: ModelTypes["BackupMediaFields_sizes_thumbnail_filename_Read"] | undefined,
	update?: ModelTypes["BackupMediaFields_sizes_thumbnail_filename_Update"] | undefined,
	delete?: ModelTypes["BackupMediaFields_sizes_thumbnail_filename_Delete"] | undefined
};
	["BackupMediaFields_sizes_thumbnail_filename_Create"]: {
		permission: boolean
};
	["BackupMediaFields_sizes_thumbnail_filename_Read"]: {
		permission: boolean
};
	["BackupMediaFields_sizes_thumbnail_filename_Update"]: {
		permission: boolean
};
	["BackupMediaFields_sizes_thumbnail_filename_Delete"]: {
		permission: boolean
};
	["BackupMediaFields_sizes_sixteenByNineMedium"]: {
		create?: ModelTypes["BackupMediaFields_sizes_sixteenByNineMedium_Create"] | undefined,
	read?: ModelTypes["BackupMediaFields_sizes_sixteenByNineMedium_Read"] | undefined,
	update?: ModelTypes["BackupMediaFields_sizes_sixteenByNineMedium_Update"] | undefined,
	delete?: ModelTypes["BackupMediaFields_sizes_sixteenByNineMedium_Delete"] | undefined,
	fields?: ModelTypes["BackupMediaFields_sizes_sixteenByNineMedium_Fields"] | undefined
};
	["BackupMediaFields_sizes_sixteenByNineMedium_Create"]: {
		permission: boolean
};
	["BackupMediaFields_sizes_sixteenByNineMedium_Read"]: {
		permission: boolean
};
	["BackupMediaFields_sizes_sixteenByNineMedium_Update"]: {
		permission: boolean
};
	["BackupMediaFields_sizes_sixteenByNineMedium_Delete"]: {
		permission: boolean
};
	["BackupMediaFields_sizes_sixteenByNineMedium_Fields"]: {
		url?: ModelTypes["BackupMediaFields_sizes_sixteenByNineMedium_url"] | undefined,
	width?: ModelTypes["BackupMediaFields_sizes_sixteenByNineMedium_width"] | undefined,
	height?: ModelTypes["BackupMediaFields_sizes_sixteenByNineMedium_height"] | undefined,
	mimeType?: ModelTypes["BackupMediaFields_sizes_sixteenByNineMedium_mimeType"] | undefined,
	filesize?: ModelTypes["BackupMediaFields_sizes_sixteenByNineMedium_filesize"] | undefined,
	filename?: ModelTypes["BackupMediaFields_sizes_sixteenByNineMedium_filename"] | undefined
};
	["BackupMediaFields_sizes_sixteenByNineMedium_url"]: {
		create?: ModelTypes["BackupMediaFields_sizes_sixteenByNineMedium_url_Create"] | undefined,
	read?: ModelTypes["BackupMediaFields_sizes_sixteenByNineMedium_url_Read"] | undefined,
	update?: ModelTypes["BackupMediaFields_sizes_sixteenByNineMedium_url_Update"] | undefined,
	delete?: ModelTypes["BackupMediaFields_sizes_sixteenByNineMedium_url_Delete"] | undefined
};
	["BackupMediaFields_sizes_sixteenByNineMedium_url_Create"]: {
		permission: boolean
};
	["BackupMediaFields_sizes_sixteenByNineMedium_url_Read"]: {
		permission: boolean
};
	["BackupMediaFields_sizes_sixteenByNineMedium_url_Update"]: {
		permission: boolean
};
	["BackupMediaFields_sizes_sixteenByNineMedium_url_Delete"]: {
		permission: boolean
};
	["BackupMediaFields_sizes_sixteenByNineMedium_width"]: {
		create?: ModelTypes["BackupMediaFields_sizes_sixteenByNineMedium_width_Create"] | undefined,
	read?: ModelTypes["BackupMediaFields_sizes_sixteenByNineMedium_width_Read"] | undefined,
	update?: ModelTypes["BackupMediaFields_sizes_sixteenByNineMedium_width_Update"] | undefined,
	delete?: ModelTypes["BackupMediaFields_sizes_sixteenByNineMedium_width_Delete"] | undefined
};
	["BackupMediaFields_sizes_sixteenByNineMedium_width_Create"]: {
		permission: boolean
};
	["BackupMediaFields_sizes_sixteenByNineMedium_width_Read"]: {
		permission: boolean
};
	["BackupMediaFields_sizes_sixteenByNineMedium_width_Update"]: {
		permission: boolean
};
	["BackupMediaFields_sizes_sixteenByNineMedium_width_Delete"]: {
		permission: boolean
};
	["BackupMediaFields_sizes_sixteenByNineMedium_height"]: {
		create?: ModelTypes["BackupMediaFields_sizes_sixteenByNineMedium_height_Create"] | undefined,
	read?: ModelTypes["BackupMediaFields_sizes_sixteenByNineMedium_height_Read"] | undefined,
	update?: ModelTypes["BackupMediaFields_sizes_sixteenByNineMedium_height_Update"] | undefined,
	delete?: ModelTypes["BackupMediaFields_sizes_sixteenByNineMedium_height_Delete"] | undefined
};
	["BackupMediaFields_sizes_sixteenByNineMedium_height_Create"]: {
		permission: boolean
};
	["BackupMediaFields_sizes_sixteenByNineMedium_height_Read"]: {
		permission: boolean
};
	["BackupMediaFields_sizes_sixteenByNineMedium_height_Update"]: {
		permission: boolean
};
	["BackupMediaFields_sizes_sixteenByNineMedium_height_Delete"]: {
		permission: boolean
};
	["BackupMediaFields_sizes_sixteenByNineMedium_mimeType"]: {
		create?: ModelTypes["BackupMediaFields_sizes_sixteenByNineMedium_mimeType_Create"] | undefined,
	read?: ModelTypes["BackupMediaFields_sizes_sixteenByNineMedium_mimeType_Read"] | undefined,
	update?: ModelTypes["BackupMediaFields_sizes_sixteenByNineMedium_mimeType_Update"] | undefined,
	delete?: ModelTypes["BackupMediaFields_sizes_sixteenByNineMedium_mimeType_Delete"] | undefined
};
	["BackupMediaFields_sizes_sixteenByNineMedium_mimeType_Create"]: {
		permission: boolean
};
	["BackupMediaFields_sizes_sixteenByNineMedium_mimeType_Read"]: {
		permission: boolean
};
	["BackupMediaFields_sizes_sixteenByNineMedium_mimeType_Update"]: {
		permission: boolean
};
	["BackupMediaFields_sizes_sixteenByNineMedium_mimeType_Delete"]: {
		permission: boolean
};
	["BackupMediaFields_sizes_sixteenByNineMedium_filesize"]: {
		create?: ModelTypes["BackupMediaFields_sizes_sixteenByNineMedium_filesize_Create"] | undefined,
	read?: ModelTypes["BackupMediaFields_sizes_sixteenByNineMedium_filesize_Read"] | undefined,
	update?: ModelTypes["BackupMediaFields_sizes_sixteenByNineMedium_filesize_Update"] | undefined,
	delete?: ModelTypes["BackupMediaFields_sizes_sixteenByNineMedium_filesize_Delete"] | undefined
};
	["BackupMediaFields_sizes_sixteenByNineMedium_filesize_Create"]: {
		permission: boolean
};
	["BackupMediaFields_sizes_sixteenByNineMedium_filesize_Read"]: {
		permission: boolean
};
	["BackupMediaFields_sizes_sixteenByNineMedium_filesize_Update"]: {
		permission: boolean
};
	["BackupMediaFields_sizes_sixteenByNineMedium_filesize_Delete"]: {
		permission: boolean
};
	["BackupMediaFields_sizes_sixteenByNineMedium_filename"]: {
		create?: ModelTypes["BackupMediaFields_sizes_sixteenByNineMedium_filename_Create"] | undefined,
	read?: ModelTypes["BackupMediaFields_sizes_sixteenByNineMedium_filename_Read"] | undefined,
	update?: ModelTypes["BackupMediaFields_sizes_sixteenByNineMedium_filename_Update"] | undefined,
	delete?: ModelTypes["BackupMediaFields_sizes_sixteenByNineMedium_filename_Delete"] | undefined
};
	["BackupMediaFields_sizes_sixteenByNineMedium_filename_Create"]: {
		permission: boolean
};
	["BackupMediaFields_sizes_sixteenByNineMedium_filename_Read"]: {
		permission: boolean
};
	["BackupMediaFields_sizes_sixteenByNineMedium_filename_Update"]: {
		permission: boolean
};
	["BackupMediaFields_sizes_sixteenByNineMedium_filename_Delete"]: {
		permission: boolean
};
	["BackupMediaCreateAccess"]: {
		permission: boolean,
	where?: ModelTypes["JSONObject"] | undefined
};
	["BackupMediaReadAccess"]: {
		permission: boolean,
	where?: ModelTypes["JSONObject"] | undefined
};
	["BackupMediaUpdateAccess"]: {
		permission: boolean,
	where?: ModelTypes["JSONObject"] | undefined
};
	["BackupMediaDeleteAccess"]: {
		permission: boolean,
	where?: ModelTypes["JSONObject"] | undefined
};
	["account_suspend_requestsAccess"]: {
		fields?: ModelTypes["AccountSuspendRequestsFields"] | undefined,
	create?: ModelTypes["AccountSuspendRequestsCreateAccess"] | undefined,
	read?: ModelTypes["AccountSuspendRequestsReadAccess"] | undefined,
	update?: ModelTypes["AccountSuspendRequestsUpdateAccess"] | undefined,
	delete?: ModelTypes["AccountSuspendRequestsDeleteAccess"] | undefined
};
	["AccountSuspendRequestsFields"]: {
		user?: ModelTypes["AccountSuspendRequestsFields_user"] | undefined,
	handledBy?: ModelTypes["AccountSuspendRequestsFields_handledBy"] | undefined,
	updatedAt?: ModelTypes["AccountSuspendRequestsFields_updatedAt"] | undefined,
	createdAt?: ModelTypes["AccountSuspendRequestsFields_createdAt"] | undefined
};
	["AccountSuspendRequestsFields_user"]: {
		create?: ModelTypes["AccountSuspendRequestsFields_user_Create"] | undefined,
	read?: ModelTypes["AccountSuspendRequestsFields_user_Read"] | undefined,
	update?: ModelTypes["AccountSuspendRequestsFields_user_Update"] | undefined,
	delete?: ModelTypes["AccountSuspendRequestsFields_user_Delete"] | undefined
};
	["AccountSuspendRequestsFields_user_Create"]: {
		permission: boolean
};
	["AccountSuspendRequestsFields_user_Read"]: {
		permission: boolean
};
	["AccountSuspendRequestsFields_user_Update"]: {
		permission: boolean
};
	["AccountSuspendRequestsFields_user_Delete"]: {
		permission: boolean
};
	["AccountSuspendRequestsFields_handledBy"]: {
		create?: ModelTypes["AccountSuspendRequestsFields_handledBy_Create"] | undefined,
	read?: ModelTypes["AccountSuspendRequestsFields_handledBy_Read"] | undefined,
	update?: ModelTypes["AccountSuspendRequestsFields_handledBy_Update"] | undefined,
	delete?: ModelTypes["AccountSuspendRequestsFields_handledBy_Delete"] | undefined
};
	["AccountSuspendRequestsFields_handledBy_Create"]: {
		permission: boolean
};
	["AccountSuspendRequestsFields_handledBy_Read"]: {
		permission: boolean
};
	["AccountSuspendRequestsFields_handledBy_Update"]: {
		permission: boolean
};
	["AccountSuspendRequestsFields_handledBy_Delete"]: {
		permission: boolean
};
	["AccountSuspendRequestsFields_updatedAt"]: {
		create?: ModelTypes["AccountSuspendRequestsFields_updatedAt_Create"] | undefined,
	read?: ModelTypes["AccountSuspendRequestsFields_updatedAt_Read"] | undefined,
	update?: ModelTypes["AccountSuspendRequestsFields_updatedAt_Update"] | undefined,
	delete?: ModelTypes["AccountSuspendRequestsFields_updatedAt_Delete"] | undefined
};
	["AccountSuspendRequestsFields_updatedAt_Create"]: {
		permission: boolean
};
	["AccountSuspendRequestsFields_updatedAt_Read"]: {
		permission: boolean
};
	["AccountSuspendRequestsFields_updatedAt_Update"]: {
		permission: boolean
};
	["AccountSuspendRequestsFields_updatedAt_Delete"]: {
		permission: boolean
};
	["AccountSuspendRequestsFields_createdAt"]: {
		create?: ModelTypes["AccountSuspendRequestsFields_createdAt_Create"] | undefined,
	read?: ModelTypes["AccountSuspendRequestsFields_createdAt_Read"] | undefined,
	update?: ModelTypes["AccountSuspendRequestsFields_createdAt_Update"] | undefined,
	delete?: ModelTypes["AccountSuspendRequestsFields_createdAt_Delete"] | undefined
};
	["AccountSuspendRequestsFields_createdAt_Create"]: {
		permission: boolean
};
	["AccountSuspendRequestsFields_createdAt_Read"]: {
		permission: boolean
};
	["AccountSuspendRequestsFields_createdAt_Update"]: {
		permission: boolean
};
	["AccountSuspendRequestsFields_createdAt_Delete"]: {
		permission: boolean
};
	["AccountSuspendRequestsCreateAccess"]: {
		permission: boolean,
	where?: ModelTypes["JSONObject"] | undefined
};
	["AccountSuspendRequestsReadAccess"]: {
		permission: boolean,
	where?: ModelTypes["JSONObject"] | undefined
};
	["AccountSuspendRequestsUpdateAccess"]: {
		permission: boolean,
	where?: ModelTypes["JSONObject"] | undefined
};
	["AccountSuspendRequestsDeleteAccess"]: {
		permission: boolean,
	where?: ModelTypes["JSONObject"] | undefined
};
	["devicesAccess"]: {
		fields?: ModelTypes["DevicesFields"] | undefined,
	create?: ModelTypes["DevicesCreateAccess"] | undefined,
	read?: ModelTypes["DevicesReadAccess"] | undefined,
	update?: ModelTypes["DevicesUpdateAccess"] | undefined,
	delete?: ModelTypes["DevicesDeleteAccess"] | undefined
};
	["DevicesFields"]: {
		user?: ModelTypes["DevicesFields_user"] | undefined,
	udid?: ModelTypes["DevicesFields_udid"] | undefined,
	pushToken?: ModelTypes["DevicesFields_pushToken"] | undefined,
	updatedAt?: ModelTypes["DevicesFields_updatedAt"] | undefined,
	createdAt?: ModelTypes["DevicesFields_createdAt"] | undefined
};
	["DevicesFields_user"]: {
		create?: ModelTypes["DevicesFields_user_Create"] | undefined,
	read?: ModelTypes["DevicesFields_user_Read"] | undefined,
	update?: ModelTypes["DevicesFields_user_Update"] | undefined,
	delete?: ModelTypes["DevicesFields_user_Delete"] | undefined
};
	["DevicesFields_user_Create"]: {
		permission: boolean
};
	["DevicesFields_user_Read"]: {
		permission: boolean
};
	["DevicesFields_user_Update"]: {
		permission: boolean
};
	["DevicesFields_user_Delete"]: {
		permission: boolean
};
	["DevicesFields_udid"]: {
		create?: ModelTypes["DevicesFields_udid_Create"] | undefined,
	read?: ModelTypes["DevicesFields_udid_Read"] | undefined,
	update?: ModelTypes["DevicesFields_udid_Update"] | undefined,
	delete?: ModelTypes["DevicesFields_udid_Delete"] | undefined
};
	["DevicesFields_udid_Create"]: {
		permission: boolean
};
	["DevicesFields_udid_Read"]: {
		permission: boolean
};
	["DevicesFields_udid_Update"]: {
		permission: boolean
};
	["DevicesFields_udid_Delete"]: {
		permission: boolean
};
	["DevicesFields_pushToken"]: {
		create?: ModelTypes["DevicesFields_pushToken_Create"] | undefined,
	read?: ModelTypes["DevicesFields_pushToken_Read"] | undefined,
	update?: ModelTypes["DevicesFields_pushToken_Update"] | undefined,
	delete?: ModelTypes["DevicesFields_pushToken_Delete"] | undefined
};
	["DevicesFields_pushToken_Create"]: {
		permission: boolean
};
	["DevicesFields_pushToken_Read"]: {
		permission: boolean
};
	["DevicesFields_pushToken_Update"]: {
		permission: boolean
};
	["DevicesFields_pushToken_Delete"]: {
		permission: boolean
};
	["DevicesFields_updatedAt"]: {
		create?: ModelTypes["DevicesFields_updatedAt_Create"] | undefined,
	read?: ModelTypes["DevicesFields_updatedAt_Read"] | undefined,
	update?: ModelTypes["DevicesFields_updatedAt_Update"] | undefined,
	delete?: ModelTypes["DevicesFields_updatedAt_Delete"] | undefined
};
	["DevicesFields_updatedAt_Create"]: {
		permission: boolean
};
	["DevicesFields_updatedAt_Read"]: {
		permission: boolean
};
	["DevicesFields_updatedAt_Update"]: {
		permission: boolean
};
	["DevicesFields_updatedAt_Delete"]: {
		permission: boolean
};
	["DevicesFields_createdAt"]: {
		create?: ModelTypes["DevicesFields_createdAt_Create"] | undefined,
	read?: ModelTypes["DevicesFields_createdAt_Read"] | undefined,
	update?: ModelTypes["DevicesFields_createdAt_Update"] | undefined,
	delete?: ModelTypes["DevicesFields_createdAt_Delete"] | undefined
};
	["DevicesFields_createdAt_Create"]: {
		permission: boolean
};
	["DevicesFields_createdAt_Read"]: {
		permission: boolean
};
	["DevicesFields_createdAt_Update"]: {
		permission: boolean
};
	["DevicesFields_createdAt_Delete"]: {
		permission: boolean
};
	["DevicesCreateAccess"]: {
		permission: boolean,
	where?: ModelTypes["JSONObject"] | undefined
};
	["DevicesReadAccess"]: {
		permission: boolean,
	where?: ModelTypes["JSONObject"] | undefined
};
	["DevicesUpdateAccess"]: {
		permission: boolean,
	where?: ModelTypes["JSONObject"] | undefined
};
	["DevicesDeleteAccess"]: {
		permission: boolean,
	where?: ModelTypes["JSONObject"] | undefined
};
	["notificationsAccess"]: {
		fields?: ModelTypes["NotificationsFields"] | undefined,
	create?: ModelTypes["NotificationsCreateAccess"] | undefined,
	read?: ModelTypes["NotificationsReadAccess"] | undefined,
	update?: ModelTypes["NotificationsUpdateAccess"] | undefined,
	delete?: ModelTypes["NotificationsDeleteAccess"] | undefined
};
	["NotificationsFields"]: {
		receiver?: ModelTypes["NotificationsFields_receiver"] | undefined,
	type?: ModelTypes["NotificationsFields_type"] | undefined,
	meta?: ModelTypes["NotificationsFields_meta"] | undefined,
	message?: ModelTypes["NotificationsFields_message"] | undefined,
	sender?: ModelTypes["NotificationsFields_sender"] | undefined,
	readed?: ModelTypes["NotificationsFields_readed"] | undefined,
	deletedByUser?: ModelTypes["NotificationsFields_deletedByUser"] | undefined,
	updatedAt?: ModelTypes["NotificationsFields_updatedAt"] | undefined,
	createdAt?: ModelTypes["NotificationsFields_createdAt"] | undefined
};
	["NotificationsFields_receiver"]: {
		create?: ModelTypes["NotificationsFields_receiver_Create"] | undefined,
	read?: ModelTypes["NotificationsFields_receiver_Read"] | undefined,
	update?: ModelTypes["NotificationsFields_receiver_Update"] | undefined,
	delete?: ModelTypes["NotificationsFields_receiver_Delete"] | undefined
};
	["NotificationsFields_receiver_Create"]: {
		permission: boolean
};
	["NotificationsFields_receiver_Read"]: {
		permission: boolean
};
	["NotificationsFields_receiver_Update"]: {
		permission: boolean
};
	["NotificationsFields_receiver_Delete"]: {
		permission: boolean
};
	["NotificationsFields_type"]: {
		create?: ModelTypes["NotificationsFields_type_Create"] | undefined,
	read?: ModelTypes["NotificationsFields_type_Read"] | undefined,
	update?: ModelTypes["NotificationsFields_type_Update"] | undefined,
	delete?: ModelTypes["NotificationsFields_type_Delete"] | undefined
};
	["NotificationsFields_type_Create"]: {
		permission: boolean
};
	["NotificationsFields_type_Read"]: {
		permission: boolean
};
	["NotificationsFields_type_Update"]: {
		permission: boolean
};
	["NotificationsFields_type_Delete"]: {
		permission: boolean
};
	["NotificationsFields_meta"]: {
		create?: ModelTypes["NotificationsFields_meta_Create"] | undefined,
	read?: ModelTypes["NotificationsFields_meta_Read"] | undefined,
	update?: ModelTypes["NotificationsFields_meta_Update"] | undefined,
	delete?: ModelTypes["NotificationsFields_meta_Delete"] | undefined
};
	["NotificationsFields_meta_Create"]: {
		permission: boolean
};
	["NotificationsFields_meta_Read"]: {
		permission: boolean
};
	["NotificationsFields_meta_Update"]: {
		permission: boolean
};
	["NotificationsFields_meta_Delete"]: {
		permission: boolean
};
	["NotificationsFields_message"]: {
		create?: ModelTypes["NotificationsFields_message_Create"] | undefined,
	read?: ModelTypes["NotificationsFields_message_Read"] | undefined,
	update?: ModelTypes["NotificationsFields_message_Update"] | undefined,
	delete?: ModelTypes["NotificationsFields_message_Delete"] | undefined
};
	["NotificationsFields_message_Create"]: {
		permission: boolean
};
	["NotificationsFields_message_Read"]: {
		permission: boolean
};
	["NotificationsFields_message_Update"]: {
		permission: boolean
};
	["NotificationsFields_message_Delete"]: {
		permission: boolean
};
	["NotificationsFields_sender"]: {
		create?: ModelTypes["NotificationsFields_sender_Create"] | undefined,
	read?: ModelTypes["NotificationsFields_sender_Read"] | undefined,
	update?: ModelTypes["NotificationsFields_sender_Update"] | undefined,
	delete?: ModelTypes["NotificationsFields_sender_Delete"] | undefined
};
	["NotificationsFields_sender_Create"]: {
		permission: boolean
};
	["NotificationsFields_sender_Read"]: {
		permission: boolean
};
	["NotificationsFields_sender_Update"]: {
		permission: boolean
};
	["NotificationsFields_sender_Delete"]: {
		permission: boolean
};
	["NotificationsFields_readed"]: {
		create?: ModelTypes["NotificationsFields_readed_Create"] | undefined,
	read?: ModelTypes["NotificationsFields_readed_Read"] | undefined,
	update?: ModelTypes["NotificationsFields_readed_Update"] | undefined,
	delete?: ModelTypes["NotificationsFields_readed_Delete"] | undefined
};
	["NotificationsFields_readed_Create"]: {
		permission: boolean
};
	["NotificationsFields_readed_Read"]: {
		permission: boolean
};
	["NotificationsFields_readed_Update"]: {
		permission: boolean
};
	["NotificationsFields_readed_Delete"]: {
		permission: boolean
};
	["NotificationsFields_deletedByUser"]: {
		create?: ModelTypes["NotificationsFields_deletedByUser_Create"] | undefined,
	read?: ModelTypes["NotificationsFields_deletedByUser_Read"] | undefined,
	update?: ModelTypes["NotificationsFields_deletedByUser_Update"] | undefined,
	delete?: ModelTypes["NotificationsFields_deletedByUser_Delete"] | undefined
};
	["NotificationsFields_deletedByUser_Create"]: {
		permission: boolean
};
	["NotificationsFields_deletedByUser_Read"]: {
		permission: boolean
};
	["NotificationsFields_deletedByUser_Update"]: {
		permission: boolean
};
	["NotificationsFields_deletedByUser_Delete"]: {
		permission: boolean
};
	["NotificationsFields_updatedAt"]: {
		create?: ModelTypes["NotificationsFields_updatedAt_Create"] | undefined,
	read?: ModelTypes["NotificationsFields_updatedAt_Read"] | undefined,
	update?: ModelTypes["NotificationsFields_updatedAt_Update"] | undefined,
	delete?: ModelTypes["NotificationsFields_updatedAt_Delete"] | undefined
};
	["NotificationsFields_updatedAt_Create"]: {
		permission: boolean
};
	["NotificationsFields_updatedAt_Read"]: {
		permission: boolean
};
	["NotificationsFields_updatedAt_Update"]: {
		permission: boolean
};
	["NotificationsFields_updatedAt_Delete"]: {
		permission: boolean
};
	["NotificationsFields_createdAt"]: {
		create?: ModelTypes["NotificationsFields_createdAt_Create"] | undefined,
	read?: ModelTypes["NotificationsFields_createdAt_Read"] | undefined,
	update?: ModelTypes["NotificationsFields_createdAt_Update"] | undefined,
	delete?: ModelTypes["NotificationsFields_createdAt_Delete"] | undefined
};
	["NotificationsFields_createdAt_Create"]: {
		permission: boolean
};
	["NotificationsFields_createdAt_Read"]: {
		permission: boolean
};
	["NotificationsFields_createdAt_Update"]: {
		permission: boolean
};
	["NotificationsFields_createdAt_Delete"]: {
		permission: boolean
};
	["NotificationsCreateAccess"]: {
		permission: boolean,
	where?: ModelTypes["JSONObject"] | undefined
};
	["NotificationsReadAccess"]: {
		permission: boolean,
	where?: ModelTypes["JSONObject"] | undefined
};
	["NotificationsUpdateAccess"]: {
		permission: boolean,
	where?: ModelTypes["JSONObject"] | undefined
};
	["NotificationsDeleteAccess"]: {
		permission: boolean,
	where?: ModelTypes["JSONObject"] | undefined
};
	["redirectSettingAccess"]: {
		fields?: ModelTypes["RedirectSettingFields"] | undefined,
	create?: ModelTypes["RedirectSettingCreateAccess"] | undefined,
	read?: ModelTypes["RedirectSettingReadAccess"] | undefined,
	update?: ModelTypes["RedirectSettingUpdateAccess"] | undefined,
	delete?: ModelTypes["RedirectSettingDeleteAccess"] | undefined
};
	["RedirectSettingFields"]: {
		from?: ModelTypes["RedirectSettingFields_from"] | undefined,
	to?: ModelTypes["RedirectSettingFields_to"] | undefined,
	updatedAt?: ModelTypes["RedirectSettingFields_updatedAt"] | undefined,
	createdAt?: ModelTypes["RedirectSettingFields_createdAt"] | undefined
};
	["RedirectSettingFields_from"]: {
		create?: ModelTypes["RedirectSettingFields_from_Create"] | undefined,
	read?: ModelTypes["RedirectSettingFields_from_Read"] | undefined,
	update?: ModelTypes["RedirectSettingFields_from_Update"] | undefined,
	delete?: ModelTypes["RedirectSettingFields_from_Delete"] | undefined
};
	["RedirectSettingFields_from_Create"]: {
		permission: boolean
};
	["RedirectSettingFields_from_Read"]: {
		permission: boolean
};
	["RedirectSettingFields_from_Update"]: {
		permission: boolean
};
	["RedirectSettingFields_from_Delete"]: {
		permission: boolean
};
	["RedirectSettingFields_to"]: {
		create?: ModelTypes["RedirectSettingFields_to_Create"] | undefined,
	read?: ModelTypes["RedirectSettingFields_to_Read"] | undefined,
	update?: ModelTypes["RedirectSettingFields_to_Update"] | undefined,
	delete?: ModelTypes["RedirectSettingFields_to_Delete"] | undefined
};
	["RedirectSettingFields_to_Create"]: {
		permission: boolean
};
	["RedirectSettingFields_to_Read"]: {
		permission: boolean
};
	["RedirectSettingFields_to_Update"]: {
		permission: boolean
};
	["RedirectSettingFields_to_Delete"]: {
		permission: boolean
};
	["RedirectSettingFields_updatedAt"]: {
		create?: ModelTypes["RedirectSettingFields_updatedAt_Create"] | undefined,
	read?: ModelTypes["RedirectSettingFields_updatedAt_Read"] | undefined,
	update?: ModelTypes["RedirectSettingFields_updatedAt_Update"] | undefined,
	delete?: ModelTypes["RedirectSettingFields_updatedAt_Delete"] | undefined
};
	["RedirectSettingFields_updatedAt_Create"]: {
		permission: boolean
};
	["RedirectSettingFields_updatedAt_Read"]: {
		permission: boolean
};
	["RedirectSettingFields_updatedAt_Update"]: {
		permission: boolean
};
	["RedirectSettingFields_updatedAt_Delete"]: {
		permission: boolean
};
	["RedirectSettingFields_createdAt"]: {
		create?: ModelTypes["RedirectSettingFields_createdAt_Create"] | undefined,
	read?: ModelTypes["RedirectSettingFields_createdAt_Read"] | undefined,
	update?: ModelTypes["RedirectSettingFields_createdAt_Update"] | undefined,
	delete?: ModelTypes["RedirectSettingFields_createdAt_Delete"] | undefined
};
	["RedirectSettingFields_createdAt_Create"]: {
		permission: boolean
};
	["RedirectSettingFields_createdAt_Read"]: {
		permission: boolean
};
	["RedirectSettingFields_createdAt_Update"]: {
		permission: boolean
};
	["RedirectSettingFields_createdAt_Delete"]: {
		permission: boolean
};
	["RedirectSettingCreateAccess"]: {
		permission: boolean,
	where?: ModelTypes["JSONObject"] | undefined
};
	["RedirectSettingReadAccess"]: {
		permission: boolean,
	where?: ModelTypes["JSONObject"] | undefined
};
	["RedirectSettingUpdateAccess"]: {
		permission: boolean,
	where?: ModelTypes["JSONObject"] | undefined
};
	["RedirectSettingDeleteAccess"]: {
		permission: boolean,
	where?: ModelTypes["JSONObject"] | undefined
};
	["ranking_eventsAccess"]: {
		fields?: ModelTypes["RankingEventsFields"] | undefined,
	create?: ModelTypes["RankingEventsCreateAccess"] | undefined,
	read?: ModelTypes["RankingEventsReadAccess"] | undefined,
	update?: ModelTypes["RankingEventsUpdateAccess"] | undefined,
	delete?: ModelTypes["RankingEventsDeleteAccess"] | undefined
};
	["RankingEventsFields"]: {
		name?: ModelTypes["RankingEventsFields_name"] | undefined,
	visible?: ModelTypes["RankingEventsFields_visible"] | undefined,
	maxItems?: ModelTypes["RankingEventsFields_maxItems"] | undefined,
	template?: ModelTypes["RankingEventsFields_template"] | undefined,
	list?: ModelTypes["RankingEventsFields_list"] | undefined,
	region?: ModelTypes["RankingEventsFields_region"] | undefined,
	updatedAt?: ModelTypes["RankingEventsFields_updatedAt"] | undefined,
	createdAt?: ModelTypes["RankingEventsFields_createdAt"] | undefined
};
	["RankingEventsFields_name"]: {
		create?: ModelTypes["RankingEventsFields_name_Create"] | undefined,
	read?: ModelTypes["RankingEventsFields_name_Read"] | undefined,
	update?: ModelTypes["RankingEventsFields_name_Update"] | undefined,
	delete?: ModelTypes["RankingEventsFields_name_Delete"] | undefined
};
	["RankingEventsFields_name_Create"]: {
		permission: boolean
};
	["RankingEventsFields_name_Read"]: {
		permission: boolean
};
	["RankingEventsFields_name_Update"]: {
		permission: boolean
};
	["RankingEventsFields_name_Delete"]: {
		permission: boolean
};
	["RankingEventsFields_visible"]: {
		create?: ModelTypes["RankingEventsFields_visible_Create"] | undefined,
	read?: ModelTypes["RankingEventsFields_visible_Read"] | undefined,
	update?: ModelTypes["RankingEventsFields_visible_Update"] | undefined,
	delete?: ModelTypes["RankingEventsFields_visible_Delete"] | undefined
};
	["RankingEventsFields_visible_Create"]: {
		permission: boolean
};
	["RankingEventsFields_visible_Read"]: {
		permission: boolean
};
	["RankingEventsFields_visible_Update"]: {
		permission: boolean
};
	["RankingEventsFields_visible_Delete"]: {
		permission: boolean
};
	["RankingEventsFields_maxItems"]: {
		create?: ModelTypes["RankingEventsFields_maxItems_Create"] | undefined,
	read?: ModelTypes["RankingEventsFields_maxItems_Read"] | undefined,
	update?: ModelTypes["RankingEventsFields_maxItems_Update"] | undefined,
	delete?: ModelTypes["RankingEventsFields_maxItems_Delete"] | undefined
};
	["RankingEventsFields_maxItems_Create"]: {
		permission: boolean
};
	["RankingEventsFields_maxItems_Read"]: {
		permission: boolean
};
	["RankingEventsFields_maxItems_Update"]: {
		permission: boolean
};
	["RankingEventsFields_maxItems_Delete"]: {
		permission: boolean
};
	["RankingEventsFields_template"]: {
		create?: ModelTypes["RankingEventsFields_template_Create"] | undefined,
	read?: ModelTypes["RankingEventsFields_template_Read"] | undefined,
	update?: ModelTypes["RankingEventsFields_template_Update"] | undefined,
	delete?: ModelTypes["RankingEventsFields_template_Delete"] | undefined
};
	["RankingEventsFields_template_Create"]: {
		permission: boolean
};
	["RankingEventsFields_template_Read"]: {
		permission: boolean
};
	["RankingEventsFields_template_Update"]: {
		permission: boolean
};
	["RankingEventsFields_template_Delete"]: {
		permission: boolean
};
	["RankingEventsFields_list"]: {
		create?: ModelTypes["RankingEventsFields_list_Create"] | undefined,
	read?: ModelTypes["RankingEventsFields_list_Read"] | undefined,
	update?: ModelTypes["RankingEventsFields_list_Update"] | undefined,
	delete?: ModelTypes["RankingEventsFields_list_Delete"] | undefined,
	fields?: ModelTypes["RankingEventsFields_list_Fields"] | undefined
};
	["RankingEventsFields_list_Create"]: {
		permission: boolean
};
	["RankingEventsFields_list_Read"]: {
		permission: boolean
};
	["RankingEventsFields_list_Update"]: {
		permission: boolean
};
	["RankingEventsFields_list_Delete"]: {
		permission: boolean
};
	["RankingEventsFields_list_Fields"]: {
		item?: ModelTypes["RankingEventsFields_list_item"] | undefined,
	id?: ModelTypes["RankingEventsFields_list_id"] | undefined
};
	["RankingEventsFields_list_item"]: {
		create?: ModelTypes["RankingEventsFields_list_item_Create"] | undefined,
	read?: ModelTypes["RankingEventsFields_list_item_Read"] | undefined,
	update?: ModelTypes["RankingEventsFields_list_item_Update"] | undefined,
	delete?: ModelTypes["RankingEventsFields_list_item_Delete"] | undefined
};
	["RankingEventsFields_list_item_Create"]: {
		permission: boolean
};
	["RankingEventsFields_list_item_Read"]: {
		permission: boolean
};
	["RankingEventsFields_list_item_Update"]: {
		permission: boolean
};
	["RankingEventsFields_list_item_Delete"]: {
		permission: boolean
};
	["RankingEventsFields_list_id"]: {
		create?: ModelTypes["RankingEventsFields_list_id_Create"] | undefined,
	read?: ModelTypes["RankingEventsFields_list_id_Read"] | undefined,
	update?: ModelTypes["RankingEventsFields_list_id_Update"] | undefined,
	delete?: ModelTypes["RankingEventsFields_list_id_Delete"] | undefined
};
	["RankingEventsFields_list_id_Create"]: {
		permission: boolean
};
	["RankingEventsFields_list_id_Read"]: {
		permission: boolean
};
	["RankingEventsFields_list_id_Update"]: {
		permission: boolean
};
	["RankingEventsFields_list_id_Delete"]: {
		permission: boolean
};
	["RankingEventsFields_region"]: {
		create?: ModelTypes["RankingEventsFields_region_Create"] | undefined,
	read?: ModelTypes["RankingEventsFields_region_Read"] | undefined,
	update?: ModelTypes["RankingEventsFields_region_Update"] | undefined,
	delete?: ModelTypes["RankingEventsFields_region_Delete"] | undefined
};
	["RankingEventsFields_region_Create"]: {
		permission: boolean
};
	["RankingEventsFields_region_Read"]: {
		permission: boolean
};
	["RankingEventsFields_region_Update"]: {
		permission: boolean
};
	["RankingEventsFields_region_Delete"]: {
		permission: boolean
};
	["RankingEventsFields_updatedAt"]: {
		create?: ModelTypes["RankingEventsFields_updatedAt_Create"] | undefined,
	read?: ModelTypes["RankingEventsFields_updatedAt_Read"] | undefined,
	update?: ModelTypes["RankingEventsFields_updatedAt_Update"] | undefined,
	delete?: ModelTypes["RankingEventsFields_updatedAt_Delete"] | undefined
};
	["RankingEventsFields_updatedAt_Create"]: {
		permission: boolean
};
	["RankingEventsFields_updatedAt_Read"]: {
		permission: boolean
};
	["RankingEventsFields_updatedAt_Update"]: {
		permission: boolean
};
	["RankingEventsFields_updatedAt_Delete"]: {
		permission: boolean
};
	["RankingEventsFields_createdAt"]: {
		create?: ModelTypes["RankingEventsFields_createdAt_Create"] | undefined,
	read?: ModelTypes["RankingEventsFields_createdAt_Read"] | undefined,
	update?: ModelTypes["RankingEventsFields_createdAt_Update"] | undefined,
	delete?: ModelTypes["RankingEventsFields_createdAt_Delete"] | undefined
};
	["RankingEventsFields_createdAt_Create"]: {
		permission: boolean
};
	["RankingEventsFields_createdAt_Read"]: {
		permission: boolean
};
	["RankingEventsFields_createdAt_Update"]: {
		permission: boolean
};
	["RankingEventsFields_createdAt_Delete"]: {
		permission: boolean
};
	["RankingEventsCreateAccess"]: {
		permission: boolean,
	where?: ModelTypes["JSONObject"] | undefined
};
	["RankingEventsReadAccess"]: {
		permission: boolean,
	where?: ModelTypes["JSONObject"] | undefined
};
	["RankingEventsUpdateAccess"]: {
		permission: boolean,
	where?: ModelTypes["JSONObject"] | undefined
};
	["RankingEventsDeleteAccess"]: {
		permission: boolean,
	where?: ModelTypes["JSONObject"] | undefined
};
	["ranking_locationsAccess"]: {
		fields?: ModelTypes["RankingLocationsFields"] | undefined,
	create?: ModelTypes["RankingLocationsCreateAccess"] | undefined,
	read?: ModelTypes["RankingLocationsReadAccess"] | undefined,
	update?: ModelTypes["RankingLocationsUpdateAccess"] | undefined,
	delete?: ModelTypes["RankingLocationsDeleteAccess"] | undefined
};
	["RankingLocationsFields"]: {
		name?: ModelTypes["RankingLocationsFields_name"] | undefined,
	visible?: ModelTypes["RankingLocationsFields_visible"] | undefined,
	maxItems?: ModelTypes["RankingLocationsFields_maxItems"] | undefined,
	template?: ModelTypes["RankingLocationsFields_template"] | undefined,
	list?: ModelTypes["RankingLocationsFields_list"] | undefined,
	region?: ModelTypes["RankingLocationsFields_region"] | undefined,
	updatedAt?: ModelTypes["RankingLocationsFields_updatedAt"] | undefined,
	createdAt?: ModelTypes["RankingLocationsFields_createdAt"] | undefined
};
	["RankingLocationsFields_name"]: {
		create?: ModelTypes["RankingLocationsFields_name_Create"] | undefined,
	read?: ModelTypes["RankingLocationsFields_name_Read"] | undefined,
	update?: ModelTypes["RankingLocationsFields_name_Update"] | undefined,
	delete?: ModelTypes["RankingLocationsFields_name_Delete"] | undefined
};
	["RankingLocationsFields_name_Create"]: {
		permission: boolean
};
	["RankingLocationsFields_name_Read"]: {
		permission: boolean
};
	["RankingLocationsFields_name_Update"]: {
		permission: boolean
};
	["RankingLocationsFields_name_Delete"]: {
		permission: boolean
};
	["RankingLocationsFields_visible"]: {
		create?: ModelTypes["RankingLocationsFields_visible_Create"] | undefined,
	read?: ModelTypes["RankingLocationsFields_visible_Read"] | undefined,
	update?: ModelTypes["RankingLocationsFields_visible_Update"] | undefined,
	delete?: ModelTypes["RankingLocationsFields_visible_Delete"] | undefined
};
	["RankingLocationsFields_visible_Create"]: {
		permission: boolean
};
	["RankingLocationsFields_visible_Read"]: {
		permission: boolean
};
	["RankingLocationsFields_visible_Update"]: {
		permission: boolean
};
	["RankingLocationsFields_visible_Delete"]: {
		permission: boolean
};
	["RankingLocationsFields_maxItems"]: {
		create?: ModelTypes["RankingLocationsFields_maxItems_Create"] | undefined,
	read?: ModelTypes["RankingLocationsFields_maxItems_Read"] | undefined,
	update?: ModelTypes["RankingLocationsFields_maxItems_Update"] | undefined,
	delete?: ModelTypes["RankingLocationsFields_maxItems_Delete"] | undefined
};
	["RankingLocationsFields_maxItems_Create"]: {
		permission: boolean
};
	["RankingLocationsFields_maxItems_Read"]: {
		permission: boolean
};
	["RankingLocationsFields_maxItems_Update"]: {
		permission: boolean
};
	["RankingLocationsFields_maxItems_Delete"]: {
		permission: boolean
};
	["RankingLocationsFields_template"]: {
		create?: ModelTypes["RankingLocationsFields_template_Create"] | undefined,
	read?: ModelTypes["RankingLocationsFields_template_Read"] | undefined,
	update?: ModelTypes["RankingLocationsFields_template_Update"] | undefined,
	delete?: ModelTypes["RankingLocationsFields_template_Delete"] | undefined
};
	["RankingLocationsFields_template_Create"]: {
		permission: boolean
};
	["RankingLocationsFields_template_Read"]: {
		permission: boolean
};
	["RankingLocationsFields_template_Update"]: {
		permission: boolean
};
	["RankingLocationsFields_template_Delete"]: {
		permission: boolean
};
	["RankingLocationsFields_list"]: {
		create?: ModelTypes["RankingLocationsFields_list_Create"] | undefined,
	read?: ModelTypes["RankingLocationsFields_list_Read"] | undefined,
	update?: ModelTypes["RankingLocationsFields_list_Update"] | undefined,
	delete?: ModelTypes["RankingLocationsFields_list_Delete"] | undefined,
	fields?: ModelTypes["RankingLocationsFields_list_Fields"] | undefined
};
	["RankingLocationsFields_list_Create"]: {
		permission: boolean
};
	["RankingLocationsFields_list_Read"]: {
		permission: boolean
};
	["RankingLocationsFields_list_Update"]: {
		permission: boolean
};
	["RankingLocationsFields_list_Delete"]: {
		permission: boolean
};
	["RankingLocationsFields_list_Fields"]: {
		item?: ModelTypes["RankingLocationsFields_list_item"] | undefined,
	id?: ModelTypes["RankingLocationsFields_list_id"] | undefined
};
	["RankingLocationsFields_list_item"]: {
		create?: ModelTypes["RankingLocationsFields_list_item_Create"] | undefined,
	read?: ModelTypes["RankingLocationsFields_list_item_Read"] | undefined,
	update?: ModelTypes["RankingLocationsFields_list_item_Update"] | undefined,
	delete?: ModelTypes["RankingLocationsFields_list_item_Delete"] | undefined
};
	["RankingLocationsFields_list_item_Create"]: {
		permission: boolean
};
	["RankingLocationsFields_list_item_Read"]: {
		permission: boolean
};
	["RankingLocationsFields_list_item_Update"]: {
		permission: boolean
};
	["RankingLocationsFields_list_item_Delete"]: {
		permission: boolean
};
	["RankingLocationsFields_list_id"]: {
		create?: ModelTypes["RankingLocationsFields_list_id_Create"] | undefined,
	read?: ModelTypes["RankingLocationsFields_list_id_Read"] | undefined,
	update?: ModelTypes["RankingLocationsFields_list_id_Update"] | undefined,
	delete?: ModelTypes["RankingLocationsFields_list_id_Delete"] | undefined
};
	["RankingLocationsFields_list_id_Create"]: {
		permission: boolean
};
	["RankingLocationsFields_list_id_Read"]: {
		permission: boolean
};
	["RankingLocationsFields_list_id_Update"]: {
		permission: boolean
};
	["RankingLocationsFields_list_id_Delete"]: {
		permission: boolean
};
	["RankingLocationsFields_region"]: {
		create?: ModelTypes["RankingLocationsFields_region_Create"] | undefined,
	read?: ModelTypes["RankingLocationsFields_region_Read"] | undefined,
	update?: ModelTypes["RankingLocationsFields_region_Update"] | undefined,
	delete?: ModelTypes["RankingLocationsFields_region_Delete"] | undefined
};
	["RankingLocationsFields_region_Create"]: {
		permission: boolean
};
	["RankingLocationsFields_region_Read"]: {
		permission: boolean
};
	["RankingLocationsFields_region_Update"]: {
		permission: boolean
};
	["RankingLocationsFields_region_Delete"]: {
		permission: boolean
};
	["RankingLocationsFields_updatedAt"]: {
		create?: ModelTypes["RankingLocationsFields_updatedAt_Create"] | undefined,
	read?: ModelTypes["RankingLocationsFields_updatedAt_Read"] | undefined,
	update?: ModelTypes["RankingLocationsFields_updatedAt_Update"] | undefined,
	delete?: ModelTypes["RankingLocationsFields_updatedAt_Delete"] | undefined
};
	["RankingLocationsFields_updatedAt_Create"]: {
		permission: boolean
};
	["RankingLocationsFields_updatedAt_Read"]: {
		permission: boolean
};
	["RankingLocationsFields_updatedAt_Update"]: {
		permission: boolean
};
	["RankingLocationsFields_updatedAt_Delete"]: {
		permission: boolean
};
	["RankingLocationsFields_createdAt"]: {
		create?: ModelTypes["RankingLocationsFields_createdAt_Create"] | undefined,
	read?: ModelTypes["RankingLocationsFields_createdAt_Read"] | undefined,
	update?: ModelTypes["RankingLocationsFields_createdAt_Update"] | undefined,
	delete?: ModelTypes["RankingLocationsFields_createdAt_Delete"] | undefined
};
	["RankingLocationsFields_createdAt_Create"]: {
		permission: boolean
};
	["RankingLocationsFields_createdAt_Read"]: {
		permission: boolean
};
	["RankingLocationsFields_createdAt_Update"]: {
		permission: boolean
};
	["RankingLocationsFields_createdAt_Delete"]: {
		permission: boolean
};
	["RankingLocationsCreateAccess"]: {
		permission: boolean,
	where?: ModelTypes["JSONObject"] | undefined
};
	["RankingLocationsReadAccess"]: {
		permission: boolean,
	where?: ModelTypes["JSONObject"] | undefined
};
	["RankingLocationsUpdateAccess"]: {
		permission: boolean,
	where?: ModelTypes["JSONObject"] | undefined
};
	["RankingLocationsDeleteAccess"]: {
		permission: boolean,
	where?: ModelTypes["JSONObject"] | undefined
};
	["event_calendarAccess"]: {
		fields?: ModelTypes["EventCalendarFields"] | undefined,
	create?: ModelTypes["EventCalendarCreateAccess"] | undefined,
	read?: ModelTypes["EventCalendarReadAccess"] | undefined,
	update?: ModelTypes["EventCalendarUpdateAccess"] | undefined,
	delete?: ModelTypes["EventCalendarDeleteAccess"] | undefined
};
	["EventCalendarFields"]: {
		date?: ModelTypes["EventCalendarFields_date"] | undefined,
	item?: ModelTypes["EventCalendarFields_item"] | undefined,
	title?: ModelTypes["EventCalendarFields_title"] | undefined,
	region?: ModelTypes["EventCalendarFields_region"] | undefined,
	updatedAt?: ModelTypes["EventCalendarFields_updatedAt"] | undefined,
	createdAt?: ModelTypes["EventCalendarFields_createdAt"] | undefined
};
	["EventCalendarFields_date"]: {
		create?: ModelTypes["EventCalendarFields_date_Create"] | undefined,
	read?: ModelTypes["EventCalendarFields_date_Read"] | undefined,
	update?: ModelTypes["EventCalendarFields_date_Update"] | undefined,
	delete?: ModelTypes["EventCalendarFields_date_Delete"] | undefined
};
	["EventCalendarFields_date_Create"]: {
		permission: boolean
};
	["EventCalendarFields_date_Read"]: {
		permission: boolean
};
	["EventCalendarFields_date_Update"]: {
		permission: boolean
};
	["EventCalendarFields_date_Delete"]: {
		permission: boolean
};
	["EventCalendarFields_item"]: {
		create?: ModelTypes["EventCalendarFields_item_Create"] | undefined,
	read?: ModelTypes["EventCalendarFields_item_Read"] | undefined,
	update?: ModelTypes["EventCalendarFields_item_Update"] | undefined,
	delete?: ModelTypes["EventCalendarFields_item_Delete"] | undefined
};
	["EventCalendarFields_item_Create"]: {
		permission: boolean
};
	["EventCalendarFields_item_Read"]: {
		permission: boolean
};
	["EventCalendarFields_item_Update"]: {
		permission: boolean
};
	["EventCalendarFields_item_Delete"]: {
		permission: boolean
};
	["EventCalendarFields_title"]: {
		create?: ModelTypes["EventCalendarFields_title_Create"] | undefined,
	read?: ModelTypes["EventCalendarFields_title_Read"] | undefined,
	update?: ModelTypes["EventCalendarFields_title_Update"] | undefined,
	delete?: ModelTypes["EventCalendarFields_title_Delete"] | undefined
};
	["EventCalendarFields_title_Create"]: {
		permission: boolean
};
	["EventCalendarFields_title_Read"]: {
		permission: boolean
};
	["EventCalendarFields_title_Update"]: {
		permission: boolean
};
	["EventCalendarFields_title_Delete"]: {
		permission: boolean
};
	["EventCalendarFields_region"]: {
		create?: ModelTypes["EventCalendarFields_region_Create"] | undefined,
	read?: ModelTypes["EventCalendarFields_region_Read"] | undefined,
	update?: ModelTypes["EventCalendarFields_region_Update"] | undefined,
	delete?: ModelTypes["EventCalendarFields_region_Delete"] | undefined
};
	["EventCalendarFields_region_Create"]: {
		permission: boolean
};
	["EventCalendarFields_region_Read"]: {
		permission: boolean
};
	["EventCalendarFields_region_Update"]: {
		permission: boolean
};
	["EventCalendarFields_region_Delete"]: {
		permission: boolean
};
	["EventCalendarFields_updatedAt"]: {
		create?: ModelTypes["EventCalendarFields_updatedAt_Create"] | undefined,
	read?: ModelTypes["EventCalendarFields_updatedAt_Read"] | undefined,
	update?: ModelTypes["EventCalendarFields_updatedAt_Update"] | undefined,
	delete?: ModelTypes["EventCalendarFields_updatedAt_Delete"] | undefined
};
	["EventCalendarFields_updatedAt_Create"]: {
		permission: boolean
};
	["EventCalendarFields_updatedAt_Read"]: {
		permission: boolean
};
	["EventCalendarFields_updatedAt_Update"]: {
		permission: boolean
};
	["EventCalendarFields_updatedAt_Delete"]: {
		permission: boolean
};
	["EventCalendarFields_createdAt"]: {
		create?: ModelTypes["EventCalendarFields_createdAt_Create"] | undefined,
	read?: ModelTypes["EventCalendarFields_createdAt_Read"] | undefined,
	update?: ModelTypes["EventCalendarFields_createdAt_Update"] | undefined,
	delete?: ModelTypes["EventCalendarFields_createdAt_Delete"] | undefined
};
	["EventCalendarFields_createdAt_Create"]: {
		permission: boolean
};
	["EventCalendarFields_createdAt_Read"]: {
		permission: boolean
};
	["EventCalendarFields_createdAt_Update"]: {
		permission: boolean
};
	["EventCalendarFields_createdAt_Delete"]: {
		permission: boolean
};
	["EventCalendarCreateAccess"]: {
		permission: boolean,
	where?: ModelTypes["JSONObject"] | undefined
};
	["EventCalendarReadAccess"]: {
		permission: boolean,
	where?: ModelTypes["JSONObject"] | undefined
};
	["EventCalendarUpdateAccess"]: {
		permission: boolean,
	where?: ModelTypes["JSONObject"] | undefined
};
	["EventCalendarDeleteAccess"]: {
		permission: boolean,
	where?: ModelTypes["JSONObject"] | undefined
};
	["editor_choicesAccess"]: {
		fields?: ModelTypes["EditorChoicesFields"] | undefined,
	create?: ModelTypes["EditorChoicesCreateAccess"] | undefined,
	read?: ModelTypes["EditorChoicesReadAccess"] | undefined,
	update?: ModelTypes["EditorChoicesUpdateAccess"] | undefined,
	delete?: ModelTypes["EditorChoicesDeleteAccess"] | undefined
};
	["EditorChoicesFields"]: {
		name?: ModelTypes["EditorChoicesFields_name"] | undefined,
	visible?: ModelTypes["EditorChoicesFields_visible"] | undefined,
	editorialPicks?: ModelTypes["EditorChoicesFields_editorialPicks"] | undefined,
	list?: ModelTypes["EditorChoicesFields_list"] | undefined,
	region?: ModelTypes["EditorChoicesFields_region"] | undefined,
	updatedAt?: ModelTypes["EditorChoicesFields_updatedAt"] | undefined,
	createdAt?: ModelTypes["EditorChoicesFields_createdAt"] | undefined
};
	["EditorChoicesFields_name"]: {
		create?: ModelTypes["EditorChoicesFields_name_Create"] | undefined,
	read?: ModelTypes["EditorChoicesFields_name_Read"] | undefined,
	update?: ModelTypes["EditorChoicesFields_name_Update"] | undefined,
	delete?: ModelTypes["EditorChoicesFields_name_Delete"] | undefined
};
	["EditorChoicesFields_name_Create"]: {
		permission: boolean
};
	["EditorChoicesFields_name_Read"]: {
		permission: boolean
};
	["EditorChoicesFields_name_Update"]: {
		permission: boolean
};
	["EditorChoicesFields_name_Delete"]: {
		permission: boolean
};
	["EditorChoicesFields_visible"]: {
		create?: ModelTypes["EditorChoicesFields_visible_Create"] | undefined,
	read?: ModelTypes["EditorChoicesFields_visible_Read"] | undefined,
	update?: ModelTypes["EditorChoicesFields_visible_Update"] | undefined,
	delete?: ModelTypes["EditorChoicesFields_visible_Delete"] | undefined
};
	["EditorChoicesFields_visible_Create"]: {
		permission: boolean
};
	["EditorChoicesFields_visible_Read"]: {
		permission: boolean
};
	["EditorChoicesFields_visible_Update"]: {
		permission: boolean
};
	["EditorChoicesFields_visible_Delete"]: {
		permission: boolean
};
	["EditorChoicesFields_editorialPicks"]: {
		create?: ModelTypes["EditorChoicesFields_editorialPicks_Create"] | undefined,
	read?: ModelTypes["EditorChoicesFields_editorialPicks_Read"] | undefined,
	update?: ModelTypes["EditorChoicesFields_editorialPicks_Update"] | undefined,
	delete?: ModelTypes["EditorChoicesFields_editorialPicks_Delete"] | undefined
};
	["EditorChoicesFields_editorialPicks_Create"]: {
		permission: boolean
};
	["EditorChoicesFields_editorialPicks_Read"]: {
		permission: boolean
};
	["EditorChoicesFields_editorialPicks_Update"]: {
		permission: boolean
};
	["EditorChoicesFields_editorialPicks_Delete"]: {
		permission: boolean
};
	["EditorChoicesFields_list"]: {
		create?: ModelTypes["EditorChoicesFields_list_Create"] | undefined,
	read?: ModelTypes["EditorChoicesFields_list_Read"] | undefined,
	update?: ModelTypes["EditorChoicesFields_list_Update"] | undefined,
	delete?: ModelTypes["EditorChoicesFields_list_Delete"] | undefined,
	fields?: ModelTypes["EditorChoicesFields_list_Fields"] | undefined
};
	["EditorChoicesFields_list_Create"]: {
		permission: boolean
};
	["EditorChoicesFields_list_Read"]: {
		permission: boolean
};
	["EditorChoicesFields_list_Update"]: {
		permission: boolean
};
	["EditorChoicesFields_list_Delete"]: {
		permission: boolean
};
	["EditorChoicesFields_list_Fields"]: {
		item?: ModelTypes["EditorChoicesFields_list_item"] | undefined,
	id?: ModelTypes["EditorChoicesFields_list_id"] | undefined
};
	["EditorChoicesFields_list_item"]: {
		create?: ModelTypes["EditorChoicesFields_list_item_Create"] | undefined,
	read?: ModelTypes["EditorChoicesFields_list_item_Read"] | undefined,
	update?: ModelTypes["EditorChoicesFields_list_item_Update"] | undefined,
	delete?: ModelTypes["EditorChoicesFields_list_item_Delete"] | undefined
};
	["EditorChoicesFields_list_item_Create"]: {
		permission: boolean
};
	["EditorChoicesFields_list_item_Read"]: {
		permission: boolean
};
	["EditorChoicesFields_list_item_Update"]: {
		permission: boolean
};
	["EditorChoicesFields_list_item_Delete"]: {
		permission: boolean
};
	["EditorChoicesFields_list_id"]: {
		create?: ModelTypes["EditorChoicesFields_list_id_Create"] | undefined,
	read?: ModelTypes["EditorChoicesFields_list_id_Read"] | undefined,
	update?: ModelTypes["EditorChoicesFields_list_id_Update"] | undefined,
	delete?: ModelTypes["EditorChoicesFields_list_id_Delete"] | undefined
};
	["EditorChoicesFields_list_id_Create"]: {
		permission: boolean
};
	["EditorChoicesFields_list_id_Read"]: {
		permission: boolean
};
	["EditorChoicesFields_list_id_Update"]: {
		permission: boolean
};
	["EditorChoicesFields_list_id_Delete"]: {
		permission: boolean
};
	["EditorChoicesFields_region"]: {
		create?: ModelTypes["EditorChoicesFields_region_Create"] | undefined,
	read?: ModelTypes["EditorChoicesFields_region_Read"] | undefined,
	update?: ModelTypes["EditorChoicesFields_region_Update"] | undefined,
	delete?: ModelTypes["EditorChoicesFields_region_Delete"] | undefined
};
	["EditorChoicesFields_region_Create"]: {
		permission: boolean
};
	["EditorChoicesFields_region_Read"]: {
		permission: boolean
};
	["EditorChoicesFields_region_Update"]: {
		permission: boolean
};
	["EditorChoicesFields_region_Delete"]: {
		permission: boolean
};
	["EditorChoicesFields_updatedAt"]: {
		create?: ModelTypes["EditorChoicesFields_updatedAt_Create"] | undefined,
	read?: ModelTypes["EditorChoicesFields_updatedAt_Read"] | undefined,
	update?: ModelTypes["EditorChoicesFields_updatedAt_Update"] | undefined,
	delete?: ModelTypes["EditorChoicesFields_updatedAt_Delete"] | undefined
};
	["EditorChoicesFields_updatedAt_Create"]: {
		permission: boolean
};
	["EditorChoicesFields_updatedAt_Read"]: {
		permission: boolean
};
	["EditorChoicesFields_updatedAt_Update"]: {
		permission: boolean
};
	["EditorChoicesFields_updatedAt_Delete"]: {
		permission: boolean
};
	["EditorChoicesFields_createdAt"]: {
		create?: ModelTypes["EditorChoicesFields_createdAt_Create"] | undefined,
	read?: ModelTypes["EditorChoicesFields_createdAt_Read"] | undefined,
	update?: ModelTypes["EditorChoicesFields_createdAt_Update"] | undefined,
	delete?: ModelTypes["EditorChoicesFields_createdAt_Delete"] | undefined
};
	["EditorChoicesFields_createdAt_Create"]: {
		permission: boolean
};
	["EditorChoicesFields_createdAt_Read"]: {
		permission: boolean
};
	["EditorChoicesFields_createdAt_Update"]: {
		permission: boolean
};
	["EditorChoicesFields_createdAt_Delete"]: {
		permission: boolean
};
	["EditorChoicesCreateAccess"]: {
		permission: boolean,
	where?: ModelTypes["JSONObject"] | undefined
};
	["EditorChoicesReadAccess"]: {
		permission: boolean,
	where?: ModelTypes["JSONObject"] | undefined
};
	["EditorChoicesUpdateAccess"]: {
		permission: boolean,
	where?: ModelTypes["JSONObject"] | undefined
};
	["EditorChoicesDeleteAccess"]: {
		permission: boolean,
	where?: ModelTypes["JSONObject"] | undefined
};
	["criteria_settingAccess"]: {
		fields?: ModelTypes["CriteriaSettingFields"] | undefined,
	create?: ModelTypes["CriteriaSettingCreateAccess"] | undefined,
	read?: ModelTypes["CriteriaSettingReadAccess"] | undefined,
	update?: ModelTypes["CriteriaSettingUpdateAccess"] | undefined,
	delete?: ModelTypes["CriteriaSettingDeleteAccess"] | undefined
};
	["CriteriaSettingFields"]: {
		criteria?: ModelTypes["CriteriaSettingFields_criteria"] | undefined,
	updatedAt?: ModelTypes["CriteriaSettingFields_updatedAt"] | undefined,
	createdAt?: ModelTypes["CriteriaSettingFields_createdAt"] | undefined
};
	["CriteriaSettingFields_criteria"]: {
		create?: ModelTypes["CriteriaSettingFields_criteria_Create"] | undefined,
	read?: ModelTypes["CriteriaSettingFields_criteria_Read"] | undefined,
	update?: ModelTypes["CriteriaSettingFields_criteria_Update"] | undefined,
	delete?: ModelTypes["CriteriaSettingFields_criteria_Delete"] | undefined,
	fields?: ModelTypes["CriteriaSettingFields_criteria_Fields"] | undefined
};
	["CriteriaSettingFields_criteria_Create"]: {
		permission: boolean
};
	["CriteriaSettingFields_criteria_Read"]: {
		permission: boolean
};
	["CriteriaSettingFields_criteria_Update"]: {
		permission: boolean
};
	["CriteriaSettingFields_criteria_Delete"]: {
		permission: boolean
};
	["CriteriaSettingFields_criteria_Fields"]: {
		date?: ModelTypes["CriteriaSettingFields_criteria_date"] | undefined,
	audience?: ModelTypes["CriteriaSettingFields_criteria_audience"] | undefined,
	category?: ModelTypes["CriteriaSettingFields_criteria_category"] | undefined,
	tag?: ModelTypes["CriteriaSettingFields_criteria_tag"] | undefined,
	region?: ModelTypes["CriteriaSettingFields_criteria_region"] | undefined
};
	["CriteriaSettingFields_criteria_date"]: {
		create?: ModelTypes["CriteriaSettingFields_criteria_date_Create"] | undefined,
	read?: ModelTypes["CriteriaSettingFields_criteria_date_Read"] | undefined,
	update?: ModelTypes["CriteriaSettingFields_criteria_date_Update"] | undefined,
	delete?: ModelTypes["CriteriaSettingFields_criteria_date_Delete"] | undefined
};
	["CriteriaSettingFields_criteria_date_Create"]: {
		permission: boolean
};
	["CriteriaSettingFields_criteria_date_Read"]: {
		permission: boolean
};
	["CriteriaSettingFields_criteria_date_Update"]: {
		permission: boolean
};
	["CriteriaSettingFields_criteria_date_Delete"]: {
		permission: boolean
};
	["CriteriaSettingFields_criteria_audience"]: {
		create?: ModelTypes["CriteriaSettingFields_criteria_audience_Create"] | undefined,
	read?: ModelTypes["CriteriaSettingFields_criteria_audience_Read"] | undefined,
	update?: ModelTypes["CriteriaSettingFields_criteria_audience_Update"] | undefined,
	delete?: ModelTypes["CriteriaSettingFields_criteria_audience_Delete"] | undefined
};
	["CriteriaSettingFields_criteria_audience_Create"]: {
		permission: boolean
};
	["CriteriaSettingFields_criteria_audience_Read"]: {
		permission: boolean
};
	["CriteriaSettingFields_criteria_audience_Update"]: {
		permission: boolean
};
	["CriteriaSettingFields_criteria_audience_Delete"]: {
		permission: boolean
};
	["CriteriaSettingFields_criteria_category"]: {
		create?: ModelTypes["CriteriaSettingFields_criteria_category_Create"] | undefined,
	read?: ModelTypes["CriteriaSettingFields_criteria_category_Read"] | undefined,
	update?: ModelTypes["CriteriaSettingFields_criteria_category_Update"] | undefined,
	delete?: ModelTypes["CriteriaSettingFields_criteria_category_Delete"] | undefined
};
	["CriteriaSettingFields_criteria_category_Create"]: {
		permission: boolean
};
	["CriteriaSettingFields_criteria_category_Read"]: {
		permission: boolean
};
	["CriteriaSettingFields_criteria_category_Update"]: {
		permission: boolean
};
	["CriteriaSettingFields_criteria_category_Delete"]: {
		permission: boolean
};
	["CriteriaSettingFields_criteria_tag"]: {
		create?: ModelTypes["CriteriaSettingFields_criteria_tag_Create"] | undefined,
	read?: ModelTypes["CriteriaSettingFields_criteria_tag_Read"] | undefined,
	update?: ModelTypes["CriteriaSettingFields_criteria_tag_Update"] | undefined,
	delete?: ModelTypes["CriteriaSettingFields_criteria_tag_Delete"] | undefined
};
	["CriteriaSettingFields_criteria_tag_Create"]: {
		permission: boolean
};
	["CriteriaSettingFields_criteria_tag_Read"]: {
		permission: boolean
};
	["CriteriaSettingFields_criteria_tag_Update"]: {
		permission: boolean
};
	["CriteriaSettingFields_criteria_tag_Delete"]: {
		permission: boolean
};
	["CriteriaSettingFields_criteria_region"]: {
		create?: ModelTypes["CriteriaSettingFields_criteria_region_Create"] | undefined,
	read?: ModelTypes["CriteriaSettingFields_criteria_region_Read"] | undefined,
	update?: ModelTypes["CriteriaSettingFields_criteria_region_Update"] | undefined,
	delete?: ModelTypes["CriteriaSettingFields_criteria_region_Delete"] | undefined
};
	["CriteriaSettingFields_criteria_region_Create"]: {
		permission: boolean
};
	["CriteriaSettingFields_criteria_region_Read"]: {
		permission: boolean
};
	["CriteriaSettingFields_criteria_region_Update"]: {
		permission: boolean
};
	["CriteriaSettingFields_criteria_region_Delete"]: {
		permission: boolean
};
	["CriteriaSettingFields_updatedAt"]: {
		create?: ModelTypes["CriteriaSettingFields_updatedAt_Create"] | undefined,
	read?: ModelTypes["CriteriaSettingFields_updatedAt_Read"] | undefined,
	update?: ModelTypes["CriteriaSettingFields_updatedAt_Update"] | undefined,
	delete?: ModelTypes["CriteriaSettingFields_updatedAt_Delete"] | undefined
};
	["CriteriaSettingFields_updatedAt_Create"]: {
		permission: boolean
};
	["CriteriaSettingFields_updatedAt_Read"]: {
		permission: boolean
};
	["CriteriaSettingFields_updatedAt_Update"]: {
		permission: boolean
};
	["CriteriaSettingFields_updatedAt_Delete"]: {
		permission: boolean
};
	["CriteriaSettingFields_createdAt"]: {
		create?: ModelTypes["CriteriaSettingFields_createdAt_Create"] | undefined,
	read?: ModelTypes["CriteriaSettingFields_createdAt_Read"] | undefined,
	update?: ModelTypes["CriteriaSettingFields_createdAt_Update"] | undefined,
	delete?: ModelTypes["CriteriaSettingFields_createdAt_Delete"] | undefined
};
	["CriteriaSettingFields_createdAt_Create"]: {
		permission: boolean
};
	["CriteriaSettingFields_createdAt_Read"]: {
		permission: boolean
};
	["CriteriaSettingFields_createdAt_Update"]: {
		permission: boolean
};
	["CriteriaSettingFields_createdAt_Delete"]: {
		permission: boolean
};
	["CriteriaSettingCreateAccess"]: {
		permission: boolean,
	where?: ModelTypes["JSONObject"] | undefined
};
	["CriteriaSettingReadAccess"]: {
		permission: boolean,
	where?: ModelTypes["JSONObject"] | undefined
};
	["CriteriaSettingUpdateAccess"]: {
		permission: boolean,
	where?: ModelTypes["JSONObject"] | undefined
};
	["CriteriaSettingDeleteAccess"]: {
		permission: boolean,
	where?: ModelTypes["JSONObject"] | undefined
};
	["organiserSettingAccess"]: {
		fields?: ModelTypes["OrganiserSettingFields"] | undefined,
	create?: ModelTypes["OrganiserSettingCreateAccess"] | undefined,
	read?: ModelTypes["OrganiserSettingReadAccess"] | undefined,
	update?: ModelTypes["OrganiserSettingUpdateAccess"] | undefined,
	delete?: ModelTypes["OrganiserSettingDeleteAccess"] | undefined
};
	["OrganiserSettingFields"]: {
		name?: ModelTypes["OrganiserSettingFields_name"] | undefined,
	organiserList?: ModelTypes["OrganiserSettingFields_organiserList"] | undefined,
	region?: ModelTypes["OrganiserSettingFields_region"] | undefined,
	updatedAt?: ModelTypes["OrganiserSettingFields_updatedAt"] | undefined,
	createdAt?: ModelTypes["OrganiserSettingFields_createdAt"] | undefined
};
	["OrganiserSettingFields_name"]: {
		create?: ModelTypes["OrganiserSettingFields_name_Create"] | undefined,
	read?: ModelTypes["OrganiserSettingFields_name_Read"] | undefined,
	update?: ModelTypes["OrganiserSettingFields_name_Update"] | undefined,
	delete?: ModelTypes["OrganiserSettingFields_name_Delete"] | undefined
};
	["OrganiserSettingFields_name_Create"]: {
		permission: boolean
};
	["OrganiserSettingFields_name_Read"]: {
		permission: boolean
};
	["OrganiserSettingFields_name_Update"]: {
		permission: boolean
};
	["OrganiserSettingFields_name_Delete"]: {
		permission: boolean
};
	["OrganiserSettingFields_organiserList"]: {
		create?: ModelTypes["OrganiserSettingFields_organiserList_Create"] | undefined,
	read?: ModelTypes["OrganiserSettingFields_organiserList_Read"] | undefined,
	update?: ModelTypes["OrganiserSettingFields_organiserList_Update"] | undefined,
	delete?: ModelTypes["OrganiserSettingFields_organiserList_Delete"] | undefined
};
	["OrganiserSettingFields_organiserList_Create"]: {
		permission: boolean
};
	["OrganiserSettingFields_organiserList_Read"]: {
		permission: boolean
};
	["OrganiserSettingFields_organiserList_Update"]: {
		permission: boolean
};
	["OrganiserSettingFields_organiserList_Delete"]: {
		permission: boolean
};
	["OrganiserSettingFields_region"]: {
		create?: ModelTypes["OrganiserSettingFields_region_Create"] | undefined,
	read?: ModelTypes["OrganiserSettingFields_region_Read"] | undefined,
	update?: ModelTypes["OrganiserSettingFields_region_Update"] | undefined,
	delete?: ModelTypes["OrganiserSettingFields_region_Delete"] | undefined
};
	["OrganiserSettingFields_region_Create"]: {
		permission: boolean
};
	["OrganiserSettingFields_region_Read"]: {
		permission: boolean
};
	["OrganiserSettingFields_region_Update"]: {
		permission: boolean
};
	["OrganiserSettingFields_region_Delete"]: {
		permission: boolean
};
	["OrganiserSettingFields_updatedAt"]: {
		create?: ModelTypes["OrganiserSettingFields_updatedAt_Create"] | undefined,
	read?: ModelTypes["OrganiserSettingFields_updatedAt_Read"] | undefined,
	update?: ModelTypes["OrganiserSettingFields_updatedAt_Update"] | undefined,
	delete?: ModelTypes["OrganiserSettingFields_updatedAt_Delete"] | undefined
};
	["OrganiserSettingFields_updatedAt_Create"]: {
		permission: boolean
};
	["OrganiserSettingFields_updatedAt_Read"]: {
		permission: boolean
};
	["OrganiserSettingFields_updatedAt_Update"]: {
		permission: boolean
};
	["OrganiserSettingFields_updatedAt_Delete"]: {
		permission: boolean
};
	["OrganiserSettingFields_createdAt"]: {
		create?: ModelTypes["OrganiserSettingFields_createdAt_Create"] | undefined,
	read?: ModelTypes["OrganiserSettingFields_createdAt_Read"] | undefined,
	update?: ModelTypes["OrganiserSettingFields_createdAt_Update"] | undefined,
	delete?: ModelTypes["OrganiserSettingFields_createdAt_Delete"] | undefined
};
	["OrganiserSettingFields_createdAt_Create"]: {
		permission: boolean
};
	["OrganiserSettingFields_createdAt_Read"]: {
		permission: boolean
};
	["OrganiserSettingFields_createdAt_Update"]: {
		permission: boolean
};
	["OrganiserSettingFields_createdAt_Delete"]: {
		permission: boolean
};
	["OrganiserSettingCreateAccess"]: {
		permission: boolean,
	where?: ModelTypes["JSONObject"] | undefined
};
	["OrganiserSettingReadAccess"]: {
		permission: boolean,
	where?: ModelTypes["JSONObject"] | undefined
};
	["OrganiserSettingUpdateAccess"]: {
		permission: boolean,
	where?: ModelTypes["JSONObject"] | undefined
};
	["OrganiserSettingDeleteAccess"]: {
		permission: boolean,
	where?: ModelTypes["JSONObject"] | undefined
};
	["post_settingAccess"]: {
		fields?: ModelTypes["PostSettingFields"] | undefined,
	create?: ModelTypes["PostSettingCreateAccess"] | undefined,
	read?: ModelTypes["PostSettingReadAccess"] | undefined,
	update?: ModelTypes["PostSettingUpdateAccess"] | undefined,
	delete?: ModelTypes["PostSettingDeleteAccess"] | undefined
};
	["PostSettingFields"]: {
		name?: ModelTypes["PostSettingFields_name"] | undefined,
	featuredPosts?: ModelTypes["PostSettingFields_featuredPosts"] | undefined,
	region?: ModelTypes["PostSettingFields_region"] | undefined,
	updatedAt?: ModelTypes["PostSettingFields_updatedAt"] | undefined,
	createdAt?: ModelTypes["PostSettingFields_createdAt"] | undefined
};
	["PostSettingFields_name"]: {
		create?: ModelTypes["PostSettingFields_name_Create"] | undefined,
	read?: ModelTypes["PostSettingFields_name_Read"] | undefined,
	update?: ModelTypes["PostSettingFields_name_Update"] | undefined,
	delete?: ModelTypes["PostSettingFields_name_Delete"] | undefined
};
	["PostSettingFields_name_Create"]: {
		permission: boolean
};
	["PostSettingFields_name_Read"]: {
		permission: boolean
};
	["PostSettingFields_name_Update"]: {
		permission: boolean
};
	["PostSettingFields_name_Delete"]: {
		permission: boolean
};
	["PostSettingFields_featuredPosts"]: {
		create?: ModelTypes["PostSettingFields_featuredPosts_Create"] | undefined,
	read?: ModelTypes["PostSettingFields_featuredPosts_Read"] | undefined,
	update?: ModelTypes["PostSettingFields_featuredPosts_Update"] | undefined,
	delete?: ModelTypes["PostSettingFields_featuredPosts_Delete"] | undefined
};
	["PostSettingFields_featuredPosts_Create"]: {
		permission: boolean
};
	["PostSettingFields_featuredPosts_Read"]: {
		permission: boolean
};
	["PostSettingFields_featuredPosts_Update"]: {
		permission: boolean
};
	["PostSettingFields_featuredPosts_Delete"]: {
		permission: boolean
};
	["PostSettingFields_region"]: {
		create?: ModelTypes["PostSettingFields_region_Create"] | undefined,
	read?: ModelTypes["PostSettingFields_region_Read"] | undefined,
	update?: ModelTypes["PostSettingFields_region_Update"] | undefined,
	delete?: ModelTypes["PostSettingFields_region_Delete"] | undefined
};
	["PostSettingFields_region_Create"]: {
		permission: boolean
};
	["PostSettingFields_region_Read"]: {
		permission: boolean
};
	["PostSettingFields_region_Update"]: {
		permission: boolean
};
	["PostSettingFields_region_Delete"]: {
		permission: boolean
};
	["PostSettingFields_updatedAt"]: {
		create?: ModelTypes["PostSettingFields_updatedAt_Create"] | undefined,
	read?: ModelTypes["PostSettingFields_updatedAt_Read"] | undefined,
	update?: ModelTypes["PostSettingFields_updatedAt_Update"] | undefined,
	delete?: ModelTypes["PostSettingFields_updatedAt_Delete"] | undefined
};
	["PostSettingFields_updatedAt_Create"]: {
		permission: boolean
};
	["PostSettingFields_updatedAt_Read"]: {
		permission: boolean
};
	["PostSettingFields_updatedAt_Update"]: {
		permission: boolean
};
	["PostSettingFields_updatedAt_Delete"]: {
		permission: boolean
};
	["PostSettingFields_createdAt"]: {
		create?: ModelTypes["PostSettingFields_createdAt_Create"] | undefined,
	read?: ModelTypes["PostSettingFields_createdAt_Read"] | undefined,
	update?: ModelTypes["PostSettingFields_createdAt_Update"] | undefined,
	delete?: ModelTypes["PostSettingFields_createdAt_Delete"] | undefined
};
	["PostSettingFields_createdAt_Create"]: {
		permission: boolean
};
	["PostSettingFields_createdAt_Read"]: {
		permission: boolean
};
	["PostSettingFields_createdAt_Update"]: {
		permission: boolean
};
	["PostSettingFields_createdAt_Delete"]: {
		permission: boolean
};
	["PostSettingCreateAccess"]: {
		permission: boolean,
	where?: ModelTypes["JSONObject"] | undefined
};
	["PostSettingReadAccess"]: {
		permission: boolean,
	where?: ModelTypes["JSONObject"] | undefined
};
	["PostSettingUpdateAccess"]: {
		permission: boolean,
	where?: ModelTypes["JSONObject"] | undefined
};
	["PostSettingDeleteAccess"]: {
		permission: boolean,
	where?: ModelTypes["JSONObject"] | undefined
};
	["homepageSettingAccess"]: {
		fields?: ModelTypes["HomepageSettingFields"] | undefined,
	create?: ModelTypes["HomepageSettingCreateAccess"] | undefined,
	read?: ModelTypes["HomepageSettingReadAccess"] | undefined,
	update?: ModelTypes["HomepageSettingUpdateAccess"] | undefined,
	delete?: ModelTypes["HomepageSettingDeleteAccess"] | undefined
};
	["HomepageSettingFields"]: {
		name?: ModelTypes["HomepageSettingFields_name"] | undefined,
	region?: ModelTypes["HomepageSettingFields_region"] | undefined,
	sections?: ModelTypes["HomepageSettingFields_sections"] | undefined,
	updatedAt?: ModelTypes["HomepageSettingFields_updatedAt"] | undefined,
	createdAt?: ModelTypes["HomepageSettingFields_createdAt"] | undefined
};
	["HomepageSettingFields_name"]: {
		create?: ModelTypes["HomepageSettingFields_name_Create"] | undefined,
	read?: ModelTypes["HomepageSettingFields_name_Read"] | undefined,
	update?: ModelTypes["HomepageSettingFields_name_Update"] | undefined,
	delete?: ModelTypes["HomepageSettingFields_name_Delete"] | undefined
};
	["HomepageSettingFields_name_Create"]: {
		permission: boolean
};
	["HomepageSettingFields_name_Read"]: {
		permission: boolean
};
	["HomepageSettingFields_name_Update"]: {
		permission: boolean
};
	["HomepageSettingFields_name_Delete"]: {
		permission: boolean
};
	["HomepageSettingFields_region"]: {
		create?: ModelTypes["HomepageSettingFields_region_Create"] | undefined,
	read?: ModelTypes["HomepageSettingFields_region_Read"] | undefined,
	update?: ModelTypes["HomepageSettingFields_region_Update"] | undefined,
	delete?: ModelTypes["HomepageSettingFields_region_Delete"] | undefined
};
	["HomepageSettingFields_region_Create"]: {
		permission: boolean
};
	["HomepageSettingFields_region_Read"]: {
		permission: boolean
};
	["HomepageSettingFields_region_Update"]: {
		permission: boolean
};
	["HomepageSettingFields_region_Delete"]: {
		permission: boolean
};
	["HomepageSettingFields_sections"]: {
		create?: ModelTypes["HomepageSettingFields_sections_Create"] | undefined,
	read?: ModelTypes["HomepageSettingFields_sections_Read"] | undefined,
	update?: ModelTypes["HomepageSettingFields_sections_Update"] | undefined,
	delete?: ModelTypes["HomepageSettingFields_sections_Delete"] | undefined
};
	["HomepageSettingFields_sections_Create"]: {
		permission: boolean
};
	["HomepageSettingFields_sections_Read"]: {
		permission: boolean
};
	["HomepageSettingFields_sections_Update"]: {
		permission: boolean
};
	["HomepageSettingFields_sections_Delete"]: {
		permission: boolean
};
	["HomepageSettingFields_updatedAt"]: {
		create?: ModelTypes["HomepageSettingFields_updatedAt_Create"] | undefined,
	read?: ModelTypes["HomepageSettingFields_updatedAt_Read"] | undefined,
	update?: ModelTypes["HomepageSettingFields_updatedAt_Update"] | undefined,
	delete?: ModelTypes["HomepageSettingFields_updatedAt_Delete"] | undefined
};
	["HomepageSettingFields_updatedAt_Create"]: {
		permission: boolean
};
	["HomepageSettingFields_updatedAt_Read"]: {
		permission: boolean
};
	["HomepageSettingFields_updatedAt_Update"]: {
		permission: boolean
};
	["HomepageSettingFields_updatedAt_Delete"]: {
		permission: boolean
};
	["HomepageSettingFields_createdAt"]: {
		create?: ModelTypes["HomepageSettingFields_createdAt_Create"] | undefined,
	read?: ModelTypes["HomepageSettingFields_createdAt_Read"] | undefined,
	update?: ModelTypes["HomepageSettingFields_createdAt_Update"] | undefined,
	delete?: ModelTypes["HomepageSettingFields_createdAt_Delete"] | undefined
};
	["HomepageSettingFields_createdAt_Create"]: {
		permission: boolean
};
	["HomepageSettingFields_createdAt_Read"]: {
		permission: boolean
};
	["HomepageSettingFields_createdAt_Update"]: {
		permission: boolean
};
	["HomepageSettingFields_createdAt_Delete"]: {
		permission: boolean
};
	["HomepageSettingCreateAccess"]: {
		permission: boolean,
	where?: ModelTypes["JSONObject"] | undefined
};
	["HomepageSettingReadAccess"]: {
		permission: boolean,
	where?: ModelTypes["JSONObject"] | undefined
};
	["HomepageSettingUpdateAccess"]: {
		permission: boolean,
	where?: ModelTypes["JSONObject"] | undefined
};
	["HomepageSettingDeleteAccess"]: {
		permission: boolean,
	where?: ModelTypes["JSONObject"] | undefined
};
	["banner_sectionAccess"]: {
		fields?: ModelTypes["BannerSectionFields"] | undefined,
	create?: ModelTypes["BannerSectionCreateAccess"] | undefined,
	read?: ModelTypes["BannerSectionReadAccess"] | undefined,
	update?: ModelTypes["BannerSectionUpdateAccess"] | undefined,
	delete?: ModelTypes["BannerSectionDeleteAccess"] | undefined
};
	["BannerSectionFields"]: {
		name?: ModelTypes["BannerSectionFields_name"] | undefined,
	slug?: ModelTypes["BannerSectionFields_slug"] | undefined,
	banners?: ModelTypes["BannerSectionFields_banners"] | undefined,
	updatedAt?: ModelTypes["BannerSectionFields_updatedAt"] | undefined,
	createdAt?: ModelTypes["BannerSectionFields_createdAt"] | undefined
};
	["BannerSectionFields_name"]: {
		create?: ModelTypes["BannerSectionFields_name_Create"] | undefined,
	read?: ModelTypes["BannerSectionFields_name_Read"] | undefined,
	update?: ModelTypes["BannerSectionFields_name_Update"] | undefined,
	delete?: ModelTypes["BannerSectionFields_name_Delete"] | undefined
};
	["BannerSectionFields_name_Create"]: {
		permission: boolean
};
	["BannerSectionFields_name_Read"]: {
		permission: boolean
};
	["BannerSectionFields_name_Update"]: {
		permission: boolean
};
	["BannerSectionFields_name_Delete"]: {
		permission: boolean
};
	["BannerSectionFields_slug"]: {
		create?: ModelTypes["BannerSectionFields_slug_Create"] | undefined,
	read?: ModelTypes["BannerSectionFields_slug_Read"] | undefined,
	update?: ModelTypes["BannerSectionFields_slug_Update"] | undefined,
	delete?: ModelTypes["BannerSectionFields_slug_Delete"] | undefined
};
	["BannerSectionFields_slug_Create"]: {
		permission: boolean
};
	["BannerSectionFields_slug_Read"]: {
		permission: boolean
};
	["BannerSectionFields_slug_Update"]: {
		permission: boolean
};
	["BannerSectionFields_slug_Delete"]: {
		permission: boolean
};
	["BannerSectionFields_banners"]: {
		create?: ModelTypes["BannerSectionFields_banners_Create"] | undefined,
	read?: ModelTypes["BannerSectionFields_banners_Read"] | undefined,
	update?: ModelTypes["BannerSectionFields_banners_Update"] | undefined,
	delete?: ModelTypes["BannerSectionFields_banners_Delete"] | undefined,
	fields?: ModelTypes["BannerSectionFields_banners_Fields"] | undefined
};
	["BannerSectionFields_banners_Create"]: {
		permission: boolean
};
	["BannerSectionFields_banners_Read"]: {
		permission: boolean
};
	["BannerSectionFields_banners_Update"]: {
		permission: boolean
};
	["BannerSectionFields_banners_Delete"]: {
		permission: boolean
};
	["BannerSectionFields_banners_Fields"]: {
		name?: ModelTypes["BannerSectionFields_banners_name"] | undefined,
	status?: ModelTypes["BannerSectionFields_banners_status"] | undefined,
	desktop?: ModelTypes["BannerSectionFields_banners_desktop"] | undefined,
	bannerStartDate?: ModelTypes["BannerSectionFields_banners_bannerStartDate"] | undefined,
	bannerEndDate?: ModelTypes["BannerSectionFields_banners_bannerEndDate"] | undefined,
	region?: ModelTypes["BannerSectionFields_banners_region"] | undefined,
	id?: ModelTypes["BannerSectionFields_banners_id"] | undefined
};
	["BannerSectionFields_banners_name"]: {
		create?: ModelTypes["BannerSectionFields_banners_name_Create"] | undefined,
	read?: ModelTypes["BannerSectionFields_banners_name_Read"] | undefined,
	update?: ModelTypes["BannerSectionFields_banners_name_Update"] | undefined,
	delete?: ModelTypes["BannerSectionFields_banners_name_Delete"] | undefined
};
	["BannerSectionFields_banners_name_Create"]: {
		permission: boolean
};
	["BannerSectionFields_banners_name_Read"]: {
		permission: boolean
};
	["BannerSectionFields_banners_name_Update"]: {
		permission: boolean
};
	["BannerSectionFields_banners_name_Delete"]: {
		permission: boolean
};
	["BannerSectionFields_banners_status"]: {
		create?: ModelTypes["BannerSectionFields_banners_status_Create"] | undefined,
	read?: ModelTypes["BannerSectionFields_banners_status_Read"] | undefined,
	update?: ModelTypes["BannerSectionFields_banners_status_Update"] | undefined,
	delete?: ModelTypes["BannerSectionFields_banners_status_Delete"] | undefined
};
	["BannerSectionFields_banners_status_Create"]: {
		permission: boolean
};
	["BannerSectionFields_banners_status_Read"]: {
		permission: boolean
};
	["BannerSectionFields_banners_status_Update"]: {
		permission: boolean
};
	["BannerSectionFields_banners_status_Delete"]: {
		permission: boolean
};
	["BannerSectionFields_banners_desktop"]: {
		create?: ModelTypes["BannerSectionFields_banners_desktop_Create"] | undefined,
	read?: ModelTypes["BannerSectionFields_banners_desktop_Read"] | undefined,
	update?: ModelTypes["BannerSectionFields_banners_desktop_Update"] | undefined,
	delete?: ModelTypes["BannerSectionFields_banners_desktop_Delete"] | undefined
};
	["BannerSectionFields_banners_desktop_Create"]: {
		permission: boolean
};
	["BannerSectionFields_banners_desktop_Read"]: {
		permission: boolean
};
	["BannerSectionFields_banners_desktop_Update"]: {
		permission: boolean
};
	["BannerSectionFields_banners_desktop_Delete"]: {
		permission: boolean
};
	["BannerSectionFields_banners_bannerStartDate"]: {
		create?: ModelTypes["BannerSectionFields_banners_bannerStartDate_Create"] | undefined,
	read?: ModelTypes["BannerSectionFields_banners_bannerStartDate_Read"] | undefined,
	update?: ModelTypes["BannerSectionFields_banners_bannerStartDate_Update"] | undefined,
	delete?: ModelTypes["BannerSectionFields_banners_bannerStartDate_Delete"] | undefined
};
	["BannerSectionFields_banners_bannerStartDate_Create"]: {
		permission: boolean
};
	["BannerSectionFields_banners_bannerStartDate_Read"]: {
		permission: boolean
};
	["BannerSectionFields_banners_bannerStartDate_Update"]: {
		permission: boolean
};
	["BannerSectionFields_banners_bannerStartDate_Delete"]: {
		permission: boolean
};
	["BannerSectionFields_banners_bannerEndDate"]: {
		create?: ModelTypes["BannerSectionFields_banners_bannerEndDate_Create"] | undefined,
	read?: ModelTypes["BannerSectionFields_banners_bannerEndDate_Read"] | undefined,
	update?: ModelTypes["BannerSectionFields_banners_bannerEndDate_Update"] | undefined,
	delete?: ModelTypes["BannerSectionFields_banners_bannerEndDate_Delete"] | undefined
};
	["BannerSectionFields_banners_bannerEndDate_Create"]: {
		permission: boolean
};
	["BannerSectionFields_banners_bannerEndDate_Read"]: {
		permission: boolean
};
	["BannerSectionFields_banners_bannerEndDate_Update"]: {
		permission: boolean
};
	["BannerSectionFields_banners_bannerEndDate_Delete"]: {
		permission: boolean
};
	["BannerSectionFields_banners_region"]: {
		create?: ModelTypes["BannerSectionFields_banners_region_Create"] | undefined,
	read?: ModelTypes["BannerSectionFields_banners_region_Read"] | undefined,
	update?: ModelTypes["BannerSectionFields_banners_region_Update"] | undefined,
	delete?: ModelTypes["BannerSectionFields_banners_region_Delete"] | undefined
};
	["BannerSectionFields_banners_region_Create"]: {
		permission: boolean
};
	["BannerSectionFields_banners_region_Read"]: {
		permission: boolean
};
	["BannerSectionFields_banners_region_Update"]: {
		permission: boolean
};
	["BannerSectionFields_banners_region_Delete"]: {
		permission: boolean
};
	["BannerSectionFields_banners_id"]: {
		create?: ModelTypes["BannerSectionFields_banners_id_Create"] | undefined,
	read?: ModelTypes["BannerSectionFields_banners_id_Read"] | undefined,
	update?: ModelTypes["BannerSectionFields_banners_id_Update"] | undefined,
	delete?: ModelTypes["BannerSectionFields_banners_id_Delete"] | undefined
};
	["BannerSectionFields_banners_id_Create"]: {
		permission: boolean
};
	["BannerSectionFields_banners_id_Read"]: {
		permission: boolean
};
	["BannerSectionFields_banners_id_Update"]: {
		permission: boolean
};
	["BannerSectionFields_banners_id_Delete"]: {
		permission: boolean
};
	["BannerSectionFields_updatedAt"]: {
		create?: ModelTypes["BannerSectionFields_updatedAt_Create"] | undefined,
	read?: ModelTypes["BannerSectionFields_updatedAt_Read"] | undefined,
	update?: ModelTypes["BannerSectionFields_updatedAt_Update"] | undefined,
	delete?: ModelTypes["BannerSectionFields_updatedAt_Delete"] | undefined
};
	["BannerSectionFields_updatedAt_Create"]: {
		permission: boolean
};
	["BannerSectionFields_updatedAt_Read"]: {
		permission: boolean
};
	["BannerSectionFields_updatedAt_Update"]: {
		permission: boolean
};
	["BannerSectionFields_updatedAt_Delete"]: {
		permission: boolean
};
	["BannerSectionFields_createdAt"]: {
		create?: ModelTypes["BannerSectionFields_createdAt_Create"] | undefined,
	read?: ModelTypes["BannerSectionFields_createdAt_Read"] | undefined,
	update?: ModelTypes["BannerSectionFields_createdAt_Update"] | undefined,
	delete?: ModelTypes["BannerSectionFields_createdAt_Delete"] | undefined
};
	["BannerSectionFields_createdAt_Create"]: {
		permission: boolean
};
	["BannerSectionFields_createdAt_Read"]: {
		permission: boolean
};
	["BannerSectionFields_createdAt_Update"]: {
		permission: boolean
};
	["BannerSectionFields_createdAt_Delete"]: {
		permission: boolean
};
	["BannerSectionCreateAccess"]: {
		permission: boolean,
	where?: ModelTypes["JSONObject"] | undefined
};
	["BannerSectionReadAccess"]: {
		permission: boolean,
	where?: ModelTypes["JSONObject"] | undefined
};
	["BannerSectionUpdateAccess"]: {
		permission: boolean,
	where?: ModelTypes["JSONObject"] | undefined
};
	["BannerSectionDeleteAccess"]: {
		permission: boolean,
	where?: ModelTypes["JSONObject"] | undefined
};
	["macao_banner_sectionAccess"]: {
		fields?: ModelTypes["MacaoBannerSectionFields"] | undefined,
	create?: ModelTypes["MacaoBannerSectionCreateAccess"] | undefined,
	read?: ModelTypes["MacaoBannerSectionReadAccess"] | undefined,
	update?: ModelTypes["MacaoBannerSectionUpdateAccess"] | undefined,
	delete?: ModelTypes["MacaoBannerSectionDeleteAccess"] | undefined
};
	["MacaoBannerSectionFields"]: {
		name?: ModelTypes["MacaoBannerSectionFields_name"] | undefined,
	slug?: ModelTypes["MacaoBannerSectionFields_slug"] | undefined,
	banners?: ModelTypes["MacaoBannerSectionFields_banners"] | undefined,
	updatedAt?: ModelTypes["MacaoBannerSectionFields_updatedAt"] | undefined,
	createdAt?: ModelTypes["MacaoBannerSectionFields_createdAt"] | undefined
};
	["MacaoBannerSectionFields_name"]: {
		create?: ModelTypes["MacaoBannerSectionFields_name_Create"] | undefined,
	read?: ModelTypes["MacaoBannerSectionFields_name_Read"] | undefined,
	update?: ModelTypes["MacaoBannerSectionFields_name_Update"] | undefined,
	delete?: ModelTypes["MacaoBannerSectionFields_name_Delete"] | undefined
};
	["MacaoBannerSectionFields_name_Create"]: {
		permission: boolean
};
	["MacaoBannerSectionFields_name_Read"]: {
		permission: boolean
};
	["MacaoBannerSectionFields_name_Update"]: {
		permission: boolean
};
	["MacaoBannerSectionFields_name_Delete"]: {
		permission: boolean
};
	["MacaoBannerSectionFields_slug"]: {
		create?: ModelTypes["MacaoBannerSectionFields_slug_Create"] | undefined,
	read?: ModelTypes["MacaoBannerSectionFields_slug_Read"] | undefined,
	update?: ModelTypes["MacaoBannerSectionFields_slug_Update"] | undefined,
	delete?: ModelTypes["MacaoBannerSectionFields_slug_Delete"] | undefined
};
	["MacaoBannerSectionFields_slug_Create"]: {
		permission: boolean
};
	["MacaoBannerSectionFields_slug_Read"]: {
		permission: boolean
};
	["MacaoBannerSectionFields_slug_Update"]: {
		permission: boolean
};
	["MacaoBannerSectionFields_slug_Delete"]: {
		permission: boolean
};
	["MacaoBannerSectionFields_banners"]: {
		create?: ModelTypes["MacaoBannerSectionFields_banners_Create"] | undefined,
	read?: ModelTypes["MacaoBannerSectionFields_banners_Read"] | undefined,
	update?: ModelTypes["MacaoBannerSectionFields_banners_Update"] | undefined,
	delete?: ModelTypes["MacaoBannerSectionFields_banners_Delete"] | undefined,
	fields?: ModelTypes["MacaoBannerSectionFields_banners_Fields"] | undefined
};
	["MacaoBannerSectionFields_banners_Create"]: {
		permission: boolean
};
	["MacaoBannerSectionFields_banners_Read"]: {
		permission: boolean
};
	["MacaoBannerSectionFields_banners_Update"]: {
		permission: boolean
};
	["MacaoBannerSectionFields_banners_Delete"]: {
		permission: boolean
};
	["MacaoBannerSectionFields_banners_Fields"]: {
		name?: ModelTypes["MacaoBannerSectionFields_banners_name"] | undefined,
	status?: ModelTypes["MacaoBannerSectionFields_banners_status"] | undefined,
	desktop?: ModelTypes["MacaoBannerSectionFields_banners_desktop"] | undefined,
	bannerStartDate?: ModelTypes["MacaoBannerSectionFields_banners_bannerStartDate"] | undefined,
	bannerEndDate?: ModelTypes["MacaoBannerSectionFields_banners_bannerEndDate"] | undefined,
	region?: ModelTypes["MacaoBannerSectionFields_banners_region"] | undefined,
	id?: ModelTypes["MacaoBannerSectionFields_banners_id"] | undefined
};
	["MacaoBannerSectionFields_banners_name"]: {
		create?: ModelTypes["MacaoBannerSectionFields_banners_name_Create"] | undefined,
	read?: ModelTypes["MacaoBannerSectionFields_banners_name_Read"] | undefined,
	update?: ModelTypes["MacaoBannerSectionFields_banners_name_Update"] | undefined,
	delete?: ModelTypes["MacaoBannerSectionFields_banners_name_Delete"] | undefined
};
	["MacaoBannerSectionFields_banners_name_Create"]: {
		permission: boolean
};
	["MacaoBannerSectionFields_banners_name_Read"]: {
		permission: boolean
};
	["MacaoBannerSectionFields_banners_name_Update"]: {
		permission: boolean
};
	["MacaoBannerSectionFields_banners_name_Delete"]: {
		permission: boolean
};
	["MacaoBannerSectionFields_banners_status"]: {
		create?: ModelTypes["MacaoBannerSectionFields_banners_status_Create"] | undefined,
	read?: ModelTypes["MacaoBannerSectionFields_banners_status_Read"] | undefined,
	update?: ModelTypes["MacaoBannerSectionFields_banners_status_Update"] | undefined,
	delete?: ModelTypes["MacaoBannerSectionFields_banners_status_Delete"] | undefined
};
	["MacaoBannerSectionFields_banners_status_Create"]: {
		permission: boolean
};
	["MacaoBannerSectionFields_banners_status_Read"]: {
		permission: boolean
};
	["MacaoBannerSectionFields_banners_status_Update"]: {
		permission: boolean
};
	["MacaoBannerSectionFields_banners_status_Delete"]: {
		permission: boolean
};
	["MacaoBannerSectionFields_banners_desktop"]: {
		create?: ModelTypes["MacaoBannerSectionFields_banners_desktop_Create"] | undefined,
	read?: ModelTypes["MacaoBannerSectionFields_banners_desktop_Read"] | undefined,
	update?: ModelTypes["MacaoBannerSectionFields_banners_desktop_Update"] | undefined,
	delete?: ModelTypes["MacaoBannerSectionFields_banners_desktop_Delete"] | undefined
};
	["MacaoBannerSectionFields_banners_desktop_Create"]: {
		permission: boolean
};
	["MacaoBannerSectionFields_banners_desktop_Read"]: {
		permission: boolean
};
	["MacaoBannerSectionFields_banners_desktop_Update"]: {
		permission: boolean
};
	["MacaoBannerSectionFields_banners_desktop_Delete"]: {
		permission: boolean
};
	["MacaoBannerSectionFields_banners_bannerStartDate"]: {
		create?: ModelTypes["MacaoBannerSectionFields_banners_bannerStartDate_Create"] | undefined,
	read?: ModelTypes["MacaoBannerSectionFields_banners_bannerStartDate_Read"] | undefined,
	update?: ModelTypes["MacaoBannerSectionFields_banners_bannerStartDate_Update"] | undefined,
	delete?: ModelTypes["MacaoBannerSectionFields_banners_bannerStartDate_Delete"] | undefined
};
	["MacaoBannerSectionFields_banners_bannerStartDate_Create"]: {
		permission: boolean
};
	["MacaoBannerSectionFields_banners_bannerStartDate_Read"]: {
		permission: boolean
};
	["MacaoBannerSectionFields_banners_bannerStartDate_Update"]: {
		permission: boolean
};
	["MacaoBannerSectionFields_banners_bannerStartDate_Delete"]: {
		permission: boolean
};
	["MacaoBannerSectionFields_banners_bannerEndDate"]: {
		create?: ModelTypes["MacaoBannerSectionFields_banners_bannerEndDate_Create"] | undefined,
	read?: ModelTypes["MacaoBannerSectionFields_banners_bannerEndDate_Read"] | undefined,
	update?: ModelTypes["MacaoBannerSectionFields_banners_bannerEndDate_Update"] | undefined,
	delete?: ModelTypes["MacaoBannerSectionFields_banners_bannerEndDate_Delete"] | undefined
};
	["MacaoBannerSectionFields_banners_bannerEndDate_Create"]: {
		permission: boolean
};
	["MacaoBannerSectionFields_banners_bannerEndDate_Read"]: {
		permission: boolean
};
	["MacaoBannerSectionFields_banners_bannerEndDate_Update"]: {
		permission: boolean
};
	["MacaoBannerSectionFields_banners_bannerEndDate_Delete"]: {
		permission: boolean
};
	["MacaoBannerSectionFields_banners_region"]: {
		create?: ModelTypes["MacaoBannerSectionFields_banners_region_Create"] | undefined,
	read?: ModelTypes["MacaoBannerSectionFields_banners_region_Read"] | undefined,
	update?: ModelTypes["MacaoBannerSectionFields_banners_region_Update"] | undefined,
	delete?: ModelTypes["MacaoBannerSectionFields_banners_region_Delete"] | undefined
};
	["MacaoBannerSectionFields_banners_region_Create"]: {
		permission: boolean
};
	["MacaoBannerSectionFields_banners_region_Read"]: {
		permission: boolean
};
	["MacaoBannerSectionFields_banners_region_Update"]: {
		permission: boolean
};
	["MacaoBannerSectionFields_banners_region_Delete"]: {
		permission: boolean
};
	["MacaoBannerSectionFields_banners_id"]: {
		create?: ModelTypes["MacaoBannerSectionFields_banners_id_Create"] | undefined,
	read?: ModelTypes["MacaoBannerSectionFields_banners_id_Read"] | undefined,
	update?: ModelTypes["MacaoBannerSectionFields_banners_id_Update"] | undefined,
	delete?: ModelTypes["MacaoBannerSectionFields_banners_id_Delete"] | undefined
};
	["MacaoBannerSectionFields_banners_id_Create"]: {
		permission: boolean
};
	["MacaoBannerSectionFields_banners_id_Read"]: {
		permission: boolean
};
	["MacaoBannerSectionFields_banners_id_Update"]: {
		permission: boolean
};
	["MacaoBannerSectionFields_banners_id_Delete"]: {
		permission: boolean
};
	["MacaoBannerSectionFields_updatedAt"]: {
		create?: ModelTypes["MacaoBannerSectionFields_updatedAt_Create"] | undefined,
	read?: ModelTypes["MacaoBannerSectionFields_updatedAt_Read"] | undefined,
	update?: ModelTypes["MacaoBannerSectionFields_updatedAt_Update"] | undefined,
	delete?: ModelTypes["MacaoBannerSectionFields_updatedAt_Delete"] | undefined
};
	["MacaoBannerSectionFields_updatedAt_Create"]: {
		permission: boolean
};
	["MacaoBannerSectionFields_updatedAt_Read"]: {
		permission: boolean
};
	["MacaoBannerSectionFields_updatedAt_Update"]: {
		permission: boolean
};
	["MacaoBannerSectionFields_updatedAt_Delete"]: {
		permission: boolean
};
	["MacaoBannerSectionFields_createdAt"]: {
		create?: ModelTypes["MacaoBannerSectionFields_createdAt_Create"] | undefined,
	read?: ModelTypes["MacaoBannerSectionFields_createdAt_Read"] | undefined,
	update?: ModelTypes["MacaoBannerSectionFields_createdAt_Update"] | undefined,
	delete?: ModelTypes["MacaoBannerSectionFields_createdAt_Delete"] | undefined
};
	["MacaoBannerSectionFields_createdAt_Create"]: {
		permission: boolean
};
	["MacaoBannerSectionFields_createdAt_Read"]: {
		permission: boolean
};
	["MacaoBannerSectionFields_createdAt_Update"]: {
		permission: boolean
};
	["MacaoBannerSectionFields_createdAt_Delete"]: {
		permission: boolean
};
	["MacaoBannerSectionCreateAccess"]: {
		permission: boolean,
	where?: ModelTypes["JSONObject"] | undefined
};
	["MacaoBannerSectionReadAccess"]: {
		permission: boolean,
	where?: ModelTypes["JSONObject"] | undefined
};
	["MacaoBannerSectionUpdateAccess"]: {
		permission: boolean,
	where?: ModelTypes["JSONObject"] | undefined
};
	["MacaoBannerSectionDeleteAccess"]: {
		permission: boolean,
	where?: ModelTypes["JSONObject"] | undefined
};
	["bannersAccess"]: {
		fields?: ModelTypes["BannersFields"] | undefined,
	create?: ModelTypes["BannersCreateAccess"] | undefined,
	read?: ModelTypes["BannersReadAccess"] | undefined,
	update?: ModelTypes["BannersUpdateAccess"] | undefined,
	delete?: ModelTypes["BannersDeleteAccess"] | undefined
};
	["BannersFields"]: {
		name?: ModelTypes["BannersFields_name"] | undefined,
	desktop?: ModelTypes["BannersFields_desktop"] | undefined,
	mobile?: ModelTypes["BannersFields_mobile"] | undefined,
	bannerStartDate?: ModelTypes["BannersFields_bannerStartDate"] | undefined,
	bannerEndDate?: ModelTypes["BannersFields_bannerEndDate"] | undefined,
	location?: ModelTypes["BannersFields_location"] | undefined,
	link?: ModelTypes["BannersFields_link"] | undefined,
	external?: ModelTypes["BannersFields_external"] | undefined,
	type?: ModelTypes["BannersFields_type"] | undefined,
	status?: ModelTypes["BannersFields_status"] | undefined,
	advertise?: ModelTypes["BannersFields_advertise"] | undefined,
	_locations?: ModelTypes["BannersFields__locations"] | undefined,
	region?: ModelTypes["BannersFields_region"] | undefined,
	homePageClick?: ModelTypes["BannersFields_homePageClick"] | undefined,
	homePageImpression?: ModelTypes["BannersFields_homePageImpression"] | undefined,
	latestEventClick?: ModelTypes["BannersFields_latestEventClick"] | undefined,
	latestEventImpression?: ModelTypes["BannersFields_latestEventImpression"] | undefined,
	editorChoiceClick?: ModelTypes["BannersFields_editorChoiceClick"] | undefined,
	editorChoiceImpression?: ModelTypes["BannersFields_editorChoiceImpression"] | undefined,
	eventListClick?: ModelTypes["BannersFields_eventListClick"] | undefined,
	eventListImpression?: ModelTypes["BannersFields_eventListImpression"] | undefined,
	topTenClick?: ModelTypes["BannersFields_topTenClick"] | undefined,
	topTenImpression?: ModelTypes["BannersFields_topTenImpression"] | undefined,
	miscClick?: ModelTypes["BannersFields_miscClick"] | undefined,
	miscImpression?: ModelTypes["BannersFields_miscImpression"] | undefined,
	clickTotal?: ModelTypes["BannersFields_clickTotal"] | undefined,
	totalImpression?: ModelTypes["BannersFields_totalImpression"] | undefined,
	updatedAt?: ModelTypes["BannersFields_updatedAt"] | undefined,
	createdAt?: ModelTypes["BannersFields_createdAt"] | undefined
};
	["BannersFields_name"]: {
		create?: ModelTypes["BannersFields_name_Create"] | undefined,
	read?: ModelTypes["BannersFields_name_Read"] | undefined,
	update?: ModelTypes["BannersFields_name_Update"] | undefined,
	delete?: ModelTypes["BannersFields_name_Delete"] | undefined
};
	["BannersFields_name_Create"]: {
		permission: boolean
};
	["BannersFields_name_Read"]: {
		permission: boolean
};
	["BannersFields_name_Update"]: {
		permission: boolean
};
	["BannersFields_name_Delete"]: {
		permission: boolean
};
	["BannersFields_desktop"]: {
		create?: ModelTypes["BannersFields_desktop_Create"] | undefined,
	read?: ModelTypes["BannersFields_desktop_Read"] | undefined,
	update?: ModelTypes["BannersFields_desktop_Update"] | undefined,
	delete?: ModelTypes["BannersFields_desktop_Delete"] | undefined
};
	["BannersFields_desktop_Create"]: {
		permission: boolean
};
	["BannersFields_desktop_Read"]: {
		permission: boolean
};
	["BannersFields_desktop_Update"]: {
		permission: boolean
};
	["BannersFields_desktop_Delete"]: {
		permission: boolean
};
	["BannersFields_mobile"]: {
		create?: ModelTypes["BannersFields_mobile_Create"] | undefined,
	read?: ModelTypes["BannersFields_mobile_Read"] | undefined,
	update?: ModelTypes["BannersFields_mobile_Update"] | undefined,
	delete?: ModelTypes["BannersFields_mobile_Delete"] | undefined
};
	["BannersFields_mobile_Create"]: {
		permission: boolean
};
	["BannersFields_mobile_Read"]: {
		permission: boolean
};
	["BannersFields_mobile_Update"]: {
		permission: boolean
};
	["BannersFields_mobile_Delete"]: {
		permission: boolean
};
	["BannersFields_bannerStartDate"]: {
		create?: ModelTypes["BannersFields_bannerStartDate_Create"] | undefined,
	read?: ModelTypes["BannersFields_bannerStartDate_Read"] | undefined,
	update?: ModelTypes["BannersFields_bannerStartDate_Update"] | undefined,
	delete?: ModelTypes["BannersFields_bannerStartDate_Delete"] | undefined
};
	["BannersFields_bannerStartDate_Create"]: {
		permission: boolean
};
	["BannersFields_bannerStartDate_Read"]: {
		permission: boolean
};
	["BannersFields_bannerStartDate_Update"]: {
		permission: boolean
};
	["BannersFields_bannerStartDate_Delete"]: {
		permission: boolean
};
	["BannersFields_bannerEndDate"]: {
		create?: ModelTypes["BannersFields_bannerEndDate_Create"] | undefined,
	read?: ModelTypes["BannersFields_bannerEndDate_Read"] | undefined,
	update?: ModelTypes["BannersFields_bannerEndDate_Update"] | undefined,
	delete?: ModelTypes["BannersFields_bannerEndDate_Delete"] | undefined
};
	["BannersFields_bannerEndDate_Create"]: {
		permission: boolean
};
	["BannersFields_bannerEndDate_Read"]: {
		permission: boolean
};
	["BannersFields_bannerEndDate_Update"]: {
		permission: boolean
};
	["BannersFields_bannerEndDate_Delete"]: {
		permission: boolean
};
	["BannersFields_location"]: {
		create?: ModelTypes["BannersFields_location_Create"] | undefined,
	read?: ModelTypes["BannersFields_location_Read"] | undefined,
	update?: ModelTypes["BannersFields_location_Update"] | undefined,
	delete?: ModelTypes["BannersFields_location_Delete"] | undefined,
	fields?: ModelTypes["BannersFields_location_Fields"] | undefined
};
	["BannersFields_location_Create"]: {
		permission: boolean
};
	["BannersFields_location_Read"]: {
		permission: boolean
};
	["BannersFields_location_Update"]: {
		permission: boolean
};
	["BannersFields_location_Delete"]: {
		permission: boolean
};
	["BannersFields_location_Fields"]: {
		page?: ModelTypes["BannersFields_location_page"] | undefined,
	eventRankingList?: ModelTypes["BannersFields_location_eventRankingList"] | undefined,
	locationRankingList?: ModelTypes["BannersFields_location_locationRankingList"] | undefined,
	categories?: ModelTypes["BannersFields_location_categories"] | undefined,
	time?: ModelTypes["BannersFields_location_time"] | undefined,
	district?: ModelTypes["BannersFields_location_district"] | undefined,
	audience?: ModelTypes["BannersFields_location_audience"] | undefined,
	multipleCriteriaSearch?: ModelTypes["BannersFields_location_multipleCriteriaSearch"] | undefined
};
	["BannersFields_location_page"]: {
		create?: ModelTypes["BannersFields_location_page_Create"] | undefined,
	read?: ModelTypes["BannersFields_location_page_Read"] | undefined,
	update?: ModelTypes["BannersFields_location_page_Update"] | undefined,
	delete?: ModelTypes["BannersFields_location_page_Delete"] | undefined
};
	["BannersFields_location_page_Create"]: {
		permission: boolean
};
	["BannersFields_location_page_Read"]: {
		permission: boolean
};
	["BannersFields_location_page_Update"]: {
		permission: boolean
};
	["BannersFields_location_page_Delete"]: {
		permission: boolean
};
	["BannersFields_location_eventRankingList"]: {
		create?: ModelTypes["BannersFields_location_eventRankingList_Create"] | undefined,
	read?: ModelTypes["BannersFields_location_eventRankingList_Read"] | undefined,
	update?: ModelTypes["BannersFields_location_eventRankingList_Update"] | undefined,
	delete?: ModelTypes["BannersFields_location_eventRankingList_Delete"] | undefined
};
	["BannersFields_location_eventRankingList_Create"]: {
		permission: boolean
};
	["BannersFields_location_eventRankingList_Read"]: {
		permission: boolean
};
	["BannersFields_location_eventRankingList_Update"]: {
		permission: boolean
};
	["BannersFields_location_eventRankingList_Delete"]: {
		permission: boolean
};
	["BannersFields_location_locationRankingList"]: {
		create?: ModelTypes["BannersFields_location_locationRankingList_Create"] | undefined,
	read?: ModelTypes["BannersFields_location_locationRankingList_Read"] | undefined,
	update?: ModelTypes["BannersFields_location_locationRankingList_Update"] | undefined,
	delete?: ModelTypes["BannersFields_location_locationRankingList_Delete"] | undefined
};
	["BannersFields_location_locationRankingList_Create"]: {
		permission: boolean
};
	["BannersFields_location_locationRankingList_Read"]: {
		permission: boolean
};
	["BannersFields_location_locationRankingList_Update"]: {
		permission: boolean
};
	["BannersFields_location_locationRankingList_Delete"]: {
		permission: boolean
};
	["BannersFields_location_categories"]: {
		create?: ModelTypes["BannersFields_location_categories_Create"] | undefined,
	read?: ModelTypes["BannersFields_location_categories_Read"] | undefined,
	update?: ModelTypes["BannersFields_location_categories_Update"] | undefined,
	delete?: ModelTypes["BannersFields_location_categories_Delete"] | undefined
};
	["BannersFields_location_categories_Create"]: {
		permission: boolean
};
	["BannersFields_location_categories_Read"]: {
		permission: boolean
};
	["BannersFields_location_categories_Update"]: {
		permission: boolean
};
	["BannersFields_location_categories_Delete"]: {
		permission: boolean
};
	["BannersFields_location_time"]: {
		create?: ModelTypes["BannersFields_location_time_Create"] | undefined,
	read?: ModelTypes["BannersFields_location_time_Read"] | undefined,
	update?: ModelTypes["BannersFields_location_time_Update"] | undefined,
	delete?: ModelTypes["BannersFields_location_time_Delete"] | undefined
};
	["BannersFields_location_time_Create"]: {
		permission: boolean
};
	["BannersFields_location_time_Read"]: {
		permission: boolean
};
	["BannersFields_location_time_Update"]: {
		permission: boolean
};
	["BannersFields_location_time_Delete"]: {
		permission: boolean
};
	["BannersFields_location_district"]: {
		create?: ModelTypes["BannersFields_location_district_Create"] | undefined,
	read?: ModelTypes["BannersFields_location_district_Read"] | undefined,
	update?: ModelTypes["BannersFields_location_district_Update"] | undefined,
	delete?: ModelTypes["BannersFields_location_district_Delete"] | undefined
};
	["BannersFields_location_district_Create"]: {
		permission: boolean
};
	["BannersFields_location_district_Read"]: {
		permission: boolean
};
	["BannersFields_location_district_Update"]: {
		permission: boolean
};
	["BannersFields_location_district_Delete"]: {
		permission: boolean
};
	["BannersFields_location_audience"]: {
		create?: ModelTypes["BannersFields_location_audience_Create"] | undefined,
	read?: ModelTypes["BannersFields_location_audience_Read"] | undefined,
	update?: ModelTypes["BannersFields_location_audience_Update"] | undefined,
	delete?: ModelTypes["BannersFields_location_audience_Delete"] | undefined
};
	["BannersFields_location_audience_Create"]: {
		permission: boolean
};
	["BannersFields_location_audience_Read"]: {
		permission: boolean
};
	["BannersFields_location_audience_Update"]: {
		permission: boolean
};
	["BannersFields_location_audience_Delete"]: {
		permission: boolean
};
	["BannersFields_location_multipleCriteriaSearch"]: {
		create?: ModelTypes["BannersFields_location_multipleCriteriaSearch_Create"] | undefined,
	read?: ModelTypes["BannersFields_location_multipleCriteriaSearch_Read"] | undefined,
	update?: ModelTypes["BannersFields_location_multipleCriteriaSearch_Update"] | undefined,
	delete?: ModelTypes["BannersFields_location_multipleCriteriaSearch_Delete"] | undefined
};
	["BannersFields_location_multipleCriteriaSearch_Create"]: {
		permission: boolean
};
	["BannersFields_location_multipleCriteriaSearch_Read"]: {
		permission: boolean
};
	["BannersFields_location_multipleCriteriaSearch_Update"]: {
		permission: boolean
};
	["BannersFields_location_multipleCriteriaSearch_Delete"]: {
		permission: boolean
};
	["BannersFields_link"]: {
		create?: ModelTypes["BannersFields_link_Create"] | undefined,
	read?: ModelTypes["BannersFields_link_Read"] | undefined,
	update?: ModelTypes["BannersFields_link_Update"] | undefined,
	delete?: ModelTypes["BannersFields_link_Delete"] | undefined
};
	["BannersFields_link_Create"]: {
		permission: boolean
};
	["BannersFields_link_Read"]: {
		permission: boolean
};
	["BannersFields_link_Update"]: {
		permission: boolean
};
	["BannersFields_link_Delete"]: {
		permission: boolean
};
	["BannersFields_external"]: {
		create?: ModelTypes["BannersFields_external_Create"] | undefined,
	read?: ModelTypes["BannersFields_external_Read"] | undefined,
	update?: ModelTypes["BannersFields_external_Update"] | undefined,
	delete?: ModelTypes["BannersFields_external_Delete"] | undefined
};
	["BannersFields_external_Create"]: {
		permission: boolean
};
	["BannersFields_external_Read"]: {
		permission: boolean
};
	["BannersFields_external_Update"]: {
		permission: boolean
};
	["BannersFields_external_Delete"]: {
		permission: boolean
};
	["BannersFields_type"]: {
		create?: ModelTypes["BannersFields_type_Create"] | undefined,
	read?: ModelTypes["BannersFields_type_Read"] | undefined,
	update?: ModelTypes["BannersFields_type_Update"] | undefined,
	delete?: ModelTypes["BannersFields_type_Delete"] | undefined
};
	["BannersFields_type_Create"]: {
		permission: boolean
};
	["BannersFields_type_Read"]: {
		permission: boolean
};
	["BannersFields_type_Update"]: {
		permission: boolean
};
	["BannersFields_type_Delete"]: {
		permission: boolean
};
	["BannersFields_status"]: {
		create?: ModelTypes["BannersFields_status_Create"] | undefined,
	read?: ModelTypes["BannersFields_status_Read"] | undefined,
	update?: ModelTypes["BannersFields_status_Update"] | undefined,
	delete?: ModelTypes["BannersFields_status_Delete"] | undefined
};
	["BannersFields_status_Create"]: {
		permission: boolean
};
	["BannersFields_status_Read"]: {
		permission: boolean
};
	["BannersFields_status_Update"]: {
		permission: boolean
};
	["BannersFields_status_Delete"]: {
		permission: boolean
};
	["BannersFields_advertise"]: {
		create?: ModelTypes["BannersFields_advertise_Create"] | undefined,
	read?: ModelTypes["BannersFields_advertise_Read"] | undefined,
	update?: ModelTypes["BannersFields_advertise_Update"] | undefined,
	delete?: ModelTypes["BannersFields_advertise_Delete"] | undefined,
	fields?: ModelTypes["BannersFields_advertise_Fields"] | undefined
};
	["BannersFields_advertise_Create"]: {
		permission: boolean
};
	["BannersFields_advertise_Read"]: {
		permission: boolean
};
	["BannersFields_advertise_Update"]: {
		permission: boolean
};
	["BannersFields_advertise_Delete"]: {
		permission: boolean
};
	["BannersFields_advertise_Fields"]: {
		campaign?: ModelTypes["BannersFields_advertise_campaign"] | undefined,
	invoiceDescription?: ModelTypes["BannersFields_advertise_invoiceDescription"] | undefined,
	standardPrice?: ModelTypes["BannersFields_advertise_standardPrice"] | undefined,
	discount?: ModelTypes["BannersFields_advertise_discount"] | undefined,
	timableCost?: ModelTypes["BannersFields_advertise_timableCost"] | undefined,
	remark?: ModelTypes["BannersFields_advertise_remark"] | undefined,
	public?: ModelTypes["BannersFields_advertise_public"] | undefined
};
	["BannersFields_advertise_campaign"]: {
		create?: ModelTypes["BannersFields_advertise_campaign_Create"] | undefined,
	read?: ModelTypes["BannersFields_advertise_campaign_Read"] | undefined,
	update?: ModelTypes["BannersFields_advertise_campaign_Update"] | undefined,
	delete?: ModelTypes["BannersFields_advertise_campaign_Delete"] | undefined
};
	["BannersFields_advertise_campaign_Create"]: {
		permission: boolean
};
	["BannersFields_advertise_campaign_Read"]: {
		permission: boolean
};
	["BannersFields_advertise_campaign_Update"]: {
		permission: boolean
};
	["BannersFields_advertise_campaign_Delete"]: {
		permission: boolean
};
	["BannersFields_advertise_invoiceDescription"]: {
		create?: ModelTypes["BannersFields_advertise_invoiceDescription_Create"] | undefined,
	read?: ModelTypes["BannersFields_advertise_invoiceDescription_Read"] | undefined,
	update?: ModelTypes["BannersFields_advertise_invoiceDescription_Update"] | undefined,
	delete?: ModelTypes["BannersFields_advertise_invoiceDescription_Delete"] | undefined
};
	["BannersFields_advertise_invoiceDescription_Create"]: {
		permission: boolean
};
	["BannersFields_advertise_invoiceDescription_Read"]: {
		permission: boolean
};
	["BannersFields_advertise_invoiceDescription_Update"]: {
		permission: boolean
};
	["BannersFields_advertise_invoiceDescription_Delete"]: {
		permission: boolean
};
	["BannersFields_advertise_standardPrice"]: {
		create?: ModelTypes["BannersFields_advertise_standardPrice_Create"] | undefined,
	read?: ModelTypes["BannersFields_advertise_standardPrice_Read"] | undefined,
	update?: ModelTypes["BannersFields_advertise_standardPrice_Update"] | undefined,
	delete?: ModelTypes["BannersFields_advertise_standardPrice_Delete"] | undefined
};
	["BannersFields_advertise_standardPrice_Create"]: {
		permission: boolean
};
	["BannersFields_advertise_standardPrice_Read"]: {
		permission: boolean
};
	["BannersFields_advertise_standardPrice_Update"]: {
		permission: boolean
};
	["BannersFields_advertise_standardPrice_Delete"]: {
		permission: boolean
};
	["BannersFields_advertise_discount"]: {
		create?: ModelTypes["BannersFields_advertise_discount_Create"] | undefined,
	read?: ModelTypes["BannersFields_advertise_discount_Read"] | undefined,
	update?: ModelTypes["BannersFields_advertise_discount_Update"] | undefined,
	delete?: ModelTypes["BannersFields_advertise_discount_Delete"] | undefined
};
	["BannersFields_advertise_discount_Create"]: {
		permission: boolean
};
	["BannersFields_advertise_discount_Read"]: {
		permission: boolean
};
	["BannersFields_advertise_discount_Update"]: {
		permission: boolean
};
	["BannersFields_advertise_discount_Delete"]: {
		permission: boolean
};
	["BannersFields_advertise_timableCost"]: {
		create?: ModelTypes["BannersFields_advertise_timableCost_Create"] | undefined,
	read?: ModelTypes["BannersFields_advertise_timableCost_Read"] | undefined,
	update?: ModelTypes["BannersFields_advertise_timableCost_Update"] | undefined,
	delete?: ModelTypes["BannersFields_advertise_timableCost_Delete"] | undefined
};
	["BannersFields_advertise_timableCost_Create"]: {
		permission: boolean
};
	["BannersFields_advertise_timableCost_Read"]: {
		permission: boolean
};
	["BannersFields_advertise_timableCost_Update"]: {
		permission: boolean
};
	["BannersFields_advertise_timableCost_Delete"]: {
		permission: boolean
};
	["BannersFields_advertise_remark"]: {
		create?: ModelTypes["BannersFields_advertise_remark_Create"] | undefined,
	read?: ModelTypes["BannersFields_advertise_remark_Read"] | undefined,
	update?: ModelTypes["BannersFields_advertise_remark_Update"] | undefined,
	delete?: ModelTypes["BannersFields_advertise_remark_Delete"] | undefined
};
	["BannersFields_advertise_remark_Create"]: {
		permission: boolean
};
	["BannersFields_advertise_remark_Read"]: {
		permission: boolean
};
	["BannersFields_advertise_remark_Update"]: {
		permission: boolean
};
	["BannersFields_advertise_remark_Delete"]: {
		permission: boolean
};
	["BannersFields_advertise_public"]: {
		create?: ModelTypes["BannersFields_advertise_public_Create"] | undefined,
	read?: ModelTypes["BannersFields_advertise_public_Read"] | undefined,
	update?: ModelTypes["BannersFields_advertise_public_Update"] | undefined,
	delete?: ModelTypes["BannersFields_advertise_public_Delete"] | undefined
};
	["BannersFields_advertise_public_Create"]: {
		permission: boolean
};
	["BannersFields_advertise_public_Read"]: {
		permission: boolean
};
	["BannersFields_advertise_public_Update"]: {
		permission: boolean
};
	["BannersFields_advertise_public_Delete"]: {
		permission: boolean
};
	["BannersFields__locations"]: {
		create?: ModelTypes["BannersFields__locations_Create"] | undefined,
	read?: ModelTypes["BannersFields__locations_Read"] | undefined,
	update?: ModelTypes["BannersFields__locations_Update"] | undefined,
	delete?: ModelTypes["BannersFields__locations_Delete"] | undefined
};
	["BannersFields__locations_Create"]: {
		permission: boolean
};
	["BannersFields__locations_Read"]: {
		permission: boolean
};
	["BannersFields__locations_Update"]: {
		permission: boolean
};
	["BannersFields__locations_Delete"]: {
		permission: boolean
};
	["BannersFields_region"]: {
		create?: ModelTypes["BannersFields_region_Create"] | undefined,
	read?: ModelTypes["BannersFields_region_Read"] | undefined,
	update?: ModelTypes["BannersFields_region_Update"] | undefined,
	delete?: ModelTypes["BannersFields_region_Delete"] | undefined
};
	["BannersFields_region_Create"]: {
		permission: boolean
};
	["BannersFields_region_Read"]: {
		permission: boolean
};
	["BannersFields_region_Update"]: {
		permission: boolean
};
	["BannersFields_region_Delete"]: {
		permission: boolean
};
	["BannersFields_homePageClick"]: {
		create?: ModelTypes["BannersFields_homePageClick_Create"] | undefined,
	read?: ModelTypes["BannersFields_homePageClick_Read"] | undefined,
	update?: ModelTypes["BannersFields_homePageClick_Update"] | undefined,
	delete?: ModelTypes["BannersFields_homePageClick_Delete"] | undefined
};
	["BannersFields_homePageClick_Create"]: {
		permission: boolean
};
	["BannersFields_homePageClick_Read"]: {
		permission: boolean
};
	["BannersFields_homePageClick_Update"]: {
		permission: boolean
};
	["BannersFields_homePageClick_Delete"]: {
		permission: boolean
};
	["BannersFields_homePageImpression"]: {
		create?: ModelTypes["BannersFields_homePageImpression_Create"] | undefined,
	read?: ModelTypes["BannersFields_homePageImpression_Read"] | undefined,
	update?: ModelTypes["BannersFields_homePageImpression_Update"] | undefined,
	delete?: ModelTypes["BannersFields_homePageImpression_Delete"] | undefined
};
	["BannersFields_homePageImpression_Create"]: {
		permission: boolean
};
	["BannersFields_homePageImpression_Read"]: {
		permission: boolean
};
	["BannersFields_homePageImpression_Update"]: {
		permission: boolean
};
	["BannersFields_homePageImpression_Delete"]: {
		permission: boolean
};
	["BannersFields_latestEventClick"]: {
		create?: ModelTypes["BannersFields_latestEventClick_Create"] | undefined,
	read?: ModelTypes["BannersFields_latestEventClick_Read"] | undefined,
	update?: ModelTypes["BannersFields_latestEventClick_Update"] | undefined,
	delete?: ModelTypes["BannersFields_latestEventClick_Delete"] | undefined
};
	["BannersFields_latestEventClick_Create"]: {
		permission: boolean
};
	["BannersFields_latestEventClick_Read"]: {
		permission: boolean
};
	["BannersFields_latestEventClick_Update"]: {
		permission: boolean
};
	["BannersFields_latestEventClick_Delete"]: {
		permission: boolean
};
	["BannersFields_latestEventImpression"]: {
		create?: ModelTypes["BannersFields_latestEventImpression_Create"] | undefined,
	read?: ModelTypes["BannersFields_latestEventImpression_Read"] | undefined,
	update?: ModelTypes["BannersFields_latestEventImpression_Update"] | undefined,
	delete?: ModelTypes["BannersFields_latestEventImpression_Delete"] | undefined
};
	["BannersFields_latestEventImpression_Create"]: {
		permission: boolean
};
	["BannersFields_latestEventImpression_Read"]: {
		permission: boolean
};
	["BannersFields_latestEventImpression_Update"]: {
		permission: boolean
};
	["BannersFields_latestEventImpression_Delete"]: {
		permission: boolean
};
	["BannersFields_editorChoiceClick"]: {
		create?: ModelTypes["BannersFields_editorChoiceClick_Create"] | undefined,
	read?: ModelTypes["BannersFields_editorChoiceClick_Read"] | undefined,
	update?: ModelTypes["BannersFields_editorChoiceClick_Update"] | undefined,
	delete?: ModelTypes["BannersFields_editorChoiceClick_Delete"] | undefined
};
	["BannersFields_editorChoiceClick_Create"]: {
		permission: boolean
};
	["BannersFields_editorChoiceClick_Read"]: {
		permission: boolean
};
	["BannersFields_editorChoiceClick_Update"]: {
		permission: boolean
};
	["BannersFields_editorChoiceClick_Delete"]: {
		permission: boolean
};
	["BannersFields_editorChoiceImpression"]: {
		create?: ModelTypes["BannersFields_editorChoiceImpression_Create"] | undefined,
	read?: ModelTypes["BannersFields_editorChoiceImpression_Read"] | undefined,
	update?: ModelTypes["BannersFields_editorChoiceImpression_Update"] | undefined,
	delete?: ModelTypes["BannersFields_editorChoiceImpression_Delete"] | undefined
};
	["BannersFields_editorChoiceImpression_Create"]: {
		permission: boolean
};
	["BannersFields_editorChoiceImpression_Read"]: {
		permission: boolean
};
	["BannersFields_editorChoiceImpression_Update"]: {
		permission: boolean
};
	["BannersFields_editorChoiceImpression_Delete"]: {
		permission: boolean
};
	["BannersFields_eventListClick"]: {
		create?: ModelTypes["BannersFields_eventListClick_Create"] | undefined,
	read?: ModelTypes["BannersFields_eventListClick_Read"] | undefined,
	update?: ModelTypes["BannersFields_eventListClick_Update"] | undefined,
	delete?: ModelTypes["BannersFields_eventListClick_Delete"] | undefined
};
	["BannersFields_eventListClick_Create"]: {
		permission: boolean
};
	["BannersFields_eventListClick_Read"]: {
		permission: boolean
};
	["BannersFields_eventListClick_Update"]: {
		permission: boolean
};
	["BannersFields_eventListClick_Delete"]: {
		permission: boolean
};
	["BannersFields_eventListImpression"]: {
		create?: ModelTypes["BannersFields_eventListImpression_Create"] | undefined,
	read?: ModelTypes["BannersFields_eventListImpression_Read"] | undefined,
	update?: ModelTypes["BannersFields_eventListImpression_Update"] | undefined,
	delete?: ModelTypes["BannersFields_eventListImpression_Delete"] | undefined
};
	["BannersFields_eventListImpression_Create"]: {
		permission: boolean
};
	["BannersFields_eventListImpression_Read"]: {
		permission: boolean
};
	["BannersFields_eventListImpression_Update"]: {
		permission: boolean
};
	["BannersFields_eventListImpression_Delete"]: {
		permission: boolean
};
	["BannersFields_topTenClick"]: {
		create?: ModelTypes["BannersFields_topTenClick_Create"] | undefined,
	read?: ModelTypes["BannersFields_topTenClick_Read"] | undefined,
	update?: ModelTypes["BannersFields_topTenClick_Update"] | undefined,
	delete?: ModelTypes["BannersFields_topTenClick_Delete"] | undefined
};
	["BannersFields_topTenClick_Create"]: {
		permission: boolean
};
	["BannersFields_topTenClick_Read"]: {
		permission: boolean
};
	["BannersFields_topTenClick_Update"]: {
		permission: boolean
};
	["BannersFields_topTenClick_Delete"]: {
		permission: boolean
};
	["BannersFields_topTenImpression"]: {
		create?: ModelTypes["BannersFields_topTenImpression_Create"] | undefined,
	read?: ModelTypes["BannersFields_topTenImpression_Read"] | undefined,
	update?: ModelTypes["BannersFields_topTenImpression_Update"] | undefined,
	delete?: ModelTypes["BannersFields_topTenImpression_Delete"] | undefined
};
	["BannersFields_topTenImpression_Create"]: {
		permission: boolean
};
	["BannersFields_topTenImpression_Read"]: {
		permission: boolean
};
	["BannersFields_topTenImpression_Update"]: {
		permission: boolean
};
	["BannersFields_topTenImpression_Delete"]: {
		permission: boolean
};
	["BannersFields_miscClick"]: {
		create?: ModelTypes["BannersFields_miscClick_Create"] | undefined,
	read?: ModelTypes["BannersFields_miscClick_Read"] | undefined,
	update?: ModelTypes["BannersFields_miscClick_Update"] | undefined,
	delete?: ModelTypes["BannersFields_miscClick_Delete"] | undefined
};
	["BannersFields_miscClick_Create"]: {
		permission: boolean
};
	["BannersFields_miscClick_Read"]: {
		permission: boolean
};
	["BannersFields_miscClick_Update"]: {
		permission: boolean
};
	["BannersFields_miscClick_Delete"]: {
		permission: boolean
};
	["BannersFields_miscImpression"]: {
		create?: ModelTypes["BannersFields_miscImpression_Create"] | undefined,
	read?: ModelTypes["BannersFields_miscImpression_Read"] | undefined,
	update?: ModelTypes["BannersFields_miscImpression_Update"] | undefined,
	delete?: ModelTypes["BannersFields_miscImpression_Delete"] | undefined
};
	["BannersFields_miscImpression_Create"]: {
		permission: boolean
};
	["BannersFields_miscImpression_Read"]: {
		permission: boolean
};
	["BannersFields_miscImpression_Update"]: {
		permission: boolean
};
	["BannersFields_miscImpression_Delete"]: {
		permission: boolean
};
	["BannersFields_clickTotal"]: {
		create?: ModelTypes["BannersFields_clickTotal_Create"] | undefined,
	read?: ModelTypes["BannersFields_clickTotal_Read"] | undefined,
	update?: ModelTypes["BannersFields_clickTotal_Update"] | undefined,
	delete?: ModelTypes["BannersFields_clickTotal_Delete"] | undefined
};
	["BannersFields_clickTotal_Create"]: {
		permission: boolean
};
	["BannersFields_clickTotal_Read"]: {
		permission: boolean
};
	["BannersFields_clickTotal_Update"]: {
		permission: boolean
};
	["BannersFields_clickTotal_Delete"]: {
		permission: boolean
};
	["BannersFields_totalImpression"]: {
		create?: ModelTypes["BannersFields_totalImpression_Create"] | undefined,
	read?: ModelTypes["BannersFields_totalImpression_Read"] | undefined,
	update?: ModelTypes["BannersFields_totalImpression_Update"] | undefined,
	delete?: ModelTypes["BannersFields_totalImpression_Delete"] | undefined
};
	["BannersFields_totalImpression_Create"]: {
		permission: boolean
};
	["BannersFields_totalImpression_Read"]: {
		permission: boolean
};
	["BannersFields_totalImpression_Update"]: {
		permission: boolean
};
	["BannersFields_totalImpression_Delete"]: {
		permission: boolean
};
	["BannersFields_updatedAt"]: {
		create?: ModelTypes["BannersFields_updatedAt_Create"] | undefined,
	read?: ModelTypes["BannersFields_updatedAt_Read"] | undefined,
	update?: ModelTypes["BannersFields_updatedAt_Update"] | undefined,
	delete?: ModelTypes["BannersFields_updatedAt_Delete"] | undefined
};
	["BannersFields_updatedAt_Create"]: {
		permission: boolean
};
	["BannersFields_updatedAt_Read"]: {
		permission: boolean
};
	["BannersFields_updatedAt_Update"]: {
		permission: boolean
};
	["BannersFields_updatedAt_Delete"]: {
		permission: boolean
};
	["BannersFields_createdAt"]: {
		create?: ModelTypes["BannersFields_createdAt_Create"] | undefined,
	read?: ModelTypes["BannersFields_createdAt_Read"] | undefined,
	update?: ModelTypes["BannersFields_createdAt_Update"] | undefined,
	delete?: ModelTypes["BannersFields_createdAt_Delete"] | undefined
};
	["BannersFields_createdAt_Create"]: {
		permission: boolean
};
	["BannersFields_createdAt_Read"]: {
		permission: boolean
};
	["BannersFields_createdAt_Update"]: {
		permission: boolean
};
	["BannersFields_createdAt_Delete"]: {
		permission: boolean
};
	["BannersCreateAccess"]: {
		permission: boolean,
	where?: ModelTypes["JSONObject"] | undefined
};
	["BannersReadAccess"]: {
		permission: boolean,
	where?: ModelTypes["JSONObject"] | undefined
};
	["BannersUpdateAccess"]: {
		permission: boolean,
	where?: ModelTypes["JSONObject"] | undefined
};
	["BannersDeleteAccess"]: {
		permission: boolean,
	where?: ModelTypes["JSONObject"] | undefined
};
	["campaignsAccess"]: {
		fields?: ModelTypes["CampaignsFields"] | undefined,
	create?: ModelTypes["CampaignsCreateAccess"] | undefined,
	read?: ModelTypes["CampaignsReadAccess"] | undefined,
	update?: ModelTypes["CampaignsUpdateAccess"] | undefined,
	delete?: ModelTypes["CampaignsDeleteAccess"] | undefined
};
	["CampaignsFields"]: {
		creator?: ModelTypes["CampaignsFields_creator"] | undefined,
	invoiceNumber?: ModelTypes["CampaignsFields_invoiceNumber"] | undefined,
	quotationNumber?: ModelTypes["CampaignsFields_quotationNumber"] | undefined,
	campaignName?: ModelTypes["CampaignsFields_campaignName"] | undefined,
	client?: ModelTypes["CampaignsFields_client"] | undefined,
	contactPerson?: ModelTypes["CampaignsFields_contactPerson"] | undefined,
	email?: ModelTypes["CampaignsFields_email"] | undefined,
	phone?: ModelTypes["CampaignsFields_phone"] | undefined,
	agentName?: ModelTypes["CampaignsFields_agentName"] | undefined,
	agentCommission?: ModelTypes["CampaignsFields_agentCommission"] | undefined,
	agentOrderNumber?: ModelTypes["CampaignsFields_agentOrderNumber"] | undefined,
	_firstPublishedTime?: ModelTypes["CampaignsFields__firstPublishedTime"] | undefined,
	updatedAt?: ModelTypes["CampaignsFields_updatedAt"] | undefined,
	createdAt?: ModelTypes["CampaignsFields_createdAt"] | undefined
};
	["CampaignsFields_creator"]: {
		create?: ModelTypes["CampaignsFields_creator_Create"] | undefined,
	read?: ModelTypes["CampaignsFields_creator_Read"] | undefined,
	update?: ModelTypes["CampaignsFields_creator_Update"] | undefined,
	delete?: ModelTypes["CampaignsFields_creator_Delete"] | undefined
};
	["CampaignsFields_creator_Create"]: {
		permission: boolean
};
	["CampaignsFields_creator_Read"]: {
		permission: boolean
};
	["CampaignsFields_creator_Update"]: {
		permission: boolean
};
	["CampaignsFields_creator_Delete"]: {
		permission: boolean
};
	["CampaignsFields_invoiceNumber"]: {
		create?: ModelTypes["CampaignsFields_invoiceNumber_Create"] | undefined,
	read?: ModelTypes["CampaignsFields_invoiceNumber_Read"] | undefined,
	update?: ModelTypes["CampaignsFields_invoiceNumber_Update"] | undefined,
	delete?: ModelTypes["CampaignsFields_invoiceNumber_Delete"] | undefined
};
	["CampaignsFields_invoiceNumber_Create"]: {
		permission: boolean
};
	["CampaignsFields_invoiceNumber_Read"]: {
		permission: boolean
};
	["CampaignsFields_invoiceNumber_Update"]: {
		permission: boolean
};
	["CampaignsFields_invoiceNumber_Delete"]: {
		permission: boolean
};
	["CampaignsFields_quotationNumber"]: {
		create?: ModelTypes["CampaignsFields_quotationNumber_Create"] | undefined,
	read?: ModelTypes["CampaignsFields_quotationNumber_Read"] | undefined,
	update?: ModelTypes["CampaignsFields_quotationNumber_Update"] | undefined,
	delete?: ModelTypes["CampaignsFields_quotationNumber_Delete"] | undefined
};
	["CampaignsFields_quotationNumber_Create"]: {
		permission: boolean
};
	["CampaignsFields_quotationNumber_Read"]: {
		permission: boolean
};
	["CampaignsFields_quotationNumber_Update"]: {
		permission: boolean
};
	["CampaignsFields_quotationNumber_Delete"]: {
		permission: boolean
};
	["CampaignsFields_campaignName"]: {
		create?: ModelTypes["CampaignsFields_campaignName_Create"] | undefined,
	read?: ModelTypes["CampaignsFields_campaignName_Read"] | undefined,
	update?: ModelTypes["CampaignsFields_campaignName_Update"] | undefined,
	delete?: ModelTypes["CampaignsFields_campaignName_Delete"] | undefined
};
	["CampaignsFields_campaignName_Create"]: {
		permission: boolean
};
	["CampaignsFields_campaignName_Read"]: {
		permission: boolean
};
	["CampaignsFields_campaignName_Update"]: {
		permission: boolean
};
	["CampaignsFields_campaignName_Delete"]: {
		permission: boolean
};
	["CampaignsFields_client"]: {
		create?: ModelTypes["CampaignsFields_client_Create"] | undefined,
	read?: ModelTypes["CampaignsFields_client_Read"] | undefined,
	update?: ModelTypes["CampaignsFields_client_Update"] | undefined,
	delete?: ModelTypes["CampaignsFields_client_Delete"] | undefined
};
	["CampaignsFields_client_Create"]: {
		permission: boolean
};
	["CampaignsFields_client_Read"]: {
		permission: boolean
};
	["CampaignsFields_client_Update"]: {
		permission: boolean
};
	["CampaignsFields_client_Delete"]: {
		permission: boolean
};
	["CampaignsFields_contactPerson"]: {
		create?: ModelTypes["CampaignsFields_contactPerson_Create"] | undefined,
	read?: ModelTypes["CampaignsFields_contactPerson_Read"] | undefined,
	update?: ModelTypes["CampaignsFields_contactPerson_Update"] | undefined,
	delete?: ModelTypes["CampaignsFields_contactPerson_Delete"] | undefined
};
	["CampaignsFields_contactPerson_Create"]: {
		permission: boolean
};
	["CampaignsFields_contactPerson_Read"]: {
		permission: boolean
};
	["CampaignsFields_contactPerson_Update"]: {
		permission: boolean
};
	["CampaignsFields_contactPerson_Delete"]: {
		permission: boolean
};
	["CampaignsFields_email"]: {
		create?: ModelTypes["CampaignsFields_email_Create"] | undefined,
	read?: ModelTypes["CampaignsFields_email_Read"] | undefined,
	update?: ModelTypes["CampaignsFields_email_Update"] | undefined,
	delete?: ModelTypes["CampaignsFields_email_Delete"] | undefined
};
	["CampaignsFields_email_Create"]: {
		permission: boolean
};
	["CampaignsFields_email_Read"]: {
		permission: boolean
};
	["CampaignsFields_email_Update"]: {
		permission: boolean
};
	["CampaignsFields_email_Delete"]: {
		permission: boolean
};
	["CampaignsFields_phone"]: {
		create?: ModelTypes["CampaignsFields_phone_Create"] | undefined,
	read?: ModelTypes["CampaignsFields_phone_Read"] | undefined,
	update?: ModelTypes["CampaignsFields_phone_Update"] | undefined,
	delete?: ModelTypes["CampaignsFields_phone_Delete"] | undefined
};
	["CampaignsFields_phone_Create"]: {
		permission: boolean
};
	["CampaignsFields_phone_Read"]: {
		permission: boolean
};
	["CampaignsFields_phone_Update"]: {
		permission: boolean
};
	["CampaignsFields_phone_Delete"]: {
		permission: boolean
};
	["CampaignsFields_agentName"]: {
		create?: ModelTypes["CampaignsFields_agentName_Create"] | undefined,
	read?: ModelTypes["CampaignsFields_agentName_Read"] | undefined,
	update?: ModelTypes["CampaignsFields_agentName_Update"] | undefined,
	delete?: ModelTypes["CampaignsFields_agentName_Delete"] | undefined
};
	["CampaignsFields_agentName_Create"]: {
		permission: boolean
};
	["CampaignsFields_agentName_Read"]: {
		permission: boolean
};
	["CampaignsFields_agentName_Update"]: {
		permission: boolean
};
	["CampaignsFields_agentName_Delete"]: {
		permission: boolean
};
	["CampaignsFields_agentCommission"]: {
		create?: ModelTypes["CampaignsFields_agentCommission_Create"] | undefined,
	read?: ModelTypes["CampaignsFields_agentCommission_Read"] | undefined,
	update?: ModelTypes["CampaignsFields_agentCommission_Update"] | undefined,
	delete?: ModelTypes["CampaignsFields_agentCommission_Delete"] | undefined
};
	["CampaignsFields_agentCommission_Create"]: {
		permission: boolean
};
	["CampaignsFields_agentCommission_Read"]: {
		permission: boolean
};
	["CampaignsFields_agentCommission_Update"]: {
		permission: boolean
};
	["CampaignsFields_agentCommission_Delete"]: {
		permission: boolean
};
	["CampaignsFields_agentOrderNumber"]: {
		create?: ModelTypes["CampaignsFields_agentOrderNumber_Create"] | undefined,
	read?: ModelTypes["CampaignsFields_agentOrderNumber_Read"] | undefined,
	update?: ModelTypes["CampaignsFields_agentOrderNumber_Update"] | undefined,
	delete?: ModelTypes["CampaignsFields_agentOrderNumber_Delete"] | undefined
};
	["CampaignsFields_agentOrderNumber_Create"]: {
		permission: boolean
};
	["CampaignsFields_agentOrderNumber_Read"]: {
		permission: boolean
};
	["CampaignsFields_agentOrderNumber_Update"]: {
		permission: boolean
};
	["CampaignsFields_agentOrderNumber_Delete"]: {
		permission: boolean
};
	["CampaignsFields__firstPublishedTime"]: {
		create?: ModelTypes["CampaignsFields__firstPublishedTime_Create"] | undefined,
	read?: ModelTypes["CampaignsFields__firstPublishedTime_Read"] | undefined,
	update?: ModelTypes["CampaignsFields__firstPublishedTime_Update"] | undefined,
	delete?: ModelTypes["CampaignsFields__firstPublishedTime_Delete"] | undefined
};
	["CampaignsFields__firstPublishedTime_Create"]: {
		permission: boolean
};
	["CampaignsFields__firstPublishedTime_Read"]: {
		permission: boolean
};
	["CampaignsFields__firstPublishedTime_Update"]: {
		permission: boolean
};
	["CampaignsFields__firstPublishedTime_Delete"]: {
		permission: boolean
};
	["CampaignsFields_updatedAt"]: {
		create?: ModelTypes["CampaignsFields_updatedAt_Create"] | undefined,
	read?: ModelTypes["CampaignsFields_updatedAt_Read"] | undefined,
	update?: ModelTypes["CampaignsFields_updatedAt_Update"] | undefined,
	delete?: ModelTypes["CampaignsFields_updatedAt_Delete"] | undefined
};
	["CampaignsFields_updatedAt_Create"]: {
		permission: boolean
};
	["CampaignsFields_updatedAt_Read"]: {
		permission: boolean
};
	["CampaignsFields_updatedAt_Update"]: {
		permission: boolean
};
	["CampaignsFields_updatedAt_Delete"]: {
		permission: boolean
};
	["CampaignsFields_createdAt"]: {
		create?: ModelTypes["CampaignsFields_createdAt_Create"] | undefined,
	read?: ModelTypes["CampaignsFields_createdAt_Read"] | undefined,
	update?: ModelTypes["CampaignsFields_createdAt_Update"] | undefined,
	delete?: ModelTypes["CampaignsFields_createdAt_Delete"] | undefined
};
	["CampaignsFields_createdAt_Create"]: {
		permission: boolean
};
	["CampaignsFields_createdAt_Read"]: {
		permission: boolean
};
	["CampaignsFields_createdAt_Update"]: {
		permission: boolean
};
	["CampaignsFields_createdAt_Delete"]: {
		permission: boolean
};
	["CampaignsCreateAccess"]: {
		permission: boolean,
	where?: ModelTypes["JSONObject"] | undefined
};
	["CampaignsReadAccess"]: {
		permission: boolean,
	where?: ModelTypes["JSONObject"] | undefined
};
	["CampaignsUpdateAccess"]: {
		permission: boolean,
	where?: ModelTypes["JSONObject"] | undefined
};
	["CampaignsDeleteAccess"]: {
		permission: boolean,
	where?: ModelTypes["JSONObject"] | undefined
};
	["advertisesAccess"]: {
		fields?: ModelTypes["AdvertisesFields"] | undefined,
	create?: ModelTypes["AdvertisesCreateAccess"] | undefined,
	read?: ModelTypes["AdvertisesReadAccess"] | undefined,
	update?: ModelTypes["AdvertisesUpdateAccess"] | undefined,
	delete?: ModelTypes["AdvertisesDeleteAccess"] | undefined
};
	["AdvertisesFields"]: {
		advertise?: ModelTypes["AdvertisesFields_advertise"] | undefined,
	advertiseStartDate?: ModelTypes["AdvertisesFields_advertiseStartDate"] | undefined,
	advertiseEndDate?: ModelTypes["AdvertisesFields_advertiseEndDate"] | undefined,
	type?: ModelTypes["AdvertisesFields_type"] | undefined,
	invoiceDescription?: ModelTypes["AdvertisesFields_invoiceDescription"] | undefined,
	standardPrice?: ModelTypes["AdvertisesFields_standardPrice"] | undefined,
	discount?: ModelTypes["AdvertisesFields_discount"] | undefined,
	discountPercentage?: ModelTypes["AdvertisesFields_discountPercentage"] | undefined,
	_calculateByDiscount?: ModelTypes["AdvertisesFields__calculateByDiscount"] | undefined,
	timableCost?: ModelTypes["AdvertisesFields_timableCost"] | undefined,
	remark?: ModelTypes["AdvertisesFields_remark"] | undefined,
	public?: ModelTypes["AdvertisesFields_public"] | undefined,
	campaignName?: ModelTypes["AdvertisesFields_campaignName"] | undefined,
	campaignId?: ModelTypes["AdvertisesFields_campaignId"] | undefined,
	status?: ModelTypes["AdvertisesFields_status"] | undefined,
	updatedAt?: ModelTypes["AdvertisesFields_updatedAt"] | undefined,
	createdAt?: ModelTypes["AdvertisesFields_createdAt"] | undefined
};
	["AdvertisesFields_advertise"]: {
		create?: ModelTypes["AdvertisesFields_advertise_Create"] | undefined,
	read?: ModelTypes["AdvertisesFields_advertise_Read"] | undefined,
	update?: ModelTypes["AdvertisesFields_advertise_Update"] | undefined,
	delete?: ModelTypes["AdvertisesFields_advertise_Delete"] | undefined,
	fields?: ModelTypes["AdvertisesFields_advertise_Fields"] | undefined
};
	["AdvertisesFields_advertise_Create"]: {
		permission: boolean
};
	["AdvertisesFields_advertise_Read"]: {
		permission: boolean
};
	["AdvertisesFields_advertise_Update"]: {
		permission: boolean
};
	["AdvertisesFields_advertise_Delete"]: {
		permission: boolean
};
	["AdvertisesFields_advertise_Fields"]: {
		type?: ModelTypes["AdvertisesFields_advertise_type"] | undefined,
	event?: ModelTypes["AdvertisesFields_advertise_event"] | undefined,
	keyword?: ModelTypes["AdvertisesFields_advertise_keyword"] | undefined,
	location?: ModelTypes["AdvertisesFields_advertise_location"] | undefined,
	displayPosition?: ModelTypes["AdvertisesFields_advertise_displayPosition"] | undefined,
	rankingListRemark?: ModelTypes["AdvertisesFields_advertise_rankingListRemark"] | undefined
};
	["AdvertisesFields_advertise_type"]: {
		create?: ModelTypes["AdvertisesFields_advertise_type_Create"] | undefined,
	read?: ModelTypes["AdvertisesFields_advertise_type_Read"] | undefined,
	update?: ModelTypes["AdvertisesFields_advertise_type_Update"] | undefined,
	delete?: ModelTypes["AdvertisesFields_advertise_type_Delete"] | undefined
};
	["AdvertisesFields_advertise_type_Create"]: {
		permission: boolean
};
	["AdvertisesFields_advertise_type_Read"]: {
		permission: boolean
};
	["AdvertisesFields_advertise_type_Update"]: {
		permission: boolean
};
	["AdvertisesFields_advertise_type_Delete"]: {
		permission: boolean
};
	["AdvertisesFields_advertise_event"]: {
		create?: ModelTypes["AdvertisesFields_advertise_event_Create"] | undefined,
	read?: ModelTypes["AdvertisesFields_advertise_event_Read"] | undefined,
	update?: ModelTypes["AdvertisesFields_advertise_event_Update"] | undefined,
	delete?: ModelTypes["AdvertisesFields_advertise_event_Delete"] | undefined
};
	["AdvertisesFields_advertise_event_Create"]: {
		permission: boolean
};
	["AdvertisesFields_advertise_event_Read"]: {
		permission: boolean
};
	["AdvertisesFields_advertise_event_Update"]: {
		permission: boolean
};
	["AdvertisesFields_advertise_event_Delete"]: {
		permission: boolean
};
	["AdvertisesFields_advertise_keyword"]: {
		create?: ModelTypes["AdvertisesFields_advertise_keyword_Create"] | undefined,
	read?: ModelTypes["AdvertisesFields_advertise_keyword_Read"] | undefined,
	update?: ModelTypes["AdvertisesFields_advertise_keyword_Update"] | undefined,
	delete?: ModelTypes["AdvertisesFields_advertise_keyword_Delete"] | undefined
};
	["AdvertisesFields_advertise_keyword_Create"]: {
		permission: boolean
};
	["AdvertisesFields_advertise_keyword_Read"]: {
		permission: boolean
};
	["AdvertisesFields_advertise_keyword_Update"]: {
		permission: boolean
};
	["AdvertisesFields_advertise_keyword_Delete"]: {
		permission: boolean
};
	["AdvertisesFields_advertise_location"]: {
		create?: ModelTypes["AdvertisesFields_advertise_location_Create"] | undefined,
	read?: ModelTypes["AdvertisesFields_advertise_location_Read"] | undefined,
	update?: ModelTypes["AdvertisesFields_advertise_location_Update"] | undefined,
	delete?: ModelTypes["AdvertisesFields_advertise_location_Delete"] | undefined
};
	["AdvertisesFields_advertise_location_Create"]: {
		permission: boolean
};
	["AdvertisesFields_advertise_location_Read"]: {
		permission: boolean
};
	["AdvertisesFields_advertise_location_Update"]: {
		permission: boolean
};
	["AdvertisesFields_advertise_location_Delete"]: {
		permission: boolean
};
	["AdvertisesFields_advertise_displayPosition"]: {
		create?: ModelTypes["AdvertisesFields_advertise_displayPosition_Create"] | undefined,
	read?: ModelTypes["AdvertisesFields_advertise_displayPosition_Read"] | undefined,
	update?: ModelTypes["AdvertisesFields_advertise_displayPosition_Update"] | undefined,
	delete?: ModelTypes["AdvertisesFields_advertise_displayPosition_Delete"] | undefined
};
	["AdvertisesFields_advertise_displayPosition_Create"]: {
		permission: boolean
};
	["AdvertisesFields_advertise_displayPosition_Read"]: {
		permission: boolean
};
	["AdvertisesFields_advertise_displayPosition_Update"]: {
		permission: boolean
};
	["AdvertisesFields_advertise_displayPosition_Delete"]: {
		permission: boolean
};
	["AdvertisesFields_advertise_rankingListRemark"]: {
		create?: ModelTypes["AdvertisesFields_advertise_rankingListRemark_Create"] | undefined,
	read?: ModelTypes["AdvertisesFields_advertise_rankingListRemark_Read"] | undefined,
	update?: ModelTypes["AdvertisesFields_advertise_rankingListRemark_Update"] | undefined,
	delete?: ModelTypes["AdvertisesFields_advertise_rankingListRemark_Delete"] | undefined
};
	["AdvertisesFields_advertise_rankingListRemark_Create"]: {
		permission: boolean
};
	["AdvertisesFields_advertise_rankingListRemark_Read"]: {
		permission: boolean
};
	["AdvertisesFields_advertise_rankingListRemark_Update"]: {
		permission: boolean
};
	["AdvertisesFields_advertise_rankingListRemark_Delete"]: {
		permission: boolean
};
	["AdvertisesFields_advertiseStartDate"]: {
		create?: ModelTypes["AdvertisesFields_advertiseStartDate_Create"] | undefined,
	read?: ModelTypes["AdvertisesFields_advertiseStartDate_Read"] | undefined,
	update?: ModelTypes["AdvertisesFields_advertiseStartDate_Update"] | undefined,
	delete?: ModelTypes["AdvertisesFields_advertiseStartDate_Delete"] | undefined
};
	["AdvertisesFields_advertiseStartDate_Create"]: {
		permission: boolean
};
	["AdvertisesFields_advertiseStartDate_Read"]: {
		permission: boolean
};
	["AdvertisesFields_advertiseStartDate_Update"]: {
		permission: boolean
};
	["AdvertisesFields_advertiseStartDate_Delete"]: {
		permission: boolean
};
	["AdvertisesFields_advertiseEndDate"]: {
		create?: ModelTypes["AdvertisesFields_advertiseEndDate_Create"] | undefined,
	read?: ModelTypes["AdvertisesFields_advertiseEndDate_Read"] | undefined,
	update?: ModelTypes["AdvertisesFields_advertiseEndDate_Update"] | undefined,
	delete?: ModelTypes["AdvertisesFields_advertiseEndDate_Delete"] | undefined
};
	["AdvertisesFields_advertiseEndDate_Create"]: {
		permission: boolean
};
	["AdvertisesFields_advertiseEndDate_Read"]: {
		permission: boolean
};
	["AdvertisesFields_advertiseEndDate_Update"]: {
		permission: boolean
};
	["AdvertisesFields_advertiseEndDate_Delete"]: {
		permission: boolean
};
	["AdvertisesFields_type"]: {
		create?: ModelTypes["AdvertisesFields_type_Create"] | undefined,
	read?: ModelTypes["AdvertisesFields_type_Read"] | undefined,
	update?: ModelTypes["AdvertisesFields_type_Update"] | undefined,
	delete?: ModelTypes["AdvertisesFields_type_Delete"] | undefined
};
	["AdvertisesFields_type_Create"]: {
		permission: boolean
};
	["AdvertisesFields_type_Read"]: {
		permission: boolean
};
	["AdvertisesFields_type_Update"]: {
		permission: boolean
};
	["AdvertisesFields_type_Delete"]: {
		permission: boolean
};
	["AdvertisesFields_invoiceDescription"]: {
		create?: ModelTypes["AdvertisesFields_invoiceDescription_Create"] | undefined,
	read?: ModelTypes["AdvertisesFields_invoiceDescription_Read"] | undefined,
	update?: ModelTypes["AdvertisesFields_invoiceDescription_Update"] | undefined,
	delete?: ModelTypes["AdvertisesFields_invoiceDescription_Delete"] | undefined
};
	["AdvertisesFields_invoiceDescription_Create"]: {
		permission: boolean
};
	["AdvertisesFields_invoiceDescription_Read"]: {
		permission: boolean
};
	["AdvertisesFields_invoiceDescription_Update"]: {
		permission: boolean
};
	["AdvertisesFields_invoiceDescription_Delete"]: {
		permission: boolean
};
	["AdvertisesFields_standardPrice"]: {
		create?: ModelTypes["AdvertisesFields_standardPrice_Create"] | undefined,
	read?: ModelTypes["AdvertisesFields_standardPrice_Read"] | undefined,
	update?: ModelTypes["AdvertisesFields_standardPrice_Update"] | undefined,
	delete?: ModelTypes["AdvertisesFields_standardPrice_Delete"] | undefined
};
	["AdvertisesFields_standardPrice_Create"]: {
		permission: boolean
};
	["AdvertisesFields_standardPrice_Read"]: {
		permission: boolean
};
	["AdvertisesFields_standardPrice_Update"]: {
		permission: boolean
};
	["AdvertisesFields_standardPrice_Delete"]: {
		permission: boolean
};
	["AdvertisesFields_discount"]: {
		create?: ModelTypes["AdvertisesFields_discount_Create"] | undefined,
	read?: ModelTypes["AdvertisesFields_discount_Read"] | undefined,
	update?: ModelTypes["AdvertisesFields_discount_Update"] | undefined,
	delete?: ModelTypes["AdvertisesFields_discount_Delete"] | undefined
};
	["AdvertisesFields_discount_Create"]: {
		permission: boolean
};
	["AdvertisesFields_discount_Read"]: {
		permission: boolean
};
	["AdvertisesFields_discount_Update"]: {
		permission: boolean
};
	["AdvertisesFields_discount_Delete"]: {
		permission: boolean
};
	["AdvertisesFields_discountPercentage"]: {
		create?: ModelTypes["AdvertisesFields_discountPercentage_Create"] | undefined,
	read?: ModelTypes["AdvertisesFields_discountPercentage_Read"] | undefined,
	update?: ModelTypes["AdvertisesFields_discountPercentage_Update"] | undefined,
	delete?: ModelTypes["AdvertisesFields_discountPercentage_Delete"] | undefined
};
	["AdvertisesFields_discountPercentage_Create"]: {
		permission: boolean
};
	["AdvertisesFields_discountPercentage_Read"]: {
		permission: boolean
};
	["AdvertisesFields_discountPercentage_Update"]: {
		permission: boolean
};
	["AdvertisesFields_discountPercentage_Delete"]: {
		permission: boolean
};
	["AdvertisesFields__calculateByDiscount"]: {
		create?: ModelTypes["AdvertisesFields__calculateByDiscount_Create"] | undefined,
	read?: ModelTypes["AdvertisesFields__calculateByDiscount_Read"] | undefined,
	update?: ModelTypes["AdvertisesFields__calculateByDiscount_Update"] | undefined,
	delete?: ModelTypes["AdvertisesFields__calculateByDiscount_Delete"] | undefined
};
	["AdvertisesFields__calculateByDiscount_Create"]: {
		permission: boolean
};
	["AdvertisesFields__calculateByDiscount_Read"]: {
		permission: boolean
};
	["AdvertisesFields__calculateByDiscount_Update"]: {
		permission: boolean
};
	["AdvertisesFields__calculateByDiscount_Delete"]: {
		permission: boolean
};
	["AdvertisesFields_timableCost"]: {
		create?: ModelTypes["AdvertisesFields_timableCost_Create"] | undefined,
	read?: ModelTypes["AdvertisesFields_timableCost_Read"] | undefined,
	update?: ModelTypes["AdvertisesFields_timableCost_Update"] | undefined,
	delete?: ModelTypes["AdvertisesFields_timableCost_Delete"] | undefined
};
	["AdvertisesFields_timableCost_Create"]: {
		permission: boolean
};
	["AdvertisesFields_timableCost_Read"]: {
		permission: boolean
};
	["AdvertisesFields_timableCost_Update"]: {
		permission: boolean
};
	["AdvertisesFields_timableCost_Delete"]: {
		permission: boolean
};
	["AdvertisesFields_remark"]: {
		create?: ModelTypes["AdvertisesFields_remark_Create"] | undefined,
	read?: ModelTypes["AdvertisesFields_remark_Read"] | undefined,
	update?: ModelTypes["AdvertisesFields_remark_Update"] | undefined,
	delete?: ModelTypes["AdvertisesFields_remark_Delete"] | undefined
};
	["AdvertisesFields_remark_Create"]: {
		permission: boolean
};
	["AdvertisesFields_remark_Read"]: {
		permission: boolean
};
	["AdvertisesFields_remark_Update"]: {
		permission: boolean
};
	["AdvertisesFields_remark_Delete"]: {
		permission: boolean
};
	["AdvertisesFields_public"]: {
		create?: ModelTypes["AdvertisesFields_public_Create"] | undefined,
	read?: ModelTypes["AdvertisesFields_public_Read"] | undefined,
	update?: ModelTypes["AdvertisesFields_public_Update"] | undefined,
	delete?: ModelTypes["AdvertisesFields_public_Delete"] | undefined
};
	["AdvertisesFields_public_Create"]: {
		permission: boolean
};
	["AdvertisesFields_public_Read"]: {
		permission: boolean
};
	["AdvertisesFields_public_Update"]: {
		permission: boolean
};
	["AdvertisesFields_public_Delete"]: {
		permission: boolean
};
	["AdvertisesFields_campaignName"]: {
		create?: ModelTypes["AdvertisesFields_campaignName_Create"] | undefined,
	read?: ModelTypes["AdvertisesFields_campaignName_Read"] | undefined,
	update?: ModelTypes["AdvertisesFields_campaignName_Update"] | undefined,
	delete?: ModelTypes["AdvertisesFields_campaignName_Delete"] | undefined
};
	["AdvertisesFields_campaignName_Create"]: {
		permission: boolean
};
	["AdvertisesFields_campaignName_Read"]: {
		permission: boolean
};
	["AdvertisesFields_campaignName_Update"]: {
		permission: boolean
};
	["AdvertisesFields_campaignName_Delete"]: {
		permission: boolean
};
	["AdvertisesFields_campaignId"]: {
		create?: ModelTypes["AdvertisesFields_campaignId_Create"] | undefined,
	read?: ModelTypes["AdvertisesFields_campaignId_Read"] | undefined,
	update?: ModelTypes["AdvertisesFields_campaignId_Update"] | undefined,
	delete?: ModelTypes["AdvertisesFields_campaignId_Delete"] | undefined
};
	["AdvertisesFields_campaignId_Create"]: {
		permission: boolean
};
	["AdvertisesFields_campaignId_Read"]: {
		permission: boolean
};
	["AdvertisesFields_campaignId_Update"]: {
		permission: boolean
};
	["AdvertisesFields_campaignId_Delete"]: {
		permission: boolean
};
	["AdvertisesFields_status"]: {
		create?: ModelTypes["AdvertisesFields_status_Create"] | undefined,
	read?: ModelTypes["AdvertisesFields_status_Read"] | undefined,
	update?: ModelTypes["AdvertisesFields_status_Update"] | undefined,
	delete?: ModelTypes["AdvertisesFields_status_Delete"] | undefined
};
	["AdvertisesFields_status_Create"]: {
		permission: boolean
};
	["AdvertisesFields_status_Read"]: {
		permission: boolean
};
	["AdvertisesFields_status_Update"]: {
		permission: boolean
};
	["AdvertisesFields_status_Delete"]: {
		permission: boolean
};
	["AdvertisesFields_updatedAt"]: {
		create?: ModelTypes["AdvertisesFields_updatedAt_Create"] | undefined,
	read?: ModelTypes["AdvertisesFields_updatedAt_Read"] | undefined,
	update?: ModelTypes["AdvertisesFields_updatedAt_Update"] | undefined,
	delete?: ModelTypes["AdvertisesFields_updatedAt_Delete"] | undefined
};
	["AdvertisesFields_updatedAt_Create"]: {
		permission: boolean
};
	["AdvertisesFields_updatedAt_Read"]: {
		permission: boolean
};
	["AdvertisesFields_updatedAt_Update"]: {
		permission: boolean
};
	["AdvertisesFields_updatedAt_Delete"]: {
		permission: boolean
};
	["AdvertisesFields_createdAt"]: {
		create?: ModelTypes["AdvertisesFields_createdAt_Create"] | undefined,
	read?: ModelTypes["AdvertisesFields_createdAt_Read"] | undefined,
	update?: ModelTypes["AdvertisesFields_createdAt_Update"] | undefined,
	delete?: ModelTypes["AdvertisesFields_createdAt_Delete"] | undefined
};
	["AdvertisesFields_createdAt_Create"]: {
		permission: boolean
};
	["AdvertisesFields_createdAt_Read"]: {
		permission: boolean
};
	["AdvertisesFields_createdAt_Update"]: {
		permission: boolean
};
	["AdvertisesFields_createdAt_Delete"]: {
		permission: boolean
};
	["AdvertisesCreateAccess"]: {
		permission: boolean,
	where?: ModelTypes["JSONObject"] | undefined
};
	["AdvertisesReadAccess"]: {
		permission: boolean,
	where?: ModelTypes["JSONObject"] | undefined
};
	["AdvertisesUpdateAccess"]: {
		permission: boolean,
	where?: ModelTypes["JSONObject"] | undefined
};
	["AdvertisesDeleteAccess"]: {
		permission: boolean,
	where?: ModelTypes["JSONObject"] | undefined
};
	["staticsAccess"]: {
		fields?: ModelTypes["StaticsFields"] | undefined,
	create?: ModelTypes["StaticsCreateAccess"] | undefined,
	read?: ModelTypes["StaticsReadAccess"] | undefined,
	update?: ModelTypes["StaticsUpdateAccess"] | undefined,
	delete?: ModelTypes["StaticsDeleteAccess"] | undefined
};
	["StaticsFields"]: {
		name?: ModelTypes["StaticsFields_name"] | undefined,
	key?: ModelTypes["StaticsFields_key"] | undefined,
	content?: ModelTypes["StaticsFields_content"] | undefined,
	region?: ModelTypes["StaticsFields_region"] | undefined,
	updatedAt?: ModelTypes["StaticsFields_updatedAt"] | undefined,
	createdAt?: ModelTypes["StaticsFields_createdAt"] | undefined
};
	["StaticsFields_name"]: {
		create?: ModelTypes["StaticsFields_name_Create"] | undefined,
	read?: ModelTypes["StaticsFields_name_Read"] | undefined,
	update?: ModelTypes["StaticsFields_name_Update"] | undefined,
	delete?: ModelTypes["StaticsFields_name_Delete"] | undefined
};
	["StaticsFields_name_Create"]: {
		permission: boolean
};
	["StaticsFields_name_Read"]: {
		permission: boolean
};
	["StaticsFields_name_Update"]: {
		permission: boolean
};
	["StaticsFields_name_Delete"]: {
		permission: boolean
};
	["StaticsFields_key"]: {
		create?: ModelTypes["StaticsFields_key_Create"] | undefined,
	read?: ModelTypes["StaticsFields_key_Read"] | undefined,
	update?: ModelTypes["StaticsFields_key_Update"] | undefined,
	delete?: ModelTypes["StaticsFields_key_Delete"] | undefined
};
	["StaticsFields_key_Create"]: {
		permission: boolean
};
	["StaticsFields_key_Read"]: {
		permission: boolean
};
	["StaticsFields_key_Update"]: {
		permission: boolean
};
	["StaticsFields_key_Delete"]: {
		permission: boolean
};
	["StaticsFields_content"]: {
		create?: ModelTypes["StaticsFields_content_Create"] | undefined,
	read?: ModelTypes["StaticsFields_content_Read"] | undefined,
	update?: ModelTypes["StaticsFields_content_Update"] | undefined,
	delete?: ModelTypes["StaticsFields_content_Delete"] | undefined
};
	["StaticsFields_content_Create"]: {
		permission: boolean
};
	["StaticsFields_content_Read"]: {
		permission: boolean
};
	["StaticsFields_content_Update"]: {
		permission: boolean
};
	["StaticsFields_content_Delete"]: {
		permission: boolean
};
	["StaticsFields_region"]: {
		create?: ModelTypes["StaticsFields_region_Create"] | undefined,
	read?: ModelTypes["StaticsFields_region_Read"] | undefined,
	update?: ModelTypes["StaticsFields_region_Update"] | undefined,
	delete?: ModelTypes["StaticsFields_region_Delete"] | undefined
};
	["StaticsFields_region_Create"]: {
		permission: boolean
};
	["StaticsFields_region_Read"]: {
		permission: boolean
};
	["StaticsFields_region_Update"]: {
		permission: boolean
};
	["StaticsFields_region_Delete"]: {
		permission: boolean
};
	["StaticsFields_updatedAt"]: {
		create?: ModelTypes["StaticsFields_updatedAt_Create"] | undefined,
	read?: ModelTypes["StaticsFields_updatedAt_Read"] | undefined,
	update?: ModelTypes["StaticsFields_updatedAt_Update"] | undefined,
	delete?: ModelTypes["StaticsFields_updatedAt_Delete"] | undefined
};
	["StaticsFields_updatedAt_Create"]: {
		permission: boolean
};
	["StaticsFields_updatedAt_Read"]: {
		permission: boolean
};
	["StaticsFields_updatedAt_Update"]: {
		permission: boolean
};
	["StaticsFields_updatedAt_Delete"]: {
		permission: boolean
};
	["StaticsFields_createdAt"]: {
		create?: ModelTypes["StaticsFields_createdAt_Create"] | undefined,
	read?: ModelTypes["StaticsFields_createdAt_Read"] | undefined,
	update?: ModelTypes["StaticsFields_createdAt_Update"] | undefined,
	delete?: ModelTypes["StaticsFields_createdAt_Delete"] | undefined
};
	["StaticsFields_createdAt_Create"]: {
		permission: boolean
};
	["StaticsFields_createdAt_Read"]: {
		permission: boolean
};
	["StaticsFields_createdAt_Update"]: {
		permission: boolean
};
	["StaticsFields_createdAt_Delete"]: {
		permission: boolean
};
	["StaticsCreateAccess"]: {
		permission: boolean,
	where?: ModelTypes["JSONObject"] | undefined
};
	["StaticsReadAccess"]: {
		permission: boolean,
	where?: ModelTypes["JSONObject"] | undefined
};
	["StaticsUpdateAccess"]: {
		permission: boolean,
	where?: ModelTypes["JSONObject"] | undefined
};
	["StaticsDeleteAccess"]: {
		permission: boolean,
	where?: ModelTypes["JSONObject"] | undefined
};
	["event_viewsAccess"]: {
		fields?: ModelTypes["EventViewsFields"] | undefined,
	create?: ModelTypes["EventViewsCreateAccess"] | undefined,
	read?: ModelTypes["EventViewsReadAccess"] | undefined,
	update?: ModelTypes["EventViewsUpdateAccess"] | undefined,
	delete?: ModelTypes["EventViewsDeleteAccess"] | undefined
};
	["EventViewsFields"]: {
		date?: ModelTypes["EventViewsFields_date"] | undefined,
	eventId?: ModelTypes["EventViewsFields_eventId"] | undefined,
	viewCount?: ModelTypes["EventViewsFields_viewCount"] | undefined,
	updatedAt?: ModelTypes["EventViewsFields_updatedAt"] | undefined,
	createdAt?: ModelTypes["EventViewsFields_createdAt"] | undefined
};
	["EventViewsFields_date"]: {
		create?: ModelTypes["EventViewsFields_date_Create"] | undefined,
	read?: ModelTypes["EventViewsFields_date_Read"] | undefined,
	update?: ModelTypes["EventViewsFields_date_Update"] | undefined,
	delete?: ModelTypes["EventViewsFields_date_Delete"] | undefined
};
	["EventViewsFields_date_Create"]: {
		permission: boolean
};
	["EventViewsFields_date_Read"]: {
		permission: boolean
};
	["EventViewsFields_date_Update"]: {
		permission: boolean
};
	["EventViewsFields_date_Delete"]: {
		permission: boolean
};
	["EventViewsFields_eventId"]: {
		create?: ModelTypes["EventViewsFields_eventId_Create"] | undefined,
	read?: ModelTypes["EventViewsFields_eventId_Read"] | undefined,
	update?: ModelTypes["EventViewsFields_eventId_Update"] | undefined,
	delete?: ModelTypes["EventViewsFields_eventId_Delete"] | undefined
};
	["EventViewsFields_eventId_Create"]: {
		permission: boolean
};
	["EventViewsFields_eventId_Read"]: {
		permission: boolean
};
	["EventViewsFields_eventId_Update"]: {
		permission: boolean
};
	["EventViewsFields_eventId_Delete"]: {
		permission: boolean
};
	["EventViewsFields_viewCount"]: {
		create?: ModelTypes["EventViewsFields_viewCount_Create"] | undefined,
	read?: ModelTypes["EventViewsFields_viewCount_Read"] | undefined,
	update?: ModelTypes["EventViewsFields_viewCount_Update"] | undefined,
	delete?: ModelTypes["EventViewsFields_viewCount_Delete"] | undefined
};
	["EventViewsFields_viewCount_Create"]: {
		permission: boolean
};
	["EventViewsFields_viewCount_Read"]: {
		permission: boolean
};
	["EventViewsFields_viewCount_Update"]: {
		permission: boolean
};
	["EventViewsFields_viewCount_Delete"]: {
		permission: boolean
};
	["EventViewsFields_updatedAt"]: {
		create?: ModelTypes["EventViewsFields_updatedAt_Create"] | undefined,
	read?: ModelTypes["EventViewsFields_updatedAt_Read"] | undefined,
	update?: ModelTypes["EventViewsFields_updatedAt_Update"] | undefined,
	delete?: ModelTypes["EventViewsFields_updatedAt_Delete"] | undefined
};
	["EventViewsFields_updatedAt_Create"]: {
		permission: boolean
};
	["EventViewsFields_updatedAt_Read"]: {
		permission: boolean
};
	["EventViewsFields_updatedAt_Update"]: {
		permission: boolean
};
	["EventViewsFields_updatedAt_Delete"]: {
		permission: boolean
};
	["EventViewsFields_createdAt"]: {
		create?: ModelTypes["EventViewsFields_createdAt_Create"] | undefined,
	read?: ModelTypes["EventViewsFields_createdAt_Read"] | undefined,
	update?: ModelTypes["EventViewsFields_createdAt_Update"] | undefined,
	delete?: ModelTypes["EventViewsFields_createdAt_Delete"] | undefined
};
	["EventViewsFields_createdAt_Create"]: {
		permission: boolean
};
	["EventViewsFields_createdAt_Read"]: {
		permission: boolean
};
	["EventViewsFields_createdAt_Update"]: {
		permission: boolean
};
	["EventViewsFields_createdAt_Delete"]: {
		permission: boolean
};
	["EventViewsCreateAccess"]: {
		permission: boolean,
	where?: ModelTypes["JSONObject"] | undefined
};
	["EventViewsReadAccess"]: {
		permission: boolean,
	where?: ModelTypes["JSONObject"] | undefined
};
	["EventViewsUpdateAccess"]: {
		permission: boolean,
	where?: ModelTypes["JSONObject"] | undefined
};
	["EventViewsDeleteAccess"]: {
		permission: boolean,
	where?: ModelTypes["JSONObject"] | undefined
};
	["post_viewsAccess"]: {
		fields?: ModelTypes["PostViewsFields"] | undefined,
	create?: ModelTypes["PostViewsCreateAccess"] | undefined,
	read?: ModelTypes["PostViewsReadAccess"] | undefined,
	update?: ModelTypes["PostViewsUpdateAccess"] | undefined,
	delete?: ModelTypes["PostViewsDeleteAccess"] | undefined
};
	["PostViewsFields"]: {
		date?: ModelTypes["PostViewsFields_date"] | undefined,
	postId?: ModelTypes["PostViewsFields_postId"] | undefined,
	viewCount?: ModelTypes["PostViewsFields_viewCount"] | undefined,
	updatedAt?: ModelTypes["PostViewsFields_updatedAt"] | undefined,
	createdAt?: ModelTypes["PostViewsFields_createdAt"] | undefined
};
	["PostViewsFields_date"]: {
		create?: ModelTypes["PostViewsFields_date_Create"] | undefined,
	read?: ModelTypes["PostViewsFields_date_Read"] | undefined,
	update?: ModelTypes["PostViewsFields_date_Update"] | undefined,
	delete?: ModelTypes["PostViewsFields_date_Delete"] | undefined
};
	["PostViewsFields_date_Create"]: {
		permission: boolean
};
	["PostViewsFields_date_Read"]: {
		permission: boolean
};
	["PostViewsFields_date_Update"]: {
		permission: boolean
};
	["PostViewsFields_date_Delete"]: {
		permission: boolean
};
	["PostViewsFields_postId"]: {
		create?: ModelTypes["PostViewsFields_postId_Create"] | undefined,
	read?: ModelTypes["PostViewsFields_postId_Read"] | undefined,
	update?: ModelTypes["PostViewsFields_postId_Update"] | undefined,
	delete?: ModelTypes["PostViewsFields_postId_Delete"] | undefined
};
	["PostViewsFields_postId_Create"]: {
		permission: boolean
};
	["PostViewsFields_postId_Read"]: {
		permission: boolean
};
	["PostViewsFields_postId_Update"]: {
		permission: boolean
};
	["PostViewsFields_postId_Delete"]: {
		permission: boolean
};
	["PostViewsFields_viewCount"]: {
		create?: ModelTypes["PostViewsFields_viewCount_Create"] | undefined,
	read?: ModelTypes["PostViewsFields_viewCount_Read"] | undefined,
	update?: ModelTypes["PostViewsFields_viewCount_Update"] | undefined,
	delete?: ModelTypes["PostViewsFields_viewCount_Delete"] | undefined
};
	["PostViewsFields_viewCount_Create"]: {
		permission: boolean
};
	["PostViewsFields_viewCount_Read"]: {
		permission: boolean
};
	["PostViewsFields_viewCount_Update"]: {
		permission: boolean
};
	["PostViewsFields_viewCount_Delete"]: {
		permission: boolean
};
	["PostViewsFields_updatedAt"]: {
		create?: ModelTypes["PostViewsFields_updatedAt_Create"] | undefined,
	read?: ModelTypes["PostViewsFields_updatedAt_Read"] | undefined,
	update?: ModelTypes["PostViewsFields_updatedAt_Update"] | undefined,
	delete?: ModelTypes["PostViewsFields_updatedAt_Delete"] | undefined
};
	["PostViewsFields_updatedAt_Create"]: {
		permission: boolean
};
	["PostViewsFields_updatedAt_Read"]: {
		permission: boolean
};
	["PostViewsFields_updatedAt_Update"]: {
		permission: boolean
};
	["PostViewsFields_updatedAt_Delete"]: {
		permission: boolean
};
	["PostViewsFields_createdAt"]: {
		create?: ModelTypes["PostViewsFields_createdAt_Create"] | undefined,
	read?: ModelTypes["PostViewsFields_createdAt_Read"] | undefined,
	update?: ModelTypes["PostViewsFields_createdAt_Update"] | undefined,
	delete?: ModelTypes["PostViewsFields_createdAt_Delete"] | undefined
};
	["PostViewsFields_createdAt_Create"]: {
		permission: boolean
};
	["PostViewsFields_createdAt_Read"]: {
		permission: boolean
};
	["PostViewsFields_createdAt_Update"]: {
		permission: boolean
};
	["PostViewsFields_createdAt_Delete"]: {
		permission: boolean
};
	["PostViewsCreateAccess"]: {
		permission: boolean,
	where?: ModelTypes["JSONObject"] | undefined
};
	["PostViewsReadAccess"]: {
		permission: boolean,
	where?: ModelTypes["JSONObject"] | undefined
};
	["PostViewsUpdateAccess"]: {
		permission: boolean,
	where?: ModelTypes["JSONObject"] | undefined
};
	["PostViewsDeleteAccess"]: {
		permission: boolean,
	where?: ModelTypes["JSONObject"] | undefined
};
	["payload_preferencesAccess"]: {
		fields?: ModelTypes["PayloadPreferencesFields"] | undefined,
	create?: ModelTypes["PayloadPreferencesCreateAccess"] | undefined,
	read?: ModelTypes["PayloadPreferencesReadAccess"] | undefined,
	update?: ModelTypes["PayloadPreferencesUpdateAccess"] | undefined,
	delete?: ModelTypes["PayloadPreferencesDeleteAccess"] | undefined
};
	["PayloadPreferencesFields"]: {
		user?: ModelTypes["PayloadPreferencesFields_user"] | undefined,
	key?: ModelTypes["PayloadPreferencesFields_key"] | undefined,
	value?: ModelTypes["PayloadPreferencesFields_value"] | undefined,
	updatedAt?: ModelTypes["PayloadPreferencesFields_updatedAt"] | undefined,
	createdAt?: ModelTypes["PayloadPreferencesFields_createdAt"] | undefined
};
	["PayloadPreferencesFields_user"]: {
		create?: ModelTypes["PayloadPreferencesFields_user_Create"] | undefined,
	read?: ModelTypes["PayloadPreferencesFields_user_Read"] | undefined,
	update?: ModelTypes["PayloadPreferencesFields_user_Update"] | undefined,
	delete?: ModelTypes["PayloadPreferencesFields_user_Delete"] | undefined
};
	["PayloadPreferencesFields_user_Create"]: {
		permission: boolean
};
	["PayloadPreferencesFields_user_Read"]: {
		permission: boolean
};
	["PayloadPreferencesFields_user_Update"]: {
		permission: boolean
};
	["PayloadPreferencesFields_user_Delete"]: {
		permission: boolean
};
	["PayloadPreferencesFields_key"]: {
		create?: ModelTypes["PayloadPreferencesFields_key_Create"] | undefined,
	read?: ModelTypes["PayloadPreferencesFields_key_Read"] | undefined,
	update?: ModelTypes["PayloadPreferencesFields_key_Update"] | undefined,
	delete?: ModelTypes["PayloadPreferencesFields_key_Delete"] | undefined
};
	["PayloadPreferencesFields_key_Create"]: {
		permission: boolean
};
	["PayloadPreferencesFields_key_Read"]: {
		permission: boolean
};
	["PayloadPreferencesFields_key_Update"]: {
		permission: boolean
};
	["PayloadPreferencesFields_key_Delete"]: {
		permission: boolean
};
	["PayloadPreferencesFields_value"]: {
		create?: ModelTypes["PayloadPreferencesFields_value_Create"] | undefined,
	read?: ModelTypes["PayloadPreferencesFields_value_Read"] | undefined,
	update?: ModelTypes["PayloadPreferencesFields_value_Update"] | undefined,
	delete?: ModelTypes["PayloadPreferencesFields_value_Delete"] | undefined
};
	["PayloadPreferencesFields_value_Create"]: {
		permission: boolean
};
	["PayloadPreferencesFields_value_Read"]: {
		permission: boolean
};
	["PayloadPreferencesFields_value_Update"]: {
		permission: boolean
};
	["PayloadPreferencesFields_value_Delete"]: {
		permission: boolean
};
	["PayloadPreferencesFields_updatedAt"]: {
		create?: ModelTypes["PayloadPreferencesFields_updatedAt_Create"] | undefined,
	read?: ModelTypes["PayloadPreferencesFields_updatedAt_Read"] | undefined,
	update?: ModelTypes["PayloadPreferencesFields_updatedAt_Update"] | undefined,
	delete?: ModelTypes["PayloadPreferencesFields_updatedAt_Delete"] | undefined
};
	["PayloadPreferencesFields_updatedAt_Create"]: {
		permission: boolean
};
	["PayloadPreferencesFields_updatedAt_Read"]: {
		permission: boolean
};
	["PayloadPreferencesFields_updatedAt_Update"]: {
		permission: boolean
};
	["PayloadPreferencesFields_updatedAt_Delete"]: {
		permission: boolean
};
	["PayloadPreferencesFields_createdAt"]: {
		create?: ModelTypes["PayloadPreferencesFields_createdAt_Create"] | undefined,
	read?: ModelTypes["PayloadPreferencesFields_createdAt_Read"] | undefined,
	update?: ModelTypes["PayloadPreferencesFields_createdAt_Update"] | undefined,
	delete?: ModelTypes["PayloadPreferencesFields_createdAt_Delete"] | undefined
};
	["PayloadPreferencesFields_createdAt_Create"]: {
		permission: boolean
};
	["PayloadPreferencesFields_createdAt_Read"]: {
		permission: boolean
};
	["PayloadPreferencesFields_createdAt_Update"]: {
		permission: boolean
};
	["PayloadPreferencesFields_createdAt_Delete"]: {
		permission: boolean
};
	["PayloadPreferencesCreateAccess"]: {
		permission: boolean,
	where?: ModelTypes["JSONObject"] | undefined
};
	["PayloadPreferencesReadAccess"]: {
		permission: boolean,
	where?: ModelTypes["JSONObject"] | undefined
};
	["PayloadPreferencesUpdateAccess"]: {
		permission: boolean,
	where?: ModelTypes["JSONObject"] | undefined
};
	["PayloadPreferencesDeleteAccess"]: {
		permission: boolean,
	where?: ModelTypes["JSONObject"] | undefined
};
	["GetEventMaxUsedTag"]: {
		tag?: ModelTypes["maxUsedTag"] | undefined
};
	["maxUsedTag"]: {
		id?: string | undefined,
	name?: string | undefined
};
	["ChatbotStocks"]: {
		product?: Array<ModelTypes["Product"] | undefined> | undefined,
	ticketing?: Array<ModelTypes["Ticketing"] | undefined> | undefined
};
	["ChatbotEvents"]: {
		events?: Array<ModelTypes["Event"] | undefined> | undefined
};
	["WebAppCheckInCountGetResponse"]: {
		checkInCount?: number | undefined,
	totalQrCode?: number | undefined
};
	["ProductWhereInput"]: {
	id?: ModelTypes["ProductDetailOperator"] | undefined
};
	["ProductDetailOperator"]: {
	equals?: string | undefined
};
	["ProductList"]: {
		products?: Array<ModelTypes["Product"] | undefined> | undefined
};
	["TicketingWhereInput"]: {
	id?: ModelTypes["TicketingDetailOperator"] | undefined
};
	["TicketingDetailOperator"]: {
	equals?: string | undefined
};
	["TicketingList"]: {
		ticketings?: Array<ModelTypes["Ticketing"] | undefined> | undefined
};
	["RedemptionCodeList"]: {
		docs?: Array<ModelTypes["RedemptionCode"] | undefined> | undefined
};
	["RedemptionCodeWhereInput"]: {
	qrcodeId?: ModelTypes["RedemptionCodeDetailOperator"] | undefined
};
	["RedemptionCodeDetailOperator"]: {
	equals?: string | undefined
};
	["BookmarkList"]: {
		docs?: Array<ModelTypes["WebAppBookmark"] | undefined> | undefined
};
	["Mutation"]: {
		createEvent?: ModelTypes["Event"] | undefined,
	updateEvent?: ModelTypes["Event"] | undefined,
	deleteEvent?: ModelTypes["Event"] | undefined,
	createLocation?: ModelTypes["Location"] | undefined,
	updateLocation?: ModelTypes["Location"] | undefined,
	deleteLocation?: ModelTypes["Location"] | undefined,
	createPost?: ModelTypes["Post"] | undefined,
	updatePost?: ModelTypes["Post"] | undefined,
	deletePost?: ModelTypes["Post"] | undefined,
	createTicketing?: ModelTypes["Ticketing"] | undefined,
	updateTicketing?: ModelTypes["Ticketing"] | undefined,
	deleteTicketing?: ModelTypes["Ticketing"] | undefined,
	createProduct?: ModelTypes["Product"] | undefined,
	updateProduct?: ModelTypes["Product"] | undefined,
	deleteProduct?: ModelTypes["Product"] | undefined,
	createInventoryStatus?: ModelTypes["InventoryStatus"] | undefined,
	updateInventoryStatus?: ModelTypes["InventoryStatus"] | undefined,
	deleteInventoryStatus?: ModelTypes["InventoryStatus"] | undefined,
	createTransaction?: ModelTypes["Transaction"] | undefined,
	updateTransaction?: ModelTypes["Transaction"] | undefined,
	deleteTransaction?: ModelTypes["Transaction"] | undefined,
	createRedemptionCode?: ModelTypes["RedemptionCode"] | undefined,
	updateRedemptionCode?: ModelTypes["RedemptionCode"] | undefined,
	deleteRedemptionCode?: ModelTypes["RedemptionCode"] | undefined,
	createRedemptionPdf?: ModelTypes["RedemptionPdf"] | undefined,
	updateRedemptionPdf?: ModelTypes["RedemptionPdf"] | undefined,
	deleteRedemptionPdf?: ModelTypes["RedemptionPdf"] | undefined,
	createCheckInRedemption?: ModelTypes["CheckInRedemption"] | undefined,
	updateCheckInRedemption?: ModelTypes["CheckInRedemption"] | undefined,
	deleteCheckInRedemption?: ModelTypes["CheckInRedemption"] | undefined,
	createUser?: ModelTypes["User"] | undefined,
	updateUser?: ModelTypes["User"] | undefined,
	deleteUser?: ModelTypes["User"] | undefined,
	refreshTokenUser?: ModelTypes["usersRefreshedUser"] | undefined,
	logoutUser?: string | undefined,
	unlockUser: boolean,
	loginUser?: ModelTypes["usersLoginResult"] | undefined,
	forgotPasswordUser: boolean,
	resetPasswordUser?: ModelTypes["usersResetPassword"] | undefined,
	verifyEmailUser?: boolean | undefined,
	createBookmark?: ModelTypes["Bookmark"] | undefined,
	updateBookmark?: ModelTypes["Bookmark"] | undefined,
	deleteBookmark?: ModelTypes["Bookmark"] | undefined,
	createWebAppBookmark?: ModelTypes["WebAppBookmark"] | undefined,
	updateWebAppBookmark?: ModelTypes["WebAppBookmark"] | undefined,
	deleteWebAppBookmark?: ModelTypes["WebAppBookmark"] | undefined,
	createViewRecord?: ModelTypes["ViewRecord"] | undefined,
	updateViewRecord?: ModelTypes["ViewRecord"] | undefined,
	deleteViewRecord?: ModelTypes["ViewRecord"] | undefined,
	createComment?: ModelTypes["Comment"] | undefined,
	updateComment?: ModelTypes["Comment"] | undefined,
	deleteComment?: ModelTypes["Comment"] | undefined,
	createSubscription?: ModelTypes["Subscription"] | undefined,
	updateSubscription?: ModelTypes["Subscription"] | undefined,
	deleteSubscription?: ModelTypes["Subscription"] | undefined,
	createNewsletter?: ModelTypes["Newsletter"] | undefined,
	updateNewsletter?: ModelTypes["Newsletter"] | undefined,
	deleteNewsletter?: ModelTypes["Newsletter"] | undefined,
	createBlogCategory?: ModelTypes["BlogCategory"] | undefined,
	updateBlogCategory?: ModelTypes["BlogCategory"] | undefined,
	deleteBlogCategory?: ModelTypes["BlogCategory"] | undefined,
	createTag?: ModelTypes["Tag"] | undefined,
	updateTag?: ModelTypes["Tag"] | undefined,
	deleteTag?: ModelTypes["Tag"] | undefined,
	createSpecialTag?: ModelTypes["SpecialTag"] | undefined,
	updateSpecialTag?: ModelTypes["SpecialTag"] | undefined,
	deleteSpecialTag?: ModelTypes["SpecialTag"] | undefined,
	createDate?: ModelTypes["Date"] | undefined,
	updateDate?: ModelTypes["Date"] | undefined,
	deleteDate?: ModelTypes["Date"] | undefined,
	createDistrict?: ModelTypes["District"] | undefined,
	updateDistrict?: ModelTypes["District"] | undefined,
	deleteDistrict?: ModelTypes["District"] | undefined,
	createAudience?: ModelTypes["Audience"] | undefined,
	updateAudience?: ModelTypes["Audience"] | undefined,
	deleteAudience?: ModelTypes["Audience"] | undefined,
	createEventCategory?: ModelTypes["EventCategory"] | undefined,
	updateEventCategory?: ModelTypes["EventCategory"] | undefined,
	deleteEventCategory?: ModelTypes["EventCategory"] | undefined,
	createPrivateMedia?: ModelTypes["PrivateMedia"] | undefined,
	updatePrivateMedia?: ModelTypes["PrivateMedia"] | undefined,
	deletePrivateMedia?: ModelTypes["PrivateMedia"] | undefined,
	createMedia?: ModelTypes["Media"] | undefined,
	updateMedia?: ModelTypes["Media"] | undefined,
	deleteMedia?: ModelTypes["Media"] | undefined,
	createBackupMedia?: ModelTypes["BackupMedia"] | undefined,
	updateBackupMedia?: ModelTypes["BackupMedia"] | undefined,
	deleteBackupMedia?: ModelTypes["BackupMedia"] | undefined,
	createAccountSuspendRequest?: ModelTypes["AccountSuspendRequest"] | undefined,
	updateAccountSuspendRequest?: ModelTypes["AccountSuspendRequest"] | undefined,
	deleteAccountSuspendRequest?: ModelTypes["AccountSuspendRequest"] | undefined,
	createDevice?: ModelTypes["Device"] | undefined,
	updateDevice?: ModelTypes["Device"] | undefined,
	deleteDevice?: ModelTypes["Device"] | undefined,
	createNotification?: ModelTypes["Notification"] | undefined,
	updateNotification?: ModelTypes["Notification"] | undefined,
	deleteNotification?: ModelTypes["Notification"] | undefined,
	createRedirectSetting?: ModelTypes["RedirectSetting"] | undefined,
	updateRedirectSetting?: ModelTypes["RedirectSetting"] | undefined,
	deleteRedirectSetting?: ModelTypes["RedirectSetting"] | undefined,
	createEventRanking?: ModelTypes["EventRanking"] | undefined,
	updateEventRanking?: ModelTypes["EventRanking"] | undefined,
	deleteEventRanking?: ModelTypes["EventRanking"] | undefined,
	createLocationRanking?: ModelTypes["LocationRanking"] | undefined,
	updateLocationRanking?: ModelTypes["LocationRanking"] | undefined,
	deleteLocationRanking?: ModelTypes["LocationRanking"] | undefined,
	createEventCalendar?: ModelTypes["EventCalendar"] | undefined,
	updateEventCalendar?: ModelTypes["EventCalendar"] | undefined,
	deleteEventCalendar?: ModelTypes["EventCalendar"] | undefined,
	createEditorChoice?: ModelTypes["EditorChoice"] | undefined,
	updateEditorChoice?: ModelTypes["EditorChoice"] | undefined,
	deleteEditorChoice?: ModelTypes["EditorChoice"] | undefined,
	createCriteriaSetting?: ModelTypes["CriteriaSetting"] | undefined,
	updateCriteriaSetting?: ModelTypes["CriteriaSetting"] | undefined,
	deleteCriteriaSetting?: ModelTypes["CriteriaSetting"] | undefined,
	createOrganiserSetting?: ModelTypes["OrganiserSetting"] | undefined,
	updateOrganiserSetting?: ModelTypes["OrganiserSetting"] | undefined,
	deleteOrganiserSetting?: ModelTypes["OrganiserSetting"] | undefined,
	createPostSetting?: ModelTypes["PostSetting"] | undefined,
	updatePostSetting?: ModelTypes["PostSetting"] | undefined,
	deletePostSetting?: ModelTypes["PostSetting"] | undefined,
	createHomepageSetting?: ModelTypes["HomepageSetting"] | undefined,
	updateHomepageSetting?: ModelTypes["HomepageSetting"] | undefined,
	deleteHomepageSetting?: ModelTypes["HomepageSetting"] | undefined,
	createBannerSection?: ModelTypes["BannerSection"] | undefined,
	updateBannerSection?: ModelTypes["BannerSection"] | undefined,
	deleteBannerSection?: ModelTypes["BannerSection"] | undefined,
	createMacaoBannerSection?: ModelTypes["MacaoBannerSection"] | undefined,
	updateMacaoBannerSection?: ModelTypes["MacaoBannerSection"] | undefined,
	deleteMacaoBannerSection?: ModelTypes["MacaoBannerSection"] | undefined,
	createBanner?: ModelTypes["Banner"] | undefined,
	updateBanner?: ModelTypes["Banner"] | undefined,
	deleteBanner?: ModelTypes["Banner"] | undefined,
	createCampaign?: ModelTypes["Campaign"] | undefined,
	updateCampaign?: ModelTypes["Campaign"] | undefined,
	deleteCampaign?: ModelTypes["Campaign"] | undefined,
	createAdvertise?: ModelTypes["Advertise"] | undefined,
	updateAdvertise?: ModelTypes["Advertise"] | undefined,
	deleteAdvertise?: ModelTypes["Advertise"] | undefined,
	createStatic?: ModelTypes["Static"] | undefined,
	updateStatic?: ModelTypes["Static"] | undefined,
	deleteStatic?: ModelTypes["Static"] | undefined,
	createEventView?: ModelTypes["EventView"] | undefined,
	updateEventView?: ModelTypes["EventView"] | undefined,
	deleteEventView?: ModelTypes["EventView"] | undefined,
	createPostView?: ModelTypes["PostView"] | undefined,
	updatePostView?: ModelTypes["PostView"] | undefined,
	deletePostView?: ModelTypes["PostView"] | undefined,
	createPayloadPreference?: ModelTypes["PayloadPreference"] | undefined,
	updatePayloadPreference?: ModelTypes["PayloadPreference"] | undefined,
	deletePayloadPreference?: ModelTypes["PayloadPreference"] | undefined,
	AddEventView?: boolean | undefined,
	ItemBookmark?: boolean | undefined,
	ItemUnbookmark?: boolean | undefined,
	readItem?: boolean | undefined,
	AddPostView?: boolean | undefined,
	LoginUser?: ModelTypes["usersLoginResult"] | undefined,
	RegisterUser?: ModelTypes["User"] | undefined,
	ActiveUser?: boolean | undefined,
	ReActivePasswordUser?: string | undefined,
	SSOLoginUser?: ModelTypes["usersLoginResult"] | undefined,
	SuspendUser?: boolean | undefined,
	UpdateUserPassword?: boolean | undefined,
	SubscribeOrganiser?: boolean | undefined,
	UnSubscribeOrganiser?: boolean | undefined,
	PostMessage?: boolean | undefined,
	AddBannerView?: boolean | undefined,
	AddDevice?: boolean | undefined,
	RemoveDevice?: boolean | undefined,
	UpdateNotifications?: boolean | undefined,
	DeleteNotification?: boolean | undefined,
	CreateNewsletter?: boolean | undefined,
	ChatbotInventoryHold?: ModelTypes["InventoryStatus"] | undefined,
	ChatbotInventoryRelease?: boolean | undefined,
	ChatbotInventoryPayment?: ModelTypes["ChatbotInventoryPayment"] | undefined,
	ChatbotTransactionCreate?: ModelTypes["Transaction"] | undefined,
	ChatbotRedemptionCodeUpdate?: boolean | undefined,
	ChatbotTransactionPdfGet?: ModelTypes["ChatbotTransactionPdfGet"] | undefined,
	WebAppQrCodeBatchScan?: ModelTypes["WebAppQrCodeBatchScanResult"] | undefined,
	WebAppQrCodeScan?: ModelTypes["WebAppQrCodeScanResponse"] | undefined,
	WebAppItemBookmark?: ModelTypes["WebAppBookmark"] | undefined,
	WebAppItemUnBookmark?: ModelTypes["WebAppBookmark"] | undefined
};
	["mutationEventInput"]: {
	region?: ModelTypes["Event_region_MutationInput"] | undefined,
	creator?: string | undefined,
	permalink?: string | undefined,
	status: string,
	level?: ModelTypes["Event_level_MutationInput"] | undefined,
	parent?: Array<string | undefined> | undefined,
	name?: string | undefined,
	chatbotEventTitle?: string | undefined,
	chatbotSequence?: number | undefined,
	thumbnail?: string | undefined,
	banner?: string | undefined,
	mobileBanner?: string | undefined,
	sections?: Array<ModelTypes["mutationEvent_SectionsInput"] | undefined> | undefined,
	ticketing?: Array<ModelTypes["mutationEvent_TicketingInput"] | undefined> | undefined,
	content?: ModelTypes["JSON"] | undefined,
	legacyContent?: string | undefined,
	showLegacyContent?: boolean | undefined,
	criteria: ModelTypes["mutationEvent_CriteriaInput"],
	walkIn?: boolean | undefined,
	contact?: ModelTypes["mutationEvent_ContactInput"] | undefined,
	views?: number | undefined,
	free?: boolean | undefined,
	fees?: Array<ModelTypes["mutationEvent_FeesInput"] | undefined> | undefined,
	homePageInternalClick?: number | undefined,
	homePageImpression?: number | undefined,
	eodClickRate?: number | undefined,
	eodImpressionRate?: number | undefined,
	top10ClickRateHomepage?: number | undefined,
	top10ImpressionkRateHomepage?: number | undefined,
	otherEventRankingClickRateHomepage?: number | undefined,
	otherEventRankingImpressionHomePage?: number | undefined,
	userPreferenceClickRate?: number | undefined,
	userPreferenceImpression?: number | undefined,
	eventSearchInternalClick?: number | undefined,
	eventListImpression?: number | undefined,
	latestEventInternalClick?: number | undefined,
	latestEventImpression?: number | undefined,
	topTenEventRankingInternalClick?: number | undefined,
	topTenImpression?: number | undefined,
	editorChoiceInternalClick?: number | undefined,
	editorChoiceImpression?: number | undefined,
	otherEventRankingInternalClick?: number | undefined,
	otherEventRankingImpression?: number | undefined,
	bannerInternalClick?: number | undefined,
	miscImpression?: number | undefined,
	miscInternalClick?: number | undefined,
	totalImpression?: number | undefined,
	internalClick?: number | undefined,
	outboundClick?: number | undefined,
	externalClick?: number | undefined,
	_viewsCountByMonth?: number | undefined,
	products?: Array<ModelTypes["mutationEvent_ProductsInput"] | undefined> | undefined,
	legacyGallery?: Array<string | undefined> | undefined,
	earliestStartDate?: string | undefined,
	latestEndDate?: string | undefined,
	latestStartDateTime?: string | undefined,
	hasTicketing?: boolean | undefined,
	hasProducts?: boolean | undefined,
	updatedAt?: string | undefined,
	createdAt?: string | undefined
};
	["Event_region_MutationInput"]:Event_region_MutationInput;
	["Event_level_MutationInput"]:Event_level_MutationInput;
	["mutationEvent_SectionsInput"]: {
	startDatetime: string,
	endDatetime?: string | undefined,
	fullDay?: boolean | undefined,
	repeat?: boolean | undefined,
	toThisDay?: string | undefined,
	recurrance: ModelTypes["mutationEvent_Sections_RecurranceInput"],
	sameLocation?: boolean | undefined,
	location?: string | undefined,
	district?: string | undefined,
	coordinate?: Array<number | undefined> | undefined,
	linkAddress?: boolean | undefined,
	address?: string | undefined,
	addressCoordinate?: Array<number | undefined> | undefined,
	id?: string | undefined
};
	["mutationEvent_Sections_RecurranceInput"]: {
	type: ModelTypes["Event_Sections_Recurrance_type_MutationInput"],
	weekday?: Array<ModelTypes["Event_Sections_Recurrance_weekday_MutationInput"] | undefined> | undefined
};
	["Event_Sections_Recurrance_type_MutationInput"]:Event_Sections_Recurrance_type_MutationInput;
	["Event_Sections_Recurrance_weekday_MutationInput"]:Event_Sections_Recurrance_weekday_MutationInput;
	["mutationEvent_TicketingInput"]: {
	startDatetime: string,
	endDatetime?: string | undefined,
	fullDay?: boolean | undefined,
	type: ModelTypes["Event_Ticketing_type_MutationInput"],
	ticketingType?: ModelTypes["Event_Ticketing_ticketingType_MutationInput"] | undefined,
	linkedTicketing?: string | undefined,
	promotionType?: ModelTypes["Event_Ticketing_promotionType_MutationInput"] | undefined,
	location?: string | undefined,
	website?: string | undefined,
	description?: string | undefined,
	id?: string | undefined
};
	["Event_Ticketing_type_MutationInput"]:Event_Ticketing_type_MutationInput;
	["Event_Ticketing_ticketingType_MutationInput"]:Event_Ticketing_ticketingType_MutationInput;
	["Event_Ticketing_promotionType_MutationInput"]:Event_Ticketing_promotionType_MutationInput;
	["mutationEvent_CriteriaInput"]: {
	categories?: Array<string | undefined> | undefined,
	audiences?: Array<string | undefined> | undefined,
	tags?: Array<string | undefined> | undefined,
	specialTags?: Array<string | undefined> | undefined
};
	["mutationEvent_ContactInput"]: {
	code?: number | undefined,
	phone?: string | undefined,
	page?: string | undefined,
	email?: string | undefined,
	privacy?: ModelTypes["JSON"] | undefined
};
	["mutationEvent_FeesInput"]: {
	fee?: number | undefined,
	remark?: string | undefined,
	id?: string | undefined
};
	["mutationEvent_ProductsInput"]: {
	startDateTime: string,
	endDateTime?: string | undefined,
	fullDay?: boolean | undefined,
	linkedProduct?: string | undefined,
	id?: string | undefined
};
	["mutationEventUpdateInput"]: {
	region?: ModelTypes["EventUpdate_region_MutationInput"] | undefined,
	creator?: string | undefined,
	permalink?: string | undefined,
	status?: string | undefined,
	level?: ModelTypes["EventUpdate_level_MutationInput"] | undefined,
	parent?: Array<string | undefined> | undefined,
	name?: string | undefined,
	chatbotEventTitle?: string | undefined,
	chatbotSequence?: number | undefined,
	thumbnail?: string | undefined,
	banner?: string | undefined,
	mobileBanner?: string | undefined,
	sections?: Array<ModelTypes["mutationEventUpdate_SectionsInput"] | undefined> | undefined,
	ticketing?: Array<ModelTypes["mutationEventUpdate_TicketingInput"] | undefined> | undefined,
	content?: ModelTypes["JSON"] | undefined,
	legacyContent?: string | undefined,
	showLegacyContent?: boolean | undefined,
	criteria: ModelTypes["mutationEventUpdate_CriteriaInput"],
	walkIn?: boolean | undefined,
	contact?: ModelTypes["mutationEventUpdate_ContactInput"] | undefined,
	views?: number | undefined,
	free?: boolean | undefined,
	fees?: Array<ModelTypes["mutationEventUpdate_FeesInput"] | undefined> | undefined,
	homePageInternalClick?: number | undefined,
	homePageImpression?: number | undefined,
	eodClickRate?: number | undefined,
	eodImpressionRate?: number | undefined,
	top10ClickRateHomepage?: number | undefined,
	top10ImpressionkRateHomepage?: number | undefined,
	otherEventRankingClickRateHomepage?: number | undefined,
	otherEventRankingImpressionHomePage?: number | undefined,
	userPreferenceClickRate?: number | undefined,
	userPreferenceImpression?: number | undefined,
	eventSearchInternalClick?: number | undefined,
	eventListImpression?: number | undefined,
	latestEventInternalClick?: number | undefined,
	latestEventImpression?: number | undefined,
	topTenEventRankingInternalClick?: number | undefined,
	topTenImpression?: number | undefined,
	editorChoiceInternalClick?: number | undefined,
	editorChoiceImpression?: number | undefined,
	otherEventRankingInternalClick?: number | undefined,
	otherEventRankingImpression?: number | undefined,
	bannerInternalClick?: number | undefined,
	miscImpression?: number | undefined,
	miscInternalClick?: number | undefined,
	totalImpression?: number | undefined,
	internalClick?: number | undefined,
	outboundClick?: number | undefined,
	externalClick?: number | undefined,
	_viewsCountByMonth?: number | undefined,
	products?: Array<ModelTypes["mutationEventUpdate_ProductsInput"] | undefined> | undefined,
	legacyGallery?: Array<string | undefined> | undefined,
	earliestStartDate?: string | undefined,
	latestEndDate?: string | undefined,
	latestStartDateTime?: string | undefined,
	hasTicketing?: boolean | undefined,
	hasProducts?: boolean | undefined,
	updatedAt?: string | undefined,
	createdAt?: string | undefined
};
	["EventUpdate_region_MutationInput"]:EventUpdate_region_MutationInput;
	["EventUpdate_level_MutationInput"]:EventUpdate_level_MutationInput;
	["mutationEventUpdate_SectionsInput"]: {
	startDatetime: string,
	endDatetime?: string | undefined,
	fullDay?: boolean | undefined,
	repeat?: boolean | undefined,
	toThisDay?: string | undefined,
	recurrance: ModelTypes["mutationEventUpdate_Sections_RecurranceInput"],
	sameLocation?: boolean | undefined,
	location?: string | undefined,
	district?: string | undefined,
	coordinate?: Array<number | undefined> | undefined,
	linkAddress?: boolean | undefined,
	address?: string | undefined,
	addressCoordinate?: Array<number | undefined> | undefined,
	id?: string | undefined
};
	["mutationEventUpdate_Sections_RecurranceInput"]: {
	type: ModelTypes["EventUpdate_Sections_Recurrance_type_MutationInput"],
	weekday?: Array<ModelTypes["EventUpdate_Sections_Recurrance_weekday_MutationInput"] | undefined> | undefined
};
	["EventUpdate_Sections_Recurrance_type_MutationInput"]:EventUpdate_Sections_Recurrance_type_MutationInput;
	["EventUpdate_Sections_Recurrance_weekday_MutationInput"]:EventUpdate_Sections_Recurrance_weekday_MutationInput;
	["mutationEventUpdate_TicketingInput"]: {
	startDatetime: string,
	endDatetime?: string | undefined,
	fullDay?: boolean | undefined,
	type: ModelTypes["EventUpdate_Ticketing_type_MutationInput"],
	ticketingType?: ModelTypes["EventUpdate_Ticketing_ticketingType_MutationInput"] | undefined,
	linkedTicketing?: string | undefined,
	promotionType?: ModelTypes["EventUpdate_Ticketing_promotionType_MutationInput"] | undefined,
	location?: string | undefined,
	website?: string | undefined,
	description?: string | undefined,
	id?: string | undefined
};
	["EventUpdate_Ticketing_type_MutationInput"]:EventUpdate_Ticketing_type_MutationInput;
	["EventUpdate_Ticketing_ticketingType_MutationInput"]:EventUpdate_Ticketing_ticketingType_MutationInput;
	["EventUpdate_Ticketing_promotionType_MutationInput"]:EventUpdate_Ticketing_promotionType_MutationInput;
	["mutationEventUpdate_CriteriaInput"]: {
	categories?: Array<string | undefined> | undefined,
	audiences?: Array<string | undefined> | undefined,
	tags?: Array<string | undefined> | undefined,
	specialTags?: Array<string | undefined> | undefined
};
	["mutationEventUpdate_ContactInput"]: {
	code?: number | undefined,
	phone?: string | undefined,
	page?: string | undefined,
	email?: string | undefined,
	privacy?: ModelTypes["JSON"] | undefined
};
	["mutationEventUpdate_FeesInput"]: {
	fee?: number | undefined,
	remark?: string | undefined,
	id?: string | undefined
};
	["mutationEventUpdate_ProductsInput"]: {
	startDateTime: string,
	endDateTime?: string | undefined,
	fullDay?: boolean | undefined,
	linkedProduct?: string | undefined,
	id?: string | undefined
};
	["mutationLocationInput"]: {
	region?: ModelTypes["Location_region_MutationInput"] | undefined,
	creator?: string | undefined,
	permalink?: string | undefined,
	status: string,
	level?: ModelTypes["Location_level_MutationInput"] | undefined,
	parent?: Array<string | undefined> | undefined,
	name?: string | undefined,
	thumbnail?: string | undefined,
	banner?: string | undefined,
	type: ModelTypes["Location_type_MutationInput"],
	category: ModelTypes["Location_category_MutationInput"],
	district?: string | undefined,
	coordinate?: Array<number | undefined> | undefined,
	linkAddress?: boolean | undefined,
	address?: string | undefined,
	addressCoordinate?: Array<number | undefined> | undefined,
	permanant?: boolean | undefined,
	Details?: ModelTypes["mutationLocation_DetailsInput"] | undefined,
	content?: ModelTypes["JSON"] | undefined,
	legacyContent?: string | undefined,
	showLegacyContent?: boolean | undefined,
	criteria?: ModelTypes["mutationLocation_CriteriaInput"] | undefined,
	sections?: Array<ModelTypes["mutationLocation_SectionsInput"] | undefined> | undefined,
	views?: number | undefined,
	_title?: string | undefined,
	legacyGallery?: Array<string | undefined> | undefined,
	updatedAt?: string | undefined,
	createdAt?: string | undefined
};
	["Location_region_MutationInput"]:Location_region_MutationInput;
	["Location_level_MutationInput"]:Location_level_MutationInput;
	["Location_type_MutationInput"]:Location_type_MutationInput;
	["Location_category_MutationInput"]:Location_category_MutationInput;
	["mutationLocation_DetailsInput"]: {
	code?: number | undefined,
	phone?: string | undefined,
	page?: string | undefined,
	email?: string | undefined
};
	["mutationLocation_CriteriaInput"]: {
	audiences?: Array<string | undefined> | undefined,
	dates?: Array<string | undefined> | undefined,
	categories?: Array<string | undefined> | undefined,
	tags?: Array<string | undefined> | undefined
};
	["mutationLocation_SectionsInput"]: {
	startDatetime?: string | undefined,
	endDatetime?: string | undefined,
	fullDay?: boolean | undefined,
	repeat?: boolean | undefined,
	recurrance: ModelTypes["mutationLocation_Sections_RecurranceInput"],
	id?: string | undefined
};
	["mutationLocation_Sections_RecurranceInput"]: {
	type: ModelTypes["Location_Sections_Recurrance_type_MutationInput"],
	weekday?: Array<ModelTypes["Location_Sections_Recurrance_weekday_MutationInput"] | undefined> | undefined
};
	["Location_Sections_Recurrance_type_MutationInput"]:Location_Sections_Recurrance_type_MutationInput;
	["Location_Sections_Recurrance_weekday_MutationInput"]:Location_Sections_Recurrance_weekday_MutationInput;
	["mutationLocationUpdateInput"]: {
	region?: ModelTypes["LocationUpdate_region_MutationInput"] | undefined,
	creator?: string | undefined,
	permalink?: string | undefined,
	status?: string | undefined,
	level?: ModelTypes["LocationUpdate_level_MutationInput"] | undefined,
	parent?: Array<string | undefined> | undefined,
	name?: string | undefined,
	thumbnail?: string | undefined,
	banner?: string | undefined,
	type?: ModelTypes["LocationUpdate_type_MutationInput"] | undefined,
	category?: ModelTypes["LocationUpdate_category_MutationInput"] | undefined,
	district?: string | undefined,
	coordinate?: Array<number | undefined> | undefined,
	linkAddress?: boolean | undefined,
	address?: string | undefined,
	addressCoordinate?: Array<number | undefined> | undefined,
	permanant?: boolean | undefined,
	Details?: ModelTypes["mutationLocationUpdate_DetailsInput"] | undefined,
	content?: ModelTypes["JSON"] | undefined,
	legacyContent?: string | undefined,
	showLegacyContent?: boolean | undefined,
	criteria?: ModelTypes["mutationLocationUpdate_CriteriaInput"] | undefined,
	sections?: Array<ModelTypes["mutationLocationUpdate_SectionsInput"] | undefined> | undefined,
	views?: number | undefined,
	_title?: string | undefined,
	legacyGallery?: Array<string | undefined> | undefined,
	updatedAt?: string | undefined,
	createdAt?: string | undefined
};
	["LocationUpdate_region_MutationInput"]:LocationUpdate_region_MutationInput;
	["LocationUpdate_level_MutationInput"]:LocationUpdate_level_MutationInput;
	["LocationUpdate_type_MutationInput"]:LocationUpdate_type_MutationInput;
	["LocationUpdate_category_MutationInput"]:LocationUpdate_category_MutationInput;
	["mutationLocationUpdate_DetailsInput"]: {
	code?: number | undefined,
	phone?: string | undefined,
	page?: string | undefined,
	email?: string | undefined
};
	["mutationLocationUpdate_CriteriaInput"]: {
	audiences?: Array<string | undefined> | undefined,
	dates?: Array<string | undefined> | undefined,
	categories?: Array<string | undefined> | undefined,
	tags?: Array<string | undefined> | undefined
};
	["mutationLocationUpdate_SectionsInput"]: {
	startDatetime?: string | undefined,
	endDatetime?: string | undefined,
	fullDay?: boolean | undefined,
	repeat?: boolean | undefined,
	recurrance: ModelTypes["mutationLocationUpdate_Sections_RecurranceInput"],
	id?: string | undefined
};
	["mutationLocationUpdate_Sections_RecurranceInput"]: {
	type: ModelTypes["LocationUpdate_Sections_Recurrance_type_MutationInput"],
	weekday?: Array<ModelTypes["LocationUpdate_Sections_Recurrance_weekday_MutationInput"] | undefined> | undefined
};
	["LocationUpdate_Sections_Recurrance_type_MutationInput"]:LocationUpdate_Sections_Recurrance_type_MutationInput;
	["LocationUpdate_Sections_Recurrance_weekday_MutationInput"]:LocationUpdate_Sections_Recurrance_weekday_MutationInput;
	["mutationPostInput"]: {
	title?: string | undefined,
	region?: ModelTypes["Post_region_MutationInput"] | undefined,
	authur?: string | undefined,
	publishDateTime?: string | undefined,
	permalink?: string | undefined,
	status: string,
	content?: ModelTypes["JSON"] | undefined,
	legacyContent?: string | undefined,
	showLegacyContent?: boolean | undefined,
	blogCategories?: Array<string | undefined> | undefined,
	thumbnail?: string | undefined,
	banner?: string | undefined,
	criteria?: ModelTypes["mutationPost_CriteriaInput"] | undefined,
	location?: string | undefined,
	views?: number | undefined,
	homePageInternalClick?: number | undefined,
	homePageImpression?: number | undefined,
	blogListInternalClick?: number | undefined,
	blogListImpression?: number | undefined,
	blogDetailInternalClick?: number | undefined,
	blogDetailImpression?: number | undefined,
	relatedEventInternalClick?: number | undefined,
	relatedEventImpression?: number | undefined,
	latestEventInternalClick?: number | undefined,
	latestEventImpression?: number | undefined,
	internalClick?: number | undefined,
	totalImpression?: number | undefined,
	externalClick?: number | undefined,
	outboundClick?: number | undefined,
	legacyGallery?: Array<string | undefined> | undefined,
	updatedAt?: string | undefined,
	createdAt?: string | undefined
};
	["Post_region_MutationInput"]:Post_region_MutationInput;
	["mutationPost_CriteriaInput"]: {
	audiences?: Array<string | undefined> | undefined,
	dates?: Array<string | undefined> | undefined,
	categories?: Array<string | undefined> | undefined,
	tags?: Array<string | undefined> | undefined
};
	["mutationPostUpdateInput"]: {
	title?: string | undefined,
	region?: ModelTypes["PostUpdate_region_MutationInput"] | undefined,
	authur?: string | undefined,
	publishDateTime?: string | undefined,
	permalink?: string | undefined,
	status?: string | undefined,
	content?: ModelTypes["JSON"] | undefined,
	legacyContent?: string | undefined,
	showLegacyContent?: boolean | undefined,
	blogCategories?: Array<string | undefined> | undefined,
	thumbnail?: string | undefined,
	banner?: string | undefined,
	criteria?: ModelTypes["mutationPostUpdate_CriteriaInput"] | undefined,
	location?: string | undefined,
	views?: number | undefined,
	homePageInternalClick?: number | undefined,
	homePageImpression?: number | undefined,
	blogListInternalClick?: number | undefined,
	blogListImpression?: number | undefined,
	blogDetailInternalClick?: number | undefined,
	blogDetailImpression?: number | undefined,
	relatedEventInternalClick?: number | undefined,
	relatedEventImpression?: number | undefined,
	latestEventInternalClick?: number | undefined,
	latestEventImpression?: number | undefined,
	internalClick?: number | undefined,
	totalImpression?: number | undefined,
	externalClick?: number | undefined,
	outboundClick?: number | undefined,
	legacyGallery?: Array<string | undefined> | undefined,
	updatedAt?: string | undefined,
	createdAt?: string | undefined
};
	["PostUpdate_region_MutationInput"]:PostUpdate_region_MutationInput;
	["mutationPostUpdate_CriteriaInput"]: {
	audiences?: Array<string | undefined> | undefined,
	dates?: Array<string | undefined> | undefined,
	categories?: Array<string | undefined> | undefined,
	tags?: Array<string | undefined> | undefined
};
	["mutationTicketingInput"]: {
	status?: ModelTypes["Ticketing_status_MutationInput"] | undefined,
	creator?: string | undefined,
	productType: string,
	productName?: string | undefined,
	referenceId?: string | undefined,
	linkedEvent?: string | undefined,
	CheckInLevel: ModelTypes["Ticketing_CheckInLevel_MutationInput"],
	displayPrice: string,
	tnc?: string | undefined,
	cutoffHours: number,
	sessions?: Array<ModelTypes["mutationTicketing_SessionsInput"] | undefined> | undefined,
	updatedAt?: string | undefined,
	createdAt?: string | undefined
};
	["Ticketing_status_MutationInput"]:Ticketing_status_MutationInput;
	["Ticketing_CheckInLevel_MutationInput"]:Ticketing_CheckInLevel_MutationInput;
	["mutationTicketing_SessionsInput"]: {
	variantId?: string | undefined,
	productId?: string | undefined,
	startDate: string,
	endDate: string,
	inventories: ModelTypes["mutationTicketing_Sessions_InventoriesInput"],
	id?: string | undefined
};
	["mutationTicketing_Sessions_InventoriesInput"]: {
	name?: string | undefined,
	quantity: number,
	unitPrice: number,
	handlingFee: number,
	totalCost?: number | undefined,
	minPurchase: number,
	maxPurchase: number,
	stocks?: ModelTypes["mutationTicketing_Sessions_Inventories_StocksInput"] | undefined,
	remarks?: string | undefined
};
	["mutationTicketing_Sessions_Inventories_StocksInput"]: {
	stockId?: string | undefined,
	remaining?: number | undefined,
	sold?: number | undefined,
	onHold?: number | undefined,
	checkedIn?: number | undefined
};
	["mutationTicketingUpdateInput"]: {
	status?: ModelTypes["TicketingUpdate_status_MutationInput"] | undefined,
	creator?: string | undefined,
	productType?: string | undefined,
	productName?: string | undefined,
	referenceId?: string | undefined,
	linkedEvent?: string | undefined,
	CheckInLevel?: ModelTypes["TicketingUpdate_CheckInLevel_MutationInput"] | undefined,
	displayPrice?: string | undefined,
	tnc?: string | undefined,
	cutoffHours?: number | undefined,
	sessions?: Array<ModelTypes["mutationTicketingUpdate_SessionsInput"] | undefined> | undefined,
	updatedAt?: string | undefined,
	createdAt?: string | undefined
};
	["TicketingUpdate_status_MutationInput"]:TicketingUpdate_status_MutationInput;
	["TicketingUpdate_CheckInLevel_MutationInput"]:TicketingUpdate_CheckInLevel_MutationInput;
	["mutationTicketingUpdate_SessionsInput"]: {
	variantId?: string | undefined,
	productId?: string | undefined,
	startDate: string,
	endDate: string,
	inventories: ModelTypes["mutationTicketingUpdate_Sessions_InventoriesInput"],
	id?: string | undefined
};
	["mutationTicketingUpdate_Sessions_InventoriesInput"]: {
	name?: string | undefined,
	quantity: number,
	unitPrice: number,
	handlingFee: number,
	totalCost?: number | undefined,
	minPurchase: number,
	maxPurchase: number,
	stocks?: ModelTypes["mutationTicketingUpdate_Sessions_Inventories_StocksInput"] | undefined,
	remarks?: string | undefined
};
	["mutationTicketingUpdate_Sessions_Inventories_StocksInput"]: {
	stockId?: string | undefined,
	remaining?: number | undefined,
	sold?: number | undefined,
	onHold?: number | undefined,
	checkedIn?: number | undefined
};
	["mutationProductInput"]: {
	status?: ModelTypes["Product_status_MutationInput"] | undefined,
	creator?: string | undefined,
	productType: string,
	productName?: string | undefined,
	referenceId?: string | undefined,
	linkedEvent?: string | undefined,
	CheckInLevel: ModelTypes["Product_CheckInLevel_MutationInput"],
	thumbnail: string,
	displayPrice: string,
	transactionType: string,
	website?: string | undefined,
	tnc?: string | undefined,
	remarks?: string | undefined,
	sessions?: Array<ModelTypes["mutationProduct_SessionsInput"] | undefined> | undefined,
	updatedAt?: string | undefined,
	createdAt?: string | undefined
};
	["Product_status_MutationInput"]:Product_status_MutationInput;
	["Product_CheckInLevel_MutationInput"]:Product_CheckInLevel_MutationInput;
	["mutationProduct_SessionsInput"]: {
	variantId?: string | undefined,
	productId?: string | undefined,
	name?: string | undefined,
	inventories?: ModelTypes["mutationProduct_Sessions_InventoriesInput"] | undefined,
	id?: string | undefined
};
	["mutationProduct_Sessions_InventoriesInput"]: {
	quantity: number,
	unitPrice: number,
	handlingFee: number,
	totalCost?: number | undefined,
	minPurchase: number,
	maxPurchase: number,
	stocks?: ModelTypes["mutationProduct_Sessions_Inventories_StocksInput"] | undefined
};
	["mutationProduct_Sessions_Inventories_StocksInput"]: {
	stockId?: string | undefined,
	remaining?: number | undefined,
	sold?: number | undefined,
	onHold?: number | undefined,
	checkedIn?: number | undefined
};
	["mutationProductUpdateInput"]: {
	status?: ModelTypes["ProductUpdate_status_MutationInput"] | undefined,
	creator?: string | undefined,
	productType?: string | undefined,
	productName?: string | undefined,
	referenceId?: string | undefined,
	linkedEvent?: string | undefined,
	CheckInLevel?: ModelTypes["ProductUpdate_CheckInLevel_MutationInput"] | undefined,
	thumbnail?: string | undefined,
	displayPrice?: string | undefined,
	transactionType?: string | undefined,
	website?: string | undefined,
	tnc?: string | undefined,
	remarks?: string | undefined,
	sessions?: Array<ModelTypes["mutationProductUpdate_SessionsInput"] | undefined> | undefined,
	updatedAt?: string | undefined,
	createdAt?: string | undefined
};
	["ProductUpdate_status_MutationInput"]:ProductUpdate_status_MutationInput;
	["ProductUpdate_CheckInLevel_MutationInput"]:ProductUpdate_CheckInLevel_MutationInput;
	["mutationProductUpdate_SessionsInput"]: {
	variantId?: string | undefined,
	productId?: string | undefined,
	name?: string | undefined,
	inventories?: ModelTypes["mutationProductUpdate_Sessions_InventoriesInput"] | undefined,
	id?: string | undefined
};
	["mutationProductUpdate_Sessions_InventoriesInput"]: {
	quantity: number,
	unitPrice: number,
	handlingFee: number,
	totalCost?: number | undefined,
	minPurchase: number,
	maxPurchase: number,
	stocks?: ModelTypes["mutationProductUpdate_Sessions_Inventories_StocksInput"] | undefined
};
	["mutationProductUpdate_Sessions_Inventories_StocksInput"]: {
	stockId?: string | undefined,
	remaining?: number | undefined,
	sold?: number | undefined,
	onHold?: number | undefined,
	checkedIn?: number | undefined
};
	["mutationInventoryStatusInput"]: {
	variantId?: string | undefined,
	variantName?: string | undefined,
	productType?: string | undefined,
	stockUpdateStatus: ModelTypes["InventoryStatus_stockUpdateStatus_MutationInput"],
	stockUpdateAmount: number,
	user?: string | undefined,
	publicUserPhone?: string | undefined,
	expiredAt?: string | undefined,
	updatedAt?: string | undefined,
	createdAt?: string | undefined
};
	["InventoryStatus_stockUpdateStatus_MutationInput"]:InventoryStatus_stockUpdateStatus_MutationInput;
	["mutationInventoryStatusUpdateInput"]: {
	variantId?: string | undefined,
	variantName?: string | undefined,
	productType?: string | undefined,
	stockUpdateStatus?: ModelTypes["InventoryStatusUpdate_stockUpdateStatus_MutationInput"] | undefined,
	stockUpdateAmount?: number | undefined,
	user?: string | undefined,
	publicUserPhone?: string | undefined,
	expiredAt?: string | undefined,
	updatedAt?: string | undefined,
	createdAt?: string | undefined
};
	["InventoryStatusUpdate_stockUpdateStatus_MutationInput"]:InventoryStatusUpdate_stockUpdateStatus_MutationInput;
	["mutationTransactionInput"]: {
	creator?: string | undefined,
	productName?: string | undefined,
	referenceId?: string | undefined,
	linkedEvent?: string | undefined,
	purchaseDateTime?: string | undefined,
	timableOrderId?: string | undefined,
	stripePaymentId?: string | undefined,
	totalAmount?: number | undefined,
	contactPersonName?: string | undefined,
	contactPersonPhone?: string | undefined,
	remarks?: string | undefined,
	redemptionCodes?: Array<ModelTypes["mutationTransaction_RedemptionCodesInput"] | undefined> | undefined,
	updatedAt?: string | undefined,
	createdAt?: string | undefined
};
	["mutationTransaction_RedemptionCodesInput"]: {
	code?: string | undefined,
	id?: string | undefined
};
	["mutationTransactionUpdateInput"]: {
	creator?: string | undefined,
	productName?: string | undefined,
	referenceId?: string | undefined,
	linkedEvent?: string | undefined,
	purchaseDateTime?: string | undefined,
	timableOrderId?: string | undefined,
	stripePaymentId?: string | undefined,
	totalAmount?: number | undefined,
	contactPersonName?: string | undefined,
	contactPersonPhone?: string | undefined,
	remarks?: string | undefined,
	redemptionCodes?: Array<ModelTypes["mutationTransactionUpdate_RedemptionCodesInput"] | undefined> | undefined,
	updatedAt?: string | undefined,
	createdAt?: string | undefined
};
	["mutationTransactionUpdate_RedemptionCodesInput"]: {
	code?: string | undefined,
	id?: string | undefined
};
	["mutationRedemptionCodeInput"]: {
	creator?: string | undefined,
	status: ModelTypes["RedemptionCode_status_MutationInput"],
	qrCodeId?: string | undefined,
	lastCheckedInDateTime?: string | undefined,
	voidedDateTime?: string | undefined,
	refundedDateTime?: string | undefined,
	eventStartTime?: string | undefined,
	eventEndTime?: string | undefined,
	eventPriceZone?: string | undefined,
	ownerName: string,
	phone: string,
	pdf?: string | undefined,
	variants?: Array<ModelTypes["mutationRedemptionCode_VariantsInput"] | undefined> | undefined,
	productId?: string | undefined,
	referenceId?: string | undefined,
	productType?: string | undefined,
	productName?: string | undefined,
	linkedEvent?: string | undefined,
	timableOrderId?: string | undefined,
	purchaseDateTime?: string | undefined,
	changeHistory?: Array<ModelTypes["mutationRedemptionCode_ChangeHistoryInput"] | undefined> | undefined,
	updatedAt?: string | undefined,
	createdAt?: string | undefined
};
	["RedemptionCode_status_MutationInput"]:RedemptionCode_status_MutationInput;
	["mutationRedemptionCode_VariantsInput"]: {
	variantId?: string | undefined,
	name?: string | undefined,
	quantity?: string | undefined,
	totalCost?: string | undefined,
	id?: string | undefined
};
	["mutationRedemptionCode_ChangeHistoryInput"]: {
	timestamp: string,
	field: string,
	oldValue: string,
	newValue: string,
	id?: string | undefined
};
	["mutationRedemptionCodeUpdateInput"]: {
	creator?: string | undefined,
	status?: ModelTypes["RedemptionCodeUpdate_status_MutationInput"] | undefined,
	qrCodeId?: string | undefined,
	lastCheckedInDateTime?: string | undefined,
	voidedDateTime?: string | undefined,
	refundedDateTime?: string | undefined,
	eventStartTime?: string | undefined,
	eventEndTime?: string | undefined,
	eventPriceZone?: string | undefined,
	ownerName?: string | undefined,
	phone?: string | undefined,
	pdf?: string | undefined,
	variants?: Array<ModelTypes["mutationRedemptionCodeUpdate_VariantsInput"] | undefined> | undefined,
	productId?: string | undefined,
	referenceId?: string | undefined,
	productType?: string | undefined,
	productName?: string | undefined,
	linkedEvent?: string | undefined,
	timableOrderId?: string | undefined,
	purchaseDateTime?: string | undefined,
	changeHistory?: Array<ModelTypes["mutationRedemptionCodeUpdate_ChangeHistoryInput"] | undefined> | undefined,
	updatedAt?: string | undefined,
	createdAt?: string | undefined
};
	["RedemptionCodeUpdate_status_MutationInput"]:RedemptionCodeUpdate_status_MutationInput;
	["mutationRedemptionCodeUpdate_VariantsInput"]: {
	variantId?: string | undefined,
	name?: string | undefined,
	quantity?: string | undefined,
	totalCost?: string | undefined,
	id?: string | undefined
};
	["mutationRedemptionCodeUpdate_ChangeHistoryInput"]: {
	timestamp: string,
	field: string,
	oldValue: string,
	newValue: string,
	id?: string | undefined
};
	["mutationRedemptionPdfInput"]: {
	updatedAt?: string | undefined,
	createdAt?: string | undefined,
	url?: string | undefined,
	filename?: string | undefined,
	mimeType?: string | undefined,
	filesize?: number | undefined,
	width?: number | undefined,
	height?: number | undefined,
	focalX?: number | undefined,
	focalY?: number | undefined
};
	["mutationRedemptionPdfUpdateInput"]: {
	updatedAt?: string | undefined,
	createdAt?: string | undefined,
	url?: string | undefined,
	filename?: string | undefined,
	mimeType?: string | undefined,
	filesize?: number | undefined,
	width?: number | undefined,
	height?: number | undefined,
	focalX?: number | undefined,
	focalY?: number | undefined
};
	["mutationCheckInRedemptionInput"]: {
	creator?: string | undefined,
	qrCodeId?: string | undefined,
	codeownerName?: string | undefined,
	name?: string | undefined,
	phone?: string | undefined,
	linkedEvent?: string | undefined,
	entryNumber?: string | undefined,
	scanAt?: string | undefined,
	uploadAt?: string | undefined,
	status?: string | undefined,
	failReason?: string | undefined,
	variants?: Array<ModelTypes["mutationCheckInRedemption_VariantsInput"] | undefined> | undefined,
	checkInAppSelectionId?: string | undefined,
	qrCodeStatus?: string | undefined,
	productType?: string | undefined,
	updatedAt?: string | undefined,
	createdAt?: string | undefined
};
	["mutationCheckInRedemption_VariantsInput"]: {
	variantId?: string | undefined,
	name?: string | undefined,
	quantity?: string | undefined,
	totalCost?: string | undefined,
	id?: string | undefined
};
	["mutationCheckInRedemptionUpdateInput"]: {
	creator?: string | undefined,
	qrCodeId?: string | undefined,
	codeownerName?: string | undefined,
	name?: string | undefined,
	phone?: string | undefined,
	linkedEvent?: string | undefined,
	entryNumber?: string | undefined,
	scanAt?: string | undefined,
	uploadAt?: string | undefined,
	status?: string | undefined,
	failReason?: string | undefined,
	variants?: Array<ModelTypes["mutationCheckInRedemptionUpdate_VariantsInput"] | undefined> | undefined,
	checkInAppSelectionId?: string | undefined,
	qrCodeStatus?: string | undefined,
	productType?: string | undefined,
	updatedAt?: string | undefined,
	createdAt?: string | undefined
};
	["mutationCheckInRedemptionUpdate_VariantsInput"]: {
	variantId?: string | undefined,
	name?: string | undefined,
	quantity?: string | undefined,
	totalCost?: string | undefined,
	id?: string | undefined
};
	["mutationUserInput"]: {
	region?: ModelTypes["User_region_MutationInput"] | undefined,
	name?: string | undefined,
	displayEmail?: string | undefined,
	legacyUsername?: string | undefined,
	roles: Array<ModelTypes["User_roles_MutationInput"] | undefined>,
	member?: ModelTypes["mutationUser_MemberInput"] | undefined,
	organiser?: ModelTypes["mutationUser_OrganiserInput"] | undefined,
	emailVerified?: boolean | undefined,
	sso?: ModelTypes["mutationUser_SsoInput"] | undefined,
	oldPassword?: string | undefined,
	status?: ModelTypes["User_status_MutationInput"] | undefined,
	updatedAt?: string | undefined,
	createdAt?: string | undefined,
	email: string,
	resetPasswordToken?: string | undefined,
	resetPasswordExpiration?: string | undefined,
	salt?: string | undefined,
	hash?: string | undefined,
	loginAttempts?: number | undefined,
	lockUntil?: string | undefined,
	password: string
};
	["User_region_MutationInput"]:User_region_MutationInput;
	["User_roles_MutationInput"]:User_roles_MutationInput;
	["mutationUser_MemberInput"]: {
	region?: ModelTypes["User_Member_region_MutationInput"] | undefined,
	language?: ModelTypes["User_Member_language_MutationInput"] | undefined,
	phone?: string | undefined,
	avatar?: string | undefined,
	birthday?: string | undefined,
	gender?: ModelTypes["User_Member_gender_MutationInput"] | undefined,
	categories?: Array<string | undefined> | undefined
};
	["User_Member_region_MutationInput"]:User_Member_region_MutationInput;
	["User_Member_language_MutationInput"]:User_Member_language_MutationInput;
	["User_Member_gender_MutationInput"]:User_Member_gender_MutationInput;
	["mutationUser_OrganiserInput"]: {
	logo?: string | undefined,
	banner?: string | undefined,
	description?: string | undefined,
	foundedIn?: string | undefined,
	address?: string | undefined,
	website?: string | undefined,
	facebook?: string | undefined,
	instagram?: string | undefined,
	language?: ModelTypes["User_Organiser_language_MutationInput"] | undefined,
	phone?: string | undefined,
	followers?: number | undefined
};
	["User_Organiser_language_MutationInput"]:User_Organiser_language_MutationInput;
	["mutationUser_SsoInput"]: {
	googleId?: string | undefined,
	facebookId?: string | undefined,
	appleId?: string | undefined
};
	["User_status_MutationInput"]:User_status_MutationInput;
	["mutationUserUpdateInput"]: {
	region?: ModelTypes["UserUpdate_region_MutationInput"] | undefined,
	name?: string | undefined,
	displayEmail?: string | undefined,
	legacyUsername?: string | undefined,
	roles?: Array<ModelTypes["UserUpdate_roles_MutationInput"] | undefined> | undefined,
	member?: ModelTypes["mutationUserUpdate_MemberInput"] | undefined,
	organiser?: ModelTypes["mutationUserUpdate_OrganiserInput"] | undefined,
	emailVerified?: boolean | undefined,
	sso?: ModelTypes["mutationUserUpdate_SsoInput"] | undefined,
	oldPassword?: string | undefined,
	status?: ModelTypes["UserUpdate_status_MutationInput"] | undefined,
	updatedAt?: string | undefined,
	createdAt?: string | undefined,
	email?: string | undefined,
	resetPasswordToken?: string | undefined,
	resetPasswordExpiration?: string | undefined,
	salt?: string | undefined,
	hash?: string | undefined,
	loginAttempts?: number | undefined,
	lockUntil?: string | undefined,
	password?: string | undefined
};
	["UserUpdate_region_MutationInput"]:UserUpdate_region_MutationInput;
	["UserUpdate_roles_MutationInput"]:UserUpdate_roles_MutationInput;
	["mutationUserUpdate_MemberInput"]: {
	region?: ModelTypes["UserUpdate_Member_region_MutationInput"] | undefined,
	language?: ModelTypes["UserUpdate_Member_language_MutationInput"] | undefined,
	phone?: string | undefined,
	avatar?: string | undefined,
	birthday?: string | undefined,
	gender?: ModelTypes["UserUpdate_Member_gender_MutationInput"] | undefined,
	categories?: Array<string | undefined> | undefined
};
	["UserUpdate_Member_region_MutationInput"]:UserUpdate_Member_region_MutationInput;
	["UserUpdate_Member_language_MutationInput"]:UserUpdate_Member_language_MutationInput;
	["UserUpdate_Member_gender_MutationInput"]:UserUpdate_Member_gender_MutationInput;
	["mutationUserUpdate_OrganiserInput"]: {
	logo?: string | undefined,
	banner?: string | undefined,
	description?: string | undefined,
	foundedIn?: string | undefined,
	address?: string | undefined,
	website?: string | undefined,
	facebook?: string | undefined,
	instagram?: string | undefined,
	language?: ModelTypes["UserUpdate_Organiser_language_MutationInput"] | undefined,
	phone?: string | undefined,
	followers?: number | undefined
};
	["UserUpdate_Organiser_language_MutationInput"]:UserUpdate_Organiser_language_MutationInput;
	["mutationUserUpdate_SsoInput"]: {
	googleId?: string | undefined,
	facebookId?: string | undefined,
	appleId?: string | undefined
};
	["UserUpdate_status_MutationInput"]:UserUpdate_status_MutationInput;
	["usersRefreshedUser"]: {
		exp?: number | undefined,
	refreshedToken?: string | undefined,
	strategy?: string | undefined,
	user?: ModelTypes["usersJWT"] | undefined
};
	["usersJWT"]: {
		name?: string | undefined,
	email: ModelTypes["EmailAddress"],
	collection: string
};
	["usersLoginResult"]: {
		exp?: number | undefined,
	token?: string | undefined,
	user?: ModelTypes["User"] | undefined
};
	["usersResetPassword"]: {
		token?: string | undefined,
	user?: ModelTypes["User"] | undefined
};
	["mutationBookmarkInput"]: {
	user?: ModelTypes["Bookmark_UserRelationshipInput"] | undefined,
	item?: ModelTypes["Bookmark_ItemRelationshipInput"] | undefined,
	updatedAt?: string | undefined,
	createdAt?: string | undefined
};
	["Bookmark_UserRelationshipInput"]: {
	relationTo?: ModelTypes["Bookmark_UserRelationshipInputRelationTo"] | undefined,
	value?: ModelTypes["JSON"] | undefined
};
	["Bookmark_UserRelationshipInputRelationTo"]:Bookmark_UserRelationshipInputRelationTo;
	["Bookmark_ItemRelationshipInput"]: {
	relationTo?: ModelTypes["Bookmark_ItemRelationshipInputRelationTo"] | undefined,
	value?: ModelTypes["JSON"] | undefined
};
	["Bookmark_ItemRelationshipInputRelationTo"]:Bookmark_ItemRelationshipInputRelationTo;
	["mutationBookmarkUpdateInput"]: {
	user?: ModelTypes["BookmarkUpdate_UserRelationshipInput"] | undefined,
	item?: ModelTypes["BookmarkUpdate_ItemRelationshipInput"] | undefined,
	updatedAt?: string | undefined,
	createdAt?: string | undefined
};
	["BookmarkUpdate_UserRelationshipInput"]: {
	relationTo?: ModelTypes["BookmarkUpdate_UserRelationshipInputRelationTo"] | undefined,
	value?: ModelTypes["JSON"] | undefined
};
	["BookmarkUpdate_UserRelationshipInputRelationTo"]:BookmarkUpdate_UserRelationshipInputRelationTo;
	["BookmarkUpdate_ItemRelationshipInput"]: {
	relationTo?: ModelTypes["BookmarkUpdate_ItemRelationshipInputRelationTo"] | undefined,
	value?: ModelTypes["JSON"] | undefined
};
	["BookmarkUpdate_ItemRelationshipInputRelationTo"]:BookmarkUpdate_ItemRelationshipInputRelationTo;
	["mutationWebAppBookmarkInput"]: {
	user?: ModelTypes["WebAppBookmark_UserRelationshipInput"] | undefined,
	event?: ModelTypes["WebAppBookmark_EventRelationshipInput"] | undefined,
	productType?: ModelTypes["WebAppBookmark_productType_MutationInput"] | undefined,
	item?: string | undefined,
	entryNumber?: string | undefined,
	updatedAt?: string | undefined,
	createdAt?: string | undefined
};
	["WebAppBookmark_UserRelationshipInput"]: {
	relationTo?: ModelTypes["WebAppBookmark_UserRelationshipInputRelationTo"] | undefined,
	value?: ModelTypes["JSON"] | undefined
};
	["WebAppBookmark_UserRelationshipInputRelationTo"]:WebAppBookmark_UserRelationshipInputRelationTo;
	["WebAppBookmark_EventRelationshipInput"]: {
	relationTo?: ModelTypes["WebAppBookmark_EventRelationshipInputRelationTo"] | undefined,
	value?: ModelTypes["JSON"] | undefined
};
	["WebAppBookmark_EventRelationshipInputRelationTo"]:WebAppBookmark_EventRelationshipInputRelationTo;
	["WebAppBookmark_productType_MutationInput"]:WebAppBookmark_productType_MutationInput;
	["mutationWebAppBookmarkUpdateInput"]: {
	user?: ModelTypes["WebAppBookmarkUpdate_UserRelationshipInput"] | undefined,
	event?: ModelTypes["WebAppBookmarkUpdate_EventRelationshipInput"] | undefined,
	productType?: ModelTypes["WebAppBookmarkUpdate_productType_MutationInput"] | undefined,
	item?: string | undefined,
	entryNumber?: string | undefined,
	updatedAt?: string | undefined,
	createdAt?: string | undefined
};
	["WebAppBookmarkUpdate_UserRelationshipInput"]: {
	relationTo?: ModelTypes["WebAppBookmarkUpdate_UserRelationshipInputRelationTo"] | undefined,
	value?: ModelTypes["JSON"] | undefined
};
	["WebAppBookmarkUpdate_UserRelationshipInputRelationTo"]:WebAppBookmarkUpdate_UserRelationshipInputRelationTo;
	["WebAppBookmarkUpdate_EventRelationshipInput"]: {
	relationTo?: ModelTypes["WebAppBookmarkUpdate_EventRelationshipInputRelationTo"] | undefined,
	value?: ModelTypes["JSON"] | undefined
};
	["WebAppBookmarkUpdate_EventRelationshipInputRelationTo"]:WebAppBookmarkUpdate_EventRelationshipInputRelationTo;
	["WebAppBookmarkUpdate_productType_MutationInput"]:WebAppBookmarkUpdate_productType_MutationInput;
	["mutationViewRecordInput"]: {
	user?: ModelTypes["ViewRecord_UserRelationshipInput"] | undefined,
	item?: ModelTypes["ViewRecord_ItemRelationshipInput"] | undefined,
	updatedAt?: string | undefined,
	createdAt?: string | undefined
};
	["ViewRecord_UserRelationshipInput"]: {
	relationTo?: ModelTypes["ViewRecord_UserRelationshipInputRelationTo"] | undefined,
	value?: ModelTypes["JSON"] | undefined
};
	["ViewRecord_UserRelationshipInputRelationTo"]:ViewRecord_UserRelationshipInputRelationTo;
	["ViewRecord_ItemRelationshipInput"]: {
	relationTo?: ModelTypes["ViewRecord_ItemRelationshipInputRelationTo"] | undefined,
	value?: ModelTypes["JSON"] | undefined
};
	["ViewRecord_ItemRelationshipInputRelationTo"]:ViewRecord_ItemRelationshipInputRelationTo;
	["mutationViewRecordUpdateInput"]: {
	user?: ModelTypes["ViewRecordUpdate_UserRelationshipInput"] | undefined,
	item?: ModelTypes["ViewRecordUpdate_ItemRelationshipInput"] | undefined,
	updatedAt?: string | undefined,
	createdAt?: string | undefined
};
	["ViewRecordUpdate_UserRelationshipInput"]: {
	relationTo?: ModelTypes["ViewRecordUpdate_UserRelationshipInputRelationTo"] | undefined,
	value?: ModelTypes["JSON"] | undefined
};
	["ViewRecordUpdate_UserRelationshipInputRelationTo"]:ViewRecordUpdate_UserRelationshipInputRelationTo;
	["ViewRecordUpdate_ItemRelationshipInput"]: {
	relationTo?: ModelTypes["ViewRecordUpdate_ItemRelationshipInputRelationTo"] | undefined,
	value?: ModelTypes["JSON"] | undefined
};
	["ViewRecordUpdate_ItemRelationshipInputRelationTo"]:ViewRecordUpdate_ItemRelationshipInputRelationTo;
	["mutationCommentInput"]: {
	commenter?: string | undefined,
	content?: string | undefined,
	image?: string | undefined,
	target?: ModelTypes["Comment_TargetRelationshipInput"] | undefined,
	parent?: ModelTypes["Comment_ParentRelationshipInput"] | undefined,
	isReviewed?: boolean | undefined,
	reviewedBy?: string | undefined,
	banned?: boolean | undefined,
	type?: string | undefined,
	updatedAt?: string | undefined,
	createdAt?: string | undefined
};
	["Comment_TargetRelationshipInput"]: {
	relationTo?: ModelTypes["Comment_TargetRelationshipInputRelationTo"] | undefined,
	value?: ModelTypes["JSON"] | undefined
};
	["Comment_TargetRelationshipInputRelationTo"]:Comment_TargetRelationshipInputRelationTo;
	["Comment_ParentRelationshipInput"]: {
	relationTo?: ModelTypes["Comment_ParentRelationshipInputRelationTo"] | undefined,
	value?: ModelTypes["JSON"] | undefined
};
	["Comment_ParentRelationshipInputRelationTo"]:Comment_ParentRelationshipInputRelationTo;
	["mutationCommentUpdateInput"]: {
	commenter?: string | undefined,
	content?: string | undefined,
	image?: string | undefined,
	target?: ModelTypes["CommentUpdate_TargetRelationshipInput"] | undefined,
	parent?: ModelTypes["CommentUpdate_ParentRelationshipInput"] | undefined,
	isReviewed?: boolean | undefined,
	reviewedBy?: string | undefined,
	banned?: boolean | undefined,
	type?: string | undefined,
	updatedAt?: string | undefined,
	createdAt?: string | undefined
};
	["CommentUpdate_TargetRelationshipInput"]: {
	relationTo?: ModelTypes["CommentUpdate_TargetRelationshipInputRelationTo"] | undefined,
	value?: ModelTypes["JSON"] | undefined
};
	["CommentUpdate_TargetRelationshipInputRelationTo"]:CommentUpdate_TargetRelationshipInputRelationTo;
	["CommentUpdate_ParentRelationshipInput"]: {
	relationTo?: ModelTypes["CommentUpdate_ParentRelationshipInputRelationTo"] | undefined,
	value?: ModelTypes["JSON"] | undefined
};
	["CommentUpdate_ParentRelationshipInputRelationTo"]:CommentUpdate_ParentRelationshipInputRelationTo;
	["mutationSubscriptionInput"]: {
	user?: ModelTypes["Subscription_UserRelationshipInput"] | undefined,
	organiser?: ModelTypes["Subscription_OrganiserRelationshipInput"] | undefined,
	updatedAt?: string | undefined,
	createdAt?: string | undefined
};
	["Subscription_UserRelationshipInput"]: {
	relationTo?: ModelTypes["Subscription_UserRelationshipInputRelationTo"] | undefined,
	value?: ModelTypes["JSON"] | undefined
};
	["Subscription_UserRelationshipInputRelationTo"]:Subscription_UserRelationshipInputRelationTo;
	["Subscription_OrganiserRelationshipInput"]: {
	relationTo?: ModelTypes["Subscription_OrganiserRelationshipInputRelationTo"] | undefined,
	value?: ModelTypes["JSON"] | undefined
};
	["Subscription_OrganiserRelationshipInputRelationTo"]:Subscription_OrganiserRelationshipInputRelationTo;
	["mutationSubscriptionUpdateInput"]: {
	user?: ModelTypes["SubscriptionUpdate_UserRelationshipInput"] | undefined,
	organiser?: ModelTypes["SubscriptionUpdate_OrganiserRelationshipInput"] | undefined,
	updatedAt?: string | undefined,
	createdAt?: string | undefined
};
	["SubscriptionUpdate_UserRelationshipInput"]: {
	relationTo?: ModelTypes["SubscriptionUpdate_UserRelationshipInputRelationTo"] | undefined,
	value?: ModelTypes["JSON"] | undefined
};
	["SubscriptionUpdate_UserRelationshipInputRelationTo"]:SubscriptionUpdate_UserRelationshipInputRelationTo;
	["SubscriptionUpdate_OrganiserRelationshipInput"]: {
	relationTo?: ModelTypes["SubscriptionUpdate_OrganiserRelationshipInputRelationTo"] | undefined,
	value?: ModelTypes["JSON"] | undefined
};
	["SubscriptionUpdate_OrganiserRelationshipInputRelationTo"]:SubscriptionUpdate_OrganiserRelationshipInputRelationTo;
	["mutationNewsletterInput"]: {
	email: string,
	region?: ModelTypes["Newsletter_region_MutationInput"] | undefined,
	updatedAt?: string | undefined,
	createdAt?: string | undefined
};
	["Newsletter_region_MutationInput"]:Newsletter_region_MutationInput;
	["mutationNewsletterUpdateInput"]: {
	email?: string | undefined,
	region?: ModelTypes["NewsletterUpdate_region_MutationInput"] | undefined,
	updatedAt?: string | undefined,
	createdAt?: string | undefined
};
	["NewsletterUpdate_region_MutationInput"]:NewsletterUpdate_region_MutationInput;
	["mutationBlogCategoryInput"]: {
	name?: string | undefined,
	value: string,
	icon?: string | undefined,
	region?: ModelTypes["BlogCategory_region_MutationInput"] | undefined,
	updatedAt?: string | undefined,
	createdAt?: string | undefined
};
	["BlogCategory_region_MutationInput"]:BlogCategory_region_MutationInput;
	["mutationBlogCategoryUpdateInput"]: {
	name?: string | undefined,
	value?: string | undefined,
	icon?: string | undefined,
	region?: ModelTypes["BlogCategoryUpdate_region_MutationInput"] | undefined,
	updatedAt?: string | undefined,
	createdAt?: string | undefined
};
	["BlogCategoryUpdate_region_MutationInput"]:BlogCategoryUpdate_region_MutationInput;
	["mutationTagInput"]: {
	name: string,
	value: string,
	region?: ModelTypes["Tag_region_MutationInput"] | undefined,
	count?: number | undefined,
	skipCountCheck?: boolean | undefined,
	removeNextCron?: boolean | undefined
};
	["Tag_region_MutationInput"]:Tag_region_MutationInput;
	["mutationTagUpdateInput"]: {
	name?: string | undefined,
	value?: string | undefined,
	region?: ModelTypes["TagUpdate_region_MutationInput"] | undefined,
	count?: number | undefined,
	skipCountCheck?: boolean | undefined,
	removeNextCron?: boolean | undefined
};
	["TagUpdate_region_MutationInput"]:TagUpdate_region_MutationInput;
	["mutationSpecialTagInput"]: {
	name: string,
	value: string,
	region?: ModelTypes["SpecialTag_region_MutationInput"] | undefined
};
	["SpecialTag_region_MutationInput"]:SpecialTag_region_MutationInput;
	["mutationSpecialTagUpdateInput"]: {
	name?: string | undefined,
	value?: string | undefined,
	region?: ModelTypes["SpecialTagUpdate_region_MutationInput"] | undefined
};
	["SpecialTagUpdate_region_MutationInput"]:SpecialTagUpdate_region_MutationInput;
	["mutationDateInput"]: {
	name?: string | undefined,
	value: string,
	type: ModelTypes["Date_type_MutationInput"],
	start?: string | undefined,
	end?: string | undefined,
	_title?: string | undefined,
	region?: ModelTypes["Date_region_MutationInput"] | undefined,
	updatedAt?: string | undefined,
	createdAt?: string | undefined
};
	["Date_type_MutationInput"]:Date_type_MutationInput;
	["Date_region_MutationInput"]:Date_region_MutationInput;
	["mutationDateUpdateInput"]: {
	name?: string | undefined,
	value?: string | undefined,
	type?: ModelTypes["DateUpdate_type_MutationInput"] | undefined,
	start?: string | undefined,
	end?: string | undefined,
	_title?: string | undefined,
	region?: ModelTypes["DateUpdate_region_MutationInput"] | undefined,
	updatedAt?: string | undefined,
	createdAt?: string | undefined
};
	["DateUpdate_type_MutationInput"]:DateUpdate_type_MutationInput;
	["DateUpdate_region_MutationInput"]:DateUpdate_region_MutationInput;
	["mutationDistrictInput"]: {
	name?: string | undefined,
	level: number,
	children?: Array<string | undefined> | undefined,
	value: string,
	lat?: number | undefined,
	long?: number | undefined,
	radius?: number | undefined,
	region?: ModelTypes["District_region_MutationInput"] | undefined,
	updatedAt?: string | undefined,
	createdAt?: string | undefined
};
	["District_region_MutationInput"]:District_region_MutationInput;
	["mutationDistrictUpdateInput"]: {
	name?: string | undefined,
	level?: number | undefined,
	children?: Array<string | undefined> | undefined,
	value?: string | undefined,
	lat?: number | undefined,
	long?: number | undefined,
	radius?: number | undefined,
	region?: ModelTypes["DistrictUpdate_region_MutationInput"] | undefined,
	updatedAt?: string | undefined,
	createdAt?: string | undefined
};
	["DistrictUpdate_region_MutationInput"]:DistrictUpdate_region_MutationInput;
	["mutationAudienceInput"]: {
	name?: string | undefined,
	value: string,
	icon?: string | undefined,
	region?: ModelTypes["Audience_region_MutationInput"] | undefined,
	updatedAt?: string | undefined,
	createdAt?: string | undefined
};
	["Audience_region_MutationInput"]:Audience_region_MutationInput;
	["mutationAudienceUpdateInput"]: {
	name?: string | undefined,
	value?: string | undefined,
	icon?: string | undefined,
	region?: ModelTypes["AudienceUpdate_region_MutationInput"] | undefined,
	updatedAt?: string | undefined,
	createdAt?: string | undefined
};
	["AudienceUpdate_region_MutationInput"]:AudienceUpdate_region_MutationInput;
	["mutationEventCategoryInput"]: {
	name?: string | undefined,
	value: string,
	tags?: Array<string | undefined> | undefined,
	icon?: string | undefined,
	region?: ModelTypes["EventCategory_region_MutationInput"] | undefined,
	updatedAt?: string | undefined,
	createdAt?: string | undefined
};
	["EventCategory_region_MutationInput"]:EventCategory_region_MutationInput;
	["mutationEventCategoryUpdateInput"]: {
	name?: string | undefined,
	value?: string | undefined,
	tags?: Array<string | undefined> | undefined,
	icon?: string | undefined,
	region?: ModelTypes["EventCategoryUpdate_region_MutationInput"] | undefined,
	updatedAt?: string | undefined,
	createdAt?: string | undefined
};
	["EventCategoryUpdate_region_MutationInput"]:EventCategoryUpdate_region_MutationInput;
	["mutationPrivateMediaInput"]: {
	alt?: string | undefined,
	updatedAt?: string | undefined,
	createdAt?: string | undefined,
	url?: string | undefined,
	filename?: string | undefined,
	mimeType?: string | undefined,
	filesize?: number | undefined,
	width?: number | undefined,
	height?: number | undefined,
	focalX?: number | undefined,
	focalY?: number | undefined,
	sizes?: ModelTypes["mutationPrivateMedia_SizesInput"] | undefined
};
	["mutationPrivateMedia_SizesInput"]: {
	thumbnail?: ModelTypes["mutationPrivateMedia_Sizes_ThumbnailInput"] | undefined,
	portrait?: ModelTypes["mutationPrivateMedia_Sizes_PortraitInput"] | undefined,
	hero?: ModelTypes["mutationPrivateMedia_Sizes_HeroInput"] | undefined,
	thumbnail_jpeg?: ModelTypes["mutationPrivateMedia_Sizes_Thumbnail_jpegInput"] | undefined,
	portrait_jpeg?: ModelTypes["mutationPrivateMedia_Sizes_Portrait_jpegInput"] | undefined,
	hero_jpeg?: ModelTypes["mutationPrivateMedia_Sizes_Hero_jpegInput"] | undefined
};
	["mutationPrivateMedia_Sizes_ThumbnailInput"]: {
	url?: string | undefined,
	width?: number | undefined,
	height?: number | undefined,
	mimeType?: string | undefined,
	filesize?: number | undefined,
	filename?: string | undefined
};
	["mutationPrivateMedia_Sizes_PortraitInput"]: {
	url?: string | undefined,
	width?: number | undefined,
	height?: number | undefined,
	mimeType?: string | undefined,
	filesize?: number | undefined,
	filename?: string | undefined
};
	["mutationPrivateMedia_Sizes_HeroInput"]: {
	url?: string | undefined,
	width?: number | undefined,
	height?: number | undefined,
	mimeType?: string | undefined,
	filesize?: number | undefined,
	filename?: string | undefined
};
	["mutationPrivateMedia_Sizes_Thumbnail_jpegInput"]: {
	url?: string | undefined,
	width?: number | undefined,
	height?: number | undefined,
	mimeType?: string | undefined,
	filesize?: number | undefined,
	filename?: string | undefined
};
	["mutationPrivateMedia_Sizes_Portrait_jpegInput"]: {
	url?: string | undefined,
	width?: number | undefined,
	height?: number | undefined,
	mimeType?: string | undefined,
	filesize?: number | undefined,
	filename?: string | undefined
};
	["mutationPrivateMedia_Sizes_Hero_jpegInput"]: {
	url?: string | undefined,
	width?: number | undefined,
	height?: number | undefined,
	mimeType?: string | undefined,
	filesize?: number | undefined,
	filename?: string | undefined
};
	["mutationPrivateMediaUpdateInput"]: {
	alt?: string | undefined,
	updatedAt?: string | undefined,
	createdAt?: string | undefined,
	url?: string | undefined,
	filename?: string | undefined,
	mimeType?: string | undefined,
	filesize?: number | undefined,
	width?: number | undefined,
	height?: number | undefined,
	focalX?: number | undefined,
	focalY?: number | undefined,
	sizes?: ModelTypes["mutationPrivateMediaUpdate_SizesInput"] | undefined
};
	["mutationPrivateMediaUpdate_SizesInput"]: {
	thumbnail?: ModelTypes["mutationPrivateMediaUpdate_Sizes_ThumbnailInput"] | undefined,
	portrait?: ModelTypes["mutationPrivateMediaUpdate_Sizes_PortraitInput"] | undefined,
	hero?: ModelTypes["mutationPrivateMediaUpdate_Sizes_HeroInput"] | undefined,
	thumbnail_jpeg?: ModelTypes["mutationPrivateMediaUpdate_Sizes_Thumbnail_jpegInput"] | undefined,
	portrait_jpeg?: ModelTypes["mutationPrivateMediaUpdate_Sizes_Portrait_jpegInput"] | undefined,
	hero_jpeg?: ModelTypes["mutationPrivateMediaUpdate_Sizes_Hero_jpegInput"] | undefined
};
	["mutationPrivateMediaUpdate_Sizes_ThumbnailInput"]: {
	url?: string | undefined,
	width?: number | undefined,
	height?: number | undefined,
	mimeType?: string | undefined,
	filesize?: number | undefined,
	filename?: string | undefined
};
	["mutationPrivateMediaUpdate_Sizes_PortraitInput"]: {
	url?: string | undefined,
	width?: number | undefined,
	height?: number | undefined,
	mimeType?: string | undefined,
	filesize?: number | undefined,
	filename?: string | undefined
};
	["mutationPrivateMediaUpdate_Sizes_HeroInput"]: {
	url?: string | undefined,
	width?: number | undefined,
	height?: number | undefined,
	mimeType?: string | undefined,
	filesize?: number | undefined,
	filename?: string | undefined
};
	["mutationPrivateMediaUpdate_Sizes_Thumbnail_jpegInput"]: {
	url?: string | undefined,
	width?: number | undefined,
	height?: number | undefined,
	mimeType?: string | undefined,
	filesize?: number | undefined,
	filename?: string | undefined
};
	["mutationPrivateMediaUpdate_Sizes_Portrait_jpegInput"]: {
	url?: string | undefined,
	width?: number | undefined,
	height?: number | undefined,
	mimeType?: string | undefined,
	filesize?: number | undefined,
	filename?: string | undefined
};
	["mutationPrivateMediaUpdate_Sizes_Hero_jpegInput"]: {
	url?: string | undefined,
	width?: number | undefined,
	height?: number | undefined,
	mimeType?: string | undefined,
	filesize?: number | undefined,
	filename?: string | undefined
};
	["mutationMediaInput"]: {
	alt?: string | undefined,
	updatedAt?: string | undefined,
	createdAt?: string | undefined,
	url?: string | undefined,
	filename?: string | undefined,
	mimeType?: string | undefined,
	filesize?: number | undefined,
	width?: number | undefined,
	height?: number | undefined,
	focalX?: number | undefined,
	focalY?: number | undefined,
	sizes?: ModelTypes["mutationMedia_SizesInput"] | undefined
};
	["mutationMedia_SizesInput"]: {
	small?: ModelTypes["mutationMedia_Sizes_SmallInput"] | undefined,
	medium?: ModelTypes["mutationMedia_Sizes_MediumInput"] | undefined,
	large?: ModelTypes["mutationMedia_Sizes_LargeInput"] | undefined,
	small_jpeg?: ModelTypes["mutationMedia_Sizes_Small_jpegInput"] | undefined,
	medium_jpeg?: ModelTypes["mutationMedia_Sizes_Medium_jpegInput"] | undefined,
	large_jpeg?: ModelTypes["mutationMedia_Sizes_Large_jpegInput"] | undefined
};
	["mutationMedia_Sizes_SmallInput"]: {
	url?: string | undefined,
	width?: number | undefined,
	height?: number | undefined,
	mimeType?: string | undefined,
	filesize?: number | undefined,
	filename?: string | undefined
};
	["mutationMedia_Sizes_MediumInput"]: {
	url?: string | undefined,
	width?: number | undefined,
	height?: number | undefined,
	mimeType?: string | undefined,
	filesize?: number | undefined,
	filename?: string | undefined
};
	["mutationMedia_Sizes_LargeInput"]: {
	url?: string | undefined,
	width?: number | undefined,
	height?: number | undefined,
	mimeType?: string | undefined,
	filesize?: number | undefined,
	filename?: string | undefined
};
	["mutationMedia_Sizes_Small_jpegInput"]: {
	url?: string | undefined,
	width?: number | undefined,
	height?: number | undefined,
	mimeType?: string | undefined,
	filesize?: number | undefined,
	filename?: string | undefined
};
	["mutationMedia_Sizes_Medium_jpegInput"]: {
	url?: string | undefined,
	width?: number | undefined,
	height?: number | undefined,
	mimeType?: string | undefined,
	filesize?: number | undefined,
	filename?: string | undefined
};
	["mutationMedia_Sizes_Large_jpegInput"]: {
	url?: string | undefined,
	width?: number | undefined,
	height?: number | undefined,
	mimeType?: string | undefined,
	filesize?: number | undefined,
	filename?: string | undefined
};
	["mutationMediaUpdateInput"]: {
	alt?: string | undefined,
	updatedAt?: string | undefined,
	createdAt?: string | undefined,
	url?: string | undefined,
	filename?: string | undefined,
	mimeType?: string | undefined,
	filesize?: number | undefined,
	width?: number | undefined,
	height?: number | undefined,
	focalX?: number | undefined,
	focalY?: number | undefined,
	sizes?: ModelTypes["mutationMediaUpdate_SizesInput"] | undefined
};
	["mutationMediaUpdate_SizesInput"]: {
	small?: ModelTypes["mutationMediaUpdate_Sizes_SmallInput"] | undefined,
	medium?: ModelTypes["mutationMediaUpdate_Sizes_MediumInput"] | undefined,
	large?: ModelTypes["mutationMediaUpdate_Sizes_LargeInput"] | undefined,
	small_jpeg?: ModelTypes["mutationMediaUpdate_Sizes_Small_jpegInput"] | undefined,
	medium_jpeg?: ModelTypes["mutationMediaUpdate_Sizes_Medium_jpegInput"] | undefined,
	large_jpeg?: ModelTypes["mutationMediaUpdate_Sizes_Large_jpegInput"] | undefined
};
	["mutationMediaUpdate_Sizes_SmallInput"]: {
	url?: string | undefined,
	width?: number | undefined,
	height?: number | undefined,
	mimeType?: string | undefined,
	filesize?: number | undefined,
	filename?: string | undefined
};
	["mutationMediaUpdate_Sizes_MediumInput"]: {
	url?: string | undefined,
	width?: number | undefined,
	height?: number | undefined,
	mimeType?: string | undefined,
	filesize?: number | undefined,
	filename?: string | undefined
};
	["mutationMediaUpdate_Sizes_LargeInput"]: {
	url?: string | undefined,
	width?: number | undefined,
	height?: number | undefined,
	mimeType?: string | undefined,
	filesize?: number | undefined,
	filename?: string | undefined
};
	["mutationMediaUpdate_Sizes_Small_jpegInput"]: {
	url?: string | undefined,
	width?: number | undefined,
	height?: number | undefined,
	mimeType?: string | undefined,
	filesize?: number | undefined,
	filename?: string | undefined
};
	["mutationMediaUpdate_Sizes_Medium_jpegInput"]: {
	url?: string | undefined,
	width?: number | undefined,
	height?: number | undefined,
	mimeType?: string | undefined,
	filesize?: number | undefined,
	filename?: string | undefined
};
	["mutationMediaUpdate_Sizes_Large_jpegInput"]: {
	url?: string | undefined,
	width?: number | undefined,
	height?: number | undefined,
	mimeType?: string | undefined,
	filesize?: number | undefined,
	filename?: string | undefined
};
	["mutationBackupMediaInput"]: {
	alt?: string | undefined,
	creator?: string | undefined,
	updatedAt?: string | undefined,
	createdAt?: string | undefined,
	url?: string | undefined,
	filename?: string | undefined,
	mimeType?: string | undefined,
	filesize?: number | undefined,
	width?: number | undefined,
	height?: number | undefined,
	focalX?: number | undefined,
	focalY?: number | undefined,
	sizes?: ModelTypes["mutationBackupMedia_SizesInput"] | undefined
};
	["mutationBackupMedia_SizesInput"]: {
	thumbnail?: ModelTypes["mutationBackupMedia_Sizes_ThumbnailInput"] | undefined,
	sixteenByNineMedium?: ModelTypes["mutationBackupMedia_Sizes_SixteenByNineMediumInput"] | undefined
};
	["mutationBackupMedia_Sizes_ThumbnailInput"]: {
	url?: string | undefined,
	width?: number | undefined,
	height?: number | undefined,
	mimeType?: string | undefined,
	filesize?: number | undefined,
	filename?: string | undefined
};
	["mutationBackupMedia_Sizes_SixteenByNineMediumInput"]: {
	url?: string | undefined,
	width?: number | undefined,
	height?: number | undefined,
	mimeType?: string | undefined,
	filesize?: number | undefined,
	filename?: string | undefined
};
	["mutationBackupMediaUpdateInput"]: {
	alt?: string | undefined,
	creator?: string | undefined,
	updatedAt?: string | undefined,
	createdAt?: string | undefined,
	url?: string | undefined,
	filename?: string | undefined,
	mimeType?: string | undefined,
	filesize?: number | undefined,
	width?: number | undefined,
	height?: number | undefined,
	focalX?: number | undefined,
	focalY?: number | undefined,
	sizes?: ModelTypes["mutationBackupMediaUpdate_SizesInput"] | undefined
};
	["mutationBackupMediaUpdate_SizesInput"]: {
	thumbnail?: ModelTypes["mutationBackupMediaUpdate_Sizes_ThumbnailInput"] | undefined,
	sixteenByNineMedium?: ModelTypes["mutationBackupMediaUpdate_Sizes_SixteenByNineMediumInput"] | undefined
};
	["mutationBackupMediaUpdate_Sizes_ThumbnailInput"]: {
	url?: string | undefined,
	width?: number | undefined,
	height?: number | undefined,
	mimeType?: string | undefined,
	filesize?: number | undefined,
	filename?: string | undefined
};
	["mutationBackupMediaUpdate_Sizes_SixteenByNineMediumInput"]: {
	url?: string | undefined,
	width?: number | undefined,
	height?: number | undefined,
	mimeType?: string | undefined,
	filesize?: number | undefined,
	filename?: string | undefined
};
	["mutationAccountSuspendRequestInput"]: {
	user?: string | undefined,
	handledBy?: string | undefined,
	updatedAt?: string | undefined,
	createdAt?: string | undefined
};
	["mutationAccountSuspendRequestUpdateInput"]: {
	user?: string | undefined,
	handledBy?: string | undefined,
	updatedAt?: string | undefined,
	createdAt?: string | undefined
};
	["mutationDeviceInput"]: {
	user?: ModelTypes["Device_UserRelationshipInput"] | undefined,
	udid?: string | undefined,
	pushToken?: string | undefined,
	updatedAt?: string | undefined,
	createdAt?: string | undefined
};
	["Device_UserRelationshipInput"]: {
	relationTo?: ModelTypes["Device_UserRelationshipInputRelationTo"] | undefined,
	value?: ModelTypes["JSON"] | undefined
};
	["Device_UserRelationshipInputRelationTo"]:Device_UserRelationshipInputRelationTo;
	["mutationDeviceUpdateInput"]: {
	user?: ModelTypes["DeviceUpdate_UserRelationshipInput"] | undefined,
	udid?: string | undefined,
	pushToken?: string | undefined,
	updatedAt?: string | undefined,
	createdAt?: string | undefined
};
	["DeviceUpdate_UserRelationshipInput"]: {
	relationTo?: ModelTypes["DeviceUpdate_UserRelationshipInputRelationTo"] | undefined,
	value?: ModelTypes["JSON"] | undefined
};
	["DeviceUpdate_UserRelationshipInputRelationTo"]:DeviceUpdate_UserRelationshipInputRelationTo;
	["mutationNotificationInput"]: {
	receiver?: Array<string | undefined> | undefined,
	type?: ModelTypes["Notification_type_MutationInput"] | undefined,
	meta?: ModelTypes["Notification_MetaRelationshipInput"] | undefined,
	message?: string | undefined,
	sender?: string | undefined,
	readed?: boolean | undefined,
	deletedByUser?: boolean | undefined,
	updatedAt?: string | undefined,
	createdAt?: string | undefined
};
	["Notification_type_MutationInput"]:Notification_type_MutationInput;
	["Notification_MetaRelationshipInput"]: {
	relationTo?: ModelTypes["Notification_MetaRelationshipInputRelationTo"] | undefined,
	value?: ModelTypes["JSON"] | undefined
};
	["Notification_MetaRelationshipInputRelationTo"]:Notification_MetaRelationshipInputRelationTo;
	["mutationNotificationUpdateInput"]: {
	receiver?: Array<string | undefined> | undefined,
	type?: ModelTypes["NotificationUpdate_type_MutationInput"] | undefined,
	meta?: ModelTypes["NotificationUpdate_MetaRelationshipInput"] | undefined,
	message?: string | undefined,
	sender?: string | undefined,
	readed?: boolean | undefined,
	deletedByUser?: boolean | undefined,
	updatedAt?: string | undefined,
	createdAt?: string | undefined
};
	["NotificationUpdate_type_MutationInput"]:NotificationUpdate_type_MutationInput;
	["NotificationUpdate_MetaRelationshipInput"]: {
	relationTo?: ModelTypes["NotificationUpdate_MetaRelationshipInputRelationTo"] | undefined,
	value?: ModelTypes["JSON"] | undefined
};
	["NotificationUpdate_MetaRelationshipInputRelationTo"]:NotificationUpdate_MetaRelationshipInputRelationTo;
	["mutationRedirectSettingInput"]: {
	from?: string | undefined,
	to?: string | undefined,
	updatedAt?: string | undefined,
	createdAt?: string | undefined
};
	["mutationRedirectSettingUpdateInput"]: {
	from?: string | undefined,
	to?: string | undefined,
	updatedAt?: string | undefined,
	createdAt?: string | undefined
};
	["mutationEventRankingInput"]: {
	name?: string | undefined,
	visible?: boolean | undefined,
	maxItems: number,
	template: ModelTypes["EventRanking_template_MutationInput"],
	list?: Array<ModelTypes["mutationEventRanking_ListInput"] | undefined> | undefined,
	region?: ModelTypes["EventRanking_region_MutationInput"] | undefined,
	updatedAt?: string | undefined,
	createdAt?: string | undefined
};
	["EventRanking_template_MutationInput"]:EventRanking_template_MutationInput;
	["mutationEventRanking_ListInput"]: {
	item?: string | undefined,
	id?: string | undefined
};
	["EventRanking_region_MutationInput"]:EventRanking_region_MutationInput;
	["mutationEventRankingUpdateInput"]: {
	name?: string | undefined,
	visible?: boolean | undefined,
	maxItems?: number | undefined,
	template?: ModelTypes["EventRankingUpdate_template_MutationInput"] | undefined,
	list?: Array<ModelTypes["mutationEventRankingUpdate_ListInput"] | undefined> | undefined,
	region?: ModelTypes["EventRankingUpdate_region_MutationInput"] | undefined,
	updatedAt?: string | undefined,
	createdAt?: string | undefined
};
	["EventRankingUpdate_template_MutationInput"]:EventRankingUpdate_template_MutationInput;
	["mutationEventRankingUpdate_ListInput"]: {
	item?: string | undefined,
	id?: string | undefined
};
	["EventRankingUpdate_region_MutationInput"]:EventRankingUpdate_region_MutationInput;
	["mutationLocationRankingInput"]: {
	name?: string | undefined,
	visible?: boolean | undefined,
	maxItems?: number | undefined,
	template: ModelTypes["LocationRanking_template_MutationInput"],
	list?: Array<ModelTypes["mutationLocationRanking_ListInput"] | undefined> | undefined,
	region?: ModelTypes["LocationRanking_region_MutationInput"] | undefined,
	updatedAt?: string | undefined,
	createdAt?: string | undefined
};
	["LocationRanking_template_MutationInput"]:LocationRanking_template_MutationInput;
	["mutationLocationRanking_ListInput"]: {
	item?: string | undefined,
	id?: string | undefined
};
	["LocationRanking_region_MutationInput"]:LocationRanking_region_MutationInput;
	["mutationLocationRankingUpdateInput"]: {
	name?: string | undefined,
	visible?: boolean | undefined,
	maxItems?: number | undefined,
	template?: ModelTypes["LocationRankingUpdate_template_MutationInput"] | undefined,
	list?: Array<ModelTypes["mutationLocationRankingUpdate_ListInput"] | undefined> | undefined,
	region?: ModelTypes["LocationRankingUpdate_region_MutationInput"] | undefined,
	updatedAt?: string | undefined,
	createdAt?: string | undefined
};
	["LocationRankingUpdate_template_MutationInput"]:LocationRankingUpdate_template_MutationInput;
	["mutationLocationRankingUpdate_ListInput"]: {
	item?: string | undefined,
	id?: string | undefined
};
	["LocationRankingUpdate_region_MutationInput"]:LocationRankingUpdate_region_MutationInput;
	["mutationEventCalendarInput"]: {
	date: string,
	item?: string | undefined,
	title?: string | undefined,
	region?: ModelTypes["EventCalendar_region_MutationInput"] | undefined,
	updatedAt?: string | undefined,
	createdAt?: string | undefined
};
	["EventCalendar_region_MutationInput"]:EventCalendar_region_MutationInput;
	["mutationEventCalendarUpdateInput"]: {
	date?: string | undefined,
	item?: string | undefined,
	title?: string | undefined,
	region?: ModelTypes["EventCalendarUpdate_region_MutationInput"] | undefined,
	updatedAt?: string | undefined,
	createdAt?: string | undefined
};
	["EventCalendarUpdate_region_MutationInput"]:EventCalendarUpdate_region_MutationInput;
	["mutationEditorChoiceInput"]: {
	name?: string | undefined,
	visible?: boolean | undefined,
	editorialPicks?: Array<string | undefined> | undefined,
	list?: Array<ModelTypes["mutationEditorChoice_ListInput"] | undefined> | undefined,
	region?: ModelTypes["EditorChoice_region_MutationInput"] | undefined,
	updatedAt?: string | undefined,
	createdAt?: string | undefined
};
	["mutationEditorChoice_ListInput"]: {
	item?: string | undefined,
	id?: string | undefined
};
	["EditorChoice_region_MutationInput"]:EditorChoice_region_MutationInput;
	["mutationEditorChoiceUpdateInput"]: {
	name?: string | undefined,
	visible?: boolean | undefined,
	editorialPicks?: Array<string | undefined> | undefined,
	list?: Array<ModelTypes["mutationEditorChoiceUpdate_ListInput"] | undefined> | undefined,
	region?: ModelTypes["EditorChoiceUpdate_region_MutationInput"] | undefined,
	updatedAt?: string | undefined,
	createdAt?: string | undefined
};
	["mutationEditorChoiceUpdate_ListInput"]: {
	item?: string | undefined,
	id?: string | undefined
};
	["EditorChoiceUpdate_region_MutationInput"]:EditorChoiceUpdate_region_MutationInput;
	["mutationCriteriaSettingInput"]: {
	criteria?: ModelTypes["mutationCriteriaSetting_CriteriaInput"] | undefined,
	updatedAt?: string | undefined,
	createdAt?: string | undefined
};
	["mutationCriteriaSetting_CriteriaInput"]: {
	date?: Array<string | undefined> | undefined,
	audience?: Array<string | undefined> | undefined,
	category?: Array<string | undefined> | undefined,
	tag?: Array<string | undefined> | undefined,
	region?: ModelTypes["CriteriaSetting_Criteria_region_MutationInput"] | undefined
};
	["CriteriaSetting_Criteria_region_MutationInput"]:CriteriaSetting_Criteria_region_MutationInput;
	["mutationCriteriaSettingUpdateInput"]: {
	criteria?: ModelTypes["mutationCriteriaSettingUpdate_CriteriaInput"] | undefined,
	updatedAt?: string | undefined,
	createdAt?: string | undefined
};
	["mutationCriteriaSettingUpdate_CriteriaInput"]: {
	date?: Array<string | undefined> | undefined,
	audience?: Array<string | undefined> | undefined,
	category?: Array<string | undefined> | undefined,
	tag?: Array<string | undefined> | undefined,
	region?: ModelTypes["CriteriaSettingUpdate_Criteria_region_MutationInput"] | undefined
};
	["CriteriaSettingUpdate_Criteria_region_MutationInput"]:CriteriaSettingUpdate_Criteria_region_MutationInput;
	["mutationOrganiserSettingInput"]: {
	name?: string | undefined,
	organiserList?: Array<string | undefined> | undefined,
	region?: ModelTypes["OrganiserSetting_region_MutationInput"] | undefined,
	updatedAt?: string | undefined,
	createdAt?: string | undefined
};
	["OrganiserSetting_region_MutationInput"]:OrganiserSetting_region_MutationInput;
	["mutationOrganiserSettingUpdateInput"]: {
	name?: string | undefined,
	organiserList?: Array<string | undefined> | undefined,
	region?: ModelTypes["OrganiserSettingUpdate_region_MutationInput"] | undefined,
	updatedAt?: string | undefined,
	createdAt?: string | undefined
};
	["OrganiserSettingUpdate_region_MutationInput"]:OrganiserSettingUpdate_region_MutationInput;
	["mutationPostSettingInput"]: {
	name?: string | undefined,
	featuredPosts?: Array<string | undefined> | undefined,
	region?: ModelTypes["PostSetting_region_MutationInput"] | undefined,
	updatedAt?: string | undefined,
	createdAt?: string | undefined
};
	["PostSetting_region_MutationInput"]:PostSetting_region_MutationInput;
	["mutationPostSettingUpdateInput"]: {
	name?: string | undefined,
	featuredPosts?: Array<string | undefined> | undefined,
	region?: ModelTypes["PostSettingUpdate_region_MutationInput"] | undefined,
	updatedAt?: string | undefined,
	createdAt?: string | undefined
};
	["PostSettingUpdate_region_MutationInput"]:PostSettingUpdate_region_MutationInput;
	["mutationHomepageSettingInput"]: {
	name?: string | undefined,
	region?: ModelTypes["HomepageSetting_region_MutationInput"] | undefined,
	sections?: ModelTypes["JSON"] | undefined,
	updatedAt?: string | undefined,
	createdAt?: string | undefined
};
	["HomepageSetting_region_MutationInput"]:HomepageSetting_region_MutationInput;
	["mutationHomepageSettingUpdateInput"]: {
	name?: string | undefined,
	region?: ModelTypes["HomepageSettingUpdate_region_MutationInput"] | undefined,
	sections?: ModelTypes["JSON"] | undefined,
	updatedAt?: string | undefined,
	createdAt?: string | undefined
};
	["HomepageSettingUpdate_region_MutationInput"]:HomepageSettingUpdate_region_MutationInput;
	["mutationBannerSectionInput"]: {
	name?: string | undefined,
	slug?: string | undefined,
	banners?: Array<ModelTypes["mutationBannerSection_BannersInput"] | undefined> | undefined,
	updatedAt?: string | undefined,
	createdAt?: string | undefined
};
	["mutationBannerSection_BannersInput"]: {
	name?: string | undefined,
	status?: string | undefined,
	desktop: string,
	bannerStartDate?: string | undefined,
	bannerEndDate?: string | undefined,
	region?: ModelTypes["BannerSection_Banners_region_MutationInput"] | undefined,
	id?: string | undefined
};
	["BannerSection_Banners_region_MutationInput"]:BannerSection_Banners_region_MutationInput;
	["mutationBannerSectionUpdateInput"]: {
	name?: string | undefined,
	slug?: string | undefined,
	banners?: Array<ModelTypes["mutationBannerSectionUpdate_BannersInput"] | undefined> | undefined,
	updatedAt?: string | undefined,
	createdAt?: string | undefined
};
	["mutationBannerSectionUpdate_BannersInput"]: {
	name?: string | undefined,
	status?: string | undefined,
	desktop: string,
	bannerStartDate?: string | undefined,
	bannerEndDate?: string | undefined,
	region?: ModelTypes["BannerSectionUpdate_Banners_region_MutationInput"] | undefined,
	id?: string | undefined
};
	["BannerSectionUpdate_Banners_region_MutationInput"]:BannerSectionUpdate_Banners_region_MutationInput;
	["mutationMacaoBannerSectionInput"]: {
	name?: string | undefined,
	slug?: string | undefined,
	banners?: Array<ModelTypes["mutationMacaoBannerSection_BannersInput"] | undefined> | undefined,
	updatedAt?: string | undefined,
	createdAt?: string | undefined
};
	["mutationMacaoBannerSection_BannersInput"]: {
	name?: string | undefined,
	status?: string | undefined,
	desktop: string,
	bannerStartDate?: string | undefined,
	bannerEndDate?: string | undefined,
	region?: ModelTypes["MacaoBannerSection_Banners_region_MutationInput"] | undefined,
	id?: string | undefined
};
	["MacaoBannerSection_Banners_region_MutationInput"]:MacaoBannerSection_Banners_region_MutationInput;
	["mutationMacaoBannerSectionUpdateInput"]: {
	name?: string | undefined,
	slug?: string | undefined,
	banners?: Array<ModelTypes["mutationMacaoBannerSectionUpdate_BannersInput"] | undefined> | undefined,
	updatedAt?: string | undefined,
	createdAt?: string | undefined
};
	["mutationMacaoBannerSectionUpdate_BannersInput"]: {
	name?: string | undefined,
	status?: string | undefined,
	desktop: string,
	bannerStartDate?: string | undefined,
	bannerEndDate?: string | undefined,
	region?: ModelTypes["MacaoBannerSectionUpdate_Banners_region_MutationInput"] | undefined,
	id?: string | undefined
};
	["MacaoBannerSectionUpdate_Banners_region_MutationInput"]:MacaoBannerSectionUpdate_Banners_region_MutationInput;
	["mutationBannerInput"]: {
	name: string,
	desktop: string,
	mobile: string,
	bannerStartDate: string,
	bannerEndDate: string,
	location?: ModelTypes["mutationBanner_LocationInput"] | undefined,
	link?: string | undefined,
	external?: boolean | undefined,
	type?: ModelTypes["Banner_type_MutationInput"] | undefined,
	status?: ModelTypes["Banner_status_MutationInput"] | undefined,
	advertise: ModelTypes["mutationBanner_AdvertiseInput"],
	_locations?: string | undefined,
	region?: ModelTypes["Banner_region_MutationInput"] | undefined,
	homePageClick?: number | undefined,
	homePageImpression?: number | undefined,
	latestEventClick?: number | undefined,
	latestEventImpression?: number | undefined,
	editorChoiceClick?: number | undefined,
	editorChoiceImpression?: number | undefined,
	eventListClick?: number | undefined,
	eventListImpression?: number | undefined,
	topTenClick?: number | undefined,
	topTenImpression?: number | undefined,
	miscClick?: number | undefined,
	miscImpression?: number | undefined,
	clickTotal?: number | undefined,
	totalImpression?: number | undefined,
	updatedAt?: string | undefined,
	createdAt?: string | undefined
};
	["mutationBanner_LocationInput"]: {
	page?: Array<ModelTypes["Banner_Location_page_MutationInput"] | undefined> | undefined,
	eventRankingList?: Array<string | undefined> | undefined,
	locationRankingList?: Array<string | undefined> | undefined,
	categories?: Array<string | undefined> | undefined,
	time?: boolean | undefined,
	district?: boolean | undefined,
	audience?: boolean | undefined,
	multipleCriteriaSearch?: boolean | undefined
};
	["Banner_Location_page_MutationInput"]:Banner_Location_page_MutationInput;
	["Banner_type_MutationInput"]:Banner_type_MutationInput;
	["Banner_status_MutationInput"]:Banner_status_MutationInput;
	["mutationBanner_AdvertiseInput"]: {
	campaign?: string | undefined,
	invoiceDescription?: string | undefined,
	standardPrice: number,
	discount?: number | undefined,
	timableCost?: number | undefined,
	remark?: string | undefined,
	public?: boolean | undefined
};
	["Banner_region_MutationInput"]:Banner_region_MutationInput;
	["mutationBannerUpdateInput"]: {
	name?: string | undefined,
	desktop?: string | undefined,
	mobile?: string | undefined,
	bannerStartDate?: string | undefined,
	bannerEndDate?: string | undefined,
	location?: ModelTypes["mutationBannerUpdate_LocationInput"] | undefined,
	link?: string | undefined,
	external?: boolean | undefined,
	type?: ModelTypes["BannerUpdate_type_MutationInput"] | undefined,
	status?: ModelTypes["BannerUpdate_status_MutationInput"] | undefined,
	advertise: ModelTypes["mutationBannerUpdate_AdvertiseInput"],
	_locations?: string | undefined,
	region?: ModelTypes["BannerUpdate_region_MutationInput"] | undefined,
	homePageClick?: number | undefined,
	homePageImpression?: number | undefined,
	latestEventClick?: number | undefined,
	latestEventImpression?: number | undefined,
	editorChoiceClick?: number | undefined,
	editorChoiceImpression?: number | undefined,
	eventListClick?: number | undefined,
	eventListImpression?: number | undefined,
	topTenClick?: number | undefined,
	topTenImpression?: number | undefined,
	miscClick?: number | undefined,
	miscImpression?: number | undefined,
	clickTotal?: number | undefined,
	totalImpression?: number | undefined,
	updatedAt?: string | undefined,
	createdAt?: string | undefined
};
	["mutationBannerUpdate_LocationInput"]: {
	page?: Array<ModelTypes["BannerUpdate_Location_page_MutationInput"] | undefined> | undefined,
	eventRankingList?: Array<string | undefined> | undefined,
	locationRankingList?: Array<string | undefined> | undefined,
	categories?: Array<string | undefined> | undefined,
	time?: boolean | undefined,
	district?: boolean | undefined,
	audience?: boolean | undefined,
	multipleCriteriaSearch?: boolean | undefined
};
	["BannerUpdate_Location_page_MutationInput"]:BannerUpdate_Location_page_MutationInput;
	["BannerUpdate_type_MutationInput"]:BannerUpdate_type_MutationInput;
	["BannerUpdate_status_MutationInput"]:BannerUpdate_status_MutationInput;
	["mutationBannerUpdate_AdvertiseInput"]: {
	campaign?: string | undefined,
	invoiceDescription?: string | undefined,
	standardPrice: number,
	discount?: number | undefined,
	timableCost?: number | undefined,
	remark?: string | undefined,
	public?: boolean | undefined
};
	["BannerUpdate_region_MutationInput"]:BannerUpdate_region_MutationInput;
	["mutationCampaignInput"]: {
	creator?: ModelTypes["Campaign_CreatorRelationshipInput"] | undefined,
	invoiceNumber?: string | undefined,
	quotationNumber?: string | undefined,
	campaignName: string,
	client: string,
	contactPerson: string,
	email: string,
	phone: string,
	agentName?: string | undefined,
	agentCommission?: number | undefined,
	agentOrderNumber?: string | undefined,
	_firstPublishedTime?: string | undefined,
	updatedAt?: string | undefined,
	createdAt?: string | undefined
};
	["Campaign_CreatorRelationshipInput"]: {
	relationTo?: ModelTypes["Campaign_CreatorRelationshipInputRelationTo"] | undefined,
	value?: ModelTypes["JSON"] | undefined
};
	["Campaign_CreatorRelationshipInputRelationTo"]:Campaign_CreatorRelationshipInputRelationTo;
	["mutationCampaignUpdateInput"]: {
	creator?: ModelTypes["CampaignUpdate_CreatorRelationshipInput"] | undefined,
	invoiceNumber?: string | undefined,
	quotationNumber?: string | undefined,
	campaignName?: string | undefined,
	client?: string | undefined,
	contactPerson?: string | undefined,
	email?: string | undefined,
	phone?: string | undefined,
	agentName?: string | undefined,
	agentCommission?: number | undefined,
	agentOrderNumber?: string | undefined,
	_firstPublishedTime?: string | undefined,
	updatedAt?: string | undefined,
	createdAt?: string | undefined
};
	["CampaignUpdate_CreatorRelationshipInput"]: {
	relationTo?: ModelTypes["CampaignUpdate_CreatorRelationshipInputRelationTo"] | undefined,
	value?: ModelTypes["JSON"] | undefined
};
	["CampaignUpdate_CreatorRelationshipInputRelationTo"]:CampaignUpdate_CreatorRelationshipInputRelationTo;
	["mutationAdvertiseInput"]: {
	advertise: ModelTypes["mutationAdvertise_AdvertiseInput"],
	advertiseStartDate: string,
	advertiseEndDate: string,
	type?: ModelTypes["Advertise_type_MutationInput"] | undefined,
	invoiceDescription?: string | undefined,
	standardPrice: number,
	discount?: number | undefined,
	discountPercentage?: number | undefined,
	_calculateByDiscount?: boolean | undefined,
	timableCost?: number | undefined,
	remark?: string | undefined,
	public?: boolean | undefined,
	campaignName?: string | undefined,
	campaignId?: string | undefined,
	status?: string | undefined,
	updatedAt?: string | undefined,
	createdAt?: string | undefined
};
	["mutationAdvertise_AdvertiseInput"]: {
	type?: ModelTypes["Advertise_Advertise_type_MutationInput"] | undefined,
	event?: string | undefined,
	keyword?: string | undefined,
	location?: string | undefined,
	displayPosition?: string | undefined,
	rankingListRemark?: string | undefined
};
	["Advertise_Advertise_type_MutationInput"]:Advertise_Advertise_type_MutationInput;
	["Advertise_type_MutationInput"]:Advertise_type_MutationInput;
	["mutationAdvertiseUpdateInput"]: {
	advertise: ModelTypes["mutationAdvertiseUpdate_AdvertiseInput"],
	advertiseStartDate?: string | undefined,
	advertiseEndDate?: string | undefined,
	type?: ModelTypes["AdvertiseUpdate_type_MutationInput"] | undefined,
	invoiceDescription?: string | undefined,
	standardPrice?: number | undefined,
	discount?: number | undefined,
	discountPercentage?: number | undefined,
	_calculateByDiscount?: boolean | undefined,
	timableCost?: number | undefined,
	remark?: string | undefined,
	public?: boolean | undefined,
	campaignName?: string | undefined,
	campaignId?: string | undefined,
	status?: string | undefined,
	updatedAt?: string | undefined,
	createdAt?: string | undefined
};
	["mutationAdvertiseUpdate_AdvertiseInput"]: {
	type?: ModelTypes["AdvertiseUpdate_Advertise_type_MutationInput"] | undefined,
	event?: string | undefined,
	keyword?: string | undefined,
	location?: string | undefined,
	displayPosition?: string | undefined,
	rankingListRemark?: string | undefined
};
	["AdvertiseUpdate_Advertise_type_MutationInput"]:AdvertiseUpdate_Advertise_type_MutationInput;
	["AdvertiseUpdate_type_MutationInput"]:AdvertiseUpdate_type_MutationInput;
	["mutationStaticInput"]: {
	name?: string | undefined,
	key?: string | undefined,
	content?: ModelTypes["JSON"] | undefined,
	region?: ModelTypes["Static_region_MutationInput"] | undefined,
	updatedAt?: string | undefined,
	createdAt?: string | undefined
};
	["Static_region_MutationInput"]:Static_region_MutationInput;
	["mutationStaticUpdateInput"]: {
	name?: string | undefined,
	key?: string | undefined,
	content?: ModelTypes["JSON"] | undefined,
	region?: ModelTypes["StaticUpdate_region_MutationInput"] | undefined,
	updatedAt?: string | undefined,
	createdAt?: string | undefined
};
	["StaticUpdate_region_MutationInput"]:StaticUpdate_region_MutationInput;
	["mutationEventViewInput"]: {
	date: string,
	eventId?: string | undefined,
	viewCount: number,
	updatedAt?: string | undefined,
	createdAt?: string | undefined
};
	["mutationEventViewUpdateInput"]: {
	date?: string | undefined,
	eventId?: string | undefined,
	viewCount?: number | undefined,
	updatedAt?: string | undefined,
	createdAt?: string | undefined
};
	["mutationPostViewInput"]: {
	date: string,
	postId?: string | undefined,
	viewCount: number,
	updatedAt?: string | undefined,
	createdAt?: string | undefined
};
	["mutationPostViewUpdateInput"]: {
	date?: string | undefined,
	postId?: string | undefined,
	viewCount?: number | undefined,
	updatedAt?: string | undefined,
	createdAt?: string | undefined
};
	["mutationPayloadPreferenceInput"]: {
	user?: ModelTypes["PayloadPreference_UserRelationshipInput"] | undefined,
	key?: string | undefined,
	value?: ModelTypes["JSON"] | undefined,
	updatedAt?: string | undefined,
	createdAt?: string | undefined
};
	["PayloadPreference_UserRelationshipInput"]: {
	relationTo?: ModelTypes["PayloadPreference_UserRelationshipInputRelationTo"] | undefined,
	value?: ModelTypes["JSON"] | undefined
};
	["PayloadPreference_UserRelationshipInputRelationTo"]:PayloadPreference_UserRelationshipInputRelationTo;
	["mutationPayloadPreferenceUpdateInput"]: {
	user?: ModelTypes["PayloadPreferenceUpdate_UserRelationshipInput"] | undefined,
	key?: string | undefined,
	value?: ModelTypes["JSON"] | undefined,
	updatedAt?: string | undefined,
	createdAt?: string | undefined
};
	["PayloadPreferenceUpdate_UserRelationshipInput"]: {
	relationTo?: ModelTypes["PayloadPreferenceUpdate_UserRelationshipInputRelationTo"] | undefined,
	value?: ModelTypes["JSON"] | undefined
};
	["PayloadPreferenceUpdate_UserRelationshipInputRelationTo"]:PayloadPreferenceUpdate_UserRelationshipInputRelationTo;
	["ChatbotInventoryPayment"]: {
		expiredAt?: string | undefined
};
	["ChatbotTransactionPdfGet"]: {
		url?: string | undefined
};
	["WebAppQrCodeBatchScanResult"]: {
		result?: Array<ModelTypes["WebAppQrCodeScanResponse"] | undefined> | undefined
};
	["WebAppQrCodeScanResponse"]: {
		status?: string | undefined,
	qrCodeId?: string | undefined,
	name?: string | undefined,
	phone?: string | undefined,
	scanAt?: string | undefined,
	uploadAt?: string | undefined,
	failReason?: string | undefined
};
	["WebAppQrCodeBatchScanItem"]: {
	checkInAppSelectionId?: string | undefined,
	checkInAppSelectionProductType?: string | undefined,
	qrCodeId?: string | undefined,
	scanAt?: string | undefined,
	entryNumber?: string | undefined
};
	["schema"]: {
	query?: ModelTypes["Query"] | undefined,
	mutation?: ModelTypes["Mutation"] | undefined
}
    }

export type GraphQLTypes = {
    ["Query"]: {
	__typename: "Query",
	Event?: GraphQLTypes["Event"] | undefined,
	Events?: GraphQLTypes["Events"] | undefined,
	countEvents?: GraphQLTypes["countEvents"] | undefined,
	docAccessEvent?: GraphQLTypes["eventsDocAccess"] | undefined,
	Location?: GraphQLTypes["Location"] | undefined,
	Locations?: GraphQLTypes["Locations"] | undefined,
	countLocations?: GraphQLTypes["countLocations"] | undefined,
	docAccessLocation?: GraphQLTypes["locationsDocAccess"] | undefined,
	Post?: GraphQLTypes["Post"] | undefined,
	Posts?: GraphQLTypes["Posts"] | undefined,
	countPosts?: GraphQLTypes["countPosts"] | undefined,
	docAccessPost?: GraphQLTypes["postsDocAccess"] | undefined,
	Ticketing?: GraphQLTypes["Ticketing"] | undefined,
	Ticketings?: GraphQLTypes["Ticketings"] | undefined,
	countTicketings?: GraphQLTypes["countTicketings"] | undefined,
	docAccessTicketing?: GraphQLTypes["ticketingDocAccess"] | undefined,
	Product?: GraphQLTypes["Product"] | undefined,
	Products?: GraphQLTypes["Products"] | undefined,
	countProducts?: GraphQLTypes["countProducts"] | undefined,
	docAccessProduct?: GraphQLTypes["productsDocAccess"] | undefined,
	InventoryStatus?: GraphQLTypes["InventoryStatus"] | undefined,
	InventoryStatuses?: GraphQLTypes["InventoryStatuses"] | undefined,
	countInventoryStatuses?: GraphQLTypes["countInventoryStatuses"] | undefined,
	docAccessInventoryStatus?: GraphQLTypes["inventory_statusDocAccess"] | undefined,
	Transaction?: GraphQLTypes["Transaction"] | undefined,
	Transactions?: GraphQLTypes["Transactions"] | undefined,
	countTransactions?: GraphQLTypes["countTransactions"] | undefined,
	docAccessTransaction?: GraphQLTypes["transactionsDocAccess"] | undefined,
	RedemptionCode?: GraphQLTypes["RedemptionCode"] | undefined,
	RedemptionCodes?: GraphQLTypes["RedemptionCodes"] | undefined,
	countRedemptionCodes?: GraphQLTypes["countRedemptionCodes"] | undefined,
	docAccessRedemptionCode?: GraphQLTypes["redemption_codesDocAccess"] | undefined,
	RedemptionPdf?: GraphQLTypes["RedemptionPdf"] | undefined,
	RedemptionPdfs?: GraphQLTypes["RedemptionPdfs"] | undefined,
	countRedemptionPdfs?: GraphQLTypes["countRedemptionPdfs"] | undefined,
	docAccessRedemptionPdf?: GraphQLTypes["redemption_pdfDocAccess"] | undefined,
	CheckInRedemption?: GraphQLTypes["CheckInRedemption"] | undefined,
	CheckInRedemptions?: GraphQLTypes["CheckInRedemptions"] | undefined,
	countCheckInRedemptions?: GraphQLTypes["countCheckInRedemptions"] | undefined,
	docAccessCheckInRedemption?: GraphQLTypes["check_in_redemptionDocAccess"] | undefined,
	User?: GraphQLTypes["User"] | undefined,
	Users?: GraphQLTypes["Users"] | undefined,
	countUsers?: GraphQLTypes["countUsers"] | undefined,
	docAccessUser?: GraphQLTypes["usersDocAccess"] | undefined,
	meUser?: GraphQLTypes["usersMe"] | undefined,
	initializedUser?: boolean | undefined,
	Bookmark?: GraphQLTypes["Bookmark"] | undefined,
	Bookmarks?: GraphQLTypes["Bookmarks"] | undefined,
	countBookmarks?: GraphQLTypes["countBookmarks"] | undefined,
	docAccessBookmark?: GraphQLTypes["bookmarksDocAccess"] | undefined,
	WebAppBookmark?: GraphQLTypes["WebAppBookmark"] | undefined,
	WebAppBookmarks?: GraphQLTypes["WebAppBookmarks"] | undefined,
	countWebAppBookmarks?: GraphQLTypes["countWebAppBookmarks"] | undefined,
	docAccessWebAppBookmark?: GraphQLTypes["webAppBookmarksDocAccess"] | undefined,
	ViewRecord?: GraphQLTypes["ViewRecord"] | undefined,
	ViewRecords?: GraphQLTypes["ViewRecords"] | undefined,
	countViewRecords?: GraphQLTypes["countViewRecords"] | undefined,
	docAccessViewRecord?: GraphQLTypes["view_recordsDocAccess"] | undefined,
	Comment?: GraphQLTypes["Comment"] | undefined,
	Comments?: GraphQLTypes["Comments"] | undefined,
	countComments?: GraphQLTypes["countComments"] | undefined,
	docAccessComment?: GraphQLTypes["commentsDocAccess"] | undefined,
	Subscription?: GraphQLTypes["Subscription"] | undefined,
	Subscriptions?: GraphQLTypes["Subscriptions"] | undefined,
	countSubscriptions?: GraphQLTypes["countSubscriptions"] | undefined,
	docAccessSubscription?: GraphQLTypes["subscriptionsDocAccess"] | undefined,
	Newsletter?: GraphQLTypes["Newsletter"] | undefined,
	Newsletters?: GraphQLTypes["Newsletters"] | undefined,
	countNewsletters?: GraphQLTypes["countNewsletters"] | undefined,
	docAccessNewsletter?: GraphQLTypes["newsletterDocAccess"] | undefined,
	BlogCategory?: GraphQLTypes["BlogCategory"] | undefined,
	BlogCategories?: GraphQLTypes["BlogCategories"] | undefined,
	countBlogCategories?: GraphQLTypes["countBlogCategories"] | undefined,
	docAccessBlogCategory?: GraphQLTypes["blog_categoriesDocAccess"] | undefined,
	Tag?: GraphQLTypes["Tag"] | undefined,
	Tags?: GraphQLTypes["Tags"] | undefined,
	countTags?: GraphQLTypes["countTags"] | undefined,
	docAccessTag?: GraphQLTypes["tagsDocAccess"] | undefined,
	SpecialTag?: GraphQLTypes["SpecialTag"] | undefined,
	SpecialTags?: GraphQLTypes["SpecialTags"] | undefined,
	countSpecialTags?: GraphQLTypes["countSpecialTags"] | undefined,
	docAccessSpecialTag?: GraphQLTypes["specialTagsDocAccess"] | undefined,
	Date?: GraphQLTypes["Date"] | undefined,
	Dates?: GraphQLTypes["Dates"] | undefined,
	countDates?: GraphQLTypes["countDates"] | undefined,
	docAccessDate?: GraphQLTypes["dateDocAccess"] | undefined,
	District?: GraphQLTypes["District"] | undefined,
	Districts?: GraphQLTypes["Districts"] | undefined,
	countDistricts?: GraphQLTypes["countDistricts"] | undefined,
	docAccessDistrict?: GraphQLTypes["districtsDocAccess"] | undefined,
	Audience?: GraphQLTypes["Audience"] | undefined,
	Audiences?: GraphQLTypes["Audiences"] | undefined,
	countAudiences?: GraphQLTypes["countAudiences"] | undefined,
	docAccessAudience?: GraphQLTypes["audienceDocAccess"] | undefined,
	EventCategory?: GraphQLTypes["EventCategory"] | undefined,
	EventCategories?: GraphQLTypes["EventCategories"] | undefined,
	countEventCategories?: GraphQLTypes["countEventCategories"] | undefined,
	docAccessEventCategory?: GraphQLTypes["categoryDocAccess"] | undefined,
	PrivateMedia?: GraphQLTypes["PrivateMedia"] | undefined,
	PrivateMedias?: GraphQLTypes["PrivateMedias"] | undefined,
	countPrivateMedias?: GraphQLTypes["countPrivateMedias"] | undefined,
	docAccessPrivateMedia?: GraphQLTypes["private_mediaDocAccess"] | undefined,
	Media?: GraphQLTypes["Media"] | undefined,
	allMedia?: GraphQLTypes["allMedia"] | undefined,
	countallMedia?: GraphQLTypes["countallMedia"] | undefined,
	docAccessMedia?: GraphQLTypes["mediaDocAccess"] | undefined,
	BackupMedia?: GraphQLTypes["BackupMedia"] | undefined,
	BackupMedias?: GraphQLTypes["BackupMedias"] | undefined,
	countBackupMedias?: GraphQLTypes["countBackupMedias"] | undefined,
	docAccessBackupMedia?: GraphQLTypes["backupMediaDocAccess"] | undefined,
	AccountSuspendRequest?: GraphQLTypes["AccountSuspendRequest"] | undefined,
	AccountSuspendRequests?: GraphQLTypes["AccountSuspendRequests"] | undefined,
	countAccountSuspendRequests?: GraphQLTypes["countAccountSuspendRequests"] | undefined,
	docAccessAccountSuspendRequest?: GraphQLTypes["account_suspend_requestsDocAccess"] | undefined,
	Device?: GraphQLTypes["Device"] | undefined,
	Devices?: GraphQLTypes["Devices"] | undefined,
	countDevices?: GraphQLTypes["countDevices"] | undefined,
	docAccessDevice?: GraphQLTypes["devicesDocAccess"] | undefined,
	Notification?: GraphQLTypes["Notification"] | undefined,
	Notifications?: GraphQLTypes["Notifications"] | undefined,
	countNotifications?: GraphQLTypes["countNotifications"] | undefined,
	docAccessNotification?: GraphQLTypes["notificationsDocAccess"] | undefined,
	RedirectSetting?: GraphQLTypes["RedirectSetting"] | undefined,
	RedirectSettings?: GraphQLTypes["RedirectSettings"] | undefined,
	countRedirectSettings?: GraphQLTypes["countRedirectSettings"] | undefined,
	docAccessRedirectSetting?: GraphQLTypes["redirectSettingDocAccess"] | undefined,
	EventRanking?: GraphQLTypes["EventRanking"] | undefined,
	EventRankings?: GraphQLTypes["EventRankings"] | undefined,
	countEventRankings?: GraphQLTypes["countEventRankings"] | undefined,
	docAccessEventRanking?: GraphQLTypes["ranking_eventsDocAccess"] | undefined,
	LocationRanking?: GraphQLTypes["LocationRanking"] | undefined,
	LocationRankings?: GraphQLTypes["LocationRankings"] | undefined,
	countLocationRankings?: GraphQLTypes["countLocationRankings"] | undefined,
	docAccessLocationRanking?: GraphQLTypes["ranking_locationsDocAccess"] | undefined,
	EventCalendar?: GraphQLTypes["EventCalendar"] | undefined,
	EventCalendars?: GraphQLTypes["EventCalendars"] | undefined,
	countEventCalendars?: GraphQLTypes["countEventCalendars"] | undefined,
	docAccessEventCalendar?: GraphQLTypes["event_calendarDocAccess"] | undefined,
	EditorChoice?: GraphQLTypes["EditorChoice"] | undefined,
	EditorChoices?: GraphQLTypes["EditorChoices"] | undefined,
	countEditorChoices?: GraphQLTypes["countEditorChoices"] | undefined,
	docAccessEditorChoice?: GraphQLTypes["editor_choicesDocAccess"] | undefined,
	CriteriaSetting?: GraphQLTypes["CriteriaSetting"] | undefined,
	CriteriaSettings?: GraphQLTypes["CriteriaSettings"] | undefined,
	countCriteriaSettings?: GraphQLTypes["countCriteriaSettings"] | undefined,
	docAccessCriteriaSetting?: GraphQLTypes["criteria_settingDocAccess"] | undefined,
	OrganiserSetting?: GraphQLTypes["OrganiserSetting"] | undefined,
	OrganiserSettings?: GraphQLTypes["OrganiserSettings"] | undefined,
	countOrganiserSettings?: GraphQLTypes["countOrganiserSettings"] | undefined,
	docAccessOrganiserSetting?: GraphQLTypes["organiserSettingDocAccess"] | undefined,
	PostSetting?: GraphQLTypes["PostSetting"] | undefined,
	PostSettings?: GraphQLTypes["PostSettings"] | undefined,
	countPostSettings?: GraphQLTypes["countPostSettings"] | undefined,
	docAccessPostSetting?: GraphQLTypes["post_settingDocAccess"] | undefined,
	HomepageSetting?: GraphQLTypes["HomepageSetting"] | undefined,
	HomepageSettings?: GraphQLTypes["HomepageSettings"] | undefined,
	countHomepageSettings?: GraphQLTypes["countHomepageSettings"] | undefined,
	docAccessHomepageSetting?: GraphQLTypes["homepageSettingDocAccess"] | undefined,
	BannerSection?: GraphQLTypes["BannerSection"] | undefined,
	BannerSections?: GraphQLTypes["BannerSections"] | undefined,
	countBannerSections?: GraphQLTypes["countBannerSections"] | undefined,
	docAccessBannerSection?: GraphQLTypes["banner_sectionDocAccess"] | undefined,
	MacaoBannerSection?: GraphQLTypes["MacaoBannerSection"] | undefined,
	MacaoBannerSections?: GraphQLTypes["MacaoBannerSections"] | undefined,
	countMacaoBannerSections?: GraphQLTypes["countMacaoBannerSections"] | undefined,
	docAccessMacaoBannerSection?: GraphQLTypes["macao_banner_sectionDocAccess"] | undefined,
	Banner?: GraphQLTypes["Banner"] | undefined,
	Banners?: GraphQLTypes["Banners"] | undefined,
	countBanners?: GraphQLTypes["countBanners"] | undefined,
	docAccessBanner?: GraphQLTypes["bannersDocAccess"] | undefined,
	Campaign?: GraphQLTypes["Campaign"] | undefined,
	Campaigns?: GraphQLTypes["Campaigns"] | undefined,
	countCampaigns?: GraphQLTypes["countCampaigns"] | undefined,
	docAccessCampaign?: GraphQLTypes["campaignsDocAccess"] | undefined,
	Advertise?: GraphQLTypes["Advertise"] | undefined,
	Advertises?: GraphQLTypes["Advertises"] | undefined,
	countAdvertises?: GraphQLTypes["countAdvertises"] | undefined,
	docAccessAdvertise?: GraphQLTypes["advertisesDocAccess"] | undefined,
	Static?: GraphQLTypes["Static"] | undefined,
	Statics?: GraphQLTypes["Statics"] | undefined,
	countStatics?: GraphQLTypes["countStatics"] | undefined,
	docAccessStatic?: GraphQLTypes["staticsDocAccess"] | undefined,
	EventView?: GraphQLTypes["EventView"] | undefined,
	EventViews?: GraphQLTypes["EventViews"] | undefined,
	countEventViews?: GraphQLTypes["countEventViews"] | undefined,
	docAccessEventView?: GraphQLTypes["event_viewsDocAccess"] | undefined,
	PostView?: GraphQLTypes["PostView"] | undefined,
	PostViews?: GraphQLTypes["PostViews"] | undefined,
	countPostViews?: GraphQLTypes["countPostViews"] | undefined,
	docAccessPostView?: GraphQLTypes["post_viewsDocAccess"] | undefined,
	PayloadPreference?: GraphQLTypes["PayloadPreference"] | undefined,
	PayloadPreferences?: GraphQLTypes["PayloadPreferences"] | undefined,
	countPayloadPreferences?: GraphQLTypes["countPayloadPreferences"] | undefined,
	docAccessPayloadPreference?: GraphQLTypes["payload_preferencesDocAccess"] | undefined,
	Access?: GraphQLTypes["Access"] | undefined,
	GetEvents?: GraphQLTypes["Events"] | undefined,
	GetFilterEvents?: GraphQLTypes["Events"] | undefined,
	GetFilterPastEvents?: GraphQLTypes["Events"] | undefined,
	GetEventMaxUsedTag?: GraphQLTypes["GetEventMaxUsedTag"] | undefined,
	GetSortEvent?: GraphQLTypes["Events"] | undefined,
	BookmarkEvents?: GraphQLTypes["Events"] | undefined,
	GetBookmarks?: GraphQLTypes["Bookmarks"] | undefined,
	BookmarkItems?: GraphQLTypes["Bookmarks"] | undefined,
	ViewedEvents?: GraphQLTypes["Events"] | undefined,
	NonFeaturedPosts?: GraphQLTypes["Posts"] | undefined,
	CheckEmailUniqueness?: boolean | undefined,
	GetOrganisers?: GraphQLTypes["Users"] | undefined,
	GetOrganiser?: GraphQLTypes["User"] | undefined,
	GetSubscriptions?: GraphQLTypes["Subscriptions"] | undefined,
	GetFollowed?: GraphQLTypes["Subscriptions"] | undefined,
	GetComments?: GraphQLTypes["Comments"] | undefined,
	CommentsCount?: GraphQLTypes["Comments"] | undefined,
	GetBanners?: GraphQLTypes["Banners"] | undefined,
	GetBannerSection?: GraphQLTypes["Banners"] | undefined,
	GetNotifications?: GraphQLTypes["Notifications"] | undefined,
	GetCriteriaSettings?: GraphQLTypes["CriteriaSettings"] | undefined,
	GetFilterLocations?: GraphQLTypes["Locations"] | undefined,
	GetContainEventTagLocations?: GraphQLTypes["Locations"] | undefined,
	GetEventRankings?: GraphQLTypes["EventRankings"] | undefined,
	ChatbotStocks?: GraphQLTypes["ChatbotStocks"] | undefined,
	ChatbotProduct?: GraphQLTypes["Product"] | undefined,
	ChatbotTicketing?: GraphQLTypes["Ticketing"] | undefined,
	ChatbotEvents?: GraphQLTypes["ChatbotEvents"] | undefined,
	FilterExpiredEvent?: GraphQLTypes["EditorChoices"] | undefined,
	WebAppCheckInCountGet?: GraphQLTypes["WebAppCheckInCountGetResponse"] | undefined,
	WebAppCheckInList?: GraphQLTypes["CheckInRedemptions"] | undefined,
	WebAppEventList?: GraphQLTypes["Events"] | undefined,
	WebAppProductDetail?: GraphQLTypes["Product"] | undefined,
	WebAppProductList?: GraphQLTypes["ProductList"] | undefined,
	WebAppTicketingDetail?: GraphQLTypes["Ticketing"] | undefined,
	WebAppTicketingList?: GraphQLTypes["TicketingList"] | undefined,
	WebAppRedemptionCodeList?: GraphQLTypes["RedemptionCodeList"] | undefined,
	WebAppRedemptionCodeDetail?: GraphQLTypes["RedemptionCode"] | undefined,
	WebAppBookmarkList?: GraphQLTypes["BookmarkList"] | undefined
};
	["Event"]: {
	__typename: "Event",
	id?: string | undefined,
	region?: GraphQLTypes["Event_region"] | undefined,
	creator?: GraphQLTypes["User"] | undefined,
	permalink?: string | undefined,
	status: string,
	level?: GraphQLTypes["Event_level"] | undefined,
	parent?: Array<GraphQLTypes["Event"]> | undefined,
	name?: string | undefined,
	chatbotEventTitle?: string | undefined,
	chatbotSequence?: number | undefined,
	thumbnail?: GraphQLTypes["Media"] | undefined,
	banner?: GraphQLTypes["Media"] | undefined,
	mobileBanner?: GraphQLTypes["Media"] | undefined,
	sections?: Array<GraphQLTypes["Event_Sections"]> | undefined,
	ticketing?: Array<GraphQLTypes["Event_Ticketing"]> | undefined,
	content?: Array<GraphQLTypes["Event_Content"]> | undefined,
	legacyContent?: string | undefined,
	showLegacyContent?: boolean | undefined,
	criteria?: GraphQLTypes["Event_Criteria"] | undefined,
	walkIn?: boolean | undefined,
	contact?: GraphQLTypes["Event_Contact"] | undefined,
	views?: number | undefined,
	free?: boolean | undefined,
	fees?: Array<GraphQLTypes["Event_Fees"]> | undefined,
	homePageInternalClick?: number | undefined,
	homePageImpression?: number | undefined,
	eodClickRate?: number | undefined,
	eodImpressionRate?: number | undefined,
	top10ClickRateHomepage?: number | undefined,
	top10ImpressionkRateHomepage?: number | undefined,
	otherEventRankingClickRateHomepage?: number | undefined,
	otherEventRankingImpressionHomePage?: number | undefined,
	userPreferenceClickRate?: number | undefined,
	userPreferenceImpression?: number | undefined,
	eventSearchInternalClick?: number | undefined,
	eventListImpression?: number | undefined,
	latestEventInternalClick?: number | undefined,
	latestEventImpression?: number | undefined,
	topTenEventRankingInternalClick?: number | undefined,
	topTenImpression?: number | undefined,
	editorChoiceInternalClick?: number | undefined,
	editorChoiceImpression?: number | undefined,
	otherEventRankingInternalClick?: number | undefined,
	otherEventRankingImpression?: number | undefined,
	bannerInternalClick?: number | undefined,
	miscImpression?: number | undefined,
	miscInternalClick?: number | undefined,
	totalImpression?: number | undefined,
	internalClick?: number | undefined,
	outboundClick?: number | undefined,
	externalClick?: number | undefined,
	_viewsCountByMonth?: number | undefined,
	products?: Array<GraphQLTypes["Event_Products"]> | undefined,
	legacyGallery?: Array<GraphQLTypes["Media"]> | undefined,
	earliestStartDate?: GraphQLTypes["DateTime"] | undefined,
	latestEndDate?: GraphQLTypes["DateTime"] | undefined,
	latestStartDateTime?: GraphQLTypes["DateTime"] | undefined,
	hasTicketing?: boolean | undefined,
	hasProducts?: boolean | undefined,
	updatedAt?: GraphQLTypes["DateTime"] | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined
};
	["Event_region"]: Event_region;
	["User"]: {
	__typename: "User",
	id?: string | undefined,
	region?: GraphQLTypes["User_region"] | undefined,
	name?: string | undefined,
	displayEmail?: string | undefined,
	legacyUsername?: string | undefined,
	roles: Array<GraphQLTypes["User_roles"]>,
	member?: GraphQLTypes["User_Member"] | undefined,
	organiser?: GraphQLTypes["User_Organiser"] | undefined,
	emailVerified?: boolean | undefined,
	sso?: GraphQLTypes["User_Sso"] | undefined,
	oldPassword?: string | undefined,
	status?: GraphQLTypes["User_status"] | undefined,
	updatedAt?: GraphQLTypes["DateTime"] | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	email: GraphQLTypes["EmailAddress"],
	resetPasswordToken?: string | undefined,
	resetPasswordExpiration?: GraphQLTypes["DateTime"] | undefined,
	salt?: string | undefined,
	hash?: string | undefined,
	loginAttempts?: number | undefined,
	lockUntil?: GraphQLTypes["DateTime"] | undefined,
	password: string
};
	["User_region"]: User_region;
	["User_roles"]: User_roles;
	["User_Member"]: {
	__typename: "User_Member",
	region?: GraphQLTypes["User_Member_region"] | undefined,
	language?: GraphQLTypes["User_Member_language"] | undefined,
	phone?: string | undefined,
	avatar?: GraphQLTypes["Media"] | undefined,
	birthday?: GraphQLTypes["DateTime"] | undefined,
	gender?: GraphQLTypes["User_Member_gender"] | undefined,
	categories?: Array<GraphQLTypes["EventCategory"]> | undefined
};
	["User_Member_region"]: User_Member_region;
	["User_Member_language"]: User_Member_language;
	["Media"]: {
	__typename: "Media",
	id?: string | undefined,
	alt?: string | undefined,
	updatedAt?: GraphQLTypes["DateTime"] | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	url?: string | undefined,
	filename?: string | undefined,
	mimeType?: string | undefined,
	filesize?: number | undefined,
	width?: number | undefined,
	height?: number | undefined,
	focalX?: number | undefined,
	focalY?: number | undefined,
	sizes?: GraphQLTypes["Media_Sizes"] | undefined
};
	/** A date-time string at UTC, such as 2007-12-03T10:15:30Z, compliant with the `date-time` format outlined in section 5.6 of the RFC 3339 profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar. */
["DateTime"]: "scalar" & { name: "DateTime" };
	["Media_Sizes"]: {
	__typename: "Media_Sizes",
	small?: GraphQLTypes["Media_Sizes_Small"] | undefined,
	medium?: GraphQLTypes["Media_Sizes_Medium"] | undefined,
	large?: GraphQLTypes["Media_Sizes_Large"] | undefined,
	small_jpeg?: GraphQLTypes["Media_Sizes_Small_jpeg"] | undefined,
	medium_jpeg?: GraphQLTypes["Media_Sizes_Medium_jpeg"] | undefined,
	large_jpeg?: GraphQLTypes["Media_Sizes_Large_jpeg"] | undefined
};
	["Media_Sizes_Small"]: {
	__typename: "Media_Sizes_Small",
	url?: string | undefined,
	width?: number | undefined,
	height?: number | undefined,
	mimeType?: string | undefined,
	filesize?: number | undefined,
	filename?: string | undefined
};
	["Media_Sizes_Medium"]: {
	__typename: "Media_Sizes_Medium",
	url?: string | undefined,
	width?: number | undefined,
	height?: number | undefined,
	mimeType?: string | undefined,
	filesize?: number | undefined,
	filename?: string | undefined
};
	["Media_Sizes_Large"]: {
	__typename: "Media_Sizes_Large",
	url?: string | undefined,
	width?: number | undefined,
	height?: number | undefined,
	mimeType?: string | undefined,
	filesize?: number | undefined,
	filename?: string | undefined
};
	["Media_Sizes_Small_jpeg"]: {
	__typename: "Media_Sizes_Small_jpeg",
	url?: string | undefined,
	width?: number | undefined,
	height?: number | undefined,
	mimeType?: string | undefined,
	filesize?: number | undefined,
	filename?: string | undefined
};
	["Media_Sizes_Medium_jpeg"]: {
	__typename: "Media_Sizes_Medium_jpeg",
	url?: string | undefined,
	width?: number | undefined,
	height?: number | undefined,
	mimeType?: string | undefined,
	filesize?: number | undefined,
	filename?: string | undefined
};
	["Media_Sizes_Large_jpeg"]: {
	__typename: "Media_Sizes_Large_jpeg",
	url?: string | undefined,
	width?: number | undefined,
	height?: number | undefined,
	mimeType?: string | undefined,
	filesize?: number | undefined,
	filename?: string | undefined
};
	["LocaleInputType"]: LocaleInputType;
	["FallbackLocaleInputType"]: FallbackLocaleInputType;
	["User_Member_Avatar_where"]: {
		alt?: GraphQLTypes["User_Member_Avatar_alt_operator"] | undefined,
	updatedAt?: GraphQLTypes["User_Member_Avatar_updatedAt_operator"] | undefined,
	createdAt?: GraphQLTypes["User_Member_Avatar_createdAt_operator"] | undefined,
	url?: GraphQLTypes["User_Member_Avatar_url_operator"] | undefined,
	filename?: GraphQLTypes["User_Member_Avatar_filename_operator"] | undefined,
	mimeType?: GraphQLTypes["User_Member_Avatar_mimeType_operator"] | undefined,
	filesize?: GraphQLTypes["User_Member_Avatar_filesize_operator"] | undefined,
	width?: GraphQLTypes["User_Member_Avatar_width_operator"] | undefined,
	height?: GraphQLTypes["User_Member_Avatar_height_operator"] | undefined,
	focalX?: GraphQLTypes["User_Member_Avatar_focalX_operator"] | undefined,
	focalY?: GraphQLTypes["User_Member_Avatar_focalY_operator"] | undefined,
	sizes__small__url?: GraphQLTypes["User_Member_Avatar_sizes__small__url_operator"] | undefined,
	sizes__small__width?: GraphQLTypes["User_Member_Avatar_sizes__small__width_operator"] | undefined,
	sizes__small__height?: GraphQLTypes["User_Member_Avatar_sizes__small__height_operator"] | undefined,
	sizes__small__mimeType?: GraphQLTypes["User_Member_Avatar_sizes__small__mimeType_operator"] | undefined,
	sizes__small__filesize?: GraphQLTypes["User_Member_Avatar_sizes__small__filesize_operator"] | undefined,
	sizes__small__filename?: GraphQLTypes["User_Member_Avatar_sizes__small__filename_operator"] | undefined,
	sizes__medium__url?: GraphQLTypes["User_Member_Avatar_sizes__medium__url_operator"] | undefined,
	sizes__medium__width?: GraphQLTypes["User_Member_Avatar_sizes__medium__width_operator"] | undefined,
	sizes__medium__height?: GraphQLTypes["User_Member_Avatar_sizes__medium__height_operator"] | undefined,
	sizes__medium__mimeType?: GraphQLTypes["User_Member_Avatar_sizes__medium__mimeType_operator"] | undefined,
	sizes__medium__filesize?: GraphQLTypes["User_Member_Avatar_sizes__medium__filesize_operator"] | undefined,
	sizes__medium__filename?: GraphQLTypes["User_Member_Avatar_sizes__medium__filename_operator"] | undefined,
	sizes__large__url?: GraphQLTypes["User_Member_Avatar_sizes__large__url_operator"] | undefined,
	sizes__large__width?: GraphQLTypes["User_Member_Avatar_sizes__large__width_operator"] | undefined,
	sizes__large__height?: GraphQLTypes["User_Member_Avatar_sizes__large__height_operator"] | undefined,
	sizes__large__mimeType?: GraphQLTypes["User_Member_Avatar_sizes__large__mimeType_operator"] | undefined,
	sizes__large__filesize?: GraphQLTypes["User_Member_Avatar_sizes__large__filesize_operator"] | undefined,
	sizes__large__filename?: GraphQLTypes["User_Member_Avatar_sizes__large__filename_operator"] | undefined,
	sizes__small_jpeg__url?: GraphQLTypes["User_Member_Avatar_sizes__small_jpeg__url_operator"] | undefined,
	sizes__small_jpeg__width?: GraphQLTypes["User_Member_Avatar_sizes__small_jpeg__width_operator"] | undefined,
	sizes__small_jpeg__height?: GraphQLTypes["User_Member_Avatar_sizes__small_jpeg__height_operator"] | undefined,
	sizes__small_jpeg__mimeType?: GraphQLTypes["User_Member_Avatar_sizes__small_jpeg__mimeType_operator"] | undefined,
	sizes__small_jpeg__filesize?: GraphQLTypes["User_Member_Avatar_sizes__small_jpeg__filesize_operator"] | undefined,
	sizes__small_jpeg__filename?: GraphQLTypes["User_Member_Avatar_sizes__small_jpeg__filename_operator"] | undefined,
	sizes__medium_jpeg__url?: GraphQLTypes["User_Member_Avatar_sizes__medium_jpeg__url_operator"] | undefined,
	sizes__medium_jpeg__width?: GraphQLTypes["User_Member_Avatar_sizes__medium_jpeg__width_operator"] | undefined,
	sizes__medium_jpeg__height?: GraphQLTypes["User_Member_Avatar_sizes__medium_jpeg__height_operator"] | undefined,
	sizes__medium_jpeg__mimeType?: GraphQLTypes["User_Member_Avatar_sizes__medium_jpeg__mimeType_operator"] | undefined,
	sizes__medium_jpeg__filesize?: GraphQLTypes["User_Member_Avatar_sizes__medium_jpeg__filesize_operator"] | undefined,
	sizes__medium_jpeg__filename?: GraphQLTypes["User_Member_Avatar_sizes__medium_jpeg__filename_operator"] | undefined,
	sizes__large_jpeg__url?: GraphQLTypes["User_Member_Avatar_sizes__large_jpeg__url_operator"] | undefined,
	sizes__large_jpeg__width?: GraphQLTypes["User_Member_Avatar_sizes__large_jpeg__width_operator"] | undefined,
	sizes__large_jpeg__height?: GraphQLTypes["User_Member_Avatar_sizes__large_jpeg__height_operator"] | undefined,
	sizes__large_jpeg__mimeType?: GraphQLTypes["User_Member_Avatar_sizes__large_jpeg__mimeType_operator"] | undefined,
	sizes__large_jpeg__filesize?: GraphQLTypes["User_Member_Avatar_sizes__large_jpeg__filesize_operator"] | undefined,
	sizes__large_jpeg__filename?: GraphQLTypes["User_Member_Avatar_sizes__large_jpeg__filename_operator"] | undefined,
	id?: GraphQLTypes["User_Member_Avatar_id_operator"] | undefined,
	AND?: Array<GraphQLTypes["User_Member_Avatar_where_and"] | undefined> | undefined,
	OR?: Array<GraphQLTypes["User_Member_Avatar_where_or"] | undefined> | undefined
};
	["User_Member_Avatar_alt_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["User_Member_Avatar_updatedAt_operator"]: {
		equals?: GraphQLTypes["DateTime"] | undefined,
	not_equals?: GraphQLTypes["DateTime"] | undefined,
	greater_than_equal?: GraphQLTypes["DateTime"] | undefined,
	greater_than?: GraphQLTypes["DateTime"] | undefined,
	less_than_equal?: GraphQLTypes["DateTime"] | undefined,
	less_than?: GraphQLTypes["DateTime"] | undefined,
	like?: GraphQLTypes["DateTime"] | undefined,
	exists?: boolean | undefined
};
	["User_Member_Avatar_createdAt_operator"]: {
		equals?: GraphQLTypes["DateTime"] | undefined,
	not_equals?: GraphQLTypes["DateTime"] | undefined,
	greater_than_equal?: GraphQLTypes["DateTime"] | undefined,
	greater_than?: GraphQLTypes["DateTime"] | undefined,
	less_than_equal?: GraphQLTypes["DateTime"] | undefined,
	less_than?: GraphQLTypes["DateTime"] | undefined,
	like?: GraphQLTypes["DateTime"] | undefined,
	exists?: boolean | undefined
};
	["User_Member_Avatar_url_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["User_Member_Avatar_filename_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["User_Member_Avatar_mimeType_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["User_Member_Avatar_filesize_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["User_Member_Avatar_width_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["User_Member_Avatar_height_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["User_Member_Avatar_focalX_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["User_Member_Avatar_focalY_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["User_Member_Avatar_sizes__small__url_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["User_Member_Avatar_sizes__small__width_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["User_Member_Avatar_sizes__small__height_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["User_Member_Avatar_sizes__small__mimeType_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["User_Member_Avatar_sizes__small__filesize_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["User_Member_Avatar_sizes__small__filename_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["User_Member_Avatar_sizes__medium__url_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["User_Member_Avatar_sizes__medium__width_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["User_Member_Avatar_sizes__medium__height_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["User_Member_Avatar_sizes__medium__mimeType_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["User_Member_Avatar_sizes__medium__filesize_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["User_Member_Avatar_sizes__medium__filename_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["User_Member_Avatar_sizes__large__url_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["User_Member_Avatar_sizes__large__width_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["User_Member_Avatar_sizes__large__height_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["User_Member_Avatar_sizes__large__mimeType_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["User_Member_Avatar_sizes__large__filesize_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["User_Member_Avatar_sizes__large__filename_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["User_Member_Avatar_sizes__small_jpeg__url_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["User_Member_Avatar_sizes__small_jpeg__width_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["User_Member_Avatar_sizes__small_jpeg__height_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["User_Member_Avatar_sizes__small_jpeg__mimeType_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["User_Member_Avatar_sizes__small_jpeg__filesize_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["User_Member_Avatar_sizes__small_jpeg__filename_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["User_Member_Avatar_sizes__medium_jpeg__url_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["User_Member_Avatar_sizes__medium_jpeg__width_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["User_Member_Avatar_sizes__medium_jpeg__height_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["User_Member_Avatar_sizes__medium_jpeg__mimeType_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["User_Member_Avatar_sizes__medium_jpeg__filesize_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["User_Member_Avatar_sizes__medium_jpeg__filename_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["User_Member_Avatar_sizes__large_jpeg__url_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["User_Member_Avatar_sizes__large_jpeg__width_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["User_Member_Avatar_sizes__large_jpeg__height_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["User_Member_Avatar_sizes__large_jpeg__mimeType_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["User_Member_Avatar_sizes__large_jpeg__filesize_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["User_Member_Avatar_sizes__large_jpeg__filename_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["User_Member_Avatar_id_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["User_Member_Avatar_where_and"]: {
		alt?: GraphQLTypes["User_Member_Avatar_alt_operator"] | undefined,
	updatedAt?: GraphQLTypes["User_Member_Avatar_updatedAt_operator"] | undefined,
	createdAt?: GraphQLTypes["User_Member_Avatar_createdAt_operator"] | undefined,
	url?: GraphQLTypes["User_Member_Avatar_url_operator"] | undefined,
	filename?: GraphQLTypes["User_Member_Avatar_filename_operator"] | undefined,
	mimeType?: GraphQLTypes["User_Member_Avatar_mimeType_operator"] | undefined,
	filesize?: GraphQLTypes["User_Member_Avatar_filesize_operator"] | undefined,
	width?: GraphQLTypes["User_Member_Avatar_width_operator"] | undefined,
	height?: GraphQLTypes["User_Member_Avatar_height_operator"] | undefined,
	focalX?: GraphQLTypes["User_Member_Avatar_focalX_operator"] | undefined,
	focalY?: GraphQLTypes["User_Member_Avatar_focalY_operator"] | undefined,
	sizes__small__url?: GraphQLTypes["User_Member_Avatar_sizes__small__url_operator"] | undefined,
	sizes__small__width?: GraphQLTypes["User_Member_Avatar_sizes__small__width_operator"] | undefined,
	sizes__small__height?: GraphQLTypes["User_Member_Avatar_sizes__small__height_operator"] | undefined,
	sizes__small__mimeType?: GraphQLTypes["User_Member_Avatar_sizes__small__mimeType_operator"] | undefined,
	sizes__small__filesize?: GraphQLTypes["User_Member_Avatar_sizes__small__filesize_operator"] | undefined,
	sizes__small__filename?: GraphQLTypes["User_Member_Avatar_sizes__small__filename_operator"] | undefined,
	sizes__medium__url?: GraphQLTypes["User_Member_Avatar_sizes__medium__url_operator"] | undefined,
	sizes__medium__width?: GraphQLTypes["User_Member_Avatar_sizes__medium__width_operator"] | undefined,
	sizes__medium__height?: GraphQLTypes["User_Member_Avatar_sizes__medium__height_operator"] | undefined,
	sizes__medium__mimeType?: GraphQLTypes["User_Member_Avatar_sizes__medium__mimeType_operator"] | undefined,
	sizes__medium__filesize?: GraphQLTypes["User_Member_Avatar_sizes__medium__filesize_operator"] | undefined,
	sizes__medium__filename?: GraphQLTypes["User_Member_Avatar_sizes__medium__filename_operator"] | undefined,
	sizes__large__url?: GraphQLTypes["User_Member_Avatar_sizes__large__url_operator"] | undefined,
	sizes__large__width?: GraphQLTypes["User_Member_Avatar_sizes__large__width_operator"] | undefined,
	sizes__large__height?: GraphQLTypes["User_Member_Avatar_sizes__large__height_operator"] | undefined,
	sizes__large__mimeType?: GraphQLTypes["User_Member_Avatar_sizes__large__mimeType_operator"] | undefined,
	sizes__large__filesize?: GraphQLTypes["User_Member_Avatar_sizes__large__filesize_operator"] | undefined,
	sizes__large__filename?: GraphQLTypes["User_Member_Avatar_sizes__large__filename_operator"] | undefined,
	sizes__small_jpeg__url?: GraphQLTypes["User_Member_Avatar_sizes__small_jpeg__url_operator"] | undefined,
	sizes__small_jpeg__width?: GraphQLTypes["User_Member_Avatar_sizes__small_jpeg__width_operator"] | undefined,
	sizes__small_jpeg__height?: GraphQLTypes["User_Member_Avatar_sizes__small_jpeg__height_operator"] | undefined,
	sizes__small_jpeg__mimeType?: GraphQLTypes["User_Member_Avatar_sizes__small_jpeg__mimeType_operator"] | undefined,
	sizes__small_jpeg__filesize?: GraphQLTypes["User_Member_Avatar_sizes__small_jpeg__filesize_operator"] | undefined,
	sizes__small_jpeg__filename?: GraphQLTypes["User_Member_Avatar_sizes__small_jpeg__filename_operator"] | undefined,
	sizes__medium_jpeg__url?: GraphQLTypes["User_Member_Avatar_sizes__medium_jpeg__url_operator"] | undefined,
	sizes__medium_jpeg__width?: GraphQLTypes["User_Member_Avatar_sizes__medium_jpeg__width_operator"] | undefined,
	sizes__medium_jpeg__height?: GraphQLTypes["User_Member_Avatar_sizes__medium_jpeg__height_operator"] | undefined,
	sizes__medium_jpeg__mimeType?: GraphQLTypes["User_Member_Avatar_sizes__medium_jpeg__mimeType_operator"] | undefined,
	sizes__medium_jpeg__filesize?: GraphQLTypes["User_Member_Avatar_sizes__medium_jpeg__filesize_operator"] | undefined,
	sizes__medium_jpeg__filename?: GraphQLTypes["User_Member_Avatar_sizes__medium_jpeg__filename_operator"] | undefined,
	sizes__large_jpeg__url?: GraphQLTypes["User_Member_Avatar_sizes__large_jpeg__url_operator"] | undefined,
	sizes__large_jpeg__width?: GraphQLTypes["User_Member_Avatar_sizes__large_jpeg__width_operator"] | undefined,
	sizes__large_jpeg__height?: GraphQLTypes["User_Member_Avatar_sizes__large_jpeg__height_operator"] | undefined,
	sizes__large_jpeg__mimeType?: GraphQLTypes["User_Member_Avatar_sizes__large_jpeg__mimeType_operator"] | undefined,
	sizes__large_jpeg__filesize?: GraphQLTypes["User_Member_Avatar_sizes__large_jpeg__filesize_operator"] | undefined,
	sizes__large_jpeg__filename?: GraphQLTypes["User_Member_Avatar_sizes__large_jpeg__filename_operator"] | undefined,
	id?: GraphQLTypes["User_Member_Avatar_id_operator"] | undefined,
	AND?: Array<GraphQLTypes["User_Member_Avatar_where_and"] | undefined> | undefined,
	OR?: Array<GraphQLTypes["User_Member_Avatar_where_or"] | undefined> | undefined
};
	["User_Member_Avatar_where_or"]: {
		alt?: GraphQLTypes["User_Member_Avatar_alt_operator"] | undefined,
	updatedAt?: GraphQLTypes["User_Member_Avatar_updatedAt_operator"] | undefined,
	createdAt?: GraphQLTypes["User_Member_Avatar_createdAt_operator"] | undefined,
	url?: GraphQLTypes["User_Member_Avatar_url_operator"] | undefined,
	filename?: GraphQLTypes["User_Member_Avatar_filename_operator"] | undefined,
	mimeType?: GraphQLTypes["User_Member_Avatar_mimeType_operator"] | undefined,
	filesize?: GraphQLTypes["User_Member_Avatar_filesize_operator"] | undefined,
	width?: GraphQLTypes["User_Member_Avatar_width_operator"] | undefined,
	height?: GraphQLTypes["User_Member_Avatar_height_operator"] | undefined,
	focalX?: GraphQLTypes["User_Member_Avatar_focalX_operator"] | undefined,
	focalY?: GraphQLTypes["User_Member_Avatar_focalY_operator"] | undefined,
	sizes__small__url?: GraphQLTypes["User_Member_Avatar_sizes__small__url_operator"] | undefined,
	sizes__small__width?: GraphQLTypes["User_Member_Avatar_sizes__small__width_operator"] | undefined,
	sizes__small__height?: GraphQLTypes["User_Member_Avatar_sizes__small__height_operator"] | undefined,
	sizes__small__mimeType?: GraphQLTypes["User_Member_Avatar_sizes__small__mimeType_operator"] | undefined,
	sizes__small__filesize?: GraphQLTypes["User_Member_Avatar_sizes__small__filesize_operator"] | undefined,
	sizes__small__filename?: GraphQLTypes["User_Member_Avatar_sizes__small__filename_operator"] | undefined,
	sizes__medium__url?: GraphQLTypes["User_Member_Avatar_sizes__medium__url_operator"] | undefined,
	sizes__medium__width?: GraphQLTypes["User_Member_Avatar_sizes__medium__width_operator"] | undefined,
	sizes__medium__height?: GraphQLTypes["User_Member_Avatar_sizes__medium__height_operator"] | undefined,
	sizes__medium__mimeType?: GraphQLTypes["User_Member_Avatar_sizes__medium__mimeType_operator"] | undefined,
	sizes__medium__filesize?: GraphQLTypes["User_Member_Avatar_sizes__medium__filesize_operator"] | undefined,
	sizes__medium__filename?: GraphQLTypes["User_Member_Avatar_sizes__medium__filename_operator"] | undefined,
	sizes__large__url?: GraphQLTypes["User_Member_Avatar_sizes__large__url_operator"] | undefined,
	sizes__large__width?: GraphQLTypes["User_Member_Avatar_sizes__large__width_operator"] | undefined,
	sizes__large__height?: GraphQLTypes["User_Member_Avatar_sizes__large__height_operator"] | undefined,
	sizes__large__mimeType?: GraphQLTypes["User_Member_Avatar_sizes__large__mimeType_operator"] | undefined,
	sizes__large__filesize?: GraphQLTypes["User_Member_Avatar_sizes__large__filesize_operator"] | undefined,
	sizes__large__filename?: GraphQLTypes["User_Member_Avatar_sizes__large__filename_operator"] | undefined,
	sizes__small_jpeg__url?: GraphQLTypes["User_Member_Avatar_sizes__small_jpeg__url_operator"] | undefined,
	sizes__small_jpeg__width?: GraphQLTypes["User_Member_Avatar_sizes__small_jpeg__width_operator"] | undefined,
	sizes__small_jpeg__height?: GraphQLTypes["User_Member_Avatar_sizes__small_jpeg__height_operator"] | undefined,
	sizes__small_jpeg__mimeType?: GraphQLTypes["User_Member_Avatar_sizes__small_jpeg__mimeType_operator"] | undefined,
	sizes__small_jpeg__filesize?: GraphQLTypes["User_Member_Avatar_sizes__small_jpeg__filesize_operator"] | undefined,
	sizes__small_jpeg__filename?: GraphQLTypes["User_Member_Avatar_sizes__small_jpeg__filename_operator"] | undefined,
	sizes__medium_jpeg__url?: GraphQLTypes["User_Member_Avatar_sizes__medium_jpeg__url_operator"] | undefined,
	sizes__medium_jpeg__width?: GraphQLTypes["User_Member_Avatar_sizes__medium_jpeg__width_operator"] | undefined,
	sizes__medium_jpeg__height?: GraphQLTypes["User_Member_Avatar_sizes__medium_jpeg__height_operator"] | undefined,
	sizes__medium_jpeg__mimeType?: GraphQLTypes["User_Member_Avatar_sizes__medium_jpeg__mimeType_operator"] | undefined,
	sizes__medium_jpeg__filesize?: GraphQLTypes["User_Member_Avatar_sizes__medium_jpeg__filesize_operator"] | undefined,
	sizes__medium_jpeg__filename?: GraphQLTypes["User_Member_Avatar_sizes__medium_jpeg__filename_operator"] | undefined,
	sizes__large_jpeg__url?: GraphQLTypes["User_Member_Avatar_sizes__large_jpeg__url_operator"] | undefined,
	sizes__large_jpeg__width?: GraphQLTypes["User_Member_Avatar_sizes__large_jpeg__width_operator"] | undefined,
	sizes__large_jpeg__height?: GraphQLTypes["User_Member_Avatar_sizes__large_jpeg__height_operator"] | undefined,
	sizes__large_jpeg__mimeType?: GraphQLTypes["User_Member_Avatar_sizes__large_jpeg__mimeType_operator"] | undefined,
	sizes__large_jpeg__filesize?: GraphQLTypes["User_Member_Avatar_sizes__large_jpeg__filesize_operator"] | undefined,
	sizes__large_jpeg__filename?: GraphQLTypes["User_Member_Avatar_sizes__large_jpeg__filename_operator"] | undefined,
	id?: GraphQLTypes["User_Member_Avatar_id_operator"] | undefined,
	AND?: Array<GraphQLTypes["User_Member_Avatar_where_and"] | undefined> | undefined,
	OR?: Array<GraphQLTypes["User_Member_Avatar_where_or"] | undefined> | undefined
};
	["User_Member_gender"]: User_Member_gender;
	["EventCategory"]: {
	__typename: "EventCategory",
	id?: string | undefined,
	name?: string | undefined,
	value: string,
	tags?: Array<GraphQLTypes["Tag"]> | undefined,
	icon?: GraphQLTypes["Media"] | undefined,
	region?: GraphQLTypes["EventCategory_region"] | undefined,
	updatedAt?: GraphQLTypes["DateTime"] | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined
};
	["Tag"]: {
	__typename: "Tag",
	id?: string | undefined,
	name: string,
	value: string,
	region?: GraphQLTypes["Tag_region"] | undefined,
	count?: number | undefined,
	skipCountCheck?: boolean | undefined,
	removeNextCron?: boolean | undefined
};
	["Tag_region"]: Tag_region;
	["EventCategory_Icon_where"]: {
		alt?: GraphQLTypes["EventCategory_Icon_alt_operator"] | undefined,
	updatedAt?: GraphQLTypes["EventCategory_Icon_updatedAt_operator"] | undefined,
	createdAt?: GraphQLTypes["EventCategory_Icon_createdAt_operator"] | undefined,
	url?: GraphQLTypes["EventCategory_Icon_url_operator"] | undefined,
	filename?: GraphQLTypes["EventCategory_Icon_filename_operator"] | undefined,
	mimeType?: GraphQLTypes["EventCategory_Icon_mimeType_operator"] | undefined,
	filesize?: GraphQLTypes["EventCategory_Icon_filesize_operator"] | undefined,
	width?: GraphQLTypes["EventCategory_Icon_width_operator"] | undefined,
	height?: GraphQLTypes["EventCategory_Icon_height_operator"] | undefined,
	focalX?: GraphQLTypes["EventCategory_Icon_focalX_operator"] | undefined,
	focalY?: GraphQLTypes["EventCategory_Icon_focalY_operator"] | undefined,
	sizes__small__url?: GraphQLTypes["EventCategory_Icon_sizes__small__url_operator"] | undefined,
	sizes__small__width?: GraphQLTypes["EventCategory_Icon_sizes__small__width_operator"] | undefined,
	sizes__small__height?: GraphQLTypes["EventCategory_Icon_sizes__small__height_operator"] | undefined,
	sizes__small__mimeType?: GraphQLTypes["EventCategory_Icon_sizes__small__mimeType_operator"] | undefined,
	sizes__small__filesize?: GraphQLTypes["EventCategory_Icon_sizes__small__filesize_operator"] | undefined,
	sizes__small__filename?: GraphQLTypes["EventCategory_Icon_sizes__small__filename_operator"] | undefined,
	sizes__medium__url?: GraphQLTypes["EventCategory_Icon_sizes__medium__url_operator"] | undefined,
	sizes__medium__width?: GraphQLTypes["EventCategory_Icon_sizes__medium__width_operator"] | undefined,
	sizes__medium__height?: GraphQLTypes["EventCategory_Icon_sizes__medium__height_operator"] | undefined,
	sizes__medium__mimeType?: GraphQLTypes["EventCategory_Icon_sizes__medium__mimeType_operator"] | undefined,
	sizes__medium__filesize?: GraphQLTypes["EventCategory_Icon_sizes__medium__filesize_operator"] | undefined,
	sizes__medium__filename?: GraphQLTypes["EventCategory_Icon_sizes__medium__filename_operator"] | undefined,
	sizes__large__url?: GraphQLTypes["EventCategory_Icon_sizes__large__url_operator"] | undefined,
	sizes__large__width?: GraphQLTypes["EventCategory_Icon_sizes__large__width_operator"] | undefined,
	sizes__large__height?: GraphQLTypes["EventCategory_Icon_sizes__large__height_operator"] | undefined,
	sizes__large__mimeType?: GraphQLTypes["EventCategory_Icon_sizes__large__mimeType_operator"] | undefined,
	sizes__large__filesize?: GraphQLTypes["EventCategory_Icon_sizes__large__filesize_operator"] | undefined,
	sizes__large__filename?: GraphQLTypes["EventCategory_Icon_sizes__large__filename_operator"] | undefined,
	sizes__small_jpeg__url?: GraphQLTypes["EventCategory_Icon_sizes__small_jpeg__url_operator"] | undefined,
	sizes__small_jpeg__width?: GraphQLTypes["EventCategory_Icon_sizes__small_jpeg__width_operator"] | undefined,
	sizes__small_jpeg__height?: GraphQLTypes["EventCategory_Icon_sizes__small_jpeg__height_operator"] | undefined,
	sizes__small_jpeg__mimeType?: GraphQLTypes["EventCategory_Icon_sizes__small_jpeg__mimeType_operator"] | undefined,
	sizes__small_jpeg__filesize?: GraphQLTypes["EventCategory_Icon_sizes__small_jpeg__filesize_operator"] | undefined,
	sizes__small_jpeg__filename?: GraphQLTypes["EventCategory_Icon_sizes__small_jpeg__filename_operator"] | undefined,
	sizes__medium_jpeg__url?: GraphQLTypes["EventCategory_Icon_sizes__medium_jpeg__url_operator"] | undefined,
	sizes__medium_jpeg__width?: GraphQLTypes["EventCategory_Icon_sizes__medium_jpeg__width_operator"] | undefined,
	sizes__medium_jpeg__height?: GraphQLTypes["EventCategory_Icon_sizes__medium_jpeg__height_operator"] | undefined,
	sizes__medium_jpeg__mimeType?: GraphQLTypes["EventCategory_Icon_sizes__medium_jpeg__mimeType_operator"] | undefined,
	sizes__medium_jpeg__filesize?: GraphQLTypes["EventCategory_Icon_sizes__medium_jpeg__filesize_operator"] | undefined,
	sizes__medium_jpeg__filename?: GraphQLTypes["EventCategory_Icon_sizes__medium_jpeg__filename_operator"] | undefined,
	sizes__large_jpeg__url?: GraphQLTypes["EventCategory_Icon_sizes__large_jpeg__url_operator"] | undefined,
	sizes__large_jpeg__width?: GraphQLTypes["EventCategory_Icon_sizes__large_jpeg__width_operator"] | undefined,
	sizes__large_jpeg__height?: GraphQLTypes["EventCategory_Icon_sizes__large_jpeg__height_operator"] | undefined,
	sizes__large_jpeg__mimeType?: GraphQLTypes["EventCategory_Icon_sizes__large_jpeg__mimeType_operator"] | undefined,
	sizes__large_jpeg__filesize?: GraphQLTypes["EventCategory_Icon_sizes__large_jpeg__filesize_operator"] | undefined,
	sizes__large_jpeg__filename?: GraphQLTypes["EventCategory_Icon_sizes__large_jpeg__filename_operator"] | undefined,
	id?: GraphQLTypes["EventCategory_Icon_id_operator"] | undefined,
	AND?: Array<GraphQLTypes["EventCategory_Icon_where_and"] | undefined> | undefined,
	OR?: Array<GraphQLTypes["EventCategory_Icon_where_or"] | undefined> | undefined
};
	["EventCategory_Icon_alt_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["EventCategory_Icon_updatedAt_operator"]: {
		equals?: GraphQLTypes["DateTime"] | undefined,
	not_equals?: GraphQLTypes["DateTime"] | undefined,
	greater_than_equal?: GraphQLTypes["DateTime"] | undefined,
	greater_than?: GraphQLTypes["DateTime"] | undefined,
	less_than_equal?: GraphQLTypes["DateTime"] | undefined,
	less_than?: GraphQLTypes["DateTime"] | undefined,
	like?: GraphQLTypes["DateTime"] | undefined,
	exists?: boolean | undefined
};
	["EventCategory_Icon_createdAt_operator"]: {
		equals?: GraphQLTypes["DateTime"] | undefined,
	not_equals?: GraphQLTypes["DateTime"] | undefined,
	greater_than_equal?: GraphQLTypes["DateTime"] | undefined,
	greater_than?: GraphQLTypes["DateTime"] | undefined,
	less_than_equal?: GraphQLTypes["DateTime"] | undefined,
	less_than?: GraphQLTypes["DateTime"] | undefined,
	like?: GraphQLTypes["DateTime"] | undefined,
	exists?: boolean | undefined
};
	["EventCategory_Icon_url_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["EventCategory_Icon_filename_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["EventCategory_Icon_mimeType_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["EventCategory_Icon_filesize_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["EventCategory_Icon_width_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["EventCategory_Icon_height_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["EventCategory_Icon_focalX_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["EventCategory_Icon_focalY_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["EventCategory_Icon_sizes__small__url_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["EventCategory_Icon_sizes__small__width_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["EventCategory_Icon_sizes__small__height_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["EventCategory_Icon_sizes__small__mimeType_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["EventCategory_Icon_sizes__small__filesize_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["EventCategory_Icon_sizes__small__filename_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["EventCategory_Icon_sizes__medium__url_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["EventCategory_Icon_sizes__medium__width_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["EventCategory_Icon_sizes__medium__height_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["EventCategory_Icon_sizes__medium__mimeType_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["EventCategory_Icon_sizes__medium__filesize_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["EventCategory_Icon_sizes__medium__filename_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["EventCategory_Icon_sizes__large__url_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["EventCategory_Icon_sizes__large__width_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["EventCategory_Icon_sizes__large__height_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["EventCategory_Icon_sizes__large__mimeType_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["EventCategory_Icon_sizes__large__filesize_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["EventCategory_Icon_sizes__large__filename_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["EventCategory_Icon_sizes__small_jpeg__url_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["EventCategory_Icon_sizes__small_jpeg__width_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["EventCategory_Icon_sizes__small_jpeg__height_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["EventCategory_Icon_sizes__small_jpeg__mimeType_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["EventCategory_Icon_sizes__small_jpeg__filesize_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["EventCategory_Icon_sizes__small_jpeg__filename_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["EventCategory_Icon_sizes__medium_jpeg__url_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["EventCategory_Icon_sizes__medium_jpeg__width_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["EventCategory_Icon_sizes__medium_jpeg__height_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["EventCategory_Icon_sizes__medium_jpeg__mimeType_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["EventCategory_Icon_sizes__medium_jpeg__filesize_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["EventCategory_Icon_sizes__medium_jpeg__filename_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["EventCategory_Icon_sizes__large_jpeg__url_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["EventCategory_Icon_sizes__large_jpeg__width_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["EventCategory_Icon_sizes__large_jpeg__height_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["EventCategory_Icon_sizes__large_jpeg__mimeType_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["EventCategory_Icon_sizes__large_jpeg__filesize_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["EventCategory_Icon_sizes__large_jpeg__filename_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["EventCategory_Icon_id_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["EventCategory_Icon_where_and"]: {
		alt?: GraphQLTypes["EventCategory_Icon_alt_operator"] | undefined,
	updatedAt?: GraphQLTypes["EventCategory_Icon_updatedAt_operator"] | undefined,
	createdAt?: GraphQLTypes["EventCategory_Icon_createdAt_operator"] | undefined,
	url?: GraphQLTypes["EventCategory_Icon_url_operator"] | undefined,
	filename?: GraphQLTypes["EventCategory_Icon_filename_operator"] | undefined,
	mimeType?: GraphQLTypes["EventCategory_Icon_mimeType_operator"] | undefined,
	filesize?: GraphQLTypes["EventCategory_Icon_filesize_operator"] | undefined,
	width?: GraphQLTypes["EventCategory_Icon_width_operator"] | undefined,
	height?: GraphQLTypes["EventCategory_Icon_height_operator"] | undefined,
	focalX?: GraphQLTypes["EventCategory_Icon_focalX_operator"] | undefined,
	focalY?: GraphQLTypes["EventCategory_Icon_focalY_operator"] | undefined,
	sizes__small__url?: GraphQLTypes["EventCategory_Icon_sizes__small__url_operator"] | undefined,
	sizes__small__width?: GraphQLTypes["EventCategory_Icon_sizes__small__width_operator"] | undefined,
	sizes__small__height?: GraphQLTypes["EventCategory_Icon_sizes__small__height_operator"] | undefined,
	sizes__small__mimeType?: GraphQLTypes["EventCategory_Icon_sizes__small__mimeType_operator"] | undefined,
	sizes__small__filesize?: GraphQLTypes["EventCategory_Icon_sizes__small__filesize_operator"] | undefined,
	sizes__small__filename?: GraphQLTypes["EventCategory_Icon_sizes__small__filename_operator"] | undefined,
	sizes__medium__url?: GraphQLTypes["EventCategory_Icon_sizes__medium__url_operator"] | undefined,
	sizes__medium__width?: GraphQLTypes["EventCategory_Icon_sizes__medium__width_operator"] | undefined,
	sizes__medium__height?: GraphQLTypes["EventCategory_Icon_sizes__medium__height_operator"] | undefined,
	sizes__medium__mimeType?: GraphQLTypes["EventCategory_Icon_sizes__medium__mimeType_operator"] | undefined,
	sizes__medium__filesize?: GraphQLTypes["EventCategory_Icon_sizes__medium__filesize_operator"] | undefined,
	sizes__medium__filename?: GraphQLTypes["EventCategory_Icon_sizes__medium__filename_operator"] | undefined,
	sizes__large__url?: GraphQLTypes["EventCategory_Icon_sizes__large__url_operator"] | undefined,
	sizes__large__width?: GraphQLTypes["EventCategory_Icon_sizes__large__width_operator"] | undefined,
	sizes__large__height?: GraphQLTypes["EventCategory_Icon_sizes__large__height_operator"] | undefined,
	sizes__large__mimeType?: GraphQLTypes["EventCategory_Icon_sizes__large__mimeType_operator"] | undefined,
	sizes__large__filesize?: GraphQLTypes["EventCategory_Icon_sizes__large__filesize_operator"] | undefined,
	sizes__large__filename?: GraphQLTypes["EventCategory_Icon_sizes__large__filename_operator"] | undefined,
	sizes__small_jpeg__url?: GraphQLTypes["EventCategory_Icon_sizes__small_jpeg__url_operator"] | undefined,
	sizes__small_jpeg__width?: GraphQLTypes["EventCategory_Icon_sizes__small_jpeg__width_operator"] | undefined,
	sizes__small_jpeg__height?: GraphQLTypes["EventCategory_Icon_sizes__small_jpeg__height_operator"] | undefined,
	sizes__small_jpeg__mimeType?: GraphQLTypes["EventCategory_Icon_sizes__small_jpeg__mimeType_operator"] | undefined,
	sizes__small_jpeg__filesize?: GraphQLTypes["EventCategory_Icon_sizes__small_jpeg__filesize_operator"] | undefined,
	sizes__small_jpeg__filename?: GraphQLTypes["EventCategory_Icon_sizes__small_jpeg__filename_operator"] | undefined,
	sizes__medium_jpeg__url?: GraphQLTypes["EventCategory_Icon_sizes__medium_jpeg__url_operator"] | undefined,
	sizes__medium_jpeg__width?: GraphQLTypes["EventCategory_Icon_sizes__medium_jpeg__width_operator"] | undefined,
	sizes__medium_jpeg__height?: GraphQLTypes["EventCategory_Icon_sizes__medium_jpeg__height_operator"] | undefined,
	sizes__medium_jpeg__mimeType?: GraphQLTypes["EventCategory_Icon_sizes__medium_jpeg__mimeType_operator"] | undefined,
	sizes__medium_jpeg__filesize?: GraphQLTypes["EventCategory_Icon_sizes__medium_jpeg__filesize_operator"] | undefined,
	sizes__medium_jpeg__filename?: GraphQLTypes["EventCategory_Icon_sizes__medium_jpeg__filename_operator"] | undefined,
	sizes__large_jpeg__url?: GraphQLTypes["EventCategory_Icon_sizes__large_jpeg__url_operator"] | undefined,
	sizes__large_jpeg__width?: GraphQLTypes["EventCategory_Icon_sizes__large_jpeg__width_operator"] | undefined,
	sizes__large_jpeg__height?: GraphQLTypes["EventCategory_Icon_sizes__large_jpeg__height_operator"] | undefined,
	sizes__large_jpeg__mimeType?: GraphQLTypes["EventCategory_Icon_sizes__large_jpeg__mimeType_operator"] | undefined,
	sizes__large_jpeg__filesize?: GraphQLTypes["EventCategory_Icon_sizes__large_jpeg__filesize_operator"] | undefined,
	sizes__large_jpeg__filename?: GraphQLTypes["EventCategory_Icon_sizes__large_jpeg__filename_operator"] | undefined,
	id?: GraphQLTypes["EventCategory_Icon_id_operator"] | undefined,
	AND?: Array<GraphQLTypes["EventCategory_Icon_where_and"] | undefined> | undefined,
	OR?: Array<GraphQLTypes["EventCategory_Icon_where_or"] | undefined> | undefined
};
	["EventCategory_Icon_where_or"]: {
		alt?: GraphQLTypes["EventCategory_Icon_alt_operator"] | undefined,
	updatedAt?: GraphQLTypes["EventCategory_Icon_updatedAt_operator"] | undefined,
	createdAt?: GraphQLTypes["EventCategory_Icon_createdAt_operator"] | undefined,
	url?: GraphQLTypes["EventCategory_Icon_url_operator"] | undefined,
	filename?: GraphQLTypes["EventCategory_Icon_filename_operator"] | undefined,
	mimeType?: GraphQLTypes["EventCategory_Icon_mimeType_operator"] | undefined,
	filesize?: GraphQLTypes["EventCategory_Icon_filesize_operator"] | undefined,
	width?: GraphQLTypes["EventCategory_Icon_width_operator"] | undefined,
	height?: GraphQLTypes["EventCategory_Icon_height_operator"] | undefined,
	focalX?: GraphQLTypes["EventCategory_Icon_focalX_operator"] | undefined,
	focalY?: GraphQLTypes["EventCategory_Icon_focalY_operator"] | undefined,
	sizes__small__url?: GraphQLTypes["EventCategory_Icon_sizes__small__url_operator"] | undefined,
	sizes__small__width?: GraphQLTypes["EventCategory_Icon_sizes__small__width_operator"] | undefined,
	sizes__small__height?: GraphQLTypes["EventCategory_Icon_sizes__small__height_operator"] | undefined,
	sizes__small__mimeType?: GraphQLTypes["EventCategory_Icon_sizes__small__mimeType_operator"] | undefined,
	sizes__small__filesize?: GraphQLTypes["EventCategory_Icon_sizes__small__filesize_operator"] | undefined,
	sizes__small__filename?: GraphQLTypes["EventCategory_Icon_sizes__small__filename_operator"] | undefined,
	sizes__medium__url?: GraphQLTypes["EventCategory_Icon_sizes__medium__url_operator"] | undefined,
	sizes__medium__width?: GraphQLTypes["EventCategory_Icon_sizes__medium__width_operator"] | undefined,
	sizes__medium__height?: GraphQLTypes["EventCategory_Icon_sizes__medium__height_operator"] | undefined,
	sizes__medium__mimeType?: GraphQLTypes["EventCategory_Icon_sizes__medium__mimeType_operator"] | undefined,
	sizes__medium__filesize?: GraphQLTypes["EventCategory_Icon_sizes__medium__filesize_operator"] | undefined,
	sizes__medium__filename?: GraphQLTypes["EventCategory_Icon_sizes__medium__filename_operator"] | undefined,
	sizes__large__url?: GraphQLTypes["EventCategory_Icon_sizes__large__url_operator"] | undefined,
	sizes__large__width?: GraphQLTypes["EventCategory_Icon_sizes__large__width_operator"] | undefined,
	sizes__large__height?: GraphQLTypes["EventCategory_Icon_sizes__large__height_operator"] | undefined,
	sizes__large__mimeType?: GraphQLTypes["EventCategory_Icon_sizes__large__mimeType_operator"] | undefined,
	sizes__large__filesize?: GraphQLTypes["EventCategory_Icon_sizes__large__filesize_operator"] | undefined,
	sizes__large__filename?: GraphQLTypes["EventCategory_Icon_sizes__large__filename_operator"] | undefined,
	sizes__small_jpeg__url?: GraphQLTypes["EventCategory_Icon_sizes__small_jpeg__url_operator"] | undefined,
	sizes__small_jpeg__width?: GraphQLTypes["EventCategory_Icon_sizes__small_jpeg__width_operator"] | undefined,
	sizes__small_jpeg__height?: GraphQLTypes["EventCategory_Icon_sizes__small_jpeg__height_operator"] | undefined,
	sizes__small_jpeg__mimeType?: GraphQLTypes["EventCategory_Icon_sizes__small_jpeg__mimeType_operator"] | undefined,
	sizes__small_jpeg__filesize?: GraphQLTypes["EventCategory_Icon_sizes__small_jpeg__filesize_operator"] | undefined,
	sizes__small_jpeg__filename?: GraphQLTypes["EventCategory_Icon_sizes__small_jpeg__filename_operator"] | undefined,
	sizes__medium_jpeg__url?: GraphQLTypes["EventCategory_Icon_sizes__medium_jpeg__url_operator"] | undefined,
	sizes__medium_jpeg__width?: GraphQLTypes["EventCategory_Icon_sizes__medium_jpeg__width_operator"] | undefined,
	sizes__medium_jpeg__height?: GraphQLTypes["EventCategory_Icon_sizes__medium_jpeg__height_operator"] | undefined,
	sizes__medium_jpeg__mimeType?: GraphQLTypes["EventCategory_Icon_sizes__medium_jpeg__mimeType_operator"] | undefined,
	sizes__medium_jpeg__filesize?: GraphQLTypes["EventCategory_Icon_sizes__medium_jpeg__filesize_operator"] | undefined,
	sizes__medium_jpeg__filename?: GraphQLTypes["EventCategory_Icon_sizes__medium_jpeg__filename_operator"] | undefined,
	sizes__large_jpeg__url?: GraphQLTypes["EventCategory_Icon_sizes__large_jpeg__url_operator"] | undefined,
	sizes__large_jpeg__width?: GraphQLTypes["EventCategory_Icon_sizes__large_jpeg__width_operator"] | undefined,
	sizes__large_jpeg__height?: GraphQLTypes["EventCategory_Icon_sizes__large_jpeg__height_operator"] | undefined,
	sizes__large_jpeg__mimeType?: GraphQLTypes["EventCategory_Icon_sizes__large_jpeg__mimeType_operator"] | undefined,
	sizes__large_jpeg__filesize?: GraphQLTypes["EventCategory_Icon_sizes__large_jpeg__filesize_operator"] | undefined,
	sizes__large_jpeg__filename?: GraphQLTypes["EventCategory_Icon_sizes__large_jpeg__filename_operator"] | undefined,
	id?: GraphQLTypes["EventCategory_Icon_id_operator"] | undefined,
	AND?: Array<GraphQLTypes["EventCategory_Icon_where_and"] | undefined> | undefined,
	OR?: Array<GraphQLTypes["EventCategory_Icon_where_or"] | undefined> | undefined
};
	["EventCategory_region"]: EventCategory_region;
	["User_Organiser"]: {
	__typename: "User_Organiser",
	logo?: GraphQLTypes["Media"] | undefined,
	banner?: GraphQLTypes["Media"] | undefined,
	description?: string | undefined,
	foundedIn?: GraphQLTypes["DateTime"] | undefined,
	address?: string | undefined,
	website?: string | undefined,
	facebook?: string | undefined,
	instagram?: string | undefined,
	language?: GraphQLTypes["User_Organiser_language"] | undefined,
	phone?: string | undefined,
	followers?: number | undefined
};
	["User_Organiser_Logo_where"]: {
		alt?: GraphQLTypes["User_Organiser_Logo_alt_operator"] | undefined,
	updatedAt?: GraphQLTypes["User_Organiser_Logo_updatedAt_operator"] | undefined,
	createdAt?: GraphQLTypes["User_Organiser_Logo_createdAt_operator"] | undefined,
	url?: GraphQLTypes["User_Organiser_Logo_url_operator"] | undefined,
	filename?: GraphQLTypes["User_Organiser_Logo_filename_operator"] | undefined,
	mimeType?: GraphQLTypes["User_Organiser_Logo_mimeType_operator"] | undefined,
	filesize?: GraphQLTypes["User_Organiser_Logo_filesize_operator"] | undefined,
	width?: GraphQLTypes["User_Organiser_Logo_width_operator"] | undefined,
	height?: GraphQLTypes["User_Organiser_Logo_height_operator"] | undefined,
	focalX?: GraphQLTypes["User_Organiser_Logo_focalX_operator"] | undefined,
	focalY?: GraphQLTypes["User_Organiser_Logo_focalY_operator"] | undefined,
	sizes__small__url?: GraphQLTypes["User_Organiser_Logo_sizes__small__url_operator"] | undefined,
	sizes__small__width?: GraphQLTypes["User_Organiser_Logo_sizes__small__width_operator"] | undefined,
	sizes__small__height?: GraphQLTypes["User_Organiser_Logo_sizes__small__height_operator"] | undefined,
	sizes__small__mimeType?: GraphQLTypes["User_Organiser_Logo_sizes__small__mimeType_operator"] | undefined,
	sizes__small__filesize?: GraphQLTypes["User_Organiser_Logo_sizes__small__filesize_operator"] | undefined,
	sizes__small__filename?: GraphQLTypes["User_Organiser_Logo_sizes__small__filename_operator"] | undefined,
	sizes__medium__url?: GraphQLTypes["User_Organiser_Logo_sizes__medium__url_operator"] | undefined,
	sizes__medium__width?: GraphQLTypes["User_Organiser_Logo_sizes__medium__width_operator"] | undefined,
	sizes__medium__height?: GraphQLTypes["User_Organiser_Logo_sizes__medium__height_operator"] | undefined,
	sizes__medium__mimeType?: GraphQLTypes["User_Organiser_Logo_sizes__medium__mimeType_operator"] | undefined,
	sizes__medium__filesize?: GraphQLTypes["User_Organiser_Logo_sizes__medium__filesize_operator"] | undefined,
	sizes__medium__filename?: GraphQLTypes["User_Organiser_Logo_sizes__medium__filename_operator"] | undefined,
	sizes__large__url?: GraphQLTypes["User_Organiser_Logo_sizes__large__url_operator"] | undefined,
	sizes__large__width?: GraphQLTypes["User_Organiser_Logo_sizes__large__width_operator"] | undefined,
	sizes__large__height?: GraphQLTypes["User_Organiser_Logo_sizes__large__height_operator"] | undefined,
	sizes__large__mimeType?: GraphQLTypes["User_Organiser_Logo_sizes__large__mimeType_operator"] | undefined,
	sizes__large__filesize?: GraphQLTypes["User_Organiser_Logo_sizes__large__filesize_operator"] | undefined,
	sizes__large__filename?: GraphQLTypes["User_Organiser_Logo_sizes__large__filename_operator"] | undefined,
	sizes__small_jpeg__url?: GraphQLTypes["User_Organiser_Logo_sizes__small_jpeg__url_operator"] | undefined,
	sizes__small_jpeg__width?: GraphQLTypes["User_Organiser_Logo_sizes__small_jpeg__width_operator"] | undefined,
	sizes__small_jpeg__height?: GraphQLTypes["User_Organiser_Logo_sizes__small_jpeg__height_operator"] | undefined,
	sizes__small_jpeg__mimeType?: GraphQLTypes["User_Organiser_Logo_sizes__small_jpeg__mimeType_operator"] | undefined,
	sizes__small_jpeg__filesize?: GraphQLTypes["User_Organiser_Logo_sizes__small_jpeg__filesize_operator"] | undefined,
	sizes__small_jpeg__filename?: GraphQLTypes["User_Organiser_Logo_sizes__small_jpeg__filename_operator"] | undefined,
	sizes__medium_jpeg__url?: GraphQLTypes["User_Organiser_Logo_sizes__medium_jpeg__url_operator"] | undefined,
	sizes__medium_jpeg__width?: GraphQLTypes["User_Organiser_Logo_sizes__medium_jpeg__width_operator"] | undefined,
	sizes__medium_jpeg__height?: GraphQLTypes["User_Organiser_Logo_sizes__medium_jpeg__height_operator"] | undefined,
	sizes__medium_jpeg__mimeType?: GraphQLTypes["User_Organiser_Logo_sizes__medium_jpeg__mimeType_operator"] | undefined,
	sizes__medium_jpeg__filesize?: GraphQLTypes["User_Organiser_Logo_sizes__medium_jpeg__filesize_operator"] | undefined,
	sizes__medium_jpeg__filename?: GraphQLTypes["User_Organiser_Logo_sizes__medium_jpeg__filename_operator"] | undefined,
	sizes__large_jpeg__url?: GraphQLTypes["User_Organiser_Logo_sizes__large_jpeg__url_operator"] | undefined,
	sizes__large_jpeg__width?: GraphQLTypes["User_Organiser_Logo_sizes__large_jpeg__width_operator"] | undefined,
	sizes__large_jpeg__height?: GraphQLTypes["User_Organiser_Logo_sizes__large_jpeg__height_operator"] | undefined,
	sizes__large_jpeg__mimeType?: GraphQLTypes["User_Organiser_Logo_sizes__large_jpeg__mimeType_operator"] | undefined,
	sizes__large_jpeg__filesize?: GraphQLTypes["User_Organiser_Logo_sizes__large_jpeg__filesize_operator"] | undefined,
	sizes__large_jpeg__filename?: GraphQLTypes["User_Organiser_Logo_sizes__large_jpeg__filename_operator"] | undefined,
	id?: GraphQLTypes["User_Organiser_Logo_id_operator"] | undefined,
	AND?: Array<GraphQLTypes["User_Organiser_Logo_where_and"] | undefined> | undefined,
	OR?: Array<GraphQLTypes["User_Organiser_Logo_where_or"] | undefined> | undefined
};
	["User_Organiser_Logo_alt_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["User_Organiser_Logo_updatedAt_operator"]: {
		equals?: GraphQLTypes["DateTime"] | undefined,
	not_equals?: GraphQLTypes["DateTime"] | undefined,
	greater_than_equal?: GraphQLTypes["DateTime"] | undefined,
	greater_than?: GraphQLTypes["DateTime"] | undefined,
	less_than_equal?: GraphQLTypes["DateTime"] | undefined,
	less_than?: GraphQLTypes["DateTime"] | undefined,
	like?: GraphQLTypes["DateTime"] | undefined,
	exists?: boolean | undefined
};
	["User_Organiser_Logo_createdAt_operator"]: {
		equals?: GraphQLTypes["DateTime"] | undefined,
	not_equals?: GraphQLTypes["DateTime"] | undefined,
	greater_than_equal?: GraphQLTypes["DateTime"] | undefined,
	greater_than?: GraphQLTypes["DateTime"] | undefined,
	less_than_equal?: GraphQLTypes["DateTime"] | undefined,
	less_than?: GraphQLTypes["DateTime"] | undefined,
	like?: GraphQLTypes["DateTime"] | undefined,
	exists?: boolean | undefined
};
	["User_Organiser_Logo_url_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["User_Organiser_Logo_filename_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["User_Organiser_Logo_mimeType_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["User_Organiser_Logo_filesize_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["User_Organiser_Logo_width_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["User_Organiser_Logo_height_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["User_Organiser_Logo_focalX_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["User_Organiser_Logo_focalY_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["User_Organiser_Logo_sizes__small__url_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["User_Organiser_Logo_sizes__small__width_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["User_Organiser_Logo_sizes__small__height_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["User_Organiser_Logo_sizes__small__mimeType_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["User_Organiser_Logo_sizes__small__filesize_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["User_Organiser_Logo_sizes__small__filename_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["User_Organiser_Logo_sizes__medium__url_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["User_Organiser_Logo_sizes__medium__width_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["User_Organiser_Logo_sizes__medium__height_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["User_Organiser_Logo_sizes__medium__mimeType_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["User_Organiser_Logo_sizes__medium__filesize_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["User_Organiser_Logo_sizes__medium__filename_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["User_Organiser_Logo_sizes__large__url_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["User_Organiser_Logo_sizes__large__width_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["User_Organiser_Logo_sizes__large__height_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["User_Organiser_Logo_sizes__large__mimeType_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["User_Organiser_Logo_sizes__large__filesize_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["User_Organiser_Logo_sizes__large__filename_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["User_Organiser_Logo_sizes__small_jpeg__url_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["User_Organiser_Logo_sizes__small_jpeg__width_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["User_Organiser_Logo_sizes__small_jpeg__height_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["User_Organiser_Logo_sizes__small_jpeg__mimeType_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["User_Organiser_Logo_sizes__small_jpeg__filesize_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["User_Organiser_Logo_sizes__small_jpeg__filename_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["User_Organiser_Logo_sizes__medium_jpeg__url_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["User_Organiser_Logo_sizes__medium_jpeg__width_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["User_Organiser_Logo_sizes__medium_jpeg__height_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["User_Organiser_Logo_sizes__medium_jpeg__mimeType_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["User_Organiser_Logo_sizes__medium_jpeg__filesize_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["User_Organiser_Logo_sizes__medium_jpeg__filename_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["User_Organiser_Logo_sizes__large_jpeg__url_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["User_Organiser_Logo_sizes__large_jpeg__width_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["User_Organiser_Logo_sizes__large_jpeg__height_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["User_Organiser_Logo_sizes__large_jpeg__mimeType_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["User_Organiser_Logo_sizes__large_jpeg__filesize_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["User_Organiser_Logo_sizes__large_jpeg__filename_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["User_Organiser_Logo_id_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["User_Organiser_Logo_where_and"]: {
		alt?: GraphQLTypes["User_Organiser_Logo_alt_operator"] | undefined,
	updatedAt?: GraphQLTypes["User_Organiser_Logo_updatedAt_operator"] | undefined,
	createdAt?: GraphQLTypes["User_Organiser_Logo_createdAt_operator"] | undefined,
	url?: GraphQLTypes["User_Organiser_Logo_url_operator"] | undefined,
	filename?: GraphQLTypes["User_Organiser_Logo_filename_operator"] | undefined,
	mimeType?: GraphQLTypes["User_Organiser_Logo_mimeType_operator"] | undefined,
	filesize?: GraphQLTypes["User_Organiser_Logo_filesize_operator"] | undefined,
	width?: GraphQLTypes["User_Organiser_Logo_width_operator"] | undefined,
	height?: GraphQLTypes["User_Organiser_Logo_height_operator"] | undefined,
	focalX?: GraphQLTypes["User_Organiser_Logo_focalX_operator"] | undefined,
	focalY?: GraphQLTypes["User_Organiser_Logo_focalY_operator"] | undefined,
	sizes__small__url?: GraphQLTypes["User_Organiser_Logo_sizes__small__url_operator"] | undefined,
	sizes__small__width?: GraphQLTypes["User_Organiser_Logo_sizes__small__width_operator"] | undefined,
	sizes__small__height?: GraphQLTypes["User_Organiser_Logo_sizes__small__height_operator"] | undefined,
	sizes__small__mimeType?: GraphQLTypes["User_Organiser_Logo_sizes__small__mimeType_operator"] | undefined,
	sizes__small__filesize?: GraphQLTypes["User_Organiser_Logo_sizes__small__filesize_operator"] | undefined,
	sizes__small__filename?: GraphQLTypes["User_Organiser_Logo_sizes__small__filename_operator"] | undefined,
	sizes__medium__url?: GraphQLTypes["User_Organiser_Logo_sizes__medium__url_operator"] | undefined,
	sizes__medium__width?: GraphQLTypes["User_Organiser_Logo_sizes__medium__width_operator"] | undefined,
	sizes__medium__height?: GraphQLTypes["User_Organiser_Logo_sizes__medium__height_operator"] | undefined,
	sizes__medium__mimeType?: GraphQLTypes["User_Organiser_Logo_sizes__medium__mimeType_operator"] | undefined,
	sizes__medium__filesize?: GraphQLTypes["User_Organiser_Logo_sizes__medium__filesize_operator"] | undefined,
	sizes__medium__filename?: GraphQLTypes["User_Organiser_Logo_sizes__medium__filename_operator"] | undefined,
	sizes__large__url?: GraphQLTypes["User_Organiser_Logo_sizes__large__url_operator"] | undefined,
	sizes__large__width?: GraphQLTypes["User_Organiser_Logo_sizes__large__width_operator"] | undefined,
	sizes__large__height?: GraphQLTypes["User_Organiser_Logo_sizes__large__height_operator"] | undefined,
	sizes__large__mimeType?: GraphQLTypes["User_Organiser_Logo_sizes__large__mimeType_operator"] | undefined,
	sizes__large__filesize?: GraphQLTypes["User_Organiser_Logo_sizes__large__filesize_operator"] | undefined,
	sizes__large__filename?: GraphQLTypes["User_Organiser_Logo_sizes__large__filename_operator"] | undefined,
	sizes__small_jpeg__url?: GraphQLTypes["User_Organiser_Logo_sizes__small_jpeg__url_operator"] | undefined,
	sizes__small_jpeg__width?: GraphQLTypes["User_Organiser_Logo_sizes__small_jpeg__width_operator"] | undefined,
	sizes__small_jpeg__height?: GraphQLTypes["User_Organiser_Logo_sizes__small_jpeg__height_operator"] | undefined,
	sizes__small_jpeg__mimeType?: GraphQLTypes["User_Organiser_Logo_sizes__small_jpeg__mimeType_operator"] | undefined,
	sizes__small_jpeg__filesize?: GraphQLTypes["User_Organiser_Logo_sizes__small_jpeg__filesize_operator"] | undefined,
	sizes__small_jpeg__filename?: GraphQLTypes["User_Organiser_Logo_sizes__small_jpeg__filename_operator"] | undefined,
	sizes__medium_jpeg__url?: GraphQLTypes["User_Organiser_Logo_sizes__medium_jpeg__url_operator"] | undefined,
	sizes__medium_jpeg__width?: GraphQLTypes["User_Organiser_Logo_sizes__medium_jpeg__width_operator"] | undefined,
	sizes__medium_jpeg__height?: GraphQLTypes["User_Organiser_Logo_sizes__medium_jpeg__height_operator"] | undefined,
	sizes__medium_jpeg__mimeType?: GraphQLTypes["User_Organiser_Logo_sizes__medium_jpeg__mimeType_operator"] | undefined,
	sizes__medium_jpeg__filesize?: GraphQLTypes["User_Organiser_Logo_sizes__medium_jpeg__filesize_operator"] | undefined,
	sizes__medium_jpeg__filename?: GraphQLTypes["User_Organiser_Logo_sizes__medium_jpeg__filename_operator"] | undefined,
	sizes__large_jpeg__url?: GraphQLTypes["User_Organiser_Logo_sizes__large_jpeg__url_operator"] | undefined,
	sizes__large_jpeg__width?: GraphQLTypes["User_Organiser_Logo_sizes__large_jpeg__width_operator"] | undefined,
	sizes__large_jpeg__height?: GraphQLTypes["User_Organiser_Logo_sizes__large_jpeg__height_operator"] | undefined,
	sizes__large_jpeg__mimeType?: GraphQLTypes["User_Organiser_Logo_sizes__large_jpeg__mimeType_operator"] | undefined,
	sizes__large_jpeg__filesize?: GraphQLTypes["User_Organiser_Logo_sizes__large_jpeg__filesize_operator"] | undefined,
	sizes__large_jpeg__filename?: GraphQLTypes["User_Organiser_Logo_sizes__large_jpeg__filename_operator"] | undefined,
	id?: GraphQLTypes["User_Organiser_Logo_id_operator"] | undefined,
	AND?: Array<GraphQLTypes["User_Organiser_Logo_where_and"] | undefined> | undefined,
	OR?: Array<GraphQLTypes["User_Organiser_Logo_where_or"] | undefined> | undefined
};
	["User_Organiser_Logo_where_or"]: {
		alt?: GraphQLTypes["User_Organiser_Logo_alt_operator"] | undefined,
	updatedAt?: GraphQLTypes["User_Organiser_Logo_updatedAt_operator"] | undefined,
	createdAt?: GraphQLTypes["User_Organiser_Logo_createdAt_operator"] | undefined,
	url?: GraphQLTypes["User_Organiser_Logo_url_operator"] | undefined,
	filename?: GraphQLTypes["User_Organiser_Logo_filename_operator"] | undefined,
	mimeType?: GraphQLTypes["User_Organiser_Logo_mimeType_operator"] | undefined,
	filesize?: GraphQLTypes["User_Organiser_Logo_filesize_operator"] | undefined,
	width?: GraphQLTypes["User_Organiser_Logo_width_operator"] | undefined,
	height?: GraphQLTypes["User_Organiser_Logo_height_operator"] | undefined,
	focalX?: GraphQLTypes["User_Organiser_Logo_focalX_operator"] | undefined,
	focalY?: GraphQLTypes["User_Organiser_Logo_focalY_operator"] | undefined,
	sizes__small__url?: GraphQLTypes["User_Organiser_Logo_sizes__small__url_operator"] | undefined,
	sizes__small__width?: GraphQLTypes["User_Organiser_Logo_sizes__small__width_operator"] | undefined,
	sizes__small__height?: GraphQLTypes["User_Organiser_Logo_sizes__small__height_operator"] | undefined,
	sizes__small__mimeType?: GraphQLTypes["User_Organiser_Logo_sizes__small__mimeType_operator"] | undefined,
	sizes__small__filesize?: GraphQLTypes["User_Organiser_Logo_sizes__small__filesize_operator"] | undefined,
	sizes__small__filename?: GraphQLTypes["User_Organiser_Logo_sizes__small__filename_operator"] | undefined,
	sizes__medium__url?: GraphQLTypes["User_Organiser_Logo_sizes__medium__url_operator"] | undefined,
	sizes__medium__width?: GraphQLTypes["User_Organiser_Logo_sizes__medium__width_operator"] | undefined,
	sizes__medium__height?: GraphQLTypes["User_Organiser_Logo_sizes__medium__height_operator"] | undefined,
	sizes__medium__mimeType?: GraphQLTypes["User_Organiser_Logo_sizes__medium__mimeType_operator"] | undefined,
	sizes__medium__filesize?: GraphQLTypes["User_Organiser_Logo_sizes__medium__filesize_operator"] | undefined,
	sizes__medium__filename?: GraphQLTypes["User_Organiser_Logo_sizes__medium__filename_operator"] | undefined,
	sizes__large__url?: GraphQLTypes["User_Organiser_Logo_sizes__large__url_operator"] | undefined,
	sizes__large__width?: GraphQLTypes["User_Organiser_Logo_sizes__large__width_operator"] | undefined,
	sizes__large__height?: GraphQLTypes["User_Organiser_Logo_sizes__large__height_operator"] | undefined,
	sizes__large__mimeType?: GraphQLTypes["User_Organiser_Logo_sizes__large__mimeType_operator"] | undefined,
	sizes__large__filesize?: GraphQLTypes["User_Organiser_Logo_sizes__large__filesize_operator"] | undefined,
	sizes__large__filename?: GraphQLTypes["User_Organiser_Logo_sizes__large__filename_operator"] | undefined,
	sizes__small_jpeg__url?: GraphQLTypes["User_Organiser_Logo_sizes__small_jpeg__url_operator"] | undefined,
	sizes__small_jpeg__width?: GraphQLTypes["User_Organiser_Logo_sizes__small_jpeg__width_operator"] | undefined,
	sizes__small_jpeg__height?: GraphQLTypes["User_Organiser_Logo_sizes__small_jpeg__height_operator"] | undefined,
	sizes__small_jpeg__mimeType?: GraphQLTypes["User_Organiser_Logo_sizes__small_jpeg__mimeType_operator"] | undefined,
	sizes__small_jpeg__filesize?: GraphQLTypes["User_Organiser_Logo_sizes__small_jpeg__filesize_operator"] | undefined,
	sizes__small_jpeg__filename?: GraphQLTypes["User_Organiser_Logo_sizes__small_jpeg__filename_operator"] | undefined,
	sizes__medium_jpeg__url?: GraphQLTypes["User_Organiser_Logo_sizes__medium_jpeg__url_operator"] | undefined,
	sizes__medium_jpeg__width?: GraphQLTypes["User_Organiser_Logo_sizes__medium_jpeg__width_operator"] | undefined,
	sizes__medium_jpeg__height?: GraphQLTypes["User_Organiser_Logo_sizes__medium_jpeg__height_operator"] | undefined,
	sizes__medium_jpeg__mimeType?: GraphQLTypes["User_Organiser_Logo_sizes__medium_jpeg__mimeType_operator"] | undefined,
	sizes__medium_jpeg__filesize?: GraphQLTypes["User_Organiser_Logo_sizes__medium_jpeg__filesize_operator"] | undefined,
	sizes__medium_jpeg__filename?: GraphQLTypes["User_Organiser_Logo_sizes__medium_jpeg__filename_operator"] | undefined,
	sizes__large_jpeg__url?: GraphQLTypes["User_Organiser_Logo_sizes__large_jpeg__url_operator"] | undefined,
	sizes__large_jpeg__width?: GraphQLTypes["User_Organiser_Logo_sizes__large_jpeg__width_operator"] | undefined,
	sizes__large_jpeg__height?: GraphQLTypes["User_Organiser_Logo_sizes__large_jpeg__height_operator"] | undefined,
	sizes__large_jpeg__mimeType?: GraphQLTypes["User_Organiser_Logo_sizes__large_jpeg__mimeType_operator"] | undefined,
	sizes__large_jpeg__filesize?: GraphQLTypes["User_Organiser_Logo_sizes__large_jpeg__filesize_operator"] | undefined,
	sizes__large_jpeg__filename?: GraphQLTypes["User_Organiser_Logo_sizes__large_jpeg__filename_operator"] | undefined,
	id?: GraphQLTypes["User_Organiser_Logo_id_operator"] | undefined,
	AND?: Array<GraphQLTypes["User_Organiser_Logo_where_and"] | undefined> | undefined,
	OR?: Array<GraphQLTypes["User_Organiser_Logo_where_or"] | undefined> | undefined
};
	["User_Organiser_Banner_where"]: {
		alt?: GraphQLTypes["User_Organiser_Banner_alt_operator"] | undefined,
	updatedAt?: GraphQLTypes["User_Organiser_Banner_updatedAt_operator"] | undefined,
	createdAt?: GraphQLTypes["User_Organiser_Banner_createdAt_operator"] | undefined,
	url?: GraphQLTypes["User_Organiser_Banner_url_operator"] | undefined,
	filename?: GraphQLTypes["User_Organiser_Banner_filename_operator"] | undefined,
	mimeType?: GraphQLTypes["User_Organiser_Banner_mimeType_operator"] | undefined,
	filesize?: GraphQLTypes["User_Organiser_Banner_filesize_operator"] | undefined,
	width?: GraphQLTypes["User_Organiser_Banner_width_operator"] | undefined,
	height?: GraphQLTypes["User_Organiser_Banner_height_operator"] | undefined,
	focalX?: GraphQLTypes["User_Organiser_Banner_focalX_operator"] | undefined,
	focalY?: GraphQLTypes["User_Organiser_Banner_focalY_operator"] | undefined,
	sizes__small__url?: GraphQLTypes["User_Organiser_Banner_sizes__small__url_operator"] | undefined,
	sizes__small__width?: GraphQLTypes["User_Organiser_Banner_sizes__small__width_operator"] | undefined,
	sizes__small__height?: GraphQLTypes["User_Organiser_Banner_sizes__small__height_operator"] | undefined,
	sizes__small__mimeType?: GraphQLTypes["User_Organiser_Banner_sizes__small__mimeType_operator"] | undefined,
	sizes__small__filesize?: GraphQLTypes["User_Organiser_Banner_sizes__small__filesize_operator"] | undefined,
	sizes__small__filename?: GraphQLTypes["User_Organiser_Banner_sizes__small__filename_operator"] | undefined,
	sizes__medium__url?: GraphQLTypes["User_Organiser_Banner_sizes__medium__url_operator"] | undefined,
	sizes__medium__width?: GraphQLTypes["User_Organiser_Banner_sizes__medium__width_operator"] | undefined,
	sizes__medium__height?: GraphQLTypes["User_Organiser_Banner_sizes__medium__height_operator"] | undefined,
	sizes__medium__mimeType?: GraphQLTypes["User_Organiser_Banner_sizes__medium__mimeType_operator"] | undefined,
	sizes__medium__filesize?: GraphQLTypes["User_Organiser_Banner_sizes__medium__filesize_operator"] | undefined,
	sizes__medium__filename?: GraphQLTypes["User_Organiser_Banner_sizes__medium__filename_operator"] | undefined,
	sizes__large__url?: GraphQLTypes["User_Organiser_Banner_sizes__large__url_operator"] | undefined,
	sizes__large__width?: GraphQLTypes["User_Organiser_Banner_sizes__large__width_operator"] | undefined,
	sizes__large__height?: GraphQLTypes["User_Organiser_Banner_sizes__large__height_operator"] | undefined,
	sizes__large__mimeType?: GraphQLTypes["User_Organiser_Banner_sizes__large__mimeType_operator"] | undefined,
	sizes__large__filesize?: GraphQLTypes["User_Organiser_Banner_sizes__large__filesize_operator"] | undefined,
	sizes__large__filename?: GraphQLTypes["User_Organiser_Banner_sizes__large__filename_operator"] | undefined,
	sizes__small_jpeg__url?: GraphQLTypes["User_Organiser_Banner_sizes__small_jpeg__url_operator"] | undefined,
	sizes__small_jpeg__width?: GraphQLTypes["User_Organiser_Banner_sizes__small_jpeg__width_operator"] | undefined,
	sizes__small_jpeg__height?: GraphQLTypes["User_Organiser_Banner_sizes__small_jpeg__height_operator"] | undefined,
	sizes__small_jpeg__mimeType?: GraphQLTypes["User_Organiser_Banner_sizes__small_jpeg__mimeType_operator"] | undefined,
	sizes__small_jpeg__filesize?: GraphQLTypes["User_Organiser_Banner_sizes__small_jpeg__filesize_operator"] | undefined,
	sizes__small_jpeg__filename?: GraphQLTypes["User_Organiser_Banner_sizes__small_jpeg__filename_operator"] | undefined,
	sizes__medium_jpeg__url?: GraphQLTypes["User_Organiser_Banner_sizes__medium_jpeg__url_operator"] | undefined,
	sizes__medium_jpeg__width?: GraphQLTypes["User_Organiser_Banner_sizes__medium_jpeg__width_operator"] | undefined,
	sizes__medium_jpeg__height?: GraphQLTypes["User_Organiser_Banner_sizes__medium_jpeg__height_operator"] | undefined,
	sizes__medium_jpeg__mimeType?: GraphQLTypes["User_Organiser_Banner_sizes__medium_jpeg__mimeType_operator"] | undefined,
	sizes__medium_jpeg__filesize?: GraphQLTypes["User_Organiser_Banner_sizes__medium_jpeg__filesize_operator"] | undefined,
	sizes__medium_jpeg__filename?: GraphQLTypes["User_Organiser_Banner_sizes__medium_jpeg__filename_operator"] | undefined,
	sizes__large_jpeg__url?: GraphQLTypes["User_Organiser_Banner_sizes__large_jpeg__url_operator"] | undefined,
	sizes__large_jpeg__width?: GraphQLTypes["User_Organiser_Banner_sizes__large_jpeg__width_operator"] | undefined,
	sizes__large_jpeg__height?: GraphQLTypes["User_Organiser_Banner_sizes__large_jpeg__height_operator"] | undefined,
	sizes__large_jpeg__mimeType?: GraphQLTypes["User_Organiser_Banner_sizes__large_jpeg__mimeType_operator"] | undefined,
	sizes__large_jpeg__filesize?: GraphQLTypes["User_Organiser_Banner_sizes__large_jpeg__filesize_operator"] | undefined,
	sizes__large_jpeg__filename?: GraphQLTypes["User_Organiser_Banner_sizes__large_jpeg__filename_operator"] | undefined,
	id?: GraphQLTypes["User_Organiser_Banner_id_operator"] | undefined,
	AND?: Array<GraphQLTypes["User_Organiser_Banner_where_and"] | undefined> | undefined,
	OR?: Array<GraphQLTypes["User_Organiser_Banner_where_or"] | undefined> | undefined
};
	["User_Organiser_Banner_alt_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["User_Organiser_Banner_updatedAt_operator"]: {
		equals?: GraphQLTypes["DateTime"] | undefined,
	not_equals?: GraphQLTypes["DateTime"] | undefined,
	greater_than_equal?: GraphQLTypes["DateTime"] | undefined,
	greater_than?: GraphQLTypes["DateTime"] | undefined,
	less_than_equal?: GraphQLTypes["DateTime"] | undefined,
	less_than?: GraphQLTypes["DateTime"] | undefined,
	like?: GraphQLTypes["DateTime"] | undefined,
	exists?: boolean | undefined
};
	["User_Organiser_Banner_createdAt_operator"]: {
		equals?: GraphQLTypes["DateTime"] | undefined,
	not_equals?: GraphQLTypes["DateTime"] | undefined,
	greater_than_equal?: GraphQLTypes["DateTime"] | undefined,
	greater_than?: GraphQLTypes["DateTime"] | undefined,
	less_than_equal?: GraphQLTypes["DateTime"] | undefined,
	less_than?: GraphQLTypes["DateTime"] | undefined,
	like?: GraphQLTypes["DateTime"] | undefined,
	exists?: boolean | undefined
};
	["User_Organiser_Banner_url_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["User_Organiser_Banner_filename_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["User_Organiser_Banner_mimeType_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["User_Organiser_Banner_filesize_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["User_Organiser_Banner_width_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["User_Organiser_Banner_height_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["User_Organiser_Banner_focalX_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["User_Organiser_Banner_focalY_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["User_Organiser_Banner_sizes__small__url_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["User_Organiser_Banner_sizes__small__width_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["User_Organiser_Banner_sizes__small__height_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["User_Organiser_Banner_sizes__small__mimeType_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["User_Organiser_Banner_sizes__small__filesize_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["User_Organiser_Banner_sizes__small__filename_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["User_Organiser_Banner_sizes__medium__url_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["User_Organiser_Banner_sizes__medium__width_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["User_Organiser_Banner_sizes__medium__height_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["User_Organiser_Banner_sizes__medium__mimeType_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["User_Organiser_Banner_sizes__medium__filesize_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["User_Organiser_Banner_sizes__medium__filename_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["User_Organiser_Banner_sizes__large__url_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["User_Organiser_Banner_sizes__large__width_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["User_Organiser_Banner_sizes__large__height_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["User_Organiser_Banner_sizes__large__mimeType_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["User_Organiser_Banner_sizes__large__filesize_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["User_Organiser_Banner_sizes__large__filename_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["User_Organiser_Banner_sizes__small_jpeg__url_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["User_Organiser_Banner_sizes__small_jpeg__width_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["User_Organiser_Banner_sizes__small_jpeg__height_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["User_Organiser_Banner_sizes__small_jpeg__mimeType_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["User_Organiser_Banner_sizes__small_jpeg__filesize_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["User_Organiser_Banner_sizes__small_jpeg__filename_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["User_Organiser_Banner_sizes__medium_jpeg__url_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["User_Organiser_Banner_sizes__medium_jpeg__width_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["User_Organiser_Banner_sizes__medium_jpeg__height_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["User_Organiser_Banner_sizes__medium_jpeg__mimeType_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["User_Organiser_Banner_sizes__medium_jpeg__filesize_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["User_Organiser_Banner_sizes__medium_jpeg__filename_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["User_Organiser_Banner_sizes__large_jpeg__url_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["User_Organiser_Banner_sizes__large_jpeg__width_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["User_Organiser_Banner_sizes__large_jpeg__height_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["User_Organiser_Banner_sizes__large_jpeg__mimeType_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["User_Organiser_Banner_sizes__large_jpeg__filesize_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["User_Organiser_Banner_sizes__large_jpeg__filename_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["User_Organiser_Banner_id_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["User_Organiser_Banner_where_and"]: {
		alt?: GraphQLTypes["User_Organiser_Banner_alt_operator"] | undefined,
	updatedAt?: GraphQLTypes["User_Organiser_Banner_updatedAt_operator"] | undefined,
	createdAt?: GraphQLTypes["User_Organiser_Banner_createdAt_operator"] | undefined,
	url?: GraphQLTypes["User_Organiser_Banner_url_operator"] | undefined,
	filename?: GraphQLTypes["User_Organiser_Banner_filename_operator"] | undefined,
	mimeType?: GraphQLTypes["User_Organiser_Banner_mimeType_operator"] | undefined,
	filesize?: GraphQLTypes["User_Organiser_Banner_filesize_operator"] | undefined,
	width?: GraphQLTypes["User_Organiser_Banner_width_operator"] | undefined,
	height?: GraphQLTypes["User_Organiser_Banner_height_operator"] | undefined,
	focalX?: GraphQLTypes["User_Organiser_Banner_focalX_operator"] | undefined,
	focalY?: GraphQLTypes["User_Organiser_Banner_focalY_operator"] | undefined,
	sizes__small__url?: GraphQLTypes["User_Organiser_Banner_sizes__small__url_operator"] | undefined,
	sizes__small__width?: GraphQLTypes["User_Organiser_Banner_sizes__small__width_operator"] | undefined,
	sizes__small__height?: GraphQLTypes["User_Organiser_Banner_sizes__small__height_operator"] | undefined,
	sizes__small__mimeType?: GraphQLTypes["User_Organiser_Banner_sizes__small__mimeType_operator"] | undefined,
	sizes__small__filesize?: GraphQLTypes["User_Organiser_Banner_sizes__small__filesize_operator"] | undefined,
	sizes__small__filename?: GraphQLTypes["User_Organiser_Banner_sizes__small__filename_operator"] | undefined,
	sizes__medium__url?: GraphQLTypes["User_Organiser_Banner_sizes__medium__url_operator"] | undefined,
	sizes__medium__width?: GraphQLTypes["User_Organiser_Banner_sizes__medium__width_operator"] | undefined,
	sizes__medium__height?: GraphQLTypes["User_Organiser_Banner_sizes__medium__height_operator"] | undefined,
	sizes__medium__mimeType?: GraphQLTypes["User_Organiser_Banner_sizes__medium__mimeType_operator"] | undefined,
	sizes__medium__filesize?: GraphQLTypes["User_Organiser_Banner_sizes__medium__filesize_operator"] | undefined,
	sizes__medium__filename?: GraphQLTypes["User_Organiser_Banner_sizes__medium__filename_operator"] | undefined,
	sizes__large__url?: GraphQLTypes["User_Organiser_Banner_sizes__large__url_operator"] | undefined,
	sizes__large__width?: GraphQLTypes["User_Organiser_Banner_sizes__large__width_operator"] | undefined,
	sizes__large__height?: GraphQLTypes["User_Organiser_Banner_sizes__large__height_operator"] | undefined,
	sizes__large__mimeType?: GraphQLTypes["User_Organiser_Banner_sizes__large__mimeType_operator"] | undefined,
	sizes__large__filesize?: GraphQLTypes["User_Organiser_Banner_sizes__large__filesize_operator"] | undefined,
	sizes__large__filename?: GraphQLTypes["User_Organiser_Banner_sizes__large__filename_operator"] | undefined,
	sizes__small_jpeg__url?: GraphQLTypes["User_Organiser_Banner_sizes__small_jpeg__url_operator"] | undefined,
	sizes__small_jpeg__width?: GraphQLTypes["User_Organiser_Banner_sizes__small_jpeg__width_operator"] | undefined,
	sizes__small_jpeg__height?: GraphQLTypes["User_Organiser_Banner_sizes__small_jpeg__height_operator"] | undefined,
	sizes__small_jpeg__mimeType?: GraphQLTypes["User_Organiser_Banner_sizes__small_jpeg__mimeType_operator"] | undefined,
	sizes__small_jpeg__filesize?: GraphQLTypes["User_Organiser_Banner_sizes__small_jpeg__filesize_operator"] | undefined,
	sizes__small_jpeg__filename?: GraphQLTypes["User_Organiser_Banner_sizes__small_jpeg__filename_operator"] | undefined,
	sizes__medium_jpeg__url?: GraphQLTypes["User_Organiser_Banner_sizes__medium_jpeg__url_operator"] | undefined,
	sizes__medium_jpeg__width?: GraphQLTypes["User_Organiser_Banner_sizes__medium_jpeg__width_operator"] | undefined,
	sizes__medium_jpeg__height?: GraphQLTypes["User_Organiser_Banner_sizes__medium_jpeg__height_operator"] | undefined,
	sizes__medium_jpeg__mimeType?: GraphQLTypes["User_Organiser_Banner_sizes__medium_jpeg__mimeType_operator"] | undefined,
	sizes__medium_jpeg__filesize?: GraphQLTypes["User_Organiser_Banner_sizes__medium_jpeg__filesize_operator"] | undefined,
	sizes__medium_jpeg__filename?: GraphQLTypes["User_Organiser_Banner_sizes__medium_jpeg__filename_operator"] | undefined,
	sizes__large_jpeg__url?: GraphQLTypes["User_Organiser_Banner_sizes__large_jpeg__url_operator"] | undefined,
	sizes__large_jpeg__width?: GraphQLTypes["User_Organiser_Banner_sizes__large_jpeg__width_operator"] | undefined,
	sizes__large_jpeg__height?: GraphQLTypes["User_Organiser_Banner_sizes__large_jpeg__height_operator"] | undefined,
	sizes__large_jpeg__mimeType?: GraphQLTypes["User_Organiser_Banner_sizes__large_jpeg__mimeType_operator"] | undefined,
	sizes__large_jpeg__filesize?: GraphQLTypes["User_Organiser_Banner_sizes__large_jpeg__filesize_operator"] | undefined,
	sizes__large_jpeg__filename?: GraphQLTypes["User_Organiser_Banner_sizes__large_jpeg__filename_operator"] | undefined,
	id?: GraphQLTypes["User_Organiser_Banner_id_operator"] | undefined,
	AND?: Array<GraphQLTypes["User_Organiser_Banner_where_and"] | undefined> | undefined,
	OR?: Array<GraphQLTypes["User_Organiser_Banner_where_or"] | undefined> | undefined
};
	["User_Organiser_Banner_where_or"]: {
		alt?: GraphQLTypes["User_Organiser_Banner_alt_operator"] | undefined,
	updatedAt?: GraphQLTypes["User_Organiser_Banner_updatedAt_operator"] | undefined,
	createdAt?: GraphQLTypes["User_Organiser_Banner_createdAt_operator"] | undefined,
	url?: GraphQLTypes["User_Organiser_Banner_url_operator"] | undefined,
	filename?: GraphQLTypes["User_Organiser_Banner_filename_operator"] | undefined,
	mimeType?: GraphQLTypes["User_Organiser_Banner_mimeType_operator"] | undefined,
	filesize?: GraphQLTypes["User_Organiser_Banner_filesize_operator"] | undefined,
	width?: GraphQLTypes["User_Organiser_Banner_width_operator"] | undefined,
	height?: GraphQLTypes["User_Organiser_Banner_height_operator"] | undefined,
	focalX?: GraphQLTypes["User_Organiser_Banner_focalX_operator"] | undefined,
	focalY?: GraphQLTypes["User_Organiser_Banner_focalY_operator"] | undefined,
	sizes__small__url?: GraphQLTypes["User_Organiser_Banner_sizes__small__url_operator"] | undefined,
	sizes__small__width?: GraphQLTypes["User_Organiser_Banner_sizes__small__width_operator"] | undefined,
	sizes__small__height?: GraphQLTypes["User_Organiser_Banner_sizes__small__height_operator"] | undefined,
	sizes__small__mimeType?: GraphQLTypes["User_Organiser_Banner_sizes__small__mimeType_operator"] | undefined,
	sizes__small__filesize?: GraphQLTypes["User_Organiser_Banner_sizes__small__filesize_operator"] | undefined,
	sizes__small__filename?: GraphQLTypes["User_Organiser_Banner_sizes__small__filename_operator"] | undefined,
	sizes__medium__url?: GraphQLTypes["User_Organiser_Banner_sizes__medium__url_operator"] | undefined,
	sizes__medium__width?: GraphQLTypes["User_Organiser_Banner_sizes__medium__width_operator"] | undefined,
	sizes__medium__height?: GraphQLTypes["User_Organiser_Banner_sizes__medium__height_operator"] | undefined,
	sizes__medium__mimeType?: GraphQLTypes["User_Organiser_Banner_sizes__medium__mimeType_operator"] | undefined,
	sizes__medium__filesize?: GraphQLTypes["User_Organiser_Banner_sizes__medium__filesize_operator"] | undefined,
	sizes__medium__filename?: GraphQLTypes["User_Organiser_Banner_sizes__medium__filename_operator"] | undefined,
	sizes__large__url?: GraphQLTypes["User_Organiser_Banner_sizes__large__url_operator"] | undefined,
	sizes__large__width?: GraphQLTypes["User_Organiser_Banner_sizes__large__width_operator"] | undefined,
	sizes__large__height?: GraphQLTypes["User_Organiser_Banner_sizes__large__height_operator"] | undefined,
	sizes__large__mimeType?: GraphQLTypes["User_Organiser_Banner_sizes__large__mimeType_operator"] | undefined,
	sizes__large__filesize?: GraphQLTypes["User_Organiser_Banner_sizes__large__filesize_operator"] | undefined,
	sizes__large__filename?: GraphQLTypes["User_Organiser_Banner_sizes__large__filename_operator"] | undefined,
	sizes__small_jpeg__url?: GraphQLTypes["User_Organiser_Banner_sizes__small_jpeg__url_operator"] | undefined,
	sizes__small_jpeg__width?: GraphQLTypes["User_Organiser_Banner_sizes__small_jpeg__width_operator"] | undefined,
	sizes__small_jpeg__height?: GraphQLTypes["User_Organiser_Banner_sizes__small_jpeg__height_operator"] | undefined,
	sizes__small_jpeg__mimeType?: GraphQLTypes["User_Organiser_Banner_sizes__small_jpeg__mimeType_operator"] | undefined,
	sizes__small_jpeg__filesize?: GraphQLTypes["User_Organiser_Banner_sizes__small_jpeg__filesize_operator"] | undefined,
	sizes__small_jpeg__filename?: GraphQLTypes["User_Organiser_Banner_sizes__small_jpeg__filename_operator"] | undefined,
	sizes__medium_jpeg__url?: GraphQLTypes["User_Organiser_Banner_sizes__medium_jpeg__url_operator"] | undefined,
	sizes__medium_jpeg__width?: GraphQLTypes["User_Organiser_Banner_sizes__medium_jpeg__width_operator"] | undefined,
	sizes__medium_jpeg__height?: GraphQLTypes["User_Organiser_Banner_sizes__medium_jpeg__height_operator"] | undefined,
	sizes__medium_jpeg__mimeType?: GraphQLTypes["User_Organiser_Banner_sizes__medium_jpeg__mimeType_operator"] | undefined,
	sizes__medium_jpeg__filesize?: GraphQLTypes["User_Organiser_Banner_sizes__medium_jpeg__filesize_operator"] | undefined,
	sizes__medium_jpeg__filename?: GraphQLTypes["User_Organiser_Banner_sizes__medium_jpeg__filename_operator"] | undefined,
	sizes__large_jpeg__url?: GraphQLTypes["User_Organiser_Banner_sizes__large_jpeg__url_operator"] | undefined,
	sizes__large_jpeg__width?: GraphQLTypes["User_Organiser_Banner_sizes__large_jpeg__width_operator"] | undefined,
	sizes__large_jpeg__height?: GraphQLTypes["User_Organiser_Banner_sizes__large_jpeg__height_operator"] | undefined,
	sizes__large_jpeg__mimeType?: GraphQLTypes["User_Organiser_Banner_sizes__large_jpeg__mimeType_operator"] | undefined,
	sizes__large_jpeg__filesize?: GraphQLTypes["User_Organiser_Banner_sizes__large_jpeg__filesize_operator"] | undefined,
	sizes__large_jpeg__filename?: GraphQLTypes["User_Organiser_Banner_sizes__large_jpeg__filename_operator"] | undefined,
	id?: GraphQLTypes["User_Organiser_Banner_id_operator"] | undefined,
	AND?: Array<GraphQLTypes["User_Organiser_Banner_where_and"] | undefined> | undefined,
	OR?: Array<GraphQLTypes["User_Organiser_Banner_where_or"] | undefined> | undefined
};
	["User_Organiser_language"]: User_Organiser_language;
	["User_Sso"]: {
	__typename: "User_Sso",
	googleId?: string | undefined,
	facebookId?: string | undefined,
	appleId?: string | undefined
};
	["User_status"]: User_status;
	/** A field whose value conforms to the standard internet email address format as specified in HTML Spec: https://html.spec.whatwg.org/multipage/input.html#valid-e-mail-address. */
["EmailAddress"]: "scalar" & { name: "EmailAddress" };
	["Event_level"]: Event_level;
	["Event_Thumbnail_where"]: {
		alt?: GraphQLTypes["Event_Thumbnail_alt_operator"] | undefined,
	updatedAt?: GraphQLTypes["Event_Thumbnail_updatedAt_operator"] | undefined,
	createdAt?: GraphQLTypes["Event_Thumbnail_createdAt_operator"] | undefined,
	url?: GraphQLTypes["Event_Thumbnail_url_operator"] | undefined,
	filename?: GraphQLTypes["Event_Thumbnail_filename_operator"] | undefined,
	mimeType?: GraphQLTypes["Event_Thumbnail_mimeType_operator"] | undefined,
	filesize?: GraphQLTypes["Event_Thumbnail_filesize_operator"] | undefined,
	width?: GraphQLTypes["Event_Thumbnail_width_operator"] | undefined,
	height?: GraphQLTypes["Event_Thumbnail_height_operator"] | undefined,
	focalX?: GraphQLTypes["Event_Thumbnail_focalX_operator"] | undefined,
	focalY?: GraphQLTypes["Event_Thumbnail_focalY_operator"] | undefined,
	sizes__small__url?: GraphQLTypes["Event_Thumbnail_sizes__small__url_operator"] | undefined,
	sizes__small__width?: GraphQLTypes["Event_Thumbnail_sizes__small__width_operator"] | undefined,
	sizes__small__height?: GraphQLTypes["Event_Thumbnail_sizes__small__height_operator"] | undefined,
	sizes__small__mimeType?: GraphQLTypes["Event_Thumbnail_sizes__small__mimeType_operator"] | undefined,
	sizes__small__filesize?: GraphQLTypes["Event_Thumbnail_sizes__small__filesize_operator"] | undefined,
	sizes__small__filename?: GraphQLTypes["Event_Thumbnail_sizes__small__filename_operator"] | undefined,
	sizes__medium__url?: GraphQLTypes["Event_Thumbnail_sizes__medium__url_operator"] | undefined,
	sizes__medium__width?: GraphQLTypes["Event_Thumbnail_sizes__medium__width_operator"] | undefined,
	sizes__medium__height?: GraphQLTypes["Event_Thumbnail_sizes__medium__height_operator"] | undefined,
	sizes__medium__mimeType?: GraphQLTypes["Event_Thumbnail_sizes__medium__mimeType_operator"] | undefined,
	sizes__medium__filesize?: GraphQLTypes["Event_Thumbnail_sizes__medium__filesize_operator"] | undefined,
	sizes__medium__filename?: GraphQLTypes["Event_Thumbnail_sizes__medium__filename_operator"] | undefined,
	sizes__large__url?: GraphQLTypes["Event_Thumbnail_sizes__large__url_operator"] | undefined,
	sizes__large__width?: GraphQLTypes["Event_Thumbnail_sizes__large__width_operator"] | undefined,
	sizes__large__height?: GraphQLTypes["Event_Thumbnail_sizes__large__height_operator"] | undefined,
	sizes__large__mimeType?: GraphQLTypes["Event_Thumbnail_sizes__large__mimeType_operator"] | undefined,
	sizes__large__filesize?: GraphQLTypes["Event_Thumbnail_sizes__large__filesize_operator"] | undefined,
	sizes__large__filename?: GraphQLTypes["Event_Thumbnail_sizes__large__filename_operator"] | undefined,
	sizes__small_jpeg__url?: GraphQLTypes["Event_Thumbnail_sizes__small_jpeg__url_operator"] | undefined,
	sizes__small_jpeg__width?: GraphQLTypes["Event_Thumbnail_sizes__small_jpeg__width_operator"] | undefined,
	sizes__small_jpeg__height?: GraphQLTypes["Event_Thumbnail_sizes__small_jpeg__height_operator"] | undefined,
	sizes__small_jpeg__mimeType?: GraphQLTypes["Event_Thumbnail_sizes__small_jpeg__mimeType_operator"] | undefined,
	sizes__small_jpeg__filesize?: GraphQLTypes["Event_Thumbnail_sizes__small_jpeg__filesize_operator"] | undefined,
	sizes__small_jpeg__filename?: GraphQLTypes["Event_Thumbnail_sizes__small_jpeg__filename_operator"] | undefined,
	sizes__medium_jpeg__url?: GraphQLTypes["Event_Thumbnail_sizes__medium_jpeg__url_operator"] | undefined,
	sizes__medium_jpeg__width?: GraphQLTypes["Event_Thumbnail_sizes__medium_jpeg__width_operator"] | undefined,
	sizes__medium_jpeg__height?: GraphQLTypes["Event_Thumbnail_sizes__medium_jpeg__height_operator"] | undefined,
	sizes__medium_jpeg__mimeType?: GraphQLTypes["Event_Thumbnail_sizes__medium_jpeg__mimeType_operator"] | undefined,
	sizes__medium_jpeg__filesize?: GraphQLTypes["Event_Thumbnail_sizes__medium_jpeg__filesize_operator"] | undefined,
	sizes__medium_jpeg__filename?: GraphQLTypes["Event_Thumbnail_sizes__medium_jpeg__filename_operator"] | undefined,
	sizes__large_jpeg__url?: GraphQLTypes["Event_Thumbnail_sizes__large_jpeg__url_operator"] | undefined,
	sizes__large_jpeg__width?: GraphQLTypes["Event_Thumbnail_sizes__large_jpeg__width_operator"] | undefined,
	sizes__large_jpeg__height?: GraphQLTypes["Event_Thumbnail_sizes__large_jpeg__height_operator"] | undefined,
	sizes__large_jpeg__mimeType?: GraphQLTypes["Event_Thumbnail_sizes__large_jpeg__mimeType_operator"] | undefined,
	sizes__large_jpeg__filesize?: GraphQLTypes["Event_Thumbnail_sizes__large_jpeg__filesize_operator"] | undefined,
	sizes__large_jpeg__filename?: GraphQLTypes["Event_Thumbnail_sizes__large_jpeg__filename_operator"] | undefined,
	id?: GraphQLTypes["Event_Thumbnail_id_operator"] | undefined,
	AND?: Array<GraphQLTypes["Event_Thumbnail_where_and"] | undefined> | undefined,
	OR?: Array<GraphQLTypes["Event_Thumbnail_where_or"] | undefined> | undefined
};
	["Event_Thumbnail_alt_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Event_Thumbnail_updatedAt_operator"]: {
		equals?: GraphQLTypes["DateTime"] | undefined,
	not_equals?: GraphQLTypes["DateTime"] | undefined,
	greater_than_equal?: GraphQLTypes["DateTime"] | undefined,
	greater_than?: GraphQLTypes["DateTime"] | undefined,
	less_than_equal?: GraphQLTypes["DateTime"] | undefined,
	less_than?: GraphQLTypes["DateTime"] | undefined,
	like?: GraphQLTypes["DateTime"] | undefined,
	exists?: boolean | undefined
};
	["Event_Thumbnail_createdAt_operator"]: {
		equals?: GraphQLTypes["DateTime"] | undefined,
	not_equals?: GraphQLTypes["DateTime"] | undefined,
	greater_than_equal?: GraphQLTypes["DateTime"] | undefined,
	greater_than?: GraphQLTypes["DateTime"] | undefined,
	less_than_equal?: GraphQLTypes["DateTime"] | undefined,
	less_than?: GraphQLTypes["DateTime"] | undefined,
	like?: GraphQLTypes["DateTime"] | undefined,
	exists?: boolean | undefined
};
	["Event_Thumbnail_url_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Event_Thumbnail_filename_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Event_Thumbnail_mimeType_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Event_Thumbnail_filesize_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Event_Thumbnail_width_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Event_Thumbnail_height_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Event_Thumbnail_focalX_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Event_Thumbnail_focalY_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Event_Thumbnail_sizes__small__url_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Event_Thumbnail_sizes__small__width_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Event_Thumbnail_sizes__small__height_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Event_Thumbnail_sizes__small__mimeType_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Event_Thumbnail_sizes__small__filesize_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Event_Thumbnail_sizes__small__filename_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Event_Thumbnail_sizes__medium__url_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Event_Thumbnail_sizes__medium__width_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Event_Thumbnail_sizes__medium__height_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Event_Thumbnail_sizes__medium__mimeType_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Event_Thumbnail_sizes__medium__filesize_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Event_Thumbnail_sizes__medium__filename_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Event_Thumbnail_sizes__large__url_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Event_Thumbnail_sizes__large__width_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Event_Thumbnail_sizes__large__height_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Event_Thumbnail_sizes__large__mimeType_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Event_Thumbnail_sizes__large__filesize_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Event_Thumbnail_sizes__large__filename_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Event_Thumbnail_sizes__small_jpeg__url_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Event_Thumbnail_sizes__small_jpeg__width_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Event_Thumbnail_sizes__small_jpeg__height_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Event_Thumbnail_sizes__small_jpeg__mimeType_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Event_Thumbnail_sizes__small_jpeg__filesize_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Event_Thumbnail_sizes__small_jpeg__filename_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Event_Thumbnail_sizes__medium_jpeg__url_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Event_Thumbnail_sizes__medium_jpeg__width_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Event_Thumbnail_sizes__medium_jpeg__height_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Event_Thumbnail_sizes__medium_jpeg__mimeType_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Event_Thumbnail_sizes__medium_jpeg__filesize_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Event_Thumbnail_sizes__medium_jpeg__filename_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Event_Thumbnail_sizes__large_jpeg__url_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Event_Thumbnail_sizes__large_jpeg__width_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Event_Thumbnail_sizes__large_jpeg__height_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Event_Thumbnail_sizes__large_jpeg__mimeType_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Event_Thumbnail_sizes__large_jpeg__filesize_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Event_Thumbnail_sizes__large_jpeg__filename_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Event_Thumbnail_id_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Event_Thumbnail_where_and"]: {
		alt?: GraphQLTypes["Event_Thumbnail_alt_operator"] | undefined,
	updatedAt?: GraphQLTypes["Event_Thumbnail_updatedAt_operator"] | undefined,
	createdAt?: GraphQLTypes["Event_Thumbnail_createdAt_operator"] | undefined,
	url?: GraphQLTypes["Event_Thumbnail_url_operator"] | undefined,
	filename?: GraphQLTypes["Event_Thumbnail_filename_operator"] | undefined,
	mimeType?: GraphQLTypes["Event_Thumbnail_mimeType_operator"] | undefined,
	filesize?: GraphQLTypes["Event_Thumbnail_filesize_operator"] | undefined,
	width?: GraphQLTypes["Event_Thumbnail_width_operator"] | undefined,
	height?: GraphQLTypes["Event_Thumbnail_height_operator"] | undefined,
	focalX?: GraphQLTypes["Event_Thumbnail_focalX_operator"] | undefined,
	focalY?: GraphQLTypes["Event_Thumbnail_focalY_operator"] | undefined,
	sizes__small__url?: GraphQLTypes["Event_Thumbnail_sizes__small__url_operator"] | undefined,
	sizes__small__width?: GraphQLTypes["Event_Thumbnail_sizes__small__width_operator"] | undefined,
	sizes__small__height?: GraphQLTypes["Event_Thumbnail_sizes__small__height_operator"] | undefined,
	sizes__small__mimeType?: GraphQLTypes["Event_Thumbnail_sizes__small__mimeType_operator"] | undefined,
	sizes__small__filesize?: GraphQLTypes["Event_Thumbnail_sizes__small__filesize_operator"] | undefined,
	sizes__small__filename?: GraphQLTypes["Event_Thumbnail_sizes__small__filename_operator"] | undefined,
	sizes__medium__url?: GraphQLTypes["Event_Thumbnail_sizes__medium__url_operator"] | undefined,
	sizes__medium__width?: GraphQLTypes["Event_Thumbnail_sizes__medium__width_operator"] | undefined,
	sizes__medium__height?: GraphQLTypes["Event_Thumbnail_sizes__medium__height_operator"] | undefined,
	sizes__medium__mimeType?: GraphQLTypes["Event_Thumbnail_sizes__medium__mimeType_operator"] | undefined,
	sizes__medium__filesize?: GraphQLTypes["Event_Thumbnail_sizes__medium__filesize_operator"] | undefined,
	sizes__medium__filename?: GraphQLTypes["Event_Thumbnail_sizes__medium__filename_operator"] | undefined,
	sizes__large__url?: GraphQLTypes["Event_Thumbnail_sizes__large__url_operator"] | undefined,
	sizes__large__width?: GraphQLTypes["Event_Thumbnail_sizes__large__width_operator"] | undefined,
	sizes__large__height?: GraphQLTypes["Event_Thumbnail_sizes__large__height_operator"] | undefined,
	sizes__large__mimeType?: GraphQLTypes["Event_Thumbnail_sizes__large__mimeType_operator"] | undefined,
	sizes__large__filesize?: GraphQLTypes["Event_Thumbnail_sizes__large__filesize_operator"] | undefined,
	sizes__large__filename?: GraphQLTypes["Event_Thumbnail_sizes__large__filename_operator"] | undefined,
	sizes__small_jpeg__url?: GraphQLTypes["Event_Thumbnail_sizes__small_jpeg__url_operator"] | undefined,
	sizes__small_jpeg__width?: GraphQLTypes["Event_Thumbnail_sizes__small_jpeg__width_operator"] | undefined,
	sizes__small_jpeg__height?: GraphQLTypes["Event_Thumbnail_sizes__small_jpeg__height_operator"] | undefined,
	sizes__small_jpeg__mimeType?: GraphQLTypes["Event_Thumbnail_sizes__small_jpeg__mimeType_operator"] | undefined,
	sizes__small_jpeg__filesize?: GraphQLTypes["Event_Thumbnail_sizes__small_jpeg__filesize_operator"] | undefined,
	sizes__small_jpeg__filename?: GraphQLTypes["Event_Thumbnail_sizes__small_jpeg__filename_operator"] | undefined,
	sizes__medium_jpeg__url?: GraphQLTypes["Event_Thumbnail_sizes__medium_jpeg__url_operator"] | undefined,
	sizes__medium_jpeg__width?: GraphQLTypes["Event_Thumbnail_sizes__medium_jpeg__width_operator"] | undefined,
	sizes__medium_jpeg__height?: GraphQLTypes["Event_Thumbnail_sizes__medium_jpeg__height_operator"] | undefined,
	sizes__medium_jpeg__mimeType?: GraphQLTypes["Event_Thumbnail_sizes__medium_jpeg__mimeType_operator"] | undefined,
	sizes__medium_jpeg__filesize?: GraphQLTypes["Event_Thumbnail_sizes__medium_jpeg__filesize_operator"] | undefined,
	sizes__medium_jpeg__filename?: GraphQLTypes["Event_Thumbnail_sizes__medium_jpeg__filename_operator"] | undefined,
	sizes__large_jpeg__url?: GraphQLTypes["Event_Thumbnail_sizes__large_jpeg__url_operator"] | undefined,
	sizes__large_jpeg__width?: GraphQLTypes["Event_Thumbnail_sizes__large_jpeg__width_operator"] | undefined,
	sizes__large_jpeg__height?: GraphQLTypes["Event_Thumbnail_sizes__large_jpeg__height_operator"] | undefined,
	sizes__large_jpeg__mimeType?: GraphQLTypes["Event_Thumbnail_sizes__large_jpeg__mimeType_operator"] | undefined,
	sizes__large_jpeg__filesize?: GraphQLTypes["Event_Thumbnail_sizes__large_jpeg__filesize_operator"] | undefined,
	sizes__large_jpeg__filename?: GraphQLTypes["Event_Thumbnail_sizes__large_jpeg__filename_operator"] | undefined,
	id?: GraphQLTypes["Event_Thumbnail_id_operator"] | undefined,
	AND?: Array<GraphQLTypes["Event_Thumbnail_where_and"] | undefined> | undefined,
	OR?: Array<GraphQLTypes["Event_Thumbnail_where_or"] | undefined> | undefined
};
	["Event_Thumbnail_where_or"]: {
		alt?: GraphQLTypes["Event_Thumbnail_alt_operator"] | undefined,
	updatedAt?: GraphQLTypes["Event_Thumbnail_updatedAt_operator"] | undefined,
	createdAt?: GraphQLTypes["Event_Thumbnail_createdAt_operator"] | undefined,
	url?: GraphQLTypes["Event_Thumbnail_url_operator"] | undefined,
	filename?: GraphQLTypes["Event_Thumbnail_filename_operator"] | undefined,
	mimeType?: GraphQLTypes["Event_Thumbnail_mimeType_operator"] | undefined,
	filesize?: GraphQLTypes["Event_Thumbnail_filesize_operator"] | undefined,
	width?: GraphQLTypes["Event_Thumbnail_width_operator"] | undefined,
	height?: GraphQLTypes["Event_Thumbnail_height_operator"] | undefined,
	focalX?: GraphQLTypes["Event_Thumbnail_focalX_operator"] | undefined,
	focalY?: GraphQLTypes["Event_Thumbnail_focalY_operator"] | undefined,
	sizes__small__url?: GraphQLTypes["Event_Thumbnail_sizes__small__url_operator"] | undefined,
	sizes__small__width?: GraphQLTypes["Event_Thumbnail_sizes__small__width_operator"] | undefined,
	sizes__small__height?: GraphQLTypes["Event_Thumbnail_sizes__small__height_operator"] | undefined,
	sizes__small__mimeType?: GraphQLTypes["Event_Thumbnail_sizes__small__mimeType_operator"] | undefined,
	sizes__small__filesize?: GraphQLTypes["Event_Thumbnail_sizes__small__filesize_operator"] | undefined,
	sizes__small__filename?: GraphQLTypes["Event_Thumbnail_sizes__small__filename_operator"] | undefined,
	sizes__medium__url?: GraphQLTypes["Event_Thumbnail_sizes__medium__url_operator"] | undefined,
	sizes__medium__width?: GraphQLTypes["Event_Thumbnail_sizes__medium__width_operator"] | undefined,
	sizes__medium__height?: GraphQLTypes["Event_Thumbnail_sizes__medium__height_operator"] | undefined,
	sizes__medium__mimeType?: GraphQLTypes["Event_Thumbnail_sizes__medium__mimeType_operator"] | undefined,
	sizes__medium__filesize?: GraphQLTypes["Event_Thumbnail_sizes__medium__filesize_operator"] | undefined,
	sizes__medium__filename?: GraphQLTypes["Event_Thumbnail_sizes__medium__filename_operator"] | undefined,
	sizes__large__url?: GraphQLTypes["Event_Thumbnail_sizes__large__url_operator"] | undefined,
	sizes__large__width?: GraphQLTypes["Event_Thumbnail_sizes__large__width_operator"] | undefined,
	sizes__large__height?: GraphQLTypes["Event_Thumbnail_sizes__large__height_operator"] | undefined,
	sizes__large__mimeType?: GraphQLTypes["Event_Thumbnail_sizes__large__mimeType_operator"] | undefined,
	sizes__large__filesize?: GraphQLTypes["Event_Thumbnail_sizes__large__filesize_operator"] | undefined,
	sizes__large__filename?: GraphQLTypes["Event_Thumbnail_sizes__large__filename_operator"] | undefined,
	sizes__small_jpeg__url?: GraphQLTypes["Event_Thumbnail_sizes__small_jpeg__url_operator"] | undefined,
	sizes__small_jpeg__width?: GraphQLTypes["Event_Thumbnail_sizes__small_jpeg__width_operator"] | undefined,
	sizes__small_jpeg__height?: GraphQLTypes["Event_Thumbnail_sizes__small_jpeg__height_operator"] | undefined,
	sizes__small_jpeg__mimeType?: GraphQLTypes["Event_Thumbnail_sizes__small_jpeg__mimeType_operator"] | undefined,
	sizes__small_jpeg__filesize?: GraphQLTypes["Event_Thumbnail_sizes__small_jpeg__filesize_operator"] | undefined,
	sizes__small_jpeg__filename?: GraphQLTypes["Event_Thumbnail_sizes__small_jpeg__filename_operator"] | undefined,
	sizes__medium_jpeg__url?: GraphQLTypes["Event_Thumbnail_sizes__medium_jpeg__url_operator"] | undefined,
	sizes__medium_jpeg__width?: GraphQLTypes["Event_Thumbnail_sizes__medium_jpeg__width_operator"] | undefined,
	sizes__medium_jpeg__height?: GraphQLTypes["Event_Thumbnail_sizes__medium_jpeg__height_operator"] | undefined,
	sizes__medium_jpeg__mimeType?: GraphQLTypes["Event_Thumbnail_sizes__medium_jpeg__mimeType_operator"] | undefined,
	sizes__medium_jpeg__filesize?: GraphQLTypes["Event_Thumbnail_sizes__medium_jpeg__filesize_operator"] | undefined,
	sizes__medium_jpeg__filename?: GraphQLTypes["Event_Thumbnail_sizes__medium_jpeg__filename_operator"] | undefined,
	sizes__large_jpeg__url?: GraphQLTypes["Event_Thumbnail_sizes__large_jpeg__url_operator"] | undefined,
	sizes__large_jpeg__width?: GraphQLTypes["Event_Thumbnail_sizes__large_jpeg__width_operator"] | undefined,
	sizes__large_jpeg__height?: GraphQLTypes["Event_Thumbnail_sizes__large_jpeg__height_operator"] | undefined,
	sizes__large_jpeg__mimeType?: GraphQLTypes["Event_Thumbnail_sizes__large_jpeg__mimeType_operator"] | undefined,
	sizes__large_jpeg__filesize?: GraphQLTypes["Event_Thumbnail_sizes__large_jpeg__filesize_operator"] | undefined,
	sizes__large_jpeg__filename?: GraphQLTypes["Event_Thumbnail_sizes__large_jpeg__filename_operator"] | undefined,
	id?: GraphQLTypes["Event_Thumbnail_id_operator"] | undefined,
	AND?: Array<GraphQLTypes["Event_Thumbnail_where_and"] | undefined> | undefined,
	OR?: Array<GraphQLTypes["Event_Thumbnail_where_or"] | undefined> | undefined
};
	["Event_Banner_where"]: {
		alt?: GraphQLTypes["Event_Banner_alt_operator"] | undefined,
	updatedAt?: GraphQLTypes["Event_Banner_updatedAt_operator"] | undefined,
	createdAt?: GraphQLTypes["Event_Banner_createdAt_operator"] | undefined,
	url?: GraphQLTypes["Event_Banner_url_operator"] | undefined,
	filename?: GraphQLTypes["Event_Banner_filename_operator"] | undefined,
	mimeType?: GraphQLTypes["Event_Banner_mimeType_operator"] | undefined,
	filesize?: GraphQLTypes["Event_Banner_filesize_operator"] | undefined,
	width?: GraphQLTypes["Event_Banner_width_operator"] | undefined,
	height?: GraphQLTypes["Event_Banner_height_operator"] | undefined,
	focalX?: GraphQLTypes["Event_Banner_focalX_operator"] | undefined,
	focalY?: GraphQLTypes["Event_Banner_focalY_operator"] | undefined,
	sizes__small__url?: GraphQLTypes["Event_Banner_sizes__small__url_operator"] | undefined,
	sizes__small__width?: GraphQLTypes["Event_Banner_sizes__small__width_operator"] | undefined,
	sizes__small__height?: GraphQLTypes["Event_Banner_sizes__small__height_operator"] | undefined,
	sizes__small__mimeType?: GraphQLTypes["Event_Banner_sizes__small__mimeType_operator"] | undefined,
	sizes__small__filesize?: GraphQLTypes["Event_Banner_sizes__small__filesize_operator"] | undefined,
	sizes__small__filename?: GraphQLTypes["Event_Banner_sizes__small__filename_operator"] | undefined,
	sizes__medium__url?: GraphQLTypes["Event_Banner_sizes__medium__url_operator"] | undefined,
	sizes__medium__width?: GraphQLTypes["Event_Banner_sizes__medium__width_operator"] | undefined,
	sizes__medium__height?: GraphQLTypes["Event_Banner_sizes__medium__height_operator"] | undefined,
	sizes__medium__mimeType?: GraphQLTypes["Event_Banner_sizes__medium__mimeType_operator"] | undefined,
	sizes__medium__filesize?: GraphQLTypes["Event_Banner_sizes__medium__filesize_operator"] | undefined,
	sizes__medium__filename?: GraphQLTypes["Event_Banner_sizes__medium__filename_operator"] | undefined,
	sizes__large__url?: GraphQLTypes["Event_Banner_sizes__large__url_operator"] | undefined,
	sizes__large__width?: GraphQLTypes["Event_Banner_sizes__large__width_operator"] | undefined,
	sizes__large__height?: GraphQLTypes["Event_Banner_sizes__large__height_operator"] | undefined,
	sizes__large__mimeType?: GraphQLTypes["Event_Banner_sizes__large__mimeType_operator"] | undefined,
	sizes__large__filesize?: GraphQLTypes["Event_Banner_sizes__large__filesize_operator"] | undefined,
	sizes__large__filename?: GraphQLTypes["Event_Banner_sizes__large__filename_operator"] | undefined,
	sizes__small_jpeg__url?: GraphQLTypes["Event_Banner_sizes__small_jpeg__url_operator"] | undefined,
	sizes__small_jpeg__width?: GraphQLTypes["Event_Banner_sizes__small_jpeg__width_operator"] | undefined,
	sizes__small_jpeg__height?: GraphQLTypes["Event_Banner_sizes__small_jpeg__height_operator"] | undefined,
	sizes__small_jpeg__mimeType?: GraphQLTypes["Event_Banner_sizes__small_jpeg__mimeType_operator"] | undefined,
	sizes__small_jpeg__filesize?: GraphQLTypes["Event_Banner_sizes__small_jpeg__filesize_operator"] | undefined,
	sizes__small_jpeg__filename?: GraphQLTypes["Event_Banner_sizes__small_jpeg__filename_operator"] | undefined,
	sizes__medium_jpeg__url?: GraphQLTypes["Event_Banner_sizes__medium_jpeg__url_operator"] | undefined,
	sizes__medium_jpeg__width?: GraphQLTypes["Event_Banner_sizes__medium_jpeg__width_operator"] | undefined,
	sizes__medium_jpeg__height?: GraphQLTypes["Event_Banner_sizes__medium_jpeg__height_operator"] | undefined,
	sizes__medium_jpeg__mimeType?: GraphQLTypes["Event_Banner_sizes__medium_jpeg__mimeType_operator"] | undefined,
	sizes__medium_jpeg__filesize?: GraphQLTypes["Event_Banner_sizes__medium_jpeg__filesize_operator"] | undefined,
	sizes__medium_jpeg__filename?: GraphQLTypes["Event_Banner_sizes__medium_jpeg__filename_operator"] | undefined,
	sizes__large_jpeg__url?: GraphQLTypes["Event_Banner_sizes__large_jpeg__url_operator"] | undefined,
	sizes__large_jpeg__width?: GraphQLTypes["Event_Banner_sizes__large_jpeg__width_operator"] | undefined,
	sizes__large_jpeg__height?: GraphQLTypes["Event_Banner_sizes__large_jpeg__height_operator"] | undefined,
	sizes__large_jpeg__mimeType?: GraphQLTypes["Event_Banner_sizes__large_jpeg__mimeType_operator"] | undefined,
	sizes__large_jpeg__filesize?: GraphQLTypes["Event_Banner_sizes__large_jpeg__filesize_operator"] | undefined,
	sizes__large_jpeg__filename?: GraphQLTypes["Event_Banner_sizes__large_jpeg__filename_operator"] | undefined,
	id?: GraphQLTypes["Event_Banner_id_operator"] | undefined,
	AND?: Array<GraphQLTypes["Event_Banner_where_and"] | undefined> | undefined,
	OR?: Array<GraphQLTypes["Event_Banner_where_or"] | undefined> | undefined
};
	["Event_Banner_alt_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Event_Banner_updatedAt_operator"]: {
		equals?: GraphQLTypes["DateTime"] | undefined,
	not_equals?: GraphQLTypes["DateTime"] | undefined,
	greater_than_equal?: GraphQLTypes["DateTime"] | undefined,
	greater_than?: GraphQLTypes["DateTime"] | undefined,
	less_than_equal?: GraphQLTypes["DateTime"] | undefined,
	less_than?: GraphQLTypes["DateTime"] | undefined,
	like?: GraphQLTypes["DateTime"] | undefined,
	exists?: boolean | undefined
};
	["Event_Banner_createdAt_operator"]: {
		equals?: GraphQLTypes["DateTime"] | undefined,
	not_equals?: GraphQLTypes["DateTime"] | undefined,
	greater_than_equal?: GraphQLTypes["DateTime"] | undefined,
	greater_than?: GraphQLTypes["DateTime"] | undefined,
	less_than_equal?: GraphQLTypes["DateTime"] | undefined,
	less_than?: GraphQLTypes["DateTime"] | undefined,
	like?: GraphQLTypes["DateTime"] | undefined,
	exists?: boolean | undefined
};
	["Event_Banner_url_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Event_Banner_filename_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Event_Banner_mimeType_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Event_Banner_filesize_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Event_Banner_width_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Event_Banner_height_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Event_Banner_focalX_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Event_Banner_focalY_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Event_Banner_sizes__small__url_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Event_Banner_sizes__small__width_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Event_Banner_sizes__small__height_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Event_Banner_sizes__small__mimeType_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Event_Banner_sizes__small__filesize_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Event_Banner_sizes__small__filename_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Event_Banner_sizes__medium__url_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Event_Banner_sizes__medium__width_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Event_Banner_sizes__medium__height_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Event_Banner_sizes__medium__mimeType_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Event_Banner_sizes__medium__filesize_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Event_Banner_sizes__medium__filename_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Event_Banner_sizes__large__url_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Event_Banner_sizes__large__width_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Event_Banner_sizes__large__height_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Event_Banner_sizes__large__mimeType_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Event_Banner_sizes__large__filesize_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Event_Banner_sizes__large__filename_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Event_Banner_sizes__small_jpeg__url_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Event_Banner_sizes__small_jpeg__width_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Event_Banner_sizes__small_jpeg__height_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Event_Banner_sizes__small_jpeg__mimeType_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Event_Banner_sizes__small_jpeg__filesize_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Event_Banner_sizes__small_jpeg__filename_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Event_Banner_sizes__medium_jpeg__url_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Event_Banner_sizes__medium_jpeg__width_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Event_Banner_sizes__medium_jpeg__height_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Event_Banner_sizes__medium_jpeg__mimeType_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Event_Banner_sizes__medium_jpeg__filesize_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Event_Banner_sizes__medium_jpeg__filename_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Event_Banner_sizes__large_jpeg__url_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Event_Banner_sizes__large_jpeg__width_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Event_Banner_sizes__large_jpeg__height_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Event_Banner_sizes__large_jpeg__mimeType_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Event_Banner_sizes__large_jpeg__filesize_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Event_Banner_sizes__large_jpeg__filename_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Event_Banner_id_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Event_Banner_where_and"]: {
		alt?: GraphQLTypes["Event_Banner_alt_operator"] | undefined,
	updatedAt?: GraphQLTypes["Event_Banner_updatedAt_operator"] | undefined,
	createdAt?: GraphQLTypes["Event_Banner_createdAt_operator"] | undefined,
	url?: GraphQLTypes["Event_Banner_url_operator"] | undefined,
	filename?: GraphQLTypes["Event_Banner_filename_operator"] | undefined,
	mimeType?: GraphQLTypes["Event_Banner_mimeType_operator"] | undefined,
	filesize?: GraphQLTypes["Event_Banner_filesize_operator"] | undefined,
	width?: GraphQLTypes["Event_Banner_width_operator"] | undefined,
	height?: GraphQLTypes["Event_Banner_height_operator"] | undefined,
	focalX?: GraphQLTypes["Event_Banner_focalX_operator"] | undefined,
	focalY?: GraphQLTypes["Event_Banner_focalY_operator"] | undefined,
	sizes__small__url?: GraphQLTypes["Event_Banner_sizes__small__url_operator"] | undefined,
	sizes__small__width?: GraphQLTypes["Event_Banner_sizes__small__width_operator"] | undefined,
	sizes__small__height?: GraphQLTypes["Event_Banner_sizes__small__height_operator"] | undefined,
	sizes__small__mimeType?: GraphQLTypes["Event_Banner_sizes__small__mimeType_operator"] | undefined,
	sizes__small__filesize?: GraphQLTypes["Event_Banner_sizes__small__filesize_operator"] | undefined,
	sizes__small__filename?: GraphQLTypes["Event_Banner_sizes__small__filename_operator"] | undefined,
	sizes__medium__url?: GraphQLTypes["Event_Banner_sizes__medium__url_operator"] | undefined,
	sizes__medium__width?: GraphQLTypes["Event_Banner_sizes__medium__width_operator"] | undefined,
	sizes__medium__height?: GraphQLTypes["Event_Banner_sizes__medium__height_operator"] | undefined,
	sizes__medium__mimeType?: GraphQLTypes["Event_Banner_sizes__medium__mimeType_operator"] | undefined,
	sizes__medium__filesize?: GraphQLTypes["Event_Banner_sizes__medium__filesize_operator"] | undefined,
	sizes__medium__filename?: GraphQLTypes["Event_Banner_sizes__medium__filename_operator"] | undefined,
	sizes__large__url?: GraphQLTypes["Event_Banner_sizes__large__url_operator"] | undefined,
	sizes__large__width?: GraphQLTypes["Event_Banner_sizes__large__width_operator"] | undefined,
	sizes__large__height?: GraphQLTypes["Event_Banner_sizes__large__height_operator"] | undefined,
	sizes__large__mimeType?: GraphQLTypes["Event_Banner_sizes__large__mimeType_operator"] | undefined,
	sizes__large__filesize?: GraphQLTypes["Event_Banner_sizes__large__filesize_operator"] | undefined,
	sizes__large__filename?: GraphQLTypes["Event_Banner_sizes__large__filename_operator"] | undefined,
	sizes__small_jpeg__url?: GraphQLTypes["Event_Banner_sizes__small_jpeg__url_operator"] | undefined,
	sizes__small_jpeg__width?: GraphQLTypes["Event_Banner_sizes__small_jpeg__width_operator"] | undefined,
	sizes__small_jpeg__height?: GraphQLTypes["Event_Banner_sizes__small_jpeg__height_operator"] | undefined,
	sizes__small_jpeg__mimeType?: GraphQLTypes["Event_Banner_sizes__small_jpeg__mimeType_operator"] | undefined,
	sizes__small_jpeg__filesize?: GraphQLTypes["Event_Banner_sizes__small_jpeg__filesize_operator"] | undefined,
	sizes__small_jpeg__filename?: GraphQLTypes["Event_Banner_sizes__small_jpeg__filename_operator"] | undefined,
	sizes__medium_jpeg__url?: GraphQLTypes["Event_Banner_sizes__medium_jpeg__url_operator"] | undefined,
	sizes__medium_jpeg__width?: GraphQLTypes["Event_Banner_sizes__medium_jpeg__width_operator"] | undefined,
	sizes__medium_jpeg__height?: GraphQLTypes["Event_Banner_sizes__medium_jpeg__height_operator"] | undefined,
	sizes__medium_jpeg__mimeType?: GraphQLTypes["Event_Banner_sizes__medium_jpeg__mimeType_operator"] | undefined,
	sizes__medium_jpeg__filesize?: GraphQLTypes["Event_Banner_sizes__medium_jpeg__filesize_operator"] | undefined,
	sizes__medium_jpeg__filename?: GraphQLTypes["Event_Banner_sizes__medium_jpeg__filename_operator"] | undefined,
	sizes__large_jpeg__url?: GraphQLTypes["Event_Banner_sizes__large_jpeg__url_operator"] | undefined,
	sizes__large_jpeg__width?: GraphQLTypes["Event_Banner_sizes__large_jpeg__width_operator"] | undefined,
	sizes__large_jpeg__height?: GraphQLTypes["Event_Banner_sizes__large_jpeg__height_operator"] | undefined,
	sizes__large_jpeg__mimeType?: GraphQLTypes["Event_Banner_sizes__large_jpeg__mimeType_operator"] | undefined,
	sizes__large_jpeg__filesize?: GraphQLTypes["Event_Banner_sizes__large_jpeg__filesize_operator"] | undefined,
	sizes__large_jpeg__filename?: GraphQLTypes["Event_Banner_sizes__large_jpeg__filename_operator"] | undefined,
	id?: GraphQLTypes["Event_Banner_id_operator"] | undefined,
	AND?: Array<GraphQLTypes["Event_Banner_where_and"] | undefined> | undefined,
	OR?: Array<GraphQLTypes["Event_Banner_where_or"] | undefined> | undefined
};
	["Event_Banner_where_or"]: {
		alt?: GraphQLTypes["Event_Banner_alt_operator"] | undefined,
	updatedAt?: GraphQLTypes["Event_Banner_updatedAt_operator"] | undefined,
	createdAt?: GraphQLTypes["Event_Banner_createdAt_operator"] | undefined,
	url?: GraphQLTypes["Event_Banner_url_operator"] | undefined,
	filename?: GraphQLTypes["Event_Banner_filename_operator"] | undefined,
	mimeType?: GraphQLTypes["Event_Banner_mimeType_operator"] | undefined,
	filesize?: GraphQLTypes["Event_Banner_filesize_operator"] | undefined,
	width?: GraphQLTypes["Event_Banner_width_operator"] | undefined,
	height?: GraphQLTypes["Event_Banner_height_operator"] | undefined,
	focalX?: GraphQLTypes["Event_Banner_focalX_operator"] | undefined,
	focalY?: GraphQLTypes["Event_Banner_focalY_operator"] | undefined,
	sizes__small__url?: GraphQLTypes["Event_Banner_sizes__small__url_operator"] | undefined,
	sizes__small__width?: GraphQLTypes["Event_Banner_sizes__small__width_operator"] | undefined,
	sizes__small__height?: GraphQLTypes["Event_Banner_sizes__small__height_operator"] | undefined,
	sizes__small__mimeType?: GraphQLTypes["Event_Banner_sizes__small__mimeType_operator"] | undefined,
	sizes__small__filesize?: GraphQLTypes["Event_Banner_sizes__small__filesize_operator"] | undefined,
	sizes__small__filename?: GraphQLTypes["Event_Banner_sizes__small__filename_operator"] | undefined,
	sizes__medium__url?: GraphQLTypes["Event_Banner_sizes__medium__url_operator"] | undefined,
	sizes__medium__width?: GraphQLTypes["Event_Banner_sizes__medium__width_operator"] | undefined,
	sizes__medium__height?: GraphQLTypes["Event_Banner_sizes__medium__height_operator"] | undefined,
	sizes__medium__mimeType?: GraphQLTypes["Event_Banner_sizes__medium__mimeType_operator"] | undefined,
	sizes__medium__filesize?: GraphQLTypes["Event_Banner_sizes__medium__filesize_operator"] | undefined,
	sizes__medium__filename?: GraphQLTypes["Event_Banner_sizes__medium__filename_operator"] | undefined,
	sizes__large__url?: GraphQLTypes["Event_Banner_sizes__large__url_operator"] | undefined,
	sizes__large__width?: GraphQLTypes["Event_Banner_sizes__large__width_operator"] | undefined,
	sizes__large__height?: GraphQLTypes["Event_Banner_sizes__large__height_operator"] | undefined,
	sizes__large__mimeType?: GraphQLTypes["Event_Banner_sizes__large__mimeType_operator"] | undefined,
	sizes__large__filesize?: GraphQLTypes["Event_Banner_sizes__large__filesize_operator"] | undefined,
	sizes__large__filename?: GraphQLTypes["Event_Banner_sizes__large__filename_operator"] | undefined,
	sizes__small_jpeg__url?: GraphQLTypes["Event_Banner_sizes__small_jpeg__url_operator"] | undefined,
	sizes__small_jpeg__width?: GraphQLTypes["Event_Banner_sizes__small_jpeg__width_operator"] | undefined,
	sizes__small_jpeg__height?: GraphQLTypes["Event_Banner_sizes__small_jpeg__height_operator"] | undefined,
	sizes__small_jpeg__mimeType?: GraphQLTypes["Event_Banner_sizes__small_jpeg__mimeType_operator"] | undefined,
	sizes__small_jpeg__filesize?: GraphQLTypes["Event_Banner_sizes__small_jpeg__filesize_operator"] | undefined,
	sizes__small_jpeg__filename?: GraphQLTypes["Event_Banner_sizes__small_jpeg__filename_operator"] | undefined,
	sizes__medium_jpeg__url?: GraphQLTypes["Event_Banner_sizes__medium_jpeg__url_operator"] | undefined,
	sizes__medium_jpeg__width?: GraphQLTypes["Event_Banner_sizes__medium_jpeg__width_operator"] | undefined,
	sizes__medium_jpeg__height?: GraphQLTypes["Event_Banner_sizes__medium_jpeg__height_operator"] | undefined,
	sizes__medium_jpeg__mimeType?: GraphQLTypes["Event_Banner_sizes__medium_jpeg__mimeType_operator"] | undefined,
	sizes__medium_jpeg__filesize?: GraphQLTypes["Event_Banner_sizes__medium_jpeg__filesize_operator"] | undefined,
	sizes__medium_jpeg__filename?: GraphQLTypes["Event_Banner_sizes__medium_jpeg__filename_operator"] | undefined,
	sizes__large_jpeg__url?: GraphQLTypes["Event_Banner_sizes__large_jpeg__url_operator"] | undefined,
	sizes__large_jpeg__width?: GraphQLTypes["Event_Banner_sizes__large_jpeg__width_operator"] | undefined,
	sizes__large_jpeg__height?: GraphQLTypes["Event_Banner_sizes__large_jpeg__height_operator"] | undefined,
	sizes__large_jpeg__mimeType?: GraphQLTypes["Event_Banner_sizes__large_jpeg__mimeType_operator"] | undefined,
	sizes__large_jpeg__filesize?: GraphQLTypes["Event_Banner_sizes__large_jpeg__filesize_operator"] | undefined,
	sizes__large_jpeg__filename?: GraphQLTypes["Event_Banner_sizes__large_jpeg__filename_operator"] | undefined,
	id?: GraphQLTypes["Event_Banner_id_operator"] | undefined,
	AND?: Array<GraphQLTypes["Event_Banner_where_and"] | undefined> | undefined,
	OR?: Array<GraphQLTypes["Event_Banner_where_or"] | undefined> | undefined
};
	["Event_MobileBanner_where"]: {
		alt?: GraphQLTypes["Event_MobileBanner_alt_operator"] | undefined,
	updatedAt?: GraphQLTypes["Event_MobileBanner_updatedAt_operator"] | undefined,
	createdAt?: GraphQLTypes["Event_MobileBanner_createdAt_operator"] | undefined,
	url?: GraphQLTypes["Event_MobileBanner_url_operator"] | undefined,
	filename?: GraphQLTypes["Event_MobileBanner_filename_operator"] | undefined,
	mimeType?: GraphQLTypes["Event_MobileBanner_mimeType_operator"] | undefined,
	filesize?: GraphQLTypes["Event_MobileBanner_filesize_operator"] | undefined,
	width?: GraphQLTypes["Event_MobileBanner_width_operator"] | undefined,
	height?: GraphQLTypes["Event_MobileBanner_height_operator"] | undefined,
	focalX?: GraphQLTypes["Event_MobileBanner_focalX_operator"] | undefined,
	focalY?: GraphQLTypes["Event_MobileBanner_focalY_operator"] | undefined,
	sizes__small__url?: GraphQLTypes["Event_MobileBanner_sizes__small__url_operator"] | undefined,
	sizes__small__width?: GraphQLTypes["Event_MobileBanner_sizes__small__width_operator"] | undefined,
	sizes__small__height?: GraphQLTypes["Event_MobileBanner_sizes__small__height_operator"] | undefined,
	sizes__small__mimeType?: GraphQLTypes["Event_MobileBanner_sizes__small__mimeType_operator"] | undefined,
	sizes__small__filesize?: GraphQLTypes["Event_MobileBanner_sizes__small__filesize_operator"] | undefined,
	sizes__small__filename?: GraphQLTypes["Event_MobileBanner_sizes__small__filename_operator"] | undefined,
	sizes__medium__url?: GraphQLTypes["Event_MobileBanner_sizes__medium__url_operator"] | undefined,
	sizes__medium__width?: GraphQLTypes["Event_MobileBanner_sizes__medium__width_operator"] | undefined,
	sizes__medium__height?: GraphQLTypes["Event_MobileBanner_sizes__medium__height_operator"] | undefined,
	sizes__medium__mimeType?: GraphQLTypes["Event_MobileBanner_sizes__medium__mimeType_operator"] | undefined,
	sizes__medium__filesize?: GraphQLTypes["Event_MobileBanner_sizes__medium__filesize_operator"] | undefined,
	sizes__medium__filename?: GraphQLTypes["Event_MobileBanner_sizes__medium__filename_operator"] | undefined,
	sizes__large__url?: GraphQLTypes["Event_MobileBanner_sizes__large__url_operator"] | undefined,
	sizes__large__width?: GraphQLTypes["Event_MobileBanner_sizes__large__width_operator"] | undefined,
	sizes__large__height?: GraphQLTypes["Event_MobileBanner_sizes__large__height_operator"] | undefined,
	sizes__large__mimeType?: GraphQLTypes["Event_MobileBanner_sizes__large__mimeType_operator"] | undefined,
	sizes__large__filesize?: GraphQLTypes["Event_MobileBanner_sizes__large__filesize_operator"] | undefined,
	sizes__large__filename?: GraphQLTypes["Event_MobileBanner_sizes__large__filename_operator"] | undefined,
	sizes__small_jpeg__url?: GraphQLTypes["Event_MobileBanner_sizes__small_jpeg__url_operator"] | undefined,
	sizes__small_jpeg__width?: GraphQLTypes["Event_MobileBanner_sizes__small_jpeg__width_operator"] | undefined,
	sizes__small_jpeg__height?: GraphQLTypes["Event_MobileBanner_sizes__small_jpeg__height_operator"] | undefined,
	sizes__small_jpeg__mimeType?: GraphQLTypes["Event_MobileBanner_sizes__small_jpeg__mimeType_operator"] | undefined,
	sizes__small_jpeg__filesize?: GraphQLTypes["Event_MobileBanner_sizes__small_jpeg__filesize_operator"] | undefined,
	sizes__small_jpeg__filename?: GraphQLTypes["Event_MobileBanner_sizes__small_jpeg__filename_operator"] | undefined,
	sizes__medium_jpeg__url?: GraphQLTypes["Event_MobileBanner_sizes__medium_jpeg__url_operator"] | undefined,
	sizes__medium_jpeg__width?: GraphQLTypes["Event_MobileBanner_sizes__medium_jpeg__width_operator"] | undefined,
	sizes__medium_jpeg__height?: GraphQLTypes["Event_MobileBanner_sizes__medium_jpeg__height_operator"] | undefined,
	sizes__medium_jpeg__mimeType?: GraphQLTypes["Event_MobileBanner_sizes__medium_jpeg__mimeType_operator"] | undefined,
	sizes__medium_jpeg__filesize?: GraphQLTypes["Event_MobileBanner_sizes__medium_jpeg__filesize_operator"] | undefined,
	sizes__medium_jpeg__filename?: GraphQLTypes["Event_MobileBanner_sizes__medium_jpeg__filename_operator"] | undefined,
	sizes__large_jpeg__url?: GraphQLTypes["Event_MobileBanner_sizes__large_jpeg__url_operator"] | undefined,
	sizes__large_jpeg__width?: GraphQLTypes["Event_MobileBanner_sizes__large_jpeg__width_operator"] | undefined,
	sizes__large_jpeg__height?: GraphQLTypes["Event_MobileBanner_sizes__large_jpeg__height_operator"] | undefined,
	sizes__large_jpeg__mimeType?: GraphQLTypes["Event_MobileBanner_sizes__large_jpeg__mimeType_operator"] | undefined,
	sizes__large_jpeg__filesize?: GraphQLTypes["Event_MobileBanner_sizes__large_jpeg__filesize_operator"] | undefined,
	sizes__large_jpeg__filename?: GraphQLTypes["Event_MobileBanner_sizes__large_jpeg__filename_operator"] | undefined,
	id?: GraphQLTypes["Event_MobileBanner_id_operator"] | undefined,
	AND?: Array<GraphQLTypes["Event_MobileBanner_where_and"] | undefined> | undefined,
	OR?: Array<GraphQLTypes["Event_MobileBanner_where_or"] | undefined> | undefined
};
	["Event_MobileBanner_alt_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Event_MobileBanner_updatedAt_operator"]: {
		equals?: GraphQLTypes["DateTime"] | undefined,
	not_equals?: GraphQLTypes["DateTime"] | undefined,
	greater_than_equal?: GraphQLTypes["DateTime"] | undefined,
	greater_than?: GraphQLTypes["DateTime"] | undefined,
	less_than_equal?: GraphQLTypes["DateTime"] | undefined,
	less_than?: GraphQLTypes["DateTime"] | undefined,
	like?: GraphQLTypes["DateTime"] | undefined,
	exists?: boolean | undefined
};
	["Event_MobileBanner_createdAt_operator"]: {
		equals?: GraphQLTypes["DateTime"] | undefined,
	not_equals?: GraphQLTypes["DateTime"] | undefined,
	greater_than_equal?: GraphQLTypes["DateTime"] | undefined,
	greater_than?: GraphQLTypes["DateTime"] | undefined,
	less_than_equal?: GraphQLTypes["DateTime"] | undefined,
	less_than?: GraphQLTypes["DateTime"] | undefined,
	like?: GraphQLTypes["DateTime"] | undefined,
	exists?: boolean | undefined
};
	["Event_MobileBanner_url_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Event_MobileBanner_filename_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Event_MobileBanner_mimeType_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Event_MobileBanner_filesize_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Event_MobileBanner_width_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Event_MobileBanner_height_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Event_MobileBanner_focalX_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Event_MobileBanner_focalY_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Event_MobileBanner_sizes__small__url_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Event_MobileBanner_sizes__small__width_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Event_MobileBanner_sizes__small__height_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Event_MobileBanner_sizes__small__mimeType_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Event_MobileBanner_sizes__small__filesize_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Event_MobileBanner_sizes__small__filename_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Event_MobileBanner_sizes__medium__url_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Event_MobileBanner_sizes__medium__width_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Event_MobileBanner_sizes__medium__height_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Event_MobileBanner_sizes__medium__mimeType_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Event_MobileBanner_sizes__medium__filesize_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Event_MobileBanner_sizes__medium__filename_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Event_MobileBanner_sizes__large__url_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Event_MobileBanner_sizes__large__width_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Event_MobileBanner_sizes__large__height_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Event_MobileBanner_sizes__large__mimeType_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Event_MobileBanner_sizes__large__filesize_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Event_MobileBanner_sizes__large__filename_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Event_MobileBanner_sizes__small_jpeg__url_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Event_MobileBanner_sizes__small_jpeg__width_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Event_MobileBanner_sizes__small_jpeg__height_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Event_MobileBanner_sizes__small_jpeg__mimeType_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Event_MobileBanner_sizes__small_jpeg__filesize_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Event_MobileBanner_sizes__small_jpeg__filename_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Event_MobileBanner_sizes__medium_jpeg__url_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Event_MobileBanner_sizes__medium_jpeg__width_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Event_MobileBanner_sizes__medium_jpeg__height_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Event_MobileBanner_sizes__medium_jpeg__mimeType_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Event_MobileBanner_sizes__medium_jpeg__filesize_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Event_MobileBanner_sizes__medium_jpeg__filename_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Event_MobileBanner_sizes__large_jpeg__url_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Event_MobileBanner_sizes__large_jpeg__width_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Event_MobileBanner_sizes__large_jpeg__height_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Event_MobileBanner_sizes__large_jpeg__mimeType_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Event_MobileBanner_sizes__large_jpeg__filesize_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Event_MobileBanner_sizes__large_jpeg__filename_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Event_MobileBanner_id_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Event_MobileBanner_where_and"]: {
		alt?: GraphQLTypes["Event_MobileBanner_alt_operator"] | undefined,
	updatedAt?: GraphQLTypes["Event_MobileBanner_updatedAt_operator"] | undefined,
	createdAt?: GraphQLTypes["Event_MobileBanner_createdAt_operator"] | undefined,
	url?: GraphQLTypes["Event_MobileBanner_url_operator"] | undefined,
	filename?: GraphQLTypes["Event_MobileBanner_filename_operator"] | undefined,
	mimeType?: GraphQLTypes["Event_MobileBanner_mimeType_operator"] | undefined,
	filesize?: GraphQLTypes["Event_MobileBanner_filesize_operator"] | undefined,
	width?: GraphQLTypes["Event_MobileBanner_width_operator"] | undefined,
	height?: GraphQLTypes["Event_MobileBanner_height_operator"] | undefined,
	focalX?: GraphQLTypes["Event_MobileBanner_focalX_operator"] | undefined,
	focalY?: GraphQLTypes["Event_MobileBanner_focalY_operator"] | undefined,
	sizes__small__url?: GraphQLTypes["Event_MobileBanner_sizes__small__url_operator"] | undefined,
	sizes__small__width?: GraphQLTypes["Event_MobileBanner_sizes__small__width_operator"] | undefined,
	sizes__small__height?: GraphQLTypes["Event_MobileBanner_sizes__small__height_operator"] | undefined,
	sizes__small__mimeType?: GraphQLTypes["Event_MobileBanner_sizes__small__mimeType_operator"] | undefined,
	sizes__small__filesize?: GraphQLTypes["Event_MobileBanner_sizes__small__filesize_operator"] | undefined,
	sizes__small__filename?: GraphQLTypes["Event_MobileBanner_sizes__small__filename_operator"] | undefined,
	sizes__medium__url?: GraphQLTypes["Event_MobileBanner_sizes__medium__url_operator"] | undefined,
	sizes__medium__width?: GraphQLTypes["Event_MobileBanner_sizes__medium__width_operator"] | undefined,
	sizes__medium__height?: GraphQLTypes["Event_MobileBanner_sizes__medium__height_operator"] | undefined,
	sizes__medium__mimeType?: GraphQLTypes["Event_MobileBanner_sizes__medium__mimeType_operator"] | undefined,
	sizes__medium__filesize?: GraphQLTypes["Event_MobileBanner_sizes__medium__filesize_operator"] | undefined,
	sizes__medium__filename?: GraphQLTypes["Event_MobileBanner_sizes__medium__filename_operator"] | undefined,
	sizes__large__url?: GraphQLTypes["Event_MobileBanner_sizes__large__url_operator"] | undefined,
	sizes__large__width?: GraphQLTypes["Event_MobileBanner_sizes__large__width_operator"] | undefined,
	sizes__large__height?: GraphQLTypes["Event_MobileBanner_sizes__large__height_operator"] | undefined,
	sizes__large__mimeType?: GraphQLTypes["Event_MobileBanner_sizes__large__mimeType_operator"] | undefined,
	sizes__large__filesize?: GraphQLTypes["Event_MobileBanner_sizes__large__filesize_operator"] | undefined,
	sizes__large__filename?: GraphQLTypes["Event_MobileBanner_sizes__large__filename_operator"] | undefined,
	sizes__small_jpeg__url?: GraphQLTypes["Event_MobileBanner_sizes__small_jpeg__url_operator"] | undefined,
	sizes__small_jpeg__width?: GraphQLTypes["Event_MobileBanner_sizes__small_jpeg__width_operator"] | undefined,
	sizes__small_jpeg__height?: GraphQLTypes["Event_MobileBanner_sizes__small_jpeg__height_operator"] | undefined,
	sizes__small_jpeg__mimeType?: GraphQLTypes["Event_MobileBanner_sizes__small_jpeg__mimeType_operator"] | undefined,
	sizes__small_jpeg__filesize?: GraphQLTypes["Event_MobileBanner_sizes__small_jpeg__filesize_operator"] | undefined,
	sizes__small_jpeg__filename?: GraphQLTypes["Event_MobileBanner_sizes__small_jpeg__filename_operator"] | undefined,
	sizes__medium_jpeg__url?: GraphQLTypes["Event_MobileBanner_sizes__medium_jpeg__url_operator"] | undefined,
	sizes__medium_jpeg__width?: GraphQLTypes["Event_MobileBanner_sizes__medium_jpeg__width_operator"] | undefined,
	sizes__medium_jpeg__height?: GraphQLTypes["Event_MobileBanner_sizes__medium_jpeg__height_operator"] | undefined,
	sizes__medium_jpeg__mimeType?: GraphQLTypes["Event_MobileBanner_sizes__medium_jpeg__mimeType_operator"] | undefined,
	sizes__medium_jpeg__filesize?: GraphQLTypes["Event_MobileBanner_sizes__medium_jpeg__filesize_operator"] | undefined,
	sizes__medium_jpeg__filename?: GraphQLTypes["Event_MobileBanner_sizes__medium_jpeg__filename_operator"] | undefined,
	sizes__large_jpeg__url?: GraphQLTypes["Event_MobileBanner_sizes__large_jpeg__url_operator"] | undefined,
	sizes__large_jpeg__width?: GraphQLTypes["Event_MobileBanner_sizes__large_jpeg__width_operator"] | undefined,
	sizes__large_jpeg__height?: GraphQLTypes["Event_MobileBanner_sizes__large_jpeg__height_operator"] | undefined,
	sizes__large_jpeg__mimeType?: GraphQLTypes["Event_MobileBanner_sizes__large_jpeg__mimeType_operator"] | undefined,
	sizes__large_jpeg__filesize?: GraphQLTypes["Event_MobileBanner_sizes__large_jpeg__filesize_operator"] | undefined,
	sizes__large_jpeg__filename?: GraphQLTypes["Event_MobileBanner_sizes__large_jpeg__filename_operator"] | undefined,
	id?: GraphQLTypes["Event_MobileBanner_id_operator"] | undefined,
	AND?: Array<GraphQLTypes["Event_MobileBanner_where_and"] | undefined> | undefined,
	OR?: Array<GraphQLTypes["Event_MobileBanner_where_or"] | undefined> | undefined
};
	["Event_MobileBanner_where_or"]: {
		alt?: GraphQLTypes["Event_MobileBanner_alt_operator"] | undefined,
	updatedAt?: GraphQLTypes["Event_MobileBanner_updatedAt_operator"] | undefined,
	createdAt?: GraphQLTypes["Event_MobileBanner_createdAt_operator"] | undefined,
	url?: GraphQLTypes["Event_MobileBanner_url_operator"] | undefined,
	filename?: GraphQLTypes["Event_MobileBanner_filename_operator"] | undefined,
	mimeType?: GraphQLTypes["Event_MobileBanner_mimeType_operator"] | undefined,
	filesize?: GraphQLTypes["Event_MobileBanner_filesize_operator"] | undefined,
	width?: GraphQLTypes["Event_MobileBanner_width_operator"] | undefined,
	height?: GraphQLTypes["Event_MobileBanner_height_operator"] | undefined,
	focalX?: GraphQLTypes["Event_MobileBanner_focalX_operator"] | undefined,
	focalY?: GraphQLTypes["Event_MobileBanner_focalY_operator"] | undefined,
	sizes__small__url?: GraphQLTypes["Event_MobileBanner_sizes__small__url_operator"] | undefined,
	sizes__small__width?: GraphQLTypes["Event_MobileBanner_sizes__small__width_operator"] | undefined,
	sizes__small__height?: GraphQLTypes["Event_MobileBanner_sizes__small__height_operator"] | undefined,
	sizes__small__mimeType?: GraphQLTypes["Event_MobileBanner_sizes__small__mimeType_operator"] | undefined,
	sizes__small__filesize?: GraphQLTypes["Event_MobileBanner_sizes__small__filesize_operator"] | undefined,
	sizes__small__filename?: GraphQLTypes["Event_MobileBanner_sizes__small__filename_operator"] | undefined,
	sizes__medium__url?: GraphQLTypes["Event_MobileBanner_sizes__medium__url_operator"] | undefined,
	sizes__medium__width?: GraphQLTypes["Event_MobileBanner_sizes__medium__width_operator"] | undefined,
	sizes__medium__height?: GraphQLTypes["Event_MobileBanner_sizes__medium__height_operator"] | undefined,
	sizes__medium__mimeType?: GraphQLTypes["Event_MobileBanner_sizes__medium__mimeType_operator"] | undefined,
	sizes__medium__filesize?: GraphQLTypes["Event_MobileBanner_sizes__medium__filesize_operator"] | undefined,
	sizes__medium__filename?: GraphQLTypes["Event_MobileBanner_sizes__medium__filename_operator"] | undefined,
	sizes__large__url?: GraphQLTypes["Event_MobileBanner_sizes__large__url_operator"] | undefined,
	sizes__large__width?: GraphQLTypes["Event_MobileBanner_sizes__large__width_operator"] | undefined,
	sizes__large__height?: GraphQLTypes["Event_MobileBanner_sizes__large__height_operator"] | undefined,
	sizes__large__mimeType?: GraphQLTypes["Event_MobileBanner_sizes__large__mimeType_operator"] | undefined,
	sizes__large__filesize?: GraphQLTypes["Event_MobileBanner_sizes__large__filesize_operator"] | undefined,
	sizes__large__filename?: GraphQLTypes["Event_MobileBanner_sizes__large__filename_operator"] | undefined,
	sizes__small_jpeg__url?: GraphQLTypes["Event_MobileBanner_sizes__small_jpeg__url_operator"] | undefined,
	sizes__small_jpeg__width?: GraphQLTypes["Event_MobileBanner_sizes__small_jpeg__width_operator"] | undefined,
	sizes__small_jpeg__height?: GraphQLTypes["Event_MobileBanner_sizes__small_jpeg__height_operator"] | undefined,
	sizes__small_jpeg__mimeType?: GraphQLTypes["Event_MobileBanner_sizes__small_jpeg__mimeType_operator"] | undefined,
	sizes__small_jpeg__filesize?: GraphQLTypes["Event_MobileBanner_sizes__small_jpeg__filesize_operator"] | undefined,
	sizes__small_jpeg__filename?: GraphQLTypes["Event_MobileBanner_sizes__small_jpeg__filename_operator"] | undefined,
	sizes__medium_jpeg__url?: GraphQLTypes["Event_MobileBanner_sizes__medium_jpeg__url_operator"] | undefined,
	sizes__medium_jpeg__width?: GraphQLTypes["Event_MobileBanner_sizes__medium_jpeg__width_operator"] | undefined,
	sizes__medium_jpeg__height?: GraphQLTypes["Event_MobileBanner_sizes__medium_jpeg__height_operator"] | undefined,
	sizes__medium_jpeg__mimeType?: GraphQLTypes["Event_MobileBanner_sizes__medium_jpeg__mimeType_operator"] | undefined,
	sizes__medium_jpeg__filesize?: GraphQLTypes["Event_MobileBanner_sizes__medium_jpeg__filesize_operator"] | undefined,
	sizes__medium_jpeg__filename?: GraphQLTypes["Event_MobileBanner_sizes__medium_jpeg__filename_operator"] | undefined,
	sizes__large_jpeg__url?: GraphQLTypes["Event_MobileBanner_sizes__large_jpeg__url_operator"] | undefined,
	sizes__large_jpeg__width?: GraphQLTypes["Event_MobileBanner_sizes__large_jpeg__width_operator"] | undefined,
	sizes__large_jpeg__height?: GraphQLTypes["Event_MobileBanner_sizes__large_jpeg__height_operator"] | undefined,
	sizes__large_jpeg__mimeType?: GraphQLTypes["Event_MobileBanner_sizes__large_jpeg__mimeType_operator"] | undefined,
	sizes__large_jpeg__filesize?: GraphQLTypes["Event_MobileBanner_sizes__large_jpeg__filesize_operator"] | undefined,
	sizes__large_jpeg__filename?: GraphQLTypes["Event_MobileBanner_sizes__large_jpeg__filename_operator"] | undefined,
	id?: GraphQLTypes["Event_MobileBanner_id_operator"] | undefined,
	AND?: Array<GraphQLTypes["Event_MobileBanner_where_and"] | undefined> | undefined,
	OR?: Array<GraphQLTypes["Event_MobileBanner_where_or"] | undefined> | undefined
};
	["Event_Sections"]: {
	__typename: "Event_Sections",
	startDatetime?: GraphQLTypes["DateTime"] | undefined,
	endDatetime?: GraphQLTypes["DateTime"] | undefined,
	fullDay?: boolean | undefined,
	repeat?: boolean | undefined,
	toThisDay?: GraphQLTypes["DateTime"] | undefined,
	recurrance?: GraphQLTypes["Event_Sections_Recurrance"] | undefined,
	sameLocation?: boolean | undefined,
	location?: GraphQLTypes["Location"] | undefined,
	district?: GraphQLTypes["District"] | undefined,
	coordinate?: Array<number> | undefined,
	linkAddress?: boolean | undefined,
	address?: string | undefined,
	addressCoordinate?: Array<number> | undefined,
	id?: string | undefined
};
	["Event_Sections_Recurrance"]: {
	__typename: "Event_Sections_Recurrance",
	type?: GraphQLTypes["Event_Sections_Recurrance_type"] | undefined,
	weekday?: Array<GraphQLTypes["Event_Sections_Recurrance_weekday"]> | undefined
};
	["Event_Sections_Recurrance_type"]: Event_Sections_Recurrance_type;
	["Event_Sections_Recurrance_weekday"]: Event_Sections_Recurrance_weekday;
	["Location"]: {
	__typename: "Location",
	id?: string | undefined,
	region?: GraphQLTypes["Location_region"] | undefined,
	creator?: GraphQLTypes["User"] | undefined,
	permalink?: string | undefined,
	status: string,
	level?: GraphQLTypes["Location_level"] | undefined,
	parent?: Array<GraphQLTypes["Location"]> | undefined,
	name?: string | undefined,
	thumbnail?: GraphQLTypes["Media"] | undefined,
	banner?: GraphQLTypes["Media"] | undefined,
	type: GraphQLTypes["Location_type"],
	category: GraphQLTypes["Location_category"],
	district?: GraphQLTypes["District"] | undefined,
	coordinate?: Array<number> | undefined,
	linkAddress?: boolean | undefined,
	address?: string | undefined,
	addressCoordinate?: Array<number> | undefined,
	permanant?: boolean | undefined,
	Details?: GraphQLTypes["Location_Details"] | undefined,
	content?: Array<GraphQLTypes["Location_Content"]> | undefined,
	legacyContent?: string | undefined,
	showLegacyContent?: boolean | undefined,
	criteria?: GraphQLTypes["Location_Criteria"] | undefined,
	sections?: Array<GraphQLTypes["Location_Sections"]> | undefined,
	views?: number | undefined,
	_title?: string | undefined,
	legacyGallery?: Array<GraphQLTypes["Media"]> | undefined,
	updatedAt?: GraphQLTypes["DateTime"] | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined
};
	["Location_region"]: Location_region;
	["Location_level"]: Location_level;
	["Location_Thumbnail_where"]: {
		alt?: GraphQLTypes["Location_Thumbnail_alt_operator"] | undefined,
	updatedAt?: GraphQLTypes["Location_Thumbnail_updatedAt_operator"] | undefined,
	createdAt?: GraphQLTypes["Location_Thumbnail_createdAt_operator"] | undefined,
	url?: GraphQLTypes["Location_Thumbnail_url_operator"] | undefined,
	filename?: GraphQLTypes["Location_Thumbnail_filename_operator"] | undefined,
	mimeType?: GraphQLTypes["Location_Thumbnail_mimeType_operator"] | undefined,
	filesize?: GraphQLTypes["Location_Thumbnail_filesize_operator"] | undefined,
	width?: GraphQLTypes["Location_Thumbnail_width_operator"] | undefined,
	height?: GraphQLTypes["Location_Thumbnail_height_operator"] | undefined,
	focalX?: GraphQLTypes["Location_Thumbnail_focalX_operator"] | undefined,
	focalY?: GraphQLTypes["Location_Thumbnail_focalY_operator"] | undefined,
	sizes__small__url?: GraphQLTypes["Location_Thumbnail_sizes__small__url_operator"] | undefined,
	sizes__small__width?: GraphQLTypes["Location_Thumbnail_sizes__small__width_operator"] | undefined,
	sizes__small__height?: GraphQLTypes["Location_Thumbnail_sizes__small__height_operator"] | undefined,
	sizes__small__mimeType?: GraphQLTypes["Location_Thumbnail_sizes__small__mimeType_operator"] | undefined,
	sizes__small__filesize?: GraphQLTypes["Location_Thumbnail_sizes__small__filesize_operator"] | undefined,
	sizes__small__filename?: GraphQLTypes["Location_Thumbnail_sizes__small__filename_operator"] | undefined,
	sizes__medium__url?: GraphQLTypes["Location_Thumbnail_sizes__medium__url_operator"] | undefined,
	sizes__medium__width?: GraphQLTypes["Location_Thumbnail_sizes__medium__width_operator"] | undefined,
	sizes__medium__height?: GraphQLTypes["Location_Thumbnail_sizes__medium__height_operator"] | undefined,
	sizes__medium__mimeType?: GraphQLTypes["Location_Thumbnail_sizes__medium__mimeType_operator"] | undefined,
	sizes__medium__filesize?: GraphQLTypes["Location_Thumbnail_sizes__medium__filesize_operator"] | undefined,
	sizes__medium__filename?: GraphQLTypes["Location_Thumbnail_sizes__medium__filename_operator"] | undefined,
	sizes__large__url?: GraphQLTypes["Location_Thumbnail_sizes__large__url_operator"] | undefined,
	sizes__large__width?: GraphQLTypes["Location_Thumbnail_sizes__large__width_operator"] | undefined,
	sizes__large__height?: GraphQLTypes["Location_Thumbnail_sizes__large__height_operator"] | undefined,
	sizes__large__mimeType?: GraphQLTypes["Location_Thumbnail_sizes__large__mimeType_operator"] | undefined,
	sizes__large__filesize?: GraphQLTypes["Location_Thumbnail_sizes__large__filesize_operator"] | undefined,
	sizes__large__filename?: GraphQLTypes["Location_Thumbnail_sizes__large__filename_operator"] | undefined,
	sizes__small_jpeg__url?: GraphQLTypes["Location_Thumbnail_sizes__small_jpeg__url_operator"] | undefined,
	sizes__small_jpeg__width?: GraphQLTypes["Location_Thumbnail_sizes__small_jpeg__width_operator"] | undefined,
	sizes__small_jpeg__height?: GraphQLTypes["Location_Thumbnail_sizes__small_jpeg__height_operator"] | undefined,
	sizes__small_jpeg__mimeType?: GraphQLTypes["Location_Thumbnail_sizes__small_jpeg__mimeType_operator"] | undefined,
	sizes__small_jpeg__filesize?: GraphQLTypes["Location_Thumbnail_sizes__small_jpeg__filesize_operator"] | undefined,
	sizes__small_jpeg__filename?: GraphQLTypes["Location_Thumbnail_sizes__small_jpeg__filename_operator"] | undefined,
	sizes__medium_jpeg__url?: GraphQLTypes["Location_Thumbnail_sizes__medium_jpeg__url_operator"] | undefined,
	sizes__medium_jpeg__width?: GraphQLTypes["Location_Thumbnail_sizes__medium_jpeg__width_operator"] | undefined,
	sizes__medium_jpeg__height?: GraphQLTypes["Location_Thumbnail_sizes__medium_jpeg__height_operator"] | undefined,
	sizes__medium_jpeg__mimeType?: GraphQLTypes["Location_Thumbnail_sizes__medium_jpeg__mimeType_operator"] | undefined,
	sizes__medium_jpeg__filesize?: GraphQLTypes["Location_Thumbnail_sizes__medium_jpeg__filesize_operator"] | undefined,
	sizes__medium_jpeg__filename?: GraphQLTypes["Location_Thumbnail_sizes__medium_jpeg__filename_operator"] | undefined,
	sizes__large_jpeg__url?: GraphQLTypes["Location_Thumbnail_sizes__large_jpeg__url_operator"] | undefined,
	sizes__large_jpeg__width?: GraphQLTypes["Location_Thumbnail_sizes__large_jpeg__width_operator"] | undefined,
	sizes__large_jpeg__height?: GraphQLTypes["Location_Thumbnail_sizes__large_jpeg__height_operator"] | undefined,
	sizes__large_jpeg__mimeType?: GraphQLTypes["Location_Thumbnail_sizes__large_jpeg__mimeType_operator"] | undefined,
	sizes__large_jpeg__filesize?: GraphQLTypes["Location_Thumbnail_sizes__large_jpeg__filesize_operator"] | undefined,
	sizes__large_jpeg__filename?: GraphQLTypes["Location_Thumbnail_sizes__large_jpeg__filename_operator"] | undefined,
	id?: GraphQLTypes["Location_Thumbnail_id_operator"] | undefined,
	AND?: Array<GraphQLTypes["Location_Thumbnail_where_and"] | undefined> | undefined,
	OR?: Array<GraphQLTypes["Location_Thumbnail_where_or"] | undefined> | undefined
};
	["Location_Thumbnail_alt_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Location_Thumbnail_updatedAt_operator"]: {
		equals?: GraphQLTypes["DateTime"] | undefined,
	not_equals?: GraphQLTypes["DateTime"] | undefined,
	greater_than_equal?: GraphQLTypes["DateTime"] | undefined,
	greater_than?: GraphQLTypes["DateTime"] | undefined,
	less_than_equal?: GraphQLTypes["DateTime"] | undefined,
	less_than?: GraphQLTypes["DateTime"] | undefined,
	like?: GraphQLTypes["DateTime"] | undefined,
	exists?: boolean | undefined
};
	["Location_Thumbnail_createdAt_operator"]: {
		equals?: GraphQLTypes["DateTime"] | undefined,
	not_equals?: GraphQLTypes["DateTime"] | undefined,
	greater_than_equal?: GraphQLTypes["DateTime"] | undefined,
	greater_than?: GraphQLTypes["DateTime"] | undefined,
	less_than_equal?: GraphQLTypes["DateTime"] | undefined,
	less_than?: GraphQLTypes["DateTime"] | undefined,
	like?: GraphQLTypes["DateTime"] | undefined,
	exists?: boolean | undefined
};
	["Location_Thumbnail_url_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Location_Thumbnail_filename_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Location_Thumbnail_mimeType_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Location_Thumbnail_filesize_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Location_Thumbnail_width_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Location_Thumbnail_height_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Location_Thumbnail_focalX_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Location_Thumbnail_focalY_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Location_Thumbnail_sizes__small__url_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Location_Thumbnail_sizes__small__width_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Location_Thumbnail_sizes__small__height_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Location_Thumbnail_sizes__small__mimeType_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Location_Thumbnail_sizes__small__filesize_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Location_Thumbnail_sizes__small__filename_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Location_Thumbnail_sizes__medium__url_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Location_Thumbnail_sizes__medium__width_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Location_Thumbnail_sizes__medium__height_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Location_Thumbnail_sizes__medium__mimeType_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Location_Thumbnail_sizes__medium__filesize_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Location_Thumbnail_sizes__medium__filename_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Location_Thumbnail_sizes__large__url_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Location_Thumbnail_sizes__large__width_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Location_Thumbnail_sizes__large__height_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Location_Thumbnail_sizes__large__mimeType_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Location_Thumbnail_sizes__large__filesize_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Location_Thumbnail_sizes__large__filename_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Location_Thumbnail_sizes__small_jpeg__url_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Location_Thumbnail_sizes__small_jpeg__width_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Location_Thumbnail_sizes__small_jpeg__height_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Location_Thumbnail_sizes__small_jpeg__mimeType_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Location_Thumbnail_sizes__small_jpeg__filesize_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Location_Thumbnail_sizes__small_jpeg__filename_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Location_Thumbnail_sizes__medium_jpeg__url_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Location_Thumbnail_sizes__medium_jpeg__width_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Location_Thumbnail_sizes__medium_jpeg__height_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Location_Thumbnail_sizes__medium_jpeg__mimeType_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Location_Thumbnail_sizes__medium_jpeg__filesize_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Location_Thumbnail_sizes__medium_jpeg__filename_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Location_Thumbnail_sizes__large_jpeg__url_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Location_Thumbnail_sizes__large_jpeg__width_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Location_Thumbnail_sizes__large_jpeg__height_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Location_Thumbnail_sizes__large_jpeg__mimeType_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Location_Thumbnail_sizes__large_jpeg__filesize_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Location_Thumbnail_sizes__large_jpeg__filename_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Location_Thumbnail_id_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Location_Thumbnail_where_and"]: {
		alt?: GraphQLTypes["Location_Thumbnail_alt_operator"] | undefined,
	updatedAt?: GraphQLTypes["Location_Thumbnail_updatedAt_operator"] | undefined,
	createdAt?: GraphQLTypes["Location_Thumbnail_createdAt_operator"] | undefined,
	url?: GraphQLTypes["Location_Thumbnail_url_operator"] | undefined,
	filename?: GraphQLTypes["Location_Thumbnail_filename_operator"] | undefined,
	mimeType?: GraphQLTypes["Location_Thumbnail_mimeType_operator"] | undefined,
	filesize?: GraphQLTypes["Location_Thumbnail_filesize_operator"] | undefined,
	width?: GraphQLTypes["Location_Thumbnail_width_operator"] | undefined,
	height?: GraphQLTypes["Location_Thumbnail_height_operator"] | undefined,
	focalX?: GraphQLTypes["Location_Thumbnail_focalX_operator"] | undefined,
	focalY?: GraphQLTypes["Location_Thumbnail_focalY_operator"] | undefined,
	sizes__small__url?: GraphQLTypes["Location_Thumbnail_sizes__small__url_operator"] | undefined,
	sizes__small__width?: GraphQLTypes["Location_Thumbnail_sizes__small__width_operator"] | undefined,
	sizes__small__height?: GraphQLTypes["Location_Thumbnail_sizes__small__height_operator"] | undefined,
	sizes__small__mimeType?: GraphQLTypes["Location_Thumbnail_sizes__small__mimeType_operator"] | undefined,
	sizes__small__filesize?: GraphQLTypes["Location_Thumbnail_sizes__small__filesize_operator"] | undefined,
	sizes__small__filename?: GraphQLTypes["Location_Thumbnail_sizes__small__filename_operator"] | undefined,
	sizes__medium__url?: GraphQLTypes["Location_Thumbnail_sizes__medium__url_operator"] | undefined,
	sizes__medium__width?: GraphQLTypes["Location_Thumbnail_sizes__medium__width_operator"] | undefined,
	sizes__medium__height?: GraphQLTypes["Location_Thumbnail_sizes__medium__height_operator"] | undefined,
	sizes__medium__mimeType?: GraphQLTypes["Location_Thumbnail_sizes__medium__mimeType_operator"] | undefined,
	sizes__medium__filesize?: GraphQLTypes["Location_Thumbnail_sizes__medium__filesize_operator"] | undefined,
	sizes__medium__filename?: GraphQLTypes["Location_Thumbnail_sizes__medium__filename_operator"] | undefined,
	sizes__large__url?: GraphQLTypes["Location_Thumbnail_sizes__large__url_operator"] | undefined,
	sizes__large__width?: GraphQLTypes["Location_Thumbnail_sizes__large__width_operator"] | undefined,
	sizes__large__height?: GraphQLTypes["Location_Thumbnail_sizes__large__height_operator"] | undefined,
	sizes__large__mimeType?: GraphQLTypes["Location_Thumbnail_sizes__large__mimeType_operator"] | undefined,
	sizes__large__filesize?: GraphQLTypes["Location_Thumbnail_sizes__large__filesize_operator"] | undefined,
	sizes__large__filename?: GraphQLTypes["Location_Thumbnail_sizes__large__filename_operator"] | undefined,
	sizes__small_jpeg__url?: GraphQLTypes["Location_Thumbnail_sizes__small_jpeg__url_operator"] | undefined,
	sizes__small_jpeg__width?: GraphQLTypes["Location_Thumbnail_sizes__small_jpeg__width_operator"] | undefined,
	sizes__small_jpeg__height?: GraphQLTypes["Location_Thumbnail_sizes__small_jpeg__height_operator"] | undefined,
	sizes__small_jpeg__mimeType?: GraphQLTypes["Location_Thumbnail_sizes__small_jpeg__mimeType_operator"] | undefined,
	sizes__small_jpeg__filesize?: GraphQLTypes["Location_Thumbnail_sizes__small_jpeg__filesize_operator"] | undefined,
	sizes__small_jpeg__filename?: GraphQLTypes["Location_Thumbnail_sizes__small_jpeg__filename_operator"] | undefined,
	sizes__medium_jpeg__url?: GraphQLTypes["Location_Thumbnail_sizes__medium_jpeg__url_operator"] | undefined,
	sizes__medium_jpeg__width?: GraphQLTypes["Location_Thumbnail_sizes__medium_jpeg__width_operator"] | undefined,
	sizes__medium_jpeg__height?: GraphQLTypes["Location_Thumbnail_sizes__medium_jpeg__height_operator"] | undefined,
	sizes__medium_jpeg__mimeType?: GraphQLTypes["Location_Thumbnail_sizes__medium_jpeg__mimeType_operator"] | undefined,
	sizes__medium_jpeg__filesize?: GraphQLTypes["Location_Thumbnail_sizes__medium_jpeg__filesize_operator"] | undefined,
	sizes__medium_jpeg__filename?: GraphQLTypes["Location_Thumbnail_sizes__medium_jpeg__filename_operator"] | undefined,
	sizes__large_jpeg__url?: GraphQLTypes["Location_Thumbnail_sizes__large_jpeg__url_operator"] | undefined,
	sizes__large_jpeg__width?: GraphQLTypes["Location_Thumbnail_sizes__large_jpeg__width_operator"] | undefined,
	sizes__large_jpeg__height?: GraphQLTypes["Location_Thumbnail_sizes__large_jpeg__height_operator"] | undefined,
	sizes__large_jpeg__mimeType?: GraphQLTypes["Location_Thumbnail_sizes__large_jpeg__mimeType_operator"] | undefined,
	sizes__large_jpeg__filesize?: GraphQLTypes["Location_Thumbnail_sizes__large_jpeg__filesize_operator"] | undefined,
	sizes__large_jpeg__filename?: GraphQLTypes["Location_Thumbnail_sizes__large_jpeg__filename_operator"] | undefined,
	id?: GraphQLTypes["Location_Thumbnail_id_operator"] | undefined,
	AND?: Array<GraphQLTypes["Location_Thumbnail_where_and"] | undefined> | undefined,
	OR?: Array<GraphQLTypes["Location_Thumbnail_where_or"] | undefined> | undefined
};
	["Location_Thumbnail_where_or"]: {
		alt?: GraphQLTypes["Location_Thumbnail_alt_operator"] | undefined,
	updatedAt?: GraphQLTypes["Location_Thumbnail_updatedAt_operator"] | undefined,
	createdAt?: GraphQLTypes["Location_Thumbnail_createdAt_operator"] | undefined,
	url?: GraphQLTypes["Location_Thumbnail_url_operator"] | undefined,
	filename?: GraphQLTypes["Location_Thumbnail_filename_operator"] | undefined,
	mimeType?: GraphQLTypes["Location_Thumbnail_mimeType_operator"] | undefined,
	filesize?: GraphQLTypes["Location_Thumbnail_filesize_operator"] | undefined,
	width?: GraphQLTypes["Location_Thumbnail_width_operator"] | undefined,
	height?: GraphQLTypes["Location_Thumbnail_height_operator"] | undefined,
	focalX?: GraphQLTypes["Location_Thumbnail_focalX_operator"] | undefined,
	focalY?: GraphQLTypes["Location_Thumbnail_focalY_operator"] | undefined,
	sizes__small__url?: GraphQLTypes["Location_Thumbnail_sizes__small__url_operator"] | undefined,
	sizes__small__width?: GraphQLTypes["Location_Thumbnail_sizes__small__width_operator"] | undefined,
	sizes__small__height?: GraphQLTypes["Location_Thumbnail_sizes__small__height_operator"] | undefined,
	sizes__small__mimeType?: GraphQLTypes["Location_Thumbnail_sizes__small__mimeType_operator"] | undefined,
	sizes__small__filesize?: GraphQLTypes["Location_Thumbnail_sizes__small__filesize_operator"] | undefined,
	sizes__small__filename?: GraphQLTypes["Location_Thumbnail_sizes__small__filename_operator"] | undefined,
	sizes__medium__url?: GraphQLTypes["Location_Thumbnail_sizes__medium__url_operator"] | undefined,
	sizes__medium__width?: GraphQLTypes["Location_Thumbnail_sizes__medium__width_operator"] | undefined,
	sizes__medium__height?: GraphQLTypes["Location_Thumbnail_sizes__medium__height_operator"] | undefined,
	sizes__medium__mimeType?: GraphQLTypes["Location_Thumbnail_sizes__medium__mimeType_operator"] | undefined,
	sizes__medium__filesize?: GraphQLTypes["Location_Thumbnail_sizes__medium__filesize_operator"] | undefined,
	sizes__medium__filename?: GraphQLTypes["Location_Thumbnail_sizes__medium__filename_operator"] | undefined,
	sizes__large__url?: GraphQLTypes["Location_Thumbnail_sizes__large__url_operator"] | undefined,
	sizes__large__width?: GraphQLTypes["Location_Thumbnail_sizes__large__width_operator"] | undefined,
	sizes__large__height?: GraphQLTypes["Location_Thumbnail_sizes__large__height_operator"] | undefined,
	sizes__large__mimeType?: GraphQLTypes["Location_Thumbnail_sizes__large__mimeType_operator"] | undefined,
	sizes__large__filesize?: GraphQLTypes["Location_Thumbnail_sizes__large__filesize_operator"] | undefined,
	sizes__large__filename?: GraphQLTypes["Location_Thumbnail_sizes__large__filename_operator"] | undefined,
	sizes__small_jpeg__url?: GraphQLTypes["Location_Thumbnail_sizes__small_jpeg__url_operator"] | undefined,
	sizes__small_jpeg__width?: GraphQLTypes["Location_Thumbnail_sizes__small_jpeg__width_operator"] | undefined,
	sizes__small_jpeg__height?: GraphQLTypes["Location_Thumbnail_sizes__small_jpeg__height_operator"] | undefined,
	sizes__small_jpeg__mimeType?: GraphQLTypes["Location_Thumbnail_sizes__small_jpeg__mimeType_operator"] | undefined,
	sizes__small_jpeg__filesize?: GraphQLTypes["Location_Thumbnail_sizes__small_jpeg__filesize_operator"] | undefined,
	sizes__small_jpeg__filename?: GraphQLTypes["Location_Thumbnail_sizes__small_jpeg__filename_operator"] | undefined,
	sizes__medium_jpeg__url?: GraphQLTypes["Location_Thumbnail_sizes__medium_jpeg__url_operator"] | undefined,
	sizes__medium_jpeg__width?: GraphQLTypes["Location_Thumbnail_sizes__medium_jpeg__width_operator"] | undefined,
	sizes__medium_jpeg__height?: GraphQLTypes["Location_Thumbnail_sizes__medium_jpeg__height_operator"] | undefined,
	sizes__medium_jpeg__mimeType?: GraphQLTypes["Location_Thumbnail_sizes__medium_jpeg__mimeType_operator"] | undefined,
	sizes__medium_jpeg__filesize?: GraphQLTypes["Location_Thumbnail_sizes__medium_jpeg__filesize_operator"] | undefined,
	sizes__medium_jpeg__filename?: GraphQLTypes["Location_Thumbnail_sizes__medium_jpeg__filename_operator"] | undefined,
	sizes__large_jpeg__url?: GraphQLTypes["Location_Thumbnail_sizes__large_jpeg__url_operator"] | undefined,
	sizes__large_jpeg__width?: GraphQLTypes["Location_Thumbnail_sizes__large_jpeg__width_operator"] | undefined,
	sizes__large_jpeg__height?: GraphQLTypes["Location_Thumbnail_sizes__large_jpeg__height_operator"] | undefined,
	sizes__large_jpeg__mimeType?: GraphQLTypes["Location_Thumbnail_sizes__large_jpeg__mimeType_operator"] | undefined,
	sizes__large_jpeg__filesize?: GraphQLTypes["Location_Thumbnail_sizes__large_jpeg__filesize_operator"] | undefined,
	sizes__large_jpeg__filename?: GraphQLTypes["Location_Thumbnail_sizes__large_jpeg__filename_operator"] | undefined,
	id?: GraphQLTypes["Location_Thumbnail_id_operator"] | undefined,
	AND?: Array<GraphQLTypes["Location_Thumbnail_where_and"] | undefined> | undefined,
	OR?: Array<GraphQLTypes["Location_Thumbnail_where_or"] | undefined> | undefined
};
	["Location_Banner_where"]: {
		alt?: GraphQLTypes["Location_Banner_alt_operator"] | undefined,
	updatedAt?: GraphQLTypes["Location_Banner_updatedAt_operator"] | undefined,
	createdAt?: GraphQLTypes["Location_Banner_createdAt_operator"] | undefined,
	url?: GraphQLTypes["Location_Banner_url_operator"] | undefined,
	filename?: GraphQLTypes["Location_Banner_filename_operator"] | undefined,
	mimeType?: GraphQLTypes["Location_Banner_mimeType_operator"] | undefined,
	filesize?: GraphQLTypes["Location_Banner_filesize_operator"] | undefined,
	width?: GraphQLTypes["Location_Banner_width_operator"] | undefined,
	height?: GraphQLTypes["Location_Banner_height_operator"] | undefined,
	focalX?: GraphQLTypes["Location_Banner_focalX_operator"] | undefined,
	focalY?: GraphQLTypes["Location_Banner_focalY_operator"] | undefined,
	sizes__small__url?: GraphQLTypes["Location_Banner_sizes__small__url_operator"] | undefined,
	sizes__small__width?: GraphQLTypes["Location_Banner_sizes__small__width_operator"] | undefined,
	sizes__small__height?: GraphQLTypes["Location_Banner_sizes__small__height_operator"] | undefined,
	sizes__small__mimeType?: GraphQLTypes["Location_Banner_sizes__small__mimeType_operator"] | undefined,
	sizes__small__filesize?: GraphQLTypes["Location_Banner_sizes__small__filesize_operator"] | undefined,
	sizes__small__filename?: GraphQLTypes["Location_Banner_sizes__small__filename_operator"] | undefined,
	sizes__medium__url?: GraphQLTypes["Location_Banner_sizes__medium__url_operator"] | undefined,
	sizes__medium__width?: GraphQLTypes["Location_Banner_sizes__medium__width_operator"] | undefined,
	sizes__medium__height?: GraphQLTypes["Location_Banner_sizes__medium__height_operator"] | undefined,
	sizes__medium__mimeType?: GraphQLTypes["Location_Banner_sizes__medium__mimeType_operator"] | undefined,
	sizes__medium__filesize?: GraphQLTypes["Location_Banner_sizes__medium__filesize_operator"] | undefined,
	sizes__medium__filename?: GraphQLTypes["Location_Banner_sizes__medium__filename_operator"] | undefined,
	sizes__large__url?: GraphQLTypes["Location_Banner_sizes__large__url_operator"] | undefined,
	sizes__large__width?: GraphQLTypes["Location_Banner_sizes__large__width_operator"] | undefined,
	sizes__large__height?: GraphQLTypes["Location_Banner_sizes__large__height_operator"] | undefined,
	sizes__large__mimeType?: GraphQLTypes["Location_Banner_sizes__large__mimeType_operator"] | undefined,
	sizes__large__filesize?: GraphQLTypes["Location_Banner_sizes__large__filesize_operator"] | undefined,
	sizes__large__filename?: GraphQLTypes["Location_Banner_sizes__large__filename_operator"] | undefined,
	sizes__small_jpeg__url?: GraphQLTypes["Location_Banner_sizes__small_jpeg__url_operator"] | undefined,
	sizes__small_jpeg__width?: GraphQLTypes["Location_Banner_sizes__small_jpeg__width_operator"] | undefined,
	sizes__small_jpeg__height?: GraphQLTypes["Location_Banner_sizes__small_jpeg__height_operator"] | undefined,
	sizes__small_jpeg__mimeType?: GraphQLTypes["Location_Banner_sizes__small_jpeg__mimeType_operator"] | undefined,
	sizes__small_jpeg__filesize?: GraphQLTypes["Location_Banner_sizes__small_jpeg__filesize_operator"] | undefined,
	sizes__small_jpeg__filename?: GraphQLTypes["Location_Banner_sizes__small_jpeg__filename_operator"] | undefined,
	sizes__medium_jpeg__url?: GraphQLTypes["Location_Banner_sizes__medium_jpeg__url_operator"] | undefined,
	sizes__medium_jpeg__width?: GraphQLTypes["Location_Banner_sizes__medium_jpeg__width_operator"] | undefined,
	sizes__medium_jpeg__height?: GraphQLTypes["Location_Banner_sizes__medium_jpeg__height_operator"] | undefined,
	sizes__medium_jpeg__mimeType?: GraphQLTypes["Location_Banner_sizes__medium_jpeg__mimeType_operator"] | undefined,
	sizes__medium_jpeg__filesize?: GraphQLTypes["Location_Banner_sizes__medium_jpeg__filesize_operator"] | undefined,
	sizes__medium_jpeg__filename?: GraphQLTypes["Location_Banner_sizes__medium_jpeg__filename_operator"] | undefined,
	sizes__large_jpeg__url?: GraphQLTypes["Location_Banner_sizes__large_jpeg__url_operator"] | undefined,
	sizes__large_jpeg__width?: GraphQLTypes["Location_Banner_sizes__large_jpeg__width_operator"] | undefined,
	sizes__large_jpeg__height?: GraphQLTypes["Location_Banner_sizes__large_jpeg__height_operator"] | undefined,
	sizes__large_jpeg__mimeType?: GraphQLTypes["Location_Banner_sizes__large_jpeg__mimeType_operator"] | undefined,
	sizes__large_jpeg__filesize?: GraphQLTypes["Location_Banner_sizes__large_jpeg__filesize_operator"] | undefined,
	sizes__large_jpeg__filename?: GraphQLTypes["Location_Banner_sizes__large_jpeg__filename_operator"] | undefined,
	id?: GraphQLTypes["Location_Banner_id_operator"] | undefined,
	AND?: Array<GraphQLTypes["Location_Banner_where_and"] | undefined> | undefined,
	OR?: Array<GraphQLTypes["Location_Banner_where_or"] | undefined> | undefined
};
	["Location_Banner_alt_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Location_Banner_updatedAt_operator"]: {
		equals?: GraphQLTypes["DateTime"] | undefined,
	not_equals?: GraphQLTypes["DateTime"] | undefined,
	greater_than_equal?: GraphQLTypes["DateTime"] | undefined,
	greater_than?: GraphQLTypes["DateTime"] | undefined,
	less_than_equal?: GraphQLTypes["DateTime"] | undefined,
	less_than?: GraphQLTypes["DateTime"] | undefined,
	like?: GraphQLTypes["DateTime"] | undefined,
	exists?: boolean | undefined
};
	["Location_Banner_createdAt_operator"]: {
		equals?: GraphQLTypes["DateTime"] | undefined,
	not_equals?: GraphQLTypes["DateTime"] | undefined,
	greater_than_equal?: GraphQLTypes["DateTime"] | undefined,
	greater_than?: GraphQLTypes["DateTime"] | undefined,
	less_than_equal?: GraphQLTypes["DateTime"] | undefined,
	less_than?: GraphQLTypes["DateTime"] | undefined,
	like?: GraphQLTypes["DateTime"] | undefined,
	exists?: boolean | undefined
};
	["Location_Banner_url_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Location_Banner_filename_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Location_Banner_mimeType_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Location_Banner_filesize_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Location_Banner_width_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Location_Banner_height_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Location_Banner_focalX_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Location_Banner_focalY_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Location_Banner_sizes__small__url_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Location_Banner_sizes__small__width_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Location_Banner_sizes__small__height_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Location_Banner_sizes__small__mimeType_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Location_Banner_sizes__small__filesize_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Location_Banner_sizes__small__filename_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Location_Banner_sizes__medium__url_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Location_Banner_sizes__medium__width_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Location_Banner_sizes__medium__height_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Location_Banner_sizes__medium__mimeType_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Location_Banner_sizes__medium__filesize_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Location_Banner_sizes__medium__filename_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Location_Banner_sizes__large__url_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Location_Banner_sizes__large__width_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Location_Banner_sizes__large__height_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Location_Banner_sizes__large__mimeType_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Location_Banner_sizes__large__filesize_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Location_Banner_sizes__large__filename_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Location_Banner_sizes__small_jpeg__url_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Location_Banner_sizes__small_jpeg__width_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Location_Banner_sizes__small_jpeg__height_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Location_Banner_sizes__small_jpeg__mimeType_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Location_Banner_sizes__small_jpeg__filesize_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Location_Banner_sizes__small_jpeg__filename_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Location_Banner_sizes__medium_jpeg__url_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Location_Banner_sizes__medium_jpeg__width_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Location_Banner_sizes__medium_jpeg__height_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Location_Banner_sizes__medium_jpeg__mimeType_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Location_Banner_sizes__medium_jpeg__filesize_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Location_Banner_sizes__medium_jpeg__filename_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Location_Banner_sizes__large_jpeg__url_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Location_Banner_sizes__large_jpeg__width_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Location_Banner_sizes__large_jpeg__height_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Location_Banner_sizes__large_jpeg__mimeType_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Location_Banner_sizes__large_jpeg__filesize_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Location_Banner_sizes__large_jpeg__filename_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Location_Banner_id_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Location_Banner_where_and"]: {
		alt?: GraphQLTypes["Location_Banner_alt_operator"] | undefined,
	updatedAt?: GraphQLTypes["Location_Banner_updatedAt_operator"] | undefined,
	createdAt?: GraphQLTypes["Location_Banner_createdAt_operator"] | undefined,
	url?: GraphQLTypes["Location_Banner_url_operator"] | undefined,
	filename?: GraphQLTypes["Location_Banner_filename_operator"] | undefined,
	mimeType?: GraphQLTypes["Location_Banner_mimeType_operator"] | undefined,
	filesize?: GraphQLTypes["Location_Banner_filesize_operator"] | undefined,
	width?: GraphQLTypes["Location_Banner_width_operator"] | undefined,
	height?: GraphQLTypes["Location_Banner_height_operator"] | undefined,
	focalX?: GraphQLTypes["Location_Banner_focalX_operator"] | undefined,
	focalY?: GraphQLTypes["Location_Banner_focalY_operator"] | undefined,
	sizes__small__url?: GraphQLTypes["Location_Banner_sizes__small__url_operator"] | undefined,
	sizes__small__width?: GraphQLTypes["Location_Banner_sizes__small__width_operator"] | undefined,
	sizes__small__height?: GraphQLTypes["Location_Banner_sizes__small__height_operator"] | undefined,
	sizes__small__mimeType?: GraphQLTypes["Location_Banner_sizes__small__mimeType_operator"] | undefined,
	sizes__small__filesize?: GraphQLTypes["Location_Banner_sizes__small__filesize_operator"] | undefined,
	sizes__small__filename?: GraphQLTypes["Location_Banner_sizes__small__filename_operator"] | undefined,
	sizes__medium__url?: GraphQLTypes["Location_Banner_sizes__medium__url_operator"] | undefined,
	sizes__medium__width?: GraphQLTypes["Location_Banner_sizes__medium__width_operator"] | undefined,
	sizes__medium__height?: GraphQLTypes["Location_Banner_sizes__medium__height_operator"] | undefined,
	sizes__medium__mimeType?: GraphQLTypes["Location_Banner_sizes__medium__mimeType_operator"] | undefined,
	sizes__medium__filesize?: GraphQLTypes["Location_Banner_sizes__medium__filesize_operator"] | undefined,
	sizes__medium__filename?: GraphQLTypes["Location_Banner_sizes__medium__filename_operator"] | undefined,
	sizes__large__url?: GraphQLTypes["Location_Banner_sizes__large__url_operator"] | undefined,
	sizes__large__width?: GraphQLTypes["Location_Banner_sizes__large__width_operator"] | undefined,
	sizes__large__height?: GraphQLTypes["Location_Banner_sizes__large__height_operator"] | undefined,
	sizes__large__mimeType?: GraphQLTypes["Location_Banner_sizes__large__mimeType_operator"] | undefined,
	sizes__large__filesize?: GraphQLTypes["Location_Banner_sizes__large__filesize_operator"] | undefined,
	sizes__large__filename?: GraphQLTypes["Location_Banner_sizes__large__filename_operator"] | undefined,
	sizes__small_jpeg__url?: GraphQLTypes["Location_Banner_sizes__small_jpeg__url_operator"] | undefined,
	sizes__small_jpeg__width?: GraphQLTypes["Location_Banner_sizes__small_jpeg__width_operator"] | undefined,
	sizes__small_jpeg__height?: GraphQLTypes["Location_Banner_sizes__small_jpeg__height_operator"] | undefined,
	sizes__small_jpeg__mimeType?: GraphQLTypes["Location_Banner_sizes__small_jpeg__mimeType_operator"] | undefined,
	sizes__small_jpeg__filesize?: GraphQLTypes["Location_Banner_sizes__small_jpeg__filesize_operator"] | undefined,
	sizes__small_jpeg__filename?: GraphQLTypes["Location_Banner_sizes__small_jpeg__filename_operator"] | undefined,
	sizes__medium_jpeg__url?: GraphQLTypes["Location_Banner_sizes__medium_jpeg__url_operator"] | undefined,
	sizes__medium_jpeg__width?: GraphQLTypes["Location_Banner_sizes__medium_jpeg__width_operator"] | undefined,
	sizes__medium_jpeg__height?: GraphQLTypes["Location_Banner_sizes__medium_jpeg__height_operator"] | undefined,
	sizes__medium_jpeg__mimeType?: GraphQLTypes["Location_Banner_sizes__medium_jpeg__mimeType_operator"] | undefined,
	sizes__medium_jpeg__filesize?: GraphQLTypes["Location_Banner_sizes__medium_jpeg__filesize_operator"] | undefined,
	sizes__medium_jpeg__filename?: GraphQLTypes["Location_Banner_sizes__medium_jpeg__filename_operator"] | undefined,
	sizes__large_jpeg__url?: GraphQLTypes["Location_Banner_sizes__large_jpeg__url_operator"] | undefined,
	sizes__large_jpeg__width?: GraphQLTypes["Location_Banner_sizes__large_jpeg__width_operator"] | undefined,
	sizes__large_jpeg__height?: GraphQLTypes["Location_Banner_sizes__large_jpeg__height_operator"] | undefined,
	sizes__large_jpeg__mimeType?: GraphQLTypes["Location_Banner_sizes__large_jpeg__mimeType_operator"] | undefined,
	sizes__large_jpeg__filesize?: GraphQLTypes["Location_Banner_sizes__large_jpeg__filesize_operator"] | undefined,
	sizes__large_jpeg__filename?: GraphQLTypes["Location_Banner_sizes__large_jpeg__filename_operator"] | undefined,
	id?: GraphQLTypes["Location_Banner_id_operator"] | undefined,
	AND?: Array<GraphQLTypes["Location_Banner_where_and"] | undefined> | undefined,
	OR?: Array<GraphQLTypes["Location_Banner_where_or"] | undefined> | undefined
};
	["Location_Banner_where_or"]: {
		alt?: GraphQLTypes["Location_Banner_alt_operator"] | undefined,
	updatedAt?: GraphQLTypes["Location_Banner_updatedAt_operator"] | undefined,
	createdAt?: GraphQLTypes["Location_Banner_createdAt_operator"] | undefined,
	url?: GraphQLTypes["Location_Banner_url_operator"] | undefined,
	filename?: GraphQLTypes["Location_Banner_filename_operator"] | undefined,
	mimeType?: GraphQLTypes["Location_Banner_mimeType_operator"] | undefined,
	filesize?: GraphQLTypes["Location_Banner_filesize_operator"] | undefined,
	width?: GraphQLTypes["Location_Banner_width_operator"] | undefined,
	height?: GraphQLTypes["Location_Banner_height_operator"] | undefined,
	focalX?: GraphQLTypes["Location_Banner_focalX_operator"] | undefined,
	focalY?: GraphQLTypes["Location_Banner_focalY_operator"] | undefined,
	sizes__small__url?: GraphQLTypes["Location_Banner_sizes__small__url_operator"] | undefined,
	sizes__small__width?: GraphQLTypes["Location_Banner_sizes__small__width_operator"] | undefined,
	sizes__small__height?: GraphQLTypes["Location_Banner_sizes__small__height_operator"] | undefined,
	sizes__small__mimeType?: GraphQLTypes["Location_Banner_sizes__small__mimeType_operator"] | undefined,
	sizes__small__filesize?: GraphQLTypes["Location_Banner_sizes__small__filesize_operator"] | undefined,
	sizes__small__filename?: GraphQLTypes["Location_Banner_sizes__small__filename_operator"] | undefined,
	sizes__medium__url?: GraphQLTypes["Location_Banner_sizes__medium__url_operator"] | undefined,
	sizes__medium__width?: GraphQLTypes["Location_Banner_sizes__medium__width_operator"] | undefined,
	sizes__medium__height?: GraphQLTypes["Location_Banner_sizes__medium__height_operator"] | undefined,
	sizes__medium__mimeType?: GraphQLTypes["Location_Banner_sizes__medium__mimeType_operator"] | undefined,
	sizes__medium__filesize?: GraphQLTypes["Location_Banner_sizes__medium__filesize_operator"] | undefined,
	sizes__medium__filename?: GraphQLTypes["Location_Banner_sizes__medium__filename_operator"] | undefined,
	sizes__large__url?: GraphQLTypes["Location_Banner_sizes__large__url_operator"] | undefined,
	sizes__large__width?: GraphQLTypes["Location_Banner_sizes__large__width_operator"] | undefined,
	sizes__large__height?: GraphQLTypes["Location_Banner_sizes__large__height_operator"] | undefined,
	sizes__large__mimeType?: GraphQLTypes["Location_Banner_sizes__large__mimeType_operator"] | undefined,
	sizes__large__filesize?: GraphQLTypes["Location_Banner_sizes__large__filesize_operator"] | undefined,
	sizes__large__filename?: GraphQLTypes["Location_Banner_sizes__large__filename_operator"] | undefined,
	sizes__small_jpeg__url?: GraphQLTypes["Location_Banner_sizes__small_jpeg__url_operator"] | undefined,
	sizes__small_jpeg__width?: GraphQLTypes["Location_Banner_sizes__small_jpeg__width_operator"] | undefined,
	sizes__small_jpeg__height?: GraphQLTypes["Location_Banner_sizes__small_jpeg__height_operator"] | undefined,
	sizes__small_jpeg__mimeType?: GraphQLTypes["Location_Banner_sizes__small_jpeg__mimeType_operator"] | undefined,
	sizes__small_jpeg__filesize?: GraphQLTypes["Location_Banner_sizes__small_jpeg__filesize_operator"] | undefined,
	sizes__small_jpeg__filename?: GraphQLTypes["Location_Banner_sizes__small_jpeg__filename_operator"] | undefined,
	sizes__medium_jpeg__url?: GraphQLTypes["Location_Banner_sizes__medium_jpeg__url_operator"] | undefined,
	sizes__medium_jpeg__width?: GraphQLTypes["Location_Banner_sizes__medium_jpeg__width_operator"] | undefined,
	sizes__medium_jpeg__height?: GraphQLTypes["Location_Banner_sizes__medium_jpeg__height_operator"] | undefined,
	sizes__medium_jpeg__mimeType?: GraphQLTypes["Location_Banner_sizes__medium_jpeg__mimeType_operator"] | undefined,
	sizes__medium_jpeg__filesize?: GraphQLTypes["Location_Banner_sizes__medium_jpeg__filesize_operator"] | undefined,
	sizes__medium_jpeg__filename?: GraphQLTypes["Location_Banner_sizes__medium_jpeg__filename_operator"] | undefined,
	sizes__large_jpeg__url?: GraphQLTypes["Location_Banner_sizes__large_jpeg__url_operator"] | undefined,
	sizes__large_jpeg__width?: GraphQLTypes["Location_Banner_sizes__large_jpeg__width_operator"] | undefined,
	sizes__large_jpeg__height?: GraphQLTypes["Location_Banner_sizes__large_jpeg__height_operator"] | undefined,
	sizes__large_jpeg__mimeType?: GraphQLTypes["Location_Banner_sizes__large_jpeg__mimeType_operator"] | undefined,
	sizes__large_jpeg__filesize?: GraphQLTypes["Location_Banner_sizes__large_jpeg__filesize_operator"] | undefined,
	sizes__large_jpeg__filename?: GraphQLTypes["Location_Banner_sizes__large_jpeg__filename_operator"] | undefined,
	id?: GraphQLTypes["Location_Banner_id_operator"] | undefined,
	AND?: Array<GraphQLTypes["Location_Banner_where_and"] | undefined> | undefined,
	OR?: Array<GraphQLTypes["Location_Banner_where_or"] | undefined> | undefined
};
	["Location_type"]: Location_type;
	["Location_category"]: Location_category;
	["District"]: {
	__typename: "District",
	id?: string | undefined,
	name?: string | undefined,
	level: number,
	children?: Array<GraphQLTypes["District"]> | undefined,
	value: string,
	lat?: number | undefined,
	long?: number | undefined,
	radius?: number | undefined,
	region?: GraphQLTypes["District_region"] | undefined,
	updatedAt?: GraphQLTypes["DateTime"] | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined
};
	["District_region"]: District_region;
	["Location_Details"]: {
	__typename: "Location_Details",
	code?: number | undefined,
	phone?: string | undefined,
	page?: string | undefined,
	email?: GraphQLTypes["EmailAddress"] | undefined
};
	["Location_Content"]:{
        	__typename:"RichText" | "Gallery" | "Image" | "Video"
        	['...on RichText']: '__union' & GraphQLTypes["RichText"];
	['...on Gallery']: '__union' & GraphQLTypes["Gallery"];
	['...on Image']: '__union' & GraphQLTypes["Image"];
	['...on Video']: '__union' & GraphQLTypes["Video"];
};
	["RichText"]: {
	__typename: "RichText",
	richText?: GraphQLTypes["JSON"] | undefined,
	id?: string | undefined,
	blockName?: string | undefined,
	blockType?: string | undefined
};
	/** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
["JSON"]: "scalar" & { name: "JSON" };
	["Gallery"]: {
	__typename: "Gallery",
	gallery?: Array<GraphQLTypes["Gallery_Gallery"]> | undefined,
	id?: string | undefined,
	blockName?: string | undefined,
	blockType?: string | undefined
};
	["Gallery_Gallery"]: {
	__typename: "Gallery_Gallery",
	item?: GraphQLTypes["Media"] | undefined,
	caption?: string | undefined,
	id?: string | undefined
};
	["Gallery_Gallery_Item_where"]: {
		alt?: GraphQLTypes["Gallery_Gallery_Item_alt_operator"] | undefined,
	updatedAt?: GraphQLTypes["Gallery_Gallery_Item_updatedAt_operator"] | undefined,
	createdAt?: GraphQLTypes["Gallery_Gallery_Item_createdAt_operator"] | undefined,
	url?: GraphQLTypes["Gallery_Gallery_Item_url_operator"] | undefined,
	filename?: GraphQLTypes["Gallery_Gallery_Item_filename_operator"] | undefined,
	mimeType?: GraphQLTypes["Gallery_Gallery_Item_mimeType_operator"] | undefined,
	filesize?: GraphQLTypes["Gallery_Gallery_Item_filesize_operator"] | undefined,
	width?: GraphQLTypes["Gallery_Gallery_Item_width_operator"] | undefined,
	height?: GraphQLTypes["Gallery_Gallery_Item_height_operator"] | undefined,
	focalX?: GraphQLTypes["Gallery_Gallery_Item_focalX_operator"] | undefined,
	focalY?: GraphQLTypes["Gallery_Gallery_Item_focalY_operator"] | undefined,
	sizes__small__url?: GraphQLTypes["Gallery_Gallery_Item_sizes__small__url_operator"] | undefined,
	sizes__small__width?: GraphQLTypes["Gallery_Gallery_Item_sizes__small__width_operator"] | undefined,
	sizes__small__height?: GraphQLTypes["Gallery_Gallery_Item_sizes__small__height_operator"] | undefined,
	sizes__small__mimeType?: GraphQLTypes["Gallery_Gallery_Item_sizes__small__mimeType_operator"] | undefined,
	sizes__small__filesize?: GraphQLTypes["Gallery_Gallery_Item_sizes__small__filesize_operator"] | undefined,
	sizes__small__filename?: GraphQLTypes["Gallery_Gallery_Item_sizes__small__filename_operator"] | undefined,
	sizes__medium__url?: GraphQLTypes["Gallery_Gallery_Item_sizes__medium__url_operator"] | undefined,
	sizes__medium__width?: GraphQLTypes["Gallery_Gallery_Item_sizes__medium__width_operator"] | undefined,
	sizes__medium__height?: GraphQLTypes["Gallery_Gallery_Item_sizes__medium__height_operator"] | undefined,
	sizes__medium__mimeType?: GraphQLTypes["Gallery_Gallery_Item_sizes__medium__mimeType_operator"] | undefined,
	sizes__medium__filesize?: GraphQLTypes["Gallery_Gallery_Item_sizes__medium__filesize_operator"] | undefined,
	sizes__medium__filename?: GraphQLTypes["Gallery_Gallery_Item_sizes__medium__filename_operator"] | undefined,
	sizes__large__url?: GraphQLTypes["Gallery_Gallery_Item_sizes__large__url_operator"] | undefined,
	sizes__large__width?: GraphQLTypes["Gallery_Gallery_Item_sizes__large__width_operator"] | undefined,
	sizes__large__height?: GraphQLTypes["Gallery_Gallery_Item_sizes__large__height_operator"] | undefined,
	sizes__large__mimeType?: GraphQLTypes["Gallery_Gallery_Item_sizes__large__mimeType_operator"] | undefined,
	sizes__large__filesize?: GraphQLTypes["Gallery_Gallery_Item_sizes__large__filesize_operator"] | undefined,
	sizes__large__filename?: GraphQLTypes["Gallery_Gallery_Item_sizes__large__filename_operator"] | undefined,
	sizes__small_jpeg__url?: GraphQLTypes["Gallery_Gallery_Item_sizes__small_jpeg__url_operator"] | undefined,
	sizes__small_jpeg__width?: GraphQLTypes["Gallery_Gallery_Item_sizes__small_jpeg__width_operator"] | undefined,
	sizes__small_jpeg__height?: GraphQLTypes["Gallery_Gallery_Item_sizes__small_jpeg__height_operator"] | undefined,
	sizes__small_jpeg__mimeType?: GraphQLTypes["Gallery_Gallery_Item_sizes__small_jpeg__mimeType_operator"] | undefined,
	sizes__small_jpeg__filesize?: GraphQLTypes["Gallery_Gallery_Item_sizes__small_jpeg__filesize_operator"] | undefined,
	sizes__small_jpeg__filename?: GraphQLTypes["Gallery_Gallery_Item_sizes__small_jpeg__filename_operator"] | undefined,
	sizes__medium_jpeg__url?: GraphQLTypes["Gallery_Gallery_Item_sizes__medium_jpeg__url_operator"] | undefined,
	sizes__medium_jpeg__width?: GraphQLTypes["Gallery_Gallery_Item_sizes__medium_jpeg__width_operator"] | undefined,
	sizes__medium_jpeg__height?: GraphQLTypes["Gallery_Gallery_Item_sizes__medium_jpeg__height_operator"] | undefined,
	sizes__medium_jpeg__mimeType?: GraphQLTypes["Gallery_Gallery_Item_sizes__medium_jpeg__mimeType_operator"] | undefined,
	sizes__medium_jpeg__filesize?: GraphQLTypes["Gallery_Gallery_Item_sizes__medium_jpeg__filesize_operator"] | undefined,
	sizes__medium_jpeg__filename?: GraphQLTypes["Gallery_Gallery_Item_sizes__medium_jpeg__filename_operator"] | undefined,
	sizes__large_jpeg__url?: GraphQLTypes["Gallery_Gallery_Item_sizes__large_jpeg__url_operator"] | undefined,
	sizes__large_jpeg__width?: GraphQLTypes["Gallery_Gallery_Item_sizes__large_jpeg__width_operator"] | undefined,
	sizes__large_jpeg__height?: GraphQLTypes["Gallery_Gallery_Item_sizes__large_jpeg__height_operator"] | undefined,
	sizes__large_jpeg__mimeType?: GraphQLTypes["Gallery_Gallery_Item_sizes__large_jpeg__mimeType_operator"] | undefined,
	sizes__large_jpeg__filesize?: GraphQLTypes["Gallery_Gallery_Item_sizes__large_jpeg__filesize_operator"] | undefined,
	sizes__large_jpeg__filename?: GraphQLTypes["Gallery_Gallery_Item_sizes__large_jpeg__filename_operator"] | undefined,
	id?: GraphQLTypes["Gallery_Gallery_Item_id_operator"] | undefined,
	AND?: Array<GraphQLTypes["Gallery_Gallery_Item_where_and"] | undefined> | undefined,
	OR?: Array<GraphQLTypes["Gallery_Gallery_Item_where_or"] | undefined> | undefined
};
	["Gallery_Gallery_Item_alt_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Gallery_Gallery_Item_updatedAt_operator"]: {
		equals?: GraphQLTypes["DateTime"] | undefined,
	not_equals?: GraphQLTypes["DateTime"] | undefined,
	greater_than_equal?: GraphQLTypes["DateTime"] | undefined,
	greater_than?: GraphQLTypes["DateTime"] | undefined,
	less_than_equal?: GraphQLTypes["DateTime"] | undefined,
	less_than?: GraphQLTypes["DateTime"] | undefined,
	like?: GraphQLTypes["DateTime"] | undefined,
	exists?: boolean | undefined
};
	["Gallery_Gallery_Item_createdAt_operator"]: {
		equals?: GraphQLTypes["DateTime"] | undefined,
	not_equals?: GraphQLTypes["DateTime"] | undefined,
	greater_than_equal?: GraphQLTypes["DateTime"] | undefined,
	greater_than?: GraphQLTypes["DateTime"] | undefined,
	less_than_equal?: GraphQLTypes["DateTime"] | undefined,
	less_than?: GraphQLTypes["DateTime"] | undefined,
	like?: GraphQLTypes["DateTime"] | undefined,
	exists?: boolean | undefined
};
	["Gallery_Gallery_Item_url_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Gallery_Gallery_Item_filename_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Gallery_Gallery_Item_mimeType_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Gallery_Gallery_Item_filesize_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Gallery_Gallery_Item_width_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Gallery_Gallery_Item_height_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Gallery_Gallery_Item_focalX_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Gallery_Gallery_Item_focalY_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Gallery_Gallery_Item_sizes__small__url_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Gallery_Gallery_Item_sizes__small__width_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Gallery_Gallery_Item_sizes__small__height_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Gallery_Gallery_Item_sizes__small__mimeType_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Gallery_Gallery_Item_sizes__small__filesize_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Gallery_Gallery_Item_sizes__small__filename_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Gallery_Gallery_Item_sizes__medium__url_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Gallery_Gallery_Item_sizes__medium__width_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Gallery_Gallery_Item_sizes__medium__height_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Gallery_Gallery_Item_sizes__medium__mimeType_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Gallery_Gallery_Item_sizes__medium__filesize_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Gallery_Gallery_Item_sizes__medium__filename_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Gallery_Gallery_Item_sizes__large__url_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Gallery_Gallery_Item_sizes__large__width_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Gallery_Gallery_Item_sizes__large__height_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Gallery_Gallery_Item_sizes__large__mimeType_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Gallery_Gallery_Item_sizes__large__filesize_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Gallery_Gallery_Item_sizes__large__filename_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Gallery_Gallery_Item_sizes__small_jpeg__url_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Gallery_Gallery_Item_sizes__small_jpeg__width_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Gallery_Gallery_Item_sizes__small_jpeg__height_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Gallery_Gallery_Item_sizes__small_jpeg__mimeType_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Gallery_Gallery_Item_sizes__small_jpeg__filesize_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Gallery_Gallery_Item_sizes__small_jpeg__filename_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Gallery_Gallery_Item_sizes__medium_jpeg__url_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Gallery_Gallery_Item_sizes__medium_jpeg__width_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Gallery_Gallery_Item_sizes__medium_jpeg__height_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Gallery_Gallery_Item_sizes__medium_jpeg__mimeType_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Gallery_Gallery_Item_sizes__medium_jpeg__filesize_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Gallery_Gallery_Item_sizes__medium_jpeg__filename_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Gallery_Gallery_Item_sizes__large_jpeg__url_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Gallery_Gallery_Item_sizes__large_jpeg__width_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Gallery_Gallery_Item_sizes__large_jpeg__height_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Gallery_Gallery_Item_sizes__large_jpeg__mimeType_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Gallery_Gallery_Item_sizes__large_jpeg__filesize_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Gallery_Gallery_Item_sizes__large_jpeg__filename_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Gallery_Gallery_Item_id_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Gallery_Gallery_Item_where_and"]: {
		alt?: GraphQLTypes["Gallery_Gallery_Item_alt_operator"] | undefined,
	updatedAt?: GraphQLTypes["Gallery_Gallery_Item_updatedAt_operator"] | undefined,
	createdAt?: GraphQLTypes["Gallery_Gallery_Item_createdAt_operator"] | undefined,
	url?: GraphQLTypes["Gallery_Gallery_Item_url_operator"] | undefined,
	filename?: GraphQLTypes["Gallery_Gallery_Item_filename_operator"] | undefined,
	mimeType?: GraphQLTypes["Gallery_Gallery_Item_mimeType_operator"] | undefined,
	filesize?: GraphQLTypes["Gallery_Gallery_Item_filesize_operator"] | undefined,
	width?: GraphQLTypes["Gallery_Gallery_Item_width_operator"] | undefined,
	height?: GraphQLTypes["Gallery_Gallery_Item_height_operator"] | undefined,
	focalX?: GraphQLTypes["Gallery_Gallery_Item_focalX_operator"] | undefined,
	focalY?: GraphQLTypes["Gallery_Gallery_Item_focalY_operator"] | undefined,
	sizes__small__url?: GraphQLTypes["Gallery_Gallery_Item_sizes__small__url_operator"] | undefined,
	sizes__small__width?: GraphQLTypes["Gallery_Gallery_Item_sizes__small__width_operator"] | undefined,
	sizes__small__height?: GraphQLTypes["Gallery_Gallery_Item_sizes__small__height_operator"] | undefined,
	sizes__small__mimeType?: GraphQLTypes["Gallery_Gallery_Item_sizes__small__mimeType_operator"] | undefined,
	sizes__small__filesize?: GraphQLTypes["Gallery_Gallery_Item_sizes__small__filesize_operator"] | undefined,
	sizes__small__filename?: GraphQLTypes["Gallery_Gallery_Item_sizes__small__filename_operator"] | undefined,
	sizes__medium__url?: GraphQLTypes["Gallery_Gallery_Item_sizes__medium__url_operator"] | undefined,
	sizes__medium__width?: GraphQLTypes["Gallery_Gallery_Item_sizes__medium__width_operator"] | undefined,
	sizes__medium__height?: GraphQLTypes["Gallery_Gallery_Item_sizes__medium__height_operator"] | undefined,
	sizes__medium__mimeType?: GraphQLTypes["Gallery_Gallery_Item_sizes__medium__mimeType_operator"] | undefined,
	sizes__medium__filesize?: GraphQLTypes["Gallery_Gallery_Item_sizes__medium__filesize_operator"] | undefined,
	sizes__medium__filename?: GraphQLTypes["Gallery_Gallery_Item_sizes__medium__filename_operator"] | undefined,
	sizes__large__url?: GraphQLTypes["Gallery_Gallery_Item_sizes__large__url_operator"] | undefined,
	sizes__large__width?: GraphQLTypes["Gallery_Gallery_Item_sizes__large__width_operator"] | undefined,
	sizes__large__height?: GraphQLTypes["Gallery_Gallery_Item_sizes__large__height_operator"] | undefined,
	sizes__large__mimeType?: GraphQLTypes["Gallery_Gallery_Item_sizes__large__mimeType_operator"] | undefined,
	sizes__large__filesize?: GraphQLTypes["Gallery_Gallery_Item_sizes__large__filesize_operator"] | undefined,
	sizes__large__filename?: GraphQLTypes["Gallery_Gallery_Item_sizes__large__filename_operator"] | undefined,
	sizes__small_jpeg__url?: GraphQLTypes["Gallery_Gallery_Item_sizes__small_jpeg__url_operator"] | undefined,
	sizes__small_jpeg__width?: GraphQLTypes["Gallery_Gallery_Item_sizes__small_jpeg__width_operator"] | undefined,
	sizes__small_jpeg__height?: GraphQLTypes["Gallery_Gallery_Item_sizes__small_jpeg__height_operator"] | undefined,
	sizes__small_jpeg__mimeType?: GraphQLTypes["Gallery_Gallery_Item_sizes__small_jpeg__mimeType_operator"] | undefined,
	sizes__small_jpeg__filesize?: GraphQLTypes["Gallery_Gallery_Item_sizes__small_jpeg__filesize_operator"] | undefined,
	sizes__small_jpeg__filename?: GraphQLTypes["Gallery_Gallery_Item_sizes__small_jpeg__filename_operator"] | undefined,
	sizes__medium_jpeg__url?: GraphQLTypes["Gallery_Gallery_Item_sizes__medium_jpeg__url_operator"] | undefined,
	sizes__medium_jpeg__width?: GraphQLTypes["Gallery_Gallery_Item_sizes__medium_jpeg__width_operator"] | undefined,
	sizes__medium_jpeg__height?: GraphQLTypes["Gallery_Gallery_Item_sizes__medium_jpeg__height_operator"] | undefined,
	sizes__medium_jpeg__mimeType?: GraphQLTypes["Gallery_Gallery_Item_sizes__medium_jpeg__mimeType_operator"] | undefined,
	sizes__medium_jpeg__filesize?: GraphQLTypes["Gallery_Gallery_Item_sizes__medium_jpeg__filesize_operator"] | undefined,
	sizes__medium_jpeg__filename?: GraphQLTypes["Gallery_Gallery_Item_sizes__medium_jpeg__filename_operator"] | undefined,
	sizes__large_jpeg__url?: GraphQLTypes["Gallery_Gallery_Item_sizes__large_jpeg__url_operator"] | undefined,
	sizes__large_jpeg__width?: GraphQLTypes["Gallery_Gallery_Item_sizes__large_jpeg__width_operator"] | undefined,
	sizes__large_jpeg__height?: GraphQLTypes["Gallery_Gallery_Item_sizes__large_jpeg__height_operator"] | undefined,
	sizes__large_jpeg__mimeType?: GraphQLTypes["Gallery_Gallery_Item_sizes__large_jpeg__mimeType_operator"] | undefined,
	sizes__large_jpeg__filesize?: GraphQLTypes["Gallery_Gallery_Item_sizes__large_jpeg__filesize_operator"] | undefined,
	sizes__large_jpeg__filename?: GraphQLTypes["Gallery_Gallery_Item_sizes__large_jpeg__filename_operator"] | undefined,
	id?: GraphQLTypes["Gallery_Gallery_Item_id_operator"] | undefined,
	AND?: Array<GraphQLTypes["Gallery_Gallery_Item_where_and"] | undefined> | undefined,
	OR?: Array<GraphQLTypes["Gallery_Gallery_Item_where_or"] | undefined> | undefined
};
	["Gallery_Gallery_Item_where_or"]: {
		alt?: GraphQLTypes["Gallery_Gallery_Item_alt_operator"] | undefined,
	updatedAt?: GraphQLTypes["Gallery_Gallery_Item_updatedAt_operator"] | undefined,
	createdAt?: GraphQLTypes["Gallery_Gallery_Item_createdAt_operator"] | undefined,
	url?: GraphQLTypes["Gallery_Gallery_Item_url_operator"] | undefined,
	filename?: GraphQLTypes["Gallery_Gallery_Item_filename_operator"] | undefined,
	mimeType?: GraphQLTypes["Gallery_Gallery_Item_mimeType_operator"] | undefined,
	filesize?: GraphQLTypes["Gallery_Gallery_Item_filesize_operator"] | undefined,
	width?: GraphQLTypes["Gallery_Gallery_Item_width_operator"] | undefined,
	height?: GraphQLTypes["Gallery_Gallery_Item_height_operator"] | undefined,
	focalX?: GraphQLTypes["Gallery_Gallery_Item_focalX_operator"] | undefined,
	focalY?: GraphQLTypes["Gallery_Gallery_Item_focalY_operator"] | undefined,
	sizes__small__url?: GraphQLTypes["Gallery_Gallery_Item_sizes__small__url_operator"] | undefined,
	sizes__small__width?: GraphQLTypes["Gallery_Gallery_Item_sizes__small__width_operator"] | undefined,
	sizes__small__height?: GraphQLTypes["Gallery_Gallery_Item_sizes__small__height_operator"] | undefined,
	sizes__small__mimeType?: GraphQLTypes["Gallery_Gallery_Item_sizes__small__mimeType_operator"] | undefined,
	sizes__small__filesize?: GraphQLTypes["Gallery_Gallery_Item_sizes__small__filesize_operator"] | undefined,
	sizes__small__filename?: GraphQLTypes["Gallery_Gallery_Item_sizes__small__filename_operator"] | undefined,
	sizes__medium__url?: GraphQLTypes["Gallery_Gallery_Item_sizes__medium__url_operator"] | undefined,
	sizes__medium__width?: GraphQLTypes["Gallery_Gallery_Item_sizes__medium__width_operator"] | undefined,
	sizes__medium__height?: GraphQLTypes["Gallery_Gallery_Item_sizes__medium__height_operator"] | undefined,
	sizes__medium__mimeType?: GraphQLTypes["Gallery_Gallery_Item_sizes__medium__mimeType_operator"] | undefined,
	sizes__medium__filesize?: GraphQLTypes["Gallery_Gallery_Item_sizes__medium__filesize_operator"] | undefined,
	sizes__medium__filename?: GraphQLTypes["Gallery_Gallery_Item_sizes__medium__filename_operator"] | undefined,
	sizes__large__url?: GraphQLTypes["Gallery_Gallery_Item_sizes__large__url_operator"] | undefined,
	sizes__large__width?: GraphQLTypes["Gallery_Gallery_Item_sizes__large__width_operator"] | undefined,
	sizes__large__height?: GraphQLTypes["Gallery_Gallery_Item_sizes__large__height_operator"] | undefined,
	sizes__large__mimeType?: GraphQLTypes["Gallery_Gallery_Item_sizes__large__mimeType_operator"] | undefined,
	sizes__large__filesize?: GraphQLTypes["Gallery_Gallery_Item_sizes__large__filesize_operator"] | undefined,
	sizes__large__filename?: GraphQLTypes["Gallery_Gallery_Item_sizes__large__filename_operator"] | undefined,
	sizes__small_jpeg__url?: GraphQLTypes["Gallery_Gallery_Item_sizes__small_jpeg__url_operator"] | undefined,
	sizes__small_jpeg__width?: GraphQLTypes["Gallery_Gallery_Item_sizes__small_jpeg__width_operator"] | undefined,
	sizes__small_jpeg__height?: GraphQLTypes["Gallery_Gallery_Item_sizes__small_jpeg__height_operator"] | undefined,
	sizes__small_jpeg__mimeType?: GraphQLTypes["Gallery_Gallery_Item_sizes__small_jpeg__mimeType_operator"] | undefined,
	sizes__small_jpeg__filesize?: GraphQLTypes["Gallery_Gallery_Item_sizes__small_jpeg__filesize_operator"] | undefined,
	sizes__small_jpeg__filename?: GraphQLTypes["Gallery_Gallery_Item_sizes__small_jpeg__filename_operator"] | undefined,
	sizes__medium_jpeg__url?: GraphQLTypes["Gallery_Gallery_Item_sizes__medium_jpeg__url_operator"] | undefined,
	sizes__medium_jpeg__width?: GraphQLTypes["Gallery_Gallery_Item_sizes__medium_jpeg__width_operator"] | undefined,
	sizes__medium_jpeg__height?: GraphQLTypes["Gallery_Gallery_Item_sizes__medium_jpeg__height_operator"] | undefined,
	sizes__medium_jpeg__mimeType?: GraphQLTypes["Gallery_Gallery_Item_sizes__medium_jpeg__mimeType_operator"] | undefined,
	sizes__medium_jpeg__filesize?: GraphQLTypes["Gallery_Gallery_Item_sizes__medium_jpeg__filesize_operator"] | undefined,
	sizes__medium_jpeg__filename?: GraphQLTypes["Gallery_Gallery_Item_sizes__medium_jpeg__filename_operator"] | undefined,
	sizes__large_jpeg__url?: GraphQLTypes["Gallery_Gallery_Item_sizes__large_jpeg__url_operator"] | undefined,
	sizes__large_jpeg__width?: GraphQLTypes["Gallery_Gallery_Item_sizes__large_jpeg__width_operator"] | undefined,
	sizes__large_jpeg__height?: GraphQLTypes["Gallery_Gallery_Item_sizes__large_jpeg__height_operator"] | undefined,
	sizes__large_jpeg__mimeType?: GraphQLTypes["Gallery_Gallery_Item_sizes__large_jpeg__mimeType_operator"] | undefined,
	sizes__large_jpeg__filesize?: GraphQLTypes["Gallery_Gallery_Item_sizes__large_jpeg__filesize_operator"] | undefined,
	sizes__large_jpeg__filename?: GraphQLTypes["Gallery_Gallery_Item_sizes__large_jpeg__filename_operator"] | undefined,
	id?: GraphQLTypes["Gallery_Gallery_Item_id_operator"] | undefined,
	AND?: Array<GraphQLTypes["Gallery_Gallery_Item_where_and"] | undefined> | undefined,
	OR?: Array<GraphQLTypes["Gallery_Gallery_Item_where_or"] | undefined> | undefined
};
	["Image"]: {
	__typename: "Image",
	media: GraphQLTypes["Media"],
	caption?: string | undefined,
	id?: string | undefined,
	blockName?: string | undefined,
	blockType?: string | undefined
};
	["Image_Media_where"]: {
		alt?: GraphQLTypes["Image_Media_alt_operator"] | undefined,
	updatedAt?: GraphQLTypes["Image_Media_updatedAt_operator"] | undefined,
	createdAt?: GraphQLTypes["Image_Media_createdAt_operator"] | undefined,
	url?: GraphQLTypes["Image_Media_url_operator"] | undefined,
	filename?: GraphQLTypes["Image_Media_filename_operator"] | undefined,
	mimeType?: GraphQLTypes["Image_Media_mimeType_operator"] | undefined,
	filesize?: GraphQLTypes["Image_Media_filesize_operator"] | undefined,
	width?: GraphQLTypes["Image_Media_width_operator"] | undefined,
	height?: GraphQLTypes["Image_Media_height_operator"] | undefined,
	focalX?: GraphQLTypes["Image_Media_focalX_operator"] | undefined,
	focalY?: GraphQLTypes["Image_Media_focalY_operator"] | undefined,
	sizes__small__url?: GraphQLTypes["Image_Media_sizes__small__url_operator"] | undefined,
	sizes__small__width?: GraphQLTypes["Image_Media_sizes__small__width_operator"] | undefined,
	sizes__small__height?: GraphQLTypes["Image_Media_sizes__small__height_operator"] | undefined,
	sizes__small__mimeType?: GraphQLTypes["Image_Media_sizes__small__mimeType_operator"] | undefined,
	sizes__small__filesize?: GraphQLTypes["Image_Media_sizes__small__filesize_operator"] | undefined,
	sizes__small__filename?: GraphQLTypes["Image_Media_sizes__small__filename_operator"] | undefined,
	sizes__medium__url?: GraphQLTypes["Image_Media_sizes__medium__url_operator"] | undefined,
	sizes__medium__width?: GraphQLTypes["Image_Media_sizes__medium__width_operator"] | undefined,
	sizes__medium__height?: GraphQLTypes["Image_Media_sizes__medium__height_operator"] | undefined,
	sizes__medium__mimeType?: GraphQLTypes["Image_Media_sizes__medium__mimeType_operator"] | undefined,
	sizes__medium__filesize?: GraphQLTypes["Image_Media_sizes__medium__filesize_operator"] | undefined,
	sizes__medium__filename?: GraphQLTypes["Image_Media_sizes__medium__filename_operator"] | undefined,
	sizes__large__url?: GraphQLTypes["Image_Media_sizes__large__url_operator"] | undefined,
	sizes__large__width?: GraphQLTypes["Image_Media_sizes__large__width_operator"] | undefined,
	sizes__large__height?: GraphQLTypes["Image_Media_sizes__large__height_operator"] | undefined,
	sizes__large__mimeType?: GraphQLTypes["Image_Media_sizes__large__mimeType_operator"] | undefined,
	sizes__large__filesize?: GraphQLTypes["Image_Media_sizes__large__filesize_operator"] | undefined,
	sizes__large__filename?: GraphQLTypes["Image_Media_sizes__large__filename_operator"] | undefined,
	sizes__small_jpeg__url?: GraphQLTypes["Image_Media_sizes__small_jpeg__url_operator"] | undefined,
	sizes__small_jpeg__width?: GraphQLTypes["Image_Media_sizes__small_jpeg__width_operator"] | undefined,
	sizes__small_jpeg__height?: GraphQLTypes["Image_Media_sizes__small_jpeg__height_operator"] | undefined,
	sizes__small_jpeg__mimeType?: GraphQLTypes["Image_Media_sizes__small_jpeg__mimeType_operator"] | undefined,
	sizes__small_jpeg__filesize?: GraphQLTypes["Image_Media_sizes__small_jpeg__filesize_operator"] | undefined,
	sizes__small_jpeg__filename?: GraphQLTypes["Image_Media_sizes__small_jpeg__filename_operator"] | undefined,
	sizes__medium_jpeg__url?: GraphQLTypes["Image_Media_sizes__medium_jpeg__url_operator"] | undefined,
	sizes__medium_jpeg__width?: GraphQLTypes["Image_Media_sizes__medium_jpeg__width_operator"] | undefined,
	sizes__medium_jpeg__height?: GraphQLTypes["Image_Media_sizes__medium_jpeg__height_operator"] | undefined,
	sizes__medium_jpeg__mimeType?: GraphQLTypes["Image_Media_sizes__medium_jpeg__mimeType_operator"] | undefined,
	sizes__medium_jpeg__filesize?: GraphQLTypes["Image_Media_sizes__medium_jpeg__filesize_operator"] | undefined,
	sizes__medium_jpeg__filename?: GraphQLTypes["Image_Media_sizes__medium_jpeg__filename_operator"] | undefined,
	sizes__large_jpeg__url?: GraphQLTypes["Image_Media_sizes__large_jpeg__url_operator"] | undefined,
	sizes__large_jpeg__width?: GraphQLTypes["Image_Media_sizes__large_jpeg__width_operator"] | undefined,
	sizes__large_jpeg__height?: GraphQLTypes["Image_Media_sizes__large_jpeg__height_operator"] | undefined,
	sizes__large_jpeg__mimeType?: GraphQLTypes["Image_Media_sizes__large_jpeg__mimeType_operator"] | undefined,
	sizes__large_jpeg__filesize?: GraphQLTypes["Image_Media_sizes__large_jpeg__filesize_operator"] | undefined,
	sizes__large_jpeg__filename?: GraphQLTypes["Image_Media_sizes__large_jpeg__filename_operator"] | undefined,
	id?: GraphQLTypes["Image_Media_id_operator"] | undefined,
	AND?: Array<GraphQLTypes["Image_Media_where_and"] | undefined> | undefined,
	OR?: Array<GraphQLTypes["Image_Media_where_or"] | undefined> | undefined
};
	["Image_Media_alt_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Image_Media_updatedAt_operator"]: {
		equals?: GraphQLTypes["DateTime"] | undefined,
	not_equals?: GraphQLTypes["DateTime"] | undefined,
	greater_than_equal?: GraphQLTypes["DateTime"] | undefined,
	greater_than?: GraphQLTypes["DateTime"] | undefined,
	less_than_equal?: GraphQLTypes["DateTime"] | undefined,
	less_than?: GraphQLTypes["DateTime"] | undefined,
	like?: GraphQLTypes["DateTime"] | undefined,
	exists?: boolean | undefined
};
	["Image_Media_createdAt_operator"]: {
		equals?: GraphQLTypes["DateTime"] | undefined,
	not_equals?: GraphQLTypes["DateTime"] | undefined,
	greater_than_equal?: GraphQLTypes["DateTime"] | undefined,
	greater_than?: GraphQLTypes["DateTime"] | undefined,
	less_than_equal?: GraphQLTypes["DateTime"] | undefined,
	less_than?: GraphQLTypes["DateTime"] | undefined,
	like?: GraphQLTypes["DateTime"] | undefined,
	exists?: boolean | undefined
};
	["Image_Media_url_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Image_Media_filename_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Image_Media_mimeType_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Image_Media_filesize_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Image_Media_width_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Image_Media_height_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Image_Media_focalX_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Image_Media_focalY_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Image_Media_sizes__small__url_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Image_Media_sizes__small__width_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Image_Media_sizes__small__height_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Image_Media_sizes__small__mimeType_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Image_Media_sizes__small__filesize_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Image_Media_sizes__small__filename_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Image_Media_sizes__medium__url_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Image_Media_sizes__medium__width_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Image_Media_sizes__medium__height_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Image_Media_sizes__medium__mimeType_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Image_Media_sizes__medium__filesize_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Image_Media_sizes__medium__filename_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Image_Media_sizes__large__url_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Image_Media_sizes__large__width_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Image_Media_sizes__large__height_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Image_Media_sizes__large__mimeType_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Image_Media_sizes__large__filesize_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Image_Media_sizes__large__filename_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Image_Media_sizes__small_jpeg__url_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Image_Media_sizes__small_jpeg__width_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Image_Media_sizes__small_jpeg__height_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Image_Media_sizes__small_jpeg__mimeType_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Image_Media_sizes__small_jpeg__filesize_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Image_Media_sizes__small_jpeg__filename_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Image_Media_sizes__medium_jpeg__url_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Image_Media_sizes__medium_jpeg__width_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Image_Media_sizes__medium_jpeg__height_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Image_Media_sizes__medium_jpeg__mimeType_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Image_Media_sizes__medium_jpeg__filesize_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Image_Media_sizes__medium_jpeg__filename_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Image_Media_sizes__large_jpeg__url_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Image_Media_sizes__large_jpeg__width_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Image_Media_sizes__large_jpeg__height_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Image_Media_sizes__large_jpeg__mimeType_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Image_Media_sizes__large_jpeg__filesize_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Image_Media_sizes__large_jpeg__filename_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Image_Media_id_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Image_Media_where_and"]: {
		alt?: GraphQLTypes["Image_Media_alt_operator"] | undefined,
	updatedAt?: GraphQLTypes["Image_Media_updatedAt_operator"] | undefined,
	createdAt?: GraphQLTypes["Image_Media_createdAt_operator"] | undefined,
	url?: GraphQLTypes["Image_Media_url_operator"] | undefined,
	filename?: GraphQLTypes["Image_Media_filename_operator"] | undefined,
	mimeType?: GraphQLTypes["Image_Media_mimeType_operator"] | undefined,
	filesize?: GraphQLTypes["Image_Media_filesize_operator"] | undefined,
	width?: GraphQLTypes["Image_Media_width_operator"] | undefined,
	height?: GraphQLTypes["Image_Media_height_operator"] | undefined,
	focalX?: GraphQLTypes["Image_Media_focalX_operator"] | undefined,
	focalY?: GraphQLTypes["Image_Media_focalY_operator"] | undefined,
	sizes__small__url?: GraphQLTypes["Image_Media_sizes__small__url_operator"] | undefined,
	sizes__small__width?: GraphQLTypes["Image_Media_sizes__small__width_operator"] | undefined,
	sizes__small__height?: GraphQLTypes["Image_Media_sizes__small__height_operator"] | undefined,
	sizes__small__mimeType?: GraphQLTypes["Image_Media_sizes__small__mimeType_operator"] | undefined,
	sizes__small__filesize?: GraphQLTypes["Image_Media_sizes__small__filesize_operator"] | undefined,
	sizes__small__filename?: GraphQLTypes["Image_Media_sizes__small__filename_operator"] | undefined,
	sizes__medium__url?: GraphQLTypes["Image_Media_sizes__medium__url_operator"] | undefined,
	sizes__medium__width?: GraphQLTypes["Image_Media_sizes__medium__width_operator"] | undefined,
	sizes__medium__height?: GraphQLTypes["Image_Media_sizes__medium__height_operator"] | undefined,
	sizes__medium__mimeType?: GraphQLTypes["Image_Media_sizes__medium__mimeType_operator"] | undefined,
	sizes__medium__filesize?: GraphQLTypes["Image_Media_sizes__medium__filesize_operator"] | undefined,
	sizes__medium__filename?: GraphQLTypes["Image_Media_sizes__medium__filename_operator"] | undefined,
	sizes__large__url?: GraphQLTypes["Image_Media_sizes__large__url_operator"] | undefined,
	sizes__large__width?: GraphQLTypes["Image_Media_sizes__large__width_operator"] | undefined,
	sizes__large__height?: GraphQLTypes["Image_Media_sizes__large__height_operator"] | undefined,
	sizes__large__mimeType?: GraphQLTypes["Image_Media_sizes__large__mimeType_operator"] | undefined,
	sizes__large__filesize?: GraphQLTypes["Image_Media_sizes__large__filesize_operator"] | undefined,
	sizes__large__filename?: GraphQLTypes["Image_Media_sizes__large__filename_operator"] | undefined,
	sizes__small_jpeg__url?: GraphQLTypes["Image_Media_sizes__small_jpeg__url_operator"] | undefined,
	sizes__small_jpeg__width?: GraphQLTypes["Image_Media_sizes__small_jpeg__width_operator"] | undefined,
	sizes__small_jpeg__height?: GraphQLTypes["Image_Media_sizes__small_jpeg__height_operator"] | undefined,
	sizes__small_jpeg__mimeType?: GraphQLTypes["Image_Media_sizes__small_jpeg__mimeType_operator"] | undefined,
	sizes__small_jpeg__filesize?: GraphQLTypes["Image_Media_sizes__small_jpeg__filesize_operator"] | undefined,
	sizes__small_jpeg__filename?: GraphQLTypes["Image_Media_sizes__small_jpeg__filename_operator"] | undefined,
	sizes__medium_jpeg__url?: GraphQLTypes["Image_Media_sizes__medium_jpeg__url_operator"] | undefined,
	sizes__medium_jpeg__width?: GraphQLTypes["Image_Media_sizes__medium_jpeg__width_operator"] | undefined,
	sizes__medium_jpeg__height?: GraphQLTypes["Image_Media_sizes__medium_jpeg__height_operator"] | undefined,
	sizes__medium_jpeg__mimeType?: GraphQLTypes["Image_Media_sizes__medium_jpeg__mimeType_operator"] | undefined,
	sizes__medium_jpeg__filesize?: GraphQLTypes["Image_Media_sizes__medium_jpeg__filesize_operator"] | undefined,
	sizes__medium_jpeg__filename?: GraphQLTypes["Image_Media_sizes__medium_jpeg__filename_operator"] | undefined,
	sizes__large_jpeg__url?: GraphQLTypes["Image_Media_sizes__large_jpeg__url_operator"] | undefined,
	sizes__large_jpeg__width?: GraphQLTypes["Image_Media_sizes__large_jpeg__width_operator"] | undefined,
	sizes__large_jpeg__height?: GraphQLTypes["Image_Media_sizes__large_jpeg__height_operator"] | undefined,
	sizes__large_jpeg__mimeType?: GraphQLTypes["Image_Media_sizes__large_jpeg__mimeType_operator"] | undefined,
	sizes__large_jpeg__filesize?: GraphQLTypes["Image_Media_sizes__large_jpeg__filesize_operator"] | undefined,
	sizes__large_jpeg__filename?: GraphQLTypes["Image_Media_sizes__large_jpeg__filename_operator"] | undefined,
	id?: GraphQLTypes["Image_Media_id_operator"] | undefined,
	AND?: Array<GraphQLTypes["Image_Media_where_and"] | undefined> | undefined,
	OR?: Array<GraphQLTypes["Image_Media_where_or"] | undefined> | undefined
};
	["Image_Media_where_or"]: {
		alt?: GraphQLTypes["Image_Media_alt_operator"] | undefined,
	updatedAt?: GraphQLTypes["Image_Media_updatedAt_operator"] | undefined,
	createdAt?: GraphQLTypes["Image_Media_createdAt_operator"] | undefined,
	url?: GraphQLTypes["Image_Media_url_operator"] | undefined,
	filename?: GraphQLTypes["Image_Media_filename_operator"] | undefined,
	mimeType?: GraphQLTypes["Image_Media_mimeType_operator"] | undefined,
	filesize?: GraphQLTypes["Image_Media_filesize_operator"] | undefined,
	width?: GraphQLTypes["Image_Media_width_operator"] | undefined,
	height?: GraphQLTypes["Image_Media_height_operator"] | undefined,
	focalX?: GraphQLTypes["Image_Media_focalX_operator"] | undefined,
	focalY?: GraphQLTypes["Image_Media_focalY_operator"] | undefined,
	sizes__small__url?: GraphQLTypes["Image_Media_sizes__small__url_operator"] | undefined,
	sizes__small__width?: GraphQLTypes["Image_Media_sizes__small__width_operator"] | undefined,
	sizes__small__height?: GraphQLTypes["Image_Media_sizes__small__height_operator"] | undefined,
	sizes__small__mimeType?: GraphQLTypes["Image_Media_sizes__small__mimeType_operator"] | undefined,
	sizes__small__filesize?: GraphQLTypes["Image_Media_sizes__small__filesize_operator"] | undefined,
	sizes__small__filename?: GraphQLTypes["Image_Media_sizes__small__filename_operator"] | undefined,
	sizes__medium__url?: GraphQLTypes["Image_Media_sizes__medium__url_operator"] | undefined,
	sizes__medium__width?: GraphQLTypes["Image_Media_sizes__medium__width_operator"] | undefined,
	sizes__medium__height?: GraphQLTypes["Image_Media_sizes__medium__height_operator"] | undefined,
	sizes__medium__mimeType?: GraphQLTypes["Image_Media_sizes__medium__mimeType_operator"] | undefined,
	sizes__medium__filesize?: GraphQLTypes["Image_Media_sizes__medium__filesize_operator"] | undefined,
	sizes__medium__filename?: GraphQLTypes["Image_Media_sizes__medium__filename_operator"] | undefined,
	sizes__large__url?: GraphQLTypes["Image_Media_sizes__large__url_operator"] | undefined,
	sizes__large__width?: GraphQLTypes["Image_Media_sizes__large__width_operator"] | undefined,
	sizes__large__height?: GraphQLTypes["Image_Media_sizes__large__height_operator"] | undefined,
	sizes__large__mimeType?: GraphQLTypes["Image_Media_sizes__large__mimeType_operator"] | undefined,
	sizes__large__filesize?: GraphQLTypes["Image_Media_sizes__large__filesize_operator"] | undefined,
	sizes__large__filename?: GraphQLTypes["Image_Media_sizes__large__filename_operator"] | undefined,
	sizes__small_jpeg__url?: GraphQLTypes["Image_Media_sizes__small_jpeg__url_operator"] | undefined,
	sizes__small_jpeg__width?: GraphQLTypes["Image_Media_sizes__small_jpeg__width_operator"] | undefined,
	sizes__small_jpeg__height?: GraphQLTypes["Image_Media_sizes__small_jpeg__height_operator"] | undefined,
	sizes__small_jpeg__mimeType?: GraphQLTypes["Image_Media_sizes__small_jpeg__mimeType_operator"] | undefined,
	sizes__small_jpeg__filesize?: GraphQLTypes["Image_Media_sizes__small_jpeg__filesize_operator"] | undefined,
	sizes__small_jpeg__filename?: GraphQLTypes["Image_Media_sizes__small_jpeg__filename_operator"] | undefined,
	sizes__medium_jpeg__url?: GraphQLTypes["Image_Media_sizes__medium_jpeg__url_operator"] | undefined,
	sizes__medium_jpeg__width?: GraphQLTypes["Image_Media_sizes__medium_jpeg__width_operator"] | undefined,
	sizes__medium_jpeg__height?: GraphQLTypes["Image_Media_sizes__medium_jpeg__height_operator"] | undefined,
	sizes__medium_jpeg__mimeType?: GraphQLTypes["Image_Media_sizes__medium_jpeg__mimeType_operator"] | undefined,
	sizes__medium_jpeg__filesize?: GraphQLTypes["Image_Media_sizes__medium_jpeg__filesize_operator"] | undefined,
	sizes__medium_jpeg__filename?: GraphQLTypes["Image_Media_sizes__medium_jpeg__filename_operator"] | undefined,
	sizes__large_jpeg__url?: GraphQLTypes["Image_Media_sizes__large_jpeg__url_operator"] | undefined,
	sizes__large_jpeg__width?: GraphQLTypes["Image_Media_sizes__large_jpeg__width_operator"] | undefined,
	sizes__large_jpeg__height?: GraphQLTypes["Image_Media_sizes__large_jpeg__height_operator"] | undefined,
	sizes__large_jpeg__mimeType?: GraphQLTypes["Image_Media_sizes__large_jpeg__mimeType_operator"] | undefined,
	sizes__large_jpeg__filesize?: GraphQLTypes["Image_Media_sizes__large_jpeg__filesize_operator"] | undefined,
	sizes__large_jpeg__filename?: GraphQLTypes["Image_Media_sizes__large_jpeg__filename_operator"] | undefined,
	id?: GraphQLTypes["Image_Media_id_operator"] | undefined,
	AND?: Array<GraphQLTypes["Image_Media_where_and"] | undefined> | undefined,
	OR?: Array<GraphQLTypes["Image_Media_where_or"] | undefined> | undefined
};
	["Video"]: {
	__typename: "Video",
	url: string,
	id?: string | undefined,
	blockName?: string | undefined,
	blockType?: string | undefined
};
	["Location_Criteria"]: {
	__typename: "Location_Criteria",
	audiences?: Array<GraphQLTypes["Audience"]> | undefined,
	dates?: Array<GraphQLTypes["Date"]> | undefined,
	categories?: Array<GraphQLTypes["EventCategory"]> | undefined,
	tags?: Array<GraphQLTypes["Tag"]> | undefined
};
	["Audience"]: {
	__typename: "Audience",
	id?: string | undefined,
	name?: string | undefined,
	value: string,
	icon?: GraphQLTypes["Media"] | undefined,
	region?: GraphQLTypes["Audience_region"] | undefined,
	updatedAt?: GraphQLTypes["DateTime"] | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined
};
	["Audience_Icon_where"]: {
		alt?: GraphQLTypes["Audience_Icon_alt_operator"] | undefined,
	updatedAt?: GraphQLTypes["Audience_Icon_updatedAt_operator"] | undefined,
	createdAt?: GraphQLTypes["Audience_Icon_createdAt_operator"] | undefined,
	url?: GraphQLTypes["Audience_Icon_url_operator"] | undefined,
	filename?: GraphQLTypes["Audience_Icon_filename_operator"] | undefined,
	mimeType?: GraphQLTypes["Audience_Icon_mimeType_operator"] | undefined,
	filesize?: GraphQLTypes["Audience_Icon_filesize_operator"] | undefined,
	width?: GraphQLTypes["Audience_Icon_width_operator"] | undefined,
	height?: GraphQLTypes["Audience_Icon_height_operator"] | undefined,
	focalX?: GraphQLTypes["Audience_Icon_focalX_operator"] | undefined,
	focalY?: GraphQLTypes["Audience_Icon_focalY_operator"] | undefined,
	sizes__small__url?: GraphQLTypes["Audience_Icon_sizes__small__url_operator"] | undefined,
	sizes__small__width?: GraphQLTypes["Audience_Icon_sizes__small__width_operator"] | undefined,
	sizes__small__height?: GraphQLTypes["Audience_Icon_sizes__small__height_operator"] | undefined,
	sizes__small__mimeType?: GraphQLTypes["Audience_Icon_sizes__small__mimeType_operator"] | undefined,
	sizes__small__filesize?: GraphQLTypes["Audience_Icon_sizes__small__filesize_operator"] | undefined,
	sizes__small__filename?: GraphQLTypes["Audience_Icon_sizes__small__filename_operator"] | undefined,
	sizes__medium__url?: GraphQLTypes["Audience_Icon_sizes__medium__url_operator"] | undefined,
	sizes__medium__width?: GraphQLTypes["Audience_Icon_sizes__medium__width_operator"] | undefined,
	sizes__medium__height?: GraphQLTypes["Audience_Icon_sizes__medium__height_operator"] | undefined,
	sizes__medium__mimeType?: GraphQLTypes["Audience_Icon_sizes__medium__mimeType_operator"] | undefined,
	sizes__medium__filesize?: GraphQLTypes["Audience_Icon_sizes__medium__filesize_operator"] | undefined,
	sizes__medium__filename?: GraphQLTypes["Audience_Icon_sizes__medium__filename_operator"] | undefined,
	sizes__large__url?: GraphQLTypes["Audience_Icon_sizes__large__url_operator"] | undefined,
	sizes__large__width?: GraphQLTypes["Audience_Icon_sizes__large__width_operator"] | undefined,
	sizes__large__height?: GraphQLTypes["Audience_Icon_sizes__large__height_operator"] | undefined,
	sizes__large__mimeType?: GraphQLTypes["Audience_Icon_sizes__large__mimeType_operator"] | undefined,
	sizes__large__filesize?: GraphQLTypes["Audience_Icon_sizes__large__filesize_operator"] | undefined,
	sizes__large__filename?: GraphQLTypes["Audience_Icon_sizes__large__filename_operator"] | undefined,
	sizes__small_jpeg__url?: GraphQLTypes["Audience_Icon_sizes__small_jpeg__url_operator"] | undefined,
	sizes__small_jpeg__width?: GraphQLTypes["Audience_Icon_sizes__small_jpeg__width_operator"] | undefined,
	sizes__small_jpeg__height?: GraphQLTypes["Audience_Icon_sizes__small_jpeg__height_operator"] | undefined,
	sizes__small_jpeg__mimeType?: GraphQLTypes["Audience_Icon_sizes__small_jpeg__mimeType_operator"] | undefined,
	sizes__small_jpeg__filesize?: GraphQLTypes["Audience_Icon_sizes__small_jpeg__filesize_operator"] | undefined,
	sizes__small_jpeg__filename?: GraphQLTypes["Audience_Icon_sizes__small_jpeg__filename_operator"] | undefined,
	sizes__medium_jpeg__url?: GraphQLTypes["Audience_Icon_sizes__medium_jpeg__url_operator"] | undefined,
	sizes__medium_jpeg__width?: GraphQLTypes["Audience_Icon_sizes__medium_jpeg__width_operator"] | undefined,
	sizes__medium_jpeg__height?: GraphQLTypes["Audience_Icon_sizes__medium_jpeg__height_operator"] | undefined,
	sizes__medium_jpeg__mimeType?: GraphQLTypes["Audience_Icon_sizes__medium_jpeg__mimeType_operator"] | undefined,
	sizes__medium_jpeg__filesize?: GraphQLTypes["Audience_Icon_sizes__medium_jpeg__filesize_operator"] | undefined,
	sizes__medium_jpeg__filename?: GraphQLTypes["Audience_Icon_sizes__medium_jpeg__filename_operator"] | undefined,
	sizes__large_jpeg__url?: GraphQLTypes["Audience_Icon_sizes__large_jpeg__url_operator"] | undefined,
	sizes__large_jpeg__width?: GraphQLTypes["Audience_Icon_sizes__large_jpeg__width_operator"] | undefined,
	sizes__large_jpeg__height?: GraphQLTypes["Audience_Icon_sizes__large_jpeg__height_operator"] | undefined,
	sizes__large_jpeg__mimeType?: GraphQLTypes["Audience_Icon_sizes__large_jpeg__mimeType_operator"] | undefined,
	sizes__large_jpeg__filesize?: GraphQLTypes["Audience_Icon_sizes__large_jpeg__filesize_operator"] | undefined,
	sizes__large_jpeg__filename?: GraphQLTypes["Audience_Icon_sizes__large_jpeg__filename_operator"] | undefined,
	id?: GraphQLTypes["Audience_Icon_id_operator"] | undefined,
	AND?: Array<GraphQLTypes["Audience_Icon_where_and"] | undefined> | undefined,
	OR?: Array<GraphQLTypes["Audience_Icon_where_or"] | undefined> | undefined
};
	["Audience_Icon_alt_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Audience_Icon_updatedAt_operator"]: {
		equals?: GraphQLTypes["DateTime"] | undefined,
	not_equals?: GraphQLTypes["DateTime"] | undefined,
	greater_than_equal?: GraphQLTypes["DateTime"] | undefined,
	greater_than?: GraphQLTypes["DateTime"] | undefined,
	less_than_equal?: GraphQLTypes["DateTime"] | undefined,
	less_than?: GraphQLTypes["DateTime"] | undefined,
	like?: GraphQLTypes["DateTime"] | undefined,
	exists?: boolean | undefined
};
	["Audience_Icon_createdAt_operator"]: {
		equals?: GraphQLTypes["DateTime"] | undefined,
	not_equals?: GraphQLTypes["DateTime"] | undefined,
	greater_than_equal?: GraphQLTypes["DateTime"] | undefined,
	greater_than?: GraphQLTypes["DateTime"] | undefined,
	less_than_equal?: GraphQLTypes["DateTime"] | undefined,
	less_than?: GraphQLTypes["DateTime"] | undefined,
	like?: GraphQLTypes["DateTime"] | undefined,
	exists?: boolean | undefined
};
	["Audience_Icon_url_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Audience_Icon_filename_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Audience_Icon_mimeType_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Audience_Icon_filesize_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Audience_Icon_width_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Audience_Icon_height_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Audience_Icon_focalX_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Audience_Icon_focalY_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Audience_Icon_sizes__small__url_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Audience_Icon_sizes__small__width_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Audience_Icon_sizes__small__height_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Audience_Icon_sizes__small__mimeType_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Audience_Icon_sizes__small__filesize_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Audience_Icon_sizes__small__filename_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Audience_Icon_sizes__medium__url_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Audience_Icon_sizes__medium__width_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Audience_Icon_sizes__medium__height_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Audience_Icon_sizes__medium__mimeType_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Audience_Icon_sizes__medium__filesize_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Audience_Icon_sizes__medium__filename_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Audience_Icon_sizes__large__url_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Audience_Icon_sizes__large__width_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Audience_Icon_sizes__large__height_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Audience_Icon_sizes__large__mimeType_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Audience_Icon_sizes__large__filesize_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Audience_Icon_sizes__large__filename_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Audience_Icon_sizes__small_jpeg__url_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Audience_Icon_sizes__small_jpeg__width_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Audience_Icon_sizes__small_jpeg__height_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Audience_Icon_sizes__small_jpeg__mimeType_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Audience_Icon_sizes__small_jpeg__filesize_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Audience_Icon_sizes__small_jpeg__filename_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Audience_Icon_sizes__medium_jpeg__url_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Audience_Icon_sizes__medium_jpeg__width_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Audience_Icon_sizes__medium_jpeg__height_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Audience_Icon_sizes__medium_jpeg__mimeType_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Audience_Icon_sizes__medium_jpeg__filesize_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Audience_Icon_sizes__medium_jpeg__filename_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Audience_Icon_sizes__large_jpeg__url_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Audience_Icon_sizes__large_jpeg__width_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Audience_Icon_sizes__large_jpeg__height_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Audience_Icon_sizes__large_jpeg__mimeType_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Audience_Icon_sizes__large_jpeg__filesize_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Audience_Icon_sizes__large_jpeg__filename_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Audience_Icon_id_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Audience_Icon_where_and"]: {
		alt?: GraphQLTypes["Audience_Icon_alt_operator"] | undefined,
	updatedAt?: GraphQLTypes["Audience_Icon_updatedAt_operator"] | undefined,
	createdAt?: GraphQLTypes["Audience_Icon_createdAt_operator"] | undefined,
	url?: GraphQLTypes["Audience_Icon_url_operator"] | undefined,
	filename?: GraphQLTypes["Audience_Icon_filename_operator"] | undefined,
	mimeType?: GraphQLTypes["Audience_Icon_mimeType_operator"] | undefined,
	filesize?: GraphQLTypes["Audience_Icon_filesize_operator"] | undefined,
	width?: GraphQLTypes["Audience_Icon_width_operator"] | undefined,
	height?: GraphQLTypes["Audience_Icon_height_operator"] | undefined,
	focalX?: GraphQLTypes["Audience_Icon_focalX_operator"] | undefined,
	focalY?: GraphQLTypes["Audience_Icon_focalY_operator"] | undefined,
	sizes__small__url?: GraphQLTypes["Audience_Icon_sizes__small__url_operator"] | undefined,
	sizes__small__width?: GraphQLTypes["Audience_Icon_sizes__small__width_operator"] | undefined,
	sizes__small__height?: GraphQLTypes["Audience_Icon_sizes__small__height_operator"] | undefined,
	sizes__small__mimeType?: GraphQLTypes["Audience_Icon_sizes__small__mimeType_operator"] | undefined,
	sizes__small__filesize?: GraphQLTypes["Audience_Icon_sizes__small__filesize_operator"] | undefined,
	sizes__small__filename?: GraphQLTypes["Audience_Icon_sizes__small__filename_operator"] | undefined,
	sizes__medium__url?: GraphQLTypes["Audience_Icon_sizes__medium__url_operator"] | undefined,
	sizes__medium__width?: GraphQLTypes["Audience_Icon_sizes__medium__width_operator"] | undefined,
	sizes__medium__height?: GraphQLTypes["Audience_Icon_sizes__medium__height_operator"] | undefined,
	sizes__medium__mimeType?: GraphQLTypes["Audience_Icon_sizes__medium__mimeType_operator"] | undefined,
	sizes__medium__filesize?: GraphQLTypes["Audience_Icon_sizes__medium__filesize_operator"] | undefined,
	sizes__medium__filename?: GraphQLTypes["Audience_Icon_sizes__medium__filename_operator"] | undefined,
	sizes__large__url?: GraphQLTypes["Audience_Icon_sizes__large__url_operator"] | undefined,
	sizes__large__width?: GraphQLTypes["Audience_Icon_sizes__large__width_operator"] | undefined,
	sizes__large__height?: GraphQLTypes["Audience_Icon_sizes__large__height_operator"] | undefined,
	sizes__large__mimeType?: GraphQLTypes["Audience_Icon_sizes__large__mimeType_operator"] | undefined,
	sizes__large__filesize?: GraphQLTypes["Audience_Icon_sizes__large__filesize_operator"] | undefined,
	sizes__large__filename?: GraphQLTypes["Audience_Icon_sizes__large__filename_operator"] | undefined,
	sizes__small_jpeg__url?: GraphQLTypes["Audience_Icon_sizes__small_jpeg__url_operator"] | undefined,
	sizes__small_jpeg__width?: GraphQLTypes["Audience_Icon_sizes__small_jpeg__width_operator"] | undefined,
	sizes__small_jpeg__height?: GraphQLTypes["Audience_Icon_sizes__small_jpeg__height_operator"] | undefined,
	sizes__small_jpeg__mimeType?: GraphQLTypes["Audience_Icon_sizes__small_jpeg__mimeType_operator"] | undefined,
	sizes__small_jpeg__filesize?: GraphQLTypes["Audience_Icon_sizes__small_jpeg__filesize_operator"] | undefined,
	sizes__small_jpeg__filename?: GraphQLTypes["Audience_Icon_sizes__small_jpeg__filename_operator"] | undefined,
	sizes__medium_jpeg__url?: GraphQLTypes["Audience_Icon_sizes__medium_jpeg__url_operator"] | undefined,
	sizes__medium_jpeg__width?: GraphQLTypes["Audience_Icon_sizes__medium_jpeg__width_operator"] | undefined,
	sizes__medium_jpeg__height?: GraphQLTypes["Audience_Icon_sizes__medium_jpeg__height_operator"] | undefined,
	sizes__medium_jpeg__mimeType?: GraphQLTypes["Audience_Icon_sizes__medium_jpeg__mimeType_operator"] | undefined,
	sizes__medium_jpeg__filesize?: GraphQLTypes["Audience_Icon_sizes__medium_jpeg__filesize_operator"] | undefined,
	sizes__medium_jpeg__filename?: GraphQLTypes["Audience_Icon_sizes__medium_jpeg__filename_operator"] | undefined,
	sizes__large_jpeg__url?: GraphQLTypes["Audience_Icon_sizes__large_jpeg__url_operator"] | undefined,
	sizes__large_jpeg__width?: GraphQLTypes["Audience_Icon_sizes__large_jpeg__width_operator"] | undefined,
	sizes__large_jpeg__height?: GraphQLTypes["Audience_Icon_sizes__large_jpeg__height_operator"] | undefined,
	sizes__large_jpeg__mimeType?: GraphQLTypes["Audience_Icon_sizes__large_jpeg__mimeType_operator"] | undefined,
	sizes__large_jpeg__filesize?: GraphQLTypes["Audience_Icon_sizes__large_jpeg__filesize_operator"] | undefined,
	sizes__large_jpeg__filename?: GraphQLTypes["Audience_Icon_sizes__large_jpeg__filename_operator"] | undefined,
	id?: GraphQLTypes["Audience_Icon_id_operator"] | undefined,
	AND?: Array<GraphQLTypes["Audience_Icon_where_and"] | undefined> | undefined,
	OR?: Array<GraphQLTypes["Audience_Icon_where_or"] | undefined> | undefined
};
	["Audience_Icon_where_or"]: {
		alt?: GraphQLTypes["Audience_Icon_alt_operator"] | undefined,
	updatedAt?: GraphQLTypes["Audience_Icon_updatedAt_operator"] | undefined,
	createdAt?: GraphQLTypes["Audience_Icon_createdAt_operator"] | undefined,
	url?: GraphQLTypes["Audience_Icon_url_operator"] | undefined,
	filename?: GraphQLTypes["Audience_Icon_filename_operator"] | undefined,
	mimeType?: GraphQLTypes["Audience_Icon_mimeType_operator"] | undefined,
	filesize?: GraphQLTypes["Audience_Icon_filesize_operator"] | undefined,
	width?: GraphQLTypes["Audience_Icon_width_operator"] | undefined,
	height?: GraphQLTypes["Audience_Icon_height_operator"] | undefined,
	focalX?: GraphQLTypes["Audience_Icon_focalX_operator"] | undefined,
	focalY?: GraphQLTypes["Audience_Icon_focalY_operator"] | undefined,
	sizes__small__url?: GraphQLTypes["Audience_Icon_sizes__small__url_operator"] | undefined,
	sizes__small__width?: GraphQLTypes["Audience_Icon_sizes__small__width_operator"] | undefined,
	sizes__small__height?: GraphQLTypes["Audience_Icon_sizes__small__height_operator"] | undefined,
	sizes__small__mimeType?: GraphQLTypes["Audience_Icon_sizes__small__mimeType_operator"] | undefined,
	sizes__small__filesize?: GraphQLTypes["Audience_Icon_sizes__small__filesize_operator"] | undefined,
	sizes__small__filename?: GraphQLTypes["Audience_Icon_sizes__small__filename_operator"] | undefined,
	sizes__medium__url?: GraphQLTypes["Audience_Icon_sizes__medium__url_operator"] | undefined,
	sizes__medium__width?: GraphQLTypes["Audience_Icon_sizes__medium__width_operator"] | undefined,
	sizes__medium__height?: GraphQLTypes["Audience_Icon_sizes__medium__height_operator"] | undefined,
	sizes__medium__mimeType?: GraphQLTypes["Audience_Icon_sizes__medium__mimeType_operator"] | undefined,
	sizes__medium__filesize?: GraphQLTypes["Audience_Icon_sizes__medium__filesize_operator"] | undefined,
	sizes__medium__filename?: GraphQLTypes["Audience_Icon_sizes__medium__filename_operator"] | undefined,
	sizes__large__url?: GraphQLTypes["Audience_Icon_sizes__large__url_operator"] | undefined,
	sizes__large__width?: GraphQLTypes["Audience_Icon_sizes__large__width_operator"] | undefined,
	sizes__large__height?: GraphQLTypes["Audience_Icon_sizes__large__height_operator"] | undefined,
	sizes__large__mimeType?: GraphQLTypes["Audience_Icon_sizes__large__mimeType_operator"] | undefined,
	sizes__large__filesize?: GraphQLTypes["Audience_Icon_sizes__large__filesize_operator"] | undefined,
	sizes__large__filename?: GraphQLTypes["Audience_Icon_sizes__large__filename_operator"] | undefined,
	sizes__small_jpeg__url?: GraphQLTypes["Audience_Icon_sizes__small_jpeg__url_operator"] | undefined,
	sizes__small_jpeg__width?: GraphQLTypes["Audience_Icon_sizes__small_jpeg__width_operator"] | undefined,
	sizes__small_jpeg__height?: GraphQLTypes["Audience_Icon_sizes__small_jpeg__height_operator"] | undefined,
	sizes__small_jpeg__mimeType?: GraphQLTypes["Audience_Icon_sizes__small_jpeg__mimeType_operator"] | undefined,
	sizes__small_jpeg__filesize?: GraphQLTypes["Audience_Icon_sizes__small_jpeg__filesize_operator"] | undefined,
	sizes__small_jpeg__filename?: GraphQLTypes["Audience_Icon_sizes__small_jpeg__filename_operator"] | undefined,
	sizes__medium_jpeg__url?: GraphQLTypes["Audience_Icon_sizes__medium_jpeg__url_operator"] | undefined,
	sizes__medium_jpeg__width?: GraphQLTypes["Audience_Icon_sizes__medium_jpeg__width_operator"] | undefined,
	sizes__medium_jpeg__height?: GraphQLTypes["Audience_Icon_sizes__medium_jpeg__height_operator"] | undefined,
	sizes__medium_jpeg__mimeType?: GraphQLTypes["Audience_Icon_sizes__medium_jpeg__mimeType_operator"] | undefined,
	sizes__medium_jpeg__filesize?: GraphQLTypes["Audience_Icon_sizes__medium_jpeg__filesize_operator"] | undefined,
	sizes__medium_jpeg__filename?: GraphQLTypes["Audience_Icon_sizes__medium_jpeg__filename_operator"] | undefined,
	sizes__large_jpeg__url?: GraphQLTypes["Audience_Icon_sizes__large_jpeg__url_operator"] | undefined,
	sizes__large_jpeg__width?: GraphQLTypes["Audience_Icon_sizes__large_jpeg__width_operator"] | undefined,
	sizes__large_jpeg__height?: GraphQLTypes["Audience_Icon_sizes__large_jpeg__height_operator"] | undefined,
	sizes__large_jpeg__mimeType?: GraphQLTypes["Audience_Icon_sizes__large_jpeg__mimeType_operator"] | undefined,
	sizes__large_jpeg__filesize?: GraphQLTypes["Audience_Icon_sizes__large_jpeg__filesize_operator"] | undefined,
	sizes__large_jpeg__filename?: GraphQLTypes["Audience_Icon_sizes__large_jpeg__filename_operator"] | undefined,
	id?: GraphQLTypes["Audience_Icon_id_operator"] | undefined,
	AND?: Array<GraphQLTypes["Audience_Icon_where_and"] | undefined> | undefined,
	OR?: Array<GraphQLTypes["Audience_Icon_where_or"] | undefined> | undefined
};
	["Audience_region"]: Audience_region;
	["Date"]: {
	__typename: "Date",
	id?: string | undefined,
	name?: string | undefined,
	value: string,
	type: GraphQLTypes["Date_type"],
	start?: GraphQLTypes["DateTime"] | undefined,
	end?: GraphQLTypes["DateTime"] | undefined,
	_title?: string | undefined,
	region?: GraphQLTypes["Date_region"] | undefined,
	updatedAt?: GraphQLTypes["DateTime"] | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined
};
	["Date_type"]: Date_type;
	["Date_region"]: Date_region;
	["Location_Sections"]: {
	__typename: "Location_Sections",
	startDatetime?: GraphQLTypes["DateTime"] | undefined,
	endDatetime?: GraphQLTypes["DateTime"] | undefined,
	fullDay?: boolean | undefined,
	repeat?: boolean | undefined,
	recurrance?: GraphQLTypes["Location_Sections_Recurrance"] | undefined,
	id?: string | undefined
};
	["Location_Sections_Recurrance"]: {
	__typename: "Location_Sections_Recurrance",
	type?: GraphQLTypes["Location_Sections_Recurrance_type"] | undefined,
	weekday?: Array<GraphQLTypes["Location_Sections_Recurrance_weekday"]> | undefined
};
	["Location_Sections_Recurrance_type"]: Location_Sections_Recurrance_type;
	["Location_Sections_Recurrance_weekday"]: Location_Sections_Recurrance_weekday;
	["Event_Ticketing"]: {
	__typename: "Event_Ticketing",
	startDatetime?: GraphQLTypes["DateTime"] | undefined,
	endDatetime?: GraphQLTypes["DateTime"] | undefined,
	fullDay?: boolean | undefined,
	type?: GraphQLTypes["Event_Ticketing_type"] | undefined,
	ticketingType?: GraphQLTypes["Event_Ticketing_ticketingType"] | undefined,
	linkedTicketing?: GraphQLTypes["Ticketing"] | undefined,
	promotionType?: GraphQLTypes["Event_Ticketing_promotionType"] | undefined,
	location?: GraphQLTypes["Location"] | undefined,
	website?: string | undefined,
	description?: string | undefined,
	id?: string | undefined
};
	["Event_Ticketing_type"]: Event_Ticketing_type;
	["Event_Ticketing_ticketingType"]: Event_Ticketing_ticketingType;
	["Ticketing"]: {
	__typename: "Ticketing",
	id?: string | undefined,
	status?: GraphQLTypes["Ticketing_status"] | undefined,
	creator?: GraphQLTypes["User"] | undefined,
	productType: GraphQLTypes["Ticketing_productType"],
	productName?: string | undefined,
	referenceId?: string | undefined,
	linkedEvent?: GraphQLTypes["Event"] | undefined,
	CheckInLevel: GraphQLTypes["Ticketing_CheckInLevel"],
	displayPrice: string,
	tnc?: string | undefined,
	cutoffHours: number,
	sessions?: Array<GraphQLTypes["TicketingVariants"]> | undefined,
	updatedAt?: GraphQLTypes["DateTime"] | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined
};
	["Ticketing_status"]: Ticketing_status;
	["Ticketing_productType"]: Ticketing_productType;
	["Ticketing_CheckInLevel"]: Ticketing_CheckInLevel;
	["TicketingVariants"]: {
	__typename: "TicketingVariants",
	variantId?: string | undefined,
	productId?: string | undefined,
	startDate?: GraphQLTypes["DateTime"] | undefined,
	endDate?: GraphQLTypes["DateTime"] | undefined,
	inventories?: GraphQLTypes["Inventories"] | undefined,
	id?: string | undefined
};
	["Inventories"]: {
	__typename: "Inventories",
	name?: string | undefined,
	quantity?: number | undefined,
	unitPrice?: number | undefined,
	handlingFee?: number | undefined,
	totalCost?: number | undefined,
	minPurchase?: number | undefined,
	maxPurchase?: number | undefined,
	stocks?: GraphQLTypes["Inventories_Stocks"] | undefined,
	remarks?: string | undefined
};
	["Inventories_Stocks"]: {
	__typename: "Inventories_Stocks",
	stockId?: string | undefined,
	remaining?: number | undefined,
	sold?: number | undefined,
	onHold?: number | undefined,
	checkedIn?: number | undefined
};
	["Event_Ticketing_promotionType"]: Event_Ticketing_promotionType;
	["Event_Content"]:{
        	__typename:"RichText" | "Gallery" | "Image" | "Video"
        	['...on RichText']: '__union' & GraphQLTypes["RichText"];
	['...on Gallery']: '__union' & GraphQLTypes["Gallery"];
	['...on Image']: '__union' & GraphQLTypes["Image"];
	['...on Video']: '__union' & GraphQLTypes["Video"];
};
	["Event_Criteria"]: {
	__typename: "Event_Criteria",
	categories?: Array<GraphQLTypes["EventCategory"]> | undefined,
	audiences?: Array<GraphQLTypes["Audience"]> | undefined,
	tags?: Array<GraphQLTypes["Tag"]> | undefined,
	specialTags?: Array<GraphQLTypes["SpecialTag"]> | undefined
};
	["SpecialTag"]: {
	__typename: "SpecialTag",
	id?: string | undefined,
	name: string,
	value: string,
	region?: GraphQLTypes["SpecialTag_region"] | undefined
};
	["SpecialTag_region"]: SpecialTag_region;
	["Event_Contact"]: {
	__typename: "Event_Contact",
	code?: number | undefined,
	phone?: string | undefined,
	page?: string | undefined,
	email?: GraphQLTypes["EmailAddress"] | undefined,
	privacy?: GraphQLTypes["JSON"] | undefined
};
	["Event_Fees"]: {
	__typename: "Event_Fees",
	fee?: number | undefined,
	remark?: string | undefined,
	id?: string | undefined
};
	["Event_Products"]: {
	__typename: "Event_Products",
	startDateTime?: GraphQLTypes["DateTime"] | undefined,
	endDateTime?: GraphQLTypes["DateTime"] | undefined,
	fullDay?: boolean | undefined,
	linkedProduct?: GraphQLTypes["Product"] | undefined,
	id?: string | undefined
};
	["Product"]: {
	__typename: "Product",
	id?: string | undefined,
	status?: GraphQLTypes["Product_status"] | undefined,
	creator?: GraphQLTypes["User"] | undefined,
	productType: GraphQLTypes["Product_productType"],
	productName?: string | undefined,
	referenceId?: string | undefined,
	linkedEvent: GraphQLTypes["Event"],
	CheckInLevel: GraphQLTypes["Product_CheckInLevel"],
	thumbnail: GraphQLTypes["Media"],
	displayPrice: string,
	transactionType: GraphQLTypes["Product_transactionType"],
	website?: string | undefined,
	tnc?: string | undefined,
	remarks?: string | undefined,
	sessions?: Array<GraphQLTypes["ProductVariants"]> | undefined,
	updatedAt?: GraphQLTypes["DateTime"] | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined
};
	["Product_status"]: Product_status;
	["Product_productType"]: Product_productType;
	["Product_CheckInLevel"]: Product_CheckInLevel;
	["Product_Thumbnail_where"]: {
		alt?: GraphQLTypes["Product_Thumbnail_alt_operator"] | undefined,
	updatedAt?: GraphQLTypes["Product_Thumbnail_updatedAt_operator"] | undefined,
	createdAt?: GraphQLTypes["Product_Thumbnail_createdAt_operator"] | undefined,
	url?: GraphQLTypes["Product_Thumbnail_url_operator"] | undefined,
	filename?: GraphQLTypes["Product_Thumbnail_filename_operator"] | undefined,
	mimeType?: GraphQLTypes["Product_Thumbnail_mimeType_operator"] | undefined,
	filesize?: GraphQLTypes["Product_Thumbnail_filesize_operator"] | undefined,
	width?: GraphQLTypes["Product_Thumbnail_width_operator"] | undefined,
	height?: GraphQLTypes["Product_Thumbnail_height_operator"] | undefined,
	focalX?: GraphQLTypes["Product_Thumbnail_focalX_operator"] | undefined,
	focalY?: GraphQLTypes["Product_Thumbnail_focalY_operator"] | undefined,
	sizes__small__url?: GraphQLTypes["Product_Thumbnail_sizes__small__url_operator"] | undefined,
	sizes__small__width?: GraphQLTypes["Product_Thumbnail_sizes__small__width_operator"] | undefined,
	sizes__small__height?: GraphQLTypes["Product_Thumbnail_sizes__small__height_operator"] | undefined,
	sizes__small__mimeType?: GraphQLTypes["Product_Thumbnail_sizes__small__mimeType_operator"] | undefined,
	sizes__small__filesize?: GraphQLTypes["Product_Thumbnail_sizes__small__filesize_operator"] | undefined,
	sizes__small__filename?: GraphQLTypes["Product_Thumbnail_sizes__small__filename_operator"] | undefined,
	sizes__medium__url?: GraphQLTypes["Product_Thumbnail_sizes__medium__url_operator"] | undefined,
	sizes__medium__width?: GraphQLTypes["Product_Thumbnail_sizes__medium__width_operator"] | undefined,
	sizes__medium__height?: GraphQLTypes["Product_Thumbnail_sizes__medium__height_operator"] | undefined,
	sizes__medium__mimeType?: GraphQLTypes["Product_Thumbnail_sizes__medium__mimeType_operator"] | undefined,
	sizes__medium__filesize?: GraphQLTypes["Product_Thumbnail_sizes__medium__filesize_operator"] | undefined,
	sizes__medium__filename?: GraphQLTypes["Product_Thumbnail_sizes__medium__filename_operator"] | undefined,
	sizes__large__url?: GraphQLTypes["Product_Thumbnail_sizes__large__url_operator"] | undefined,
	sizes__large__width?: GraphQLTypes["Product_Thumbnail_sizes__large__width_operator"] | undefined,
	sizes__large__height?: GraphQLTypes["Product_Thumbnail_sizes__large__height_operator"] | undefined,
	sizes__large__mimeType?: GraphQLTypes["Product_Thumbnail_sizes__large__mimeType_operator"] | undefined,
	sizes__large__filesize?: GraphQLTypes["Product_Thumbnail_sizes__large__filesize_operator"] | undefined,
	sizes__large__filename?: GraphQLTypes["Product_Thumbnail_sizes__large__filename_operator"] | undefined,
	sizes__small_jpeg__url?: GraphQLTypes["Product_Thumbnail_sizes__small_jpeg__url_operator"] | undefined,
	sizes__small_jpeg__width?: GraphQLTypes["Product_Thumbnail_sizes__small_jpeg__width_operator"] | undefined,
	sizes__small_jpeg__height?: GraphQLTypes["Product_Thumbnail_sizes__small_jpeg__height_operator"] | undefined,
	sizes__small_jpeg__mimeType?: GraphQLTypes["Product_Thumbnail_sizes__small_jpeg__mimeType_operator"] | undefined,
	sizes__small_jpeg__filesize?: GraphQLTypes["Product_Thumbnail_sizes__small_jpeg__filesize_operator"] | undefined,
	sizes__small_jpeg__filename?: GraphQLTypes["Product_Thumbnail_sizes__small_jpeg__filename_operator"] | undefined,
	sizes__medium_jpeg__url?: GraphQLTypes["Product_Thumbnail_sizes__medium_jpeg__url_operator"] | undefined,
	sizes__medium_jpeg__width?: GraphQLTypes["Product_Thumbnail_sizes__medium_jpeg__width_operator"] | undefined,
	sizes__medium_jpeg__height?: GraphQLTypes["Product_Thumbnail_sizes__medium_jpeg__height_operator"] | undefined,
	sizes__medium_jpeg__mimeType?: GraphQLTypes["Product_Thumbnail_sizes__medium_jpeg__mimeType_operator"] | undefined,
	sizes__medium_jpeg__filesize?: GraphQLTypes["Product_Thumbnail_sizes__medium_jpeg__filesize_operator"] | undefined,
	sizes__medium_jpeg__filename?: GraphQLTypes["Product_Thumbnail_sizes__medium_jpeg__filename_operator"] | undefined,
	sizes__large_jpeg__url?: GraphQLTypes["Product_Thumbnail_sizes__large_jpeg__url_operator"] | undefined,
	sizes__large_jpeg__width?: GraphQLTypes["Product_Thumbnail_sizes__large_jpeg__width_operator"] | undefined,
	sizes__large_jpeg__height?: GraphQLTypes["Product_Thumbnail_sizes__large_jpeg__height_operator"] | undefined,
	sizes__large_jpeg__mimeType?: GraphQLTypes["Product_Thumbnail_sizes__large_jpeg__mimeType_operator"] | undefined,
	sizes__large_jpeg__filesize?: GraphQLTypes["Product_Thumbnail_sizes__large_jpeg__filesize_operator"] | undefined,
	sizes__large_jpeg__filename?: GraphQLTypes["Product_Thumbnail_sizes__large_jpeg__filename_operator"] | undefined,
	id?: GraphQLTypes["Product_Thumbnail_id_operator"] | undefined,
	AND?: Array<GraphQLTypes["Product_Thumbnail_where_and"] | undefined> | undefined,
	OR?: Array<GraphQLTypes["Product_Thumbnail_where_or"] | undefined> | undefined
};
	["Product_Thumbnail_alt_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Product_Thumbnail_updatedAt_operator"]: {
		equals?: GraphQLTypes["DateTime"] | undefined,
	not_equals?: GraphQLTypes["DateTime"] | undefined,
	greater_than_equal?: GraphQLTypes["DateTime"] | undefined,
	greater_than?: GraphQLTypes["DateTime"] | undefined,
	less_than_equal?: GraphQLTypes["DateTime"] | undefined,
	less_than?: GraphQLTypes["DateTime"] | undefined,
	like?: GraphQLTypes["DateTime"] | undefined,
	exists?: boolean | undefined
};
	["Product_Thumbnail_createdAt_operator"]: {
		equals?: GraphQLTypes["DateTime"] | undefined,
	not_equals?: GraphQLTypes["DateTime"] | undefined,
	greater_than_equal?: GraphQLTypes["DateTime"] | undefined,
	greater_than?: GraphQLTypes["DateTime"] | undefined,
	less_than_equal?: GraphQLTypes["DateTime"] | undefined,
	less_than?: GraphQLTypes["DateTime"] | undefined,
	like?: GraphQLTypes["DateTime"] | undefined,
	exists?: boolean | undefined
};
	["Product_Thumbnail_url_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Product_Thumbnail_filename_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Product_Thumbnail_mimeType_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Product_Thumbnail_filesize_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Product_Thumbnail_width_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Product_Thumbnail_height_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Product_Thumbnail_focalX_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Product_Thumbnail_focalY_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Product_Thumbnail_sizes__small__url_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Product_Thumbnail_sizes__small__width_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Product_Thumbnail_sizes__small__height_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Product_Thumbnail_sizes__small__mimeType_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Product_Thumbnail_sizes__small__filesize_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Product_Thumbnail_sizes__small__filename_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Product_Thumbnail_sizes__medium__url_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Product_Thumbnail_sizes__medium__width_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Product_Thumbnail_sizes__medium__height_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Product_Thumbnail_sizes__medium__mimeType_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Product_Thumbnail_sizes__medium__filesize_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Product_Thumbnail_sizes__medium__filename_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Product_Thumbnail_sizes__large__url_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Product_Thumbnail_sizes__large__width_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Product_Thumbnail_sizes__large__height_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Product_Thumbnail_sizes__large__mimeType_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Product_Thumbnail_sizes__large__filesize_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Product_Thumbnail_sizes__large__filename_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Product_Thumbnail_sizes__small_jpeg__url_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Product_Thumbnail_sizes__small_jpeg__width_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Product_Thumbnail_sizes__small_jpeg__height_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Product_Thumbnail_sizes__small_jpeg__mimeType_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Product_Thumbnail_sizes__small_jpeg__filesize_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Product_Thumbnail_sizes__small_jpeg__filename_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Product_Thumbnail_sizes__medium_jpeg__url_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Product_Thumbnail_sizes__medium_jpeg__width_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Product_Thumbnail_sizes__medium_jpeg__height_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Product_Thumbnail_sizes__medium_jpeg__mimeType_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Product_Thumbnail_sizes__medium_jpeg__filesize_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Product_Thumbnail_sizes__medium_jpeg__filename_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Product_Thumbnail_sizes__large_jpeg__url_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Product_Thumbnail_sizes__large_jpeg__width_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Product_Thumbnail_sizes__large_jpeg__height_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Product_Thumbnail_sizes__large_jpeg__mimeType_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Product_Thumbnail_sizes__large_jpeg__filesize_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Product_Thumbnail_sizes__large_jpeg__filename_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Product_Thumbnail_id_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Product_Thumbnail_where_and"]: {
		alt?: GraphQLTypes["Product_Thumbnail_alt_operator"] | undefined,
	updatedAt?: GraphQLTypes["Product_Thumbnail_updatedAt_operator"] | undefined,
	createdAt?: GraphQLTypes["Product_Thumbnail_createdAt_operator"] | undefined,
	url?: GraphQLTypes["Product_Thumbnail_url_operator"] | undefined,
	filename?: GraphQLTypes["Product_Thumbnail_filename_operator"] | undefined,
	mimeType?: GraphQLTypes["Product_Thumbnail_mimeType_operator"] | undefined,
	filesize?: GraphQLTypes["Product_Thumbnail_filesize_operator"] | undefined,
	width?: GraphQLTypes["Product_Thumbnail_width_operator"] | undefined,
	height?: GraphQLTypes["Product_Thumbnail_height_operator"] | undefined,
	focalX?: GraphQLTypes["Product_Thumbnail_focalX_operator"] | undefined,
	focalY?: GraphQLTypes["Product_Thumbnail_focalY_operator"] | undefined,
	sizes__small__url?: GraphQLTypes["Product_Thumbnail_sizes__small__url_operator"] | undefined,
	sizes__small__width?: GraphQLTypes["Product_Thumbnail_sizes__small__width_operator"] | undefined,
	sizes__small__height?: GraphQLTypes["Product_Thumbnail_sizes__small__height_operator"] | undefined,
	sizes__small__mimeType?: GraphQLTypes["Product_Thumbnail_sizes__small__mimeType_operator"] | undefined,
	sizes__small__filesize?: GraphQLTypes["Product_Thumbnail_sizes__small__filesize_operator"] | undefined,
	sizes__small__filename?: GraphQLTypes["Product_Thumbnail_sizes__small__filename_operator"] | undefined,
	sizes__medium__url?: GraphQLTypes["Product_Thumbnail_sizes__medium__url_operator"] | undefined,
	sizes__medium__width?: GraphQLTypes["Product_Thumbnail_sizes__medium__width_operator"] | undefined,
	sizes__medium__height?: GraphQLTypes["Product_Thumbnail_sizes__medium__height_operator"] | undefined,
	sizes__medium__mimeType?: GraphQLTypes["Product_Thumbnail_sizes__medium__mimeType_operator"] | undefined,
	sizes__medium__filesize?: GraphQLTypes["Product_Thumbnail_sizes__medium__filesize_operator"] | undefined,
	sizes__medium__filename?: GraphQLTypes["Product_Thumbnail_sizes__medium__filename_operator"] | undefined,
	sizes__large__url?: GraphQLTypes["Product_Thumbnail_sizes__large__url_operator"] | undefined,
	sizes__large__width?: GraphQLTypes["Product_Thumbnail_sizes__large__width_operator"] | undefined,
	sizes__large__height?: GraphQLTypes["Product_Thumbnail_sizes__large__height_operator"] | undefined,
	sizes__large__mimeType?: GraphQLTypes["Product_Thumbnail_sizes__large__mimeType_operator"] | undefined,
	sizes__large__filesize?: GraphQLTypes["Product_Thumbnail_sizes__large__filesize_operator"] | undefined,
	sizes__large__filename?: GraphQLTypes["Product_Thumbnail_sizes__large__filename_operator"] | undefined,
	sizes__small_jpeg__url?: GraphQLTypes["Product_Thumbnail_sizes__small_jpeg__url_operator"] | undefined,
	sizes__small_jpeg__width?: GraphQLTypes["Product_Thumbnail_sizes__small_jpeg__width_operator"] | undefined,
	sizes__small_jpeg__height?: GraphQLTypes["Product_Thumbnail_sizes__small_jpeg__height_operator"] | undefined,
	sizes__small_jpeg__mimeType?: GraphQLTypes["Product_Thumbnail_sizes__small_jpeg__mimeType_operator"] | undefined,
	sizes__small_jpeg__filesize?: GraphQLTypes["Product_Thumbnail_sizes__small_jpeg__filesize_operator"] | undefined,
	sizes__small_jpeg__filename?: GraphQLTypes["Product_Thumbnail_sizes__small_jpeg__filename_operator"] | undefined,
	sizes__medium_jpeg__url?: GraphQLTypes["Product_Thumbnail_sizes__medium_jpeg__url_operator"] | undefined,
	sizes__medium_jpeg__width?: GraphQLTypes["Product_Thumbnail_sizes__medium_jpeg__width_operator"] | undefined,
	sizes__medium_jpeg__height?: GraphQLTypes["Product_Thumbnail_sizes__medium_jpeg__height_operator"] | undefined,
	sizes__medium_jpeg__mimeType?: GraphQLTypes["Product_Thumbnail_sizes__medium_jpeg__mimeType_operator"] | undefined,
	sizes__medium_jpeg__filesize?: GraphQLTypes["Product_Thumbnail_sizes__medium_jpeg__filesize_operator"] | undefined,
	sizes__medium_jpeg__filename?: GraphQLTypes["Product_Thumbnail_sizes__medium_jpeg__filename_operator"] | undefined,
	sizes__large_jpeg__url?: GraphQLTypes["Product_Thumbnail_sizes__large_jpeg__url_operator"] | undefined,
	sizes__large_jpeg__width?: GraphQLTypes["Product_Thumbnail_sizes__large_jpeg__width_operator"] | undefined,
	sizes__large_jpeg__height?: GraphQLTypes["Product_Thumbnail_sizes__large_jpeg__height_operator"] | undefined,
	sizes__large_jpeg__mimeType?: GraphQLTypes["Product_Thumbnail_sizes__large_jpeg__mimeType_operator"] | undefined,
	sizes__large_jpeg__filesize?: GraphQLTypes["Product_Thumbnail_sizes__large_jpeg__filesize_operator"] | undefined,
	sizes__large_jpeg__filename?: GraphQLTypes["Product_Thumbnail_sizes__large_jpeg__filename_operator"] | undefined,
	id?: GraphQLTypes["Product_Thumbnail_id_operator"] | undefined,
	AND?: Array<GraphQLTypes["Product_Thumbnail_where_and"] | undefined> | undefined,
	OR?: Array<GraphQLTypes["Product_Thumbnail_where_or"] | undefined> | undefined
};
	["Product_Thumbnail_where_or"]: {
		alt?: GraphQLTypes["Product_Thumbnail_alt_operator"] | undefined,
	updatedAt?: GraphQLTypes["Product_Thumbnail_updatedAt_operator"] | undefined,
	createdAt?: GraphQLTypes["Product_Thumbnail_createdAt_operator"] | undefined,
	url?: GraphQLTypes["Product_Thumbnail_url_operator"] | undefined,
	filename?: GraphQLTypes["Product_Thumbnail_filename_operator"] | undefined,
	mimeType?: GraphQLTypes["Product_Thumbnail_mimeType_operator"] | undefined,
	filesize?: GraphQLTypes["Product_Thumbnail_filesize_operator"] | undefined,
	width?: GraphQLTypes["Product_Thumbnail_width_operator"] | undefined,
	height?: GraphQLTypes["Product_Thumbnail_height_operator"] | undefined,
	focalX?: GraphQLTypes["Product_Thumbnail_focalX_operator"] | undefined,
	focalY?: GraphQLTypes["Product_Thumbnail_focalY_operator"] | undefined,
	sizes__small__url?: GraphQLTypes["Product_Thumbnail_sizes__small__url_operator"] | undefined,
	sizes__small__width?: GraphQLTypes["Product_Thumbnail_sizes__small__width_operator"] | undefined,
	sizes__small__height?: GraphQLTypes["Product_Thumbnail_sizes__small__height_operator"] | undefined,
	sizes__small__mimeType?: GraphQLTypes["Product_Thumbnail_sizes__small__mimeType_operator"] | undefined,
	sizes__small__filesize?: GraphQLTypes["Product_Thumbnail_sizes__small__filesize_operator"] | undefined,
	sizes__small__filename?: GraphQLTypes["Product_Thumbnail_sizes__small__filename_operator"] | undefined,
	sizes__medium__url?: GraphQLTypes["Product_Thumbnail_sizes__medium__url_operator"] | undefined,
	sizes__medium__width?: GraphQLTypes["Product_Thumbnail_sizes__medium__width_operator"] | undefined,
	sizes__medium__height?: GraphQLTypes["Product_Thumbnail_sizes__medium__height_operator"] | undefined,
	sizes__medium__mimeType?: GraphQLTypes["Product_Thumbnail_sizes__medium__mimeType_operator"] | undefined,
	sizes__medium__filesize?: GraphQLTypes["Product_Thumbnail_sizes__medium__filesize_operator"] | undefined,
	sizes__medium__filename?: GraphQLTypes["Product_Thumbnail_sizes__medium__filename_operator"] | undefined,
	sizes__large__url?: GraphQLTypes["Product_Thumbnail_sizes__large__url_operator"] | undefined,
	sizes__large__width?: GraphQLTypes["Product_Thumbnail_sizes__large__width_operator"] | undefined,
	sizes__large__height?: GraphQLTypes["Product_Thumbnail_sizes__large__height_operator"] | undefined,
	sizes__large__mimeType?: GraphQLTypes["Product_Thumbnail_sizes__large__mimeType_operator"] | undefined,
	sizes__large__filesize?: GraphQLTypes["Product_Thumbnail_sizes__large__filesize_operator"] | undefined,
	sizes__large__filename?: GraphQLTypes["Product_Thumbnail_sizes__large__filename_operator"] | undefined,
	sizes__small_jpeg__url?: GraphQLTypes["Product_Thumbnail_sizes__small_jpeg__url_operator"] | undefined,
	sizes__small_jpeg__width?: GraphQLTypes["Product_Thumbnail_sizes__small_jpeg__width_operator"] | undefined,
	sizes__small_jpeg__height?: GraphQLTypes["Product_Thumbnail_sizes__small_jpeg__height_operator"] | undefined,
	sizes__small_jpeg__mimeType?: GraphQLTypes["Product_Thumbnail_sizes__small_jpeg__mimeType_operator"] | undefined,
	sizes__small_jpeg__filesize?: GraphQLTypes["Product_Thumbnail_sizes__small_jpeg__filesize_operator"] | undefined,
	sizes__small_jpeg__filename?: GraphQLTypes["Product_Thumbnail_sizes__small_jpeg__filename_operator"] | undefined,
	sizes__medium_jpeg__url?: GraphQLTypes["Product_Thumbnail_sizes__medium_jpeg__url_operator"] | undefined,
	sizes__medium_jpeg__width?: GraphQLTypes["Product_Thumbnail_sizes__medium_jpeg__width_operator"] | undefined,
	sizes__medium_jpeg__height?: GraphQLTypes["Product_Thumbnail_sizes__medium_jpeg__height_operator"] | undefined,
	sizes__medium_jpeg__mimeType?: GraphQLTypes["Product_Thumbnail_sizes__medium_jpeg__mimeType_operator"] | undefined,
	sizes__medium_jpeg__filesize?: GraphQLTypes["Product_Thumbnail_sizes__medium_jpeg__filesize_operator"] | undefined,
	sizes__medium_jpeg__filename?: GraphQLTypes["Product_Thumbnail_sizes__medium_jpeg__filename_operator"] | undefined,
	sizes__large_jpeg__url?: GraphQLTypes["Product_Thumbnail_sizes__large_jpeg__url_operator"] | undefined,
	sizes__large_jpeg__width?: GraphQLTypes["Product_Thumbnail_sizes__large_jpeg__width_operator"] | undefined,
	sizes__large_jpeg__height?: GraphQLTypes["Product_Thumbnail_sizes__large_jpeg__height_operator"] | undefined,
	sizes__large_jpeg__mimeType?: GraphQLTypes["Product_Thumbnail_sizes__large_jpeg__mimeType_operator"] | undefined,
	sizes__large_jpeg__filesize?: GraphQLTypes["Product_Thumbnail_sizes__large_jpeg__filesize_operator"] | undefined,
	sizes__large_jpeg__filename?: GraphQLTypes["Product_Thumbnail_sizes__large_jpeg__filename_operator"] | undefined,
	id?: GraphQLTypes["Product_Thumbnail_id_operator"] | undefined,
	AND?: Array<GraphQLTypes["Product_Thumbnail_where_and"] | undefined> | undefined,
	OR?: Array<GraphQLTypes["Product_Thumbnail_where_or"] | undefined> | undefined
};
	["Product_transactionType"]: Product_transactionType;
	["ProductVariants"]: {
	__typename: "ProductVariants",
	variantId?: string | undefined,
	productId?: string | undefined,
	name?: string | undefined,
	inventories?: GraphQLTypes["Inventories"] | undefined,
	id?: string | undefined
};
	["Events"]: {
	__typename: "Events",
	docs?: Array<GraphQLTypes["Event"] | undefined> | undefined,
	hasNextPage?: boolean | undefined,
	hasPrevPage?: boolean | undefined,
	limit?: number | undefined,
	nextPage?: number | undefined,
	offset?: number | undefined,
	page?: number | undefined,
	pagingCounter?: number | undefined,
	prevPage?: number | undefined,
	totalDocs?: number | undefined,
	totalPages?: number | undefined
};
	["Event_where"]: {
		region?: GraphQLTypes["Event_region_operator"] | undefined,
	creator?: GraphQLTypes["Event_creator_operator"] | undefined,
	permalink?: GraphQLTypes["Event_permalink_operator"] | undefined,
	status?: GraphQLTypes["Event_status_operator"] | undefined,
	level?: GraphQLTypes["Event_level_operator"] | undefined,
	parent?: GraphQLTypes["Event_parent_operator"] | undefined,
	name?: GraphQLTypes["Event_name_operator"] | undefined,
	chatbotEventTitle?: GraphQLTypes["Event_chatbotEventTitle_operator"] | undefined,
	chatbotSequence?: GraphQLTypes["Event_chatbotSequence_operator"] | undefined,
	thumbnail?: GraphQLTypes["Event_thumbnail_operator"] | undefined,
	banner?: GraphQLTypes["Event_banner_operator"] | undefined,
	mobileBanner?: GraphQLTypes["Event_mobileBanner_operator"] | undefined,
	sections__startDatetime?: GraphQLTypes["Event_sections__startDatetime_operator"] | undefined,
	sections__endDatetime?: GraphQLTypes["Event_sections__endDatetime_operator"] | undefined,
	sections__fullDay?: GraphQLTypes["Event_sections__fullDay_operator"] | undefined,
	sections__repeat?: GraphQLTypes["Event_sections__repeat_operator"] | undefined,
	sections__toThisDay?: GraphQLTypes["Event_sections__toThisDay_operator"] | undefined,
	sections__recurrance__type?: GraphQLTypes["Event_sections__recurrance__type_operator"] | undefined,
	sections__recurrance__weekday?: GraphQLTypes["Event_sections__recurrance__weekday_operator"] | undefined,
	sections__sameLocation?: GraphQLTypes["Event_sections__sameLocation_operator"] | undefined,
	sections__location?: GraphQLTypes["Event_sections__location_operator"] | undefined,
	sections__district?: GraphQLTypes["Event_sections__district_operator"] | undefined,
	sections__coordinate?: GraphQLTypes["Event_sections__coordinate_operator"] | undefined,
	sections__linkAddress?: GraphQLTypes["Event_sections__linkAddress_operator"] | undefined,
	sections__address?: GraphQLTypes["Event_sections__address_operator"] | undefined,
	sections__addressCoordinate?: GraphQLTypes["Event_sections__addressCoordinate_operator"] | undefined,
	sections__id?: GraphQLTypes["Event_sections__id_operator"] | undefined,
	ticketing__startDatetime?: GraphQLTypes["Event_ticketing__startDatetime_operator"] | undefined,
	ticketing__endDatetime?: GraphQLTypes["Event_ticketing__endDatetime_operator"] | undefined,
	ticketing__fullDay?: GraphQLTypes["Event_ticketing__fullDay_operator"] | undefined,
	ticketing__type?: GraphQLTypes["Event_ticketing__type_operator"] | undefined,
	ticketing__ticketingType?: GraphQLTypes["Event_ticketing__ticketingType_operator"] | undefined,
	ticketing__linkedTicketing?: GraphQLTypes["Event_ticketing__linkedTicketing_operator"] | undefined,
	ticketing__promotionType?: GraphQLTypes["Event_ticketing__promotionType_operator"] | undefined,
	ticketing__location?: GraphQLTypes["Event_ticketing__location_operator"] | undefined,
	ticketing__website?: GraphQLTypes["Event_ticketing__website_operator"] | undefined,
	ticketing__description?: GraphQLTypes["Event_ticketing__description_operator"] | undefined,
	ticketing__id?: GraphQLTypes["Event_ticketing__id_operator"] | undefined,
	legacyContent?: GraphQLTypes["Event_legacyContent_operator"] | undefined,
	showLegacyContent?: GraphQLTypes["Event_showLegacyContent_operator"] | undefined,
	criteria__categories?: GraphQLTypes["Event_criteria__categories_operator"] | undefined,
	criteria__audiences?: GraphQLTypes["Event_criteria__audiences_operator"] | undefined,
	criteria__tags?: GraphQLTypes["Event_criteria__tags_operator"] | undefined,
	criteria__specialTags?: GraphQLTypes["Event_criteria__specialTags_operator"] | undefined,
	walkIn?: GraphQLTypes["Event_walkIn_operator"] | undefined,
	contact__code?: GraphQLTypes["Event_contact__code_operator"] | undefined,
	contact__phone?: GraphQLTypes["Event_contact__phone_operator"] | undefined,
	contact__page?: GraphQLTypes["Event_contact__page_operator"] | undefined,
	contact__email?: GraphQLTypes["Event_contact__email_operator"] | undefined,
	contact__privacy?: GraphQLTypes["Event_contact__privacy_operator"] | undefined,
	views?: GraphQLTypes["Event_views_operator"] | undefined,
	free?: GraphQLTypes["Event_free_operator"] | undefined,
	fees__fee?: GraphQLTypes["Event_fees__fee_operator"] | undefined,
	fees__remark?: GraphQLTypes["Event_fees__remark_operator"] | undefined,
	fees__id?: GraphQLTypes["Event_fees__id_operator"] | undefined,
	homePageInternalClick?: GraphQLTypes["Event_homePageInternalClick_operator"] | undefined,
	homePageImpression?: GraphQLTypes["Event_homePageImpression_operator"] | undefined,
	eodClickRate?: GraphQLTypes["Event_eodClickRate_operator"] | undefined,
	eodImpressionRate?: GraphQLTypes["Event_eodImpressionRate_operator"] | undefined,
	top10ClickRateHomepage?: GraphQLTypes["Event_top10ClickRateHomepage_operator"] | undefined,
	top10ImpressionkRateHomepage?: GraphQLTypes["Event_top10ImpressionkRateHomepage_operator"] | undefined,
	otherEventRankingClickRateHomepage?: GraphQLTypes["Event_otherEventRankingClickRateHomepage_operator"] | undefined,
	otherEventRankingImpressionHomePage?: GraphQLTypes["Event_otherEventRankingImpressionHomePage_operator"] | undefined,
	userPreferenceClickRate?: GraphQLTypes["Event_userPreferenceClickRate_operator"] | undefined,
	userPreferenceImpression?: GraphQLTypes["Event_userPreferenceImpression_operator"] | undefined,
	eventSearchInternalClick?: GraphQLTypes["Event_eventSearchInternalClick_operator"] | undefined,
	eventListImpression?: GraphQLTypes["Event_eventListImpression_operator"] | undefined,
	latestEventInternalClick?: GraphQLTypes["Event_latestEventInternalClick_operator"] | undefined,
	latestEventImpression?: GraphQLTypes["Event_latestEventImpression_operator"] | undefined,
	topTenEventRankingInternalClick?: GraphQLTypes["Event_topTenEventRankingInternalClick_operator"] | undefined,
	topTenImpression?: GraphQLTypes["Event_topTenImpression_operator"] | undefined,
	editorChoiceInternalClick?: GraphQLTypes["Event_editorChoiceInternalClick_operator"] | undefined,
	editorChoiceImpression?: GraphQLTypes["Event_editorChoiceImpression_operator"] | undefined,
	otherEventRankingInternalClick?: GraphQLTypes["Event_otherEventRankingInternalClick_operator"] | undefined,
	otherEventRankingImpression?: GraphQLTypes["Event_otherEventRankingImpression_operator"] | undefined,
	bannerInternalClick?: GraphQLTypes["Event_bannerInternalClick_operator"] | undefined,
	miscImpression?: GraphQLTypes["Event_miscImpression_operator"] | undefined,
	miscInternalClick?: GraphQLTypes["Event_miscInternalClick_operator"] | undefined,
	totalImpression?: GraphQLTypes["Event_totalImpression_operator"] | undefined,
	internalClick?: GraphQLTypes["Event_internalClick_operator"] | undefined,
	outboundClick?: GraphQLTypes["Event_outboundClick_operator"] | undefined,
	externalClick?: GraphQLTypes["Event_externalClick_operator"] | undefined,
	_viewsCountByMonth?: GraphQLTypes["Event__viewsCountByMonth_operator"] | undefined,
	products__startDateTime?: GraphQLTypes["Event_products__startDateTime_operator"] | undefined,
	products__endDateTime?: GraphQLTypes["Event_products__endDateTime_operator"] | undefined,
	products__fullDay?: GraphQLTypes["Event_products__fullDay_operator"] | undefined,
	products__linkedProduct?: GraphQLTypes["Event_products__linkedProduct_operator"] | undefined,
	products__id?: GraphQLTypes["Event_products__id_operator"] | undefined,
	legacyGallery?: GraphQLTypes["Event_legacyGallery_operator"] | undefined,
	earliestStartDate?: GraphQLTypes["Event_earliestStartDate_operator"] | undefined,
	latestEndDate?: GraphQLTypes["Event_latestEndDate_operator"] | undefined,
	latestStartDateTime?: GraphQLTypes["Event_latestStartDateTime_operator"] | undefined,
	hasTicketing?: GraphQLTypes["Event_hasTicketing_operator"] | undefined,
	hasProducts?: GraphQLTypes["Event_hasProducts_operator"] | undefined,
	updatedAt?: GraphQLTypes["Event_updatedAt_operator"] | undefined,
	createdAt?: GraphQLTypes["Event_createdAt_operator"] | undefined,
	id?: GraphQLTypes["Event_id_operator"] | undefined,
	AND?: Array<GraphQLTypes["Event_where_and"] | undefined> | undefined,
	OR?: Array<GraphQLTypes["Event_where_or"] | undefined> | undefined
};
	["Event_region_operator"]: {
		equals?: GraphQLTypes["Event_region_Input"] | undefined,
	not_equals?: GraphQLTypes["Event_region_Input"] | undefined,
	in?: Array<GraphQLTypes["Event_region_Input"] | undefined> | undefined,
	not_in?: Array<GraphQLTypes["Event_region_Input"] | undefined> | undefined,
	all?: Array<GraphQLTypes["Event_region_Input"] | undefined> | undefined,
	exists?: boolean | undefined
};
	["Event_region_Input"]: Event_region_Input;
	["Event_creator_operator"]: {
		equals?: GraphQLTypes["JSON"] | undefined,
	not_equals?: GraphQLTypes["JSON"] | undefined,
	in?: Array<GraphQLTypes["JSON"] | undefined> | undefined,
	not_in?: Array<GraphQLTypes["JSON"] | undefined> | undefined,
	all?: Array<GraphQLTypes["JSON"] | undefined> | undefined,
	exists?: boolean | undefined
};
	["Event_permalink_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Event_status_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined
};
	["Event_level_operator"]: {
		equals?: GraphQLTypes["Event_level_Input"] | undefined,
	not_equals?: GraphQLTypes["Event_level_Input"] | undefined,
	in?: Array<GraphQLTypes["Event_level_Input"] | undefined> | undefined,
	not_in?: Array<GraphQLTypes["Event_level_Input"] | undefined> | undefined,
	all?: Array<GraphQLTypes["Event_level_Input"] | undefined> | undefined
};
	["Event_level_Input"]: Event_level_Input;
	["Event_parent_operator"]: {
		equals?: GraphQLTypes["JSON"] | undefined,
	not_equals?: GraphQLTypes["JSON"] | undefined,
	in?: Array<GraphQLTypes["JSON"] | undefined> | undefined,
	not_in?: Array<GraphQLTypes["JSON"] | undefined> | undefined,
	all?: Array<GraphQLTypes["JSON"] | undefined> | undefined,
	exists?: boolean | undefined
};
	["Event_name_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined
};
	["Event_chatbotEventTitle_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined
};
	["Event_chatbotSequence_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Event_thumbnail_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	exists?: boolean | undefined
};
	["Event_banner_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	exists?: boolean | undefined
};
	["Event_mobileBanner_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	exists?: boolean | undefined
};
	["Event_sections__startDatetime_operator"]: {
		equals?: GraphQLTypes["DateTime"] | undefined,
	not_equals?: GraphQLTypes["DateTime"] | undefined,
	greater_than_equal?: GraphQLTypes["DateTime"] | undefined,
	greater_than?: GraphQLTypes["DateTime"] | undefined,
	less_than_equal?: GraphQLTypes["DateTime"] | undefined,
	less_than?: GraphQLTypes["DateTime"] | undefined,
	like?: GraphQLTypes["DateTime"] | undefined
};
	["Event_sections__endDatetime_operator"]: {
		equals?: GraphQLTypes["DateTime"] | undefined,
	not_equals?: GraphQLTypes["DateTime"] | undefined,
	greater_than_equal?: GraphQLTypes["DateTime"] | undefined,
	greater_than?: GraphQLTypes["DateTime"] | undefined,
	less_than_equal?: GraphQLTypes["DateTime"] | undefined,
	less_than?: GraphQLTypes["DateTime"] | undefined,
	like?: GraphQLTypes["DateTime"] | undefined
};
	["Event_sections__fullDay_operator"]: {
		equals?: boolean | undefined,
	not_equals?: boolean | undefined,
	exists?: boolean | undefined
};
	["Event_sections__repeat_operator"]: {
		equals?: boolean | undefined,
	not_equals?: boolean | undefined,
	exists?: boolean | undefined
};
	["Event_sections__toThisDay_operator"]: {
		equals?: GraphQLTypes["DateTime"] | undefined,
	not_equals?: GraphQLTypes["DateTime"] | undefined,
	greater_than_equal?: GraphQLTypes["DateTime"] | undefined,
	greater_than?: GraphQLTypes["DateTime"] | undefined,
	less_than_equal?: GraphQLTypes["DateTime"] | undefined,
	less_than?: GraphQLTypes["DateTime"] | undefined,
	like?: GraphQLTypes["DateTime"] | undefined
};
	["Event_sections__recurrance__type_operator"]: {
		equals?: GraphQLTypes["Event_sections__recurrance__type_Input"] | undefined,
	not_equals?: GraphQLTypes["Event_sections__recurrance__type_Input"] | undefined,
	in?: Array<GraphQLTypes["Event_sections__recurrance__type_Input"] | undefined> | undefined,
	not_in?: Array<GraphQLTypes["Event_sections__recurrance__type_Input"] | undefined> | undefined,
	all?: Array<GraphQLTypes["Event_sections__recurrance__type_Input"] | undefined> | undefined
};
	["Event_sections__recurrance__type_Input"]: Event_sections__recurrance__type_Input;
	["Event_sections__recurrance__weekday_operator"]: {
		equals?: GraphQLTypes["Event_sections__recurrance__weekday_Input"] | undefined,
	not_equals?: GraphQLTypes["Event_sections__recurrance__weekday_Input"] | undefined,
	in?: Array<GraphQLTypes["Event_sections__recurrance__weekday_Input"] | undefined> | undefined,
	not_in?: Array<GraphQLTypes["Event_sections__recurrance__weekday_Input"] | undefined> | undefined,
	all?: Array<GraphQLTypes["Event_sections__recurrance__weekday_Input"] | undefined> | undefined
};
	["Event_sections__recurrance__weekday_Input"]: Event_sections__recurrance__weekday_Input;
	["Event_sections__sameLocation_operator"]: {
		equals?: boolean | undefined,
	not_equals?: boolean | undefined,
	exists?: boolean | undefined
};
	["Event_sections__location_operator"]: {
		equals?: GraphQLTypes["JSON"] | undefined,
	not_equals?: GraphQLTypes["JSON"] | undefined,
	in?: Array<GraphQLTypes["JSON"] | undefined> | undefined,
	not_in?: Array<GraphQLTypes["JSON"] | undefined> | undefined,
	all?: Array<GraphQLTypes["JSON"] | undefined> | undefined,
	exists?: boolean | undefined
};
	["Event_sections__district_operator"]: {
		equals?: GraphQLTypes["JSON"] | undefined,
	not_equals?: GraphQLTypes["JSON"] | undefined,
	in?: Array<GraphQLTypes["JSON"] | undefined> | undefined,
	not_in?: Array<GraphQLTypes["JSON"] | undefined> | undefined,
	all?: Array<GraphQLTypes["JSON"] | undefined> | undefined,
	exists?: boolean | undefined
};
	["Event_sections__coordinate_operator"]: {
		equals?: Array<number | undefined> | undefined,
	not_equals?: Array<number | undefined> | undefined,
	greater_than_equal?: Array<number | undefined> | undefined,
	greater_than?: Array<number | undefined> | undefined,
	less_than_equal?: Array<number | undefined> | undefined,
	less_than?: Array<number | undefined> | undefined,
	near?: Array<number | undefined> | undefined,
	within?: GraphQLTypes["GeoJSONObject"] | undefined,
	intersects?: GraphQLTypes["GeoJSONObject"] | undefined,
	exists?: boolean | undefined
};
	["GeoJSONObject"]: {
		coordinates?: GraphQLTypes["JSON"] | undefined,
	type?: string | undefined
};
	["Event_sections__linkAddress_operator"]: {
		equals?: boolean | undefined,
	not_equals?: boolean | undefined,
	exists?: boolean | undefined
};
	["Event_sections__address_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Event_sections__addressCoordinate_operator"]: {
		equals?: Array<number | undefined> | undefined,
	not_equals?: Array<number | undefined> | undefined,
	greater_than_equal?: Array<number | undefined> | undefined,
	greater_than?: Array<number | undefined> | undefined,
	less_than_equal?: Array<number | undefined> | undefined,
	less_than?: Array<number | undefined> | undefined,
	near?: Array<number | undefined> | undefined,
	within?: GraphQLTypes["GeoJSONObject"] | undefined,
	intersects?: GraphQLTypes["GeoJSONObject"] | undefined,
	exists?: boolean | undefined
};
	["Event_sections__id_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Event_ticketing__startDatetime_operator"]: {
		equals?: GraphQLTypes["DateTime"] | undefined,
	not_equals?: GraphQLTypes["DateTime"] | undefined,
	greater_than_equal?: GraphQLTypes["DateTime"] | undefined,
	greater_than?: GraphQLTypes["DateTime"] | undefined,
	less_than_equal?: GraphQLTypes["DateTime"] | undefined,
	less_than?: GraphQLTypes["DateTime"] | undefined,
	like?: GraphQLTypes["DateTime"] | undefined
};
	["Event_ticketing__endDatetime_operator"]: {
		equals?: GraphQLTypes["DateTime"] | undefined,
	not_equals?: GraphQLTypes["DateTime"] | undefined,
	greater_than_equal?: GraphQLTypes["DateTime"] | undefined,
	greater_than?: GraphQLTypes["DateTime"] | undefined,
	less_than_equal?: GraphQLTypes["DateTime"] | undefined,
	less_than?: GraphQLTypes["DateTime"] | undefined,
	like?: GraphQLTypes["DateTime"] | undefined
};
	["Event_ticketing__fullDay_operator"]: {
		equals?: boolean | undefined,
	not_equals?: boolean | undefined,
	exists?: boolean | undefined
};
	["Event_ticketing__type_operator"]: {
		equals?: GraphQLTypes["Event_ticketing__type_Input"] | undefined,
	not_equals?: GraphQLTypes["Event_ticketing__type_Input"] | undefined,
	in?: Array<GraphQLTypes["Event_ticketing__type_Input"] | undefined> | undefined,
	not_in?: Array<GraphQLTypes["Event_ticketing__type_Input"] | undefined> | undefined,
	all?: Array<GraphQLTypes["Event_ticketing__type_Input"] | undefined> | undefined
};
	["Event_ticketing__type_Input"]: Event_ticketing__type_Input;
	["Event_ticketing__ticketingType_operator"]: {
		equals?: GraphQLTypes["Event_ticketing__ticketingType_Input"] | undefined,
	not_equals?: GraphQLTypes["Event_ticketing__ticketingType_Input"] | undefined,
	in?: Array<GraphQLTypes["Event_ticketing__ticketingType_Input"] | undefined> | undefined,
	not_in?: Array<GraphQLTypes["Event_ticketing__ticketingType_Input"] | undefined> | undefined,
	all?: Array<GraphQLTypes["Event_ticketing__ticketingType_Input"] | undefined> | undefined
};
	["Event_ticketing__ticketingType_Input"]: Event_ticketing__ticketingType_Input;
	["Event_ticketing__linkedTicketing_operator"]: {
		equals?: GraphQLTypes["JSON"] | undefined,
	not_equals?: GraphQLTypes["JSON"] | undefined,
	in?: Array<GraphQLTypes["JSON"] | undefined> | undefined,
	not_in?: Array<GraphQLTypes["JSON"] | undefined> | undefined,
	all?: Array<GraphQLTypes["JSON"] | undefined> | undefined,
	exists?: boolean | undefined
};
	["Event_ticketing__promotionType_operator"]: {
		equals?: GraphQLTypes["Event_ticketing__promotionType_Input"] | undefined,
	not_equals?: GraphQLTypes["Event_ticketing__promotionType_Input"] | undefined,
	in?: Array<GraphQLTypes["Event_ticketing__promotionType_Input"] | undefined> | undefined,
	not_in?: Array<GraphQLTypes["Event_ticketing__promotionType_Input"] | undefined> | undefined,
	all?: Array<GraphQLTypes["Event_ticketing__promotionType_Input"] | undefined> | undefined,
	exists?: boolean | undefined
};
	["Event_ticketing__promotionType_Input"]: Event_ticketing__promotionType_Input;
	["Event_ticketing__location_operator"]: {
		equals?: GraphQLTypes["JSON"] | undefined,
	not_equals?: GraphQLTypes["JSON"] | undefined,
	in?: Array<GraphQLTypes["JSON"] | undefined> | undefined,
	not_in?: Array<GraphQLTypes["JSON"] | undefined> | undefined,
	all?: Array<GraphQLTypes["JSON"] | undefined> | undefined
};
	["Event_ticketing__website_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Event_ticketing__description_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	exists?: boolean | undefined
};
	["Event_ticketing__id_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Event_legacyContent_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	exists?: boolean | undefined
};
	["Event_showLegacyContent_operator"]: {
		equals?: boolean | undefined,
	not_equals?: boolean | undefined,
	exists?: boolean | undefined
};
	["Event_criteria__categories_operator"]: {
		equals?: GraphQLTypes["JSON"] | undefined,
	not_equals?: GraphQLTypes["JSON"] | undefined,
	in?: Array<GraphQLTypes["JSON"] | undefined> | undefined,
	not_in?: Array<GraphQLTypes["JSON"] | undefined> | undefined,
	all?: Array<GraphQLTypes["JSON"] | undefined> | undefined
};
	["Event_criteria__audiences_operator"]: {
		equals?: GraphQLTypes["JSON"] | undefined,
	not_equals?: GraphQLTypes["JSON"] | undefined,
	in?: Array<GraphQLTypes["JSON"] | undefined> | undefined,
	not_in?: Array<GraphQLTypes["JSON"] | undefined> | undefined,
	all?: Array<GraphQLTypes["JSON"] | undefined> | undefined,
	exists?: boolean | undefined
};
	["Event_criteria__tags_operator"]: {
		equals?: GraphQLTypes["JSON"] | undefined,
	not_equals?: GraphQLTypes["JSON"] | undefined,
	in?: Array<GraphQLTypes["JSON"] | undefined> | undefined,
	not_in?: Array<GraphQLTypes["JSON"] | undefined> | undefined,
	all?: Array<GraphQLTypes["JSON"] | undefined> | undefined,
	exists?: boolean | undefined
};
	["Event_criteria__specialTags_operator"]: {
		equals?: GraphQLTypes["JSON"] | undefined,
	not_equals?: GraphQLTypes["JSON"] | undefined,
	in?: Array<GraphQLTypes["JSON"] | undefined> | undefined,
	not_in?: Array<GraphQLTypes["JSON"] | undefined> | undefined,
	all?: Array<GraphQLTypes["JSON"] | undefined> | undefined,
	exists?: boolean | undefined
};
	["Event_walkIn_operator"]: {
		equals?: boolean | undefined,
	not_equals?: boolean | undefined,
	exists?: boolean | undefined
};
	["Event_contact__code_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Event_contact__phone_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Event_contact__page_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Event_contact__email_operator"]: {
		equals?: GraphQLTypes["EmailAddress"] | undefined,
	not_equals?: GraphQLTypes["EmailAddress"] | undefined,
	like?: GraphQLTypes["EmailAddress"] | undefined,
	contains?: GraphQLTypes["EmailAddress"] | undefined,
	in?: Array<GraphQLTypes["EmailAddress"] | undefined> | undefined,
	not_in?: Array<GraphQLTypes["EmailAddress"] | undefined> | undefined,
	all?: Array<GraphQLTypes["EmailAddress"] | undefined> | undefined,
	exists?: boolean | undefined
};
	["Event_contact__privacy_operator"]: {
		equals?: GraphQLTypes["JSON"] | undefined,
	not_equals?: GraphQLTypes["JSON"] | undefined,
	like?: GraphQLTypes["JSON"] | undefined,
	contains?: GraphQLTypes["JSON"] | undefined,
	exists?: boolean | undefined
};
	["Event_views_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Event_free_operator"]: {
		equals?: boolean | undefined,
	not_equals?: boolean | undefined,
	exists?: boolean | undefined
};
	["Event_fees__fee_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Event_fees__remark_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Event_fees__id_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Event_homePageInternalClick_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Event_homePageImpression_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Event_eodClickRate_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Event_eodImpressionRate_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Event_top10ClickRateHomepage_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Event_top10ImpressionkRateHomepage_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Event_otherEventRankingClickRateHomepage_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Event_otherEventRankingImpressionHomePage_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Event_userPreferenceClickRate_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Event_userPreferenceImpression_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Event_eventSearchInternalClick_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Event_eventListImpression_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Event_latestEventInternalClick_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Event_latestEventImpression_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Event_topTenEventRankingInternalClick_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Event_topTenImpression_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Event_editorChoiceInternalClick_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Event_editorChoiceImpression_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Event_otherEventRankingInternalClick_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Event_otherEventRankingImpression_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Event_bannerInternalClick_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Event_miscImpression_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Event_miscInternalClick_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Event_totalImpression_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Event_internalClick_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Event_outboundClick_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Event_externalClick_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Event__viewsCountByMonth_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Event_products__startDateTime_operator"]: {
		equals?: GraphQLTypes["DateTime"] | undefined,
	not_equals?: GraphQLTypes["DateTime"] | undefined,
	greater_than_equal?: GraphQLTypes["DateTime"] | undefined,
	greater_than?: GraphQLTypes["DateTime"] | undefined,
	less_than_equal?: GraphQLTypes["DateTime"] | undefined,
	less_than?: GraphQLTypes["DateTime"] | undefined,
	like?: GraphQLTypes["DateTime"] | undefined
};
	["Event_products__endDateTime_operator"]: {
		equals?: GraphQLTypes["DateTime"] | undefined,
	not_equals?: GraphQLTypes["DateTime"] | undefined,
	greater_than_equal?: GraphQLTypes["DateTime"] | undefined,
	greater_than?: GraphQLTypes["DateTime"] | undefined,
	less_than_equal?: GraphQLTypes["DateTime"] | undefined,
	less_than?: GraphQLTypes["DateTime"] | undefined,
	like?: GraphQLTypes["DateTime"] | undefined
};
	["Event_products__fullDay_operator"]: {
		equals?: boolean | undefined,
	not_equals?: boolean | undefined,
	exists?: boolean | undefined
};
	["Event_products__linkedProduct_operator"]: {
		equals?: GraphQLTypes["JSON"] | undefined,
	not_equals?: GraphQLTypes["JSON"] | undefined,
	in?: Array<GraphQLTypes["JSON"] | undefined> | undefined,
	not_in?: Array<GraphQLTypes["JSON"] | undefined> | undefined,
	all?: Array<GraphQLTypes["JSON"] | undefined> | undefined
};
	["Event_products__id_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Event_legacyGallery_operator"]: {
		equals?: GraphQLTypes["JSON"] | undefined,
	not_equals?: GraphQLTypes["JSON"] | undefined,
	in?: Array<GraphQLTypes["JSON"] | undefined> | undefined,
	not_in?: Array<GraphQLTypes["JSON"] | undefined> | undefined,
	all?: Array<GraphQLTypes["JSON"] | undefined> | undefined,
	exists?: boolean | undefined
};
	["Event_earliestStartDate_operator"]: {
		equals?: GraphQLTypes["DateTime"] | undefined,
	not_equals?: GraphQLTypes["DateTime"] | undefined,
	greater_than_equal?: GraphQLTypes["DateTime"] | undefined,
	greater_than?: GraphQLTypes["DateTime"] | undefined,
	less_than_equal?: GraphQLTypes["DateTime"] | undefined,
	less_than?: GraphQLTypes["DateTime"] | undefined,
	like?: GraphQLTypes["DateTime"] | undefined,
	exists?: boolean | undefined
};
	["Event_latestEndDate_operator"]: {
		equals?: GraphQLTypes["DateTime"] | undefined,
	not_equals?: GraphQLTypes["DateTime"] | undefined,
	greater_than_equal?: GraphQLTypes["DateTime"] | undefined,
	greater_than?: GraphQLTypes["DateTime"] | undefined,
	less_than_equal?: GraphQLTypes["DateTime"] | undefined,
	less_than?: GraphQLTypes["DateTime"] | undefined,
	like?: GraphQLTypes["DateTime"] | undefined,
	exists?: boolean | undefined
};
	["Event_latestStartDateTime_operator"]: {
		equals?: GraphQLTypes["DateTime"] | undefined,
	not_equals?: GraphQLTypes["DateTime"] | undefined,
	greater_than_equal?: GraphQLTypes["DateTime"] | undefined,
	greater_than?: GraphQLTypes["DateTime"] | undefined,
	less_than_equal?: GraphQLTypes["DateTime"] | undefined,
	less_than?: GraphQLTypes["DateTime"] | undefined,
	like?: GraphQLTypes["DateTime"] | undefined,
	exists?: boolean | undefined
};
	["Event_hasTicketing_operator"]: {
		equals?: boolean | undefined,
	not_equals?: boolean | undefined,
	exists?: boolean | undefined
};
	["Event_hasProducts_operator"]: {
		equals?: boolean | undefined,
	not_equals?: boolean | undefined,
	exists?: boolean | undefined
};
	["Event_updatedAt_operator"]: {
		equals?: GraphQLTypes["DateTime"] | undefined,
	not_equals?: GraphQLTypes["DateTime"] | undefined,
	greater_than_equal?: GraphQLTypes["DateTime"] | undefined,
	greater_than?: GraphQLTypes["DateTime"] | undefined,
	less_than_equal?: GraphQLTypes["DateTime"] | undefined,
	less_than?: GraphQLTypes["DateTime"] | undefined,
	like?: GraphQLTypes["DateTime"] | undefined,
	exists?: boolean | undefined
};
	["Event_createdAt_operator"]: {
		equals?: GraphQLTypes["DateTime"] | undefined,
	not_equals?: GraphQLTypes["DateTime"] | undefined,
	greater_than_equal?: GraphQLTypes["DateTime"] | undefined,
	greater_than?: GraphQLTypes["DateTime"] | undefined,
	less_than_equal?: GraphQLTypes["DateTime"] | undefined,
	less_than?: GraphQLTypes["DateTime"] | undefined,
	like?: GraphQLTypes["DateTime"] | undefined,
	exists?: boolean | undefined
};
	["Event_id_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Event_where_and"]: {
		region?: GraphQLTypes["Event_region_operator"] | undefined,
	creator?: GraphQLTypes["Event_creator_operator"] | undefined,
	permalink?: GraphQLTypes["Event_permalink_operator"] | undefined,
	status?: GraphQLTypes["Event_status_operator"] | undefined,
	level?: GraphQLTypes["Event_level_operator"] | undefined,
	parent?: GraphQLTypes["Event_parent_operator"] | undefined,
	name?: GraphQLTypes["Event_name_operator"] | undefined,
	chatbotEventTitle?: GraphQLTypes["Event_chatbotEventTitle_operator"] | undefined,
	chatbotSequence?: GraphQLTypes["Event_chatbotSequence_operator"] | undefined,
	thumbnail?: GraphQLTypes["Event_thumbnail_operator"] | undefined,
	banner?: GraphQLTypes["Event_banner_operator"] | undefined,
	mobileBanner?: GraphQLTypes["Event_mobileBanner_operator"] | undefined,
	sections__startDatetime?: GraphQLTypes["Event_sections__startDatetime_operator"] | undefined,
	sections__endDatetime?: GraphQLTypes["Event_sections__endDatetime_operator"] | undefined,
	sections__fullDay?: GraphQLTypes["Event_sections__fullDay_operator"] | undefined,
	sections__repeat?: GraphQLTypes["Event_sections__repeat_operator"] | undefined,
	sections__toThisDay?: GraphQLTypes["Event_sections__toThisDay_operator"] | undefined,
	sections__recurrance__type?: GraphQLTypes["Event_sections__recurrance__type_operator"] | undefined,
	sections__recurrance__weekday?: GraphQLTypes["Event_sections__recurrance__weekday_operator"] | undefined,
	sections__sameLocation?: GraphQLTypes["Event_sections__sameLocation_operator"] | undefined,
	sections__location?: GraphQLTypes["Event_sections__location_operator"] | undefined,
	sections__district?: GraphQLTypes["Event_sections__district_operator"] | undefined,
	sections__coordinate?: GraphQLTypes["Event_sections__coordinate_operator"] | undefined,
	sections__linkAddress?: GraphQLTypes["Event_sections__linkAddress_operator"] | undefined,
	sections__address?: GraphQLTypes["Event_sections__address_operator"] | undefined,
	sections__addressCoordinate?: GraphQLTypes["Event_sections__addressCoordinate_operator"] | undefined,
	sections__id?: GraphQLTypes["Event_sections__id_operator"] | undefined,
	ticketing__startDatetime?: GraphQLTypes["Event_ticketing__startDatetime_operator"] | undefined,
	ticketing__endDatetime?: GraphQLTypes["Event_ticketing__endDatetime_operator"] | undefined,
	ticketing__fullDay?: GraphQLTypes["Event_ticketing__fullDay_operator"] | undefined,
	ticketing__type?: GraphQLTypes["Event_ticketing__type_operator"] | undefined,
	ticketing__ticketingType?: GraphQLTypes["Event_ticketing__ticketingType_operator"] | undefined,
	ticketing__linkedTicketing?: GraphQLTypes["Event_ticketing__linkedTicketing_operator"] | undefined,
	ticketing__promotionType?: GraphQLTypes["Event_ticketing__promotionType_operator"] | undefined,
	ticketing__location?: GraphQLTypes["Event_ticketing__location_operator"] | undefined,
	ticketing__website?: GraphQLTypes["Event_ticketing__website_operator"] | undefined,
	ticketing__description?: GraphQLTypes["Event_ticketing__description_operator"] | undefined,
	ticketing__id?: GraphQLTypes["Event_ticketing__id_operator"] | undefined,
	legacyContent?: GraphQLTypes["Event_legacyContent_operator"] | undefined,
	showLegacyContent?: GraphQLTypes["Event_showLegacyContent_operator"] | undefined,
	criteria__categories?: GraphQLTypes["Event_criteria__categories_operator"] | undefined,
	criteria__audiences?: GraphQLTypes["Event_criteria__audiences_operator"] | undefined,
	criteria__tags?: GraphQLTypes["Event_criteria__tags_operator"] | undefined,
	criteria__specialTags?: GraphQLTypes["Event_criteria__specialTags_operator"] | undefined,
	walkIn?: GraphQLTypes["Event_walkIn_operator"] | undefined,
	contact__code?: GraphQLTypes["Event_contact__code_operator"] | undefined,
	contact__phone?: GraphQLTypes["Event_contact__phone_operator"] | undefined,
	contact__page?: GraphQLTypes["Event_contact__page_operator"] | undefined,
	contact__email?: GraphQLTypes["Event_contact__email_operator"] | undefined,
	contact__privacy?: GraphQLTypes["Event_contact__privacy_operator"] | undefined,
	views?: GraphQLTypes["Event_views_operator"] | undefined,
	free?: GraphQLTypes["Event_free_operator"] | undefined,
	fees__fee?: GraphQLTypes["Event_fees__fee_operator"] | undefined,
	fees__remark?: GraphQLTypes["Event_fees__remark_operator"] | undefined,
	fees__id?: GraphQLTypes["Event_fees__id_operator"] | undefined,
	homePageInternalClick?: GraphQLTypes["Event_homePageInternalClick_operator"] | undefined,
	homePageImpression?: GraphQLTypes["Event_homePageImpression_operator"] | undefined,
	eodClickRate?: GraphQLTypes["Event_eodClickRate_operator"] | undefined,
	eodImpressionRate?: GraphQLTypes["Event_eodImpressionRate_operator"] | undefined,
	top10ClickRateHomepage?: GraphQLTypes["Event_top10ClickRateHomepage_operator"] | undefined,
	top10ImpressionkRateHomepage?: GraphQLTypes["Event_top10ImpressionkRateHomepage_operator"] | undefined,
	otherEventRankingClickRateHomepage?: GraphQLTypes["Event_otherEventRankingClickRateHomepage_operator"] | undefined,
	otherEventRankingImpressionHomePage?: GraphQLTypes["Event_otherEventRankingImpressionHomePage_operator"] | undefined,
	userPreferenceClickRate?: GraphQLTypes["Event_userPreferenceClickRate_operator"] | undefined,
	userPreferenceImpression?: GraphQLTypes["Event_userPreferenceImpression_operator"] | undefined,
	eventSearchInternalClick?: GraphQLTypes["Event_eventSearchInternalClick_operator"] | undefined,
	eventListImpression?: GraphQLTypes["Event_eventListImpression_operator"] | undefined,
	latestEventInternalClick?: GraphQLTypes["Event_latestEventInternalClick_operator"] | undefined,
	latestEventImpression?: GraphQLTypes["Event_latestEventImpression_operator"] | undefined,
	topTenEventRankingInternalClick?: GraphQLTypes["Event_topTenEventRankingInternalClick_operator"] | undefined,
	topTenImpression?: GraphQLTypes["Event_topTenImpression_operator"] | undefined,
	editorChoiceInternalClick?: GraphQLTypes["Event_editorChoiceInternalClick_operator"] | undefined,
	editorChoiceImpression?: GraphQLTypes["Event_editorChoiceImpression_operator"] | undefined,
	otherEventRankingInternalClick?: GraphQLTypes["Event_otherEventRankingInternalClick_operator"] | undefined,
	otherEventRankingImpression?: GraphQLTypes["Event_otherEventRankingImpression_operator"] | undefined,
	bannerInternalClick?: GraphQLTypes["Event_bannerInternalClick_operator"] | undefined,
	miscImpression?: GraphQLTypes["Event_miscImpression_operator"] | undefined,
	miscInternalClick?: GraphQLTypes["Event_miscInternalClick_operator"] | undefined,
	totalImpression?: GraphQLTypes["Event_totalImpression_operator"] | undefined,
	internalClick?: GraphQLTypes["Event_internalClick_operator"] | undefined,
	outboundClick?: GraphQLTypes["Event_outboundClick_operator"] | undefined,
	externalClick?: GraphQLTypes["Event_externalClick_operator"] | undefined,
	_viewsCountByMonth?: GraphQLTypes["Event__viewsCountByMonth_operator"] | undefined,
	products__startDateTime?: GraphQLTypes["Event_products__startDateTime_operator"] | undefined,
	products__endDateTime?: GraphQLTypes["Event_products__endDateTime_operator"] | undefined,
	products__fullDay?: GraphQLTypes["Event_products__fullDay_operator"] | undefined,
	products__linkedProduct?: GraphQLTypes["Event_products__linkedProduct_operator"] | undefined,
	products__id?: GraphQLTypes["Event_products__id_operator"] | undefined,
	legacyGallery?: GraphQLTypes["Event_legacyGallery_operator"] | undefined,
	earliestStartDate?: GraphQLTypes["Event_earliestStartDate_operator"] | undefined,
	latestEndDate?: GraphQLTypes["Event_latestEndDate_operator"] | undefined,
	latestStartDateTime?: GraphQLTypes["Event_latestStartDateTime_operator"] | undefined,
	hasTicketing?: GraphQLTypes["Event_hasTicketing_operator"] | undefined,
	hasProducts?: GraphQLTypes["Event_hasProducts_operator"] | undefined,
	updatedAt?: GraphQLTypes["Event_updatedAt_operator"] | undefined,
	createdAt?: GraphQLTypes["Event_createdAt_operator"] | undefined,
	id?: GraphQLTypes["Event_id_operator"] | undefined,
	AND?: Array<GraphQLTypes["Event_where_and"] | undefined> | undefined,
	OR?: Array<GraphQLTypes["Event_where_or"] | undefined> | undefined
};
	["Event_where_or"]: {
		region?: GraphQLTypes["Event_region_operator"] | undefined,
	creator?: GraphQLTypes["Event_creator_operator"] | undefined,
	permalink?: GraphQLTypes["Event_permalink_operator"] | undefined,
	status?: GraphQLTypes["Event_status_operator"] | undefined,
	level?: GraphQLTypes["Event_level_operator"] | undefined,
	parent?: GraphQLTypes["Event_parent_operator"] | undefined,
	name?: GraphQLTypes["Event_name_operator"] | undefined,
	chatbotEventTitle?: GraphQLTypes["Event_chatbotEventTitle_operator"] | undefined,
	chatbotSequence?: GraphQLTypes["Event_chatbotSequence_operator"] | undefined,
	thumbnail?: GraphQLTypes["Event_thumbnail_operator"] | undefined,
	banner?: GraphQLTypes["Event_banner_operator"] | undefined,
	mobileBanner?: GraphQLTypes["Event_mobileBanner_operator"] | undefined,
	sections__startDatetime?: GraphQLTypes["Event_sections__startDatetime_operator"] | undefined,
	sections__endDatetime?: GraphQLTypes["Event_sections__endDatetime_operator"] | undefined,
	sections__fullDay?: GraphQLTypes["Event_sections__fullDay_operator"] | undefined,
	sections__repeat?: GraphQLTypes["Event_sections__repeat_operator"] | undefined,
	sections__toThisDay?: GraphQLTypes["Event_sections__toThisDay_operator"] | undefined,
	sections__recurrance__type?: GraphQLTypes["Event_sections__recurrance__type_operator"] | undefined,
	sections__recurrance__weekday?: GraphQLTypes["Event_sections__recurrance__weekday_operator"] | undefined,
	sections__sameLocation?: GraphQLTypes["Event_sections__sameLocation_operator"] | undefined,
	sections__location?: GraphQLTypes["Event_sections__location_operator"] | undefined,
	sections__district?: GraphQLTypes["Event_sections__district_operator"] | undefined,
	sections__coordinate?: GraphQLTypes["Event_sections__coordinate_operator"] | undefined,
	sections__linkAddress?: GraphQLTypes["Event_sections__linkAddress_operator"] | undefined,
	sections__address?: GraphQLTypes["Event_sections__address_operator"] | undefined,
	sections__addressCoordinate?: GraphQLTypes["Event_sections__addressCoordinate_operator"] | undefined,
	sections__id?: GraphQLTypes["Event_sections__id_operator"] | undefined,
	ticketing__startDatetime?: GraphQLTypes["Event_ticketing__startDatetime_operator"] | undefined,
	ticketing__endDatetime?: GraphQLTypes["Event_ticketing__endDatetime_operator"] | undefined,
	ticketing__fullDay?: GraphQLTypes["Event_ticketing__fullDay_operator"] | undefined,
	ticketing__type?: GraphQLTypes["Event_ticketing__type_operator"] | undefined,
	ticketing__ticketingType?: GraphQLTypes["Event_ticketing__ticketingType_operator"] | undefined,
	ticketing__linkedTicketing?: GraphQLTypes["Event_ticketing__linkedTicketing_operator"] | undefined,
	ticketing__promotionType?: GraphQLTypes["Event_ticketing__promotionType_operator"] | undefined,
	ticketing__location?: GraphQLTypes["Event_ticketing__location_operator"] | undefined,
	ticketing__website?: GraphQLTypes["Event_ticketing__website_operator"] | undefined,
	ticketing__description?: GraphQLTypes["Event_ticketing__description_operator"] | undefined,
	ticketing__id?: GraphQLTypes["Event_ticketing__id_operator"] | undefined,
	legacyContent?: GraphQLTypes["Event_legacyContent_operator"] | undefined,
	showLegacyContent?: GraphQLTypes["Event_showLegacyContent_operator"] | undefined,
	criteria__categories?: GraphQLTypes["Event_criteria__categories_operator"] | undefined,
	criteria__audiences?: GraphQLTypes["Event_criteria__audiences_operator"] | undefined,
	criteria__tags?: GraphQLTypes["Event_criteria__tags_operator"] | undefined,
	criteria__specialTags?: GraphQLTypes["Event_criteria__specialTags_operator"] | undefined,
	walkIn?: GraphQLTypes["Event_walkIn_operator"] | undefined,
	contact__code?: GraphQLTypes["Event_contact__code_operator"] | undefined,
	contact__phone?: GraphQLTypes["Event_contact__phone_operator"] | undefined,
	contact__page?: GraphQLTypes["Event_contact__page_operator"] | undefined,
	contact__email?: GraphQLTypes["Event_contact__email_operator"] | undefined,
	contact__privacy?: GraphQLTypes["Event_contact__privacy_operator"] | undefined,
	views?: GraphQLTypes["Event_views_operator"] | undefined,
	free?: GraphQLTypes["Event_free_operator"] | undefined,
	fees__fee?: GraphQLTypes["Event_fees__fee_operator"] | undefined,
	fees__remark?: GraphQLTypes["Event_fees__remark_operator"] | undefined,
	fees__id?: GraphQLTypes["Event_fees__id_operator"] | undefined,
	homePageInternalClick?: GraphQLTypes["Event_homePageInternalClick_operator"] | undefined,
	homePageImpression?: GraphQLTypes["Event_homePageImpression_operator"] | undefined,
	eodClickRate?: GraphQLTypes["Event_eodClickRate_operator"] | undefined,
	eodImpressionRate?: GraphQLTypes["Event_eodImpressionRate_operator"] | undefined,
	top10ClickRateHomepage?: GraphQLTypes["Event_top10ClickRateHomepage_operator"] | undefined,
	top10ImpressionkRateHomepage?: GraphQLTypes["Event_top10ImpressionkRateHomepage_operator"] | undefined,
	otherEventRankingClickRateHomepage?: GraphQLTypes["Event_otherEventRankingClickRateHomepage_operator"] | undefined,
	otherEventRankingImpressionHomePage?: GraphQLTypes["Event_otherEventRankingImpressionHomePage_operator"] | undefined,
	userPreferenceClickRate?: GraphQLTypes["Event_userPreferenceClickRate_operator"] | undefined,
	userPreferenceImpression?: GraphQLTypes["Event_userPreferenceImpression_operator"] | undefined,
	eventSearchInternalClick?: GraphQLTypes["Event_eventSearchInternalClick_operator"] | undefined,
	eventListImpression?: GraphQLTypes["Event_eventListImpression_operator"] | undefined,
	latestEventInternalClick?: GraphQLTypes["Event_latestEventInternalClick_operator"] | undefined,
	latestEventImpression?: GraphQLTypes["Event_latestEventImpression_operator"] | undefined,
	topTenEventRankingInternalClick?: GraphQLTypes["Event_topTenEventRankingInternalClick_operator"] | undefined,
	topTenImpression?: GraphQLTypes["Event_topTenImpression_operator"] | undefined,
	editorChoiceInternalClick?: GraphQLTypes["Event_editorChoiceInternalClick_operator"] | undefined,
	editorChoiceImpression?: GraphQLTypes["Event_editorChoiceImpression_operator"] | undefined,
	otherEventRankingInternalClick?: GraphQLTypes["Event_otherEventRankingInternalClick_operator"] | undefined,
	otherEventRankingImpression?: GraphQLTypes["Event_otherEventRankingImpression_operator"] | undefined,
	bannerInternalClick?: GraphQLTypes["Event_bannerInternalClick_operator"] | undefined,
	miscImpression?: GraphQLTypes["Event_miscImpression_operator"] | undefined,
	miscInternalClick?: GraphQLTypes["Event_miscInternalClick_operator"] | undefined,
	totalImpression?: GraphQLTypes["Event_totalImpression_operator"] | undefined,
	internalClick?: GraphQLTypes["Event_internalClick_operator"] | undefined,
	outboundClick?: GraphQLTypes["Event_outboundClick_operator"] | undefined,
	externalClick?: GraphQLTypes["Event_externalClick_operator"] | undefined,
	_viewsCountByMonth?: GraphQLTypes["Event__viewsCountByMonth_operator"] | undefined,
	products__startDateTime?: GraphQLTypes["Event_products__startDateTime_operator"] | undefined,
	products__endDateTime?: GraphQLTypes["Event_products__endDateTime_operator"] | undefined,
	products__fullDay?: GraphQLTypes["Event_products__fullDay_operator"] | undefined,
	products__linkedProduct?: GraphQLTypes["Event_products__linkedProduct_operator"] | undefined,
	products__id?: GraphQLTypes["Event_products__id_operator"] | undefined,
	legacyGallery?: GraphQLTypes["Event_legacyGallery_operator"] | undefined,
	earliestStartDate?: GraphQLTypes["Event_earliestStartDate_operator"] | undefined,
	latestEndDate?: GraphQLTypes["Event_latestEndDate_operator"] | undefined,
	latestStartDateTime?: GraphQLTypes["Event_latestStartDateTime_operator"] | undefined,
	hasTicketing?: GraphQLTypes["Event_hasTicketing_operator"] | undefined,
	hasProducts?: GraphQLTypes["Event_hasProducts_operator"] | undefined,
	updatedAt?: GraphQLTypes["Event_updatedAt_operator"] | undefined,
	createdAt?: GraphQLTypes["Event_createdAt_operator"] | undefined,
	id?: GraphQLTypes["Event_id_operator"] | undefined,
	AND?: Array<GraphQLTypes["Event_where_and"] | undefined> | undefined,
	OR?: Array<GraphQLTypes["Event_where_or"] | undefined> | undefined
};
	["countEvents"]: {
	__typename: "countEvents",
	totalDocs?: number | undefined
};
	["eventsDocAccess"]: {
	__typename: "eventsDocAccess",
	fields?: GraphQLTypes["EventsDocAccessFields"] | undefined,
	create?: GraphQLTypes["EventsCreateDocAccess"] | undefined,
	read?: GraphQLTypes["EventsReadDocAccess"] | undefined,
	update?: GraphQLTypes["EventsUpdateDocAccess"] | undefined,
	delete?: GraphQLTypes["EventsDeleteDocAccess"] | undefined
};
	["EventsDocAccessFields"]: {
	__typename: "EventsDocAccessFields",
	region?: GraphQLTypes["EventsDocAccessFields_region"] | undefined,
	creator?: GraphQLTypes["EventsDocAccessFields_creator"] | undefined,
	permalink?: GraphQLTypes["EventsDocAccessFields_permalink"] | undefined,
	status?: GraphQLTypes["EventsDocAccessFields_status"] | undefined,
	level?: GraphQLTypes["EventsDocAccessFields_level"] | undefined,
	parent?: GraphQLTypes["EventsDocAccessFields_parent"] | undefined,
	name?: GraphQLTypes["EventsDocAccessFields_name"] | undefined,
	chatbotEventTitle?: GraphQLTypes["EventsDocAccessFields_chatbotEventTitle"] | undefined,
	chatbotSequence?: GraphQLTypes["EventsDocAccessFields_chatbotSequence"] | undefined,
	thumbnail?: GraphQLTypes["EventsDocAccessFields_thumbnail"] | undefined,
	banner?: GraphQLTypes["EventsDocAccessFields_banner"] | undefined,
	mobileBanner?: GraphQLTypes["EventsDocAccessFields_mobileBanner"] | undefined,
	sections?: GraphQLTypes["EventsDocAccessFields_sections"] | undefined,
	ticketing?: GraphQLTypes["EventsDocAccessFields_ticketing"] | undefined,
	content?: GraphQLTypes["EventsDocAccessFields_content"] | undefined,
	legacyContent?: GraphQLTypes["EventsDocAccessFields_legacyContent"] | undefined,
	showLegacyContent?: GraphQLTypes["EventsDocAccessFields_showLegacyContent"] | undefined,
	criteria?: GraphQLTypes["EventsDocAccessFields_criteria"] | undefined,
	walkIn?: GraphQLTypes["EventsDocAccessFields_walkIn"] | undefined,
	contact?: GraphQLTypes["EventsDocAccessFields_contact"] | undefined,
	views?: GraphQLTypes["EventsDocAccessFields_views"] | undefined,
	free?: GraphQLTypes["EventsDocAccessFields_free"] | undefined,
	fees?: GraphQLTypes["EventsDocAccessFields_fees"] | undefined,
	homePageInternalClick?: GraphQLTypes["EventsDocAccessFields_homePageInternalClick"] | undefined,
	homePageImpression?: GraphQLTypes["EventsDocAccessFields_homePageImpression"] | undefined,
	eodClickRate?: GraphQLTypes["EventsDocAccessFields_eodClickRate"] | undefined,
	eodImpressionRate?: GraphQLTypes["EventsDocAccessFields_eodImpressionRate"] | undefined,
	top10ClickRateHomepage?: GraphQLTypes["EventsDocAccessFields_top10ClickRateHomepage"] | undefined,
	top10ImpressionkRateHomepage?: GraphQLTypes["EventsDocAccessFields_top10ImpressionkRateHomepage"] | undefined,
	otherEventRankingClickRateHomepage?: GraphQLTypes["EventsDocAccessFields_otherEventRankingClickRateHomepage"] | undefined,
	otherEventRankingImpressionHomePage?: GraphQLTypes["EventsDocAccessFields_otherEventRankingImpressionHomePage"] | undefined,
	userPreferenceClickRate?: GraphQLTypes["EventsDocAccessFields_userPreferenceClickRate"] | undefined,
	userPreferenceImpression?: GraphQLTypes["EventsDocAccessFields_userPreferenceImpression"] | undefined,
	eventSearchInternalClick?: GraphQLTypes["EventsDocAccessFields_eventSearchInternalClick"] | undefined,
	eventListImpression?: GraphQLTypes["EventsDocAccessFields_eventListImpression"] | undefined,
	latestEventInternalClick?: GraphQLTypes["EventsDocAccessFields_latestEventInternalClick"] | undefined,
	latestEventImpression?: GraphQLTypes["EventsDocAccessFields_latestEventImpression"] | undefined,
	topTenEventRankingInternalClick?: GraphQLTypes["EventsDocAccessFields_topTenEventRankingInternalClick"] | undefined,
	topTenImpression?: GraphQLTypes["EventsDocAccessFields_topTenImpression"] | undefined,
	editorChoiceInternalClick?: GraphQLTypes["EventsDocAccessFields_editorChoiceInternalClick"] | undefined,
	editorChoiceImpression?: GraphQLTypes["EventsDocAccessFields_editorChoiceImpression"] | undefined,
	otherEventRankingInternalClick?: GraphQLTypes["EventsDocAccessFields_otherEventRankingInternalClick"] | undefined,
	otherEventRankingImpression?: GraphQLTypes["EventsDocAccessFields_otherEventRankingImpression"] | undefined,
	bannerInternalClick?: GraphQLTypes["EventsDocAccessFields_bannerInternalClick"] | undefined,
	miscImpression?: GraphQLTypes["EventsDocAccessFields_miscImpression"] | undefined,
	miscInternalClick?: GraphQLTypes["EventsDocAccessFields_miscInternalClick"] | undefined,
	totalImpression?: GraphQLTypes["EventsDocAccessFields_totalImpression"] | undefined,
	internalClick?: GraphQLTypes["EventsDocAccessFields_internalClick"] | undefined,
	outboundClick?: GraphQLTypes["EventsDocAccessFields_outboundClick"] | undefined,
	externalClick?: GraphQLTypes["EventsDocAccessFields_externalClick"] | undefined,
	_viewsCountByMonth?: GraphQLTypes["EventsDocAccessFields__viewsCountByMonth"] | undefined,
	products?: GraphQLTypes["EventsDocAccessFields_products"] | undefined,
	legacyGallery?: GraphQLTypes["EventsDocAccessFields_legacyGallery"] | undefined,
	earliestStartDate?: GraphQLTypes["EventsDocAccessFields_earliestStartDate"] | undefined,
	latestEndDate?: GraphQLTypes["EventsDocAccessFields_latestEndDate"] | undefined,
	latestStartDateTime?: GraphQLTypes["EventsDocAccessFields_latestStartDateTime"] | undefined,
	hasTicketing?: GraphQLTypes["EventsDocAccessFields_hasTicketing"] | undefined,
	hasProducts?: GraphQLTypes["EventsDocAccessFields_hasProducts"] | undefined,
	updatedAt?: GraphQLTypes["EventsDocAccessFields_updatedAt"] | undefined,
	createdAt?: GraphQLTypes["EventsDocAccessFields_createdAt"] | undefined
};
	["EventsDocAccessFields_region"]: {
	__typename: "EventsDocAccessFields_region",
	create?: GraphQLTypes["EventsDocAccessFields_region_Create"] | undefined,
	read?: GraphQLTypes["EventsDocAccessFields_region_Read"] | undefined,
	update?: GraphQLTypes["EventsDocAccessFields_region_Update"] | undefined,
	delete?: GraphQLTypes["EventsDocAccessFields_region_Delete"] | undefined
};
	["EventsDocAccessFields_region_Create"]: {
	__typename: "EventsDocAccessFields_region_Create",
	permission: boolean
};
	["EventsDocAccessFields_region_Read"]: {
	__typename: "EventsDocAccessFields_region_Read",
	permission: boolean
};
	["EventsDocAccessFields_region_Update"]: {
	__typename: "EventsDocAccessFields_region_Update",
	permission: boolean
};
	["EventsDocAccessFields_region_Delete"]: {
	__typename: "EventsDocAccessFields_region_Delete",
	permission: boolean
};
	["EventsDocAccessFields_creator"]: {
	__typename: "EventsDocAccessFields_creator",
	create?: GraphQLTypes["EventsDocAccessFields_creator_Create"] | undefined,
	read?: GraphQLTypes["EventsDocAccessFields_creator_Read"] | undefined,
	update?: GraphQLTypes["EventsDocAccessFields_creator_Update"] | undefined,
	delete?: GraphQLTypes["EventsDocAccessFields_creator_Delete"] | undefined
};
	["EventsDocAccessFields_creator_Create"]: {
	__typename: "EventsDocAccessFields_creator_Create",
	permission: boolean
};
	["EventsDocAccessFields_creator_Read"]: {
	__typename: "EventsDocAccessFields_creator_Read",
	permission: boolean
};
	["EventsDocAccessFields_creator_Update"]: {
	__typename: "EventsDocAccessFields_creator_Update",
	permission: boolean
};
	["EventsDocAccessFields_creator_Delete"]: {
	__typename: "EventsDocAccessFields_creator_Delete",
	permission: boolean
};
	["EventsDocAccessFields_permalink"]: {
	__typename: "EventsDocAccessFields_permalink",
	create?: GraphQLTypes["EventsDocAccessFields_permalink_Create"] | undefined,
	read?: GraphQLTypes["EventsDocAccessFields_permalink_Read"] | undefined,
	update?: GraphQLTypes["EventsDocAccessFields_permalink_Update"] | undefined,
	delete?: GraphQLTypes["EventsDocAccessFields_permalink_Delete"] | undefined
};
	["EventsDocAccessFields_permalink_Create"]: {
	__typename: "EventsDocAccessFields_permalink_Create",
	permission: boolean
};
	["EventsDocAccessFields_permalink_Read"]: {
	__typename: "EventsDocAccessFields_permalink_Read",
	permission: boolean
};
	["EventsDocAccessFields_permalink_Update"]: {
	__typename: "EventsDocAccessFields_permalink_Update",
	permission: boolean
};
	["EventsDocAccessFields_permalink_Delete"]: {
	__typename: "EventsDocAccessFields_permalink_Delete",
	permission: boolean
};
	["EventsDocAccessFields_status"]: {
	__typename: "EventsDocAccessFields_status",
	create?: GraphQLTypes["EventsDocAccessFields_status_Create"] | undefined,
	read?: GraphQLTypes["EventsDocAccessFields_status_Read"] | undefined,
	update?: GraphQLTypes["EventsDocAccessFields_status_Update"] | undefined,
	delete?: GraphQLTypes["EventsDocAccessFields_status_Delete"] | undefined
};
	["EventsDocAccessFields_status_Create"]: {
	__typename: "EventsDocAccessFields_status_Create",
	permission: boolean
};
	["EventsDocAccessFields_status_Read"]: {
	__typename: "EventsDocAccessFields_status_Read",
	permission: boolean
};
	["EventsDocAccessFields_status_Update"]: {
	__typename: "EventsDocAccessFields_status_Update",
	permission: boolean
};
	["EventsDocAccessFields_status_Delete"]: {
	__typename: "EventsDocAccessFields_status_Delete",
	permission: boolean
};
	["EventsDocAccessFields_level"]: {
	__typename: "EventsDocAccessFields_level",
	create?: GraphQLTypes["EventsDocAccessFields_level_Create"] | undefined,
	read?: GraphQLTypes["EventsDocAccessFields_level_Read"] | undefined,
	update?: GraphQLTypes["EventsDocAccessFields_level_Update"] | undefined,
	delete?: GraphQLTypes["EventsDocAccessFields_level_Delete"] | undefined
};
	["EventsDocAccessFields_level_Create"]: {
	__typename: "EventsDocAccessFields_level_Create",
	permission: boolean
};
	["EventsDocAccessFields_level_Read"]: {
	__typename: "EventsDocAccessFields_level_Read",
	permission: boolean
};
	["EventsDocAccessFields_level_Update"]: {
	__typename: "EventsDocAccessFields_level_Update",
	permission: boolean
};
	["EventsDocAccessFields_level_Delete"]: {
	__typename: "EventsDocAccessFields_level_Delete",
	permission: boolean
};
	["EventsDocAccessFields_parent"]: {
	__typename: "EventsDocAccessFields_parent",
	create?: GraphQLTypes["EventsDocAccessFields_parent_Create"] | undefined,
	read?: GraphQLTypes["EventsDocAccessFields_parent_Read"] | undefined,
	update?: GraphQLTypes["EventsDocAccessFields_parent_Update"] | undefined,
	delete?: GraphQLTypes["EventsDocAccessFields_parent_Delete"] | undefined
};
	["EventsDocAccessFields_parent_Create"]: {
	__typename: "EventsDocAccessFields_parent_Create",
	permission: boolean
};
	["EventsDocAccessFields_parent_Read"]: {
	__typename: "EventsDocAccessFields_parent_Read",
	permission: boolean
};
	["EventsDocAccessFields_parent_Update"]: {
	__typename: "EventsDocAccessFields_parent_Update",
	permission: boolean
};
	["EventsDocAccessFields_parent_Delete"]: {
	__typename: "EventsDocAccessFields_parent_Delete",
	permission: boolean
};
	["EventsDocAccessFields_name"]: {
	__typename: "EventsDocAccessFields_name",
	create?: GraphQLTypes["EventsDocAccessFields_name_Create"] | undefined,
	read?: GraphQLTypes["EventsDocAccessFields_name_Read"] | undefined,
	update?: GraphQLTypes["EventsDocAccessFields_name_Update"] | undefined,
	delete?: GraphQLTypes["EventsDocAccessFields_name_Delete"] | undefined
};
	["EventsDocAccessFields_name_Create"]: {
	__typename: "EventsDocAccessFields_name_Create",
	permission: boolean
};
	["EventsDocAccessFields_name_Read"]: {
	__typename: "EventsDocAccessFields_name_Read",
	permission: boolean
};
	["EventsDocAccessFields_name_Update"]: {
	__typename: "EventsDocAccessFields_name_Update",
	permission: boolean
};
	["EventsDocAccessFields_name_Delete"]: {
	__typename: "EventsDocAccessFields_name_Delete",
	permission: boolean
};
	["EventsDocAccessFields_chatbotEventTitle"]: {
	__typename: "EventsDocAccessFields_chatbotEventTitle",
	create?: GraphQLTypes["EventsDocAccessFields_chatbotEventTitle_Create"] | undefined,
	read?: GraphQLTypes["EventsDocAccessFields_chatbotEventTitle_Read"] | undefined,
	update?: GraphQLTypes["EventsDocAccessFields_chatbotEventTitle_Update"] | undefined,
	delete?: GraphQLTypes["EventsDocAccessFields_chatbotEventTitle_Delete"] | undefined
};
	["EventsDocAccessFields_chatbotEventTitle_Create"]: {
	__typename: "EventsDocAccessFields_chatbotEventTitle_Create",
	permission: boolean
};
	["EventsDocAccessFields_chatbotEventTitle_Read"]: {
	__typename: "EventsDocAccessFields_chatbotEventTitle_Read",
	permission: boolean
};
	["EventsDocAccessFields_chatbotEventTitle_Update"]: {
	__typename: "EventsDocAccessFields_chatbotEventTitle_Update",
	permission: boolean
};
	["EventsDocAccessFields_chatbotEventTitle_Delete"]: {
	__typename: "EventsDocAccessFields_chatbotEventTitle_Delete",
	permission: boolean
};
	["EventsDocAccessFields_chatbotSequence"]: {
	__typename: "EventsDocAccessFields_chatbotSequence",
	create?: GraphQLTypes["EventsDocAccessFields_chatbotSequence_Create"] | undefined,
	read?: GraphQLTypes["EventsDocAccessFields_chatbotSequence_Read"] | undefined,
	update?: GraphQLTypes["EventsDocAccessFields_chatbotSequence_Update"] | undefined,
	delete?: GraphQLTypes["EventsDocAccessFields_chatbotSequence_Delete"] | undefined
};
	["EventsDocAccessFields_chatbotSequence_Create"]: {
	__typename: "EventsDocAccessFields_chatbotSequence_Create",
	permission: boolean
};
	["EventsDocAccessFields_chatbotSequence_Read"]: {
	__typename: "EventsDocAccessFields_chatbotSequence_Read",
	permission: boolean
};
	["EventsDocAccessFields_chatbotSequence_Update"]: {
	__typename: "EventsDocAccessFields_chatbotSequence_Update",
	permission: boolean
};
	["EventsDocAccessFields_chatbotSequence_Delete"]: {
	__typename: "EventsDocAccessFields_chatbotSequence_Delete",
	permission: boolean
};
	["EventsDocAccessFields_thumbnail"]: {
	__typename: "EventsDocAccessFields_thumbnail",
	create?: GraphQLTypes["EventsDocAccessFields_thumbnail_Create"] | undefined,
	read?: GraphQLTypes["EventsDocAccessFields_thumbnail_Read"] | undefined,
	update?: GraphQLTypes["EventsDocAccessFields_thumbnail_Update"] | undefined,
	delete?: GraphQLTypes["EventsDocAccessFields_thumbnail_Delete"] | undefined
};
	["EventsDocAccessFields_thumbnail_Create"]: {
	__typename: "EventsDocAccessFields_thumbnail_Create",
	permission: boolean
};
	["EventsDocAccessFields_thumbnail_Read"]: {
	__typename: "EventsDocAccessFields_thumbnail_Read",
	permission: boolean
};
	["EventsDocAccessFields_thumbnail_Update"]: {
	__typename: "EventsDocAccessFields_thumbnail_Update",
	permission: boolean
};
	["EventsDocAccessFields_thumbnail_Delete"]: {
	__typename: "EventsDocAccessFields_thumbnail_Delete",
	permission: boolean
};
	["EventsDocAccessFields_banner"]: {
	__typename: "EventsDocAccessFields_banner",
	create?: GraphQLTypes["EventsDocAccessFields_banner_Create"] | undefined,
	read?: GraphQLTypes["EventsDocAccessFields_banner_Read"] | undefined,
	update?: GraphQLTypes["EventsDocAccessFields_banner_Update"] | undefined,
	delete?: GraphQLTypes["EventsDocAccessFields_banner_Delete"] | undefined
};
	["EventsDocAccessFields_banner_Create"]: {
	__typename: "EventsDocAccessFields_banner_Create",
	permission: boolean
};
	["EventsDocAccessFields_banner_Read"]: {
	__typename: "EventsDocAccessFields_banner_Read",
	permission: boolean
};
	["EventsDocAccessFields_banner_Update"]: {
	__typename: "EventsDocAccessFields_banner_Update",
	permission: boolean
};
	["EventsDocAccessFields_banner_Delete"]: {
	__typename: "EventsDocAccessFields_banner_Delete",
	permission: boolean
};
	["EventsDocAccessFields_mobileBanner"]: {
	__typename: "EventsDocAccessFields_mobileBanner",
	create?: GraphQLTypes["EventsDocAccessFields_mobileBanner_Create"] | undefined,
	read?: GraphQLTypes["EventsDocAccessFields_mobileBanner_Read"] | undefined,
	update?: GraphQLTypes["EventsDocAccessFields_mobileBanner_Update"] | undefined,
	delete?: GraphQLTypes["EventsDocAccessFields_mobileBanner_Delete"] | undefined
};
	["EventsDocAccessFields_mobileBanner_Create"]: {
	__typename: "EventsDocAccessFields_mobileBanner_Create",
	permission: boolean
};
	["EventsDocAccessFields_mobileBanner_Read"]: {
	__typename: "EventsDocAccessFields_mobileBanner_Read",
	permission: boolean
};
	["EventsDocAccessFields_mobileBanner_Update"]: {
	__typename: "EventsDocAccessFields_mobileBanner_Update",
	permission: boolean
};
	["EventsDocAccessFields_mobileBanner_Delete"]: {
	__typename: "EventsDocAccessFields_mobileBanner_Delete",
	permission: boolean
};
	["EventsDocAccessFields_sections"]: {
	__typename: "EventsDocAccessFields_sections",
	create?: GraphQLTypes["EventsDocAccessFields_sections_Create"] | undefined,
	read?: GraphQLTypes["EventsDocAccessFields_sections_Read"] | undefined,
	update?: GraphQLTypes["EventsDocAccessFields_sections_Update"] | undefined,
	delete?: GraphQLTypes["EventsDocAccessFields_sections_Delete"] | undefined,
	fields?: GraphQLTypes["EventsDocAccessFields_sections_Fields"] | undefined
};
	["EventsDocAccessFields_sections_Create"]: {
	__typename: "EventsDocAccessFields_sections_Create",
	permission: boolean
};
	["EventsDocAccessFields_sections_Read"]: {
	__typename: "EventsDocAccessFields_sections_Read",
	permission: boolean
};
	["EventsDocAccessFields_sections_Update"]: {
	__typename: "EventsDocAccessFields_sections_Update",
	permission: boolean
};
	["EventsDocAccessFields_sections_Delete"]: {
	__typename: "EventsDocAccessFields_sections_Delete",
	permission: boolean
};
	["EventsDocAccessFields_sections_Fields"]: {
	__typename: "EventsDocAccessFields_sections_Fields",
	startDatetime?: GraphQLTypes["EventsDocAccessFields_sections_startDatetime"] | undefined,
	endDatetime?: GraphQLTypes["EventsDocAccessFields_sections_endDatetime"] | undefined,
	fullDay?: GraphQLTypes["EventsDocAccessFields_sections_fullDay"] | undefined,
	repeat?: GraphQLTypes["EventsDocAccessFields_sections_repeat"] | undefined,
	toThisDay?: GraphQLTypes["EventsDocAccessFields_sections_toThisDay"] | undefined,
	recurrance?: GraphQLTypes["EventsDocAccessFields_sections_recurrance"] | undefined,
	sameLocation?: GraphQLTypes["EventsDocAccessFields_sections_sameLocation"] | undefined,
	location?: GraphQLTypes["EventsDocAccessFields_sections_location"] | undefined,
	district?: GraphQLTypes["EventsDocAccessFields_sections_district"] | undefined,
	coordinate?: GraphQLTypes["EventsDocAccessFields_sections_coordinate"] | undefined,
	linkAddress?: GraphQLTypes["EventsDocAccessFields_sections_linkAddress"] | undefined,
	address?: GraphQLTypes["EventsDocAccessFields_sections_address"] | undefined,
	id?: GraphQLTypes["EventsDocAccessFields_sections_id"] | undefined
};
	["EventsDocAccessFields_sections_startDatetime"]: {
	__typename: "EventsDocAccessFields_sections_startDatetime",
	create?: GraphQLTypes["EventsDocAccessFields_sections_startDatetime_Create"] | undefined,
	read?: GraphQLTypes["EventsDocAccessFields_sections_startDatetime_Read"] | undefined,
	update?: GraphQLTypes["EventsDocAccessFields_sections_startDatetime_Update"] | undefined,
	delete?: GraphQLTypes["EventsDocAccessFields_sections_startDatetime_Delete"] | undefined
};
	["EventsDocAccessFields_sections_startDatetime_Create"]: {
	__typename: "EventsDocAccessFields_sections_startDatetime_Create",
	permission: boolean
};
	["EventsDocAccessFields_sections_startDatetime_Read"]: {
	__typename: "EventsDocAccessFields_sections_startDatetime_Read",
	permission: boolean
};
	["EventsDocAccessFields_sections_startDatetime_Update"]: {
	__typename: "EventsDocAccessFields_sections_startDatetime_Update",
	permission: boolean
};
	["EventsDocAccessFields_sections_startDatetime_Delete"]: {
	__typename: "EventsDocAccessFields_sections_startDatetime_Delete",
	permission: boolean
};
	["EventsDocAccessFields_sections_endDatetime"]: {
	__typename: "EventsDocAccessFields_sections_endDatetime",
	create?: GraphQLTypes["EventsDocAccessFields_sections_endDatetime_Create"] | undefined,
	read?: GraphQLTypes["EventsDocAccessFields_sections_endDatetime_Read"] | undefined,
	update?: GraphQLTypes["EventsDocAccessFields_sections_endDatetime_Update"] | undefined,
	delete?: GraphQLTypes["EventsDocAccessFields_sections_endDatetime_Delete"] | undefined
};
	["EventsDocAccessFields_sections_endDatetime_Create"]: {
	__typename: "EventsDocAccessFields_sections_endDatetime_Create",
	permission: boolean
};
	["EventsDocAccessFields_sections_endDatetime_Read"]: {
	__typename: "EventsDocAccessFields_sections_endDatetime_Read",
	permission: boolean
};
	["EventsDocAccessFields_sections_endDatetime_Update"]: {
	__typename: "EventsDocAccessFields_sections_endDatetime_Update",
	permission: boolean
};
	["EventsDocAccessFields_sections_endDatetime_Delete"]: {
	__typename: "EventsDocAccessFields_sections_endDatetime_Delete",
	permission: boolean
};
	["EventsDocAccessFields_sections_fullDay"]: {
	__typename: "EventsDocAccessFields_sections_fullDay",
	create?: GraphQLTypes["EventsDocAccessFields_sections_fullDay_Create"] | undefined,
	read?: GraphQLTypes["EventsDocAccessFields_sections_fullDay_Read"] | undefined,
	update?: GraphQLTypes["EventsDocAccessFields_sections_fullDay_Update"] | undefined,
	delete?: GraphQLTypes["EventsDocAccessFields_sections_fullDay_Delete"] | undefined
};
	["EventsDocAccessFields_sections_fullDay_Create"]: {
	__typename: "EventsDocAccessFields_sections_fullDay_Create",
	permission: boolean
};
	["EventsDocAccessFields_sections_fullDay_Read"]: {
	__typename: "EventsDocAccessFields_sections_fullDay_Read",
	permission: boolean
};
	["EventsDocAccessFields_sections_fullDay_Update"]: {
	__typename: "EventsDocAccessFields_sections_fullDay_Update",
	permission: boolean
};
	["EventsDocAccessFields_sections_fullDay_Delete"]: {
	__typename: "EventsDocAccessFields_sections_fullDay_Delete",
	permission: boolean
};
	["EventsDocAccessFields_sections_repeat"]: {
	__typename: "EventsDocAccessFields_sections_repeat",
	create?: GraphQLTypes["EventsDocAccessFields_sections_repeat_Create"] | undefined,
	read?: GraphQLTypes["EventsDocAccessFields_sections_repeat_Read"] | undefined,
	update?: GraphQLTypes["EventsDocAccessFields_sections_repeat_Update"] | undefined,
	delete?: GraphQLTypes["EventsDocAccessFields_sections_repeat_Delete"] | undefined
};
	["EventsDocAccessFields_sections_repeat_Create"]: {
	__typename: "EventsDocAccessFields_sections_repeat_Create",
	permission: boolean
};
	["EventsDocAccessFields_sections_repeat_Read"]: {
	__typename: "EventsDocAccessFields_sections_repeat_Read",
	permission: boolean
};
	["EventsDocAccessFields_sections_repeat_Update"]: {
	__typename: "EventsDocAccessFields_sections_repeat_Update",
	permission: boolean
};
	["EventsDocAccessFields_sections_repeat_Delete"]: {
	__typename: "EventsDocAccessFields_sections_repeat_Delete",
	permission: boolean
};
	["EventsDocAccessFields_sections_toThisDay"]: {
	__typename: "EventsDocAccessFields_sections_toThisDay",
	create?: GraphQLTypes["EventsDocAccessFields_sections_toThisDay_Create"] | undefined,
	read?: GraphQLTypes["EventsDocAccessFields_sections_toThisDay_Read"] | undefined,
	update?: GraphQLTypes["EventsDocAccessFields_sections_toThisDay_Update"] | undefined,
	delete?: GraphQLTypes["EventsDocAccessFields_sections_toThisDay_Delete"] | undefined
};
	["EventsDocAccessFields_sections_toThisDay_Create"]: {
	__typename: "EventsDocAccessFields_sections_toThisDay_Create",
	permission: boolean
};
	["EventsDocAccessFields_sections_toThisDay_Read"]: {
	__typename: "EventsDocAccessFields_sections_toThisDay_Read",
	permission: boolean
};
	["EventsDocAccessFields_sections_toThisDay_Update"]: {
	__typename: "EventsDocAccessFields_sections_toThisDay_Update",
	permission: boolean
};
	["EventsDocAccessFields_sections_toThisDay_Delete"]: {
	__typename: "EventsDocAccessFields_sections_toThisDay_Delete",
	permission: boolean
};
	["EventsDocAccessFields_sections_recurrance"]: {
	__typename: "EventsDocAccessFields_sections_recurrance",
	create?: GraphQLTypes["EventsDocAccessFields_sections_recurrance_Create"] | undefined,
	read?: GraphQLTypes["EventsDocAccessFields_sections_recurrance_Read"] | undefined,
	update?: GraphQLTypes["EventsDocAccessFields_sections_recurrance_Update"] | undefined,
	delete?: GraphQLTypes["EventsDocAccessFields_sections_recurrance_Delete"] | undefined,
	fields?: GraphQLTypes["EventsDocAccessFields_sections_recurrance_Fields"] | undefined
};
	["EventsDocAccessFields_sections_recurrance_Create"]: {
	__typename: "EventsDocAccessFields_sections_recurrance_Create",
	permission: boolean
};
	["EventsDocAccessFields_sections_recurrance_Read"]: {
	__typename: "EventsDocAccessFields_sections_recurrance_Read",
	permission: boolean
};
	["EventsDocAccessFields_sections_recurrance_Update"]: {
	__typename: "EventsDocAccessFields_sections_recurrance_Update",
	permission: boolean
};
	["EventsDocAccessFields_sections_recurrance_Delete"]: {
	__typename: "EventsDocAccessFields_sections_recurrance_Delete",
	permission: boolean
};
	["EventsDocAccessFields_sections_recurrance_Fields"]: {
	__typename: "EventsDocAccessFields_sections_recurrance_Fields",
	type?: GraphQLTypes["EventsDocAccessFields_sections_recurrance_type"] | undefined,
	weekday?: GraphQLTypes["EventsDocAccessFields_sections_recurrance_weekday"] | undefined
};
	["EventsDocAccessFields_sections_recurrance_type"]: {
	__typename: "EventsDocAccessFields_sections_recurrance_type",
	create?: GraphQLTypes["EventsDocAccessFields_sections_recurrance_type_Create"] | undefined,
	read?: GraphQLTypes["EventsDocAccessFields_sections_recurrance_type_Read"] | undefined,
	update?: GraphQLTypes["EventsDocAccessFields_sections_recurrance_type_Update"] | undefined,
	delete?: GraphQLTypes["EventsDocAccessFields_sections_recurrance_type_Delete"] | undefined
};
	["EventsDocAccessFields_sections_recurrance_type_Create"]: {
	__typename: "EventsDocAccessFields_sections_recurrance_type_Create",
	permission: boolean
};
	["EventsDocAccessFields_sections_recurrance_type_Read"]: {
	__typename: "EventsDocAccessFields_sections_recurrance_type_Read",
	permission: boolean
};
	["EventsDocAccessFields_sections_recurrance_type_Update"]: {
	__typename: "EventsDocAccessFields_sections_recurrance_type_Update",
	permission: boolean
};
	["EventsDocAccessFields_sections_recurrance_type_Delete"]: {
	__typename: "EventsDocAccessFields_sections_recurrance_type_Delete",
	permission: boolean
};
	["EventsDocAccessFields_sections_recurrance_weekday"]: {
	__typename: "EventsDocAccessFields_sections_recurrance_weekday",
	create?: GraphQLTypes["EventsDocAccessFields_sections_recurrance_weekday_Create"] | undefined,
	read?: GraphQLTypes["EventsDocAccessFields_sections_recurrance_weekday_Read"] | undefined,
	update?: GraphQLTypes["EventsDocAccessFields_sections_recurrance_weekday_Update"] | undefined,
	delete?: GraphQLTypes["EventsDocAccessFields_sections_recurrance_weekday_Delete"] | undefined
};
	["EventsDocAccessFields_sections_recurrance_weekday_Create"]: {
	__typename: "EventsDocAccessFields_sections_recurrance_weekday_Create",
	permission: boolean
};
	["EventsDocAccessFields_sections_recurrance_weekday_Read"]: {
	__typename: "EventsDocAccessFields_sections_recurrance_weekday_Read",
	permission: boolean
};
	["EventsDocAccessFields_sections_recurrance_weekday_Update"]: {
	__typename: "EventsDocAccessFields_sections_recurrance_weekday_Update",
	permission: boolean
};
	["EventsDocAccessFields_sections_recurrance_weekday_Delete"]: {
	__typename: "EventsDocAccessFields_sections_recurrance_weekday_Delete",
	permission: boolean
};
	["EventsDocAccessFields_sections_sameLocation"]: {
	__typename: "EventsDocAccessFields_sections_sameLocation",
	create?: GraphQLTypes["EventsDocAccessFields_sections_sameLocation_Create"] | undefined,
	read?: GraphQLTypes["EventsDocAccessFields_sections_sameLocation_Read"] | undefined,
	update?: GraphQLTypes["EventsDocAccessFields_sections_sameLocation_Update"] | undefined,
	delete?: GraphQLTypes["EventsDocAccessFields_sections_sameLocation_Delete"] | undefined
};
	["EventsDocAccessFields_sections_sameLocation_Create"]: {
	__typename: "EventsDocAccessFields_sections_sameLocation_Create",
	permission: boolean
};
	["EventsDocAccessFields_sections_sameLocation_Read"]: {
	__typename: "EventsDocAccessFields_sections_sameLocation_Read",
	permission: boolean
};
	["EventsDocAccessFields_sections_sameLocation_Update"]: {
	__typename: "EventsDocAccessFields_sections_sameLocation_Update",
	permission: boolean
};
	["EventsDocAccessFields_sections_sameLocation_Delete"]: {
	__typename: "EventsDocAccessFields_sections_sameLocation_Delete",
	permission: boolean
};
	["EventsDocAccessFields_sections_location"]: {
	__typename: "EventsDocAccessFields_sections_location",
	create?: GraphQLTypes["EventsDocAccessFields_sections_location_Create"] | undefined,
	read?: GraphQLTypes["EventsDocAccessFields_sections_location_Read"] | undefined,
	update?: GraphQLTypes["EventsDocAccessFields_sections_location_Update"] | undefined,
	delete?: GraphQLTypes["EventsDocAccessFields_sections_location_Delete"] | undefined
};
	["EventsDocAccessFields_sections_location_Create"]: {
	__typename: "EventsDocAccessFields_sections_location_Create",
	permission: boolean
};
	["EventsDocAccessFields_sections_location_Read"]: {
	__typename: "EventsDocAccessFields_sections_location_Read",
	permission: boolean
};
	["EventsDocAccessFields_sections_location_Update"]: {
	__typename: "EventsDocAccessFields_sections_location_Update",
	permission: boolean
};
	["EventsDocAccessFields_sections_location_Delete"]: {
	__typename: "EventsDocAccessFields_sections_location_Delete",
	permission: boolean
};
	["EventsDocAccessFields_sections_district"]: {
	__typename: "EventsDocAccessFields_sections_district",
	create?: GraphQLTypes["EventsDocAccessFields_sections_district_Create"] | undefined,
	read?: GraphQLTypes["EventsDocAccessFields_sections_district_Read"] | undefined,
	update?: GraphQLTypes["EventsDocAccessFields_sections_district_Update"] | undefined,
	delete?: GraphQLTypes["EventsDocAccessFields_sections_district_Delete"] | undefined
};
	["EventsDocAccessFields_sections_district_Create"]: {
	__typename: "EventsDocAccessFields_sections_district_Create",
	permission: boolean
};
	["EventsDocAccessFields_sections_district_Read"]: {
	__typename: "EventsDocAccessFields_sections_district_Read",
	permission: boolean
};
	["EventsDocAccessFields_sections_district_Update"]: {
	__typename: "EventsDocAccessFields_sections_district_Update",
	permission: boolean
};
	["EventsDocAccessFields_sections_district_Delete"]: {
	__typename: "EventsDocAccessFields_sections_district_Delete",
	permission: boolean
};
	["EventsDocAccessFields_sections_coordinate"]: {
	__typename: "EventsDocAccessFields_sections_coordinate",
	create?: GraphQLTypes["EventsDocAccessFields_sections_coordinate_Create"] | undefined,
	read?: GraphQLTypes["EventsDocAccessFields_sections_coordinate_Read"] | undefined,
	update?: GraphQLTypes["EventsDocAccessFields_sections_coordinate_Update"] | undefined,
	delete?: GraphQLTypes["EventsDocAccessFields_sections_coordinate_Delete"] | undefined
};
	["EventsDocAccessFields_sections_coordinate_Create"]: {
	__typename: "EventsDocAccessFields_sections_coordinate_Create",
	permission: boolean
};
	["EventsDocAccessFields_sections_coordinate_Read"]: {
	__typename: "EventsDocAccessFields_sections_coordinate_Read",
	permission: boolean
};
	["EventsDocAccessFields_sections_coordinate_Update"]: {
	__typename: "EventsDocAccessFields_sections_coordinate_Update",
	permission: boolean
};
	["EventsDocAccessFields_sections_coordinate_Delete"]: {
	__typename: "EventsDocAccessFields_sections_coordinate_Delete",
	permission: boolean
};
	["EventsDocAccessFields_sections_linkAddress"]: {
	__typename: "EventsDocAccessFields_sections_linkAddress",
	create?: GraphQLTypes["EventsDocAccessFields_sections_linkAddress_Create"] | undefined,
	read?: GraphQLTypes["EventsDocAccessFields_sections_linkAddress_Read"] | undefined,
	update?: GraphQLTypes["EventsDocAccessFields_sections_linkAddress_Update"] | undefined,
	delete?: GraphQLTypes["EventsDocAccessFields_sections_linkAddress_Delete"] | undefined
};
	["EventsDocAccessFields_sections_linkAddress_Create"]: {
	__typename: "EventsDocAccessFields_sections_linkAddress_Create",
	permission: boolean
};
	["EventsDocAccessFields_sections_linkAddress_Read"]: {
	__typename: "EventsDocAccessFields_sections_linkAddress_Read",
	permission: boolean
};
	["EventsDocAccessFields_sections_linkAddress_Update"]: {
	__typename: "EventsDocAccessFields_sections_linkAddress_Update",
	permission: boolean
};
	["EventsDocAccessFields_sections_linkAddress_Delete"]: {
	__typename: "EventsDocAccessFields_sections_linkAddress_Delete",
	permission: boolean
};
	["EventsDocAccessFields_sections_address"]: {
	__typename: "EventsDocAccessFields_sections_address",
	create?: GraphQLTypes["EventsDocAccessFields_sections_address_Create"] | undefined,
	read?: GraphQLTypes["EventsDocAccessFields_sections_address_Read"] | undefined,
	update?: GraphQLTypes["EventsDocAccessFields_sections_address_Update"] | undefined,
	delete?: GraphQLTypes["EventsDocAccessFields_sections_address_Delete"] | undefined
};
	["EventsDocAccessFields_sections_address_Create"]: {
	__typename: "EventsDocAccessFields_sections_address_Create",
	permission: boolean
};
	["EventsDocAccessFields_sections_address_Read"]: {
	__typename: "EventsDocAccessFields_sections_address_Read",
	permission: boolean
};
	["EventsDocAccessFields_sections_address_Update"]: {
	__typename: "EventsDocAccessFields_sections_address_Update",
	permission: boolean
};
	["EventsDocAccessFields_sections_address_Delete"]: {
	__typename: "EventsDocAccessFields_sections_address_Delete",
	permission: boolean
};
	["EventsDocAccessFields_sections_id"]: {
	__typename: "EventsDocAccessFields_sections_id",
	create?: GraphQLTypes["EventsDocAccessFields_sections_id_Create"] | undefined,
	read?: GraphQLTypes["EventsDocAccessFields_sections_id_Read"] | undefined,
	update?: GraphQLTypes["EventsDocAccessFields_sections_id_Update"] | undefined,
	delete?: GraphQLTypes["EventsDocAccessFields_sections_id_Delete"] | undefined
};
	["EventsDocAccessFields_sections_id_Create"]: {
	__typename: "EventsDocAccessFields_sections_id_Create",
	permission: boolean
};
	["EventsDocAccessFields_sections_id_Read"]: {
	__typename: "EventsDocAccessFields_sections_id_Read",
	permission: boolean
};
	["EventsDocAccessFields_sections_id_Update"]: {
	__typename: "EventsDocAccessFields_sections_id_Update",
	permission: boolean
};
	["EventsDocAccessFields_sections_id_Delete"]: {
	__typename: "EventsDocAccessFields_sections_id_Delete",
	permission: boolean
};
	["EventsDocAccessFields_ticketing"]: {
	__typename: "EventsDocAccessFields_ticketing",
	create?: GraphQLTypes["EventsDocAccessFields_ticketing_Create"] | undefined,
	read?: GraphQLTypes["EventsDocAccessFields_ticketing_Read"] | undefined,
	update?: GraphQLTypes["EventsDocAccessFields_ticketing_Update"] | undefined,
	delete?: GraphQLTypes["EventsDocAccessFields_ticketing_Delete"] | undefined,
	fields?: GraphQLTypes["EventsDocAccessFields_ticketing_Fields"] | undefined
};
	["EventsDocAccessFields_ticketing_Create"]: {
	__typename: "EventsDocAccessFields_ticketing_Create",
	permission: boolean
};
	["EventsDocAccessFields_ticketing_Read"]: {
	__typename: "EventsDocAccessFields_ticketing_Read",
	permission: boolean
};
	["EventsDocAccessFields_ticketing_Update"]: {
	__typename: "EventsDocAccessFields_ticketing_Update",
	permission: boolean
};
	["EventsDocAccessFields_ticketing_Delete"]: {
	__typename: "EventsDocAccessFields_ticketing_Delete",
	permission: boolean
};
	["EventsDocAccessFields_ticketing_Fields"]: {
	__typename: "EventsDocAccessFields_ticketing_Fields",
	startDatetime?: GraphQLTypes["EventsDocAccessFields_ticketing_startDatetime"] | undefined,
	endDatetime?: GraphQLTypes["EventsDocAccessFields_ticketing_endDatetime"] | undefined,
	fullDay?: GraphQLTypes["EventsDocAccessFields_ticketing_fullDay"] | undefined,
	type?: GraphQLTypes["EventsDocAccessFields_ticketing_type"] | undefined,
	ticketingType?: GraphQLTypes["EventsDocAccessFields_ticketing_ticketingType"] | undefined,
	linkedTicketing?: GraphQLTypes["EventsDocAccessFields_ticketing_linkedTicketing"] | undefined,
	promotionType?: GraphQLTypes["EventsDocAccessFields_ticketing_promotionType"] | undefined,
	location?: GraphQLTypes["EventsDocAccessFields_ticketing_location"] | undefined,
	website?: GraphQLTypes["EventsDocAccessFields_ticketing_website"] | undefined,
	description?: GraphQLTypes["EventsDocAccessFields_ticketing_description"] | undefined,
	id?: GraphQLTypes["EventsDocAccessFields_ticketing_id"] | undefined
};
	["EventsDocAccessFields_ticketing_startDatetime"]: {
	__typename: "EventsDocAccessFields_ticketing_startDatetime",
	create?: GraphQLTypes["EventsDocAccessFields_ticketing_startDatetime_Create"] | undefined,
	read?: GraphQLTypes["EventsDocAccessFields_ticketing_startDatetime_Read"] | undefined,
	update?: GraphQLTypes["EventsDocAccessFields_ticketing_startDatetime_Update"] | undefined,
	delete?: GraphQLTypes["EventsDocAccessFields_ticketing_startDatetime_Delete"] | undefined
};
	["EventsDocAccessFields_ticketing_startDatetime_Create"]: {
	__typename: "EventsDocAccessFields_ticketing_startDatetime_Create",
	permission: boolean
};
	["EventsDocAccessFields_ticketing_startDatetime_Read"]: {
	__typename: "EventsDocAccessFields_ticketing_startDatetime_Read",
	permission: boolean
};
	["EventsDocAccessFields_ticketing_startDatetime_Update"]: {
	__typename: "EventsDocAccessFields_ticketing_startDatetime_Update",
	permission: boolean
};
	["EventsDocAccessFields_ticketing_startDatetime_Delete"]: {
	__typename: "EventsDocAccessFields_ticketing_startDatetime_Delete",
	permission: boolean
};
	["EventsDocAccessFields_ticketing_endDatetime"]: {
	__typename: "EventsDocAccessFields_ticketing_endDatetime",
	create?: GraphQLTypes["EventsDocAccessFields_ticketing_endDatetime_Create"] | undefined,
	read?: GraphQLTypes["EventsDocAccessFields_ticketing_endDatetime_Read"] | undefined,
	update?: GraphQLTypes["EventsDocAccessFields_ticketing_endDatetime_Update"] | undefined,
	delete?: GraphQLTypes["EventsDocAccessFields_ticketing_endDatetime_Delete"] | undefined
};
	["EventsDocAccessFields_ticketing_endDatetime_Create"]: {
	__typename: "EventsDocAccessFields_ticketing_endDatetime_Create",
	permission: boolean
};
	["EventsDocAccessFields_ticketing_endDatetime_Read"]: {
	__typename: "EventsDocAccessFields_ticketing_endDatetime_Read",
	permission: boolean
};
	["EventsDocAccessFields_ticketing_endDatetime_Update"]: {
	__typename: "EventsDocAccessFields_ticketing_endDatetime_Update",
	permission: boolean
};
	["EventsDocAccessFields_ticketing_endDatetime_Delete"]: {
	__typename: "EventsDocAccessFields_ticketing_endDatetime_Delete",
	permission: boolean
};
	["EventsDocAccessFields_ticketing_fullDay"]: {
	__typename: "EventsDocAccessFields_ticketing_fullDay",
	create?: GraphQLTypes["EventsDocAccessFields_ticketing_fullDay_Create"] | undefined,
	read?: GraphQLTypes["EventsDocAccessFields_ticketing_fullDay_Read"] | undefined,
	update?: GraphQLTypes["EventsDocAccessFields_ticketing_fullDay_Update"] | undefined,
	delete?: GraphQLTypes["EventsDocAccessFields_ticketing_fullDay_Delete"] | undefined
};
	["EventsDocAccessFields_ticketing_fullDay_Create"]: {
	__typename: "EventsDocAccessFields_ticketing_fullDay_Create",
	permission: boolean
};
	["EventsDocAccessFields_ticketing_fullDay_Read"]: {
	__typename: "EventsDocAccessFields_ticketing_fullDay_Read",
	permission: boolean
};
	["EventsDocAccessFields_ticketing_fullDay_Update"]: {
	__typename: "EventsDocAccessFields_ticketing_fullDay_Update",
	permission: boolean
};
	["EventsDocAccessFields_ticketing_fullDay_Delete"]: {
	__typename: "EventsDocAccessFields_ticketing_fullDay_Delete",
	permission: boolean
};
	["EventsDocAccessFields_ticketing_type"]: {
	__typename: "EventsDocAccessFields_ticketing_type",
	create?: GraphQLTypes["EventsDocAccessFields_ticketing_type_Create"] | undefined,
	read?: GraphQLTypes["EventsDocAccessFields_ticketing_type_Read"] | undefined,
	update?: GraphQLTypes["EventsDocAccessFields_ticketing_type_Update"] | undefined,
	delete?: GraphQLTypes["EventsDocAccessFields_ticketing_type_Delete"] | undefined
};
	["EventsDocAccessFields_ticketing_type_Create"]: {
	__typename: "EventsDocAccessFields_ticketing_type_Create",
	permission: boolean
};
	["EventsDocAccessFields_ticketing_type_Read"]: {
	__typename: "EventsDocAccessFields_ticketing_type_Read",
	permission: boolean
};
	["EventsDocAccessFields_ticketing_type_Update"]: {
	__typename: "EventsDocAccessFields_ticketing_type_Update",
	permission: boolean
};
	["EventsDocAccessFields_ticketing_type_Delete"]: {
	__typename: "EventsDocAccessFields_ticketing_type_Delete",
	permission: boolean
};
	["EventsDocAccessFields_ticketing_ticketingType"]: {
	__typename: "EventsDocAccessFields_ticketing_ticketingType",
	create?: GraphQLTypes["EventsDocAccessFields_ticketing_ticketingType_Create"] | undefined,
	read?: GraphQLTypes["EventsDocAccessFields_ticketing_ticketingType_Read"] | undefined,
	update?: GraphQLTypes["EventsDocAccessFields_ticketing_ticketingType_Update"] | undefined,
	delete?: GraphQLTypes["EventsDocAccessFields_ticketing_ticketingType_Delete"] | undefined
};
	["EventsDocAccessFields_ticketing_ticketingType_Create"]: {
	__typename: "EventsDocAccessFields_ticketing_ticketingType_Create",
	permission: boolean
};
	["EventsDocAccessFields_ticketing_ticketingType_Read"]: {
	__typename: "EventsDocAccessFields_ticketing_ticketingType_Read",
	permission: boolean
};
	["EventsDocAccessFields_ticketing_ticketingType_Update"]: {
	__typename: "EventsDocAccessFields_ticketing_ticketingType_Update",
	permission: boolean
};
	["EventsDocAccessFields_ticketing_ticketingType_Delete"]: {
	__typename: "EventsDocAccessFields_ticketing_ticketingType_Delete",
	permission: boolean
};
	["EventsDocAccessFields_ticketing_linkedTicketing"]: {
	__typename: "EventsDocAccessFields_ticketing_linkedTicketing",
	create?: GraphQLTypes["EventsDocAccessFields_ticketing_linkedTicketing_Create"] | undefined,
	read?: GraphQLTypes["EventsDocAccessFields_ticketing_linkedTicketing_Read"] | undefined,
	update?: GraphQLTypes["EventsDocAccessFields_ticketing_linkedTicketing_Update"] | undefined,
	delete?: GraphQLTypes["EventsDocAccessFields_ticketing_linkedTicketing_Delete"] | undefined
};
	["EventsDocAccessFields_ticketing_linkedTicketing_Create"]: {
	__typename: "EventsDocAccessFields_ticketing_linkedTicketing_Create",
	permission: boolean
};
	["EventsDocAccessFields_ticketing_linkedTicketing_Read"]: {
	__typename: "EventsDocAccessFields_ticketing_linkedTicketing_Read",
	permission: boolean
};
	["EventsDocAccessFields_ticketing_linkedTicketing_Update"]: {
	__typename: "EventsDocAccessFields_ticketing_linkedTicketing_Update",
	permission: boolean
};
	["EventsDocAccessFields_ticketing_linkedTicketing_Delete"]: {
	__typename: "EventsDocAccessFields_ticketing_linkedTicketing_Delete",
	permission: boolean
};
	["EventsDocAccessFields_ticketing_promotionType"]: {
	__typename: "EventsDocAccessFields_ticketing_promotionType",
	create?: GraphQLTypes["EventsDocAccessFields_ticketing_promotionType_Create"] | undefined,
	read?: GraphQLTypes["EventsDocAccessFields_ticketing_promotionType_Read"] | undefined,
	update?: GraphQLTypes["EventsDocAccessFields_ticketing_promotionType_Update"] | undefined,
	delete?: GraphQLTypes["EventsDocAccessFields_ticketing_promotionType_Delete"] | undefined
};
	["EventsDocAccessFields_ticketing_promotionType_Create"]: {
	__typename: "EventsDocAccessFields_ticketing_promotionType_Create",
	permission: boolean
};
	["EventsDocAccessFields_ticketing_promotionType_Read"]: {
	__typename: "EventsDocAccessFields_ticketing_promotionType_Read",
	permission: boolean
};
	["EventsDocAccessFields_ticketing_promotionType_Update"]: {
	__typename: "EventsDocAccessFields_ticketing_promotionType_Update",
	permission: boolean
};
	["EventsDocAccessFields_ticketing_promotionType_Delete"]: {
	__typename: "EventsDocAccessFields_ticketing_promotionType_Delete",
	permission: boolean
};
	["EventsDocAccessFields_ticketing_location"]: {
	__typename: "EventsDocAccessFields_ticketing_location",
	create?: GraphQLTypes["EventsDocAccessFields_ticketing_location_Create"] | undefined,
	read?: GraphQLTypes["EventsDocAccessFields_ticketing_location_Read"] | undefined,
	update?: GraphQLTypes["EventsDocAccessFields_ticketing_location_Update"] | undefined,
	delete?: GraphQLTypes["EventsDocAccessFields_ticketing_location_Delete"] | undefined
};
	["EventsDocAccessFields_ticketing_location_Create"]: {
	__typename: "EventsDocAccessFields_ticketing_location_Create",
	permission: boolean
};
	["EventsDocAccessFields_ticketing_location_Read"]: {
	__typename: "EventsDocAccessFields_ticketing_location_Read",
	permission: boolean
};
	["EventsDocAccessFields_ticketing_location_Update"]: {
	__typename: "EventsDocAccessFields_ticketing_location_Update",
	permission: boolean
};
	["EventsDocAccessFields_ticketing_location_Delete"]: {
	__typename: "EventsDocAccessFields_ticketing_location_Delete",
	permission: boolean
};
	["EventsDocAccessFields_ticketing_website"]: {
	__typename: "EventsDocAccessFields_ticketing_website",
	create?: GraphQLTypes["EventsDocAccessFields_ticketing_website_Create"] | undefined,
	read?: GraphQLTypes["EventsDocAccessFields_ticketing_website_Read"] | undefined,
	update?: GraphQLTypes["EventsDocAccessFields_ticketing_website_Update"] | undefined,
	delete?: GraphQLTypes["EventsDocAccessFields_ticketing_website_Delete"] | undefined
};
	["EventsDocAccessFields_ticketing_website_Create"]: {
	__typename: "EventsDocAccessFields_ticketing_website_Create",
	permission: boolean
};
	["EventsDocAccessFields_ticketing_website_Read"]: {
	__typename: "EventsDocAccessFields_ticketing_website_Read",
	permission: boolean
};
	["EventsDocAccessFields_ticketing_website_Update"]: {
	__typename: "EventsDocAccessFields_ticketing_website_Update",
	permission: boolean
};
	["EventsDocAccessFields_ticketing_website_Delete"]: {
	__typename: "EventsDocAccessFields_ticketing_website_Delete",
	permission: boolean
};
	["EventsDocAccessFields_ticketing_description"]: {
	__typename: "EventsDocAccessFields_ticketing_description",
	create?: GraphQLTypes["EventsDocAccessFields_ticketing_description_Create"] | undefined,
	read?: GraphQLTypes["EventsDocAccessFields_ticketing_description_Read"] | undefined,
	update?: GraphQLTypes["EventsDocAccessFields_ticketing_description_Update"] | undefined,
	delete?: GraphQLTypes["EventsDocAccessFields_ticketing_description_Delete"] | undefined
};
	["EventsDocAccessFields_ticketing_description_Create"]: {
	__typename: "EventsDocAccessFields_ticketing_description_Create",
	permission: boolean
};
	["EventsDocAccessFields_ticketing_description_Read"]: {
	__typename: "EventsDocAccessFields_ticketing_description_Read",
	permission: boolean
};
	["EventsDocAccessFields_ticketing_description_Update"]: {
	__typename: "EventsDocAccessFields_ticketing_description_Update",
	permission: boolean
};
	["EventsDocAccessFields_ticketing_description_Delete"]: {
	__typename: "EventsDocAccessFields_ticketing_description_Delete",
	permission: boolean
};
	["EventsDocAccessFields_ticketing_id"]: {
	__typename: "EventsDocAccessFields_ticketing_id",
	create?: GraphQLTypes["EventsDocAccessFields_ticketing_id_Create"] | undefined,
	read?: GraphQLTypes["EventsDocAccessFields_ticketing_id_Read"] | undefined,
	update?: GraphQLTypes["EventsDocAccessFields_ticketing_id_Update"] | undefined,
	delete?: GraphQLTypes["EventsDocAccessFields_ticketing_id_Delete"] | undefined
};
	["EventsDocAccessFields_ticketing_id_Create"]: {
	__typename: "EventsDocAccessFields_ticketing_id_Create",
	permission: boolean
};
	["EventsDocAccessFields_ticketing_id_Read"]: {
	__typename: "EventsDocAccessFields_ticketing_id_Read",
	permission: boolean
};
	["EventsDocAccessFields_ticketing_id_Update"]: {
	__typename: "EventsDocAccessFields_ticketing_id_Update",
	permission: boolean
};
	["EventsDocAccessFields_ticketing_id_Delete"]: {
	__typename: "EventsDocAccessFields_ticketing_id_Delete",
	permission: boolean
};
	["EventsDocAccessFields_content"]: {
	__typename: "EventsDocAccessFields_content",
	create?: GraphQLTypes["EventsDocAccessFields_content_Create"] | undefined,
	read?: GraphQLTypes["EventsDocAccessFields_content_Read"] | undefined,
	update?: GraphQLTypes["EventsDocAccessFields_content_Update"] | undefined,
	delete?: GraphQLTypes["EventsDocAccessFields_content_Delete"] | undefined
};
	["EventsDocAccessFields_content_Create"]: {
	__typename: "EventsDocAccessFields_content_Create",
	permission: boolean
};
	["EventsDocAccessFields_content_Read"]: {
	__typename: "EventsDocAccessFields_content_Read",
	permission: boolean
};
	["EventsDocAccessFields_content_Update"]: {
	__typename: "EventsDocAccessFields_content_Update",
	permission: boolean
};
	["EventsDocAccessFields_content_Delete"]: {
	__typename: "EventsDocAccessFields_content_Delete",
	permission: boolean
};
	["EventsDocAccessFields_legacyContent"]: {
	__typename: "EventsDocAccessFields_legacyContent",
	create?: GraphQLTypes["EventsDocAccessFields_legacyContent_Create"] | undefined,
	read?: GraphQLTypes["EventsDocAccessFields_legacyContent_Read"] | undefined,
	update?: GraphQLTypes["EventsDocAccessFields_legacyContent_Update"] | undefined,
	delete?: GraphQLTypes["EventsDocAccessFields_legacyContent_Delete"] | undefined
};
	["EventsDocAccessFields_legacyContent_Create"]: {
	__typename: "EventsDocAccessFields_legacyContent_Create",
	permission: boolean
};
	["EventsDocAccessFields_legacyContent_Read"]: {
	__typename: "EventsDocAccessFields_legacyContent_Read",
	permission: boolean
};
	["EventsDocAccessFields_legacyContent_Update"]: {
	__typename: "EventsDocAccessFields_legacyContent_Update",
	permission: boolean
};
	["EventsDocAccessFields_legacyContent_Delete"]: {
	__typename: "EventsDocAccessFields_legacyContent_Delete",
	permission: boolean
};
	["EventsDocAccessFields_showLegacyContent"]: {
	__typename: "EventsDocAccessFields_showLegacyContent",
	create?: GraphQLTypes["EventsDocAccessFields_showLegacyContent_Create"] | undefined,
	read?: GraphQLTypes["EventsDocAccessFields_showLegacyContent_Read"] | undefined,
	update?: GraphQLTypes["EventsDocAccessFields_showLegacyContent_Update"] | undefined,
	delete?: GraphQLTypes["EventsDocAccessFields_showLegacyContent_Delete"] | undefined
};
	["EventsDocAccessFields_showLegacyContent_Create"]: {
	__typename: "EventsDocAccessFields_showLegacyContent_Create",
	permission: boolean
};
	["EventsDocAccessFields_showLegacyContent_Read"]: {
	__typename: "EventsDocAccessFields_showLegacyContent_Read",
	permission: boolean
};
	["EventsDocAccessFields_showLegacyContent_Update"]: {
	__typename: "EventsDocAccessFields_showLegacyContent_Update",
	permission: boolean
};
	["EventsDocAccessFields_showLegacyContent_Delete"]: {
	__typename: "EventsDocAccessFields_showLegacyContent_Delete",
	permission: boolean
};
	["EventsDocAccessFields_criteria"]: {
	__typename: "EventsDocAccessFields_criteria",
	create?: GraphQLTypes["EventsDocAccessFields_criteria_Create"] | undefined,
	read?: GraphQLTypes["EventsDocAccessFields_criteria_Read"] | undefined,
	update?: GraphQLTypes["EventsDocAccessFields_criteria_Update"] | undefined,
	delete?: GraphQLTypes["EventsDocAccessFields_criteria_Delete"] | undefined,
	fields?: GraphQLTypes["EventsDocAccessFields_criteria_Fields"] | undefined
};
	["EventsDocAccessFields_criteria_Create"]: {
	__typename: "EventsDocAccessFields_criteria_Create",
	permission: boolean
};
	["EventsDocAccessFields_criteria_Read"]: {
	__typename: "EventsDocAccessFields_criteria_Read",
	permission: boolean
};
	["EventsDocAccessFields_criteria_Update"]: {
	__typename: "EventsDocAccessFields_criteria_Update",
	permission: boolean
};
	["EventsDocAccessFields_criteria_Delete"]: {
	__typename: "EventsDocAccessFields_criteria_Delete",
	permission: boolean
};
	["EventsDocAccessFields_criteria_Fields"]: {
	__typename: "EventsDocAccessFields_criteria_Fields",
	categories?: GraphQLTypes["EventsDocAccessFields_criteria_categories"] | undefined,
	audiences?: GraphQLTypes["EventsDocAccessFields_criteria_audiences"] | undefined,
	tags?: GraphQLTypes["EventsDocAccessFields_criteria_tags"] | undefined,
	specialTags?: GraphQLTypes["EventsDocAccessFields_criteria_specialTags"] | undefined
};
	["EventsDocAccessFields_criteria_categories"]: {
	__typename: "EventsDocAccessFields_criteria_categories",
	create?: GraphQLTypes["EventsDocAccessFields_criteria_categories_Create"] | undefined,
	read?: GraphQLTypes["EventsDocAccessFields_criteria_categories_Read"] | undefined,
	update?: GraphQLTypes["EventsDocAccessFields_criteria_categories_Update"] | undefined,
	delete?: GraphQLTypes["EventsDocAccessFields_criteria_categories_Delete"] | undefined
};
	["EventsDocAccessFields_criteria_categories_Create"]: {
	__typename: "EventsDocAccessFields_criteria_categories_Create",
	permission: boolean
};
	["EventsDocAccessFields_criteria_categories_Read"]: {
	__typename: "EventsDocAccessFields_criteria_categories_Read",
	permission: boolean
};
	["EventsDocAccessFields_criteria_categories_Update"]: {
	__typename: "EventsDocAccessFields_criteria_categories_Update",
	permission: boolean
};
	["EventsDocAccessFields_criteria_categories_Delete"]: {
	__typename: "EventsDocAccessFields_criteria_categories_Delete",
	permission: boolean
};
	["EventsDocAccessFields_criteria_audiences"]: {
	__typename: "EventsDocAccessFields_criteria_audiences",
	create?: GraphQLTypes["EventsDocAccessFields_criteria_audiences_Create"] | undefined,
	read?: GraphQLTypes["EventsDocAccessFields_criteria_audiences_Read"] | undefined,
	update?: GraphQLTypes["EventsDocAccessFields_criteria_audiences_Update"] | undefined,
	delete?: GraphQLTypes["EventsDocAccessFields_criteria_audiences_Delete"] | undefined
};
	["EventsDocAccessFields_criteria_audiences_Create"]: {
	__typename: "EventsDocAccessFields_criteria_audiences_Create",
	permission: boolean
};
	["EventsDocAccessFields_criteria_audiences_Read"]: {
	__typename: "EventsDocAccessFields_criteria_audiences_Read",
	permission: boolean
};
	["EventsDocAccessFields_criteria_audiences_Update"]: {
	__typename: "EventsDocAccessFields_criteria_audiences_Update",
	permission: boolean
};
	["EventsDocAccessFields_criteria_audiences_Delete"]: {
	__typename: "EventsDocAccessFields_criteria_audiences_Delete",
	permission: boolean
};
	["EventsDocAccessFields_criteria_tags"]: {
	__typename: "EventsDocAccessFields_criteria_tags",
	create?: GraphQLTypes["EventsDocAccessFields_criteria_tags_Create"] | undefined,
	read?: GraphQLTypes["EventsDocAccessFields_criteria_tags_Read"] | undefined,
	update?: GraphQLTypes["EventsDocAccessFields_criteria_tags_Update"] | undefined,
	delete?: GraphQLTypes["EventsDocAccessFields_criteria_tags_Delete"] | undefined
};
	["EventsDocAccessFields_criteria_tags_Create"]: {
	__typename: "EventsDocAccessFields_criteria_tags_Create",
	permission: boolean
};
	["EventsDocAccessFields_criteria_tags_Read"]: {
	__typename: "EventsDocAccessFields_criteria_tags_Read",
	permission: boolean
};
	["EventsDocAccessFields_criteria_tags_Update"]: {
	__typename: "EventsDocAccessFields_criteria_tags_Update",
	permission: boolean
};
	["EventsDocAccessFields_criteria_tags_Delete"]: {
	__typename: "EventsDocAccessFields_criteria_tags_Delete",
	permission: boolean
};
	["EventsDocAccessFields_criteria_specialTags"]: {
	__typename: "EventsDocAccessFields_criteria_specialTags",
	create?: GraphQLTypes["EventsDocAccessFields_criteria_specialTags_Create"] | undefined,
	read?: GraphQLTypes["EventsDocAccessFields_criteria_specialTags_Read"] | undefined,
	update?: GraphQLTypes["EventsDocAccessFields_criteria_specialTags_Update"] | undefined,
	delete?: GraphQLTypes["EventsDocAccessFields_criteria_specialTags_Delete"] | undefined
};
	["EventsDocAccessFields_criteria_specialTags_Create"]: {
	__typename: "EventsDocAccessFields_criteria_specialTags_Create",
	permission: boolean
};
	["EventsDocAccessFields_criteria_specialTags_Read"]: {
	__typename: "EventsDocAccessFields_criteria_specialTags_Read",
	permission: boolean
};
	["EventsDocAccessFields_criteria_specialTags_Update"]: {
	__typename: "EventsDocAccessFields_criteria_specialTags_Update",
	permission: boolean
};
	["EventsDocAccessFields_criteria_specialTags_Delete"]: {
	__typename: "EventsDocAccessFields_criteria_specialTags_Delete",
	permission: boolean
};
	["EventsDocAccessFields_walkIn"]: {
	__typename: "EventsDocAccessFields_walkIn",
	create?: GraphQLTypes["EventsDocAccessFields_walkIn_Create"] | undefined,
	read?: GraphQLTypes["EventsDocAccessFields_walkIn_Read"] | undefined,
	update?: GraphQLTypes["EventsDocAccessFields_walkIn_Update"] | undefined,
	delete?: GraphQLTypes["EventsDocAccessFields_walkIn_Delete"] | undefined
};
	["EventsDocAccessFields_walkIn_Create"]: {
	__typename: "EventsDocAccessFields_walkIn_Create",
	permission: boolean
};
	["EventsDocAccessFields_walkIn_Read"]: {
	__typename: "EventsDocAccessFields_walkIn_Read",
	permission: boolean
};
	["EventsDocAccessFields_walkIn_Update"]: {
	__typename: "EventsDocAccessFields_walkIn_Update",
	permission: boolean
};
	["EventsDocAccessFields_walkIn_Delete"]: {
	__typename: "EventsDocAccessFields_walkIn_Delete",
	permission: boolean
};
	["EventsDocAccessFields_contact"]: {
	__typename: "EventsDocAccessFields_contact",
	create?: GraphQLTypes["EventsDocAccessFields_contact_Create"] | undefined,
	read?: GraphQLTypes["EventsDocAccessFields_contact_Read"] | undefined,
	update?: GraphQLTypes["EventsDocAccessFields_contact_Update"] | undefined,
	delete?: GraphQLTypes["EventsDocAccessFields_contact_Delete"] | undefined,
	fields?: GraphQLTypes["EventsDocAccessFields_contact_Fields"] | undefined
};
	["EventsDocAccessFields_contact_Create"]: {
	__typename: "EventsDocAccessFields_contact_Create",
	permission: boolean
};
	["EventsDocAccessFields_contact_Read"]: {
	__typename: "EventsDocAccessFields_contact_Read",
	permission: boolean
};
	["EventsDocAccessFields_contact_Update"]: {
	__typename: "EventsDocAccessFields_contact_Update",
	permission: boolean
};
	["EventsDocAccessFields_contact_Delete"]: {
	__typename: "EventsDocAccessFields_contact_Delete",
	permission: boolean
};
	["EventsDocAccessFields_contact_Fields"]: {
	__typename: "EventsDocAccessFields_contact_Fields",
	code?: GraphQLTypes["EventsDocAccessFields_contact_code"] | undefined,
	phone?: GraphQLTypes["EventsDocAccessFields_contact_phone"] | undefined,
	page?: GraphQLTypes["EventsDocAccessFields_contact_page"] | undefined,
	email?: GraphQLTypes["EventsDocAccessFields_contact_email"] | undefined,
	privacy?: GraphQLTypes["EventsDocAccessFields_contact_privacy"] | undefined
};
	["EventsDocAccessFields_contact_code"]: {
	__typename: "EventsDocAccessFields_contact_code",
	create?: GraphQLTypes["EventsDocAccessFields_contact_code_Create"] | undefined,
	read?: GraphQLTypes["EventsDocAccessFields_contact_code_Read"] | undefined,
	update?: GraphQLTypes["EventsDocAccessFields_contact_code_Update"] | undefined,
	delete?: GraphQLTypes["EventsDocAccessFields_contact_code_Delete"] | undefined
};
	["EventsDocAccessFields_contact_code_Create"]: {
	__typename: "EventsDocAccessFields_contact_code_Create",
	permission: boolean
};
	["EventsDocAccessFields_contact_code_Read"]: {
	__typename: "EventsDocAccessFields_contact_code_Read",
	permission: boolean
};
	["EventsDocAccessFields_contact_code_Update"]: {
	__typename: "EventsDocAccessFields_contact_code_Update",
	permission: boolean
};
	["EventsDocAccessFields_contact_code_Delete"]: {
	__typename: "EventsDocAccessFields_contact_code_Delete",
	permission: boolean
};
	["EventsDocAccessFields_contact_phone"]: {
	__typename: "EventsDocAccessFields_contact_phone",
	create?: GraphQLTypes["EventsDocAccessFields_contact_phone_Create"] | undefined,
	read?: GraphQLTypes["EventsDocAccessFields_contact_phone_Read"] | undefined,
	update?: GraphQLTypes["EventsDocAccessFields_contact_phone_Update"] | undefined,
	delete?: GraphQLTypes["EventsDocAccessFields_contact_phone_Delete"] | undefined
};
	["EventsDocAccessFields_contact_phone_Create"]: {
	__typename: "EventsDocAccessFields_contact_phone_Create",
	permission: boolean
};
	["EventsDocAccessFields_contact_phone_Read"]: {
	__typename: "EventsDocAccessFields_contact_phone_Read",
	permission: boolean
};
	["EventsDocAccessFields_contact_phone_Update"]: {
	__typename: "EventsDocAccessFields_contact_phone_Update",
	permission: boolean
};
	["EventsDocAccessFields_contact_phone_Delete"]: {
	__typename: "EventsDocAccessFields_contact_phone_Delete",
	permission: boolean
};
	["EventsDocAccessFields_contact_page"]: {
	__typename: "EventsDocAccessFields_contact_page",
	create?: GraphQLTypes["EventsDocAccessFields_contact_page_Create"] | undefined,
	read?: GraphQLTypes["EventsDocAccessFields_contact_page_Read"] | undefined,
	update?: GraphQLTypes["EventsDocAccessFields_contact_page_Update"] | undefined,
	delete?: GraphQLTypes["EventsDocAccessFields_contact_page_Delete"] | undefined
};
	["EventsDocAccessFields_contact_page_Create"]: {
	__typename: "EventsDocAccessFields_contact_page_Create",
	permission: boolean
};
	["EventsDocAccessFields_contact_page_Read"]: {
	__typename: "EventsDocAccessFields_contact_page_Read",
	permission: boolean
};
	["EventsDocAccessFields_contact_page_Update"]: {
	__typename: "EventsDocAccessFields_contact_page_Update",
	permission: boolean
};
	["EventsDocAccessFields_contact_page_Delete"]: {
	__typename: "EventsDocAccessFields_contact_page_Delete",
	permission: boolean
};
	["EventsDocAccessFields_contact_email"]: {
	__typename: "EventsDocAccessFields_contact_email",
	create?: GraphQLTypes["EventsDocAccessFields_contact_email_Create"] | undefined,
	read?: GraphQLTypes["EventsDocAccessFields_contact_email_Read"] | undefined,
	update?: GraphQLTypes["EventsDocAccessFields_contact_email_Update"] | undefined,
	delete?: GraphQLTypes["EventsDocAccessFields_contact_email_Delete"] | undefined
};
	["EventsDocAccessFields_contact_email_Create"]: {
	__typename: "EventsDocAccessFields_contact_email_Create",
	permission: boolean
};
	["EventsDocAccessFields_contact_email_Read"]: {
	__typename: "EventsDocAccessFields_contact_email_Read",
	permission: boolean
};
	["EventsDocAccessFields_contact_email_Update"]: {
	__typename: "EventsDocAccessFields_contact_email_Update",
	permission: boolean
};
	["EventsDocAccessFields_contact_email_Delete"]: {
	__typename: "EventsDocAccessFields_contact_email_Delete",
	permission: boolean
};
	["EventsDocAccessFields_contact_privacy"]: {
	__typename: "EventsDocAccessFields_contact_privacy",
	create?: GraphQLTypes["EventsDocAccessFields_contact_privacy_Create"] | undefined,
	read?: GraphQLTypes["EventsDocAccessFields_contact_privacy_Read"] | undefined,
	update?: GraphQLTypes["EventsDocAccessFields_contact_privacy_Update"] | undefined,
	delete?: GraphQLTypes["EventsDocAccessFields_contact_privacy_Delete"] | undefined
};
	["EventsDocAccessFields_contact_privacy_Create"]: {
	__typename: "EventsDocAccessFields_contact_privacy_Create",
	permission: boolean
};
	["EventsDocAccessFields_contact_privacy_Read"]: {
	__typename: "EventsDocAccessFields_contact_privacy_Read",
	permission: boolean
};
	["EventsDocAccessFields_contact_privacy_Update"]: {
	__typename: "EventsDocAccessFields_contact_privacy_Update",
	permission: boolean
};
	["EventsDocAccessFields_contact_privacy_Delete"]: {
	__typename: "EventsDocAccessFields_contact_privacy_Delete",
	permission: boolean
};
	["EventsDocAccessFields_views"]: {
	__typename: "EventsDocAccessFields_views",
	create?: GraphQLTypes["EventsDocAccessFields_views_Create"] | undefined,
	read?: GraphQLTypes["EventsDocAccessFields_views_Read"] | undefined,
	update?: GraphQLTypes["EventsDocAccessFields_views_Update"] | undefined,
	delete?: GraphQLTypes["EventsDocAccessFields_views_Delete"] | undefined
};
	["EventsDocAccessFields_views_Create"]: {
	__typename: "EventsDocAccessFields_views_Create",
	permission: boolean
};
	["EventsDocAccessFields_views_Read"]: {
	__typename: "EventsDocAccessFields_views_Read",
	permission: boolean
};
	["EventsDocAccessFields_views_Update"]: {
	__typename: "EventsDocAccessFields_views_Update",
	permission: boolean
};
	["EventsDocAccessFields_views_Delete"]: {
	__typename: "EventsDocAccessFields_views_Delete",
	permission: boolean
};
	["EventsDocAccessFields_free"]: {
	__typename: "EventsDocAccessFields_free",
	create?: GraphQLTypes["EventsDocAccessFields_free_Create"] | undefined,
	read?: GraphQLTypes["EventsDocAccessFields_free_Read"] | undefined,
	update?: GraphQLTypes["EventsDocAccessFields_free_Update"] | undefined,
	delete?: GraphQLTypes["EventsDocAccessFields_free_Delete"] | undefined
};
	["EventsDocAccessFields_free_Create"]: {
	__typename: "EventsDocAccessFields_free_Create",
	permission: boolean
};
	["EventsDocAccessFields_free_Read"]: {
	__typename: "EventsDocAccessFields_free_Read",
	permission: boolean
};
	["EventsDocAccessFields_free_Update"]: {
	__typename: "EventsDocAccessFields_free_Update",
	permission: boolean
};
	["EventsDocAccessFields_free_Delete"]: {
	__typename: "EventsDocAccessFields_free_Delete",
	permission: boolean
};
	["EventsDocAccessFields_fees"]: {
	__typename: "EventsDocAccessFields_fees",
	create?: GraphQLTypes["EventsDocAccessFields_fees_Create"] | undefined,
	read?: GraphQLTypes["EventsDocAccessFields_fees_Read"] | undefined,
	update?: GraphQLTypes["EventsDocAccessFields_fees_Update"] | undefined,
	delete?: GraphQLTypes["EventsDocAccessFields_fees_Delete"] | undefined,
	fields?: GraphQLTypes["EventsDocAccessFields_fees_Fields"] | undefined
};
	["EventsDocAccessFields_fees_Create"]: {
	__typename: "EventsDocAccessFields_fees_Create",
	permission: boolean
};
	["EventsDocAccessFields_fees_Read"]: {
	__typename: "EventsDocAccessFields_fees_Read",
	permission: boolean
};
	["EventsDocAccessFields_fees_Update"]: {
	__typename: "EventsDocAccessFields_fees_Update",
	permission: boolean
};
	["EventsDocAccessFields_fees_Delete"]: {
	__typename: "EventsDocAccessFields_fees_Delete",
	permission: boolean
};
	["EventsDocAccessFields_fees_Fields"]: {
	__typename: "EventsDocAccessFields_fees_Fields",
	fee?: GraphQLTypes["EventsDocAccessFields_fees_fee"] | undefined,
	remark?: GraphQLTypes["EventsDocAccessFields_fees_remark"] | undefined,
	id?: GraphQLTypes["EventsDocAccessFields_fees_id"] | undefined
};
	["EventsDocAccessFields_fees_fee"]: {
	__typename: "EventsDocAccessFields_fees_fee",
	create?: GraphQLTypes["EventsDocAccessFields_fees_fee_Create"] | undefined,
	read?: GraphQLTypes["EventsDocAccessFields_fees_fee_Read"] | undefined,
	update?: GraphQLTypes["EventsDocAccessFields_fees_fee_Update"] | undefined,
	delete?: GraphQLTypes["EventsDocAccessFields_fees_fee_Delete"] | undefined
};
	["EventsDocAccessFields_fees_fee_Create"]: {
	__typename: "EventsDocAccessFields_fees_fee_Create",
	permission: boolean
};
	["EventsDocAccessFields_fees_fee_Read"]: {
	__typename: "EventsDocAccessFields_fees_fee_Read",
	permission: boolean
};
	["EventsDocAccessFields_fees_fee_Update"]: {
	__typename: "EventsDocAccessFields_fees_fee_Update",
	permission: boolean
};
	["EventsDocAccessFields_fees_fee_Delete"]: {
	__typename: "EventsDocAccessFields_fees_fee_Delete",
	permission: boolean
};
	["EventsDocAccessFields_fees_remark"]: {
	__typename: "EventsDocAccessFields_fees_remark",
	create?: GraphQLTypes["EventsDocAccessFields_fees_remark_Create"] | undefined,
	read?: GraphQLTypes["EventsDocAccessFields_fees_remark_Read"] | undefined,
	update?: GraphQLTypes["EventsDocAccessFields_fees_remark_Update"] | undefined,
	delete?: GraphQLTypes["EventsDocAccessFields_fees_remark_Delete"] | undefined
};
	["EventsDocAccessFields_fees_remark_Create"]: {
	__typename: "EventsDocAccessFields_fees_remark_Create",
	permission: boolean
};
	["EventsDocAccessFields_fees_remark_Read"]: {
	__typename: "EventsDocAccessFields_fees_remark_Read",
	permission: boolean
};
	["EventsDocAccessFields_fees_remark_Update"]: {
	__typename: "EventsDocAccessFields_fees_remark_Update",
	permission: boolean
};
	["EventsDocAccessFields_fees_remark_Delete"]: {
	__typename: "EventsDocAccessFields_fees_remark_Delete",
	permission: boolean
};
	["EventsDocAccessFields_fees_id"]: {
	__typename: "EventsDocAccessFields_fees_id",
	create?: GraphQLTypes["EventsDocAccessFields_fees_id_Create"] | undefined,
	read?: GraphQLTypes["EventsDocAccessFields_fees_id_Read"] | undefined,
	update?: GraphQLTypes["EventsDocAccessFields_fees_id_Update"] | undefined,
	delete?: GraphQLTypes["EventsDocAccessFields_fees_id_Delete"] | undefined
};
	["EventsDocAccessFields_fees_id_Create"]: {
	__typename: "EventsDocAccessFields_fees_id_Create",
	permission: boolean
};
	["EventsDocAccessFields_fees_id_Read"]: {
	__typename: "EventsDocAccessFields_fees_id_Read",
	permission: boolean
};
	["EventsDocAccessFields_fees_id_Update"]: {
	__typename: "EventsDocAccessFields_fees_id_Update",
	permission: boolean
};
	["EventsDocAccessFields_fees_id_Delete"]: {
	__typename: "EventsDocAccessFields_fees_id_Delete",
	permission: boolean
};
	["EventsDocAccessFields_homePageInternalClick"]: {
	__typename: "EventsDocAccessFields_homePageInternalClick",
	create?: GraphQLTypes["EventsDocAccessFields_homePageInternalClick_Create"] | undefined,
	read?: GraphQLTypes["EventsDocAccessFields_homePageInternalClick_Read"] | undefined,
	update?: GraphQLTypes["EventsDocAccessFields_homePageInternalClick_Update"] | undefined,
	delete?: GraphQLTypes["EventsDocAccessFields_homePageInternalClick_Delete"] | undefined
};
	["EventsDocAccessFields_homePageInternalClick_Create"]: {
	__typename: "EventsDocAccessFields_homePageInternalClick_Create",
	permission: boolean
};
	["EventsDocAccessFields_homePageInternalClick_Read"]: {
	__typename: "EventsDocAccessFields_homePageInternalClick_Read",
	permission: boolean
};
	["EventsDocAccessFields_homePageInternalClick_Update"]: {
	__typename: "EventsDocAccessFields_homePageInternalClick_Update",
	permission: boolean
};
	["EventsDocAccessFields_homePageInternalClick_Delete"]: {
	__typename: "EventsDocAccessFields_homePageInternalClick_Delete",
	permission: boolean
};
	["EventsDocAccessFields_homePageImpression"]: {
	__typename: "EventsDocAccessFields_homePageImpression",
	create?: GraphQLTypes["EventsDocAccessFields_homePageImpression_Create"] | undefined,
	read?: GraphQLTypes["EventsDocAccessFields_homePageImpression_Read"] | undefined,
	update?: GraphQLTypes["EventsDocAccessFields_homePageImpression_Update"] | undefined,
	delete?: GraphQLTypes["EventsDocAccessFields_homePageImpression_Delete"] | undefined
};
	["EventsDocAccessFields_homePageImpression_Create"]: {
	__typename: "EventsDocAccessFields_homePageImpression_Create",
	permission: boolean
};
	["EventsDocAccessFields_homePageImpression_Read"]: {
	__typename: "EventsDocAccessFields_homePageImpression_Read",
	permission: boolean
};
	["EventsDocAccessFields_homePageImpression_Update"]: {
	__typename: "EventsDocAccessFields_homePageImpression_Update",
	permission: boolean
};
	["EventsDocAccessFields_homePageImpression_Delete"]: {
	__typename: "EventsDocAccessFields_homePageImpression_Delete",
	permission: boolean
};
	["EventsDocAccessFields_eodClickRate"]: {
	__typename: "EventsDocAccessFields_eodClickRate",
	create?: GraphQLTypes["EventsDocAccessFields_eodClickRate_Create"] | undefined,
	read?: GraphQLTypes["EventsDocAccessFields_eodClickRate_Read"] | undefined,
	update?: GraphQLTypes["EventsDocAccessFields_eodClickRate_Update"] | undefined,
	delete?: GraphQLTypes["EventsDocAccessFields_eodClickRate_Delete"] | undefined
};
	["EventsDocAccessFields_eodClickRate_Create"]: {
	__typename: "EventsDocAccessFields_eodClickRate_Create",
	permission: boolean
};
	["EventsDocAccessFields_eodClickRate_Read"]: {
	__typename: "EventsDocAccessFields_eodClickRate_Read",
	permission: boolean
};
	["EventsDocAccessFields_eodClickRate_Update"]: {
	__typename: "EventsDocAccessFields_eodClickRate_Update",
	permission: boolean
};
	["EventsDocAccessFields_eodClickRate_Delete"]: {
	__typename: "EventsDocAccessFields_eodClickRate_Delete",
	permission: boolean
};
	["EventsDocAccessFields_eodImpressionRate"]: {
	__typename: "EventsDocAccessFields_eodImpressionRate",
	create?: GraphQLTypes["EventsDocAccessFields_eodImpressionRate_Create"] | undefined,
	read?: GraphQLTypes["EventsDocAccessFields_eodImpressionRate_Read"] | undefined,
	update?: GraphQLTypes["EventsDocAccessFields_eodImpressionRate_Update"] | undefined,
	delete?: GraphQLTypes["EventsDocAccessFields_eodImpressionRate_Delete"] | undefined
};
	["EventsDocAccessFields_eodImpressionRate_Create"]: {
	__typename: "EventsDocAccessFields_eodImpressionRate_Create",
	permission: boolean
};
	["EventsDocAccessFields_eodImpressionRate_Read"]: {
	__typename: "EventsDocAccessFields_eodImpressionRate_Read",
	permission: boolean
};
	["EventsDocAccessFields_eodImpressionRate_Update"]: {
	__typename: "EventsDocAccessFields_eodImpressionRate_Update",
	permission: boolean
};
	["EventsDocAccessFields_eodImpressionRate_Delete"]: {
	__typename: "EventsDocAccessFields_eodImpressionRate_Delete",
	permission: boolean
};
	["EventsDocAccessFields_top10ClickRateHomepage"]: {
	__typename: "EventsDocAccessFields_top10ClickRateHomepage",
	create?: GraphQLTypes["EventsDocAccessFields_top10ClickRateHomepage_Create"] | undefined,
	read?: GraphQLTypes["EventsDocAccessFields_top10ClickRateHomepage_Read"] | undefined,
	update?: GraphQLTypes["EventsDocAccessFields_top10ClickRateHomepage_Update"] | undefined,
	delete?: GraphQLTypes["EventsDocAccessFields_top10ClickRateHomepage_Delete"] | undefined
};
	["EventsDocAccessFields_top10ClickRateHomepage_Create"]: {
	__typename: "EventsDocAccessFields_top10ClickRateHomepage_Create",
	permission: boolean
};
	["EventsDocAccessFields_top10ClickRateHomepage_Read"]: {
	__typename: "EventsDocAccessFields_top10ClickRateHomepage_Read",
	permission: boolean
};
	["EventsDocAccessFields_top10ClickRateHomepage_Update"]: {
	__typename: "EventsDocAccessFields_top10ClickRateHomepage_Update",
	permission: boolean
};
	["EventsDocAccessFields_top10ClickRateHomepage_Delete"]: {
	__typename: "EventsDocAccessFields_top10ClickRateHomepage_Delete",
	permission: boolean
};
	["EventsDocAccessFields_top10ImpressionkRateHomepage"]: {
	__typename: "EventsDocAccessFields_top10ImpressionkRateHomepage",
	create?: GraphQLTypes["EventsDocAccessFields_top10ImpressionkRateHomepage_Create"] | undefined,
	read?: GraphQLTypes["EventsDocAccessFields_top10ImpressionkRateHomepage_Read"] | undefined,
	update?: GraphQLTypes["EventsDocAccessFields_top10ImpressionkRateHomepage_Update"] | undefined,
	delete?: GraphQLTypes["EventsDocAccessFields_top10ImpressionkRateHomepage_Delete"] | undefined
};
	["EventsDocAccessFields_top10ImpressionkRateHomepage_Create"]: {
	__typename: "EventsDocAccessFields_top10ImpressionkRateHomepage_Create",
	permission: boolean
};
	["EventsDocAccessFields_top10ImpressionkRateHomepage_Read"]: {
	__typename: "EventsDocAccessFields_top10ImpressionkRateHomepage_Read",
	permission: boolean
};
	["EventsDocAccessFields_top10ImpressionkRateHomepage_Update"]: {
	__typename: "EventsDocAccessFields_top10ImpressionkRateHomepage_Update",
	permission: boolean
};
	["EventsDocAccessFields_top10ImpressionkRateHomepage_Delete"]: {
	__typename: "EventsDocAccessFields_top10ImpressionkRateHomepage_Delete",
	permission: boolean
};
	["EventsDocAccessFields_otherEventRankingClickRateHomepage"]: {
	__typename: "EventsDocAccessFields_otherEventRankingClickRateHomepage",
	create?: GraphQLTypes["EventsDocAccessFields_otherEventRankingClickRateHomepage_Create"] | undefined,
	read?: GraphQLTypes["EventsDocAccessFields_otherEventRankingClickRateHomepage_Read"] | undefined,
	update?: GraphQLTypes["EventsDocAccessFields_otherEventRankingClickRateHomepage_Update"] | undefined,
	delete?: GraphQLTypes["EventsDocAccessFields_otherEventRankingClickRateHomepage_Delete"] | undefined
};
	["EventsDocAccessFields_otherEventRankingClickRateHomepage_Create"]: {
	__typename: "EventsDocAccessFields_otherEventRankingClickRateHomepage_Create",
	permission: boolean
};
	["EventsDocAccessFields_otherEventRankingClickRateHomepage_Read"]: {
	__typename: "EventsDocAccessFields_otherEventRankingClickRateHomepage_Read",
	permission: boolean
};
	["EventsDocAccessFields_otherEventRankingClickRateHomepage_Update"]: {
	__typename: "EventsDocAccessFields_otherEventRankingClickRateHomepage_Update",
	permission: boolean
};
	["EventsDocAccessFields_otherEventRankingClickRateHomepage_Delete"]: {
	__typename: "EventsDocAccessFields_otherEventRankingClickRateHomepage_Delete",
	permission: boolean
};
	["EventsDocAccessFields_otherEventRankingImpressionHomePage"]: {
	__typename: "EventsDocAccessFields_otherEventRankingImpressionHomePage",
	create?: GraphQLTypes["EventsDocAccessFields_otherEventRankingImpressionHomePage_Create"] | undefined,
	read?: GraphQLTypes["EventsDocAccessFields_otherEventRankingImpressionHomePage_Read"] | undefined,
	update?: GraphQLTypes["EventsDocAccessFields_otherEventRankingImpressionHomePage_Update"] | undefined,
	delete?: GraphQLTypes["EventsDocAccessFields_otherEventRankingImpressionHomePage_Delete"] | undefined
};
	["EventsDocAccessFields_otherEventRankingImpressionHomePage_Create"]: {
	__typename: "EventsDocAccessFields_otherEventRankingImpressionHomePage_Create",
	permission: boolean
};
	["EventsDocAccessFields_otherEventRankingImpressionHomePage_Read"]: {
	__typename: "EventsDocAccessFields_otherEventRankingImpressionHomePage_Read",
	permission: boolean
};
	["EventsDocAccessFields_otherEventRankingImpressionHomePage_Update"]: {
	__typename: "EventsDocAccessFields_otherEventRankingImpressionHomePage_Update",
	permission: boolean
};
	["EventsDocAccessFields_otherEventRankingImpressionHomePage_Delete"]: {
	__typename: "EventsDocAccessFields_otherEventRankingImpressionHomePage_Delete",
	permission: boolean
};
	["EventsDocAccessFields_userPreferenceClickRate"]: {
	__typename: "EventsDocAccessFields_userPreferenceClickRate",
	create?: GraphQLTypes["EventsDocAccessFields_userPreferenceClickRate_Create"] | undefined,
	read?: GraphQLTypes["EventsDocAccessFields_userPreferenceClickRate_Read"] | undefined,
	update?: GraphQLTypes["EventsDocAccessFields_userPreferenceClickRate_Update"] | undefined,
	delete?: GraphQLTypes["EventsDocAccessFields_userPreferenceClickRate_Delete"] | undefined
};
	["EventsDocAccessFields_userPreferenceClickRate_Create"]: {
	__typename: "EventsDocAccessFields_userPreferenceClickRate_Create",
	permission: boolean
};
	["EventsDocAccessFields_userPreferenceClickRate_Read"]: {
	__typename: "EventsDocAccessFields_userPreferenceClickRate_Read",
	permission: boolean
};
	["EventsDocAccessFields_userPreferenceClickRate_Update"]: {
	__typename: "EventsDocAccessFields_userPreferenceClickRate_Update",
	permission: boolean
};
	["EventsDocAccessFields_userPreferenceClickRate_Delete"]: {
	__typename: "EventsDocAccessFields_userPreferenceClickRate_Delete",
	permission: boolean
};
	["EventsDocAccessFields_userPreferenceImpression"]: {
	__typename: "EventsDocAccessFields_userPreferenceImpression",
	create?: GraphQLTypes["EventsDocAccessFields_userPreferenceImpression_Create"] | undefined,
	read?: GraphQLTypes["EventsDocAccessFields_userPreferenceImpression_Read"] | undefined,
	update?: GraphQLTypes["EventsDocAccessFields_userPreferenceImpression_Update"] | undefined,
	delete?: GraphQLTypes["EventsDocAccessFields_userPreferenceImpression_Delete"] | undefined
};
	["EventsDocAccessFields_userPreferenceImpression_Create"]: {
	__typename: "EventsDocAccessFields_userPreferenceImpression_Create",
	permission: boolean
};
	["EventsDocAccessFields_userPreferenceImpression_Read"]: {
	__typename: "EventsDocAccessFields_userPreferenceImpression_Read",
	permission: boolean
};
	["EventsDocAccessFields_userPreferenceImpression_Update"]: {
	__typename: "EventsDocAccessFields_userPreferenceImpression_Update",
	permission: boolean
};
	["EventsDocAccessFields_userPreferenceImpression_Delete"]: {
	__typename: "EventsDocAccessFields_userPreferenceImpression_Delete",
	permission: boolean
};
	["EventsDocAccessFields_eventSearchInternalClick"]: {
	__typename: "EventsDocAccessFields_eventSearchInternalClick",
	create?: GraphQLTypes["EventsDocAccessFields_eventSearchInternalClick_Create"] | undefined,
	read?: GraphQLTypes["EventsDocAccessFields_eventSearchInternalClick_Read"] | undefined,
	update?: GraphQLTypes["EventsDocAccessFields_eventSearchInternalClick_Update"] | undefined,
	delete?: GraphQLTypes["EventsDocAccessFields_eventSearchInternalClick_Delete"] | undefined
};
	["EventsDocAccessFields_eventSearchInternalClick_Create"]: {
	__typename: "EventsDocAccessFields_eventSearchInternalClick_Create",
	permission: boolean
};
	["EventsDocAccessFields_eventSearchInternalClick_Read"]: {
	__typename: "EventsDocAccessFields_eventSearchInternalClick_Read",
	permission: boolean
};
	["EventsDocAccessFields_eventSearchInternalClick_Update"]: {
	__typename: "EventsDocAccessFields_eventSearchInternalClick_Update",
	permission: boolean
};
	["EventsDocAccessFields_eventSearchInternalClick_Delete"]: {
	__typename: "EventsDocAccessFields_eventSearchInternalClick_Delete",
	permission: boolean
};
	["EventsDocAccessFields_eventListImpression"]: {
	__typename: "EventsDocAccessFields_eventListImpression",
	create?: GraphQLTypes["EventsDocAccessFields_eventListImpression_Create"] | undefined,
	read?: GraphQLTypes["EventsDocAccessFields_eventListImpression_Read"] | undefined,
	update?: GraphQLTypes["EventsDocAccessFields_eventListImpression_Update"] | undefined,
	delete?: GraphQLTypes["EventsDocAccessFields_eventListImpression_Delete"] | undefined
};
	["EventsDocAccessFields_eventListImpression_Create"]: {
	__typename: "EventsDocAccessFields_eventListImpression_Create",
	permission: boolean
};
	["EventsDocAccessFields_eventListImpression_Read"]: {
	__typename: "EventsDocAccessFields_eventListImpression_Read",
	permission: boolean
};
	["EventsDocAccessFields_eventListImpression_Update"]: {
	__typename: "EventsDocAccessFields_eventListImpression_Update",
	permission: boolean
};
	["EventsDocAccessFields_eventListImpression_Delete"]: {
	__typename: "EventsDocAccessFields_eventListImpression_Delete",
	permission: boolean
};
	["EventsDocAccessFields_latestEventInternalClick"]: {
	__typename: "EventsDocAccessFields_latestEventInternalClick",
	create?: GraphQLTypes["EventsDocAccessFields_latestEventInternalClick_Create"] | undefined,
	read?: GraphQLTypes["EventsDocAccessFields_latestEventInternalClick_Read"] | undefined,
	update?: GraphQLTypes["EventsDocAccessFields_latestEventInternalClick_Update"] | undefined,
	delete?: GraphQLTypes["EventsDocAccessFields_latestEventInternalClick_Delete"] | undefined
};
	["EventsDocAccessFields_latestEventInternalClick_Create"]: {
	__typename: "EventsDocAccessFields_latestEventInternalClick_Create",
	permission: boolean
};
	["EventsDocAccessFields_latestEventInternalClick_Read"]: {
	__typename: "EventsDocAccessFields_latestEventInternalClick_Read",
	permission: boolean
};
	["EventsDocAccessFields_latestEventInternalClick_Update"]: {
	__typename: "EventsDocAccessFields_latestEventInternalClick_Update",
	permission: boolean
};
	["EventsDocAccessFields_latestEventInternalClick_Delete"]: {
	__typename: "EventsDocAccessFields_latestEventInternalClick_Delete",
	permission: boolean
};
	["EventsDocAccessFields_latestEventImpression"]: {
	__typename: "EventsDocAccessFields_latestEventImpression",
	create?: GraphQLTypes["EventsDocAccessFields_latestEventImpression_Create"] | undefined,
	read?: GraphQLTypes["EventsDocAccessFields_latestEventImpression_Read"] | undefined,
	update?: GraphQLTypes["EventsDocAccessFields_latestEventImpression_Update"] | undefined,
	delete?: GraphQLTypes["EventsDocAccessFields_latestEventImpression_Delete"] | undefined
};
	["EventsDocAccessFields_latestEventImpression_Create"]: {
	__typename: "EventsDocAccessFields_latestEventImpression_Create",
	permission: boolean
};
	["EventsDocAccessFields_latestEventImpression_Read"]: {
	__typename: "EventsDocAccessFields_latestEventImpression_Read",
	permission: boolean
};
	["EventsDocAccessFields_latestEventImpression_Update"]: {
	__typename: "EventsDocAccessFields_latestEventImpression_Update",
	permission: boolean
};
	["EventsDocAccessFields_latestEventImpression_Delete"]: {
	__typename: "EventsDocAccessFields_latestEventImpression_Delete",
	permission: boolean
};
	["EventsDocAccessFields_topTenEventRankingInternalClick"]: {
	__typename: "EventsDocAccessFields_topTenEventRankingInternalClick",
	create?: GraphQLTypes["EventsDocAccessFields_topTenEventRankingInternalClick_Create"] | undefined,
	read?: GraphQLTypes["EventsDocAccessFields_topTenEventRankingInternalClick_Read"] | undefined,
	update?: GraphQLTypes["EventsDocAccessFields_topTenEventRankingInternalClick_Update"] | undefined,
	delete?: GraphQLTypes["EventsDocAccessFields_topTenEventRankingInternalClick_Delete"] | undefined
};
	["EventsDocAccessFields_topTenEventRankingInternalClick_Create"]: {
	__typename: "EventsDocAccessFields_topTenEventRankingInternalClick_Create",
	permission: boolean
};
	["EventsDocAccessFields_topTenEventRankingInternalClick_Read"]: {
	__typename: "EventsDocAccessFields_topTenEventRankingInternalClick_Read",
	permission: boolean
};
	["EventsDocAccessFields_topTenEventRankingInternalClick_Update"]: {
	__typename: "EventsDocAccessFields_topTenEventRankingInternalClick_Update",
	permission: boolean
};
	["EventsDocAccessFields_topTenEventRankingInternalClick_Delete"]: {
	__typename: "EventsDocAccessFields_topTenEventRankingInternalClick_Delete",
	permission: boolean
};
	["EventsDocAccessFields_topTenImpression"]: {
	__typename: "EventsDocAccessFields_topTenImpression",
	create?: GraphQLTypes["EventsDocAccessFields_topTenImpression_Create"] | undefined,
	read?: GraphQLTypes["EventsDocAccessFields_topTenImpression_Read"] | undefined,
	update?: GraphQLTypes["EventsDocAccessFields_topTenImpression_Update"] | undefined,
	delete?: GraphQLTypes["EventsDocAccessFields_topTenImpression_Delete"] | undefined
};
	["EventsDocAccessFields_topTenImpression_Create"]: {
	__typename: "EventsDocAccessFields_topTenImpression_Create",
	permission: boolean
};
	["EventsDocAccessFields_topTenImpression_Read"]: {
	__typename: "EventsDocAccessFields_topTenImpression_Read",
	permission: boolean
};
	["EventsDocAccessFields_topTenImpression_Update"]: {
	__typename: "EventsDocAccessFields_topTenImpression_Update",
	permission: boolean
};
	["EventsDocAccessFields_topTenImpression_Delete"]: {
	__typename: "EventsDocAccessFields_topTenImpression_Delete",
	permission: boolean
};
	["EventsDocAccessFields_editorChoiceInternalClick"]: {
	__typename: "EventsDocAccessFields_editorChoiceInternalClick",
	create?: GraphQLTypes["EventsDocAccessFields_editorChoiceInternalClick_Create"] | undefined,
	read?: GraphQLTypes["EventsDocAccessFields_editorChoiceInternalClick_Read"] | undefined,
	update?: GraphQLTypes["EventsDocAccessFields_editorChoiceInternalClick_Update"] | undefined,
	delete?: GraphQLTypes["EventsDocAccessFields_editorChoiceInternalClick_Delete"] | undefined
};
	["EventsDocAccessFields_editorChoiceInternalClick_Create"]: {
	__typename: "EventsDocAccessFields_editorChoiceInternalClick_Create",
	permission: boolean
};
	["EventsDocAccessFields_editorChoiceInternalClick_Read"]: {
	__typename: "EventsDocAccessFields_editorChoiceInternalClick_Read",
	permission: boolean
};
	["EventsDocAccessFields_editorChoiceInternalClick_Update"]: {
	__typename: "EventsDocAccessFields_editorChoiceInternalClick_Update",
	permission: boolean
};
	["EventsDocAccessFields_editorChoiceInternalClick_Delete"]: {
	__typename: "EventsDocAccessFields_editorChoiceInternalClick_Delete",
	permission: boolean
};
	["EventsDocAccessFields_editorChoiceImpression"]: {
	__typename: "EventsDocAccessFields_editorChoiceImpression",
	create?: GraphQLTypes["EventsDocAccessFields_editorChoiceImpression_Create"] | undefined,
	read?: GraphQLTypes["EventsDocAccessFields_editorChoiceImpression_Read"] | undefined,
	update?: GraphQLTypes["EventsDocAccessFields_editorChoiceImpression_Update"] | undefined,
	delete?: GraphQLTypes["EventsDocAccessFields_editorChoiceImpression_Delete"] | undefined
};
	["EventsDocAccessFields_editorChoiceImpression_Create"]: {
	__typename: "EventsDocAccessFields_editorChoiceImpression_Create",
	permission: boolean
};
	["EventsDocAccessFields_editorChoiceImpression_Read"]: {
	__typename: "EventsDocAccessFields_editorChoiceImpression_Read",
	permission: boolean
};
	["EventsDocAccessFields_editorChoiceImpression_Update"]: {
	__typename: "EventsDocAccessFields_editorChoiceImpression_Update",
	permission: boolean
};
	["EventsDocAccessFields_editorChoiceImpression_Delete"]: {
	__typename: "EventsDocAccessFields_editorChoiceImpression_Delete",
	permission: boolean
};
	["EventsDocAccessFields_otherEventRankingInternalClick"]: {
	__typename: "EventsDocAccessFields_otherEventRankingInternalClick",
	create?: GraphQLTypes["EventsDocAccessFields_otherEventRankingInternalClick_Create"] | undefined,
	read?: GraphQLTypes["EventsDocAccessFields_otherEventRankingInternalClick_Read"] | undefined,
	update?: GraphQLTypes["EventsDocAccessFields_otherEventRankingInternalClick_Update"] | undefined,
	delete?: GraphQLTypes["EventsDocAccessFields_otherEventRankingInternalClick_Delete"] | undefined
};
	["EventsDocAccessFields_otherEventRankingInternalClick_Create"]: {
	__typename: "EventsDocAccessFields_otherEventRankingInternalClick_Create",
	permission: boolean
};
	["EventsDocAccessFields_otherEventRankingInternalClick_Read"]: {
	__typename: "EventsDocAccessFields_otherEventRankingInternalClick_Read",
	permission: boolean
};
	["EventsDocAccessFields_otherEventRankingInternalClick_Update"]: {
	__typename: "EventsDocAccessFields_otherEventRankingInternalClick_Update",
	permission: boolean
};
	["EventsDocAccessFields_otherEventRankingInternalClick_Delete"]: {
	__typename: "EventsDocAccessFields_otherEventRankingInternalClick_Delete",
	permission: boolean
};
	["EventsDocAccessFields_otherEventRankingImpression"]: {
	__typename: "EventsDocAccessFields_otherEventRankingImpression",
	create?: GraphQLTypes["EventsDocAccessFields_otherEventRankingImpression_Create"] | undefined,
	read?: GraphQLTypes["EventsDocAccessFields_otherEventRankingImpression_Read"] | undefined,
	update?: GraphQLTypes["EventsDocAccessFields_otherEventRankingImpression_Update"] | undefined,
	delete?: GraphQLTypes["EventsDocAccessFields_otherEventRankingImpression_Delete"] | undefined
};
	["EventsDocAccessFields_otherEventRankingImpression_Create"]: {
	__typename: "EventsDocAccessFields_otherEventRankingImpression_Create",
	permission: boolean
};
	["EventsDocAccessFields_otherEventRankingImpression_Read"]: {
	__typename: "EventsDocAccessFields_otherEventRankingImpression_Read",
	permission: boolean
};
	["EventsDocAccessFields_otherEventRankingImpression_Update"]: {
	__typename: "EventsDocAccessFields_otherEventRankingImpression_Update",
	permission: boolean
};
	["EventsDocAccessFields_otherEventRankingImpression_Delete"]: {
	__typename: "EventsDocAccessFields_otherEventRankingImpression_Delete",
	permission: boolean
};
	["EventsDocAccessFields_bannerInternalClick"]: {
	__typename: "EventsDocAccessFields_bannerInternalClick",
	create?: GraphQLTypes["EventsDocAccessFields_bannerInternalClick_Create"] | undefined,
	read?: GraphQLTypes["EventsDocAccessFields_bannerInternalClick_Read"] | undefined,
	update?: GraphQLTypes["EventsDocAccessFields_bannerInternalClick_Update"] | undefined,
	delete?: GraphQLTypes["EventsDocAccessFields_bannerInternalClick_Delete"] | undefined
};
	["EventsDocAccessFields_bannerInternalClick_Create"]: {
	__typename: "EventsDocAccessFields_bannerInternalClick_Create",
	permission: boolean
};
	["EventsDocAccessFields_bannerInternalClick_Read"]: {
	__typename: "EventsDocAccessFields_bannerInternalClick_Read",
	permission: boolean
};
	["EventsDocAccessFields_bannerInternalClick_Update"]: {
	__typename: "EventsDocAccessFields_bannerInternalClick_Update",
	permission: boolean
};
	["EventsDocAccessFields_bannerInternalClick_Delete"]: {
	__typename: "EventsDocAccessFields_bannerInternalClick_Delete",
	permission: boolean
};
	["EventsDocAccessFields_miscImpression"]: {
	__typename: "EventsDocAccessFields_miscImpression",
	create?: GraphQLTypes["EventsDocAccessFields_miscImpression_Create"] | undefined,
	read?: GraphQLTypes["EventsDocAccessFields_miscImpression_Read"] | undefined,
	update?: GraphQLTypes["EventsDocAccessFields_miscImpression_Update"] | undefined,
	delete?: GraphQLTypes["EventsDocAccessFields_miscImpression_Delete"] | undefined
};
	["EventsDocAccessFields_miscImpression_Create"]: {
	__typename: "EventsDocAccessFields_miscImpression_Create",
	permission: boolean
};
	["EventsDocAccessFields_miscImpression_Read"]: {
	__typename: "EventsDocAccessFields_miscImpression_Read",
	permission: boolean
};
	["EventsDocAccessFields_miscImpression_Update"]: {
	__typename: "EventsDocAccessFields_miscImpression_Update",
	permission: boolean
};
	["EventsDocAccessFields_miscImpression_Delete"]: {
	__typename: "EventsDocAccessFields_miscImpression_Delete",
	permission: boolean
};
	["EventsDocAccessFields_miscInternalClick"]: {
	__typename: "EventsDocAccessFields_miscInternalClick",
	create?: GraphQLTypes["EventsDocAccessFields_miscInternalClick_Create"] | undefined,
	read?: GraphQLTypes["EventsDocAccessFields_miscInternalClick_Read"] | undefined,
	update?: GraphQLTypes["EventsDocAccessFields_miscInternalClick_Update"] | undefined,
	delete?: GraphQLTypes["EventsDocAccessFields_miscInternalClick_Delete"] | undefined
};
	["EventsDocAccessFields_miscInternalClick_Create"]: {
	__typename: "EventsDocAccessFields_miscInternalClick_Create",
	permission: boolean
};
	["EventsDocAccessFields_miscInternalClick_Read"]: {
	__typename: "EventsDocAccessFields_miscInternalClick_Read",
	permission: boolean
};
	["EventsDocAccessFields_miscInternalClick_Update"]: {
	__typename: "EventsDocAccessFields_miscInternalClick_Update",
	permission: boolean
};
	["EventsDocAccessFields_miscInternalClick_Delete"]: {
	__typename: "EventsDocAccessFields_miscInternalClick_Delete",
	permission: boolean
};
	["EventsDocAccessFields_totalImpression"]: {
	__typename: "EventsDocAccessFields_totalImpression",
	create?: GraphQLTypes["EventsDocAccessFields_totalImpression_Create"] | undefined,
	read?: GraphQLTypes["EventsDocAccessFields_totalImpression_Read"] | undefined,
	update?: GraphQLTypes["EventsDocAccessFields_totalImpression_Update"] | undefined,
	delete?: GraphQLTypes["EventsDocAccessFields_totalImpression_Delete"] | undefined
};
	["EventsDocAccessFields_totalImpression_Create"]: {
	__typename: "EventsDocAccessFields_totalImpression_Create",
	permission: boolean
};
	["EventsDocAccessFields_totalImpression_Read"]: {
	__typename: "EventsDocAccessFields_totalImpression_Read",
	permission: boolean
};
	["EventsDocAccessFields_totalImpression_Update"]: {
	__typename: "EventsDocAccessFields_totalImpression_Update",
	permission: boolean
};
	["EventsDocAccessFields_totalImpression_Delete"]: {
	__typename: "EventsDocAccessFields_totalImpression_Delete",
	permission: boolean
};
	["EventsDocAccessFields_internalClick"]: {
	__typename: "EventsDocAccessFields_internalClick",
	create?: GraphQLTypes["EventsDocAccessFields_internalClick_Create"] | undefined,
	read?: GraphQLTypes["EventsDocAccessFields_internalClick_Read"] | undefined,
	update?: GraphQLTypes["EventsDocAccessFields_internalClick_Update"] | undefined,
	delete?: GraphQLTypes["EventsDocAccessFields_internalClick_Delete"] | undefined
};
	["EventsDocAccessFields_internalClick_Create"]: {
	__typename: "EventsDocAccessFields_internalClick_Create",
	permission: boolean
};
	["EventsDocAccessFields_internalClick_Read"]: {
	__typename: "EventsDocAccessFields_internalClick_Read",
	permission: boolean
};
	["EventsDocAccessFields_internalClick_Update"]: {
	__typename: "EventsDocAccessFields_internalClick_Update",
	permission: boolean
};
	["EventsDocAccessFields_internalClick_Delete"]: {
	__typename: "EventsDocAccessFields_internalClick_Delete",
	permission: boolean
};
	["EventsDocAccessFields_outboundClick"]: {
	__typename: "EventsDocAccessFields_outboundClick",
	create?: GraphQLTypes["EventsDocAccessFields_outboundClick_Create"] | undefined,
	read?: GraphQLTypes["EventsDocAccessFields_outboundClick_Read"] | undefined,
	update?: GraphQLTypes["EventsDocAccessFields_outboundClick_Update"] | undefined,
	delete?: GraphQLTypes["EventsDocAccessFields_outboundClick_Delete"] | undefined
};
	["EventsDocAccessFields_outboundClick_Create"]: {
	__typename: "EventsDocAccessFields_outboundClick_Create",
	permission: boolean
};
	["EventsDocAccessFields_outboundClick_Read"]: {
	__typename: "EventsDocAccessFields_outboundClick_Read",
	permission: boolean
};
	["EventsDocAccessFields_outboundClick_Update"]: {
	__typename: "EventsDocAccessFields_outboundClick_Update",
	permission: boolean
};
	["EventsDocAccessFields_outboundClick_Delete"]: {
	__typename: "EventsDocAccessFields_outboundClick_Delete",
	permission: boolean
};
	["EventsDocAccessFields_externalClick"]: {
	__typename: "EventsDocAccessFields_externalClick",
	create?: GraphQLTypes["EventsDocAccessFields_externalClick_Create"] | undefined,
	read?: GraphQLTypes["EventsDocAccessFields_externalClick_Read"] | undefined,
	update?: GraphQLTypes["EventsDocAccessFields_externalClick_Update"] | undefined,
	delete?: GraphQLTypes["EventsDocAccessFields_externalClick_Delete"] | undefined
};
	["EventsDocAccessFields_externalClick_Create"]: {
	__typename: "EventsDocAccessFields_externalClick_Create",
	permission: boolean
};
	["EventsDocAccessFields_externalClick_Read"]: {
	__typename: "EventsDocAccessFields_externalClick_Read",
	permission: boolean
};
	["EventsDocAccessFields_externalClick_Update"]: {
	__typename: "EventsDocAccessFields_externalClick_Update",
	permission: boolean
};
	["EventsDocAccessFields_externalClick_Delete"]: {
	__typename: "EventsDocAccessFields_externalClick_Delete",
	permission: boolean
};
	["EventsDocAccessFields__viewsCountByMonth"]: {
	__typename: "EventsDocAccessFields__viewsCountByMonth",
	create?: GraphQLTypes["EventsDocAccessFields__viewsCountByMonth_Create"] | undefined,
	read?: GraphQLTypes["EventsDocAccessFields__viewsCountByMonth_Read"] | undefined,
	update?: GraphQLTypes["EventsDocAccessFields__viewsCountByMonth_Update"] | undefined,
	delete?: GraphQLTypes["EventsDocAccessFields__viewsCountByMonth_Delete"] | undefined
};
	["EventsDocAccessFields__viewsCountByMonth_Create"]: {
	__typename: "EventsDocAccessFields__viewsCountByMonth_Create",
	permission: boolean
};
	["EventsDocAccessFields__viewsCountByMonth_Read"]: {
	__typename: "EventsDocAccessFields__viewsCountByMonth_Read",
	permission: boolean
};
	["EventsDocAccessFields__viewsCountByMonth_Update"]: {
	__typename: "EventsDocAccessFields__viewsCountByMonth_Update",
	permission: boolean
};
	["EventsDocAccessFields__viewsCountByMonth_Delete"]: {
	__typename: "EventsDocAccessFields__viewsCountByMonth_Delete",
	permission: boolean
};
	["EventsDocAccessFields_products"]: {
	__typename: "EventsDocAccessFields_products",
	create?: GraphQLTypes["EventsDocAccessFields_products_Create"] | undefined,
	read?: GraphQLTypes["EventsDocAccessFields_products_Read"] | undefined,
	update?: GraphQLTypes["EventsDocAccessFields_products_Update"] | undefined,
	delete?: GraphQLTypes["EventsDocAccessFields_products_Delete"] | undefined,
	fields?: GraphQLTypes["EventsDocAccessFields_products_Fields"] | undefined
};
	["EventsDocAccessFields_products_Create"]: {
	__typename: "EventsDocAccessFields_products_Create",
	permission: boolean
};
	["EventsDocAccessFields_products_Read"]: {
	__typename: "EventsDocAccessFields_products_Read",
	permission: boolean
};
	["EventsDocAccessFields_products_Update"]: {
	__typename: "EventsDocAccessFields_products_Update",
	permission: boolean
};
	["EventsDocAccessFields_products_Delete"]: {
	__typename: "EventsDocAccessFields_products_Delete",
	permission: boolean
};
	["EventsDocAccessFields_products_Fields"]: {
	__typename: "EventsDocAccessFields_products_Fields",
	startDateTime?: GraphQLTypes["EventsDocAccessFields_products_startDateTime"] | undefined,
	endDateTime?: GraphQLTypes["EventsDocAccessFields_products_endDateTime"] | undefined,
	fullDay?: GraphQLTypes["EventsDocAccessFields_products_fullDay"] | undefined,
	linkedProduct?: GraphQLTypes["EventsDocAccessFields_products_linkedProduct"] | undefined,
	id?: GraphQLTypes["EventsDocAccessFields_products_id"] | undefined
};
	["EventsDocAccessFields_products_startDateTime"]: {
	__typename: "EventsDocAccessFields_products_startDateTime",
	create?: GraphQLTypes["EventsDocAccessFields_products_startDateTime_Create"] | undefined,
	read?: GraphQLTypes["EventsDocAccessFields_products_startDateTime_Read"] | undefined,
	update?: GraphQLTypes["EventsDocAccessFields_products_startDateTime_Update"] | undefined,
	delete?: GraphQLTypes["EventsDocAccessFields_products_startDateTime_Delete"] | undefined
};
	["EventsDocAccessFields_products_startDateTime_Create"]: {
	__typename: "EventsDocAccessFields_products_startDateTime_Create",
	permission: boolean
};
	["EventsDocAccessFields_products_startDateTime_Read"]: {
	__typename: "EventsDocAccessFields_products_startDateTime_Read",
	permission: boolean
};
	["EventsDocAccessFields_products_startDateTime_Update"]: {
	__typename: "EventsDocAccessFields_products_startDateTime_Update",
	permission: boolean
};
	["EventsDocAccessFields_products_startDateTime_Delete"]: {
	__typename: "EventsDocAccessFields_products_startDateTime_Delete",
	permission: boolean
};
	["EventsDocAccessFields_products_endDateTime"]: {
	__typename: "EventsDocAccessFields_products_endDateTime",
	create?: GraphQLTypes["EventsDocAccessFields_products_endDateTime_Create"] | undefined,
	read?: GraphQLTypes["EventsDocAccessFields_products_endDateTime_Read"] | undefined,
	update?: GraphQLTypes["EventsDocAccessFields_products_endDateTime_Update"] | undefined,
	delete?: GraphQLTypes["EventsDocAccessFields_products_endDateTime_Delete"] | undefined
};
	["EventsDocAccessFields_products_endDateTime_Create"]: {
	__typename: "EventsDocAccessFields_products_endDateTime_Create",
	permission: boolean
};
	["EventsDocAccessFields_products_endDateTime_Read"]: {
	__typename: "EventsDocAccessFields_products_endDateTime_Read",
	permission: boolean
};
	["EventsDocAccessFields_products_endDateTime_Update"]: {
	__typename: "EventsDocAccessFields_products_endDateTime_Update",
	permission: boolean
};
	["EventsDocAccessFields_products_endDateTime_Delete"]: {
	__typename: "EventsDocAccessFields_products_endDateTime_Delete",
	permission: boolean
};
	["EventsDocAccessFields_products_fullDay"]: {
	__typename: "EventsDocAccessFields_products_fullDay",
	create?: GraphQLTypes["EventsDocAccessFields_products_fullDay_Create"] | undefined,
	read?: GraphQLTypes["EventsDocAccessFields_products_fullDay_Read"] | undefined,
	update?: GraphQLTypes["EventsDocAccessFields_products_fullDay_Update"] | undefined,
	delete?: GraphQLTypes["EventsDocAccessFields_products_fullDay_Delete"] | undefined
};
	["EventsDocAccessFields_products_fullDay_Create"]: {
	__typename: "EventsDocAccessFields_products_fullDay_Create",
	permission: boolean
};
	["EventsDocAccessFields_products_fullDay_Read"]: {
	__typename: "EventsDocAccessFields_products_fullDay_Read",
	permission: boolean
};
	["EventsDocAccessFields_products_fullDay_Update"]: {
	__typename: "EventsDocAccessFields_products_fullDay_Update",
	permission: boolean
};
	["EventsDocAccessFields_products_fullDay_Delete"]: {
	__typename: "EventsDocAccessFields_products_fullDay_Delete",
	permission: boolean
};
	["EventsDocAccessFields_products_linkedProduct"]: {
	__typename: "EventsDocAccessFields_products_linkedProduct",
	create?: GraphQLTypes["EventsDocAccessFields_products_linkedProduct_Create"] | undefined,
	read?: GraphQLTypes["EventsDocAccessFields_products_linkedProduct_Read"] | undefined,
	update?: GraphQLTypes["EventsDocAccessFields_products_linkedProduct_Update"] | undefined,
	delete?: GraphQLTypes["EventsDocAccessFields_products_linkedProduct_Delete"] | undefined
};
	["EventsDocAccessFields_products_linkedProduct_Create"]: {
	__typename: "EventsDocAccessFields_products_linkedProduct_Create",
	permission: boolean
};
	["EventsDocAccessFields_products_linkedProduct_Read"]: {
	__typename: "EventsDocAccessFields_products_linkedProduct_Read",
	permission: boolean
};
	["EventsDocAccessFields_products_linkedProduct_Update"]: {
	__typename: "EventsDocAccessFields_products_linkedProduct_Update",
	permission: boolean
};
	["EventsDocAccessFields_products_linkedProduct_Delete"]: {
	__typename: "EventsDocAccessFields_products_linkedProduct_Delete",
	permission: boolean
};
	["EventsDocAccessFields_products_id"]: {
	__typename: "EventsDocAccessFields_products_id",
	create?: GraphQLTypes["EventsDocAccessFields_products_id_Create"] | undefined,
	read?: GraphQLTypes["EventsDocAccessFields_products_id_Read"] | undefined,
	update?: GraphQLTypes["EventsDocAccessFields_products_id_Update"] | undefined,
	delete?: GraphQLTypes["EventsDocAccessFields_products_id_Delete"] | undefined
};
	["EventsDocAccessFields_products_id_Create"]: {
	__typename: "EventsDocAccessFields_products_id_Create",
	permission: boolean
};
	["EventsDocAccessFields_products_id_Read"]: {
	__typename: "EventsDocAccessFields_products_id_Read",
	permission: boolean
};
	["EventsDocAccessFields_products_id_Update"]: {
	__typename: "EventsDocAccessFields_products_id_Update",
	permission: boolean
};
	["EventsDocAccessFields_products_id_Delete"]: {
	__typename: "EventsDocAccessFields_products_id_Delete",
	permission: boolean
};
	["EventsDocAccessFields_legacyGallery"]: {
	__typename: "EventsDocAccessFields_legacyGallery",
	create?: GraphQLTypes["EventsDocAccessFields_legacyGallery_Create"] | undefined,
	read?: GraphQLTypes["EventsDocAccessFields_legacyGallery_Read"] | undefined,
	update?: GraphQLTypes["EventsDocAccessFields_legacyGallery_Update"] | undefined,
	delete?: GraphQLTypes["EventsDocAccessFields_legacyGallery_Delete"] | undefined
};
	["EventsDocAccessFields_legacyGallery_Create"]: {
	__typename: "EventsDocAccessFields_legacyGallery_Create",
	permission: boolean
};
	["EventsDocAccessFields_legacyGallery_Read"]: {
	__typename: "EventsDocAccessFields_legacyGallery_Read",
	permission: boolean
};
	["EventsDocAccessFields_legacyGallery_Update"]: {
	__typename: "EventsDocAccessFields_legacyGallery_Update",
	permission: boolean
};
	["EventsDocAccessFields_legacyGallery_Delete"]: {
	__typename: "EventsDocAccessFields_legacyGallery_Delete",
	permission: boolean
};
	["EventsDocAccessFields_earliestStartDate"]: {
	__typename: "EventsDocAccessFields_earliestStartDate",
	create?: GraphQLTypes["EventsDocAccessFields_earliestStartDate_Create"] | undefined,
	read?: GraphQLTypes["EventsDocAccessFields_earliestStartDate_Read"] | undefined,
	update?: GraphQLTypes["EventsDocAccessFields_earliestStartDate_Update"] | undefined,
	delete?: GraphQLTypes["EventsDocAccessFields_earliestStartDate_Delete"] | undefined
};
	["EventsDocAccessFields_earliestStartDate_Create"]: {
	__typename: "EventsDocAccessFields_earliestStartDate_Create",
	permission: boolean
};
	["EventsDocAccessFields_earliestStartDate_Read"]: {
	__typename: "EventsDocAccessFields_earliestStartDate_Read",
	permission: boolean
};
	["EventsDocAccessFields_earliestStartDate_Update"]: {
	__typename: "EventsDocAccessFields_earliestStartDate_Update",
	permission: boolean
};
	["EventsDocAccessFields_earliestStartDate_Delete"]: {
	__typename: "EventsDocAccessFields_earliestStartDate_Delete",
	permission: boolean
};
	["EventsDocAccessFields_latestEndDate"]: {
	__typename: "EventsDocAccessFields_latestEndDate",
	create?: GraphQLTypes["EventsDocAccessFields_latestEndDate_Create"] | undefined,
	read?: GraphQLTypes["EventsDocAccessFields_latestEndDate_Read"] | undefined,
	update?: GraphQLTypes["EventsDocAccessFields_latestEndDate_Update"] | undefined,
	delete?: GraphQLTypes["EventsDocAccessFields_latestEndDate_Delete"] | undefined
};
	["EventsDocAccessFields_latestEndDate_Create"]: {
	__typename: "EventsDocAccessFields_latestEndDate_Create",
	permission: boolean
};
	["EventsDocAccessFields_latestEndDate_Read"]: {
	__typename: "EventsDocAccessFields_latestEndDate_Read",
	permission: boolean
};
	["EventsDocAccessFields_latestEndDate_Update"]: {
	__typename: "EventsDocAccessFields_latestEndDate_Update",
	permission: boolean
};
	["EventsDocAccessFields_latestEndDate_Delete"]: {
	__typename: "EventsDocAccessFields_latestEndDate_Delete",
	permission: boolean
};
	["EventsDocAccessFields_latestStartDateTime"]: {
	__typename: "EventsDocAccessFields_latestStartDateTime",
	create?: GraphQLTypes["EventsDocAccessFields_latestStartDateTime_Create"] | undefined,
	read?: GraphQLTypes["EventsDocAccessFields_latestStartDateTime_Read"] | undefined,
	update?: GraphQLTypes["EventsDocAccessFields_latestStartDateTime_Update"] | undefined,
	delete?: GraphQLTypes["EventsDocAccessFields_latestStartDateTime_Delete"] | undefined
};
	["EventsDocAccessFields_latestStartDateTime_Create"]: {
	__typename: "EventsDocAccessFields_latestStartDateTime_Create",
	permission: boolean
};
	["EventsDocAccessFields_latestStartDateTime_Read"]: {
	__typename: "EventsDocAccessFields_latestStartDateTime_Read",
	permission: boolean
};
	["EventsDocAccessFields_latestStartDateTime_Update"]: {
	__typename: "EventsDocAccessFields_latestStartDateTime_Update",
	permission: boolean
};
	["EventsDocAccessFields_latestStartDateTime_Delete"]: {
	__typename: "EventsDocAccessFields_latestStartDateTime_Delete",
	permission: boolean
};
	["EventsDocAccessFields_hasTicketing"]: {
	__typename: "EventsDocAccessFields_hasTicketing",
	create?: GraphQLTypes["EventsDocAccessFields_hasTicketing_Create"] | undefined,
	read?: GraphQLTypes["EventsDocAccessFields_hasTicketing_Read"] | undefined,
	update?: GraphQLTypes["EventsDocAccessFields_hasTicketing_Update"] | undefined,
	delete?: GraphQLTypes["EventsDocAccessFields_hasTicketing_Delete"] | undefined
};
	["EventsDocAccessFields_hasTicketing_Create"]: {
	__typename: "EventsDocAccessFields_hasTicketing_Create",
	permission: boolean
};
	["EventsDocAccessFields_hasTicketing_Read"]: {
	__typename: "EventsDocAccessFields_hasTicketing_Read",
	permission: boolean
};
	["EventsDocAccessFields_hasTicketing_Update"]: {
	__typename: "EventsDocAccessFields_hasTicketing_Update",
	permission: boolean
};
	["EventsDocAccessFields_hasTicketing_Delete"]: {
	__typename: "EventsDocAccessFields_hasTicketing_Delete",
	permission: boolean
};
	["EventsDocAccessFields_hasProducts"]: {
	__typename: "EventsDocAccessFields_hasProducts",
	create?: GraphQLTypes["EventsDocAccessFields_hasProducts_Create"] | undefined,
	read?: GraphQLTypes["EventsDocAccessFields_hasProducts_Read"] | undefined,
	update?: GraphQLTypes["EventsDocAccessFields_hasProducts_Update"] | undefined,
	delete?: GraphQLTypes["EventsDocAccessFields_hasProducts_Delete"] | undefined
};
	["EventsDocAccessFields_hasProducts_Create"]: {
	__typename: "EventsDocAccessFields_hasProducts_Create",
	permission: boolean
};
	["EventsDocAccessFields_hasProducts_Read"]: {
	__typename: "EventsDocAccessFields_hasProducts_Read",
	permission: boolean
};
	["EventsDocAccessFields_hasProducts_Update"]: {
	__typename: "EventsDocAccessFields_hasProducts_Update",
	permission: boolean
};
	["EventsDocAccessFields_hasProducts_Delete"]: {
	__typename: "EventsDocAccessFields_hasProducts_Delete",
	permission: boolean
};
	["EventsDocAccessFields_updatedAt"]: {
	__typename: "EventsDocAccessFields_updatedAt",
	create?: GraphQLTypes["EventsDocAccessFields_updatedAt_Create"] | undefined,
	read?: GraphQLTypes["EventsDocAccessFields_updatedAt_Read"] | undefined,
	update?: GraphQLTypes["EventsDocAccessFields_updatedAt_Update"] | undefined,
	delete?: GraphQLTypes["EventsDocAccessFields_updatedAt_Delete"] | undefined
};
	["EventsDocAccessFields_updatedAt_Create"]: {
	__typename: "EventsDocAccessFields_updatedAt_Create",
	permission: boolean
};
	["EventsDocAccessFields_updatedAt_Read"]: {
	__typename: "EventsDocAccessFields_updatedAt_Read",
	permission: boolean
};
	["EventsDocAccessFields_updatedAt_Update"]: {
	__typename: "EventsDocAccessFields_updatedAt_Update",
	permission: boolean
};
	["EventsDocAccessFields_updatedAt_Delete"]: {
	__typename: "EventsDocAccessFields_updatedAt_Delete",
	permission: boolean
};
	["EventsDocAccessFields_createdAt"]: {
	__typename: "EventsDocAccessFields_createdAt",
	create?: GraphQLTypes["EventsDocAccessFields_createdAt_Create"] | undefined,
	read?: GraphQLTypes["EventsDocAccessFields_createdAt_Read"] | undefined,
	update?: GraphQLTypes["EventsDocAccessFields_createdAt_Update"] | undefined,
	delete?: GraphQLTypes["EventsDocAccessFields_createdAt_Delete"] | undefined
};
	["EventsDocAccessFields_createdAt_Create"]: {
	__typename: "EventsDocAccessFields_createdAt_Create",
	permission: boolean
};
	["EventsDocAccessFields_createdAt_Read"]: {
	__typename: "EventsDocAccessFields_createdAt_Read",
	permission: boolean
};
	["EventsDocAccessFields_createdAt_Update"]: {
	__typename: "EventsDocAccessFields_createdAt_Update",
	permission: boolean
};
	["EventsDocAccessFields_createdAt_Delete"]: {
	__typename: "EventsDocAccessFields_createdAt_Delete",
	permission: boolean
};
	["EventsCreateDocAccess"]: {
	__typename: "EventsCreateDocAccess",
	permission: boolean,
	where?: GraphQLTypes["JSONObject"] | undefined
};
	/** The `JSONObject` scalar type represents JSON objects as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
["JSONObject"]: "scalar" & { name: "JSONObject" };
	["EventsReadDocAccess"]: {
	__typename: "EventsReadDocAccess",
	permission: boolean,
	where?: GraphQLTypes["JSONObject"] | undefined
};
	["EventsUpdateDocAccess"]: {
	__typename: "EventsUpdateDocAccess",
	permission: boolean,
	where?: GraphQLTypes["JSONObject"] | undefined
};
	["EventsDeleteDocAccess"]: {
	__typename: "EventsDeleteDocAccess",
	permission: boolean,
	where?: GraphQLTypes["JSONObject"] | undefined
};
	["Locations"]: {
	__typename: "Locations",
	docs?: Array<GraphQLTypes["Location"] | undefined> | undefined,
	hasNextPage?: boolean | undefined,
	hasPrevPage?: boolean | undefined,
	limit?: number | undefined,
	nextPage?: number | undefined,
	offset?: number | undefined,
	page?: number | undefined,
	pagingCounter?: number | undefined,
	prevPage?: number | undefined,
	totalDocs?: number | undefined,
	totalPages?: number | undefined
};
	["Location_where"]: {
		region?: GraphQLTypes["Location_region_operator"] | undefined,
	creator?: GraphQLTypes["Location_creator_operator"] | undefined,
	permalink?: GraphQLTypes["Location_permalink_operator"] | undefined,
	status?: GraphQLTypes["Location_status_operator"] | undefined,
	level?: GraphQLTypes["Location_level_operator"] | undefined,
	parent?: GraphQLTypes["Location_parent_operator"] | undefined,
	name?: GraphQLTypes["Location_name_operator"] | undefined,
	thumbnail?: GraphQLTypes["Location_thumbnail_operator"] | undefined,
	banner?: GraphQLTypes["Location_banner_operator"] | undefined,
	type?: GraphQLTypes["Location_type_operator"] | undefined,
	category?: GraphQLTypes["Location_category_operator"] | undefined,
	district?: GraphQLTypes["Location_district_operator"] | undefined,
	coordinate?: GraphQLTypes["Location_coordinate_operator"] | undefined,
	linkAddress?: GraphQLTypes["Location_linkAddress_operator"] | undefined,
	address?: GraphQLTypes["Location_address_operator"] | undefined,
	Details__code?: GraphQLTypes["Location_Details__code_operator"] | undefined,
	Details__phone?: GraphQLTypes["Location_Details__phone_operator"] | undefined,
	Details__page?: GraphQLTypes["Location_Details__page_operator"] | undefined,
	Details__email?: GraphQLTypes["Location_Details__email_operator"] | undefined,
	legacyContent?: GraphQLTypes["Location_legacyContent_operator"] | undefined,
	showLegacyContent?: GraphQLTypes["Location_showLegacyContent_operator"] | undefined,
	criteria__audiences?: GraphQLTypes["Location_criteria__audiences_operator"] | undefined,
	criteria__dates?: GraphQLTypes["Location_criteria__dates_operator"] | undefined,
	criteria__categories?: GraphQLTypes["Location_criteria__categories_operator"] | undefined,
	criteria__tags?: GraphQLTypes["Location_criteria__tags_operator"] | undefined,
	sections__startDatetime?: GraphQLTypes["Location_sections__startDatetime_operator"] | undefined,
	sections__endDatetime?: GraphQLTypes["Location_sections__endDatetime_operator"] | undefined,
	sections__fullDay?: GraphQLTypes["Location_sections__fullDay_operator"] | undefined,
	sections__repeat?: GraphQLTypes["Location_sections__repeat_operator"] | undefined,
	sections__recurrance__type?: GraphQLTypes["Location_sections__recurrance__type_operator"] | undefined,
	sections__recurrance__weekday?: GraphQLTypes["Location_sections__recurrance__weekday_operator"] | undefined,
	sections__id?: GraphQLTypes["Location_sections__id_operator"] | undefined,
	_title?: GraphQLTypes["Location__title_operator"] | undefined,
	legacyGallery?: GraphQLTypes["Location_legacyGallery_operator"] | undefined,
	updatedAt?: GraphQLTypes["Location_updatedAt_operator"] | undefined,
	createdAt?: GraphQLTypes["Location_createdAt_operator"] | undefined,
	id?: GraphQLTypes["Location_id_operator"] | undefined,
	AND?: Array<GraphQLTypes["Location_where_and"] | undefined> | undefined,
	OR?: Array<GraphQLTypes["Location_where_or"] | undefined> | undefined
};
	["Location_region_operator"]: {
		equals?: GraphQLTypes["Location_region_Input"] | undefined,
	not_equals?: GraphQLTypes["Location_region_Input"] | undefined,
	in?: Array<GraphQLTypes["Location_region_Input"] | undefined> | undefined,
	not_in?: Array<GraphQLTypes["Location_region_Input"] | undefined> | undefined,
	all?: Array<GraphQLTypes["Location_region_Input"] | undefined> | undefined,
	exists?: boolean | undefined
};
	["Location_region_Input"]: Location_region_Input;
	["Location_creator_operator"]: {
		equals?: GraphQLTypes["JSON"] | undefined,
	not_equals?: GraphQLTypes["JSON"] | undefined,
	in?: Array<GraphQLTypes["JSON"] | undefined> | undefined,
	not_in?: Array<GraphQLTypes["JSON"] | undefined> | undefined,
	all?: Array<GraphQLTypes["JSON"] | undefined> | undefined,
	exists?: boolean | undefined
};
	["Location_permalink_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Location_status_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined
};
	["Location_level_operator"]: {
		equals?: GraphQLTypes["Location_level_Input"] | undefined,
	not_equals?: GraphQLTypes["Location_level_Input"] | undefined,
	in?: Array<GraphQLTypes["Location_level_Input"] | undefined> | undefined,
	not_in?: Array<GraphQLTypes["Location_level_Input"] | undefined> | undefined,
	all?: Array<GraphQLTypes["Location_level_Input"] | undefined> | undefined
};
	["Location_level_Input"]: Location_level_Input;
	["Location_parent_operator"]: {
		equals?: GraphQLTypes["JSON"] | undefined,
	not_equals?: GraphQLTypes["JSON"] | undefined,
	in?: Array<GraphQLTypes["JSON"] | undefined> | undefined,
	not_in?: Array<GraphQLTypes["JSON"] | undefined> | undefined,
	all?: Array<GraphQLTypes["JSON"] | undefined> | undefined,
	exists?: boolean | undefined
};
	["Location_name_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined
};
	["Location_thumbnail_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	exists?: boolean | undefined
};
	["Location_banner_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	exists?: boolean | undefined
};
	["Location_type_operator"]: {
		equals?: GraphQLTypes["Location_type_Input"] | undefined,
	not_equals?: GraphQLTypes["Location_type_Input"] | undefined,
	in?: Array<GraphQLTypes["Location_type_Input"] | undefined> | undefined,
	not_in?: Array<GraphQLTypes["Location_type_Input"] | undefined> | undefined,
	all?: Array<GraphQLTypes["Location_type_Input"] | undefined> | undefined
};
	["Location_type_Input"]: Location_type_Input;
	["Location_category_operator"]: {
		equals?: GraphQLTypes["Location_category_Input"] | undefined,
	not_equals?: GraphQLTypes["Location_category_Input"] | undefined,
	in?: Array<GraphQLTypes["Location_category_Input"] | undefined> | undefined,
	not_in?: Array<GraphQLTypes["Location_category_Input"] | undefined> | undefined,
	all?: Array<GraphQLTypes["Location_category_Input"] | undefined> | undefined
};
	["Location_category_Input"]: Location_category_Input;
	["Location_district_operator"]: {
		equals?: GraphQLTypes["JSON"] | undefined,
	not_equals?: GraphQLTypes["JSON"] | undefined,
	in?: Array<GraphQLTypes["JSON"] | undefined> | undefined,
	not_in?: Array<GraphQLTypes["JSON"] | undefined> | undefined,
	all?: Array<GraphQLTypes["JSON"] | undefined> | undefined,
	exists?: boolean | undefined
};
	["Location_coordinate_operator"]: {
		equals?: Array<number | undefined> | undefined,
	not_equals?: Array<number | undefined> | undefined,
	greater_than_equal?: Array<number | undefined> | undefined,
	greater_than?: Array<number | undefined> | undefined,
	less_than_equal?: Array<number | undefined> | undefined,
	less_than?: Array<number | undefined> | undefined,
	near?: Array<number | undefined> | undefined,
	within?: GraphQLTypes["GeoJSONObject"] | undefined,
	intersects?: GraphQLTypes["GeoJSONObject"] | undefined,
	exists?: boolean | undefined
};
	["Location_linkAddress_operator"]: {
		equals?: boolean | undefined,
	not_equals?: boolean | undefined,
	exists?: boolean | undefined
};
	["Location_address_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Location_Details__code_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Location_Details__phone_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Location_Details__page_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Location_Details__email_operator"]: {
		equals?: GraphQLTypes["EmailAddress"] | undefined,
	not_equals?: GraphQLTypes["EmailAddress"] | undefined,
	like?: GraphQLTypes["EmailAddress"] | undefined,
	contains?: GraphQLTypes["EmailAddress"] | undefined,
	in?: Array<GraphQLTypes["EmailAddress"] | undefined> | undefined,
	not_in?: Array<GraphQLTypes["EmailAddress"] | undefined> | undefined,
	all?: Array<GraphQLTypes["EmailAddress"] | undefined> | undefined,
	exists?: boolean | undefined
};
	["Location_legacyContent_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	exists?: boolean | undefined
};
	["Location_showLegacyContent_operator"]: {
		equals?: boolean | undefined,
	not_equals?: boolean | undefined,
	exists?: boolean | undefined
};
	["Location_criteria__audiences_operator"]: {
		equals?: GraphQLTypes["JSON"] | undefined,
	not_equals?: GraphQLTypes["JSON"] | undefined,
	in?: Array<GraphQLTypes["JSON"] | undefined> | undefined,
	not_in?: Array<GraphQLTypes["JSON"] | undefined> | undefined,
	all?: Array<GraphQLTypes["JSON"] | undefined> | undefined,
	exists?: boolean | undefined
};
	["Location_criteria__dates_operator"]: {
		equals?: GraphQLTypes["JSON"] | undefined,
	not_equals?: GraphQLTypes["JSON"] | undefined,
	in?: Array<GraphQLTypes["JSON"] | undefined> | undefined,
	not_in?: Array<GraphQLTypes["JSON"] | undefined> | undefined,
	all?: Array<GraphQLTypes["JSON"] | undefined> | undefined,
	exists?: boolean | undefined
};
	["Location_criteria__categories_operator"]: {
		equals?: GraphQLTypes["JSON"] | undefined,
	not_equals?: GraphQLTypes["JSON"] | undefined,
	in?: Array<GraphQLTypes["JSON"] | undefined> | undefined,
	not_in?: Array<GraphQLTypes["JSON"] | undefined> | undefined,
	all?: Array<GraphQLTypes["JSON"] | undefined> | undefined,
	exists?: boolean | undefined
};
	["Location_criteria__tags_operator"]: {
		equals?: GraphQLTypes["JSON"] | undefined,
	not_equals?: GraphQLTypes["JSON"] | undefined,
	in?: Array<GraphQLTypes["JSON"] | undefined> | undefined,
	not_in?: Array<GraphQLTypes["JSON"] | undefined> | undefined,
	all?: Array<GraphQLTypes["JSON"] | undefined> | undefined,
	exists?: boolean | undefined
};
	["Location_sections__startDatetime_operator"]: {
		equals?: GraphQLTypes["DateTime"] | undefined,
	not_equals?: GraphQLTypes["DateTime"] | undefined,
	greater_than_equal?: GraphQLTypes["DateTime"] | undefined,
	greater_than?: GraphQLTypes["DateTime"] | undefined,
	less_than_equal?: GraphQLTypes["DateTime"] | undefined,
	less_than?: GraphQLTypes["DateTime"] | undefined,
	like?: GraphQLTypes["DateTime"] | undefined
};
	["Location_sections__endDatetime_operator"]: {
		equals?: GraphQLTypes["DateTime"] | undefined,
	not_equals?: GraphQLTypes["DateTime"] | undefined,
	greater_than_equal?: GraphQLTypes["DateTime"] | undefined,
	greater_than?: GraphQLTypes["DateTime"] | undefined,
	less_than_equal?: GraphQLTypes["DateTime"] | undefined,
	less_than?: GraphQLTypes["DateTime"] | undefined,
	like?: GraphQLTypes["DateTime"] | undefined
};
	["Location_sections__fullDay_operator"]: {
		equals?: boolean | undefined,
	not_equals?: boolean | undefined,
	exists?: boolean | undefined
};
	["Location_sections__repeat_operator"]: {
		equals?: boolean | undefined,
	not_equals?: boolean | undefined,
	exists?: boolean | undefined
};
	["Location_sections__recurrance__type_operator"]: {
		equals?: GraphQLTypes["Location_sections__recurrance__type_Input"] | undefined,
	not_equals?: GraphQLTypes["Location_sections__recurrance__type_Input"] | undefined,
	in?: Array<GraphQLTypes["Location_sections__recurrance__type_Input"] | undefined> | undefined,
	not_in?: Array<GraphQLTypes["Location_sections__recurrance__type_Input"] | undefined> | undefined,
	all?: Array<GraphQLTypes["Location_sections__recurrance__type_Input"] | undefined> | undefined
};
	["Location_sections__recurrance__type_Input"]: Location_sections__recurrance__type_Input;
	["Location_sections__recurrance__weekday_operator"]: {
		equals?: GraphQLTypes["Location_sections__recurrance__weekday_Input"] | undefined,
	not_equals?: GraphQLTypes["Location_sections__recurrance__weekday_Input"] | undefined,
	in?: Array<GraphQLTypes["Location_sections__recurrance__weekday_Input"] | undefined> | undefined,
	not_in?: Array<GraphQLTypes["Location_sections__recurrance__weekday_Input"] | undefined> | undefined,
	all?: Array<GraphQLTypes["Location_sections__recurrance__weekday_Input"] | undefined> | undefined
};
	["Location_sections__recurrance__weekday_Input"]: Location_sections__recurrance__weekday_Input;
	["Location_sections__id_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Location__title_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Location_legacyGallery_operator"]: {
		equals?: GraphQLTypes["JSON"] | undefined,
	not_equals?: GraphQLTypes["JSON"] | undefined,
	in?: Array<GraphQLTypes["JSON"] | undefined> | undefined,
	not_in?: Array<GraphQLTypes["JSON"] | undefined> | undefined,
	all?: Array<GraphQLTypes["JSON"] | undefined> | undefined,
	exists?: boolean | undefined
};
	["Location_updatedAt_operator"]: {
		equals?: GraphQLTypes["DateTime"] | undefined,
	not_equals?: GraphQLTypes["DateTime"] | undefined,
	greater_than_equal?: GraphQLTypes["DateTime"] | undefined,
	greater_than?: GraphQLTypes["DateTime"] | undefined,
	less_than_equal?: GraphQLTypes["DateTime"] | undefined,
	less_than?: GraphQLTypes["DateTime"] | undefined,
	like?: GraphQLTypes["DateTime"] | undefined,
	exists?: boolean | undefined
};
	["Location_createdAt_operator"]: {
		equals?: GraphQLTypes["DateTime"] | undefined,
	not_equals?: GraphQLTypes["DateTime"] | undefined,
	greater_than_equal?: GraphQLTypes["DateTime"] | undefined,
	greater_than?: GraphQLTypes["DateTime"] | undefined,
	less_than_equal?: GraphQLTypes["DateTime"] | undefined,
	less_than?: GraphQLTypes["DateTime"] | undefined,
	like?: GraphQLTypes["DateTime"] | undefined,
	exists?: boolean | undefined
};
	["Location_id_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Location_where_and"]: {
		region?: GraphQLTypes["Location_region_operator"] | undefined,
	creator?: GraphQLTypes["Location_creator_operator"] | undefined,
	permalink?: GraphQLTypes["Location_permalink_operator"] | undefined,
	status?: GraphQLTypes["Location_status_operator"] | undefined,
	level?: GraphQLTypes["Location_level_operator"] | undefined,
	parent?: GraphQLTypes["Location_parent_operator"] | undefined,
	name?: GraphQLTypes["Location_name_operator"] | undefined,
	thumbnail?: GraphQLTypes["Location_thumbnail_operator"] | undefined,
	banner?: GraphQLTypes["Location_banner_operator"] | undefined,
	type?: GraphQLTypes["Location_type_operator"] | undefined,
	category?: GraphQLTypes["Location_category_operator"] | undefined,
	district?: GraphQLTypes["Location_district_operator"] | undefined,
	coordinate?: GraphQLTypes["Location_coordinate_operator"] | undefined,
	linkAddress?: GraphQLTypes["Location_linkAddress_operator"] | undefined,
	address?: GraphQLTypes["Location_address_operator"] | undefined,
	Details__code?: GraphQLTypes["Location_Details__code_operator"] | undefined,
	Details__phone?: GraphQLTypes["Location_Details__phone_operator"] | undefined,
	Details__page?: GraphQLTypes["Location_Details__page_operator"] | undefined,
	Details__email?: GraphQLTypes["Location_Details__email_operator"] | undefined,
	legacyContent?: GraphQLTypes["Location_legacyContent_operator"] | undefined,
	showLegacyContent?: GraphQLTypes["Location_showLegacyContent_operator"] | undefined,
	criteria__audiences?: GraphQLTypes["Location_criteria__audiences_operator"] | undefined,
	criteria__dates?: GraphQLTypes["Location_criteria__dates_operator"] | undefined,
	criteria__categories?: GraphQLTypes["Location_criteria__categories_operator"] | undefined,
	criteria__tags?: GraphQLTypes["Location_criteria__tags_operator"] | undefined,
	sections__startDatetime?: GraphQLTypes["Location_sections__startDatetime_operator"] | undefined,
	sections__endDatetime?: GraphQLTypes["Location_sections__endDatetime_operator"] | undefined,
	sections__fullDay?: GraphQLTypes["Location_sections__fullDay_operator"] | undefined,
	sections__repeat?: GraphQLTypes["Location_sections__repeat_operator"] | undefined,
	sections__recurrance__type?: GraphQLTypes["Location_sections__recurrance__type_operator"] | undefined,
	sections__recurrance__weekday?: GraphQLTypes["Location_sections__recurrance__weekday_operator"] | undefined,
	sections__id?: GraphQLTypes["Location_sections__id_operator"] | undefined,
	_title?: GraphQLTypes["Location__title_operator"] | undefined,
	legacyGallery?: GraphQLTypes["Location_legacyGallery_operator"] | undefined,
	updatedAt?: GraphQLTypes["Location_updatedAt_operator"] | undefined,
	createdAt?: GraphQLTypes["Location_createdAt_operator"] | undefined,
	id?: GraphQLTypes["Location_id_operator"] | undefined,
	AND?: Array<GraphQLTypes["Location_where_and"] | undefined> | undefined,
	OR?: Array<GraphQLTypes["Location_where_or"] | undefined> | undefined
};
	["Location_where_or"]: {
		region?: GraphQLTypes["Location_region_operator"] | undefined,
	creator?: GraphQLTypes["Location_creator_operator"] | undefined,
	permalink?: GraphQLTypes["Location_permalink_operator"] | undefined,
	status?: GraphQLTypes["Location_status_operator"] | undefined,
	level?: GraphQLTypes["Location_level_operator"] | undefined,
	parent?: GraphQLTypes["Location_parent_operator"] | undefined,
	name?: GraphQLTypes["Location_name_operator"] | undefined,
	thumbnail?: GraphQLTypes["Location_thumbnail_operator"] | undefined,
	banner?: GraphQLTypes["Location_banner_operator"] | undefined,
	type?: GraphQLTypes["Location_type_operator"] | undefined,
	category?: GraphQLTypes["Location_category_operator"] | undefined,
	district?: GraphQLTypes["Location_district_operator"] | undefined,
	coordinate?: GraphQLTypes["Location_coordinate_operator"] | undefined,
	linkAddress?: GraphQLTypes["Location_linkAddress_operator"] | undefined,
	address?: GraphQLTypes["Location_address_operator"] | undefined,
	Details__code?: GraphQLTypes["Location_Details__code_operator"] | undefined,
	Details__phone?: GraphQLTypes["Location_Details__phone_operator"] | undefined,
	Details__page?: GraphQLTypes["Location_Details__page_operator"] | undefined,
	Details__email?: GraphQLTypes["Location_Details__email_operator"] | undefined,
	legacyContent?: GraphQLTypes["Location_legacyContent_operator"] | undefined,
	showLegacyContent?: GraphQLTypes["Location_showLegacyContent_operator"] | undefined,
	criteria__audiences?: GraphQLTypes["Location_criteria__audiences_operator"] | undefined,
	criteria__dates?: GraphQLTypes["Location_criteria__dates_operator"] | undefined,
	criteria__categories?: GraphQLTypes["Location_criteria__categories_operator"] | undefined,
	criteria__tags?: GraphQLTypes["Location_criteria__tags_operator"] | undefined,
	sections__startDatetime?: GraphQLTypes["Location_sections__startDatetime_operator"] | undefined,
	sections__endDatetime?: GraphQLTypes["Location_sections__endDatetime_operator"] | undefined,
	sections__fullDay?: GraphQLTypes["Location_sections__fullDay_operator"] | undefined,
	sections__repeat?: GraphQLTypes["Location_sections__repeat_operator"] | undefined,
	sections__recurrance__type?: GraphQLTypes["Location_sections__recurrance__type_operator"] | undefined,
	sections__recurrance__weekday?: GraphQLTypes["Location_sections__recurrance__weekday_operator"] | undefined,
	sections__id?: GraphQLTypes["Location_sections__id_operator"] | undefined,
	_title?: GraphQLTypes["Location__title_operator"] | undefined,
	legacyGallery?: GraphQLTypes["Location_legacyGallery_operator"] | undefined,
	updatedAt?: GraphQLTypes["Location_updatedAt_operator"] | undefined,
	createdAt?: GraphQLTypes["Location_createdAt_operator"] | undefined,
	id?: GraphQLTypes["Location_id_operator"] | undefined,
	AND?: Array<GraphQLTypes["Location_where_and"] | undefined> | undefined,
	OR?: Array<GraphQLTypes["Location_where_or"] | undefined> | undefined
};
	["countLocations"]: {
	__typename: "countLocations",
	totalDocs?: number | undefined
};
	["locationsDocAccess"]: {
	__typename: "locationsDocAccess",
	fields?: GraphQLTypes["LocationsDocAccessFields"] | undefined,
	create?: GraphQLTypes["LocationsCreateDocAccess"] | undefined,
	read?: GraphQLTypes["LocationsReadDocAccess"] | undefined,
	update?: GraphQLTypes["LocationsUpdateDocAccess"] | undefined,
	delete?: GraphQLTypes["LocationsDeleteDocAccess"] | undefined
};
	["LocationsDocAccessFields"]: {
	__typename: "LocationsDocAccessFields",
	region?: GraphQLTypes["LocationsDocAccessFields_region"] | undefined,
	creator?: GraphQLTypes["LocationsDocAccessFields_creator"] | undefined,
	permalink?: GraphQLTypes["LocationsDocAccessFields_permalink"] | undefined,
	status?: GraphQLTypes["LocationsDocAccessFields_status"] | undefined,
	level?: GraphQLTypes["LocationsDocAccessFields_level"] | undefined,
	parent?: GraphQLTypes["LocationsDocAccessFields_parent"] | undefined,
	name?: GraphQLTypes["LocationsDocAccessFields_name"] | undefined,
	thumbnail?: GraphQLTypes["LocationsDocAccessFields_thumbnail"] | undefined,
	banner?: GraphQLTypes["LocationsDocAccessFields_banner"] | undefined,
	type?: GraphQLTypes["LocationsDocAccessFields_type"] | undefined,
	category?: GraphQLTypes["LocationsDocAccessFields_category"] | undefined,
	district?: GraphQLTypes["LocationsDocAccessFields_district"] | undefined,
	coordinate?: GraphQLTypes["LocationsDocAccessFields_coordinate"] | undefined,
	linkAddress?: GraphQLTypes["LocationsDocAccessFields_linkAddress"] | undefined,
	address?: GraphQLTypes["LocationsDocAccessFields_address"] | undefined,
	Details?: GraphQLTypes["LocationsDocAccessFields_Details"] | undefined,
	content?: GraphQLTypes["LocationsDocAccessFields_content"] | undefined,
	legacyContent?: GraphQLTypes["LocationsDocAccessFields_legacyContent"] | undefined,
	showLegacyContent?: GraphQLTypes["LocationsDocAccessFields_showLegacyContent"] | undefined,
	criteria?: GraphQLTypes["LocationsDocAccessFields_criteria"] | undefined,
	sections?: GraphQLTypes["LocationsDocAccessFields_sections"] | undefined,
	_title?: GraphQLTypes["LocationsDocAccessFields__title"] | undefined,
	legacyGallery?: GraphQLTypes["LocationsDocAccessFields_legacyGallery"] | undefined,
	updatedAt?: GraphQLTypes["LocationsDocAccessFields_updatedAt"] | undefined,
	createdAt?: GraphQLTypes["LocationsDocAccessFields_createdAt"] | undefined
};
	["LocationsDocAccessFields_region"]: {
	__typename: "LocationsDocAccessFields_region",
	create?: GraphQLTypes["LocationsDocAccessFields_region_Create"] | undefined,
	read?: GraphQLTypes["LocationsDocAccessFields_region_Read"] | undefined,
	update?: GraphQLTypes["LocationsDocAccessFields_region_Update"] | undefined,
	delete?: GraphQLTypes["LocationsDocAccessFields_region_Delete"] | undefined
};
	["LocationsDocAccessFields_region_Create"]: {
	__typename: "LocationsDocAccessFields_region_Create",
	permission: boolean
};
	["LocationsDocAccessFields_region_Read"]: {
	__typename: "LocationsDocAccessFields_region_Read",
	permission: boolean
};
	["LocationsDocAccessFields_region_Update"]: {
	__typename: "LocationsDocAccessFields_region_Update",
	permission: boolean
};
	["LocationsDocAccessFields_region_Delete"]: {
	__typename: "LocationsDocAccessFields_region_Delete",
	permission: boolean
};
	["LocationsDocAccessFields_creator"]: {
	__typename: "LocationsDocAccessFields_creator",
	create?: GraphQLTypes["LocationsDocAccessFields_creator_Create"] | undefined,
	read?: GraphQLTypes["LocationsDocAccessFields_creator_Read"] | undefined,
	update?: GraphQLTypes["LocationsDocAccessFields_creator_Update"] | undefined,
	delete?: GraphQLTypes["LocationsDocAccessFields_creator_Delete"] | undefined
};
	["LocationsDocAccessFields_creator_Create"]: {
	__typename: "LocationsDocAccessFields_creator_Create",
	permission: boolean
};
	["LocationsDocAccessFields_creator_Read"]: {
	__typename: "LocationsDocAccessFields_creator_Read",
	permission: boolean
};
	["LocationsDocAccessFields_creator_Update"]: {
	__typename: "LocationsDocAccessFields_creator_Update",
	permission: boolean
};
	["LocationsDocAccessFields_creator_Delete"]: {
	__typename: "LocationsDocAccessFields_creator_Delete",
	permission: boolean
};
	["LocationsDocAccessFields_permalink"]: {
	__typename: "LocationsDocAccessFields_permalink",
	create?: GraphQLTypes["LocationsDocAccessFields_permalink_Create"] | undefined,
	read?: GraphQLTypes["LocationsDocAccessFields_permalink_Read"] | undefined,
	update?: GraphQLTypes["LocationsDocAccessFields_permalink_Update"] | undefined,
	delete?: GraphQLTypes["LocationsDocAccessFields_permalink_Delete"] | undefined
};
	["LocationsDocAccessFields_permalink_Create"]: {
	__typename: "LocationsDocAccessFields_permalink_Create",
	permission: boolean
};
	["LocationsDocAccessFields_permalink_Read"]: {
	__typename: "LocationsDocAccessFields_permalink_Read",
	permission: boolean
};
	["LocationsDocAccessFields_permalink_Update"]: {
	__typename: "LocationsDocAccessFields_permalink_Update",
	permission: boolean
};
	["LocationsDocAccessFields_permalink_Delete"]: {
	__typename: "LocationsDocAccessFields_permalink_Delete",
	permission: boolean
};
	["LocationsDocAccessFields_status"]: {
	__typename: "LocationsDocAccessFields_status",
	create?: GraphQLTypes["LocationsDocAccessFields_status_Create"] | undefined,
	read?: GraphQLTypes["LocationsDocAccessFields_status_Read"] | undefined,
	update?: GraphQLTypes["LocationsDocAccessFields_status_Update"] | undefined,
	delete?: GraphQLTypes["LocationsDocAccessFields_status_Delete"] | undefined
};
	["LocationsDocAccessFields_status_Create"]: {
	__typename: "LocationsDocAccessFields_status_Create",
	permission: boolean
};
	["LocationsDocAccessFields_status_Read"]: {
	__typename: "LocationsDocAccessFields_status_Read",
	permission: boolean
};
	["LocationsDocAccessFields_status_Update"]: {
	__typename: "LocationsDocAccessFields_status_Update",
	permission: boolean
};
	["LocationsDocAccessFields_status_Delete"]: {
	__typename: "LocationsDocAccessFields_status_Delete",
	permission: boolean
};
	["LocationsDocAccessFields_level"]: {
	__typename: "LocationsDocAccessFields_level",
	create?: GraphQLTypes["LocationsDocAccessFields_level_Create"] | undefined,
	read?: GraphQLTypes["LocationsDocAccessFields_level_Read"] | undefined,
	update?: GraphQLTypes["LocationsDocAccessFields_level_Update"] | undefined,
	delete?: GraphQLTypes["LocationsDocAccessFields_level_Delete"] | undefined
};
	["LocationsDocAccessFields_level_Create"]: {
	__typename: "LocationsDocAccessFields_level_Create",
	permission: boolean
};
	["LocationsDocAccessFields_level_Read"]: {
	__typename: "LocationsDocAccessFields_level_Read",
	permission: boolean
};
	["LocationsDocAccessFields_level_Update"]: {
	__typename: "LocationsDocAccessFields_level_Update",
	permission: boolean
};
	["LocationsDocAccessFields_level_Delete"]: {
	__typename: "LocationsDocAccessFields_level_Delete",
	permission: boolean
};
	["LocationsDocAccessFields_parent"]: {
	__typename: "LocationsDocAccessFields_parent",
	create?: GraphQLTypes["LocationsDocAccessFields_parent_Create"] | undefined,
	read?: GraphQLTypes["LocationsDocAccessFields_parent_Read"] | undefined,
	update?: GraphQLTypes["LocationsDocAccessFields_parent_Update"] | undefined,
	delete?: GraphQLTypes["LocationsDocAccessFields_parent_Delete"] | undefined
};
	["LocationsDocAccessFields_parent_Create"]: {
	__typename: "LocationsDocAccessFields_parent_Create",
	permission: boolean
};
	["LocationsDocAccessFields_parent_Read"]: {
	__typename: "LocationsDocAccessFields_parent_Read",
	permission: boolean
};
	["LocationsDocAccessFields_parent_Update"]: {
	__typename: "LocationsDocAccessFields_parent_Update",
	permission: boolean
};
	["LocationsDocAccessFields_parent_Delete"]: {
	__typename: "LocationsDocAccessFields_parent_Delete",
	permission: boolean
};
	["LocationsDocAccessFields_name"]: {
	__typename: "LocationsDocAccessFields_name",
	create?: GraphQLTypes["LocationsDocAccessFields_name_Create"] | undefined,
	read?: GraphQLTypes["LocationsDocAccessFields_name_Read"] | undefined,
	update?: GraphQLTypes["LocationsDocAccessFields_name_Update"] | undefined,
	delete?: GraphQLTypes["LocationsDocAccessFields_name_Delete"] | undefined
};
	["LocationsDocAccessFields_name_Create"]: {
	__typename: "LocationsDocAccessFields_name_Create",
	permission: boolean
};
	["LocationsDocAccessFields_name_Read"]: {
	__typename: "LocationsDocAccessFields_name_Read",
	permission: boolean
};
	["LocationsDocAccessFields_name_Update"]: {
	__typename: "LocationsDocAccessFields_name_Update",
	permission: boolean
};
	["LocationsDocAccessFields_name_Delete"]: {
	__typename: "LocationsDocAccessFields_name_Delete",
	permission: boolean
};
	["LocationsDocAccessFields_thumbnail"]: {
	__typename: "LocationsDocAccessFields_thumbnail",
	create?: GraphQLTypes["LocationsDocAccessFields_thumbnail_Create"] | undefined,
	read?: GraphQLTypes["LocationsDocAccessFields_thumbnail_Read"] | undefined,
	update?: GraphQLTypes["LocationsDocAccessFields_thumbnail_Update"] | undefined,
	delete?: GraphQLTypes["LocationsDocAccessFields_thumbnail_Delete"] | undefined
};
	["LocationsDocAccessFields_thumbnail_Create"]: {
	__typename: "LocationsDocAccessFields_thumbnail_Create",
	permission: boolean
};
	["LocationsDocAccessFields_thumbnail_Read"]: {
	__typename: "LocationsDocAccessFields_thumbnail_Read",
	permission: boolean
};
	["LocationsDocAccessFields_thumbnail_Update"]: {
	__typename: "LocationsDocAccessFields_thumbnail_Update",
	permission: boolean
};
	["LocationsDocAccessFields_thumbnail_Delete"]: {
	__typename: "LocationsDocAccessFields_thumbnail_Delete",
	permission: boolean
};
	["LocationsDocAccessFields_banner"]: {
	__typename: "LocationsDocAccessFields_banner",
	create?: GraphQLTypes["LocationsDocAccessFields_banner_Create"] | undefined,
	read?: GraphQLTypes["LocationsDocAccessFields_banner_Read"] | undefined,
	update?: GraphQLTypes["LocationsDocAccessFields_banner_Update"] | undefined,
	delete?: GraphQLTypes["LocationsDocAccessFields_banner_Delete"] | undefined
};
	["LocationsDocAccessFields_banner_Create"]: {
	__typename: "LocationsDocAccessFields_banner_Create",
	permission: boolean
};
	["LocationsDocAccessFields_banner_Read"]: {
	__typename: "LocationsDocAccessFields_banner_Read",
	permission: boolean
};
	["LocationsDocAccessFields_banner_Update"]: {
	__typename: "LocationsDocAccessFields_banner_Update",
	permission: boolean
};
	["LocationsDocAccessFields_banner_Delete"]: {
	__typename: "LocationsDocAccessFields_banner_Delete",
	permission: boolean
};
	["LocationsDocAccessFields_type"]: {
	__typename: "LocationsDocAccessFields_type",
	create?: GraphQLTypes["LocationsDocAccessFields_type_Create"] | undefined,
	read?: GraphQLTypes["LocationsDocAccessFields_type_Read"] | undefined,
	update?: GraphQLTypes["LocationsDocAccessFields_type_Update"] | undefined,
	delete?: GraphQLTypes["LocationsDocAccessFields_type_Delete"] | undefined
};
	["LocationsDocAccessFields_type_Create"]: {
	__typename: "LocationsDocAccessFields_type_Create",
	permission: boolean
};
	["LocationsDocAccessFields_type_Read"]: {
	__typename: "LocationsDocAccessFields_type_Read",
	permission: boolean
};
	["LocationsDocAccessFields_type_Update"]: {
	__typename: "LocationsDocAccessFields_type_Update",
	permission: boolean
};
	["LocationsDocAccessFields_type_Delete"]: {
	__typename: "LocationsDocAccessFields_type_Delete",
	permission: boolean
};
	["LocationsDocAccessFields_category"]: {
	__typename: "LocationsDocAccessFields_category",
	create?: GraphQLTypes["LocationsDocAccessFields_category_Create"] | undefined,
	read?: GraphQLTypes["LocationsDocAccessFields_category_Read"] | undefined,
	update?: GraphQLTypes["LocationsDocAccessFields_category_Update"] | undefined,
	delete?: GraphQLTypes["LocationsDocAccessFields_category_Delete"] | undefined
};
	["LocationsDocAccessFields_category_Create"]: {
	__typename: "LocationsDocAccessFields_category_Create",
	permission: boolean
};
	["LocationsDocAccessFields_category_Read"]: {
	__typename: "LocationsDocAccessFields_category_Read",
	permission: boolean
};
	["LocationsDocAccessFields_category_Update"]: {
	__typename: "LocationsDocAccessFields_category_Update",
	permission: boolean
};
	["LocationsDocAccessFields_category_Delete"]: {
	__typename: "LocationsDocAccessFields_category_Delete",
	permission: boolean
};
	["LocationsDocAccessFields_district"]: {
	__typename: "LocationsDocAccessFields_district",
	create?: GraphQLTypes["LocationsDocAccessFields_district_Create"] | undefined,
	read?: GraphQLTypes["LocationsDocAccessFields_district_Read"] | undefined,
	update?: GraphQLTypes["LocationsDocAccessFields_district_Update"] | undefined,
	delete?: GraphQLTypes["LocationsDocAccessFields_district_Delete"] | undefined
};
	["LocationsDocAccessFields_district_Create"]: {
	__typename: "LocationsDocAccessFields_district_Create",
	permission: boolean
};
	["LocationsDocAccessFields_district_Read"]: {
	__typename: "LocationsDocAccessFields_district_Read",
	permission: boolean
};
	["LocationsDocAccessFields_district_Update"]: {
	__typename: "LocationsDocAccessFields_district_Update",
	permission: boolean
};
	["LocationsDocAccessFields_district_Delete"]: {
	__typename: "LocationsDocAccessFields_district_Delete",
	permission: boolean
};
	["LocationsDocAccessFields_coordinate"]: {
	__typename: "LocationsDocAccessFields_coordinate",
	create?: GraphQLTypes["LocationsDocAccessFields_coordinate_Create"] | undefined,
	read?: GraphQLTypes["LocationsDocAccessFields_coordinate_Read"] | undefined,
	update?: GraphQLTypes["LocationsDocAccessFields_coordinate_Update"] | undefined,
	delete?: GraphQLTypes["LocationsDocAccessFields_coordinate_Delete"] | undefined
};
	["LocationsDocAccessFields_coordinate_Create"]: {
	__typename: "LocationsDocAccessFields_coordinate_Create",
	permission: boolean
};
	["LocationsDocAccessFields_coordinate_Read"]: {
	__typename: "LocationsDocAccessFields_coordinate_Read",
	permission: boolean
};
	["LocationsDocAccessFields_coordinate_Update"]: {
	__typename: "LocationsDocAccessFields_coordinate_Update",
	permission: boolean
};
	["LocationsDocAccessFields_coordinate_Delete"]: {
	__typename: "LocationsDocAccessFields_coordinate_Delete",
	permission: boolean
};
	["LocationsDocAccessFields_linkAddress"]: {
	__typename: "LocationsDocAccessFields_linkAddress",
	create?: GraphQLTypes["LocationsDocAccessFields_linkAddress_Create"] | undefined,
	read?: GraphQLTypes["LocationsDocAccessFields_linkAddress_Read"] | undefined,
	update?: GraphQLTypes["LocationsDocAccessFields_linkAddress_Update"] | undefined,
	delete?: GraphQLTypes["LocationsDocAccessFields_linkAddress_Delete"] | undefined
};
	["LocationsDocAccessFields_linkAddress_Create"]: {
	__typename: "LocationsDocAccessFields_linkAddress_Create",
	permission: boolean
};
	["LocationsDocAccessFields_linkAddress_Read"]: {
	__typename: "LocationsDocAccessFields_linkAddress_Read",
	permission: boolean
};
	["LocationsDocAccessFields_linkAddress_Update"]: {
	__typename: "LocationsDocAccessFields_linkAddress_Update",
	permission: boolean
};
	["LocationsDocAccessFields_linkAddress_Delete"]: {
	__typename: "LocationsDocAccessFields_linkAddress_Delete",
	permission: boolean
};
	["LocationsDocAccessFields_address"]: {
	__typename: "LocationsDocAccessFields_address",
	create?: GraphQLTypes["LocationsDocAccessFields_address_Create"] | undefined,
	read?: GraphQLTypes["LocationsDocAccessFields_address_Read"] | undefined,
	update?: GraphQLTypes["LocationsDocAccessFields_address_Update"] | undefined,
	delete?: GraphQLTypes["LocationsDocAccessFields_address_Delete"] | undefined
};
	["LocationsDocAccessFields_address_Create"]: {
	__typename: "LocationsDocAccessFields_address_Create",
	permission: boolean
};
	["LocationsDocAccessFields_address_Read"]: {
	__typename: "LocationsDocAccessFields_address_Read",
	permission: boolean
};
	["LocationsDocAccessFields_address_Update"]: {
	__typename: "LocationsDocAccessFields_address_Update",
	permission: boolean
};
	["LocationsDocAccessFields_address_Delete"]: {
	__typename: "LocationsDocAccessFields_address_Delete",
	permission: boolean
};
	["LocationsDocAccessFields_Details"]: {
	__typename: "LocationsDocAccessFields_Details",
	create?: GraphQLTypes["LocationsDocAccessFields_Details_Create"] | undefined,
	read?: GraphQLTypes["LocationsDocAccessFields_Details_Read"] | undefined,
	update?: GraphQLTypes["LocationsDocAccessFields_Details_Update"] | undefined,
	delete?: GraphQLTypes["LocationsDocAccessFields_Details_Delete"] | undefined,
	fields?: GraphQLTypes["LocationsDocAccessFields_Details_Fields"] | undefined
};
	["LocationsDocAccessFields_Details_Create"]: {
	__typename: "LocationsDocAccessFields_Details_Create",
	permission: boolean
};
	["LocationsDocAccessFields_Details_Read"]: {
	__typename: "LocationsDocAccessFields_Details_Read",
	permission: boolean
};
	["LocationsDocAccessFields_Details_Update"]: {
	__typename: "LocationsDocAccessFields_Details_Update",
	permission: boolean
};
	["LocationsDocAccessFields_Details_Delete"]: {
	__typename: "LocationsDocAccessFields_Details_Delete",
	permission: boolean
};
	["LocationsDocAccessFields_Details_Fields"]: {
	__typename: "LocationsDocAccessFields_Details_Fields",
	code?: GraphQLTypes["LocationsDocAccessFields_Details_code"] | undefined,
	phone?: GraphQLTypes["LocationsDocAccessFields_Details_phone"] | undefined,
	page?: GraphQLTypes["LocationsDocAccessFields_Details_page"] | undefined,
	email?: GraphQLTypes["LocationsDocAccessFields_Details_email"] | undefined
};
	["LocationsDocAccessFields_Details_code"]: {
	__typename: "LocationsDocAccessFields_Details_code",
	create?: GraphQLTypes["LocationsDocAccessFields_Details_code_Create"] | undefined,
	read?: GraphQLTypes["LocationsDocAccessFields_Details_code_Read"] | undefined,
	update?: GraphQLTypes["LocationsDocAccessFields_Details_code_Update"] | undefined,
	delete?: GraphQLTypes["LocationsDocAccessFields_Details_code_Delete"] | undefined
};
	["LocationsDocAccessFields_Details_code_Create"]: {
	__typename: "LocationsDocAccessFields_Details_code_Create",
	permission: boolean
};
	["LocationsDocAccessFields_Details_code_Read"]: {
	__typename: "LocationsDocAccessFields_Details_code_Read",
	permission: boolean
};
	["LocationsDocAccessFields_Details_code_Update"]: {
	__typename: "LocationsDocAccessFields_Details_code_Update",
	permission: boolean
};
	["LocationsDocAccessFields_Details_code_Delete"]: {
	__typename: "LocationsDocAccessFields_Details_code_Delete",
	permission: boolean
};
	["LocationsDocAccessFields_Details_phone"]: {
	__typename: "LocationsDocAccessFields_Details_phone",
	create?: GraphQLTypes["LocationsDocAccessFields_Details_phone_Create"] | undefined,
	read?: GraphQLTypes["LocationsDocAccessFields_Details_phone_Read"] | undefined,
	update?: GraphQLTypes["LocationsDocAccessFields_Details_phone_Update"] | undefined,
	delete?: GraphQLTypes["LocationsDocAccessFields_Details_phone_Delete"] | undefined
};
	["LocationsDocAccessFields_Details_phone_Create"]: {
	__typename: "LocationsDocAccessFields_Details_phone_Create",
	permission: boolean
};
	["LocationsDocAccessFields_Details_phone_Read"]: {
	__typename: "LocationsDocAccessFields_Details_phone_Read",
	permission: boolean
};
	["LocationsDocAccessFields_Details_phone_Update"]: {
	__typename: "LocationsDocAccessFields_Details_phone_Update",
	permission: boolean
};
	["LocationsDocAccessFields_Details_phone_Delete"]: {
	__typename: "LocationsDocAccessFields_Details_phone_Delete",
	permission: boolean
};
	["LocationsDocAccessFields_Details_page"]: {
	__typename: "LocationsDocAccessFields_Details_page",
	create?: GraphQLTypes["LocationsDocAccessFields_Details_page_Create"] | undefined,
	read?: GraphQLTypes["LocationsDocAccessFields_Details_page_Read"] | undefined,
	update?: GraphQLTypes["LocationsDocAccessFields_Details_page_Update"] | undefined,
	delete?: GraphQLTypes["LocationsDocAccessFields_Details_page_Delete"] | undefined
};
	["LocationsDocAccessFields_Details_page_Create"]: {
	__typename: "LocationsDocAccessFields_Details_page_Create",
	permission: boolean
};
	["LocationsDocAccessFields_Details_page_Read"]: {
	__typename: "LocationsDocAccessFields_Details_page_Read",
	permission: boolean
};
	["LocationsDocAccessFields_Details_page_Update"]: {
	__typename: "LocationsDocAccessFields_Details_page_Update",
	permission: boolean
};
	["LocationsDocAccessFields_Details_page_Delete"]: {
	__typename: "LocationsDocAccessFields_Details_page_Delete",
	permission: boolean
};
	["LocationsDocAccessFields_Details_email"]: {
	__typename: "LocationsDocAccessFields_Details_email",
	create?: GraphQLTypes["LocationsDocAccessFields_Details_email_Create"] | undefined,
	read?: GraphQLTypes["LocationsDocAccessFields_Details_email_Read"] | undefined,
	update?: GraphQLTypes["LocationsDocAccessFields_Details_email_Update"] | undefined,
	delete?: GraphQLTypes["LocationsDocAccessFields_Details_email_Delete"] | undefined
};
	["LocationsDocAccessFields_Details_email_Create"]: {
	__typename: "LocationsDocAccessFields_Details_email_Create",
	permission: boolean
};
	["LocationsDocAccessFields_Details_email_Read"]: {
	__typename: "LocationsDocAccessFields_Details_email_Read",
	permission: boolean
};
	["LocationsDocAccessFields_Details_email_Update"]: {
	__typename: "LocationsDocAccessFields_Details_email_Update",
	permission: boolean
};
	["LocationsDocAccessFields_Details_email_Delete"]: {
	__typename: "LocationsDocAccessFields_Details_email_Delete",
	permission: boolean
};
	["LocationsDocAccessFields_content"]: {
	__typename: "LocationsDocAccessFields_content",
	create?: GraphQLTypes["LocationsDocAccessFields_content_Create"] | undefined,
	read?: GraphQLTypes["LocationsDocAccessFields_content_Read"] | undefined,
	update?: GraphQLTypes["LocationsDocAccessFields_content_Update"] | undefined,
	delete?: GraphQLTypes["LocationsDocAccessFields_content_Delete"] | undefined
};
	["LocationsDocAccessFields_content_Create"]: {
	__typename: "LocationsDocAccessFields_content_Create",
	permission: boolean
};
	["LocationsDocAccessFields_content_Read"]: {
	__typename: "LocationsDocAccessFields_content_Read",
	permission: boolean
};
	["LocationsDocAccessFields_content_Update"]: {
	__typename: "LocationsDocAccessFields_content_Update",
	permission: boolean
};
	["LocationsDocAccessFields_content_Delete"]: {
	__typename: "LocationsDocAccessFields_content_Delete",
	permission: boolean
};
	["LocationsDocAccessFields_legacyContent"]: {
	__typename: "LocationsDocAccessFields_legacyContent",
	create?: GraphQLTypes["LocationsDocAccessFields_legacyContent_Create"] | undefined,
	read?: GraphQLTypes["LocationsDocAccessFields_legacyContent_Read"] | undefined,
	update?: GraphQLTypes["LocationsDocAccessFields_legacyContent_Update"] | undefined,
	delete?: GraphQLTypes["LocationsDocAccessFields_legacyContent_Delete"] | undefined
};
	["LocationsDocAccessFields_legacyContent_Create"]: {
	__typename: "LocationsDocAccessFields_legacyContent_Create",
	permission: boolean
};
	["LocationsDocAccessFields_legacyContent_Read"]: {
	__typename: "LocationsDocAccessFields_legacyContent_Read",
	permission: boolean
};
	["LocationsDocAccessFields_legacyContent_Update"]: {
	__typename: "LocationsDocAccessFields_legacyContent_Update",
	permission: boolean
};
	["LocationsDocAccessFields_legacyContent_Delete"]: {
	__typename: "LocationsDocAccessFields_legacyContent_Delete",
	permission: boolean
};
	["LocationsDocAccessFields_showLegacyContent"]: {
	__typename: "LocationsDocAccessFields_showLegacyContent",
	create?: GraphQLTypes["LocationsDocAccessFields_showLegacyContent_Create"] | undefined,
	read?: GraphQLTypes["LocationsDocAccessFields_showLegacyContent_Read"] | undefined,
	update?: GraphQLTypes["LocationsDocAccessFields_showLegacyContent_Update"] | undefined,
	delete?: GraphQLTypes["LocationsDocAccessFields_showLegacyContent_Delete"] | undefined
};
	["LocationsDocAccessFields_showLegacyContent_Create"]: {
	__typename: "LocationsDocAccessFields_showLegacyContent_Create",
	permission: boolean
};
	["LocationsDocAccessFields_showLegacyContent_Read"]: {
	__typename: "LocationsDocAccessFields_showLegacyContent_Read",
	permission: boolean
};
	["LocationsDocAccessFields_showLegacyContent_Update"]: {
	__typename: "LocationsDocAccessFields_showLegacyContent_Update",
	permission: boolean
};
	["LocationsDocAccessFields_showLegacyContent_Delete"]: {
	__typename: "LocationsDocAccessFields_showLegacyContent_Delete",
	permission: boolean
};
	["LocationsDocAccessFields_criteria"]: {
	__typename: "LocationsDocAccessFields_criteria",
	create?: GraphQLTypes["LocationsDocAccessFields_criteria_Create"] | undefined,
	read?: GraphQLTypes["LocationsDocAccessFields_criteria_Read"] | undefined,
	update?: GraphQLTypes["LocationsDocAccessFields_criteria_Update"] | undefined,
	delete?: GraphQLTypes["LocationsDocAccessFields_criteria_Delete"] | undefined,
	fields?: GraphQLTypes["LocationsDocAccessFields_criteria_Fields"] | undefined
};
	["LocationsDocAccessFields_criteria_Create"]: {
	__typename: "LocationsDocAccessFields_criteria_Create",
	permission: boolean
};
	["LocationsDocAccessFields_criteria_Read"]: {
	__typename: "LocationsDocAccessFields_criteria_Read",
	permission: boolean
};
	["LocationsDocAccessFields_criteria_Update"]: {
	__typename: "LocationsDocAccessFields_criteria_Update",
	permission: boolean
};
	["LocationsDocAccessFields_criteria_Delete"]: {
	__typename: "LocationsDocAccessFields_criteria_Delete",
	permission: boolean
};
	["LocationsDocAccessFields_criteria_Fields"]: {
	__typename: "LocationsDocAccessFields_criteria_Fields",
	audiences?: GraphQLTypes["LocationsDocAccessFields_criteria_audiences"] | undefined,
	dates?: GraphQLTypes["LocationsDocAccessFields_criteria_dates"] | undefined,
	categories?: GraphQLTypes["LocationsDocAccessFields_criteria_categories"] | undefined,
	tags?: GraphQLTypes["LocationsDocAccessFields_criteria_tags"] | undefined
};
	["LocationsDocAccessFields_criteria_audiences"]: {
	__typename: "LocationsDocAccessFields_criteria_audiences",
	create?: GraphQLTypes["LocationsDocAccessFields_criteria_audiences_Create"] | undefined,
	read?: GraphQLTypes["LocationsDocAccessFields_criteria_audiences_Read"] | undefined,
	update?: GraphQLTypes["LocationsDocAccessFields_criteria_audiences_Update"] | undefined,
	delete?: GraphQLTypes["LocationsDocAccessFields_criteria_audiences_Delete"] | undefined
};
	["LocationsDocAccessFields_criteria_audiences_Create"]: {
	__typename: "LocationsDocAccessFields_criteria_audiences_Create",
	permission: boolean
};
	["LocationsDocAccessFields_criteria_audiences_Read"]: {
	__typename: "LocationsDocAccessFields_criteria_audiences_Read",
	permission: boolean
};
	["LocationsDocAccessFields_criteria_audiences_Update"]: {
	__typename: "LocationsDocAccessFields_criteria_audiences_Update",
	permission: boolean
};
	["LocationsDocAccessFields_criteria_audiences_Delete"]: {
	__typename: "LocationsDocAccessFields_criteria_audiences_Delete",
	permission: boolean
};
	["LocationsDocAccessFields_criteria_dates"]: {
	__typename: "LocationsDocAccessFields_criteria_dates",
	create?: GraphQLTypes["LocationsDocAccessFields_criteria_dates_Create"] | undefined,
	read?: GraphQLTypes["LocationsDocAccessFields_criteria_dates_Read"] | undefined,
	update?: GraphQLTypes["LocationsDocAccessFields_criteria_dates_Update"] | undefined,
	delete?: GraphQLTypes["LocationsDocAccessFields_criteria_dates_Delete"] | undefined
};
	["LocationsDocAccessFields_criteria_dates_Create"]: {
	__typename: "LocationsDocAccessFields_criteria_dates_Create",
	permission: boolean
};
	["LocationsDocAccessFields_criteria_dates_Read"]: {
	__typename: "LocationsDocAccessFields_criteria_dates_Read",
	permission: boolean
};
	["LocationsDocAccessFields_criteria_dates_Update"]: {
	__typename: "LocationsDocAccessFields_criteria_dates_Update",
	permission: boolean
};
	["LocationsDocAccessFields_criteria_dates_Delete"]: {
	__typename: "LocationsDocAccessFields_criteria_dates_Delete",
	permission: boolean
};
	["LocationsDocAccessFields_criteria_categories"]: {
	__typename: "LocationsDocAccessFields_criteria_categories",
	create?: GraphQLTypes["LocationsDocAccessFields_criteria_categories_Create"] | undefined,
	read?: GraphQLTypes["LocationsDocAccessFields_criteria_categories_Read"] | undefined,
	update?: GraphQLTypes["LocationsDocAccessFields_criteria_categories_Update"] | undefined,
	delete?: GraphQLTypes["LocationsDocAccessFields_criteria_categories_Delete"] | undefined
};
	["LocationsDocAccessFields_criteria_categories_Create"]: {
	__typename: "LocationsDocAccessFields_criteria_categories_Create",
	permission: boolean
};
	["LocationsDocAccessFields_criteria_categories_Read"]: {
	__typename: "LocationsDocAccessFields_criteria_categories_Read",
	permission: boolean
};
	["LocationsDocAccessFields_criteria_categories_Update"]: {
	__typename: "LocationsDocAccessFields_criteria_categories_Update",
	permission: boolean
};
	["LocationsDocAccessFields_criteria_categories_Delete"]: {
	__typename: "LocationsDocAccessFields_criteria_categories_Delete",
	permission: boolean
};
	["LocationsDocAccessFields_criteria_tags"]: {
	__typename: "LocationsDocAccessFields_criteria_tags",
	create?: GraphQLTypes["LocationsDocAccessFields_criteria_tags_Create"] | undefined,
	read?: GraphQLTypes["LocationsDocAccessFields_criteria_tags_Read"] | undefined,
	update?: GraphQLTypes["LocationsDocAccessFields_criteria_tags_Update"] | undefined,
	delete?: GraphQLTypes["LocationsDocAccessFields_criteria_tags_Delete"] | undefined
};
	["LocationsDocAccessFields_criteria_tags_Create"]: {
	__typename: "LocationsDocAccessFields_criteria_tags_Create",
	permission: boolean
};
	["LocationsDocAccessFields_criteria_tags_Read"]: {
	__typename: "LocationsDocAccessFields_criteria_tags_Read",
	permission: boolean
};
	["LocationsDocAccessFields_criteria_tags_Update"]: {
	__typename: "LocationsDocAccessFields_criteria_tags_Update",
	permission: boolean
};
	["LocationsDocAccessFields_criteria_tags_Delete"]: {
	__typename: "LocationsDocAccessFields_criteria_tags_Delete",
	permission: boolean
};
	["LocationsDocAccessFields_sections"]: {
	__typename: "LocationsDocAccessFields_sections",
	create?: GraphQLTypes["LocationsDocAccessFields_sections_Create"] | undefined,
	read?: GraphQLTypes["LocationsDocAccessFields_sections_Read"] | undefined,
	update?: GraphQLTypes["LocationsDocAccessFields_sections_Update"] | undefined,
	delete?: GraphQLTypes["LocationsDocAccessFields_sections_Delete"] | undefined,
	fields?: GraphQLTypes["LocationsDocAccessFields_sections_Fields"] | undefined
};
	["LocationsDocAccessFields_sections_Create"]: {
	__typename: "LocationsDocAccessFields_sections_Create",
	permission: boolean
};
	["LocationsDocAccessFields_sections_Read"]: {
	__typename: "LocationsDocAccessFields_sections_Read",
	permission: boolean
};
	["LocationsDocAccessFields_sections_Update"]: {
	__typename: "LocationsDocAccessFields_sections_Update",
	permission: boolean
};
	["LocationsDocAccessFields_sections_Delete"]: {
	__typename: "LocationsDocAccessFields_sections_Delete",
	permission: boolean
};
	["LocationsDocAccessFields_sections_Fields"]: {
	__typename: "LocationsDocAccessFields_sections_Fields",
	startDatetime?: GraphQLTypes["LocationsDocAccessFields_sections_startDatetime"] | undefined,
	endDatetime?: GraphQLTypes["LocationsDocAccessFields_sections_endDatetime"] | undefined,
	fullDay?: GraphQLTypes["LocationsDocAccessFields_sections_fullDay"] | undefined,
	repeat?: GraphQLTypes["LocationsDocAccessFields_sections_repeat"] | undefined,
	recurrance?: GraphQLTypes["LocationsDocAccessFields_sections_recurrance"] | undefined,
	id?: GraphQLTypes["LocationsDocAccessFields_sections_id"] | undefined
};
	["LocationsDocAccessFields_sections_startDatetime"]: {
	__typename: "LocationsDocAccessFields_sections_startDatetime",
	create?: GraphQLTypes["LocationsDocAccessFields_sections_startDatetime_Create"] | undefined,
	read?: GraphQLTypes["LocationsDocAccessFields_sections_startDatetime_Read"] | undefined,
	update?: GraphQLTypes["LocationsDocAccessFields_sections_startDatetime_Update"] | undefined,
	delete?: GraphQLTypes["LocationsDocAccessFields_sections_startDatetime_Delete"] | undefined
};
	["LocationsDocAccessFields_sections_startDatetime_Create"]: {
	__typename: "LocationsDocAccessFields_sections_startDatetime_Create",
	permission: boolean
};
	["LocationsDocAccessFields_sections_startDatetime_Read"]: {
	__typename: "LocationsDocAccessFields_sections_startDatetime_Read",
	permission: boolean
};
	["LocationsDocAccessFields_sections_startDatetime_Update"]: {
	__typename: "LocationsDocAccessFields_sections_startDatetime_Update",
	permission: boolean
};
	["LocationsDocAccessFields_sections_startDatetime_Delete"]: {
	__typename: "LocationsDocAccessFields_sections_startDatetime_Delete",
	permission: boolean
};
	["LocationsDocAccessFields_sections_endDatetime"]: {
	__typename: "LocationsDocAccessFields_sections_endDatetime",
	create?: GraphQLTypes["LocationsDocAccessFields_sections_endDatetime_Create"] | undefined,
	read?: GraphQLTypes["LocationsDocAccessFields_sections_endDatetime_Read"] | undefined,
	update?: GraphQLTypes["LocationsDocAccessFields_sections_endDatetime_Update"] | undefined,
	delete?: GraphQLTypes["LocationsDocAccessFields_sections_endDatetime_Delete"] | undefined
};
	["LocationsDocAccessFields_sections_endDatetime_Create"]: {
	__typename: "LocationsDocAccessFields_sections_endDatetime_Create",
	permission: boolean
};
	["LocationsDocAccessFields_sections_endDatetime_Read"]: {
	__typename: "LocationsDocAccessFields_sections_endDatetime_Read",
	permission: boolean
};
	["LocationsDocAccessFields_sections_endDatetime_Update"]: {
	__typename: "LocationsDocAccessFields_sections_endDatetime_Update",
	permission: boolean
};
	["LocationsDocAccessFields_sections_endDatetime_Delete"]: {
	__typename: "LocationsDocAccessFields_sections_endDatetime_Delete",
	permission: boolean
};
	["LocationsDocAccessFields_sections_fullDay"]: {
	__typename: "LocationsDocAccessFields_sections_fullDay",
	create?: GraphQLTypes["LocationsDocAccessFields_sections_fullDay_Create"] | undefined,
	read?: GraphQLTypes["LocationsDocAccessFields_sections_fullDay_Read"] | undefined,
	update?: GraphQLTypes["LocationsDocAccessFields_sections_fullDay_Update"] | undefined,
	delete?: GraphQLTypes["LocationsDocAccessFields_sections_fullDay_Delete"] | undefined
};
	["LocationsDocAccessFields_sections_fullDay_Create"]: {
	__typename: "LocationsDocAccessFields_sections_fullDay_Create",
	permission: boolean
};
	["LocationsDocAccessFields_sections_fullDay_Read"]: {
	__typename: "LocationsDocAccessFields_sections_fullDay_Read",
	permission: boolean
};
	["LocationsDocAccessFields_sections_fullDay_Update"]: {
	__typename: "LocationsDocAccessFields_sections_fullDay_Update",
	permission: boolean
};
	["LocationsDocAccessFields_sections_fullDay_Delete"]: {
	__typename: "LocationsDocAccessFields_sections_fullDay_Delete",
	permission: boolean
};
	["LocationsDocAccessFields_sections_repeat"]: {
	__typename: "LocationsDocAccessFields_sections_repeat",
	create?: GraphQLTypes["LocationsDocAccessFields_sections_repeat_Create"] | undefined,
	read?: GraphQLTypes["LocationsDocAccessFields_sections_repeat_Read"] | undefined,
	update?: GraphQLTypes["LocationsDocAccessFields_sections_repeat_Update"] | undefined,
	delete?: GraphQLTypes["LocationsDocAccessFields_sections_repeat_Delete"] | undefined
};
	["LocationsDocAccessFields_sections_repeat_Create"]: {
	__typename: "LocationsDocAccessFields_sections_repeat_Create",
	permission: boolean
};
	["LocationsDocAccessFields_sections_repeat_Read"]: {
	__typename: "LocationsDocAccessFields_sections_repeat_Read",
	permission: boolean
};
	["LocationsDocAccessFields_sections_repeat_Update"]: {
	__typename: "LocationsDocAccessFields_sections_repeat_Update",
	permission: boolean
};
	["LocationsDocAccessFields_sections_repeat_Delete"]: {
	__typename: "LocationsDocAccessFields_sections_repeat_Delete",
	permission: boolean
};
	["LocationsDocAccessFields_sections_recurrance"]: {
	__typename: "LocationsDocAccessFields_sections_recurrance",
	create?: GraphQLTypes["LocationsDocAccessFields_sections_recurrance_Create"] | undefined,
	read?: GraphQLTypes["LocationsDocAccessFields_sections_recurrance_Read"] | undefined,
	update?: GraphQLTypes["LocationsDocAccessFields_sections_recurrance_Update"] | undefined,
	delete?: GraphQLTypes["LocationsDocAccessFields_sections_recurrance_Delete"] | undefined,
	fields?: GraphQLTypes["LocationsDocAccessFields_sections_recurrance_Fields"] | undefined
};
	["LocationsDocAccessFields_sections_recurrance_Create"]: {
	__typename: "LocationsDocAccessFields_sections_recurrance_Create",
	permission: boolean
};
	["LocationsDocAccessFields_sections_recurrance_Read"]: {
	__typename: "LocationsDocAccessFields_sections_recurrance_Read",
	permission: boolean
};
	["LocationsDocAccessFields_sections_recurrance_Update"]: {
	__typename: "LocationsDocAccessFields_sections_recurrance_Update",
	permission: boolean
};
	["LocationsDocAccessFields_sections_recurrance_Delete"]: {
	__typename: "LocationsDocAccessFields_sections_recurrance_Delete",
	permission: boolean
};
	["LocationsDocAccessFields_sections_recurrance_Fields"]: {
	__typename: "LocationsDocAccessFields_sections_recurrance_Fields",
	type?: GraphQLTypes["LocationsDocAccessFields_sections_recurrance_type"] | undefined,
	weekday?: GraphQLTypes["LocationsDocAccessFields_sections_recurrance_weekday"] | undefined
};
	["LocationsDocAccessFields_sections_recurrance_type"]: {
	__typename: "LocationsDocAccessFields_sections_recurrance_type",
	create?: GraphQLTypes["LocationsDocAccessFields_sections_recurrance_type_Create"] | undefined,
	read?: GraphQLTypes["LocationsDocAccessFields_sections_recurrance_type_Read"] | undefined,
	update?: GraphQLTypes["LocationsDocAccessFields_sections_recurrance_type_Update"] | undefined,
	delete?: GraphQLTypes["LocationsDocAccessFields_sections_recurrance_type_Delete"] | undefined
};
	["LocationsDocAccessFields_sections_recurrance_type_Create"]: {
	__typename: "LocationsDocAccessFields_sections_recurrance_type_Create",
	permission: boolean
};
	["LocationsDocAccessFields_sections_recurrance_type_Read"]: {
	__typename: "LocationsDocAccessFields_sections_recurrance_type_Read",
	permission: boolean
};
	["LocationsDocAccessFields_sections_recurrance_type_Update"]: {
	__typename: "LocationsDocAccessFields_sections_recurrance_type_Update",
	permission: boolean
};
	["LocationsDocAccessFields_sections_recurrance_type_Delete"]: {
	__typename: "LocationsDocAccessFields_sections_recurrance_type_Delete",
	permission: boolean
};
	["LocationsDocAccessFields_sections_recurrance_weekday"]: {
	__typename: "LocationsDocAccessFields_sections_recurrance_weekday",
	create?: GraphQLTypes["LocationsDocAccessFields_sections_recurrance_weekday_Create"] | undefined,
	read?: GraphQLTypes["LocationsDocAccessFields_sections_recurrance_weekday_Read"] | undefined,
	update?: GraphQLTypes["LocationsDocAccessFields_sections_recurrance_weekday_Update"] | undefined,
	delete?: GraphQLTypes["LocationsDocAccessFields_sections_recurrance_weekday_Delete"] | undefined
};
	["LocationsDocAccessFields_sections_recurrance_weekday_Create"]: {
	__typename: "LocationsDocAccessFields_sections_recurrance_weekday_Create",
	permission: boolean
};
	["LocationsDocAccessFields_sections_recurrance_weekday_Read"]: {
	__typename: "LocationsDocAccessFields_sections_recurrance_weekday_Read",
	permission: boolean
};
	["LocationsDocAccessFields_sections_recurrance_weekday_Update"]: {
	__typename: "LocationsDocAccessFields_sections_recurrance_weekday_Update",
	permission: boolean
};
	["LocationsDocAccessFields_sections_recurrance_weekday_Delete"]: {
	__typename: "LocationsDocAccessFields_sections_recurrance_weekday_Delete",
	permission: boolean
};
	["LocationsDocAccessFields_sections_id"]: {
	__typename: "LocationsDocAccessFields_sections_id",
	create?: GraphQLTypes["LocationsDocAccessFields_sections_id_Create"] | undefined,
	read?: GraphQLTypes["LocationsDocAccessFields_sections_id_Read"] | undefined,
	update?: GraphQLTypes["LocationsDocAccessFields_sections_id_Update"] | undefined,
	delete?: GraphQLTypes["LocationsDocAccessFields_sections_id_Delete"] | undefined
};
	["LocationsDocAccessFields_sections_id_Create"]: {
	__typename: "LocationsDocAccessFields_sections_id_Create",
	permission: boolean
};
	["LocationsDocAccessFields_sections_id_Read"]: {
	__typename: "LocationsDocAccessFields_sections_id_Read",
	permission: boolean
};
	["LocationsDocAccessFields_sections_id_Update"]: {
	__typename: "LocationsDocAccessFields_sections_id_Update",
	permission: boolean
};
	["LocationsDocAccessFields_sections_id_Delete"]: {
	__typename: "LocationsDocAccessFields_sections_id_Delete",
	permission: boolean
};
	["LocationsDocAccessFields__title"]: {
	__typename: "LocationsDocAccessFields__title",
	create?: GraphQLTypes["LocationsDocAccessFields__title_Create"] | undefined,
	read?: GraphQLTypes["LocationsDocAccessFields__title_Read"] | undefined,
	update?: GraphQLTypes["LocationsDocAccessFields__title_Update"] | undefined,
	delete?: GraphQLTypes["LocationsDocAccessFields__title_Delete"] | undefined
};
	["LocationsDocAccessFields__title_Create"]: {
	__typename: "LocationsDocAccessFields__title_Create",
	permission: boolean
};
	["LocationsDocAccessFields__title_Read"]: {
	__typename: "LocationsDocAccessFields__title_Read",
	permission: boolean
};
	["LocationsDocAccessFields__title_Update"]: {
	__typename: "LocationsDocAccessFields__title_Update",
	permission: boolean
};
	["LocationsDocAccessFields__title_Delete"]: {
	__typename: "LocationsDocAccessFields__title_Delete",
	permission: boolean
};
	["LocationsDocAccessFields_legacyGallery"]: {
	__typename: "LocationsDocAccessFields_legacyGallery",
	create?: GraphQLTypes["LocationsDocAccessFields_legacyGallery_Create"] | undefined,
	read?: GraphQLTypes["LocationsDocAccessFields_legacyGallery_Read"] | undefined,
	update?: GraphQLTypes["LocationsDocAccessFields_legacyGallery_Update"] | undefined,
	delete?: GraphQLTypes["LocationsDocAccessFields_legacyGallery_Delete"] | undefined
};
	["LocationsDocAccessFields_legacyGallery_Create"]: {
	__typename: "LocationsDocAccessFields_legacyGallery_Create",
	permission: boolean
};
	["LocationsDocAccessFields_legacyGallery_Read"]: {
	__typename: "LocationsDocAccessFields_legacyGallery_Read",
	permission: boolean
};
	["LocationsDocAccessFields_legacyGallery_Update"]: {
	__typename: "LocationsDocAccessFields_legacyGallery_Update",
	permission: boolean
};
	["LocationsDocAccessFields_legacyGallery_Delete"]: {
	__typename: "LocationsDocAccessFields_legacyGallery_Delete",
	permission: boolean
};
	["LocationsDocAccessFields_updatedAt"]: {
	__typename: "LocationsDocAccessFields_updatedAt",
	create?: GraphQLTypes["LocationsDocAccessFields_updatedAt_Create"] | undefined,
	read?: GraphQLTypes["LocationsDocAccessFields_updatedAt_Read"] | undefined,
	update?: GraphQLTypes["LocationsDocAccessFields_updatedAt_Update"] | undefined,
	delete?: GraphQLTypes["LocationsDocAccessFields_updatedAt_Delete"] | undefined
};
	["LocationsDocAccessFields_updatedAt_Create"]: {
	__typename: "LocationsDocAccessFields_updatedAt_Create",
	permission: boolean
};
	["LocationsDocAccessFields_updatedAt_Read"]: {
	__typename: "LocationsDocAccessFields_updatedAt_Read",
	permission: boolean
};
	["LocationsDocAccessFields_updatedAt_Update"]: {
	__typename: "LocationsDocAccessFields_updatedAt_Update",
	permission: boolean
};
	["LocationsDocAccessFields_updatedAt_Delete"]: {
	__typename: "LocationsDocAccessFields_updatedAt_Delete",
	permission: boolean
};
	["LocationsDocAccessFields_createdAt"]: {
	__typename: "LocationsDocAccessFields_createdAt",
	create?: GraphQLTypes["LocationsDocAccessFields_createdAt_Create"] | undefined,
	read?: GraphQLTypes["LocationsDocAccessFields_createdAt_Read"] | undefined,
	update?: GraphQLTypes["LocationsDocAccessFields_createdAt_Update"] | undefined,
	delete?: GraphQLTypes["LocationsDocAccessFields_createdAt_Delete"] | undefined
};
	["LocationsDocAccessFields_createdAt_Create"]: {
	__typename: "LocationsDocAccessFields_createdAt_Create",
	permission: boolean
};
	["LocationsDocAccessFields_createdAt_Read"]: {
	__typename: "LocationsDocAccessFields_createdAt_Read",
	permission: boolean
};
	["LocationsDocAccessFields_createdAt_Update"]: {
	__typename: "LocationsDocAccessFields_createdAt_Update",
	permission: boolean
};
	["LocationsDocAccessFields_createdAt_Delete"]: {
	__typename: "LocationsDocAccessFields_createdAt_Delete",
	permission: boolean
};
	["LocationsCreateDocAccess"]: {
	__typename: "LocationsCreateDocAccess",
	permission: boolean,
	where?: GraphQLTypes["JSONObject"] | undefined
};
	["LocationsReadDocAccess"]: {
	__typename: "LocationsReadDocAccess",
	permission: boolean,
	where?: GraphQLTypes["JSONObject"] | undefined
};
	["LocationsUpdateDocAccess"]: {
	__typename: "LocationsUpdateDocAccess",
	permission: boolean,
	where?: GraphQLTypes["JSONObject"] | undefined
};
	["LocationsDeleteDocAccess"]: {
	__typename: "LocationsDeleteDocAccess",
	permission: boolean,
	where?: GraphQLTypes["JSONObject"] | undefined
};
	["Post"]: {
	__typename: "Post",
	id?: string | undefined,
	title?: string | undefined,
	region?: GraphQLTypes["Post_region"] | undefined,
	authur?: GraphQLTypes["User"] | undefined,
	publishDateTime?: GraphQLTypes["DateTime"] | undefined,
	permalink?: string | undefined,
	status: string,
	content?: Array<GraphQLTypes["Post_Content"]> | undefined,
	legacyContent?: string | undefined,
	showLegacyContent?: boolean | undefined,
	blogCategories?: Array<GraphQLTypes["BlogCategory"]> | undefined,
	thumbnail?: GraphQLTypes["Media"] | undefined,
	banner?: GraphQLTypes["Media"] | undefined,
	criteria?: GraphQLTypes["Post_Criteria"] | undefined,
	location?: GraphQLTypes["Location"] | undefined,
	views?: number | undefined,
	homePageInternalClick?: number | undefined,
	homePageImpression?: number | undefined,
	blogListInternalClick?: number | undefined,
	blogListImpression?: number | undefined,
	blogDetailInternalClick?: number | undefined,
	blogDetailImpression?: number | undefined,
	relatedEventInternalClick?: number | undefined,
	relatedEventImpression?: number | undefined,
	latestEventInternalClick?: number | undefined,
	latestEventImpression?: number | undefined,
	internalClick?: number | undefined,
	totalImpression?: number | undefined,
	externalClick?: number | undefined,
	outboundClick?: number | undefined,
	legacyGallery?: Array<GraphQLTypes["Media"]> | undefined,
	updatedAt?: GraphQLTypes["DateTime"] | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined
};
	["Post_region"]: Post_region;
	["Post_Content"]:{
        	__typename:"RichText" | "Gallery" | "Image" | "Video"
        	['...on RichText']: '__union' & GraphQLTypes["RichText"];
	['...on Gallery']: '__union' & GraphQLTypes["Gallery"];
	['...on Image']: '__union' & GraphQLTypes["Image"];
	['...on Video']: '__union' & GraphQLTypes["Video"];
};
	["BlogCategory"]: {
	__typename: "BlogCategory",
	id?: string | undefined,
	name?: string | undefined,
	value: string,
	icon?: GraphQLTypes["Media"] | undefined,
	region?: GraphQLTypes["BlogCategory_region"] | undefined,
	updatedAt?: GraphQLTypes["DateTime"] | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined
};
	["BlogCategory_Icon_where"]: {
		alt?: GraphQLTypes["BlogCategory_Icon_alt_operator"] | undefined,
	updatedAt?: GraphQLTypes["BlogCategory_Icon_updatedAt_operator"] | undefined,
	createdAt?: GraphQLTypes["BlogCategory_Icon_createdAt_operator"] | undefined,
	url?: GraphQLTypes["BlogCategory_Icon_url_operator"] | undefined,
	filename?: GraphQLTypes["BlogCategory_Icon_filename_operator"] | undefined,
	mimeType?: GraphQLTypes["BlogCategory_Icon_mimeType_operator"] | undefined,
	filesize?: GraphQLTypes["BlogCategory_Icon_filesize_operator"] | undefined,
	width?: GraphQLTypes["BlogCategory_Icon_width_operator"] | undefined,
	height?: GraphQLTypes["BlogCategory_Icon_height_operator"] | undefined,
	focalX?: GraphQLTypes["BlogCategory_Icon_focalX_operator"] | undefined,
	focalY?: GraphQLTypes["BlogCategory_Icon_focalY_operator"] | undefined,
	sizes__small__url?: GraphQLTypes["BlogCategory_Icon_sizes__small__url_operator"] | undefined,
	sizes__small__width?: GraphQLTypes["BlogCategory_Icon_sizes__small__width_operator"] | undefined,
	sizes__small__height?: GraphQLTypes["BlogCategory_Icon_sizes__small__height_operator"] | undefined,
	sizes__small__mimeType?: GraphQLTypes["BlogCategory_Icon_sizes__small__mimeType_operator"] | undefined,
	sizes__small__filesize?: GraphQLTypes["BlogCategory_Icon_sizes__small__filesize_operator"] | undefined,
	sizes__small__filename?: GraphQLTypes["BlogCategory_Icon_sizes__small__filename_operator"] | undefined,
	sizes__medium__url?: GraphQLTypes["BlogCategory_Icon_sizes__medium__url_operator"] | undefined,
	sizes__medium__width?: GraphQLTypes["BlogCategory_Icon_sizes__medium__width_operator"] | undefined,
	sizes__medium__height?: GraphQLTypes["BlogCategory_Icon_sizes__medium__height_operator"] | undefined,
	sizes__medium__mimeType?: GraphQLTypes["BlogCategory_Icon_sizes__medium__mimeType_operator"] | undefined,
	sizes__medium__filesize?: GraphQLTypes["BlogCategory_Icon_sizes__medium__filesize_operator"] | undefined,
	sizes__medium__filename?: GraphQLTypes["BlogCategory_Icon_sizes__medium__filename_operator"] | undefined,
	sizes__large__url?: GraphQLTypes["BlogCategory_Icon_sizes__large__url_operator"] | undefined,
	sizes__large__width?: GraphQLTypes["BlogCategory_Icon_sizes__large__width_operator"] | undefined,
	sizes__large__height?: GraphQLTypes["BlogCategory_Icon_sizes__large__height_operator"] | undefined,
	sizes__large__mimeType?: GraphQLTypes["BlogCategory_Icon_sizes__large__mimeType_operator"] | undefined,
	sizes__large__filesize?: GraphQLTypes["BlogCategory_Icon_sizes__large__filesize_operator"] | undefined,
	sizes__large__filename?: GraphQLTypes["BlogCategory_Icon_sizes__large__filename_operator"] | undefined,
	sizes__small_jpeg__url?: GraphQLTypes["BlogCategory_Icon_sizes__small_jpeg__url_operator"] | undefined,
	sizes__small_jpeg__width?: GraphQLTypes["BlogCategory_Icon_sizes__small_jpeg__width_operator"] | undefined,
	sizes__small_jpeg__height?: GraphQLTypes["BlogCategory_Icon_sizes__small_jpeg__height_operator"] | undefined,
	sizes__small_jpeg__mimeType?: GraphQLTypes["BlogCategory_Icon_sizes__small_jpeg__mimeType_operator"] | undefined,
	sizes__small_jpeg__filesize?: GraphQLTypes["BlogCategory_Icon_sizes__small_jpeg__filesize_operator"] | undefined,
	sizes__small_jpeg__filename?: GraphQLTypes["BlogCategory_Icon_sizes__small_jpeg__filename_operator"] | undefined,
	sizes__medium_jpeg__url?: GraphQLTypes["BlogCategory_Icon_sizes__medium_jpeg__url_operator"] | undefined,
	sizes__medium_jpeg__width?: GraphQLTypes["BlogCategory_Icon_sizes__medium_jpeg__width_operator"] | undefined,
	sizes__medium_jpeg__height?: GraphQLTypes["BlogCategory_Icon_sizes__medium_jpeg__height_operator"] | undefined,
	sizes__medium_jpeg__mimeType?: GraphQLTypes["BlogCategory_Icon_sizes__medium_jpeg__mimeType_operator"] | undefined,
	sizes__medium_jpeg__filesize?: GraphQLTypes["BlogCategory_Icon_sizes__medium_jpeg__filesize_operator"] | undefined,
	sizes__medium_jpeg__filename?: GraphQLTypes["BlogCategory_Icon_sizes__medium_jpeg__filename_operator"] | undefined,
	sizes__large_jpeg__url?: GraphQLTypes["BlogCategory_Icon_sizes__large_jpeg__url_operator"] | undefined,
	sizes__large_jpeg__width?: GraphQLTypes["BlogCategory_Icon_sizes__large_jpeg__width_operator"] | undefined,
	sizes__large_jpeg__height?: GraphQLTypes["BlogCategory_Icon_sizes__large_jpeg__height_operator"] | undefined,
	sizes__large_jpeg__mimeType?: GraphQLTypes["BlogCategory_Icon_sizes__large_jpeg__mimeType_operator"] | undefined,
	sizes__large_jpeg__filesize?: GraphQLTypes["BlogCategory_Icon_sizes__large_jpeg__filesize_operator"] | undefined,
	sizes__large_jpeg__filename?: GraphQLTypes["BlogCategory_Icon_sizes__large_jpeg__filename_operator"] | undefined,
	id?: GraphQLTypes["BlogCategory_Icon_id_operator"] | undefined,
	AND?: Array<GraphQLTypes["BlogCategory_Icon_where_and"] | undefined> | undefined,
	OR?: Array<GraphQLTypes["BlogCategory_Icon_where_or"] | undefined> | undefined
};
	["BlogCategory_Icon_alt_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["BlogCategory_Icon_updatedAt_operator"]: {
		equals?: GraphQLTypes["DateTime"] | undefined,
	not_equals?: GraphQLTypes["DateTime"] | undefined,
	greater_than_equal?: GraphQLTypes["DateTime"] | undefined,
	greater_than?: GraphQLTypes["DateTime"] | undefined,
	less_than_equal?: GraphQLTypes["DateTime"] | undefined,
	less_than?: GraphQLTypes["DateTime"] | undefined,
	like?: GraphQLTypes["DateTime"] | undefined,
	exists?: boolean | undefined
};
	["BlogCategory_Icon_createdAt_operator"]: {
		equals?: GraphQLTypes["DateTime"] | undefined,
	not_equals?: GraphQLTypes["DateTime"] | undefined,
	greater_than_equal?: GraphQLTypes["DateTime"] | undefined,
	greater_than?: GraphQLTypes["DateTime"] | undefined,
	less_than_equal?: GraphQLTypes["DateTime"] | undefined,
	less_than?: GraphQLTypes["DateTime"] | undefined,
	like?: GraphQLTypes["DateTime"] | undefined,
	exists?: boolean | undefined
};
	["BlogCategory_Icon_url_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["BlogCategory_Icon_filename_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["BlogCategory_Icon_mimeType_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["BlogCategory_Icon_filesize_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["BlogCategory_Icon_width_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["BlogCategory_Icon_height_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["BlogCategory_Icon_focalX_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["BlogCategory_Icon_focalY_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["BlogCategory_Icon_sizes__small__url_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["BlogCategory_Icon_sizes__small__width_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["BlogCategory_Icon_sizes__small__height_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["BlogCategory_Icon_sizes__small__mimeType_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["BlogCategory_Icon_sizes__small__filesize_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["BlogCategory_Icon_sizes__small__filename_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["BlogCategory_Icon_sizes__medium__url_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["BlogCategory_Icon_sizes__medium__width_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["BlogCategory_Icon_sizes__medium__height_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["BlogCategory_Icon_sizes__medium__mimeType_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["BlogCategory_Icon_sizes__medium__filesize_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["BlogCategory_Icon_sizes__medium__filename_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["BlogCategory_Icon_sizes__large__url_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["BlogCategory_Icon_sizes__large__width_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["BlogCategory_Icon_sizes__large__height_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["BlogCategory_Icon_sizes__large__mimeType_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["BlogCategory_Icon_sizes__large__filesize_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["BlogCategory_Icon_sizes__large__filename_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["BlogCategory_Icon_sizes__small_jpeg__url_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["BlogCategory_Icon_sizes__small_jpeg__width_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["BlogCategory_Icon_sizes__small_jpeg__height_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["BlogCategory_Icon_sizes__small_jpeg__mimeType_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["BlogCategory_Icon_sizes__small_jpeg__filesize_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["BlogCategory_Icon_sizes__small_jpeg__filename_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["BlogCategory_Icon_sizes__medium_jpeg__url_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["BlogCategory_Icon_sizes__medium_jpeg__width_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["BlogCategory_Icon_sizes__medium_jpeg__height_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["BlogCategory_Icon_sizes__medium_jpeg__mimeType_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["BlogCategory_Icon_sizes__medium_jpeg__filesize_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["BlogCategory_Icon_sizes__medium_jpeg__filename_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["BlogCategory_Icon_sizes__large_jpeg__url_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["BlogCategory_Icon_sizes__large_jpeg__width_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["BlogCategory_Icon_sizes__large_jpeg__height_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["BlogCategory_Icon_sizes__large_jpeg__mimeType_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["BlogCategory_Icon_sizes__large_jpeg__filesize_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["BlogCategory_Icon_sizes__large_jpeg__filename_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["BlogCategory_Icon_id_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["BlogCategory_Icon_where_and"]: {
		alt?: GraphQLTypes["BlogCategory_Icon_alt_operator"] | undefined,
	updatedAt?: GraphQLTypes["BlogCategory_Icon_updatedAt_operator"] | undefined,
	createdAt?: GraphQLTypes["BlogCategory_Icon_createdAt_operator"] | undefined,
	url?: GraphQLTypes["BlogCategory_Icon_url_operator"] | undefined,
	filename?: GraphQLTypes["BlogCategory_Icon_filename_operator"] | undefined,
	mimeType?: GraphQLTypes["BlogCategory_Icon_mimeType_operator"] | undefined,
	filesize?: GraphQLTypes["BlogCategory_Icon_filesize_operator"] | undefined,
	width?: GraphQLTypes["BlogCategory_Icon_width_operator"] | undefined,
	height?: GraphQLTypes["BlogCategory_Icon_height_operator"] | undefined,
	focalX?: GraphQLTypes["BlogCategory_Icon_focalX_operator"] | undefined,
	focalY?: GraphQLTypes["BlogCategory_Icon_focalY_operator"] | undefined,
	sizes__small__url?: GraphQLTypes["BlogCategory_Icon_sizes__small__url_operator"] | undefined,
	sizes__small__width?: GraphQLTypes["BlogCategory_Icon_sizes__small__width_operator"] | undefined,
	sizes__small__height?: GraphQLTypes["BlogCategory_Icon_sizes__small__height_operator"] | undefined,
	sizes__small__mimeType?: GraphQLTypes["BlogCategory_Icon_sizes__small__mimeType_operator"] | undefined,
	sizes__small__filesize?: GraphQLTypes["BlogCategory_Icon_sizes__small__filesize_operator"] | undefined,
	sizes__small__filename?: GraphQLTypes["BlogCategory_Icon_sizes__small__filename_operator"] | undefined,
	sizes__medium__url?: GraphQLTypes["BlogCategory_Icon_sizes__medium__url_operator"] | undefined,
	sizes__medium__width?: GraphQLTypes["BlogCategory_Icon_sizes__medium__width_operator"] | undefined,
	sizes__medium__height?: GraphQLTypes["BlogCategory_Icon_sizes__medium__height_operator"] | undefined,
	sizes__medium__mimeType?: GraphQLTypes["BlogCategory_Icon_sizes__medium__mimeType_operator"] | undefined,
	sizes__medium__filesize?: GraphQLTypes["BlogCategory_Icon_sizes__medium__filesize_operator"] | undefined,
	sizes__medium__filename?: GraphQLTypes["BlogCategory_Icon_sizes__medium__filename_operator"] | undefined,
	sizes__large__url?: GraphQLTypes["BlogCategory_Icon_sizes__large__url_operator"] | undefined,
	sizes__large__width?: GraphQLTypes["BlogCategory_Icon_sizes__large__width_operator"] | undefined,
	sizes__large__height?: GraphQLTypes["BlogCategory_Icon_sizes__large__height_operator"] | undefined,
	sizes__large__mimeType?: GraphQLTypes["BlogCategory_Icon_sizes__large__mimeType_operator"] | undefined,
	sizes__large__filesize?: GraphQLTypes["BlogCategory_Icon_sizes__large__filesize_operator"] | undefined,
	sizes__large__filename?: GraphQLTypes["BlogCategory_Icon_sizes__large__filename_operator"] | undefined,
	sizes__small_jpeg__url?: GraphQLTypes["BlogCategory_Icon_sizes__small_jpeg__url_operator"] | undefined,
	sizes__small_jpeg__width?: GraphQLTypes["BlogCategory_Icon_sizes__small_jpeg__width_operator"] | undefined,
	sizes__small_jpeg__height?: GraphQLTypes["BlogCategory_Icon_sizes__small_jpeg__height_operator"] | undefined,
	sizes__small_jpeg__mimeType?: GraphQLTypes["BlogCategory_Icon_sizes__small_jpeg__mimeType_operator"] | undefined,
	sizes__small_jpeg__filesize?: GraphQLTypes["BlogCategory_Icon_sizes__small_jpeg__filesize_operator"] | undefined,
	sizes__small_jpeg__filename?: GraphQLTypes["BlogCategory_Icon_sizes__small_jpeg__filename_operator"] | undefined,
	sizes__medium_jpeg__url?: GraphQLTypes["BlogCategory_Icon_sizes__medium_jpeg__url_operator"] | undefined,
	sizes__medium_jpeg__width?: GraphQLTypes["BlogCategory_Icon_sizes__medium_jpeg__width_operator"] | undefined,
	sizes__medium_jpeg__height?: GraphQLTypes["BlogCategory_Icon_sizes__medium_jpeg__height_operator"] | undefined,
	sizes__medium_jpeg__mimeType?: GraphQLTypes["BlogCategory_Icon_sizes__medium_jpeg__mimeType_operator"] | undefined,
	sizes__medium_jpeg__filesize?: GraphQLTypes["BlogCategory_Icon_sizes__medium_jpeg__filesize_operator"] | undefined,
	sizes__medium_jpeg__filename?: GraphQLTypes["BlogCategory_Icon_sizes__medium_jpeg__filename_operator"] | undefined,
	sizes__large_jpeg__url?: GraphQLTypes["BlogCategory_Icon_sizes__large_jpeg__url_operator"] | undefined,
	sizes__large_jpeg__width?: GraphQLTypes["BlogCategory_Icon_sizes__large_jpeg__width_operator"] | undefined,
	sizes__large_jpeg__height?: GraphQLTypes["BlogCategory_Icon_sizes__large_jpeg__height_operator"] | undefined,
	sizes__large_jpeg__mimeType?: GraphQLTypes["BlogCategory_Icon_sizes__large_jpeg__mimeType_operator"] | undefined,
	sizes__large_jpeg__filesize?: GraphQLTypes["BlogCategory_Icon_sizes__large_jpeg__filesize_operator"] | undefined,
	sizes__large_jpeg__filename?: GraphQLTypes["BlogCategory_Icon_sizes__large_jpeg__filename_operator"] | undefined,
	id?: GraphQLTypes["BlogCategory_Icon_id_operator"] | undefined,
	AND?: Array<GraphQLTypes["BlogCategory_Icon_where_and"] | undefined> | undefined,
	OR?: Array<GraphQLTypes["BlogCategory_Icon_where_or"] | undefined> | undefined
};
	["BlogCategory_Icon_where_or"]: {
		alt?: GraphQLTypes["BlogCategory_Icon_alt_operator"] | undefined,
	updatedAt?: GraphQLTypes["BlogCategory_Icon_updatedAt_operator"] | undefined,
	createdAt?: GraphQLTypes["BlogCategory_Icon_createdAt_operator"] | undefined,
	url?: GraphQLTypes["BlogCategory_Icon_url_operator"] | undefined,
	filename?: GraphQLTypes["BlogCategory_Icon_filename_operator"] | undefined,
	mimeType?: GraphQLTypes["BlogCategory_Icon_mimeType_operator"] | undefined,
	filesize?: GraphQLTypes["BlogCategory_Icon_filesize_operator"] | undefined,
	width?: GraphQLTypes["BlogCategory_Icon_width_operator"] | undefined,
	height?: GraphQLTypes["BlogCategory_Icon_height_operator"] | undefined,
	focalX?: GraphQLTypes["BlogCategory_Icon_focalX_operator"] | undefined,
	focalY?: GraphQLTypes["BlogCategory_Icon_focalY_operator"] | undefined,
	sizes__small__url?: GraphQLTypes["BlogCategory_Icon_sizes__small__url_operator"] | undefined,
	sizes__small__width?: GraphQLTypes["BlogCategory_Icon_sizes__small__width_operator"] | undefined,
	sizes__small__height?: GraphQLTypes["BlogCategory_Icon_sizes__small__height_operator"] | undefined,
	sizes__small__mimeType?: GraphQLTypes["BlogCategory_Icon_sizes__small__mimeType_operator"] | undefined,
	sizes__small__filesize?: GraphQLTypes["BlogCategory_Icon_sizes__small__filesize_operator"] | undefined,
	sizes__small__filename?: GraphQLTypes["BlogCategory_Icon_sizes__small__filename_operator"] | undefined,
	sizes__medium__url?: GraphQLTypes["BlogCategory_Icon_sizes__medium__url_operator"] | undefined,
	sizes__medium__width?: GraphQLTypes["BlogCategory_Icon_sizes__medium__width_operator"] | undefined,
	sizes__medium__height?: GraphQLTypes["BlogCategory_Icon_sizes__medium__height_operator"] | undefined,
	sizes__medium__mimeType?: GraphQLTypes["BlogCategory_Icon_sizes__medium__mimeType_operator"] | undefined,
	sizes__medium__filesize?: GraphQLTypes["BlogCategory_Icon_sizes__medium__filesize_operator"] | undefined,
	sizes__medium__filename?: GraphQLTypes["BlogCategory_Icon_sizes__medium__filename_operator"] | undefined,
	sizes__large__url?: GraphQLTypes["BlogCategory_Icon_sizes__large__url_operator"] | undefined,
	sizes__large__width?: GraphQLTypes["BlogCategory_Icon_sizes__large__width_operator"] | undefined,
	sizes__large__height?: GraphQLTypes["BlogCategory_Icon_sizes__large__height_operator"] | undefined,
	sizes__large__mimeType?: GraphQLTypes["BlogCategory_Icon_sizes__large__mimeType_operator"] | undefined,
	sizes__large__filesize?: GraphQLTypes["BlogCategory_Icon_sizes__large__filesize_operator"] | undefined,
	sizes__large__filename?: GraphQLTypes["BlogCategory_Icon_sizes__large__filename_operator"] | undefined,
	sizes__small_jpeg__url?: GraphQLTypes["BlogCategory_Icon_sizes__small_jpeg__url_operator"] | undefined,
	sizes__small_jpeg__width?: GraphQLTypes["BlogCategory_Icon_sizes__small_jpeg__width_operator"] | undefined,
	sizes__small_jpeg__height?: GraphQLTypes["BlogCategory_Icon_sizes__small_jpeg__height_operator"] | undefined,
	sizes__small_jpeg__mimeType?: GraphQLTypes["BlogCategory_Icon_sizes__small_jpeg__mimeType_operator"] | undefined,
	sizes__small_jpeg__filesize?: GraphQLTypes["BlogCategory_Icon_sizes__small_jpeg__filesize_operator"] | undefined,
	sizes__small_jpeg__filename?: GraphQLTypes["BlogCategory_Icon_sizes__small_jpeg__filename_operator"] | undefined,
	sizes__medium_jpeg__url?: GraphQLTypes["BlogCategory_Icon_sizes__medium_jpeg__url_operator"] | undefined,
	sizes__medium_jpeg__width?: GraphQLTypes["BlogCategory_Icon_sizes__medium_jpeg__width_operator"] | undefined,
	sizes__medium_jpeg__height?: GraphQLTypes["BlogCategory_Icon_sizes__medium_jpeg__height_operator"] | undefined,
	sizes__medium_jpeg__mimeType?: GraphQLTypes["BlogCategory_Icon_sizes__medium_jpeg__mimeType_operator"] | undefined,
	sizes__medium_jpeg__filesize?: GraphQLTypes["BlogCategory_Icon_sizes__medium_jpeg__filesize_operator"] | undefined,
	sizes__medium_jpeg__filename?: GraphQLTypes["BlogCategory_Icon_sizes__medium_jpeg__filename_operator"] | undefined,
	sizes__large_jpeg__url?: GraphQLTypes["BlogCategory_Icon_sizes__large_jpeg__url_operator"] | undefined,
	sizes__large_jpeg__width?: GraphQLTypes["BlogCategory_Icon_sizes__large_jpeg__width_operator"] | undefined,
	sizes__large_jpeg__height?: GraphQLTypes["BlogCategory_Icon_sizes__large_jpeg__height_operator"] | undefined,
	sizes__large_jpeg__mimeType?: GraphQLTypes["BlogCategory_Icon_sizes__large_jpeg__mimeType_operator"] | undefined,
	sizes__large_jpeg__filesize?: GraphQLTypes["BlogCategory_Icon_sizes__large_jpeg__filesize_operator"] | undefined,
	sizes__large_jpeg__filename?: GraphQLTypes["BlogCategory_Icon_sizes__large_jpeg__filename_operator"] | undefined,
	id?: GraphQLTypes["BlogCategory_Icon_id_operator"] | undefined,
	AND?: Array<GraphQLTypes["BlogCategory_Icon_where_and"] | undefined> | undefined,
	OR?: Array<GraphQLTypes["BlogCategory_Icon_where_or"] | undefined> | undefined
};
	["BlogCategory_region"]: BlogCategory_region;
	["Post_Thumbnail_where"]: {
		alt?: GraphQLTypes["Post_Thumbnail_alt_operator"] | undefined,
	updatedAt?: GraphQLTypes["Post_Thumbnail_updatedAt_operator"] | undefined,
	createdAt?: GraphQLTypes["Post_Thumbnail_createdAt_operator"] | undefined,
	url?: GraphQLTypes["Post_Thumbnail_url_operator"] | undefined,
	filename?: GraphQLTypes["Post_Thumbnail_filename_operator"] | undefined,
	mimeType?: GraphQLTypes["Post_Thumbnail_mimeType_operator"] | undefined,
	filesize?: GraphQLTypes["Post_Thumbnail_filesize_operator"] | undefined,
	width?: GraphQLTypes["Post_Thumbnail_width_operator"] | undefined,
	height?: GraphQLTypes["Post_Thumbnail_height_operator"] | undefined,
	focalX?: GraphQLTypes["Post_Thumbnail_focalX_operator"] | undefined,
	focalY?: GraphQLTypes["Post_Thumbnail_focalY_operator"] | undefined,
	sizes__small__url?: GraphQLTypes["Post_Thumbnail_sizes__small__url_operator"] | undefined,
	sizes__small__width?: GraphQLTypes["Post_Thumbnail_sizes__small__width_operator"] | undefined,
	sizes__small__height?: GraphQLTypes["Post_Thumbnail_sizes__small__height_operator"] | undefined,
	sizes__small__mimeType?: GraphQLTypes["Post_Thumbnail_sizes__small__mimeType_operator"] | undefined,
	sizes__small__filesize?: GraphQLTypes["Post_Thumbnail_sizes__small__filesize_operator"] | undefined,
	sizes__small__filename?: GraphQLTypes["Post_Thumbnail_sizes__small__filename_operator"] | undefined,
	sizes__medium__url?: GraphQLTypes["Post_Thumbnail_sizes__medium__url_operator"] | undefined,
	sizes__medium__width?: GraphQLTypes["Post_Thumbnail_sizes__medium__width_operator"] | undefined,
	sizes__medium__height?: GraphQLTypes["Post_Thumbnail_sizes__medium__height_operator"] | undefined,
	sizes__medium__mimeType?: GraphQLTypes["Post_Thumbnail_sizes__medium__mimeType_operator"] | undefined,
	sizes__medium__filesize?: GraphQLTypes["Post_Thumbnail_sizes__medium__filesize_operator"] | undefined,
	sizes__medium__filename?: GraphQLTypes["Post_Thumbnail_sizes__medium__filename_operator"] | undefined,
	sizes__large__url?: GraphQLTypes["Post_Thumbnail_sizes__large__url_operator"] | undefined,
	sizes__large__width?: GraphQLTypes["Post_Thumbnail_sizes__large__width_operator"] | undefined,
	sizes__large__height?: GraphQLTypes["Post_Thumbnail_sizes__large__height_operator"] | undefined,
	sizes__large__mimeType?: GraphQLTypes["Post_Thumbnail_sizes__large__mimeType_operator"] | undefined,
	sizes__large__filesize?: GraphQLTypes["Post_Thumbnail_sizes__large__filesize_operator"] | undefined,
	sizes__large__filename?: GraphQLTypes["Post_Thumbnail_sizes__large__filename_operator"] | undefined,
	sizes__small_jpeg__url?: GraphQLTypes["Post_Thumbnail_sizes__small_jpeg__url_operator"] | undefined,
	sizes__small_jpeg__width?: GraphQLTypes["Post_Thumbnail_sizes__small_jpeg__width_operator"] | undefined,
	sizes__small_jpeg__height?: GraphQLTypes["Post_Thumbnail_sizes__small_jpeg__height_operator"] | undefined,
	sizes__small_jpeg__mimeType?: GraphQLTypes["Post_Thumbnail_sizes__small_jpeg__mimeType_operator"] | undefined,
	sizes__small_jpeg__filesize?: GraphQLTypes["Post_Thumbnail_sizes__small_jpeg__filesize_operator"] | undefined,
	sizes__small_jpeg__filename?: GraphQLTypes["Post_Thumbnail_sizes__small_jpeg__filename_operator"] | undefined,
	sizes__medium_jpeg__url?: GraphQLTypes["Post_Thumbnail_sizes__medium_jpeg__url_operator"] | undefined,
	sizes__medium_jpeg__width?: GraphQLTypes["Post_Thumbnail_sizes__medium_jpeg__width_operator"] | undefined,
	sizes__medium_jpeg__height?: GraphQLTypes["Post_Thumbnail_sizes__medium_jpeg__height_operator"] | undefined,
	sizes__medium_jpeg__mimeType?: GraphQLTypes["Post_Thumbnail_sizes__medium_jpeg__mimeType_operator"] | undefined,
	sizes__medium_jpeg__filesize?: GraphQLTypes["Post_Thumbnail_sizes__medium_jpeg__filesize_operator"] | undefined,
	sizes__medium_jpeg__filename?: GraphQLTypes["Post_Thumbnail_sizes__medium_jpeg__filename_operator"] | undefined,
	sizes__large_jpeg__url?: GraphQLTypes["Post_Thumbnail_sizes__large_jpeg__url_operator"] | undefined,
	sizes__large_jpeg__width?: GraphQLTypes["Post_Thumbnail_sizes__large_jpeg__width_operator"] | undefined,
	sizes__large_jpeg__height?: GraphQLTypes["Post_Thumbnail_sizes__large_jpeg__height_operator"] | undefined,
	sizes__large_jpeg__mimeType?: GraphQLTypes["Post_Thumbnail_sizes__large_jpeg__mimeType_operator"] | undefined,
	sizes__large_jpeg__filesize?: GraphQLTypes["Post_Thumbnail_sizes__large_jpeg__filesize_operator"] | undefined,
	sizes__large_jpeg__filename?: GraphQLTypes["Post_Thumbnail_sizes__large_jpeg__filename_operator"] | undefined,
	id?: GraphQLTypes["Post_Thumbnail_id_operator"] | undefined,
	AND?: Array<GraphQLTypes["Post_Thumbnail_where_and"] | undefined> | undefined,
	OR?: Array<GraphQLTypes["Post_Thumbnail_where_or"] | undefined> | undefined
};
	["Post_Thumbnail_alt_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Post_Thumbnail_updatedAt_operator"]: {
		equals?: GraphQLTypes["DateTime"] | undefined,
	not_equals?: GraphQLTypes["DateTime"] | undefined,
	greater_than_equal?: GraphQLTypes["DateTime"] | undefined,
	greater_than?: GraphQLTypes["DateTime"] | undefined,
	less_than_equal?: GraphQLTypes["DateTime"] | undefined,
	less_than?: GraphQLTypes["DateTime"] | undefined,
	like?: GraphQLTypes["DateTime"] | undefined,
	exists?: boolean | undefined
};
	["Post_Thumbnail_createdAt_operator"]: {
		equals?: GraphQLTypes["DateTime"] | undefined,
	not_equals?: GraphQLTypes["DateTime"] | undefined,
	greater_than_equal?: GraphQLTypes["DateTime"] | undefined,
	greater_than?: GraphQLTypes["DateTime"] | undefined,
	less_than_equal?: GraphQLTypes["DateTime"] | undefined,
	less_than?: GraphQLTypes["DateTime"] | undefined,
	like?: GraphQLTypes["DateTime"] | undefined,
	exists?: boolean | undefined
};
	["Post_Thumbnail_url_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Post_Thumbnail_filename_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Post_Thumbnail_mimeType_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Post_Thumbnail_filesize_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Post_Thumbnail_width_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Post_Thumbnail_height_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Post_Thumbnail_focalX_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Post_Thumbnail_focalY_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Post_Thumbnail_sizes__small__url_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Post_Thumbnail_sizes__small__width_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Post_Thumbnail_sizes__small__height_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Post_Thumbnail_sizes__small__mimeType_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Post_Thumbnail_sizes__small__filesize_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Post_Thumbnail_sizes__small__filename_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Post_Thumbnail_sizes__medium__url_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Post_Thumbnail_sizes__medium__width_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Post_Thumbnail_sizes__medium__height_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Post_Thumbnail_sizes__medium__mimeType_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Post_Thumbnail_sizes__medium__filesize_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Post_Thumbnail_sizes__medium__filename_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Post_Thumbnail_sizes__large__url_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Post_Thumbnail_sizes__large__width_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Post_Thumbnail_sizes__large__height_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Post_Thumbnail_sizes__large__mimeType_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Post_Thumbnail_sizes__large__filesize_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Post_Thumbnail_sizes__large__filename_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Post_Thumbnail_sizes__small_jpeg__url_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Post_Thumbnail_sizes__small_jpeg__width_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Post_Thumbnail_sizes__small_jpeg__height_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Post_Thumbnail_sizes__small_jpeg__mimeType_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Post_Thumbnail_sizes__small_jpeg__filesize_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Post_Thumbnail_sizes__small_jpeg__filename_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Post_Thumbnail_sizes__medium_jpeg__url_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Post_Thumbnail_sizes__medium_jpeg__width_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Post_Thumbnail_sizes__medium_jpeg__height_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Post_Thumbnail_sizes__medium_jpeg__mimeType_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Post_Thumbnail_sizes__medium_jpeg__filesize_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Post_Thumbnail_sizes__medium_jpeg__filename_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Post_Thumbnail_sizes__large_jpeg__url_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Post_Thumbnail_sizes__large_jpeg__width_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Post_Thumbnail_sizes__large_jpeg__height_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Post_Thumbnail_sizes__large_jpeg__mimeType_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Post_Thumbnail_sizes__large_jpeg__filesize_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Post_Thumbnail_sizes__large_jpeg__filename_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Post_Thumbnail_id_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Post_Thumbnail_where_and"]: {
		alt?: GraphQLTypes["Post_Thumbnail_alt_operator"] | undefined,
	updatedAt?: GraphQLTypes["Post_Thumbnail_updatedAt_operator"] | undefined,
	createdAt?: GraphQLTypes["Post_Thumbnail_createdAt_operator"] | undefined,
	url?: GraphQLTypes["Post_Thumbnail_url_operator"] | undefined,
	filename?: GraphQLTypes["Post_Thumbnail_filename_operator"] | undefined,
	mimeType?: GraphQLTypes["Post_Thumbnail_mimeType_operator"] | undefined,
	filesize?: GraphQLTypes["Post_Thumbnail_filesize_operator"] | undefined,
	width?: GraphQLTypes["Post_Thumbnail_width_operator"] | undefined,
	height?: GraphQLTypes["Post_Thumbnail_height_operator"] | undefined,
	focalX?: GraphQLTypes["Post_Thumbnail_focalX_operator"] | undefined,
	focalY?: GraphQLTypes["Post_Thumbnail_focalY_operator"] | undefined,
	sizes__small__url?: GraphQLTypes["Post_Thumbnail_sizes__small__url_operator"] | undefined,
	sizes__small__width?: GraphQLTypes["Post_Thumbnail_sizes__small__width_operator"] | undefined,
	sizes__small__height?: GraphQLTypes["Post_Thumbnail_sizes__small__height_operator"] | undefined,
	sizes__small__mimeType?: GraphQLTypes["Post_Thumbnail_sizes__small__mimeType_operator"] | undefined,
	sizes__small__filesize?: GraphQLTypes["Post_Thumbnail_sizes__small__filesize_operator"] | undefined,
	sizes__small__filename?: GraphQLTypes["Post_Thumbnail_sizes__small__filename_operator"] | undefined,
	sizes__medium__url?: GraphQLTypes["Post_Thumbnail_sizes__medium__url_operator"] | undefined,
	sizes__medium__width?: GraphQLTypes["Post_Thumbnail_sizes__medium__width_operator"] | undefined,
	sizes__medium__height?: GraphQLTypes["Post_Thumbnail_sizes__medium__height_operator"] | undefined,
	sizes__medium__mimeType?: GraphQLTypes["Post_Thumbnail_sizes__medium__mimeType_operator"] | undefined,
	sizes__medium__filesize?: GraphQLTypes["Post_Thumbnail_sizes__medium__filesize_operator"] | undefined,
	sizes__medium__filename?: GraphQLTypes["Post_Thumbnail_sizes__medium__filename_operator"] | undefined,
	sizes__large__url?: GraphQLTypes["Post_Thumbnail_sizes__large__url_operator"] | undefined,
	sizes__large__width?: GraphQLTypes["Post_Thumbnail_sizes__large__width_operator"] | undefined,
	sizes__large__height?: GraphQLTypes["Post_Thumbnail_sizes__large__height_operator"] | undefined,
	sizes__large__mimeType?: GraphQLTypes["Post_Thumbnail_sizes__large__mimeType_operator"] | undefined,
	sizes__large__filesize?: GraphQLTypes["Post_Thumbnail_sizes__large__filesize_operator"] | undefined,
	sizes__large__filename?: GraphQLTypes["Post_Thumbnail_sizes__large__filename_operator"] | undefined,
	sizes__small_jpeg__url?: GraphQLTypes["Post_Thumbnail_sizes__small_jpeg__url_operator"] | undefined,
	sizes__small_jpeg__width?: GraphQLTypes["Post_Thumbnail_sizes__small_jpeg__width_operator"] | undefined,
	sizes__small_jpeg__height?: GraphQLTypes["Post_Thumbnail_sizes__small_jpeg__height_operator"] | undefined,
	sizes__small_jpeg__mimeType?: GraphQLTypes["Post_Thumbnail_sizes__small_jpeg__mimeType_operator"] | undefined,
	sizes__small_jpeg__filesize?: GraphQLTypes["Post_Thumbnail_sizes__small_jpeg__filesize_operator"] | undefined,
	sizes__small_jpeg__filename?: GraphQLTypes["Post_Thumbnail_sizes__small_jpeg__filename_operator"] | undefined,
	sizes__medium_jpeg__url?: GraphQLTypes["Post_Thumbnail_sizes__medium_jpeg__url_operator"] | undefined,
	sizes__medium_jpeg__width?: GraphQLTypes["Post_Thumbnail_sizes__medium_jpeg__width_operator"] | undefined,
	sizes__medium_jpeg__height?: GraphQLTypes["Post_Thumbnail_sizes__medium_jpeg__height_operator"] | undefined,
	sizes__medium_jpeg__mimeType?: GraphQLTypes["Post_Thumbnail_sizes__medium_jpeg__mimeType_operator"] | undefined,
	sizes__medium_jpeg__filesize?: GraphQLTypes["Post_Thumbnail_sizes__medium_jpeg__filesize_operator"] | undefined,
	sizes__medium_jpeg__filename?: GraphQLTypes["Post_Thumbnail_sizes__medium_jpeg__filename_operator"] | undefined,
	sizes__large_jpeg__url?: GraphQLTypes["Post_Thumbnail_sizes__large_jpeg__url_operator"] | undefined,
	sizes__large_jpeg__width?: GraphQLTypes["Post_Thumbnail_sizes__large_jpeg__width_operator"] | undefined,
	sizes__large_jpeg__height?: GraphQLTypes["Post_Thumbnail_sizes__large_jpeg__height_operator"] | undefined,
	sizes__large_jpeg__mimeType?: GraphQLTypes["Post_Thumbnail_sizes__large_jpeg__mimeType_operator"] | undefined,
	sizes__large_jpeg__filesize?: GraphQLTypes["Post_Thumbnail_sizes__large_jpeg__filesize_operator"] | undefined,
	sizes__large_jpeg__filename?: GraphQLTypes["Post_Thumbnail_sizes__large_jpeg__filename_operator"] | undefined,
	id?: GraphQLTypes["Post_Thumbnail_id_operator"] | undefined,
	AND?: Array<GraphQLTypes["Post_Thumbnail_where_and"] | undefined> | undefined,
	OR?: Array<GraphQLTypes["Post_Thumbnail_where_or"] | undefined> | undefined
};
	["Post_Thumbnail_where_or"]: {
		alt?: GraphQLTypes["Post_Thumbnail_alt_operator"] | undefined,
	updatedAt?: GraphQLTypes["Post_Thumbnail_updatedAt_operator"] | undefined,
	createdAt?: GraphQLTypes["Post_Thumbnail_createdAt_operator"] | undefined,
	url?: GraphQLTypes["Post_Thumbnail_url_operator"] | undefined,
	filename?: GraphQLTypes["Post_Thumbnail_filename_operator"] | undefined,
	mimeType?: GraphQLTypes["Post_Thumbnail_mimeType_operator"] | undefined,
	filesize?: GraphQLTypes["Post_Thumbnail_filesize_operator"] | undefined,
	width?: GraphQLTypes["Post_Thumbnail_width_operator"] | undefined,
	height?: GraphQLTypes["Post_Thumbnail_height_operator"] | undefined,
	focalX?: GraphQLTypes["Post_Thumbnail_focalX_operator"] | undefined,
	focalY?: GraphQLTypes["Post_Thumbnail_focalY_operator"] | undefined,
	sizes__small__url?: GraphQLTypes["Post_Thumbnail_sizes__small__url_operator"] | undefined,
	sizes__small__width?: GraphQLTypes["Post_Thumbnail_sizes__small__width_operator"] | undefined,
	sizes__small__height?: GraphQLTypes["Post_Thumbnail_sizes__small__height_operator"] | undefined,
	sizes__small__mimeType?: GraphQLTypes["Post_Thumbnail_sizes__small__mimeType_operator"] | undefined,
	sizes__small__filesize?: GraphQLTypes["Post_Thumbnail_sizes__small__filesize_operator"] | undefined,
	sizes__small__filename?: GraphQLTypes["Post_Thumbnail_sizes__small__filename_operator"] | undefined,
	sizes__medium__url?: GraphQLTypes["Post_Thumbnail_sizes__medium__url_operator"] | undefined,
	sizes__medium__width?: GraphQLTypes["Post_Thumbnail_sizes__medium__width_operator"] | undefined,
	sizes__medium__height?: GraphQLTypes["Post_Thumbnail_sizes__medium__height_operator"] | undefined,
	sizes__medium__mimeType?: GraphQLTypes["Post_Thumbnail_sizes__medium__mimeType_operator"] | undefined,
	sizes__medium__filesize?: GraphQLTypes["Post_Thumbnail_sizes__medium__filesize_operator"] | undefined,
	sizes__medium__filename?: GraphQLTypes["Post_Thumbnail_sizes__medium__filename_operator"] | undefined,
	sizes__large__url?: GraphQLTypes["Post_Thumbnail_sizes__large__url_operator"] | undefined,
	sizes__large__width?: GraphQLTypes["Post_Thumbnail_sizes__large__width_operator"] | undefined,
	sizes__large__height?: GraphQLTypes["Post_Thumbnail_sizes__large__height_operator"] | undefined,
	sizes__large__mimeType?: GraphQLTypes["Post_Thumbnail_sizes__large__mimeType_operator"] | undefined,
	sizes__large__filesize?: GraphQLTypes["Post_Thumbnail_sizes__large__filesize_operator"] | undefined,
	sizes__large__filename?: GraphQLTypes["Post_Thumbnail_sizes__large__filename_operator"] | undefined,
	sizes__small_jpeg__url?: GraphQLTypes["Post_Thumbnail_sizes__small_jpeg__url_operator"] | undefined,
	sizes__small_jpeg__width?: GraphQLTypes["Post_Thumbnail_sizes__small_jpeg__width_operator"] | undefined,
	sizes__small_jpeg__height?: GraphQLTypes["Post_Thumbnail_sizes__small_jpeg__height_operator"] | undefined,
	sizes__small_jpeg__mimeType?: GraphQLTypes["Post_Thumbnail_sizes__small_jpeg__mimeType_operator"] | undefined,
	sizes__small_jpeg__filesize?: GraphQLTypes["Post_Thumbnail_sizes__small_jpeg__filesize_operator"] | undefined,
	sizes__small_jpeg__filename?: GraphQLTypes["Post_Thumbnail_sizes__small_jpeg__filename_operator"] | undefined,
	sizes__medium_jpeg__url?: GraphQLTypes["Post_Thumbnail_sizes__medium_jpeg__url_operator"] | undefined,
	sizes__medium_jpeg__width?: GraphQLTypes["Post_Thumbnail_sizes__medium_jpeg__width_operator"] | undefined,
	sizes__medium_jpeg__height?: GraphQLTypes["Post_Thumbnail_sizes__medium_jpeg__height_operator"] | undefined,
	sizes__medium_jpeg__mimeType?: GraphQLTypes["Post_Thumbnail_sizes__medium_jpeg__mimeType_operator"] | undefined,
	sizes__medium_jpeg__filesize?: GraphQLTypes["Post_Thumbnail_sizes__medium_jpeg__filesize_operator"] | undefined,
	sizes__medium_jpeg__filename?: GraphQLTypes["Post_Thumbnail_sizes__medium_jpeg__filename_operator"] | undefined,
	sizes__large_jpeg__url?: GraphQLTypes["Post_Thumbnail_sizes__large_jpeg__url_operator"] | undefined,
	sizes__large_jpeg__width?: GraphQLTypes["Post_Thumbnail_sizes__large_jpeg__width_operator"] | undefined,
	sizes__large_jpeg__height?: GraphQLTypes["Post_Thumbnail_sizes__large_jpeg__height_operator"] | undefined,
	sizes__large_jpeg__mimeType?: GraphQLTypes["Post_Thumbnail_sizes__large_jpeg__mimeType_operator"] | undefined,
	sizes__large_jpeg__filesize?: GraphQLTypes["Post_Thumbnail_sizes__large_jpeg__filesize_operator"] | undefined,
	sizes__large_jpeg__filename?: GraphQLTypes["Post_Thumbnail_sizes__large_jpeg__filename_operator"] | undefined,
	id?: GraphQLTypes["Post_Thumbnail_id_operator"] | undefined,
	AND?: Array<GraphQLTypes["Post_Thumbnail_where_and"] | undefined> | undefined,
	OR?: Array<GraphQLTypes["Post_Thumbnail_where_or"] | undefined> | undefined
};
	["Post_Banner_where"]: {
		alt?: GraphQLTypes["Post_Banner_alt_operator"] | undefined,
	updatedAt?: GraphQLTypes["Post_Banner_updatedAt_operator"] | undefined,
	createdAt?: GraphQLTypes["Post_Banner_createdAt_operator"] | undefined,
	url?: GraphQLTypes["Post_Banner_url_operator"] | undefined,
	filename?: GraphQLTypes["Post_Banner_filename_operator"] | undefined,
	mimeType?: GraphQLTypes["Post_Banner_mimeType_operator"] | undefined,
	filesize?: GraphQLTypes["Post_Banner_filesize_operator"] | undefined,
	width?: GraphQLTypes["Post_Banner_width_operator"] | undefined,
	height?: GraphQLTypes["Post_Banner_height_operator"] | undefined,
	focalX?: GraphQLTypes["Post_Banner_focalX_operator"] | undefined,
	focalY?: GraphQLTypes["Post_Banner_focalY_operator"] | undefined,
	sizes__small__url?: GraphQLTypes["Post_Banner_sizes__small__url_operator"] | undefined,
	sizes__small__width?: GraphQLTypes["Post_Banner_sizes__small__width_operator"] | undefined,
	sizes__small__height?: GraphQLTypes["Post_Banner_sizes__small__height_operator"] | undefined,
	sizes__small__mimeType?: GraphQLTypes["Post_Banner_sizes__small__mimeType_operator"] | undefined,
	sizes__small__filesize?: GraphQLTypes["Post_Banner_sizes__small__filesize_operator"] | undefined,
	sizes__small__filename?: GraphQLTypes["Post_Banner_sizes__small__filename_operator"] | undefined,
	sizes__medium__url?: GraphQLTypes["Post_Banner_sizes__medium__url_operator"] | undefined,
	sizes__medium__width?: GraphQLTypes["Post_Banner_sizes__medium__width_operator"] | undefined,
	sizes__medium__height?: GraphQLTypes["Post_Banner_sizes__medium__height_operator"] | undefined,
	sizes__medium__mimeType?: GraphQLTypes["Post_Banner_sizes__medium__mimeType_operator"] | undefined,
	sizes__medium__filesize?: GraphQLTypes["Post_Banner_sizes__medium__filesize_operator"] | undefined,
	sizes__medium__filename?: GraphQLTypes["Post_Banner_sizes__medium__filename_operator"] | undefined,
	sizes__large__url?: GraphQLTypes["Post_Banner_sizes__large__url_operator"] | undefined,
	sizes__large__width?: GraphQLTypes["Post_Banner_sizes__large__width_operator"] | undefined,
	sizes__large__height?: GraphQLTypes["Post_Banner_sizes__large__height_operator"] | undefined,
	sizes__large__mimeType?: GraphQLTypes["Post_Banner_sizes__large__mimeType_operator"] | undefined,
	sizes__large__filesize?: GraphQLTypes["Post_Banner_sizes__large__filesize_operator"] | undefined,
	sizes__large__filename?: GraphQLTypes["Post_Banner_sizes__large__filename_operator"] | undefined,
	sizes__small_jpeg__url?: GraphQLTypes["Post_Banner_sizes__small_jpeg__url_operator"] | undefined,
	sizes__small_jpeg__width?: GraphQLTypes["Post_Banner_sizes__small_jpeg__width_operator"] | undefined,
	sizes__small_jpeg__height?: GraphQLTypes["Post_Banner_sizes__small_jpeg__height_operator"] | undefined,
	sizes__small_jpeg__mimeType?: GraphQLTypes["Post_Banner_sizes__small_jpeg__mimeType_operator"] | undefined,
	sizes__small_jpeg__filesize?: GraphQLTypes["Post_Banner_sizes__small_jpeg__filesize_operator"] | undefined,
	sizes__small_jpeg__filename?: GraphQLTypes["Post_Banner_sizes__small_jpeg__filename_operator"] | undefined,
	sizes__medium_jpeg__url?: GraphQLTypes["Post_Banner_sizes__medium_jpeg__url_operator"] | undefined,
	sizes__medium_jpeg__width?: GraphQLTypes["Post_Banner_sizes__medium_jpeg__width_operator"] | undefined,
	sizes__medium_jpeg__height?: GraphQLTypes["Post_Banner_sizes__medium_jpeg__height_operator"] | undefined,
	sizes__medium_jpeg__mimeType?: GraphQLTypes["Post_Banner_sizes__medium_jpeg__mimeType_operator"] | undefined,
	sizes__medium_jpeg__filesize?: GraphQLTypes["Post_Banner_sizes__medium_jpeg__filesize_operator"] | undefined,
	sizes__medium_jpeg__filename?: GraphQLTypes["Post_Banner_sizes__medium_jpeg__filename_operator"] | undefined,
	sizes__large_jpeg__url?: GraphQLTypes["Post_Banner_sizes__large_jpeg__url_operator"] | undefined,
	sizes__large_jpeg__width?: GraphQLTypes["Post_Banner_sizes__large_jpeg__width_operator"] | undefined,
	sizes__large_jpeg__height?: GraphQLTypes["Post_Banner_sizes__large_jpeg__height_operator"] | undefined,
	sizes__large_jpeg__mimeType?: GraphQLTypes["Post_Banner_sizes__large_jpeg__mimeType_operator"] | undefined,
	sizes__large_jpeg__filesize?: GraphQLTypes["Post_Banner_sizes__large_jpeg__filesize_operator"] | undefined,
	sizes__large_jpeg__filename?: GraphQLTypes["Post_Banner_sizes__large_jpeg__filename_operator"] | undefined,
	id?: GraphQLTypes["Post_Banner_id_operator"] | undefined,
	AND?: Array<GraphQLTypes["Post_Banner_where_and"] | undefined> | undefined,
	OR?: Array<GraphQLTypes["Post_Banner_where_or"] | undefined> | undefined
};
	["Post_Banner_alt_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Post_Banner_updatedAt_operator"]: {
		equals?: GraphQLTypes["DateTime"] | undefined,
	not_equals?: GraphQLTypes["DateTime"] | undefined,
	greater_than_equal?: GraphQLTypes["DateTime"] | undefined,
	greater_than?: GraphQLTypes["DateTime"] | undefined,
	less_than_equal?: GraphQLTypes["DateTime"] | undefined,
	less_than?: GraphQLTypes["DateTime"] | undefined,
	like?: GraphQLTypes["DateTime"] | undefined,
	exists?: boolean | undefined
};
	["Post_Banner_createdAt_operator"]: {
		equals?: GraphQLTypes["DateTime"] | undefined,
	not_equals?: GraphQLTypes["DateTime"] | undefined,
	greater_than_equal?: GraphQLTypes["DateTime"] | undefined,
	greater_than?: GraphQLTypes["DateTime"] | undefined,
	less_than_equal?: GraphQLTypes["DateTime"] | undefined,
	less_than?: GraphQLTypes["DateTime"] | undefined,
	like?: GraphQLTypes["DateTime"] | undefined,
	exists?: boolean | undefined
};
	["Post_Banner_url_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Post_Banner_filename_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Post_Banner_mimeType_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Post_Banner_filesize_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Post_Banner_width_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Post_Banner_height_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Post_Banner_focalX_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Post_Banner_focalY_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Post_Banner_sizes__small__url_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Post_Banner_sizes__small__width_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Post_Banner_sizes__small__height_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Post_Banner_sizes__small__mimeType_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Post_Banner_sizes__small__filesize_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Post_Banner_sizes__small__filename_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Post_Banner_sizes__medium__url_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Post_Banner_sizes__medium__width_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Post_Banner_sizes__medium__height_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Post_Banner_sizes__medium__mimeType_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Post_Banner_sizes__medium__filesize_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Post_Banner_sizes__medium__filename_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Post_Banner_sizes__large__url_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Post_Banner_sizes__large__width_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Post_Banner_sizes__large__height_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Post_Banner_sizes__large__mimeType_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Post_Banner_sizes__large__filesize_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Post_Banner_sizes__large__filename_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Post_Banner_sizes__small_jpeg__url_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Post_Banner_sizes__small_jpeg__width_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Post_Banner_sizes__small_jpeg__height_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Post_Banner_sizes__small_jpeg__mimeType_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Post_Banner_sizes__small_jpeg__filesize_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Post_Banner_sizes__small_jpeg__filename_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Post_Banner_sizes__medium_jpeg__url_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Post_Banner_sizes__medium_jpeg__width_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Post_Banner_sizes__medium_jpeg__height_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Post_Banner_sizes__medium_jpeg__mimeType_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Post_Banner_sizes__medium_jpeg__filesize_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Post_Banner_sizes__medium_jpeg__filename_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Post_Banner_sizes__large_jpeg__url_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Post_Banner_sizes__large_jpeg__width_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Post_Banner_sizes__large_jpeg__height_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Post_Banner_sizes__large_jpeg__mimeType_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Post_Banner_sizes__large_jpeg__filesize_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Post_Banner_sizes__large_jpeg__filename_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Post_Banner_id_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Post_Banner_where_and"]: {
		alt?: GraphQLTypes["Post_Banner_alt_operator"] | undefined,
	updatedAt?: GraphQLTypes["Post_Banner_updatedAt_operator"] | undefined,
	createdAt?: GraphQLTypes["Post_Banner_createdAt_operator"] | undefined,
	url?: GraphQLTypes["Post_Banner_url_operator"] | undefined,
	filename?: GraphQLTypes["Post_Banner_filename_operator"] | undefined,
	mimeType?: GraphQLTypes["Post_Banner_mimeType_operator"] | undefined,
	filesize?: GraphQLTypes["Post_Banner_filesize_operator"] | undefined,
	width?: GraphQLTypes["Post_Banner_width_operator"] | undefined,
	height?: GraphQLTypes["Post_Banner_height_operator"] | undefined,
	focalX?: GraphQLTypes["Post_Banner_focalX_operator"] | undefined,
	focalY?: GraphQLTypes["Post_Banner_focalY_operator"] | undefined,
	sizes__small__url?: GraphQLTypes["Post_Banner_sizes__small__url_operator"] | undefined,
	sizes__small__width?: GraphQLTypes["Post_Banner_sizes__small__width_operator"] | undefined,
	sizes__small__height?: GraphQLTypes["Post_Banner_sizes__small__height_operator"] | undefined,
	sizes__small__mimeType?: GraphQLTypes["Post_Banner_sizes__small__mimeType_operator"] | undefined,
	sizes__small__filesize?: GraphQLTypes["Post_Banner_sizes__small__filesize_operator"] | undefined,
	sizes__small__filename?: GraphQLTypes["Post_Banner_sizes__small__filename_operator"] | undefined,
	sizes__medium__url?: GraphQLTypes["Post_Banner_sizes__medium__url_operator"] | undefined,
	sizes__medium__width?: GraphQLTypes["Post_Banner_sizes__medium__width_operator"] | undefined,
	sizes__medium__height?: GraphQLTypes["Post_Banner_sizes__medium__height_operator"] | undefined,
	sizes__medium__mimeType?: GraphQLTypes["Post_Banner_sizes__medium__mimeType_operator"] | undefined,
	sizes__medium__filesize?: GraphQLTypes["Post_Banner_sizes__medium__filesize_operator"] | undefined,
	sizes__medium__filename?: GraphQLTypes["Post_Banner_sizes__medium__filename_operator"] | undefined,
	sizes__large__url?: GraphQLTypes["Post_Banner_sizes__large__url_operator"] | undefined,
	sizes__large__width?: GraphQLTypes["Post_Banner_sizes__large__width_operator"] | undefined,
	sizes__large__height?: GraphQLTypes["Post_Banner_sizes__large__height_operator"] | undefined,
	sizes__large__mimeType?: GraphQLTypes["Post_Banner_sizes__large__mimeType_operator"] | undefined,
	sizes__large__filesize?: GraphQLTypes["Post_Banner_sizes__large__filesize_operator"] | undefined,
	sizes__large__filename?: GraphQLTypes["Post_Banner_sizes__large__filename_operator"] | undefined,
	sizes__small_jpeg__url?: GraphQLTypes["Post_Banner_sizes__small_jpeg__url_operator"] | undefined,
	sizes__small_jpeg__width?: GraphQLTypes["Post_Banner_sizes__small_jpeg__width_operator"] | undefined,
	sizes__small_jpeg__height?: GraphQLTypes["Post_Banner_sizes__small_jpeg__height_operator"] | undefined,
	sizes__small_jpeg__mimeType?: GraphQLTypes["Post_Banner_sizes__small_jpeg__mimeType_operator"] | undefined,
	sizes__small_jpeg__filesize?: GraphQLTypes["Post_Banner_sizes__small_jpeg__filesize_operator"] | undefined,
	sizes__small_jpeg__filename?: GraphQLTypes["Post_Banner_sizes__small_jpeg__filename_operator"] | undefined,
	sizes__medium_jpeg__url?: GraphQLTypes["Post_Banner_sizes__medium_jpeg__url_operator"] | undefined,
	sizes__medium_jpeg__width?: GraphQLTypes["Post_Banner_sizes__medium_jpeg__width_operator"] | undefined,
	sizes__medium_jpeg__height?: GraphQLTypes["Post_Banner_sizes__medium_jpeg__height_operator"] | undefined,
	sizes__medium_jpeg__mimeType?: GraphQLTypes["Post_Banner_sizes__medium_jpeg__mimeType_operator"] | undefined,
	sizes__medium_jpeg__filesize?: GraphQLTypes["Post_Banner_sizes__medium_jpeg__filesize_operator"] | undefined,
	sizes__medium_jpeg__filename?: GraphQLTypes["Post_Banner_sizes__medium_jpeg__filename_operator"] | undefined,
	sizes__large_jpeg__url?: GraphQLTypes["Post_Banner_sizes__large_jpeg__url_operator"] | undefined,
	sizes__large_jpeg__width?: GraphQLTypes["Post_Banner_sizes__large_jpeg__width_operator"] | undefined,
	sizes__large_jpeg__height?: GraphQLTypes["Post_Banner_sizes__large_jpeg__height_operator"] | undefined,
	sizes__large_jpeg__mimeType?: GraphQLTypes["Post_Banner_sizes__large_jpeg__mimeType_operator"] | undefined,
	sizes__large_jpeg__filesize?: GraphQLTypes["Post_Banner_sizes__large_jpeg__filesize_operator"] | undefined,
	sizes__large_jpeg__filename?: GraphQLTypes["Post_Banner_sizes__large_jpeg__filename_operator"] | undefined,
	id?: GraphQLTypes["Post_Banner_id_operator"] | undefined,
	AND?: Array<GraphQLTypes["Post_Banner_where_and"] | undefined> | undefined,
	OR?: Array<GraphQLTypes["Post_Banner_where_or"] | undefined> | undefined
};
	["Post_Banner_where_or"]: {
		alt?: GraphQLTypes["Post_Banner_alt_operator"] | undefined,
	updatedAt?: GraphQLTypes["Post_Banner_updatedAt_operator"] | undefined,
	createdAt?: GraphQLTypes["Post_Banner_createdAt_operator"] | undefined,
	url?: GraphQLTypes["Post_Banner_url_operator"] | undefined,
	filename?: GraphQLTypes["Post_Banner_filename_operator"] | undefined,
	mimeType?: GraphQLTypes["Post_Banner_mimeType_operator"] | undefined,
	filesize?: GraphQLTypes["Post_Banner_filesize_operator"] | undefined,
	width?: GraphQLTypes["Post_Banner_width_operator"] | undefined,
	height?: GraphQLTypes["Post_Banner_height_operator"] | undefined,
	focalX?: GraphQLTypes["Post_Banner_focalX_operator"] | undefined,
	focalY?: GraphQLTypes["Post_Banner_focalY_operator"] | undefined,
	sizes__small__url?: GraphQLTypes["Post_Banner_sizes__small__url_operator"] | undefined,
	sizes__small__width?: GraphQLTypes["Post_Banner_sizes__small__width_operator"] | undefined,
	sizes__small__height?: GraphQLTypes["Post_Banner_sizes__small__height_operator"] | undefined,
	sizes__small__mimeType?: GraphQLTypes["Post_Banner_sizes__small__mimeType_operator"] | undefined,
	sizes__small__filesize?: GraphQLTypes["Post_Banner_sizes__small__filesize_operator"] | undefined,
	sizes__small__filename?: GraphQLTypes["Post_Banner_sizes__small__filename_operator"] | undefined,
	sizes__medium__url?: GraphQLTypes["Post_Banner_sizes__medium__url_operator"] | undefined,
	sizes__medium__width?: GraphQLTypes["Post_Banner_sizes__medium__width_operator"] | undefined,
	sizes__medium__height?: GraphQLTypes["Post_Banner_sizes__medium__height_operator"] | undefined,
	sizes__medium__mimeType?: GraphQLTypes["Post_Banner_sizes__medium__mimeType_operator"] | undefined,
	sizes__medium__filesize?: GraphQLTypes["Post_Banner_sizes__medium__filesize_operator"] | undefined,
	sizes__medium__filename?: GraphQLTypes["Post_Banner_sizes__medium__filename_operator"] | undefined,
	sizes__large__url?: GraphQLTypes["Post_Banner_sizes__large__url_operator"] | undefined,
	sizes__large__width?: GraphQLTypes["Post_Banner_sizes__large__width_operator"] | undefined,
	sizes__large__height?: GraphQLTypes["Post_Banner_sizes__large__height_operator"] | undefined,
	sizes__large__mimeType?: GraphQLTypes["Post_Banner_sizes__large__mimeType_operator"] | undefined,
	sizes__large__filesize?: GraphQLTypes["Post_Banner_sizes__large__filesize_operator"] | undefined,
	sizes__large__filename?: GraphQLTypes["Post_Banner_sizes__large__filename_operator"] | undefined,
	sizes__small_jpeg__url?: GraphQLTypes["Post_Banner_sizes__small_jpeg__url_operator"] | undefined,
	sizes__small_jpeg__width?: GraphQLTypes["Post_Banner_sizes__small_jpeg__width_operator"] | undefined,
	sizes__small_jpeg__height?: GraphQLTypes["Post_Banner_sizes__small_jpeg__height_operator"] | undefined,
	sizes__small_jpeg__mimeType?: GraphQLTypes["Post_Banner_sizes__small_jpeg__mimeType_operator"] | undefined,
	sizes__small_jpeg__filesize?: GraphQLTypes["Post_Banner_sizes__small_jpeg__filesize_operator"] | undefined,
	sizes__small_jpeg__filename?: GraphQLTypes["Post_Banner_sizes__small_jpeg__filename_operator"] | undefined,
	sizes__medium_jpeg__url?: GraphQLTypes["Post_Banner_sizes__medium_jpeg__url_operator"] | undefined,
	sizes__medium_jpeg__width?: GraphQLTypes["Post_Banner_sizes__medium_jpeg__width_operator"] | undefined,
	sizes__medium_jpeg__height?: GraphQLTypes["Post_Banner_sizes__medium_jpeg__height_operator"] | undefined,
	sizes__medium_jpeg__mimeType?: GraphQLTypes["Post_Banner_sizes__medium_jpeg__mimeType_operator"] | undefined,
	sizes__medium_jpeg__filesize?: GraphQLTypes["Post_Banner_sizes__medium_jpeg__filesize_operator"] | undefined,
	sizes__medium_jpeg__filename?: GraphQLTypes["Post_Banner_sizes__medium_jpeg__filename_operator"] | undefined,
	sizes__large_jpeg__url?: GraphQLTypes["Post_Banner_sizes__large_jpeg__url_operator"] | undefined,
	sizes__large_jpeg__width?: GraphQLTypes["Post_Banner_sizes__large_jpeg__width_operator"] | undefined,
	sizes__large_jpeg__height?: GraphQLTypes["Post_Banner_sizes__large_jpeg__height_operator"] | undefined,
	sizes__large_jpeg__mimeType?: GraphQLTypes["Post_Banner_sizes__large_jpeg__mimeType_operator"] | undefined,
	sizes__large_jpeg__filesize?: GraphQLTypes["Post_Banner_sizes__large_jpeg__filesize_operator"] | undefined,
	sizes__large_jpeg__filename?: GraphQLTypes["Post_Banner_sizes__large_jpeg__filename_operator"] | undefined,
	id?: GraphQLTypes["Post_Banner_id_operator"] | undefined,
	AND?: Array<GraphQLTypes["Post_Banner_where_and"] | undefined> | undefined,
	OR?: Array<GraphQLTypes["Post_Banner_where_or"] | undefined> | undefined
};
	["Post_Criteria"]: {
	__typename: "Post_Criteria",
	audiences?: Array<GraphQLTypes["Audience"]> | undefined,
	dates?: Array<GraphQLTypes["Date"]> | undefined,
	categories?: Array<GraphQLTypes["EventCategory"]> | undefined,
	tags?: Array<GraphQLTypes["Tag"]> | undefined
};
	["Posts"]: {
	__typename: "Posts",
	docs?: Array<GraphQLTypes["Post"] | undefined> | undefined,
	hasNextPage?: boolean | undefined,
	hasPrevPage?: boolean | undefined,
	limit?: number | undefined,
	nextPage?: number | undefined,
	offset?: number | undefined,
	page?: number | undefined,
	pagingCounter?: number | undefined,
	prevPage?: number | undefined,
	totalDocs?: number | undefined,
	totalPages?: number | undefined
};
	["Post_where"]: {
		title?: GraphQLTypes["Post_title_operator"] | undefined,
	region?: GraphQLTypes["Post_region_operator"] | undefined,
	authur?: GraphQLTypes["Post_authur_operator"] | undefined,
	publishDateTime?: GraphQLTypes["Post_publishDateTime_operator"] | undefined,
	permalink?: GraphQLTypes["Post_permalink_operator"] | undefined,
	status?: GraphQLTypes["Post_status_operator"] | undefined,
	legacyContent?: GraphQLTypes["Post_legacyContent_operator"] | undefined,
	showLegacyContent?: GraphQLTypes["Post_showLegacyContent_operator"] | undefined,
	blogCategories?: GraphQLTypes["Post_blogCategories_operator"] | undefined,
	thumbnail?: GraphQLTypes["Post_thumbnail_operator"] | undefined,
	banner?: GraphQLTypes["Post_banner_operator"] | undefined,
	criteria__audiences?: GraphQLTypes["Post_criteria__audiences_operator"] | undefined,
	criteria__dates?: GraphQLTypes["Post_criteria__dates_operator"] | undefined,
	criteria__categories?: GraphQLTypes["Post_criteria__categories_operator"] | undefined,
	criteria__tags?: GraphQLTypes["Post_criteria__tags_operator"] | undefined,
	location?: GraphQLTypes["Post_location_operator"] | undefined,
	views?: GraphQLTypes["Post_views_operator"] | undefined,
	homePageInternalClick?: GraphQLTypes["Post_homePageInternalClick_operator"] | undefined,
	homePageImpression?: GraphQLTypes["Post_homePageImpression_operator"] | undefined,
	blogListInternalClick?: GraphQLTypes["Post_blogListInternalClick_operator"] | undefined,
	blogListImpression?: GraphQLTypes["Post_blogListImpression_operator"] | undefined,
	blogDetailInternalClick?: GraphQLTypes["Post_blogDetailInternalClick_operator"] | undefined,
	blogDetailImpression?: GraphQLTypes["Post_blogDetailImpression_operator"] | undefined,
	relatedEventInternalClick?: GraphQLTypes["Post_relatedEventInternalClick_operator"] | undefined,
	relatedEventImpression?: GraphQLTypes["Post_relatedEventImpression_operator"] | undefined,
	latestEventInternalClick?: GraphQLTypes["Post_latestEventInternalClick_operator"] | undefined,
	latestEventImpression?: GraphQLTypes["Post_latestEventImpression_operator"] | undefined,
	internalClick?: GraphQLTypes["Post_internalClick_operator"] | undefined,
	totalImpression?: GraphQLTypes["Post_totalImpression_operator"] | undefined,
	externalClick?: GraphQLTypes["Post_externalClick_operator"] | undefined,
	outboundClick?: GraphQLTypes["Post_outboundClick_operator"] | undefined,
	legacyGallery?: GraphQLTypes["Post_legacyGallery_operator"] | undefined,
	updatedAt?: GraphQLTypes["Post_updatedAt_operator"] | undefined,
	createdAt?: GraphQLTypes["Post_createdAt_operator"] | undefined,
	id?: GraphQLTypes["Post_id_operator"] | undefined,
	AND?: Array<GraphQLTypes["Post_where_and"] | undefined> | undefined,
	OR?: Array<GraphQLTypes["Post_where_or"] | undefined> | undefined
};
	["Post_title_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Post_region_operator"]: {
		equals?: GraphQLTypes["Post_region_Input"] | undefined,
	not_equals?: GraphQLTypes["Post_region_Input"] | undefined,
	in?: Array<GraphQLTypes["Post_region_Input"] | undefined> | undefined,
	not_in?: Array<GraphQLTypes["Post_region_Input"] | undefined> | undefined,
	all?: Array<GraphQLTypes["Post_region_Input"] | undefined> | undefined,
	exists?: boolean | undefined
};
	["Post_region_Input"]: Post_region_Input;
	["Post_authur_operator"]: {
		equals?: GraphQLTypes["JSON"] | undefined,
	not_equals?: GraphQLTypes["JSON"] | undefined,
	in?: Array<GraphQLTypes["JSON"] | undefined> | undefined,
	not_in?: Array<GraphQLTypes["JSON"] | undefined> | undefined,
	all?: Array<GraphQLTypes["JSON"] | undefined> | undefined,
	exists?: boolean | undefined
};
	["Post_publishDateTime_operator"]: {
		equals?: GraphQLTypes["DateTime"] | undefined,
	not_equals?: GraphQLTypes["DateTime"] | undefined,
	greater_than_equal?: GraphQLTypes["DateTime"] | undefined,
	greater_than?: GraphQLTypes["DateTime"] | undefined,
	less_than_equal?: GraphQLTypes["DateTime"] | undefined,
	less_than?: GraphQLTypes["DateTime"] | undefined,
	like?: GraphQLTypes["DateTime"] | undefined,
	exists?: boolean | undefined
};
	["Post_permalink_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Post_status_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined
};
	["Post_legacyContent_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	exists?: boolean | undefined
};
	["Post_showLegacyContent_operator"]: {
		equals?: boolean | undefined,
	not_equals?: boolean | undefined,
	exists?: boolean | undefined
};
	["Post_blogCategories_operator"]: {
		equals?: GraphQLTypes["JSON"] | undefined,
	not_equals?: GraphQLTypes["JSON"] | undefined,
	in?: Array<GraphQLTypes["JSON"] | undefined> | undefined,
	not_in?: Array<GraphQLTypes["JSON"] | undefined> | undefined,
	all?: Array<GraphQLTypes["JSON"] | undefined> | undefined,
	exists?: boolean | undefined
};
	["Post_thumbnail_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	exists?: boolean | undefined
};
	["Post_banner_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	exists?: boolean | undefined
};
	["Post_criteria__audiences_operator"]: {
		equals?: GraphQLTypes["JSON"] | undefined,
	not_equals?: GraphQLTypes["JSON"] | undefined,
	in?: Array<GraphQLTypes["JSON"] | undefined> | undefined,
	not_in?: Array<GraphQLTypes["JSON"] | undefined> | undefined,
	all?: Array<GraphQLTypes["JSON"] | undefined> | undefined,
	exists?: boolean | undefined
};
	["Post_criteria__dates_operator"]: {
		equals?: GraphQLTypes["JSON"] | undefined,
	not_equals?: GraphQLTypes["JSON"] | undefined,
	in?: Array<GraphQLTypes["JSON"] | undefined> | undefined,
	not_in?: Array<GraphQLTypes["JSON"] | undefined> | undefined,
	all?: Array<GraphQLTypes["JSON"] | undefined> | undefined,
	exists?: boolean | undefined
};
	["Post_criteria__categories_operator"]: {
		equals?: GraphQLTypes["JSON"] | undefined,
	not_equals?: GraphQLTypes["JSON"] | undefined,
	in?: Array<GraphQLTypes["JSON"] | undefined> | undefined,
	not_in?: Array<GraphQLTypes["JSON"] | undefined> | undefined,
	all?: Array<GraphQLTypes["JSON"] | undefined> | undefined,
	exists?: boolean | undefined
};
	["Post_criteria__tags_operator"]: {
		equals?: GraphQLTypes["JSON"] | undefined,
	not_equals?: GraphQLTypes["JSON"] | undefined,
	in?: Array<GraphQLTypes["JSON"] | undefined> | undefined,
	not_in?: Array<GraphQLTypes["JSON"] | undefined> | undefined,
	all?: Array<GraphQLTypes["JSON"] | undefined> | undefined,
	exists?: boolean | undefined
};
	["Post_location_operator"]: {
		equals?: GraphQLTypes["JSON"] | undefined,
	not_equals?: GraphQLTypes["JSON"] | undefined,
	in?: Array<GraphQLTypes["JSON"] | undefined> | undefined,
	not_in?: Array<GraphQLTypes["JSON"] | undefined> | undefined,
	all?: Array<GraphQLTypes["JSON"] | undefined> | undefined,
	exists?: boolean | undefined
};
	["Post_views_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Post_homePageInternalClick_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Post_homePageImpression_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Post_blogListInternalClick_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Post_blogListImpression_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Post_blogDetailInternalClick_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Post_blogDetailImpression_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Post_relatedEventInternalClick_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Post_relatedEventImpression_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Post_latestEventInternalClick_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Post_latestEventImpression_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Post_internalClick_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Post_totalImpression_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Post_externalClick_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Post_outboundClick_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Post_legacyGallery_operator"]: {
		equals?: GraphQLTypes["JSON"] | undefined,
	not_equals?: GraphQLTypes["JSON"] | undefined,
	in?: Array<GraphQLTypes["JSON"] | undefined> | undefined,
	not_in?: Array<GraphQLTypes["JSON"] | undefined> | undefined,
	all?: Array<GraphQLTypes["JSON"] | undefined> | undefined,
	exists?: boolean | undefined
};
	["Post_updatedAt_operator"]: {
		equals?: GraphQLTypes["DateTime"] | undefined,
	not_equals?: GraphQLTypes["DateTime"] | undefined,
	greater_than_equal?: GraphQLTypes["DateTime"] | undefined,
	greater_than?: GraphQLTypes["DateTime"] | undefined,
	less_than_equal?: GraphQLTypes["DateTime"] | undefined,
	less_than?: GraphQLTypes["DateTime"] | undefined,
	like?: GraphQLTypes["DateTime"] | undefined,
	exists?: boolean | undefined
};
	["Post_createdAt_operator"]: {
		equals?: GraphQLTypes["DateTime"] | undefined,
	not_equals?: GraphQLTypes["DateTime"] | undefined,
	greater_than_equal?: GraphQLTypes["DateTime"] | undefined,
	greater_than?: GraphQLTypes["DateTime"] | undefined,
	less_than_equal?: GraphQLTypes["DateTime"] | undefined,
	less_than?: GraphQLTypes["DateTime"] | undefined,
	like?: GraphQLTypes["DateTime"] | undefined,
	exists?: boolean | undefined
};
	["Post_id_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Post_where_and"]: {
		title?: GraphQLTypes["Post_title_operator"] | undefined,
	region?: GraphQLTypes["Post_region_operator"] | undefined,
	authur?: GraphQLTypes["Post_authur_operator"] | undefined,
	publishDateTime?: GraphQLTypes["Post_publishDateTime_operator"] | undefined,
	permalink?: GraphQLTypes["Post_permalink_operator"] | undefined,
	status?: GraphQLTypes["Post_status_operator"] | undefined,
	legacyContent?: GraphQLTypes["Post_legacyContent_operator"] | undefined,
	showLegacyContent?: GraphQLTypes["Post_showLegacyContent_operator"] | undefined,
	blogCategories?: GraphQLTypes["Post_blogCategories_operator"] | undefined,
	thumbnail?: GraphQLTypes["Post_thumbnail_operator"] | undefined,
	banner?: GraphQLTypes["Post_banner_operator"] | undefined,
	criteria__audiences?: GraphQLTypes["Post_criteria__audiences_operator"] | undefined,
	criteria__dates?: GraphQLTypes["Post_criteria__dates_operator"] | undefined,
	criteria__categories?: GraphQLTypes["Post_criteria__categories_operator"] | undefined,
	criteria__tags?: GraphQLTypes["Post_criteria__tags_operator"] | undefined,
	location?: GraphQLTypes["Post_location_operator"] | undefined,
	views?: GraphQLTypes["Post_views_operator"] | undefined,
	homePageInternalClick?: GraphQLTypes["Post_homePageInternalClick_operator"] | undefined,
	homePageImpression?: GraphQLTypes["Post_homePageImpression_operator"] | undefined,
	blogListInternalClick?: GraphQLTypes["Post_blogListInternalClick_operator"] | undefined,
	blogListImpression?: GraphQLTypes["Post_blogListImpression_operator"] | undefined,
	blogDetailInternalClick?: GraphQLTypes["Post_blogDetailInternalClick_operator"] | undefined,
	blogDetailImpression?: GraphQLTypes["Post_blogDetailImpression_operator"] | undefined,
	relatedEventInternalClick?: GraphQLTypes["Post_relatedEventInternalClick_operator"] | undefined,
	relatedEventImpression?: GraphQLTypes["Post_relatedEventImpression_operator"] | undefined,
	latestEventInternalClick?: GraphQLTypes["Post_latestEventInternalClick_operator"] | undefined,
	latestEventImpression?: GraphQLTypes["Post_latestEventImpression_operator"] | undefined,
	internalClick?: GraphQLTypes["Post_internalClick_operator"] | undefined,
	totalImpression?: GraphQLTypes["Post_totalImpression_operator"] | undefined,
	externalClick?: GraphQLTypes["Post_externalClick_operator"] | undefined,
	outboundClick?: GraphQLTypes["Post_outboundClick_operator"] | undefined,
	legacyGallery?: GraphQLTypes["Post_legacyGallery_operator"] | undefined,
	updatedAt?: GraphQLTypes["Post_updatedAt_operator"] | undefined,
	createdAt?: GraphQLTypes["Post_createdAt_operator"] | undefined,
	id?: GraphQLTypes["Post_id_operator"] | undefined,
	AND?: Array<GraphQLTypes["Post_where_and"] | undefined> | undefined,
	OR?: Array<GraphQLTypes["Post_where_or"] | undefined> | undefined
};
	["Post_where_or"]: {
		title?: GraphQLTypes["Post_title_operator"] | undefined,
	region?: GraphQLTypes["Post_region_operator"] | undefined,
	authur?: GraphQLTypes["Post_authur_operator"] | undefined,
	publishDateTime?: GraphQLTypes["Post_publishDateTime_operator"] | undefined,
	permalink?: GraphQLTypes["Post_permalink_operator"] | undefined,
	status?: GraphQLTypes["Post_status_operator"] | undefined,
	legacyContent?: GraphQLTypes["Post_legacyContent_operator"] | undefined,
	showLegacyContent?: GraphQLTypes["Post_showLegacyContent_operator"] | undefined,
	blogCategories?: GraphQLTypes["Post_blogCategories_operator"] | undefined,
	thumbnail?: GraphQLTypes["Post_thumbnail_operator"] | undefined,
	banner?: GraphQLTypes["Post_banner_operator"] | undefined,
	criteria__audiences?: GraphQLTypes["Post_criteria__audiences_operator"] | undefined,
	criteria__dates?: GraphQLTypes["Post_criteria__dates_operator"] | undefined,
	criteria__categories?: GraphQLTypes["Post_criteria__categories_operator"] | undefined,
	criteria__tags?: GraphQLTypes["Post_criteria__tags_operator"] | undefined,
	location?: GraphQLTypes["Post_location_operator"] | undefined,
	views?: GraphQLTypes["Post_views_operator"] | undefined,
	homePageInternalClick?: GraphQLTypes["Post_homePageInternalClick_operator"] | undefined,
	homePageImpression?: GraphQLTypes["Post_homePageImpression_operator"] | undefined,
	blogListInternalClick?: GraphQLTypes["Post_blogListInternalClick_operator"] | undefined,
	blogListImpression?: GraphQLTypes["Post_blogListImpression_operator"] | undefined,
	blogDetailInternalClick?: GraphQLTypes["Post_blogDetailInternalClick_operator"] | undefined,
	blogDetailImpression?: GraphQLTypes["Post_blogDetailImpression_operator"] | undefined,
	relatedEventInternalClick?: GraphQLTypes["Post_relatedEventInternalClick_operator"] | undefined,
	relatedEventImpression?: GraphQLTypes["Post_relatedEventImpression_operator"] | undefined,
	latestEventInternalClick?: GraphQLTypes["Post_latestEventInternalClick_operator"] | undefined,
	latestEventImpression?: GraphQLTypes["Post_latestEventImpression_operator"] | undefined,
	internalClick?: GraphQLTypes["Post_internalClick_operator"] | undefined,
	totalImpression?: GraphQLTypes["Post_totalImpression_operator"] | undefined,
	externalClick?: GraphQLTypes["Post_externalClick_operator"] | undefined,
	outboundClick?: GraphQLTypes["Post_outboundClick_operator"] | undefined,
	legacyGallery?: GraphQLTypes["Post_legacyGallery_operator"] | undefined,
	updatedAt?: GraphQLTypes["Post_updatedAt_operator"] | undefined,
	createdAt?: GraphQLTypes["Post_createdAt_operator"] | undefined,
	id?: GraphQLTypes["Post_id_operator"] | undefined,
	AND?: Array<GraphQLTypes["Post_where_and"] | undefined> | undefined,
	OR?: Array<GraphQLTypes["Post_where_or"] | undefined> | undefined
};
	["countPosts"]: {
	__typename: "countPosts",
	totalDocs?: number | undefined
};
	["postsDocAccess"]: {
	__typename: "postsDocAccess",
	fields?: GraphQLTypes["PostsDocAccessFields"] | undefined,
	create?: GraphQLTypes["PostsCreateDocAccess"] | undefined,
	read?: GraphQLTypes["PostsReadDocAccess"] | undefined,
	update?: GraphQLTypes["PostsUpdateDocAccess"] | undefined,
	delete?: GraphQLTypes["PostsDeleteDocAccess"] | undefined
};
	["PostsDocAccessFields"]: {
	__typename: "PostsDocAccessFields",
	title?: GraphQLTypes["PostsDocAccessFields_title"] | undefined,
	region?: GraphQLTypes["PostsDocAccessFields_region"] | undefined,
	authur?: GraphQLTypes["PostsDocAccessFields_authur"] | undefined,
	publishDateTime?: GraphQLTypes["PostsDocAccessFields_publishDateTime"] | undefined,
	permalink?: GraphQLTypes["PostsDocAccessFields_permalink"] | undefined,
	status?: GraphQLTypes["PostsDocAccessFields_status"] | undefined,
	content?: GraphQLTypes["PostsDocAccessFields_content"] | undefined,
	legacyContent?: GraphQLTypes["PostsDocAccessFields_legacyContent"] | undefined,
	showLegacyContent?: GraphQLTypes["PostsDocAccessFields_showLegacyContent"] | undefined,
	blogCategories?: GraphQLTypes["PostsDocAccessFields_blogCategories"] | undefined,
	thumbnail?: GraphQLTypes["PostsDocAccessFields_thumbnail"] | undefined,
	banner?: GraphQLTypes["PostsDocAccessFields_banner"] | undefined,
	criteria?: GraphQLTypes["PostsDocAccessFields_criteria"] | undefined,
	location?: GraphQLTypes["PostsDocAccessFields_location"] | undefined,
	homePageInternalClick?: GraphQLTypes["PostsDocAccessFields_homePageInternalClick"] | undefined,
	homePageImpression?: GraphQLTypes["PostsDocAccessFields_homePageImpression"] | undefined,
	blogListInternalClick?: GraphQLTypes["PostsDocAccessFields_blogListInternalClick"] | undefined,
	blogListImpression?: GraphQLTypes["PostsDocAccessFields_blogListImpression"] | undefined,
	blogDetailInternalClick?: GraphQLTypes["PostsDocAccessFields_blogDetailInternalClick"] | undefined,
	blogDetailImpression?: GraphQLTypes["PostsDocAccessFields_blogDetailImpression"] | undefined,
	relatedEventInternalClick?: GraphQLTypes["PostsDocAccessFields_relatedEventInternalClick"] | undefined,
	relatedEventImpression?: GraphQLTypes["PostsDocAccessFields_relatedEventImpression"] | undefined,
	latestEventInternalClick?: GraphQLTypes["PostsDocAccessFields_latestEventInternalClick"] | undefined,
	latestEventImpression?: GraphQLTypes["PostsDocAccessFields_latestEventImpression"] | undefined,
	internalClick?: GraphQLTypes["PostsDocAccessFields_internalClick"] | undefined,
	totalImpression?: GraphQLTypes["PostsDocAccessFields_totalImpression"] | undefined,
	externalClick?: GraphQLTypes["PostsDocAccessFields_externalClick"] | undefined,
	outboundClick?: GraphQLTypes["PostsDocAccessFields_outboundClick"] | undefined,
	legacyGallery?: GraphQLTypes["PostsDocAccessFields_legacyGallery"] | undefined,
	updatedAt?: GraphQLTypes["PostsDocAccessFields_updatedAt"] | undefined,
	createdAt?: GraphQLTypes["PostsDocAccessFields_createdAt"] | undefined
};
	["PostsDocAccessFields_title"]: {
	__typename: "PostsDocAccessFields_title",
	create?: GraphQLTypes["PostsDocAccessFields_title_Create"] | undefined,
	read?: GraphQLTypes["PostsDocAccessFields_title_Read"] | undefined,
	update?: GraphQLTypes["PostsDocAccessFields_title_Update"] | undefined,
	delete?: GraphQLTypes["PostsDocAccessFields_title_Delete"] | undefined
};
	["PostsDocAccessFields_title_Create"]: {
	__typename: "PostsDocAccessFields_title_Create",
	permission: boolean
};
	["PostsDocAccessFields_title_Read"]: {
	__typename: "PostsDocAccessFields_title_Read",
	permission: boolean
};
	["PostsDocAccessFields_title_Update"]: {
	__typename: "PostsDocAccessFields_title_Update",
	permission: boolean
};
	["PostsDocAccessFields_title_Delete"]: {
	__typename: "PostsDocAccessFields_title_Delete",
	permission: boolean
};
	["PostsDocAccessFields_region"]: {
	__typename: "PostsDocAccessFields_region",
	create?: GraphQLTypes["PostsDocAccessFields_region_Create"] | undefined,
	read?: GraphQLTypes["PostsDocAccessFields_region_Read"] | undefined,
	update?: GraphQLTypes["PostsDocAccessFields_region_Update"] | undefined,
	delete?: GraphQLTypes["PostsDocAccessFields_region_Delete"] | undefined
};
	["PostsDocAccessFields_region_Create"]: {
	__typename: "PostsDocAccessFields_region_Create",
	permission: boolean
};
	["PostsDocAccessFields_region_Read"]: {
	__typename: "PostsDocAccessFields_region_Read",
	permission: boolean
};
	["PostsDocAccessFields_region_Update"]: {
	__typename: "PostsDocAccessFields_region_Update",
	permission: boolean
};
	["PostsDocAccessFields_region_Delete"]: {
	__typename: "PostsDocAccessFields_region_Delete",
	permission: boolean
};
	["PostsDocAccessFields_authur"]: {
	__typename: "PostsDocAccessFields_authur",
	create?: GraphQLTypes["PostsDocAccessFields_authur_Create"] | undefined,
	read?: GraphQLTypes["PostsDocAccessFields_authur_Read"] | undefined,
	update?: GraphQLTypes["PostsDocAccessFields_authur_Update"] | undefined,
	delete?: GraphQLTypes["PostsDocAccessFields_authur_Delete"] | undefined
};
	["PostsDocAccessFields_authur_Create"]: {
	__typename: "PostsDocAccessFields_authur_Create",
	permission: boolean
};
	["PostsDocAccessFields_authur_Read"]: {
	__typename: "PostsDocAccessFields_authur_Read",
	permission: boolean
};
	["PostsDocAccessFields_authur_Update"]: {
	__typename: "PostsDocAccessFields_authur_Update",
	permission: boolean
};
	["PostsDocAccessFields_authur_Delete"]: {
	__typename: "PostsDocAccessFields_authur_Delete",
	permission: boolean
};
	["PostsDocAccessFields_publishDateTime"]: {
	__typename: "PostsDocAccessFields_publishDateTime",
	create?: GraphQLTypes["PostsDocAccessFields_publishDateTime_Create"] | undefined,
	read?: GraphQLTypes["PostsDocAccessFields_publishDateTime_Read"] | undefined,
	update?: GraphQLTypes["PostsDocAccessFields_publishDateTime_Update"] | undefined,
	delete?: GraphQLTypes["PostsDocAccessFields_publishDateTime_Delete"] | undefined
};
	["PostsDocAccessFields_publishDateTime_Create"]: {
	__typename: "PostsDocAccessFields_publishDateTime_Create",
	permission: boolean
};
	["PostsDocAccessFields_publishDateTime_Read"]: {
	__typename: "PostsDocAccessFields_publishDateTime_Read",
	permission: boolean
};
	["PostsDocAccessFields_publishDateTime_Update"]: {
	__typename: "PostsDocAccessFields_publishDateTime_Update",
	permission: boolean
};
	["PostsDocAccessFields_publishDateTime_Delete"]: {
	__typename: "PostsDocAccessFields_publishDateTime_Delete",
	permission: boolean
};
	["PostsDocAccessFields_permalink"]: {
	__typename: "PostsDocAccessFields_permalink",
	create?: GraphQLTypes["PostsDocAccessFields_permalink_Create"] | undefined,
	read?: GraphQLTypes["PostsDocAccessFields_permalink_Read"] | undefined,
	update?: GraphQLTypes["PostsDocAccessFields_permalink_Update"] | undefined,
	delete?: GraphQLTypes["PostsDocAccessFields_permalink_Delete"] | undefined
};
	["PostsDocAccessFields_permalink_Create"]: {
	__typename: "PostsDocAccessFields_permalink_Create",
	permission: boolean
};
	["PostsDocAccessFields_permalink_Read"]: {
	__typename: "PostsDocAccessFields_permalink_Read",
	permission: boolean
};
	["PostsDocAccessFields_permalink_Update"]: {
	__typename: "PostsDocAccessFields_permalink_Update",
	permission: boolean
};
	["PostsDocAccessFields_permalink_Delete"]: {
	__typename: "PostsDocAccessFields_permalink_Delete",
	permission: boolean
};
	["PostsDocAccessFields_status"]: {
	__typename: "PostsDocAccessFields_status",
	create?: GraphQLTypes["PostsDocAccessFields_status_Create"] | undefined,
	read?: GraphQLTypes["PostsDocAccessFields_status_Read"] | undefined,
	update?: GraphQLTypes["PostsDocAccessFields_status_Update"] | undefined,
	delete?: GraphQLTypes["PostsDocAccessFields_status_Delete"] | undefined
};
	["PostsDocAccessFields_status_Create"]: {
	__typename: "PostsDocAccessFields_status_Create",
	permission: boolean
};
	["PostsDocAccessFields_status_Read"]: {
	__typename: "PostsDocAccessFields_status_Read",
	permission: boolean
};
	["PostsDocAccessFields_status_Update"]: {
	__typename: "PostsDocAccessFields_status_Update",
	permission: boolean
};
	["PostsDocAccessFields_status_Delete"]: {
	__typename: "PostsDocAccessFields_status_Delete",
	permission: boolean
};
	["PostsDocAccessFields_content"]: {
	__typename: "PostsDocAccessFields_content",
	create?: GraphQLTypes["PostsDocAccessFields_content_Create"] | undefined,
	read?: GraphQLTypes["PostsDocAccessFields_content_Read"] | undefined,
	update?: GraphQLTypes["PostsDocAccessFields_content_Update"] | undefined,
	delete?: GraphQLTypes["PostsDocAccessFields_content_Delete"] | undefined
};
	["PostsDocAccessFields_content_Create"]: {
	__typename: "PostsDocAccessFields_content_Create",
	permission: boolean
};
	["PostsDocAccessFields_content_Read"]: {
	__typename: "PostsDocAccessFields_content_Read",
	permission: boolean
};
	["PostsDocAccessFields_content_Update"]: {
	__typename: "PostsDocAccessFields_content_Update",
	permission: boolean
};
	["PostsDocAccessFields_content_Delete"]: {
	__typename: "PostsDocAccessFields_content_Delete",
	permission: boolean
};
	["PostsDocAccessFields_legacyContent"]: {
	__typename: "PostsDocAccessFields_legacyContent",
	create?: GraphQLTypes["PostsDocAccessFields_legacyContent_Create"] | undefined,
	read?: GraphQLTypes["PostsDocAccessFields_legacyContent_Read"] | undefined,
	update?: GraphQLTypes["PostsDocAccessFields_legacyContent_Update"] | undefined,
	delete?: GraphQLTypes["PostsDocAccessFields_legacyContent_Delete"] | undefined
};
	["PostsDocAccessFields_legacyContent_Create"]: {
	__typename: "PostsDocAccessFields_legacyContent_Create",
	permission: boolean
};
	["PostsDocAccessFields_legacyContent_Read"]: {
	__typename: "PostsDocAccessFields_legacyContent_Read",
	permission: boolean
};
	["PostsDocAccessFields_legacyContent_Update"]: {
	__typename: "PostsDocAccessFields_legacyContent_Update",
	permission: boolean
};
	["PostsDocAccessFields_legacyContent_Delete"]: {
	__typename: "PostsDocAccessFields_legacyContent_Delete",
	permission: boolean
};
	["PostsDocAccessFields_showLegacyContent"]: {
	__typename: "PostsDocAccessFields_showLegacyContent",
	create?: GraphQLTypes["PostsDocAccessFields_showLegacyContent_Create"] | undefined,
	read?: GraphQLTypes["PostsDocAccessFields_showLegacyContent_Read"] | undefined,
	update?: GraphQLTypes["PostsDocAccessFields_showLegacyContent_Update"] | undefined,
	delete?: GraphQLTypes["PostsDocAccessFields_showLegacyContent_Delete"] | undefined
};
	["PostsDocAccessFields_showLegacyContent_Create"]: {
	__typename: "PostsDocAccessFields_showLegacyContent_Create",
	permission: boolean
};
	["PostsDocAccessFields_showLegacyContent_Read"]: {
	__typename: "PostsDocAccessFields_showLegacyContent_Read",
	permission: boolean
};
	["PostsDocAccessFields_showLegacyContent_Update"]: {
	__typename: "PostsDocAccessFields_showLegacyContent_Update",
	permission: boolean
};
	["PostsDocAccessFields_showLegacyContent_Delete"]: {
	__typename: "PostsDocAccessFields_showLegacyContent_Delete",
	permission: boolean
};
	["PostsDocAccessFields_blogCategories"]: {
	__typename: "PostsDocAccessFields_blogCategories",
	create?: GraphQLTypes["PostsDocAccessFields_blogCategories_Create"] | undefined,
	read?: GraphQLTypes["PostsDocAccessFields_blogCategories_Read"] | undefined,
	update?: GraphQLTypes["PostsDocAccessFields_blogCategories_Update"] | undefined,
	delete?: GraphQLTypes["PostsDocAccessFields_blogCategories_Delete"] | undefined
};
	["PostsDocAccessFields_blogCategories_Create"]: {
	__typename: "PostsDocAccessFields_blogCategories_Create",
	permission: boolean
};
	["PostsDocAccessFields_blogCategories_Read"]: {
	__typename: "PostsDocAccessFields_blogCategories_Read",
	permission: boolean
};
	["PostsDocAccessFields_blogCategories_Update"]: {
	__typename: "PostsDocAccessFields_blogCategories_Update",
	permission: boolean
};
	["PostsDocAccessFields_blogCategories_Delete"]: {
	__typename: "PostsDocAccessFields_blogCategories_Delete",
	permission: boolean
};
	["PostsDocAccessFields_thumbnail"]: {
	__typename: "PostsDocAccessFields_thumbnail",
	create?: GraphQLTypes["PostsDocAccessFields_thumbnail_Create"] | undefined,
	read?: GraphQLTypes["PostsDocAccessFields_thumbnail_Read"] | undefined,
	update?: GraphQLTypes["PostsDocAccessFields_thumbnail_Update"] | undefined,
	delete?: GraphQLTypes["PostsDocAccessFields_thumbnail_Delete"] | undefined
};
	["PostsDocAccessFields_thumbnail_Create"]: {
	__typename: "PostsDocAccessFields_thumbnail_Create",
	permission: boolean
};
	["PostsDocAccessFields_thumbnail_Read"]: {
	__typename: "PostsDocAccessFields_thumbnail_Read",
	permission: boolean
};
	["PostsDocAccessFields_thumbnail_Update"]: {
	__typename: "PostsDocAccessFields_thumbnail_Update",
	permission: boolean
};
	["PostsDocAccessFields_thumbnail_Delete"]: {
	__typename: "PostsDocAccessFields_thumbnail_Delete",
	permission: boolean
};
	["PostsDocAccessFields_banner"]: {
	__typename: "PostsDocAccessFields_banner",
	create?: GraphQLTypes["PostsDocAccessFields_banner_Create"] | undefined,
	read?: GraphQLTypes["PostsDocAccessFields_banner_Read"] | undefined,
	update?: GraphQLTypes["PostsDocAccessFields_banner_Update"] | undefined,
	delete?: GraphQLTypes["PostsDocAccessFields_banner_Delete"] | undefined
};
	["PostsDocAccessFields_banner_Create"]: {
	__typename: "PostsDocAccessFields_banner_Create",
	permission: boolean
};
	["PostsDocAccessFields_banner_Read"]: {
	__typename: "PostsDocAccessFields_banner_Read",
	permission: boolean
};
	["PostsDocAccessFields_banner_Update"]: {
	__typename: "PostsDocAccessFields_banner_Update",
	permission: boolean
};
	["PostsDocAccessFields_banner_Delete"]: {
	__typename: "PostsDocAccessFields_banner_Delete",
	permission: boolean
};
	["PostsDocAccessFields_criteria"]: {
	__typename: "PostsDocAccessFields_criteria",
	create?: GraphQLTypes["PostsDocAccessFields_criteria_Create"] | undefined,
	read?: GraphQLTypes["PostsDocAccessFields_criteria_Read"] | undefined,
	update?: GraphQLTypes["PostsDocAccessFields_criteria_Update"] | undefined,
	delete?: GraphQLTypes["PostsDocAccessFields_criteria_Delete"] | undefined,
	fields?: GraphQLTypes["PostsDocAccessFields_criteria_Fields"] | undefined
};
	["PostsDocAccessFields_criteria_Create"]: {
	__typename: "PostsDocAccessFields_criteria_Create",
	permission: boolean
};
	["PostsDocAccessFields_criteria_Read"]: {
	__typename: "PostsDocAccessFields_criteria_Read",
	permission: boolean
};
	["PostsDocAccessFields_criteria_Update"]: {
	__typename: "PostsDocAccessFields_criteria_Update",
	permission: boolean
};
	["PostsDocAccessFields_criteria_Delete"]: {
	__typename: "PostsDocAccessFields_criteria_Delete",
	permission: boolean
};
	["PostsDocAccessFields_criteria_Fields"]: {
	__typename: "PostsDocAccessFields_criteria_Fields",
	audiences?: GraphQLTypes["PostsDocAccessFields_criteria_audiences"] | undefined,
	dates?: GraphQLTypes["PostsDocAccessFields_criteria_dates"] | undefined,
	categories?: GraphQLTypes["PostsDocAccessFields_criteria_categories"] | undefined,
	tags?: GraphQLTypes["PostsDocAccessFields_criteria_tags"] | undefined
};
	["PostsDocAccessFields_criteria_audiences"]: {
	__typename: "PostsDocAccessFields_criteria_audiences",
	create?: GraphQLTypes["PostsDocAccessFields_criteria_audiences_Create"] | undefined,
	read?: GraphQLTypes["PostsDocAccessFields_criteria_audiences_Read"] | undefined,
	update?: GraphQLTypes["PostsDocAccessFields_criteria_audiences_Update"] | undefined,
	delete?: GraphQLTypes["PostsDocAccessFields_criteria_audiences_Delete"] | undefined
};
	["PostsDocAccessFields_criteria_audiences_Create"]: {
	__typename: "PostsDocAccessFields_criteria_audiences_Create",
	permission: boolean
};
	["PostsDocAccessFields_criteria_audiences_Read"]: {
	__typename: "PostsDocAccessFields_criteria_audiences_Read",
	permission: boolean
};
	["PostsDocAccessFields_criteria_audiences_Update"]: {
	__typename: "PostsDocAccessFields_criteria_audiences_Update",
	permission: boolean
};
	["PostsDocAccessFields_criteria_audiences_Delete"]: {
	__typename: "PostsDocAccessFields_criteria_audiences_Delete",
	permission: boolean
};
	["PostsDocAccessFields_criteria_dates"]: {
	__typename: "PostsDocAccessFields_criteria_dates",
	create?: GraphQLTypes["PostsDocAccessFields_criteria_dates_Create"] | undefined,
	read?: GraphQLTypes["PostsDocAccessFields_criteria_dates_Read"] | undefined,
	update?: GraphQLTypes["PostsDocAccessFields_criteria_dates_Update"] | undefined,
	delete?: GraphQLTypes["PostsDocAccessFields_criteria_dates_Delete"] | undefined
};
	["PostsDocAccessFields_criteria_dates_Create"]: {
	__typename: "PostsDocAccessFields_criteria_dates_Create",
	permission: boolean
};
	["PostsDocAccessFields_criteria_dates_Read"]: {
	__typename: "PostsDocAccessFields_criteria_dates_Read",
	permission: boolean
};
	["PostsDocAccessFields_criteria_dates_Update"]: {
	__typename: "PostsDocAccessFields_criteria_dates_Update",
	permission: boolean
};
	["PostsDocAccessFields_criteria_dates_Delete"]: {
	__typename: "PostsDocAccessFields_criteria_dates_Delete",
	permission: boolean
};
	["PostsDocAccessFields_criteria_categories"]: {
	__typename: "PostsDocAccessFields_criteria_categories",
	create?: GraphQLTypes["PostsDocAccessFields_criteria_categories_Create"] | undefined,
	read?: GraphQLTypes["PostsDocAccessFields_criteria_categories_Read"] | undefined,
	update?: GraphQLTypes["PostsDocAccessFields_criteria_categories_Update"] | undefined,
	delete?: GraphQLTypes["PostsDocAccessFields_criteria_categories_Delete"] | undefined
};
	["PostsDocAccessFields_criteria_categories_Create"]: {
	__typename: "PostsDocAccessFields_criteria_categories_Create",
	permission: boolean
};
	["PostsDocAccessFields_criteria_categories_Read"]: {
	__typename: "PostsDocAccessFields_criteria_categories_Read",
	permission: boolean
};
	["PostsDocAccessFields_criteria_categories_Update"]: {
	__typename: "PostsDocAccessFields_criteria_categories_Update",
	permission: boolean
};
	["PostsDocAccessFields_criteria_categories_Delete"]: {
	__typename: "PostsDocAccessFields_criteria_categories_Delete",
	permission: boolean
};
	["PostsDocAccessFields_criteria_tags"]: {
	__typename: "PostsDocAccessFields_criteria_tags",
	create?: GraphQLTypes["PostsDocAccessFields_criteria_tags_Create"] | undefined,
	read?: GraphQLTypes["PostsDocAccessFields_criteria_tags_Read"] | undefined,
	update?: GraphQLTypes["PostsDocAccessFields_criteria_tags_Update"] | undefined,
	delete?: GraphQLTypes["PostsDocAccessFields_criteria_tags_Delete"] | undefined
};
	["PostsDocAccessFields_criteria_tags_Create"]: {
	__typename: "PostsDocAccessFields_criteria_tags_Create",
	permission: boolean
};
	["PostsDocAccessFields_criteria_tags_Read"]: {
	__typename: "PostsDocAccessFields_criteria_tags_Read",
	permission: boolean
};
	["PostsDocAccessFields_criteria_tags_Update"]: {
	__typename: "PostsDocAccessFields_criteria_tags_Update",
	permission: boolean
};
	["PostsDocAccessFields_criteria_tags_Delete"]: {
	__typename: "PostsDocAccessFields_criteria_tags_Delete",
	permission: boolean
};
	["PostsDocAccessFields_location"]: {
	__typename: "PostsDocAccessFields_location",
	create?: GraphQLTypes["PostsDocAccessFields_location_Create"] | undefined,
	read?: GraphQLTypes["PostsDocAccessFields_location_Read"] | undefined,
	update?: GraphQLTypes["PostsDocAccessFields_location_Update"] | undefined,
	delete?: GraphQLTypes["PostsDocAccessFields_location_Delete"] | undefined
};
	["PostsDocAccessFields_location_Create"]: {
	__typename: "PostsDocAccessFields_location_Create",
	permission: boolean
};
	["PostsDocAccessFields_location_Read"]: {
	__typename: "PostsDocAccessFields_location_Read",
	permission: boolean
};
	["PostsDocAccessFields_location_Update"]: {
	__typename: "PostsDocAccessFields_location_Update",
	permission: boolean
};
	["PostsDocAccessFields_location_Delete"]: {
	__typename: "PostsDocAccessFields_location_Delete",
	permission: boolean
};
	["PostsDocAccessFields_homePageInternalClick"]: {
	__typename: "PostsDocAccessFields_homePageInternalClick",
	create?: GraphQLTypes["PostsDocAccessFields_homePageInternalClick_Create"] | undefined,
	read?: GraphQLTypes["PostsDocAccessFields_homePageInternalClick_Read"] | undefined,
	update?: GraphQLTypes["PostsDocAccessFields_homePageInternalClick_Update"] | undefined,
	delete?: GraphQLTypes["PostsDocAccessFields_homePageInternalClick_Delete"] | undefined
};
	["PostsDocAccessFields_homePageInternalClick_Create"]: {
	__typename: "PostsDocAccessFields_homePageInternalClick_Create",
	permission: boolean
};
	["PostsDocAccessFields_homePageInternalClick_Read"]: {
	__typename: "PostsDocAccessFields_homePageInternalClick_Read",
	permission: boolean
};
	["PostsDocAccessFields_homePageInternalClick_Update"]: {
	__typename: "PostsDocAccessFields_homePageInternalClick_Update",
	permission: boolean
};
	["PostsDocAccessFields_homePageInternalClick_Delete"]: {
	__typename: "PostsDocAccessFields_homePageInternalClick_Delete",
	permission: boolean
};
	["PostsDocAccessFields_homePageImpression"]: {
	__typename: "PostsDocAccessFields_homePageImpression",
	create?: GraphQLTypes["PostsDocAccessFields_homePageImpression_Create"] | undefined,
	read?: GraphQLTypes["PostsDocAccessFields_homePageImpression_Read"] | undefined,
	update?: GraphQLTypes["PostsDocAccessFields_homePageImpression_Update"] | undefined,
	delete?: GraphQLTypes["PostsDocAccessFields_homePageImpression_Delete"] | undefined
};
	["PostsDocAccessFields_homePageImpression_Create"]: {
	__typename: "PostsDocAccessFields_homePageImpression_Create",
	permission: boolean
};
	["PostsDocAccessFields_homePageImpression_Read"]: {
	__typename: "PostsDocAccessFields_homePageImpression_Read",
	permission: boolean
};
	["PostsDocAccessFields_homePageImpression_Update"]: {
	__typename: "PostsDocAccessFields_homePageImpression_Update",
	permission: boolean
};
	["PostsDocAccessFields_homePageImpression_Delete"]: {
	__typename: "PostsDocAccessFields_homePageImpression_Delete",
	permission: boolean
};
	["PostsDocAccessFields_blogListInternalClick"]: {
	__typename: "PostsDocAccessFields_blogListInternalClick",
	create?: GraphQLTypes["PostsDocAccessFields_blogListInternalClick_Create"] | undefined,
	read?: GraphQLTypes["PostsDocAccessFields_blogListInternalClick_Read"] | undefined,
	update?: GraphQLTypes["PostsDocAccessFields_blogListInternalClick_Update"] | undefined,
	delete?: GraphQLTypes["PostsDocAccessFields_blogListInternalClick_Delete"] | undefined
};
	["PostsDocAccessFields_blogListInternalClick_Create"]: {
	__typename: "PostsDocAccessFields_blogListInternalClick_Create",
	permission: boolean
};
	["PostsDocAccessFields_blogListInternalClick_Read"]: {
	__typename: "PostsDocAccessFields_blogListInternalClick_Read",
	permission: boolean
};
	["PostsDocAccessFields_blogListInternalClick_Update"]: {
	__typename: "PostsDocAccessFields_blogListInternalClick_Update",
	permission: boolean
};
	["PostsDocAccessFields_blogListInternalClick_Delete"]: {
	__typename: "PostsDocAccessFields_blogListInternalClick_Delete",
	permission: boolean
};
	["PostsDocAccessFields_blogListImpression"]: {
	__typename: "PostsDocAccessFields_blogListImpression",
	create?: GraphQLTypes["PostsDocAccessFields_blogListImpression_Create"] | undefined,
	read?: GraphQLTypes["PostsDocAccessFields_blogListImpression_Read"] | undefined,
	update?: GraphQLTypes["PostsDocAccessFields_blogListImpression_Update"] | undefined,
	delete?: GraphQLTypes["PostsDocAccessFields_blogListImpression_Delete"] | undefined
};
	["PostsDocAccessFields_blogListImpression_Create"]: {
	__typename: "PostsDocAccessFields_blogListImpression_Create",
	permission: boolean
};
	["PostsDocAccessFields_blogListImpression_Read"]: {
	__typename: "PostsDocAccessFields_blogListImpression_Read",
	permission: boolean
};
	["PostsDocAccessFields_blogListImpression_Update"]: {
	__typename: "PostsDocAccessFields_blogListImpression_Update",
	permission: boolean
};
	["PostsDocAccessFields_blogListImpression_Delete"]: {
	__typename: "PostsDocAccessFields_blogListImpression_Delete",
	permission: boolean
};
	["PostsDocAccessFields_blogDetailInternalClick"]: {
	__typename: "PostsDocAccessFields_blogDetailInternalClick",
	create?: GraphQLTypes["PostsDocAccessFields_blogDetailInternalClick_Create"] | undefined,
	read?: GraphQLTypes["PostsDocAccessFields_blogDetailInternalClick_Read"] | undefined,
	update?: GraphQLTypes["PostsDocAccessFields_blogDetailInternalClick_Update"] | undefined,
	delete?: GraphQLTypes["PostsDocAccessFields_blogDetailInternalClick_Delete"] | undefined
};
	["PostsDocAccessFields_blogDetailInternalClick_Create"]: {
	__typename: "PostsDocAccessFields_blogDetailInternalClick_Create",
	permission: boolean
};
	["PostsDocAccessFields_blogDetailInternalClick_Read"]: {
	__typename: "PostsDocAccessFields_blogDetailInternalClick_Read",
	permission: boolean
};
	["PostsDocAccessFields_blogDetailInternalClick_Update"]: {
	__typename: "PostsDocAccessFields_blogDetailInternalClick_Update",
	permission: boolean
};
	["PostsDocAccessFields_blogDetailInternalClick_Delete"]: {
	__typename: "PostsDocAccessFields_blogDetailInternalClick_Delete",
	permission: boolean
};
	["PostsDocAccessFields_blogDetailImpression"]: {
	__typename: "PostsDocAccessFields_blogDetailImpression",
	create?: GraphQLTypes["PostsDocAccessFields_blogDetailImpression_Create"] | undefined,
	read?: GraphQLTypes["PostsDocAccessFields_blogDetailImpression_Read"] | undefined,
	update?: GraphQLTypes["PostsDocAccessFields_blogDetailImpression_Update"] | undefined,
	delete?: GraphQLTypes["PostsDocAccessFields_blogDetailImpression_Delete"] | undefined
};
	["PostsDocAccessFields_blogDetailImpression_Create"]: {
	__typename: "PostsDocAccessFields_blogDetailImpression_Create",
	permission: boolean
};
	["PostsDocAccessFields_blogDetailImpression_Read"]: {
	__typename: "PostsDocAccessFields_blogDetailImpression_Read",
	permission: boolean
};
	["PostsDocAccessFields_blogDetailImpression_Update"]: {
	__typename: "PostsDocAccessFields_blogDetailImpression_Update",
	permission: boolean
};
	["PostsDocAccessFields_blogDetailImpression_Delete"]: {
	__typename: "PostsDocAccessFields_blogDetailImpression_Delete",
	permission: boolean
};
	["PostsDocAccessFields_relatedEventInternalClick"]: {
	__typename: "PostsDocAccessFields_relatedEventInternalClick",
	create?: GraphQLTypes["PostsDocAccessFields_relatedEventInternalClick_Create"] | undefined,
	read?: GraphQLTypes["PostsDocAccessFields_relatedEventInternalClick_Read"] | undefined,
	update?: GraphQLTypes["PostsDocAccessFields_relatedEventInternalClick_Update"] | undefined,
	delete?: GraphQLTypes["PostsDocAccessFields_relatedEventInternalClick_Delete"] | undefined
};
	["PostsDocAccessFields_relatedEventInternalClick_Create"]: {
	__typename: "PostsDocAccessFields_relatedEventInternalClick_Create",
	permission: boolean
};
	["PostsDocAccessFields_relatedEventInternalClick_Read"]: {
	__typename: "PostsDocAccessFields_relatedEventInternalClick_Read",
	permission: boolean
};
	["PostsDocAccessFields_relatedEventInternalClick_Update"]: {
	__typename: "PostsDocAccessFields_relatedEventInternalClick_Update",
	permission: boolean
};
	["PostsDocAccessFields_relatedEventInternalClick_Delete"]: {
	__typename: "PostsDocAccessFields_relatedEventInternalClick_Delete",
	permission: boolean
};
	["PostsDocAccessFields_relatedEventImpression"]: {
	__typename: "PostsDocAccessFields_relatedEventImpression",
	create?: GraphQLTypes["PostsDocAccessFields_relatedEventImpression_Create"] | undefined,
	read?: GraphQLTypes["PostsDocAccessFields_relatedEventImpression_Read"] | undefined,
	update?: GraphQLTypes["PostsDocAccessFields_relatedEventImpression_Update"] | undefined,
	delete?: GraphQLTypes["PostsDocAccessFields_relatedEventImpression_Delete"] | undefined
};
	["PostsDocAccessFields_relatedEventImpression_Create"]: {
	__typename: "PostsDocAccessFields_relatedEventImpression_Create",
	permission: boolean
};
	["PostsDocAccessFields_relatedEventImpression_Read"]: {
	__typename: "PostsDocAccessFields_relatedEventImpression_Read",
	permission: boolean
};
	["PostsDocAccessFields_relatedEventImpression_Update"]: {
	__typename: "PostsDocAccessFields_relatedEventImpression_Update",
	permission: boolean
};
	["PostsDocAccessFields_relatedEventImpression_Delete"]: {
	__typename: "PostsDocAccessFields_relatedEventImpression_Delete",
	permission: boolean
};
	["PostsDocAccessFields_latestEventInternalClick"]: {
	__typename: "PostsDocAccessFields_latestEventInternalClick",
	create?: GraphQLTypes["PostsDocAccessFields_latestEventInternalClick_Create"] | undefined,
	read?: GraphQLTypes["PostsDocAccessFields_latestEventInternalClick_Read"] | undefined,
	update?: GraphQLTypes["PostsDocAccessFields_latestEventInternalClick_Update"] | undefined,
	delete?: GraphQLTypes["PostsDocAccessFields_latestEventInternalClick_Delete"] | undefined
};
	["PostsDocAccessFields_latestEventInternalClick_Create"]: {
	__typename: "PostsDocAccessFields_latestEventInternalClick_Create",
	permission: boolean
};
	["PostsDocAccessFields_latestEventInternalClick_Read"]: {
	__typename: "PostsDocAccessFields_latestEventInternalClick_Read",
	permission: boolean
};
	["PostsDocAccessFields_latestEventInternalClick_Update"]: {
	__typename: "PostsDocAccessFields_latestEventInternalClick_Update",
	permission: boolean
};
	["PostsDocAccessFields_latestEventInternalClick_Delete"]: {
	__typename: "PostsDocAccessFields_latestEventInternalClick_Delete",
	permission: boolean
};
	["PostsDocAccessFields_latestEventImpression"]: {
	__typename: "PostsDocAccessFields_latestEventImpression",
	create?: GraphQLTypes["PostsDocAccessFields_latestEventImpression_Create"] | undefined,
	read?: GraphQLTypes["PostsDocAccessFields_latestEventImpression_Read"] | undefined,
	update?: GraphQLTypes["PostsDocAccessFields_latestEventImpression_Update"] | undefined,
	delete?: GraphQLTypes["PostsDocAccessFields_latestEventImpression_Delete"] | undefined
};
	["PostsDocAccessFields_latestEventImpression_Create"]: {
	__typename: "PostsDocAccessFields_latestEventImpression_Create",
	permission: boolean
};
	["PostsDocAccessFields_latestEventImpression_Read"]: {
	__typename: "PostsDocAccessFields_latestEventImpression_Read",
	permission: boolean
};
	["PostsDocAccessFields_latestEventImpression_Update"]: {
	__typename: "PostsDocAccessFields_latestEventImpression_Update",
	permission: boolean
};
	["PostsDocAccessFields_latestEventImpression_Delete"]: {
	__typename: "PostsDocAccessFields_latestEventImpression_Delete",
	permission: boolean
};
	["PostsDocAccessFields_internalClick"]: {
	__typename: "PostsDocAccessFields_internalClick",
	create?: GraphQLTypes["PostsDocAccessFields_internalClick_Create"] | undefined,
	read?: GraphQLTypes["PostsDocAccessFields_internalClick_Read"] | undefined,
	update?: GraphQLTypes["PostsDocAccessFields_internalClick_Update"] | undefined,
	delete?: GraphQLTypes["PostsDocAccessFields_internalClick_Delete"] | undefined
};
	["PostsDocAccessFields_internalClick_Create"]: {
	__typename: "PostsDocAccessFields_internalClick_Create",
	permission: boolean
};
	["PostsDocAccessFields_internalClick_Read"]: {
	__typename: "PostsDocAccessFields_internalClick_Read",
	permission: boolean
};
	["PostsDocAccessFields_internalClick_Update"]: {
	__typename: "PostsDocAccessFields_internalClick_Update",
	permission: boolean
};
	["PostsDocAccessFields_internalClick_Delete"]: {
	__typename: "PostsDocAccessFields_internalClick_Delete",
	permission: boolean
};
	["PostsDocAccessFields_totalImpression"]: {
	__typename: "PostsDocAccessFields_totalImpression",
	create?: GraphQLTypes["PostsDocAccessFields_totalImpression_Create"] | undefined,
	read?: GraphQLTypes["PostsDocAccessFields_totalImpression_Read"] | undefined,
	update?: GraphQLTypes["PostsDocAccessFields_totalImpression_Update"] | undefined,
	delete?: GraphQLTypes["PostsDocAccessFields_totalImpression_Delete"] | undefined
};
	["PostsDocAccessFields_totalImpression_Create"]: {
	__typename: "PostsDocAccessFields_totalImpression_Create",
	permission: boolean
};
	["PostsDocAccessFields_totalImpression_Read"]: {
	__typename: "PostsDocAccessFields_totalImpression_Read",
	permission: boolean
};
	["PostsDocAccessFields_totalImpression_Update"]: {
	__typename: "PostsDocAccessFields_totalImpression_Update",
	permission: boolean
};
	["PostsDocAccessFields_totalImpression_Delete"]: {
	__typename: "PostsDocAccessFields_totalImpression_Delete",
	permission: boolean
};
	["PostsDocAccessFields_externalClick"]: {
	__typename: "PostsDocAccessFields_externalClick",
	create?: GraphQLTypes["PostsDocAccessFields_externalClick_Create"] | undefined,
	read?: GraphQLTypes["PostsDocAccessFields_externalClick_Read"] | undefined,
	update?: GraphQLTypes["PostsDocAccessFields_externalClick_Update"] | undefined,
	delete?: GraphQLTypes["PostsDocAccessFields_externalClick_Delete"] | undefined
};
	["PostsDocAccessFields_externalClick_Create"]: {
	__typename: "PostsDocAccessFields_externalClick_Create",
	permission: boolean
};
	["PostsDocAccessFields_externalClick_Read"]: {
	__typename: "PostsDocAccessFields_externalClick_Read",
	permission: boolean
};
	["PostsDocAccessFields_externalClick_Update"]: {
	__typename: "PostsDocAccessFields_externalClick_Update",
	permission: boolean
};
	["PostsDocAccessFields_externalClick_Delete"]: {
	__typename: "PostsDocAccessFields_externalClick_Delete",
	permission: boolean
};
	["PostsDocAccessFields_outboundClick"]: {
	__typename: "PostsDocAccessFields_outboundClick",
	create?: GraphQLTypes["PostsDocAccessFields_outboundClick_Create"] | undefined,
	read?: GraphQLTypes["PostsDocAccessFields_outboundClick_Read"] | undefined,
	update?: GraphQLTypes["PostsDocAccessFields_outboundClick_Update"] | undefined,
	delete?: GraphQLTypes["PostsDocAccessFields_outboundClick_Delete"] | undefined
};
	["PostsDocAccessFields_outboundClick_Create"]: {
	__typename: "PostsDocAccessFields_outboundClick_Create",
	permission: boolean
};
	["PostsDocAccessFields_outboundClick_Read"]: {
	__typename: "PostsDocAccessFields_outboundClick_Read",
	permission: boolean
};
	["PostsDocAccessFields_outboundClick_Update"]: {
	__typename: "PostsDocAccessFields_outboundClick_Update",
	permission: boolean
};
	["PostsDocAccessFields_outboundClick_Delete"]: {
	__typename: "PostsDocAccessFields_outboundClick_Delete",
	permission: boolean
};
	["PostsDocAccessFields_legacyGallery"]: {
	__typename: "PostsDocAccessFields_legacyGallery",
	create?: GraphQLTypes["PostsDocAccessFields_legacyGallery_Create"] | undefined,
	read?: GraphQLTypes["PostsDocAccessFields_legacyGallery_Read"] | undefined,
	update?: GraphQLTypes["PostsDocAccessFields_legacyGallery_Update"] | undefined,
	delete?: GraphQLTypes["PostsDocAccessFields_legacyGallery_Delete"] | undefined
};
	["PostsDocAccessFields_legacyGallery_Create"]: {
	__typename: "PostsDocAccessFields_legacyGallery_Create",
	permission: boolean
};
	["PostsDocAccessFields_legacyGallery_Read"]: {
	__typename: "PostsDocAccessFields_legacyGallery_Read",
	permission: boolean
};
	["PostsDocAccessFields_legacyGallery_Update"]: {
	__typename: "PostsDocAccessFields_legacyGallery_Update",
	permission: boolean
};
	["PostsDocAccessFields_legacyGallery_Delete"]: {
	__typename: "PostsDocAccessFields_legacyGallery_Delete",
	permission: boolean
};
	["PostsDocAccessFields_updatedAt"]: {
	__typename: "PostsDocAccessFields_updatedAt",
	create?: GraphQLTypes["PostsDocAccessFields_updatedAt_Create"] | undefined,
	read?: GraphQLTypes["PostsDocAccessFields_updatedAt_Read"] | undefined,
	update?: GraphQLTypes["PostsDocAccessFields_updatedAt_Update"] | undefined,
	delete?: GraphQLTypes["PostsDocAccessFields_updatedAt_Delete"] | undefined
};
	["PostsDocAccessFields_updatedAt_Create"]: {
	__typename: "PostsDocAccessFields_updatedAt_Create",
	permission: boolean
};
	["PostsDocAccessFields_updatedAt_Read"]: {
	__typename: "PostsDocAccessFields_updatedAt_Read",
	permission: boolean
};
	["PostsDocAccessFields_updatedAt_Update"]: {
	__typename: "PostsDocAccessFields_updatedAt_Update",
	permission: boolean
};
	["PostsDocAccessFields_updatedAt_Delete"]: {
	__typename: "PostsDocAccessFields_updatedAt_Delete",
	permission: boolean
};
	["PostsDocAccessFields_createdAt"]: {
	__typename: "PostsDocAccessFields_createdAt",
	create?: GraphQLTypes["PostsDocAccessFields_createdAt_Create"] | undefined,
	read?: GraphQLTypes["PostsDocAccessFields_createdAt_Read"] | undefined,
	update?: GraphQLTypes["PostsDocAccessFields_createdAt_Update"] | undefined,
	delete?: GraphQLTypes["PostsDocAccessFields_createdAt_Delete"] | undefined
};
	["PostsDocAccessFields_createdAt_Create"]: {
	__typename: "PostsDocAccessFields_createdAt_Create",
	permission: boolean
};
	["PostsDocAccessFields_createdAt_Read"]: {
	__typename: "PostsDocAccessFields_createdAt_Read",
	permission: boolean
};
	["PostsDocAccessFields_createdAt_Update"]: {
	__typename: "PostsDocAccessFields_createdAt_Update",
	permission: boolean
};
	["PostsDocAccessFields_createdAt_Delete"]: {
	__typename: "PostsDocAccessFields_createdAt_Delete",
	permission: boolean
};
	["PostsCreateDocAccess"]: {
	__typename: "PostsCreateDocAccess",
	permission: boolean,
	where?: GraphQLTypes["JSONObject"] | undefined
};
	["PostsReadDocAccess"]: {
	__typename: "PostsReadDocAccess",
	permission: boolean,
	where?: GraphQLTypes["JSONObject"] | undefined
};
	["PostsUpdateDocAccess"]: {
	__typename: "PostsUpdateDocAccess",
	permission: boolean,
	where?: GraphQLTypes["JSONObject"] | undefined
};
	["PostsDeleteDocAccess"]: {
	__typename: "PostsDeleteDocAccess",
	permission: boolean,
	where?: GraphQLTypes["JSONObject"] | undefined
};
	["Ticketings"]: {
	__typename: "Ticketings",
	docs?: Array<GraphQLTypes["Ticketing"] | undefined> | undefined,
	hasNextPage?: boolean | undefined,
	hasPrevPage?: boolean | undefined,
	limit?: number | undefined,
	nextPage?: number | undefined,
	offset?: number | undefined,
	page?: number | undefined,
	pagingCounter?: number | undefined,
	prevPage?: number | undefined,
	totalDocs?: number | undefined,
	totalPages?: number | undefined
};
	["Ticketing_where"]: {
		status?: GraphQLTypes["Ticketing_status_operator"] | undefined,
	creator?: GraphQLTypes["Ticketing_creator_operator"] | undefined,
	productType?: GraphQLTypes["Ticketing_productType_operator"] | undefined,
	productName?: GraphQLTypes["Ticketing_productName_operator"] | undefined,
	referenceId?: GraphQLTypes["Ticketing_referenceId_operator"] | undefined,
	linkedEvent?: GraphQLTypes["Ticketing_linkedEvent_operator"] | undefined,
	CheckInLevel?: GraphQLTypes["Ticketing_CheckInLevel_operator"] | undefined,
	displayPrice?: GraphQLTypes["Ticketing_displayPrice_operator"] | undefined,
	tnc?: GraphQLTypes["Ticketing_tnc_operator"] | undefined,
	cutoffHours?: GraphQLTypes["Ticketing_cutoffHours_operator"] | undefined,
	sessions__variantId?: GraphQLTypes["Ticketing_sessions__variantId_operator"] | undefined,
	sessions__productId?: GraphQLTypes["Ticketing_sessions__productId_operator"] | undefined,
	sessions__startDate?: GraphQLTypes["Ticketing_sessions__startDate_operator"] | undefined,
	sessions__endDate?: GraphQLTypes["Ticketing_sessions__endDate_operator"] | undefined,
	sessions__inventories__name?: GraphQLTypes["Ticketing_sessions__inventories__name_operator"] | undefined,
	sessions__inventories__quantity?: GraphQLTypes["Ticketing_sessions__inventories__quantity_operator"] | undefined,
	sessions__inventories__unitPrice?: GraphQLTypes["Ticketing_sessions__inventories__unitPrice_operator"] | undefined,
	sessions__inventories__handlingFee?: GraphQLTypes["Ticketing_sessions__inventories__handlingFee_operator"] | undefined,
	sessions__inventories__totalCost?: GraphQLTypes["Ticketing_sessions__inventories__totalCost_operator"] | undefined,
	sessions__inventories__minPurchase?: GraphQLTypes["Ticketing_sessions__inventories__minPurchase_operator"] | undefined,
	sessions__inventories__maxPurchase?: GraphQLTypes["Ticketing_sessions__inventories__maxPurchase_operator"] | undefined,
	sessions__inventories__stocks__stockId?: GraphQLTypes["Ticketing_sessions__inventories__stocks__stockId_operator"] | undefined,
	sessions__inventories__stocks__remaining?: GraphQLTypes["Ticketing_sessions__inventories__stocks__remaining_operator"] | undefined,
	sessions__inventories__stocks__sold?: GraphQLTypes["Ticketing_sessions__inventories__stocks__sold_operator"] | undefined,
	sessions__inventories__stocks__onHold?: GraphQLTypes["Ticketing_sessions__inventories__stocks__onHold_operator"] | undefined,
	sessions__inventories__stocks__checkedIn?: GraphQLTypes["Ticketing_sessions__inventories__stocks__checkedIn_operator"] | undefined,
	sessions__inventories__remarks?: GraphQLTypes["Ticketing_sessions__inventories__remarks_operator"] | undefined,
	sessions__id?: GraphQLTypes["Ticketing_sessions__id_operator"] | undefined,
	updatedAt?: GraphQLTypes["Ticketing_updatedAt_operator"] | undefined,
	createdAt?: GraphQLTypes["Ticketing_createdAt_operator"] | undefined,
	id?: GraphQLTypes["Ticketing_id_operator"] | undefined,
	AND?: Array<GraphQLTypes["Ticketing_where_and"] | undefined> | undefined,
	OR?: Array<GraphQLTypes["Ticketing_where_or"] | undefined> | undefined
};
	["Ticketing_status_operator"]: {
		equals?: GraphQLTypes["Ticketing_status_Input"] | undefined,
	not_equals?: GraphQLTypes["Ticketing_status_Input"] | undefined,
	in?: Array<GraphQLTypes["Ticketing_status_Input"] | undefined> | undefined,
	not_in?: Array<GraphQLTypes["Ticketing_status_Input"] | undefined> | undefined,
	all?: Array<GraphQLTypes["Ticketing_status_Input"] | undefined> | undefined,
	exists?: boolean | undefined
};
	["Ticketing_status_Input"]: Ticketing_status_Input;
	["Ticketing_creator_operator"]: {
		equals?: GraphQLTypes["JSON"] | undefined,
	not_equals?: GraphQLTypes["JSON"] | undefined,
	in?: Array<GraphQLTypes["JSON"] | undefined> | undefined,
	not_in?: Array<GraphQLTypes["JSON"] | undefined> | undefined,
	all?: Array<GraphQLTypes["JSON"] | undefined> | undefined,
	exists?: boolean | undefined
};
	["Ticketing_productType_operator"]: {
		equals?: GraphQLTypes["Ticketing_productType_Input"] | undefined,
	not_equals?: GraphQLTypes["Ticketing_productType_Input"] | undefined,
	like?: GraphQLTypes["Ticketing_productType_Input"] | undefined,
	contains?: GraphQLTypes["Ticketing_productType_Input"] | undefined
};
	["Ticketing_productType_Input"]: Ticketing_productType_Input;
	["Ticketing_productName_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined
};
	["Ticketing_referenceId_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Ticketing_linkedEvent_operator"]: {
		equals?: GraphQLTypes["JSON"] | undefined,
	not_equals?: GraphQLTypes["JSON"] | undefined,
	in?: Array<GraphQLTypes["JSON"] | undefined> | undefined,
	not_in?: Array<GraphQLTypes["JSON"] | undefined> | undefined,
	all?: Array<GraphQLTypes["JSON"] | undefined> | undefined,
	exists?: boolean | undefined
};
	["Ticketing_CheckInLevel_operator"]: {
		equals?: GraphQLTypes["Ticketing_CheckInLevel_Input"] | undefined,
	not_equals?: GraphQLTypes["Ticketing_CheckInLevel_Input"] | undefined,
	in?: Array<GraphQLTypes["Ticketing_CheckInLevel_Input"] | undefined> | undefined,
	not_in?: Array<GraphQLTypes["Ticketing_CheckInLevel_Input"] | undefined> | undefined,
	all?: Array<GraphQLTypes["Ticketing_CheckInLevel_Input"] | undefined> | undefined
};
	["Ticketing_CheckInLevel_Input"]: Ticketing_CheckInLevel_Input;
	["Ticketing_displayPrice_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined
};
	["Ticketing_tnc_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined
};
	["Ticketing_cutoffHours_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined
};
	["Ticketing_sessions__variantId_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Ticketing_sessions__productId_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Ticketing_sessions__startDate_operator"]: {
		equals?: GraphQLTypes["DateTime"] | undefined,
	not_equals?: GraphQLTypes["DateTime"] | undefined,
	greater_than_equal?: GraphQLTypes["DateTime"] | undefined,
	greater_than?: GraphQLTypes["DateTime"] | undefined,
	less_than_equal?: GraphQLTypes["DateTime"] | undefined,
	less_than?: GraphQLTypes["DateTime"] | undefined,
	like?: GraphQLTypes["DateTime"] | undefined
};
	["Ticketing_sessions__endDate_operator"]: {
		equals?: GraphQLTypes["DateTime"] | undefined,
	not_equals?: GraphQLTypes["DateTime"] | undefined,
	greater_than_equal?: GraphQLTypes["DateTime"] | undefined,
	greater_than?: GraphQLTypes["DateTime"] | undefined,
	less_than_equal?: GraphQLTypes["DateTime"] | undefined,
	less_than?: GraphQLTypes["DateTime"] | undefined,
	like?: GraphQLTypes["DateTime"] | undefined
};
	["Ticketing_sessions__inventories__name_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined
};
	["Ticketing_sessions__inventories__quantity_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined
};
	["Ticketing_sessions__inventories__unitPrice_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined
};
	["Ticketing_sessions__inventories__handlingFee_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined
};
	["Ticketing_sessions__inventories__totalCost_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Ticketing_sessions__inventories__minPurchase_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined
};
	["Ticketing_sessions__inventories__maxPurchase_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined
};
	["Ticketing_sessions__inventories__stocks__stockId_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Ticketing_sessions__inventories__stocks__remaining_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Ticketing_sessions__inventories__stocks__sold_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Ticketing_sessions__inventories__stocks__onHold_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Ticketing_sessions__inventories__stocks__checkedIn_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Ticketing_sessions__inventories__remarks_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	exists?: boolean | undefined
};
	["Ticketing_sessions__id_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Ticketing_updatedAt_operator"]: {
		equals?: GraphQLTypes["DateTime"] | undefined,
	not_equals?: GraphQLTypes["DateTime"] | undefined,
	greater_than_equal?: GraphQLTypes["DateTime"] | undefined,
	greater_than?: GraphQLTypes["DateTime"] | undefined,
	less_than_equal?: GraphQLTypes["DateTime"] | undefined,
	less_than?: GraphQLTypes["DateTime"] | undefined,
	like?: GraphQLTypes["DateTime"] | undefined,
	exists?: boolean | undefined
};
	["Ticketing_createdAt_operator"]: {
		equals?: GraphQLTypes["DateTime"] | undefined,
	not_equals?: GraphQLTypes["DateTime"] | undefined,
	greater_than_equal?: GraphQLTypes["DateTime"] | undefined,
	greater_than?: GraphQLTypes["DateTime"] | undefined,
	less_than_equal?: GraphQLTypes["DateTime"] | undefined,
	less_than?: GraphQLTypes["DateTime"] | undefined,
	like?: GraphQLTypes["DateTime"] | undefined,
	exists?: boolean | undefined
};
	["Ticketing_id_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Ticketing_where_and"]: {
		status?: GraphQLTypes["Ticketing_status_operator"] | undefined,
	creator?: GraphQLTypes["Ticketing_creator_operator"] | undefined,
	productType?: GraphQLTypes["Ticketing_productType_operator"] | undefined,
	productName?: GraphQLTypes["Ticketing_productName_operator"] | undefined,
	referenceId?: GraphQLTypes["Ticketing_referenceId_operator"] | undefined,
	linkedEvent?: GraphQLTypes["Ticketing_linkedEvent_operator"] | undefined,
	CheckInLevel?: GraphQLTypes["Ticketing_CheckInLevel_operator"] | undefined,
	displayPrice?: GraphQLTypes["Ticketing_displayPrice_operator"] | undefined,
	tnc?: GraphQLTypes["Ticketing_tnc_operator"] | undefined,
	cutoffHours?: GraphQLTypes["Ticketing_cutoffHours_operator"] | undefined,
	sessions__variantId?: GraphQLTypes["Ticketing_sessions__variantId_operator"] | undefined,
	sessions__productId?: GraphQLTypes["Ticketing_sessions__productId_operator"] | undefined,
	sessions__startDate?: GraphQLTypes["Ticketing_sessions__startDate_operator"] | undefined,
	sessions__endDate?: GraphQLTypes["Ticketing_sessions__endDate_operator"] | undefined,
	sessions__inventories__name?: GraphQLTypes["Ticketing_sessions__inventories__name_operator"] | undefined,
	sessions__inventories__quantity?: GraphQLTypes["Ticketing_sessions__inventories__quantity_operator"] | undefined,
	sessions__inventories__unitPrice?: GraphQLTypes["Ticketing_sessions__inventories__unitPrice_operator"] | undefined,
	sessions__inventories__handlingFee?: GraphQLTypes["Ticketing_sessions__inventories__handlingFee_operator"] | undefined,
	sessions__inventories__totalCost?: GraphQLTypes["Ticketing_sessions__inventories__totalCost_operator"] | undefined,
	sessions__inventories__minPurchase?: GraphQLTypes["Ticketing_sessions__inventories__minPurchase_operator"] | undefined,
	sessions__inventories__maxPurchase?: GraphQLTypes["Ticketing_sessions__inventories__maxPurchase_operator"] | undefined,
	sessions__inventories__stocks__stockId?: GraphQLTypes["Ticketing_sessions__inventories__stocks__stockId_operator"] | undefined,
	sessions__inventories__stocks__remaining?: GraphQLTypes["Ticketing_sessions__inventories__stocks__remaining_operator"] | undefined,
	sessions__inventories__stocks__sold?: GraphQLTypes["Ticketing_sessions__inventories__stocks__sold_operator"] | undefined,
	sessions__inventories__stocks__onHold?: GraphQLTypes["Ticketing_sessions__inventories__stocks__onHold_operator"] | undefined,
	sessions__inventories__stocks__checkedIn?: GraphQLTypes["Ticketing_sessions__inventories__stocks__checkedIn_operator"] | undefined,
	sessions__inventories__remarks?: GraphQLTypes["Ticketing_sessions__inventories__remarks_operator"] | undefined,
	sessions__id?: GraphQLTypes["Ticketing_sessions__id_operator"] | undefined,
	updatedAt?: GraphQLTypes["Ticketing_updatedAt_operator"] | undefined,
	createdAt?: GraphQLTypes["Ticketing_createdAt_operator"] | undefined,
	id?: GraphQLTypes["Ticketing_id_operator"] | undefined,
	AND?: Array<GraphQLTypes["Ticketing_where_and"] | undefined> | undefined,
	OR?: Array<GraphQLTypes["Ticketing_where_or"] | undefined> | undefined
};
	["Ticketing_where_or"]: {
		status?: GraphQLTypes["Ticketing_status_operator"] | undefined,
	creator?: GraphQLTypes["Ticketing_creator_operator"] | undefined,
	productType?: GraphQLTypes["Ticketing_productType_operator"] | undefined,
	productName?: GraphQLTypes["Ticketing_productName_operator"] | undefined,
	referenceId?: GraphQLTypes["Ticketing_referenceId_operator"] | undefined,
	linkedEvent?: GraphQLTypes["Ticketing_linkedEvent_operator"] | undefined,
	CheckInLevel?: GraphQLTypes["Ticketing_CheckInLevel_operator"] | undefined,
	displayPrice?: GraphQLTypes["Ticketing_displayPrice_operator"] | undefined,
	tnc?: GraphQLTypes["Ticketing_tnc_operator"] | undefined,
	cutoffHours?: GraphQLTypes["Ticketing_cutoffHours_operator"] | undefined,
	sessions__variantId?: GraphQLTypes["Ticketing_sessions__variantId_operator"] | undefined,
	sessions__productId?: GraphQLTypes["Ticketing_sessions__productId_operator"] | undefined,
	sessions__startDate?: GraphQLTypes["Ticketing_sessions__startDate_operator"] | undefined,
	sessions__endDate?: GraphQLTypes["Ticketing_sessions__endDate_operator"] | undefined,
	sessions__inventories__name?: GraphQLTypes["Ticketing_sessions__inventories__name_operator"] | undefined,
	sessions__inventories__quantity?: GraphQLTypes["Ticketing_sessions__inventories__quantity_operator"] | undefined,
	sessions__inventories__unitPrice?: GraphQLTypes["Ticketing_sessions__inventories__unitPrice_operator"] | undefined,
	sessions__inventories__handlingFee?: GraphQLTypes["Ticketing_sessions__inventories__handlingFee_operator"] | undefined,
	sessions__inventories__totalCost?: GraphQLTypes["Ticketing_sessions__inventories__totalCost_operator"] | undefined,
	sessions__inventories__minPurchase?: GraphQLTypes["Ticketing_sessions__inventories__minPurchase_operator"] | undefined,
	sessions__inventories__maxPurchase?: GraphQLTypes["Ticketing_sessions__inventories__maxPurchase_operator"] | undefined,
	sessions__inventories__stocks__stockId?: GraphQLTypes["Ticketing_sessions__inventories__stocks__stockId_operator"] | undefined,
	sessions__inventories__stocks__remaining?: GraphQLTypes["Ticketing_sessions__inventories__stocks__remaining_operator"] | undefined,
	sessions__inventories__stocks__sold?: GraphQLTypes["Ticketing_sessions__inventories__stocks__sold_operator"] | undefined,
	sessions__inventories__stocks__onHold?: GraphQLTypes["Ticketing_sessions__inventories__stocks__onHold_operator"] | undefined,
	sessions__inventories__stocks__checkedIn?: GraphQLTypes["Ticketing_sessions__inventories__stocks__checkedIn_operator"] | undefined,
	sessions__inventories__remarks?: GraphQLTypes["Ticketing_sessions__inventories__remarks_operator"] | undefined,
	sessions__id?: GraphQLTypes["Ticketing_sessions__id_operator"] | undefined,
	updatedAt?: GraphQLTypes["Ticketing_updatedAt_operator"] | undefined,
	createdAt?: GraphQLTypes["Ticketing_createdAt_operator"] | undefined,
	id?: GraphQLTypes["Ticketing_id_operator"] | undefined,
	AND?: Array<GraphQLTypes["Ticketing_where_and"] | undefined> | undefined,
	OR?: Array<GraphQLTypes["Ticketing_where_or"] | undefined> | undefined
};
	["countTicketings"]: {
	__typename: "countTicketings",
	totalDocs?: number | undefined
};
	["ticketingDocAccess"]: {
	__typename: "ticketingDocAccess",
	fields?: GraphQLTypes["TicketingDocAccessFields"] | undefined,
	create?: GraphQLTypes["TicketingCreateDocAccess"] | undefined,
	read?: GraphQLTypes["TicketingReadDocAccess"] | undefined,
	update?: GraphQLTypes["TicketingUpdateDocAccess"] | undefined,
	delete?: GraphQLTypes["TicketingDeleteDocAccess"] | undefined
};
	["TicketingDocAccessFields"]: {
	__typename: "TicketingDocAccessFields",
	status?: GraphQLTypes["TicketingDocAccessFields_status"] | undefined,
	creator?: GraphQLTypes["TicketingDocAccessFields_creator"] | undefined,
	productType?: GraphQLTypes["TicketingDocAccessFields_productType"] | undefined,
	productName?: GraphQLTypes["TicketingDocAccessFields_productName"] | undefined,
	referenceId?: GraphQLTypes["TicketingDocAccessFields_referenceId"] | undefined,
	linkedEvent?: GraphQLTypes["TicketingDocAccessFields_linkedEvent"] | undefined,
	CheckInLevel?: GraphQLTypes["TicketingDocAccessFields_CheckInLevel"] | undefined,
	displayPrice?: GraphQLTypes["TicketingDocAccessFields_displayPrice"] | undefined,
	tnc?: GraphQLTypes["TicketingDocAccessFields_tnc"] | undefined,
	cutoffHours?: GraphQLTypes["TicketingDocAccessFields_cutoffHours"] | undefined,
	sessions?: GraphQLTypes["TicketingDocAccessFields_sessions"] | undefined,
	updatedAt?: GraphQLTypes["TicketingDocAccessFields_updatedAt"] | undefined,
	createdAt?: GraphQLTypes["TicketingDocAccessFields_createdAt"] | undefined
};
	["TicketingDocAccessFields_status"]: {
	__typename: "TicketingDocAccessFields_status",
	create?: GraphQLTypes["TicketingDocAccessFields_status_Create"] | undefined,
	read?: GraphQLTypes["TicketingDocAccessFields_status_Read"] | undefined,
	update?: GraphQLTypes["TicketingDocAccessFields_status_Update"] | undefined,
	delete?: GraphQLTypes["TicketingDocAccessFields_status_Delete"] | undefined
};
	["TicketingDocAccessFields_status_Create"]: {
	__typename: "TicketingDocAccessFields_status_Create",
	permission: boolean
};
	["TicketingDocAccessFields_status_Read"]: {
	__typename: "TicketingDocAccessFields_status_Read",
	permission: boolean
};
	["TicketingDocAccessFields_status_Update"]: {
	__typename: "TicketingDocAccessFields_status_Update",
	permission: boolean
};
	["TicketingDocAccessFields_status_Delete"]: {
	__typename: "TicketingDocAccessFields_status_Delete",
	permission: boolean
};
	["TicketingDocAccessFields_creator"]: {
	__typename: "TicketingDocAccessFields_creator",
	create?: GraphQLTypes["TicketingDocAccessFields_creator_Create"] | undefined,
	read?: GraphQLTypes["TicketingDocAccessFields_creator_Read"] | undefined,
	update?: GraphQLTypes["TicketingDocAccessFields_creator_Update"] | undefined,
	delete?: GraphQLTypes["TicketingDocAccessFields_creator_Delete"] | undefined
};
	["TicketingDocAccessFields_creator_Create"]: {
	__typename: "TicketingDocAccessFields_creator_Create",
	permission: boolean
};
	["TicketingDocAccessFields_creator_Read"]: {
	__typename: "TicketingDocAccessFields_creator_Read",
	permission: boolean
};
	["TicketingDocAccessFields_creator_Update"]: {
	__typename: "TicketingDocAccessFields_creator_Update",
	permission: boolean
};
	["TicketingDocAccessFields_creator_Delete"]: {
	__typename: "TicketingDocAccessFields_creator_Delete",
	permission: boolean
};
	["TicketingDocAccessFields_productType"]: {
	__typename: "TicketingDocAccessFields_productType",
	create?: GraphQLTypes["TicketingDocAccessFields_productType_Create"] | undefined,
	read?: GraphQLTypes["TicketingDocAccessFields_productType_Read"] | undefined,
	update?: GraphQLTypes["TicketingDocAccessFields_productType_Update"] | undefined,
	delete?: GraphQLTypes["TicketingDocAccessFields_productType_Delete"] | undefined
};
	["TicketingDocAccessFields_productType_Create"]: {
	__typename: "TicketingDocAccessFields_productType_Create",
	permission: boolean
};
	["TicketingDocAccessFields_productType_Read"]: {
	__typename: "TicketingDocAccessFields_productType_Read",
	permission: boolean
};
	["TicketingDocAccessFields_productType_Update"]: {
	__typename: "TicketingDocAccessFields_productType_Update",
	permission: boolean
};
	["TicketingDocAccessFields_productType_Delete"]: {
	__typename: "TicketingDocAccessFields_productType_Delete",
	permission: boolean
};
	["TicketingDocAccessFields_productName"]: {
	__typename: "TicketingDocAccessFields_productName",
	create?: GraphQLTypes["TicketingDocAccessFields_productName_Create"] | undefined,
	read?: GraphQLTypes["TicketingDocAccessFields_productName_Read"] | undefined,
	update?: GraphQLTypes["TicketingDocAccessFields_productName_Update"] | undefined,
	delete?: GraphQLTypes["TicketingDocAccessFields_productName_Delete"] | undefined
};
	["TicketingDocAccessFields_productName_Create"]: {
	__typename: "TicketingDocAccessFields_productName_Create",
	permission: boolean
};
	["TicketingDocAccessFields_productName_Read"]: {
	__typename: "TicketingDocAccessFields_productName_Read",
	permission: boolean
};
	["TicketingDocAccessFields_productName_Update"]: {
	__typename: "TicketingDocAccessFields_productName_Update",
	permission: boolean
};
	["TicketingDocAccessFields_productName_Delete"]: {
	__typename: "TicketingDocAccessFields_productName_Delete",
	permission: boolean
};
	["TicketingDocAccessFields_referenceId"]: {
	__typename: "TicketingDocAccessFields_referenceId",
	create?: GraphQLTypes["TicketingDocAccessFields_referenceId_Create"] | undefined,
	read?: GraphQLTypes["TicketingDocAccessFields_referenceId_Read"] | undefined,
	update?: GraphQLTypes["TicketingDocAccessFields_referenceId_Update"] | undefined,
	delete?: GraphQLTypes["TicketingDocAccessFields_referenceId_Delete"] | undefined
};
	["TicketingDocAccessFields_referenceId_Create"]: {
	__typename: "TicketingDocAccessFields_referenceId_Create",
	permission: boolean
};
	["TicketingDocAccessFields_referenceId_Read"]: {
	__typename: "TicketingDocAccessFields_referenceId_Read",
	permission: boolean
};
	["TicketingDocAccessFields_referenceId_Update"]: {
	__typename: "TicketingDocAccessFields_referenceId_Update",
	permission: boolean
};
	["TicketingDocAccessFields_referenceId_Delete"]: {
	__typename: "TicketingDocAccessFields_referenceId_Delete",
	permission: boolean
};
	["TicketingDocAccessFields_linkedEvent"]: {
	__typename: "TicketingDocAccessFields_linkedEvent",
	create?: GraphQLTypes["TicketingDocAccessFields_linkedEvent_Create"] | undefined,
	read?: GraphQLTypes["TicketingDocAccessFields_linkedEvent_Read"] | undefined,
	update?: GraphQLTypes["TicketingDocAccessFields_linkedEvent_Update"] | undefined,
	delete?: GraphQLTypes["TicketingDocAccessFields_linkedEvent_Delete"] | undefined
};
	["TicketingDocAccessFields_linkedEvent_Create"]: {
	__typename: "TicketingDocAccessFields_linkedEvent_Create",
	permission: boolean
};
	["TicketingDocAccessFields_linkedEvent_Read"]: {
	__typename: "TicketingDocAccessFields_linkedEvent_Read",
	permission: boolean
};
	["TicketingDocAccessFields_linkedEvent_Update"]: {
	__typename: "TicketingDocAccessFields_linkedEvent_Update",
	permission: boolean
};
	["TicketingDocAccessFields_linkedEvent_Delete"]: {
	__typename: "TicketingDocAccessFields_linkedEvent_Delete",
	permission: boolean
};
	["TicketingDocAccessFields_CheckInLevel"]: {
	__typename: "TicketingDocAccessFields_CheckInLevel",
	create?: GraphQLTypes["TicketingDocAccessFields_CheckInLevel_Create"] | undefined,
	read?: GraphQLTypes["TicketingDocAccessFields_CheckInLevel_Read"] | undefined,
	update?: GraphQLTypes["TicketingDocAccessFields_CheckInLevel_Update"] | undefined,
	delete?: GraphQLTypes["TicketingDocAccessFields_CheckInLevel_Delete"] | undefined
};
	["TicketingDocAccessFields_CheckInLevel_Create"]: {
	__typename: "TicketingDocAccessFields_CheckInLevel_Create",
	permission: boolean
};
	["TicketingDocAccessFields_CheckInLevel_Read"]: {
	__typename: "TicketingDocAccessFields_CheckInLevel_Read",
	permission: boolean
};
	["TicketingDocAccessFields_CheckInLevel_Update"]: {
	__typename: "TicketingDocAccessFields_CheckInLevel_Update",
	permission: boolean
};
	["TicketingDocAccessFields_CheckInLevel_Delete"]: {
	__typename: "TicketingDocAccessFields_CheckInLevel_Delete",
	permission: boolean
};
	["TicketingDocAccessFields_displayPrice"]: {
	__typename: "TicketingDocAccessFields_displayPrice",
	create?: GraphQLTypes["TicketingDocAccessFields_displayPrice_Create"] | undefined,
	read?: GraphQLTypes["TicketingDocAccessFields_displayPrice_Read"] | undefined,
	update?: GraphQLTypes["TicketingDocAccessFields_displayPrice_Update"] | undefined,
	delete?: GraphQLTypes["TicketingDocAccessFields_displayPrice_Delete"] | undefined
};
	["TicketingDocAccessFields_displayPrice_Create"]: {
	__typename: "TicketingDocAccessFields_displayPrice_Create",
	permission: boolean
};
	["TicketingDocAccessFields_displayPrice_Read"]: {
	__typename: "TicketingDocAccessFields_displayPrice_Read",
	permission: boolean
};
	["TicketingDocAccessFields_displayPrice_Update"]: {
	__typename: "TicketingDocAccessFields_displayPrice_Update",
	permission: boolean
};
	["TicketingDocAccessFields_displayPrice_Delete"]: {
	__typename: "TicketingDocAccessFields_displayPrice_Delete",
	permission: boolean
};
	["TicketingDocAccessFields_tnc"]: {
	__typename: "TicketingDocAccessFields_tnc",
	create?: GraphQLTypes["TicketingDocAccessFields_tnc_Create"] | undefined,
	read?: GraphQLTypes["TicketingDocAccessFields_tnc_Read"] | undefined,
	update?: GraphQLTypes["TicketingDocAccessFields_tnc_Update"] | undefined,
	delete?: GraphQLTypes["TicketingDocAccessFields_tnc_Delete"] | undefined
};
	["TicketingDocAccessFields_tnc_Create"]: {
	__typename: "TicketingDocAccessFields_tnc_Create",
	permission: boolean
};
	["TicketingDocAccessFields_tnc_Read"]: {
	__typename: "TicketingDocAccessFields_tnc_Read",
	permission: boolean
};
	["TicketingDocAccessFields_tnc_Update"]: {
	__typename: "TicketingDocAccessFields_tnc_Update",
	permission: boolean
};
	["TicketingDocAccessFields_tnc_Delete"]: {
	__typename: "TicketingDocAccessFields_tnc_Delete",
	permission: boolean
};
	["TicketingDocAccessFields_cutoffHours"]: {
	__typename: "TicketingDocAccessFields_cutoffHours",
	create?: GraphQLTypes["TicketingDocAccessFields_cutoffHours_Create"] | undefined,
	read?: GraphQLTypes["TicketingDocAccessFields_cutoffHours_Read"] | undefined,
	update?: GraphQLTypes["TicketingDocAccessFields_cutoffHours_Update"] | undefined,
	delete?: GraphQLTypes["TicketingDocAccessFields_cutoffHours_Delete"] | undefined
};
	["TicketingDocAccessFields_cutoffHours_Create"]: {
	__typename: "TicketingDocAccessFields_cutoffHours_Create",
	permission: boolean
};
	["TicketingDocAccessFields_cutoffHours_Read"]: {
	__typename: "TicketingDocAccessFields_cutoffHours_Read",
	permission: boolean
};
	["TicketingDocAccessFields_cutoffHours_Update"]: {
	__typename: "TicketingDocAccessFields_cutoffHours_Update",
	permission: boolean
};
	["TicketingDocAccessFields_cutoffHours_Delete"]: {
	__typename: "TicketingDocAccessFields_cutoffHours_Delete",
	permission: boolean
};
	["TicketingDocAccessFields_sessions"]: {
	__typename: "TicketingDocAccessFields_sessions",
	create?: GraphQLTypes["TicketingDocAccessFields_sessions_Create"] | undefined,
	read?: GraphQLTypes["TicketingDocAccessFields_sessions_Read"] | undefined,
	update?: GraphQLTypes["TicketingDocAccessFields_sessions_Update"] | undefined,
	delete?: GraphQLTypes["TicketingDocAccessFields_sessions_Delete"] | undefined,
	fields?: GraphQLTypes["TicketingDocAccessFields_sessions_Fields"] | undefined
};
	["TicketingDocAccessFields_sessions_Create"]: {
	__typename: "TicketingDocAccessFields_sessions_Create",
	permission: boolean
};
	["TicketingDocAccessFields_sessions_Read"]: {
	__typename: "TicketingDocAccessFields_sessions_Read",
	permission: boolean
};
	["TicketingDocAccessFields_sessions_Update"]: {
	__typename: "TicketingDocAccessFields_sessions_Update",
	permission: boolean
};
	["TicketingDocAccessFields_sessions_Delete"]: {
	__typename: "TicketingDocAccessFields_sessions_Delete",
	permission: boolean
};
	["TicketingDocAccessFields_sessions_Fields"]: {
	__typename: "TicketingDocAccessFields_sessions_Fields",
	productId?: GraphQLTypes["TicketingDocAccessFields_sessions_productId"] | undefined,
	startDate?: GraphQLTypes["TicketingDocAccessFields_sessions_startDate"] | undefined,
	endDate?: GraphQLTypes["TicketingDocAccessFields_sessions_endDate"] | undefined,
	inventories?: GraphQLTypes["TicketingDocAccessFields_sessions_inventories"] | undefined,
	id?: GraphQLTypes["TicketingDocAccessFields_sessions_id"] | undefined
};
	["TicketingDocAccessFields_sessions_productId"]: {
	__typename: "TicketingDocAccessFields_sessions_productId",
	create?: GraphQLTypes["TicketingDocAccessFields_sessions_productId_Create"] | undefined,
	read?: GraphQLTypes["TicketingDocAccessFields_sessions_productId_Read"] | undefined,
	update?: GraphQLTypes["TicketingDocAccessFields_sessions_productId_Update"] | undefined,
	delete?: GraphQLTypes["TicketingDocAccessFields_sessions_productId_Delete"] | undefined
};
	["TicketingDocAccessFields_sessions_productId_Create"]: {
	__typename: "TicketingDocAccessFields_sessions_productId_Create",
	permission: boolean
};
	["TicketingDocAccessFields_sessions_productId_Read"]: {
	__typename: "TicketingDocAccessFields_sessions_productId_Read",
	permission: boolean
};
	["TicketingDocAccessFields_sessions_productId_Update"]: {
	__typename: "TicketingDocAccessFields_sessions_productId_Update",
	permission: boolean
};
	["TicketingDocAccessFields_sessions_productId_Delete"]: {
	__typename: "TicketingDocAccessFields_sessions_productId_Delete",
	permission: boolean
};
	["TicketingDocAccessFields_sessions_startDate"]: {
	__typename: "TicketingDocAccessFields_sessions_startDate",
	create?: GraphQLTypes["TicketingDocAccessFields_sessions_startDate_Create"] | undefined,
	read?: GraphQLTypes["TicketingDocAccessFields_sessions_startDate_Read"] | undefined,
	update?: GraphQLTypes["TicketingDocAccessFields_sessions_startDate_Update"] | undefined,
	delete?: GraphQLTypes["TicketingDocAccessFields_sessions_startDate_Delete"] | undefined
};
	["TicketingDocAccessFields_sessions_startDate_Create"]: {
	__typename: "TicketingDocAccessFields_sessions_startDate_Create",
	permission: boolean
};
	["TicketingDocAccessFields_sessions_startDate_Read"]: {
	__typename: "TicketingDocAccessFields_sessions_startDate_Read",
	permission: boolean
};
	["TicketingDocAccessFields_sessions_startDate_Update"]: {
	__typename: "TicketingDocAccessFields_sessions_startDate_Update",
	permission: boolean
};
	["TicketingDocAccessFields_sessions_startDate_Delete"]: {
	__typename: "TicketingDocAccessFields_sessions_startDate_Delete",
	permission: boolean
};
	["TicketingDocAccessFields_sessions_endDate"]: {
	__typename: "TicketingDocAccessFields_sessions_endDate",
	create?: GraphQLTypes["TicketingDocAccessFields_sessions_endDate_Create"] | undefined,
	read?: GraphQLTypes["TicketingDocAccessFields_sessions_endDate_Read"] | undefined,
	update?: GraphQLTypes["TicketingDocAccessFields_sessions_endDate_Update"] | undefined,
	delete?: GraphQLTypes["TicketingDocAccessFields_sessions_endDate_Delete"] | undefined
};
	["TicketingDocAccessFields_sessions_endDate_Create"]: {
	__typename: "TicketingDocAccessFields_sessions_endDate_Create",
	permission: boolean
};
	["TicketingDocAccessFields_sessions_endDate_Read"]: {
	__typename: "TicketingDocAccessFields_sessions_endDate_Read",
	permission: boolean
};
	["TicketingDocAccessFields_sessions_endDate_Update"]: {
	__typename: "TicketingDocAccessFields_sessions_endDate_Update",
	permission: boolean
};
	["TicketingDocAccessFields_sessions_endDate_Delete"]: {
	__typename: "TicketingDocAccessFields_sessions_endDate_Delete",
	permission: boolean
};
	["TicketingDocAccessFields_sessions_inventories"]: {
	__typename: "TicketingDocAccessFields_sessions_inventories",
	create?: GraphQLTypes["TicketingDocAccessFields_sessions_inventories_Create"] | undefined,
	read?: GraphQLTypes["TicketingDocAccessFields_sessions_inventories_Read"] | undefined,
	update?: GraphQLTypes["TicketingDocAccessFields_sessions_inventories_Update"] | undefined,
	delete?: GraphQLTypes["TicketingDocAccessFields_sessions_inventories_Delete"] | undefined,
	fields?: GraphQLTypes["TicketingDocAccessFields_sessions_inventories_Fields"] | undefined
};
	["TicketingDocAccessFields_sessions_inventories_Create"]: {
	__typename: "TicketingDocAccessFields_sessions_inventories_Create",
	permission: boolean
};
	["TicketingDocAccessFields_sessions_inventories_Read"]: {
	__typename: "TicketingDocAccessFields_sessions_inventories_Read",
	permission: boolean
};
	["TicketingDocAccessFields_sessions_inventories_Update"]: {
	__typename: "TicketingDocAccessFields_sessions_inventories_Update",
	permission: boolean
};
	["TicketingDocAccessFields_sessions_inventories_Delete"]: {
	__typename: "TicketingDocAccessFields_sessions_inventories_Delete",
	permission: boolean
};
	["TicketingDocAccessFields_sessions_inventories_Fields"]: {
	__typename: "TicketingDocAccessFields_sessions_inventories_Fields",
	name?: GraphQLTypes["TicketingDocAccessFields_sessions_inventories_name"] | undefined,
	quantity?: GraphQLTypes["TicketingDocAccessFields_sessions_inventories_quantity"] | undefined,
	unitPrice?: GraphQLTypes["TicketingDocAccessFields_sessions_inventories_unitPrice"] | undefined,
	handlingFee?: GraphQLTypes["TicketingDocAccessFields_sessions_inventories_handlingFee"] | undefined,
	totalCost?: GraphQLTypes["TicketingDocAccessFields_sessions_inventories_totalCost"] | undefined,
	minPurchase?: GraphQLTypes["TicketingDocAccessFields_sessions_inventories_minPurchase"] | undefined,
	maxPurchase?: GraphQLTypes["TicketingDocAccessFields_sessions_inventories_maxPurchase"] | undefined,
	stocks?: GraphQLTypes["TicketingDocAccessFields_sessions_inventories_stocks"] | undefined,
	remarks?: GraphQLTypes["TicketingDocAccessFields_sessions_inventories_remarks"] | undefined
};
	["TicketingDocAccessFields_sessions_inventories_name"]: {
	__typename: "TicketingDocAccessFields_sessions_inventories_name",
	create?: GraphQLTypes["TicketingDocAccessFields_sessions_inventories_name_Create"] | undefined,
	read?: GraphQLTypes["TicketingDocAccessFields_sessions_inventories_name_Read"] | undefined,
	update?: GraphQLTypes["TicketingDocAccessFields_sessions_inventories_name_Update"] | undefined,
	delete?: GraphQLTypes["TicketingDocAccessFields_sessions_inventories_name_Delete"] | undefined
};
	["TicketingDocAccessFields_sessions_inventories_name_Create"]: {
	__typename: "TicketingDocAccessFields_sessions_inventories_name_Create",
	permission: boolean
};
	["TicketingDocAccessFields_sessions_inventories_name_Read"]: {
	__typename: "TicketingDocAccessFields_sessions_inventories_name_Read",
	permission: boolean
};
	["TicketingDocAccessFields_sessions_inventories_name_Update"]: {
	__typename: "TicketingDocAccessFields_sessions_inventories_name_Update",
	permission: boolean
};
	["TicketingDocAccessFields_sessions_inventories_name_Delete"]: {
	__typename: "TicketingDocAccessFields_sessions_inventories_name_Delete",
	permission: boolean
};
	["TicketingDocAccessFields_sessions_inventories_quantity"]: {
	__typename: "TicketingDocAccessFields_sessions_inventories_quantity",
	create?: GraphQLTypes["TicketingDocAccessFields_sessions_inventories_quantity_Create"] | undefined,
	read?: GraphQLTypes["TicketingDocAccessFields_sessions_inventories_quantity_Read"] | undefined,
	update?: GraphQLTypes["TicketingDocAccessFields_sessions_inventories_quantity_Update"] | undefined,
	delete?: GraphQLTypes["TicketingDocAccessFields_sessions_inventories_quantity_Delete"] | undefined
};
	["TicketingDocAccessFields_sessions_inventories_quantity_Create"]: {
	__typename: "TicketingDocAccessFields_sessions_inventories_quantity_Create",
	permission: boolean
};
	["TicketingDocAccessFields_sessions_inventories_quantity_Read"]: {
	__typename: "TicketingDocAccessFields_sessions_inventories_quantity_Read",
	permission: boolean
};
	["TicketingDocAccessFields_sessions_inventories_quantity_Update"]: {
	__typename: "TicketingDocAccessFields_sessions_inventories_quantity_Update",
	permission: boolean
};
	["TicketingDocAccessFields_sessions_inventories_quantity_Delete"]: {
	__typename: "TicketingDocAccessFields_sessions_inventories_quantity_Delete",
	permission: boolean
};
	["TicketingDocAccessFields_sessions_inventories_unitPrice"]: {
	__typename: "TicketingDocAccessFields_sessions_inventories_unitPrice",
	create?: GraphQLTypes["TicketingDocAccessFields_sessions_inventories_unitPrice_Create"] | undefined,
	read?: GraphQLTypes["TicketingDocAccessFields_sessions_inventories_unitPrice_Read"] | undefined,
	update?: GraphQLTypes["TicketingDocAccessFields_sessions_inventories_unitPrice_Update"] | undefined,
	delete?: GraphQLTypes["TicketingDocAccessFields_sessions_inventories_unitPrice_Delete"] | undefined
};
	["TicketingDocAccessFields_sessions_inventories_unitPrice_Create"]: {
	__typename: "TicketingDocAccessFields_sessions_inventories_unitPrice_Create",
	permission: boolean
};
	["TicketingDocAccessFields_sessions_inventories_unitPrice_Read"]: {
	__typename: "TicketingDocAccessFields_sessions_inventories_unitPrice_Read",
	permission: boolean
};
	["TicketingDocAccessFields_sessions_inventories_unitPrice_Update"]: {
	__typename: "TicketingDocAccessFields_sessions_inventories_unitPrice_Update",
	permission: boolean
};
	["TicketingDocAccessFields_sessions_inventories_unitPrice_Delete"]: {
	__typename: "TicketingDocAccessFields_sessions_inventories_unitPrice_Delete",
	permission: boolean
};
	["TicketingDocAccessFields_sessions_inventories_handlingFee"]: {
	__typename: "TicketingDocAccessFields_sessions_inventories_handlingFee",
	create?: GraphQLTypes["TicketingDocAccessFields_sessions_inventories_handlingFee_Create"] | undefined,
	read?: GraphQLTypes["TicketingDocAccessFields_sessions_inventories_handlingFee_Read"] | undefined,
	update?: GraphQLTypes["TicketingDocAccessFields_sessions_inventories_handlingFee_Update"] | undefined,
	delete?: GraphQLTypes["TicketingDocAccessFields_sessions_inventories_handlingFee_Delete"] | undefined
};
	["TicketingDocAccessFields_sessions_inventories_handlingFee_Create"]: {
	__typename: "TicketingDocAccessFields_sessions_inventories_handlingFee_Create",
	permission: boolean
};
	["TicketingDocAccessFields_sessions_inventories_handlingFee_Read"]: {
	__typename: "TicketingDocAccessFields_sessions_inventories_handlingFee_Read",
	permission: boolean
};
	["TicketingDocAccessFields_sessions_inventories_handlingFee_Update"]: {
	__typename: "TicketingDocAccessFields_sessions_inventories_handlingFee_Update",
	permission: boolean
};
	["TicketingDocAccessFields_sessions_inventories_handlingFee_Delete"]: {
	__typename: "TicketingDocAccessFields_sessions_inventories_handlingFee_Delete",
	permission: boolean
};
	["TicketingDocAccessFields_sessions_inventories_totalCost"]: {
	__typename: "TicketingDocAccessFields_sessions_inventories_totalCost",
	create?: GraphQLTypes["TicketingDocAccessFields_sessions_inventories_totalCost_Create"] | undefined,
	read?: GraphQLTypes["TicketingDocAccessFields_sessions_inventories_totalCost_Read"] | undefined,
	update?: GraphQLTypes["TicketingDocAccessFields_sessions_inventories_totalCost_Update"] | undefined,
	delete?: GraphQLTypes["TicketingDocAccessFields_sessions_inventories_totalCost_Delete"] | undefined
};
	["TicketingDocAccessFields_sessions_inventories_totalCost_Create"]: {
	__typename: "TicketingDocAccessFields_sessions_inventories_totalCost_Create",
	permission: boolean
};
	["TicketingDocAccessFields_sessions_inventories_totalCost_Read"]: {
	__typename: "TicketingDocAccessFields_sessions_inventories_totalCost_Read",
	permission: boolean
};
	["TicketingDocAccessFields_sessions_inventories_totalCost_Update"]: {
	__typename: "TicketingDocAccessFields_sessions_inventories_totalCost_Update",
	permission: boolean
};
	["TicketingDocAccessFields_sessions_inventories_totalCost_Delete"]: {
	__typename: "TicketingDocAccessFields_sessions_inventories_totalCost_Delete",
	permission: boolean
};
	["TicketingDocAccessFields_sessions_inventories_minPurchase"]: {
	__typename: "TicketingDocAccessFields_sessions_inventories_minPurchase",
	create?: GraphQLTypes["TicketingDocAccessFields_sessions_inventories_minPurchase_Create"] | undefined,
	read?: GraphQLTypes["TicketingDocAccessFields_sessions_inventories_minPurchase_Read"] | undefined,
	update?: GraphQLTypes["TicketingDocAccessFields_sessions_inventories_minPurchase_Update"] | undefined,
	delete?: GraphQLTypes["TicketingDocAccessFields_sessions_inventories_minPurchase_Delete"] | undefined
};
	["TicketingDocAccessFields_sessions_inventories_minPurchase_Create"]: {
	__typename: "TicketingDocAccessFields_sessions_inventories_minPurchase_Create",
	permission: boolean
};
	["TicketingDocAccessFields_sessions_inventories_minPurchase_Read"]: {
	__typename: "TicketingDocAccessFields_sessions_inventories_minPurchase_Read",
	permission: boolean
};
	["TicketingDocAccessFields_sessions_inventories_minPurchase_Update"]: {
	__typename: "TicketingDocAccessFields_sessions_inventories_minPurchase_Update",
	permission: boolean
};
	["TicketingDocAccessFields_sessions_inventories_minPurchase_Delete"]: {
	__typename: "TicketingDocAccessFields_sessions_inventories_minPurchase_Delete",
	permission: boolean
};
	["TicketingDocAccessFields_sessions_inventories_maxPurchase"]: {
	__typename: "TicketingDocAccessFields_sessions_inventories_maxPurchase",
	create?: GraphQLTypes["TicketingDocAccessFields_sessions_inventories_maxPurchase_Create"] | undefined,
	read?: GraphQLTypes["TicketingDocAccessFields_sessions_inventories_maxPurchase_Read"] | undefined,
	update?: GraphQLTypes["TicketingDocAccessFields_sessions_inventories_maxPurchase_Update"] | undefined,
	delete?: GraphQLTypes["TicketingDocAccessFields_sessions_inventories_maxPurchase_Delete"] | undefined
};
	["TicketingDocAccessFields_sessions_inventories_maxPurchase_Create"]: {
	__typename: "TicketingDocAccessFields_sessions_inventories_maxPurchase_Create",
	permission: boolean
};
	["TicketingDocAccessFields_sessions_inventories_maxPurchase_Read"]: {
	__typename: "TicketingDocAccessFields_sessions_inventories_maxPurchase_Read",
	permission: boolean
};
	["TicketingDocAccessFields_sessions_inventories_maxPurchase_Update"]: {
	__typename: "TicketingDocAccessFields_sessions_inventories_maxPurchase_Update",
	permission: boolean
};
	["TicketingDocAccessFields_sessions_inventories_maxPurchase_Delete"]: {
	__typename: "TicketingDocAccessFields_sessions_inventories_maxPurchase_Delete",
	permission: boolean
};
	["TicketingDocAccessFields_sessions_inventories_stocks"]: {
	__typename: "TicketingDocAccessFields_sessions_inventories_stocks",
	create?: GraphQLTypes["TicketingDocAccessFields_sessions_inventories_stocks_Create"] | undefined,
	read?: GraphQLTypes["TicketingDocAccessFields_sessions_inventories_stocks_Read"] | undefined,
	update?: GraphQLTypes["TicketingDocAccessFields_sessions_inventories_stocks_Update"] | undefined,
	delete?: GraphQLTypes["TicketingDocAccessFields_sessions_inventories_stocks_Delete"] | undefined,
	fields?: GraphQLTypes["TicketingDocAccessFields_sessions_inventories_stocks_Fields"] | undefined
};
	["TicketingDocAccessFields_sessions_inventories_stocks_Create"]: {
	__typename: "TicketingDocAccessFields_sessions_inventories_stocks_Create",
	permission: boolean
};
	["TicketingDocAccessFields_sessions_inventories_stocks_Read"]: {
	__typename: "TicketingDocAccessFields_sessions_inventories_stocks_Read",
	permission: boolean
};
	["TicketingDocAccessFields_sessions_inventories_stocks_Update"]: {
	__typename: "TicketingDocAccessFields_sessions_inventories_stocks_Update",
	permission: boolean
};
	["TicketingDocAccessFields_sessions_inventories_stocks_Delete"]: {
	__typename: "TicketingDocAccessFields_sessions_inventories_stocks_Delete",
	permission: boolean
};
	["TicketingDocAccessFields_sessions_inventories_stocks_Fields"]: {
	__typename: "TicketingDocAccessFields_sessions_inventories_stocks_Fields",
	stockId?: GraphQLTypes["TicketingDocAccessFields_sessions_inventories_stocks_stockId"] | undefined,
	remaining?: GraphQLTypes["TicketingDocAccessFields_sessions_inventories_stocks_remaining"] | undefined,
	sold?: GraphQLTypes["TicketingDocAccessFields_sessions_inventories_stocks_sold"] | undefined,
	onHold?: GraphQLTypes["TicketingDocAccessFields_sessions_inventories_stocks_onHold"] | undefined,
	checkedIn?: GraphQLTypes["TicketingDocAccessFields_sessions_inventories_stocks_checkedIn"] | undefined
};
	["TicketingDocAccessFields_sessions_inventories_stocks_stockId"]: {
	__typename: "TicketingDocAccessFields_sessions_inventories_stocks_stockId",
	create?: GraphQLTypes["TicketingDocAccessFields_sessions_inventories_stocks_stockId_Create"] | undefined,
	read?: GraphQLTypes["TicketingDocAccessFields_sessions_inventories_stocks_stockId_Read"] | undefined,
	update?: GraphQLTypes["TicketingDocAccessFields_sessions_inventories_stocks_stockId_Update"] | undefined,
	delete?: GraphQLTypes["TicketingDocAccessFields_sessions_inventories_stocks_stockId_Delete"] | undefined
};
	["TicketingDocAccessFields_sessions_inventories_stocks_stockId_Create"]: {
	__typename: "TicketingDocAccessFields_sessions_inventories_stocks_stockId_Create",
	permission: boolean
};
	["TicketingDocAccessFields_sessions_inventories_stocks_stockId_Read"]: {
	__typename: "TicketingDocAccessFields_sessions_inventories_stocks_stockId_Read",
	permission: boolean
};
	["TicketingDocAccessFields_sessions_inventories_stocks_stockId_Update"]: {
	__typename: "TicketingDocAccessFields_sessions_inventories_stocks_stockId_Update",
	permission: boolean
};
	["TicketingDocAccessFields_sessions_inventories_stocks_stockId_Delete"]: {
	__typename: "TicketingDocAccessFields_sessions_inventories_stocks_stockId_Delete",
	permission: boolean
};
	["TicketingDocAccessFields_sessions_inventories_stocks_remaining"]: {
	__typename: "TicketingDocAccessFields_sessions_inventories_stocks_remaining",
	create?: GraphQLTypes["TicketingDocAccessFields_sessions_inventories_stocks_remaining_Create"] | undefined,
	read?: GraphQLTypes["TicketingDocAccessFields_sessions_inventories_stocks_remaining_Read"] | undefined,
	update?: GraphQLTypes["TicketingDocAccessFields_sessions_inventories_stocks_remaining_Update"] | undefined,
	delete?: GraphQLTypes["TicketingDocAccessFields_sessions_inventories_stocks_remaining_Delete"] | undefined
};
	["TicketingDocAccessFields_sessions_inventories_stocks_remaining_Create"]: {
	__typename: "TicketingDocAccessFields_sessions_inventories_stocks_remaining_Create",
	permission: boolean
};
	["TicketingDocAccessFields_sessions_inventories_stocks_remaining_Read"]: {
	__typename: "TicketingDocAccessFields_sessions_inventories_stocks_remaining_Read",
	permission: boolean
};
	["TicketingDocAccessFields_sessions_inventories_stocks_remaining_Update"]: {
	__typename: "TicketingDocAccessFields_sessions_inventories_stocks_remaining_Update",
	permission: boolean
};
	["TicketingDocAccessFields_sessions_inventories_stocks_remaining_Delete"]: {
	__typename: "TicketingDocAccessFields_sessions_inventories_stocks_remaining_Delete",
	permission: boolean
};
	["TicketingDocAccessFields_sessions_inventories_stocks_sold"]: {
	__typename: "TicketingDocAccessFields_sessions_inventories_stocks_sold",
	create?: GraphQLTypes["TicketingDocAccessFields_sessions_inventories_stocks_sold_Create"] | undefined,
	read?: GraphQLTypes["TicketingDocAccessFields_sessions_inventories_stocks_sold_Read"] | undefined,
	update?: GraphQLTypes["TicketingDocAccessFields_sessions_inventories_stocks_sold_Update"] | undefined,
	delete?: GraphQLTypes["TicketingDocAccessFields_sessions_inventories_stocks_sold_Delete"] | undefined
};
	["TicketingDocAccessFields_sessions_inventories_stocks_sold_Create"]: {
	__typename: "TicketingDocAccessFields_sessions_inventories_stocks_sold_Create",
	permission: boolean
};
	["TicketingDocAccessFields_sessions_inventories_stocks_sold_Read"]: {
	__typename: "TicketingDocAccessFields_sessions_inventories_stocks_sold_Read",
	permission: boolean
};
	["TicketingDocAccessFields_sessions_inventories_stocks_sold_Update"]: {
	__typename: "TicketingDocAccessFields_sessions_inventories_stocks_sold_Update",
	permission: boolean
};
	["TicketingDocAccessFields_sessions_inventories_stocks_sold_Delete"]: {
	__typename: "TicketingDocAccessFields_sessions_inventories_stocks_sold_Delete",
	permission: boolean
};
	["TicketingDocAccessFields_sessions_inventories_stocks_onHold"]: {
	__typename: "TicketingDocAccessFields_sessions_inventories_stocks_onHold",
	create?: GraphQLTypes["TicketingDocAccessFields_sessions_inventories_stocks_onHold_Create"] | undefined,
	read?: GraphQLTypes["TicketingDocAccessFields_sessions_inventories_stocks_onHold_Read"] | undefined,
	update?: GraphQLTypes["TicketingDocAccessFields_sessions_inventories_stocks_onHold_Update"] | undefined,
	delete?: GraphQLTypes["TicketingDocAccessFields_sessions_inventories_stocks_onHold_Delete"] | undefined
};
	["TicketingDocAccessFields_sessions_inventories_stocks_onHold_Create"]: {
	__typename: "TicketingDocAccessFields_sessions_inventories_stocks_onHold_Create",
	permission: boolean
};
	["TicketingDocAccessFields_sessions_inventories_stocks_onHold_Read"]: {
	__typename: "TicketingDocAccessFields_sessions_inventories_stocks_onHold_Read",
	permission: boolean
};
	["TicketingDocAccessFields_sessions_inventories_stocks_onHold_Update"]: {
	__typename: "TicketingDocAccessFields_sessions_inventories_stocks_onHold_Update",
	permission: boolean
};
	["TicketingDocAccessFields_sessions_inventories_stocks_onHold_Delete"]: {
	__typename: "TicketingDocAccessFields_sessions_inventories_stocks_onHold_Delete",
	permission: boolean
};
	["TicketingDocAccessFields_sessions_inventories_stocks_checkedIn"]: {
	__typename: "TicketingDocAccessFields_sessions_inventories_stocks_checkedIn",
	create?: GraphQLTypes["TicketingDocAccessFields_sessions_inventories_stocks_checkedIn_Create"] | undefined,
	read?: GraphQLTypes["TicketingDocAccessFields_sessions_inventories_stocks_checkedIn_Read"] | undefined,
	update?: GraphQLTypes["TicketingDocAccessFields_sessions_inventories_stocks_checkedIn_Update"] | undefined,
	delete?: GraphQLTypes["TicketingDocAccessFields_sessions_inventories_stocks_checkedIn_Delete"] | undefined
};
	["TicketingDocAccessFields_sessions_inventories_stocks_checkedIn_Create"]: {
	__typename: "TicketingDocAccessFields_sessions_inventories_stocks_checkedIn_Create",
	permission: boolean
};
	["TicketingDocAccessFields_sessions_inventories_stocks_checkedIn_Read"]: {
	__typename: "TicketingDocAccessFields_sessions_inventories_stocks_checkedIn_Read",
	permission: boolean
};
	["TicketingDocAccessFields_sessions_inventories_stocks_checkedIn_Update"]: {
	__typename: "TicketingDocAccessFields_sessions_inventories_stocks_checkedIn_Update",
	permission: boolean
};
	["TicketingDocAccessFields_sessions_inventories_stocks_checkedIn_Delete"]: {
	__typename: "TicketingDocAccessFields_sessions_inventories_stocks_checkedIn_Delete",
	permission: boolean
};
	["TicketingDocAccessFields_sessions_inventories_remarks"]: {
	__typename: "TicketingDocAccessFields_sessions_inventories_remarks",
	create?: GraphQLTypes["TicketingDocAccessFields_sessions_inventories_remarks_Create"] | undefined,
	read?: GraphQLTypes["TicketingDocAccessFields_sessions_inventories_remarks_Read"] | undefined,
	update?: GraphQLTypes["TicketingDocAccessFields_sessions_inventories_remarks_Update"] | undefined,
	delete?: GraphQLTypes["TicketingDocAccessFields_sessions_inventories_remarks_Delete"] | undefined
};
	["TicketingDocAccessFields_sessions_inventories_remarks_Create"]: {
	__typename: "TicketingDocAccessFields_sessions_inventories_remarks_Create",
	permission: boolean
};
	["TicketingDocAccessFields_sessions_inventories_remarks_Read"]: {
	__typename: "TicketingDocAccessFields_sessions_inventories_remarks_Read",
	permission: boolean
};
	["TicketingDocAccessFields_sessions_inventories_remarks_Update"]: {
	__typename: "TicketingDocAccessFields_sessions_inventories_remarks_Update",
	permission: boolean
};
	["TicketingDocAccessFields_sessions_inventories_remarks_Delete"]: {
	__typename: "TicketingDocAccessFields_sessions_inventories_remarks_Delete",
	permission: boolean
};
	["TicketingDocAccessFields_sessions_id"]: {
	__typename: "TicketingDocAccessFields_sessions_id",
	create?: GraphQLTypes["TicketingDocAccessFields_sessions_id_Create"] | undefined,
	read?: GraphQLTypes["TicketingDocAccessFields_sessions_id_Read"] | undefined,
	update?: GraphQLTypes["TicketingDocAccessFields_sessions_id_Update"] | undefined,
	delete?: GraphQLTypes["TicketingDocAccessFields_sessions_id_Delete"] | undefined
};
	["TicketingDocAccessFields_sessions_id_Create"]: {
	__typename: "TicketingDocAccessFields_sessions_id_Create",
	permission: boolean
};
	["TicketingDocAccessFields_sessions_id_Read"]: {
	__typename: "TicketingDocAccessFields_sessions_id_Read",
	permission: boolean
};
	["TicketingDocAccessFields_sessions_id_Update"]: {
	__typename: "TicketingDocAccessFields_sessions_id_Update",
	permission: boolean
};
	["TicketingDocAccessFields_sessions_id_Delete"]: {
	__typename: "TicketingDocAccessFields_sessions_id_Delete",
	permission: boolean
};
	["TicketingDocAccessFields_updatedAt"]: {
	__typename: "TicketingDocAccessFields_updatedAt",
	create?: GraphQLTypes["TicketingDocAccessFields_updatedAt_Create"] | undefined,
	read?: GraphQLTypes["TicketingDocAccessFields_updatedAt_Read"] | undefined,
	update?: GraphQLTypes["TicketingDocAccessFields_updatedAt_Update"] | undefined,
	delete?: GraphQLTypes["TicketingDocAccessFields_updatedAt_Delete"] | undefined
};
	["TicketingDocAccessFields_updatedAt_Create"]: {
	__typename: "TicketingDocAccessFields_updatedAt_Create",
	permission: boolean
};
	["TicketingDocAccessFields_updatedAt_Read"]: {
	__typename: "TicketingDocAccessFields_updatedAt_Read",
	permission: boolean
};
	["TicketingDocAccessFields_updatedAt_Update"]: {
	__typename: "TicketingDocAccessFields_updatedAt_Update",
	permission: boolean
};
	["TicketingDocAccessFields_updatedAt_Delete"]: {
	__typename: "TicketingDocAccessFields_updatedAt_Delete",
	permission: boolean
};
	["TicketingDocAccessFields_createdAt"]: {
	__typename: "TicketingDocAccessFields_createdAt",
	create?: GraphQLTypes["TicketingDocAccessFields_createdAt_Create"] | undefined,
	read?: GraphQLTypes["TicketingDocAccessFields_createdAt_Read"] | undefined,
	update?: GraphQLTypes["TicketingDocAccessFields_createdAt_Update"] | undefined,
	delete?: GraphQLTypes["TicketingDocAccessFields_createdAt_Delete"] | undefined
};
	["TicketingDocAccessFields_createdAt_Create"]: {
	__typename: "TicketingDocAccessFields_createdAt_Create",
	permission: boolean
};
	["TicketingDocAccessFields_createdAt_Read"]: {
	__typename: "TicketingDocAccessFields_createdAt_Read",
	permission: boolean
};
	["TicketingDocAccessFields_createdAt_Update"]: {
	__typename: "TicketingDocAccessFields_createdAt_Update",
	permission: boolean
};
	["TicketingDocAccessFields_createdAt_Delete"]: {
	__typename: "TicketingDocAccessFields_createdAt_Delete",
	permission: boolean
};
	["TicketingCreateDocAccess"]: {
	__typename: "TicketingCreateDocAccess",
	permission: boolean,
	where?: GraphQLTypes["JSONObject"] | undefined
};
	["TicketingReadDocAccess"]: {
	__typename: "TicketingReadDocAccess",
	permission: boolean,
	where?: GraphQLTypes["JSONObject"] | undefined
};
	["TicketingUpdateDocAccess"]: {
	__typename: "TicketingUpdateDocAccess",
	permission: boolean,
	where?: GraphQLTypes["JSONObject"] | undefined
};
	["TicketingDeleteDocAccess"]: {
	__typename: "TicketingDeleteDocAccess",
	permission: boolean,
	where?: GraphQLTypes["JSONObject"] | undefined
};
	["Products"]: {
	__typename: "Products",
	docs?: Array<GraphQLTypes["Product"] | undefined> | undefined,
	hasNextPage?: boolean | undefined,
	hasPrevPage?: boolean | undefined,
	limit?: number | undefined,
	nextPage?: number | undefined,
	offset?: number | undefined,
	page?: number | undefined,
	pagingCounter?: number | undefined,
	prevPage?: number | undefined,
	totalDocs?: number | undefined,
	totalPages?: number | undefined
};
	["Product_where"]: {
		status?: GraphQLTypes["Product_status_operator"] | undefined,
	creator?: GraphQLTypes["Product_creator_operator"] | undefined,
	productType?: GraphQLTypes["Product_productType_operator"] | undefined,
	productName?: GraphQLTypes["Product_productName_operator"] | undefined,
	referenceId?: GraphQLTypes["Product_referenceId_operator"] | undefined,
	linkedEvent?: GraphQLTypes["Product_linkedEvent_operator"] | undefined,
	CheckInLevel?: GraphQLTypes["Product_CheckInLevel_operator"] | undefined,
	thumbnail?: GraphQLTypes["Product_thumbnail_operator"] | undefined,
	displayPrice?: GraphQLTypes["Product_displayPrice_operator"] | undefined,
	transactionType?: GraphQLTypes["Product_transactionType_operator"] | undefined,
	website?: GraphQLTypes["Product_website_operator"] | undefined,
	tnc?: GraphQLTypes["Product_tnc_operator"] | undefined,
	remarks?: GraphQLTypes["Product_remarks_operator"] | undefined,
	sessions__variantId?: GraphQLTypes["Product_sessions__variantId_operator"] | undefined,
	sessions__productId?: GraphQLTypes["Product_sessions__productId_operator"] | undefined,
	sessions__name?: GraphQLTypes["Product_sessions__name_operator"] | undefined,
	sessions__inventories__quantity?: GraphQLTypes["Product_sessions__inventories__quantity_operator"] | undefined,
	sessions__inventories__unitPrice?: GraphQLTypes["Product_sessions__inventories__unitPrice_operator"] | undefined,
	sessions__inventories__handlingFee?: GraphQLTypes["Product_sessions__inventories__handlingFee_operator"] | undefined,
	sessions__inventories__totalCost?: GraphQLTypes["Product_sessions__inventories__totalCost_operator"] | undefined,
	sessions__inventories__minPurchase?: GraphQLTypes["Product_sessions__inventories__minPurchase_operator"] | undefined,
	sessions__inventories__maxPurchase?: GraphQLTypes["Product_sessions__inventories__maxPurchase_operator"] | undefined,
	sessions__inventories__stocks__stockId?: GraphQLTypes["Product_sessions__inventories__stocks__stockId_operator"] | undefined,
	sessions__inventories__stocks__remaining?: GraphQLTypes["Product_sessions__inventories__stocks__remaining_operator"] | undefined,
	sessions__inventories__stocks__sold?: GraphQLTypes["Product_sessions__inventories__stocks__sold_operator"] | undefined,
	sessions__inventories__stocks__onHold?: GraphQLTypes["Product_sessions__inventories__stocks__onHold_operator"] | undefined,
	sessions__inventories__stocks__checkedIn?: GraphQLTypes["Product_sessions__inventories__stocks__checkedIn_operator"] | undefined,
	sessions__id?: GraphQLTypes["Product_sessions__id_operator"] | undefined,
	updatedAt?: GraphQLTypes["Product_updatedAt_operator"] | undefined,
	createdAt?: GraphQLTypes["Product_createdAt_operator"] | undefined,
	id?: GraphQLTypes["Product_id_operator"] | undefined,
	AND?: Array<GraphQLTypes["Product_where_and"] | undefined> | undefined,
	OR?: Array<GraphQLTypes["Product_where_or"] | undefined> | undefined
};
	["Product_status_operator"]: {
		equals?: GraphQLTypes["Product_status_Input"] | undefined,
	not_equals?: GraphQLTypes["Product_status_Input"] | undefined,
	in?: Array<GraphQLTypes["Product_status_Input"] | undefined> | undefined,
	not_in?: Array<GraphQLTypes["Product_status_Input"] | undefined> | undefined,
	all?: Array<GraphQLTypes["Product_status_Input"] | undefined> | undefined,
	exists?: boolean | undefined
};
	["Product_status_Input"]: Product_status_Input;
	["Product_creator_operator"]: {
		equals?: GraphQLTypes["JSON"] | undefined,
	not_equals?: GraphQLTypes["JSON"] | undefined,
	in?: Array<GraphQLTypes["JSON"] | undefined> | undefined,
	not_in?: Array<GraphQLTypes["JSON"] | undefined> | undefined,
	all?: Array<GraphQLTypes["JSON"] | undefined> | undefined,
	exists?: boolean | undefined
};
	["Product_productType_operator"]: {
		equals?: GraphQLTypes["Product_productType_Input"] | undefined,
	not_equals?: GraphQLTypes["Product_productType_Input"] | undefined,
	like?: GraphQLTypes["Product_productType_Input"] | undefined,
	contains?: GraphQLTypes["Product_productType_Input"] | undefined
};
	["Product_productType_Input"]: Product_productType_Input;
	["Product_productName_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined
};
	["Product_referenceId_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Product_linkedEvent_operator"]: {
		equals?: GraphQLTypes["JSON"] | undefined,
	not_equals?: GraphQLTypes["JSON"] | undefined,
	in?: Array<GraphQLTypes["JSON"] | undefined> | undefined,
	not_in?: Array<GraphQLTypes["JSON"] | undefined> | undefined,
	all?: Array<GraphQLTypes["JSON"] | undefined> | undefined
};
	["Product_CheckInLevel_operator"]: {
		equals?: GraphQLTypes["Product_CheckInLevel_Input"] | undefined,
	not_equals?: GraphQLTypes["Product_CheckInLevel_Input"] | undefined,
	in?: Array<GraphQLTypes["Product_CheckInLevel_Input"] | undefined> | undefined,
	not_in?: Array<GraphQLTypes["Product_CheckInLevel_Input"] | undefined> | undefined,
	all?: Array<GraphQLTypes["Product_CheckInLevel_Input"] | undefined> | undefined
};
	["Product_CheckInLevel_Input"]: Product_CheckInLevel_Input;
	["Product_thumbnail_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined
};
	["Product_displayPrice_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined
};
	["Product_transactionType_operator"]: {
		equals?: GraphQLTypes["Product_transactionType_Input"] | undefined,
	not_equals?: GraphQLTypes["Product_transactionType_Input"] | undefined,
	like?: GraphQLTypes["Product_transactionType_Input"] | undefined,
	contains?: GraphQLTypes["Product_transactionType_Input"] | undefined
};
	["Product_transactionType_Input"]: Product_transactionType_Input;
	["Product_website_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined
};
	["Product_tnc_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined
};
	["Product_remarks_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	exists?: boolean | undefined
};
	["Product_sessions__variantId_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Product_sessions__productId_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Product_sessions__name_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined
};
	["Product_sessions__inventories__quantity_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined
};
	["Product_sessions__inventories__unitPrice_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined
};
	["Product_sessions__inventories__handlingFee_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined
};
	["Product_sessions__inventories__totalCost_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Product_sessions__inventories__minPurchase_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined
};
	["Product_sessions__inventories__maxPurchase_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined
};
	["Product_sessions__inventories__stocks__stockId_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Product_sessions__inventories__stocks__remaining_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Product_sessions__inventories__stocks__sold_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Product_sessions__inventories__stocks__onHold_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Product_sessions__inventories__stocks__checkedIn_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Product_sessions__id_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Product_updatedAt_operator"]: {
		equals?: GraphQLTypes["DateTime"] | undefined,
	not_equals?: GraphQLTypes["DateTime"] | undefined,
	greater_than_equal?: GraphQLTypes["DateTime"] | undefined,
	greater_than?: GraphQLTypes["DateTime"] | undefined,
	less_than_equal?: GraphQLTypes["DateTime"] | undefined,
	less_than?: GraphQLTypes["DateTime"] | undefined,
	like?: GraphQLTypes["DateTime"] | undefined,
	exists?: boolean | undefined
};
	["Product_createdAt_operator"]: {
		equals?: GraphQLTypes["DateTime"] | undefined,
	not_equals?: GraphQLTypes["DateTime"] | undefined,
	greater_than_equal?: GraphQLTypes["DateTime"] | undefined,
	greater_than?: GraphQLTypes["DateTime"] | undefined,
	less_than_equal?: GraphQLTypes["DateTime"] | undefined,
	less_than?: GraphQLTypes["DateTime"] | undefined,
	like?: GraphQLTypes["DateTime"] | undefined,
	exists?: boolean | undefined
};
	["Product_id_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Product_where_and"]: {
		status?: GraphQLTypes["Product_status_operator"] | undefined,
	creator?: GraphQLTypes["Product_creator_operator"] | undefined,
	productType?: GraphQLTypes["Product_productType_operator"] | undefined,
	productName?: GraphQLTypes["Product_productName_operator"] | undefined,
	referenceId?: GraphQLTypes["Product_referenceId_operator"] | undefined,
	linkedEvent?: GraphQLTypes["Product_linkedEvent_operator"] | undefined,
	CheckInLevel?: GraphQLTypes["Product_CheckInLevel_operator"] | undefined,
	thumbnail?: GraphQLTypes["Product_thumbnail_operator"] | undefined,
	displayPrice?: GraphQLTypes["Product_displayPrice_operator"] | undefined,
	transactionType?: GraphQLTypes["Product_transactionType_operator"] | undefined,
	website?: GraphQLTypes["Product_website_operator"] | undefined,
	tnc?: GraphQLTypes["Product_tnc_operator"] | undefined,
	remarks?: GraphQLTypes["Product_remarks_operator"] | undefined,
	sessions__variantId?: GraphQLTypes["Product_sessions__variantId_operator"] | undefined,
	sessions__productId?: GraphQLTypes["Product_sessions__productId_operator"] | undefined,
	sessions__name?: GraphQLTypes["Product_sessions__name_operator"] | undefined,
	sessions__inventories__quantity?: GraphQLTypes["Product_sessions__inventories__quantity_operator"] | undefined,
	sessions__inventories__unitPrice?: GraphQLTypes["Product_sessions__inventories__unitPrice_operator"] | undefined,
	sessions__inventories__handlingFee?: GraphQLTypes["Product_sessions__inventories__handlingFee_operator"] | undefined,
	sessions__inventories__totalCost?: GraphQLTypes["Product_sessions__inventories__totalCost_operator"] | undefined,
	sessions__inventories__minPurchase?: GraphQLTypes["Product_sessions__inventories__minPurchase_operator"] | undefined,
	sessions__inventories__maxPurchase?: GraphQLTypes["Product_sessions__inventories__maxPurchase_operator"] | undefined,
	sessions__inventories__stocks__stockId?: GraphQLTypes["Product_sessions__inventories__stocks__stockId_operator"] | undefined,
	sessions__inventories__stocks__remaining?: GraphQLTypes["Product_sessions__inventories__stocks__remaining_operator"] | undefined,
	sessions__inventories__stocks__sold?: GraphQLTypes["Product_sessions__inventories__stocks__sold_operator"] | undefined,
	sessions__inventories__stocks__onHold?: GraphQLTypes["Product_sessions__inventories__stocks__onHold_operator"] | undefined,
	sessions__inventories__stocks__checkedIn?: GraphQLTypes["Product_sessions__inventories__stocks__checkedIn_operator"] | undefined,
	sessions__id?: GraphQLTypes["Product_sessions__id_operator"] | undefined,
	updatedAt?: GraphQLTypes["Product_updatedAt_operator"] | undefined,
	createdAt?: GraphQLTypes["Product_createdAt_operator"] | undefined,
	id?: GraphQLTypes["Product_id_operator"] | undefined,
	AND?: Array<GraphQLTypes["Product_where_and"] | undefined> | undefined,
	OR?: Array<GraphQLTypes["Product_where_or"] | undefined> | undefined
};
	["Product_where_or"]: {
		status?: GraphQLTypes["Product_status_operator"] | undefined,
	creator?: GraphQLTypes["Product_creator_operator"] | undefined,
	productType?: GraphQLTypes["Product_productType_operator"] | undefined,
	productName?: GraphQLTypes["Product_productName_operator"] | undefined,
	referenceId?: GraphQLTypes["Product_referenceId_operator"] | undefined,
	linkedEvent?: GraphQLTypes["Product_linkedEvent_operator"] | undefined,
	CheckInLevel?: GraphQLTypes["Product_CheckInLevel_operator"] | undefined,
	thumbnail?: GraphQLTypes["Product_thumbnail_operator"] | undefined,
	displayPrice?: GraphQLTypes["Product_displayPrice_operator"] | undefined,
	transactionType?: GraphQLTypes["Product_transactionType_operator"] | undefined,
	website?: GraphQLTypes["Product_website_operator"] | undefined,
	tnc?: GraphQLTypes["Product_tnc_operator"] | undefined,
	remarks?: GraphQLTypes["Product_remarks_operator"] | undefined,
	sessions__variantId?: GraphQLTypes["Product_sessions__variantId_operator"] | undefined,
	sessions__productId?: GraphQLTypes["Product_sessions__productId_operator"] | undefined,
	sessions__name?: GraphQLTypes["Product_sessions__name_operator"] | undefined,
	sessions__inventories__quantity?: GraphQLTypes["Product_sessions__inventories__quantity_operator"] | undefined,
	sessions__inventories__unitPrice?: GraphQLTypes["Product_sessions__inventories__unitPrice_operator"] | undefined,
	sessions__inventories__handlingFee?: GraphQLTypes["Product_sessions__inventories__handlingFee_operator"] | undefined,
	sessions__inventories__totalCost?: GraphQLTypes["Product_sessions__inventories__totalCost_operator"] | undefined,
	sessions__inventories__minPurchase?: GraphQLTypes["Product_sessions__inventories__minPurchase_operator"] | undefined,
	sessions__inventories__maxPurchase?: GraphQLTypes["Product_sessions__inventories__maxPurchase_operator"] | undefined,
	sessions__inventories__stocks__stockId?: GraphQLTypes["Product_sessions__inventories__stocks__stockId_operator"] | undefined,
	sessions__inventories__stocks__remaining?: GraphQLTypes["Product_sessions__inventories__stocks__remaining_operator"] | undefined,
	sessions__inventories__stocks__sold?: GraphQLTypes["Product_sessions__inventories__stocks__sold_operator"] | undefined,
	sessions__inventories__stocks__onHold?: GraphQLTypes["Product_sessions__inventories__stocks__onHold_operator"] | undefined,
	sessions__inventories__stocks__checkedIn?: GraphQLTypes["Product_sessions__inventories__stocks__checkedIn_operator"] | undefined,
	sessions__id?: GraphQLTypes["Product_sessions__id_operator"] | undefined,
	updatedAt?: GraphQLTypes["Product_updatedAt_operator"] | undefined,
	createdAt?: GraphQLTypes["Product_createdAt_operator"] | undefined,
	id?: GraphQLTypes["Product_id_operator"] | undefined,
	AND?: Array<GraphQLTypes["Product_where_and"] | undefined> | undefined,
	OR?: Array<GraphQLTypes["Product_where_or"] | undefined> | undefined
};
	["countProducts"]: {
	__typename: "countProducts",
	totalDocs?: number | undefined
};
	["productsDocAccess"]: {
	__typename: "productsDocAccess",
	fields?: GraphQLTypes["ProductsDocAccessFields"] | undefined,
	create?: GraphQLTypes["ProductsCreateDocAccess"] | undefined,
	read?: GraphQLTypes["ProductsReadDocAccess"] | undefined,
	update?: GraphQLTypes["ProductsUpdateDocAccess"] | undefined,
	delete?: GraphQLTypes["ProductsDeleteDocAccess"] | undefined
};
	["ProductsDocAccessFields"]: {
	__typename: "ProductsDocAccessFields",
	status?: GraphQLTypes["ProductsDocAccessFields_status"] | undefined,
	creator?: GraphQLTypes["ProductsDocAccessFields_creator"] | undefined,
	productType?: GraphQLTypes["ProductsDocAccessFields_productType"] | undefined,
	productName?: GraphQLTypes["ProductsDocAccessFields_productName"] | undefined,
	referenceId?: GraphQLTypes["ProductsDocAccessFields_referenceId"] | undefined,
	linkedEvent?: GraphQLTypes["ProductsDocAccessFields_linkedEvent"] | undefined,
	CheckInLevel?: GraphQLTypes["ProductsDocAccessFields_CheckInLevel"] | undefined,
	thumbnail?: GraphQLTypes["ProductsDocAccessFields_thumbnail"] | undefined,
	displayPrice?: GraphQLTypes["ProductsDocAccessFields_displayPrice"] | undefined,
	transactionType?: GraphQLTypes["ProductsDocAccessFields_transactionType"] | undefined,
	website?: GraphQLTypes["ProductsDocAccessFields_website"] | undefined,
	tnc?: GraphQLTypes["ProductsDocAccessFields_tnc"] | undefined,
	remarks?: GraphQLTypes["ProductsDocAccessFields_remarks"] | undefined,
	sessions?: GraphQLTypes["ProductsDocAccessFields_sessions"] | undefined,
	updatedAt?: GraphQLTypes["ProductsDocAccessFields_updatedAt"] | undefined,
	createdAt?: GraphQLTypes["ProductsDocAccessFields_createdAt"] | undefined
};
	["ProductsDocAccessFields_status"]: {
	__typename: "ProductsDocAccessFields_status",
	create?: GraphQLTypes["ProductsDocAccessFields_status_Create"] | undefined,
	read?: GraphQLTypes["ProductsDocAccessFields_status_Read"] | undefined,
	update?: GraphQLTypes["ProductsDocAccessFields_status_Update"] | undefined,
	delete?: GraphQLTypes["ProductsDocAccessFields_status_Delete"] | undefined
};
	["ProductsDocAccessFields_status_Create"]: {
	__typename: "ProductsDocAccessFields_status_Create",
	permission: boolean
};
	["ProductsDocAccessFields_status_Read"]: {
	__typename: "ProductsDocAccessFields_status_Read",
	permission: boolean
};
	["ProductsDocAccessFields_status_Update"]: {
	__typename: "ProductsDocAccessFields_status_Update",
	permission: boolean
};
	["ProductsDocAccessFields_status_Delete"]: {
	__typename: "ProductsDocAccessFields_status_Delete",
	permission: boolean
};
	["ProductsDocAccessFields_creator"]: {
	__typename: "ProductsDocAccessFields_creator",
	create?: GraphQLTypes["ProductsDocAccessFields_creator_Create"] | undefined,
	read?: GraphQLTypes["ProductsDocAccessFields_creator_Read"] | undefined,
	update?: GraphQLTypes["ProductsDocAccessFields_creator_Update"] | undefined,
	delete?: GraphQLTypes["ProductsDocAccessFields_creator_Delete"] | undefined
};
	["ProductsDocAccessFields_creator_Create"]: {
	__typename: "ProductsDocAccessFields_creator_Create",
	permission: boolean
};
	["ProductsDocAccessFields_creator_Read"]: {
	__typename: "ProductsDocAccessFields_creator_Read",
	permission: boolean
};
	["ProductsDocAccessFields_creator_Update"]: {
	__typename: "ProductsDocAccessFields_creator_Update",
	permission: boolean
};
	["ProductsDocAccessFields_creator_Delete"]: {
	__typename: "ProductsDocAccessFields_creator_Delete",
	permission: boolean
};
	["ProductsDocAccessFields_productType"]: {
	__typename: "ProductsDocAccessFields_productType",
	create?: GraphQLTypes["ProductsDocAccessFields_productType_Create"] | undefined,
	read?: GraphQLTypes["ProductsDocAccessFields_productType_Read"] | undefined,
	update?: GraphQLTypes["ProductsDocAccessFields_productType_Update"] | undefined,
	delete?: GraphQLTypes["ProductsDocAccessFields_productType_Delete"] | undefined
};
	["ProductsDocAccessFields_productType_Create"]: {
	__typename: "ProductsDocAccessFields_productType_Create",
	permission: boolean
};
	["ProductsDocAccessFields_productType_Read"]: {
	__typename: "ProductsDocAccessFields_productType_Read",
	permission: boolean
};
	["ProductsDocAccessFields_productType_Update"]: {
	__typename: "ProductsDocAccessFields_productType_Update",
	permission: boolean
};
	["ProductsDocAccessFields_productType_Delete"]: {
	__typename: "ProductsDocAccessFields_productType_Delete",
	permission: boolean
};
	["ProductsDocAccessFields_productName"]: {
	__typename: "ProductsDocAccessFields_productName",
	create?: GraphQLTypes["ProductsDocAccessFields_productName_Create"] | undefined,
	read?: GraphQLTypes["ProductsDocAccessFields_productName_Read"] | undefined,
	update?: GraphQLTypes["ProductsDocAccessFields_productName_Update"] | undefined,
	delete?: GraphQLTypes["ProductsDocAccessFields_productName_Delete"] | undefined
};
	["ProductsDocAccessFields_productName_Create"]: {
	__typename: "ProductsDocAccessFields_productName_Create",
	permission: boolean
};
	["ProductsDocAccessFields_productName_Read"]: {
	__typename: "ProductsDocAccessFields_productName_Read",
	permission: boolean
};
	["ProductsDocAccessFields_productName_Update"]: {
	__typename: "ProductsDocAccessFields_productName_Update",
	permission: boolean
};
	["ProductsDocAccessFields_productName_Delete"]: {
	__typename: "ProductsDocAccessFields_productName_Delete",
	permission: boolean
};
	["ProductsDocAccessFields_referenceId"]: {
	__typename: "ProductsDocAccessFields_referenceId",
	create?: GraphQLTypes["ProductsDocAccessFields_referenceId_Create"] | undefined,
	read?: GraphQLTypes["ProductsDocAccessFields_referenceId_Read"] | undefined,
	update?: GraphQLTypes["ProductsDocAccessFields_referenceId_Update"] | undefined,
	delete?: GraphQLTypes["ProductsDocAccessFields_referenceId_Delete"] | undefined
};
	["ProductsDocAccessFields_referenceId_Create"]: {
	__typename: "ProductsDocAccessFields_referenceId_Create",
	permission: boolean
};
	["ProductsDocAccessFields_referenceId_Read"]: {
	__typename: "ProductsDocAccessFields_referenceId_Read",
	permission: boolean
};
	["ProductsDocAccessFields_referenceId_Update"]: {
	__typename: "ProductsDocAccessFields_referenceId_Update",
	permission: boolean
};
	["ProductsDocAccessFields_referenceId_Delete"]: {
	__typename: "ProductsDocAccessFields_referenceId_Delete",
	permission: boolean
};
	["ProductsDocAccessFields_linkedEvent"]: {
	__typename: "ProductsDocAccessFields_linkedEvent",
	create?: GraphQLTypes["ProductsDocAccessFields_linkedEvent_Create"] | undefined,
	read?: GraphQLTypes["ProductsDocAccessFields_linkedEvent_Read"] | undefined,
	update?: GraphQLTypes["ProductsDocAccessFields_linkedEvent_Update"] | undefined,
	delete?: GraphQLTypes["ProductsDocAccessFields_linkedEvent_Delete"] | undefined
};
	["ProductsDocAccessFields_linkedEvent_Create"]: {
	__typename: "ProductsDocAccessFields_linkedEvent_Create",
	permission: boolean
};
	["ProductsDocAccessFields_linkedEvent_Read"]: {
	__typename: "ProductsDocAccessFields_linkedEvent_Read",
	permission: boolean
};
	["ProductsDocAccessFields_linkedEvent_Update"]: {
	__typename: "ProductsDocAccessFields_linkedEvent_Update",
	permission: boolean
};
	["ProductsDocAccessFields_linkedEvent_Delete"]: {
	__typename: "ProductsDocAccessFields_linkedEvent_Delete",
	permission: boolean
};
	["ProductsDocAccessFields_CheckInLevel"]: {
	__typename: "ProductsDocAccessFields_CheckInLevel",
	create?: GraphQLTypes["ProductsDocAccessFields_CheckInLevel_Create"] | undefined,
	read?: GraphQLTypes["ProductsDocAccessFields_CheckInLevel_Read"] | undefined,
	update?: GraphQLTypes["ProductsDocAccessFields_CheckInLevel_Update"] | undefined,
	delete?: GraphQLTypes["ProductsDocAccessFields_CheckInLevel_Delete"] | undefined
};
	["ProductsDocAccessFields_CheckInLevel_Create"]: {
	__typename: "ProductsDocAccessFields_CheckInLevel_Create",
	permission: boolean
};
	["ProductsDocAccessFields_CheckInLevel_Read"]: {
	__typename: "ProductsDocAccessFields_CheckInLevel_Read",
	permission: boolean
};
	["ProductsDocAccessFields_CheckInLevel_Update"]: {
	__typename: "ProductsDocAccessFields_CheckInLevel_Update",
	permission: boolean
};
	["ProductsDocAccessFields_CheckInLevel_Delete"]: {
	__typename: "ProductsDocAccessFields_CheckInLevel_Delete",
	permission: boolean
};
	["ProductsDocAccessFields_thumbnail"]: {
	__typename: "ProductsDocAccessFields_thumbnail",
	create?: GraphQLTypes["ProductsDocAccessFields_thumbnail_Create"] | undefined,
	read?: GraphQLTypes["ProductsDocAccessFields_thumbnail_Read"] | undefined,
	update?: GraphQLTypes["ProductsDocAccessFields_thumbnail_Update"] | undefined,
	delete?: GraphQLTypes["ProductsDocAccessFields_thumbnail_Delete"] | undefined
};
	["ProductsDocAccessFields_thumbnail_Create"]: {
	__typename: "ProductsDocAccessFields_thumbnail_Create",
	permission: boolean
};
	["ProductsDocAccessFields_thumbnail_Read"]: {
	__typename: "ProductsDocAccessFields_thumbnail_Read",
	permission: boolean
};
	["ProductsDocAccessFields_thumbnail_Update"]: {
	__typename: "ProductsDocAccessFields_thumbnail_Update",
	permission: boolean
};
	["ProductsDocAccessFields_thumbnail_Delete"]: {
	__typename: "ProductsDocAccessFields_thumbnail_Delete",
	permission: boolean
};
	["ProductsDocAccessFields_displayPrice"]: {
	__typename: "ProductsDocAccessFields_displayPrice",
	create?: GraphQLTypes["ProductsDocAccessFields_displayPrice_Create"] | undefined,
	read?: GraphQLTypes["ProductsDocAccessFields_displayPrice_Read"] | undefined,
	update?: GraphQLTypes["ProductsDocAccessFields_displayPrice_Update"] | undefined,
	delete?: GraphQLTypes["ProductsDocAccessFields_displayPrice_Delete"] | undefined
};
	["ProductsDocAccessFields_displayPrice_Create"]: {
	__typename: "ProductsDocAccessFields_displayPrice_Create",
	permission: boolean
};
	["ProductsDocAccessFields_displayPrice_Read"]: {
	__typename: "ProductsDocAccessFields_displayPrice_Read",
	permission: boolean
};
	["ProductsDocAccessFields_displayPrice_Update"]: {
	__typename: "ProductsDocAccessFields_displayPrice_Update",
	permission: boolean
};
	["ProductsDocAccessFields_displayPrice_Delete"]: {
	__typename: "ProductsDocAccessFields_displayPrice_Delete",
	permission: boolean
};
	["ProductsDocAccessFields_transactionType"]: {
	__typename: "ProductsDocAccessFields_transactionType",
	create?: GraphQLTypes["ProductsDocAccessFields_transactionType_Create"] | undefined,
	read?: GraphQLTypes["ProductsDocAccessFields_transactionType_Read"] | undefined,
	update?: GraphQLTypes["ProductsDocAccessFields_transactionType_Update"] | undefined,
	delete?: GraphQLTypes["ProductsDocAccessFields_transactionType_Delete"] | undefined
};
	["ProductsDocAccessFields_transactionType_Create"]: {
	__typename: "ProductsDocAccessFields_transactionType_Create",
	permission: boolean
};
	["ProductsDocAccessFields_transactionType_Read"]: {
	__typename: "ProductsDocAccessFields_transactionType_Read",
	permission: boolean
};
	["ProductsDocAccessFields_transactionType_Update"]: {
	__typename: "ProductsDocAccessFields_transactionType_Update",
	permission: boolean
};
	["ProductsDocAccessFields_transactionType_Delete"]: {
	__typename: "ProductsDocAccessFields_transactionType_Delete",
	permission: boolean
};
	["ProductsDocAccessFields_website"]: {
	__typename: "ProductsDocAccessFields_website",
	create?: GraphQLTypes["ProductsDocAccessFields_website_Create"] | undefined,
	read?: GraphQLTypes["ProductsDocAccessFields_website_Read"] | undefined,
	update?: GraphQLTypes["ProductsDocAccessFields_website_Update"] | undefined,
	delete?: GraphQLTypes["ProductsDocAccessFields_website_Delete"] | undefined
};
	["ProductsDocAccessFields_website_Create"]: {
	__typename: "ProductsDocAccessFields_website_Create",
	permission: boolean
};
	["ProductsDocAccessFields_website_Read"]: {
	__typename: "ProductsDocAccessFields_website_Read",
	permission: boolean
};
	["ProductsDocAccessFields_website_Update"]: {
	__typename: "ProductsDocAccessFields_website_Update",
	permission: boolean
};
	["ProductsDocAccessFields_website_Delete"]: {
	__typename: "ProductsDocAccessFields_website_Delete",
	permission: boolean
};
	["ProductsDocAccessFields_tnc"]: {
	__typename: "ProductsDocAccessFields_tnc",
	create?: GraphQLTypes["ProductsDocAccessFields_tnc_Create"] | undefined,
	read?: GraphQLTypes["ProductsDocAccessFields_tnc_Read"] | undefined,
	update?: GraphQLTypes["ProductsDocAccessFields_tnc_Update"] | undefined,
	delete?: GraphQLTypes["ProductsDocAccessFields_tnc_Delete"] | undefined
};
	["ProductsDocAccessFields_tnc_Create"]: {
	__typename: "ProductsDocAccessFields_tnc_Create",
	permission: boolean
};
	["ProductsDocAccessFields_tnc_Read"]: {
	__typename: "ProductsDocAccessFields_tnc_Read",
	permission: boolean
};
	["ProductsDocAccessFields_tnc_Update"]: {
	__typename: "ProductsDocAccessFields_tnc_Update",
	permission: boolean
};
	["ProductsDocAccessFields_tnc_Delete"]: {
	__typename: "ProductsDocAccessFields_tnc_Delete",
	permission: boolean
};
	["ProductsDocAccessFields_remarks"]: {
	__typename: "ProductsDocAccessFields_remarks",
	create?: GraphQLTypes["ProductsDocAccessFields_remarks_Create"] | undefined,
	read?: GraphQLTypes["ProductsDocAccessFields_remarks_Read"] | undefined,
	update?: GraphQLTypes["ProductsDocAccessFields_remarks_Update"] | undefined,
	delete?: GraphQLTypes["ProductsDocAccessFields_remarks_Delete"] | undefined
};
	["ProductsDocAccessFields_remarks_Create"]: {
	__typename: "ProductsDocAccessFields_remarks_Create",
	permission: boolean
};
	["ProductsDocAccessFields_remarks_Read"]: {
	__typename: "ProductsDocAccessFields_remarks_Read",
	permission: boolean
};
	["ProductsDocAccessFields_remarks_Update"]: {
	__typename: "ProductsDocAccessFields_remarks_Update",
	permission: boolean
};
	["ProductsDocAccessFields_remarks_Delete"]: {
	__typename: "ProductsDocAccessFields_remarks_Delete",
	permission: boolean
};
	["ProductsDocAccessFields_sessions"]: {
	__typename: "ProductsDocAccessFields_sessions",
	create?: GraphQLTypes["ProductsDocAccessFields_sessions_Create"] | undefined,
	read?: GraphQLTypes["ProductsDocAccessFields_sessions_Read"] | undefined,
	update?: GraphQLTypes["ProductsDocAccessFields_sessions_Update"] | undefined,
	delete?: GraphQLTypes["ProductsDocAccessFields_sessions_Delete"] | undefined,
	fields?: GraphQLTypes["ProductsDocAccessFields_sessions_Fields"] | undefined
};
	["ProductsDocAccessFields_sessions_Create"]: {
	__typename: "ProductsDocAccessFields_sessions_Create",
	permission: boolean
};
	["ProductsDocAccessFields_sessions_Read"]: {
	__typename: "ProductsDocAccessFields_sessions_Read",
	permission: boolean
};
	["ProductsDocAccessFields_sessions_Update"]: {
	__typename: "ProductsDocAccessFields_sessions_Update",
	permission: boolean
};
	["ProductsDocAccessFields_sessions_Delete"]: {
	__typename: "ProductsDocAccessFields_sessions_Delete",
	permission: boolean
};
	["ProductsDocAccessFields_sessions_Fields"]: {
	__typename: "ProductsDocAccessFields_sessions_Fields",
	productId?: GraphQLTypes["ProductsDocAccessFields_sessions_productId"] | undefined,
	name?: GraphQLTypes["ProductsDocAccessFields_sessions_name"] | undefined,
	inventories?: GraphQLTypes["ProductsDocAccessFields_sessions_inventories"] | undefined,
	id?: GraphQLTypes["ProductsDocAccessFields_sessions_id"] | undefined
};
	["ProductsDocAccessFields_sessions_productId"]: {
	__typename: "ProductsDocAccessFields_sessions_productId",
	create?: GraphQLTypes["ProductsDocAccessFields_sessions_productId_Create"] | undefined,
	read?: GraphQLTypes["ProductsDocAccessFields_sessions_productId_Read"] | undefined,
	update?: GraphQLTypes["ProductsDocAccessFields_sessions_productId_Update"] | undefined,
	delete?: GraphQLTypes["ProductsDocAccessFields_sessions_productId_Delete"] | undefined
};
	["ProductsDocAccessFields_sessions_productId_Create"]: {
	__typename: "ProductsDocAccessFields_sessions_productId_Create",
	permission: boolean
};
	["ProductsDocAccessFields_sessions_productId_Read"]: {
	__typename: "ProductsDocAccessFields_sessions_productId_Read",
	permission: boolean
};
	["ProductsDocAccessFields_sessions_productId_Update"]: {
	__typename: "ProductsDocAccessFields_sessions_productId_Update",
	permission: boolean
};
	["ProductsDocAccessFields_sessions_productId_Delete"]: {
	__typename: "ProductsDocAccessFields_sessions_productId_Delete",
	permission: boolean
};
	["ProductsDocAccessFields_sessions_name"]: {
	__typename: "ProductsDocAccessFields_sessions_name",
	create?: GraphQLTypes["ProductsDocAccessFields_sessions_name_Create"] | undefined,
	read?: GraphQLTypes["ProductsDocAccessFields_sessions_name_Read"] | undefined,
	update?: GraphQLTypes["ProductsDocAccessFields_sessions_name_Update"] | undefined,
	delete?: GraphQLTypes["ProductsDocAccessFields_sessions_name_Delete"] | undefined
};
	["ProductsDocAccessFields_sessions_name_Create"]: {
	__typename: "ProductsDocAccessFields_sessions_name_Create",
	permission: boolean
};
	["ProductsDocAccessFields_sessions_name_Read"]: {
	__typename: "ProductsDocAccessFields_sessions_name_Read",
	permission: boolean
};
	["ProductsDocAccessFields_sessions_name_Update"]: {
	__typename: "ProductsDocAccessFields_sessions_name_Update",
	permission: boolean
};
	["ProductsDocAccessFields_sessions_name_Delete"]: {
	__typename: "ProductsDocAccessFields_sessions_name_Delete",
	permission: boolean
};
	["ProductsDocAccessFields_sessions_inventories"]: {
	__typename: "ProductsDocAccessFields_sessions_inventories",
	create?: GraphQLTypes["ProductsDocAccessFields_sessions_inventories_Create"] | undefined,
	read?: GraphQLTypes["ProductsDocAccessFields_sessions_inventories_Read"] | undefined,
	update?: GraphQLTypes["ProductsDocAccessFields_sessions_inventories_Update"] | undefined,
	delete?: GraphQLTypes["ProductsDocAccessFields_sessions_inventories_Delete"] | undefined,
	fields?: GraphQLTypes["ProductsDocAccessFields_sessions_inventories_Fields"] | undefined
};
	["ProductsDocAccessFields_sessions_inventories_Create"]: {
	__typename: "ProductsDocAccessFields_sessions_inventories_Create",
	permission: boolean
};
	["ProductsDocAccessFields_sessions_inventories_Read"]: {
	__typename: "ProductsDocAccessFields_sessions_inventories_Read",
	permission: boolean
};
	["ProductsDocAccessFields_sessions_inventories_Update"]: {
	__typename: "ProductsDocAccessFields_sessions_inventories_Update",
	permission: boolean
};
	["ProductsDocAccessFields_sessions_inventories_Delete"]: {
	__typename: "ProductsDocAccessFields_sessions_inventories_Delete",
	permission: boolean
};
	["ProductsDocAccessFields_sessions_inventories_Fields"]: {
	__typename: "ProductsDocAccessFields_sessions_inventories_Fields",
	quantity?: GraphQLTypes["ProductsDocAccessFields_sessions_inventories_quantity"] | undefined,
	unitPrice?: GraphQLTypes["ProductsDocAccessFields_sessions_inventories_unitPrice"] | undefined,
	handlingFee?: GraphQLTypes["ProductsDocAccessFields_sessions_inventories_handlingFee"] | undefined,
	totalCost?: GraphQLTypes["ProductsDocAccessFields_sessions_inventories_totalCost"] | undefined,
	minPurchase?: GraphQLTypes["ProductsDocAccessFields_sessions_inventories_minPurchase"] | undefined,
	maxPurchase?: GraphQLTypes["ProductsDocAccessFields_sessions_inventories_maxPurchase"] | undefined,
	stocks?: GraphQLTypes["ProductsDocAccessFields_sessions_inventories_stocks"] | undefined
};
	["ProductsDocAccessFields_sessions_inventories_quantity"]: {
	__typename: "ProductsDocAccessFields_sessions_inventories_quantity",
	create?: GraphQLTypes["ProductsDocAccessFields_sessions_inventories_quantity_Create"] | undefined,
	read?: GraphQLTypes["ProductsDocAccessFields_sessions_inventories_quantity_Read"] | undefined,
	update?: GraphQLTypes["ProductsDocAccessFields_sessions_inventories_quantity_Update"] | undefined,
	delete?: GraphQLTypes["ProductsDocAccessFields_sessions_inventories_quantity_Delete"] | undefined
};
	["ProductsDocAccessFields_sessions_inventories_quantity_Create"]: {
	__typename: "ProductsDocAccessFields_sessions_inventories_quantity_Create",
	permission: boolean
};
	["ProductsDocAccessFields_sessions_inventories_quantity_Read"]: {
	__typename: "ProductsDocAccessFields_sessions_inventories_quantity_Read",
	permission: boolean
};
	["ProductsDocAccessFields_sessions_inventories_quantity_Update"]: {
	__typename: "ProductsDocAccessFields_sessions_inventories_quantity_Update",
	permission: boolean
};
	["ProductsDocAccessFields_sessions_inventories_quantity_Delete"]: {
	__typename: "ProductsDocAccessFields_sessions_inventories_quantity_Delete",
	permission: boolean
};
	["ProductsDocAccessFields_sessions_inventories_unitPrice"]: {
	__typename: "ProductsDocAccessFields_sessions_inventories_unitPrice",
	create?: GraphQLTypes["ProductsDocAccessFields_sessions_inventories_unitPrice_Create"] | undefined,
	read?: GraphQLTypes["ProductsDocAccessFields_sessions_inventories_unitPrice_Read"] | undefined,
	update?: GraphQLTypes["ProductsDocAccessFields_sessions_inventories_unitPrice_Update"] | undefined,
	delete?: GraphQLTypes["ProductsDocAccessFields_sessions_inventories_unitPrice_Delete"] | undefined
};
	["ProductsDocAccessFields_sessions_inventories_unitPrice_Create"]: {
	__typename: "ProductsDocAccessFields_sessions_inventories_unitPrice_Create",
	permission: boolean
};
	["ProductsDocAccessFields_sessions_inventories_unitPrice_Read"]: {
	__typename: "ProductsDocAccessFields_sessions_inventories_unitPrice_Read",
	permission: boolean
};
	["ProductsDocAccessFields_sessions_inventories_unitPrice_Update"]: {
	__typename: "ProductsDocAccessFields_sessions_inventories_unitPrice_Update",
	permission: boolean
};
	["ProductsDocAccessFields_sessions_inventories_unitPrice_Delete"]: {
	__typename: "ProductsDocAccessFields_sessions_inventories_unitPrice_Delete",
	permission: boolean
};
	["ProductsDocAccessFields_sessions_inventories_handlingFee"]: {
	__typename: "ProductsDocAccessFields_sessions_inventories_handlingFee",
	create?: GraphQLTypes["ProductsDocAccessFields_sessions_inventories_handlingFee_Create"] | undefined,
	read?: GraphQLTypes["ProductsDocAccessFields_sessions_inventories_handlingFee_Read"] | undefined,
	update?: GraphQLTypes["ProductsDocAccessFields_sessions_inventories_handlingFee_Update"] | undefined,
	delete?: GraphQLTypes["ProductsDocAccessFields_sessions_inventories_handlingFee_Delete"] | undefined
};
	["ProductsDocAccessFields_sessions_inventories_handlingFee_Create"]: {
	__typename: "ProductsDocAccessFields_sessions_inventories_handlingFee_Create",
	permission: boolean
};
	["ProductsDocAccessFields_sessions_inventories_handlingFee_Read"]: {
	__typename: "ProductsDocAccessFields_sessions_inventories_handlingFee_Read",
	permission: boolean
};
	["ProductsDocAccessFields_sessions_inventories_handlingFee_Update"]: {
	__typename: "ProductsDocAccessFields_sessions_inventories_handlingFee_Update",
	permission: boolean
};
	["ProductsDocAccessFields_sessions_inventories_handlingFee_Delete"]: {
	__typename: "ProductsDocAccessFields_sessions_inventories_handlingFee_Delete",
	permission: boolean
};
	["ProductsDocAccessFields_sessions_inventories_totalCost"]: {
	__typename: "ProductsDocAccessFields_sessions_inventories_totalCost",
	create?: GraphQLTypes["ProductsDocAccessFields_sessions_inventories_totalCost_Create"] | undefined,
	read?: GraphQLTypes["ProductsDocAccessFields_sessions_inventories_totalCost_Read"] | undefined,
	update?: GraphQLTypes["ProductsDocAccessFields_sessions_inventories_totalCost_Update"] | undefined,
	delete?: GraphQLTypes["ProductsDocAccessFields_sessions_inventories_totalCost_Delete"] | undefined
};
	["ProductsDocAccessFields_sessions_inventories_totalCost_Create"]: {
	__typename: "ProductsDocAccessFields_sessions_inventories_totalCost_Create",
	permission: boolean
};
	["ProductsDocAccessFields_sessions_inventories_totalCost_Read"]: {
	__typename: "ProductsDocAccessFields_sessions_inventories_totalCost_Read",
	permission: boolean
};
	["ProductsDocAccessFields_sessions_inventories_totalCost_Update"]: {
	__typename: "ProductsDocAccessFields_sessions_inventories_totalCost_Update",
	permission: boolean
};
	["ProductsDocAccessFields_sessions_inventories_totalCost_Delete"]: {
	__typename: "ProductsDocAccessFields_sessions_inventories_totalCost_Delete",
	permission: boolean
};
	["ProductsDocAccessFields_sessions_inventories_minPurchase"]: {
	__typename: "ProductsDocAccessFields_sessions_inventories_minPurchase",
	create?: GraphQLTypes["ProductsDocAccessFields_sessions_inventories_minPurchase_Create"] | undefined,
	read?: GraphQLTypes["ProductsDocAccessFields_sessions_inventories_minPurchase_Read"] | undefined,
	update?: GraphQLTypes["ProductsDocAccessFields_sessions_inventories_minPurchase_Update"] | undefined,
	delete?: GraphQLTypes["ProductsDocAccessFields_sessions_inventories_minPurchase_Delete"] | undefined
};
	["ProductsDocAccessFields_sessions_inventories_minPurchase_Create"]: {
	__typename: "ProductsDocAccessFields_sessions_inventories_minPurchase_Create",
	permission: boolean
};
	["ProductsDocAccessFields_sessions_inventories_minPurchase_Read"]: {
	__typename: "ProductsDocAccessFields_sessions_inventories_minPurchase_Read",
	permission: boolean
};
	["ProductsDocAccessFields_sessions_inventories_minPurchase_Update"]: {
	__typename: "ProductsDocAccessFields_sessions_inventories_minPurchase_Update",
	permission: boolean
};
	["ProductsDocAccessFields_sessions_inventories_minPurchase_Delete"]: {
	__typename: "ProductsDocAccessFields_sessions_inventories_minPurchase_Delete",
	permission: boolean
};
	["ProductsDocAccessFields_sessions_inventories_maxPurchase"]: {
	__typename: "ProductsDocAccessFields_sessions_inventories_maxPurchase",
	create?: GraphQLTypes["ProductsDocAccessFields_sessions_inventories_maxPurchase_Create"] | undefined,
	read?: GraphQLTypes["ProductsDocAccessFields_sessions_inventories_maxPurchase_Read"] | undefined,
	update?: GraphQLTypes["ProductsDocAccessFields_sessions_inventories_maxPurchase_Update"] | undefined,
	delete?: GraphQLTypes["ProductsDocAccessFields_sessions_inventories_maxPurchase_Delete"] | undefined
};
	["ProductsDocAccessFields_sessions_inventories_maxPurchase_Create"]: {
	__typename: "ProductsDocAccessFields_sessions_inventories_maxPurchase_Create",
	permission: boolean
};
	["ProductsDocAccessFields_sessions_inventories_maxPurchase_Read"]: {
	__typename: "ProductsDocAccessFields_sessions_inventories_maxPurchase_Read",
	permission: boolean
};
	["ProductsDocAccessFields_sessions_inventories_maxPurchase_Update"]: {
	__typename: "ProductsDocAccessFields_sessions_inventories_maxPurchase_Update",
	permission: boolean
};
	["ProductsDocAccessFields_sessions_inventories_maxPurchase_Delete"]: {
	__typename: "ProductsDocAccessFields_sessions_inventories_maxPurchase_Delete",
	permission: boolean
};
	["ProductsDocAccessFields_sessions_inventories_stocks"]: {
	__typename: "ProductsDocAccessFields_sessions_inventories_stocks",
	create?: GraphQLTypes["ProductsDocAccessFields_sessions_inventories_stocks_Create"] | undefined,
	read?: GraphQLTypes["ProductsDocAccessFields_sessions_inventories_stocks_Read"] | undefined,
	update?: GraphQLTypes["ProductsDocAccessFields_sessions_inventories_stocks_Update"] | undefined,
	delete?: GraphQLTypes["ProductsDocAccessFields_sessions_inventories_stocks_Delete"] | undefined,
	fields?: GraphQLTypes["ProductsDocAccessFields_sessions_inventories_stocks_Fields"] | undefined
};
	["ProductsDocAccessFields_sessions_inventories_stocks_Create"]: {
	__typename: "ProductsDocAccessFields_sessions_inventories_stocks_Create",
	permission: boolean
};
	["ProductsDocAccessFields_sessions_inventories_stocks_Read"]: {
	__typename: "ProductsDocAccessFields_sessions_inventories_stocks_Read",
	permission: boolean
};
	["ProductsDocAccessFields_sessions_inventories_stocks_Update"]: {
	__typename: "ProductsDocAccessFields_sessions_inventories_stocks_Update",
	permission: boolean
};
	["ProductsDocAccessFields_sessions_inventories_stocks_Delete"]: {
	__typename: "ProductsDocAccessFields_sessions_inventories_stocks_Delete",
	permission: boolean
};
	["ProductsDocAccessFields_sessions_inventories_stocks_Fields"]: {
	__typename: "ProductsDocAccessFields_sessions_inventories_stocks_Fields",
	stockId?: GraphQLTypes["ProductsDocAccessFields_sessions_inventories_stocks_stockId"] | undefined,
	remaining?: GraphQLTypes["ProductsDocAccessFields_sessions_inventories_stocks_remaining"] | undefined,
	sold?: GraphQLTypes["ProductsDocAccessFields_sessions_inventories_stocks_sold"] | undefined,
	onHold?: GraphQLTypes["ProductsDocAccessFields_sessions_inventories_stocks_onHold"] | undefined,
	checkedIn?: GraphQLTypes["ProductsDocAccessFields_sessions_inventories_stocks_checkedIn"] | undefined
};
	["ProductsDocAccessFields_sessions_inventories_stocks_stockId"]: {
	__typename: "ProductsDocAccessFields_sessions_inventories_stocks_stockId",
	create?: GraphQLTypes["ProductsDocAccessFields_sessions_inventories_stocks_stockId_Create"] | undefined,
	read?: GraphQLTypes["ProductsDocAccessFields_sessions_inventories_stocks_stockId_Read"] | undefined,
	update?: GraphQLTypes["ProductsDocAccessFields_sessions_inventories_stocks_stockId_Update"] | undefined,
	delete?: GraphQLTypes["ProductsDocAccessFields_sessions_inventories_stocks_stockId_Delete"] | undefined
};
	["ProductsDocAccessFields_sessions_inventories_stocks_stockId_Create"]: {
	__typename: "ProductsDocAccessFields_sessions_inventories_stocks_stockId_Create",
	permission: boolean
};
	["ProductsDocAccessFields_sessions_inventories_stocks_stockId_Read"]: {
	__typename: "ProductsDocAccessFields_sessions_inventories_stocks_stockId_Read",
	permission: boolean
};
	["ProductsDocAccessFields_sessions_inventories_stocks_stockId_Update"]: {
	__typename: "ProductsDocAccessFields_sessions_inventories_stocks_stockId_Update",
	permission: boolean
};
	["ProductsDocAccessFields_sessions_inventories_stocks_stockId_Delete"]: {
	__typename: "ProductsDocAccessFields_sessions_inventories_stocks_stockId_Delete",
	permission: boolean
};
	["ProductsDocAccessFields_sessions_inventories_stocks_remaining"]: {
	__typename: "ProductsDocAccessFields_sessions_inventories_stocks_remaining",
	create?: GraphQLTypes["ProductsDocAccessFields_sessions_inventories_stocks_remaining_Create"] | undefined,
	read?: GraphQLTypes["ProductsDocAccessFields_sessions_inventories_stocks_remaining_Read"] | undefined,
	update?: GraphQLTypes["ProductsDocAccessFields_sessions_inventories_stocks_remaining_Update"] | undefined,
	delete?: GraphQLTypes["ProductsDocAccessFields_sessions_inventories_stocks_remaining_Delete"] | undefined
};
	["ProductsDocAccessFields_sessions_inventories_stocks_remaining_Create"]: {
	__typename: "ProductsDocAccessFields_sessions_inventories_stocks_remaining_Create",
	permission: boolean
};
	["ProductsDocAccessFields_sessions_inventories_stocks_remaining_Read"]: {
	__typename: "ProductsDocAccessFields_sessions_inventories_stocks_remaining_Read",
	permission: boolean
};
	["ProductsDocAccessFields_sessions_inventories_stocks_remaining_Update"]: {
	__typename: "ProductsDocAccessFields_sessions_inventories_stocks_remaining_Update",
	permission: boolean
};
	["ProductsDocAccessFields_sessions_inventories_stocks_remaining_Delete"]: {
	__typename: "ProductsDocAccessFields_sessions_inventories_stocks_remaining_Delete",
	permission: boolean
};
	["ProductsDocAccessFields_sessions_inventories_stocks_sold"]: {
	__typename: "ProductsDocAccessFields_sessions_inventories_stocks_sold",
	create?: GraphQLTypes["ProductsDocAccessFields_sessions_inventories_stocks_sold_Create"] | undefined,
	read?: GraphQLTypes["ProductsDocAccessFields_sessions_inventories_stocks_sold_Read"] | undefined,
	update?: GraphQLTypes["ProductsDocAccessFields_sessions_inventories_stocks_sold_Update"] | undefined,
	delete?: GraphQLTypes["ProductsDocAccessFields_sessions_inventories_stocks_sold_Delete"] | undefined
};
	["ProductsDocAccessFields_sessions_inventories_stocks_sold_Create"]: {
	__typename: "ProductsDocAccessFields_sessions_inventories_stocks_sold_Create",
	permission: boolean
};
	["ProductsDocAccessFields_sessions_inventories_stocks_sold_Read"]: {
	__typename: "ProductsDocAccessFields_sessions_inventories_stocks_sold_Read",
	permission: boolean
};
	["ProductsDocAccessFields_sessions_inventories_stocks_sold_Update"]: {
	__typename: "ProductsDocAccessFields_sessions_inventories_stocks_sold_Update",
	permission: boolean
};
	["ProductsDocAccessFields_sessions_inventories_stocks_sold_Delete"]: {
	__typename: "ProductsDocAccessFields_sessions_inventories_stocks_sold_Delete",
	permission: boolean
};
	["ProductsDocAccessFields_sessions_inventories_stocks_onHold"]: {
	__typename: "ProductsDocAccessFields_sessions_inventories_stocks_onHold",
	create?: GraphQLTypes["ProductsDocAccessFields_sessions_inventories_stocks_onHold_Create"] | undefined,
	read?: GraphQLTypes["ProductsDocAccessFields_sessions_inventories_stocks_onHold_Read"] | undefined,
	update?: GraphQLTypes["ProductsDocAccessFields_sessions_inventories_stocks_onHold_Update"] | undefined,
	delete?: GraphQLTypes["ProductsDocAccessFields_sessions_inventories_stocks_onHold_Delete"] | undefined
};
	["ProductsDocAccessFields_sessions_inventories_stocks_onHold_Create"]: {
	__typename: "ProductsDocAccessFields_sessions_inventories_stocks_onHold_Create",
	permission: boolean
};
	["ProductsDocAccessFields_sessions_inventories_stocks_onHold_Read"]: {
	__typename: "ProductsDocAccessFields_sessions_inventories_stocks_onHold_Read",
	permission: boolean
};
	["ProductsDocAccessFields_sessions_inventories_stocks_onHold_Update"]: {
	__typename: "ProductsDocAccessFields_sessions_inventories_stocks_onHold_Update",
	permission: boolean
};
	["ProductsDocAccessFields_sessions_inventories_stocks_onHold_Delete"]: {
	__typename: "ProductsDocAccessFields_sessions_inventories_stocks_onHold_Delete",
	permission: boolean
};
	["ProductsDocAccessFields_sessions_inventories_stocks_checkedIn"]: {
	__typename: "ProductsDocAccessFields_sessions_inventories_stocks_checkedIn",
	create?: GraphQLTypes["ProductsDocAccessFields_sessions_inventories_stocks_checkedIn_Create"] | undefined,
	read?: GraphQLTypes["ProductsDocAccessFields_sessions_inventories_stocks_checkedIn_Read"] | undefined,
	update?: GraphQLTypes["ProductsDocAccessFields_sessions_inventories_stocks_checkedIn_Update"] | undefined,
	delete?: GraphQLTypes["ProductsDocAccessFields_sessions_inventories_stocks_checkedIn_Delete"] | undefined
};
	["ProductsDocAccessFields_sessions_inventories_stocks_checkedIn_Create"]: {
	__typename: "ProductsDocAccessFields_sessions_inventories_stocks_checkedIn_Create",
	permission: boolean
};
	["ProductsDocAccessFields_sessions_inventories_stocks_checkedIn_Read"]: {
	__typename: "ProductsDocAccessFields_sessions_inventories_stocks_checkedIn_Read",
	permission: boolean
};
	["ProductsDocAccessFields_sessions_inventories_stocks_checkedIn_Update"]: {
	__typename: "ProductsDocAccessFields_sessions_inventories_stocks_checkedIn_Update",
	permission: boolean
};
	["ProductsDocAccessFields_sessions_inventories_stocks_checkedIn_Delete"]: {
	__typename: "ProductsDocAccessFields_sessions_inventories_stocks_checkedIn_Delete",
	permission: boolean
};
	["ProductsDocAccessFields_sessions_id"]: {
	__typename: "ProductsDocAccessFields_sessions_id",
	create?: GraphQLTypes["ProductsDocAccessFields_sessions_id_Create"] | undefined,
	read?: GraphQLTypes["ProductsDocAccessFields_sessions_id_Read"] | undefined,
	update?: GraphQLTypes["ProductsDocAccessFields_sessions_id_Update"] | undefined,
	delete?: GraphQLTypes["ProductsDocAccessFields_sessions_id_Delete"] | undefined
};
	["ProductsDocAccessFields_sessions_id_Create"]: {
	__typename: "ProductsDocAccessFields_sessions_id_Create",
	permission: boolean
};
	["ProductsDocAccessFields_sessions_id_Read"]: {
	__typename: "ProductsDocAccessFields_sessions_id_Read",
	permission: boolean
};
	["ProductsDocAccessFields_sessions_id_Update"]: {
	__typename: "ProductsDocAccessFields_sessions_id_Update",
	permission: boolean
};
	["ProductsDocAccessFields_sessions_id_Delete"]: {
	__typename: "ProductsDocAccessFields_sessions_id_Delete",
	permission: boolean
};
	["ProductsDocAccessFields_updatedAt"]: {
	__typename: "ProductsDocAccessFields_updatedAt",
	create?: GraphQLTypes["ProductsDocAccessFields_updatedAt_Create"] | undefined,
	read?: GraphQLTypes["ProductsDocAccessFields_updatedAt_Read"] | undefined,
	update?: GraphQLTypes["ProductsDocAccessFields_updatedAt_Update"] | undefined,
	delete?: GraphQLTypes["ProductsDocAccessFields_updatedAt_Delete"] | undefined
};
	["ProductsDocAccessFields_updatedAt_Create"]: {
	__typename: "ProductsDocAccessFields_updatedAt_Create",
	permission: boolean
};
	["ProductsDocAccessFields_updatedAt_Read"]: {
	__typename: "ProductsDocAccessFields_updatedAt_Read",
	permission: boolean
};
	["ProductsDocAccessFields_updatedAt_Update"]: {
	__typename: "ProductsDocAccessFields_updatedAt_Update",
	permission: boolean
};
	["ProductsDocAccessFields_updatedAt_Delete"]: {
	__typename: "ProductsDocAccessFields_updatedAt_Delete",
	permission: boolean
};
	["ProductsDocAccessFields_createdAt"]: {
	__typename: "ProductsDocAccessFields_createdAt",
	create?: GraphQLTypes["ProductsDocAccessFields_createdAt_Create"] | undefined,
	read?: GraphQLTypes["ProductsDocAccessFields_createdAt_Read"] | undefined,
	update?: GraphQLTypes["ProductsDocAccessFields_createdAt_Update"] | undefined,
	delete?: GraphQLTypes["ProductsDocAccessFields_createdAt_Delete"] | undefined
};
	["ProductsDocAccessFields_createdAt_Create"]: {
	__typename: "ProductsDocAccessFields_createdAt_Create",
	permission: boolean
};
	["ProductsDocAccessFields_createdAt_Read"]: {
	__typename: "ProductsDocAccessFields_createdAt_Read",
	permission: boolean
};
	["ProductsDocAccessFields_createdAt_Update"]: {
	__typename: "ProductsDocAccessFields_createdAt_Update",
	permission: boolean
};
	["ProductsDocAccessFields_createdAt_Delete"]: {
	__typename: "ProductsDocAccessFields_createdAt_Delete",
	permission: boolean
};
	["ProductsCreateDocAccess"]: {
	__typename: "ProductsCreateDocAccess",
	permission: boolean,
	where?: GraphQLTypes["JSONObject"] | undefined
};
	["ProductsReadDocAccess"]: {
	__typename: "ProductsReadDocAccess",
	permission: boolean,
	where?: GraphQLTypes["JSONObject"] | undefined
};
	["ProductsUpdateDocAccess"]: {
	__typename: "ProductsUpdateDocAccess",
	permission: boolean,
	where?: GraphQLTypes["JSONObject"] | undefined
};
	["ProductsDeleteDocAccess"]: {
	__typename: "ProductsDeleteDocAccess",
	permission: boolean,
	where?: GraphQLTypes["JSONObject"] | undefined
};
	["InventoryStatus"]: {
	__typename: "InventoryStatus",
	id?: string | undefined,
	variantId?: string | undefined,
	variantName?: string | undefined,
	productType?: GraphQLTypes["InventoryStatus_productType"] | undefined,
	stockUpdateStatus: GraphQLTypes["InventoryStatus_stockUpdateStatus"],
	stockUpdateAmount: number,
	user?: GraphQLTypes["User"] | undefined,
	publicUserPhone?: string | undefined,
	expiredAt?: GraphQLTypes["DateTime"] | undefined,
	updatedAt?: GraphQLTypes["DateTime"] | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined
};
	["InventoryStatus_productType"]: InventoryStatus_productType;
	["InventoryStatus_stockUpdateStatus"]: InventoryStatus_stockUpdateStatus;
	["InventoryStatuses"]: {
	__typename: "InventoryStatuses",
	docs?: Array<GraphQLTypes["InventoryStatus"] | undefined> | undefined,
	hasNextPage?: boolean | undefined,
	hasPrevPage?: boolean | undefined,
	limit?: number | undefined,
	nextPage?: number | undefined,
	offset?: number | undefined,
	page?: number | undefined,
	pagingCounter?: number | undefined,
	prevPage?: number | undefined,
	totalDocs?: number | undefined,
	totalPages?: number | undefined
};
	["InventoryStatus_where"]: {
		variantId?: GraphQLTypes["InventoryStatus_variantId_operator"] | undefined,
	variantName?: GraphQLTypes["InventoryStatus_variantName_operator"] | undefined,
	productType?: GraphQLTypes["InventoryStatus_productType_operator"] | undefined,
	stockUpdateStatus?: GraphQLTypes["InventoryStatus_stockUpdateStatus_operator"] | undefined,
	stockUpdateAmount?: GraphQLTypes["InventoryStatus_stockUpdateAmount_operator"] | undefined,
	user?: GraphQLTypes["InventoryStatus_user_operator"] | undefined,
	publicUserPhone?: GraphQLTypes["InventoryStatus_publicUserPhone_operator"] | undefined,
	expiredAt?: GraphQLTypes["InventoryStatus_expiredAt_operator"] | undefined,
	updatedAt?: GraphQLTypes["InventoryStatus_updatedAt_operator"] | undefined,
	createdAt?: GraphQLTypes["InventoryStatus_createdAt_operator"] | undefined,
	id?: GraphQLTypes["InventoryStatus_id_operator"] | undefined,
	AND?: Array<GraphQLTypes["InventoryStatus_where_and"] | undefined> | undefined,
	OR?: Array<GraphQLTypes["InventoryStatus_where_or"] | undefined> | undefined
};
	["InventoryStatus_variantId_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["InventoryStatus_variantName_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["InventoryStatus_productType_operator"]: {
		equals?: GraphQLTypes["InventoryStatus_productType_Input"] | undefined,
	not_equals?: GraphQLTypes["InventoryStatus_productType_Input"] | undefined,
	like?: GraphQLTypes["InventoryStatus_productType_Input"] | undefined,
	contains?: GraphQLTypes["InventoryStatus_productType_Input"] | undefined,
	exists?: boolean | undefined
};
	["InventoryStatus_productType_Input"]: InventoryStatus_productType_Input;
	["InventoryStatus_stockUpdateStatus_operator"]: {
		equals?: GraphQLTypes["InventoryStatus_stockUpdateStatus_Input"] | undefined,
	not_equals?: GraphQLTypes["InventoryStatus_stockUpdateStatus_Input"] | undefined,
	in?: Array<GraphQLTypes["InventoryStatus_stockUpdateStatus_Input"] | undefined> | undefined,
	not_in?: Array<GraphQLTypes["InventoryStatus_stockUpdateStatus_Input"] | undefined> | undefined,
	all?: Array<GraphQLTypes["InventoryStatus_stockUpdateStatus_Input"] | undefined> | undefined
};
	["InventoryStatus_stockUpdateStatus_Input"]: InventoryStatus_stockUpdateStatus_Input;
	["InventoryStatus_stockUpdateAmount_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined
};
	["InventoryStatus_user_operator"]: {
		equals?: GraphQLTypes["JSON"] | undefined,
	not_equals?: GraphQLTypes["JSON"] | undefined,
	in?: Array<GraphQLTypes["JSON"] | undefined> | undefined,
	not_in?: Array<GraphQLTypes["JSON"] | undefined> | undefined,
	all?: Array<GraphQLTypes["JSON"] | undefined> | undefined,
	exists?: boolean | undefined
};
	["InventoryStatus_publicUserPhone_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["InventoryStatus_expiredAt_operator"]: {
		equals?: GraphQLTypes["DateTime"] | undefined,
	not_equals?: GraphQLTypes["DateTime"] | undefined,
	greater_than_equal?: GraphQLTypes["DateTime"] | undefined,
	greater_than?: GraphQLTypes["DateTime"] | undefined,
	less_than_equal?: GraphQLTypes["DateTime"] | undefined,
	less_than?: GraphQLTypes["DateTime"] | undefined,
	like?: GraphQLTypes["DateTime"] | undefined,
	exists?: boolean | undefined
};
	["InventoryStatus_updatedAt_operator"]: {
		equals?: GraphQLTypes["DateTime"] | undefined,
	not_equals?: GraphQLTypes["DateTime"] | undefined,
	greater_than_equal?: GraphQLTypes["DateTime"] | undefined,
	greater_than?: GraphQLTypes["DateTime"] | undefined,
	less_than_equal?: GraphQLTypes["DateTime"] | undefined,
	less_than?: GraphQLTypes["DateTime"] | undefined,
	like?: GraphQLTypes["DateTime"] | undefined,
	exists?: boolean | undefined
};
	["InventoryStatus_createdAt_operator"]: {
		equals?: GraphQLTypes["DateTime"] | undefined,
	not_equals?: GraphQLTypes["DateTime"] | undefined,
	greater_than_equal?: GraphQLTypes["DateTime"] | undefined,
	greater_than?: GraphQLTypes["DateTime"] | undefined,
	less_than_equal?: GraphQLTypes["DateTime"] | undefined,
	less_than?: GraphQLTypes["DateTime"] | undefined,
	like?: GraphQLTypes["DateTime"] | undefined,
	exists?: boolean | undefined
};
	["InventoryStatus_id_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["InventoryStatus_where_and"]: {
		variantId?: GraphQLTypes["InventoryStatus_variantId_operator"] | undefined,
	variantName?: GraphQLTypes["InventoryStatus_variantName_operator"] | undefined,
	productType?: GraphQLTypes["InventoryStatus_productType_operator"] | undefined,
	stockUpdateStatus?: GraphQLTypes["InventoryStatus_stockUpdateStatus_operator"] | undefined,
	stockUpdateAmount?: GraphQLTypes["InventoryStatus_stockUpdateAmount_operator"] | undefined,
	user?: GraphQLTypes["InventoryStatus_user_operator"] | undefined,
	publicUserPhone?: GraphQLTypes["InventoryStatus_publicUserPhone_operator"] | undefined,
	expiredAt?: GraphQLTypes["InventoryStatus_expiredAt_operator"] | undefined,
	updatedAt?: GraphQLTypes["InventoryStatus_updatedAt_operator"] | undefined,
	createdAt?: GraphQLTypes["InventoryStatus_createdAt_operator"] | undefined,
	id?: GraphQLTypes["InventoryStatus_id_operator"] | undefined,
	AND?: Array<GraphQLTypes["InventoryStatus_where_and"] | undefined> | undefined,
	OR?: Array<GraphQLTypes["InventoryStatus_where_or"] | undefined> | undefined
};
	["InventoryStatus_where_or"]: {
		variantId?: GraphQLTypes["InventoryStatus_variantId_operator"] | undefined,
	variantName?: GraphQLTypes["InventoryStatus_variantName_operator"] | undefined,
	productType?: GraphQLTypes["InventoryStatus_productType_operator"] | undefined,
	stockUpdateStatus?: GraphQLTypes["InventoryStatus_stockUpdateStatus_operator"] | undefined,
	stockUpdateAmount?: GraphQLTypes["InventoryStatus_stockUpdateAmount_operator"] | undefined,
	user?: GraphQLTypes["InventoryStatus_user_operator"] | undefined,
	publicUserPhone?: GraphQLTypes["InventoryStatus_publicUserPhone_operator"] | undefined,
	expiredAt?: GraphQLTypes["InventoryStatus_expiredAt_operator"] | undefined,
	updatedAt?: GraphQLTypes["InventoryStatus_updatedAt_operator"] | undefined,
	createdAt?: GraphQLTypes["InventoryStatus_createdAt_operator"] | undefined,
	id?: GraphQLTypes["InventoryStatus_id_operator"] | undefined,
	AND?: Array<GraphQLTypes["InventoryStatus_where_and"] | undefined> | undefined,
	OR?: Array<GraphQLTypes["InventoryStatus_where_or"] | undefined> | undefined
};
	["countInventoryStatuses"]: {
	__typename: "countInventoryStatuses",
	totalDocs?: number | undefined
};
	["inventory_statusDocAccess"]: {
	__typename: "inventory_statusDocAccess",
	fields?: GraphQLTypes["InventoryStatusDocAccessFields"] | undefined,
	create?: GraphQLTypes["InventoryStatusCreateDocAccess"] | undefined,
	read?: GraphQLTypes["InventoryStatusReadDocAccess"] | undefined,
	update?: GraphQLTypes["InventoryStatusUpdateDocAccess"] | undefined,
	delete?: GraphQLTypes["InventoryStatusDeleteDocAccess"] | undefined
};
	["InventoryStatusDocAccessFields"]: {
	__typename: "InventoryStatusDocAccessFields",
	variantId?: GraphQLTypes["InventoryStatusDocAccessFields_variantId"] | undefined,
	variantName?: GraphQLTypes["InventoryStatusDocAccessFields_variantName"] | undefined,
	productType?: GraphQLTypes["InventoryStatusDocAccessFields_productType"] | undefined,
	stockUpdateStatus?: GraphQLTypes["InventoryStatusDocAccessFields_stockUpdateStatus"] | undefined,
	stockUpdateAmount?: GraphQLTypes["InventoryStatusDocAccessFields_stockUpdateAmount"] | undefined,
	user?: GraphQLTypes["InventoryStatusDocAccessFields_user"] | undefined,
	publicUserPhone?: GraphQLTypes["InventoryStatusDocAccessFields_publicUserPhone"] | undefined,
	expiredAt?: GraphQLTypes["InventoryStatusDocAccessFields_expiredAt"] | undefined,
	updatedAt?: GraphQLTypes["InventoryStatusDocAccessFields_updatedAt"] | undefined,
	createdAt?: GraphQLTypes["InventoryStatusDocAccessFields_createdAt"] | undefined
};
	["InventoryStatusDocAccessFields_variantId"]: {
	__typename: "InventoryStatusDocAccessFields_variantId",
	create?: GraphQLTypes["InventoryStatusDocAccessFields_variantId_Create"] | undefined,
	read?: GraphQLTypes["InventoryStatusDocAccessFields_variantId_Read"] | undefined,
	update?: GraphQLTypes["InventoryStatusDocAccessFields_variantId_Update"] | undefined,
	delete?: GraphQLTypes["InventoryStatusDocAccessFields_variantId_Delete"] | undefined
};
	["InventoryStatusDocAccessFields_variantId_Create"]: {
	__typename: "InventoryStatusDocAccessFields_variantId_Create",
	permission: boolean
};
	["InventoryStatusDocAccessFields_variantId_Read"]: {
	__typename: "InventoryStatusDocAccessFields_variantId_Read",
	permission: boolean
};
	["InventoryStatusDocAccessFields_variantId_Update"]: {
	__typename: "InventoryStatusDocAccessFields_variantId_Update",
	permission: boolean
};
	["InventoryStatusDocAccessFields_variantId_Delete"]: {
	__typename: "InventoryStatusDocAccessFields_variantId_Delete",
	permission: boolean
};
	["InventoryStatusDocAccessFields_variantName"]: {
	__typename: "InventoryStatusDocAccessFields_variantName",
	create?: GraphQLTypes["InventoryStatusDocAccessFields_variantName_Create"] | undefined,
	read?: GraphQLTypes["InventoryStatusDocAccessFields_variantName_Read"] | undefined,
	update?: GraphQLTypes["InventoryStatusDocAccessFields_variantName_Update"] | undefined,
	delete?: GraphQLTypes["InventoryStatusDocAccessFields_variantName_Delete"] | undefined
};
	["InventoryStatusDocAccessFields_variantName_Create"]: {
	__typename: "InventoryStatusDocAccessFields_variantName_Create",
	permission: boolean
};
	["InventoryStatusDocAccessFields_variantName_Read"]: {
	__typename: "InventoryStatusDocAccessFields_variantName_Read",
	permission: boolean
};
	["InventoryStatusDocAccessFields_variantName_Update"]: {
	__typename: "InventoryStatusDocAccessFields_variantName_Update",
	permission: boolean
};
	["InventoryStatusDocAccessFields_variantName_Delete"]: {
	__typename: "InventoryStatusDocAccessFields_variantName_Delete",
	permission: boolean
};
	["InventoryStatusDocAccessFields_productType"]: {
	__typename: "InventoryStatusDocAccessFields_productType",
	create?: GraphQLTypes["InventoryStatusDocAccessFields_productType_Create"] | undefined,
	read?: GraphQLTypes["InventoryStatusDocAccessFields_productType_Read"] | undefined,
	update?: GraphQLTypes["InventoryStatusDocAccessFields_productType_Update"] | undefined,
	delete?: GraphQLTypes["InventoryStatusDocAccessFields_productType_Delete"] | undefined
};
	["InventoryStatusDocAccessFields_productType_Create"]: {
	__typename: "InventoryStatusDocAccessFields_productType_Create",
	permission: boolean
};
	["InventoryStatusDocAccessFields_productType_Read"]: {
	__typename: "InventoryStatusDocAccessFields_productType_Read",
	permission: boolean
};
	["InventoryStatusDocAccessFields_productType_Update"]: {
	__typename: "InventoryStatusDocAccessFields_productType_Update",
	permission: boolean
};
	["InventoryStatusDocAccessFields_productType_Delete"]: {
	__typename: "InventoryStatusDocAccessFields_productType_Delete",
	permission: boolean
};
	["InventoryStatusDocAccessFields_stockUpdateStatus"]: {
	__typename: "InventoryStatusDocAccessFields_stockUpdateStatus",
	create?: GraphQLTypes["InventoryStatusDocAccessFields_stockUpdateStatus_Create"] | undefined,
	read?: GraphQLTypes["InventoryStatusDocAccessFields_stockUpdateStatus_Read"] | undefined,
	update?: GraphQLTypes["InventoryStatusDocAccessFields_stockUpdateStatus_Update"] | undefined,
	delete?: GraphQLTypes["InventoryStatusDocAccessFields_stockUpdateStatus_Delete"] | undefined
};
	["InventoryStatusDocAccessFields_stockUpdateStatus_Create"]: {
	__typename: "InventoryStatusDocAccessFields_stockUpdateStatus_Create",
	permission: boolean
};
	["InventoryStatusDocAccessFields_stockUpdateStatus_Read"]: {
	__typename: "InventoryStatusDocAccessFields_stockUpdateStatus_Read",
	permission: boolean
};
	["InventoryStatusDocAccessFields_stockUpdateStatus_Update"]: {
	__typename: "InventoryStatusDocAccessFields_stockUpdateStatus_Update",
	permission: boolean
};
	["InventoryStatusDocAccessFields_stockUpdateStatus_Delete"]: {
	__typename: "InventoryStatusDocAccessFields_stockUpdateStatus_Delete",
	permission: boolean
};
	["InventoryStatusDocAccessFields_stockUpdateAmount"]: {
	__typename: "InventoryStatusDocAccessFields_stockUpdateAmount",
	create?: GraphQLTypes["InventoryStatusDocAccessFields_stockUpdateAmount_Create"] | undefined,
	read?: GraphQLTypes["InventoryStatusDocAccessFields_stockUpdateAmount_Read"] | undefined,
	update?: GraphQLTypes["InventoryStatusDocAccessFields_stockUpdateAmount_Update"] | undefined,
	delete?: GraphQLTypes["InventoryStatusDocAccessFields_stockUpdateAmount_Delete"] | undefined
};
	["InventoryStatusDocAccessFields_stockUpdateAmount_Create"]: {
	__typename: "InventoryStatusDocAccessFields_stockUpdateAmount_Create",
	permission: boolean
};
	["InventoryStatusDocAccessFields_stockUpdateAmount_Read"]: {
	__typename: "InventoryStatusDocAccessFields_stockUpdateAmount_Read",
	permission: boolean
};
	["InventoryStatusDocAccessFields_stockUpdateAmount_Update"]: {
	__typename: "InventoryStatusDocAccessFields_stockUpdateAmount_Update",
	permission: boolean
};
	["InventoryStatusDocAccessFields_stockUpdateAmount_Delete"]: {
	__typename: "InventoryStatusDocAccessFields_stockUpdateAmount_Delete",
	permission: boolean
};
	["InventoryStatusDocAccessFields_user"]: {
	__typename: "InventoryStatusDocAccessFields_user",
	create?: GraphQLTypes["InventoryStatusDocAccessFields_user_Create"] | undefined,
	read?: GraphQLTypes["InventoryStatusDocAccessFields_user_Read"] | undefined,
	update?: GraphQLTypes["InventoryStatusDocAccessFields_user_Update"] | undefined,
	delete?: GraphQLTypes["InventoryStatusDocAccessFields_user_Delete"] | undefined
};
	["InventoryStatusDocAccessFields_user_Create"]: {
	__typename: "InventoryStatusDocAccessFields_user_Create",
	permission: boolean
};
	["InventoryStatusDocAccessFields_user_Read"]: {
	__typename: "InventoryStatusDocAccessFields_user_Read",
	permission: boolean
};
	["InventoryStatusDocAccessFields_user_Update"]: {
	__typename: "InventoryStatusDocAccessFields_user_Update",
	permission: boolean
};
	["InventoryStatusDocAccessFields_user_Delete"]: {
	__typename: "InventoryStatusDocAccessFields_user_Delete",
	permission: boolean
};
	["InventoryStatusDocAccessFields_publicUserPhone"]: {
	__typename: "InventoryStatusDocAccessFields_publicUserPhone",
	create?: GraphQLTypes["InventoryStatusDocAccessFields_publicUserPhone_Create"] | undefined,
	read?: GraphQLTypes["InventoryStatusDocAccessFields_publicUserPhone_Read"] | undefined,
	update?: GraphQLTypes["InventoryStatusDocAccessFields_publicUserPhone_Update"] | undefined,
	delete?: GraphQLTypes["InventoryStatusDocAccessFields_publicUserPhone_Delete"] | undefined
};
	["InventoryStatusDocAccessFields_publicUserPhone_Create"]: {
	__typename: "InventoryStatusDocAccessFields_publicUserPhone_Create",
	permission: boolean
};
	["InventoryStatusDocAccessFields_publicUserPhone_Read"]: {
	__typename: "InventoryStatusDocAccessFields_publicUserPhone_Read",
	permission: boolean
};
	["InventoryStatusDocAccessFields_publicUserPhone_Update"]: {
	__typename: "InventoryStatusDocAccessFields_publicUserPhone_Update",
	permission: boolean
};
	["InventoryStatusDocAccessFields_publicUserPhone_Delete"]: {
	__typename: "InventoryStatusDocAccessFields_publicUserPhone_Delete",
	permission: boolean
};
	["InventoryStatusDocAccessFields_expiredAt"]: {
	__typename: "InventoryStatusDocAccessFields_expiredAt",
	create?: GraphQLTypes["InventoryStatusDocAccessFields_expiredAt_Create"] | undefined,
	read?: GraphQLTypes["InventoryStatusDocAccessFields_expiredAt_Read"] | undefined,
	update?: GraphQLTypes["InventoryStatusDocAccessFields_expiredAt_Update"] | undefined,
	delete?: GraphQLTypes["InventoryStatusDocAccessFields_expiredAt_Delete"] | undefined
};
	["InventoryStatusDocAccessFields_expiredAt_Create"]: {
	__typename: "InventoryStatusDocAccessFields_expiredAt_Create",
	permission: boolean
};
	["InventoryStatusDocAccessFields_expiredAt_Read"]: {
	__typename: "InventoryStatusDocAccessFields_expiredAt_Read",
	permission: boolean
};
	["InventoryStatusDocAccessFields_expiredAt_Update"]: {
	__typename: "InventoryStatusDocAccessFields_expiredAt_Update",
	permission: boolean
};
	["InventoryStatusDocAccessFields_expiredAt_Delete"]: {
	__typename: "InventoryStatusDocAccessFields_expiredAt_Delete",
	permission: boolean
};
	["InventoryStatusDocAccessFields_updatedAt"]: {
	__typename: "InventoryStatusDocAccessFields_updatedAt",
	create?: GraphQLTypes["InventoryStatusDocAccessFields_updatedAt_Create"] | undefined,
	read?: GraphQLTypes["InventoryStatusDocAccessFields_updatedAt_Read"] | undefined,
	update?: GraphQLTypes["InventoryStatusDocAccessFields_updatedAt_Update"] | undefined,
	delete?: GraphQLTypes["InventoryStatusDocAccessFields_updatedAt_Delete"] | undefined
};
	["InventoryStatusDocAccessFields_updatedAt_Create"]: {
	__typename: "InventoryStatusDocAccessFields_updatedAt_Create",
	permission: boolean
};
	["InventoryStatusDocAccessFields_updatedAt_Read"]: {
	__typename: "InventoryStatusDocAccessFields_updatedAt_Read",
	permission: boolean
};
	["InventoryStatusDocAccessFields_updatedAt_Update"]: {
	__typename: "InventoryStatusDocAccessFields_updatedAt_Update",
	permission: boolean
};
	["InventoryStatusDocAccessFields_updatedAt_Delete"]: {
	__typename: "InventoryStatusDocAccessFields_updatedAt_Delete",
	permission: boolean
};
	["InventoryStatusDocAccessFields_createdAt"]: {
	__typename: "InventoryStatusDocAccessFields_createdAt",
	create?: GraphQLTypes["InventoryStatusDocAccessFields_createdAt_Create"] | undefined,
	read?: GraphQLTypes["InventoryStatusDocAccessFields_createdAt_Read"] | undefined,
	update?: GraphQLTypes["InventoryStatusDocAccessFields_createdAt_Update"] | undefined,
	delete?: GraphQLTypes["InventoryStatusDocAccessFields_createdAt_Delete"] | undefined
};
	["InventoryStatusDocAccessFields_createdAt_Create"]: {
	__typename: "InventoryStatusDocAccessFields_createdAt_Create",
	permission: boolean
};
	["InventoryStatusDocAccessFields_createdAt_Read"]: {
	__typename: "InventoryStatusDocAccessFields_createdAt_Read",
	permission: boolean
};
	["InventoryStatusDocAccessFields_createdAt_Update"]: {
	__typename: "InventoryStatusDocAccessFields_createdAt_Update",
	permission: boolean
};
	["InventoryStatusDocAccessFields_createdAt_Delete"]: {
	__typename: "InventoryStatusDocAccessFields_createdAt_Delete",
	permission: boolean
};
	["InventoryStatusCreateDocAccess"]: {
	__typename: "InventoryStatusCreateDocAccess",
	permission: boolean,
	where?: GraphQLTypes["JSONObject"] | undefined
};
	["InventoryStatusReadDocAccess"]: {
	__typename: "InventoryStatusReadDocAccess",
	permission: boolean,
	where?: GraphQLTypes["JSONObject"] | undefined
};
	["InventoryStatusUpdateDocAccess"]: {
	__typename: "InventoryStatusUpdateDocAccess",
	permission: boolean,
	where?: GraphQLTypes["JSONObject"] | undefined
};
	["InventoryStatusDeleteDocAccess"]: {
	__typename: "InventoryStatusDeleteDocAccess",
	permission: boolean,
	where?: GraphQLTypes["JSONObject"] | undefined
};
	["Transaction"]: {
	__typename: "Transaction",
	id?: string | undefined,
	creator?: GraphQLTypes["User"] | undefined,
	productName?: string | undefined,
	referenceId?: string | undefined,
	linkedEvent?: GraphQLTypes["Event"] | undefined,
	purchaseDateTime?: GraphQLTypes["DateTime"] | undefined,
	timableOrderId?: string | undefined,
	stripePaymentId?: string | undefined,
	totalAmount?: number | undefined,
	contactPersonName?: string | undefined,
	contactPersonPhone?: string | undefined,
	remarks?: string | undefined,
	redemptionCodes?: Array<GraphQLTypes["Transaction_RedemptionCodes"]> | undefined,
	updatedAt?: GraphQLTypes["DateTime"] | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined
};
	["Transaction_RedemptionCodes"]: {
	__typename: "Transaction_RedemptionCodes",
	code?: string | undefined,
	id?: string | undefined
};
	["Transactions"]: {
	__typename: "Transactions",
	docs?: Array<GraphQLTypes["Transaction"] | undefined> | undefined,
	hasNextPage?: boolean | undefined,
	hasPrevPage?: boolean | undefined,
	limit?: number | undefined,
	nextPage?: number | undefined,
	offset?: number | undefined,
	page?: number | undefined,
	pagingCounter?: number | undefined,
	prevPage?: number | undefined,
	totalDocs?: number | undefined,
	totalPages?: number | undefined
};
	["Transaction_where"]: {
		creator?: GraphQLTypes["Transaction_creator_operator"] | undefined,
	productName?: GraphQLTypes["Transaction_productName_operator"] | undefined,
	referenceId?: GraphQLTypes["Transaction_referenceId_operator"] | undefined,
	linkedEvent?: GraphQLTypes["Transaction_linkedEvent_operator"] | undefined,
	purchaseDateTime?: GraphQLTypes["Transaction_purchaseDateTime_operator"] | undefined,
	timableOrderId?: GraphQLTypes["Transaction_timableOrderId_operator"] | undefined,
	stripePaymentId?: GraphQLTypes["Transaction_stripePaymentId_operator"] | undefined,
	totalAmount?: GraphQLTypes["Transaction_totalAmount_operator"] | undefined,
	contactPersonName?: GraphQLTypes["Transaction_contactPersonName_operator"] | undefined,
	contactPersonPhone?: GraphQLTypes["Transaction_contactPersonPhone_operator"] | undefined,
	remarks?: GraphQLTypes["Transaction_remarks_operator"] | undefined,
	redemptionCodes__code?: GraphQLTypes["Transaction_redemptionCodes__code_operator"] | undefined,
	redemptionCodes__id?: GraphQLTypes["Transaction_redemptionCodes__id_operator"] | undefined,
	updatedAt?: GraphQLTypes["Transaction_updatedAt_operator"] | undefined,
	createdAt?: GraphQLTypes["Transaction_createdAt_operator"] | undefined,
	id?: GraphQLTypes["Transaction_id_operator"] | undefined,
	AND?: Array<GraphQLTypes["Transaction_where_and"] | undefined> | undefined,
	OR?: Array<GraphQLTypes["Transaction_where_or"] | undefined> | undefined
};
	["Transaction_creator_operator"]: {
		equals?: GraphQLTypes["JSON"] | undefined,
	not_equals?: GraphQLTypes["JSON"] | undefined,
	in?: Array<GraphQLTypes["JSON"] | undefined> | undefined,
	not_in?: Array<GraphQLTypes["JSON"] | undefined> | undefined,
	all?: Array<GraphQLTypes["JSON"] | undefined> | undefined,
	exists?: boolean | undefined
};
	["Transaction_productName_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Transaction_referenceId_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Transaction_linkedEvent_operator"]: {
		equals?: GraphQLTypes["JSON"] | undefined,
	not_equals?: GraphQLTypes["JSON"] | undefined,
	in?: Array<GraphQLTypes["JSON"] | undefined> | undefined,
	not_in?: Array<GraphQLTypes["JSON"] | undefined> | undefined,
	all?: Array<GraphQLTypes["JSON"] | undefined> | undefined,
	exists?: boolean | undefined
};
	["Transaction_purchaseDateTime_operator"]: {
		equals?: GraphQLTypes["DateTime"] | undefined,
	not_equals?: GraphQLTypes["DateTime"] | undefined,
	greater_than_equal?: GraphQLTypes["DateTime"] | undefined,
	greater_than?: GraphQLTypes["DateTime"] | undefined,
	less_than_equal?: GraphQLTypes["DateTime"] | undefined,
	less_than?: GraphQLTypes["DateTime"] | undefined,
	like?: GraphQLTypes["DateTime"] | undefined,
	exists?: boolean | undefined
};
	["Transaction_timableOrderId_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Transaction_stripePaymentId_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Transaction_totalAmount_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Transaction_contactPersonName_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Transaction_contactPersonPhone_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Transaction_remarks_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	exists?: boolean | undefined
};
	["Transaction_redemptionCodes__code_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Transaction_redemptionCodes__id_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Transaction_updatedAt_operator"]: {
		equals?: GraphQLTypes["DateTime"] | undefined,
	not_equals?: GraphQLTypes["DateTime"] | undefined,
	greater_than_equal?: GraphQLTypes["DateTime"] | undefined,
	greater_than?: GraphQLTypes["DateTime"] | undefined,
	less_than_equal?: GraphQLTypes["DateTime"] | undefined,
	less_than?: GraphQLTypes["DateTime"] | undefined,
	like?: GraphQLTypes["DateTime"] | undefined,
	exists?: boolean | undefined
};
	["Transaction_createdAt_operator"]: {
		equals?: GraphQLTypes["DateTime"] | undefined,
	not_equals?: GraphQLTypes["DateTime"] | undefined,
	greater_than_equal?: GraphQLTypes["DateTime"] | undefined,
	greater_than?: GraphQLTypes["DateTime"] | undefined,
	less_than_equal?: GraphQLTypes["DateTime"] | undefined,
	less_than?: GraphQLTypes["DateTime"] | undefined,
	like?: GraphQLTypes["DateTime"] | undefined,
	exists?: boolean | undefined
};
	["Transaction_id_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Transaction_where_and"]: {
		creator?: GraphQLTypes["Transaction_creator_operator"] | undefined,
	productName?: GraphQLTypes["Transaction_productName_operator"] | undefined,
	referenceId?: GraphQLTypes["Transaction_referenceId_operator"] | undefined,
	linkedEvent?: GraphQLTypes["Transaction_linkedEvent_operator"] | undefined,
	purchaseDateTime?: GraphQLTypes["Transaction_purchaseDateTime_operator"] | undefined,
	timableOrderId?: GraphQLTypes["Transaction_timableOrderId_operator"] | undefined,
	stripePaymentId?: GraphQLTypes["Transaction_stripePaymentId_operator"] | undefined,
	totalAmount?: GraphQLTypes["Transaction_totalAmount_operator"] | undefined,
	contactPersonName?: GraphQLTypes["Transaction_contactPersonName_operator"] | undefined,
	contactPersonPhone?: GraphQLTypes["Transaction_contactPersonPhone_operator"] | undefined,
	remarks?: GraphQLTypes["Transaction_remarks_operator"] | undefined,
	redemptionCodes__code?: GraphQLTypes["Transaction_redemptionCodes__code_operator"] | undefined,
	redemptionCodes__id?: GraphQLTypes["Transaction_redemptionCodes__id_operator"] | undefined,
	updatedAt?: GraphQLTypes["Transaction_updatedAt_operator"] | undefined,
	createdAt?: GraphQLTypes["Transaction_createdAt_operator"] | undefined,
	id?: GraphQLTypes["Transaction_id_operator"] | undefined,
	AND?: Array<GraphQLTypes["Transaction_where_and"] | undefined> | undefined,
	OR?: Array<GraphQLTypes["Transaction_where_or"] | undefined> | undefined
};
	["Transaction_where_or"]: {
		creator?: GraphQLTypes["Transaction_creator_operator"] | undefined,
	productName?: GraphQLTypes["Transaction_productName_operator"] | undefined,
	referenceId?: GraphQLTypes["Transaction_referenceId_operator"] | undefined,
	linkedEvent?: GraphQLTypes["Transaction_linkedEvent_operator"] | undefined,
	purchaseDateTime?: GraphQLTypes["Transaction_purchaseDateTime_operator"] | undefined,
	timableOrderId?: GraphQLTypes["Transaction_timableOrderId_operator"] | undefined,
	stripePaymentId?: GraphQLTypes["Transaction_stripePaymentId_operator"] | undefined,
	totalAmount?: GraphQLTypes["Transaction_totalAmount_operator"] | undefined,
	contactPersonName?: GraphQLTypes["Transaction_contactPersonName_operator"] | undefined,
	contactPersonPhone?: GraphQLTypes["Transaction_contactPersonPhone_operator"] | undefined,
	remarks?: GraphQLTypes["Transaction_remarks_operator"] | undefined,
	redemptionCodes__code?: GraphQLTypes["Transaction_redemptionCodes__code_operator"] | undefined,
	redemptionCodes__id?: GraphQLTypes["Transaction_redemptionCodes__id_operator"] | undefined,
	updatedAt?: GraphQLTypes["Transaction_updatedAt_operator"] | undefined,
	createdAt?: GraphQLTypes["Transaction_createdAt_operator"] | undefined,
	id?: GraphQLTypes["Transaction_id_operator"] | undefined,
	AND?: Array<GraphQLTypes["Transaction_where_and"] | undefined> | undefined,
	OR?: Array<GraphQLTypes["Transaction_where_or"] | undefined> | undefined
};
	["countTransactions"]: {
	__typename: "countTransactions",
	totalDocs?: number | undefined
};
	["transactionsDocAccess"]: {
	__typename: "transactionsDocAccess",
	fields?: GraphQLTypes["TransactionsDocAccessFields"] | undefined,
	create?: GraphQLTypes["TransactionsCreateDocAccess"] | undefined,
	read?: GraphQLTypes["TransactionsReadDocAccess"] | undefined,
	update?: GraphQLTypes["TransactionsUpdateDocAccess"] | undefined,
	delete?: GraphQLTypes["TransactionsDeleteDocAccess"] | undefined
};
	["TransactionsDocAccessFields"]: {
	__typename: "TransactionsDocAccessFields",
	creator?: GraphQLTypes["TransactionsDocAccessFields_creator"] | undefined,
	productName?: GraphQLTypes["TransactionsDocAccessFields_productName"] | undefined,
	referenceId?: GraphQLTypes["TransactionsDocAccessFields_referenceId"] | undefined,
	linkedEvent?: GraphQLTypes["TransactionsDocAccessFields_linkedEvent"] | undefined,
	purchaseDateTime?: GraphQLTypes["TransactionsDocAccessFields_purchaseDateTime"] | undefined,
	timableOrderId?: GraphQLTypes["TransactionsDocAccessFields_timableOrderId"] | undefined,
	stripePaymentId?: GraphQLTypes["TransactionsDocAccessFields_stripePaymentId"] | undefined,
	totalAmount?: GraphQLTypes["TransactionsDocAccessFields_totalAmount"] | undefined,
	contactPersonName?: GraphQLTypes["TransactionsDocAccessFields_contactPersonName"] | undefined,
	contactPersonPhone?: GraphQLTypes["TransactionsDocAccessFields_contactPersonPhone"] | undefined,
	remarks?: GraphQLTypes["TransactionsDocAccessFields_remarks"] | undefined,
	redemptionCodes?: GraphQLTypes["TransactionsDocAccessFields_redemptionCodes"] | undefined,
	updatedAt?: GraphQLTypes["TransactionsDocAccessFields_updatedAt"] | undefined,
	createdAt?: GraphQLTypes["TransactionsDocAccessFields_createdAt"] | undefined
};
	["TransactionsDocAccessFields_creator"]: {
	__typename: "TransactionsDocAccessFields_creator",
	create?: GraphQLTypes["TransactionsDocAccessFields_creator_Create"] | undefined,
	read?: GraphQLTypes["TransactionsDocAccessFields_creator_Read"] | undefined,
	update?: GraphQLTypes["TransactionsDocAccessFields_creator_Update"] | undefined,
	delete?: GraphQLTypes["TransactionsDocAccessFields_creator_Delete"] | undefined
};
	["TransactionsDocAccessFields_creator_Create"]: {
	__typename: "TransactionsDocAccessFields_creator_Create",
	permission: boolean
};
	["TransactionsDocAccessFields_creator_Read"]: {
	__typename: "TransactionsDocAccessFields_creator_Read",
	permission: boolean
};
	["TransactionsDocAccessFields_creator_Update"]: {
	__typename: "TransactionsDocAccessFields_creator_Update",
	permission: boolean
};
	["TransactionsDocAccessFields_creator_Delete"]: {
	__typename: "TransactionsDocAccessFields_creator_Delete",
	permission: boolean
};
	["TransactionsDocAccessFields_productName"]: {
	__typename: "TransactionsDocAccessFields_productName",
	create?: GraphQLTypes["TransactionsDocAccessFields_productName_Create"] | undefined,
	read?: GraphQLTypes["TransactionsDocAccessFields_productName_Read"] | undefined,
	update?: GraphQLTypes["TransactionsDocAccessFields_productName_Update"] | undefined,
	delete?: GraphQLTypes["TransactionsDocAccessFields_productName_Delete"] | undefined
};
	["TransactionsDocAccessFields_productName_Create"]: {
	__typename: "TransactionsDocAccessFields_productName_Create",
	permission: boolean
};
	["TransactionsDocAccessFields_productName_Read"]: {
	__typename: "TransactionsDocAccessFields_productName_Read",
	permission: boolean
};
	["TransactionsDocAccessFields_productName_Update"]: {
	__typename: "TransactionsDocAccessFields_productName_Update",
	permission: boolean
};
	["TransactionsDocAccessFields_productName_Delete"]: {
	__typename: "TransactionsDocAccessFields_productName_Delete",
	permission: boolean
};
	["TransactionsDocAccessFields_referenceId"]: {
	__typename: "TransactionsDocAccessFields_referenceId",
	create?: GraphQLTypes["TransactionsDocAccessFields_referenceId_Create"] | undefined,
	read?: GraphQLTypes["TransactionsDocAccessFields_referenceId_Read"] | undefined,
	update?: GraphQLTypes["TransactionsDocAccessFields_referenceId_Update"] | undefined,
	delete?: GraphQLTypes["TransactionsDocAccessFields_referenceId_Delete"] | undefined
};
	["TransactionsDocAccessFields_referenceId_Create"]: {
	__typename: "TransactionsDocAccessFields_referenceId_Create",
	permission: boolean
};
	["TransactionsDocAccessFields_referenceId_Read"]: {
	__typename: "TransactionsDocAccessFields_referenceId_Read",
	permission: boolean
};
	["TransactionsDocAccessFields_referenceId_Update"]: {
	__typename: "TransactionsDocAccessFields_referenceId_Update",
	permission: boolean
};
	["TransactionsDocAccessFields_referenceId_Delete"]: {
	__typename: "TransactionsDocAccessFields_referenceId_Delete",
	permission: boolean
};
	["TransactionsDocAccessFields_linkedEvent"]: {
	__typename: "TransactionsDocAccessFields_linkedEvent",
	create?: GraphQLTypes["TransactionsDocAccessFields_linkedEvent_Create"] | undefined,
	read?: GraphQLTypes["TransactionsDocAccessFields_linkedEvent_Read"] | undefined,
	update?: GraphQLTypes["TransactionsDocAccessFields_linkedEvent_Update"] | undefined,
	delete?: GraphQLTypes["TransactionsDocAccessFields_linkedEvent_Delete"] | undefined
};
	["TransactionsDocAccessFields_linkedEvent_Create"]: {
	__typename: "TransactionsDocAccessFields_linkedEvent_Create",
	permission: boolean
};
	["TransactionsDocAccessFields_linkedEvent_Read"]: {
	__typename: "TransactionsDocAccessFields_linkedEvent_Read",
	permission: boolean
};
	["TransactionsDocAccessFields_linkedEvent_Update"]: {
	__typename: "TransactionsDocAccessFields_linkedEvent_Update",
	permission: boolean
};
	["TransactionsDocAccessFields_linkedEvent_Delete"]: {
	__typename: "TransactionsDocAccessFields_linkedEvent_Delete",
	permission: boolean
};
	["TransactionsDocAccessFields_purchaseDateTime"]: {
	__typename: "TransactionsDocAccessFields_purchaseDateTime",
	create?: GraphQLTypes["TransactionsDocAccessFields_purchaseDateTime_Create"] | undefined,
	read?: GraphQLTypes["TransactionsDocAccessFields_purchaseDateTime_Read"] | undefined,
	update?: GraphQLTypes["TransactionsDocAccessFields_purchaseDateTime_Update"] | undefined,
	delete?: GraphQLTypes["TransactionsDocAccessFields_purchaseDateTime_Delete"] | undefined
};
	["TransactionsDocAccessFields_purchaseDateTime_Create"]: {
	__typename: "TransactionsDocAccessFields_purchaseDateTime_Create",
	permission: boolean
};
	["TransactionsDocAccessFields_purchaseDateTime_Read"]: {
	__typename: "TransactionsDocAccessFields_purchaseDateTime_Read",
	permission: boolean
};
	["TransactionsDocAccessFields_purchaseDateTime_Update"]: {
	__typename: "TransactionsDocAccessFields_purchaseDateTime_Update",
	permission: boolean
};
	["TransactionsDocAccessFields_purchaseDateTime_Delete"]: {
	__typename: "TransactionsDocAccessFields_purchaseDateTime_Delete",
	permission: boolean
};
	["TransactionsDocAccessFields_timableOrderId"]: {
	__typename: "TransactionsDocAccessFields_timableOrderId",
	create?: GraphQLTypes["TransactionsDocAccessFields_timableOrderId_Create"] | undefined,
	read?: GraphQLTypes["TransactionsDocAccessFields_timableOrderId_Read"] | undefined,
	update?: GraphQLTypes["TransactionsDocAccessFields_timableOrderId_Update"] | undefined,
	delete?: GraphQLTypes["TransactionsDocAccessFields_timableOrderId_Delete"] | undefined
};
	["TransactionsDocAccessFields_timableOrderId_Create"]: {
	__typename: "TransactionsDocAccessFields_timableOrderId_Create",
	permission: boolean
};
	["TransactionsDocAccessFields_timableOrderId_Read"]: {
	__typename: "TransactionsDocAccessFields_timableOrderId_Read",
	permission: boolean
};
	["TransactionsDocAccessFields_timableOrderId_Update"]: {
	__typename: "TransactionsDocAccessFields_timableOrderId_Update",
	permission: boolean
};
	["TransactionsDocAccessFields_timableOrderId_Delete"]: {
	__typename: "TransactionsDocAccessFields_timableOrderId_Delete",
	permission: boolean
};
	["TransactionsDocAccessFields_stripePaymentId"]: {
	__typename: "TransactionsDocAccessFields_stripePaymentId",
	create?: GraphQLTypes["TransactionsDocAccessFields_stripePaymentId_Create"] | undefined,
	read?: GraphQLTypes["TransactionsDocAccessFields_stripePaymentId_Read"] | undefined,
	update?: GraphQLTypes["TransactionsDocAccessFields_stripePaymentId_Update"] | undefined,
	delete?: GraphQLTypes["TransactionsDocAccessFields_stripePaymentId_Delete"] | undefined
};
	["TransactionsDocAccessFields_stripePaymentId_Create"]: {
	__typename: "TransactionsDocAccessFields_stripePaymentId_Create",
	permission: boolean
};
	["TransactionsDocAccessFields_stripePaymentId_Read"]: {
	__typename: "TransactionsDocAccessFields_stripePaymentId_Read",
	permission: boolean
};
	["TransactionsDocAccessFields_stripePaymentId_Update"]: {
	__typename: "TransactionsDocAccessFields_stripePaymentId_Update",
	permission: boolean
};
	["TransactionsDocAccessFields_stripePaymentId_Delete"]: {
	__typename: "TransactionsDocAccessFields_stripePaymentId_Delete",
	permission: boolean
};
	["TransactionsDocAccessFields_totalAmount"]: {
	__typename: "TransactionsDocAccessFields_totalAmount",
	create?: GraphQLTypes["TransactionsDocAccessFields_totalAmount_Create"] | undefined,
	read?: GraphQLTypes["TransactionsDocAccessFields_totalAmount_Read"] | undefined,
	update?: GraphQLTypes["TransactionsDocAccessFields_totalAmount_Update"] | undefined,
	delete?: GraphQLTypes["TransactionsDocAccessFields_totalAmount_Delete"] | undefined
};
	["TransactionsDocAccessFields_totalAmount_Create"]: {
	__typename: "TransactionsDocAccessFields_totalAmount_Create",
	permission: boolean
};
	["TransactionsDocAccessFields_totalAmount_Read"]: {
	__typename: "TransactionsDocAccessFields_totalAmount_Read",
	permission: boolean
};
	["TransactionsDocAccessFields_totalAmount_Update"]: {
	__typename: "TransactionsDocAccessFields_totalAmount_Update",
	permission: boolean
};
	["TransactionsDocAccessFields_totalAmount_Delete"]: {
	__typename: "TransactionsDocAccessFields_totalAmount_Delete",
	permission: boolean
};
	["TransactionsDocAccessFields_contactPersonName"]: {
	__typename: "TransactionsDocAccessFields_contactPersonName",
	create?: GraphQLTypes["TransactionsDocAccessFields_contactPersonName_Create"] | undefined,
	read?: GraphQLTypes["TransactionsDocAccessFields_contactPersonName_Read"] | undefined,
	update?: GraphQLTypes["TransactionsDocAccessFields_contactPersonName_Update"] | undefined,
	delete?: GraphQLTypes["TransactionsDocAccessFields_contactPersonName_Delete"] | undefined
};
	["TransactionsDocAccessFields_contactPersonName_Create"]: {
	__typename: "TransactionsDocAccessFields_contactPersonName_Create",
	permission: boolean
};
	["TransactionsDocAccessFields_contactPersonName_Read"]: {
	__typename: "TransactionsDocAccessFields_contactPersonName_Read",
	permission: boolean
};
	["TransactionsDocAccessFields_contactPersonName_Update"]: {
	__typename: "TransactionsDocAccessFields_contactPersonName_Update",
	permission: boolean
};
	["TransactionsDocAccessFields_contactPersonName_Delete"]: {
	__typename: "TransactionsDocAccessFields_contactPersonName_Delete",
	permission: boolean
};
	["TransactionsDocAccessFields_contactPersonPhone"]: {
	__typename: "TransactionsDocAccessFields_contactPersonPhone",
	create?: GraphQLTypes["TransactionsDocAccessFields_contactPersonPhone_Create"] | undefined,
	read?: GraphQLTypes["TransactionsDocAccessFields_contactPersonPhone_Read"] | undefined,
	update?: GraphQLTypes["TransactionsDocAccessFields_contactPersonPhone_Update"] | undefined,
	delete?: GraphQLTypes["TransactionsDocAccessFields_contactPersonPhone_Delete"] | undefined
};
	["TransactionsDocAccessFields_contactPersonPhone_Create"]: {
	__typename: "TransactionsDocAccessFields_contactPersonPhone_Create",
	permission: boolean
};
	["TransactionsDocAccessFields_contactPersonPhone_Read"]: {
	__typename: "TransactionsDocAccessFields_contactPersonPhone_Read",
	permission: boolean
};
	["TransactionsDocAccessFields_contactPersonPhone_Update"]: {
	__typename: "TransactionsDocAccessFields_contactPersonPhone_Update",
	permission: boolean
};
	["TransactionsDocAccessFields_contactPersonPhone_Delete"]: {
	__typename: "TransactionsDocAccessFields_contactPersonPhone_Delete",
	permission: boolean
};
	["TransactionsDocAccessFields_remarks"]: {
	__typename: "TransactionsDocAccessFields_remarks",
	create?: GraphQLTypes["TransactionsDocAccessFields_remarks_Create"] | undefined,
	read?: GraphQLTypes["TransactionsDocAccessFields_remarks_Read"] | undefined,
	update?: GraphQLTypes["TransactionsDocAccessFields_remarks_Update"] | undefined,
	delete?: GraphQLTypes["TransactionsDocAccessFields_remarks_Delete"] | undefined
};
	["TransactionsDocAccessFields_remarks_Create"]: {
	__typename: "TransactionsDocAccessFields_remarks_Create",
	permission: boolean
};
	["TransactionsDocAccessFields_remarks_Read"]: {
	__typename: "TransactionsDocAccessFields_remarks_Read",
	permission: boolean
};
	["TransactionsDocAccessFields_remarks_Update"]: {
	__typename: "TransactionsDocAccessFields_remarks_Update",
	permission: boolean
};
	["TransactionsDocAccessFields_remarks_Delete"]: {
	__typename: "TransactionsDocAccessFields_remarks_Delete",
	permission: boolean
};
	["TransactionsDocAccessFields_redemptionCodes"]: {
	__typename: "TransactionsDocAccessFields_redemptionCodes",
	create?: GraphQLTypes["TransactionsDocAccessFields_redemptionCodes_Create"] | undefined,
	read?: GraphQLTypes["TransactionsDocAccessFields_redemptionCodes_Read"] | undefined,
	update?: GraphQLTypes["TransactionsDocAccessFields_redemptionCodes_Update"] | undefined,
	delete?: GraphQLTypes["TransactionsDocAccessFields_redemptionCodes_Delete"] | undefined,
	fields?: GraphQLTypes["TransactionsDocAccessFields_redemptionCodes_Fields"] | undefined
};
	["TransactionsDocAccessFields_redemptionCodes_Create"]: {
	__typename: "TransactionsDocAccessFields_redemptionCodes_Create",
	permission: boolean
};
	["TransactionsDocAccessFields_redemptionCodes_Read"]: {
	__typename: "TransactionsDocAccessFields_redemptionCodes_Read",
	permission: boolean
};
	["TransactionsDocAccessFields_redemptionCodes_Update"]: {
	__typename: "TransactionsDocAccessFields_redemptionCodes_Update",
	permission: boolean
};
	["TransactionsDocAccessFields_redemptionCodes_Delete"]: {
	__typename: "TransactionsDocAccessFields_redemptionCodes_Delete",
	permission: boolean
};
	["TransactionsDocAccessFields_redemptionCodes_Fields"]: {
	__typename: "TransactionsDocAccessFields_redemptionCodes_Fields",
	code?: GraphQLTypes["TransactionsDocAccessFields_redemptionCodes_code"] | undefined,
	id?: GraphQLTypes["TransactionsDocAccessFields_redemptionCodes_id"] | undefined
};
	["TransactionsDocAccessFields_redemptionCodes_code"]: {
	__typename: "TransactionsDocAccessFields_redemptionCodes_code",
	create?: GraphQLTypes["TransactionsDocAccessFields_redemptionCodes_code_Create"] | undefined,
	read?: GraphQLTypes["TransactionsDocAccessFields_redemptionCodes_code_Read"] | undefined,
	update?: GraphQLTypes["TransactionsDocAccessFields_redemptionCodes_code_Update"] | undefined,
	delete?: GraphQLTypes["TransactionsDocAccessFields_redemptionCodes_code_Delete"] | undefined
};
	["TransactionsDocAccessFields_redemptionCodes_code_Create"]: {
	__typename: "TransactionsDocAccessFields_redemptionCodes_code_Create",
	permission: boolean
};
	["TransactionsDocAccessFields_redemptionCodes_code_Read"]: {
	__typename: "TransactionsDocAccessFields_redemptionCodes_code_Read",
	permission: boolean
};
	["TransactionsDocAccessFields_redemptionCodes_code_Update"]: {
	__typename: "TransactionsDocAccessFields_redemptionCodes_code_Update",
	permission: boolean
};
	["TransactionsDocAccessFields_redemptionCodes_code_Delete"]: {
	__typename: "TransactionsDocAccessFields_redemptionCodes_code_Delete",
	permission: boolean
};
	["TransactionsDocAccessFields_redemptionCodes_id"]: {
	__typename: "TransactionsDocAccessFields_redemptionCodes_id",
	create?: GraphQLTypes["TransactionsDocAccessFields_redemptionCodes_id_Create"] | undefined,
	read?: GraphQLTypes["TransactionsDocAccessFields_redemptionCodes_id_Read"] | undefined,
	update?: GraphQLTypes["TransactionsDocAccessFields_redemptionCodes_id_Update"] | undefined,
	delete?: GraphQLTypes["TransactionsDocAccessFields_redemptionCodes_id_Delete"] | undefined
};
	["TransactionsDocAccessFields_redemptionCodes_id_Create"]: {
	__typename: "TransactionsDocAccessFields_redemptionCodes_id_Create",
	permission: boolean
};
	["TransactionsDocAccessFields_redemptionCodes_id_Read"]: {
	__typename: "TransactionsDocAccessFields_redemptionCodes_id_Read",
	permission: boolean
};
	["TransactionsDocAccessFields_redemptionCodes_id_Update"]: {
	__typename: "TransactionsDocAccessFields_redemptionCodes_id_Update",
	permission: boolean
};
	["TransactionsDocAccessFields_redemptionCodes_id_Delete"]: {
	__typename: "TransactionsDocAccessFields_redemptionCodes_id_Delete",
	permission: boolean
};
	["TransactionsDocAccessFields_updatedAt"]: {
	__typename: "TransactionsDocAccessFields_updatedAt",
	create?: GraphQLTypes["TransactionsDocAccessFields_updatedAt_Create"] | undefined,
	read?: GraphQLTypes["TransactionsDocAccessFields_updatedAt_Read"] | undefined,
	update?: GraphQLTypes["TransactionsDocAccessFields_updatedAt_Update"] | undefined,
	delete?: GraphQLTypes["TransactionsDocAccessFields_updatedAt_Delete"] | undefined
};
	["TransactionsDocAccessFields_updatedAt_Create"]: {
	__typename: "TransactionsDocAccessFields_updatedAt_Create",
	permission: boolean
};
	["TransactionsDocAccessFields_updatedAt_Read"]: {
	__typename: "TransactionsDocAccessFields_updatedAt_Read",
	permission: boolean
};
	["TransactionsDocAccessFields_updatedAt_Update"]: {
	__typename: "TransactionsDocAccessFields_updatedAt_Update",
	permission: boolean
};
	["TransactionsDocAccessFields_updatedAt_Delete"]: {
	__typename: "TransactionsDocAccessFields_updatedAt_Delete",
	permission: boolean
};
	["TransactionsDocAccessFields_createdAt"]: {
	__typename: "TransactionsDocAccessFields_createdAt",
	create?: GraphQLTypes["TransactionsDocAccessFields_createdAt_Create"] | undefined,
	read?: GraphQLTypes["TransactionsDocAccessFields_createdAt_Read"] | undefined,
	update?: GraphQLTypes["TransactionsDocAccessFields_createdAt_Update"] | undefined,
	delete?: GraphQLTypes["TransactionsDocAccessFields_createdAt_Delete"] | undefined
};
	["TransactionsDocAccessFields_createdAt_Create"]: {
	__typename: "TransactionsDocAccessFields_createdAt_Create",
	permission: boolean
};
	["TransactionsDocAccessFields_createdAt_Read"]: {
	__typename: "TransactionsDocAccessFields_createdAt_Read",
	permission: boolean
};
	["TransactionsDocAccessFields_createdAt_Update"]: {
	__typename: "TransactionsDocAccessFields_createdAt_Update",
	permission: boolean
};
	["TransactionsDocAccessFields_createdAt_Delete"]: {
	__typename: "TransactionsDocAccessFields_createdAt_Delete",
	permission: boolean
};
	["TransactionsCreateDocAccess"]: {
	__typename: "TransactionsCreateDocAccess",
	permission: boolean,
	where?: GraphQLTypes["JSONObject"] | undefined
};
	["TransactionsReadDocAccess"]: {
	__typename: "TransactionsReadDocAccess",
	permission: boolean,
	where?: GraphQLTypes["JSONObject"] | undefined
};
	["TransactionsUpdateDocAccess"]: {
	__typename: "TransactionsUpdateDocAccess",
	permission: boolean,
	where?: GraphQLTypes["JSONObject"] | undefined
};
	["TransactionsDeleteDocAccess"]: {
	__typename: "TransactionsDeleteDocAccess",
	permission: boolean,
	where?: GraphQLTypes["JSONObject"] | undefined
};
	["RedemptionCode"]: {
	__typename: "RedemptionCode",
	id?: string | undefined,
	creator?: GraphQLTypes["User"] | undefined,
	status: GraphQLTypes["RedemptionCode_status"],
	qrCodeId?: string | undefined,
	lastCheckedInDateTime?: GraphQLTypes["DateTime"] | undefined,
	voidedDateTime?: GraphQLTypes["DateTime"] | undefined,
	refundedDateTime?: GraphQLTypes["DateTime"] | undefined,
	eventStartTime?: GraphQLTypes["DateTime"] | undefined,
	eventEndTime?: GraphQLTypes["DateTime"] | undefined,
	eventPriceZone?: string | undefined,
	ownerName: string,
	phone: string,
	pdf?: GraphQLTypes["RedemptionPdf"] | undefined,
	variants?: Array<GraphQLTypes["RedemptionCode_Variants"]> | undefined,
	productId?: string | undefined,
	referenceId?: string | undefined,
	productType?: GraphQLTypes["RedemptionCode_productType"] | undefined,
	productName?: string | undefined,
	linkedEvent?: GraphQLTypes["Event"] | undefined,
	timableOrderId?: string | undefined,
	purchaseDateTime?: GraphQLTypes["DateTime"] | undefined,
	changeHistory?: Array<GraphQLTypes["RedemptionCode_ChangeHistory"]> | undefined,
	updatedAt?: GraphQLTypes["DateTime"] | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined
};
	["RedemptionCode_status"]: RedemptionCode_status;
	["RedemptionPdf"]: {
	__typename: "RedemptionPdf",
	id?: string | undefined,
	updatedAt?: GraphQLTypes["DateTime"] | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	url?: string | undefined,
	filename?: string | undefined,
	mimeType?: string | undefined,
	filesize?: number | undefined,
	width?: number | undefined,
	height?: number | undefined,
	focalX?: number | undefined,
	focalY?: number | undefined
};
	["RedemptionCode_Variants"]: {
	__typename: "RedemptionCode_Variants",
	variantId?: string | undefined,
	name?: string | undefined,
	quantity?: string | undefined,
	totalCost?: string | undefined,
	id?: string | undefined
};
	["RedemptionCode_productType"]: RedemptionCode_productType;
	["RedemptionCode_ChangeHistory"]: {
	__typename: "RedemptionCode_ChangeHistory",
	timestamp?: GraphQLTypes["DateTime"] | undefined,
	field?: string | undefined,
	oldValue?: string | undefined,
	newValue?: string | undefined,
	id?: string | undefined
};
	["RedemptionCodes"]: {
	__typename: "RedemptionCodes",
	docs?: Array<GraphQLTypes["RedemptionCode"] | undefined> | undefined,
	hasNextPage?: boolean | undefined,
	hasPrevPage?: boolean | undefined,
	limit?: number | undefined,
	nextPage?: number | undefined,
	offset?: number | undefined,
	page?: number | undefined,
	pagingCounter?: number | undefined,
	prevPage?: number | undefined,
	totalDocs?: number | undefined,
	totalPages?: number | undefined
};
	["RedemptionCode_where"]: {
		creator?: GraphQLTypes["RedemptionCode_creator_operator"] | undefined,
	status?: GraphQLTypes["RedemptionCode_status_operator"] | undefined,
	qrCodeId?: GraphQLTypes["RedemptionCode_qrCodeId_operator"] | undefined,
	lastCheckedInDateTime?: GraphQLTypes["RedemptionCode_lastCheckedInDateTime_operator"] | undefined,
	voidedDateTime?: GraphQLTypes["RedemptionCode_voidedDateTime_operator"] | undefined,
	refundedDateTime?: GraphQLTypes["RedemptionCode_refundedDateTime_operator"] | undefined,
	eventStartTime?: GraphQLTypes["RedemptionCode_eventStartTime_operator"] | undefined,
	eventEndTime?: GraphQLTypes["RedemptionCode_eventEndTime_operator"] | undefined,
	eventPriceZone?: GraphQLTypes["RedemptionCode_eventPriceZone_operator"] | undefined,
	ownerName?: GraphQLTypes["RedemptionCode_ownerName_operator"] | undefined,
	phone?: GraphQLTypes["RedemptionCode_phone_operator"] | undefined,
	pdf?: GraphQLTypes["RedemptionCode_pdf_operator"] | undefined,
	variants__variantId?: GraphQLTypes["RedemptionCode_variants__variantId_operator"] | undefined,
	variants__name?: GraphQLTypes["RedemptionCode_variants__name_operator"] | undefined,
	variants__quantity?: GraphQLTypes["RedemptionCode_variants__quantity_operator"] | undefined,
	variants__totalCost?: GraphQLTypes["RedemptionCode_variants__totalCost_operator"] | undefined,
	variants__id?: GraphQLTypes["RedemptionCode_variants__id_operator"] | undefined,
	productId?: GraphQLTypes["RedemptionCode_productId_operator"] | undefined,
	referenceId?: GraphQLTypes["RedemptionCode_referenceId_operator"] | undefined,
	productType?: GraphQLTypes["RedemptionCode_productType_operator"] | undefined,
	productName?: GraphQLTypes["RedemptionCode_productName_operator"] | undefined,
	linkedEvent?: GraphQLTypes["RedemptionCode_linkedEvent_operator"] | undefined,
	timableOrderId?: GraphQLTypes["RedemptionCode_timableOrderId_operator"] | undefined,
	purchaseDateTime?: GraphQLTypes["RedemptionCode_purchaseDateTime_operator"] | undefined,
	changeHistory__timestamp?: GraphQLTypes["RedemptionCode_changeHistory__timestamp_operator"] | undefined,
	changeHistory__field?: GraphQLTypes["RedemptionCode_changeHistory__field_operator"] | undefined,
	changeHistory__oldValue?: GraphQLTypes["RedemptionCode_changeHistory__oldValue_operator"] | undefined,
	changeHistory__newValue?: GraphQLTypes["RedemptionCode_changeHistory__newValue_operator"] | undefined,
	changeHistory__id?: GraphQLTypes["RedemptionCode_changeHistory__id_operator"] | undefined,
	updatedAt?: GraphQLTypes["RedemptionCode_updatedAt_operator"] | undefined,
	createdAt?: GraphQLTypes["RedemptionCode_createdAt_operator"] | undefined,
	id?: GraphQLTypes["RedemptionCode_id_operator"] | undefined,
	AND?: Array<GraphQLTypes["RedemptionCode_where_and"] | undefined> | undefined,
	OR?: Array<GraphQLTypes["RedemptionCode_where_or"] | undefined> | undefined
};
	["RedemptionCode_creator_operator"]: {
		equals?: GraphQLTypes["JSON"] | undefined,
	not_equals?: GraphQLTypes["JSON"] | undefined,
	in?: Array<GraphQLTypes["JSON"] | undefined> | undefined,
	not_in?: Array<GraphQLTypes["JSON"] | undefined> | undefined,
	all?: Array<GraphQLTypes["JSON"] | undefined> | undefined,
	exists?: boolean | undefined
};
	["RedemptionCode_status_operator"]: {
		equals?: GraphQLTypes["RedemptionCode_status_Input"] | undefined,
	not_equals?: GraphQLTypes["RedemptionCode_status_Input"] | undefined,
	in?: Array<GraphQLTypes["RedemptionCode_status_Input"] | undefined> | undefined,
	not_in?: Array<GraphQLTypes["RedemptionCode_status_Input"] | undefined> | undefined,
	all?: Array<GraphQLTypes["RedemptionCode_status_Input"] | undefined> | undefined
};
	["RedemptionCode_status_Input"]: RedemptionCode_status_Input;
	["RedemptionCode_qrCodeId_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["RedemptionCode_lastCheckedInDateTime_operator"]: {
		equals?: GraphQLTypes["DateTime"] | undefined,
	not_equals?: GraphQLTypes["DateTime"] | undefined,
	greater_than_equal?: GraphQLTypes["DateTime"] | undefined,
	greater_than?: GraphQLTypes["DateTime"] | undefined,
	less_than_equal?: GraphQLTypes["DateTime"] | undefined,
	less_than?: GraphQLTypes["DateTime"] | undefined,
	like?: GraphQLTypes["DateTime"] | undefined,
	exists?: boolean | undefined
};
	["RedemptionCode_voidedDateTime_operator"]: {
		equals?: GraphQLTypes["DateTime"] | undefined,
	not_equals?: GraphQLTypes["DateTime"] | undefined,
	greater_than_equal?: GraphQLTypes["DateTime"] | undefined,
	greater_than?: GraphQLTypes["DateTime"] | undefined,
	less_than_equal?: GraphQLTypes["DateTime"] | undefined,
	less_than?: GraphQLTypes["DateTime"] | undefined,
	like?: GraphQLTypes["DateTime"] | undefined,
	exists?: boolean | undefined
};
	["RedemptionCode_refundedDateTime_operator"]: {
		equals?: GraphQLTypes["DateTime"] | undefined,
	not_equals?: GraphQLTypes["DateTime"] | undefined,
	greater_than_equal?: GraphQLTypes["DateTime"] | undefined,
	greater_than?: GraphQLTypes["DateTime"] | undefined,
	less_than_equal?: GraphQLTypes["DateTime"] | undefined,
	less_than?: GraphQLTypes["DateTime"] | undefined,
	like?: GraphQLTypes["DateTime"] | undefined,
	exists?: boolean | undefined
};
	["RedemptionCode_eventStartTime_operator"]: {
		equals?: GraphQLTypes["DateTime"] | undefined,
	not_equals?: GraphQLTypes["DateTime"] | undefined,
	greater_than_equal?: GraphQLTypes["DateTime"] | undefined,
	greater_than?: GraphQLTypes["DateTime"] | undefined,
	less_than_equal?: GraphQLTypes["DateTime"] | undefined,
	less_than?: GraphQLTypes["DateTime"] | undefined,
	like?: GraphQLTypes["DateTime"] | undefined,
	exists?: boolean | undefined
};
	["RedemptionCode_eventEndTime_operator"]: {
		equals?: GraphQLTypes["DateTime"] | undefined,
	not_equals?: GraphQLTypes["DateTime"] | undefined,
	greater_than_equal?: GraphQLTypes["DateTime"] | undefined,
	greater_than?: GraphQLTypes["DateTime"] | undefined,
	less_than_equal?: GraphQLTypes["DateTime"] | undefined,
	less_than?: GraphQLTypes["DateTime"] | undefined,
	like?: GraphQLTypes["DateTime"] | undefined,
	exists?: boolean | undefined
};
	["RedemptionCode_eventPriceZone_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["RedemptionCode_ownerName_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined
};
	["RedemptionCode_phone_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined
};
	["RedemptionCode_pdf_operator"]: {
		equals?: GraphQLTypes["JSON"] | undefined,
	not_equals?: GraphQLTypes["JSON"] | undefined,
	in?: Array<GraphQLTypes["JSON"] | undefined> | undefined,
	not_in?: Array<GraphQLTypes["JSON"] | undefined> | undefined,
	all?: Array<GraphQLTypes["JSON"] | undefined> | undefined,
	exists?: boolean | undefined
};
	["RedemptionCode_variants__variantId_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["RedemptionCode_variants__name_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["RedemptionCode_variants__quantity_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["RedemptionCode_variants__totalCost_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["RedemptionCode_variants__id_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["RedemptionCode_productId_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["RedemptionCode_referenceId_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["RedemptionCode_productType_operator"]: {
		equals?: GraphQLTypes["RedemptionCode_productType_Input"] | undefined,
	not_equals?: GraphQLTypes["RedemptionCode_productType_Input"] | undefined,
	like?: GraphQLTypes["RedemptionCode_productType_Input"] | undefined,
	contains?: GraphQLTypes["RedemptionCode_productType_Input"] | undefined,
	exists?: boolean | undefined
};
	["RedemptionCode_productType_Input"]: RedemptionCode_productType_Input;
	["RedemptionCode_productName_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["RedemptionCode_linkedEvent_operator"]: {
		equals?: GraphQLTypes["JSON"] | undefined,
	not_equals?: GraphQLTypes["JSON"] | undefined,
	in?: Array<GraphQLTypes["JSON"] | undefined> | undefined,
	not_in?: Array<GraphQLTypes["JSON"] | undefined> | undefined,
	all?: Array<GraphQLTypes["JSON"] | undefined> | undefined,
	exists?: boolean | undefined
};
	["RedemptionCode_timableOrderId_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["RedemptionCode_purchaseDateTime_operator"]: {
		equals?: GraphQLTypes["DateTime"] | undefined,
	not_equals?: GraphQLTypes["DateTime"] | undefined,
	greater_than_equal?: GraphQLTypes["DateTime"] | undefined,
	greater_than?: GraphQLTypes["DateTime"] | undefined,
	less_than_equal?: GraphQLTypes["DateTime"] | undefined,
	less_than?: GraphQLTypes["DateTime"] | undefined,
	like?: GraphQLTypes["DateTime"] | undefined,
	exists?: boolean | undefined
};
	["RedemptionCode_changeHistory__timestamp_operator"]: {
		equals?: GraphQLTypes["DateTime"] | undefined,
	not_equals?: GraphQLTypes["DateTime"] | undefined,
	greater_than_equal?: GraphQLTypes["DateTime"] | undefined,
	greater_than?: GraphQLTypes["DateTime"] | undefined,
	less_than_equal?: GraphQLTypes["DateTime"] | undefined,
	less_than?: GraphQLTypes["DateTime"] | undefined,
	like?: GraphQLTypes["DateTime"] | undefined
};
	["RedemptionCode_changeHistory__field_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined
};
	["RedemptionCode_changeHistory__oldValue_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined
};
	["RedemptionCode_changeHistory__newValue_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined
};
	["RedemptionCode_changeHistory__id_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["RedemptionCode_updatedAt_operator"]: {
		equals?: GraphQLTypes["DateTime"] | undefined,
	not_equals?: GraphQLTypes["DateTime"] | undefined,
	greater_than_equal?: GraphQLTypes["DateTime"] | undefined,
	greater_than?: GraphQLTypes["DateTime"] | undefined,
	less_than_equal?: GraphQLTypes["DateTime"] | undefined,
	less_than?: GraphQLTypes["DateTime"] | undefined,
	like?: GraphQLTypes["DateTime"] | undefined,
	exists?: boolean | undefined
};
	["RedemptionCode_createdAt_operator"]: {
		equals?: GraphQLTypes["DateTime"] | undefined,
	not_equals?: GraphQLTypes["DateTime"] | undefined,
	greater_than_equal?: GraphQLTypes["DateTime"] | undefined,
	greater_than?: GraphQLTypes["DateTime"] | undefined,
	less_than_equal?: GraphQLTypes["DateTime"] | undefined,
	less_than?: GraphQLTypes["DateTime"] | undefined,
	like?: GraphQLTypes["DateTime"] | undefined,
	exists?: boolean | undefined
};
	["RedemptionCode_id_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["RedemptionCode_where_and"]: {
		creator?: GraphQLTypes["RedemptionCode_creator_operator"] | undefined,
	status?: GraphQLTypes["RedemptionCode_status_operator"] | undefined,
	qrCodeId?: GraphQLTypes["RedemptionCode_qrCodeId_operator"] | undefined,
	lastCheckedInDateTime?: GraphQLTypes["RedemptionCode_lastCheckedInDateTime_operator"] | undefined,
	voidedDateTime?: GraphQLTypes["RedemptionCode_voidedDateTime_operator"] | undefined,
	refundedDateTime?: GraphQLTypes["RedemptionCode_refundedDateTime_operator"] | undefined,
	eventStartTime?: GraphQLTypes["RedemptionCode_eventStartTime_operator"] | undefined,
	eventEndTime?: GraphQLTypes["RedemptionCode_eventEndTime_operator"] | undefined,
	eventPriceZone?: GraphQLTypes["RedemptionCode_eventPriceZone_operator"] | undefined,
	ownerName?: GraphQLTypes["RedemptionCode_ownerName_operator"] | undefined,
	phone?: GraphQLTypes["RedemptionCode_phone_operator"] | undefined,
	pdf?: GraphQLTypes["RedemptionCode_pdf_operator"] | undefined,
	variants__variantId?: GraphQLTypes["RedemptionCode_variants__variantId_operator"] | undefined,
	variants__name?: GraphQLTypes["RedemptionCode_variants__name_operator"] | undefined,
	variants__quantity?: GraphQLTypes["RedemptionCode_variants__quantity_operator"] | undefined,
	variants__totalCost?: GraphQLTypes["RedemptionCode_variants__totalCost_operator"] | undefined,
	variants__id?: GraphQLTypes["RedemptionCode_variants__id_operator"] | undefined,
	productId?: GraphQLTypes["RedemptionCode_productId_operator"] | undefined,
	referenceId?: GraphQLTypes["RedemptionCode_referenceId_operator"] | undefined,
	productType?: GraphQLTypes["RedemptionCode_productType_operator"] | undefined,
	productName?: GraphQLTypes["RedemptionCode_productName_operator"] | undefined,
	linkedEvent?: GraphQLTypes["RedemptionCode_linkedEvent_operator"] | undefined,
	timableOrderId?: GraphQLTypes["RedemptionCode_timableOrderId_operator"] | undefined,
	purchaseDateTime?: GraphQLTypes["RedemptionCode_purchaseDateTime_operator"] | undefined,
	changeHistory__timestamp?: GraphQLTypes["RedemptionCode_changeHistory__timestamp_operator"] | undefined,
	changeHistory__field?: GraphQLTypes["RedemptionCode_changeHistory__field_operator"] | undefined,
	changeHistory__oldValue?: GraphQLTypes["RedemptionCode_changeHistory__oldValue_operator"] | undefined,
	changeHistory__newValue?: GraphQLTypes["RedemptionCode_changeHistory__newValue_operator"] | undefined,
	changeHistory__id?: GraphQLTypes["RedemptionCode_changeHistory__id_operator"] | undefined,
	updatedAt?: GraphQLTypes["RedemptionCode_updatedAt_operator"] | undefined,
	createdAt?: GraphQLTypes["RedemptionCode_createdAt_operator"] | undefined,
	id?: GraphQLTypes["RedemptionCode_id_operator"] | undefined,
	AND?: Array<GraphQLTypes["RedemptionCode_where_and"] | undefined> | undefined,
	OR?: Array<GraphQLTypes["RedemptionCode_where_or"] | undefined> | undefined
};
	["RedemptionCode_where_or"]: {
		creator?: GraphQLTypes["RedemptionCode_creator_operator"] | undefined,
	status?: GraphQLTypes["RedemptionCode_status_operator"] | undefined,
	qrCodeId?: GraphQLTypes["RedemptionCode_qrCodeId_operator"] | undefined,
	lastCheckedInDateTime?: GraphQLTypes["RedemptionCode_lastCheckedInDateTime_operator"] | undefined,
	voidedDateTime?: GraphQLTypes["RedemptionCode_voidedDateTime_operator"] | undefined,
	refundedDateTime?: GraphQLTypes["RedemptionCode_refundedDateTime_operator"] | undefined,
	eventStartTime?: GraphQLTypes["RedemptionCode_eventStartTime_operator"] | undefined,
	eventEndTime?: GraphQLTypes["RedemptionCode_eventEndTime_operator"] | undefined,
	eventPriceZone?: GraphQLTypes["RedemptionCode_eventPriceZone_operator"] | undefined,
	ownerName?: GraphQLTypes["RedemptionCode_ownerName_operator"] | undefined,
	phone?: GraphQLTypes["RedemptionCode_phone_operator"] | undefined,
	pdf?: GraphQLTypes["RedemptionCode_pdf_operator"] | undefined,
	variants__variantId?: GraphQLTypes["RedemptionCode_variants__variantId_operator"] | undefined,
	variants__name?: GraphQLTypes["RedemptionCode_variants__name_operator"] | undefined,
	variants__quantity?: GraphQLTypes["RedemptionCode_variants__quantity_operator"] | undefined,
	variants__totalCost?: GraphQLTypes["RedemptionCode_variants__totalCost_operator"] | undefined,
	variants__id?: GraphQLTypes["RedemptionCode_variants__id_operator"] | undefined,
	productId?: GraphQLTypes["RedemptionCode_productId_operator"] | undefined,
	referenceId?: GraphQLTypes["RedemptionCode_referenceId_operator"] | undefined,
	productType?: GraphQLTypes["RedemptionCode_productType_operator"] | undefined,
	productName?: GraphQLTypes["RedemptionCode_productName_operator"] | undefined,
	linkedEvent?: GraphQLTypes["RedemptionCode_linkedEvent_operator"] | undefined,
	timableOrderId?: GraphQLTypes["RedemptionCode_timableOrderId_operator"] | undefined,
	purchaseDateTime?: GraphQLTypes["RedemptionCode_purchaseDateTime_operator"] | undefined,
	changeHistory__timestamp?: GraphQLTypes["RedemptionCode_changeHistory__timestamp_operator"] | undefined,
	changeHistory__field?: GraphQLTypes["RedemptionCode_changeHistory__field_operator"] | undefined,
	changeHistory__oldValue?: GraphQLTypes["RedemptionCode_changeHistory__oldValue_operator"] | undefined,
	changeHistory__newValue?: GraphQLTypes["RedemptionCode_changeHistory__newValue_operator"] | undefined,
	changeHistory__id?: GraphQLTypes["RedemptionCode_changeHistory__id_operator"] | undefined,
	updatedAt?: GraphQLTypes["RedemptionCode_updatedAt_operator"] | undefined,
	createdAt?: GraphQLTypes["RedemptionCode_createdAt_operator"] | undefined,
	id?: GraphQLTypes["RedemptionCode_id_operator"] | undefined,
	AND?: Array<GraphQLTypes["RedemptionCode_where_and"] | undefined> | undefined,
	OR?: Array<GraphQLTypes["RedemptionCode_where_or"] | undefined> | undefined
};
	["countRedemptionCodes"]: {
	__typename: "countRedemptionCodes",
	totalDocs?: number | undefined
};
	["redemption_codesDocAccess"]: {
	__typename: "redemption_codesDocAccess",
	fields?: GraphQLTypes["RedemptionCodesDocAccessFields"] | undefined,
	create?: GraphQLTypes["RedemptionCodesCreateDocAccess"] | undefined,
	read?: GraphQLTypes["RedemptionCodesReadDocAccess"] | undefined,
	update?: GraphQLTypes["RedemptionCodesUpdateDocAccess"] | undefined,
	delete?: GraphQLTypes["RedemptionCodesDeleteDocAccess"] | undefined
};
	["RedemptionCodesDocAccessFields"]: {
	__typename: "RedemptionCodesDocAccessFields",
	creator?: GraphQLTypes["RedemptionCodesDocAccessFields_creator"] | undefined,
	status?: GraphQLTypes["RedemptionCodesDocAccessFields_status"] | undefined,
	qrCodeId?: GraphQLTypes["RedemptionCodesDocAccessFields_qrCodeId"] | undefined,
	lastCheckedInDateTime?: GraphQLTypes["RedemptionCodesDocAccessFields_lastCheckedInDateTime"] | undefined,
	voidedDateTime?: GraphQLTypes["RedemptionCodesDocAccessFields_voidedDateTime"] | undefined,
	refundedDateTime?: GraphQLTypes["RedemptionCodesDocAccessFields_refundedDateTime"] | undefined,
	eventStartTime?: GraphQLTypes["RedemptionCodesDocAccessFields_eventStartTime"] | undefined,
	eventEndTime?: GraphQLTypes["RedemptionCodesDocAccessFields_eventEndTime"] | undefined,
	eventPriceZone?: GraphQLTypes["RedemptionCodesDocAccessFields_eventPriceZone"] | undefined,
	ownerName?: GraphQLTypes["RedemptionCodesDocAccessFields_ownerName"] | undefined,
	phone?: GraphQLTypes["RedemptionCodesDocAccessFields_phone"] | undefined,
	pdf?: GraphQLTypes["RedemptionCodesDocAccessFields_pdf"] | undefined,
	variants?: GraphQLTypes["RedemptionCodesDocAccessFields_variants"] | undefined,
	productId?: GraphQLTypes["RedemptionCodesDocAccessFields_productId"] | undefined,
	referenceId?: GraphQLTypes["RedemptionCodesDocAccessFields_referenceId"] | undefined,
	productType?: GraphQLTypes["RedemptionCodesDocAccessFields_productType"] | undefined,
	productName?: GraphQLTypes["RedemptionCodesDocAccessFields_productName"] | undefined,
	linkedEvent?: GraphQLTypes["RedemptionCodesDocAccessFields_linkedEvent"] | undefined,
	timableOrderId?: GraphQLTypes["RedemptionCodesDocAccessFields_timableOrderId"] | undefined,
	purchaseDateTime?: GraphQLTypes["RedemptionCodesDocAccessFields_purchaseDateTime"] | undefined,
	changeHistory?: GraphQLTypes["RedemptionCodesDocAccessFields_changeHistory"] | undefined,
	updatedAt?: GraphQLTypes["RedemptionCodesDocAccessFields_updatedAt"] | undefined,
	createdAt?: GraphQLTypes["RedemptionCodesDocAccessFields_createdAt"] | undefined
};
	["RedemptionCodesDocAccessFields_creator"]: {
	__typename: "RedemptionCodesDocAccessFields_creator",
	create?: GraphQLTypes["RedemptionCodesDocAccessFields_creator_Create"] | undefined,
	read?: GraphQLTypes["RedemptionCodesDocAccessFields_creator_Read"] | undefined,
	update?: GraphQLTypes["RedemptionCodesDocAccessFields_creator_Update"] | undefined,
	delete?: GraphQLTypes["RedemptionCodesDocAccessFields_creator_Delete"] | undefined
};
	["RedemptionCodesDocAccessFields_creator_Create"]: {
	__typename: "RedemptionCodesDocAccessFields_creator_Create",
	permission: boolean
};
	["RedemptionCodesDocAccessFields_creator_Read"]: {
	__typename: "RedemptionCodesDocAccessFields_creator_Read",
	permission: boolean
};
	["RedemptionCodesDocAccessFields_creator_Update"]: {
	__typename: "RedemptionCodesDocAccessFields_creator_Update",
	permission: boolean
};
	["RedemptionCodesDocAccessFields_creator_Delete"]: {
	__typename: "RedemptionCodesDocAccessFields_creator_Delete",
	permission: boolean
};
	["RedemptionCodesDocAccessFields_status"]: {
	__typename: "RedemptionCodesDocAccessFields_status",
	create?: GraphQLTypes["RedemptionCodesDocAccessFields_status_Create"] | undefined,
	read?: GraphQLTypes["RedemptionCodesDocAccessFields_status_Read"] | undefined,
	update?: GraphQLTypes["RedemptionCodesDocAccessFields_status_Update"] | undefined,
	delete?: GraphQLTypes["RedemptionCodesDocAccessFields_status_Delete"] | undefined
};
	["RedemptionCodesDocAccessFields_status_Create"]: {
	__typename: "RedemptionCodesDocAccessFields_status_Create",
	permission: boolean
};
	["RedemptionCodesDocAccessFields_status_Read"]: {
	__typename: "RedemptionCodesDocAccessFields_status_Read",
	permission: boolean
};
	["RedemptionCodesDocAccessFields_status_Update"]: {
	__typename: "RedemptionCodesDocAccessFields_status_Update",
	permission: boolean
};
	["RedemptionCodesDocAccessFields_status_Delete"]: {
	__typename: "RedemptionCodesDocAccessFields_status_Delete",
	permission: boolean
};
	["RedemptionCodesDocAccessFields_qrCodeId"]: {
	__typename: "RedemptionCodesDocAccessFields_qrCodeId",
	create?: GraphQLTypes["RedemptionCodesDocAccessFields_qrCodeId_Create"] | undefined,
	read?: GraphQLTypes["RedemptionCodesDocAccessFields_qrCodeId_Read"] | undefined,
	update?: GraphQLTypes["RedemptionCodesDocAccessFields_qrCodeId_Update"] | undefined,
	delete?: GraphQLTypes["RedemptionCodesDocAccessFields_qrCodeId_Delete"] | undefined
};
	["RedemptionCodesDocAccessFields_qrCodeId_Create"]: {
	__typename: "RedemptionCodesDocAccessFields_qrCodeId_Create",
	permission: boolean
};
	["RedemptionCodesDocAccessFields_qrCodeId_Read"]: {
	__typename: "RedemptionCodesDocAccessFields_qrCodeId_Read",
	permission: boolean
};
	["RedemptionCodesDocAccessFields_qrCodeId_Update"]: {
	__typename: "RedemptionCodesDocAccessFields_qrCodeId_Update",
	permission: boolean
};
	["RedemptionCodesDocAccessFields_qrCodeId_Delete"]: {
	__typename: "RedemptionCodesDocAccessFields_qrCodeId_Delete",
	permission: boolean
};
	["RedemptionCodesDocAccessFields_lastCheckedInDateTime"]: {
	__typename: "RedemptionCodesDocAccessFields_lastCheckedInDateTime",
	create?: GraphQLTypes["RedemptionCodesDocAccessFields_lastCheckedInDateTime_Create"] | undefined,
	read?: GraphQLTypes["RedemptionCodesDocAccessFields_lastCheckedInDateTime_Read"] | undefined,
	update?: GraphQLTypes["RedemptionCodesDocAccessFields_lastCheckedInDateTime_Update"] | undefined,
	delete?: GraphQLTypes["RedemptionCodesDocAccessFields_lastCheckedInDateTime_Delete"] | undefined
};
	["RedemptionCodesDocAccessFields_lastCheckedInDateTime_Create"]: {
	__typename: "RedemptionCodesDocAccessFields_lastCheckedInDateTime_Create",
	permission: boolean
};
	["RedemptionCodesDocAccessFields_lastCheckedInDateTime_Read"]: {
	__typename: "RedemptionCodesDocAccessFields_lastCheckedInDateTime_Read",
	permission: boolean
};
	["RedemptionCodesDocAccessFields_lastCheckedInDateTime_Update"]: {
	__typename: "RedemptionCodesDocAccessFields_lastCheckedInDateTime_Update",
	permission: boolean
};
	["RedemptionCodesDocAccessFields_lastCheckedInDateTime_Delete"]: {
	__typename: "RedemptionCodesDocAccessFields_lastCheckedInDateTime_Delete",
	permission: boolean
};
	["RedemptionCodesDocAccessFields_voidedDateTime"]: {
	__typename: "RedemptionCodesDocAccessFields_voidedDateTime",
	create?: GraphQLTypes["RedemptionCodesDocAccessFields_voidedDateTime_Create"] | undefined,
	read?: GraphQLTypes["RedemptionCodesDocAccessFields_voidedDateTime_Read"] | undefined,
	update?: GraphQLTypes["RedemptionCodesDocAccessFields_voidedDateTime_Update"] | undefined,
	delete?: GraphQLTypes["RedemptionCodesDocAccessFields_voidedDateTime_Delete"] | undefined
};
	["RedemptionCodesDocAccessFields_voidedDateTime_Create"]: {
	__typename: "RedemptionCodesDocAccessFields_voidedDateTime_Create",
	permission: boolean
};
	["RedemptionCodesDocAccessFields_voidedDateTime_Read"]: {
	__typename: "RedemptionCodesDocAccessFields_voidedDateTime_Read",
	permission: boolean
};
	["RedemptionCodesDocAccessFields_voidedDateTime_Update"]: {
	__typename: "RedemptionCodesDocAccessFields_voidedDateTime_Update",
	permission: boolean
};
	["RedemptionCodesDocAccessFields_voidedDateTime_Delete"]: {
	__typename: "RedemptionCodesDocAccessFields_voidedDateTime_Delete",
	permission: boolean
};
	["RedemptionCodesDocAccessFields_refundedDateTime"]: {
	__typename: "RedemptionCodesDocAccessFields_refundedDateTime",
	create?: GraphQLTypes["RedemptionCodesDocAccessFields_refundedDateTime_Create"] | undefined,
	read?: GraphQLTypes["RedemptionCodesDocAccessFields_refundedDateTime_Read"] | undefined,
	update?: GraphQLTypes["RedemptionCodesDocAccessFields_refundedDateTime_Update"] | undefined,
	delete?: GraphQLTypes["RedemptionCodesDocAccessFields_refundedDateTime_Delete"] | undefined
};
	["RedemptionCodesDocAccessFields_refundedDateTime_Create"]: {
	__typename: "RedemptionCodesDocAccessFields_refundedDateTime_Create",
	permission: boolean
};
	["RedemptionCodesDocAccessFields_refundedDateTime_Read"]: {
	__typename: "RedemptionCodesDocAccessFields_refundedDateTime_Read",
	permission: boolean
};
	["RedemptionCodesDocAccessFields_refundedDateTime_Update"]: {
	__typename: "RedemptionCodesDocAccessFields_refundedDateTime_Update",
	permission: boolean
};
	["RedemptionCodesDocAccessFields_refundedDateTime_Delete"]: {
	__typename: "RedemptionCodesDocAccessFields_refundedDateTime_Delete",
	permission: boolean
};
	["RedemptionCodesDocAccessFields_eventStartTime"]: {
	__typename: "RedemptionCodesDocAccessFields_eventStartTime",
	create?: GraphQLTypes["RedemptionCodesDocAccessFields_eventStartTime_Create"] | undefined,
	read?: GraphQLTypes["RedemptionCodesDocAccessFields_eventStartTime_Read"] | undefined,
	update?: GraphQLTypes["RedemptionCodesDocAccessFields_eventStartTime_Update"] | undefined,
	delete?: GraphQLTypes["RedemptionCodesDocAccessFields_eventStartTime_Delete"] | undefined
};
	["RedemptionCodesDocAccessFields_eventStartTime_Create"]: {
	__typename: "RedemptionCodesDocAccessFields_eventStartTime_Create",
	permission: boolean
};
	["RedemptionCodesDocAccessFields_eventStartTime_Read"]: {
	__typename: "RedemptionCodesDocAccessFields_eventStartTime_Read",
	permission: boolean
};
	["RedemptionCodesDocAccessFields_eventStartTime_Update"]: {
	__typename: "RedemptionCodesDocAccessFields_eventStartTime_Update",
	permission: boolean
};
	["RedemptionCodesDocAccessFields_eventStartTime_Delete"]: {
	__typename: "RedemptionCodesDocAccessFields_eventStartTime_Delete",
	permission: boolean
};
	["RedemptionCodesDocAccessFields_eventEndTime"]: {
	__typename: "RedemptionCodesDocAccessFields_eventEndTime",
	create?: GraphQLTypes["RedemptionCodesDocAccessFields_eventEndTime_Create"] | undefined,
	read?: GraphQLTypes["RedemptionCodesDocAccessFields_eventEndTime_Read"] | undefined,
	update?: GraphQLTypes["RedemptionCodesDocAccessFields_eventEndTime_Update"] | undefined,
	delete?: GraphQLTypes["RedemptionCodesDocAccessFields_eventEndTime_Delete"] | undefined
};
	["RedemptionCodesDocAccessFields_eventEndTime_Create"]: {
	__typename: "RedemptionCodesDocAccessFields_eventEndTime_Create",
	permission: boolean
};
	["RedemptionCodesDocAccessFields_eventEndTime_Read"]: {
	__typename: "RedemptionCodesDocAccessFields_eventEndTime_Read",
	permission: boolean
};
	["RedemptionCodesDocAccessFields_eventEndTime_Update"]: {
	__typename: "RedemptionCodesDocAccessFields_eventEndTime_Update",
	permission: boolean
};
	["RedemptionCodesDocAccessFields_eventEndTime_Delete"]: {
	__typename: "RedemptionCodesDocAccessFields_eventEndTime_Delete",
	permission: boolean
};
	["RedemptionCodesDocAccessFields_eventPriceZone"]: {
	__typename: "RedemptionCodesDocAccessFields_eventPriceZone",
	create?: GraphQLTypes["RedemptionCodesDocAccessFields_eventPriceZone_Create"] | undefined,
	read?: GraphQLTypes["RedemptionCodesDocAccessFields_eventPriceZone_Read"] | undefined,
	update?: GraphQLTypes["RedemptionCodesDocAccessFields_eventPriceZone_Update"] | undefined,
	delete?: GraphQLTypes["RedemptionCodesDocAccessFields_eventPriceZone_Delete"] | undefined
};
	["RedemptionCodesDocAccessFields_eventPriceZone_Create"]: {
	__typename: "RedemptionCodesDocAccessFields_eventPriceZone_Create",
	permission: boolean
};
	["RedemptionCodesDocAccessFields_eventPriceZone_Read"]: {
	__typename: "RedemptionCodesDocAccessFields_eventPriceZone_Read",
	permission: boolean
};
	["RedemptionCodesDocAccessFields_eventPriceZone_Update"]: {
	__typename: "RedemptionCodesDocAccessFields_eventPriceZone_Update",
	permission: boolean
};
	["RedemptionCodesDocAccessFields_eventPriceZone_Delete"]: {
	__typename: "RedemptionCodesDocAccessFields_eventPriceZone_Delete",
	permission: boolean
};
	["RedemptionCodesDocAccessFields_ownerName"]: {
	__typename: "RedemptionCodesDocAccessFields_ownerName",
	create?: GraphQLTypes["RedemptionCodesDocAccessFields_ownerName_Create"] | undefined,
	read?: GraphQLTypes["RedemptionCodesDocAccessFields_ownerName_Read"] | undefined,
	update?: GraphQLTypes["RedemptionCodesDocAccessFields_ownerName_Update"] | undefined,
	delete?: GraphQLTypes["RedemptionCodesDocAccessFields_ownerName_Delete"] | undefined
};
	["RedemptionCodesDocAccessFields_ownerName_Create"]: {
	__typename: "RedemptionCodesDocAccessFields_ownerName_Create",
	permission: boolean
};
	["RedemptionCodesDocAccessFields_ownerName_Read"]: {
	__typename: "RedemptionCodesDocAccessFields_ownerName_Read",
	permission: boolean
};
	["RedemptionCodesDocAccessFields_ownerName_Update"]: {
	__typename: "RedemptionCodesDocAccessFields_ownerName_Update",
	permission: boolean
};
	["RedemptionCodesDocAccessFields_ownerName_Delete"]: {
	__typename: "RedemptionCodesDocAccessFields_ownerName_Delete",
	permission: boolean
};
	["RedemptionCodesDocAccessFields_phone"]: {
	__typename: "RedemptionCodesDocAccessFields_phone",
	create?: GraphQLTypes["RedemptionCodesDocAccessFields_phone_Create"] | undefined,
	read?: GraphQLTypes["RedemptionCodesDocAccessFields_phone_Read"] | undefined,
	update?: GraphQLTypes["RedemptionCodesDocAccessFields_phone_Update"] | undefined,
	delete?: GraphQLTypes["RedemptionCodesDocAccessFields_phone_Delete"] | undefined
};
	["RedemptionCodesDocAccessFields_phone_Create"]: {
	__typename: "RedemptionCodesDocAccessFields_phone_Create",
	permission: boolean
};
	["RedemptionCodesDocAccessFields_phone_Read"]: {
	__typename: "RedemptionCodesDocAccessFields_phone_Read",
	permission: boolean
};
	["RedemptionCodesDocAccessFields_phone_Update"]: {
	__typename: "RedemptionCodesDocAccessFields_phone_Update",
	permission: boolean
};
	["RedemptionCodesDocAccessFields_phone_Delete"]: {
	__typename: "RedemptionCodesDocAccessFields_phone_Delete",
	permission: boolean
};
	["RedemptionCodesDocAccessFields_pdf"]: {
	__typename: "RedemptionCodesDocAccessFields_pdf",
	create?: GraphQLTypes["RedemptionCodesDocAccessFields_pdf_Create"] | undefined,
	read?: GraphQLTypes["RedemptionCodesDocAccessFields_pdf_Read"] | undefined,
	update?: GraphQLTypes["RedemptionCodesDocAccessFields_pdf_Update"] | undefined,
	delete?: GraphQLTypes["RedemptionCodesDocAccessFields_pdf_Delete"] | undefined
};
	["RedemptionCodesDocAccessFields_pdf_Create"]: {
	__typename: "RedemptionCodesDocAccessFields_pdf_Create",
	permission: boolean
};
	["RedemptionCodesDocAccessFields_pdf_Read"]: {
	__typename: "RedemptionCodesDocAccessFields_pdf_Read",
	permission: boolean
};
	["RedemptionCodesDocAccessFields_pdf_Update"]: {
	__typename: "RedemptionCodesDocAccessFields_pdf_Update",
	permission: boolean
};
	["RedemptionCodesDocAccessFields_pdf_Delete"]: {
	__typename: "RedemptionCodesDocAccessFields_pdf_Delete",
	permission: boolean
};
	["RedemptionCodesDocAccessFields_variants"]: {
	__typename: "RedemptionCodesDocAccessFields_variants",
	create?: GraphQLTypes["RedemptionCodesDocAccessFields_variants_Create"] | undefined,
	read?: GraphQLTypes["RedemptionCodesDocAccessFields_variants_Read"] | undefined,
	update?: GraphQLTypes["RedemptionCodesDocAccessFields_variants_Update"] | undefined,
	delete?: GraphQLTypes["RedemptionCodesDocAccessFields_variants_Delete"] | undefined,
	fields?: GraphQLTypes["RedemptionCodesDocAccessFields_variants_Fields"] | undefined
};
	["RedemptionCodesDocAccessFields_variants_Create"]: {
	__typename: "RedemptionCodesDocAccessFields_variants_Create",
	permission: boolean
};
	["RedemptionCodesDocAccessFields_variants_Read"]: {
	__typename: "RedemptionCodesDocAccessFields_variants_Read",
	permission: boolean
};
	["RedemptionCodesDocAccessFields_variants_Update"]: {
	__typename: "RedemptionCodesDocAccessFields_variants_Update",
	permission: boolean
};
	["RedemptionCodesDocAccessFields_variants_Delete"]: {
	__typename: "RedemptionCodesDocAccessFields_variants_Delete",
	permission: boolean
};
	["RedemptionCodesDocAccessFields_variants_Fields"]: {
	__typename: "RedemptionCodesDocAccessFields_variants_Fields",
	variantId?: GraphQLTypes["RedemptionCodesDocAccessFields_variants_variantId"] | undefined,
	name?: GraphQLTypes["RedemptionCodesDocAccessFields_variants_name"] | undefined,
	quantity?: GraphQLTypes["RedemptionCodesDocAccessFields_variants_quantity"] | undefined,
	totalCost?: GraphQLTypes["RedemptionCodesDocAccessFields_variants_totalCost"] | undefined,
	id?: GraphQLTypes["RedemptionCodesDocAccessFields_variants_id"] | undefined
};
	["RedemptionCodesDocAccessFields_variants_variantId"]: {
	__typename: "RedemptionCodesDocAccessFields_variants_variantId",
	create?: GraphQLTypes["RedemptionCodesDocAccessFields_variants_variantId_Create"] | undefined,
	read?: GraphQLTypes["RedemptionCodesDocAccessFields_variants_variantId_Read"] | undefined,
	update?: GraphQLTypes["RedemptionCodesDocAccessFields_variants_variantId_Update"] | undefined,
	delete?: GraphQLTypes["RedemptionCodesDocAccessFields_variants_variantId_Delete"] | undefined
};
	["RedemptionCodesDocAccessFields_variants_variantId_Create"]: {
	__typename: "RedemptionCodesDocAccessFields_variants_variantId_Create",
	permission: boolean
};
	["RedemptionCodesDocAccessFields_variants_variantId_Read"]: {
	__typename: "RedemptionCodesDocAccessFields_variants_variantId_Read",
	permission: boolean
};
	["RedemptionCodesDocAccessFields_variants_variantId_Update"]: {
	__typename: "RedemptionCodesDocAccessFields_variants_variantId_Update",
	permission: boolean
};
	["RedemptionCodesDocAccessFields_variants_variantId_Delete"]: {
	__typename: "RedemptionCodesDocAccessFields_variants_variantId_Delete",
	permission: boolean
};
	["RedemptionCodesDocAccessFields_variants_name"]: {
	__typename: "RedemptionCodesDocAccessFields_variants_name",
	create?: GraphQLTypes["RedemptionCodesDocAccessFields_variants_name_Create"] | undefined,
	read?: GraphQLTypes["RedemptionCodesDocAccessFields_variants_name_Read"] | undefined,
	update?: GraphQLTypes["RedemptionCodesDocAccessFields_variants_name_Update"] | undefined,
	delete?: GraphQLTypes["RedemptionCodesDocAccessFields_variants_name_Delete"] | undefined
};
	["RedemptionCodesDocAccessFields_variants_name_Create"]: {
	__typename: "RedemptionCodesDocAccessFields_variants_name_Create",
	permission: boolean
};
	["RedemptionCodesDocAccessFields_variants_name_Read"]: {
	__typename: "RedemptionCodesDocAccessFields_variants_name_Read",
	permission: boolean
};
	["RedemptionCodesDocAccessFields_variants_name_Update"]: {
	__typename: "RedemptionCodesDocAccessFields_variants_name_Update",
	permission: boolean
};
	["RedemptionCodesDocAccessFields_variants_name_Delete"]: {
	__typename: "RedemptionCodesDocAccessFields_variants_name_Delete",
	permission: boolean
};
	["RedemptionCodesDocAccessFields_variants_quantity"]: {
	__typename: "RedemptionCodesDocAccessFields_variants_quantity",
	create?: GraphQLTypes["RedemptionCodesDocAccessFields_variants_quantity_Create"] | undefined,
	read?: GraphQLTypes["RedemptionCodesDocAccessFields_variants_quantity_Read"] | undefined,
	update?: GraphQLTypes["RedemptionCodesDocAccessFields_variants_quantity_Update"] | undefined,
	delete?: GraphQLTypes["RedemptionCodesDocAccessFields_variants_quantity_Delete"] | undefined
};
	["RedemptionCodesDocAccessFields_variants_quantity_Create"]: {
	__typename: "RedemptionCodesDocAccessFields_variants_quantity_Create",
	permission: boolean
};
	["RedemptionCodesDocAccessFields_variants_quantity_Read"]: {
	__typename: "RedemptionCodesDocAccessFields_variants_quantity_Read",
	permission: boolean
};
	["RedemptionCodesDocAccessFields_variants_quantity_Update"]: {
	__typename: "RedemptionCodesDocAccessFields_variants_quantity_Update",
	permission: boolean
};
	["RedemptionCodesDocAccessFields_variants_quantity_Delete"]: {
	__typename: "RedemptionCodesDocAccessFields_variants_quantity_Delete",
	permission: boolean
};
	["RedemptionCodesDocAccessFields_variants_totalCost"]: {
	__typename: "RedemptionCodesDocAccessFields_variants_totalCost",
	create?: GraphQLTypes["RedemptionCodesDocAccessFields_variants_totalCost_Create"] | undefined,
	read?: GraphQLTypes["RedemptionCodesDocAccessFields_variants_totalCost_Read"] | undefined,
	update?: GraphQLTypes["RedemptionCodesDocAccessFields_variants_totalCost_Update"] | undefined,
	delete?: GraphQLTypes["RedemptionCodesDocAccessFields_variants_totalCost_Delete"] | undefined
};
	["RedemptionCodesDocAccessFields_variants_totalCost_Create"]: {
	__typename: "RedemptionCodesDocAccessFields_variants_totalCost_Create",
	permission: boolean
};
	["RedemptionCodesDocAccessFields_variants_totalCost_Read"]: {
	__typename: "RedemptionCodesDocAccessFields_variants_totalCost_Read",
	permission: boolean
};
	["RedemptionCodesDocAccessFields_variants_totalCost_Update"]: {
	__typename: "RedemptionCodesDocAccessFields_variants_totalCost_Update",
	permission: boolean
};
	["RedemptionCodesDocAccessFields_variants_totalCost_Delete"]: {
	__typename: "RedemptionCodesDocAccessFields_variants_totalCost_Delete",
	permission: boolean
};
	["RedemptionCodesDocAccessFields_variants_id"]: {
	__typename: "RedemptionCodesDocAccessFields_variants_id",
	create?: GraphQLTypes["RedemptionCodesDocAccessFields_variants_id_Create"] | undefined,
	read?: GraphQLTypes["RedemptionCodesDocAccessFields_variants_id_Read"] | undefined,
	update?: GraphQLTypes["RedemptionCodesDocAccessFields_variants_id_Update"] | undefined,
	delete?: GraphQLTypes["RedemptionCodesDocAccessFields_variants_id_Delete"] | undefined
};
	["RedemptionCodesDocAccessFields_variants_id_Create"]: {
	__typename: "RedemptionCodesDocAccessFields_variants_id_Create",
	permission: boolean
};
	["RedemptionCodesDocAccessFields_variants_id_Read"]: {
	__typename: "RedemptionCodesDocAccessFields_variants_id_Read",
	permission: boolean
};
	["RedemptionCodesDocAccessFields_variants_id_Update"]: {
	__typename: "RedemptionCodesDocAccessFields_variants_id_Update",
	permission: boolean
};
	["RedemptionCodesDocAccessFields_variants_id_Delete"]: {
	__typename: "RedemptionCodesDocAccessFields_variants_id_Delete",
	permission: boolean
};
	["RedemptionCodesDocAccessFields_productId"]: {
	__typename: "RedemptionCodesDocAccessFields_productId",
	create?: GraphQLTypes["RedemptionCodesDocAccessFields_productId_Create"] | undefined,
	read?: GraphQLTypes["RedemptionCodesDocAccessFields_productId_Read"] | undefined,
	update?: GraphQLTypes["RedemptionCodesDocAccessFields_productId_Update"] | undefined,
	delete?: GraphQLTypes["RedemptionCodesDocAccessFields_productId_Delete"] | undefined
};
	["RedemptionCodesDocAccessFields_productId_Create"]: {
	__typename: "RedemptionCodesDocAccessFields_productId_Create",
	permission: boolean
};
	["RedemptionCodesDocAccessFields_productId_Read"]: {
	__typename: "RedemptionCodesDocAccessFields_productId_Read",
	permission: boolean
};
	["RedemptionCodesDocAccessFields_productId_Update"]: {
	__typename: "RedemptionCodesDocAccessFields_productId_Update",
	permission: boolean
};
	["RedemptionCodesDocAccessFields_productId_Delete"]: {
	__typename: "RedemptionCodesDocAccessFields_productId_Delete",
	permission: boolean
};
	["RedemptionCodesDocAccessFields_referenceId"]: {
	__typename: "RedemptionCodesDocAccessFields_referenceId",
	create?: GraphQLTypes["RedemptionCodesDocAccessFields_referenceId_Create"] | undefined,
	read?: GraphQLTypes["RedemptionCodesDocAccessFields_referenceId_Read"] | undefined,
	update?: GraphQLTypes["RedemptionCodesDocAccessFields_referenceId_Update"] | undefined,
	delete?: GraphQLTypes["RedemptionCodesDocAccessFields_referenceId_Delete"] | undefined
};
	["RedemptionCodesDocAccessFields_referenceId_Create"]: {
	__typename: "RedemptionCodesDocAccessFields_referenceId_Create",
	permission: boolean
};
	["RedemptionCodesDocAccessFields_referenceId_Read"]: {
	__typename: "RedemptionCodesDocAccessFields_referenceId_Read",
	permission: boolean
};
	["RedemptionCodesDocAccessFields_referenceId_Update"]: {
	__typename: "RedemptionCodesDocAccessFields_referenceId_Update",
	permission: boolean
};
	["RedemptionCodesDocAccessFields_referenceId_Delete"]: {
	__typename: "RedemptionCodesDocAccessFields_referenceId_Delete",
	permission: boolean
};
	["RedemptionCodesDocAccessFields_productType"]: {
	__typename: "RedemptionCodesDocAccessFields_productType",
	create?: GraphQLTypes["RedemptionCodesDocAccessFields_productType_Create"] | undefined,
	read?: GraphQLTypes["RedemptionCodesDocAccessFields_productType_Read"] | undefined,
	update?: GraphQLTypes["RedemptionCodesDocAccessFields_productType_Update"] | undefined,
	delete?: GraphQLTypes["RedemptionCodesDocAccessFields_productType_Delete"] | undefined
};
	["RedemptionCodesDocAccessFields_productType_Create"]: {
	__typename: "RedemptionCodesDocAccessFields_productType_Create",
	permission: boolean
};
	["RedemptionCodesDocAccessFields_productType_Read"]: {
	__typename: "RedemptionCodesDocAccessFields_productType_Read",
	permission: boolean
};
	["RedemptionCodesDocAccessFields_productType_Update"]: {
	__typename: "RedemptionCodesDocAccessFields_productType_Update",
	permission: boolean
};
	["RedemptionCodesDocAccessFields_productType_Delete"]: {
	__typename: "RedemptionCodesDocAccessFields_productType_Delete",
	permission: boolean
};
	["RedemptionCodesDocAccessFields_productName"]: {
	__typename: "RedemptionCodesDocAccessFields_productName",
	create?: GraphQLTypes["RedemptionCodesDocAccessFields_productName_Create"] | undefined,
	read?: GraphQLTypes["RedemptionCodesDocAccessFields_productName_Read"] | undefined,
	update?: GraphQLTypes["RedemptionCodesDocAccessFields_productName_Update"] | undefined,
	delete?: GraphQLTypes["RedemptionCodesDocAccessFields_productName_Delete"] | undefined
};
	["RedemptionCodesDocAccessFields_productName_Create"]: {
	__typename: "RedemptionCodesDocAccessFields_productName_Create",
	permission: boolean
};
	["RedemptionCodesDocAccessFields_productName_Read"]: {
	__typename: "RedemptionCodesDocAccessFields_productName_Read",
	permission: boolean
};
	["RedemptionCodesDocAccessFields_productName_Update"]: {
	__typename: "RedemptionCodesDocAccessFields_productName_Update",
	permission: boolean
};
	["RedemptionCodesDocAccessFields_productName_Delete"]: {
	__typename: "RedemptionCodesDocAccessFields_productName_Delete",
	permission: boolean
};
	["RedemptionCodesDocAccessFields_linkedEvent"]: {
	__typename: "RedemptionCodesDocAccessFields_linkedEvent",
	create?: GraphQLTypes["RedemptionCodesDocAccessFields_linkedEvent_Create"] | undefined,
	read?: GraphQLTypes["RedemptionCodesDocAccessFields_linkedEvent_Read"] | undefined,
	update?: GraphQLTypes["RedemptionCodesDocAccessFields_linkedEvent_Update"] | undefined,
	delete?: GraphQLTypes["RedemptionCodesDocAccessFields_linkedEvent_Delete"] | undefined
};
	["RedemptionCodesDocAccessFields_linkedEvent_Create"]: {
	__typename: "RedemptionCodesDocAccessFields_linkedEvent_Create",
	permission: boolean
};
	["RedemptionCodesDocAccessFields_linkedEvent_Read"]: {
	__typename: "RedemptionCodesDocAccessFields_linkedEvent_Read",
	permission: boolean
};
	["RedemptionCodesDocAccessFields_linkedEvent_Update"]: {
	__typename: "RedemptionCodesDocAccessFields_linkedEvent_Update",
	permission: boolean
};
	["RedemptionCodesDocAccessFields_linkedEvent_Delete"]: {
	__typename: "RedemptionCodesDocAccessFields_linkedEvent_Delete",
	permission: boolean
};
	["RedemptionCodesDocAccessFields_timableOrderId"]: {
	__typename: "RedemptionCodesDocAccessFields_timableOrderId",
	create?: GraphQLTypes["RedemptionCodesDocAccessFields_timableOrderId_Create"] | undefined,
	read?: GraphQLTypes["RedemptionCodesDocAccessFields_timableOrderId_Read"] | undefined,
	update?: GraphQLTypes["RedemptionCodesDocAccessFields_timableOrderId_Update"] | undefined,
	delete?: GraphQLTypes["RedemptionCodesDocAccessFields_timableOrderId_Delete"] | undefined
};
	["RedemptionCodesDocAccessFields_timableOrderId_Create"]: {
	__typename: "RedemptionCodesDocAccessFields_timableOrderId_Create",
	permission: boolean
};
	["RedemptionCodesDocAccessFields_timableOrderId_Read"]: {
	__typename: "RedemptionCodesDocAccessFields_timableOrderId_Read",
	permission: boolean
};
	["RedemptionCodesDocAccessFields_timableOrderId_Update"]: {
	__typename: "RedemptionCodesDocAccessFields_timableOrderId_Update",
	permission: boolean
};
	["RedemptionCodesDocAccessFields_timableOrderId_Delete"]: {
	__typename: "RedemptionCodesDocAccessFields_timableOrderId_Delete",
	permission: boolean
};
	["RedemptionCodesDocAccessFields_purchaseDateTime"]: {
	__typename: "RedemptionCodesDocAccessFields_purchaseDateTime",
	create?: GraphQLTypes["RedemptionCodesDocAccessFields_purchaseDateTime_Create"] | undefined,
	read?: GraphQLTypes["RedemptionCodesDocAccessFields_purchaseDateTime_Read"] | undefined,
	update?: GraphQLTypes["RedemptionCodesDocAccessFields_purchaseDateTime_Update"] | undefined,
	delete?: GraphQLTypes["RedemptionCodesDocAccessFields_purchaseDateTime_Delete"] | undefined
};
	["RedemptionCodesDocAccessFields_purchaseDateTime_Create"]: {
	__typename: "RedemptionCodesDocAccessFields_purchaseDateTime_Create",
	permission: boolean
};
	["RedemptionCodesDocAccessFields_purchaseDateTime_Read"]: {
	__typename: "RedemptionCodesDocAccessFields_purchaseDateTime_Read",
	permission: boolean
};
	["RedemptionCodesDocAccessFields_purchaseDateTime_Update"]: {
	__typename: "RedemptionCodesDocAccessFields_purchaseDateTime_Update",
	permission: boolean
};
	["RedemptionCodesDocAccessFields_purchaseDateTime_Delete"]: {
	__typename: "RedemptionCodesDocAccessFields_purchaseDateTime_Delete",
	permission: boolean
};
	["RedemptionCodesDocAccessFields_changeHistory"]: {
	__typename: "RedemptionCodesDocAccessFields_changeHistory",
	create?: GraphQLTypes["RedemptionCodesDocAccessFields_changeHistory_Create"] | undefined,
	read?: GraphQLTypes["RedemptionCodesDocAccessFields_changeHistory_Read"] | undefined,
	update?: GraphQLTypes["RedemptionCodesDocAccessFields_changeHistory_Update"] | undefined,
	delete?: GraphQLTypes["RedemptionCodesDocAccessFields_changeHistory_Delete"] | undefined,
	fields?: GraphQLTypes["RedemptionCodesDocAccessFields_changeHistory_Fields"] | undefined
};
	["RedemptionCodesDocAccessFields_changeHistory_Create"]: {
	__typename: "RedemptionCodesDocAccessFields_changeHistory_Create",
	permission: boolean
};
	["RedemptionCodesDocAccessFields_changeHistory_Read"]: {
	__typename: "RedemptionCodesDocAccessFields_changeHistory_Read",
	permission: boolean
};
	["RedemptionCodesDocAccessFields_changeHistory_Update"]: {
	__typename: "RedemptionCodesDocAccessFields_changeHistory_Update",
	permission: boolean
};
	["RedemptionCodesDocAccessFields_changeHistory_Delete"]: {
	__typename: "RedemptionCodesDocAccessFields_changeHistory_Delete",
	permission: boolean
};
	["RedemptionCodesDocAccessFields_changeHistory_Fields"]: {
	__typename: "RedemptionCodesDocAccessFields_changeHistory_Fields",
	timestamp?: GraphQLTypes["RedemptionCodesDocAccessFields_changeHistory_timestamp"] | undefined,
	field?: GraphQLTypes["RedemptionCodesDocAccessFields_changeHistory_field"] | undefined,
	oldValue?: GraphQLTypes["RedemptionCodesDocAccessFields_changeHistory_oldValue"] | undefined,
	newValue?: GraphQLTypes["RedemptionCodesDocAccessFields_changeHistory_newValue"] | undefined,
	id?: GraphQLTypes["RedemptionCodesDocAccessFields_changeHistory_id"] | undefined
};
	["RedemptionCodesDocAccessFields_changeHistory_timestamp"]: {
	__typename: "RedemptionCodesDocAccessFields_changeHistory_timestamp",
	create?: GraphQLTypes["RedemptionCodesDocAccessFields_changeHistory_timestamp_Create"] | undefined,
	read?: GraphQLTypes["RedemptionCodesDocAccessFields_changeHistory_timestamp_Read"] | undefined,
	update?: GraphQLTypes["RedemptionCodesDocAccessFields_changeHistory_timestamp_Update"] | undefined,
	delete?: GraphQLTypes["RedemptionCodesDocAccessFields_changeHistory_timestamp_Delete"] | undefined
};
	["RedemptionCodesDocAccessFields_changeHistory_timestamp_Create"]: {
	__typename: "RedemptionCodesDocAccessFields_changeHistory_timestamp_Create",
	permission: boolean
};
	["RedemptionCodesDocAccessFields_changeHistory_timestamp_Read"]: {
	__typename: "RedemptionCodesDocAccessFields_changeHistory_timestamp_Read",
	permission: boolean
};
	["RedemptionCodesDocAccessFields_changeHistory_timestamp_Update"]: {
	__typename: "RedemptionCodesDocAccessFields_changeHistory_timestamp_Update",
	permission: boolean
};
	["RedemptionCodesDocAccessFields_changeHistory_timestamp_Delete"]: {
	__typename: "RedemptionCodesDocAccessFields_changeHistory_timestamp_Delete",
	permission: boolean
};
	["RedemptionCodesDocAccessFields_changeHistory_field"]: {
	__typename: "RedemptionCodesDocAccessFields_changeHistory_field",
	create?: GraphQLTypes["RedemptionCodesDocAccessFields_changeHistory_field_Create"] | undefined,
	read?: GraphQLTypes["RedemptionCodesDocAccessFields_changeHistory_field_Read"] | undefined,
	update?: GraphQLTypes["RedemptionCodesDocAccessFields_changeHistory_field_Update"] | undefined,
	delete?: GraphQLTypes["RedemptionCodesDocAccessFields_changeHistory_field_Delete"] | undefined
};
	["RedemptionCodesDocAccessFields_changeHistory_field_Create"]: {
	__typename: "RedemptionCodesDocAccessFields_changeHistory_field_Create",
	permission: boolean
};
	["RedemptionCodesDocAccessFields_changeHistory_field_Read"]: {
	__typename: "RedemptionCodesDocAccessFields_changeHistory_field_Read",
	permission: boolean
};
	["RedemptionCodesDocAccessFields_changeHistory_field_Update"]: {
	__typename: "RedemptionCodesDocAccessFields_changeHistory_field_Update",
	permission: boolean
};
	["RedemptionCodesDocAccessFields_changeHistory_field_Delete"]: {
	__typename: "RedemptionCodesDocAccessFields_changeHistory_field_Delete",
	permission: boolean
};
	["RedemptionCodesDocAccessFields_changeHistory_oldValue"]: {
	__typename: "RedemptionCodesDocAccessFields_changeHistory_oldValue",
	create?: GraphQLTypes["RedemptionCodesDocAccessFields_changeHistory_oldValue_Create"] | undefined,
	read?: GraphQLTypes["RedemptionCodesDocAccessFields_changeHistory_oldValue_Read"] | undefined,
	update?: GraphQLTypes["RedemptionCodesDocAccessFields_changeHistory_oldValue_Update"] | undefined,
	delete?: GraphQLTypes["RedemptionCodesDocAccessFields_changeHistory_oldValue_Delete"] | undefined
};
	["RedemptionCodesDocAccessFields_changeHistory_oldValue_Create"]: {
	__typename: "RedemptionCodesDocAccessFields_changeHistory_oldValue_Create",
	permission: boolean
};
	["RedemptionCodesDocAccessFields_changeHistory_oldValue_Read"]: {
	__typename: "RedemptionCodesDocAccessFields_changeHistory_oldValue_Read",
	permission: boolean
};
	["RedemptionCodesDocAccessFields_changeHistory_oldValue_Update"]: {
	__typename: "RedemptionCodesDocAccessFields_changeHistory_oldValue_Update",
	permission: boolean
};
	["RedemptionCodesDocAccessFields_changeHistory_oldValue_Delete"]: {
	__typename: "RedemptionCodesDocAccessFields_changeHistory_oldValue_Delete",
	permission: boolean
};
	["RedemptionCodesDocAccessFields_changeHistory_newValue"]: {
	__typename: "RedemptionCodesDocAccessFields_changeHistory_newValue",
	create?: GraphQLTypes["RedemptionCodesDocAccessFields_changeHistory_newValue_Create"] | undefined,
	read?: GraphQLTypes["RedemptionCodesDocAccessFields_changeHistory_newValue_Read"] | undefined,
	update?: GraphQLTypes["RedemptionCodesDocAccessFields_changeHistory_newValue_Update"] | undefined,
	delete?: GraphQLTypes["RedemptionCodesDocAccessFields_changeHistory_newValue_Delete"] | undefined
};
	["RedemptionCodesDocAccessFields_changeHistory_newValue_Create"]: {
	__typename: "RedemptionCodesDocAccessFields_changeHistory_newValue_Create",
	permission: boolean
};
	["RedemptionCodesDocAccessFields_changeHistory_newValue_Read"]: {
	__typename: "RedemptionCodesDocAccessFields_changeHistory_newValue_Read",
	permission: boolean
};
	["RedemptionCodesDocAccessFields_changeHistory_newValue_Update"]: {
	__typename: "RedemptionCodesDocAccessFields_changeHistory_newValue_Update",
	permission: boolean
};
	["RedemptionCodesDocAccessFields_changeHistory_newValue_Delete"]: {
	__typename: "RedemptionCodesDocAccessFields_changeHistory_newValue_Delete",
	permission: boolean
};
	["RedemptionCodesDocAccessFields_changeHistory_id"]: {
	__typename: "RedemptionCodesDocAccessFields_changeHistory_id",
	create?: GraphQLTypes["RedemptionCodesDocAccessFields_changeHistory_id_Create"] | undefined,
	read?: GraphQLTypes["RedemptionCodesDocAccessFields_changeHistory_id_Read"] | undefined,
	update?: GraphQLTypes["RedemptionCodesDocAccessFields_changeHistory_id_Update"] | undefined,
	delete?: GraphQLTypes["RedemptionCodesDocAccessFields_changeHistory_id_Delete"] | undefined
};
	["RedemptionCodesDocAccessFields_changeHistory_id_Create"]: {
	__typename: "RedemptionCodesDocAccessFields_changeHistory_id_Create",
	permission: boolean
};
	["RedemptionCodesDocAccessFields_changeHistory_id_Read"]: {
	__typename: "RedemptionCodesDocAccessFields_changeHistory_id_Read",
	permission: boolean
};
	["RedemptionCodesDocAccessFields_changeHistory_id_Update"]: {
	__typename: "RedemptionCodesDocAccessFields_changeHistory_id_Update",
	permission: boolean
};
	["RedemptionCodesDocAccessFields_changeHistory_id_Delete"]: {
	__typename: "RedemptionCodesDocAccessFields_changeHistory_id_Delete",
	permission: boolean
};
	["RedemptionCodesDocAccessFields_updatedAt"]: {
	__typename: "RedemptionCodesDocAccessFields_updatedAt",
	create?: GraphQLTypes["RedemptionCodesDocAccessFields_updatedAt_Create"] | undefined,
	read?: GraphQLTypes["RedemptionCodesDocAccessFields_updatedAt_Read"] | undefined,
	update?: GraphQLTypes["RedemptionCodesDocAccessFields_updatedAt_Update"] | undefined,
	delete?: GraphQLTypes["RedemptionCodesDocAccessFields_updatedAt_Delete"] | undefined
};
	["RedemptionCodesDocAccessFields_updatedAt_Create"]: {
	__typename: "RedemptionCodesDocAccessFields_updatedAt_Create",
	permission: boolean
};
	["RedemptionCodesDocAccessFields_updatedAt_Read"]: {
	__typename: "RedemptionCodesDocAccessFields_updatedAt_Read",
	permission: boolean
};
	["RedemptionCodesDocAccessFields_updatedAt_Update"]: {
	__typename: "RedemptionCodesDocAccessFields_updatedAt_Update",
	permission: boolean
};
	["RedemptionCodesDocAccessFields_updatedAt_Delete"]: {
	__typename: "RedemptionCodesDocAccessFields_updatedAt_Delete",
	permission: boolean
};
	["RedemptionCodesDocAccessFields_createdAt"]: {
	__typename: "RedemptionCodesDocAccessFields_createdAt",
	create?: GraphQLTypes["RedemptionCodesDocAccessFields_createdAt_Create"] | undefined,
	read?: GraphQLTypes["RedemptionCodesDocAccessFields_createdAt_Read"] | undefined,
	update?: GraphQLTypes["RedemptionCodesDocAccessFields_createdAt_Update"] | undefined,
	delete?: GraphQLTypes["RedemptionCodesDocAccessFields_createdAt_Delete"] | undefined
};
	["RedemptionCodesDocAccessFields_createdAt_Create"]: {
	__typename: "RedemptionCodesDocAccessFields_createdAt_Create",
	permission: boolean
};
	["RedemptionCodesDocAccessFields_createdAt_Read"]: {
	__typename: "RedemptionCodesDocAccessFields_createdAt_Read",
	permission: boolean
};
	["RedemptionCodesDocAccessFields_createdAt_Update"]: {
	__typename: "RedemptionCodesDocAccessFields_createdAt_Update",
	permission: boolean
};
	["RedemptionCodesDocAccessFields_createdAt_Delete"]: {
	__typename: "RedemptionCodesDocAccessFields_createdAt_Delete",
	permission: boolean
};
	["RedemptionCodesCreateDocAccess"]: {
	__typename: "RedemptionCodesCreateDocAccess",
	permission: boolean,
	where?: GraphQLTypes["JSONObject"] | undefined
};
	["RedemptionCodesReadDocAccess"]: {
	__typename: "RedemptionCodesReadDocAccess",
	permission: boolean,
	where?: GraphQLTypes["JSONObject"] | undefined
};
	["RedemptionCodesUpdateDocAccess"]: {
	__typename: "RedemptionCodesUpdateDocAccess",
	permission: boolean,
	where?: GraphQLTypes["JSONObject"] | undefined
};
	["RedemptionCodesDeleteDocAccess"]: {
	__typename: "RedemptionCodesDeleteDocAccess",
	permission: boolean,
	where?: GraphQLTypes["JSONObject"] | undefined
};
	["RedemptionPdfs"]: {
	__typename: "RedemptionPdfs",
	docs?: Array<GraphQLTypes["RedemptionPdf"] | undefined> | undefined,
	hasNextPage?: boolean | undefined,
	hasPrevPage?: boolean | undefined,
	limit?: number | undefined,
	nextPage?: number | undefined,
	offset?: number | undefined,
	page?: number | undefined,
	pagingCounter?: number | undefined,
	prevPage?: number | undefined,
	totalDocs?: number | undefined,
	totalPages?: number | undefined
};
	["RedemptionPdf_where"]: {
		updatedAt?: GraphQLTypes["RedemptionPdf_updatedAt_operator"] | undefined,
	createdAt?: GraphQLTypes["RedemptionPdf_createdAt_operator"] | undefined,
	url?: GraphQLTypes["RedemptionPdf_url_operator"] | undefined,
	filename?: GraphQLTypes["RedemptionPdf_filename_operator"] | undefined,
	mimeType?: GraphQLTypes["RedemptionPdf_mimeType_operator"] | undefined,
	filesize?: GraphQLTypes["RedemptionPdf_filesize_operator"] | undefined,
	width?: GraphQLTypes["RedemptionPdf_width_operator"] | undefined,
	height?: GraphQLTypes["RedemptionPdf_height_operator"] | undefined,
	focalX?: GraphQLTypes["RedemptionPdf_focalX_operator"] | undefined,
	focalY?: GraphQLTypes["RedemptionPdf_focalY_operator"] | undefined,
	id?: GraphQLTypes["RedemptionPdf_id_operator"] | undefined,
	AND?: Array<GraphQLTypes["RedemptionPdf_where_and"] | undefined> | undefined,
	OR?: Array<GraphQLTypes["RedemptionPdf_where_or"] | undefined> | undefined
};
	["RedemptionPdf_updatedAt_operator"]: {
		equals?: GraphQLTypes["DateTime"] | undefined,
	not_equals?: GraphQLTypes["DateTime"] | undefined,
	greater_than_equal?: GraphQLTypes["DateTime"] | undefined,
	greater_than?: GraphQLTypes["DateTime"] | undefined,
	less_than_equal?: GraphQLTypes["DateTime"] | undefined,
	less_than?: GraphQLTypes["DateTime"] | undefined,
	like?: GraphQLTypes["DateTime"] | undefined,
	exists?: boolean | undefined
};
	["RedemptionPdf_createdAt_operator"]: {
		equals?: GraphQLTypes["DateTime"] | undefined,
	not_equals?: GraphQLTypes["DateTime"] | undefined,
	greater_than_equal?: GraphQLTypes["DateTime"] | undefined,
	greater_than?: GraphQLTypes["DateTime"] | undefined,
	less_than_equal?: GraphQLTypes["DateTime"] | undefined,
	less_than?: GraphQLTypes["DateTime"] | undefined,
	like?: GraphQLTypes["DateTime"] | undefined,
	exists?: boolean | undefined
};
	["RedemptionPdf_url_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["RedemptionPdf_filename_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["RedemptionPdf_mimeType_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["RedemptionPdf_filesize_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["RedemptionPdf_width_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["RedemptionPdf_height_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["RedemptionPdf_focalX_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["RedemptionPdf_focalY_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["RedemptionPdf_id_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["RedemptionPdf_where_and"]: {
		updatedAt?: GraphQLTypes["RedemptionPdf_updatedAt_operator"] | undefined,
	createdAt?: GraphQLTypes["RedemptionPdf_createdAt_operator"] | undefined,
	url?: GraphQLTypes["RedemptionPdf_url_operator"] | undefined,
	filename?: GraphQLTypes["RedemptionPdf_filename_operator"] | undefined,
	mimeType?: GraphQLTypes["RedemptionPdf_mimeType_operator"] | undefined,
	filesize?: GraphQLTypes["RedemptionPdf_filesize_operator"] | undefined,
	width?: GraphQLTypes["RedemptionPdf_width_operator"] | undefined,
	height?: GraphQLTypes["RedemptionPdf_height_operator"] | undefined,
	focalX?: GraphQLTypes["RedemptionPdf_focalX_operator"] | undefined,
	focalY?: GraphQLTypes["RedemptionPdf_focalY_operator"] | undefined,
	id?: GraphQLTypes["RedemptionPdf_id_operator"] | undefined,
	AND?: Array<GraphQLTypes["RedemptionPdf_where_and"] | undefined> | undefined,
	OR?: Array<GraphQLTypes["RedemptionPdf_where_or"] | undefined> | undefined
};
	["RedemptionPdf_where_or"]: {
		updatedAt?: GraphQLTypes["RedemptionPdf_updatedAt_operator"] | undefined,
	createdAt?: GraphQLTypes["RedemptionPdf_createdAt_operator"] | undefined,
	url?: GraphQLTypes["RedemptionPdf_url_operator"] | undefined,
	filename?: GraphQLTypes["RedemptionPdf_filename_operator"] | undefined,
	mimeType?: GraphQLTypes["RedemptionPdf_mimeType_operator"] | undefined,
	filesize?: GraphQLTypes["RedemptionPdf_filesize_operator"] | undefined,
	width?: GraphQLTypes["RedemptionPdf_width_operator"] | undefined,
	height?: GraphQLTypes["RedemptionPdf_height_operator"] | undefined,
	focalX?: GraphQLTypes["RedemptionPdf_focalX_operator"] | undefined,
	focalY?: GraphQLTypes["RedemptionPdf_focalY_operator"] | undefined,
	id?: GraphQLTypes["RedemptionPdf_id_operator"] | undefined,
	AND?: Array<GraphQLTypes["RedemptionPdf_where_and"] | undefined> | undefined,
	OR?: Array<GraphQLTypes["RedemptionPdf_where_or"] | undefined> | undefined
};
	["countRedemptionPdfs"]: {
	__typename: "countRedemptionPdfs",
	totalDocs?: number | undefined
};
	["redemption_pdfDocAccess"]: {
	__typename: "redemption_pdfDocAccess",
	fields?: GraphQLTypes["RedemptionPdfDocAccessFields"] | undefined,
	create?: GraphQLTypes["RedemptionPdfCreateDocAccess"] | undefined,
	read?: GraphQLTypes["RedemptionPdfReadDocAccess"] | undefined,
	update?: GraphQLTypes["RedemptionPdfUpdateDocAccess"] | undefined,
	delete?: GraphQLTypes["RedemptionPdfDeleteDocAccess"] | undefined
};
	["RedemptionPdfDocAccessFields"]: {
	__typename: "RedemptionPdfDocAccessFields",
	updatedAt?: GraphQLTypes["RedemptionPdfDocAccessFields_updatedAt"] | undefined,
	createdAt?: GraphQLTypes["RedemptionPdfDocAccessFields_createdAt"] | undefined,
	url?: GraphQLTypes["RedemptionPdfDocAccessFields_url"] | undefined,
	filename?: GraphQLTypes["RedemptionPdfDocAccessFields_filename"] | undefined,
	mimeType?: GraphQLTypes["RedemptionPdfDocAccessFields_mimeType"] | undefined,
	filesize?: GraphQLTypes["RedemptionPdfDocAccessFields_filesize"] | undefined,
	width?: GraphQLTypes["RedemptionPdfDocAccessFields_width"] | undefined,
	height?: GraphQLTypes["RedemptionPdfDocAccessFields_height"] | undefined,
	focalX?: GraphQLTypes["RedemptionPdfDocAccessFields_focalX"] | undefined,
	focalY?: GraphQLTypes["RedemptionPdfDocAccessFields_focalY"] | undefined
};
	["RedemptionPdfDocAccessFields_updatedAt"]: {
	__typename: "RedemptionPdfDocAccessFields_updatedAt",
	create?: GraphQLTypes["RedemptionPdfDocAccessFields_updatedAt_Create"] | undefined,
	read?: GraphQLTypes["RedemptionPdfDocAccessFields_updatedAt_Read"] | undefined,
	update?: GraphQLTypes["RedemptionPdfDocAccessFields_updatedAt_Update"] | undefined,
	delete?: GraphQLTypes["RedemptionPdfDocAccessFields_updatedAt_Delete"] | undefined
};
	["RedemptionPdfDocAccessFields_updatedAt_Create"]: {
	__typename: "RedemptionPdfDocAccessFields_updatedAt_Create",
	permission: boolean
};
	["RedemptionPdfDocAccessFields_updatedAt_Read"]: {
	__typename: "RedemptionPdfDocAccessFields_updatedAt_Read",
	permission: boolean
};
	["RedemptionPdfDocAccessFields_updatedAt_Update"]: {
	__typename: "RedemptionPdfDocAccessFields_updatedAt_Update",
	permission: boolean
};
	["RedemptionPdfDocAccessFields_updatedAt_Delete"]: {
	__typename: "RedemptionPdfDocAccessFields_updatedAt_Delete",
	permission: boolean
};
	["RedemptionPdfDocAccessFields_createdAt"]: {
	__typename: "RedemptionPdfDocAccessFields_createdAt",
	create?: GraphQLTypes["RedemptionPdfDocAccessFields_createdAt_Create"] | undefined,
	read?: GraphQLTypes["RedemptionPdfDocAccessFields_createdAt_Read"] | undefined,
	update?: GraphQLTypes["RedemptionPdfDocAccessFields_createdAt_Update"] | undefined,
	delete?: GraphQLTypes["RedemptionPdfDocAccessFields_createdAt_Delete"] | undefined
};
	["RedemptionPdfDocAccessFields_createdAt_Create"]: {
	__typename: "RedemptionPdfDocAccessFields_createdAt_Create",
	permission: boolean
};
	["RedemptionPdfDocAccessFields_createdAt_Read"]: {
	__typename: "RedemptionPdfDocAccessFields_createdAt_Read",
	permission: boolean
};
	["RedemptionPdfDocAccessFields_createdAt_Update"]: {
	__typename: "RedemptionPdfDocAccessFields_createdAt_Update",
	permission: boolean
};
	["RedemptionPdfDocAccessFields_createdAt_Delete"]: {
	__typename: "RedemptionPdfDocAccessFields_createdAt_Delete",
	permission: boolean
};
	["RedemptionPdfDocAccessFields_url"]: {
	__typename: "RedemptionPdfDocAccessFields_url",
	create?: GraphQLTypes["RedemptionPdfDocAccessFields_url_Create"] | undefined,
	read?: GraphQLTypes["RedemptionPdfDocAccessFields_url_Read"] | undefined,
	update?: GraphQLTypes["RedemptionPdfDocAccessFields_url_Update"] | undefined,
	delete?: GraphQLTypes["RedemptionPdfDocAccessFields_url_Delete"] | undefined
};
	["RedemptionPdfDocAccessFields_url_Create"]: {
	__typename: "RedemptionPdfDocAccessFields_url_Create",
	permission: boolean
};
	["RedemptionPdfDocAccessFields_url_Read"]: {
	__typename: "RedemptionPdfDocAccessFields_url_Read",
	permission: boolean
};
	["RedemptionPdfDocAccessFields_url_Update"]: {
	__typename: "RedemptionPdfDocAccessFields_url_Update",
	permission: boolean
};
	["RedemptionPdfDocAccessFields_url_Delete"]: {
	__typename: "RedemptionPdfDocAccessFields_url_Delete",
	permission: boolean
};
	["RedemptionPdfDocAccessFields_filename"]: {
	__typename: "RedemptionPdfDocAccessFields_filename",
	create?: GraphQLTypes["RedemptionPdfDocAccessFields_filename_Create"] | undefined,
	read?: GraphQLTypes["RedemptionPdfDocAccessFields_filename_Read"] | undefined,
	update?: GraphQLTypes["RedemptionPdfDocAccessFields_filename_Update"] | undefined,
	delete?: GraphQLTypes["RedemptionPdfDocAccessFields_filename_Delete"] | undefined
};
	["RedemptionPdfDocAccessFields_filename_Create"]: {
	__typename: "RedemptionPdfDocAccessFields_filename_Create",
	permission: boolean
};
	["RedemptionPdfDocAccessFields_filename_Read"]: {
	__typename: "RedemptionPdfDocAccessFields_filename_Read",
	permission: boolean
};
	["RedemptionPdfDocAccessFields_filename_Update"]: {
	__typename: "RedemptionPdfDocAccessFields_filename_Update",
	permission: boolean
};
	["RedemptionPdfDocAccessFields_filename_Delete"]: {
	__typename: "RedemptionPdfDocAccessFields_filename_Delete",
	permission: boolean
};
	["RedemptionPdfDocAccessFields_mimeType"]: {
	__typename: "RedemptionPdfDocAccessFields_mimeType",
	create?: GraphQLTypes["RedemptionPdfDocAccessFields_mimeType_Create"] | undefined,
	read?: GraphQLTypes["RedemptionPdfDocAccessFields_mimeType_Read"] | undefined,
	update?: GraphQLTypes["RedemptionPdfDocAccessFields_mimeType_Update"] | undefined,
	delete?: GraphQLTypes["RedemptionPdfDocAccessFields_mimeType_Delete"] | undefined
};
	["RedemptionPdfDocAccessFields_mimeType_Create"]: {
	__typename: "RedemptionPdfDocAccessFields_mimeType_Create",
	permission: boolean
};
	["RedemptionPdfDocAccessFields_mimeType_Read"]: {
	__typename: "RedemptionPdfDocAccessFields_mimeType_Read",
	permission: boolean
};
	["RedemptionPdfDocAccessFields_mimeType_Update"]: {
	__typename: "RedemptionPdfDocAccessFields_mimeType_Update",
	permission: boolean
};
	["RedemptionPdfDocAccessFields_mimeType_Delete"]: {
	__typename: "RedemptionPdfDocAccessFields_mimeType_Delete",
	permission: boolean
};
	["RedemptionPdfDocAccessFields_filesize"]: {
	__typename: "RedemptionPdfDocAccessFields_filesize",
	create?: GraphQLTypes["RedemptionPdfDocAccessFields_filesize_Create"] | undefined,
	read?: GraphQLTypes["RedemptionPdfDocAccessFields_filesize_Read"] | undefined,
	update?: GraphQLTypes["RedemptionPdfDocAccessFields_filesize_Update"] | undefined,
	delete?: GraphQLTypes["RedemptionPdfDocAccessFields_filesize_Delete"] | undefined
};
	["RedemptionPdfDocAccessFields_filesize_Create"]: {
	__typename: "RedemptionPdfDocAccessFields_filesize_Create",
	permission: boolean
};
	["RedemptionPdfDocAccessFields_filesize_Read"]: {
	__typename: "RedemptionPdfDocAccessFields_filesize_Read",
	permission: boolean
};
	["RedemptionPdfDocAccessFields_filesize_Update"]: {
	__typename: "RedemptionPdfDocAccessFields_filesize_Update",
	permission: boolean
};
	["RedemptionPdfDocAccessFields_filesize_Delete"]: {
	__typename: "RedemptionPdfDocAccessFields_filesize_Delete",
	permission: boolean
};
	["RedemptionPdfDocAccessFields_width"]: {
	__typename: "RedemptionPdfDocAccessFields_width",
	create?: GraphQLTypes["RedemptionPdfDocAccessFields_width_Create"] | undefined,
	read?: GraphQLTypes["RedemptionPdfDocAccessFields_width_Read"] | undefined,
	update?: GraphQLTypes["RedemptionPdfDocAccessFields_width_Update"] | undefined,
	delete?: GraphQLTypes["RedemptionPdfDocAccessFields_width_Delete"] | undefined
};
	["RedemptionPdfDocAccessFields_width_Create"]: {
	__typename: "RedemptionPdfDocAccessFields_width_Create",
	permission: boolean
};
	["RedemptionPdfDocAccessFields_width_Read"]: {
	__typename: "RedemptionPdfDocAccessFields_width_Read",
	permission: boolean
};
	["RedemptionPdfDocAccessFields_width_Update"]: {
	__typename: "RedemptionPdfDocAccessFields_width_Update",
	permission: boolean
};
	["RedemptionPdfDocAccessFields_width_Delete"]: {
	__typename: "RedemptionPdfDocAccessFields_width_Delete",
	permission: boolean
};
	["RedemptionPdfDocAccessFields_height"]: {
	__typename: "RedemptionPdfDocAccessFields_height",
	create?: GraphQLTypes["RedemptionPdfDocAccessFields_height_Create"] | undefined,
	read?: GraphQLTypes["RedemptionPdfDocAccessFields_height_Read"] | undefined,
	update?: GraphQLTypes["RedemptionPdfDocAccessFields_height_Update"] | undefined,
	delete?: GraphQLTypes["RedemptionPdfDocAccessFields_height_Delete"] | undefined
};
	["RedemptionPdfDocAccessFields_height_Create"]: {
	__typename: "RedemptionPdfDocAccessFields_height_Create",
	permission: boolean
};
	["RedemptionPdfDocAccessFields_height_Read"]: {
	__typename: "RedemptionPdfDocAccessFields_height_Read",
	permission: boolean
};
	["RedemptionPdfDocAccessFields_height_Update"]: {
	__typename: "RedemptionPdfDocAccessFields_height_Update",
	permission: boolean
};
	["RedemptionPdfDocAccessFields_height_Delete"]: {
	__typename: "RedemptionPdfDocAccessFields_height_Delete",
	permission: boolean
};
	["RedemptionPdfDocAccessFields_focalX"]: {
	__typename: "RedemptionPdfDocAccessFields_focalX",
	create?: GraphQLTypes["RedemptionPdfDocAccessFields_focalX_Create"] | undefined,
	read?: GraphQLTypes["RedemptionPdfDocAccessFields_focalX_Read"] | undefined,
	update?: GraphQLTypes["RedemptionPdfDocAccessFields_focalX_Update"] | undefined,
	delete?: GraphQLTypes["RedemptionPdfDocAccessFields_focalX_Delete"] | undefined
};
	["RedemptionPdfDocAccessFields_focalX_Create"]: {
	__typename: "RedemptionPdfDocAccessFields_focalX_Create",
	permission: boolean
};
	["RedemptionPdfDocAccessFields_focalX_Read"]: {
	__typename: "RedemptionPdfDocAccessFields_focalX_Read",
	permission: boolean
};
	["RedemptionPdfDocAccessFields_focalX_Update"]: {
	__typename: "RedemptionPdfDocAccessFields_focalX_Update",
	permission: boolean
};
	["RedemptionPdfDocAccessFields_focalX_Delete"]: {
	__typename: "RedemptionPdfDocAccessFields_focalX_Delete",
	permission: boolean
};
	["RedemptionPdfDocAccessFields_focalY"]: {
	__typename: "RedemptionPdfDocAccessFields_focalY",
	create?: GraphQLTypes["RedemptionPdfDocAccessFields_focalY_Create"] | undefined,
	read?: GraphQLTypes["RedemptionPdfDocAccessFields_focalY_Read"] | undefined,
	update?: GraphQLTypes["RedemptionPdfDocAccessFields_focalY_Update"] | undefined,
	delete?: GraphQLTypes["RedemptionPdfDocAccessFields_focalY_Delete"] | undefined
};
	["RedemptionPdfDocAccessFields_focalY_Create"]: {
	__typename: "RedemptionPdfDocAccessFields_focalY_Create",
	permission: boolean
};
	["RedemptionPdfDocAccessFields_focalY_Read"]: {
	__typename: "RedemptionPdfDocAccessFields_focalY_Read",
	permission: boolean
};
	["RedemptionPdfDocAccessFields_focalY_Update"]: {
	__typename: "RedemptionPdfDocAccessFields_focalY_Update",
	permission: boolean
};
	["RedemptionPdfDocAccessFields_focalY_Delete"]: {
	__typename: "RedemptionPdfDocAccessFields_focalY_Delete",
	permission: boolean
};
	["RedemptionPdfCreateDocAccess"]: {
	__typename: "RedemptionPdfCreateDocAccess",
	permission: boolean,
	where?: GraphQLTypes["JSONObject"] | undefined
};
	["RedemptionPdfReadDocAccess"]: {
	__typename: "RedemptionPdfReadDocAccess",
	permission: boolean,
	where?: GraphQLTypes["JSONObject"] | undefined
};
	["RedemptionPdfUpdateDocAccess"]: {
	__typename: "RedemptionPdfUpdateDocAccess",
	permission: boolean,
	where?: GraphQLTypes["JSONObject"] | undefined
};
	["RedemptionPdfDeleteDocAccess"]: {
	__typename: "RedemptionPdfDeleteDocAccess",
	permission: boolean,
	where?: GraphQLTypes["JSONObject"] | undefined
};
	["CheckInRedemption"]: {
	__typename: "CheckInRedemption",
	id?: string | undefined,
	creator?: GraphQLTypes["User"] | undefined,
	qrCodeId?: string | undefined,
	codeownerName?: string | undefined,
	name?: string | undefined,
	phone?: string | undefined,
	linkedEvent?: GraphQLTypes["Event"] | undefined,
	entryNumber?: string | undefined,
	scanAt?: GraphQLTypes["DateTime"] | undefined,
	uploadAt?: GraphQLTypes["DateTime"] | undefined,
	status?: string | undefined,
	failReason?: string | undefined,
	variants?: Array<GraphQLTypes["CheckInRedemption_Variants"]> | undefined,
	checkInAppSelectionId?: string | undefined,
	qrCodeStatus?: string | undefined,
	productType?: GraphQLTypes["CheckInRedemption_productType"] | undefined,
	updatedAt?: GraphQLTypes["DateTime"] | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined
};
	["CheckInRedemption_Variants"]: {
	__typename: "CheckInRedemption_Variants",
	variantId?: string | undefined,
	name?: string | undefined,
	quantity?: string | undefined,
	totalCost?: string | undefined,
	id?: string | undefined
};
	["CheckInRedemption_productType"]: CheckInRedemption_productType;
	["CheckInRedemptions"]: {
	__typename: "CheckInRedemptions",
	docs?: Array<GraphQLTypes["CheckInRedemption"] | undefined> | undefined,
	hasNextPage?: boolean | undefined,
	hasPrevPage?: boolean | undefined,
	limit?: number | undefined,
	nextPage?: number | undefined,
	offset?: number | undefined,
	page?: number | undefined,
	pagingCounter?: number | undefined,
	prevPage?: number | undefined,
	totalDocs?: number | undefined,
	totalPages?: number | undefined
};
	["CheckInRedemption_where"]: {
		creator?: GraphQLTypes["CheckInRedemption_creator_operator"] | undefined,
	qrCodeId?: GraphQLTypes["CheckInRedemption_qrCodeId_operator"] | undefined,
	codeownerName?: GraphQLTypes["CheckInRedemption_codeownerName_operator"] | undefined,
	name?: GraphQLTypes["CheckInRedemption_name_operator"] | undefined,
	phone?: GraphQLTypes["CheckInRedemption_phone_operator"] | undefined,
	linkedEvent?: GraphQLTypes["CheckInRedemption_linkedEvent_operator"] | undefined,
	entryNumber?: GraphQLTypes["CheckInRedemption_entryNumber_operator"] | undefined,
	scanAt?: GraphQLTypes["CheckInRedemption_scanAt_operator"] | undefined,
	uploadAt?: GraphQLTypes["CheckInRedemption_uploadAt_operator"] | undefined,
	status?: GraphQLTypes["CheckInRedemption_status_operator"] | undefined,
	failReason?: GraphQLTypes["CheckInRedemption_failReason_operator"] | undefined,
	variants__variantId?: GraphQLTypes["CheckInRedemption_variants__variantId_operator"] | undefined,
	variants__name?: GraphQLTypes["CheckInRedemption_variants__name_operator"] | undefined,
	variants__quantity?: GraphQLTypes["CheckInRedemption_variants__quantity_operator"] | undefined,
	variants__totalCost?: GraphQLTypes["CheckInRedemption_variants__totalCost_operator"] | undefined,
	variants__id?: GraphQLTypes["CheckInRedemption_variants__id_operator"] | undefined,
	checkInAppSelectionId?: GraphQLTypes["CheckInRedemption_checkInAppSelectionId_operator"] | undefined,
	qrCodeStatus?: GraphQLTypes["CheckInRedemption_qrCodeStatus_operator"] | undefined,
	productType?: GraphQLTypes["CheckInRedemption_productType_operator"] | undefined,
	updatedAt?: GraphQLTypes["CheckInRedemption_updatedAt_operator"] | undefined,
	createdAt?: GraphQLTypes["CheckInRedemption_createdAt_operator"] | undefined,
	id?: GraphQLTypes["CheckInRedemption_id_operator"] | undefined,
	AND?: Array<GraphQLTypes["CheckInRedemption_where_and"] | undefined> | undefined,
	OR?: Array<GraphQLTypes["CheckInRedemption_where_or"] | undefined> | undefined
};
	["CheckInRedemption_creator_operator"]: {
		equals?: GraphQLTypes["JSON"] | undefined,
	not_equals?: GraphQLTypes["JSON"] | undefined,
	in?: Array<GraphQLTypes["JSON"] | undefined> | undefined,
	not_in?: Array<GraphQLTypes["JSON"] | undefined> | undefined,
	all?: Array<GraphQLTypes["JSON"] | undefined> | undefined,
	exists?: boolean | undefined
};
	["CheckInRedemption_qrCodeId_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["CheckInRedemption_codeownerName_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["CheckInRedemption_name_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["CheckInRedemption_phone_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["CheckInRedemption_linkedEvent_operator"]: {
		equals?: GraphQLTypes["JSON"] | undefined,
	not_equals?: GraphQLTypes["JSON"] | undefined,
	in?: Array<GraphQLTypes["JSON"] | undefined> | undefined,
	not_in?: Array<GraphQLTypes["JSON"] | undefined> | undefined,
	all?: Array<GraphQLTypes["JSON"] | undefined> | undefined,
	exists?: boolean | undefined
};
	["CheckInRedemption_entryNumber_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["CheckInRedemption_scanAt_operator"]: {
		equals?: GraphQLTypes["DateTime"] | undefined,
	not_equals?: GraphQLTypes["DateTime"] | undefined,
	greater_than_equal?: GraphQLTypes["DateTime"] | undefined,
	greater_than?: GraphQLTypes["DateTime"] | undefined,
	less_than_equal?: GraphQLTypes["DateTime"] | undefined,
	less_than?: GraphQLTypes["DateTime"] | undefined,
	like?: GraphQLTypes["DateTime"] | undefined,
	exists?: boolean | undefined
};
	["CheckInRedemption_uploadAt_operator"]: {
		equals?: GraphQLTypes["DateTime"] | undefined,
	not_equals?: GraphQLTypes["DateTime"] | undefined,
	greater_than_equal?: GraphQLTypes["DateTime"] | undefined,
	greater_than?: GraphQLTypes["DateTime"] | undefined,
	less_than_equal?: GraphQLTypes["DateTime"] | undefined,
	less_than?: GraphQLTypes["DateTime"] | undefined,
	like?: GraphQLTypes["DateTime"] | undefined,
	exists?: boolean | undefined
};
	["CheckInRedemption_status_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["CheckInRedemption_failReason_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["CheckInRedemption_variants__variantId_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["CheckInRedemption_variants__name_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["CheckInRedemption_variants__quantity_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["CheckInRedemption_variants__totalCost_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["CheckInRedemption_variants__id_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["CheckInRedemption_checkInAppSelectionId_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["CheckInRedemption_qrCodeStatus_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["CheckInRedemption_productType_operator"]: {
		equals?: GraphQLTypes["CheckInRedemption_productType_Input"] | undefined,
	not_equals?: GraphQLTypes["CheckInRedemption_productType_Input"] | undefined,
	like?: GraphQLTypes["CheckInRedemption_productType_Input"] | undefined,
	contains?: GraphQLTypes["CheckInRedemption_productType_Input"] | undefined,
	exists?: boolean | undefined
};
	["CheckInRedemption_productType_Input"]: CheckInRedemption_productType_Input;
	["CheckInRedemption_updatedAt_operator"]: {
		equals?: GraphQLTypes["DateTime"] | undefined,
	not_equals?: GraphQLTypes["DateTime"] | undefined,
	greater_than_equal?: GraphQLTypes["DateTime"] | undefined,
	greater_than?: GraphQLTypes["DateTime"] | undefined,
	less_than_equal?: GraphQLTypes["DateTime"] | undefined,
	less_than?: GraphQLTypes["DateTime"] | undefined,
	like?: GraphQLTypes["DateTime"] | undefined,
	exists?: boolean | undefined
};
	["CheckInRedemption_createdAt_operator"]: {
		equals?: GraphQLTypes["DateTime"] | undefined,
	not_equals?: GraphQLTypes["DateTime"] | undefined,
	greater_than_equal?: GraphQLTypes["DateTime"] | undefined,
	greater_than?: GraphQLTypes["DateTime"] | undefined,
	less_than_equal?: GraphQLTypes["DateTime"] | undefined,
	less_than?: GraphQLTypes["DateTime"] | undefined,
	like?: GraphQLTypes["DateTime"] | undefined,
	exists?: boolean | undefined
};
	["CheckInRedemption_id_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["CheckInRedemption_where_and"]: {
		creator?: GraphQLTypes["CheckInRedemption_creator_operator"] | undefined,
	qrCodeId?: GraphQLTypes["CheckInRedemption_qrCodeId_operator"] | undefined,
	codeownerName?: GraphQLTypes["CheckInRedemption_codeownerName_operator"] | undefined,
	name?: GraphQLTypes["CheckInRedemption_name_operator"] | undefined,
	phone?: GraphQLTypes["CheckInRedemption_phone_operator"] | undefined,
	linkedEvent?: GraphQLTypes["CheckInRedemption_linkedEvent_operator"] | undefined,
	entryNumber?: GraphQLTypes["CheckInRedemption_entryNumber_operator"] | undefined,
	scanAt?: GraphQLTypes["CheckInRedemption_scanAt_operator"] | undefined,
	uploadAt?: GraphQLTypes["CheckInRedemption_uploadAt_operator"] | undefined,
	status?: GraphQLTypes["CheckInRedemption_status_operator"] | undefined,
	failReason?: GraphQLTypes["CheckInRedemption_failReason_operator"] | undefined,
	variants__variantId?: GraphQLTypes["CheckInRedemption_variants__variantId_operator"] | undefined,
	variants__name?: GraphQLTypes["CheckInRedemption_variants__name_operator"] | undefined,
	variants__quantity?: GraphQLTypes["CheckInRedemption_variants__quantity_operator"] | undefined,
	variants__totalCost?: GraphQLTypes["CheckInRedemption_variants__totalCost_operator"] | undefined,
	variants__id?: GraphQLTypes["CheckInRedemption_variants__id_operator"] | undefined,
	checkInAppSelectionId?: GraphQLTypes["CheckInRedemption_checkInAppSelectionId_operator"] | undefined,
	qrCodeStatus?: GraphQLTypes["CheckInRedemption_qrCodeStatus_operator"] | undefined,
	productType?: GraphQLTypes["CheckInRedemption_productType_operator"] | undefined,
	updatedAt?: GraphQLTypes["CheckInRedemption_updatedAt_operator"] | undefined,
	createdAt?: GraphQLTypes["CheckInRedemption_createdAt_operator"] | undefined,
	id?: GraphQLTypes["CheckInRedemption_id_operator"] | undefined,
	AND?: Array<GraphQLTypes["CheckInRedemption_where_and"] | undefined> | undefined,
	OR?: Array<GraphQLTypes["CheckInRedemption_where_or"] | undefined> | undefined
};
	["CheckInRedemption_where_or"]: {
		creator?: GraphQLTypes["CheckInRedemption_creator_operator"] | undefined,
	qrCodeId?: GraphQLTypes["CheckInRedemption_qrCodeId_operator"] | undefined,
	codeownerName?: GraphQLTypes["CheckInRedemption_codeownerName_operator"] | undefined,
	name?: GraphQLTypes["CheckInRedemption_name_operator"] | undefined,
	phone?: GraphQLTypes["CheckInRedemption_phone_operator"] | undefined,
	linkedEvent?: GraphQLTypes["CheckInRedemption_linkedEvent_operator"] | undefined,
	entryNumber?: GraphQLTypes["CheckInRedemption_entryNumber_operator"] | undefined,
	scanAt?: GraphQLTypes["CheckInRedemption_scanAt_operator"] | undefined,
	uploadAt?: GraphQLTypes["CheckInRedemption_uploadAt_operator"] | undefined,
	status?: GraphQLTypes["CheckInRedemption_status_operator"] | undefined,
	failReason?: GraphQLTypes["CheckInRedemption_failReason_operator"] | undefined,
	variants__variantId?: GraphQLTypes["CheckInRedemption_variants__variantId_operator"] | undefined,
	variants__name?: GraphQLTypes["CheckInRedemption_variants__name_operator"] | undefined,
	variants__quantity?: GraphQLTypes["CheckInRedemption_variants__quantity_operator"] | undefined,
	variants__totalCost?: GraphQLTypes["CheckInRedemption_variants__totalCost_operator"] | undefined,
	variants__id?: GraphQLTypes["CheckInRedemption_variants__id_operator"] | undefined,
	checkInAppSelectionId?: GraphQLTypes["CheckInRedemption_checkInAppSelectionId_operator"] | undefined,
	qrCodeStatus?: GraphQLTypes["CheckInRedemption_qrCodeStatus_operator"] | undefined,
	productType?: GraphQLTypes["CheckInRedemption_productType_operator"] | undefined,
	updatedAt?: GraphQLTypes["CheckInRedemption_updatedAt_operator"] | undefined,
	createdAt?: GraphQLTypes["CheckInRedemption_createdAt_operator"] | undefined,
	id?: GraphQLTypes["CheckInRedemption_id_operator"] | undefined,
	AND?: Array<GraphQLTypes["CheckInRedemption_where_and"] | undefined> | undefined,
	OR?: Array<GraphQLTypes["CheckInRedemption_where_or"] | undefined> | undefined
};
	["countCheckInRedemptions"]: {
	__typename: "countCheckInRedemptions",
	totalDocs?: number | undefined
};
	["check_in_redemptionDocAccess"]: {
	__typename: "check_in_redemptionDocAccess",
	fields?: GraphQLTypes["CheckInRedemptionDocAccessFields"] | undefined,
	create?: GraphQLTypes["CheckInRedemptionCreateDocAccess"] | undefined,
	read?: GraphQLTypes["CheckInRedemptionReadDocAccess"] | undefined,
	update?: GraphQLTypes["CheckInRedemptionUpdateDocAccess"] | undefined,
	delete?: GraphQLTypes["CheckInRedemptionDeleteDocAccess"] | undefined
};
	["CheckInRedemptionDocAccessFields"]: {
	__typename: "CheckInRedemptionDocAccessFields",
	creator?: GraphQLTypes["CheckInRedemptionDocAccessFields_creator"] | undefined,
	qrCodeId?: GraphQLTypes["CheckInRedemptionDocAccessFields_qrCodeId"] | undefined,
	codeownerName?: GraphQLTypes["CheckInRedemptionDocAccessFields_codeownerName"] | undefined,
	name?: GraphQLTypes["CheckInRedemptionDocAccessFields_name"] | undefined,
	phone?: GraphQLTypes["CheckInRedemptionDocAccessFields_phone"] | undefined,
	linkedEvent?: GraphQLTypes["CheckInRedemptionDocAccessFields_linkedEvent"] | undefined,
	entryNumber?: GraphQLTypes["CheckInRedemptionDocAccessFields_entryNumber"] | undefined,
	scanAt?: GraphQLTypes["CheckInRedemptionDocAccessFields_scanAt"] | undefined,
	uploadAt?: GraphQLTypes["CheckInRedemptionDocAccessFields_uploadAt"] | undefined,
	status?: GraphQLTypes["CheckInRedemptionDocAccessFields_status"] | undefined,
	failReason?: GraphQLTypes["CheckInRedemptionDocAccessFields_failReason"] | undefined,
	variants?: GraphQLTypes["CheckInRedemptionDocAccessFields_variants"] | undefined,
	checkInAppSelectionId?: GraphQLTypes["CheckInRedemptionDocAccessFields_checkInAppSelectionId"] | undefined,
	qrCodeStatus?: GraphQLTypes["CheckInRedemptionDocAccessFields_qrCodeStatus"] | undefined,
	productType?: GraphQLTypes["CheckInRedemptionDocAccessFields_productType"] | undefined,
	updatedAt?: GraphQLTypes["CheckInRedemptionDocAccessFields_updatedAt"] | undefined,
	createdAt?: GraphQLTypes["CheckInRedemptionDocAccessFields_createdAt"] | undefined
};
	["CheckInRedemptionDocAccessFields_creator"]: {
	__typename: "CheckInRedemptionDocAccessFields_creator",
	create?: GraphQLTypes["CheckInRedemptionDocAccessFields_creator_Create"] | undefined,
	read?: GraphQLTypes["CheckInRedemptionDocAccessFields_creator_Read"] | undefined,
	update?: GraphQLTypes["CheckInRedemptionDocAccessFields_creator_Update"] | undefined,
	delete?: GraphQLTypes["CheckInRedemptionDocAccessFields_creator_Delete"] | undefined
};
	["CheckInRedemptionDocAccessFields_creator_Create"]: {
	__typename: "CheckInRedemptionDocAccessFields_creator_Create",
	permission: boolean
};
	["CheckInRedemptionDocAccessFields_creator_Read"]: {
	__typename: "CheckInRedemptionDocAccessFields_creator_Read",
	permission: boolean
};
	["CheckInRedemptionDocAccessFields_creator_Update"]: {
	__typename: "CheckInRedemptionDocAccessFields_creator_Update",
	permission: boolean
};
	["CheckInRedemptionDocAccessFields_creator_Delete"]: {
	__typename: "CheckInRedemptionDocAccessFields_creator_Delete",
	permission: boolean
};
	["CheckInRedemptionDocAccessFields_qrCodeId"]: {
	__typename: "CheckInRedemptionDocAccessFields_qrCodeId",
	create?: GraphQLTypes["CheckInRedemptionDocAccessFields_qrCodeId_Create"] | undefined,
	read?: GraphQLTypes["CheckInRedemptionDocAccessFields_qrCodeId_Read"] | undefined,
	update?: GraphQLTypes["CheckInRedemptionDocAccessFields_qrCodeId_Update"] | undefined,
	delete?: GraphQLTypes["CheckInRedemptionDocAccessFields_qrCodeId_Delete"] | undefined
};
	["CheckInRedemptionDocAccessFields_qrCodeId_Create"]: {
	__typename: "CheckInRedemptionDocAccessFields_qrCodeId_Create",
	permission: boolean
};
	["CheckInRedemptionDocAccessFields_qrCodeId_Read"]: {
	__typename: "CheckInRedemptionDocAccessFields_qrCodeId_Read",
	permission: boolean
};
	["CheckInRedemptionDocAccessFields_qrCodeId_Update"]: {
	__typename: "CheckInRedemptionDocAccessFields_qrCodeId_Update",
	permission: boolean
};
	["CheckInRedemptionDocAccessFields_qrCodeId_Delete"]: {
	__typename: "CheckInRedemptionDocAccessFields_qrCodeId_Delete",
	permission: boolean
};
	["CheckInRedemptionDocAccessFields_codeownerName"]: {
	__typename: "CheckInRedemptionDocAccessFields_codeownerName",
	create?: GraphQLTypes["CheckInRedemptionDocAccessFields_codeownerName_Create"] | undefined,
	read?: GraphQLTypes["CheckInRedemptionDocAccessFields_codeownerName_Read"] | undefined,
	update?: GraphQLTypes["CheckInRedemptionDocAccessFields_codeownerName_Update"] | undefined,
	delete?: GraphQLTypes["CheckInRedemptionDocAccessFields_codeownerName_Delete"] | undefined
};
	["CheckInRedemptionDocAccessFields_codeownerName_Create"]: {
	__typename: "CheckInRedemptionDocAccessFields_codeownerName_Create",
	permission: boolean
};
	["CheckInRedemptionDocAccessFields_codeownerName_Read"]: {
	__typename: "CheckInRedemptionDocAccessFields_codeownerName_Read",
	permission: boolean
};
	["CheckInRedemptionDocAccessFields_codeownerName_Update"]: {
	__typename: "CheckInRedemptionDocAccessFields_codeownerName_Update",
	permission: boolean
};
	["CheckInRedemptionDocAccessFields_codeownerName_Delete"]: {
	__typename: "CheckInRedemptionDocAccessFields_codeownerName_Delete",
	permission: boolean
};
	["CheckInRedemptionDocAccessFields_name"]: {
	__typename: "CheckInRedemptionDocAccessFields_name",
	create?: GraphQLTypes["CheckInRedemptionDocAccessFields_name_Create"] | undefined,
	read?: GraphQLTypes["CheckInRedemptionDocAccessFields_name_Read"] | undefined,
	update?: GraphQLTypes["CheckInRedemptionDocAccessFields_name_Update"] | undefined,
	delete?: GraphQLTypes["CheckInRedemptionDocAccessFields_name_Delete"] | undefined
};
	["CheckInRedemptionDocAccessFields_name_Create"]: {
	__typename: "CheckInRedemptionDocAccessFields_name_Create",
	permission: boolean
};
	["CheckInRedemptionDocAccessFields_name_Read"]: {
	__typename: "CheckInRedemptionDocAccessFields_name_Read",
	permission: boolean
};
	["CheckInRedemptionDocAccessFields_name_Update"]: {
	__typename: "CheckInRedemptionDocAccessFields_name_Update",
	permission: boolean
};
	["CheckInRedemptionDocAccessFields_name_Delete"]: {
	__typename: "CheckInRedemptionDocAccessFields_name_Delete",
	permission: boolean
};
	["CheckInRedemptionDocAccessFields_phone"]: {
	__typename: "CheckInRedemptionDocAccessFields_phone",
	create?: GraphQLTypes["CheckInRedemptionDocAccessFields_phone_Create"] | undefined,
	read?: GraphQLTypes["CheckInRedemptionDocAccessFields_phone_Read"] | undefined,
	update?: GraphQLTypes["CheckInRedemptionDocAccessFields_phone_Update"] | undefined,
	delete?: GraphQLTypes["CheckInRedemptionDocAccessFields_phone_Delete"] | undefined
};
	["CheckInRedemptionDocAccessFields_phone_Create"]: {
	__typename: "CheckInRedemptionDocAccessFields_phone_Create",
	permission: boolean
};
	["CheckInRedemptionDocAccessFields_phone_Read"]: {
	__typename: "CheckInRedemptionDocAccessFields_phone_Read",
	permission: boolean
};
	["CheckInRedemptionDocAccessFields_phone_Update"]: {
	__typename: "CheckInRedemptionDocAccessFields_phone_Update",
	permission: boolean
};
	["CheckInRedemptionDocAccessFields_phone_Delete"]: {
	__typename: "CheckInRedemptionDocAccessFields_phone_Delete",
	permission: boolean
};
	["CheckInRedemptionDocAccessFields_linkedEvent"]: {
	__typename: "CheckInRedemptionDocAccessFields_linkedEvent",
	create?: GraphQLTypes["CheckInRedemptionDocAccessFields_linkedEvent_Create"] | undefined,
	read?: GraphQLTypes["CheckInRedemptionDocAccessFields_linkedEvent_Read"] | undefined,
	update?: GraphQLTypes["CheckInRedemptionDocAccessFields_linkedEvent_Update"] | undefined,
	delete?: GraphQLTypes["CheckInRedemptionDocAccessFields_linkedEvent_Delete"] | undefined
};
	["CheckInRedemptionDocAccessFields_linkedEvent_Create"]: {
	__typename: "CheckInRedemptionDocAccessFields_linkedEvent_Create",
	permission: boolean
};
	["CheckInRedemptionDocAccessFields_linkedEvent_Read"]: {
	__typename: "CheckInRedemptionDocAccessFields_linkedEvent_Read",
	permission: boolean
};
	["CheckInRedemptionDocAccessFields_linkedEvent_Update"]: {
	__typename: "CheckInRedemptionDocAccessFields_linkedEvent_Update",
	permission: boolean
};
	["CheckInRedemptionDocAccessFields_linkedEvent_Delete"]: {
	__typename: "CheckInRedemptionDocAccessFields_linkedEvent_Delete",
	permission: boolean
};
	["CheckInRedemptionDocAccessFields_entryNumber"]: {
	__typename: "CheckInRedemptionDocAccessFields_entryNumber",
	create?: GraphQLTypes["CheckInRedemptionDocAccessFields_entryNumber_Create"] | undefined,
	read?: GraphQLTypes["CheckInRedemptionDocAccessFields_entryNumber_Read"] | undefined,
	update?: GraphQLTypes["CheckInRedemptionDocAccessFields_entryNumber_Update"] | undefined,
	delete?: GraphQLTypes["CheckInRedemptionDocAccessFields_entryNumber_Delete"] | undefined
};
	["CheckInRedemptionDocAccessFields_entryNumber_Create"]: {
	__typename: "CheckInRedemptionDocAccessFields_entryNumber_Create",
	permission: boolean
};
	["CheckInRedemptionDocAccessFields_entryNumber_Read"]: {
	__typename: "CheckInRedemptionDocAccessFields_entryNumber_Read",
	permission: boolean
};
	["CheckInRedemptionDocAccessFields_entryNumber_Update"]: {
	__typename: "CheckInRedemptionDocAccessFields_entryNumber_Update",
	permission: boolean
};
	["CheckInRedemptionDocAccessFields_entryNumber_Delete"]: {
	__typename: "CheckInRedemptionDocAccessFields_entryNumber_Delete",
	permission: boolean
};
	["CheckInRedemptionDocAccessFields_scanAt"]: {
	__typename: "CheckInRedemptionDocAccessFields_scanAt",
	create?: GraphQLTypes["CheckInRedemptionDocAccessFields_scanAt_Create"] | undefined,
	read?: GraphQLTypes["CheckInRedemptionDocAccessFields_scanAt_Read"] | undefined,
	update?: GraphQLTypes["CheckInRedemptionDocAccessFields_scanAt_Update"] | undefined,
	delete?: GraphQLTypes["CheckInRedemptionDocAccessFields_scanAt_Delete"] | undefined
};
	["CheckInRedemptionDocAccessFields_scanAt_Create"]: {
	__typename: "CheckInRedemptionDocAccessFields_scanAt_Create",
	permission: boolean
};
	["CheckInRedemptionDocAccessFields_scanAt_Read"]: {
	__typename: "CheckInRedemptionDocAccessFields_scanAt_Read",
	permission: boolean
};
	["CheckInRedemptionDocAccessFields_scanAt_Update"]: {
	__typename: "CheckInRedemptionDocAccessFields_scanAt_Update",
	permission: boolean
};
	["CheckInRedemptionDocAccessFields_scanAt_Delete"]: {
	__typename: "CheckInRedemptionDocAccessFields_scanAt_Delete",
	permission: boolean
};
	["CheckInRedemptionDocAccessFields_uploadAt"]: {
	__typename: "CheckInRedemptionDocAccessFields_uploadAt",
	create?: GraphQLTypes["CheckInRedemptionDocAccessFields_uploadAt_Create"] | undefined,
	read?: GraphQLTypes["CheckInRedemptionDocAccessFields_uploadAt_Read"] | undefined,
	update?: GraphQLTypes["CheckInRedemptionDocAccessFields_uploadAt_Update"] | undefined,
	delete?: GraphQLTypes["CheckInRedemptionDocAccessFields_uploadAt_Delete"] | undefined
};
	["CheckInRedemptionDocAccessFields_uploadAt_Create"]: {
	__typename: "CheckInRedemptionDocAccessFields_uploadAt_Create",
	permission: boolean
};
	["CheckInRedemptionDocAccessFields_uploadAt_Read"]: {
	__typename: "CheckInRedemptionDocAccessFields_uploadAt_Read",
	permission: boolean
};
	["CheckInRedemptionDocAccessFields_uploadAt_Update"]: {
	__typename: "CheckInRedemptionDocAccessFields_uploadAt_Update",
	permission: boolean
};
	["CheckInRedemptionDocAccessFields_uploadAt_Delete"]: {
	__typename: "CheckInRedemptionDocAccessFields_uploadAt_Delete",
	permission: boolean
};
	["CheckInRedemptionDocAccessFields_status"]: {
	__typename: "CheckInRedemptionDocAccessFields_status",
	create?: GraphQLTypes["CheckInRedemptionDocAccessFields_status_Create"] | undefined,
	read?: GraphQLTypes["CheckInRedemptionDocAccessFields_status_Read"] | undefined,
	update?: GraphQLTypes["CheckInRedemptionDocAccessFields_status_Update"] | undefined,
	delete?: GraphQLTypes["CheckInRedemptionDocAccessFields_status_Delete"] | undefined
};
	["CheckInRedemptionDocAccessFields_status_Create"]: {
	__typename: "CheckInRedemptionDocAccessFields_status_Create",
	permission: boolean
};
	["CheckInRedemptionDocAccessFields_status_Read"]: {
	__typename: "CheckInRedemptionDocAccessFields_status_Read",
	permission: boolean
};
	["CheckInRedemptionDocAccessFields_status_Update"]: {
	__typename: "CheckInRedemptionDocAccessFields_status_Update",
	permission: boolean
};
	["CheckInRedemptionDocAccessFields_status_Delete"]: {
	__typename: "CheckInRedemptionDocAccessFields_status_Delete",
	permission: boolean
};
	["CheckInRedemptionDocAccessFields_failReason"]: {
	__typename: "CheckInRedemptionDocAccessFields_failReason",
	create?: GraphQLTypes["CheckInRedemptionDocAccessFields_failReason_Create"] | undefined,
	read?: GraphQLTypes["CheckInRedemptionDocAccessFields_failReason_Read"] | undefined,
	update?: GraphQLTypes["CheckInRedemptionDocAccessFields_failReason_Update"] | undefined,
	delete?: GraphQLTypes["CheckInRedemptionDocAccessFields_failReason_Delete"] | undefined
};
	["CheckInRedemptionDocAccessFields_failReason_Create"]: {
	__typename: "CheckInRedemptionDocAccessFields_failReason_Create",
	permission: boolean
};
	["CheckInRedemptionDocAccessFields_failReason_Read"]: {
	__typename: "CheckInRedemptionDocAccessFields_failReason_Read",
	permission: boolean
};
	["CheckInRedemptionDocAccessFields_failReason_Update"]: {
	__typename: "CheckInRedemptionDocAccessFields_failReason_Update",
	permission: boolean
};
	["CheckInRedemptionDocAccessFields_failReason_Delete"]: {
	__typename: "CheckInRedemptionDocAccessFields_failReason_Delete",
	permission: boolean
};
	["CheckInRedemptionDocAccessFields_variants"]: {
	__typename: "CheckInRedemptionDocAccessFields_variants",
	create?: GraphQLTypes["CheckInRedemptionDocAccessFields_variants_Create"] | undefined,
	read?: GraphQLTypes["CheckInRedemptionDocAccessFields_variants_Read"] | undefined,
	update?: GraphQLTypes["CheckInRedemptionDocAccessFields_variants_Update"] | undefined,
	delete?: GraphQLTypes["CheckInRedemptionDocAccessFields_variants_Delete"] | undefined,
	fields?: GraphQLTypes["CheckInRedemptionDocAccessFields_variants_Fields"] | undefined
};
	["CheckInRedemptionDocAccessFields_variants_Create"]: {
	__typename: "CheckInRedemptionDocAccessFields_variants_Create",
	permission: boolean
};
	["CheckInRedemptionDocAccessFields_variants_Read"]: {
	__typename: "CheckInRedemptionDocAccessFields_variants_Read",
	permission: boolean
};
	["CheckInRedemptionDocAccessFields_variants_Update"]: {
	__typename: "CheckInRedemptionDocAccessFields_variants_Update",
	permission: boolean
};
	["CheckInRedemptionDocAccessFields_variants_Delete"]: {
	__typename: "CheckInRedemptionDocAccessFields_variants_Delete",
	permission: boolean
};
	["CheckInRedemptionDocAccessFields_variants_Fields"]: {
	__typename: "CheckInRedemptionDocAccessFields_variants_Fields",
	variantId?: GraphQLTypes["CheckInRedemptionDocAccessFields_variants_variantId"] | undefined,
	name?: GraphQLTypes["CheckInRedemptionDocAccessFields_variants_name"] | undefined,
	quantity?: GraphQLTypes["CheckInRedemptionDocAccessFields_variants_quantity"] | undefined,
	totalCost?: GraphQLTypes["CheckInRedemptionDocAccessFields_variants_totalCost"] | undefined,
	id?: GraphQLTypes["CheckInRedemptionDocAccessFields_variants_id"] | undefined
};
	["CheckInRedemptionDocAccessFields_variants_variantId"]: {
	__typename: "CheckInRedemptionDocAccessFields_variants_variantId",
	create?: GraphQLTypes["CheckInRedemptionDocAccessFields_variants_variantId_Create"] | undefined,
	read?: GraphQLTypes["CheckInRedemptionDocAccessFields_variants_variantId_Read"] | undefined,
	update?: GraphQLTypes["CheckInRedemptionDocAccessFields_variants_variantId_Update"] | undefined,
	delete?: GraphQLTypes["CheckInRedemptionDocAccessFields_variants_variantId_Delete"] | undefined
};
	["CheckInRedemptionDocAccessFields_variants_variantId_Create"]: {
	__typename: "CheckInRedemptionDocAccessFields_variants_variantId_Create",
	permission: boolean
};
	["CheckInRedemptionDocAccessFields_variants_variantId_Read"]: {
	__typename: "CheckInRedemptionDocAccessFields_variants_variantId_Read",
	permission: boolean
};
	["CheckInRedemptionDocAccessFields_variants_variantId_Update"]: {
	__typename: "CheckInRedemptionDocAccessFields_variants_variantId_Update",
	permission: boolean
};
	["CheckInRedemptionDocAccessFields_variants_variantId_Delete"]: {
	__typename: "CheckInRedemptionDocAccessFields_variants_variantId_Delete",
	permission: boolean
};
	["CheckInRedemptionDocAccessFields_variants_name"]: {
	__typename: "CheckInRedemptionDocAccessFields_variants_name",
	create?: GraphQLTypes["CheckInRedemptionDocAccessFields_variants_name_Create"] | undefined,
	read?: GraphQLTypes["CheckInRedemptionDocAccessFields_variants_name_Read"] | undefined,
	update?: GraphQLTypes["CheckInRedemptionDocAccessFields_variants_name_Update"] | undefined,
	delete?: GraphQLTypes["CheckInRedemptionDocAccessFields_variants_name_Delete"] | undefined
};
	["CheckInRedemptionDocAccessFields_variants_name_Create"]: {
	__typename: "CheckInRedemptionDocAccessFields_variants_name_Create",
	permission: boolean
};
	["CheckInRedemptionDocAccessFields_variants_name_Read"]: {
	__typename: "CheckInRedemptionDocAccessFields_variants_name_Read",
	permission: boolean
};
	["CheckInRedemptionDocAccessFields_variants_name_Update"]: {
	__typename: "CheckInRedemptionDocAccessFields_variants_name_Update",
	permission: boolean
};
	["CheckInRedemptionDocAccessFields_variants_name_Delete"]: {
	__typename: "CheckInRedemptionDocAccessFields_variants_name_Delete",
	permission: boolean
};
	["CheckInRedemptionDocAccessFields_variants_quantity"]: {
	__typename: "CheckInRedemptionDocAccessFields_variants_quantity",
	create?: GraphQLTypes["CheckInRedemptionDocAccessFields_variants_quantity_Create"] | undefined,
	read?: GraphQLTypes["CheckInRedemptionDocAccessFields_variants_quantity_Read"] | undefined,
	update?: GraphQLTypes["CheckInRedemptionDocAccessFields_variants_quantity_Update"] | undefined,
	delete?: GraphQLTypes["CheckInRedemptionDocAccessFields_variants_quantity_Delete"] | undefined
};
	["CheckInRedemptionDocAccessFields_variants_quantity_Create"]: {
	__typename: "CheckInRedemptionDocAccessFields_variants_quantity_Create",
	permission: boolean
};
	["CheckInRedemptionDocAccessFields_variants_quantity_Read"]: {
	__typename: "CheckInRedemptionDocAccessFields_variants_quantity_Read",
	permission: boolean
};
	["CheckInRedemptionDocAccessFields_variants_quantity_Update"]: {
	__typename: "CheckInRedemptionDocAccessFields_variants_quantity_Update",
	permission: boolean
};
	["CheckInRedemptionDocAccessFields_variants_quantity_Delete"]: {
	__typename: "CheckInRedemptionDocAccessFields_variants_quantity_Delete",
	permission: boolean
};
	["CheckInRedemptionDocAccessFields_variants_totalCost"]: {
	__typename: "CheckInRedemptionDocAccessFields_variants_totalCost",
	create?: GraphQLTypes["CheckInRedemptionDocAccessFields_variants_totalCost_Create"] | undefined,
	read?: GraphQLTypes["CheckInRedemptionDocAccessFields_variants_totalCost_Read"] | undefined,
	update?: GraphQLTypes["CheckInRedemptionDocAccessFields_variants_totalCost_Update"] | undefined,
	delete?: GraphQLTypes["CheckInRedemptionDocAccessFields_variants_totalCost_Delete"] | undefined
};
	["CheckInRedemptionDocAccessFields_variants_totalCost_Create"]: {
	__typename: "CheckInRedemptionDocAccessFields_variants_totalCost_Create",
	permission: boolean
};
	["CheckInRedemptionDocAccessFields_variants_totalCost_Read"]: {
	__typename: "CheckInRedemptionDocAccessFields_variants_totalCost_Read",
	permission: boolean
};
	["CheckInRedemptionDocAccessFields_variants_totalCost_Update"]: {
	__typename: "CheckInRedemptionDocAccessFields_variants_totalCost_Update",
	permission: boolean
};
	["CheckInRedemptionDocAccessFields_variants_totalCost_Delete"]: {
	__typename: "CheckInRedemptionDocAccessFields_variants_totalCost_Delete",
	permission: boolean
};
	["CheckInRedemptionDocAccessFields_variants_id"]: {
	__typename: "CheckInRedemptionDocAccessFields_variants_id",
	create?: GraphQLTypes["CheckInRedemptionDocAccessFields_variants_id_Create"] | undefined,
	read?: GraphQLTypes["CheckInRedemptionDocAccessFields_variants_id_Read"] | undefined,
	update?: GraphQLTypes["CheckInRedemptionDocAccessFields_variants_id_Update"] | undefined,
	delete?: GraphQLTypes["CheckInRedemptionDocAccessFields_variants_id_Delete"] | undefined
};
	["CheckInRedemptionDocAccessFields_variants_id_Create"]: {
	__typename: "CheckInRedemptionDocAccessFields_variants_id_Create",
	permission: boolean
};
	["CheckInRedemptionDocAccessFields_variants_id_Read"]: {
	__typename: "CheckInRedemptionDocAccessFields_variants_id_Read",
	permission: boolean
};
	["CheckInRedemptionDocAccessFields_variants_id_Update"]: {
	__typename: "CheckInRedemptionDocAccessFields_variants_id_Update",
	permission: boolean
};
	["CheckInRedemptionDocAccessFields_variants_id_Delete"]: {
	__typename: "CheckInRedemptionDocAccessFields_variants_id_Delete",
	permission: boolean
};
	["CheckInRedemptionDocAccessFields_checkInAppSelectionId"]: {
	__typename: "CheckInRedemptionDocAccessFields_checkInAppSelectionId",
	create?: GraphQLTypes["CheckInRedemptionDocAccessFields_checkInAppSelectionId_Create"] | undefined,
	read?: GraphQLTypes["CheckInRedemptionDocAccessFields_checkInAppSelectionId_Read"] | undefined,
	update?: GraphQLTypes["CheckInRedemptionDocAccessFields_checkInAppSelectionId_Update"] | undefined,
	delete?: GraphQLTypes["CheckInRedemptionDocAccessFields_checkInAppSelectionId_Delete"] | undefined
};
	["CheckInRedemptionDocAccessFields_checkInAppSelectionId_Create"]: {
	__typename: "CheckInRedemptionDocAccessFields_checkInAppSelectionId_Create",
	permission: boolean
};
	["CheckInRedemptionDocAccessFields_checkInAppSelectionId_Read"]: {
	__typename: "CheckInRedemptionDocAccessFields_checkInAppSelectionId_Read",
	permission: boolean
};
	["CheckInRedemptionDocAccessFields_checkInAppSelectionId_Update"]: {
	__typename: "CheckInRedemptionDocAccessFields_checkInAppSelectionId_Update",
	permission: boolean
};
	["CheckInRedemptionDocAccessFields_checkInAppSelectionId_Delete"]: {
	__typename: "CheckInRedemptionDocAccessFields_checkInAppSelectionId_Delete",
	permission: boolean
};
	["CheckInRedemptionDocAccessFields_qrCodeStatus"]: {
	__typename: "CheckInRedemptionDocAccessFields_qrCodeStatus",
	create?: GraphQLTypes["CheckInRedemptionDocAccessFields_qrCodeStatus_Create"] | undefined,
	read?: GraphQLTypes["CheckInRedemptionDocAccessFields_qrCodeStatus_Read"] | undefined,
	update?: GraphQLTypes["CheckInRedemptionDocAccessFields_qrCodeStatus_Update"] | undefined,
	delete?: GraphQLTypes["CheckInRedemptionDocAccessFields_qrCodeStatus_Delete"] | undefined
};
	["CheckInRedemptionDocAccessFields_qrCodeStatus_Create"]: {
	__typename: "CheckInRedemptionDocAccessFields_qrCodeStatus_Create",
	permission: boolean
};
	["CheckInRedemptionDocAccessFields_qrCodeStatus_Read"]: {
	__typename: "CheckInRedemptionDocAccessFields_qrCodeStatus_Read",
	permission: boolean
};
	["CheckInRedemptionDocAccessFields_qrCodeStatus_Update"]: {
	__typename: "CheckInRedemptionDocAccessFields_qrCodeStatus_Update",
	permission: boolean
};
	["CheckInRedemptionDocAccessFields_qrCodeStatus_Delete"]: {
	__typename: "CheckInRedemptionDocAccessFields_qrCodeStatus_Delete",
	permission: boolean
};
	["CheckInRedemptionDocAccessFields_productType"]: {
	__typename: "CheckInRedemptionDocAccessFields_productType",
	create?: GraphQLTypes["CheckInRedemptionDocAccessFields_productType_Create"] | undefined,
	read?: GraphQLTypes["CheckInRedemptionDocAccessFields_productType_Read"] | undefined,
	update?: GraphQLTypes["CheckInRedemptionDocAccessFields_productType_Update"] | undefined,
	delete?: GraphQLTypes["CheckInRedemptionDocAccessFields_productType_Delete"] | undefined
};
	["CheckInRedemptionDocAccessFields_productType_Create"]: {
	__typename: "CheckInRedemptionDocAccessFields_productType_Create",
	permission: boolean
};
	["CheckInRedemptionDocAccessFields_productType_Read"]: {
	__typename: "CheckInRedemptionDocAccessFields_productType_Read",
	permission: boolean
};
	["CheckInRedemptionDocAccessFields_productType_Update"]: {
	__typename: "CheckInRedemptionDocAccessFields_productType_Update",
	permission: boolean
};
	["CheckInRedemptionDocAccessFields_productType_Delete"]: {
	__typename: "CheckInRedemptionDocAccessFields_productType_Delete",
	permission: boolean
};
	["CheckInRedemptionDocAccessFields_updatedAt"]: {
	__typename: "CheckInRedemptionDocAccessFields_updatedAt",
	create?: GraphQLTypes["CheckInRedemptionDocAccessFields_updatedAt_Create"] | undefined,
	read?: GraphQLTypes["CheckInRedemptionDocAccessFields_updatedAt_Read"] | undefined,
	update?: GraphQLTypes["CheckInRedemptionDocAccessFields_updatedAt_Update"] | undefined,
	delete?: GraphQLTypes["CheckInRedemptionDocAccessFields_updatedAt_Delete"] | undefined
};
	["CheckInRedemptionDocAccessFields_updatedAt_Create"]: {
	__typename: "CheckInRedemptionDocAccessFields_updatedAt_Create",
	permission: boolean
};
	["CheckInRedemptionDocAccessFields_updatedAt_Read"]: {
	__typename: "CheckInRedemptionDocAccessFields_updatedAt_Read",
	permission: boolean
};
	["CheckInRedemptionDocAccessFields_updatedAt_Update"]: {
	__typename: "CheckInRedemptionDocAccessFields_updatedAt_Update",
	permission: boolean
};
	["CheckInRedemptionDocAccessFields_updatedAt_Delete"]: {
	__typename: "CheckInRedemptionDocAccessFields_updatedAt_Delete",
	permission: boolean
};
	["CheckInRedemptionDocAccessFields_createdAt"]: {
	__typename: "CheckInRedemptionDocAccessFields_createdAt",
	create?: GraphQLTypes["CheckInRedemptionDocAccessFields_createdAt_Create"] | undefined,
	read?: GraphQLTypes["CheckInRedemptionDocAccessFields_createdAt_Read"] | undefined,
	update?: GraphQLTypes["CheckInRedemptionDocAccessFields_createdAt_Update"] | undefined,
	delete?: GraphQLTypes["CheckInRedemptionDocAccessFields_createdAt_Delete"] | undefined
};
	["CheckInRedemptionDocAccessFields_createdAt_Create"]: {
	__typename: "CheckInRedemptionDocAccessFields_createdAt_Create",
	permission: boolean
};
	["CheckInRedemptionDocAccessFields_createdAt_Read"]: {
	__typename: "CheckInRedemptionDocAccessFields_createdAt_Read",
	permission: boolean
};
	["CheckInRedemptionDocAccessFields_createdAt_Update"]: {
	__typename: "CheckInRedemptionDocAccessFields_createdAt_Update",
	permission: boolean
};
	["CheckInRedemptionDocAccessFields_createdAt_Delete"]: {
	__typename: "CheckInRedemptionDocAccessFields_createdAt_Delete",
	permission: boolean
};
	["CheckInRedemptionCreateDocAccess"]: {
	__typename: "CheckInRedemptionCreateDocAccess",
	permission: boolean,
	where?: GraphQLTypes["JSONObject"] | undefined
};
	["CheckInRedemptionReadDocAccess"]: {
	__typename: "CheckInRedemptionReadDocAccess",
	permission: boolean,
	where?: GraphQLTypes["JSONObject"] | undefined
};
	["CheckInRedemptionUpdateDocAccess"]: {
	__typename: "CheckInRedemptionUpdateDocAccess",
	permission: boolean,
	where?: GraphQLTypes["JSONObject"] | undefined
};
	["CheckInRedemptionDeleteDocAccess"]: {
	__typename: "CheckInRedemptionDeleteDocAccess",
	permission: boolean,
	where?: GraphQLTypes["JSONObject"] | undefined
};
	["Users"]: {
	__typename: "Users",
	docs?: Array<GraphQLTypes["User"] | undefined> | undefined,
	hasNextPage?: boolean | undefined,
	hasPrevPage?: boolean | undefined,
	limit?: number | undefined,
	nextPage?: number | undefined,
	offset?: number | undefined,
	page?: number | undefined,
	pagingCounter?: number | undefined,
	prevPage?: number | undefined,
	totalDocs?: number | undefined,
	totalPages?: number | undefined
};
	["User_where"]: {
		region?: GraphQLTypes["User_region_operator"] | undefined,
	name?: GraphQLTypes["User_name_operator"] | undefined,
	displayEmail?: GraphQLTypes["User_displayEmail_operator"] | undefined,
	legacyUsername?: GraphQLTypes["User_legacyUsername_operator"] | undefined,
	roles?: GraphQLTypes["User_roles_operator"] | undefined,
	member__region?: GraphQLTypes["User_member__region_operator"] | undefined,
	member__language?: GraphQLTypes["User_member__language_operator"] | undefined,
	member__phone?: GraphQLTypes["User_member__phone_operator"] | undefined,
	member__avatar?: GraphQLTypes["User_member__avatar_operator"] | undefined,
	member__birthday?: GraphQLTypes["User_member__birthday_operator"] | undefined,
	member__gender?: GraphQLTypes["User_member__gender_operator"] | undefined,
	member__categories?: GraphQLTypes["User_member__categories_operator"] | undefined,
	organiser__logo?: GraphQLTypes["User_organiser__logo_operator"] | undefined,
	organiser__banner?: GraphQLTypes["User_organiser__banner_operator"] | undefined,
	organiser__description?: GraphQLTypes["User_organiser__description_operator"] | undefined,
	organiser__foundedIn?: GraphQLTypes["User_organiser__foundedIn_operator"] | undefined,
	organiser__address?: GraphQLTypes["User_organiser__address_operator"] | undefined,
	organiser__website?: GraphQLTypes["User_organiser__website_operator"] | undefined,
	organiser__facebook?: GraphQLTypes["User_organiser__facebook_operator"] | undefined,
	organiser__instagram?: GraphQLTypes["User_organiser__instagram_operator"] | undefined,
	organiser__language?: GraphQLTypes["User_organiser__language_operator"] | undefined,
	organiser__phone?: GraphQLTypes["User_organiser__phone_operator"] | undefined,
	organiser__followers?: GraphQLTypes["User_organiser__followers_operator"] | undefined,
	emailVerified?: GraphQLTypes["User_emailVerified_operator"] | undefined,
	sso__googleId?: GraphQLTypes["User_sso__googleId_operator"] | undefined,
	sso__facebookId?: GraphQLTypes["User_sso__facebookId_operator"] | undefined,
	sso__appleId?: GraphQLTypes["User_sso__appleId_operator"] | undefined,
	oldPassword?: GraphQLTypes["User_oldPassword_operator"] | undefined,
	status?: GraphQLTypes["User_status_operator"] | undefined,
	updatedAt?: GraphQLTypes["User_updatedAt_operator"] | undefined,
	createdAt?: GraphQLTypes["User_createdAt_operator"] | undefined,
	email?: GraphQLTypes["User_email_operator"] | undefined,
	id?: GraphQLTypes["User_id_operator"] | undefined,
	AND?: Array<GraphQLTypes["User_where_and"] | undefined> | undefined,
	OR?: Array<GraphQLTypes["User_where_or"] | undefined> | undefined
};
	["User_region_operator"]: {
		equals?: GraphQLTypes["User_region_Input"] | undefined,
	not_equals?: GraphQLTypes["User_region_Input"] | undefined,
	in?: Array<GraphQLTypes["User_region_Input"] | undefined> | undefined,
	not_in?: Array<GraphQLTypes["User_region_Input"] | undefined> | undefined,
	all?: Array<GraphQLTypes["User_region_Input"] | undefined> | undefined,
	exists?: boolean | undefined
};
	["User_region_Input"]: User_region_Input;
	["User_name_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["User_displayEmail_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["User_legacyUsername_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["User_roles_operator"]: {
		equals?: GraphQLTypes["User_roles_Input"] | undefined,
	not_equals?: GraphQLTypes["User_roles_Input"] | undefined,
	in?: Array<GraphQLTypes["User_roles_Input"] | undefined> | undefined,
	not_in?: Array<GraphQLTypes["User_roles_Input"] | undefined> | undefined,
	all?: Array<GraphQLTypes["User_roles_Input"] | undefined> | undefined
};
	["User_roles_Input"]: User_roles_Input;
	["User_member__region_operator"]: {
		equals?: GraphQLTypes["User_member__region_Input"] | undefined,
	not_equals?: GraphQLTypes["User_member__region_Input"] | undefined,
	in?: Array<GraphQLTypes["User_member__region_Input"] | undefined> | undefined,
	not_in?: Array<GraphQLTypes["User_member__region_Input"] | undefined> | undefined,
	all?: Array<GraphQLTypes["User_member__region_Input"] | undefined> | undefined,
	exists?: boolean | undefined
};
	["User_member__region_Input"]: User_member__region_Input;
	["User_member__language_operator"]: {
		equals?: GraphQLTypes["User_member__language_Input"] | undefined,
	not_equals?: GraphQLTypes["User_member__language_Input"] | undefined,
	in?: Array<GraphQLTypes["User_member__language_Input"] | undefined> | undefined,
	not_in?: Array<GraphQLTypes["User_member__language_Input"] | undefined> | undefined,
	all?: Array<GraphQLTypes["User_member__language_Input"] | undefined> | undefined,
	exists?: boolean | undefined
};
	["User_member__language_Input"]: User_member__language_Input;
	["User_member__phone_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["User_member__avatar_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	exists?: boolean | undefined
};
	["User_member__birthday_operator"]: {
		equals?: GraphQLTypes["DateTime"] | undefined,
	not_equals?: GraphQLTypes["DateTime"] | undefined,
	greater_than_equal?: GraphQLTypes["DateTime"] | undefined,
	greater_than?: GraphQLTypes["DateTime"] | undefined,
	less_than_equal?: GraphQLTypes["DateTime"] | undefined,
	less_than?: GraphQLTypes["DateTime"] | undefined,
	like?: GraphQLTypes["DateTime"] | undefined,
	exists?: boolean | undefined
};
	["User_member__gender_operator"]: {
		equals?: GraphQLTypes["User_member__gender_Input"] | undefined,
	not_equals?: GraphQLTypes["User_member__gender_Input"] | undefined,
	in?: Array<GraphQLTypes["User_member__gender_Input"] | undefined> | undefined,
	not_in?: Array<GraphQLTypes["User_member__gender_Input"] | undefined> | undefined,
	all?: Array<GraphQLTypes["User_member__gender_Input"] | undefined> | undefined,
	exists?: boolean | undefined
};
	["User_member__gender_Input"]: User_member__gender_Input;
	["User_member__categories_operator"]: {
		equals?: GraphQLTypes["JSON"] | undefined,
	not_equals?: GraphQLTypes["JSON"] | undefined,
	in?: Array<GraphQLTypes["JSON"] | undefined> | undefined,
	not_in?: Array<GraphQLTypes["JSON"] | undefined> | undefined,
	all?: Array<GraphQLTypes["JSON"] | undefined> | undefined,
	exists?: boolean | undefined
};
	["User_organiser__logo_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	exists?: boolean | undefined
};
	["User_organiser__banner_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	exists?: boolean | undefined
};
	["User_organiser__description_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	exists?: boolean | undefined
};
	["User_organiser__foundedIn_operator"]: {
		equals?: GraphQLTypes["DateTime"] | undefined,
	not_equals?: GraphQLTypes["DateTime"] | undefined,
	greater_than_equal?: GraphQLTypes["DateTime"] | undefined,
	greater_than?: GraphQLTypes["DateTime"] | undefined,
	less_than_equal?: GraphQLTypes["DateTime"] | undefined,
	less_than?: GraphQLTypes["DateTime"] | undefined,
	like?: GraphQLTypes["DateTime"] | undefined,
	exists?: boolean | undefined
};
	["User_organiser__address_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["User_organiser__website_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["User_organiser__facebook_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["User_organiser__instagram_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["User_organiser__language_operator"]: {
		equals?: GraphQLTypes["User_organiser__language_Input"] | undefined,
	not_equals?: GraphQLTypes["User_organiser__language_Input"] | undefined,
	in?: Array<GraphQLTypes["User_organiser__language_Input"] | undefined> | undefined,
	not_in?: Array<GraphQLTypes["User_organiser__language_Input"] | undefined> | undefined,
	all?: Array<GraphQLTypes["User_organiser__language_Input"] | undefined> | undefined,
	exists?: boolean | undefined
};
	["User_organiser__language_Input"]: User_organiser__language_Input;
	["User_organiser__phone_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["User_organiser__followers_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["User_emailVerified_operator"]: {
		equals?: boolean | undefined,
	not_equals?: boolean | undefined,
	exists?: boolean | undefined
};
	["User_sso__googleId_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["User_sso__facebookId_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["User_sso__appleId_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["User_oldPassword_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["User_status_operator"]: {
		equals?: GraphQLTypes["User_status_Input"] | undefined,
	not_equals?: GraphQLTypes["User_status_Input"] | undefined,
	in?: Array<GraphQLTypes["User_status_Input"] | undefined> | undefined,
	not_in?: Array<GraphQLTypes["User_status_Input"] | undefined> | undefined,
	all?: Array<GraphQLTypes["User_status_Input"] | undefined> | undefined,
	exists?: boolean | undefined
};
	["User_status_Input"]: User_status_Input;
	["User_updatedAt_operator"]: {
		equals?: GraphQLTypes["DateTime"] | undefined,
	not_equals?: GraphQLTypes["DateTime"] | undefined,
	greater_than_equal?: GraphQLTypes["DateTime"] | undefined,
	greater_than?: GraphQLTypes["DateTime"] | undefined,
	less_than_equal?: GraphQLTypes["DateTime"] | undefined,
	less_than?: GraphQLTypes["DateTime"] | undefined,
	like?: GraphQLTypes["DateTime"] | undefined,
	exists?: boolean | undefined
};
	["User_createdAt_operator"]: {
		equals?: GraphQLTypes["DateTime"] | undefined,
	not_equals?: GraphQLTypes["DateTime"] | undefined,
	greater_than_equal?: GraphQLTypes["DateTime"] | undefined,
	greater_than?: GraphQLTypes["DateTime"] | undefined,
	less_than_equal?: GraphQLTypes["DateTime"] | undefined,
	less_than?: GraphQLTypes["DateTime"] | undefined,
	like?: GraphQLTypes["DateTime"] | undefined,
	exists?: boolean | undefined
};
	["User_email_operator"]: {
		equals?: GraphQLTypes["EmailAddress"] | undefined,
	not_equals?: GraphQLTypes["EmailAddress"] | undefined,
	like?: GraphQLTypes["EmailAddress"] | undefined,
	contains?: GraphQLTypes["EmailAddress"] | undefined,
	in?: Array<GraphQLTypes["EmailAddress"] | undefined> | undefined,
	not_in?: Array<GraphQLTypes["EmailAddress"] | undefined> | undefined,
	all?: Array<GraphQLTypes["EmailAddress"] | undefined> | undefined
};
	["User_id_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["User_where_and"]: {
		region?: GraphQLTypes["User_region_operator"] | undefined,
	name?: GraphQLTypes["User_name_operator"] | undefined,
	displayEmail?: GraphQLTypes["User_displayEmail_operator"] | undefined,
	legacyUsername?: GraphQLTypes["User_legacyUsername_operator"] | undefined,
	roles?: GraphQLTypes["User_roles_operator"] | undefined,
	member__region?: GraphQLTypes["User_member__region_operator"] | undefined,
	member__language?: GraphQLTypes["User_member__language_operator"] | undefined,
	member__phone?: GraphQLTypes["User_member__phone_operator"] | undefined,
	member__avatar?: GraphQLTypes["User_member__avatar_operator"] | undefined,
	member__birthday?: GraphQLTypes["User_member__birthday_operator"] | undefined,
	member__gender?: GraphQLTypes["User_member__gender_operator"] | undefined,
	member__categories?: GraphQLTypes["User_member__categories_operator"] | undefined,
	organiser__logo?: GraphQLTypes["User_organiser__logo_operator"] | undefined,
	organiser__banner?: GraphQLTypes["User_organiser__banner_operator"] | undefined,
	organiser__description?: GraphQLTypes["User_organiser__description_operator"] | undefined,
	organiser__foundedIn?: GraphQLTypes["User_organiser__foundedIn_operator"] | undefined,
	organiser__address?: GraphQLTypes["User_organiser__address_operator"] | undefined,
	organiser__website?: GraphQLTypes["User_organiser__website_operator"] | undefined,
	organiser__facebook?: GraphQLTypes["User_organiser__facebook_operator"] | undefined,
	organiser__instagram?: GraphQLTypes["User_organiser__instagram_operator"] | undefined,
	organiser__language?: GraphQLTypes["User_organiser__language_operator"] | undefined,
	organiser__phone?: GraphQLTypes["User_organiser__phone_operator"] | undefined,
	organiser__followers?: GraphQLTypes["User_organiser__followers_operator"] | undefined,
	emailVerified?: GraphQLTypes["User_emailVerified_operator"] | undefined,
	sso__googleId?: GraphQLTypes["User_sso__googleId_operator"] | undefined,
	sso__facebookId?: GraphQLTypes["User_sso__facebookId_operator"] | undefined,
	sso__appleId?: GraphQLTypes["User_sso__appleId_operator"] | undefined,
	oldPassword?: GraphQLTypes["User_oldPassword_operator"] | undefined,
	status?: GraphQLTypes["User_status_operator"] | undefined,
	updatedAt?: GraphQLTypes["User_updatedAt_operator"] | undefined,
	createdAt?: GraphQLTypes["User_createdAt_operator"] | undefined,
	email?: GraphQLTypes["User_email_operator"] | undefined,
	id?: GraphQLTypes["User_id_operator"] | undefined,
	AND?: Array<GraphQLTypes["User_where_and"] | undefined> | undefined,
	OR?: Array<GraphQLTypes["User_where_or"] | undefined> | undefined
};
	["User_where_or"]: {
		region?: GraphQLTypes["User_region_operator"] | undefined,
	name?: GraphQLTypes["User_name_operator"] | undefined,
	displayEmail?: GraphQLTypes["User_displayEmail_operator"] | undefined,
	legacyUsername?: GraphQLTypes["User_legacyUsername_operator"] | undefined,
	roles?: GraphQLTypes["User_roles_operator"] | undefined,
	member__region?: GraphQLTypes["User_member__region_operator"] | undefined,
	member__language?: GraphQLTypes["User_member__language_operator"] | undefined,
	member__phone?: GraphQLTypes["User_member__phone_operator"] | undefined,
	member__avatar?: GraphQLTypes["User_member__avatar_operator"] | undefined,
	member__birthday?: GraphQLTypes["User_member__birthday_operator"] | undefined,
	member__gender?: GraphQLTypes["User_member__gender_operator"] | undefined,
	member__categories?: GraphQLTypes["User_member__categories_operator"] | undefined,
	organiser__logo?: GraphQLTypes["User_organiser__logo_operator"] | undefined,
	organiser__banner?: GraphQLTypes["User_organiser__banner_operator"] | undefined,
	organiser__description?: GraphQLTypes["User_organiser__description_operator"] | undefined,
	organiser__foundedIn?: GraphQLTypes["User_organiser__foundedIn_operator"] | undefined,
	organiser__address?: GraphQLTypes["User_organiser__address_operator"] | undefined,
	organiser__website?: GraphQLTypes["User_organiser__website_operator"] | undefined,
	organiser__facebook?: GraphQLTypes["User_organiser__facebook_operator"] | undefined,
	organiser__instagram?: GraphQLTypes["User_organiser__instagram_operator"] | undefined,
	organiser__language?: GraphQLTypes["User_organiser__language_operator"] | undefined,
	organiser__phone?: GraphQLTypes["User_organiser__phone_operator"] | undefined,
	organiser__followers?: GraphQLTypes["User_organiser__followers_operator"] | undefined,
	emailVerified?: GraphQLTypes["User_emailVerified_operator"] | undefined,
	sso__googleId?: GraphQLTypes["User_sso__googleId_operator"] | undefined,
	sso__facebookId?: GraphQLTypes["User_sso__facebookId_operator"] | undefined,
	sso__appleId?: GraphQLTypes["User_sso__appleId_operator"] | undefined,
	oldPassword?: GraphQLTypes["User_oldPassword_operator"] | undefined,
	status?: GraphQLTypes["User_status_operator"] | undefined,
	updatedAt?: GraphQLTypes["User_updatedAt_operator"] | undefined,
	createdAt?: GraphQLTypes["User_createdAt_operator"] | undefined,
	email?: GraphQLTypes["User_email_operator"] | undefined,
	id?: GraphQLTypes["User_id_operator"] | undefined,
	AND?: Array<GraphQLTypes["User_where_and"] | undefined> | undefined,
	OR?: Array<GraphQLTypes["User_where_or"] | undefined> | undefined
};
	["countUsers"]: {
	__typename: "countUsers",
	totalDocs?: number | undefined
};
	["usersDocAccess"]: {
	__typename: "usersDocAccess",
	fields?: GraphQLTypes["UsersDocAccessFields"] | undefined,
	create?: GraphQLTypes["UsersCreateDocAccess"] | undefined,
	read?: GraphQLTypes["UsersReadDocAccess"] | undefined,
	update?: GraphQLTypes["UsersUpdateDocAccess"] | undefined,
	delete?: GraphQLTypes["UsersDeleteDocAccess"] | undefined,
	unlock?: GraphQLTypes["UsersUnlockDocAccess"] | undefined
};
	["UsersDocAccessFields"]: {
	__typename: "UsersDocAccessFields",
	region?: GraphQLTypes["UsersDocAccessFields_region"] | undefined,
	name?: GraphQLTypes["UsersDocAccessFields_name"] | undefined,
	displayEmail?: GraphQLTypes["UsersDocAccessFields_displayEmail"] | undefined,
	legacyUsername?: GraphQLTypes["UsersDocAccessFields_legacyUsername"] | undefined,
	roles?: GraphQLTypes["UsersDocAccessFields_roles"] | undefined,
	member?: GraphQLTypes["UsersDocAccessFields_member"] | undefined,
	organiser?: GraphQLTypes["UsersDocAccessFields_organiser"] | undefined,
	emailVerified?: GraphQLTypes["UsersDocAccessFields_emailVerified"] | undefined,
	sso?: GraphQLTypes["UsersDocAccessFields_sso"] | undefined,
	oldPassword?: GraphQLTypes["UsersDocAccessFields_oldPassword"] | undefined,
	status?: GraphQLTypes["UsersDocAccessFields_status"] | undefined,
	updatedAt?: GraphQLTypes["UsersDocAccessFields_updatedAt"] | undefined,
	createdAt?: GraphQLTypes["UsersDocAccessFields_createdAt"] | undefined,
	email?: GraphQLTypes["UsersDocAccessFields_email"] | undefined,
	password?: GraphQLTypes["UsersDocAccessFields_password"] | undefined
};
	["UsersDocAccessFields_region"]: {
	__typename: "UsersDocAccessFields_region",
	create?: GraphQLTypes["UsersDocAccessFields_region_Create"] | undefined,
	read?: GraphQLTypes["UsersDocAccessFields_region_Read"] | undefined,
	update?: GraphQLTypes["UsersDocAccessFields_region_Update"] | undefined,
	delete?: GraphQLTypes["UsersDocAccessFields_region_Delete"] | undefined
};
	["UsersDocAccessFields_region_Create"]: {
	__typename: "UsersDocAccessFields_region_Create",
	permission: boolean
};
	["UsersDocAccessFields_region_Read"]: {
	__typename: "UsersDocAccessFields_region_Read",
	permission: boolean
};
	["UsersDocAccessFields_region_Update"]: {
	__typename: "UsersDocAccessFields_region_Update",
	permission: boolean
};
	["UsersDocAccessFields_region_Delete"]: {
	__typename: "UsersDocAccessFields_region_Delete",
	permission: boolean
};
	["UsersDocAccessFields_name"]: {
	__typename: "UsersDocAccessFields_name",
	create?: GraphQLTypes["UsersDocAccessFields_name_Create"] | undefined,
	read?: GraphQLTypes["UsersDocAccessFields_name_Read"] | undefined,
	update?: GraphQLTypes["UsersDocAccessFields_name_Update"] | undefined,
	delete?: GraphQLTypes["UsersDocAccessFields_name_Delete"] | undefined
};
	["UsersDocAccessFields_name_Create"]: {
	__typename: "UsersDocAccessFields_name_Create",
	permission: boolean
};
	["UsersDocAccessFields_name_Read"]: {
	__typename: "UsersDocAccessFields_name_Read",
	permission: boolean
};
	["UsersDocAccessFields_name_Update"]: {
	__typename: "UsersDocAccessFields_name_Update",
	permission: boolean
};
	["UsersDocAccessFields_name_Delete"]: {
	__typename: "UsersDocAccessFields_name_Delete",
	permission: boolean
};
	["UsersDocAccessFields_displayEmail"]: {
	__typename: "UsersDocAccessFields_displayEmail",
	create?: GraphQLTypes["UsersDocAccessFields_displayEmail_Create"] | undefined,
	read?: GraphQLTypes["UsersDocAccessFields_displayEmail_Read"] | undefined,
	update?: GraphQLTypes["UsersDocAccessFields_displayEmail_Update"] | undefined,
	delete?: GraphQLTypes["UsersDocAccessFields_displayEmail_Delete"] | undefined
};
	["UsersDocAccessFields_displayEmail_Create"]: {
	__typename: "UsersDocAccessFields_displayEmail_Create",
	permission: boolean
};
	["UsersDocAccessFields_displayEmail_Read"]: {
	__typename: "UsersDocAccessFields_displayEmail_Read",
	permission: boolean
};
	["UsersDocAccessFields_displayEmail_Update"]: {
	__typename: "UsersDocAccessFields_displayEmail_Update",
	permission: boolean
};
	["UsersDocAccessFields_displayEmail_Delete"]: {
	__typename: "UsersDocAccessFields_displayEmail_Delete",
	permission: boolean
};
	["UsersDocAccessFields_legacyUsername"]: {
	__typename: "UsersDocAccessFields_legacyUsername",
	create?: GraphQLTypes["UsersDocAccessFields_legacyUsername_Create"] | undefined,
	read?: GraphQLTypes["UsersDocAccessFields_legacyUsername_Read"] | undefined,
	update?: GraphQLTypes["UsersDocAccessFields_legacyUsername_Update"] | undefined,
	delete?: GraphQLTypes["UsersDocAccessFields_legacyUsername_Delete"] | undefined
};
	["UsersDocAccessFields_legacyUsername_Create"]: {
	__typename: "UsersDocAccessFields_legacyUsername_Create",
	permission: boolean
};
	["UsersDocAccessFields_legacyUsername_Read"]: {
	__typename: "UsersDocAccessFields_legacyUsername_Read",
	permission: boolean
};
	["UsersDocAccessFields_legacyUsername_Update"]: {
	__typename: "UsersDocAccessFields_legacyUsername_Update",
	permission: boolean
};
	["UsersDocAccessFields_legacyUsername_Delete"]: {
	__typename: "UsersDocAccessFields_legacyUsername_Delete",
	permission: boolean
};
	["UsersDocAccessFields_roles"]: {
	__typename: "UsersDocAccessFields_roles",
	create?: GraphQLTypes["UsersDocAccessFields_roles_Create"] | undefined,
	read?: GraphQLTypes["UsersDocAccessFields_roles_Read"] | undefined,
	update?: GraphQLTypes["UsersDocAccessFields_roles_Update"] | undefined,
	delete?: GraphQLTypes["UsersDocAccessFields_roles_Delete"] | undefined
};
	["UsersDocAccessFields_roles_Create"]: {
	__typename: "UsersDocAccessFields_roles_Create",
	permission: boolean
};
	["UsersDocAccessFields_roles_Read"]: {
	__typename: "UsersDocAccessFields_roles_Read",
	permission: boolean
};
	["UsersDocAccessFields_roles_Update"]: {
	__typename: "UsersDocAccessFields_roles_Update",
	permission: boolean
};
	["UsersDocAccessFields_roles_Delete"]: {
	__typename: "UsersDocAccessFields_roles_Delete",
	permission: boolean
};
	["UsersDocAccessFields_member"]: {
	__typename: "UsersDocAccessFields_member",
	create?: GraphQLTypes["UsersDocAccessFields_member_Create"] | undefined,
	read?: GraphQLTypes["UsersDocAccessFields_member_Read"] | undefined,
	update?: GraphQLTypes["UsersDocAccessFields_member_Update"] | undefined,
	delete?: GraphQLTypes["UsersDocAccessFields_member_Delete"] | undefined,
	fields?: GraphQLTypes["UsersDocAccessFields_member_Fields"] | undefined
};
	["UsersDocAccessFields_member_Create"]: {
	__typename: "UsersDocAccessFields_member_Create",
	permission: boolean
};
	["UsersDocAccessFields_member_Read"]: {
	__typename: "UsersDocAccessFields_member_Read",
	permission: boolean
};
	["UsersDocAccessFields_member_Update"]: {
	__typename: "UsersDocAccessFields_member_Update",
	permission: boolean
};
	["UsersDocAccessFields_member_Delete"]: {
	__typename: "UsersDocAccessFields_member_Delete",
	permission: boolean
};
	["UsersDocAccessFields_member_Fields"]: {
	__typename: "UsersDocAccessFields_member_Fields",
	region?: GraphQLTypes["UsersDocAccessFields_member_region"] | undefined,
	language?: GraphQLTypes["UsersDocAccessFields_member_language"] | undefined,
	phone?: GraphQLTypes["UsersDocAccessFields_member_phone"] | undefined,
	avatar?: GraphQLTypes["UsersDocAccessFields_member_avatar"] | undefined,
	birthday?: GraphQLTypes["UsersDocAccessFields_member_birthday"] | undefined,
	gender?: GraphQLTypes["UsersDocAccessFields_member_gender"] | undefined,
	categories?: GraphQLTypes["UsersDocAccessFields_member_categories"] | undefined
};
	["UsersDocAccessFields_member_region"]: {
	__typename: "UsersDocAccessFields_member_region",
	create?: GraphQLTypes["UsersDocAccessFields_member_region_Create"] | undefined,
	read?: GraphQLTypes["UsersDocAccessFields_member_region_Read"] | undefined,
	update?: GraphQLTypes["UsersDocAccessFields_member_region_Update"] | undefined,
	delete?: GraphQLTypes["UsersDocAccessFields_member_region_Delete"] | undefined
};
	["UsersDocAccessFields_member_region_Create"]: {
	__typename: "UsersDocAccessFields_member_region_Create",
	permission: boolean
};
	["UsersDocAccessFields_member_region_Read"]: {
	__typename: "UsersDocAccessFields_member_region_Read",
	permission: boolean
};
	["UsersDocAccessFields_member_region_Update"]: {
	__typename: "UsersDocAccessFields_member_region_Update",
	permission: boolean
};
	["UsersDocAccessFields_member_region_Delete"]: {
	__typename: "UsersDocAccessFields_member_region_Delete",
	permission: boolean
};
	["UsersDocAccessFields_member_language"]: {
	__typename: "UsersDocAccessFields_member_language",
	create?: GraphQLTypes["UsersDocAccessFields_member_language_Create"] | undefined,
	read?: GraphQLTypes["UsersDocAccessFields_member_language_Read"] | undefined,
	update?: GraphQLTypes["UsersDocAccessFields_member_language_Update"] | undefined,
	delete?: GraphQLTypes["UsersDocAccessFields_member_language_Delete"] | undefined
};
	["UsersDocAccessFields_member_language_Create"]: {
	__typename: "UsersDocAccessFields_member_language_Create",
	permission: boolean
};
	["UsersDocAccessFields_member_language_Read"]: {
	__typename: "UsersDocAccessFields_member_language_Read",
	permission: boolean
};
	["UsersDocAccessFields_member_language_Update"]: {
	__typename: "UsersDocAccessFields_member_language_Update",
	permission: boolean
};
	["UsersDocAccessFields_member_language_Delete"]: {
	__typename: "UsersDocAccessFields_member_language_Delete",
	permission: boolean
};
	["UsersDocAccessFields_member_phone"]: {
	__typename: "UsersDocAccessFields_member_phone",
	create?: GraphQLTypes["UsersDocAccessFields_member_phone_Create"] | undefined,
	read?: GraphQLTypes["UsersDocAccessFields_member_phone_Read"] | undefined,
	update?: GraphQLTypes["UsersDocAccessFields_member_phone_Update"] | undefined,
	delete?: GraphQLTypes["UsersDocAccessFields_member_phone_Delete"] | undefined
};
	["UsersDocAccessFields_member_phone_Create"]: {
	__typename: "UsersDocAccessFields_member_phone_Create",
	permission: boolean
};
	["UsersDocAccessFields_member_phone_Read"]: {
	__typename: "UsersDocAccessFields_member_phone_Read",
	permission: boolean
};
	["UsersDocAccessFields_member_phone_Update"]: {
	__typename: "UsersDocAccessFields_member_phone_Update",
	permission: boolean
};
	["UsersDocAccessFields_member_phone_Delete"]: {
	__typename: "UsersDocAccessFields_member_phone_Delete",
	permission: boolean
};
	["UsersDocAccessFields_member_avatar"]: {
	__typename: "UsersDocAccessFields_member_avatar",
	create?: GraphQLTypes["UsersDocAccessFields_member_avatar_Create"] | undefined,
	read?: GraphQLTypes["UsersDocAccessFields_member_avatar_Read"] | undefined,
	update?: GraphQLTypes["UsersDocAccessFields_member_avatar_Update"] | undefined,
	delete?: GraphQLTypes["UsersDocAccessFields_member_avatar_Delete"] | undefined
};
	["UsersDocAccessFields_member_avatar_Create"]: {
	__typename: "UsersDocAccessFields_member_avatar_Create",
	permission: boolean
};
	["UsersDocAccessFields_member_avatar_Read"]: {
	__typename: "UsersDocAccessFields_member_avatar_Read",
	permission: boolean
};
	["UsersDocAccessFields_member_avatar_Update"]: {
	__typename: "UsersDocAccessFields_member_avatar_Update",
	permission: boolean
};
	["UsersDocAccessFields_member_avatar_Delete"]: {
	__typename: "UsersDocAccessFields_member_avatar_Delete",
	permission: boolean
};
	["UsersDocAccessFields_member_birthday"]: {
	__typename: "UsersDocAccessFields_member_birthday",
	create?: GraphQLTypes["UsersDocAccessFields_member_birthday_Create"] | undefined,
	read?: GraphQLTypes["UsersDocAccessFields_member_birthday_Read"] | undefined,
	update?: GraphQLTypes["UsersDocAccessFields_member_birthday_Update"] | undefined,
	delete?: GraphQLTypes["UsersDocAccessFields_member_birthday_Delete"] | undefined
};
	["UsersDocAccessFields_member_birthday_Create"]: {
	__typename: "UsersDocAccessFields_member_birthday_Create",
	permission: boolean
};
	["UsersDocAccessFields_member_birthday_Read"]: {
	__typename: "UsersDocAccessFields_member_birthday_Read",
	permission: boolean
};
	["UsersDocAccessFields_member_birthday_Update"]: {
	__typename: "UsersDocAccessFields_member_birthday_Update",
	permission: boolean
};
	["UsersDocAccessFields_member_birthday_Delete"]: {
	__typename: "UsersDocAccessFields_member_birthday_Delete",
	permission: boolean
};
	["UsersDocAccessFields_member_gender"]: {
	__typename: "UsersDocAccessFields_member_gender",
	create?: GraphQLTypes["UsersDocAccessFields_member_gender_Create"] | undefined,
	read?: GraphQLTypes["UsersDocAccessFields_member_gender_Read"] | undefined,
	update?: GraphQLTypes["UsersDocAccessFields_member_gender_Update"] | undefined,
	delete?: GraphQLTypes["UsersDocAccessFields_member_gender_Delete"] | undefined
};
	["UsersDocAccessFields_member_gender_Create"]: {
	__typename: "UsersDocAccessFields_member_gender_Create",
	permission: boolean
};
	["UsersDocAccessFields_member_gender_Read"]: {
	__typename: "UsersDocAccessFields_member_gender_Read",
	permission: boolean
};
	["UsersDocAccessFields_member_gender_Update"]: {
	__typename: "UsersDocAccessFields_member_gender_Update",
	permission: boolean
};
	["UsersDocAccessFields_member_gender_Delete"]: {
	__typename: "UsersDocAccessFields_member_gender_Delete",
	permission: boolean
};
	["UsersDocAccessFields_member_categories"]: {
	__typename: "UsersDocAccessFields_member_categories",
	create?: GraphQLTypes["UsersDocAccessFields_member_categories_Create"] | undefined,
	read?: GraphQLTypes["UsersDocAccessFields_member_categories_Read"] | undefined,
	update?: GraphQLTypes["UsersDocAccessFields_member_categories_Update"] | undefined,
	delete?: GraphQLTypes["UsersDocAccessFields_member_categories_Delete"] | undefined
};
	["UsersDocAccessFields_member_categories_Create"]: {
	__typename: "UsersDocAccessFields_member_categories_Create",
	permission: boolean
};
	["UsersDocAccessFields_member_categories_Read"]: {
	__typename: "UsersDocAccessFields_member_categories_Read",
	permission: boolean
};
	["UsersDocAccessFields_member_categories_Update"]: {
	__typename: "UsersDocAccessFields_member_categories_Update",
	permission: boolean
};
	["UsersDocAccessFields_member_categories_Delete"]: {
	__typename: "UsersDocAccessFields_member_categories_Delete",
	permission: boolean
};
	["UsersDocAccessFields_organiser"]: {
	__typename: "UsersDocAccessFields_organiser",
	create?: GraphQLTypes["UsersDocAccessFields_organiser_Create"] | undefined,
	read?: GraphQLTypes["UsersDocAccessFields_organiser_Read"] | undefined,
	update?: GraphQLTypes["UsersDocAccessFields_organiser_Update"] | undefined,
	delete?: GraphQLTypes["UsersDocAccessFields_organiser_Delete"] | undefined,
	fields?: GraphQLTypes["UsersDocAccessFields_organiser_Fields"] | undefined
};
	["UsersDocAccessFields_organiser_Create"]: {
	__typename: "UsersDocAccessFields_organiser_Create",
	permission: boolean
};
	["UsersDocAccessFields_organiser_Read"]: {
	__typename: "UsersDocAccessFields_organiser_Read",
	permission: boolean
};
	["UsersDocAccessFields_organiser_Update"]: {
	__typename: "UsersDocAccessFields_organiser_Update",
	permission: boolean
};
	["UsersDocAccessFields_organiser_Delete"]: {
	__typename: "UsersDocAccessFields_organiser_Delete",
	permission: boolean
};
	["UsersDocAccessFields_organiser_Fields"]: {
	__typename: "UsersDocAccessFields_organiser_Fields",
	logo?: GraphQLTypes["UsersDocAccessFields_organiser_logo"] | undefined,
	banner?: GraphQLTypes["UsersDocAccessFields_organiser_banner"] | undefined,
	description?: GraphQLTypes["UsersDocAccessFields_organiser_description"] | undefined,
	foundedIn?: GraphQLTypes["UsersDocAccessFields_organiser_foundedIn"] | undefined,
	address?: GraphQLTypes["UsersDocAccessFields_organiser_address"] | undefined,
	website?: GraphQLTypes["UsersDocAccessFields_organiser_website"] | undefined,
	facebook?: GraphQLTypes["UsersDocAccessFields_organiser_facebook"] | undefined,
	instagram?: GraphQLTypes["UsersDocAccessFields_organiser_instagram"] | undefined,
	language?: GraphQLTypes["UsersDocAccessFields_organiser_language"] | undefined,
	phone?: GraphQLTypes["UsersDocAccessFields_organiser_phone"] | undefined,
	followers?: GraphQLTypes["UsersDocAccessFields_organiser_followers"] | undefined
};
	["UsersDocAccessFields_organiser_logo"]: {
	__typename: "UsersDocAccessFields_organiser_logo",
	create?: GraphQLTypes["UsersDocAccessFields_organiser_logo_Create"] | undefined,
	read?: GraphQLTypes["UsersDocAccessFields_organiser_logo_Read"] | undefined,
	update?: GraphQLTypes["UsersDocAccessFields_organiser_logo_Update"] | undefined,
	delete?: GraphQLTypes["UsersDocAccessFields_organiser_logo_Delete"] | undefined
};
	["UsersDocAccessFields_organiser_logo_Create"]: {
	__typename: "UsersDocAccessFields_organiser_logo_Create",
	permission: boolean
};
	["UsersDocAccessFields_organiser_logo_Read"]: {
	__typename: "UsersDocAccessFields_organiser_logo_Read",
	permission: boolean
};
	["UsersDocAccessFields_organiser_logo_Update"]: {
	__typename: "UsersDocAccessFields_organiser_logo_Update",
	permission: boolean
};
	["UsersDocAccessFields_organiser_logo_Delete"]: {
	__typename: "UsersDocAccessFields_organiser_logo_Delete",
	permission: boolean
};
	["UsersDocAccessFields_organiser_banner"]: {
	__typename: "UsersDocAccessFields_organiser_banner",
	create?: GraphQLTypes["UsersDocAccessFields_organiser_banner_Create"] | undefined,
	read?: GraphQLTypes["UsersDocAccessFields_organiser_banner_Read"] | undefined,
	update?: GraphQLTypes["UsersDocAccessFields_organiser_banner_Update"] | undefined,
	delete?: GraphQLTypes["UsersDocAccessFields_organiser_banner_Delete"] | undefined
};
	["UsersDocAccessFields_organiser_banner_Create"]: {
	__typename: "UsersDocAccessFields_organiser_banner_Create",
	permission: boolean
};
	["UsersDocAccessFields_organiser_banner_Read"]: {
	__typename: "UsersDocAccessFields_organiser_banner_Read",
	permission: boolean
};
	["UsersDocAccessFields_organiser_banner_Update"]: {
	__typename: "UsersDocAccessFields_organiser_banner_Update",
	permission: boolean
};
	["UsersDocAccessFields_organiser_banner_Delete"]: {
	__typename: "UsersDocAccessFields_organiser_banner_Delete",
	permission: boolean
};
	["UsersDocAccessFields_organiser_description"]: {
	__typename: "UsersDocAccessFields_organiser_description",
	create?: GraphQLTypes["UsersDocAccessFields_organiser_description_Create"] | undefined,
	read?: GraphQLTypes["UsersDocAccessFields_organiser_description_Read"] | undefined,
	update?: GraphQLTypes["UsersDocAccessFields_organiser_description_Update"] | undefined,
	delete?: GraphQLTypes["UsersDocAccessFields_organiser_description_Delete"] | undefined
};
	["UsersDocAccessFields_organiser_description_Create"]: {
	__typename: "UsersDocAccessFields_organiser_description_Create",
	permission: boolean
};
	["UsersDocAccessFields_organiser_description_Read"]: {
	__typename: "UsersDocAccessFields_organiser_description_Read",
	permission: boolean
};
	["UsersDocAccessFields_organiser_description_Update"]: {
	__typename: "UsersDocAccessFields_organiser_description_Update",
	permission: boolean
};
	["UsersDocAccessFields_organiser_description_Delete"]: {
	__typename: "UsersDocAccessFields_organiser_description_Delete",
	permission: boolean
};
	["UsersDocAccessFields_organiser_foundedIn"]: {
	__typename: "UsersDocAccessFields_organiser_foundedIn",
	create?: GraphQLTypes["UsersDocAccessFields_organiser_foundedIn_Create"] | undefined,
	read?: GraphQLTypes["UsersDocAccessFields_organiser_foundedIn_Read"] | undefined,
	update?: GraphQLTypes["UsersDocAccessFields_organiser_foundedIn_Update"] | undefined,
	delete?: GraphQLTypes["UsersDocAccessFields_organiser_foundedIn_Delete"] | undefined
};
	["UsersDocAccessFields_organiser_foundedIn_Create"]: {
	__typename: "UsersDocAccessFields_organiser_foundedIn_Create",
	permission: boolean
};
	["UsersDocAccessFields_organiser_foundedIn_Read"]: {
	__typename: "UsersDocAccessFields_organiser_foundedIn_Read",
	permission: boolean
};
	["UsersDocAccessFields_organiser_foundedIn_Update"]: {
	__typename: "UsersDocAccessFields_organiser_foundedIn_Update",
	permission: boolean
};
	["UsersDocAccessFields_organiser_foundedIn_Delete"]: {
	__typename: "UsersDocAccessFields_organiser_foundedIn_Delete",
	permission: boolean
};
	["UsersDocAccessFields_organiser_address"]: {
	__typename: "UsersDocAccessFields_organiser_address",
	create?: GraphQLTypes["UsersDocAccessFields_organiser_address_Create"] | undefined,
	read?: GraphQLTypes["UsersDocAccessFields_organiser_address_Read"] | undefined,
	update?: GraphQLTypes["UsersDocAccessFields_organiser_address_Update"] | undefined,
	delete?: GraphQLTypes["UsersDocAccessFields_organiser_address_Delete"] | undefined
};
	["UsersDocAccessFields_organiser_address_Create"]: {
	__typename: "UsersDocAccessFields_organiser_address_Create",
	permission: boolean
};
	["UsersDocAccessFields_organiser_address_Read"]: {
	__typename: "UsersDocAccessFields_organiser_address_Read",
	permission: boolean
};
	["UsersDocAccessFields_organiser_address_Update"]: {
	__typename: "UsersDocAccessFields_organiser_address_Update",
	permission: boolean
};
	["UsersDocAccessFields_organiser_address_Delete"]: {
	__typename: "UsersDocAccessFields_organiser_address_Delete",
	permission: boolean
};
	["UsersDocAccessFields_organiser_website"]: {
	__typename: "UsersDocAccessFields_organiser_website",
	create?: GraphQLTypes["UsersDocAccessFields_organiser_website_Create"] | undefined,
	read?: GraphQLTypes["UsersDocAccessFields_organiser_website_Read"] | undefined,
	update?: GraphQLTypes["UsersDocAccessFields_organiser_website_Update"] | undefined,
	delete?: GraphQLTypes["UsersDocAccessFields_organiser_website_Delete"] | undefined
};
	["UsersDocAccessFields_organiser_website_Create"]: {
	__typename: "UsersDocAccessFields_organiser_website_Create",
	permission: boolean
};
	["UsersDocAccessFields_organiser_website_Read"]: {
	__typename: "UsersDocAccessFields_organiser_website_Read",
	permission: boolean
};
	["UsersDocAccessFields_organiser_website_Update"]: {
	__typename: "UsersDocAccessFields_organiser_website_Update",
	permission: boolean
};
	["UsersDocAccessFields_organiser_website_Delete"]: {
	__typename: "UsersDocAccessFields_organiser_website_Delete",
	permission: boolean
};
	["UsersDocAccessFields_organiser_facebook"]: {
	__typename: "UsersDocAccessFields_organiser_facebook",
	create?: GraphQLTypes["UsersDocAccessFields_organiser_facebook_Create"] | undefined,
	read?: GraphQLTypes["UsersDocAccessFields_organiser_facebook_Read"] | undefined,
	update?: GraphQLTypes["UsersDocAccessFields_organiser_facebook_Update"] | undefined,
	delete?: GraphQLTypes["UsersDocAccessFields_organiser_facebook_Delete"] | undefined
};
	["UsersDocAccessFields_organiser_facebook_Create"]: {
	__typename: "UsersDocAccessFields_organiser_facebook_Create",
	permission: boolean
};
	["UsersDocAccessFields_organiser_facebook_Read"]: {
	__typename: "UsersDocAccessFields_organiser_facebook_Read",
	permission: boolean
};
	["UsersDocAccessFields_organiser_facebook_Update"]: {
	__typename: "UsersDocAccessFields_organiser_facebook_Update",
	permission: boolean
};
	["UsersDocAccessFields_organiser_facebook_Delete"]: {
	__typename: "UsersDocAccessFields_organiser_facebook_Delete",
	permission: boolean
};
	["UsersDocAccessFields_organiser_instagram"]: {
	__typename: "UsersDocAccessFields_organiser_instagram",
	create?: GraphQLTypes["UsersDocAccessFields_organiser_instagram_Create"] | undefined,
	read?: GraphQLTypes["UsersDocAccessFields_organiser_instagram_Read"] | undefined,
	update?: GraphQLTypes["UsersDocAccessFields_organiser_instagram_Update"] | undefined,
	delete?: GraphQLTypes["UsersDocAccessFields_organiser_instagram_Delete"] | undefined
};
	["UsersDocAccessFields_organiser_instagram_Create"]: {
	__typename: "UsersDocAccessFields_organiser_instagram_Create",
	permission: boolean
};
	["UsersDocAccessFields_organiser_instagram_Read"]: {
	__typename: "UsersDocAccessFields_organiser_instagram_Read",
	permission: boolean
};
	["UsersDocAccessFields_organiser_instagram_Update"]: {
	__typename: "UsersDocAccessFields_organiser_instagram_Update",
	permission: boolean
};
	["UsersDocAccessFields_organiser_instagram_Delete"]: {
	__typename: "UsersDocAccessFields_organiser_instagram_Delete",
	permission: boolean
};
	["UsersDocAccessFields_organiser_language"]: {
	__typename: "UsersDocAccessFields_organiser_language",
	create?: GraphQLTypes["UsersDocAccessFields_organiser_language_Create"] | undefined,
	read?: GraphQLTypes["UsersDocAccessFields_organiser_language_Read"] | undefined,
	update?: GraphQLTypes["UsersDocAccessFields_organiser_language_Update"] | undefined,
	delete?: GraphQLTypes["UsersDocAccessFields_organiser_language_Delete"] | undefined
};
	["UsersDocAccessFields_organiser_language_Create"]: {
	__typename: "UsersDocAccessFields_organiser_language_Create",
	permission: boolean
};
	["UsersDocAccessFields_organiser_language_Read"]: {
	__typename: "UsersDocAccessFields_organiser_language_Read",
	permission: boolean
};
	["UsersDocAccessFields_organiser_language_Update"]: {
	__typename: "UsersDocAccessFields_organiser_language_Update",
	permission: boolean
};
	["UsersDocAccessFields_organiser_language_Delete"]: {
	__typename: "UsersDocAccessFields_organiser_language_Delete",
	permission: boolean
};
	["UsersDocAccessFields_organiser_phone"]: {
	__typename: "UsersDocAccessFields_organiser_phone",
	create?: GraphQLTypes["UsersDocAccessFields_organiser_phone_Create"] | undefined,
	read?: GraphQLTypes["UsersDocAccessFields_organiser_phone_Read"] | undefined,
	update?: GraphQLTypes["UsersDocAccessFields_organiser_phone_Update"] | undefined,
	delete?: GraphQLTypes["UsersDocAccessFields_organiser_phone_Delete"] | undefined
};
	["UsersDocAccessFields_organiser_phone_Create"]: {
	__typename: "UsersDocAccessFields_organiser_phone_Create",
	permission: boolean
};
	["UsersDocAccessFields_organiser_phone_Read"]: {
	__typename: "UsersDocAccessFields_organiser_phone_Read",
	permission: boolean
};
	["UsersDocAccessFields_organiser_phone_Update"]: {
	__typename: "UsersDocAccessFields_organiser_phone_Update",
	permission: boolean
};
	["UsersDocAccessFields_organiser_phone_Delete"]: {
	__typename: "UsersDocAccessFields_organiser_phone_Delete",
	permission: boolean
};
	["UsersDocAccessFields_organiser_followers"]: {
	__typename: "UsersDocAccessFields_organiser_followers",
	create?: GraphQLTypes["UsersDocAccessFields_organiser_followers_Create"] | undefined,
	read?: GraphQLTypes["UsersDocAccessFields_organiser_followers_Read"] | undefined,
	update?: GraphQLTypes["UsersDocAccessFields_organiser_followers_Update"] | undefined,
	delete?: GraphQLTypes["UsersDocAccessFields_organiser_followers_Delete"] | undefined
};
	["UsersDocAccessFields_organiser_followers_Create"]: {
	__typename: "UsersDocAccessFields_organiser_followers_Create",
	permission: boolean
};
	["UsersDocAccessFields_organiser_followers_Read"]: {
	__typename: "UsersDocAccessFields_organiser_followers_Read",
	permission: boolean
};
	["UsersDocAccessFields_organiser_followers_Update"]: {
	__typename: "UsersDocAccessFields_organiser_followers_Update",
	permission: boolean
};
	["UsersDocAccessFields_organiser_followers_Delete"]: {
	__typename: "UsersDocAccessFields_organiser_followers_Delete",
	permission: boolean
};
	["UsersDocAccessFields_emailVerified"]: {
	__typename: "UsersDocAccessFields_emailVerified",
	create?: GraphQLTypes["UsersDocAccessFields_emailVerified_Create"] | undefined,
	read?: GraphQLTypes["UsersDocAccessFields_emailVerified_Read"] | undefined,
	update?: GraphQLTypes["UsersDocAccessFields_emailVerified_Update"] | undefined,
	delete?: GraphQLTypes["UsersDocAccessFields_emailVerified_Delete"] | undefined
};
	["UsersDocAccessFields_emailVerified_Create"]: {
	__typename: "UsersDocAccessFields_emailVerified_Create",
	permission: boolean
};
	["UsersDocAccessFields_emailVerified_Read"]: {
	__typename: "UsersDocAccessFields_emailVerified_Read",
	permission: boolean
};
	["UsersDocAccessFields_emailVerified_Update"]: {
	__typename: "UsersDocAccessFields_emailVerified_Update",
	permission: boolean
};
	["UsersDocAccessFields_emailVerified_Delete"]: {
	__typename: "UsersDocAccessFields_emailVerified_Delete",
	permission: boolean
};
	["UsersDocAccessFields_sso"]: {
	__typename: "UsersDocAccessFields_sso",
	create?: GraphQLTypes["UsersDocAccessFields_sso_Create"] | undefined,
	read?: GraphQLTypes["UsersDocAccessFields_sso_Read"] | undefined,
	update?: GraphQLTypes["UsersDocAccessFields_sso_Update"] | undefined,
	delete?: GraphQLTypes["UsersDocAccessFields_sso_Delete"] | undefined,
	fields?: GraphQLTypes["UsersDocAccessFields_sso_Fields"] | undefined
};
	["UsersDocAccessFields_sso_Create"]: {
	__typename: "UsersDocAccessFields_sso_Create",
	permission: boolean
};
	["UsersDocAccessFields_sso_Read"]: {
	__typename: "UsersDocAccessFields_sso_Read",
	permission: boolean
};
	["UsersDocAccessFields_sso_Update"]: {
	__typename: "UsersDocAccessFields_sso_Update",
	permission: boolean
};
	["UsersDocAccessFields_sso_Delete"]: {
	__typename: "UsersDocAccessFields_sso_Delete",
	permission: boolean
};
	["UsersDocAccessFields_sso_Fields"]: {
	__typename: "UsersDocAccessFields_sso_Fields",
	googleId?: GraphQLTypes["UsersDocAccessFields_sso_googleId"] | undefined,
	facebookId?: GraphQLTypes["UsersDocAccessFields_sso_facebookId"] | undefined,
	appleId?: GraphQLTypes["UsersDocAccessFields_sso_appleId"] | undefined
};
	["UsersDocAccessFields_sso_googleId"]: {
	__typename: "UsersDocAccessFields_sso_googleId",
	create?: GraphQLTypes["UsersDocAccessFields_sso_googleId_Create"] | undefined,
	read?: GraphQLTypes["UsersDocAccessFields_sso_googleId_Read"] | undefined,
	update?: GraphQLTypes["UsersDocAccessFields_sso_googleId_Update"] | undefined,
	delete?: GraphQLTypes["UsersDocAccessFields_sso_googleId_Delete"] | undefined
};
	["UsersDocAccessFields_sso_googleId_Create"]: {
	__typename: "UsersDocAccessFields_sso_googleId_Create",
	permission: boolean
};
	["UsersDocAccessFields_sso_googleId_Read"]: {
	__typename: "UsersDocAccessFields_sso_googleId_Read",
	permission: boolean
};
	["UsersDocAccessFields_sso_googleId_Update"]: {
	__typename: "UsersDocAccessFields_sso_googleId_Update",
	permission: boolean
};
	["UsersDocAccessFields_sso_googleId_Delete"]: {
	__typename: "UsersDocAccessFields_sso_googleId_Delete",
	permission: boolean
};
	["UsersDocAccessFields_sso_facebookId"]: {
	__typename: "UsersDocAccessFields_sso_facebookId",
	create?: GraphQLTypes["UsersDocAccessFields_sso_facebookId_Create"] | undefined,
	read?: GraphQLTypes["UsersDocAccessFields_sso_facebookId_Read"] | undefined,
	update?: GraphQLTypes["UsersDocAccessFields_sso_facebookId_Update"] | undefined,
	delete?: GraphQLTypes["UsersDocAccessFields_sso_facebookId_Delete"] | undefined
};
	["UsersDocAccessFields_sso_facebookId_Create"]: {
	__typename: "UsersDocAccessFields_sso_facebookId_Create",
	permission: boolean
};
	["UsersDocAccessFields_sso_facebookId_Read"]: {
	__typename: "UsersDocAccessFields_sso_facebookId_Read",
	permission: boolean
};
	["UsersDocAccessFields_sso_facebookId_Update"]: {
	__typename: "UsersDocAccessFields_sso_facebookId_Update",
	permission: boolean
};
	["UsersDocAccessFields_sso_facebookId_Delete"]: {
	__typename: "UsersDocAccessFields_sso_facebookId_Delete",
	permission: boolean
};
	["UsersDocAccessFields_sso_appleId"]: {
	__typename: "UsersDocAccessFields_sso_appleId",
	create?: GraphQLTypes["UsersDocAccessFields_sso_appleId_Create"] | undefined,
	read?: GraphQLTypes["UsersDocAccessFields_sso_appleId_Read"] | undefined,
	update?: GraphQLTypes["UsersDocAccessFields_sso_appleId_Update"] | undefined,
	delete?: GraphQLTypes["UsersDocAccessFields_sso_appleId_Delete"] | undefined
};
	["UsersDocAccessFields_sso_appleId_Create"]: {
	__typename: "UsersDocAccessFields_sso_appleId_Create",
	permission: boolean
};
	["UsersDocAccessFields_sso_appleId_Read"]: {
	__typename: "UsersDocAccessFields_sso_appleId_Read",
	permission: boolean
};
	["UsersDocAccessFields_sso_appleId_Update"]: {
	__typename: "UsersDocAccessFields_sso_appleId_Update",
	permission: boolean
};
	["UsersDocAccessFields_sso_appleId_Delete"]: {
	__typename: "UsersDocAccessFields_sso_appleId_Delete",
	permission: boolean
};
	["UsersDocAccessFields_oldPassword"]: {
	__typename: "UsersDocAccessFields_oldPassword",
	create?: GraphQLTypes["UsersDocAccessFields_oldPassword_Create"] | undefined,
	read?: GraphQLTypes["UsersDocAccessFields_oldPassword_Read"] | undefined,
	update?: GraphQLTypes["UsersDocAccessFields_oldPassword_Update"] | undefined,
	delete?: GraphQLTypes["UsersDocAccessFields_oldPassword_Delete"] | undefined
};
	["UsersDocAccessFields_oldPassword_Create"]: {
	__typename: "UsersDocAccessFields_oldPassword_Create",
	permission: boolean
};
	["UsersDocAccessFields_oldPassword_Read"]: {
	__typename: "UsersDocAccessFields_oldPassword_Read",
	permission: boolean
};
	["UsersDocAccessFields_oldPassword_Update"]: {
	__typename: "UsersDocAccessFields_oldPassword_Update",
	permission: boolean
};
	["UsersDocAccessFields_oldPassword_Delete"]: {
	__typename: "UsersDocAccessFields_oldPassword_Delete",
	permission: boolean
};
	["UsersDocAccessFields_status"]: {
	__typename: "UsersDocAccessFields_status",
	create?: GraphQLTypes["UsersDocAccessFields_status_Create"] | undefined,
	read?: GraphQLTypes["UsersDocAccessFields_status_Read"] | undefined,
	update?: GraphQLTypes["UsersDocAccessFields_status_Update"] | undefined,
	delete?: GraphQLTypes["UsersDocAccessFields_status_Delete"] | undefined
};
	["UsersDocAccessFields_status_Create"]: {
	__typename: "UsersDocAccessFields_status_Create",
	permission: boolean
};
	["UsersDocAccessFields_status_Read"]: {
	__typename: "UsersDocAccessFields_status_Read",
	permission: boolean
};
	["UsersDocAccessFields_status_Update"]: {
	__typename: "UsersDocAccessFields_status_Update",
	permission: boolean
};
	["UsersDocAccessFields_status_Delete"]: {
	__typename: "UsersDocAccessFields_status_Delete",
	permission: boolean
};
	["UsersDocAccessFields_updatedAt"]: {
	__typename: "UsersDocAccessFields_updatedAt",
	create?: GraphQLTypes["UsersDocAccessFields_updatedAt_Create"] | undefined,
	read?: GraphQLTypes["UsersDocAccessFields_updatedAt_Read"] | undefined,
	update?: GraphQLTypes["UsersDocAccessFields_updatedAt_Update"] | undefined,
	delete?: GraphQLTypes["UsersDocAccessFields_updatedAt_Delete"] | undefined
};
	["UsersDocAccessFields_updatedAt_Create"]: {
	__typename: "UsersDocAccessFields_updatedAt_Create",
	permission: boolean
};
	["UsersDocAccessFields_updatedAt_Read"]: {
	__typename: "UsersDocAccessFields_updatedAt_Read",
	permission: boolean
};
	["UsersDocAccessFields_updatedAt_Update"]: {
	__typename: "UsersDocAccessFields_updatedAt_Update",
	permission: boolean
};
	["UsersDocAccessFields_updatedAt_Delete"]: {
	__typename: "UsersDocAccessFields_updatedAt_Delete",
	permission: boolean
};
	["UsersDocAccessFields_createdAt"]: {
	__typename: "UsersDocAccessFields_createdAt",
	create?: GraphQLTypes["UsersDocAccessFields_createdAt_Create"] | undefined,
	read?: GraphQLTypes["UsersDocAccessFields_createdAt_Read"] | undefined,
	update?: GraphQLTypes["UsersDocAccessFields_createdAt_Update"] | undefined,
	delete?: GraphQLTypes["UsersDocAccessFields_createdAt_Delete"] | undefined
};
	["UsersDocAccessFields_createdAt_Create"]: {
	__typename: "UsersDocAccessFields_createdAt_Create",
	permission: boolean
};
	["UsersDocAccessFields_createdAt_Read"]: {
	__typename: "UsersDocAccessFields_createdAt_Read",
	permission: boolean
};
	["UsersDocAccessFields_createdAt_Update"]: {
	__typename: "UsersDocAccessFields_createdAt_Update",
	permission: boolean
};
	["UsersDocAccessFields_createdAt_Delete"]: {
	__typename: "UsersDocAccessFields_createdAt_Delete",
	permission: boolean
};
	["UsersDocAccessFields_email"]: {
	__typename: "UsersDocAccessFields_email",
	create?: GraphQLTypes["UsersDocAccessFields_email_Create"] | undefined,
	read?: GraphQLTypes["UsersDocAccessFields_email_Read"] | undefined,
	update?: GraphQLTypes["UsersDocAccessFields_email_Update"] | undefined,
	delete?: GraphQLTypes["UsersDocAccessFields_email_Delete"] | undefined
};
	["UsersDocAccessFields_email_Create"]: {
	__typename: "UsersDocAccessFields_email_Create",
	permission: boolean
};
	["UsersDocAccessFields_email_Read"]: {
	__typename: "UsersDocAccessFields_email_Read",
	permission: boolean
};
	["UsersDocAccessFields_email_Update"]: {
	__typename: "UsersDocAccessFields_email_Update",
	permission: boolean
};
	["UsersDocAccessFields_email_Delete"]: {
	__typename: "UsersDocAccessFields_email_Delete",
	permission: boolean
};
	["UsersDocAccessFields_password"]: {
	__typename: "UsersDocAccessFields_password",
	create?: GraphQLTypes["UsersDocAccessFields_password_Create"] | undefined,
	read?: GraphQLTypes["UsersDocAccessFields_password_Read"] | undefined,
	update?: GraphQLTypes["UsersDocAccessFields_password_Update"] | undefined,
	delete?: GraphQLTypes["UsersDocAccessFields_password_Delete"] | undefined
};
	["UsersDocAccessFields_password_Create"]: {
	__typename: "UsersDocAccessFields_password_Create",
	permission: boolean
};
	["UsersDocAccessFields_password_Read"]: {
	__typename: "UsersDocAccessFields_password_Read",
	permission: boolean
};
	["UsersDocAccessFields_password_Update"]: {
	__typename: "UsersDocAccessFields_password_Update",
	permission: boolean
};
	["UsersDocAccessFields_password_Delete"]: {
	__typename: "UsersDocAccessFields_password_Delete",
	permission: boolean
};
	["UsersCreateDocAccess"]: {
	__typename: "UsersCreateDocAccess",
	permission: boolean,
	where?: GraphQLTypes["JSONObject"] | undefined
};
	["UsersReadDocAccess"]: {
	__typename: "UsersReadDocAccess",
	permission: boolean,
	where?: GraphQLTypes["JSONObject"] | undefined
};
	["UsersUpdateDocAccess"]: {
	__typename: "UsersUpdateDocAccess",
	permission: boolean,
	where?: GraphQLTypes["JSONObject"] | undefined
};
	["UsersDeleteDocAccess"]: {
	__typename: "UsersDeleteDocAccess",
	permission: boolean,
	where?: GraphQLTypes["JSONObject"] | undefined
};
	["UsersUnlockDocAccess"]: {
	__typename: "UsersUnlockDocAccess",
	permission: boolean,
	where?: GraphQLTypes["JSONObject"] | undefined
};
	["usersMe"]: {
	__typename: "usersMe",
	collection?: string | undefined,
	exp?: number | undefined,
	strategy?: string | undefined,
	token?: string | undefined,
	user?: GraphQLTypes["User"] | undefined
};
	["Bookmark"]: {
	__typename: "Bookmark",
	id?: string | undefined,
	user?: GraphQLTypes["Bookmark_User_Relationship"] | undefined,
	item?: GraphQLTypes["Bookmark_Item_Relationship"] | undefined,
	updatedAt?: GraphQLTypes["DateTime"] | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined
};
	["Bookmark_User_Relationship"]: {
	__typename: "Bookmark_User_Relationship",
	relationTo?: GraphQLTypes["Bookmark_User_RelationTo"] | undefined,
	value?: GraphQLTypes["Bookmark_User"] | undefined
};
	["Bookmark_User_RelationTo"]: Bookmark_User_RelationTo;
	["Bookmark_User"]:{
        	__typename:"User"
        	['...on User']: '__union' & GraphQLTypes["User"];
};
	["Bookmark_Item_Relationship"]: {
	__typename: "Bookmark_Item_Relationship",
	relationTo?: GraphQLTypes["Bookmark_Item_RelationTo"] | undefined,
	value?: GraphQLTypes["Bookmark_Item"] | undefined
};
	["Bookmark_Item_RelationTo"]: Bookmark_Item_RelationTo;
	["Bookmark_Item"]:{
        	__typename:"Event" | "Location" | "Post"
        	['...on Event']: '__union' & GraphQLTypes["Event"];
	['...on Location']: '__union' & GraphQLTypes["Location"];
	['...on Post']: '__union' & GraphQLTypes["Post"];
};
	["Bookmarks"]: {
	__typename: "Bookmarks",
	docs?: Array<GraphQLTypes["Bookmark"] | undefined> | undefined,
	hasNextPage?: boolean | undefined,
	hasPrevPage?: boolean | undefined,
	limit?: number | undefined,
	nextPage?: number | undefined,
	offset?: number | undefined,
	page?: number | undefined,
	pagingCounter?: number | undefined,
	prevPage?: number | undefined,
	totalDocs?: number | undefined,
	totalPages?: number | undefined
};
	["Bookmark_where"]: {
		user?: GraphQLTypes["Bookmark_user_Relation"] | undefined,
	item?: GraphQLTypes["Bookmark_item_Relation"] | undefined,
	updatedAt?: GraphQLTypes["Bookmark_updatedAt_operator"] | undefined,
	createdAt?: GraphQLTypes["Bookmark_createdAt_operator"] | undefined,
	id?: GraphQLTypes["Bookmark_id_operator"] | undefined,
	AND?: Array<GraphQLTypes["Bookmark_where_and"] | undefined> | undefined,
	OR?: Array<GraphQLTypes["Bookmark_where_or"] | undefined> | undefined
};
	["Bookmark_user_Relation"]: {
		relationTo?: GraphQLTypes["Bookmark_user_Relation_RelationTo"] | undefined,
	value?: GraphQLTypes["JSON"] | undefined
};
	["Bookmark_user_Relation_RelationTo"]: Bookmark_user_Relation_RelationTo;
	["Bookmark_item_Relation"]: {
		relationTo?: GraphQLTypes["Bookmark_item_Relation_RelationTo"] | undefined,
	value?: GraphQLTypes["JSON"] | undefined
};
	["Bookmark_item_Relation_RelationTo"]: Bookmark_item_Relation_RelationTo;
	["Bookmark_updatedAt_operator"]: {
		equals?: GraphQLTypes["DateTime"] | undefined,
	not_equals?: GraphQLTypes["DateTime"] | undefined,
	greater_than_equal?: GraphQLTypes["DateTime"] | undefined,
	greater_than?: GraphQLTypes["DateTime"] | undefined,
	less_than_equal?: GraphQLTypes["DateTime"] | undefined,
	less_than?: GraphQLTypes["DateTime"] | undefined,
	like?: GraphQLTypes["DateTime"] | undefined,
	exists?: boolean | undefined
};
	["Bookmark_createdAt_operator"]: {
		equals?: GraphQLTypes["DateTime"] | undefined,
	not_equals?: GraphQLTypes["DateTime"] | undefined,
	greater_than_equal?: GraphQLTypes["DateTime"] | undefined,
	greater_than?: GraphQLTypes["DateTime"] | undefined,
	less_than_equal?: GraphQLTypes["DateTime"] | undefined,
	less_than?: GraphQLTypes["DateTime"] | undefined,
	like?: GraphQLTypes["DateTime"] | undefined,
	exists?: boolean | undefined
};
	["Bookmark_id_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Bookmark_where_and"]: {
		user?: GraphQLTypes["Bookmark_user_Relation"] | undefined,
	item?: GraphQLTypes["Bookmark_item_Relation"] | undefined,
	updatedAt?: GraphQLTypes["Bookmark_updatedAt_operator"] | undefined,
	createdAt?: GraphQLTypes["Bookmark_createdAt_operator"] | undefined,
	id?: GraphQLTypes["Bookmark_id_operator"] | undefined,
	AND?: Array<GraphQLTypes["Bookmark_where_and"] | undefined> | undefined,
	OR?: Array<GraphQLTypes["Bookmark_where_or"] | undefined> | undefined
};
	["Bookmark_where_or"]: {
		user?: GraphQLTypes["Bookmark_user_Relation"] | undefined,
	item?: GraphQLTypes["Bookmark_item_Relation"] | undefined,
	updatedAt?: GraphQLTypes["Bookmark_updatedAt_operator"] | undefined,
	createdAt?: GraphQLTypes["Bookmark_createdAt_operator"] | undefined,
	id?: GraphQLTypes["Bookmark_id_operator"] | undefined,
	AND?: Array<GraphQLTypes["Bookmark_where_and"] | undefined> | undefined,
	OR?: Array<GraphQLTypes["Bookmark_where_or"] | undefined> | undefined
};
	["countBookmarks"]: {
	__typename: "countBookmarks",
	totalDocs?: number | undefined
};
	["bookmarksDocAccess"]: {
	__typename: "bookmarksDocAccess",
	fields?: GraphQLTypes["BookmarksDocAccessFields"] | undefined,
	create?: GraphQLTypes["BookmarksCreateDocAccess"] | undefined,
	read?: GraphQLTypes["BookmarksReadDocAccess"] | undefined,
	update?: GraphQLTypes["BookmarksUpdateDocAccess"] | undefined,
	delete?: GraphQLTypes["BookmarksDeleteDocAccess"] | undefined
};
	["BookmarksDocAccessFields"]: {
	__typename: "BookmarksDocAccessFields",
	user?: GraphQLTypes["BookmarksDocAccessFields_user"] | undefined,
	item?: GraphQLTypes["BookmarksDocAccessFields_item"] | undefined,
	updatedAt?: GraphQLTypes["BookmarksDocAccessFields_updatedAt"] | undefined,
	createdAt?: GraphQLTypes["BookmarksDocAccessFields_createdAt"] | undefined
};
	["BookmarksDocAccessFields_user"]: {
	__typename: "BookmarksDocAccessFields_user",
	create?: GraphQLTypes["BookmarksDocAccessFields_user_Create"] | undefined,
	read?: GraphQLTypes["BookmarksDocAccessFields_user_Read"] | undefined,
	update?: GraphQLTypes["BookmarksDocAccessFields_user_Update"] | undefined,
	delete?: GraphQLTypes["BookmarksDocAccessFields_user_Delete"] | undefined
};
	["BookmarksDocAccessFields_user_Create"]: {
	__typename: "BookmarksDocAccessFields_user_Create",
	permission: boolean
};
	["BookmarksDocAccessFields_user_Read"]: {
	__typename: "BookmarksDocAccessFields_user_Read",
	permission: boolean
};
	["BookmarksDocAccessFields_user_Update"]: {
	__typename: "BookmarksDocAccessFields_user_Update",
	permission: boolean
};
	["BookmarksDocAccessFields_user_Delete"]: {
	__typename: "BookmarksDocAccessFields_user_Delete",
	permission: boolean
};
	["BookmarksDocAccessFields_item"]: {
	__typename: "BookmarksDocAccessFields_item",
	create?: GraphQLTypes["BookmarksDocAccessFields_item_Create"] | undefined,
	read?: GraphQLTypes["BookmarksDocAccessFields_item_Read"] | undefined,
	update?: GraphQLTypes["BookmarksDocAccessFields_item_Update"] | undefined,
	delete?: GraphQLTypes["BookmarksDocAccessFields_item_Delete"] | undefined
};
	["BookmarksDocAccessFields_item_Create"]: {
	__typename: "BookmarksDocAccessFields_item_Create",
	permission: boolean
};
	["BookmarksDocAccessFields_item_Read"]: {
	__typename: "BookmarksDocAccessFields_item_Read",
	permission: boolean
};
	["BookmarksDocAccessFields_item_Update"]: {
	__typename: "BookmarksDocAccessFields_item_Update",
	permission: boolean
};
	["BookmarksDocAccessFields_item_Delete"]: {
	__typename: "BookmarksDocAccessFields_item_Delete",
	permission: boolean
};
	["BookmarksDocAccessFields_updatedAt"]: {
	__typename: "BookmarksDocAccessFields_updatedAt",
	create?: GraphQLTypes["BookmarksDocAccessFields_updatedAt_Create"] | undefined,
	read?: GraphQLTypes["BookmarksDocAccessFields_updatedAt_Read"] | undefined,
	update?: GraphQLTypes["BookmarksDocAccessFields_updatedAt_Update"] | undefined,
	delete?: GraphQLTypes["BookmarksDocAccessFields_updatedAt_Delete"] | undefined
};
	["BookmarksDocAccessFields_updatedAt_Create"]: {
	__typename: "BookmarksDocAccessFields_updatedAt_Create",
	permission: boolean
};
	["BookmarksDocAccessFields_updatedAt_Read"]: {
	__typename: "BookmarksDocAccessFields_updatedAt_Read",
	permission: boolean
};
	["BookmarksDocAccessFields_updatedAt_Update"]: {
	__typename: "BookmarksDocAccessFields_updatedAt_Update",
	permission: boolean
};
	["BookmarksDocAccessFields_updatedAt_Delete"]: {
	__typename: "BookmarksDocAccessFields_updatedAt_Delete",
	permission: boolean
};
	["BookmarksDocAccessFields_createdAt"]: {
	__typename: "BookmarksDocAccessFields_createdAt",
	create?: GraphQLTypes["BookmarksDocAccessFields_createdAt_Create"] | undefined,
	read?: GraphQLTypes["BookmarksDocAccessFields_createdAt_Read"] | undefined,
	update?: GraphQLTypes["BookmarksDocAccessFields_createdAt_Update"] | undefined,
	delete?: GraphQLTypes["BookmarksDocAccessFields_createdAt_Delete"] | undefined
};
	["BookmarksDocAccessFields_createdAt_Create"]: {
	__typename: "BookmarksDocAccessFields_createdAt_Create",
	permission: boolean
};
	["BookmarksDocAccessFields_createdAt_Read"]: {
	__typename: "BookmarksDocAccessFields_createdAt_Read",
	permission: boolean
};
	["BookmarksDocAccessFields_createdAt_Update"]: {
	__typename: "BookmarksDocAccessFields_createdAt_Update",
	permission: boolean
};
	["BookmarksDocAccessFields_createdAt_Delete"]: {
	__typename: "BookmarksDocAccessFields_createdAt_Delete",
	permission: boolean
};
	["BookmarksCreateDocAccess"]: {
	__typename: "BookmarksCreateDocAccess",
	permission: boolean,
	where?: GraphQLTypes["JSONObject"] | undefined
};
	["BookmarksReadDocAccess"]: {
	__typename: "BookmarksReadDocAccess",
	permission: boolean,
	where?: GraphQLTypes["JSONObject"] | undefined
};
	["BookmarksUpdateDocAccess"]: {
	__typename: "BookmarksUpdateDocAccess",
	permission: boolean,
	where?: GraphQLTypes["JSONObject"] | undefined
};
	["BookmarksDeleteDocAccess"]: {
	__typename: "BookmarksDeleteDocAccess",
	permission: boolean,
	where?: GraphQLTypes["JSONObject"] | undefined
};
	["WebAppBookmark"]: {
	__typename: "WebAppBookmark",
	id?: string | undefined,
	user?: GraphQLTypes["WebAppBookmark_User_Relationship"] | undefined,
	event?: GraphQLTypes["WebAppBookmark_Event_Relationship"] | undefined,
	productType?: GraphQLTypes["WebAppBookmark_productType"] | undefined,
	item?: string | undefined,
	entryNumber?: string | undefined,
	updatedAt?: GraphQLTypes["DateTime"] | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined
};
	["WebAppBookmark_User_Relationship"]: {
	__typename: "WebAppBookmark_User_Relationship",
	relationTo?: GraphQLTypes["WebAppBookmark_User_RelationTo"] | undefined,
	value?: GraphQLTypes["WebAppBookmark_User"] | undefined
};
	["WebAppBookmark_User_RelationTo"]: WebAppBookmark_User_RelationTo;
	["WebAppBookmark_User"]:{
        	__typename:"User"
        	['...on User']: '__union' & GraphQLTypes["User"];
};
	["WebAppBookmark_Event_Relationship"]: {
	__typename: "WebAppBookmark_Event_Relationship",
	relationTo?: GraphQLTypes["WebAppBookmark_Event_RelationTo"] | undefined,
	value?: GraphQLTypes["WebAppBookmark_Event"] | undefined
};
	["WebAppBookmark_Event_RelationTo"]: WebAppBookmark_Event_RelationTo;
	["WebAppBookmark_Event"]:{
        	__typename:"Event"
        	['...on Event']: '__union' & GraphQLTypes["Event"];
};
	["WebAppBookmark_productType"]: WebAppBookmark_productType;
	["WebAppBookmarks"]: {
	__typename: "WebAppBookmarks",
	docs?: Array<GraphQLTypes["WebAppBookmark"] | undefined> | undefined,
	hasNextPage?: boolean | undefined,
	hasPrevPage?: boolean | undefined,
	limit?: number | undefined,
	nextPage?: number | undefined,
	offset?: number | undefined,
	page?: number | undefined,
	pagingCounter?: number | undefined,
	prevPage?: number | undefined,
	totalDocs?: number | undefined,
	totalPages?: number | undefined
};
	["WebAppBookmark_where"]: {
		user?: GraphQLTypes["WebAppBookmark_user_Relation"] | undefined,
	event?: GraphQLTypes["WebAppBookmark_event_Relation"] | undefined,
	productType?: GraphQLTypes["WebAppBookmark_productType_operator"] | undefined,
	item?: GraphQLTypes["WebAppBookmark_item_operator"] | undefined,
	entryNumber?: GraphQLTypes["WebAppBookmark_entryNumber_operator"] | undefined,
	updatedAt?: GraphQLTypes["WebAppBookmark_updatedAt_operator"] | undefined,
	createdAt?: GraphQLTypes["WebAppBookmark_createdAt_operator"] | undefined,
	id?: GraphQLTypes["WebAppBookmark_id_operator"] | undefined,
	AND?: Array<GraphQLTypes["WebAppBookmark_where_and"] | undefined> | undefined,
	OR?: Array<GraphQLTypes["WebAppBookmark_where_or"] | undefined> | undefined
};
	["WebAppBookmark_user_Relation"]: {
		relationTo?: GraphQLTypes["WebAppBookmark_user_Relation_RelationTo"] | undefined,
	value?: GraphQLTypes["JSON"] | undefined
};
	["WebAppBookmark_user_Relation_RelationTo"]: WebAppBookmark_user_Relation_RelationTo;
	["WebAppBookmark_event_Relation"]: {
		relationTo?: GraphQLTypes["WebAppBookmark_event_Relation_RelationTo"] | undefined,
	value?: GraphQLTypes["JSON"] | undefined
};
	["WebAppBookmark_event_Relation_RelationTo"]: WebAppBookmark_event_Relation_RelationTo;
	["WebAppBookmark_productType_operator"]: {
		equals?: GraphQLTypes["WebAppBookmark_productType_Input"] | undefined,
	not_equals?: GraphQLTypes["WebAppBookmark_productType_Input"] | undefined,
	in?: Array<GraphQLTypes["WebAppBookmark_productType_Input"] | undefined> | undefined,
	not_in?: Array<GraphQLTypes["WebAppBookmark_productType_Input"] | undefined> | undefined,
	all?: Array<GraphQLTypes["WebAppBookmark_productType_Input"] | undefined> | undefined,
	exists?: boolean | undefined
};
	["WebAppBookmark_productType_Input"]: WebAppBookmark_productType_Input;
	["WebAppBookmark_item_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["WebAppBookmark_entryNumber_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["WebAppBookmark_updatedAt_operator"]: {
		equals?: GraphQLTypes["DateTime"] | undefined,
	not_equals?: GraphQLTypes["DateTime"] | undefined,
	greater_than_equal?: GraphQLTypes["DateTime"] | undefined,
	greater_than?: GraphQLTypes["DateTime"] | undefined,
	less_than_equal?: GraphQLTypes["DateTime"] | undefined,
	less_than?: GraphQLTypes["DateTime"] | undefined,
	like?: GraphQLTypes["DateTime"] | undefined,
	exists?: boolean | undefined
};
	["WebAppBookmark_createdAt_operator"]: {
		equals?: GraphQLTypes["DateTime"] | undefined,
	not_equals?: GraphQLTypes["DateTime"] | undefined,
	greater_than_equal?: GraphQLTypes["DateTime"] | undefined,
	greater_than?: GraphQLTypes["DateTime"] | undefined,
	less_than_equal?: GraphQLTypes["DateTime"] | undefined,
	less_than?: GraphQLTypes["DateTime"] | undefined,
	like?: GraphQLTypes["DateTime"] | undefined,
	exists?: boolean | undefined
};
	["WebAppBookmark_id_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["WebAppBookmark_where_and"]: {
		user?: GraphQLTypes["WebAppBookmark_user_Relation"] | undefined,
	event?: GraphQLTypes["WebAppBookmark_event_Relation"] | undefined,
	productType?: GraphQLTypes["WebAppBookmark_productType_operator"] | undefined,
	item?: GraphQLTypes["WebAppBookmark_item_operator"] | undefined,
	entryNumber?: GraphQLTypes["WebAppBookmark_entryNumber_operator"] | undefined,
	updatedAt?: GraphQLTypes["WebAppBookmark_updatedAt_operator"] | undefined,
	createdAt?: GraphQLTypes["WebAppBookmark_createdAt_operator"] | undefined,
	id?: GraphQLTypes["WebAppBookmark_id_operator"] | undefined,
	AND?: Array<GraphQLTypes["WebAppBookmark_where_and"] | undefined> | undefined,
	OR?: Array<GraphQLTypes["WebAppBookmark_where_or"] | undefined> | undefined
};
	["WebAppBookmark_where_or"]: {
		user?: GraphQLTypes["WebAppBookmark_user_Relation"] | undefined,
	event?: GraphQLTypes["WebAppBookmark_event_Relation"] | undefined,
	productType?: GraphQLTypes["WebAppBookmark_productType_operator"] | undefined,
	item?: GraphQLTypes["WebAppBookmark_item_operator"] | undefined,
	entryNumber?: GraphQLTypes["WebAppBookmark_entryNumber_operator"] | undefined,
	updatedAt?: GraphQLTypes["WebAppBookmark_updatedAt_operator"] | undefined,
	createdAt?: GraphQLTypes["WebAppBookmark_createdAt_operator"] | undefined,
	id?: GraphQLTypes["WebAppBookmark_id_operator"] | undefined,
	AND?: Array<GraphQLTypes["WebAppBookmark_where_and"] | undefined> | undefined,
	OR?: Array<GraphQLTypes["WebAppBookmark_where_or"] | undefined> | undefined
};
	["countWebAppBookmarks"]: {
	__typename: "countWebAppBookmarks",
	totalDocs?: number | undefined
};
	["webAppBookmarksDocAccess"]: {
	__typename: "webAppBookmarksDocAccess",
	fields?: GraphQLTypes["WebAppBookmarksDocAccessFields"] | undefined,
	create?: GraphQLTypes["WebAppBookmarksCreateDocAccess"] | undefined,
	read?: GraphQLTypes["WebAppBookmarksReadDocAccess"] | undefined,
	update?: GraphQLTypes["WebAppBookmarksUpdateDocAccess"] | undefined,
	delete?: GraphQLTypes["WebAppBookmarksDeleteDocAccess"] | undefined
};
	["WebAppBookmarksDocAccessFields"]: {
	__typename: "WebAppBookmarksDocAccessFields",
	user?: GraphQLTypes["WebAppBookmarksDocAccessFields_user"] | undefined,
	event?: GraphQLTypes["WebAppBookmarksDocAccessFields_event"] | undefined,
	productType?: GraphQLTypes["WebAppBookmarksDocAccessFields_productType"] | undefined,
	item?: GraphQLTypes["WebAppBookmarksDocAccessFields_item"] | undefined,
	entryNumber?: GraphQLTypes["WebAppBookmarksDocAccessFields_entryNumber"] | undefined,
	updatedAt?: GraphQLTypes["WebAppBookmarksDocAccessFields_updatedAt"] | undefined,
	createdAt?: GraphQLTypes["WebAppBookmarksDocAccessFields_createdAt"] | undefined
};
	["WebAppBookmarksDocAccessFields_user"]: {
	__typename: "WebAppBookmarksDocAccessFields_user",
	create?: GraphQLTypes["WebAppBookmarksDocAccessFields_user_Create"] | undefined,
	read?: GraphQLTypes["WebAppBookmarksDocAccessFields_user_Read"] | undefined,
	update?: GraphQLTypes["WebAppBookmarksDocAccessFields_user_Update"] | undefined,
	delete?: GraphQLTypes["WebAppBookmarksDocAccessFields_user_Delete"] | undefined
};
	["WebAppBookmarksDocAccessFields_user_Create"]: {
	__typename: "WebAppBookmarksDocAccessFields_user_Create",
	permission: boolean
};
	["WebAppBookmarksDocAccessFields_user_Read"]: {
	__typename: "WebAppBookmarksDocAccessFields_user_Read",
	permission: boolean
};
	["WebAppBookmarksDocAccessFields_user_Update"]: {
	__typename: "WebAppBookmarksDocAccessFields_user_Update",
	permission: boolean
};
	["WebAppBookmarksDocAccessFields_user_Delete"]: {
	__typename: "WebAppBookmarksDocAccessFields_user_Delete",
	permission: boolean
};
	["WebAppBookmarksDocAccessFields_event"]: {
	__typename: "WebAppBookmarksDocAccessFields_event",
	create?: GraphQLTypes["WebAppBookmarksDocAccessFields_event_Create"] | undefined,
	read?: GraphQLTypes["WebAppBookmarksDocAccessFields_event_Read"] | undefined,
	update?: GraphQLTypes["WebAppBookmarksDocAccessFields_event_Update"] | undefined,
	delete?: GraphQLTypes["WebAppBookmarksDocAccessFields_event_Delete"] | undefined
};
	["WebAppBookmarksDocAccessFields_event_Create"]: {
	__typename: "WebAppBookmarksDocAccessFields_event_Create",
	permission: boolean
};
	["WebAppBookmarksDocAccessFields_event_Read"]: {
	__typename: "WebAppBookmarksDocAccessFields_event_Read",
	permission: boolean
};
	["WebAppBookmarksDocAccessFields_event_Update"]: {
	__typename: "WebAppBookmarksDocAccessFields_event_Update",
	permission: boolean
};
	["WebAppBookmarksDocAccessFields_event_Delete"]: {
	__typename: "WebAppBookmarksDocAccessFields_event_Delete",
	permission: boolean
};
	["WebAppBookmarksDocAccessFields_productType"]: {
	__typename: "WebAppBookmarksDocAccessFields_productType",
	create?: GraphQLTypes["WebAppBookmarksDocAccessFields_productType_Create"] | undefined,
	read?: GraphQLTypes["WebAppBookmarksDocAccessFields_productType_Read"] | undefined,
	update?: GraphQLTypes["WebAppBookmarksDocAccessFields_productType_Update"] | undefined,
	delete?: GraphQLTypes["WebAppBookmarksDocAccessFields_productType_Delete"] | undefined
};
	["WebAppBookmarksDocAccessFields_productType_Create"]: {
	__typename: "WebAppBookmarksDocAccessFields_productType_Create",
	permission: boolean
};
	["WebAppBookmarksDocAccessFields_productType_Read"]: {
	__typename: "WebAppBookmarksDocAccessFields_productType_Read",
	permission: boolean
};
	["WebAppBookmarksDocAccessFields_productType_Update"]: {
	__typename: "WebAppBookmarksDocAccessFields_productType_Update",
	permission: boolean
};
	["WebAppBookmarksDocAccessFields_productType_Delete"]: {
	__typename: "WebAppBookmarksDocAccessFields_productType_Delete",
	permission: boolean
};
	["WebAppBookmarksDocAccessFields_item"]: {
	__typename: "WebAppBookmarksDocAccessFields_item",
	create?: GraphQLTypes["WebAppBookmarksDocAccessFields_item_Create"] | undefined,
	read?: GraphQLTypes["WebAppBookmarksDocAccessFields_item_Read"] | undefined,
	update?: GraphQLTypes["WebAppBookmarksDocAccessFields_item_Update"] | undefined,
	delete?: GraphQLTypes["WebAppBookmarksDocAccessFields_item_Delete"] | undefined
};
	["WebAppBookmarksDocAccessFields_item_Create"]: {
	__typename: "WebAppBookmarksDocAccessFields_item_Create",
	permission: boolean
};
	["WebAppBookmarksDocAccessFields_item_Read"]: {
	__typename: "WebAppBookmarksDocAccessFields_item_Read",
	permission: boolean
};
	["WebAppBookmarksDocAccessFields_item_Update"]: {
	__typename: "WebAppBookmarksDocAccessFields_item_Update",
	permission: boolean
};
	["WebAppBookmarksDocAccessFields_item_Delete"]: {
	__typename: "WebAppBookmarksDocAccessFields_item_Delete",
	permission: boolean
};
	["WebAppBookmarksDocAccessFields_entryNumber"]: {
	__typename: "WebAppBookmarksDocAccessFields_entryNumber",
	create?: GraphQLTypes["WebAppBookmarksDocAccessFields_entryNumber_Create"] | undefined,
	read?: GraphQLTypes["WebAppBookmarksDocAccessFields_entryNumber_Read"] | undefined,
	update?: GraphQLTypes["WebAppBookmarksDocAccessFields_entryNumber_Update"] | undefined,
	delete?: GraphQLTypes["WebAppBookmarksDocAccessFields_entryNumber_Delete"] | undefined
};
	["WebAppBookmarksDocAccessFields_entryNumber_Create"]: {
	__typename: "WebAppBookmarksDocAccessFields_entryNumber_Create",
	permission: boolean
};
	["WebAppBookmarksDocAccessFields_entryNumber_Read"]: {
	__typename: "WebAppBookmarksDocAccessFields_entryNumber_Read",
	permission: boolean
};
	["WebAppBookmarksDocAccessFields_entryNumber_Update"]: {
	__typename: "WebAppBookmarksDocAccessFields_entryNumber_Update",
	permission: boolean
};
	["WebAppBookmarksDocAccessFields_entryNumber_Delete"]: {
	__typename: "WebAppBookmarksDocAccessFields_entryNumber_Delete",
	permission: boolean
};
	["WebAppBookmarksDocAccessFields_updatedAt"]: {
	__typename: "WebAppBookmarksDocAccessFields_updatedAt",
	create?: GraphQLTypes["WebAppBookmarksDocAccessFields_updatedAt_Create"] | undefined,
	read?: GraphQLTypes["WebAppBookmarksDocAccessFields_updatedAt_Read"] | undefined,
	update?: GraphQLTypes["WebAppBookmarksDocAccessFields_updatedAt_Update"] | undefined,
	delete?: GraphQLTypes["WebAppBookmarksDocAccessFields_updatedAt_Delete"] | undefined
};
	["WebAppBookmarksDocAccessFields_updatedAt_Create"]: {
	__typename: "WebAppBookmarksDocAccessFields_updatedAt_Create",
	permission: boolean
};
	["WebAppBookmarksDocAccessFields_updatedAt_Read"]: {
	__typename: "WebAppBookmarksDocAccessFields_updatedAt_Read",
	permission: boolean
};
	["WebAppBookmarksDocAccessFields_updatedAt_Update"]: {
	__typename: "WebAppBookmarksDocAccessFields_updatedAt_Update",
	permission: boolean
};
	["WebAppBookmarksDocAccessFields_updatedAt_Delete"]: {
	__typename: "WebAppBookmarksDocAccessFields_updatedAt_Delete",
	permission: boolean
};
	["WebAppBookmarksDocAccessFields_createdAt"]: {
	__typename: "WebAppBookmarksDocAccessFields_createdAt",
	create?: GraphQLTypes["WebAppBookmarksDocAccessFields_createdAt_Create"] | undefined,
	read?: GraphQLTypes["WebAppBookmarksDocAccessFields_createdAt_Read"] | undefined,
	update?: GraphQLTypes["WebAppBookmarksDocAccessFields_createdAt_Update"] | undefined,
	delete?: GraphQLTypes["WebAppBookmarksDocAccessFields_createdAt_Delete"] | undefined
};
	["WebAppBookmarksDocAccessFields_createdAt_Create"]: {
	__typename: "WebAppBookmarksDocAccessFields_createdAt_Create",
	permission: boolean
};
	["WebAppBookmarksDocAccessFields_createdAt_Read"]: {
	__typename: "WebAppBookmarksDocAccessFields_createdAt_Read",
	permission: boolean
};
	["WebAppBookmarksDocAccessFields_createdAt_Update"]: {
	__typename: "WebAppBookmarksDocAccessFields_createdAt_Update",
	permission: boolean
};
	["WebAppBookmarksDocAccessFields_createdAt_Delete"]: {
	__typename: "WebAppBookmarksDocAccessFields_createdAt_Delete",
	permission: boolean
};
	["WebAppBookmarksCreateDocAccess"]: {
	__typename: "WebAppBookmarksCreateDocAccess",
	permission: boolean,
	where?: GraphQLTypes["JSONObject"] | undefined
};
	["WebAppBookmarksReadDocAccess"]: {
	__typename: "WebAppBookmarksReadDocAccess",
	permission: boolean,
	where?: GraphQLTypes["JSONObject"] | undefined
};
	["WebAppBookmarksUpdateDocAccess"]: {
	__typename: "WebAppBookmarksUpdateDocAccess",
	permission: boolean,
	where?: GraphQLTypes["JSONObject"] | undefined
};
	["WebAppBookmarksDeleteDocAccess"]: {
	__typename: "WebAppBookmarksDeleteDocAccess",
	permission: boolean,
	where?: GraphQLTypes["JSONObject"] | undefined
};
	["ViewRecord"]: {
	__typename: "ViewRecord",
	id?: string | undefined,
	user?: GraphQLTypes["ViewRecord_User_Relationship"] | undefined,
	item?: GraphQLTypes["ViewRecord_Item_Relationship"] | undefined,
	updatedAt?: GraphQLTypes["DateTime"] | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined
};
	["ViewRecord_User_Relationship"]: {
	__typename: "ViewRecord_User_Relationship",
	relationTo?: GraphQLTypes["ViewRecord_User_RelationTo"] | undefined,
	value?: GraphQLTypes["ViewRecord_User"] | undefined
};
	["ViewRecord_User_RelationTo"]: ViewRecord_User_RelationTo;
	["ViewRecord_User"]:{
        	__typename:"User"
        	['...on User']: '__union' & GraphQLTypes["User"];
};
	["ViewRecord_Item_Relationship"]: {
	__typename: "ViewRecord_Item_Relationship",
	relationTo?: GraphQLTypes["ViewRecord_Item_RelationTo"] | undefined,
	value?: GraphQLTypes["ViewRecord_Item"] | undefined
};
	["ViewRecord_Item_RelationTo"]: ViewRecord_Item_RelationTo;
	["ViewRecord_Item"]:{
        	__typename:"Event" | "Location" | "Post"
        	['...on Event']: '__union' & GraphQLTypes["Event"];
	['...on Location']: '__union' & GraphQLTypes["Location"];
	['...on Post']: '__union' & GraphQLTypes["Post"];
};
	["ViewRecords"]: {
	__typename: "ViewRecords",
	docs?: Array<GraphQLTypes["ViewRecord"] | undefined> | undefined,
	hasNextPage?: boolean | undefined,
	hasPrevPage?: boolean | undefined,
	limit?: number | undefined,
	nextPage?: number | undefined,
	offset?: number | undefined,
	page?: number | undefined,
	pagingCounter?: number | undefined,
	prevPage?: number | undefined,
	totalDocs?: number | undefined,
	totalPages?: number | undefined
};
	["ViewRecord_where"]: {
		user?: GraphQLTypes["ViewRecord_user_Relation"] | undefined,
	item?: GraphQLTypes["ViewRecord_item_Relation"] | undefined,
	updatedAt?: GraphQLTypes["ViewRecord_updatedAt_operator"] | undefined,
	createdAt?: GraphQLTypes["ViewRecord_createdAt_operator"] | undefined,
	id?: GraphQLTypes["ViewRecord_id_operator"] | undefined,
	AND?: Array<GraphQLTypes["ViewRecord_where_and"] | undefined> | undefined,
	OR?: Array<GraphQLTypes["ViewRecord_where_or"] | undefined> | undefined
};
	["ViewRecord_user_Relation"]: {
		relationTo?: GraphQLTypes["ViewRecord_user_Relation_RelationTo"] | undefined,
	value?: GraphQLTypes["JSON"] | undefined
};
	["ViewRecord_user_Relation_RelationTo"]: ViewRecord_user_Relation_RelationTo;
	["ViewRecord_item_Relation"]: {
		relationTo?: GraphQLTypes["ViewRecord_item_Relation_RelationTo"] | undefined,
	value?: GraphQLTypes["JSON"] | undefined
};
	["ViewRecord_item_Relation_RelationTo"]: ViewRecord_item_Relation_RelationTo;
	["ViewRecord_updatedAt_operator"]: {
		equals?: GraphQLTypes["DateTime"] | undefined,
	not_equals?: GraphQLTypes["DateTime"] | undefined,
	greater_than_equal?: GraphQLTypes["DateTime"] | undefined,
	greater_than?: GraphQLTypes["DateTime"] | undefined,
	less_than_equal?: GraphQLTypes["DateTime"] | undefined,
	less_than?: GraphQLTypes["DateTime"] | undefined,
	like?: GraphQLTypes["DateTime"] | undefined,
	exists?: boolean | undefined
};
	["ViewRecord_createdAt_operator"]: {
		equals?: GraphQLTypes["DateTime"] | undefined,
	not_equals?: GraphQLTypes["DateTime"] | undefined,
	greater_than_equal?: GraphQLTypes["DateTime"] | undefined,
	greater_than?: GraphQLTypes["DateTime"] | undefined,
	less_than_equal?: GraphQLTypes["DateTime"] | undefined,
	less_than?: GraphQLTypes["DateTime"] | undefined,
	like?: GraphQLTypes["DateTime"] | undefined,
	exists?: boolean | undefined
};
	["ViewRecord_id_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["ViewRecord_where_and"]: {
		user?: GraphQLTypes["ViewRecord_user_Relation"] | undefined,
	item?: GraphQLTypes["ViewRecord_item_Relation"] | undefined,
	updatedAt?: GraphQLTypes["ViewRecord_updatedAt_operator"] | undefined,
	createdAt?: GraphQLTypes["ViewRecord_createdAt_operator"] | undefined,
	id?: GraphQLTypes["ViewRecord_id_operator"] | undefined,
	AND?: Array<GraphQLTypes["ViewRecord_where_and"] | undefined> | undefined,
	OR?: Array<GraphQLTypes["ViewRecord_where_or"] | undefined> | undefined
};
	["ViewRecord_where_or"]: {
		user?: GraphQLTypes["ViewRecord_user_Relation"] | undefined,
	item?: GraphQLTypes["ViewRecord_item_Relation"] | undefined,
	updatedAt?: GraphQLTypes["ViewRecord_updatedAt_operator"] | undefined,
	createdAt?: GraphQLTypes["ViewRecord_createdAt_operator"] | undefined,
	id?: GraphQLTypes["ViewRecord_id_operator"] | undefined,
	AND?: Array<GraphQLTypes["ViewRecord_where_and"] | undefined> | undefined,
	OR?: Array<GraphQLTypes["ViewRecord_where_or"] | undefined> | undefined
};
	["countViewRecords"]: {
	__typename: "countViewRecords",
	totalDocs?: number | undefined
};
	["view_recordsDocAccess"]: {
	__typename: "view_recordsDocAccess",
	fields?: GraphQLTypes["ViewRecordsDocAccessFields"] | undefined,
	create?: GraphQLTypes["ViewRecordsCreateDocAccess"] | undefined,
	read?: GraphQLTypes["ViewRecordsReadDocAccess"] | undefined,
	update?: GraphQLTypes["ViewRecordsUpdateDocAccess"] | undefined,
	delete?: GraphQLTypes["ViewRecordsDeleteDocAccess"] | undefined
};
	["ViewRecordsDocAccessFields"]: {
	__typename: "ViewRecordsDocAccessFields",
	user?: GraphQLTypes["ViewRecordsDocAccessFields_user"] | undefined,
	item?: GraphQLTypes["ViewRecordsDocAccessFields_item"] | undefined,
	updatedAt?: GraphQLTypes["ViewRecordsDocAccessFields_updatedAt"] | undefined,
	createdAt?: GraphQLTypes["ViewRecordsDocAccessFields_createdAt"] | undefined
};
	["ViewRecordsDocAccessFields_user"]: {
	__typename: "ViewRecordsDocAccessFields_user",
	create?: GraphQLTypes["ViewRecordsDocAccessFields_user_Create"] | undefined,
	read?: GraphQLTypes["ViewRecordsDocAccessFields_user_Read"] | undefined,
	update?: GraphQLTypes["ViewRecordsDocAccessFields_user_Update"] | undefined,
	delete?: GraphQLTypes["ViewRecordsDocAccessFields_user_Delete"] | undefined
};
	["ViewRecordsDocAccessFields_user_Create"]: {
	__typename: "ViewRecordsDocAccessFields_user_Create",
	permission: boolean
};
	["ViewRecordsDocAccessFields_user_Read"]: {
	__typename: "ViewRecordsDocAccessFields_user_Read",
	permission: boolean
};
	["ViewRecordsDocAccessFields_user_Update"]: {
	__typename: "ViewRecordsDocAccessFields_user_Update",
	permission: boolean
};
	["ViewRecordsDocAccessFields_user_Delete"]: {
	__typename: "ViewRecordsDocAccessFields_user_Delete",
	permission: boolean
};
	["ViewRecordsDocAccessFields_item"]: {
	__typename: "ViewRecordsDocAccessFields_item",
	create?: GraphQLTypes["ViewRecordsDocAccessFields_item_Create"] | undefined,
	read?: GraphQLTypes["ViewRecordsDocAccessFields_item_Read"] | undefined,
	update?: GraphQLTypes["ViewRecordsDocAccessFields_item_Update"] | undefined,
	delete?: GraphQLTypes["ViewRecordsDocAccessFields_item_Delete"] | undefined
};
	["ViewRecordsDocAccessFields_item_Create"]: {
	__typename: "ViewRecordsDocAccessFields_item_Create",
	permission: boolean
};
	["ViewRecordsDocAccessFields_item_Read"]: {
	__typename: "ViewRecordsDocAccessFields_item_Read",
	permission: boolean
};
	["ViewRecordsDocAccessFields_item_Update"]: {
	__typename: "ViewRecordsDocAccessFields_item_Update",
	permission: boolean
};
	["ViewRecordsDocAccessFields_item_Delete"]: {
	__typename: "ViewRecordsDocAccessFields_item_Delete",
	permission: boolean
};
	["ViewRecordsDocAccessFields_updatedAt"]: {
	__typename: "ViewRecordsDocAccessFields_updatedAt",
	create?: GraphQLTypes["ViewRecordsDocAccessFields_updatedAt_Create"] | undefined,
	read?: GraphQLTypes["ViewRecordsDocAccessFields_updatedAt_Read"] | undefined,
	update?: GraphQLTypes["ViewRecordsDocAccessFields_updatedAt_Update"] | undefined,
	delete?: GraphQLTypes["ViewRecordsDocAccessFields_updatedAt_Delete"] | undefined
};
	["ViewRecordsDocAccessFields_updatedAt_Create"]: {
	__typename: "ViewRecordsDocAccessFields_updatedAt_Create",
	permission: boolean
};
	["ViewRecordsDocAccessFields_updatedAt_Read"]: {
	__typename: "ViewRecordsDocAccessFields_updatedAt_Read",
	permission: boolean
};
	["ViewRecordsDocAccessFields_updatedAt_Update"]: {
	__typename: "ViewRecordsDocAccessFields_updatedAt_Update",
	permission: boolean
};
	["ViewRecordsDocAccessFields_updatedAt_Delete"]: {
	__typename: "ViewRecordsDocAccessFields_updatedAt_Delete",
	permission: boolean
};
	["ViewRecordsDocAccessFields_createdAt"]: {
	__typename: "ViewRecordsDocAccessFields_createdAt",
	create?: GraphQLTypes["ViewRecordsDocAccessFields_createdAt_Create"] | undefined,
	read?: GraphQLTypes["ViewRecordsDocAccessFields_createdAt_Read"] | undefined,
	update?: GraphQLTypes["ViewRecordsDocAccessFields_createdAt_Update"] | undefined,
	delete?: GraphQLTypes["ViewRecordsDocAccessFields_createdAt_Delete"] | undefined
};
	["ViewRecordsDocAccessFields_createdAt_Create"]: {
	__typename: "ViewRecordsDocAccessFields_createdAt_Create",
	permission: boolean
};
	["ViewRecordsDocAccessFields_createdAt_Read"]: {
	__typename: "ViewRecordsDocAccessFields_createdAt_Read",
	permission: boolean
};
	["ViewRecordsDocAccessFields_createdAt_Update"]: {
	__typename: "ViewRecordsDocAccessFields_createdAt_Update",
	permission: boolean
};
	["ViewRecordsDocAccessFields_createdAt_Delete"]: {
	__typename: "ViewRecordsDocAccessFields_createdAt_Delete",
	permission: boolean
};
	["ViewRecordsCreateDocAccess"]: {
	__typename: "ViewRecordsCreateDocAccess",
	permission: boolean,
	where?: GraphQLTypes["JSONObject"] | undefined
};
	["ViewRecordsReadDocAccess"]: {
	__typename: "ViewRecordsReadDocAccess",
	permission: boolean,
	where?: GraphQLTypes["JSONObject"] | undefined
};
	["ViewRecordsUpdateDocAccess"]: {
	__typename: "ViewRecordsUpdateDocAccess",
	permission: boolean,
	where?: GraphQLTypes["JSONObject"] | undefined
};
	["ViewRecordsDeleteDocAccess"]: {
	__typename: "ViewRecordsDeleteDocAccess",
	permission: boolean,
	where?: GraphQLTypes["JSONObject"] | undefined
};
	["Comment"]: {
	__typename: "Comment",
	id?: string | undefined,
	commenter?: GraphQLTypes["User"] | undefined,
	content?: string | undefined,
	image?: GraphQLTypes["Media"] | undefined,
	target?: GraphQLTypes["Comment_Target_Relationship"] | undefined,
	parent?: GraphQLTypes["Comment_Parent_Relationship"] | undefined,
	isReviewed?: boolean | undefined,
	reviewedBy?: GraphQLTypes["User"] | undefined,
	banned?: boolean | undefined,
	type?: string | undefined,
	updatedAt?: GraphQLTypes["DateTime"] | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined
};
	["Comment_Image_where"]: {
		alt?: GraphQLTypes["Comment_Image_alt_operator"] | undefined,
	updatedAt?: GraphQLTypes["Comment_Image_updatedAt_operator"] | undefined,
	createdAt?: GraphQLTypes["Comment_Image_createdAt_operator"] | undefined,
	url?: GraphQLTypes["Comment_Image_url_operator"] | undefined,
	filename?: GraphQLTypes["Comment_Image_filename_operator"] | undefined,
	mimeType?: GraphQLTypes["Comment_Image_mimeType_operator"] | undefined,
	filesize?: GraphQLTypes["Comment_Image_filesize_operator"] | undefined,
	width?: GraphQLTypes["Comment_Image_width_operator"] | undefined,
	height?: GraphQLTypes["Comment_Image_height_operator"] | undefined,
	focalX?: GraphQLTypes["Comment_Image_focalX_operator"] | undefined,
	focalY?: GraphQLTypes["Comment_Image_focalY_operator"] | undefined,
	sizes__small__url?: GraphQLTypes["Comment_Image_sizes__small__url_operator"] | undefined,
	sizes__small__width?: GraphQLTypes["Comment_Image_sizes__small__width_operator"] | undefined,
	sizes__small__height?: GraphQLTypes["Comment_Image_sizes__small__height_operator"] | undefined,
	sizes__small__mimeType?: GraphQLTypes["Comment_Image_sizes__small__mimeType_operator"] | undefined,
	sizes__small__filesize?: GraphQLTypes["Comment_Image_sizes__small__filesize_operator"] | undefined,
	sizes__small__filename?: GraphQLTypes["Comment_Image_sizes__small__filename_operator"] | undefined,
	sizes__medium__url?: GraphQLTypes["Comment_Image_sizes__medium__url_operator"] | undefined,
	sizes__medium__width?: GraphQLTypes["Comment_Image_sizes__medium__width_operator"] | undefined,
	sizes__medium__height?: GraphQLTypes["Comment_Image_sizes__medium__height_operator"] | undefined,
	sizes__medium__mimeType?: GraphQLTypes["Comment_Image_sizes__medium__mimeType_operator"] | undefined,
	sizes__medium__filesize?: GraphQLTypes["Comment_Image_sizes__medium__filesize_operator"] | undefined,
	sizes__medium__filename?: GraphQLTypes["Comment_Image_sizes__medium__filename_operator"] | undefined,
	sizes__large__url?: GraphQLTypes["Comment_Image_sizes__large__url_operator"] | undefined,
	sizes__large__width?: GraphQLTypes["Comment_Image_sizes__large__width_operator"] | undefined,
	sizes__large__height?: GraphQLTypes["Comment_Image_sizes__large__height_operator"] | undefined,
	sizes__large__mimeType?: GraphQLTypes["Comment_Image_sizes__large__mimeType_operator"] | undefined,
	sizes__large__filesize?: GraphQLTypes["Comment_Image_sizes__large__filesize_operator"] | undefined,
	sizes__large__filename?: GraphQLTypes["Comment_Image_sizes__large__filename_operator"] | undefined,
	sizes__small_jpeg__url?: GraphQLTypes["Comment_Image_sizes__small_jpeg__url_operator"] | undefined,
	sizes__small_jpeg__width?: GraphQLTypes["Comment_Image_sizes__small_jpeg__width_operator"] | undefined,
	sizes__small_jpeg__height?: GraphQLTypes["Comment_Image_sizes__small_jpeg__height_operator"] | undefined,
	sizes__small_jpeg__mimeType?: GraphQLTypes["Comment_Image_sizes__small_jpeg__mimeType_operator"] | undefined,
	sizes__small_jpeg__filesize?: GraphQLTypes["Comment_Image_sizes__small_jpeg__filesize_operator"] | undefined,
	sizes__small_jpeg__filename?: GraphQLTypes["Comment_Image_sizes__small_jpeg__filename_operator"] | undefined,
	sizes__medium_jpeg__url?: GraphQLTypes["Comment_Image_sizes__medium_jpeg__url_operator"] | undefined,
	sizes__medium_jpeg__width?: GraphQLTypes["Comment_Image_sizes__medium_jpeg__width_operator"] | undefined,
	sizes__medium_jpeg__height?: GraphQLTypes["Comment_Image_sizes__medium_jpeg__height_operator"] | undefined,
	sizes__medium_jpeg__mimeType?: GraphQLTypes["Comment_Image_sizes__medium_jpeg__mimeType_operator"] | undefined,
	sizes__medium_jpeg__filesize?: GraphQLTypes["Comment_Image_sizes__medium_jpeg__filesize_operator"] | undefined,
	sizes__medium_jpeg__filename?: GraphQLTypes["Comment_Image_sizes__medium_jpeg__filename_operator"] | undefined,
	sizes__large_jpeg__url?: GraphQLTypes["Comment_Image_sizes__large_jpeg__url_operator"] | undefined,
	sizes__large_jpeg__width?: GraphQLTypes["Comment_Image_sizes__large_jpeg__width_operator"] | undefined,
	sizes__large_jpeg__height?: GraphQLTypes["Comment_Image_sizes__large_jpeg__height_operator"] | undefined,
	sizes__large_jpeg__mimeType?: GraphQLTypes["Comment_Image_sizes__large_jpeg__mimeType_operator"] | undefined,
	sizes__large_jpeg__filesize?: GraphQLTypes["Comment_Image_sizes__large_jpeg__filesize_operator"] | undefined,
	sizes__large_jpeg__filename?: GraphQLTypes["Comment_Image_sizes__large_jpeg__filename_operator"] | undefined,
	id?: GraphQLTypes["Comment_Image_id_operator"] | undefined,
	AND?: Array<GraphQLTypes["Comment_Image_where_and"] | undefined> | undefined,
	OR?: Array<GraphQLTypes["Comment_Image_where_or"] | undefined> | undefined
};
	["Comment_Image_alt_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Comment_Image_updatedAt_operator"]: {
		equals?: GraphQLTypes["DateTime"] | undefined,
	not_equals?: GraphQLTypes["DateTime"] | undefined,
	greater_than_equal?: GraphQLTypes["DateTime"] | undefined,
	greater_than?: GraphQLTypes["DateTime"] | undefined,
	less_than_equal?: GraphQLTypes["DateTime"] | undefined,
	less_than?: GraphQLTypes["DateTime"] | undefined,
	like?: GraphQLTypes["DateTime"] | undefined,
	exists?: boolean | undefined
};
	["Comment_Image_createdAt_operator"]: {
		equals?: GraphQLTypes["DateTime"] | undefined,
	not_equals?: GraphQLTypes["DateTime"] | undefined,
	greater_than_equal?: GraphQLTypes["DateTime"] | undefined,
	greater_than?: GraphQLTypes["DateTime"] | undefined,
	less_than_equal?: GraphQLTypes["DateTime"] | undefined,
	less_than?: GraphQLTypes["DateTime"] | undefined,
	like?: GraphQLTypes["DateTime"] | undefined,
	exists?: boolean | undefined
};
	["Comment_Image_url_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Comment_Image_filename_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Comment_Image_mimeType_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Comment_Image_filesize_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Comment_Image_width_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Comment_Image_height_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Comment_Image_focalX_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Comment_Image_focalY_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Comment_Image_sizes__small__url_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Comment_Image_sizes__small__width_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Comment_Image_sizes__small__height_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Comment_Image_sizes__small__mimeType_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Comment_Image_sizes__small__filesize_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Comment_Image_sizes__small__filename_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Comment_Image_sizes__medium__url_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Comment_Image_sizes__medium__width_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Comment_Image_sizes__medium__height_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Comment_Image_sizes__medium__mimeType_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Comment_Image_sizes__medium__filesize_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Comment_Image_sizes__medium__filename_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Comment_Image_sizes__large__url_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Comment_Image_sizes__large__width_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Comment_Image_sizes__large__height_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Comment_Image_sizes__large__mimeType_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Comment_Image_sizes__large__filesize_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Comment_Image_sizes__large__filename_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Comment_Image_sizes__small_jpeg__url_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Comment_Image_sizes__small_jpeg__width_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Comment_Image_sizes__small_jpeg__height_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Comment_Image_sizes__small_jpeg__mimeType_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Comment_Image_sizes__small_jpeg__filesize_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Comment_Image_sizes__small_jpeg__filename_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Comment_Image_sizes__medium_jpeg__url_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Comment_Image_sizes__medium_jpeg__width_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Comment_Image_sizes__medium_jpeg__height_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Comment_Image_sizes__medium_jpeg__mimeType_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Comment_Image_sizes__medium_jpeg__filesize_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Comment_Image_sizes__medium_jpeg__filename_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Comment_Image_sizes__large_jpeg__url_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Comment_Image_sizes__large_jpeg__width_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Comment_Image_sizes__large_jpeg__height_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Comment_Image_sizes__large_jpeg__mimeType_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Comment_Image_sizes__large_jpeg__filesize_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Comment_Image_sizes__large_jpeg__filename_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Comment_Image_id_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Comment_Image_where_and"]: {
		alt?: GraphQLTypes["Comment_Image_alt_operator"] | undefined,
	updatedAt?: GraphQLTypes["Comment_Image_updatedAt_operator"] | undefined,
	createdAt?: GraphQLTypes["Comment_Image_createdAt_operator"] | undefined,
	url?: GraphQLTypes["Comment_Image_url_operator"] | undefined,
	filename?: GraphQLTypes["Comment_Image_filename_operator"] | undefined,
	mimeType?: GraphQLTypes["Comment_Image_mimeType_operator"] | undefined,
	filesize?: GraphQLTypes["Comment_Image_filesize_operator"] | undefined,
	width?: GraphQLTypes["Comment_Image_width_operator"] | undefined,
	height?: GraphQLTypes["Comment_Image_height_operator"] | undefined,
	focalX?: GraphQLTypes["Comment_Image_focalX_operator"] | undefined,
	focalY?: GraphQLTypes["Comment_Image_focalY_operator"] | undefined,
	sizes__small__url?: GraphQLTypes["Comment_Image_sizes__small__url_operator"] | undefined,
	sizes__small__width?: GraphQLTypes["Comment_Image_sizes__small__width_operator"] | undefined,
	sizes__small__height?: GraphQLTypes["Comment_Image_sizes__small__height_operator"] | undefined,
	sizes__small__mimeType?: GraphQLTypes["Comment_Image_sizes__small__mimeType_operator"] | undefined,
	sizes__small__filesize?: GraphQLTypes["Comment_Image_sizes__small__filesize_operator"] | undefined,
	sizes__small__filename?: GraphQLTypes["Comment_Image_sizes__small__filename_operator"] | undefined,
	sizes__medium__url?: GraphQLTypes["Comment_Image_sizes__medium__url_operator"] | undefined,
	sizes__medium__width?: GraphQLTypes["Comment_Image_sizes__medium__width_operator"] | undefined,
	sizes__medium__height?: GraphQLTypes["Comment_Image_sizes__medium__height_operator"] | undefined,
	sizes__medium__mimeType?: GraphQLTypes["Comment_Image_sizes__medium__mimeType_operator"] | undefined,
	sizes__medium__filesize?: GraphQLTypes["Comment_Image_sizes__medium__filesize_operator"] | undefined,
	sizes__medium__filename?: GraphQLTypes["Comment_Image_sizes__medium__filename_operator"] | undefined,
	sizes__large__url?: GraphQLTypes["Comment_Image_sizes__large__url_operator"] | undefined,
	sizes__large__width?: GraphQLTypes["Comment_Image_sizes__large__width_operator"] | undefined,
	sizes__large__height?: GraphQLTypes["Comment_Image_sizes__large__height_operator"] | undefined,
	sizes__large__mimeType?: GraphQLTypes["Comment_Image_sizes__large__mimeType_operator"] | undefined,
	sizes__large__filesize?: GraphQLTypes["Comment_Image_sizes__large__filesize_operator"] | undefined,
	sizes__large__filename?: GraphQLTypes["Comment_Image_sizes__large__filename_operator"] | undefined,
	sizes__small_jpeg__url?: GraphQLTypes["Comment_Image_sizes__small_jpeg__url_operator"] | undefined,
	sizes__small_jpeg__width?: GraphQLTypes["Comment_Image_sizes__small_jpeg__width_operator"] | undefined,
	sizes__small_jpeg__height?: GraphQLTypes["Comment_Image_sizes__small_jpeg__height_operator"] | undefined,
	sizes__small_jpeg__mimeType?: GraphQLTypes["Comment_Image_sizes__small_jpeg__mimeType_operator"] | undefined,
	sizes__small_jpeg__filesize?: GraphQLTypes["Comment_Image_sizes__small_jpeg__filesize_operator"] | undefined,
	sizes__small_jpeg__filename?: GraphQLTypes["Comment_Image_sizes__small_jpeg__filename_operator"] | undefined,
	sizes__medium_jpeg__url?: GraphQLTypes["Comment_Image_sizes__medium_jpeg__url_operator"] | undefined,
	sizes__medium_jpeg__width?: GraphQLTypes["Comment_Image_sizes__medium_jpeg__width_operator"] | undefined,
	sizes__medium_jpeg__height?: GraphQLTypes["Comment_Image_sizes__medium_jpeg__height_operator"] | undefined,
	sizes__medium_jpeg__mimeType?: GraphQLTypes["Comment_Image_sizes__medium_jpeg__mimeType_operator"] | undefined,
	sizes__medium_jpeg__filesize?: GraphQLTypes["Comment_Image_sizes__medium_jpeg__filesize_operator"] | undefined,
	sizes__medium_jpeg__filename?: GraphQLTypes["Comment_Image_sizes__medium_jpeg__filename_operator"] | undefined,
	sizes__large_jpeg__url?: GraphQLTypes["Comment_Image_sizes__large_jpeg__url_operator"] | undefined,
	sizes__large_jpeg__width?: GraphQLTypes["Comment_Image_sizes__large_jpeg__width_operator"] | undefined,
	sizes__large_jpeg__height?: GraphQLTypes["Comment_Image_sizes__large_jpeg__height_operator"] | undefined,
	sizes__large_jpeg__mimeType?: GraphQLTypes["Comment_Image_sizes__large_jpeg__mimeType_operator"] | undefined,
	sizes__large_jpeg__filesize?: GraphQLTypes["Comment_Image_sizes__large_jpeg__filesize_operator"] | undefined,
	sizes__large_jpeg__filename?: GraphQLTypes["Comment_Image_sizes__large_jpeg__filename_operator"] | undefined,
	id?: GraphQLTypes["Comment_Image_id_operator"] | undefined,
	AND?: Array<GraphQLTypes["Comment_Image_where_and"] | undefined> | undefined,
	OR?: Array<GraphQLTypes["Comment_Image_where_or"] | undefined> | undefined
};
	["Comment_Image_where_or"]: {
		alt?: GraphQLTypes["Comment_Image_alt_operator"] | undefined,
	updatedAt?: GraphQLTypes["Comment_Image_updatedAt_operator"] | undefined,
	createdAt?: GraphQLTypes["Comment_Image_createdAt_operator"] | undefined,
	url?: GraphQLTypes["Comment_Image_url_operator"] | undefined,
	filename?: GraphQLTypes["Comment_Image_filename_operator"] | undefined,
	mimeType?: GraphQLTypes["Comment_Image_mimeType_operator"] | undefined,
	filesize?: GraphQLTypes["Comment_Image_filesize_operator"] | undefined,
	width?: GraphQLTypes["Comment_Image_width_operator"] | undefined,
	height?: GraphQLTypes["Comment_Image_height_operator"] | undefined,
	focalX?: GraphQLTypes["Comment_Image_focalX_operator"] | undefined,
	focalY?: GraphQLTypes["Comment_Image_focalY_operator"] | undefined,
	sizes__small__url?: GraphQLTypes["Comment_Image_sizes__small__url_operator"] | undefined,
	sizes__small__width?: GraphQLTypes["Comment_Image_sizes__small__width_operator"] | undefined,
	sizes__small__height?: GraphQLTypes["Comment_Image_sizes__small__height_operator"] | undefined,
	sizes__small__mimeType?: GraphQLTypes["Comment_Image_sizes__small__mimeType_operator"] | undefined,
	sizes__small__filesize?: GraphQLTypes["Comment_Image_sizes__small__filesize_operator"] | undefined,
	sizes__small__filename?: GraphQLTypes["Comment_Image_sizes__small__filename_operator"] | undefined,
	sizes__medium__url?: GraphQLTypes["Comment_Image_sizes__medium__url_operator"] | undefined,
	sizes__medium__width?: GraphQLTypes["Comment_Image_sizes__medium__width_operator"] | undefined,
	sizes__medium__height?: GraphQLTypes["Comment_Image_sizes__medium__height_operator"] | undefined,
	sizes__medium__mimeType?: GraphQLTypes["Comment_Image_sizes__medium__mimeType_operator"] | undefined,
	sizes__medium__filesize?: GraphQLTypes["Comment_Image_sizes__medium__filesize_operator"] | undefined,
	sizes__medium__filename?: GraphQLTypes["Comment_Image_sizes__medium__filename_operator"] | undefined,
	sizes__large__url?: GraphQLTypes["Comment_Image_sizes__large__url_operator"] | undefined,
	sizes__large__width?: GraphQLTypes["Comment_Image_sizes__large__width_operator"] | undefined,
	sizes__large__height?: GraphQLTypes["Comment_Image_sizes__large__height_operator"] | undefined,
	sizes__large__mimeType?: GraphQLTypes["Comment_Image_sizes__large__mimeType_operator"] | undefined,
	sizes__large__filesize?: GraphQLTypes["Comment_Image_sizes__large__filesize_operator"] | undefined,
	sizes__large__filename?: GraphQLTypes["Comment_Image_sizes__large__filename_operator"] | undefined,
	sizes__small_jpeg__url?: GraphQLTypes["Comment_Image_sizes__small_jpeg__url_operator"] | undefined,
	sizes__small_jpeg__width?: GraphQLTypes["Comment_Image_sizes__small_jpeg__width_operator"] | undefined,
	sizes__small_jpeg__height?: GraphQLTypes["Comment_Image_sizes__small_jpeg__height_operator"] | undefined,
	sizes__small_jpeg__mimeType?: GraphQLTypes["Comment_Image_sizes__small_jpeg__mimeType_operator"] | undefined,
	sizes__small_jpeg__filesize?: GraphQLTypes["Comment_Image_sizes__small_jpeg__filesize_operator"] | undefined,
	sizes__small_jpeg__filename?: GraphQLTypes["Comment_Image_sizes__small_jpeg__filename_operator"] | undefined,
	sizes__medium_jpeg__url?: GraphQLTypes["Comment_Image_sizes__medium_jpeg__url_operator"] | undefined,
	sizes__medium_jpeg__width?: GraphQLTypes["Comment_Image_sizes__medium_jpeg__width_operator"] | undefined,
	sizes__medium_jpeg__height?: GraphQLTypes["Comment_Image_sizes__medium_jpeg__height_operator"] | undefined,
	sizes__medium_jpeg__mimeType?: GraphQLTypes["Comment_Image_sizes__medium_jpeg__mimeType_operator"] | undefined,
	sizes__medium_jpeg__filesize?: GraphQLTypes["Comment_Image_sizes__medium_jpeg__filesize_operator"] | undefined,
	sizes__medium_jpeg__filename?: GraphQLTypes["Comment_Image_sizes__medium_jpeg__filename_operator"] | undefined,
	sizes__large_jpeg__url?: GraphQLTypes["Comment_Image_sizes__large_jpeg__url_operator"] | undefined,
	sizes__large_jpeg__width?: GraphQLTypes["Comment_Image_sizes__large_jpeg__width_operator"] | undefined,
	sizes__large_jpeg__height?: GraphQLTypes["Comment_Image_sizes__large_jpeg__height_operator"] | undefined,
	sizes__large_jpeg__mimeType?: GraphQLTypes["Comment_Image_sizes__large_jpeg__mimeType_operator"] | undefined,
	sizes__large_jpeg__filesize?: GraphQLTypes["Comment_Image_sizes__large_jpeg__filesize_operator"] | undefined,
	sizes__large_jpeg__filename?: GraphQLTypes["Comment_Image_sizes__large_jpeg__filename_operator"] | undefined,
	id?: GraphQLTypes["Comment_Image_id_operator"] | undefined,
	AND?: Array<GraphQLTypes["Comment_Image_where_and"] | undefined> | undefined,
	OR?: Array<GraphQLTypes["Comment_Image_where_or"] | undefined> | undefined
};
	["Comment_Target_Relationship"]: {
	__typename: "Comment_Target_Relationship",
	relationTo?: GraphQLTypes["Comment_Target_RelationTo"] | undefined,
	value?: GraphQLTypes["Comment_Target"] | undefined
};
	["Comment_Target_RelationTo"]: Comment_Target_RelationTo;
	["Comment_Target"]:{
        	__typename:"Event" | "Post" | "Location"
        	['...on Event']: '__union' & GraphQLTypes["Event"];
	['...on Post']: '__union' & GraphQLTypes["Post"];
	['...on Location']: '__union' & GraphQLTypes["Location"];
};
	["Comment_Parent_Relationship"]: {
	__typename: "Comment_Parent_Relationship",
	relationTo?: GraphQLTypes["Comment_Parent_RelationTo"] | undefined,
	value?: GraphQLTypes["Comment_Parent"] | undefined
};
	["Comment_Parent_RelationTo"]: Comment_Parent_RelationTo;
	["Comment_Parent"]:{
        	__typename:"Comment"
        	['...on Comment']: '__union' & GraphQLTypes["Comment"];
};
	["Comments"]: {
	__typename: "Comments",
	docs?: Array<GraphQLTypes["Comment"] | undefined> | undefined,
	hasNextPage?: boolean | undefined,
	hasPrevPage?: boolean | undefined,
	limit?: number | undefined,
	nextPage?: number | undefined,
	offset?: number | undefined,
	page?: number | undefined,
	pagingCounter?: number | undefined,
	prevPage?: number | undefined,
	totalDocs?: number | undefined,
	totalPages?: number | undefined
};
	["Comment_where"]: {
		commenter?: GraphQLTypes["Comment_commenter_operator"] | undefined,
	content?: GraphQLTypes["Comment_content_operator"] | undefined,
	image?: GraphQLTypes["Comment_image_operator"] | undefined,
	target?: GraphQLTypes["Comment_target_Relation"] | undefined,
	parent?: GraphQLTypes["Comment_parent_Relation"] | undefined,
	isReviewed?: GraphQLTypes["Comment_isReviewed_operator"] | undefined,
	reviewedBy?: GraphQLTypes["Comment_reviewedBy_operator"] | undefined,
	banned?: GraphQLTypes["Comment_banned_operator"] | undefined,
	type?: GraphQLTypes["Comment_type_operator"] | undefined,
	updatedAt?: GraphQLTypes["Comment_updatedAt_operator"] | undefined,
	createdAt?: GraphQLTypes["Comment_createdAt_operator"] | undefined,
	id?: GraphQLTypes["Comment_id_operator"] | undefined,
	AND?: Array<GraphQLTypes["Comment_where_and"] | undefined> | undefined,
	OR?: Array<GraphQLTypes["Comment_where_or"] | undefined> | undefined
};
	["Comment_commenter_operator"]: {
		equals?: GraphQLTypes["JSON"] | undefined,
	not_equals?: GraphQLTypes["JSON"] | undefined,
	in?: Array<GraphQLTypes["JSON"] | undefined> | undefined,
	not_in?: Array<GraphQLTypes["JSON"] | undefined> | undefined,
	all?: Array<GraphQLTypes["JSON"] | undefined> | undefined,
	exists?: boolean | undefined
};
	["Comment_content_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Comment_image_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	exists?: boolean | undefined
};
	["Comment_target_Relation"]: {
		relationTo?: GraphQLTypes["Comment_target_Relation_RelationTo"] | undefined,
	value?: GraphQLTypes["JSON"] | undefined
};
	["Comment_target_Relation_RelationTo"]: Comment_target_Relation_RelationTo;
	["Comment_parent_Relation"]: {
		relationTo?: GraphQLTypes["Comment_parent_Relation_RelationTo"] | undefined,
	value?: GraphQLTypes["JSON"] | undefined
};
	["Comment_parent_Relation_RelationTo"]: Comment_parent_Relation_RelationTo;
	["Comment_isReviewed_operator"]: {
		equals?: boolean | undefined,
	not_equals?: boolean | undefined,
	exists?: boolean | undefined
};
	["Comment_reviewedBy_operator"]: {
		equals?: GraphQLTypes["JSON"] | undefined,
	not_equals?: GraphQLTypes["JSON"] | undefined,
	in?: Array<GraphQLTypes["JSON"] | undefined> | undefined,
	not_in?: Array<GraphQLTypes["JSON"] | undefined> | undefined,
	all?: Array<GraphQLTypes["JSON"] | undefined> | undefined,
	exists?: boolean | undefined
};
	["Comment_banned_operator"]: {
		equals?: boolean | undefined,
	not_equals?: boolean | undefined,
	exists?: boolean | undefined
};
	["Comment_type_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Comment_updatedAt_operator"]: {
		equals?: GraphQLTypes["DateTime"] | undefined,
	not_equals?: GraphQLTypes["DateTime"] | undefined,
	greater_than_equal?: GraphQLTypes["DateTime"] | undefined,
	greater_than?: GraphQLTypes["DateTime"] | undefined,
	less_than_equal?: GraphQLTypes["DateTime"] | undefined,
	less_than?: GraphQLTypes["DateTime"] | undefined,
	like?: GraphQLTypes["DateTime"] | undefined,
	exists?: boolean | undefined
};
	["Comment_createdAt_operator"]: {
		equals?: GraphQLTypes["DateTime"] | undefined,
	not_equals?: GraphQLTypes["DateTime"] | undefined,
	greater_than_equal?: GraphQLTypes["DateTime"] | undefined,
	greater_than?: GraphQLTypes["DateTime"] | undefined,
	less_than_equal?: GraphQLTypes["DateTime"] | undefined,
	less_than?: GraphQLTypes["DateTime"] | undefined,
	like?: GraphQLTypes["DateTime"] | undefined,
	exists?: boolean | undefined
};
	["Comment_id_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Comment_where_and"]: {
		commenter?: GraphQLTypes["Comment_commenter_operator"] | undefined,
	content?: GraphQLTypes["Comment_content_operator"] | undefined,
	image?: GraphQLTypes["Comment_image_operator"] | undefined,
	target?: GraphQLTypes["Comment_target_Relation"] | undefined,
	parent?: GraphQLTypes["Comment_parent_Relation"] | undefined,
	isReviewed?: GraphQLTypes["Comment_isReviewed_operator"] | undefined,
	reviewedBy?: GraphQLTypes["Comment_reviewedBy_operator"] | undefined,
	banned?: GraphQLTypes["Comment_banned_operator"] | undefined,
	type?: GraphQLTypes["Comment_type_operator"] | undefined,
	updatedAt?: GraphQLTypes["Comment_updatedAt_operator"] | undefined,
	createdAt?: GraphQLTypes["Comment_createdAt_operator"] | undefined,
	id?: GraphQLTypes["Comment_id_operator"] | undefined,
	AND?: Array<GraphQLTypes["Comment_where_and"] | undefined> | undefined,
	OR?: Array<GraphQLTypes["Comment_where_or"] | undefined> | undefined
};
	["Comment_where_or"]: {
		commenter?: GraphQLTypes["Comment_commenter_operator"] | undefined,
	content?: GraphQLTypes["Comment_content_operator"] | undefined,
	image?: GraphQLTypes["Comment_image_operator"] | undefined,
	target?: GraphQLTypes["Comment_target_Relation"] | undefined,
	parent?: GraphQLTypes["Comment_parent_Relation"] | undefined,
	isReviewed?: GraphQLTypes["Comment_isReviewed_operator"] | undefined,
	reviewedBy?: GraphQLTypes["Comment_reviewedBy_operator"] | undefined,
	banned?: GraphQLTypes["Comment_banned_operator"] | undefined,
	type?: GraphQLTypes["Comment_type_operator"] | undefined,
	updatedAt?: GraphQLTypes["Comment_updatedAt_operator"] | undefined,
	createdAt?: GraphQLTypes["Comment_createdAt_operator"] | undefined,
	id?: GraphQLTypes["Comment_id_operator"] | undefined,
	AND?: Array<GraphQLTypes["Comment_where_and"] | undefined> | undefined,
	OR?: Array<GraphQLTypes["Comment_where_or"] | undefined> | undefined
};
	["countComments"]: {
	__typename: "countComments",
	totalDocs?: number | undefined
};
	["commentsDocAccess"]: {
	__typename: "commentsDocAccess",
	fields?: GraphQLTypes["CommentsDocAccessFields"] | undefined,
	create?: GraphQLTypes["CommentsCreateDocAccess"] | undefined,
	read?: GraphQLTypes["CommentsReadDocAccess"] | undefined,
	update?: GraphQLTypes["CommentsUpdateDocAccess"] | undefined,
	delete?: GraphQLTypes["CommentsDeleteDocAccess"] | undefined
};
	["CommentsDocAccessFields"]: {
	__typename: "CommentsDocAccessFields",
	commenter?: GraphQLTypes["CommentsDocAccessFields_commenter"] | undefined,
	content?: GraphQLTypes["CommentsDocAccessFields_content"] | undefined,
	image?: GraphQLTypes["CommentsDocAccessFields_image"] | undefined,
	target?: GraphQLTypes["CommentsDocAccessFields_target"] | undefined,
	parent?: GraphQLTypes["CommentsDocAccessFields_parent"] | undefined,
	isReviewed?: GraphQLTypes["CommentsDocAccessFields_isReviewed"] | undefined,
	reviewedBy?: GraphQLTypes["CommentsDocAccessFields_reviewedBy"] | undefined,
	banned?: GraphQLTypes["CommentsDocAccessFields_banned"] | undefined,
	type?: GraphQLTypes["CommentsDocAccessFields_type"] | undefined,
	updatedAt?: GraphQLTypes["CommentsDocAccessFields_updatedAt"] | undefined,
	createdAt?: GraphQLTypes["CommentsDocAccessFields_createdAt"] | undefined
};
	["CommentsDocAccessFields_commenter"]: {
	__typename: "CommentsDocAccessFields_commenter",
	create?: GraphQLTypes["CommentsDocAccessFields_commenter_Create"] | undefined,
	read?: GraphQLTypes["CommentsDocAccessFields_commenter_Read"] | undefined,
	update?: GraphQLTypes["CommentsDocAccessFields_commenter_Update"] | undefined,
	delete?: GraphQLTypes["CommentsDocAccessFields_commenter_Delete"] | undefined
};
	["CommentsDocAccessFields_commenter_Create"]: {
	__typename: "CommentsDocAccessFields_commenter_Create",
	permission: boolean
};
	["CommentsDocAccessFields_commenter_Read"]: {
	__typename: "CommentsDocAccessFields_commenter_Read",
	permission: boolean
};
	["CommentsDocAccessFields_commenter_Update"]: {
	__typename: "CommentsDocAccessFields_commenter_Update",
	permission: boolean
};
	["CommentsDocAccessFields_commenter_Delete"]: {
	__typename: "CommentsDocAccessFields_commenter_Delete",
	permission: boolean
};
	["CommentsDocAccessFields_content"]: {
	__typename: "CommentsDocAccessFields_content",
	create?: GraphQLTypes["CommentsDocAccessFields_content_Create"] | undefined,
	read?: GraphQLTypes["CommentsDocAccessFields_content_Read"] | undefined,
	update?: GraphQLTypes["CommentsDocAccessFields_content_Update"] | undefined,
	delete?: GraphQLTypes["CommentsDocAccessFields_content_Delete"] | undefined
};
	["CommentsDocAccessFields_content_Create"]: {
	__typename: "CommentsDocAccessFields_content_Create",
	permission: boolean
};
	["CommentsDocAccessFields_content_Read"]: {
	__typename: "CommentsDocAccessFields_content_Read",
	permission: boolean
};
	["CommentsDocAccessFields_content_Update"]: {
	__typename: "CommentsDocAccessFields_content_Update",
	permission: boolean
};
	["CommentsDocAccessFields_content_Delete"]: {
	__typename: "CommentsDocAccessFields_content_Delete",
	permission: boolean
};
	["CommentsDocAccessFields_image"]: {
	__typename: "CommentsDocAccessFields_image",
	create?: GraphQLTypes["CommentsDocAccessFields_image_Create"] | undefined,
	read?: GraphQLTypes["CommentsDocAccessFields_image_Read"] | undefined,
	update?: GraphQLTypes["CommentsDocAccessFields_image_Update"] | undefined,
	delete?: GraphQLTypes["CommentsDocAccessFields_image_Delete"] | undefined
};
	["CommentsDocAccessFields_image_Create"]: {
	__typename: "CommentsDocAccessFields_image_Create",
	permission: boolean
};
	["CommentsDocAccessFields_image_Read"]: {
	__typename: "CommentsDocAccessFields_image_Read",
	permission: boolean
};
	["CommentsDocAccessFields_image_Update"]: {
	__typename: "CommentsDocAccessFields_image_Update",
	permission: boolean
};
	["CommentsDocAccessFields_image_Delete"]: {
	__typename: "CommentsDocAccessFields_image_Delete",
	permission: boolean
};
	["CommentsDocAccessFields_target"]: {
	__typename: "CommentsDocAccessFields_target",
	create?: GraphQLTypes["CommentsDocAccessFields_target_Create"] | undefined,
	read?: GraphQLTypes["CommentsDocAccessFields_target_Read"] | undefined,
	update?: GraphQLTypes["CommentsDocAccessFields_target_Update"] | undefined,
	delete?: GraphQLTypes["CommentsDocAccessFields_target_Delete"] | undefined
};
	["CommentsDocAccessFields_target_Create"]: {
	__typename: "CommentsDocAccessFields_target_Create",
	permission: boolean
};
	["CommentsDocAccessFields_target_Read"]: {
	__typename: "CommentsDocAccessFields_target_Read",
	permission: boolean
};
	["CommentsDocAccessFields_target_Update"]: {
	__typename: "CommentsDocAccessFields_target_Update",
	permission: boolean
};
	["CommentsDocAccessFields_target_Delete"]: {
	__typename: "CommentsDocAccessFields_target_Delete",
	permission: boolean
};
	["CommentsDocAccessFields_parent"]: {
	__typename: "CommentsDocAccessFields_parent",
	create?: GraphQLTypes["CommentsDocAccessFields_parent_Create"] | undefined,
	read?: GraphQLTypes["CommentsDocAccessFields_parent_Read"] | undefined,
	update?: GraphQLTypes["CommentsDocAccessFields_parent_Update"] | undefined,
	delete?: GraphQLTypes["CommentsDocAccessFields_parent_Delete"] | undefined
};
	["CommentsDocAccessFields_parent_Create"]: {
	__typename: "CommentsDocAccessFields_parent_Create",
	permission: boolean
};
	["CommentsDocAccessFields_parent_Read"]: {
	__typename: "CommentsDocAccessFields_parent_Read",
	permission: boolean
};
	["CommentsDocAccessFields_parent_Update"]: {
	__typename: "CommentsDocAccessFields_parent_Update",
	permission: boolean
};
	["CommentsDocAccessFields_parent_Delete"]: {
	__typename: "CommentsDocAccessFields_parent_Delete",
	permission: boolean
};
	["CommentsDocAccessFields_isReviewed"]: {
	__typename: "CommentsDocAccessFields_isReviewed",
	create?: GraphQLTypes["CommentsDocAccessFields_isReviewed_Create"] | undefined,
	read?: GraphQLTypes["CommentsDocAccessFields_isReviewed_Read"] | undefined,
	update?: GraphQLTypes["CommentsDocAccessFields_isReviewed_Update"] | undefined,
	delete?: GraphQLTypes["CommentsDocAccessFields_isReviewed_Delete"] | undefined
};
	["CommentsDocAccessFields_isReviewed_Create"]: {
	__typename: "CommentsDocAccessFields_isReviewed_Create",
	permission: boolean
};
	["CommentsDocAccessFields_isReviewed_Read"]: {
	__typename: "CommentsDocAccessFields_isReviewed_Read",
	permission: boolean
};
	["CommentsDocAccessFields_isReviewed_Update"]: {
	__typename: "CommentsDocAccessFields_isReviewed_Update",
	permission: boolean
};
	["CommentsDocAccessFields_isReviewed_Delete"]: {
	__typename: "CommentsDocAccessFields_isReviewed_Delete",
	permission: boolean
};
	["CommentsDocAccessFields_reviewedBy"]: {
	__typename: "CommentsDocAccessFields_reviewedBy",
	create?: GraphQLTypes["CommentsDocAccessFields_reviewedBy_Create"] | undefined,
	read?: GraphQLTypes["CommentsDocAccessFields_reviewedBy_Read"] | undefined,
	update?: GraphQLTypes["CommentsDocAccessFields_reviewedBy_Update"] | undefined,
	delete?: GraphQLTypes["CommentsDocAccessFields_reviewedBy_Delete"] | undefined
};
	["CommentsDocAccessFields_reviewedBy_Create"]: {
	__typename: "CommentsDocAccessFields_reviewedBy_Create",
	permission: boolean
};
	["CommentsDocAccessFields_reviewedBy_Read"]: {
	__typename: "CommentsDocAccessFields_reviewedBy_Read",
	permission: boolean
};
	["CommentsDocAccessFields_reviewedBy_Update"]: {
	__typename: "CommentsDocAccessFields_reviewedBy_Update",
	permission: boolean
};
	["CommentsDocAccessFields_reviewedBy_Delete"]: {
	__typename: "CommentsDocAccessFields_reviewedBy_Delete",
	permission: boolean
};
	["CommentsDocAccessFields_banned"]: {
	__typename: "CommentsDocAccessFields_banned",
	create?: GraphQLTypes["CommentsDocAccessFields_banned_Create"] | undefined,
	read?: GraphQLTypes["CommentsDocAccessFields_banned_Read"] | undefined,
	update?: GraphQLTypes["CommentsDocAccessFields_banned_Update"] | undefined,
	delete?: GraphQLTypes["CommentsDocAccessFields_banned_Delete"] | undefined
};
	["CommentsDocAccessFields_banned_Create"]: {
	__typename: "CommentsDocAccessFields_banned_Create",
	permission: boolean
};
	["CommentsDocAccessFields_banned_Read"]: {
	__typename: "CommentsDocAccessFields_banned_Read",
	permission: boolean
};
	["CommentsDocAccessFields_banned_Update"]: {
	__typename: "CommentsDocAccessFields_banned_Update",
	permission: boolean
};
	["CommentsDocAccessFields_banned_Delete"]: {
	__typename: "CommentsDocAccessFields_banned_Delete",
	permission: boolean
};
	["CommentsDocAccessFields_type"]: {
	__typename: "CommentsDocAccessFields_type",
	create?: GraphQLTypes["CommentsDocAccessFields_type_Create"] | undefined,
	read?: GraphQLTypes["CommentsDocAccessFields_type_Read"] | undefined,
	update?: GraphQLTypes["CommentsDocAccessFields_type_Update"] | undefined,
	delete?: GraphQLTypes["CommentsDocAccessFields_type_Delete"] | undefined
};
	["CommentsDocAccessFields_type_Create"]: {
	__typename: "CommentsDocAccessFields_type_Create",
	permission: boolean
};
	["CommentsDocAccessFields_type_Read"]: {
	__typename: "CommentsDocAccessFields_type_Read",
	permission: boolean
};
	["CommentsDocAccessFields_type_Update"]: {
	__typename: "CommentsDocAccessFields_type_Update",
	permission: boolean
};
	["CommentsDocAccessFields_type_Delete"]: {
	__typename: "CommentsDocAccessFields_type_Delete",
	permission: boolean
};
	["CommentsDocAccessFields_updatedAt"]: {
	__typename: "CommentsDocAccessFields_updatedAt",
	create?: GraphQLTypes["CommentsDocAccessFields_updatedAt_Create"] | undefined,
	read?: GraphQLTypes["CommentsDocAccessFields_updatedAt_Read"] | undefined,
	update?: GraphQLTypes["CommentsDocAccessFields_updatedAt_Update"] | undefined,
	delete?: GraphQLTypes["CommentsDocAccessFields_updatedAt_Delete"] | undefined
};
	["CommentsDocAccessFields_updatedAt_Create"]: {
	__typename: "CommentsDocAccessFields_updatedAt_Create",
	permission: boolean
};
	["CommentsDocAccessFields_updatedAt_Read"]: {
	__typename: "CommentsDocAccessFields_updatedAt_Read",
	permission: boolean
};
	["CommentsDocAccessFields_updatedAt_Update"]: {
	__typename: "CommentsDocAccessFields_updatedAt_Update",
	permission: boolean
};
	["CommentsDocAccessFields_updatedAt_Delete"]: {
	__typename: "CommentsDocAccessFields_updatedAt_Delete",
	permission: boolean
};
	["CommentsDocAccessFields_createdAt"]: {
	__typename: "CommentsDocAccessFields_createdAt",
	create?: GraphQLTypes["CommentsDocAccessFields_createdAt_Create"] | undefined,
	read?: GraphQLTypes["CommentsDocAccessFields_createdAt_Read"] | undefined,
	update?: GraphQLTypes["CommentsDocAccessFields_createdAt_Update"] | undefined,
	delete?: GraphQLTypes["CommentsDocAccessFields_createdAt_Delete"] | undefined
};
	["CommentsDocAccessFields_createdAt_Create"]: {
	__typename: "CommentsDocAccessFields_createdAt_Create",
	permission: boolean
};
	["CommentsDocAccessFields_createdAt_Read"]: {
	__typename: "CommentsDocAccessFields_createdAt_Read",
	permission: boolean
};
	["CommentsDocAccessFields_createdAt_Update"]: {
	__typename: "CommentsDocAccessFields_createdAt_Update",
	permission: boolean
};
	["CommentsDocAccessFields_createdAt_Delete"]: {
	__typename: "CommentsDocAccessFields_createdAt_Delete",
	permission: boolean
};
	["CommentsCreateDocAccess"]: {
	__typename: "CommentsCreateDocAccess",
	permission: boolean,
	where?: GraphQLTypes["JSONObject"] | undefined
};
	["CommentsReadDocAccess"]: {
	__typename: "CommentsReadDocAccess",
	permission: boolean,
	where?: GraphQLTypes["JSONObject"] | undefined
};
	["CommentsUpdateDocAccess"]: {
	__typename: "CommentsUpdateDocAccess",
	permission: boolean,
	where?: GraphQLTypes["JSONObject"] | undefined
};
	["CommentsDeleteDocAccess"]: {
	__typename: "CommentsDeleteDocAccess",
	permission: boolean,
	where?: GraphQLTypes["JSONObject"] | undefined
};
	["Subscription"]: {
	__typename: "Subscription",
	id?: string | undefined,
	user?: GraphQLTypes["Subscription_User_Relationship"] | undefined,
	organiser?: GraphQLTypes["Subscription_Organiser_Relationship"] | undefined,
	updatedAt?: GraphQLTypes["DateTime"] | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined
};
	["Subscription_User_Relationship"]: {
	__typename: "Subscription_User_Relationship",
	relationTo?: GraphQLTypes["Subscription_User_RelationTo"] | undefined,
	value?: GraphQLTypes["Subscription_User"] | undefined
};
	["Subscription_User_RelationTo"]: Subscription_User_RelationTo;
	["Subscription_User"]:{
        	__typename:"User"
        	['...on User']: '__union' & GraphQLTypes["User"];
};
	["Subscription_Organiser_Relationship"]: {
	__typename: "Subscription_Organiser_Relationship",
	relationTo?: GraphQLTypes["Subscription_Organiser_RelationTo"] | undefined,
	value?: GraphQLTypes["Subscription_Organiser"] | undefined
};
	["Subscription_Organiser_RelationTo"]: Subscription_Organiser_RelationTo;
	["Subscription_Organiser"]:{
        	__typename:"User"
        	['...on User']: '__union' & GraphQLTypes["User"];
};
	["Subscriptions"]: {
	__typename: "Subscriptions",
	docs?: Array<GraphQLTypes["Subscription"] | undefined> | undefined,
	hasNextPage?: boolean | undefined,
	hasPrevPage?: boolean | undefined,
	limit?: number | undefined,
	nextPage?: number | undefined,
	offset?: number | undefined,
	page?: number | undefined,
	pagingCounter?: number | undefined,
	prevPage?: number | undefined,
	totalDocs?: number | undefined,
	totalPages?: number | undefined
};
	["Subscription_where"]: {
		user?: GraphQLTypes["Subscription_user_Relation"] | undefined,
	organiser?: GraphQLTypes["Subscription_organiser_Relation"] | undefined,
	updatedAt?: GraphQLTypes["Subscription_updatedAt_operator"] | undefined,
	createdAt?: GraphQLTypes["Subscription_createdAt_operator"] | undefined,
	id?: GraphQLTypes["Subscription_id_operator"] | undefined,
	AND?: Array<GraphQLTypes["Subscription_where_and"] | undefined> | undefined,
	OR?: Array<GraphQLTypes["Subscription_where_or"] | undefined> | undefined
};
	["Subscription_user_Relation"]: {
		relationTo?: GraphQLTypes["Subscription_user_Relation_RelationTo"] | undefined,
	value?: GraphQLTypes["JSON"] | undefined
};
	["Subscription_user_Relation_RelationTo"]: Subscription_user_Relation_RelationTo;
	["Subscription_organiser_Relation"]: {
		relationTo?: GraphQLTypes["Subscription_organiser_Relation_RelationTo"] | undefined,
	value?: GraphQLTypes["JSON"] | undefined
};
	["Subscription_organiser_Relation_RelationTo"]: Subscription_organiser_Relation_RelationTo;
	["Subscription_updatedAt_operator"]: {
		equals?: GraphQLTypes["DateTime"] | undefined,
	not_equals?: GraphQLTypes["DateTime"] | undefined,
	greater_than_equal?: GraphQLTypes["DateTime"] | undefined,
	greater_than?: GraphQLTypes["DateTime"] | undefined,
	less_than_equal?: GraphQLTypes["DateTime"] | undefined,
	less_than?: GraphQLTypes["DateTime"] | undefined,
	like?: GraphQLTypes["DateTime"] | undefined,
	exists?: boolean | undefined
};
	["Subscription_createdAt_operator"]: {
		equals?: GraphQLTypes["DateTime"] | undefined,
	not_equals?: GraphQLTypes["DateTime"] | undefined,
	greater_than_equal?: GraphQLTypes["DateTime"] | undefined,
	greater_than?: GraphQLTypes["DateTime"] | undefined,
	less_than_equal?: GraphQLTypes["DateTime"] | undefined,
	less_than?: GraphQLTypes["DateTime"] | undefined,
	like?: GraphQLTypes["DateTime"] | undefined,
	exists?: boolean | undefined
};
	["Subscription_id_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Subscription_where_and"]: {
		user?: GraphQLTypes["Subscription_user_Relation"] | undefined,
	organiser?: GraphQLTypes["Subscription_organiser_Relation"] | undefined,
	updatedAt?: GraphQLTypes["Subscription_updatedAt_operator"] | undefined,
	createdAt?: GraphQLTypes["Subscription_createdAt_operator"] | undefined,
	id?: GraphQLTypes["Subscription_id_operator"] | undefined,
	AND?: Array<GraphQLTypes["Subscription_where_and"] | undefined> | undefined,
	OR?: Array<GraphQLTypes["Subscription_where_or"] | undefined> | undefined
};
	["Subscription_where_or"]: {
		user?: GraphQLTypes["Subscription_user_Relation"] | undefined,
	organiser?: GraphQLTypes["Subscription_organiser_Relation"] | undefined,
	updatedAt?: GraphQLTypes["Subscription_updatedAt_operator"] | undefined,
	createdAt?: GraphQLTypes["Subscription_createdAt_operator"] | undefined,
	id?: GraphQLTypes["Subscription_id_operator"] | undefined,
	AND?: Array<GraphQLTypes["Subscription_where_and"] | undefined> | undefined,
	OR?: Array<GraphQLTypes["Subscription_where_or"] | undefined> | undefined
};
	["countSubscriptions"]: {
	__typename: "countSubscriptions",
	totalDocs?: number | undefined
};
	["subscriptionsDocAccess"]: {
	__typename: "subscriptionsDocAccess",
	fields?: GraphQLTypes["SubscriptionsDocAccessFields"] | undefined,
	create?: GraphQLTypes["SubscriptionsCreateDocAccess"] | undefined,
	read?: GraphQLTypes["SubscriptionsReadDocAccess"] | undefined,
	update?: GraphQLTypes["SubscriptionsUpdateDocAccess"] | undefined,
	delete?: GraphQLTypes["SubscriptionsDeleteDocAccess"] | undefined
};
	["SubscriptionsDocAccessFields"]: {
	__typename: "SubscriptionsDocAccessFields",
	user?: GraphQLTypes["SubscriptionsDocAccessFields_user"] | undefined,
	organiser?: GraphQLTypes["SubscriptionsDocAccessFields_organiser"] | undefined,
	updatedAt?: GraphQLTypes["SubscriptionsDocAccessFields_updatedAt"] | undefined,
	createdAt?: GraphQLTypes["SubscriptionsDocAccessFields_createdAt"] | undefined
};
	["SubscriptionsDocAccessFields_user"]: {
	__typename: "SubscriptionsDocAccessFields_user",
	create?: GraphQLTypes["SubscriptionsDocAccessFields_user_Create"] | undefined,
	read?: GraphQLTypes["SubscriptionsDocAccessFields_user_Read"] | undefined,
	update?: GraphQLTypes["SubscriptionsDocAccessFields_user_Update"] | undefined,
	delete?: GraphQLTypes["SubscriptionsDocAccessFields_user_Delete"] | undefined
};
	["SubscriptionsDocAccessFields_user_Create"]: {
	__typename: "SubscriptionsDocAccessFields_user_Create",
	permission: boolean
};
	["SubscriptionsDocAccessFields_user_Read"]: {
	__typename: "SubscriptionsDocAccessFields_user_Read",
	permission: boolean
};
	["SubscriptionsDocAccessFields_user_Update"]: {
	__typename: "SubscriptionsDocAccessFields_user_Update",
	permission: boolean
};
	["SubscriptionsDocAccessFields_user_Delete"]: {
	__typename: "SubscriptionsDocAccessFields_user_Delete",
	permission: boolean
};
	["SubscriptionsDocAccessFields_organiser"]: {
	__typename: "SubscriptionsDocAccessFields_organiser",
	create?: GraphQLTypes["SubscriptionsDocAccessFields_organiser_Create"] | undefined,
	read?: GraphQLTypes["SubscriptionsDocAccessFields_organiser_Read"] | undefined,
	update?: GraphQLTypes["SubscriptionsDocAccessFields_organiser_Update"] | undefined,
	delete?: GraphQLTypes["SubscriptionsDocAccessFields_organiser_Delete"] | undefined
};
	["SubscriptionsDocAccessFields_organiser_Create"]: {
	__typename: "SubscriptionsDocAccessFields_organiser_Create",
	permission: boolean
};
	["SubscriptionsDocAccessFields_organiser_Read"]: {
	__typename: "SubscriptionsDocAccessFields_organiser_Read",
	permission: boolean
};
	["SubscriptionsDocAccessFields_organiser_Update"]: {
	__typename: "SubscriptionsDocAccessFields_organiser_Update",
	permission: boolean
};
	["SubscriptionsDocAccessFields_organiser_Delete"]: {
	__typename: "SubscriptionsDocAccessFields_organiser_Delete",
	permission: boolean
};
	["SubscriptionsDocAccessFields_updatedAt"]: {
	__typename: "SubscriptionsDocAccessFields_updatedAt",
	create?: GraphQLTypes["SubscriptionsDocAccessFields_updatedAt_Create"] | undefined,
	read?: GraphQLTypes["SubscriptionsDocAccessFields_updatedAt_Read"] | undefined,
	update?: GraphQLTypes["SubscriptionsDocAccessFields_updatedAt_Update"] | undefined,
	delete?: GraphQLTypes["SubscriptionsDocAccessFields_updatedAt_Delete"] | undefined
};
	["SubscriptionsDocAccessFields_updatedAt_Create"]: {
	__typename: "SubscriptionsDocAccessFields_updatedAt_Create",
	permission: boolean
};
	["SubscriptionsDocAccessFields_updatedAt_Read"]: {
	__typename: "SubscriptionsDocAccessFields_updatedAt_Read",
	permission: boolean
};
	["SubscriptionsDocAccessFields_updatedAt_Update"]: {
	__typename: "SubscriptionsDocAccessFields_updatedAt_Update",
	permission: boolean
};
	["SubscriptionsDocAccessFields_updatedAt_Delete"]: {
	__typename: "SubscriptionsDocAccessFields_updatedAt_Delete",
	permission: boolean
};
	["SubscriptionsDocAccessFields_createdAt"]: {
	__typename: "SubscriptionsDocAccessFields_createdAt",
	create?: GraphQLTypes["SubscriptionsDocAccessFields_createdAt_Create"] | undefined,
	read?: GraphQLTypes["SubscriptionsDocAccessFields_createdAt_Read"] | undefined,
	update?: GraphQLTypes["SubscriptionsDocAccessFields_createdAt_Update"] | undefined,
	delete?: GraphQLTypes["SubscriptionsDocAccessFields_createdAt_Delete"] | undefined
};
	["SubscriptionsDocAccessFields_createdAt_Create"]: {
	__typename: "SubscriptionsDocAccessFields_createdAt_Create",
	permission: boolean
};
	["SubscriptionsDocAccessFields_createdAt_Read"]: {
	__typename: "SubscriptionsDocAccessFields_createdAt_Read",
	permission: boolean
};
	["SubscriptionsDocAccessFields_createdAt_Update"]: {
	__typename: "SubscriptionsDocAccessFields_createdAt_Update",
	permission: boolean
};
	["SubscriptionsDocAccessFields_createdAt_Delete"]: {
	__typename: "SubscriptionsDocAccessFields_createdAt_Delete",
	permission: boolean
};
	["SubscriptionsCreateDocAccess"]: {
	__typename: "SubscriptionsCreateDocAccess",
	permission: boolean,
	where?: GraphQLTypes["JSONObject"] | undefined
};
	["SubscriptionsReadDocAccess"]: {
	__typename: "SubscriptionsReadDocAccess",
	permission: boolean,
	where?: GraphQLTypes["JSONObject"] | undefined
};
	["SubscriptionsUpdateDocAccess"]: {
	__typename: "SubscriptionsUpdateDocAccess",
	permission: boolean,
	where?: GraphQLTypes["JSONObject"] | undefined
};
	["SubscriptionsDeleteDocAccess"]: {
	__typename: "SubscriptionsDeleteDocAccess",
	permission: boolean,
	where?: GraphQLTypes["JSONObject"] | undefined
};
	["Newsletter"]: {
	__typename: "Newsletter",
	id?: string | undefined,
	email: string,
	region?: GraphQLTypes["Newsletter_region"] | undefined,
	updatedAt?: GraphQLTypes["DateTime"] | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined
};
	["Newsletter_region"]: Newsletter_region;
	["Newsletters"]: {
	__typename: "Newsletters",
	docs?: Array<GraphQLTypes["Newsletter"] | undefined> | undefined,
	hasNextPage?: boolean | undefined,
	hasPrevPage?: boolean | undefined,
	limit?: number | undefined,
	nextPage?: number | undefined,
	offset?: number | undefined,
	page?: number | undefined,
	pagingCounter?: number | undefined,
	prevPage?: number | undefined,
	totalDocs?: number | undefined,
	totalPages?: number | undefined
};
	["Newsletter_where"]: {
		email?: GraphQLTypes["Newsletter_email_operator"] | undefined,
	region?: GraphQLTypes["Newsletter_region_operator"] | undefined,
	updatedAt?: GraphQLTypes["Newsletter_updatedAt_operator"] | undefined,
	createdAt?: GraphQLTypes["Newsletter_createdAt_operator"] | undefined,
	id?: GraphQLTypes["Newsletter_id_operator"] | undefined,
	AND?: Array<GraphQLTypes["Newsletter_where_and"] | undefined> | undefined,
	OR?: Array<GraphQLTypes["Newsletter_where_or"] | undefined> | undefined
};
	["Newsletter_email_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined
};
	["Newsletter_region_operator"]: {
		equals?: GraphQLTypes["Newsletter_region_Input"] | undefined,
	not_equals?: GraphQLTypes["Newsletter_region_Input"] | undefined,
	in?: Array<GraphQLTypes["Newsletter_region_Input"] | undefined> | undefined,
	not_in?: Array<GraphQLTypes["Newsletter_region_Input"] | undefined> | undefined,
	all?: Array<GraphQLTypes["Newsletter_region_Input"] | undefined> | undefined,
	exists?: boolean | undefined
};
	["Newsletter_region_Input"]: Newsletter_region_Input;
	["Newsletter_updatedAt_operator"]: {
		equals?: GraphQLTypes["DateTime"] | undefined,
	not_equals?: GraphQLTypes["DateTime"] | undefined,
	greater_than_equal?: GraphQLTypes["DateTime"] | undefined,
	greater_than?: GraphQLTypes["DateTime"] | undefined,
	less_than_equal?: GraphQLTypes["DateTime"] | undefined,
	less_than?: GraphQLTypes["DateTime"] | undefined,
	like?: GraphQLTypes["DateTime"] | undefined,
	exists?: boolean | undefined
};
	["Newsletter_createdAt_operator"]: {
		equals?: GraphQLTypes["DateTime"] | undefined,
	not_equals?: GraphQLTypes["DateTime"] | undefined,
	greater_than_equal?: GraphQLTypes["DateTime"] | undefined,
	greater_than?: GraphQLTypes["DateTime"] | undefined,
	less_than_equal?: GraphQLTypes["DateTime"] | undefined,
	less_than?: GraphQLTypes["DateTime"] | undefined,
	like?: GraphQLTypes["DateTime"] | undefined,
	exists?: boolean | undefined
};
	["Newsletter_id_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Newsletter_where_and"]: {
		email?: GraphQLTypes["Newsletter_email_operator"] | undefined,
	region?: GraphQLTypes["Newsletter_region_operator"] | undefined,
	updatedAt?: GraphQLTypes["Newsletter_updatedAt_operator"] | undefined,
	createdAt?: GraphQLTypes["Newsletter_createdAt_operator"] | undefined,
	id?: GraphQLTypes["Newsletter_id_operator"] | undefined,
	AND?: Array<GraphQLTypes["Newsletter_where_and"] | undefined> | undefined,
	OR?: Array<GraphQLTypes["Newsletter_where_or"] | undefined> | undefined
};
	["Newsletter_where_or"]: {
		email?: GraphQLTypes["Newsletter_email_operator"] | undefined,
	region?: GraphQLTypes["Newsletter_region_operator"] | undefined,
	updatedAt?: GraphQLTypes["Newsletter_updatedAt_operator"] | undefined,
	createdAt?: GraphQLTypes["Newsletter_createdAt_operator"] | undefined,
	id?: GraphQLTypes["Newsletter_id_operator"] | undefined,
	AND?: Array<GraphQLTypes["Newsletter_where_and"] | undefined> | undefined,
	OR?: Array<GraphQLTypes["Newsletter_where_or"] | undefined> | undefined
};
	["countNewsletters"]: {
	__typename: "countNewsletters",
	totalDocs?: number | undefined
};
	["newsletterDocAccess"]: {
	__typename: "newsletterDocAccess",
	fields?: GraphQLTypes["NewsletterDocAccessFields"] | undefined,
	create?: GraphQLTypes["NewsletterCreateDocAccess"] | undefined,
	read?: GraphQLTypes["NewsletterReadDocAccess"] | undefined,
	update?: GraphQLTypes["NewsletterUpdateDocAccess"] | undefined,
	delete?: GraphQLTypes["NewsletterDeleteDocAccess"] | undefined
};
	["NewsletterDocAccessFields"]: {
	__typename: "NewsletterDocAccessFields",
	email?: GraphQLTypes["NewsletterDocAccessFields_email"] | undefined,
	region?: GraphQLTypes["NewsletterDocAccessFields_region"] | undefined,
	updatedAt?: GraphQLTypes["NewsletterDocAccessFields_updatedAt"] | undefined,
	createdAt?: GraphQLTypes["NewsletterDocAccessFields_createdAt"] | undefined
};
	["NewsletterDocAccessFields_email"]: {
	__typename: "NewsletterDocAccessFields_email",
	create?: GraphQLTypes["NewsletterDocAccessFields_email_Create"] | undefined,
	read?: GraphQLTypes["NewsletterDocAccessFields_email_Read"] | undefined,
	update?: GraphQLTypes["NewsletterDocAccessFields_email_Update"] | undefined,
	delete?: GraphQLTypes["NewsletterDocAccessFields_email_Delete"] | undefined
};
	["NewsletterDocAccessFields_email_Create"]: {
	__typename: "NewsletterDocAccessFields_email_Create",
	permission: boolean
};
	["NewsletterDocAccessFields_email_Read"]: {
	__typename: "NewsletterDocAccessFields_email_Read",
	permission: boolean
};
	["NewsletterDocAccessFields_email_Update"]: {
	__typename: "NewsletterDocAccessFields_email_Update",
	permission: boolean
};
	["NewsletterDocAccessFields_email_Delete"]: {
	__typename: "NewsletterDocAccessFields_email_Delete",
	permission: boolean
};
	["NewsletterDocAccessFields_region"]: {
	__typename: "NewsletterDocAccessFields_region",
	create?: GraphQLTypes["NewsletterDocAccessFields_region_Create"] | undefined,
	read?: GraphQLTypes["NewsletterDocAccessFields_region_Read"] | undefined,
	update?: GraphQLTypes["NewsletterDocAccessFields_region_Update"] | undefined,
	delete?: GraphQLTypes["NewsletterDocAccessFields_region_Delete"] | undefined
};
	["NewsletterDocAccessFields_region_Create"]: {
	__typename: "NewsletterDocAccessFields_region_Create",
	permission: boolean
};
	["NewsletterDocAccessFields_region_Read"]: {
	__typename: "NewsletterDocAccessFields_region_Read",
	permission: boolean
};
	["NewsletterDocAccessFields_region_Update"]: {
	__typename: "NewsletterDocAccessFields_region_Update",
	permission: boolean
};
	["NewsletterDocAccessFields_region_Delete"]: {
	__typename: "NewsletterDocAccessFields_region_Delete",
	permission: boolean
};
	["NewsletterDocAccessFields_updatedAt"]: {
	__typename: "NewsletterDocAccessFields_updatedAt",
	create?: GraphQLTypes["NewsletterDocAccessFields_updatedAt_Create"] | undefined,
	read?: GraphQLTypes["NewsletterDocAccessFields_updatedAt_Read"] | undefined,
	update?: GraphQLTypes["NewsletterDocAccessFields_updatedAt_Update"] | undefined,
	delete?: GraphQLTypes["NewsletterDocAccessFields_updatedAt_Delete"] | undefined
};
	["NewsletterDocAccessFields_updatedAt_Create"]: {
	__typename: "NewsletterDocAccessFields_updatedAt_Create",
	permission: boolean
};
	["NewsletterDocAccessFields_updatedAt_Read"]: {
	__typename: "NewsletterDocAccessFields_updatedAt_Read",
	permission: boolean
};
	["NewsletterDocAccessFields_updatedAt_Update"]: {
	__typename: "NewsletterDocAccessFields_updatedAt_Update",
	permission: boolean
};
	["NewsletterDocAccessFields_updatedAt_Delete"]: {
	__typename: "NewsletterDocAccessFields_updatedAt_Delete",
	permission: boolean
};
	["NewsletterDocAccessFields_createdAt"]: {
	__typename: "NewsletterDocAccessFields_createdAt",
	create?: GraphQLTypes["NewsletterDocAccessFields_createdAt_Create"] | undefined,
	read?: GraphQLTypes["NewsletterDocAccessFields_createdAt_Read"] | undefined,
	update?: GraphQLTypes["NewsletterDocAccessFields_createdAt_Update"] | undefined,
	delete?: GraphQLTypes["NewsletterDocAccessFields_createdAt_Delete"] | undefined
};
	["NewsletterDocAccessFields_createdAt_Create"]: {
	__typename: "NewsletterDocAccessFields_createdAt_Create",
	permission: boolean
};
	["NewsletterDocAccessFields_createdAt_Read"]: {
	__typename: "NewsletterDocAccessFields_createdAt_Read",
	permission: boolean
};
	["NewsletterDocAccessFields_createdAt_Update"]: {
	__typename: "NewsletterDocAccessFields_createdAt_Update",
	permission: boolean
};
	["NewsletterDocAccessFields_createdAt_Delete"]: {
	__typename: "NewsletterDocAccessFields_createdAt_Delete",
	permission: boolean
};
	["NewsletterCreateDocAccess"]: {
	__typename: "NewsletterCreateDocAccess",
	permission: boolean,
	where?: GraphQLTypes["JSONObject"] | undefined
};
	["NewsletterReadDocAccess"]: {
	__typename: "NewsletterReadDocAccess",
	permission: boolean,
	where?: GraphQLTypes["JSONObject"] | undefined
};
	["NewsletterUpdateDocAccess"]: {
	__typename: "NewsletterUpdateDocAccess",
	permission: boolean,
	where?: GraphQLTypes["JSONObject"] | undefined
};
	["NewsletterDeleteDocAccess"]: {
	__typename: "NewsletterDeleteDocAccess",
	permission: boolean,
	where?: GraphQLTypes["JSONObject"] | undefined
};
	["BlogCategories"]: {
	__typename: "BlogCategories",
	docs?: Array<GraphQLTypes["BlogCategory"] | undefined> | undefined,
	hasNextPage?: boolean | undefined,
	hasPrevPage?: boolean | undefined,
	limit?: number | undefined,
	nextPage?: number | undefined,
	offset?: number | undefined,
	page?: number | undefined,
	pagingCounter?: number | undefined,
	prevPage?: number | undefined,
	totalDocs?: number | undefined,
	totalPages?: number | undefined
};
	["BlogCategory_where"]: {
		name?: GraphQLTypes["BlogCategory_name_operator"] | undefined,
	value?: GraphQLTypes["BlogCategory_value_operator"] | undefined,
	icon?: GraphQLTypes["BlogCategory_icon_operator"] | undefined,
	region?: GraphQLTypes["BlogCategory_region_operator"] | undefined,
	updatedAt?: GraphQLTypes["BlogCategory_updatedAt_operator"] | undefined,
	createdAt?: GraphQLTypes["BlogCategory_createdAt_operator"] | undefined,
	id?: GraphQLTypes["BlogCategory_id_operator"] | undefined,
	AND?: Array<GraphQLTypes["BlogCategory_where_and"] | undefined> | undefined,
	OR?: Array<GraphQLTypes["BlogCategory_where_or"] | undefined> | undefined
};
	["BlogCategory_name_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined
};
	["BlogCategory_value_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined
};
	["BlogCategory_icon_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	exists?: boolean | undefined
};
	["BlogCategory_region_operator"]: {
		equals?: GraphQLTypes["BlogCategory_region_Input"] | undefined,
	not_equals?: GraphQLTypes["BlogCategory_region_Input"] | undefined,
	in?: Array<GraphQLTypes["BlogCategory_region_Input"] | undefined> | undefined,
	not_in?: Array<GraphQLTypes["BlogCategory_region_Input"] | undefined> | undefined,
	all?: Array<GraphQLTypes["BlogCategory_region_Input"] | undefined> | undefined,
	exists?: boolean | undefined
};
	["BlogCategory_region_Input"]: BlogCategory_region_Input;
	["BlogCategory_updatedAt_operator"]: {
		equals?: GraphQLTypes["DateTime"] | undefined,
	not_equals?: GraphQLTypes["DateTime"] | undefined,
	greater_than_equal?: GraphQLTypes["DateTime"] | undefined,
	greater_than?: GraphQLTypes["DateTime"] | undefined,
	less_than_equal?: GraphQLTypes["DateTime"] | undefined,
	less_than?: GraphQLTypes["DateTime"] | undefined,
	like?: GraphQLTypes["DateTime"] | undefined,
	exists?: boolean | undefined
};
	["BlogCategory_createdAt_operator"]: {
		equals?: GraphQLTypes["DateTime"] | undefined,
	not_equals?: GraphQLTypes["DateTime"] | undefined,
	greater_than_equal?: GraphQLTypes["DateTime"] | undefined,
	greater_than?: GraphQLTypes["DateTime"] | undefined,
	less_than_equal?: GraphQLTypes["DateTime"] | undefined,
	less_than?: GraphQLTypes["DateTime"] | undefined,
	like?: GraphQLTypes["DateTime"] | undefined,
	exists?: boolean | undefined
};
	["BlogCategory_id_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["BlogCategory_where_and"]: {
		name?: GraphQLTypes["BlogCategory_name_operator"] | undefined,
	value?: GraphQLTypes["BlogCategory_value_operator"] | undefined,
	icon?: GraphQLTypes["BlogCategory_icon_operator"] | undefined,
	region?: GraphQLTypes["BlogCategory_region_operator"] | undefined,
	updatedAt?: GraphQLTypes["BlogCategory_updatedAt_operator"] | undefined,
	createdAt?: GraphQLTypes["BlogCategory_createdAt_operator"] | undefined,
	id?: GraphQLTypes["BlogCategory_id_operator"] | undefined,
	AND?: Array<GraphQLTypes["BlogCategory_where_and"] | undefined> | undefined,
	OR?: Array<GraphQLTypes["BlogCategory_where_or"] | undefined> | undefined
};
	["BlogCategory_where_or"]: {
		name?: GraphQLTypes["BlogCategory_name_operator"] | undefined,
	value?: GraphQLTypes["BlogCategory_value_operator"] | undefined,
	icon?: GraphQLTypes["BlogCategory_icon_operator"] | undefined,
	region?: GraphQLTypes["BlogCategory_region_operator"] | undefined,
	updatedAt?: GraphQLTypes["BlogCategory_updatedAt_operator"] | undefined,
	createdAt?: GraphQLTypes["BlogCategory_createdAt_operator"] | undefined,
	id?: GraphQLTypes["BlogCategory_id_operator"] | undefined,
	AND?: Array<GraphQLTypes["BlogCategory_where_and"] | undefined> | undefined,
	OR?: Array<GraphQLTypes["BlogCategory_where_or"] | undefined> | undefined
};
	["countBlogCategories"]: {
	__typename: "countBlogCategories",
	totalDocs?: number | undefined
};
	["blog_categoriesDocAccess"]: {
	__typename: "blog_categoriesDocAccess",
	fields?: GraphQLTypes["BlogCategoriesDocAccessFields"] | undefined,
	create?: GraphQLTypes["BlogCategoriesCreateDocAccess"] | undefined,
	read?: GraphQLTypes["BlogCategoriesReadDocAccess"] | undefined,
	update?: GraphQLTypes["BlogCategoriesUpdateDocAccess"] | undefined,
	delete?: GraphQLTypes["BlogCategoriesDeleteDocAccess"] | undefined
};
	["BlogCategoriesDocAccessFields"]: {
	__typename: "BlogCategoriesDocAccessFields",
	name?: GraphQLTypes["BlogCategoriesDocAccessFields_name"] | undefined,
	value?: GraphQLTypes["BlogCategoriesDocAccessFields_value"] | undefined,
	icon?: GraphQLTypes["BlogCategoriesDocAccessFields_icon"] | undefined,
	region?: GraphQLTypes["BlogCategoriesDocAccessFields_region"] | undefined,
	updatedAt?: GraphQLTypes["BlogCategoriesDocAccessFields_updatedAt"] | undefined,
	createdAt?: GraphQLTypes["BlogCategoriesDocAccessFields_createdAt"] | undefined
};
	["BlogCategoriesDocAccessFields_name"]: {
	__typename: "BlogCategoriesDocAccessFields_name",
	create?: GraphQLTypes["BlogCategoriesDocAccessFields_name_Create"] | undefined,
	read?: GraphQLTypes["BlogCategoriesDocAccessFields_name_Read"] | undefined,
	update?: GraphQLTypes["BlogCategoriesDocAccessFields_name_Update"] | undefined,
	delete?: GraphQLTypes["BlogCategoriesDocAccessFields_name_Delete"] | undefined
};
	["BlogCategoriesDocAccessFields_name_Create"]: {
	__typename: "BlogCategoriesDocAccessFields_name_Create",
	permission: boolean
};
	["BlogCategoriesDocAccessFields_name_Read"]: {
	__typename: "BlogCategoriesDocAccessFields_name_Read",
	permission: boolean
};
	["BlogCategoriesDocAccessFields_name_Update"]: {
	__typename: "BlogCategoriesDocAccessFields_name_Update",
	permission: boolean
};
	["BlogCategoriesDocAccessFields_name_Delete"]: {
	__typename: "BlogCategoriesDocAccessFields_name_Delete",
	permission: boolean
};
	["BlogCategoriesDocAccessFields_value"]: {
	__typename: "BlogCategoriesDocAccessFields_value",
	create?: GraphQLTypes["BlogCategoriesDocAccessFields_value_Create"] | undefined,
	read?: GraphQLTypes["BlogCategoriesDocAccessFields_value_Read"] | undefined,
	update?: GraphQLTypes["BlogCategoriesDocAccessFields_value_Update"] | undefined,
	delete?: GraphQLTypes["BlogCategoriesDocAccessFields_value_Delete"] | undefined
};
	["BlogCategoriesDocAccessFields_value_Create"]: {
	__typename: "BlogCategoriesDocAccessFields_value_Create",
	permission: boolean
};
	["BlogCategoriesDocAccessFields_value_Read"]: {
	__typename: "BlogCategoriesDocAccessFields_value_Read",
	permission: boolean
};
	["BlogCategoriesDocAccessFields_value_Update"]: {
	__typename: "BlogCategoriesDocAccessFields_value_Update",
	permission: boolean
};
	["BlogCategoriesDocAccessFields_value_Delete"]: {
	__typename: "BlogCategoriesDocAccessFields_value_Delete",
	permission: boolean
};
	["BlogCategoriesDocAccessFields_icon"]: {
	__typename: "BlogCategoriesDocAccessFields_icon",
	create?: GraphQLTypes["BlogCategoriesDocAccessFields_icon_Create"] | undefined,
	read?: GraphQLTypes["BlogCategoriesDocAccessFields_icon_Read"] | undefined,
	update?: GraphQLTypes["BlogCategoriesDocAccessFields_icon_Update"] | undefined,
	delete?: GraphQLTypes["BlogCategoriesDocAccessFields_icon_Delete"] | undefined
};
	["BlogCategoriesDocAccessFields_icon_Create"]: {
	__typename: "BlogCategoriesDocAccessFields_icon_Create",
	permission: boolean
};
	["BlogCategoriesDocAccessFields_icon_Read"]: {
	__typename: "BlogCategoriesDocAccessFields_icon_Read",
	permission: boolean
};
	["BlogCategoriesDocAccessFields_icon_Update"]: {
	__typename: "BlogCategoriesDocAccessFields_icon_Update",
	permission: boolean
};
	["BlogCategoriesDocAccessFields_icon_Delete"]: {
	__typename: "BlogCategoriesDocAccessFields_icon_Delete",
	permission: boolean
};
	["BlogCategoriesDocAccessFields_region"]: {
	__typename: "BlogCategoriesDocAccessFields_region",
	create?: GraphQLTypes["BlogCategoriesDocAccessFields_region_Create"] | undefined,
	read?: GraphQLTypes["BlogCategoriesDocAccessFields_region_Read"] | undefined,
	update?: GraphQLTypes["BlogCategoriesDocAccessFields_region_Update"] | undefined,
	delete?: GraphQLTypes["BlogCategoriesDocAccessFields_region_Delete"] | undefined
};
	["BlogCategoriesDocAccessFields_region_Create"]: {
	__typename: "BlogCategoriesDocAccessFields_region_Create",
	permission: boolean
};
	["BlogCategoriesDocAccessFields_region_Read"]: {
	__typename: "BlogCategoriesDocAccessFields_region_Read",
	permission: boolean
};
	["BlogCategoriesDocAccessFields_region_Update"]: {
	__typename: "BlogCategoriesDocAccessFields_region_Update",
	permission: boolean
};
	["BlogCategoriesDocAccessFields_region_Delete"]: {
	__typename: "BlogCategoriesDocAccessFields_region_Delete",
	permission: boolean
};
	["BlogCategoriesDocAccessFields_updatedAt"]: {
	__typename: "BlogCategoriesDocAccessFields_updatedAt",
	create?: GraphQLTypes["BlogCategoriesDocAccessFields_updatedAt_Create"] | undefined,
	read?: GraphQLTypes["BlogCategoriesDocAccessFields_updatedAt_Read"] | undefined,
	update?: GraphQLTypes["BlogCategoriesDocAccessFields_updatedAt_Update"] | undefined,
	delete?: GraphQLTypes["BlogCategoriesDocAccessFields_updatedAt_Delete"] | undefined
};
	["BlogCategoriesDocAccessFields_updatedAt_Create"]: {
	__typename: "BlogCategoriesDocAccessFields_updatedAt_Create",
	permission: boolean
};
	["BlogCategoriesDocAccessFields_updatedAt_Read"]: {
	__typename: "BlogCategoriesDocAccessFields_updatedAt_Read",
	permission: boolean
};
	["BlogCategoriesDocAccessFields_updatedAt_Update"]: {
	__typename: "BlogCategoriesDocAccessFields_updatedAt_Update",
	permission: boolean
};
	["BlogCategoriesDocAccessFields_updatedAt_Delete"]: {
	__typename: "BlogCategoriesDocAccessFields_updatedAt_Delete",
	permission: boolean
};
	["BlogCategoriesDocAccessFields_createdAt"]: {
	__typename: "BlogCategoriesDocAccessFields_createdAt",
	create?: GraphQLTypes["BlogCategoriesDocAccessFields_createdAt_Create"] | undefined,
	read?: GraphQLTypes["BlogCategoriesDocAccessFields_createdAt_Read"] | undefined,
	update?: GraphQLTypes["BlogCategoriesDocAccessFields_createdAt_Update"] | undefined,
	delete?: GraphQLTypes["BlogCategoriesDocAccessFields_createdAt_Delete"] | undefined
};
	["BlogCategoriesDocAccessFields_createdAt_Create"]: {
	__typename: "BlogCategoriesDocAccessFields_createdAt_Create",
	permission: boolean
};
	["BlogCategoriesDocAccessFields_createdAt_Read"]: {
	__typename: "BlogCategoriesDocAccessFields_createdAt_Read",
	permission: boolean
};
	["BlogCategoriesDocAccessFields_createdAt_Update"]: {
	__typename: "BlogCategoriesDocAccessFields_createdAt_Update",
	permission: boolean
};
	["BlogCategoriesDocAccessFields_createdAt_Delete"]: {
	__typename: "BlogCategoriesDocAccessFields_createdAt_Delete",
	permission: boolean
};
	["BlogCategoriesCreateDocAccess"]: {
	__typename: "BlogCategoriesCreateDocAccess",
	permission: boolean,
	where?: GraphQLTypes["JSONObject"] | undefined
};
	["BlogCategoriesReadDocAccess"]: {
	__typename: "BlogCategoriesReadDocAccess",
	permission: boolean,
	where?: GraphQLTypes["JSONObject"] | undefined
};
	["BlogCategoriesUpdateDocAccess"]: {
	__typename: "BlogCategoriesUpdateDocAccess",
	permission: boolean,
	where?: GraphQLTypes["JSONObject"] | undefined
};
	["BlogCategoriesDeleteDocAccess"]: {
	__typename: "BlogCategoriesDeleteDocAccess",
	permission: boolean,
	where?: GraphQLTypes["JSONObject"] | undefined
};
	["Tags"]: {
	__typename: "Tags",
	docs?: Array<GraphQLTypes["Tag"] | undefined> | undefined,
	hasNextPage?: boolean | undefined,
	hasPrevPage?: boolean | undefined,
	limit?: number | undefined,
	nextPage?: number | undefined,
	offset?: number | undefined,
	page?: number | undefined,
	pagingCounter?: number | undefined,
	prevPage?: number | undefined,
	totalDocs?: number | undefined,
	totalPages?: number | undefined
};
	["Tag_where"]: {
		name?: GraphQLTypes["Tag_name_operator"] | undefined,
	value?: GraphQLTypes["Tag_value_operator"] | undefined,
	region?: GraphQLTypes["Tag_region_operator"] | undefined,
	count?: GraphQLTypes["Tag_count_operator"] | undefined,
	skipCountCheck?: GraphQLTypes["Tag_skipCountCheck_operator"] | undefined,
	removeNextCron?: GraphQLTypes["Tag_removeNextCron_operator"] | undefined,
	id?: GraphQLTypes["Tag_id_operator"] | undefined,
	AND?: Array<GraphQLTypes["Tag_where_and"] | undefined> | undefined,
	OR?: Array<GraphQLTypes["Tag_where_or"] | undefined> | undefined
};
	["Tag_name_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined
};
	["Tag_value_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined
};
	["Tag_region_operator"]: {
		equals?: GraphQLTypes["Tag_region_Input"] | undefined,
	not_equals?: GraphQLTypes["Tag_region_Input"] | undefined,
	in?: Array<GraphQLTypes["Tag_region_Input"] | undefined> | undefined,
	not_in?: Array<GraphQLTypes["Tag_region_Input"] | undefined> | undefined,
	all?: Array<GraphQLTypes["Tag_region_Input"] | undefined> | undefined,
	exists?: boolean | undefined
};
	["Tag_region_Input"]: Tag_region_Input;
	["Tag_count_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Tag_skipCountCheck_operator"]: {
		equals?: boolean | undefined,
	not_equals?: boolean | undefined,
	exists?: boolean | undefined
};
	["Tag_removeNextCron_operator"]: {
		equals?: boolean | undefined,
	not_equals?: boolean | undefined,
	exists?: boolean | undefined
};
	["Tag_id_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Tag_where_and"]: {
		name?: GraphQLTypes["Tag_name_operator"] | undefined,
	value?: GraphQLTypes["Tag_value_operator"] | undefined,
	region?: GraphQLTypes["Tag_region_operator"] | undefined,
	count?: GraphQLTypes["Tag_count_operator"] | undefined,
	skipCountCheck?: GraphQLTypes["Tag_skipCountCheck_operator"] | undefined,
	removeNextCron?: GraphQLTypes["Tag_removeNextCron_operator"] | undefined,
	id?: GraphQLTypes["Tag_id_operator"] | undefined,
	AND?: Array<GraphQLTypes["Tag_where_and"] | undefined> | undefined,
	OR?: Array<GraphQLTypes["Tag_where_or"] | undefined> | undefined
};
	["Tag_where_or"]: {
		name?: GraphQLTypes["Tag_name_operator"] | undefined,
	value?: GraphQLTypes["Tag_value_operator"] | undefined,
	region?: GraphQLTypes["Tag_region_operator"] | undefined,
	count?: GraphQLTypes["Tag_count_operator"] | undefined,
	skipCountCheck?: GraphQLTypes["Tag_skipCountCheck_operator"] | undefined,
	removeNextCron?: GraphQLTypes["Tag_removeNextCron_operator"] | undefined,
	id?: GraphQLTypes["Tag_id_operator"] | undefined,
	AND?: Array<GraphQLTypes["Tag_where_and"] | undefined> | undefined,
	OR?: Array<GraphQLTypes["Tag_where_or"] | undefined> | undefined
};
	["countTags"]: {
	__typename: "countTags",
	totalDocs?: number | undefined
};
	["tagsDocAccess"]: {
	__typename: "tagsDocAccess",
	fields?: GraphQLTypes["TagsDocAccessFields"] | undefined,
	create?: GraphQLTypes["TagsCreateDocAccess"] | undefined,
	read?: GraphQLTypes["TagsReadDocAccess"] | undefined,
	update?: GraphQLTypes["TagsUpdateDocAccess"] | undefined,
	delete?: GraphQLTypes["TagsDeleteDocAccess"] | undefined
};
	["TagsDocAccessFields"]: {
	__typename: "TagsDocAccessFields",
	name?: GraphQLTypes["TagsDocAccessFields_name"] | undefined,
	value?: GraphQLTypes["TagsDocAccessFields_value"] | undefined,
	region?: GraphQLTypes["TagsDocAccessFields_region"] | undefined,
	count?: GraphQLTypes["TagsDocAccessFields_count"] | undefined,
	skipCountCheck?: GraphQLTypes["TagsDocAccessFields_skipCountCheck"] | undefined,
	removeNextCron?: GraphQLTypes["TagsDocAccessFields_removeNextCron"] | undefined
};
	["TagsDocAccessFields_name"]: {
	__typename: "TagsDocAccessFields_name",
	create?: GraphQLTypes["TagsDocAccessFields_name_Create"] | undefined,
	read?: GraphQLTypes["TagsDocAccessFields_name_Read"] | undefined,
	update?: GraphQLTypes["TagsDocAccessFields_name_Update"] | undefined,
	delete?: GraphQLTypes["TagsDocAccessFields_name_Delete"] | undefined
};
	["TagsDocAccessFields_name_Create"]: {
	__typename: "TagsDocAccessFields_name_Create",
	permission: boolean
};
	["TagsDocAccessFields_name_Read"]: {
	__typename: "TagsDocAccessFields_name_Read",
	permission: boolean
};
	["TagsDocAccessFields_name_Update"]: {
	__typename: "TagsDocAccessFields_name_Update",
	permission: boolean
};
	["TagsDocAccessFields_name_Delete"]: {
	__typename: "TagsDocAccessFields_name_Delete",
	permission: boolean
};
	["TagsDocAccessFields_value"]: {
	__typename: "TagsDocAccessFields_value",
	create?: GraphQLTypes["TagsDocAccessFields_value_Create"] | undefined,
	read?: GraphQLTypes["TagsDocAccessFields_value_Read"] | undefined,
	update?: GraphQLTypes["TagsDocAccessFields_value_Update"] | undefined,
	delete?: GraphQLTypes["TagsDocAccessFields_value_Delete"] | undefined
};
	["TagsDocAccessFields_value_Create"]: {
	__typename: "TagsDocAccessFields_value_Create",
	permission: boolean
};
	["TagsDocAccessFields_value_Read"]: {
	__typename: "TagsDocAccessFields_value_Read",
	permission: boolean
};
	["TagsDocAccessFields_value_Update"]: {
	__typename: "TagsDocAccessFields_value_Update",
	permission: boolean
};
	["TagsDocAccessFields_value_Delete"]: {
	__typename: "TagsDocAccessFields_value_Delete",
	permission: boolean
};
	["TagsDocAccessFields_region"]: {
	__typename: "TagsDocAccessFields_region",
	create?: GraphQLTypes["TagsDocAccessFields_region_Create"] | undefined,
	read?: GraphQLTypes["TagsDocAccessFields_region_Read"] | undefined,
	update?: GraphQLTypes["TagsDocAccessFields_region_Update"] | undefined,
	delete?: GraphQLTypes["TagsDocAccessFields_region_Delete"] | undefined
};
	["TagsDocAccessFields_region_Create"]: {
	__typename: "TagsDocAccessFields_region_Create",
	permission: boolean
};
	["TagsDocAccessFields_region_Read"]: {
	__typename: "TagsDocAccessFields_region_Read",
	permission: boolean
};
	["TagsDocAccessFields_region_Update"]: {
	__typename: "TagsDocAccessFields_region_Update",
	permission: boolean
};
	["TagsDocAccessFields_region_Delete"]: {
	__typename: "TagsDocAccessFields_region_Delete",
	permission: boolean
};
	["TagsDocAccessFields_count"]: {
	__typename: "TagsDocAccessFields_count",
	create?: GraphQLTypes["TagsDocAccessFields_count_Create"] | undefined,
	read?: GraphQLTypes["TagsDocAccessFields_count_Read"] | undefined,
	update?: GraphQLTypes["TagsDocAccessFields_count_Update"] | undefined,
	delete?: GraphQLTypes["TagsDocAccessFields_count_Delete"] | undefined
};
	["TagsDocAccessFields_count_Create"]: {
	__typename: "TagsDocAccessFields_count_Create",
	permission: boolean
};
	["TagsDocAccessFields_count_Read"]: {
	__typename: "TagsDocAccessFields_count_Read",
	permission: boolean
};
	["TagsDocAccessFields_count_Update"]: {
	__typename: "TagsDocAccessFields_count_Update",
	permission: boolean
};
	["TagsDocAccessFields_count_Delete"]: {
	__typename: "TagsDocAccessFields_count_Delete",
	permission: boolean
};
	["TagsDocAccessFields_skipCountCheck"]: {
	__typename: "TagsDocAccessFields_skipCountCheck",
	create?: GraphQLTypes["TagsDocAccessFields_skipCountCheck_Create"] | undefined,
	read?: GraphQLTypes["TagsDocAccessFields_skipCountCheck_Read"] | undefined,
	update?: GraphQLTypes["TagsDocAccessFields_skipCountCheck_Update"] | undefined,
	delete?: GraphQLTypes["TagsDocAccessFields_skipCountCheck_Delete"] | undefined
};
	["TagsDocAccessFields_skipCountCheck_Create"]: {
	__typename: "TagsDocAccessFields_skipCountCheck_Create",
	permission: boolean
};
	["TagsDocAccessFields_skipCountCheck_Read"]: {
	__typename: "TagsDocAccessFields_skipCountCheck_Read",
	permission: boolean
};
	["TagsDocAccessFields_skipCountCheck_Update"]: {
	__typename: "TagsDocAccessFields_skipCountCheck_Update",
	permission: boolean
};
	["TagsDocAccessFields_skipCountCheck_Delete"]: {
	__typename: "TagsDocAccessFields_skipCountCheck_Delete",
	permission: boolean
};
	["TagsDocAccessFields_removeNextCron"]: {
	__typename: "TagsDocAccessFields_removeNextCron",
	create?: GraphQLTypes["TagsDocAccessFields_removeNextCron_Create"] | undefined,
	read?: GraphQLTypes["TagsDocAccessFields_removeNextCron_Read"] | undefined,
	update?: GraphQLTypes["TagsDocAccessFields_removeNextCron_Update"] | undefined,
	delete?: GraphQLTypes["TagsDocAccessFields_removeNextCron_Delete"] | undefined
};
	["TagsDocAccessFields_removeNextCron_Create"]: {
	__typename: "TagsDocAccessFields_removeNextCron_Create",
	permission: boolean
};
	["TagsDocAccessFields_removeNextCron_Read"]: {
	__typename: "TagsDocAccessFields_removeNextCron_Read",
	permission: boolean
};
	["TagsDocAccessFields_removeNextCron_Update"]: {
	__typename: "TagsDocAccessFields_removeNextCron_Update",
	permission: boolean
};
	["TagsDocAccessFields_removeNextCron_Delete"]: {
	__typename: "TagsDocAccessFields_removeNextCron_Delete",
	permission: boolean
};
	["TagsCreateDocAccess"]: {
	__typename: "TagsCreateDocAccess",
	permission: boolean,
	where?: GraphQLTypes["JSONObject"] | undefined
};
	["TagsReadDocAccess"]: {
	__typename: "TagsReadDocAccess",
	permission: boolean,
	where?: GraphQLTypes["JSONObject"] | undefined
};
	["TagsUpdateDocAccess"]: {
	__typename: "TagsUpdateDocAccess",
	permission: boolean,
	where?: GraphQLTypes["JSONObject"] | undefined
};
	["TagsDeleteDocAccess"]: {
	__typename: "TagsDeleteDocAccess",
	permission: boolean,
	where?: GraphQLTypes["JSONObject"] | undefined
};
	["SpecialTags"]: {
	__typename: "SpecialTags",
	docs?: Array<GraphQLTypes["SpecialTag"] | undefined> | undefined,
	hasNextPage?: boolean | undefined,
	hasPrevPage?: boolean | undefined,
	limit?: number | undefined,
	nextPage?: number | undefined,
	offset?: number | undefined,
	page?: number | undefined,
	pagingCounter?: number | undefined,
	prevPage?: number | undefined,
	totalDocs?: number | undefined,
	totalPages?: number | undefined
};
	["SpecialTag_where"]: {
		name?: GraphQLTypes["SpecialTag_name_operator"] | undefined,
	value?: GraphQLTypes["SpecialTag_value_operator"] | undefined,
	region?: GraphQLTypes["SpecialTag_region_operator"] | undefined,
	id?: GraphQLTypes["SpecialTag_id_operator"] | undefined,
	AND?: Array<GraphQLTypes["SpecialTag_where_and"] | undefined> | undefined,
	OR?: Array<GraphQLTypes["SpecialTag_where_or"] | undefined> | undefined
};
	["SpecialTag_name_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined
};
	["SpecialTag_value_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined
};
	["SpecialTag_region_operator"]: {
		equals?: GraphQLTypes["SpecialTag_region_Input"] | undefined,
	not_equals?: GraphQLTypes["SpecialTag_region_Input"] | undefined,
	in?: Array<GraphQLTypes["SpecialTag_region_Input"] | undefined> | undefined,
	not_in?: Array<GraphQLTypes["SpecialTag_region_Input"] | undefined> | undefined,
	all?: Array<GraphQLTypes["SpecialTag_region_Input"] | undefined> | undefined,
	exists?: boolean | undefined
};
	["SpecialTag_region_Input"]: SpecialTag_region_Input;
	["SpecialTag_id_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["SpecialTag_where_and"]: {
		name?: GraphQLTypes["SpecialTag_name_operator"] | undefined,
	value?: GraphQLTypes["SpecialTag_value_operator"] | undefined,
	region?: GraphQLTypes["SpecialTag_region_operator"] | undefined,
	id?: GraphQLTypes["SpecialTag_id_operator"] | undefined,
	AND?: Array<GraphQLTypes["SpecialTag_where_and"] | undefined> | undefined,
	OR?: Array<GraphQLTypes["SpecialTag_where_or"] | undefined> | undefined
};
	["SpecialTag_where_or"]: {
		name?: GraphQLTypes["SpecialTag_name_operator"] | undefined,
	value?: GraphQLTypes["SpecialTag_value_operator"] | undefined,
	region?: GraphQLTypes["SpecialTag_region_operator"] | undefined,
	id?: GraphQLTypes["SpecialTag_id_operator"] | undefined,
	AND?: Array<GraphQLTypes["SpecialTag_where_and"] | undefined> | undefined,
	OR?: Array<GraphQLTypes["SpecialTag_where_or"] | undefined> | undefined
};
	["countSpecialTags"]: {
	__typename: "countSpecialTags",
	totalDocs?: number | undefined
};
	["specialTagsDocAccess"]: {
	__typename: "specialTagsDocAccess",
	fields?: GraphQLTypes["SpecialTagsDocAccessFields"] | undefined,
	create?: GraphQLTypes["SpecialTagsCreateDocAccess"] | undefined,
	read?: GraphQLTypes["SpecialTagsReadDocAccess"] | undefined,
	update?: GraphQLTypes["SpecialTagsUpdateDocAccess"] | undefined,
	delete?: GraphQLTypes["SpecialTagsDeleteDocAccess"] | undefined
};
	["SpecialTagsDocAccessFields"]: {
	__typename: "SpecialTagsDocAccessFields",
	name?: GraphQLTypes["SpecialTagsDocAccessFields_name"] | undefined,
	value?: GraphQLTypes["SpecialTagsDocAccessFields_value"] | undefined,
	region?: GraphQLTypes["SpecialTagsDocAccessFields_region"] | undefined
};
	["SpecialTagsDocAccessFields_name"]: {
	__typename: "SpecialTagsDocAccessFields_name",
	create?: GraphQLTypes["SpecialTagsDocAccessFields_name_Create"] | undefined,
	read?: GraphQLTypes["SpecialTagsDocAccessFields_name_Read"] | undefined,
	update?: GraphQLTypes["SpecialTagsDocAccessFields_name_Update"] | undefined,
	delete?: GraphQLTypes["SpecialTagsDocAccessFields_name_Delete"] | undefined
};
	["SpecialTagsDocAccessFields_name_Create"]: {
	__typename: "SpecialTagsDocAccessFields_name_Create",
	permission: boolean
};
	["SpecialTagsDocAccessFields_name_Read"]: {
	__typename: "SpecialTagsDocAccessFields_name_Read",
	permission: boolean
};
	["SpecialTagsDocAccessFields_name_Update"]: {
	__typename: "SpecialTagsDocAccessFields_name_Update",
	permission: boolean
};
	["SpecialTagsDocAccessFields_name_Delete"]: {
	__typename: "SpecialTagsDocAccessFields_name_Delete",
	permission: boolean
};
	["SpecialTagsDocAccessFields_value"]: {
	__typename: "SpecialTagsDocAccessFields_value",
	create?: GraphQLTypes["SpecialTagsDocAccessFields_value_Create"] | undefined,
	read?: GraphQLTypes["SpecialTagsDocAccessFields_value_Read"] | undefined,
	update?: GraphQLTypes["SpecialTagsDocAccessFields_value_Update"] | undefined,
	delete?: GraphQLTypes["SpecialTagsDocAccessFields_value_Delete"] | undefined
};
	["SpecialTagsDocAccessFields_value_Create"]: {
	__typename: "SpecialTagsDocAccessFields_value_Create",
	permission: boolean
};
	["SpecialTagsDocAccessFields_value_Read"]: {
	__typename: "SpecialTagsDocAccessFields_value_Read",
	permission: boolean
};
	["SpecialTagsDocAccessFields_value_Update"]: {
	__typename: "SpecialTagsDocAccessFields_value_Update",
	permission: boolean
};
	["SpecialTagsDocAccessFields_value_Delete"]: {
	__typename: "SpecialTagsDocAccessFields_value_Delete",
	permission: boolean
};
	["SpecialTagsDocAccessFields_region"]: {
	__typename: "SpecialTagsDocAccessFields_region",
	create?: GraphQLTypes["SpecialTagsDocAccessFields_region_Create"] | undefined,
	read?: GraphQLTypes["SpecialTagsDocAccessFields_region_Read"] | undefined,
	update?: GraphQLTypes["SpecialTagsDocAccessFields_region_Update"] | undefined,
	delete?: GraphQLTypes["SpecialTagsDocAccessFields_region_Delete"] | undefined
};
	["SpecialTagsDocAccessFields_region_Create"]: {
	__typename: "SpecialTagsDocAccessFields_region_Create",
	permission: boolean
};
	["SpecialTagsDocAccessFields_region_Read"]: {
	__typename: "SpecialTagsDocAccessFields_region_Read",
	permission: boolean
};
	["SpecialTagsDocAccessFields_region_Update"]: {
	__typename: "SpecialTagsDocAccessFields_region_Update",
	permission: boolean
};
	["SpecialTagsDocAccessFields_region_Delete"]: {
	__typename: "SpecialTagsDocAccessFields_region_Delete",
	permission: boolean
};
	["SpecialTagsCreateDocAccess"]: {
	__typename: "SpecialTagsCreateDocAccess",
	permission: boolean,
	where?: GraphQLTypes["JSONObject"] | undefined
};
	["SpecialTagsReadDocAccess"]: {
	__typename: "SpecialTagsReadDocAccess",
	permission: boolean,
	where?: GraphQLTypes["JSONObject"] | undefined
};
	["SpecialTagsUpdateDocAccess"]: {
	__typename: "SpecialTagsUpdateDocAccess",
	permission: boolean,
	where?: GraphQLTypes["JSONObject"] | undefined
};
	["SpecialTagsDeleteDocAccess"]: {
	__typename: "SpecialTagsDeleteDocAccess",
	permission: boolean,
	where?: GraphQLTypes["JSONObject"] | undefined
};
	["Dates"]: {
	__typename: "Dates",
	docs?: Array<GraphQLTypes["Date"] | undefined> | undefined,
	hasNextPage?: boolean | undefined,
	hasPrevPage?: boolean | undefined,
	limit?: number | undefined,
	nextPage?: number | undefined,
	offset?: number | undefined,
	page?: number | undefined,
	pagingCounter?: number | undefined,
	prevPage?: number | undefined,
	totalDocs?: number | undefined,
	totalPages?: number | undefined
};
	["Date_where"]: {
		name?: GraphQLTypes["Date_name_operator"] | undefined,
	value?: GraphQLTypes["Date_value_operator"] | undefined,
	type?: GraphQLTypes["Date_type_operator"] | undefined,
	start?: GraphQLTypes["Date_start_operator"] | undefined,
	end?: GraphQLTypes["Date_end_operator"] | undefined,
	_title?: GraphQLTypes["Date__title_operator"] | undefined,
	region?: GraphQLTypes["Date_region_operator"] | undefined,
	updatedAt?: GraphQLTypes["Date_updatedAt_operator"] | undefined,
	createdAt?: GraphQLTypes["Date_createdAt_operator"] | undefined,
	id?: GraphQLTypes["Date_id_operator"] | undefined,
	AND?: Array<GraphQLTypes["Date_where_and"] | undefined> | undefined,
	OR?: Array<GraphQLTypes["Date_where_or"] | undefined> | undefined
};
	["Date_name_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined
};
	["Date_value_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined
};
	["Date_type_operator"]: {
		equals?: GraphQLTypes["Date_type_Input"] | undefined,
	not_equals?: GraphQLTypes["Date_type_Input"] | undefined,
	in?: Array<GraphQLTypes["Date_type_Input"] | undefined> | undefined,
	not_in?: Array<GraphQLTypes["Date_type_Input"] | undefined> | undefined,
	all?: Array<GraphQLTypes["Date_type_Input"] | undefined> | undefined
};
	["Date_type_Input"]: Date_type_Input;
	["Date_start_operator"]: {
		equals?: GraphQLTypes["DateTime"] | undefined,
	not_equals?: GraphQLTypes["DateTime"] | undefined,
	greater_than_equal?: GraphQLTypes["DateTime"] | undefined,
	greater_than?: GraphQLTypes["DateTime"] | undefined,
	less_than_equal?: GraphQLTypes["DateTime"] | undefined,
	less_than?: GraphQLTypes["DateTime"] | undefined,
	like?: GraphQLTypes["DateTime"] | undefined,
	exists?: boolean | undefined
};
	["Date_end_operator"]: {
		equals?: GraphQLTypes["DateTime"] | undefined,
	not_equals?: GraphQLTypes["DateTime"] | undefined,
	greater_than_equal?: GraphQLTypes["DateTime"] | undefined,
	greater_than?: GraphQLTypes["DateTime"] | undefined,
	less_than_equal?: GraphQLTypes["DateTime"] | undefined,
	less_than?: GraphQLTypes["DateTime"] | undefined,
	like?: GraphQLTypes["DateTime"] | undefined,
	exists?: boolean | undefined
};
	["Date__title_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Date_region_operator"]: {
		equals?: GraphQLTypes["Date_region_Input"] | undefined,
	not_equals?: GraphQLTypes["Date_region_Input"] | undefined,
	in?: Array<GraphQLTypes["Date_region_Input"] | undefined> | undefined,
	not_in?: Array<GraphQLTypes["Date_region_Input"] | undefined> | undefined,
	all?: Array<GraphQLTypes["Date_region_Input"] | undefined> | undefined,
	exists?: boolean | undefined
};
	["Date_region_Input"]: Date_region_Input;
	["Date_updatedAt_operator"]: {
		equals?: GraphQLTypes["DateTime"] | undefined,
	not_equals?: GraphQLTypes["DateTime"] | undefined,
	greater_than_equal?: GraphQLTypes["DateTime"] | undefined,
	greater_than?: GraphQLTypes["DateTime"] | undefined,
	less_than_equal?: GraphQLTypes["DateTime"] | undefined,
	less_than?: GraphQLTypes["DateTime"] | undefined,
	like?: GraphQLTypes["DateTime"] | undefined,
	exists?: boolean | undefined
};
	["Date_createdAt_operator"]: {
		equals?: GraphQLTypes["DateTime"] | undefined,
	not_equals?: GraphQLTypes["DateTime"] | undefined,
	greater_than_equal?: GraphQLTypes["DateTime"] | undefined,
	greater_than?: GraphQLTypes["DateTime"] | undefined,
	less_than_equal?: GraphQLTypes["DateTime"] | undefined,
	less_than?: GraphQLTypes["DateTime"] | undefined,
	like?: GraphQLTypes["DateTime"] | undefined,
	exists?: boolean | undefined
};
	["Date_id_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Date_where_and"]: {
		name?: GraphQLTypes["Date_name_operator"] | undefined,
	value?: GraphQLTypes["Date_value_operator"] | undefined,
	type?: GraphQLTypes["Date_type_operator"] | undefined,
	start?: GraphQLTypes["Date_start_operator"] | undefined,
	end?: GraphQLTypes["Date_end_operator"] | undefined,
	_title?: GraphQLTypes["Date__title_operator"] | undefined,
	region?: GraphQLTypes["Date_region_operator"] | undefined,
	updatedAt?: GraphQLTypes["Date_updatedAt_operator"] | undefined,
	createdAt?: GraphQLTypes["Date_createdAt_operator"] | undefined,
	id?: GraphQLTypes["Date_id_operator"] | undefined,
	AND?: Array<GraphQLTypes["Date_where_and"] | undefined> | undefined,
	OR?: Array<GraphQLTypes["Date_where_or"] | undefined> | undefined
};
	["Date_where_or"]: {
		name?: GraphQLTypes["Date_name_operator"] | undefined,
	value?: GraphQLTypes["Date_value_operator"] | undefined,
	type?: GraphQLTypes["Date_type_operator"] | undefined,
	start?: GraphQLTypes["Date_start_operator"] | undefined,
	end?: GraphQLTypes["Date_end_operator"] | undefined,
	_title?: GraphQLTypes["Date__title_operator"] | undefined,
	region?: GraphQLTypes["Date_region_operator"] | undefined,
	updatedAt?: GraphQLTypes["Date_updatedAt_operator"] | undefined,
	createdAt?: GraphQLTypes["Date_createdAt_operator"] | undefined,
	id?: GraphQLTypes["Date_id_operator"] | undefined,
	AND?: Array<GraphQLTypes["Date_where_and"] | undefined> | undefined,
	OR?: Array<GraphQLTypes["Date_where_or"] | undefined> | undefined
};
	["countDates"]: {
	__typename: "countDates",
	totalDocs?: number | undefined
};
	["dateDocAccess"]: {
	__typename: "dateDocAccess",
	fields?: GraphQLTypes["DateDocAccessFields"] | undefined,
	create?: GraphQLTypes["DateCreateDocAccess"] | undefined,
	read?: GraphQLTypes["DateReadDocAccess"] | undefined,
	update?: GraphQLTypes["DateUpdateDocAccess"] | undefined,
	delete?: GraphQLTypes["DateDeleteDocAccess"] | undefined
};
	["DateDocAccessFields"]: {
	__typename: "DateDocAccessFields",
	name?: GraphQLTypes["DateDocAccessFields_name"] | undefined,
	value?: GraphQLTypes["DateDocAccessFields_value"] | undefined,
	type?: GraphQLTypes["DateDocAccessFields_type"] | undefined,
	start?: GraphQLTypes["DateDocAccessFields_start"] | undefined,
	end?: GraphQLTypes["DateDocAccessFields_end"] | undefined,
	_title?: GraphQLTypes["DateDocAccessFields__title"] | undefined,
	region?: GraphQLTypes["DateDocAccessFields_region"] | undefined,
	updatedAt?: GraphQLTypes["DateDocAccessFields_updatedAt"] | undefined,
	createdAt?: GraphQLTypes["DateDocAccessFields_createdAt"] | undefined
};
	["DateDocAccessFields_name"]: {
	__typename: "DateDocAccessFields_name",
	create?: GraphQLTypes["DateDocAccessFields_name_Create"] | undefined,
	read?: GraphQLTypes["DateDocAccessFields_name_Read"] | undefined,
	update?: GraphQLTypes["DateDocAccessFields_name_Update"] | undefined,
	delete?: GraphQLTypes["DateDocAccessFields_name_Delete"] | undefined
};
	["DateDocAccessFields_name_Create"]: {
	__typename: "DateDocAccessFields_name_Create",
	permission: boolean
};
	["DateDocAccessFields_name_Read"]: {
	__typename: "DateDocAccessFields_name_Read",
	permission: boolean
};
	["DateDocAccessFields_name_Update"]: {
	__typename: "DateDocAccessFields_name_Update",
	permission: boolean
};
	["DateDocAccessFields_name_Delete"]: {
	__typename: "DateDocAccessFields_name_Delete",
	permission: boolean
};
	["DateDocAccessFields_value"]: {
	__typename: "DateDocAccessFields_value",
	create?: GraphQLTypes["DateDocAccessFields_value_Create"] | undefined,
	read?: GraphQLTypes["DateDocAccessFields_value_Read"] | undefined,
	update?: GraphQLTypes["DateDocAccessFields_value_Update"] | undefined,
	delete?: GraphQLTypes["DateDocAccessFields_value_Delete"] | undefined
};
	["DateDocAccessFields_value_Create"]: {
	__typename: "DateDocAccessFields_value_Create",
	permission: boolean
};
	["DateDocAccessFields_value_Read"]: {
	__typename: "DateDocAccessFields_value_Read",
	permission: boolean
};
	["DateDocAccessFields_value_Update"]: {
	__typename: "DateDocAccessFields_value_Update",
	permission: boolean
};
	["DateDocAccessFields_value_Delete"]: {
	__typename: "DateDocAccessFields_value_Delete",
	permission: boolean
};
	["DateDocAccessFields_type"]: {
	__typename: "DateDocAccessFields_type",
	create?: GraphQLTypes["DateDocAccessFields_type_Create"] | undefined,
	read?: GraphQLTypes["DateDocAccessFields_type_Read"] | undefined,
	update?: GraphQLTypes["DateDocAccessFields_type_Update"] | undefined,
	delete?: GraphQLTypes["DateDocAccessFields_type_Delete"] | undefined
};
	["DateDocAccessFields_type_Create"]: {
	__typename: "DateDocAccessFields_type_Create",
	permission: boolean
};
	["DateDocAccessFields_type_Read"]: {
	__typename: "DateDocAccessFields_type_Read",
	permission: boolean
};
	["DateDocAccessFields_type_Update"]: {
	__typename: "DateDocAccessFields_type_Update",
	permission: boolean
};
	["DateDocAccessFields_type_Delete"]: {
	__typename: "DateDocAccessFields_type_Delete",
	permission: boolean
};
	["DateDocAccessFields_start"]: {
	__typename: "DateDocAccessFields_start",
	create?: GraphQLTypes["DateDocAccessFields_start_Create"] | undefined,
	read?: GraphQLTypes["DateDocAccessFields_start_Read"] | undefined,
	update?: GraphQLTypes["DateDocAccessFields_start_Update"] | undefined,
	delete?: GraphQLTypes["DateDocAccessFields_start_Delete"] | undefined
};
	["DateDocAccessFields_start_Create"]: {
	__typename: "DateDocAccessFields_start_Create",
	permission: boolean
};
	["DateDocAccessFields_start_Read"]: {
	__typename: "DateDocAccessFields_start_Read",
	permission: boolean
};
	["DateDocAccessFields_start_Update"]: {
	__typename: "DateDocAccessFields_start_Update",
	permission: boolean
};
	["DateDocAccessFields_start_Delete"]: {
	__typename: "DateDocAccessFields_start_Delete",
	permission: boolean
};
	["DateDocAccessFields_end"]: {
	__typename: "DateDocAccessFields_end",
	create?: GraphQLTypes["DateDocAccessFields_end_Create"] | undefined,
	read?: GraphQLTypes["DateDocAccessFields_end_Read"] | undefined,
	update?: GraphQLTypes["DateDocAccessFields_end_Update"] | undefined,
	delete?: GraphQLTypes["DateDocAccessFields_end_Delete"] | undefined
};
	["DateDocAccessFields_end_Create"]: {
	__typename: "DateDocAccessFields_end_Create",
	permission: boolean
};
	["DateDocAccessFields_end_Read"]: {
	__typename: "DateDocAccessFields_end_Read",
	permission: boolean
};
	["DateDocAccessFields_end_Update"]: {
	__typename: "DateDocAccessFields_end_Update",
	permission: boolean
};
	["DateDocAccessFields_end_Delete"]: {
	__typename: "DateDocAccessFields_end_Delete",
	permission: boolean
};
	["DateDocAccessFields__title"]: {
	__typename: "DateDocAccessFields__title",
	create?: GraphQLTypes["DateDocAccessFields__title_Create"] | undefined,
	read?: GraphQLTypes["DateDocAccessFields__title_Read"] | undefined,
	update?: GraphQLTypes["DateDocAccessFields__title_Update"] | undefined,
	delete?: GraphQLTypes["DateDocAccessFields__title_Delete"] | undefined
};
	["DateDocAccessFields__title_Create"]: {
	__typename: "DateDocAccessFields__title_Create",
	permission: boolean
};
	["DateDocAccessFields__title_Read"]: {
	__typename: "DateDocAccessFields__title_Read",
	permission: boolean
};
	["DateDocAccessFields__title_Update"]: {
	__typename: "DateDocAccessFields__title_Update",
	permission: boolean
};
	["DateDocAccessFields__title_Delete"]: {
	__typename: "DateDocAccessFields__title_Delete",
	permission: boolean
};
	["DateDocAccessFields_region"]: {
	__typename: "DateDocAccessFields_region",
	create?: GraphQLTypes["DateDocAccessFields_region_Create"] | undefined,
	read?: GraphQLTypes["DateDocAccessFields_region_Read"] | undefined,
	update?: GraphQLTypes["DateDocAccessFields_region_Update"] | undefined,
	delete?: GraphQLTypes["DateDocAccessFields_region_Delete"] | undefined
};
	["DateDocAccessFields_region_Create"]: {
	__typename: "DateDocAccessFields_region_Create",
	permission: boolean
};
	["DateDocAccessFields_region_Read"]: {
	__typename: "DateDocAccessFields_region_Read",
	permission: boolean
};
	["DateDocAccessFields_region_Update"]: {
	__typename: "DateDocAccessFields_region_Update",
	permission: boolean
};
	["DateDocAccessFields_region_Delete"]: {
	__typename: "DateDocAccessFields_region_Delete",
	permission: boolean
};
	["DateDocAccessFields_updatedAt"]: {
	__typename: "DateDocAccessFields_updatedAt",
	create?: GraphQLTypes["DateDocAccessFields_updatedAt_Create"] | undefined,
	read?: GraphQLTypes["DateDocAccessFields_updatedAt_Read"] | undefined,
	update?: GraphQLTypes["DateDocAccessFields_updatedAt_Update"] | undefined,
	delete?: GraphQLTypes["DateDocAccessFields_updatedAt_Delete"] | undefined
};
	["DateDocAccessFields_updatedAt_Create"]: {
	__typename: "DateDocAccessFields_updatedAt_Create",
	permission: boolean
};
	["DateDocAccessFields_updatedAt_Read"]: {
	__typename: "DateDocAccessFields_updatedAt_Read",
	permission: boolean
};
	["DateDocAccessFields_updatedAt_Update"]: {
	__typename: "DateDocAccessFields_updatedAt_Update",
	permission: boolean
};
	["DateDocAccessFields_updatedAt_Delete"]: {
	__typename: "DateDocAccessFields_updatedAt_Delete",
	permission: boolean
};
	["DateDocAccessFields_createdAt"]: {
	__typename: "DateDocAccessFields_createdAt",
	create?: GraphQLTypes["DateDocAccessFields_createdAt_Create"] | undefined,
	read?: GraphQLTypes["DateDocAccessFields_createdAt_Read"] | undefined,
	update?: GraphQLTypes["DateDocAccessFields_createdAt_Update"] | undefined,
	delete?: GraphQLTypes["DateDocAccessFields_createdAt_Delete"] | undefined
};
	["DateDocAccessFields_createdAt_Create"]: {
	__typename: "DateDocAccessFields_createdAt_Create",
	permission: boolean
};
	["DateDocAccessFields_createdAt_Read"]: {
	__typename: "DateDocAccessFields_createdAt_Read",
	permission: boolean
};
	["DateDocAccessFields_createdAt_Update"]: {
	__typename: "DateDocAccessFields_createdAt_Update",
	permission: boolean
};
	["DateDocAccessFields_createdAt_Delete"]: {
	__typename: "DateDocAccessFields_createdAt_Delete",
	permission: boolean
};
	["DateCreateDocAccess"]: {
	__typename: "DateCreateDocAccess",
	permission: boolean,
	where?: GraphQLTypes["JSONObject"] | undefined
};
	["DateReadDocAccess"]: {
	__typename: "DateReadDocAccess",
	permission: boolean,
	where?: GraphQLTypes["JSONObject"] | undefined
};
	["DateUpdateDocAccess"]: {
	__typename: "DateUpdateDocAccess",
	permission: boolean,
	where?: GraphQLTypes["JSONObject"] | undefined
};
	["DateDeleteDocAccess"]: {
	__typename: "DateDeleteDocAccess",
	permission: boolean,
	where?: GraphQLTypes["JSONObject"] | undefined
};
	["Districts"]: {
	__typename: "Districts",
	docs?: Array<GraphQLTypes["District"] | undefined> | undefined,
	hasNextPage?: boolean | undefined,
	hasPrevPage?: boolean | undefined,
	limit?: number | undefined,
	nextPage?: number | undefined,
	offset?: number | undefined,
	page?: number | undefined,
	pagingCounter?: number | undefined,
	prevPage?: number | undefined,
	totalDocs?: number | undefined,
	totalPages?: number | undefined
};
	["District_where"]: {
		name?: GraphQLTypes["District_name_operator"] | undefined,
	level?: GraphQLTypes["District_level_operator"] | undefined,
	children?: GraphQLTypes["District_children_operator"] | undefined,
	value?: GraphQLTypes["District_value_operator"] | undefined,
	lat?: GraphQLTypes["District_lat_operator"] | undefined,
	long?: GraphQLTypes["District_long_operator"] | undefined,
	radius?: GraphQLTypes["District_radius_operator"] | undefined,
	region?: GraphQLTypes["District_region_operator"] | undefined,
	updatedAt?: GraphQLTypes["District_updatedAt_operator"] | undefined,
	createdAt?: GraphQLTypes["District_createdAt_operator"] | undefined,
	id?: GraphQLTypes["District_id_operator"] | undefined,
	AND?: Array<GraphQLTypes["District_where_and"] | undefined> | undefined,
	OR?: Array<GraphQLTypes["District_where_or"] | undefined> | undefined
};
	["District_name_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined
};
	["District_level_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined
};
	["District_children_operator"]: {
		equals?: GraphQLTypes["JSON"] | undefined,
	not_equals?: GraphQLTypes["JSON"] | undefined,
	in?: Array<GraphQLTypes["JSON"] | undefined> | undefined,
	not_in?: Array<GraphQLTypes["JSON"] | undefined> | undefined,
	all?: Array<GraphQLTypes["JSON"] | undefined> | undefined,
	exists?: boolean | undefined
};
	["District_value_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined
};
	["District_lat_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["District_long_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["District_radius_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["District_region_operator"]: {
		equals?: GraphQLTypes["District_region_Input"] | undefined,
	not_equals?: GraphQLTypes["District_region_Input"] | undefined,
	in?: Array<GraphQLTypes["District_region_Input"] | undefined> | undefined,
	not_in?: Array<GraphQLTypes["District_region_Input"] | undefined> | undefined,
	all?: Array<GraphQLTypes["District_region_Input"] | undefined> | undefined,
	exists?: boolean | undefined
};
	["District_region_Input"]: District_region_Input;
	["District_updatedAt_operator"]: {
		equals?: GraphQLTypes["DateTime"] | undefined,
	not_equals?: GraphQLTypes["DateTime"] | undefined,
	greater_than_equal?: GraphQLTypes["DateTime"] | undefined,
	greater_than?: GraphQLTypes["DateTime"] | undefined,
	less_than_equal?: GraphQLTypes["DateTime"] | undefined,
	less_than?: GraphQLTypes["DateTime"] | undefined,
	like?: GraphQLTypes["DateTime"] | undefined,
	exists?: boolean | undefined
};
	["District_createdAt_operator"]: {
		equals?: GraphQLTypes["DateTime"] | undefined,
	not_equals?: GraphQLTypes["DateTime"] | undefined,
	greater_than_equal?: GraphQLTypes["DateTime"] | undefined,
	greater_than?: GraphQLTypes["DateTime"] | undefined,
	less_than_equal?: GraphQLTypes["DateTime"] | undefined,
	less_than?: GraphQLTypes["DateTime"] | undefined,
	like?: GraphQLTypes["DateTime"] | undefined,
	exists?: boolean | undefined
};
	["District_id_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["District_where_and"]: {
		name?: GraphQLTypes["District_name_operator"] | undefined,
	level?: GraphQLTypes["District_level_operator"] | undefined,
	children?: GraphQLTypes["District_children_operator"] | undefined,
	value?: GraphQLTypes["District_value_operator"] | undefined,
	lat?: GraphQLTypes["District_lat_operator"] | undefined,
	long?: GraphQLTypes["District_long_operator"] | undefined,
	radius?: GraphQLTypes["District_radius_operator"] | undefined,
	region?: GraphQLTypes["District_region_operator"] | undefined,
	updatedAt?: GraphQLTypes["District_updatedAt_operator"] | undefined,
	createdAt?: GraphQLTypes["District_createdAt_operator"] | undefined,
	id?: GraphQLTypes["District_id_operator"] | undefined,
	AND?: Array<GraphQLTypes["District_where_and"] | undefined> | undefined,
	OR?: Array<GraphQLTypes["District_where_or"] | undefined> | undefined
};
	["District_where_or"]: {
		name?: GraphQLTypes["District_name_operator"] | undefined,
	level?: GraphQLTypes["District_level_operator"] | undefined,
	children?: GraphQLTypes["District_children_operator"] | undefined,
	value?: GraphQLTypes["District_value_operator"] | undefined,
	lat?: GraphQLTypes["District_lat_operator"] | undefined,
	long?: GraphQLTypes["District_long_operator"] | undefined,
	radius?: GraphQLTypes["District_radius_operator"] | undefined,
	region?: GraphQLTypes["District_region_operator"] | undefined,
	updatedAt?: GraphQLTypes["District_updatedAt_operator"] | undefined,
	createdAt?: GraphQLTypes["District_createdAt_operator"] | undefined,
	id?: GraphQLTypes["District_id_operator"] | undefined,
	AND?: Array<GraphQLTypes["District_where_and"] | undefined> | undefined,
	OR?: Array<GraphQLTypes["District_where_or"] | undefined> | undefined
};
	["countDistricts"]: {
	__typename: "countDistricts",
	totalDocs?: number | undefined
};
	["districtsDocAccess"]: {
	__typename: "districtsDocAccess",
	fields?: GraphQLTypes["DistrictsDocAccessFields"] | undefined,
	create?: GraphQLTypes["DistrictsCreateDocAccess"] | undefined,
	read?: GraphQLTypes["DistrictsReadDocAccess"] | undefined,
	update?: GraphQLTypes["DistrictsUpdateDocAccess"] | undefined,
	delete?: GraphQLTypes["DistrictsDeleteDocAccess"] | undefined
};
	["DistrictsDocAccessFields"]: {
	__typename: "DistrictsDocAccessFields",
	name?: GraphQLTypes["DistrictsDocAccessFields_name"] | undefined,
	level?: GraphQLTypes["DistrictsDocAccessFields_level"] | undefined,
	children?: GraphQLTypes["DistrictsDocAccessFields_children"] | undefined,
	value?: GraphQLTypes["DistrictsDocAccessFields_value"] | undefined,
	lat?: GraphQLTypes["DistrictsDocAccessFields_lat"] | undefined,
	long?: GraphQLTypes["DistrictsDocAccessFields_long"] | undefined,
	radius?: GraphQLTypes["DistrictsDocAccessFields_radius"] | undefined,
	region?: GraphQLTypes["DistrictsDocAccessFields_region"] | undefined,
	updatedAt?: GraphQLTypes["DistrictsDocAccessFields_updatedAt"] | undefined,
	createdAt?: GraphQLTypes["DistrictsDocAccessFields_createdAt"] | undefined
};
	["DistrictsDocAccessFields_name"]: {
	__typename: "DistrictsDocAccessFields_name",
	create?: GraphQLTypes["DistrictsDocAccessFields_name_Create"] | undefined,
	read?: GraphQLTypes["DistrictsDocAccessFields_name_Read"] | undefined,
	update?: GraphQLTypes["DistrictsDocAccessFields_name_Update"] | undefined,
	delete?: GraphQLTypes["DistrictsDocAccessFields_name_Delete"] | undefined
};
	["DistrictsDocAccessFields_name_Create"]: {
	__typename: "DistrictsDocAccessFields_name_Create",
	permission: boolean
};
	["DistrictsDocAccessFields_name_Read"]: {
	__typename: "DistrictsDocAccessFields_name_Read",
	permission: boolean
};
	["DistrictsDocAccessFields_name_Update"]: {
	__typename: "DistrictsDocAccessFields_name_Update",
	permission: boolean
};
	["DistrictsDocAccessFields_name_Delete"]: {
	__typename: "DistrictsDocAccessFields_name_Delete",
	permission: boolean
};
	["DistrictsDocAccessFields_level"]: {
	__typename: "DistrictsDocAccessFields_level",
	create?: GraphQLTypes["DistrictsDocAccessFields_level_Create"] | undefined,
	read?: GraphQLTypes["DistrictsDocAccessFields_level_Read"] | undefined,
	update?: GraphQLTypes["DistrictsDocAccessFields_level_Update"] | undefined,
	delete?: GraphQLTypes["DistrictsDocAccessFields_level_Delete"] | undefined
};
	["DistrictsDocAccessFields_level_Create"]: {
	__typename: "DistrictsDocAccessFields_level_Create",
	permission: boolean
};
	["DistrictsDocAccessFields_level_Read"]: {
	__typename: "DistrictsDocAccessFields_level_Read",
	permission: boolean
};
	["DistrictsDocAccessFields_level_Update"]: {
	__typename: "DistrictsDocAccessFields_level_Update",
	permission: boolean
};
	["DistrictsDocAccessFields_level_Delete"]: {
	__typename: "DistrictsDocAccessFields_level_Delete",
	permission: boolean
};
	["DistrictsDocAccessFields_children"]: {
	__typename: "DistrictsDocAccessFields_children",
	create?: GraphQLTypes["DistrictsDocAccessFields_children_Create"] | undefined,
	read?: GraphQLTypes["DistrictsDocAccessFields_children_Read"] | undefined,
	update?: GraphQLTypes["DistrictsDocAccessFields_children_Update"] | undefined,
	delete?: GraphQLTypes["DistrictsDocAccessFields_children_Delete"] | undefined
};
	["DistrictsDocAccessFields_children_Create"]: {
	__typename: "DistrictsDocAccessFields_children_Create",
	permission: boolean
};
	["DistrictsDocAccessFields_children_Read"]: {
	__typename: "DistrictsDocAccessFields_children_Read",
	permission: boolean
};
	["DistrictsDocAccessFields_children_Update"]: {
	__typename: "DistrictsDocAccessFields_children_Update",
	permission: boolean
};
	["DistrictsDocAccessFields_children_Delete"]: {
	__typename: "DistrictsDocAccessFields_children_Delete",
	permission: boolean
};
	["DistrictsDocAccessFields_value"]: {
	__typename: "DistrictsDocAccessFields_value",
	create?: GraphQLTypes["DistrictsDocAccessFields_value_Create"] | undefined,
	read?: GraphQLTypes["DistrictsDocAccessFields_value_Read"] | undefined,
	update?: GraphQLTypes["DistrictsDocAccessFields_value_Update"] | undefined,
	delete?: GraphQLTypes["DistrictsDocAccessFields_value_Delete"] | undefined
};
	["DistrictsDocAccessFields_value_Create"]: {
	__typename: "DistrictsDocAccessFields_value_Create",
	permission: boolean
};
	["DistrictsDocAccessFields_value_Read"]: {
	__typename: "DistrictsDocAccessFields_value_Read",
	permission: boolean
};
	["DistrictsDocAccessFields_value_Update"]: {
	__typename: "DistrictsDocAccessFields_value_Update",
	permission: boolean
};
	["DistrictsDocAccessFields_value_Delete"]: {
	__typename: "DistrictsDocAccessFields_value_Delete",
	permission: boolean
};
	["DistrictsDocAccessFields_lat"]: {
	__typename: "DistrictsDocAccessFields_lat",
	create?: GraphQLTypes["DistrictsDocAccessFields_lat_Create"] | undefined,
	read?: GraphQLTypes["DistrictsDocAccessFields_lat_Read"] | undefined,
	update?: GraphQLTypes["DistrictsDocAccessFields_lat_Update"] | undefined,
	delete?: GraphQLTypes["DistrictsDocAccessFields_lat_Delete"] | undefined
};
	["DistrictsDocAccessFields_lat_Create"]: {
	__typename: "DistrictsDocAccessFields_lat_Create",
	permission: boolean
};
	["DistrictsDocAccessFields_lat_Read"]: {
	__typename: "DistrictsDocAccessFields_lat_Read",
	permission: boolean
};
	["DistrictsDocAccessFields_lat_Update"]: {
	__typename: "DistrictsDocAccessFields_lat_Update",
	permission: boolean
};
	["DistrictsDocAccessFields_lat_Delete"]: {
	__typename: "DistrictsDocAccessFields_lat_Delete",
	permission: boolean
};
	["DistrictsDocAccessFields_long"]: {
	__typename: "DistrictsDocAccessFields_long",
	create?: GraphQLTypes["DistrictsDocAccessFields_long_Create"] | undefined,
	read?: GraphQLTypes["DistrictsDocAccessFields_long_Read"] | undefined,
	update?: GraphQLTypes["DistrictsDocAccessFields_long_Update"] | undefined,
	delete?: GraphQLTypes["DistrictsDocAccessFields_long_Delete"] | undefined
};
	["DistrictsDocAccessFields_long_Create"]: {
	__typename: "DistrictsDocAccessFields_long_Create",
	permission: boolean
};
	["DistrictsDocAccessFields_long_Read"]: {
	__typename: "DistrictsDocAccessFields_long_Read",
	permission: boolean
};
	["DistrictsDocAccessFields_long_Update"]: {
	__typename: "DistrictsDocAccessFields_long_Update",
	permission: boolean
};
	["DistrictsDocAccessFields_long_Delete"]: {
	__typename: "DistrictsDocAccessFields_long_Delete",
	permission: boolean
};
	["DistrictsDocAccessFields_radius"]: {
	__typename: "DistrictsDocAccessFields_radius",
	create?: GraphQLTypes["DistrictsDocAccessFields_radius_Create"] | undefined,
	read?: GraphQLTypes["DistrictsDocAccessFields_radius_Read"] | undefined,
	update?: GraphQLTypes["DistrictsDocAccessFields_radius_Update"] | undefined,
	delete?: GraphQLTypes["DistrictsDocAccessFields_radius_Delete"] | undefined
};
	["DistrictsDocAccessFields_radius_Create"]: {
	__typename: "DistrictsDocAccessFields_radius_Create",
	permission: boolean
};
	["DistrictsDocAccessFields_radius_Read"]: {
	__typename: "DistrictsDocAccessFields_radius_Read",
	permission: boolean
};
	["DistrictsDocAccessFields_radius_Update"]: {
	__typename: "DistrictsDocAccessFields_radius_Update",
	permission: boolean
};
	["DistrictsDocAccessFields_radius_Delete"]: {
	__typename: "DistrictsDocAccessFields_radius_Delete",
	permission: boolean
};
	["DistrictsDocAccessFields_region"]: {
	__typename: "DistrictsDocAccessFields_region",
	create?: GraphQLTypes["DistrictsDocAccessFields_region_Create"] | undefined,
	read?: GraphQLTypes["DistrictsDocAccessFields_region_Read"] | undefined,
	update?: GraphQLTypes["DistrictsDocAccessFields_region_Update"] | undefined,
	delete?: GraphQLTypes["DistrictsDocAccessFields_region_Delete"] | undefined
};
	["DistrictsDocAccessFields_region_Create"]: {
	__typename: "DistrictsDocAccessFields_region_Create",
	permission: boolean
};
	["DistrictsDocAccessFields_region_Read"]: {
	__typename: "DistrictsDocAccessFields_region_Read",
	permission: boolean
};
	["DistrictsDocAccessFields_region_Update"]: {
	__typename: "DistrictsDocAccessFields_region_Update",
	permission: boolean
};
	["DistrictsDocAccessFields_region_Delete"]: {
	__typename: "DistrictsDocAccessFields_region_Delete",
	permission: boolean
};
	["DistrictsDocAccessFields_updatedAt"]: {
	__typename: "DistrictsDocAccessFields_updatedAt",
	create?: GraphQLTypes["DistrictsDocAccessFields_updatedAt_Create"] | undefined,
	read?: GraphQLTypes["DistrictsDocAccessFields_updatedAt_Read"] | undefined,
	update?: GraphQLTypes["DistrictsDocAccessFields_updatedAt_Update"] | undefined,
	delete?: GraphQLTypes["DistrictsDocAccessFields_updatedAt_Delete"] | undefined
};
	["DistrictsDocAccessFields_updatedAt_Create"]: {
	__typename: "DistrictsDocAccessFields_updatedAt_Create",
	permission: boolean
};
	["DistrictsDocAccessFields_updatedAt_Read"]: {
	__typename: "DistrictsDocAccessFields_updatedAt_Read",
	permission: boolean
};
	["DistrictsDocAccessFields_updatedAt_Update"]: {
	__typename: "DistrictsDocAccessFields_updatedAt_Update",
	permission: boolean
};
	["DistrictsDocAccessFields_updatedAt_Delete"]: {
	__typename: "DistrictsDocAccessFields_updatedAt_Delete",
	permission: boolean
};
	["DistrictsDocAccessFields_createdAt"]: {
	__typename: "DistrictsDocAccessFields_createdAt",
	create?: GraphQLTypes["DistrictsDocAccessFields_createdAt_Create"] | undefined,
	read?: GraphQLTypes["DistrictsDocAccessFields_createdAt_Read"] | undefined,
	update?: GraphQLTypes["DistrictsDocAccessFields_createdAt_Update"] | undefined,
	delete?: GraphQLTypes["DistrictsDocAccessFields_createdAt_Delete"] | undefined
};
	["DistrictsDocAccessFields_createdAt_Create"]: {
	__typename: "DistrictsDocAccessFields_createdAt_Create",
	permission: boolean
};
	["DistrictsDocAccessFields_createdAt_Read"]: {
	__typename: "DistrictsDocAccessFields_createdAt_Read",
	permission: boolean
};
	["DistrictsDocAccessFields_createdAt_Update"]: {
	__typename: "DistrictsDocAccessFields_createdAt_Update",
	permission: boolean
};
	["DistrictsDocAccessFields_createdAt_Delete"]: {
	__typename: "DistrictsDocAccessFields_createdAt_Delete",
	permission: boolean
};
	["DistrictsCreateDocAccess"]: {
	__typename: "DistrictsCreateDocAccess",
	permission: boolean,
	where?: GraphQLTypes["JSONObject"] | undefined
};
	["DistrictsReadDocAccess"]: {
	__typename: "DistrictsReadDocAccess",
	permission: boolean,
	where?: GraphQLTypes["JSONObject"] | undefined
};
	["DistrictsUpdateDocAccess"]: {
	__typename: "DistrictsUpdateDocAccess",
	permission: boolean,
	where?: GraphQLTypes["JSONObject"] | undefined
};
	["DistrictsDeleteDocAccess"]: {
	__typename: "DistrictsDeleteDocAccess",
	permission: boolean,
	where?: GraphQLTypes["JSONObject"] | undefined
};
	["Audiences"]: {
	__typename: "Audiences",
	docs?: Array<GraphQLTypes["Audience"] | undefined> | undefined,
	hasNextPage?: boolean | undefined,
	hasPrevPage?: boolean | undefined,
	limit?: number | undefined,
	nextPage?: number | undefined,
	offset?: number | undefined,
	page?: number | undefined,
	pagingCounter?: number | undefined,
	prevPage?: number | undefined,
	totalDocs?: number | undefined,
	totalPages?: number | undefined
};
	["Audience_where"]: {
		name?: GraphQLTypes["Audience_name_operator"] | undefined,
	value?: GraphQLTypes["Audience_value_operator"] | undefined,
	icon?: GraphQLTypes["Audience_icon_operator"] | undefined,
	region?: GraphQLTypes["Audience_region_operator"] | undefined,
	updatedAt?: GraphQLTypes["Audience_updatedAt_operator"] | undefined,
	createdAt?: GraphQLTypes["Audience_createdAt_operator"] | undefined,
	id?: GraphQLTypes["Audience_id_operator"] | undefined,
	AND?: Array<GraphQLTypes["Audience_where_and"] | undefined> | undefined,
	OR?: Array<GraphQLTypes["Audience_where_or"] | undefined> | undefined
};
	["Audience_name_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined
};
	["Audience_value_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined
};
	["Audience_icon_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	exists?: boolean | undefined
};
	["Audience_region_operator"]: {
		equals?: GraphQLTypes["Audience_region_Input"] | undefined,
	not_equals?: GraphQLTypes["Audience_region_Input"] | undefined,
	in?: Array<GraphQLTypes["Audience_region_Input"] | undefined> | undefined,
	not_in?: Array<GraphQLTypes["Audience_region_Input"] | undefined> | undefined,
	all?: Array<GraphQLTypes["Audience_region_Input"] | undefined> | undefined,
	exists?: boolean | undefined
};
	["Audience_region_Input"]: Audience_region_Input;
	["Audience_updatedAt_operator"]: {
		equals?: GraphQLTypes["DateTime"] | undefined,
	not_equals?: GraphQLTypes["DateTime"] | undefined,
	greater_than_equal?: GraphQLTypes["DateTime"] | undefined,
	greater_than?: GraphQLTypes["DateTime"] | undefined,
	less_than_equal?: GraphQLTypes["DateTime"] | undefined,
	less_than?: GraphQLTypes["DateTime"] | undefined,
	like?: GraphQLTypes["DateTime"] | undefined,
	exists?: boolean | undefined
};
	["Audience_createdAt_operator"]: {
		equals?: GraphQLTypes["DateTime"] | undefined,
	not_equals?: GraphQLTypes["DateTime"] | undefined,
	greater_than_equal?: GraphQLTypes["DateTime"] | undefined,
	greater_than?: GraphQLTypes["DateTime"] | undefined,
	less_than_equal?: GraphQLTypes["DateTime"] | undefined,
	less_than?: GraphQLTypes["DateTime"] | undefined,
	like?: GraphQLTypes["DateTime"] | undefined,
	exists?: boolean | undefined
};
	["Audience_id_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Audience_where_and"]: {
		name?: GraphQLTypes["Audience_name_operator"] | undefined,
	value?: GraphQLTypes["Audience_value_operator"] | undefined,
	icon?: GraphQLTypes["Audience_icon_operator"] | undefined,
	region?: GraphQLTypes["Audience_region_operator"] | undefined,
	updatedAt?: GraphQLTypes["Audience_updatedAt_operator"] | undefined,
	createdAt?: GraphQLTypes["Audience_createdAt_operator"] | undefined,
	id?: GraphQLTypes["Audience_id_operator"] | undefined,
	AND?: Array<GraphQLTypes["Audience_where_and"] | undefined> | undefined,
	OR?: Array<GraphQLTypes["Audience_where_or"] | undefined> | undefined
};
	["Audience_where_or"]: {
		name?: GraphQLTypes["Audience_name_operator"] | undefined,
	value?: GraphQLTypes["Audience_value_operator"] | undefined,
	icon?: GraphQLTypes["Audience_icon_operator"] | undefined,
	region?: GraphQLTypes["Audience_region_operator"] | undefined,
	updatedAt?: GraphQLTypes["Audience_updatedAt_operator"] | undefined,
	createdAt?: GraphQLTypes["Audience_createdAt_operator"] | undefined,
	id?: GraphQLTypes["Audience_id_operator"] | undefined,
	AND?: Array<GraphQLTypes["Audience_where_and"] | undefined> | undefined,
	OR?: Array<GraphQLTypes["Audience_where_or"] | undefined> | undefined
};
	["countAudiences"]: {
	__typename: "countAudiences",
	totalDocs?: number | undefined
};
	["audienceDocAccess"]: {
	__typename: "audienceDocAccess",
	fields?: GraphQLTypes["AudienceDocAccessFields"] | undefined,
	create?: GraphQLTypes["AudienceCreateDocAccess"] | undefined,
	read?: GraphQLTypes["AudienceReadDocAccess"] | undefined,
	update?: GraphQLTypes["AudienceUpdateDocAccess"] | undefined,
	delete?: GraphQLTypes["AudienceDeleteDocAccess"] | undefined
};
	["AudienceDocAccessFields"]: {
	__typename: "AudienceDocAccessFields",
	name?: GraphQLTypes["AudienceDocAccessFields_name"] | undefined,
	value?: GraphQLTypes["AudienceDocAccessFields_value"] | undefined,
	icon?: GraphQLTypes["AudienceDocAccessFields_icon"] | undefined,
	region?: GraphQLTypes["AudienceDocAccessFields_region"] | undefined,
	updatedAt?: GraphQLTypes["AudienceDocAccessFields_updatedAt"] | undefined,
	createdAt?: GraphQLTypes["AudienceDocAccessFields_createdAt"] | undefined
};
	["AudienceDocAccessFields_name"]: {
	__typename: "AudienceDocAccessFields_name",
	create?: GraphQLTypes["AudienceDocAccessFields_name_Create"] | undefined,
	read?: GraphQLTypes["AudienceDocAccessFields_name_Read"] | undefined,
	update?: GraphQLTypes["AudienceDocAccessFields_name_Update"] | undefined,
	delete?: GraphQLTypes["AudienceDocAccessFields_name_Delete"] | undefined
};
	["AudienceDocAccessFields_name_Create"]: {
	__typename: "AudienceDocAccessFields_name_Create",
	permission: boolean
};
	["AudienceDocAccessFields_name_Read"]: {
	__typename: "AudienceDocAccessFields_name_Read",
	permission: boolean
};
	["AudienceDocAccessFields_name_Update"]: {
	__typename: "AudienceDocAccessFields_name_Update",
	permission: boolean
};
	["AudienceDocAccessFields_name_Delete"]: {
	__typename: "AudienceDocAccessFields_name_Delete",
	permission: boolean
};
	["AudienceDocAccessFields_value"]: {
	__typename: "AudienceDocAccessFields_value",
	create?: GraphQLTypes["AudienceDocAccessFields_value_Create"] | undefined,
	read?: GraphQLTypes["AudienceDocAccessFields_value_Read"] | undefined,
	update?: GraphQLTypes["AudienceDocAccessFields_value_Update"] | undefined,
	delete?: GraphQLTypes["AudienceDocAccessFields_value_Delete"] | undefined
};
	["AudienceDocAccessFields_value_Create"]: {
	__typename: "AudienceDocAccessFields_value_Create",
	permission: boolean
};
	["AudienceDocAccessFields_value_Read"]: {
	__typename: "AudienceDocAccessFields_value_Read",
	permission: boolean
};
	["AudienceDocAccessFields_value_Update"]: {
	__typename: "AudienceDocAccessFields_value_Update",
	permission: boolean
};
	["AudienceDocAccessFields_value_Delete"]: {
	__typename: "AudienceDocAccessFields_value_Delete",
	permission: boolean
};
	["AudienceDocAccessFields_icon"]: {
	__typename: "AudienceDocAccessFields_icon",
	create?: GraphQLTypes["AudienceDocAccessFields_icon_Create"] | undefined,
	read?: GraphQLTypes["AudienceDocAccessFields_icon_Read"] | undefined,
	update?: GraphQLTypes["AudienceDocAccessFields_icon_Update"] | undefined,
	delete?: GraphQLTypes["AudienceDocAccessFields_icon_Delete"] | undefined
};
	["AudienceDocAccessFields_icon_Create"]: {
	__typename: "AudienceDocAccessFields_icon_Create",
	permission: boolean
};
	["AudienceDocAccessFields_icon_Read"]: {
	__typename: "AudienceDocAccessFields_icon_Read",
	permission: boolean
};
	["AudienceDocAccessFields_icon_Update"]: {
	__typename: "AudienceDocAccessFields_icon_Update",
	permission: boolean
};
	["AudienceDocAccessFields_icon_Delete"]: {
	__typename: "AudienceDocAccessFields_icon_Delete",
	permission: boolean
};
	["AudienceDocAccessFields_region"]: {
	__typename: "AudienceDocAccessFields_region",
	create?: GraphQLTypes["AudienceDocAccessFields_region_Create"] | undefined,
	read?: GraphQLTypes["AudienceDocAccessFields_region_Read"] | undefined,
	update?: GraphQLTypes["AudienceDocAccessFields_region_Update"] | undefined,
	delete?: GraphQLTypes["AudienceDocAccessFields_region_Delete"] | undefined
};
	["AudienceDocAccessFields_region_Create"]: {
	__typename: "AudienceDocAccessFields_region_Create",
	permission: boolean
};
	["AudienceDocAccessFields_region_Read"]: {
	__typename: "AudienceDocAccessFields_region_Read",
	permission: boolean
};
	["AudienceDocAccessFields_region_Update"]: {
	__typename: "AudienceDocAccessFields_region_Update",
	permission: boolean
};
	["AudienceDocAccessFields_region_Delete"]: {
	__typename: "AudienceDocAccessFields_region_Delete",
	permission: boolean
};
	["AudienceDocAccessFields_updatedAt"]: {
	__typename: "AudienceDocAccessFields_updatedAt",
	create?: GraphQLTypes["AudienceDocAccessFields_updatedAt_Create"] | undefined,
	read?: GraphQLTypes["AudienceDocAccessFields_updatedAt_Read"] | undefined,
	update?: GraphQLTypes["AudienceDocAccessFields_updatedAt_Update"] | undefined,
	delete?: GraphQLTypes["AudienceDocAccessFields_updatedAt_Delete"] | undefined
};
	["AudienceDocAccessFields_updatedAt_Create"]: {
	__typename: "AudienceDocAccessFields_updatedAt_Create",
	permission: boolean
};
	["AudienceDocAccessFields_updatedAt_Read"]: {
	__typename: "AudienceDocAccessFields_updatedAt_Read",
	permission: boolean
};
	["AudienceDocAccessFields_updatedAt_Update"]: {
	__typename: "AudienceDocAccessFields_updatedAt_Update",
	permission: boolean
};
	["AudienceDocAccessFields_updatedAt_Delete"]: {
	__typename: "AudienceDocAccessFields_updatedAt_Delete",
	permission: boolean
};
	["AudienceDocAccessFields_createdAt"]: {
	__typename: "AudienceDocAccessFields_createdAt",
	create?: GraphQLTypes["AudienceDocAccessFields_createdAt_Create"] | undefined,
	read?: GraphQLTypes["AudienceDocAccessFields_createdAt_Read"] | undefined,
	update?: GraphQLTypes["AudienceDocAccessFields_createdAt_Update"] | undefined,
	delete?: GraphQLTypes["AudienceDocAccessFields_createdAt_Delete"] | undefined
};
	["AudienceDocAccessFields_createdAt_Create"]: {
	__typename: "AudienceDocAccessFields_createdAt_Create",
	permission: boolean
};
	["AudienceDocAccessFields_createdAt_Read"]: {
	__typename: "AudienceDocAccessFields_createdAt_Read",
	permission: boolean
};
	["AudienceDocAccessFields_createdAt_Update"]: {
	__typename: "AudienceDocAccessFields_createdAt_Update",
	permission: boolean
};
	["AudienceDocAccessFields_createdAt_Delete"]: {
	__typename: "AudienceDocAccessFields_createdAt_Delete",
	permission: boolean
};
	["AudienceCreateDocAccess"]: {
	__typename: "AudienceCreateDocAccess",
	permission: boolean,
	where?: GraphQLTypes["JSONObject"] | undefined
};
	["AudienceReadDocAccess"]: {
	__typename: "AudienceReadDocAccess",
	permission: boolean,
	where?: GraphQLTypes["JSONObject"] | undefined
};
	["AudienceUpdateDocAccess"]: {
	__typename: "AudienceUpdateDocAccess",
	permission: boolean,
	where?: GraphQLTypes["JSONObject"] | undefined
};
	["AudienceDeleteDocAccess"]: {
	__typename: "AudienceDeleteDocAccess",
	permission: boolean,
	where?: GraphQLTypes["JSONObject"] | undefined
};
	["EventCategories"]: {
	__typename: "EventCategories",
	docs?: Array<GraphQLTypes["EventCategory"] | undefined> | undefined,
	hasNextPage?: boolean | undefined,
	hasPrevPage?: boolean | undefined,
	limit?: number | undefined,
	nextPage?: number | undefined,
	offset?: number | undefined,
	page?: number | undefined,
	pagingCounter?: number | undefined,
	prevPage?: number | undefined,
	totalDocs?: number | undefined,
	totalPages?: number | undefined
};
	["EventCategory_where"]: {
		name?: GraphQLTypes["EventCategory_name_operator"] | undefined,
	value?: GraphQLTypes["EventCategory_value_operator"] | undefined,
	tags?: GraphQLTypes["EventCategory_tags_operator"] | undefined,
	icon?: GraphQLTypes["EventCategory_icon_operator"] | undefined,
	region?: GraphQLTypes["EventCategory_region_operator"] | undefined,
	updatedAt?: GraphQLTypes["EventCategory_updatedAt_operator"] | undefined,
	createdAt?: GraphQLTypes["EventCategory_createdAt_operator"] | undefined,
	id?: GraphQLTypes["EventCategory_id_operator"] | undefined,
	AND?: Array<GraphQLTypes["EventCategory_where_and"] | undefined> | undefined,
	OR?: Array<GraphQLTypes["EventCategory_where_or"] | undefined> | undefined
};
	["EventCategory_name_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined
};
	["EventCategory_value_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined
};
	["EventCategory_tags_operator"]: {
		equals?: GraphQLTypes["JSON"] | undefined,
	not_equals?: GraphQLTypes["JSON"] | undefined,
	in?: Array<GraphQLTypes["JSON"] | undefined> | undefined,
	not_in?: Array<GraphQLTypes["JSON"] | undefined> | undefined,
	all?: Array<GraphQLTypes["JSON"] | undefined> | undefined,
	exists?: boolean | undefined
};
	["EventCategory_icon_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	exists?: boolean | undefined
};
	["EventCategory_region_operator"]: {
		equals?: GraphQLTypes["EventCategory_region_Input"] | undefined,
	not_equals?: GraphQLTypes["EventCategory_region_Input"] | undefined,
	in?: Array<GraphQLTypes["EventCategory_region_Input"] | undefined> | undefined,
	not_in?: Array<GraphQLTypes["EventCategory_region_Input"] | undefined> | undefined,
	all?: Array<GraphQLTypes["EventCategory_region_Input"] | undefined> | undefined,
	exists?: boolean | undefined
};
	["EventCategory_region_Input"]: EventCategory_region_Input;
	["EventCategory_updatedAt_operator"]: {
		equals?: GraphQLTypes["DateTime"] | undefined,
	not_equals?: GraphQLTypes["DateTime"] | undefined,
	greater_than_equal?: GraphQLTypes["DateTime"] | undefined,
	greater_than?: GraphQLTypes["DateTime"] | undefined,
	less_than_equal?: GraphQLTypes["DateTime"] | undefined,
	less_than?: GraphQLTypes["DateTime"] | undefined,
	like?: GraphQLTypes["DateTime"] | undefined,
	exists?: boolean | undefined
};
	["EventCategory_createdAt_operator"]: {
		equals?: GraphQLTypes["DateTime"] | undefined,
	not_equals?: GraphQLTypes["DateTime"] | undefined,
	greater_than_equal?: GraphQLTypes["DateTime"] | undefined,
	greater_than?: GraphQLTypes["DateTime"] | undefined,
	less_than_equal?: GraphQLTypes["DateTime"] | undefined,
	less_than?: GraphQLTypes["DateTime"] | undefined,
	like?: GraphQLTypes["DateTime"] | undefined,
	exists?: boolean | undefined
};
	["EventCategory_id_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["EventCategory_where_and"]: {
		name?: GraphQLTypes["EventCategory_name_operator"] | undefined,
	value?: GraphQLTypes["EventCategory_value_operator"] | undefined,
	tags?: GraphQLTypes["EventCategory_tags_operator"] | undefined,
	icon?: GraphQLTypes["EventCategory_icon_operator"] | undefined,
	region?: GraphQLTypes["EventCategory_region_operator"] | undefined,
	updatedAt?: GraphQLTypes["EventCategory_updatedAt_operator"] | undefined,
	createdAt?: GraphQLTypes["EventCategory_createdAt_operator"] | undefined,
	id?: GraphQLTypes["EventCategory_id_operator"] | undefined,
	AND?: Array<GraphQLTypes["EventCategory_where_and"] | undefined> | undefined,
	OR?: Array<GraphQLTypes["EventCategory_where_or"] | undefined> | undefined
};
	["EventCategory_where_or"]: {
		name?: GraphQLTypes["EventCategory_name_operator"] | undefined,
	value?: GraphQLTypes["EventCategory_value_operator"] | undefined,
	tags?: GraphQLTypes["EventCategory_tags_operator"] | undefined,
	icon?: GraphQLTypes["EventCategory_icon_operator"] | undefined,
	region?: GraphQLTypes["EventCategory_region_operator"] | undefined,
	updatedAt?: GraphQLTypes["EventCategory_updatedAt_operator"] | undefined,
	createdAt?: GraphQLTypes["EventCategory_createdAt_operator"] | undefined,
	id?: GraphQLTypes["EventCategory_id_operator"] | undefined,
	AND?: Array<GraphQLTypes["EventCategory_where_and"] | undefined> | undefined,
	OR?: Array<GraphQLTypes["EventCategory_where_or"] | undefined> | undefined
};
	["countEventCategories"]: {
	__typename: "countEventCategories",
	totalDocs?: number | undefined
};
	["categoryDocAccess"]: {
	__typename: "categoryDocAccess",
	fields?: GraphQLTypes["CategoryDocAccessFields"] | undefined,
	create?: GraphQLTypes["CategoryCreateDocAccess"] | undefined,
	read?: GraphQLTypes["CategoryReadDocAccess"] | undefined,
	update?: GraphQLTypes["CategoryUpdateDocAccess"] | undefined,
	delete?: GraphQLTypes["CategoryDeleteDocAccess"] | undefined
};
	["CategoryDocAccessFields"]: {
	__typename: "CategoryDocAccessFields",
	name?: GraphQLTypes["CategoryDocAccessFields_name"] | undefined,
	value?: GraphQLTypes["CategoryDocAccessFields_value"] | undefined,
	tags?: GraphQLTypes["CategoryDocAccessFields_tags"] | undefined,
	icon?: GraphQLTypes["CategoryDocAccessFields_icon"] | undefined,
	region?: GraphQLTypes["CategoryDocAccessFields_region"] | undefined,
	updatedAt?: GraphQLTypes["CategoryDocAccessFields_updatedAt"] | undefined,
	createdAt?: GraphQLTypes["CategoryDocAccessFields_createdAt"] | undefined
};
	["CategoryDocAccessFields_name"]: {
	__typename: "CategoryDocAccessFields_name",
	create?: GraphQLTypes["CategoryDocAccessFields_name_Create"] | undefined,
	read?: GraphQLTypes["CategoryDocAccessFields_name_Read"] | undefined,
	update?: GraphQLTypes["CategoryDocAccessFields_name_Update"] | undefined,
	delete?: GraphQLTypes["CategoryDocAccessFields_name_Delete"] | undefined
};
	["CategoryDocAccessFields_name_Create"]: {
	__typename: "CategoryDocAccessFields_name_Create",
	permission: boolean
};
	["CategoryDocAccessFields_name_Read"]: {
	__typename: "CategoryDocAccessFields_name_Read",
	permission: boolean
};
	["CategoryDocAccessFields_name_Update"]: {
	__typename: "CategoryDocAccessFields_name_Update",
	permission: boolean
};
	["CategoryDocAccessFields_name_Delete"]: {
	__typename: "CategoryDocAccessFields_name_Delete",
	permission: boolean
};
	["CategoryDocAccessFields_value"]: {
	__typename: "CategoryDocAccessFields_value",
	create?: GraphQLTypes["CategoryDocAccessFields_value_Create"] | undefined,
	read?: GraphQLTypes["CategoryDocAccessFields_value_Read"] | undefined,
	update?: GraphQLTypes["CategoryDocAccessFields_value_Update"] | undefined,
	delete?: GraphQLTypes["CategoryDocAccessFields_value_Delete"] | undefined
};
	["CategoryDocAccessFields_value_Create"]: {
	__typename: "CategoryDocAccessFields_value_Create",
	permission: boolean
};
	["CategoryDocAccessFields_value_Read"]: {
	__typename: "CategoryDocAccessFields_value_Read",
	permission: boolean
};
	["CategoryDocAccessFields_value_Update"]: {
	__typename: "CategoryDocAccessFields_value_Update",
	permission: boolean
};
	["CategoryDocAccessFields_value_Delete"]: {
	__typename: "CategoryDocAccessFields_value_Delete",
	permission: boolean
};
	["CategoryDocAccessFields_tags"]: {
	__typename: "CategoryDocAccessFields_tags",
	create?: GraphQLTypes["CategoryDocAccessFields_tags_Create"] | undefined,
	read?: GraphQLTypes["CategoryDocAccessFields_tags_Read"] | undefined,
	update?: GraphQLTypes["CategoryDocAccessFields_tags_Update"] | undefined,
	delete?: GraphQLTypes["CategoryDocAccessFields_tags_Delete"] | undefined
};
	["CategoryDocAccessFields_tags_Create"]: {
	__typename: "CategoryDocAccessFields_tags_Create",
	permission: boolean
};
	["CategoryDocAccessFields_tags_Read"]: {
	__typename: "CategoryDocAccessFields_tags_Read",
	permission: boolean
};
	["CategoryDocAccessFields_tags_Update"]: {
	__typename: "CategoryDocAccessFields_tags_Update",
	permission: boolean
};
	["CategoryDocAccessFields_tags_Delete"]: {
	__typename: "CategoryDocAccessFields_tags_Delete",
	permission: boolean
};
	["CategoryDocAccessFields_icon"]: {
	__typename: "CategoryDocAccessFields_icon",
	create?: GraphQLTypes["CategoryDocAccessFields_icon_Create"] | undefined,
	read?: GraphQLTypes["CategoryDocAccessFields_icon_Read"] | undefined,
	update?: GraphQLTypes["CategoryDocAccessFields_icon_Update"] | undefined,
	delete?: GraphQLTypes["CategoryDocAccessFields_icon_Delete"] | undefined
};
	["CategoryDocAccessFields_icon_Create"]: {
	__typename: "CategoryDocAccessFields_icon_Create",
	permission: boolean
};
	["CategoryDocAccessFields_icon_Read"]: {
	__typename: "CategoryDocAccessFields_icon_Read",
	permission: boolean
};
	["CategoryDocAccessFields_icon_Update"]: {
	__typename: "CategoryDocAccessFields_icon_Update",
	permission: boolean
};
	["CategoryDocAccessFields_icon_Delete"]: {
	__typename: "CategoryDocAccessFields_icon_Delete",
	permission: boolean
};
	["CategoryDocAccessFields_region"]: {
	__typename: "CategoryDocAccessFields_region",
	create?: GraphQLTypes["CategoryDocAccessFields_region_Create"] | undefined,
	read?: GraphQLTypes["CategoryDocAccessFields_region_Read"] | undefined,
	update?: GraphQLTypes["CategoryDocAccessFields_region_Update"] | undefined,
	delete?: GraphQLTypes["CategoryDocAccessFields_region_Delete"] | undefined
};
	["CategoryDocAccessFields_region_Create"]: {
	__typename: "CategoryDocAccessFields_region_Create",
	permission: boolean
};
	["CategoryDocAccessFields_region_Read"]: {
	__typename: "CategoryDocAccessFields_region_Read",
	permission: boolean
};
	["CategoryDocAccessFields_region_Update"]: {
	__typename: "CategoryDocAccessFields_region_Update",
	permission: boolean
};
	["CategoryDocAccessFields_region_Delete"]: {
	__typename: "CategoryDocAccessFields_region_Delete",
	permission: boolean
};
	["CategoryDocAccessFields_updatedAt"]: {
	__typename: "CategoryDocAccessFields_updatedAt",
	create?: GraphQLTypes["CategoryDocAccessFields_updatedAt_Create"] | undefined,
	read?: GraphQLTypes["CategoryDocAccessFields_updatedAt_Read"] | undefined,
	update?: GraphQLTypes["CategoryDocAccessFields_updatedAt_Update"] | undefined,
	delete?: GraphQLTypes["CategoryDocAccessFields_updatedAt_Delete"] | undefined
};
	["CategoryDocAccessFields_updatedAt_Create"]: {
	__typename: "CategoryDocAccessFields_updatedAt_Create",
	permission: boolean
};
	["CategoryDocAccessFields_updatedAt_Read"]: {
	__typename: "CategoryDocAccessFields_updatedAt_Read",
	permission: boolean
};
	["CategoryDocAccessFields_updatedAt_Update"]: {
	__typename: "CategoryDocAccessFields_updatedAt_Update",
	permission: boolean
};
	["CategoryDocAccessFields_updatedAt_Delete"]: {
	__typename: "CategoryDocAccessFields_updatedAt_Delete",
	permission: boolean
};
	["CategoryDocAccessFields_createdAt"]: {
	__typename: "CategoryDocAccessFields_createdAt",
	create?: GraphQLTypes["CategoryDocAccessFields_createdAt_Create"] | undefined,
	read?: GraphQLTypes["CategoryDocAccessFields_createdAt_Read"] | undefined,
	update?: GraphQLTypes["CategoryDocAccessFields_createdAt_Update"] | undefined,
	delete?: GraphQLTypes["CategoryDocAccessFields_createdAt_Delete"] | undefined
};
	["CategoryDocAccessFields_createdAt_Create"]: {
	__typename: "CategoryDocAccessFields_createdAt_Create",
	permission: boolean
};
	["CategoryDocAccessFields_createdAt_Read"]: {
	__typename: "CategoryDocAccessFields_createdAt_Read",
	permission: boolean
};
	["CategoryDocAccessFields_createdAt_Update"]: {
	__typename: "CategoryDocAccessFields_createdAt_Update",
	permission: boolean
};
	["CategoryDocAccessFields_createdAt_Delete"]: {
	__typename: "CategoryDocAccessFields_createdAt_Delete",
	permission: boolean
};
	["CategoryCreateDocAccess"]: {
	__typename: "CategoryCreateDocAccess",
	permission: boolean,
	where?: GraphQLTypes["JSONObject"] | undefined
};
	["CategoryReadDocAccess"]: {
	__typename: "CategoryReadDocAccess",
	permission: boolean,
	where?: GraphQLTypes["JSONObject"] | undefined
};
	["CategoryUpdateDocAccess"]: {
	__typename: "CategoryUpdateDocAccess",
	permission: boolean,
	where?: GraphQLTypes["JSONObject"] | undefined
};
	["CategoryDeleteDocAccess"]: {
	__typename: "CategoryDeleteDocAccess",
	permission: boolean,
	where?: GraphQLTypes["JSONObject"] | undefined
};
	["PrivateMedia"]: {
	__typename: "PrivateMedia",
	id?: string | undefined,
	alt?: string | undefined,
	updatedAt?: GraphQLTypes["DateTime"] | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	url?: string | undefined,
	filename?: string | undefined,
	mimeType?: string | undefined,
	filesize?: number | undefined,
	width?: number | undefined,
	height?: number | undefined,
	focalX?: number | undefined,
	focalY?: number | undefined,
	sizes?: GraphQLTypes["PrivateMedia_Sizes"] | undefined
};
	["PrivateMedia_Sizes"]: {
	__typename: "PrivateMedia_Sizes",
	thumbnail?: GraphQLTypes["PrivateMedia_Sizes_Thumbnail"] | undefined,
	portrait?: GraphQLTypes["PrivateMedia_Sizes_Portrait"] | undefined,
	hero?: GraphQLTypes["PrivateMedia_Sizes_Hero"] | undefined,
	thumbnail_jpeg?: GraphQLTypes["PrivateMedia_Sizes_Thumbnail_jpeg"] | undefined,
	portrait_jpeg?: GraphQLTypes["PrivateMedia_Sizes_Portrait_jpeg"] | undefined,
	hero_jpeg?: GraphQLTypes["PrivateMedia_Sizes_Hero_jpeg"] | undefined
};
	["PrivateMedia_Sizes_Thumbnail"]: {
	__typename: "PrivateMedia_Sizes_Thumbnail",
	url?: string | undefined,
	width?: number | undefined,
	height?: number | undefined,
	mimeType?: string | undefined,
	filesize?: number | undefined,
	filename?: string | undefined
};
	["PrivateMedia_Sizes_Portrait"]: {
	__typename: "PrivateMedia_Sizes_Portrait",
	url?: string | undefined,
	width?: number | undefined,
	height?: number | undefined,
	mimeType?: string | undefined,
	filesize?: number | undefined,
	filename?: string | undefined
};
	["PrivateMedia_Sizes_Hero"]: {
	__typename: "PrivateMedia_Sizes_Hero",
	url?: string | undefined,
	width?: number | undefined,
	height?: number | undefined,
	mimeType?: string | undefined,
	filesize?: number | undefined,
	filename?: string | undefined
};
	["PrivateMedia_Sizes_Thumbnail_jpeg"]: {
	__typename: "PrivateMedia_Sizes_Thumbnail_jpeg",
	url?: string | undefined,
	width?: number | undefined,
	height?: number | undefined,
	mimeType?: string | undefined,
	filesize?: number | undefined,
	filename?: string | undefined
};
	["PrivateMedia_Sizes_Portrait_jpeg"]: {
	__typename: "PrivateMedia_Sizes_Portrait_jpeg",
	url?: string | undefined,
	width?: number | undefined,
	height?: number | undefined,
	mimeType?: string | undefined,
	filesize?: number | undefined,
	filename?: string | undefined
};
	["PrivateMedia_Sizes_Hero_jpeg"]: {
	__typename: "PrivateMedia_Sizes_Hero_jpeg",
	url?: string | undefined,
	width?: number | undefined,
	height?: number | undefined,
	mimeType?: string | undefined,
	filesize?: number | undefined,
	filename?: string | undefined
};
	["PrivateMedias"]: {
	__typename: "PrivateMedias",
	docs?: Array<GraphQLTypes["PrivateMedia"] | undefined> | undefined,
	hasNextPage?: boolean | undefined,
	hasPrevPage?: boolean | undefined,
	limit?: number | undefined,
	nextPage?: number | undefined,
	offset?: number | undefined,
	page?: number | undefined,
	pagingCounter?: number | undefined,
	prevPage?: number | undefined,
	totalDocs?: number | undefined,
	totalPages?: number | undefined
};
	["PrivateMedia_where"]: {
		alt?: GraphQLTypes["PrivateMedia_alt_operator"] | undefined,
	updatedAt?: GraphQLTypes["PrivateMedia_updatedAt_operator"] | undefined,
	createdAt?: GraphQLTypes["PrivateMedia_createdAt_operator"] | undefined,
	url?: GraphQLTypes["PrivateMedia_url_operator"] | undefined,
	filename?: GraphQLTypes["PrivateMedia_filename_operator"] | undefined,
	mimeType?: GraphQLTypes["PrivateMedia_mimeType_operator"] | undefined,
	filesize?: GraphQLTypes["PrivateMedia_filesize_operator"] | undefined,
	width?: GraphQLTypes["PrivateMedia_width_operator"] | undefined,
	height?: GraphQLTypes["PrivateMedia_height_operator"] | undefined,
	focalX?: GraphQLTypes["PrivateMedia_focalX_operator"] | undefined,
	focalY?: GraphQLTypes["PrivateMedia_focalY_operator"] | undefined,
	sizes__thumbnail__url?: GraphQLTypes["PrivateMedia_sizes__thumbnail__url_operator"] | undefined,
	sizes__thumbnail__width?: GraphQLTypes["PrivateMedia_sizes__thumbnail__width_operator"] | undefined,
	sizes__thumbnail__height?: GraphQLTypes["PrivateMedia_sizes__thumbnail__height_operator"] | undefined,
	sizes__thumbnail__mimeType?: GraphQLTypes["PrivateMedia_sizes__thumbnail__mimeType_operator"] | undefined,
	sizes__thumbnail__filesize?: GraphQLTypes["PrivateMedia_sizes__thumbnail__filesize_operator"] | undefined,
	sizes__thumbnail__filename?: GraphQLTypes["PrivateMedia_sizes__thumbnail__filename_operator"] | undefined,
	sizes__portrait__url?: GraphQLTypes["PrivateMedia_sizes__portrait__url_operator"] | undefined,
	sizes__portrait__width?: GraphQLTypes["PrivateMedia_sizes__portrait__width_operator"] | undefined,
	sizes__portrait__height?: GraphQLTypes["PrivateMedia_sizes__portrait__height_operator"] | undefined,
	sizes__portrait__mimeType?: GraphQLTypes["PrivateMedia_sizes__portrait__mimeType_operator"] | undefined,
	sizes__portrait__filesize?: GraphQLTypes["PrivateMedia_sizes__portrait__filesize_operator"] | undefined,
	sizes__portrait__filename?: GraphQLTypes["PrivateMedia_sizes__portrait__filename_operator"] | undefined,
	sizes__hero__url?: GraphQLTypes["PrivateMedia_sizes__hero__url_operator"] | undefined,
	sizes__hero__width?: GraphQLTypes["PrivateMedia_sizes__hero__width_operator"] | undefined,
	sizes__hero__height?: GraphQLTypes["PrivateMedia_sizes__hero__height_operator"] | undefined,
	sizes__hero__mimeType?: GraphQLTypes["PrivateMedia_sizes__hero__mimeType_operator"] | undefined,
	sizes__hero__filesize?: GraphQLTypes["PrivateMedia_sizes__hero__filesize_operator"] | undefined,
	sizes__hero__filename?: GraphQLTypes["PrivateMedia_sizes__hero__filename_operator"] | undefined,
	sizes__thumbnail_jpeg__url?: GraphQLTypes["PrivateMedia_sizes__thumbnail_jpeg__url_operator"] | undefined,
	sizes__thumbnail_jpeg__width?: GraphQLTypes["PrivateMedia_sizes__thumbnail_jpeg__width_operator"] | undefined,
	sizes__thumbnail_jpeg__height?: GraphQLTypes["PrivateMedia_sizes__thumbnail_jpeg__height_operator"] | undefined,
	sizes__thumbnail_jpeg__mimeType?: GraphQLTypes["PrivateMedia_sizes__thumbnail_jpeg__mimeType_operator"] | undefined,
	sizes__thumbnail_jpeg__filesize?: GraphQLTypes["PrivateMedia_sizes__thumbnail_jpeg__filesize_operator"] | undefined,
	sizes__thumbnail_jpeg__filename?: GraphQLTypes["PrivateMedia_sizes__thumbnail_jpeg__filename_operator"] | undefined,
	sizes__portrait_jpeg__url?: GraphQLTypes["PrivateMedia_sizes__portrait_jpeg__url_operator"] | undefined,
	sizes__portrait_jpeg__width?: GraphQLTypes["PrivateMedia_sizes__portrait_jpeg__width_operator"] | undefined,
	sizes__portrait_jpeg__height?: GraphQLTypes["PrivateMedia_sizes__portrait_jpeg__height_operator"] | undefined,
	sizes__portrait_jpeg__mimeType?: GraphQLTypes["PrivateMedia_sizes__portrait_jpeg__mimeType_operator"] | undefined,
	sizes__portrait_jpeg__filesize?: GraphQLTypes["PrivateMedia_sizes__portrait_jpeg__filesize_operator"] | undefined,
	sizes__portrait_jpeg__filename?: GraphQLTypes["PrivateMedia_sizes__portrait_jpeg__filename_operator"] | undefined,
	sizes__hero_jpeg__url?: GraphQLTypes["PrivateMedia_sizes__hero_jpeg__url_operator"] | undefined,
	sizes__hero_jpeg__width?: GraphQLTypes["PrivateMedia_sizes__hero_jpeg__width_operator"] | undefined,
	sizes__hero_jpeg__height?: GraphQLTypes["PrivateMedia_sizes__hero_jpeg__height_operator"] | undefined,
	sizes__hero_jpeg__mimeType?: GraphQLTypes["PrivateMedia_sizes__hero_jpeg__mimeType_operator"] | undefined,
	sizes__hero_jpeg__filesize?: GraphQLTypes["PrivateMedia_sizes__hero_jpeg__filesize_operator"] | undefined,
	sizes__hero_jpeg__filename?: GraphQLTypes["PrivateMedia_sizes__hero_jpeg__filename_operator"] | undefined,
	id?: GraphQLTypes["PrivateMedia_id_operator"] | undefined,
	AND?: Array<GraphQLTypes["PrivateMedia_where_and"] | undefined> | undefined,
	OR?: Array<GraphQLTypes["PrivateMedia_where_or"] | undefined> | undefined
};
	["PrivateMedia_alt_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["PrivateMedia_updatedAt_operator"]: {
		equals?: GraphQLTypes["DateTime"] | undefined,
	not_equals?: GraphQLTypes["DateTime"] | undefined,
	greater_than_equal?: GraphQLTypes["DateTime"] | undefined,
	greater_than?: GraphQLTypes["DateTime"] | undefined,
	less_than_equal?: GraphQLTypes["DateTime"] | undefined,
	less_than?: GraphQLTypes["DateTime"] | undefined,
	like?: GraphQLTypes["DateTime"] | undefined,
	exists?: boolean | undefined
};
	["PrivateMedia_createdAt_operator"]: {
		equals?: GraphQLTypes["DateTime"] | undefined,
	not_equals?: GraphQLTypes["DateTime"] | undefined,
	greater_than_equal?: GraphQLTypes["DateTime"] | undefined,
	greater_than?: GraphQLTypes["DateTime"] | undefined,
	less_than_equal?: GraphQLTypes["DateTime"] | undefined,
	less_than?: GraphQLTypes["DateTime"] | undefined,
	like?: GraphQLTypes["DateTime"] | undefined,
	exists?: boolean | undefined
};
	["PrivateMedia_url_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["PrivateMedia_filename_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["PrivateMedia_mimeType_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["PrivateMedia_filesize_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["PrivateMedia_width_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["PrivateMedia_height_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["PrivateMedia_focalX_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["PrivateMedia_focalY_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["PrivateMedia_sizes__thumbnail__url_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["PrivateMedia_sizes__thumbnail__width_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["PrivateMedia_sizes__thumbnail__height_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["PrivateMedia_sizes__thumbnail__mimeType_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["PrivateMedia_sizes__thumbnail__filesize_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["PrivateMedia_sizes__thumbnail__filename_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["PrivateMedia_sizes__portrait__url_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["PrivateMedia_sizes__portrait__width_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["PrivateMedia_sizes__portrait__height_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["PrivateMedia_sizes__portrait__mimeType_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["PrivateMedia_sizes__portrait__filesize_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["PrivateMedia_sizes__portrait__filename_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["PrivateMedia_sizes__hero__url_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["PrivateMedia_sizes__hero__width_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["PrivateMedia_sizes__hero__height_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["PrivateMedia_sizes__hero__mimeType_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["PrivateMedia_sizes__hero__filesize_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["PrivateMedia_sizes__hero__filename_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["PrivateMedia_sizes__thumbnail_jpeg__url_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["PrivateMedia_sizes__thumbnail_jpeg__width_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["PrivateMedia_sizes__thumbnail_jpeg__height_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["PrivateMedia_sizes__thumbnail_jpeg__mimeType_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["PrivateMedia_sizes__thumbnail_jpeg__filesize_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["PrivateMedia_sizes__thumbnail_jpeg__filename_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["PrivateMedia_sizes__portrait_jpeg__url_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["PrivateMedia_sizes__portrait_jpeg__width_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["PrivateMedia_sizes__portrait_jpeg__height_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["PrivateMedia_sizes__portrait_jpeg__mimeType_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["PrivateMedia_sizes__portrait_jpeg__filesize_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["PrivateMedia_sizes__portrait_jpeg__filename_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["PrivateMedia_sizes__hero_jpeg__url_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["PrivateMedia_sizes__hero_jpeg__width_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["PrivateMedia_sizes__hero_jpeg__height_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["PrivateMedia_sizes__hero_jpeg__mimeType_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["PrivateMedia_sizes__hero_jpeg__filesize_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["PrivateMedia_sizes__hero_jpeg__filename_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["PrivateMedia_id_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["PrivateMedia_where_and"]: {
		alt?: GraphQLTypes["PrivateMedia_alt_operator"] | undefined,
	updatedAt?: GraphQLTypes["PrivateMedia_updatedAt_operator"] | undefined,
	createdAt?: GraphQLTypes["PrivateMedia_createdAt_operator"] | undefined,
	url?: GraphQLTypes["PrivateMedia_url_operator"] | undefined,
	filename?: GraphQLTypes["PrivateMedia_filename_operator"] | undefined,
	mimeType?: GraphQLTypes["PrivateMedia_mimeType_operator"] | undefined,
	filesize?: GraphQLTypes["PrivateMedia_filesize_operator"] | undefined,
	width?: GraphQLTypes["PrivateMedia_width_operator"] | undefined,
	height?: GraphQLTypes["PrivateMedia_height_operator"] | undefined,
	focalX?: GraphQLTypes["PrivateMedia_focalX_operator"] | undefined,
	focalY?: GraphQLTypes["PrivateMedia_focalY_operator"] | undefined,
	sizes__thumbnail__url?: GraphQLTypes["PrivateMedia_sizes__thumbnail__url_operator"] | undefined,
	sizes__thumbnail__width?: GraphQLTypes["PrivateMedia_sizes__thumbnail__width_operator"] | undefined,
	sizes__thumbnail__height?: GraphQLTypes["PrivateMedia_sizes__thumbnail__height_operator"] | undefined,
	sizes__thumbnail__mimeType?: GraphQLTypes["PrivateMedia_sizes__thumbnail__mimeType_operator"] | undefined,
	sizes__thumbnail__filesize?: GraphQLTypes["PrivateMedia_sizes__thumbnail__filesize_operator"] | undefined,
	sizes__thumbnail__filename?: GraphQLTypes["PrivateMedia_sizes__thumbnail__filename_operator"] | undefined,
	sizes__portrait__url?: GraphQLTypes["PrivateMedia_sizes__portrait__url_operator"] | undefined,
	sizes__portrait__width?: GraphQLTypes["PrivateMedia_sizes__portrait__width_operator"] | undefined,
	sizes__portrait__height?: GraphQLTypes["PrivateMedia_sizes__portrait__height_operator"] | undefined,
	sizes__portrait__mimeType?: GraphQLTypes["PrivateMedia_sizes__portrait__mimeType_operator"] | undefined,
	sizes__portrait__filesize?: GraphQLTypes["PrivateMedia_sizes__portrait__filesize_operator"] | undefined,
	sizes__portrait__filename?: GraphQLTypes["PrivateMedia_sizes__portrait__filename_operator"] | undefined,
	sizes__hero__url?: GraphQLTypes["PrivateMedia_sizes__hero__url_operator"] | undefined,
	sizes__hero__width?: GraphQLTypes["PrivateMedia_sizes__hero__width_operator"] | undefined,
	sizes__hero__height?: GraphQLTypes["PrivateMedia_sizes__hero__height_operator"] | undefined,
	sizes__hero__mimeType?: GraphQLTypes["PrivateMedia_sizes__hero__mimeType_operator"] | undefined,
	sizes__hero__filesize?: GraphQLTypes["PrivateMedia_sizes__hero__filesize_operator"] | undefined,
	sizes__hero__filename?: GraphQLTypes["PrivateMedia_sizes__hero__filename_operator"] | undefined,
	sizes__thumbnail_jpeg__url?: GraphQLTypes["PrivateMedia_sizes__thumbnail_jpeg__url_operator"] | undefined,
	sizes__thumbnail_jpeg__width?: GraphQLTypes["PrivateMedia_sizes__thumbnail_jpeg__width_operator"] | undefined,
	sizes__thumbnail_jpeg__height?: GraphQLTypes["PrivateMedia_sizes__thumbnail_jpeg__height_operator"] | undefined,
	sizes__thumbnail_jpeg__mimeType?: GraphQLTypes["PrivateMedia_sizes__thumbnail_jpeg__mimeType_operator"] | undefined,
	sizes__thumbnail_jpeg__filesize?: GraphQLTypes["PrivateMedia_sizes__thumbnail_jpeg__filesize_operator"] | undefined,
	sizes__thumbnail_jpeg__filename?: GraphQLTypes["PrivateMedia_sizes__thumbnail_jpeg__filename_operator"] | undefined,
	sizes__portrait_jpeg__url?: GraphQLTypes["PrivateMedia_sizes__portrait_jpeg__url_operator"] | undefined,
	sizes__portrait_jpeg__width?: GraphQLTypes["PrivateMedia_sizes__portrait_jpeg__width_operator"] | undefined,
	sizes__portrait_jpeg__height?: GraphQLTypes["PrivateMedia_sizes__portrait_jpeg__height_operator"] | undefined,
	sizes__portrait_jpeg__mimeType?: GraphQLTypes["PrivateMedia_sizes__portrait_jpeg__mimeType_operator"] | undefined,
	sizes__portrait_jpeg__filesize?: GraphQLTypes["PrivateMedia_sizes__portrait_jpeg__filesize_operator"] | undefined,
	sizes__portrait_jpeg__filename?: GraphQLTypes["PrivateMedia_sizes__portrait_jpeg__filename_operator"] | undefined,
	sizes__hero_jpeg__url?: GraphQLTypes["PrivateMedia_sizes__hero_jpeg__url_operator"] | undefined,
	sizes__hero_jpeg__width?: GraphQLTypes["PrivateMedia_sizes__hero_jpeg__width_operator"] | undefined,
	sizes__hero_jpeg__height?: GraphQLTypes["PrivateMedia_sizes__hero_jpeg__height_operator"] | undefined,
	sizes__hero_jpeg__mimeType?: GraphQLTypes["PrivateMedia_sizes__hero_jpeg__mimeType_operator"] | undefined,
	sizes__hero_jpeg__filesize?: GraphQLTypes["PrivateMedia_sizes__hero_jpeg__filesize_operator"] | undefined,
	sizes__hero_jpeg__filename?: GraphQLTypes["PrivateMedia_sizes__hero_jpeg__filename_operator"] | undefined,
	id?: GraphQLTypes["PrivateMedia_id_operator"] | undefined,
	AND?: Array<GraphQLTypes["PrivateMedia_where_and"] | undefined> | undefined,
	OR?: Array<GraphQLTypes["PrivateMedia_where_or"] | undefined> | undefined
};
	["PrivateMedia_where_or"]: {
		alt?: GraphQLTypes["PrivateMedia_alt_operator"] | undefined,
	updatedAt?: GraphQLTypes["PrivateMedia_updatedAt_operator"] | undefined,
	createdAt?: GraphQLTypes["PrivateMedia_createdAt_operator"] | undefined,
	url?: GraphQLTypes["PrivateMedia_url_operator"] | undefined,
	filename?: GraphQLTypes["PrivateMedia_filename_operator"] | undefined,
	mimeType?: GraphQLTypes["PrivateMedia_mimeType_operator"] | undefined,
	filesize?: GraphQLTypes["PrivateMedia_filesize_operator"] | undefined,
	width?: GraphQLTypes["PrivateMedia_width_operator"] | undefined,
	height?: GraphQLTypes["PrivateMedia_height_operator"] | undefined,
	focalX?: GraphQLTypes["PrivateMedia_focalX_operator"] | undefined,
	focalY?: GraphQLTypes["PrivateMedia_focalY_operator"] | undefined,
	sizes__thumbnail__url?: GraphQLTypes["PrivateMedia_sizes__thumbnail__url_operator"] | undefined,
	sizes__thumbnail__width?: GraphQLTypes["PrivateMedia_sizes__thumbnail__width_operator"] | undefined,
	sizes__thumbnail__height?: GraphQLTypes["PrivateMedia_sizes__thumbnail__height_operator"] | undefined,
	sizes__thumbnail__mimeType?: GraphQLTypes["PrivateMedia_sizes__thumbnail__mimeType_operator"] | undefined,
	sizes__thumbnail__filesize?: GraphQLTypes["PrivateMedia_sizes__thumbnail__filesize_operator"] | undefined,
	sizes__thumbnail__filename?: GraphQLTypes["PrivateMedia_sizes__thumbnail__filename_operator"] | undefined,
	sizes__portrait__url?: GraphQLTypes["PrivateMedia_sizes__portrait__url_operator"] | undefined,
	sizes__portrait__width?: GraphQLTypes["PrivateMedia_sizes__portrait__width_operator"] | undefined,
	sizes__portrait__height?: GraphQLTypes["PrivateMedia_sizes__portrait__height_operator"] | undefined,
	sizes__portrait__mimeType?: GraphQLTypes["PrivateMedia_sizes__portrait__mimeType_operator"] | undefined,
	sizes__portrait__filesize?: GraphQLTypes["PrivateMedia_sizes__portrait__filesize_operator"] | undefined,
	sizes__portrait__filename?: GraphQLTypes["PrivateMedia_sizes__portrait__filename_operator"] | undefined,
	sizes__hero__url?: GraphQLTypes["PrivateMedia_sizes__hero__url_operator"] | undefined,
	sizes__hero__width?: GraphQLTypes["PrivateMedia_sizes__hero__width_operator"] | undefined,
	sizes__hero__height?: GraphQLTypes["PrivateMedia_sizes__hero__height_operator"] | undefined,
	sizes__hero__mimeType?: GraphQLTypes["PrivateMedia_sizes__hero__mimeType_operator"] | undefined,
	sizes__hero__filesize?: GraphQLTypes["PrivateMedia_sizes__hero__filesize_operator"] | undefined,
	sizes__hero__filename?: GraphQLTypes["PrivateMedia_sizes__hero__filename_operator"] | undefined,
	sizes__thumbnail_jpeg__url?: GraphQLTypes["PrivateMedia_sizes__thumbnail_jpeg__url_operator"] | undefined,
	sizes__thumbnail_jpeg__width?: GraphQLTypes["PrivateMedia_sizes__thumbnail_jpeg__width_operator"] | undefined,
	sizes__thumbnail_jpeg__height?: GraphQLTypes["PrivateMedia_sizes__thumbnail_jpeg__height_operator"] | undefined,
	sizes__thumbnail_jpeg__mimeType?: GraphQLTypes["PrivateMedia_sizes__thumbnail_jpeg__mimeType_operator"] | undefined,
	sizes__thumbnail_jpeg__filesize?: GraphQLTypes["PrivateMedia_sizes__thumbnail_jpeg__filesize_operator"] | undefined,
	sizes__thumbnail_jpeg__filename?: GraphQLTypes["PrivateMedia_sizes__thumbnail_jpeg__filename_operator"] | undefined,
	sizes__portrait_jpeg__url?: GraphQLTypes["PrivateMedia_sizes__portrait_jpeg__url_operator"] | undefined,
	sizes__portrait_jpeg__width?: GraphQLTypes["PrivateMedia_sizes__portrait_jpeg__width_operator"] | undefined,
	sizes__portrait_jpeg__height?: GraphQLTypes["PrivateMedia_sizes__portrait_jpeg__height_operator"] | undefined,
	sizes__portrait_jpeg__mimeType?: GraphQLTypes["PrivateMedia_sizes__portrait_jpeg__mimeType_operator"] | undefined,
	sizes__portrait_jpeg__filesize?: GraphQLTypes["PrivateMedia_sizes__portrait_jpeg__filesize_operator"] | undefined,
	sizes__portrait_jpeg__filename?: GraphQLTypes["PrivateMedia_sizes__portrait_jpeg__filename_operator"] | undefined,
	sizes__hero_jpeg__url?: GraphQLTypes["PrivateMedia_sizes__hero_jpeg__url_operator"] | undefined,
	sizes__hero_jpeg__width?: GraphQLTypes["PrivateMedia_sizes__hero_jpeg__width_operator"] | undefined,
	sizes__hero_jpeg__height?: GraphQLTypes["PrivateMedia_sizes__hero_jpeg__height_operator"] | undefined,
	sizes__hero_jpeg__mimeType?: GraphQLTypes["PrivateMedia_sizes__hero_jpeg__mimeType_operator"] | undefined,
	sizes__hero_jpeg__filesize?: GraphQLTypes["PrivateMedia_sizes__hero_jpeg__filesize_operator"] | undefined,
	sizes__hero_jpeg__filename?: GraphQLTypes["PrivateMedia_sizes__hero_jpeg__filename_operator"] | undefined,
	id?: GraphQLTypes["PrivateMedia_id_operator"] | undefined,
	AND?: Array<GraphQLTypes["PrivateMedia_where_and"] | undefined> | undefined,
	OR?: Array<GraphQLTypes["PrivateMedia_where_or"] | undefined> | undefined
};
	["countPrivateMedias"]: {
	__typename: "countPrivateMedias",
	totalDocs?: number | undefined
};
	["private_mediaDocAccess"]: {
	__typename: "private_mediaDocAccess",
	fields?: GraphQLTypes["PrivateMediaDocAccessFields"] | undefined,
	create?: GraphQLTypes["PrivateMediaCreateDocAccess"] | undefined,
	read?: GraphQLTypes["PrivateMediaReadDocAccess"] | undefined,
	update?: GraphQLTypes["PrivateMediaUpdateDocAccess"] | undefined,
	delete?: GraphQLTypes["PrivateMediaDeleteDocAccess"] | undefined
};
	["PrivateMediaDocAccessFields"]: {
	__typename: "PrivateMediaDocAccessFields",
	alt?: GraphQLTypes["PrivateMediaDocAccessFields_alt"] | undefined,
	updatedAt?: GraphQLTypes["PrivateMediaDocAccessFields_updatedAt"] | undefined,
	createdAt?: GraphQLTypes["PrivateMediaDocAccessFields_createdAt"] | undefined,
	url?: GraphQLTypes["PrivateMediaDocAccessFields_url"] | undefined,
	filename?: GraphQLTypes["PrivateMediaDocAccessFields_filename"] | undefined,
	mimeType?: GraphQLTypes["PrivateMediaDocAccessFields_mimeType"] | undefined,
	filesize?: GraphQLTypes["PrivateMediaDocAccessFields_filesize"] | undefined,
	width?: GraphQLTypes["PrivateMediaDocAccessFields_width"] | undefined,
	height?: GraphQLTypes["PrivateMediaDocAccessFields_height"] | undefined,
	focalX?: GraphQLTypes["PrivateMediaDocAccessFields_focalX"] | undefined,
	focalY?: GraphQLTypes["PrivateMediaDocAccessFields_focalY"] | undefined,
	sizes?: GraphQLTypes["PrivateMediaDocAccessFields_sizes"] | undefined
};
	["PrivateMediaDocAccessFields_alt"]: {
	__typename: "PrivateMediaDocAccessFields_alt",
	create?: GraphQLTypes["PrivateMediaDocAccessFields_alt_Create"] | undefined,
	read?: GraphQLTypes["PrivateMediaDocAccessFields_alt_Read"] | undefined,
	update?: GraphQLTypes["PrivateMediaDocAccessFields_alt_Update"] | undefined,
	delete?: GraphQLTypes["PrivateMediaDocAccessFields_alt_Delete"] | undefined
};
	["PrivateMediaDocAccessFields_alt_Create"]: {
	__typename: "PrivateMediaDocAccessFields_alt_Create",
	permission: boolean
};
	["PrivateMediaDocAccessFields_alt_Read"]: {
	__typename: "PrivateMediaDocAccessFields_alt_Read",
	permission: boolean
};
	["PrivateMediaDocAccessFields_alt_Update"]: {
	__typename: "PrivateMediaDocAccessFields_alt_Update",
	permission: boolean
};
	["PrivateMediaDocAccessFields_alt_Delete"]: {
	__typename: "PrivateMediaDocAccessFields_alt_Delete",
	permission: boolean
};
	["PrivateMediaDocAccessFields_updatedAt"]: {
	__typename: "PrivateMediaDocAccessFields_updatedAt",
	create?: GraphQLTypes["PrivateMediaDocAccessFields_updatedAt_Create"] | undefined,
	read?: GraphQLTypes["PrivateMediaDocAccessFields_updatedAt_Read"] | undefined,
	update?: GraphQLTypes["PrivateMediaDocAccessFields_updatedAt_Update"] | undefined,
	delete?: GraphQLTypes["PrivateMediaDocAccessFields_updatedAt_Delete"] | undefined
};
	["PrivateMediaDocAccessFields_updatedAt_Create"]: {
	__typename: "PrivateMediaDocAccessFields_updatedAt_Create",
	permission: boolean
};
	["PrivateMediaDocAccessFields_updatedAt_Read"]: {
	__typename: "PrivateMediaDocAccessFields_updatedAt_Read",
	permission: boolean
};
	["PrivateMediaDocAccessFields_updatedAt_Update"]: {
	__typename: "PrivateMediaDocAccessFields_updatedAt_Update",
	permission: boolean
};
	["PrivateMediaDocAccessFields_updatedAt_Delete"]: {
	__typename: "PrivateMediaDocAccessFields_updatedAt_Delete",
	permission: boolean
};
	["PrivateMediaDocAccessFields_createdAt"]: {
	__typename: "PrivateMediaDocAccessFields_createdAt",
	create?: GraphQLTypes["PrivateMediaDocAccessFields_createdAt_Create"] | undefined,
	read?: GraphQLTypes["PrivateMediaDocAccessFields_createdAt_Read"] | undefined,
	update?: GraphQLTypes["PrivateMediaDocAccessFields_createdAt_Update"] | undefined,
	delete?: GraphQLTypes["PrivateMediaDocAccessFields_createdAt_Delete"] | undefined
};
	["PrivateMediaDocAccessFields_createdAt_Create"]: {
	__typename: "PrivateMediaDocAccessFields_createdAt_Create",
	permission: boolean
};
	["PrivateMediaDocAccessFields_createdAt_Read"]: {
	__typename: "PrivateMediaDocAccessFields_createdAt_Read",
	permission: boolean
};
	["PrivateMediaDocAccessFields_createdAt_Update"]: {
	__typename: "PrivateMediaDocAccessFields_createdAt_Update",
	permission: boolean
};
	["PrivateMediaDocAccessFields_createdAt_Delete"]: {
	__typename: "PrivateMediaDocAccessFields_createdAt_Delete",
	permission: boolean
};
	["PrivateMediaDocAccessFields_url"]: {
	__typename: "PrivateMediaDocAccessFields_url",
	create?: GraphQLTypes["PrivateMediaDocAccessFields_url_Create"] | undefined,
	read?: GraphQLTypes["PrivateMediaDocAccessFields_url_Read"] | undefined,
	update?: GraphQLTypes["PrivateMediaDocAccessFields_url_Update"] | undefined,
	delete?: GraphQLTypes["PrivateMediaDocAccessFields_url_Delete"] | undefined
};
	["PrivateMediaDocAccessFields_url_Create"]: {
	__typename: "PrivateMediaDocAccessFields_url_Create",
	permission: boolean
};
	["PrivateMediaDocAccessFields_url_Read"]: {
	__typename: "PrivateMediaDocAccessFields_url_Read",
	permission: boolean
};
	["PrivateMediaDocAccessFields_url_Update"]: {
	__typename: "PrivateMediaDocAccessFields_url_Update",
	permission: boolean
};
	["PrivateMediaDocAccessFields_url_Delete"]: {
	__typename: "PrivateMediaDocAccessFields_url_Delete",
	permission: boolean
};
	["PrivateMediaDocAccessFields_filename"]: {
	__typename: "PrivateMediaDocAccessFields_filename",
	create?: GraphQLTypes["PrivateMediaDocAccessFields_filename_Create"] | undefined,
	read?: GraphQLTypes["PrivateMediaDocAccessFields_filename_Read"] | undefined,
	update?: GraphQLTypes["PrivateMediaDocAccessFields_filename_Update"] | undefined,
	delete?: GraphQLTypes["PrivateMediaDocAccessFields_filename_Delete"] | undefined
};
	["PrivateMediaDocAccessFields_filename_Create"]: {
	__typename: "PrivateMediaDocAccessFields_filename_Create",
	permission: boolean
};
	["PrivateMediaDocAccessFields_filename_Read"]: {
	__typename: "PrivateMediaDocAccessFields_filename_Read",
	permission: boolean
};
	["PrivateMediaDocAccessFields_filename_Update"]: {
	__typename: "PrivateMediaDocAccessFields_filename_Update",
	permission: boolean
};
	["PrivateMediaDocAccessFields_filename_Delete"]: {
	__typename: "PrivateMediaDocAccessFields_filename_Delete",
	permission: boolean
};
	["PrivateMediaDocAccessFields_mimeType"]: {
	__typename: "PrivateMediaDocAccessFields_mimeType",
	create?: GraphQLTypes["PrivateMediaDocAccessFields_mimeType_Create"] | undefined,
	read?: GraphQLTypes["PrivateMediaDocAccessFields_mimeType_Read"] | undefined,
	update?: GraphQLTypes["PrivateMediaDocAccessFields_mimeType_Update"] | undefined,
	delete?: GraphQLTypes["PrivateMediaDocAccessFields_mimeType_Delete"] | undefined
};
	["PrivateMediaDocAccessFields_mimeType_Create"]: {
	__typename: "PrivateMediaDocAccessFields_mimeType_Create",
	permission: boolean
};
	["PrivateMediaDocAccessFields_mimeType_Read"]: {
	__typename: "PrivateMediaDocAccessFields_mimeType_Read",
	permission: boolean
};
	["PrivateMediaDocAccessFields_mimeType_Update"]: {
	__typename: "PrivateMediaDocAccessFields_mimeType_Update",
	permission: boolean
};
	["PrivateMediaDocAccessFields_mimeType_Delete"]: {
	__typename: "PrivateMediaDocAccessFields_mimeType_Delete",
	permission: boolean
};
	["PrivateMediaDocAccessFields_filesize"]: {
	__typename: "PrivateMediaDocAccessFields_filesize",
	create?: GraphQLTypes["PrivateMediaDocAccessFields_filesize_Create"] | undefined,
	read?: GraphQLTypes["PrivateMediaDocAccessFields_filesize_Read"] | undefined,
	update?: GraphQLTypes["PrivateMediaDocAccessFields_filesize_Update"] | undefined,
	delete?: GraphQLTypes["PrivateMediaDocAccessFields_filesize_Delete"] | undefined
};
	["PrivateMediaDocAccessFields_filesize_Create"]: {
	__typename: "PrivateMediaDocAccessFields_filesize_Create",
	permission: boolean
};
	["PrivateMediaDocAccessFields_filesize_Read"]: {
	__typename: "PrivateMediaDocAccessFields_filesize_Read",
	permission: boolean
};
	["PrivateMediaDocAccessFields_filesize_Update"]: {
	__typename: "PrivateMediaDocAccessFields_filesize_Update",
	permission: boolean
};
	["PrivateMediaDocAccessFields_filesize_Delete"]: {
	__typename: "PrivateMediaDocAccessFields_filesize_Delete",
	permission: boolean
};
	["PrivateMediaDocAccessFields_width"]: {
	__typename: "PrivateMediaDocAccessFields_width",
	create?: GraphQLTypes["PrivateMediaDocAccessFields_width_Create"] | undefined,
	read?: GraphQLTypes["PrivateMediaDocAccessFields_width_Read"] | undefined,
	update?: GraphQLTypes["PrivateMediaDocAccessFields_width_Update"] | undefined,
	delete?: GraphQLTypes["PrivateMediaDocAccessFields_width_Delete"] | undefined
};
	["PrivateMediaDocAccessFields_width_Create"]: {
	__typename: "PrivateMediaDocAccessFields_width_Create",
	permission: boolean
};
	["PrivateMediaDocAccessFields_width_Read"]: {
	__typename: "PrivateMediaDocAccessFields_width_Read",
	permission: boolean
};
	["PrivateMediaDocAccessFields_width_Update"]: {
	__typename: "PrivateMediaDocAccessFields_width_Update",
	permission: boolean
};
	["PrivateMediaDocAccessFields_width_Delete"]: {
	__typename: "PrivateMediaDocAccessFields_width_Delete",
	permission: boolean
};
	["PrivateMediaDocAccessFields_height"]: {
	__typename: "PrivateMediaDocAccessFields_height",
	create?: GraphQLTypes["PrivateMediaDocAccessFields_height_Create"] | undefined,
	read?: GraphQLTypes["PrivateMediaDocAccessFields_height_Read"] | undefined,
	update?: GraphQLTypes["PrivateMediaDocAccessFields_height_Update"] | undefined,
	delete?: GraphQLTypes["PrivateMediaDocAccessFields_height_Delete"] | undefined
};
	["PrivateMediaDocAccessFields_height_Create"]: {
	__typename: "PrivateMediaDocAccessFields_height_Create",
	permission: boolean
};
	["PrivateMediaDocAccessFields_height_Read"]: {
	__typename: "PrivateMediaDocAccessFields_height_Read",
	permission: boolean
};
	["PrivateMediaDocAccessFields_height_Update"]: {
	__typename: "PrivateMediaDocAccessFields_height_Update",
	permission: boolean
};
	["PrivateMediaDocAccessFields_height_Delete"]: {
	__typename: "PrivateMediaDocAccessFields_height_Delete",
	permission: boolean
};
	["PrivateMediaDocAccessFields_focalX"]: {
	__typename: "PrivateMediaDocAccessFields_focalX",
	create?: GraphQLTypes["PrivateMediaDocAccessFields_focalX_Create"] | undefined,
	read?: GraphQLTypes["PrivateMediaDocAccessFields_focalX_Read"] | undefined,
	update?: GraphQLTypes["PrivateMediaDocAccessFields_focalX_Update"] | undefined,
	delete?: GraphQLTypes["PrivateMediaDocAccessFields_focalX_Delete"] | undefined
};
	["PrivateMediaDocAccessFields_focalX_Create"]: {
	__typename: "PrivateMediaDocAccessFields_focalX_Create",
	permission: boolean
};
	["PrivateMediaDocAccessFields_focalX_Read"]: {
	__typename: "PrivateMediaDocAccessFields_focalX_Read",
	permission: boolean
};
	["PrivateMediaDocAccessFields_focalX_Update"]: {
	__typename: "PrivateMediaDocAccessFields_focalX_Update",
	permission: boolean
};
	["PrivateMediaDocAccessFields_focalX_Delete"]: {
	__typename: "PrivateMediaDocAccessFields_focalX_Delete",
	permission: boolean
};
	["PrivateMediaDocAccessFields_focalY"]: {
	__typename: "PrivateMediaDocAccessFields_focalY",
	create?: GraphQLTypes["PrivateMediaDocAccessFields_focalY_Create"] | undefined,
	read?: GraphQLTypes["PrivateMediaDocAccessFields_focalY_Read"] | undefined,
	update?: GraphQLTypes["PrivateMediaDocAccessFields_focalY_Update"] | undefined,
	delete?: GraphQLTypes["PrivateMediaDocAccessFields_focalY_Delete"] | undefined
};
	["PrivateMediaDocAccessFields_focalY_Create"]: {
	__typename: "PrivateMediaDocAccessFields_focalY_Create",
	permission: boolean
};
	["PrivateMediaDocAccessFields_focalY_Read"]: {
	__typename: "PrivateMediaDocAccessFields_focalY_Read",
	permission: boolean
};
	["PrivateMediaDocAccessFields_focalY_Update"]: {
	__typename: "PrivateMediaDocAccessFields_focalY_Update",
	permission: boolean
};
	["PrivateMediaDocAccessFields_focalY_Delete"]: {
	__typename: "PrivateMediaDocAccessFields_focalY_Delete",
	permission: boolean
};
	["PrivateMediaDocAccessFields_sizes"]: {
	__typename: "PrivateMediaDocAccessFields_sizes",
	create?: GraphQLTypes["PrivateMediaDocAccessFields_sizes_Create"] | undefined,
	read?: GraphQLTypes["PrivateMediaDocAccessFields_sizes_Read"] | undefined,
	update?: GraphQLTypes["PrivateMediaDocAccessFields_sizes_Update"] | undefined,
	delete?: GraphQLTypes["PrivateMediaDocAccessFields_sizes_Delete"] | undefined,
	fields?: GraphQLTypes["PrivateMediaDocAccessFields_sizes_Fields"] | undefined
};
	["PrivateMediaDocAccessFields_sizes_Create"]: {
	__typename: "PrivateMediaDocAccessFields_sizes_Create",
	permission: boolean
};
	["PrivateMediaDocAccessFields_sizes_Read"]: {
	__typename: "PrivateMediaDocAccessFields_sizes_Read",
	permission: boolean
};
	["PrivateMediaDocAccessFields_sizes_Update"]: {
	__typename: "PrivateMediaDocAccessFields_sizes_Update",
	permission: boolean
};
	["PrivateMediaDocAccessFields_sizes_Delete"]: {
	__typename: "PrivateMediaDocAccessFields_sizes_Delete",
	permission: boolean
};
	["PrivateMediaDocAccessFields_sizes_Fields"]: {
	__typename: "PrivateMediaDocAccessFields_sizes_Fields",
	thumbnail?: GraphQLTypes["PrivateMediaDocAccessFields_sizes_thumbnail"] | undefined,
	portrait?: GraphQLTypes["PrivateMediaDocAccessFields_sizes_portrait"] | undefined,
	hero?: GraphQLTypes["PrivateMediaDocAccessFields_sizes_hero"] | undefined,
	thumbnail_jpeg?: GraphQLTypes["PrivateMediaDocAccessFields_sizes_thumbnail_jpeg"] | undefined,
	portrait_jpeg?: GraphQLTypes["PrivateMediaDocAccessFields_sizes_portrait_jpeg"] | undefined,
	hero_jpeg?: GraphQLTypes["PrivateMediaDocAccessFields_sizes_hero_jpeg"] | undefined
};
	["PrivateMediaDocAccessFields_sizes_thumbnail"]: {
	__typename: "PrivateMediaDocAccessFields_sizes_thumbnail",
	create?: GraphQLTypes["PrivateMediaDocAccessFields_sizes_thumbnail_Create"] | undefined,
	read?: GraphQLTypes["PrivateMediaDocAccessFields_sizes_thumbnail_Read"] | undefined,
	update?: GraphQLTypes["PrivateMediaDocAccessFields_sizes_thumbnail_Update"] | undefined,
	delete?: GraphQLTypes["PrivateMediaDocAccessFields_sizes_thumbnail_Delete"] | undefined,
	fields?: GraphQLTypes["PrivateMediaDocAccessFields_sizes_thumbnail_Fields"] | undefined
};
	["PrivateMediaDocAccessFields_sizes_thumbnail_Create"]: {
	__typename: "PrivateMediaDocAccessFields_sizes_thumbnail_Create",
	permission: boolean
};
	["PrivateMediaDocAccessFields_sizes_thumbnail_Read"]: {
	__typename: "PrivateMediaDocAccessFields_sizes_thumbnail_Read",
	permission: boolean
};
	["PrivateMediaDocAccessFields_sizes_thumbnail_Update"]: {
	__typename: "PrivateMediaDocAccessFields_sizes_thumbnail_Update",
	permission: boolean
};
	["PrivateMediaDocAccessFields_sizes_thumbnail_Delete"]: {
	__typename: "PrivateMediaDocAccessFields_sizes_thumbnail_Delete",
	permission: boolean
};
	["PrivateMediaDocAccessFields_sizes_thumbnail_Fields"]: {
	__typename: "PrivateMediaDocAccessFields_sizes_thumbnail_Fields",
	url?: GraphQLTypes["PrivateMediaDocAccessFields_sizes_thumbnail_url"] | undefined,
	width?: GraphQLTypes["PrivateMediaDocAccessFields_sizes_thumbnail_width"] | undefined,
	height?: GraphQLTypes["PrivateMediaDocAccessFields_sizes_thumbnail_height"] | undefined,
	mimeType?: GraphQLTypes["PrivateMediaDocAccessFields_sizes_thumbnail_mimeType"] | undefined,
	filesize?: GraphQLTypes["PrivateMediaDocAccessFields_sizes_thumbnail_filesize"] | undefined,
	filename?: GraphQLTypes["PrivateMediaDocAccessFields_sizes_thumbnail_filename"] | undefined
};
	["PrivateMediaDocAccessFields_sizes_thumbnail_url"]: {
	__typename: "PrivateMediaDocAccessFields_sizes_thumbnail_url",
	create?: GraphQLTypes["PrivateMediaDocAccessFields_sizes_thumbnail_url_Create"] | undefined,
	read?: GraphQLTypes["PrivateMediaDocAccessFields_sizes_thumbnail_url_Read"] | undefined,
	update?: GraphQLTypes["PrivateMediaDocAccessFields_sizes_thumbnail_url_Update"] | undefined,
	delete?: GraphQLTypes["PrivateMediaDocAccessFields_sizes_thumbnail_url_Delete"] | undefined
};
	["PrivateMediaDocAccessFields_sizes_thumbnail_url_Create"]: {
	__typename: "PrivateMediaDocAccessFields_sizes_thumbnail_url_Create",
	permission: boolean
};
	["PrivateMediaDocAccessFields_sizes_thumbnail_url_Read"]: {
	__typename: "PrivateMediaDocAccessFields_sizes_thumbnail_url_Read",
	permission: boolean
};
	["PrivateMediaDocAccessFields_sizes_thumbnail_url_Update"]: {
	__typename: "PrivateMediaDocAccessFields_sizes_thumbnail_url_Update",
	permission: boolean
};
	["PrivateMediaDocAccessFields_sizes_thumbnail_url_Delete"]: {
	__typename: "PrivateMediaDocAccessFields_sizes_thumbnail_url_Delete",
	permission: boolean
};
	["PrivateMediaDocAccessFields_sizes_thumbnail_width"]: {
	__typename: "PrivateMediaDocAccessFields_sizes_thumbnail_width",
	create?: GraphQLTypes["PrivateMediaDocAccessFields_sizes_thumbnail_width_Create"] | undefined,
	read?: GraphQLTypes["PrivateMediaDocAccessFields_sizes_thumbnail_width_Read"] | undefined,
	update?: GraphQLTypes["PrivateMediaDocAccessFields_sizes_thumbnail_width_Update"] | undefined,
	delete?: GraphQLTypes["PrivateMediaDocAccessFields_sizes_thumbnail_width_Delete"] | undefined
};
	["PrivateMediaDocAccessFields_sizes_thumbnail_width_Create"]: {
	__typename: "PrivateMediaDocAccessFields_sizes_thumbnail_width_Create",
	permission: boolean
};
	["PrivateMediaDocAccessFields_sizes_thumbnail_width_Read"]: {
	__typename: "PrivateMediaDocAccessFields_sizes_thumbnail_width_Read",
	permission: boolean
};
	["PrivateMediaDocAccessFields_sizes_thumbnail_width_Update"]: {
	__typename: "PrivateMediaDocAccessFields_sizes_thumbnail_width_Update",
	permission: boolean
};
	["PrivateMediaDocAccessFields_sizes_thumbnail_width_Delete"]: {
	__typename: "PrivateMediaDocAccessFields_sizes_thumbnail_width_Delete",
	permission: boolean
};
	["PrivateMediaDocAccessFields_sizes_thumbnail_height"]: {
	__typename: "PrivateMediaDocAccessFields_sizes_thumbnail_height",
	create?: GraphQLTypes["PrivateMediaDocAccessFields_sizes_thumbnail_height_Create"] | undefined,
	read?: GraphQLTypes["PrivateMediaDocAccessFields_sizes_thumbnail_height_Read"] | undefined,
	update?: GraphQLTypes["PrivateMediaDocAccessFields_sizes_thumbnail_height_Update"] | undefined,
	delete?: GraphQLTypes["PrivateMediaDocAccessFields_sizes_thumbnail_height_Delete"] | undefined
};
	["PrivateMediaDocAccessFields_sizes_thumbnail_height_Create"]: {
	__typename: "PrivateMediaDocAccessFields_sizes_thumbnail_height_Create",
	permission: boolean
};
	["PrivateMediaDocAccessFields_sizes_thumbnail_height_Read"]: {
	__typename: "PrivateMediaDocAccessFields_sizes_thumbnail_height_Read",
	permission: boolean
};
	["PrivateMediaDocAccessFields_sizes_thumbnail_height_Update"]: {
	__typename: "PrivateMediaDocAccessFields_sizes_thumbnail_height_Update",
	permission: boolean
};
	["PrivateMediaDocAccessFields_sizes_thumbnail_height_Delete"]: {
	__typename: "PrivateMediaDocAccessFields_sizes_thumbnail_height_Delete",
	permission: boolean
};
	["PrivateMediaDocAccessFields_sizes_thumbnail_mimeType"]: {
	__typename: "PrivateMediaDocAccessFields_sizes_thumbnail_mimeType",
	create?: GraphQLTypes["PrivateMediaDocAccessFields_sizes_thumbnail_mimeType_Create"] | undefined,
	read?: GraphQLTypes["PrivateMediaDocAccessFields_sizes_thumbnail_mimeType_Read"] | undefined,
	update?: GraphQLTypes["PrivateMediaDocAccessFields_sizes_thumbnail_mimeType_Update"] | undefined,
	delete?: GraphQLTypes["PrivateMediaDocAccessFields_sizes_thumbnail_mimeType_Delete"] | undefined
};
	["PrivateMediaDocAccessFields_sizes_thumbnail_mimeType_Create"]: {
	__typename: "PrivateMediaDocAccessFields_sizes_thumbnail_mimeType_Create",
	permission: boolean
};
	["PrivateMediaDocAccessFields_sizes_thumbnail_mimeType_Read"]: {
	__typename: "PrivateMediaDocAccessFields_sizes_thumbnail_mimeType_Read",
	permission: boolean
};
	["PrivateMediaDocAccessFields_sizes_thumbnail_mimeType_Update"]: {
	__typename: "PrivateMediaDocAccessFields_sizes_thumbnail_mimeType_Update",
	permission: boolean
};
	["PrivateMediaDocAccessFields_sizes_thumbnail_mimeType_Delete"]: {
	__typename: "PrivateMediaDocAccessFields_sizes_thumbnail_mimeType_Delete",
	permission: boolean
};
	["PrivateMediaDocAccessFields_sizes_thumbnail_filesize"]: {
	__typename: "PrivateMediaDocAccessFields_sizes_thumbnail_filesize",
	create?: GraphQLTypes["PrivateMediaDocAccessFields_sizes_thumbnail_filesize_Create"] | undefined,
	read?: GraphQLTypes["PrivateMediaDocAccessFields_sizes_thumbnail_filesize_Read"] | undefined,
	update?: GraphQLTypes["PrivateMediaDocAccessFields_sizes_thumbnail_filesize_Update"] | undefined,
	delete?: GraphQLTypes["PrivateMediaDocAccessFields_sizes_thumbnail_filesize_Delete"] | undefined
};
	["PrivateMediaDocAccessFields_sizes_thumbnail_filesize_Create"]: {
	__typename: "PrivateMediaDocAccessFields_sizes_thumbnail_filesize_Create",
	permission: boolean
};
	["PrivateMediaDocAccessFields_sizes_thumbnail_filesize_Read"]: {
	__typename: "PrivateMediaDocAccessFields_sizes_thumbnail_filesize_Read",
	permission: boolean
};
	["PrivateMediaDocAccessFields_sizes_thumbnail_filesize_Update"]: {
	__typename: "PrivateMediaDocAccessFields_sizes_thumbnail_filesize_Update",
	permission: boolean
};
	["PrivateMediaDocAccessFields_sizes_thumbnail_filesize_Delete"]: {
	__typename: "PrivateMediaDocAccessFields_sizes_thumbnail_filesize_Delete",
	permission: boolean
};
	["PrivateMediaDocAccessFields_sizes_thumbnail_filename"]: {
	__typename: "PrivateMediaDocAccessFields_sizes_thumbnail_filename",
	create?: GraphQLTypes["PrivateMediaDocAccessFields_sizes_thumbnail_filename_Create"] | undefined,
	read?: GraphQLTypes["PrivateMediaDocAccessFields_sizes_thumbnail_filename_Read"] | undefined,
	update?: GraphQLTypes["PrivateMediaDocAccessFields_sizes_thumbnail_filename_Update"] | undefined,
	delete?: GraphQLTypes["PrivateMediaDocAccessFields_sizes_thumbnail_filename_Delete"] | undefined
};
	["PrivateMediaDocAccessFields_sizes_thumbnail_filename_Create"]: {
	__typename: "PrivateMediaDocAccessFields_sizes_thumbnail_filename_Create",
	permission: boolean
};
	["PrivateMediaDocAccessFields_sizes_thumbnail_filename_Read"]: {
	__typename: "PrivateMediaDocAccessFields_sizes_thumbnail_filename_Read",
	permission: boolean
};
	["PrivateMediaDocAccessFields_sizes_thumbnail_filename_Update"]: {
	__typename: "PrivateMediaDocAccessFields_sizes_thumbnail_filename_Update",
	permission: boolean
};
	["PrivateMediaDocAccessFields_sizes_thumbnail_filename_Delete"]: {
	__typename: "PrivateMediaDocAccessFields_sizes_thumbnail_filename_Delete",
	permission: boolean
};
	["PrivateMediaDocAccessFields_sizes_portrait"]: {
	__typename: "PrivateMediaDocAccessFields_sizes_portrait",
	create?: GraphQLTypes["PrivateMediaDocAccessFields_sizes_portrait_Create"] | undefined,
	read?: GraphQLTypes["PrivateMediaDocAccessFields_sizes_portrait_Read"] | undefined,
	update?: GraphQLTypes["PrivateMediaDocAccessFields_sizes_portrait_Update"] | undefined,
	delete?: GraphQLTypes["PrivateMediaDocAccessFields_sizes_portrait_Delete"] | undefined,
	fields?: GraphQLTypes["PrivateMediaDocAccessFields_sizes_portrait_Fields"] | undefined
};
	["PrivateMediaDocAccessFields_sizes_portrait_Create"]: {
	__typename: "PrivateMediaDocAccessFields_sizes_portrait_Create",
	permission: boolean
};
	["PrivateMediaDocAccessFields_sizes_portrait_Read"]: {
	__typename: "PrivateMediaDocAccessFields_sizes_portrait_Read",
	permission: boolean
};
	["PrivateMediaDocAccessFields_sizes_portrait_Update"]: {
	__typename: "PrivateMediaDocAccessFields_sizes_portrait_Update",
	permission: boolean
};
	["PrivateMediaDocAccessFields_sizes_portrait_Delete"]: {
	__typename: "PrivateMediaDocAccessFields_sizes_portrait_Delete",
	permission: boolean
};
	["PrivateMediaDocAccessFields_sizes_portrait_Fields"]: {
	__typename: "PrivateMediaDocAccessFields_sizes_portrait_Fields",
	url?: GraphQLTypes["PrivateMediaDocAccessFields_sizes_portrait_url"] | undefined,
	width?: GraphQLTypes["PrivateMediaDocAccessFields_sizes_portrait_width"] | undefined,
	height?: GraphQLTypes["PrivateMediaDocAccessFields_sizes_portrait_height"] | undefined,
	mimeType?: GraphQLTypes["PrivateMediaDocAccessFields_sizes_portrait_mimeType"] | undefined,
	filesize?: GraphQLTypes["PrivateMediaDocAccessFields_sizes_portrait_filesize"] | undefined,
	filename?: GraphQLTypes["PrivateMediaDocAccessFields_sizes_portrait_filename"] | undefined
};
	["PrivateMediaDocAccessFields_sizes_portrait_url"]: {
	__typename: "PrivateMediaDocAccessFields_sizes_portrait_url",
	create?: GraphQLTypes["PrivateMediaDocAccessFields_sizes_portrait_url_Create"] | undefined,
	read?: GraphQLTypes["PrivateMediaDocAccessFields_sizes_portrait_url_Read"] | undefined,
	update?: GraphQLTypes["PrivateMediaDocAccessFields_sizes_portrait_url_Update"] | undefined,
	delete?: GraphQLTypes["PrivateMediaDocAccessFields_sizes_portrait_url_Delete"] | undefined
};
	["PrivateMediaDocAccessFields_sizes_portrait_url_Create"]: {
	__typename: "PrivateMediaDocAccessFields_sizes_portrait_url_Create",
	permission: boolean
};
	["PrivateMediaDocAccessFields_sizes_portrait_url_Read"]: {
	__typename: "PrivateMediaDocAccessFields_sizes_portrait_url_Read",
	permission: boolean
};
	["PrivateMediaDocAccessFields_sizes_portrait_url_Update"]: {
	__typename: "PrivateMediaDocAccessFields_sizes_portrait_url_Update",
	permission: boolean
};
	["PrivateMediaDocAccessFields_sizes_portrait_url_Delete"]: {
	__typename: "PrivateMediaDocAccessFields_sizes_portrait_url_Delete",
	permission: boolean
};
	["PrivateMediaDocAccessFields_sizes_portrait_width"]: {
	__typename: "PrivateMediaDocAccessFields_sizes_portrait_width",
	create?: GraphQLTypes["PrivateMediaDocAccessFields_sizes_portrait_width_Create"] | undefined,
	read?: GraphQLTypes["PrivateMediaDocAccessFields_sizes_portrait_width_Read"] | undefined,
	update?: GraphQLTypes["PrivateMediaDocAccessFields_sizes_portrait_width_Update"] | undefined,
	delete?: GraphQLTypes["PrivateMediaDocAccessFields_sizes_portrait_width_Delete"] | undefined
};
	["PrivateMediaDocAccessFields_sizes_portrait_width_Create"]: {
	__typename: "PrivateMediaDocAccessFields_sizes_portrait_width_Create",
	permission: boolean
};
	["PrivateMediaDocAccessFields_sizes_portrait_width_Read"]: {
	__typename: "PrivateMediaDocAccessFields_sizes_portrait_width_Read",
	permission: boolean
};
	["PrivateMediaDocAccessFields_sizes_portrait_width_Update"]: {
	__typename: "PrivateMediaDocAccessFields_sizes_portrait_width_Update",
	permission: boolean
};
	["PrivateMediaDocAccessFields_sizes_portrait_width_Delete"]: {
	__typename: "PrivateMediaDocAccessFields_sizes_portrait_width_Delete",
	permission: boolean
};
	["PrivateMediaDocAccessFields_sizes_portrait_height"]: {
	__typename: "PrivateMediaDocAccessFields_sizes_portrait_height",
	create?: GraphQLTypes["PrivateMediaDocAccessFields_sizes_portrait_height_Create"] | undefined,
	read?: GraphQLTypes["PrivateMediaDocAccessFields_sizes_portrait_height_Read"] | undefined,
	update?: GraphQLTypes["PrivateMediaDocAccessFields_sizes_portrait_height_Update"] | undefined,
	delete?: GraphQLTypes["PrivateMediaDocAccessFields_sizes_portrait_height_Delete"] | undefined
};
	["PrivateMediaDocAccessFields_sizes_portrait_height_Create"]: {
	__typename: "PrivateMediaDocAccessFields_sizes_portrait_height_Create",
	permission: boolean
};
	["PrivateMediaDocAccessFields_sizes_portrait_height_Read"]: {
	__typename: "PrivateMediaDocAccessFields_sizes_portrait_height_Read",
	permission: boolean
};
	["PrivateMediaDocAccessFields_sizes_portrait_height_Update"]: {
	__typename: "PrivateMediaDocAccessFields_sizes_portrait_height_Update",
	permission: boolean
};
	["PrivateMediaDocAccessFields_sizes_portrait_height_Delete"]: {
	__typename: "PrivateMediaDocAccessFields_sizes_portrait_height_Delete",
	permission: boolean
};
	["PrivateMediaDocAccessFields_sizes_portrait_mimeType"]: {
	__typename: "PrivateMediaDocAccessFields_sizes_portrait_mimeType",
	create?: GraphQLTypes["PrivateMediaDocAccessFields_sizes_portrait_mimeType_Create"] | undefined,
	read?: GraphQLTypes["PrivateMediaDocAccessFields_sizes_portrait_mimeType_Read"] | undefined,
	update?: GraphQLTypes["PrivateMediaDocAccessFields_sizes_portrait_mimeType_Update"] | undefined,
	delete?: GraphQLTypes["PrivateMediaDocAccessFields_sizes_portrait_mimeType_Delete"] | undefined
};
	["PrivateMediaDocAccessFields_sizes_portrait_mimeType_Create"]: {
	__typename: "PrivateMediaDocAccessFields_sizes_portrait_mimeType_Create",
	permission: boolean
};
	["PrivateMediaDocAccessFields_sizes_portrait_mimeType_Read"]: {
	__typename: "PrivateMediaDocAccessFields_sizes_portrait_mimeType_Read",
	permission: boolean
};
	["PrivateMediaDocAccessFields_sizes_portrait_mimeType_Update"]: {
	__typename: "PrivateMediaDocAccessFields_sizes_portrait_mimeType_Update",
	permission: boolean
};
	["PrivateMediaDocAccessFields_sizes_portrait_mimeType_Delete"]: {
	__typename: "PrivateMediaDocAccessFields_sizes_portrait_mimeType_Delete",
	permission: boolean
};
	["PrivateMediaDocAccessFields_sizes_portrait_filesize"]: {
	__typename: "PrivateMediaDocAccessFields_sizes_portrait_filesize",
	create?: GraphQLTypes["PrivateMediaDocAccessFields_sizes_portrait_filesize_Create"] | undefined,
	read?: GraphQLTypes["PrivateMediaDocAccessFields_sizes_portrait_filesize_Read"] | undefined,
	update?: GraphQLTypes["PrivateMediaDocAccessFields_sizes_portrait_filesize_Update"] | undefined,
	delete?: GraphQLTypes["PrivateMediaDocAccessFields_sizes_portrait_filesize_Delete"] | undefined
};
	["PrivateMediaDocAccessFields_sizes_portrait_filesize_Create"]: {
	__typename: "PrivateMediaDocAccessFields_sizes_portrait_filesize_Create",
	permission: boolean
};
	["PrivateMediaDocAccessFields_sizes_portrait_filesize_Read"]: {
	__typename: "PrivateMediaDocAccessFields_sizes_portrait_filesize_Read",
	permission: boolean
};
	["PrivateMediaDocAccessFields_sizes_portrait_filesize_Update"]: {
	__typename: "PrivateMediaDocAccessFields_sizes_portrait_filesize_Update",
	permission: boolean
};
	["PrivateMediaDocAccessFields_sizes_portrait_filesize_Delete"]: {
	__typename: "PrivateMediaDocAccessFields_sizes_portrait_filesize_Delete",
	permission: boolean
};
	["PrivateMediaDocAccessFields_sizes_portrait_filename"]: {
	__typename: "PrivateMediaDocAccessFields_sizes_portrait_filename",
	create?: GraphQLTypes["PrivateMediaDocAccessFields_sizes_portrait_filename_Create"] | undefined,
	read?: GraphQLTypes["PrivateMediaDocAccessFields_sizes_portrait_filename_Read"] | undefined,
	update?: GraphQLTypes["PrivateMediaDocAccessFields_sizes_portrait_filename_Update"] | undefined,
	delete?: GraphQLTypes["PrivateMediaDocAccessFields_sizes_portrait_filename_Delete"] | undefined
};
	["PrivateMediaDocAccessFields_sizes_portrait_filename_Create"]: {
	__typename: "PrivateMediaDocAccessFields_sizes_portrait_filename_Create",
	permission: boolean
};
	["PrivateMediaDocAccessFields_sizes_portrait_filename_Read"]: {
	__typename: "PrivateMediaDocAccessFields_sizes_portrait_filename_Read",
	permission: boolean
};
	["PrivateMediaDocAccessFields_sizes_portrait_filename_Update"]: {
	__typename: "PrivateMediaDocAccessFields_sizes_portrait_filename_Update",
	permission: boolean
};
	["PrivateMediaDocAccessFields_sizes_portrait_filename_Delete"]: {
	__typename: "PrivateMediaDocAccessFields_sizes_portrait_filename_Delete",
	permission: boolean
};
	["PrivateMediaDocAccessFields_sizes_hero"]: {
	__typename: "PrivateMediaDocAccessFields_sizes_hero",
	create?: GraphQLTypes["PrivateMediaDocAccessFields_sizes_hero_Create"] | undefined,
	read?: GraphQLTypes["PrivateMediaDocAccessFields_sizes_hero_Read"] | undefined,
	update?: GraphQLTypes["PrivateMediaDocAccessFields_sizes_hero_Update"] | undefined,
	delete?: GraphQLTypes["PrivateMediaDocAccessFields_sizes_hero_Delete"] | undefined,
	fields?: GraphQLTypes["PrivateMediaDocAccessFields_sizes_hero_Fields"] | undefined
};
	["PrivateMediaDocAccessFields_sizes_hero_Create"]: {
	__typename: "PrivateMediaDocAccessFields_sizes_hero_Create",
	permission: boolean
};
	["PrivateMediaDocAccessFields_sizes_hero_Read"]: {
	__typename: "PrivateMediaDocAccessFields_sizes_hero_Read",
	permission: boolean
};
	["PrivateMediaDocAccessFields_sizes_hero_Update"]: {
	__typename: "PrivateMediaDocAccessFields_sizes_hero_Update",
	permission: boolean
};
	["PrivateMediaDocAccessFields_sizes_hero_Delete"]: {
	__typename: "PrivateMediaDocAccessFields_sizes_hero_Delete",
	permission: boolean
};
	["PrivateMediaDocAccessFields_sizes_hero_Fields"]: {
	__typename: "PrivateMediaDocAccessFields_sizes_hero_Fields",
	url?: GraphQLTypes["PrivateMediaDocAccessFields_sizes_hero_url"] | undefined,
	width?: GraphQLTypes["PrivateMediaDocAccessFields_sizes_hero_width"] | undefined,
	height?: GraphQLTypes["PrivateMediaDocAccessFields_sizes_hero_height"] | undefined,
	mimeType?: GraphQLTypes["PrivateMediaDocAccessFields_sizes_hero_mimeType"] | undefined,
	filesize?: GraphQLTypes["PrivateMediaDocAccessFields_sizes_hero_filesize"] | undefined,
	filename?: GraphQLTypes["PrivateMediaDocAccessFields_sizes_hero_filename"] | undefined
};
	["PrivateMediaDocAccessFields_sizes_hero_url"]: {
	__typename: "PrivateMediaDocAccessFields_sizes_hero_url",
	create?: GraphQLTypes["PrivateMediaDocAccessFields_sizes_hero_url_Create"] | undefined,
	read?: GraphQLTypes["PrivateMediaDocAccessFields_sizes_hero_url_Read"] | undefined,
	update?: GraphQLTypes["PrivateMediaDocAccessFields_sizes_hero_url_Update"] | undefined,
	delete?: GraphQLTypes["PrivateMediaDocAccessFields_sizes_hero_url_Delete"] | undefined
};
	["PrivateMediaDocAccessFields_sizes_hero_url_Create"]: {
	__typename: "PrivateMediaDocAccessFields_sizes_hero_url_Create",
	permission: boolean
};
	["PrivateMediaDocAccessFields_sizes_hero_url_Read"]: {
	__typename: "PrivateMediaDocAccessFields_sizes_hero_url_Read",
	permission: boolean
};
	["PrivateMediaDocAccessFields_sizes_hero_url_Update"]: {
	__typename: "PrivateMediaDocAccessFields_sizes_hero_url_Update",
	permission: boolean
};
	["PrivateMediaDocAccessFields_sizes_hero_url_Delete"]: {
	__typename: "PrivateMediaDocAccessFields_sizes_hero_url_Delete",
	permission: boolean
};
	["PrivateMediaDocAccessFields_sizes_hero_width"]: {
	__typename: "PrivateMediaDocAccessFields_sizes_hero_width",
	create?: GraphQLTypes["PrivateMediaDocAccessFields_sizes_hero_width_Create"] | undefined,
	read?: GraphQLTypes["PrivateMediaDocAccessFields_sizes_hero_width_Read"] | undefined,
	update?: GraphQLTypes["PrivateMediaDocAccessFields_sizes_hero_width_Update"] | undefined,
	delete?: GraphQLTypes["PrivateMediaDocAccessFields_sizes_hero_width_Delete"] | undefined
};
	["PrivateMediaDocAccessFields_sizes_hero_width_Create"]: {
	__typename: "PrivateMediaDocAccessFields_sizes_hero_width_Create",
	permission: boolean
};
	["PrivateMediaDocAccessFields_sizes_hero_width_Read"]: {
	__typename: "PrivateMediaDocAccessFields_sizes_hero_width_Read",
	permission: boolean
};
	["PrivateMediaDocAccessFields_sizes_hero_width_Update"]: {
	__typename: "PrivateMediaDocAccessFields_sizes_hero_width_Update",
	permission: boolean
};
	["PrivateMediaDocAccessFields_sizes_hero_width_Delete"]: {
	__typename: "PrivateMediaDocAccessFields_sizes_hero_width_Delete",
	permission: boolean
};
	["PrivateMediaDocAccessFields_sizes_hero_height"]: {
	__typename: "PrivateMediaDocAccessFields_sizes_hero_height",
	create?: GraphQLTypes["PrivateMediaDocAccessFields_sizes_hero_height_Create"] | undefined,
	read?: GraphQLTypes["PrivateMediaDocAccessFields_sizes_hero_height_Read"] | undefined,
	update?: GraphQLTypes["PrivateMediaDocAccessFields_sizes_hero_height_Update"] | undefined,
	delete?: GraphQLTypes["PrivateMediaDocAccessFields_sizes_hero_height_Delete"] | undefined
};
	["PrivateMediaDocAccessFields_sizes_hero_height_Create"]: {
	__typename: "PrivateMediaDocAccessFields_sizes_hero_height_Create",
	permission: boolean
};
	["PrivateMediaDocAccessFields_sizes_hero_height_Read"]: {
	__typename: "PrivateMediaDocAccessFields_sizes_hero_height_Read",
	permission: boolean
};
	["PrivateMediaDocAccessFields_sizes_hero_height_Update"]: {
	__typename: "PrivateMediaDocAccessFields_sizes_hero_height_Update",
	permission: boolean
};
	["PrivateMediaDocAccessFields_sizes_hero_height_Delete"]: {
	__typename: "PrivateMediaDocAccessFields_sizes_hero_height_Delete",
	permission: boolean
};
	["PrivateMediaDocAccessFields_sizes_hero_mimeType"]: {
	__typename: "PrivateMediaDocAccessFields_sizes_hero_mimeType",
	create?: GraphQLTypes["PrivateMediaDocAccessFields_sizes_hero_mimeType_Create"] | undefined,
	read?: GraphQLTypes["PrivateMediaDocAccessFields_sizes_hero_mimeType_Read"] | undefined,
	update?: GraphQLTypes["PrivateMediaDocAccessFields_sizes_hero_mimeType_Update"] | undefined,
	delete?: GraphQLTypes["PrivateMediaDocAccessFields_sizes_hero_mimeType_Delete"] | undefined
};
	["PrivateMediaDocAccessFields_sizes_hero_mimeType_Create"]: {
	__typename: "PrivateMediaDocAccessFields_sizes_hero_mimeType_Create",
	permission: boolean
};
	["PrivateMediaDocAccessFields_sizes_hero_mimeType_Read"]: {
	__typename: "PrivateMediaDocAccessFields_sizes_hero_mimeType_Read",
	permission: boolean
};
	["PrivateMediaDocAccessFields_sizes_hero_mimeType_Update"]: {
	__typename: "PrivateMediaDocAccessFields_sizes_hero_mimeType_Update",
	permission: boolean
};
	["PrivateMediaDocAccessFields_sizes_hero_mimeType_Delete"]: {
	__typename: "PrivateMediaDocAccessFields_sizes_hero_mimeType_Delete",
	permission: boolean
};
	["PrivateMediaDocAccessFields_sizes_hero_filesize"]: {
	__typename: "PrivateMediaDocAccessFields_sizes_hero_filesize",
	create?: GraphQLTypes["PrivateMediaDocAccessFields_sizes_hero_filesize_Create"] | undefined,
	read?: GraphQLTypes["PrivateMediaDocAccessFields_sizes_hero_filesize_Read"] | undefined,
	update?: GraphQLTypes["PrivateMediaDocAccessFields_sizes_hero_filesize_Update"] | undefined,
	delete?: GraphQLTypes["PrivateMediaDocAccessFields_sizes_hero_filesize_Delete"] | undefined
};
	["PrivateMediaDocAccessFields_sizes_hero_filesize_Create"]: {
	__typename: "PrivateMediaDocAccessFields_sizes_hero_filesize_Create",
	permission: boolean
};
	["PrivateMediaDocAccessFields_sizes_hero_filesize_Read"]: {
	__typename: "PrivateMediaDocAccessFields_sizes_hero_filesize_Read",
	permission: boolean
};
	["PrivateMediaDocAccessFields_sizes_hero_filesize_Update"]: {
	__typename: "PrivateMediaDocAccessFields_sizes_hero_filesize_Update",
	permission: boolean
};
	["PrivateMediaDocAccessFields_sizes_hero_filesize_Delete"]: {
	__typename: "PrivateMediaDocAccessFields_sizes_hero_filesize_Delete",
	permission: boolean
};
	["PrivateMediaDocAccessFields_sizes_hero_filename"]: {
	__typename: "PrivateMediaDocAccessFields_sizes_hero_filename",
	create?: GraphQLTypes["PrivateMediaDocAccessFields_sizes_hero_filename_Create"] | undefined,
	read?: GraphQLTypes["PrivateMediaDocAccessFields_sizes_hero_filename_Read"] | undefined,
	update?: GraphQLTypes["PrivateMediaDocAccessFields_sizes_hero_filename_Update"] | undefined,
	delete?: GraphQLTypes["PrivateMediaDocAccessFields_sizes_hero_filename_Delete"] | undefined
};
	["PrivateMediaDocAccessFields_sizes_hero_filename_Create"]: {
	__typename: "PrivateMediaDocAccessFields_sizes_hero_filename_Create",
	permission: boolean
};
	["PrivateMediaDocAccessFields_sizes_hero_filename_Read"]: {
	__typename: "PrivateMediaDocAccessFields_sizes_hero_filename_Read",
	permission: boolean
};
	["PrivateMediaDocAccessFields_sizes_hero_filename_Update"]: {
	__typename: "PrivateMediaDocAccessFields_sizes_hero_filename_Update",
	permission: boolean
};
	["PrivateMediaDocAccessFields_sizes_hero_filename_Delete"]: {
	__typename: "PrivateMediaDocAccessFields_sizes_hero_filename_Delete",
	permission: boolean
};
	["PrivateMediaDocAccessFields_sizes_thumbnail_jpeg"]: {
	__typename: "PrivateMediaDocAccessFields_sizes_thumbnail_jpeg",
	create?: GraphQLTypes["PrivateMediaDocAccessFields_sizes_thumbnail_jpeg_Create"] | undefined,
	read?: GraphQLTypes["PrivateMediaDocAccessFields_sizes_thumbnail_jpeg_Read"] | undefined,
	update?: GraphQLTypes["PrivateMediaDocAccessFields_sizes_thumbnail_jpeg_Update"] | undefined,
	delete?: GraphQLTypes["PrivateMediaDocAccessFields_sizes_thumbnail_jpeg_Delete"] | undefined,
	fields?: GraphQLTypes["PrivateMediaDocAccessFields_sizes_thumbnail_jpeg_Fields"] | undefined
};
	["PrivateMediaDocAccessFields_sizes_thumbnail_jpeg_Create"]: {
	__typename: "PrivateMediaDocAccessFields_sizes_thumbnail_jpeg_Create",
	permission: boolean
};
	["PrivateMediaDocAccessFields_sizes_thumbnail_jpeg_Read"]: {
	__typename: "PrivateMediaDocAccessFields_sizes_thumbnail_jpeg_Read",
	permission: boolean
};
	["PrivateMediaDocAccessFields_sizes_thumbnail_jpeg_Update"]: {
	__typename: "PrivateMediaDocAccessFields_sizes_thumbnail_jpeg_Update",
	permission: boolean
};
	["PrivateMediaDocAccessFields_sizes_thumbnail_jpeg_Delete"]: {
	__typename: "PrivateMediaDocAccessFields_sizes_thumbnail_jpeg_Delete",
	permission: boolean
};
	["PrivateMediaDocAccessFields_sizes_thumbnail_jpeg_Fields"]: {
	__typename: "PrivateMediaDocAccessFields_sizes_thumbnail_jpeg_Fields",
	url?: GraphQLTypes["PrivateMediaDocAccessFields_sizes_thumbnail_jpeg_url"] | undefined,
	width?: GraphQLTypes["PrivateMediaDocAccessFields_sizes_thumbnail_jpeg_width"] | undefined,
	height?: GraphQLTypes["PrivateMediaDocAccessFields_sizes_thumbnail_jpeg_height"] | undefined,
	mimeType?: GraphQLTypes["PrivateMediaDocAccessFields_sizes_thumbnail_jpeg_mimeType"] | undefined,
	filesize?: GraphQLTypes["PrivateMediaDocAccessFields_sizes_thumbnail_jpeg_filesize"] | undefined,
	filename?: GraphQLTypes["PrivateMediaDocAccessFields_sizes_thumbnail_jpeg_filename"] | undefined
};
	["PrivateMediaDocAccessFields_sizes_thumbnail_jpeg_url"]: {
	__typename: "PrivateMediaDocAccessFields_sizes_thumbnail_jpeg_url",
	create?: GraphQLTypes["PrivateMediaDocAccessFields_sizes_thumbnail_jpeg_url_Create"] | undefined,
	read?: GraphQLTypes["PrivateMediaDocAccessFields_sizes_thumbnail_jpeg_url_Read"] | undefined,
	update?: GraphQLTypes["PrivateMediaDocAccessFields_sizes_thumbnail_jpeg_url_Update"] | undefined,
	delete?: GraphQLTypes["PrivateMediaDocAccessFields_sizes_thumbnail_jpeg_url_Delete"] | undefined
};
	["PrivateMediaDocAccessFields_sizes_thumbnail_jpeg_url_Create"]: {
	__typename: "PrivateMediaDocAccessFields_sizes_thumbnail_jpeg_url_Create",
	permission: boolean
};
	["PrivateMediaDocAccessFields_sizes_thumbnail_jpeg_url_Read"]: {
	__typename: "PrivateMediaDocAccessFields_sizes_thumbnail_jpeg_url_Read",
	permission: boolean
};
	["PrivateMediaDocAccessFields_sizes_thumbnail_jpeg_url_Update"]: {
	__typename: "PrivateMediaDocAccessFields_sizes_thumbnail_jpeg_url_Update",
	permission: boolean
};
	["PrivateMediaDocAccessFields_sizes_thumbnail_jpeg_url_Delete"]: {
	__typename: "PrivateMediaDocAccessFields_sizes_thumbnail_jpeg_url_Delete",
	permission: boolean
};
	["PrivateMediaDocAccessFields_sizes_thumbnail_jpeg_width"]: {
	__typename: "PrivateMediaDocAccessFields_sizes_thumbnail_jpeg_width",
	create?: GraphQLTypes["PrivateMediaDocAccessFields_sizes_thumbnail_jpeg_width_Create"] | undefined,
	read?: GraphQLTypes["PrivateMediaDocAccessFields_sizes_thumbnail_jpeg_width_Read"] | undefined,
	update?: GraphQLTypes["PrivateMediaDocAccessFields_sizes_thumbnail_jpeg_width_Update"] | undefined,
	delete?: GraphQLTypes["PrivateMediaDocAccessFields_sizes_thumbnail_jpeg_width_Delete"] | undefined
};
	["PrivateMediaDocAccessFields_sizes_thumbnail_jpeg_width_Create"]: {
	__typename: "PrivateMediaDocAccessFields_sizes_thumbnail_jpeg_width_Create",
	permission: boolean
};
	["PrivateMediaDocAccessFields_sizes_thumbnail_jpeg_width_Read"]: {
	__typename: "PrivateMediaDocAccessFields_sizes_thumbnail_jpeg_width_Read",
	permission: boolean
};
	["PrivateMediaDocAccessFields_sizes_thumbnail_jpeg_width_Update"]: {
	__typename: "PrivateMediaDocAccessFields_sizes_thumbnail_jpeg_width_Update",
	permission: boolean
};
	["PrivateMediaDocAccessFields_sizes_thumbnail_jpeg_width_Delete"]: {
	__typename: "PrivateMediaDocAccessFields_sizes_thumbnail_jpeg_width_Delete",
	permission: boolean
};
	["PrivateMediaDocAccessFields_sizes_thumbnail_jpeg_height"]: {
	__typename: "PrivateMediaDocAccessFields_sizes_thumbnail_jpeg_height",
	create?: GraphQLTypes["PrivateMediaDocAccessFields_sizes_thumbnail_jpeg_height_Create"] | undefined,
	read?: GraphQLTypes["PrivateMediaDocAccessFields_sizes_thumbnail_jpeg_height_Read"] | undefined,
	update?: GraphQLTypes["PrivateMediaDocAccessFields_sizes_thumbnail_jpeg_height_Update"] | undefined,
	delete?: GraphQLTypes["PrivateMediaDocAccessFields_sizes_thumbnail_jpeg_height_Delete"] | undefined
};
	["PrivateMediaDocAccessFields_sizes_thumbnail_jpeg_height_Create"]: {
	__typename: "PrivateMediaDocAccessFields_sizes_thumbnail_jpeg_height_Create",
	permission: boolean
};
	["PrivateMediaDocAccessFields_sizes_thumbnail_jpeg_height_Read"]: {
	__typename: "PrivateMediaDocAccessFields_sizes_thumbnail_jpeg_height_Read",
	permission: boolean
};
	["PrivateMediaDocAccessFields_sizes_thumbnail_jpeg_height_Update"]: {
	__typename: "PrivateMediaDocAccessFields_sizes_thumbnail_jpeg_height_Update",
	permission: boolean
};
	["PrivateMediaDocAccessFields_sizes_thumbnail_jpeg_height_Delete"]: {
	__typename: "PrivateMediaDocAccessFields_sizes_thumbnail_jpeg_height_Delete",
	permission: boolean
};
	["PrivateMediaDocAccessFields_sizes_thumbnail_jpeg_mimeType"]: {
	__typename: "PrivateMediaDocAccessFields_sizes_thumbnail_jpeg_mimeType",
	create?: GraphQLTypes["PrivateMediaDocAccessFields_sizes_thumbnail_jpeg_mimeType_Create"] | undefined,
	read?: GraphQLTypes["PrivateMediaDocAccessFields_sizes_thumbnail_jpeg_mimeType_Read"] | undefined,
	update?: GraphQLTypes["PrivateMediaDocAccessFields_sizes_thumbnail_jpeg_mimeType_Update"] | undefined,
	delete?: GraphQLTypes["PrivateMediaDocAccessFields_sizes_thumbnail_jpeg_mimeType_Delete"] | undefined
};
	["PrivateMediaDocAccessFields_sizes_thumbnail_jpeg_mimeType_Create"]: {
	__typename: "PrivateMediaDocAccessFields_sizes_thumbnail_jpeg_mimeType_Create",
	permission: boolean
};
	["PrivateMediaDocAccessFields_sizes_thumbnail_jpeg_mimeType_Read"]: {
	__typename: "PrivateMediaDocAccessFields_sizes_thumbnail_jpeg_mimeType_Read",
	permission: boolean
};
	["PrivateMediaDocAccessFields_sizes_thumbnail_jpeg_mimeType_Update"]: {
	__typename: "PrivateMediaDocAccessFields_sizes_thumbnail_jpeg_mimeType_Update",
	permission: boolean
};
	["PrivateMediaDocAccessFields_sizes_thumbnail_jpeg_mimeType_Delete"]: {
	__typename: "PrivateMediaDocAccessFields_sizes_thumbnail_jpeg_mimeType_Delete",
	permission: boolean
};
	["PrivateMediaDocAccessFields_sizes_thumbnail_jpeg_filesize"]: {
	__typename: "PrivateMediaDocAccessFields_sizes_thumbnail_jpeg_filesize",
	create?: GraphQLTypes["PrivateMediaDocAccessFields_sizes_thumbnail_jpeg_filesize_Create"] | undefined,
	read?: GraphQLTypes["PrivateMediaDocAccessFields_sizes_thumbnail_jpeg_filesize_Read"] | undefined,
	update?: GraphQLTypes["PrivateMediaDocAccessFields_sizes_thumbnail_jpeg_filesize_Update"] | undefined,
	delete?: GraphQLTypes["PrivateMediaDocAccessFields_sizes_thumbnail_jpeg_filesize_Delete"] | undefined
};
	["PrivateMediaDocAccessFields_sizes_thumbnail_jpeg_filesize_Create"]: {
	__typename: "PrivateMediaDocAccessFields_sizes_thumbnail_jpeg_filesize_Create",
	permission: boolean
};
	["PrivateMediaDocAccessFields_sizes_thumbnail_jpeg_filesize_Read"]: {
	__typename: "PrivateMediaDocAccessFields_sizes_thumbnail_jpeg_filesize_Read",
	permission: boolean
};
	["PrivateMediaDocAccessFields_sizes_thumbnail_jpeg_filesize_Update"]: {
	__typename: "PrivateMediaDocAccessFields_sizes_thumbnail_jpeg_filesize_Update",
	permission: boolean
};
	["PrivateMediaDocAccessFields_sizes_thumbnail_jpeg_filesize_Delete"]: {
	__typename: "PrivateMediaDocAccessFields_sizes_thumbnail_jpeg_filesize_Delete",
	permission: boolean
};
	["PrivateMediaDocAccessFields_sizes_thumbnail_jpeg_filename"]: {
	__typename: "PrivateMediaDocAccessFields_sizes_thumbnail_jpeg_filename",
	create?: GraphQLTypes["PrivateMediaDocAccessFields_sizes_thumbnail_jpeg_filename_Create"] | undefined,
	read?: GraphQLTypes["PrivateMediaDocAccessFields_sizes_thumbnail_jpeg_filename_Read"] | undefined,
	update?: GraphQLTypes["PrivateMediaDocAccessFields_sizes_thumbnail_jpeg_filename_Update"] | undefined,
	delete?: GraphQLTypes["PrivateMediaDocAccessFields_sizes_thumbnail_jpeg_filename_Delete"] | undefined
};
	["PrivateMediaDocAccessFields_sizes_thumbnail_jpeg_filename_Create"]: {
	__typename: "PrivateMediaDocAccessFields_sizes_thumbnail_jpeg_filename_Create",
	permission: boolean
};
	["PrivateMediaDocAccessFields_sizes_thumbnail_jpeg_filename_Read"]: {
	__typename: "PrivateMediaDocAccessFields_sizes_thumbnail_jpeg_filename_Read",
	permission: boolean
};
	["PrivateMediaDocAccessFields_sizes_thumbnail_jpeg_filename_Update"]: {
	__typename: "PrivateMediaDocAccessFields_sizes_thumbnail_jpeg_filename_Update",
	permission: boolean
};
	["PrivateMediaDocAccessFields_sizes_thumbnail_jpeg_filename_Delete"]: {
	__typename: "PrivateMediaDocAccessFields_sizes_thumbnail_jpeg_filename_Delete",
	permission: boolean
};
	["PrivateMediaDocAccessFields_sizes_portrait_jpeg"]: {
	__typename: "PrivateMediaDocAccessFields_sizes_portrait_jpeg",
	create?: GraphQLTypes["PrivateMediaDocAccessFields_sizes_portrait_jpeg_Create"] | undefined,
	read?: GraphQLTypes["PrivateMediaDocAccessFields_sizes_portrait_jpeg_Read"] | undefined,
	update?: GraphQLTypes["PrivateMediaDocAccessFields_sizes_portrait_jpeg_Update"] | undefined,
	delete?: GraphQLTypes["PrivateMediaDocAccessFields_sizes_portrait_jpeg_Delete"] | undefined,
	fields?: GraphQLTypes["PrivateMediaDocAccessFields_sizes_portrait_jpeg_Fields"] | undefined
};
	["PrivateMediaDocAccessFields_sizes_portrait_jpeg_Create"]: {
	__typename: "PrivateMediaDocAccessFields_sizes_portrait_jpeg_Create",
	permission: boolean
};
	["PrivateMediaDocAccessFields_sizes_portrait_jpeg_Read"]: {
	__typename: "PrivateMediaDocAccessFields_sizes_portrait_jpeg_Read",
	permission: boolean
};
	["PrivateMediaDocAccessFields_sizes_portrait_jpeg_Update"]: {
	__typename: "PrivateMediaDocAccessFields_sizes_portrait_jpeg_Update",
	permission: boolean
};
	["PrivateMediaDocAccessFields_sizes_portrait_jpeg_Delete"]: {
	__typename: "PrivateMediaDocAccessFields_sizes_portrait_jpeg_Delete",
	permission: boolean
};
	["PrivateMediaDocAccessFields_sizes_portrait_jpeg_Fields"]: {
	__typename: "PrivateMediaDocAccessFields_sizes_portrait_jpeg_Fields",
	url?: GraphQLTypes["PrivateMediaDocAccessFields_sizes_portrait_jpeg_url"] | undefined,
	width?: GraphQLTypes["PrivateMediaDocAccessFields_sizes_portrait_jpeg_width"] | undefined,
	height?: GraphQLTypes["PrivateMediaDocAccessFields_sizes_portrait_jpeg_height"] | undefined,
	mimeType?: GraphQLTypes["PrivateMediaDocAccessFields_sizes_portrait_jpeg_mimeType"] | undefined,
	filesize?: GraphQLTypes["PrivateMediaDocAccessFields_sizes_portrait_jpeg_filesize"] | undefined,
	filename?: GraphQLTypes["PrivateMediaDocAccessFields_sizes_portrait_jpeg_filename"] | undefined
};
	["PrivateMediaDocAccessFields_sizes_portrait_jpeg_url"]: {
	__typename: "PrivateMediaDocAccessFields_sizes_portrait_jpeg_url",
	create?: GraphQLTypes["PrivateMediaDocAccessFields_sizes_portrait_jpeg_url_Create"] | undefined,
	read?: GraphQLTypes["PrivateMediaDocAccessFields_sizes_portrait_jpeg_url_Read"] | undefined,
	update?: GraphQLTypes["PrivateMediaDocAccessFields_sizes_portrait_jpeg_url_Update"] | undefined,
	delete?: GraphQLTypes["PrivateMediaDocAccessFields_sizes_portrait_jpeg_url_Delete"] | undefined
};
	["PrivateMediaDocAccessFields_sizes_portrait_jpeg_url_Create"]: {
	__typename: "PrivateMediaDocAccessFields_sizes_portrait_jpeg_url_Create",
	permission: boolean
};
	["PrivateMediaDocAccessFields_sizes_portrait_jpeg_url_Read"]: {
	__typename: "PrivateMediaDocAccessFields_sizes_portrait_jpeg_url_Read",
	permission: boolean
};
	["PrivateMediaDocAccessFields_sizes_portrait_jpeg_url_Update"]: {
	__typename: "PrivateMediaDocAccessFields_sizes_portrait_jpeg_url_Update",
	permission: boolean
};
	["PrivateMediaDocAccessFields_sizes_portrait_jpeg_url_Delete"]: {
	__typename: "PrivateMediaDocAccessFields_sizes_portrait_jpeg_url_Delete",
	permission: boolean
};
	["PrivateMediaDocAccessFields_sizes_portrait_jpeg_width"]: {
	__typename: "PrivateMediaDocAccessFields_sizes_portrait_jpeg_width",
	create?: GraphQLTypes["PrivateMediaDocAccessFields_sizes_portrait_jpeg_width_Create"] | undefined,
	read?: GraphQLTypes["PrivateMediaDocAccessFields_sizes_portrait_jpeg_width_Read"] | undefined,
	update?: GraphQLTypes["PrivateMediaDocAccessFields_sizes_portrait_jpeg_width_Update"] | undefined,
	delete?: GraphQLTypes["PrivateMediaDocAccessFields_sizes_portrait_jpeg_width_Delete"] | undefined
};
	["PrivateMediaDocAccessFields_sizes_portrait_jpeg_width_Create"]: {
	__typename: "PrivateMediaDocAccessFields_sizes_portrait_jpeg_width_Create",
	permission: boolean
};
	["PrivateMediaDocAccessFields_sizes_portrait_jpeg_width_Read"]: {
	__typename: "PrivateMediaDocAccessFields_sizes_portrait_jpeg_width_Read",
	permission: boolean
};
	["PrivateMediaDocAccessFields_sizes_portrait_jpeg_width_Update"]: {
	__typename: "PrivateMediaDocAccessFields_sizes_portrait_jpeg_width_Update",
	permission: boolean
};
	["PrivateMediaDocAccessFields_sizes_portrait_jpeg_width_Delete"]: {
	__typename: "PrivateMediaDocAccessFields_sizes_portrait_jpeg_width_Delete",
	permission: boolean
};
	["PrivateMediaDocAccessFields_sizes_portrait_jpeg_height"]: {
	__typename: "PrivateMediaDocAccessFields_sizes_portrait_jpeg_height",
	create?: GraphQLTypes["PrivateMediaDocAccessFields_sizes_portrait_jpeg_height_Create"] | undefined,
	read?: GraphQLTypes["PrivateMediaDocAccessFields_sizes_portrait_jpeg_height_Read"] | undefined,
	update?: GraphQLTypes["PrivateMediaDocAccessFields_sizes_portrait_jpeg_height_Update"] | undefined,
	delete?: GraphQLTypes["PrivateMediaDocAccessFields_sizes_portrait_jpeg_height_Delete"] | undefined
};
	["PrivateMediaDocAccessFields_sizes_portrait_jpeg_height_Create"]: {
	__typename: "PrivateMediaDocAccessFields_sizes_portrait_jpeg_height_Create",
	permission: boolean
};
	["PrivateMediaDocAccessFields_sizes_portrait_jpeg_height_Read"]: {
	__typename: "PrivateMediaDocAccessFields_sizes_portrait_jpeg_height_Read",
	permission: boolean
};
	["PrivateMediaDocAccessFields_sizes_portrait_jpeg_height_Update"]: {
	__typename: "PrivateMediaDocAccessFields_sizes_portrait_jpeg_height_Update",
	permission: boolean
};
	["PrivateMediaDocAccessFields_sizes_portrait_jpeg_height_Delete"]: {
	__typename: "PrivateMediaDocAccessFields_sizes_portrait_jpeg_height_Delete",
	permission: boolean
};
	["PrivateMediaDocAccessFields_sizes_portrait_jpeg_mimeType"]: {
	__typename: "PrivateMediaDocAccessFields_sizes_portrait_jpeg_mimeType",
	create?: GraphQLTypes["PrivateMediaDocAccessFields_sizes_portrait_jpeg_mimeType_Create"] | undefined,
	read?: GraphQLTypes["PrivateMediaDocAccessFields_sizes_portrait_jpeg_mimeType_Read"] | undefined,
	update?: GraphQLTypes["PrivateMediaDocAccessFields_sizes_portrait_jpeg_mimeType_Update"] | undefined,
	delete?: GraphQLTypes["PrivateMediaDocAccessFields_sizes_portrait_jpeg_mimeType_Delete"] | undefined
};
	["PrivateMediaDocAccessFields_sizes_portrait_jpeg_mimeType_Create"]: {
	__typename: "PrivateMediaDocAccessFields_sizes_portrait_jpeg_mimeType_Create",
	permission: boolean
};
	["PrivateMediaDocAccessFields_sizes_portrait_jpeg_mimeType_Read"]: {
	__typename: "PrivateMediaDocAccessFields_sizes_portrait_jpeg_mimeType_Read",
	permission: boolean
};
	["PrivateMediaDocAccessFields_sizes_portrait_jpeg_mimeType_Update"]: {
	__typename: "PrivateMediaDocAccessFields_sizes_portrait_jpeg_mimeType_Update",
	permission: boolean
};
	["PrivateMediaDocAccessFields_sizes_portrait_jpeg_mimeType_Delete"]: {
	__typename: "PrivateMediaDocAccessFields_sizes_portrait_jpeg_mimeType_Delete",
	permission: boolean
};
	["PrivateMediaDocAccessFields_sizes_portrait_jpeg_filesize"]: {
	__typename: "PrivateMediaDocAccessFields_sizes_portrait_jpeg_filesize",
	create?: GraphQLTypes["PrivateMediaDocAccessFields_sizes_portrait_jpeg_filesize_Create"] | undefined,
	read?: GraphQLTypes["PrivateMediaDocAccessFields_sizes_portrait_jpeg_filesize_Read"] | undefined,
	update?: GraphQLTypes["PrivateMediaDocAccessFields_sizes_portrait_jpeg_filesize_Update"] | undefined,
	delete?: GraphQLTypes["PrivateMediaDocAccessFields_sizes_portrait_jpeg_filesize_Delete"] | undefined
};
	["PrivateMediaDocAccessFields_sizes_portrait_jpeg_filesize_Create"]: {
	__typename: "PrivateMediaDocAccessFields_sizes_portrait_jpeg_filesize_Create",
	permission: boolean
};
	["PrivateMediaDocAccessFields_sizes_portrait_jpeg_filesize_Read"]: {
	__typename: "PrivateMediaDocAccessFields_sizes_portrait_jpeg_filesize_Read",
	permission: boolean
};
	["PrivateMediaDocAccessFields_sizes_portrait_jpeg_filesize_Update"]: {
	__typename: "PrivateMediaDocAccessFields_sizes_portrait_jpeg_filesize_Update",
	permission: boolean
};
	["PrivateMediaDocAccessFields_sizes_portrait_jpeg_filesize_Delete"]: {
	__typename: "PrivateMediaDocAccessFields_sizes_portrait_jpeg_filesize_Delete",
	permission: boolean
};
	["PrivateMediaDocAccessFields_sizes_portrait_jpeg_filename"]: {
	__typename: "PrivateMediaDocAccessFields_sizes_portrait_jpeg_filename",
	create?: GraphQLTypes["PrivateMediaDocAccessFields_sizes_portrait_jpeg_filename_Create"] | undefined,
	read?: GraphQLTypes["PrivateMediaDocAccessFields_sizes_portrait_jpeg_filename_Read"] | undefined,
	update?: GraphQLTypes["PrivateMediaDocAccessFields_sizes_portrait_jpeg_filename_Update"] | undefined,
	delete?: GraphQLTypes["PrivateMediaDocAccessFields_sizes_portrait_jpeg_filename_Delete"] | undefined
};
	["PrivateMediaDocAccessFields_sizes_portrait_jpeg_filename_Create"]: {
	__typename: "PrivateMediaDocAccessFields_sizes_portrait_jpeg_filename_Create",
	permission: boolean
};
	["PrivateMediaDocAccessFields_sizes_portrait_jpeg_filename_Read"]: {
	__typename: "PrivateMediaDocAccessFields_sizes_portrait_jpeg_filename_Read",
	permission: boolean
};
	["PrivateMediaDocAccessFields_sizes_portrait_jpeg_filename_Update"]: {
	__typename: "PrivateMediaDocAccessFields_sizes_portrait_jpeg_filename_Update",
	permission: boolean
};
	["PrivateMediaDocAccessFields_sizes_portrait_jpeg_filename_Delete"]: {
	__typename: "PrivateMediaDocAccessFields_sizes_portrait_jpeg_filename_Delete",
	permission: boolean
};
	["PrivateMediaDocAccessFields_sizes_hero_jpeg"]: {
	__typename: "PrivateMediaDocAccessFields_sizes_hero_jpeg",
	create?: GraphQLTypes["PrivateMediaDocAccessFields_sizes_hero_jpeg_Create"] | undefined,
	read?: GraphQLTypes["PrivateMediaDocAccessFields_sizes_hero_jpeg_Read"] | undefined,
	update?: GraphQLTypes["PrivateMediaDocAccessFields_sizes_hero_jpeg_Update"] | undefined,
	delete?: GraphQLTypes["PrivateMediaDocAccessFields_sizes_hero_jpeg_Delete"] | undefined,
	fields?: GraphQLTypes["PrivateMediaDocAccessFields_sizes_hero_jpeg_Fields"] | undefined
};
	["PrivateMediaDocAccessFields_sizes_hero_jpeg_Create"]: {
	__typename: "PrivateMediaDocAccessFields_sizes_hero_jpeg_Create",
	permission: boolean
};
	["PrivateMediaDocAccessFields_sizes_hero_jpeg_Read"]: {
	__typename: "PrivateMediaDocAccessFields_sizes_hero_jpeg_Read",
	permission: boolean
};
	["PrivateMediaDocAccessFields_sizes_hero_jpeg_Update"]: {
	__typename: "PrivateMediaDocAccessFields_sizes_hero_jpeg_Update",
	permission: boolean
};
	["PrivateMediaDocAccessFields_sizes_hero_jpeg_Delete"]: {
	__typename: "PrivateMediaDocAccessFields_sizes_hero_jpeg_Delete",
	permission: boolean
};
	["PrivateMediaDocAccessFields_sizes_hero_jpeg_Fields"]: {
	__typename: "PrivateMediaDocAccessFields_sizes_hero_jpeg_Fields",
	url?: GraphQLTypes["PrivateMediaDocAccessFields_sizes_hero_jpeg_url"] | undefined,
	width?: GraphQLTypes["PrivateMediaDocAccessFields_sizes_hero_jpeg_width"] | undefined,
	height?: GraphQLTypes["PrivateMediaDocAccessFields_sizes_hero_jpeg_height"] | undefined,
	mimeType?: GraphQLTypes["PrivateMediaDocAccessFields_sizes_hero_jpeg_mimeType"] | undefined,
	filesize?: GraphQLTypes["PrivateMediaDocAccessFields_sizes_hero_jpeg_filesize"] | undefined,
	filename?: GraphQLTypes["PrivateMediaDocAccessFields_sizes_hero_jpeg_filename"] | undefined
};
	["PrivateMediaDocAccessFields_sizes_hero_jpeg_url"]: {
	__typename: "PrivateMediaDocAccessFields_sizes_hero_jpeg_url",
	create?: GraphQLTypes["PrivateMediaDocAccessFields_sizes_hero_jpeg_url_Create"] | undefined,
	read?: GraphQLTypes["PrivateMediaDocAccessFields_sizes_hero_jpeg_url_Read"] | undefined,
	update?: GraphQLTypes["PrivateMediaDocAccessFields_sizes_hero_jpeg_url_Update"] | undefined,
	delete?: GraphQLTypes["PrivateMediaDocAccessFields_sizes_hero_jpeg_url_Delete"] | undefined
};
	["PrivateMediaDocAccessFields_sizes_hero_jpeg_url_Create"]: {
	__typename: "PrivateMediaDocAccessFields_sizes_hero_jpeg_url_Create",
	permission: boolean
};
	["PrivateMediaDocAccessFields_sizes_hero_jpeg_url_Read"]: {
	__typename: "PrivateMediaDocAccessFields_sizes_hero_jpeg_url_Read",
	permission: boolean
};
	["PrivateMediaDocAccessFields_sizes_hero_jpeg_url_Update"]: {
	__typename: "PrivateMediaDocAccessFields_sizes_hero_jpeg_url_Update",
	permission: boolean
};
	["PrivateMediaDocAccessFields_sizes_hero_jpeg_url_Delete"]: {
	__typename: "PrivateMediaDocAccessFields_sizes_hero_jpeg_url_Delete",
	permission: boolean
};
	["PrivateMediaDocAccessFields_sizes_hero_jpeg_width"]: {
	__typename: "PrivateMediaDocAccessFields_sizes_hero_jpeg_width",
	create?: GraphQLTypes["PrivateMediaDocAccessFields_sizes_hero_jpeg_width_Create"] | undefined,
	read?: GraphQLTypes["PrivateMediaDocAccessFields_sizes_hero_jpeg_width_Read"] | undefined,
	update?: GraphQLTypes["PrivateMediaDocAccessFields_sizes_hero_jpeg_width_Update"] | undefined,
	delete?: GraphQLTypes["PrivateMediaDocAccessFields_sizes_hero_jpeg_width_Delete"] | undefined
};
	["PrivateMediaDocAccessFields_sizes_hero_jpeg_width_Create"]: {
	__typename: "PrivateMediaDocAccessFields_sizes_hero_jpeg_width_Create",
	permission: boolean
};
	["PrivateMediaDocAccessFields_sizes_hero_jpeg_width_Read"]: {
	__typename: "PrivateMediaDocAccessFields_sizes_hero_jpeg_width_Read",
	permission: boolean
};
	["PrivateMediaDocAccessFields_sizes_hero_jpeg_width_Update"]: {
	__typename: "PrivateMediaDocAccessFields_sizes_hero_jpeg_width_Update",
	permission: boolean
};
	["PrivateMediaDocAccessFields_sizes_hero_jpeg_width_Delete"]: {
	__typename: "PrivateMediaDocAccessFields_sizes_hero_jpeg_width_Delete",
	permission: boolean
};
	["PrivateMediaDocAccessFields_sizes_hero_jpeg_height"]: {
	__typename: "PrivateMediaDocAccessFields_sizes_hero_jpeg_height",
	create?: GraphQLTypes["PrivateMediaDocAccessFields_sizes_hero_jpeg_height_Create"] | undefined,
	read?: GraphQLTypes["PrivateMediaDocAccessFields_sizes_hero_jpeg_height_Read"] | undefined,
	update?: GraphQLTypes["PrivateMediaDocAccessFields_sizes_hero_jpeg_height_Update"] | undefined,
	delete?: GraphQLTypes["PrivateMediaDocAccessFields_sizes_hero_jpeg_height_Delete"] | undefined
};
	["PrivateMediaDocAccessFields_sizes_hero_jpeg_height_Create"]: {
	__typename: "PrivateMediaDocAccessFields_sizes_hero_jpeg_height_Create",
	permission: boolean
};
	["PrivateMediaDocAccessFields_sizes_hero_jpeg_height_Read"]: {
	__typename: "PrivateMediaDocAccessFields_sizes_hero_jpeg_height_Read",
	permission: boolean
};
	["PrivateMediaDocAccessFields_sizes_hero_jpeg_height_Update"]: {
	__typename: "PrivateMediaDocAccessFields_sizes_hero_jpeg_height_Update",
	permission: boolean
};
	["PrivateMediaDocAccessFields_sizes_hero_jpeg_height_Delete"]: {
	__typename: "PrivateMediaDocAccessFields_sizes_hero_jpeg_height_Delete",
	permission: boolean
};
	["PrivateMediaDocAccessFields_sizes_hero_jpeg_mimeType"]: {
	__typename: "PrivateMediaDocAccessFields_sizes_hero_jpeg_mimeType",
	create?: GraphQLTypes["PrivateMediaDocAccessFields_sizes_hero_jpeg_mimeType_Create"] | undefined,
	read?: GraphQLTypes["PrivateMediaDocAccessFields_sizes_hero_jpeg_mimeType_Read"] | undefined,
	update?: GraphQLTypes["PrivateMediaDocAccessFields_sizes_hero_jpeg_mimeType_Update"] | undefined,
	delete?: GraphQLTypes["PrivateMediaDocAccessFields_sizes_hero_jpeg_mimeType_Delete"] | undefined
};
	["PrivateMediaDocAccessFields_sizes_hero_jpeg_mimeType_Create"]: {
	__typename: "PrivateMediaDocAccessFields_sizes_hero_jpeg_mimeType_Create",
	permission: boolean
};
	["PrivateMediaDocAccessFields_sizes_hero_jpeg_mimeType_Read"]: {
	__typename: "PrivateMediaDocAccessFields_sizes_hero_jpeg_mimeType_Read",
	permission: boolean
};
	["PrivateMediaDocAccessFields_sizes_hero_jpeg_mimeType_Update"]: {
	__typename: "PrivateMediaDocAccessFields_sizes_hero_jpeg_mimeType_Update",
	permission: boolean
};
	["PrivateMediaDocAccessFields_sizes_hero_jpeg_mimeType_Delete"]: {
	__typename: "PrivateMediaDocAccessFields_sizes_hero_jpeg_mimeType_Delete",
	permission: boolean
};
	["PrivateMediaDocAccessFields_sizes_hero_jpeg_filesize"]: {
	__typename: "PrivateMediaDocAccessFields_sizes_hero_jpeg_filesize",
	create?: GraphQLTypes["PrivateMediaDocAccessFields_sizes_hero_jpeg_filesize_Create"] | undefined,
	read?: GraphQLTypes["PrivateMediaDocAccessFields_sizes_hero_jpeg_filesize_Read"] | undefined,
	update?: GraphQLTypes["PrivateMediaDocAccessFields_sizes_hero_jpeg_filesize_Update"] | undefined,
	delete?: GraphQLTypes["PrivateMediaDocAccessFields_sizes_hero_jpeg_filesize_Delete"] | undefined
};
	["PrivateMediaDocAccessFields_sizes_hero_jpeg_filesize_Create"]: {
	__typename: "PrivateMediaDocAccessFields_sizes_hero_jpeg_filesize_Create",
	permission: boolean
};
	["PrivateMediaDocAccessFields_sizes_hero_jpeg_filesize_Read"]: {
	__typename: "PrivateMediaDocAccessFields_sizes_hero_jpeg_filesize_Read",
	permission: boolean
};
	["PrivateMediaDocAccessFields_sizes_hero_jpeg_filesize_Update"]: {
	__typename: "PrivateMediaDocAccessFields_sizes_hero_jpeg_filesize_Update",
	permission: boolean
};
	["PrivateMediaDocAccessFields_sizes_hero_jpeg_filesize_Delete"]: {
	__typename: "PrivateMediaDocAccessFields_sizes_hero_jpeg_filesize_Delete",
	permission: boolean
};
	["PrivateMediaDocAccessFields_sizes_hero_jpeg_filename"]: {
	__typename: "PrivateMediaDocAccessFields_sizes_hero_jpeg_filename",
	create?: GraphQLTypes["PrivateMediaDocAccessFields_sizes_hero_jpeg_filename_Create"] | undefined,
	read?: GraphQLTypes["PrivateMediaDocAccessFields_sizes_hero_jpeg_filename_Read"] | undefined,
	update?: GraphQLTypes["PrivateMediaDocAccessFields_sizes_hero_jpeg_filename_Update"] | undefined,
	delete?: GraphQLTypes["PrivateMediaDocAccessFields_sizes_hero_jpeg_filename_Delete"] | undefined
};
	["PrivateMediaDocAccessFields_sizes_hero_jpeg_filename_Create"]: {
	__typename: "PrivateMediaDocAccessFields_sizes_hero_jpeg_filename_Create",
	permission: boolean
};
	["PrivateMediaDocAccessFields_sizes_hero_jpeg_filename_Read"]: {
	__typename: "PrivateMediaDocAccessFields_sizes_hero_jpeg_filename_Read",
	permission: boolean
};
	["PrivateMediaDocAccessFields_sizes_hero_jpeg_filename_Update"]: {
	__typename: "PrivateMediaDocAccessFields_sizes_hero_jpeg_filename_Update",
	permission: boolean
};
	["PrivateMediaDocAccessFields_sizes_hero_jpeg_filename_Delete"]: {
	__typename: "PrivateMediaDocAccessFields_sizes_hero_jpeg_filename_Delete",
	permission: boolean
};
	["PrivateMediaCreateDocAccess"]: {
	__typename: "PrivateMediaCreateDocAccess",
	permission: boolean,
	where?: GraphQLTypes["JSONObject"] | undefined
};
	["PrivateMediaReadDocAccess"]: {
	__typename: "PrivateMediaReadDocAccess",
	permission: boolean,
	where?: GraphQLTypes["JSONObject"] | undefined
};
	["PrivateMediaUpdateDocAccess"]: {
	__typename: "PrivateMediaUpdateDocAccess",
	permission: boolean,
	where?: GraphQLTypes["JSONObject"] | undefined
};
	["PrivateMediaDeleteDocAccess"]: {
	__typename: "PrivateMediaDeleteDocAccess",
	permission: boolean,
	where?: GraphQLTypes["JSONObject"] | undefined
};
	["allMedia"]: {
	__typename: "allMedia",
	docs?: Array<GraphQLTypes["Media"] | undefined> | undefined,
	hasNextPage?: boolean | undefined,
	hasPrevPage?: boolean | undefined,
	limit?: number | undefined,
	nextPage?: number | undefined,
	offset?: number | undefined,
	page?: number | undefined,
	pagingCounter?: number | undefined,
	prevPage?: number | undefined,
	totalDocs?: number | undefined,
	totalPages?: number | undefined
};
	["Media_where"]: {
		alt?: GraphQLTypes["Media_alt_operator"] | undefined,
	updatedAt?: GraphQLTypes["Media_updatedAt_operator"] | undefined,
	createdAt?: GraphQLTypes["Media_createdAt_operator"] | undefined,
	url?: GraphQLTypes["Media_url_operator"] | undefined,
	filename?: GraphQLTypes["Media_filename_operator"] | undefined,
	mimeType?: GraphQLTypes["Media_mimeType_operator"] | undefined,
	filesize?: GraphQLTypes["Media_filesize_operator"] | undefined,
	width?: GraphQLTypes["Media_width_operator"] | undefined,
	height?: GraphQLTypes["Media_height_operator"] | undefined,
	focalX?: GraphQLTypes["Media_focalX_operator"] | undefined,
	focalY?: GraphQLTypes["Media_focalY_operator"] | undefined,
	sizes__small__url?: GraphQLTypes["Media_sizes__small__url_operator"] | undefined,
	sizes__small__width?: GraphQLTypes["Media_sizes__small__width_operator"] | undefined,
	sizes__small__height?: GraphQLTypes["Media_sizes__small__height_operator"] | undefined,
	sizes__small__mimeType?: GraphQLTypes["Media_sizes__small__mimeType_operator"] | undefined,
	sizes__small__filesize?: GraphQLTypes["Media_sizes__small__filesize_operator"] | undefined,
	sizes__small__filename?: GraphQLTypes["Media_sizes__small__filename_operator"] | undefined,
	sizes__medium__url?: GraphQLTypes["Media_sizes__medium__url_operator"] | undefined,
	sizes__medium__width?: GraphQLTypes["Media_sizes__medium__width_operator"] | undefined,
	sizes__medium__height?: GraphQLTypes["Media_sizes__medium__height_operator"] | undefined,
	sizes__medium__mimeType?: GraphQLTypes["Media_sizes__medium__mimeType_operator"] | undefined,
	sizes__medium__filesize?: GraphQLTypes["Media_sizes__medium__filesize_operator"] | undefined,
	sizes__medium__filename?: GraphQLTypes["Media_sizes__medium__filename_operator"] | undefined,
	sizes__large__url?: GraphQLTypes["Media_sizes__large__url_operator"] | undefined,
	sizes__large__width?: GraphQLTypes["Media_sizes__large__width_operator"] | undefined,
	sizes__large__height?: GraphQLTypes["Media_sizes__large__height_operator"] | undefined,
	sizes__large__mimeType?: GraphQLTypes["Media_sizes__large__mimeType_operator"] | undefined,
	sizes__large__filesize?: GraphQLTypes["Media_sizes__large__filesize_operator"] | undefined,
	sizes__large__filename?: GraphQLTypes["Media_sizes__large__filename_operator"] | undefined,
	sizes__small_jpeg__url?: GraphQLTypes["Media_sizes__small_jpeg__url_operator"] | undefined,
	sizes__small_jpeg__width?: GraphQLTypes["Media_sizes__small_jpeg__width_operator"] | undefined,
	sizes__small_jpeg__height?: GraphQLTypes["Media_sizes__small_jpeg__height_operator"] | undefined,
	sizes__small_jpeg__mimeType?: GraphQLTypes["Media_sizes__small_jpeg__mimeType_operator"] | undefined,
	sizes__small_jpeg__filesize?: GraphQLTypes["Media_sizes__small_jpeg__filesize_operator"] | undefined,
	sizes__small_jpeg__filename?: GraphQLTypes["Media_sizes__small_jpeg__filename_operator"] | undefined,
	sizes__medium_jpeg__url?: GraphQLTypes["Media_sizes__medium_jpeg__url_operator"] | undefined,
	sizes__medium_jpeg__width?: GraphQLTypes["Media_sizes__medium_jpeg__width_operator"] | undefined,
	sizes__medium_jpeg__height?: GraphQLTypes["Media_sizes__medium_jpeg__height_operator"] | undefined,
	sizes__medium_jpeg__mimeType?: GraphQLTypes["Media_sizes__medium_jpeg__mimeType_operator"] | undefined,
	sizes__medium_jpeg__filesize?: GraphQLTypes["Media_sizes__medium_jpeg__filesize_operator"] | undefined,
	sizes__medium_jpeg__filename?: GraphQLTypes["Media_sizes__medium_jpeg__filename_operator"] | undefined,
	sizes__large_jpeg__url?: GraphQLTypes["Media_sizes__large_jpeg__url_operator"] | undefined,
	sizes__large_jpeg__width?: GraphQLTypes["Media_sizes__large_jpeg__width_operator"] | undefined,
	sizes__large_jpeg__height?: GraphQLTypes["Media_sizes__large_jpeg__height_operator"] | undefined,
	sizes__large_jpeg__mimeType?: GraphQLTypes["Media_sizes__large_jpeg__mimeType_operator"] | undefined,
	sizes__large_jpeg__filesize?: GraphQLTypes["Media_sizes__large_jpeg__filesize_operator"] | undefined,
	sizes__large_jpeg__filename?: GraphQLTypes["Media_sizes__large_jpeg__filename_operator"] | undefined,
	id?: GraphQLTypes["Media_id_operator"] | undefined,
	AND?: Array<GraphQLTypes["Media_where_and"] | undefined> | undefined,
	OR?: Array<GraphQLTypes["Media_where_or"] | undefined> | undefined
};
	["Media_alt_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Media_updatedAt_operator"]: {
		equals?: GraphQLTypes["DateTime"] | undefined,
	not_equals?: GraphQLTypes["DateTime"] | undefined,
	greater_than_equal?: GraphQLTypes["DateTime"] | undefined,
	greater_than?: GraphQLTypes["DateTime"] | undefined,
	less_than_equal?: GraphQLTypes["DateTime"] | undefined,
	less_than?: GraphQLTypes["DateTime"] | undefined,
	like?: GraphQLTypes["DateTime"] | undefined,
	exists?: boolean | undefined
};
	["Media_createdAt_operator"]: {
		equals?: GraphQLTypes["DateTime"] | undefined,
	not_equals?: GraphQLTypes["DateTime"] | undefined,
	greater_than_equal?: GraphQLTypes["DateTime"] | undefined,
	greater_than?: GraphQLTypes["DateTime"] | undefined,
	less_than_equal?: GraphQLTypes["DateTime"] | undefined,
	less_than?: GraphQLTypes["DateTime"] | undefined,
	like?: GraphQLTypes["DateTime"] | undefined,
	exists?: boolean | undefined
};
	["Media_url_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Media_filename_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Media_mimeType_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Media_filesize_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Media_width_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Media_height_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Media_focalX_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Media_focalY_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Media_sizes__small__url_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Media_sizes__small__width_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Media_sizes__small__height_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Media_sizes__small__mimeType_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Media_sizes__small__filesize_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Media_sizes__small__filename_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Media_sizes__medium__url_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Media_sizes__medium__width_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Media_sizes__medium__height_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Media_sizes__medium__mimeType_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Media_sizes__medium__filesize_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Media_sizes__medium__filename_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Media_sizes__large__url_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Media_sizes__large__width_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Media_sizes__large__height_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Media_sizes__large__mimeType_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Media_sizes__large__filesize_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Media_sizes__large__filename_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Media_sizes__small_jpeg__url_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Media_sizes__small_jpeg__width_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Media_sizes__small_jpeg__height_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Media_sizes__small_jpeg__mimeType_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Media_sizes__small_jpeg__filesize_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Media_sizes__small_jpeg__filename_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Media_sizes__medium_jpeg__url_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Media_sizes__medium_jpeg__width_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Media_sizes__medium_jpeg__height_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Media_sizes__medium_jpeg__mimeType_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Media_sizes__medium_jpeg__filesize_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Media_sizes__medium_jpeg__filename_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Media_sizes__large_jpeg__url_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Media_sizes__large_jpeg__width_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Media_sizes__large_jpeg__height_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Media_sizes__large_jpeg__mimeType_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Media_sizes__large_jpeg__filesize_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Media_sizes__large_jpeg__filename_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Media_id_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Media_where_and"]: {
		alt?: GraphQLTypes["Media_alt_operator"] | undefined,
	updatedAt?: GraphQLTypes["Media_updatedAt_operator"] | undefined,
	createdAt?: GraphQLTypes["Media_createdAt_operator"] | undefined,
	url?: GraphQLTypes["Media_url_operator"] | undefined,
	filename?: GraphQLTypes["Media_filename_operator"] | undefined,
	mimeType?: GraphQLTypes["Media_mimeType_operator"] | undefined,
	filesize?: GraphQLTypes["Media_filesize_operator"] | undefined,
	width?: GraphQLTypes["Media_width_operator"] | undefined,
	height?: GraphQLTypes["Media_height_operator"] | undefined,
	focalX?: GraphQLTypes["Media_focalX_operator"] | undefined,
	focalY?: GraphQLTypes["Media_focalY_operator"] | undefined,
	sizes__small__url?: GraphQLTypes["Media_sizes__small__url_operator"] | undefined,
	sizes__small__width?: GraphQLTypes["Media_sizes__small__width_operator"] | undefined,
	sizes__small__height?: GraphQLTypes["Media_sizes__small__height_operator"] | undefined,
	sizes__small__mimeType?: GraphQLTypes["Media_sizes__small__mimeType_operator"] | undefined,
	sizes__small__filesize?: GraphQLTypes["Media_sizes__small__filesize_operator"] | undefined,
	sizes__small__filename?: GraphQLTypes["Media_sizes__small__filename_operator"] | undefined,
	sizes__medium__url?: GraphQLTypes["Media_sizes__medium__url_operator"] | undefined,
	sizes__medium__width?: GraphQLTypes["Media_sizes__medium__width_operator"] | undefined,
	sizes__medium__height?: GraphQLTypes["Media_sizes__medium__height_operator"] | undefined,
	sizes__medium__mimeType?: GraphQLTypes["Media_sizes__medium__mimeType_operator"] | undefined,
	sizes__medium__filesize?: GraphQLTypes["Media_sizes__medium__filesize_operator"] | undefined,
	sizes__medium__filename?: GraphQLTypes["Media_sizes__medium__filename_operator"] | undefined,
	sizes__large__url?: GraphQLTypes["Media_sizes__large__url_operator"] | undefined,
	sizes__large__width?: GraphQLTypes["Media_sizes__large__width_operator"] | undefined,
	sizes__large__height?: GraphQLTypes["Media_sizes__large__height_operator"] | undefined,
	sizes__large__mimeType?: GraphQLTypes["Media_sizes__large__mimeType_operator"] | undefined,
	sizes__large__filesize?: GraphQLTypes["Media_sizes__large__filesize_operator"] | undefined,
	sizes__large__filename?: GraphQLTypes["Media_sizes__large__filename_operator"] | undefined,
	sizes__small_jpeg__url?: GraphQLTypes["Media_sizes__small_jpeg__url_operator"] | undefined,
	sizes__small_jpeg__width?: GraphQLTypes["Media_sizes__small_jpeg__width_operator"] | undefined,
	sizes__small_jpeg__height?: GraphQLTypes["Media_sizes__small_jpeg__height_operator"] | undefined,
	sizes__small_jpeg__mimeType?: GraphQLTypes["Media_sizes__small_jpeg__mimeType_operator"] | undefined,
	sizes__small_jpeg__filesize?: GraphQLTypes["Media_sizes__small_jpeg__filesize_operator"] | undefined,
	sizes__small_jpeg__filename?: GraphQLTypes["Media_sizes__small_jpeg__filename_operator"] | undefined,
	sizes__medium_jpeg__url?: GraphQLTypes["Media_sizes__medium_jpeg__url_operator"] | undefined,
	sizes__medium_jpeg__width?: GraphQLTypes["Media_sizes__medium_jpeg__width_operator"] | undefined,
	sizes__medium_jpeg__height?: GraphQLTypes["Media_sizes__medium_jpeg__height_operator"] | undefined,
	sizes__medium_jpeg__mimeType?: GraphQLTypes["Media_sizes__medium_jpeg__mimeType_operator"] | undefined,
	sizes__medium_jpeg__filesize?: GraphQLTypes["Media_sizes__medium_jpeg__filesize_operator"] | undefined,
	sizes__medium_jpeg__filename?: GraphQLTypes["Media_sizes__medium_jpeg__filename_operator"] | undefined,
	sizes__large_jpeg__url?: GraphQLTypes["Media_sizes__large_jpeg__url_operator"] | undefined,
	sizes__large_jpeg__width?: GraphQLTypes["Media_sizes__large_jpeg__width_operator"] | undefined,
	sizes__large_jpeg__height?: GraphQLTypes["Media_sizes__large_jpeg__height_operator"] | undefined,
	sizes__large_jpeg__mimeType?: GraphQLTypes["Media_sizes__large_jpeg__mimeType_operator"] | undefined,
	sizes__large_jpeg__filesize?: GraphQLTypes["Media_sizes__large_jpeg__filesize_operator"] | undefined,
	sizes__large_jpeg__filename?: GraphQLTypes["Media_sizes__large_jpeg__filename_operator"] | undefined,
	id?: GraphQLTypes["Media_id_operator"] | undefined,
	AND?: Array<GraphQLTypes["Media_where_and"] | undefined> | undefined,
	OR?: Array<GraphQLTypes["Media_where_or"] | undefined> | undefined
};
	["Media_where_or"]: {
		alt?: GraphQLTypes["Media_alt_operator"] | undefined,
	updatedAt?: GraphQLTypes["Media_updatedAt_operator"] | undefined,
	createdAt?: GraphQLTypes["Media_createdAt_operator"] | undefined,
	url?: GraphQLTypes["Media_url_operator"] | undefined,
	filename?: GraphQLTypes["Media_filename_operator"] | undefined,
	mimeType?: GraphQLTypes["Media_mimeType_operator"] | undefined,
	filesize?: GraphQLTypes["Media_filesize_operator"] | undefined,
	width?: GraphQLTypes["Media_width_operator"] | undefined,
	height?: GraphQLTypes["Media_height_operator"] | undefined,
	focalX?: GraphQLTypes["Media_focalX_operator"] | undefined,
	focalY?: GraphQLTypes["Media_focalY_operator"] | undefined,
	sizes__small__url?: GraphQLTypes["Media_sizes__small__url_operator"] | undefined,
	sizes__small__width?: GraphQLTypes["Media_sizes__small__width_operator"] | undefined,
	sizes__small__height?: GraphQLTypes["Media_sizes__small__height_operator"] | undefined,
	sizes__small__mimeType?: GraphQLTypes["Media_sizes__small__mimeType_operator"] | undefined,
	sizes__small__filesize?: GraphQLTypes["Media_sizes__small__filesize_operator"] | undefined,
	sizes__small__filename?: GraphQLTypes["Media_sizes__small__filename_operator"] | undefined,
	sizes__medium__url?: GraphQLTypes["Media_sizes__medium__url_operator"] | undefined,
	sizes__medium__width?: GraphQLTypes["Media_sizes__medium__width_operator"] | undefined,
	sizes__medium__height?: GraphQLTypes["Media_sizes__medium__height_operator"] | undefined,
	sizes__medium__mimeType?: GraphQLTypes["Media_sizes__medium__mimeType_operator"] | undefined,
	sizes__medium__filesize?: GraphQLTypes["Media_sizes__medium__filesize_operator"] | undefined,
	sizes__medium__filename?: GraphQLTypes["Media_sizes__medium__filename_operator"] | undefined,
	sizes__large__url?: GraphQLTypes["Media_sizes__large__url_operator"] | undefined,
	sizes__large__width?: GraphQLTypes["Media_sizes__large__width_operator"] | undefined,
	sizes__large__height?: GraphQLTypes["Media_sizes__large__height_operator"] | undefined,
	sizes__large__mimeType?: GraphQLTypes["Media_sizes__large__mimeType_operator"] | undefined,
	sizes__large__filesize?: GraphQLTypes["Media_sizes__large__filesize_operator"] | undefined,
	sizes__large__filename?: GraphQLTypes["Media_sizes__large__filename_operator"] | undefined,
	sizes__small_jpeg__url?: GraphQLTypes["Media_sizes__small_jpeg__url_operator"] | undefined,
	sizes__small_jpeg__width?: GraphQLTypes["Media_sizes__small_jpeg__width_operator"] | undefined,
	sizes__small_jpeg__height?: GraphQLTypes["Media_sizes__small_jpeg__height_operator"] | undefined,
	sizes__small_jpeg__mimeType?: GraphQLTypes["Media_sizes__small_jpeg__mimeType_operator"] | undefined,
	sizes__small_jpeg__filesize?: GraphQLTypes["Media_sizes__small_jpeg__filesize_operator"] | undefined,
	sizes__small_jpeg__filename?: GraphQLTypes["Media_sizes__small_jpeg__filename_operator"] | undefined,
	sizes__medium_jpeg__url?: GraphQLTypes["Media_sizes__medium_jpeg__url_operator"] | undefined,
	sizes__medium_jpeg__width?: GraphQLTypes["Media_sizes__medium_jpeg__width_operator"] | undefined,
	sizes__medium_jpeg__height?: GraphQLTypes["Media_sizes__medium_jpeg__height_operator"] | undefined,
	sizes__medium_jpeg__mimeType?: GraphQLTypes["Media_sizes__medium_jpeg__mimeType_operator"] | undefined,
	sizes__medium_jpeg__filesize?: GraphQLTypes["Media_sizes__medium_jpeg__filesize_operator"] | undefined,
	sizes__medium_jpeg__filename?: GraphQLTypes["Media_sizes__medium_jpeg__filename_operator"] | undefined,
	sizes__large_jpeg__url?: GraphQLTypes["Media_sizes__large_jpeg__url_operator"] | undefined,
	sizes__large_jpeg__width?: GraphQLTypes["Media_sizes__large_jpeg__width_operator"] | undefined,
	sizes__large_jpeg__height?: GraphQLTypes["Media_sizes__large_jpeg__height_operator"] | undefined,
	sizes__large_jpeg__mimeType?: GraphQLTypes["Media_sizes__large_jpeg__mimeType_operator"] | undefined,
	sizes__large_jpeg__filesize?: GraphQLTypes["Media_sizes__large_jpeg__filesize_operator"] | undefined,
	sizes__large_jpeg__filename?: GraphQLTypes["Media_sizes__large_jpeg__filename_operator"] | undefined,
	id?: GraphQLTypes["Media_id_operator"] | undefined,
	AND?: Array<GraphQLTypes["Media_where_and"] | undefined> | undefined,
	OR?: Array<GraphQLTypes["Media_where_or"] | undefined> | undefined
};
	["countallMedia"]: {
	__typename: "countallMedia",
	totalDocs?: number | undefined
};
	["mediaDocAccess"]: {
	__typename: "mediaDocAccess",
	fields?: GraphQLTypes["MediaDocAccessFields"] | undefined,
	create?: GraphQLTypes["MediaCreateDocAccess"] | undefined,
	read?: GraphQLTypes["MediaReadDocAccess"] | undefined,
	update?: GraphQLTypes["MediaUpdateDocAccess"] | undefined,
	delete?: GraphQLTypes["MediaDeleteDocAccess"] | undefined
};
	["MediaDocAccessFields"]: {
	__typename: "MediaDocAccessFields",
	alt?: GraphQLTypes["MediaDocAccessFields_alt"] | undefined,
	updatedAt?: GraphQLTypes["MediaDocAccessFields_updatedAt"] | undefined,
	createdAt?: GraphQLTypes["MediaDocAccessFields_createdAt"] | undefined,
	url?: GraphQLTypes["MediaDocAccessFields_url"] | undefined,
	filename?: GraphQLTypes["MediaDocAccessFields_filename"] | undefined,
	mimeType?: GraphQLTypes["MediaDocAccessFields_mimeType"] | undefined,
	filesize?: GraphQLTypes["MediaDocAccessFields_filesize"] | undefined,
	width?: GraphQLTypes["MediaDocAccessFields_width"] | undefined,
	height?: GraphQLTypes["MediaDocAccessFields_height"] | undefined,
	focalX?: GraphQLTypes["MediaDocAccessFields_focalX"] | undefined,
	focalY?: GraphQLTypes["MediaDocAccessFields_focalY"] | undefined,
	sizes?: GraphQLTypes["MediaDocAccessFields_sizes"] | undefined
};
	["MediaDocAccessFields_alt"]: {
	__typename: "MediaDocAccessFields_alt",
	create?: GraphQLTypes["MediaDocAccessFields_alt_Create"] | undefined,
	read?: GraphQLTypes["MediaDocAccessFields_alt_Read"] | undefined,
	update?: GraphQLTypes["MediaDocAccessFields_alt_Update"] | undefined,
	delete?: GraphQLTypes["MediaDocAccessFields_alt_Delete"] | undefined
};
	["MediaDocAccessFields_alt_Create"]: {
	__typename: "MediaDocAccessFields_alt_Create",
	permission: boolean
};
	["MediaDocAccessFields_alt_Read"]: {
	__typename: "MediaDocAccessFields_alt_Read",
	permission: boolean
};
	["MediaDocAccessFields_alt_Update"]: {
	__typename: "MediaDocAccessFields_alt_Update",
	permission: boolean
};
	["MediaDocAccessFields_alt_Delete"]: {
	__typename: "MediaDocAccessFields_alt_Delete",
	permission: boolean
};
	["MediaDocAccessFields_updatedAt"]: {
	__typename: "MediaDocAccessFields_updatedAt",
	create?: GraphQLTypes["MediaDocAccessFields_updatedAt_Create"] | undefined,
	read?: GraphQLTypes["MediaDocAccessFields_updatedAt_Read"] | undefined,
	update?: GraphQLTypes["MediaDocAccessFields_updatedAt_Update"] | undefined,
	delete?: GraphQLTypes["MediaDocAccessFields_updatedAt_Delete"] | undefined
};
	["MediaDocAccessFields_updatedAt_Create"]: {
	__typename: "MediaDocAccessFields_updatedAt_Create",
	permission: boolean
};
	["MediaDocAccessFields_updatedAt_Read"]: {
	__typename: "MediaDocAccessFields_updatedAt_Read",
	permission: boolean
};
	["MediaDocAccessFields_updatedAt_Update"]: {
	__typename: "MediaDocAccessFields_updatedAt_Update",
	permission: boolean
};
	["MediaDocAccessFields_updatedAt_Delete"]: {
	__typename: "MediaDocAccessFields_updatedAt_Delete",
	permission: boolean
};
	["MediaDocAccessFields_createdAt"]: {
	__typename: "MediaDocAccessFields_createdAt",
	create?: GraphQLTypes["MediaDocAccessFields_createdAt_Create"] | undefined,
	read?: GraphQLTypes["MediaDocAccessFields_createdAt_Read"] | undefined,
	update?: GraphQLTypes["MediaDocAccessFields_createdAt_Update"] | undefined,
	delete?: GraphQLTypes["MediaDocAccessFields_createdAt_Delete"] | undefined
};
	["MediaDocAccessFields_createdAt_Create"]: {
	__typename: "MediaDocAccessFields_createdAt_Create",
	permission: boolean
};
	["MediaDocAccessFields_createdAt_Read"]: {
	__typename: "MediaDocAccessFields_createdAt_Read",
	permission: boolean
};
	["MediaDocAccessFields_createdAt_Update"]: {
	__typename: "MediaDocAccessFields_createdAt_Update",
	permission: boolean
};
	["MediaDocAccessFields_createdAt_Delete"]: {
	__typename: "MediaDocAccessFields_createdAt_Delete",
	permission: boolean
};
	["MediaDocAccessFields_url"]: {
	__typename: "MediaDocAccessFields_url",
	create?: GraphQLTypes["MediaDocAccessFields_url_Create"] | undefined,
	read?: GraphQLTypes["MediaDocAccessFields_url_Read"] | undefined,
	update?: GraphQLTypes["MediaDocAccessFields_url_Update"] | undefined,
	delete?: GraphQLTypes["MediaDocAccessFields_url_Delete"] | undefined
};
	["MediaDocAccessFields_url_Create"]: {
	__typename: "MediaDocAccessFields_url_Create",
	permission: boolean
};
	["MediaDocAccessFields_url_Read"]: {
	__typename: "MediaDocAccessFields_url_Read",
	permission: boolean
};
	["MediaDocAccessFields_url_Update"]: {
	__typename: "MediaDocAccessFields_url_Update",
	permission: boolean
};
	["MediaDocAccessFields_url_Delete"]: {
	__typename: "MediaDocAccessFields_url_Delete",
	permission: boolean
};
	["MediaDocAccessFields_filename"]: {
	__typename: "MediaDocAccessFields_filename",
	create?: GraphQLTypes["MediaDocAccessFields_filename_Create"] | undefined,
	read?: GraphQLTypes["MediaDocAccessFields_filename_Read"] | undefined,
	update?: GraphQLTypes["MediaDocAccessFields_filename_Update"] | undefined,
	delete?: GraphQLTypes["MediaDocAccessFields_filename_Delete"] | undefined
};
	["MediaDocAccessFields_filename_Create"]: {
	__typename: "MediaDocAccessFields_filename_Create",
	permission: boolean
};
	["MediaDocAccessFields_filename_Read"]: {
	__typename: "MediaDocAccessFields_filename_Read",
	permission: boolean
};
	["MediaDocAccessFields_filename_Update"]: {
	__typename: "MediaDocAccessFields_filename_Update",
	permission: boolean
};
	["MediaDocAccessFields_filename_Delete"]: {
	__typename: "MediaDocAccessFields_filename_Delete",
	permission: boolean
};
	["MediaDocAccessFields_mimeType"]: {
	__typename: "MediaDocAccessFields_mimeType",
	create?: GraphQLTypes["MediaDocAccessFields_mimeType_Create"] | undefined,
	read?: GraphQLTypes["MediaDocAccessFields_mimeType_Read"] | undefined,
	update?: GraphQLTypes["MediaDocAccessFields_mimeType_Update"] | undefined,
	delete?: GraphQLTypes["MediaDocAccessFields_mimeType_Delete"] | undefined
};
	["MediaDocAccessFields_mimeType_Create"]: {
	__typename: "MediaDocAccessFields_mimeType_Create",
	permission: boolean
};
	["MediaDocAccessFields_mimeType_Read"]: {
	__typename: "MediaDocAccessFields_mimeType_Read",
	permission: boolean
};
	["MediaDocAccessFields_mimeType_Update"]: {
	__typename: "MediaDocAccessFields_mimeType_Update",
	permission: boolean
};
	["MediaDocAccessFields_mimeType_Delete"]: {
	__typename: "MediaDocAccessFields_mimeType_Delete",
	permission: boolean
};
	["MediaDocAccessFields_filesize"]: {
	__typename: "MediaDocAccessFields_filesize",
	create?: GraphQLTypes["MediaDocAccessFields_filesize_Create"] | undefined,
	read?: GraphQLTypes["MediaDocAccessFields_filesize_Read"] | undefined,
	update?: GraphQLTypes["MediaDocAccessFields_filesize_Update"] | undefined,
	delete?: GraphQLTypes["MediaDocAccessFields_filesize_Delete"] | undefined
};
	["MediaDocAccessFields_filesize_Create"]: {
	__typename: "MediaDocAccessFields_filesize_Create",
	permission: boolean
};
	["MediaDocAccessFields_filesize_Read"]: {
	__typename: "MediaDocAccessFields_filesize_Read",
	permission: boolean
};
	["MediaDocAccessFields_filesize_Update"]: {
	__typename: "MediaDocAccessFields_filesize_Update",
	permission: boolean
};
	["MediaDocAccessFields_filesize_Delete"]: {
	__typename: "MediaDocAccessFields_filesize_Delete",
	permission: boolean
};
	["MediaDocAccessFields_width"]: {
	__typename: "MediaDocAccessFields_width",
	create?: GraphQLTypes["MediaDocAccessFields_width_Create"] | undefined,
	read?: GraphQLTypes["MediaDocAccessFields_width_Read"] | undefined,
	update?: GraphQLTypes["MediaDocAccessFields_width_Update"] | undefined,
	delete?: GraphQLTypes["MediaDocAccessFields_width_Delete"] | undefined
};
	["MediaDocAccessFields_width_Create"]: {
	__typename: "MediaDocAccessFields_width_Create",
	permission: boolean
};
	["MediaDocAccessFields_width_Read"]: {
	__typename: "MediaDocAccessFields_width_Read",
	permission: boolean
};
	["MediaDocAccessFields_width_Update"]: {
	__typename: "MediaDocAccessFields_width_Update",
	permission: boolean
};
	["MediaDocAccessFields_width_Delete"]: {
	__typename: "MediaDocAccessFields_width_Delete",
	permission: boolean
};
	["MediaDocAccessFields_height"]: {
	__typename: "MediaDocAccessFields_height",
	create?: GraphQLTypes["MediaDocAccessFields_height_Create"] | undefined,
	read?: GraphQLTypes["MediaDocAccessFields_height_Read"] | undefined,
	update?: GraphQLTypes["MediaDocAccessFields_height_Update"] | undefined,
	delete?: GraphQLTypes["MediaDocAccessFields_height_Delete"] | undefined
};
	["MediaDocAccessFields_height_Create"]: {
	__typename: "MediaDocAccessFields_height_Create",
	permission: boolean
};
	["MediaDocAccessFields_height_Read"]: {
	__typename: "MediaDocAccessFields_height_Read",
	permission: boolean
};
	["MediaDocAccessFields_height_Update"]: {
	__typename: "MediaDocAccessFields_height_Update",
	permission: boolean
};
	["MediaDocAccessFields_height_Delete"]: {
	__typename: "MediaDocAccessFields_height_Delete",
	permission: boolean
};
	["MediaDocAccessFields_focalX"]: {
	__typename: "MediaDocAccessFields_focalX",
	create?: GraphQLTypes["MediaDocAccessFields_focalX_Create"] | undefined,
	read?: GraphQLTypes["MediaDocAccessFields_focalX_Read"] | undefined,
	update?: GraphQLTypes["MediaDocAccessFields_focalX_Update"] | undefined,
	delete?: GraphQLTypes["MediaDocAccessFields_focalX_Delete"] | undefined
};
	["MediaDocAccessFields_focalX_Create"]: {
	__typename: "MediaDocAccessFields_focalX_Create",
	permission: boolean
};
	["MediaDocAccessFields_focalX_Read"]: {
	__typename: "MediaDocAccessFields_focalX_Read",
	permission: boolean
};
	["MediaDocAccessFields_focalX_Update"]: {
	__typename: "MediaDocAccessFields_focalX_Update",
	permission: boolean
};
	["MediaDocAccessFields_focalX_Delete"]: {
	__typename: "MediaDocAccessFields_focalX_Delete",
	permission: boolean
};
	["MediaDocAccessFields_focalY"]: {
	__typename: "MediaDocAccessFields_focalY",
	create?: GraphQLTypes["MediaDocAccessFields_focalY_Create"] | undefined,
	read?: GraphQLTypes["MediaDocAccessFields_focalY_Read"] | undefined,
	update?: GraphQLTypes["MediaDocAccessFields_focalY_Update"] | undefined,
	delete?: GraphQLTypes["MediaDocAccessFields_focalY_Delete"] | undefined
};
	["MediaDocAccessFields_focalY_Create"]: {
	__typename: "MediaDocAccessFields_focalY_Create",
	permission: boolean
};
	["MediaDocAccessFields_focalY_Read"]: {
	__typename: "MediaDocAccessFields_focalY_Read",
	permission: boolean
};
	["MediaDocAccessFields_focalY_Update"]: {
	__typename: "MediaDocAccessFields_focalY_Update",
	permission: boolean
};
	["MediaDocAccessFields_focalY_Delete"]: {
	__typename: "MediaDocAccessFields_focalY_Delete",
	permission: boolean
};
	["MediaDocAccessFields_sizes"]: {
	__typename: "MediaDocAccessFields_sizes",
	create?: GraphQLTypes["MediaDocAccessFields_sizes_Create"] | undefined,
	read?: GraphQLTypes["MediaDocAccessFields_sizes_Read"] | undefined,
	update?: GraphQLTypes["MediaDocAccessFields_sizes_Update"] | undefined,
	delete?: GraphQLTypes["MediaDocAccessFields_sizes_Delete"] | undefined,
	fields?: GraphQLTypes["MediaDocAccessFields_sizes_Fields"] | undefined
};
	["MediaDocAccessFields_sizes_Create"]: {
	__typename: "MediaDocAccessFields_sizes_Create",
	permission: boolean
};
	["MediaDocAccessFields_sizes_Read"]: {
	__typename: "MediaDocAccessFields_sizes_Read",
	permission: boolean
};
	["MediaDocAccessFields_sizes_Update"]: {
	__typename: "MediaDocAccessFields_sizes_Update",
	permission: boolean
};
	["MediaDocAccessFields_sizes_Delete"]: {
	__typename: "MediaDocAccessFields_sizes_Delete",
	permission: boolean
};
	["MediaDocAccessFields_sizes_Fields"]: {
	__typename: "MediaDocAccessFields_sizes_Fields",
	small?: GraphQLTypes["MediaDocAccessFields_sizes_small"] | undefined,
	medium?: GraphQLTypes["MediaDocAccessFields_sizes_medium"] | undefined,
	large?: GraphQLTypes["MediaDocAccessFields_sizes_large"] | undefined,
	small_jpeg?: GraphQLTypes["MediaDocAccessFields_sizes_small_jpeg"] | undefined,
	medium_jpeg?: GraphQLTypes["MediaDocAccessFields_sizes_medium_jpeg"] | undefined,
	large_jpeg?: GraphQLTypes["MediaDocAccessFields_sizes_large_jpeg"] | undefined
};
	["MediaDocAccessFields_sizes_small"]: {
	__typename: "MediaDocAccessFields_sizes_small",
	create?: GraphQLTypes["MediaDocAccessFields_sizes_small_Create"] | undefined,
	read?: GraphQLTypes["MediaDocAccessFields_sizes_small_Read"] | undefined,
	update?: GraphQLTypes["MediaDocAccessFields_sizes_small_Update"] | undefined,
	delete?: GraphQLTypes["MediaDocAccessFields_sizes_small_Delete"] | undefined,
	fields?: GraphQLTypes["MediaDocAccessFields_sizes_small_Fields"] | undefined
};
	["MediaDocAccessFields_sizes_small_Create"]: {
	__typename: "MediaDocAccessFields_sizes_small_Create",
	permission: boolean
};
	["MediaDocAccessFields_sizes_small_Read"]: {
	__typename: "MediaDocAccessFields_sizes_small_Read",
	permission: boolean
};
	["MediaDocAccessFields_sizes_small_Update"]: {
	__typename: "MediaDocAccessFields_sizes_small_Update",
	permission: boolean
};
	["MediaDocAccessFields_sizes_small_Delete"]: {
	__typename: "MediaDocAccessFields_sizes_small_Delete",
	permission: boolean
};
	["MediaDocAccessFields_sizes_small_Fields"]: {
	__typename: "MediaDocAccessFields_sizes_small_Fields",
	url?: GraphQLTypes["MediaDocAccessFields_sizes_small_url"] | undefined,
	width?: GraphQLTypes["MediaDocAccessFields_sizes_small_width"] | undefined,
	height?: GraphQLTypes["MediaDocAccessFields_sizes_small_height"] | undefined,
	mimeType?: GraphQLTypes["MediaDocAccessFields_sizes_small_mimeType"] | undefined,
	filesize?: GraphQLTypes["MediaDocAccessFields_sizes_small_filesize"] | undefined,
	filename?: GraphQLTypes["MediaDocAccessFields_sizes_small_filename"] | undefined
};
	["MediaDocAccessFields_sizes_small_url"]: {
	__typename: "MediaDocAccessFields_sizes_small_url",
	create?: GraphQLTypes["MediaDocAccessFields_sizes_small_url_Create"] | undefined,
	read?: GraphQLTypes["MediaDocAccessFields_sizes_small_url_Read"] | undefined,
	update?: GraphQLTypes["MediaDocAccessFields_sizes_small_url_Update"] | undefined,
	delete?: GraphQLTypes["MediaDocAccessFields_sizes_small_url_Delete"] | undefined
};
	["MediaDocAccessFields_sizes_small_url_Create"]: {
	__typename: "MediaDocAccessFields_sizes_small_url_Create",
	permission: boolean
};
	["MediaDocAccessFields_sizes_small_url_Read"]: {
	__typename: "MediaDocAccessFields_sizes_small_url_Read",
	permission: boolean
};
	["MediaDocAccessFields_sizes_small_url_Update"]: {
	__typename: "MediaDocAccessFields_sizes_small_url_Update",
	permission: boolean
};
	["MediaDocAccessFields_sizes_small_url_Delete"]: {
	__typename: "MediaDocAccessFields_sizes_small_url_Delete",
	permission: boolean
};
	["MediaDocAccessFields_sizes_small_width"]: {
	__typename: "MediaDocAccessFields_sizes_small_width",
	create?: GraphQLTypes["MediaDocAccessFields_sizes_small_width_Create"] | undefined,
	read?: GraphQLTypes["MediaDocAccessFields_sizes_small_width_Read"] | undefined,
	update?: GraphQLTypes["MediaDocAccessFields_sizes_small_width_Update"] | undefined,
	delete?: GraphQLTypes["MediaDocAccessFields_sizes_small_width_Delete"] | undefined
};
	["MediaDocAccessFields_sizes_small_width_Create"]: {
	__typename: "MediaDocAccessFields_sizes_small_width_Create",
	permission: boolean
};
	["MediaDocAccessFields_sizes_small_width_Read"]: {
	__typename: "MediaDocAccessFields_sizes_small_width_Read",
	permission: boolean
};
	["MediaDocAccessFields_sizes_small_width_Update"]: {
	__typename: "MediaDocAccessFields_sizes_small_width_Update",
	permission: boolean
};
	["MediaDocAccessFields_sizes_small_width_Delete"]: {
	__typename: "MediaDocAccessFields_sizes_small_width_Delete",
	permission: boolean
};
	["MediaDocAccessFields_sizes_small_height"]: {
	__typename: "MediaDocAccessFields_sizes_small_height",
	create?: GraphQLTypes["MediaDocAccessFields_sizes_small_height_Create"] | undefined,
	read?: GraphQLTypes["MediaDocAccessFields_sizes_small_height_Read"] | undefined,
	update?: GraphQLTypes["MediaDocAccessFields_sizes_small_height_Update"] | undefined,
	delete?: GraphQLTypes["MediaDocAccessFields_sizes_small_height_Delete"] | undefined
};
	["MediaDocAccessFields_sizes_small_height_Create"]: {
	__typename: "MediaDocAccessFields_sizes_small_height_Create",
	permission: boolean
};
	["MediaDocAccessFields_sizes_small_height_Read"]: {
	__typename: "MediaDocAccessFields_sizes_small_height_Read",
	permission: boolean
};
	["MediaDocAccessFields_sizes_small_height_Update"]: {
	__typename: "MediaDocAccessFields_sizes_small_height_Update",
	permission: boolean
};
	["MediaDocAccessFields_sizes_small_height_Delete"]: {
	__typename: "MediaDocAccessFields_sizes_small_height_Delete",
	permission: boolean
};
	["MediaDocAccessFields_sizes_small_mimeType"]: {
	__typename: "MediaDocAccessFields_sizes_small_mimeType",
	create?: GraphQLTypes["MediaDocAccessFields_sizes_small_mimeType_Create"] | undefined,
	read?: GraphQLTypes["MediaDocAccessFields_sizes_small_mimeType_Read"] | undefined,
	update?: GraphQLTypes["MediaDocAccessFields_sizes_small_mimeType_Update"] | undefined,
	delete?: GraphQLTypes["MediaDocAccessFields_sizes_small_mimeType_Delete"] | undefined
};
	["MediaDocAccessFields_sizes_small_mimeType_Create"]: {
	__typename: "MediaDocAccessFields_sizes_small_mimeType_Create",
	permission: boolean
};
	["MediaDocAccessFields_sizes_small_mimeType_Read"]: {
	__typename: "MediaDocAccessFields_sizes_small_mimeType_Read",
	permission: boolean
};
	["MediaDocAccessFields_sizes_small_mimeType_Update"]: {
	__typename: "MediaDocAccessFields_sizes_small_mimeType_Update",
	permission: boolean
};
	["MediaDocAccessFields_sizes_small_mimeType_Delete"]: {
	__typename: "MediaDocAccessFields_sizes_small_mimeType_Delete",
	permission: boolean
};
	["MediaDocAccessFields_sizes_small_filesize"]: {
	__typename: "MediaDocAccessFields_sizes_small_filesize",
	create?: GraphQLTypes["MediaDocAccessFields_sizes_small_filesize_Create"] | undefined,
	read?: GraphQLTypes["MediaDocAccessFields_sizes_small_filesize_Read"] | undefined,
	update?: GraphQLTypes["MediaDocAccessFields_sizes_small_filesize_Update"] | undefined,
	delete?: GraphQLTypes["MediaDocAccessFields_sizes_small_filesize_Delete"] | undefined
};
	["MediaDocAccessFields_sizes_small_filesize_Create"]: {
	__typename: "MediaDocAccessFields_sizes_small_filesize_Create",
	permission: boolean
};
	["MediaDocAccessFields_sizes_small_filesize_Read"]: {
	__typename: "MediaDocAccessFields_sizes_small_filesize_Read",
	permission: boolean
};
	["MediaDocAccessFields_sizes_small_filesize_Update"]: {
	__typename: "MediaDocAccessFields_sizes_small_filesize_Update",
	permission: boolean
};
	["MediaDocAccessFields_sizes_small_filesize_Delete"]: {
	__typename: "MediaDocAccessFields_sizes_small_filesize_Delete",
	permission: boolean
};
	["MediaDocAccessFields_sizes_small_filename"]: {
	__typename: "MediaDocAccessFields_sizes_small_filename",
	create?: GraphQLTypes["MediaDocAccessFields_sizes_small_filename_Create"] | undefined,
	read?: GraphQLTypes["MediaDocAccessFields_sizes_small_filename_Read"] | undefined,
	update?: GraphQLTypes["MediaDocAccessFields_sizes_small_filename_Update"] | undefined,
	delete?: GraphQLTypes["MediaDocAccessFields_sizes_small_filename_Delete"] | undefined
};
	["MediaDocAccessFields_sizes_small_filename_Create"]: {
	__typename: "MediaDocAccessFields_sizes_small_filename_Create",
	permission: boolean
};
	["MediaDocAccessFields_sizes_small_filename_Read"]: {
	__typename: "MediaDocAccessFields_sizes_small_filename_Read",
	permission: boolean
};
	["MediaDocAccessFields_sizes_small_filename_Update"]: {
	__typename: "MediaDocAccessFields_sizes_small_filename_Update",
	permission: boolean
};
	["MediaDocAccessFields_sizes_small_filename_Delete"]: {
	__typename: "MediaDocAccessFields_sizes_small_filename_Delete",
	permission: boolean
};
	["MediaDocAccessFields_sizes_medium"]: {
	__typename: "MediaDocAccessFields_sizes_medium",
	create?: GraphQLTypes["MediaDocAccessFields_sizes_medium_Create"] | undefined,
	read?: GraphQLTypes["MediaDocAccessFields_sizes_medium_Read"] | undefined,
	update?: GraphQLTypes["MediaDocAccessFields_sizes_medium_Update"] | undefined,
	delete?: GraphQLTypes["MediaDocAccessFields_sizes_medium_Delete"] | undefined,
	fields?: GraphQLTypes["MediaDocAccessFields_sizes_medium_Fields"] | undefined
};
	["MediaDocAccessFields_sizes_medium_Create"]: {
	__typename: "MediaDocAccessFields_sizes_medium_Create",
	permission: boolean
};
	["MediaDocAccessFields_sizes_medium_Read"]: {
	__typename: "MediaDocAccessFields_sizes_medium_Read",
	permission: boolean
};
	["MediaDocAccessFields_sizes_medium_Update"]: {
	__typename: "MediaDocAccessFields_sizes_medium_Update",
	permission: boolean
};
	["MediaDocAccessFields_sizes_medium_Delete"]: {
	__typename: "MediaDocAccessFields_sizes_medium_Delete",
	permission: boolean
};
	["MediaDocAccessFields_sizes_medium_Fields"]: {
	__typename: "MediaDocAccessFields_sizes_medium_Fields",
	url?: GraphQLTypes["MediaDocAccessFields_sizes_medium_url"] | undefined,
	width?: GraphQLTypes["MediaDocAccessFields_sizes_medium_width"] | undefined,
	height?: GraphQLTypes["MediaDocAccessFields_sizes_medium_height"] | undefined,
	mimeType?: GraphQLTypes["MediaDocAccessFields_sizes_medium_mimeType"] | undefined,
	filesize?: GraphQLTypes["MediaDocAccessFields_sizes_medium_filesize"] | undefined,
	filename?: GraphQLTypes["MediaDocAccessFields_sizes_medium_filename"] | undefined
};
	["MediaDocAccessFields_sizes_medium_url"]: {
	__typename: "MediaDocAccessFields_sizes_medium_url",
	create?: GraphQLTypes["MediaDocAccessFields_sizes_medium_url_Create"] | undefined,
	read?: GraphQLTypes["MediaDocAccessFields_sizes_medium_url_Read"] | undefined,
	update?: GraphQLTypes["MediaDocAccessFields_sizes_medium_url_Update"] | undefined,
	delete?: GraphQLTypes["MediaDocAccessFields_sizes_medium_url_Delete"] | undefined
};
	["MediaDocAccessFields_sizes_medium_url_Create"]: {
	__typename: "MediaDocAccessFields_sizes_medium_url_Create",
	permission: boolean
};
	["MediaDocAccessFields_sizes_medium_url_Read"]: {
	__typename: "MediaDocAccessFields_sizes_medium_url_Read",
	permission: boolean
};
	["MediaDocAccessFields_sizes_medium_url_Update"]: {
	__typename: "MediaDocAccessFields_sizes_medium_url_Update",
	permission: boolean
};
	["MediaDocAccessFields_sizes_medium_url_Delete"]: {
	__typename: "MediaDocAccessFields_sizes_medium_url_Delete",
	permission: boolean
};
	["MediaDocAccessFields_sizes_medium_width"]: {
	__typename: "MediaDocAccessFields_sizes_medium_width",
	create?: GraphQLTypes["MediaDocAccessFields_sizes_medium_width_Create"] | undefined,
	read?: GraphQLTypes["MediaDocAccessFields_sizes_medium_width_Read"] | undefined,
	update?: GraphQLTypes["MediaDocAccessFields_sizes_medium_width_Update"] | undefined,
	delete?: GraphQLTypes["MediaDocAccessFields_sizes_medium_width_Delete"] | undefined
};
	["MediaDocAccessFields_sizes_medium_width_Create"]: {
	__typename: "MediaDocAccessFields_sizes_medium_width_Create",
	permission: boolean
};
	["MediaDocAccessFields_sizes_medium_width_Read"]: {
	__typename: "MediaDocAccessFields_sizes_medium_width_Read",
	permission: boolean
};
	["MediaDocAccessFields_sizes_medium_width_Update"]: {
	__typename: "MediaDocAccessFields_sizes_medium_width_Update",
	permission: boolean
};
	["MediaDocAccessFields_sizes_medium_width_Delete"]: {
	__typename: "MediaDocAccessFields_sizes_medium_width_Delete",
	permission: boolean
};
	["MediaDocAccessFields_sizes_medium_height"]: {
	__typename: "MediaDocAccessFields_sizes_medium_height",
	create?: GraphQLTypes["MediaDocAccessFields_sizes_medium_height_Create"] | undefined,
	read?: GraphQLTypes["MediaDocAccessFields_sizes_medium_height_Read"] | undefined,
	update?: GraphQLTypes["MediaDocAccessFields_sizes_medium_height_Update"] | undefined,
	delete?: GraphQLTypes["MediaDocAccessFields_sizes_medium_height_Delete"] | undefined
};
	["MediaDocAccessFields_sizes_medium_height_Create"]: {
	__typename: "MediaDocAccessFields_sizes_medium_height_Create",
	permission: boolean
};
	["MediaDocAccessFields_sizes_medium_height_Read"]: {
	__typename: "MediaDocAccessFields_sizes_medium_height_Read",
	permission: boolean
};
	["MediaDocAccessFields_sizes_medium_height_Update"]: {
	__typename: "MediaDocAccessFields_sizes_medium_height_Update",
	permission: boolean
};
	["MediaDocAccessFields_sizes_medium_height_Delete"]: {
	__typename: "MediaDocAccessFields_sizes_medium_height_Delete",
	permission: boolean
};
	["MediaDocAccessFields_sizes_medium_mimeType"]: {
	__typename: "MediaDocAccessFields_sizes_medium_mimeType",
	create?: GraphQLTypes["MediaDocAccessFields_sizes_medium_mimeType_Create"] | undefined,
	read?: GraphQLTypes["MediaDocAccessFields_sizes_medium_mimeType_Read"] | undefined,
	update?: GraphQLTypes["MediaDocAccessFields_sizes_medium_mimeType_Update"] | undefined,
	delete?: GraphQLTypes["MediaDocAccessFields_sizes_medium_mimeType_Delete"] | undefined
};
	["MediaDocAccessFields_sizes_medium_mimeType_Create"]: {
	__typename: "MediaDocAccessFields_sizes_medium_mimeType_Create",
	permission: boolean
};
	["MediaDocAccessFields_sizes_medium_mimeType_Read"]: {
	__typename: "MediaDocAccessFields_sizes_medium_mimeType_Read",
	permission: boolean
};
	["MediaDocAccessFields_sizes_medium_mimeType_Update"]: {
	__typename: "MediaDocAccessFields_sizes_medium_mimeType_Update",
	permission: boolean
};
	["MediaDocAccessFields_sizes_medium_mimeType_Delete"]: {
	__typename: "MediaDocAccessFields_sizes_medium_mimeType_Delete",
	permission: boolean
};
	["MediaDocAccessFields_sizes_medium_filesize"]: {
	__typename: "MediaDocAccessFields_sizes_medium_filesize",
	create?: GraphQLTypes["MediaDocAccessFields_sizes_medium_filesize_Create"] | undefined,
	read?: GraphQLTypes["MediaDocAccessFields_sizes_medium_filesize_Read"] | undefined,
	update?: GraphQLTypes["MediaDocAccessFields_sizes_medium_filesize_Update"] | undefined,
	delete?: GraphQLTypes["MediaDocAccessFields_sizes_medium_filesize_Delete"] | undefined
};
	["MediaDocAccessFields_sizes_medium_filesize_Create"]: {
	__typename: "MediaDocAccessFields_sizes_medium_filesize_Create",
	permission: boolean
};
	["MediaDocAccessFields_sizes_medium_filesize_Read"]: {
	__typename: "MediaDocAccessFields_sizes_medium_filesize_Read",
	permission: boolean
};
	["MediaDocAccessFields_sizes_medium_filesize_Update"]: {
	__typename: "MediaDocAccessFields_sizes_medium_filesize_Update",
	permission: boolean
};
	["MediaDocAccessFields_sizes_medium_filesize_Delete"]: {
	__typename: "MediaDocAccessFields_sizes_medium_filesize_Delete",
	permission: boolean
};
	["MediaDocAccessFields_sizes_medium_filename"]: {
	__typename: "MediaDocAccessFields_sizes_medium_filename",
	create?: GraphQLTypes["MediaDocAccessFields_sizes_medium_filename_Create"] | undefined,
	read?: GraphQLTypes["MediaDocAccessFields_sizes_medium_filename_Read"] | undefined,
	update?: GraphQLTypes["MediaDocAccessFields_sizes_medium_filename_Update"] | undefined,
	delete?: GraphQLTypes["MediaDocAccessFields_sizes_medium_filename_Delete"] | undefined
};
	["MediaDocAccessFields_sizes_medium_filename_Create"]: {
	__typename: "MediaDocAccessFields_sizes_medium_filename_Create",
	permission: boolean
};
	["MediaDocAccessFields_sizes_medium_filename_Read"]: {
	__typename: "MediaDocAccessFields_sizes_medium_filename_Read",
	permission: boolean
};
	["MediaDocAccessFields_sizes_medium_filename_Update"]: {
	__typename: "MediaDocAccessFields_sizes_medium_filename_Update",
	permission: boolean
};
	["MediaDocAccessFields_sizes_medium_filename_Delete"]: {
	__typename: "MediaDocAccessFields_sizes_medium_filename_Delete",
	permission: boolean
};
	["MediaDocAccessFields_sizes_large"]: {
	__typename: "MediaDocAccessFields_sizes_large",
	create?: GraphQLTypes["MediaDocAccessFields_sizes_large_Create"] | undefined,
	read?: GraphQLTypes["MediaDocAccessFields_sizes_large_Read"] | undefined,
	update?: GraphQLTypes["MediaDocAccessFields_sizes_large_Update"] | undefined,
	delete?: GraphQLTypes["MediaDocAccessFields_sizes_large_Delete"] | undefined,
	fields?: GraphQLTypes["MediaDocAccessFields_sizes_large_Fields"] | undefined
};
	["MediaDocAccessFields_sizes_large_Create"]: {
	__typename: "MediaDocAccessFields_sizes_large_Create",
	permission: boolean
};
	["MediaDocAccessFields_sizes_large_Read"]: {
	__typename: "MediaDocAccessFields_sizes_large_Read",
	permission: boolean
};
	["MediaDocAccessFields_sizes_large_Update"]: {
	__typename: "MediaDocAccessFields_sizes_large_Update",
	permission: boolean
};
	["MediaDocAccessFields_sizes_large_Delete"]: {
	__typename: "MediaDocAccessFields_sizes_large_Delete",
	permission: boolean
};
	["MediaDocAccessFields_sizes_large_Fields"]: {
	__typename: "MediaDocAccessFields_sizes_large_Fields",
	url?: GraphQLTypes["MediaDocAccessFields_sizes_large_url"] | undefined,
	width?: GraphQLTypes["MediaDocAccessFields_sizes_large_width"] | undefined,
	height?: GraphQLTypes["MediaDocAccessFields_sizes_large_height"] | undefined,
	mimeType?: GraphQLTypes["MediaDocAccessFields_sizes_large_mimeType"] | undefined,
	filesize?: GraphQLTypes["MediaDocAccessFields_sizes_large_filesize"] | undefined,
	filename?: GraphQLTypes["MediaDocAccessFields_sizes_large_filename"] | undefined
};
	["MediaDocAccessFields_sizes_large_url"]: {
	__typename: "MediaDocAccessFields_sizes_large_url",
	create?: GraphQLTypes["MediaDocAccessFields_sizes_large_url_Create"] | undefined,
	read?: GraphQLTypes["MediaDocAccessFields_sizes_large_url_Read"] | undefined,
	update?: GraphQLTypes["MediaDocAccessFields_sizes_large_url_Update"] | undefined,
	delete?: GraphQLTypes["MediaDocAccessFields_sizes_large_url_Delete"] | undefined
};
	["MediaDocAccessFields_sizes_large_url_Create"]: {
	__typename: "MediaDocAccessFields_sizes_large_url_Create",
	permission: boolean
};
	["MediaDocAccessFields_sizes_large_url_Read"]: {
	__typename: "MediaDocAccessFields_sizes_large_url_Read",
	permission: boolean
};
	["MediaDocAccessFields_sizes_large_url_Update"]: {
	__typename: "MediaDocAccessFields_sizes_large_url_Update",
	permission: boolean
};
	["MediaDocAccessFields_sizes_large_url_Delete"]: {
	__typename: "MediaDocAccessFields_sizes_large_url_Delete",
	permission: boolean
};
	["MediaDocAccessFields_sizes_large_width"]: {
	__typename: "MediaDocAccessFields_sizes_large_width",
	create?: GraphQLTypes["MediaDocAccessFields_sizes_large_width_Create"] | undefined,
	read?: GraphQLTypes["MediaDocAccessFields_sizes_large_width_Read"] | undefined,
	update?: GraphQLTypes["MediaDocAccessFields_sizes_large_width_Update"] | undefined,
	delete?: GraphQLTypes["MediaDocAccessFields_sizes_large_width_Delete"] | undefined
};
	["MediaDocAccessFields_sizes_large_width_Create"]: {
	__typename: "MediaDocAccessFields_sizes_large_width_Create",
	permission: boolean
};
	["MediaDocAccessFields_sizes_large_width_Read"]: {
	__typename: "MediaDocAccessFields_sizes_large_width_Read",
	permission: boolean
};
	["MediaDocAccessFields_sizes_large_width_Update"]: {
	__typename: "MediaDocAccessFields_sizes_large_width_Update",
	permission: boolean
};
	["MediaDocAccessFields_sizes_large_width_Delete"]: {
	__typename: "MediaDocAccessFields_sizes_large_width_Delete",
	permission: boolean
};
	["MediaDocAccessFields_sizes_large_height"]: {
	__typename: "MediaDocAccessFields_sizes_large_height",
	create?: GraphQLTypes["MediaDocAccessFields_sizes_large_height_Create"] | undefined,
	read?: GraphQLTypes["MediaDocAccessFields_sizes_large_height_Read"] | undefined,
	update?: GraphQLTypes["MediaDocAccessFields_sizes_large_height_Update"] | undefined,
	delete?: GraphQLTypes["MediaDocAccessFields_sizes_large_height_Delete"] | undefined
};
	["MediaDocAccessFields_sizes_large_height_Create"]: {
	__typename: "MediaDocAccessFields_sizes_large_height_Create",
	permission: boolean
};
	["MediaDocAccessFields_sizes_large_height_Read"]: {
	__typename: "MediaDocAccessFields_sizes_large_height_Read",
	permission: boolean
};
	["MediaDocAccessFields_sizes_large_height_Update"]: {
	__typename: "MediaDocAccessFields_sizes_large_height_Update",
	permission: boolean
};
	["MediaDocAccessFields_sizes_large_height_Delete"]: {
	__typename: "MediaDocAccessFields_sizes_large_height_Delete",
	permission: boolean
};
	["MediaDocAccessFields_sizes_large_mimeType"]: {
	__typename: "MediaDocAccessFields_sizes_large_mimeType",
	create?: GraphQLTypes["MediaDocAccessFields_sizes_large_mimeType_Create"] | undefined,
	read?: GraphQLTypes["MediaDocAccessFields_sizes_large_mimeType_Read"] | undefined,
	update?: GraphQLTypes["MediaDocAccessFields_sizes_large_mimeType_Update"] | undefined,
	delete?: GraphQLTypes["MediaDocAccessFields_sizes_large_mimeType_Delete"] | undefined
};
	["MediaDocAccessFields_sizes_large_mimeType_Create"]: {
	__typename: "MediaDocAccessFields_sizes_large_mimeType_Create",
	permission: boolean
};
	["MediaDocAccessFields_sizes_large_mimeType_Read"]: {
	__typename: "MediaDocAccessFields_sizes_large_mimeType_Read",
	permission: boolean
};
	["MediaDocAccessFields_sizes_large_mimeType_Update"]: {
	__typename: "MediaDocAccessFields_sizes_large_mimeType_Update",
	permission: boolean
};
	["MediaDocAccessFields_sizes_large_mimeType_Delete"]: {
	__typename: "MediaDocAccessFields_sizes_large_mimeType_Delete",
	permission: boolean
};
	["MediaDocAccessFields_sizes_large_filesize"]: {
	__typename: "MediaDocAccessFields_sizes_large_filesize",
	create?: GraphQLTypes["MediaDocAccessFields_sizes_large_filesize_Create"] | undefined,
	read?: GraphQLTypes["MediaDocAccessFields_sizes_large_filesize_Read"] | undefined,
	update?: GraphQLTypes["MediaDocAccessFields_sizes_large_filesize_Update"] | undefined,
	delete?: GraphQLTypes["MediaDocAccessFields_sizes_large_filesize_Delete"] | undefined
};
	["MediaDocAccessFields_sizes_large_filesize_Create"]: {
	__typename: "MediaDocAccessFields_sizes_large_filesize_Create",
	permission: boolean
};
	["MediaDocAccessFields_sizes_large_filesize_Read"]: {
	__typename: "MediaDocAccessFields_sizes_large_filesize_Read",
	permission: boolean
};
	["MediaDocAccessFields_sizes_large_filesize_Update"]: {
	__typename: "MediaDocAccessFields_sizes_large_filesize_Update",
	permission: boolean
};
	["MediaDocAccessFields_sizes_large_filesize_Delete"]: {
	__typename: "MediaDocAccessFields_sizes_large_filesize_Delete",
	permission: boolean
};
	["MediaDocAccessFields_sizes_large_filename"]: {
	__typename: "MediaDocAccessFields_sizes_large_filename",
	create?: GraphQLTypes["MediaDocAccessFields_sizes_large_filename_Create"] | undefined,
	read?: GraphQLTypes["MediaDocAccessFields_sizes_large_filename_Read"] | undefined,
	update?: GraphQLTypes["MediaDocAccessFields_sizes_large_filename_Update"] | undefined,
	delete?: GraphQLTypes["MediaDocAccessFields_sizes_large_filename_Delete"] | undefined
};
	["MediaDocAccessFields_sizes_large_filename_Create"]: {
	__typename: "MediaDocAccessFields_sizes_large_filename_Create",
	permission: boolean
};
	["MediaDocAccessFields_sizes_large_filename_Read"]: {
	__typename: "MediaDocAccessFields_sizes_large_filename_Read",
	permission: boolean
};
	["MediaDocAccessFields_sizes_large_filename_Update"]: {
	__typename: "MediaDocAccessFields_sizes_large_filename_Update",
	permission: boolean
};
	["MediaDocAccessFields_sizes_large_filename_Delete"]: {
	__typename: "MediaDocAccessFields_sizes_large_filename_Delete",
	permission: boolean
};
	["MediaDocAccessFields_sizes_small_jpeg"]: {
	__typename: "MediaDocAccessFields_sizes_small_jpeg",
	create?: GraphQLTypes["MediaDocAccessFields_sizes_small_jpeg_Create"] | undefined,
	read?: GraphQLTypes["MediaDocAccessFields_sizes_small_jpeg_Read"] | undefined,
	update?: GraphQLTypes["MediaDocAccessFields_sizes_small_jpeg_Update"] | undefined,
	delete?: GraphQLTypes["MediaDocAccessFields_sizes_small_jpeg_Delete"] | undefined,
	fields?: GraphQLTypes["MediaDocAccessFields_sizes_small_jpeg_Fields"] | undefined
};
	["MediaDocAccessFields_sizes_small_jpeg_Create"]: {
	__typename: "MediaDocAccessFields_sizes_small_jpeg_Create",
	permission: boolean
};
	["MediaDocAccessFields_sizes_small_jpeg_Read"]: {
	__typename: "MediaDocAccessFields_sizes_small_jpeg_Read",
	permission: boolean
};
	["MediaDocAccessFields_sizes_small_jpeg_Update"]: {
	__typename: "MediaDocAccessFields_sizes_small_jpeg_Update",
	permission: boolean
};
	["MediaDocAccessFields_sizes_small_jpeg_Delete"]: {
	__typename: "MediaDocAccessFields_sizes_small_jpeg_Delete",
	permission: boolean
};
	["MediaDocAccessFields_sizes_small_jpeg_Fields"]: {
	__typename: "MediaDocAccessFields_sizes_small_jpeg_Fields",
	url?: GraphQLTypes["MediaDocAccessFields_sizes_small_jpeg_url"] | undefined,
	width?: GraphQLTypes["MediaDocAccessFields_sizes_small_jpeg_width"] | undefined,
	height?: GraphQLTypes["MediaDocAccessFields_sizes_small_jpeg_height"] | undefined,
	mimeType?: GraphQLTypes["MediaDocAccessFields_sizes_small_jpeg_mimeType"] | undefined,
	filesize?: GraphQLTypes["MediaDocAccessFields_sizes_small_jpeg_filesize"] | undefined,
	filename?: GraphQLTypes["MediaDocAccessFields_sizes_small_jpeg_filename"] | undefined
};
	["MediaDocAccessFields_sizes_small_jpeg_url"]: {
	__typename: "MediaDocAccessFields_sizes_small_jpeg_url",
	create?: GraphQLTypes["MediaDocAccessFields_sizes_small_jpeg_url_Create"] | undefined,
	read?: GraphQLTypes["MediaDocAccessFields_sizes_small_jpeg_url_Read"] | undefined,
	update?: GraphQLTypes["MediaDocAccessFields_sizes_small_jpeg_url_Update"] | undefined,
	delete?: GraphQLTypes["MediaDocAccessFields_sizes_small_jpeg_url_Delete"] | undefined
};
	["MediaDocAccessFields_sizes_small_jpeg_url_Create"]: {
	__typename: "MediaDocAccessFields_sizes_small_jpeg_url_Create",
	permission: boolean
};
	["MediaDocAccessFields_sizes_small_jpeg_url_Read"]: {
	__typename: "MediaDocAccessFields_sizes_small_jpeg_url_Read",
	permission: boolean
};
	["MediaDocAccessFields_sizes_small_jpeg_url_Update"]: {
	__typename: "MediaDocAccessFields_sizes_small_jpeg_url_Update",
	permission: boolean
};
	["MediaDocAccessFields_sizes_small_jpeg_url_Delete"]: {
	__typename: "MediaDocAccessFields_sizes_small_jpeg_url_Delete",
	permission: boolean
};
	["MediaDocAccessFields_sizes_small_jpeg_width"]: {
	__typename: "MediaDocAccessFields_sizes_small_jpeg_width",
	create?: GraphQLTypes["MediaDocAccessFields_sizes_small_jpeg_width_Create"] | undefined,
	read?: GraphQLTypes["MediaDocAccessFields_sizes_small_jpeg_width_Read"] | undefined,
	update?: GraphQLTypes["MediaDocAccessFields_sizes_small_jpeg_width_Update"] | undefined,
	delete?: GraphQLTypes["MediaDocAccessFields_sizes_small_jpeg_width_Delete"] | undefined
};
	["MediaDocAccessFields_sizes_small_jpeg_width_Create"]: {
	__typename: "MediaDocAccessFields_sizes_small_jpeg_width_Create",
	permission: boolean
};
	["MediaDocAccessFields_sizes_small_jpeg_width_Read"]: {
	__typename: "MediaDocAccessFields_sizes_small_jpeg_width_Read",
	permission: boolean
};
	["MediaDocAccessFields_sizes_small_jpeg_width_Update"]: {
	__typename: "MediaDocAccessFields_sizes_small_jpeg_width_Update",
	permission: boolean
};
	["MediaDocAccessFields_sizes_small_jpeg_width_Delete"]: {
	__typename: "MediaDocAccessFields_sizes_small_jpeg_width_Delete",
	permission: boolean
};
	["MediaDocAccessFields_sizes_small_jpeg_height"]: {
	__typename: "MediaDocAccessFields_sizes_small_jpeg_height",
	create?: GraphQLTypes["MediaDocAccessFields_sizes_small_jpeg_height_Create"] | undefined,
	read?: GraphQLTypes["MediaDocAccessFields_sizes_small_jpeg_height_Read"] | undefined,
	update?: GraphQLTypes["MediaDocAccessFields_sizes_small_jpeg_height_Update"] | undefined,
	delete?: GraphQLTypes["MediaDocAccessFields_sizes_small_jpeg_height_Delete"] | undefined
};
	["MediaDocAccessFields_sizes_small_jpeg_height_Create"]: {
	__typename: "MediaDocAccessFields_sizes_small_jpeg_height_Create",
	permission: boolean
};
	["MediaDocAccessFields_sizes_small_jpeg_height_Read"]: {
	__typename: "MediaDocAccessFields_sizes_small_jpeg_height_Read",
	permission: boolean
};
	["MediaDocAccessFields_sizes_small_jpeg_height_Update"]: {
	__typename: "MediaDocAccessFields_sizes_small_jpeg_height_Update",
	permission: boolean
};
	["MediaDocAccessFields_sizes_small_jpeg_height_Delete"]: {
	__typename: "MediaDocAccessFields_sizes_small_jpeg_height_Delete",
	permission: boolean
};
	["MediaDocAccessFields_sizes_small_jpeg_mimeType"]: {
	__typename: "MediaDocAccessFields_sizes_small_jpeg_mimeType",
	create?: GraphQLTypes["MediaDocAccessFields_sizes_small_jpeg_mimeType_Create"] | undefined,
	read?: GraphQLTypes["MediaDocAccessFields_sizes_small_jpeg_mimeType_Read"] | undefined,
	update?: GraphQLTypes["MediaDocAccessFields_sizes_small_jpeg_mimeType_Update"] | undefined,
	delete?: GraphQLTypes["MediaDocAccessFields_sizes_small_jpeg_mimeType_Delete"] | undefined
};
	["MediaDocAccessFields_sizes_small_jpeg_mimeType_Create"]: {
	__typename: "MediaDocAccessFields_sizes_small_jpeg_mimeType_Create",
	permission: boolean
};
	["MediaDocAccessFields_sizes_small_jpeg_mimeType_Read"]: {
	__typename: "MediaDocAccessFields_sizes_small_jpeg_mimeType_Read",
	permission: boolean
};
	["MediaDocAccessFields_sizes_small_jpeg_mimeType_Update"]: {
	__typename: "MediaDocAccessFields_sizes_small_jpeg_mimeType_Update",
	permission: boolean
};
	["MediaDocAccessFields_sizes_small_jpeg_mimeType_Delete"]: {
	__typename: "MediaDocAccessFields_sizes_small_jpeg_mimeType_Delete",
	permission: boolean
};
	["MediaDocAccessFields_sizes_small_jpeg_filesize"]: {
	__typename: "MediaDocAccessFields_sizes_small_jpeg_filesize",
	create?: GraphQLTypes["MediaDocAccessFields_sizes_small_jpeg_filesize_Create"] | undefined,
	read?: GraphQLTypes["MediaDocAccessFields_sizes_small_jpeg_filesize_Read"] | undefined,
	update?: GraphQLTypes["MediaDocAccessFields_sizes_small_jpeg_filesize_Update"] | undefined,
	delete?: GraphQLTypes["MediaDocAccessFields_sizes_small_jpeg_filesize_Delete"] | undefined
};
	["MediaDocAccessFields_sizes_small_jpeg_filesize_Create"]: {
	__typename: "MediaDocAccessFields_sizes_small_jpeg_filesize_Create",
	permission: boolean
};
	["MediaDocAccessFields_sizes_small_jpeg_filesize_Read"]: {
	__typename: "MediaDocAccessFields_sizes_small_jpeg_filesize_Read",
	permission: boolean
};
	["MediaDocAccessFields_sizes_small_jpeg_filesize_Update"]: {
	__typename: "MediaDocAccessFields_sizes_small_jpeg_filesize_Update",
	permission: boolean
};
	["MediaDocAccessFields_sizes_small_jpeg_filesize_Delete"]: {
	__typename: "MediaDocAccessFields_sizes_small_jpeg_filesize_Delete",
	permission: boolean
};
	["MediaDocAccessFields_sizes_small_jpeg_filename"]: {
	__typename: "MediaDocAccessFields_sizes_small_jpeg_filename",
	create?: GraphQLTypes["MediaDocAccessFields_sizes_small_jpeg_filename_Create"] | undefined,
	read?: GraphQLTypes["MediaDocAccessFields_sizes_small_jpeg_filename_Read"] | undefined,
	update?: GraphQLTypes["MediaDocAccessFields_sizes_small_jpeg_filename_Update"] | undefined,
	delete?: GraphQLTypes["MediaDocAccessFields_sizes_small_jpeg_filename_Delete"] | undefined
};
	["MediaDocAccessFields_sizes_small_jpeg_filename_Create"]: {
	__typename: "MediaDocAccessFields_sizes_small_jpeg_filename_Create",
	permission: boolean
};
	["MediaDocAccessFields_sizes_small_jpeg_filename_Read"]: {
	__typename: "MediaDocAccessFields_sizes_small_jpeg_filename_Read",
	permission: boolean
};
	["MediaDocAccessFields_sizes_small_jpeg_filename_Update"]: {
	__typename: "MediaDocAccessFields_sizes_small_jpeg_filename_Update",
	permission: boolean
};
	["MediaDocAccessFields_sizes_small_jpeg_filename_Delete"]: {
	__typename: "MediaDocAccessFields_sizes_small_jpeg_filename_Delete",
	permission: boolean
};
	["MediaDocAccessFields_sizes_medium_jpeg"]: {
	__typename: "MediaDocAccessFields_sizes_medium_jpeg",
	create?: GraphQLTypes["MediaDocAccessFields_sizes_medium_jpeg_Create"] | undefined,
	read?: GraphQLTypes["MediaDocAccessFields_sizes_medium_jpeg_Read"] | undefined,
	update?: GraphQLTypes["MediaDocAccessFields_sizes_medium_jpeg_Update"] | undefined,
	delete?: GraphQLTypes["MediaDocAccessFields_sizes_medium_jpeg_Delete"] | undefined,
	fields?: GraphQLTypes["MediaDocAccessFields_sizes_medium_jpeg_Fields"] | undefined
};
	["MediaDocAccessFields_sizes_medium_jpeg_Create"]: {
	__typename: "MediaDocAccessFields_sizes_medium_jpeg_Create",
	permission: boolean
};
	["MediaDocAccessFields_sizes_medium_jpeg_Read"]: {
	__typename: "MediaDocAccessFields_sizes_medium_jpeg_Read",
	permission: boolean
};
	["MediaDocAccessFields_sizes_medium_jpeg_Update"]: {
	__typename: "MediaDocAccessFields_sizes_medium_jpeg_Update",
	permission: boolean
};
	["MediaDocAccessFields_sizes_medium_jpeg_Delete"]: {
	__typename: "MediaDocAccessFields_sizes_medium_jpeg_Delete",
	permission: boolean
};
	["MediaDocAccessFields_sizes_medium_jpeg_Fields"]: {
	__typename: "MediaDocAccessFields_sizes_medium_jpeg_Fields",
	url?: GraphQLTypes["MediaDocAccessFields_sizes_medium_jpeg_url"] | undefined,
	width?: GraphQLTypes["MediaDocAccessFields_sizes_medium_jpeg_width"] | undefined,
	height?: GraphQLTypes["MediaDocAccessFields_sizes_medium_jpeg_height"] | undefined,
	mimeType?: GraphQLTypes["MediaDocAccessFields_sizes_medium_jpeg_mimeType"] | undefined,
	filesize?: GraphQLTypes["MediaDocAccessFields_sizes_medium_jpeg_filesize"] | undefined,
	filename?: GraphQLTypes["MediaDocAccessFields_sizes_medium_jpeg_filename"] | undefined
};
	["MediaDocAccessFields_sizes_medium_jpeg_url"]: {
	__typename: "MediaDocAccessFields_sizes_medium_jpeg_url",
	create?: GraphQLTypes["MediaDocAccessFields_sizes_medium_jpeg_url_Create"] | undefined,
	read?: GraphQLTypes["MediaDocAccessFields_sizes_medium_jpeg_url_Read"] | undefined,
	update?: GraphQLTypes["MediaDocAccessFields_sizes_medium_jpeg_url_Update"] | undefined,
	delete?: GraphQLTypes["MediaDocAccessFields_sizes_medium_jpeg_url_Delete"] | undefined
};
	["MediaDocAccessFields_sizes_medium_jpeg_url_Create"]: {
	__typename: "MediaDocAccessFields_sizes_medium_jpeg_url_Create",
	permission: boolean
};
	["MediaDocAccessFields_sizes_medium_jpeg_url_Read"]: {
	__typename: "MediaDocAccessFields_sizes_medium_jpeg_url_Read",
	permission: boolean
};
	["MediaDocAccessFields_sizes_medium_jpeg_url_Update"]: {
	__typename: "MediaDocAccessFields_sizes_medium_jpeg_url_Update",
	permission: boolean
};
	["MediaDocAccessFields_sizes_medium_jpeg_url_Delete"]: {
	__typename: "MediaDocAccessFields_sizes_medium_jpeg_url_Delete",
	permission: boolean
};
	["MediaDocAccessFields_sizes_medium_jpeg_width"]: {
	__typename: "MediaDocAccessFields_sizes_medium_jpeg_width",
	create?: GraphQLTypes["MediaDocAccessFields_sizes_medium_jpeg_width_Create"] | undefined,
	read?: GraphQLTypes["MediaDocAccessFields_sizes_medium_jpeg_width_Read"] | undefined,
	update?: GraphQLTypes["MediaDocAccessFields_sizes_medium_jpeg_width_Update"] | undefined,
	delete?: GraphQLTypes["MediaDocAccessFields_sizes_medium_jpeg_width_Delete"] | undefined
};
	["MediaDocAccessFields_sizes_medium_jpeg_width_Create"]: {
	__typename: "MediaDocAccessFields_sizes_medium_jpeg_width_Create",
	permission: boolean
};
	["MediaDocAccessFields_sizes_medium_jpeg_width_Read"]: {
	__typename: "MediaDocAccessFields_sizes_medium_jpeg_width_Read",
	permission: boolean
};
	["MediaDocAccessFields_sizes_medium_jpeg_width_Update"]: {
	__typename: "MediaDocAccessFields_sizes_medium_jpeg_width_Update",
	permission: boolean
};
	["MediaDocAccessFields_sizes_medium_jpeg_width_Delete"]: {
	__typename: "MediaDocAccessFields_sizes_medium_jpeg_width_Delete",
	permission: boolean
};
	["MediaDocAccessFields_sizes_medium_jpeg_height"]: {
	__typename: "MediaDocAccessFields_sizes_medium_jpeg_height",
	create?: GraphQLTypes["MediaDocAccessFields_sizes_medium_jpeg_height_Create"] | undefined,
	read?: GraphQLTypes["MediaDocAccessFields_sizes_medium_jpeg_height_Read"] | undefined,
	update?: GraphQLTypes["MediaDocAccessFields_sizes_medium_jpeg_height_Update"] | undefined,
	delete?: GraphQLTypes["MediaDocAccessFields_sizes_medium_jpeg_height_Delete"] | undefined
};
	["MediaDocAccessFields_sizes_medium_jpeg_height_Create"]: {
	__typename: "MediaDocAccessFields_sizes_medium_jpeg_height_Create",
	permission: boolean
};
	["MediaDocAccessFields_sizes_medium_jpeg_height_Read"]: {
	__typename: "MediaDocAccessFields_sizes_medium_jpeg_height_Read",
	permission: boolean
};
	["MediaDocAccessFields_sizes_medium_jpeg_height_Update"]: {
	__typename: "MediaDocAccessFields_sizes_medium_jpeg_height_Update",
	permission: boolean
};
	["MediaDocAccessFields_sizes_medium_jpeg_height_Delete"]: {
	__typename: "MediaDocAccessFields_sizes_medium_jpeg_height_Delete",
	permission: boolean
};
	["MediaDocAccessFields_sizes_medium_jpeg_mimeType"]: {
	__typename: "MediaDocAccessFields_sizes_medium_jpeg_mimeType",
	create?: GraphQLTypes["MediaDocAccessFields_sizes_medium_jpeg_mimeType_Create"] | undefined,
	read?: GraphQLTypes["MediaDocAccessFields_sizes_medium_jpeg_mimeType_Read"] | undefined,
	update?: GraphQLTypes["MediaDocAccessFields_sizes_medium_jpeg_mimeType_Update"] | undefined,
	delete?: GraphQLTypes["MediaDocAccessFields_sizes_medium_jpeg_mimeType_Delete"] | undefined
};
	["MediaDocAccessFields_sizes_medium_jpeg_mimeType_Create"]: {
	__typename: "MediaDocAccessFields_sizes_medium_jpeg_mimeType_Create",
	permission: boolean
};
	["MediaDocAccessFields_sizes_medium_jpeg_mimeType_Read"]: {
	__typename: "MediaDocAccessFields_sizes_medium_jpeg_mimeType_Read",
	permission: boolean
};
	["MediaDocAccessFields_sizes_medium_jpeg_mimeType_Update"]: {
	__typename: "MediaDocAccessFields_sizes_medium_jpeg_mimeType_Update",
	permission: boolean
};
	["MediaDocAccessFields_sizes_medium_jpeg_mimeType_Delete"]: {
	__typename: "MediaDocAccessFields_sizes_medium_jpeg_mimeType_Delete",
	permission: boolean
};
	["MediaDocAccessFields_sizes_medium_jpeg_filesize"]: {
	__typename: "MediaDocAccessFields_sizes_medium_jpeg_filesize",
	create?: GraphQLTypes["MediaDocAccessFields_sizes_medium_jpeg_filesize_Create"] | undefined,
	read?: GraphQLTypes["MediaDocAccessFields_sizes_medium_jpeg_filesize_Read"] | undefined,
	update?: GraphQLTypes["MediaDocAccessFields_sizes_medium_jpeg_filesize_Update"] | undefined,
	delete?: GraphQLTypes["MediaDocAccessFields_sizes_medium_jpeg_filesize_Delete"] | undefined
};
	["MediaDocAccessFields_sizes_medium_jpeg_filesize_Create"]: {
	__typename: "MediaDocAccessFields_sizes_medium_jpeg_filesize_Create",
	permission: boolean
};
	["MediaDocAccessFields_sizes_medium_jpeg_filesize_Read"]: {
	__typename: "MediaDocAccessFields_sizes_medium_jpeg_filesize_Read",
	permission: boolean
};
	["MediaDocAccessFields_sizes_medium_jpeg_filesize_Update"]: {
	__typename: "MediaDocAccessFields_sizes_medium_jpeg_filesize_Update",
	permission: boolean
};
	["MediaDocAccessFields_sizes_medium_jpeg_filesize_Delete"]: {
	__typename: "MediaDocAccessFields_sizes_medium_jpeg_filesize_Delete",
	permission: boolean
};
	["MediaDocAccessFields_sizes_medium_jpeg_filename"]: {
	__typename: "MediaDocAccessFields_sizes_medium_jpeg_filename",
	create?: GraphQLTypes["MediaDocAccessFields_sizes_medium_jpeg_filename_Create"] | undefined,
	read?: GraphQLTypes["MediaDocAccessFields_sizes_medium_jpeg_filename_Read"] | undefined,
	update?: GraphQLTypes["MediaDocAccessFields_sizes_medium_jpeg_filename_Update"] | undefined,
	delete?: GraphQLTypes["MediaDocAccessFields_sizes_medium_jpeg_filename_Delete"] | undefined
};
	["MediaDocAccessFields_sizes_medium_jpeg_filename_Create"]: {
	__typename: "MediaDocAccessFields_sizes_medium_jpeg_filename_Create",
	permission: boolean
};
	["MediaDocAccessFields_sizes_medium_jpeg_filename_Read"]: {
	__typename: "MediaDocAccessFields_sizes_medium_jpeg_filename_Read",
	permission: boolean
};
	["MediaDocAccessFields_sizes_medium_jpeg_filename_Update"]: {
	__typename: "MediaDocAccessFields_sizes_medium_jpeg_filename_Update",
	permission: boolean
};
	["MediaDocAccessFields_sizes_medium_jpeg_filename_Delete"]: {
	__typename: "MediaDocAccessFields_sizes_medium_jpeg_filename_Delete",
	permission: boolean
};
	["MediaDocAccessFields_sizes_large_jpeg"]: {
	__typename: "MediaDocAccessFields_sizes_large_jpeg",
	create?: GraphQLTypes["MediaDocAccessFields_sizes_large_jpeg_Create"] | undefined,
	read?: GraphQLTypes["MediaDocAccessFields_sizes_large_jpeg_Read"] | undefined,
	update?: GraphQLTypes["MediaDocAccessFields_sizes_large_jpeg_Update"] | undefined,
	delete?: GraphQLTypes["MediaDocAccessFields_sizes_large_jpeg_Delete"] | undefined,
	fields?: GraphQLTypes["MediaDocAccessFields_sizes_large_jpeg_Fields"] | undefined
};
	["MediaDocAccessFields_sizes_large_jpeg_Create"]: {
	__typename: "MediaDocAccessFields_sizes_large_jpeg_Create",
	permission: boolean
};
	["MediaDocAccessFields_sizes_large_jpeg_Read"]: {
	__typename: "MediaDocAccessFields_sizes_large_jpeg_Read",
	permission: boolean
};
	["MediaDocAccessFields_sizes_large_jpeg_Update"]: {
	__typename: "MediaDocAccessFields_sizes_large_jpeg_Update",
	permission: boolean
};
	["MediaDocAccessFields_sizes_large_jpeg_Delete"]: {
	__typename: "MediaDocAccessFields_sizes_large_jpeg_Delete",
	permission: boolean
};
	["MediaDocAccessFields_sizes_large_jpeg_Fields"]: {
	__typename: "MediaDocAccessFields_sizes_large_jpeg_Fields",
	url?: GraphQLTypes["MediaDocAccessFields_sizes_large_jpeg_url"] | undefined,
	width?: GraphQLTypes["MediaDocAccessFields_sizes_large_jpeg_width"] | undefined,
	height?: GraphQLTypes["MediaDocAccessFields_sizes_large_jpeg_height"] | undefined,
	mimeType?: GraphQLTypes["MediaDocAccessFields_sizes_large_jpeg_mimeType"] | undefined,
	filesize?: GraphQLTypes["MediaDocAccessFields_sizes_large_jpeg_filesize"] | undefined,
	filename?: GraphQLTypes["MediaDocAccessFields_sizes_large_jpeg_filename"] | undefined
};
	["MediaDocAccessFields_sizes_large_jpeg_url"]: {
	__typename: "MediaDocAccessFields_sizes_large_jpeg_url",
	create?: GraphQLTypes["MediaDocAccessFields_sizes_large_jpeg_url_Create"] | undefined,
	read?: GraphQLTypes["MediaDocAccessFields_sizes_large_jpeg_url_Read"] | undefined,
	update?: GraphQLTypes["MediaDocAccessFields_sizes_large_jpeg_url_Update"] | undefined,
	delete?: GraphQLTypes["MediaDocAccessFields_sizes_large_jpeg_url_Delete"] | undefined
};
	["MediaDocAccessFields_sizes_large_jpeg_url_Create"]: {
	__typename: "MediaDocAccessFields_sizes_large_jpeg_url_Create",
	permission: boolean
};
	["MediaDocAccessFields_sizes_large_jpeg_url_Read"]: {
	__typename: "MediaDocAccessFields_sizes_large_jpeg_url_Read",
	permission: boolean
};
	["MediaDocAccessFields_sizes_large_jpeg_url_Update"]: {
	__typename: "MediaDocAccessFields_sizes_large_jpeg_url_Update",
	permission: boolean
};
	["MediaDocAccessFields_sizes_large_jpeg_url_Delete"]: {
	__typename: "MediaDocAccessFields_sizes_large_jpeg_url_Delete",
	permission: boolean
};
	["MediaDocAccessFields_sizes_large_jpeg_width"]: {
	__typename: "MediaDocAccessFields_sizes_large_jpeg_width",
	create?: GraphQLTypes["MediaDocAccessFields_sizes_large_jpeg_width_Create"] | undefined,
	read?: GraphQLTypes["MediaDocAccessFields_sizes_large_jpeg_width_Read"] | undefined,
	update?: GraphQLTypes["MediaDocAccessFields_sizes_large_jpeg_width_Update"] | undefined,
	delete?: GraphQLTypes["MediaDocAccessFields_sizes_large_jpeg_width_Delete"] | undefined
};
	["MediaDocAccessFields_sizes_large_jpeg_width_Create"]: {
	__typename: "MediaDocAccessFields_sizes_large_jpeg_width_Create",
	permission: boolean
};
	["MediaDocAccessFields_sizes_large_jpeg_width_Read"]: {
	__typename: "MediaDocAccessFields_sizes_large_jpeg_width_Read",
	permission: boolean
};
	["MediaDocAccessFields_sizes_large_jpeg_width_Update"]: {
	__typename: "MediaDocAccessFields_sizes_large_jpeg_width_Update",
	permission: boolean
};
	["MediaDocAccessFields_sizes_large_jpeg_width_Delete"]: {
	__typename: "MediaDocAccessFields_sizes_large_jpeg_width_Delete",
	permission: boolean
};
	["MediaDocAccessFields_sizes_large_jpeg_height"]: {
	__typename: "MediaDocAccessFields_sizes_large_jpeg_height",
	create?: GraphQLTypes["MediaDocAccessFields_sizes_large_jpeg_height_Create"] | undefined,
	read?: GraphQLTypes["MediaDocAccessFields_sizes_large_jpeg_height_Read"] | undefined,
	update?: GraphQLTypes["MediaDocAccessFields_sizes_large_jpeg_height_Update"] | undefined,
	delete?: GraphQLTypes["MediaDocAccessFields_sizes_large_jpeg_height_Delete"] | undefined
};
	["MediaDocAccessFields_sizes_large_jpeg_height_Create"]: {
	__typename: "MediaDocAccessFields_sizes_large_jpeg_height_Create",
	permission: boolean
};
	["MediaDocAccessFields_sizes_large_jpeg_height_Read"]: {
	__typename: "MediaDocAccessFields_sizes_large_jpeg_height_Read",
	permission: boolean
};
	["MediaDocAccessFields_sizes_large_jpeg_height_Update"]: {
	__typename: "MediaDocAccessFields_sizes_large_jpeg_height_Update",
	permission: boolean
};
	["MediaDocAccessFields_sizes_large_jpeg_height_Delete"]: {
	__typename: "MediaDocAccessFields_sizes_large_jpeg_height_Delete",
	permission: boolean
};
	["MediaDocAccessFields_sizes_large_jpeg_mimeType"]: {
	__typename: "MediaDocAccessFields_sizes_large_jpeg_mimeType",
	create?: GraphQLTypes["MediaDocAccessFields_sizes_large_jpeg_mimeType_Create"] | undefined,
	read?: GraphQLTypes["MediaDocAccessFields_sizes_large_jpeg_mimeType_Read"] | undefined,
	update?: GraphQLTypes["MediaDocAccessFields_sizes_large_jpeg_mimeType_Update"] | undefined,
	delete?: GraphQLTypes["MediaDocAccessFields_sizes_large_jpeg_mimeType_Delete"] | undefined
};
	["MediaDocAccessFields_sizes_large_jpeg_mimeType_Create"]: {
	__typename: "MediaDocAccessFields_sizes_large_jpeg_mimeType_Create",
	permission: boolean
};
	["MediaDocAccessFields_sizes_large_jpeg_mimeType_Read"]: {
	__typename: "MediaDocAccessFields_sizes_large_jpeg_mimeType_Read",
	permission: boolean
};
	["MediaDocAccessFields_sizes_large_jpeg_mimeType_Update"]: {
	__typename: "MediaDocAccessFields_sizes_large_jpeg_mimeType_Update",
	permission: boolean
};
	["MediaDocAccessFields_sizes_large_jpeg_mimeType_Delete"]: {
	__typename: "MediaDocAccessFields_sizes_large_jpeg_mimeType_Delete",
	permission: boolean
};
	["MediaDocAccessFields_sizes_large_jpeg_filesize"]: {
	__typename: "MediaDocAccessFields_sizes_large_jpeg_filesize",
	create?: GraphQLTypes["MediaDocAccessFields_sizes_large_jpeg_filesize_Create"] | undefined,
	read?: GraphQLTypes["MediaDocAccessFields_sizes_large_jpeg_filesize_Read"] | undefined,
	update?: GraphQLTypes["MediaDocAccessFields_sizes_large_jpeg_filesize_Update"] | undefined,
	delete?: GraphQLTypes["MediaDocAccessFields_sizes_large_jpeg_filesize_Delete"] | undefined
};
	["MediaDocAccessFields_sizes_large_jpeg_filesize_Create"]: {
	__typename: "MediaDocAccessFields_sizes_large_jpeg_filesize_Create",
	permission: boolean
};
	["MediaDocAccessFields_sizes_large_jpeg_filesize_Read"]: {
	__typename: "MediaDocAccessFields_sizes_large_jpeg_filesize_Read",
	permission: boolean
};
	["MediaDocAccessFields_sizes_large_jpeg_filesize_Update"]: {
	__typename: "MediaDocAccessFields_sizes_large_jpeg_filesize_Update",
	permission: boolean
};
	["MediaDocAccessFields_sizes_large_jpeg_filesize_Delete"]: {
	__typename: "MediaDocAccessFields_sizes_large_jpeg_filesize_Delete",
	permission: boolean
};
	["MediaDocAccessFields_sizes_large_jpeg_filename"]: {
	__typename: "MediaDocAccessFields_sizes_large_jpeg_filename",
	create?: GraphQLTypes["MediaDocAccessFields_sizes_large_jpeg_filename_Create"] | undefined,
	read?: GraphQLTypes["MediaDocAccessFields_sizes_large_jpeg_filename_Read"] | undefined,
	update?: GraphQLTypes["MediaDocAccessFields_sizes_large_jpeg_filename_Update"] | undefined,
	delete?: GraphQLTypes["MediaDocAccessFields_sizes_large_jpeg_filename_Delete"] | undefined
};
	["MediaDocAccessFields_sizes_large_jpeg_filename_Create"]: {
	__typename: "MediaDocAccessFields_sizes_large_jpeg_filename_Create",
	permission: boolean
};
	["MediaDocAccessFields_sizes_large_jpeg_filename_Read"]: {
	__typename: "MediaDocAccessFields_sizes_large_jpeg_filename_Read",
	permission: boolean
};
	["MediaDocAccessFields_sizes_large_jpeg_filename_Update"]: {
	__typename: "MediaDocAccessFields_sizes_large_jpeg_filename_Update",
	permission: boolean
};
	["MediaDocAccessFields_sizes_large_jpeg_filename_Delete"]: {
	__typename: "MediaDocAccessFields_sizes_large_jpeg_filename_Delete",
	permission: boolean
};
	["MediaCreateDocAccess"]: {
	__typename: "MediaCreateDocAccess",
	permission: boolean,
	where?: GraphQLTypes["JSONObject"] | undefined
};
	["MediaReadDocAccess"]: {
	__typename: "MediaReadDocAccess",
	permission: boolean,
	where?: GraphQLTypes["JSONObject"] | undefined
};
	["MediaUpdateDocAccess"]: {
	__typename: "MediaUpdateDocAccess",
	permission: boolean,
	where?: GraphQLTypes["JSONObject"] | undefined
};
	["MediaDeleteDocAccess"]: {
	__typename: "MediaDeleteDocAccess",
	permission: boolean,
	where?: GraphQLTypes["JSONObject"] | undefined
};
	["BackupMedia"]: {
	__typename: "BackupMedia",
	id?: string | undefined,
	alt?: string | undefined,
	creator?: GraphQLTypes["User"] | undefined,
	updatedAt?: GraphQLTypes["DateTime"] | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	url?: string | undefined,
	filename?: string | undefined,
	mimeType?: string | undefined,
	filesize?: number | undefined,
	width?: number | undefined,
	height?: number | undefined,
	focalX?: number | undefined,
	focalY?: number | undefined,
	sizes?: GraphQLTypes["BackupMedia_Sizes"] | undefined
};
	["BackupMedia_Sizes"]: {
	__typename: "BackupMedia_Sizes",
	thumbnail?: GraphQLTypes["BackupMedia_Sizes_Thumbnail"] | undefined,
	sixteenByNineMedium?: GraphQLTypes["BackupMedia_Sizes_SixteenByNineMedium"] | undefined
};
	["BackupMedia_Sizes_Thumbnail"]: {
	__typename: "BackupMedia_Sizes_Thumbnail",
	url?: string | undefined,
	width?: number | undefined,
	height?: number | undefined,
	mimeType?: string | undefined,
	filesize?: number | undefined,
	filename?: string | undefined
};
	["BackupMedia_Sizes_SixteenByNineMedium"]: {
	__typename: "BackupMedia_Sizes_SixteenByNineMedium",
	url?: string | undefined,
	width?: number | undefined,
	height?: number | undefined,
	mimeType?: string | undefined,
	filesize?: number | undefined,
	filename?: string | undefined
};
	["BackupMedias"]: {
	__typename: "BackupMedias",
	docs?: Array<GraphQLTypes["BackupMedia"] | undefined> | undefined,
	hasNextPage?: boolean | undefined,
	hasPrevPage?: boolean | undefined,
	limit?: number | undefined,
	nextPage?: number | undefined,
	offset?: number | undefined,
	page?: number | undefined,
	pagingCounter?: number | undefined,
	prevPage?: number | undefined,
	totalDocs?: number | undefined,
	totalPages?: number | undefined
};
	["BackupMedia_where"]: {
		alt?: GraphQLTypes["BackupMedia_alt_operator"] | undefined,
	creator?: GraphQLTypes["BackupMedia_creator_operator"] | undefined,
	updatedAt?: GraphQLTypes["BackupMedia_updatedAt_operator"] | undefined,
	createdAt?: GraphQLTypes["BackupMedia_createdAt_operator"] | undefined,
	url?: GraphQLTypes["BackupMedia_url_operator"] | undefined,
	filename?: GraphQLTypes["BackupMedia_filename_operator"] | undefined,
	mimeType?: GraphQLTypes["BackupMedia_mimeType_operator"] | undefined,
	filesize?: GraphQLTypes["BackupMedia_filesize_operator"] | undefined,
	width?: GraphQLTypes["BackupMedia_width_operator"] | undefined,
	height?: GraphQLTypes["BackupMedia_height_operator"] | undefined,
	focalX?: GraphQLTypes["BackupMedia_focalX_operator"] | undefined,
	focalY?: GraphQLTypes["BackupMedia_focalY_operator"] | undefined,
	sizes__thumbnail__url?: GraphQLTypes["BackupMedia_sizes__thumbnail__url_operator"] | undefined,
	sizes__thumbnail__width?: GraphQLTypes["BackupMedia_sizes__thumbnail__width_operator"] | undefined,
	sizes__thumbnail__height?: GraphQLTypes["BackupMedia_sizes__thumbnail__height_operator"] | undefined,
	sizes__thumbnail__mimeType?: GraphQLTypes["BackupMedia_sizes__thumbnail__mimeType_operator"] | undefined,
	sizes__thumbnail__filesize?: GraphQLTypes["BackupMedia_sizes__thumbnail__filesize_operator"] | undefined,
	sizes__thumbnail__filename?: GraphQLTypes["BackupMedia_sizes__thumbnail__filename_operator"] | undefined,
	sizes__sixteenByNineMedium__url?: GraphQLTypes["BackupMedia_sizes__sixteenByNineMedium__url_operator"] | undefined,
	sizes__sixteenByNineMedium__width?: GraphQLTypes["BackupMedia_sizes__sixteenByNineMedium__width_operator"] | undefined,
	sizes__sixteenByNineMedium__height?: GraphQLTypes["BackupMedia_sizes__sixteenByNineMedium__height_operator"] | undefined,
	sizes__sixteenByNineMedium__mimeType?: GraphQLTypes["BackupMedia_sizes__sixteenByNineMedium__mimeType_operator"] | undefined,
	sizes__sixteenByNineMedium__filesize?: GraphQLTypes["BackupMedia_sizes__sixteenByNineMedium__filesize_operator"] | undefined,
	sizes__sixteenByNineMedium__filename?: GraphQLTypes["BackupMedia_sizes__sixteenByNineMedium__filename_operator"] | undefined,
	id?: GraphQLTypes["BackupMedia_id_operator"] | undefined,
	AND?: Array<GraphQLTypes["BackupMedia_where_and"] | undefined> | undefined,
	OR?: Array<GraphQLTypes["BackupMedia_where_or"] | undefined> | undefined
};
	["BackupMedia_alt_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["BackupMedia_creator_operator"]: {
		equals?: GraphQLTypes["JSON"] | undefined,
	not_equals?: GraphQLTypes["JSON"] | undefined,
	in?: Array<GraphQLTypes["JSON"] | undefined> | undefined,
	not_in?: Array<GraphQLTypes["JSON"] | undefined> | undefined,
	all?: Array<GraphQLTypes["JSON"] | undefined> | undefined,
	exists?: boolean | undefined
};
	["BackupMedia_updatedAt_operator"]: {
		equals?: GraphQLTypes["DateTime"] | undefined,
	not_equals?: GraphQLTypes["DateTime"] | undefined,
	greater_than_equal?: GraphQLTypes["DateTime"] | undefined,
	greater_than?: GraphQLTypes["DateTime"] | undefined,
	less_than_equal?: GraphQLTypes["DateTime"] | undefined,
	less_than?: GraphQLTypes["DateTime"] | undefined,
	like?: GraphQLTypes["DateTime"] | undefined,
	exists?: boolean | undefined
};
	["BackupMedia_createdAt_operator"]: {
		equals?: GraphQLTypes["DateTime"] | undefined,
	not_equals?: GraphQLTypes["DateTime"] | undefined,
	greater_than_equal?: GraphQLTypes["DateTime"] | undefined,
	greater_than?: GraphQLTypes["DateTime"] | undefined,
	less_than_equal?: GraphQLTypes["DateTime"] | undefined,
	less_than?: GraphQLTypes["DateTime"] | undefined,
	like?: GraphQLTypes["DateTime"] | undefined,
	exists?: boolean | undefined
};
	["BackupMedia_url_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["BackupMedia_filename_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["BackupMedia_mimeType_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["BackupMedia_filesize_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["BackupMedia_width_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["BackupMedia_height_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["BackupMedia_focalX_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["BackupMedia_focalY_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["BackupMedia_sizes__thumbnail__url_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["BackupMedia_sizes__thumbnail__width_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["BackupMedia_sizes__thumbnail__height_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["BackupMedia_sizes__thumbnail__mimeType_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["BackupMedia_sizes__thumbnail__filesize_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["BackupMedia_sizes__thumbnail__filename_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["BackupMedia_sizes__sixteenByNineMedium__url_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["BackupMedia_sizes__sixteenByNineMedium__width_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["BackupMedia_sizes__sixteenByNineMedium__height_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["BackupMedia_sizes__sixteenByNineMedium__mimeType_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["BackupMedia_sizes__sixteenByNineMedium__filesize_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["BackupMedia_sizes__sixteenByNineMedium__filename_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["BackupMedia_id_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["BackupMedia_where_and"]: {
		alt?: GraphQLTypes["BackupMedia_alt_operator"] | undefined,
	creator?: GraphQLTypes["BackupMedia_creator_operator"] | undefined,
	updatedAt?: GraphQLTypes["BackupMedia_updatedAt_operator"] | undefined,
	createdAt?: GraphQLTypes["BackupMedia_createdAt_operator"] | undefined,
	url?: GraphQLTypes["BackupMedia_url_operator"] | undefined,
	filename?: GraphQLTypes["BackupMedia_filename_operator"] | undefined,
	mimeType?: GraphQLTypes["BackupMedia_mimeType_operator"] | undefined,
	filesize?: GraphQLTypes["BackupMedia_filesize_operator"] | undefined,
	width?: GraphQLTypes["BackupMedia_width_operator"] | undefined,
	height?: GraphQLTypes["BackupMedia_height_operator"] | undefined,
	focalX?: GraphQLTypes["BackupMedia_focalX_operator"] | undefined,
	focalY?: GraphQLTypes["BackupMedia_focalY_operator"] | undefined,
	sizes__thumbnail__url?: GraphQLTypes["BackupMedia_sizes__thumbnail__url_operator"] | undefined,
	sizes__thumbnail__width?: GraphQLTypes["BackupMedia_sizes__thumbnail__width_operator"] | undefined,
	sizes__thumbnail__height?: GraphQLTypes["BackupMedia_sizes__thumbnail__height_operator"] | undefined,
	sizes__thumbnail__mimeType?: GraphQLTypes["BackupMedia_sizes__thumbnail__mimeType_operator"] | undefined,
	sizes__thumbnail__filesize?: GraphQLTypes["BackupMedia_sizes__thumbnail__filesize_operator"] | undefined,
	sizes__thumbnail__filename?: GraphQLTypes["BackupMedia_sizes__thumbnail__filename_operator"] | undefined,
	sizes__sixteenByNineMedium__url?: GraphQLTypes["BackupMedia_sizes__sixteenByNineMedium__url_operator"] | undefined,
	sizes__sixteenByNineMedium__width?: GraphQLTypes["BackupMedia_sizes__sixteenByNineMedium__width_operator"] | undefined,
	sizes__sixteenByNineMedium__height?: GraphQLTypes["BackupMedia_sizes__sixteenByNineMedium__height_operator"] | undefined,
	sizes__sixteenByNineMedium__mimeType?: GraphQLTypes["BackupMedia_sizes__sixteenByNineMedium__mimeType_operator"] | undefined,
	sizes__sixteenByNineMedium__filesize?: GraphQLTypes["BackupMedia_sizes__sixteenByNineMedium__filesize_operator"] | undefined,
	sizes__sixteenByNineMedium__filename?: GraphQLTypes["BackupMedia_sizes__sixteenByNineMedium__filename_operator"] | undefined,
	id?: GraphQLTypes["BackupMedia_id_operator"] | undefined,
	AND?: Array<GraphQLTypes["BackupMedia_where_and"] | undefined> | undefined,
	OR?: Array<GraphQLTypes["BackupMedia_where_or"] | undefined> | undefined
};
	["BackupMedia_where_or"]: {
		alt?: GraphQLTypes["BackupMedia_alt_operator"] | undefined,
	creator?: GraphQLTypes["BackupMedia_creator_operator"] | undefined,
	updatedAt?: GraphQLTypes["BackupMedia_updatedAt_operator"] | undefined,
	createdAt?: GraphQLTypes["BackupMedia_createdAt_operator"] | undefined,
	url?: GraphQLTypes["BackupMedia_url_operator"] | undefined,
	filename?: GraphQLTypes["BackupMedia_filename_operator"] | undefined,
	mimeType?: GraphQLTypes["BackupMedia_mimeType_operator"] | undefined,
	filesize?: GraphQLTypes["BackupMedia_filesize_operator"] | undefined,
	width?: GraphQLTypes["BackupMedia_width_operator"] | undefined,
	height?: GraphQLTypes["BackupMedia_height_operator"] | undefined,
	focalX?: GraphQLTypes["BackupMedia_focalX_operator"] | undefined,
	focalY?: GraphQLTypes["BackupMedia_focalY_operator"] | undefined,
	sizes__thumbnail__url?: GraphQLTypes["BackupMedia_sizes__thumbnail__url_operator"] | undefined,
	sizes__thumbnail__width?: GraphQLTypes["BackupMedia_sizes__thumbnail__width_operator"] | undefined,
	sizes__thumbnail__height?: GraphQLTypes["BackupMedia_sizes__thumbnail__height_operator"] | undefined,
	sizes__thumbnail__mimeType?: GraphQLTypes["BackupMedia_sizes__thumbnail__mimeType_operator"] | undefined,
	sizes__thumbnail__filesize?: GraphQLTypes["BackupMedia_sizes__thumbnail__filesize_operator"] | undefined,
	sizes__thumbnail__filename?: GraphQLTypes["BackupMedia_sizes__thumbnail__filename_operator"] | undefined,
	sizes__sixteenByNineMedium__url?: GraphQLTypes["BackupMedia_sizes__sixteenByNineMedium__url_operator"] | undefined,
	sizes__sixteenByNineMedium__width?: GraphQLTypes["BackupMedia_sizes__sixteenByNineMedium__width_operator"] | undefined,
	sizes__sixteenByNineMedium__height?: GraphQLTypes["BackupMedia_sizes__sixteenByNineMedium__height_operator"] | undefined,
	sizes__sixteenByNineMedium__mimeType?: GraphQLTypes["BackupMedia_sizes__sixteenByNineMedium__mimeType_operator"] | undefined,
	sizes__sixteenByNineMedium__filesize?: GraphQLTypes["BackupMedia_sizes__sixteenByNineMedium__filesize_operator"] | undefined,
	sizes__sixteenByNineMedium__filename?: GraphQLTypes["BackupMedia_sizes__sixteenByNineMedium__filename_operator"] | undefined,
	id?: GraphQLTypes["BackupMedia_id_operator"] | undefined,
	AND?: Array<GraphQLTypes["BackupMedia_where_and"] | undefined> | undefined,
	OR?: Array<GraphQLTypes["BackupMedia_where_or"] | undefined> | undefined
};
	["countBackupMedias"]: {
	__typename: "countBackupMedias",
	totalDocs?: number | undefined
};
	["backupMediaDocAccess"]: {
	__typename: "backupMediaDocAccess",
	fields?: GraphQLTypes["BackupMediaDocAccessFields"] | undefined,
	create?: GraphQLTypes["BackupMediaCreateDocAccess"] | undefined,
	read?: GraphQLTypes["BackupMediaReadDocAccess"] | undefined,
	update?: GraphQLTypes["BackupMediaUpdateDocAccess"] | undefined,
	delete?: GraphQLTypes["BackupMediaDeleteDocAccess"] | undefined
};
	["BackupMediaDocAccessFields"]: {
	__typename: "BackupMediaDocAccessFields",
	alt?: GraphQLTypes["BackupMediaDocAccessFields_alt"] | undefined,
	creator?: GraphQLTypes["BackupMediaDocAccessFields_creator"] | undefined,
	updatedAt?: GraphQLTypes["BackupMediaDocAccessFields_updatedAt"] | undefined,
	createdAt?: GraphQLTypes["BackupMediaDocAccessFields_createdAt"] | undefined,
	url?: GraphQLTypes["BackupMediaDocAccessFields_url"] | undefined,
	filename?: GraphQLTypes["BackupMediaDocAccessFields_filename"] | undefined,
	mimeType?: GraphQLTypes["BackupMediaDocAccessFields_mimeType"] | undefined,
	filesize?: GraphQLTypes["BackupMediaDocAccessFields_filesize"] | undefined,
	width?: GraphQLTypes["BackupMediaDocAccessFields_width"] | undefined,
	height?: GraphQLTypes["BackupMediaDocAccessFields_height"] | undefined,
	focalX?: GraphQLTypes["BackupMediaDocAccessFields_focalX"] | undefined,
	focalY?: GraphQLTypes["BackupMediaDocAccessFields_focalY"] | undefined,
	sizes?: GraphQLTypes["BackupMediaDocAccessFields_sizes"] | undefined
};
	["BackupMediaDocAccessFields_alt"]: {
	__typename: "BackupMediaDocAccessFields_alt",
	create?: GraphQLTypes["BackupMediaDocAccessFields_alt_Create"] | undefined,
	read?: GraphQLTypes["BackupMediaDocAccessFields_alt_Read"] | undefined,
	update?: GraphQLTypes["BackupMediaDocAccessFields_alt_Update"] | undefined,
	delete?: GraphQLTypes["BackupMediaDocAccessFields_alt_Delete"] | undefined
};
	["BackupMediaDocAccessFields_alt_Create"]: {
	__typename: "BackupMediaDocAccessFields_alt_Create",
	permission: boolean
};
	["BackupMediaDocAccessFields_alt_Read"]: {
	__typename: "BackupMediaDocAccessFields_alt_Read",
	permission: boolean
};
	["BackupMediaDocAccessFields_alt_Update"]: {
	__typename: "BackupMediaDocAccessFields_alt_Update",
	permission: boolean
};
	["BackupMediaDocAccessFields_alt_Delete"]: {
	__typename: "BackupMediaDocAccessFields_alt_Delete",
	permission: boolean
};
	["BackupMediaDocAccessFields_creator"]: {
	__typename: "BackupMediaDocAccessFields_creator",
	create?: GraphQLTypes["BackupMediaDocAccessFields_creator_Create"] | undefined,
	read?: GraphQLTypes["BackupMediaDocAccessFields_creator_Read"] | undefined,
	update?: GraphQLTypes["BackupMediaDocAccessFields_creator_Update"] | undefined,
	delete?: GraphQLTypes["BackupMediaDocAccessFields_creator_Delete"] | undefined
};
	["BackupMediaDocAccessFields_creator_Create"]: {
	__typename: "BackupMediaDocAccessFields_creator_Create",
	permission: boolean
};
	["BackupMediaDocAccessFields_creator_Read"]: {
	__typename: "BackupMediaDocAccessFields_creator_Read",
	permission: boolean
};
	["BackupMediaDocAccessFields_creator_Update"]: {
	__typename: "BackupMediaDocAccessFields_creator_Update",
	permission: boolean
};
	["BackupMediaDocAccessFields_creator_Delete"]: {
	__typename: "BackupMediaDocAccessFields_creator_Delete",
	permission: boolean
};
	["BackupMediaDocAccessFields_updatedAt"]: {
	__typename: "BackupMediaDocAccessFields_updatedAt",
	create?: GraphQLTypes["BackupMediaDocAccessFields_updatedAt_Create"] | undefined,
	read?: GraphQLTypes["BackupMediaDocAccessFields_updatedAt_Read"] | undefined,
	update?: GraphQLTypes["BackupMediaDocAccessFields_updatedAt_Update"] | undefined,
	delete?: GraphQLTypes["BackupMediaDocAccessFields_updatedAt_Delete"] | undefined
};
	["BackupMediaDocAccessFields_updatedAt_Create"]: {
	__typename: "BackupMediaDocAccessFields_updatedAt_Create",
	permission: boolean
};
	["BackupMediaDocAccessFields_updatedAt_Read"]: {
	__typename: "BackupMediaDocAccessFields_updatedAt_Read",
	permission: boolean
};
	["BackupMediaDocAccessFields_updatedAt_Update"]: {
	__typename: "BackupMediaDocAccessFields_updatedAt_Update",
	permission: boolean
};
	["BackupMediaDocAccessFields_updatedAt_Delete"]: {
	__typename: "BackupMediaDocAccessFields_updatedAt_Delete",
	permission: boolean
};
	["BackupMediaDocAccessFields_createdAt"]: {
	__typename: "BackupMediaDocAccessFields_createdAt",
	create?: GraphQLTypes["BackupMediaDocAccessFields_createdAt_Create"] | undefined,
	read?: GraphQLTypes["BackupMediaDocAccessFields_createdAt_Read"] | undefined,
	update?: GraphQLTypes["BackupMediaDocAccessFields_createdAt_Update"] | undefined,
	delete?: GraphQLTypes["BackupMediaDocAccessFields_createdAt_Delete"] | undefined
};
	["BackupMediaDocAccessFields_createdAt_Create"]: {
	__typename: "BackupMediaDocAccessFields_createdAt_Create",
	permission: boolean
};
	["BackupMediaDocAccessFields_createdAt_Read"]: {
	__typename: "BackupMediaDocAccessFields_createdAt_Read",
	permission: boolean
};
	["BackupMediaDocAccessFields_createdAt_Update"]: {
	__typename: "BackupMediaDocAccessFields_createdAt_Update",
	permission: boolean
};
	["BackupMediaDocAccessFields_createdAt_Delete"]: {
	__typename: "BackupMediaDocAccessFields_createdAt_Delete",
	permission: boolean
};
	["BackupMediaDocAccessFields_url"]: {
	__typename: "BackupMediaDocAccessFields_url",
	create?: GraphQLTypes["BackupMediaDocAccessFields_url_Create"] | undefined,
	read?: GraphQLTypes["BackupMediaDocAccessFields_url_Read"] | undefined,
	update?: GraphQLTypes["BackupMediaDocAccessFields_url_Update"] | undefined,
	delete?: GraphQLTypes["BackupMediaDocAccessFields_url_Delete"] | undefined
};
	["BackupMediaDocAccessFields_url_Create"]: {
	__typename: "BackupMediaDocAccessFields_url_Create",
	permission: boolean
};
	["BackupMediaDocAccessFields_url_Read"]: {
	__typename: "BackupMediaDocAccessFields_url_Read",
	permission: boolean
};
	["BackupMediaDocAccessFields_url_Update"]: {
	__typename: "BackupMediaDocAccessFields_url_Update",
	permission: boolean
};
	["BackupMediaDocAccessFields_url_Delete"]: {
	__typename: "BackupMediaDocAccessFields_url_Delete",
	permission: boolean
};
	["BackupMediaDocAccessFields_filename"]: {
	__typename: "BackupMediaDocAccessFields_filename",
	create?: GraphQLTypes["BackupMediaDocAccessFields_filename_Create"] | undefined,
	read?: GraphQLTypes["BackupMediaDocAccessFields_filename_Read"] | undefined,
	update?: GraphQLTypes["BackupMediaDocAccessFields_filename_Update"] | undefined,
	delete?: GraphQLTypes["BackupMediaDocAccessFields_filename_Delete"] | undefined
};
	["BackupMediaDocAccessFields_filename_Create"]: {
	__typename: "BackupMediaDocAccessFields_filename_Create",
	permission: boolean
};
	["BackupMediaDocAccessFields_filename_Read"]: {
	__typename: "BackupMediaDocAccessFields_filename_Read",
	permission: boolean
};
	["BackupMediaDocAccessFields_filename_Update"]: {
	__typename: "BackupMediaDocAccessFields_filename_Update",
	permission: boolean
};
	["BackupMediaDocAccessFields_filename_Delete"]: {
	__typename: "BackupMediaDocAccessFields_filename_Delete",
	permission: boolean
};
	["BackupMediaDocAccessFields_mimeType"]: {
	__typename: "BackupMediaDocAccessFields_mimeType",
	create?: GraphQLTypes["BackupMediaDocAccessFields_mimeType_Create"] | undefined,
	read?: GraphQLTypes["BackupMediaDocAccessFields_mimeType_Read"] | undefined,
	update?: GraphQLTypes["BackupMediaDocAccessFields_mimeType_Update"] | undefined,
	delete?: GraphQLTypes["BackupMediaDocAccessFields_mimeType_Delete"] | undefined
};
	["BackupMediaDocAccessFields_mimeType_Create"]: {
	__typename: "BackupMediaDocAccessFields_mimeType_Create",
	permission: boolean
};
	["BackupMediaDocAccessFields_mimeType_Read"]: {
	__typename: "BackupMediaDocAccessFields_mimeType_Read",
	permission: boolean
};
	["BackupMediaDocAccessFields_mimeType_Update"]: {
	__typename: "BackupMediaDocAccessFields_mimeType_Update",
	permission: boolean
};
	["BackupMediaDocAccessFields_mimeType_Delete"]: {
	__typename: "BackupMediaDocAccessFields_mimeType_Delete",
	permission: boolean
};
	["BackupMediaDocAccessFields_filesize"]: {
	__typename: "BackupMediaDocAccessFields_filesize",
	create?: GraphQLTypes["BackupMediaDocAccessFields_filesize_Create"] | undefined,
	read?: GraphQLTypes["BackupMediaDocAccessFields_filesize_Read"] | undefined,
	update?: GraphQLTypes["BackupMediaDocAccessFields_filesize_Update"] | undefined,
	delete?: GraphQLTypes["BackupMediaDocAccessFields_filesize_Delete"] | undefined
};
	["BackupMediaDocAccessFields_filesize_Create"]: {
	__typename: "BackupMediaDocAccessFields_filesize_Create",
	permission: boolean
};
	["BackupMediaDocAccessFields_filesize_Read"]: {
	__typename: "BackupMediaDocAccessFields_filesize_Read",
	permission: boolean
};
	["BackupMediaDocAccessFields_filesize_Update"]: {
	__typename: "BackupMediaDocAccessFields_filesize_Update",
	permission: boolean
};
	["BackupMediaDocAccessFields_filesize_Delete"]: {
	__typename: "BackupMediaDocAccessFields_filesize_Delete",
	permission: boolean
};
	["BackupMediaDocAccessFields_width"]: {
	__typename: "BackupMediaDocAccessFields_width",
	create?: GraphQLTypes["BackupMediaDocAccessFields_width_Create"] | undefined,
	read?: GraphQLTypes["BackupMediaDocAccessFields_width_Read"] | undefined,
	update?: GraphQLTypes["BackupMediaDocAccessFields_width_Update"] | undefined,
	delete?: GraphQLTypes["BackupMediaDocAccessFields_width_Delete"] | undefined
};
	["BackupMediaDocAccessFields_width_Create"]: {
	__typename: "BackupMediaDocAccessFields_width_Create",
	permission: boolean
};
	["BackupMediaDocAccessFields_width_Read"]: {
	__typename: "BackupMediaDocAccessFields_width_Read",
	permission: boolean
};
	["BackupMediaDocAccessFields_width_Update"]: {
	__typename: "BackupMediaDocAccessFields_width_Update",
	permission: boolean
};
	["BackupMediaDocAccessFields_width_Delete"]: {
	__typename: "BackupMediaDocAccessFields_width_Delete",
	permission: boolean
};
	["BackupMediaDocAccessFields_height"]: {
	__typename: "BackupMediaDocAccessFields_height",
	create?: GraphQLTypes["BackupMediaDocAccessFields_height_Create"] | undefined,
	read?: GraphQLTypes["BackupMediaDocAccessFields_height_Read"] | undefined,
	update?: GraphQLTypes["BackupMediaDocAccessFields_height_Update"] | undefined,
	delete?: GraphQLTypes["BackupMediaDocAccessFields_height_Delete"] | undefined
};
	["BackupMediaDocAccessFields_height_Create"]: {
	__typename: "BackupMediaDocAccessFields_height_Create",
	permission: boolean
};
	["BackupMediaDocAccessFields_height_Read"]: {
	__typename: "BackupMediaDocAccessFields_height_Read",
	permission: boolean
};
	["BackupMediaDocAccessFields_height_Update"]: {
	__typename: "BackupMediaDocAccessFields_height_Update",
	permission: boolean
};
	["BackupMediaDocAccessFields_height_Delete"]: {
	__typename: "BackupMediaDocAccessFields_height_Delete",
	permission: boolean
};
	["BackupMediaDocAccessFields_focalX"]: {
	__typename: "BackupMediaDocAccessFields_focalX",
	create?: GraphQLTypes["BackupMediaDocAccessFields_focalX_Create"] | undefined,
	read?: GraphQLTypes["BackupMediaDocAccessFields_focalX_Read"] | undefined,
	update?: GraphQLTypes["BackupMediaDocAccessFields_focalX_Update"] | undefined,
	delete?: GraphQLTypes["BackupMediaDocAccessFields_focalX_Delete"] | undefined
};
	["BackupMediaDocAccessFields_focalX_Create"]: {
	__typename: "BackupMediaDocAccessFields_focalX_Create",
	permission: boolean
};
	["BackupMediaDocAccessFields_focalX_Read"]: {
	__typename: "BackupMediaDocAccessFields_focalX_Read",
	permission: boolean
};
	["BackupMediaDocAccessFields_focalX_Update"]: {
	__typename: "BackupMediaDocAccessFields_focalX_Update",
	permission: boolean
};
	["BackupMediaDocAccessFields_focalX_Delete"]: {
	__typename: "BackupMediaDocAccessFields_focalX_Delete",
	permission: boolean
};
	["BackupMediaDocAccessFields_focalY"]: {
	__typename: "BackupMediaDocAccessFields_focalY",
	create?: GraphQLTypes["BackupMediaDocAccessFields_focalY_Create"] | undefined,
	read?: GraphQLTypes["BackupMediaDocAccessFields_focalY_Read"] | undefined,
	update?: GraphQLTypes["BackupMediaDocAccessFields_focalY_Update"] | undefined,
	delete?: GraphQLTypes["BackupMediaDocAccessFields_focalY_Delete"] | undefined
};
	["BackupMediaDocAccessFields_focalY_Create"]: {
	__typename: "BackupMediaDocAccessFields_focalY_Create",
	permission: boolean
};
	["BackupMediaDocAccessFields_focalY_Read"]: {
	__typename: "BackupMediaDocAccessFields_focalY_Read",
	permission: boolean
};
	["BackupMediaDocAccessFields_focalY_Update"]: {
	__typename: "BackupMediaDocAccessFields_focalY_Update",
	permission: boolean
};
	["BackupMediaDocAccessFields_focalY_Delete"]: {
	__typename: "BackupMediaDocAccessFields_focalY_Delete",
	permission: boolean
};
	["BackupMediaDocAccessFields_sizes"]: {
	__typename: "BackupMediaDocAccessFields_sizes",
	create?: GraphQLTypes["BackupMediaDocAccessFields_sizes_Create"] | undefined,
	read?: GraphQLTypes["BackupMediaDocAccessFields_sizes_Read"] | undefined,
	update?: GraphQLTypes["BackupMediaDocAccessFields_sizes_Update"] | undefined,
	delete?: GraphQLTypes["BackupMediaDocAccessFields_sizes_Delete"] | undefined,
	fields?: GraphQLTypes["BackupMediaDocAccessFields_sizes_Fields"] | undefined
};
	["BackupMediaDocAccessFields_sizes_Create"]: {
	__typename: "BackupMediaDocAccessFields_sizes_Create",
	permission: boolean
};
	["BackupMediaDocAccessFields_sizes_Read"]: {
	__typename: "BackupMediaDocAccessFields_sizes_Read",
	permission: boolean
};
	["BackupMediaDocAccessFields_sizes_Update"]: {
	__typename: "BackupMediaDocAccessFields_sizes_Update",
	permission: boolean
};
	["BackupMediaDocAccessFields_sizes_Delete"]: {
	__typename: "BackupMediaDocAccessFields_sizes_Delete",
	permission: boolean
};
	["BackupMediaDocAccessFields_sizes_Fields"]: {
	__typename: "BackupMediaDocAccessFields_sizes_Fields",
	thumbnail?: GraphQLTypes["BackupMediaDocAccessFields_sizes_thumbnail"] | undefined,
	sixteenByNineMedium?: GraphQLTypes["BackupMediaDocAccessFields_sizes_sixteenByNineMedium"] | undefined
};
	["BackupMediaDocAccessFields_sizes_thumbnail"]: {
	__typename: "BackupMediaDocAccessFields_sizes_thumbnail",
	create?: GraphQLTypes["BackupMediaDocAccessFields_sizes_thumbnail_Create"] | undefined,
	read?: GraphQLTypes["BackupMediaDocAccessFields_sizes_thumbnail_Read"] | undefined,
	update?: GraphQLTypes["BackupMediaDocAccessFields_sizes_thumbnail_Update"] | undefined,
	delete?: GraphQLTypes["BackupMediaDocAccessFields_sizes_thumbnail_Delete"] | undefined,
	fields?: GraphQLTypes["BackupMediaDocAccessFields_sizes_thumbnail_Fields"] | undefined
};
	["BackupMediaDocAccessFields_sizes_thumbnail_Create"]: {
	__typename: "BackupMediaDocAccessFields_sizes_thumbnail_Create",
	permission: boolean
};
	["BackupMediaDocAccessFields_sizes_thumbnail_Read"]: {
	__typename: "BackupMediaDocAccessFields_sizes_thumbnail_Read",
	permission: boolean
};
	["BackupMediaDocAccessFields_sizes_thumbnail_Update"]: {
	__typename: "BackupMediaDocAccessFields_sizes_thumbnail_Update",
	permission: boolean
};
	["BackupMediaDocAccessFields_sizes_thumbnail_Delete"]: {
	__typename: "BackupMediaDocAccessFields_sizes_thumbnail_Delete",
	permission: boolean
};
	["BackupMediaDocAccessFields_sizes_thumbnail_Fields"]: {
	__typename: "BackupMediaDocAccessFields_sizes_thumbnail_Fields",
	url?: GraphQLTypes["BackupMediaDocAccessFields_sizes_thumbnail_url"] | undefined,
	width?: GraphQLTypes["BackupMediaDocAccessFields_sizes_thumbnail_width"] | undefined,
	height?: GraphQLTypes["BackupMediaDocAccessFields_sizes_thumbnail_height"] | undefined,
	mimeType?: GraphQLTypes["BackupMediaDocAccessFields_sizes_thumbnail_mimeType"] | undefined,
	filesize?: GraphQLTypes["BackupMediaDocAccessFields_sizes_thumbnail_filesize"] | undefined,
	filename?: GraphQLTypes["BackupMediaDocAccessFields_sizes_thumbnail_filename"] | undefined
};
	["BackupMediaDocAccessFields_sizes_thumbnail_url"]: {
	__typename: "BackupMediaDocAccessFields_sizes_thumbnail_url",
	create?: GraphQLTypes["BackupMediaDocAccessFields_sizes_thumbnail_url_Create"] | undefined,
	read?: GraphQLTypes["BackupMediaDocAccessFields_sizes_thumbnail_url_Read"] | undefined,
	update?: GraphQLTypes["BackupMediaDocAccessFields_sizes_thumbnail_url_Update"] | undefined,
	delete?: GraphQLTypes["BackupMediaDocAccessFields_sizes_thumbnail_url_Delete"] | undefined
};
	["BackupMediaDocAccessFields_sizes_thumbnail_url_Create"]: {
	__typename: "BackupMediaDocAccessFields_sizes_thumbnail_url_Create",
	permission: boolean
};
	["BackupMediaDocAccessFields_sizes_thumbnail_url_Read"]: {
	__typename: "BackupMediaDocAccessFields_sizes_thumbnail_url_Read",
	permission: boolean
};
	["BackupMediaDocAccessFields_sizes_thumbnail_url_Update"]: {
	__typename: "BackupMediaDocAccessFields_sizes_thumbnail_url_Update",
	permission: boolean
};
	["BackupMediaDocAccessFields_sizes_thumbnail_url_Delete"]: {
	__typename: "BackupMediaDocAccessFields_sizes_thumbnail_url_Delete",
	permission: boolean
};
	["BackupMediaDocAccessFields_sizes_thumbnail_width"]: {
	__typename: "BackupMediaDocAccessFields_sizes_thumbnail_width",
	create?: GraphQLTypes["BackupMediaDocAccessFields_sizes_thumbnail_width_Create"] | undefined,
	read?: GraphQLTypes["BackupMediaDocAccessFields_sizes_thumbnail_width_Read"] | undefined,
	update?: GraphQLTypes["BackupMediaDocAccessFields_sizes_thumbnail_width_Update"] | undefined,
	delete?: GraphQLTypes["BackupMediaDocAccessFields_sizes_thumbnail_width_Delete"] | undefined
};
	["BackupMediaDocAccessFields_sizes_thumbnail_width_Create"]: {
	__typename: "BackupMediaDocAccessFields_sizes_thumbnail_width_Create",
	permission: boolean
};
	["BackupMediaDocAccessFields_sizes_thumbnail_width_Read"]: {
	__typename: "BackupMediaDocAccessFields_sizes_thumbnail_width_Read",
	permission: boolean
};
	["BackupMediaDocAccessFields_sizes_thumbnail_width_Update"]: {
	__typename: "BackupMediaDocAccessFields_sizes_thumbnail_width_Update",
	permission: boolean
};
	["BackupMediaDocAccessFields_sizes_thumbnail_width_Delete"]: {
	__typename: "BackupMediaDocAccessFields_sizes_thumbnail_width_Delete",
	permission: boolean
};
	["BackupMediaDocAccessFields_sizes_thumbnail_height"]: {
	__typename: "BackupMediaDocAccessFields_sizes_thumbnail_height",
	create?: GraphQLTypes["BackupMediaDocAccessFields_sizes_thumbnail_height_Create"] | undefined,
	read?: GraphQLTypes["BackupMediaDocAccessFields_sizes_thumbnail_height_Read"] | undefined,
	update?: GraphQLTypes["BackupMediaDocAccessFields_sizes_thumbnail_height_Update"] | undefined,
	delete?: GraphQLTypes["BackupMediaDocAccessFields_sizes_thumbnail_height_Delete"] | undefined
};
	["BackupMediaDocAccessFields_sizes_thumbnail_height_Create"]: {
	__typename: "BackupMediaDocAccessFields_sizes_thumbnail_height_Create",
	permission: boolean
};
	["BackupMediaDocAccessFields_sizes_thumbnail_height_Read"]: {
	__typename: "BackupMediaDocAccessFields_sizes_thumbnail_height_Read",
	permission: boolean
};
	["BackupMediaDocAccessFields_sizes_thumbnail_height_Update"]: {
	__typename: "BackupMediaDocAccessFields_sizes_thumbnail_height_Update",
	permission: boolean
};
	["BackupMediaDocAccessFields_sizes_thumbnail_height_Delete"]: {
	__typename: "BackupMediaDocAccessFields_sizes_thumbnail_height_Delete",
	permission: boolean
};
	["BackupMediaDocAccessFields_sizes_thumbnail_mimeType"]: {
	__typename: "BackupMediaDocAccessFields_sizes_thumbnail_mimeType",
	create?: GraphQLTypes["BackupMediaDocAccessFields_sizes_thumbnail_mimeType_Create"] | undefined,
	read?: GraphQLTypes["BackupMediaDocAccessFields_sizes_thumbnail_mimeType_Read"] | undefined,
	update?: GraphQLTypes["BackupMediaDocAccessFields_sizes_thumbnail_mimeType_Update"] | undefined,
	delete?: GraphQLTypes["BackupMediaDocAccessFields_sizes_thumbnail_mimeType_Delete"] | undefined
};
	["BackupMediaDocAccessFields_sizes_thumbnail_mimeType_Create"]: {
	__typename: "BackupMediaDocAccessFields_sizes_thumbnail_mimeType_Create",
	permission: boolean
};
	["BackupMediaDocAccessFields_sizes_thumbnail_mimeType_Read"]: {
	__typename: "BackupMediaDocAccessFields_sizes_thumbnail_mimeType_Read",
	permission: boolean
};
	["BackupMediaDocAccessFields_sizes_thumbnail_mimeType_Update"]: {
	__typename: "BackupMediaDocAccessFields_sizes_thumbnail_mimeType_Update",
	permission: boolean
};
	["BackupMediaDocAccessFields_sizes_thumbnail_mimeType_Delete"]: {
	__typename: "BackupMediaDocAccessFields_sizes_thumbnail_mimeType_Delete",
	permission: boolean
};
	["BackupMediaDocAccessFields_sizes_thumbnail_filesize"]: {
	__typename: "BackupMediaDocAccessFields_sizes_thumbnail_filesize",
	create?: GraphQLTypes["BackupMediaDocAccessFields_sizes_thumbnail_filesize_Create"] | undefined,
	read?: GraphQLTypes["BackupMediaDocAccessFields_sizes_thumbnail_filesize_Read"] | undefined,
	update?: GraphQLTypes["BackupMediaDocAccessFields_sizes_thumbnail_filesize_Update"] | undefined,
	delete?: GraphQLTypes["BackupMediaDocAccessFields_sizes_thumbnail_filesize_Delete"] | undefined
};
	["BackupMediaDocAccessFields_sizes_thumbnail_filesize_Create"]: {
	__typename: "BackupMediaDocAccessFields_sizes_thumbnail_filesize_Create",
	permission: boolean
};
	["BackupMediaDocAccessFields_sizes_thumbnail_filesize_Read"]: {
	__typename: "BackupMediaDocAccessFields_sizes_thumbnail_filesize_Read",
	permission: boolean
};
	["BackupMediaDocAccessFields_sizes_thumbnail_filesize_Update"]: {
	__typename: "BackupMediaDocAccessFields_sizes_thumbnail_filesize_Update",
	permission: boolean
};
	["BackupMediaDocAccessFields_sizes_thumbnail_filesize_Delete"]: {
	__typename: "BackupMediaDocAccessFields_sizes_thumbnail_filesize_Delete",
	permission: boolean
};
	["BackupMediaDocAccessFields_sizes_thumbnail_filename"]: {
	__typename: "BackupMediaDocAccessFields_sizes_thumbnail_filename",
	create?: GraphQLTypes["BackupMediaDocAccessFields_sizes_thumbnail_filename_Create"] | undefined,
	read?: GraphQLTypes["BackupMediaDocAccessFields_sizes_thumbnail_filename_Read"] | undefined,
	update?: GraphQLTypes["BackupMediaDocAccessFields_sizes_thumbnail_filename_Update"] | undefined,
	delete?: GraphQLTypes["BackupMediaDocAccessFields_sizes_thumbnail_filename_Delete"] | undefined
};
	["BackupMediaDocAccessFields_sizes_thumbnail_filename_Create"]: {
	__typename: "BackupMediaDocAccessFields_sizes_thumbnail_filename_Create",
	permission: boolean
};
	["BackupMediaDocAccessFields_sizes_thumbnail_filename_Read"]: {
	__typename: "BackupMediaDocAccessFields_sizes_thumbnail_filename_Read",
	permission: boolean
};
	["BackupMediaDocAccessFields_sizes_thumbnail_filename_Update"]: {
	__typename: "BackupMediaDocAccessFields_sizes_thumbnail_filename_Update",
	permission: boolean
};
	["BackupMediaDocAccessFields_sizes_thumbnail_filename_Delete"]: {
	__typename: "BackupMediaDocAccessFields_sizes_thumbnail_filename_Delete",
	permission: boolean
};
	["BackupMediaDocAccessFields_sizes_sixteenByNineMedium"]: {
	__typename: "BackupMediaDocAccessFields_sizes_sixteenByNineMedium",
	create?: GraphQLTypes["BackupMediaDocAccessFields_sizes_sixteenByNineMedium_Create"] | undefined,
	read?: GraphQLTypes["BackupMediaDocAccessFields_sizes_sixteenByNineMedium_Read"] | undefined,
	update?: GraphQLTypes["BackupMediaDocAccessFields_sizes_sixteenByNineMedium_Update"] | undefined,
	delete?: GraphQLTypes["BackupMediaDocAccessFields_sizes_sixteenByNineMedium_Delete"] | undefined,
	fields?: GraphQLTypes["BackupMediaDocAccessFields_sizes_sixteenByNineMedium_Fields"] | undefined
};
	["BackupMediaDocAccessFields_sizes_sixteenByNineMedium_Create"]: {
	__typename: "BackupMediaDocAccessFields_sizes_sixteenByNineMedium_Create",
	permission: boolean
};
	["BackupMediaDocAccessFields_sizes_sixteenByNineMedium_Read"]: {
	__typename: "BackupMediaDocAccessFields_sizes_sixteenByNineMedium_Read",
	permission: boolean
};
	["BackupMediaDocAccessFields_sizes_sixteenByNineMedium_Update"]: {
	__typename: "BackupMediaDocAccessFields_sizes_sixteenByNineMedium_Update",
	permission: boolean
};
	["BackupMediaDocAccessFields_sizes_sixteenByNineMedium_Delete"]: {
	__typename: "BackupMediaDocAccessFields_sizes_sixteenByNineMedium_Delete",
	permission: boolean
};
	["BackupMediaDocAccessFields_sizes_sixteenByNineMedium_Fields"]: {
	__typename: "BackupMediaDocAccessFields_sizes_sixteenByNineMedium_Fields",
	url?: GraphQLTypes["BackupMediaDocAccessFields_sizes_sixteenByNineMedium_url"] | undefined,
	width?: GraphQLTypes["BackupMediaDocAccessFields_sizes_sixteenByNineMedium_width"] | undefined,
	height?: GraphQLTypes["BackupMediaDocAccessFields_sizes_sixteenByNineMedium_height"] | undefined,
	mimeType?: GraphQLTypes["BackupMediaDocAccessFields_sizes_sixteenByNineMedium_mimeType"] | undefined,
	filesize?: GraphQLTypes["BackupMediaDocAccessFields_sizes_sixteenByNineMedium_filesize"] | undefined,
	filename?: GraphQLTypes["BackupMediaDocAccessFields_sizes_sixteenByNineMedium_filename"] | undefined
};
	["BackupMediaDocAccessFields_sizes_sixteenByNineMedium_url"]: {
	__typename: "BackupMediaDocAccessFields_sizes_sixteenByNineMedium_url",
	create?: GraphQLTypes["BackupMediaDocAccessFields_sizes_sixteenByNineMedium_url_Create"] | undefined,
	read?: GraphQLTypes["BackupMediaDocAccessFields_sizes_sixteenByNineMedium_url_Read"] | undefined,
	update?: GraphQLTypes["BackupMediaDocAccessFields_sizes_sixteenByNineMedium_url_Update"] | undefined,
	delete?: GraphQLTypes["BackupMediaDocAccessFields_sizes_sixteenByNineMedium_url_Delete"] | undefined
};
	["BackupMediaDocAccessFields_sizes_sixteenByNineMedium_url_Create"]: {
	__typename: "BackupMediaDocAccessFields_sizes_sixteenByNineMedium_url_Create",
	permission: boolean
};
	["BackupMediaDocAccessFields_sizes_sixteenByNineMedium_url_Read"]: {
	__typename: "BackupMediaDocAccessFields_sizes_sixteenByNineMedium_url_Read",
	permission: boolean
};
	["BackupMediaDocAccessFields_sizes_sixteenByNineMedium_url_Update"]: {
	__typename: "BackupMediaDocAccessFields_sizes_sixteenByNineMedium_url_Update",
	permission: boolean
};
	["BackupMediaDocAccessFields_sizes_sixteenByNineMedium_url_Delete"]: {
	__typename: "BackupMediaDocAccessFields_sizes_sixteenByNineMedium_url_Delete",
	permission: boolean
};
	["BackupMediaDocAccessFields_sizes_sixteenByNineMedium_width"]: {
	__typename: "BackupMediaDocAccessFields_sizes_sixteenByNineMedium_width",
	create?: GraphQLTypes["BackupMediaDocAccessFields_sizes_sixteenByNineMedium_width_Create"] | undefined,
	read?: GraphQLTypes["BackupMediaDocAccessFields_sizes_sixteenByNineMedium_width_Read"] | undefined,
	update?: GraphQLTypes["BackupMediaDocAccessFields_sizes_sixteenByNineMedium_width_Update"] | undefined,
	delete?: GraphQLTypes["BackupMediaDocAccessFields_sizes_sixteenByNineMedium_width_Delete"] | undefined
};
	["BackupMediaDocAccessFields_sizes_sixteenByNineMedium_width_Create"]: {
	__typename: "BackupMediaDocAccessFields_sizes_sixteenByNineMedium_width_Create",
	permission: boolean
};
	["BackupMediaDocAccessFields_sizes_sixteenByNineMedium_width_Read"]: {
	__typename: "BackupMediaDocAccessFields_sizes_sixteenByNineMedium_width_Read",
	permission: boolean
};
	["BackupMediaDocAccessFields_sizes_sixteenByNineMedium_width_Update"]: {
	__typename: "BackupMediaDocAccessFields_sizes_sixteenByNineMedium_width_Update",
	permission: boolean
};
	["BackupMediaDocAccessFields_sizes_sixteenByNineMedium_width_Delete"]: {
	__typename: "BackupMediaDocAccessFields_sizes_sixteenByNineMedium_width_Delete",
	permission: boolean
};
	["BackupMediaDocAccessFields_sizes_sixteenByNineMedium_height"]: {
	__typename: "BackupMediaDocAccessFields_sizes_sixteenByNineMedium_height",
	create?: GraphQLTypes["BackupMediaDocAccessFields_sizes_sixteenByNineMedium_height_Create"] | undefined,
	read?: GraphQLTypes["BackupMediaDocAccessFields_sizes_sixteenByNineMedium_height_Read"] | undefined,
	update?: GraphQLTypes["BackupMediaDocAccessFields_sizes_sixteenByNineMedium_height_Update"] | undefined,
	delete?: GraphQLTypes["BackupMediaDocAccessFields_sizes_sixteenByNineMedium_height_Delete"] | undefined
};
	["BackupMediaDocAccessFields_sizes_sixteenByNineMedium_height_Create"]: {
	__typename: "BackupMediaDocAccessFields_sizes_sixteenByNineMedium_height_Create",
	permission: boolean
};
	["BackupMediaDocAccessFields_sizes_sixteenByNineMedium_height_Read"]: {
	__typename: "BackupMediaDocAccessFields_sizes_sixteenByNineMedium_height_Read",
	permission: boolean
};
	["BackupMediaDocAccessFields_sizes_sixteenByNineMedium_height_Update"]: {
	__typename: "BackupMediaDocAccessFields_sizes_sixteenByNineMedium_height_Update",
	permission: boolean
};
	["BackupMediaDocAccessFields_sizes_sixteenByNineMedium_height_Delete"]: {
	__typename: "BackupMediaDocAccessFields_sizes_sixteenByNineMedium_height_Delete",
	permission: boolean
};
	["BackupMediaDocAccessFields_sizes_sixteenByNineMedium_mimeType"]: {
	__typename: "BackupMediaDocAccessFields_sizes_sixteenByNineMedium_mimeType",
	create?: GraphQLTypes["BackupMediaDocAccessFields_sizes_sixteenByNineMedium_mimeType_Create"] | undefined,
	read?: GraphQLTypes["BackupMediaDocAccessFields_sizes_sixteenByNineMedium_mimeType_Read"] | undefined,
	update?: GraphQLTypes["BackupMediaDocAccessFields_sizes_sixteenByNineMedium_mimeType_Update"] | undefined,
	delete?: GraphQLTypes["BackupMediaDocAccessFields_sizes_sixteenByNineMedium_mimeType_Delete"] | undefined
};
	["BackupMediaDocAccessFields_sizes_sixteenByNineMedium_mimeType_Create"]: {
	__typename: "BackupMediaDocAccessFields_sizes_sixteenByNineMedium_mimeType_Create",
	permission: boolean
};
	["BackupMediaDocAccessFields_sizes_sixteenByNineMedium_mimeType_Read"]: {
	__typename: "BackupMediaDocAccessFields_sizes_sixteenByNineMedium_mimeType_Read",
	permission: boolean
};
	["BackupMediaDocAccessFields_sizes_sixteenByNineMedium_mimeType_Update"]: {
	__typename: "BackupMediaDocAccessFields_sizes_sixteenByNineMedium_mimeType_Update",
	permission: boolean
};
	["BackupMediaDocAccessFields_sizes_sixteenByNineMedium_mimeType_Delete"]: {
	__typename: "BackupMediaDocAccessFields_sizes_sixteenByNineMedium_mimeType_Delete",
	permission: boolean
};
	["BackupMediaDocAccessFields_sizes_sixteenByNineMedium_filesize"]: {
	__typename: "BackupMediaDocAccessFields_sizes_sixteenByNineMedium_filesize",
	create?: GraphQLTypes["BackupMediaDocAccessFields_sizes_sixteenByNineMedium_filesize_Create"] | undefined,
	read?: GraphQLTypes["BackupMediaDocAccessFields_sizes_sixteenByNineMedium_filesize_Read"] | undefined,
	update?: GraphQLTypes["BackupMediaDocAccessFields_sizes_sixteenByNineMedium_filesize_Update"] | undefined,
	delete?: GraphQLTypes["BackupMediaDocAccessFields_sizes_sixteenByNineMedium_filesize_Delete"] | undefined
};
	["BackupMediaDocAccessFields_sizes_sixteenByNineMedium_filesize_Create"]: {
	__typename: "BackupMediaDocAccessFields_sizes_sixteenByNineMedium_filesize_Create",
	permission: boolean
};
	["BackupMediaDocAccessFields_sizes_sixteenByNineMedium_filesize_Read"]: {
	__typename: "BackupMediaDocAccessFields_sizes_sixteenByNineMedium_filesize_Read",
	permission: boolean
};
	["BackupMediaDocAccessFields_sizes_sixteenByNineMedium_filesize_Update"]: {
	__typename: "BackupMediaDocAccessFields_sizes_sixteenByNineMedium_filesize_Update",
	permission: boolean
};
	["BackupMediaDocAccessFields_sizes_sixteenByNineMedium_filesize_Delete"]: {
	__typename: "BackupMediaDocAccessFields_sizes_sixteenByNineMedium_filesize_Delete",
	permission: boolean
};
	["BackupMediaDocAccessFields_sizes_sixteenByNineMedium_filename"]: {
	__typename: "BackupMediaDocAccessFields_sizes_sixteenByNineMedium_filename",
	create?: GraphQLTypes["BackupMediaDocAccessFields_sizes_sixteenByNineMedium_filename_Create"] | undefined,
	read?: GraphQLTypes["BackupMediaDocAccessFields_sizes_sixteenByNineMedium_filename_Read"] | undefined,
	update?: GraphQLTypes["BackupMediaDocAccessFields_sizes_sixteenByNineMedium_filename_Update"] | undefined,
	delete?: GraphQLTypes["BackupMediaDocAccessFields_sizes_sixteenByNineMedium_filename_Delete"] | undefined
};
	["BackupMediaDocAccessFields_sizes_sixteenByNineMedium_filename_Create"]: {
	__typename: "BackupMediaDocAccessFields_sizes_sixteenByNineMedium_filename_Create",
	permission: boolean
};
	["BackupMediaDocAccessFields_sizes_sixteenByNineMedium_filename_Read"]: {
	__typename: "BackupMediaDocAccessFields_sizes_sixteenByNineMedium_filename_Read",
	permission: boolean
};
	["BackupMediaDocAccessFields_sizes_sixteenByNineMedium_filename_Update"]: {
	__typename: "BackupMediaDocAccessFields_sizes_sixteenByNineMedium_filename_Update",
	permission: boolean
};
	["BackupMediaDocAccessFields_sizes_sixteenByNineMedium_filename_Delete"]: {
	__typename: "BackupMediaDocAccessFields_sizes_sixteenByNineMedium_filename_Delete",
	permission: boolean
};
	["BackupMediaCreateDocAccess"]: {
	__typename: "BackupMediaCreateDocAccess",
	permission: boolean,
	where?: GraphQLTypes["JSONObject"] | undefined
};
	["BackupMediaReadDocAccess"]: {
	__typename: "BackupMediaReadDocAccess",
	permission: boolean,
	where?: GraphQLTypes["JSONObject"] | undefined
};
	["BackupMediaUpdateDocAccess"]: {
	__typename: "BackupMediaUpdateDocAccess",
	permission: boolean,
	where?: GraphQLTypes["JSONObject"] | undefined
};
	["BackupMediaDeleteDocAccess"]: {
	__typename: "BackupMediaDeleteDocAccess",
	permission: boolean,
	where?: GraphQLTypes["JSONObject"] | undefined
};
	["AccountSuspendRequest"]: {
	__typename: "AccountSuspendRequest",
	id?: string | undefined,
	user?: GraphQLTypes["User"] | undefined,
	handledBy?: GraphQLTypes["User"] | undefined,
	updatedAt?: GraphQLTypes["DateTime"] | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined
};
	["AccountSuspendRequests"]: {
	__typename: "AccountSuspendRequests",
	docs?: Array<GraphQLTypes["AccountSuspendRequest"] | undefined> | undefined,
	hasNextPage?: boolean | undefined,
	hasPrevPage?: boolean | undefined,
	limit?: number | undefined,
	nextPage?: number | undefined,
	offset?: number | undefined,
	page?: number | undefined,
	pagingCounter?: number | undefined,
	prevPage?: number | undefined,
	totalDocs?: number | undefined,
	totalPages?: number | undefined
};
	["AccountSuspendRequest_where"]: {
		user?: GraphQLTypes["AccountSuspendRequest_user_operator"] | undefined,
	handledBy?: GraphQLTypes["AccountSuspendRequest_handledBy_operator"] | undefined,
	updatedAt?: GraphQLTypes["AccountSuspendRequest_updatedAt_operator"] | undefined,
	createdAt?: GraphQLTypes["AccountSuspendRequest_createdAt_operator"] | undefined,
	id?: GraphQLTypes["AccountSuspendRequest_id_operator"] | undefined,
	AND?: Array<GraphQLTypes["AccountSuspendRequest_where_and"] | undefined> | undefined,
	OR?: Array<GraphQLTypes["AccountSuspendRequest_where_or"] | undefined> | undefined
};
	["AccountSuspendRequest_user_operator"]: {
		equals?: GraphQLTypes["JSON"] | undefined,
	not_equals?: GraphQLTypes["JSON"] | undefined,
	in?: Array<GraphQLTypes["JSON"] | undefined> | undefined,
	not_in?: Array<GraphQLTypes["JSON"] | undefined> | undefined,
	all?: Array<GraphQLTypes["JSON"] | undefined> | undefined,
	exists?: boolean | undefined
};
	["AccountSuspendRequest_handledBy_operator"]: {
		equals?: GraphQLTypes["JSON"] | undefined,
	not_equals?: GraphQLTypes["JSON"] | undefined,
	in?: Array<GraphQLTypes["JSON"] | undefined> | undefined,
	not_in?: Array<GraphQLTypes["JSON"] | undefined> | undefined,
	all?: Array<GraphQLTypes["JSON"] | undefined> | undefined,
	exists?: boolean | undefined
};
	["AccountSuspendRequest_updatedAt_operator"]: {
		equals?: GraphQLTypes["DateTime"] | undefined,
	not_equals?: GraphQLTypes["DateTime"] | undefined,
	greater_than_equal?: GraphQLTypes["DateTime"] | undefined,
	greater_than?: GraphQLTypes["DateTime"] | undefined,
	less_than_equal?: GraphQLTypes["DateTime"] | undefined,
	less_than?: GraphQLTypes["DateTime"] | undefined,
	like?: GraphQLTypes["DateTime"] | undefined,
	exists?: boolean | undefined
};
	["AccountSuspendRequest_createdAt_operator"]: {
		equals?: GraphQLTypes["DateTime"] | undefined,
	not_equals?: GraphQLTypes["DateTime"] | undefined,
	greater_than_equal?: GraphQLTypes["DateTime"] | undefined,
	greater_than?: GraphQLTypes["DateTime"] | undefined,
	less_than_equal?: GraphQLTypes["DateTime"] | undefined,
	less_than?: GraphQLTypes["DateTime"] | undefined,
	like?: GraphQLTypes["DateTime"] | undefined,
	exists?: boolean | undefined
};
	["AccountSuspendRequest_id_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["AccountSuspendRequest_where_and"]: {
		user?: GraphQLTypes["AccountSuspendRequest_user_operator"] | undefined,
	handledBy?: GraphQLTypes["AccountSuspendRequest_handledBy_operator"] | undefined,
	updatedAt?: GraphQLTypes["AccountSuspendRequest_updatedAt_operator"] | undefined,
	createdAt?: GraphQLTypes["AccountSuspendRequest_createdAt_operator"] | undefined,
	id?: GraphQLTypes["AccountSuspendRequest_id_operator"] | undefined,
	AND?: Array<GraphQLTypes["AccountSuspendRequest_where_and"] | undefined> | undefined,
	OR?: Array<GraphQLTypes["AccountSuspendRequest_where_or"] | undefined> | undefined
};
	["AccountSuspendRequest_where_or"]: {
		user?: GraphQLTypes["AccountSuspendRequest_user_operator"] | undefined,
	handledBy?: GraphQLTypes["AccountSuspendRequest_handledBy_operator"] | undefined,
	updatedAt?: GraphQLTypes["AccountSuspendRequest_updatedAt_operator"] | undefined,
	createdAt?: GraphQLTypes["AccountSuspendRequest_createdAt_operator"] | undefined,
	id?: GraphQLTypes["AccountSuspendRequest_id_operator"] | undefined,
	AND?: Array<GraphQLTypes["AccountSuspendRequest_where_and"] | undefined> | undefined,
	OR?: Array<GraphQLTypes["AccountSuspendRequest_where_or"] | undefined> | undefined
};
	["countAccountSuspendRequests"]: {
	__typename: "countAccountSuspendRequests",
	totalDocs?: number | undefined
};
	["account_suspend_requestsDocAccess"]: {
	__typename: "account_suspend_requestsDocAccess",
	fields?: GraphQLTypes["AccountSuspendRequestsDocAccessFields"] | undefined,
	create?: GraphQLTypes["AccountSuspendRequestsCreateDocAccess"] | undefined,
	read?: GraphQLTypes["AccountSuspendRequestsReadDocAccess"] | undefined,
	update?: GraphQLTypes["AccountSuspendRequestsUpdateDocAccess"] | undefined,
	delete?: GraphQLTypes["AccountSuspendRequestsDeleteDocAccess"] | undefined
};
	["AccountSuspendRequestsDocAccessFields"]: {
	__typename: "AccountSuspendRequestsDocAccessFields",
	user?: GraphQLTypes["AccountSuspendRequestsDocAccessFields_user"] | undefined,
	handledBy?: GraphQLTypes["AccountSuspendRequestsDocAccessFields_handledBy"] | undefined,
	updatedAt?: GraphQLTypes["AccountSuspendRequestsDocAccessFields_updatedAt"] | undefined,
	createdAt?: GraphQLTypes["AccountSuspendRequestsDocAccessFields_createdAt"] | undefined
};
	["AccountSuspendRequestsDocAccessFields_user"]: {
	__typename: "AccountSuspendRequestsDocAccessFields_user",
	create?: GraphQLTypes["AccountSuspendRequestsDocAccessFields_user_Create"] | undefined,
	read?: GraphQLTypes["AccountSuspendRequestsDocAccessFields_user_Read"] | undefined,
	update?: GraphQLTypes["AccountSuspendRequestsDocAccessFields_user_Update"] | undefined,
	delete?: GraphQLTypes["AccountSuspendRequestsDocAccessFields_user_Delete"] | undefined
};
	["AccountSuspendRequestsDocAccessFields_user_Create"]: {
	__typename: "AccountSuspendRequestsDocAccessFields_user_Create",
	permission: boolean
};
	["AccountSuspendRequestsDocAccessFields_user_Read"]: {
	__typename: "AccountSuspendRequestsDocAccessFields_user_Read",
	permission: boolean
};
	["AccountSuspendRequestsDocAccessFields_user_Update"]: {
	__typename: "AccountSuspendRequestsDocAccessFields_user_Update",
	permission: boolean
};
	["AccountSuspendRequestsDocAccessFields_user_Delete"]: {
	__typename: "AccountSuspendRequestsDocAccessFields_user_Delete",
	permission: boolean
};
	["AccountSuspendRequestsDocAccessFields_handledBy"]: {
	__typename: "AccountSuspendRequestsDocAccessFields_handledBy",
	create?: GraphQLTypes["AccountSuspendRequestsDocAccessFields_handledBy_Create"] | undefined,
	read?: GraphQLTypes["AccountSuspendRequestsDocAccessFields_handledBy_Read"] | undefined,
	update?: GraphQLTypes["AccountSuspendRequestsDocAccessFields_handledBy_Update"] | undefined,
	delete?: GraphQLTypes["AccountSuspendRequestsDocAccessFields_handledBy_Delete"] | undefined
};
	["AccountSuspendRequestsDocAccessFields_handledBy_Create"]: {
	__typename: "AccountSuspendRequestsDocAccessFields_handledBy_Create",
	permission: boolean
};
	["AccountSuspendRequestsDocAccessFields_handledBy_Read"]: {
	__typename: "AccountSuspendRequestsDocAccessFields_handledBy_Read",
	permission: boolean
};
	["AccountSuspendRequestsDocAccessFields_handledBy_Update"]: {
	__typename: "AccountSuspendRequestsDocAccessFields_handledBy_Update",
	permission: boolean
};
	["AccountSuspendRequestsDocAccessFields_handledBy_Delete"]: {
	__typename: "AccountSuspendRequestsDocAccessFields_handledBy_Delete",
	permission: boolean
};
	["AccountSuspendRequestsDocAccessFields_updatedAt"]: {
	__typename: "AccountSuspendRequestsDocAccessFields_updatedAt",
	create?: GraphQLTypes["AccountSuspendRequestsDocAccessFields_updatedAt_Create"] | undefined,
	read?: GraphQLTypes["AccountSuspendRequestsDocAccessFields_updatedAt_Read"] | undefined,
	update?: GraphQLTypes["AccountSuspendRequestsDocAccessFields_updatedAt_Update"] | undefined,
	delete?: GraphQLTypes["AccountSuspendRequestsDocAccessFields_updatedAt_Delete"] | undefined
};
	["AccountSuspendRequestsDocAccessFields_updatedAt_Create"]: {
	__typename: "AccountSuspendRequestsDocAccessFields_updatedAt_Create",
	permission: boolean
};
	["AccountSuspendRequestsDocAccessFields_updatedAt_Read"]: {
	__typename: "AccountSuspendRequestsDocAccessFields_updatedAt_Read",
	permission: boolean
};
	["AccountSuspendRequestsDocAccessFields_updatedAt_Update"]: {
	__typename: "AccountSuspendRequestsDocAccessFields_updatedAt_Update",
	permission: boolean
};
	["AccountSuspendRequestsDocAccessFields_updatedAt_Delete"]: {
	__typename: "AccountSuspendRequestsDocAccessFields_updatedAt_Delete",
	permission: boolean
};
	["AccountSuspendRequestsDocAccessFields_createdAt"]: {
	__typename: "AccountSuspendRequestsDocAccessFields_createdAt",
	create?: GraphQLTypes["AccountSuspendRequestsDocAccessFields_createdAt_Create"] | undefined,
	read?: GraphQLTypes["AccountSuspendRequestsDocAccessFields_createdAt_Read"] | undefined,
	update?: GraphQLTypes["AccountSuspendRequestsDocAccessFields_createdAt_Update"] | undefined,
	delete?: GraphQLTypes["AccountSuspendRequestsDocAccessFields_createdAt_Delete"] | undefined
};
	["AccountSuspendRequestsDocAccessFields_createdAt_Create"]: {
	__typename: "AccountSuspendRequestsDocAccessFields_createdAt_Create",
	permission: boolean
};
	["AccountSuspendRequestsDocAccessFields_createdAt_Read"]: {
	__typename: "AccountSuspendRequestsDocAccessFields_createdAt_Read",
	permission: boolean
};
	["AccountSuspendRequestsDocAccessFields_createdAt_Update"]: {
	__typename: "AccountSuspendRequestsDocAccessFields_createdAt_Update",
	permission: boolean
};
	["AccountSuspendRequestsDocAccessFields_createdAt_Delete"]: {
	__typename: "AccountSuspendRequestsDocAccessFields_createdAt_Delete",
	permission: boolean
};
	["AccountSuspendRequestsCreateDocAccess"]: {
	__typename: "AccountSuspendRequestsCreateDocAccess",
	permission: boolean,
	where?: GraphQLTypes["JSONObject"] | undefined
};
	["AccountSuspendRequestsReadDocAccess"]: {
	__typename: "AccountSuspendRequestsReadDocAccess",
	permission: boolean,
	where?: GraphQLTypes["JSONObject"] | undefined
};
	["AccountSuspendRequestsUpdateDocAccess"]: {
	__typename: "AccountSuspendRequestsUpdateDocAccess",
	permission: boolean,
	where?: GraphQLTypes["JSONObject"] | undefined
};
	["AccountSuspendRequestsDeleteDocAccess"]: {
	__typename: "AccountSuspendRequestsDeleteDocAccess",
	permission: boolean,
	where?: GraphQLTypes["JSONObject"] | undefined
};
	["Device"]: {
	__typename: "Device",
	id?: string | undefined,
	user?: GraphQLTypes["Device_User_Relationship"] | undefined,
	udid?: string | undefined,
	pushToken?: string | undefined,
	updatedAt?: GraphQLTypes["DateTime"] | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined
};
	["Device_User_Relationship"]: {
	__typename: "Device_User_Relationship",
	relationTo?: GraphQLTypes["Device_User_RelationTo"] | undefined,
	value?: GraphQLTypes["Device_User"] | undefined
};
	["Device_User_RelationTo"]: Device_User_RelationTo;
	["Device_User"]:{
        	__typename:"User"
        	['...on User']: '__union' & GraphQLTypes["User"];
};
	["Devices"]: {
	__typename: "Devices",
	docs?: Array<GraphQLTypes["Device"] | undefined> | undefined,
	hasNextPage?: boolean | undefined,
	hasPrevPage?: boolean | undefined,
	limit?: number | undefined,
	nextPage?: number | undefined,
	offset?: number | undefined,
	page?: number | undefined,
	pagingCounter?: number | undefined,
	prevPage?: number | undefined,
	totalDocs?: number | undefined,
	totalPages?: number | undefined
};
	["Device_where"]: {
		user?: GraphQLTypes["Device_user_Relation"] | undefined,
	udid?: GraphQLTypes["Device_udid_operator"] | undefined,
	pushToken?: GraphQLTypes["Device_pushToken_operator"] | undefined,
	updatedAt?: GraphQLTypes["Device_updatedAt_operator"] | undefined,
	createdAt?: GraphQLTypes["Device_createdAt_operator"] | undefined,
	id?: GraphQLTypes["Device_id_operator"] | undefined,
	AND?: Array<GraphQLTypes["Device_where_and"] | undefined> | undefined,
	OR?: Array<GraphQLTypes["Device_where_or"] | undefined> | undefined
};
	["Device_user_Relation"]: {
		relationTo?: GraphQLTypes["Device_user_Relation_RelationTo"] | undefined,
	value?: GraphQLTypes["JSON"] | undefined
};
	["Device_user_Relation_RelationTo"]: Device_user_Relation_RelationTo;
	["Device_udid_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Device_pushToken_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Device_updatedAt_operator"]: {
		equals?: GraphQLTypes["DateTime"] | undefined,
	not_equals?: GraphQLTypes["DateTime"] | undefined,
	greater_than_equal?: GraphQLTypes["DateTime"] | undefined,
	greater_than?: GraphQLTypes["DateTime"] | undefined,
	less_than_equal?: GraphQLTypes["DateTime"] | undefined,
	less_than?: GraphQLTypes["DateTime"] | undefined,
	like?: GraphQLTypes["DateTime"] | undefined,
	exists?: boolean | undefined
};
	["Device_createdAt_operator"]: {
		equals?: GraphQLTypes["DateTime"] | undefined,
	not_equals?: GraphQLTypes["DateTime"] | undefined,
	greater_than_equal?: GraphQLTypes["DateTime"] | undefined,
	greater_than?: GraphQLTypes["DateTime"] | undefined,
	less_than_equal?: GraphQLTypes["DateTime"] | undefined,
	less_than?: GraphQLTypes["DateTime"] | undefined,
	like?: GraphQLTypes["DateTime"] | undefined,
	exists?: boolean | undefined
};
	["Device_id_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Device_where_and"]: {
		user?: GraphQLTypes["Device_user_Relation"] | undefined,
	udid?: GraphQLTypes["Device_udid_operator"] | undefined,
	pushToken?: GraphQLTypes["Device_pushToken_operator"] | undefined,
	updatedAt?: GraphQLTypes["Device_updatedAt_operator"] | undefined,
	createdAt?: GraphQLTypes["Device_createdAt_operator"] | undefined,
	id?: GraphQLTypes["Device_id_operator"] | undefined,
	AND?: Array<GraphQLTypes["Device_where_and"] | undefined> | undefined,
	OR?: Array<GraphQLTypes["Device_where_or"] | undefined> | undefined
};
	["Device_where_or"]: {
		user?: GraphQLTypes["Device_user_Relation"] | undefined,
	udid?: GraphQLTypes["Device_udid_operator"] | undefined,
	pushToken?: GraphQLTypes["Device_pushToken_operator"] | undefined,
	updatedAt?: GraphQLTypes["Device_updatedAt_operator"] | undefined,
	createdAt?: GraphQLTypes["Device_createdAt_operator"] | undefined,
	id?: GraphQLTypes["Device_id_operator"] | undefined,
	AND?: Array<GraphQLTypes["Device_where_and"] | undefined> | undefined,
	OR?: Array<GraphQLTypes["Device_where_or"] | undefined> | undefined
};
	["countDevices"]: {
	__typename: "countDevices",
	totalDocs?: number | undefined
};
	["devicesDocAccess"]: {
	__typename: "devicesDocAccess",
	fields?: GraphQLTypes["DevicesDocAccessFields"] | undefined,
	create?: GraphQLTypes["DevicesCreateDocAccess"] | undefined,
	read?: GraphQLTypes["DevicesReadDocAccess"] | undefined,
	update?: GraphQLTypes["DevicesUpdateDocAccess"] | undefined,
	delete?: GraphQLTypes["DevicesDeleteDocAccess"] | undefined
};
	["DevicesDocAccessFields"]: {
	__typename: "DevicesDocAccessFields",
	user?: GraphQLTypes["DevicesDocAccessFields_user"] | undefined,
	udid?: GraphQLTypes["DevicesDocAccessFields_udid"] | undefined,
	pushToken?: GraphQLTypes["DevicesDocAccessFields_pushToken"] | undefined,
	updatedAt?: GraphQLTypes["DevicesDocAccessFields_updatedAt"] | undefined,
	createdAt?: GraphQLTypes["DevicesDocAccessFields_createdAt"] | undefined
};
	["DevicesDocAccessFields_user"]: {
	__typename: "DevicesDocAccessFields_user",
	create?: GraphQLTypes["DevicesDocAccessFields_user_Create"] | undefined,
	read?: GraphQLTypes["DevicesDocAccessFields_user_Read"] | undefined,
	update?: GraphQLTypes["DevicesDocAccessFields_user_Update"] | undefined,
	delete?: GraphQLTypes["DevicesDocAccessFields_user_Delete"] | undefined
};
	["DevicesDocAccessFields_user_Create"]: {
	__typename: "DevicesDocAccessFields_user_Create",
	permission: boolean
};
	["DevicesDocAccessFields_user_Read"]: {
	__typename: "DevicesDocAccessFields_user_Read",
	permission: boolean
};
	["DevicesDocAccessFields_user_Update"]: {
	__typename: "DevicesDocAccessFields_user_Update",
	permission: boolean
};
	["DevicesDocAccessFields_user_Delete"]: {
	__typename: "DevicesDocAccessFields_user_Delete",
	permission: boolean
};
	["DevicesDocAccessFields_udid"]: {
	__typename: "DevicesDocAccessFields_udid",
	create?: GraphQLTypes["DevicesDocAccessFields_udid_Create"] | undefined,
	read?: GraphQLTypes["DevicesDocAccessFields_udid_Read"] | undefined,
	update?: GraphQLTypes["DevicesDocAccessFields_udid_Update"] | undefined,
	delete?: GraphQLTypes["DevicesDocAccessFields_udid_Delete"] | undefined
};
	["DevicesDocAccessFields_udid_Create"]: {
	__typename: "DevicesDocAccessFields_udid_Create",
	permission: boolean
};
	["DevicesDocAccessFields_udid_Read"]: {
	__typename: "DevicesDocAccessFields_udid_Read",
	permission: boolean
};
	["DevicesDocAccessFields_udid_Update"]: {
	__typename: "DevicesDocAccessFields_udid_Update",
	permission: boolean
};
	["DevicesDocAccessFields_udid_Delete"]: {
	__typename: "DevicesDocAccessFields_udid_Delete",
	permission: boolean
};
	["DevicesDocAccessFields_pushToken"]: {
	__typename: "DevicesDocAccessFields_pushToken",
	create?: GraphQLTypes["DevicesDocAccessFields_pushToken_Create"] | undefined,
	read?: GraphQLTypes["DevicesDocAccessFields_pushToken_Read"] | undefined,
	update?: GraphQLTypes["DevicesDocAccessFields_pushToken_Update"] | undefined,
	delete?: GraphQLTypes["DevicesDocAccessFields_pushToken_Delete"] | undefined
};
	["DevicesDocAccessFields_pushToken_Create"]: {
	__typename: "DevicesDocAccessFields_pushToken_Create",
	permission: boolean
};
	["DevicesDocAccessFields_pushToken_Read"]: {
	__typename: "DevicesDocAccessFields_pushToken_Read",
	permission: boolean
};
	["DevicesDocAccessFields_pushToken_Update"]: {
	__typename: "DevicesDocAccessFields_pushToken_Update",
	permission: boolean
};
	["DevicesDocAccessFields_pushToken_Delete"]: {
	__typename: "DevicesDocAccessFields_pushToken_Delete",
	permission: boolean
};
	["DevicesDocAccessFields_updatedAt"]: {
	__typename: "DevicesDocAccessFields_updatedAt",
	create?: GraphQLTypes["DevicesDocAccessFields_updatedAt_Create"] | undefined,
	read?: GraphQLTypes["DevicesDocAccessFields_updatedAt_Read"] | undefined,
	update?: GraphQLTypes["DevicesDocAccessFields_updatedAt_Update"] | undefined,
	delete?: GraphQLTypes["DevicesDocAccessFields_updatedAt_Delete"] | undefined
};
	["DevicesDocAccessFields_updatedAt_Create"]: {
	__typename: "DevicesDocAccessFields_updatedAt_Create",
	permission: boolean
};
	["DevicesDocAccessFields_updatedAt_Read"]: {
	__typename: "DevicesDocAccessFields_updatedAt_Read",
	permission: boolean
};
	["DevicesDocAccessFields_updatedAt_Update"]: {
	__typename: "DevicesDocAccessFields_updatedAt_Update",
	permission: boolean
};
	["DevicesDocAccessFields_updatedAt_Delete"]: {
	__typename: "DevicesDocAccessFields_updatedAt_Delete",
	permission: boolean
};
	["DevicesDocAccessFields_createdAt"]: {
	__typename: "DevicesDocAccessFields_createdAt",
	create?: GraphQLTypes["DevicesDocAccessFields_createdAt_Create"] | undefined,
	read?: GraphQLTypes["DevicesDocAccessFields_createdAt_Read"] | undefined,
	update?: GraphQLTypes["DevicesDocAccessFields_createdAt_Update"] | undefined,
	delete?: GraphQLTypes["DevicesDocAccessFields_createdAt_Delete"] | undefined
};
	["DevicesDocAccessFields_createdAt_Create"]: {
	__typename: "DevicesDocAccessFields_createdAt_Create",
	permission: boolean
};
	["DevicesDocAccessFields_createdAt_Read"]: {
	__typename: "DevicesDocAccessFields_createdAt_Read",
	permission: boolean
};
	["DevicesDocAccessFields_createdAt_Update"]: {
	__typename: "DevicesDocAccessFields_createdAt_Update",
	permission: boolean
};
	["DevicesDocAccessFields_createdAt_Delete"]: {
	__typename: "DevicesDocAccessFields_createdAt_Delete",
	permission: boolean
};
	["DevicesCreateDocAccess"]: {
	__typename: "DevicesCreateDocAccess",
	permission: boolean,
	where?: GraphQLTypes["JSONObject"] | undefined
};
	["DevicesReadDocAccess"]: {
	__typename: "DevicesReadDocAccess",
	permission: boolean,
	where?: GraphQLTypes["JSONObject"] | undefined
};
	["DevicesUpdateDocAccess"]: {
	__typename: "DevicesUpdateDocAccess",
	permission: boolean,
	where?: GraphQLTypes["JSONObject"] | undefined
};
	["DevicesDeleteDocAccess"]: {
	__typename: "DevicesDeleteDocAccess",
	permission: boolean,
	where?: GraphQLTypes["JSONObject"] | undefined
};
	["Notification"]: {
	__typename: "Notification",
	id?: string | undefined,
	receiver?: Array<GraphQLTypes["User"]> | undefined,
	type?: GraphQLTypes["Notification_type"] | undefined,
	meta?: GraphQLTypes["Notification_Meta_Relationship"] | undefined,
	message?: string | undefined,
	sender?: GraphQLTypes["User"] | undefined,
	readed?: boolean | undefined,
	deletedByUser?: boolean | undefined,
	updatedAt?: GraphQLTypes["DateTime"] | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined
};
	["Notification_type"]: Notification_type;
	["Notification_Meta_Relationship"]: {
	__typename: "Notification_Meta_Relationship",
	relationTo?: GraphQLTypes["Notification_Meta_RelationTo"] | undefined,
	value?: GraphQLTypes["Notification_Meta"] | undefined
};
	["Notification_Meta_RelationTo"]: Notification_Meta_RelationTo;
	["Notification_Meta"]:{
        	__typename:"Event" | "Location" | "Post" | "Comment"
        	['...on Event']: '__union' & GraphQLTypes["Event"];
	['...on Location']: '__union' & GraphQLTypes["Location"];
	['...on Post']: '__union' & GraphQLTypes["Post"];
	['...on Comment']: '__union' & GraphQLTypes["Comment"];
};
	["Notifications"]: {
	__typename: "Notifications",
	docs?: Array<GraphQLTypes["Notification"] | undefined> | undefined,
	hasNextPage?: boolean | undefined,
	hasPrevPage?: boolean | undefined,
	limit?: number | undefined,
	nextPage?: number | undefined,
	offset?: number | undefined,
	page?: number | undefined,
	pagingCounter?: number | undefined,
	prevPage?: number | undefined,
	totalDocs?: number | undefined,
	totalPages?: number | undefined
};
	["Notification_where"]: {
		receiver?: GraphQLTypes["Notification_receiver_operator"] | undefined,
	type?: GraphQLTypes["Notification_type_operator"] | undefined,
	meta?: GraphQLTypes["Notification_meta_Relation"] | undefined,
	message?: GraphQLTypes["Notification_message_operator"] | undefined,
	sender?: GraphQLTypes["Notification_sender_operator"] | undefined,
	readed?: GraphQLTypes["Notification_readed_operator"] | undefined,
	deletedByUser?: GraphQLTypes["Notification_deletedByUser_operator"] | undefined,
	updatedAt?: GraphQLTypes["Notification_updatedAt_operator"] | undefined,
	createdAt?: GraphQLTypes["Notification_createdAt_operator"] | undefined,
	id?: GraphQLTypes["Notification_id_operator"] | undefined,
	AND?: Array<GraphQLTypes["Notification_where_and"] | undefined> | undefined,
	OR?: Array<GraphQLTypes["Notification_where_or"] | undefined> | undefined
};
	["Notification_receiver_operator"]: {
		equals?: GraphQLTypes["JSON"] | undefined,
	not_equals?: GraphQLTypes["JSON"] | undefined,
	in?: Array<GraphQLTypes["JSON"] | undefined> | undefined,
	not_in?: Array<GraphQLTypes["JSON"] | undefined> | undefined,
	all?: Array<GraphQLTypes["JSON"] | undefined> | undefined,
	exists?: boolean | undefined
};
	["Notification_type_operator"]: {
		equals?: GraphQLTypes["Notification_type_Input"] | undefined,
	not_equals?: GraphQLTypes["Notification_type_Input"] | undefined,
	in?: Array<GraphQLTypes["Notification_type_Input"] | undefined> | undefined,
	not_in?: Array<GraphQLTypes["Notification_type_Input"] | undefined> | undefined,
	all?: Array<GraphQLTypes["Notification_type_Input"] | undefined> | undefined,
	exists?: boolean | undefined
};
	["Notification_type_Input"]: Notification_type_Input;
	["Notification_meta_Relation"]: {
		relationTo?: GraphQLTypes["Notification_meta_Relation_RelationTo"] | undefined,
	value?: GraphQLTypes["JSON"] | undefined
};
	["Notification_meta_Relation_RelationTo"]: Notification_meta_Relation_RelationTo;
	["Notification_message_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Notification_sender_operator"]: {
		equals?: GraphQLTypes["JSON"] | undefined,
	not_equals?: GraphQLTypes["JSON"] | undefined,
	in?: Array<GraphQLTypes["JSON"] | undefined> | undefined,
	not_in?: Array<GraphQLTypes["JSON"] | undefined> | undefined,
	all?: Array<GraphQLTypes["JSON"] | undefined> | undefined,
	exists?: boolean | undefined
};
	["Notification_readed_operator"]: {
		equals?: boolean | undefined,
	not_equals?: boolean | undefined,
	exists?: boolean | undefined
};
	["Notification_deletedByUser_operator"]: {
		equals?: boolean | undefined,
	not_equals?: boolean | undefined,
	exists?: boolean | undefined
};
	["Notification_updatedAt_operator"]: {
		equals?: GraphQLTypes["DateTime"] | undefined,
	not_equals?: GraphQLTypes["DateTime"] | undefined,
	greater_than_equal?: GraphQLTypes["DateTime"] | undefined,
	greater_than?: GraphQLTypes["DateTime"] | undefined,
	less_than_equal?: GraphQLTypes["DateTime"] | undefined,
	less_than?: GraphQLTypes["DateTime"] | undefined,
	like?: GraphQLTypes["DateTime"] | undefined,
	exists?: boolean | undefined
};
	["Notification_createdAt_operator"]: {
		equals?: GraphQLTypes["DateTime"] | undefined,
	not_equals?: GraphQLTypes["DateTime"] | undefined,
	greater_than_equal?: GraphQLTypes["DateTime"] | undefined,
	greater_than?: GraphQLTypes["DateTime"] | undefined,
	less_than_equal?: GraphQLTypes["DateTime"] | undefined,
	less_than?: GraphQLTypes["DateTime"] | undefined,
	like?: GraphQLTypes["DateTime"] | undefined,
	exists?: boolean | undefined
};
	["Notification_id_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Notification_where_and"]: {
		receiver?: GraphQLTypes["Notification_receiver_operator"] | undefined,
	type?: GraphQLTypes["Notification_type_operator"] | undefined,
	meta?: GraphQLTypes["Notification_meta_Relation"] | undefined,
	message?: GraphQLTypes["Notification_message_operator"] | undefined,
	sender?: GraphQLTypes["Notification_sender_operator"] | undefined,
	readed?: GraphQLTypes["Notification_readed_operator"] | undefined,
	deletedByUser?: GraphQLTypes["Notification_deletedByUser_operator"] | undefined,
	updatedAt?: GraphQLTypes["Notification_updatedAt_operator"] | undefined,
	createdAt?: GraphQLTypes["Notification_createdAt_operator"] | undefined,
	id?: GraphQLTypes["Notification_id_operator"] | undefined,
	AND?: Array<GraphQLTypes["Notification_where_and"] | undefined> | undefined,
	OR?: Array<GraphQLTypes["Notification_where_or"] | undefined> | undefined
};
	["Notification_where_or"]: {
		receiver?: GraphQLTypes["Notification_receiver_operator"] | undefined,
	type?: GraphQLTypes["Notification_type_operator"] | undefined,
	meta?: GraphQLTypes["Notification_meta_Relation"] | undefined,
	message?: GraphQLTypes["Notification_message_operator"] | undefined,
	sender?: GraphQLTypes["Notification_sender_operator"] | undefined,
	readed?: GraphQLTypes["Notification_readed_operator"] | undefined,
	deletedByUser?: GraphQLTypes["Notification_deletedByUser_operator"] | undefined,
	updatedAt?: GraphQLTypes["Notification_updatedAt_operator"] | undefined,
	createdAt?: GraphQLTypes["Notification_createdAt_operator"] | undefined,
	id?: GraphQLTypes["Notification_id_operator"] | undefined,
	AND?: Array<GraphQLTypes["Notification_where_and"] | undefined> | undefined,
	OR?: Array<GraphQLTypes["Notification_where_or"] | undefined> | undefined
};
	["countNotifications"]: {
	__typename: "countNotifications",
	totalDocs?: number | undefined
};
	["notificationsDocAccess"]: {
	__typename: "notificationsDocAccess",
	fields?: GraphQLTypes["NotificationsDocAccessFields"] | undefined,
	create?: GraphQLTypes["NotificationsCreateDocAccess"] | undefined,
	read?: GraphQLTypes["NotificationsReadDocAccess"] | undefined,
	update?: GraphQLTypes["NotificationsUpdateDocAccess"] | undefined,
	delete?: GraphQLTypes["NotificationsDeleteDocAccess"] | undefined
};
	["NotificationsDocAccessFields"]: {
	__typename: "NotificationsDocAccessFields",
	receiver?: GraphQLTypes["NotificationsDocAccessFields_receiver"] | undefined,
	type?: GraphQLTypes["NotificationsDocAccessFields_type"] | undefined,
	meta?: GraphQLTypes["NotificationsDocAccessFields_meta"] | undefined,
	message?: GraphQLTypes["NotificationsDocAccessFields_message"] | undefined,
	sender?: GraphQLTypes["NotificationsDocAccessFields_sender"] | undefined,
	readed?: GraphQLTypes["NotificationsDocAccessFields_readed"] | undefined,
	deletedByUser?: GraphQLTypes["NotificationsDocAccessFields_deletedByUser"] | undefined,
	updatedAt?: GraphQLTypes["NotificationsDocAccessFields_updatedAt"] | undefined,
	createdAt?: GraphQLTypes["NotificationsDocAccessFields_createdAt"] | undefined
};
	["NotificationsDocAccessFields_receiver"]: {
	__typename: "NotificationsDocAccessFields_receiver",
	create?: GraphQLTypes["NotificationsDocAccessFields_receiver_Create"] | undefined,
	read?: GraphQLTypes["NotificationsDocAccessFields_receiver_Read"] | undefined,
	update?: GraphQLTypes["NotificationsDocAccessFields_receiver_Update"] | undefined,
	delete?: GraphQLTypes["NotificationsDocAccessFields_receiver_Delete"] | undefined
};
	["NotificationsDocAccessFields_receiver_Create"]: {
	__typename: "NotificationsDocAccessFields_receiver_Create",
	permission: boolean
};
	["NotificationsDocAccessFields_receiver_Read"]: {
	__typename: "NotificationsDocAccessFields_receiver_Read",
	permission: boolean
};
	["NotificationsDocAccessFields_receiver_Update"]: {
	__typename: "NotificationsDocAccessFields_receiver_Update",
	permission: boolean
};
	["NotificationsDocAccessFields_receiver_Delete"]: {
	__typename: "NotificationsDocAccessFields_receiver_Delete",
	permission: boolean
};
	["NotificationsDocAccessFields_type"]: {
	__typename: "NotificationsDocAccessFields_type",
	create?: GraphQLTypes["NotificationsDocAccessFields_type_Create"] | undefined,
	read?: GraphQLTypes["NotificationsDocAccessFields_type_Read"] | undefined,
	update?: GraphQLTypes["NotificationsDocAccessFields_type_Update"] | undefined,
	delete?: GraphQLTypes["NotificationsDocAccessFields_type_Delete"] | undefined
};
	["NotificationsDocAccessFields_type_Create"]: {
	__typename: "NotificationsDocAccessFields_type_Create",
	permission: boolean
};
	["NotificationsDocAccessFields_type_Read"]: {
	__typename: "NotificationsDocAccessFields_type_Read",
	permission: boolean
};
	["NotificationsDocAccessFields_type_Update"]: {
	__typename: "NotificationsDocAccessFields_type_Update",
	permission: boolean
};
	["NotificationsDocAccessFields_type_Delete"]: {
	__typename: "NotificationsDocAccessFields_type_Delete",
	permission: boolean
};
	["NotificationsDocAccessFields_meta"]: {
	__typename: "NotificationsDocAccessFields_meta",
	create?: GraphQLTypes["NotificationsDocAccessFields_meta_Create"] | undefined,
	read?: GraphQLTypes["NotificationsDocAccessFields_meta_Read"] | undefined,
	update?: GraphQLTypes["NotificationsDocAccessFields_meta_Update"] | undefined,
	delete?: GraphQLTypes["NotificationsDocAccessFields_meta_Delete"] | undefined
};
	["NotificationsDocAccessFields_meta_Create"]: {
	__typename: "NotificationsDocAccessFields_meta_Create",
	permission: boolean
};
	["NotificationsDocAccessFields_meta_Read"]: {
	__typename: "NotificationsDocAccessFields_meta_Read",
	permission: boolean
};
	["NotificationsDocAccessFields_meta_Update"]: {
	__typename: "NotificationsDocAccessFields_meta_Update",
	permission: boolean
};
	["NotificationsDocAccessFields_meta_Delete"]: {
	__typename: "NotificationsDocAccessFields_meta_Delete",
	permission: boolean
};
	["NotificationsDocAccessFields_message"]: {
	__typename: "NotificationsDocAccessFields_message",
	create?: GraphQLTypes["NotificationsDocAccessFields_message_Create"] | undefined,
	read?: GraphQLTypes["NotificationsDocAccessFields_message_Read"] | undefined,
	update?: GraphQLTypes["NotificationsDocAccessFields_message_Update"] | undefined,
	delete?: GraphQLTypes["NotificationsDocAccessFields_message_Delete"] | undefined
};
	["NotificationsDocAccessFields_message_Create"]: {
	__typename: "NotificationsDocAccessFields_message_Create",
	permission: boolean
};
	["NotificationsDocAccessFields_message_Read"]: {
	__typename: "NotificationsDocAccessFields_message_Read",
	permission: boolean
};
	["NotificationsDocAccessFields_message_Update"]: {
	__typename: "NotificationsDocAccessFields_message_Update",
	permission: boolean
};
	["NotificationsDocAccessFields_message_Delete"]: {
	__typename: "NotificationsDocAccessFields_message_Delete",
	permission: boolean
};
	["NotificationsDocAccessFields_sender"]: {
	__typename: "NotificationsDocAccessFields_sender",
	create?: GraphQLTypes["NotificationsDocAccessFields_sender_Create"] | undefined,
	read?: GraphQLTypes["NotificationsDocAccessFields_sender_Read"] | undefined,
	update?: GraphQLTypes["NotificationsDocAccessFields_sender_Update"] | undefined,
	delete?: GraphQLTypes["NotificationsDocAccessFields_sender_Delete"] | undefined
};
	["NotificationsDocAccessFields_sender_Create"]: {
	__typename: "NotificationsDocAccessFields_sender_Create",
	permission: boolean
};
	["NotificationsDocAccessFields_sender_Read"]: {
	__typename: "NotificationsDocAccessFields_sender_Read",
	permission: boolean
};
	["NotificationsDocAccessFields_sender_Update"]: {
	__typename: "NotificationsDocAccessFields_sender_Update",
	permission: boolean
};
	["NotificationsDocAccessFields_sender_Delete"]: {
	__typename: "NotificationsDocAccessFields_sender_Delete",
	permission: boolean
};
	["NotificationsDocAccessFields_readed"]: {
	__typename: "NotificationsDocAccessFields_readed",
	create?: GraphQLTypes["NotificationsDocAccessFields_readed_Create"] | undefined,
	read?: GraphQLTypes["NotificationsDocAccessFields_readed_Read"] | undefined,
	update?: GraphQLTypes["NotificationsDocAccessFields_readed_Update"] | undefined,
	delete?: GraphQLTypes["NotificationsDocAccessFields_readed_Delete"] | undefined
};
	["NotificationsDocAccessFields_readed_Create"]: {
	__typename: "NotificationsDocAccessFields_readed_Create",
	permission: boolean
};
	["NotificationsDocAccessFields_readed_Read"]: {
	__typename: "NotificationsDocAccessFields_readed_Read",
	permission: boolean
};
	["NotificationsDocAccessFields_readed_Update"]: {
	__typename: "NotificationsDocAccessFields_readed_Update",
	permission: boolean
};
	["NotificationsDocAccessFields_readed_Delete"]: {
	__typename: "NotificationsDocAccessFields_readed_Delete",
	permission: boolean
};
	["NotificationsDocAccessFields_deletedByUser"]: {
	__typename: "NotificationsDocAccessFields_deletedByUser",
	create?: GraphQLTypes["NotificationsDocAccessFields_deletedByUser_Create"] | undefined,
	read?: GraphQLTypes["NotificationsDocAccessFields_deletedByUser_Read"] | undefined,
	update?: GraphQLTypes["NotificationsDocAccessFields_deletedByUser_Update"] | undefined,
	delete?: GraphQLTypes["NotificationsDocAccessFields_deletedByUser_Delete"] | undefined
};
	["NotificationsDocAccessFields_deletedByUser_Create"]: {
	__typename: "NotificationsDocAccessFields_deletedByUser_Create",
	permission: boolean
};
	["NotificationsDocAccessFields_deletedByUser_Read"]: {
	__typename: "NotificationsDocAccessFields_deletedByUser_Read",
	permission: boolean
};
	["NotificationsDocAccessFields_deletedByUser_Update"]: {
	__typename: "NotificationsDocAccessFields_deletedByUser_Update",
	permission: boolean
};
	["NotificationsDocAccessFields_deletedByUser_Delete"]: {
	__typename: "NotificationsDocAccessFields_deletedByUser_Delete",
	permission: boolean
};
	["NotificationsDocAccessFields_updatedAt"]: {
	__typename: "NotificationsDocAccessFields_updatedAt",
	create?: GraphQLTypes["NotificationsDocAccessFields_updatedAt_Create"] | undefined,
	read?: GraphQLTypes["NotificationsDocAccessFields_updatedAt_Read"] | undefined,
	update?: GraphQLTypes["NotificationsDocAccessFields_updatedAt_Update"] | undefined,
	delete?: GraphQLTypes["NotificationsDocAccessFields_updatedAt_Delete"] | undefined
};
	["NotificationsDocAccessFields_updatedAt_Create"]: {
	__typename: "NotificationsDocAccessFields_updatedAt_Create",
	permission: boolean
};
	["NotificationsDocAccessFields_updatedAt_Read"]: {
	__typename: "NotificationsDocAccessFields_updatedAt_Read",
	permission: boolean
};
	["NotificationsDocAccessFields_updatedAt_Update"]: {
	__typename: "NotificationsDocAccessFields_updatedAt_Update",
	permission: boolean
};
	["NotificationsDocAccessFields_updatedAt_Delete"]: {
	__typename: "NotificationsDocAccessFields_updatedAt_Delete",
	permission: boolean
};
	["NotificationsDocAccessFields_createdAt"]: {
	__typename: "NotificationsDocAccessFields_createdAt",
	create?: GraphQLTypes["NotificationsDocAccessFields_createdAt_Create"] | undefined,
	read?: GraphQLTypes["NotificationsDocAccessFields_createdAt_Read"] | undefined,
	update?: GraphQLTypes["NotificationsDocAccessFields_createdAt_Update"] | undefined,
	delete?: GraphQLTypes["NotificationsDocAccessFields_createdAt_Delete"] | undefined
};
	["NotificationsDocAccessFields_createdAt_Create"]: {
	__typename: "NotificationsDocAccessFields_createdAt_Create",
	permission: boolean
};
	["NotificationsDocAccessFields_createdAt_Read"]: {
	__typename: "NotificationsDocAccessFields_createdAt_Read",
	permission: boolean
};
	["NotificationsDocAccessFields_createdAt_Update"]: {
	__typename: "NotificationsDocAccessFields_createdAt_Update",
	permission: boolean
};
	["NotificationsDocAccessFields_createdAt_Delete"]: {
	__typename: "NotificationsDocAccessFields_createdAt_Delete",
	permission: boolean
};
	["NotificationsCreateDocAccess"]: {
	__typename: "NotificationsCreateDocAccess",
	permission: boolean,
	where?: GraphQLTypes["JSONObject"] | undefined
};
	["NotificationsReadDocAccess"]: {
	__typename: "NotificationsReadDocAccess",
	permission: boolean,
	where?: GraphQLTypes["JSONObject"] | undefined
};
	["NotificationsUpdateDocAccess"]: {
	__typename: "NotificationsUpdateDocAccess",
	permission: boolean,
	where?: GraphQLTypes["JSONObject"] | undefined
};
	["NotificationsDeleteDocAccess"]: {
	__typename: "NotificationsDeleteDocAccess",
	permission: boolean,
	where?: GraphQLTypes["JSONObject"] | undefined
};
	["RedirectSetting"]: {
	__typename: "RedirectSetting",
	id?: string | undefined,
	from?: string | undefined,
	to?: string | undefined,
	updatedAt?: GraphQLTypes["DateTime"] | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined
};
	["RedirectSettings"]: {
	__typename: "RedirectSettings",
	docs?: Array<GraphQLTypes["RedirectSetting"] | undefined> | undefined,
	hasNextPage?: boolean | undefined,
	hasPrevPage?: boolean | undefined,
	limit?: number | undefined,
	nextPage?: number | undefined,
	offset?: number | undefined,
	page?: number | undefined,
	pagingCounter?: number | undefined,
	prevPage?: number | undefined,
	totalDocs?: number | undefined,
	totalPages?: number | undefined
};
	["RedirectSetting_where"]: {
		from?: GraphQLTypes["RedirectSetting_from_operator"] | undefined,
	to?: GraphQLTypes["RedirectSetting_to_operator"] | undefined,
	updatedAt?: GraphQLTypes["RedirectSetting_updatedAt_operator"] | undefined,
	createdAt?: GraphQLTypes["RedirectSetting_createdAt_operator"] | undefined,
	id?: GraphQLTypes["RedirectSetting_id_operator"] | undefined,
	AND?: Array<GraphQLTypes["RedirectSetting_where_and"] | undefined> | undefined,
	OR?: Array<GraphQLTypes["RedirectSetting_where_or"] | undefined> | undefined
};
	["RedirectSetting_from_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["RedirectSetting_to_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["RedirectSetting_updatedAt_operator"]: {
		equals?: GraphQLTypes["DateTime"] | undefined,
	not_equals?: GraphQLTypes["DateTime"] | undefined,
	greater_than_equal?: GraphQLTypes["DateTime"] | undefined,
	greater_than?: GraphQLTypes["DateTime"] | undefined,
	less_than_equal?: GraphQLTypes["DateTime"] | undefined,
	less_than?: GraphQLTypes["DateTime"] | undefined,
	like?: GraphQLTypes["DateTime"] | undefined,
	exists?: boolean | undefined
};
	["RedirectSetting_createdAt_operator"]: {
		equals?: GraphQLTypes["DateTime"] | undefined,
	not_equals?: GraphQLTypes["DateTime"] | undefined,
	greater_than_equal?: GraphQLTypes["DateTime"] | undefined,
	greater_than?: GraphQLTypes["DateTime"] | undefined,
	less_than_equal?: GraphQLTypes["DateTime"] | undefined,
	less_than?: GraphQLTypes["DateTime"] | undefined,
	like?: GraphQLTypes["DateTime"] | undefined,
	exists?: boolean | undefined
};
	["RedirectSetting_id_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["RedirectSetting_where_and"]: {
		from?: GraphQLTypes["RedirectSetting_from_operator"] | undefined,
	to?: GraphQLTypes["RedirectSetting_to_operator"] | undefined,
	updatedAt?: GraphQLTypes["RedirectSetting_updatedAt_operator"] | undefined,
	createdAt?: GraphQLTypes["RedirectSetting_createdAt_operator"] | undefined,
	id?: GraphQLTypes["RedirectSetting_id_operator"] | undefined,
	AND?: Array<GraphQLTypes["RedirectSetting_where_and"] | undefined> | undefined,
	OR?: Array<GraphQLTypes["RedirectSetting_where_or"] | undefined> | undefined
};
	["RedirectSetting_where_or"]: {
		from?: GraphQLTypes["RedirectSetting_from_operator"] | undefined,
	to?: GraphQLTypes["RedirectSetting_to_operator"] | undefined,
	updatedAt?: GraphQLTypes["RedirectSetting_updatedAt_operator"] | undefined,
	createdAt?: GraphQLTypes["RedirectSetting_createdAt_operator"] | undefined,
	id?: GraphQLTypes["RedirectSetting_id_operator"] | undefined,
	AND?: Array<GraphQLTypes["RedirectSetting_where_and"] | undefined> | undefined,
	OR?: Array<GraphQLTypes["RedirectSetting_where_or"] | undefined> | undefined
};
	["countRedirectSettings"]: {
	__typename: "countRedirectSettings",
	totalDocs?: number | undefined
};
	["redirectSettingDocAccess"]: {
	__typename: "redirectSettingDocAccess",
	fields?: GraphQLTypes["RedirectSettingDocAccessFields"] | undefined,
	create?: GraphQLTypes["RedirectSettingCreateDocAccess"] | undefined,
	read?: GraphQLTypes["RedirectSettingReadDocAccess"] | undefined,
	update?: GraphQLTypes["RedirectSettingUpdateDocAccess"] | undefined,
	delete?: GraphQLTypes["RedirectSettingDeleteDocAccess"] | undefined
};
	["RedirectSettingDocAccessFields"]: {
	__typename: "RedirectSettingDocAccessFields",
	from?: GraphQLTypes["RedirectSettingDocAccessFields_from"] | undefined,
	to?: GraphQLTypes["RedirectSettingDocAccessFields_to"] | undefined,
	updatedAt?: GraphQLTypes["RedirectSettingDocAccessFields_updatedAt"] | undefined,
	createdAt?: GraphQLTypes["RedirectSettingDocAccessFields_createdAt"] | undefined
};
	["RedirectSettingDocAccessFields_from"]: {
	__typename: "RedirectSettingDocAccessFields_from",
	create?: GraphQLTypes["RedirectSettingDocAccessFields_from_Create"] | undefined,
	read?: GraphQLTypes["RedirectSettingDocAccessFields_from_Read"] | undefined,
	update?: GraphQLTypes["RedirectSettingDocAccessFields_from_Update"] | undefined,
	delete?: GraphQLTypes["RedirectSettingDocAccessFields_from_Delete"] | undefined
};
	["RedirectSettingDocAccessFields_from_Create"]: {
	__typename: "RedirectSettingDocAccessFields_from_Create",
	permission: boolean
};
	["RedirectSettingDocAccessFields_from_Read"]: {
	__typename: "RedirectSettingDocAccessFields_from_Read",
	permission: boolean
};
	["RedirectSettingDocAccessFields_from_Update"]: {
	__typename: "RedirectSettingDocAccessFields_from_Update",
	permission: boolean
};
	["RedirectSettingDocAccessFields_from_Delete"]: {
	__typename: "RedirectSettingDocAccessFields_from_Delete",
	permission: boolean
};
	["RedirectSettingDocAccessFields_to"]: {
	__typename: "RedirectSettingDocAccessFields_to",
	create?: GraphQLTypes["RedirectSettingDocAccessFields_to_Create"] | undefined,
	read?: GraphQLTypes["RedirectSettingDocAccessFields_to_Read"] | undefined,
	update?: GraphQLTypes["RedirectSettingDocAccessFields_to_Update"] | undefined,
	delete?: GraphQLTypes["RedirectSettingDocAccessFields_to_Delete"] | undefined
};
	["RedirectSettingDocAccessFields_to_Create"]: {
	__typename: "RedirectSettingDocAccessFields_to_Create",
	permission: boolean
};
	["RedirectSettingDocAccessFields_to_Read"]: {
	__typename: "RedirectSettingDocAccessFields_to_Read",
	permission: boolean
};
	["RedirectSettingDocAccessFields_to_Update"]: {
	__typename: "RedirectSettingDocAccessFields_to_Update",
	permission: boolean
};
	["RedirectSettingDocAccessFields_to_Delete"]: {
	__typename: "RedirectSettingDocAccessFields_to_Delete",
	permission: boolean
};
	["RedirectSettingDocAccessFields_updatedAt"]: {
	__typename: "RedirectSettingDocAccessFields_updatedAt",
	create?: GraphQLTypes["RedirectSettingDocAccessFields_updatedAt_Create"] | undefined,
	read?: GraphQLTypes["RedirectSettingDocAccessFields_updatedAt_Read"] | undefined,
	update?: GraphQLTypes["RedirectSettingDocAccessFields_updatedAt_Update"] | undefined,
	delete?: GraphQLTypes["RedirectSettingDocAccessFields_updatedAt_Delete"] | undefined
};
	["RedirectSettingDocAccessFields_updatedAt_Create"]: {
	__typename: "RedirectSettingDocAccessFields_updatedAt_Create",
	permission: boolean
};
	["RedirectSettingDocAccessFields_updatedAt_Read"]: {
	__typename: "RedirectSettingDocAccessFields_updatedAt_Read",
	permission: boolean
};
	["RedirectSettingDocAccessFields_updatedAt_Update"]: {
	__typename: "RedirectSettingDocAccessFields_updatedAt_Update",
	permission: boolean
};
	["RedirectSettingDocAccessFields_updatedAt_Delete"]: {
	__typename: "RedirectSettingDocAccessFields_updatedAt_Delete",
	permission: boolean
};
	["RedirectSettingDocAccessFields_createdAt"]: {
	__typename: "RedirectSettingDocAccessFields_createdAt",
	create?: GraphQLTypes["RedirectSettingDocAccessFields_createdAt_Create"] | undefined,
	read?: GraphQLTypes["RedirectSettingDocAccessFields_createdAt_Read"] | undefined,
	update?: GraphQLTypes["RedirectSettingDocAccessFields_createdAt_Update"] | undefined,
	delete?: GraphQLTypes["RedirectSettingDocAccessFields_createdAt_Delete"] | undefined
};
	["RedirectSettingDocAccessFields_createdAt_Create"]: {
	__typename: "RedirectSettingDocAccessFields_createdAt_Create",
	permission: boolean
};
	["RedirectSettingDocAccessFields_createdAt_Read"]: {
	__typename: "RedirectSettingDocAccessFields_createdAt_Read",
	permission: boolean
};
	["RedirectSettingDocAccessFields_createdAt_Update"]: {
	__typename: "RedirectSettingDocAccessFields_createdAt_Update",
	permission: boolean
};
	["RedirectSettingDocAccessFields_createdAt_Delete"]: {
	__typename: "RedirectSettingDocAccessFields_createdAt_Delete",
	permission: boolean
};
	["RedirectSettingCreateDocAccess"]: {
	__typename: "RedirectSettingCreateDocAccess",
	permission: boolean,
	where?: GraphQLTypes["JSONObject"] | undefined
};
	["RedirectSettingReadDocAccess"]: {
	__typename: "RedirectSettingReadDocAccess",
	permission: boolean,
	where?: GraphQLTypes["JSONObject"] | undefined
};
	["RedirectSettingUpdateDocAccess"]: {
	__typename: "RedirectSettingUpdateDocAccess",
	permission: boolean,
	where?: GraphQLTypes["JSONObject"] | undefined
};
	["RedirectSettingDeleteDocAccess"]: {
	__typename: "RedirectSettingDeleteDocAccess",
	permission: boolean,
	where?: GraphQLTypes["JSONObject"] | undefined
};
	["EventRanking"]: {
	__typename: "EventRanking",
	id?: string | undefined,
	name?: string | undefined,
	visible?: boolean | undefined,
	maxItems: number,
	template: GraphQLTypes["EventRanking_template"],
	list?: Array<GraphQLTypes["EventRanking_List"]> | undefined,
	region?: GraphQLTypes["EventRanking_region"] | undefined,
	updatedAt?: GraphQLTypes["DateTime"] | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined
};
	["EventRanking_template"]: EventRanking_template;
	["EventRanking_List"]: {
	__typename: "EventRanking_List",
	item?: GraphQLTypes["Event"] | undefined,
	id?: string | undefined
};
	["EventRanking_region"]: EventRanking_region;
	["EventRankings"]: {
	__typename: "EventRankings",
	docs?: Array<GraphQLTypes["EventRanking"] | undefined> | undefined,
	hasNextPage?: boolean | undefined,
	hasPrevPage?: boolean | undefined,
	limit?: number | undefined,
	nextPage?: number | undefined,
	offset?: number | undefined,
	page?: number | undefined,
	pagingCounter?: number | undefined,
	prevPage?: number | undefined,
	totalDocs?: number | undefined,
	totalPages?: number | undefined
};
	["EventRanking_where"]: {
		name?: GraphQLTypes["EventRanking_name_operator"] | undefined,
	visible?: GraphQLTypes["EventRanking_visible_operator"] | undefined,
	maxItems?: GraphQLTypes["EventRanking_maxItems_operator"] | undefined,
	template?: GraphQLTypes["EventRanking_template_operator"] | undefined,
	list__item?: GraphQLTypes["EventRanking_list__item_operator"] | undefined,
	list__id?: GraphQLTypes["EventRanking_list__id_operator"] | undefined,
	region?: GraphQLTypes["EventRanking_region_operator"] | undefined,
	updatedAt?: GraphQLTypes["EventRanking_updatedAt_operator"] | undefined,
	createdAt?: GraphQLTypes["EventRanking_createdAt_operator"] | undefined,
	id?: GraphQLTypes["EventRanking_id_operator"] | undefined,
	AND?: Array<GraphQLTypes["EventRanking_where_and"] | undefined> | undefined,
	OR?: Array<GraphQLTypes["EventRanking_where_or"] | undefined> | undefined
};
	["EventRanking_name_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined
};
	["EventRanking_visible_operator"]: {
		equals?: boolean | undefined,
	not_equals?: boolean | undefined,
	exists?: boolean | undefined
};
	["EventRanking_maxItems_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined
};
	["EventRanking_template_operator"]: {
		equals?: GraphQLTypes["EventRanking_template_Input"] | undefined,
	not_equals?: GraphQLTypes["EventRanking_template_Input"] | undefined,
	in?: Array<GraphQLTypes["EventRanking_template_Input"] | undefined> | undefined,
	not_in?: Array<GraphQLTypes["EventRanking_template_Input"] | undefined> | undefined,
	all?: Array<GraphQLTypes["EventRanking_template_Input"] | undefined> | undefined
};
	["EventRanking_template_Input"]: EventRanking_template_Input;
	["EventRanking_list__item_operator"]: {
		equals?: GraphQLTypes["JSON"] | undefined,
	not_equals?: GraphQLTypes["JSON"] | undefined,
	in?: Array<GraphQLTypes["JSON"] | undefined> | undefined,
	not_in?: Array<GraphQLTypes["JSON"] | undefined> | undefined,
	all?: Array<GraphQLTypes["JSON"] | undefined> | undefined
};
	["EventRanking_list__id_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["EventRanking_region_operator"]: {
		equals?: GraphQLTypes["EventRanking_region_Input"] | undefined,
	not_equals?: GraphQLTypes["EventRanking_region_Input"] | undefined,
	in?: Array<GraphQLTypes["EventRanking_region_Input"] | undefined> | undefined,
	not_in?: Array<GraphQLTypes["EventRanking_region_Input"] | undefined> | undefined,
	all?: Array<GraphQLTypes["EventRanking_region_Input"] | undefined> | undefined,
	exists?: boolean | undefined
};
	["EventRanking_region_Input"]: EventRanking_region_Input;
	["EventRanking_updatedAt_operator"]: {
		equals?: GraphQLTypes["DateTime"] | undefined,
	not_equals?: GraphQLTypes["DateTime"] | undefined,
	greater_than_equal?: GraphQLTypes["DateTime"] | undefined,
	greater_than?: GraphQLTypes["DateTime"] | undefined,
	less_than_equal?: GraphQLTypes["DateTime"] | undefined,
	less_than?: GraphQLTypes["DateTime"] | undefined,
	like?: GraphQLTypes["DateTime"] | undefined,
	exists?: boolean | undefined
};
	["EventRanking_createdAt_operator"]: {
		equals?: GraphQLTypes["DateTime"] | undefined,
	not_equals?: GraphQLTypes["DateTime"] | undefined,
	greater_than_equal?: GraphQLTypes["DateTime"] | undefined,
	greater_than?: GraphQLTypes["DateTime"] | undefined,
	less_than_equal?: GraphQLTypes["DateTime"] | undefined,
	less_than?: GraphQLTypes["DateTime"] | undefined,
	like?: GraphQLTypes["DateTime"] | undefined,
	exists?: boolean | undefined
};
	["EventRanking_id_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["EventRanking_where_and"]: {
		name?: GraphQLTypes["EventRanking_name_operator"] | undefined,
	visible?: GraphQLTypes["EventRanking_visible_operator"] | undefined,
	maxItems?: GraphQLTypes["EventRanking_maxItems_operator"] | undefined,
	template?: GraphQLTypes["EventRanking_template_operator"] | undefined,
	list__item?: GraphQLTypes["EventRanking_list__item_operator"] | undefined,
	list__id?: GraphQLTypes["EventRanking_list__id_operator"] | undefined,
	region?: GraphQLTypes["EventRanking_region_operator"] | undefined,
	updatedAt?: GraphQLTypes["EventRanking_updatedAt_operator"] | undefined,
	createdAt?: GraphQLTypes["EventRanking_createdAt_operator"] | undefined,
	id?: GraphQLTypes["EventRanking_id_operator"] | undefined,
	AND?: Array<GraphQLTypes["EventRanking_where_and"] | undefined> | undefined,
	OR?: Array<GraphQLTypes["EventRanking_where_or"] | undefined> | undefined
};
	["EventRanking_where_or"]: {
		name?: GraphQLTypes["EventRanking_name_operator"] | undefined,
	visible?: GraphQLTypes["EventRanking_visible_operator"] | undefined,
	maxItems?: GraphQLTypes["EventRanking_maxItems_operator"] | undefined,
	template?: GraphQLTypes["EventRanking_template_operator"] | undefined,
	list__item?: GraphQLTypes["EventRanking_list__item_operator"] | undefined,
	list__id?: GraphQLTypes["EventRanking_list__id_operator"] | undefined,
	region?: GraphQLTypes["EventRanking_region_operator"] | undefined,
	updatedAt?: GraphQLTypes["EventRanking_updatedAt_operator"] | undefined,
	createdAt?: GraphQLTypes["EventRanking_createdAt_operator"] | undefined,
	id?: GraphQLTypes["EventRanking_id_operator"] | undefined,
	AND?: Array<GraphQLTypes["EventRanking_where_and"] | undefined> | undefined,
	OR?: Array<GraphQLTypes["EventRanking_where_or"] | undefined> | undefined
};
	["countEventRankings"]: {
	__typename: "countEventRankings",
	totalDocs?: number | undefined
};
	["ranking_eventsDocAccess"]: {
	__typename: "ranking_eventsDocAccess",
	fields?: GraphQLTypes["RankingEventsDocAccessFields"] | undefined,
	create?: GraphQLTypes["RankingEventsCreateDocAccess"] | undefined,
	read?: GraphQLTypes["RankingEventsReadDocAccess"] | undefined,
	update?: GraphQLTypes["RankingEventsUpdateDocAccess"] | undefined,
	delete?: GraphQLTypes["RankingEventsDeleteDocAccess"] | undefined
};
	["RankingEventsDocAccessFields"]: {
	__typename: "RankingEventsDocAccessFields",
	name?: GraphQLTypes["RankingEventsDocAccessFields_name"] | undefined,
	visible?: GraphQLTypes["RankingEventsDocAccessFields_visible"] | undefined,
	maxItems?: GraphQLTypes["RankingEventsDocAccessFields_maxItems"] | undefined,
	template?: GraphQLTypes["RankingEventsDocAccessFields_template"] | undefined,
	list?: GraphQLTypes["RankingEventsDocAccessFields_list"] | undefined,
	region?: GraphQLTypes["RankingEventsDocAccessFields_region"] | undefined,
	updatedAt?: GraphQLTypes["RankingEventsDocAccessFields_updatedAt"] | undefined,
	createdAt?: GraphQLTypes["RankingEventsDocAccessFields_createdAt"] | undefined
};
	["RankingEventsDocAccessFields_name"]: {
	__typename: "RankingEventsDocAccessFields_name",
	create?: GraphQLTypes["RankingEventsDocAccessFields_name_Create"] | undefined,
	read?: GraphQLTypes["RankingEventsDocAccessFields_name_Read"] | undefined,
	update?: GraphQLTypes["RankingEventsDocAccessFields_name_Update"] | undefined,
	delete?: GraphQLTypes["RankingEventsDocAccessFields_name_Delete"] | undefined
};
	["RankingEventsDocAccessFields_name_Create"]: {
	__typename: "RankingEventsDocAccessFields_name_Create",
	permission: boolean
};
	["RankingEventsDocAccessFields_name_Read"]: {
	__typename: "RankingEventsDocAccessFields_name_Read",
	permission: boolean
};
	["RankingEventsDocAccessFields_name_Update"]: {
	__typename: "RankingEventsDocAccessFields_name_Update",
	permission: boolean
};
	["RankingEventsDocAccessFields_name_Delete"]: {
	__typename: "RankingEventsDocAccessFields_name_Delete",
	permission: boolean
};
	["RankingEventsDocAccessFields_visible"]: {
	__typename: "RankingEventsDocAccessFields_visible",
	create?: GraphQLTypes["RankingEventsDocAccessFields_visible_Create"] | undefined,
	read?: GraphQLTypes["RankingEventsDocAccessFields_visible_Read"] | undefined,
	update?: GraphQLTypes["RankingEventsDocAccessFields_visible_Update"] | undefined,
	delete?: GraphQLTypes["RankingEventsDocAccessFields_visible_Delete"] | undefined
};
	["RankingEventsDocAccessFields_visible_Create"]: {
	__typename: "RankingEventsDocAccessFields_visible_Create",
	permission: boolean
};
	["RankingEventsDocAccessFields_visible_Read"]: {
	__typename: "RankingEventsDocAccessFields_visible_Read",
	permission: boolean
};
	["RankingEventsDocAccessFields_visible_Update"]: {
	__typename: "RankingEventsDocAccessFields_visible_Update",
	permission: boolean
};
	["RankingEventsDocAccessFields_visible_Delete"]: {
	__typename: "RankingEventsDocAccessFields_visible_Delete",
	permission: boolean
};
	["RankingEventsDocAccessFields_maxItems"]: {
	__typename: "RankingEventsDocAccessFields_maxItems",
	create?: GraphQLTypes["RankingEventsDocAccessFields_maxItems_Create"] | undefined,
	read?: GraphQLTypes["RankingEventsDocAccessFields_maxItems_Read"] | undefined,
	update?: GraphQLTypes["RankingEventsDocAccessFields_maxItems_Update"] | undefined,
	delete?: GraphQLTypes["RankingEventsDocAccessFields_maxItems_Delete"] | undefined
};
	["RankingEventsDocAccessFields_maxItems_Create"]: {
	__typename: "RankingEventsDocAccessFields_maxItems_Create",
	permission: boolean
};
	["RankingEventsDocAccessFields_maxItems_Read"]: {
	__typename: "RankingEventsDocAccessFields_maxItems_Read",
	permission: boolean
};
	["RankingEventsDocAccessFields_maxItems_Update"]: {
	__typename: "RankingEventsDocAccessFields_maxItems_Update",
	permission: boolean
};
	["RankingEventsDocAccessFields_maxItems_Delete"]: {
	__typename: "RankingEventsDocAccessFields_maxItems_Delete",
	permission: boolean
};
	["RankingEventsDocAccessFields_template"]: {
	__typename: "RankingEventsDocAccessFields_template",
	create?: GraphQLTypes["RankingEventsDocAccessFields_template_Create"] | undefined,
	read?: GraphQLTypes["RankingEventsDocAccessFields_template_Read"] | undefined,
	update?: GraphQLTypes["RankingEventsDocAccessFields_template_Update"] | undefined,
	delete?: GraphQLTypes["RankingEventsDocAccessFields_template_Delete"] | undefined
};
	["RankingEventsDocAccessFields_template_Create"]: {
	__typename: "RankingEventsDocAccessFields_template_Create",
	permission: boolean
};
	["RankingEventsDocAccessFields_template_Read"]: {
	__typename: "RankingEventsDocAccessFields_template_Read",
	permission: boolean
};
	["RankingEventsDocAccessFields_template_Update"]: {
	__typename: "RankingEventsDocAccessFields_template_Update",
	permission: boolean
};
	["RankingEventsDocAccessFields_template_Delete"]: {
	__typename: "RankingEventsDocAccessFields_template_Delete",
	permission: boolean
};
	["RankingEventsDocAccessFields_list"]: {
	__typename: "RankingEventsDocAccessFields_list",
	create?: GraphQLTypes["RankingEventsDocAccessFields_list_Create"] | undefined,
	read?: GraphQLTypes["RankingEventsDocAccessFields_list_Read"] | undefined,
	update?: GraphQLTypes["RankingEventsDocAccessFields_list_Update"] | undefined,
	delete?: GraphQLTypes["RankingEventsDocAccessFields_list_Delete"] | undefined,
	fields?: GraphQLTypes["RankingEventsDocAccessFields_list_Fields"] | undefined
};
	["RankingEventsDocAccessFields_list_Create"]: {
	__typename: "RankingEventsDocAccessFields_list_Create",
	permission: boolean
};
	["RankingEventsDocAccessFields_list_Read"]: {
	__typename: "RankingEventsDocAccessFields_list_Read",
	permission: boolean
};
	["RankingEventsDocAccessFields_list_Update"]: {
	__typename: "RankingEventsDocAccessFields_list_Update",
	permission: boolean
};
	["RankingEventsDocAccessFields_list_Delete"]: {
	__typename: "RankingEventsDocAccessFields_list_Delete",
	permission: boolean
};
	["RankingEventsDocAccessFields_list_Fields"]: {
	__typename: "RankingEventsDocAccessFields_list_Fields",
	item?: GraphQLTypes["RankingEventsDocAccessFields_list_item"] | undefined,
	id?: GraphQLTypes["RankingEventsDocAccessFields_list_id"] | undefined
};
	["RankingEventsDocAccessFields_list_item"]: {
	__typename: "RankingEventsDocAccessFields_list_item",
	create?: GraphQLTypes["RankingEventsDocAccessFields_list_item_Create"] | undefined,
	read?: GraphQLTypes["RankingEventsDocAccessFields_list_item_Read"] | undefined,
	update?: GraphQLTypes["RankingEventsDocAccessFields_list_item_Update"] | undefined,
	delete?: GraphQLTypes["RankingEventsDocAccessFields_list_item_Delete"] | undefined
};
	["RankingEventsDocAccessFields_list_item_Create"]: {
	__typename: "RankingEventsDocAccessFields_list_item_Create",
	permission: boolean
};
	["RankingEventsDocAccessFields_list_item_Read"]: {
	__typename: "RankingEventsDocAccessFields_list_item_Read",
	permission: boolean
};
	["RankingEventsDocAccessFields_list_item_Update"]: {
	__typename: "RankingEventsDocAccessFields_list_item_Update",
	permission: boolean
};
	["RankingEventsDocAccessFields_list_item_Delete"]: {
	__typename: "RankingEventsDocAccessFields_list_item_Delete",
	permission: boolean
};
	["RankingEventsDocAccessFields_list_id"]: {
	__typename: "RankingEventsDocAccessFields_list_id",
	create?: GraphQLTypes["RankingEventsDocAccessFields_list_id_Create"] | undefined,
	read?: GraphQLTypes["RankingEventsDocAccessFields_list_id_Read"] | undefined,
	update?: GraphQLTypes["RankingEventsDocAccessFields_list_id_Update"] | undefined,
	delete?: GraphQLTypes["RankingEventsDocAccessFields_list_id_Delete"] | undefined
};
	["RankingEventsDocAccessFields_list_id_Create"]: {
	__typename: "RankingEventsDocAccessFields_list_id_Create",
	permission: boolean
};
	["RankingEventsDocAccessFields_list_id_Read"]: {
	__typename: "RankingEventsDocAccessFields_list_id_Read",
	permission: boolean
};
	["RankingEventsDocAccessFields_list_id_Update"]: {
	__typename: "RankingEventsDocAccessFields_list_id_Update",
	permission: boolean
};
	["RankingEventsDocAccessFields_list_id_Delete"]: {
	__typename: "RankingEventsDocAccessFields_list_id_Delete",
	permission: boolean
};
	["RankingEventsDocAccessFields_region"]: {
	__typename: "RankingEventsDocAccessFields_region",
	create?: GraphQLTypes["RankingEventsDocAccessFields_region_Create"] | undefined,
	read?: GraphQLTypes["RankingEventsDocAccessFields_region_Read"] | undefined,
	update?: GraphQLTypes["RankingEventsDocAccessFields_region_Update"] | undefined,
	delete?: GraphQLTypes["RankingEventsDocAccessFields_region_Delete"] | undefined
};
	["RankingEventsDocAccessFields_region_Create"]: {
	__typename: "RankingEventsDocAccessFields_region_Create",
	permission: boolean
};
	["RankingEventsDocAccessFields_region_Read"]: {
	__typename: "RankingEventsDocAccessFields_region_Read",
	permission: boolean
};
	["RankingEventsDocAccessFields_region_Update"]: {
	__typename: "RankingEventsDocAccessFields_region_Update",
	permission: boolean
};
	["RankingEventsDocAccessFields_region_Delete"]: {
	__typename: "RankingEventsDocAccessFields_region_Delete",
	permission: boolean
};
	["RankingEventsDocAccessFields_updatedAt"]: {
	__typename: "RankingEventsDocAccessFields_updatedAt",
	create?: GraphQLTypes["RankingEventsDocAccessFields_updatedAt_Create"] | undefined,
	read?: GraphQLTypes["RankingEventsDocAccessFields_updatedAt_Read"] | undefined,
	update?: GraphQLTypes["RankingEventsDocAccessFields_updatedAt_Update"] | undefined,
	delete?: GraphQLTypes["RankingEventsDocAccessFields_updatedAt_Delete"] | undefined
};
	["RankingEventsDocAccessFields_updatedAt_Create"]: {
	__typename: "RankingEventsDocAccessFields_updatedAt_Create",
	permission: boolean
};
	["RankingEventsDocAccessFields_updatedAt_Read"]: {
	__typename: "RankingEventsDocAccessFields_updatedAt_Read",
	permission: boolean
};
	["RankingEventsDocAccessFields_updatedAt_Update"]: {
	__typename: "RankingEventsDocAccessFields_updatedAt_Update",
	permission: boolean
};
	["RankingEventsDocAccessFields_updatedAt_Delete"]: {
	__typename: "RankingEventsDocAccessFields_updatedAt_Delete",
	permission: boolean
};
	["RankingEventsDocAccessFields_createdAt"]: {
	__typename: "RankingEventsDocAccessFields_createdAt",
	create?: GraphQLTypes["RankingEventsDocAccessFields_createdAt_Create"] | undefined,
	read?: GraphQLTypes["RankingEventsDocAccessFields_createdAt_Read"] | undefined,
	update?: GraphQLTypes["RankingEventsDocAccessFields_createdAt_Update"] | undefined,
	delete?: GraphQLTypes["RankingEventsDocAccessFields_createdAt_Delete"] | undefined
};
	["RankingEventsDocAccessFields_createdAt_Create"]: {
	__typename: "RankingEventsDocAccessFields_createdAt_Create",
	permission: boolean
};
	["RankingEventsDocAccessFields_createdAt_Read"]: {
	__typename: "RankingEventsDocAccessFields_createdAt_Read",
	permission: boolean
};
	["RankingEventsDocAccessFields_createdAt_Update"]: {
	__typename: "RankingEventsDocAccessFields_createdAt_Update",
	permission: boolean
};
	["RankingEventsDocAccessFields_createdAt_Delete"]: {
	__typename: "RankingEventsDocAccessFields_createdAt_Delete",
	permission: boolean
};
	["RankingEventsCreateDocAccess"]: {
	__typename: "RankingEventsCreateDocAccess",
	permission: boolean,
	where?: GraphQLTypes["JSONObject"] | undefined
};
	["RankingEventsReadDocAccess"]: {
	__typename: "RankingEventsReadDocAccess",
	permission: boolean,
	where?: GraphQLTypes["JSONObject"] | undefined
};
	["RankingEventsUpdateDocAccess"]: {
	__typename: "RankingEventsUpdateDocAccess",
	permission: boolean,
	where?: GraphQLTypes["JSONObject"] | undefined
};
	["RankingEventsDeleteDocAccess"]: {
	__typename: "RankingEventsDeleteDocAccess",
	permission: boolean,
	where?: GraphQLTypes["JSONObject"] | undefined
};
	["LocationRanking"]: {
	__typename: "LocationRanking",
	id?: string | undefined,
	name?: string | undefined,
	visible?: boolean | undefined,
	maxItems?: number | undefined,
	template: GraphQLTypes["LocationRanking_template"],
	list?: Array<GraphQLTypes["LocationRanking_List"]> | undefined,
	region?: GraphQLTypes["LocationRanking_region"] | undefined,
	updatedAt?: GraphQLTypes["DateTime"] | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined
};
	["LocationRanking_template"]: LocationRanking_template;
	["LocationRanking_List"]: {
	__typename: "LocationRanking_List",
	item?: GraphQLTypes["Location"] | undefined,
	id?: string | undefined
};
	["LocationRanking_region"]: LocationRanking_region;
	["LocationRankings"]: {
	__typename: "LocationRankings",
	docs?: Array<GraphQLTypes["LocationRanking"] | undefined> | undefined,
	hasNextPage?: boolean | undefined,
	hasPrevPage?: boolean | undefined,
	limit?: number | undefined,
	nextPage?: number | undefined,
	offset?: number | undefined,
	page?: number | undefined,
	pagingCounter?: number | undefined,
	prevPage?: number | undefined,
	totalDocs?: number | undefined,
	totalPages?: number | undefined
};
	["LocationRanking_where"]: {
		name?: GraphQLTypes["LocationRanking_name_operator"] | undefined,
	visible?: GraphQLTypes["LocationRanking_visible_operator"] | undefined,
	maxItems?: GraphQLTypes["LocationRanking_maxItems_operator"] | undefined,
	template?: GraphQLTypes["LocationRanking_template_operator"] | undefined,
	list__item?: GraphQLTypes["LocationRanking_list__item_operator"] | undefined,
	list__id?: GraphQLTypes["LocationRanking_list__id_operator"] | undefined,
	region?: GraphQLTypes["LocationRanking_region_operator"] | undefined,
	updatedAt?: GraphQLTypes["LocationRanking_updatedAt_operator"] | undefined,
	createdAt?: GraphQLTypes["LocationRanking_createdAt_operator"] | undefined,
	id?: GraphQLTypes["LocationRanking_id_operator"] | undefined,
	AND?: Array<GraphQLTypes["LocationRanking_where_and"] | undefined> | undefined,
	OR?: Array<GraphQLTypes["LocationRanking_where_or"] | undefined> | undefined
};
	["LocationRanking_name_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined
};
	["LocationRanking_visible_operator"]: {
		equals?: boolean | undefined,
	not_equals?: boolean | undefined,
	exists?: boolean | undefined
};
	["LocationRanking_maxItems_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["LocationRanking_template_operator"]: {
		equals?: GraphQLTypes["LocationRanking_template_Input"] | undefined,
	not_equals?: GraphQLTypes["LocationRanking_template_Input"] | undefined,
	in?: Array<GraphQLTypes["LocationRanking_template_Input"] | undefined> | undefined,
	not_in?: Array<GraphQLTypes["LocationRanking_template_Input"] | undefined> | undefined,
	all?: Array<GraphQLTypes["LocationRanking_template_Input"] | undefined> | undefined
};
	["LocationRanking_template_Input"]: LocationRanking_template_Input;
	["LocationRanking_list__item_operator"]: {
		equals?: GraphQLTypes["JSON"] | undefined,
	not_equals?: GraphQLTypes["JSON"] | undefined,
	in?: Array<GraphQLTypes["JSON"] | undefined> | undefined,
	not_in?: Array<GraphQLTypes["JSON"] | undefined> | undefined,
	all?: Array<GraphQLTypes["JSON"] | undefined> | undefined
};
	["LocationRanking_list__id_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["LocationRanking_region_operator"]: {
		equals?: GraphQLTypes["LocationRanking_region_Input"] | undefined,
	not_equals?: GraphQLTypes["LocationRanking_region_Input"] | undefined,
	in?: Array<GraphQLTypes["LocationRanking_region_Input"] | undefined> | undefined,
	not_in?: Array<GraphQLTypes["LocationRanking_region_Input"] | undefined> | undefined,
	all?: Array<GraphQLTypes["LocationRanking_region_Input"] | undefined> | undefined,
	exists?: boolean | undefined
};
	["LocationRanking_region_Input"]: LocationRanking_region_Input;
	["LocationRanking_updatedAt_operator"]: {
		equals?: GraphQLTypes["DateTime"] | undefined,
	not_equals?: GraphQLTypes["DateTime"] | undefined,
	greater_than_equal?: GraphQLTypes["DateTime"] | undefined,
	greater_than?: GraphQLTypes["DateTime"] | undefined,
	less_than_equal?: GraphQLTypes["DateTime"] | undefined,
	less_than?: GraphQLTypes["DateTime"] | undefined,
	like?: GraphQLTypes["DateTime"] | undefined,
	exists?: boolean | undefined
};
	["LocationRanking_createdAt_operator"]: {
		equals?: GraphQLTypes["DateTime"] | undefined,
	not_equals?: GraphQLTypes["DateTime"] | undefined,
	greater_than_equal?: GraphQLTypes["DateTime"] | undefined,
	greater_than?: GraphQLTypes["DateTime"] | undefined,
	less_than_equal?: GraphQLTypes["DateTime"] | undefined,
	less_than?: GraphQLTypes["DateTime"] | undefined,
	like?: GraphQLTypes["DateTime"] | undefined,
	exists?: boolean | undefined
};
	["LocationRanking_id_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["LocationRanking_where_and"]: {
		name?: GraphQLTypes["LocationRanking_name_operator"] | undefined,
	visible?: GraphQLTypes["LocationRanking_visible_operator"] | undefined,
	maxItems?: GraphQLTypes["LocationRanking_maxItems_operator"] | undefined,
	template?: GraphQLTypes["LocationRanking_template_operator"] | undefined,
	list__item?: GraphQLTypes["LocationRanking_list__item_operator"] | undefined,
	list__id?: GraphQLTypes["LocationRanking_list__id_operator"] | undefined,
	region?: GraphQLTypes["LocationRanking_region_operator"] | undefined,
	updatedAt?: GraphQLTypes["LocationRanking_updatedAt_operator"] | undefined,
	createdAt?: GraphQLTypes["LocationRanking_createdAt_operator"] | undefined,
	id?: GraphQLTypes["LocationRanking_id_operator"] | undefined,
	AND?: Array<GraphQLTypes["LocationRanking_where_and"] | undefined> | undefined,
	OR?: Array<GraphQLTypes["LocationRanking_where_or"] | undefined> | undefined
};
	["LocationRanking_where_or"]: {
		name?: GraphQLTypes["LocationRanking_name_operator"] | undefined,
	visible?: GraphQLTypes["LocationRanking_visible_operator"] | undefined,
	maxItems?: GraphQLTypes["LocationRanking_maxItems_operator"] | undefined,
	template?: GraphQLTypes["LocationRanking_template_operator"] | undefined,
	list__item?: GraphQLTypes["LocationRanking_list__item_operator"] | undefined,
	list__id?: GraphQLTypes["LocationRanking_list__id_operator"] | undefined,
	region?: GraphQLTypes["LocationRanking_region_operator"] | undefined,
	updatedAt?: GraphQLTypes["LocationRanking_updatedAt_operator"] | undefined,
	createdAt?: GraphQLTypes["LocationRanking_createdAt_operator"] | undefined,
	id?: GraphQLTypes["LocationRanking_id_operator"] | undefined,
	AND?: Array<GraphQLTypes["LocationRanking_where_and"] | undefined> | undefined,
	OR?: Array<GraphQLTypes["LocationRanking_where_or"] | undefined> | undefined
};
	["countLocationRankings"]: {
	__typename: "countLocationRankings",
	totalDocs?: number | undefined
};
	["ranking_locationsDocAccess"]: {
	__typename: "ranking_locationsDocAccess",
	fields?: GraphQLTypes["RankingLocationsDocAccessFields"] | undefined,
	create?: GraphQLTypes["RankingLocationsCreateDocAccess"] | undefined,
	read?: GraphQLTypes["RankingLocationsReadDocAccess"] | undefined,
	update?: GraphQLTypes["RankingLocationsUpdateDocAccess"] | undefined,
	delete?: GraphQLTypes["RankingLocationsDeleteDocAccess"] | undefined
};
	["RankingLocationsDocAccessFields"]: {
	__typename: "RankingLocationsDocAccessFields",
	name?: GraphQLTypes["RankingLocationsDocAccessFields_name"] | undefined,
	visible?: GraphQLTypes["RankingLocationsDocAccessFields_visible"] | undefined,
	maxItems?: GraphQLTypes["RankingLocationsDocAccessFields_maxItems"] | undefined,
	template?: GraphQLTypes["RankingLocationsDocAccessFields_template"] | undefined,
	list?: GraphQLTypes["RankingLocationsDocAccessFields_list"] | undefined,
	region?: GraphQLTypes["RankingLocationsDocAccessFields_region"] | undefined,
	updatedAt?: GraphQLTypes["RankingLocationsDocAccessFields_updatedAt"] | undefined,
	createdAt?: GraphQLTypes["RankingLocationsDocAccessFields_createdAt"] | undefined
};
	["RankingLocationsDocAccessFields_name"]: {
	__typename: "RankingLocationsDocAccessFields_name",
	create?: GraphQLTypes["RankingLocationsDocAccessFields_name_Create"] | undefined,
	read?: GraphQLTypes["RankingLocationsDocAccessFields_name_Read"] | undefined,
	update?: GraphQLTypes["RankingLocationsDocAccessFields_name_Update"] | undefined,
	delete?: GraphQLTypes["RankingLocationsDocAccessFields_name_Delete"] | undefined
};
	["RankingLocationsDocAccessFields_name_Create"]: {
	__typename: "RankingLocationsDocAccessFields_name_Create",
	permission: boolean
};
	["RankingLocationsDocAccessFields_name_Read"]: {
	__typename: "RankingLocationsDocAccessFields_name_Read",
	permission: boolean
};
	["RankingLocationsDocAccessFields_name_Update"]: {
	__typename: "RankingLocationsDocAccessFields_name_Update",
	permission: boolean
};
	["RankingLocationsDocAccessFields_name_Delete"]: {
	__typename: "RankingLocationsDocAccessFields_name_Delete",
	permission: boolean
};
	["RankingLocationsDocAccessFields_visible"]: {
	__typename: "RankingLocationsDocAccessFields_visible",
	create?: GraphQLTypes["RankingLocationsDocAccessFields_visible_Create"] | undefined,
	read?: GraphQLTypes["RankingLocationsDocAccessFields_visible_Read"] | undefined,
	update?: GraphQLTypes["RankingLocationsDocAccessFields_visible_Update"] | undefined,
	delete?: GraphQLTypes["RankingLocationsDocAccessFields_visible_Delete"] | undefined
};
	["RankingLocationsDocAccessFields_visible_Create"]: {
	__typename: "RankingLocationsDocAccessFields_visible_Create",
	permission: boolean
};
	["RankingLocationsDocAccessFields_visible_Read"]: {
	__typename: "RankingLocationsDocAccessFields_visible_Read",
	permission: boolean
};
	["RankingLocationsDocAccessFields_visible_Update"]: {
	__typename: "RankingLocationsDocAccessFields_visible_Update",
	permission: boolean
};
	["RankingLocationsDocAccessFields_visible_Delete"]: {
	__typename: "RankingLocationsDocAccessFields_visible_Delete",
	permission: boolean
};
	["RankingLocationsDocAccessFields_maxItems"]: {
	__typename: "RankingLocationsDocAccessFields_maxItems",
	create?: GraphQLTypes["RankingLocationsDocAccessFields_maxItems_Create"] | undefined,
	read?: GraphQLTypes["RankingLocationsDocAccessFields_maxItems_Read"] | undefined,
	update?: GraphQLTypes["RankingLocationsDocAccessFields_maxItems_Update"] | undefined,
	delete?: GraphQLTypes["RankingLocationsDocAccessFields_maxItems_Delete"] | undefined
};
	["RankingLocationsDocAccessFields_maxItems_Create"]: {
	__typename: "RankingLocationsDocAccessFields_maxItems_Create",
	permission: boolean
};
	["RankingLocationsDocAccessFields_maxItems_Read"]: {
	__typename: "RankingLocationsDocAccessFields_maxItems_Read",
	permission: boolean
};
	["RankingLocationsDocAccessFields_maxItems_Update"]: {
	__typename: "RankingLocationsDocAccessFields_maxItems_Update",
	permission: boolean
};
	["RankingLocationsDocAccessFields_maxItems_Delete"]: {
	__typename: "RankingLocationsDocAccessFields_maxItems_Delete",
	permission: boolean
};
	["RankingLocationsDocAccessFields_template"]: {
	__typename: "RankingLocationsDocAccessFields_template",
	create?: GraphQLTypes["RankingLocationsDocAccessFields_template_Create"] | undefined,
	read?: GraphQLTypes["RankingLocationsDocAccessFields_template_Read"] | undefined,
	update?: GraphQLTypes["RankingLocationsDocAccessFields_template_Update"] | undefined,
	delete?: GraphQLTypes["RankingLocationsDocAccessFields_template_Delete"] | undefined
};
	["RankingLocationsDocAccessFields_template_Create"]: {
	__typename: "RankingLocationsDocAccessFields_template_Create",
	permission: boolean
};
	["RankingLocationsDocAccessFields_template_Read"]: {
	__typename: "RankingLocationsDocAccessFields_template_Read",
	permission: boolean
};
	["RankingLocationsDocAccessFields_template_Update"]: {
	__typename: "RankingLocationsDocAccessFields_template_Update",
	permission: boolean
};
	["RankingLocationsDocAccessFields_template_Delete"]: {
	__typename: "RankingLocationsDocAccessFields_template_Delete",
	permission: boolean
};
	["RankingLocationsDocAccessFields_list"]: {
	__typename: "RankingLocationsDocAccessFields_list",
	create?: GraphQLTypes["RankingLocationsDocAccessFields_list_Create"] | undefined,
	read?: GraphQLTypes["RankingLocationsDocAccessFields_list_Read"] | undefined,
	update?: GraphQLTypes["RankingLocationsDocAccessFields_list_Update"] | undefined,
	delete?: GraphQLTypes["RankingLocationsDocAccessFields_list_Delete"] | undefined,
	fields?: GraphQLTypes["RankingLocationsDocAccessFields_list_Fields"] | undefined
};
	["RankingLocationsDocAccessFields_list_Create"]: {
	__typename: "RankingLocationsDocAccessFields_list_Create",
	permission: boolean
};
	["RankingLocationsDocAccessFields_list_Read"]: {
	__typename: "RankingLocationsDocAccessFields_list_Read",
	permission: boolean
};
	["RankingLocationsDocAccessFields_list_Update"]: {
	__typename: "RankingLocationsDocAccessFields_list_Update",
	permission: boolean
};
	["RankingLocationsDocAccessFields_list_Delete"]: {
	__typename: "RankingLocationsDocAccessFields_list_Delete",
	permission: boolean
};
	["RankingLocationsDocAccessFields_list_Fields"]: {
	__typename: "RankingLocationsDocAccessFields_list_Fields",
	item?: GraphQLTypes["RankingLocationsDocAccessFields_list_item"] | undefined,
	id?: GraphQLTypes["RankingLocationsDocAccessFields_list_id"] | undefined
};
	["RankingLocationsDocAccessFields_list_item"]: {
	__typename: "RankingLocationsDocAccessFields_list_item",
	create?: GraphQLTypes["RankingLocationsDocAccessFields_list_item_Create"] | undefined,
	read?: GraphQLTypes["RankingLocationsDocAccessFields_list_item_Read"] | undefined,
	update?: GraphQLTypes["RankingLocationsDocAccessFields_list_item_Update"] | undefined,
	delete?: GraphQLTypes["RankingLocationsDocAccessFields_list_item_Delete"] | undefined
};
	["RankingLocationsDocAccessFields_list_item_Create"]: {
	__typename: "RankingLocationsDocAccessFields_list_item_Create",
	permission: boolean
};
	["RankingLocationsDocAccessFields_list_item_Read"]: {
	__typename: "RankingLocationsDocAccessFields_list_item_Read",
	permission: boolean
};
	["RankingLocationsDocAccessFields_list_item_Update"]: {
	__typename: "RankingLocationsDocAccessFields_list_item_Update",
	permission: boolean
};
	["RankingLocationsDocAccessFields_list_item_Delete"]: {
	__typename: "RankingLocationsDocAccessFields_list_item_Delete",
	permission: boolean
};
	["RankingLocationsDocAccessFields_list_id"]: {
	__typename: "RankingLocationsDocAccessFields_list_id",
	create?: GraphQLTypes["RankingLocationsDocAccessFields_list_id_Create"] | undefined,
	read?: GraphQLTypes["RankingLocationsDocAccessFields_list_id_Read"] | undefined,
	update?: GraphQLTypes["RankingLocationsDocAccessFields_list_id_Update"] | undefined,
	delete?: GraphQLTypes["RankingLocationsDocAccessFields_list_id_Delete"] | undefined
};
	["RankingLocationsDocAccessFields_list_id_Create"]: {
	__typename: "RankingLocationsDocAccessFields_list_id_Create",
	permission: boolean
};
	["RankingLocationsDocAccessFields_list_id_Read"]: {
	__typename: "RankingLocationsDocAccessFields_list_id_Read",
	permission: boolean
};
	["RankingLocationsDocAccessFields_list_id_Update"]: {
	__typename: "RankingLocationsDocAccessFields_list_id_Update",
	permission: boolean
};
	["RankingLocationsDocAccessFields_list_id_Delete"]: {
	__typename: "RankingLocationsDocAccessFields_list_id_Delete",
	permission: boolean
};
	["RankingLocationsDocAccessFields_region"]: {
	__typename: "RankingLocationsDocAccessFields_region",
	create?: GraphQLTypes["RankingLocationsDocAccessFields_region_Create"] | undefined,
	read?: GraphQLTypes["RankingLocationsDocAccessFields_region_Read"] | undefined,
	update?: GraphQLTypes["RankingLocationsDocAccessFields_region_Update"] | undefined,
	delete?: GraphQLTypes["RankingLocationsDocAccessFields_region_Delete"] | undefined
};
	["RankingLocationsDocAccessFields_region_Create"]: {
	__typename: "RankingLocationsDocAccessFields_region_Create",
	permission: boolean
};
	["RankingLocationsDocAccessFields_region_Read"]: {
	__typename: "RankingLocationsDocAccessFields_region_Read",
	permission: boolean
};
	["RankingLocationsDocAccessFields_region_Update"]: {
	__typename: "RankingLocationsDocAccessFields_region_Update",
	permission: boolean
};
	["RankingLocationsDocAccessFields_region_Delete"]: {
	__typename: "RankingLocationsDocAccessFields_region_Delete",
	permission: boolean
};
	["RankingLocationsDocAccessFields_updatedAt"]: {
	__typename: "RankingLocationsDocAccessFields_updatedAt",
	create?: GraphQLTypes["RankingLocationsDocAccessFields_updatedAt_Create"] | undefined,
	read?: GraphQLTypes["RankingLocationsDocAccessFields_updatedAt_Read"] | undefined,
	update?: GraphQLTypes["RankingLocationsDocAccessFields_updatedAt_Update"] | undefined,
	delete?: GraphQLTypes["RankingLocationsDocAccessFields_updatedAt_Delete"] | undefined
};
	["RankingLocationsDocAccessFields_updatedAt_Create"]: {
	__typename: "RankingLocationsDocAccessFields_updatedAt_Create",
	permission: boolean
};
	["RankingLocationsDocAccessFields_updatedAt_Read"]: {
	__typename: "RankingLocationsDocAccessFields_updatedAt_Read",
	permission: boolean
};
	["RankingLocationsDocAccessFields_updatedAt_Update"]: {
	__typename: "RankingLocationsDocAccessFields_updatedAt_Update",
	permission: boolean
};
	["RankingLocationsDocAccessFields_updatedAt_Delete"]: {
	__typename: "RankingLocationsDocAccessFields_updatedAt_Delete",
	permission: boolean
};
	["RankingLocationsDocAccessFields_createdAt"]: {
	__typename: "RankingLocationsDocAccessFields_createdAt",
	create?: GraphQLTypes["RankingLocationsDocAccessFields_createdAt_Create"] | undefined,
	read?: GraphQLTypes["RankingLocationsDocAccessFields_createdAt_Read"] | undefined,
	update?: GraphQLTypes["RankingLocationsDocAccessFields_createdAt_Update"] | undefined,
	delete?: GraphQLTypes["RankingLocationsDocAccessFields_createdAt_Delete"] | undefined
};
	["RankingLocationsDocAccessFields_createdAt_Create"]: {
	__typename: "RankingLocationsDocAccessFields_createdAt_Create",
	permission: boolean
};
	["RankingLocationsDocAccessFields_createdAt_Read"]: {
	__typename: "RankingLocationsDocAccessFields_createdAt_Read",
	permission: boolean
};
	["RankingLocationsDocAccessFields_createdAt_Update"]: {
	__typename: "RankingLocationsDocAccessFields_createdAt_Update",
	permission: boolean
};
	["RankingLocationsDocAccessFields_createdAt_Delete"]: {
	__typename: "RankingLocationsDocAccessFields_createdAt_Delete",
	permission: boolean
};
	["RankingLocationsCreateDocAccess"]: {
	__typename: "RankingLocationsCreateDocAccess",
	permission: boolean,
	where?: GraphQLTypes["JSONObject"] | undefined
};
	["RankingLocationsReadDocAccess"]: {
	__typename: "RankingLocationsReadDocAccess",
	permission: boolean,
	where?: GraphQLTypes["JSONObject"] | undefined
};
	["RankingLocationsUpdateDocAccess"]: {
	__typename: "RankingLocationsUpdateDocAccess",
	permission: boolean,
	where?: GraphQLTypes["JSONObject"] | undefined
};
	["RankingLocationsDeleteDocAccess"]: {
	__typename: "RankingLocationsDeleteDocAccess",
	permission: boolean,
	where?: GraphQLTypes["JSONObject"] | undefined
};
	["EventCalendar"]: {
	__typename: "EventCalendar",
	id?: string | undefined,
	date: GraphQLTypes["DateTime"],
	item: GraphQLTypes["Event"],
	title?: string | undefined,
	region?: GraphQLTypes["EventCalendar_region"] | undefined,
	updatedAt?: GraphQLTypes["DateTime"] | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined
};
	["EventCalendar_region"]: EventCalendar_region;
	["EventCalendars"]: {
	__typename: "EventCalendars",
	docs?: Array<GraphQLTypes["EventCalendar"] | undefined> | undefined,
	hasNextPage?: boolean | undefined,
	hasPrevPage?: boolean | undefined,
	limit?: number | undefined,
	nextPage?: number | undefined,
	offset?: number | undefined,
	page?: number | undefined,
	pagingCounter?: number | undefined,
	prevPage?: number | undefined,
	totalDocs?: number | undefined,
	totalPages?: number | undefined
};
	["EventCalendar_where"]: {
		date?: GraphQLTypes["EventCalendar_date_operator"] | undefined,
	item?: GraphQLTypes["EventCalendar_item_operator"] | undefined,
	title?: GraphQLTypes["EventCalendar_title_operator"] | undefined,
	region?: GraphQLTypes["EventCalendar_region_operator"] | undefined,
	updatedAt?: GraphQLTypes["EventCalendar_updatedAt_operator"] | undefined,
	createdAt?: GraphQLTypes["EventCalendar_createdAt_operator"] | undefined,
	id?: GraphQLTypes["EventCalendar_id_operator"] | undefined,
	AND?: Array<GraphQLTypes["EventCalendar_where_and"] | undefined> | undefined,
	OR?: Array<GraphQLTypes["EventCalendar_where_or"] | undefined> | undefined
};
	["EventCalendar_date_operator"]: {
		equals?: GraphQLTypes["DateTime"] | undefined,
	not_equals?: GraphQLTypes["DateTime"] | undefined,
	greater_than_equal?: GraphQLTypes["DateTime"] | undefined,
	greater_than?: GraphQLTypes["DateTime"] | undefined,
	less_than_equal?: GraphQLTypes["DateTime"] | undefined,
	less_than?: GraphQLTypes["DateTime"] | undefined,
	like?: GraphQLTypes["DateTime"] | undefined
};
	["EventCalendar_item_operator"]: {
		equals?: GraphQLTypes["JSON"] | undefined,
	not_equals?: GraphQLTypes["JSON"] | undefined,
	in?: Array<GraphQLTypes["JSON"] | undefined> | undefined,
	not_in?: Array<GraphQLTypes["JSON"] | undefined> | undefined,
	all?: Array<GraphQLTypes["JSON"] | undefined> | undefined
};
	["EventCalendar_title_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["EventCalendar_region_operator"]: {
		equals?: GraphQLTypes["EventCalendar_region_Input"] | undefined,
	not_equals?: GraphQLTypes["EventCalendar_region_Input"] | undefined,
	in?: Array<GraphQLTypes["EventCalendar_region_Input"] | undefined> | undefined,
	not_in?: Array<GraphQLTypes["EventCalendar_region_Input"] | undefined> | undefined,
	all?: Array<GraphQLTypes["EventCalendar_region_Input"] | undefined> | undefined,
	exists?: boolean | undefined
};
	["EventCalendar_region_Input"]: EventCalendar_region_Input;
	["EventCalendar_updatedAt_operator"]: {
		equals?: GraphQLTypes["DateTime"] | undefined,
	not_equals?: GraphQLTypes["DateTime"] | undefined,
	greater_than_equal?: GraphQLTypes["DateTime"] | undefined,
	greater_than?: GraphQLTypes["DateTime"] | undefined,
	less_than_equal?: GraphQLTypes["DateTime"] | undefined,
	less_than?: GraphQLTypes["DateTime"] | undefined,
	like?: GraphQLTypes["DateTime"] | undefined,
	exists?: boolean | undefined
};
	["EventCalendar_createdAt_operator"]: {
		equals?: GraphQLTypes["DateTime"] | undefined,
	not_equals?: GraphQLTypes["DateTime"] | undefined,
	greater_than_equal?: GraphQLTypes["DateTime"] | undefined,
	greater_than?: GraphQLTypes["DateTime"] | undefined,
	less_than_equal?: GraphQLTypes["DateTime"] | undefined,
	less_than?: GraphQLTypes["DateTime"] | undefined,
	like?: GraphQLTypes["DateTime"] | undefined,
	exists?: boolean | undefined
};
	["EventCalendar_id_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["EventCalendar_where_and"]: {
		date?: GraphQLTypes["EventCalendar_date_operator"] | undefined,
	item?: GraphQLTypes["EventCalendar_item_operator"] | undefined,
	title?: GraphQLTypes["EventCalendar_title_operator"] | undefined,
	region?: GraphQLTypes["EventCalendar_region_operator"] | undefined,
	updatedAt?: GraphQLTypes["EventCalendar_updatedAt_operator"] | undefined,
	createdAt?: GraphQLTypes["EventCalendar_createdAt_operator"] | undefined,
	id?: GraphQLTypes["EventCalendar_id_operator"] | undefined,
	AND?: Array<GraphQLTypes["EventCalendar_where_and"] | undefined> | undefined,
	OR?: Array<GraphQLTypes["EventCalendar_where_or"] | undefined> | undefined
};
	["EventCalendar_where_or"]: {
		date?: GraphQLTypes["EventCalendar_date_operator"] | undefined,
	item?: GraphQLTypes["EventCalendar_item_operator"] | undefined,
	title?: GraphQLTypes["EventCalendar_title_operator"] | undefined,
	region?: GraphQLTypes["EventCalendar_region_operator"] | undefined,
	updatedAt?: GraphQLTypes["EventCalendar_updatedAt_operator"] | undefined,
	createdAt?: GraphQLTypes["EventCalendar_createdAt_operator"] | undefined,
	id?: GraphQLTypes["EventCalendar_id_operator"] | undefined,
	AND?: Array<GraphQLTypes["EventCalendar_where_and"] | undefined> | undefined,
	OR?: Array<GraphQLTypes["EventCalendar_where_or"] | undefined> | undefined
};
	["countEventCalendars"]: {
	__typename: "countEventCalendars",
	totalDocs?: number | undefined
};
	["event_calendarDocAccess"]: {
	__typename: "event_calendarDocAccess",
	fields?: GraphQLTypes["EventCalendarDocAccessFields"] | undefined,
	create?: GraphQLTypes["EventCalendarCreateDocAccess"] | undefined,
	read?: GraphQLTypes["EventCalendarReadDocAccess"] | undefined,
	update?: GraphQLTypes["EventCalendarUpdateDocAccess"] | undefined,
	delete?: GraphQLTypes["EventCalendarDeleteDocAccess"] | undefined
};
	["EventCalendarDocAccessFields"]: {
	__typename: "EventCalendarDocAccessFields",
	date?: GraphQLTypes["EventCalendarDocAccessFields_date"] | undefined,
	item?: GraphQLTypes["EventCalendarDocAccessFields_item"] | undefined,
	title?: GraphQLTypes["EventCalendarDocAccessFields_title"] | undefined,
	region?: GraphQLTypes["EventCalendarDocAccessFields_region"] | undefined,
	updatedAt?: GraphQLTypes["EventCalendarDocAccessFields_updatedAt"] | undefined,
	createdAt?: GraphQLTypes["EventCalendarDocAccessFields_createdAt"] | undefined
};
	["EventCalendarDocAccessFields_date"]: {
	__typename: "EventCalendarDocAccessFields_date",
	create?: GraphQLTypes["EventCalendarDocAccessFields_date_Create"] | undefined,
	read?: GraphQLTypes["EventCalendarDocAccessFields_date_Read"] | undefined,
	update?: GraphQLTypes["EventCalendarDocAccessFields_date_Update"] | undefined,
	delete?: GraphQLTypes["EventCalendarDocAccessFields_date_Delete"] | undefined
};
	["EventCalendarDocAccessFields_date_Create"]: {
	__typename: "EventCalendarDocAccessFields_date_Create",
	permission: boolean
};
	["EventCalendarDocAccessFields_date_Read"]: {
	__typename: "EventCalendarDocAccessFields_date_Read",
	permission: boolean
};
	["EventCalendarDocAccessFields_date_Update"]: {
	__typename: "EventCalendarDocAccessFields_date_Update",
	permission: boolean
};
	["EventCalendarDocAccessFields_date_Delete"]: {
	__typename: "EventCalendarDocAccessFields_date_Delete",
	permission: boolean
};
	["EventCalendarDocAccessFields_item"]: {
	__typename: "EventCalendarDocAccessFields_item",
	create?: GraphQLTypes["EventCalendarDocAccessFields_item_Create"] | undefined,
	read?: GraphQLTypes["EventCalendarDocAccessFields_item_Read"] | undefined,
	update?: GraphQLTypes["EventCalendarDocAccessFields_item_Update"] | undefined,
	delete?: GraphQLTypes["EventCalendarDocAccessFields_item_Delete"] | undefined
};
	["EventCalendarDocAccessFields_item_Create"]: {
	__typename: "EventCalendarDocAccessFields_item_Create",
	permission: boolean
};
	["EventCalendarDocAccessFields_item_Read"]: {
	__typename: "EventCalendarDocAccessFields_item_Read",
	permission: boolean
};
	["EventCalendarDocAccessFields_item_Update"]: {
	__typename: "EventCalendarDocAccessFields_item_Update",
	permission: boolean
};
	["EventCalendarDocAccessFields_item_Delete"]: {
	__typename: "EventCalendarDocAccessFields_item_Delete",
	permission: boolean
};
	["EventCalendarDocAccessFields_title"]: {
	__typename: "EventCalendarDocAccessFields_title",
	create?: GraphQLTypes["EventCalendarDocAccessFields_title_Create"] | undefined,
	read?: GraphQLTypes["EventCalendarDocAccessFields_title_Read"] | undefined,
	update?: GraphQLTypes["EventCalendarDocAccessFields_title_Update"] | undefined,
	delete?: GraphQLTypes["EventCalendarDocAccessFields_title_Delete"] | undefined
};
	["EventCalendarDocAccessFields_title_Create"]: {
	__typename: "EventCalendarDocAccessFields_title_Create",
	permission: boolean
};
	["EventCalendarDocAccessFields_title_Read"]: {
	__typename: "EventCalendarDocAccessFields_title_Read",
	permission: boolean
};
	["EventCalendarDocAccessFields_title_Update"]: {
	__typename: "EventCalendarDocAccessFields_title_Update",
	permission: boolean
};
	["EventCalendarDocAccessFields_title_Delete"]: {
	__typename: "EventCalendarDocAccessFields_title_Delete",
	permission: boolean
};
	["EventCalendarDocAccessFields_region"]: {
	__typename: "EventCalendarDocAccessFields_region",
	create?: GraphQLTypes["EventCalendarDocAccessFields_region_Create"] | undefined,
	read?: GraphQLTypes["EventCalendarDocAccessFields_region_Read"] | undefined,
	update?: GraphQLTypes["EventCalendarDocAccessFields_region_Update"] | undefined,
	delete?: GraphQLTypes["EventCalendarDocAccessFields_region_Delete"] | undefined
};
	["EventCalendarDocAccessFields_region_Create"]: {
	__typename: "EventCalendarDocAccessFields_region_Create",
	permission: boolean
};
	["EventCalendarDocAccessFields_region_Read"]: {
	__typename: "EventCalendarDocAccessFields_region_Read",
	permission: boolean
};
	["EventCalendarDocAccessFields_region_Update"]: {
	__typename: "EventCalendarDocAccessFields_region_Update",
	permission: boolean
};
	["EventCalendarDocAccessFields_region_Delete"]: {
	__typename: "EventCalendarDocAccessFields_region_Delete",
	permission: boolean
};
	["EventCalendarDocAccessFields_updatedAt"]: {
	__typename: "EventCalendarDocAccessFields_updatedAt",
	create?: GraphQLTypes["EventCalendarDocAccessFields_updatedAt_Create"] | undefined,
	read?: GraphQLTypes["EventCalendarDocAccessFields_updatedAt_Read"] | undefined,
	update?: GraphQLTypes["EventCalendarDocAccessFields_updatedAt_Update"] | undefined,
	delete?: GraphQLTypes["EventCalendarDocAccessFields_updatedAt_Delete"] | undefined
};
	["EventCalendarDocAccessFields_updatedAt_Create"]: {
	__typename: "EventCalendarDocAccessFields_updatedAt_Create",
	permission: boolean
};
	["EventCalendarDocAccessFields_updatedAt_Read"]: {
	__typename: "EventCalendarDocAccessFields_updatedAt_Read",
	permission: boolean
};
	["EventCalendarDocAccessFields_updatedAt_Update"]: {
	__typename: "EventCalendarDocAccessFields_updatedAt_Update",
	permission: boolean
};
	["EventCalendarDocAccessFields_updatedAt_Delete"]: {
	__typename: "EventCalendarDocAccessFields_updatedAt_Delete",
	permission: boolean
};
	["EventCalendarDocAccessFields_createdAt"]: {
	__typename: "EventCalendarDocAccessFields_createdAt",
	create?: GraphQLTypes["EventCalendarDocAccessFields_createdAt_Create"] | undefined,
	read?: GraphQLTypes["EventCalendarDocAccessFields_createdAt_Read"] | undefined,
	update?: GraphQLTypes["EventCalendarDocAccessFields_createdAt_Update"] | undefined,
	delete?: GraphQLTypes["EventCalendarDocAccessFields_createdAt_Delete"] | undefined
};
	["EventCalendarDocAccessFields_createdAt_Create"]: {
	__typename: "EventCalendarDocAccessFields_createdAt_Create",
	permission: boolean
};
	["EventCalendarDocAccessFields_createdAt_Read"]: {
	__typename: "EventCalendarDocAccessFields_createdAt_Read",
	permission: boolean
};
	["EventCalendarDocAccessFields_createdAt_Update"]: {
	__typename: "EventCalendarDocAccessFields_createdAt_Update",
	permission: boolean
};
	["EventCalendarDocAccessFields_createdAt_Delete"]: {
	__typename: "EventCalendarDocAccessFields_createdAt_Delete",
	permission: boolean
};
	["EventCalendarCreateDocAccess"]: {
	__typename: "EventCalendarCreateDocAccess",
	permission: boolean,
	where?: GraphQLTypes["JSONObject"] | undefined
};
	["EventCalendarReadDocAccess"]: {
	__typename: "EventCalendarReadDocAccess",
	permission: boolean,
	where?: GraphQLTypes["JSONObject"] | undefined
};
	["EventCalendarUpdateDocAccess"]: {
	__typename: "EventCalendarUpdateDocAccess",
	permission: boolean,
	where?: GraphQLTypes["JSONObject"] | undefined
};
	["EventCalendarDeleteDocAccess"]: {
	__typename: "EventCalendarDeleteDocAccess",
	permission: boolean,
	where?: GraphQLTypes["JSONObject"] | undefined
};
	["EditorChoice"]: {
	__typename: "EditorChoice",
	id?: string | undefined,
	name?: string | undefined,
	visible?: boolean | undefined,
	editorialPicks?: Array<GraphQLTypes["Event"]> | undefined,
	list?: Array<GraphQLTypes["EditorChoice_List"]> | undefined,
	region?: GraphQLTypes["EditorChoice_region"] | undefined,
	updatedAt?: GraphQLTypes["DateTime"] | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined
};
	["EditorChoice_List"]: {
	__typename: "EditorChoice_List",
	item?: GraphQLTypes["Event"] | undefined,
	id?: string | undefined
};
	["EditorChoice_region"]: EditorChoice_region;
	["EditorChoices"]: {
	__typename: "EditorChoices",
	docs?: Array<GraphQLTypes["EditorChoice"] | undefined> | undefined,
	hasNextPage?: boolean | undefined,
	hasPrevPage?: boolean | undefined,
	limit?: number | undefined,
	nextPage?: number | undefined,
	offset?: number | undefined,
	page?: number | undefined,
	pagingCounter?: number | undefined,
	prevPage?: number | undefined,
	totalDocs?: number | undefined,
	totalPages?: number | undefined
};
	["EditorChoice_where"]: {
		name?: GraphQLTypes["EditorChoice_name_operator"] | undefined,
	visible?: GraphQLTypes["EditorChoice_visible_operator"] | undefined,
	editorialPicks?: GraphQLTypes["EditorChoice_editorialPicks_operator"] | undefined,
	list__item?: GraphQLTypes["EditorChoice_list__item_operator"] | undefined,
	list__id?: GraphQLTypes["EditorChoice_list__id_operator"] | undefined,
	region?: GraphQLTypes["EditorChoice_region_operator"] | undefined,
	updatedAt?: GraphQLTypes["EditorChoice_updatedAt_operator"] | undefined,
	createdAt?: GraphQLTypes["EditorChoice_createdAt_operator"] | undefined,
	id?: GraphQLTypes["EditorChoice_id_operator"] | undefined,
	AND?: Array<GraphQLTypes["EditorChoice_where_and"] | undefined> | undefined,
	OR?: Array<GraphQLTypes["EditorChoice_where_or"] | undefined> | undefined
};
	["EditorChoice_name_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined
};
	["EditorChoice_visible_operator"]: {
		equals?: boolean | undefined,
	not_equals?: boolean | undefined,
	exists?: boolean | undefined
};
	["EditorChoice_editorialPicks_operator"]: {
		equals?: GraphQLTypes["JSON"] | undefined,
	not_equals?: GraphQLTypes["JSON"] | undefined,
	in?: Array<GraphQLTypes["JSON"] | undefined> | undefined,
	not_in?: Array<GraphQLTypes["JSON"] | undefined> | undefined,
	all?: Array<GraphQLTypes["JSON"] | undefined> | undefined,
	exists?: boolean | undefined
};
	["EditorChoice_list__item_operator"]: {
		equals?: GraphQLTypes["JSON"] | undefined,
	not_equals?: GraphQLTypes["JSON"] | undefined,
	in?: Array<GraphQLTypes["JSON"] | undefined> | undefined,
	not_in?: Array<GraphQLTypes["JSON"] | undefined> | undefined,
	all?: Array<GraphQLTypes["JSON"] | undefined> | undefined
};
	["EditorChoice_list__id_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["EditorChoice_region_operator"]: {
		equals?: GraphQLTypes["EditorChoice_region_Input"] | undefined,
	not_equals?: GraphQLTypes["EditorChoice_region_Input"] | undefined,
	in?: Array<GraphQLTypes["EditorChoice_region_Input"] | undefined> | undefined,
	not_in?: Array<GraphQLTypes["EditorChoice_region_Input"] | undefined> | undefined,
	all?: Array<GraphQLTypes["EditorChoice_region_Input"] | undefined> | undefined,
	exists?: boolean | undefined
};
	["EditorChoice_region_Input"]: EditorChoice_region_Input;
	["EditorChoice_updatedAt_operator"]: {
		equals?: GraphQLTypes["DateTime"] | undefined,
	not_equals?: GraphQLTypes["DateTime"] | undefined,
	greater_than_equal?: GraphQLTypes["DateTime"] | undefined,
	greater_than?: GraphQLTypes["DateTime"] | undefined,
	less_than_equal?: GraphQLTypes["DateTime"] | undefined,
	less_than?: GraphQLTypes["DateTime"] | undefined,
	like?: GraphQLTypes["DateTime"] | undefined,
	exists?: boolean | undefined
};
	["EditorChoice_createdAt_operator"]: {
		equals?: GraphQLTypes["DateTime"] | undefined,
	not_equals?: GraphQLTypes["DateTime"] | undefined,
	greater_than_equal?: GraphQLTypes["DateTime"] | undefined,
	greater_than?: GraphQLTypes["DateTime"] | undefined,
	less_than_equal?: GraphQLTypes["DateTime"] | undefined,
	less_than?: GraphQLTypes["DateTime"] | undefined,
	like?: GraphQLTypes["DateTime"] | undefined,
	exists?: boolean | undefined
};
	["EditorChoice_id_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["EditorChoice_where_and"]: {
		name?: GraphQLTypes["EditorChoice_name_operator"] | undefined,
	visible?: GraphQLTypes["EditorChoice_visible_operator"] | undefined,
	editorialPicks?: GraphQLTypes["EditorChoice_editorialPicks_operator"] | undefined,
	list__item?: GraphQLTypes["EditorChoice_list__item_operator"] | undefined,
	list__id?: GraphQLTypes["EditorChoice_list__id_operator"] | undefined,
	region?: GraphQLTypes["EditorChoice_region_operator"] | undefined,
	updatedAt?: GraphQLTypes["EditorChoice_updatedAt_operator"] | undefined,
	createdAt?: GraphQLTypes["EditorChoice_createdAt_operator"] | undefined,
	id?: GraphQLTypes["EditorChoice_id_operator"] | undefined,
	AND?: Array<GraphQLTypes["EditorChoice_where_and"] | undefined> | undefined,
	OR?: Array<GraphQLTypes["EditorChoice_where_or"] | undefined> | undefined
};
	["EditorChoice_where_or"]: {
		name?: GraphQLTypes["EditorChoice_name_operator"] | undefined,
	visible?: GraphQLTypes["EditorChoice_visible_operator"] | undefined,
	editorialPicks?: GraphQLTypes["EditorChoice_editorialPicks_operator"] | undefined,
	list__item?: GraphQLTypes["EditorChoice_list__item_operator"] | undefined,
	list__id?: GraphQLTypes["EditorChoice_list__id_operator"] | undefined,
	region?: GraphQLTypes["EditorChoice_region_operator"] | undefined,
	updatedAt?: GraphQLTypes["EditorChoice_updatedAt_operator"] | undefined,
	createdAt?: GraphQLTypes["EditorChoice_createdAt_operator"] | undefined,
	id?: GraphQLTypes["EditorChoice_id_operator"] | undefined,
	AND?: Array<GraphQLTypes["EditorChoice_where_and"] | undefined> | undefined,
	OR?: Array<GraphQLTypes["EditorChoice_where_or"] | undefined> | undefined
};
	["countEditorChoices"]: {
	__typename: "countEditorChoices",
	totalDocs?: number | undefined
};
	["editor_choicesDocAccess"]: {
	__typename: "editor_choicesDocAccess",
	fields?: GraphQLTypes["EditorChoicesDocAccessFields"] | undefined,
	create?: GraphQLTypes["EditorChoicesCreateDocAccess"] | undefined,
	read?: GraphQLTypes["EditorChoicesReadDocAccess"] | undefined,
	update?: GraphQLTypes["EditorChoicesUpdateDocAccess"] | undefined,
	delete?: GraphQLTypes["EditorChoicesDeleteDocAccess"] | undefined
};
	["EditorChoicesDocAccessFields"]: {
	__typename: "EditorChoicesDocAccessFields",
	name?: GraphQLTypes["EditorChoicesDocAccessFields_name"] | undefined,
	visible?: GraphQLTypes["EditorChoicesDocAccessFields_visible"] | undefined,
	editorialPicks?: GraphQLTypes["EditorChoicesDocAccessFields_editorialPicks"] | undefined,
	list?: GraphQLTypes["EditorChoicesDocAccessFields_list"] | undefined,
	region?: GraphQLTypes["EditorChoicesDocAccessFields_region"] | undefined,
	updatedAt?: GraphQLTypes["EditorChoicesDocAccessFields_updatedAt"] | undefined,
	createdAt?: GraphQLTypes["EditorChoicesDocAccessFields_createdAt"] | undefined
};
	["EditorChoicesDocAccessFields_name"]: {
	__typename: "EditorChoicesDocAccessFields_name",
	create?: GraphQLTypes["EditorChoicesDocAccessFields_name_Create"] | undefined,
	read?: GraphQLTypes["EditorChoicesDocAccessFields_name_Read"] | undefined,
	update?: GraphQLTypes["EditorChoicesDocAccessFields_name_Update"] | undefined,
	delete?: GraphQLTypes["EditorChoicesDocAccessFields_name_Delete"] | undefined
};
	["EditorChoicesDocAccessFields_name_Create"]: {
	__typename: "EditorChoicesDocAccessFields_name_Create",
	permission: boolean
};
	["EditorChoicesDocAccessFields_name_Read"]: {
	__typename: "EditorChoicesDocAccessFields_name_Read",
	permission: boolean
};
	["EditorChoicesDocAccessFields_name_Update"]: {
	__typename: "EditorChoicesDocAccessFields_name_Update",
	permission: boolean
};
	["EditorChoicesDocAccessFields_name_Delete"]: {
	__typename: "EditorChoicesDocAccessFields_name_Delete",
	permission: boolean
};
	["EditorChoicesDocAccessFields_visible"]: {
	__typename: "EditorChoicesDocAccessFields_visible",
	create?: GraphQLTypes["EditorChoicesDocAccessFields_visible_Create"] | undefined,
	read?: GraphQLTypes["EditorChoicesDocAccessFields_visible_Read"] | undefined,
	update?: GraphQLTypes["EditorChoicesDocAccessFields_visible_Update"] | undefined,
	delete?: GraphQLTypes["EditorChoicesDocAccessFields_visible_Delete"] | undefined
};
	["EditorChoicesDocAccessFields_visible_Create"]: {
	__typename: "EditorChoicesDocAccessFields_visible_Create",
	permission: boolean
};
	["EditorChoicesDocAccessFields_visible_Read"]: {
	__typename: "EditorChoicesDocAccessFields_visible_Read",
	permission: boolean
};
	["EditorChoicesDocAccessFields_visible_Update"]: {
	__typename: "EditorChoicesDocAccessFields_visible_Update",
	permission: boolean
};
	["EditorChoicesDocAccessFields_visible_Delete"]: {
	__typename: "EditorChoicesDocAccessFields_visible_Delete",
	permission: boolean
};
	["EditorChoicesDocAccessFields_editorialPicks"]: {
	__typename: "EditorChoicesDocAccessFields_editorialPicks",
	create?: GraphQLTypes["EditorChoicesDocAccessFields_editorialPicks_Create"] | undefined,
	read?: GraphQLTypes["EditorChoicesDocAccessFields_editorialPicks_Read"] | undefined,
	update?: GraphQLTypes["EditorChoicesDocAccessFields_editorialPicks_Update"] | undefined,
	delete?: GraphQLTypes["EditorChoicesDocAccessFields_editorialPicks_Delete"] | undefined
};
	["EditorChoicesDocAccessFields_editorialPicks_Create"]: {
	__typename: "EditorChoicesDocAccessFields_editorialPicks_Create",
	permission: boolean
};
	["EditorChoicesDocAccessFields_editorialPicks_Read"]: {
	__typename: "EditorChoicesDocAccessFields_editorialPicks_Read",
	permission: boolean
};
	["EditorChoicesDocAccessFields_editorialPicks_Update"]: {
	__typename: "EditorChoicesDocAccessFields_editorialPicks_Update",
	permission: boolean
};
	["EditorChoicesDocAccessFields_editorialPicks_Delete"]: {
	__typename: "EditorChoicesDocAccessFields_editorialPicks_Delete",
	permission: boolean
};
	["EditorChoicesDocAccessFields_list"]: {
	__typename: "EditorChoicesDocAccessFields_list",
	create?: GraphQLTypes["EditorChoicesDocAccessFields_list_Create"] | undefined,
	read?: GraphQLTypes["EditorChoicesDocAccessFields_list_Read"] | undefined,
	update?: GraphQLTypes["EditorChoicesDocAccessFields_list_Update"] | undefined,
	delete?: GraphQLTypes["EditorChoicesDocAccessFields_list_Delete"] | undefined,
	fields?: GraphQLTypes["EditorChoicesDocAccessFields_list_Fields"] | undefined
};
	["EditorChoicesDocAccessFields_list_Create"]: {
	__typename: "EditorChoicesDocAccessFields_list_Create",
	permission: boolean
};
	["EditorChoicesDocAccessFields_list_Read"]: {
	__typename: "EditorChoicesDocAccessFields_list_Read",
	permission: boolean
};
	["EditorChoicesDocAccessFields_list_Update"]: {
	__typename: "EditorChoicesDocAccessFields_list_Update",
	permission: boolean
};
	["EditorChoicesDocAccessFields_list_Delete"]: {
	__typename: "EditorChoicesDocAccessFields_list_Delete",
	permission: boolean
};
	["EditorChoicesDocAccessFields_list_Fields"]: {
	__typename: "EditorChoicesDocAccessFields_list_Fields",
	item?: GraphQLTypes["EditorChoicesDocAccessFields_list_item"] | undefined,
	id?: GraphQLTypes["EditorChoicesDocAccessFields_list_id"] | undefined
};
	["EditorChoicesDocAccessFields_list_item"]: {
	__typename: "EditorChoicesDocAccessFields_list_item",
	create?: GraphQLTypes["EditorChoicesDocAccessFields_list_item_Create"] | undefined,
	read?: GraphQLTypes["EditorChoicesDocAccessFields_list_item_Read"] | undefined,
	update?: GraphQLTypes["EditorChoicesDocAccessFields_list_item_Update"] | undefined,
	delete?: GraphQLTypes["EditorChoicesDocAccessFields_list_item_Delete"] | undefined
};
	["EditorChoicesDocAccessFields_list_item_Create"]: {
	__typename: "EditorChoicesDocAccessFields_list_item_Create",
	permission: boolean
};
	["EditorChoicesDocAccessFields_list_item_Read"]: {
	__typename: "EditorChoicesDocAccessFields_list_item_Read",
	permission: boolean
};
	["EditorChoicesDocAccessFields_list_item_Update"]: {
	__typename: "EditorChoicesDocAccessFields_list_item_Update",
	permission: boolean
};
	["EditorChoicesDocAccessFields_list_item_Delete"]: {
	__typename: "EditorChoicesDocAccessFields_list_item_Delete",
	permission: boolean
};
	["EditorChoicesDocAccessFields_list_id"]: {
	__typename: "EditorChoicesDocAccessFields_list_id",
	create?: GraphQLTypes["EditorChoicesDocAccessFields_list_id_Create"] | undefined,
	read?: GraphQLTypes["EditorChoicesDocAccessFields_list_id_Read"] | undefined,
	update?: GraphQLTypes["EditorChoicesDocAccessFields_list_id_Update"] | undefined,
	delete?: GraphQLTypes["EditorChoicesDocAccessFields_list_id_Delete"] | undefined
};
	["EditorChoicesDocAccessFields_list_id_Create"]: {
	__typename: "EditorChoicesDocAccessFields_list_id_Create",
	permission: boolean
};
	["EditorChoicesDocAccessFields_list_id_Read"]: {
	__typename: "EditorChoicesDocAccessFields_list_id_Read",
	permission: boolean
};
	["EditorChoicesDocAccessFields_list_id_Update"]: {
	__typename: "EditorChoicesDocAccessFields_list_id_Update",
	permission: boolean
};
	["EditorChoicesDocAccessFields_list_id_Delete"]: {
	__typename: "EditorChoicesDocAccessFields_list_id_Delete",
	permission: boolean
};
	["EditorChoicesDocAccessFields_region"]: {
	__typename: "EditorChoicesDocAccessFields_region",
	create?: GraphQLTypes["EditorChoicesDocAccessFields_region_Create"] | undefined,
	read?: GraphQLTypes["EditorChoicesDocAccessFields_region_Read"] | undefined,
	update?: GraphQLTypes["EditorChoicesDocAccessFields_region_Update"] | undefined,
	delete?: GraphQLTypes["EditorChoicesDocAccessFields_region_Delete"] | undefined
};
	["EditorChoicesDocAccessFields_region_Create"]: {
	__typename: "EditorChoicesDocAccessFields_region_Create",
	permission: boolean
};
	["EditorChoicesDocAccessFields_region_Read"]: {
	__typename: "EditorChoicesDocAccessFields_region_Read",
	permission: boolean
};
	["EditorChoicesDocAccessFields_region_Update"]: {
	__typename: "EditorChoicesDocAccessFields_region_Update",
	permission: boolean
};
	["EditorChoicesDocAccessFields_region_Delete"]: {
	__typename: "EditorChoicesDocAccessFields_region_Delete",
	permission: boolean
};
	["EditorChoicesDocAccessFields_updatedAt"]: {
	__typename: "EditorChoicesDocAccessFields_updatedAt",
	create?: GraphQLTypes["EditorChoicesDocAccessFields_updatedAt_Create"] | undefined,
	read?: GraphQLTypes["EditorChoicesDocAccessFields_updatedAt_Read"] | undefined,
	update?: GraphQLTypes["EditorChoicesDocAccessFields_updatedAt_Update"] | undefined,
	delete?: GraphQLTypes["EditorChoicesDocAccessFields_updatedAt_Delete"] | undefined
};
	["EditorChoicesDocAccessFields_updatedAt_Create"]: {
	__typename: "EditorChoicesDocAccessFields_updatedAt_Create",
	permission: boolean
};
	["EditorChoicesDocAccessFields_updatedAt_Read"]: {
	__typename: "EditorChoicesDocAccessFields_updatedAt_Read",
	permission: boolean
};
	["EditorChoicesDocAccessFields_updatedAt_Update"]: {
	__typename: "EditorChoicesDocAccessFields_updatedAt_Update",
	permission: boolean
};
	["EditorChoicesDocAccessFields_updatedAt_Delete"]: {
	__typename: "EditorChoicesDocAccessFields_updatedAt_Delete",
	permission: boolean
};
	["EditorChoicesDocAccessFields_createdAt"]: {
	__typename: "EditorChoicesDocAccessFields_createdAt",
	create?: GraphQLTypes["EditorChoicesDocAccessFields_createdAt_Create"] | undefined,
	read?: GraphQLTypes["EditorChoicesDocAccessFields_createdAt_Read"] | undefined,
	update?: GraphQLTypes["EditorChoicesDocAccessFields_createdAt_Update"] | undefined,
	delete?: GraphQLTypes["EditorChoicesDocAccessFields_createdAt_Delete"] | undefined
};
	["EditorChoicesDocAccessFields_createdAt_Create"]: {
	__typename: "EditorChoicesDocAccessFields_createdAt_Create",
	permission: boolean
};
	["EditorChoicesDocAccessFields_createdAt_Read"]: {
	__typename: "EditorChoicesDocAccessFields_createdAt_Read",
	permission: boolean
};
	["EditorChoicesDocAccessFields_createdAt_Update"]: {
	__typename: "EditorChoicesDocAccessFields_createdAt_Update",
	permission: boolean
};
	["EditorChoicesDocAccessFields_createdAt_Delete"]: {
	__typename: "EditorChoicesDocAccessFields_createdAt_Delete",
	permission: boolean
};
	["EditorChoicesCreateDocAccess"]: {
	__typename: "EditorChoicesCreateDocAccess",
	permission: boolean,
	where?: GraphQLTypes["JSONObject"] | undefined
};
	["EditorChoicesReadDocAccess"]: {
	__typename: "EditorChoicesReadDocAccess",
	permission: boolean,
	where?: GraphQLTypes["JSONObject"] | undefined
};
	["EditorChoicesUpdateDocAccess"]: {
	__typename: "EditorChoicesUpdateDocAccess",
	permission: boolean,
	where?: GraphQLTypes["JSONObject"] | undefined
};
	["EditorChoicesDeleteDocAccess"]: {
	__typename: "EditorChoicesDeleteDocAccess",
	permission: boolean,
	where?: GraphQLTypes["JSONObject"] | undefined
};
	["CriteriaSetting"]: {
	__typename: "CriteriaSetting",
	id?: string | undefined,
	criteria?: GraphQLTypes["CriteriaSetting_Criteria"] | undefined,
	updatedAt?: GraphQLTypes["DateTime"] | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined
};
	["CriteriaSetting_Criteria"]: {
	__typename: "CriteriaSetting_Criteria",
	date?: Array<GraphQLTypes["Date"]> | undefined,
	audience?: Array<GraphQLTypes["Audience"]> | undefined,
	category?: Array<GraphQLTypes["EventCategory"]> | undefined,
	tag?: Array<GraphQLTypes["Tag"]> | undefined,
	region?: GraphQLTypes["CriteriaSetting_Criteria_region"] | undefined
};
	["CriteriaSetting_Criteria_region"]: CriteriaSetting_Criteria_region;
	["CriteriaSettings"]: {
	__typename: "CriteriaSettings",
	docs?: Array<GraphQLTypes["CriteriaSetting"] | undefined> | undefined,
	hasNextPage?: boolean | undefined,
	hasPrevPage?: boolean | undefined,
	limit?: number | undefined,
	nextPage?: number | undefined,
	offset?: number | undefined,
	page?: number | undefined,
	pagingCounter?: number | undefined,
	prevPage?: number | undefined,
	totalDocs?: number | undefined,
	totalPages?: number | undefined
};
	["CriteriaSetting_where"]: {
		criteria__date?: GraphQLTypes["CriteriaSetting_criteria__date_operator"] | undefined,
	criteria__audience?: GraphQLTypes["CriteriaSetting_criteria__audience_operator"] | undefined,
	criteria__category?: GraphQLTypes["CriteriaSetting_criteria__category_operator"] | undefined,
	criteria__tag?: GraphQLTypes["CriteriaSetting_criteria__tag_operator"] | undefined,
	criteria__region?: GraphQLTypes["CriteriaSetting_criteria__region_operator"] | undefined,
	updatedAt?: GraphQLTypes["CriteriaSetting_updatedAt_operator"] | undefined,
	createdAt?: GraphQLTypes["CriteriaSetting_createdAt_operator"] | undefined,
	id?: GraphQLTypes["CriteriaSetting_id_operator"] | undefined,
	AND?: Array<GraphQLTypes["CriteriaSetting_where_and"] | undefined> | undefined,
	OR?: Array<GraphQLTypes["CriteriaSetting_where_or"] | undefined> | undefined
};
	["CriteriaSetting_criteria__date_operator"]: {
		equals?: GraphQLTypes["JSON"] | undefined,
	not_equals?: GraphQLTypes["JSON"] | undefined,
	in?: Array<GraphQLTypes["JSON"] | undefined> | undefined,
	not_in?: Array<GraphQLTypes["JSON"] | undefined> | undefined,
	all?: Array<GraphQLTypes["JSON"] | undefined> | undefined,
	exists?: boolean | undefined
};
	["CriteriaSetting_criteria__audience_operator"]: {
		equals?: GraphQLTypes["JSON"] | undefined,
	not_equals?: GraphQLTypes["JSON"] | undefined,
	in?: Array<GraphQLTypes["JSON"] | undefined> | undefined,
	not_in?: Array<GraphQLTypes["JSON"] | undefined> | undefined,
	all?: Array<GraphQLTypes["JSON"] | undefined> | undefined,
	exists?: boolean | undefined
};
	["CriteriaSetting_criteria__category_operator"]: {
		equals?: GraphQLTypes["JSON"] | undefined,
	not_equals?: GraphQLTypes["JSON"] | undefined,
	in?: Array<GraphQLTypes["JSON"] | undefined> | undefined,
	not_in?: Array<GraphQLTypes["JSON"] | undefined> | undefined,
	all?: Array<GraphQLTypes["JSON"] | undefined> | undefined,
	exists?: boolean | undefined
};
	["CriteriaSetting_criteria__tag_operator"]: {
		equals?: GraphQLTypes["JSON"] | undefined,
	not_equals?: GraphQLTypes["JSON"] | undefined,
	in?: Array<GraphQLTypes["JSON"] | undefined> | undefined,
	not_in?: Array<GraphQLTypes["JSON"] | undefined> | undefined,
	all?: Array<GraphQLTypes["JSON"] | undefined> | undefined,
	exists?: boolean | undefined
};
	["CriteriaSetting_criteria__region_operator"]: {
		equals?: GraphQLTypes["CriteriaSetting_criteria__region_Input"] | undefined,
	not_equals?: GraphQLTypes["CriteriaSetting_criteria__region_Input"] | undefined,
	in?: Array<GraphQLTypes["CriteriaSetting_criteria__region_Input"] | undefined> | undefined,
	not_in?: Array<GraphQLTypes["CriteriaSetting_criteria__region_Input"] | undefined> | undefined,
	all?: Array<GraphQLTypes["CriteriaSetting_criteria__region_Input"] | undefined> | undefined,
	exists?: boolean | undefined
};
	["CriteriaSetting_criteria__region_Input"]: CriteriaSetting_criteria__region_Input;
	["CriteriaSetting_updatedAt_operator"]: {
		equals?: GraphQLTypes["DateTime"] | undefined,
	not_equals?: GraphQLTypes["DateTime"] | undefined,
	greater_than_equal?: GraphQLTypes["DateTime"] | undefined,
	greater_than?: GraphQLTypes["DateTime"] | undefined,
	less_than_equal?: GraphQLTypes["DateTime"] | undefined,
	less_than?: GraphQLTypes["DateTime"] | undefined,
	like?: GraphQLTypes["DateTime"] | undefined,
	exists?: boolean | undefined
};
	["CriteriaSetting_createdAt_operator"]: {
		equals?: GraphQLTypes["DateTime"] | undefined,
	not_equals?: GraphQLTypes["DateTime"] | undefined,
	greater_than_equal?: GraphQLTypes["DateTime"] | undefined,
	greater_than?: GraphQLTypes["DateTime"] | undefined,
	less_than_equal?: GraphQLTypes["DateTime"] | undefined,
	less_than?: GraphQLTypes["DateTime"] | undefined,
	like?: GraphQLTypes["DateTime"] | undefined,
	exists?: boolean | undefined
};
	["CriteriaSetting_id_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["CriteriaSetting_where_and"]: {
		criteria__date?: GraphQLTypes["CriteriaSetting_criteria__date_operator"] | undefined,
	criteria__audience?: GraphQLTypes["CriteriaSetting_criteria__audience_operator"] | undefined,
	criteria__category?: GraphQLTypes["CriteriaSetting_criteria__category_operator"] | undefined,
	criteria__tag?: GraphQLTypes["CriteriaSetting_criteria__tag_operator"] | undefined,
	criteria__region?: GraphQLTypes["CriteriaSetting_criteria__region_operator"] | undefined,
	updatedAt?: GraphQLTypes["CriteriaSetting_updatedAt_operator"] | undefined,
	createdAt?: GraphQLTypes["CriteriaSetting_createdAt_operator"] | undefined,
	id?: GraphQLTypes["CriteriaSetting_id_operator"] | undefined,
	AND?: Array<GraphQLTypes["CriteriaSetting_where_and"] | undefined> | undefined,
	OR?: Array<GraphQLTypes["CriteriaSetting_where_or"] | undefined> | undefined
};
	["CriteriaSetting_where_or"]: {
		criteria__date?: GraphQLTypes["CriteriaSetting_criteria__date_operator"] | undefined,
	criteria__audience?: GraphQLTypes["CriteriaSetting_criteria__audience_operator"] | undefined,
	criteria__category?: GraphQLTypes["CriteriaSetting_criteria__category_operator"] | undefined,
	criteria__tag?: GraphQLTypes["CriteriaSetting_criteria__tag_operator"] | undefined,
	criteria__region?: GraphQLTypes["CriteriaSetting_criteria__region_operator"] | undefined,
	updatedAt?: GraphQLTypes["CriteriaSetting_updatedAt_operator"] | undefined,
	createdAt?: GraphQLTypes["CriteriaSetting_createdAt_operator"] | undefined,
	id?: GraphQLTypes["CriteriaSetting_id_operator"] | undefined,
	AND?: Array<GraphQLTypes["CriteriaSetting_where_and"] | undefined> | undefined,
	OR?: Array<GraphQLTypes["CriteriaSetting_where_or"] | undefined> | undefined
};
	["countCriteriaSettings"]: {
	__typename: "countCriteriaSettings",
	totalDocs?: number | undefined
};
	["criteria_settingDocAccess"]: {
	__typename: "criteria_settingDocAccess",
	fields?: GraphQLTypes["CriteriaSettingDocAccessFields"] | undefined,
	create?: GraphQLTypes["CriteriaSettingCreateDocAccess"] | undefined,
	read?: GraphQLTypes["CriteriaSettingReadDocAccess"] | undefined,
	update?: GraphQLTypes["CriteriaSettingUpdateDocAccess"] | undefined,
	delete?: GraphQLTypes["CriteriaSettingDeleteDocAccess"] | undefined
};
	["CriteriaSettingDocAccessFields"]: {
	__typename: "CriteriaSettingDocAccessFields",
	criteria?: GraphQLTypes["CriteriaSettingDocAccessFields_criteria"] | undefined,
	updatedAt?: GraphQLTypes["CriteriaSettingDocAccessFields_updatedAt"] | undefined,
	createdAt?: GraphQLTypes["CriteriaSettingDocAccessFields_createdAt"] | undefined
};
	["CriteriaSettingDocAccessFields_criteria"]: {
	__typename: "CriteriaSettingDocAccessFields_criteria",
	create?: GraphQLTypes["CriteriaSettingDocAccessFields_criteria_Create"] | undefined,
	read?: GraphQLTypes["CriteriaSettingDocAccessFields_criteria_Read"] | undefined,
	update?: GraphQLTypes["CriteriaSettingDocAccessFields_criteria_Update"] | undefined,
	delete?: GraphQLTypes["CriteriaSettingDocAccessFields_criteria_Delete"] | undefined,
	fields?: GraphQLTypes["CriteriaSettingDocAccessFields_criteria_Fields"] | undefined
};
	["CriteriaSettingDocAccessFields_criteria_Create"]: {
	__typename: "CriteriaSettingDocAccessFields_criteria_Create",
	permission: boolean
};
	["CriteriaSettingDocAccessFields_criteria_Read"]: {
	__typename: "CriteriaSettingDocAccessFields_criteria_Read",
	permission: boolean
};
	["CriteriaSettingDocAccessFields_criteria_Update"]: {
	__typename: "CriteriaSettingDocAccessFields_criteria_Update",
	permission: boolean
};
	["CriteriaSettingDocAccessFields_criteria_Delete"]: {
	__typename: "CriteriaSettingDocAccessFields_criteria_Delete",
	permission: boolean
};
	["CriteriaSettingDocAccessFields_criteria_Fields"]: {
	__typename: "CriteriaSettingDocAccessFields_criteria_Fields",
	date?: GraphQLTypes["CriteriaSettingDocAccessFields_criteria_date"] | undefined,
	audience?: GraphQLTypes["CriteriaSettingDocAccessFields_criteria_audience"] | undefined,
	category?: GraphQLTypes["CriteriaSettingDocAccessFields_criteria_category"] | undefined,
	tag?: GraphQLTypes["CriteriaSettingDocAccessFields_criteria_tag"] | undefined,
	region?: GraphQLTypes["CriteriaSettingDocAccessFields_criteria_region"] | undefined
};
	["CriteriaSettingDocAccessFields_criteria_date"]: {
	__typename: "CriteriaSettingDocAccessFields_criteria_date",
	create?: GraphQLTypes["CriteriaSettingDocAccessFields_criteria_date_Create"] | undefined,
	read?: GraphQLTypes["CriteriaSettingDocAccessFields_criteria_date_Read"] | undefined,
	update?: GraphQLTypes["CriteriaSettingDocAccessFields_criteria_date_Update"] | undefined,
	delete?: GraphQLTypes["CriteriaSettingDocAccessFields_criteria_date_Delete"] | undefined
};
	["CriteriaSettingDocAccessFields_criteria_date_Create"]: {
	__typename: "CriteriaSettingDocAccessFields_criteria_date_Create",
	permission: boolean
};
	["CriteriaSettingDocAccessFields_criteria_date_Read"]: {
	__typename: "CriteriaSettingDocAccessFields_criteria_date_Read",
	permission: boolean
};
	["CriteriaSettingDocAccessFields_criteria_date_Update"]: {
	__typename: "CriteriaSettingDocAccessFields_criteria_date_Update",
	permission: boolean
};
	["CriteriaSettingDocAccessFields_criteria_date_Delete"]: {
	__typename: "CriteriaSettingDocAccessFields_criteria_date_Delete",
	permission: boolean
};
	["CriteriaSettingDocAccessFields_criteria_audience"]: {
	__typename: "CriteriaSettingDocAccessFields_criteria_audience",
	create?: GraphQLTypes["CriteriaSettingDocAccessFields_criteria_audience_Create"] | undefined,
	read?: GraphQLTypes["CriteriaSettingDocAccessFields_criteria_audience_Read"] | undefined,
	update?: GraphQLTypes["CriteriaSettingDocAccessFields_criteria_audience_Update"] | undefined,
	delete?: GraphQLTypes["CriteriaSettingDocAccessFields_criteria_audience_Delete"] | undefined
};
	["CriteriaSettingDocAccessFields_criteria_audience_Create"]: {
	__typename: "CriteriaSettingDocAccessFields_criteria_audience_Create",
	permission: boolean
};
	["CriteriaSettingDocAccessFields_criteria_audience_Read"]: {
	__typename: "CriteriaSettingDocAccessFields_criteria_audience_Read",
	permission: boolean
};
	["CriteriaSettingDocAccessFields_criteria_audience_Update"]: {
	__typename: "CriteriaSettingDocAccessFields_criteria_audience_Update",
	permission: boolean
};
	["CriteriaSettingDocAccessFields_criteria_audience_Delete"]: {
	__typename: "CriteriaSettingDocAccessFields_criteria_audience_Delete",
	permission: boolean
};
	["CriteriaSettingDocAccessFields_criteria_category"]: {
	__typename: "CriteriaSettingDocAccessFields_criteria_category",
	create?: GraphQLTypes["CriteriaSettingDocAccessFields_criteria_category_Create"] | undefined,
	read?: GraphQLTypes["CriteriaSettingDocAccessFields_criteria_category_Read"] | undefined,
	update?: GraphQLTypes["CriteriaSettingDocAccessFields_criteria_category_Update"] | undefined,
	delete?: GraphQLTypes["CriteriaSettingDocAccessFields_criteria_category_Delete"] | undefined
};
	["CriteriaSettingDocAccessFields_criteria_category_Create"]: {
	__typename: "CriteriaSettingDocAccessFields_criteria_category_Create",
	permission: boolean
};
	["CriteriaSettingDocAccessFields_criteria_category_Read"]: {
	__typename: "CriteriaSettingDocAccessFields_criteria_category_Read",
	permission: boolean
};
	["CriteriaSettingDocAccessFields_criteria_category_Update"]: {
	__typename: "CriteriaSettingDocAccessFields_criteria_category_Update",
	permission: boolean
};
	["CriteriaSettingDocAccessFields_criteria_category_Delete"]: {
	__typename: "CriteriaSettingDocAccessFields_criteria_category_Delete",
	permission: boolean
};
	["CriteriaSettingDocAccessFields_criteria_tag"]: {
	__typename: "CriteriaSettingDocAccessFields_criteria_tag",
	create?: GraphQLTypes["CriteriaSettingDocAccessFields_criteria_tag_Create"] | undefined,
	read?: GraphQLTypes["CriteriaSettingDocAccessFields_criteria_tag_Read"] | undefined,
	update?: GraphQLTypes["CriteriaSettingDocAccessFields_criteria_tag_Update"] | undefined,
	delete?: GraphQLTypes["CriteriaSettingDocAccessFields_criteria_tag_Delete"] | undefined
};
	["CriteriaSettingDocAccessFields_criteria_tag_Create"]: {
	__typename: "CriteriaSettingDocAccessFields_criteria_tag_Create",
	permission: boolean
};
	["CriteriaSettingDocAccessFields_criteria_tag_Read"]: {
	__typename: "CriteriaSettingDocAccessFields_criteria_tag_Read",
	permission: boolean
};
	["CriteriaSettingDocAccessFields_criteria_tag_Update"]: {
	__typename: "CriteriaSettingDocAccessFields_criteria_tag_Update",
	permission: boolean
};
	["CriteriaSettingDocAccessFields_criteria_tag_Delete"]: {
	__typename: "CriteriaSettingDocAccessFields_criteria_tag_Delete",
	permission: boolean
};
	["CriteriaSettingDocAccessFields_criteria_region"]: {
	__typename: "CriteriaSettingDocAccessFields_criteria_region",
	create?: GraphQLTypes["CriteriaSettingDocAccessFields_criteria_region_Create"] | undefined,
	read?: GraphQLTypes["CriteriaSettingDocAccessFields_criteria_region_Read"] | undefined,
	update?: GraphQLTypes["CriteriaSettingDocAccessFields_criteria_region_Update"] | undefined,
	delete?: GraphQLTypes["CriteriaSettingDocAccessFields_criteria_region_Delete"] | undefined
};
	["CriteriaSettingDocAccessFields_criteria_region_Create"]: {
	__typename: "CriteriaSettingDocAccessFields_criteria_region_Create",
	permission: boolean
};
	["CriteriaSettingDocAccessFields_criteria_region_Read"]: {
	__typename: "CriteriaSettingDocAccessFields_criteria_region_Read",
	permission: boolean
};
	["CriteriaSettingDocAccessFields_criteria_region_Update"]: {
	__typename: "CriteriaSettingDocAccessFields_criteria_region_Update",
	permission: boolean
};
	["CriteriaSettingDocAccessFields_criteria_region_Delete"]: {
	__typename: "CriteriaSettingDocAccessFields_criteria_region_Delete",
	permission: boolean
};
	["CriteriaSettingDocAccessFields_updatedAt"]: {
	__typename: "CriteriaSettingDocAccessFields_updatedAt",
	create?: GraphQLTypes["CriteriaSettingDocAccessFields_updatedAt_Create"] | undefined,
	read?: GraphQLTypes["CriteriaSettingDocAccessFields_updatedAt_Read"] | undefined,
	update?: GraphQLTypes["CriteriaSettingDocAccessFields_updatedAt_Update"] | undefined,
	delete?: GraphQLTypes["CriteriaSettingDocAccessFields_updatedAt_Delete"] | undefined
};
	["CriteriaSettingDocAccessFields_updatedAt_Create"]: {
	__typename: "CriteriaSettingDocAccessFields_updatedAt_Create",
	permission: boolean
};
	["CriteriaSettingDocAccessFields_updatedAt_Read"]: {
	__typename: "CriteriaSettingDocAccessFields_updatedAt_Read",
	permission: boolean
};
	["CriteriaSettingDocAccessFields_updatedAt_Update"]: {
	__typename: "CriteriaSettingDocAccessFields_updatedAt_Update",
	permission: boolean
};
	["CriteriaSettingDocAccessFields_updatedAt_Delete"]: {
	__typename: "CriteriaSettingDocAccessFields_updatedAt_Delete",
	permission: boolean
};
	["CriteriaSettingDocAccessFields_createdAt"]: {
	__typename: "CriteriaSettingDocAccessFields_createdAt",
	create?: GraphQLTypes["CriteriaSettingDocAccessFields_createdAt_Create"] | undefined,
	read?: GraphQLTypes["CriteriaSettingDocAccessFields_createdAt_Read"] | undefined,
	update?: GraphQLTypes["CriteriaSettingDocAccessFields_createdAt_Update"] | undefined,
	delete?: GraphQLTypes["CriteriaSettingDocAccessFields_createdAt_Delete"] | undefined
};
	["CriteriaSettingDocAccessFields_createdAt_Create"]: {
	__typename: "CriteriaSettingDocAccessFields_createdAt_Create",
	permission: boolean
};
	["CriteriaSettingDocAccessFields_createdAt_Read"]: {
	__typename: "CriteriaSettingDocAccessFields_createdAt_Read",
	permission: boolean
};
	["CriteriaSettingDocAccessFields_createdAt_Update"]: {
	__typename: "CriteriaSettingDocAccessFields_createdAt_Update",
	permission: boolean
};
	["CriteriaSettingDocAccessFields_createdAt_Delete"]: {
	__typename: "CriteriaSettingDocAccessFields_createdAt_Delete",
	permission: boolean
};
	["CriteriaSettingCreateDocAccess"]: {
	__typename: "CriteriaSettingCreateDocAccess",
	permission: boolean,
	where?: GraphQLTypes["JSONObject"] | undefined
};
	["CriteriaSettingReadDocAccess"]: {
	__typename: "CriteriaSettingReadDocAccess",
	permission: boolean,
	where?: GraphQLTypes["JSONObject"] | undefined
};
	["CriteriaSettingUpdateDocAccess"]: {
	__typename: "CriteriaSettingUpdateDocAccess",
	permission: boolean,
	where?: GraphQLTypes["JSONObject"] | undefined
};
	["CriteriaSettingDeleteDocAccess"]: {
	__typename: "CriteriaSettingDeleteDocAccess",
	permission: boolean,
	where?: GraphQLTypes["JSONObject"] | undefined
};
	["OrganiserSetting"]: {
	__typename: "OrganiserSetting",
	id?: string | undefined,
	name?: string | undefined,
	organiserList?: Array<GraphQLTypes["User"]> | undefined,
	region?: GraphQLTypes["OrganiserSetting_region"] | undefined,
	updatedAt?: GraphQLTypes["DateTime"] | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined
};
	["OrganiserSetting_region"]: OrganiserSetting_region;
	["OrganiserSettings"]: {
	__typename: "OrganiserSettings",
	docs?: Array<GraphQLTypes["OrganiserSetting"] | undefined> | undefined,
	hasNextPage?: boolean | undefined,
	hasPrevPage?: boolean | undefined,
	limit?: number | undefined,
	nextPage?: number | undefined,
	offset?: number | undefined,
	page?: number | undefined,
	pagingCounter?: number | undefined,
	prevPage?: number | undefined,
	totalDocs?: number | undefined,
	totalPages?: number | undefined
};
	["OrganiserSetting_where"]: {
		name?: GraphQLTypes["OrganiserSetting_name_operator"] | undefined,
	organiserList?: GraphQLTypes["OrganiserSetting_organiserList_operator"] | undefined,
	region?: GraphQLTypes["OrganiserSetting_region_operator"] | undefined,
	updatedAt?: GraphQLTypes["OrganiserSetting_updatedAt_operator"] | undefined,
	createdAt?: GraphQLTypes["OrganiserSetting_createdAt_operator"] | undefined,
	id?: GraphQLTypes["OrganiserSetting_id_operator"] | undefined,
	AND?: Array<GraphQLTypes["OrganiserSetting_where_and"] | undefined> | undefined,
	OR?: Array<GraphQLTypes["OrganiserSetting_where_or"] | undefined> | undefined
};
	["OrganiserSetting_name_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["OrganiserSetting_organiserList_operator"]: {
		equals?: GraphQLTypes["JSON"] | undefined,
	not_equals?: GraphQLTypes["JSON"] | undefined,
	in?: Array<GraphQLTypes["JSON"] | undefined> | undefined,
	not_in?: Array<GraphQLTypes["JSON"] | undefined> | undefined,
	all?: Array<GraphQLTypes["JSON"] | undefined> | undefined,
	exists?: boolean | undefined
};
	["OrganiserSetting_region_operator"]: {
		equals?: GraphQLTypes["OrganiserSetting_region_Input"] | undefined,
	not_equals?: GraphQLTypes["OrganiserSetting_region_Input"] | undefined,
	in?: Array<GraphQLTypes["OrganiserSetting_region_Input"] | undefined> | undefined,
	not_in?: Array<GraphQLTypes["OrganiserSetting_region_Input"] | undefined> | undefined,
	all?: Array<GraphQLTypes["OrganiserSetting_region_Input"] | undefined> | undefined,
	exists?: boolean | undefined
};
	["OrganiserSetting_region_Input"]: OrganiserSetting_region_Input;
	["OrganiserSetting_updatedAt_operator"]: {
		equals?: GraphQLTypes["DateTime"] | undefined,
	not_equals?: GraphQLTypes["DateTime"] | undefined,
	greater_than_equal?: GraphQLTypes["DateTime"] | undefined,
	greater_than?: GraphQLTypes["DateTime"] | undefined,
	less_than_equal?: GraphQLTypes["DateTime"] | undefined,
	less_than?: GraphQLTypes["DateTime"] | undefined,
	like?: GraphQLTypes["DateTime"] | undefined,
	exists?: boolean | undefined
};
	["OrganiserSetting_createdAt_operator"]: {
		equals?: GraphQLTypes["DateTime"] | undefined,
	not_equals?: GraphQLTypes["DateTime"] | undefined,
	greater_than_equal?: GraphQLTypes["DateTime"] | undefined,
	greater_than?: GraphQLTypes["DateTime"] | undefined,
	less_than_equal?: GraphQLTypes["DateTime"] | undefined,
	less_than?: GraphQLTypes["DateTime"] | undefined,
	like?: GraphQLTypes["DateTime"] | undefined,
	exists?: boolean | undefined
};
	["OrganiserSetting_id_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["OrganiserSetting_where_and"]: {
		name?: GraphQLTypes["OrganiserSetting_name_operator"] | undefined,
	organiserList?: GraphQLTypes["OrganiserSetting_organiserList_operator"] | undefined,
	region?: GraphQLTypes["OrganiserSetting_region_operator"] | undefined,
	updatedAt?: GraphQLTypes["OrganiserSetting_updatedAt_operator"] | undefined,
	createdAt?: GraphQLTypes["OrganiserSetting_createdAt_operator"] | undefined,
	id?: GraphQLTypes["OrganiserSetting_id_operator"] | undefined,
	AND?: Array<GraphQLTypes["OrganiserSetting_where_and"] | undefined> | undefined,
	OR?: Array<GraphQLTypes["OrganiserSetting_where_or"] | undefined> | undefined
};
	["OrganiserSetting_where_or"]: {
		name?: GraphQLTypes["OrganiserSetting_name_operator"] | undefined,
	organiserList?: GraphQLTypes["OrganiserSetting_organiserList_operator"] | undefined,
	region?: GraphQLTypes["OrganiserSetting_region_operator"] | undefined,
	updatedAt?: GraphQLTypes["OrganiserSetting_updatedAt_operator"] | undefined,
	createdAt?: GraphQLTypes["OrganiserSetting_createdAt_operator"] | undefined,
	id?: GraphQLTypes["OrganiserSetting_id_operator"] | undefined,
	AND?: Array<GraphQLTypes["OrganiserSetting_where_and"] | undefined> | undefined,
	OR?: Array<GraphQLTypes["OrganiserSetting_where_or"] | undefined> | undefined
};
	["countOrganiserSettings"]: {
	__typename: "countOrganiserSettings",
	totalDocs?: number | undefined
};
	["organiserSettingDocAccess"]: {
	__typename: "organiserSettingDocAccess",
	fields?: GraphQLTypes["OrganiserSettingDocAccessFields"] | undefined,
	create?: GraphQLTypes["OrganiserSettingCreateDocAccess"] | undefined,
	read?: GraphQLTypes["OrganiserSettingReadDocAccess"] | undefined,
	update?: GraphQLTypes["OrganiserSettingUpdateDocAccess"] | undefined,
	delete?: GraphQLTypes["OrganiserSettingDeleteDocAccess"] | undefined
};
	["OrganiserSettingDocAccessFields"]: {
	__typename: "OrganiserSettingDocAccessFields",
	name?: GraphQLTypes["OrganiserSettingDocAccessFields_name"] | undefined,
	organiserList?: GraphQLTypes["OrganiserSettingDocAccessFields_organiserList"] | undefined,
	region?: GraphQLTypes["OrganiserSettingDocAccessFields_region"] | undefined,
	updatedAt?: GraphQLTypes["OrganiserSettingDocAccessFields_updatedAt"] | undefined,
	createdAt?: GraphQLTypes["OrganiserSettingDocAccessFields_createdAt"] | undefined
};
	["OrganiserSettingDocAccessFields_name"]: {
	__typename: "OrganiserSettingDocAccessFields_name",
	create?: GraphQLTypes["OrganiserSettingDocAccessFields_name_Create"] | undefined,
	read?: GraphQLTypes["OrganiserSettingDocAccessFields_name_Read"] | undefined,
	update?: GraphQLTypes["OrganiserSettingDocAccessFields_name_Update"] | undefined,
	delete?: GraphQLTypes["OrganiserSettingDocAccessFields_name_Delete"] | undefined
};
	["OrganiserSettingDocAccessFields_name_Create"]: {
	__typename: "OrganiserSettingDocAccessFields_name_Create",
	permission: boolean
};
	["OrganiserSettingDocAccessFields_name_Read"]: {
	__typename: "OrganiserSettingDocAccessFields_name_Read",
	permission: boolean
};
	["OrganiserSettingDocAccessFields_name_Update"]: {
	__typename: "OrganiserSettingDocAccessFields_name_Update",
	permission: boolean
};
	["OrganiserSettingDocAccessFields_name_Delete"]: {
	__typename: "OrganiserSettingDocAccessFields_name_Delete",
	permission: boolean
};
	["OrganiserSettingDocAccessFields_organiserList"]: {
	__typename: "OrganiserSettingDocAccessFields_organiserList",
	create?: GraphQLTypes["OrganiserSettingDocAccessFields_organiserList_Create"] | undefined,
	read?: GraphQLTypes["OrganiserSettingDocAccessFields_organiserList_Read"] | undefined,
	update?: GraphQLTypes["OrganiserSettingDocAccessFields_organiserList_Update"] | undefined,
	delete?: GraphQLTypes["OrganiserSettingDocAccessFields_organiserList_Delete"] | undefined
};
	["OrganiserSettingDocAccessFields_organiserList_Create"]: {
	__typename: "OrganiserSettingDocAccessFields_organiserList_Create",
	permission: boolean
};
	["OrganiserSettingDocAccessFields_organiserList_Read"]: {
	__typename: "OrganiserSettingDocAccessFields_organiserList_Read",
	permission: boolean
};
	["OrganiserSettingDocAccessFields_organiserList_Update"]: {
	__typename: "OrganiserSettingDocAccessFields_organiserList_Update",
	permission: boolean
};
	["OrganiserSettingDocAccessFields_organiserList_Delete"]: {
	__typename: "OrganiserSettingDocAccessFields_organiserList_Delete",
	permission: boolean
};
	["OrganiserSettingDocAccessFields_region"]: {
	__typename: "OrganiserSettingDocAccessFields_region",
	create?: GraphQLTypes["OrganiserSettingDocAccessFields_region_Create"] | undefined,
	read?: GraphQLTypes["OrganiserSettingDocAccessFields_region_Read"] | undefined,
	update?: GraphQLTypes["OrganiserSettingDocAccessFields_region_Update"] | undefined,
	delete?: GraphQLTypes["OrganiserSettingDocAccessFields_region_Delete"] | undefined
};
	["OrganiserSettingDocAccessFields_region_Create"]: {
	__typename: "OrganiserSettingDocAccessFields_region_Create",
	permission: boolean
};
	["OrganiserSettingDocAccessFields_region_Read"]: {
	__typename: "OrganiserSettingDocAccessFields_region_Read",
	permission: boolean
};
	["OrganiserSettingDocAccessFields_region_Update"]: {
	__typename: "OrganiserSettingDocAccessFields_region_Update",
	permission: boolean
};
	["OrganiserSettingDocAccessFields_region_Delete"]: {
	__typename: "OrganiserSettingDocAccessFields_region_Delete",
	permission: boolean
};
	["OrganiserSettingDocAccessFields_updatedAt"]: {
	__typename: "OrganiserSettingDocAccessFields_updatedAt",
	create?: GraphQLTypes["OrganiserSettingDocAccessFields_updatedAt_Create"] | undefined,
	read?: GraphQLTypes["OrganiserSettingDocAccessFields_updatedAt_Read"] | undefined,
	update?: GraphQLTypes["OrganiserSettingDocAccessFields_updatedAt_Update"] | undefined,
	delete?: GraphQLTypes["OrganiserSettingDocAccessFields_updatedAt_Delete"] | undefined
};
	["OrganiserSettingDocAccessFields_updatedAt_Create"]: {
	__typename: "OrganiserSettingDocAccessFields_updatedAt_Create",
	permission: boolean
};
	["OrganiserSettingDocAccessFields_updatedAt_Read"]: {
	__typename: "OrganiserSettingDocAccessFields_updatedAt_Read",
	permission: boolean
};
	["OrganiserSettingDocAccessFields_updatedAt_Update"]: {
	__typename: "OrganiserSettingDocAccessFields_updatedAt_Update",
	permission: boolean
};
	["OrganiserSettingDocAccessFields_updatedAt_Delete"]: {
	__typename: "OrganiserSettingDocAccessFields_updatedAt_Delete",
	permission: boolean
};
	["OrganiserSettingDocAccessFields_createdAt"]: {
	__typename: "OrganiserSettingDocAccessFields_createdAt",
	create?: GraphQLTypes["OrganiserSettingDocAccessFields_createdAt_Create"] | undefined,
	read?: GraphQLTypes["OrganiserSettingDocAccessFields_createdAt_Read"] | undefined,
	update?: GraphQLTypes["OrganiserSettingDocAccessFields_createdAt_Update"] | undefined,
	delete?: GraphQLTypes["OrganiserSettingDocAccessFields_createdAt_Delete"] | undefined
};
	["OrganiserSettingDocAccessFields_createdAt_Create"]: {
	__typename: "OrganiserSettingDocAccessFields_createdAt_Create",
	permission: boolean
};
	["OrganiserSettingDocAccessFields_createdAt_Read"]: {
	__typename: "OrganiserSettingDocAccessFields_createdAt_Read",
	permission: boolean
};
	["OrganiserSettingDocAccessFields_createdAt_Update"]: {
	__typename: "OrganiserSettingDocAccessFields_createdAt_Update",
	permission: boolean
};
	["OrganiserSettingDocAccessFields_createdAt_Delete"]: {
	__typename: "OrganiserSettingDocAccessFields_createdAt_Delete",
	permission: boolean
};
	["OrganiserSettingCreateDocAccess"]: {
	__typename: "OrganiserSettingCreateDocAccess",
	permission: boolean,
	where?: GraphQLTypes["JSONObject"] | undefined
};
	["OrganiserSettingReadDocAccess"]: {
	__typename: "OrganiserSettingReadDocAccess",
	permission: boolean,
	where?: GraphQLTypes["JSONObject"] | undefined
};
	["OrganiserSettingUpdateDocAccess"]: {
	__typename: "OrganiserSettingUpdateDocAccess",
	permission: boolean,
	where?: GraphQLTypes["JSONObject"] | undefined
};
	["OrganiserSettingDeleteDocAccess"]: {
	__typename: "OrganiserSettingDeleteDocAccess",
	permission: boolean,
	where?: GraphQLTypes["JSONObject"] | undefined
};
	["PostSetting"]: {
	__typename: "PostSetting",
	id?: string | undefined,
	name?: string | undefined,
	featuredPosts?: Array<GraphQLTypes["Post"]> | undefined,
	region?: GraphQLTypes["PostSetting_region"] | undefined,
	updatedAt?: GraphQLTypes["DateTime"] | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined
};
	["PostSetting_region"]: PostSetting_region;
	["PostSettings"]: {
	__typename: "PostSettings",
	docs?: Array<GraphQLTypes["PostSetting"] | undefined> | undefined,
	hasNextPage?: boolean | undefined,
	hasPrevPage?: boolean | undefined,
	limit?: number | undefined,
	nextPage?: number | undefined,
	offset?: number | undefined,
	page?: number | undefined,
	pagingCounter?: number | undefined,
	prevPage?: number | undefined,
	totalDocs?: number | undefined,
	totalPages?: number | undefined
};
	["PostSetting_where"]: {
		name?: GraphQLTypes["PostSetting_name_operator"] | undefined,
	featuredPosts?: GraphQLTypes["PostSetting_featuredPosts_operator"] | undefined,
	region?: GraphQLTypes["PostSetting_region_operator"] | undefined,
	updatedAt?: GraphQLTypes["PostSetting_updatedAt_operator"] | undefined,
	createdAt?: GraphQLTypes["PostSetting_createdAt_operator"] | undefined,
	id?: GraphQLTypes["PostSetting_id_operator"] | undefined,
	AND?: Array<GraphQLTypes["PostSetting_where_and"] | undefined> | undefined,
	OR?: Array<GraphQLTypes["PostSetting_where_or"] | undefined> | undefined
};
	["PostSetting_name_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["PostSetting_featuredPosts_operator"]: {
		equals?: GraphQLTypes["JSON"] | undefined,
	not_equals?: GraphQLTypes["JSON"] | undefined,
	in?: Array<GraphQLTypes["JSON"] | undefined> | undefined,
	not_in?: Array<GraphQLTypes["JSON"] | undefined> | undefined,
	all?: Array<GraphQLTypes["JSON"] | undefined> | undefined,
	exists?: boolean | undefined
};
	["PostSetting_region_operator"]: {
		equals?: GraphQLTypes["PostSetting_region_Input"] | undefined,
	not_equals?: GraphQLTypes["PostSetting_region_Input"] | undefined,
	in?: Array<GraphQLTypes["PostSetting_region_Input"] | undefined> | undefined,
	not_in?: Array<GraphQLTypes["PostSetting_region_Input"] | undefined> | undefined,
	all?: Array<GraphQLTypes["PostSetting_region_Input"] | undefined> | undefined,
	exists?: boolean | undefined
};
	["PostSetting_region_Input"]: PostSetting_region_Input;
	["PostSetting_updatedAt_operator"]: {
		equals?: GraphQLTypes["DateTime"] | undefined,
	not_equals?: GraphQLTypes["DateTime"] | undefined,
	greater_than_equal?: GraphQLTypes["DateTime"] | undefined,
	greater_than?: GraphQLTypes["DateTime"] | undefined,
	less_than_equal?: GraphQLTypes["DateTime"] | undefined,
	less_than?: GraphQLTypes["DateTime"] | undefined,
	like?: GraphQLTypes["DateTime"] | undefined,
	exists?: boolean | undefined
};
	["PostSetting_createdAt_operator"]: {
		equals?: GraphQLTypes["DateTime"] | undefined,
	not_equals?: GraphQLTypes["DateTime"] | undefined,
	greater_than_equal?: GraphQLTypes["DateTime"] | undefined,
	greater_than?: GraphQLTypes["DateTime"] | undefined,
	less_than_equal?: GraphQLTypes["DateTime"] | undefined,
	less_than?: GraphQLTypes["DateTime"] | undefined,
	like?: GraphQLTypes["DateTime"] | undefined,
	exists?: boolean | undefined
};
	["PostSetting_id_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["PostSetting_where_and"]: {
		name?: GraphQLTypes["PostSetting_name_operator"] | undefined,
	featuredPosts?: GraphQLTypes["PostSetting_featuredPosts_operator"] | undefined,
	region?: GraphQLTypes["PostSetting_region_operator"] | undefined,
	updatedAt?: GraphQLTypes["PostSetting_updatedAt_operator"] | undefined,
	createdAt?: GraphQLTypes["PostSetting_createdAt_operator"] | undefined,
	id?: GraphQLTypes["PostSetting_id_operator"] | undefined,
	AND?: Array<GraphQLTypes["PostSetting_where_and"] | undefined> | undefined,
	OR?: Array<GraphQLTypes["PostSetting_where_or"] | undefined> | undefined
};
	["PostSetting_where_or"]: {
		name?: GraphQLTypes["PostSetting_name_operator"] | undefined,
	featuredPosts?: GraphQLTypes["PostSetting_featuredPosts_operator"] | undefined,
	region?: GraphQLTypes["PostSetting_region_operator"] | undefined,
	updatedAt?: GraphQLTypes["PostSetting_updatedAt_operator"] | undefined,
	createdAt?: GraphQLTypes["PostSetting_createdAt_operator"] | undefined,
	id?: GraphQLTypes["PostSetting_id_operator"] | undefined,
	AND?: Array<GraphQLTypes["PostSetting_where_and"] | undefined> | undefined,
	OR?: Array<GraphQLTypes["PostSetting_where_or"] | undefined> | undefined
};
	["countPostSettings"]: {
	__typename: "countPostSettings",
	totalDocs?: number | undefined
};
	["post_settingDocAccess"]: {
	__typename: "post_settingDocAccess",
	fields?: GraphQLTypes["PostSettingDocAccessFields"] | undefined,
	create?: GraphQLTypes["PostSettingCreateDocAccess"] | undefined,
	read?: GraphQLTypes["PostSettingReadDocAccess"] | undefined,
	update?: GraphQLTypes["PostSettingUpdateDocAccess"] | undefined,
	delete?: GraphQLTypes["PostSettingDeleteDocAccess"] | undefined
};
	["PostSettingDocAccessFields"]: {
	__typename: "PostSettingDocAccessFields",
	name?: GraphQLTypes["PostSettingDocAccessFields_name"] | undefined,
	featuredPosts?: GraphQLTypes["PostSettingDocAccessFields_featuredPosts"] | undefined,
	region?: GraphQLTypes["PostSettingDocAccessFields_region"] | undefined,
	updatedAt?: GraphQLTypes["PostSettingDocAccessFields_updatedAt"] | undefined,
	createdAt?: GraphQLTypes["PostSettingDocAccessFields_createdAt"] | undefined
};
	["PostSettingDocAccessFields_name"]: {
	__typename: "PostSettingDocAccessFields_name",
	create?: GraphQLTypes["PostSettingDocAccessFields_name_Create"] | undefined,
	read?: GraphQLTypes["PostSettingDocAccessFields_name_Read"] | undefined,
	update?: GraphQLTypes["PostSettingDocAccessFields_name_Update"] | undefined,
	delete?: GraphQLTypes["PostSettingDocAccessFields_name_Delete"] | undefined
};
	["PostSettingDocAccessFields_name_Create"]: {
	__typename: "PostSettingDocAccessFields_name_Create",
	permission: boolean
};
	["PostSettingDocAccessFields_name_Read"]: {
	__typename: "PostSettingDocAccessFields_name_Read",
	permission: boolean
};
	["PostSettingDocAccessFields_name_Update"]: {
	__typename: "PostSettingDocAccessFields_name_Update",
	permission: boolean
};
	["PostSettingDocAccessFields_name_Delete"]: {
	__typename: "PostSettingDocAccessFields_name_Delete",
	permission: boolean
};
	["PostSettingDocAccessFields_featuredPosts"]: {
	__typename: "PostSettingDocAccessFields_featuredPosts",
	create?: GraphQLTypes["PostSettingDocAccessFields_featuredPosts_Create"] | undefined,
	read?: GraphQLTypes["PostSettingDocAccessFields_featuredPosts_Read"] | undefined,
	update?: GraphQLTypes["PostSettingDocAccessFields_featuredPosts_Update"] | undefined,
	delete?: GraphQLTypes["PostSettingDocAccessFields_featuredPosts_Delete"] | undefined
};
	["PostSettingDocAccessFields_featuredPosts_Create"]: {
	__typename: "PostSettingDocAccessFields_featuredPosts_Create",
	permission: boolean
};
	["PostSettingDocAccessFields_featuredPosts_Read"]: {
	__typename: "PostSettingDocAccessFields_featuredPosts_Read",
	permission: boolean
};
	["PostSettingDocAccessFields_featuredPosts_Update"]: {
	__typename: "PostSettingDocAccessFields_featuredPosts_Update",
	permission: boolean
};
	["PostSettingDocAccessFields_featuredPosts_Delete"]: {
	__typename: "PostSettingDocAccessFields_featuredPosts_Delete",
	permission: boolean
};
	["PostSettingDocAccessFields_region"]: {
	__typename: "PostSettingDocAccessFields_region",
	create?: GraphQLTypes["PostSettingDocAccessFields_region_Create"] | undefined,
	read?: GraphQLTypes["PostSettingDocAccessFields_region_Read"] | undefined,
	update?: GraphQLTypes["PostSettingDocAccessFields_region_Update"] | undefined,
	delete?: GraphQLTypes["PostSettingDocAccessFields_region_Delete"] | undefined
};
	["PostSettingDocAccessFields_region_Create"]: {
	__typename: "PostSettingDocAccessFields_region_Create",
	permission: boolean
};
	["PostSettingDocAccessFields_region_Read"]: {
	__typename: "PostSettingDocAccessFields_region_Read",
	permission: boolean
};
	["PostSettingDocAccessFields_region_Update"]: {
	__typename: "PostSettingDocAccessFields_region_Update",
	permission: boolean
};
	["PostSettingDocAccessFields_region_Delete"]: {
	__typename: "PostSettingDocAccessFields_region_Delete",
	permission: boolean
};
	["PostSettingDocAccessFields_updatedAt"]: {
	__typename: "PostSettingDocAccessFields_updatedAt",
	create?: GraphQLTypes["PostSettingDocAccessFields_updatedAt_Create"] | undefined,
	read?: GraphQLTypes["PostSettingDocAccessFields_updatedAt_Read"] | undefined,
	update?: GraphQLTypes["PostSettingDocAccessFields_updatedAt_Update"] | undefined,
	delete?: GraphQLTypes["PostSettingDocAccessFields_updatedAt_Delete"] | undefined
};
	["PostSettingDocAccessFields_updatedAt_Create"]: {
	__typename: "PostSettingDocAccessFields_updatedAt_Create",
	permission: boolean
};
	["PostSettingDocAccessFields_updatedAt_Read"]: {
	__typename: "PostSettingDocAccessFields_updatedAt_Read",
	permission: boolean
};
	["PostSettingDocAccessFields_updatedAt_Update"]: {
	__typename: "PostSettingDocAccessFields_updatedAt_Update",
	permission: boolean
};
	["PostSettingDocAccessFields_updatedAt_Delete"]: {
	__typename: "PostSettingDocAccessFields_updatedAt_Delete",
	permission: boolean
};
	["PostSettingDocAccessFields_createdAt"]: {
	__typename: "PostSettingDocAccessFields_createdAt",
	create?: GraphQLTypes["PostSettingDocAccessFields_createdAt_Create"] | undefined,
	read?: GraphQLTypes["PostSettingDocAccessFields_createdAt_Read"] | undefined,
	update?: GraphQLTypes["PostSettingDocAccessFields_createdAt_Update"] | undefined,
	delete?: GraphQLTypes["PostSettingDocAccessFields_createdAt_Delete"] | undefined
};
	["PostSettingDocAccessFields_createdAt_Create"]: {
	__typename: "PostSettingDocAccessFields_createdAt_Create",
	permission: boolean
};
	["PostSettingDocAccessFields_createdAt_Read"]: {
	__typename: "PostSettingDocAccessFields_createdAt_Read",
	permission: boolean
};
	["PostSettingDocAccessFields_createdAt_Update"]: {
	__typename: "PostSettingDocAccessFields_createdAt_Update",
	permission: boolean
};
	["PostSettingDocAccessFields_createdAt_Delete"]: {
	__typename: "PostSettingDocAccessFields_createdAt_Delete",
	permission: boolean
};
	["PostSettingCreateDocAccess"]: {
	__typename: "PostSettingCreateDocAccess",
	permission: boolean,
	where?: GraphQLTypes["JSONObject"] | undefined
};
	["PostSettingReadDocAccess"]: {
	__typename: "PostSettingReadDocAccess",
	permission: boolean,
	where?: GraphQLTypes["JSONObject"] | undefined
};
	["PostSettingUpdateDocAccess"]: {
	__typename: "PostSettingUpdateDocAccess",
	permission: boolean,
	where?: GraphQLTypes["JSONObject"] | undefined
};
	["PostSettingDeleteDocAccess"]: {
	__typename: "PostSettingDeleteDocAccess",
	permission: boolean,
	where?: GraphQLTypes["JSONObject"] | undefined
};
	["HomepageSetting"]: {
	__typename: "HomepageSetting",
	id?: string | undefined,
	name?: string | undefined,
	region?: GraphQLTypes["HomepageSetting_region"] | undefined,
	sections?: Array<GraphQLTypes["HomepageSetting_Sections"]> | undefined,
	updatedAt?: GraphQLTypes["DateTime"] | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined
};
	["HomepageSetting_region"]: HomepageSetting_region;
	["HomepageSetting_Sections"]:{
        	__typename:"ComingWeekEventAndLocationRankingBlock" | "CategoryListBlock" | "UserPreferenceBlock" | "EventRankingBlock" | "EventTrendBlock" | "LocationRankingBlock" | "OrganizarBlock" | "SubscriptionBlock" | "TimableRankingBlock" | "MultipleEventRankingBlock" | "TicketingBlock" | "BlogBlock" | "EditorChoiceBlock"
        	['...on ComingWeekEventAndLocationRankingBlock']: '__union' & GraphQLTypes["ComingWeekEventAndLocationRankingBlock"];
	['...on CategoryListBlock']: '__union' & GraphQLTypes["CategoryListBlock"];
	['...on UserPreferenceBlock']: '__union' & GraphQLTypes["UserPreferenceBlock"];
	['...on EventRankingBlock']: '__union' & GraphQLTypes["EventRankingBlock"];
	['...on EventTrendBlock']: '__union' & GraphQLTypes["EventTrendBlock"];
	['...on LocationRankingBlock']: '__union' & GraphQLTypes["LocationRankingBlock"];
	['...on OrganizarBlock']: '__union' & GraphQLTypes["OrganizarBlock"];
	['...on SubscriptionBlock']: '__union' & GraphQLTypes["SubscriptionBlock"];
	['...on TimableRankingBlock']: '__union' & GraphQLTypes["TimableRankingBlock"];
	['...on MultipleEventRankingBlock']: '__union' & GraphQLTypes["MultipleEventRankingBlock"];
	['...on TicketingBlock']: '__union' & GraphQLTypes["TicketingBlock"];
	['...on BlogBlock']: '__union' & GraphQLTypes["BlogBlock"];
	['...on EditorChoiceBlock']: '__union' & GraphQLTypes["EditorChoiceBlock"];
};
	["ComingWeekEventAndLocationRankingBlock"]: {
	__typename: "ComingWeekEventAndLocationRankingBlock",
	title?: string | undefined,
	locationRankingTitle?: string | undefined,
	appearance: GraphQLTypes["ComingWeekEventAndLocationRankingBlock_appearance"],
	byRegion?: GraphQLTypes["ComingWeekEventAndLocationRankingBlock_byRegion"] | undefined,
	rankings?: Array<GraphQLTypes["LocationRanking"]> | undefined,
	id?: string | undefined,
	blockName?: string | undefined,
	blockType?: string | undefined
};
	["ComingWeekEventAndLocationRankingBlock_appearance"]: ComingWeekEventAndLocationRankingBlock_appearance;
	["ComingWeekEventAndLocationRankingBlock_byRegion"]: ComingWeekEventAndLocationRankingBlock_byRegion;
	["CategoryListBlock"]: {
	__typename: "CategoryListBlock",
	title?: string | undefined,
	id?: string | undefined,
	blockName?: string | undefined,
	blockType?: string | undefined
};
	["UserPreferenceBlock"]: {
	__typename: "UserPreferenceBlock",
	title?: string | undefined,
	id?: string | undefined,
	blockName?: string | undefined,
	blockType?: string | undefined
};
	["EventRankingBlock"]: {
	__typename: "EventRankingBlock",
	title?: string | undefined,
	appearance: GraphQLTypes["EventRankingBlock_appearance"],
	ranking: GraphQLTypes["EventRanking"],
	id?: string | undefined,
	blockName?: string | undefined,
	blockType?: string | undefined
};
	["EventRankingBlock_appearance"]: EventRankingBlock_appearance;
	["EventTrendBlock"]: {
	__typename: "EventTrendBlock",
	title?: string | undefined,
	id?: string | undefined,
	blockName?: string | undefined,
	blockType?: string | undefined
};
	["LocationRankingBlock"]: {
	__typename: "LocationRankingBlock",
	title?: string | undefined,
	appearance: GraphQLTypes["LocationRankingBlock_appearance"],
	ranking: GraphQLTypes["LocationRanking"],
	id?: string | undefined,
	blockName?: string | undefined,
	blockType?: string | undefined
};
	["LocationRankingBlock_appearance"]: LocationRankingBlock_appearance;
	["OrganizarBlock"]: {
	__typename: "OrganizarBlock",
	title?: string | undefined,
	id?: string | undefined,
	blockName?: string | undefined,
	blockType?: string | undefined
};
	["SubscriptionBlock"]: {
	__typename: "SubscriptionBlock",
	title?: string | undefined,
	id?: string | undefined,
	blockName?: string | undefined,
	blockType?: string | undefined
};
	["TimableRankingBlock"]: {
	__typename: "TimableRankingBlock",
	title?: string | undefined,
	ranking?: Array<GraphQLTypes["LocationRanking"]> | undefined,
	id?: string | undefined,
	blockName?: string | undefined,
	blockType?: string | undefined
};
	["MultipleEventRankingBlock"]: {
	__typename: "MultipleEventRankingBlock",
	title?: string | undefined,
	ranking?: Array<GraphQLTypes["EventRanking"]> | undefined,
	id?: string | undefined,
	blockName?: string | undefined,
	blockType?: string | undefined
};
	["TicketingBlock"]: {
	__typename: "TicketingBlock",
	title?: string | undefined,
	id?: string | undefined,
	blockName?: string | undefined,
	blockType?: string | undefined
};
	["BlogBlock"]: {
	__typename: "BlogBlock",
	title?: string | undefined,
	id?: string | undefined,
	blockName?: string | undefined,
	blockType?: string | undefined
};
	["EditorChoiceBlock"]: {
	__typename: "EditorChoiceBlock",
	title?: string | undefined,
	id?: string | undefined,
	blockName?: string | undefined,
	blockType?: string | undefined
};
	["HomepageSettings"]: {
	__typename: "HomepageSettings",
	docs?: Array<GraphQLTypes["HomepageSetting"] | undefined> | undefined,
	hasNextPage?: boolean | undefined,
	hasPrevPage?: boolean | undefined,
	limit?: number | undefined,
	nextPage?: number | undefined,
	offset?: number | undefined,
	page?: number | undefined,
	pagingCounter?: number | undefined,
	prevPage?: number | undefined,
	totalDocs?: number | undefined,
	totalPages?: number | undefined
};
	["HomepageSetting_where"]: {
		name?: GraphQLTypes["HomepageSetting_name_operator"] | undefined,
	region?: GraphQLTypes["HomepageSetting_region_operator"] | undefined,
	updatedAt?: GraphQLTypes["HomepageSetting_updatedAt_operator"] | undefined,
	createdAt?: GraphQLTypes["HomepageSetting_createdAt_operator"] | undefined,
	id?: GraphQLTypes["HomepageSetting_id_operator"] | undefined,
	AND?: Array<GraphQLTypes["HomepageSetting_where_and"] | undefined> | undefined,
	OR?: Array<GraphQLTypes["HomepageSetting_where_or"] | undefined> | undefined
};
	["HomepageSetting_name_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined
};
	["HomepageSetting_region_operator"]: {
		equals?: GraphQLTypes["HomepageSetting_region_Input"] | undefined,
	not_equals?: GraphQLTypes["HomepageSetting_region_Input"] | undefined,
	in?: Array<GraphQLTypes["HomepageSetting_region_Input"] | undefined> | undefined,
	not_in?: Array<GraphQLTypes["HomepageSetting_region_Input"] | undefined> | undefined,
	all?: Array<GraphQLTypes["HomepageSetting_region_Input"] | undefined> | undefined,
	exists?: boolean | undefined
};
	["HomepageSetting_region_Input"]: HomepageSetting_region_Input;
	["HomepageSetting_updatedAt_operator"]: {
		equals?: GraphQLTypes["DateTime"] | undefined,
	not_equals?: GraphQLTypes["DateTime"] | undefined,
	greater_than_equal?: GraphQLTypes["DateTime"] | undefined,
	greater_than?: GraphQLTypes["DateTime"] | undefined,
	less_than_equal?: GraphQLTypes["DateTime"] | undefined,
	less_than?: GraphQLTypes["DateTime"] | undefined,
	like?: GraphQLTypes["DateTime"] | undefined,
	exists?: boolean | undefined
};
	["HomepageSetting_createdAt_operator"]: {
		equals?: GraphQLTypes["DateTime"] | undefined,
	not_equals?: GraphQLTypes["DateTime"] | undefined,
	greater_than_equal?: GraphQLTypes["DateTime"] | undefined,
	greater_than?: GraphQLTypes["DateTime"] | undefined,
	less_than_equal?: GraphQLTypes["DateTime"] | undefined,
	less_than?: GraphQLTypes["DateTime"] | undefined,
	like?: GraphQLTypes["DateTime"] | undefined,
	exists?: boolean | undefined
};
	["HomepageSetting_id_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["HomepageSetting_where_and"]: {
		name?: GraphQLTypes["HomepageSetting_name_operator"] | undefined,
	region?: GraphQLTypes["HomepageSetting_region_operator"] | undefined,
	updatedAt?: GraphQLTypes["HomepageSetting_updatedAt_operator"] | undefined,
	createdAt?: GraphQLTypes["HomepageSetting_createdAt_operator"] | undefined,
	id?: GraphQLTypes["HomepageSetting_id_operator"] | undefined,
	AND?: Array<GraphQLTypes["HomepageSetting_where_and"] | undefined> | undefined,
	OR?: Array<GraphQLTypes["HomepageSetting_where_or"] | undefined> | undefined
};
	["HomepageSetting_where_or"]: {
		name?: GraphQLTypes["HomepageSetting_name_operator"] | undefined,
	region?: GraphQLTypes["HomepageSetting_region_operator"] | undefined,
	updatedAt?: GraphQLTypes["HomepageSetting_updatedAt_operator"] | undefined,
	createdAt?: GraphQLTypes["HomepageSetting_createdAt_operator"] | undefined,
	id?: GraphQLTypes["HomepageSetting_id_operator"] | undefined,
	AND?: Array<GraphQLTypes["HomepageSetting_where_and"] | undefined> | undefined,
	OR?: Array<GraphQLTypes["HomepageSetting_where_or"] | undefined> | undefined
};
	["countHomepageSettings"]: {
	__typename: "countHomepageSettings",
	totalDocs?: number | undefined
};
	["homepageSettingDocAccess"]: {
	__typename: "homepageSettingDocAccess",
	fields?: GraphQLTypes["HomepageSettingDocAccessFields"] | undefined,
	create?: GraphQLTypes["HomepageSettingCreateDocAccess"] | undefined,
	read?: GraphQLTypes["HomepageSettingReadDocAccess"] | undefined,
	update?: GraphQLTypes["HomepageSettingUpdateDocAccess"] | undefined,
	delete?: GraphQLTypes["HomepageSettingDeleteDocAccess"] | undefined
};
	["HomepageSettingDocAccessFields"]: {
	__typename: "HomepageSettingDocAccessFields",
	name?: GraphQLTypes["HomepageSettingDocAccessFields_name"] | undefined,
	region?: GraphQLTypes["HomepageSettingDocAccessFields_region"] | undefined,
	sections?: GraphQLTypes["HomepageSettingDocAccessFields_sections"] | undefined,
	updatedAt?: GraphQLTypes["HomepageSettingDocAccessFields_updatedAt"] | undefined,
	createdAt?: GraphQLTypes["HomepageSettingDocAccessFields_createdAt"] | undefined
};
	["HomepageSettingDocAccessFields_name"]: {
	__typename: "HomepageSettingDocAccessFields_name",
	create?: GraphQLTypes["HomepageSettingDocAccessFields_name_Create"] | undefined,
	read?: GraphQLTypes["HomepageSettingDocAccessFields_name_Read"] | undefined,
	update?: GraphQLTypes["HomepageSettingDocAccessFields_name_Update"] | undefined,
	delete?: GraphQLTypes["HomepageSettingDocAccessFields_name_Delete"] | undefined
};
	["HomepageSettingDocAccessFields_name_Create"]: {
	__typename: "HomepageSettingDocAccessFields_name_Create",
	permission: boolean
};
	["HomepageSettingDocAccessFields_name_Read"]: {
	__typename: "HomepageSettingDocAccessFields_name_Read",
	permission: boolean
};
	["HomepageSettingDocAccessFields_name_Update"]: {
	__typename: "HomepageSettingDocAccessFields_name_Update",
	permission: boolean
};
	["HomepageSettingDocAccessFields_name_Delete"]: {
	__typename: "HomepageSettingDocAccessFields_name_Delete",
	permission: boolean
};
	["HomepageSettingDocAccessFields_region"]: {
	__typename: "HomepageSettingDocAccessFields_region",
	create?: GraphQLTypes["HomepageSettingDocAccessFields_region_Create"] | undefined,
	read?: GraphQLTypes["HomepageSettingDocAccessFields_region_Read"] | undefined,
	update?: GraphQLTypes["HomepageSettingDocAccessFields_region_Update"] | undefined,
	delete?: GraphQLTypes["HomepageSettingDocAccessFields_region_Delete"] | undefined
};
	["HomepageSettingDocAccessFields_region_Create"]: {
	__typename: "HomepageSettingDocAccessFields_region_Create",
	permission: boolean
};
	["HomepageSettingDocAccessFields_region_Read"]: {
	__typename: "HomepageSettingDocAccessFields_region_Read",
	permission: boolean
};
	["HomepageSettingDocAccessFields_region_Update"]: {
	__typename: "HomepageSettingDocAccessFields_region_Update",
	permission: boolean
};
	["HomepageSettingDocAccessFields_region_Delete"]: {
	__typename: "HomepageSettingDocAccessFields_region_Delete",
	permission: boolean
};
	["HomepageSettingDocAccessFields_sections"]: {
	__typename: "HomepageSettingDocAccessFields_sections",
	create?: GraphQLTypes["HomepageSettingDocAccessFields_sections_Create"] | undefined,
	read?: GraphQLTypes["HomepageSettingDocAccessFields_sections_Read"] | undefined,
	update?: GraphQLTypes["HomepageSettingDocAccessFields_sections_Update"] | undefined,
	delete?: GraphQLTypes["HomepageSettingDocAccessFields_sections_Delete"] | undefined
};
	["HomepageSettingDocAccessFields_sections_Create"]: {
	__typename: "HomepageSettingDocAccessFields_sections_Create",
	permission: boolean
};
	["HomepageSettingDocAccessFields_sections_Read"]: {
	__typename: "HomepageSettingDocAccessFields_sections_Read",
	permission: boolean
};
	["HomepageSettingDocAccessFields_sections_Update"]: {
	__typename: "HomepageSettingDocAccessFields_sections_Update",
	permission: boolean
};
	["HomepageSettingDocAccessFields_sections_Delete"]: {
	__typename: "HomepageSettingDocAccessFields_sections_Delete",
	permission: boolean
};
	["HomepageSettingDocAccessFields_updatedAt"]: {
	__typename: "HomepageSettingDocAccessFields_updatedAt",
	create?: GraphQLTypes["HomepageSettingDocAccessFields_updatedAt_Create"] | undefined,
	read?: GraphQLTypes["HomepageSettingDocAccessFields_updatedAt_Read"] | undefined,
	update?: GraphQLTypes["HomepageSettingDocAccessFields_updatedAt_Update"] | undefined,
	delete?: GraphQLTypes["HomepageSettingDocAccessFields_updatedAt_Delete"] | undefined
};
	["HomepageSettingDocAccessFields_updatedAt_Create"]: {
	__typename: "HomepageSettingDocAccessFields_updatedAt_Create",
	permission: boolean
};
	["HomepageSettingDocAccessFields_updatedAt_Read"]: {
	__typename: "HomepageSettingDocAccessFields_updatedAt_Read",
	permission: boolean
};
	["HomepageSettingDocAccessFields_updatedAt_Update"]: {
	__typename: "HomepageSettingDocAccessFields_updatedAt_Update",
	permission: boolean
};
	["HomepageSettingDocAccessFields_updatedAt_Delete"]: {
	__typename: "HomepageSettingDocAccessFields_updatedAt_Delete",
	permission: boolean
};
	["HomepageSettingDocAccessFields_createdAt"]: {
	__typename: "HomepageSettingDocAccessFields_createdAt",
	create?: GraphQLTypes["HomepageSettingDocAccessFields_createdAt_Create"] | undefined,
	read?: GraphQLTypes["HomepageSettingDocAccessFields_createdAt_Read"] | undefined,
	update?: GraphQLTypes["HomepageSettingDocAccessFields_createdAt_Update"] | undefined,
	delete?: GraphQLTypes["HomepageSettingDocAccessFields_createdAt_Delete"] | undefined
};
	["HomepageSettingDocAccessFields_createdAt_Create"]: {
	__typename: "HomepageSettingDocAccessFields_createdAt_Create",
	permission: boolean
};
	["HomepageSettingDocAccessFields_createdAt_Read"]: {
	__typename: "HomepageSettingDocAccessFields_createdAt_Read",
	permission: boolean
};
	["HomepageSettingDocAccessFields_createdAt_Update"]: {
	__typename: "HomepageSettingDocAccessFields_createdAt_Update",
	permission: boolean
};
	["HomepageSettingDocAccessFields_createdAt_Delete"]: {
	__typename: "HomepageSettingDocAccessFields_createdAt_Delete",
	permission: boolean
};
	["HomepageSettingCreateDocAccess"]: {
	__typename: "HomepageSettingCreateDocAccess",
	permission: boolean,
	where?: GraphQLTypes["JSONObject"] | undefined
};
	["HomepageSettingReadDocAccess"]: {
	__typename: "HomepageSettingReadDocAccess",
	permission: boolean,
	where?: GraphQLTypes["JSONObject"] | undefined
};
	["HomepageSettingUpdateDocAccess"]: {
	__typename: "HomepageSettingUpdateDocAccess",
	permission: boolean,
	where?: GraphQLTypes["JSONObject"] | undefined
};
	["HomepageSettingDeleteDocAccess"]: {
	__typename: "HomepageSettingDeleteDocAccess",
	permission: boolean,
	where?: GraphQLTypes["JSONObject"] | undefined
};
	["BannerSection"]: {
	__typename: "BannerSection",
	id?: string | undefined,
	name?: string | undefined,
	slug?: string | undefined,
	banners?: Array<GraphQLTypes["BannerSection_Banners"]> | undefined,
	updatedAt?: GraphQLTypes["DateTime"] | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined
};
	["BannerSection_Banners"]: {
	__typename: "BannerSection_Banners",
	name?: string | undefined,
	status?: string | undefined,
	desktop?: GraphQLTypes["Media"] | undefined,
	bannerStartDate?: GraphQLTypes["DateTime"] | undefined,
	bannerEndDate?: GraphQLTypes["DateTime"] | undefined,
	region?: GraphQLTypes["BannerSection_Banners_region"] | undefined,
	id?: string | undefined
};
	["BannerSection_Banners_Desktop_where"]: {
		alt?: GraphQLTypes["BannerSection_Banners_Desktop_alt_operator"] | undefined,
	updatedAt?: GraphQLTypes["BannerSection_Banners_Desktop_updatedAt_operator"] | undefined,
	createdAt?: GraphQLTypes["BannerSection_Banners_Desktop_createdAt_operator"] | undefined,
	url?: GraphQLTypes["BannerSection_Banners_Desktop_url_operator"] | undefined,
	filename?: GraphQLTypes["BannerSection_Banners_Desktop_filename_operator"] | undefined,
	mimeType?: GraphQLTypes["BannerSection_Banners_Desktop_mimeType_operator"] | undefined,
	filesize?: GraphQLTypes["BannerSection_Banners_Desktop_filesize_operator"] | undefined,
	width?: GraphQLTypes["BannerSection_Banners_Desktop_width_operator"] | undefined,
	height?: GraphQLTypes["BannerSection_Banners_Desktop_height_operator"] | undefined,
	focalX?: GraphQLTypes["BannerSection_Banners_Desktop_focalX_operator"] | undefined,
	focalY?: GraphQLTypes["BannerSection_Banners_Desktop_focalY_operator"] | undefined,
	sizes__small__url?: GraphQLTypes["BannerSection_Banners_Desktop_sizes__small__url_operator"] | undefined,
	sizes__small__width?: GraphQLTypes["BannerSection_Banners_Desktop_sizes__small__width_operator"] | undefined,
	sizes__small__height?: GraphQLTypes["BannerSection_Banners_Desktop_sizes__small__height_operator"] | undefined,
	sizes__small__mimeType?: GraphQLTypes["BannerSection_Banners_Desktop_sizes__small__mimeType_operator"] | undefined,
	sizes__small__filesize?: GraphQLTypes["BannerSection_Banners_Desktop_sizes__small__filesize_operator"] | undefined,
	sizes__small__filename?: GraphQLTypes["BannerSection_Banners_Desktop_sizes__small__filename_operator"] | undefined,
	sizes__medium__url?: GraphQLTypes["BannerSection_Banners_Desktop_sizes__medium__url_operator"] | undefined,
	sizes__medium__width?: GraphQLTypes["BannerSection_Banners_Desktop_sizes__medium__width_operator"] | undefined,
	sizes__medium__height?: GraphQLTypes["BannerSection_Banners_Desktop_sizes__medium__height_operator"] | undefined,
	sizes__medium__mimeType?: GraphQLTypes["BannerSection_Banners_Desktop_sizes__medium__mimeType_operator"] | undefined,
	sizes__medium__filesize?: GraphQLTypes["BannerSection_Banners_Desktop_sizes__medium__filesize_operator"] | undefined,
	sizes__medium__filename?: GraphQLTypes["BannerSection_Banners_Desktop_sizes__medium__filename_operator"] | undefined,
	sizes__large__url?: GraphQLTypes["BannerSection_Banners_Desktop_sizes__large__url_operator"] | undefined,
	sizes__large__width?: GraphQLTypes["BannerSection_Banners_Desktop_sizes__large__width_operator"] | undefined,
	sizes__large__height?: GraphQLTypes["BannerSection_Banners_Desktop_sizes__large__height_operator"] | undefined,
	sizes__large__mimeType?: GraphQLTypes["BannerSection_Banners_Desktop_sizes__large__mimeType_operator"] | undefined,
	sizes__large__filesize?: GraphQLTypes["BannerSection_Banners_Desktop_sizes__large__filesize_operator"] | undefined,
	sizes__large__filename?: GraphQLTypes["BannerSection_Banners_Desktop_sizes__large__filename_operator"] | undefined,
	sizes__small_jpeg__url?: GraphQLTypes["BannerSection_Banners_Desktop_sizes__small_jpeg__url_operator"] | undefined,
	sizes__small_jpeg__width?: GraphQLTypes["BannerSection_Banners_Desktop_sizes__small_jpeg__width_operator"] | undefined,
	sizes__small_jpeg__height?: GraphQLTypes["BannerSection_Banners_Desktop_sizes__small_jpeg__height_operator"] | undefined,
	sizes__small_jpeg__mimeType?: GraphQLTypes["BannerSection_Banners_Desktop_sizes__small_jpeg__mimeType_operator"] | undefined,
	sizes__small_jpeg__filesize?: GraphQLTypes["BannerSection_Banners_Desktop_sizes__small_jpeg__filesize_operator"] | undefined,
	sizes__small_jpeg__filename?: GraphQLTypes["BannerSection_Banners_Desktop_sizes__small_jpeg__filename_operator"] | undefined,
	sizes__medium_jpeg__url?: GraphQLTypes["BannerSection_Banners_Desktop_sizes__medium_jpeg__url_operator"] | undefined,
	sizes__medium_jpeg__width?: GraphQLTypes["BannerSection_Banners_Desktop_sizes__medium_jpeg__width_operator"] | undefined,
	sizes__medium_jpeg__height?: GraphQLTypes["BannerSection_Banners_Desktop_sizes__medium_jpeg__height_operator"] | undefined,
	sizes__medium_jpeg__mimeType?: GraphQLTypes["BannerSection_Banners_Desktop_sizes__medium_jpeg__mimeType_operator"] | undefined,
	sizes__medium_jpeg__filesize?: GraphQLTypes["BannerSection_Banners_Desktop_sizes__medium_jpeg__filesize_operator"] | undefined,
	sizes__medium_jpeg__filename?: GraphQLTypes["BannerSection_Banners_Desktop_sizes__medium_jpeg__filename_operator"] | undefined,
	sizes__large_jpeg__url?: GraphQLTypes["BannerSection_Banners_Desktop_sizes__large_jpeg__url_operator"] | undefined,
	sizes__large_jpeg__width?: GraphQLTypes["BannerSection_Banners_Desktop_sizes__large_jpeg__width_operator"] | undefined,
	sizes__large_jpeg__height?: GraphQLTypes["BannerSection_Banners_Desktop_sizes__large_jpeg__height_operator"] | undefined,
	sizes__large_jpeg__mimeType?: GraphQLTypes["BannerSection_Banners_Desktop_sizes__large_jpeg__mimeType_operator"] | undefined,
	sizes__large_jpeg__filesize?: GraphQLTypes["BannerSection_Banners_Desktop_sizes__large_jpeg__filesize_operator"] | undefined,
	sizes__large_jpeg__filename?: GraphQLTypes["BannerSection_Banners_Desktop_sizes__large_jpeg__filename_operator"] | undefined,
	id?: GraphQLTypes["BannerSection_Banners_Desktop_id_operator"] | undefined,
	AND?: Array<GraphQLTypes["BannerSection_Banners_Desktop_where_and"] | undefined> | undefined,
	OR?: Array<GraphQLTypes["BannerSection_Banners_Desktop_where_or"] | undefined> | undefined
};
	["BannerSection_Banners_Desktop_alt_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["BannerSection_Banners_Desktop_updatedAt_operator"]: {
		equals?: GraphQLTypes["DateTime"] | undefined,
	not_equals?: GraphQLTypes["DateTime"] | undefined,
	greater_than_equal?: GraphQLTypes["DateTime"] | undefined,
	greater_than?: GraphQLTypes["DateTime"] | undefined,
	less_than_equal?: GraphQLTypes["DateTime"] | undefined,
	less_than?: GraphQLTypes["DateTime"] | undefined,
	like?: GraphQLTypes["DateTime"] | undefined,
	exists?: boolean | undefined
};
	["BannerSection_Banners_Desktop_createdAt_operator"]: {
		equals?: GraphQLTypes["DateTime"] | undefined,
	not_equals?: GraphQLTypes["DateTime"] | undefined,
	greater_than_equal?: GraphQLTypes["DateTime"] | undefined,
	greater_than?: GraphQLTypes["DateTime"] | undefined,
	less_than_equal?: GraphQLTypes["DateTime"] | undefined,
	less_than?: GraphQLTypes["DateTime"] | undefined,
	like?: GraphQLTypes["DateTime"] | undefined,
	exists?: boolean | undefined
};
	["BannerSection_Banners_Desktop_url_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["BannerSection_Banners_Desktop_filename_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["BannerSection_Banners_Desktop_mimeType_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["BannerSection_Banners_Desktop_filesize_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["BannerSection_Banners_Desktop_width_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["BannerSection_Banners_Desktop_height_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["BannerSection_Banners_Desktop_focalX_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["BannerSection_Banners_Desktop_focalY_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["BannerSection_Banners_Desktop_sizes__small__url_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["BannerSection_Banners_Desktop_sizes__small__width_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["BannerSection_Banners_Desktop_sizes__small__height_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["BannerSection_Banners_Desktop_sizes__small__mimeType_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["BannerSection_Banners_Desktop_sizes__small__filesize_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["BannerSection_Banners_Desktop_sizes__small__filename_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["BannerSection_Banners_Desktop_sizes__medium__url_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["BannerSection_Banners_Desktop_sizes__medium__width_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["BannerSection_Banners_Desktop_sizes__medium__height_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["BannerSection_Banners_Desktop_sizes__medium__mimeType_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["BannerSection_Banners_Desktop_sizes__medium__filesize_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["BannerSection_Banners_Desktop_sizes__medium__filename_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["BannerSection_Banners_Desktop_sizes__large__url_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["BannerSection_Banners_Desktop_sizes__large__width_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["BannerSection_Banners_Desktop_sizes__large__height_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["BannerSection_Banners_Desktop_sizes__large__mimeType_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["BannerSection_Banners_Desktop_sizes__large__filesize_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["BannerSection_Banners_Desktop_sizes__large__filename_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["BannerSection_Banners_Desktop_sizes__small_jpeg__url_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["BannerSection_Banners_Desktop_sizes__small_jpeg__width_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["BannerSection_Banners_Desktop_sizes__small_jpeg__height_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["BannerSection_Banners_Desktop_sizes__small_jpeg__mimeType_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["BannerSection_Banners_Desktop_sizes__small_jpeg__filesize_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["BannerSection_Banners_Desktop_sizes__small_jpeg__filename_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["BannerSection_Banners_Desktop_sizes__medium_jpeg__url_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["BannerSection_Banners_Desktop_sizes__medium_jpeg__width_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["BannerSection_Banners_Desktop_sizes__medium_jpeg__height_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["BannerSection_Banners_Desktop_sizes__medium_jpeg__mimeType_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["BannerSection_Banners_Desktop_sizes__medium_jpeg__filesize_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["BannerSection_Banners_Desktop_sizes__medium_jpeg__filename_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["BannerSection_Banners_Desktop_sizes__large_jpeg__url_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["BannerSection_Banners_Desktop_sizes__large_jpeg__width_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["BannerSection_Banners_Desktop_sizes__large_jpeg__height_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["BannerSection_Banners_Desktop_sizes__large_jpeg__mimeType_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["BannerSection_Banners_Desktop_sizes__large_jpeg__filesize_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["BannerSection_Banners_Desktop_sizes__large_jpeg__filename_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["BannerSection_Banners_Desktop_id_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["BannerSection_Banners_Desktop_where_and"]: {
		alt?: GraphQLTypes["BannerSection_Banners_Desktop_alt_operator"] | undefined,
	updatedAt?: GraphQLTypes["BannerSection_Banners_Desktop_updatedAt_operator"] | undefined,
	createdAt?: GraphQLTypes["BannerSection_Banners_Desktop_createdAt_operator"] | undefined,
	url?: GraphQLTypes["BannerSection_Banners_Desktop_url_operator"] | undefined,
	filename?: GraphQLTypes["BannerSection_Banners_Desktop_filename_operator"] | undefined,
	mimeType?: GraphQLTypes["BannerSection_Banners_Desktop_mimeType_operator"] | undefined,
	filesize?: GraphQLTypes["BannerSection_Banners_Desktop_filesize_operator"] | undefined,
	width?: GraphQLTypes["BannerSection_Banners_Desktop_width_operator"] | undefined,
	height?: GraphQLTypes["BannerSection_Banners_Desktop_height_operator"] | undefined,
	focalX?: GraphQLTypes["BannerSection_Banners_Desktop_focalX_operator"] | undefined,
	focalY?: GraphQLTypes["BannerSection_Banners_Desktop_focalY_operator"] | undefined,
	sizes__small__url?: GraphQLTypes["BannerSection_Banners_Desktop_sizes__small__url_operator"] | undefined,
	sizes__small__width?: GraphQLTypes["BannerSection_Banners_Desktop_sizes__small__width_operator"] | undefined,
	sizes__small__height?: GraphQLTypes["BannerSection_Banners_Desktop_sizes__small__height_operator"] | undefined,
	sizes__small__mimeType?: GraphQLTypes["BannerSection_Banners_Desktop_sizes__small__mimeType_operator"] | undefined,
	sizes__small__filesize?: GraphQLTypes["BannerSection_Banners_Desktop_sizes__small__filesize_operator"] | undefined,
	sizes__small__filename?: GraphQLTypes["BannerSection_Banners_Desktop_sizes__small__filename_operator"] | undefined,
	sizes__medium__url?: GraphQLTypes["BannerSection_Banners_Desktop_sizes__medium__url_operator"] | undefined,
	sizes__medium__width?: GraphQLTypes["BannerSection_Banners_Desktop_sizes__medium__width_operator"] | undefined,
	sizes__medium__height?: GraphQLTypes["BannerSection_Banners_Desktop_sizes__medium__height_operator"] | undefined,
	sizes__medium__mimeType?: GraphQLTypes["BannerSection_Banners_Desktop_sizes__medium__mimeType_operator"] | undefined,
	sizes__medium__filesize?: GraphQLTypes["BannerSection_Banners_Desktop_sizes__medium__filesize_operator"] | undefined,
	sizes__medium__filename?: GraphQLTypes["BannerSection_Banners_Desktop_sizes__medium__filename_operator"] | undefined,
	sizes__large__url?: GraphQLTypes["BannerSection_Banners_Desktop_sizes__large__url_operator"] | undefined,
	sizes__large__width?: GraphQLTypes["BannerSection_Banners_Desktop_sizes__large__width_operator"] | undefined,
	sizes__large__height?: GraphQLTypes["BannerSection_Banners_Desktop_sizes__large__height_operator"] | undefined,
	sizes__large__mimeType?: GraphQLTypes["BannerSection_Banners_Desktop_sizes__large__mimeType_operator"] | undefined,
	sizes__large__filesize?: GraphQLTypes["BannerSection_Banners_Desktop_sizes__large__filesize_operator"] | undefined,
	sizes__large__filename?: GraphQLTypes["BannerSection_Banners_Desktop_sizes__large__filename_operator"] | undefined,
	sizes__small_jpeg__url?: GraphQLTypes["BannerSection_Banners_Desktop_sizes__small_jpeg__url_operator"] | undefined,
	sizes__small_jpeg__width?: GraphQLTypes["BannerSection_Banners_Desktop_sizes__small_jpeg__width_operator"] | undefined,
	sizes__small_jpeg__height?: GraphQLTypes["BannerSection_Banners_Desktop_sizes__small_jpeg__height_operator"] | undefined,
	sizes__small_jpeg__mimeType?: GraphQLTypes["BannerSection_Banners_Desktop_sizes__small_jpeg__mimeType_operator"] | undefined,
	sizes__small_jpeg__filesize?: GraphQLTypes["BannerSection_Banners_Desktop_sizes__small_jpeg__filesize_operator"] | undefined,
	sizes__small_jpeg__filename?: GraphQLTypes["BannerSection_Banners_Desktop_sizes__small_jpeg__filename_operator"] | undefined,
	sizes__medium_jpeg__url?: GraphQLTypes["BannerSection_Banners_Desktop_sizes__medium_jpeg__url_operator"] | undefined,
	sizes__medium_jpeg__width?: GraphQLTypes["BannerSection_Banners_Desktop_sizes__medium_jpeg__width_operator"] | undefined,
	sizes__medium_jpeg__height?: GraphQLTypes["BannerSection_Banners_Desktop_sizes__medium_jpeg__height_operator"] | undefined,
	sizes__medium_jpeg__mimeType?: GraphQLTypes["BannerSection_Banners_Desktop_sizes__medium_jpeg__mimeType_operator"] | undefined,
	sizes__medium_jpeg__filesize?: GraphQLTypes["BannerSection_Banners_Desktop_sizes__medium_jpeg__filesize_operator"] | undefined,
	sizes__medium_jpeg__filename?: GraphQLTypes["BannerSection_Banners_Desktop_sizes__medium_jpeg__filename_operator"] | undefined,
	sizes__large_jpeg__url?: GraphQLTypes["BannerSection_Banners_Desktop_sizes__large_jpeg__url_operator"] | undefined,
	sizes__large_jpeg__width?: GraphQLTypes["BannerSection_Banners_Desktop_sizes__large_jpeg__width_operator"] | undefined,
	sizes__large_jpeg__height?: GraphQLTypes["BannerSection_Banners_Desktop_sizes__large_jpeg__height_operator"] | undefined,
	sizes__large_jpeg__mimeType?: GraphQLTypes["BannerSection_Banners_Desktop_sizes__large_jpeg__mimeType_operator"] | undefined,
	sizes__large_jpeg__filesize?: GraphQLTypes["BannerSection_Banners_Desktop_sizes__large_jpeg__filesize_operator"] | undefined,
	sizes__large_jpeg__filename?: GraphQLTypes["BannerSection_Banners_Desktop_sizes__large_jpeg__filename_operator"] | undefined,
	id?: GraphQLTypes["BannerSection_Banners_Desktop_id_operator"] | undefined,
	AND?: Array<GraphQLTypes["BannerSection_Banners_Desktop_where_and"] | undefined> | undefined,
	OR?: Array<GraphQLTypes["BannerSection_Banners_Desktop_where_or"] | undefined> | undefined
};
	["BannerSection_Banners_Desktop_where_or"]: {
		alt?: GraphQLTypes["BannerSection_Banners_Desktop_alt_operator"] | undefined,
	updatedAt?: GraphQLTypes["BannerSection_Banners_Desktop_updatedAt_operator"] | undefined,
	createdAt?: GraphQLTypes["BannerSection_Banners_Desktop_createdAt_operator"] | undefined,
	url?: GraphQLTypes["BannerSection_Banners_Desktop_url_operator"] | undefined,
	filename?: GraphQLTypes["BannerSection_Banners_Desktop_filename_operator"] | undefined,
	mimeType?: GraphQLTypes["BannerSection_Banners_Desktop_mimeType_operator"] | undefined,
	filesize?: GraphQLTypes["BannerSection_Banners_Desktop_filesize_operator"] | undefined,
	width?: GraphQLTypes["BannerSection_Banners_Desktop_width_operator"] | undefined,
	height?: GraphQLTypes["BannerSection_Banners_Desktop_height_operator"] | undefined,
	focalX?: GraphQLTypes["BannerSection_Banners_Desktop_focalX_operator"] | undefined,
	focalY?: GraphQLTypes["BannerSection_Banners_Desktop_focalY_operator"] | undefined,
	sizes__small__url?: GraphQLTypes["BannerSection_Banners_Desktop_sizes__small__url_operator"] | undefined,
	sizes__small__width?: GraphQLTypes["BannerSection_Banners_Desktop_sizes__small__width_operator"] | undefined,
	sizes__small__height?: GraphQLTypes["BannerSection_Banners_Desktop_sizes__small__height_operator"] | undefined,
	sizes__small__mimeType?: GraphQLTypes["BannerSection_Banners_Desktop_sizes__small__mimeType_operator"] | undefined,
	sizes__small__filesize?: GraphQLTypes["BannerSection_Banners_Desktop_sizes__small__filesize_operator"] | undefined,
	sizes__small__filename?: GraphQLTypes["BannerSection_Banners_Desktop_sizes__small__filename_operator"] | undefined,
	sizes__medium__url?: GraphQLTypes["BannerSection_Banners_Desktop_sizes__medium__url_operator"] | undefined,
	sizes__medium__width?: GraphQLTypes["BannerSection_Banners_Desktop_sizes__medium__width_operator"] | undefined,
	sizes__medium__height?: GraphQLTypes["BannerSection_Banners_Desktop_sizes__medium__height_operator"] | undefined,
	sizes__medium__mimeType?: GraphQLTypes["BannerSection_Banners_Desktop_sizes__medium__mimeType_operator"] | undefined,
	sizes__medium__filesize?: GraphQLTypes["BannerSection_Banners_Desktop_sizes__medium__filesize_operator"] | undefined,
	sizes__medium__filename?: GraphQLTypes["BannerSection_Banners_Desktop_sizes__medium__filename_operator"] | undefined,
	sizes__large__url?: GraphQLTypes["BannerSection_Banners_Desktop_sizes__large__url_operator"] | undefined,
	sizes__large__width?: GraphQLTypes["BannerSection_Banners_Desktop_sizes__large__width_operator"] | undefined,
	sizes__large__height?: GraphQLTypes["BannerSection_Banners_Desktop_sizes__large__height_operator"] | undefined,
	sizes__large__mimeType?: GraphQLTypes["BannerSection_Banners_Desktop_sizes__large__mimeType_operator"] | undefined,
	sizes__large__filesize?: GraphQLTypes["BannerSection_Banners_Desktop_sizes__large__filesize_operator"] | undefined,
	sizes__large__filename?: GraphQLTypes["BannerSection_Banners_Desktop_sizes__large__filename_operator"] | undefined,
	sizes__small_jpeg__url?: GraphQLTypes["BannerSection_Banners_Desktop_sizes__small_jpeg__url_operator"] | undefined,
	sizes__small_jpeg__width?: GraphQLTypes["BannerSection_Banners_Desktop_sizes__small_jpeg__width_operator"] | undefined,
	sizes__small_jpeg__height?: GraphQLTypes["BannerSection_Banners_Desktop_sizes__small_jpeg__height_operator"] | undefined,
	sizes__small_jpeg__mimeType?: GraphQLTypes["BannerSection_Banners_Desktop_sizes__small_jpeg__mimeType_operator"] | undefined,
	sizes__small_jpeg__filesize?: GraphQLTypes["BannerSection_Banners_Desktop_sizes__small_jpeg__filesize_operator"] | undefined,
	sizes__small_jpeg__filename?: GraphQLTypes["BannerSection_Banners_Desktop_sizes__small_jpeg__filename_operator"] | undefined,
	sizes__medium_jpeg__url?: GraphQLTypes["BannerSection_Banners_Desktop_sizes__medium_jpeg__url_operator"] | undefined,
	sizes__medium_jpeg__width?: GraphQLTypes["BannerSection_Banners_Desktop_sizes__medium_jpeg__width_operator"] | undefined,
	sizes__medium_jpeg__height?: GraphQLTypes["BannerSection_Banners_Desktop_sizes__medium_jpeg__height_operator"] | undefined,
	sizes__medium_jpeg__mimeType?: GraphQLTypes["BannerSection_Banners_Desktop_sizes__medium_jpeg__mimeType_operator"] | undefined,
	sizes__medium_jpeg__filesize?: GraphQLTypes["BannerSection_Banners_Desktop_sizes__medium_jpeg__filesize_operator"] | undefined,
	sizes__medium_jpeg__filename?: GraphQLTypes["BannerSection_Banners_Desktop_sizes__medium_jpeg__filename_operator"] | undefined,
	sizes__large_jpeg__url?: GraphQLTypes["BannerSection_Banners_Desktop_sizes__large_jpeg__url_operator"] | undefined,
	sizes__large_jpeg__width?: GraphQLTypes["BannerSection_Banners_Desktop_sizes__large_jpeg__width_operator"] | undefined,
	sizes__large_jpeg__height?: GraphQLTypes["BannerSection_Banners_Desktop_sizes__large_jpeg__height_operator"] | undefined,
	sizes__large_jpeg__mimeType?: GraphQLTypes["BannerSection_Banners_Desktop_sizes__large_jpeg__mimeType_operator"] | undefined,
	sizes__large_jpeg__filesize?: GraphQLTypes["BannerSection_Banners_Desktop_sizes__large_jpeg__filesize_operator"] | undefined,
	sizes__large_jpeg__filename?: GraphQLTypes["BannerSection_Banners_Desktop_sizes__large_jpeg__filename_operator"] | undefined,
	id?: GraphQLTypes["BannerSection_Banners_Desktop_id_operator"] | undefined,
	AND?: Array<GraphQLTypes["BannerSection_Banners_Desktop_where_and"] | undefined> | undefined,
	OR?: Array<GraphQLTypes["BannerSection_Banners_Desktop_where_or"] | undefined> | undefined
};
	["BannerSection_Banners_region"]: BannerSection_Banners_region;
	["BannerSections"]: {
	__typename: "BannerSections",
	docs?: Array<GraphQLTypes["BannerSection"] | undefined> | undefined,
	hasNextPage?: boolean | undefined,
	hasPrevPage?: boolean | undefined,
	limit?: number | undefined,
	nextPage?: number | undefined,
	offset?: number | undefined,
	page?: number | undefined,
	pagingCounter?: number | undefined,
	prevPage?: number | undefined,
	totalDocs?: number | undefined,
	totalPages?: number | undefined
};
	["BannerSection_where"]: {
		name?: GraphQLTypes["BannerSection_name_operator"] | undefined,
	slug?: GraphQLTypes["BannerSection_slug_operator"] | undefined,
	banners__name?: GraphQLTypes["BannerSection_banners__name_operator"] | undefined,
	banners__status?: GraphQLTypes["BannerSection_banners__status_operator"] | undefined,
	banners__desktop?: GraphQLTypes["BannerSection_banners__desktop_operator"] | undefined,
	banners__bannerStartDate?: GraphQLTypes["BannerSection_banners__bannerStartDate_operator"] | undefined,
	banners__bannerEndDate?: GraphQLTypes["BannerSection_banners__bannerEndDate_operator"] | undefined,
	banners__region?: GraphQLTypes["BannerSection_banners__region_operator"] | undefined,
	banners__id?: GraphQLTypes["BannerSection_banners__id_operator"] | undefined,
	updatedAt?: GraphQLTypes["BannerSection_updatedAt_operator"] | undefined,
	createdAt?: GraphQLTypes["BannerSection_createdAt_operator"] | undefined,
	id?: GraphQLTypes["BannerSection_id_operator"] | undefined,
	AND?: Array<GraphQLTypes["BannerSection_where_and"] | undefined> | undefined,
	OR?: Array<GraphQLTypes["BannerSection_where_or"] | undefined> | undefined
};
	["BannerSection_name_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["BannerSection_slug_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["BannerSection_banners__name_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["BannerSection_banners__status_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["BannerSection_banners__desktop_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined
};
	["BannerSection_banners__bannerStartDate_operator"]: {
		equals?: GraphQLTypes["DateTime"] | undefined,
	not_equals?: GraphQLTypes["DateTime"] | undefined,
	greater_than_equal?: GraphQLTypes["DateTime"] | undefined,
	greater_than?: GraphQLTypes["DateTime"] | undefined,
	less_than_equal?: GraphQLTypes["DateTime"] | undefined,
	less_than?: GraphQLTypes["DateTime"] | undefined,
	like?: GraphQLTypes["DateTime"] | undefined,
	exists?: boolean | undefined
};
	["BannerSection_banners__bannerEndDate_operator"]: {
		equals?: GraphQLTypes["DateTime"] | undefined,
	not_equals?: GraphQLTypes["DateTime"] | undefined,
	greater_than_equal?: GraphQLTypes["DateTime"] | undefined,
	greater_than?: GraphQLTypes["DateTime"] | undefined,
	less_than_equal?: GraphQLTypes["DateTime"] | undefined,
	less_than?: GraphQLTypes["DateTime"] | undefined,
	like?: GraphQLTypes["DateTime"] | undefined,
	exists?: boolean | undefined
};
	["BannerSection_banners__region_operator"]: {
		equals?: GraphQLTypes["BannerSection_banners__region_Input"] | undefined,
	not_equals?: GraphQLTypes["BannerSection_banners__region_Input"] | undefined,
	in?: Array<GraphQLTypes["BannerSection_banners__region_Input"] | undefined> | undefined,
	not_in?: Array<GraphQLTypes["BannerSection_banners__region_Input"] | undefined> | undefined,
	all?: Array<GraphQLTypes["BannerSection_banners__region_Input"] | undefined> | undefined,
	exists?: boolean | undefined
};
	["BannerSection_banners__region_Input"]: BannerSection_banners__region_Input;
	["BannerSection_banners__id_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["BannerSection_updatedAt_operator"]: {
		equals?: GraphQLTypes["DateTime"] | undefined,
	not_equals?: GraphQLTypes["DateTime"] | undefined,
	greater_than_equal?: GraphQLTypes["DateTime"] | undefined,
	greater_than?: GraphQLTypes["DateTime"] | undefined,
	less_than_equal?: GraphQLTypes["DateTime"] | undefined,
	less_than?: GraphQLTypes["DateTime"] | undefined,
	like?: GraphQLTypes["DateTime"] | undefined,
	exists?: boolean | undefined
};
	["BannerSection_createdAt_operator"]: {
		equals?: GraphQLTypes["DateTime"] | undefined,
	not_equals?: GraphQLTypes["DateTime"] | undefined,
	greater_than_equal?: GraphQLTypes["DateTime"] | undefined,
	greater_than?: GraphQLTypes["DateTime"] | undefined,
	less_than_equal?: GraphQLTypes["DateTime"] | undefined,
	less_than?: GraphQLTypes["DateTime"] | undefined,
	like?: GraphQLTypes["DateTime"] | undefined,
	exists?: boolean | undefined
};
	["BannerSection_id_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["BannerSection_where_and"]: {
		name?: GraphQLTypes["BannerSection_name_operator"] | undefined,
	slug?: GraphQLTypes["BannerSection_slug_operator"] | undefined,
	banners__name?: GraphQLTypes["BannerSection_banners__name_operator"] | undefined,
	banners__status?: GraphQLTypes["BannerSection_banners__status_operator"] | undefined,
	banners__desktop?: GraphQLTypes["BannerSection_banners__desktop_operator"] | undefined,
	banners__bannerStartDate?: GraphQLTypes["BannerSection_banners__bannerStartDate_operator"] | undefined,
	banners__bannerEndDate?: GraphQLTypes["BannerSection_banners__bannerEndDate_operator"] | undefined,
	banners__region?: GraphQLTypes["BannerSection_banners__region_operator"] | undefined,
	banners__id?: GraphQLTypes["BannerSection_banners__id_operator"] | undefined,
	updatedAt?: GraphQLTypes["BannerSection_updatedAt_operator"] | undefined,
	createdAt?: GraphQLTypes["BannerSection_createdAt_operator"] | undefined,
	id?: GraphQLTypes["BannerSection_id_operator"] | undefined,
	AND?: Array<GraphQLTypes["BannerSection_where_and"] | undefined> | undefined,
	OR?: Array<GraphQLTypes["BannerSection_where_or"] | undefined> | undefined
};
	["BannerSection_where_or"]: {
		name?: GraphQLTypes["BannerSection_name_operator"] | undefined,
	slug?: GraphQLTypes["BannerSection_slug_operator"] | undefined,
	banners__name?: GraphQLTypes["BannerSection_banners__name_operator"] | undefined,
	banners__status?: GraphQLTypes["BannerSection_banners__status_operator"] | undefined,
	banners__desktop?: GraphQLTypes["BannerSection_banners__desktop_operator"] | undefined,
	banners__bannerStartDate?: GraphQLTypes["BannerSection_banners__bannerStartDate_operator"] | undefined,
	banners__bannerEndDate?: GraphQLTypes["BannerSection_banners__bannerEndDate_operator"] | undefined,
	banners__region?: GraphQLTypes["BannerSection_banners__region_operator"] | undefined,
	banners__id?: GraphQLTypes["BannerSection_banners__id_operator"] | undefined,
	updatedAt?: GraphQLTypes["BannerSection_updatedAt_operator"] | undefined,
	createdAt?: GraphQLTypes["BannerSection_createdAt_operator"] | undefined,
	id?: GraphQLTypes["BannerSection_id_operator"] | undefined,
	AND?: Array<GraphQLTypes["BannerSection_where_and"] | undefined> | undefined,
	OR?: Array<GraphQLTypes["BannerSection_where_or"] | undefined> | undefined
};
	["countBannerSections"]: {
	__typename: "countBannerSections",
	totalDocs?: number | undefined
};
	["banner_sectionDocAccess"]: {
	__typename: "banner_sectionDocAccess",
	fields?: GraphQLTypes["BannerSectionDocAccessFields"] | undefined,
	create?: GraphQLTypes["BannerSectionCreateDocAccess"] | undefined,
	read?: GraphQLTypes["BannerSectionReadDocAccess"] | undefined,
	update?: GraphQLTypes["BannerSectionUpdateDocAccess"] | undefined,
	delete?: GraphQLTypes["BannerSectionDeleteDocAccess"] | undefined
};
	["BannerSectionDocAccessFields"]: {
	__typename: "BannerSectionDocAccessFields",
	name?: GraphQLTypes["BannerSectionDocAccessFields_name"] | undefined,
	slug?: GraphQLTypes["BannerSectionDocAccessFields_slug"] | undefined,
	banners?: GraphQLTypes["BannerSectionDocAccessFields_banners"] | undefined,
	updatedAt?: GraphQLTypes["BannerSectionDocAccessFields_updatedAt"] | undefined,
	createdAt?: GraphQLTypes["BannerSectionDocAccessFields_createdAt"] | undefined
};
	["BannerSectionDocAccessFields_name"]: {
	__typename: "BannerSectionDocAccessFields_name",
	create?: GraphQLTypes["BannerSectionDocAccessFields_name_Create"] | undefined,
	read?: GraphQLTypes["BannerSectionDocAccessFields_name_Read"] | undefined,
	update?: GraphQLTypes["BannerSectionDocAccessFields_name_Update"] | undefined,
	delete?: GraphQLTypes["BannerSectionDocAccessFields_name_Delete"] | undefined
};
	["BannerSectionDocAccessFields_name_Create"]: {
	__typename: "BannerSectionDocAccessFields_name_Create",
	permission: boolean
};
	["BannerSectionDocAccessFields_name_Read"]: {
	__typename: "BannerSectionDocAccessFields_name_Read",
	permission: boolean
};
	["BannerSectionDocAccessFields_name_Update"]: {
	__typename: "BannerSectionDocAccessFields_name_Update",
	permission: boolean
};
	["BannerSectionDocAccessFields_name_Delete"]: {
	__typename: "BannerSectionDocAccessFields_name_Delete",
	permission: boolean
};
	["BannerSectionDocAccessFields_slug"]: {
	__typename: "BannerSectionDocAccessFields_slug",
	create?: GraphQLTypes["BannerSectionDocAccessFields_slug_Create"] | undefined,
	read?: GraphQLTypes["BannerSectionDocAccessFields_slug_Read"] | undefined,
	update?: GraphQLTypes["BannerSectionDocAccessFields_slug_Update"] | undefined,
	delete?: GraphQLTypes["BannerSectionDocAccessFields_slug_Delete"] | undefined
};
	["BannerSectionDocAccessFields_slug_Create"]: {
	__typename: "BannerSectionDocAccessFields_slug_Create",
	permission: boolean
};
	["BannerSectionDocAccessFields_slug_Read"]: {
	__typename: "BannerSectionDocAccessFields_slug_Read",
	permission: boolean
};
	["BannerSectionDocAccessFields_slug_Update"]: {
	__typename: "BannerSectionDocAccessFields_slug_Update",
	permission: boolean
};
	["BannerSectionDocAccessFields_slug_Delete"]: {
	__typename: "BannerSectionDocAccessFields_slug_Delete",
	permission: boolean
};
	["BannerSectionDocAccessFields_banners"]: {
	__typename: "BannerSectionDocAccessFields_banners",
	create?: GraphQLTypes["BannerSectionDocAccessFields_banners_Create"] | undefined,
	read?: GraphQLTypes["BannerSectionDocAccessFields_banners_Read"] | undefined,
	update?: GraphQLTypes["BannerSectionDocAccessFields_banners_Update"] | undefined,
	delete?: GraphQLTypes["BannerSectionDocAccessFields_banners_Delete"] | undefined,
	fields?: GraphQLTypes["BannerSectionDocAccessFields_banners_Fields"] | undefined
};
	["BannerSectionDocAccessFields_banners_Create"]: {
	__typename: "BannerSectionDocAccessFields_banners_Create",
	permission: boolean
};
	["BannerSectionDocAccessFields_banners_Read"]: {
	__typename: "BannerSectionDocAccessFields_banners_Read",
	permission: boolean
};
	["BannerSectionDocAccessFields_banners_Update"]: {
	__typename: "BannerSectionDocAccessFields_banners_Update",
	permission: boolean
};
	["BannerSectionDocAccessFields_banners_Delete"]: {
	__typename: "BannerSectionDocAccessFields_banners_Delete",
	permission: boolean
};
	["BannerSectionDocAccessFields_banners_Fields"]: {
	__typename: "BannerSectionDocAccessFields_banners_Fields",
	name?: GraphQLTypes["BannerSectionDocAccessFields_banners_name"] | undefined,
	status?: GraphQLTypes["BannerSectionDocAccessFields_banners_status"] | undefined,
	desktop?: GraphQLTypes["BannerSectionDocAccessFields_banners_desktop"] | undefined,
	bannerStartDate?: GraphQLTypes["BannerSectionDocAccessFields_banners_bannerStartDate"] | undefined,
	bannerEndDate?: GraphQLTypes["BannerSectionDocAccessFields_banners_bannerEndDate"] | undefined,
	region?: GraphQLTypes["BannerSectionDocAccessFields_banners_region"] | undefined,
	id?: GraphQLTypes["BannerSectionDocAccessFields_banners_id"] | undefined
};
	["BannerSectionDocAccessFields_banners_name"]: {
	__typename: "BannerSectionDocAccessFields_banners_name",
	create?: GraphQLTypes["BannerSectionDocAccessFields_banners_name_Create"] | undefined,
	read?: GraphQLTypes["BannerSectionDocAccessFields_banners_name_Read"] | undefined,
	update?: GraphQLTypes["BannerSectionDocAccessFields_banners_name_Update"] | undefined,
	delete?: GraphQLTypes["BannerSectionDocAccessFields_banners_name_Delete"] | undefined
};
	["BannerSectionDocAccessFields_banners_name_Create"]: {
	__typename: "BannerSectionDocAccessFields_banners_name_Create",
	permission: boolean
};
	["BannerSectionDocAccessFields_banners_name_Read"]: {
	__typename: "BannerSectionDocAccessFields_banners_name_Read",
	permission: boolean
};
	["BannerSectionDocAccessFields_banners_name_Update"]: {
	__typename: "BannerSectionDocAccessFields_banners_name_Update",
	permission: boolean
};
	["BannerSectionDocAccessFields_banners_name_Delete"]: {
	__typename: "BannerSectionDocAccessFields_banners_name_Delete",
	permission: boolean
};
	["BannerSectionDocAccessFields_banners_status"]: {
	__typename: "BannerSectionDocAccessFields_banners_status",
	create?: GraphQLTypes["BannerSectionDocAccessFields_banners_status_Create"] | undefined,
	read?: GraphQLTypes["BannerSectionDocAccessFields_banners_status_Read"] | undefined,
	update?: GraphQLTypes["BannerSectionDocAccessFields_banners_status_Update"] | undefined,
	delete?: GraphQLTypes["BannerSectionDocAccessFields_banners_status_Delete"] | undefined
};
	["BannerSectionDocAccessFields_banners_status_Create"]: {
	__typename: "BannerSectionDocAccessFields_banners_status_Create",
	permission: boolean
};
	["BannerSectionDocAccessFields_banners_status_Read"]: {
	__typename: "BannerSectionDocAccessFields_banners_status_Read",
	permission: boolean
};
	["BannerSectionDocAccessFields_banners_status_Update"]: {
	__typename: "BannerSectionDocAccessFields_banners_status_Update",
	permission: boolean
};
	["BannerSectionDocAccessFields_banners_status_Delete"]: {
	__typename: "BannerSectionDocAccessFields_banners_status_Delete",
	permission: boolean
};
	["BannerSectionDocAccessFields_banners_desktop"]: {
	__typename: "BannerSectionDocAccessFields_banners_desktop",
	create?: GraphQLTypes["BannerSectionDocAccessFields_banners_desktop_Create"] | undefined,
	read?: GraphQLTypes["BannerSectionDocAccessFields_banners_desktop_Read"] | undefined,
	update?: GraphQLTypes["BannerSectionDocAccessFields_banners_desktop_Update"] | undefined,
	delete?: GraphQLTypes["BannerSectionDocAccessFields_banners_desktop_Delete"] | undefined
};
	["BannerSectionDocAccessFields_banners_desktop_Create"]: {
	__typename: "BannerSectionDocAccessFields_banners_desktop_Create",
	permission: boolean
};
	["BannerSectionDocAccessFields_banners_desktop_Read"]: {
	__typename: "BannerSectionDocAccessFields_banners_desktop_Read",
	permission: boolean
};
	["BannerSectionDocAccessFields_banners_desktop_Update"]: {
	__typename: "BannerSectionDocAccessFields_banners_desktop_Update",
	permission: boolean
};
	["BannerSectionDocAccessFields_banners_desktop_Delete"]: {
	__typename: "BannerSectionDocAccessFields_banners_desktop_Delete",
	permission: boolean
};
	["BannerSectionDocAccessFields_banners_bannerStartDate"]: {
	__typename: "BannerSectionDocAccessFields_banners_bannerStartDate",
	create?: GraphQLTypes["BannerSectionDocAccessFields_banners_bannerStartDate_Create"] | undefined,
	read?: GraphQLTypes["BannerSectionDocAccessFields_banners_bannerStartDate_Read"] | undefined,
	update?: GraphQLTypes["BannerSectionDocAccessFields_banners_bannerStartDate_Update"] | undefined,
	delete?: GraphQLTypes["BannerSectionDocAccessFields_banners_bannerStartDate_Delete"] | undefined
};
	["BannerSectionDocAccessFields_banners_bannerStartDate_Create"]: {
	__typename: "BannerSectionDocAccessFields_banners_bannerStartDate_Create",
	permission: boolean
};
	["BannerSectionDocAccessFields_banners_bannerStartDate_Read"]: {
	__typename: "BannerSectionDocAccessFields_banners_bannerStartDate_Read",
	permission: boolean
};
	["BannerSectionDocAccessFields_banners_bannerStartDate_Update"]: {
	__typename: "BannerSectionDocAccessFields_banners_bannerStartDate_Update",
	permission: boolean
};
	["BannerSectionDocAccessFields_banners_bannerStartDate_Delete"]: {
	__typename: "BannerSectionDocAccessFields_banners_bannerStartDate_Delete",
	permission: boolean
};
	["BannerSectionDocAccessFields_banners_bannerEndDate"]: {
	__typename: "BannerSectionDocAccessFields_banners_bannerEndDate",
	create?: GraphQLTypes["BannerSectionDocAccessFields_banners_bannerEndDate_Create"] | undefined,
	read?: GraphQLTypes["BannerSectionDocAccessFields_banners_bannerEndDate_Read"] | undefined,
	update?: GraphQLTypes["BannerSectionDocAccessFields_banners_bannerEndDate_Update"] | undefined,
	delete?: GraphQLTypes["BannerSectionDocAccessFields_banners_bannerEndDate_Delete"] | undefined
};
	["BannerSectionDocAccessFields_banners_bannerEndDate_Create"]: {
	__typename: "BannerSectionDocAccessFields_banners_bannerEndDate_Create",
	permission: boolean
};
	["BannerSectionDocAccessFields_banners_bannerEndDate_Read"]: {
	__typename: "BannerSectionDocAccessFields_banners_bannerEndDate_Read",
	permission: boolean
};
	["BannerSectionDocAccessFields_banners_bannerEndDate_Update"]: {
	__typename: "BannerSectionDocAccessFields_banners_bannerEndDate_Update",
	permission: boolean
};
	["BannerSectionDocAccessFields_banners_bannerEndDate_Delete"]: {
	__typename: "BannerSectionDocAccessFields_banners_bannerEndDate_Delete",
	permission: boolean
};
	["BannerSectionDocAccessFields_banners_region"]: {
	__typename: "BannerSectionDocAccessFields_banners_region",
	create?: GraphQLTypes["BannerSectionDocAccessFields_banners_region_Create"] | undefined,
	read?: GraphQLTypes["BannerSectionDocAccessFields_banners_region_Read"] | undefined,
	update?: GraphQLTypes["BannerSectionDocAccessFields_banners_region_Update"] | undefined,
	delete?: GraphQLTypes["BannerSectionDocAccessFields_banners_region_Delete"] | undefined
};
	["BannerSectionDocAccessFields_banners_region_Create"]: {
	__typename: "BannerSectionDocAccessFields_banners_region_Create",
	permission: boolean
};
	["BannerSectionDocAccessFields_banners_region_Read"]: {
	__typename: "BannerSectionDocAccessFields_banners_region_Read",
	permission: boolean
};
	["BannerSectionDocAccessFields_banners_region_Update"]: {
	__typename: "BannerSectionDocAccessFields_banners_region_Update",
	permission: boolean
};
	["BannerSectionDocAccessFields_banners_region_Delete"]: {
	__typename: "BannerSectionDocAccessFields_banners_region_Delete",
	permission: boolean
};
	["BannerSectionDocAccessFields_banners_id"]: {
	__typename: "BannerSectionDocAccessFields_banners_id",
	create?: GraphQLTypes["BannerSectionDocAccessFields_banners_id_Create"] | undefined,
	read?: GraphQLTypes["BannerSectionDocAccessFields_banners_id_Read"] | undefined,
	update?: GraphQLTypes["BannerSectionDocAccessFields_banners_id_Update"] | undefined,
	delete?: GraphQLTypes["BannerSectionDocAccessFields_banners_id_Delete"] | undefined
};
	["BannerSectionDocAccessFields_banners_id_Create"]: {
	__typename: "BannerSectionDocAccessFields_banners_id_Create",
	permission: boolean
};
	["BannerSectionDocAccessFields_banners_id_Read"]: {
	__typename: "BannerSectionDocAccessFields_banners_id_Read",
	permission: boolean
};
	["BannerSectionDocAccessFields_banners_id_Update"]: {
	__typename: "BannerSectionDocAccessFields_banners_id_Update",
	permission: boolean
};
	["BannerSectionDocAccessFields_banners_id_Delete"]: {
	__typename: "BannerSectionDocAccessFields_banners_id_Delete",
	permission: boolean
};
	["BannerSectionDocAccessFields_updatedAt"]: {
	__typename: "BannerSectionDocAccessFields_updatedAt",
	create?: GraphQLTypes["BannerSectionDocAccessFields_updatedAt_Create"] | undefined,
	read?: GraphQLTypes["BannerSectionDocAccessFields_updatedAt_Read"] | undefined,
	update?: GraphQLTypes["BannerSectionDocAccessFields_updatedAt_Update"] | undefined,
	delete?: GraphQLTypes["BannerSectionDocAccessFields_updatedAt_Delete"] | undefined
};
	["BannerSectionDocAccessFields_updatedAt_Create"]: {
	__typename: "BannerSectionDocAccessFields_updatedAt_Create",
	permission: boolean
};
	["BannerSectionDocAccessFields_updatedAt_Read"]: {
	__typename: "BannerSectionDocAccessFields_updatedAt_Read",
	permission: boolean
};
	["BannerSectionDocAccessFields_updatedAt_Update"]: {
	__typename: "BannerSectionDocAccessFields_updatedAt_Update",
	permission: boolean
};
	["BannerSectionDocAccessFields_updatedAt_Delete"]: {
	__typename: "BannerSectionDocAccessFields_updatedAt_Delete",
	permission: boolean
};
	["BannerSectionDocAccessFields_createdAt"]: {
	__typename: "BannerSectionDocAccessFields_createdAt",
	create?: GraphQLTypes["BannerSectionDocAccessFields_createdAt_Create"] | undefined,
	read?: GraphQLTypes["BannerSectionDocAccessFields_createdAt_Read"] | undefined,
	update?: GraphQLTypes["BannerSectionDocAccessFields_createdAt_Update"] | undefined,
	delete?: GraphQLTypes["BannerSectionDocAccessFields_createdAt_Delete"] | undefined
};
	["BannerSectionDocAccessFields_createdAt_Create"]: {
	__typename: "BannerSectionDocAccessFields_createdAt_Create",
	permission: boolean
};
	["BannerSectionDocAccessFields_createdAt_Read"]: {
	__typename: "BannerSectionDocAccessFields_createdAt_Read",
	permission: boolean
};
	["BannerSectionDocAccessFields_createdAt_Update"]: {
	__typename: "BannerSectionDocAccessFields_createdAt_Update",
	permission: boolean
};
	["BannerSectionDocAccessFields_createdAt_Delete"]: {
	__typename: "BannerSectionDocAccessFields_createdAt_Delete",
	permission: boolean
};
	["BannerSectionCreateDocAccess"]: {
	__typename: "BannerSectionCreateDocAccess",
	permission: boolean,
	where?: GraphQLTypes["JSONObject"] | undefined
};
	["BannerSectionReadDocAccess"]: {
	__typename: "BannerSectionReadDocAccess",
	permission: boolean,
	where?: GraphQLTypes["JSONObject"] | undefined
};
	["BannerSectionUpdateDocAccess"]: {
	__typename: "BannerSectionUpdateDocAccess",
	permission: boolean,
	where?: GraphQLTypes["JSONObject"] | undefined
};
	["BannerSectionDeleteDocAccess"]: {
	__typename: "BannerSectionDeleteDocAccess",
	permission: boolean,
	where?: GraphQLTypes["JSONObject"] | undefined
};
	["MacaoBannerSection"]: {
	__typename: "MacaoBannerSection",
	id?: string | undefined,
	name?: string | undefined,
	slug?: string | undefined,
	banners?: Array<GraphQLTypes["MacaoBannerSection_Banners"]> | undefined,
	updatedAt?: GraphQLTypes["DateTime"] | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined
};
	["MacaoBannerSection_Banners"]: {
	__typename: "MacaoBannerSection_Banners",
	name?: string | undefined,
	status?: string | undefined,
	desktop?: GraphQLTypes["Media"] | undefined,
	bannerStartDate?: GraphQLTypes["DateTime"] | undefined,
	bannerEndDate?: GraphQLTypes["DateTime"] | undefined,
	region?: GraphQLTypes["MacaoBannerSection_Banners_region"] | undefined,
	id?: string | undefined
};
	["MacaoBannerSection_Banners_Desktop_where"]: {
		alt?: GraphQLTypes["MacaoBannerSection_Banners_Desktop_alt_operator"] | undefined,
	updatedAt?: GraphQLTypes["MacaoBannerSection_Banners_Desktop_updatedAt_operator"] | undefined,
	createdAt?: GraphQLTypes["MacaoBannerSection_Banners_Desktop_createdAt_operator"] | undefined,
	url?: GraphQLTypes["MacaoBannerSection_Banners_Desktop_url_operator"] | undefined,
	filename?: GraphQLTypes["MacaoBannerSection_Banners_Desktop_filename_operator"] | undefined,
	mimeType?: GraphQLTypes["MacaoBannerSection_Banners_Desktop_mimeType_operator"] | undefined,
	filesize?: GraphQLTypes["MacaoBannerSection_Banners_Desktop_filesize_operator"] | undefined,
	width?: GraphQLTypes["MacaoBannerSection_Banners_Desktop_width_operator"] | undefined,
	height?: GraphQLTypes["MacaoBannerSection_Banners_Desktop_height_operator"] | undefined,
	focalX?: GraphQLTypes["MacaoBannerSection_Banners_Desktop_focalX_operator"] | undefined,
	focalY?: GraphQLTypes["MacaoBannerSection_Banners_Desktop_focalY_operator"] | undefined,
	sizes__small__url?: GraphQLTypes["MacaoBannerSection_Banners_Desktop_sizes__small__url_operator"] | undefined,
	sizes__small__width?: GraphQLTypes["MacaoBannerSection_Banners_Desktop_sizes__small__width_operator"] | undefined,
	sizes__small__height?: GraphQLTypes["MacaoBannerSection_Banners_Desktop_sizes__small__height_operator"] | undefined,
	sizes__small__mimeType?: GraphQLTypes["MacaoBannerSection_Banners_Desktop_sizes__small__mimeType_operator"] | undefined,
	sizes__small__filesize?: GraphQLTypes["MacaoBannerSection_Banners_Desktop_sizes__small__filesize_operator"] | undefined,
	sizes__small__filename?: GraphQLTypes["MacaoBannerSection_Banners_Desktop_sizes__small__filename_operator"] | undefined,
	sizes__medium__url?: GraphQLTypes["MacaoBannerSection_Banners_Desktop_sizes__medium__url_operator"] | undefined,
	sizes__medium__width?: GraphQLTypes["MacaoBannerSection_Banners_Desktop_sizes__medium__width_operator"] | undefined,
	sizes__medium__height?: GraphQLTypes["MacaoBannerSection_Banners_Desktop_sizes__medium__height_operator"] | undefined,
	sizes__medium__mimeType?: GraphQLTypes["MacaoBannerSection_Banners_Desktop_sizes__medium__mimeType_operator"] | undefined,
	sizes__medium__filesize?: GraphQLTypes["MacaoBannerSection_Banners_Desktop_sizes__medium__filesize_operator"] | undefined,
	sizes__medium__filename?: GraphQLTypes["MacaoBannerSection_Banners_Desktop_sizes__medium__filename_operator"] | undefined,
	sizes__large__url?: GraphQLTypes["MacaoBannerSection_Banners_Desktop_sizes__large__url_operator"] | undefined,
	sizes__large__width?: GraphQLTypes["MacaoBannerSection_Banners_Desktop_sizes__large__width_operator"] | undefined,
	sizes__large__height?: GraphQLTypes["MacaoBannerSection_Banners_Desktop_sizes__large__height_operator"] | undefined,
	sizes__large__mimeType?: GraphQLTypes["MacaoBannerSection_Banners_Desktop_sizes__large__mimeType_operator"] | undefined,
	sizes__large__filesize?: GraphQLTypes["MacaoBannerSection_Banners_Desktop_sizes__large__filesize_operator"] | undefined,
	sizes__large__filename?: GraphQLTypes["MacaoBannerSection_Banners_Desktop_sizes__large__filename_operator"] | undefined,
	sizes__small_jpeg__url?: GraphQLTypes["MacaoBannerSection_Banners_Desktop_sizes__small_jpeg__url_operator"] | undefined,
	sizes__small_jpeg__width?: GraphQLTypes["MacaoBannerSection_Banners_Desktop_sizes__small_jpeg__width_operator"] | undefined,
	sizes__small_jpeg__height?: GraphQLTypes["MacaoBannerSection_Banners_Desktop_sizes__small_jpeg__height_operator"] | undefined,
	sizes__small_jpeg__mimeType?: GraphQLTypes["MacaoBannerSection_Banners_Desktop_sizes__small_jpeg__mimeType_operator"] | undefined,
	sizes__small_jpeg__filesize?: GraphQLTypes["MacaoBannerSection_Banners_Desktop_sizes__small_jpeg__filesize_operator"] | undefined,
	sizes__small_jpeg__filename?: GraphQLTypes["MacaoBannerSection_Banners_Desktop_sizes__small_jpeg__filename_operator"] | undefined,
	sizes__medium_jpeg__url?: GraphQLTypes["MacaoBannerSection_Banners_Desktop_sizes__medium_jpeg__url_operator"] | undefined,
	sizes__medium_jpeg__width?: GraphQLTypes["MacaoBannerSection_Banners_Desktop_sizes__medium_jpeg__width_operator"] | undefined,
	sizes__medium_jpeg__height?: GraphQLTypes["MacaoBannerSection_Banners_Desktop_sizes__medium_jpeg__height_operator"] | undefined,
	sizes__medium_jpeg__mimeType?: GraphQLTypes["MacaoBannerSection_Banners_Desktop_sizes__medium_jpeg__mimeType_operator"] | undefined,
	sizes__medium_jpeg__filesize?: GraphQLTypes["MacaoBannerSection_Banners_Desktop_sizes__medium_jpeg__filesize_operator"] | undefined,
	sizes__medium_jpeg__filename?: GraphQLTypes["MacaoBannerSection_Banners_Desktop_sizes__medium_jpeg__filename_operator"] | undefined,
	sizes__large_jpeg__url?: GraphQLTypes["MacaoBannerSection_Banners_Desktop_sizes__large_jpeg__url_operator"] | undefined,
	sizes__large_jpeg__width?: GraphQLTypes["MacaoBannerSection_Banners_Desktop_sizes__large_jpeg__width_operator"] | undefined,
	sizes__large_jpeg__height?: GraphQLTypes["MacaoBannerSection_Banners_Desktop_sizes__large_jpeg__height_operator"] | undefined,
	sizes__large_jpeg__mimeType?: GraphQLTypes["MacaoBannerSection_Banners_Desktop_sizes__large_jpeg__mimeType_operator"] | undefined,
	sizes__large_jpeg__filesize?: GraphQLTypes["MacaoBannerSection_Banners_Desktop_sizes__large_jpeg__filesize_operator"] | undefined,
	sizes__large_jpeg__filename?: GraphQLTypes["MacaoBannerSection_Banners_Desktop_sizes__large_jpeg__filename_operator"] | undefined,
	id?: GraphQLTypes["MacaoBannerSection_Banners_Desktop_id_operator"] | undefined,
	AND?: Array<GraphQLTypes["MacaoBannerSection_Banners_Desktop_where_and"] | undefined> | undefined,
	OR?: Array<GraphQLTypes["MacaoBannerSection_Banners_Desktop_where_or"] | undefined> | undefined
};
	["MacaoBannerSection_Banners_Desktop_alt_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["MacaoBannerSection_Banners_Desktop_updatedAt_operator"]: {
		equals?: GraphQLTypes["DateTime"] | undefined,
	not_equals?: GraphQLTypes["DateTime"] | undefined,
	greater_than_equal?: GraphQLTypes["DateTime"] | undefined,
	greater_than?: GraphQLTypes["DateTime"] | undefined,
	less_than_equal?: GraphQLTypes["DateTime"] | undefined,
	less_than?: GraphQLTypes["DateTime"] | undefined,
	like?: GraphQLTypes["DateTime"] | undefined,
	exists?: boolean | undefined
};
	["MacaoBannerSection_Banners_Desktop_createdAt_operator"]: {
		equals?: GraphQLTypes["DateTime"] | undefined,
	not_equals?: GraphQLTypes["DateTime"] | undefined,
	greater_than_equal?: GraphQLTypes["DateTime"] | undefined,
	greater_than?: GraphQLTypes["DateTime"] | undefined,
	less_than_equal?: GraphQLTypes["DateTime"] | undefined,
	less_than?: GraphQLTypes["DateTime"] | undefined,
	like?: GraphQLTypes["DateTime"] | undefined,
	exists?: boolean | undefined
};
	["MacaoBannerSection_Banners_Desktop_url_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["MacaoBannerSection_Banners_Desktop_filename_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["MacaoBannerSection_Banners_Desktop_mimeType_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["MacaoBannerSection_Banners_Desktop_filesize_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["MacaoBannerSection_Banners_Desktop_width_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["MacaoBannerSection_Banners_Desktop_height_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["MacaoBannerSection_Banners_Desktop_focalX_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["MacaoBannerSection_Banners_Desktop_focalY_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["MacaoBannerSection_Banners_Desktop_sizes__small__url_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["MacaoBannerSection_Banners_Desktop_sizes__small__width_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["MacaoBannerSection_Banners_Desktop_sizes__small__height_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["MacaoBannerSection_Banners_Desktop_sizes__small__mimeType_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["MacaoBannerSection_Banners_Desktop_sizes__small__filesize_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["MacaoBannerSection_Banners_Desktop_sizes__small__filename_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["MacaoBannerSection_Banners_Desktop_sizes__medium__url_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["MacaoBannerSection_Banners_Desktop_sizes__medium__width_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["MacaoBannerSection_Banners_Desktop_sizes__medium__height_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["MacaoBannerSection_Banners_Desktop_sizes__medium__mimeType_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["MacaoBannerSection_Banners_Desktop_sizes__medium__filesize_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["MacaoBannerSection_Banners_Desktop_sizes__medium__filename_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["MacaoBannerSection_Banners_Desktop_sizes__large__url_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["MacaoBannerSection_Banners_Desktop_sizes__large__width_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["MacaoBannerSection_Banners_Desktop_sizes__large__height_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["MacaoBannerSection_Banners_Desktop_sizes__large__mimeType_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["MacaoBannerSection_Banners_Desktop_sizes__large__filesize_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["MacaoBannerSection_Banners_Desktop_sizes__large__filename_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["MacaoBannerSection_Banners_Desktop_sizes__small_jpeg__url_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["MacaoBannerSection_Banners_Desktop_sizes__small_jpeg__width_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["MacaoBannerSection_Banners_Desktop_sizes__small_jpeg__height_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["MacaoBannerSection_Banners_Desktop_sizes__small_jpeg__mimeType_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["MacaoBannerSection_Banners_Desktop_sizes__small_jpeg__filesize_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["MacaoBannerSection_Banners_Desktop_sizes__small_jpeg__filename_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["MacaoBannerSection_Banners_Desktop_sizes__medium_jpeg__url_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["MacaoBannerSection_Banners_Desktop_sizes__medium_jpeg__width_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["MacaoBannerSection_Banners_Desktop_sizes__medium_jpeg__height_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["MacaoBannerSection_Banners_Desktop_sizes__medium_jpeg__mimeType_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["MacaoBannerSection_Banners_Desktop_sizes__medium_jpeg__filesize_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["MacaoBannerSection_Banners_Desktop_sizes__medium_jpeg__filename_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["MacaoBannerSection_Banners_Desktop_sizes__large_jpeg__url_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["MacaoBannerSection_Banners_Desktop_sizes__large_jpeg__width_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["MacaoBannerSection_Banners_Desktop_sizes__large_jpeg__height_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["MacaoBannerSection_Banners_Desktop_sizes__large_jpeg__mimeType_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["MacaoBannerSection_Banners_Desktop_sizes__large_jpeg__filesize_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["MacaoBannerSection_Banners_Desktop_sizes__large_jpeg__filename_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["MacaoBannerSection_Banners_Desktop_id_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["MacaoBannerSection_Banners_Desktop_where_and"]: {
		alt?: GraphQLTypes["MacaoBannerSection_Banners_Desktop_alt_operator"] | undefined,
	updatedAt?: GraphQLTypes["MacaoBannerSection_Banners_Desktop_updatedAt_operator"] | undefined,
	createdAt?: GraphQLTypes["MacaoBannerSection_Banners_Desktop_createdAt_operator"] | undefined,
	url?: GraphQLTypes["MacaoBannerSection_Banners_Desktop_url_operator"] | undefined,
	filename?: GraphQLTypes["MacaoBannerSection_Banners_Desktop_filename_operator"] | undefined,
	mimeType?: GraphQLTypes["MacaoBannerSection_Banners_Desktop_mimeType_operator"] | undefined,
	filesize?: GraphQLTypes["MacaoBannerSection_Banners_Desktop_filesize_operator"] | undefined,
	width?: GraphQLTypes["MacaoBannerSection_Banners_Desktop_width_operator"] | undefined,
	height?: GraphQLTypes["MacaoBannerSection_Banners_Desktop_height_operator"] | undefined,
	focalX?: GraphQLTypes["MacaoBannerSection_Banners_Desktop_focalX_operator"] | undefined,
	focalY?: GraphQLTypes["MacaoBannerSection_Banners_Desktop_focalY_operator"] | undefined,
	sizes__small__url?: GraphQLTypes["MacaoBannerSection_Banners_Desktop_sizes__small__url_operator"] | undefined,
	sizes__small__width?: GraphQLTypes["MacaoBannerSection_Banners_Desktop_sizes__small__width_operator"] | undefined,
	sizes__small__height?: GraphQLTypes["MacaoBannerSection_Banners_Desktop_sizes__small__height_operator"] | undefined,
	sizes__small__mimeType?: GraphQLTypes["MacaoBannerSection_Banners_Desktop_sizes__small__mimeType_operator"] | undefined,
	sizes__small__filesize?: GraphQLTypes["MacaoBannerSection_Banners_Desktop_sizes__small__filesize_operator"] | undefined,
	sizes__small__filename?: GraphQLTypes["MacaoBannerSection_Banners_Desktop_sizes__small__filename_operator"] | undefined,
	sizes__medium__url?: GraphQLTypes["MacaoBannerSection_Banners_Desktop_sizes__medium__url_operator"] | undefined,
	sizes__medium__width?: GraphQLTypes["MacaoBannerSection_Banners_Desktop_sizes__medium__width_operator"] | undefined,
	sizes__medium__height?: GraphQLTypes["MacaoBannerSection_Banners_Desktop_sizes__medium__height_operator"] | undefined,
	sizes__medium__mimeType?: GraphQLTypes["MacaoBannerSection_Banners_Desktop_sizes__medium__mimeType_operator"] | undefined,
	sizes__medium__filesize?: GraphQLTypes["MacaoBannerSection_Banners_Desktop_sizes__medium__filesize_operator"] | undefined,
	sizes__medium__filename?: GraphQLTypes["MacaoBannerSection_Banners_Desktop_sizes__medium__filename_operator"] | undefined,
	sizes__large__url?: GraphQLTypes["MacaoBannerSection_Banners_Desktop_sizes__large__url_operator"] | undefined,
	sizes__large__width?: GraphQLTypes["MacaoBannerSection_Banners_Desktop_sizes__large__width_operator"] | undefined,
	sizes__large__height?: GraphQLTypes["MacaoBannerSection_Banners_Desktop_sizes__large__height_operator"] | undefined,
	sizes__large__mimeType?: GraphQLTypes["MacaoBannerSection_Banners_Desktop_sizes__large__mimeType_operator"] | undefined,
	sizes__large__filesize?: GraphQLTypes["MacaoBannerSection_Banners_Desktop_sizes__large__filesize_operator"] | undefined,
	sizes__large__filename?: GraphQLTypes["MacaoBannerSection_Banners_Desktop_sizes__large__filename_operator"] | undefined,
	sizes__small_jpeg__url?: GraphQLTypes["MacaoBannerSection_Banners_Desktop_sizes__small_jpeg__url_operator"] | undefined,
	sizes__small_jpeg__width?: GraphQLTypes["MacaoBannerSection_Banners_Desktop_sizes__small_jpeg__width_operator"] | undefined,
	sizes__small_jpeg__height?: GraphQLTypes["MacaoBannerSection_Banners_Desktop_sizes__small_jpeg__height_operator"] | undefined,
	sizes__small_jpeg__mimeType?: GraphQLTypes["MacaoBannerSection_Banners_Desktop_sizes__small_jpeg__mimeType_operator"] | undefined,
	sizes__small_jpeg__filesize?: GraphQLTypes["MacaoBannerSection_Banners_Desktop_sizes__small_jpeg__filesize_operator"] | undefined,
	sizes__small_jpeg__filename?: GraphQLTypes["MacaoBannerSection_Banners_Desktop_sizes__small_jpeg__filename_operator"] | undefined,
	sizes__medium_jpeg__url?: GraphQLTypes["MacaoBannerSection_Banners_Desktop_sizes__medium_jpeg__url_operator"] | undefined,
	sizes__medium_jpeg__width?: GraphQLTypes["MacaoBannerSection_Banners_Desktop_sizes__medium_jpeg__width_operator"] | undefined,
	sizes__medium_jpeg__height?: GraphQLTypes["MacaoBannerSection_Banners_Desktop_sizes__medium_jpeg__height_operator"] | undefined,
	sizes__medium_jpeg__mimeType?: GraphQLTypes["MacaoBannerSection_Banners_Desktop_sizes__medium_jpeg__mimeType_operator"] | undefined,
	sizes__medium_jpeg__filesize?: GraphQLTypes["MacaoBannerSection_Banners_Desktop_sizes__medium_jpeg__filesize_operator"] | undefined,
	sizes__medium_jpeg__filename?: GraphQLTypes["MacaoBannerSection_Banners_Desktop_sizes__medium_jpeg__filename_operator"] | undefined,
	sizes__large_jpeg__url?: GraphQLTypes["MacaoBannerSection_Banners_Desktop_sizes__large_jpeg__url_operator"] | undefined,
	sizes__large_jpeg__width?: GraphQLTypes["MacaoBannerSection_Banners_Desktop_sizes__large_jpeg__width_operator"] | undefined,
	sizes__large_jpeg__height?: GraphQLTypes["MacaoBannerSection_Banners_Desktop_sizes__large_jpeg__height_operator"] | undefined,
	sizes__large_jpeg__mimeType?: GraphQLTypes["MacaoBannerSection_Banners_Desktop_sizes__large_jpeg__mimeType_operator"] | undefined,
	sizes__large_jpeg__filesize?: GraphQLTypes["MacaoBannerSection_Banners_Desktop_sizes__large_jpeg__filesize_operator"] | undefined,
	sizes__large_jpeg__filename?: GraphQLTypes["MacaoBannerSection_Banners_Desktop_sizes__large_jpeg__filename_operator"] | undefined,
	id?: GraphQLTypes["MacaoBannerSection_Banners_Desktop_id_operator"] | undefined,
	AND?: Array<GraphQLTypes["MacaoBannerSection_Banners_Desktop_where_and"] | undefined> | undefined,
	OR?: Array<GraphQLTypes["MacaoBannerSection_Banners_Desktop_where_or"] | undefined> | undefined
};
	["MacaoBannerSection_Banners_Desktop_where_or"]: {
		alt?: GraphQLTypes["MacaoBannerSection_Banners_Desktop_alt_operator"] | undefined,
	updatedAt?: GraphQLTypes["MacaoBannerSection_Banners_Desktop_updatedAt_operator"] | undefined,
	createdAt?: GraphQLTypes["MacaoBannerSection_Banners_Desktop_createdAt_operator"] | undefined,
	url?: GraphQLTypes["MacaoBannerSection_Banners_Desktop_url_operator"] | undefined,
	filename?: GraphQLTypes["MacaoBannerSection_Banners_Desktop_filename_operator"] | undefined,
	mimeType?: GraphQLTypes["MacaoBannerSection_Banners_Desktop_mimeType_operator"] | undefined,
	filesize?: GraphQLTypes["MacaoBannerSection_Banners_Desktop_filesize_operator"] | undefined,
	width?: GraphQLTypes["MacaoBannerSection_Banners_Desktop_width_operator"] | undefined,
	height?: GraphQLTypes["MacaoBannerSection_Banners_Desktop_height_operator"] | undefined,
	focalX?: GraphQLTypes["MacaoBannerSection_Banners_Desktop_focalX_operator"] | undefined,
	focalY?: GraphQLTypes["MacaoBannerSection_Banners_Desktop_focalY_operator"] | undefined,
	sizes__small__url?: GraphQLTypes["MacaoBannerSection_Banners_Desktop_sizes__small__url_operator"] | undefined,
	sizes__small__width?: GraphQLTypes["MacaoBannerSection_Banners_Desktop_sizes__small__width_operator"] | undefined,
	sizes__small__height?: GraphQLTypes["MacaoBannerSection_Banners_Desktop_sizes__small__height_operator"] | undefined,
	sizes__small__mimeType?: GraphQLTypes["MacaoBannerSection_Banners_Desktop_sizes__small__mimeType_operator"] | undefined,
	sizes__small__filesize?: GraphQLTypes["MacaoBannerSection_Banners_Desktop_sizes__small__filesize_operator"] | undefined,
	sizes__small__filename?: GraphQLTypes["MacaoBannerSection_Banners_Desktop_sizes__small__filename_operator"] | undefined,
	sizes__medium__url?: GraphQLTypes["MacaoBannerSection_Banners_Desktop_sizes__medium__url_operator"] | undefined,
	sizes__medium__width?: GraphQLTypes["MacaoBannerSection_Banners_Desktop_sizes__medium__width_operator"] | undefined,
	sizes__medium__height?: GraphQLTypes["MacaoBannerSection_Banners_Desktop_sizes__medium__height_operator"] | undefined,
	sizes__medium__mimeType?: GraphQLTypes["MacaoBannerSection_Banners_Desktop_sizes__medium__mimeType_operator"] | undefined,
	sizes__medium__filesize?: GraphQLTypes["MacaoBannerSection_Banners_Desktop_sizes__medium__filesize_operator"] | undefined,
	sizes__medium__filename?: GraphQLTypes["MacaoBannerSection_Banners_Desktop_sizes__medium__filename_operator"] | undefined,
	sizes__large__url?: GraphQLTypes["MacaoBannerSection_Banners_Desktop_sizes__large__url_operator"] | undefined,
	sizes__large__width?: GraphQLTypes["MacaoBannerSection_Banners_Desktop_sizes__large__width_operator"] | undefined,
	sizes__large__height?: GraphQLTypes["MacaoBannerSection_Banners_Desktop_sizes__large__height_operator"] | undefined,
	sizes__large__mimeType?: GraphQLTypes["MacaoBannerSection_Banners_Desktop_sizes__large__mimeType_operator"] | undefined,
	sizes__large__filesize?: GraphQLTypes["MacaoBannerSection_Banners_Desktop_sizes__large__filesize_operator"] | undefined,
	sizes__large__filename?: GraphQLTypes["MacaoBannerSection_Banners_Desktop_sizes__large__filename_operator"] | undefined,
	sizes__small_jpeg__url?: GraphQLTypes["MacaoBannerSection_Banners_Desktop_sizes__small_jpeg__url_operator"] | undefined,
	sizes__small_jpeg__width?: GraphQLTypes["MacaoBannerSection_Banners_Desktop_sizes__small_jpeg__width_operator"] | undefined,
	sizes__small_jpeg__height?: GraphQLTypes["MacaoBannerSection_Banners_Desktop_sizes__small_jpeg__height_operator"] | undefined,
	sizes__small_jpeg__mimeType?: GraphQLTypes["MacaoBannerSection_Banners_Desktop_sizes__small_jpeg__mimeType_operator"] | undefined,
	sizes__small_jpeg__filesize?: GraphQLTypes["MacaoBannerSection_Banners_Desktop_sizes__small_jpeg__filesize_operator"] | undefined,
	sizes__small_jpeg__filename?: GraphQLTypes["MacaoBannerSection_Banners_Desktop_sizes__small_jpeg__filename_operator"] | undefined,
	sizes__medium_jpeg__url?: GraphQLTypes["MacaoBannerSection_Banners_Desktop_sizes__medium_jpeg__url_operator"] | undefined,
	sizes__medium_jpeg__width?: GraphQLTypes["MacaoBannerSection_Banners_Desktop_sizes__medium_jpeg__width_operator"] | undefined,
	sizes__medium_jpeg__height?: GraphQLTypes["MacaoBannerSection_Banners_Desktop_sizes__medium_jpeg__height_operator"] | undefined,
	sizes__medium_jpeg__mimeType?: GraphQLTypes["MacaoBannerSection_Banners_Desktop_sizes__medium_jpeg__mimeType_operator"] | undefined,
	sizes__medium_jpeg__filesize?: GraphQLTypes["MacaoBannerSection_Banners_Desktop_sizes__medium_jpeg__filesize_operator"] | undefined,
	sizes__medium_jpeg__filename?: GraphQLTypes["MacaoBannerSection_Banners_Desktop_sizes__medium_jpeg__filename_operator"] | undefined,
	sizes__large_jpeg__url?: GraphQLTypes["MacaoBannerSection_Banners_Desktop_sizes__large_jpeg__url_operator"] | undefined,
	sizes__large_jpeg__width?: GraphQLTypes["MacaoBannerSection_Banners_Desktop_sizes__large_jpeg__width_operator"] | undefined,
	sizes__large_jpeg__height?: GraphQLTypes["MacaoBannerSection_Banners_Desktop_sizes__large_jpeg__height_operator"] | undefined,
	sizes__large_jpeg__mimeType?: GraphQLTypes["MacaoBannerSection_Banners_Desktop_sizes__large_jpeg__mimeType_operator"] | undefined,
	sizes__large_jpeg__filesize?: GraphQLTypes["MacaoBannerSection_Banners_Desktop_sizes__large_jpeg__filesize_operator"] | undefined,
	sizes__large_jpeg__filename?: GraphQLTypes["MacaoBannerSection_Banners_Desktop_sizes__large_jpeg__filename_operator"] | undefined,
	id?: GraphQLTypes["MacaoBannerSection_Banners_Desktop_id_operator"] | undefined,
	AND?: Array<GraphQLTypes["MacaoBannerSection_Banners_Desktop_where_and"] | undefined> | undefined,
	OR?: Array<GraphQLTypes["MacaoBannerSection_Banners_Desktop_where_or"] | undefined> | undefined
};
	["MacaoBannerSection_Banners_region"]: MacaoBannerSection_Banners_region;
	["MacaoBannerSections"]: {
	__typename: "MacaoBannerSections",
	docs?: Array<GraphQLTypes["MacaoBannerSection"] | undefined> | undefined,
	hasNextPage?: boolean | undefined,
	hasPrevPage?: boolean | undefined,
	limit?: number | undefined,
	nextPage?: number | undefined,
	offset?: number | undefined,
	page?: number | undefined,
	pagingCounter?: number | undefined,
	prevPage?: number | undefined,
	totalDocs?: number | undefined,
	totalPages?: number | undefined
};
	["MacaoBannerSection_where"]: {
		name?: GraphQLTypes["MacaoBannerSection_name_operator"] | undefined,
	slug?: GraphQLTypes["MacaoBannerSection_slug_operator"] | undefined,
	banners__name?: GraphQLTypes["MacaoBannerSection_banners__name_operator"] | undefined,
	banners__status?: GraphQLTypes["MacaoBannerSection_banners__status_operator"] | undefined,
	banners__desktop?: GraphQLTypes["MacaoBannerSection_banners__desktop_operator"] | undefined,
	banners__bannerStartDate?: GraphQLTypes["MacaoBannerSection_banners__bannerStartDate_operator"] | undefined,
	banners__bannerEndDate?: GraphQLTypes["MacaoBannerSection_banners__bannerEndDate_operator"] | undefined,
	banners__region?: GraphQLTypes["MacaoBannerSection_banners__region_operator"] | undefined,
	banners__id?: GraphQLTypes["MacaoBannerSection_banners__id_operator"] | undefined,
	updatedAt?: GraphQLTypes["MacaoBannerSection_updatedAt_operator"] | undefined,
	createdAt?: GraphQLTypes["MacaoBannerSection_createdAt_operator"] | undefined,
	id?: GraphQLTypes["MacaoBannerSection_id_operator"] | undefined,
	AND?: Array<GraphQLTypes["MacaoBannerSection_where_and"] | undefined> | undefined,
	OR?: Array<GraphQLTypes["MacaoBannerSection_where_or"] | undefined> | undefined
};
	["MacaoBannerSection_name_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["MacaoBannerSection_slug_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["MacaoBannerSection_banners__name_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["MacaoBannerSection_banners__status_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["MacaoBannerSection_banners__desktop_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined
};
	["MacaoBannerSection_banners__bannerStartDate_operator"]: {
		equals?: GraphQLTypes["DateTime"] | undefined,
	not_equals?: GraphQLTypes["DateTime"] | undefined,
	greater_than_equal?: GraphQLTypes["DateTime"] | undefined,
	greater_than?: GraphQLTypes["DateTime"] | undefined,
	less_than_equal?: GraphQLTypes["DateTime"] | undefined,
	less_than?: GraphQLTypes["DateTime"] | undefined,
	like?: GraphQLTypes["DateTime"] | undefined,
	exists?: boolean | undefined
};
	["MacaoBannerSection_banners__bannerEndDate_operator"]: {
		equals?: GraphQLTypes["DateTime"] | undefined,
	not_equals?: GraphQLTypes["DateTime"] | undefined,
	greater_than_equal?: GraphQLTypes["DateTime"] | undefined,
	greater_than?: GraphQLTypes["DateTime"] | undefined,
	less_than_equal?: GraphQLTypes["DateTime"] | undefined,
	less_than?: GraphQLTypes["DateTime"] | undefined,
	like?: GraphQLTypes["DateTime"] | undefined,
	exists?: boolean | undefined
};
	["MacaoBannerSection_banners__region_operator"]: {
		equals?: GraphQLTypes["MacaoBannerSection_banners__region_Input"] | undefined,
	not_equals?: GraphQLTypes["MacaoBannerSection_banners__region_Input"] | undefined,
	in?: Array<GraphQLTypes["MacaoBannerSection_banners__region_Input"] | undefined> | undefined,
	not_in?: Array<GraphQLTypes["MacaoBannerSection_banners__region_Input"] | undefined> | undefined,
	all?: Array<GraphQLTypes["MacaoBannerSection_banners__region_Input"] | undefined> | undefined,
	exists?: boolean | undefined
};
	["MacaoBannerSection_banners__region_Input"]: MacaoBannerSection_banners__region_Input;
	["MacaoBannerSection_banners__id_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["MacaoBannerSection_updatedAt_operator"]: {
		equals?: GraphQLTypes["DateTime"] | undefined,
	not_equals?: GraphQLTypes["DateTime"] | undefined,
	greater_than_equal?: GraphQLTypes["DateTime"] | undefined,
	greater_than?: GraphQLTypes["DateTime"] | undefined,
	less_than_equal?: GraphQLTypes["DateTime"] | undefined,
	less_than?: GraphQLTypes["DateTime"] | undefined,
	like?: GraphQLTypes["DateTime"] | undefined,
	exists?: boolean | undefined
};
	["MacaoBannerSection_createdAt_operator"]: {
		equals?: GraphQLTypes["DateTime"] | undefined,
	not_equals?: GraphQLTypes["DateTime"] | undefined,
	greater_than_equal?: GraphQLTypes["DateTime"] | undefined,
	greater_than?: GraphQLTypes["DateTime"] | undefined,
	less_than_equal?: GraphQLTypes["DateTime"] | undefined,
	less_than?: GraphQLTypes["DateTime"] | undefined,
	like?: GraphQLTypes["DateTime"] | undefined,
	exists?: boolean | undefined
};
	["MacaoBannerSection_id_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["MacaoBannerSection_where_and"]: {
		name?: GraphQLTypes["MacaoBannerSection_name_operator"] | undefined,
	slug?: GraphQLTypes["MacaoBannerSection_slug_operator"] | undefined,
	banners__name?: GraphQLTypes["MacaoBannerSection_banners__name_operator"] | undefined,
	banners__status?: GraphQLTypes["MacaoBannerSection_banners__status_operator"] | undefined,
	banners__desktop?: GraphQLTypes["MacaoBannerSection_banners__desktop_operator"] | undefined,
	banners__bannerStartDate?: GraphQLTypes["MacaoBannerSection_banners__bannerStartDate_operator"] | undefined,
	banners__bannerEndDate?: GraphQLTypes["MacaoBannerSection_banners__bannerEndDate_operator"] | undefined,
	banners__region?: GraphQLTypes["MacaoBannerSection_banners__region_operator"] | undefined,
	banners__id?: GraphQLTypes["MacaoBannerSection_banners__id_operator"] | undefined,
	updatedAt?: GraphQLTypes["MacaoBannerSection_updatedAt_operator"] | undefined,
	createdAt?: GraphQLTypes["MacaoBannerSection_createdAt_operator"] | undefined,
	id?: GraphQLTypes["MacaoBannerSection_id_operator"] | undefined,
	AND?: Array<GraphQLTypes["MacaoBannerSection_where_and"] | undefined> | undefined,
	OR?: Array<GraphQLTypes["MacaoBannerSection_where_or"] | undefined> | undefined
};
	["MacaoBannerSection_where_or"]: {
		name?: GraphQLTypes["MacaoBannerSection_name_operator"] | undefined,
	slug?: GraphQLTypes["MacaoBannerSection_slug_operator"] | undefined,
	banners__name?: GraphQLTypes["MacaoBannerSection_banners__name_operator"] | undefined,
	banners__status?: GraphQLTypes["MacaoBannerSection_banners__status_operator"] | undefined,
	banners__desktop?: GraphQLTypes["MacaoBannerSection_banners__desktop_operator"] | undefined,
	banners__bannerStartDate?: GraphQLTypes["MacaoBannerSection_banners__bannerStartDate_operator"] | undefined,
	banners__bannerEndDate?: GraphQLTypes["MacaoBannerSection_banners__bannerEndDate_operator"] | undefined,
	banners__region?: GraphQLTypes["MacaoBannerSection_banners__region_operator"] | undefined,
	banners__id?: GraphQLTypes["MacaoBannerSection_banners__id_operator"] | undefined,
	updatedAt?: GraphQLTypes["MacaoBannerSection_updatedAt_operator"] | undefined,
	createdAt?: GraphQLTypes["MacaoBannerSection_createdAt_operator"] | undefined,
	id?: GraphQLTypes["MacaoBannerSection_id_operator"] | undefined,
	AND?: Array<GraphQLTypes["MacaoBannerSection_where_and"] | undefined> | undefined,
	OR?: Array<GraphQLTypes["MacaoBannerSection_where_or"] | undefined> | undefined
};
	["countMacaoBannerSections"]: {
	__typename: "countMacaoBannerSections",
	totalDocs?: number | undefined
};
	["macao_banner_sectionDocAccess"]: {
	__typename: "macao_banner_sectionDocAccess",
	fields?: GraphQLTypes["MacaoBannerSectionDocAccessFields"] | undefined,
	create?: GraphQLTypes["MacaoBannerSectionCreateDocAccess"] | undefined,
	read?: GraphQLTypes["MacaoBannerSectionReadDocAccess"] | undefined,
	update?: GraphQLTypes["MacaoBannerSectionUpdateDocAccess"] | undefined,
	delete?: GraphQLTypes["MacaoBannerSectionDeleteDocAccess"] | undefined
};
	["MacaoBannerSectionDocAccessFields"]: {
	__typename: "MacaoBannerSectionDocAccessFields",
	name?: GraphQLTypes["MacaoBannerSectionDocAccessFields_name"] | undefined,
	slug?: GraphQLTypes["MacaoBannerSectionDocAccessFields_slug"] | undefined,
	banners?: GraphQLTypes["MacaoBannerSectionDocAccessFields_banners"] | undefined,
	updatedAt?: GraphQLTypes["MacaoBannerSectionDocAccessFields_updatedAt"] | undefined,
	createdAt?: GraphQLTypes["MacaoBannerSectionDocAccessFields_createdAt"] | undefined
};
	["MacaoBannerSectionDocAccessFields_name"]: {
	__typename: "MacaoBannerSectionDocAccessFields_name",
	create?: GraphQLTypes["MacaoBannerSectionDocAccessFields_name_Create"] | undefined,
	read?: GraphQLTypes["MacaoBannerSectionDocAccessFields_name_Read"] | undefined,
	update?: GraphQLTypes["MacaoBannerSectionDocAccessFields_name_Update"] | undefined,
	delete?: GraphQLTypes["MacaoBannerSectionDocAccessFields_name_Delete"] | undefined
};
	["MacaoBannerSectionDocAccessFields_name_Create"]: {
	__typename: "MacaoBannerSectionDocAccessFields_name_Create",
	permission: boolean
};
	["MacaoBannerSectionDocAccessFields_name_Read"]: {
	__typename: "MacaoBannerSectionDocAccessFields_name_Read",
	permission: boolean
};
	["MacaoBannerSectionDocAccessFields_name_Update"]: {
	__typename: "MacaoBannerSectionDocAccessFields_name_Update",
	permission: boolean
};
	["MacaoBannerSectionDocAccessFields_name_Delete"]: {
	__typename: "MacaoBannerSectionDocAccessFields_name_Delete",
	permission: boolean
};
	["MacaoBannerSectionDocAccessFields_slug"]: {
	__typename: "MacaoBannerSectionDocAccessFields_slug",
	create?: GraphQLTypes["MacaoBannerSectionDocAccessFields_slug_Create"] | undefined,
	read?: GraphQLTypes["MacaoBannerSectionDocAccessFields_slug_Read"] | undefined,
	update?: GraphQLTypes["MacaoBannerSectionDocAccessFields_slug_Update"] | undefined,
	delete?: GraphQLTypes["MacaoBannerSectionDocAccessFields_slug_Delete"] | undefined
};
	["MacaoBannerSectionDocAccessFields_slug_Create"]: {
	__typename: "MacaoBannerSectionDocAccessFields_slug_Create",
	permission: boolean
};
	["MacaoBannerSectionDocAccessFields_slug_Read"]: {
	__typename: "MacaoBannerSectionDocAccessFields_slug_Read",
	permission: boolean
};
	["MacaoBannerSectionDocAccessFields_slug_Update"]: {
	__typename: "MacaoBannerSectionDocAccessFields_slug_Update",
	permission: boolean
};
	["MacaoBannerSectionDocAccessFields_slug_Delete"]: {
	__typename: "MacaoBannerSectionDocAccessFields_slug_Delete",
	permission: boolean
};
	["MacaoBannerSectionDocAccessFields_banners"]: {
	__typename: "MacaoBannerSectionDocAccessFields_banners",
	create?: GraphQLTypes["MacaoBannerSectionDocAccessFields_banners_Create"] | undefined,
	read?: GraphQLTypes["MacaoBannerSectionDocAccessFields_banners_Read"] | undefined,
	update?: GraphQLTypes["MacaoBannerSectionDocAccessFields_banners_Update"] | undefined,
	delete?: GraphQLTypes["MacaoBannerSectionDocAccessFields_banners_Delete"] | undefined,
	fields?: GraphQLTypes["MacaoBannerSectionDocAccessFields_banners_Fields"] | undefined
};
	["MacaoBannerSectionDocAccessFields_banners_Create"]: {
	__typename: "MacaoBannerSectionDocAccessFields_banners_Create",
	permission: boolean
};
	["MacaoBannerSectionDocAccessFields_banners_Read"]: {
	__typename: "MacaoBannerSectionDocAccessFields_banners_Read",
	permission: boolean
};
	["MacaoBannerSectionDocAccessFields_banners_Update"]: {
	__typename: "MacaoBannerSectionDocAccessFields_banners_Update",
	permission: boolean
};
	["MacaoBannerSectionDocAccessFields_banners_Delete"]: {
	__typename: "MacaoBannerSectionDocAccessFields_banners_Delete",
	permission: boolean
};
	["MacaoBannerSectionDocAccessFields_banners_Fields"]: {
	__typename: "MacaoBannerSectionDocAccessFields_banners_Fields",
	name?: GraphQLTypes["MacaoBannerSectionDocAccessFields_banners_name"] | undefined,
	status?: GraphQLTypes["MacaoBannerSectionDocAccessFields_banners_status"] | undefined,
	desktop?: GraphQLTypes["MacaoBannerSectionDocAccessFields_banners_desktop"] | undefined,
	bannerStartDate?: GraphQLTypes["MacaoBannerSectionDocAccessFields_banners_bannerStartDate"] | undefined,
	bannerEndDate?: GraphQLTypes["MacaoBannerSectionDocAccessFields_banners_bannerEndDate"] | undefined,
	region?: GraphQLTypes["MacaoBannerSectionDocAccessFields_banners_region"] | undefined,
	id?: GraphQLTypes["MacaoBannerSectionDocAccessFields_banners_id"] | undefined
};
	["MacaoBannerSectionDocAccessFields_banners_name"]: {
	__typename: "MacaoBannerSectionDocAccessFields_banners_name",
	create?: GraphQLTypes["MacaoBannerSectionDocAccessFields_banners_name_Create"] | undefined,
	read?: GraphQLTypes["MacaoBannerSectionDocAccessFields_banners_name_Read"] | undefined,
	update?: GraphQLTypes["MacaoBannerSectionDocAccessFields_banners_name_Update"] | undefined,
	delete?: GraphQLTypes["MacaoBannerSectionDocAccessFields_banners_name_Delete"] | undefined
};
	["MacaoBannerSectionDocAccessFields_banners_name_Create"]: {
	__typename: "MacaoBannerSectionDocAccessFields_banners_name_Create",
	permission: boolean
};
	["MacaoBannerSectionDocAccessFields_banners_name_Read"]: {
	__typename: "MacaoBannerSectionDocAccessFields_banners_name_Read",
	permission: boolean
};
	["MacaoBannerSectionDocAccessFields_banners_name_Update"]: {
	__typename: "MacaoBannerSectionDocAccessFields_banners_name_Update",
	permission: boolean
};
	["MacaoBannerSectionDocAccessFields_banners_name_Delete"]: {
	__typename: "MacaoBannerSectionDocAccessFields_banners_name_Delete",
	permission: boolean
};
	["MacaoBannerSectionDocAccessFields_banners_status"]: {
	__typename: "MacaoBannerSectionDocAccessFields_banners_status",
	create?: GraphQLTypes["MacaoBannerSectionDocAccessFields_banners_status_Create"] | undefined,
	read?: GraphQLTypes["MacaoBannerSectionDocAccessFields_banners_status_Read"] | undefined,
	update?: GraphQLTypes["MacaoBannerSectionDocAccessFields_banners_status_Update"] | undefined,
	delete?: GraphQLTypes["MacaoBannerSectionDocAccessFields_banners_status_Delete"] | undefined
};
	["MacaoBannerSectionDocAccessFields_banners_status_Create"]: {
	__typename: "MacaoBannerSectionDocAccessFields_banners_status_Create",
	permission: boolean
};
	["MacaoBannerSectionDocAccessFields_banners_status_Read"]: {
	__typename: "MacaoBannerSectionDocAccessFields_banners_status_Read",
	permission: boolean
};
	["MacaoBannerSectionDocAccessFields_banners_status_Update"]: {
	__typename: "MacaoBannerSectionDocAccessFields_banners_status_Update",
	permission: boolean
};
	["MacaoBannerSectionDocAccessFields_banners_status_Delete"]: {
	__typename: "MacaoBannerSectionDocAccessFields_banners_status_Delete",
	permission: boolean
};
	["MacaoBannerSectionDocAccessFields_banners_desktop"]: {
	__typename: "MacaoBannerSectionDocAccessFields_banners_desktop",
	create?: GraphQLTypes["MacaoBannerSectionDocAccessFields_banners_desktop_Create"] | undefined,
	read?: GraphQLTypes["MacaoBannerSectionDocAccessFields_banners_desktop_Read"] | undefined,
	update?: GraphQLTypes["MacaoBannerSectionDocAccessFields_banners_desktop_Update"] | undefined,
	delete?: GraphQLTypes["MacaoBannerSectionDocAccessFields_banners_desktop_Delete"] | undefined
};
	["MacaoBannerSectionDocAccessFields_banners_desktop_Create"]: {
	__typename: "MacaoBannerSectionDocAccessFields_banners_desktop_Create",
	permission: boolean
};
	["MacaoBannerSectionDocAccessFields_banners_desktop_Read"]: {
	__typename: "MacaoBannerSectionDocAccessFields_banners_desktop_Read",
	permission: boolean
};
	["MacaoBannerSectionDocAccessFields_banners_desktop_Update"]: {
	__typename: "MacaoBannerSectionDocAccessFields_banners_desktop_Update",
	permission: boolean
};
	["MacaoBannerSectionDocAccessFields_banners_desktop_Delete"]: {
	__typename: "MacaoBannerSectionDocAccessFields_banners_desktop_Delete",
	permission: boolean
};
	["MacaoBannerSectionDocAccessFields_banners_bannerStartDate"]: {
	__typename: "MacaoBannerSectionDocAccessFields_banners_bannerStartDate",
	create?: GraphQLTypes["MacaoBannerSectionDocAccessFields_banners_bannerStartDate_Create"] | undefined,
	read?: GraphQLTypes["MacaoBannerSectionDocAccessFields_banners_bannerStartDate_Read"] | undefined,
	update?: GraphQLTypes["MacaoBannerSectionDocAccessFields_banners_bannerStartDate_Update"] | undefined,
	delete?: GraphQLTypes["MacaoBannerSectionDocAccessFields_banners_bannerStartDate_Delete"] | undefined
};
	["MacaoBannerSectionDocAccessFields_banners_bannerStartDate_Create"]: {
	__typename: "MacaoBannerSectionDocAccessFields_banners_bannerStartDate_Create",
	permission: boolean
};
	["MacaoBannerSectionDocAccessFields_banners_bannerStartDate_Read"]: {
	__typename: "MacaoBannerSectionDocAccessFields_banners_bannerStartDate_Read",
	permission: boolean
};
	["MacaoBannerSectionDocAccessFields_banners_bannerStartDate_Update"]: {
	__typename: "MacaoBannerSectionDocAccessFields_banners_bannerStartDate_Update",
	permission: boolean
};
	["MacaoBannerSectionDocAccessFields_banners_bannerStartDate_Delete"]: {
	__typename: "MacaoBannerSectionDocAccessFields_banners_bannerStartDate_Delete",
	permission: boolean
};
	["MacaoBannerSectionDocAccessFields_banners_bannerEndDate"]: {
	__typename: "MacaoBannerSectionDocAccessFields_banners_bannerEndDate",
	create?: GraphQLTypes["MacaoBannerSectionDocAccessFields_banners_bannerEndDate_Create"] | undefined,
	read?: GraphQLTypes["MacaoBannerSectionDocAccessFields_banners_bannerEndDate_Read"] | undefined,
	update?: GraphQLTypes["MacaoBannerSectionDocAccessFields_banners_bannerEndDate_Update"] | undefined,
	delete?: GraphQLTypes["MacaoBannerSectionDocAccessFields_banners_bannerEndDate_Delete"] | undefined
};
	["MacaoBannerSectionDocAccessFields_banners_bannerEndDate_Create"]: {
	__typename: "MacaoBannerSectionDocAccessFields_banners_bannerEndDate_Create",
	permission: boolean
};
	["MacaoBannerSectionDocAccessFields_banners_bannerEndDate_Read"]: {
	__typename: "MacaoBannerSectionDocAccessFields_banners_bannerEndDate_Read",
	permission: boolean
};
	["MacaoBannerSectionDocAccessFields_banners_bannerEndDate_Update"]: {
	__typename: "MacaoBannerSectionDocAccessFields_banners_bannerEndDate_Update",
	permission: boolean
};
	["MacaoBannerSectionDocAccessFields_banners_bannerEndDate_Delete"]: {
	__typename: "MacaoBannerSectionDocAccessFields_banners_bannerEndDate_Delete",
	permission: boolean
};
	["MacaoBannerSectionDocAccessFields_banners_region"]: {
	__typename: "MacaoBannerSectionDocAccessFields_banners_region",
	create?: GraphQLTypes["MacaoBannerSectionDocAccessFields_banners_region_Create"] | undefined,
	read?: GraphQLTypes["MacaoBannerSectionDocAccessFields_banners_region_Read"] | undefined,
	update?: GraphQLTypes["MacaoBannerSectionDocAccessFields_banners_region_Update"] | undefined,
	delete?: GraphQLTypes["MacaoBannerSectionDocAccessFields_banners_region_Delete"] | undefined
};
	["MacaoBannerSectionDocAccessFields_banners_region_Create"]: {
	__typename: "MacaoBannerSectionDocAccessFields_banners_region_Create",
	permission: boolean
};
	["MacaoBannerSectionDocAccessFields_banners_region_Read"]: {
	__typename: "MacaoBannerSectionDocAccessFields_banners_region_Read",
	permission: boolean
};
	["MacaoBannerSectionDocAccessFields_banners_region_Update"]: {
	__typename: "MacaoBannerSectionDocAccessFields_banners_region_Update",
	permission: boolean
};
	["MacaoBannerSectionDocAccessFields_banners_region_Delete"]: {
	__typename: "MacaoBannerSectionDocAccessFields_banners_region_Delete",
	permission: boolean
};
	["MacaoBannerSectionDocAccessFields_banners_id"]: {
	__typename: "MacaoBannerSectionDocAccessFields_banners_id",
	create?: GraphQLTypes["MacaoBannerSectionDocAccessFields_banners_id_Create"] | undefined,
	read?: GraphQLTypes["MacaoBannerSectionDocAccessFields_banners_id_Read"] | undefined,
	update?: GraphQLTypes["MacaoBannerSectionDocAccessFields_banners_id_Update"] | undefined,
	delete?: GraphQLTypes["MacaoBannerSectionDocAccessFields_banners_id_Delete"] | undefined
};
	["MacaoBannerSectionDocAccessFields_banners_id_Create"]: {
	__typename: "MacaoBannerSectionDocAccessFields_banners_id_Create",
	permission: boolean
};
	["MacaoBannerSectionDocAccessFields_banners_id_Read"]: {
	__typename: "MacaoBannerSectionDocAccessFields_banners_id_Read",
	permission: boolean
};
	["MacaoBannerSectionDocAccessFields_banners_id_Update"]: {
	__typename: "MacaoBannerSectionDocAccessFields_banners_id_Update",
	permission: boolean
};
	["MacaoBannerSectionDocAccessFields_banners_id_Delete"]: {
	__typename: "MacaoBannerSectionDocAccessFields_banners_id_Delete",
	permission: boolean
};
	["MacaoBannerSectionDocAccessFields_updatedAt"]: {
	__typename: "MacaoBannerSectionDocAccessFields_updatedAt",
	create?: GraphQLTypes["MacaoBannerSectionDocAccessFields_updatedAt_Create"] | undefined,
	read?: GraphQLTypes["MacaoBannerSectionDocAccessFields_updatedAt_Read"] | undefined,
	update?: GraphQLTypes["MacaoBannerSectionDocAccessFields_updatedAt_Update"] | undefined,
	delete?: GraphQLTypes["MacaoBannerSectionDocAccessFields_updatedAt_Delete"] | undefined
};
	["MacaoBannerSectionDocAccessFields_updatedAt_Create"]: {
	__typename: "MacaoBannerSectionDocAccessFields_updatedAt_Create",
	permission: boolean
};
	["MacaoBannerSectionDocAccessFields_updatedAt_Read"]: {
	__typename: "MacaoBannerSectionDocAccessFields_updatedAt_Read",
	permission: boolean
};
	["MacaoBannerSectionDocAccessFields_updatedAt_Update"]: {
	__typename: "MacaoBannerSectionDocAccessFields_updatedAt_Update",
	permission: boolean
};
	["MacaoBannerSectionDocAccessFields_updatedAt_Delete"]: {
	__typename: "MacaoBannerSectionDocAccessFields_updatedAt_Delete",
	permission: boolean
};
	["MacaoBannerSectionDocAccessFields_createdAt"]: {
	__typename: "MacaoBannerSectionDocAccessFields_createdAt",
	create?: GraphQLTypes["MacaoBannerSectionDocAccessFields_createdAt_Create"] | undefined,
	read?: GraphQLTypes["MacaoBannerSectionDocAccessFields_createdAt_Read"] | undefined,
	update?: GraphQLTypes["MacaoBannerSectionDocAccessFields_createdAt_Update"] | undefined,
	delete?: GraphQLTypes["MacaoBannerSectionDocAccessFields_createdAt_Delete"] | undefined
};
	["MacaoBannerSectionDocAccessFields_createdAt_Create"]: {
	__typename: "MacaoBannerSectionDocAccessFields_createdAt_Create",
	permission: boolean
};
	["MacaoBannerSectionDocAccessFields_createdAt_Read"]: {
	__typename: "MacaoBannerSectionDocAccessFields_createdAt_Read",
	permission: boolean
};
	["MacaoBannerSectionDocAccessFields_createdAt_Update"]: {
	__typename: "MacaoBannerSectionDocAccessFields_createdAt_Update",
	permission: boolean
};
	["MacaoBannerSectionDocAccessFields_createdAt_Delete"]: {
	__typename: "MacaoBannerSectionDocAccessFields_createdAt_Delete",
	permission: boolean
};
	["MacaoBannerSectionCreateDocAccess"]: {
	__typename: "MacaoBannerSectionCreateDocAccess",
	permission: boolean,
	where?: GraphQLTypes["JSONObject"] | undefined
};
	["MacaoBannerSectionReadDocAccess"]: {
	__typename: "MacaoBannerSectionReadDocAccess",
	permission: boolean,
	where?: GraphQLTypes["JSONObject"] | undefined
};
	["MacaoBannerSectionUpdateDocAccess"]: {
	__typename: "MacaoBannerSectionUpdateDocAccess",
	permission: boolean,
	where?: GraphQLTypes["JSONObject"] | undefined
};
	["MacaoBannerSectionDeleteDocAccess"]: {
	__typename: "MacaoBannerSectionDeleteDocAccess",
	permission: boolean,
	where?: GraphQLTypes["JSONObject"] | undefined
};
	["Banner"]: {
	__typename: "Banner",
	id?: string | undefined,
	name: string,
	desktop: GraphQLTypes["Media"],
	mobile: GraphQLTypes["Media"],
	bannerStartDate: GraphQLTypes["DateTime"],
	bannerEndDate: GraphQLTypes["DateTime"],
	location?: GraphQLTypes["Banner_Location"] | undefined,
	link?: string | undefined,
	external?: boolean | undefined,
	type?: GraphQLTypes["Banner_type"] | undefined,
	status?: GraphQLTypes["Banner_status"] | undefined,
	advertise?: GraphQLTypes["Banner_Advertise"] | undefined,
	_locations?: string | undefined,
	region?: GraphQLTypes["Banner_region"] | undefined,
	homePageClick?: number | undefined,
	homePageImpression?: number | undefined,
	latestEventClick?: number | undefined,
	latestEventImpression?: number | undefined,
	editorChoiceClick?: number | undefined,
	editorChoiceImpression?: number | undefined,
	eventListClick?: number | undefined,
	eventListImpression?: number | undefined,
	topTenClick?: number | undefined,
	topTenImpression?: number | undefined,
	miscClick?: number | undefined,
	miscImpression?: number | undefined,
	clickTotal?: number | undefined,
	totalImpression?: number | undefined,
	updatedAt?: GraphQLTypes["DateTime"] | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined
};
	["Banner_Desktop_where"]: {
		alt?: GraphQLTypes["Banner_Desktop_alt_operator"] | undefined,
	updatedAt?: GraphQLTypes["Banner_Desktop_updatedAt_operator"] | undefined,
	createdAt?: GraphQLTypes["Banner_Desktop_createdAt_operator"] | undefined,
	url?: GraphQLTypes["Banner_Desktop_url_operator"] | undefined,
	filename?: GraphQLTypes["Banner_Desktop_filename_operator"] | undefined,
	mimeType?: GraphQLTypes["Banner_Desktop_mimeType_operator"] | undefined,
	filesize?: GraphQLTypes["Banner_Desktop_filesize_operator"] | undefined,
	width?: GraphQLTypes["Banner_Desktop_width_operator"] | undefined,
	height?: GraphQLTypes["Banner_Desktop_height_operator"] | undefined,
	focalX?: GraphQLTypes["Banner_Desktop_focalX_operator"] | undefined,
	focalY?: GraphQLTypes["Banner_Desktop_focalY_operator"] | undefined,
	sizes__small__url?: GraphQLTypes["Banner_Desktop_sizes__small__url_operator"] | undefined,
	sizes__small__width?: GraphQLTypes["Banner_Desktop_sizes__small__width_operator"] | undefined,
	sizes__small__height?: GraphQLTypes["Banner_Desktop_sizes__small__height_operator"] | undefined,
	sizes__small__mimeType?: GraphQLTypes["Banner_Desktop_sizes__small__mimeType_operator"] | undefined,
	sizes__small__filesize?: GraphQLTypes["Banner_Desktop_sizes__small__filesize_operator"] | undefined,
	sizes__small__filename?: GraphQLTypes["Banner_Desktop_sizes__small__filename_operator"] | undefined,
	sizes__medium__url?: GraphQLTypes["Banner_Desktop_sizes__medium__url_operator"] | undefined,
	sizes__medium__width?: GraphQLTypes["Banner_Desktop_sizes__medium__width_operator"] | undefined,
	sizes__medium__height?: GraphQLTypes["Banner_Desktop_sizes__medium__height_operator"] | undefined,
	sizes__medium__mimeType?: GraphQLTypes["Banner_Desktop_sizes__medium__mimeType_operator"] | undefined,
	sizes__medium__filesize?: GraphQLTypes["Banner_Desktop_sizes__medium__filesize_operator"] | undefined,
	sizes__medium__filename?: GraphQLTypes["Banner_Desktop_sizes__medium__filename_operator"] | undefined,
	sizes__large__url?: GraphQLTypes["Banner_Desktop_sizes__large__url_operator"] | undefined,
	sizes__large__width?: GraphQLTypes["Banner_Desktop_sizes__large__width_operator"] | undefined,
	sizes__large__height?: GraphQLTypes["Banner_Desktop_sizes__large__height_operator"] | undefined,
	sizes__large__mimeType?: GraphQLTypes["Banner_Desktop_sizes__large__mimeType_operator"] | undefined,
	sizes__large__filesize?: GraphQLTypes["Banner_Desktop_sizes__large__filesize_operator"] | undefined,
	sizes__large__filename?: GraphQLTypes["Banner_Desktop_sizes__large__filename_operator"] | undefined,
	sizes__small_jpeg__url?: GraphQLTypes["Banner_Desktop_sizes__small_jpeg__url_operator"] | undefined,
	sizes__small_jpeg__width?: GraphQLTypes["Banner_Desktop_sizes__small_jpeg__width_operator"] | undefined,
	sizes__small_jpeg__height?: GraphQLTypes["Banner_Desktop_sizes__small_jpeg__height_operator"] | undefined,
	sizes__small_jpeg__mimeType?: GraphQLTypes["Banner_Desktop_sizes__small_jpeg__mimeType_operator"] | undefined,
	sizes__small_jpeg__filesize?: GraphQLTypes["Banner_Desktop_sizes__small_jpeg__filesize_operator"] | undefined,
	sizes__small_jpeg__filename?: GraphQLTypes["Banner_Desktop_sizes__small_jpeg__filename_operator"] | undefined,
	sizes__medium_jpeg__url?: GraphQLTypes["Banner_Desktop_sizes__medium_jpeg__url_operator"] | undefined,
	sizes__medium_jpeg__width?: GraphQLTypes["Banner_Desktop_sizes__medium_jpeg__width_operator"] | undefined,
	sizes__medium_jpeg__height?: GraphQLTypes["Banner_Desktop_sizes__medium_jpeg__height_operator"] | undefined,
	sizes__medium_jpeg__mimeType?: GraphQLTypes["Banner_Desktop_sizes__medium_jpeg__mimeType_operator"] | undefined,
	sizes__medium_jpeg__filesize?: GraphQLTypes["Banner_Desktop_sizes__medium_jpeg__filesize_operator"] | undefined,
	sizes__medium_jpeg__filename?: GraphQLTypes["Banner_Desktop_sizes__medium_jpeg__filename_operator"] | undefined,
	sizes__large_jpeg__url?: GraphQLTypes["Banner_Desktop_sizes__large_jpeg__url_operator"] | undefined,
	sizes__large_jpeg__width?: GraphQLTypes["Banner_Desktop_sizes__large_jpeg__width_operator"] | undefined,
	sizes__large_jpeg__height?: GraphQLTypes["Banner_Desktop_sizes__large_jpeg__height_operator"] | undefined,
	sizes__large_jpeg__mimeType?: GraphQLTypes["Banner_Desktop_sizes__large_jpeg__mimeType_operator"] | undefined,
	sizes__large_jpeg__filesize?: GraphQLTypes["Banner_Desktop_sizes__large_jpeg__filesize_operator"] | undefined,
	sizes__large_jpeg__filename?: GraphQLTypes["Banner_Desktop_sizes__large_jpeg__filename_operator"] | undefined,
	id?: GraphQLTypes["Banner_Desktop_id_operator"] | undefined,
	AND?: Array<GraphQLTypes["Banner_Desktop_where_and"] | undefined> | undefined,
	OR?: Array<GraphQLTypes["Banner_Desktop_where_or"] | undefined> | undefined
};
	["Banner_Desktop_alt_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Banner_Desktop_updatedAt_operator"]: {
		equals?: GraphQLTypes["DateTime"] | undefined,
	not_equals?: GraphQLTypes["DateTime"] | undefined,
	greater_than_equal?: GraphQLTypes["DateTime"] | undefined,
	greater_than?: GraphQLTypes["DateTime"] | undefined,
	less_than_equal?: GraphQLTypes["DateTime"] | undefined,
	less_than?: GraphQLTypes["DateTime"] | undefined,
	like?: GraphQLTypes["DateTime"] | undefined,
	exists?: boolean | undefined
};
	["Banner_Desktop_createdAt_operator"]: {
		equals?: GraphQLTypes["DateTime"] | undefined,
	not_equals?: GraphQLTypes["DateTime"] | undefined,
	greater_than_equal?: GraphQLTypes["DateTime"] | undefined,
	greater_than?: GraphQLTypes["DateTime"] | undefined,
	less_than_equal?: GraphQLTypes["DateTime"] | undefined,
	less_than?: GraphQLTypes["DateTime"] | undefined,
	like?: GraphQLTypes["DateTime"] | undefined,
	exists?: boolean | undefined
};
	["Banner_Desktop_url_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Banner_Desktop_filename_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Banner_Desktop_mimeType_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Banner_Desktop_filesize_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Banner_Desktop_width_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Banner_Desktop_height_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Banner_Desktop_focalX_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Banner_Desktop_focalY_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Banner_Desktop_sizes__small__url_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Banner_Desktop_sizes__small__width_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Banner_Desktop_sizes__small__height_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Banner_Desktop_sizes__small__mimeType_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Banner_Desktop_sizes__small__filesize_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Banner_Desktop_sizes__small__filename_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Banner_Desktop_sizes__medium__url_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Banner_Desktop_sizes__medium__width_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Banner_Desktop_sizes__medium__height_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Banner_Desktop_sizes__medium__mimeType_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Banner_Desktop_sizes__medium__filesize_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Banner_Desktop_sizes__medium__filename_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Banner_Desktop_sizes__large__url_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Banner_Desktop_sizes__large__width_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Banner_Desktop_sizes__large__height_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Banner_Desktop_sizes__large__mimeType_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Banner_Desktop_sizes__large__filesize_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Banner_Desktop_sizes__large__filename_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Banner_Desktop_sizes__small_jpeg__url_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Banner_Desktop_sizes__small_jpeg__width_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Banner_Desktop_sizes__small_jpeg__height_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Banner_Desktop_sizes__small_jpeg__mimeType_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Banner_Desktop_sizes__small_jpeg__filesize_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Banner_Desktop_sizes__small_jpeg__filename_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Banner_Desktop_sizes__medium_jpeg__url_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Banner_Desktop_sizes__medium_jpeg__width_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Banner_Desktop_sizes__medium_jpeg__height_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Banner_Desktop_sizes__medium_jpeg__mimeType_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Banner_Desktop_sizes__medium_jpeg__filesize_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Banner_Desktop_sizes__medium_jpeg__filename_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Banner_Desktop_sizes__large_jpeg__url_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Banner_Desktop_sizes__large_jpeg__width_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Banner_Desktop_sizes__large_jpeg__height_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Banner_Desktop_sizes__large_jpeg__mimeType_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Banner_Desktop_sizes__large_jpeg__filesize_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Banner_Desktop_sizes__large_jpeg__filename_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Banner_Desktop_id_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Banner_Desktop_where_and"]: {
		alt?: GraphQLTypes["Banner_Desktop_alt_operator"] | undefined,
	updatedAt?: GraphQLTypes["Banner_Desktop_updatedAt_operator"] | undefined,
	createdAt?: GraphQLTypes["Banner_Desktop_createdAt_operator"] | undefined,
	url?: GraphQLTypes["Banner_Desktop_url_operator"] | undefined,
	filename?: GraphQLTypes["Banner_Desktop_filename_operator"] | undefined,
	mimeType?: GraphQLTypes["Banner_Desktop_mimeType_operator"] | undefined,
	filesize?: GraphQLTypes["Banner_Desktop_filesize_operator"] | undefined,
	width?: GraphQLTypes["Banner_Desktop_width_operator"] | undefined,
	height?: GraphQLTypes["Banner_Desktop_height_operator"] | undefined,
	focalX?: GraphQLTypes["Banner_Desktop_focalX_operator"] | undefined,
	focalY?: GraphQLTypes["Banner_Desktop_focalY_operator"] | undefined,
	sizes__small__url?: GraphQLTypes["Banner_Desktop_sizes__small__url_operator"] | undefined,
	sizes__small__width?: GraphQLTypes["Banner_Desktop_sizes__small__width_operator"] | undefined,
	sizes__small__height?: GraphQLTypes["Banner_Desktop_sizes__small__height_operator"] | undefined,
	sizes__small__mimeType?: GraphQLTypes["Banner_Desktop_sizes__small__mimeType_operator"] | undefined,
	sizes__small__filesize?: GraphQLTypes["Banner_Desktop_sizes__small__filesize_operator"] | undefined,
	sizes__small__filename?: GraphQLTypes["Banner_Desktop_sizes__small__filename_operator"] | undefined,
	sizes__medium__url?: GraphQLTypes["Banner_Desktop_sizes__medium__url_operator"] | undefined,
	sizes__medium__width?: GraphQLTypes["Banner_Desktop_sizes__medium__width_operator"] | undefined,
	sizes__medium__height?: GraphQLTypes["Banner_Desktop_sizes__medium__height_operator"] | undefined,
	sizes__medium__mimeType?: GraphQLTypes["Banner_Desktop_sizes__medium__mimeType_operator"] | undefined,
	sizes__medium__filesize?: GraphQLTypes["Banner_Desktop_sizes__medium__filesize_operator"] | undefined,
	sizes__medium__filename?: GraphQLTypes["Banner_Desktop_sizes__medium__filename_operator"] | undefined,
	sizes__large__url?: GraphQLTypes["Banner_Desktop_sizes__large__url_operator"] | undefined,
	sizes__large__width?: GraphQLTypes["Banner_Desktop_sizes__large__width_operator"] | undefined,
	sizes__large__height?: GraphQLTypes["Banner_Desktop_sizes__large__height_operator"] | undefined,
	sizes__large__mimeType?: GraphQLTypes["Banner_Desktop_sizes__large__mimeType_operator"] | undefined,
	sizes__large__filesize?: GraphQLTypes["Banner_Desktop_sizes__large__filesize_operator"] | undefined,
	sizes__large__filename?: GraphQLTypes["Banner_Desktop_sizes__large__filename_operator"] | undefined,
	sizes__small_jpeg__url?: GraphQLTypes["Banner_Desktop_sizes__small_jpeg__url_operator"] | undefined,
	sizes__small_jpeg__width?: GraphQLTypes["Banner_Desktop_sizes__small_jpeg__width_operator"] | undefined,
	sizes__small_jpeg__height?: GraphQLTypes["Banner_Desktop_sizes__small_jpeg__height_operator"] | undefined,
	sizes__small_jpeg__mimeType?: GraphQLTypes["Banner_Desktop_sizes__small_jpeg__mimeType_operator"] | undefined,
	sizes__small_jpeg__filesize?: GraphQLTypes["Banner_Desktop_sizes__small_jpeg__filesize_operator"] | undefined,
	sizes__small_jpeg__filename?: GraphQLTypes["Banner_Desktop_sizes__small_jpeg__filename_operator"] | undefined,
	sizes__medium_jpeg__url?: GraphQLTypes["Banner_Desktop_sizes__medium_jpeg__url_operator"] | undefined,
	sizes__medium_jpeg__width?: GraphQLTypes["Banner_Desktop_sizes__medium_jpeg__width_operator"] | undefined,
	sizes__medium_jpeg__height?: GraphQLTypes["Banner_Desktop_sizes__medium_jpeg__height_operator"] | undefined,
	sizes__medium_jpeg__mimeType?: GraphQLTypes["Banner_Desktop_sizes__medium_jpeg__mimeType_operator"] | undefined,
	sizes__medium_jpeg__filesize?: GraphQLTypes["Banner_Desktop_sizes__medium_jpeg__filesize_operator"] | undefined,
	sizes__medium_jpeg__filename?: GraphQLTypes["Banner_Desktop_sizes__medium_jpeg__filename_operator"] | undefined,
	sizes__large_jpeg__url?: GraphQLTypes["Banner_Desktop_sizes__large_jpeg__url_operator"] | undefined,
	sizes__large_jpeg__width?: GraphQLTypes["Banner_Desktop_sizes__large_jpeg__width_operator"] | undefined,
	sizes__large_jpeg__height?: GraphQLTypes["Banner_Desktop_sizes__large_jpeg__height_operator"] | undefined,
	sizes__large_jpeg__mimeType?: GraphQLTypes["Banner_Desktop_sizes__large_jpeg__mimeType_operator"] | undefined,
	sizes__large_jpeg__filesize?: GraphQLTypes["Banner_Desktop_sizes__large_jpeg__filesize_operator"] | undefined,
	sizes__large_jpeg__filename?: GraphQLTypes["Banner_Desktop_sizes__large_jpeg__filename_operator"] | undefined,
	id?: GraphQLTypes["Banner_Desktop_id_operator"] | undefined,
	AND?: Array<GraphQLTypes["Banner_Desktop_where_and"] | undefined> | undefined,
	OR?: Array<GraphQLTypes["Banner_Desktop_where_or"] | undefined> | undefined
};
	["Banner_Desktop_where_or"]: {
		alt?: GraphQLTypes["Banner_Desktop_alt_operator"] | undefined,
	updatedAt?: GraphQLTypes["Banner_Desktop_updatedAt_operator"] | undefined,
	createdAt?: GraphQLTypes["Banner_Desktop_createdAt_operator"] | undefined,
	url?: GraphQLTypes["Banner_Desktop_url_operator"] | undefined,
	filename?: GraphQLTypes["Banner_Desktop_filename_operator"] | undefined,
	mimeType?: GraphQLTypes["Banner_Desktop_mimeType_operator"] | undefined,
	filesize?: GraphQLTypes["Banner_Desktop_filesize_operator"] | undefined,
	width?: GraphQLTypes["Banner_Desktop_width_operator"] | undefined,
	height?: GraphQLTypes["Banner_Desktop_height_operator"] | undefined,
	focalX?: GraphQLTypes["Banner_Desktop_focalX_operator"] | undefined,
	focalY?: GraphQLTypes["Banner_Desktop_focalY_operator"] | undefined,
	sizes__small__url?: GraphQLTypes["Banner_Desktop_sizes__small__url_operator"] | undefined,
	sizes__small__width?: GraphQLTypes["Banner_Desktop_sizes__small__width_operator"] | undefined,
	sizes__small__height?: GraphQLTypes["Banner_Desktop_sizes__small__height_operator"] | undefined,
	sizes__small__mimeType?: GraphQLTypes["Banner_Desktop_sizes__small__mimeType_operator"] | undefined,
	sizes__small__filesize?: GraphQLTypes["Banner_Desktop_sizes__small__filesize_operator"] | undefined,
	sizes__small__filename?: GraphQLTypes["Banner_Desktop_sizes__small__filename_operator"] | undefined,
	sizes__medium__url?: GraphQLTypes["Banner_Desktop_sizes__medium__url_operator"] | undefined,
	sizes__medium__width?: GraphQLTypes["Banner_Desktop_sizes__medium__width_operator"] | undefined,
	sizes__medium__height?: GraphQLTypes["Banner_Desktop_sizes__medium__height_operator"] | undefined,
	sizes__medium__mimeType?: GraphQLTypes["Banner_Desktop_sizes__medium__mimeType_operator"] | undefined,
	sizes__medium__filesize?: GraphQLTypes["Banner_Desktop_sizes__medium__filesize_operator"] | undefined,
	sizes__medium__filename?: GraphQLTypes["Banner_Desktop_sizes__medium__filename_operator"] | undefined,
	sizes__large__url?: GraphQLTypes["Banner_Desktop_sizes__large__url_operator"] | undefined,
	sizes__large__width?: GraphQLTypes["Banner_Desktop_sizes__large__width_operator"] | undefined,
	sizes__large__height?: GraphQLTypes["Banner_Desktop_sizes__large__height_operator"] | undefined,
	sizes__large__mimeType?: GraphQLTypes["Banner_Desktop_sizes__large__mimeType_operator"] | undefined,
	sizes__large__filesize?: GraphQLTypes["Banner_Desktop_sizes__large__filesize_operator"] | undefined,
	sizes__large__filename?: GraphQLTypes["Banner_Desktop_sizes__large__filename_operator"] | undefined,
	sizes__small_jpeg__url?: GraphQLTypes["Banner_Desktop_sizes__small_jpeg__url_operator"] | undefined,
	sizes__small_jpeg__width?: GraphQLTypes["Banner_Desktop_sizes__small_jpeg__width_operator"] | undefined,
	sizes__small_jpeg__height?: GraphQLTypes["Banner_Desktop_sizes__small_jpeg__height_operator"] | undefined,
	sizes__small_jpeg__mimeType?: GraphQLTypes["Banner_Desktop_sizes__small_jpeg__mimeType_operator"] | undefined,
	sizes__small_jpeg__filesize?: GraphQLTypes["Banner_Desktop_sizes__small_jpeg__filesize_operator"] | undefined,
	sizes__small_jpeg__filename?: GraphQLTypes["Banner_Desktop_sizes__small_jpeg__filename_operator"] | undefined,
	sizes__medium_jpeg__url?: GraphQLTypes["Banner_Desktop_sizes__medium_jpeg__url_operator"] | undefined,
	sizes__medium_jpeg__width?: GraphQLTypes["Banner_Desktop_sizes__medium_jpeg__width_operator"] | undefined,
	sizes__medium_jpeg__height?: GraphQLTypes["Banner_Desktop_sizes__medium_jpeg__height_operator"] | undefined,
	sizes__medium_jpeg__mimeType?: GraphQLTypes["Banner_Desktop_sizes__medium_jpeg__mimeType_operator"] | undefined,
	sizes__medium_jpeg__filesize?: GraphQLTypes["Banner_Desktop_sizes__medium_jpeg__filesize_operator"] | undefined,
	sizes__medium_jpeg__filename?: GraphQLTypes["Banner_Desktop_sizes__medium_jpeg__filename_operator"] | undefined,
	sizes__large_jpeg__url?: GraphQLTypes["Banner_Desktop_sizes__large_jpeg__url_operator"] | undefined,
	sizes__large_jpeg__width?: GraphQLTypes["Banner_Desktop_sizes__large_jpeg__width_operator"] | undefined,
	sizes__large_jpeg__height?: GraphQLTypes["Banner_Desktop_sizes__large_jpeg__height_operator"] | undefined,
	sizes__large_jpeg__mimeType?: GraphQLTypes["Banner_Desktop_sizes__large_jpeg__mimeType_operator"] | undefined,
	sizes__large_jpeg__filesize?: GraphQLTypes["Banner_Desktop_sizes__large_jpeg__filesize_operator"] | undefined,
	sizes__large_jpeg__filename?: GraphQLTypes["Banner_Desktop_sizes__large_jpeg__filename_operator"] | undefined,
	id?: GraphQLTypes["Banner_Desktop_id_operator"] | undefined,
	AND?: Array<GraphQLTypes["Banner_Desktop_where_and"] | undefined> | undefined,
	OR?: Array<GraphQLTypes["Banner_Desktop_where_or"] | undefined> | undefined
};
	["Banner_Mobile_where"]: {
		alt?: GraphQLTypes["Banner_Mobile_alt_operator"] | undefined,
	updatedAt?: GraphQLTypes["Banner_Mobile_updatedAt_operator"] | undefined,
	createdAt?: GraphQLTypes["Banner_Mobile_createdAt_operator"] | undefined,
	url?: GraphQLTypes["Banner_Mobile_url_operator"] | undefined,
	filename?: GraphQLTypes["Banner_Mobile_filename_operator"] | undefined,
	mimeType?: GraphQLTypes["Banner_Mobile_mimeType_operator"] | undefined,
	filesize?: GraphQLTypes["Banner_Mobile_filesize_operator"] | undefined,
	width?: GraphQLTypes["Banner_Mobile_width_operator"] | undefined,
	height?: GraphQLTypes["Banner_Mobile_height_operator"] | undefined,
	focalX?: GraphQLTypes["Banner_Mobile_focalX_operator"] | undefined,
	focalY?: GraphQLTypes["Banner_Mobile_focalY_operator"] | undefined,
	sizes__small__url?: GraphQLTypes["Banner_Mobile_sizes__small__url_operator"] | undefined,
	sizes__small__width?: GraphQLTypes["Banner_Mobile_sizes__small__width_operator"] | undefined,
	sizes__small__height?: GraphQLTypes["Banner_Mobile_sizes__small__height_operator"] | undefined,
	sizes__small__mimeType?: GraphQLTypes["Banner_Mobile_sizes__small__mimeType_operator"] | undefined,
	sizes__small__filesize?: GraphQLTypes["Banner_Mobile_sizes__small__filesize_operator"] | undefined,
	sizes__small__filename?: GraphQLTypes["Banner_Mobile_sizes__small__filename_operator"] | undefined,
	sizes__medium__url?: GraphQLTypes["Banner_Mobile_sizes__medium__url_operator"] | undefined,
	sizes__medium__width?: GraphQLTypes["Banner_Mobile_sizes__medium__width_operator"] | undefined,
	sizes__medium__height?: GraphQLTypes["Banner_Mobile_sizes__medium__height_operator"] | undefined,
	sizes__medium__mimeType?: GraphQLTypes["Banner_Mobile_sizes__medium__mimeType_operator"] | undefined,
	sizes__medium__filesize?: GraphQLTypes["Banner_Mobile_sizes__medium__filesize_operator"] | undefined,
	sizes__medium__filename?: GraphQLTypes["Banner_Mobile_sizes__medium__filename_operator"] | undefined,
	sizes__large__url?: GraphQLTypes["Banner_Mobile_sizes__large__url_operator"] | undefined,
	sizes__large__width?: GraphQLTypes["Banner_Mobile_sizes__large__width_operator"] | undefined,
	sizes__large__height?: GraphQLTypes["Banner_Mobile_sizes__large__height_operator"] | undefined,
	sizes__large__mimeType?: GraphQLTypes["Banner_Mobile_sizes__large__mimeType_operator"] | undefined,
	sizes__large__filesize?: GraphQLTypes["Banner_Mobile_sizes__large__filesize_operator"] | undefined,
	sizes__large__filename?: GraphQLTypes["Banner_Mobile_sizes__large__filename_operator"] | undefined,
	sizes__small_jpeg__url?: GraphQLTypes["Banner_Mobile_sizes__small_jpeg__url_operator"] | undefined,
	sizes__small_jpeg__width?: GraphQLTypes["Banner_Mobile_sizes__small_jpeg__width_operator"] | undefined,
	sizes__small_jpeg__height?: GraphQLTypes["Banner_Mobile_sizes__small_jpeg__height_operator"] | undefined,
	sizes__small_jpeg__mimeType?: GraphQLTypes["Banner_Mobile_sizes__small_jpeg__mimeType_operator"] | undefined,
	sizes__small_jpeg__filesize?: GraphQLTypes["Banner_Mobile_sizes__small_jpeg__filesize_operator"] | undefined,
	sizes__small_jpeg__filename?: GraphQLTypes["Banner_Mobile_sizes__small_jpeg__filename_operator"] | undefined,
	sizes__medium_jpeg__url?: GraphQLTypes["Banner_Mobile_sizes__medium_jpeg__url_operator"] | undefined,
	sizes__medium_jpeg__width?: GraphQLTypes["Banner_Mobile_sizes__medium_jpeg__width_operator"] | undefined,
	sizes__medium_jpeg__height?: GraphQLTypes["Banner_Mobile_sizes__medium_jpeg__height_operator"] | undefined,
	sizes__medium_jpeg__mimeType?: GraphQLTypes["Banner_Mobile_sizes__medium_jpeg__mimeType_operator"] | undefined,
	sizes__medium_jpeg__filesize?: GraphQLTypes["Banner_Mobile_sizes__medium_jpeg__filesize_operator"] | undefined,
	sizes__medium_jpeg__filename?: GraphQLTypes["Banner_Mobile_sizes__medium_jpeg__filename_operator"] | undefined,
	sizes__large_jpeg__url?: GraphQLTypes["Banner_Mobile_sizes__large_jpeg__url_operator"] | undefined,
	sizes__large_jpeg__width?: GraphQLTypes["Banner_Mobile_sizes__large_jpeg__width_operator"] | undefined,
	sizes__large_jpeg__height?: GraphQLTypes["Banner_Mobile_sizes__large_jpeg__height_operator"] | undefined,
	sizes__large_jpeg__mimeType?: GraphQLTypes["Banner_Mobile_sizes__large_jpeg__mimeType_operator"] | undefined,
	sizes__large_jpeg__filesize?: GraphQLTypes["Banner_Mobile_sizes__large_jpeg__filesize_operator"] | undefined,
	sizes__large_jpeg__filename?: GraphQLTypes["Banner_Mobile_sizes__large_jpeg__filename_operator"] | undefined,
	id?: GraphQLTypes["Banner_Mobile_id_operator"] | undefined,
	AND?: Array<GraphQLTypes["Banner_Mobile_where_and"] | undefined> | undefined,
	OR?: Array<GraphQLTypes["Banner_Mobile_where_or"] | undefined> | undefined
};
	["Banner_Mobile_alt_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Banner_Mobile_updatedAt_operator"]: {
		equals?: GraphQLTypes["DateTime"] | undefined,
	not_equals?: GraphQLTypes["DateTime"] | undefined,
	greater_than_equal?: GraphQLTypes["DateTime"] | undefined,
	greater_than?: GraphQLTypes["DateTime"] | undefined,
	less_than_equal?: GraphQLTypes["DateTime"] | undefined,
	less_than?: GraphQLTypes["DateTime"] | undefined,
	like?: GraphQLTypes["DateTime"] | undefined,
	exists?: boolean | undefined
};
	["Banner_Mobile_createdAt_operator"]: {
		equals?: GraphQLTypes["DateTime"] | undefined,
	not_equals?: GraphQLTypes["DateTime"] | undefined,
	greater_than_equal?: GraphQLTypes["DateTime"] | undefined,
	greater_than?: GraphQLTypes["DateTime"] | undefined,
	less_than_equal?: GraphQLTypes["DateTime"] | undefined,
	less_than?: GraphQLTypes["DateTime"] | undefined,
	like?: GraphQLTypes["DateTime"] | undefined,
	exists?: boolean | undefined
};
	["Banner_Mobile_url_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Banner_Mobile_filename_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Banner_Mobile_mimeType_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Banner_Mobile_filesize_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Banner_Mobile_width_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Banner_Mobile_height_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Banner_Mobile_focalX_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Banner_Mobile_focalY_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Banner_Mobile_sizes__small__url_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Banner_Mobile_sizes__small__width_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Banner_Mobile_sizes__small__height_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Banner_Mobile_sizes__small__mimeType_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Banner_Mobile_sizes__small__filesize_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Banner_Mobile_sizes__small__filename_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Banner_Mobile_sizes__medium__url_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Banner_Mobile_sizes__medium__width_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Banner_Mobile_sizes__medium__height_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Banner_Mobile_sizes__medium__mimeType_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Banner_Mobile_sizes__medium__filesize_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Banner_Mobile_sizes__medium__filename_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Banner_Mobile_sizes__large__url_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Banner_Mobile_sizes__large__width_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Banner_Mobile_sizes__large__height_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Banner_Mobile_sizes__large__mimeType_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Banner_Mobile_sizes__large__filesize_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Banner_Mobile_sizes__large__filename_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Banner_Mobile_sizes__small_jpeg__url_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Banner_Mobile_sizes__small_jpeg__width_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Banner_Mobile_sizes__small_jpeg__height_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Banner_Mobile_sizes__small_jpeg__mimeType_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Banner_Mobile_sizes__small_jpeg__filesize_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Banner_Mobile_sizes__small_jpeg__filename_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Banner_Mobile_sizes__medium_jpeg__url_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Banner_Mobile_sizes__medium_jpeg__width_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Banner_Mobile_sizes__medium_jpeg__height_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Banner_Mobile_sizes__medium_jpeg__mimeType_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Banner_Mobile_sizes__medium_jpeg__filesize_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Banner_Mobile_sizes__medium_jpeg__filename_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Banner_Mobile_sizes__large_jpeg__url_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Banner_Mobile_sizes__large_jpeg__width_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Banner_Mobile_sizes__large_jpeg__height_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Banner_Mobile_sizes__large_jpeg__mimeType_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Banner_Mobile_sizes__large_jpeg__filesize_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Banner_Mobile_sizes__large_jpeg__filename_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Banner_Mobile_id_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Banner_Mobile_where_and"]: {
		alt?: GraphQLTypes["Banner_Mobile_alt_operator"] | undefined,
	updatedAt?: GraphQLTypes["Banner_Mobile_updatedAt_operator"] | undefined,
	createdAt?: GraphQLTypes["Banner_Mobile_createdAt_operator"] | undefined,
	url?: GraphQLTypes["Banner_Mobile_url_operator"] | undefined,
	filename?: GraphQLTypes["Banner_Mobile_filename_operator"] | undefined,
	mimeType?: GraphQLTypes["Banner_Mobile_mimeType_operator"] | undefined,
	filesize?: GraphQLTypes["Banner_Mobile_filesize_operator"] | undefined,
	width?: GraphQLTypes["Banner_Mobile_width_operator"] | undefined,
	height?: GraphQLTypes["Banner_Mobile_height_operator"] | undefined,
	focalX?: GraphQLTypes["Banner_Mobile_focalX_operator"] | undefined,
	focalY?: GraphQLTypes["Banner_Mobile_focalY_operator"] | undefined,
	sizes__small__url?: GraphQLTypes["Banner_Mobile_sizes__small__url_operator"] | undefined,
	sizes__small__width?: GraphQLTypes["Banner_Mobile_sizes__small__width_operator"] | undefined,
	sizes__small__height?: GraphQLTypes["Banner_Mobile_sizes__small__height_operator"] | undefined,
	sizes__small__mimeType?: GraphQLTypes["Banner_Mobile_sizes__small__mimeType_operator"] | undefined,
	sizes__small__filesize?: GraphQLTypes["Banner_Mobile_sizes__small__filesize_operator"] | undefined,
	sizes__small__filename?: GraphQLTypes["Banner_Mobile_sizes__small__filename_operator"] | undefined,
	sizes__medium__url?: GraphQLTypes["Banner_Mobile_sizes__medium__url_operator"] | undefined,
	sizes__medium__width?: GraphQLTypes["Banner_Mobile_sizes__medium__width_operator"] | undefined,
	sizes__medium__height?: GraphQLTypes["Banner_Mobile_sizes__medium__height_operator"] | undefined,
	sizes__medium__mimeType?: GraphQLTypes["Banner_Mobile_sizes__medium__mimeType_operator"] | undefined,
	sizes__medium__filesize?: GraphQLTypes["Banner_Mobile_sizes__medium__filesize_operator"] | undefined,
	sizes__medium__filename?: GraphQLTypes["Banner_Mobile_sizes__medium__filename_operator"] | undefined,
	sizes__large__url?: GraphQLTypes["Banner_Mobile_sizes__large__url_operator"] | undefined,
	sizes__large__width?: GraphQLTypes["Banner_Mobile_sizes__large__width_operator"] | undefined,
	sizes__large__height?: GraphQLTypes["Banner_Mobile_sizes__large__height_operator"] | undefined,
	sizes__large__mimeType?: GraphQLTypes["Banner_Mobile_sizes__large__mimeType_operator"] | undefined,
	sizes__large__filesize?: GraphQLTypes["Banner_Mobile_sizes__large__filesize_operator"] | undefined,
	sizes__large__filename?: GraphQLTypes["Banner_Mobile_sizes__large__filename_operator"] | undefined,
	sizes__small_jpeg__url?: GraphQLTypes["Banner_Mobile_sizes__small_jpeg__url_operator"] | undefined,
	sizes__small_jpeg__width?: GraphQLTypes["Banner_Mobile_sizes__small_jpeg__width_operator"] | undefined,
	sizes__small_jpeg__height?: GraphQLTypes["Banner_Mobile_sizes__small_jpeg__height_operator"] | undefined,
	sizes__small_jpeg__mimeType?: GraphQLTypes["Banner_Mobile_sizes__small_jpeg__mimeType_operator"] | undefined,
	sizes__small_jpeg__filesize?: GraphQLTypes["Banner_Mobile_sizes__small_jpeg__filesize_operator"] | undefined,
	sizes__small_jpeg__filename?: GraphQLTypes["Banner_Mobile_sizes__small_jpeg__filename_operator"] | undefined,
	sizes__medium_jpeg__url?: GraphQLTypes["Banner_Mobile_sizes__medium_jpeg__url_operator"] | undefined,
	sizes__medium_jpeg__width?: GraphQLTypes["Banner_Mobile_sizes__medium_jpeg__width_operator"] | undefined,
	sizes__medium_jpeg__height?: GraphQLTypes["Banner_Mobile_sizes__medium_jpeg__height_operator"] | undefined,
	sizes__medium_jpeg__mimeType?: GraphQLTypes["Banner_Mobile_sizes__medium_jpeg__mimeType_operator"] | undefined,
	sizes__medium_jpeg__filesize?: GraphQLTypes["Banner_Mobile_sizes__medium_jpeg__filesize_operator"] | undefined,
	sizes__medium_jpeg__filename?: GraphQLTypes["Banner_Mobile_sizes__medium_jpeg__filename_operator"] | undefined,
	sizes__large_jpeg__url?: GraphQLTypes["Banner_Mobile_sizes__large_jpeg__url_operator"] | undefined,
	sizes__large_jpeg__width?: GraphQLTypes["Banner_Mobile_sizes__large_jpeg__width_operator"] | undefined,
	sizes__large_jpeg__height?: GraphQLTypes["Banner_Mobile_sizes__large_jpeg__height_operator"] | undefined,
	sizes__large_jpeg__mimeType?: GraphQLTypes["Banner_Mobile_sizes__large_jpeg__mimeType_operator"] | undefined,
	sizes__large_jpeg__filesize?: GraphQLTypes["Banner_Mobile_sizes__large_jpeg__filesize_operator"] | undefined,
	sizes__large_jpeg__filename?: GraphQLTypes["Banner_Mobile_sizes__large_jpeg__filename_operator"] | undefined,
	id?: GraphQLTypes["Banner_Mobile_id_operator"] | undefined,
	AND?: Array<GraphQLTypes["Banner_Mobile_where_and"] | undefined> | undefined,
	OR?: Array<GraphQLTypes["Banner_Mobile_where_or"] | undefined> | undefined
};
	["Banner_Mobile_where_or"]: {
		alt?: GraphQLTypes["Banner_Mobile_alt_operator"] | undefined,
	updatedAt?: GraphQLTypes["Banner_Mobile_updatedAt_operator"] | undefined,
	createdAt?: GraphQLTypes["Banner_Mobile_createdAt_operator"] | undefined,
	url?: GraphQLTypes["Banner_Mobile_url_operator"] | undefined,
	filename?: GraphQLTypes["Banner_Mobile_filename_operator"] | undefined,
	mimeType?: GraphQLTypes["Banner_Mobile_mimeType_operator"] | undefined,
	filesize?: GraphQLTypes["Banner_Mobile_filesize_operator"] | undefined,
	width?: GraphQLTypes["Banner_Mobile_width_operator"] | undefined,
	height?: GraphQLTypes["Banner_Mobile_height_operator"] | undefined,
	focalX?: GraphQLTypes["Banner_Mobile_focalX_operator"] | undefined,
	focalY?: GraphQLTypes["Banner_Mobile_focalY_operator"] | undefined,
	sizes__small__url?: GraphQLTypes["Banner_Mobile_sizes__small__url_operator"] | undefined,
	sizes__small__width?: GraphQLTypes["Banner_Mobile_sizes__small__width_operator"] | undefined,
	sizes__small__height?: GraphQLTypes["Banner_Mobile_sizes__small__height_operator"] | undefined,
	sizes__small__mimeType?: GraphQLTypes["Banner_Mobile_sizes__small__mimeType_operator"] | undefined,
	sizes__small__filesize?: GraphQLTypes["Banner_Mobile_sizes__small__filesize_operator"] | undefined,
	sizes__small__filename?: GraphQLTypes["Banner_Mobile_sizes__small__filename_operator"] | undefined,
	sizes__medium__url?: GraphQLTypes["Banner_Mobile_sizes__medium__url_operator"] | undefined,
	sizes__medium__width?: GraphQLTypes["Banner_Mobile_sizes__medium__width_operator"] | undefined,
	sizes__medium__height?: GraphQLTypes["Banner_Mobile_sizes__medium__height_operator"] | undefined,
	sizes__medium__mimeType?: GraphQLTypes["Banner_Mobile_sizes__medium__mimeType_operator"] | undefined,
	sizes__medium__filesize?: GraphQLTypes["Banner_Mobile_sizes__medium__filesize_operator"] | undefined,
	sizes__medium__filename?: GraphQLTypes["Banner_Mobile_sizes__medium__filename_operator"] | undefined,
	sizes__large__url?: GraphQLTypes["Banner_Mobile_sizes__large__url_operator"] | undefined,
	sizes__large__width?: GraphQLTypes["Banner_Mobile_sizes__large__width_operator"] | undefined,
	sizes__large__height?: GraphQLTypes["Banner_Mobile_sizes__large__height_operator"] | undefined,
	sizes__large__mimeType?: GraphQLTypes["Banner_Mobile_sizes__large__mimeType_operator"] | undefined,
	sizes__large__filesize?: GraphQLTypes["Banner_Mobile_sizes__large__filesize_operator"] | undefined,
	sizes__large__filename?: GraphQLTypes["Banner_Mobile_sizes__large__filename_operator"] | undefined,
	sizes__small_jpeg__url?: GraphQLTypes["Banner_Mobile_sizes__small_jpeg__url_operator"] | undefined,
	sizes__small_jpeg__width?: GraphQLTypes["Banner_Mobile_sizes__small_jpeg__width_operator"] | undefined,
	sizes__small_jpeg__height?: GraphQLTypes["Banner_Mobile_sizes__small_jpeg__height_operator"] | undefined,
	sizes__small_jpeg__mimeType?: GraphQLTypes["Banner_Mobile_sizes__small_jpeg__mimeType_operator"] | undefined,
	sizes__small_jpeg__filesize?: GraphQLTypes["Banner_Mobile_sizes__small_jpeg__filesize_operator"] | undefined,
	sizes__small_jpeg__filename?: GraphQLTypes["Banner_Mobile_sizes__small_jpeg__filename_operator"] | undefined,
	sizes__medium_jpeg__url?: GraphQLTypes["Banner_Mobile_sizes__medium_jpeg__url_operator"] | undefined,
	sizes__medium_jpeg__width?: GraphQLTypes["Banner_Mobile_sizes__medium_jpeg__width_operator"] | undefined,
	sizes__medium_jpeg__height?: GraphQLTypes["Banner_Mobile_sizes__medium_jpeg__height_operator"] | undefined,
	sizes__medium_jpeg__mimeType?: GraphQLTypes["Banner_Mobile_sizes__medium_jpeg__mimeType_operator"] | undefined,
	sizes__medium_jpeg__filesize?: GraphQLTypes["Banner_Mobile_sizes__medium_jpeg__filesize_operator"] | undefined,
	sizes__medium_jpeg__filename?: GraphQLTypes["Banner_Mobile_sizes__medium_jpeg__filename_operator"] | undefined,
	sizes__large_jpeg__url?: GraphQLTypes["Banner_Mobile_sizes__large_jpeg__url_operator"] | undefined,
	sizes__large_jpeg__width?: GraphQLTypes["Banner_Mobile_sizes__large_jpeg__width_operator"] | undefined,
	sizes__large_jpeg__height?: GraphQLTypes["Banner_Mobile_sizes__large_jpeg__height_operator"] | undefined,
	sizes__large_jpeg__mimeType?: GraphQLTypes["Banner_Mobile_sizes__large_jpeg__mimeType_operator"] | undefined,
	sizes__large_jpeg__filesize?: GraphQLTypes["Banner_Mobile_sizes__large_jpeg__filesize_operator"] | undefined,
	sizes__large_jpeg__filename?: GraphQLTypes["Banner_Mobile_sizes__large_jpeg__filename_operator"] | undefined,
	id?: GraphQLTypes["Banner_Mobile_id_operator"] | undefined,
	AND?: Array<GraphQLTypes["Banner_Mobile_where_and"] | undefined> | undefined,
	OR?: Array<GraphQLTypes["Banner_Mobile_where_or"] | undefined> | undefined
};
	["Banner_Location"]: {
	__typename: "Banner_Location",
	page?: Array<GraphQLTypes["Banner_Location_page"]> | undefined,
	eventRankingList?: Array<GraphQLTypes["EventRanking"]> | undefined,
	locationRankingList?: Array<GraphQLTypes["LocationRanking"]> | undefined,
	categories?: Array<GraphQLTypes["EventCategory"]> | undefined,
	time?: boolean | undefined,
	district?: boolean | undefined,
	audience?: boolean | undefined,
	multipleCriteriaSearch?: boolean | undefined
};
	["Banner_Location_page"]: Banner_Location_page;
	["Banner_type"]: Banner_type;
	["Banner_status"]: Banner_status;
	["Banner_Advertise"]: {
	__typename: "Banner_Advertise",
	campaign?: GraphQLTypes["Campaign"] | undefined,
	invoiceDescription?: string | undefined,
	standardPrice?: number | undefined,
	discount?: number | undefined,
	timableCost?: number | undefined,
	remark?: string | undefined,
	public?: boolean | undefined
};
	["Campaign"]: {
	__typename: "Campaign",
	id?: string | undefined,
	creator: GraphQLTypes["Campaign_Creator_Relationship"],
	invoiceNumber?: string | undefined,
	quotationNumber?: string | undefined,
	campaignName: string,
	client: string,
	contactPerson: string,
	email: string,
	phone: string,
	agentName?: string | undefined,
	agentCommission?: number | undefined,
	agentOrderNumber?: string | undefined,
	_firstPublishedTime?: GraphQLTypes["DateTime"] | undefined,
	updatedAt?: GraphQLTypes["DateTime"] | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined
};
	["Campaign_Creator_Relationship"]: {
	__typename: "Campaign_Creator_Relationship",
	relationTo?: GraphQLTypes["Campaign_Creator_RelationTo"] | undefined,
	value?: GraphQLTypes["Campaign_Creator"] | undefined
};
	["Campaign_Creator_RelationTo"]: Campaign_Creator_RelationTo;
	["Campaign_Creator"]:{
        	__typename:"User"
        	['...on User']: '__union' & GraphQLTypes["User"];
};
	["Banner_region"]: Banner_region;
	["Banners"]: {
	__typename: "Banners",
	docs?: Array<GraphQLTypes["Banner"] | undefined> | undefined,
	hasNextPage?: boolean | undefined,
	hasPrevPage?: boolean | undefined,
	limit?: number | undefined,
	nextPage?: number | undefined,
	offset?: number | undefined,
	page?: number | undefined,
	pagingCounter?: number | undefined,
	prevPage?: number | undefined,
	totalDocs?: number | undefined,
	totalPages?: number | undefined
};
	["Banner_where"]: {
		name?: GraphQLTypes["Banner_name_operator"] | undefined,
	desktop?: GraphQLTypes["Banner_desktop_operator"] | undefined,
	mobile?: GraphQLTypes["Banner_mobile_operator"] | undefined,
	bannerStartDate?: GraphQLTypes["Banner_bannerStartDate_operator"] | undefined,
	bannerEndDate?: GraphQLTypes["Banner_bannerEndDate_operator"] | undefined,
	location__page?: GraphQLTypes["Banner_location__page_operator"] | undefined,
	location__eventRankingList?: GraphQLTypes["Banner_location__eventRankingList_operator"] | undefined,
	location__locationRankingList?: GraphQLTypes["Banner_location__locationRankingList_operator"] | undefined,
	location__categories?: GraphQLTypes["Banner_location__categories_operator"] | undefined,
	location__time?: GraphQLTypes["Banner_location__time_operator"] | undefined,
	location__district?: GraphQLTypes["Banner_location__district_operator"] | undefined,
	location__audience?: GraphQLTypes["Banner_location__audience_operator"] | undefined,
	location__multipleCriteriaSearch?: GraphQLTypes["Banner_location__multipleCriteriaSearch_operator"] | undefined,
	link?: GraphQLTypes["Banner_link_operator"] | undefined,
	external?: GraphQLTypes["Banner_external_operator"] | undefined,
	type?: GraphQLTypes["Banner_type_operator"] | undefined,
	status?: GraphQLTypes["Banner_status_operator"] | undefined,
	advertise__campaign?: GraphQLTypes["Banner_advertise__campaign_operator"] | undefined,
	advertise__invoiceDescription?: GraphQLTypes["Banner_advertise__invoiceDescription_operator"] | undefined,
	advertise__standardPrice?: GraphQLTypes["Banner_advertise__standardPrice_operator"] | undefined,
	advertise__discount?: GraphQLTypes["Banner_advertise__discount_operator"] | undefined,
	advertise__timableCost?: GraphQLTypes["Banner_advertise__timableCost_operator"] | undefined,
	advertise__remark?: GraphQLTypes["Banner_advertise__remark_operator"] | undefined,
	advertise__public?: GraphQLTypes["Banner_advertise__public_operator"] | undefined,
	_locations?: GraphQLTypes["Banner__locations_operator"] | undefined,
	region?: GraphQLTypes["Banner_region_operator"] | undefined,
	homePageClick?: GraphQLTypes["Banner_homePageClick_operator"] | undefined,
	homePageImpression?: GraphQLTypes["Banner_homePageImpression_operator"] | undefined,
	latestEventClick?: GraphQLTypes["Banner_latestEventClick_operator"] | undefined,
	latestEventImpression?: GraphQLTypes["Banner_latestEventImpression_operator"] | undefined,
	editorChoiceClick?: GraphQLTypes["Banner_editorChoiceClick_operator"] | undefined,
	editorChoiceImpression?: GraphQLTypes["Banner_editorChoiceImpression_operator"] | undefined,
	eventListClick?: GraphQLTypes["Banner_eventListClick_operator"] | undefined,
	eventListImpression?: GraphQLTypes["Banner_eventListImpression_operator"] | undefined,
	topTenClick?: GraphQLTypes["Banner_topTenClick_operator"] | undefined,
	topTenImpression?: GraphQLTypes["Banner_topTenImpression_operator"] | undefined,
	miscClick?: GraphQLTypes["Banner_miscClick_operator"] | undefined,
	miscImpression?: GraphQLTypes["Banner_miscImpression_operator"] | undefined,
	clickTotal?: GraphQLTypes["Banner_clickTotal_operator"] | undefined,
	totalImpression?: GraphQLTypes["Banner_totalImpression_operator"] | undefined,
	updatedAt?: GraphQLTypes["Banner_updatedAt_operator"] | undefined,
	createdAt?: GraphQLTypes["Banner_createdAt_operator"] | undefined,
	id?: GraphQLTypes["Banner_id_operator"] | undefined,
	AND?: Array<GraphQLTypes["Banner_where_and"] | undefined> | undefined,
	OR?: Array<GraphQLTypes["Banner_where_or"] | undefined> | undefined
};
	["Banner_name_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined
};
	["Banner_desktop_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined
};
	["Banner_mobile_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined
};
	["Banner_bannerStartDate_operator"]: {
		equals?: GraphQLTypes["DateTime"] | undefined,
	not_equals?: GraphQLTypes["DateTime"] | undefined,
	greater_than_equal?: GraphQLTypes["DateTime"] | undefined,
	greater_than?: GraphQLTypes["DateTime"] | undefined,
	less_than_equal?: GraphQLTypes["DateTime"] | undefined,
	less_than?: GraphQLTypes["DateTime"] | undefined,
	like?: GraphQLTypes["DateTime"] | undefined
};
	["Banner_bannerEndDate_operator"]: {
		equals?: GraphQLTypes["DateTime"] | undefined,
	not_equals?: GraphQLTypes["DateTime"] | undefined,
	greater_than_equal?: GraphQLTypes["DateTime"] | undefined,
	greater_than?: GraphQLTypes["DateTime"] | undefined,
	less_than_equal?: GraphQLTypes["DateTime"] | undefined,
	less_than?: GraphQLTypes["DateTime"] | undefined,
	like?: GraphQLTypes["DateTime"] | undefined
};
	["Banner_location__page_operator"]: {
		equals?: GraphQLTypes["Banner_location__page_Input"] | undefined,
	not_equals?: GraphQLTypes["Banner_location__page_Input"] | undefined,
	in?: Array<GraphQLTypes["Banner_location__page_Input"] | undefined> | undefined,
	not_in?: Array<GraphQLTypes["Banner_location__page_Input"] | undefined> | undefined,
	all?: Array<GraphQLTypes["Banner_location__page_Input"] | undefined> | undefined,
	exists?: boolean | undefined
};
	["Banner_location__page_Input"]: Banner_location__page_Input;
	["Banner_location__eventRankingList_operator"]: {
		equals?: GraphQLTypes["JSON"] | undefined,
	not_equals?: GraphQLTypes["JSON"] | undefined,
	in?: Array<GraphQLTypes["JSON"] | undefined> | undefined,
	not_in?: Array<GraphQLTypes["JSON"] | undefined> | undefined,
	all?: Array<GraphQLTypes["JSON"] | undefined> | undefined,
	exists?: boolean | undefined
};
	["Banner_location__locationRankingList_operator"]: {
		equals?: GraphQLTypes["JSON"] | undefined,
	not_equals?: GraphQLTypes["JSON"] | undefined,
	in?: Array<GraphQLTypes["JSON"] | undefined> | undefined,
	not_in?: Array<GraphQLTypes["JSON"] | undefined> | undefined,
	all?: Array<GraphQLTypes["JSON"] | undefined> | undefined,
	exists?: boolean | undefined
};
	["Banner_location__categories_operator"]: {
		equals?: GraphQLTypes["JSON"] | undefined,
	not_equals?: GraphQLTypes["JSON"] | undefined,
	in?: Array<GraphQLTypes["JSON"] | undefined> | undefined,
	not_in?: Array<GraphQLTypes["JSON"] | undefined> | undefined,
	all?: Array<GraphQLTypes["JSON"] | undefined> | undefined,
	exists?: boolean | undefined
};
	["Banner_location__time_operator"]: {
		equals?: boolean | undefined,
	not_equals?: boolean | undefined,
	exists?: boolean | undefined
};
	["Banner_location__district_operator"]: {
		equals?: boolean | undefined,
	not_equals?: boolean | undefined,
	exists?: boolean | undefined
};
	["Banner_location__audience_operator"]: {
		equals?: boolean | undefined,
	not_equals?: boolean | undefined,
	exists?: boolean | undefined
};
	["Banner_location__multipleCriteriaSearch_operator"]: {
		equals?: boolean | undefined,
	not_equals?: boolean | undefined,
	exists?: boolean | undefined
};
	["Banner_link_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Banner_external_operator"]: {
		equals?: boolean | undefined,
	not_equals?: boolean | undefined,
	exists?: boolean | undefined
};
	["Banner_type_operator"]: {
		equals?: GraphQLTypes["Banner_type_Input"] | undefined,
	not_equals?: GraphQLTypes["Banner_type_Input"] | undefined,
	in?: Array<GraphQLTypes["Banner_type_Input"] | undefined> | undefined,
	not_in?: Array<GraphQLTypes["Banner_type_Input"] | undefined> | undefined,
	all?: Array<GraphQLTypes["Banner_type_Input"] | undefined> | undefined,
	exists?: boolean | undefined
};
	["Banner_type_Input"]: Banner_type_Input;
	["Banner_status_operator"]: {
		equals?: GraphQLTypes["Banner_status_Input"] | undefined,
	not_equals?: GraphQLTypes["Banner_status_Input"] | undefined,
	in?: Array<GraphQLTypes["Banner_status_Input"] | undefined> | undefined,
	not_in?: Array<GraphQLTypes["Banner_status_Input"] | undefined> | undefined,
	all?: Array<GraphQLTypes["Banner_status_Input"] | undefined> | undefined,
	exists?: boolean | undefined
};
	["Banner_status_Input"]: Banner_status_Input;
	["Banner_advertise__campaign_operator"]: {
		equals?: GraphQLTypes["JSON"] | undefined,
	not_equals?: GraphQLTypes["JSON"] | undefined,
	in?: Array<GraphQLTypes["JSON"] | undefined> | undefined,
	not_in?: Array<GraphQLTypes["JSON"] | undefined> | undefined,
	all?: Array<GraphQLTypes["JSON"] | undefined> | undefined
};
	["Banner_advertise__invoiceDescription_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	exists?: boolean | undefined
};
	["Banner_advertise__standardPrice_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined
};
	["Banner_advertise__discount_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Banner_advertise__timableCost_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Banner_advertise__remark_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	exists?: boolean | undefined
};
	["Banner_advertise__public_operator"]: {
		equals?: boolean | undefined,
	not_equals?: boolean | undefined,
	exists?: boolean | undefined
};
	["Banner__locations_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Banner_region_operator"]: {
		equals?: GraphQLTypes["Banner_region_Input"] | undefined,
	not_equals?: GraphQLTypes["Banner_region_Input"] | undefined,
	in?: Array<GraphQLTypes["Banner_region_Input"] | undefined> | undefined,
	not_in?: Array<GraphQLTypes["Banner_region_Input"] | undefined> | undefined,
	all?: Array<GraphQLTypes["Banner_region_Input"] | undefined> | undefined,
	exists?: boolean | undefined
};
	["Banner_region_Input"]: Banner_region_Input;
	["Banner_homePageClick_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Banner_homePageImpression_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Banner_latestEventClick_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Banner_latestEventImpression_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Banner_editorChoiceClick_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Banner_editorChoiceImpression_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Banner_eventListClick_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Banner_eventListImpression_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Banner_topTenClick_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Banner_topTenImpression_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Banner_miscClick_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Banner_miscImpression_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Banner_clickTotal_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Banner_totalImpression_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Banner_updatedAt_operator"]: {
		equals?: GraphQLTypes["DateTime"] | undefined,
	not_equals?: GraphQLTypes["DateTime"] | undefined,
	greater_than_equal?: GraphQLTypes["DateTime"] | undefined,
	greater_than?: GraphQLTypes["DateTime"] | undefined,
	less_than_equal?: GraphQLTypes["DateTime"] | undefined,
	less_than?: GraphQLTypes["DateTime"] | undefined,
	like?: GraphQLTypes["DateTime"] | undefined,
	exists?: boolean | undefined
};
	["Banner_createdAt_operator"]: {
		equals?: GraphQLTypes["DateTime"] | undefined,
	not_equals?: GraphQLTypes["DateTime"] | undefined,
	greater_than_equal?: GraphQLTypes["DateTime"] | undefined,
	greater_than?: GraphQLTypes["DateTime"] | undefined,
	less_than_equal?: GraphQLTypes["DateTime"] | undefined,
	less_than?: GraphQLTypes["DateTime"] | undefined,
	like?: GraphQLTypes["DateTime"] | undefined,
	exists?: boolean | undefined
};
	["Banner_id_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Banner_where_and"]: {
		name?: GraphQLTypes["Banner_name_operator"] | undefined,
	desktop?: GraphQLTypes["Banner_desktop_operator"] | undefined,
	mobile?: GraphQLTypes["Banner_mobile_operator"] | undefined,
	bannerStartDate?: GraphQLTypes["Banner_bannerStartDate_operator"] | undefined,
	bannerEndDate?: GraphQLTypes["Banner_bannerEndDate_operator"] | undefined,
	location__page?: GraphQLTypes["Banner_location__page_operator"] | undefined,
	location__eventRankingList?: GraphQLTypes["Banner_location__eventRankingList_operator"] | undefined,
	location__locationRankingList?: GraphQLTypes["Banner_location__locationRankingList_operator"] | undefined,
	location__categories?: GraphQLTypes["Banner_location__categories_operator"] | undefined,
	location__time?: GraphQLTypes["Banner_location__time_operator"] | undefined,
	location__district?: GraphQLTypes["Banner_location__district_operator"] | undefined,
	location__audience?: GraphQLTypes["Banner_location__audience_operator"] | undefined,
	location__multipleCriteriaSearch?: GraphQLTypes["Banner_location__multipleCriteriaSearch_operator"] | undefined,
	link?: GraphQLTypes["Banner_link_operator"] | undefined,
	external?: GraphQLTypes["Banner_external_operator"] | undefined,
	type?: GraphQLTypes["Banner_type_operator"] | undefined,
	status?: GraphQLTypes["Banner_status_operator"] | undefined,
	advertise__campaign?: GraphQLTypes["Banner_advertise__campaign_operator"] | undefined,
	advertise__invoiceDescription?: GraphQLTypes["Banner_advertise__invoiceDescription_operator"] | undefined,
	advertise__standardPrice?: GraphQLTypes["Banner_advertise__standardPrice_operator"] | undefined,
	advertise__discount?: GraphQLTypes["Banner_advertise__discount_operator"] | undefined,
	advertise__timableCost?: GraphQLTypes["Banner_advertise__timableCost_operator"] | undefined,
	advertise__remark?: GraphQLTypes["Banner_advertise__remark_operator"] | undefined,
	advertise__public?: GraphQLTypes["Banner_advertise__public_operator"] | undefined,
	_locations?: GraphQLTypes["Banner__locations_operator"] | undefined,
	region?: GraphQLTypes["Banner_region_operator"] | undefined,
	homePageClick?: GraphQLTypes["Banner_homePageClick_operator"] | undefined,
	homePageImpression?: GraphQLTypes["Banner_homePageImpression_operator"] | undefined,
	latestEventClick?: GraphQLTypes["Banner_latestEventClick_operator"] | undefined,
	latestEventImpression?: GraphQLTypes["Banner_latestEventImpression_operator"] | undefined,
	editorChoiceClick?: GraphQLTypes["Banner_editorChoiceClick_operator"] | undefined,
	editorChoiceImpression?: GraphQLTypes["Banner_editorChoiceImpression_operator"] | undefined,
	eventListClick?: GraphQLTypes["Banner_eventListClick_operator"] | undefined,
	eventListImpression?: GraphQLTypes["Banner_eventListImpression_operator"] | undefined,
	topTenClick?: GraphQLTypes["Banner_topTenClick_operator"] | undefined,
	topTenImpression?: GraphQLTypes["Banner_topTenImpression_operator"] | undefined,
	miscClick?: GraphQLTypes["Banner_miscClick_operator"] | undefined,
	miscImpression?: GraphQLTypes["Banner_miscImpression_operator"] | undefined,
	clickTotal?: GraphQLTypes["Banner_clickTotal_operator"] | undefined,
	totalImpression?: GraphQLTypes["Banner_totalImpression_operator"] | undefined,
	updatedAt?: GraphQLTypes["Banner_updatedAt_operator"] | undefined,
	createdAt?: GraphQLTypes["Banner_createdAt_operator"] | undefined,
	id?: GraphQLTypes["Banner_id_operator"] | undefined,
	AND?: Array<GraphQLTypes["Banner_where_and"] | undefined> | undefined,
	OR?: Array<GraphQLTypes["Banner_where_or"] | undefined> | undefined
};
	["Banner_where_or"]: {
		name?: GraphQLTypes["Banner_name_operator"] | undefined,
	desktop?: GraphQLTypes["Banner_desktop_operator"] | undefined,
	mobile?: GraphQLTypes["Banner_mobile_operator"] | undefined,
	bannerStartDate?: GraphQLTypes["Banner_bannerStartDate_operator"] | undefined,
	bannerEndDate?: GraphQLTypes["Banner_bannerEndDate_operator"] | undefined,
	location__page?: GraphQLTypes["Banner_location__page_operator"] | undefined,
	location__eventRankingList?: GraphQLTypes["Banner_location__eventRankingList_operator"] | undefined,
	location__locationRankingList?: GraphQLTypes["Banner_location__locationRankingList_operator"] | undefined,
	location__categories?: GraphQLTypes["Banner_location__categories_operator"] | undefined,
	location__time?: GraphQLTypes["Banner_location__time_operator"] | undefined,
	location__district?: GraphQLTypes["Banner_location__district_operator"] | undefined,
	location__audience?: GraphQLTypes["Banner_location__audience_operator"] | undefined,
	location__multipleCriteriaSearch?: GraphQLTypes["Banner_location__multipleCriteriaSearch_operator"] | undefined,
	link?: GraphQLTypes["Banner_link_operator"] | undefined,
	external?: GraphQLTypes["Banner_external_operator"] | undefined,
	type?: GraphQLTypes["Banner_type_operator"] | undefined,
	status?: GraphQLTypes["Banner_status_operator"] | undefined,
	advertise__campaign?: GraphQLTypes["Banner_advertise__campaign_operator"] | undefined,
	advertise__invoiceDescription?: GraphQLTypes["Banner_advertise__invoiceDescription_operator"] | undefined,
	advertise__standardPrice?: GraphQLTypes["Banner_advertise__standardPrice_operator"] | undefined,
	advertise__discount?: GraphQLTypes["Banner_advertise__discount_operator"] | undefined,
	advertise__timableCost?: GraphQLTypes["Banner_advertise__timableCost_operator"] | undefined,
	advertise__remark?: GraphQLTypes["Banner_advertise__remark_operator"] | undefined,
	advertise__public?: GraphQLTypes["Banner_advertise__public_operator"] | undefined,
	_locations?: GraphQLTypes["Banner__locations_operator"] | undefined,
	region?: GraphQLTypes["Banner_region_operator"] | undefined,
	homePageClick?: GraphQLTypes["Banner_homePageClick_operator"] | undefined,
	homePageImpression?: GraphQLTypes["Banner_homePageImpression_operator"] | undefined,
	latestEventClick?: GraphQLTypes["Banner_latestEventClick_operator"] | undefined,
	latestEventImpression?: GraphQLTypes["Banner_latestEventImpression_operator"] | undefined,
	editorChoiceClick?: GraphQLTypes["Banner_editorChoiceClick_operator"] | undefined,
	editorChoiceImpression?: GraphQLTypes["Banner_editorChoiceImpression_operator"] | undefined,
	eventListClick?: GraphQLTypes["Banner_eventListClick_operator"] | undefined,
	eventListImpression?: GraphQLTypes["Banner_eventListImpression_operator"] | undefined,
	topTenClick?: GraphQLTypes["Banner_topTenClick_operator"] | undefined,
	topTenImpression?: GraphQLTypes["Banner_topTenImpression_operator"] | undefined,
	miscClick?: GraphQLTypes["Banner_miscClick_operator"] | undefined,
	miscImpression?: GraphQLTypes["Banner_miscImpression_operator"] | undefined,
	clickTotal?: GraphQLTypes["Banner_clickTotal_operator"] | undefined,
	totalImpression?: GraphQLTypes["Banner_totalImpression_operator"] | undefined,
	updatedAt?: GraphQLTypes["Banner_updatedAt_operator"] | undefined,
	createdAt?: GraphQLTypes["Banner_createdAt_operator"] | undefined,
	id?: GraphQLTypes["Banner_id_operator"] | undefined,
	AND?: Array<GraphQLTypes["Banner_where_and"] | undefined> | undefined,
	OR?: Array<GraphQLTypes["Banner_where_or"] | undefined> | undefined
};
	["countBanners"]: {
	__typename: "countBanners",
	totalDocs?: number | undefined
};
	["bannersDocAccess"]: {
	__typename: "bannersDocAccess",
	fields?: GraphQLTypes["BannersDocAccessFields"] | undefined,
	create?: GraphQLTypes["BannersCreateDocAccess"] | undefined,
	read?: GraphQLTypes["BannersReadDocAccess"] | undefined,
	update?: GraphQLTypes["BannersUpdateDocAccess"] | undefined,
	delete?: GraphQLTypes["BannersDeleteDocAccess"] | undefined
};
	["BannersDocAccessFields"]: {
	__typename: "BannersDocAccessFields",
	name?: GraphQLTypes["BannersDocAccessFields_name"] | undefined,
	desktop?: GraphQLTypes["BannersDocAccessFields_desktop"] | undefined,
	mobile?: GraphQLTypes["BannersDocAccessFields_mobile"] | undefined,
	bannerStartDate?: GraphQLTypes["BannersDocAccessFields_bannerStartDate"] | undefined,
	bannerEndDate?: GraphQLTypes["BannersDocAccessFields_bannerEndDate"] | undefined,
	location?: GraphQLTypes["BannersDocAccessFields_location"] | undefined,
	link?: GraphQLTypes["BannersDocAccessFields_link"] | undefined,
	external?: GraphQLTypes["BannersDocAccessFields_external"] | undefined,
	type?: GraphQLTypes["BannersDocAccessFields_type"] | undefined,
	status?: GraphQLTypes["BannersDocAccessFields_status"] | undefined,
	advertise?: GraphQLTypes["BannersDocAccessFields_advertise"] | undefined,
	_locations?: GraphQLTypes["BannersDocAccessFields__locations"] | undefined,
	region?: GraphQLTypes["BannersDocAccessFields_region"] | undefined,
	homePageClick?: GraphQLTypes["BannersDocAccessFields_homePageClick"] | undefined,
	homePageImpression?: GraphQLTypes["BannersDocAccessFields_homePageImpression"] | undefined,
	latestEventClick?: GraphQLTypes["BannersDocAccessFields_latestEventClick"] | undefined,
	latestEventImpression?: GraphQLTypes["BannersDocAccessFields_latestEventImpression"] | undefined,
	editorChoiceClick?: GraphQLTypes["BannersDocAccessFields_editorChoiceClick"] | undefined,
	editorChoiceImpression?: GraphQLTypes["BannersDocAccessFields_editorChoiceImpression"] | undefined,
	eventListClick?: GraphQLTypes["BannersDocAccessFields_eventListClick"] | undefined,
	eventListImpression?: GraphQLTypes["BannersDocAccessFields_eventListImpression"] | undefined,
	topTenClick?: GraphQLTypes["BannersDocAccessFields_topTenClick"] | undefined,
	topTenImpression?: GraphQLTypes["BannersDocAccessFields_topTenImpression"] | undefined,
	miscClick?: GraphQLTypes["BannersDocAccessFields_miscClick"] | undefined,
	miscImpression?: GraphQLTypes["BannersDocAccessFields_miscImpression"] | undefined,
	clickTotal?: GraphQLTypes["BannersDocAccessFields_clickTotal"] | undefined,
	totalImpression?: GraphQLTypes["BannersDocAccessFields_totalImpression"] | undefined,
	updatedAt?: GraphQLTypes["BannersDocAccessFields_updatedAt"] | undefined,
	createdAt?: GraphQLTypes["BannersDocAccessFields_createdAt"] | undefined
};
	["BannersDocAccessFields_name"]: {
	__typename: "BannersDocAccessFields_name",
	create?: GraphQLTypes["BannersDocAccessFields_name_Create"] | undefined,
	read?: GraphQLTypes["BannersDocAccessFields_name_Read"] | undefined,
	update?: GraphQLTypes["BannersDocAccessFields_name_Update"] | undefined,
	delete?: GraphQLTypes["BannersDocAccessFields_name_Delete"] | undefined
};
	["BannersDocAccessFields_name_Create"]: {
	__typename: "BannersDocAccessFields_name_Create",
	permission: boolean
};
	["BannersDocAccessFields_name_Read"]: {
	__typename: "BannersDocAccessFields_name_Read",
	permission: boolean
};
	["BannersDocAccessFields_name_Update"]: {
	__typename: "BannersDocAccessFields_name_Update",
	permission: boolean
};
	["BannersDocAccessFields_name_Delete"]: {
	__typename: "BannersDocAccessFields_name_Delete",
	permission: boolean
};
	["BannersDocAccessFields_desktop"]: {
	__typename: "BannersDocAccessFields_desktop",
	create?: GraphQLTypes["BannersDocAccessFields_desktop_Create"] | undefined,
	read?: GraphQLTypes["BannersDocAccessFields_desktop_Read"] | undefined,
	update?: GraphQLTypes["BannersDocAccessFields_desktop_Update"] | undefined,
	delete?: GraphQLTypes["BannersDocAccessFields_desktop_Delete"] | undefined
};
	["BannersDocAccessFields_desktop_Create"]: {
	__typename: "BannersDocAccessFields_desktop_Create",
	permission: boolean
};
	["BannersDocAccessFields_desktop_Read"]: {
	__typename: "BannersDocAccessFields_desktop_Read",
	permission: boolean
};
	["BannersDocAccessFields_desktop_Update"]: {
	__typename: "BannersDocAccessFields_desktop_Update",
	permission: boolean
};
	["BannersDocAccessFields_desktop_Delete"]: {
	__typename: "BannersDocAccessFields_desktop_Delete",
	permission: boolean
};
	["BannersDocAccessFields_mobile"]: {
	__typename: "BannersDocAccessFields_mobile",
	create?: GraphQLTypes["BannersDocAccessFields_mobile_Create"] | undefined,
	read?: GraphQLTypes["BannersDocAccessFields_mobile_Read"] | undefined,
	update?: GraphQLTypes["BannersDocAccessFields_mobile_Update"] | undefined,
	delete?: GraphQLTypes["BannersDocAccessFields_mobile_Delete"] | undefined
};
	["BannersDocAccessFields_mobile_Create"]: {
	__typename: "BannersDocAccessFields_mobile_Create",
	permission: boolean
};
	["BannersDocAccessFields_mobile_Read"]: {
	__typename: "BannersDocAccessFields_mobile_Read",
	permission: boolean
};
	["BannersDocAccessFields_mobile_Update"]: {
	__typename: "BannersDocAccessFields_mobile_Update",
	permission: boolean
};
	["BannersDocAccessFields_mobile_Delete"]: {
	__typename: "BannersDocAccessFields_mobile_Delete",
	permission: boolean
};
	["BannersDocAccessFields_bannerStartDate"]: {
	__typename: "BannersDocAccessFields_bannerStartDate",
	create?: GraphQLTypes["BannersDocAccessFields_bannerStartDate_Create"] | undefined,
	read?: GraphQLTypes["BannersDocAccessFields_bannerStartDate_Read"] | undefined,
	update?: GraphQLTypes["BannersDocAccessFields_bannerStartDate_Update"] | undefined,
	delete?: GraphQLTypes["BannersDocAccessFields_bannerStartDate_Delete"] | undefined
};
	["BannersDocAccessFields_bannerStartDate_Create"]: {
	__typename: "BannersDocAccessFields_bannerStartDate_Create",
	permission: boolean
};
	["BannersDocAccessFields_bannerStartDate_Read"]: {
	__typename: "BannersDocAccessFields_bannerStartDate_Read",
	permission: boolean
};
	["BannersDocAccessFields_bannerStartDate_Update"]: {
	__typename: "BannersDocAccessFields_bannerStartDate_Update",
	permission: boolean
};
	["BannersDocAccessFields_bannerStartDate_Delete"]: {
	__typename: "BannersDocAccessFields_bannerStartDate_Delete",
	permission: boolean
};
	["BannersDocAccessFields_bannerEndDate"]: {
	__typename: "BannersDocAccessFields_bannerEndDate",
	create?: GraphQLTypes["BannersDocAccessFields_bannerEndDate_Create"] | undefined,
	read?: GraphQLTypes["BannersDocAccessFields_bannerEndDate_Read"] | undefined,
	update?: GraphQLTypes["BannersDocAccessFields_bannerEndDate_Update"] | undefined,
	delete?: GraphQLTypes["BannersDocAccessFields_bannerEndDate_Delete"] | undefined
};
	["BannersDocAccessFields_bannerEndDate_Create"]: {
	__typename: "BannersDocAccessFields_bannerEndDate_Create",
	permission: boolean
};
	["BannersDocAccessFields_bannerEndDate_Read"]: {
	__typename: "BannersDocAccessFields_bannerEndDate_Read",
	permission: boolean
};
	["BannersDocAccessFields_bannerEndDate_Update"]: {
	__typename: "BannersDocAccessFields_bannerEndDate_Update",
	permission: boolean
};
	["BannersDocAccessFields_bannerEndDate_Delete"]: {
	__typename: "BannersDocAccessFields_bannerEndDate_Delete",
	permission: boolean
};
	["BannersDocAccessFields_location"]: {
	__typename: "BannersDocAccessFields_location",
	create?: GraphQLTypes["BannersDocAccessFields_location_Create"] | undefined,
	read?: GraphQLTypes["BannersDocAccessFields_location_Read"] | undefined,
	update?: GraphQLTypes["BannersDocAccessFields_location_Update"] | undefined,
	delete?: GraphQLTypes["BannersDocAccessFields_location_Delete"] | undefined,
	fields?: GraphQLTypes["BannersDocAccessFields_location_Fields"] | undefined
};
	["BannersDocAccessFields_location_Create"]: {
	__typename: "BannersDocAccessFields_location_Create",
	permission: boolean
};
	["BannersDocAccessFields_location_Read"]: {
	__typename: "BannersDocAccessFields_location_Read",
	permission: boolean
};
	["BannersDocAccessFields_location_Update"]: {
	__typename: "BannersDocAccessFields_location_Update",
	permission: boolean
};
	["BannersDocAccessFields_location_Delete"]: {
	__typename: "BannersDocAccessFields_location_Delete",
	permission: boolean
};
	["BannersDocAccessFields_location_Fields"]: {
	__typename: "BannersDocAccessFields_location_Fields",
	page?: GraphQLTypes["BannersDocAccessFields_location_page"] | undefined,
	eventRankingList?: GraphQLTypes["BannersDocAccessFields_location_eventRankingList"] | undefined,
	locationRankingList?: GraphQLTypes["BannersDocAccessFields_location_locationRankingList"] | undefined,
	categories?: GraphQLTypes["BannersDocAccessFields_location_categories"] | undefined,
	time?: GraphQLTypes["BannersDocAccessFields_location_time"] | undefined,
	district?: GraphQLTypes["BannersDocAccessFields_location_district"] | undefined,
	audience?: GraphQLTypes["BannersDocAccessFields_location_audience"] | undefined,
	multipleCriteriaSearch?: GraphQLTypes["BannersDocAccessFields_location_multipleCriteriaSearch"] | undefined
};
	["BannersDocAccessFields_location_page"]: {
	__typename: "BannersDocAccessFields_location_page",
	create?: GraphQLTypes["BannersDocAccessFields_location_page_Create"] | undefined,
	read?: GraphQLTypes["BannersDocAccessFields_location_page_Read"] | undefined,
	update?: GraphQLTypes["BannersDocAccessFields_location_page_Update"] | undefined,
	delete?: GraphQLTypes["BannersDocAccessFields_location_page_Delete"] | undefined
};
	["BannersDocAccessFields_location_page_Create"]: {
	__typename: "BannersDocAccessFields_location_page_Create",
	permission: boolean
};
	["BannersDocAccessFields_location_page_Read"]: {
	__typename: "BannersDocAccessFields_location_page_Read",
	permission: boolean
};
	["BannersDocAccessFields_location_page_Update"]: {
	__typename: "BannersDocAccessFields_location_page_Update",
	permission: boolean
};
	["BannersDocAccessFields_location_page_Delete"]: {
	__typename: "BannersDocAccessFields_location_page_Delete",
	permission: boolean
};
	["BannersDocAccessFields_location_eventRankingList"]: {
	__typename: "BannersDocAccessFields_location_eventRankingList",
	create?: GraphQLTypes["BannersDocAccessFields_location_eventRankingList_Create"] | undefined,
	read?: GraphQLTypes["BannersDocAccessFields_location_eventRankingList_Read"] | undefined,
	update?: GraphQLTypes["BannersDocAccessFields_location_eventRankingList_Update"] | undefined,
	delete?: GraphQLTypes["BannersDocAccessFields_location_eventRankingList_Delete"] | undefined
};
	["BannersDocAccessFields_location_eventRankingList_Create"]: {
	__typename: "BannersDocAccessFields_location_eventRankingList_Create",
	permission: boolean
};
	["BannersDocAccessFields_location_eventRankingList_Read"]: {
	__typename: "BannersDocAccessFields_location_eventRankingList_Read",
	permission: boolean
};
	["BannersDocAccessFields_location_eventRankingList_Update"]: {
	__typename: "BannersDocAccessFields_location_eventRankingList_Update",
	permission: boolean
};
	["BannersDocAccessFields_location_eventRankingList_Delete"]: {
	__typename: "BannersDocAccessFields_location_eventRankingList_Delete",
	permission: boolean
};
	["BannersDocAccessFields_location_locationRankingList"]: {
	__typename: "BannersDocAccessFields_location_locationRankingList",
	create?: GraphQLTypes["BannersDocAccessFields_location_locationRankingList_Create"] | undefined,
	read?: GraphQLTypes["BannersDocAccessFields_location_locationRankingList_Read"] | undefined,
	update?: GraphQLTypes["BannersDocAccessFields_location_locationRankingList_Update"] | undefined,
	delete?: GraphQLTypes["BannersDocAccessFields_location_locationRankingList_Delete"] | undefined
};
	["BannersDocAccessFields_location_locationRankingList_Create"]: {
	__typename: "BannersDocAccessFields_location_locationRankingList_Create",
	permission: boolean
};
	["BannersDocAccessFields_location_locationRankingList_Read"]: {
	__typename: "BannersDocAccessFields_location_locationRankingList_Read",
	permission: boolean
};
	["BannersDocAccessFields_location_locationRankingList_Update"]: {
	__typename: "BannersDocAccessFields_location_locationRankingList_Update",
	permission: boolean
};
	["BannersDocAccessFields_location_locationRankingList_Delete"]: {
	__typename: "BannersDocAccessFields_location_locationRankingList_Delete",
	permission: boolean
};
	["BannersDocAccessFields_location_categories"]: {
	__typename: "BannersDocAccessFields_location_categories",
	create?: GraphQLTypes["BannersDocAccessFields_location_categories_Create"] | undefined,
	read?: GraphQLTypes["BannersDocAccessFields_location_categories_Read"] | undefined,
	update?: GraphQLTypes["BannersDocAccessFields_location_categories_Update"] | undefined,
	delete?: GraphQLTypes["BannersDocAccessFields_location_categories_Delete"] | undefined
};
	["BannersDocAccessFields_location_categories_Create"]: {
	__typename: "BannersDocAccessFields_location_categories_Create",
	permission: boolean
};
	["BannersDocAccessFields_location_categories_Read"]: {
	__typename: "BannersDocAccessFields_location_categories_Read",
	permission: boolean
};
	["BannersDocAccessFields_location_categories_Update"]: {
	__typename: "BannersDocAccessFields_location_categories_Update",
	permission: boolean
};
	["BannersDocAccessFields_location_categories_Delete"]: {
	__typename: "BannersDocAccessFields_location_categories_Delete",
	permission: boolean
};
	["BannersDocAccessFields_location_time"]: {
	__typename: "BannersDocAccessFields_location_time",
	create?: GraphQLTypes["BannersDocAccessFields_location_time_Create"] | undefined,
	read?: GraphQLTypes["BannersDocAccessFields_location_time_Read"] | undefined,
	update?: GraphQLTypes["BannersDocAccessFields_location_time_Update"] | undefined,
	delete?: GraphQLTypes["BannersDocAccessFields_location_time_Delete"] | undefined
};
	["BannersDocAccessFields_location_time_Create"]: {
	__typename: "BannersDocAccessFields_location_time_Create",
	permission: boolean
};
	["BannersDocAccessFields_location_time_Read"]: {
	__typename: "BannersDocAccessFields_location_time_Read",
	permission: boolean
};
	["BannersDocAccessFields_location_time_Update"]: {
	__typename: "BannersDocAccessFields_location_time_Update",
	permission: boolean
};
	["BannersDocAccessFields_location_time_Delete"]: {
	__typename: "BannersDocAccessFields_location_time_Delete",
	permission: boolean
};
	["BannersDocAccessFields_location_district"]: {
	__typename: "BannersDocAccessFields_location_district",
	create?: GraphQLTypes["BannersDocAccessFields_location_district_Create"] | undefined,
	read?: GraphQLTypes["BannersDocAccessFields_location_district_Read"] | undefined,
	update?: GraphQLTypes["BannersDocAccessFields_location_district_Update"] | undefined,
	delete?: GraphQLTypes["BannersDocAccessFields_location_district_Delete"] | undefined
};
	["BannersDocAccessFields_location_district_Create"]: {
	__typename: "BannersDocAccessFields_location_district_Create",
	permission: boolean
};
	["BannersDocAccessFields_location_district_Read"]: {
	__typename: "BannersDocAccessFields_location_district_Read",
	permission: boolean
};
	["BannersDocAccessFields_location_district_Update"]: {
	__typename: "BannersDocAccessFields_location_district_Update",
	permission: boolean
};
	["BannersDocAccessFields_location_district_Delete"]: {
	__typename: "BannersDocAccessFields_location_district_Delete",
	permission: boolean
};
	["BannersDocAccessFields_location_audience"]: {
	__typename: "BannersDocAccessFields_location_audience",
	create?: GraphQLTypes["BannersDocAccessFields_location_audience_Create"] | undefined,
	read?: GraphQLTypes["BannersDocAccessFields_location_audience_Read"] | undefined,
	update?: GraphQLTypes["BannersDocAccessFields_location_audience_Update"] | undefined,
	delete?: GraphQLTypes["BannersDocAccessFields_location_audience_Delete"] | undefined
};
	["BannersDocAccessFields_location_audience_Create"]: {
	__typename: "BannersDocAccessFields_location_audience_Create",
	permission: boolean
};
	["BannersDocAccessFields_location_audience_Read"]: {
	__typename: "BannersDocAccessFields_location_audience_Read",
	permission: boolean
};
	["BannersDocAccessFields_location_audience_Update"]: {
	__typename: "BannersDocAccessFields_location_audience_Update",
	permission: boolean
};
	["BannersDocAccessFields_location_audience_Delete"]: {
	__typename: "BannersDocAccessFields_location_audience_Delete",
	permission: boolean
};
	["BannersDocAccessFields_location_multipleCriteriaSearch"]: {
	__typename: "BannersDocAccessFields_location_multipleCriteriaSearch",
	create?: GraphQLTypes["BannersDocAccessFields_location_multipleCriteriaSearch_Create"] | undefined,
	read?: GraphQLTypes["BannersDocAccessFields_location_multipleCriteriaSearch_Read"] | undefined,
	update?: GraphQLTypes["BannersDocAccessFields_location_multipleCriteriaSearch_Update"] | undefined,
	delete?: GraphQLTypes["BannersDocAccessFields_location_multipleCriteriaSearch_Delete"] | undefined
};
	["BannersDocAccessFields_location_multipleCriteriaSearch_Create"]: {
	__typename: "BannersDocAccessFields_location_multipleCriteriaSearch_Create",
	permission: boolean
};
	["BannersDocAccessFields_location_multipleCriteriaSearch_Read"]: {
	__typename: "BannersDocAccessFields_location_multipleCriteriaSearch_Read",
	permission: boolean
};
	["BannersDocAccessFields_location_multipleCriteriaSearch_Update"]: {
	__typename: "BannersDocAccessFields_location_multipleCriteriaSearch_Update",
	permission: boolean
};
	["BannersDocAccessFields_location_multipleCriteriaSearch_Delete"]: {
	__typename: "BannersDocAccessFields_location_multipleCriteriaSearch_Delete",
	permission: boolean
};
	["BannersDocAccessFields_link"]: {
	__typename: "BannersDocAccessFields_link",
	create?: GraphQLTypes["BannersDocAccessFields_link_Create"] | undefined,
	read?: GraphQLTypes["BannersDocAccessFields_link_Read"] | undefined,
	update?: GraphQLTypes["BannersDocAccessFields_link_Update"] | undefined,
	delete?: GraphQLTypes["BannersDocAccessFields_link_Delete"] | undefined
};
	["BannersDocAccessFields_link_Create"]: {
	__typename: "BannersDocAccessFields_link_Create",
	permission: boolean
};
	["BannersDocAccessFields_link_Read"]: {
	__typename: "BannersDocAccessFields_link_Read",
	permission: boolean
};
	["BannersDocAccessFields_link_Update"]: {
	__typename: "BannersDocAccessFields_link_Update",
	permission: boolean
};
	["BannersDocAccessFields_link_Delete"]: {
	__typename: "BannersDocAccessFields_link_Delete",
	permission: boolean
};
	["BannersDocAccessFields_external"]: {
	__typename: "BannersDocAccessFields_external",
	create?: GraphQLTypes["BannersDocAccessFields_external_Create"] | undefined,
	read?: GraphQLTypes["BannersDocAccessFields_external_Read"] | undefined,
	update?: GraphQLTypes["BannersDocAccessFields_external_Update"] | undefined,
	delete?: GraphQLTypes["BannersDocAccessFields_external_Delete"] | undefined
};
	["BannersDocAccessFields_external_Create"]: {
	__typename: "BannersDocAccessFields_external_Create",
	permission: boolean
};
	["BannersDocAccessFields_external_Read"]: {
	__typename: "BannersDocAccessFields_external_Read",
	permission: boolean
};
	["BannersDocAccessFields_external_Update"]: {
	__typename: "BannersDocAccessFields_external_Update",
	permission: boolean
};
	["BannersDocAccessFields_external_Delete"]: {
	__typename: "BannersDocAccessFields_external_Delete",
	permission: boolean
};
	["BannersDocAccessFields_type"]: {
	__typename: "BannersDocAccessFields_type",
	create?: GraphQLTypes["BannersDocAccessFields_type_Create"] | undefined,
	read?: GraphQLTypes["BannersDocAccessFields_type_Read"] | undefined,
	update?: GraphQLTypes["BannersDocAccessFields_type_Update"] | undefined,
	delete?: GraphQLTypes["BannersDocAccessFields_type_Delete"] | undefined
};
	["BannersDocAccessFields_type_Create"]: {
	__typename: "BannersDocAccessFields_type_Create",
	permission: boolean
};
	["BannersDocAccessFields_type_Read"]: {
	__typename: "BannersDocAccessFields_type_Read",
	permission: boolean
};
	["BannersDocAccessFields_type_Update"]: {
	__typename: "BannersDocAccessFields_type_Update",
	permission: boolean
};
	["BannersDocAccessFields_type_Delete"]: {
	__typename: "BannersDocAccessFields_type_Delete",
	permission: boolean
};
	["BannersDocAccessFields_status"]: {
	__typename: "BannersDocAccessFields_status",
	create?: GraphQLTypes["BannersDocAccessFields_status_Create"] | undefined,
	read?: GraphQLTypes["BannersDocAccessFields_status_Read"] | undefined,
	update?: GraphQLTypes["BannersDocAccessFields_status_Update"] | undefined,
	delete?: GraphQLTypes["BannersDocAccessFields_status_Delete"] | undefined
};
	["BannersDocAccessFields_status_Create"]: {
	__typename: "BannersDocAccessFields_status_Create",
	permission: boolean
};
	["BannersDocAccessFields_status_Read"]: {
	__typename: "BannersDocAccessFields_status_Read",
	permission: boolean
};
	["BannersDocAccessFields_status_Update"]: {
	__typename: "BannersDocAccessFields_status_Update",
	permission: boolean
};
	["BannersDocAccessFields_status_Delete"]: {
	__typename: "BannersDocAccessFields_status_Delete",
	permission: boolean
};
	["BannersDocAccessFields_advertise"]: {
	__typename: "BannersDocAccessFields_advertise",
	create?: GraphQLTypes["BannersDocAccessFields_advertise_Create"] | undefined,
	read?: GraphQLTypes["BannersDocAccessFields_advertise_Read"] | undefined,
	update?: GraphQLTypes["BannersDocAccessFields_advertise_Update"] | undefined,
	delete?: GraphQLTypes["BannersDocAccessFields_advertise_Delete"] | undefined,
	fields?: GraphQLTypes["BannersDocAccessFields_advertise_Fields"] | undefined
};
	["BannersDocAccessFields_advertise_Create"]: {
	__typename: "BannersDocAccessFields_advertise_Create",
	permission: boolean
};
	["BannersDocAccessFields_advertise_Read"]: {
	__typename: "BannersDocAccessFields_advertise_Read",
	permission: boolean
};
	["BannersDocAccessFields_advertise_Update"]: {
	__typename: "BannersDocAccessFields_advertise_Update",
	permission: boolean
};
	["BannersDocAccessFields_advertise_Delete"]: {
	__typename: "BannersDocAccessFields_advertise_Delete",
	permission: boolean
};
	["BannersDocAccessFields_advertise_Fields"]: {
	__typename: "BannersDocAccessFields_advertise_Fields",
	campaign?: GraphQLTypes["BannersDocAccessFields_advertise_campaign"] | undefined,
	invoiceDescription?: GraphQLTypes["BannersDocAccessFields_advertise_invoiceDescription"] | undefined,
	standardPrice?: GraphQLTypes["BannersDocAccessFields_advertise_standardPrice"] | undefined,
	discount?: GraphQLTypes["BannersDocAccessFields_advertise_discount"] | undefined,
	timableCost?: GraphQLTypes["BannersDocAccessFields_advertise_timableCost"] | undefined,
	remark?: GraphQLTypes["BannersDocAccessFields_advertise_remark"] | undefined,
	public?: GraphQLTypes["BannersDocAccessFields_advertise_public"] | undefined
};
	["BannersDocAccessFields_advertise_campaign"]: {
	__typename: "BannersDocAccessFields_advertise_campaign",
	create?: GraphQLTypes["BannersDocAccessFields_advertise_campaign_Create"] | undefined,
	read?: GraphQLTypes["BannersDocAccessFields_advertise_campaign_Read"] | undefined,
	update?: GraphQLTypes["BannersDocAccessFields_advertise_campaign_Update"] | undefined,
	delete?: GraphQLTypes["BannersDocAccessFields_advertise_campaign_Delete"] | undefined
};
	["BannersDocAccessFields_advertise_campaign_Create"]: {
	__typename: "BannersDocAccessFields_advertise_campaign_Create",
	permission: boolean
};
	["BannersDocAccessFields_advertise_campaign_Read"]: {
	__typename: "BannersDocAccessFields_advertise_campaign_Read",
	permission: boolean
};
	["BannersDocAccessFields_advertise_campaign_Update"]: {
	__typename: "BannersDocAccessFields_advertise_campaign_Update",
	permission: boolean
};
	["BannersDocAccessFields_advertise_campaign_Delete"]: {
	__typename: "BannersDocAccessFields_advertise_campaign_Delete",
	permission: boolean
};
	["BannersDocAccessFields_advertise_invoiceDescription"]: {
	__typename: "BannersDocAccessFields_advertise_invoiceDescription",
	create?: GraphQLTypes["BannersDocAccessFields_advertise_invoiceDescription_Create"] | undefined,
	read?: GraphQLTypes["BannersDocAccessFields_advertise_invoiceDescription_Read"] | undefined,
	update?: GraphQLTypes["BannersDocAccessFields_advertise_invoiceDescription_Update"] | undefined,
	delete?: GraphQLTypes["BannersDocAccessFields_advertise_invoiceDescription_Delete"] | undefined
};
	["BannersDocAccessFields_advertise_invoiceDescription_Create"]: {
	__typename: "BannersDocAccessFields_advertise_invoiceDescription_Create",
	permission: boolean
};
	["BannersDocAccessFields_advertise_invoiceDescription_Read"]: {
	__typename: "BannersDocAccessFields_advertise_invoiceDescription_Read",
	permission: boolean
};
	["BannersDocAccessFields_advertise_invoiceDescription_Update"]: {
	__typename: "BannersDocAccessFields_advertise_invoiceDescription_Update",
	permission: boolean
};
	["BannersDocAccessFields_advertise_invoiceDescription_Delete"]: {
	__typename: "BannersDocAccessFields_advertise_invoiceDescription_Delete",
	permission: boolean
};
	["BannersDocAccessFields_advertise_standardPrice"]: {
	__typename: "BannersDocAccessFields_advertise_standardPrice",
	create?: GraphQLTypes["BannersDocAccessFields_advertise_standardPrice_Create"] | undefined,
	read?: GraphQLTypes["BannersDocAccessFields_advertise_standardPrice_Read"] | undefined,
	update?: GraphQLTypes["BannersDocAccessFields_advertise_standardPrice_Update"] | undefined,
	delete?: GraphQLTypes["BannersDocAccessFields_advertise_standardPrice_Delete"] | undefined
};
	["BannersDocAccessFields_advertise_standardPrice_Create"]: {
	__typename: "BannersDocAccessFields_advertise_standardPrice_Create",
	permission: boolean
};
	["BannersDocAccessFields_advertise_standardPrice_Read"]: {
	__typename: "BannersDocAccessFields_advertise_standardPrice_Read",
	permission: boolean
};
	["BannersDocAccessFields_advertise_standardPrice_Update"]: {
	__typename: "BannersDocAccessFields_advertise_standardPrice_Update",
	permission: boolean
};
	["BannersDocAccessFields_advertise_standardPrice_Delete"]: {
	__typename: "BannersDocAccessFields_advertise_standardPrice_Delete",
	permission: boolean
};
	["BannersDocAccessFields_advertise_discount"]: {
	__typename: "BannersDocAccessFields_advertise_discount",
	create?: GraphQLTypes["BannersDocAccessFields_advertise_discount_Create"] | undefined,
	read?: GraphQLTypes["BannersDocAccessFields_advertise_discount_Read"] | undefined,
	update?: GraphQLTypes["BannersDocAccessFields_advertise_discount_Update"] | undefined,
	delete?: GraphQLTypes["BannersDocAccessFields_advertise_discount_Delete"] | undefined
};
	["BannersDocAccessFields_advertise_discount_Create"]: {
	__typename: "BannersDocAccessFields_advertise_discount_Create",
	permission: boolean
};
	["BannersDocAccessFields_advertise_discount_Read"]: {
	__typename: "BannersDocAccessFields_advertise_discount_Read",
	permission: boolean
};
	["BannersDocAccessFields_advertise_discount_Update"]: {
	__typename: "BannersDocAccessFields_advertise_discount_Update",
	permission: boolean
};
	["BannersDocAccessFields_advertise_discount_Delete"]: {
	__typename: "BannersDocAccessFields_advertise_discount_Delete",
	permission: boolean
};
	["BannersDocAccessFields_advertise_timableCost"]: {
	__typename: "BannersDocAccessFields_advertise_timableCost",
	create?: GraphQLTypes["BannersDocAccessFields_advertise_timableCost_Create"] | undefined,
	read?: GraphQLTypes["BannersDocAccessFields_advertise_timableCost_Read"] | undefined,
	update?: GraphQLTypes["BannersDocAccessFields_advertise_timableCost_Update"] | undefined,
	delete?: GraphQLTypes["BannersDocAccessFields_advertise_timableCost_Delete"] | undefined
};
	["BannersDocAccessFields_advertise_timableCost_Create"]: {
	__typename: "BannersDocAccessFields_advertise_timableCost_Create",
	permission: boolean
};
	["BannersDocAccessFields_advertise_timableCost_Read"]: {
	__typename: "BannersDocAccessFields_advertise_timableCost_Read",
	permission: boolean
};
	["BannersDocAccessFields_advertise_timableCost_Update"]: {
	__typename: "BannersDocAccessFields_advertise_timableCost_Update",
	permission: boolean
};
	["BannersDocAccessFields_advertise_timableCost_Delete"]: {
	__typename: "BannersDocAccessFields_advertise_timableCost_Delete",
	permission: boolean
};
	["BannersDocAccessFields_advertise_remark"]: {
	__typename: "BannersDocAccessFields_advertise_remark",
	create?: GraphQLTypes["BannersDocAccessFields_advertise_remark_Create"] | undefined,
	read?: GraphQLTypes["BannersDocAccessFields_advertise_remark_Read"] | undefined,
	update?: GraphQLTypes["BannersDocAccessFields_advertise_remark_Update"] | undefined,
	delete?: GraphQLTypes["BannersDocAccessFields_advertise_remark_Delete"] | undefined
};
	["BannersDocAccessFields_advertise_remark_Create"]: {
	__typename: "BannersDocAccessFields_advertise_remark_Create",
	permission: boolean
};
	["BannersDocAccessFields_advertise_remark_Read"]: {
	__typename: "BannersDocAccessFields_advertise_remark_Read",
	permission: boolean
};
	["BannersDocAccessFields_advertise_remark_Update"]: {
	__typename: "BannersDocAccessFields_advertise_remark_Update",
	permission: boolean
};
	["BannersDocAccessFields_advertise_remark_Delete"]: {
	__typename: "BannersDocAccessFields_advertise_remark_Delete",
	permission: boolean
};
	["BannersDocAccessFields_advertise_public"]: {
	__typename: "BannersDocAccessFields_advertise_public",
	create?: GraphQLTypes["BannersDocAccessFields_advertise_public_Create"] | undefined,
	read?: GraphQLTypes["BannersDocAccessFields_advertise_public_Read"] | undefined,
	update?: GraphQLTypes["BannersDocAccessFields_advertise_public_Update"] | undefined,
	delete?: GraphQLTypes["BannersDocAccessFields_advertise_public_Delete"] | undefined
};
	["BannersDocAccessFields_advertise_public_Create"]: {
	__typename: "BannersDocAccessFields_advertise_public_Create",
	permission: boolean
};
	["BannersDocAccessFields_advertise_public_Read"]: {
	__typename: "BannersDocAccessFields_advertise_public_Read",
	permission: boolean
};
	["BannersDocAccessFields_advertise_public_Update"]: {
	__typename: "BannersDocAccessFields_advertise_public_Update",
	permission: boolean
};
	["BannersDocAccessFields_advertise_public_Delete"]: {
	__typename: "BannersDocAccessFields_advertise_public_Delete",
	permission: boolean
};
	["BannersDocAccessFields__locations"]: {
	__typename: "BannersDocAccessFields__locations",
	create?: GraphQLTypes["BannersDocAccessFields__locations_Create"] | undefined,
	read?: GraphQLTypes["BannersDocAccessFields__locations_Read"] | undefined,
	update?: GraphQLTypes["BannersDocAccessFields__locations_Update"] | undefined,
	delete?: GraphQLTypes["BannersDocAccessFields__locations_Delete"] | undefined
};
	["BannersDocAccessFields__locations_Create"]: {
	__typename: "BannersDocAccessFields__locations_Create",
	permission: boolean
};
	["BannersDocAccessFields__locations_Read"]: {
	__typename: "BannersDocAccessFields__locations_Read",
	permission: boolean
};
	["BannersDocAccessFields__locations_Update"]: {
	__typename: "BannersDocAccessFields__locations_Update",
	permission: boolean
};
	["BannersDocAccessFields__locations_Delete"]: {
	__typename: "BannersDocAccessFields__locations_Delete",
	permission: boolean
};
	["BannersDocAccessFields_region"]: {
	__typename: "BannersDocAccessFields_region",
	create?: GraphQLTypes["BannersDocAccessFields_region_Create"] | undefined,
	read?: GraphQLTypes["BannersDocAccessFields_region_Read"] | undefined,
	update?: GraphQLTypes["BannersDocAccessFields_region_Update"] | undefined,
	delete?: GraphQLTypes["BannersDocAccessFields_region_Delete"] | undefined
};
	["BannersDocAccessFields_region_Create"]: {
	__typename: "BannersDocAccessFields_region_Create",
	permission: boolean
};
	["BannersDocAccessFields_region_Read"]: {
	__typename: "BannersDocAccessFields_region_Read",
	permission: boolean
};
	["BannersDocAccessFields_region_Update"]: {
	__typename: "BannersDocAccessFields_region_Update",
	permission: boolean
};
	["BannersDocAccessFields_region_Delete"]: {
	__typename: "BannersDocAccessFields_region_Delete",
	permission: boolean
};
	["BannersDocAccessFields_homePageClick"]: {
	__typename: "BannersDocAccessFields_homePageClick",
	create?: GraphQLTypes["BannersDocAccessFields_homePageClick_Create"] | undefined,
	read?: GraphQLTypes["BannersDocAccessFields_homePageClick_Read"] | undefined,
	update?: GraphQLTypes["BannersDocAccessFields_homePageClick_Update"] | undefined,
	delete?: GraphQLTypes["BannersDocAccessFields_homePageClick_Delete"] | undefined
};
	["BannersDocAccessFields_homePageClick_Create"]: {
	__typename: "BannersDocAccessFields_homePageClick_Create",
	permission: boolean
};
	["BannersDocAccessFields_homePageClick_Read"]: {
	__typename: "BannersDocAccessFields_homePageClick_Read",
	permission: boolean
};
	["BannersDocAccessFields_homePageClick_Update"]: {
	__typename: "BannersDocAccessFields_homePageClick_Update",
	permission: boolean
};
	["BannersDocAccessFields_homePageClick_Delete"]: {
	__typename: "BannersDocAccessFields_homePageClick_Delete",
	permission: boolean
};
	["BannersDocAccessFields_homePageImpression"]: {
	__typename: "BannersDocAccessFields_homePageImpression",
	create?: GraphQLTypes["BannersDocAccessFields_homePageImpression_Create"] | undefined,
	read?: GraphQLTypes["BannersDocAccessFields_homePageImpression_Read"] | undefined,
	update?: GraphQLTypes["BannersDocAccessFields_homePageImpression_Update"] | undefined,
	delete?: GraphQLTypes["BannersDocAccessFields_homePageImpression_Delete"] | undefined
};
	["BannersDocAccessFields_homePageImpression_Create"]: {
	__typename: "BannersDocAccessFields_homePageImpression_Create",
	permission: boolean
};
	["BannersDocAccessFields_homePageImpression_Read"]: {
	__typename: "BannersDocAccessFields_homePageImpression_Read",
	permission: boolean
};
	["BannersDocAccessFields_homePageImpression_Update"]: {
	__typename: "BannersDocAccessFields_homePageImpression_Update",
	permission: boolean
};
	["BannersDocAccessFields_homePageImpression_Delete"]: {
	__typename: "BannersDocAccessFields_homePageImpression_Delete",
	permission: boolean
};
	["BannersDocAccessFields_latestEventClick"]: {
	__typename: "BannersDocAccessFields_latestEventClick",
	create?: GraphQLTypes["BannersDocAccessFields_latestEventClick_Create"] | undefined,
	read?: GraphQLTypes["BannersDocAccessFields_latestEventClick_Read"] | undefined,
	update?: GraphQLTypes["BannersDocAccessFields_latestEventClick_Update"] | undefined,
	delete?: GraphQLTypes["BannersDocAccessFields_latestEventClick_Delete"] | undefined
};
	["BannersDocAccessFields_latestEventClick_Create"]: {
	__typename: "BannersDocAccessFields_latestEventClick_Create",
	permission: boolean
};
	["BannersDocAccessFields_latestEventClick_Read"]: {
	__typename: "BannersDocAccessFields_latestEventClick_Read",
	permission: boolean
};
	["BannersDocAccessFields_latestEventClick_Update"]: {
	__typename: "BannersDocAccessFields_latestEventClick_Update",
	permission: boolean
};
	["BannersDocAccessFields_latestEventClick_Delete"]: {
	__typename: "BannersDocAccessFields_latestEventClick_Delete",
	permission: boolean
};
	["BannersDocAccessFields_latestEventImpression"]: {
	__typename: "BannersDocAccessFields_latestEventImpression",
	create?: GraphQLTypes["BannersDocAccessFields_latestEventImpression_Create"] | undefined,
	read?: GraphQLTypes["BannersDocAccessFields_latestEventImpression_Read"] | undefined,
	update?: GraphQLTypes["BannersDocAccessFields_latestEventImpression_Update"] | undefined,
	delete?: GraphQLTypes["BannersDocAccessFields_latestEventImpression_Delete"] | undefined
};
	["BannersDocAccessFields_latestEventImpression_Create"]: {
	__typename: "BannersDocAccessFields_latestEventImpression_Create",
	permission: boolean
};
	["BannersDocAccessFields_latestEventImpression_Read"]: {
	__typename: "BannersDocAccessFields_latestEventImpression_Read",
	permission: boolean
};
	["BannersDocAccessFields_latestEventImpression_Update"]: {
	__typename: "BannersDocAccessFields_latestEventImpression_Update",
	permission: boolean
};
	["BannersDocAccessFields_latestEventImpression_Delete"]: {
	__typename: "BannersDocAccessFields_latestEventImpression_Delete",
	permission: boolean
};
	["BannersDocAccessFields_editorChoiceClick"]: {
	__typename: "BannersDocAccessFields_editorChoiceClick",
	create?: GraphQLTypes["BannersDocAccessFields_editorChoiceClick_Create"] | undefined,
	read?: GraphQLTypes["BannersDocAccessFields_editorChoiceClick_Read"] | undefined,
	update?: GraphQLTypes["BannersDocAccessFields_editorChoiceClick_Update"] | undefined,
	delete?: GraphQLTypes["BannersDocAccessFields_editorChoiceClick_Delete"] | undefined
};
	["BannersDocAccessFields_editorChoiceClick_Create"]: {
	__typename: "BannersDocAccessFields_editorChoiceClick_Create",
	permission: boolean
};
	["BannersDocAccessFields_editorChoiceClick_Read"]: {
	__typename: "BannersDocAccessFields_editorChoiceClick_Read",
	permission: boolean
};
	["BannersDocAccessFields_editorChoiceClick_Update"]: {
	__typename: "BannersDocAccessFields_editorChoiceClick_Update",
	permission: boolean
};
	["BannersDocAccessFields_editorChoiceClick_Delete"]: {
	__typename: "BannersDocAccessFields_editorChoiceClick_Delete",
	permission: boolean
};
	["BannersDocAccessFields_editorChoiceImpression"]: {
	__typename: "BannersDocAccessFields_editorChoiceImpression",
	create?: GraphQLTypes["BannersDocAccessFields_editorChoiceImpression_Create"] | undefined,
	read?: GraphQLTypes["BannersDocAccessFields_editorChoiceImpression_Read"] | undefined,
	update?: GraphQLTypes["BannersDocAccessFields_editorChoiceImpression_Update"] | undefined,
	delete?: GraphQLTypes["BannersDocAccessFields_editorChoiceImpression_Delete"] | undefined
};
	["BannersDocAccessFields_editorChoiceImpression_Create"]: {
	__typename: "BannersDocAccessFields_editorChoiceImpression_Create",
	permission: boolean
};
	["BannersDocAccessFields_editorChoiceImpression_Read"]: {
	__typename: "BannersDocAccessFields_editorChoiceImpression_Read",
	permission: boolean
};
	["BannersDocAccessFields_editorChoiceImpression_Update"]: {
	__typename: "BannersDocAccessFields_editorChoiceImpression_Update",
	permission: boolean
};
	["BannersDocAccessFields_editorChoiceImpression_Delete"]: {
	__typename: "BannersDocAccessFields_editorChoiceImpression_Delete",
	permission: boolean
};
	["BannersDocAccessFields_eventListClick"]: {
	__typename: "BannersDocAccessFields_eventListClick",
	create?: GraphQLTypes["BannersDocAccessFields_eventListClick_Create"] | undefined,
	read?: GraphQLTypes["BannersDocAccessFields_eventListClick_Read"] | undefined,
	update?: GraphQLTypes["BannersDocAccessFields_eventListClick_Update"] | undefined,
	delete?: GraphQLTypes["BannersDocAccessFields_eventListClick_Delete"] | undefined
};
	["BannersDocAccessFields_eventListClick_Create"]: {
	__typename: "BannersDocAccessFields_eventListClick_Create",
	permission: boolean
};
	["BannersDocAccessFields_eventListClick_Read"]: {
	__typename: "BannersDocAccessFields_eventListClick_Read",
	permission: boolean
};
	["BannersDocAccessFields_eventListClick_Update"]: {
	__typename: "BannersDocAccessFields_eventListClick_Update",
	permission: boolean
};
	["BannersDocAccessFields_eventListClick_Delete"]: {
	__typename: "BannersDocAccessFields_eventListClick_Delete",
	permission: boolean
};
	["BannersDocAccessFields_eventListImpression"]: {
	__typename: "BannersDocAccessFields_eventListImpression",
	create?: GraphQLTypes["BannersDocAccessFields_eventListImpression_Create"] | undefined,
	read?: GraphQLTypes["BannersDocAccessFields_eventListImpression_Read"] | undefined,
	update?: GraphQLTypes["BannersDocAccessFields_eventListImpression_Update"] | undefined,
	delete?: GraphQLTypes["BannersDocAccessFields_eventListImpression_Delete"] | undefined
};
	["BannersDocAccessFields_eventListImpression_Create"]: {
	__typename: "BannersDocAccessFields_eventListImpression_Create",
	permission: boolean
};
	["BannersDocAccessFields_eventListImpression_Read"]: {
	__typename: "BannersDocAccessFields_eventListImpression_Read",
	permission: boolean
};
	["BannersDocAccessFields_eventListImpression_Update"]: {
	__typename: "BannersDocAccessFields_eventListImpression_Update",
	permission: boolean
};
	["BannersDocAccessFields_eventListImpression_Delete"]: {
	__typename: "BannersDocAccessFields_eventListImpression_Delete",
	permission: boolean
};
	["BannersDocAccessFields_topTenClick"]: {
	__typename: "BannersDocAccessFields_topTenClick",
	create?: GraphQLTypes["BannersDocAccessFields_topTenClick_Create"] | undefined,
	read?: GraphQLTypes["BannersDocAccessFields_topTenClick_Read"] | undefined,
	update?: GraphQLTypes["BannersDocAccessFields_topTenClick_Update"] | undefined,
	delete?: GraphQLTypes["BannersDocAccessFields_topTenClick_Delete"] | undefined
};
	["BannersDocAccessFields_topTenClick_Create"]: {
	__typename: "BannersDocAccessFields_topTenClick_Create",
	permission: boolean
};
	["BannersDocAccessFields_topTenClick_Read"]: {
	__typename: "BannersDocAccessFields_topTenClick_Read",
	permission: boolean
};
	["BannersDocAccessFields_topTenClick_Update"]: {
	__typename: "BannersDocAccessFields_topTenClick_Update",
	permission: boolean
};
	["BannersDocAccessFields_topTenClick_Delete"]: {
	__typename: "BannersDocAccessFields_topTenClick_Delete",
	permission: boolean
};
	["BannersDocAccessFields_topTenImpression"]: {
	__typename: "BannersDocAccessFields_topTenImpression",
	create?: GraphQLTypes["BannersDocAccessFields_topTenImpression_Create"] | undefined,
	read?: GraphQLTypes["BannersDocAccessFields_topTenImpression_Read"] | undefined,
	update?: GraphQLTypes["BannersDocAccessFields_topTenImpression_Update"] | undefined,
	delete?: GraphQLTypes["BannersDocAccessFields_topTenImpression_Delete"] | undefined
};
	["BannersDocAccessFields_topTenImpression_Create"]: {
	__typename: "BannersDocAccessFields_topTenImpression_Create",
	permission: boolean
};
	["BannersDocAccessFields_topTenImpression_Read"]: {
	__typename: "BannersDocAccessFields_topTenImpression_Read",
	permission: boolean
};
	["BannersDocAccessFields_topTenImpression_Update"]: {
	__typename: "BannersDocAccessFields_topTenImpression_Update",
	permission: boolean
};
	["BannersDocAccessFields_topTenImpression_Delete"]: {
	__typename: "BannersDocAccessFields_topTenImpression_Delete",
	permission: boolean
};
	["BannersDocAccessFields_miscClick"]: {
	__typename: "BannersDocAccessFields_miscClick",
	create?: GraphQLTypes["BannersDocAccessFields_miscClick_Create"] | undefined,
	read?: GraphQLTypes["BannersDocAccessFields_miscClick_Read"] | undefined,
	update?: GraphQLTypes["BannersDocAccessFields_miscClick_Update"] | undefined,
	delete?: GraphQLTypes["BannersDocAccessFields_miscClick_Delete"] | undefined
};
	["BannersDocAccessFields_miscClick_Create"]: {
	__typename: "BannersDocAccessFields_miscClick_Create",
	permission: boolean
};
	["BannersDocAccessFields_miscClick_Read"]: {
	__typename: "BannersDocAccessFields_miscClick_Read",
	permission: boolean
};
	["BannersDocAccessFields_miscClick_Update"]: {
	__typename: "BannersDocAccessFields_miscClick_Update",
	permission: boolean
};
	["BannersDocAccessFields_miscClick_Delete"]: {
	__typename: "BannersDocAccessFields_miscClick_Delete",
	permission: boolean
};
	["BannersDocAccessFields_miscImpression"]: {
	__typename: "BannersDocAccessFields_miscImpression",
	create?: GraphQLTypes["BannersDocAccessFields_miscImpression_Create"] | undefined,
	read?: GraphQLTypes["BannersDocAccessFields_miscImpression_Read"] | undefined,
	update?: GraphQLTypes["BannersDocAccessFields_miscImpression_Update"] | undefined,
	delete?: GraphQLTypes["BannersDocAccessFields_miscImpression_Delete"] | undefined
};
	["BannersDocAccessFields_miscImpression_Create"]: {
	__typename: "BannersDocAccessFields_miscImpression_Create",
	permission: boolean
};
	["BannersDocAccessFields_miscImpression_Read"]: {
	__typename: "BannersDocAccessFields_miscImpression_Read",
	permission: boolean
};
	["BannersDocAccessFields_miscImpression_Update"]: {
	__typename: "BannersDocAccessFields_miscImpression_Update",
	permission: boolean
};
	["BannersDocAccessFields_miscImpression_Delete"]: {
	__typename: "BannersDocAccessFields_miscImpression_Delete",
	permission: boolean
};
	["BannersDocAccessFields_clickTotal"]: {
	__typename: "BannersDocAccessFields_clickTotal",
	create?: GraphQLTypes["BannersDocAccessFields_clickTotal_Create"] | undefined,
	read?: GraphQLTypes["BannersDocAccessFields_clickTotal_Read"] | undefined,
	update?: GraphQLTypes["BannersDocAccessFields_clickTotal_Update"] | undefined,
	delete?: GraphQLTypes["BannersDocAccessFields_clickTotal_Delete"] | undefined
};
	["BannersDocAccessFields_clickTotal_Create"]: {
	__typename: "BannersDocAccessFields_clickTotal_Create",
	permission: boolean
};
	["BannersDocAccessFields_clickTotal_Read"]: {
	__typename: "BannersDocAccessFields_clickTotal_Read",
	permission: boolean
};
	["BannersDocAccessFields_clickTotal_Update"]: {
	__typename: "BannersDocAccessFields_clickTotal_Update",
	permission: boolean
};
	["BannersDocAccessFields_clickTotal_Delete"]: {
	__typename: "BannersDocAccessFields_clickTotal_Delete",
	permission: boolean
};
	["BannersDocAccessFields_totalImpression"]: {
	__typename: "BannersDocAccessFields_totalImpression",
	create?: GraphQLTypes["BannersDocAccessFields_totalImpression_Create"] | undefined,
	read?: GraphQLTypes["BannersDocAccessFields_totalImpression_Read"] | undefined,
	update?: GraphQLTypes["BannersDocAccessFields_totalImpression_Update"] | undefined,
	delete?: GraphQLTypes["BannersDocAccessFields_totalImpression_Delete"] | undefined
};
	["BannersDocAccessFields_totalImpression_Create"]: {
	__typename: "BannersDocAccessFields_totalImpression_Create",
	permission: boolean
};
	["BannersDocAccessFields_totalImpression_Read"]: {
	__typename: "BannersDocAccessFields_totalImpression_Read",
	permission: boolean
};
	["BannersDocAccessFields_totalImpression_Update"]: {
	__typename: "BannersDocAccessFields_totalImpression_Update",
	permission: boolean
};
	["BannersDocAccessFields_totalImpression_Delete"]: {
	__typename: "BannersDocAccessFields_totalImpression_Delete",
	permission: boolean
};
	["BannersDocAccessFields_updatedAt"]: {
	__typename: "BannersDocAccessFields_updatedAt",
	create?: GraphQLTypes["BannersDocAccessFields_updatedAt_Create"] | undefined,
	read?: GraphQLTypes["BannersDocAccessFields_updatedAt_Read"] | undefined,
	update?: GraphQLTypes["BannersDocAccessFields_updatedAt_Update"] | undefined,
	delete?: GraphQLTypes["BannersDocAccessFields_updatedAt_Delete"] | undefined
};
	["BannersDocAccessFields_updatedAt_Create"]: {
	__typename: "BannersDocAccessFields_updatedAt_Create",
	permission: boolean
};
	["BannersDocAccessFields_updatedAt_Read"]: {
	__typename: "BannersDocAccessFields_updatedAt_Read",
	permission: boolean
};
	["BannersDocAccessFields_updatedAt_Update"]: {
	__typename: "BannersDocAccessFields_updatedAt_Update",
	permission: boolean
};
	["BannersDocAccessFields_updatedAt_Delete"]: {
	__typename: "BannersDocAccessFields_updatedAt_Delete",
	permission: boolean
};
	["BannersDocAccessFields_createdAt"]: {
	__typename: "BannersDocAccessFields_createdAt",
	create?: GraphQLTypes["BannersDocAccessFields_createdAt_Create"] | undefined,
	read?: GraphQLTypes["BannersDocAccessFields_createdAt_Read"] | undefined,
	update?: GraphQLTypes["BannersDocAccessFields_createdAt_Update"] | undefined,
	delete?: GraphQLTypes["BannersDocAccessFields_createdAt_Delete"] | undefined
};
	["BannersDocAccessFields_createdAt_Create"]: {
	__typename: "BannersDocAccessFields_createdAt_Create",
	permission: boolean
};
	["BannersDocAccessFields_createdAt_Read"]: {
	__typename: "BannersDocAccessFields_createdAt_Read",
	permission: boolean
};
	["BannersDocAccessFields_createdAt_Update"]: {
	__typename: "BannersDocAccessFields_createdAt_Update",
	permission: boolean
};
	["BannersDocAccessFields_createdAt_Delete"]: {
	__typename: "BannersDocAccessFields_createdAt_Delete",
	permission: boolean
};
	["BannersCreateDocAccess"]: {
	__typename: "BannersCreateDocAccess",
	permission: boolean,
	where?: GraphQLTypes["JSONObject"] | undefined
};
	["BannersReadDocAccess"]: {
	__typename: "BannersReadDocAccess",
	permission: boolean,
	where?: GraphQLTypes["JSONObject"] | undefined
};
	["BannersUpdateDocAccess"]: {
	__typename: "BannersUpdateDocAccess",
	permission: boolean,
	where?: GraphQLTypes["JSONObject"] | undefined
};
	["BannersDeleteDocAccess"]: {
	__typename: "BannersDeleteDocAccess",
	permission: boolean,
	where?: GraphQLTypes["JSONObject"] | undefined
};
	["Campaigns"]: {
	__typename: "Campaigns",
	docs?: Array<GraphQLTypes["Campaign"] | undefined> | undefined,
	hasNextPage?: boolean | undefined,
	hasPrevPage?: boolean | undefined,
	limit?: number | undefined,
	nextPage?: number | undefined,
	offset?: number | undefined,
	page?: number | undefined,
	pagingCounter?: number | undefined,
	prevPage?: number | undefined,
	totalDocs?: number | undefined,
	totalPages?: number | undefined
};
	["Campaign_where"]: {
		creator?: GraphQLTypes["Campaign_creator_Relation"] | undefined,
	invoiceNumber?: GraphQLTypes["Campaign_invoiceNumber_operator"] | undefined,
	quotationNumber?: GraphQLTypes["Campaign_quotationNumber_operator"] | undefined,
	campaignName?: GraphQLTypes["Campaign_campaignName_operator"] | undefined,
	client?: GraphQLTypes["Campaign_client_operator"] | undefined,
	contactPerson?: GraphQLTypes["Campaign_contactPerson_operator"] | undefined,
	email?: GraphQLTypes["Campaign_email_operator"] | undefined,
	phone?: GraphQLTypes["Campaign_phone_operator"] | undefined,
	agentName?: GraphQLTypes["Campaign_agentName_operator"] | undefined,
	agentCommission?: GraphQLTypes["Campaign_agentCommission_operator"] | undefined,
	agentOrderNumber?: GraphQLTypes["Campaign_agentOrderNumber_operator"] | undefined,
	_firstPublishedTime?: GraphQLTypes["Campaign__firstPublishedTime_operator"] | undefined,
	updatedAt?: GraphQLTypes["Campaign_updatedAt_operator"] | undefined,
	createdAt?: GraphQLTypes["Campaign_createdAt_operator"] | undefined,
	id?: GraphQLTypes["Campaign_id_operator"] | undefined,
	AND?: Array<GraphQLTypes["Campaign_where_and"] | undefined> | undefined,
	OR?: Array<GraphQLTypes["Campaign_where_or"] | undefined> | undefined
};
	["Campaign_creator_Relation"]: {
		relationTo?: GraphQLTypes["Campaign_creator_Relation_RelationTo"] | undefined,
	value?: GraphQLTypes["JSON"] | undefined
};
	["Campaign_creator_Relation_RelationTo"]: Campaign_creator_Relation_RelationTo;
	["Campaign_invoiceNumber_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Campaign_quotationNumber_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Campaign_campaignName_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined
};
	["Campaign_client_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined
};
	["Campaign_contactPerson_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined
};
	["Campaign_email_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined
};
	["Campaign_phone_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined
};
	["Campaign_agentName_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Campaign_agentCommission_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined
};
	["Campaign_agentOrderNumber_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Campaign__firstPublishedTime_operator"]: {
		equals?: GraphQLTypes["DateTime"] | undefined,
	not_equals?: GraphQLTypes["DateTime"] | undefined,
	greater_than_equal?: GraphQLTypes["DateTime"] | undefined,
	greater_than?: GraphQLTypes["DateTime"] | undefined,
	less_than_equal?: GraphQLTypes["DateTime"] | undefined,
	less_than?: GraphQLTypes["DateTime"] | undefined,
	like?: GraphQLTypes["DateTime"] | undefined,
	exists?: boolean | undefined
};
	["Campaign_updatedAt_operator"]: {
		equals?: GraphQLTypes["DateTime"] | undefined,
	not_equals?: GraphQLTypes["DateTime"] | undefined,
	greater_than_equal?: GraphQLTypes["DateTime"] | undefined,
	greater_than?: GraphQLTypes["DateTime"] | undefined,
	less_than_equal?: GraphQLTypes["DateTime"] | undefined,
	less_than?: GraphQLTypes["DateTime"] | undefined,
	like?: GraphQLTypes["DateTime"] | undefined,
	exists?: boolean | undefined
};
	["Campaign_createdAt_operator"]: {
		equals?: GraphQLTypes["DateTime"] | undefined,
	not_equals?: GraphQLTypes["DateTime"] | undefined,
	greater_than_equal?: GraphQLTypes["DateTime"] | undefined,
	greater_than?: GraphQLTypes["DateTime"] | undefined,
	less_than_equal?: GraphQLTypes["DateTime"] | undefined,
	less_than?: GraphQLTypes["DateTime"] | undefined,
	like?: GraphQLTypes["DateTime"] | undefined,
	exists?: boolean | undefined
};
	["Campaign_id_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Campaign_where_and"]: {
		creator?: GraphQLTypes["Campaign_creator_Relation"] | undefined,
	invoiceNumber?: GraphQLTypes["Campaign_invoiceNumber_operator"] | undefined,
	quotationNumber?: GraphQLTypes["Campaign_quotationNumber_operator"] | undefined,
	campaignName?: GraphQLTypes["Campaign_campaignName_operator"] | undefined,
	client?: GraphQLTypes["Campaign_client_operator"] | undefined,
	contactPerson?: GraphQLTypes["Campaign_contactPerson_operator"] | undefined,
	email?: GraphQLTypes["Campaign_email_operator"] | undefined,
	phone?: GraphQLTypes["Campaign_phone_operator"] | undefined,
	agentName?: GraphQLTypes["Campaign_agentName_operator"] | undefined,
	agentCommission?: GraphQLTypes["Campaign_agentCommission_operator"] | undefined,
	agentOrderNumber?: GraphQLTypes["Campaign_agentOrderNumber_operator"] | undefined,
	_firstPublishedTime?: GraphQLTypes["Campaign__firstPublishedTime_operator"] | undefined,
	updatedAt?: GraphQLTypes["Campaign_updatedAt_operator"] | undefined,
	createdAt?: GraphQLTypes["Campaign_createdAt_operator"] | undefined,
	id?: GraphQLTypes["Campaign_id_operator"] | undefined,
	AND?: Array<GraphQLTypes["Campaign_where_and"] | undefined> | undefined,
	OR?: Array<GraphQLTypes["Campaign_where_or"] | undefined> | undefined
};
	["Campaign_where_or"]: {
		creator?: GraphQLTypes["Campaign_creator_Relation"] | undefined,
	invoiceNumber?: GraphQLTypes["Campaign_invoiceNumber_operator"] | undefined,
	quotationNumber?: GraphQLTypes["Campaign_quotationNumber_operator"] | undefined,
	campaignName?: GraphQLTypes["Campaign_campaignName_operator"] | undefined,
	client?: GraphQLTypes["Campaign_client_operator"] | undefined,
	contactPerson?: GraphQLTypes["Campaign_contactPerson_operator"] | undefined,
	email?: GraphQLTypes["Campaign_email_operator"] | undefined,
	phone?: GraphQLTypes["Campaign_phone_operator"] | undefined,
	agentName?: GraphQLTypes["Campaign_agentName_operator"] | undefined,
	agentCommission?: GraphQLTypes["Campaign_agentCommission_operator"] | undefined,
	agentOrderNumber?: GraphQLTypes["Campaign_agentOrderNumber_operator"] | undefined,
	_firstPublishedTime?: GraphQLTypes["Campaign__firstPublishedTime_operator"] | undefined,
	updatedAt?: GraphQLTypes["Campaign_updatedAt_operator"] | undefined,
	createdAt?: GraphQLTypes["Campaign_createdAt_operator"] | undefined,
	id?: GraphQLTypes["Campaign_id_operator"] | undefined,
	AND?: Array<GraphQLTypes["Campaign_where_and"] | undefined> | undefined,
	OR?: Array<GraphQLTypes["Campaign_where_or"] | undefined> | undefined
};
	["countCampaigns"]: {
	__typename: "countCampaigns",
	totalDocs?: number | undefined
};
	["campaignsDocAccess"]: {
	__typename: "campaignsDocAccess",
	fields?: GraphQLTypes["CampaignsDocAccessFields"] | undefined,
	create?: GraphQLTypes["CampaignsCreateDocAccess"] | undefined,
	read?: GraphQLTypes["CampaignsReadDocAccess"] | undefined,
	update?: GraphQLTypes["CampaignsUpdateDocAccess"] | undefined,
	delete?: GraphQLTypes["CampaignsDeleteDocAccess"] | undefined
};
	["CampaignsDocAccessFields"]: {
	__typename: "CampaignsDocAccessFields",
	creator?: GraphQLTypes["CampaignsDocAccessFields_creator"] | undefined,
	invoiceNumber?: GraphQLTypes["CampaignsDocAccessFields_invoiceNumber"] | undefined,
	quotationNumber?: GraphQLTypes["CampaignsDocAccessFields_quotationNumber"] | undefined,
	campaignName?: GraphQLTypes["CampaignsDocAccessFields_campaignName"] | undefined,
	client?: GraphQLTypes["CampaignsDocAccessFields_client"] | undefined,
	contactPerson?: GraphQLTypes["CampaignsDocAccessFields_contactPerson"] | undefined,
	email?: GraphQLTypes["CampaignsDocAccessFields_email"] | undefined,
	phone?: GraphQLTypes["CampaignsDocAccessFields_phone"] | undefined,
	agentName?: GraphQLTypes["CampaignsDocAccessFields_agentName"] | undefined,
	agentCommission?: GraphQLTypes["CampaignsDocAccessFields_agentCommission"] | undefined,
	agentOrderNumber?: GraphQLTypes["CampaignsDocAccessFields_agentOrderNumber"] | undefined,
	_firstPublishedTime?: GraphQLTypes["CampaignsDocAccessFields__firstPublishedTime"] | undefined,
	updatedAt?: GraphQLTypes["CampaignsDocAccessFields_updatedAt"] | undefined,
	createdAt?: GraphQLTypes["CampaignsDocAccessFields_createdAt"] | undefined
};
	["CampaignsDocAccessFields_creator"]: {
	__typename: "CampaignsDocAccessFields_creator",
	create?: GraphQLTypes["CampaignsDocAccessFields_creator_Create"] | undefined,
	read?: GraphQLTypes["CampaignsDocAccessFields_creator_Read"] | undefined,
	update?: GraphQLTypes["CampaignsDocAccessFields_creator_Update"] | undefined,
	delete?: GraphQLTypes["CampaignsDocAccessFields_creator_Delete"] | undefined
};
	["CampaignsDocAccessFields_creator_Create"]: {
	__typename: "CampaignsDocAccessFields_creator_Create",
	permission: boolean
};
	["CampaignsDocAccessFields_creator_Read"]: {
	__typename: "CampaignsDocAccessFields_creator_Read",
	permission: boolean
};
	["CampaignsDocAccessFields_creator_Update"]: {
	__typename: "CampaignsDocAccessFields_creator_Update",
	permission: boolean
};
	["CampaignsDocAccessFields_creator_Delete"]: {
	__typename: "CampaignsDocAccessFields_creator_Delete",
	permission: boolean
};
	["CampaignsDocAccessFields_invoiceNumber"]: {
	__typename: "CampaignsDocAccessFields_invoiceNumber",
	create?: GraphQLTypes["CampaignsDocAccessFields_invoiceNumber_Create"] | undefined,
	read?: GraphQLTypes["CampaignsDocAccessFields_invoiceNumber_Read"] | undefined,
	update?: GraphQLTypes["CampaignsDocAccessFields_invoiceNumber_Update"] | undefined,
	delete?: GraphQLTypes["CampaignsDocAccessFields_invoiceNumber_Delete"] | undefined
};
	["CampaignsDocAccessFields_invoiceNumber_Create"]: {
	__typename: "CampaignsDocAccessFields_invoiceNumber_Create",
	permission: boolean
};
	["CampaignsDocAccessFields_invoiceNumber_Read"]: {
	__typename: "CampaignsDocAccessFields_invoiceNumber_Read",
	permission: boolean
};
	["CampaignsDocAccessFields_invoiceNumber_Update"]: {
	__typename: "CampaignsDocAccessFields_invoiceNumber_Update",
	permission: boolean
};
	["CampaignsDocAccessFields_invoiceNumber_Delete"]: {
	__typename: "CampaignsDocAccessFields_invoiceNumber_Delete",
	permission: boolean
};
	["CampaignsDocAccessFields_quotationNumber"]: {
	__typename: "CampaignsDocAccessFields_quotationNumber",
	create?: GraphQLTypes["CampaignsDocAccessFields_quotationNumber_Create"] | undefined,
	read?: GraphQLTypes["CampaignsDocAccessFields_quotationNumber_Read"] | undefined,
	update?: GraphQLTypes["CampaignsDocAccessFields_quotationNumber_Update"] | undefined,
	delete?: GraphQLTypes["CampaignsDocAccessFields_quotationNumber_Delete"] | undefined
};
	["CampaignsDocAccessFields_quotationNumber_Create"]: {
	__typename: "CampaignsDocAccessFields_quotationNumber_Create",
	permission: boolean
};
	["CampaignsDocAccessFields_quotationNumber_Read"]: {
	__typename: "CampaignsDocAccessFields_quotationNumber_Read",
	permission: boolean
};
	["CampaignsDocAccessFields_quotationNumber_Update"]: {
	__typename: "CampaignsDocAccessFields_quotationNumber_Update",
	permission: boolean
};
	["CampaignsDocAccessFields_quotationNumber_Delete"]: {
	__typename: "CampaignsDocAccessFields_quotationNumber_Delete",
	permission: boolean
};
	["CampaignsDocAccessFields_campaignName"]: {
	__typename: "CampaignsDocAccessFields_campaignName",
	create?: GraphQLTypes["CampaignsDocAccessFields_campaignName_Create"] | undefined,
	read?: GraphQLTypes["CampaignsDocAccessFields_campaignName_Read"] | undefined,
	update?: GraphQLTypes["CampaignsDocAccessFields_campaignName_Update"] | undefined,
	delete?: GraphQLTypes["CampaignsDocAccessFields_campaignName_Delete"] | undefined
};
	["CampaignsDocAccessFields_campaignName_Create"]: {
	__typename: "CampaignsDocAccessFields_campaignName_Create",
	permission: boolean
};
	["CampaignsDocAccessFields_campaignName_Read"]: {
	__typename: "CampaignsDocAccessFields_campaignName_Read",
	permission: boolean
};
	["CampaignsDocAccessFields_campaignName_Update"]: {
	__typename: "CampaignsDocAccessFields_campaignName_Update",
	permission: boolean
};
	["CampaignsDocAccessFields_campaignName_Delete"]: {
	__typename: "CampaignsDocAccessFields_campaignName_Delete",
	permission: boolean
};
	["CampaignsDocAccessFields_client"]: {
	__typename: "CampaignsDocAccessFields_client",
	create?: GraphQLTypes["CampaignsDocAccessFields_client_Create"] | undefined,
	read?: GraphQLTypes["CampaignsDocAccessFields_client_Read"] | undefined,
	update?: GraphQLTypes["CampaignsDocAccessFields_client_Update"] | undefined,
	delete?: GraphQLTypes["CampaignsDocAccessFields_client_Delete"] | undefined
};
	["CampaignsDocAccessFields_client_Create"]: {
	__typename: "CampaignsDocAccessFields_client_Create",
	permission: boolean
};
	["CampaignsDocAccessFields_client_Read"]: {
	__typename: "CampaignsDocAccessFields_client_Read",
	permission: boolean
};
	["CampaignsDocAccessFields_client_Update"]: {
	__typename: "CampaignsDocAccessFields_client_Update",
	permission: boolean
};
	["CampaignsDocAccessFields_client_Delete"]: {
	__typename: "CampaignsDocAccessFields_client_Delete",
	permission: boolean
};
	["CampaignsDocAccessFields_contactPerson"]: {
	__typename: "CampaignsDocAccessFields_contactPerson",
	create?: GraphQLTypes["CampaignsDocAccessFields_contactPerson_Create"] | undefined,
	read?: GraphQLTypes["CampaignsDocAccessFields_contactPerson_Read"] | undefined,
	update?: GraphQLTypes["CampaignsDocAccessFields_contactPerson_Update"] | undefined,
	delete?: GraphQLTypes["CampaignsDocAccessFields_contactPerson_Delete"] | undefined
};
	["CampaignsDocAccessFields_contactPerson_Create"]: {
	__typename: "CampaignsDocAccessFields_contactPerson_Create",
	permission: boolean
};
	["CampaignsDocAccessFields_contactPerson_Read"]: {
	__typename: "CampaignsDocAccessFields_contactPerson_Read",
	permission: boolean
};
	["CampaignsDocAccessFields_contactPerson_Update"]: {
	__typename: "CampaignsDocAccessFields_contactPerson_Update",
	permission: boolean
};
	["CampaignsDocAccessFields_contactPerson_Delete"]: {
	__typename: "CampaignsDocAccessFields_contactPerson_Delete",
	permission: boolean
};
	["CampaignsDocAccessFields_email"]: {
	__typename: "CampaignsDocAccessFields_email",
	create?: GraphQLTypes["CampaignsDocAccessFields_email_Create"] | undefined,
	read?: GraphQLTypes["CampaignsDocAccessFields_email_Read"] | undefined,
	update?: GraphQLTypes["CampaignsDocAccessFields_email_Update"] | undefined,
	delete?: GraphQLTypes["CampaignsDocAccessFields_email_Delete"] | undefined
};
	["CampaignsDocAccessFields_email_Create"]: {
	__typename: "CampaignsDocAccessFields_email_Create",
	permission: boolean
};
	["CampaignsDocAccessFields_email_Read"]: {
	__typename: "CampaignsDocAccessFields_email_Read",
	permission: boolean
};
	["CampaignsDocAccessFields_email_Update"]: {
	__typename: "CampaignsDocAccessFields_email_Update",
	permission: boolean
};
	["CampaignsDocAccessFields_email_Delete"]: {
	__typename: "CampaignsDocAccessFields_email_Delete",
	permission: boolean
};
	["CampaignsDocAccessFields_phone"]: {
	__typename: "CampaignsDocAccessFields_phone",
	create?: GraphQLTypes["CampaignsDocAccessFields_phone_Create"] | undefined,
	read?: GraphQLTypes["CampaignsDocAccessFields_phone_Read"] | undefined,
	update?: GraphQLTypes["CampaignsDocAccessFields_phone_Update"] | undefined,
	delete?: GraphQLTypes["CampaignsDocAccessFields_phone_Delete"] | undefined
};
	["CampaignsDocAccessFields_phone_Create"]: {
	__typename: "CampaignsDocAccessFields_phone_Create",
	permission: boolean
};
	["CampaignsDocAccessFields_phone_Read"]: {
	__typename: "CampaignsDocAccessFields_phone_Read",
	permission: boolean
};
	["CampaignsDocAccessFields_phone_Update"]: {
	__typename: "CampaignsDocAccessFields_phone_Update",
	permission: boolean
};
	["CampaignsDocAccessFields_phone_Delete"]: {
	__typename: "CampaignsDocAccessFields_phone_Delete",
	permission: boolean
};
	["CampaignsDocAccessFields_agentName"]: {
	__typename: "CampaignsDocAccessFields_agentName",
	create?: GraphQLTypes["CampaignsDocAccessFields_agentName_Create"] | undefined,
	read?: GraphQLTypes["CampaignsDocAccessFields_agentName_Read"] | undefined,
	update?: GraphQLTypes["CampaignsDocAccessFields_agentName_Update"] | undefined,
	delete?: GraphQLTypes["CampaignsDocAccessFields_agentName_Delete"] | undefined
};
	["CampaignsDocAccessFields_agentName_Create"]: {
	__typename: "CampaignsDocAccessFields_agentName_Create",
	permission: boolean
};
	["CampaignsDocAccessFields_agentName_Read"]: {
	__typename: "CampaignsDocAccessFields_agentName_Read",
	permission: boolean
};
	["CampaignsDocAccessFields_agentName_Update"]: {
	__typename: "CampaignsDocAccessFields_agentName_Update",
	permission: boolean
};
	["CampaignsDocAccessFields_agentName_Delete"]: {
	__typename: "CampaignsDocAccessFields_agentName_Delete",
	permission: boolean
};
	["CampaignsDocAccessFields_agentCommission"]: {
	__typename: "CampaignsDocAccessFields_agentCommission",
	create?: GraphQLTypes["CampaignsDocAccessFields_agentCommission_Create"] | undefined,
	read?: GraphQLTypes["CampaignsDocAccessFields_agentCommission_Read"] | undefined,
	update?: GraphQLTypes["CampaignsDocAccessFields_agentCommission_Update"] | undefined,
	delete?: GraphQLTypes["CampaignsDocAccessFields_agentCommission_Delete"] | undefined
};
	["CampaignsDocAccessFields_agentCommission_Create"]: {
	__typename: "CampaignsDocAccessFields_agentCommission_Create",
	permission: boolean
};
	["CampaignsDocAccessFields_agentCommission_Read"]: {
	__typename: "CampaignsDocAccessFields_agentCommission_Read",
	permission: boolean
};
	["CampaignsDocAccessFields_agentCommission_Update"]: {
	__typename: "CampaignsDocAccessFields_agentCommission_Update",
	permission: boolean
};
	["CampaignsDocAccessFields_agentCommission_Delete"]: {
	__typename: "CampaignsDocAccessFields_agentCommission_Delete",
	permission: boolean
};
	["CampaignsDocAccessFields_agentOrderNumber"]: {
	__typename: "CampaignsDocAccessFields_agentOrderNumber",
	create?: GraphQLTypes["CampaignsDocAccessFields_agentOrderNumber_Create"] | undefined,
	read?: GraphQLTypes["CampaignsDocAccessFields_agentOrderNumber_Read"] | undefined,
	update?: GraphQLTypes["CampaignsDocAccessFields_agentOrderNumber_Update"] | undefined,
	delete?: GraphQLTypes["CampaignsDocAccessFields_agentOrderNumber_Delete"] | undefined
};
	["CampaignsDocAccessFields_agentOrderNumber_Create"]: {
	__typename: "CampaignsDocAccessFields_agentOrderNumber_Create",
	permission: boolean
};
	["CampaignsDocAccessFields_agentOrderNumber_Read"]: {
	__typename: "CampaignsDocAccessFields_agentOrderNumber_Read",
	permission: boolean
};
	["CampaignsDocAccessFields_agentOrderNumber_Update"]: {
	__typename: "CampaignsDocAccessFields_agentOrderNumber_Update",
	permission: boolean
};
	["CampaignsDocAccessFields_agentOrderNumber_Delete"]: {
	__typename: "CampaignsDocAccessFields_agentOrderNumber_Delete",
	permission: boolean
};
	["CampaignsDocAccessFields__firstPublishedTime"]: {
	__typename: "CampaignsDocAccessFields__firstPublishedTime",
	create?: GraphQLTypes["CampaignsDocAccessFields__firstPublishedTime_Create"] | undefined,
	read?: GraphQLTypes["CampaignsDocAccessFields__firstPublishedTime_Read"] | undefined,
	update?: GraphQLTypes["CampaignsDocAccessFields__firstPublishedTime_Update"] | undefined,
	delete?: GraphQLTypes["CampaignsDocAccessFields__firstPublishedTime_Delete"] | undefined
};
	["CampaignsDocAccessFields__firstPublishedTime_Create"]: {
	__typename: "CampaignsDocAccessFields__firstPublishedTime_Create",
	permission: boolean
};
	["CampaignsDocAccessFields__firstPublishedTime_Read"]: {
	__typename: "CampaignsDocAccessFields__firstPublishedTime_Read",
	permission: boolean
};
	["CampaignsDocAccessFields__firstPublishedTime_Update"]: {
	__typename: "CampaignsDocAccessFields__firstPublishedTime_Update",
	permission: boolean
};
	["CampaignsDocAccessFields__firstPublishedTime_Delete"]: {
	__typename: "CampaignsDocAccessFields__firstPublishedTime_Delete",
	permission: boolean
};
	["CampaignsDocAccessFields_updatedAt"]: {
	__typename: "CampaignsDocAccessFields_updatedAt",
	create?: GraphQLTypes["CampaignsDocAccessFields_updatedAt_Create"] | undefined,
	read?: GraphQLTypes["CampaignsDocAccessFields_updatedAt_Read"] | undefined,
	update?: GraphQLTypes["CampaignsDocAccessFields_updatedAt_Update"] | undefined,
	delete?: GraphQLTypes["CampaignsDocAccessFields_updatedAt_Delete"] | undefined
};
	["CampaignsDocAccessFields_updatedAt_Create"]: {
	__typename: "CampaignsDocAccessFields_updatedAt_Create",
	permission: boolean
};
	["CampaignsDocAccessFields_updatedAt_Read"]: {
	__typename: "CampaignsDocAccessFields_updatedAt_Read",
	permission: boolean
};
	["CampaignsDocAccessFields_updatedAt_Update"]: {
	__typename: "CampaignsDocAccessFields_updatedAt_Update",
	permission: boolean
};
	["CampaignsDocAccessFields_updatedAt_Delete"]: {
	__typename: "CampaignsDocAccessFields_updatedAt_Delete",
	permission: boolean
};
	["CampaignsDocAccessFields_createdAt"]: {
	__typename: "CampaignsDocAccessFields_createdAt",
	create?: GraphQLTypes["CampaignsDocAccessFields_createdAt_Create"] | undefined,
	read?: GraphQLTypes["CampaignsDocAccessFields_createdAt_Read"] | undefined,
	update?: GraphQLTypes["CampaignsDocAccessFields_createdAt_Update"] | undefined,
	delete?: GraphQLTypes["CampaignsDocAccessFields_createdAt_Delete"] | undefined
};
	["CampaignsDocAccessFields_createdAt_Create"]: {
	__typename: "CampaignsDocAccessFields_createdAt_Create",
	permission: boolean
};
	["CampaignsDocAccessFields_createdAt_Read"]: {
	__typename: "CampaignsDocAccessFields_createdAt_Read",
	permission: boolean
};
	["CampaignsDocAccessFields_createdAt_Update"]: {
	__typename: "CampaignsDocAccessFields_createdAt_Update",
	permission: boolean
};
	["CampaignsDocAccessFields_createdAt_Delete"]: {
	__typename: "CampaignsDocAccessFields_createdAt_Delete",
	permission: boolean
};
	["CampaignsCreateDocAccess"]: {
	__typename: "CampaignsCreateDocAccess",
	permission: boolean,
	where?: GraphQLTypes["JSONObject"] | undefined
};
	["CampaignsReadDocAccess"]: {
	__typename: "CampaignsReadDocAccess",
	permission: boolean,
	where?: GraphQLTypes["JSONObject"] | undefined
};
	["CampaignsUpdateDocAccess"]: {
	__typename: "CampaignsUpdateDocAccess",
	permission: boolean,
	where?: GraphQLTypes["JSONObject"] | undefined
};
	["CampaignsDeleteDocAccess"]: {
	__typename: "CampaignsDeleteDocAccess",
	permission: boolean,
	where?: GraphQLTypes["JSONObject"] | undefined
};
	["Advertise"]: {
	__typename: "Advertise",
	id?: string | undefined,
	advertise?: GraphQLTypes["Advertise_Advertise"] | undefined,
	advertiseStartDate: GraphQLTypes["DateTime"],
	advertiseEndDate: GraphQLTypes["DateTime"],
	type?: GraphQLTypes["Advertise_type"] | undefined,
	invoiceDescription?: string | undefined,
	standardPrice: number,
	discount?: number | undefined,
	discountPercentage?: number | undefined,
	_calculateByDiscount?: boolean | undefined,
	timableCost?: number | undefined,
	remark?: string | undefined,
	public?: boolean | undefined,
	campaignName?: string | undefined,
	campaignId?: string | undefined,
	status?: string | undefined,
	updatedAt?: GraphQLTypes["DateTime"] | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined
};
	["Advertise_Advertise"]: {
	__typename: "Advertise_Advertise",
	type?: GraphQLTypes["Advertise_Advertise_type"] | undefined,
	event?: GraphQLTypes["Event"] | undefined,
	keyword?: string | undefined,
	location?: GraphQLTypes["Location"] | undefined,
	displayPosition?: GraphQLTypes["Advertise_Advertise_displayPosition"] | undefined,
	rankingListRemark?: string | undefined
};
	["Advertise_Advertise_type"]: Advertise_Advertise_type;
	["Advertise_Advertise_displayPosition"]: Advertise_Advertise_displayPosition;
	["Advertise_type"]: Advertise_type;
	["Advertises"]: {
	__typename: "Advertises",
	docs?: Array<GraphQLTypes["Advertise"] | undefined> | undefined,
	hasNextPage?: boolean | undefined,
	hasPrevPage?: boolean | undefined,
	limit?: number | undefined,
	nextPage?: number | undefined,
	offset?: number | undefined,
	page?: number | undefined,
	pagingCounter?: number | undefined,
	prevPage?: number | undefined,
	totalDocs?: number | undefined,
	totalPages?: number | undefined
};
	["Advertise_where"]: {
		advertise__type?: GraphQLTypes["Advertise_advertise__type_operator"] | undefined,
	advertise__event?: GraphQLTypes["Advertise_advertise__event_operator"] | undefined,
	advertise__keyword?: GraphQLTypes["Advertise_advertise__keyword_operator"] | undefined,
	advertise__location?: GraphQLTypes["Advertise_advertise__location_operator"] | undefined,
	advertise__displayPosition?: GraphQLTypes["Advertise_advertise__displayPosition_operator"] | undefined,
	advertise__rankingListRemark?: GraphQLTypes["Advertise_advertise__rankingListRemark_operator"] | undefined,
	advertiseStartDate?: GraphQLTypes["Advertise_advertiseStartDate_operator"] | undefined,
	advertiseEndDate?: GraphQLTypes["Advertise_advertiseEndDate_operator"] | undefined,
	type?: GraphQLTypes["Advertise_type_operator"] | undefined,
	invoiceDescription?: GraphQLTypes["Advertise_invoiceDescription_operator"] | undefined,
	standardPrice?: GraphQLTypes["Advertise_standardPrice_operator"] | undefined,
	discount?: GraphQLTypes["Advertise_discount_operator"] | undefined,
	discountPercentage?: GraphQLTypes["Advertise_discountPercentage_operator"] | undefined,
	_calculateByDiscount?: GraphQLTypes["Advertise__calculateByDiscount_operator"] | undefined,
	timableCost?: GraphQLTypes["Advertise_timableCost_operator"] | undefined,
	remark?: GraphQLTypes["Advertise_remark_operator"] | undefined,
	public?: GraphQLTypes["Advertise_public_operator"] | undefined,
	campaignName?: GraphQLTypes["Advertise_campaignName_operator"] | undefined,
	campaignId?: GraphQLTypes["Advertise_campaignId_operator"] | undefined,
	status?: GraphQLTypes["Advertise_status_operator"] | undefined,
	updatedAt?: GraphQLTypes["Advertise_updatedAt_operator"] | undefined,
	createdAt?: GraphQLTypes["Advertise_createdAt_operator"] | undefined,
	id?: GraphQLTypes["Advertise_id_operator"] | undefined,
	AND?: Array<GraphQLTypes["Advertise_where_and"] | undefined> | undefined,
	OR?: Array<GraphQLTypes["Advertise_where_or"] | undefined> | undefined
};
	["Advertise_advertise__type_operator"]: {
		equals?: GraphQLTypes["Advertise_advertise__type_Input"] | undefined,
	not_equals?: GraphQLTypes["Advertise_advertise__type_Input"] | undefined,
	in?: Array<GraphQLTypes["Advertise_advertise__type_Input"] | undefined> | undefined,
	not_in?: Array<GraphQLTypes["Advertise_advertise__type_Input"] | undefined> | undefined,
	all?: Array<GraphQLTypes["Advertise_advertise__type_Input"] | undefined> | undefined,
	exists?: boolean | undefined
};
	["Advertise_advertise__type_Input"]: Advertise_advertise__type_Input;
	["Advertise_advertise__event_operator"]: {
		equals?: GraphQLTypes["JSON"] | undefined,
	not_equals?: GraphQLTypes["JSON"] | undefined,
	in?: Array<GraphQLTypes["JSON"] | undefined> | undefined,
	not_in?: Array<GraphQLTypes["JSON"] | undefined> | undefined,
	all?: Array<GraphQLTypes["JSON"] | undefined> | undefined
};
	["Advertise_advertise__keyword_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined
};
	["Advertise_advertise__location_operator"]: {
		equals?: GraphQLTypes["JSON"] | undefined,
	not_equals?: GraphQLTypes["JSON"] | undefined,
	in?: Array<GraphQLTypes["JSON"] | undefined> | undefined,
	not_in?: Array<GraphQLTypes["JSON"] | undefined> | undefined,
	all?: Array<GraphQLTypes["JSON"] | undefined> | undefined
};
	["Advertise_advertise__displayPosition_operator"]: {
		equals?: GraphQLTypes["Advertise_advertise__displayPosition_Input"] | undefined,
	not_equals?: GraphQLTypes["Advertise_advertise__displayPosition_Input"] | undefined,
	like?: GraphQLTypes["Advertise_advertise__displayPosition_Input"] | undefined,
	contains?: GraphQLTypes["Advertise_advertise__displayPosition_Input"] | undefined
};
	["Advertise_advertise__displayPosition_Input"]: Advertise_advertise__displayPosition_Input;
	["Advertise_advertise__rankingListRemark_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Advertise_advertiseStartDate_operator"]: {
		equals?: GraphQLTypes["DateTime"] | undefined,
	not_equals?: GraphQLTypes["DateTime"] | undefined,
	greater_than_equal?: GraphQLTypes["DateTime"] | undefined,
	greater_than?: GraphQLTypes["DateTime"] | undefined,
	less_than_equal?: GraphQLTypes["DateTime"] | undefined,
	less_than?: GraphQLTypes["DateTime"] | undefined,
	like?: GraphQLTypes["DateTime"] | undefined
};
	["Advertise_advertiseEndDate_operator"]: {
		equals?: GraphQLTypes["DateTime"] | undefined,
	not_equals?: GraphQLTypes["DateTime"] | undefined,
	greater_than_equal?: GraphQLTypes["DateTime"] | undefined,
	greater_than?: GraphQLTypes["DateTime"] | undefined,
	less_than_equal?: GraphQLTypes["DateTime"] | undefined,
	less_than?: GraphQLTypes["DateTime"] | undefined,
	like?: GraphQLTypes["DateTime"] | undefined
};
	["Advertise_type_operator"]: {
		equals?: GraphQLTypes["Advertise_type_Input"] | undefined,
	not_equals?: GraphQLTypes["Advertise_type_Input"] | undefined,
	in?: Array<GraphQLTypes["Advertise_type_Input"] | undefined> | undefined,
	not_in?: Array<GraphQLTypes["Advertise_type_Input"] | undefined> | undefined,
	all?: Array<GraphQLTypes["Advertise_type_Input"] | undefined> | undefined,
	exists?: boolean | undefined
};
	["Advertise_type_Input"]: Advertise_type_Input;
	["Advertise_invoiceDescription_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	exists?: boolean | undefined
};
	["Advertise_standardPrice_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined
};
	["Advertise_discount_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Advertise_discountPercentage_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Advertise__calculateByDiscount_operator"]: {
		equals?: boolean | undefined,
	not_equals?: boolean | undefined,
	exists?: boolean | undefined
};
	["Advertise_timableCost_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined,
	exists?: boolean | undefined
};
	["Advertise_remark_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	exists?: boolean | undefined
};
	["Advertise_public_operator"]: {
		equals?: boolean | undefined,
	not_equals?: boolean | undefined,
	exists?: boolean | undefined
};
	["Advertise_campaignName_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Advertise_campaignId_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Advertise_status_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Advertise_updatedAt_operator"]: {
		equals?: GraphQLTypes["DateTime"] | undefined,
	not_equals?: GraphQLTypes["DateTime"] | undefined,
	greater_than_equal?: GraphQLTypes["DateTime"] | undefined,
	greater_than?: GraphQLTypes["DateTime"] | undefined,
	less_than_equal?: GraphQLTypes["DateTime"] | undefined,
	less_than?: GraphQLTypes["DateTime"] | undefined,
	like?: GraphQLTypes["DateTime"] | undefined,
	exists?: boolean | undefined
};
	["Advertise_createdAt_operator"]: {
		equals?: GraphQLTypes["DateTime"] | undefined,
	not_equals?: GraphQLTypes["DateTime"] | undefined,
	greater_than_equal?: GraphQLTypes["DateTime"] | undefined,
	greater_than?: GraphQLTypes["DateTime"] | undefined,
	less_than_equal?: GraphQLTypes["DateTime"] | undefined,
	less_than?: GraphQLTypes["DateTime"] | undefined,
	like?: GraphQLTypes["DateTime"] | undefined,
	exists?: boolean | undefined
};
	["Advertise_id_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Advertise_where_and"]: {
		advertise__type?: GraphQLTypes["Advertise_advertise__type_operator"] | undefined,
	advertise__event?: GraphQLTypes["Advertise_advertise__event_operator"] | undefined,
	advertise__keyword?: GraphQLTypes["Advertise_advertise__keyword_operator"] | undefined,
	advertise__location?: GraphQLTypes["Advertise_advertise__location_operator"] | undefined,
	advertise__displayPosition?: GraphQLTypes["Advertise_advertise__displayPosition_operator"] | undefined,
	advertise__rankingListRemark?: GraphQLTypes["Advertise_advertise__rankingListRemark_operator"] | undefined,
	advertiseStartDate?: GraphQLTypes["Advertise_advertiseStartDate_operator"] | undefined,
	advertiseEndDate?: GraphQLTypes["Advertise_advertiseEndDate_operator"] | undefined,
	type?: GraphQLTypes["Advertise_type_operator"] | undefined,
	invoiceDescription?: GraphQLTypes["Advertise_invoiceDescription_operator"] | undefined,
	standardPrice?: GraphQLTypes["Advertise_standardPrice_operator"] | undefined,
	discount?: GraphQLTypes["Advertise_discount_operator"] | undefined,
	discountPercentage?: GraphQLTypes["Advertise_discountPercentage_operator"] | undefined,
	_calculateByDiscount?: GraphQLTypes["Advertise__calculateByDiscount_operator"] | undefined,
	timableCost?: GraphQLTypes["Advertise_timableCost_operator"] | undefined,
	remark?: GraphQLTypes["Advertise_remark_operator"] | undefined,
	public?: GraphQLTypes["Advertise_public_operator"] | undefined,
	campaignName?: GraphQLTypes["Advertise_campaignName_operator"] | undefined,
	campaignId?: GraphQLTypes["Advertise_campaignId_operator"] | undefined,
	status?: GraphQLTypes["Advertise_status_operator"] | undefined,
	updatedAt?: GraphQLTypes["Advertise_updatedAt_operator"] | undefined,
	createdAt?: GraphQLTypes["Advertise_createdAt_operator"] | undefined,
	id?: GraphQLTypes["Advertise_id_operator"] | undefined,
	AND?: Array<GraphQLTypes["Advertise_where_and"] | undefined> | undefined,
	OR?: Array<GraphQLTypes["Advertise_where_or"] | undefined> | undefined
};
	["Advertise_where_or"]: {
		advertise__type?: GraphQLTypes["Advertise_advertise__type_operator"] | undefined,
	advertise__event?: GraphQLTypes["Advertise_advertise__event_operator"] | undefined,
	advertise__keyword?: GraphQLTypes["Advertise_advertise__keyword_operator"] | undefined,
	advertise__location?: GraphQLTypes["Advertise_advertise__location_operator"] | undefined,
	advertise__displayPosition?: GraphQLTypes["Advertise_advertise__displayPosition_operator"] | undefined,
	advertise__rankingListRemark?: GraphQLTypes["Advertise_advertise__rankingListRemark_operator"] | undefined,
	advertiseStartDate?: GraphQLTypes["Advertise_advertiseStartDate_operator"] | undefined,
	advertiseEndDate?: GraphQLTypes["Advertise_advertiseEndDate_operator"] | undefined,
	type?: GraphQLTypes["Advertise_type_operator"] | undefined,
	invoiceDescription?: GraphQLTypes["Advertise_invoiceDescription_operator"] | undefined,
	standardPrice?: GraphQLTypes["Advertise_standardPrice_operator"] | undefined,
	discount?: GraphQLTypes["Advertise_discount_operator"] | undefined,
	discountPercentage?: GraphQLTypes["Advertise_discountPercentage_operator"] | undefined,
	_calculateByDiscount?: GraphQLTypes["Advertise__calculateByDiscount_operator"] | undefined,
	timableCost?: GraphQLTypes["Advertise_timableCost_operator"] | undefined,
	remark?: GraphQLTypes["Advertise_remark_operator"] | undefined,
	public?: GraphQLTypes["Advertise_public_operator"] | undefined,
	campaignName?: GraphQLTypes["Advertise_campaignName_operator"] | undefined,
	campaignId?: GraphQLTypes["Advertise_campaignId_operator"] | undefined,
	status?: GraphQLTypes["Advertise_status_operator"] | undefined,
	updatedAt?: GraphQLTypes["Advertise_updatedAt_operator"] | undefined,
	createdAt?: GraphQLTypes["Advertise_createdAt_operator"] | undefined,
	id?: GraphQLTypes["Advertise_id_operator"] | undefined,
	AND?: Array<GraphQLTypes["Advertise_where_and"] | undefined> | undefined,
	OR?: Array<GraphQLTypes["Advertise_where_or"] | undefined> | undefined
};
	["countAdvertises"]: {
	__typename: "countAdvertises",
	totalDocs?: number | undefined
};
	["advertisesDocAccess"]: {
	__typename: "advertisesDocAccess",
	fields?: GraphQLTypes["AdvertisesDocAccessFields"] | undefined,
	create?: GraphQLTypes["AdvertisesCreateDocAccess"] | undefined,
	read?: GraphQLTypes["AdvertisesReadDocAccess"] | undefined,
	update?: GraphQLTypes["AdvertisesUpdateDocAccess"] | undefined,
	delete?: GraphQLTypes["AdvertisesDeleteDocAccess"] | undefined
};
	["AdvertisesDocAccessFields"]: {
	__typename: "AdvertisesDocAccessFields",
	advertise?: GraphQLTypes["AdvertisesDocAccessFields_advertise"] | undefined,
	advertiseStartDate?: GraphQLTypes["AdvertisesDocAccessFields_advertiseStartDate"] | undefined,
	advertiseEndDate?: GraphQLTypes["AdvertisesDocAccessFields_advertiseEndDate"] | undefined,
	type?: GraphQLTypes["AdvertisesDocAccessFields_type"] | undefined,
	invoiceDescription?: GraphQLTypes["AdvertisesDocAccessFields_invoiceDescription"] | undefined,
	standardPrice?: GraphQLTypes["AdvertisesDocAccessFields_standardPrice"] | undefined,
	discount?: GraphQLTypes["AdvertisesDocAccessFields_discount"] | undefined,
	discountPercentage?: GraphQLTypes["AdvertisesDocAccessFields_discountPercentage"] | undefined,
	_calculateByDiscount?: GraphQLTypes["AdvertisesDocAccessFields__calculateByDiscount"] | undefined,
	timableCost?: GraphQLTypes["AdvertisesDocAccessFields_timableCost"] | undefined,
	remark?: GraphQLTypes["AdvertisesDocAccessFields_remark"] | undefined,
	public?: GraphQLTypes["AdvertisesDocAccessFields_public"] | undefined,
	campaignName?: GraphQLTypes["AdvertisesDocAccessFields_campaignName"] | undefined,
	campaignId?: GraphQLTypes["AdvertisesDocAccessFields_campaignId"] | undefined,
	status?: GraphQLTypes["AdvertisesDocAccessFields_status"] | undefined,
	updatedAt?: GraphQLTypes["AdvertisesDocAccessFields_updatedAt"] | undefined,
	createdAt?: GraphQLTypes["AdvertisesDocAccessFields_createdAt"] | undefined
};
	["AdvertisesDocAccessFields_advertise"]: {
	__typename: "AdvertisesDocAccessFields_advertise",
	create?: GraphQLTypes["AdvertisesDocAccessFields_advertise_Create"] | undefined,
	read?: GraphQLTypes["AdvertisesDocAccessFields_advertise_Read"] | undefined,
	update?: GraphQLTypes["AdvertisesDocAccessFields_advertise_Update"] | undefined,
	delete?: GraphQLTypes["AdvertisesDocAccessFields_advertise_Delete"] | undefined,
	fields?: GraphQLTypes["AdvertisesDocAccessFields_advertise_Fields"] | undefined
};
	["AdvertisesDocAccessFields_advertise_Create"]: {
	__typename: "AdvertisesDocAccessFields_advertise_Create",
	permission: boolean
};
	["AdvertisesDocAccessFields_advertise_Read"]: {
	__typename: "AdvertisesDocAccessFields_advertise_Read",
	permission: boolean
};
	["AdvertisesDocAccessFields_advertise_Update"]: {
	__typename: "AdvertisesDocAccessFields_advertise_Update",
	permission: boolean
};
	["AdvertisesDocAccessFields_advertise_Delete"]: {
	__typename: "AdvertisesDocAccessFields_advertise_Delete",
	permission: boolean
};
	["AdvertisesDocAccessFields_advertise_Fields"]: {
	__typename: "AdvertisesDocAccessFields_advertise_Fields",
	type?: GraphQLTypes["AdvertisesDocAccessFields_advertise_type"] | undefined,
	event?: GraphQLTypes["AdvertisesDocAccessFields_advertise_event"] | undefined,
	keyword?: GraphQLTypes["AdvertisesDocAccessFields_advertise_keyword"] | undefined,
	location?: GraphQLTypes["AdvertisesDocAccessFields_advertise_location"] | undefined,
	displayPosition?: GraphQLTypes["AdvertisesDocAccessFields_advertise_displayPosition"] | undefined,
	rankingListRemark?: GraphQLTypes["AdvertisesDocAccessFields_advertise_rankingListRemark"] | undefined
};
	["AdvertisesDocAccessFields_advertise_type"]: {
	__typename: "AdvertisesDocAccessFields_advertise_type",
	create?: GraphQLTypes["AdvertisesDocAccessFields_advertise_type_Create"] | undefined,
	read?: GraphQLTypes["AdvertisesDocAccessFields_advertise_type_Read"] | undefined,
	update?: GraphQLTypes["AdvertisesDocAccessFields_advertise_type_Update"] | undefined,
	delete?: GraphQLTypes["AdvertisesDocAccessFields_advertise_type_Delete"] | undefined
};
	["AdvertisesDocAccessFields_advertise_type_Create"]: {
	__typename: "AdvertisesDocAccessFields_advertise_type_Create",
	permission: boolean
};
	["AdvertisesDocAccessFields_advertise_type_Read"]: {
	__typename: "AdvertisesDocAccessFields_advertise_type_Read",
	permission: boolean
};
	["AdvertisesDocAccessFields_advertise_type_Update"]: {
	__typename: "AdvertisesDocAccessFields_advertise_type_Update",
	permission: boolean
};
	["AdvertisesDocAccessFields_advertise_type_Delete"]: {
	__typename: "AdvertisesDocAccessFields_advertise_type_Delete",
	permission: boolean
};
	["AdvertisesDocAccessFields_advertise_event"]: {
	__typename: "AdvertisesDocAccessFields_advertise_event",
	create?: GraphQLTypes["AdvertisesDocAccessFields_advertise_event_Create"] | undefined,
	read?: GraphQLTypes["AdvertisesDocAccessFields_advertise_event_Read"] | undefined,
	update?: GraphQLTypes["AdvertisesDocAccessFields_advertise_event_Update"] | undefined,
	delete?: GraphQLTypes["AdvertisesDocAccessFields_advertise_event_Delete"] | undefined
};
	["AdvertisesDocAccessFields_advertise_event_Create"]: {
	__typename: "AdvertisesDocAccessFields_advertise_event_Create",
	permission: boolean
};
	["AdvertisesDocAccessFields_advertise_event_Read"]: {
	__typename: "AdvertisesDocAccessFields_advertise_event_Read",
	permission: boolean
};
	["AdvertisesDocAccessFields_advertise_event_Update"]: {
	__typename: "AdvertisesDocAccessFields_advertise_event_Update",
	permission: boolean
};
	["AdvertisesDocAccessFields_advertise_event_Delete"]: {
	__typename: "AdvertisesDocAccessFields_advertise_event_Delete",
	permission: boolean
};
	["AdvertisesDocAccessFields_advertise_keyword"]: {
	__typename: "AdvertisesDocAccessFields_advertise_keyword",
	create?: GraphQLTypes["AdvertisesDocAccessFields_advertise_keyword_Create"] | undefined,
	read?: GraphQLTypes["AdvertisesDocAccessFields_advertise_keyword_Read"] | undefined,
	update?: GraphQLTypes["AdvertisesDocAccessFields_advertise_keyword_Update"] | undefined,
	delete?: GraphQLTypes["AdvertisesDocAccessFields_advertise_keyword_Delete"] | undefined
};
	["AdvertisesDocAccessFields_advertise_keyword_Create"]: {
	__typename: "AdvertisesDocAccessFields_advertise_keyword_Create",
	permission: boolean
};
	["AdvertisesDocAccessFields_advertise_keyword_Read"]: {
	__typename: "AdvertisesDocAccessFields_advertise_keyword_Read",
	permission: boolean
};
	["AdvertisesDocAccessFields_advertise_keyword_Update"]: {
	__typename: "AdvertisesDocAccessFields_advertise_keyword_Update",
	permission: boolean
};
	["AdvertisesDocAccessFields_advertise_keyword_Delete"]: {
	__typename: "AdvertisesDocAccessFields_advertise_keyword_Delete",
	permission: boolean
};
	["AdvertisesDocAccessFields_advertise_location"]: {
	__typename: "AdvertisesDocAccessFields_advertise_location",
	create?: GraphQLTypes["AdvertisesDocAccessFields_advertise_location_Create"] | undefined,
	read?: GraphQLTypes["AdvertisesDocAccessFields_advertise_location_Read"] | undefined,
	update?: GraphQLTypes["AdvertisesDocAccessFields_advertise_location_Update"] | undefined,
	delete?: GraphQLTypes["AdvertisesDocAccessFields_advertise_location_Delete"] | undefined
};
	["AdvertisesDocAccessFields_advertise_location_Create"]: {
	__typename: "AdvertisesDocAccessFields_advertise_location_Create",
	permission: boolean
};
	["AdvertisesDocAccessFields_advertise_location_Read"]: {
	__typename: "AdvertisesDocAccessFields_advertise_location_Read",
	permission: boolean
};
	["AdvertisesDocAccessFields_advertise_location_Update"]: {
	__typename: "AdvertisesDocAccessFields_advertise_location_Update",
	permission: boolean
};
	["AdvertisesDocAccessFields_advertise_location_Delete"]: {
	__typename: "AdvertisesDocAccessFields_advertise_location_Delete",
	permission: boolean
};
	["AdvertisesDocAccessFields_advertise_displayPosition"]: {
	__typename: "AdvertisesDocAccessFields_advertise_displayPosition",
	create?: GraphQLTypes["AdvertisesDocAccessFields_advertise_displayPosition_Create"] | undefined,
	read?: GraphQLTypes["AdvertisesDocAccessFields_advertise_displayPosition_Read"] | undefined,
	update?: GraphQLTypes["AdvertisesDocAccessFields_advertise_displayPosition_Update"] | undefined,
	delete?: GraphQLTypes["AdvertisesDocAccessFields_advertise_displayPosition_Delete"] | undefined
};
	["AdvertisesDocAccessFields_advertise_displayPosition_Create"]: {
	__typename: "AdvertisesDocAccessFields_advertise_displayPosition_Create",
	permission: boolean
};
	["AdvertisesDocAccessFields_advertise_displayPosition_Read"]: {
	__typename: "AdvertisesDocAccessFields_advertise_displayPosition_Read",
	permission: boolean
};
	["AdvertisesDocAccessFields_advertise_displayPosition_Update"]: {
	__typename: "AdvertisesDocAccessFields_advertise_displayPosition_Update",
	permission: boolean
};
	["AdvertisesDocAccessFields_advertise_displayPosition_Delete"]: {
	__typename: "AdvertisesDocAccessFields_advertise_displayPosition_Delete",
	permission: boolean
};
	["AdvertisesDocAccessFields_advertise_rankingListRemark"]: {
	__typename: "AdvertisesDocAccessFields_advertise_rankingListRemark",
	create?: GraphQLTypes["AdvertisesDocAccessFields_advertise_rankingListRemark_Create"] | undefined,
	read?: GraphQLTypes["AdvertisesDocAccessFields_advertise_rankingListRemark_Read"] | undefined,
	update?: GraphQLTypes["AdvertisesDocAccessFields_advertise_rankingListRemark_Update"] | undefined,
	delete?: GraphQLTypes["AdvertisesDocAccessFields_advertise_rankingListRemark_Delete"] | undefined
};
	["AdvertisesDocAccessFields_advertise_rankingListRemark_Create"]: {
	__typename: "AdvertisesDocAccessFields_advertise_rankingListRemark_Create",
	permission: boolean
};
	["AdvertisesDocAccessFields_advertise_rankingListRemark_Read"]: {
	__typename: "AdvertisesDocAccessFields_advertise_rankingListRemark_Read",
	permission: boolean
};
	["AdvertisesDocAccessFields_advertise_rankingListRemark_Update"]: {
	__typename: "AdvertisesDocAccessFields_advertise_rankingListRemark_Update",
	permission: boolean
};
	["AdvertisesDocAccessFields_advertise_rankingListRemark_Delete"]: {
	__typename: "AdvertisesDocAccessFields_advertise_rankingListRemark_Delete",
	permission: boolean
};
	["AdvertisesDocAccessFields_advertiseStartDate"]: {
	__typename: "AdvertisesDocAccessFields_advertiseStartDate",
	create?: GraphQLTypes["AdvertisesDocAccessFields_advertiseStartDate_Create"] | undefined,
	read?: GraphQLTypes["AdvertisesDocAccessFields_advertiseStartDate_Read"] | undefined,
	update?: GraphQLTypes["AdvertisesDocAccessFields_advertiseStartDate_Update"] | undefined,
	delete?: GraphQLTypes["AdvertisesDocAccessFields_advertiseStartDate_Delete"] | undefined
};
	["AdvertisesDocAccessFields_advertiseStartDate_Create"]: {
	__typename: "AdvertisesDocAccessFields_advertiseStartDate_Create",
	permission: boolean
};
	["AdvertisesDocAccessFields_advertiseStartDate_Read"]: {
	__typename: "AdvertisesDocAccessFields_advertiseStartDate_Read",
	permission: boolean
};
	["AdvertisesDocAccessFields_advertiseStartDate_Update"]: {
	__typename: "AdvertisesDocAccessFields_advertiseStartDate_Update",
	permission: boolean
};
	["AdvertisesDocAccessFields_advertiseStartDate_Delete"]: {
	__typename: "AdvertisesDocAccessFields_advertiseStartDate_Delete",
	permission: boolean
};
	["AdvertisesDocAccessFields_advertiseEndDate"]: {
	__typename: "AdvertisesDocAccessFields_advertiseEndDate",
	create?: GraphQLTypes["AdvertisesDocAccessFields_advertiseEndDate_Create"] | undefined,
	read?: GraphQLTypes["AdvertisesDocAccessFields_advertiseEndDate_Read"] | undefined,
	update?: GraphQLTypes["AdvertisesDocAccessFields_advertiseEndDate_Update"] | undefined,
	delete?: GraphQLTypes["AdvertisesDocAccessFields_advertiseEndDate_Delete"] | undefined
};
	["AdvertisesDocAccessFields_advertiseEndDate_Create"]: {
	__typename: "AdvertisesDocAccessFields_advertiseEndDate_Create",
	permission: boolean
};
	["AdvertisesDocAccessFields_advertiseEndDate_Read"]: {
	__typename: "AdvertisesDocAccessFields_advertiseEndDate_Read",
	permission: boolean
};
	["AdvertisesDocAccessFields_advertiseEndDate_Update"]: {
	__typename: "AdvertisesDocAccessFields_advertiseEndDate_Update",
	permission: boolean
};
	["AdvertisesDocAccessFields_advertiseEndDate_Delete"]: {
	__typename: "AdvertisesDocAccessFields_advertiseEndDate_Delete",
	permission: boolean
};
	["AdvertisesDocAccessFields_type"]: {
	__typename: "AdvertisesDocAccessFields_type",
	create?: GraphQLTypes["AdvertisesDocAccessFields_type_Create"] | undefined,
	read?: GraphQLTypes["AdvertisesDocAccessFields_type_Read"] | undefined,
	update?: GraphQLTypes["AdvertisesDocAccessFields_type_Update"] | undefined,
	delete?: GraphQLTypes["AdvertisesDocAccessFields_type_Delete"] | undefined
};
	["AdvertisesDocAccessFields_type_Create"]: {
	__typename: "AdvertisesDocAccessFields_type_Create",
	permission: boolean
};
	["AdvertisesDocAccessFields_type_Read"]: {
	__typename: "AdvertisesDocAccessFields_type_Read",
	permission: boolean
};
	["AdvertisesDocAccessFields_type_Update"]: {
	__typename: "AdvertisesDocAccessFields_type_Update",
	permission: boolean
};
	["AdvertisesDocAccessFields_type_Delete"]: {
	__typename: "AdvertisesDocAccessFields_type_Delete",
	permission: boolean
};
	["AdvertisesDocAccessFields_invoiceDescription"]: {
	__typename: "AdvertisesDocAccessFields_invoiceDescription",
	create?: GraphQLTypes["AdvertisesDocAccessFields_invoiceDescription_Create"] | undefined,
	read?: GraphQLTypes["AdvertisesDocAccessFields_invoiceDescription_Read"] | undefined,
	update?: GraphQLTypes["AdvertisesDocAccessFields_invoiceDescription_Update"] | undefined,
	delete?: GraphQLTypes["AdvertisesDocAccessFields_invoiceDescription_Delete"] | undefined
};
	["AdvertisesDocAccessFields_invoiceDescription_Create"]: {
	__typename: "AdvertisesDocAccessFields_invoiceDescription_Create",
	permission: boolean
};
	["AdvertisesDocAccessFields_invoiceDescription_Read"]: {
	__typename: "AdvertisesDocAccessFields_invoiceDescription_Read",
	permission: boolean
};
	["AdvertisesDocAccessFields_invoiceDescription_Update"]: {
	__typename: "AdvertisesDocAccessFields_invoiceDescription_Update",
	permission: boolean
};
	["AdvertisesDocAccessFields_invoiceDescription_Delete"]: {
	__typename: "AdvertisesDocAccessFields_invoiceDescription_Delete",
	permission: boolean
};
	["AdvertisesDocAccessFields_standardPrice"]: {
	__typename: "AdvertisesDocAccessFields_standardPrice",
	create?: GraphQLTypes["AdvertisesDocAccessFields_standardPrice_Create"] | undefined,
	read?: GraphQLTypes["AdvertisesDocAccessFields_standardPrice_Read"] | undefined,
	update?: GraphQLTypes["AdvertisesDocAccessFields_standardPrice_Update"] | undefined,
	delete?: GraphQLTypes["AdvertisesDocAccessFields_standardPrice_Delete"] | undefined
};
	["AdvertisesDocAccessFields_standardPrice_Create"]: {
	__typename: "AdvertisesDocAccessFields_standardPrice_Create",
	permission: boolean
};
	["AdvertisesDocAccessFields_standardPrice_Read"]: {
	__typename: "AdvertisesDocAccessFields_standardPrice_Read",
	permission: boolean
};
	["AdvertisesDocAccessFields_standardPrice_Update"]: {
	__typename: "AdvertisesDocAccessFields_standardPrice_Update",
	permission: boolean
};
	["AdvertisesDocAccessFields_standardPrice_Delete"]: {
	__typename: "AdvertisesDocAccessFields_standardPrice_Delete",
	permission: boolean
};
	["AdvertisesDocAccessFields_discount"]: {
	__typename: "AdvertisesDocAccessFields_discount",
	create?: GraphQLTypes["AdvertisesDocAccessFields_discount_Create"] | undefined,
	read?: GraphQLTypes["AdvertisesDocAccessFields_discount_Read"] | undefined,
	update?: GraphQLTypes["AdvertisesDocAccessFields_discount_Update"] | undefined,
	delete?: GraphQLTypes["AdvertisesDocAccessFields_discount_Delete"] | undefined
};
	["AdvertisesDocAccessFields_discount_Create"]: {
	__typename: "AdvertisesDocAccessFields_discount_Create",
	permission: boolean
};
	["AdvertisesDocAccessFields_discount_Read"]: {
	__typename: "AdvertisesDocAccessFields_discount_Read",
	permission: boolean
};
	["AdvertisesDocAccessFields_discount_Update"]: {
	__typename: "AdvertisesDocAccessFields_discount_Update",
	permission: boolean
};
	["AdvertisesDocAccessFields_discount_Delete"]: {
	__typename: "AdvertisesDocAccessFields_discount_Delete",
	permission: boolean
};
	["AdvertisesDocAccessFields_discountPercentage"]: {
	__typename: "AdvertisesDocAccessFields_discountPercentage",
	create?: GraphQLTypes["AdvertisesDocAccessFields_discountPercentage_Create"] | undefined,
	read?: GraphQLTypes["AdvertisesDocAccessFields_discountPercentage_Read"] | undefined,
	update?: GraphQLTypes["AdvertisesDocAccessFields_discountPercentage_Update"] | undefined,
	delete?: GraphQLTypes["AdvertisesDocAccessFields_discountPercentage_Delete"] | undefined
};
	["AdvertisesDocAccessFields_discountPercentage_Create"]: {
	__typename: "AdvertisesDocAccessFields_discountPercentage_Create",
	permission: boolean
};
	["AdvertisesDocAccessFields_discountPercentage_Read"]: {
	__typename: "AdvertisesDocAccessFields_discountPercentage_Read",
	permission: boolean
};
	["AdvertisesDocAccessFields_discountPercentage_Update"]: {
	__typename: "AdvertisesDocAccessFields_discountPercentage_Update",
	permission: boolean
};
	["AdvertisesDocAccessFields_discountPercentage_Delete"]: {
	__typename: "AdvertisesDocAccessFields_discountPercentage_Delete",
	permission: boolean
};
	["AdvertisesDocAccessFields__calculateByDiscount"]: {
	__typename: "AdvertisesDocAccessFields__calculateByDiscount",
	create?: GraphQLTypes["AdvertisesDocAccessFields__calculateByDiscount_Create"] | undefined,
	read?: GraphQLTypes["AdvertisesDocAccessFields__calculateByDiscount_Read"] | undefined,
	update?: GraphQLTypes["AdvertisesDocAccessFields__calculateByDiscount_Update"] | undefined,
	delete?: GraphQLTypes["AdvertisesDocAccessFields__calculateByDiscount_Delete"] | undefined
};
	["AdvertisesDocAccessFields__calculateByDiscount_Create"]: {
	__typename: "AdvertisesDocAccessFields__calculateByDiscount_Create",
	permission: boolean
};
	["AdvertisesDocAccessFields__calculateByDiscount_Read"]: {
	__typename: "AdvertisesDocAccessFields__calculateByDiscount_Read",
	permission: boolean
};
	["AdvertisesDocAccessFields__calculateByDiscount_Update"]: {
	__typename: "AdvertisesDocAccessFields__calculateByDiscount_Update",
	permission: boolean
};
	["AdvertisesDocAccessFields__calculateByDiscount_Delete"]: {
	__typename: "AdvertisesDocAccessFields__calculateByDiscount_Delete",
	permission: boolean
};
	["AdvertisesDocAccessFields_timableCost"]: {
	__typename: "AdvertisesDocAccessFields_timableCost",
	create?: GraphQLTypes["AdvertisesDocAccessFields_timableCost_Create"] | undefined,
	read?: GraphQLTypes["AdvertisesDocAccessFields_timableCost_Read"] | undefined,
	update?: GraphQLTypes["AdvertisesDocAccessFields_timableCost_Update"] | undefined,
	delete?: GraphQLTypes["AdvertisesDocAccessFields_timableCost_Delete"] | undefined
};
	["AdvertisesDocAccessFields_timableCost_Create"]: {
	__typename: "AdvertisesDocAccessFields_timableCost_Create",
	permission: boolean
};
	["AdvertisesDocAccessFields_timableCost_Read"]: {
	__typename: "AdvertisesDocAccessFields_timableCost_Read",
	permission: boolean
};
	["AdvertisesDocAccessFields_timableCost_Update"]: {
	__typename: "AdvertisesDocAccessFields_timableCost_Update",
	permission: boolean
};
	["AdvertisesDocAccessFields_timableCost_Delete"]: {
	__typename: "AdvertisesDocAccessFields_timableCost_Delete",
	permission: boolean
};
	["AdvertisesDocAccessFields_remark"]: {
	__typename: "AdvertisesDocAccessFields_remark",
	create?: GraphQLTypes["AdvertisesDocAccessFields_remark_Create"] | undefined,
	read?: GraphQLTypes["AdvertisesDocAccessFields_remark_Read"] | undefined,
	update?: GraphQLTypes["AdvertisesDocAccessFields_remark_Update"] | undefined,
	delete?: GraphQLTypes["AdvertisesDocAccessFields_remark_Delete"] | undefined
};
	["AdvertisesDocAccessFields_remark_Create"]: {
	__typename: "AdvertisesDocAccessFields_remark_Create",
	permission: boolean
};
	["AdvertisesDocAccessFields_remark_Read"]: {
	__typename: "AdvertisesDocAccessFields_remark_Read",
	permission: boolean
};
	["AdvertisesDocAccessFields_remark_Update"]: {
	__typename: "AdvertisesDocAccessFields_remark_Update",
	permission: boolean
};
	["AdvertisesDocAccessFields_remark_Delete"]: {
	__typename: "AdvertisesDocAccessFields_remark_Delete",
	permission: boolean
};
	["AdvertisesDocAccessFields_public"]: {
	__typename: "AdvertisesDocAccessFields_public",
	create?: GraphQLTypes["AdvertisesDocAccessFields_public_Create"] | undefined,
	read?: GraphQLTypes["AdvertisesDocAccessFields_public_Read"] | undefined,
	update?: GraphQLTypes["AdvertisesDocAccessFields_public_Update"] | undefined,
	delete?: GraphQLTypes["AdvertisesDocAccessFields_public_Delete"] | undefined
};
	["AdvertisesDocAccessFields_public_Create"]: {
	__typename: "AdvertisesDocAccessFields_public_Create",
	permission: boolean
};
	["AdvertisesDocAccessFields_public_Read"]: {
	__typename: "AdvertisesDocAccessFields_public_Read",
	permission: boolean
};
	["AdvertisesDocAccessFields_public_Update"]: {
	__typename: "AdvertisesDocAccessFields_public_Update",
	permission: boolean
};
	["AdvertisesDocAccessFields_public_Delete"]: {
	__typename: "AdvertisesDocAccessFields_public_Delete",
	permission: boolean
};
	["AdvertisesDocAccessFields_campaignName"]: {
	__typename: "AdvertisesDocAccessFields_campaignName",
	create?: GraphQLTypes["AdvertisesDocAccessFields_campaignName_Create"] | undefined,
	read?: GraphQLTypes["AdvertisesDocAccessFields_campaignName_Read"] | undefined,
	update?: GraphQLTypes["AdvertisesDocAccessFields_campaignName_Update"] | undefined,
	delete?: GraphQLTypes["AdvertisesDocAccessFields_campaignName_Delete"] | undefined
};
	["AdvertisesDocAccessFields_campaignName_Create"]: {
	__typename: "AdvertisesDocAccessFields_campaignName_Create",
	permission: boolean
};
	["AdvertisesDocAccessFields_campaignName_Read"]: {
	__typename: "AdvertisesDocAccessFields_campaignName_Read",
	permission: boolean
};
	["AdvertisesDocAccessFields_campaignName_Update"]: {
	__typename: "AdvertisesDocAccessFields_campaignName_Update",
	permission: boolean
};
	["AdvertisesDocAccessFields_campaignName_Delete"]: {
	__typename: "AdvertisesDocAccessFields_campaignName_Delete",
	permission: boolean
};
	["AdvertisesDocAccessFields_campaignId"]: {
	__typename: "AdvertisesDocAccessFields_campaignId",
	create?: GraphQLTypes["AdvertisesDocAccessFields_campaignId_Create"] | undefined,
	read?: GraphQLTypes["AdvertisesDocAccessFields_campaignId_Read"] | undefined,
	update?: GraphQLTypes["AdvertisesDocAccessFields_campaignId_Update"] | undefined,
	delete?: GraphQLTypes["AdvertisesDocAccessFields_campaignId_Delete"] | undefined
};
	["AdvertisesDocAccessFields_campaignId_Create"]: {
	__typename: "AdvertisesDocAccessFields_campaignId_Create",
	permission: boolean
};
	["AdvertisesDocAccessFields_campaignId_Read"]: {
	__typename: "AdvertisesDocAccessFields_campaignId_Read",
	permission: boolean
};
	["AdvertisesDocAccessFields_campaignId_Update"]: {
	__typename: "AdvertisesDocAccessFields_campaignId_Update",
	permission: boolean
};
	["AdvertisesDocAccessFields_campaignId_Delete"]: {
	__typename: "AdvertisesDocAccessFields_campaignId_Delete",
	permission: boolean
};
	["AdvertisesDocAccessFields_status"]: {
	__typename: "AdvertisesDocAccessFields_status",
	create?: GraphQLTypes["AdvertisesDocAccessFields_status_Create"] | undefined,
	read?: GraphQLTypes["AdvertisesDocAccessFields_status_Read"] | undefined,
	update?: GraphQLTypes["AdvertisesDocAccessFields_status_Update"] | undefined,
	delete?: GraphQLTypes["AdvertisesDocAccessFields_status_Delete"] | undefined
};
	["AdvertisesDocAccessFields_status_Create"]: {
	__typename: "AdvertisesDocAccessFields_status_Create",
	permission: boolean
};
	["AdvertisesDocAccessFields_status_Read"]: {
	__typename: "AdvertisesDocAccessFields_status_Read",
	permission: boolean
};
	["AdvertisesDocAccessFields_status_Update"]: {
	__typename: "AdvertisesDocAccessFields_status_Update",
	permission: boolean
};
	["AdvertisesDocAccessFields_status_Delete"]: {
	__typename: "AdvertisesDocAccessFields_status_Delete",
	permission: boolean
};
	["AdvertisesDocAccessFields_updatedAt"]: {
	__typename: "AdvertisesDocAccessFields_updatedAt",
	create?: GraphQLTypes["AdvertisesDocAccessFields_updatedAt_Create"] | undefined,
	read?: GraphQLTypes["AdvertisesDocAccessFields_updatedAt_Read"] | undefined,
	update?: GraphQLTypes["AdvertisesDocAccessFields_updatedAt_Update"] | undefined,
	delete?: GraphQLTypes["AdvertisesDocAccessFields_updatedAt_Delete"] | undefined
};
	["AdvertisesDocAccessFields_updatedAt_Create"]: {
	__typename: "AdvertisesDocAccessFields_updatedAt_Create",
	permission: boolean
};
	["AdvertisesDocAccessFields_updatedAt_Read"]: {
	__typename: "AdvertisesDocAccessFields_updatedAt_Read",
	permission: boolean
};
	["AdvertisesDocAccessFields_updatedAt_Update"]: {
	__typename: "AdvertisesDocAccessFields_updatedAt_Update",
	permission: boolean
};
	["AdvertisesDocAccessFields_updatedAt_Delete"]: {
	__typename: "AdvertisesDocAccessFields_updatedAt_Delete",
	permission: boolean
};
	["AdvertisesDocAccessFields_createdAt"]: {
	__typename: "AdvertisesDocAccessFields_createdAt",
	create?: GraphQLTypes["AdvertisesDocAccessFields_createdAt_Create"] | undefined,
	read?: GraphQLTypes["AdvertisesDocAccessFields_createdAt_Read"] | undefined,
	update?: GraphQLTypes["AdvertisesDocAccessFields_createdAt_Update"] | undefined,
	delete?: GraphQLTypes["AdvertisesDocAccessFields_createdAt_Delete"] | undefined
};
	["AdvertisesDocAccessFields_createdAt_Create"]: {
	__typename: "AdvertisesDocAccessFields_createdAt_Create",
	permission: boolean
};
	["AdvertisesDocAccessFields_createdAt_Read"]: {
	__typename: "AdvertisesDocAccessFields_createdAt_Read",
	permission: boolean
};
	["AdvertisesDocAccessFields_createdAt_Update"]: {
	__typename: "AdvertisesDocAccessFields_createdAt_Update",
	permission: boolean
};
	["AdvertisesDocAccessFields_createdAt_Delete"]: {
	__typename: "AdvertisesDocAccessFields_createdAt_Delete",
	permission: boolean
};
	["AdvertisesCreateDocAccess"]: {
	__typename: "AdvertisesCreateDocAccess",
	permission: boolean,
	where?: GraphQLTypes["JSONObject"] | undefined
};
	["AdvertisesReadDocAccess"]: {
	__typename: "AdvertisesReadDocAccess",
	permission: boolean,
	where?: GraphQLTypes["JSONObject"] | undefined
};
	["AdvertisesUpdateDocAccess"]: {
	__typename: "AdvertisesUpdateDocAccess",
	permission: boolean,
	where?: GraphQLTypes["JSONObject"] | undefined
};
	["AdvertisesDeleteDocAccess"]: {
	__typename: "AdvertisesDeleteDocAccess",
	permission: boolean,
	where?: GraphQLTypes["JSONObject"] | undefined
};
	["Static"]: {
	__typename: "Static",
	id?: string | undefined,
	name?: string | undefined,
	key?: string | undefined,
	content?: Array<GraphQLTypes["Static_Content"]> | undefined,
	region?: GraphQLTypes["Static_region"] | undefined,
	updatedAt?: GraphQLTypes["DateTime"] | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined
};
	["Static_Content"]:{
        	__typename:"RichText"
        	['...on RichText']: '__union' & GraphQLTypes["RichText"];
};
	["Static_region"]: Static_region;
	["Statics"]: {
	__typename: "Statics",
	docs?: Array<GraphQLTypes["Static"] | undefined> | undefined,
	hasNextPage?: boolean | undefined,
	hasPrevPage?: boolean | undefined,
	limit?: number | undefined,
	nextPage?: number | undefined,
	offset?: number | undefined,
	page?: number | undefined,
	pagingCounter?: number | undefined,
	prevPage?: number | undefined,
	totalDocs?: number | undefined,
	totalPages?: number | undefined
};
	["Static_where"]: {
		name?: GraphQLTypes["Static_name_operator"] | undefined,
	key?: GraphQLTypes["Static_key_operator"] | undefined,
	region?: GraphQLTypes["Static_region_operator"] | undefined,
	updatedAt?: GraphQLTypes["Static_updatedAt_operator"] | undefined,
	createdAt?: GraphQLTypes["Static_createdAt_operator"] | undefined,
	id?: GraphQLTypes["Static_id_operator"] | undefined,
	AND?: Array<GraphQLTypes["Static_where_and"] | undefined> | undefined,
	OR?: Array<GraphQLTypes["Static_where_or"] | undefined> | undefined
};
	["Static_name_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Static_key_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Static_region_operator"]: {
		equals?: GraphQLTypes["Static_region_Input"] | undefined,
	not_equals?: GraphQLTypes["Static_region_Input"] | undefined,
	in?: Array<GraphQLTypes["Static_region_Input"] | undefined> | undefined,
	not_in?: Array<GraphQLTypes["Static_region_Input"] | undefined> | undefined,
	all?: Array<GraphQLTypes["Static_region_Input"] | undefined> | undefined,
	exists?: boolean | undefined
};
	["Static_region_Input"]: Static_region_Input;
	["Static_updatedAt_operator"]: {
		equals?: GraphQLTypes["DateTime"] | undefined,
	not_equals?: GraphQLTypes["DateTime"] | undefined,
	greater_than_equal?: GraphQLTypes["DateTime"] | undefined,
	greater_than?: GraphQLTypes["DateTime"] | undefined,
	less_than_equal?: GraphQLTypes["DateTime"] | undefined,
	less_than?: GraphQLTypes["DateTime"] | undefined,
	like?: GraphQLTypes["DateTime"] | undefined,
	exists?: boolean | undefined
};
	["Static_createdAt_operator"]: {
		equals?: GraphQLTypes["DateTime"] | undefined,
	not_equals?: GraphQLTypes["DateTime"] | undefined,
	greater_than_equal?: GraphQLTypes["DateTime"] | undefined,
	greater_than?: GraphQLTypes["DateTime"] | undefined,
	less_than_equal?: GraphQLTypes["DateTime"] | undefined,
	less_than?: GraphQLTypes["DateTime"] | undefined,
	like?: GraphQLTypes["DateTime"] | undefined,
	exists?: boolean | undefined
};
	["Static_id_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["Static_where_and"]: {
		name?: GraphQLTypes["Static_name_operator"] | undefined,
	key?: GraphQLTypes["Static_key_operator"] | undefined,
	region?: GraphQLTypes["Static_region_operator"] | undefined,
	updatedAt?: GraphQLTypes["Static_updatedAt_operator"] | undefined,
	createdAt?: GraphQLTypes["Static_createdAt_operator"] | undefined,
	id?: GraphQLTypes["Static_id_operator"] | undefined,
	AND?: Array<GraphQLTypes["Static_where_and"] | undefined> | undefined,
	OR?: Array<GraphQLTypes["Static_where_or"] | undefined> | undefined
};
	["Static_where_or"]: {
		name?: GraphQLTypes["Static_name_operator"] | undefined,
	key?: GraphQLTypes["Static_key_operator"] | undefined,
	region?: GraphQLTypes["Static_region_operator"] | undefined,
	updatedAt?: GraphQLTypes["Static_updatedAt_operator"] | undefined,
	createdAt?: GraphQLTypes["Static_createdAt_operator"] | undefined,
	id?: GraphQLTypes["Static_id_operator"] | undefined,
	AND?: Array<GraphQLTypes["Static_where_and"] | undefined> | undefined,
	OR?: Array<GraphQLTypes["Static_where_or"] | undefined> | undefined
};
	["countStatics"]: {
	__typename: "countStatics",
	totalDocs?: number | undefined
};
	["staticsDocAccess"]: {
	__typename: "staticsDocAccess",
	fields?: GraphQLTypes["StaticsDocAccessFields"] | undefined,
	create?: GraphQLTypes["StaticsCreateDocAccess"] | undefined,
	read?: GraphQLTypes["StaticsReadDocAccess"] | undefined,
	update?: GraphQLTypes["StaticsUpdateDocAccess"] | undefined,
	delete?: GraphQLTypes["StaticsDeleteDocAccess"] | undefined
};
	["StaticsDocAccessFields"]: {
	__typename: "StaticsDocAccessFields",
	name?: GraphQLTypes["StaticsDocAccessFields_name"] | undefined,
	key?: GraphQLTypes["StaticsDocAccessFields_key"] | undefined,
	content?: GraphQLTypes["StaticsDocAccessFields_content"] | undefined,
	region?: GraphQLTypes["StaticsDocAccessFields_region"] | undefined,
	updatedAt?: GraphQLTypes["StaticsDocAccessFields_updatedAt"] | undefined,
	createdAt?: GraphQLTypes["StaticsDocAccessFields_createdAt"] | undefined
};
	["StaticsDocAccessFields_name"]: {
	__typename: "StaticsDocAccessFields_name",
	create?: GraphQLTypes["StaticsDocAccessFields_name_Create"] | undefined,
	read?: GraphQLTypes["StaticsDocAccessFields_name_Read"] | undefined,
	update?: GraphQLTypes["StaticsDocAccessFields_name_Update"] | undefined,
	delete?: GraphQLTypes["StaticsDocAccessFields_name_Delete"] | undefined
};
	["StaticsDocAccessFields_name_Create"]: {
	__typename: "StaticsDocAccessFields_name_Create",
	permission: boolean
};
	["StaticsDocAccessFields_name_Read"]: {
	__typename: "StaticsDocAccessFields_name_Read",
	permission: boolean
};
	["StaticsDocAccessFields_name_Update"]: {
	__typename: "StaticsDocAccessFields_name_Update",
	permission: boolean
};
	["StaticsDocAccessFields_name_Delete"]: {
	__typename: "StaticsDocAccessFields_name_Delete",
	permission: boolean
};
	["StaticsDocAccessFields_key"]: {
	__typename: "StaticsDocAccessFields_key",
	create?: GraphQLTypes["StaticsDocAccessFields_key_Create"] | undefined,
	read?: GraphQLTypes["StaticsDocAccessFields_key_Read"] | undefined,
	update?: GraphQLTypes["StaticsDocAccessFields_key_Update"] | undefined,
	delete?: GraphQLTypes["StaticsDocAccessFields_key_Delete"] | undefined
};
	["StaticsDocAccessFields_key_Create"]: {
	__typename: "StaticsDocAccessFields_key_Create",
	permission: boolean
};
	["StaticsDocAccessFields_key_Read"]: {
	__typename: "StaticsDocAccessFields_key_Read",
	permission: boolean
};
	["StaticsDocAccessFields_key_Update"]: {
	__typename: "StaticsDocAccessFields_key_Update",
	permission: boolean
};
	["StaticsDocAccessFields_key_Delete"]: {
	__typename: "StaticsDocAccessFields_key_Delete",
	permission: boolean
};
	["StaticsDocAccessFields_content"]: {
	__typename: "StaticsDocAccessFields_content",
	create?: GraphQLTypes["StaticsDocAccessFields_content_Create"] | undefined,
	read?: GraphQLTypes["StaticsDocAccessFields_content_Read"] | undefined,
	update?: GraphQLTypes["StaticsDocAccessFields_content_Update"] | undefined,
	delete?: GraphQLTypes["StaticsDocAccessFields_content_Delete"] | undefined
};
	["StaticsDocAccessFields_content_Create"]: {
	__typename: "StaticsDocAccessFields_content_Create",
	permission: boolean
};
	["StaticsDocAccessFields_content_Read"]: {
	__typename: "StaticsDocAccessFields_content_Read",
	permission: boolean
};
	["StaticsDocAccessFields_content_Update"]: {
	__typename: "StaticsDocAccessFields_content_Update",
	permission: boolean
};
	["StaticsDocAccessFields_content_Delete"]: {
	__typename: "StaticsDocAccessFields_content_Delete",
	permission: boolean
};
	["StaticsDocAccessFields_region"]: {
	__typename: "StaticsDocAccessFields_region",
	create?: GraphQLTypes["StaticsDocAccessFields_region_Create"] | undefined,
	read?: GraphQLTypes["StaticsDocAccessFields_region_Read"] | undefined,
	update?: GraphQLTypes["StaticsDocAccessFields_region_Update"] | undefined,
	delete?: GraphQLTypes["StaticsDocAccessFields_region_Delete"] | undefined
};
	["StaticsDocAccessFields_region_Create"]: {
	__typename: "StaticsDocAccessFields_region_Create",
	permission: boolean
};
	["StaticsDocAccessFields_region_Read"]: {
	__typename: "StaticsDocAccessFields_region_Read",
	permission: boolean
};
	["StaticsDocAccessFields_region_Update"]: {
	__typename: "StaticsDocAccessFields_region_Update",
	permission: boolean
};
	["StaticsDocAccessFields_region_Delete"]: {
	__typename: "StaticsDocAccessFields_region_Delete",
	permission: boolean
};
	["StaticsDocAccessFields_updatedAt"]: {
	__typename: "StaticsDocAccessFields_updatedAt",
	create?: GraphQLTypes["StaticsDocAccessFields_updatedAt_Create"] | undefined,
	read?: GraphQLTypes["StaticsDocAccessFields_updatedAt_Read"] | undefined,
	update?: GraphQLTypes["StaticsDocAccessFields_updatedAt_Update"] | undefined,
	delete?: GraphQLTypes["StaticsDocAccessFields_updatedAt_Delete"] | undefined
};
	["StaticsDocAccessFields_updatedAt_Create"]: {
	__typename: "StaticsDocAccessFields_updatedAt_Create",
	permission: boolean
};
	["StaticsDocAccessFields_updatedAt_Read"]: {
	__typename: "StaticsDocAccessFields_updatedAt_Read",
	permission: boolean
};
	["StaticsDocAccessFields_updatedAt_Update"]: {
	__typename: "StaticsDocAccessFields_updatedAt_Update",
	permission: boolean
};
	["StaticsDocAccessFields_updatedAt_Delete"]: {
	__typename: "StaticsDocAccessFields_updatedAt_Delete",
	permission: boolean
};
	["StaticsDocAccessFields_createdAt"]: {
	__typename: "StaticsDocAccessFields_createdAt",
	create?: GraphQLTypes["StaticsDocAccessFields_createdAt_Create"] | undefined,
	read?: GraphQLTypes["StaticsDocAccessFields_createdAt_Read"] | undefined,
	update?: GraphQLTypes["StaticsDocAccessFields_createdAt_Update"] | undefined,
	delete?: GraphQLTypes["StaticsDocAccessFields_createdAt_Delete"] | undefined
};
	["StaticsDocAccessFields_createdAt_Create"]: {
	__typename: "StaticsDocAccessFields_createdAt_Create",
	permission: boolean
};
	["StaticsDocAccessFields_createdAt_Read"]: {
	__typename: "StaticsDocAccessFields_createdAt_Read",
	permission: boolean
};
	["StaticsDocAccessFields_createdAt_Update"]: {
	__typename: "StaticsDocAccessFields_createdAt_Update",
	permission: boolean
};
	["StaticsDocAccessFields_createdAt_Delete"]: {
	__typename: "StaticsDocAccessFields_createdAt_Delete",
	permission: boolean
};
	["StaticsCreateDocAccess"]: {
	__typename: "StaticsCreateDocAccess",
	permission: boolean,
	where?: GraphQLTypes["JSONObject"] | undefined
};
	["StaticsReadDocAccess"]: {
	__typename: "StaticsReadDocAccess",
	permission: boolean,
	where?: GraphQLTypes["JSONObject"] | undefined
};
	["StaticsUpdateDocAccess"]: {
	__typename: "StaticsUpdateDocAccess",
	permission: boolean,
	where?: GraphQLTypes["JSONObject"] | undefined
};
	["StaticsDeleteDocAccess"]: {
	__typename: "StaticsDeleteDocAccess",
	permission: boolean,
	where?: GraphQLTypes["JSONObject"] | undefined
};
	["EventView"]: {
	__typename: "EventView",
	id?: string | undefined,
	date: GraphQLTypes["DateTime"],
	eventId: GraphQLTypes["Event"],
	viewCount: number,
	updatedAt?: GraphQLTypes["DateTime"] | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined
};
	["EventViews"]: {
	__typename: "EventViews",
	docs?: Array<GraphQLTypes["EventView"] | undefined> | undefined,
	hasNextPage?: boolean | undefined,
	hasPrevPage?: boolean | undefined,
	limit?: number | undefined,
	nextPage?: number | undefined,
	offset?: number | undefined,
	page?: number | undefined,
	pagingCounter?: number | undefined,
	prevPage?: number | undefined,
	totalDocs?: number | undefined,
	totalPages?: number | undefined
};
	["EventView_where"]: {
		date?: GraphQLTypes["EventView_date_operator"] | undefined,
	eventId?: GraphQLTypes["EventView_eventId_operator"] | undefined,
	viewCount?: GraphQLTypes["EventView_viewCount_operator"] | undefined,
	updatedAt?: GraphQLTypes["EventView_updatedAt_operator"] | undefined,
	createdAt?: GraphQLTypes["EventView_createdAt_operator"] | undefined,
	id?: GraphQLTypes["EventView_id_operator"] | undefined,
	AND?: Array<GraphQLTypes["EventView_where_and"] | undefined> | undefined,
	OR?: Array<GraphQLTypes["EventView_where_or"] | undefined> | undefined
};
	["EventView_date_operator"]: {
		equals?: GraphQLTypes["DateTime"] | undefined,
	not_equals?: GraphQLTypes["DateTime"] | undefined,
	greater_than_equal?: GraphQLTypes["DateTime"] | undefined,
	greater_than?: GraphQLTypes["DateTime"] | undefined,
	less_than_equal?: GraphQLTypes["DateTime"] | undefined,
	less_than?: GraphQLTypes["DateTime"] | undefined,
	like?: GraphQLTypes["DateTime"] | undefined
};
	["EventView_eventId_operator"]: {
		equals?: GraphQLTypes["JSON"] | undefined,
	not_equals?: GraphQLTypes["JSON"] | undefined,
	in?: Array<GraphQLTypes["JSON"] | undefined> | undefined,
	not_in?: Array<GraphQLTypes["JSON"] | undefined> | undefined,
	all?: Array<GraphQLTypes["JSON"] | undefined> | undefined
};
	["EventView_viewCount_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined
};
	["EventView_updatedAt_operator"]: {
		equals?: GraphQLTypes["DateTime"] | undefined,
	not_equals?: GraphQLTypes["DateTime"] | undefined,
	greater_than_equal?: GraphQLTypes["DateTime"] | undefined,
	greater_than?: GraphQLTypes["DateTime"] | undefined,
	less_than_equal?: GraphQLTypes["DateTime"] | undefined,
	less_than?: GraphQLTypes["DateTime"] | undefined,
	like?: GraphQLTypes["DateTime"] | undefined,
	exists?: boolean | undefined
};
	["EventView_createdAt_operator"]: {
		equals?: GraphQLTypes["DateTime"] | undefined,
	not_equals?: GraphQLTypes["DateTime"] | undefined,
	greater_than_equal?: GraphQLTypes["DateTime"] | undefined,
	greater_than?: GraphQLTypes["DateTime"] | undefined,
	less_than_equal?: GraphQLTypes["DateTime"] | undefined,
	less_than?: GraphQLTypes["DateTime"] | undefined,
	like?: GraphQLTypes["DateTime"] | undefined,
	exists?: boolean | undefined
};
	["EventView_id_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["EventView_where_and"]: {
		date?: GraphQLTypes["EventView_date_operator"] | undefined,
	eventId?: GraphQLTypes["EventView_eventId_operator"] | undefined,
	viewCount?: GraphQLTypes["EventView_viewCount_operator"] | undefined,
	updatedAt?: GraphQLTypes["EventView_updatedAt_operator"] | undefined,
	createdAt?: GraphQLTypes["EventView_createdAt_operator"] | undefined,
	id?: GraphQLTypes["EventView_id_operator"] | undefined,
	AND?: Array<GraphQLTypes["EventView_where_and"] | undefined> | undefined,
	OR?: Array<GraphQLTypes["EventView_where_or"] | undefined> | undefined
};
	["EventView_where_or"]: {
		date?: GraphQLTypes["EventView_date_operator"] | undefined,
	eventId?: GraphQLTypes["EventView_eventId_operator"] | undefined,
	viewCount?: GraphQLTypes["EventView_viewCount_operator"] | undefined,
	updatedAt?: GraphQLTypes["EventView_updatedAt_operator"] | undefined,
	createdAt?: GraphQLTypes["EventView_createdAt_operator"] | undefined,
	id?: GraphQLTypes["EventView_id_operator"] | undefined,
	AND?: Array<GraphQLTypes["EventView_where_and"] | undefined> | undefined,
	OR?: Array<GraphQLTypes["EventView_where_or"] | undefined> | undefined
};
	["countEventViews"]: {
	__typename: "countEventViews",
	totalDocs?: number | undefined
};
	["event_viewsDocAccess"]: {
	__typename: "event_viewsDocAccess",
	fields?: GraphQLTypes["EventViewsDocAccessFields"] | undefined,
	create?: GraphQLTypes["EventViewsCreateDocAccess"] | undefined,
	read?: GraphQLTypes["EventViewsReadDocAccess"] | undefined,
	update?: GraphQLTypes["EventViewsUpdateDocAccess"] | undefined,
	delete?: GraphQLTypes["EventViewsDeleteDocAccess"] | undefined
};
	["EventViewsDocAccessFields"]: {
	__typename: "EventViewsDocAccessFields",
	date?: GraphQLTypes["EventViewsDocAccessFields_date"] | undefined,
	eventId?: GraphQLTypes["EventViewsDocAccessFields_eventId"] | undefined,
	viewCount?: GraphQLTypes["EventViewsDocAccessFields_viewCount"] | undefined,
	updatedAt?: GraphQLTypes["EventViewsDocAccessFields_updatedAt"] | undefined,
	createdAt?: GraphQLTypes["EventViewsDocAccessFields_createdAt"] | undefined
};
	["EventViewsDocAccessFields_date"]: {
	__typename: "EventViewsDocAccessFields_date",
	create?: GraphQLTypes["EventViewsDocAccessFields_date_Create"] | undefined,
	read?: GraphQLTypes["EventViewsDocAccessFields_date_Read"] | undefined,
	update?: GraphQLTypes["EventViewsDocAccessFields_date_Update"] | undefined,
	delete?: GraphQLTypes["EventViewsDocAccessFields_date_Delete"] | undefined
};
	["EventViewsDocAccessFields_date_Create"]: {
	__typename: "EventViewsDocAccessFields_date_Create",
	permission: boolean
};
	["EventViewsDocAccessFields_date_Read"]: {
	__typename: "EventViewsDocAccessFields_date_Read",
	permission: boolean
};
	["EventViewsDocAccessFields_date_Update"]: {
	__typename: "EventViewsDocAccessFields_date_Update",
	permission: boolean
};
	["EventViewsDocAccessFields_date_Delete"]: {
	__typename: "EventViewsDocAccessFields_date_Delete",
	permission: boolean
};
	["EventViewsDocAccessFields_eventId"]: {
	__typename: "EventViewsDocAccessFields_eventId",
	create?: GraphQLTypes["EventViewsDocAccessFields_eventId_Create"] | undefined,
	read?: GraphQLTypes["EventViewsDocAccessFields_eventId_Read"] | undefined,
	update?: GraphQLTypes["EventViewsDocAccessFields_eventId_Update"] | undefined,
	delete?: GraphQLTypes["EventViewsDocAccessFields_eventId_Delete"] | undefined
};
	["EventViewsDocAccessFields_eventId_Create"]: {
	__typename: "EventViewsDocAccessFields_eventId_Create",
	permission: boolean
};
	["EventViewsDocAccessFields_eventId_Read"]: {
	__typename: "EventViewsDocAccessFields_eventId_Read",
	permission: boolean
};
	["EventViewsDocAccessFields_eventId_Update"]: {
	__typename: "EventViewsDocAccessFields_eventId_Update",
	permission: boolean
};
	["EventViewsDocAccessFields_eventId_Delete"]: {
	__typename: "EventViewsDocAccessFields_eventId_Delete",
	permission: boolean
};
	["EventViewsDocAccessFields_viewCount"]: {
	__typename: "EventViewsDocAccessFields_viewCount",
	create?: GraphQLTypes["EventViewsDocAccessFields_viewCount_Create"] | undefined,
	read?: GraphQLTypes["EventViewsDocAccessFields_viewCount_Read"] | undefined,
	update?: GraphQLTypes["EventViewsDocAccessFields_viewCount_Update"] | undefined,
	delete?: GraphQLTypes["EventViewsDocAccessFields_viewCount_Delete"] | undefined
};
	["EventViewsDocAccessFields_viewCount_Create"]: {
	__typename: "EventViewsDocAccessFields_viewCount_Create",
	permission: boolean
};
	["EventViewsDocAccessFields_viewCount_Read"]: {
	__typename: "EventViewsDocAccessFields_viewCount_Read",
	permission: boolean
};
	["EventViewsDocAccessFields_viewCount_Update"]: {
	__typename: "EventViewsDocAccessFields_viewCount_Update",
	permission: boolean
};
	["EventViewsDocAccessFields_viewCount_Delete"]: {
	__typename: "EventViewsDocAccessFields_viewCount_Delete",
	permission: boolean
};
	["EventViewsDocAccessFields_updatedAt"]: {
	__typename: "EventViewsDocAccessFields_updatedAt",
	create?: GraphQLTypes["EventViewsDocAccessFields_updatedAt_Create"] | undefined,
	read?: GraphQLTypes["EventViewsDocAccessFields_updatedAt_Read"] | undefined,
	update?: GraphQLTypes["EventViewsDocAccessFields_updatedAt_Update"] | undefined,
	delete?: GraphQLTypes["EventViewsDocAccessFields_updatedAt_Delete"] | undefined
};
	["EventViewsDocAccessFields_updatedAt_Create"]: {
	__typename: "EventViewsDocAccessFields_updatedAt_Create",
	permission: boolean
};
	["EventViewsDocAccessFields_updatedAt_Read"]: {
	__typename: "EventViewsDocAccessFields_updatedAt_Read",
	permission: boolean
};
	["EventViewsDocAccessFields_updatedAt_Update"]: {
	__typename: "EventViewsDocAccessFields_updatedAt_Update",
	permission: boolean
};
	["EventViewsDocAccessFields_updatedAt_Delete"]: {
	__typename: "EventViewsDocAccessFields_updatedAt_Delete",
	permission: boolean
};
	["EventViewsDocAccessFields_createdAt"]: {
	__typename: "EventViewsDocAccessFields_createdAt",
	create?: GraphQLTypes["EventViewsDocAccessFields_createdAt_Create"] | undefined,
	read?: GraphQLTypes["EventViewsDocAccessFields_createdAt_Read"] | undefined,
	update?: GraphQLTypes["EventViewsDocAccessFields_createdAt_Update"] | undefined,
	delete?: GraphQLTypes["EventViewsDocAccessFields_createdAt_Delete"] | undefined
};
	["EventViewsDocAccessFields_createdAt_Create"]: {
	__typename: "EventViewsDocAccessFields_createdAt_Create",
	permission: boolean
};
	["EventViewsDocAccessFields_createdAt_Read"]: {
	__typename: "EventViewsDocAccessFields_createdAt_Read",
	permission: boolean
};
	["EventViewsDocAccessFields_createdAt_Update"]: {
	__typename: "EventViewsDocAccessFields_createdAt_Update",
	permission: boolean
};
	["EventViewsDocAccessFields_createdAt_Delete"]: {
	__typename: "EventViewsDocAccessFields_createdAt_Delete",
	permission: boolean
};
	["EventViewsCreateDocAccess"]: {
	__typename: "EventViewsCreateDocAccess",
	permission: boolean,
	where?: GraphQLTypes["JSONObject"] | undefined
};
	["EventViewsReadDocAccess"]: {
	__typename: "EventViewsReadDocAccess",
	permission: boolean,
	where?: GraphQLTypes["JSONObject"] | undefined
};
	["EventViewsUpdateDocAccess"]: {
	__typename: "EventViewsUpdateDocAccess",
	permission: boolean,
	where?: GraphQLTypes["JSONObject"] | undefined
};
	["EventViewsDeleteDocAccess"]: {
	__typename: "EventViewsDeleteDocAccess",
	permission: boolean,
	where?: GraphQLTypes["JSONObject"] | undefined
};
	["PostView"]: {
	__typename: "PostView",
	id?: string | undefined,
	date: GraphQLTypes["DateTime"],
	postId: GraphQLTypes["Post"],
	viewCount: number,
	updatedAt?: GraphQLTypes["DateTime"] | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined
};
	["PostViews"]: {
	__typename: "PostViews",
	docs?: Array<GraphQLTypes["PostView"] | undefined> | undefined,
	hasNextPage?: boolean | undefined,
	hasPrevPage?: boolean | undefined,
	limit?: number | undefined,
	nextPage?: number | undefined,
	offset?: number | undefined,
	page?: number | undefined,
	pagingCounter?: number | undefined,
	prevPage?: number | undefined,
	totalDocs?: number | undefined,
	totalPages?: number | undefined
};
	["PostView_where"]: {
		date?: GraphQLTypes["PostView_date_operator"] | undefined,
	postId?: GraphQLTypes["PostView_postId_operator"] | undefined,
	viewCount?: GraphQLTypes["PostView_viewCount_operator"] | undefined,
	updatedAt?: GraphQLTypes["PostView_updatedAt_operator"] | undefined,
	createdAt?: GraphQLTypes["PostView_createdAt_operator"] | undefined,
	id?: GraphQLTypes["PostView_id_operator"] | undefined,
	AND?: Array<GraphQLTypes["PostView_where_and"] | undefined> | undefined,
	OR?: Array<GraphQLTypes["PostView_where_or"] | undefined> | undefined
};
	["PostView_date_operator"]: {
		equals?: GraphQLTypes["DateTime"] | undefined,
	not_equals?: GraphQLTypes["DateTime"] | undefined,
	greater_than_equal?: GraphQLTypes["DateTime"] | undefined,
	greater_than?: GraphQLTypes["DateTime"] | undefined,
	less_than_equal?: GraphQLTypes["DateTime"] | undefined,
	less_than?: GraphQLTypes["DateTime"] | undefined,
	like?: GraphQLTypes["DateTime"] | undefined
};
	["PostView_postId_operator"]: {
		equals?: GraphQLTypes["JSON"] | undefined,
	not_equals?: GraphQLTypes["JSON"] | undefined,
	in?: Array<GraphQLTypes["JSON"] | undefined> | undefined,
	not_in?: Array<GraphQLTypes["JSON"] | undefined> | undefined,
	all?: Array<GraphQLTypes["JSON"] | undefined> | undefined
};
	["PostView_viewCount_operator"]: {
		equals?: number | undefined,
	not_equals?: number | undefined,
	greater_than_equal?: number | undefined,
	greater_than?: number | undefined,
	less_than_equal?: number | undefined,
	less_than?: number | undefined
};
	["PostView_updatedAt_operator"]: {
		equals?: GraphQLTypes["DateTime"] | undefined,
	not_equals?: GraphQLTypes["DateTime"] | undefined,
	greater_than_equal?: GraphQLTypes["DateTime"] | undefined,
	greater_than?: GraphQLTypes["DateTime"] | undefined,
	less_than_equal?: GraphQLTypes["DateTime"] | undefined,
	less_than?: GraphQLTypes["DateTime"] | undefined,
	like?: GraphQLTypes["DateTime"] | undefined,
	exists?: boolean | undefined
};
	["PostView_createdAt_operator"]: {
		equals?: GraphQLTypes["DateTime"] | undefined,
	not_equals?: GraphQLTypes["DateTime"] | undefined,
	greater_than_equal?: GraphQLTypes["DateTime"] | undefined,
	greater_than?: GraphQLTypes["DateTime"] | undefined,
	less_than_equal?: GraphQLTypes["DateTime"] | undefined,
	less_than?: GraphQLTypes["DateTime"] | undefined,
	like?: GraphQLTypes["DateTime"] | undefined,
	exists?: boolean | undefined
};
	["PostView_id_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["PostView_where_and"]: {
		date?: GraphQLTypes["PostView_date_operator"] | undefined,
	postId?: GraphQLTypes["PostView_postId_operator"] | undefined,
	viewCount?: GraphQLTypes["PostView_viewCount_operator"] | undefined,
	updatedAt?: GraphQLTypes["PostView_updatedAt_operator"] | undefined,
	createdAt?: GraphQLTypes["PostView_createdAt_operator"] | undefined,
	id?: GraphQLTypes["PostView_id_operator"] | undefined,
	AND?: Array<GraphQLTypes["PostView_where_and"] | undefined> | undefined,
	OR?: Array<GraphQLTypes["PostView_where_or"] | undefined> | undefined
};
	["PostView_where_or"]: {
		date?: GraphQLTypes["PostView_date_operator"] | undefined,
	postId?: GraphQLTypes["PostView_postId_operator"] | undefined,
	viewCount?: GraphQLTypes["PostView_viewCount_operator"] | undefined,
	updatedAt?: GraphQLTypes["PostView_updatedAt_operator"] | undefined,
	createdAt?: GraphQLTypes["PostView_createdAt_operator"] | undefined,
	id?: GraphQLTypes["PostView_id_operator"] | undefined,
	AND?: Array<GraphQLTypes["PostView_where_and"] | undefined> | undefined,
	OR?: Array<GraphQLTypes["PostView_where_or"] | undefined> | undefined
};
	["countPostViews"]: {
	__typename: "countPostViews",
	totalDocs?: number | undefined
};
	["post_viewsDocAccess"]: {
	__typename: "post_viewsDocAccess",
	fields?: GraphQLTypes["PostViewsDocAccessFields"] | undefined,
	create?: GraphQLTypes["PostViewsCreateDocAccess"] | undefined,
	read?: GraphQLTypes["PostViewsReadDocAccess"] | undefined,
	update?: GraphQLTypes["PostViewsUpdateDocAccess"] | undefined,
	delete?: GraphQLTypes["PostViewsDeleteDocAccess"] | undefined
};
	["PostViewsDocAccessFields"]: {
	__typename: "PostViewsDocAccessFields",
	date?: GraphQLTypes["PostViewsDocAccessFields_date"] | undefined,
	postId?: GraphQLTypes["PostViewsDocAccessFields_postId"] | undefined,
	viewCount?: GraphQLTypes["PostViewsDocAccessFields_viewCount"] | undefined,
	updatedAt?: GraphQLTypes["PostViewsDocAccessFields_updatedAt"] | undefined,
	createdAt?: GraphQLTypes["PostViewsDocAccessFields_createdAt"] | undefined
};
	["PostViewsDocAccessFields_date"]: {
	__typename: "PostViewsDocAccessFields_date",
	create?: GraphQLTypes["PostViewsDocAccessFields_date_Create"] | undefined,
	read?: GraphQLTypes["PostViewsDocAccessFields_date_Read"] | undefined,
	update?: GraphQLTypes["PostViewsDocAccessFields_date_Update"] | undefined,
	delete?: GraphQLTypes["PostViewsDocAccessFields_date_Delete"] | undefined
};
	["PostViewsDocAccessFields_date_Create"]: {
	__typename: "PostViewsDocAccessFields_date_Create",
	permission: boolean
};
	["PostViewsDocAccessFields_date_Read"]: {
	__typename: "PostViewsDocAccessFields_date_Read",
	permission: boolean
};
	["PostViewsDocAccessFields_date_Update"]: {
	__typename: "PostViewsDocAccessFields_date_Update",
	permission: boolean
};
	["PostViewsDocAccessFields_date_Delete"]: {
	__typename: "PostViewsDocAccessFields_date_Delete",
	permission: boolean
};
	["PostViewsDocAccessFields_postId"]: {
	__typename: "PostViewsDocAccessFields_postId",
	create?: GraphQLTypes["PostViewsDocAccessFields_postId_Create"] | undefined,
	read?: GraphQLTypes["PostViewsDocAccessFields_postId_Read"] | undefined,
	update?: GraphQLTypes["PostViewsDocAccessFields_postId_Update"] | undefined,
	delete?: GraphQLTypes["PostViewsDocAccessFields_postId_Delete"] | undefined
};
	["PostViewsDocAccessFields_postId_Create"]: {
	__typename: "PostViewsDocAccessFields_postId_Create",
	permission: boolean
};
	["PostViewsDocAccessFields_postId_Read"]: {
	__typename: "PostViewsDocAccessFields_postId_Read",
	permission: boolean
};
	["PostViewsDocAccessFields_postId_Update"]: {
	__typename: "PostViewsDocAccessFields_postId_Update",
	permission: boolean
};
	["PostViewsDocAccessFields_postId_Delete"]: {
	__typename: "PostViewsDocAccessFields_postId_Delete",
	permission: boolean
};
	["PostViewsDocAccessFields_viewCount"]: {
	__typename: "PostViewsDocAccessFields_viewCount",
	create?: GraphQLTypes["PostViewsDocAccessFields_viewCount_Create"] | undefined,
	read?: GraphQLTypes["PostViewsDocAccessFields_viewCount_Read"] | undefined,
	update?: GraphQLTypes["PostViewsDocAccessFields_viewCount_Update"] | undefined,
	delete?: GraphQLTypes["PostViewsDocAccessFields_viewCount_Delete"] | undefined
};
	["PostViewsDocAccessFields_viewCount_Create"]: {
	__typename: "PostViewsDocAccessFields_viewCount_Create",
	permission: boolean
};
	["PostViewsDocAccessFields_viewCount_Read"]: {
	__typename: "PostViewsDocAccessFields_viewCount_Read",
	permission: boolean
};
	["PostViewsDocAccessFields_viewCount_Update"]: {
	__typename: "PostViewsDocAccessFields_viewCount_Update",
	permission: boolean
};
	["PostViewsDocAccessFields_viewCount_Delete"]: {
	__typename: "PostViewsDocAccessFields_viewCount_Delete",
	permission: boolean
};
	["PostViewsDocAccessFields_updatedAt"]: {
	__typename: "PostViewsDocAccessFields_updatedAt",
	create?: GraphQLTypes["PostViewsDocAccessFields_updatedAt_Create"] | undefined,
	read?: GraphQLTypes["PostViewsDocAccessFields_updatedAt_Read"] | undefined,
	update?: GraphQLTypes["PostViewsDocAccessFields_updatedAt_Update"] | undefined,
	delete?: GraphQLTypes["PostViewsDocAccessFields_updatedAt_Delete"] | undefined
};
	["PostViewsDocAccessFields_updatedAt_Create"]: {
	__typename: "PostViewsDocAccessFields_updatedAt_Create",
	permission: boolean
};
	["PostViewsDocAccessFields_updatedAt_Read"]: {
	__typename: "PostViewsDocAccessFields_updatedAt_Read",
	permission: boolean
};
	["PostViewsDocAccessFields_updatedAt_Update"]: {
	__typename: "PostViewsDocAccessFields_updatedAt_Update",
	permission: boolean
};
	["PostViewsDocAccessFields_updatedAt_Delete"]: {
	__typename: "PostViewsDocAccessFields_updatedAt_Delete",
	permission: boolean
};
	["PostViewsDocAccessFields_createdAt"]: {
	__typename: "PostViewsDocAccessFields_createdAt",
	create?: GraphQLTypes["PostViewsDocAccessFields_createdAt_Create"] | undefined,
	read?: GraphQLTypes["PostViewsDocAccessFields_createdAt_Read"] | undefined,
	update?: GraphQLTypes["PostViewsDocAccessFields_createdAt_Update"] | undefined,
	delete?: GraphQLTypes["PostViewsDocAccessFields_createdAt_Delete"] | undefined
};
	["PostViewsDocAccessFields_createdAt_Create"]: {
	__typename: "PostViewsDocAccessFields_createdAt_Create",
	permission: boolean
};
	["PostViewsDocAccessFields_createdAt_Read"]: {
	__typename: "PostViewsDocAccessFields_createdAt_Read",
	permission: boolean
};
	["PostViewsDocAccessFields_createdAt_Update"]: {
	__typename: "PostViewsDocAccessFields_createdAt_Update",
	permission: boolean
};
	["PostViewsDocAccessFields_createdAt_Delete"]: {
	__typename: "PostViewsDocAccessFields_createdAt_Delete",
	permission: boolean
};
	["PostViewsCreateDocAccess"]: {
	__typename: "PostViewsCreateDocAccess",
	permission: boolean,
	where?: GraphQLTypes["JSONObject"] | undefined
};
	["PostViewsReadDocAccess"]: {
	__typename: "PostViewsReadDocAccess",
	permission: boolean,
	where?: GraphQLTypes["JSONObject"] | undefined
};
	["PostViewsUpdateDocAccess"]: {
	__typename: "PostViewsUpdateDocAccess",
	permission: boolean,
	where?: GraphQLTypes["JSONObject"] | undefined
};
	["PostViewsDeleteDocAccess"]: {
	__typename: "PostViewsDeleteDocAccess",
	permission: boolean,
	where?: GraphQLTypes["JSONObject"] | undefined
};
	["PayloadPreference"]: {
	__typename: "PayloadPreference",
	id?: string | undefined,
	user: GraphQLTypes["PayloadPreference_User_Relationship"],
	key?: string | undefined,
	value?: GraphQLTypes["JSON"] | undefined,
	updatedAt?: GraphQLTypes["DateTime"] | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined
};
	["PayloadPreference_User_Relationship"]: {
	__typename: "PayloadPreference_User_Relationship",
	relationTo?: GraphQLTypes["PayloadPreference_User_RelationTo"] | undefined,
	value?: GraphQLTypes["PayloadPreference_User"] | undefined
};
	["PayloadPreference_User_RelationTo"]: PayloadPreference_User_RelationTo;
	["PayloadPreference_User"]:{
        	__typename:"User"
        	['...on User']: '__union' & GraphQLTypes["User"];
};
	["PayloadPreferences"]: {
	__typename: "PayloadPreferences",
	docs?: Array<GraphQLTypes["PayloadPreference"] | undefined> | undefined,
	hasNextPage?: boolean | undefined,
	hasPrevPage?: boolean | undefined,
	limit?: number | undefined,
	nextPage?: number | undefined,
	offset?: number | undefined,
	page?: number | undefined,
	pagingCounter?: number | undefined,
	prevPage?: number | undefined,
	totalDocs?: number | undefined,
	totalPages?: number | undefined
};
	["PayloadPreference_where"]: {
		user?: GraphQLTypes["PayloadPreference_user_Relation"] | undefined,
	key?: GraphQLTypes["PayloadPreference_key_operator"] | undefined,
	value?: GraphQLTypes["PayloadPreference_value_operator"] | undefined,
	updatedAt?: GraphQLTypes["PayloadPreference_updatedAt_operator"] | undefined,
	createdAt?: GraphQLTypes["PayloadPreference_createdAt_operator"] | undefined,
	id?: GraphQLTypes["PayloadPreference_id_operator"] | undefined,
	AND?: Array<GraphQLTypes["PayloadPreference_where_and"] | undefined> | undefined,
	OR?: Array<GraphQLTypes["PayloadPreference_where_or"] | undefined> | undefined
};
	["PayloadPreference_user_Relation"]: {
		relationTo?: GraphQLTypes["PayloadPreference_user_Relation_RelationTo"] | undefined,
	value?: GraphQLTypes["JSON"] | undefined
};
	["PayloadPreference_user_Relation_RelationTo"]: PayloadPreference_user_Relation_RelationTo;
	["PayloadPreference_key_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["PayloadPreference_value_operator"]: {
		equals?: GraphQLTypes["JSON"] | undefined,
	not_equals?: GraphQLTypes["JSON"] | undefined,
	like?: GraphQLTypes["JSON"] | undefined,
	contains?: GraphQLTypes["JSON"] | undefined,
	within?: GraphQLTypes["JSON"] | undefined,
	intersects?: GraphQLTypes["JSON"] | undefined,
	exists?: boolean | undefined
};
	["PayloadPreference_updatedAt_operator"]: {
		equals?: GraphQLTypes["DateTime"] | undefined,
	not_equals?: GraphQLTypes["DateTime"] | undefined,
	greater_than_equal?: GraphQLTypes["DateTime"] | undefined,
	greater_than?: GraphQLTypes["DateTime"] | undefined,
	less_than_equal?: GraphQLTypes["DateTime"] | undefined,
	less_than?: GraphQLTypes["DateTime"] | undefined,
	like?: GraphQLTypes["DateTime"] | undefined,
	exists?: boolean | undefined
};
	["PayloadPreference_createdAt_operator"]: {
		equals?: GraphQLTypes["DateTime"] | undefined,
	not_equals?: GraphQLTypes["DateTime"] | undefined,
	greater_than_equal?: GraphQLTypes["DateTime"] | undefined,
	greater_than?: GraphQLTypes["DateTime"] | undefined,
	less_than_equal?: GraphQLTypes["DateTime"] | undefined,
	less_than?: GraphQLTypes["DateTime"] | undefined,
	like?: GraphQLTypes["DateTime"] | undefined,
	exists?: boolean | undefined
};
	["PayloadPreference_id_operator"]: {
		equals?: string | undefined,
	not_equals?: string | undefined,
	like?: string | undefined,
	contains?: string | undefined,
	in?: Array<string | undefined> | undefined,
	not_in?: Array<string | undefined> | undefined,
	all?: Array<string | undefined> | undefined,
	exists?: boolean | undefined
};
	["PayloadPreference_where_and"]: {
		user?: GraphQLTypes["PayloadPreference_user_Relation"] | undefined,
	key?: GraphQLTypes["PayloadPreference_key_operator"] | undefined,
	value?: GraphQLTypes["PayloadPreference_value_operator"] | undefined,
	updatedAt?: GraphQLTypes["PayloadPreference_updatedAt_operator"] | undefined,
	createdAt?: GraphQLTypes["PayloadPreference_createdAt_operator"] | undefined,
	id?: GraphQLTypes["PayloadPreference_id_operator"] | undefined,
	AND?: Array<GraphQLTypes["PayloadPreference_where_and"] | undefined> | undefined,
	OR?: Array<GraphQLTypes["PayloadPreference_where_or"] | undefined> | undefined
};
	["PayloadPreference_where_or"]: {
		user?: GraphQLTypes["PayloadPreference_user_Relation"] | undefined,
	key?: GraphQLTypes["PayloadPreference_key_operator"] | undefined,
	value?: GraphQLTypes["PayloadPreference_value_operator"] | undefined,
	updatedAt?: GraphQLTypes["PayloadPreference_updatedAt_operator"] | undefined,
	createdAt?: GraphQLTypes["PayloadPreference_createdAt_operator"] | undefined,
	id?: GraphQLTypes["PayloadPreference_id_operator"] | undefined,
	AND?: Array<GraphQLTypes["PayloadPreference_where_and"] | undefined> | undefined,
	OR?: Array<GraphQLTypes["PayloadPreference_where_or"] | undefined> | undefined
};
	["countPayloadPreferences"]: {
	__typename: "countPayloadPreferences",
	totalDocs?: number | undefined
};
	["payload_preferencesDocAccess"]: {
	__typename: "payload_preferencesDocAccess",
	fields?: GraphQLTypes["PayloadPreferencesDocAccessFields"] | undefined,
	create?: GraphQLTypes["PayloadPreferencesCreateDocAccess"] | undefined,
	read?: GraphQLTypes["PayloadPreferencesReadDocAccess"] | undefined,
	update?: GraphQLTypes["PayloadPreferencesUpdateDocAccess"] | undefined,
	delete?: GraphQLTypes["PayloadPreferencesDeleteDocAccess"] | undefined
};
	["PayloadPreferencesDocAccessFields"]: {
	__typename: "PayloadPreferencesDocAccessFields",
	user?: GraphQLTypes["PayloadPreferencesDocAccessFields_user"] | undefined,
	key?: GraphQLTypes["PayloadPreferencesDocAccessFields_key"] | undefined,
	value?: GraphQLTypes["PayloadPreferencesDocAccessFields_value"] | undefined,
	updatedAt?: GraphQLTypes["PayloadPreferencesDocAccessFields_updatedAt"] | undefined,
	createdAt?: GraphQLTypes["PayloadPreferencesDocAccessFields_createdAt"] | undefined
};
	["PayloadPreferencesDocAccessFields_user"]: {
	__typename: "PayloadPreferencesDocAccessFields_user",
	create?: GraphQLTypes["PayloadPreferencesDocAccessFields_user_Create"] | undefined,
	read?: GraphQLTypes["PayloadPreferencesDocAccessFields_user_Read"] | undefined,
	update?: GraphQLTypes["PayloadPreferencesDocAccessFields_user_Update"] | undefined,
	delete?: GraphQLTypes["PayloadPreferencesDocAccessFields_user_Delete"] | undefined
};
	["PayloadPreferencesDocAccessFields_user_Create"]: {
	__typename: "PayloadPreferencesDocAccessFields_user_Create",
	permission: boolean
};
	["PayloadPreferencesDocAccessFields_user_Read"]: {
	__typename: "PayloadPreferencesDocAccessFields_user_Read",
	permission: boolean
};
	["PayloadPreferencesDocAccessFields_user_Update"]: {
	__typename: "PayloadPreferencesDocAccessFields_user_Update",
	permission: boolean
};
	["PayloadPreferencesDocAccessFields_user_Delete"]: {
	__typename: "PayloadPreferencesDocAccessFields_user_Delete",
	permission: boolean
};
	["PayloadPreferencesDocAccessFields_key"]: {
	__typename: "PayloadPreferencesDocAccessFields_key",
	create?: GraphQLTypes["PayloadPreferencesDocAccessFields_key_Create"] | undefined,
	read?: GraphQLTypes["PayloadPreferencesDocAccessFields_key_Read"] | undefined,
	update?: GraphQLTypes["PayloadPreferencesDocAccessFields_key_Update"] | undefined,
	delete?: GraphQLTypes["PayloadPreferencesDocAccessFields_key_Delete"] | undefined
};
	["PayloadPreferencesDocAccessFields_key_Create"]: {
	__typename: "PayloadPreferencesDocAccessFields_key_Create",
	permission: boolean
};
	["PayloadPreferencesDocAccessFields_key_Read"]: {
	__typename: "PayloadPreferencesDocAccessFields_key_Read",
	permission: boolean
};
	["PayloadPreferencesDocAccessFields_key_Update"]: {
	__typename: "PayloadPreferencesDocAccessFields_key_Update",
	permission: boolean
};
	["PayloadPreferencesDocAccessFields_key_Delete"]: {
	__typename: "PayloadPreferencesDocAccessFields_key_Delete",
	permission: boolean
};
	["PayloadPreferencesDocAccessFields_value"]: {
	__typename: "PayloadPreferencesDocAccessFields_value",
	create?: GraphQLTypes["PayloadPreferencesDocAccessFields_value_Create"] | undefined,
	read?: GraphQLTypes["PayloadPreferencesDocAccessFields_value_Read"] | undefined,
	update?: GraphQLTypes["PayloadPreferencesDocAccessFields_value_Update"] | undefined,
	delete?: GraphQLTypes["PayloadPreferencesDocAccessFields_value_Delete"] | undefined
};
	["PayloadPreferencesDocAccessFields_value_Create"]: {
	__typename: "PayloadPreferencesDocAccessFields_value_Create",
	permission: boolean
};
	["PayloadPreferencesDocAccessFields_value_Read"]: {
	__typename: "PayloadPreferencesDocAccessFields_value_Read",
	permission: boolean
};
	["PayloadPreferencesDocAccessFields_value_Update"]: {
	__typename: "PayloadPreferencesDocAccessFields_value_Update",
	permission: boolean
};
	["PayloadPreferencesDocAccessFields_value_Delete"]: {
	__typename: "PayloadPreferencesDocAccessFields_value_Delete",
	permission: boolean
};
	["PayloadPreferencesDocAccessFields_updatedAt"]: {
	__typename: "PayloadPreferencesDocAccessFields_updatedAt",
	create?: GraphQLTypes["PayloadPreferencesDocAccessFields_updatedAt_Create"] | undefined,
	read?: GraphQLTypes["PayloadPreferencesDocAccessFields_updatedAt_Read"] | undefined,
	update?: GraphQLTypes["PayloadPreferencesDocAccessFields_updatedAt_Update"] | undefined,
	delete?: GraphQLTypes["PayloadPreferencesDocAccessFields_updatedAt_Delete"] | undefined
};
	["PayloadPreferencesDocAccessFields_updatedAt_Create"]: {
	__typename: "PayloadPreferencesDocAccessFields_updatedAt_Create",
	permission: boolean
};
	["PayloadPreferencesDocAccessFields_updatedAt_Read"]: {
	__typename: "PayloadPreferencesDocAccessFields_updatedAt_Read",
	permission: boolean
};
	["PayloadPreferencesDocAccessFields_updatedAt_Update"]: {
	__typename: "PayloadPreferencesDocAccessFields_updatedAt_Update",
	permission: boolean
};
	["PayloadPreferencesDocAccessFields_updatedAt_Delete"]: {
	__typename: "PayloadPreferencesDocAccessFields_updatedAt_Delete",
	permission: boolean
};
	["PayloadPreferencesDocAccessFields_createdAt"]: {
	__typename: "PayloadPreferencesDocAccessFields_createdAt",
	create?: GraphQLTypes["PayloadPreferencesDocAccessFields_createdAt_Create"] | undefined,
	read?: GraphQLTypes["PayloadPreferencesDocAccessFields_createdAt_Read"] | undefined,
	update?: GraphQLTypes["PayloadPreferencesDocAccessFields_createdAt_Update"] | undefined,
	delete?: GraphQLTypes["PayloadPreferencesDocAccessFields_createdAt_Delete"] | undefined
};
	["PayloadPreferencesDocAccessFields_createdAt_Create"]: {
	__typename: "PayloadPreferencesDocAccessFields_createdAt_Create",
	permission: boolean
};
	["PayloadPreferencesDocAccessFields_createdAt_Read"]: {
	__typename: "PayloadPreferencesDocAccessFields_createdAt_Read",
	permission: boolean
};
	["PayloadPreferencesDocAccessFields_createdAt_Update"]: {
	__typename: "PayloadPreferencesDocAccessFields_createdAt_Update",
	permission: boolean
};
	["PayloadPreferencesDocAccessFields_createdAt_Delete"]: {
	__typename: "PayloadPreferencesDocAccessFields_createdAt_Delete",
	permission: boolean
};
	["PayloadPreferencesCreateDocAccess"]: {
	__typename: "PayloadPreferencesCreateDocAccess",
	permission: boolean,
	where?: GraphQLTypes["JSONObject"] | undefined
};
	["PayloadPreferencesReadDocAccess"]: {
	__typename: "PayloadPreferencesReadDocAccess",
	permission: boolean,
	where?: GraphQLTypes["JSONObject"] | undefined
};
	["PayloadPreferencesUpdateDocAccess"]: {
	__typename: "PayloadPreferencesUpdateDocAccess",
	permission: boolean,
	where?: GraphQLTypes["JSONObject"] | undefined
};
	["PayloadPreferencesDeleteDocAccess"]: {
	__typename: "PayloadPreferencesDeleteDocAccess",
	permission: boolean,
	where?: GraphQLTypes["JSONObject"] | undefined
};
	["Access"]: {
	__typename: "Access",
	canAccessAdmin: boolean,
	events?: GraphQLTypes["eventsAccess"] | undefined,
	locations?: GraphQLTypes["locationsAccess"] | undefined,
	posts?: GraphQLTypes["postsAccess"] | undefined,
	ticketing?: GraphQLTypes["ticketingAccess"] | undefined,
	products?: GraphQLTypes["productsAccess"] | undefined,
	inventory_status?: GraphQLTypes["inventory_statusAccess"] | undefined,
	transactions?: GraphQLTypes["transactionsAccess"] | undefined,
	redemption_codes?: GraphQLTypes["redemption_codesAccess"] | undefined,
	redemption_pdf?: GraphQLTypes["redemption_pdfAccess"] | undefined,
	check_in_redemption?: GraphQLTypes["check_in_redemptionAccess"] | undefined,
	users?: GraphQLTypes["usersAccess"] | undefined,
	bookmarks?: GraphQLTypes["bookmarksAccess"] | undefined,
	webAppBookmarks?: GraphQLTypes["webAppBookmarksAccess"] | undefined,
	view_records?: GraphQLTypes["view_recordsAccess"] | undefined,
	comments?: GraphQLTypes["commentsAccess"] | undefined,
	subscriptions?: GraphQLTypes["subscriptionsAccess"] | undefined,
	newsletter?: GraphQLTypes["newsletterAccess"] | undefined,
	blog_categories?: GraphQLTypes["blog_categoriesAccess"] | undefined,
	tags?: GraphQLTypes["tagsAccess"] | undefined,
	specialTags?: GraphQLTypes["specialTagsAccess"] | undefined,
	date?: GraphQLTypes["dateAccess"] | undefined,
	districts?: GraphQLTypes["districtsAccess"] | undefined,
	audience?: GraphQLTypes["audienceAccess"] | undefined,
	category?: GraphQLTypes["categoryAccess"] | undefined,
	private_media?: GraphQLTypes["private_mediaAccess"] | undefined,
	media?: GraphQLTypes["mediaAccess"] | undefined,
	backupMedia?: GraphQLTypes["backupMediaAccess"] | undefined,
	account_suspend_requests?: GraphQLTypes["account_suspend_requestsAccess"] | undefined,
	devices?: GraphQLTypes["devicesAccess"] | undefined,
	notifications?: GraphQLTypes["notificationsAccess"] | undefined,
	redirectSetting?: GraphQLTypes["redirectSettingAccess"] | undefined,
	ranking_events?: GraphQLTypes["ranking_eventsAccess"] | undefined,
	ranking_locations?: GraphQLTypes["ranking_locationsAccess"] | undefined,
	event_calendar?: GraphQLTypes["event_calendarAccess"] | undefined,
	editor_choices?: GraphQLTypes["editor_choicesAccess"] | undefined,
	criteria_setting?: GraphQLTypes["criteria_settingAccess"] | undefined,
	organiserSetting?: GraphQLTypes["organiserSettingAccess"] | undefined,
	post_setting?: GraphQLTypes["post_settingAccess"] | undefined,
	homepageSetting?: GraphQLTypes["homepageSettingAccess"] | undefined,
	banner_section?: GraphQLTypes["banner_sectionAccess"] | undefined,
	macao_banner_section?: GraphQLTypes["macao_banner_sectionAccess"] | undefined,
	banners?: GraphQLTypes["bannersAccess"] | undefined,
	campaigns?: GraphQLTypes["campaignsAccess"] | undefined,
	advertises?: GraphQLTypes["advertisesAccess"] | undefined,
	statics?: GraphQLTypes["staticsAccess"] | undefined,
	event_views?: GraphQLTypes["event_viewsAccess"] | undefined,
	post_views?: GraphQLTypes["post_viewsAccess"] | undefined,
	payload_preferences?: GraphQLTypes["payload_preferencesAccess"] | undefined
};
	["eventsAccess"]: {
	__typename: "eventsAccess",
	fields?: GraphQLTypes["EventsFields"] | undefined,
	create?: GraphQLTypes["EventsCreateAccess"] | undefined,
	read?: GraphQLTypes["EventsReadAccess"] | undefined,
	update?: GraphQLTypes["EventsUpdateAccess"] | undefined,
	delete?: GraphQLTypes["EventsDeleteAccess"] | undefined
};
	["EventsFields"]: {
	__typename: "EventsFields",
	region?: GraphQLTypes["EventsFields_region"] | undefined,
	creator?: GraphQLTypes["EventsFields_creator"] | undefined,
	permalink?: GraphQLTypes["EventsFields_permalink"] | undefined,
	status?: GraphQLTypes["EventsFields_status"] | undefined,
	level?: GraphQLTypes["EventsFields_level"] | undefined,
	parent?: GraphQLTypes["EventsFields_parent"] | undefined,
	name?: GraphQLTypes["EventsFields_name"] | undefined,
	chatbotEventTitle?: GraphQLTypes["EventsFields_chatbotEventTitle"] | undefined,
	chatbotSequence?: GraphQLTypes["EventsFields_chatbotSequence"] | undefined,
	thumbnail?: GraphQLTypes["EventsFields_thumbnail"] | undefined,
	banner?: GraphQLTypes["EventsFields_banner"] | undefined,
	mobileBanner?: GraphQLTypes["EventsFields_mobileBanner"] | undefined,
	sections?: GraphQLTypes["EventsFields_sections"] | undefined,
	ticketing?: GraphQLTypes["EventsFields_ticketing"] | undefined,
	content?: GraphQLTypes["EventsFields_content"] | undefined,
	legacyContent?: GraphQLTypes["EventsFields_legacyContent"] | undefined,
	showLegacyContent?: GraphQLTypes["EventsFields_showLegacyContent"] | undefined,
	criteria?: GraphQLTypes["EventsFields_criteria"] | undefined,
	walkIn?: GraphQLTypes["EventsFields_walkIn"] | undefined,
	contact?: GraphQLTypes["EventsFields_contact"] | undefined,
	views?: GraphQLTypes["EventsFields_views"] | undefined,
	free?: GraphQLTypes["EventsFields_free"] | undefined,
	fees?: GraphQLTypes["EventsFields_fees"] | undefined,
	homePageInternalClick?: GraphQLTypes["EventsFields_homePageInternalClick"] | undefined,
	homePageImpression?: GraphQLTypes["EventsFields_homePageImpression"] | undefined,
	eodClickRate?: GraphQLTypes["EventsFields_eodClickRate"] | undefined,
	eodImpressionRate?: GraphQLTypes["EventsFields_eodImpressionRate"] | undefined,
	top10ClickRateHomepage?: GraphQLTypes["EventsFields_top10ClickRateHomepage"] | undefined,
	top10ImpressionkRateHomepage?: GraphQLTypes["EventsFields_top10ImpressionkRateHomepage"] | undefined,
	otherEventRankingClickRateHomepage?: GraphQLTypes["EventsFields_otherEventRankingClickRateHomepage"] | undefined,
	otherEventRankingImpressionHomePage?: GraphQLTypes["EventsFields_otherEventRankingImpressionHomePage"] | undefined,
	userPreferenceClickRate?: GraphQLTypes["EventsFields_userPreferenceClickRate"] | undefined,
	userPreferenceImpression?: GraphQLTypes["EventsFields_userPreferenceImpression"] | undefined,
	eventSearchInternalClick?: GraphQLTypes["EventsFields_eventSearchInternalClick"] | undefined,
	eventListImpression?: GraphQLTypes["EventsFields_eventListImpression"] | undefined,
	latestEventInternalClick?: GraphQLTypes["EventsFields_latestEventInternalClick"] | undefined,
	latestEventImpression?: GraphQLTypes["EventsFields_latestEventImpression"] | undefined,
	topTenEventRankingInternalClick?: GraphQLTypes["EventsFields_topTenEventRankingInternalClick"] | undefined,
	topTenImpression?: GraphQLTypes["EventsFields_topTenImpression"] | undefined,
	editorChoiceInternalClick?: GraphQLTypes["EventsFields_editorChoiceInternalClick"] | undefined,
	editorChoiceImpression?: GraphQLTypes["EventsFields_editorChoiceImpression"] | undefined,
	otherEventRankingInternalClick?: GraphQLTypes["EventsFields_otherEventRankingInternalClick"] | undefined,
	otherEventRankingImpression?: GraphQLTypes["EventsFields_otherEventRankingImpression"] | undefined,
	bannerInternalClick?: GraphQLTypes["EventsFields_bannerInternalClick"] | undefined,
	miscImpression?: GraphQLTypes["EventsFields_miscImpression"] | undefined,
	miscInternalClick?: GraphQLTypes["EventsFields_miscInternalClick"] | undefined,
	totalImpression?: GraphQLTypes["EventsFields_totalImpression"] | undefined,
	internalClick?: GraphQLTypes["EventsFields_internalClick"] | undefined,
	outboundClick?: GraphQLTypes["EventsFields_outboundClick"] | undefined,
	externalClick?: GraphQLTypes["EventsFields_externalClick"] | undefined,
	_viewsCountByMonth?: GraphQLTypes["EventsFields__viewsCountByMonth"] | undefined,
	products?: GraphQLTypes["EventsFields_products"] | undefined,
	legacyGallery?: GraphQLTypes["EventsFields_legacyGallery"] | undefined,
	earliestStartDate?: GraphQLTypes["EventsFields_earliestStartDate"] | undefined,
	latestEndDate?: GraphQLTypes["EventsFields_latestEndDate"] | undefined,
	latestStartDateTime?: GraphQLTypes["EventsFields_latestStartDateTime"] | undefined,
	hasTicketing?: GraphQLTypes["EventsFields_hasTicketing"] | undefined,
	hasProducts?: GraphQLTypes["EventsFields_hasProducts"] | undefined,
	updatedAt?: GraphQLTypes["EventsFields_updatedAt"] | undefined,
	createdAt?: GraphQLTypes["EventsFields_createdAt"] | undefined
};
	["EventsFields_region"]: {
	__typename: "EventsFields_region",
	create?: GraphQLTypes["EventsFields_region_Create"] | undefined,
	read?: GraphQLTypes["EventsFields_region_Read"] | undefined,
	update?: GraphQLTypes["EventsFields_region_Update"] | undefined,
	delete?: GraphQLTypes["EventsFields_region_Delete"] | undefined
};
	["EventsFields_region_Create"]: {
	__typename: "EventsFields_region_Create",
	permission: boolean
};
	["EventsFields_region_Read"]: {
	__typename: "EventsFields_region_Read",
	permission: boolean
};
	["EventsFields_region_Update"]: {
	__typename: "EventsFields_region_Update",
	permission: boolean
};
	["EventsFields_region_Delete"]: {
	__typename: "EventsFields_region_Delete",
	permission: boolean
};
	["EventsFields_creator"]: {
	__typename: "EventsFields_creator",
	create?: GraphQLTypes["EventsFields_creator_Create"] | undefined,
	read?: GraphQLTypes["EventsFields_creator_Read"] | undefined,
	update?: GraphQLTypes["EventsFields_creator_Update"] | undefined,
	delete?: GraphQLTypes["EventsFields_creator_Delete"] | undefined
};
	["EventsFields_creator_Create"]: {
	__typename: "EventsFields_creator_Create",
	permission: boolean
};
	["EventsFields_creator_Read"]: {
	__typename: "EventsFields_creator_Read",
	permission: boolean
};
	["EventsFields_creator_Update"]: {
	__typename: "EventsFields_creator_Update",
	permission: boolean
};
	["EventsFields_creator_Delete"]: {
	__typename: "EventsFields_creator_Delete",
	permission: boolean
};
	["EventsFields_permalink"]: {
	__typename: "EventsFields_permalink",
	create?: GraphQLTypes["EventsFields_permalink_Create"] | undefined,
	read?: GraphQLTypes["EventsFields_permalink_Read"] | undefined,
	update?: GraphQLTypes["EventsFields_permalink_Update"] | undefined,
	delete?: GraphQLTypes["EventsFields_permalink_Delete"] | undefined
};
	["EventsFields_permalink_Create"]: {
	__typename: "EventsFields_permalink_Create",
	permission: boolean
};
	["EventsFields_permalink_Read"]: {
	__typename: "EventsFields_permalink_Read",
	permission: boolean
};
	["EventsFields_permalink_Update"]: {
	__typename: "EventsFields_permalink_Update",
	permission: boolean
};
	["EventsFields_permalink_Delete"]: {
	__typename: "EventsFields_permalink_Delete",
	permission: boolean
};
	["EventsFields_status"]: {
	__typename: "EventsFields_status",
	create?: GraphQLTypes["EventsFields_status_Create"] | undefined,
	read?: GraphQLTypes["EventsFields_status_Read"] | undefined,
	update?: GraphQLTypes["EventsFields_status_Update"] | undefined,
	delete?: GraphQLTypes["EventsFields_status_Delete"] | undefined
};
	["EventsFields_status_Create"]: {
	__typename: "EventsFields_status_Create",
	permission: boolean
};
	["EventsFields_status_Read"]: {
	__typename: "EventsFields_status_Read",
	permission: boolean
};
	["EventsFields_status_Update"]: {
	__typename: "EventsFields_status_Update",
	permission: boolean
};
	["EventsFields_status_Delete"]: {
	__typename: "EventsFields_status_Delete",
	permission: boolean
};
	["EventsFields_level"]: {
	__typename: "EventsFields_level",
	create?: GraphQLTypes["EventsFields_level_Create"] | undefined,
	read?: GraphQLTypes["EventsFields_level_Read"] | undefined,
	update?: GraphQLTypes["EventsFields_level_Update"] | undefined,
	delete?: GraphQLTypes["EventsFields_level_Delete"] | undefined
};
	["EventsFields_level_Create"]: {
	__typename: "EventsFields_level_Create",
	permission: boolean
};
	["EventsFields_level_Read"]: {
	__typename: "EventsFields_level_Read",
	permission: boolean
};
	["EventsFields_level_Update"]: {
	__typename: "EventsFields_level_Update",
	permission: boolean
};
	["EventsFields_level_Delete"]: {
	__typename: "EventsFields_level_Delete",
	permission: boolean
};
	["EventsFields_parent"]: {
	__typename: "EventsFields_parent",
	create?: GraphQLTypes["EventsFields_parent_Create"] | undefined,
	read?: GraphQLTypes["EventsFields_parent_Read"] | undefined,
	update?: GraphQLTypes["EventsFields_parent_Update"] | undefined,
	delete?: GraphQLTypes["EventsFields_parent_Delete"] | undefined
};
	["EventsFields_parent_Create"]: {
	__typename: "EventsFields_parent_Create",
	permission: boolean
};
	["EventsFields_parent_Read"]: {
	__typename: "EventsFields_parent_Read",
	permission: boolean
};
	["EventsFields_parent_Update"]: {
	__typename: "EventsFields_parent_Update",
	permission: boolean
};
	["EventsFields_parent_Delete"]: {
	__typename: "EventsFields_parent_Delete",
	permission: boolean
};
	["EventsFields_name"]: {
	__typename: "EventsFields_name",
	create?: GraphQLTypes["EventsFields_name_Create"] | undefined,
	read?: GraphQLTypes["EventsFields_name_Read"] | undefined,
	update?: GraphQLTypes["EventsFields_name_Update"] | undefined,
	delete?: GraphQLTypes["EventsFields_name_Delete"] | undefined
};
	["EventsFields_name_Create"]: {
	__typename: "EventsFields_name_Create",
	permission: boolean
};
	["EventsFields_name_Read"]: {
	__typename: "EventsFields_name_Read",
	permission: boolean
};
	["EventsFields_name_Update"]: {
	__typename: "EventsFields_name_Update",
	permission: boolean
};
	["EventsFields_name_Delete"]: {
	__typename: "EventsFields_name_Delete",
	permission: boolean
};
	["EventsFields_chatbotEventTitle"]: {
	__typename: "EventsFields_chatbotEventTitle",
	create?: GraphQLTypes["EventsFields_chatbotEventTitle_Create"] | undefined,
	read?: GraphQLTypes["EventsFields_chatbotEventTitle_Read"] | undefined,
	update?: GraphQLTypes["EventsFields_chatbotEventTitle_Update"] | undefined,
	delete?: GraphQLTypes["EventsFields_chatbotEventTitle_Delete"] | undefined
};
	["EventsFields_chatbotEventTitle_Create"]: {
	__typename: "EventsFields_chatbotEventTitle_Create",
	permission: boolean
};
	["EventsFields_chatbotEventTitle_Read"]: {
	__typename: "EventsFields_chatbotEventTitle_Read",
	permission: boolean
};
	["EventsFields_chatbotEventTitle_Update"]: {
	__typename: "EventsFields_chatbotEventTitle_Update",
	permission: boolean
};
	["EventsFields_chatbotEventTitle_Delete"]: {
	__typename: "EventsFields_chatbotEventTitle_Delete",
	permission: boolean
};
	["EventsFields_chatbotSequence"]: {
	__typename: "EventsFields_chatbotSequence",
	create?: GraphQLTypes["EventsFields_chatbotSequence_Create"] | undefined,
	read?: GraphQLTypes["EventsFields_chatbotSequence_Read"] | undefined,
	update?: GraphQLTypes["EventsFields_chatbotSequence_Update"] | undefined,
	delete?: GraphQLTypes["EventsFields_chatbotSequence_Delete"] | undefined
};
	["EventsFields_chatbotSequence_Create"]: {
	__typename: "EventsFields_chatbotSequence_Create",
	permission: boolean
};
	["EventsFields_chatbotSequence_Read"]: {
	__typename: "EventsFields_chatbotSequence_Read",
	permission: boolean
};
	["EventsFields_chatbotSequence_Update"]: {
	__typename: "EventsFields_chatbotSequence_Update",
	permission: boolean
};
	["EventsFields_chatbotSequence_Delete"]: {
	__typename: "EventsFields_chatbotSequence_Delete",
	permission: boolean
};
	["EventsFields_thumbnail"]: {
	__typename: "EventsFields_thumbnail",
	create?: GraphQLTypes["EventsFields_thumbnail_Create"] | undefined,
	read?: GraphQLTypes["EventsFields_thumbnail_Read"] | undefined,
	update?: GraphQLTypes["EventsFields_thumbnail_Update"] | undefined,
	delete?: GraphQLTypes["EventsFields_thumbnail_Delete"] | undefined
};
	["EventsFields_thumbnail_Create"]: {
	__typename: "EventsFields_thumbnail_Create",
	permission: boolean
};
	["EventsFields_thumbnail_Read"]: {
	__typename: "EventsFields_thumbnail_Read",
	permission: boolean
};
	["EventsFields_thumbnail_Update"]: {
	__typename: "EventsFields_thumbnail_Update",
	permission: boolean
};
	["EventsFields_thumbnail_Delete"]: {
	__typename: "EventsFields_thumbnail_Delete",
	permission: boolean
};
	["EventsFields_banner"]: {
	__typename: "EventsFields_banner",
	create?: GraphQLTypes["EventsFields_banner_Create"] | undefined,
	read?: GraphQLTypes["EventsFields_banner_Read"] | undefined,
	update?: GraphQLTypes["EventsFields_banner_Update"] | undefined,
	delete?: GraphQLTypes["EventsFields_banner_Delete"] | undefined
};
	["EventsFields_banner_Create"]: {
	__typename: "EventsFields_banner_Create",
	permission: boolean
};
	["EventsFields_banner_Read"]: {
	__typename: "EventsFields_banner_Read",
	permission: boolean
};
	["EventsFields_banner_Update"]: {
	__typename: "EventsFields_banner_Update",
	permission: boolean
};
	["EventsFields_banner_Delete"]: {
	__typename: "EventsFields_banner_Delete",
	permission: boolean
};
	["EventsFields_mobileBanner"]: {
	__typename: "EventsFields_mobileBanner",
	create?: GraphQLTypes["EventsFields_mobileBanner_Create"] | undefined,
	read?: GraphQLTypes["EventsFields_mobileBanner_Read"] | undefined,
	update?: GraphQLTypes["EventsFields_mobileBanner_Update"] | undefined,
	delete?: GraphQLTypes["EventsFields_mobileBanner_Delete"] | undefined
};
	["EventsFields_mobileBanner_Create"]: {
	__typename: "EventsFields_mobileBanner_Create",
	permission: boolean
};
	["EventsFields_mobileBanner_Read"]: {
	__typename: "EventsFields_mobileBanner_Read",
	permission: boolean
};
	["EventsFields_mobileBanner_Update"]: {
	__typename: "EventsFields_mobileBanner_Update",
	permission: boolean
};
	["EventsFields_mobileBanner_Delete"]: {
	__typename: "EventsFields_mobileBanner_Delete",
	permission: boolean
};
	["EventsFields_sections"]: {
	__typename: "EventsFields_sections",
	create?: GraphQLTypes["EventsFields_sections_Create"] | undefined,
	read?: GraphQLTypes["EventsFields_sections_Read"] | undefined,
	update?: GraphQLTypes["EventsFields_sections_Update"] | undefined,
	delete?: GraphQLTypes["EventsFields_sections_Delete"] | undefined,
	fields?: GraphQLTypes["EventsFields_sections_Fields"] | undefined
};
	["EventsFields_sections_Create"]: {
	__typename: "EventsFields_sections_Create",
	permission: boolean
};
	["EventsFields_sections_Read"]: {
	__typename: "EventsFields_sections_Read",
	permission: boolean
};
	["EventsFields_sections_Update"]: {
	__typename: "EventsFields_sections_Update",
	permission: boolean
};
	["EventsFields_sections_Delete"]: {
	__typename: "EventsFields_sections_Delete",
	permission: boolean
};
	["EventsFields_sections_Fields"]: {
	__typename: "EventsFields_sections_Fields",
	startDatetime?: GraphQLTypes["EventsFields_sections_startDatetime"] | undefined,
	endDatetime?: GraphQLTypes["EventsFields_sections_endDatetime"] | undefined,
	fullDay?: GraphQLTypes["EventsFields_sections_fullDay"] | undefined,
	repeat?: GraphQLTypes["EventsFields_sections_repeat"] | undefined,
	toThisDay?: GraphQLTypes["EventsFields_sections_toThisDay"] | undefined,
	recurrance?: GraphQLTypes["EventsFields_sections_recurrance"] | undefined,
	sameLocation?: GraphQLTypes["EventsFields_sections_sameLocation"] | undefined,
	location?: GraphQLTypes["EventsFields_sections_location"] | undefined,
	district?: GraphQLTypes["EventsFields_sections_district"] | undefined,
	coordinate?: GraphQLTypes["EventsFields_sections_coordinate"] | undefined,
	linkAddress?: GraphQLTypes["EventsFields_sections_linkAddress"] | undefined,
	address?: GraphQLTypes["EventsFields_sections_address"] | undefined,
	id?: GraphQLTypes["EventsFields_sections_id"] | undefined
};
	["EventsFields_sections_startDatetime"]: {
	__typename: "EventsFields_sections_startDatetime",
	create?: GraphQLTypes["EventsFields_sections_startDatetime_Create"] | undefined,
	read?: GraphQLTypes["EventsFields_sections_startDatetime_Read"] | undefined,
	update?: GraphQLTypes["EventsFields_sections_startDatetime_Update"] | undefined,
	delete?: GraphQLTypes["EventsFields_sections_startDatetime_Delete"] | undefined
};
	["EventsFields_sections_startDatetime_Create"]: {
	__typename: "EventsFields_sections_startDatetime_Create",
	permission: boolean
};
	["EventsFields_sections_startDatetime_Read"]: {
	__typename: "EventsFields_sections_startDatetime_Read",
	permission: boolean
};
	["EventsFields_sections_startDatetime_Update"]: {
	__typename: "EventsFields_sections_startDatetime_Update",
	permission: boolean
};
	["EventsFields_sections_startDatetime_Delete"]: {
	__typename: "EventsFields_sections_startDatetime_Delete",
	permission: boolean
};
	["EventsFields_sections_endDatetime"]: {
	__typename: "EventsFields_sections_endDatetime",
	create?: GraphQLTypes["EventsFields_sections_endDatetime_Create"] | undefined,
	read?: GraphQLTypes["EventsFields_sections_endDatetime_Read"] | undefined,
	update?: GraphQLTypes["EventsFields_sections_endDatetime_Update"] | undefined,
	delete?: GraphQLTypes["EventsFields_sections_endDatetime_Delete"] | undefined
};
	["EventsFields_sections_endDatetime_Create"]: {
	__typename: "EventsFields_sections_endDatetime_Create",
	permission: boolean
};
	["EventsFields_sections_endDatetime_Read"]: {
	__typename: "EventsFields_sections_endDatetime_Read",
	permission: boolean
};
	["EventsFields_sections_endDatetime_Update"]: {
	__typename: "EventsFields_sections_endDatetime_Update",
	permission: boolean
};
	["EventsFields_sections_endDatetime_Delete"]: {
	__typename: "EventsFields_sections_endDatetime_Delete",
	permission: boolean
};
	["EventsFields_sections_fullDay"]: {
	__typename: "EventsFields_sections_fullDay",
	create?: GraphQLTypes["EventsFields_sections_fullDay_Create"] | undefined,
	read?: GraphQLTypes["EventsFields_sections_fullDay_Read"] | undefined,
	update?: GraphQLTypes["EventsFields_sections_fullDay_Update"] | undefined,
	delete?: GraphQLTypes["EventsFields_sections_fullDay_Delete"] | undefined
};
	["EventsFields_sections_fullDay_Create"]: {
	__typename: "EventsFields_sections_fullDay_Create",
	permission: boolean
};
	["EventsFields_sections_fullDay_Read"]: {
	__typename: "EventsFields_sections_fullDay_Read",
	permission: boolean
};
	["EventsFields_sections_fullDay_Update"]: {
	__typename: "EventsFields_sections_fullDay_Update",
	permission: boolean
};
	["EventsFields_sections_fullDay_Delete"]: {
	__typename: "EventsFields_sections_fullDay_Delete",
	permission: boolean
};
	["EventsFields_sections_repeat"]: {
	__typename: "EventsFields_sections_repeat",
	create?: GraphQLTypes["EventsFields_sections_repeat_Create"] | undefined,
	read?: GraphQLTypes["EventsFields_sections_repeat_Read"] | undefined,
	update?: GraphQLTypes["EventsFields_sections_repeat_Update"] | undefined,
	delete?: GraphQLTypes["EventsFields_sections_repeat_Delete"] | undefined
};
	["EventsFields_sections_repeat_Create"]: {
	__typename: "EventsFields_sections_repeat_Create",
	permission: boolean
};
	["EventsFields_sections_repeat_Read"]: {
	__typename: "EventsFields_sections_repeat_Read",
	permission: boolean
};
	["EventsFields_sections_repeat_Update"]: {
	__typename: "EventsFields_sections_repeat_Update",
	permission: boolean
};
	["EventsFields_sections_repeat_Delete"]: {
	__typename: "EventsFields_sections_repeat_Delete",
	permission: boolean
};
	["EventsFields_sections_toThisDay"]: {
	__typename: "EventsFields_sections_toThisDay",
	create?: GraphQLTypes["EventsFields_sections_toThisDay_Create"] | undefined,
	read?: GraphQLTypes["EventsFields_sections_toThisDay_Read"] | undefined,
	update?: GraphQLTypes["EventsFields_sections_toThisDay_Update"] | undefined,
	delete?: GraphQLTypes["EventsFields_sections_toThisDay_Delete"] | undefined
};
	["EventsFields_sections_toThisDay_Create"]: {
	__typename: "EventsFields_sections_toThisDay_Create",
	permission: boolean
};
	["EventsFields_sections_toThisDay_Read"]: {
	__typename: "EventsFields_sections_toThisDay_Read",
	permission: boolean
};
	["EventsFields_sections_toThisDay_Update"]: {
	__typename: "EventsFields_sections_toThisDay_Update",
	permission: boolean
};
	["EventsFields_sections_toThisDay_Delete"]: {
	__typename: "EventsFields_sections_toThisDay_Delete",
	permission: boolean
};
	["EventsFields_sections_recurrance"]: {
	__typename: "EventsFields_sections_recurrance",
	create?: GraphQLTypes["EventsFields_sections_recurrance_Create"] | undefined,
	read?: GraphQLTypes["EventsFields_sections_recurrance_Read"] | undefined,
	update?: GraphQLTypes["EventsFields_sections_recurrance_Update"] | undefined,
	delete?: GraphQLTypes["EventsFields_sections_recurrance_Delete"] | undefined,
	fields?: GraphQLTypes["EventsFields_sections_recurrance_Fields"] | undefined
};
	["EventsFields_sections_recurrance_Create"]: {
	__typename: "EventsFields_sections_recurrance_Create",
	permission: boolean
};
	["EventsFields_sections_recurrance_Read"]: {
	__typename: "EventsFields_sections_recurrance_Read",
	permission: boolean
};
	["EventsFields_sections_recurrance_Update"]: {
	__typename: "EventsFields_sections_recurrance_Update",
	permission: boolean
};
	["EventsFields_sections_recurrance_Delete"]: {
	__typename: "EventsFields_sections_recurrance_Delete",
	permission: boolean
};
	["EventsFields_sections_recurrance_Fields"]: {
	__typename: "EventsFields_sections_recurrance_Fields",
	type?: GraphQLTypes["EventsFields_sections_recurrance_type"] | undefined,
	weekday?: GraphQLTypes["EventsFields_sections_recurrance_weekday"] | undefined
};
	["EventsFields_sections_recurrance_type"]: {
	__typename: "EventsFields_sections_recurrance_type",
	create?: GraphQLTypes["EventsFields_sections_recurrance_type_Create"] | undefined,
	read?: GraphQLTypes["EventsFields_sections_recurrance_type_Read"] | undefined,
	update?: GraphQLTypes["EventsFields_sections_recurrance_type_Update"] | undefined,
	delete?: GraphQLTypes["EventsFields_sections_recurrance_type_Delete"] | undefined
};
	["EventsFields_sections_recurrance_type_Create"]: {
	__typename: "EventsFields_sections_recurrance_type_Create",
	permission: boolean
};
	["EventsFields_sections_recurrance_type_Read"]: {
	__typename: "EventsFields_sections_recurrance_type_Read",
	permission: boolean
};
	["EventsFields_sections_recurrance_type_Update"]: {
	__typename: "EventsFields_sections_recurrance_type_Update",
	permission: boolean
};
	["EventsFields_sections_recurrance_type_Delete"]: {
	__typename: "EventsFields_sections_recurrance_type_Delete",
	permission: boolean
};
	["EventsFields_sections_recurrance_weekday"]: {
	__typename: "EventsFields_sections_recurrance_weekday",
	create?: GraphQLTypes["EventsFields_sections_recurrance_weekday_Create"] | undefined,
	read?: GraphQLTypes["EventsFields_sections_recurrance_weekday_Read"] | undefined,
	update?: GraphQLTypes["EventsFields_sections_recurrance_weekday_Update"] | undefined,
	delete?: GraphQLTypes["EventsFields_sections_recurrance_weekday_Delete"] | undefined
};
	["EventsFields_sections_recurrance_weekday_Create"]: {
	__typename: "EventsFields_sections_recurrance_weekday_Create",
	permission: boolean
};
	["EventsFields_sections_recurrance_weekday_Read"]: {
	__typename: "EventsFields_sections_recurrance_weekday_Read",
	permission: boolean
};
	["EventsFields_sections_recurrance_weekday_Update"]: {
	__typename: "EventsFields_sections_recurrance_weekday_Update",
	permission: boolean
};
	["EventsFields_sections_recurrance_weekday_Delete"]: {
	__typename: "EventsFields_sections_recurrance_weekday_Delete",
	permission: boolean
};
	["EventsFields_sections_sameLocation"]: {
	__typename: "EventsFields_sections_sameLocation",
	create?: GraphQLTypes["EventsFields_sections_sameLocation_Create"] | undefined,
	read?: GraphQLTypes["EventsFields_sections_sameLocation_Read"] | undefined,
	update?: GraphQLTypes["EventsFields_sections_sameLocation_Update"] | undefined,
	delete?: GraphQLTypes["EventsFields_sections_sameLocation_Delete"] | undefined
};
	["EventsFields_sections_sameLocation_Create"]: {
	__typename: "EventsFields_sections_sameLocation_Create",
	permission: boolean
};
	["EventsFields_sections_sameLocation_Read"]: {
	__typename: "EventsFields_sections_sameLocation_Read",
	permission: boolean
};
	["EventsFields_sections_sameLocation_Update"]: {
	__typename: "EventsFields_sections_sameLocation_Update",
	permission: boolean
};
	["EventsFields_sections_sameLocation_Delete"]: {
	__typename: "EventsFields_sections_sameLocation_Delete",
	permission: boolean
};
	["EventsFields_sections_location"]: {
	__typename: "EventsFields_sections_location",
	create?: GraphQLTypes["EventsFields_sections_location_Create"] | undefined,
	read?: GraphQLTypes["EventsFields_sections_location_Read"] | undefined,
	update?: GraphQLTypes["EventsFields_sections_location_Update"] | undefined,
	delete?: GraphQLTypes["EventsFields_sections_location_Delete"] | undefined
};
	["EventsFields_sections_location_Create"]: {
	__typename: "EventsFields_sections_location_Create",
	permission: boolean
};
	["EventsFields_sections_location_Read"]: {
	__typename: "EventsFields_sections_location_Read",
	permission: boolean
};
	["EventsFields_sections_location_Update"]: {
	__typename: "EventsFields_sections_location_Update",
	permission: boolean
};
	["EventsFields_sections_location_Delete"]: {
	__typename: "EventsFields_sections_location_Delete",
	permission: boolean
};
	["EventsFields_sections_district"]: {
	__typename: "EventsFields_sections_district",
	create?: GraphQLTypes["EventsFields_sections_district_Create"] | undefined,
	read?: GraphQLTypes["EventsFields_sections_district_Read"] | undefined,
	update?: GraphQLTypes["EventsFields_sections_district_Update"] | undefined,
	delete?: GraphQLTypes["EventsFields_sections_district_Delete"] | undefined
};
	["EventsFields_sections_district_Create"]: {
	__typename: "EventsFields_sections_district_Create",
	permission: boolean
};
	["EventsFields_sections_district_Read"]: {
	__typename: "EventsFields_sections_district_Read",
	permission: boolean
};
	["EventsFields_sections_district_Update"]: {
	__typename: "EventsFields_sections_district_Update",
	permission: boolean
};
	["EventsFields_sections_district_Delete"]: {
	__typename: "EventsFields_sections_district_Delete",
	permission: boolean
};
	["EventsFields_sections_coordinate"]: {
	__typename: "EventsFields_sections_coordinate",
	create?: GraphQLTypes["EventsFields_sections_coordinate_Create"] | undefined,
	read?: GraphQLTypes["EventsFields_sections_coordinate_Read"] | undefined,
	update?: GraphQLTypes["EventsFields_sections_coordinate_Update"] | undefined,
	delete?: GraphQLTypes["EventsFields_sections_coordinate_Delete"] | undefined
};
	["EventsFields_sections_coordinate_Create"]: {
	__typename: "EventsFields_sections_coordinate_Create",
	permission: boolean
};
	["EventsFields_sections_coordinate_Read"]: {
	__typename: "EventsFields_sections_coordinate_Read",
	permission: boolean
};
	["EventsFields_sections_coordinate_Update"]: {
	__typename: "EventsFields_sections_coordinate_Update",
	permission: boolean
};
	["EventsFields_sections_coordinate_Delete"]: {
	__typename: "EventsFields_sections_coordinate_Delete",
	permission: boolean
};
	["EventsFields_sections_linkAddress"]: {
	__typename: "EventsFields_sections_linkAddress",
	create?: GraphQLTypes["EventsFields_sections_linkAddress_Create"] | undefined,
	read?: GraphQLTypes["EventsFields_sections_linkAddress_Read"] | undefined,
	update?: GraphQLTypes["EventsFields_sections_linkAddress_Update"] | undefined,
	delete?: GraphQLTypes["EventsFields_sections_linkAddress_Delete"] | undefined
};
	["EventsFields_sections_linkAddress_Create"]: {
	__typename: "EventsFields_sections_linkAddress_Create",
	permission: boolean
};
	["EventsFields_sections_linkAddress_Read"]: {
	__typename: "EventsFields_sections_linkAddress_Read",
	permission: boolean
};
	["EventsFields_sections_linkAddress_Update"]: {
	__typename: "EventsFields_sections_linkAddress_Update",
	permission: boolean
};
	["EventsFields_sections_linkAddress_Delete"]: {
	__typename: "EventsFields_sections_linkAddress_Delete",
	permission: boolean
};
	["EventsFields_sections_address"]: {
	__typename: "EventsFields_sections_address",
	create?: GraphQLTypes["EventsFields_sections_address_Create"] | undefined,
	read?: GraphQLTypes["EventsFields_sections_address_Read"] | undefined,
	update?: GraphQLTypes["EventsFields_sections_address_Update"] | undefined,
	delete?: GraphQLTypes["EventsFields_sections_address_Delete"] | undefined
};
	["EventsFields_sections_address_Create"]: {
	__typename: "EventsFields_sections_address_Create",
	permission: boolean
};
	["EventsFields_sections_address_Read"]: {
	__typename: "EventsFields_sections_address_Read",
	permission: boolean
};
	["EventsFields_sections_address_Update"]: {
	__typename: "EventsFields_sections_address_Update",
	permission: boolean
};
	["EventsFields_sections_address_Delete"]: {
	__typename: "EventsFields_sections_address_Delete",
	permission: boolean
};
	["EventsFields_sections_id"]: {
	__typename: "EventsFields_sections_id",
	create?: GraphQLTypes["EventsFields_sections_id_Create"] | undefined,
	read?: GraphQLTypes["EventsFields_sections_id_Read"] | undefined,
	update?: GraphQLTypes["EventsFields_sections_id_Update"] | undefined,
	delete?: GraphQLTypes["EventsFields_sections_id_Delete"] | undefined
};
	["EventsFields_sections_id_Create"]: {
	__typename: "EventsFields_sections_id_Create",
	permission: boolean
};
	["EventsFields_sections_id_Read"]: {
	__typename: "EventsFields_sections_id_Read",
	permission: boolean
};
	["EventsFields_sections_id_Update"]: {
	__typename: "EventsFields_sections_id_Update",
	permission: boolean
};
	["EventsFields_sections_id_Delete"]: {
	__typename: "EventsFields_sections_id_Delete",
	permission: boolean
};
	["EventsFields_ticketing"]: {
	__typename: "EventsFields_ticketing",
	create?: GraphQLTypes["EventsFields_ticketing_Create"] | undefined,
	read?: GraphQLTypes["EventsFields_ticketing_Read"] | undefined,
	update?: GraphQLTypes["EventsFields_ticketing_Update"] | undefined,
	delete?: GraphQLTypes["EventsFields_ticketing_Delete"] | undefined,
	fields?: GraphQLTypes["EventsFields_ticketing_Fields"] | undefined
};
	["EventsFields_ticketing_Create"]: {
	__typename: "EventsFields_ticketing_Create",
	permission: boolean
};
	["EventsFields_ticketing_Read"]: {
	__typename: "EventsFields_ticketing_Read",
	permission: boolean
};
	["EventsFields_ticketing_Update"]: {
	__typename: "EventsFields_ticketing_Update",
	permission: boolean
};
	["EventsFields_ticketing_Delete"]: {
	__typename: "EventsFields_ticketing_Delete",
	permission: boolean
};
	["EventsFields_ticketing_Fields"]: {
	__typename: "EventsFields_ticketing_Fields",
	startDatetime?: GraphQLTypes["EventsFields_ticketing_startDatetime"] | undefined,
	endDatetime?: GraphQLTypes["EventsFields_ticketing_endDatetime"] | undefined,
	fullDay?: GraphQLTypes["EventsFields_ticketing_fullDay"] | undefined,
	type?: GraphQLTypes["EventsFields_ticketing_type"] | undefined,
	ticketingType?: GraphQLTypes["EventsFields_ticketing_ticketingType"] | undefined,
	linkedTicketing?: GraphQLTypes["EventsFields_ticketing_linkedTicketing"] | undefined,
	promotionType?: GraphQLTypes["EventsFields_ticketing_promotionType"] | undefined,
	location?: GraphQLTypes["EventsFields_ticketing_location"] | undefined,
	website?: GraphQLTypes["EventsFields_ticketing_website"] | undefined,
	description?: GraphQLTypes["EventsFields_ticketing_description"] | undefined,
	id?: GraphQLTypes["EventsFields_ticketing_id"] | undefined
};
	["EventsFields_ticketing_startDatetime"]: {
	__typename: "EventsFields_ticketing_startDatetime",
	create?: GraphQLTypes["EventsFields_ticketing_startDatetime_Create"] | undefined,
	read?: GraphQLTypes["EventsFields_ticketing_startDatetime_Read"] | undefined,
	update?: GraphQLTypes["EventsFields_ticketing_startDatetime_Update"] | undefined,
	delete?: GraphQLTypes["EventsFields_ticketing_startDatetime_Delete"] | undefined
};
	["EventsFields_ticketing_startDatetime_Create"]: {
	__typename: "EventsFields_ticketing_startDatetime_Create",
	permission: boolean
};
	["EventsFields_ticketing_startDatetime_Read"]: {
	__typename: "EventsFields_ticketing_startDatetime_Read",
	permission: boolean
};
	["EventsFields_ticketing_startDatetime_Update"]: {
	__typename: "EventsFields_ticketing_startDatetime_Update",
	permission: boolean
};
	["EventsFields_ticketing_startDatetime_Delete"]: {
	__typename: "EventsFields_ticketing_startDatetime_Delete",
	permission: boolean
};
	["EventsFields_ticketing_endDatetime"]: {
	__typename: "EventsFields_ticketing_endDatetime",
	create?: GraphQLTypes["EventsFields_ticketing_endDatetime_Create"] | undefined,
	read?: GraphQLTypes["EventsFields_ticketing_endDatetime_Read"] | undefined,
	update?: GraphQLTypes["EventsFields_ticketing_endDatetime_Update"] | undefined,
	delete?: GraphQLTypes["EventsFields_ticketing_endDatetime_Delete"] | undefined
};
	["EventsFields_ticketing_endDatetime_Create"]: {
	__typename: "EventsFields_ticketing_endDatetime_Create",
	permission: boolean
};
	["EventsFields_ticketing_endDatetime_Read"]: {
	__typename: "EventsFields_ticketing_endDatetime_Read",
	permission: boolean
};
	["EventsFields_ticketing_endDatetime_Update"]: {
	__typename: "EventsFields_ticketing_endDatetime_Update",
	permission: boolean
};
	["EventsFields_ticketing_endDatetime_Delete"]: {
	__typename: "EventsFields_ticketing_endDatetime_Delete",
	permission: boolean
};
	["EventsFields_ticketing_fullDay"]: {
	__typename: "EventsFields_ticketing_fullDay",
	create?: GraphQLTypes["EventsFields_ticketing_fullDay_Create"] | undefined,
	read?: GraphQLTypes["EventsFields_ticketing_fullDay_Read"] | undefined,
	update?: GraphQLTypes["EventsFields_ticketing_fullDay_Update"] | undefined,
	delete?: GraphQLTypes["EventsFields_ticketing_fullDay_Delete"] | undefined
};
	["EventsFields_ticketing_fullDay_Create"]: {
	__typename: "EventsFields_ticketing_fullDay_Create",
	permission: boolean
};
	["EventsFields_ticketing_fullDay_Read"]: {
	__typename: "EventsFields_ticketing_fullDay_Read",
	permission: boolean
};
	["EventsFields_ticketing_fullDay_Update"]: {
	__typename: "EventsFields_ticketing_fullDay_Update",
	permission: boolean
};
	["EventsFields_ticketing_fullDay_Delete"]: {
	__typename: "EventsFields_ticketing_fullDay_Delete",
	permission: boolean
};
	["EventsFields_ticketing_type"]: {
	__typename: "EventsFields_ticketing_type",
	create?: GraphQLTypes["EventsFields_ticketing_type_Create"] | undefined,
	read?: GraphQLTypes["EventsFields_ticketing_type_Read"] | undefined,
	update?: GraphQLTypes["EventsFields_ticketing_type_Update"] | undefined,
	delete?: GraphQLTypes["EventsFields_ticketing_type_Delete"] | undefined
};
	["EventsFields_ticketing_type_Create"]: {
	__typename: "EventsFields_ticketing_type_Create",
	permission: boolean
};
	["EventsFields_ticketing_type_Read"]: {
	__typename: "EventsFields_ticketing_type_Read",
	permission: boolean
};
	["EventsFields_ticketing_type_Update"]: {
	__typename: "EventsFields_ticketing_type_Update",
	permission: boolean
};
	["EventsFields_ticketing_type_Delete"]: {
	__typename: "EventsFields_ticketing_type_Delete",
	permission: boolean
};
	["EventsFields_ticketing_ticketingType"]: {
	__typename: "EventsFields_ticketing_ticketingType",
	create?: GraphQLTypes["EventsFields_ticketing_ticketingType_Create"] | undefined,
	read?: GraphQLTypes["EventsFields_ticketing_ticketingType_Read"] | undefined,
	update?: GraphQLTypes["EventsFields_ticketing_ticketingType_Update"] | undefined,
	delete?: GraphQLTypes["EventsFields_ticketing_ticketingType_Delete"] | undefined
};
	["EventsFields_ticketing_ticketingType_Create"]: {
	__typename: "EventsFields_ticketing_ticketingType_Create",
	permission: boolean
};
	["EventsFields_ticketing_ticketingType_Read"]: {
	__typename: "EventsFields_ticketing_ticketingType_Read",
	permission: boolean
};
	["EventsFields_ticketing_ticketingType_Update"]: {
	__typename: "EventsFields_ticketing_ticketingType_Update",
	permission: boolean
};
	["EventsFields_ticketing_ticketingType_Delete"]: {
	__typename: "EventsFields_ticketing_ticketingType_Delete",
	permission: boolean
};
	["EventsFields_ticketing_linkedTicketing"]: {
	__typename: "EventsFields_ticketing_linkedTicketing",
	create?: GraphQLTypes["EventsFields_ticketing_linkedTicketing_Create"] | undefined,
	read?: GraphQLTypes["EventsFields_ticketing_linkedTicketing_Read"] | undefined,
	update?: GraphQLTypes["EventsFields_ticketing_linkedTicketing_Update"] | undefined,
	delete?: GraphQLTypes["EventsFields_ticketing_linkedTicketing_Delete"] | undefined
};
	["EventsFields_ticketing_linkedTicketing_Create"]: {
	__typename: "EventsFields_ticketing_linkedTicketing_Create",
	permission: boolean
};
	["EventsFields_ticketing_linkedTicketing_Read"]: {
	__typename: "EventsFields_ticketing_linkedTicketing_Read",
	permission: boolean
};
	["EventsFields_ticketing_linkedTicketing_Update"]: {
	__typename: "EventsFields_ticketing_linkedTicketing_Update",
	permission: boolean
};
	["EventsFields_ticketing_linkedTicketing_Delete"]: {
	__typename: "EventsFields_ticketing_linkedTicketing_Delete",
	permission: boolean
};
	["EventsFields_ticketing_promotionType"]: {
	__typename: "EventsFields_ticketing_promotionType",
	create?: GraphQLTypes["EventsFields_ticketing_promotionType_Create"] | undefined,
	read?: GraphQLTypes["EventsFields_ticketing_promotionType_Read"] | undefined,
	update?: GraphQLTypes["EventsFields_ticketing_promotionType_Update"] | undefined,
	delete?: GraphQLTypes["EventsFields_ticketing_promotionType_Delete"] | undefined
};
	["EventsFields_ticketing_promotionType_Create"]: {
	__typename: "EventsFields_ticketing_promotionType_Create",
	permission: boolean
};
	["EventsFields_ticketing_promotionType_Read"]: {
	__typename: "EventsFields_ticketing_promotionType_Read",
	permission: boolean
};
	["EventsFields_ticketing_promotionType_Update"]: {
	__typename: "EventsFields_ticketing_promotionType_Update",
	permission: boolean
};
	["EventsFields_ticketing_promotionType_Delete"]: {
	__typename: "EventsFields_ticketing_promotionType_Delete",
	permission: boolean
};
	["EventsFields_ticketing_location"]: {
	__typename: "EventsFields_ticketing_location",
	create?: GraphQLTypes["EventsFields_ticketing_location_Create"] | undefined,
	read?: GraphQLTypes["EventsFields_ticketing_location_Read"] | undefined,
	update?: GraphQLTypes["EventsFields_ticketing_location_Update"] | undefined,
	delete?: GraphQLTypes["EventsFields_ticketing_location_Delete"] | undefined
};
	["EventsFields_ticketing_location_Create"]: {
	__typename: "EventsFields_ticketing_location_Create",
	permission: boolean
};
	["EventsFields_ticketing_location_Read"]: {
	__typename: "EventsFields_ticketing_location_Read",
	permission: boolean
};
	["EventsFields_ticketing_location_Update"]: {
	__typename: "EventsFields_ticketing_location_Update",
	permission: boolean
};
	["EventsFields_ticketing_location_Delete"]: {
	__typename: "EventsFields_ticketing_location_Delete",
	permission: boolean
};
	["EventsFields_ticketing_website"]: {
	__typename: "EventsFields_ticketing_website",
	create?: GraphQLTypes["EventsFields_ticketing_website_Create"] | undefined,
	read?: GraphQLTypes["EventsFields_ticketing_website_Read"] | undefined,
	update?: GraphQLTypes["EventsFields_ticketing_website_Update"] | undefined,
	delete?: GraphQLTypes["EventsFields_ticketing_website_Delete"] | undefined
};
	["EventsFields_ticketing_website_Create"]: {
	__typename: "EventsFields_ticketing_website_Create",
	permission: boolean
};
	["EventsFields_ticketing_website_Read"]: {
	__typename: "EventsFields_ticketing_website_Read",
	permission: boolean
};
	["EventsFields_ticketing_website_Update"]: {
	__typename: "EventsFields_ticketing_website_Update",
	permission: boolean
};
	["EventsFields_ticketing_website_Delete"]: {
	__typename: "EventsFields_ticketing_website_Delete",
	permission: boolean
};
	["EventsFields_ticketing_description"]: {
	__typename: "EventsFields_ticketing_description",
	create?: GraphQLTypes["EventsFields_ticketing_description_Create"] | undefined,
	read?: GraphQLTypes["EventsFields_ticketing_description_Read"] | undefined,
	update?: GraphQLTypes["EventsFields_ticketing_description_Update"] | undefined,
	delete?: GraphQLTypes["EventsFields_ticketing_description_Delete"] | undefined
};
	["EventsFields_ticketing_description_Create"]: {
	__typename: "EventsFields_ticketing_description_Create",
	permission: boolean
};
	["EventsFields_ticketing_description_Read"]: {
	__typename: "EventsFields_ticketing_description_Read",
	permission: boolean
};
	["EventsFields_ticketing_description_Update"]: {
	__typename: "EventsFields_ticketing_description_Update",
	permission: boolean
};
	["EventsFields_ticketing_description_Delete"]: {
	__typename: "EventsFields_ticketing_description_Delete",
	permission: boolean
};
	["EventsFields_ticketing_id"]: {
	__typename: "EventsFields_ticketing_id",
	create?: GraphQLTypes["EventsFields_ticketing_id_Create"] | undefined,
	read?: GraphQLTypes["EventsFields_ticketing_id_Read"] | undefined,
	update?: GraphQLTypes["EventsFields_ticketing_id_Update"] | undefined,
	delete?: GraphQLTypes["EventsFields_ticketing_id_Delete"] | undefined
};
	["EventsFields_ticketing_id_Create"]: {
	__typename: "EventsFields_ticketing_id_Create",
	permission: boolean
};
	["EventsFields_ticketing_id_Read"]: {
	__typename: "EventsFields_ticketing_id_Read",
	permission: boolean
};
	["EventsFields_ticketing_id_Update"]: {
	__typename: "EventsFields_ticketing_id_Update",
	permission: boolean
};
	["EventsFields_ticketing_id_Delete"]: {
	__typename: "EventsFields_ticketing_id_Delete",
	permission: boolean
};
	["EventsFields_content"]: {
	__typename: "EventsFields_content",
	create?: GraphQLTypes["EventsFields_content_Create"] | undefined,
	read?: GraphQLTypes["EventsFields_content_Read"] | undefined,
	update?: GraphQLTypes["EventsFields_content_Update"] | undefined,
	delete?: GraphQLTypes["EventsFields_content_Delete"] | undefined
};
	["EventsFields_content_Create"]: {
	__typename: "EventsFields_content_Create",
	permission: boolean
};
	["EventsFields_content_Read"]: {
	__typename: "EventsFields_content_Read",
	permission: boolean
};
	["EventsFields_content_Update"]: {
	__typename: "EventsFields_content_Update",
	permission: boolean
};
	["EventsFields_content_Delete"]: {
	__typename: "EventsFields_content_Delete",
	permission: boolean
};
	["EventsFields_legacyContent"]: {
	__typename: "EventsFields_legacyContent",
	create?: GraphQLTypes["EventsFields_legacyContent_Create"] | undefined,
	read?: GraphQLTypes["EventsFields_legacyContent_Read"] | undefined,
	update?: GraphQLTypes["EventsFields_legacyContent_Update"] | undefined,
	delete?: GraphQLTypes["EventsFields_legacyContent_Delete"] | undefined
};
	["EventsFields_legacyContent_Create"]: {
	__typename: "EventsFields_legacyContent_Create",
	permission: boolean
};
	["EventsFields_legacyContent_Read"]: {
	__typename: "EventsFields_legacyContent_Read",
	permission: boolean
};
	["EventsFields_legacyContent_Update"]: {
	__typename: "EventsFields_legacyContent_Update",
	permission: boolean
};
	["EventsFields_legacyContent_Delete"]: {
	__typename: "EventsFields_legacyContent_Delete",
	permission: boolean
};
	["EventsFields_showLegacyContent"]: {
	__typename: "EventsFields_showLegacyContent",
	create?: GraphQLTypes["EventsFields_showLegacyContent_Create"] | undefined,
	read?: GraphQLTypes["EventsFields_showLegacyContent_Read"] | undefined,
	update?: GraphQLTypes["EventsFields_showLegacyContent_Update"] | undefined,
	delete?: GraphQLTypes["EventsFields_showLegacyContent_Delete"] | undefined
};
	["EventsFields_showLegacyContent_Create"]: {
	__typename: "EventsFields_showLegacyContent_Create",
	permission: boolean
};
	["EventsFields_showLegacyContent_Read"]: {
	__typename: "EventsFields_showLegacyContent_Read",
	permission: boolean
};
	["EventsFields_showLegacyContent_Update"]: {
	__typename: "EventsFields_showLegacyContent_Update",
	permission: boolean
};
	["EventsFields_showLegacyContent_Delete"]: {
	__typename: "EventsFields_showLegacyContent_Delete",
	permission: boolean
};
	["EventsFields_criteria"]: {
	__typename: "EventsFields_criteria",
	create?: GraphQLTypes["EventsFields_criteria_Create"] | undefined,
	read?: GraphQLTypes["EventsFields_criteria_Read"] | undefined,
	update?: GraphQLTypes["EventsFields_criteria_Update"] | undefined,
	delete?: GraphQLTypes["EventsFields_criteria_Delete"] | undefined,
	fields?: GraphQLTypes["EventsFields_criteria_Fields"] | undefined
};
	["EventsFields_criteria_Create"]: {
	__typename: "EventsFields_criteria_Create",
	permission: boolean
};
	["EventsFields_criteria_Read"]: {
	__typename: "EventsFields_criteria_Read",
	permission: boolean
};
	["EventsFields_criteria_Update"]: {
	__typename: "EventsFields_criteria_Update",
	permission: boolean
};
	["EventsFields_criteria_Delete"]: {
	__typename: "EventsFields_criteria_Delete",
	permission: boolean
};
	["EventsFields_criteria_Fields"]: {
	__typename: "EventsFields_criteria_Fields",
	categories?: GraphQLTypes["EventsFields_criteria_categories"] | undefined,
	audiences?: GraphQLTypes["EventsFields_criteria_audiences"] | undefined,
	tags?: GraphQLTypes["EventsFields_criteria_tags"] | undefined,
	specialTags?: GraphQLTypes["EventsFields_criteria_specialTags"] | undefined
};
	["EventsFields_criteria_categories"]: {
	__typename: "EventsFields_criteria_categories",
	create?: GraphQLTypes["EventsFields_criteria_categories_Create"] | undefined,
	read?: GraphQLTypes["EventsFields_criteria_categories_Read"] | undefined,
	update?: GraphQLTypes["EventsFields_criteria_categories_Update"] | undefined,
	delete?: GraphQLTypes["EventsFields_criteria_categories_Delete"] | undefined
};
	["EventsFields_criteria_categories_Create"]: {
	__typename: "EventsFields_criteria_categories_Create",
	permission: boolean
};
	["EventsFields_criteria_categories_Read"]: {
	__typename: "EventsFields_criteria_categories_Read",
	permission: boolean
};
	["EventsFields_criteria_categories_Update"]: {
	__typename: "EventsFields_criteria_categories_Update",
	permission: boolean
};
	["EventsFields_criteria_categories_Delete"]: {
	__typename: "EventsFields_criteria_categories_Delete",
	permission: boolean
};
	["EventsFields_criteria_audiences"]: {
	__typename: "EventsFields_criteria_audiences",
	create?: GraphQLTypes["EventsFields_criteria_audiences_Create"] | undefined,
	read?: GraphQLTypes["EventsFields_criteria_audiences_Read"] | undefined,
	update?: GraphQLTypes["EventsFields_criteria_audiences_Update"] | undefined,
	delete?: GraphQLTypes["EventsFields_criteria_audiences_Delete"] | undefined
};
	["EventsFields_criteria_audiences_Create"]: {
	__typename: "EventsFields_criteria_audiences_Create",
	permission: boolean
};
	["EventsFields_criteria_audiences_Read"]: {
	__typename: "EventsFields_criteria_audiences_Read",
	permission: boolean
};
	["EventsFields_criteria_audiences_Update"]: {
	__typename: "EventsFields_criteria_audiences_Update",
	permission: boolean
};
	["EventsFields_criteria_audiences_Delete"]: {
	__typename: "EventsFields_criteria_audiences_Delete",
	permission: boolean
};
	["EventsFields_criteria_tags"]: {
	__typename: "EventsFields_criteria_tags",
	create?: GraphQLTypes["EventsFields_criteria_tags_Create"] | undefined,
	read?: GraphQLTypes["EventsFields_criteria_tags_Read"] | undefined,
	update?: GraphQLTypes["EventsFields_criteria_tags_Update"] | undefined,
	delete?: GraphQLTypes["EventsFields_criteria_tags_Delete"] | undefined
};
	["EventsFields_criteria_tags_Create"]: {
	__typename: "EventsFields_criteria_tags_Create",
	permission: boolean
};
	["EventsFields_criteria_tags_Read"]: {
	__typename: "EventsFields_criteria_tags_Read",
	permission: boolean
};
	["EventsFields_criteria_tags_Update"]: {
	__typename: "EventsFields_criteria_tags_Update",
	permission: boolean
};
	["EventsFields_criteria_tags_Delete"]: {
	__typename: "EventsFields_criteria_tags_Delete",
	permission: boolean
};
	["EventsFields_criteria_specialTags"]: {
	__typename: "EventsFields_criteria_specialTags",
	create?: GraphQLTypes["EventsFields_criteria_specialTags_Create"] | undefined,
	read?: GraphQLTypes["EventsFields_criteria_specialTags_Read"] | undefined,
	update?: GraphQLTypes["EventsFields_criteria_specialTags_Update"] | undefined,
	delete?: GraphQLTypes["EventsFields_criteria_specialTags_Delete"] | undefined
};
	["EventsFields_criteria_specialTags_Create"]: {
	__typename: "EventsFields_criteria_specialTags_Create",
	permission: boolean
};
	["EventsFields_criteria_specialTags_Read"]: {
	__typename: "EventsFields_criteria_specialTags_Read",
	permission: boolean
};
	["EventsFields_criteria_specialTags_Update"]: {
	__typename: "EventsFields_criteria_specialTags_Update",
	permission: boolean
};
	["EventsFields_criteria_specialTags_Delete"]: {
	__typename: "EventsFields_criteria_specialTags_Delete",
	permission: boolean
};
	["EventsFields_walkIn"]: {
	__typename: "EventsFields_walkIn",
	create?: GraphQLTypes["EventsFields_walkIn_Create"] | undefined,
	read?: GraphQLTypes["EventsFields_walkIn_Read"] | undefined,
	update?: GraphQLTypes["EventsFields_walkIn_Update"] | undefined,
	delete?: GraphQLTypes["EventsFields_walkIn_Delete"] | undefined
};
	["EventsFields_walkIn_Create"]: {
	__typename: "EventsFields_walkIn_Create",
	permission: boolean
};
	["EventsFields_walkIn_Read"]: {
	__typename: "EventsFields_walkIn_Read",
	permission: boolean
};
	["EventsFields_walkIn_Update"]: {
	__typename: "EventsFields_walkIn_Update",
	permission: boolean
};
	["EventsFields_walkIn_Delete"]: {
	__typename: "EventsFields_walkIn_Delete",
	permission: boolean
};
	["EventsFields_contact"]: {
	__typename: "EventsFields_contact",
	create?: GraphQLTypes["EventsFields_contact_Create"] | undefined,
	read?: GraphQLTypes["EventsFields_contact_Read"] | undefined,
	update?: GraphQLTypes["EventsFields_contact_Update"] | undefined,
	delete?: GraphQLTypes["EventsFields_contact_Delete"] | undefined,
	fields?: GraphQLTypes["EventsFields_contact_Fields"] | undefined
};
	["EventsFields_contact_Create"]: {
	__typename: "EventsFields_contact_Create",
	permission: boolean
};
	["EventsFields_contact_Read"]: {
	__typename: "EventsFields_contact_Read",
	permission: boolean
};
	["EventsFields_contact_Update"]: {
	__typename: "EventsFields_contact_Update",
	permission: boolean
};
	["EventsFields_contact_Delete"]: {
	__typename: "EventsFields_contact_Delete",
	permission: boolean
};
	["EventsFields_contact_Fields"]: {
	__typename: "EventsFields_contact_Fields",
	code?: GraphQLTypes["EventsFields_contact_code"] | undefined,
	phone?: GraphQLTypes["EventsFields_contact_phone"] | undefined,
	page?: GraphQLTypes["EventsFields_contact_page"] | undefined,
	email?: GraphQLTypes["EventsFields_contact_email"] | undefined,
	privacy?: GraphQLTypes["EventsFields_contact_privacy"] | undefined
};
	["EventsFields_contact_code"]: {
	__typename: "EventsFields_contact_code",
	create?: GraphQLTypes["EventsFields_contact_code_Create"] | undefined,
	read?: GraphQLTypes["EventsFields_contact_code_Read"] | undefined,
	update?: GraphQLTypes["EventsFields_contact_code_Update"] | undefined,
	delete?: GraphQLTypes["EventsFields_contact_code_Delete"] | undefined
};
	["EventsFields_contact_code_Create"]: {
	__typename: "EventsFields_contact_code_Create",
	permission: boolean
};
	["EventsFields_contact_code_Read"]: {
	__typename: "EventsFields_contact_code_Read",
	permission: boolean
};
	["EventsFields_contact_code_Update"]: {
	__typename: "EventsFields_contact_code_Update",
	permission: boolean
};
	["EventsFields_contact_code_Delete"]: {
	__typename: "EventsFields_contact_code_Delete",
	permission: boolean
};
	["EventsFields_contact_phone"]: {
	__typename: "EventsFields_contact_phone",
	create?: GraphQLTypes["EventsFields_contact_phone_Create"] | undefined,
	read?: GraphQLTypes["EventsFields_contact_phone_Read"] | undefined,
	update?: GraphQLTypes["EventsFields_contact_phone_Update"] | undefined,
	delete?: GraphQLTypes["EventsFields_contact_phone_Delete"] | undefined
};
	["EventsFields_contact_phone_Create"]: {
	__typename: "EventsFields_contact_phone_Create",
	permission: boolean
};
	["EventsFields_contact_phone_Read"]: {
	__typename: "EventsFields_contact_phone_Read",
	permission: boolean
};
	["EventsFields_contact_phone_Update"]: {
	__typename: "EventsFields_contact_phone_Update",
	permission: boolean
};
	["EventsFields_contact_phone_Delete"]: {
	__typename: "EventsFields_contact_phone_Delete",
	permission: boolean
};
	["EventsFields_contact_page"]: {
	__typename: "EventsFields_contact_page",
	create?: GraphQLTypes["EventsFields_contact_page_Create"] | undefined,
	read?: GraphQLTypes["EventsFields_contact_page_Read"] | undefined,
	update?: GraphQLTypes["EventsFields_contact_page_Update"] | undefined,
	delete?: GraphQLTypes["EventsFields_contact_page_Delete"] | undefined
};
	["EventsFields_contact_page_Create"]: {
	__typename: "EventsFields_contact_page_Create",
	permission: boolean
};
	["EventsFields_contact_page_Read"]: {
	__typename: "EventsFields_contact_page_Read",
	permission: boolean
};
	["EventsFields_contact_page_Update"]: {
	__typename: "EventsFields_contact_page_Update",
	permission: boolean
};
	["EventsFields_contact_page_Delete"]: {
	__typename: "EventsFields_contact_page_Delete",
	permission: boolean
};
	["EventsFields_contact_email"]: {
	__typename: "EventsFields_contact_email",
	create?: GraphQLTypes["EventsFields_contact_email_Create"] | undefined,
	read?: GraphQLTypes["EventsFields_contact_email_Read"] | undefined,
	update?: GraphQLTypes["EventsFields_contact_email_Update"] | undefined,
	delete?: GraphQLTypes["EventsFields_contact_email_Delete"] | undefined
};
	["EventsFields_contact_email_Create"]: {
	__typename: "EventsFields_contact_email_Create",
	permission: boolean
};
	["EventsFields_contact_email_Read"]: {
	__typename: "EventsFields_contact_email_Read",
	permission: boolean
};
	["EventsFields_contact_email_Update"]: {
	__typename: "EventsFields_contact_email_Update",
	permission: boolean
};
	["EventsFields_contact_email_Delete"]: {
	__typename: "EventsFields_contact_email_Delete",
	permission: boolean
};
	["EventsFields_contact_privacy"]: {
	__typename: "EventsFields_contact_privacy",
	create?: GraphQLTypes["EventsFields_contact_privacy_Create"] | undefined,
	read?: GraphQLTypes["EventsFields_contact_privacy_Read"] | undefined,
	update?: GraphQLTypes["EventsFields_contact_privacy_Update"] | undefined,
	delete?: GraphQLTypes["EventsFields_contact_privacy_Delete"] | undefined
};
	["EventsFields_contact_privacy_Create"]: {
	__typename: "EventsFields_contact_privacy_Create",
	permission: boolean
};
	["EventsFields_contact_privacy_Read"]: {
	__typename: "EventsFields_contact_privacy_Read",
	permission: boolean
};
	["EventsFields_contact_privacy_Update"]: {
	__typename: "EventsFields_contact_privacy_Update",
	permission: boolean
};
	["EventsFields_contact_privacy_Delete"]: {
	__typename: "EventsFields_contact_privacy_Delete",
	permission: boolean
};
	["EventsFields_views"]: {
	__typename: "EventsFields_views",
	create?: GraphQLTypes["EventsFields_views_Create"] | undefined,
	read?: GraphQLTypes["EventsFields_views_Read"] | undefined,
	update?: GraphQLTypes["EventsFields_views_Update"] | undefined,
	delete?: GraphQLTypes["EventsFields_views_Delete"] | undefined
};
	["EventsFields_views_Create"]: {
	__typename: "EventsFields_views_Create",
	permission: boolean
};
	["EventsFields_views_Read"]: {
	__typename: "EventsFields_views_Read",
	permission: boolean
};
	["EventsFields_views_Update"]: {
	__typename: "EventsFields_views_Update",
	permission: boolean
};
	["EventsFields_views_Delete"]: {
	__typename: "EventsFields_views_Delete",
	permission: boolean
};
	["EventsFields_free"]: {
	__typename: "EventsFields_free",
	create?: GraphQLTypes["EventsFields_free_Create"] | undefined,
	read?: GraphQLTypes["EventsFields_free_Read"] | undefined,
	update?: GraphQLTypes["EventsFields_free_Update"] | undefined,
	delete?: GraphQLTypes["EventsFields_free_Delete"] | undefined
};
	["EventsFields_free_Create"]: {
	__typename: "EventsFields_free_Create",
	permission: boolean
};
	["EventsFields_free_Read"]: {
	__typename: "EventsFields_free_Read",
	permission: boolean
};
	["EventsFields_free_Update"]: {
	__typename: "EventsFields_free_Update",
	permission: boolean
};
	["EventsFields_free_Delete"]: {
	__typename: "EventsFields_free_Delete",
	permission: boolean
};
	["EventsFields_fees"]: {
	__typename: "EventsFields_fees",
	create?: GraphQLTypes["EventsFields_fees_Create"] | undefined,
	read?: GraphQLTypes["EventsFields_fees_Read"] | undefined,
	update?: GraphQLTypes["EventsFields_fees_Update"] | undefined,
	delete?: GraphQLTypes["EventsFields_fees_Delete"] | undefined,
	fields?: GraphQLTypes["EventsFields_fees_Fields"] | undefined
};
	["EventsFields_fees_Create"]: {
	__typename: "EventsFields_fees_Create",
	permission: boolean
};
	["EventsFields_fees_Read"]: {
	__typename: "EventsFields_fees_Read",
	permission: boolean
};
	["EventsFields_fees_Update"]: {
	__typename: "EventsFields_fees_Update",
	permission: boolean
};
	["EventsFields_fees_Delete"]: {
	__typename: "EventsFields_fees_Delete",
	permission: boolean
};
	["EventsFields_fees_Fields"]: {
	__typename: "EventsFields_fees_Fields",
	fee?: GraphQLTypes["EventsFields_fees_fee"] | undefined,
	remark?: GraphQLTypes["EventsFields_fees_remark"] | undefined,
	id?: GraphQLTypes["EventsFields_fees_id"] | undefined
};
	["EventsFields_fees_fee"]: {
	__typename: "EventsFields_fees_fee",
	create?: GraphQLTypes["EventsFields_fees_fee_Create"] | undefined,
	read?: GraphQLTypes["EventsFields_fees_fee_Read"] | undefined,
	update?: GraphQLTypes["EventsFields_fees_fee_Update"] | undefined,
	delete?: GraphQLTypes["EventsFields_fees_fee_Delete"] | undefined
};
	["EventsFields_fees_fee_Create"]: {
	__typename: "EventsFields_fees_fee_Create",
	permission: boolean
};
	["EventsFields_fees_fee_Read"]: {
	__typename: "EventsFields_fees_fee_Read",
	permission: boolean
};
	["EventsFields_fees_fee_Update"]: {
	__typename: "EventsFields_fees_fee_Update",
	permission: boolean
};
	["EventsFields_fees_fee_Delete"]: {
	__typename: "EventsFields_fees_fee_Delete",
	permission: boolean
};
	["EventsFields_fees_remark"]: {
	__typename: "EventsFields_fees_remark",
	create?: GraphQLTypes["EventsFields_fees_remark_Create"] | undefined,
	read?: GraphQLTypes["EventsFields_fees_remark_Read"] | undefined,
	update?: GraphQLTypes["EventsFields_fees_remark_Update"] | undefined,
	delete?: GraphQLTypes["EventsFields_fees_remark_Delete"] | undefined
};
	["EventsFields_fees_remark_Create"]: {
	__typename: "EventsFields_fees_remark_Create",
	permission: boolean
};
	["EventsFields_fees_remark_Read"]: {
	__typename: "EventsFields_fees_remark_Read",
	permission: boolean
};
	["EventsFields_fees_remark_Update"]: {
	__typename: "EventsFields_fees_remark_Update",
	permission: boolean
};
	["EventsFields_fees_remark_Delete"]: {
	__typename: "EventsFields_fees_remark_Delete",
	permission: boolean
};
	["EventsFields_fees_id"]: {
	__typename: "EventsFields_fees_id",
	create?: GraphQLTypes["EventsFields_fees_id_Create"] | undefined,
	read?: GraphQLTypes["EventsFields_fees_id_Read"] | undefined,
	update?: GraphQLTypes["EventsFields_fees_id_Update"] | undefined,
	delete?: GraphQLTypes["EventsFields_fees_id_Delete"] | undefined
};
	["EventsFields_fees_id_Create"]: {
	__typename: "EventsFields_fees_id_Create",
	permission: boolean
};
	["EventsFields_fees_id_Read"]: {
	__typename: "EventsFields_fees_id_Read",
	permission: boolean
};
	["EventsFields_fees_id_Update"]: {
	__typename: "EventsFields_fees_id_Update",
	permission: boolean
};
	["EventsFields_fees_id_Delete"]: {
	__typename: "EventsFields_fees_id_Delete",
	permission: boolean
};
	["EventsFields_homePageInternalClick"]: {
	__typename: "EventsFields_homePageInternalClick",
	create?: GraphQLTypes["EventsFields_homePageInternalClick_Create"] | undefined,
	read?: GraphQLTypes["EventsFields_homePageInternalClick_Read"] | undefined,
	update?: GraphQLTypes["EventsFields_homePageInternalClick_Update"] | undefined,
	delete?: GraphQLTypes["EventsFields_homePageInternalClick_Delete"] | undefined
};
	["EventsFields_homePageInternalClick_Create"]: {
	__typename: "EventsFields_homePageInternalClick_Create",
	permission: boolean
};
	["EventsFields_homePageInternalClick_Read"]: {
	__typename: "EventsFields_homePageInternalClick_Read",
	permission: boolean
};
	["EventsFields_homePageInternalClick_Update"]: {
	__typename: "EventsFields_homePageInternalClick_Update",
	permission: boolean
};
	["EventsFields_homePageInternalClick_Delete"]: {
	__typename: "EventsFields_homePageInternalClick_Delete",
	permission: boolean
};
	["EventsFields_homePageImpression"]: {
	__typename: "EventsFields_homePageImpression",
	create?: GraphQLTypes["EventsFields_homePageImpression_Create"] | undefined,
	read?: GraphQLTypes["EventsFields_homePageImpression_Read"] | undefined,
	update?: GraphQLTypes["EventsFields_homePageImpression_Update"] | undefined,
	delete?: GraphQLTypes["EventsFields_homePageImpression_Delete"] | undefined
};
	["EventsFields_homePageImpression_Create"]: {
	__typename: "EventsFields_homePageImpression_Create",
	permission: boolean
};
	["EventsFields_homePageImpression_Read"]: {
	__typename: "EventsFields_homePageImpression_Read",
	permission: boolean
};
	["EventsFields_homePageImpression_Update"]: {
	__typename: "EventsFields_homePageImpression_Update",
	permission: boolean
};
	["EventsFields_homePageImpression_Delete"]: {
	__typename: "EventsFields_homePageImpression_Delete",
	permission: boolean
};
	["EventsFields_eodClickRate"]: {
	__typename: "EventsFields_eodClickRate",
	create?: GraphQLTypes["EventsFields_eodClickRate_Create"] | undefined,
	read?: GraphQLTypes["EventsFields_eodClickRate_Read"] | undefined,
	update?: GraphQLTypes["EventsFields_eodClickRate_Update"] | undefined,
	delete?: GraphQLTypes["EventsFields_eodClickRate_Delete"] | undefined
};
	["EventsFields_eodClickRate_Create"]: {
	__typename: "EventsFields_eodClickRate_Create",
	permission: boolean
};
	["EventsFields_eodClickRate_Read"]: {
	__typename: "EventsFields_eodClickRate_Read",
	permission: boolean
};
	["EventsFields_eodClickRate_Update"]: {
	__typename: "EventsFields_eodClickRate_Update",
	permission: boolean
};
	["EventsFields_eodClickRate_Delete"]: {
	__typename: "EventsFields_eodClickRate_Delete",
	permission: boolean
};
	["EventsFields_eodImpressionRate"]: {
	__typename: "EventsFields_eodImpressionRate",
	create?: GraphQLTypes["EventsFields_eodImpressionRate_Create"] | undefined,
	read?: GraphQLTypes["EventsFields_eodImpressionRate_Read"] | undefined,
	update?: GraphQLTypes["EventsFields_eodImpressionRate_Update"] | undefined,
	delete?: GraphQLTypes["EventsFields_eodImpressionRate_Delete"] | undefined
};
	["EventsFields_eodImpressionRate_Create"]: {
	__typename: "EventsFields_eodImpressionRate_Create",
	permission: boolean
};
	["EventsFields_eodImpressionRate_Read"]: {
	__typename: "EventsFields_eodImpressionRate_Read",
	permission: boolean
};
	["EventsFields_eodImpressionRate_Update"]: {
	__typename: "EventsFields_eodImpressionRate_Update",
	permission: boolean
};
	["EventsFields_eodImpressionRate_Delete"]: {
	__typename: "EventsFields_eodImpressionRate_Delete",
	permission: boolean
};
	["EventsFields_top10ClickRateHomepage"]: {
	__typename: "EventsFields_top10ClickRateHomepage",
	create?: GraphQLTypes["EventsFields_top10ClickRateHomepage_Create"] | undefined,
	read?: GraphQLTypes["EventsFields_top10ClickRateHomepage_Read"] | undefined,
	update?: GraphQLTypes["EventsFields_top10ClickRateHomepage_Update"] | undefined,
	delete?: GraphQLTypes["EventsFields_top10ClickRateHomepage_Delete"] | undefined
};
	["EventsFields_top10ClickRateHomepage_Create"]: {
	__typename: "EventsFields_top10ClickRateHomepage_Create",
	permission: boolean
};
	["EventsFields_top10ClickRateHomepage_Read"]: {
	__typename: "EventsFields_top10ClickRateHomepage_Read",
	permission: boolean
};
	["EventsFields_top10ClickRateHomepage_Update"]: {
	__typename: "EventsFields_top10ClickRateHomepage_Update",
	permission: boolean
};
	["EventsFields_top10ClickRateHomepage_Delete"]: {
	__typename: "EventsFields_top10ClickRateHomepage_Delete",
	permission: boolean
};
	["EventsFields_top10ImpressionkRateHomepage"]: {
	__typename: "EventsFields_top10ImpressionkRateHomepage",
	create?: GraphQLTypes["EventsFields_top10ImpressionkRateHomepage_Create"] | undefined,
	read?: GraphQLTypes["EventsFields_top10ImpressionkRateHomepage_Read"] | undefined,
	update?: GraphQLTypes["EventsFields_top10ImpressionkRateHomepage_Update"] | undefined,
	delete?: GraphQLTypes["EventsFields_top10ImpressionkRateHomepage_Delete"] | undefined
};
	["EventsFields_top10ImpressionkRateHomepage_Create"]: {
	__typename: "EventsFields_top10ImpressionkRateHomepage_Create",
	permission: boolean
};
	["EventsFields_top10ImpressionkRateHomepage_Read"]: {
	__typename: "EventsFields_top10ImpressionkRateHomepage_Read",
	permission: boolean
};
	["EventsFields_top10ImpressionkRateHomepage_Update"]: {
	__typename: "EventsFields_top10ImpressionkRateHomepage_Update",
	permission: boolean
};
	["EventsFields_top10ImpressionkRateHomepage_Delete"]: {
	__typename: "EventsFields_top10ImpressionkRateHomepage_Delete",
	permission: boolean
};
	["EventsFields_otherEventRankingClickRateHomepage"]: {
	__typename: "EventsFields_otherEventRankingClickRateHomepage",
	create?: GraphQLTypes["EventsFields_otherEventRankingClickRateHomepage_Create"] | undefined,
	read?: GraphQLTypes["EventsFields_otherEventRankingClickRateHomepage_Read"] | undefined,
	update?: GraphQLTypes["EventsFields_otherEventRankingClickRateHomepage_Update"] | undefined,
	delete?: GraphQLTypes["EventsFields_otherEventRankingClickRateHomepage_Delete"] | undefined
};
	["EventsFields_otherEventRankingClickRateHomepage_Create"]: {
	__typename: "EventsFields_otherEventRankingClickRateHomepage_Create",
	permission: boolean
};
	["EventsFields_otherEventRankingClickRateHomepage_Read"]: {
	__typename: "EventsFields_otherEventRankingClickRateHomepage_Read",
	permission: boolean
};
	["EventsFields_otherEventRankingClickRateHomepage_Update"]: {
	__typename: "EventsFields_otherEventRankingClickRateHomepage_Update",
	permission: boolean
};
	["EventsFields_otherEventRankingClickRateHomepage_Delete"]: {
	__typename: "EventsFields_otherEventRankingClickRateHomepage_Delete",
	permission: boolean
};
	["EventsFields_otherEventRankingImpressionHomePage"]: {
	__typename: "EventsFields_otherEventRankingImpressionHomePage",
	create?: GraphQLTypes["EventsFields_otherEventRankingImpressionHomePage_Create"] | undefined,
	read?: GraphQLTypes["EventsFields_otherEventRankingImpressionHomePage_Read"] | undefined,
	update?: GraphQLTypes["EventsFields_otherEventRankingImpressionHomePage_Update"] | undefined,
	delete?: GraphQLTypes["EventsFields_otherEventRankingImpressionHomePage_Delete"] | undefined
};
	["EventsFields_otherEventRankingImpressionHomePage_Create"]: {
	__typename: "EventsFields_otherEventRankingImpressionHomePage_Create",
	permission: boolean
};
	["EventsFields_otherEventRankingImpressionHomePage_Read"]: {
	__typename: "EventsFields_otherEventRankingImpressionHomePage_Read",
	permission: boolean
};
	["EventsFields_otherEventRankingImpressionHomePage_Update"]: {
	__typename: "EventsFields_otherEventRankingImpressionHomePage_Update",
	permission: boolean
};
	["EventsFields_otherEventRankingImpressionHomePage_Delete"]: {
	__typename: "EventsFields_otherEventRankingImpressionHomePage_Delete",
	permission: boolean
};
	["EventsFields_userPreferenceClickRate"]: {
	__typename: "EventsFields_userPreferenceClickRate",
	create?: GraphQLTypes["EventsFields_userPreferenceClickRate_Create"] | undefined,
	read?: GraphQLTypes["EventsFields_userPreferenceClickRate_Read"] | undefined,
	update?: GraphQLTypes["EventsFields_userPreferenceClickRate_Update"] | undefined,
	delete?: GraphQLTypes["EventsFields_userPreferenceClickRate_Delete"] | undefined
};
	["EventsFields_userPreferenceClickRate_Create"]: {
	__typename: "EventsFields_userPreferenceClickRate_Create",
	permission: boolean
};
	["EventsFields_userPreferenceClickRate_Read"]: {
	__typename: "EventsFields_userPreferenceClickRate_Read",
	permission: boolean
};
	["EventsFields_userPreferenceClickRate_Update"]: {
	__typename: "EventsFields_userPreferenceClickRate_Update",
	permission: boolean
};
	["EventsFields_userPreferenceClickRate_Delete"]: {
	__typename: "EventsFields_userPreferenceClickRate_Delete",
	permission: boolean
};
	["EventsFields_userPreferenceImpression"]: {
	__typename: "EventsFields_userPreferenceImpression",
	create?: GraphQLTypes["EventsFields_userPreferenceImpression_Create"] | undefined,
	read?: GraphQLTypes["EventsFields_userPreferenceImpression_Read"] | undefined,
	update?: GraphQLTypes["EventsFields_userPreferenceImpression_Update"] | undefined,
	delete?: GraphQLTypes["EventsFields_userPreferenceImpression_Delete"] | undefined
};
	["EventsFields_userPreferenceImpression_Create"]: {
	__typename: "EventsFields_userPreferenceImpression_Create",
	permission: boolean
};
	["EventsFields_userPreferenceImpression_Read"]: {
	__typename: "EventsFields_userPreferenceImpression_Read",
	permission: boolean
};
	["EventsFields_userPreferenceImpression_Update"]: {
	__typename: "EventsFields_userPreferenceImpression_Update",
	permission: boolean
};
	["EventsFields_userPreferenceImpression_Delete"]: {
	__typename: "EventsFields_userPreferenceImpression_Delete",
	permission: boolean
};
	["EventsFields_eventSearchInternalClick"]: {
	__typename: "EventsFields_eventSearchInternalClick",
	create?: GraphQLTypes["EventsFields_eventSearchInternalClick_Create"] | undefined,
	read?: GraphQLTypes["EventsFields_eventSearchInternalClick_Read"] | undefined,
	update?: GraphQLTypes["EventsFields_eventSearchInternalClick_Update"] | undefined,
	delete?: GraphQLTypes["EventsFields_eventSearchInternalClick_Delete"] | undefined
};
	["EventsFields_eventSearchInternalClick_Create"]: {
	__typename: "EventsFields_eventSearchInternalClick_Create",
	permission: boolean
};
	["EventsFields_eventSearchInternalClick_Read"]: {
	__typename: "EventsFields_eventSearchInternalClick_Read",
	permission: boolean
};
	["EventsFields_eventSearchInternalClick_Update"]: {
	__typename: "EventsFields_eventSearchInternalClick_Update",
	permission: boolean
};
	["EventsFields_eventSearchInternalClick_Delete"]: {
	__typename: "EventsFields_eventSearchInternalClick_Delete",
	permission: boolean
};
	["EventsFields_eventListImpression"]: {
	__typename: "EventsFields_eventListImpression",
	create?: GraphQLTypes["EventsFields_eventListImpression_Create"] | undefined,
	read?: GraphQLTypes["EventsFields_eventListImpression_Read"] | undefined,
	update?: GraphQLTypes["EventsFields_eventListImpression_Update"] | undefined,
	delete?: GraphQLTypes["EventsFields_eventListImpression_Delete"] | undefined
};
	["EventsFields_eventListImpression_Create"]: {
	__typename: "EventsFields_eventListImpression_Create",
	permission: boolean
};
	["EventsFields_eventListImpression_Read"]: {
	__typename: "EventsFields_eventListImpression_Read",
	permission: boolean
};
	["EventsFields_eventListImpression_Update"]: {
	__typename: "EventsFields_eventListImpression_Update",
	permission: boolean
};
	["EventsFields_eventListImpression_Delete"]: {
	__typename: "EventsFields_eventListImpression_Delete",
	permission: boolean
};
	["EventsFields_latestEventInternalClick"]: {
	__typename: "EventsFields_latestEventInternalClick",
	create?: GraphQLTypes["EventsFields_latestEventInternalClick_Create"] | undefined,
	read?: GraphQLTypes["EventsFields_latestEventInternalClick_Read"] | undefined,
	update?: GraphQLTypes["EventsFields_latestEventInternalClick_Update"] | undefined,
	delete?: GraphQLTypes["EventsFields_latestEventInternalClick_Delete"] | undefined
};
	["EventsFields_latestEventInternalClick_Create"]: {
	__typename: "EventsFields_latestEventInternalClick_Create",
	permission: boolean
};
	["EventsFields_latestEventInternalClick_Read"]: {
	__typename: "EventsFields_latestEventInternalClick_Read",
	permission: boolean
};
	["EventsFields_latestEventInternalClick_Update"]: {
	__typename: "EventsFields_latestEventInternalClick_Update",
	permission: boolean
};
	["EventsFields_latestEventInternalClick_Delete"]: {
	__typename: "EventsFields_latestEventInternalClick_Delete",
	permission: boolean
};
	["EventsFields_latestEventImpression"]: {
	__typename: "EventsFields_latestEventImpression",
	create?: GraphQLTypes["EventsFields_latestEventImpression_Create"] | undefined,
	read?: GraphQLTypes["EventsFields_latestEventImpression_Read"] | undefined,
	update?: GraphQLTypes["EventsFields_latestEventImpression_Update"] | undefined,
	delete?: GraphQLTypes["EventsFields_latestEventImpression_Delete"] | undefined
};
	["EventsFields_latestEventImpression_Create"]: {
	__typename: "EventsFields_latestEventImpression_Create",
	permission: boolean
};
	["EventsFields_latestEventImpression_Read"]: {
	__typename: "EventsFields_latestEventImpression_Read",
	permission: boolean
};
	["EventsFields_latestEventImpression_Update"]: {
	__typename: "EventsFields_latestEventImpression_Update",
	permission: boolean
};
	["EventsFields_latestEventImpression_Delete"]: {
	__typename: "EventsFields_latestEventImpression_Delete",
	permission: boolean
};
	["EventsFields_topTenEventRankingInternalClick"]: {
	__typename: "EventsFields_topTenEventRankingInternalClick",
	create?: GraphQLTypes["EventsFields_topTenEventRankingInternalClick_Create"] | undefined,
	read?: GraphQLTypes["EventsFields_topTenEventRankingInternalClick_Read"] | undefined,
	update?: GraphQLTypes["EventsFields_topTenEventRankingInternalClick_Update"] | undefined,
	delete?: GraphQLTypes["EventsFields_topTenEventRankingInternalClick_Delete"] | undefined
};
	["EventsFields_topTenEventRankingInternalClick_Create"]: {
	__typename: "EventsFields_topTenEventRankingInternalClick_Create",
	permission: boolean
};
	["EventsFields_topTenEventRankingInternalClick_Read"]: {
	__typename: "EventsFields_topTenEventRankingInternalClick_Read",
	permission: boolean
};
	["EventsFields_topTenEventRankingInternalClick_Update"]: {
	__typename: "EventsFields_topTenEventRankingInternalClick_Update",
	permission: boolean
};
	["EventsFields_topTenEventRankingInternalClick_Delete"]: {
	__typename: "EventsFields_topTenEventRankingInternalClick_Delete",
	permission: boolean
};
	["EventsFields_topTenImpression"]: {
	__typename: "EventsFields_topTenImpression",
	create?: GraphQLTypes["EventsFields_topTenImpression_Create"] | undefined,
	read?: GraphQLTypes["EventsFields_topTenImpression_Read"] | undefined,
	update?: GraphQLTypes["EventsFields_topTenImpression_Update"] | undefined,
	delete?: GraphQLTypes["EventsFields_topTenImpression_Delete"] | undefined
};
	["EventsFields_topTenImpression_Create"]: {
	__typename: "EventsFields_topTenImpression_Create",
	permission: boolean
};
	["EventsFields_topTenImpression_Read"]: {
	__typename: "EventsFields_topTenImpression_Read",
	permission: boolean
};
	["EventsFields_topTenImpression_Update"]: {
	__typename: "EventsFields_topTenImpression_Update",
	permission: boolean
};
	["EventsFields_topTenImpression_Delete"]: {
	__typename: "EventsFields_topTenImpression_Delete",
	permission: boolean
};
	["EventsFields_editorChoiceInternalClick"]: {
	__typename: "EventsFields_editorChoiceInternalClick",
	create?: GraphQLTypes["EventsFields_editorChoiceInternalClick_Create"] | undefined,
	read?: GraphQLTypes["EventsFields_editorChoiceInternalClick_Read"] | undefined,
	update?: GraphQLTypes["EventsFields_editorChoiceInternalClick_Update"] | undefined,
	delete?: GraphQLTypes["EventsFields_editorChoiceInternalClick_Delete"] | undefined
};
	["EventsFields_editorChoiceInternalClick_Create"]: {
	__typename: "EventsFields_editorChoiceInternalClick_Create",
	permission: boolean
};
	["EventsFields_editorChoiceInternalClick_Read"]: {
	__typename: "EventsFields_editorChoiceInternalClick_Read",
	permission: boolean
};
	["EventsFields_editorChoiceInternalClick_Update"]: {
	__typename: "EventsFields_editorChoiceInternalClick_Update",
	permission: boolean
};
	["EventsFields_editorChoiceInternalClick_Delete"]: {
	__typename: "EventsFields_editorChoiceInternalClick_Delete",
	permission: boolean
};
	["EventsFields_editorChoiceImpression"]: {
	__typename: "EventsFields_editorChoiceImpression",
	create?: GraphQLTypes["EventsFields_editorChoiceImpression_Create"] | undefined,
	read?: GraphQLTypes["EventsFields_editorChoiceImpression_Read"] | undefined,
	update?: GraphQLTypes["EventsFields_editorChoiceImpression_Update"] | undefined,
	delete?: GraphQLTypes["EventsFields_editorChoiceImpression_Delete"] | undefined
};
	["EventsFields_editorChoiceImpression_Create"]: {
	__typename: "EventsFields_editorChoiceImpression_Create",
	permission: boolean
};
	["EventsFields_editorChoiceImpression_Read"]: {
	__typename: "EventsFields_editorChoiceImpression_Read",
	permission: boolean
};
	["EventsFields_editorChoiceImpression_Update"]: {
	__typename: "EventsFields_editorChoiceImpression_Update",
	permission: boolean
};
	["EventsFields_editorChoiceImpression_Delete"]: {
	__typename: "EventsFields_editorChoiceImpression_Delete",
	permission: boolean
};
	["EventsFields_otherEventRankingInternalClick"]: {
	__typename: "EventsFields_otherEventRankingInternalClick",
	create?: GraphQLTypes["EventsFields_otherEventRankingInternalClick_Create"] | undefined,
	read?: GraphQLTypes["EventsFields_otherEventRankingInternalClick_Read"] | undefined,
	update?: GraphQLTypes["EventsFields_otherEventRankingInternalClick_Update"] | undefined,
	delete?: GraphQLTypes["EventsFields_otherEventRankingInternalClick_Delete"] | undefined
};
	["EventsFields_otherEventRankingInternalClick_Create"]: {
	__typename: "EventsFields_otherEventRankingInternalClick_Create",
	permission: boolean
};
	["EventsFields_otherEventRankingInternalClick_Read"]: {
	__typename: "EventsFields_otherEventRankingInternalClick_Read",
	permission: boolean
};
	["EventsFields_otherEventRankingInternalClick_Update"]: {
	__typename: "EventsFields_otherEventRankingInternalClick_Update",
	permission: boolean
};
	["EventsFields_otherEventRankingInternalClick_Delete"]: {
	__typename: "EventsFields_otherEventRankingInternalClick_Delete",
	permission: boolean
};
	["EventsFields_otherEventRankingImpression"]: {
	__typename: "EventsFields_otherEventRankingImpression",
	create?: GraphQLTypes["EventsFields_otherEventRankingImpression_Create"] | undefined,
	read?: GraphQLTypes["EventsFields_otherEventRankingImpression_Read"] | undefined,
	update?: GraphQLTypes["EventsFields_otherEventRankingImpression_Update"] | undefined,
	delete?: GraphQLTypes["EventsFields_otherEventRankingImpression_Delete"] | undefined
};
	["EventsFields_otherEventRankingImpression_Create"]: {
	__typename: "EventsFields_otherEventRankingImpression_Create",
	permission: boolean
};
	["EventsFields_otherEventRankingImpression_Read"]: {
	__typename: "EventsFields_otherEventRankingImpression_Read",
	permission: boolean
};
	["EventsFields_otherEventRankingImpression_Update"]: {
	__typename: "EventsFields_otherEventRankingImpression_Update",
	permission: boolean
};
	["EventsFields_otherEventRankingImpression_Delete"]: {
	__typename: "EventsFields_otherEventRankingImpression_Delete",
	permission: boolean
};
	["EventsFields_bannerInternalClick"]: {
	__typename: "EventsFields_bannerInternalClick",
	create?: GraphQLTypes["EventsFields_bannerInternalClick_Create"] | undefined,
	read?: GraphQLTypes["EventsFields_bannerInternalClick_Read"] | undefined,
	update?: GraphQLTypes["EventsFields_bannerInternalClick_Update"] | undefined,
	delete?: GraphQLTypes["EventsFields_bannerInternalClick_Delete"] | undefined
};
	["EventsFields_bannerInternalClick_Create"]: {
	__typename: "EventsFields_bannerInternalClick_Create",
	permission: boolean
};
	["EventsFields_bannerInternalClick_Read"]: {
	__typename: "EventsFields_bannerInternalClick_Read",
	permission: boolean
};
	["EventsFields_bannerInternalClick_Update"]: {
	__typename: "EventsFields_bannerInternalClick_Update",
	permission: boolean
};
	["EventsFields_bannerInternalClick_Delete"]: {
	__typename: "EventsFields_bannerInternalClick_Delete",
	permission: boolean
};
	["EventsFields_miscImpression"]: {
	__typename: "EventsFields_miscImpression",
	create?: GraphQLTypes["EventsFields_miscImpression_Create"] | undefined,
	read?: GraphQLTypes["EventsFields_miscImpression_Read"] | undefined,
	update?: GraphQLTypes["EventsFields_miscImpression_Update"] | undefined,
	delete?: GraphQLTypes["EventsFields_miscImpression_Delete"] | undefined
};
	["EventsFields_miscImpression_Create"]: {
	__typename: "EventsFields_miscImpression_Create",
	permission: boolean
};
	["EventsFields_miscImpression_Read"]: {
	__typename: "EventsFields_miscImpression_Read",
	permission: boolean
};
	["EventsFields_miscImpression_Update"]: {
	__typename: "EventsFields_miscImpression_Update",
	permission: boolean
};
	["EventsFields_miscImpression_Delete"]: {
	__typename: "EventsFields_miscImpression_Delete",
	permission: boolean
};
	["EventsFields_miscInternalClick"]: {
	__typename: "EventsFields_miscInternalClick",
	create?: GraphQLTypes["EventsFields_miscInternalClick_Create"] | undefined,
	read?: GraphQLTypes["EventsFields_miscInternalClick_Read"] | undefined,
	update?: GraphQLTypes["EventsFields_miscInternalClick_Update"] | undefined,
	delete?: GraphQLTypes["EventsFields_miscInternalClick_Delete"] | undefined
};
	["EventsFields_miscInternalClick_Create"]: {
	__typename: "EventsFields_miscInternalClick_Create",
	permission: boolean
};
	["EventsFields_miscInternalClick_Read"]: {
	__typename: "EventsFields_miscInternalClick_Read",
	permission: boolean
};
	["EventsFields_miscInternalClick_Update"]: {
	__typename: "EventsFields_miscInternalClick_Update",
	permission: boolean
};
	["EventsFields_miscInternalClick_Delete"]: {
	__typename: "EventsFields_miscInternalClick_Delete",
	permission: boolean
};
	["EventsFields_totalImpression"]: {
	__typename: "EventsFields_totalImpression",
	create?: GraphQLTypes["EventsFields_totalImpression_Create"] | undefined,
	read?: GraphQLTypes["EventsFields_totalImpression_Read"] | undefined,
	update?: GraphQLTypes["EventsFields_totalImpression_Update"] | undefined,
	delete?: GraphQLTypes["EventsFields_totalImpression_Delete"] | undefined
};
	["EventsFields_totalImpression_Create"]: {
	__typename: "EventsFields_totalImpression_Create",
	permission: boolean
};
	["EventsFields_totalImpression_Read"]: {
	__typename: "EventsFields_totalImpression_Read",
	permission: boolean
};
	["EventsFields_totalImpression_Update"]: {
	__typename: "EventsFields_totalImpression_Update",
	permission: boolean
};
	["EventsFields_totalImpression_Delete"]: {
	__typename: "EventsFields_totalImpression_Delete",
	permission: boolean
};
	["EventsFields_internalClick"]: {
	__typename: "EventsFields_internalClick",
	create?: GraphQLTypes["EventsFields_internalClick_Create"] | undefined,
	read?: GraphQLTypes["EventsFields_internalClick_Read"] | undefined,
	update?: GraphQLTypes["EventsFields_internalClick_Update"] | undefined,
	delete?: GraphQLTypes["EventsFields_internalClick_Delete"] | undefined
};
	["EventsFields_internalClick_Create"]: {
	__typename: "EventsFields_internalClick_Create",
	permission: boolean
};
	["EventsFields_internalClick_Read"]: {
	__typename: "EventsFields_internalClick_Read",
	permission: boolean
};
	["EventsFields_internalClick_Update"]: {
	__typename: "EventsFields_internalClick_Update",
	permission: boolean
};
	["EventsFields_internalClick_Delete"]: {
	__typename: "EventsFields_internalClick_Delete",
	permission: boolean
};
	["EventsFields_outboundClick"]: {
	__typename: "EventsFields_outboundClick",
	create?: GraphQLTypes["EventsFields_outboundClick_Create"] | undefined,
	read?: GraphQLTypes["EventsFields_outboundClick_Read"] | undefined,
	update?: GraphQLTypes["EventsFields_outboundClick_Update"] | undefined,
	delete?: GraphQLTypes["EventsFields_outboundClick_Delete"] | undefined
};
	["EventsFields_outboundClick_Create"]: {
	__typename: "EventsFields_outboundClick_Create",
	permission: boolean
};
	["EventsFields_outboundClick_Read"]: {
	__typename: "EventsFields_outboundClick_Read",
	permission: boolean
};
	["EventsFields_outboundClick_Update"]: {
	__typename: "EventsFields_outboundClick_Update",
	permission: boolean
};
	["EventsFields_outboundClick_Delete"]: {
	__typename: "EventsFields_outboundClick_Delete",
	permission: boolean
};
	["EventsFields_externalClick"]: {
	__typename: "EventsFields_externalClick",
	create?: GraphQLTypes["EventsFields_externalClick_Create"] | undefined,
	read?: GraphQLTypes["EventsFields_externalClick_Read"] | undefined,
	update?: GraphQLTypes["EventsFields_externalClick_Update"] | undefined,
	delete?: GraphQLTypes["EventsFields_externalClick_Delete"] | undefined
};
	["EventsFields_externalClick_Create"]: {
	__typename: "EventsFields_externalClick_Create",
	permission: boolean
};
	["EventsFields_externalClick_Read"]: {
	__typename: "EventsFields_externalClick_Read",
	permission: boolean
};
	["EventsFields_externalClick_Update"]: {
	__typename: "EventsFields_externalClick_Update",
	permission: boolean
};
	["EventsFields_externalClick_Delete"]: {
	__typename: "EventsFields_externalClick_Delete",
	permission: boolean
};
	["EventsFields__viewsCountByMonth"]: {
	__typename: "EventsFields__viewsCountByMonth",
	create?: GraphQLTypes["EventsFields__viewsCountByMonth_Create"] | undefined,
	read?: GraphQLTypes["EventsFields__viewsCountByMonth_Read"] | undefined,
	update?: GraphQLTypes["EventsFields__viewsCountByMonth_Update"] | undefined,
	delete?: GraphQLTypes["EventsFields__viewsCountByMonth_Delete"] | undefined
};
	["EventsFields__viewsCountByMonth_Create"]: {
	__typename: "EventsFields__viewsCountByMonth_Create",
	permission: boolean
};
	["EventsFields__viewsCountByMonth_Read"]: {
	__typename: "EventsFields__viewsCountByMonth_Read",
	permission: boolean
};
	["EventsFields__viewsCountByMonth_Update"]: {
	__typename: "EventsFields__viewsCountByMonth_Update",
	permission: boolean
};
	["EventsFields__viewsCountByMonth_Delete"]: {
	__typename: "EventsFields__viewsCountByMonth_Delete",
	permission: boolean
};
	["EventsFields_products"]: {
	__typename: "EventsFields_products",
	create?: GraphQLTypes["EventsFields_products_Create"] | undefined,
	read?: GraphQLTypes["EventsFields_products_Read"] | undefined,
	update?: GraphQLTypes["EventsFields_products_Update"] | undefined,
	delete?: GraphQLTypes["EventsFields_products_Delete"] | undefined,
	fields?: GraphQLTypes["EventsFields_products_Fields"] | undefined
};
	["EventsFields_products_Create"]: {
	__typename: "EventsFields_products_Create",
	permission: boolean
};
	["EventsFields_products_Read"]: {
	__typename: "EventsFields_products_Read",
	permission: boolean
};
	["EventsFields_products_Update"]: {
	__typename: "EventsFields_products_Update",
	permission: boolean
};
	["EventsFields_products_Delete"]: {
	__typename: "EventsFields_products_Delete",
	permission: boolean
};
	["EventsFields_products_Fields"]: {
	__typename: "EventsFields_products_Fields",
	startDateTime?: GraphQLTypes["EventsFields_products_startDateTime"] | undefined,
	endDateTime?: GraphQLTypes["EventsFields_products_endDateTime"] | undefined,
	fullDay?: GraphQLTypes["EventsFields_products_fullDay"] | undefined,
	linkedProduct?: GraphQLTypes["EventsFields_products_linkedProduct"] | undefined,
	id?: GraphQLTypes["EventsFields_products_id"] | undefined
};
	["EventsFields_products_startDateTime"]: {
	__typename: "EventsFields_products_startDateTime",
	create?: GraphQLTypes["EventsFields_products_startDateTime_Create"] | undefined,
	read?: GraphQLTypes["EventsFields_products_startDateTime_Read"] | undefined,
	update?: GraphQLTypes["EventsFields_products_startDateTime_Update"] | undefined,
	delete?: GraphQLTypes["EventsFields_products_startDateTime_Delete"] | undefined
};
	["EventsFields_products_startDateTime_Create"]: {
	__typename: "EventsFields_products_startDateTime_Create",
	permission: boolean
};
	["EventsFields_products_startDateTime_Read"]: {
	__typename: "EventsFields_products_startDateTime_Read",
	permission: boolean
};
	["EventsFields_products_startDateTime_Update"]: {
	__typename: "EventsFields_products_startDateTime_Update",
	permission: boolean
};
	["EventsFields_products_startDateTime_Delete"]: {
	__typename: "EventsFields_products_startDateTime_Delete",
	permission: boolean
};
	["EventsFields_products_endDateTime"]: {
	__typename: "EventsFields_products_endDateTime",
	create?: GraphQLTypes["EventsFields_products_endDateTime_Create"] | undefined,
	read?: GraphQLTypes["EventsFields_products_endDateTime_Read"] | undefined,
	update?: GraphQLTypes["EventsFields_products_endDateTime_Update"] | undefined,
	delete?: GraphQLTypes["EventsFields_products_endDateTime_Delete"] | undefined
};
	["EventsFields_products_endDateTime_Create"]: {
	__typename: "EventsFields_products_endDateTime_Create",
	permission: boolean
};
	["EventsFields_products_endDateTime_Read"]: {
	__typename: "EventsFields_products_endDateTime_Read",
	permission: boolean
};
	["EventsFields_products_endDateTime_Update"]: {
	__typename: "EventsFields_products_endDateTime_Update",
	permission: boolean
};
	["EventsFields_products_endDateTime_Delete"]: {
	__typename: "EventsFields_products_endDateTime_Delete",
	permission: boolean
};
	["EventsFields_products_fullDay"]: {
	__typename: "EventsFields_products_fullDay",
	create?: GraphQLTypes["EventsFields_products_fullDay_Create"] | undefined,
	read?: GraphQLTypes["EventsFields_products_fullDay_Read"] | undefined,
	update?: GraphQLTypes["EventsFields_products_fullDay_Update"] | undefined,
	delete?: GraphQLTypes["EventsFields_products_fullDay_Delete"] | undefined
};
	["EventsFields_products_fullDay_Create"]: {
	__typename: "EventsFields_products_fullDay_Create",
	permission: boolean
};
	["EventsFields_products_fullDay_Read"]: {
	__typename: "EventsFields_products_fullDay_Read",
	permission: boolean
};
	["EventsFields_products_fullDay_Update"]: {
	__typename: "EventsFields_products_fullDay_Update",
	permission: boolean
};
	["EventsFields_products_fullDay_Delete"]: {
	__typename: "EventsFields_products_fullDay_Delete",
	permission: boolean
};
	["EventsFields_products_linkedProduct"]: {
	__typename: "EventsFields_products_linkedProduct",
	create?: GraphQLTypes["EventsFields_products_linkedProduct_Create"] | undefined,
	read?: GraphQLTypes["EventsFields_products_linkedProduct_Read"] | undefined,
	update?: GraphQLTypes["EventsFields_products_linkedProduct_Update"] | undefined,
	delete?: GraphQLTypes["EventsFields_products_linkedProduct_Delete"] | undefined
};
	["EventsFields_products_linkedProduct_Create"]: {
	__typename: "EventsFields_products_linkedProduct_Create",
	permission: boolean
};
	["EventsFields_products_linkedProduct_Read"]: {
	__typename: "EventsFields_products_linkedProduct_Read",
	permission: boolean
};
	["EventsFields_products_linkedProduct_Update"]: {
	__typename: "EventsFields_products_linkedProduct_Update",
	permission: boolean
};
	["EventsFields_products_linkedProduct_Delete"]: {
	__typename: "EventsFields_products_linkedProduct_Delete",
	permission: boolean
};
	["EventsFields_products_id"]: {
	__typename: "EventsFields_products_id",
	create?: GraphQLTypes["EventsFields_products_id_Create"] | undefined,
	read?: GraphQLTypes["EventsFields_products_id_Read"] | undefined,
	update?: GraphQLTypes["EventsFields_products_id_Update"] | undefined,
	delete?: GraphQLTypes["EventsFields_products_id_Delete"] | undefined
};
	["EventsFields_products_id_Create"]: {
	__typename: "EventsFields_products_id_Create",
	permission: boolean
};
	["EventsFields_products_id_Read"]: {
	__typename: "EventsFields_products_id_Read",
	permission: boolean
};
	["EventsFields_products_id_Update"]: {
	__typename: "EventsFields_products_id_Update",
	permission: boolean
};
	["EventsFields_products_id_Delete"]: {
	__typename: "EventsFields_products_id_Delete",
	permission: boolean
};
	["EventsFields_legacyGallery"]: {
	__typename: "EventsFields_legacyGallery",
	create?: GraphQLTypes["EventsFields_legacyGallery_Create"] | undefined,
	read?: GraphQLTypes["EventsFields_legacyGallery_Read"] | undefined,
	update?: GraphQLTypes["EventsFields_legacyGallery_Update"] | undefined,
	delete?: GraphQLTypes["EventsFields_legacyGallery_Delete"] | undefined
};
	["EventsFields_legacyGallery_Create"]: {
	__typename: "EventsFields_legacyGallery_Create",
	permission: boolean
};
	["EventsFields_legacyGallery_Read"]: {
	__typename: "EventsFields_legacyGallery_Read",
	permission: boolean
};
	["EventsFields_legacyGallery_Update"]: {
	__typename: "EventsFields_legacyGallery_Update",
	permission: boolean
};
	["EventsFields_legacyGallery_Delete"]: {
	__typename: "EventsFields_legacyGallery_Delete",
	permission: boolean
};
	["EventsFields_earliestStartDate"]: {
	__typename: "EventsFields_earliestStartDate",
	create?: GraphQLTypes["EventsFields_earliestStartDate_Create"] | undefined,
	read?: GraphQLTypes["EventsFields_earliestStartDate_Read"] | undefined,
	update?: GraphQLTypes["EventsFields_earliestStartDate_Update"] | undefined,
	delete?: GraphQLTypes["EventsFields_earliestStartDate_Delete"] | undefined
};
	["EventsFields_earliestStartDate_Create"]: {
	__typename: "EventsFields_earliestStartDate_Create",
	permission: boolean
};
	["EventsFields_earliestStartDate_Read"]: {
	__typename: "EventsFields_earliestStartDate_Read",
	permission: boolean
};
	["EventsFields_earliestStartDate_Update"]: {
	__typename: "EventsFields_earliestStartDate_Update",
	permission: boolean
};
	["EventsFields_earliestStartDate_Delete"]: {
	__typename: "EventsFields_earliestStartDate_Delete",
	permission: boolean
};
	["EventsFields_latestEndDate"]: {
	__typename: "EventsFields_latestEndDate",
	create?: GraphQLTypes["EventsFields_latestEndDate_Create"] | undefined,
	read?: GraphQLTypes["EventsFields_latestEndDate_Read"] | undefined,
	update?: GraphQLTypes["EventsFields_latestEndDate_Update"] | undefined,
	delete?: GraphQLTypes["EventsFields_latestEndDate_Delete"] | undefined
};
	["EventsFields_latestEndDate_Create"]: {
	__typename: "EventsFields_latestEndDate_Create",
	permission: boolean
};
	["EventsFields_latestEndDate_Read"]: {
	__typename: "EventsFields_latestEndDate_Read",
	permission: boolean
};
	["EventsFields_latestEndDate_Update"]: {
	__typename: "EventsFields_latestEndDate_Update",
	permission: boolean
};
	["EventsFields_latestEndDate_Delete"]: {
	__typename: "EventsFields_latestEndDate_Delete",
	permission: boolean
};
	["EventsFields_latestStartDateTime"]: {
	__typename: "EventsFields_latestStartDateTime",
	create?: GraphQLTypes["EventsFields_latestStartDateTime_Create"] | undefined,
	read?: GraphQLTypes["EventsFields_latestStartDateTime_Read"] | undefined,
	update?: GraphQLTypes["EventsFields_latestStartDateTime_Update"] | undefined,
	delete?: GraphQLTypes["EventsFields_latestStartDateTime_Delete"] | undefined
};
	["EventsFields_latestStartDateTime_Create"]: {
	__typename: "EventsFields_latestStartDateTime_Create",
	permission: boolean
};
	["EventsFields_latestStartDateTime_Read"]: {
	__typename: "EventsFields_latestStartDateTime_Read",
	permission: boolean
};
	["EventsFields_latestStartDateTime_Update"]: {
	__typename: "EventsFields_latestStartDateTime_Update",
	permission: boolean
};
	["EventsFields_latestStartDateTime_Delete"]: {
	__typename: "EventsFields_latestStartDateTime_Delete",
	permission: boolean
};
	["EventsFields_hasTicketing"]: {
	__typename: "EventsFields_hasTicketing",
	create?: GraphQLTypes["EventsFields_hasTicketing_Create"] | undefined,
	read?: GraphQLTypes["EventsFields_hasTicketing_Read"] | undefined,
	update?: GraphQLTypes["EventsFields_hasTicketing_Update"] | undefined,
	delete?: GraphQLTypes["EventsFields_hasTicketing_Delete"] | undefined
};
	["EventsFields_hasTicketing_Create"]: {
	__typename: "EventsFields_hasTicketing_Create",
	permission: boolean
};
	["EventsFields_hasTicketing_Read"]: {
	__typename: "EventsFields_hasTicketing_Read",
	permission: boolean
};
	["EventsFields_hasTicketing_Update"]: {
	__typename: "EventsFields_hasTicketing_Update",
	permission: boolean
};
	["EventsFields_hasTicketing_Delete"]: {
	__typename: "EventsFields_hasTicketing_Delete",
	permission: boolean
};
	["EventsFields_hasProducts"]: {
	__typename: "EventsFields_hasProducts",
	create?: GraphQLTypes["EventsFields_hasProducts_Create"] | undefined,
	read?: GraphQLTypes["EventsFields_hasProducts_Read"] | undefined,
	update?: GraphQLTypes["EventsFields_hasProducts_Update"] | undefined,
	delete?: GraphQLTypes["EventsFields_hasProducts_Delete"] | undefined
};
	["EventsFields_hasProducts_Create"]: {
	__typename: "EventsFields_hasProducts_Create",
	permission: boolean
};
	["EventsFields_hasProducts_Read"]: {
	__typename: "EventsFields_hasProducts_Read",
	permission: boolean
};
	["EventsFields_hasProducts_Update"]: {
	__typename: "EventsFields_hasProducts_Update",
	permission: boolean
};
	["EventsFields_hasProducts_Delete"]: {
	__typename: "EventsFields_hasProducts_Delete",
	permission: boolean
};
	["EventsFields_updatedAt"]: {
	__typename: "EventsFields_updatedAt",
	create?: GraphQLTypes["EventsFields_updatedAt_Create"] | undefined,
	read?: GraphQLTypes["EventsFields_updatedAt_Read"] | undefined,
	update?: GraphQLTypes["EventsFields_updatedAt_Update"] | undefined,
	delete?: GraphQLTypes["EventsFields_updatedAt_Delete"] | undefined
};
	["EventsFields_updatedAt_Create"]: {
	__typename: "EventsFields_updatedAt_Create",
	permission: boolean
};
	["EventsFields_updatedAt_Read"]: {
	__typename: "EventsFields_updatedAt_Read",
	permission: boolean
};
	["EventsFields_updatedAt_Update"]: {
	__typename: "EventsFields_updatedAt_Update",
	permission: boolean
};
	["EventsFields_updatedAt_Delete"]: {
	__typename: "EventsFields_updatedAt_Delete",
	permission: boolean
};
	["EventsFields_createdAt"]: {
	__typename: "EventsFields_createdAt",
	create?: GraphQLTypes["EventsFields_createdAt_Create"] | undefined,
	read?: GraphQLTypes["EventsFields_createdAt_Read"] | undefined,
	update?: GraphQLTypes["EventsFields_createdAt_Update"] | undefined,
	delete?: GraphQLTypes["EventsFields_createdAt_Delete"] | undefined
};
	["EventsFields_createdAt_Create"]: {
	__typename: "EventsFields_createdAt_Create",
	permission: boolean
};
	["EventsFields_createdAt_Read"]: {
	__typename: "EventsFields_createdAt_Read",
	permission: boolean
};
	["EventsFields_createdAt_Update"]: {
	__typename: "EventsFields_createdAt_Update",
	permission: boolean
};
	["EventsFields_createdAt_Delete"]: {
	__typename: "EventsFields_createdAt_Delete",
	permission: boolean
};
	["EventsCreateAccess"]: {
	__typename: "EventsCreateAccess",
	permission: boolean,
	where?: GraphQLTypes["JSONObject"] | undefined
};
	["EventsReadAccess"]: {
	__typename: "EventsReadAccess",
	permission: boolean,
	where?: GraphQLTypes["JSONObject"] | undefined
};
	["EventsUpdateAccess"]: {
	__typename: "EventsUpdateAccess",
	permission: boolean,
	where?: GraphQLTypes["JSONObject"] | undefined
};
	["EventsDeleteAccess"]: {
	__typename: "EventsDeleteAccess",
	permission: boolean,
	where?: GraphQLTypes["JSONObject"] | undefined
};
	["locationsAccess"]: {
	__typename: "locationsAccess",
	fields?: GraphQLTypes["LocationsFields"] | undefined,
	create?: GraphQLTypes["LocationsCreateAccess"] | undefined,
	read?: GraphQLTypes["LocationsReadAccess"] | undefined,
	update?: GraphQLTypes["LocationsUpdateAccess"] | undefined,
	delete?: GraphQLTypes["LocationsDeleteAccess"] | undefined
};
	["LocationsFields"]: {
	__typename: "LocationsFields",
	region?: GraphQLTypes["LocationsFields_region"] | undefined,
	creator?: GraphQLTypes["LocationsFields_creator"] | undefined,
	permalink?: GraphQLTypes["LocationsFields_permalink"] | undefined,
	status?: GraphQLTypes["LocationsFields_status"] | undefined,
	level?: GraphQLTypes["LocationsFields_level"] | undefined,
	parent?: GraphQLTypes["LocationsFields_parent"] | undefined,
	name?: GraphQLTypes["LocationsFields_name"] | undefined,
	thumbnail?: GraphQLTypes["LocationsFields_thumbnail"] | undefined,
	banner?: GraphQLTypes["LocationsFields_banner"] | undefined,
	type?: GraphQLTypes["LocationsFields_type"] | undefined,
	category?: GraphQLTypes["LocationsFields_category"] | undefined,
	district?: GraphQLTypes["LocationsFields_district"] | undefined,
	coordinate?: GraphQLTypes["LocationsFields_coordinate"] | undefined,
	linkAddress?: GraphQLTypes["LocationsFields_linkAddress"] | undefined,
	address?: GraphQLTypes["LocationsFields_address"] | undefined,
	Details?: GraphQLTypes["LocationsFields_Details"] | undefined,
	content?: GraphQLTypes["LocationsFields_content"] | undefined,
	legacyContent?: GraphQLTypes["LocationsFields_legacyContent"] | undefined,
	showLegacyContent?: GraphQLTypes["LocationsFields_showLegacyContent"] | undefined,
	criteria?: GraphQLTypes["LocationsFields_criteria"] | undefined,
	sections?: GraphQLTypes["LocationsFields_sections"] | undefined,
	_title?: GraphQLTypes["LocationsFields__title"] | undefined,
	legacyGallery?: GraphQLTypes["LocationsFields_legacyGallery"] | undefined,
	updatedAt?: GraphQLTypes["LocationsFields_updatedAt"] | undefined,
	createdAt?: GraphQLTypes["LocationsFields_createdAt"] | undefined
};
	["LocationsFields_region"]: {
	__typename: "LocationsFields_region",
	create?: GraphQLTypes["LocationsFields_region_Create"] | undefined,
	read?: GraphQLTypes["LocationsFields_region_Read"] | undefined,
	update?: GraphQLTypes["LocationsFields_region_Update"] | undefined,
	delete?: GraphQLTypes["LocationsFields_region_Delete"] | undefined
};
	["LocationsFields_region_Create"]: {
	__typename: "LocationsFields_region_Create",
	permission: boolean
};
	["LocationsFields_region_Read"]: {
	__typename: "LocationsFields_region_Read",
	permission: boolean
};
	["LocationsFields_region_Update"]: {
	__typename: "LocationsFields_region_Update",
	permission: boolean
};
	["LocationsFields_region_Delete"]: {
	__typename: "LocationsFields_region_Delete",
	permission: boolean
};
	["LocationsFields_creator"]: {
	__typename: "LocationsFields_creator",
	create?: GraphQLTypes["LocationsFields_creator_Create"] | undefined,
	read?: GraphQLTypes["LocationsFields_creator_Read"] | undefined,
	update?: GraphQLTypes["LocationsFields_creator_Update"] | undefined,
	delete?: GraphQLTypes["LocationsFields_creator_Delete"] | undefined
};
	["LocationsFields_creator_Create"]: {
	__typename: "LocationsFields_creator_Create",
	permission: boolean
};
	["LocationsFields_creator_Read"]: {
	__typename: "LocationsFields_creator_Read",
	permission: boolean
};
	["LocationsFields_creator_Update"]: {
	__typename: "LocationsFields_creator_Update",
	permission: boolean
};
	["LocationsFields_creator_Delete"]: {
	__typename: "LocationsFields_creator_Delete",
	permission: boolean
};
	["LocationsFields_permalink"]: {
	__typename: "LocationsFields_permalink",
	create?: GraphQLTypes["LocationsFields_permalink_Create"] | undefined,
	read?: GraphQLTypes["LocationsFields_permalink_Read"] | undefined,
	update?: GraphQLTypes["LocationsFields_permalink_Update"] | undefined,
	delete?: GraphQLTypes["LocationsFields_permalink_Delete"] | undefined
};
	["LocationsFields_permalink_Create"]: {
	__typename: "LocationsFields_permalink_Create",
	permission: boolean
};
	["LocationsFields_permalink_Read"]: {
	__typename: "LocationsFields_permalink_Read",
	permission: boolean
};
	["LocationsFields_permalink_Update"]: {
	__typename: "LocationsFields_permalink_Update",
	permission: boolean
};
	["LocationsFields_permalink_Delete"]: {
	__typename: "LocationsFields_permalink_Delete",
	permission: boolean
};
	["LocationsFields_status"]: {
	__typename: "LocationsFields_status",
	create?: GraphQLTypes["LocationsFields_status_Create"] | undefined,
	read?: GraphQLTypes["LocationsFields_status_Read"] | undefined,
	update?: GraphQLTypes["LocationsFields_status_Update"] | undefined,
	delete?: GraphQLTypes["LocationsFields_status_Delete"] | undefined
};
	["LocationsFields_status_Create"]: {
	__typename: "LocationsFields_status_Create",
	permission: boolean
};
	["LocationsFields_status_Read"]: {
	__typename: "LocationsFields_status_Read",
	permission: boolean
};
	["LocationsFields_status_Update"]: {
	__typename: "LocationsFields_status_Update",
	permission: boolean
};
	["LocationsFields_status_Delete"]: {
	__typename: "LocationsFields_status_Delete",
	permission: boolean
};
	["LocationsFields_level"]: {
	__typename: "LocationsFields_level",
	create?: GraphQLTypes["LocationsFields_level_Create"] | undefined,
	read?: GraphQLTypes["LocationsFields_level_Read"] | undefined,
	update?: GraphQLTypes["LocationsFields_level_Update"] | undefined,
	delete?: GraphQLTypes["LocationsFields_level_Delete"] | undefined
};
	["LocationsFields_level_Create"]: {
	__typename: "LocationsFields_level_Create",
	permission: boolean
};
	["LocationsFields_level_Read"]: {
	__typename: "LocationsFields_level_Read",
	permission: boolean
};
	["LocationsFields_level_Update"]: {
	__typename: "LocationsFields_level_Update",
	permission: boolean
};
	["LocationsFields_level_Delete"]: {
	__typename: "LocationsFields_level_Delete",
	permission: boolean
};
	["LocationsFields_parent"]: {
	__typename: "LocationsFields_parent",
	create?: GraphQLTypes["LocationsFields_parent_Create"] | undefined,
	read?: GraphQLTypes["LocationsFields_parent_Read"] | undefined,
	update?: GraphQLTypes["LocationsFields_parent_Update"] | undefined,
	delete?: GraphQLTypes["LocationsFields_parent_Delete"] | undefined
};
	["LocationsFields_parent_Create"]: {
	__typename: "LocationsFields_parent_Create",
	permission: boolean
};
	["LocationsFields_parent_Read"]: {
	__typename: "LocationsFields_parent_Read",
	permission: boolean
};
	["LocationsFields_parent_Update"]: {
	__typename: "LocationsFields_parent_Update",
	permission: boolean
};
	["LocationsFields_parent_Delete"]: {
	__typename: "LocationsFields_parent_Delete",
	permission: boolean
};
	["LocationsFields_name"]: {
	__typename: "LocationsFields_name",
	create?: GraphQLTypes["LocationsFields_name_Create"] | undefined,
	read?: GraphQLTypes["LocationsFields_name_Read"] | undefined,
	update?: GraphQLTypes["LocationsFields_name_Update"] | undefined,
	delete?: GraphQLTypes["LocationsFields_name_Delete"] | undefined
};
	["LocationsFields_name_Create"]: {
	__typename: "LocationsFields_name_Create",
	permission: boolean
};
	["LocationsFields_name_Read"]: {
	__typename: "LocationsFields_name_Read",
	permission: boolean
};
	["LocationsFields_name_Update"]: {
	__typename: "LocationsFields_name_Update",
	permission: boolean
};
	["LocationsFields_name_Delete"]: {
	__typename: "LocationsFields_name_Delete",
	permission: boolean
};
	["LocationsFields_thumbnail"]: {
	__typename: "LocationsFields_thumbnail",
	create?: GraphQLTypes["LocationsFields_thumbnail_Create"] | undefined,
	read?: GraphQLTypes["LocationsFields_thumbnail_Read"] | undefined,
	update?: GraphQLTypes["LocationsFields_thumbnail_Update"] | undefined,
	delete?: GraphQLTypes["LocationsFields_thumbnail_Delete"] | undefined
};
	["LocationsFields_thumbnail_Create"]: {
	__typename: "LocationsFields_thumbnail_Create",
	permission: boolean
};
	["LocationsFields_thumbnail_Read"]: {
	__typename: "LocationsFields_thumbnail_Read",
	permission: boolean
};
	["LocationsFields_thumbnail_Update"]: {
	__typename: "LocationsFields_thumbnail_Update",
	permission: boolean
};
	["LocationsFields_thumbnail_Delete"]: {
	__typename: "LocationsFields_thumbnail_Delete",
	permission: boolean
};
	["LocationsFields_banner"]: {
	__typename: "LocationsFields_banner",
	create?: GraphQLTypes["LocationsFields_banner_Create"] | undefined,
	read?: GraphQLTypes["LocationsFields_banner_Read"] | undefined,
	update?: GraphQLTypes["LocationsFields_banner_Update"] | undefined,
	delete?: GraphQLTypes["LocationsFields_banner_Delete"] | undefined
};
	["LocationsFields_banner_Create"]: {
	__typename: "LocationsFields_banner_Create",
	permission: boolean
};
	["LocationsFields_banner_Read"]: {
	__typename: "LocationsFields_banner_Read",
	permission: boolean
};
	["LocationsFields_banner_Update"]: {
	__typename: "LocationsFields_banner_Update",
	permission: boolean
};
	["LocationsFields_banner_Delete"]: {
	__typename: "LocationsFields_banner_Delete",
	permission: boolean
};
	["LocationsFields_type"]: {
	__typename: "LocationsFields_type",
	create?: GraphQLTypes["LocationsFields_type_Create"] | undefined,
	read?: GraphQLTypes["LocationsFields_type_Read"] | undefined,
	update?: GraphQLTypes["LocationsFields_type_Update"] | undefined,
	delete?: GraphQLTypes["LocationsFields_type_Delete"] | undefined
};
	["LocationsFields_type_Create"]: {
	__typename: "LocationsFields_type_Create",
	permission: boolean
};
	["LocationsFields_type_Read"]: {
	__typename: "LocationsFields_type_Read",
	permission: boolean
};
	["LocationsFields_type_Update"]: {
	__typename: "LocationsFields_type_Update",
	permission: boolean
};
	["LocationsFields_type_Delete"]: {
	__typename: "LocationsFields_type_Delete",
	permission: boolean
};
	["LocationsFields_category"]: {
	__typename: "LocationsFields_category",
	create?: GraphQLTypes["LocationsFields_category_Create"] | undefined,
	read?: GraphQLTypes["LocationsFields_category_Read"] | undefined,
	update?: GraphQLTypes["LocationsFields_category_Update"] | undefined,
	delete?: GraphQLTypes["LocationsFields_category_Delete"] | undefined
};
	["LocationsFields_category_Create"]: {
	__typename: "LocationsFields_category_Create",
	permission: boolean
};
	["LocationsFields_category_Read"]: {
	__typename: "LocationsFields_category_Read",
	permission: boolean
};
	["LocationsFields_category_Update"]: {
	__typename: "LocationsFields_category_Update",
	permission: boolean
};
	["LocationsFields_category_Delete"]: {
	__typename: "LocationsFields_category_Delete",
	permission: boolean
};
	["LocationsFields_district"]: {
	__typename: "LocationsFields_district",
	create?: GraphQLTypes["LocationsFields_district_Create"] | undefined,
	read?: GraphQLTypes["LocationsFields_district_Read"] | undefined,
	update?: GraphQLTypes["LocationsFields_district_Update"] | undefined,
	delete?: GraphQLTypes["LocationsFields_district_Delete"] | undefined
};
	["LocationsFields_district_Create"]: {
	__typename: "LocationsFields_district_Create",
	permission: boolean
};
	["LocationsFields_district_Read"]: {
	__typename: "LocationsFields_district_Read",
	permission: boolean
};
	["LocationsFields_district_Update"]: {
	__typename: "LocationsFields_district_Update",
	permission: boolean
};
	["LocationsFields_district_Delete"]: {
	__typename: "LocationsFields_district_Delete",
	permission: boolean
};
	["LocationsFields_coordinate"]: {
	__typename: "LocationsFields_coordinate",
	create?: GraphQLTypes["LocationsFields_coordinate_Create"] | undefined,
	read?: GraphQLTypes["LocationsFields_coordinate_Read"] | undefined,
	update?: GraphQLTypes["LocationsFields_coordinate_Update"] | undefined,
	delete?: GraphQLTypes["LocationsFields_coordinate_Delete"] | undefined
};
	["LocationsFields_coordinate_Create"]: {
	__typename: "LocationsFields_coordinate_Create",
	permission: boolean
};
	["LocationsFields_coordinate_Read"]: {
	__typename: "LocationsFields_coordinate_Read",
	permission: boolean
};
	["LocationsFields_coordinate_Update"]: {
	__typename: "LocationsFields_coordinate_Update",
	permission: boolean
};
	["LocationsFields_coordinate_Delete"]: {
	__typename: "LocationsFields_coordinate_Delete",
	permission: boolean
};
	["LocationsFields_linkAddress"]: {
	__typename: "LocationsFields_linkAddress",
	create?: GraphQLTypes["LocationsFields_linkAddress_Create"] | undefined,
	read?: GraphQLTypes["LocationsFields_linkAddress_Read"] | undefined,
	update?: GraphQLTypes["LocationsFields_linkAddress_Update"] | undefined,
	delete?: GraphQLTypes["LocationsFields_linkAddress_Delete"] | undefined
};
	["LocationsFields_linkAddress_Create"]: {
	__typename: "LocationsFields_linkAddress_Create",
	permission: boolean
};
	["LocationsFields_linkAddress_Read"]: {
	__typename: "LocationsFields_linkAddress_Read",
	permission: boolean
};
	["LocationsFields_linkAddress_Update"]: {
	__typename: "LocationsFields_linkAddress_Update",
	permission: boolean
};
	["LocationsFields_linkAddress_Delete"]: {
	__typename: "LocationsFields_linkAddress_Delete",
	permission: boolean
};
	["LocationsFields_address"]: {
	__typename: "LocationsFields_address",
	create?: GraphQLTypes["LocationsFields_address_Create"] | undefined,
	read?: GraphQLTypes["LocationsFields_address_Read"] | undefined,
	update?: GraphQLTypes["LocationsFields_address_Update"] | undefined,
	delete?: GraphQLTypes["LocationsFields_address_Delete"] | undefined
};
	["LocationsFields_address_Create"]: {
	__typename: "LocationsFields_address_Create",
	permission: boolean
};
	["LocationsFields_address_Read"]: {
	__typename: "LocationsFields_address_Read",
	permission: boolean
};
	["LocationsFields_address_Update"]: {
	__typename: "LocationsFields_address_Update",
	permission: boolean
};
	["LocationsFields_address_Delete"]: {
	__typename: "LocationsFields_address_Delete",
	permission: boolean
};
	["LocationsFields_Details"]: {
	__typename: "LocationsFields_Details",
	create?: GraphQLTypes["LocationsFields_Details_Create"] | undefined,
	read?: GraphQLTypes["LocationsFields_Details_Read"] | undefined,
	update?: GraphQLTypes["LocationsFields_Details_Update"] | undefined,
	delete?: GraphQLTypes["LocationsFields_Details_Delete"] | undefined,
	fields?: GraphQLTypes["LocationsFields_Details_Fields"] | undefined
};
	["LocationsFields_Details_Create"]: {
	__typename: "LocationsFields_Details_Create",
	permission: boolean
};
	["LocationsFields_Details_Read"]: {
	__typename: "LocationsFields_Details_Read",
	permission: boolean
};
	["LocationsFields_Details_Update"]: {
	__typename: "LocationsFields_Details_Update",
	permission: boolean
};
	["LocationsFields_Details_Delete"]: {
	__typename: "LocationsFields_Details_Delete",
	permission: boolean
};
	["LocationsFields_Details_Fields"]: {
	__typename: "LocationsFields_Details_Fields",
	code?: GraphQLTypes["LocationsFields_Details_code"] | undefined,
	phone?: GraphQLTypes["LocationsFields_Details_phone"] | undefined,
	page?: GraphQLTypes["LocationsFields_Details_page"] | undefined,
	email?: GraphQLTypes["LocationsFields_Details_email"] | undefined
};
	["LocationsFields_Details_code"]: {
	__typename: "LocationsFields_Details_code",
	create?: GraphQLTypes["LocationsFields_Details_code_Create"] | undefined,
	read?: GraphQLTypes["LocationsFields_Details_code_Read"] | undefined,
	update?: GraphQLTypes["LocationsFields_Details_code_Update"] | undefined,
	delete?: GraphQLTypes["LocationsFields_Details_code_Delete"] | undefined
};
	["LocationsFields_Details_code_Create"]: {
	__typename: "LocationsFields_Details_code_Create",
	permission: boolean
};
	["LocationsFields_Details_code_Read"]: {
	__typename: "LocationsFields_Details_code_Read",
	permission: boolean
};
	["LocationsFields_Details_code_Update"]: {
	__typename: "LocationsFields_Details_code_Update",
	permission: boolean
};
	["LocationsFields_Details_code_Delete"]: {
	__typename: "LocationsFields_Details_code_Delete",
	permission: boolean
};
	["LocationsFields_Details_phone"]: {
	__typename: "LocationsFields_Details_phone",
	create?: GraphQLTypes["LocationsFields_Details_phone_Create"] | undefined,
	read?: GraphQLTypes["LocationsFields_Details_phone_Read"] | undefined,
	update?: GraphQLTypes["LocationsFields_Details_phone_Update"] | undefined,
	delete?: GraphQLTypes["LocationsFields_Details_phone_Delete"] | undefined
};
	["LocationsFields_Details_phone_Create"]: {
	__typename: "LocationsFields_Details_phone_Create",
	permission: boolean
};
	["LocationsFields_Details_phone_Read"]: {
	__typename: "LocationsFields_Details_phone_Read",
	permission: boolean
};
	["LocationsFields_Details_phone_Update"]: {
	__typename: "LocationsFields_Details_phone_Update",
	permission: boolean
};
	["LocationsFields_Details_phone_Delete"]: {
	__typename: "LocationsFields_Details_phone_Delete",
	permission: boolean
};
	["LocationsFields_Details_page"]: {
	__typename: "LocationsFields_Details_page",
	create?: GraphQLTypes["LocationsFields_Details_page_Create"] | undefined,
	read?: GraphQLTypes["LocationsFields_Details_page_Read"] | undefined,
	update?: GraphQLTypes["LocationsFields_Details_page_Update"] | undefined,
	delete?: GraphQLTypes["LocationsFields_Details_page_Delete"] | undefined
};
	["LocationsFields_Details_page_Create"]: {
	__typename: "LocationsFields_Details_page_Create",
	permission: boolean
};
	["LocationsFields_Details_page_Read"]: {
	__typename: "LocationsFields_Details_page_Read",
	permission: boolean
};
	["LocationsFields_Details_page_Update"]: {
	__typename: "LocationsFields_Details_page_Update",
	permission: boolean
};
	["LocationsFields_Details_page_Delete"]: {
	__typename: "LocationsFields_Details_page_Delete",
	permission: boolean
};
	["LocationsFields_Details_email"]: {
	__typename: "LocationsFields_Details_email",
	create?: GraphQLTypes["LocationsFields_Details_email_Create"] | undefined,
	read?: GraphQLTypes["LocationsFields_Details_email_Read"] | undefined,
	update?: GraphQLTypes["LocationsFields_Details_email_Update"] | undefined,
	delete?: GraphQLTypes["LocationsFields_Details_email_Delete"] | undefined
};
	["LocationsFields_Details_email_Create"]: {
	__typename: "LocationsFields_Details_email_Create",
	permission: boolean
};
	["LocationsFields_Details_email_Read"]: {
	__typename: "LocationsFields_Details_email_Read",
	permission: boolean
};
	["LocationsFields_Details_email_Update"]: {
	__typename: "LocationsFields_Details_email_Update",
	permission: boolean
};
	["LocationsFields_Details_email_Delete"]: {
	__typename: "LocationsFields_Details_email_Delete",
	permission: boolean
};
	["LocationsFields_content"]: {
	__typename: "LocationsFields_content",
	create?: GraphQLTypes["LocationsFields_content_Create"] | undefined,
	read?: GraphQLTypes["LocationsFields_content_Read"] | undefined,
	update?: GraphQLTypes["LocationsFields_content_Update"] | undefined,
	delete?: GraphQLTypes["LocationsFields_content_Delete"] | undefined
};
	["LocationsFields_content_Create"]: {
	__typename: "LocationsFields_content_Create",
	permission: boolean
};
	["LocationsFields_content_Read"]: {
	__typename: "LocationsFields_content_Read",
	permission: boolean
};
	["LocationsFields_content_Update"]: {
	__typename: "LocationsFields_content_Update",
	permission: boolean
};
	["LocationsFields_content_Delete"]: {
	__typename: "LocationsFields_content_Delete",
	permission: boolean
};
	["LocationsFields_legacyContent"]: {
	__typename: "LocationsFields_legacyContent",
	create?: GraphQLTypes["LocationsFields_legacyContent_Create"] | undefined,
	read?: GraphQLTypes["LocationsFields_legacyContent_Read"] | undefined,
	update?: GraphQLTypes["LocationsFields_legacyContent_Update"] | undefined,
	delete?: GraphQLTypes["LocationsFields_legacyContent_Delete"] | undefined
};
	["LocationsFields_legacyContent_Create"]: {
	__typename: "LocationsFields_legacyContent_Create",
	permission: boolean
};
	["LocationsFields_legacyContent_Read"]: {
	__typename: "LocationsFields_legacyContent_Read",
	permission: boolean
};
	["LocationsFields_legacyContent_Update"]: {
	__typename: "LocationsFields_legacyContent_Update",
	permission: boolean
};
	["LocationsFields_legacyContent_Delete"]: {
	__typename: "LocationsFields_legacyContent_Delete",
	permission: boolean
};
	["LocationsFields_showLegacyContent"]: {
	__typename: "LocationsFields_showLegacyContent",
	create?: GraphQLTypes["LocationsFields_showLegacyContent_Create"] | undefined,
	read?: GraphQLTypes["LocationsFields_showLegacyContent_Read"] | undefined,
	update?: GraphQLTypes["LocationsFields_showLegacyContent_Update"] | undefined,
	delete?: GraphQLTypes["LocationsFields_showLegacyContent_Delete"] | undefined
};
	["LocationsFields_showLegacyContent_Create"]: {
	__typename: "LocationsFields_showLegacyContent_Create",
	permission: boolean
};
	["LocationsFields_showLegacyContent_Read"]: {
	__typename: "LocationsFields_showLegacyContent_Read",
	permission: boolean
};
	["LocationsFields_showLegacyContent_Update"]: {
	__typename: "LocationsFields_showLegacyContent_Update",
	permission: boolean
};
	["LocationsFields_showLegacyContent_Delete"]: {
	__typename: "LocationsFields_showLegacyContent_Delete",
	permission: boolean
};
	["LocationsFields_criteria"]: {
	__typename: "LocationsFields_criteria",
	create?: GraphQLTypes["LocationsFields_criteria_Create"] | undefined,
	read?: GraphQLTypes["LocationsFields_criteria_Read"] | undefined,
	update?: GraphQLTypes["LocationsFields_criteria_Update"] | undefined,
	delete?: GraphQLTypes["LocationsFields_criteria_Delete"] | undefined,
	fields?: GraphQLTypes["LocationsFields_criteria_Fields"] | undefined
};
	["LocationsFields_criteria_Create"]: {
	__typename: "LocationsFields_criteria_Create",
	permission: boolean
};
	["LocationsFields_criteria_Read"]: {
	__typename: "LocationsFields_criteria_Read",
	permission: boolean
};
	["LocationsFields_criteria_Update"]: {
	__typename: "LocationsFields_criteria_Update",
	permission: boolean
};
	["LocationsFields_criteria_Delete"]: {
	__typename: "LocationsFields_criteria_Delete",
	permission: boolean
};
	["LocationsFields_criteria_Fields"]: {
	__typename: "LocationsFields_criteria_Fields",
	audiences?: GraphQLTypes["LocationsFields_criteria_audiences"] | undefined,
	dates?: GraphQLTypes["LocationsFields_criteria_dates"] | undefined,
	categories?: GraphQLTypes["LocationsFields_criteria_categories"] | undefined,
	tags?: GraphQLTypes["LocationsFields_criteria_tags"] | undefined
};
	["LocationsFields_criteria_audiences"]: {
	__typename: "LocationsFields_criteria_audiences",
	create?: GraphQLTypes["LocationsFields_criteria_audiences_Create"] | undefined,
	read?: GraphQLTypes["LocationsFields_criteria_audiences_Read"] | undefined,
	update?: GraphQLTypes["LocationsFields_criteria_audiences_Update"] | undefined,
	delete?: GraphQLTypes["LocationsFields_criteria_audiences_Delete"] | undefined
};
	["LocationsFields_criteria_audiences_Create"]: {
	__typename: "LocationsFields_criteria_audiences_Create",
	permission: boolean
};
	["LocationsFields_criteria_audiences_Read"]: {
	__typename: "LocationsFields_criteria_audiences_Read",
	permission: boolean
};
	["LocationsFields_criteria_audiences_Update"]: {
	__typename: "LocationsFields_criteria_audiences_Update",
	permission: boolean
};
	["LocationsFields_criteria_audiences_Delete"]: {
	__typename: "LocationsFields_criteria_audiences_Delete",
	permission: boolean
};
	["LocationsFields_criteria_dates"]: {
	__typename: "LocationsFields_criteria_dates",
	create?: GraphQLTypes["LocationsFields_criteria_dates_Create"] | undefined,
	read?: GraphQLTypes["LocationsFields_criteria_dates_Read"] | undefined,
	update?: GraphQLTypes["LocationsFields_criteria_dates_Update"] | undefined,
	delete?: GraphQLTypes["LocationsFields_criteria_dates_Delete"] | undefined
};
	["LocationsFields_criteria_dates_Create"]: {
	__typename: "LocationsFields_criteria_dates_Create",
	permission: boolean
};
	["LocationsFields_criteria_dates_Read"]: {
	__typename: "LocationsFields_criteria_dates_Read",
	permission: boolean
};
	["LocationsFields_criteria_dates_Update"]: {
	__typename: "LocationsFields_criteria_dates_Update",
	permission: boolean
};
	["LocationsFields_criteria_dates_Delete"]: {
	__typename: "LocationsFields_criteria_dates_Delete",
	permission: boolean
};
	["LocationsFields_criteria_categories"]: {
	__typename: "LocationsFields_criteria_categories",
	create?: GraphQLTypes["LocationsFields_criteria_categories_Create"] | undefined,
	read?: GraphQLTypes["LocationsFields_criteria_categories_Read"] | undefined,
	update?: GraphQLTypes["LocationsFields_criteria_categories_Update"] | undefined,
	delete?: GraphQLTypes["LocationsFields_criteria_categories_Delete"] | undefined
};
	["LocationsFields_criteria_categories_Create"]: {
	__typename: "LocationsFields_criteria_categories_Create",
	permission: boolean
};
	["LocationsFields_criteria_categories_Read"]: {
	__typename: "LocationsFields_criteria_categories_Read",
	permission: boolean
};
	["LocationsFields_criteria_categories_Update"]: {
	__typename: "LocationsFields_criteria_categories_Update",
	permission: boolean
};
	["LocationsFields_criteria_categories_Delete"]: {
	__typename: "LocationsFields_criteria_categories_Delete",
	permission: boolean
};
	["LocationsFields_criteria_tags"]: {
	__typename: "LocationsFields_criteria_tags",
	create?: GraphQLTypes["LocationsFields_criteria_tags_Create"] | undefined,
	read?: GraphQLTypes["LocationsFields_criteria_tags_Read"] | undefined,
	update?: GraphQLTypes["LocationsFields_criteria_tags_Update"] | undefined,
	delete?: GraphQLTypes["LocationsFields_criteria_tags_Delete"] | undefined
};
	["LocationsFields_criteria_tags_Create"]: {
	__typename: "LocationsFields_criteria_tags_Create",
	permission: boolean
};
	["LocationsFields_criteria_tags_Read"]: {
	__typename: "LocationsFields_criteria_tags_Read",
	permission: boolean
};
	["LocationsFields_criteria_tags_Update"]: {
	__typename: "LocationsFields_criteria_tags_Update",
	permission: boolean
};
	["LocationsFields_criteria_tags_Delete"]: {
	__typename: "LocationsFields_criteria_tags_Delete",
	permission: boolean
};
	["LocationsFields_sections"]: {
	__typename: "LocationsFields_sections",
	create?: GraphQLTypes["LocationsFields_sections_Create"] | undefined,
	read?: GraphQLTypes["LocationsFields_sections_Read"] | undefined,
	update?: GraphQLTypes["LocationsFields_sections_Update"] | undefined,
	delete?: GraphQLTypes["LocationsFields_sections_Delete"] | undefined,
	fields?: GraphQLTypes["LocationsFields_sections_Fields"] | undefined
};
	["LocationsFields_sections_Create"]: {
	__typename: "LocationsFields_sections_Create",
	permission: boolean
};
	["LocationsFields_sections_Read"]: {
	__typename: "LocationsFields_sections_Read",
	permission: boolean
};
	["LocationsFields_sections_Update"]: {
	__typename: "LocationsFields_sections_Update",
	permission: boolean
};
	["LocationsFields_sections_Delete"]: {
	__typename: "LocationsFields_sections_Delete",
	permission: boolean
};
	["LocationsFields_sections_Fields"]: {
	__typename: "LocationsFields_sections_Fields",
	startDatetime?: GraphQLTypes["LocationsFields_sections_startDatetime"] | undefined,
	endDatetime?: GraphQLTypes["LocationsFields_sections_endDatetime"] | undefined,
	fullDay?: GraphQLTypes["LocationsFields_sections_fullDay"] | undefined,
	repeat?: GraphQLTypes["LocationsFields_sections_repeat"] | undefined,
	recurrance?: GraphQLTypes["LocationsFields_sections_recurrance"] | undefined,
	id?: GraphQLTypes["LocationsFields_sections_id"] | undefined
};
	["LocationsFields_sections_startDatetime"]: {
	__typename: "LocationsFields_sections_startDatetime",
	create?: GraphQLTypes["LocationsFields_sections_startDatetime_Create"] | undefined,
	read?: GraphQLTypes["LocationsFields_sections_startDatetime_Read"] | undefined,
	update?: GraphQLTypes["LocationsFields_sections_startDatetime_Update"] | undefined,
	delete?: GraphQLTypes["LocationsFields_sections_startDatetime_Delete"] | undefined
};
	["LocationsFields_sections_startDatetime_Create"]: {
	__typename: "LocationsFields_sections_startDatetime_Create",
	permission: boolean
};
	["LocationsFields_sections_startDatetime_Read"]: {
	__typename: "LocationsFields_sections_startDatetime_Read",
	permission: boolean
};
	["LocationsFields_sections_startDatetime_Update"]: {
	__typename: "LocationsFields_sections_startDatetime_Update",
	permission: boolean
};
	["LocationsFields_sections_startDatetime_Delete"]: {
	__typename: "LocationsFields_sections_startDatetime_Delete",
	permission: boolean
};
	["LocationsFields_sections_endDatetime"]: {
	__typename: "LocationsFields_sections_endDatetime",
	create?: GraphQLTypes["LocationsFields_sections_endDatetime_Create"] | undefined,
	read?: GraphQLTypes["LocationsFields_sections_endDatetime_Read"] | undefined,
	update?: GraphQLTypes["LocationsFields_sections_endDatetime_Update"] | undefined,
	delete?: GraphQLTypes["LocationsFields_sections_endDatetime_Delete"] | undefined
};
	["LocationsFields_sections_endDatetime_Create"]: {
	__typename: "LocationsFields_sections_endDatetime_Create",
	permission: boolean
};
	["LocationsFields_sections_endDatetime_Read"]: {
	__typename: "LocationsFields_sections_endDatetime_Read",
	permission: boolean
};
	["LocationsFields_sections_endDatetime_Update"]: {
	__typename: "LocationsFields_sections_endDatetime_Update",
	permission: boolean
};
	["LocationsFields_sections_endDatetime_Delete"]: {
	__typename: "LocationsFields_sections_endDatetime_Delete",
	permission: boolean
};
	["LocationsFields_sections_fullDay"]: {
	__typename: "LocationsFields_sections_fullDay",
	create?: GraphQLTypes["LocationsFields_sections_fullDay_Create"] | undefined,
	read?: GraphQLTypes["LocationsFields_sections_fullDay_Read"] | undefined,
	update?: GraphQLTypes["LocationsFields_sections_fullDay_Update"] | undefined,
	delete?: GraphQLTypes["LocationsFields_sections_fullDay_Delete"] | undefined
};
	["LocationsFields_sections_fullDay_Create"]: {
	__typename: "LocationsFields_sections_fullDay_Create",
	permission: boolean
};
	["LocationsFields_sections_fullDay_Read"]: {
	__typename: "LocationsFields_sections_fullDay_Read",
	permission: boolean
};
	["LocationsFields_sections_fullDay_Update"]: {
	__typename: "LocationsFields_sections_fullDay_Update",
	permission: boolean
};
	["LocationsFields_sections_fullDay_Delete"]: {
	__typename: "LocationsFields_sections_fullDay_Delete",
	permission: boolean
};
	["LocationsFields_sections_repeat"]: {
	__typename: "LocationsFields_sections_repeat",
	create?: GraphQLTypes["LocationsFields_sections_repeat_Create"] | undefined,
	read?: GraphQLTypes["LocationsFields_sections_repeat_Read"] | undefined,
	update?: GraphQLTypes["LocationsFields_sections_repeat_Update"] | undefined,
	delete?: GraphQLTypes["LocationsFields_sections_repeat_Delete"] | undefined
};
	["LocationsFields_sections_repeat_Create"]: {
	__typename: "LocationsFields_sections_repeat_Create",
	permission: boolean
};
	["LocationsFields_sections_repeat_Read"]: {
	__typename: "LocationsFields_sections_repeat_Read",
	permission: boolean
};
	["LocationsFields_sections_repeat_Update"]: {
	__typename: "LocationsFields_sections_repeat_Update",
	permission: boolean
};
	["LocationsFields_sections_repeat_Delete"]: {
	__typename: "LocationsFields_sections_repeat_Delete",
	permission: boolean
};
	["LocationsFields_sections_recurrance"]: {
	__typename: "LocationsFields_sections_recurrance",
	create?: GraphQLTypes["LocationsFields_sections_recurrance_Create"] | undefined,
	read?: GraphQLTypes["LocationsFields_sections_recurrance_Read"] | undefined,
	update?: GraphQLTypes["LocationsFields_sections_recurrance_Update"] | undefined,
	delete?: GraphQLTypes["LocationsFields_sections_recurrance_Delete"] | undefined,
	fields?: GraphQLTypes["LocationsFields_sections_recurrance_Fields"] | undefined
};
	["LocationsFields_sections_recurrance_Create"]: {
	__typename: "LocationsFields_sections_recurrance_Create",
	permission: boolean
};
	["LocationsFields_sections_recurrance_Read"]: {
	__typename: "LocationsFields_sections_recurrance_Read",
	permission: boolean
};
	["LocationsFields_sections_recurrance_Update"]: {
	__typename: "LocationsFields_sections_recurrance_Update",
	permission: boolean
};
	["LocationsFields_sections_recurrance_Delete"]: {
	__typename: "LocationsFields_sections_recurrance_Delete",
	permission: boolean
};
	["LocationsFields_sections_recurrance_Fields"]: {
	__typename: "LocationsFields_sections_recurrance_Fields",
	type?: GraphQLTypes["LocationsFields_sections_recurrance_type"] | undefined,
	weekday?: GraphQLTypes["LocationsFields_sections_recurrance_weekday"] | undefined
};
	["LocationsFields_sections_recurrance_type"]: {
	__typename: "LocationsFields_sections_recurrance_type",
	create?: GraphQLTypes["LocationsFields_sections_recurrance_type_Create"] | undefined,
	read?: GraphQLTypes["LocationsFields_sections_recurrance_type_Read"] | undefined,
	update?: GraphQLTypes["LocationsFields_sections_recurrance_type_Update"] | undefined,
	delete?: GraphQLTypes["LocationsFields_sections_recurrance_type_Delete"] | undefined
};
	["LocationsFields_sections_recurrance_type_Create"]: {
	__typename: "LocationsFields_sections_recurrance_type_Create",
	permission: boolean
};
	["LocationsFields_sections_recurrance_type_Read"]: {
	__typename: "LocationsFields_sections_recurrance_type_Read",
	permission: boolean
};
	["LocationsFields_sections_recurrance_type_Update"]: {
	__typename: "LocationsFields_sections_recurrance_type_Update",
	permission: boolean
};
	["LocationsFields_sections_recurrance_type_Delete"]: {
	__typename: "LocationsFields_sections_recurrance_type_Delete",
	permission: boolean
};
	["LocationsFields_sections_recurrance_weekday"]: {
	__typename: "LocationsFields_sections_recurrance_weekday",
	create?: GraphQLTypes["LocationsFields_sections_recurrance_weekday_Create"] | undefined,
	read?: GraphQLTypes["LocationsFields_sections_recurrance_weekday_Read"] | undefined,
	update?: GraphQLTypes["LocationsFields_sections_recurrance_weekday_Update"] | undefined,
	delete?: GraphQLTypes["LocationsFields_sections_recurrance_weekday_Delete"] | undefined
};
	["LocationsFields_sections_recurrance_weekday_Create"]: {
	__typename: "LocationsFields_sections_recurrance_weekday_Create",
	permission: boolean
};
	["LocationsFields_sections_recurrance_weekday_Read"]: {
	__typename: "LocationsFields_sections_recurrance_weekday_Read",
	permission: boolean
};
	["LocationsFields_sections_recurrance_weekday_Update"]: {
	__typename: "LocationsFields_sections_recurrance_weekday_Update",
	permission: boolean
};
	["LocationsFields_sections_recurrance_weekday_Delete"]: {
	__typename: "LocationsFields_sections_recurrance_weekday_Delete",
	permission: boolean
};
	["LocationsFields_sections_id"]: {
	__typename: "LocationsFields_sections_id",
	create?: GraphQLTypes["LocationsFields_sections_id_Create"] | undefined,
	read?: GraphQLTypes["LocationsFields_sections_id_Read"] | undefined,
	update?: GraphQLTypes["LocationsFields_sections_id_Update"] | undefined,
	delete?: GraphQLTypes["LocationsFields_sections_id_Delete"] | undefined
};
	["LocationsFields_sections_id_Create"]: {
	__typename: "LocationsFields_sections_id_Create",
	permission: boolean
};
	["LocationsFields_sections_id_Read"]: {
	__typename: "LocationsFields_sections_id_Read",
	permission: boolean
};
	["LocationsFields_sections_id_Update"]: {
	__typename: "LocationsFields_sections_id_Update",
	permission: boolean
};
	["LocationsFields_sections_id_Delete"]: {
	__typename: "LocationsFields_sections_id_Delete",
	permission: boolean
};
	["LocationsFields__title"]: {
	__typename: "LocationsFields__title",
	create?: GraphQLTypes["LocationsFields__title_Create"] | undefined,
	read?: GraphQLTypes["LocationsFields__title_Read"] | undefined,
	update?: GraphQLTypes["LocationsFields__title_Update"] | undefined,
	delete?: GraphQLTypes["LocationsFields__title_Delete"] | undefined
};
	["LocationsFields__title_Create"]: {
	__typename: "LocationsFields__title_Create",
	permission: boolean
};
	["LocationsFields__title_Read"]: {
	__typename: "LocationsFields__title_Read",
	permission: boolean
};
	["LocationsFields__title_Update"]: {
	__typename: "LocationsFields__title_Update",
	permission: boolean
};
	["LocationsFields__title_Delete"]: {
	__typename: "LocationsFields__title_Delete",
	permission: boolean
};
	["LocationsFields_legacyGallery"]: {
	__typename: "LocationsFields_legacyGallery",
	create?: GraphQLTypes["LocationsFields_legacyGallery_Create"] | undefined,
	read?: GraphQLTypes["LocationsFields_legacyGallery_Read"] | undefined,
	update?: GraphQLTypes["LocationsFields_legacyGallery_Update"] | undefined,
	delete?: GraphQLTypes["LocationsFields_legacyGallery_Delete"] | undefined
};
	["LocationsFields_legacyGallery_Create"]: {
	__typename: "LocationsFields_legacyGallery_Create",
	permission: boolean
};
	["LocationsFields_legacyGallery_Read"]: {
	__typename: "LocationsFields_legacyGallery_Read",
	permission: boolean
};
	["LocationsFields_legacyGallery_Update"]: {
	__typename: "LocationsFields_legacyGallery_Update",
	permission: boolean
};
	["LocationsFields_legacyGallery_Delete"]: {
	__typename: "LocationsFields_legacyGallery_Delete",
	permission: boolean
};
	["LocationsFields_updatedAt"]: {
	__typename: "LocationsFields_updatedAt",
	create?: GraphQLTypes["LocationsFields_updatedAt_Create"] | undefined,
	read?: GraphQLTypes["LocationsFields_updatedAt_Read"] | undefined,
	update?: GraphQLTypes["LocationsFields_updatedAt_Update"] | undefined,
	delete?: GraphQLTypes["LocationsFields_updatedAt_Delete"] | undefined
};
	["LocationsFields_updatedAt_Create"]: {
	__typename: "LocationsFields_updatedAt_Create",
	permission: boolean
};
	["LocationsFields_updatedAt_Read"]: {
	__typename: "LocationsFields_updatedAt_Read",
	permission: boolean
};
	["LocationsFields_updatedAt_Update"]: {
	__typename: "LocationsFields_updatedAt_Update",
	permission: boolean
};
	["LocationsFields_updatedAt_Delete"]: {
	__typename: "LocationsFields_updatedAt_Delete",
	permission: boolean
};
	["LocationsFields_createdAt"]: {
	__typename: "LocationsFields_createdAt",
	create?: GraphQLTypes["LocationsFields_createdAt_Create"] | undefined,
	read?: GraphQLTypes["LocationsFields_createdAt_Read"] | undefined,
	update?: GraphQLTypes["LocationsFields_createdAt_Update"] | undefined,
	delete?: GraphQLTypes["LocationsFields_createdAt_Delete"] | undefined
};
	["LocationsFields_createdAt_Create"]: {
	__typename: "LocationsFields_createdAt_Create",
	permission: boolean
};
	["LocationsFields_createdAt_Read"]: {
	__typename: "LocationsFields_createdAt_Read",
	permission: boolean
};
	["LocationsFields_createdAt_Update"]: {
	__typename: "LocationsFields_createdAt_Update",
	permission: boolean
};
	["LocationsFields_createdAt_Delete"]: {
	__typename: "LocationsFields_createdAt_Delete",
	permission: boolean
};
	["LocationsCreateAccess"]: {
	__typename: "LocationsCreateAccess",
	permission: boolean,
	where?: GraphQLTypes["JSONObject"] | undefined
};
	["LocationsReadAccess"]: {
	__typename: "LocationsReadAccess",
	permission: boolean,
	where?: GraphQLTypes["JSONObject"] | undefined
};
	["LocationsUpdateAccess"]: {
	__typename: "LocationsUpdateAccess",
	permission: boolean,
	where?: GraphQLTypes["JSONObject"] | undefined
};
	["LocationsDeleteAccess"]: {
	__typename: "LocationsDeleteAccess",
	permission: boolean,
	where?: GraphQLTypes["JSONObject"] | undefined
};
	["postsAccess"]: {
	__typename: "postsAccess",
	fields?: GraphQLTypes["PostsFields"] | undefined,
	create?: GraphQLTypes["PostsCreateAccess"] | undefined,
	read?: GraphQLTypes["PostsReadAccess"] | undefined,
	update?: GraphQLTypes["PostsUpdateAccess"] | undefined,
	delete?: GraphQLTypes["PostsDeleteAccess"] | undefined
};
	["PostsFields"]: {
	__typename: "PostsFields",
	title?: GraphQLTypes["PostsFields_title"] | undefined,
	region?: GraphQLTypes["PostsFields_region"] | undefined,
	authur?: GraphQLTypes["PostsFields_authur"] | undefined,
	publishDateTime?: GraphQLTypes["PostsFields_publishDateTime"] | undefined,
	permalink?: GraphQLTypes["PostsFields_permalink"] | undefined,
	status?: GraphQLTypes["PostsFields_status"] | undefined,
	content?: GraphQLTypes["PostsFields_content"] | undefined,
	legacyContent?: GraphQLTypes["PostsFields_legacyContent"] | undefined,
	showLegacyContent?: GraphQLTypes["PostsFields_showLegacyContent"] | undefined,
	blogCategories?: GraphQLTypes["PostsFields_blogCategories"] | undefined,
	thumbnail?: GraphQLTypes["PostsFields_thumbnail"] | undefined,
	banner?: GraphQLTypes["PostsFields_banner"] | undefined,
	criteria?: GraphQLTypes["PostsFields_criteria"] | undefined,
	location?: GraphQLTypes["PostsFields_location"] | undefined,
	homePageInternalClick?: GraphQLTypes["PostsFields_homePageInternalClick"] | undefined,
	homePageImpression?: GraphQLTypes["PostsFields_homePageImpression"] | undefined,
	blogListInternalClick?: GraphQLTypes["PostsFields_blogListInternalClick"] | undefined,
	blogListImpression?: GraphQLTypes["PostsFields_blogListImpression"] | undefined,
	blogDetailInternalClick?: GraphQLTypes["PostsFields_blogDetailInternalClick"] | undefined,
	blogDetailImpression?: GraphQLTypes["PostsFields_blogDetailImpression"] | undefined,
	relatedEventInternalClick?: GraphQLTypes["PostsFields_relatedEventInternalClick"] | undefined,
	relatedEventImpression?: GraphQLTypes["PostsFields_relatedEventImpression"] | undefined,
	latestEventInternalClick?: GraphQLTypes["PostsFields_latestEventInternalClick"] | undefined,
	latestEventImpression?: GraphQLTypes["PostsFields_latestEventImpression"] | undefined,
	internalClick?: GraphQLTypes["PostsFields_internalClick"] | undefined,
	totalImpression?: GraphQLTypes["PostsFields_totalImpression"] | undefined,
	externalClick?: GraphQLTypes["PostsFields_externalClick"] | undefined,
	outboundClick?: GraphQLTypes["PostsFields_outboundClick"] | undefined,
	legacyGallery?: GraphQLTypes["PostsFields_legacyGallery"] | undefined,
	updatedAt?: GraphQLTypes["PostsFields_updatedAt"] | undefined,
	createdAt?: GraphQLTypes["PostsFields_createdAt"] | undefined
};
	["PostsFields_title"]: {
	__typename: "PostsFields_title",
	create?: GraphQLTypes["PostsFields_title_Create"] | undefined,
	read?: GraphQLTypes["PostsFields_title_Read"] | undefined,
	update?: GraphQLTypes["PostsFields_title_Update"] | undefined,
	delete?: GraphQLTypes["PostsFields_title_Delete"] | undefined
};
	["PostsFields_title_Create"]: {
	__typename: "PostsFields_title_Create",
	permission: boolean
};
	["PostsFields_title_Read"]: {
	__typename: "PostsFields_title_Read",
	permission: boolean
};
	["PostsFields_title_Update"]: {
	__typename: "PostsFields_title_Update",
	permission: boolean
};
	["PostsFields_title_Delete"]: {
	__typename: "PostsFields_title_Delete",
	permission: boolean
};
	["PostsFields_region"]: {
	__typename: "PostsFields_region",
	create?: GraphQLTypes["PostsFields_region_Create"] | undefined,
	read?: GraphQLTypes["PostsFields_region_Read"] | undefined,
	update?: GraphQLTypes["PostsFields_region_Update"] | undefined,
	delete?: GraphQLTypes["PostsFields_region_Delete"] | undefined
};
	["PostsFields_region_Create"]: {
	__typename: "PostsFields_region_Create",
	permission: boolean
};
	["PostsFields_region_Read"]: {
	__typename: "PostsFields_region_Read",
	permission: boolean
};
	["PostsFields_region_Update"]: {
	__typename: "PostsFields_region_Update",
	permission: boolean
};
	["PostsFields_region_Delete"]: {
	__typename: "PostsFields_region_Delete",
	permission: boolean
};
	["PostsFields_authur"]: {
	__typename: "PostsFields_authur",
	create?: GraphQLTypes["PostsFields_authur_Create"] | undefined,
	read?: GraphQLTypes["PostsFields_authur_Read"] | undefined,
	update?: GraphQLTypes["PostsFields_authur_Update"] | undefined,
	delete?: GraphQLTypes["PostsFields_authur_Delete"] | undefined
};
	["PostsFields_authur_Create"]: {
	__typename: "PostsFields_authur_Create",
	permission: boolean
};
	["PostsFields_authur_Read"]: {
	__typename: "PostsFields_authur_Read",
	permission: boolean
};
	["PostsFields_authur_Update"]: {
	__typename: "PostsFields_authur_Update",
	permission: boolean
};
	["PostsFields_authur_Delete"]: {
	__typename: "PostsFields_authur_Delete",
	permission: boolean
};
	["PostsFields_publishDateTime"]: {
	__typename: "PostsFields_publishDateTime",
	create?: GraphQLTypes["PostsFields_publishDateTime_Create"] | undefined,
	read?: GraphQLTypes["PostsFields_publishDateTime_Read"] | undefined,
	update?: GraphQLTypes["PostsFields_publishDateTime_Update"] | undefined,
	delete?: GraphQLTypes["PostsFields_publishDateTime_Delete"] | undefined
};
	["PostsFields_publishDateTime_Create"]: {
	__typename: "PostsFields_publishDateTime_Create",
	permission: boolean
};
	["PostsFields_publishDateTime_Read"]: {
	__typename: "PostsFields_publishDateTime_Read",
	permission: boolean
};
	["PostsFields_publishDateTime_Update"]: {
	__typename: "PostsFields_publishDateTime_Update",
	permission: boolean
};
	["PostsFields_publishDateTime_Delete"]: {
	__typename: "PostsFields_publishDateTime_Delete",
	permission: boolean
};
	["PostsFields_permalink"]: {
	__typename: "PostsFields_permalink",
	create?: GraphQLTypes["PostsFields_permalink_Create"] | undefined,
	read?: GraphQLTypes["PostsFields_permalink_Read"] | undefined,
	update?: GraphQLTypes["PostsFields_permalink_Update"] | undefined,
	delete?: GraphQLTypes["PostsFields_permalink_Delete"] | undefined
};
	["PostsFields_permalink_Create"]: {
	__typename: "PostsFields_permalink_Create",
	permission: boolean
};
	["PostsFields_permalink_Read"]: {
	__typename: "PostsFields_permalink_Read",
	permission: boolean
};
	["PostsFields_permalink_Update"]: {
	__typename: "PostsFields_permalink_Update",
	permission: boolean
};
	["PostsFields_permalink_Delete"]: {
	__typename: "PostsFields_permalink_Delete",
	permission: boolean
};
	["PostsFields_status"]: {
	__typename: "PostsFields_status",
	create?: GraphQLTypes["PostsFields_status_Create"] | undefined,
	read?: GraphQLTypes["PostsFields_status_Read"] | undefined,
	update?: GraphQLTypes["PostsFields_status_Update"] | undefined,
	delete?: GraphQLTypes["PostsFields_status_Delete"] | undefined
};
	["PostsFields_status_Create"]: {
	__typename: "PostsFields_status_Create",
	permission: boolean
};
	["PostsFields_status_Read"]: {
	__typename: "PostsFields_status_Read",
	permission: boolean
};
	["PostsFields_status_Update"]: {
	__typename: "PostsFields_status_Update",
	permission: boolean
};
	["PostsFields_status_Delete"]: {
	__typename: "PostsFields_status_Delete",
	permission: boolean
};
	["PostsFields_content"]: {
	__typename: "PostsFields_content",
	create?: GraphQLTypes["PostsFields_content_Create"] | undefined,
	read?: GraphQLTypes["PostsFields_content_Read"] | undefined,
	update?: GraphQLTypes["PostsFields_content_Update"] | undefined,
	delete?: GraphQLTypes["PostsFields_content_Delete"] | undefined
};
	["PostsFields_content_Create"]: {
	__typename: "PostsFields_content_Create",
	permission: boolean
};
	["PostsFields_content_Read"]: {
	__typename: "PostsFields_content_Read",
	permission: boolean
};
	["PostsFields_content_Update"]: {
	__typename: "PostsFields_content_Update",
	permission: boolean
};
	["PostsFields_content_Delete"]: {
	__typename: "PostsFields_content_Delete",
	permission: boolean
};
	["PostsFields_legacyContent"]: {
	__typename: "PostsFields_legacyContent",
	create?: GraphQLTypes["PostsFields_legacyContent_Create"] | undefined,
	read?: GraphQLTypes["PostsFields_legacyContent_Read"] | undefined,
	update?: GraphQLTypes["PostsFields_legacyContent_Update"] | undefined,
	delete?: GraphQLTypes["PostsFields_legacyContent_Delete"] | undefined
};
	["PostsFields_legacyContent_Create"]: {
	__typename: "PostsFields_legacyContent_Create",
	permission: boolean
};
	["PostsFields_legacyContent_Read"]: {
	__typename: "PostsFields_legacyContent_Read",
	permission: boolean
};
	["PostsFields_legacyContent_Update"]: {
	__typename: "PostsFields_legacyContent_Update",
	permission: boolean
};
	["PostsFields_legacyContent_Delete"]: {
	__typename: "PostsFields_legacyContent_Delete",
	permission: boolean
};
	["PostsFields_showLegacyContent"]: {
	__typename: "PostsFields_showLegacyContent",
	create?: GraphQLTypes["PostsFields_showLegacyContent_Create"] | undefined,
	read?: GraphQLTypes["PostsFields_showLegacyContent_Read"] | undefined,
	update?: GraphQLTypes["PostsFields_showLegacyContent_Update"] | undefined,
	delete?: GraphQLTypes["PostsFields_showLegacyContent_Delete"] | undefined
};
	["PostsFields_showLegacyContent_Create"]: {
	__typename: "PostsFields_showLegacyContent_Create",
	permission: boolean
};
	["PostsFields_showLegacyContent_Read"]: {
	__typename: "PostsFields_showLegacyContent_Read",
	permission: boolean
};
	["PostsFields_showLegacyContent_Update"]: {
	__typename: "PostsFields_showLegacyContent_Update",
	permission: boolean
};
	["PostsFields_showLegacyContent_Delete"]: {
	__typename: "PostsFields_showLegacyContent_Delete",
	permission: boolean
};
	["PostsFields_blogCategories"]: {
	__typename: "PostsFields_blogCategories",
	create?: GraphQLTypes["PostsFields_blogCategories_Create"] | undefined,
	read?: GraphQLTypes["PostsFields_blogCategories_Read"] | undefined,
	update?: GraphQLTypes["PostsFields_blogCategories_Update"] | undefined,
	delete?: GraphQLTypes["PostsFields_blogCategories_Delete"] | undefined
};
	["PostsFields_blogCategories_Create"]: {
	__typename: "PostsFields_blogCategories_Create",
	permission: boolean
};
	["PostsFields_blogCategories_Read"]: {
	__typename: "PostsFields_blogCategories_Read",
	permission: boolean
};
	["PostsFields_blogCategories_Update"]: {
	__typename: "PostsFields_blogCategories_Update",
	permission: boolean
};
	["PostsFields_blogCategories_Delete"]: {
	__typename: "PostsFields_blogCategories_Delete",
	permission: boolean
};
	["PostsFields_thumbnail"]: {
	__typename: "PostsFields_thumbnail",
	create?: GraphQLTypes["PostsFields_thumbnail_Create"] | undefined,
	read?: GraphQLTypes["PostsFields_thumbnail_Read"] | undefined,
	update?: GraphQLTypes["PostsFields_thumbnail_Update"] | undefined,
	delete?: GraphQLTypes["PostsFields_thumbnail_Delete"] | undefined
};
	["PostsFields_thumbnail_Create"]: {
	__typename: "PostsFields_thumbnail_Create",
	permission: boolean
};
	["PostsFields_thumbnail_Read"]: {
	__typename: "PostsFields_thumbnail_Read",
	permission: boolean
};
	["PostsFields_thumbnail_Update"]: {
	__typename: "PostsFields_thumbnail_Update",
	permission: boolean
};
	["PostsFields_thumbnail_Delete"]: {
	__typename: "PostsFields_thumbnail_Delete",
	permission: boolean
};
	["PostsFields_banner"]: {
	__typename: "PostsFields_banner",
	create?: GraphQLTypes["PostsFields_banner_Create"] | undefined,
	read?: GraphQLTypes["PostsFields_banner_Read"] | undefined,
	update?: GraphQLTypes["PostsFields_banner_Update"] | undefined,
	delete?: GraphQLTypes["PostsFields_banner_Delete"] | undefined
};
	["PostsFields_banner_Create"]: {
	__typename: "PostsFields_banner_Create",
	permission: boolean
};
	["PostsFields_banner_Read"]: {
	__typename: "PostsFields_banner_Read",
	permission: boolean
};
	["PostsFields_banner_Update"]: {
	__typename: "PostsFields_banner_Update",
	permission: boolean
};
	["PostsFields_banner_Delete"]: {
	__typename: "PostsFields_banner_Delete",
	permission: boolean
};
	["PostsFields_criteria"]: {
	__typename: "PostsFields_criteria",
	create?: GraphQLTypes["PostsFields_criteria_Create"] | undefined,
	read?: GraphQLTypes["PostsFields_criteria_Read"] | undefined,
	update?: GraphQLTypes["PostsFields_criteria_Update"] | undefined,
	delete?: GraphQLTypes["PostsFields_criteria_Delete"] | undefined,
	fields?: GraphQLTypes["PostsFields_criteria_Fields"] | undefined
};
	["PostsFields_criteria_Create"]: {
	__typename: "PostsFields_criteria_Create",
	permission: boolean
};
	["PostsFields_criteria_Read"]: {
	__typename: "PostsFields_criteria_Read",
	permission: boolean
};
	["PostsFields_criteria_Update"]: {
	__typename: "PostsFields_criteria_Update",
	permission: boolean
};
	["PostsFields_criteria_Delete"]: {
	__typename: "PostsFields_criteria_Delete",
	permission: boolean
};
	["PostsFields_criteria_Fields"]: {
	__typename: "PostsFields_criteria_Fields",
	audiences?: GraphQLTypes["PostsFields_criteria_audiences"] | undefined,
	dates?: GraphQLTypes["PostsFields_criteria_dates"] | undefined,
	categories?: GraphQLTypes["PostsFields_criteria_categories"] | undefined,
	tags?: GraphQLTypes["PostsFields_criteria_tags"] | undefined
};
	["PostsFields_criteria_audiences"]: {
	__typename: "PostsFields_criteria_audiences",
	create?: GraphQLTypes["PostsFields_criteria_audiences_Create"] | undefined,
	read?: GraphQLTypes["PostsFields_criteria_audiences_Read"] | undefined,
	update?: GraphQLTypes["PostsFields_criteria_audiences_Update"] | undefined,
	delete?: GraphQLTypes["PostsFields_criteria_audiences_Delete"] | undefined
};
	["PostsFields_criteria_audiences_Create"]: {
	__typename: "PostsFields_criteria_audiences_Create",
	permission: boolean
};
	["PostsFields_criteria_audiences_Read"]: {
	__typename: "PostsFields_criteria_audiences_Read",
	permission: boolean
};
	["PostsFields_criteria_audiences_Update"]: {
	__typename: "PostsFields_criteria_audiences_Update",
	permission: boolean
};
	["PostsFields_criteria_audiences_Delete"]: {
	__typename: "PostsFields_criteria_audiences_Delete",
	permission: boolean
};
	["PostsFields_criteria_dates"]: {
	__typename: "PostsFields_criteria_dates",
	create?: GraphQLTypes["PostsFields_criteria_dates_Create"] | undefined,
	read?: GraphQLTypes["PostsFields_criteria_dates_Read"] | undefined,
	update?: GraphQLTypes["PostsFields_criteria_dates_Update"] | undefined,
	delete?: GraphQLTypes["PostsFields_criteria_dates_Delete"] | undefined
};
	["PostsFields_criteria_dates_Create"]: {
	__typename: "PostsFields_criteria_dates_Create",
	permission: boolean
};
	["PostsFields_criteria_dates_Read"]: {
	__typename: "PostsFields_criteria_dates_Read",
	permission: boolean
};
	["PostsFields_criteria_dates_Update"]: {
	__typename: "PostsFields_criteria_dates_Update",
	permission: boolean
};
	["PostsFields_criteria_dates_Delete"]: {
	__typename: "PostsFields_criteria_dates_Delete",
	permission: boolean
};
	["PostsFields_criteria_categories"]: {
	__typename: "PostsFields_criteria_categories",
	create?: GraphQLTypes["PostsFields_criteria_categories_Create"] | undefined,
	read?: GraphQLTypes["PostsFields_criteria_categories_Read"] | undefined,
	update?: GraphQLTypes["PostsFields_criteria_categories_Update"] | undefined,
	delete?: GraphQLTypes["PostsFields_criteria_categories_Delete"] | undefined
};
	["PostsFields_criteria_categories_Create"]: {
	__typename: "PostsFields_criteria_categories_Create",
	permission: boolean
};
	["PostsFields_criteria_categories_Read"]: {
	__typename: "PostsFields_criteria_categories_Read",
	permission: boolean
};
	["PostsFields_criteria_categories_Update"]: {
	__typename: "PostsFields_criteria_categories_Update",
	permission: boolean
};
	["PostsFields_criteria_categories_Delete"]: {
	__typename: "PostsFields_criteria_categories_Delete",
	permission: boolean
};
	["PostsFields_criteria_tags"]: {
	__typename: "PostsFields_criteria_tags",
	create?: GraphQLTypes["PostsFields_criteria_tags_Create"] | undefined,
	read?: GraphQLTypes["PostsFields_criteria_tags_Read"] | undefined,
	update?: GraphQLTypes["PostsFields_criteria_tags_Update"] | undefined,
	delete?: GraphQLTypes["PostsFields_criteria_tags_Delete"] | undefined
};
	["PostsFields_criteria_tags_Create"]: {
	__typename: "PostsFields_criteria_tags_Create",
	permission: boolean
};
	["PostsFields_criteria_tags_Read"]: {
	__typename: "PostsFields_criteria_tags_Read",
	permission: boolean
};
	["PostsFields_criteria_tags_Update"]: {
	__typename: "PostsFields_criteria_tags_Update",
	permission: boolean
};
	["PostsFields_criteria_tags_Delete"]: {
	__typename: "PostsFields_criteria_tags_Delete",
	permission: boolean
};
	["PostsFields_location"]: {
	__typename: "PostsFields_location",
	create?: GraphQLTypes["PostsFields_location_Create"] | undefined,
	read?: GraphQLTypes["PostsFields_location_Read"] | undefined,
	update?: GraphQLTypes["PostsFields_location_Update"] | undefined,
	delete?: GraphQLTypes["PostsFields_location_Delete"] | undefined
};
	["PostsFields_location_Create"]: {
	__typename: "PostsFields_location_Create",
	permission: boolean
};
	["PostsFields_location_Read"]: {
	__typename: "PostsFields_location_Read",
	permission: boolean
};
	["PostsFields_location_Update"]: {
	__typename: "PostsFields_location_Update",
	permission: boolean
};
	["PostsFields_location_Delete"]: {
	__typename: "PostsFields_location_Delete",
	permission: boolean
};
	["PostsFields_homePageInternalClick"]: {
	__typename: "PostsFields_homePageInternalClick",
	create?: GraphQLTypes["PostsFields_homePageInternalClick_Create"] | undefined,
	read?: GraphQLTypes["PostsFields_homePageInternalClick_Read"] | undefined,
	update?: GraphQLTypes["PostsFields_homePageInternalClick_Update"] | undefined,
	delete?: GraphQLTypes["PostsFields_homePageInternalClick_Delete"] | undefined
};
	["PostsFields_homePageInternalClick_Create"]: {
	__typename: "PostsFields_homePageInternalClick_Create",
	permission: boolean
};
	["PostsFields_homePageInternalClick_Read"]: {
	__typename: "PostsFields_homePageInternalClick_Read",
	permission: boolean
};
	["PostsFields_homePageInternalClick_Update"]: {
	__typename: "PostsFields_homePageInternalClick_Update",
	permission: boolean
};
	["PostsFields_homePageInternalClick_Delete"]: {
	__typename: "PostsFields_homePageInternalClick_Delete",
	permission: boolean
};
	["PostsFields_homePageImpression"]: {
	__typename: "PostsFields_homePageImpression",
	create?: GraphQLTypes["PostsFields_homePageImpression_Create"] | undefined,
	read?: GraphQLTypes["PostsFields_homePageImpression_Read"] | undefined,
	update?: GraphQLTypes["PostsFields_homePageImpression_Update"] | undefined,
	delete?: GraphQLTypes["PostsFields_homePageImpression_Delete"] | undefined
};
	["PostsFields_homePageImpression_Create"]: {
	__typename: "PostsFields_homePageImpression_Create",
	permission: boolean
};
	["PostsFields_homePageImpression_Read"]: {
	__typename: "PostsFields_homePageImpression_Read",
	permission: boolean
};
	["PostsFields_homePageImpression_Update"]: {
	__typename: "PostsFields_homePageImpression_Update",
	permission: boolean
};
	["PostsFields_homePageImpression_Delete"]: {
	__typename: "PostsFields_homePageImpression_Delete",
	permission: boolean
};
	["PostsFields_blogListInternalClick"]: {
	__typename: "PostsFields_blogListInternalClick",
	create?: GraphQLTypes["PostsFields_blogListInternalClick_Create"] | undefined,
	read?: GraphQLTypes["PostsFields_blogListInternalClick_Read"] | undefined,
	update?: GraphQLTypes["PostsFields_blogListInternalClick_Update"] | undefined,
	delete?: GraphQLTypes["PostsFields_blogListInternalClick_Delete"] | undefined
};
	["PostsFields_blogListInternalClick_Create"]: {
	__typename: "PostsFields_blogListInternalClick_Create",
	permission: boolean
};
	["PostsFields_blogListInternalClick_Read"]: {
	__typename: "PostsFields_blogListInternalClick_Read",
	permission: boolean
};
	["PostsFields_blogListInternalClick_Update"]: {
	__typename: "PostsFields_blogListInternalClick_Update",
	permission: boolean
};
	["PostsFields_blogListInternalClick_Delete"]: {
	__typename: "PostsFields_blogListInternalClick_Delete",
	permission: boolean
};
	["PostsFields_blogListImpression"]: {
	__typename: "PostsFields_blogListImpression",
	create?: GraphQLTypes["PostsFields_blogListImpression_Create"] | undefined,
	read?: GraphQLTypes["PostsFields_blogListImpression_Read"] | undefined,
	update?: GraphQLTypes["PostsFields_blogListImpression_Update"] | undefined,
	delete?: GraphQLTypes["PostsFields_blogListImpression_Delete"] | undefined
};
	["PostsFields_blogListImpression_Create"]: {
	__typename: "PostsFields_blogListImpression_Create",
	permission: boolean
};
	["PostsFields_blogListImpression_Read"]: {
	__typename: "PostsFields_blogListImpression_Read",
	permission: boolean
};
	["PostsFields_blogListImpression_Update"]: {
	__typename: "PostsFields_blogListImpression_Update",
	permission: boolean
};
	["PostsFields_blogListImpression_Delete"]: {
	__typename: "PostsFields_blogListImpression_Delete",
	permission: boolean
};
	["PostsFields_blogDetailInternalClick"]: {
	__typename: "PostsFields_blogDetailInternalClick",
	create?: GraphQLTypes["PostsFields_blogDetailInternalClick_Create"] | undefined,
	read?: GraphQLTypes["PostsFields_blogDetailInternalClick_Read"] | undefined,
	update?: GraphQLTypes["PostsFields_blogDetailInternalClick_Update"] | undefined,
	delete?: GraphQLTypes["PostsFields_blogDetailInternalClick_Delete"] | undefined
};
	["PostsFields_blogDetailInternalClick_Create"]: {
	__typename: "PostsFields_blogDetailInternalClick_Create",
	permission: boolean
};
	["PostsFields_blogDetailInternalClick_Read"]: {
	__typename: "PostsFields_blogDetailInternalClick_Read",
	permission: boolean
};
	["PostsFields_blogDetailInternalClick_Update"]: {
	__typename: "PostsFields_blogDetailInternalClick_Update",
	permission: boolean
};
	["PostsFields_blogDetailInternalClick_Delete"]: {
	__typename: "PostsFields_blogDetailInternalClick_Delete",
	permission: boolean
};
	["PostsFields_blogDetailImpression"]: {
	__typename: "PostsFields_blogDetailImpression",
	create?: GraphQLTypes["PostsFields_blogDetailImpression_Create"] | undefined,
	read?: GraphQLTypes["PostsFields_blogDetailImpression_Read"] | undefined,
	update?: GraphQLTypes["PostsFields_blogDetailImpression_Update"] | undefined,
	delete?: GraphQLTypes["PostsFields_blogDetailImpression_Delete"] | undefined
};
	["PostsFields_blogDetailImpression_Create"]: {
	__typename: "PostsFields_blogDetailImpression_Create",
	permission: boolean
};
	["PostsFields_blogDetailImpression_Read"]: {
	__typename: "PostsFields_blogDetailImpression_Read",
	permission: boolean
};
	["PostsFields_blogDetailImpression_Update"]: {
	__typename: "PostsFields_blogDetailImpression_Update",
	permission: boolean
};
	["PostsFields_blogDetailImpression_Delete"]: {
	__typename: "PostsFields_blogDetailImpression_Delete",
	permission: boolean
};
	["PostsFields_relatedEventInternalClick"]: {
	__typename: "PostsFields_relatedEventInternalClick",
	create?: GraphQLTypes["PostsFields_relatedEventInternalClick_Create"] | undefined,
	read?: GraphQLTypes["PostsFields_relatedEventInternalClick_Read"] | undefined,
	update?: GraphQLTypes["PostsFields_relatedEventInternalClick_Update"] | undefined,
	delete?: GraphQLTypes["PostsFields_relatedEventInternalClick_Delete"] | undefined
};
	["PostsFields_relatedEventInternalClick_Create"]: {
	__typename: "PostsFields_relatedEventInternalClick_Create",
	permission: boolean
};
	["PostsFields_relatedEventInternalClick_Read"]: {
	__typename: "PostsFields_relatedEventInternalClick_Read",
	permission: boolean
};
	["PostsFields_relatedEventInternalClick_Update"]: {
	__typename: "PostsFields_relatedEventInternalClick_Update",
	permission: boolean
};
	["PostsFields_relatedEventInternalClick_Delete"]: {
	__typename: "PostsFields_relatedEventInternalClick_Delete",
	permission: boolean
};
	["PostsFields_relatedEventImpression"]: {
	__typename: "PostsFields_relatedEventImpression",
	create?: GraphQLTypes["PostsFields_relatedEventImpression_Create"] | undefined,
	read?: GraphQLTypes["PostsFields_relatedEventImpression_Read"] | undefined,
	update?: GraphQLTypes["PostsFields_relatedEventImpression_Update"] | undefined,
	delete?: GraphQLTypes["PostsFields_relatedEventImpression_Delete"] | undefined
};
	["PostsFields_relatedEventImpression_Create"]: {
	__typename: "PostsFields_relatedEventImpression_Create",
	permission: boolean
};
	["PostsFields_relatedEventImpression_Read"]: {
	__typename: "PostsFields_relatedEventImpression_Read",
	permission: boolean
};
	["PostsFields_relatedEventImpression_Update"]: {
	__typename: "PostsFields_relatedEventImpression_Update",
	permission: boolean
};
	["PostsFields_relatedEventImpression_Delete"]: {
	__typename: "PostsFields_relatedEventImpression_Delete",
	permission: boolean
};
	["PostsFields_latestEventInternalClick"]: {
	__typename: "PostsFields_latestEventInternalClick",
	create?: GraphQLTypes["PostsFields_latestEventInternalClick_Create"] | undefined,
	read?: GraphQLTypes["PostsFields_latestEventInternalClick_Read"] | undefined,
	update?: GraphQLTypes["PostsFields_latestEventInternalClick_Update"] | undefined,
	delete?: GraphQLTypes["PostsFields_latestEventInternalClick_Delete"] | undefined
};
	["PostsFields_latestEventInternalClick_Create"]: {
	__typename: "PostsFields_latestEventInternalClick_Create",
	permission: boolean
};
	["PostsFields_latestEventInternalClick_Read"]: {
	__typename: "PostsFields_latestEventInternalClick_Read",
	permission: boolean
};
	["PostsFields_latestEventInternalClick_Update"]: {
	__typename: "PostsFields_latestEventInternalClick_Update",
	permission: boolean
};
	["PostsFields_latestEventInternalClick_Delete"]: {
	__typename: "PostsFields_latestEventInternalClick_Delete",
	permission: boolean
};
	["PostsFields_latestEventImpression"]: {
	__typename: "PostsFields_latestEventImpression",
	create?: GraphQLTypes["PostsFields_latestEventImpression_Create"] | undefined,
	read?: GraphQLTypes["PostsFields_latestEventImpression_Read"] | undefined,
	update?: GraphQLTypes["PostsFields_latestEventImpression_Update"] | undefined,
	delete?: GraphQLTypes["PostsFields_latestEventImpression_Delete"] | undefined
};
	["PostsFields_latestEventImpression_Create"]: {
	__typename: "PostsFields_latestEventImpression_Create",
	permission: boolean
};
	["PostsFields_latestEventImpression_Read"]: {
	__typename: "PostsFields_latestEventImpression_Read",
	permission: boolean
};
	["PostsFields_latestEventImpression_Update"]: {
	__typename: "PostsFields_latestEventImpression_Update",
	permission: boolean
};
	["PostsFields_latestEventImpression_Delete"]: {
	__typename: "PostsFields_latestEventImpression_Delete",
	permission: boolean
};
	["PostsFields_internalClick"]: {
	__typename: "PostsFields_internalClick",
	create?: GraphQLTypes["PostsFields_internalClick_Create"] | undefined,
	read?: GraphQLTypes["PostsFields_internalClick_Read"] | undefined,
	update?: GraphQLTypes["PostsFields_internalClick_Update"] | undefined,
	delete?: GraphQLTypes["PostsFields_internalClick_Delete"] | undefined
};
	["PostsFields_internalClick_Create"]: {
	__typename: "PostsFields_internalClick_Create",
	permission: boolean
};
	["PostsFields_internalClick_Read"]: {
	__typename: "PostsFields_internalClick_Read",
	permission: boolean
};
	["PostsFields_internalClick_Update"]: {
	__typename: "PostsFields_internalClick_Update",
	permission: boolean
};
	["PostsFields_internalClick_Delete"]: {
	__typename: "PostsFields_internalClick_Delete",
	permission: boolean
};
	["PostsFields_totalImpression"]: {
	__typename: "PostsFields_totalImpression",
	create?: GraphQLTypes["PostsFields_totalImpression_Create"] | undefined,
	read?: GraphQLTypes["PostsFields_totalImpression_Read"] | undefined,
	update?: GraphQLTypes["PostsFields_totalImpression_Update"] | undefined,
	delete?: GraphQLTypes["PostsFields_totalImpression_Delete"] | undefined
};
	["PostsFields_totalImpression_Create"]: {
	__typename: "PostsFields_totalImpression_Create",
	permission: boolean
};
	["PostsFields_totalImpression_Read"]: {
	__typename: "PostsFields_totalImpression_Read",
	permission: boolean
};
	["PostsFields_totalImpression_Update"]: {
	__typename: "PostsFields_totalImpression_Update",
	permission: boolean
};
	["PostsFields_totalImpression_Delete"]: {
	__typename: "PostsFields_totalImpression_Delete",
	permission: boolean
};
	["PostsFields_externalClick"]: {
	__typename: "PostsFields_externalClick",
	create?: GraphQLTypes["PostsFields_externalClick_Create"] | undefined,
	read?: GraphQLTypes["PostsFields_externalClick_Read"] | undefined,
	update?: GraphQLTypes["PostsFields_externalClick_Update"] | undefined,
	delete?: GraphQLTypes["PostsFields_externalClick_Delete"] | undefined
};
	["PostsFields_externalClick_Create"]: {
	__typename: "PostsFields_externalClick_Create",
	permission: boolean
};
	["PostsFields_externalClick_Read"]: {
	__typename: "PostsFields_externalClick_Read",
	permission: boolean
};
	["PostsFields_externalClick_Update"]: {
	__typename: "PostsFields_externalClick_Update",
	permission: boolean
};
	["PostsFields_externalClick_Delete"]: {
	__typename: "PostsFields_externalClick_Delete",
	permission: boolean
};
	["PostsFields_outboundClick"]: {
	__typename: "PostsFields_outboundClick",
	create?: GraphQLTypes["PostsFields_outboundClick_Create"] | undefined,
	read?: GraphQLTypes["PostsFields_outboundClick_Read"] | undefined,
	update?: GraphQLTypes["PostsFields_outboundClick_Update"] | undefined,
	delete?: GraphQLTypes["PostsFields_outboundClick_Delete"] | undefined
};
	["PostsFields_outboundClick_Create"]: {
	__typename: "PostsFields_outboundClick_Create",
	permission: boolean
};
	["PostsFields_outboundClick_Read"]: {
	__typename: "PostsFields_outboundClick_Read",
	permission: boolean
};
	["PostsFields_outboundClick_Update"]: {
	__typename: "PostsFields_outboundClick_Update",
	permission: boolean
};
	["PostsFields_outboundClick_Delete"]: {
	__typename: "PostsFields_outboundClick_Delete",
	permission: boolean
};
	["PostsFields_legacyGallery"]: {
	__typename: "PostsFields_legacyGallery",
	create?: GraphQLTypes["PostsFields_legacyGallery_Create"] | undefined,
	read?: GraphQLTypes["PostsFields_legacyGallery_Read"] | undefined,
	update?: GraphQLTypes["PostsFields_legacyGallery_Update"] | undefined,
	delete?: GraphQLTypes["PostsFields_legacyGallery_Delete"] | undefined
};
	["PostsFields_legacyGallery_Create"]: {
	__typename: "PostsFields_legacyGallery_Create",
	permission: boolean
};
	["PostsFields_legacyGallery_Read"]: {
	__typename: "PostsFields_legacyGallery_Read",
	permission: boolean
};
	["PostsFields_legacyGallery_Update"]: {
	__typename: "PostsFields_legacyGallery_Update",
	permission: boolean
};
	["PostsFields_legacyGallery_Delete"]: {
	__typename: "PostsFields_legacyGallery_Delete",
	permission: boolean
};
	["PostsFields_updatedAt"]: {
	__typename: "PostsFields_updatedAt",
	create?: GraphQLTypes["PostsFields_updatedAt_Create"] | undefined,
	read?: GraphQLTypes["PostsFields_updatedAt_Read"] | undefined,
	update?: GraphQLTypes["PostsFields_updatedAt_Update"] | undefined,
	delete?: GraphQLTypes["PostsFields_updatedAt_Delete"] | undefined
};
	["PostsFields_updatedAt_Create"]: {
	__typename: "PostsFields_updatedAt_Create",
	permission: boolean
};
	["PostsFields_updatedAt_Read"]: {
	__typename: "PostsFields_updatedAt_Read",
	permission: boolean
};
	["PostsFields_updatedAt_Update"]: {
	__typename: "PostsFields_updatedAt_Update",
	permission: boolean
};
	["PostsFields_updatedAt_Delete"]: {
	__typename: "PostsFields_updatedAt_Delete",
	permission: boolean
};
	["PostsFields_createdAt"]: {
	__typename: "PostsFields_createdAt",
	create?: GraphQLTypes["PostsFields_createdAt_Create"] | undefined,
	read?: GraphQLTypes["PostsFields_createdAt_Read"] | undefined,
	update?: GraphQLTypes["PostsFields_createdAt_Update"] | undefined,
	delete?: GraphQLTypes["PostsFields_createdAt_Delete"] | undefined
};
	["PostsFields_createdAt_Create"]: {
	__typename: "PostsFields_createdAt_Create",
	permission: boolean
};
	["PostsFields_createdAt_Read"]: {
	__typename: "PostsFields_createdAt_Read",
	permission: boolean
};
	["PostsFields_createdAt_Update"]: {
	__typename: "PostsFields_createdAt_Update",
	permission: boolean
};
	["PostsFields_createdAt_Delete"]: {
	__typename: "PostsFields_createdAt_Delete",
	permission: boolean
};
	["PostsCreateAccess"]: {
	__typename: "PostsCreateAccess",
	permission: boolean,
	where?: GraphQLTypes["JSONObject"] | undefined
};
	["PostsReadAccess"]: {
	__typename: "PostsReadAccess",
	permission: boolean,
	where?: GraphQLTypes["JSONObject"] | undefined
};
	["PostsUpdateAccess"]: {
	__typename: "PostsUpdateAccess",
	permission: boolean,
	where?: GraphQLTypes["JSONObject"] | undefined
};
	["PostsDeleteAccess"]: {
	__typename: "PostsDeleteAccess",
	permission: boolean,
	where?: GraphQLTypes["JSONObject"] | undefined
};
	["ticketingAccess"]: {
	__typename: "ticketingAccess",
	fields?: GraphQLTypes["TicketingFields"] | undefined,
	create?: GraphQLTypes["TicketingCreateAccess"] | undefined,
	read?: GraphQLTypes["TicketingReadAccess"] | undefined,
	update?: GraphQLTypes["TicketingUpdateAccess"] | undefined,
	delete?: GraphQLTypes["TicketingDeleteAccess"] | undefined
};
	["TicketingFields"]: {
	__typename: "TicketingFields",
	status?: GraphQLTypes["TicketingFields_status"] | undefined,
	creator?: GraphQLTypes["TicketingFields_creator"] | undefined,
	productType?: GraphQLTypes["TicketingFields_productType"] | undefined,
	productName?: GraphQLTypes["TicketingFields_productName"] | undefined,
	referenceId?: GraphQLTypes["TicketingFields_referenceId"] | undefined,
	linkedEvent?: GraphQLTypes["TicketingFields_linkedEvent"] | undefined,
	CheckInLevel?: GraphQLTypes["TicketingFields_CheckInLevel"] | undefined,
	displayPrice?: GraphQLTypes["TicketingFields_displayPrice"] | undefined,
	tnc?: GraphQLTypes["TicketingFields_tnc"] | undefined,
	cutoffHours?: GraphQLTypes["TicketingFields_cutoffHours"] | undefined,
	sessions?: GraphQLTypes["TicketingFields_sessions"] | undefined,
	updatedAt?: GraphQLTypes["TicketingFields_updatedAt"] | undefined,
	createdAt?: GraphQLTypes["TicketingFields_createdAt"] | undefined
};
	["TicketingFields_status"]: {
	__typename: "TicketingFields_status",
	create?: GraphQLTypes["TicketingFields_status_Create"] | undefined,
	read?: GraphQLTypes["TicketingFields_status_Read"] | undefined,
	update?: GraphQLTypes["TicketingFields_status_Update"] | undefined,
	delete?: GraphQLTypes["TicketingFields_status_Delete"] | undefined
};
	["TicketingFields_status_Create"]: {
	__typename: "TicketingFields_status_Create",
	permission: boolean
};
	["TicketingFields_status_Read"]: {
	__typename: "TicketingFields_status_Read",
	permission: boolean
};
	["TicketingFields_status_Update"]: {
	__typename: "TicketingFields_status_Update",
	permission: boolean
};
	["TicketingFields_status_Delete"]: {
	__typename: "TicketingFields_status_Delete",
	permission: boolean
};
	["TicketingFields_creator"]: {
	__typename: "TicketingFields_creator",
	create?: GraphQLTypes["TicketingFields_creator_Create"] | undefined,
	read?: GraphQLTypes["TicketingFields_creator_Read"] | undefined,
	update?: GraphQLTypes["TicketingFields_creator_Update"] | undefined,
	delete?: GraphQLTypes["TicketingFields_creator_Delete"] | undefined
};
	["TicketingFields_creator_Create"]: {
	__typename: "TicketingFields_creator_Create",
	permission: boolean
};
	["TicketingFields_creator_Read"]: {
	__typename: "TicketingFields_creator_Read",
	permission: boolean
};
	["TicketingFields_creator_Update"]: {
	__typename: "TicketingFields_creator_Update",
	permission: boolean
};
	["TicketingFields_creator_Delete"]: {
	__typename: "TicketingFields_creator_Delete",
	permission: boolean
};
	["TicketingFields_productType"]: {
	__typename: "TicketingFields_productType",
	create?: GraphQLTypes["TicketingFields_productType_Create"] | undefined,
	read?: GraphQLTypes["TicketingFields_productType_Read"] | undefined,
	update?: GraphQLTypes["TicketingFields_productType_Update"] | undefined,
	delete?: GraphQLTypes["TicketingFields_productType_Delete"] | undefined
};
	["TicketingFields_productType_Create"]: {
	__typename: "TicketingFields_productType_Create",
	permission: boolean
};
	["TicketingFields_productType_Read"]: {
	__typename: "TicketingFields_productType_Read",
	permission: boolean
};
	["TicketingFields_productType_Update"]: {
	__typename: "TicketingFields_productType_Update",
	permission: boolean
};
	["TicketingFields_productType_Delete"]: {
	__typename: "TicketingFields_productType_Delete",
	permission: boolean
};
	["TicketingFields_productName"]: {
	__typename: "TicketingFields_productName",
	create?: GraphQLTypes["TicketingFields_productName_Create"] | undefined,
	read?: GraphQLTypes["TicketingFields_productName_Read"] | undefined,
	update?: GraphQLTypes["TicketingFields_productName_Update"] | undefined,
	delete?: GraphQLTypes["TicketingFields_productName_Delete"] | undefined
};
	["TicketingFields_productName_Create"]: {
	__typename: "TicketingFields_productName_Create",
	permission: boolean
};
	["TicketingFields_productName_Read"]: {
	__typename: "TicketingFields_productName_Read",
	permission: boolean
};
	["TicketingFields_productName_Update"]: {
	__typename: "TicketingFields_productName_Update",
	permission: boolean
};
	["TicketingFields_productName_Delete"]: {
	__typename: "TicketingFields_productName_Delete",
	permission: boolean
};
	["TicketingFields_referenceId"]: {
	__typename: "TicketingFields_referenceId",
	create?: GraphQLTypes["TicketingFields_referenceId_Create"] | undefined,
	read?: GraphQLTypes["TicketingFields_referenceId_Read"] | undefined,
	update?: GraphQLTypes["TicketingFields_referenceId_Update"] | undefined,
	delete?: GraphQLTypes["TicketingFields_referenceId_Delete"] | undefined
};
	["TicketingFields_referenceId_Create"]: {
	__typename: "TicketingFields_referenceId_Create",
	permission: boolean
};
	["TicketingFields_referenceId_Read"]: {
	__typename: "TicketingFields_referenceId_Read",
	permission: boolean
};
	["TicketingFields_referenceId_Update"]: {
	__typename: "TicketingFields_referenceId_Update",
	permission: boolean
};
	["TicketingFields_referenceId_Delete"]: {
	__typename: "TicketingFields_referenceId_Delete",
	permission: boolean
};
	["TicketingFields_linkedEvent"]: {
	__typename: "TicketingFields_linkedEvent",
	create?: GraphQLTypes["TicketingFields_linkedEvent_Create"] | undefined,
	read?: GraphQLTypes["TicketingFields_linkedEvent_Read"] | undefined,
	update?: GraphQLTypes["TicketingFields_linkedEvent_Update"] | undefined,
	delete?: GraphQLTypes["TicketingFields_linkedEvent_Delete"] | undefined
};
	["TicketingFields_linkedEvent_Create"]: {
	__typename: "TicketingFields_linkedEvent_Create",
	permission: boolean
};
	["TicketingFields_linkedEvent_Read"]: {
	__typename: "TicketingFields_linkedEvent_Read",
	permission: boolean
};
	["TicketingFields_linkedEvent_Update"]: {
	__typename: "TicketingFields_linkedEvent_Update",
	permission: boolean
};
	["TicketingFields_linkedEvent_Delete"]: {
	__typename: "TicketingFields_linkedEvent_Delete",
	permission: boolean
};
	["TicketingFields_CheckInLevel"]: {
	__typename: "TicketingFields_CheckInLevel",
	create?: GraphQLTypes["TicketingFields_CheckInLevel_Create"] | undefined,
	read?: GraphQLTypes["TicketingFields_CheckInLevel_Read"] | undefined,
	update?: GraphQLTypes["TicketingFields_CheckInLevel_Update"] | undefined,
	delete?: GraphQLTypes["TicketingFields_CheckInLevel_Delete"] | undefined
};
	["TicketingFields_CheckInLevel_Create"]: {
	__typename: "TicketingFields_CheckInLevel_Create",
	permission: boolean
};
	["TicketingFields_CheckInLevel_Read"]: {
	__typename: "TicketingFields_CheckInLevel_Read",
	permission: boolean
};
	["TicketingFields_CheckInLevel_Update"]: {
	__typename: "TicketingFields_CheckInLevel_Update",
	permission: boolean
};
	["TicketingFields_CheckInLevel_Delete"]: {
	__typename: "TicketingFields_CheckInLevel_Delete",
	permission: boolean
};
	["TicketingFields_displayPrice"]: {
	__typename: "TicketingFields_displayPrice",
	create?: GraphQLTypes["TicketingFields_displayPrice_Create"] | undefined,
	read?: GraphQLTypes["TicketingFields_displayPrice_Read"] | undefined,
	update?: GraphQLTypes["TicketingFields_displayPrice_Update"] | undefined,
	delete?: GraphQLTypes["TicketingFields_displayPrice_Delete"] | undefined
};
	["TicketingFields_displayPrice_Create"]: {
	__typename: "TicketingFields_displayPrice_Create",
	permission: boolean
};
	["TicketingFields_displayPrice_Read"]: {
	__typename: "TicketingFields_displayPrice_Read",
	permission: boolean
};
	["TicketingFields_displayPrice_Update"]: {
	__typename: "TicketingFields_displayPrice_Update",
	permission: boolean
};
	["TicketingFields_displayPrice_Delete"]: {
	__typename: "TicketingFields_displayPrice_Delete",
	permission: boolean
};
	["TicketingFields_tnc"]: {
	__typename: "TicketingFields_tnc",
	create?: GraphQLTypes["TicketingFields_tnc_Create"] | undefined,
	read?: GraphQLTypes["TicketingFields_tnc_Read"] | undefined,
	update?: GraphQLTypes["TicketingFields_tnc_Update"] | undefined,
	delete?: GraphQLTypes["TicketingFields_tnc_Delete"] | undefined
};
	["TicketingFields_tnc_Create"]: {
	__typename: "TicketingFields_tnc_Create",
	permission: boolean
};
	["TicketingFields_tnc_Read"]: {
	__typename: "TicketingFields_tnc_Read",
	permission: boolean
};
	["TicketingFields_tnc_Update"]: {
	__typename: "TicketingFields_tnc_Update",
	permission: boolean
};
	["TicketingFields_tnc_Delete"]: {
	__typename: "TicketingFields_tnc_Delete",
	permission: boolean
};
	["TicketingFields_cutoffHours"]: {
	__typename: "TicketingFields_cutoffHours",
	create?: GraphQLTypes["TicketingFields_cutoffHours_Create"] | undefined,
	read?: GraphQLTypes["TicketingFields_cutoffHours_Read"] | undefined,
	update?: GraphQLTypes["TicketingFields_cutoffHours_Update"] | undefined,
	delete?: GraphQLTypes["TicketingFields_cutoffHours_Delete"] | undefined
};
	["TicketingFields_cutoffHours_Create"]: {
	__typename: "TicketingFields_cutoffHours_Create",
	permission: boolean
};
	["TicketingFields_cutoffHours_Read"]: {
	__typename: "TicketingFields_cutoffHours_Read",
	permission: boolean
};
	["TicketingFields_cutoffHours_Update"]: {
	__typename: "TicketingFields_cutoffHours_Update",
	permission: boolean
};
	["TicketingFields_cutoffHours_Delete"]: {
	__typename: "TicketingFields_cutoffHours_Delete",
	permission: boolean
};
	["TicketingFields_sessions"]: {
	__typename: "TicketingFields_sessions",
	create?: GraphQLTypes["TicketingFields_sessions_Create"] | undefined,
	read?: GraphQLTypes["TicketingFields_sessions_Read"] | undefined,
	update?: GraphQLTypes["TicketingFields_sessions_Update"] | undefined,
	delete?: GraphQLTypes["TicketingFields_sessions_Delete"] | undefined,
	fields?: GraphQLTypes["TicketingFields_sessions_Fields"] | undefined
};
	["TicketingFields_sessions_Create"]: {
	__typename: "TicketingFields_sessions_Create",
	permission: boolean
};
	["TicketingFields_sessions_Read"]: {
	__typename: "TicketingFields_sessions_Read",
	permission: boolean
};
	["TicketingFields_sessions_Update"]: {
	__typename: "TicketingFields_sessions_Update",
	permission: boolean
};
	["TicketingFields_sessions_Delete"]: {
	__typename: "TicketingFields_sessions_Delete",
	permission: boolean
};
	["TicketingFields_sessions_Fields"]: {
	__typename: "TicketingFields_sessions_Fields",
	productId?: GraphQLTypes["TicketingFields_sessions_productId"] | undefined,
	startDate?: GraphQLTypes["TicketingFields_sessions_startDate"] | undefined,
	endDate?: GraphQLTypes["TicketingFields_sessions_endDate"] | undefined,
	inventories?: GraphQLTypes["TicketingFields_sessions_inventories"] | undefined,
	id?: GraphQLTypes["TicketingFields_sessions_id"] | undefined
};
	["TicketingFields_sessions_productId"]: {
	__typename: "TicketingFields_sessions_productId",
	create?: GraphQLTypes["TicketingFields_sessions_productId_Create"] | undefined,
	read?: GraphQLTypes["TicketingFields_sessions_productId_Read"] | undefined,
	update?: GraphQLTypes["TicketingFields_sessions_productId_Update"] | undefined,
	delete?: GraphQLTypes["TicketingFields_sessions_productId_Delete"] | undefined
};
	["TicketingFields_sessions_productId_Create"]: {
	__typename: "TicketingFields_sessions_productId_Create",
	permission: boolean
};
	["TicketingFields_sessions_productId_Read"]: {
	__typename: "TicketingFields_sessions_productId_Read",
	permission: boolean
};
	["TicketingFields_sessions_productId_Update"]: {
	__typename: "TicketingFields_sessions_productId_Update",
	permission: boolean
};
	["TicketingFields_sessions_productId_Delete"]: {
	__typename: "TicketingFields_sessions_productId_Delete",
	permission: boolean
};
	["TicketingFields_sessions_startDate"]: {
	__typename: "TicketingFields_sessions_startDate",
	create?: GraphQLTypes["TicketingFields_sessions_startDate_Create"] | undefined,
	read?: GraphQLTypes["TicketingFields_sessions_startDate_Read"] | undefined,
	update?: GraphQLTypes["TicketingFields_sessions_startDate_Update"] | undefined,
	delete?: GraphQLTypes["TicketingFields_sessions_startDate_Delete"] | undefined
};
	["TicketingFields_sessions_startDate_Create"]: {
	__typename: "TicketingFields_sessions_startDate_Create",
	permission: boolean
};
	["TicketingFields_sessions_startDate_Read"]: {
	__typename: "TicketingFields_sessions_startDate_Read",
	permission: boolean
};
	["TicketingFields_sessions_startDate_Update"]: {
	__typename: "TicketingFields_sessions_startDate_Update",
	permission: boolean
};
	["TicketingFields_sessions_startDate_Delete"]: {
	__typename: "TicketingFields_sessions_startDate_Delete",
	permission: boolean
};
	["TicketingFields_sessions_endDate"]: {
	__typename: "TicketingFields_sessions_endDate",
	create?: GraphQLTypes["TicketingFields_sessions_endDate_Create"] | undefined,
	read?: GraphQLTypes["TicketingFields_sessions_endDate_Read"] | undefined,
	update?: GraphQLTypes["TicketingFields_sessions_endDate_Update"] | undefined,
	delete?: GraphQLTypes["TicketingFields_sessions_endDate_Delete"] | undefined
};
	["TicketingFields_sessions_endDate_Create"]: {
	__typename: "TicketingFields_sessions_endDate_Create",
	permission: boolean
};
	["TicketingFields_sessions_endDate_Read"]: {
	__typename: "TicketingFields_sessions_endDate_Read",
	permission: boolean
};
	["TicketingFields_sessions_endDate_Update"]: {
	__typename: "TicketingFields_sessions_endDate_Update",
	permission: boolean
};
	["TicketingFields_sessions_endDate_Delete"]: {
	__typename: "TicketingFields_sessions_endDate_Delete",
	permission: boolean
};
	["TicketingFields_sessions_inventories"]: {
	__typename: "TicketingFields_sessions_inventories",
	create?: GraphQLTypes["TicketingFields_sessions_inventories_Create"] | undefined,
	read?: GraphQLTypes["TicketingFields_sessions_inventories_Read"] | undefined,
	update?: GraphQLTypes["TicketingFields_sessions_inventories_Update"] | undefined,
	delete?: GraphQLTypes["TicketingFields_sessions_inventories_Delete"] | undefined,
	fields?: GraphQLTypes["TicketingFields_sessions_inventories_Fields"] | undefined
};
	["TicketingFields_sessions_inventories_Create"]: {
	__typename: "TicketingFields_sessions_inventories_Create",
	permission: boolean
};
	["TicketingFields_sessions_inventories_Read"]: {
	__typename: "TicketingFields_sessions_inventories_Read",
	permission: boolean
};
	["TicketingFields_sessions_inventories_Update"]: {
	__typename: "TicketingFields_sessions_inventories_Update",
	permission: boolean
};
	["TicketingFields_sessions_inventories_Delete"]: {
	__typename: "TicketingFields_sessions_inventories_Delete",
	permission: boolean
};
	["TicketingFields_sessions_inventories_Fields"]: {
	__typename: "TicketingFields_sessions_inventories_Fields",
	name?: GraphQLTypes["TicketingFields_sessions_inventories_name"] | undefined,
	quantity?: GraphQLTypes["TicketingFields_sessions_inventories_quantity"] | undefined,
	unitPrice?: GraphQLTypes["TicketingFields_sessions_inventories_unitPrice"] | undefined,
	handlingFee?: GraphQLTypes["TicketingFields_sessions_inventories_handlingFee"] | undefined,
	totalCost?: GraphQLTypes["TicketingFields_sessions_inventories_totalCost"] | undefined,
	minPurchase?: GraphQLTypes["TicketingFields_sessions_inventories_minPurchase"] | undefined,
	maxPurchase?: GraphQLTypes["TicketingFields_sessions_inventories_maxPurchase"] | undefined,
	stocks?: GraphQLTypes["TicketingFields_sessions_inventories_stocks"] | undefined,
	remarks?: GraphQLTypes["TicketingFields_sessions_inventories_remarks"] | undefined
};
	["TicketingFields_sessions_inventories_name"]: {
	__typename: "TicketingFields_sessions_inventories_name",
	create?: GraphQLTypes["TicketingFields_sessions_inventories_name_Create"] | undefined,
	read?: GraphQLTypes["TicketingFields_sessions_inventories_name_Read"] | undefined,
	update?: GraphQLTypes["TicketingFields_sessions_inventories_name_Update"] | undefined,
	delete?: GraphQLTypes["TicketingFields_sessions_inventories_name_Delete"] | undefined
};
	["TicketingFields_sessions_inventories_name_Create"]: {
	__typename: "TicketingFields_sessions_inventories_name_Create",
	permission: boolean
};
	["TicketingFields_sessions_inventories_name_Read"]: {
	__typename: "TicketingFields_sessions_inventories_name_Read",
	permission: boolean
};
	["TicketingFields_sessions_inventories_name_Update"]: {
	__typename: "TicketingFields_sessions_inventories_name_Update",
	permission: boolean
};
	["TicketingFields_sessions_inventories_name_Delete"]: {
	__typename: "TicketingFields_sessions_inventories_name_Delete",
	permission: boolean
};
	["TicketingFields_sessions_inventories_quantity"]: {
	__typename: "TicketingFields_sessions_inventories_quantity",
	create?: GraphQLTypes["TicketingFields_sessions_inventories_quantity_Create"] | undefined,
	read?: GraphQLTypes["TicketingFields_sessions_inventories_quantity_Read"] | undefined,
	update?: GraphQLTypes["TicketingFields_sessions_inventories_quantity_Update"] | undefined,
	delete?: GraphQLTypes["TicketingFields_sessions_inventories_quantity_Delete"] | undefined
};
	["TicketingFields_sessions_inventories_quantity_Create"]: {
	__typename: "TicketingFields_sessions_inventories_quantity_Create",
	permission: boolean
};
	["TicketingFields_sessions_inventories_quantity_Read"]: {
	__typename: "TicketingFields_sessions_inventories_quantity_Read",
	permission: boolean
};
	["TicketingFields_sessions_inventories_quantity_Update"]: {
	__typename: "TicketingFields_sessions_inventories_quantity_Update",
	permission: boolean
};
	["TicketingFields_sessions_inventories_quantity_Delete"]: {
	__typename: "TicketingFields_sessions_inventories_quantity_Delete",
	permission: boolean
};
	["TicketingFields_sessions_inventories_unitPrice"]: {
	__typename: "TicketingFields_sessions_inventories_unitPrice",
	create?: GraphQLTypes["TicketingFields_sessions_inventories_unitPrice_Create"] | undefined,
	read?: GraphQLTypes["TicketingFields_sessions_inventories_unitPrice_Read"] | undefined,
	update?: GraphQLTypes["TicketingFields_sessions_inventories_unitPrice_Update"] | undefined,
	delete?: GraphQLTypes["TicketingFields_sessions_inventories_unitPrice_Delete"] | undefined
};
	["TicketingFields_sessions_inventories_unitPrice_Create"]: {
	__typename: "TicketingFields_sessions_inventories_unitPrice_Create",
	permission: boolean
};
	["TicketingFields_sessions_inventories_unitPrice_Read"]: {
	__typename: "TicketingFields_sessions_inventories_unitPrice_Read",
	permission: boolean
};
	["TicketingFields_sessions_inventories_unitPrice_Update"]: {
	__typename: "TicketingFields_sessions_inventories_unitPrice_Update",
	permission: boolean
};
	["TicketingFields_sessions_inventories_unitPrice_Delete"]: {
	__typename: "TicketingFields_sessions_inventories_unitPrice_Delete",
	permission: boolean
};
	["TicketingFields_sessions_inventories_handlingFee"]: {
	__typename: "TicketingFields_sessions_inventories_handlingFee",
	create?: GraphQLTypes["TicketingFields_sessions_inventories_handlingFee_Create"] | undefined,
	read?: GraphQLTypes["TicketingFields_sessions_inventories_handlingFee_Read"] | undefined,
	update?: GraphQLTypes["TicketingFields_sessions_inventories_handlingFee_Update"] | undefined,
	delete?: GraphQLTypes["TicketingFields_sessions_inventories_handlingFee_Delete"] | undefined
};
	["TicketingFields_sessions_inventories_handlingFee_Create"]: {
	__typename: "TicketingFields_sessions_inventories_handlingFee_Create",
	permission: boolean
};
	["TicketingFields_sessions_inventories_handlingFee_Read"]: {
	__typename: "TicketingFields_sessions_inventories_handlingFee_Read",
	permission: boolean
};
	["TicketingFields_sessions_inventories_handlingFee_Update"]: {
	__typename: "TicketingFields_sessions_inventories_handlingFee_Update",
	permission: boolean
};
	["TicketingFields_sessions_inventories_handlingFee_Delete"]: {
	__typename: "TicketingFields_sessions_inventories_handlingFee_Delete",
	permission: boolean
};
	["TicketingFields_sessions_inventories_totalCost"]: {
	__typename: "TicketingFields_sessions_inventories_totalCost",
	create?: GraphQLTypes["TicketingFields_sessions_inventories_totalCost_Create"] | undefined,
	read?: GraphQLTypes["TicketingFields_sessions_inventories_totalCost_Read"] | undefined,
	update?: GraphQLTypes["TicketingFields_sessions_inventories_totalCost_Update"] | undefined,
	delete?: GraphQLTypes["TicketingFields_sessions_inventories_totalCost_Delete"] | undefined
};
	["TicketingFields_sessions_inventories_totalCost_Create"]: {
	__typename: "TicketingFields_sessions_inventories_totalCost_Create",
	permission: boolean
};
	["TicketingFields_sessions_inventories_totalCost_Read"]: {
	__typename: "TicketingFields_sessions_inventories_totalCost_Read",
	permission: boolean
};
	["TicketingFields_sessions_inventories_totalCost_Update"]: {
	__typename: "TicketingFields_sessions_inventories_totalCost_Update",
	permission: boolean
};
	["TicketingFields_sessions_inventories_totalCost_Delete"]: {
	__typename: "TicketingFields_sessions_inventories_totalCost_Delete",
	permission: boolean
};
	["TicketingFields_sessions_inventories_minPurchase"]: {
	__typename: "TicketingFields_sessions_inventories_minPurchase",
	create?: GraphQLTypes["TicketingFields_sessions_inventories_minPurchase_Create"] | undefined,
	read?: GraphQLTypes["TicketingFields_sessions_inventories_minPurchase_Read"] | undefined,
	update?: GraphQLTypes["TicketingFields_sessions_inventories_minPurchase_Update"] | undefined,
	delete?: GraphQLTypes["TicketingFields_sessions_inventories_minPurchase_Delete"] | undefined
};
	["TicketingFields_sessions_inventories_minPurchase_Create"]: {
	__typename: "TicketingFields_sessions_inventories_minPurchase_Create",
	permission: boolean
};
	["TicketingFields_sessions_inventories_minPurchase_Read"]: {
	__typename: "TicketingFields_sessions_inventories_minPurchase_Read",
	permission: boolean
};
	["TicketingFields_sessions_inventories_minPurchase_Update"]: {
	__typename: "TicketingFields_sessions_inventories_minPurchase_Update",
	permission: boolean
};
	["TicketingFields_sessions_inventories_minPurchase_Delete"]: {
	__typename: "TicketingFields_sessions_inventories_minPurchase_Delete",
	permission: boolean
};
	["TicketingFields_sessions_inventories_maxPurchase"]: {
	__typename: "TicketingFields_sessions_inventories_maxPurchase",
	create?: GraphQLTypes["TicketingFields_sessions_inventories_maxPurchase_Create"] | undefined,
	read?: GraphQLTypes["TicketingFields_sessions_inventories_maxPurchase_Read"] | undefined,
	update?: GraphQLTypes["TicketingFields_sessions_inventories_maxPurchase_Update"] | undefined,
	delete?: GraphQLTypes["TicketingFields_sessions_inventories_maxPurchase_Delete"] | undefined
};
	["TicketingFields_sessions_inventories_maxPurchase_Create"]: {
	__typename: "TicketingFields_sessions_inventories_maxPurchase_Create",
	permission: boolean
};
	["TicketingFields_sessions_inventories_maxPurchase_Read"]: {
	__typename: "TicketingFields_sessions_inventories_maxPurchase_Read",
	permission: boolean
};
	["TicketingFields_sessions_inventories_maxPurchase_Update"]: {
	__typename: "TicketingFields_sessions_inventories_maxPurchase_Update",
	permission: boolean
};
	["TicketingFields_sessions_inventories_maxPurchase_Delete"]: {
	__typename: "TicketingFields_sessions_inventories_maxPurchase_Delete",
	permission: boolean
};
	["TicketingFields_sessions_inventories_stocks"]: {
	__typename: "TicketingFields_sessions_inventories_stocks",
	create?: GraphQLTypes["TicketingFields_sessions_inventories_stocks_Create"] | undefined,
	read?: GraphQLTypes["TicketingFields_sessions_inventories_stocks_Read"] | undefined,
	update?: GraphQLTypes["TicketingFields_sessions_inventories_stocks_Update"] | undefined,
	delete?: GraphQLTypes["TicketingFields_sessions_inventories_stocks_Delete"] | undefined,
	fields?: GraphQLTypes["TicketingFields_sessions_inventories_stocks_Fields"] | undefined
};
	["TicketingFields_sessions_inventories_stocks_Create"]: {
	__typename: "TicketingFields_sessions_inventories_stocks_Create",
	permission: boolean
};
	["TicketingFields_sessions_inventories_stocks_Read"]: {
	__typename: "TicketingFields_sessions_inventories_stocks_Read",
	permission: boolean
};
	["TicketingFields_sessions_inventories_stocks_Update"]: {
	__typename: "TicketingFields_sessions_inventories_stocks_Update",
	permission: boolean
};
	["TicketingFields_sessions_inventories_stocks_Delete"]: {
	__typename: "TicketingFields_sessions_inventories_stocks_Delete",
	permission: boolean
};
	["TicketingFields_sessions_inventories_stocks_Fields"]: {
	__typename: "TicketingFields_sessions_inventories_stocks_Fields",
	stockId?: GraphQLTypes["TicketingFields_sessions_inventories_stocks_stockId"] | undefined,
	remaining?: GraphQLTypes["TicketingFields_sessions_inventories_stocks_remaining"] | undefined,
	sold?: GraphQLTypes["TicketingFields_sessions_inventories_stocks_sold"] | undefined,
	onHold?: GraphQLTypes["TicketingFields_sessions_inventories_stocks_onHold"] | undefined,
	checkedIn?: GraphQLTypes["TicketingFields_sessions_inventories_stocks_checkedIn"] | undefined
};
	["TicketingFields_sessions_inventories_stocks_stockId"]: {
	__typename: "TicketingFields_sessions_inventories_stocks_stockId",
	create?: GraphQLTypes["TicketingFields_sessions_inventories_stocks_stockId_Create"] | undefined,
	read?: GraphQLTypes["TicketingFields_sessions_inventories_stocks_stockId_Read"] | undefined,
	update?: GraphQLTypes["TicketingFields_sessions_inventories_stocks_stockId_Update"] | undefined,
	delete?: GraphQLTypes["TicketingFields_sessions_inventories_stocks_stockId_Delete"] | undefined
};
	["TicketingFields_sessions_inventories_stocks_stockId_Create"]: {
	__typename: "TicketingFields_sessions_inventories_stocks_stockId_Create",
	permission: boolean
};
	["TicketingFields_sessions_inventories_stocks_stockId_Read"]: {
	__typename: "TicketingFields_sessions_inventories_stocks_stockId_Read",
	permission: boolean
};
	["TicketingFields_sessions_inventories_stocks_stockId_Update"]: {
	__typename: "TicketingFields_sessions_inventories_stocks_stockId_Update",
	permission: boolean
};
	["TicketingFields_sessions_inventories_stocks_stockId_Delete"]: {
	__typename: "TicketingFields_sessions_inventories_stocks_stockId_Delete",
	permission: boolean
};
	["TicketingFields_sessions_inventories_stocks_remaining"]: {
	__typename: "TicketingFields_sessions_inventories_stocks_remaining",
	create?: GraphQLTypes["TicketingFields_sessions_inventories_stocks_remaining_Create"] | undefined,
	read?: GraphQLTypes["TicketingFields_sessions_inventories_stocks_remaining_Read"] | undefined,
	update?: GraphQLTypes["TicketingFields_sessions_inventories_stocks_remaining_Update"] | undefined,
	delete?: GraphQLTypes["TicketingFields_sessions_inventories_stocks_remaining_Delete"] | undefined
};
	["TicketingFields_sessions_inventories_stocks_remaining_Create"]: {
	__typename: "TicketingFields_sessions_inventories_stocks_remaining_Create",
	permission: boolean
};
	["TicketingFields_sessions_inventories_stocks_remaining_Read"]: {
	__typename: "TicketingFields_sessions_inventories_stocks_remaining_Read",
	permission: boolean
};
	["TicketingFields_sessions_inventories_stocks_remaining_Update"]: {
	__typename: "TicketingFields_sessions_inventories_stocks_remaining_Update",
	permission: boolean
};
	["TicketingFields_sessions_inventories_stocks_remaining_Delete"]: {
	__typename: "TicketingFields_sessions_inventories_stocks_remaining_Delete",
	permission: boolean
};
	["TicketingFields_sessions_inventories_stocks_sold"]: {
	__typename: "TicketingFields_sessions_inventories_stocks_sold",
	create?: GraphQLTypes["TicketingFields_sessions_inventories_stocks_sold_Create"] | undefined,
	read?: GraphQLTypes["TicketingFields_sessions_inventories_stocks_sold_Read"] | undefined,
	update?: GraphQLTypes["TicketingFields_sessions_inventories_stocks_sold_Update"] | undefined,
	delete?: GraphQLTypes["TicketingFields_sessions_inventories_stocks_sold_Delete"] | undefined
};
	["TicketingFields_sessions_inventories_stocks_sold_Create"]: {
	__typename: "TicketingFields_sessions_inventories_stocks_sold_Create",
	permission: boolean
};
	["TicketingFields_sessions_inventories_stocks_sold_Read"]: {
	__typename: "TicketingFields_sessions_inventories_stocks_sold_Read",
	permission: boolean
};
	["TicketingFields_sessions_inventories_stocks_sold_Update"]: {
	__typename: "TicketingFields_sessions_inventories_stocks_sold_Update",
	permission: boolean
};
	["TicketingFields_sessions_inventories_stocks_sold_Delete"]: {
	__typename: "TicketingFields_sessions_inventories_stocks_sold_Delete",
	permission: boolean
};
	["TicketingFields_sessions_inventories_stocks_onHold"]: {
	__typename: "TicketingFields_sessions_inventories_stocks_onHold",
	create?: GraphQLTypes["TicketingFields_sessions_inventories_stocks_onHold_Create"] | undefined,
	read?: GraphQLTypes["TicketingFields_sessions_inventories_stocks_onHold_Read"] | undefined,
	update?: GraphQLTypes["TicketingFields_sessions_inventories_stocks_onHold_Update"] | undefined,
	delete?: GraphQLTypes["TicketingFields_sessions_inventories_stocks_onHold_Delete"] | undefined
};
	["TicketingFields_sessions_inventories_stocks_onHold_Create"]: {
	__typename: "TicketingFields_sessions_inventories_stocks_onHold_Create",
	permission: boolean
};
	["TicketingFields_sessions_inventories_stocks_onHold_Read"]: {
	__typename: "TicketingFields_sessions_inventories_stocks_onHold_Read",
	permission: boolean
};
	["TicketingFields_sessions_inventories_stocks_onHold_Update"]: {
	__typename: "TicketingFields_sessions_inventories_stocks_onHold_Update",
	permission: boolean
};
	["TicketingFields_sessions_inventories_stocks_onHold_Delete"]: {
	__typename: "TicketingFields_sessions_inventories_stocks_onHold_Delete",
	permission: boolean
};
	["TicketingFields_sessions_inventories_stocks_checkedIn"]: {
	__typename: "TicketingFields_sessions_inventories_stocks_checkedIn",
	create?: GraphQLTypes["TicketingFields_sessions_inventories_stocks_checkedIn_Create"] | undefined,
	read?: GraphQLTypes["TicketingFields_sessions_inventories_stocks_checkedIn_Read"] | undefined,
	update?: GraphQLTypes["TicketingFields_sessions_inventories_stocks_checkedIn_Update"] | undefined,
	delete?: GraphQLTypes["TicketingFields_sessions_inventories_stocks_checkedIn_Delete"] | undefined
};
	["TicketingFields_sessions_inventories_stocks_checkedIn_Create"]: {
	__typename: "TicketingFields_sessions_inventories_stocks_checkedIn_Create",
	permission: boolean
};
	["TicketingFields_sessions_inventories_stocks_checkedIn_Read"]: {
	__typename: "TicketingFields_sessions_inventories_stocks_checkedIn_Read",
	permission: boolean
};
	["TicketingFields_sessions_inventories_stocks_checkedIn_Update"]: {
	__typename: "TicketingFields_sessions_inventories_stocks_checkedIn_Update",
	permission: boolean
};
	["TicketingFields_sessions_inventories_stocks_checkedIn_Delete"]: {
	__typename: "TicketingFields_sessions_inventories_stocks_checkedIn_Delete",
	permission: boolean
};
	["TicketingFields_sessions_inventories_remarks"]: {
	__typename: "TicketingFields_sessions_inventories_remarks",
	create?: GraphQLTypes["TicketingFields_sessions_inventories_remarks_Create"] | undefined,
	read?: GraphQLTypes["TicketingFields_sessions_inventories_remarks_Read"] | undefined,
	update?: GraphQLTypes["TicketingFields_sessions_inventories_remarks_Update"] | undefined,
	delete?: GraphQLTypes["TicketingFields_sessions_inventories_remarks_Delete"] | undefined
};
	["TicketingFields_sessions_inventories_remarks_Create"]: {
	__typename: "TicketingFields_sessions_inventories_remarks_Create",
	permission: boolean
};
	["TicketingFields_sessions_inventories_remarks_Read"]: {
	__typename: "TicketingFields_sessions_inventories_remarks_Read",
	permission: boolean
};
	["TicketingFields_sessions_inventories_remarks_Update"]: {
	__typename: "TicketingFields_sessions_inventories_remarks_Update",
	permission: boolean
};
	["TicketingFields_sessions_inventories_remarks_Delete"]: {
	__typename: "TicketingFields_sessions_inventories_remarks_Delete",
	permission: boolean
};
	["TicketingFields_sessions_id"]: {
	__typename: "TicketingFields_sessions_id",
	create?: GraphQLTypes["TicketingFields_sessions_id_Create"] | undefined,
	read?: GraphQLTypes["TicketingFields_sessions_id_Read"] | undefined,
	update?: GraphQLTypes["TicketingFields_sessions_id_Update"] | undefined,
	delete?: GraphQLTypes["TicketingFields_sessions_id_Delete"] | undefined
};
	["TicketingFields_sessions_id_Create"]: {
	__typename: "TicketingFields_sessions_id_Create",
	permission: boolean
};
	["TicketingFields_sessions_id_Read"]: {
	__typename: "TicketingFields_sessions_id_Read",
	permission: boolean
};
	["TicketingFields_sessions_id_Update"]: {
	__typename: "TicketingFields_sessions_id_Update",
	permission: boolean
};
	["TicketingFields_sessions_id_Delete"]: {
	__typename: "TicketingFields_sessions_id_Delete",
	permission: boolean
};
	["TicketingFields_updatedAt"]: {
	__typename: "TicketingFields_updatedAt",
	create?: GraphQLTypes["TicketingFields_updatedAt_Create"] | undefined,
	read?: GraphQLTypes["TicketingFields_updatedAt_Read"] | undefined,
	update?: GraphQLTypes["TicketingFields_updatedAt_Update"] | undefined,
	delete?: GraphQLTypes["TicketingFields_updatedAt_Delete"] | undefined
};
	["TicketingFields_updatedAt_Create"]: {
	__typename: "TicketingFields_updatedAt_Create",
	permission: boolean
};
	["TicketingFields_updatedAt_Read"]: {
	__typename: "TicketingFields_updatedAt_Read",
	permission: boolean
};
	["TicketingFields_updatedAt_Update"]: {
	__typename: "TicketingFields_updatedAt_Update",
	permission: boolean
};
	["TicketingFields_updatedAt_Delete"]: {
	__typename: "TicketingFields_updatedAt_Delete",
	permission: boolean
};
	["TicketingFields_createdAt"]: {
	__typename: "TicketingFields_createdAt",
	create?: GraphQLTypes["TicketingFields_createdAt_Create"] | undefined,
	read?: GraphQLTypes["TicketingFields_createdAt_Read"] | undefined,
	update?: GraphQLTypes["TicketingFields_createdAt_Update"] | undefined,
	delete?: GraphQLTypes["TicketingFields_createdAt_Delete"] | undefined
};
	["TicketingFields_createdAt_Create"]: {
	__typename: "TicketingFields_createdAt_Create",
	permission: boolean
};
	["TicketingFields_createdAt_Read"]: {
	__typename: "TicketingFields_createdAt_Read",
	permission: boolean
};
	["TicketingFields_createdAt_Update"]: {
	__typename: "TicketingFields_createdAt_Update",
	permission: boolean
};
	["TicketingFields_createdAt_Delete"]: {
	__typename: "TicketingFields_createdAt_Delete",
	permission: boolean
};
	["TicketingCreateAccess"]: {
	__typename: "TicketingCreateAccess",
	permission: boolean,
	where?: GraphQLTypes["JSONObject"] | undefined
};
	["TicketingReadAccess"]: {
	__typename: "TicketingReadAccess",
	permission: boolean,
	where?: GraphQLTypes["JSONObject"] | undefined
};
	["TicketingUpdateAccess"]: {
	__typename: "TicketingUpdateAccess",
	permission: boolean,
	where?: GraphQLTypes["JSONObject"] | undefined
};
	["TicketingDeleteAccess"]: {
	__typename: "TicketingDeleteAccess",
	permission: boolean,
	where?: GraphQLTypes["JSONObject"] | undefined
};
	["productsAccess"]: {
	__typename: "productsAccess",
	fields?: GraphQLTypes["ProductsFields"] | undefined,
	create?: GraphQLTypes["ProductsCreateAccess"] | undefined,
	read?: GraphQLTypes["ProductsReadAccess"] | undefined,
	update?: GraphQLTypes["ProductsUpdateAccess"] | undefined,
	delete?: GraphQLTypes["ProductsDeleteAccess"] | undefined
};
	["ProductsFields"]: {
	__typename: "ProductsFields",
	status?: GraphQLTypes["ProductsFields_status"] | undefined,
	creator?: GraphQLTypes["ProductsFields_creator"] | undefined,
	productType?: GraphQLTypes["ProductsFields_productType"] | undefined,
	productName?: GraphQLTypes["ProductsFields_productName"] | undefined,
	referenceId?: GraphQLTypes["ProductsFields_referenceId"] | undefined,
	linkedEvent?: GraphQLTypes["ProductsFields_linkedEvent"] | undefined,
	CheckInLevel?: GraphQLTypes["ProductsFields_CheckInLevel"] | undefined,
	thumbnail?: GraphQLTypes["ProductsFields_thumbnail"] | undefined,
	displayPrice?: GraphQLTypes["ProductsFields_displayPrice"] | undefined,
	transactionType?: GraphQLTypes["ProductsFields_transactionType"] | undefined,
	website?: GraphQLTypes["ProductsFields_website"] | undefined,
	tnc?: GraphQLTypes["ProductsFields_tnc"] | undefined,
	remarks?: GraphQLTypes["ProductsFields_remarks"] | undefined,
	sessions?: GraphQLTypes["ProductsFields_sessions"] | undefined,
	updatedAt?: GraphQLTypes["ProductsFields_updatedAt"] | undefined,
	createdAt?: GraphQLTypes["ProductsFields_createdAt"] | undefined
};
	["ProductsFields_status"]: {
	__typename: "ProductsFields_status",
	create?: GraphQLTypes["ProductsFields_status_Create"] | undefined,
	read?: GraphQLTypes["ProductsFields_status_Read"] | undefined,
	update?: GraphQLTypes["ProductsFields_status_Update"] | undefined,
	delete?: GraphQLTypes["ProductsFields_status_Delete"] | undefined
};
	["ProductsFields_status_Create"]: {
	__typename: "ProductsFields_status_Create",
	permission: boolean
};
	["ProductsFields_status_Read"]: {
	__typename: "ProductsFields_status_Read",
	permission: boolean
};
	["ProductsFields_status_Update"]: {
	__typename: "ProductsFields_status_Update",
	permission: boolean
};
	["ProductsFields_status_Delete"]: {
	__typename: "ProductsFields_status_Delete",
	permission: boolean
};
	["ProductsFields_creator"]: {
	__typename: "ProductsFields_creator",
	create?: GraphQLTypes["ProductsFields_creator_Create"] | undefined,
	read?: GraphQLTypes["ProductsFields_creator_Read"] | undefined,
	update?: GraphQLTypes["ProductsFields_creator_Update"] | undefined,
	delete?: GraphQLTypes["ProductsFields_creator_Delete"] | undefined
};
	["ProductsFields_creator_Create"]: {
	__typename: "ProductsFields_creator_Create",
	permission: boolean
};
	["ProductsFields_creator_Read"]: {
	__typename: "ProductsFields_creator_Read",
	permission: boolean
};
	["ProductsFields_creator_Update"]: {
	__typename: "ProductsFields_creator_Update",
	permission: boolean
};
	["ProductsFields_creator_Delete"]: {
	__typename: "ProductsFields_creator_Delete",
	permission: boolean
};
	["ProductsFields_productType"]: {
	__typename: "ProductsFields_productType",
	create?: GraphQLTypes["ProductsFields_productType_Create"] | undefined,
	read?: GraphQLTypes["ProductsFields_productType_Read"] | undefined,
	update?: GraphQLTypes["ProductsFields_productType_Update"] | undefined,
	delete?: GraphQLTypes["ProductsFields_productType_Delete"] | undefined
};
	["ProductsFields_productType_Create"]: {
	__typename: "ProductsFields_productType_Create",
	permission: boolean
};
	["ProductsFields_productType_Read"]: {
	__typename: "ProductsFields_productType_Read",
	permission: boolean
};
	["ProductsFields_productType_Update"]: {
	__typename: "ProductsFields_productType_Update",
	permission: boolean
};
	["ProductsFields_productType_Delete"]: {
	__typename: "ProductsFields_productType_Delete",
	permission: boolean
};
	["ProductsFields_productName"]: {
	__typename: "ProductsFields_productName",
	create?: GraphQLTypes["ProductsFields_productName_Create"] | undefined,
	read?: GraphQLTypes["ProductsFields_productName_Read"] | undefined,
	update?: GraphQLTypes["ProductsFields_productName_Update"] | undefined,
	delete?: GraphQLTypes["ProductsFields_productName_Delete"] | undefined
};
	["ProductsFields_productName_Create"]: {
	__typename: "ProductsFields_productName_Create",
	permission: boolean
};
	["ProductsFields_productName_Read"]: {
	__typename: "ProductsFields_productName_Read",
	permission: boolean
};
	["ProductsFields_productName_Update"]: {
	__typename: "ProductsFields_productName_Update",
	permission: boolean
};
	["ProductsFields_productName_Delete"]: {
	__typename: "ProductsFields_productName_Delete",
	permission: boolean
};
	["ProductsFields_referenceId"]: {
	__typename: "ProductsFields_referenceId",
	create?: GraphQLTypes["ProductsFields_referenceId_Create"] | undefined,
	read?: GraphQLTypes["ProductsFields_referenceId_Read"] | undefined,
	update?: GraphQLTypes["ProductsFields_referenceId_Update"] | undefined,
	delete?: GraphQLTypes["ProductsFields_referenceId_Delete"] | undefined
};
	["ProductsFields_referenceId_Create"]: {
	__typename: "ProductsFields_referenceId_Create",
	permission: boolean
};
	["ProductsFields_referenceId_Read"]: {
	__typename: "ProductsFields_referenceId_Read",
	permission: boolean
};
	["ProductsFields_referenceId_Update"]: {
	__typename: "ProductsFields_referenceId_Update",
	permission: boolean
};
	["ProductsFields_referenceId_Delete"]: {
	__typename: "ProductsFields_referenceId_Delete",
	permission: boolean
};
	["ProductsFields_linkedEvent"]: {
	__typename: "ProductsFields_linkedEvent",
	create?: GraphQLTypes["ProductsFields_linkedEvent_Create"] | undefined,
	read?: GraphQLTypes["ProductsFields_linkedEvent_Read"] | undefined,
	update?: GraphQLTypes["ProductsFields_linkedEvent_Update"] | undefined,
	delete?: GraphQLTypes["ProductsFields_linkedEvent_Delete"] | undefined
};
	["ProductsFields_linkedEvent_Create"]: {
	__typename: "ProductsFields_linkedEvent_Create",
	permission: boolean
};
	["ProductsFields_linkedEvent_Read"]: {
	__typename: "ProductsFields_linkedEvent_Read",
	permission: boolean
};
	["ProductsFields_linkedEvent_Update"]: {
	__typename: "ProductsFields_linkedEvent_Update",
	permission: boolean
};
	["ProductsFields_linkedEvent_Delete"]: {
	__typename: "ProductsFields_linkedEvent_Delete",
	permission: boolean
};
	["ProductsFields_CheckInLevel"]: {
	__typename: "ProductsFields_CheckInLevel",
	create?: GraphQLTypes["ProductsFields_CheckInLevel_Create"] | undefined,
	read?: GraphQLTypes["ProductsFields_CheckInLevel_Read"] | undefined,
	update?: GraphQLTypes["ProductsFields_CheckInLevel_Update"] | undefined,
	delete?: GraphQLTypes["ProductsFields_CheckInLevel_Delete"] | undefined
};
	["ProductsFields_CheckInLevel_Create"]: {
	__typename: "ProductsFields_CheckInLevel_Create",
	permission: boolean
};
	["ProductsFields_CheckInLevel_Read"]: {
	__typename: "ProductsFields_CheckInLevel_Read",
	permission: boolean
};
	["ProductsFields_CheckInLevel_Update"]: {
	__typename: "ProductsFields_CheckInLevel_Update",
	permission: boolean
};
	["ProductsFields_CheckInLevel_Delete"]: {
	__typename: "ProductsFields_CheckInLevel_Delete",
	permission: boolean
};
	["ProductsFields_thumbnail"]: {
	__typename: "ProductsFields_thumbnail",
	create?: GraphQLTypes["ProductsFields_thumbnail_Create"] | undefined,
	read?: GraphQLTypes["ProductsFields_thumbnail_Read"] | undefined,
	update?: GraphQLTypes["ProductsFields_thumbnail_Update"] | undefined,
	delete?: GraphQLTypes["ProductsFields_thumbnail_Delete"] | undefined
};
	["ProductsFields_thumbnail_Create"]: {
	__typename: "ProductsFields_thumbnail_Create",
	permission: boolean
};
	["ProductsFields_thumbnail_Read"]: {
	__typename: "ProductsFields_thumbnail_Read",
	permission: boolean
};
	["ProductsFields_thumbnail_Update"]: {
	__typename: "ProductsFields_thumbnail_Update",
	permission: boolean
};
	["ProductsFields_thumbnail_Delete"]: {
	__typename: "ProductsFields_thumbnail_Delete",
	permission: boolean
};
	["ProductsFields_displayPrice"]: {
	__typename: "ProductsFields_displayPrice",
	create?: GraphQLTypes["ProductsFields_displayPrice_Create"] | undefined,
	read?: GraphQLTypes["ProductsFields_displayPrice_Read"] | undefined,
	update?: GraphQLTypes["ProductsFields_displayPrice_Update"] | undefined,
	delete?: GraphQLTypes["ProductsFields_displayPrice_Delete"] | undefined
};
	["ProductsFields_displayPrice_Create"]: {
	__typename: "ProductsFields_displayPrice_Create",
	permission: boolean
};
	["ProductsFields_displayPrice_Read"]: {
	__typename: "ProductsFields_displayPrice_Read",
	permission: boolean
};
	["ProductsFields_displayPrice_Update"]: {
	__typename: "ProductsFields_displayPrice_Update",
	permission: boolean
};
	["ProductsFields_displayPrice_Delete"]: {
	__typename: "ProductsFields_displayPrice_Delete",
	permission: boolean
};
	["ProductsFields_transactionType"]: {
	__typename: "ProductsFields_transactionType",
	create?: GraphQLTypes["ProductsFields_transactionType_Create"] | undefined,
	read?: GraphQLTypes["ProductsFields_transactionType_Read"] | undefined,
	update?: GraphQLTypes["ProductsFields_transactionType_Update"] | undefined,
	delete?: GraphQLTypes["ProductsFields_transactionType_Delete"] | undefined
};
	["ProductsFields_transactionType_Create"]: {
	__typename: "ProductsFields_transactionType_Create",
	permission: boolean
};
	["ProductsFields_transactionType_Read"]: {
	__typename: "ProductsFields_transactionType_Read",
	permission: boolean
};
	["ProductsFields_transactionType_Update"]: {
	__typename: "ProductsFields_transactionType_Update",
	permission: boolean
};
	["ProductsFields_transactionType_Delete"]: {
	__typename: "ProductsFields_transactionType_Delete",
	permission: boolean
};
	["ProductsFields_website"]: {
	__typename: "ProductsFields_website",
	create?: GraphQLTypes["ProductsFields_website_Create"] | undefined,
	read?: GraphQLTypes["ProductsFields_website_Read"] | undefined,
	update?: GraphQLTypes["ProductsFields_website_Update"] | undefined,
	delete?: GraphQLTypes["ProductsFields_website_Delete"] | undefined
};
	["ProductsFields_website_Create"]: {
	__typename: "ProductsFields_website_Create",
	permission: boolean
};
	["ProductsFields_website_Read"]: {
	__typename: "ProductsFields_website_Read",
	permission: boolean
};
	["ProductsFields_website_Update"]: {
	__typename: "ProductsFields_website_Update",
	permission: boolean
};
	["ProductsFields_website_Delete"]: {
	__typename: "ProductsFields_website_Delete",
	permission: boolean
};
	["ProductsFields_tnc"]: {
	__typename: "ProductsFields_tnc",
	create?: GraphQLTypes["ProductsFields_tnc_Create"] | undefined,
	read?: GraphQLTypes["ProductsFields_tnc_Read"] | undefined,
	update?: GraphQLTypes["ProductsFields_tnc_Update"] | undefined,
	delete?: GraphQLTypes["ProductsFields_tnc_Delete"] | undefined
};
	["ProductsFields_tnc_Create"]: {
	__typename: "ProductsFields_tnc_Create",
	permission: boolean
};
	["ProductsFields_tnc_Read"]: {
	__typename: "ProductsFields_tnc_Read",
	permission: boolean
};
	["ProductsFields_tnc_Update"]: {
	__typename: "ProductsFields_tnc_Update",
	permission: boolean
};
	["ProductsFields_tnc_Delete"]: {
	__typename: "ProductsFields_tnc_Delete",
	permission: boolean
};
	["ProductsFields_remarks"]: {
	__typename: "ProductsFields_remarks",
	create?: GraphQLTypes["ProductsFields_remarks_Create"] | undefined,
	read?: GraphQLTypes["ProductsFields_remarks_Read"] | undefined,
	update?: GraphQLTypes["ProductsFields_remarks_Update"] | undefined,
	delete?: GraphQLTypes["ProductsFields_remarks_Delete"] | undefined
};
	["ProductsFields_remarks_Create"]: {
	__typename: "ProductsFields_remarks_Create",
	permission: boolean
};
	["ProductsFields_remarks_Read"]: {
	__typename: "ProductsFields_remarks_Read",
	permission: boolean
};
	["ProductsFields_remarks_Update"]: {
	__typename: "ProductsFields_remarks_Update",
	permission: boolean
};
	["ProductsFields_remarks_Delete"]: {
	__typename: "ProductsFields_remarks_Delete",
	permission: boolean
};
	["ProductsFields_sessions"]: {
	__typename: "ProductsFields_sessions",
	create?: GraphQLTypes["ProductsFields_sessions_Create"] | undefined,
	read?: GraphQLTypes["ProductsFields_sessions_Read"] | undefined,
	update?: GraphQLTypes["ProductsFields_sessions_Update"] | undefined,
	delete?: GraphQLTypes["ProductsFields_sessions_Delete"] | undefined,
	fields?: GraphQLTypes["ProductsFields_sessions_Fields"] | undefined
};
	["ProductsFields_sessions_Create"]: {
	__typename: "ProductsFields_sessions_Create",
	permission: boolean
};
	["ProductsFields_sessions_Read"]: {
	__typename: "ProductsFields_sessions_Read",
	permission: boolean
};
	["ProductsFields_sessions_Update"]: {
	__typename: "ProductsFields_sessions_Update",
	permission: boolean
};
	["ProductsFields_sessions_Delete"]: {
	__typename: "ProductsFields_sessions_Delete",
	permission: boolean
};
	["ProductsFields_sessions_Fields"]: {
	__typename: "ProductsFields_sessions_Fields",
	productId?: GraphQLTypes["ProductsFields_sessions_productId"] | undefined,
	name?: GraphQLTypes["ProductsFields_sessions_name"] | undefined,
	inventories?: GraphQLTypes["ProductsFields_sessions_inventories"] | undefined,
	id?: GraphQLTypes["ProductsFields_sessions_id"] | undefined
};
	["ProductsFields_sessions_productId"]: {
	__typename: "ProductsFields_sessions_productId",
	create?: GraphQLTypes["ProductsFields_sessions_productId_Create"] | undefined,
	read?: GraphQLTypes["ProductsFields_sessions_productId_Read"] | undefined,
	update?: GraphQLTypes["ProductsFields_sessions_productId_Update"] | undefined,
	delete?: GraphQLTypes["ProductsFields_sessions_productId_Delete"] | undefined
};
	["ProductsFields_sessions_productId_Create"]: {
	__typename: "ProductsFields_sessions_productId_Create",
	permission: boolean
};
	["ProductsFields_sessions_productId_Read"]: {
	__typename: "ProductsFields_sessions_productId_Read",
	permission: boolean
};
	["ProductsFields_sessions_productId_Update"]: {
	__typename: "ProductsFields_sessions_productId_Update",
	permission: boolean
};
	["ProductsFields_sessions_productId_Delete"]: {
	__typename: "ProductsFields_sessions_productId_Delete",
	permission: boolean
};
	["ProductsFields_sessions_name"]: {
	__typename: "ProductsFields_sessions_name",
	create?: GraphQLTypes["ProductsFields_sessions_name_Create"] | undefined,
	read?: GraphQLTypes["ProductsFields_sessions_name_Read"] | undefined,
	update?: GraphQLTypes["ProductsFields_sessions_name_Update"] | undefined,
	delete?: GraphQLTypes["ProductsFields_sessions_name_Delete"] | undefined
};
	["ProductsFields_sessions_name_Create"]: {
	__typename: "ProductsFields_sessions_name_Create",
	permission: boolean
};
	["ProductsFields_sessions_name_Read"]: {
	__typename: "ProductsFields_sessions_name_Read",
	permission: boolean
};
	["ProductsFields_sessions_name_Update"]: {
	__typename: "ProductsFields_sessions_name_Update",
	permission: boolean
};
	["ProductsFields_sessions_name_Delete"]: {
	__typename: "ProductsFields_sessions_name_Delete",
	permission: boolean
};
	["ProductsFields_sessions_inventories"]: {
	__typename: "ProductsFields_sessions_inventories",
	create?: GraphQLTypes["ProductsFields_sessions_inventories_Create"] | undefined,
	read?: GraphQLTypes["ProductsFields_sessions_inventories_Read"] | undefined,
	update?: GraphQLTypes["ProductsFields_sessions_inventories_Update"] | undefined,
	delete?: GraphQLTypes["ProductsFields_sessions_inventories_Delete"] | undefined,
	fields?: GraphQLTypes["ProductsFields_sessions_inventories_Fields"] | undefined
};
	["ProductsFields_sessions_inventories_Create"]: {
	__typename: "ProductsFields_sessions_inventories_Create",
	permission: boolean
};
	["ProductsFields_sessions_inventories_Read"]: {
	__typename: "ProductsFields_sessions_inventories_Read",
	permission: boolean
};
	["ProductsFields_sessions_inventories_Update"]: {
	__typename: "ProductsFields_sessions_inventories_Update",
	permission: boolean
};
	["ProductsFields_sessions_inventories_Delete"]: {
	__typename: "ProductsFields_sessions_inventories_Delete",
	permission: boolean
};
	["ProductsFields_sessions_inventories_Fields"]: {
	__typename: "ProductsFields_sessions_inventories_Fields",
	quantity?: GraphQLTypes["ProductsFields_sessions_inventories_quantity"] | undefined,
	unitPrice?: GraphQLTypes["ProductsFields_sessions_inventories_unitPrice"] | undefined,
	handlingFee?: GraphQLTypes["ProductsFields_sessions_inventories_handlingFee"] | undefined,
	totalCost?: GraphQLTypes["ProductsFields_sessions_inventories_totalCost"] | undefined,
	minPurchase?: GraphQLTypes["ProductsFields_sessions_inventories_minPurchase"] | undefined,
	maxPurchase?: GraphQLTypes["ProductsFields_sessions_inventories_maxPurchase"] | undefined,
	stocks?: GraphQLTypes["ProductsFields_sessions_inventories_stocks"] | undefined
};
	["ProductsFields_sessions_inventories_quantity"]: {
	__typename: "ProductsFields_sessions_inventories_quantity",
	create?: GraphQLTypes["ProductsFields_sessions_inventories_quantity_Create"] | undefined,
	read?: GraphQLTypes["ProductsFields_sessions_inventories_quantity_Read"] | undefined,
	update?: GraphQLTypes["ProductsFields_sessions_inventories_quantity_Update"] | undefined,
	delete?: GraphQLTypes["ProductsFields_sessions_inventories_quantity_Delete"] | undefined
};
	["ProductsFields_sessions_inventories_quantity_Create"]: {
	__typename: "ProductsFields_sessions_inventories_quantity_Create",
	permission: boolean
};
	["ProductsFields_sessions_inventories_quantity_Read"]: {
	__typename: "ProductsFields_sessions_inventories_quantity_Read",
	permission: boolean
};
	["ProductsFields_sessions_inventories_quantity_Update"]: {
	__typename: "ProductsFields_sessions_inventories_quantity_Update",
	permission: boolean
};
	["ProductsFields_sessions_inventories_quantity_Delete"]: {
	__typename: "ProductsFields_sessions_inventories_quantity_Delete",
	permission: boolean
};
	["ProductsFields_sessions_inventories_unitPrice"]: {
	__typename: "ProductsFields_sessions_inventories_unitPrice",
	create?: GraphQLTypes["ProductsFields_sessions_inventories_unitPrice_Create"] | undefined,
	read?: GraphQLTypes["ProductsFields_sessions_inventories_unitPrice_Read"] | undefined,
	update?: GraphQLTypes["ProductsFields_sessions_inventories_unitPrice_Update"] | undefined,
	delete?: GraphQLTypes["ProductsFields_sessions_inventories_unitPrice_Delete"] | undefined
};
	["ProductsFields_sessions_inventories_unitPrice_Create"]: {
	__typename: "ProductsFields_sessions_inventories_unitPrice_Create",
	permission: boolean
};
	["ProductsFields_sessions_inventories_unitPrice_Read"]: {
	__typename: "ProductsFields_sessions_inventories_unitPrice_Read",
	permission: boolean
};
	["ProductsFields_sessions_inventories_unitPrice_Update"]: {
	__typename: "ProductsFields_sessions_inventories_unitPrice_Update",
	permission: boolean
};
	["ProductsFields_sessions_inventories_unitPrice_Delete"]: {
	__typename: "ProductsFields_sessions_inventories_unitPrice_Delete",
	permission: boolean
};
	["ProductsFields_sessions_inventories_handlingFee"]: {
	__typename: "ProductsFields_sessions_inventories_handlingFee",
	create?: GraphQLTypes["ProductsFields_sessions_inventories_handlingFee_Create"] | undefined,
	read?: GraphQLTypes["ProductsFields_sessions_inventories_handlingFee_Read"] | undefined,
	update?: GraphQLTypes["ProductsFields_sessions_inventories_handlingFee_Update"] | undefined,
	delete?: GraphQLTypes["ProductsFields_sessions_inventories_handlingFee_Delete"] | undefined
};
	["ProductsFields_sessions_inventories_handlingFee_Create"]: {
	__typename: "ProductsFields_sessions_inventories_handlingFee_Create",
	permission: boolean
};
	["ProductsFields_sessions_inventories_handlingFee_Read"]: {
	__typename: "ProductsFields_sessions_inventories_handlingFee_Read",
	permission: boolean
};
	["ProductsFields_sessions_inventories_handlingFee_Update"]: {
	__typename: "ProductsFields_sessions_inventories_handlingFee_Update",
	permission: boolean
};
	["ProductsFields_sessions_inventories_handlingFee_Delete"]: {
	__typename: "ProductsFields_sessions_inventories_handlingFee_Delete",
	permission: boolean
};
	["ProductsFields_sessions_inventories_totalCost"]: {
	__typename: "ProductsFields_sessions_inventories_totalCost",
	create?: GraphQLTypes["ProductsFields_sessions_inventories_totalCost_Create"] | undefined,
	read?: GraphQLTypes["ProductsFields_sessions_inventories_totalCost_Read"] | undefined,
	update?: GraphQLTypes["ProductsFields_sessions_inventories_totalCost_Update"] | undefined,
	delete?: GraphQLTypes["ProductsFields_sessions_inventories_totalCost_Delete"] | undefined
};
	["ProductsFields_sessions_inventories_totalCost_Create"]: {
	__typename: "ProductsFields_sessions_inventories_totalCost_Create",
	permission: boolean
};
	["ProductsFields_sessions_inventories_totalCost_Read"]: {
	__typename: "ProductsFields_sessions_inventories_totalCost_Read",
	permission: boolean
};
	["ProductsFields_sessions_inventories_totalCost_Update"]: {
	__typename: "ProductsFields_sessions_inventories_totalCost_Update",
	permission: boolean
};
	["ProductsFields_sessions_inventories_totalCost_Delete"]: {
	__typename: "ProductsFields_sessions_inventories_totalCost_Delete",
	permission: boolean
};
	["ProductsFields_sessions_inventories_minPurchase"]: {
	__typename: "ProductsFields_sessions_inventories_minPurchase",
	create?: GraphQLTypes["ProductsFields_sessions_inventories_minPurchase_Create"] | undefined,
	read?: GraphQLTypes["ProductsFields_sessions_inventories_minPurchase_Read"] | undefined,
	update?: GraphQLTypes["ProductsFields_sessions_inventories_minPurchase_Update"] | undefined,
	delete?: GraphQLTypes["ProductsFields_sessions_inventories_minPurchase_Delete"] | undefined
};
	["ProductsFields_sessions_inventories_minPurchase_Create"]: {
	__typename: "ProductsFields_sessions_inventories_minPurchase_Create",
	permission: boolean
};
	["ProductsFields_sessions_inventories_minPurchase_Read"]: {
	__typename: "ProductsFields_sessions_inventories_minPurchase_Read",
	permission: boolean
};
	["ProductsFields_sessions_inventories_minPurchase_Update"]: {
	__typename: "ProductsFields_sessions_inventories_minPurchase_Update",
	permission: boolean
};
	["ProductsFields_sessions_inventories_minPurchase_Delete"]: {
	__typename: "ProductsFields_sessions_inventories_minPurchase_Delete",
	permission: boolean
};
	["ProductsFields_sessions_inventories_maxPurchase"]: {
	__typename: "ProductsFields_sessions_inventories_maxPurchase",
	create?: GraphQLTypes["ProductsFields_sessions_inventories_maxPurchase_Create"] | undefined,
	read?: GraphQLTypes["ProductsFields_sessions_inventories_maxPurchase_Read"] | undefined,
	update?: GraphQLTypes["ProductsFields_sessions_inventories_maxPurchase_Update"] | undefined,
	delete?: GraphQLTypes["ProductsFields_sessions_inventories_maxPurchase_Delete"] | undefined
};
	["ProductsFields_sessions_inventories_maxPurchase_Create"]: {
	__typename: "ProductsFields_sessions_inventories_maxPurchase_Create",
	permission: boolean
};
	["ProductsFields_sessions_inventories_maxPurchase_Read"]: {
	__typename: "ProductsFields_sessions_inventories_maxPurchase_Read",
	permission: boolean
};
	["ProductsFields_sessions_inventories_maxPurchase_Update"]: {
	__typename: "ProductsFields_sessions_inventories_maxPurchase_Update",
	permission: boolean
};
	["ProductsFields_sessions_inventories_maxPurchase_Delete"]: {
	__typename: "ProductsFields_sessions_inventories_maxPurchase_Delete",
	permission: boolean
};
	["ProductsFields_sessions_inventories_stocks"]: {
	__typename: "ProductsFields_sessions_inventories_stocks",
	create?: GraphQLTypes["ProductsFields_sessions_inventories_stocks_Create"] | undefined,
	read?: GraphQLTypes["ProductsFields_sessions_inventories_stocks_Read"] | undefined,
	update?: GraphQLTypes["ProductsFields_sessions_inventories_stocks_Update"] | undefined,
	delete?: GraphQLTypes["ProductsFields_sessions_inventories_stocks_Delete"] | undefined,
	fields?: GraphQLTypes["ProductsFields_sessions_inventories_stocks_Fields"] | undefined
};
	["ProductsFields_sessions_inventories_stocks_Create"]: {
	__typename: "ProductsFields_sessions_inventories_stocks_Create",
	permission: boolean
};
	["ProductsFields_sessions_inventories_stocks_Read"]: {
	__typename: "ProductsFields_sessions_inventories_stocks_Read",
	permission: boolean
};
	["ProductsFields_sessions_inventories_stocks_Update"]: {
	__typename: "ProductsFields_sessions_inventories_stocks_Update",
	permission: boolean
};
	["ProductsFields_sessions_inventories_stocks_Delete"]: {
	__typename: "ProductsFields_sessions_inventories_stocks_Delete",
	permission: boolean
};
	["ProductsFields_sessions_inventories_stocks_Fields"]: {
	__typename: "ProductsFields_sessions_inventories_stocks_Fields",
	stockId?: GraphQLTypes["ProductsFields_sessions_inventories_stocks_stockId"] | undefined,
	remaining?: GraphQLTypes["ProductsFields_sessions_inventories_stocks_remaining"] | undefined,
	sold?: GraphQLTypes["ProductsFields_sessions_inventories_stocks_sold"] | undefined,
	onHold?: GraphQLTypes["ProductsFields_sessions_inventories_stocks_onHold"] | undefined,
	checkedIn?: GraphQLTypes["ProductsFields_sessions_inventories_stocks_checkedIn"] | undefined
};
	["ProductsFields_sessions_inventories_stocks_stockId"]: {
	__typename: "ProductsFields_sessions_inventories_stocks_stockId",
	create?: GraphQLTypes["ProductsFields_sessions_inventories_stocks_stockId_Create"] | undefined,
	read?: GraphQLTypes["ProductsFields_sessions_inventories_stocks_stockId_Read"] | undefined,
	update?: GraphQLTypes["ProductsFields_sessions_inventories_stocks_stockId_Update"] | undefined,
	delete?: GraphQLTypes["ProductsFields_sessions_inventories_stocks_stockId_Delete"] | undefined
};
	["ProductsFields_sessions_inventories_stocks_stockId_Create"]: {
	__typename: "ProductsFields_sessions_inventories_stocks_stockId_Create",
	permission: boolean
};
	["ProductsFields_sessions_inventories_stocks_stockId_Read"]: {
	__typename: "ProductsFields_sessions_inventories_stocks_stockId_Read",
	permission: boolean
};
	["ProductsFields_sessions_inventories_stocks_stockId_Update"]: {
	__typename: "ProductsFields_sessions_inventories_stocks_stockId_Update",
	permission: boolean
};
	["ProductsFields_sessions_inventories_stocks_stockId_Delete"]: {
	__typename: "ProductsFields_sessions_inventories_stocks_stockId_Delete",
	permission: boolean
};
	["ProductsFields_sessions_inventories_stocks_remaining"]: {
	__typename: "ProductsFields_sessions_inventories_stocks_remaining",
	create?: GraphQLTypes["ProductsFields_sessions_inventories_stocks_remaining_Create"] | undefined,
	read?: GraphQLTypes["ProductsFields_sessions_inventories_stocks_remaining_Read"] | undefined,
	update?: GraphQLTypes["ProductsFields_sessions_inventories_stocks_remaining_Update"] | undefined,
	delete?: GraphQLTypes["ProductsFields_sessions_inventories_stocks_remaining_Delete"] | undefined
};
	["ProductsFields_sessions_inventories_stocks_remaining_Create"]: {
	__typename: "ProductsFields_sessions_inventories_stocks_remaining_Create",
	permission: boolean
};
	["ProductsFields_sessions_inventories_stocks_remaining_Read"]: {
	__typename: "ProductsFields_sessions_inventories_stocks_remaining_Read",
	permission: boolean
};
	["ProductsFields_sessions_inventories_stocks_remaining_Update"]: {
	__typename: "ProductsFields_sessions_inventories_stocks_remaining_Update",
	permission: boolean
};
	["ProductsFields_sessions_inventories_stocks_remaining_Delete"]: {
	__typename: "ProductsFields_sessions_inventories_stocks_remaining_Delete",
	permission: boolean
};
	["ProductsFields_sessions_inventories_stocks_sold"]: {
	__typename: "ProductsFields_sessions_inventories_stocks_sold",
	create?: GraphQLTypes["ProductsFields_sessions_inventories_stocks_sold_Create"] | undefined,
	read?: GraphQLTypes["ProductsFields_sessions_inventories_stocks_sold_Read"] | undefined,
	update?: GraphQLTypes["ProductsFields_sessions_inventories_stocks_sold_Update"] | undefined,
	delete?: GraphQLTypes["ProductsFields_sessions_inventories_stocks_sold_Delete"] | undefined
};
	["ProductsFields_sessions_inventories_stocks_sold_Create"]: {
	__typename: "ProductsFields_sessions_inventories_stocks_sold_Create",
	permission: boolean
};
	["ProductsFields_sessions_inventories_stocks_sold_Read"]: {
	__typename: "ProductsFields_sessions_inventories_stocks_sold_Read",
	permission: boolean
};
	["ProductsFields_sessions_inventories_stocks_sold_Update"]: {
	__typename: "ProductsFields_sessions_inventories_stocks_sold_Update",
	permission: boolean
};
	["ProductsFields_sessions_inventories_stocks_sold_Delete"]: {
	__typename: "ProductsFields_sessions_inventories_stocks_sold_Delete",
	permission: boolean
};
	["ProductsFields_sessions_inventories_stocks_onHold"]: {
	__typename: "ProductsFields_sessions_inventories_stocks_onHold",
	create?: GraphQLTypes["ProductsFields_sessions_inventories_stocks_onHold_Create"] | undefined,
	read?: GraphQLTypes["ProductsFields_sessions_inventories_stocks_onHold_Read"] | undefined,
	update?: GraphQLTypes["ProductsFields_sessions_inventories_stocks_onHold_Update"] | undefined,
	delete?: GraphQLTypes["ProductsFields_sessions_inventories_stocks_onHold_Delete"] | undefined
};
	["ProductsFields_sessions_inventories_stocks_onHold_Create"]: {
	__typename: "ProductsFields_sessions_inventories_stocks_onHold_Create",
	permission: boolean
};
	["ProductsFields_sessions_inventories_stocks_onHold_Read"]: {
	__typename: "ProductsFields_sessions_inventories_stocks_onHold_Read",
	permission: boolean
};
	["ProductsFields_sessions_inventories_stocks_onHold_Update"]: {
	__typename: "ProductsFields_sessions_inventories_stocks_onHold_Update",
	permission: boolean
};
	["ProductsFields_sessions_inventories_stocks_onHold_Delete"]: {
	__typename: "ProductsFields_sessions_inventories_stocks_onHold_Delete",
	permission: boolean
};
	["ProductsFields_sessions_inventories_stocks_checkedIn"]: {
	__typename: "ProductsFields_sessions_inventories_stocks_checkedIn",
	create?: GraphQLTypes["ProductsFields_sessions_inventories_stocks_checkedIn_Create"] | undefined,
	read?: GraphQLTypes["ProductsFields_sessions_inventories_stocks_checkedIn_Read"] | undefined,
	update?: GraphQLTypes["ProductsFields_sessions_inventories_stocks_checkedIn_Update"] | undefined,
	delete?: GraphQLTypes["ProductsFields_sessions_inventories_stocks_checkedIn_Delete"] | undefined
};
	["ProductsFields_sessions_inventories_stocks_checkedIn_Create"]: {
	__typename: "ProductsFields_sessions_inventories_stocks_checkedIn_Create",
	permission: boolean
};
	["ProductsFields_sessions_inventories_stocks_checkedIn_Read"]: {
	__typename: "ProductsFields_sessions_inventories_stocks_checkedIn_Read",
	permission: boolean
};
	["ProductsFields_sessions_inventories_stocks_checkedIn_Update"]: {
	__typename: "ProductsFields_sessions_inventories_stocks_checkedIn_Update",
	permission: boolean
};
	["ProductsFields_sessions_inventories_stocks_checkedIn_Delete"]: {
	__typename: "ProductsFields_sessions_inventories_stocks_checkedIn_Delete",
	permission: boolean
};
	["ProductsFields_sessions_id"]: {
	__typename: "ProductsFields_sessions_id",
	create?: GraphQLTypes["ProductsFields_sessions_id_Create"] | undefined,
	read?: GraphQLTypes["ProductsFields_sessions_id_Read"] | undefined,
	update?: GraphQLTypes["ProductsFields_sessions_id_Update"] | undefined,
	delete?: GraphQLTypes["ProductsFields_sessions_id_Delete"] | undefined
};
	["ProductsFields_sessions_id_Create"]: {
	__typename: "ProductsFields_sessions_id_Create",
	permission: boolean
};
	["ProductsFields_sessions_id_Read"]: {
	__typename: "ProductsFields_sessions_id_Read",
	permission: boolean
};
	["ProductsFields_sessions_id_Update"]: {
	__typename: "ProductsFields_sessions_id_Update",
	permission: boolean
};
	["ProductsFields_sessions_id_Delete"]: {
	__typename: "ProductsFields_sessions_id_Delete",
	permission: boolean
};
	["ProductsFields_updatedAt"]: {
	__typename: "ProductsFields_updatedAt",
	create?: GraphQLTypes["ProductsFields_updatedAt_Create"] | undefined,
	read?: GraphQLTypes["ProductsFields_updatedAt_Read"] | undefined,
	update?: GraphQLTypes["ProductsFields_updatedAt_Update"] | undefined,
	delete?: GraphQLTypes["ProductsFields_updatedAt_Delete"] | undefined
};
	["ProductsFields_updatedAt_Create"]: {
	__typename: "ProductsFields_updatedAt_Create",
	permission: boolean
};
	["ProductsFields_updatedAt_Read"]: {
	__typename: "ProductsFields_updatedAt_Read",
	permission: boolean
};
	["ProductsFields_updatedAt_Update"]: {
	__typename: "ProductsFields_updatedAt_Update",
	permission: boolean
};
	["ProductsFields_updatedAt_Delete"]: {
	__typename: "ProductsFields_updatedAt_Delete",
	permission: boolean
};
	["ProductsFields_createdAt"]: {
	__typename: "ProductsFields_createdAt",
	create?: GraphQLTypes["ProductsFields_createdAt_Create"] | undefined,
	read?: GraphQLTypes["ProductsFields_createdAt_Read"] | undefined,
	update?: GraphQLTypes["ProductsFields_createdAt_Update"] | undefined,
	delete?: GraphQLTypes["ProductsFields_createdAt_Delete"] | undefined
};
	["ProductsFields_createdAt_Create"]: {
	__typename: "ProductsFields_createdAt_Create",
	permission: boolean
};
	["ProductsFields_createdAt_Read"]: {
	__typename: "ProductsFields_createdAt_Read",
	permission: boolean
};
	["ProductsFields_createdAt_Update"]: {
	__typename: "ProductsFields_createdAt_Update",
	permission: boolean
};
	["ProductsFields_createdAt_Delete"]: {
	__typename: "ProductsFields_createdAt_Delete",
	permission: boolean
};
	["ProductsCreateAccess"]: {
	__typename: "ProductsCreateAccess",
	permission: boolean,
	where?: GraphQLTypes["JSONObject"] | undefined
};
	["ProductsReadAccess"]: {
	__typename: "ProductsReadAccess",
	permission: boolean,
	where?: GraphQLTypes["JSONObject"] | undefined
};
	["ProductsUpdateAccess"]: {
	__typename: "ProductsUpdateAccess",
	permission: boolean,
	where?: GraphQLTypes["JSONObject"] | undefined
};
	["ProductsDeleteAccess"]: {
	__typename: "ProductsDeleteAccess",
	permission: boolean,
	where?: GraphQLTypes["JSONObject"] | undefined
};
	["inventory_statusAccess"]: {
	__typename: "inventory_statusAccess",
	fields?: GraphQLTypes["InventoryStatusFields"] | undefined,
	create?: GraphQLTypes["InventoryStatusCreateAccess"] | undefined,
	read?: GraphQLTypes["InventoryStatusReadAccess"] | undefined,
	update?: GraphQLTypes["InventoryStatusUpdateAccess"] | undefined,
	delete?: GraphQLTypes["InventoryStatusDeleteAccess"] | undefined
};
	["InventoryStatusFields"]: {
	__typename: "InventoryStatusFields",
	variantId?: GraphQLTypes["InventoryStatusFields_variantId"] | undefined,
	variantName?: GraphQLTypes["InventoryStatusFields_variantName"] | undefined,
	productType?: GraphQLTypes["InventoryStatusFields_productType"] | undefined,
	stockUpdateStatus?: GraphQLTypes["InventoryStatusFields_stockUpdateStatus"] | undefined,
	stockUpdateAmount?: GraphQLTypes["InventoryStatusFields_stockUpdateAmount"] | undefined,
	user?: GraphQLTypes["InventoryStatusFields_user"] | undefined,
	publicUserPhone?: GraphQLTypes["InventoryStatusFields_publicUserPhone"] | undefined,
	expiredAt?: GraphQLTypes["InventoryStatusFields_expiredAt"] | undefined,
	updatedAt?: GraphQLTypes["InventoryStatusFields_updatedAt"] | undefined,
	createdAt?: GraphQLTypes["InventoryStatusFields_createdAt"] | undefined
};
	["InventoryStatusFields_variantId"]: {
	__typename: "InventoryStatusFields_variantId",
	create?: GraphQLTypes["InventoryStatusFields_variantId_Create"] | undefined,
	read?: GraphQLTypes["InventoryStatusFields_variantId_Read"] | undefined,
	update?: GraphQLTypes["InventoryStatusFields_variantId_Update"] | undefined,
	delete?: GraphQLTypes["InventoryStatusFields_variantId_Delete"] | undefined
};
	["InventoryStatusFields_variantId_Create"]: {
	__typename: "InventoryStatusFields_variantId_Create",
	permission: boolean
};
	["InventoryStatusFields_variantId_Read"]: {
	__typename: "InventoryStatusFields_variantId_Read",
	permission: boolean
};
	["InventoryStatusFields_variantId_Update"]: {
	__typename: "InventoryStatusFields_variantId_Update",
	permission: boolean
};
	["InventoryStatusFields_variantId_Delete"]: {
	__typename: "InventoryStatusFields_variantId_Delete",
	permission: boolean
};
	["InventoryStatusFields_variantName"]: {
	__typename: "InventoryStatusFields_variantName",
	create?: GraphQLTypes["InventoryStatusFields_variantName_Create"] | undefined,
	read?: GraphQLTypes["InventoryStatusFields_variantName_Read"] | undefined,
	update?: GraphQLTypes["InventoryStatusFields_variantName_Update"] | undefined,
	delete?: GraphQLTypes["InventoryStatusFields_variantName_Delete"] | undefined
};
	["InventoryStatusFields_variantName_Create"]: {
	__typename: "InventoryStatusFields_variantName_Create",
	permission: boolean
};
	["InventoryStatusFields_variantName_Read"]: {
	__typename: "InventoryStatusFields_variantName_Read",
	permission: boolean
};
	["InventoryStatusFields_variantName_Update"]: {
	__typename: "InventoryStatusFields_variantName_Update",
	permission: boolean
};
	["InventoryStatusFields_variantName_Delete"]: {
	__typename: "InventoryStatusFields_variantName_Delete",
	permission: boolean
};
	["InventoryStatusFields_productType"]: {
	__typename: "InventoryStatusFields_productType",
	create?: GraphQLTypes["InventoryStatusFields_productType_Create"] | undefined,
	read?: GraphQLTypes["InventoryStatusFields_productType_Read"] | undefined,
	update?: GraphQLTypes["InventoryStatusFields_productType_Update"] | undefined,
	delete?: GraphQLTypes["InventoryStatusFields_productType_Delete"] | undefined
};
	["InventoryStatusFields_productType_Create"]: {
	__typename: "InventoryStatusFields_productType_Create",
	permission: boolean
};
	["InventoryStatusFields_productType_Read"]: {
	__typename: "InventoryStatusFields_productType_Read",
	permission: boolean
};
	["InventoryStatusFields_productType_Update"]: {
	__typename: "InventoryStatusFields_productType_Update",
	permission: boolean
};
	["InventoryStatusFields_productType_Delete"]: {
	__typename: "InventoryStatusFields_productType_Delete",
	permission: boolean
};
	["InventoryStatusFields_stockUpdateStatus"]: {
	__typename: "InventoryStatusFields_stockUpdateStatus",
	create?: GraphQLTypes["InventoryStatusFields_stockUpdateStatus_Create"] | undefined,
	read?: GraphQLTypes["InventoryStatusFields_stockUpdateStatus_Read"] | undefined,
	update?: GraphQLTypes["InventoryStatusFields_stockUpdateStatus_Update"] | undefined,
	delete?: GraphQLTypes["InventoryStatusFields_stockUpdateStatus_Delete"] | undefined
};
	["InventoryStatusFields_stockUpdateStatus_Create"]: {
	__typename: "InventoryStatusFields_stockUpdateStatus_Create",
	permission: boolean
};
	["InventoryStatusFields_stockUpdateStatus_Read"]: {
	__typename: "InventoryStatusFields_stockUpdateStatus_Read",
	permission: boolean
};
	["InventoryStatusFields_stockUpdateStatus_Update"]: {
	__typename: "InventoryStatusFields_stockUpdateStatus_Update",
	permission: boolean
};
	["InventoryStatusFields_stockUpdateStatus_Delete"]: {
	__typename: "InventoryStatusFields_stockUpdateStatus_Delete",
	permission: boolean
};
	["InventoryStatusFields_stockUpdateAmount"]: {
	__typename: "InventoryStatusFields_stockUpdateAmount",
	create?: GraphQLTypes["InventoryStatusFields_stockUpdateAmount_Create"] | undefined,
	read?: GraphQLTypes["InventoryStatusFields_stockUpdateAmount_Read"] | undefined,
	update?: GraphQLTypes["InventoryStatusFields_stockUpdateAmount_Update"] | undefined,
	delete?: GraphQLTypes["InventoryStatusFields_stockUpdateAmount_Delete"] | undefined
};
	["InventoryStatusFields_stockUpdateAmount_Create"]: {
	__typename: "InventoryStatusFields_stockUpdateAmount_Create",
	permission: boolean
};
	["InventoryStatusFields_stockUpdateAmount_Read"]: {
	__typename: "InventoryStatusFields_stockUpdateAmount_Read",
	permission: boolean
};
	["InventoryStatusFields_stockUpdateAmount_Update"]: {
	__typename: "InventoryStatusFields_stockUpdateAmount_Update",
	permission: boolean
};
	["InventoryStatusFields_stockUpdateAmount_Delete"]: {
	__typename: "InventoryStatusFields_stockUpdateAmount_Delete",
	permission: boolean
};
	["InventoryStatusFields_user"]: {
	__typename: "InventoryStatusFields_user",
	create?: GraphQLTypes["InventoryStatusFields_user_Create"] | undefined,
	read?: GraphQLTypes["InventoryStatusFields_user_Read"] | undefined,
	update?: GraphQLTypes["InventoryStatusFields_user_Update"] | undefined,
	delete?: GraphQLTypes["InventoryStatusFields_user_Delete"] | undefined
};
	["InventoryStatusFields_user_Create"]: {
	__typename: "InventoryStatusFields_user_Create",
	permission: boolean
};
	["InventoryStatusFields_user_Read"]: {
	__typename: "InventoryStatusFields_user_Read",
	permission: boolean
};
	["InventoryStatusFields_user_Update"]: {
	__typename: "InventoryStatusFields_user_Update",
	permission: boolean
};
	["InventoryStatusFields_user_Delete"]: {
	__typename: "InventoryStatusFields_user_Delete",
	permission: boolean
};
	["InventoryStatusFields_publicUserPhone"]: {
	__typename: "InventoryStatusFields_publicUserPhone",
	create?: GraphQLTypes["InventoryStatusFields_publicUserPhone_Create"] | undefined,
	read?: GraphQLTypes["InventoryStatusFields_publicUserPhone_Read"] | undefined,
	update?: GraphQLTypes["InventoryStatusFields_publicUserPhone_Update"] | undefined,
	delete?: GraphQLTypes["InventoryStatusFields_publicUserPhone_Delete"] | undefined
};
	["InventoryStatusFields_publicUserPhone_Create"]: {
	__typename: "InventoryStatusFields_publicUserPhone_Create",
	permission: boolean
};
	["InventoryStatusFields_publicUserPhone_Read"]: {
	__typename: "InventoryStatusFields_publicUserPhone_Read",
	permission: boolean
};
	["InventoryStatusFields_publicUserPhone_Update"]: {
	__typename: "InventoryStatusFields_publicUserPhone_Update",
	permission: boolean
};
	["InventoryStatusFields_publicUserPhone_Delete"]: {
	__typename: "InventoryStatusFields_publicUserPhone_Delete",
	permission: boolean
};
	["InventoryStatusFields_expiredAt"]: {
	__typename: "InventoryStatusFields_expiredAt",
	create?: GraphQLTypes["InventoryStatusFields_expiredAt_Create"] | undefined,
	read?: GraphQLTypes["InventoryStatusFields_expiredAt_Read"] | undefined,
	update?: GraphQLTypes["InventoryStatusFields_expiredAt_Update"] | undefined,
	delete?: GraphQLTypes["InventoryStatusFields_expiredAt_Delete"] | undefined
};
	["InventoryStatusFields_expiredAt_Create"]: {
	__typename: "InventoryStatusFields_expiredAt_Create",
	permission: boolean
};
	["InventoryStatusFields_expiredAt_Read"]: {
	__typename: "InventoryStatusFields_expiredAt_Read",
	permission: boolean
};
	["InventoryStatusFields_expiredAt_Update"]: {
	__typename: "InventoryStatusFields_expiredAt_Update",
	permission: boolean
};
	["InventoryStatusFields_expiredAt_Delete"]: {
	__typename: "InventoryStatusFields_expiredAt_Delete",
	permission: boolean
};
	["InventoryStatusFields_updatedAt"]: {
	__typename: "InventoryStatusFields_updatedAt",
	create?: GraphQLTypes["InventoryStatusFields_updatedAt_Create"] | undefined,
	read?: GraphQLTypes["InventoryStatusFields_updatedAt_Read"] | undefined,
	update?: GraphQLTypes["InventoryStatusFields_updatedAt_Update"] | undefined,
	delete?: GraphQLTypes["InventoryStatusFields_updatedAt_Delete"] | undefined
};
	["InventoryStatusFields_updatedAt_Create"]: {
	__typename: "InventoryStatusFields_updatedAt_Create",
	permission: boolean
};
	["InventoryStatusFields_updatedAt_Read"]: {
	__typename: "InventoryStatusFields_updatedAt_Read",
	permission: boolean
};
	["InventoryStatusFields_updatedAt_Update"]: {
	__typename: "InventoryStatusFields_updatedAt_Update",
	permission: boolean
};
	["InventoryStatusFields_updatedAt_Delete"]: {
	__typename: "InventoryStatusFields_updatedAt_Delete",
	permission: boolean
};
	["InventoryStatusFields_createdAt"]: {
	__typename: "InventoryStatusFields_createdAt",
	create?: GraphQLTypes["InventoryStatusFields_createdAt_Create"] | undefined,
	read?: GraphQLTypes["InventoryStatusFields_createdAt_Read"] | undefined,
	update?: GraphQLTypes["InventoryStatusFields_createdAt_Update"] | undefined,
	delete?: GraphQLTypes["InventoryStatusFields_createdAt_Delete"] | undefined
};
	["InventoryStatusFields_createdAt_Create"]: {
	__typename: "InventoryStatusFields_createdAt_Create",
	permission: boolean
};
	["InventoryStatusFields_createdAt_Read"]: {
	__typename: "InventoryStatusFields_createdAt_Read",
	permission: boolean
};
	["InventoryStatusFields_createdAt_Update"]: {
	__typename: "InventoryStatusFields_createdAt_Update",
	permission: boolean
};
	["InventoryStatusFields_createdAt_Delete"]: {
	__typename: "InventoryStatusFields_createdAt_Delete",
	permission: boolean
};
	["InventoryStatusCreateAccess"]: {
	__typename: "InventoryStatusCreateAccess",
	permission: boolean,
	where?: GraphQLTypes["JSONObject"] | undefined
};
	["InventoryStatusReadAccess"]: {
	__typename: "InventoryStatusReadAccess",
	permission: boolean,
	where?: GraphQLTypes["JSONObject"] | undefined
};
	["InventoryStatusUpdateAccess"]: {
	__typename: "InventoryStatusUpdateAccess",
	permission: boolean,
	where?: GraphQLTypes["JSONObject"] | undefined
};
	["InventoryStatusDeleteAccess"]: {
	__typename: "InventoryStatusDeleteAccess",
	permission: boolean,
	where?: GraphQLTypes["JSONObject"] | undefined
};
	["transactionsAccess"]: {
	__typename: "transactionsAccess",
	fields?: GraphQLTypes["TransactionsFields"] | undefined,
	create?: GraphQLTypes["TransactionsCreateAccess"] | undefined,
	read?: GraphQLTypes["TransactionsReadAccess"] | undefined,
	update?: GraphQLTypes["TransactionsUpdateAccess"] | undefined,
	delete?: GraphQLTypes["TransactionsDeleteAccess"] | undefined
};
	["TransactionsFields"]: {
	__typename: "TransactionsFields",
	creator?: GraphQLTypes["TransactionsFields_creator"] | undefined,
	productName?: GraphQLTypes["TransactionsFields_productName"] | undefined,
	referenceId?: GraphQLTypes["TransactionsFields_referenceId"] | undefined,
	linkedEvent?: GraphQLTypes["TransactionsFields_linkedEvent"] | undefined,
	purchaseDateTime?: GraphQLTypes["TransactionsFields_purchaseDateTime"] | undefined,
	timableOrderId?: GraphQLTypes["TransactionsFields_timableOrderId"] | undefined,
	stripePaymentId?: GraphQLTypes["TransactionsFields_stripePaymentId"] | undefined,
	totalAmount?: GraphQLTypes["TransactionsFields_totalAmount"] | undefined,
	contactPersonName?: GraphQLTypes["TransactionsFields_contactPersonName"] | undefined,
	contactPersonPhone?: GraphQLTypes["TransactionsFields_contactPersonPhone"] | undefined,
	remarks?: GraphQLTypes["TransactionsFields_remarks"] | undefined,
	redemptionCodes?: GraphQLTypes["TransactionsFields_redemptionCodes"] | undefined,
	updatedAt?: GraphQLTypes["TransactionsFields_updatedAt"] | undefined,
	createdAt?: GraphQLTypes["TransactionsFields_createdAt"] | undefined
};
	["TransactionsFields_creator"]: {
	__typename: "TransactionsFields_creator",
	create?: GraphQLTypes["TransactionsFields_creator_Create"] | undefined,
	read?: GraphQLTypes["TransactionsFields_creator_Read"] | undefined,
	update?: GraphQLTypes["TransactionsFields_creator_Update"] | undefined,
	delete?: GraphQLTypes["TransactionsFields_creator_Delete"] | undefined
};
	["TransactionsFields_creator_Create"]: {
	__typename: "TransactionsFields_creator_Create",
	permission: boolean
};
	["TransactionsFields_creator_Read"]: {
	__typename: "TransactionsFields_creator_Read",
	permission: boolean
};
	["TransactionsFields_creator_Update"]: {
	__typename: "TransactionsFields_creator_Update",
	permission: boolean
};
	["TransactionsFields_creator_Delete"]: {
	__typename: "TransactionsFields_creator_Delete",
	permission: boolean
};
	["TransactionsFields_productName"]: {
	__typename: "TransactionsFields_productName",
	create?: GraphQLTypes["TransactionsFields_productName_Create"] | undefined,
	read?: GraphQLTypes["TransactionsFields_productName_Read"] | undefined,
	update?: GraphQLTypes["TransactionsFields_productName_Update"] | undefined,
	delete?: GraphQLTypes["TransactionsFields_productName_Delete"] | undefined
};
	["TransactionsFields_productName_Create"]: {
	__typename: "TransactionsFields_productName_Create",
	permission: boolean
};
	["TransactionsFields_productName_Read"]: {
	__typename: "TransactionsFields_productName_Read",
	permission: boolean
};
	["TransactionsFields_productName_Update"]: {
	__typename: "TransactionsFields_productName_Update",
	permission: boolean
};
	["TransactionsFields_productName_Delete"]: {
	__typename: "TransactionsFields_productName_Delete",
	permission: boolean
};
	["TransactionsFields_referenceId"]: {
	__typename: "TransactionsFields_referenceId",
	create?: GraphQLTypes["TransactionsFields_referenceId_Create"] | undefined,
	read?: GraphQLTypes["TransactionsFields_referenceId_Read"] | undefined,
	update?: GraphQLTypes["TransactionsFields_referenceId_Update"] | undefined,
	delete?: GraphQLTypes["TransactionsFields_referenceId_Delete"] | undefined
};
	["TransactionsFields_referenceId_Create"]: {
	__typename: "TransactionsFields_referenceId_Create",
	permission: boolean
};
	["TransactionsFields_referenceId_Read"]: {
	__typename: "TransactionsFields_referenceId_Read",
	permission: boolean
};
	["TransactionsFields_referenceId_Update"]: {
	__typename: "TransactionsFields_referenceId_Update",
	permission: boolean
};
	["TransactionsFields_referenceId_Delete"]: {
	__typename: "TransactionsFields_referenceId_Delete",
	permission: boolean
};
	["TransactionsFields_linkedEvent"]: {
	__typename: "TransactionsFields_linkedEvent",
	create?: GraphQLTypes["TransactionsFields_linkedEvent_Create"] | undefined,
	read?: GraphQLTypes["TransactionsFields_linkedEvent_Read"] | undefined,
	update?: GraphQLTypes["TransactionsFields_linkedEvent_Update"] | undefined,
	delete?: GraphQLTypes["TransactionsFields_linkedEvent_Delete"] | undefined
};
	["TransactionsFields_linkedEvent_Create"]: {
	__typename: "TransactionsFields_linkedEvent_Create",
	permission: boolean
};
	["TransactionsFields_linkedEvent_Read"]: {
	__typename: "TransactionsFields_linkedEvent_Read",
	permission: boolean
};
	["TransactionsFields_linkedEvent_Update"]: {
	__typename: "TransactionsFields_linkedEvent_Update",
	permission: boolean
};
	["TransactionsFields_linkedEvent_Delete"]: {
	__typename: "TransactionsFields_linkedEvent_Delete",
	permission: boolean
};
	["TransactionsFields_purchaseDateTime"]: {
	__typename: "TransactionsFields_purchaseDateTime",
	create?: GraphQLTypes["TransactionsFields_purchaseDateTime_Create"] | undefined,
	read?: GraphQLTypes["TransactionsFields_purchaseDateTime_Read"] | undefined,
	update?: GraphQLTypes["TransactionsFields_purchaseDateTime_Update"] | undefined,
	delete?: GraphQLTypes["TransactionsFields_purchaseDateTime_Delete"] | undefined
};
	["TransactionsFields_purchaseDateTime_Create"]: {
	__typename: "TransactionsFields_purchaseDateTime_Create",
	permission: boolean
};
	["TransactionsFields_purchaseDateTime_Read"]: {
	__typename: "TransactionsFields_purchaseDateTime_Read",
	permission: boolean
};
	["TransactionsFields_purchaseDateTime_Update"]: {
	__typename: "TransactionsFields_purchaseDateTime_Update",
	permission: boolean
};
	["TransactionsFields_purchaseDateTime_Delete"]: {
	__typename: "TransactionsFields_purchaseDateTime_Delete",
	permission: boolean
};
	["TransactionsFields_timableOrderId"]: {
	__typename: "TransactionsFields_timableOrderId",
	create?: GraphQLTypes["TransactionsFields_timableOrderId_Create"] | undefined,
	read?: GraphQLTypes["TransactionsFields_timableOrderId_Read"] | undefined,
	update?: GraphQLTypes["TransactionsFields_timableOrderId_Update"] | undefined,
	delete?: GraphQLTypes["TransactionsFields_timableOrderId_Delete"] | undefined
};
	["TransactionsFields_timableOrderId_Create"]: {
	__typename: "TransactionsFields_timableOrderId_Create",
	permission: boolean
};
	["TransactionsFields_timableOrderId_Read"]: {
	__typename: "TransactionsFields_timableOrderId_Read",
	permission: boolean
};
	["TransactionsFields_timableOrderId_Update"]: {
	__typename: "TransactionsFields_timableOrderId_Update",
	permission: boolean
};
	["TransactionsFields_timableOrderId_Delete"]: {
	__typename: "TransactionsFields_timableOrderId_Delete",
	permission: boolean
};
	["TransactionsFields_stripePaymentId"]: {
	__typename: "TransactionsFields_stripePaymentId",
	create?: GraphQLTypes["TransactionsFields_stripePaymentId_Create"] | undefined,
	read?: GraphQLTypes["TransactionsFields_stripePaymentId_Read"] | undefined,
	update?: GraphQLTypes["TransactionsFields_stripePaymentId_Update"] | undefined,
	delete?: GraphQLTypes["TransactionsFields_stripePaymentId_Delete"] | undefined
};
	["TransactionsFields_stripePaymentId_Create"]: {
	__typename: "TransactionsFields_stripePaymentId_Create",
	permission: boolean
};
	["TransactionsFields_stripePaymentId_Read"]: {
	__typename: "TransactionsFields_stripePaymentId_Read",
	permission: boolean
};
	["TransactionsFields_stripePaymentId_Update"]: {
	__typename: "TransactionsFields_stripePaymentId_Update",
	permission: boolean
};
	["TransactionsFields_stripePaymentId_Delete"]: {
	__typename: "TransactionsFields_stripePaymentId_Delete",
	permission: boolean
};
	["TransactionsFields_totalAmount"]: {
	__typename: "TransactionsFields_totalAmount",
	create?: GraphQLTypes["TransactionsFields_totalAmount_Create"] | undefined,
	read?: GraphQLTypes["TransactionsFields_totalAmount_Read"] | undefined,
	update?: GraphQLTypes["TransactionsFields_totalAmount_Update"] | undefined,
	delete?: GraphQLTypes["TransactionsFields_totalAmount_Delete"] | undefined
};
	["TransactionsFields_totalAmount_Create"]: {
	__typename: "TransactionsFields_totalAmount_Create",
	permission: boolean
};
	["TransactionsFields_totalAmount_Read"]: {
	__typename: "TransactionsFields_totalAmount_Read",
	permission: boolean
};
	["TransactionsFields_totalAmount_Update"]: {
	__typename: "TransactionsFields_totalAmount_Update",
	permission: boolean
};
	["TransactionsFields_totalAmount_Delete"]: {
	__typename: "TransactionsFields_totalAmount_Delete",
	permission: boolean
};
	["TransactionsFields_contactPersonName"]: {
	__typename: "TransactionsFields_contactPersonName",
	create?: GraphQLTypes["TransactionsFields_contactPersonName_Create"] | undefined,
	read?: GraphQLTypes["TransactionsFields_contactPersonName_Read"] | undefined,
	update?: GraphQLTypes["TransactionsFields_contactPersonName_Update"] | undefined,
	delete?: GraphQLTypes["TransactionsFields_contactPersonName_Delete"] | undefined
};
	["TransactionsFields_contactPersonName_Create"]: {
	__typename: "TransactionsFields_contactPersonName_Create",
	permission: boolean
};
	["TransactionsFields_contactPersonName_Read"]: {
	__typename: "TransactionsFields_contactPersonName_Read",
	permission: boolean
};
	["TransactionsFields_contactPersonName_Update"]: {
	__typename: "TransactionsFields_contactPersonName_Update",
	permission: boolean
};
	["TransactionsFields_contactPersonName_Delete"]: {
	__typename: "TransactionsFields_contactPersonName_Delete",
	permission: boolean
};
	["TransactionsFields_contactPersonPhone"]: {
	__typename: "TransactionsFields_contactPersonPhone",
	create?: GraphQLTypes["TransactionsFields_contactPersonPhone_Create"] | undefined,
	read?: GraphQLTypes["TransactionsFields_contactPersonPhone_Read"] | undefined,
	update?: GraphQLTypes["TransactionsFields_contactPersonPhone_Update"] | undefined,
	delete?: GraphQLTypes["TransactionsFields_contactPersonPhone_Delete"] | undefined
};
	["TransactionsFields_contactPersonPhone_Create"]: {
	__typename: "TransactionsFields_contactPersonPhone_Create",
	permission: boolean
};
	["TransactionsFields_contactPersonPhone_Read"]: {
	__typename: "TransactionsFields_contactPersonPhone_Read",
	permission: boolean
};
	["TransactionsFields_contactPersonPhone_Update"]: {
	__typename: "TransactionsFields_contactPersonPhone_Update",
	permission: boolean
};
	["TransactionsFields_contactPersonPhone_Delete"]: {
	__typename: "TransactionsFields_contactPersonPhone_Delete",
	permission: boolean
};
	["TransactionsFields_remarks"]: {
	__typename: "TransactionsFields_remarks",
	create?: GraphQLTypes["TransactionsFields_remarks_Create"] | undefined,
	read?: GraphQLTypes["TransactionsFields_remarks_Read"] | undefined,
	update?: GraphQLTypes["TransactionsFields_remarks_Update"] | undefined,
	delete?: GraphQLTypes["TransactionsFields_remarks_Delete"] | undefined
};
	["TransactionsFields_remarks_Create"]: {
	__typename: "TransactionsFields_remarks_Create",
	permission: boolean
};
	["TransactionsFields_remarks_Read"]: {
	__typename: "TransactionsFields_remarks_Read",
	permission: boolean
};
	["TransactionsFields_remarks_Update"]: {
	__typename: "TransactionsFields_remarks_Update",
	permission: boolean
};
	["TransactionsFields_remarks_Delete"]: {
	__typename: "TransactionsFields_remarks_Delete",
	permission: boolean
};
	["TransactionsFields_redemptionCodes"]: {
	__typename: "TransactionsFields_redemptionCodes",
	create?: GraphQLTypes["TransactionsFields_redemptionCodes_Create"] | undefined,
	read?: GraphQLTypes["TransactionsFields_redemptionCodes_Read"] | undefined,
	update?: GraphQLTypes["TransactionsFields_redemptionCodes_Update"] | undefined,
	delete?: GraphQLTypes["TransactionsFields_redemptionCodes_Delete"] | undefined,
	fields?: GraphQLTypes["TransactionsFields_redemptionCodes_Fields"] | undefined
};
	["TransactionsFields_redemptionCodes_Create"]: {
	__typename: "TransactionsFields_redemptionCodes_Create",
	permission: boolean
};
	["TransactionsFields_redemptionCodes_Read"]: {
	__typename: "TransactionsFields_redemptionCodes_Read",
	permission: boolean
};
	["TransactionsFields_redemptionCodes_Update"]: {
	__typename: "TransactionsFields_redemptionCodes_Update",
	permission: boolean
};
	["TransactionsFields_redemptionCodes_Delete"]: {
	__typename: "TransactionsFields_redemptionCodes_Delete",
	permission: boolean
};
	["TransactionsFields_redemptionCodes_Fields"]: {
	__typename: "TransactionsFields_redemptionCodes_Fields",
	code?: GraphQLTypes["TransactionsFields_redemptionCodes_code"] | undefined,
	id?: GraphQLTypes["TransactionsFields_redemptionCodes_id"] | undefined
};
	["TransactionsFields_redemptionCodes_code"]: {
	__typename: "TransactionsFields_redemptionCodes_code",
	create?: GraphQLTypes["TransactionsFields_redemptionCodes_code_Create"] | undefined,
	read?: GraphQLTypes["TransactionsFields_redemptionCodes_code_Read"] | undefined,
	update?: GraphQLTypes["TransactionsFields_redemptionCodes_code_Update"] | undefined,
	delete?: GraphQLTypes["TransactionsFields_redemptionCodes_code_Delete"] | undefined
};
	["TransactionsFields_redemptionCodes_code_Create"]: {
	__typename: "TransactionsFields_redemptionCodes_code_Create",
	permission: boolean
};
	["TransactionsFields_redemptionCodes_code_Read"]: {
	__typename: "TransactionsFields_redemptionCodes_code_Read",
	permission: boolean
};
	["TransactionsFields_redemptionCodes_code_Update"]: {
	__typename: "TransactionsFields_redemptionCodes_code_Update",
	permission: boolean
};
	["TransactionsFields_redemptionCodes_code_Delete"]: {
	__typename: "TransactionsFields_redemptionCodes_code_Delete",
	permission: boolean
};
	["TransactionsFields_redemptionCodes_id"]: {
	__typename: "TransactionsFields_redemptionCodes_id",
	create?: GraphQLTypes["TransactionsFields_redemptionCodes_id_Create"] | undefined,
	read?: GraphQLTypes["TransactionsFields_redemptionCodes_id_Read"] | undefined,
	update?: GraphQLTypes["TransactionsFields_redemptionCodes_id_Update"] | undefined,
	delete?: GraphQLTypes["TransactionsFields_redemptionCodes_id_Delete"] | undefined
};
	["TransactionsFields_redemptionCodes_id_Create"]: {
	__typename: "TransactionsFields_redemptionCodes_id_Create",
	permission: boolean
};
	["TransactionsFields_redemptionCodes_id_Read"]: {
	__typename: "TransactionsFields_redemptionCodes_id_Read",
	permission: boolean
};
	["TransactionsFields_redemptionCodes_id_Update"]: {
	__typename: "TransactionsFields_redemptionCodes_id_Update",
	permission: boolean
};
	["TransactionsFields_redemptionCodes_id_Delete"]: {
	__typename: "TransactionsFields_redemptionCodes_id_Delete",
	permission: boolean
};
	["TransactionsFields_updatedAt"]: {
	__typename: "TransactionsFields_updatedAt",
	create?: GraphQLTypes["TransactionsFields_updatedAt_Create"] | undefined,
	read?: GraphQLTypes["TransactionsFields_updatedAt_Read"] | undefined,
	update?: GraphQLTypes["TransactionsFields_updatedAt_Update"] | undefined,
	delete?: GraphQLTypes["TransactionsFields_updatedAt_Delete"] | undefined
};
	["TransactionsFields_updatedAt_Create"]: {
	__typename: "TransactionsFields_updatedAt_Create",
	permission: boolean
};
	["TransactionsFields_updatedAt_Read"]: {
	__typename: "TransactionsFields_updatedAt_Read",
	permission: boolean
};
	["TransactionsFields_updatedAt_Update"]: {
	__typename: "TransactionsFields_updatedAt_Update",
	permission: boolean
};
	["TransactionsFields_updatedAt_Delete"]: {
	__typename: "TransactionsFields_updatedAt_Delete",
	permission: boolean
};
	["TransactionsFields_createdAt"]: {
	__typename: "TransactionsFields_createdAt",
	create?: GraphQLTypes["TransactionsFields_createdAt_Create"] | undefined,
	read?: GraphQLTypes["TransactionsFields_createdAt_Read"] | undefined,
	update?: GraphQLTypes["TransactionsFields_createdAt_Update"] | undefined,
	delete?: GraphQLTypes["TransactionsFields_createdAt_Delete"] | undefined
};
	["TransactionsFields_createdAt_Create"]: {
	__typename: "TransactionsFields_createdAt_Create",
	permission: boolean
};
	["TransactionsFields_createdAt_Read"]: {
	__typename: "TransactionsFields_createdAt_Read",
	permission: boolean
};
	["TransactionsFields_createdAt_Update"]: {
	__typename: "TransactionsFields_createdAt_Update",
	permission: boolean
};
	["TransactionsFields_createdAt_Delete"]: {
	__typename: "TransactionsFields_createdAt_Delete",
	permission: boolean
};
	["TransactionsCreateAccess"]: {
	__typename: "TransactionsCreateAccess",
	permission: boolean,
	where?: GraphQLTypes["JSONObject"] | undefined
};
	["TransactionsReadAccess"]: {
	__typename: "TransactionsReadAccess",
	permission: boolean,
	where?: GraphQLTypes["JSONObject"] | undefined
};
	["TransactionsUpdateAccess"]: {
	__typename: "TransactionsUpdateAccess",
	permission: boolean,
	where?: GraphQLTypes["JSONObject"] | undefined
};
	["TransactionsDeleteAccess"]: {
	__typename: "TransactionsDeleteAccess",
	permission: boolean,
	where?: GraphQLTypes["JSONObject"] | undefined
};
	["redemption_codesAccess"]: {
	__typename: "redemption_codesAccess",
	fields?: GraphQLTypes["RedemptionCodesFields"] | undefined,
	create?: GraphQLTypes["RedemptionCodesCreateAccess"] | undefined,
	read?: GraphQLTypes["RedemptionCodesReadAccess"] | undefined,
	update?: GraphQLTypes["RedemptionCodesUpdateAccess"] | undefined,
	delete?: GraphQLTypes["RedemptionCodesDeleteAccess"] | undefined
};
	["RedemptionCodesFields"]: {
	__typename: "RedemptionCodesFields",
	creator?: GraphQLTypes["RedemptionCodesFields_creator"] | undefined,
	status?: GraphQLTypes["RedemptionCodesFields_status"] | undefined,
	qrCodeId?: GraphQLTypes["RedemptionCodesFields_qrCodeId"] | undefined,
	lastCheckedInDateTime?: GraphQLTypes["RedemptionCodesFields_lastCheckedInDateTime"] | undefined,
	voidedDateTime?: GraphQLTypes["RedemptionCodesFields_voidedDateTime"] | undefined,
	refundedDateTime?: GraphQLTypes["RedemptionCodesFields_refundedDateTime"] | undefined,
	eventStartTime?: GraphQLTypes["RedemptionCodesFields_eventStartTime"] | undefined,
	eventEndTime?: GraphQLTypes["RedemptionCodesFields_eventEndTime"] | undefined,
	eventPriceZone?: GraphQLTypes["RedemptionCodesFields_eventPriceZone"] | undefined,
	ownerName?: GraphQLTypes["RedemptionCodesFields_ownerName"] | undefined,
	phone?: GraphQLTypes["RedemptionCodesFields_phone"] | undefined,
	pdf?: GraphQLTypes["RedemptionCodesFields_pdf"] | undefined,
	variants?: GraphQLTypes["RedemptionCodesFields_variants"] | undefined,
	productId?: GraphQLTypes["RedemptionCodesFields_productId"] | undefined,
	referenceId?: GraphQLTypes["RedemptionCodesFields_referenceId"] | undefined,
	productType?: GraphQLTypes["RedemptionCodesFields_productType"] | undefined,
	productName?: GraphQLTypes["RedemptionCodesFields_productName"] | undefined,
	linkedEvent?: GraphQLTypes["RedemptionCodesFields_linkedEvent"] | undefined,
	timableOrderId?: GraphQLTypes["RedemptionCodesFields_timableOrderId"] | undefined,
	purchaseDateTime?: GraphQLTypes["RedemptionCodesFields_purchaseDateTime"] | undefined,
	changeHistory?: GraphQLTypes["RedemptionCodesFields_changeHistory"] | undefined,
	updatedAt?: GraphQLTypes["RedemptionCodesFields_updatedAt"] | undefined,
	createdAt?: GraphQLTypes["RedemptionCodesFields_createdAt"] | undefined
};
	["RedemptionCodesFields_creator"]: {
	__typename: "RedemptionCodesFields_creator",
	create?: GraphQLTypes["RedemptionCodesFields_creator_Create"] | undefined,
	read?: GraphQLTypes["RedemptionCodesFields_creator_Read"] | undefined,
	update?: GraphQLTypes["RedemptionCodesFields_creator_Update"] | undefined,
	delete?: GraphQLTypes["RedemptionCodesFields_creator_Delete"] | undefined
};
	["RedemptionCodesFields_creator_Create"]: {
	__typename: "RedemptionCodesFields_creator_Create",
	permission: boolean
};
	["RedemptionCodesFields_creator_Read"]: {
	__typename: "RedemptionCodesFields_creator_Read",
	permission: boolean
};
	["RedemptionCodesFields_creator_Update"]: {
	__typename: "RedemptionCodesFields_creator_Update",
	permission: boolean
};
	["RedemptionCodesFields_creator_Delete"]: {
	__typename: "RedemptionCodesFields_creator_Delete",
	permission: boolean
};
	["RedemptionCodesFields_status"]: {
	__typename: "RedemptionCodesFields_status",
	create?: GraphQLTypes["RedemptionCodesFields_status_Create"] | undefined,
	read?: GraphQLTypes["RedemptionCodesFields_status_Read"] | undefined,
	update?: GraphQLTypes["RedemptionCodesFields_status_Update"] | undefined,
	delete?: GraphQLTypes["RedemptionCodesFields_status_Delete"] | undefined
};
	["RedemptionCodesFields_status_Create"]: {
	__typename: "RedemptionCodesFields_status_Create",
	permission: boolean
};
	["RedemptionCodesFields_status_Read"]: {
	__typename: "RedemptionCodesFields_status_Read",
	permission: boolean
};
	["RedemptionCodesFields_status_Update"]: {
	__typename: "RedemptionCodesFields_status_Update",
	permission: boolean
};
	["RedemptionCodesFields_status_Delete"]: {
	__typename: "RedemptionCodesFields_status_Delete",
	permission: boolean
};
	["RedemptionCodesFields_qrCodeId"]: {
	__typename: "RedemptionCodesFields_qrCodeId",
	create?: GraphQLTypes["RedemptionCodesFields_qrCodeId_Create"] | undefined,
	read?: GraphQLTypes["RedemptionCodesFields_qrCodeId_Read"] | undefined,
	update?: GraphQLTypes["RedemptionCodesFields_qrCodeId_Update"] | undefined,
	delete?: GraphQLTypes["RedemptionCodesFields_qrCodeId_Delete"] | undefined
};
	["RedemptionCodesFields_qrCodeId_Create"]: {
	__typename: "RedemptionCodesFields_qrCodeId_Create",
	permission: boolean
};
	["RedemptionCodesFields_qrCodeId_Read"]: {
	__typename: "RedemptionCodesFields_qrCodeId_Read",
	permission: boolean
};
	["RedemptionCodesFields_qrCodeId_Update"]: {
	__typename: "RedemptionCodesFields_qrCodeId_Update",
	permission: boolean
};
	["RedemptionCodesFields_qrCodeId_Delete"]: {
	__typename: "RedemptionCodesFields_qrCodeId_Delete",
	permission: boolean
};
	["RedemptionCodesFields_lastCheckedInDateTime"]: {
	__typename: "RedemptionCodesFields_lastCheckedInDateTime",
	create?: GraphQLTypes["RedemptionCodesFields_lastCheckedInDateTime_Create"] | undefined,
	read?: GraphQLTypes["RedemptionCodesFields_lastCheckedInDateTime_Read"] | undefined,
	update?: GraphQLTypes["RedemptionCodesFields_lastCheckedInDateTime_Update"] | undefined,
	delete?: GraphQLTypes["RedemptionCodesFields_lastCheckedInDateTime_Delete"] | undefined
};
	["RedemptionCodesFields_lastCheckedInDateTime_Create"]: {
	__typename: "RedemptionCodesFields_lastCheckedInDateTime_Create",
	permission: boolean
};
	["RedemptionCodesFields_lastCheckedInDateTime_Read"]: {
	__typename: "RedemptionCodesFields_lastCheckedInDateTime_Read",
	permission: boolean
};
	["RedemptionCodesFields_lastCheckedInDateTime_Update"]: {
	__typename: "RedemptionCodesFields_lastCheckedInDateTime_Update",
	permission: boolean
};
	["RedemptionCodesFields_lastCheckedInDateTime_Delete"]: {
	__typename: "RedemptionCodesFields_lastCheckedInDateTime_Delete",
	permission: boolean
};
	["RedemptionCodesFields_voidedDateTime"]: {
	__typename: "RedemptionCodesFields_voidedDateTime",
	create?: GraphQLTypes["RedemptionCodesFields_voidedDateTime_Create"] | undefined,
	read?: GraphQLTypes["RedemptionCodesFields_voidedDateTime_Read"] | undefined,
	update?: GraphQLTypes["RedemptionCodesFields_voidedDateTime_Update"] | undefined,
	delete?: GraphQLTypes["RedemptionCodesFields_voidedDateTime_Delete"] | undefined
};
	["RedemptionCodesFields_voidedDateTime_Create"]: {
	__typename: "RedemptionCodesFields_voidedDateTime_Create",
	permission: boolean
};
	["RedemptionCodesFields_voidedDateTime_Read"]: {
	__typename: "RedemptionCodesFields_voidedDateTime_Read",
	permission: boolean
};
	["RedemptionCodesFields_voidedDateTime_Update"]: {
	__typename: "RedemptionCodesFields_voidedDateTime_Update",
	permission: boolean
};
	["RedemptionCodesFields_voidedDateTime_Delete"]: {
	__typename: "RedemptionCodesFields_voidedDateTime_Delete",
	permission: boolean
};
	["RedemptionCodesFields_refundedDateTime"]: {
	__typename: "RedemptionCodesFields_refundedDateTime",
	create?: GraphQLTypes["RedemptionCodesFields_refundedDateTime_Create"] | undefined,
	read?: GraphQLTypes["RedemptionCodesFields_refundedDateTime_Read"] | undefined,
	update?: GraphQLTypes["RedemptionCodesFields_refundedDateTime_Update"] | undefined,
	delete?: GraphQLTypes["RedemptionCodesFields_refundedDateTime_Delete"] | undefined
};
	["RedemptionCodesFields_refundedDateTime_Create"]: {
	__typename: "RedemptionCodesFields_refundedDateTime_Create",
	permission: boolean
};
	["RedemptionCodesFields_refundedDateTime_Read"]: {
	__typename: "RedemptionCodesFields_refundedDateTime_Read",
	permission: boolean
};
	["RedemptionCodesFields_refundedDateTime_Update"]: {
	__typename: "RedemptionCodesFields_refundedDateTime_Update",
	permission: boolean
};
	["RedemptionCodesFields_refundedDateTime_Delete"]: {
	__typename: "RedemptionCodesFields_refundedDateTime_Delete",
	permission: boolean
};
	["RedemptionCodesFields_eventStartTime"]: {
	__typename: "RedemptionCodesFields_eventStartTime",
	create?: GraphQLTypes["RedemptionCodesFields_eventStartTime_Create"] | undefined,
	read?: GraphQLTypes["RedemptionCodesFields_eventStartTime_Read"] | undefined,
	update?: GraphQLTypes["RedemptionCodesFields_eventStartTime_Update"] | undefined,
	delete?: GraphQLTypes["RedemptionCodesFields_eventStartTime_Delete"] | undefined
};
	["RedemptionCodesFields_eventStartTime_Create"]: {
	__typename: "RedemptionCodesFields_eventStartTime_Create",
	permission: boolean
};
	["RedemptionCodesFields_eventStartTime_Read"]: {
	__typename: "RedemptionCodesFields_eventStartTime_Read",
	permission: boolean
};
	["RedemptionCodesFields_eventStartTime_Update"]: {
	__typename: "RedemptionCodesFields_eventStartTime_Update",
	permission: boolean
};
	["RedemptionCodesFields_eventStartTime_Delete"]: {
	__typename: "RedemptionCodesFields_eventStartTime_Delete",
	permission: boolean
};
	["RedemptionCodesFields_eventEndTime"]: {
	__typename: "RedemptionCodesFields_eventEndTime",
	create?: GraphQLTypes["RedemptionCodesFields_eventEndTime_Create"] | undefined,
	read?: GraphQLTypes["RedemptionCodesFields_eventEndTime_Read"] | undefined,
	update?: GraphQLTypes["RedemptionCodesFields_eventEndTime_Update"] | undefined,
	delete?: GraphQLTypes["RedemptionCodesFields_eventEndTime_Delete"] | undefined
};
	["RedemptionCodesFields_eventEndTime_Create"]: {
	__typename: "RedemptionCodesFields_eventEndTime_Create",
	permission: boolean
};
	["RedemptionCodesFields_eventEndTime_Read"]: {
	__typename: "RedemptionCodesFields_eventEndTime_Read",
	permission: boolean
};
	["RedemptionCodesFields_eventEndTime_Update"]: {
	__typename: "RedemptionCodesFields_eventEndTime_Update",
	permission: boolean
};
	["RedemptionCodesFields_eventEndTime_Delete"]: {
	__typename: "RedemptionCodesFields_eventEndTime_Delete",
	permission: boolean
};
	["RedemptionCodesFields_eventPriceZone"]: {
	__typename: "RedemptionCodesFields_eventPriceZone",
	create?: GraphQLTypes["RedemptionCodesFields_eventPriceZone_Create"] | undefined,
	read?: GraphQLTypes["RedemptionCodesFields_eventPriceZone_Read"] | undefined,
	update?: GraphQLTypes["RedemptionCodesFields_eventPriceZone_Update"] | undefined,
	delete?: GraphQLTypes["RedemptionCodesFields_eventPriceZone_Delete"] | undefined
};
	["RedemptionCodesFields_eventPriceZone_Create"]: {
	__typename: "RedemptionCodesFields_eventPriceZone_Create",
	permission: boolean
};
	["RedemptionCodesFields_eventPriceZone_Read"]: {
	__typename: "RedemptionCodesFields_eventPriceZone_Read",
	permission: boolean
};
	["RedemptionCodesFields_eventPriceZone_Update"]: {
	__typename: "RedemptionCodesFields_eventPriceZone_Update",
	permission: boolean
};
	["RedemptionCodesFields_eventPriceZone_Delete"]: {
	__typename: "RedemptionCodesFields_eventPriceZone_Delete",
	permission: boolean
};
	["RedemptionCodesFields_ownerName"]: {
	__typename: "RedemptionCodesFields_ownerName",
	create?: GraphQLTypes["RedemptionCodesFields_ownerName_Create"] | undefined,
	read?: GraphQLTypes["RedemptionCodesFields_ownerName_Read"] | undefined,
	update?: GraphQLTypes["RedemptionCodesFields_ownerName_Update"] | undefined,
	delete?: GraphQLTypes["RedemptionCodesFields_ownerName_Delete"] | undefined
};
	["RedemptionCodesFields_ownerName_Create"]: {
	__typename: "RedemptionCodesFields_ownerName_Create",
	permission: boolean
};
	["RedemptionCodesFields_ownerName_Read"]: {
	__typename: "RedemptionCodesFields_ownerName_Read",
	permission: boolean
};
	["RedemptionCodesFields_ownerName_Update"]: {
	__typename: "RedemptionCodesFields_ownerName_Update",
	permission: boolean
};
	["RedemptionCodesFields_ownerName_Delete"]: {
	__typename: "RedemptionCodesFields_ownerName_Delete",
	permission: boolean
};
	["RedemptionCodesFields_phone"]: {
	__typename: "RedemptionCodesFields_phone",
	create?: GraphQLTypes["RedemptionCodesFields_phone_Create"] | undefined,
	read?: GraphQLTypes["RedemptionCodesFields_phone_Read"] | undefined,
	update?: GraphQLTypes["RedemptionCodesFields_phone_Update"] | undefined,
	delete?: GraphQLTypes["RedemptionCodesFields_phone_Delete"] | undefined
};
	["RedemptionCodesFields_phone_Create"]: {
	__typename: "RedemptionCodesFields_phone_Create",
	permission: boolean
};
	["RedemptionCodesFields_phone_Read"]: {
	__typename: "RedemptionCodesFields_phone_Read",
	permission: boolean
};
	["RedemptionCodesFields_phone_Update"]: {
	__typename: "RedemptionCodesFields_phone_Update",
	permission: boolean
};
	["RedemptionCodesFields_phone_Delete"]: {
	__typename: "RedemptionCodesFields_phone_Delete",
	permission: boolean
};
	["RedemptionCodesFields_pdf"]: {
	__typename: "RedemptionCodesFields_pdf",
	create?: GraphQLTypes["RedemptionCodesFields_pdf_Create"] | undefined,
	read?: GraphQLTypes["RedemptionCodesFields_pdf_Read"] | undefined,
	update?: GraphQLTypes["RedemptionCodesFields_pdf_Update"] | undefined,
	delete?: GraphQLTypes["RedemptionCodesFields_pdf_Delete"] | undefined
};
	["RedemptionCodesFields_pdf_Create"]: {
	__typename: "RedemptionCodesFields_pdf_Create",
	permission: boolean
};
	["RedemptionCodesFields_pdf_Read"]: {
	__typename: "RedemptionCodesFields_pdf_Read",
	permission: boolean
};
	["RedemptionCodesFields_pdf_Update"]: {
	__typename: "RedemptionCodesFields_pdf_Update",
	permission: boolean
};
	["RedemptionCodesFields_pdf_Delete"]: {
	__typename: "RedemptionCodesFields_pdf_Delete",
	permission: boolean
};
	["RedemptionCodesFields_variants"]: {
	__typename: "RedemptionCodesFields_variants",
	create?: GraphQLTypes["RedemptionCodesFields_variants_Create"] | undefined,
	read?: GraphQLTypes["RedemptionCodesFields_variants_Read"] | undefined,
	update?: GraphQLTypes["RedemptionCodesFields_variants_Update"] | undefined,
	delete?: GraphQLTypes["RedemptionCodesFields_variants_Delete"] | undefined,
	fields?: GraphQLTypes["RedemptionCodesFields_variants_Fields"] | undefined
};
	["RedemptionCodesFields_variants_Create"]: {
	__typename: "RedemptionCodesFields_variants_Create",
	permission: boolean
};
	["RedemptionCodesFields_variants_Read"]: {
	__typename: "RedemptionCodesFields_variants_Read",
	permission: boolean
};
	["RedemptionCodesFields_variants_Update"]: {
	__typename: "RedemptionCodesFields_variants_Update",
	permission: boolean
};
	["RedemptionCodesFields_variants_Delete"]: {
	__typename: "RedemptionCodesFields_variants_Delete",
	permission: boolean
};
	["RedemptionCodesFields_variants_Fields"]: {
	__typename: "RedemptionCodesFields_variants_Fields",
	variantId?: GraphQLTypes["RedemptionCodesFields_variants_variantId"] | undefined,
	name?: GraphQLTypes["RedemptionCodesFields_variants_name"] | undefined,
	quantity?: GraphQLTypes["RedemptionCodesFields_variants_quantity"] | undefined,
	totalCost?: GraphQLTypes["RedemptionCodesFields_variants_totalCost"] | undefined,
	id?: GraphQLTypes["RedemptionCodesFields_variants_id"] | undefined
};
	["RedemptionCodesFields_variants_variantId"]: {
	__typename: "RedemptionCodesFields_variants_variantId",
	create?: GraphQLTypes["RedemptionCodesFields_variants_variantId_Create"] | undefined,
	read?: GraphQLTypes["RedemptionCodesFields_variants_variantId_Read"] | undefined,
	update?: GraphQLTypes["RedemptionCodesFields_variants_variantId_Update"] | undefined,
	delete?: GraphQLTypes["RedemptionCodesFields_variants_variantId_Delete"] | undefined
};
	["RedemptionCodesFields_variants_variantId_Create"]: {
	__typename: "RedemptionCodesFields_variants_variantId_Create",
	permission: boolean
};
	["RedemptionCodesFields_variants_variantId_Read"]: {
	__typename: "RedemptionCodesFields_variants_variantId_Read",
	permission: boolean
};
	["RedemptionCodesFields_variants_variantId_Update"]: {
	__typename: "RedemptionCodesFields_variants_variantId_Update",
	permission: boolean
};
	["RedemptionCodesFields_variants_variantId_Delete"]: {
	__typename: "RedemptionCodesFields_variants_variantId_Delete",
	permission: boolean
};
	["RedemptionCodesFields_variants_name"]: {
	__typename: "RedemptionCodesFields_variants_name",
	create?: GraphQLTypes["RedemptionCodesFields_variants_name_Create"] | undefined,
	read?: GraphQLTypes["RedemptionCodesFields_variants_name_Read"] | undefined,
	update?: GraphQLTypes["RedemptionCodesFields_variants_name_Update"] | undefined,
	delete?: GraphQLTypes["RedemptionCodesFields_variants_name_Delete"] | undefined
};
	["RedemptionCodesFields_variants_name_Create"]: {
	__typename: "RedemptionCodesFields_variants_name_Create",
	permission: boolean
};
	["RedemptionCodesFields_variants_name_Read"]: {
	__typename: "RedemptionCodesFields_variants_name_Read",
	permission: boolean
};
	["RedemptionCodesFields_variants_name_Update"]: {
	__typename: "RedemptionCodesFields_variants_name_Update",
	permission: boolean
};
	["RedemptionCodesFields_variants_name_Delete"]: {
	__typename: "RedemptionCodesFields_variants_name_Delete",
	permission: boolean
};
	["RedemptionCodesFields_variants_quantity"]: {
	__typename: "RedemptionCodesFields_variants_quantity",
	create?: GraphQLTypes["RedemptionCodesFields_variants_quantity_Create"] | undefined,
	read?: GraphQLTypes["RedemptionCodesFields_variants_quantity_Read"] | undefined,
	update?: GraphQLTypes["RedemptionCodesFields_variants_quantity_Update"] | undefined,
	delete?: GraphQLTypes["RedemptionCodesFields_variants_quantity_Delete"] | undefined
};
	["RedemptionCodesFields_variants_quantity_Create"]: {
	__typename: "RedemptionCodesFields_variants_quantity_Create",
	permission: boolean
};
	["RedemptionCodesFields_variants_quantity_Read"]: {
	__typename: "RedemptionCodesFields_variants_quantity_Read",
	permission: boolean
};
	["RedemptionCodesFields_variants_quantity_Update"]: {
	__typename: "RedemptionCodesFields_variants_quantity_Update",
	permission: boolean
};
	["RedemptionCodesFields_variants_quantity_Delete"]: {
	__typename: "RedemptionCodesFields_variants_quantity_Delete",
	permission: boolean
};
	["RedemptionCodesFields_variants_totalCost"]: {
	__typename: "RedemptionCodesFields_variants_totalCost",
	create?: GraphQLTypes["RedemptionCodesFields_variants_totalCost_Create"] | undefined,
	read?: GraphQLTypes["RedemptionCodesFields_variants_totalCost_Read"] | undefined,
	update?: GraphQLTypes["RedemptionCodesFields_variants_totalCost_Update"] | undefined,
	delete?: GraphQLTypes["RedemptionCodesFields_variants_totalCost_Delete"] | undefined
};
	["RedemptionCodesFields_variants_totalCost_Create"]: {
	__typename: "RedemptionCodesFields_variants_totalCost_Create",
	permission: boolean
};
	["RedemptionCodesFields_variants_totalCost_Read"]: {
	__typename: "RedemptionCodesFields_variants_totalCost_Read",
	permission: boolean
};
	["RedemptionCodesFields_variants_totalCost_Update"]: {
	__typename: "RedemptionCodesFields_variants_totalCost_Update",
	permission: boolean
};
	["RedemptionCodesFields_variants_totalCost_Delete"]: {
	__typename: "RedemptionCodesFields_variants_totalCost_Delete",
	permission: boolean
};
	["RedemptionCodesFields_variants_id"]: {
	__typename: "RedemptionCodesFields_variants_id",
	create?: GraphQLTypes["RedemptionCodesFields_variants_id_Create"] | undefined,
	read?: GraphQLTypes["RedemptionCodesFields_variants_id_Read"] | undefined,
	update?: GraphQLTypes["RedemptionCodesFields_variants_id_Update"] | undefined,
	delete?: GraphQLTypes["RedemptionCodesFields_variants_id_Delete"] | undefined
};
	["RedemptionCodesFields_variants_id_Create"]: {
	__typename: "RedemptionCodesFields_variants_id_Create",
	permission: boolean
};
	["RedemptionCodesFields_variants_id_Read"]: {
	__typename: "RedemptionCodesFields_variants_id_Read",
	permission: boolean
};
	["RedemptionCodesFields_variants_id_Update"]: {
	__typename: "RedemptionCodesFields_variants_id_Update",
	permission: boolean
};
	["RedemptionCodesFields_variants_id_Delete"]: {
	__typename: "RedemptionCodesFields_variants_id_Delete",
	permission: boolean
};
	["RedemptionCodesFields_productId"]: {
	__typename: "RedemptionCodesFields_productId",
	create?: GraphQLTypes["RedemptionCodesFields_productId_Create"] | undefined,
	read?: GraphQLTypes["RedemptionCodesFields_productId_Read"] | undefined,
	update?: GraphQLTypes["RedemptionCodesFields_productId_Update"] | undefined,
	delete?: GraphQLTypes["RedemptionCodesFields_productId_Delete"] | undefined
};
	["RedemptionCodesFields_productId_Create"]: {
	__typename: "RedemptionCodesFields_productId_Create",
	permission: boolean
};
	["RedemptionCodesFields_productId_Read"]: {
	__typename: "RedemptionCodesFields_productId_Read",
	permission: boolean
};
	["RedemptionCodesFields_productId_Update"]: {
	__typename: "RedemptionCodesFields_productId_Update",
	permission: boolean
};
	["RedemptionCodesFields_productId_Delete"]: {
	__typename: "RedemptionCodesFields_productId_Delete",
	permission: boolean
};
	["RedemptionCodesFields_referenceId"]: {
	__typename: "RedemptionCodesFields_referenceId",
	create?: GraphQLTypes["RedemptionCodesFields_referenceId_Create"] | undefined,
	read?: GraphQLTypes["RedemptionCodesFields_referenceId_Read"] | undefined,
	update?: GraphQLTypes["RedemptionCodesFields_referenceId_Update"] | undefined,
	delete?: GraphQLTypes["RedemptionCodesFields_referenceId_Delete"] | undefined
};
	["RedemptionCodesFields_referenceId_Create"]: {
	__typename: "RedemptionCodesFields_referenceId_Create",
	permission: boolean
};
	["RedemptionCodesFields_referenceId_Read"]: {
	__typename: "RedemptionCodesFields_referenceId_Read",
	permission: boolean
};
	["RedemptionCodesFields_referenceId_Update"]: {
	__typename: "RedemptionCodesFields_referenceId_Update",
	permission: boolean
};
	["RedemptionCodesFields_referenceId_Delete"]: {
	__typename: "RedemptionCodesFields_referenceId_Delete",
	permission: boolean
};
	["RedemptionCodesFields_productType"]: {
	__typename: "RedemptionCodesFields_productType",
	create?: GraphQLTypes["RedemptionCodesFields_productType_Create"] | undefined,
	read?: GraphQLTypes["RedemptionCodesFields_productType_Read"] | undefined,
	update?: GraphQLTypes["RedemptionCodesFields_productType_Update"] | undefined,
	delete?: GraphQLTypes["RedemptionCodesFields_productType_Delete"] | undefined
};
	["RedemptionCodesFields_productType_Create"]: {
	__typename: "RedemptionCodesFields_productType_Create",
	permission: boolean
};
	["RedemptionCodesFields_productType_Read"]: {
	__typename: "RedemptionCodesFields_productType_Read",
	permission: boolean
};
	["RedemptionCodesFields_productType_Update"]: {
	__typename: "RedemptionCodesFields_productType_Update",
	permission: boolean
};
	["RedemptionCodesFields_productType_Delete"]: {
	__typename: "RedemptionCodesFields_productType_Delete",
	permission: boolean
};
	["RedemptionCodesFields_productName"]: {
	__typename: "RedemptionCodesFields_productName",
	create?: GraphQLTypes["RedemptionCodesFields_productName_Create"] | undefined,
	read?: GraphQLTypes["RedemptionCodesFields_productName_Read"] | undefined,
	update?: GraphQLTypes["RedemptionCodesFields_productName_Update"] | undefined,
	delete?: GraphQLTypes["RedemptionCodesFields_productName_Delete"] | undefined
};
	["RedemptionCodesFields_productName_Create"]: {
	__typename: "RedemptionCodesFields_productName_Create",
	permission: boolean
};
	["RedemptionCodesFields_productName_Read"]: {
	__typename: "RedemptionCodesFields_productName_Read",
	permission: boolean
};
	["RedemptionCodesFields_productName_Update"]: {
	__typename: "RedemptionCodesFields_productName_Update",
	permission: boolean
};
	["RedemptionCodesFields_productName_Delete"]: {
	__typename: "RedemptionCodesFields_productName_Delete",
	permission: boolean
};
	["RedemptionCodesFields_linkedEvent"]: {
	__typename: "RedemptionCodesFields_linkedEvent",
	create?: GraphQLTypes["RedemptionCodesFields_linkedEvent_Create"] | undefined,
	read?: GraphQLTypes["RedemptionCodesFields_linkedEvent_Read"] | undefined,
	update?: GraphQLTypes["RedemptionCodesFields_linkedEvent_Update"] | undefined,
	delete?: GraphQLTypes["RedemptionCodesFields_linkedEvent_Delete"] | undefined
};
	["RedemptionCodesFields_linkedEvent_Create"]: {
	__typename: "RedemptionCodesFields_linkedEvent_Create",
	permission: boolean
};
	["RedemptionCodesFields_linkedEvent_Read"]: {
	__typename: "RedemptionCodesFields_linkedEvent_Read",
	permission: boolean
};
	["RedemptionCodesFields_linkedEvent_Update"]: {
	__typename: "RedemptionCodesFields_linkedEvent_Update",
	permission: boolean
};
	["RedemptionCodesFields_linkedEvent_Delete"]: {
	__typename: "RedemptionCodesFields_linkedEvent_Delete",
	permission: boolean
};
	["RedemptionCodesFields_timableOrderId"]: {
	__typename: "RedemptionCodesFields_timableOrderId",
	create?: GraphQLTypes["RedemptionCodesFields_timableOrderId_Create"] | undefined,
	read?: GraphQLTypes["RedemptionCodesFields_timableOrderId_Read"] | undefined,
	update?: GraphQLTypes["RedemptionCodesFields_timableOrderId_Update"] | undefined,
	delete?: GraphQLTypes["RedemptionCodesFields_timableOrderId_Delete"] | undefined
};
	["RedemptionCodesFields_timableOrderId_Create"]: {
	__typename: "RedemptionCodesFields_timableOrderId_Create",
	permission: boolean
};
	["RedemptionCodesFields_timableOrderId_Read"]: {
	__typename: "RedemptionCodesFields_timableOrderId_Read",
	permission: boolean
};
	["RedemptionCodesFields_timableOrderId_Update"]: {
	__typename: "RedemptionCodesFields_timableOrderId_Update",
	permission: boolean
};
	["RedemptionCodesFields_timableOrderId_Delete"]: {
	__typename: "RedemptionCodesFields_timableOrderId_Delete",
	permission: boolean
};
	["RedemptionCodesFields_purchaseDateTime"]: {
	__typename: "RedemptionCodesFields_purchaseDateTime",
	create?: GraphQLTypes["RedemptionCodesFields_purchaseDateTime_Create"] | undefined,
	read?: GraphQLTypes["RedemptionCodesFields_purchaseDateTime_Read"] | undefined,
	update?: GraphQLTypes["RedemptionCodesFields_purchaseDateTime_Update"] | undefined,
	delete?: GraphQLTypes["RedemptionCodesFields_purchaseDateTime_Delete"] | undefined
};
	["RedemptionCodesFields_purchaseDateTime_Create"]: {
	__typename: "RedemptionCodesFields_purchaseDateTime_Create",
	permission: boolean
};
	["RedemptionCodesFields_purchaseDateTime_Read"]: {
	__typename: "RedemptionCodesFields_purchaseDateTime_Read",
	permission: boolean
};
	["RedemptionCodesFields_purchaseDateTime_Update"]: {
	__typename: "RedemptionCodesFields_purchaseDateTime_Update",
	permission: boolean
};
	["RedemptionCodesFields_purchaseDateTime_Delete"]: {
	__typename: "RedemptionCodesFields_purchaseDateTime_Delete",
	permission: boolean
};
	["RedemptionCodesFields_changeHistory"]: {
	__typename: "RedemptionCodesFields_changeHistory",
	create?: GraphQLTypes["RedemptionCodesFields_changeHistory_Create"] | undefined,
	read?: GraphQLTypes["RedemptionCodesFields_changeHistory_Read"] | undefined,
	update?: GraphQLTypes["RedemptionCodesFields_changeHistory_Update"] | undefined,
	delete?: GraphQLTypes["RedemptionCodesFields_changeHistory_Delete"] | undefined,
	fields?: GraphQLTypes["RedemptionCodesFields_changeHistory_Fields"] | undefined
};
	["RedemptionCodesFields_changeHistory_Create"]: {
	__typename: "RedemptionCodesFields_changeHistory_Create",
	permission: boolean
};
	["RedemptionCodesFields_changeHistory_Read"]: {
	__typename: "RedemptionCodesFields_changeHistory_Read",
	permission: boolean
};
	["RedemptionCodesFields_changeHistory_Update"]: {
	__typename: "RedemptionCodesFields_changeHistory_Update",
	permission: boolean
};
	["RedemptionCodesFields_changeHistory_Delete"]: {
	__typename: "RedemptionCodesFields_changeHistory_Delete",
	permission: boolean
};
	["RedemptionCodesFields_changeHistory_Fields"]: {
	__typename: "RedemptionCodesFields_changeHistory_Fields",
	timestamp?: GraphQLTypes["RedemptionCodesFields_changeHistory_timestamp"] | undefined,
	field?: GraphQLTypes["RedemptionCodesFields_changeHistory_field"] | undefined,
	oldValue?: GraphQLTypes["RedemptionCodesFields_changeHistory_oldValue"] | undefined,
	newValue?: GraphQLTypes["RedemptionCodesFields_changeHistory_newValue"] | undefined,
	id?: GraphQLTypes["RedemptionCodesFields_changeHistory_id"] | undefined
};
	["RedemptionCodesFields_changeHistory_timestamp"]: {
	__typename: "RedemptionCodesFields_changeHistory_timestamp",
	create?: GraphQLTypes["RedemptionCodesFields_changeHistory_timestamp_Create"] | undefined,
	read?: GraphQLTypes["RedemptionCodesFields_changeHistory_timestamp_Read"] | undefined,
	update?: GraphQLTypes["RedemptionCodesFields_changeHistory_timestamp_Update"] | undefined,
	delete?: GraphQLTypes["RedemptionCodesFields_changeHistory_timestamp_Delete"] | undefined
};
	["RedemptionCodesFields_changeHistory_timestamp_Create"]: {
	__typename: "RedemptionCodesFields_changeHistory_timestamp_Create",
	permission: boolean
};
	["RedemptionCodesFields_changeHistory_timestamp_Read"]: {
	__typename: "RedemptionCodesFields_changeHistory_timestamp_Read",
	permission: boolean
};
	["RedemptionCodesFields_changeHistory_timestamp_Update"]: {
	__typename: "RedemptionCodesFields_changeHistory_timestamp_Update",
	permission: boolean
};
	["RedemptionCodesFields_changeHistory_timestamp_Delete"]: {
	__typename: "RedemptionCodesFields_changeHistory_timestamp_Delete",
	permission: boolean
};
	["RedemptionCodesFields_changeHistory_field"]: {
	__typename: "RedemptionCodesFields_changeHistory_field",
	create?: GraphQLTypes["RedemptionCodesFields_changeHistory_field_Create"] | undefined,
	read?: GraphQLTypes["RedemptionCodesFields_changeHistory_field_Read"] | undefined,
	update?: GraphQLTypes["RedemptionCodesFields_changeHistory_field_Update"] | undefined,
	delete?: GraphQLTypes["RedemptionCodesFields_changeHistory_field_Delete"] | undefined
};
	["RedemptionCodesFields_changeHistory_field_Create"]: {
	__typename: "RedemptionCodesFields_changeHistory_field_Create",
	permission: boolean
};
	["RedemptionCodesFields_changeHistory_field_Read"]: {
	__typename: "RedemptionCodesFields_changeHistory_field_Read",
	permission: boolean
};
	["RedemptionCodesFields_changeHistory_field_Update"]: {
	__typename: "RedemptionCodesFields_changeHistory_field_Update",
	permission: boolean
};
	["RedemptionCodesFields_changeHistory_field_Delete"]: {
	__typename: "RedemptionCodesFields_changeHistory_field_Delete",
	permission: boolean
};
	["RedemptionCodesFields_changeHistory_oldValue"]: {
	__typename: "RedemptionCodesFields_changeHistory_oldValue",
	create?: GraphQLTypes["RedemptionCodesFields_changeHistory_oldValue_Create"] | undefined,
	read?: GraphQLTypes["RedemptionCodesFields_changeHistory_oldValue_Read"] | undefined,
	update?: GraphQLTypes["RedemptionCodesFields_changeHistory_oldValue_Update"] | undefined,
	delete?: GraphQLTypes["RedemptionCodesFields_changeHistory_oldValue_Delete"] | undefined
};
	["RedemptionCodesFields_changeHistory_oldValue_Create"]: {
	__typename: "RedemptionCodesFields_changeHistory_oldValue_Create",
	permission: boolean
};
	["RedemptionCodesFields_changeHistory_oldValue_Read"]: {
	__typename: "RedemptionCodesFields_changeHistory_oldValue_Read",
	permission: boolean
};
	["RedemptionCodesFields_changeHistory_oldValue_Update"]: {
	__typename: "RedemptionCodesFields_changeHistory_oldValue_Update",
	permission: boolean
};
	["RedemptionCodesFields_changeHistory_oldValue_Delete"]: {
	__typename: "RedemptionCodesFields_changeHistory_oldValue_Delete",
	permission: boolean
};
	["RedemptionCodesFields_changeHistory_newValue"]: {
	__typename: "RedemptionCodesFields_changeHistory_newValue",
	create?: GraphQLTypes["RedemptionCodesFields_changeHistory_newValue_Create"] | undefined,
	read?: GraphQLTypes["RedemptionCodesFields_changeHistory_newValue_Read"] | undefined,
	update?: GraphQLTypes["RedemptionCodesFields_changeHistory_newValue_Update"] | undefined,
	delete?: GraphQLTypes["RedemptionCodesFields_changeHistory_newValue_Delete"] | undefined
};
	["RedemptionCodesFields_changeHistory_newValue_Create"]: {
	__typename: "RedemptionCodesFields_changeHistory_newValue_Create",
	permission: boolean
};
	["RedemptionCodesFields_changeHistory_newValue_Read"]: {
	__typename: "RedemptionCodesFields_changeHistory_newValue_Read",
	permission: boolean
};
	["RedemptionCodesFields_changeHistory_newValue_Update"]: {
	__typename: "RedemptionCodesFields_changeHistory_newValue_Update",
	permission: boolean
};
	["RedemptionCodesFields_changeHistory_newValue_Delete"]: {
	__typename: "RedemptionCodesFields_changeHistory_newValue_Delete",
	permission: boolean
};
	["RedemptionCodesFields_changeHistory_id"]: {
	__typename: "RedemptionCodesFields_changeHistory_id",
	create?: GraphQLTypes["RedemptionCodesFields_changeHistory_id_Create"] | undefined,
	read?: GraphQLTypes["RedemptionCodesFields_changeHistory_id_Read"] | undefined,
	update?: GraphQLTypes["RedemptionCodesFields_changeHistory_id_Update"] | undefined,
	delete?: GraphQLTypes["RedemptionCodesFields_changeHistory_id_Delete"] | undefined
};
	["RedemptionCodesFields_changeHistory_id_Create"]: {
	__typename: "RedemptionCodesFields_changeHistory_id_Create",
	permission: boolean
};
	["RedemptionCodesFields_changeHistory_id_Read"]: {
	__typename: "RedemptionCodesFields_changeHistory_id_Read",
	permission: boolean
};
	["RedemptionCodesFields_changeHistory_id_Update"]: {
	__typename: "RedemptionCodesFields_changeHistory_id_Update",
	permission: boolean
};
	["RedemptionCodesFields_changeHistory_id_Delete"]: {
	__typename: "RedemptionCodesFields_changeHistory_id_Delete",
	permission: boolean
};
	["RedemptionCodesFields_updatedAt"]: {
	__typename: "RedemptionCodesFields_updatedAt",
	create?: GraphQLTypes["RedemptionCodesFields_updatedAt_Create"] | undefined,
	read?: GraphQLTypes["RedemptionCodesFields_updatedAt_Read"] | undefined,
	update?: GraphQLTypes["RedemptionCodesFields_updatedAt_Update"] | undefined,
	delete?: GraphQLTypes["RedemptionCodesFields_updatedAt_Delete"] | undefined
};
	["RedemptionCodesFields_updatedAt_Create"]: {
	__typename: "RedemptionCodesFields_updatedAt_Create",
	permission: boolean
};
	["RedemptionCodesFields_updatedAt_Read"]: {
	__typename: "RedemptionCodesFields_updatedAt_Read",
	permission: boolean
};
	["RedemptionCodesFields_updatedAt_Update"]: {
	__typename: "RedemptionCodesFields_updatedAt_Update",
	permission: boolean
};
	["RedemptionCodesFields_updatedAt_Delete"]: {
	__typename: "RedemptionCodesFields_updatedAt_Delete",
	permission: boolean
};
	["RedemptionCodesFields_createdAt"]: {
	__typename: "RedemptionCodesFields_createdAt",
	create?: GraphQLTypes["RedemptionCodesFields_createdAt_Create"] | undefined,
	read?: GraphQLTypes["RedemptionCodesFields_createdAt_Read"] | undefined,
	update?: GraphQLTypes["RedemptionCodesFields_createdAt_Update"] | undefined,
	delete?: GraphQLTypes["RedemptionCodesFields_createdAt_Delete"] | undefined
};
	["RedemptionCodesFields_createdAt_Create"]: {
	__typename: "RedemptionCodesFields_createdAt_Create",
	permission: boolean
};
	["RedemptionCodesFields_createdAt_Read"]: {
	__typename: "RedemptionCodesFields_createdAt_Read",
	permission: boolean
};
	["RedemptionCodesFields_createdAt_Update"]: {
	__typename: "RedemptionCodesFields_createdAt_Update",
	permission: boolean
};
	["RedemptionCodesFields_createdAt_Delete"]: {
	__typename: "RedemptionCodesFields_createdAt_Delete",
	permission: boolean
};
	["RedemptionCodesCreateAccess"]: {
	__typename: "RedemptionCodesCreateAccess",
	permission: boolean,
	where?: GraphQLTypes["JSONObject"] | undefined
};
	["RedemptionCodesReadAccess"]: {
	__typename: "RedemptionCodesReadAccess",
	permission: boolean,
	where?: GraphQLTypes["JSONObject"] | undefined
};
	["RedemptionCodesUpdateAccess"]: {
	__typename: "RedemptionCodesUpdateAccess",
	permission: boolean,
	where?: GraphQLTypes["JSONObject"] | undefined
};
	["RedemptionCodesDeleteAccess"]: {
	__typename: "RedemptionCodesDeleteAccess",
	permission: boolean,
	where?: GraphQLTypes["JSONObject"] | undefined
};
	["redemption_pdfAccess"]: {
	__typename: "redemption_pdfAccess",
	fields?: GraphQLTypes["RedemptionPdfFields"] | undefined,
	create?: GraphQLTypes["RedemptionPdfCreateAccess"] | undefined,
	read?: GraphQLTypes["RedemptionPdfReadAccess"] | undefined,
	update?: GraphQLTypes["RedemptionPdfUpdateAccess"] | undefined,
	delete?: GraphQLTypes["RedemptionPdfDeleteAccess"] | undefined
};
	["RedemptionPdfFields"]: {
	__typename: "RedemptionPdfFields",
	updatedAt?: GraphQLTypes["RedemptionPdfFields_updatedAt"] | undefined,
	createdAt?: GraphQLTypes["RedemptionPdfFields_createdAt"] | undefined,
	url?: GraphQLTypes["RedemptionPdfFields_url"] | undefined,
	filename?: GraphQLTypes["RedemptionPdfFields_filename"] | undefined,
	mimeType?: GraphQLTypes["RedemptionPdfFields_mimeType"] | undefined,
	filesize?: GraphQLTypes["RedemptionPdfFields_filesize"] | undefined,
	width?: GraphQLTypes["RedemptionPdfFields_width"] | undefined,
	height?: GraphQLTypes["RedemptionPdfFields_height"] | undefined,
	focalX?: GraphQLTypes["RedemptionPdfFields_focalX"] | undefined,
	focalY?: GraphQLTypes["RedemptionPdfFields_focalY"] | undefined
};
	["RedemptionPdfFields_updatedAt"]: {
	__typename: "RedemptionPdfFields_updatedAt",
	create?: GraphQLTypes["RedemptionPdfFields_updatedAt_Create"] | undefined,
	read?: GraphQLTypes["RedemptionPdfFields_updatedAt_Read"] | undefined,
	update?: GraphQLTypes["RedemptionPdfFields_updatedAt_Update"] | undefined,
	delete?: GraphQLTypes["RedemptionPdfFields_updatedAt_Delete"] | undefined
};
	["RedemptionPdfFields_updatedAt_Create"]: {
	__typename: "RedemptionPdfFields_updatedAt_Create",
	permission: boolean
};
	["RedemptionPdfFields_updatedAt_Read"]: {
	__typename: "RedemptionPdfFields_updatedAt_Read",
	permission: boolean
};
	["RedemptionPdfFields_updatedAt_Update"]: {
	__typename: "RedemptionPdfFields_updatedAt_Update",
	permission: boolean
};
	["RedemptionPdfFields_updatedAt_Delete"]: {
	__typename: "RedemptionPdfFields_updatedAt_Delete",
	permission: boolean
};
	["RedemptionPdfFields_createdAt"]: {
	__typename: "RedemptionPdfFields_createdAt",
	create?: GraphQLTypes["RedemptionPdfFields_createdAt_Create"] | undefined,
	read?: GraphQLTypes["RedemptionPdfFields_createdAt_Read"] | undefined,
	update?: GraphQLTypes["RedemptionPdfFields_createdAt_Update"] | undefined,
	delete?: GraphQLTypes["RedemptionPdfFields_createdAt_Delete"] | undefined
};
	["RedemptionPdfFields_createdAt_Create"]: {
	__typename: "RedemptionPdfFields_createdAt_Create",
	permission: boolean
};
	["RedemptionPdfFields_createdAt_Read"]: {
	__typename: "RedemptionPdfFields_createdAt_Read",
	permission: boolean
};
	["RedemptionPdfFields_createdAt_Update"]: {
	__typename: "RedemptionPdfFields_createdAt_Update",
	permission: boolean
};
	["RedemptionPdfFields_createdAt_Delete"]: {
	__typename: "RedemptionPdfFields_createdAt_Delete",
	permission: boolean
};
	["RedemptionPdfFields_url"]: {
	__typename: "RedemptionPdfFields_url",
	create?: GraphQLTypes["RedemptionPdfFields_url_Create"] | undefined,
	read?: GraphQLTypes["RedemptionPdfFields_url_Read"] | undefined,
	update?: GraphQLTypes["RedemptionPdfFields_url_Update"] | undefined,
	delete?: GraphQLTypes["RedemptionPdfFields_url_Delete"] | undefined
};
	["RedemptionPdfFields_url_Create"]: {
	__typename: "RedemptionPdfFields_url_Create",
	permission: boolean
};
	["RedemptionPdfFields_url_Read"]: {
	__typename: "RedemptionPdfFields_url_Read",
	permission: boolean
};
	["RedemptionPdfFields_url_Update"]: {
	__typename: "RedemptionPdfFields_url_Update",
	permission: boolean
};
	["RedemptionPdfFields_url_Delete"]: {
	__typename: "RedemptionPdfFields_url_Delete",
	permission: boolean
};
	["RedemptionPdfFields_filename"]: {
	__typename: "RedemptionPdfFields_filename",
	create?: GraphQLTypes["RedemptionPdfFields_filename_Create"] | undefined,
	read?: GraphQLTypes["RedemptionPdfFields_filename_Read"] | undefined,
	update?: GraphQLTypes["RedemptionPdfFields_filename_Update"] | undefined,
	delete?: GraphQLTypes["RedemptionPdfFields_filename_Delete"] | undefined
};
	["RedemptionPdfFields_filename_Create"]: {
	__typename: "RedemptionPdfFields_filename_Create",
	permission: boolean
};
	["RedemptionPdfFields_filename_Read"]: {
	__typename: "RedemptionPdfFields_filename_Read",
	permission: boolean
};
	["RedemptionPdfFields_filename_Update"]: {
	__typename: "RedemptionPdfFields_filename_Update",
	permission: boolean
};
	["RedemptionPdfFields_filename_Delete"]: {
	__typename: "RedemptionPdfFields_filename_Delete",
	permission: boolean
};
	["RedemptionPdfFields_mimeType"]: {
	__typename: "RedemptionPdfFields_mimeType",
	create?: GraphQLTypes["RedemptionPdfFields_mimeType_Create"] | undefined,
	read?: GraphQLTypes["RedemptionPdfFields_mimeType_Read"] | undefined,
	update?: GraphQLTypes["RedemptionPdfFields_mimeType_Update"] | undefined,
	delete?: GraphQLTypes["RedemptionPdfFields_mimeType_Delete"] | undefined
};
	["RedemptionPdfFields_mimeType_Create"]: {
	__typename: "RedemptionPdfFields_mimeType_Create",
	permission: boolean
};
	["RedemptionPdfFields_mimeType_Read"]: {
	__typename: "RedemptionPdfFields_mimeType_Read",
	permission: boolean
};
	["RedemptionPdfFields_mimeType_Update"]: {
	__typename: "RedemptionPdfFields_mimeType_Update",
	permission: boolean
};
	["RedemptionPdfFields_mimeType_Delete"]: {
	__typename: "RedemptionPdfFields_mimeType_Delete",
	permission: boolean
};
	["RedemptionPdfFields_filesize"]: {
	__typename: "RedemptionPdfFields_filesize",
	create?: GraphQLTypes["RedemptionPdfFields_filesize_Create"] | undefined,
	read?: GraphQLTypes["RedemptionPdfFields_filesize_Read"] | undefined,
	update?: GraphQLTypes["RedemptionPdfFields_filesize_Update"] | undefined,
	delete?: GraphQLTypes["RedemptionPdfFields_filesize_Delete"] | undefined
};
	["RedemptionPdfFields_filesize_Create"]: {
	__typename: "RedemptionPdfFields_filesize_Create",
	permission: boolean
};
	["RedemptionPdfFields_filesize_Read"]: {
	__typename: "RedemptionPdfFields_filesize_Read",
	permission: boolean
};
	["RedemptionPdfFields_filesize_Update"]: {
	__typename: "RedemptionPdfFields_filesize_Update",
	permission: boolean
};
	["RedemptionPdfFields_filesize_Delete"]: {
	__typename: "RedemptionPdfFields_filesize_Delete",
	permission: boolean
};
	["RedemptionPdfFields_width"]: {
	__typename: "RedemptionPdfFields_width",
	create?: GraphQLTypes["RedemptionPdfFields_width_Create"] | undefined,
	read?: GraphQLTypes["RedemptionPdfFields_width_Read"] | undefined,
	update?: GraphQLTypes["RedemptionPdfFields_width_Update"] | undefined,
	delete?: GraphQLTypes["RedemptionPdfFields_width_Delete"] | undefined
};
	["RedemptionPdfFields_width_Create"]: {
	__typename: "RedemptionPdfFields_width_Create",
	permission: boolean
};
	["RedemptionPdfFields_width_Read"]: {
	__typename: "RedemptionPdfFields_width_Read",
	permission: boolean
};
	["RedemptionPdfFields_width_Update"]: {
	__typename: "RedemptionPdfFields_width_Update",
	permission: boolean
};
	["RedemptionPdfFields_width_Delete"]: {
	__typename: "RedemptionPdfFields_width_Delete",
	permission: boolean
};
	["RedemptionPdfFields_height"]: {
	__typename: "RedemptionPdfFields_height",
	create?: GraphQLTypes["RedemptionPdfFields_height_Create"] | undefined,
	read?: GraphQLTypes["RedemptionPdfFields_height_Read"] | undefined,
	update?: GraphQLTypes["RedemptionPdfFields_height_Update"] | undefined,
	delete?: GraphQLTypes["RedemptionPdfFields_height_Delete"] | undefined
};
	["RedemptionPdfFields_height_Create"]: {
	__typename: "RedemptionPdfFields_height_Create",
	permission: boolean
};
	["RedemptionPdfFields_height_Read"]: {
	__typename: "RedemptionPdfFields_height_Read",
	permission: boolean
};
	["RedemptionPdfFields_height_Update"]: {
	__typename: "RedemptionPdfFields_height_Update",
	permission: boolean
};
	["RedemptionPdfFields_height_Delete"]: {
	__typename: "RedemptionPdfFields_height_Delete",
	permission: boolean
};
	["RedemptionPdfFields_focalX"]: {
	__typename: "RedemptionPdfFields_focalX",
	create?: GraphQLTypes["RedemptionPdfFields_focalX_Create"] | undefined,
	read?: GraphQLTypes["RedemptionPdfFields_focalX_Read"] | undefined,
	update?: GraphQLTypes["RedemptionPdfFields_focalX_Update"] | undefined,
	delete?: GraphQLTypes["RedemptionPdfFields_focalX_Delete"] | undefined
};
	["RedemptionPdfFields_focalX_Create"]: {
	__typename: "RedemptionPdfFields_focalX_Create",
	permission: boolean
};
	["RedemptionPdfFields_focalX_Read"]: {
	__typename: "RedemptionPdfFields_focalX_Read",
	permission: boolean
};
	["RedemptionPdfFields_focalX_Update"]: {
	__typename: "RedemptionPdfFields_focalX_Update",
	permission: boolean
};
	["RedemptionPdfFields_focalX_Delete"]: {
	__typename: "RedemptionPdfFields_focalX_Delete",
	permission: boolean
};
	["RedemptionPdfFields_focalY"]: {
	__typename: "RedemptionPdfFields_focalY",
	create?: GraphQLTypes["RedemptionPdfFields_focalY_Create"] | undefined,
	read?: GraphQLTypes["RedemptionPdfFields_focalY_Read"] | undefined,
	update?: GraphQLTypes["RedemptionPdfFields_focalY_Update"] | undefined,
	delete?: GraphQLTypes["RedemptionPdfFields_focalY_Delete"] | undefined
};
	["RedemptionPdfFields_focalY_Create"]: {
	__typename: "RedemptionPdfFields_focalY_Create",
	permission: boolean
};
	["RedemptionPdfFields_focalY_Read"]: {
	__typename: "RedemptionPdfFields_focalY_Read",
	permission: boolean
};
	["RedemptionPdfFields_focalY_Update"]: {
	__typename: "RedemptionPdfFields_focalY_Update",
	permission: boolean
};
	["RedemptionPdfFields_focalY_Delete"]: {
	__typename: "RedemptionPdfFields_focalY_Delete",
	permission: boolean
};
	["RedemptionPdfCreateAccess"]: {
	__typename: "RedemptionPdfCreateAccess",
	permission: boolean,
	where?: GraphQLTypes["JSONObject"] | undefined
};
	["RedemptionPdfReadAccess"]: {
	__typename: "RedemptionPdfReadAccess",
	permission: boolean,
	where?: GraphQLTypes["JSONObject"] | undefined
};
	["RedemptionPdfUpdateAccess"]: {
	__typename: "RedemptionPdfUpdateAccess",
	permission: boolean,
	where?: GraphQLTypes["JSONObject"] | undefined
};
	["RedemptionPdfDeleteAccess"]: {
	__typename: "RedemptionPdfDeleteAccess",
	permission: boolean,
	where?: GraphQLTypes["JSONObject"] | undefined
};
	["check_in_redemptionAccess"]: {
	__typename: "check_in_redemptionAccess",
	fields?: GraphQLTypes["CheckInRedemptionFields"] | undefined,
	create?: GraphQLTypes["CheckInRedemptionCreateAccess"] | undefined,
	read?: GraphQLTypes["CheckInRedemptionReadAccess"] | undefined,
	update?: GraphQLTypes["CheckInRedemptionUpdateAccess"] | undefined,
	delete?: GraphQLTypes["CheckInRedemptionDeleteAccess"] | undefined
};
	["CheckInRedemptionFields"]: {
	__typename: "CheckInRedemptionFields",
	creator?: GraphQLTypes["CheckInRedemptionFields_creator"] | undefined,
	qrCodeId?: GraphQLTypes["CheckInRedemptionFields_qrCodeId"] | undefined,
	codeownerName?: GraphQLTypes["CheckInRedemptionFields_codeownerName"] | undefined,
	name?: GraphQLTypes["CheckInRedemptionFields_name"] | undefined,
	phone?: GraphQLTypes["CheckInRedemptionFields_phone"] | undefined,
	linkedEvent?: GraphQLTypes["CheckInRedemptionFields_linkedEvent"] | undefined,
	entryNumber?: GraphQLTypes["CheckInRedemptionFields_entryNumber"] | undefined,
	scanAt?: GraphQLTypes["CheckInRedemptionFields_scanAt"] | undefined,
	uploadAt?: GraphQLTypes["CheckInRedemptionFields_uploadAt"] | undefined,
	status?: GraphQLTypes["CheckInRedemptionFields_status"] | undefined,
	failReason?: GraphQLTypes["CheckInRedemptionFields_failReason"] | undefined,
	variants?: GraphQLTypes["CheckInRedemptionFields_variants"] | undefined,
	checkInAppSelectionId?: GraphQLTypes["CheckInRedemptionFields_checkInAppSelectionId"] | undefined,
	qrCodeStatus?: GraphQLTypes["CheckInRedemptionFields_qrCodeStatus"] | undefined,
	productType?: GraphQLTypes["CheckInRedemptionFields_productType"] | undefined,
	updatedAt?: GraphQLTypes["CheckInRedemptionFields_updatedAt"] | undefined,
	createdAt?: GraphQLTypes["CheckInRedemptionFields_createdAt"] | undefined
};
	["CheckInRedemptionFields_creator"]: {
	__typename: "CheckInRedemptionFields_creator",
	create?: GraphQLTypes["CheckInRedemptionFields_creator_Create"] | undefined,
	read?: GraphQLTypes["CheckInRedemptionFields_creator_Read"] | undefined,
	update?: GraphQLTypes["CheckInRedemptionFields_creator_Update"] | undefined,
	delete?: GraphQLTypes["CheckInRedemptionFields_creator_Delete"] | undefined
};
	["CheckInRedemptionFields_creator_Create"]: {
	__typename: "CheckInRedemptionFields_creator_Create",
	permission: boolean
};
	["CheckInRedemptionFields_creator_Read"]: {
	__typename: "CheckInRedemptionFields_creator_Read",
	permission: boolean
};
	["CheckInRedemptionFields_creator_Update"]: {
	__typename: "CheckInRedemptionFields_creator_Update",
	permission: boolean
};
	["CheckInRedemptionFields_creator_Delete"]: {
	__typename: "CheckInRedemptionFields_creator_Delete",
	permission: boolean
};
	["CheckInRedemptionFields_qrCodeId"]: {
	__typename: "CheckInRedemptionFields_qrCodeId",
	create?: GraphQLTypes["CheckInRedemptionFields_qrCodeId_Create"] | undefined,
	read?: GraphQLTypes["CheckInRedemptionFields_qrCodeId_Read"] | undefined,
	update?: GraphQLTypes["CheckInRedemptionFields_qrCodeId_Update"] | undefined,
	delete?: GraphQLTypes["CheckInRedemptionFields_qrCodeId_Delete"] | undefined
};
	["CheckInRedemptionFields_qrCodeId_Create"]: {
	__typename: "CheckInRedemptionFields_qrCodeId_Create",
	permission: boolean
};
	["CheckInRedemptionFields_qrCodeId_Read"]: {
	__typename: "CheckInRedemptionFields_qrCodeId_Read",
	permission: boolean
};
	["CheckInRedemptionFields_qrCodeId_Update"]: {
	__typename: "CheckInRedemptionFields_qrCodeId_Update",
	permission: boolean
};
	["CheckInRedemptionFields_qrCodeId_Delete"]: {
	__typename: "CheckInRedemptionFields_qrCodeId_Delete",
	permission: boolean
};
	["CheckInRedemptionFields_codeownerName"]: {
	__typename: "CheckInRedemptionFields_codeownerName",
	create?: GraphQLTypes["CheckInRedemptionFields_codeownerName_Create"] | undefined,
	read?: GraphQLTypes["CheckInRedemptionFields_codeownerName_Read"] | undefined,
	update?: GraphQLTypes["CheckInRedemptionFields_codeownerName_Update"] | undefined,
	delete?: GraphQLTypes["CheckInRedemptionFields_codeownerName_Delete"] | undefined
};
	["CheckInRedemptionFields_codeownerName_Create"]: {
	__typename: "CheckInRedemptionFields_codeownerName_Create",
	permission: boolean
};
	["CheckInRedemptionFields_codeownerName_Read"]: {
	__typename: "CheckInRedemptionFields_codeownerName_Read",
	permission: boolean
};
	["CheckInRedemptionFields_codeownerName_Update"]: {
	__typename: "CheckInRedemptionFields_codeownerName_Update",
	permission: boolean
};
	["CheckInRedemptionFields_codeownerName_Delete"]: {
	__typename: "CheckInRedemptionFields_codeownerName_Delete",
	permission: boolean
};
	["CheckInRedemptionFields_name"]: {
	__typename: "CheckInRedemptionFields_name",
	create?: GraphQLTypes["CheckInRedemptionFields_name_Create"] | undefined,
	read?: GraphQLTypes["CheckInRedemptionFields_name_Read"] | undefined,
	update?: GraphQLTypes["CheckInRedemptionFields_name_Update"] | undefined,
	delete?: GraphQLTypes["CheckInRedemptionFields_name_Delete"] | undefined
};
	["CheckInRedemptionFields_name_Create"]: {
	__typename: "CheckInRedemptionFields_name_Create",
	permission: boolean
};
	["CheckInRedemptionFields_name_Read"]: {
	__typename: "CheckInRedemptionFields_name_Read",
	permission: boolean
};
	["CheckInRedemptionFields_name_Update"]: {
	__typename: "CheckInRedemptionFields_name_Update",
	permission: boolean
};
	["CheckInRedemptionFields_name_Delete"]: {
	__typename: "CheckInRedemptionFields_name_Delete",
	permission: boolean
};
	["CheckInRedemptionFields_phone"]: {
	__typename: "CheckInRedemptionFields_phone",
	create?: GraphQLTypes["CheckInRedemptionFields_phone_Create"] | undefined,
	read?: GraphQLTypes["CheckInRedemptionFields_phone_Read"] | undefined,
	update?: GraphQLTypes["CheckInRedemptionFields_phone_Update"] | undefined,
	delete?: GraphQLTypes["CheckInRedemptionFields_phone_Delete"] | undefined
};
	["CheckInRedemptionFields_phone_Create"]: {
	__typename: "CheckInRedemptionFields_phone_Create",
	permission: boolean
};
	["CheckInRedemptionFields_phone_Read"]: {
	__typename: "CheckInRedemptionFields_phone_Read",
	permission: boolean
};
	["CheckInRedemptionFields_phone_Update"]: {
	__typename: "CheckInRedemptionFields_phone_Update",
	permission: boolean
};
	["CheckInRedemptionFields_phone_Delete"]: {
	__typename: "CheckInRedemptionFields_phone_Delete",
	permission: boolean
};
	["CheckInRedemptionFields_linkedEvent"]: {
	__typename: "CheckInRedemptionFields_linkedEvent",
	create?: GraphQLTypes["CheckInRedemptionFields_linkedEvent_Create"] | undefined,
	read?: GraphQLTypes["CheckInRedemptionFields_linkedEvent_Read"] | undefined,
	update?: GraphQLTypes["CheckInRedemptionFields_linkedEvent_Update"] | undefined,
	delete?: GraphQLTypes["CheckInRedemptionFields_linkedEvent_Delete"] | undefined
};
	["CheckInRedemptionFields_linkedEvent_Create"]: {
	__typename: "CheckInRedemptionFields_linkedEvent_Create",
	permission: boolean
};
	["CheckInRedemptionFields_linkedEvent_Read"]: {
	__typename: "CheckInRedemptionFields_linkedEvent_Read",
	permission: boolean
};
	["CheckInRedemptionFields_linkedEvent_Update"]: {
	__typename: "CheckInRedemptionFields_linkedEvent_Update",
	permission: boolean
};
	["CheckInRedemptionFields_linkedEvent_Delete"]: {
	__typename: "CheckInRedemptionFields_linkedEvent_Delete",
	permission: boolean
};
	["CheckInRedemptionFields_entryNumber"]: {
	__typename: "CheckInRedemptionFields_entryNumber",
	create?: GraphQLTypes["CheckInRedemptionFields_entryNumber_Create"] | undefined,
	read?: GraphQLTypes["CheckInRedemptionFields_entryNumber_Read"] | undefined,
	update?: GraphQLTypes["CheckInRedemptionFields_entryNumber_Update"] | undefined,
	delete?: GraphQLTypes["CheckInRedemptionFields_entryNumber_Delete"] | undefined
};
	["CheckInRedemptionFields_entryNumber_Create"]: {
	__typename: "CheckInRedemptionFields_entryNumber_Create",
	permission: boolean
};
	["CheckInRedemptionFields_entryNumber_Read"]: {
	__typename: "CheckInRedemptionFields_entryNumber_Read",
	permission: boolean
};
	["CheckInRedemptionFields_entryNumber_Update"]: {
	__typename: "CheckInRedemptionFields_entryNumber_Update",
	permission: boolean
};
	["CheckInRedemptionFields_entryNumber_Delete"]: {
	__typename: "CheckInRedemptionFields_entryNumber_Delete",
	permission: boolean
};
	["CheckInRedemptionFields_scanAt"]: {
	__typename: "CheckInRedemptionFields_scanAt",
	create?: GraphQLTypes["CheckInRedemptionFields_scanAt_Create"] | undefined,
	read?: GraphQLTypes["CheckInRedemptionFields_scanAt_Read"] | undefined,
	update?: GraphQLTypes["CheckInRedemptionFields_scanAt_Update"] | undefined,
	delete?: GraphQLTypes["CheckInRedemptionFields_scanAt_Delete"] | undefined
};
	["CheckInRedemptionFields_scanAt_Create"]: {
	__typename: "CheckInRedemptionFields_scanAt_Create",
	permission: boolean
};
	["CheckInRedemptionFields_scanAt_Read"]: {
	__typename: "CheckInRedemptionFields_scanAt_Read",
	permission: boolean
};
	["CheckInRedemptionFields_scanAt_Update"]: {
	__typename: "CheckInRedemptionFields_scanAt_Update",
	permission: boolean
};
	["CheckInRedemptionFields_scanAt_Delete"]: {
	__typename: "CheckInRedemptionFields_scanAt_Delete",
	permission: boolean
};
	["CheckInRedemptionFields_uploadAt"]: {
	__typename: "CheckInRedemptionFields_uploadAt",
	create?: GraphQLTypes["CheckInRedemptionFields_uploadAt_Create"] | undefined,
	read?: GraphQLTypes["CheckInRedemptionFields_uploadAt_Read"] | undefined,
	update?: GraphQLTypes["CheckInRedemptionFields_uploadAt_Update"] | undefined,
	delete?: GraphQLTypes["CheckInRedemptionFields_uploadAt_Delete"] | undefined
};
	["CheckInRedemptionFields_uploadAt_Create"]: {
	__typename: "CheckInRedemptionFields_uploadAt_Create",
	permission: boolean
};
	["CheckInRedemptionFields_uploadAt_Read"]: {
	__typename: "CheckInRedemptionFields_uploadAt_Read",
	permission: boolean
};
	["CheckInRedemptionFields_uploadAt_Update"]: {
	__typename: "CheckInRedemptionFields_uploadAt_Update",
	permission: boolean
};
	["CheckInRedemptionFields_uploadAt_Delete"]: {
	__typename: "CheckInRedemptionFields_uploadAt_Delete",
	permission: boolean
};
	["CheckInRedemptionFields_status"]: {
	__typename: "CheckInRedemptionFields_status",
	create?: GraphQLTypes["CheckInRedemptionFields_status_Create"] | undefined,
	read?: GraphQLTypes["CheckInRedemptionFields_status_Read"] | undefined,
	update?: GraphQLTypes["CheckInRedemptionFields_status_Update"] | undefined,
	delete?: GraphQLTypes["CheckInRedemptionFields_status_Delete"] | undefined
};
	["CheckInRedemptionFields_status_Create"]: {
	__typename: "CheckInRedemptionFields_status_Create",
	permission: boolean
};
	["CheckInRedemptionFields_status_Read"]: {
	__typename: "CheckInRedemptionFields_status_Read",
	permission: boolean
};
	["CheckInRedemptionFields_status_Update"]: {
	__typename: "CheckInRedemptionFields_status_Update",
	permission: boolean
};
	["CheckInRedemptionFields_status_Delete"]: {
	__typename: "CheckInRedemptionFields_status_Delete",
	permission: boolean
};
	["CheckInRedemptionFields_failReason"]: {
	__typename: "CheckInRedemptionFields_failReason",
	create?: GraphQLTypes["CheckInRedemptionFields_failReason_Create"] | undefined,
	read?: GraphQLTypes["CheckInRedemptionFields_failReason_Read"] | undefined,
	update?: GraphQLTypes["CheckInRedemptionFields_failReason_Update"] | undefined,
	delete?: GraphQLTypes["CheckInRedemptionFields_failReason_Delete"] | undefined
};
	["CheckInRedemptionFields_failReason_Create"]: {
	__typename: "CheckInRedemptionFields_failReason_Create",
	permission: boolean
};
	["CheckInRedemptionFields_failReason_Read"]: {
	__typename: "CheckInRedemptionFields_failReason_Read",
	permission: boolean
};
	["CheckInRedemptionFields_failReason_Update"]: {
	__typename: "CheckInRedemptionFields_failReason_Update",
	permission: boolean
};
	["CheckInRedemptionFields_failReason_Delete"]: {
	__typename: "CheckInRedemptionFields_failReason_Delete",
	permission: boolean
};
	["CheckInRedemptionFields_variants"]: {
	__typename: "CheckInRedemptionFields_variants",
	create?: GraphQLTypes["CheckInRedemptionFields_variants_Create"] | undefined,
	read?: GraphQLTypes["CheckInRedemptionFields_variants_Read"] | undefined,
	update?: GraphQLTypes["CheckInRedemptionFields_variants_Update"] | undefined,
	delete?: GraphQLTypes["CheckInRedemptionFields_variants_Delete"] | undefined,
	fields?: GraphQLTypes["CheckInRedemptionFields_variants_Fields"] | undefined
};
	["CheckInRedemptionFields_variants_Create"]: {
	__typename: "CheckInRedemptionFields_variants_Create",
	permission: boolean
};
	["CheckInRedemptionFields_variants_Read"]: {
	__typename: "CheckInRedemptionFields_variants_Read",
	permission: boolean
};
	["CheckInRedemptionFields_variants_Update"]: {
	__typename: "CheckInRedemptionFields_variants_Update",
	permission: boolean
};
	["CheckInRedemptionFields_variants_Delete"]: {
	__typename: "CheckInRedemptionFields_variants_Delete",
	permission: boolean
};
	["CheckInRedemptionFields_variants_Fields"]: {
	__typename: "CheckInRedemptionFields_variants_Fields",
	variantId?: GraphQLTypes["CheckInRedemptionFields_variants_variantId"] | undefined,
	name?: GraphQLTypes["CheckInRedemptionFields_variants_name"] | undefined,
	quantity?: GraphQLTypes["CheckInRedemptionFields_variants_quantity"] | undefined,
	totalCost?: GraphQLTypes["CheckInRedemptionFields_variants_totalCost"] | undefined,
	id?: GraphQLTypes["CheckInRedemptionFields_variants_id"] | undefined
};
	["CheckInRedemptionFields_variants_variantId"]: {
	__typename: "CheckInRedemptionFields_variants_variantId",
	create?: GraphQLTypes["CheckInRedemptionFields_variants_variantId_Create"] | undefined,
	read?: GraphQLTypes["CheckInRedemptionFields_variants_variantId_Read"] | undefined,
	update?: GraphQLTypes["CheckInRedemptionFields_variants_variantId_Update"] | undefined,
	delete?: GraphQLTypes["CheckInRedemptionFields_variants_variantId_Delete"] | undefined
};
	["CheckInRedemptionFields_variants_variantId_Create"]: {
	__typename: "CheckInRedemptionFields_variants_variantId_Create",
	permission: boolean
};
	["CheckInRedemptionFields_variants_variantId_Read"]: {
	__typename: "CheckInRedemptionFields_variants_variantId_Read",
	permission: boolean
};
	["CheckInRedemptionFields_variants_variantId_Update"]: {
	__typename: "CheckInRedemptionFields_variants_variantId_Update",
	permission: boolean
};
	["CheckInRedemptionFields_variants_variantId_Delete"]: {
	__typename: "CheckInRedemptionFields_variants_variantId_Delete",
	permission: boolean
};
	["CheckInRedemptionFields_variants_name"]: {
	__typename: "CheckInRedemptionFields_variants_name",
	create?: GraphQLTypes["CheckInRedemptionFields_variants_name_Create"] | undefined,
	read?: GraphQLTypes["CheckInRedemptionFields_variants_name_Read"] | undefined,
	update?: GraphQLTypes["CheckInRedemptionFields_variants_name_Update"] | undefined,
	delete?: GraphQLTypes["CheckInRedemptionFields_variants_name_Delete"] | undefined
};
	["CheckInRedemptionFields_variants_name_Create"]: {
	__typename: "CheckInRedemptionFields_variants_name_Create",
	permission: boolean
};
	["CheckInRedemptionFields_variants_name_Read"]: {
	__typename: "CheckInRedemptionFields_variants_name_Read",
	permission: boolean
};
	["CheckInRedemptionFields_variants_name_Update"]: {
	__typename: "CheckInRedemptionFields_variants_name_Update",
	permission: boolean
};
	["CheckInRedemptionFields_variants_name_Delete"]: {
	__typename: "CheckInRedemptionFields_variants_name_Delete",
	permission: boolean
};
	["CheckInRedemptionFields_variants_quantity"]: {
	__typename: "CheckInRedemptionFields_variants_quantity",
	create?: GraphQLTypes["CheckInRedemptionFields_variants_quantity_Create"] | undefined,
	read?: GraphQLTypes["CheckInRedemptionFields_variants_quantity_Read"] | undefined,
	update?: GraphQLTypes["CheckInRedemptionFields_variants_quantity_Update"] | undefined,
	delete?: GraphQLTypes["CheckInRedemptionFields_variants_quantity_Delete"] | undefined
};
	["CheckInRedemptionFields_variants_quantity_Create"]: {
	__typename: "CheckInRedemptionFields_variants_quantity_Create",
	permission: boolean
};
	["CheckInRedemptionFields_variants_quantity_Read"]: {
	__typename: "CheckInRedemptionFields_variants_quantity_Read",
	permission: boolean
};
	["CheckInRedemptionFields_variants_quantity_Update"]: {
	__typename: "CheckInRedemptionFields_variants_quantity_Update",
	permission: boolean
};
	["CheckInRedemptionFields_variants_quantity_Delete"]: {
	__typename: "CheckInRedemptionFields_variants_quantity_Delete",
	permission: boolean
};
	["CheckInRedemptionFields_variants_totalCost"]: {
	__typename: "CheckInRedemptionFields_variants_totalCost",
	create?: GraphQLTypes["CheckInRedemptionFields_variants_totalCost_Create"] | undefined,
	read?: GraphQLTypes["CheckInRedemptionFields_variants_totalCost_Read"] | undefined,
	update?: GraphQLTypes["CheckInRedemptionFields_variants_totalCost_Update"] | undefined,
	delete?: GraphQLTypes["CheckInRedemptionFields_variants_totalCost_Delete"] | undefined
};
	["CheckInRedemptionFields_variants_totalCost_Create"]: {
	__typename: "CheckInRedemptionFields_variants_totalCost_Create",
	permission: boolean
};
	["CheckInRedemptionFields_variants_totalCost_Read"]: {
	__typename: "CheckInRedemptionFields_variants_totalCost_Read",
	permission: boolean
};
	["CheckInRedemptionFields_variants_totalCost_Update"]: {
	__typename: "CheckInRedemptionFields_variants_totalCost_Update",
	permission: boolean
};
	["CheckInRedemptionFields_variants_totalCost_Delete"]: {
	__typename: "CheckInRedemptionFields_variants_totalCost_Delete",
	permission: boolean
};
	["CheckInRedemptionFields_variants_id"]: {
	__typename: "CheckInRedemptionFields_variants_id",
	create?: GraphQLTypes["CheckInRedemptionFields_variants_id_Create"] | undefined,
	read?: GraphQLTypes["CheckInRedemptionFields_variants_id_Read"] | undefined,
	update?: GraphQLTypes["CheckInRedemptionFields_variants_id_Update"] | undefined,
	delete?: GraphQLTypes["CheckInRedemptionFields_variants_id_Delete"] | undefined
};
	["CheckInRedemptionFields_variants_id_Create"]: {
	__typename: "CheckInRedemptionFields_variants_id_Create",
	permission: boolean
};
	["CheckInRedemptionFields_variants_id_Read"]: {
	__typename: "CheckInRedemptionFields_variants_id_Read",
	permission: boolean
};
	["CheckInRedemptionFields_variants_id_Update"]: {
	__typename: "CheckInRedemptionFields_variants_id_Update",
	permission: boolean
};
	["CheckInRedemptionFields_variants_id_Delete"]: {
	__typename: "CheckInRedemptionFields_variants_id_Delete",
	permission: boolean
};
	["CheckInRedemptionFields_checkInAppSelectionId"]: {
	__typename: "CheckInRedemptionFields_checkInAppSelectionId",
	create?: GraphQLTypes["CheckInRedemptionFields_checkInAppSelectionId_Create"] | undefined,
	read?: GraphQLTypes["CheckInRedemptionFields_checkInAppSelectionId_Read"] | undefined,
	update?: GraphQLTypes["CheckInRedemptionFields_checkInAppSelectionId_Update"] | undefined,
	delete?: GraphQLTypes["CheckInRedemptionFields_checkInAppSelectionId_Delete"] | undefined
};
	["CheckInRedemptionFields_checkInAppSelectionId_Create"]: {
	__typename: "CheckInRedemptionFields_checkInAppSelectionId_Create",
	permission: boolean
};
	["CheckInRedemptionFields_checkInAppSelectionId_Read"]: {
	__typename: "CheckInRedemptionFields_checkInAppSelectionId_Read",
	permission: boolean
};
	["CheckInRedemptionFields_checkInAppSelectionId_Update"]: {
	__typename: "CheckInRedemptionFields_checkInAppSelectionId_Update",
	permission: boolean
};
	["CheckInRedemptionFields_checkInAppSelectionId_Delete"]: {
	__typename: "CheckInRedemptionFields_checkInAppSelectionId_Delete",
	permission: boolean
};
	["CheckInRedemptionFields_qrCodeStatus"]: {
	__typename: "CheckInRedemptionFields_qrCodeStatus",
	create?: GraphQLTypes["CheckInRedemptionFields_qrCodeStatus_Create"] | undefined,
	read?: GraphQLTypes["CheckInRedemptionFields_qrCodeStatus_Read"] | undefined,
	update?: GraphQLTypes["CheckInRedemptionFields_qrCodeStatus_Update"] | undefined,
	delete?: GraphQLTypes["CheckInRedemptionFields_qrCodeStatus_Delete"] | undefined
};
	["CheckInRedemptionFields_qrCodeStatus_Create"]: {
	__typename: "CheckInRedemptionFields_qrCodeStatus_Create",
	permission: boolean
};
	["CheckInRedemptionFields_qrCodeStatus_Read"]: {
	__typename: "CheckInRedemptionFields_qrCodeStatus_Read",
	permission: boolean
};
	["CheckInRedemptionFields_qrCodeStatus_Update"]: {
	__typename: "CheckInRedemptionFields_qrCodeStatus_Update",
	permission: boolean
};
	["CheckInRedemptionFields_qrCodeStatus_Delete"]: {
	__typename: "CheckInRedemptionFields_qrCodeStatus_Delete",
	permission: boolean
};
	["CheckInRedemptionFields_productType"]: {
	__typename: "CheckInRedemptionFields_productType",
	create?: GraphQLTypes["CheckInRedemptionFields_productType_Create"] | undefined,
	read?: GraphQLTypes["CheckInRedemptionFields_productType_Read"] | undefined,
	update?: GraphQLTypes["CheckInRedemptionFields_productType_Update"] | undefined,
	delete?: GraphQLTypes["CheckInRedemptionFields_productType_Delete"] | undefined
};
	["CheckInRedemptionFields_productType_Create"]: {
	__typename: "CheckInRedemptionFields_productType_Create",
	permission: boolean
};
	["CheckInRedemptionFields_productType_Read"]: {
	__typename: "CheckInRedemptionFields_productType_Read",
	permission: boolean
};
	["CheckInRedemptionFields_productType_Update"]: {
	__typename: "CheckInRedemptionFields_productType_Update",
	permission: boolean
};
	["CheckInRedemptionFields_productType_Delete"]: {
	__typename: "CheckInRedemptionFields_productType_Delete",
	permission: boolean
};
	["CheckInRedemptionFields_updatedAt"]: {
	__typename: "CheckInRedemptionFields_updatedAt",
	create?: GraphQLTypes["CheckInRedemptionFields_updatedAt_Create"] | undefined,
	read?: GraphQLTypes["CheckInRedemptionFields_updatedAt_Read"] | undefined,
	update?: GraphQLTypes["CheckInRedemptionFields_updatedAt_Update"] | undefined,
	delete?: GraphQLTypes["CheckInRedemptionFields_updatedAt_Delete"] | undefined
};
	["CheckInRedemptionFields_updatedAt_Create"]: {
	__typename: "CheckInRedemptionFields_updatedAt_Create",
	permission: boolean
};
	["CheckInRedemptionFields_updatedAt_Read"]: {
	__typename: "CheckInRedemptionFields_updatedAt_Read",
	permission: boolean
};
	["CheckInRedemptionFields_updatedAt_Update"]: {
	__typename: "CheckInRedemptionFields_updatedAt_Update",
	permission: boolean
};
	["CheckInRedemptionFields_updatedAt_Delete"]: {
	__typename: "CheckInRedemptionFields_updatedAt_Delete",
	permission: boolean
};
	["CheckInRedemptionFields_createdAt"]: {
	__typename: "CheckInRedemptionFields_createdAt",
	create?: GraphQLTypes["CheckInRedemptionFields_createdAt_Create"] | undefined,
	read?: GraphQLTypes["CheckInRedemptionFields_createdAt_Read"] | undefined,
	update?: GraphQLTypes["CheckInRedemptionFields_createdAt_Update"] | undefined,
	delete?: GraphQLTypes["CheckInRedemptionFields_createdAt_Delete"] | undefined
};
	["CheckInRedemptionFields_createdAt_Create"]: {
	__typename: "CheckInRedemptionFields_createdAt_Create",
	permission: boolean
};
	["CheckInRedemptionFields_createdAt_Read"]: {
	__typename: "CheckInRedemptionFields_createdAt_Read",
	permission: boolean
};
	["CheckInRedemptionFields_createdAt_Update"]: {
	__typename: "CheckInRedemptionFields_createdAt_Update",
	permission: boolean
};
	["CheckInRedemptionFields_createdAt_Delete"]: {
	__typename: "CheckInRedemptionFields_createdAt_Delete",
	permission: boolean
};
	["CheckInRedemptionCreateAccess"]: {
	__typename: "CheckInRedemptionCreateAccess",
	permission: boolean,
	where?: GraphQLTypes["JSONObject"] | undefined
};
	["CheckInRedemptionReadAccess"]: {
	__typename: "CheckInRedemptionReadAccess",
	permission: boolean,
	where?: GraphQLTypes["JSONObject"] | undefined
};
	["CheckInRedemptionUpdateAccess"]: {
	__typename: "CheckInRedemptionUpdateAccess",
	permission: boolean,
	where?: GraphQLTypes["JSONObject"] | undefined
};
	["CheckInRedemptionDeleteAccess"]: {
	__typename: "CheckInRedemptionDeleteAccess",
	permission: boolean,
	where?: GraphQLTypes["JSONObject"] | undefined
};
	["usersAccess"]: {
	__typename: "usersAccess",
	fields?: GraphQLTypes["UsersFields"] | undefined,
	create?: GraphQLTypes["UsersCreateAccess"] | undefined,
	read?: GraphQLTypes["UsersReadAccess"] | undefined,
	update?: GraphQLTypes["UsersUpdateAccess"] | undefined,
	delete?: GraphQLTypes["UsersDeleteAccess"] | undefined,
	unlock?: GraphQLTypes["UsersUnlockAccess"] | undefined
};
	["UsersFields"]: {
	__typename: "UsersFields",
	region?: GraphQLTypes["UsersFields_region"] | undefined,
	name?: GraphQLTypes["UsersFields_name"] | undefined,
	displayEmail?: GraphQLTypes["UsersFields_displayEmail"] | undefined,
	legacyUsername?: GraphQLTypes["UsersFields_legacyUsername"] | undefined,
	roles?: GraphQLTypes["UsersFields_roles"] | undefined,
	member?: GraphQLTypes["UsersFields_member"] | undefined,
	organiser?: GraphQLTypes["UsersFields_organiser"] | undefined,
	emailVerified?: GraphQLTypes["UsersFields_emailVerified"] | undefined,
	sso?: GraphQLTypes["UsersFields_sso"] | undefined,
	oldPassword?: GraphQLTypes["UsersFields_oldPassword"] | undefined,
	status?: GraphQLTypes["UsersFields_status"] | undefined,
	updatedAt?: GraphQLTypes["UsersFields_updatedAt"] | undefined,
	createdAt?: GraphQLTypes["UsersFields_createdAt"] | undefined,
	email?: GraphQLTypes["UsersFields_email"] | undefined,
	password?: GraphQLTypes["UsersFields_password"] | undefined
};
	["UsersFields_region"]: {
	__typename: "UsersFields_region",
	create?: GraphQLTypes["UsersFields_region_Create"] | undefined,
	read?: GraphQLTypes["UsersFields_region_Read"] | undefined,
	update?: GraphQLTypes["UsersFields_region_Update"] | undefined,
	delete?: GraphQLTypes["UsersFields_region_Delete"] | undefined
};
	["UsersFields_region_Create"]: {
	__typename: "UsersFields_region_Create",
	permission: boolean
};
	["UsersFields_region_Read"]: {
	__typename: "UsersFields_region_Read",
	permission: boolean
};
	["UsersFields_region_Update"]: {
	__typename: "UsersFields_region_Update",
	permission: boolean
};
	["UsersFields_region_Delete"]: {
	__typename: "UsersFields_region_Delete",
	permission: boolean
};
	["UsersFields_name"]: {
	__typename: "UsersFields_name",
	create?: GraphQLTypes["UsersFields_name_Create"] | undefined,
	read?: GraphQLTypes["UsersFields_name_Read"] | undefined,
	update?: GraphQLTypes["UsersFields_name_Update"] | undefined,
	delete?: GraphQLTypes["UsersFields_name_Delete"] | undefined
};
	["UsersFields_name_Create"]: {
	__typename: "UsersFields_name_Create",
	permission: boolean
};
	["UsersFields_name_Read"]: {
	__typename: "UsersFields_name_Read",
	permission: boolean
};
	["UsersFields_name_Update"]: {
	__typename: "UsersFields_name_Update",
	permission: boolean
};
	["UsersFields_name_Delete"]: {
	__typename: "UsersFields_name_Delete",
	permission: boolean
};
	["UsersFields_displayEmail"]: {
	__typename: "UsersFields_displayEmail",
	create?: GraphQLTypes["UsersFields_displayEmail_Create"] | undefined,
	read?: GraphQLTypes["UsersFields_displayEmail_Read"] | undefined,
	update?: GraphQLTypes["UsersFields_displayEmail_Update"] | undefined,
	delete?: GraphQLTypes["UsersFields_displayEmail_Delete"] | undefined
};
	["UsersFields_displayEmail_Create"]: {
	__typename: "UsersFields_displayEmail_Create",
	permission: boolean
};
	["UsersFields_displayEmail_Read"]: {
	__typename: "UsersFields_displayEmail_Read",
	permission: boolean
};
	["UsersFields_displayEmail_Update"]: {
	__typename: "UsersFields_displayEmail_Update",
	permission: boolean
};
	["UsersFields_displayEmail_Delete"]: {
	__typename: "UsersFields_displayEmail_Delete",
	permission: boolean
};
	["UsersFields_legacyUsername"]: {
	__typename: "UsersFields_legacyUsername",
	create?: GraphQLTypes["UsersFields_legacyUsername_Create"] | undefined,
	read?: GraphQLTypes["UsersFields_legacyUsername_Read"] | undefined,
	update?: GraphQLTypes["UsersFields_legacyUsername_Update"] | undefined,
	delete?: GraphQLTypes["UsersFields_legacyUsername_Delete"] | undefined
};
	["UsersFields_legacyUsername_Create"]: {
	__typename: "UsersFields_legacyUsername_Create",
	permission: boolean
};
	["UsersFields_legacyUsername_Read"]: {
	__typename: "UsersFields_legacyUsername_Read",
	permission: boolean
};
	["UsersFields_legacyUsername_Update"]: {
	__typename: "UsersFields_legacyUsername_Update",
	permission: boolean
};
	["UsersFields_legacyUsername_Delete"]: {
	__typename: "UsersFields_legacyUsername_Delete",
	permission: boolean
};
	["UsersFields_roles"]: {
	__typename: "UsersFields_roles",
	create?: GraphQLTypes["UsersFields_roles_Create"] | undefined,
	read?: GraphQLTypes["UsersFields_roles_Read"] | undefined,
	update?: GraphQLTypes["UsersFields_roles_Update"] | undefined,
	delete?: GraphQLTypes["UsersFields_roles_Delete"] | undefined
};
	["UsersFields_roles_Create"]: {
	__typename: "UsersFields_roles_Create",
	permission: boolean
};
	["UsersFields_roles_Read"]: {
	__typename: "UsersFields_roles_Read",
	permission: boolean
};
	["UsersFields_roles_Update"]: {
	__typename: "UsersFields_roles_Update",
	permission: boolean
};
	["UsersFields_roles_Delete"]: {
	__typename: "UsersFields_roles_Delete",
	permission: boolean
};
	["UsersFields_member"]: {
	__typename: "UsersFields_member",
	create?: GraphQLTypes["UsersFields_member_Create"] | undefined,
	read?: GraphQLTypes["UsersFields_member_Read"] | undefined,
	update?: GraphQLTypes["UsersFields_member_Update"] | undefined,
	delete?: GraphQLTypes["UsersFields_member_Delete"] | undefined,
	fields?: GraphQLTypes["UsersFields_member_Fields"] | undefined
};
	["UsersFields_member_Create"]: {
	__typename: "UsersFields_member_Create",
	permission: boolean
};
	["UsersFields_member_Read"]: {
	__typename: "UsersFields_member_Read",
	permission: boolean
};
	["UsersFields_member_Update"]: {
	__typename: "UsersFields_member_Update",
	permission: boolean
};
	["UsersFields_member_Delete"]: {
	__typename: "UsersFields_member_Delete",
	permission: boolean
};
	["UsersFields_member_Fields"]: {
	__typename: "UsersFields_member_Fields",
	region?: GraphQLTypes["UsersFields_member_region"] | undefined,
	language?: GraphQLTypes["UsersFields_member_language"] | undefined,
	phone?: GraphQLTypes["UsersFields_member_phone"] | undefined,
	avatar?: GraphQLTypes["UsersFields_member_avatar"] | undefined,
	birthday?: GraphQLTypes["UsersFields_member_birthday"] | undefined,
	gender?: GraphQLTypes["UsersFields_member_gender"] | undefined,
	categories?: GraphQLTypes["UsersFields_member_categories"] | undefined
};
	["UsersFields_member_region"]: {
	__typename: "UsersFields_member_region",
	create?: GraphQLTypes["UsersFields_member_region_Create"] | undefined,
	read?: GraphQLTypes["UsersFields_member_region_Read"] | undefined,
	update?: GraphQLTypes["UsersFields_member_region_Update"] | undefined,
	delete?: GraphQLTypes["UsersFields_member_region_Delete"] | undefined
};
	["UsersFields_member_region_Create"]: {
	__typename: "UsersFields_member_region_Create",
	permission: boolean
};
	["UsersFields_member_region_Read"]: {
	__typename: "UsersFields_member_region_Read",
	permission: boolean
};
	["UsersFields_member_region_Update"]: {
	__typename: "UsersFields_member_region_Update",
	permission: boolean
};
	["UsersFields_member_region_Delete"]: {
	__typename: "UsersFields_member_region_Delete",
	permission: boolean
};
	["UsersFields_member_language"]: {
	__typename: "UsersFields_member_language",
	create?: GraphQLTypes["UsersFields_member_language_Create"] | undefined,
	read?: GraphQLTypes["UsersFields_member_language_Read"] | undefined,
	update?: GraphQLTypes["UsersFields_member_language_Update"] | undefined,
	delete?: GraphQLTypes["UsersFields_member_language_Delete"] | undefined
};
	["UsersFields_member_language_Create"]: {
	__typename: "UsersFields_member_language_Create",
	permission: boolean
};
	["UsersFields_member_language_Read"]: {
	__typename: "UsersFields_member_language_Read",
	permission: boolean
};
	["UsersFields_member_language_Update"]: {
	__typename: "UsersFields_member_language_Update",
	permission: boolean
};
	["UsersFields_member_language_Delete"]: {
	__typename: "UsersFields_member_language_Delete",
	permission: boolean
};
	["UsersFields_member_phone"]: {
	__typename: "UsersFields_member_phone",
	create?: GraphQLTypes["UsersFields_member_phone_Create"] | undefined,
	read?: GraphQLTypes["UsersFields_member_phone_Read"] | undefined,
	update?: GraphQLTypes["UsersFields_member_phone_Update"] | undefined,
	delete?: GraphQLTypes["UsersFields_member_phone_Delete"] | undefined
};
	["UsersFields_member_phone_Create"]: {
	__typename: "UsersFields_member_phone_Create",
	permission: boolean
};
	["UsersFields_member_phone_Read"]: {
	__typename: "UsersFields_member_phone_Read",
	permission: boolean
};
	["UsersFields_member_phone_Update"]: {
	__typename: "UsersFields_member_phone_Update",
	permission: boolean
};
	["UsersFields_member_phone_Delete"]: {
	__typename: "UsersFields_member_phone_Delete",
	permission: boolean
};
	["UsersFields_member_avatar"]: {
	__typename: "UsersFields_member_avatar",
	create?: GraphQLTypes["UsersFields_member_avatar_Create"] | undefined,
	read?: GraphQLTypes["UsersFields_member_avatar_Read"] | undefined,
	update?: GraphQLTypes["UsersFields_member_avatar_Update"] | undefined,
	delete?: GraphQLTypes["UsersFields_member_avatar_Delete"] | undefined
};
	["UsersFields_member_avatar_Create"]: {
	__typename: "UsersFields_member_avatar_Create",
	permission: boolean
};
	["UsersFields_member_avatar_Read"]: {
	__typename: "UsersFields_member_avatar_Read",
	permission: boolean
};
	["UsersFields_member_avatar_Update"]: {
	__typename: "UsersFields_member_avatar_Update",
	permission: boolean
};
	["UsersFields_member_avatar_Delete"]: {
	__typename: "UsersFields_member_avatar_Delete",
	permission: boolean
};
	["UsersFields_member_birthday"]: {
	__typename: "UsersFields_member_birthday",
	create?: GraphQLTypes["UsersFields_member_birthday_Create"] | undefined,
	read?: GraphQLTypes["UsersFields_member_birthday_Read"] | undefined,
	update?: GraphQLTypes["UsersFields_member_birthday_Update"] | undefined,
	delete?: GraphQLTypes["UsersFields_member_birthday_Delete"] | undefined
};
	["UsersFields_member_birthday_Create"]: {
	__typename: "UsersFields_member_birthday_Create",
	permission: boolean
};
	["UsersFields_member_birthday_Read"]: {
	__typename: "UsersFields_member_birthday_Read",
	permission: boolean
};
	["UsersFields_member_birthday_Update"]: {
	__typename: "UsersFields_member_birthday_Update",
	permission: boolean
};
	["UsersFields_member_birthday_Delete"]: {
	__typename: "UsersFields_member_birthday_Delete",
	permission: boolean
};
	["UsersFields_member_gender"]: {
	__typename: "UsersFields_member_gender",
	create?: GraphQLTypes["UsersFields_member_gender_Create"] | undefined,
	read?: GraphQLTypes["UsersFields_member_gender_Read"] | undefined,
	update?: GraphQLTypes["UsersFields_member_gender_Update"] | undefined,
	delete?: GraphQLTypes["UsersFields_member_gender_Delete"] | undefined
};
	["UsersFields_member_gender_Create"]: {
	__typename: "UsersFields_member_gender_Create",
	permission: boolean
};
	["UsersFields_member_gender_Read"]: {
	__typename: "UsersFields_member_gender_Read",
	permission: boolean
};
	["UsersFields_member_gender_Update"]: {
	__typename: "UsersFields_member_gender_Update",
	permission: boolean
};
	["UsersFields_member_gender_Delete"]: {
	__typename: "UsersFields_member_gender_Delete",
	permission: boolean
};
	["UsersFields_member_categories"]: {
	__typename: "UsersFields_member_categories",
	create?: GraphQLTypes["UsersFields_member_categories_Create"] | undefined,
	read?: GraphQLTypes["UsersFields_member_categories_Read"] | undefined,
	update?: GraphQLTypes["UsersFields_member_categories_Update"] | undefined,
	delete?: GraphQLTypes["UsersFields_member_categories_Delete"] | undefined
};
	["UsersFields_member_categories_Create"]: {
	__typename: "UsersFields_member_categories_Create",
	permission: boolean
};
	["UsersFields_member_categories_Read"]: {
	__typename: "UsersFields_member_categories_Read",
	permission: boolean
};
	["UsersFields_member_categories_Update"]: {
	__typename: "UsersFields_member_categories_Update",
	permission: boolean
};
	["UsersFields_member_categories_Delete"]: {
	__typename: "UsersFields_member_categories_Delete",
	permission: boolean
};
	["UsersFields_organiser"]: {
	__typename: "UsersFields_organiser",
	create?: GraphQLTypes["UsersFields_organiser_Create"] | undefined,
	read?: GraphQLTypes["UsersFields_organiser_Read"] | undefined,
	update?: GraphQLTypes["UsersFields_organiser_Update"] | undefined,
	delete?: GraphQLTypes["UsersFields_organiser_Delete"] | undefined,
	fields?: GraphQLTypes["UsersFields_organiser_Fields"] | undefined
};
	["UsersFields_organiser_Create"]: {
	__typename: "UsersFields_organiser_Create",
	permission: boolean
};
	["UsersFields_organiser_Read"]: {
	__typename: "UsersFields_organiser_Read",
	permission: boolean
};
	["UsersFields_organiser_Update"]: {
	__typename: "UsersFields_organiser_Update",
	permission: boolean
};
	["UsersFields_organiser_Delete"]: {
	__typename: "UsersFields_organiser_Delete",
	permission: boolean
};
	["UsersFields_organiser_Fields"]: {
	__typename: "UsersFields_organiser_Fields",
	logo?: GraphQLTypes["UsersFields_organiser_logo"] | undefined,
	banner?: GraphQLTypes["UsersFields_organiser_banner"] | undefined,
	description?: GraphQLTypes["UsersFields_organiser_description"] | undefined,
	foundedIn?: GraphQLTypes["UsersFields_organiser_foundedIn"] | undefined,
	address?: GraphQLTypes["UsersFields_organiser_address"] | undefined,
	website?: GraphQLTypes["UsersFields_organiser_website"] | undefined,
	facebook?: GraphQLTypes["UsersFields_organiser_facebook"] | undefined,
	instagram?: GraphQLTypes["UsersFields_organiser_instagram"] | undefined,
	language?: GraphQLTypes["UsersFields_organiser_language"] | undefined,
	phone?: GraphQLTypes["UsersFields_organiser_phone"] | undefined,
	followers?: GraphQLTypes["UsersFields_organiser_followers"] | undefined
};
	["UsersFields_organiser_logo"]: {
	__typename: "UsersFields_organiser_logo",
	create?: GraphQLTypes["UsersFields_organiser_logo_Create"] | undefined,
	read?: GraphQLTypes["UsersFields_organiser_logo_Read"] | undefined,
	update?: GraphQLTypes["UsersFields_organiser_logo_Update"] | undefined,
	delete?: GraphQLTypes["UsersFields_organiser_logo_Delete"] | undefined
};
	["UsersFields_organiser_logo_Create"]: {
	__typename: "UsersFields_organiser_logo_Create",
	permission: boolean
};
	["UsersFields_organiser_logo_Read"]: {
	__typename: "UsersFields_organiser_logo_Read",
	permission: boolean
};
	["UsersFields_organiser_logo_Update"]: {
	__typename: "UsersFields_organiser_logo_Update",
	permission: boolean
};
	["UsersFields_organiser_logo_Delete"]: {
	__typename: "UsersFields_organiser_logo_Delete",
	permission: boolean
};
	["UsersFields_organiser_banner"]: {
	__typename: "UsersFields_organiser_banner",
	create?: GraphQLTypes["UsersFields_organiser_banner_Create"] | undefined,
	read?: GraphQLTypes["UsersFields_organiser_banner_Read"] | undefined,
	update?: GraphQLTypes["UsersFields_organiser_banner_Update"] | undefined,
	delete?: GraphQLTypes["UsersFields_organiser_banner_Delete"] | undefined
};
	["UsersFields_organiser_banner_Create"]: {
	__typename: "UsersFields_organiser_banner_Create",
	permission: boolean
};
	["UsersFields_organiser_banner_Read"]: {
	__typename: "UsersFields_organiser_banner_Read",
	permission: boolean
};
	["UsersFields_organiser_banner_Update"]: {
	__typename: "UsersFields_organiser_banner_Update",
	permission: boolean
};
	["UsersFields_organiser_banner_Delete"]: {
	__typename: "UsersFields_organiser_banner_Delete",
	permission: boolean
};
	["UsersFields_organiser_description"]: {
	__typename: "UsersFields_organiser_description",
	create?: GraphQLTypes["UsersFields_organiser_description_Create"] | undefined,
	read?: GraphQLTypes["UsersFields_organiser_description_Read"] | undefined,
	update?: GraphQLTypes["UsersFields_organiser_description_Update"] | undefined,
	delete?: GraphQLTypes["UsersFields_organiser_description_Delete"] | undefined
};
	["UsersFields_organiser_description_Create"]: {
	__typename: "UsersFields_organiser_description_Create",
	permission: boolean
};
	["UsersFields_organiser_description_Read"]: {
	__typename: "UsersFields_organiser_description_Read",
	permission: boolean
};
	["UsersFields_organiser_description_Update"]: {
	__typename: "UsersFields_organiser_description_Update",
	permission: boolean
};
	["UsersFields_organiser_description_Delete"]: {
	__typename: "UsersFields_organiser_description_Delete",
	permission: boolean
};
	["UsersFields_organiser_foundedIn"]: {
	__typename: "UsersFields_organiser_foundedIn",
	create?: GraphQLTypes["UsersFields_organiser_foundedIn_Create"] | undefined,
	read?: GraphQLTypes["UsersFields_organiser_foundedIn_Read"] | undefined,
	update?: GraphQLTypes["UsersFields_organiser_foundedIn_Update"] | undefined,
	delete?: GraphQLTypes["UsersFields_organiser_foundedIn_Delete"] | undefined
};
	["UsersFields_organiser_foundedIn_Create"]: {
	__typename: "UsersFields_organiser_foundedIn_Create",
	permission: boolean
};
	["UsersFields_organiser_foundedIn_Read"]: {
	__typename: "UsersFields_organiser_foundedIn_Read",
	permission: boolean
};
	["UsersFields_organiser_foundedIn_Update"]: {
	__typename: "UsersFields_organiser_foundedIn_Update",
	permission: boolean
};
	["UsersFields_organiser_foundedIn_Delete"]: {
	__typename: "UsersFields_organiser_foundedIn_Delete",
	permission: boolean
};
	["UsersFields_organiser_address"]: {
	__typename: "UsersFields_organiser_address",
	create?: GraphQLTypes["UsersFields_organiser_address_Create"] | undefined,
	read?: GraphQLTypes["UsersFields_organiser_address_Read"] | undefined,
	update?: GraphQLTypes["UsersFields_organiser_address_Update"] | undefined,
	delete?: GraphQLTypes["UsersFields_organiser_address_Delete"] | undefined
};
	["UsersFields_organiser_address_Create"]: {
	__typename: "UsersFields_organiser_address_Create",
	permission: boolean
};
	["UsersFields_organiser_address_Read"]: {
	__typename: "UsersFields_organiser_address_Read",
	permission: boolean
};
	["UsersFields_organiser_address_Update"]: {
	__typename: "UsersFields_organiser_address_Update",
	permission: boolean
};
	["UsersFields_organiser_address_Delete"]: {
	__typename: "UsersFields_organiser_address_Delete",
	permission: boolean
};
	["UsersFields_organiser_website"]: {
	__typename: "UsersFields_organiser_website",
	create?: GraphQLTypes["UsersFields_organiser_website_Create"] | undefined,
	read?: GraphQLTypes["UsersFields_organiser_website_Read"] | undefined,
	update?: GraphQLTypes["UsersFields_organiser_website_Update"] | undefined,
	delete?: GraphQLTypes["UsersFields_organiser_website_Delete"] | undefined
};
	["UsersFields_organiser_website_Create"]: {
	__typename: "UsersFields_organiser_website_Create",
	permission: boolean
};
	["UsersFields_organiser_website_Read"]: {
	__typename: "UsersFields_organiser_website_Read",
	permission: boolean
};
	["UsersFields_organiser_website_Update"]: {
	__typename: "UsersFields_organiser_website_Update",
	permission: boolean
};
	["UsersFields_organiser_website_Delete"]: {
	__typename: "UsersFields_organiser_website_Delete",
	permission: boolean
};
	["UsersFields_organiser_facebook"]: {
	__typename: "UsersFields_organiser_facebook",
	create?: GraphQLTypes["UsersFields_organiser_facebook_Create"] | undefined,
	read?: GraphQLTypes["UsersFields_organiser_facebook_Read"] | undefined,
	update?: GraphQLTypes["UsersFields_organiser_facebook_Update"] | undefined,
	delete?: GraphQLTypes["UsersFields_organiser_facebook_Delete"] | undefined
};
	["UsersFields_organiser_facebook_Create"]: {
	__typename: "UsersFields_organiser_facebook_Create",
	permission: boolean
};
	["UsersFields_organiser_facebook_Read"]: {
	__typename: "UsersFields_organiser_facebook_Read",
	permission: boolean
};
	["UsersFields_organiser_facebook_Update"]: {
	__typename: "UsersFields_organiser_facebook_Update",
	permission: boolean
};
	["UsersFields_organiser_facebook_Delete"]: {
	__typename: "UsersFields_organiser_facebook_Delete",
	permission: boolean
};
	["UsersFields_organiser_instagram"]: {
	__typename: "UsersFields_organiser_instagram",
	create?: GraphQLTypes["UsersFields_organiser_instagram_Create"] | undefined,
	read?: GraphQLTypes["UsersFields_organiser_instagram_Read"] | undefined,
	update?: GraphQLTypes["UsersFields_organiser_instagram_Update"] | undefined,
	delete?: GraphQLTypes["UsersFields_organiser_instagram_Delete"] | undefined
};
	["UsersFields_organiser_instagram_Create"]: {
	__typename: "UsersFields_organiser_instagram_Create",
	permission: boolean
};
	["UsersFields_organiser_instagram_Read"]: {
	__typename: "UsersFields_organiser_instagram_Read",
	permission: boolean
};
	["UsersFields_organiser_instagram_Update"]: {
	__typename: "UsersFields_organiser_instagram_Update",
	permission: boolean
};
	["UsersFields_organiser_instagram_Delete"]: {
	__typename: "UsersFields_organiser_instagram_Delete",
	permission: boolean
};
	["UsersFields_organiser_language"]: {
	__typename: "UsersFields_organiser_language",
	create?: GraphQLTypes["UsersFields_organiser_language_Create"] | undefined,
	read?: GraphQLTypes["UsersFields_organiser_language_Read"] | undefined,
	update?: GraphQLTypes["UsersFields_organiser_language_Update"] | undefined,
	delete?: GraphQLTypes["UsersFields_organiser_language_Delete"] | undefined
};
	["UsersFields_organiser_language_Create"]: {
	__typename: "UsersFields_organiser_language_Create",
	permission: boolean
};
	["UsersFields_organiser_language_Read"]: {
	__typename: "UsersFields_organiser_language_Read",
	permission: boolean
};
	["UsersFields_organiser_language_Update"]: {
	__typename: "UsersFields_organiser_language_Update",
	permission: boolean
};
	["UsersFields_organiser_language_Delete"]: {
	__typename: "UsersFields_organiser_language_Delete",
	permission: boolean
};
	["UsersFields_organiser_phone"]: {
	__typename: "UsersFields_organiser_phone",
	create?: GraphQLTypes["UsersFields_organiser_phone_Create"] | undefined,
	read?: GraphQLTypes["UsersFields_organiser_phone_Read"] | undefined,
	update?: GraphQLTypes["UsersFields_organiser_phone_Update"] | undefined,
	delete?: GraphQLTypes["UsersFields_organiser_phone_Delete"] | undefined
};
	["UsersFields_organiser_phone_Create"]: {
	__typename: "UsersFields_organiser_phone_Create",
	permission: boolean
};
	["UsersFields_organiser_phone_Read"]: {
	__typename: "UsersFields_organiser_phone_Read",
	permission: boolean
};
	["UsersFields_organiser_phone_Update"]: {
	__typename: "UsersFields_organiser_phone_Update",
	permission: boolean
};
	["UsersFields_organiser_phone_Delete"]: {
	__typename: "UsersFields_organiser_phone_Delete",
	permission: boolean
};
	["UsersFields_organiser_followers"]: {
	__typename: "UsersFields_organiser_followers",
	create?: GraphQLTypes["UsersFields_organiser_followers_Create"] | undefined,
	read?: GraphQLTypes["UsersFields_organiser_followers_Read"] | undefined,
	update?: GraphQLTypes["UsersFields_organiser_followers_Update"] | undefined,
	delete?: GraphQLTypes["UsersFields_organiser_followers_Delete"] | undefined
};
	["UsersFields_organiser_followers_Create"]: {
	__typename: "UsersFields_organiser_followers_Create",
	permission: boolean
};
	["UsersFields_organiser_followers_Read"]: {
	__typename: "UsersFields_organiser_followers_Read",
	permission: boolean
};
	["UsersFields_organiser_followers_Update"]: {
	__typename: "UsersFields_organiser_followers_Update",
	permission: boolean
};
	["UsersFields_organiser_followers_Delete"]: {
	__typename: "UsersFields_organiser_followers_Delete",
	permission: boolean
};
	["UsersFields_emailVerified"]: {
	__typename: "UsersFields_emailVerified",
	create?: GraphQLTypes["UsersFields_emailVerified_Create"] | undefined,
	read?: GraphQLTypes["UsersFields_emailVerified_Read"] | undefined,
	update?: GraphQLTypes["UsersFields_emailVerified_Update"] | undefined,
	delete?: GraphQLTypes["UsersFields_emailVerified_Delete"] | undefined
};
	["UsersFields_emailVerified_Create"]: {
	__typename: "UsersFields_emailVerified_Create",
	permission: boolean
};
	["UsersFields_emailVerified_Read"]: {
	__typename: "UsersFields_emailVerified_Read",
	permission: boolean
};
	["UsersFields_emailVerified_Update"]: {
	__typename: "UsersFields_emailVerified_Update",
	permission: boolean
};
	["UsersFields_emailVerified_Delete"]: {
	__typename: "UsersFields_emailVerified_Delete",
	permission: boolean
};
	["UsersFields_sso"]: {
	__typename: "UsersFields_sso",
	create?: GraphQLTypes["UsersFields_sso_Create"] | undefined,
	read?: GraphQLTypes["UsersFields_sso_Read"] | undefined,
	update?: GraphQLTypes["UsersFields_sso_Update"] | undefined,
	delete?: GraphQLTypes["UsersFields_sso_Delete"] | undefined,
	fields?: GraphQLTypes["UsersFields_sso_Fields"] | undefined
};
	["UsersFields_sso_Create"]: {
	__typename: "UsersFields_sso_Create",
	permission: boolean
};
	["UsersFields_sso_Read"]: {
	__typename: "UsersFields_sso_Read",
	permission: boolean
};
	["UsersFields_sso_Update"]: {
	__typename: "UsersFields_sso_Update",
	permission: boolean
};
	["UsersFields_sso_Delete"]: {
	__typename: "UsersFields_sso_Delete",
	permission: boolean
};
	["UsersFields_sso_Fields"]: {
	__typename: "UsersFields_sso_Fields",
	googleId?: GraphQLTypes["UsersFields_sso_googleId"] | undefined,
	facebookId?: GraphQLTypes["UsersFields_sso_facebookId"] | undefined,
	appleId?: GraphQLTypes["UsersFields_sso_appleId"] | undefined
};
	["UsersFields_sso_googleId"]: {
	__typename: "UsersFields_sso_googleId",
	create?: GraphQLTypes["UsersFields_sso_googleId_Create"] | undefined,
	read?: GraphQLTypes["UsersFields_sso_googleId_Read"] | undefined,
	update?: GraphQLTypes["UsersFields_sso_googleId_Update"] | undefined,
	delete?: GraphQLTypes["UsersFields_sso_googleId_Delete"] | undefined
};
	["UsersFields_sso_googleId_Create"]: {
	__typename: "UsersFields_sso_googleId_Create",
	permission: boolean
};
	["UsersFields_sso_googleId_Read"]: {
	__typename: "UsersFields_sso_googleId_Read",
	permission: boolean
};
	["UsersFields_sso_googleId_Update"]: {
	__typename: "UsersFields_sso_googleId_Update",
	permission: boolean
};
	["UsersFields_sso_googleId_Delete"]: {
	__typename: "UsersFields_sso_googleId_Delete",
	permission: boolean
};
	["UsersFields_sso_facebookId"]: {
	__typename: "UsersFields_sso_facebookId",
	create?: GraphQLTypes["UsersFields_sso_facebookId_Create"] | undefined,
	read?: GraphQLTypes["UsersFields_sso_facebookId_Read"] | undefined,
	update?: GraphQLTypes["UsersFields_sso_facebookId_Update"] | undefined,
	delete?: GraphQLTypes["UsersFields_sso_facebookId_Delete"] | undefined
};
	["UsersFields_sso_facebookId_Create"]: {
	__typename: "UsersFields_sso_facebookId_Create",
	permission: boolean
};
	["UsersFields_sso_facebookId_Read"]: {
	__typename: "UsersFields_sso_facebookId_Read",
	permission: boolean
};
	["UsersFields_sso_facebookId_Update"]: {
	__typename: "UsersFields_sso_facebookId_Update",
	permission: boolean
};
	["UsersFields_sso_facebookId_Delete"]: {
	__typename: "UsersFields_sso_facebookId_Delete",
	permission: boolean
};
	["UsersFields_sso_appleId"]: {
	__typename: "UsersFields_sso_appleId",
	create?: GraphQLTypes["UsersFields_sso_appleId_Create"] | undefined,
	read?: GraphQLTypes["UsersFields_sso_appleId_Read"] | undefined,
	update?: GraphQLTypes["UsersFields_sso_appleId_Update"] | undefined,
	delete?: GraphQLTypes["UsersFields_sso_appleId_Delete"] | undefined
};
	["UsersFields_sso_appleId_Create"]: {
	__typename: "UsersFields_sso_appleId_Create",
	permission: boolean
};
	["UsersFields_sso_appleId_Read"]: {
	__typename: "UsersFields_sso_appleId_Read",
	permission: boolean
};
	["UsersFields_sso_appleId_Update"]: {
	__typename: "UsersFields_sso_appleId_Update",
	permission: boolean
};
	["UsersFields_sso_appleId_Delete"]: {
	__typename: "UsersFields_sso_appleId_Delete",
	permission: boolean
};
	["UsersFields_oldPassword"]: {
	__typename: "UsersFields_oldPassword",
	create?: GraphQLTypes["UsersFields_oldPassword_Create"] | undefined,
	read?: GraphQLTypes["UsersFields_oldPassword_Read"] | undefined,
	update?: GraphQLTypes["UsersFields_oldPassword_Update"] | undefined,
	delete?: GraphQLTypes["UsersFields_oldPassword_Delete"] | undefined
};
	["UsersFields_oldPassword_Create"]: {
	__typename: "UsersFields_oldPassword_Create",
	permission: boolean
};
	["UsersFields_oldPassword_Read"]: {
	__typename: "UsersFields_oldPassword_Read",
	permission: boolean
};
	["UsersFields_oldPassword_Update"]: {
	__typename: "UsersFields_oldPassword_Update",
	permission: boolean
};
	["UsersFields_oldPassword_Delete"]: {
	__typename: "UsersFields_oldPassword_Delete",
	permission: boolean
};
	["UsersFields_status"]: {
	__typename: "UsersFields_status",
	create?: GraphQLTypes["UsersFields_status_Create"] | undefined,
	read?: GraphQLTypes["UsersFields_status_Read"] | undefined,
	update?: GraphQLTypes["UsersFields_status_Update"] | undefined,
	delete?: GraphQLTypes["UsersFields_status_Delete"] | undefined
};
	["UsersFields_status_Create"]: {
	__typename: "UsersFields_status_Create",
	permission: boolean
};
	["UsersFields_status_Read"]: {
	__typename: "UsersFields_status_Read",
	permission: boolean
};
	["UsersFields_status_Update"]: {
	__typename: "UsersFields_status_Update",
	permission: boolean
};
	["UsersFields_status_Delete"]: {
	__typename: "UsersFields_status_Delete",
	permission: boolean
};
	["UsersFields_updatedAt"]: {
	__typename: "UsersFields_updatedAt",
	create?: GraphQLTypes["UsersFields_updatedAt_Create"] | undefined,
	read?: GraphQLTypes["UsersFields_updatedAt_Read"] | undefined,
	update?: GraphQLTypes["UsersFields_updatedAt_Update"] | undefined,
	delete?: GraphQLTypes["UsersFields_updatedAt_Delete"] | undefined
};
	["UsersFields_updatedAt_Create"]: {
	__typename: "UsersFields_updatedAt_Create",
	permission: boolean
};
	["UsersFields_updatedAt_Read"]: {
	__typename: "UsersFields_updatedAt_Read",
	permission: boolean
};
	["UsersFields_updatedAt_Update"]: {
	__typename: "UsersFields_updatedAt_Update",
	permission: boolean
};
	["UsersFields_updatedAt_Delete"]: {
	__typename: "UsersFields_updatedAt_Delete",
	permission: boolean
};
	["UsersFields_createdAt"]: {
	__typename: "UsersFields_createdAt",
	create?: GraphQLTypes["UsersFields_createdAt_Create"] | undefined,
	read?: GraphQLTypes["UsersFields_createdAt_Read"] | undefined,
	update?: GraphQLTypes["UsersFields_createdAt_Update"] | undefined,
	delete?: GraphQLTypes["UsersFields_createdAt_Delete"] | undefined
};
	["UsersFields_createdAt_Create"]: {
	__typename: "UsersFields_createdAt_Create",
	permission: boolean
};
	["UsersFields_createdAt_Read"]: {
	__typename: "UsersFields_createdAt_Read",
	permission: boolean
};
	["UsersFields_createdAt_Update"]: {
	__typename: "UsersFields_createdAt_Update",
	permission: boolean
};
	["UsersFields_createdAt_Delete"]: {
	__typename: "UsersFields_createdAt_Delete",
	permission: boolean
};
	["UsersFields_email"]: {
	__typename: "UsersFields_email",
	create?: GraphQLTypes["UsersFields_email_Create"] | undefined,
	read?: GraphQLTypes["UsersFields_email_Read"] | undefined,
	update?: GraphQLTypes["UsersFields_email_Update"] | undefined,
	delete?: GraphQLTypes["UsersFields_email_Delete"] | undefined
};
	["UsersFields_email_Create"]: {
	__typename: "UsersFields_email_Create",
	permission: boolean
};
	["UsersFields_email_Read"]: {
	__typename: "UsersFields_email_Read",
	permission: boolean
};
	["UsersFields_email_Update"]: {
	__typename: "UsersFields_email_Update",
	permission: boolean
};
	["UsersFields_email_Delete"]: {
	__typename: "UsersFields_email_Delete",
	permission: boolean
};
	["UsersFields_password"]: {
	__typename: "UsersFields_password",
	create?: GraphQLTypes["UsersFields_password_Create"] | undefined,
	read?: GraphQLTypes["UsersFields_password_Read"] | undefined,
	update?: GraphQLTypes["UsersFields_password_Update"] | undefined,
	delete?: GraphQLTypes["UsersFields_password_Delete"] | undefined
};
	["UsersFields_password_Create"]: {
	__typename: "UsersFields_password_Create",
	permission: boolean
};
	["UsersFields_password_Read"]: {
	__typename: "UsersFields_password_Read",
	permission: boolean
};
	["UsersFields_password_Update"]: {
	__typename: "UsersFields_password_Update",
	permission: boolean
};
	["UsersFields_password_Delete"]: {
	__typename: "UsersFields_password_Delete",
	permission: boolean
};
	["UsersCreateAccess"]: {
	__typename: "UsersCreateAccess",
	permission: boolean,
	where?: GraphQLTypes["JSONObject"] | undefined
};
	["UsersReadAccess"]: {
	__typename: "UsersReadAccess",
	permission: boolean,
	where?: GraphQLTypes["JSONObject"] | undefined
};
	["UsersUpdateAccess"]: {
	__typename: "UsersUpdateAccess",
	permission: boolean,
	where?: GraphQLTypes["JSONObject"] | undefined
};
	["UsersDeleteAccess"]: {
	__typename: "UsersDeleteAccess",
	permission: boolean,
	where?: GraphQLTypes["JSONObject"] | undefined
};
	["UsersUnlockAccess"]: {
	__typename: "UsersUnlockAccess",
	permission: boolean,
	where?: GraphQLTypes["JSONObject"] | undefined
};
	["bookmarksAccess"]: {
	__typename: "bookmarksAccess",
	fields?: GraphQLTypes["BookmarksFields"] | undefined,
	create?: GraphQLTypes["BookmarksCreateAccess"] | undefined,
	read?: GraphQLTypes["BookmarksReadAccess"] | undefined,
	update?: GraphQLTypes["BookmarksUpdateAccess"] | undefined,
	delete?: GraphQLTypes["BookmarksDeleteAccess"] | undefined
};
	["BookmarksFields"]: {
	__typename: "BookmarksFields",
	user?: GraphQLTypes["BookmarksFields_user"] | undefined,
	item?: GraphQLTypes["BookmarksFields_item"] | undefined,
	updatedAt?: GraphQLTypes["BookmarksFields_updatedAt"] | undefined,
	createdAt?: GraphQLTypes["BookmarksFields_createdAt"] | undefined
};
	["BookmarksFields_user"]: {
	__typename: "BookmarksFields_user",
	create?: GraphQLTypes["BookmarksFields_user_Create"] | undefined,
	read?: GraphQLTypes["BookmarksFields_user_Read"] | undefined,
	update?: GraphQLTypes["BookmarksFields_user_Update"] | undefined,
	delete?: GraphQLTypes["BookmarksFields_user_Delete"] | undefined
};
	["BookmarksFields_user_Create"]: {
	__typename: "BookmarksFields_user_Create",
	permission: boolean
};
	["BookmarksFields_user_Read"]: {
	__typename: "BookmarksFields_user_Read",
	permission: boolean
};
	["BookmarksFields_user_Update"]: {
	__typename: "BookmarksFields_user_Update",
	permission: boolean
};
	["BookmarksFields_user_Delete"]: {
	__typename: "BookmarksFields_user_Delete",
	permission: boolean
};
	["BookmarksFields_item"]: {
	__typename: "BookmarksFields_item",
	create?: GraphQLTypes["BookmarksFields_item_Create"] | undefined,
	read?: GraphQLTypes["BookmarksFields_item_Read"] | undefined,
	update?: GraphQLTypes["BookmarksFields_item_Update"] | undefined,
	delete?: GraphQLTypes["BookmarksFields_item_Delete"] | undefined
};
	["BookmarksFields_item_Create"]: {
	__typename: "BookmarksFields_item_Create",
	permission: boolean
};
	["BookmarksFields_item_Read"]: {
	__typename: "BookmarksFields_item_Read",
	permission: boolean
};
	["BookmarksFields_item_Update"]: {
	__typename: "BookmarksFields_item_Update",
	permission: boolean
};
	["BookmarksFields_item_Delete"]: {
	__typename: "BookmarksFields_item_Delete",
	permission: boolean
};
	["BookmarksFields_updatedAt"]: {
	__typename: "BookmarksFields_updatedAt",
	create?: GraphQLTypes["BookmarksFields_updatedAt_Create"] | undefined,
	read?: GraphQLTypes["BookmarksFields_updatedAt_Read"] | undefined,
	update?: GraphQLTypes["BookmarksFields_updatedAt_Update"] | undefined,
	delete?: GraphQLTypes["BookmarksFields_updatedAt_Delete"] | undefined
};
	["BookmarksFields_updatedAt_Create"]: {
	__typename: "BookmarksFields_updatedAt_Create",
	permission: boolean
};
	["BookmarksFields_updatedAt_Read"]: {
	__typename: "BookmarksFields_updatedAt_Read",
	permission: boolean
};
	["BookmarksFields_updatedAt_Update"]: {
	__typename: "BookmarksFields_updatedAt_Update",
	permission: boolean
};
	["BookmarksFields_updatedAt_Delete"]: {
	__typename: "BookmarksFields_updatedAt_Delete",
	permission: boolean
};
	["BookmarksFields_createdAt"]: {
	__typename: "BookmarksFields_createdAt",
	create?: GraphQLTypes["BookmarksFields_createdAt_Create"] | undefined,
	read?: GraphQLTypes["BookmarksFields_createdAt_Read"] | undefined,
	update?: GraphQLTypes["BookmarksFields_createdAt_Update"] | undefined,
	delete?: GraphQLTypes["BookmarksFields_createdAt_Delete"] | undefined
};
	["BookmarksFields_createdAt_Create"]: {
	__typename: "BookmarksFields_createdAt_Create",
	permission: boolean
};
	["BookmarksFields_createdAt_Read"]: {
	__typename: "BookmarksFields_createdAt_Read",
	permission: boolean
};
	["BookmarksFields_createdAt_Update"]: {
	__typename: "BookmarksFields_createdAt_Update",
	permission: boolean
};
	["BookmarksFields_createdAt_Delete"]: {
	__typename: "BookmarksFields_createdAt_Delete",
	permission: boolean
};
	["BookmarksCreateAccess"]: {
	__typename: "BookmarksCreateAccess",
	permission: boolean,
	where?: GraphQLTypes["JSONObject"] | undefined
};
	["BookmarksReadAccess"]: {
	__typename: "BookmarksReadAccess",
	permission: boolean,
	where?: GraphQLTypes["JSONObject"] | undefined
};
	["BookmarksUpdateAccess"]: {
	__typename: "BookmarksUpdateAccess",
	permission: boolean,
	where?: GraphQLTypes["JSONObject"] | undefined
};
	["BookmarksDeleteAccess"]: {
	__typename: "BookmarksDeleteAccess",
	permission: boolean,
	where?: GraphQLTypes["JSONObject"] | undefined
};
	["webAppBookmarksAccess"]: {
	__typename: "webAppBookmarksAccess",
	fields?: GraphQLTypes["WebAppBookmarksFields"] | undefined,
	create?: GraphQLTypes["WebAppBookmarksCreateAccess"] | undefined,
	read?: GraphQLTypes["WebAppBookmarksReadAccess"] | undefined,
	update?: GraphQLTypes["WebAppBookmarksUpdateAccess"] | undefined,
	delete?: GraphQLTypes["WebAppBookmarksDeleteAccess"] | undefined
};
	["WebAppBookmarksFields"]: {
	__typename: "WebAppBookmarksFields",
	user?: GraphQLTypes["WebAppBookmarksFields_user"] | undefined,
	event?: GraphQLTypes["WebAppBookmarksFields_event"] | undefined,
	productType?: GraphQLTypes["WebAppBookmarksFields_productType"] | undefined,
	item?: GraphQLTypes["WebAppBookmarksFields_item"] | undefined,
	entryNumber?: GraphQLTypes["WebAppBookmarksFields_entryNumber"] | undefined,
	updatedAt?: GraphQLTypes["WebAppBookmarksFields_updatedAt"] | undefined,
	createdAt?: GraphQLTypes["WebAppBookmarksFields_createdAt"] | undefined
};
	["WebAppBookmarksFields_user"]: {
	__typename: "WebAppBookmarksFields_user",
	create?: GraphQLTypes["WebAppBookmarksFields_user_Create"] | undefined,
	read?: GraphQLTypes["WebAppBookmarksFields_user_Read"] | undefined,
	update?: GraphQLTypes["WebAppBookmarksFields_user_Update"] | undefined,
	delete?: GraphQLTypes["WebAppBookmarksFields_user_Delete"] | undefined
};
	["WebAppBookmarksFields_user_Create"]: {
	__typename: "WebAppBookmarksFields_user_Create",
	permission: boolean
};
	["WebAppBookmarksFields_user_Read"]: {
	__typename: "WebAppBookmarksFields_user_Read",
	permission: boolean
};
	["WebAppBookmarksFields_user_Update"]: {
	__typename: "WebAppBookmarksFields_user_Update",
	permission: boolean
};
	["WebAppBookmarksFields_user_Delete"]: {
	__typename: "WebAppBookmarksFields_user_Delete",
	permission: boolean
};
	["WebAppBookmarksFields_event"]: {
	__typename: "WebAppBookmarksFields_event",
	create?: GraphQLTypes["WebAppBookmarksFields_event_Create"] | undefined,
	read?: GraphQLTypes["WebAppBookmarksFields_event_Read"] | undefined,
	update?: GraphQLTypes["WebAppBookmarksFields_event_Update"] | undefined,
	delete?: GraphQLTypes["WebAppBookmarksFields_event_Delete"] | undefined
};
	["WebAppBookmarksFields_event_Create"]: {
	__typename: "WebAppBookmarksFields_event_Create",
	permission: boolean
};
	["WebAppBookmarksFields_event_Read"]: {
	__typename: "WebAppBookmarksFields_event_Read",
	permission: boolean
};
	["WebAppBookmarksFields_event_Update"]: {
	__typename: "WebAppBookmarksFields_event_Update",
	permission: boolean
};
	["WebAppBookmarksFields_event_Delete"]: {
	__typename: "WebAppBookmarksFields_event_Delete",
	permission: boolean
};
	["WebAppBookmarksFields_productType"]: {
	__typename: "WebAppBookmarksFields_productType",
	create?: GraphQLTypes["WebAppBookmarksFields_productType_Create"] | undefined,
	read?: GraphQLTypes["WebAppBookmarksFields_productType_Read"] | undefined,
	update?: GraphQLTypes["WebAppBookmarksFields_productType_Update"] | undefined,
	delete?: GraphQLTypes["WebAppBookmarksFields_productType_Delete"] | undefined
};
	["WebAppBookmarksFields_productType_Create"]: {
	__typename: "WebAppBookmarksFields_productType_Create",
	permission: boolean
};
	["WebAppBookmarksFields_productType_Read"]: {
	__typename: "WebAppBookmarksFields_productType_Read",
	permission: boolean
};
	["WebAppBookmarksFields_productType_Update"]: {
	__typename: "WebAppBookmarksFields_productType_Update",
	permission: boolean
};
	["WebAppBookmarksFields_productType_Delete"]: {
	__typename: "WebAppBookmarksFields_productType_Delete",
	permission: boolean
};
	["WebAppBookmarksFields_item"]: {
	__typename: "WebAppBookmarksFields_item",
	create?: GraphQLTypes["WebAppBookmarksFields_item_Create"] | undefined,
	read?: GraphQLTypes["WebAppBookmarksFields_item_Read"] | undefined,
	update?: GraphQLTypes["WebAppBookmarksFields_item_Update"] | undefined,
	delete?: GraphQLTypes["WebAppBookmarksFields_item_Delete"] | undefined
};
	["WebAppBookmarksFields_item_Create"]: {
	__typename: "WebAppBookmarksFields_item_Create",
	permission: boolean
};
	["WebAppBookmarksFields_item_Read"]: {
	__typename: "WebAppBookmarksFields_item_Read",
	permission: boolean
};
	["WebAppBookmarksFields_item_Update"]: {
	__typename: "WebAppBookmarksFields_item_Update",
	permission: boolean
};
	["WebAppBookmarksFields_item_Delete"]: {
	__typename: "WebAppBookmarksFields_item_Delete",
	permission: boolean
};
	["WebAppBookmarksFields_entryNumber"]: {
	__typename: "WebAppBookmarksFields_entryNumber",
	create?: GraphQLTypes["WebAppBookmarksFields_entryNumber_Create"] | undefined,
	read?: GraphQLTypes["WebAppBookmarksFields_entryNumber_Read"] | undefined,
	update?: GraphQLTypes["WebAppBookmarksFields_entryNumber_Update"] | undefined,
	delete?: GraphQLTypes["WebAppBookmarksFields_entryNumber_Delete"] | undefined
};
	["WebAppBookmarksFields_entryNumber_Create"]: {
	__typename: "WebAppBookmarksFields_entryNumber_Create",
	permission: boolean
};
	["WebAppBookmarksFields_entryNumber_Read"]: {
	__typename: "WebAppBookmarksFields_entryNumber_Read",
	permission: boolean
};
	["WebAppBookmarksFields_entryNumber_Update"]: {
	__typename: "WebAppBookmarksFields_entryNumber_Update",
	permission: boolean
};
	["WebAppBookmarksFields_entryNumber_Delete"]: {
	__typename: "WebAppBookmarksFields_entryNumber_Delete",
	permission: boolean
};
	["WebAppBookmarksFields_updatedAt"]: {
	__typename: "WebAppBookmarksFields_updatedAt",
	create?: GraphQLTypes["WebAppBookmarksFields_updatedAt_Create"] | undefined,
	read?: GraphQLTypes["WebAppBookmarksFields_updatedAt_Read"] | undefined,
	update?: GraphQLTypes["WebAppBookmarksFields_updatedAt_Update"] | undefined,
	delete?: GraphQLTypes["WebAppBookmarksFields_updatedAt_Delete"] | undefined
};
	["WebAppBookmarksFields_updatedAt_Create"]: {
	__typename: "WebAppBookmarksFields_updatedAt_Create",
	permission: boolean
};
	["WebAppBookmarksFields_updatedAt_Read"]: {
	__typename: "WebAppBookmarksFields_updatedAt_Read",
	permission: boolean
};
	["WebAppBookmarksFields_updatedAt_Update"]: {
	__typename: "WebAppBookmarksFields_updatedAt_Update",
	permission: boolean
};
	["WebAppBookmarksFields_updatedAt_Delete"]: {
	__typename: "WebAppBookmarksFields_updatedAt_Delete",
	permission: boolean
};
	["WebAppBookmarksFields_createdAt"]: {
	__typename: "WebAppBookmarksFields_createdAt",
	create?: GraphQLTypes["WebAppBookmarksFields_createdAt_Create"] | undefined,
	read?: GraphQLTypes["WebAppBookmarksFields_createdAt_Read"] | undefined,
	update?: GraphQLTypes["WebAppBookmarksFields_createdAt_Update"] | undefined,
	delete?: GraphQLTypes["WebAppBookmarksFields_createdAt_Delete"] | undefined
};
	["WebAppBookmarksFields_createdAt_Create"]: {
	__typename: "WebAppBookmarksFields_createdAt_Create",
	permission: boolean
};
	["WebAppBookmarksFields_createdAt_Read"]: {
	__typename: "WebAppBookmarksFields_createdAt_Read",
	permission: boolean
};
	["WebAppBookmarksFields_createdAt_Update"]: {
	__typename: "WebAppBookmarksFields_createdAt_Update",
	permission: boolean
};
	["WebAppBookmarksFields_createdAt_Delete"]: {
	__typename: "WebAppBookmarksFields_createdAt_Delete",
	permission: boolean
};
	["WebAppBookmarksCreateAccess"]: {
	__typename: "WebAppBookmarksCreateAccess",
	permission: boolean,
	where?: GraphQLTypes["JSONObject"] | undefined
};
	["WebAppBookmarksReadAccess"]: {
	__typename: "WebAppBookmarksReadAccess",
	permission: boolean,
	where?: GraphQLTypes["JSONObject"] | undefined
};
	["WebAppBookmarksUpdateAccess"]: {
	__typename: "WebAppBookmarksUpdateAccess",
	permission: boolean,
	where?: GraphQLTypes["JSONObject"] | undefined
};
	["WebAppBookmarksDeleteAccess"]: {
	__typename: "WebAppBookmarksDeleteAccess",
	permission: boolean,
	where?: GraphQLTypes["JSONObject"] | undefined
};
	["view_recordsAccess"]: {
	__typename: "view_recordsAccess",
	fields?: GraphQLTypes["ViewRecordsFields"] | undefined,
	create?: GraphQLTypes["ViewRecordsCreateAccess"] | undefined,
	read?: GraphQLTypes["ViewRecordsReadAccess"] | undefined,
	update?: GraphQLTypes["ViewRecordsUpdateAccess"] | undefined,
	delete?: GraphQLTypes["ViewRecordsDeleteAccess"] | undefined
};
	["ViewRecordsFields"]: {
	__typename: "ViewRecordsFields",
	user?: GraphQLTypes["ViewRecordsFields_user"] | undefined,
	item?: GraphQLTypes["ViewRecordsFields_item"] | undefined,
	updatedAt?: GraphQLTypes["ViewRecordsFields_updatedAt"] | undefined,
	createdAt?: GraphQLTypes["ViewRecordsFields_createdAt"] | undefined
};
	["ViewRecordsFields_user"]: {
	__typename: "ViewRecordsFields_user",
	create?: GraphQLTypes["ViewRecordsFields_user_Create"] | undefined,
	read?: GraphQLTypes["ViewRecordsFields_user_Read"] | undefined,
	update?: GraphQLTypes["ViewRecordsFields_user_Update"] | undefined,
	delete?: GraphQLTypes["ViewRecordsFields_user_Delete"] | undefined
};
	["ViewRecordsFields_user_Create"]: {
	__typename: "ViewRecordsFields_user_Create",
	permission: boolean
};
	["ViewRecordsFields_user_Read"]: {
	__typename: "ViewRecordsFields_user_Read",
	permission: boolean
};
	["ViewRecordsFields_user_Update"]: {
	__typename: "ViewRecordsFields_user_Update",
	permission: boolean
};
	["ViewRecordsFields_user_Delete"]: {
	__typename: "ViewRecordsFields_user_Delete",
	permission: boolean
};
	["ViewRecordsFields_item"]: {
	__typename: "ViewRecordsFields_item",
	create?: GraphQLTypes["ViewRecordsFields_item_Create"] | undefined,
	read?: GraphQLTypes["ViewRecordsFields_item_Read"] | undefined,
	update?: GraphQLTypes["ViewRecordsFields_item_Update"] | undefined,
	delete?: GraphQLTypes["ViewRecordsFields_item_Delete"] | undefined
};
	["ViewRecordsFields_item_Create"]: {
	__typename: "ViewRecordsFields_item_Create",
	permission: boolean
};
	["ViewRecordsFields_item_Read"]: {
	__typename: "ViewRecordsFields_item_Read",
	permission: boolean
};
	["ViewRecordsFields_item_Update"]: {
	__typename: "ViewRecordsFields_item_Update",
	permission: boolean
};
	["ViewRecordsFields_item_Delete"]: {
	__typename: "ViewRecordsFields_item_Delete",
	permission: boolean
};
	["ViewRecordsFields_updatedAt"]: {
	__typename: "ViewRecordsFields_updatedAt",
	create?: GraphQLTypes["ViewRecordsFields_updatedAt_Create"] | undefined,
	read?: GraphQLTypes["ViewRecordsFields_updatedAt_Read"] | undefined,
	update?: GraphQLTypes["ViewRecordsFields_updatedAt_Update"] | undefined,
	delete?: GraphQLTypes["ViewRecordsFields_updatedAt_Delete"] | undefined
};
	["ViewRecordsFields_updatedAt_Create"]: {
	__typename: "ViewRecordsFields_updatedAt_Create",
	permission: boolean
};
	["ViewRecordsFields_updatedAt_Read"]: {
	__typename: "ViewRecordsFields_updatedAt_Read",
	permission: boolean
};
	["ViewRecordsFields_updatedAt_Update"]: {
	__typename: "ViewRecordsFields_updatedAt_Update",
	permission: boolean
};
	["ViewRecordsFields_updatedAt_Delete"]: {
	__typename: "ViewRecordsFields_updatedAt_Delete",
	permission: boolean
};
	["ViewRecordsFields_createdAt"]: {
	__typename: "ViewRecordsFields_createdAt",
	create?: GraphQLTypes["ViewRecordsFields_createdAt_Create"] | undefined,
	read?: GraphQLTypes["ViewRecordsFields_createdAt_Read"] | undefined,
	update?: GraphQLTypes["ViewRecordsFields_createdAt_Update"] | undefined,
	delete?: GraphQLTypes["ViewRecordsFields_createdAt_Delete"] | undefined
};
	["ViewRecordsFields_createdAt_Create"]: {
	__typename: "ViewRecordsFields_createdAt_Create",
	permission: boolean
};
	["ViewRecordsFields_createdAt_Read"]: {
	__typename: "ViewRecordsFields_createdAt_Read",
	permission: boolean
};
	["ViewRecordsFields_createdAt_Update"]: {
	__typename: "ViewRecordsFields_createdAt_Update",
	permission: boolean
};
	["ViewRecordsFields_createdAt_Delete"]: {
	__typename: "ViewRecordsFields_createdAt_Delete",
	permission: boolean
};
	["ViewRecordsCreateAccess"]: {
	__typename: "ViewRecordsCreateAccess",
	permission: boolean,
	where?: GraphQLTypes["JSONObject"] | undefined
};
	["ViewRecordsReadAccess"]: {
	__typename: "ViewRecordsReadAccess",
	permission: boolean,
	where?: GraphQLTypes["JSONObject"] | undefined
};
	["ViewRecordsUpdateAccess"]: {
	__typename: "ViewRecordsUpdateAccess",
	permission: boolean,
	where?: GraphQLTypes["JSONObject"] | undefined
};
	["ViewRecordsDeleteAccess"]: {
	__typename: "ViewRecordsDeleteAccess",
	permission: boolean,
	where?: GraphQLTypes["JSONObject"] | undefined
};
	["commentsAccess"]: {
	__typename: "commentsAccess",
	fields?: GraphQLTypes["CommentsFields"] | undefined,
	create?: GraphQLTypes["CommentsCreateAccess"] | undefined,
	read?: GraphQLTypes["CommentsReadAccess"] | undefined,
	update?: GraphQLTypes["CommentsUpdateAccess"] | undefined,
	delete?: GraphQLTypes["CommentsDeleteAccess"] | undefined
};
	["CommentsFields"]: {
	__typename: "CommentsFields",
	commenter?: GraphQLTypes["CommentsFields_commenter"] | undefined,
	content?: GraphQLTypes["CommentsFields_content"] | undefined,
	image?: GraphQLTypes["CommentsFields_image"] | undefined,
	target?: GraphQLTypes["CommentsFields_target"] | undefined,
	parent?: GraphQLTypes["CommentsFields_parent"] | undefined,
	isReviewed?: GraphQLTypes["CommentsFields_isReviewed"] | undefined,
	reviewedBy?: GraphQLTypes["CommentsFields_reviewedBy"] | undefined,
	banned?: GraphQLTypes["CommentsFields_banned"] | undefined,
	type?: GraphQLTypes["CommentsFields_type"] | undefined,
	updatedAt?: GraphQLTypes["CommentsFields_updatedAt"] | undefined,
	createdAt?: GraphQLTypes["CommentsFields_createdAt"] | undefined
};
	["CommentsFields_commenter"]: {
	__typename: "CommentsFields_commenter",
	create?: GraphQLTypes["CommentsFields_commenter_Create"] | undefined,
	read?: GraphQLTypes["CommentsFields_commenter_Read"] | undefined,
	update?: GraphQLTypes["CommentsFields_commenter_Update"] | undefined,
	delete?: GraphQLTypes["CommentsFields_commenter_Delete"] | undefined
};
	["CommentsFields_commenter_Create"]: {
	__typename: "CommentsFields_commenter_Create",
	permission: boolean
};
	["CommentsFields_commenter_Read"]: {
	__typename: "CommentsFields_commenter_Read",
	permission: boolean
};
	["CommentsFields_commenter_Update"]: {
	__typename: "CommentsFields_commenter_Update",
	permission: boolean
};
	["CommentsFields_commenter_Delete"]: {
	__typename: "CommentsFields_commenter_Delete",
	permission: boolean
};
	["CommentsFields_content"]: {
	__typename: "CommentsFields_content",
	create?: GraphQLTypes["CommentsFields_content_Create"] | undefined,
	read?: GraphQLTypes["CommentsFields_content_Read"] | undefined,
	update?: GraphQLTypes["CommentsFields_content_Update"] | undefined,
	delete?: GraphQLTypes["CommentsFields_content_Delete"] | undefined
};
	["CommentsFields_content_Create"]: {
	__typename: "CommentsFields_content_Create",
	permission: boolean
};
	["CommentsFields_content_Read"]: {
	__typename: "CommentsFields_content_Read",
	permission: boolean
};
	["CommentsFields_content_Update"]: {
	__typename: "CommentsFields_content_Update",
	permission: boolean
};
	["CommentsFields_content_Delete"]: {
	__typename: "CommentsFields_content_Delete",
	permission: boolean
};
	["CommentsFields_image"]: {
	__typename: "CommentsFields_image",
	create?: GraphQLTypes["CommentsFields_image_Create"] | undefined,
	read?: GraphQLTypes["CommentsFields_image_Read"] | undefined,
	update?: GraphQLTypes["CommentsFields_image_Update"] | undefined,
	delete?: GraphQLTypes["CommentsFields_image_Delete"] | undefined
};
	["CommentsFields_image_Create"]: {
	__typename: "CommentsFields_image_Create",
	permission: boolean
};
	["CommentsFields_image_Read"]: {
	__typename: "CommentsFields_image_Read",
	permission: boolean
};
	["CommentsFields_image_Update"]: {
	__typename: "CommentsFields_image_Update",
	permission: boolean
};
	["CommentsFields_image_Delete"]: {
	__typename: "CommentsFields_image_Delete",
	permission: boolean
};
	["CommentsFields_target"]: {
	__typename: "CommentsFields_target",
	create?: GraphQLTypes["CommentsFields_target_Create"] | undefined,
	read?: GraphQLTypes["CommentsFields_target_Read"] | undefined,
	update?: GraphQLTypes["CommentsFields_target_Update"] | undefined,
	delete?: GraphQLTypes["CommentsFields_target_Delete"] | undefined
};
	["CommentsFields_target_Create"]: {
	__typename: "CommentsFields_target_Create",
	permission: boolean
};
	["CommentsFields_target_Read"]: {
	__typename: "CommentsFields_target_Read",
	permission: boolean
};
	["CommentsFields_target_Update"]: {
	__typename: "CommentsFields_target_Update",
	permission: boolean
};
	["CommentsFields_target_Delete"]: {
	__typename: "CommentsFields_target_Delete",
	permission: boolean
};
	["CommentsFields_parent"]: {
	__typename: "CommentsFields_parent",
	create?: GraphQLTypes["CommentsFields_parent_Create"] | undefined,
	read?: GraphQLTypes["CommentsFields_parent_Read"] | undefined,
	update?: GraphQLTypes["CommentsFields_parent_Update"] | undefined,
	delete?: GraphQLTypes["CommentsFields_parent_Delete"] | undefined
};
	["CommentsFields_parent_Create"]: {
	__typename: "CommentsFields_parent_Create",
	permission: boolean
};
	["CommentsFields_parent_Read"]: {
	__typename: "CommentsFields_parent_Read",
	permission: boolean
};
	["CommentsFields_parent_Update"]: {
	__typename: "CommentsFields_parent_Update",
	permission: boolean
};
	["CommentsFields_parent_Delete"]: {
	__typename: "CommentsFields_parent_Delete",
	permission: boolean
};
	["CommentsFields_isReviewed"]: {
	__typename: "CommentsFields_isReviewed",
	create?: GraphQLTypes["CommentsFields_isReviewed_Create"] | undefined,
	read?: GraphQLTypes["CommentsFields_isReviewed_Read"] | undefined,
	update?: GraphQLTypes["CommentsFields_isReviewed_Update"] | undefined,
	delete?: GraphQLTypes["CommentsFields_isReviewed_Delete"] | undefined
};
	["CommentsFields_isReviewed_Create"]: {
	__typename: "CommentsFields_isReviewed_Create",
	permission: boolean
};
	["CommentsFields_isReviewed_Read"]: {
	__typename: "CommentsFields_isReviewed_Read",
	permission: boolean
};
	["CommentsFields_isReviewed_Update"]: {
	__typename: "CommentsFields_isReviewed_Update",
	permission: boolean
};
	["CommentsFields_isReviewed_Delete"]: {
	__typename: "CommentsFields_isReviewed_Delete",
	permission: boolean
};
	["CommentsFields_reviewedBy"]: {
	__typename: "CommentsFields_reviewedBy",
	create?: GraphQLTypes["CommentsFields_reviewedBy_Create"] | undefined,
	read?: GraphQLTypes["CommentsFields_reviewedBy_Read"] | undefined,
	update?: GraphQLTypes["CommentsFields_reviewedBy_Update"] | undefined,
	delete?: GraphQLTypes["CommentsFields_reviewedBy_Delete"] | undefined
};
	["CommentsFields_reviewedBy_Create"]: {
	__typename: "CommentsFields_reviewedBy_Create",
	permission: boolean
};
	["CommentsFields_reviewedBy_Read"]: {
	__typename: "CommentsFields_reviewedBy_Read",
	permission: boolean
};
	["CommentsFields_reviewedBy_Update"]: {
	__typename: "CommentsFields_reviewedBy_Update",
	permission: boolean
};
	["CommentsFields_reviewedBy_Delete"]: {
	__typename: "CommentsFields_reviewedBy_Delete",
	permission: boolean
};
	["CommentsFields_banned"]: {
	__typename: "CommentsFields_banned",
	create?: GraphQLTypes["CommentsFields_banned_Create"] | undefined,
	read?: GraphQLTypes["CommentsFields_banned_Read"] | undefined,
	update?: GraphQLTypes["CommentsFields_banned_Update"] | undefined,
	delete?: GraphQLTypes["CommentsFields_banned_Delete"] | undefined
};
	["CommentsFields_banned_Create"]: {
	__typename: "CommentsFields_banned_Create",
	permission: boolean
};
	["CommentsFields_banned_Read"]: {
	__typename: "CommentsFields_banned_Read",
	permission: boolean
};
	["CommentsFields_banned_Update"]: {
	__typename: "CommentsFields_banned_Update",
	permission: boolean
};
	["CommentsFields_banned_Delete"]: {
	__typename: "CommentsFields_banned_Delete",
	permission: boolean
};
	["CommentsFields_type"]: {
	__typename: "CommentsFields_type",
	create?: GraphQLTypes["CommentsFields_type_Create"] | undefined,
	read?: GraphQLTypes["CommentsFields_type_Read"] | undefined,
	update?: GraphQLTypes["CommentsFields_type_Update"] | undefined,
	delete?: GraphQLTypes["CommentsFields_type_Delete"] | undefined
};
	["CommentsFields_type_Create"]: {
	__typename: "CommentsFields_type_Create",
	permission: boolean
};
	["CommentsFields_type_Read"]: {
	__typename: "CommentsFields_type_Read",
	permission: boolean
};
	["CommentsFields_type_Update"]: {
	__typename: "CommentsFields_type_Update",
	permission: boolean
};
	["CommentsFields_type_Delete"]: {
	__typename: "CommentsFields_type_Delete",
	permission: boolean
};
	["CommentsFields_updatedAt"]: {
	__typename: "CommentsFields_updatedAt",
	create?: GraphQLTypes["CommentsFields_updatedAt_Create"] | undefined,
	read?: GraphQLTypes["CommentsFields_updatedAt_Read"] | undefined,
	update?: GraphQLTypes["CommentsFields_updatedAt_Update"] | undefined,
	delete?: GraphQLTypes["CommentsFields_updatedAt_Delete"] | undefined
};
	["CommentsFields_updatedAt_Create"]: {
	__typename: "CommentsFields_updatedAt_Create",
	permission: boolean
};
	["CommentsFields_updatedAt_Read"]: {
	__typename: "CommentsFields_updatedAt_Read",
	permission: boolean
};
	["CommentsFields_updatedAt_Update"]: {
	__typename: "CommentsFields_updatedAt_Update",
	permission: boolean
};
	["CommentsFields_updatedAt_Delete"]: {
	__typename: "CommentsFields_updatedAt_Delete",
	permission: boolean
};
	["CommentsFields_createdAt"]: {
	__typename: "CommentsFields_createdAt",
	create?: GraphQLTypes["CommentsFields_createdAt_Create"] | undefined,
	read?: GraphQLTypes["CommentsFields_createdAt_Read"] | undefined,
	update?: GraphQLTypes["CommentsFields_createdAt_Update"] | undefined,
	delete?: GraphQLTypes["CommentsFields_createdAt_Delete"] | undefined
};
	["CommentsFields_createdAt_Create"]: {
	__typename: "CommentsFields_createdAt_Create",
	permission: boolean
};
	["CommentsFields_createdAt_Read"]: {
	__typename: "CommentsFields_createdAt_Read",
	permission: boolean
};
	["CommentsFields_createdAt_Update"]: {
	__typename: "CommentsFields_createdAt_Update",
	permission: boolean
};
	["CommentsFields_createdAt_Delete"]: {
	__typename: "CommentsFields_createdAt_Delete",
	permission: boolean
};
	["CommentsCreateAccess"]: {
	__typename: "CommentsCreateAccess",
	permission: boolean,
	where?: GraphQLTypes["JSONObject"] | undefined
};
	["CommentsReadAccess"]: {
	__typename: "CommentsReadAccess",
	permission: boolean,
	where?: GraphQLTypes["JSONObject"] | undefined
};
	["CommentsUpdateAccess"]: {
	__typename: "CommentsUpdateAccess",
	permission: boolean,
	where?: GraphQLTypes["JSONObject"] | undefined
};
	["CommentsDeleteAccess"]: {
	__typename: "CommentsDeleteAccess",
	permission: boolean,
	where?: GraphQLTypes["JSONObject"] | undefined
};
	["subscriptionsAccess"]: {
	__typename: "subscriptionsAccess",
	fields?: GraphQLTypes["SubscriptionsFields"] | undefined,
	create?: GraphQLTypes["SubscriptionsCreateAccess"] | undefined,
	read?: GraphQLTypes["SubscriptionsReadAccess"] | undefined,
	update?: GraphQLTypes["SubscriptionsUpdateAccess"] | undefined,
	delete?: GraphQLTypes["SubscriptionsDeleteAccess"] | undefined
};
	["SubscriptionsFields"]: {
	__typename: "SubscriptionsFields",
	user?: GraphQLTypes["SubscriptionsFields_user"] | undefined,
	organiser?: GraphQLTypes["SubscriptionsFields_organiser"] | undefined,
	updatedAt?: GraphQLTypes["SubscriptionsFields_updatedAt"] | undefined,
	createdAt?: GraphQLTypes["SubscriptionsFields_createdAt"] | undefined
};
	["SubscriptionsFields_user"]: {
	__typename: "SubscriptionsFields_user",
	create?: GraphQLTypes["SubscriptionsFields_user_Create"] | undefined,
	read?: GraphQLTypes["SubscriptionsFields_user_Read"] | undefined,
	update?: GraphQLTypes["SubscriptionsFields_user_Update"] | undefined,
	delete?: GraphQLTypes["SubscriptionsFields_user_Delete"] | undefined
};
	["SubscriptionsFields_user_Create"]: {
	__typename: "SubscriptionsFields_user_Create",
	permission: boolean
};
	["SubscriptionsFields_user_Read"]: {
	__typename: "SubscriptionsFields_user_Read",
	permission: boolean
};
	["SubscriptionsFields_user_Update"]: {
	__typename: "SubscriptionsFields_user_Update",
	permission: boolean
};
	["SubscriptionsFields_user_Delete"]: {
	__typename: "SubscriptionsFields_user_Delete",
	permission: boolean
};
	["SubscriptionsFields_organiser"]: {
	__typename: "SubscriptionsFields_organiser",
	create?: GraphQLTypes["SubscriptionsFields_organiser_Create"] | undefined,
	read?: GraphQLTypes["SubscriptionsFields_organiser_Read"] | undefined,
	update?: GraphQLTypes["SubscriptionsFields_organiser_Update"] | undefined,
	delete?: GraphQLTypes["SubscriptionsFields_organiser_Delete"] | undefined
};
	["SubscriptionsFields_organiser_Create"]: {
	__typename: "SubscriptionsFields_organiser_Create",
	permission: boolean
};
	["SubscriptionsFields_organiser_Read"]: {
	__typename: "SubscriptionsFields_organiser_Read",
	permission: boolean
};
	["SubscriptionsFields_organiser_Update"]: {
	__typename: "SubscriptionsFields_organiser_Update",
	permission: boolean
};
	["SubscriptionsFields_organiser_Delete"]: {
	__typename: "SubscriptionsFields_organiser_Delete",
	permission: boolean
};
	["SubscriptionsFields_updatedAt"]: {
	__typename: "SubscriptionsFields_updatedAt",
	create?: GraphQLTypes["SubscriptionsFields_updatedAt_Create"] | undefined,
	read?: GraphQLTypes["SubscriptionsFields_updatedAt_Read"] | undefined,
	update?: GraphQLTypes["SubscriptionsFields_updatedAt_Update"] | undefined,
	delete?: GraphQLTypes["SubscriptionsFields_updatedAt_Delete"] | undefined
};
	["SubscriptionsFields_updatedAt_Create"]: {
	__typename: "SubscriptionsFields_updatedAt_Create",
	permission: boolean
};
	["SubscriptionsFields_updatedAt_Read"]: {
	__typename: "SubscriptionsFields_updatedAt_Read",
	permission: boolean
};
	["SubscriptionsFields_updatedAt_Update"]: {
	__typename: "SubscriptionsFields_updatedAt_Update",
	permission: boolean
};
	["SubscriptionsFields_updatedAt_Delete"]: {
	__typename: "SubscriptionsFields_updatedAt_Delete",
	permission: boolean
};
	["SubscriptionsFields_createdAt"]: {
	__typename: "SubscriptionsFields_createdAt",
	create?: GraphQLTypes["SubscriptionsFields_createdAt_Create"] | undefined,
	read?: GraphQLTypes["SubscriptionsFields_createdAt_Read"] | undefined,
	update?: GraphQLTypes["SubscriptionsFields_createdAt_Update"] | undefined,
	delete?: GraphQLTypes["SubscriptionsFields_createdAt_Delete"] | undefined
};
	["SubscriptionsFields_createdAt_Create"]: {
	__typename: "SubscriptionsFields_createdAt_Create",
	permission: boolean
};
	["SubscriptionsFields_createdAt_Read"]: {
	__typename: "SubscriptionsFields_createdAt_Read",
	permission: boolean
};
	["SubscriptionsFields_createdAt_Update"]: {
	__typename: "SubscriptionsFields_createdAt_Update",
	permission: boolean
};
	["SubscriptionsFields_createdAt_Delete"]: {
	__typename: "SubscriptionsFields_createdAt_Delete",
	permission: boolean
};
	["SubscriptionsCreateAccess"]: {
	__typename: "SubscriptionsCreateAccess",
	permission: boolean,
	where?: GraphQLTypes["JSONObject"] | undefined
};
	["SubscriptionsReadAccess"]: {
	__typename: "SubscriptionsReadAccess",
	permission: boolean,
	where?: GraphQLTypes["JSONObject"] | undefined
};
	["SubscriptionsUpdateAccess"]: {
	__typename: "SubscriptionsUpdateAccess",
	permission: boolean,
	where?: GraphQLTypes["JSONObject"] | undefined
};
	["SubscriptionsDeleteAccess"]: {
	__typename: "SubscriptionsDeleteAccess",
	permission: boolean,
	where?: GraphQLTypes["JSONObject"] | undefined
};
	["newsletterAccess"]: {
	__typename: "newsletterAccess",
	fields?: GraphQLTypes["NewsletterFields"] | undefined,
	create?: GraphQLTypes["NewsletterCreateAccess"] | undefined,
	read?: GraphQLTypes["NewsletterReadAccess"] | undefined,
	update?: GraphQLTypes["NewsletterUpdateAccess"] | undefined,
	delete?: GraphQLTypes["NewsletterDeleteAccess"] | undefined
};
	["NewsletterFields"]: {
	__typename: "NewsletterFields",
	email?: GraphQLTypes["NewsletterFields_email"] | undefined,
	region?: GraphQLTypes["NewsletterFields_region"] | undefined,
	updatedAt?: GraphQLTypes["NewsletterFields_updatedAt"] | undefined,
	createdAt?: GraphQLTypes["NewsletterFields_createdAt"] | undefined
};
	["NewsletterFields_email"]: {
	__typename: "NewsletterFields_email",
	create?: GraphQLTypes["NewsletterFields_email_Create"] | undefined,
	read?: GraphQLTypes["NewsletterFields_email_Read"] | undefined,
	update?: GraphQLTypes["NewsletterFields_email_Update"] | undefined,
	delete?: GraphQLTypes["NewsletterFields_email_Delete"] | undefined
};
	["NewsletterFields_email_Create"]: {
	__typename: "NewsletterFields_email_Create",
	permission: boolean
};
	["NewsletterFields_email_Read"]: {
	__typename: "NewsletterFields_email_Read",
	permission: boolean
};
	["NewsletterFields_email_Update"]: {
	__typename: "NewsletterFields_email_Update",
	permission: boolean
};
	["NewsletterFields_email_Delete"]: {
	__typename: "NewsletterFields_email_Delete",
	permission: boolean
};
	["NewsletterFields_region"]: {
	__typename: "NewsletterFields_region",
	create?: GraphQLTypes["NewsletterFields_region_Create"] | undefined,
	read?: GraphQLTypes["NewsletterFields_region_Read"] | undefined,
	update?: GraphQLTypes["NewsletterFields_region_Update"] | undefined,
	delete?: GraphQLTypes["NewsletterFields_region_Delete"] | undefined
};
	["NewsletterFields_region_Create"]: {
	__typename: "NewsletterFields_region_Create",
	permission: boolean
};
	["NewsletterFields_region_Read"]: {
	__typename: "NewsletterFields_region_Read",
	permission: boolean
};
	["NewsletterFields_region_Update"]: {
	__typename: "NewsletterFields_region_Update",
	permission: boolean
};
	["NewsletterFields_region_Delete"]: {
	__typename: "NewsletterFields_region_Delete",
	permission: boolean
};
	["NewsletterFields_updatedAt"]: {
	__typename: "NewsletterFields_updatedAt",
	create?: GraphQLTypes["NewsletterFields_updatedAt_Create"] | undefined,
	read?: GraphQLTypes["NewsletterFields_updatedAt_Read"] | undefined,
	update?: GraphQLTypes["NewsletterFields_updatedAt_Update"] | undefined,
	delete?: GraphQLTypes["NewsletterFields_updatedAt_Delete"] | undefined
};
	["NewsletterFields_updatedAt_Create"]: {
	__typename: "NewsletterFields_updatedAt_Create",
	permission: boolean
};
	["NewsletterFields_updatedAt_Read"]: {
	__typename: "NewsletterFields_updatedAt_Read",
	permission: boolean
};
	["NewsletterFields_updatedAt_Update"]: {
	__typename: "NewsletterFields_updatedAt_Update",
	permission: boolean
};
	["NewsletterFields_updatedAt_Delete"]: {
	__typename: "NewsletterFields_updatedAt_Delete",
	permission: boolean
};
	["NewsletterFields_createdAt"]: {
	__typename: "NewsletterFields_createdAt",
	create?: GraphQLTypes["NewsletterFields_createdAt_Create"] | undefined,
	read?: GraphQLTypes["NewsletterFields_createdAt_Read"] | undefined,
	update?: GraphQLTypes["NewsletterFields_createdAt_Update"] | undefined,
	delete?: GraphQLTypes["NewsletterFields_createdAt_Delete"] | undefined
};
	["NewsletterFields_createdAt_Create"]: {
	__typename: "NewsletterFields_createdAt_Create",
	permission: boolean
};
	["NewsletterFields_createdAt_Read"]: {
	__typename: "NewsletterFields_createdAt_Read",
	permission: boolean
};
	["NewsletterFields_createdAt_Update"]: {
	__typename: "NewsletterFields_createdAt_Update",
	permission: boolean
};
	["NewsletterFields_createdAt_Delete"]: {
	__typename: "NewsletterFields_createdAt_Delete",
	permission: boolean
};
	["NewsletterCreateAccess"]: {
	__typename: "NewsletterCreateAccess",
	permission: boolean,
	where?: GraphQLTypes["JSONObject"] | undefined
};
	["NewsletterReadAccess"]: {
	__typename: "NewsletterReadAccess",
	permission: boolean,
	where?: GraphQLTypes["JSONObject"] | undefined
};
	["NewsletterUpdateAccess"]: {
	__typename: "NewsletterUpdateAccess",
	permission: boolean,
	where?: GraphQLTypes["JSONObject"] | undefined
};
	["NewsletterDeleteAccess"]: {
	__typename: "NewsletterDeleteAccess",
	permission: boolean,
	where?: GraphQLTypes["JSONObject"] | undefined
};
	["blog_categoriesAccess"]: {
	__typename: "blog_categoriesAccess",
	fields?: GraphQLTypes["BlogCategoriesFields"] | undefined,
	create?: GraphQLTypes["BlogCategoriesCreateAccess"] | undefined,
	read?: GraphQLTypes["BlogCategoriesReadAccess"] | undefined,
	update?: GraphQLTypes["BlogCategoriesUpdateAccess"] | undefined,
	delete?: GraphQLTypes["BlogCategoriesDeleteAccess"] | undefined
};
	["BlogCategoriesFields"]: {
	__typename: "BlogCategoriesFields",
	name?: GraphQLTypes["BlogCategoriesFields_name"] | undefined,
	value?: GraphQLTypes["BlogCategoriesFields_value"] | undefined,
	icon?: GraphQLTypes["BlogCategoriesFields_icon"] | undefined,
	region?: GraphQLTypes["BlogCategoriesFields_region"] | undefined,
	updatedAt?: GraphQLTypes["BlogCategoriesFields_updatedAt"] | undefined,
	createdAt?: GraphQLTypes["BlogCategoriesFields_createdAt"] | undefined
};
	["BlogCategoriesFields_name"]: {
	__typename: "BlogCategoriesFields_name",
	create?: GraphQLTypes["BlogCategoriesFields_name_Create"] | undefined,
	read?: GraphQLTypes["BlogCategoriesFields_name_Read"] | undefined,
	update?: GraphQLTypes["BlogCategoriesFields_name_Update"] | undefined,
	delete?: GraphQLTypes["BlogCategoriesFields_name_Delete"] | undefined
};
	["BlogCategoriesFields_name_Create"]: {
	__typename: "BlogCategoriesFields_name_Create",
	permission: boolean
};
	["BlogCategoriesFields_name_Read"]: {
	__typename: "BlogCategoriesFields_name_Read",
	permission: boolean
};
	["BlogCategoriesFields_name_Update"]: {
	__typename: "BlogCategoriesFields_name_Update",
	permission: boolean
};
	["BlogCategoriesFields_name_Delete"]: {
	__typename: "BlogCategoriesFields_name_Delete",
	permission: boolean
};
	["BlogCategoriesFields_value"]: {
	__typename: "BlogCategoriesFields_value",
	create?: GraphQLTypes["BlogCategoriesFields_value_Create"] | undefined,
	read?: GraphQLTypes["BlogCategoriesFields_value_Read"] | undefined,
	update?: GraphQLTypes["BlogCategoriesFields_value_Update"] | undefined,
	delete?: GraphQLTypes["BlogCategoriesFields_value_Delete"] | undefined
};
	["BlogCategoriesFields_value_Create"]: {
	__typename: "BlogCategoriesFields_value_Create",
	permission: boolean
};
	["BlogCategoriesFields_value_Read"]: {
	__typename: "BlogCategoriesFields_value_Read",
	permission: boolean
};
	["BlogCategoriesFields_value_Update"]: {
	__typename: "BlogCategoriesFields_value_Update",
	permission: boolean
};
	["BlogCategoriesFields_value_Delete"]: {
	__typename: "BlogCategoriesFields_value_Delete",
	permission: boolean
};
	["BlogCategoriesFields_icon"]: {
	__typename: "BlogCategoriesFields_icon",
	create?: GraphQLTypes["BlogCategoriesFields_icon_Create"] | undefined,
	read?: GraphQLTypes["BlogCategoriesFields_icon_Read"] | undefined,
	update?: GraphQLTypes["BlogCategoriesFields_icon_Update"] | undefined,
	delete?: GraphQLTypes["BlogCategoriesFields_icon_Delete"] | undefined
};
	["BlogCategoriesFields_icon_Create"]: {
	__typename: "BlogCategoriesFields_icon_Create",
	permission: boolean
};
	["BlogCategoriesFields_icon_Read"]: {
	__typename: "BlogCategoriesFields_icon_Read",
	permission: boolean
};
	["BlogCategoriesFields_icon_Update"]: {
	__typename: "BlogCategoriesFields_icon_Update",
	permission: boolean
};
	["BlogCategoriesFields_icon_Delete"]: {
	__typename: "BlogCategoriesFields_icon_Delete",
	permission: boolean
};
	["BlogCategoriesFields_region"]: {
	__typename: "BlogCategoriesFields_region",
	create?: GraphQLTypes["BlogCategoriesFields_region_Create"] | undefined,
	read?: GraphQLTypes["BlogCategoriesFields_region_Read"] | undefined,
	update?: GraphQLTypes["BlogCategoriesFields_region_Update"] | undefined,
	delete?: GraphQLTypes["BlogCategoriesFields_region_Delete"] | undefined
};
	["BlogCategoriesFields_region_Create"]: {
	__typename: "BlogCategoriesFields_region_Create",
	permission: boolean
};
	["BlogCategoriesFields_region_Read"]: {
	__typename: "BlogCategoriesFields_region_Read",
	permission: boolean
};
	["BlogCategoriesFields_region_Update"]: {
	__typename: "BlogCategoriesFields_region_Update",
	permission: boolean
};
	["BlogCategoriesFields_region_Delete"]: {
	__typename: "BlogCategoriesFields_region_Delete",
	permission: boolean
};
	["BlogCategoriesFields_updatedAt"]: {
	__typename: "BlogCategoriesFields_updatedAt",
	create?: GraphQLTypes["BlogCategoriesFields_updatedAt_Create"] | undefined,
	read?: GraphQLTypes["BlogCategoriesFields_updatedAt_Read"] | undefined,
	update?: GraphQLTypes["BlogCategoriesFields_updatedAt_Update"] | undefined,
	delete?: GraphQLTypes["BlogCategoriesFields_updatedAt_Delete"] | undefined
};
	["BlogCategoriesFields_updatedAt_Create"]: {
	__typename: "BlogCategoriesFields_updatedAt_Create",
	permission: boolean
};
	["BlogCategoriesFields_updatedAt_Read"]: {
	__typename: "BlogCategoriesFields_updatedAt_Read",
	permission: boolean
};
	["BlogCategoriesFields_updatedAt_Update"]: {
	__typename: "BlogCategoriesFields_updatedAt_Update",
	permission: boolean
};
	["BlogCategoriesFields_updatedAt_Delete"]: {
	__typename: "BlogCategoriesFields_updatedAt_Delete",
	permission: boolean
};
	["BlogCategoriesFields_createdAt"]: {
	__typename: "BlogCategoriesFields_createdAt",
	create?: GraphQLTypes["BlogCategoriesFields_createdAt_Create"] | undefined,
	read?: GraphQLTypes["BlogCategoriesFields_createdAt_Read"] | undefined,
	update?: GraphQLTypes["BlogCategoriesFields_createdAt_Update"] | undefined,
	delete?: GraphQLTypes["BlogCategoriesFields_createdAt_Delete"] | undefined
};
	["BlogCategoriesFields_createdAt_Create"]: {
	__typename: "BlogCategoriesFields_createdAt_Create",
	permission: boolean
};
	["BlogCategoriesFields_createdAt_Read"]: {
	__typename: "BlogCategoriesFields_createdAt_Read",
	permission: boolean
};
	["BlogCategoriesFields_createdAt_Update"]: {
	__typename: "BlogCategoriesFields_createdAt_Update",
	permission: boolean
};
	["BlogCategoriesFields_createdAt_Delete"]: {
	__typename: "BlogCategoriesFields_createdAt_Delete",
	permission: boolean
};
	["BlogCategoriesCreateAccess"]: {
	__typename: "BlogCategoriesCreateAccess",
	permission: boolean,
	where?: GraphQLTypes["JSONObject"] | undefined
};
	["BlogCategoriesReadAccess"]: {
	__typename: "BlogCategoriesReadAccess",
	permission: boolean,
	where?: GraphQLTypes["JSONObject"] | undefined
};
	["BlogCategoriesUpdateAccess"]: {
	__typename: "BlogCategoriesUpdateAccess",
	permission: boolean,
	where?: GraphQLTypes["JSONObject"] | undefined
};
	["BlogCategoriesDeleteAccess"]: {
	__typename: "BlogCategoriesDeleteAccess",
	permission: boolean,
	where?: GraphQLTypes["JSONObject"] | undefined
};
	["tagsAccess"]: {
	__typename: "tagsAccess",
	fields?: GraphQLTypes["TagsFields"] | undefined,
	create?: GraphQLTypes["TagsCreateAccess"] | undefined,
	read?: GraphQLTypes["TagsReadAccess"] | undefined,
	update?: GraphQLTypes["TagsUpdateAccess"] | undefined,
	delete?: GraphQLTypes["TagsDeleteAccess"] | undefined
};
	["TagsFields"]: {
	__typename: "TagsFields",
	name?: GraphQLTypes["TagsFields_name"] | undefined,
	value?: GraphQLTypes["TagsFields_value"] | undefined,
	region?: GraphQLTypes["TagsFields_region"] | undefined,
	count?: GraphQLTypes["TagsFields_count"] | undefined,
	skipCountCheck?: GraphQLTypes["TagsFields_skipCountCheck"] | undefined,
	removeNextCron?: GraphQLTypes["TagsFields_removeNextCron"] | undefined
};
	["TagsFields_name"]: {
	__typename: "TagsFields_name",
	create?: GraphQLTypes["TagsFields_name_Create"] | undefined,
	read?: GraphQLTypes["TagsFields_name_Read"] | undefined,
	update?: GraphQLTypes["TagsFields_name_Update"] | undefined,
	delete?: GraphQLTypes["TagsFields_name_Delete"] | undefined
};
	["TagsFields_name_Create"]: {
	__typename: "TagsFields_name_Create",
	permission: boolean
};
	["TagsFields_name_Read"]: {
	__typename: "TagsFields_name_Read",
	permission: boolean
};
	["TagsFields_name_Update"]: {
	__typename: "TagsFields_name_Update",
	permission: boolean
};
	["TagsFields_name_Delete"]: {
	__typename: "TagsFields_name_Delete",
	permission: boolean
};
	["TagsFields_value"]: {
	__typename: "TagsFields_value",
	create?: GraphQLTypes["TagsFields_value_Create"] | undefined,
	read?: GraphQLTypes["TagsFields_value_Read"] | undefined,
	update?: GraphQLTypes["TagsFields_value_Update"] | undefined,
	delete?: GraphQLTypes["TagsFields_value_Delete"] | undefined
};
	["TagsFields_value_Create"]: {
	__typename: "TagsFields_value_Create",
	permission: boolean
};
	["TagsFields_value_Read"]: {
	__typename: "TagsFields_value_Read",
	permission: boolean
};
	["TagsFields_value_Update"]: {
	__typename: "TagsFields_value_Update",
	permission: boolean
};
	["TagsFields_value_Delete"]: {
	__typename: "TagsFields_value_Delete",
	permission: boolean
};
	["TagsFields_region"]: {
	__typename: "TagsFields_region",
	create?: GraphQLTypes["TagsFields_region_Create"] | undefined,
	read?: GraphQLTypes["TagsFields_region_Read"] | undefined,
	update?: GraphQLTypes["TagsFields_region_Update"] | undefined,
	delete?: GraphQLTypes["TagsFields_region_Delete"] | undefined
};
	["TagsFields_region_Create"]: {
	__typename: "TagsFields_region_Create",
	permission: boolean
};
	["TagsFields_region_Read"]: {
	__typename: "TagsFields_region_Read",
	permission: boolean
};
	["TagsFields_region_Update"]: {
	__typename: "TagsFields_region_Update",
	permission: boolean
};
	["TagsFields_region_Delete"]: {
	__typename: "TagsFields_region_Delete",
	permission: boolean
};
	["TagsFields_count"]: {
	__typename: "TagsFields_count",
	create?: GraphQLTypes["TagsFields_count_Create"] | undefined,
	read?: GraphQLTypes["TagsFields_count_Read"] | undefined,
	update?: GraphQLTypes["TagsFields_count_Update"] | undefined,
	delete?: GraphQLTypes["TagsFields_count_Delete"] | undefined
};
	["TagsFields_count_Create"]: {
	__typename: "TagsFields_count_Create",
	permission: boolean
};
	["TagsFields_count_Read"]: {
	__typename: "TagsFields_count_Read",
	permission: boolean
};
	["TagsFields_count_Update"]: {
	__typename: "TagsFields_count_Update",
	permission: boolean
};
	["TagsFields_count_Delete"]: {
	__typename: "TagsFields_count_Delete",
	permission: boolean
};
	["TagsFields_skipCountCheck"]: {
	__typename: "TagsFields_skipCountCheck",
	create?: GraphQLTypes["TagsFields_skipCountCheck_Create"] | undefined,
	read?: GraphQLTypes["TagsFields_skipCountCheck_Read"] | undefined,
	update?: GraphQLTypes["TagsFields_skipCountCheck_Update"] | undefined,
	delete?: GraphQLTypes["TagsFields_skipCountCheck_Delete"] | undefined
};
	["TagsFields_skipCountCheck_Create"]: {
	__typename: "TagsFields_skipCountCheck_Create",
	permission: boolean
};
	["TagsFields_skipCountCheck_Read"]: {
	__typename: "TagsFields_skipCountCheck_Read",
	permission: boolean
};
	["TagsFields_skipCountCheck_Update"]: {
	__typename: "TagsFields_skipCountCheck_Update",
	permission: boolean
};
	["TagsFields_skipCountCheck_Delete"]: {
	__typename: "TagsFields_skipCountCheck_Delete",
	permission: boolean
};
	["TagsFields_removeNextCron"]: {
	__typename: "TagsFields_removeNextCron",
	create?: GraphQLTypes["TagsFields_removeNextCron_Create"] | undefined,
	read?: GraphQLTypes["TagsFields_removeNextCron_Read"] | undefined,
	update?: GraphQLTypes["TagsFields_removeNextCron_Update"] | undefined,
	delete?: GraphQLTypes["TagsFields_removeNextCron_Delete"] | undefined
};
	["TagsFields_removeNextCron_Create"]: {
	__typename: "TagsFields_removeNextCron_Create",
	permission: boolean
};
	["TagsFields_removeNextCron_Read"]: {
	__typename: "TagsFields_removeNextCron_Read",
	permission: boolean
};
	["TagsFields_removeNextCron_Update"]: {
	__typename: "TagsFields_removeNextCron_Update",
	permission: boolean
};
	["TagsFields_removeNextCron_Delete"]: {
	__typename: "TagsFields_removeNextCron_Delete",
	permission: boolean
};
	["TagsCreateAccess"]: {
	__typename: "TagsCreateAccess",
	permission: boolean,
	where?: GraphQLTypes["JSONObject"] | undefined
};
	["TagsReadAccess"]: {
	__typename: "TagsReadAccess",
	permission: boolean,
	where?: GraphQLTypes["JSONObject"] | undefined
};
	["TagsUpdateAccess"]: {
	__typename: "TagsUpdateAccess",
	permission: boolean,
	where?: GraphQLTypes["JSONObject"] | undefined
};
	["TagsDeleteAccess"]: {
	__typename: "TagsDeleteAccess",
	permission: boolean,
	where?: GraphQLTypes["JSONObject"] | undefined
};
	["specialTagsAccess"]: {
	__typename: "specialTagsAccess",
	fields?: GraphQLTypes["SpecialTagsFields"] | undefined,
	create?: GraphQLTypes["SpecialTagsCreateAccess"] | undefined,
	read?: GraphQLTypes["SpecialTagsReadAccess"] | undefined,
	update?: GraphQLTypes["SpecialTagsUpdateAccess"] | undefined,
	delete?: GraphQLTypes["SpecialTagsDeleteAccess"] | undefined
};
	["SpecialTagsFields"]: {
	__typename: "SpecialTagsFields",
	name?: GraphQLTypes["SpecialTagsFields_name"] | undefined,
	value?: GraphQLTypes["SpecialTagsFields_value"] | undefined,
	region?: GraphQLTypes["SpecialTagsFields_region"] | undefined
};
	["SpecialTagsFields_name"]: {
	__typename: "SpecialTagsFields_name",
	create?: GraphQLTypes["SpecialTagsFields_name_Create"] | undefined,
	read?: GraphQLTypes["SpecialTagsFields_name_Read"] | undefined,
	update?: GraphQLTypes["SpecialTagsFields_name_Update"] | undefined,
	delete?: GraphQLTypes["SpecialTagsFields_name_Delete"] | undefined
};
	["SpecialTagsFields_name_Create"]: {
	__typename: "SpecialTagsFields_name_Create",
	permission: boolean
};
	["SpecialTagsFields_name_Read"]: {
	__typename: "SpecialTagsFields_name_Read",
	permission: boolean
};
	["SpecialTagsFields_name_Update"]: {
	__typename: "SpecialTagsFields_name_Update",
	permission: boolean
};
	["SpecialTagsFields_name_Delete"]: {
	__typename: "SpecialTagsFields_name_Delete",
	permission: boolean
};
	["SpecialTagsFields_value"]: {
	__typename: "SpecialTagsFields_value",
	create?: GraphQLTypes["SpecialTagsFields_value_Create"] | undefined,
	read?: GraphQLTypes["SpecialTagsFields_value_Read"] | undefined,
	update?: GraphQLTypes["SpecialTagsFields_value_Update"] | undefined,
	delete?: GraphQLTypes["SpecialTagsFields_value_Delete"] | undefined
};
	["SpecialTagsFields_value_Create"]: {
	__typename: "SpecialTagsFields_value_Create",
	permission: boolean
};
	["SpecialTagsFields_value_Read"]: {
	__typename: "SpecialTagsFields_value_Read",
	permission: boolean
};
	["SpecialTagsFields_value_Update"]: {
	__typename: "SpecialTagsFields_value_Update",
	permission: boolean
};
	["SpecialTagsFields_value_Delete"]: {
	__typename: "SpecialTagsFields_value_Delete",
	permission: boolean
};
	["SpecialTagsFields_region"]: {
	__typename: "SpecialTagsFields_region",
	create?: GraphQLTypes["SpecialTagsFields_region_Create"] | undefined,
	read?: GraphQLTypes["SpecialTagsFields_region_Read"] | undefined,
	update?: GraphQLTypes["SpecialTagsFields_region_Update"] | undefined,
	delete?: GraphQLTypes["SpecialTagsFields_region_Delete"] | undefined
};
	["SpecialTagsFields_region_Create"]: {
	__typename: "SpecialTagsFields_region_Create",
	permission: boolean
};
	["SpecialTagsFields_region_Read"]: {
	__typename: "SpecialTagsFields_region_Read",
	permission: boolean
};
	["SpecialTagsFields_region_Update"]: {
	__typename: "SpecialTagsFields_region_Update",
	permission: boolean
};
	["SpecialTagsFields_region_Delete"]: {
	__typename: "SpecialTagsFields_region_Delete",
	permission: boolean
};
	["SpecialTagsCreateAccess"]: {
	__typename: "SpecialTagsCreateAccess",
	permission: boolean,
	where?: GraphQLTypes["JSONObject"] | undefined
};
	["SpecialTagsReadAccess"]: {
	__typename: "SpecialTagsReadAccess",
	permission: boolean,
	where?: GraphQLTypes["JSONObject"] | undefined
};
	["SpecialTagsUpdateAccess"]: {
	__typename: "SpecialTagsUpdateAccess",
	permission: boolean,
	where?: GraphQLTypes["JSONObject"] | undefined
};
	["SpecialTagsDeleteAccess"]: {
	__typename: "SpecialTagsDeleteAccess",
	permission: boolean,
	where?: GraphQLTypes["JSONObject"] | undefined
};
	["dateAccess"]: {
	__typename: "dateAccess",
	fields?: GraphQLTypes["DateFields"] | undefined,
	create?: GraphQLTypes["DateCreateAccess"] | undefined,
	read?: GraphQLTypes["DateReadAccess"] | undefined,
	update?: GraphQLTypes["DateUpdateAccess"] | undefined,
	delete?: GraphQLTypes["DateDeleteAccess"] | undefined
};
	["DateFields"]: {
	__typename: "DateFields",
	name?: GraphQLTypes["DateFields_name"] | undefined,
	value?: GraphQLTypes["DateFields_value"] | undefined,
	type?: GraphQLTypes["DateFields_type"] | undefined,
	start?: GraphQLTypes["DateFields_start"] | undefined,
	end?: GraphQLTypes["DateFields_end"] | undefined,
	_title?: GraphQLTypes["DateFields__title"] | undefined,
	region?: GraphQLTypes["DateFields_region"] | undefined,
	updatedAt?: GraphQLTypes["DateFields_updatedAt"] | undefined,
	createdAt?: GraphQLTypes["DateFields_createdAt"] | undefined
};
	["DateFields_name"]: {
	__typename: "DateFields_name",
	create?: GraphQLTypes["DateFields_name_Create"] | undefined,
	read?: GraphQLTypes["DateFields_name_Read"] | undefined,
	update?: GraphQLTypes["DateFields_name_Update"] | undefined,
	delete?: GraphQLTypes["DateFields_name_Delete"] | undefined
};
	["DateFields_name_Create"]: {
	__typename: "DateFields_name_Create",
	permission: boolean
};
	["DateFields_name_Read"]: {
	__typename: "DateFields_name_Read",
	permission: boolean
};
	["DateFields_name_Update"]: {
	__typename: "DateFields_name_Update",
	permission: boolean
};
	["DateFields_name_Delete"]: {
	__typename: "DateFields_name_Delete",
	permission: boolean
};
	["DateFields_value"]: {
	__typename: "DateFields_value",
	create?: GraphQLTypes["DateFields_value_Create"] | undefined,
	read?: GraphQLTypes["DateFields_value_Read"] | undefined,
	update?: GraphQLTypes["DateFields_value_Update"] | undefined,
	delete?: GraphQLTypes["DateFields_value_Delete"] | undefined
};
	["DateFields_value_Create"]: {
	__typename: "DateFields_value_Create",
	permission: boolean
};
	["DateFields_value_Read"]: {
	__typename: "DateFields_value_Read",
	permission: boolean
};
	["DateFields_value_Update"]: {
	__typename: "DateFields_value_Update",
	permission: boolean
};
	["DateFields_value_Delete"]: {
	__typename: "DateFields_value_Delete",
	permission: boolean
};
	["DateFields_type"]: {
	__typename: "DateFields_type",
	create?: GraphQLTypes["DateFields_type_Create"] | undefined,
	read?: GraphQLTypes["DateFields_type_Read"] | undefined,
	update?: GraphQLTypes["DateFields_type_Update"] | undefined,
	delete?: GraphQLTypes["DateFields_type_Delete"] | undefined
};
	["DateFields_type_Create"]: {
	__typename: "DateFields_type_Create",
	permission: boolean
};
	["DateFields_type_Read"]: {
	__typename: "DateFields_type_Read",
	permission: boolean
};
	["DateFields_type_Update"]: {
	__typename: "DateFields_type_Update",
	permission: boolean
};
	["DateFields_type_Delete"]: {
	__typename: "DateFields_type_Delete",
	permission: boolean
};
	["DateFields_start"]: {
	__typename: "DateFields_start",
	create?: GraphQLTypes["DateFields_start_Create"] | undefined,
	read?: GraphQLTypes["DateFields_start_Read"] | undefined,
	update?: GraphQLTypes["DateFields_start_Update"] | undefined,
	delete?: GraphQLTypes["DateFields_start_Delete"] | undefined
};
	["DateFields_start_Create"]: {
	__typename: "DateFields_start_Create",
	permission: boolean
};
	["DateFields_start_Read"]: {
	__typename: "DateFields_start_Read",
	permission: boolean
};
	["DateFields_start_Update"]: {
	__typename: "DateFields_start_Update",
	permission: boolean
};
	["DateFields_start_Delete"]: {
	__typename: "DateFields_start_Delete",
	permission: boolean
};
	["DateFields_end"]: {
	__typename: "DateFields_end",
	create?: GraphQLTypes["DateFields_end_Create"] | undefined,
	read?: GraphQLTypes["DateFields_end_Read"] | undefined,
	update?: GraphQLTypes["DateFields_end_Update"] | undefined,
	delete?: GraphQLTypes["DateFields_end_Delete"] | undefined
};
	["DateFields_end_Create"]: {
	__typename: "DateFields_end_Create",
	permission: boolean
};
	["DateFields_end_Read"]: {
	__typename: "DateFields_end_Read",
	permission: boolean
};
	["DateFields_end_Update"]: {
	__typename: "DateFields_end_Update",
	permission: boolean
};
	["DateFields_end_Delete"]: {
	__typename: "DateFields_end_Delete",
	permission: boolean
};
	["DateFields__title"]: {
	__typename: "DateFields__title",
	create?: GraphQLTypes["DateFields__title_Create"] | undefined,
	read?: GraphQLTypes["DateFields__title_Read"] | undefined,
	update?: GraphQLTypes["DateFields__title_Update"] | undefined,
	delete?: GraphQLTypes["DateFields__title_Delete"] | undefined
};
	["DateFields__title_Create"]: {
	__typename: "DateFields__title_Create",
	permission: boolean
};
	["DateFields__title_Read"]: {
	__typename: "DateFields__title_Read",
	permission: boolean
};
	["DateFields__title_Update"]: {
	__typename: "DateFields__title_Update",
	permission: boolean
};
	["DateFields__title_Delete"]: {
	__typename: "DateFields__title_Delete",
	permission: boolean
};
	["DateFields_region"]: {
	__typename: "DateFields_region",
	create?: GraphQLTypes["DateFields_region_Create"] | undefined,
	read?: GraphQLTypes["DateFields_region_Read"] | undefined,
	update?: GraphQLTypes["DateFields_region_Update"] | undefined,
	delete?: GraphQLTypes["DateFields_region_Delete"] | undefined
};
	["DateFields_region_Create"]: {
	__typename: "DateFields_region_Create",
	permission: boolean
};
	["DateFields_region_Read"]: {
	__typename: "DateFields_region_Read",
	permission: boolean
};
	["DateFields_region_Update"]: {
	__typename: "DateFields_region_Update",
	permission: boolean
};
	["DateFields_region_Delete"]: {
	__typename: "DateFields_region_Delete",
	permission: boolean
};
	["DateFields_updatedAt"]: {
	__typename: "DateFields_updatedAt",
	create?: GraphQLTypes["DateFields_updatedAt_Create"] | undefined,
	read?: GraphQLTypes["DateFields_updatedAt_Read"] | undefined,
	update?: GraphQLTypes["DateFields_updatedAt_Update"] | undefined,
	delete?: GraphQLTypes["DateFields_updatedAt_Delete"] | undefined
};
	["DateFields_updatedAt_Create"]: {
	__typename: "DateFields_updatedAt_Create",
	permission: boolean
};
	["DateFields_updatedAt_Read"]: {
	__typename: "DateFields_updatedAt_Read",
	permission: boolean
};
	["DateFields_updatedAt_Update"]: {
	__typename: "DateFields_updatedAt_Update",
	permission: boolean
};
	["DateFields_updatedAt_Delete"]: {
	__typename: "DateFields_updatedAt_Delete",
	permission: boolean
};
	["DateFields_createdAt"]: {
	__typename: "DateFields_createdAt",
	create?: GraphQLTypes["DateFields_createdAt_Create"] | undefined,
	read?: GraphQLTypes["DateFields_createdAt_Read"] | undefined,
	update?: GraphQLTypes["DateFields_createdAt_Update"] | undefined,
	delete?: GraphQLTypes["DateFields_createdAt_Delete"] | undefined
};
	["DateFields_createdAt_Create"]: {
	__typename: "DateFields_createdAt_Create",
	permission: boolean
};
	["DateFields_createdAt_Read"]: {
	__typename: "DateFields_createdAt_Read",
	permission: boolean
};
	["DateFields_createdAt_Update"]: {
	__typename: "DateFields_createdAt_Update",
	permission: boolean
};
	["DateFields_createdAt_Delete"]: {
	__typename: "DateFields_createdAt_Delete",
	permission: boolean
};
	["DateCreateAccess"]: {
	__typename: "DateCreateAccess",
	permission: boolean,
	where?: GraphQLTypes["JSONObject"] | undefined
};
	["DateReadAccess"]: {
	__typename: "DateReadAccess",
	permission: boolean,
	where?: GraphQLTypes["JSONObject"] | undefined
};
	["DateUpdateAccess"]: {
	__typename: "DateUpdateAccess",
	permission: boolean,
	where?: GraphQLTypes["JSONObject"] | undefined
};
	["DateDeleteAccess"]: {
	__typename: "DateDeleteAccess",
	permission: boolean,
	where?: GraphQLTypes["JSONObject"] | undefined
};
	["districtsAccess"]: {
	__typename: "districtsAccess",
	fields?: GraphQLTypes["DistrictsFields"] | undefined,
	create?: GraphQLTypes["DistrictsCreateAccess"] | undefined,
	read?: GraphQLTypes["DistrictsReadAccess"] | undefined,
	update?: GraphQLTypes["DistrictsUpdateAccess"] | undefined,
	delete?: GraphQLTypes["DistrictsDeleteAccess"] | undefined
};
	["DistrictsFields"]: {
	__typename: "DistrictsFields",
	name?: GraphQLTypes["DistrictsFields_name"] | undefined,
	level?: GraphQLTypes["DistrictsFields_level"] | undefined,
	children?: GraphQLTypes["DistrictsFields_children"] | undefined,
	value?: GraphQLTypes["DistrictsFields_value"] | undefined,
	lat?: GraphQLTypes["DistrictsFields_lat"] | undefined,
	long?: GraphQLTypes["DistrictsFields_long"] | undefined,
	radius?: GraphQLTypes["DistrictsFields_radius"] | undefined,
	region?: GraphQLTypes["DistrictsFields_region"] | undefined,
	updatedAt?: GraphQLTypes["DistrictsFields_updatedAt"] | undefined,
	createdAt?: GraphQLTypes["DistrictsFields_createdAt"] | undefined
};
	["DistrictsFields_name"]: {
	__typename: "DistrictsFields_name",
	create?: GraphQLTypes["DistrictsFields_name_Create"] | undefined,
	read?: GraphQLTypes["DistrictsFields_name_Read"] | undefined,
	update?: GraphQLTypes["DistrictsFields_name_Update"] | undefined,
	delete?: GraphQLTypes["DistrictsFields_name_Delete"] | undefined
};
	["DistrictsFields_name_Create"]: {
	__typename: "DistrictsFields_name_Create",
	permission: boolean
};
	["DistrictsFields_name_Read"]: {
	__typename: "DistrictsFields_name_Read",
	permission: boolean
};
	["DistrictsFields_name_Update"]: {
	__typename: "DistrictsFields_name_Update",
	permission: boolean
};
	["DistrictsFields_name_Delete"]: {
	__typename: "DistrictsFields_name_Delete",
	permission: boolean
};
	["DistrictsFields_level"]: {
	__typename: "DistrictsFields_level",
	create?: GraphQLTypes["DistrictsFields_level_Create"] | undefined,
	read?: GraphQLTypes["DistrictsFields_level_Read"] | undefined,
	update?: GraphQLTypes["DistrictsFields_level_Update"] | undefined,
	delete?: GraphQLTypes["DistrictsFields_level_Delete"] | undefined
};
	["DistrictsFields_level_Create"]: {
	__typename: "DistrictsFields_level_Create",
	permission: boolean
};
	["DistrictsFields_level_Read"]: {
	__typename: "DistrictsFields_level_Read",
	permission: boolean
};
	["DistrictsFields_level_Update"]: {
	__typename: "DistrictsFields_level_Update",
	permission: boolean
};
	["DistrictsFields_level_Delete"]: {
	__typename: "DistrictsFields_level_Delete",
	permission: boolean
};
	["DistrictsFields_children"]: {
	__typename: "DistrictsFields_children",
	create?: GraphQLTypes["DistrictsFields_children_Create"] | undefined,
	read?: GraphQLTypes["DistrictsFields_children_Read"] | undefined,
	update?: GraphQLTypes["DistrictsFields_children_Update"] | undefined,
	delete?: GraphQLTypes["DistrictsFields_children_Delete"] | undefined
};
	["DistrictsFields_children_Create"]: {
	__typename: "DistrictsFields_children_Create",
	permission: boolean
};
	["DistrictsFields_children_Read"]: {
	__typename: "DistrictsFields_children_Read",
	permission: boolean
};
	["DistrictsFields_children_Update"]: {
	__typename: "DistrictsFields_children_Update",
	permission: boolean
};
	["DistrictsFields_children_Delete"]: {
	__typename: "DistrictsFields_children_Delete",
	permission: boolean
};
	["DistrictsFields_value"]: {
	__typename: "DistrictsFields_value",
	create?: GraphQLTypes["DistrictsFields_value_Create"] | undefined,
	read?: GraphQLTypes["DistrictsFields_value_Read"] | undefined,
	update?: GraphQLTypes["DistrictsFields_value_Update"] | undefined,
	delete?: GraphQLTypes["DistrictsFields_value_Delete"] | undefined
};
	["DistrictsFields_value_Create"]: {
	__typename: "DistrictsFields_value_Create",
	permission: boolean
};
	["DistrictsFields_value_Read"]: {
	__typename: "DistrictsFields_value_Read",
	permission: boolean
};
	["DistrictsFields_value_Update"]: {
	__typename: "DistrictsFields_value_Update",
	permission: boolean
};
	["DistrictsFields_value_Delete"]: {
	__typename: "DistrictsFields_value_Delete",
	permission: boolean
};
	["DistrictsFields_lat"]: {
	__typename: "DistrictsFields_lat",
	create?: GraphQLTypes["DistrictsFields_lat_Create"] | undefined,
	read?: GraphQLTypes["DistrictsFields_lat_Read"] | undefined,
	update?: GraphQLTypes["DistrictsFields_lat_Update"] | undefined,
	delete?: GraphQLTypes["DistrictsFields_lat_Delete"] | undefined
};
	["DistrictsFields_lat_Create"]: {
	__typename: "DistrictsFields_lat_Create",
	permission: boolean
};
	["DistrictsFields_lat_Read"]: {
	__typename: "DistrictsFields_lat_Read",
	permission: boolean
};
	["DistrictsFields_lat_Update"]: {
	__typename: "DistrictsFields_lat_Update",
	permission: boolean
};
	["DistrictsFields_lat_Delete"]: {
	__typename: "DistrictsFields_lat_Delete",
	permission: boolean
};
	["DistrictsFields_long"]: {
	__typename: "DistrictsFields_long",
	create?: GraphQLTypes["DistrictsFields_long_Create"] | undefined,
	read?: GraphQLTypes["DistrictsFields_long_Read"] | undefined,
	update?: GraphQLTypes["DistrictsFields_long_Update"] | undefined,
	delete?: GraphQLTypes["DistrictsFields_long_Delete"] | undefined
};
	["DistrictsFields_long_Create"]: {
	__typename: "DistrictsFields_long_Create",
	permission: boolean
};
	["DistrictsFields_long_Read"]: {
	__typename: "DistrictsFields_long_Read",
	permission: boolean
};
	["DistrictsFields_long_Update"]: {
	__typename: "DistrictsFields_long_Update",
	permission: boolean
};
	["DistrictsFields_long_Delete"]: {
	__typename: "DistrictsFields_long_Delete",
	permission: boolean
};
	["DistrictsFields_radius"]: {
	__typename: "DistrictsFields_radius",
	create?: GraphQLTypes["DistrictsFields_radius_Create"] | undefined,
	read?: GraphQLTypes["DistrictsFields_radius_Read"] | undefined,
	update?: GraphQLTypes["DistrictsFields_radius_Update"] | undefined,
	delete?: GraphQLTypes["DistrictsFields_radius_Delete"] | undefined
};
	["DistrictsFields_radius_Create"]: {
	__typename: "DistrictsFields_radius_Create",
	permission: boolean
};
	["DistrictsFields_radius_Read"]: {
	__typename: "DistrictsFields_radius_Read",
	permission: boolean
};
	["DistrictsFields_radius_Update"]: {
	__typename: "DistrictsFields_radius_Update",
	permission: boolean
};
	["DistrictsFields_radius_Delete"]: {
	__typename: "DistrictsFields_radius_Delete",
	permission: boolean
};
	["DistrictsFields_region"]: {
	__typename: "DistrictsFields_region",
	create?: GraphQLTypes["DistrictsFields_region_Create"] | undefined,
	read?: GraphQLTypes["DistrictsFields_region_Read"] | undefined,
	update?: GraphQLTypes["DistrictsFields_region_Update"] | undefined,
	delete?: GraphQLTypes["DistrictsFields_region_Delete"] | undefined
};
	["DistrictsFields_region_Create"]: {
	__typename: "DistrictsFields_region_Create",
	permission: boolean
};
	["DistrictsFields_region_Read"]: {
	__typename: "DistrictsFields_region_Read",
	permission: boolean
};
	["DistrictsFields_region_Update"]: {
	__typename: "DistrictsFields_region_Update",
	permission: boolean
};
	["DistrictsFields_region_Delete"]: {
	__typename: "DistrictsFields_region_Delete",
	permission: boolean
};
	["DistrictsFields_updatedAt"]: {
	__typename: "DistrictsFields_updatedAt",
	create?: GraphQLTypes["DistrictsFields_updatedAt_Create"] | undefined,
	read?: GraphQLTypes["DistrictsFields_updatedAt_Read"] | undefined,
	update?: GraphQLTypes["DistrictsFields_updatedAt_Update"] | undefined,
	delete?: GraphQLTypes["DistrictsFields_updatedAt_Delete"] | undefined
};
	["DistrictsFields_updatedAt_Create"]: {
	__typename: "DistrictsFields_updatedAt_Create",
	permission: boolean
};
	["DistrictsFields_updatedAt_Read"]: {
	__typename: "DistrictsFields_updatedAt_Read",
	permission: boolean
};
	["DistrictsFields_updatedAt_Update"]: {
	__typename: "DistrictsFields_updatedAt_Update",
	permission: boolean
};
	["DistrictsFields_updatedAt_Delete"]: {
	__typename: "DistrictsFields_updatedAt_Delete",
	permission: boolean
};
	["DistrictsFields_createdAt"]: {
	__typename: "DistrictsFields_createdAt",
	create?: GraphQLTypes["DistrictsFields_createdAt_Create"] | undefined,
	read?: GraphQLTypes["DistrictsFields_createdAt_Read"] | undefined,
	update?: GraphQLTypes["DistrictsFields_createdAt_Update"] | undefined,
	delete?: GraphQLTypes["DistrictsFields_createdAt_Delete"] | undefined
};
	["DistrictsFields_createdAt_Create"]: {
	__typename: "DistrictsFields_createdAt_Create",
	permission: boolean
};
	["DistrictsFields_createdAt_Read"]: {
	__typename: "DistrictsFields_createdAt_Read",
	permission: boolean
};
	["DistrictsFields_createdAt_Update"]: {
	__typename: "DistrictsFields_createdAt_Update",
	permission: boolean
};
	["DistrictsFields_createdAt_Delete"]: {
	__typename: "DistrictsFields_createdAt_Delete",
	permission: boolean
};
	["DistrictsCreateAccess"]: {
	__typename: "DistrictsCreateAccess",
	permission: boolean,
	where?: GraphQLTypes["JSONObject"] | undefined
};
	["DistrictsReadAccess"]: {
	__typename: "DistrictsReadAccess",
	permission: boolean,
	where?: GraphQLTypes["JSONObject"] | undefined
};
	["DistrictsUpdateAccess"]: {
	__typename: "DistrictsUpdateAccess",
	permission: boolean,
	where?: GraphQLTypes["JSONObject"] | undefined
};
	["DistrictsDeleteAccess"]: {
	__typename: "DistrictsDeleteAccess",
	permission: boolean,
	where?: GraphQLTypes["JSONObject"] | undefined
};
	["audienceAccess"]: {
	__typename: "audienceAccess",
	fields?: GraphQLTypes["AudienceFields"] | undefined,
	create?: GraphQLTypes["AudienceCreateAccess"] | undefined,
	read?: GraphQLTypes["AudienceReadAccess"] | undefined,
	update?: GraphQLTypes["AudienceUpdateAccess"] | undefined,
	delete?: GraphQLTypes["AudienceDeleteAccess"] | undefined
};
	["AudienceFields"]: {
	__typename: "AudienceFields",
	name?: GraphQLTypes["AudienceFields_name"] | undefined,
	value?: GraphQLTypes["AudienceFields_value"] | undefined,
	icon?: GraphQLTypes["AudienceFields_icon"] | undefined,
	region?: GraphQLTypes["AudienceFields_region"] | undefined,
	updatedAt?: GraphQLTypes["AudienceFields_updatedAt"] | undefined,
	createdAt?: GraphQLTypes["AudienceFields_createdAt"] | undefined
};
	["AudienceFields_name"]: {
	__typename: "AudienceFields_name",
	create?: GraphQLTypes["AudienceFields_name_Create"] | undefined,
	read?: GraphQLTypes["AudienceFields_name_Read"] | undefined,
	update?: GraphQLTypes["AudienceFields_name_Update"] | undefined,
	delete?: GraphQLTypes["AudienceFields_name_Delete"] | undefined
};
	["AudienceFields_name_Create"]: {
	__typename: "AudienceFields_name_Create",
	permission: boolean
};
	["AudienceFields_name_Read"]: {
	__typename: "AudienceFields_name_Read",
	permission: boolean
};
	["AudienceFields_name_Update"]: {
	__typename: "AudienceFields_name_Update",
	permission: boolean
};
	["AudienceFields_name_Delete"]: {
	__typename: "AudienceFields_name_Delete",
	permission: boolean
};
	["AudienceFields_value"]: {
	__typename: "AudienceFields_value",
	create?: GraphQLTypes["AudienceFields_value_Create"] | undefined,
	read?: GraphQLTypes["AudienceFields_value_Read"] | undefined,
	update?: GraphQLTypes["AudienceFields_value_Update"] | undefined,
	delete?: GraphQLTypes["AudienceFields_value_Delete"] | undefined
};
	["AudienceFields_value_Create"]: {
	__typename: "AudienceFields_value_Create",
	permission: boolean
};
	["AudienceFields_value_Read"]: {
	__typename: "AudienceFields_value_Read",
	permission: boolean
};
	["AudienceFields_value_Update"]: {
	__typename: "AudienceFields_value_Update",
	permission: boolean
};
	["AudienceFields_value_Delete"]: {
	__typename: "AudienceFields_value_Delete",
	permission: boolean
};
	["AudienceFields_icon"]: {
	__typename: "AudienceFields_icon",
	create?: GraphQLTypes["AudienceFields_icon_Create"] | undefined,
	read?: GraphQLTypes["AudienceFields_icon_Read"] | undefined,
	update?: GraphQLTypes["AudienceFields_icon_Update"] | undefined,
	delete?: GraphQLTypes["AudienceFields_icon_Delete"] | undefined
};
	["AudienceFields_icon_Create"]: {
	__typename: "AudienceFields_icon_Create",
	permission: boolean
};
	["AudienceFields_icon_Read"]: {
	__typename: "AudienceFields_icon_Read",
	permission: boolean
};
	["AudienceFields_icon_Update"]: {
	__typename: "AudienceFields_icon_Update",
	permission: boolean
};
	["AudienceFields_icon_Delete"]: {
	__typename: "AudienceFields_icon_Delete",
	permission: boolean
};
	["AudienceFields_region"]: {
	__typename: "AudienceFields_region",
	create?: GraphQLTypes["AudienceFields_region_Create"] | undefined,
	read?: GraphQLTypes["AudienceFields_region_Read"] | undefined,
	update?: GraphQLTypes["AudienceFields_region_Update"] | undefined,
	delete?: GraphQLTypes["AudienceFields_region_Delete"] | undefined
};
	["AudienceFields_region_Create"]: {
	__typename: "AudienceFields_region_Create",
	permission: boolean
};
	["AudienceFields_region_Read"]: {
	__typename: "AudienceFields_region_Read",
	permission: boolean
};
	["AudienceFields_region_Update"]: {
	__typename: "AudienceFields_region_Update",
	permission: boolean
};
	["AudienceFields_region_Delete"]: {
	__typename: "AudienceFields_region_Delete",
	permission: boolean
};
	["AudienceFields_updatedAt"]: {
	__typename: "AudienceFields_updatedAt",
	create?: GraphQLTypes["AudienceFields_updatedAt_Create"] | undefined,
	read?: GraphQLTypes["AudienceFields_updatedAt_Read"] | undefined,
	update?: GraphQLTypes["AudienceFields_updatedAt_Update"] | undefined,
	delete?: GraphQLTypes["AudienceFields_updatedAt_Delete"] | undefined
};
	["AudienceFields_updatedAt_Create"]: {
	__typename: "AudienceFields_updatedAt_Create",
	permission: boolean
};
	["AudienceFields_updatedAt_Read"]: {
	__typename: "AudienceFields_updatedAt_Read",
	permission: boolean
};
	["AudienceFields_updatedAt_Update"]: {
	__typename: "AudienceFields_updatedAt_Update",
	permission: boolean
};
	["AudienceFields_updatedAt_Delete"]: {
	__typename: "AudienceFields_updatedAt_Delete",
	permission: boolean
};
	["AudienceFields_createdAt"]: {
	__typename: "AudienceFields_createdAt",
	create?: GraphQLTypes["AudienceFields_createdAt_Create"] | undefined,
	read?: GraphQLTypes["AudienceFields_createdAt_Read"] | undefined,
	update?: GraphQLTypes["AudienceFields_createdAt_Update"] | undefined,
	delete?: GraphQLTypes["AudienceFields_createdAt_Delete"] | undefined
};
	["AudienceFields_createdAt_Create"]: {
	__typename: "AudienceFields_createdAt_Create",
	permission: boolean
};
	["AudienceFields_createdAt_Read"]: {
	__typename: "AudienceFields_createdAt_Read",
	permission: boolean
};
	["AudienceFields_createdAt_Update"]: {
	__typename: "AudienceFields_createdAt_Update",
	permission: boolean
};
	["AudienceFields_createdAt_Delete"]: {
	__typename: "AudienceFields_createdAt_Delete",
	permission: boolean
};
	["AudienceCreateAccess"]: {
	__typename: "AudienceCreateAccess",
	permission: boolean,
	where?: GraphQLTypes["JSONObject"] | undefined
};
	["AudienceReadAccess"]: {
	__typename: "AudienceReadAccess",
	permission: boolean,
	where?: GraphQLTypes["JSONObject"] | undefined
};
	["AudienceUpdateAccess"]: {
	__typename: "AudienceUpdateAccess",
	permission: boolean,
	where?: GraphQLTypes["JSONObject"] | undefined
};
	["AudienceDeleteAccess"]: {
	__typename: "AudienceDeleteAccess",
	permission: boolean,
	where?: GraphQLTypes["JSONObject"] | undefined
};
	["categoryAccess"]: {
	__typename: "categoryAccess",
	fields?: GraphQLTypes["CategoryFields"] | undefined,
	create?: GraphQLTypes["CategoryCreateAccess"] | undefined,
	read?: GraphQLTypes["CategoryReadAccess"] | undefined,
	update?: GraphQLTypes["CategoryUpdateAccess"] | undefined,
	delete?: GraphQLTypes["CategoryDeleteAccess"] | undefined
};
	["CategoryFields"]: {
	__typename: "CategoryFields",
	name?: GraphQLTypes["CategoryFields_name"] | undefined,
	value?: GraphQLTypes["CategoryFields_value"] | undefined,
	tags?: GraphQLTypes["CategoryFields_tags"] | undefined,
	icon?: GraphQLTypes["CategoryFields_icon"] | undefined,
	region?: GraphQLTypes["CategoryFields_region"] | undefined,
	updatedAt?: GraphQLTypes["CategoryFields_updatedAt"] | undefined,
	createdAt?: GraphQLTypes["CategoryFields_createdAt"] | undefined
};
	["CategoryFields_name"]: {
	__typename: "CategoryFields_name",
	create?: GraphQLTypes["CategoryFields_name_Create"] | undefined,
	read?: GraphQLTypes["CategoryFields_name_Read"] | undefined,
	update?: GraphQLTypes["CategoryFields_name_Update"] | undefined,
	delete?: GraphQLTypes["CategoryFields_name_Delete"] | undefined
};
	["CategoryFields_name_Create"]: {
	__typename: "CategoryFields_name_Create",
	permission: boolean
};
	["CategoryFields_name_Read"]: {
	__typename: "CategoryFields_name_Read",
	permission: boolean
};
	["CategoryFields_name_Update"]: {
	__typename: "CategoryFields_name_Update",
	permission: boolean
};
	["CategoryFields_name_Delete"]: {
	__typename: "CategoryFields_name_Delete",
	permission: boolean
};
	["CategoryFields_value"]: {
	__typename: "CategoryFields_value",
	create?: GraphQLTypes["CategoryFields_value_Create"] | undefined,
	read?: GraphQLTypes["CategoryFields_value_Read"] | undefined,
	update?: GraphQLTypes["CategoryFields_value_Update"] | undefined,
	delete?: GraphQLTypes["CategoryFields_value_Delete"] | undefined
};
	["CategoryFields_value_Create"]: {
	__typename: "CategoryFields_value_Create",
	permission: boolean
};
	["CategoryFields_value_Read"]: {
	__typename: "CategoryFields_value_Read",
	permission: boolean
};
	["CategoryFields_value_Update"]: {
	__typename: "CategoryFields_value_Update",
	permission: boolean
};
	["CategoryFields_value_Delete"]: {
	__typename: "CategoryFields_value_Delete",
	permission: boolean
};
	["CategoryFields_tags"]: {
	__typename: "CategoryFields_tags",
	create?: GraphQLTypes["CategoryFields_tags_Create"] | undefined,
	read?: GraphQLTypes["CategoryFields_tags_Read"] | undefined,
	update?: GraphQLTypes["CategoryFields_tags_Update"] | undefined,
	delete?: GraphQLTypes["CategoryFields_tags_Delete"] | undefined
};
	["CategoryFields_tags_Create"]: {
	__typename: "CategoryFields_tags_Create",
	permission: boolean
};
	["CategoryFields_tags_Read"]: {
	__typename: "CategoryFields_tags_Read",
	permission: boolean
};
	["CategoryFields_tags_Update"]: {
	__typename: "CategoryFields_tags_Update",
	permission: boolean
};
	["CategoryFields_tags_Delete"]: {
	__typename: "CategoryFields_tags_Delete",
	permission: boolean
};
	["CategoryFields_icon"]: {
	__typename: "CategoryFields_icon",
	create?: GraphQLTypes["CategoryFields_icon_Create"] | undefined,
	read?: GraphQLTypes["CategoryFields_icon_Read"] | undefined,
	update?: GraphQLTypes["CategoryFields_icon_Update"] | undefined,
	delete?: GraphQLTypes["CategoryFields_icon_Delete"] | undefined
};
	["CategoryFields_icon_Create"]: {
	__typename: "CategoryFields_icon_Create",
	permission: boolean
};
	["CategoryFields_icon_Read"]: {
	__typename: "CategoryFields_icon_Read",
	permission: boolean
};
	["CategoryFields_icon_Update"]: {
	__typename: "CategoryFields_icon_Update",
	permission: boolean
};
	["CategoryFields_icon_Delete"]: {
	__typename: "CategoryFields_icon_Delete",
	permission: boolean
};
	["CategoryFields_region"]: {
	__typename: "CategoryFields_region",
	create?: GraphQLTypes["CategoryFields_region_Create"] | undefined,
	read?: GraphQLTypes["CategoryFields_region_Read"] | undefined,
	update?: GraphQLTypes["CategoryFields_region_Update"] | undefined,
	delete?: GraphQLTypes["CategoryFields_region_Delete"] | undefined
};
	["CategoryFields_region_Create"]: {
	__typename: "CategoryFields_region_Create",
	permission: boolean
};
	["CategoryFields_region_Read"]: {
	__typename: "CategoryFields_region_Read",
	permission: boolean
};
	["CategoryFields_region_Update"]: {
	__typename: "CategoryFields_region_Update",
	permission: boolean
};
	["CategoryFields_region_Delete"]: {
	__typename: "CategoryFields_region_Delete",
	permission: boolean
};
	["CategoryFields_updatedAt"]: {
	__typename: "CategoryFields_updatedAt",
	create?: GraphQLTypes["CategoryFields_updatedAt_Create"] | undefined,
	read?: GraphQLTypes["CategoryFields_updatedAt_Read"] | undefined,
	update?: GraphQLTypes["CategoryFields_updatedAt_Update"] | undefined,
	delete?: GraphQLTypes["CategoryFields_updatedAt_Delete"] | undefined
};
	["CategoryFields_updatedAt_Create"]: {
	__typename: "CategoryFields_updatedAt_Create",
	permission: boolean
};
	["CategoryFields_updatedAt_Read"]: {
	__typename: "CategoryFields_updatedAt_Read",
	permission: boolean
};
	["CategoryFields_updatedAt_Update"]: {
	__typename: "CategoryFields_updatedAt_Update",
	permission: boolean
};
	["CategoryFields_updatedAt_Delete"]: {
	__typename: "CategoryFields_updatedAt_Delete",
	permission: boolean
};
	["CategoryFields_createdAt"]: {
	__typename: "CategoryFields_createdAt",
	create?: GraphQLTypes["CategoryFields_createdAt_Create"] | undefined,
	read?: GraphQLTypes["CategoryFields_createdAt_Read"] | undefined,
	update?: GraphQLTypes["CategoryFields_createdAt_Update"] | undefined,
	delete?: GraphQLTypes["CategoryFields_createdAt_Delete"] | undefined
};
	["CategoryFields_createdAt_Create"]: {
	__typename: "CategoryFields_createdAt_Create",
	permission: boolean
};
	["CategoryFields_createdAt_Read"]: {
	__typename: "CategoryFields_createdAt_Read",
	permission: boolean
};
	["CategoryFields_createdAt_Update"]: {
	__typename: "CategoryFields_createdAt_Update",
	permission: boolean
};
	["CategoryFields_createdAt_Delete"]: {
	__typename: "CategoryFields_createdAt_Delete",
	permission: boolean
};
	["CategoryCreateAccess"]: {
	__typename: "CategoryCreateAccess",
	permission: boolean,
	where?: GraphQLTypes["JSONObject"] | undefined
};
	["CategoryReadAccess"]: {
	__typename: "CategoryReadAccess",
	permission: boolean,
	where?: GraphQLTypes["JSONObject"] | undefined
};
	["CategoryUpdateAccess"]: {
	__typename: "CategoryUpdateAccess",
	permission: boolean,
	where?: GraphQLTypes["JSONObject"] | undefined
};
	["CategoryDeleteAccess"]: {
	__typename: "CategoryDeleteAccess",
	permission: boolean,
	where?: GraphQLTypes["JSONObject"] | undefined
};
	["private_mediaAccess"]: {
	__typename: "private_mediaAccess",
	fields?: GraphQLTypes["PrivateMediaFields"] | undefined,
	create?: GraphQLTypes["PrivateMediaCreateAccess"] | undefined,
	read?: GraphQLTypes["PrivateMediaReadAccess"] | undefined,
	update?: GraphQLTypes["PrivateMediaUpdateAccess"] | undefined,
	delete?: GraphQLTypes["PrivateMediaDeleteAccess"] | undefined
};
	["PrivateMediaFields"]: {
	__typename: "PrivateMediaFields",
	alt?: GraphQLTypes["PrivateMediaFields_alt"] | undefined,
	updatedAt?: GraphQLTypes["PrivateMediaFields_updatedAt"] | undefined,
	createdAt?: GraphQLTypes["PrivateMediaFields_createdAt"] | undefined,
	url?: GraphQLTypes["PrivateMediaFields_url"] | undefined,
	filename?: GraphQLTypes["PrivateMediaFields_filename"] | undefined,
	mimeType?: GraphQLTypes["PrivateMediaFields_mimeType"] | undefined,
	filesize?: GraphQLTypes["PrivateMediaFields_filesize"] | undefined,
	width?: GraphQLTypes["PrivateMediaFields_width"] | undefined,
	height?: GraphQLTypes["PrivateMediaFields_height"] | undefined,
	focalX?: GraphQLTypes["PrivateMediaFields_focalX"] | undefined,
	focalY?: GraphQLTypes["PrivateMediaFields_focalY"] | undefined,
	sizes?: GraphQLTypes["PrivateMediaFields_sizes"] | undefined
};
	["PrivateMediaFields_alt"]: {
	__typename: "PrivateMediaFields_alt",
	create?: GraphQLTypes["PrivateMediaFields_alt_Create"] | undefined,
	read?: GraphQLTypes["PrivateMediaFields_alt_Read"] | undefined,
	update?: GraphQLTypes["PrivateMediaFields_alt_Update"] | undefined,
	delete?: GraphQLTypes["PrivateMediaFields_alt_Delete"] | undefined
};
	["PrivateMediaFields_alt_Create"]: {
	__typename: "PrivateMediaFields_alt_Create",
	permission: boolean
};
	["PrivateMediaFields_alt_Read"]: {
	__typename: "PrivateMediaFields_alt_Read",
	permission: boolean
};
	["PrivateMediaFields_alt_Update"]: {
	__typename: "PrivateMediaFields_alt_Update",
	permission: boolean
};
	["PrivateMediaFields_alt_Delete"]: {
	__typename: "PrivateMediaFields_alt_Delete",
	permission: boolean
};
	["PrivateMediaFields_updatedAt"]: {
	__typename: "PrivateMediaFields_updatedAt",
	create?: GraphQLTypes["PrivateMediaFields_updatedAt_Create"] | undefined,
	read?: GraphQLTypes["PrivateMediaFields_updatedAt_Read"] | undefined,
	update?: GraphQLTypes["PrivateMediaFields_updatedAt_Update"] | undefined,
	delete?: GraphQLTypes["PrivateMediaFields_updatedAt_Delete"] | undefined
};
	["PrivateMediaFields_updatedAt_Create"]: {
	__typename: "PrivateMediaFields_updatedAt_Create",
	permission: boolean
};
	["PrivateMediaFields_updatedAt_Read"]: {
	__typename: "PrivateMediaFields_updatedAt_Read",
	permission: boolean
};
	["PrivateMediaFields_updatedAt_Update"]: {
	__typename: "PrivateMediaFields_updatedAt_Update",
	permission: boolean
};
	["PrivateMediaFields_updatedAt_Delete"]: {
	__typename: "PrivateMediaFields_updatedAt_Delete",
	permission: boolean
};
	["PrivateMediaFields_createdAt"]: {
	__typename: "PrivateMediaFields_createdAt",
	create?: GraphQLTypes["PrivateMediaFields_createdAt_Create"] | undefined,
	read?: GraphQLTypes["PrivateMediaFields_createdAt_Read"] | undefined,
	update?: GraphQLTypes["PrivateMediaFields_createdAt_Update"] | undefined,
	delete?: GraphQLTypes["PrivateMediaFields_createdAt_Delete"] | undefined
};
	["PrivateMediaFields_createdAt_Create"]: {
	__typename: "PrivateMediaFields_createdAt_Create",
	permission: boolean
};
	["PrivateMediaFields_createdAt_Read"]: {
	__typename: "PrivateMediaFields_createdAt_Read",
	permission: boolean
};
	["PrivateMediaFields_createdAt_Update"]: {
	__typename: "PrivateMediaFields_createdAt_Update",
	permission: boolean
};
	["PrivateMediaFields_createdAt_Delete"]: {
	__typename: "PrivateMediaFields_createdAt_Delete",
	permission: boolean
};
	["PrivateMediaFields_url"]: {
	__typename: "PrivateMediaFields_url",
	create?: GraphQLTypes["PrivateMediaFields_url_Create"] | undefined,
	read?: GraphQLTypes["PrivateMediaFields_url_Read"] | undefined,
	update?: GraphQLTypes["PrivateMediaFields_url_Update"] | undefined,
	delete?: GraphQLTypes["PrivateMediaFields_url_Delete"] | undefined
};
	["PrivateMediaFields_url_Create"]: {
	__typename: "PrivateMediaFields_url_Create",
	permission: boolean
};
	["PrivateMediaFields_url_Read"]: {
	__typename: "PrivateMediaFields_url_Read",
	permission: boolean
};
	["PrivateMediaFields_url_Update"]: {
	__typename: "PrivateMediaFields_url_Update",
	permission: boolean
};
	["PrivateMediaFields_url_Delete"]: {
	__typename: "PrivateMediaFields_url_Delete",
	permission: boolean
};
	["PrivateMediaFields_filename"]: {
	__typename: "PrivateMediaFields_filename",
	create?: GraphQLTypes["PrivateMediaFields_filename_Create"] | undefined,
	read?: GraphQLTypes["PrivateMediaFields_filename_Read"] | undefined,
	update?: GraphQLTypes["PrivateMediaFields_filename_Update"] | undefined,
	delete?: GraphQLTypes["PrivateMediaFields_filename_Delete"] | undefined
};
	["PrivateMediaFields_filename_Create"]: {
	__typename: "PrivateMediaFields_filename_Create",
	permission: boolean
};
	["PrivateMediaFields_filename_Read"]: {
	__typename: "PrivateMediaFields_filename_Read",
	permission: boolean
};
	["PrivateMediaFields_filename_Update"]: {
	__typename: "PrivateMediaFields_filename_Update",
	permission: boolean
};
	["PrivateMediaFields_filename_Delete"]: {
	__typename: "PrivateMediaFields_filename_Delete",
	permission: boolean
};
	["PrivateMediaFields_mimeType"]: {
	__typename: "PrivateMediaFields_mimeType",
	create?: GraphQLTypes["PrivateMediaFields_mimeType_Create"] | undefined,
	read?: GraphQLTypes["PrivateMediaFields_mimeType_Read"] | undefined,
	update?: GraphQLTypes["PrivateMediaFields_mimeType_Update"] | undefined,
	delete?: GraphQLTypes["PrivateMediaFields_mimeType_Delete"] | undefined
};
	["PrivateMediaFields_mimeType_Create"]: {
	__typename: "PrivateMediaFields_mimeType_Create",
	permission: boolean
};
	["PrivateMediaFields_mimeType_Read"]: {
	__typename: "PrivateMediaFields_mimeType_Read",
	permission: boolean
};
	["PrivateMediaFields_mimeType_Update"]: {
	__typename: "PrivateMediaFields_mimeType_Update",
	permission: boolean
};
	["PrivateMediaFields_mimeType_Delete"]: {
	__typename: "PrivateMediaFields_mimeType_Delete",
	permission: boolean
};
	["PrivateMediaFields_filesize"]: {
	__typename: "PrivateMediaFields_filesize",
	create?: GraphQLTypes["PrivateMediaFields_filesize_Create"] | undefined,
	read?: GraphQLTypes["PrivateMediaFields_filesize_Read"] | undefined,
	update?: GraphQLTypes["PrivateMediaFields_filesize_Update"] | undefined,
	delete?: GraphQLTypes["PrivateMediaFields_filesize_Delete"] | undefined
};
	["PrivateMediaFields_filesize_Create"]: {
	__typename: "PrivateMediaFields_filesize_Create",
	permission: boolean
};
	["PrivateMediaFields_filesize_Read"]: {
	__typename: "PrivateMediaFields_filesize_Read",
	permission: boolean
};
	["PrivateMediaFields_filesize_Update"]: {
	__typename: "PrivateMediaFields_filesize_Update",
	permission: boolean
};
	["PrivateMediaFields_filesize_Delete"]: {
	__typename: "PrivateMediaFields_filesize_Delete",
	permission: boolean
};
	["PrivateMediaFields_width"]: {
	__typename: "PrivateMediaFields_width",
	create?: GraphQLTypes["PrivateMediaFields_width_Create"] | undefined,
	read?: GraphQLTypes["PrivateMediaFields_width_Read"] | undefined,
	update?: GraphQLTypes["PrivateMediaFields_width_Update"] | undefined,
	delete?: GraphQLTypes["PrivateMediaFields_width_Delete"] | undefined
};
	["PrivateMediaFields_width_Create"]: {
	__typename: "PrivateMediaFields_width_Create",
	permission: boolean
};
	["PrivateMediaFields_width_Read"]: {
	__typename: "PrivateMediaFields_width_Read",
	permission: boolean
};
	["PrivateMediaFields_width_Update"]: {
	__typename: "PrivateMediaFields_width_Update",
	permission: boolean
};
	["PrivateMediaFields_width_Delete"]: {
	__typename: "PrivateMediaFields_width_Delete",
	permission: boolean
};
	["PrivateMediaFields_height"]: {
	__typename: "PrivateMediaFields_height",
	create?: GraphQLTypes["PrivateMediaFields_height_Create"] | undefined,
	read?: GraphQLTypes["PrivateMediaFields_height_Read"] | undefined,
	update?: GraphQLTypes["PrivateMediaFields_height_Update"] | undefined,
	delete?: GraphQLTypes["PrivateMediaFields_height_Delete"] | undefined
};
	["PrivateMediaFields_height_Create"]: {
	__typename: "PrivateMediaFields_height_Create",
	permission: boolean
};
	["PrivateMediaFields_height_Read"]: {
	__typename: "PrivateMediaFields_height_Read",
	permission: boolean
};
	["PrivateMediaFields_height_Update"]: {
	__typename: "PrivateMediaFields_height_Update",
	permission: boolean
};
	["PrivateMediaFields_height_Delete"]: {
	__typename: "PrivateMediaFields_height_Delete",
	permission: boolean
};
	["PrivateMediaFields_focalX"]: {
	__typename: "PrivateMediaFields_focalX",
	create?: GraphQLTypes["PrivateMediaFields_focalX_Create"] | undefined,
	read?: GraphQLTypes["PrivateMediaFields_focalX_Read"] | undefined,
	update?: GraphQLTypes["PrivateMediaFields_focalX_Update"] | undefined,
	delete?: GraphQLTypes["PrivateMediaFields_focalX_Delete"] | undefined
};
	["PrivateMediaFields_focalX_Create"]: {
	__typename: "PrivateMediaFields_focalX_Create",
	permission: boolean
};
	["PrivateMediaFields_focalX_Read"]: {
	__typename: "PrivateMediaFields_focalX_Read",
	permission: boolean
};
	["PrivateMediaFields_focalX_Update"]: {
	__typename: "PrivateMediaFields_focalX_Update",
	permission: boolean
};
	["PrivateMediaFields_focalX_Delete"]: {
	__typename: "PrivateMediaFields_focalX_Delete",
	permission: boolean
};
	["PrivateMediaFields_focalY"]: {
	__typename: "PrivateMediaFields_focalY",
	create?: GraphQLTypes["PrivateMediaFields_focalY_Create"] | undefined,
	read?: GraphQLTypes["PrivateMediaFields_focalY_Read"] | undefined,
	update?: GraphQLTypes["PrivateMediaFields_focalY_Update"] | undefined,
	delete?: GraphQLTypes["PrivateMediaFields_focalY_Delete"] | undefined
};
	["PrivateMediaFields_focalY_Create"]: {
	__typename: "PrivateMediaFields_focalY_Create",
	permission: boolean
};
	["PrivateMediaFields_focalY_Read"]: {
	__typename: "PrivateMediaFields_focalY_Read",
	permission: boolean
};
	["PrivateMediaFields_focalY_Update"]: {
	__typename: "PrivateMediaFields_focalY_Update",
	permission: boolean
};
	["PrivateMediaFields_focalY_Delete"]: {
	__typename: "PrivateMediaFields_focalY_Delete",
	permission: boolean
};
	["PrivateMediaFields_sizes"]: {
	__typename: "PrivateMediaFields_sizes",
	create?: GraphQLTypes["PrivateMediaFields_sizes_Create"] | undefined,
	read?: GraphQLTypes["PrivateMediaFields_sizes_Read"] | undefined,
	update?: GraphQLTypes["PrivateMediaFields_sizes_Update"] | undefined,
	delete?: GraphQLTypes["PrivateMediaFields_sizes_Delete"] | undefined,
	fields?: GraphQLTypes["PrivateMediaFields_sizes_Fields"] | undefined
};
	["PrivateMediaFields_sizes_Create"]: {
	__typename: "PrivateMediaFields_sizes_Create",
	permission: boolean
};
	["PrivateMediaFields_sizes_Read"]: {
	__typename: "PrivateMediaFields_sizes_Read",
	permission: boolean
};
	["PrivateMediaFields_sizes_Update"]: {
	__typename: "PrivateMediaFields_sizes_Update",
	permission: boolean
};
	["PrivateMediaFields_sizes_Delete"]: {
	__typename: "PrivateMediaFields_sizes_Delete",
	permission: boolean
};
	["PrivateMediaFields_sizes_Fields"]: {
	__typename: "PrivateMediaFields_sizes_Fields",
	thumbnail?: GraphQLTypes["PrivateMediaFields_sizes_thumbnail"] | undefined,
	portrait?: GraphQLTypes["PrivateMediaFields_sizes_portrait"] | undefined,
	hero?: GraphQLTypes["PrivateMediaFields_sizes_hero"] | undefined,
	thumbnail_jpeg?: GraphQLTypes["PrivateMediaFields_sizes_thumbnail_jpeg"] | undefined,
	portrait_jpeg?: GraphQLTypes["PrivateMediaFields_sizes_portrait_jpeg"] | undefined,
	hero_jpeg?: GraphQLTypes["PrivateMediaFields_sizes_hero_jpeg"] | undefined
};
	["PrivateMediaFields_sizes_thumbnail"]: {
	__typename: "PrivateMediaFields_sizes_thumbnail",
	create?: GraphQLTypes["PrivateMediaFields_sizes_thumbnail_Create"] | undefined,
	read?: GraphQLTypes["PrivateMediaFields_sizes_thumbnail_Read"] | undefined,
	update?: GraphQLTypes["PrivateMediaFields_sizes_thumbnail_Update"] | undefined,
	delete?: GraphQLTypes["PrivateMediaFields_sizes_thumbnail_Delete"] | undefined,
	fields?: GraphQLTypes["PrivateMediaFields_sizes_thumbnail_Fields"] | undefined
};
	["PrivateMediaFields_sizes_thumbnail_Create"]: {
	__typename: "PrivateMediaFields_sizes_thumbnail_Create",
	permission: boolean
};
	["PrivateMediaFields_sizes_thumbnail_Read"]: {
	__typename: "PrivateMediaFields_sizes_thumbnail_Read",
	permission: boolean
};
	["PrivateMediaFields_sizes_thumbnail_Update"]: {
	__typename: "PrivateMediaFields_sizes_thumbnail_Update",
	permission: boolean
};
	["PrivateMediaFields_sizes_thumbnail_Delete"]: {
	__typename: "PrivateMediaFields_sizes_thumbnail_Delete",
	permission: boolean
};
	["PrivateMediaFields_sizes_thumbnail_Fields"]: {
	__typename: "PrivateMediaFields_sizes_thumbnail_Fields",
	url?: GraphQLTypes["PrivateMediaFields_sizes_thumbnail_url"] | undefined,
	width?: GraphQLTypes["PrivateMediaFields_sizes_thumbnail_width"] | undefined,
	height?: GraphQLTypes["PrivateMediaFields_sizes_thumbnail_height"] | undefined,
	mimeType?: GraphQLTypes["PrivateMediaFields_sizes_thumbnail_mimeType"] | undefined,
	filesize?: GraphQLTypes["PrivateMediaFields_sizes_thumbnail_filesize"] | undefined,
	filename?: GraphQLTypes["PrivateMediaFields_sizes_thumbnail_filename"] | undefined
};
	["PrivateMediaFields_sizes_thumbnail_url"]: {
	__typename: "PrivateMediaFields_sizes_thumbnail_url",
	create?: GraphQLTypes["PrivateMediaFields_sizes_thumbnail_url_Create"] | undefined,
	read?: GraphQLTypes["PrivateMediaFields_sizes_thumbnail_url_Read"] | undefined,
	update?: GraphQLTypes["PrivateMediaFields_sizes_thumbnail_url_Update"] | undefined,
	delete?: GraphQLTypes["PrivateMediaFields_sizes_thumbnail_url_Delete"] | undefined
};
	["PrivateMediaFields_sizes_thumbnail_url_Create"]: {
	__typename: "PrivateMediaFields_sizes_thumbnail_url_Create",
	permission: boolean
};
	["PrivateMediaFields_sizes_thumbnail_url_Read"]: {
	__typename: "PrivateMediaFields_sizes_thumbnail_url_Read",
	permission: boolean
};
	["PrivateMediaFields_sizes_thumbnail_url_Update"]: {
	__typename: "PrivateMediaFields_sizes_thumbnail_url_Update",
	permission: boolean
};
	["PrivateMediaFields_sizes_thumbnail_url_Delete"]: {
	__typename: "PrivateMediaFields_sizes_thumbnail_url_Delete",
	permission: boolean
};
	["PrivateMediaFields_sizes_thumbnail_width"]: {
	__typename: "PrivateMediaFields_sizes_thumbnail_width",
	create?: GraphQLTypes["PrivateMediaFields_sizes_thumbnail_width_Create"] | undefined,
	read?: GraphQLTypes["PrivateMediaFields_sizes_thumbnail_width_Read"] | undefined,
	update?: GraphQLTypes["PrivateMediaFields_sizes_thumbnail_width_Update"] | undefined,
	delete?: GraphQLTypes["PrivateMediaFields_sizes_thumbnail_width_Delete"] | undefined
};
	["PrivateMediaFields_sizes_thumbnail_width_Create"]: {
	__typename: "PrivateMediaFields_sizes_thumbnail_width_Create",
	permission: boolean
};
	["PrivateMediaFields_sizes_thumbnail_width_Read"]: {
	__typename: "PrivateMediaFields_sizes_thumbnail_width_Read",
	permission: boolean
};
	["PrivateMediaFields_sizes_thumbnail_width_Update"]: {
	__typename: "PrivateMediaFields_sizes_thumbnail_width_Update",
	permission: boolean
};
	["PrivateMediaFields_sizes_thumbnail_width_Delete"]: {
	__typename: "PrivateMediaFields_sizes_thumbnail_width_Delete",
	permission: boolean
};
	["PrivateMediaFields_sizes_thumbnail_height"]: {
	__typename: "PrivateMediaFields_sizes_thumbnail_height",
	create?: GraphQLTypes["PrivateMediaFields_sizes_thumbnail_height_Create"] | undefined,
	read?: GraphQLTypes["PrivateMediaFields_sizes_thumbnail_height_Read"] | undefined,
	update?: GraphQLTypes["PrivateMediaFields_sizes_thumbnail_height_Update"] | undefined,
	delete?: GraphQLTypes["PrivateMediaFields_sizes_thumbnail_height_Delete"] | undefined
};
	["PrivateMediaFields_sizes_thumbnail_height_Create"]: {
	__typename: "PrivateMediaFields_sizes_thumbnail_height_Create",
	permission: boolean
};
	["PrivateMediaFields_sizes_thumbnail_height_Read"]: {
	__typename: "PrivateMediaFields_sizes_thumbnail_height_Read",
	permission: boolean
};
	["PrivateMediaFields_sizes_thumbnail_height_Update"]: {
	__typename: "PrivateMediaFields_sizes_thumbnail_height_Update",
	permission: boolean
};
	["PrivateMediaFields_sizes_thumbnail_height_Delete"]: {
	__typename: "PrivateMediaFields_sizes_thumbnail_height_Delete",
	permission: boolean
};
	["PrivateMediaFields_sizes_thumbnail_mimeType"]: {
	__typename: "PrivateMediaFields_sizes_thumbnail_mimeType",
	create?: GraphQLTypes["PrivateMediaFields_sizes_thumbnail_mimeType_Create"] | undefined,
	read?: GraphQLTypes["PrivateMediaFields_sizes_thumbnail_mimeType_Read"] | undefined,
	update?: GraphQLTypes["PrivateMediaFields_sizes_thumbnail_mimeType_Update"] | undefined,
	delete?: GraphQLTypes["PrivateMediaFields_sizes_thumbnail_mimeType_Delete"] | undefined
};
	["PrivateMediaFields_sizes_thumbnail_mimeType_Create"]: {
	__typename: "PrivateMediaFields_sizes_thumbnail_mimeType_Create",
	permission: boolean
};
	["PrivateMediaFields_sizes_thumbnail_mimeType_Read"]: {
	__typename: "PrivateMediaFields_sizes_thumbnail_mimeType_Read",
	permission: boolean
};
	["PrivateMediaFields_sizes_thumbnail_mimeType_Update"]: {
	__typename: "PrivateMediaFields_sizes_thumbnail_mimeType_Update",
	permission: boolean
};
	["PrivateMediaFields_sizes_thumbnail_mimeType_Delete"]: {
	__typename: "PrivateMediaFields_sizes_thumbnail_mimeType_Delete",
	permission: boolean
};
	["PrivateMediaFields_sizes_thumbnail_filesize"]: {
	__typename: "PrivateMediaFields_sizes_thumbnail_filesize",
	create?: GraphQLTypes["PrivateMediaFields_sizes_thumbnail_filesize_Create"] | undefined,
	read?: GraphQLTypes["PrivateMediaFields_sizes_thumbnail_filesize_Read"] | undefined,
	update?: GraphQLTypes["PrivateMediaFields_sizes_thumbnail_filesize_Update"] | undefined,
	delete?: GraphQLTypes["PrivateMediaFields_sizes_thumbnail_filesize_Delete"] | undefined
};
	["PrivateMediaFields_sizes_thumbnail_filesize_Create"]: {
	__typename: "PrivateMediaFields_sizes_thumbnail_filesize_Create",
	permission: boolean
};
	["PrivateMediaFields_sizes_thumbnail_filesize_Read"]: {
	__typename: "PrivateMediaFields_sizes_thumbnail_filesize_Read",
	permission: boolean
};
	["PrivateMediaFields_sizes_thumbnail_filesize_Update"]: {
	__typename: "PrivateMediaFields_sizes_thumbnail_filesize_Update",
	permission: boolean
};
	["PrivateMediaFields_sizes_thumbnail_filesize_Delete"]: {
	__typename: "PrivateMediaFields_sizes_thumbnail_filesize_Delete",
	permission: boolean
};
	["PrivateMediaFields_sizes_thumbnail_filename"]: {
	__typename: "PrivateMediaFields_sizes_thumbnail_filename",
	create?: GraphQLTypes["PrivateMediaFields_sizes_thumbnail_filename_Create"] | undefined,
	read?: GraphQLTypes["PrivateMediaFields_sizes_thumbnail_filename_Read"] | undefined,
	update?: GraphQLTypes["PrivateMediaFields_sizes_thumbnail_filename_Update"] | undefined,
	delete?: GraphQLTypes["PrivateMediaFields_sizes_thumbnail_filename_Delete"] | undefined
};
	["PrivateMediaFields_sizes_thumbnail_filename_Create"]: {
	__typename: "PrivateMediaFields_sizes_thumbnail_filename_Create",
	permission: boolean
};
	["PrivateMediaFields_sizes_thumbnail_filename_Read"]: {
	__typename: "PrivateMediaFields_sizes_thumbnail_filename_Read",
	permission: boolean
};
	["PrivateMediaFields_sizes_thumbnail_filename_Update"]: {
	__typename: "PrivateMediaFields_sizes_thumbnail_filename_Update",
	permission: boolean
};
	["PrivateMediaFields_sizes_thumbnail_filename_Delete"]: {
	__typename: "PrivateMediaFields_sizes_thumbnail_filename_Delete",
	permission: boolean
};
	["PrivateMediaFields_sizes_portrait"]: {
	__typename: "PrivateMediaFields_sizes_portrait",
	create?: GraphQLTypes["PrivateMediaFields_sizes_portrait_Create"] | undefined,
	read?: GraphQLTypes["PrivateMediaFields_sizes_portrait_Read"] | undefined,
	update?: GraphQLTypes["PrivateMediaFields_sizes_portrait_Update"] | undefined,
	delete?: GraphQLTypes["PrivateMediaFields_sizes_portrait_Delete"] | undefined,
	fields?: GraphQLTypes["PrivateMediaFields_sizes_portrait_Fields"] | undefined
};
	["PrivateMediaFields_sizes_portrait_Create"]: {
	__typename: "PrivateMediaFields_sizes_portrait_Create",
	permission: boolean
};
	["PrivateMediaFields_sizes_portrait_Read"]: {
	__typename: "PrivateMediaFields_sizes_portrait_Read",
	permission: boolean
};
	["PrivateMediaFields_sizes_portrait_Update"]: {
	__typename: "PrivateMediaFields_sizes_portrait_Update",
	permission: boolean
};
	["PrivateMediaFields_sizes_portrait_Delete"]: {
	__typename: "PrivateMediaFields_sizes_portrait_Delete",
	permission: boolean
};
	["PrivateMediaFields_sizes_portrait_Fields"]: {
	__typename: "PrivateMediaFields_sizes_portrait_Fields",
	url?: GraphQLTypes["PrivateMediaFields_sizes_portrait_url"] | undefined,
	width?: GraphQLTypes["PrivateMediaFields_sizes_portrait_width"] | undefined,
	height?: GraphQLTypes["PrivateMediaFields_sizes_portrait_height"] | undefined,
	mimeType?: GraphQLTypes["PrivateMediaFields_sizes_portrait_mimeType"] | undefined,
	filesize?: GraphQLTypes["PrivateMediaFields_sizes_portrait_filesize"] | undefined,
	filename?: GraphQLTypes["PrivateMediaFields_sizes_portrait_filename"] | undefined
};
	["PrivateMediaFields_sizes_portrait_url"]: {
	__typename: "PrivateMediaFields_sizes_portrait_url",
	create?: GraphQLTypes["PrivateMediaFields_sizes_portrait_url_Create"] | undefined,
	read?: GraphQLTypes["PrivateMediaFields_sizes_portrait_url_Read"] | undefined,
	update?: GraphQLTypes["PrivateMediaFields_sizes_portrait_url_Update"] | undefined,
	delete?: GraphQLTypes["PrivateMediaFields_sizes_portrait_url_Delete"] | undefined
};
	["PrivateMediaFields_sizes_portrait_url_Create"]: {
	__typename: "PrivateMediaFields_sizes_portrait_url_Create",
	permission: boolean
};
	["PrivateMediaFields_sizes_portrait_url_Read"]: {
	__typename: "PrivateMediaFields_sizes_portrait_url_Read",
	permission: boolean
};
	["PrivateMediaFields_sizes_portrait_url_Update"]: {
	__typename: "PrivateMediaFields_sizes_portrait_url_Update",
	permission: boolean
};
	["PrivateMediaFields_sizes_portrait_url_Delete"]: {
	__typename: "PrivateMediaFields_sizes_portrait_url_Delete",
	permission: boolean
};
	["PrivateMediaFields_sizes_portrait_width"]: {
	__typename: "PrivateMediaFields_sizes_portrait_width",
	create?: GraphQLTypes["PrivateMediaFields_sizes_portrait_width_Create"] | undefined,
	read?: GraphQLTypes["PrivateMediaFields_sizes_portrait_width_Read"] | undefined,
	update?: GraphQLTypes["PrivateMediaFields_sizes_portrait_width_Update"] | undefined,
	delete?: GraphQLTypes["PrivateMediaFields_sizes_portrait_width_Delete"] | undefined
};
	["PrivateMediaFields_sizes_portrait_width_Create"]: {
	__typename: "PrivateMediaFields_sizes_portrait_width_Create",
	permission: boolean
};
	["PrivateMediaFields_sizes_portrait_width_Read"]: {
	__typename: "PrivateMediaFields_sizes_portrait_width_Read",
	permission: boolean
};
	["PrivateMediaFields_sizes_portrait_width_Update"]: {
	__typename: "PrivateMediaFields_sizes_portrait_width_Update",
	permission: boolean
};
	["PrivateMediaFields_sizes_portrait_width_Delete"]: {
	__typename: "PrivateMediaFields_sizes_portrait_width_Delete",
	permission: boolean
};
	["PrivateMediaFields_sizes_portrait_height"]: {
	__typename: "PrivateMediaFields_sizes_portrait_height",
	create?: GraphQLTypes["PrivateMediaFields_sizes_portrait_height_Create"] | undefined,
	read?: GraphQLTypes["PrivateMediaFields_sizes_portrait_height_Read"] | undefined,
	update?: GraphQLTypes["PrivateMediaFields_sizes_portrait_height_Update"] | undefined,
	delete?: GraphQLTypes["PrivateMediaFields_sizes_portrait_height_Delete"] | undefined
};
	["PrivateMediaFields_sizes_portrait_height_Create"]: {
	__typename: "PrivateMediaFields_sizes_portrait_height_Create",
	permission: boolean
};
	["PrivateMediaFields_sizes_portrait_height_Read"]: {
	__typename: "PrivateMediaFields_sizes_portrait_height_Read",
	permission: boolean
};
	["PrivateMediaFields_sizes_portrait_height_Update"]: {
	__typename: "PrivateMediaFields_sizes_portrait_height_Update",
	permission: boolean
};
	["PrivateMediaFields_sizes_portrait_height_Delete"]: {
	__typename: "PrivateMediaFields_sizes_portrait_height_Delete",
	permission: boolean
};
	["PrivateMediaFields_sizes_portrait_mimeType"]: {
	__typename: "PrivateMediaFields_sizes_portrait_mimeType",
	create?: GraphQLTypes["PrivateMediaFields_sizes_portrait_mimeType_Create"] | undefined,
	read?: GraphQLTypes["PrivateMediaFields_sizes_portrait_mimeType_Read"] | undefined,
	update?: GraphQLTypes["PrivateMediaFields_sizes_portrait_mimeType_Update"] | undefined,
	delete?: GraphQLTypes["PrivateMediaFields_sizes_portrait_mimeType_Delete"] | undefined
};
	["PrivateMediaFields_sizes_portrait_mimeType_Create"]: {
	__typename: "PrivateMediaFields_sizes_portrait_mimeType_Create",
	permission: boolean
};
	["PrivateMediaFields_sizes_portrait_mimeType_Read"]: {
	__typename: "PrivateMediaFields_sizes_portrait_mimeType_Read",
	permission: boolean
};
	["PrivateMediaFields_sizes_portrait_mimeType_Update"]: {
	__typename: "PrivateMediaFields_sizes_portrait_mimeType_Update",
	permission: boolean
};
	["PrivateMediaFields_sizes_portrait_mimeType_Delete"]: {
	__typename: "PrivateMediaFields_sizes_portrait_mimeType_Delete",
	permission: boolean
};
	["PrivateMediaFields_sizes_portrait_filesize"]: {
	__typename: "PrivateMediaFields_sizes_portrait_filesize",
	create?: GraphQLTypes["PrivateMediaFields_sizes_portrait_filesize_Create"] | undefined,
	read?: GraphQLTypes["PrivateMediaFields_sizes_portrait_filesize_Read"] | undefined,
	update?: GraphQLTypes["PrivateMediaFields_sizes_portrait_filesize_Update"] | undefined,
	delete?: GraphQLTypes["PrivateMediaFields_sizes_portrait_filesize_Delete"] | undefined
};
	["PrivateMediaFields_sizes_portrait_filesize_Create"]: {
	__typename: "PrivateMediaFields_sizes_portrait_filesize_Create",
	permission: boolean
};
	["PrivateMediaFields_sizes_portrait_filesize_Read"]: {
	__typename: "PrivateMediaFields_sizes_portrait_filesize_Read",
	permission: boolean
};
	["PrivateMediaFields_sizes_portrait_filesize_Update"]: {
	__typename: "PrivateMediaFields_sizes_portrait_filesize_Update",
	permission: boolean
};
	["PrivateMediaFields_sizes_portrait_filesize_Delete"]: {
	__typename: "PrivateMediaFields_sizes_portrait_filesize_Delete",
	permission: boolean
};
	["PrivateMediaFields_sizes_portrait_filename"]: {
	__typename: "PrivateMediaFields_sizes_portrait_filename",
	create?: GraphQLTypes["PrivateMediaFields_sizes_portrait_filename_Create"] | undefined,
	read?: GraphQLTypes["PrivateMediaFields_sizes_portrait_filename_Read"] | undefined,
	update?: GraphQLTypes["PrivateMediaFields_sizes_portrait_filename_Update"] | undefined,
	delete?: GraphQLTypes["PrivateMediaFields_sizes_portrait_filename_Delete"] | undefined
};
	["PrivateMediaFields_sizes_portrait_filename_Create"]: {
	__typename: "PrivateMediaFields_sizes_portrait_filename_Create",
	permission: boolean
};
	["PrivateMediaFields_sizes_portrait_filename_Read"]: {
	__typename: "PrivateMediaFields_sizes_portrait_filename_Read",
	permission: boolean
};
	["PrivateMediaFields_sizes_portrait_filename_Update"]: {
	__typename: "PrivateMediaFields_sizes_portrait_filename_Update",
	permission: boolean
};
	["PrivateMediaFields_sizes_portrait_filename_Delete"]: {
	__typename: "PrivateMediaFields_sizes_portrait_filename_Delete",
	permission: boolean
};
	["PrivateMediaFields_sizes_hero"]: {
	__typename: "PrivateMediaFields_sizes_hero",
	create?: GraphQLTypes["PrivateMediaFields_sizes_hero_Create"] | undefined,
	read?: GraphQLTypes["PrivateMediaFields_sizes_hero_Read"] | undefined,
	update?: GraphQLTypes["PrivateMediaFields_sizes_hero_Update"] | undefined,
	delete?: GraphQLTypes["PrivateMediaFields_sizes_hero_Delete"] | undefined,
	fields?: GraphQLTypes["PrivateMediaFields_sizes_hero_Fields"] | undefined
};
	["PrivateMediaFields_sizes_hero_Create"]: {
	__typename: "PrivateMediaFields_sizes_hero_Create",
	permission: boolean
};
	["PrivateMediaFields_sizes_hero_Read"]: {
	__typename: "PrivateMediaFields_sizes_hero_Read",
	permission: boolean
};
	["PrivateMediaFields_sizes_hero_Update"]: {
	__typename: "PrivateMediaFields_sizes_hero_Update",
	permission: boolean
};
	["PrivateMediaFields_sizes_hero_Delete"]: {
	__typename: "PrivateMediaFields_sizes_hero_Delete",
	permission: boolean
};
	["PrivateMediaFields_sizes_hero_Fields"]: {
	__typename: "PrivateMediaFields_sizes_hero_Fields",
	url?: GraphQLTypes["PrivateMediaFields_sizes_hero_url"] | undefined,
	width?: GraphQLTypes["PrivateMediaFields_sizes_hero_width"] | undefined,
	height?: GraphQLTypes["PrivateMediaFields_sizes_hero_height"] | undefined,
	mimeType?: GraphQLTypes["PrivateMediaFields_sizes_hero_mimeType"] | undefined,
	filesize?: GraphQLTypes["PrivateMediaFields_sizes_hero_filesize"] | undefined,
	filename?: GraphQLTypes["PrivateMediaFields_sizes_hero_filename"] | undefined
};
	["PrivateMediaFields_sizes_hero_url"]: {
	__typename: "PrivateMediaFields_sizes_hero_url",
	create?: GraphQLTypes["PrivateMediaFields_sizes_hero_url_Create"] | undefined,
	read?: GraphQLTypes["PrivateMediaFields_sizes_hero_url_Read"] | undefined,
	update?: GraphQLTypes["PrivateMediaFields_sizes_hero_url_Update"] | undefined,
	delete?: GraphQLTypes["PrivateMediaFields_sizes_hero_url_Delete"] | undefined
};
	["PrivateMediaFields_sizes_hero_url_Create"]: {
	__typename: "PrivateMediaFields_sizes_hero_url_Create",
	permission: boolean
};
	["PrivateMediaFields_sizes_hero_url_Read"]: {
	__typename: "PrivateMediaFields_sizes_hero_url_Read",
	permission: boolean
};
	["PrivateMediaFields_sizes_hero_url_Update"]: {
	__typename: "PrivateMediaFields_sizes_hero_url_Update",
	permission: boolean
};
	["PrivateMediaFields_sizes_hero_url_Delete"]: {
	__typename: "PrivateMediaFields_sizes_hero_url_Delete",
	permission: boolean
};
	["PrivateMediaFields_sizes_hero_width"]: {
	__typename: "PrivateMediaFields_sizes_hero_width",
	create?: GraphQLTypes["PrivateMediaFields_sizes_hero_width_Create"] | undefined,
	read?: GraphQLTypes["PrivateMediaFields_sizes_hero_width_Read"] | undefined,
	update?: GraphQLTypes["PrivateMediaFields_sizes_hero_width_Update"] | undefined,
	delete?: GraphQLTypes["PrivateMediaFields_sizes_hero_width_Delete"] | undefined
};
	["PrivateMediaFields_sizes_hero_width_Create"]: {
	__typename: "PrivateMediaFields_sizes_hero_width_Create",
	permission: boolean
};
	["PrivateMediaFields_sizes_hero_width_Read"]: {
	__typename: "PrivateMediaFields_sizes_hero_width_Read",
	permission: boolean
};
	["PrivateMediaFields_sizes_hero_width_Update"]: {
	__typename: "PrivateMediaFields_sizes_hero_width_Update",
	permission: boolean
};
	["PrivateMediaFields_sizes_hero_width_Delete"]: {
	__typename: "PrivateMediaFields_sizes_hero_width_Delete",
	permission: boolean
};
	["PrivateMediaFields_sizes_hero_height"]: {
	__typename: "PrivateMediaFields_sizes_hero_height",
	create?: GraphQLTypes["PrivateMediaFields_sizes_hero_height_Create"] | undefined,
	read?: GraphQLTypes["PrivateMediaFields_sizes_hero_height_Read"] | undefined,
	update?: GraphQLTypes["PrivateMediaFields_sizes_hero_height_Update"] | undefined,
	delete?: GraphQLTypes["PrivateMediaFields_sizes_hero_height_Delete"] | undefined
};
	["PrivateMediaFields_sizes_hero_height_Create"]: {
	__typename: "PrivateMediaFields_sizes_hero_height_Create",
	permission: boolean
};
	["PrivateMediaFields_sizes_hero_height_Read"]: {
	__typename: "PrivateMediaFields_sizes_hero_height_Read",
	permission: boolean
};
	["PrivateMediaFields_sizes_hero_height_Update"]: {
	__typename: "PrivateMediaFields_sizes_hero_height_Update",
	permission: boolean
};
	["PrivateMediaFields_sizes_hero_height_Delete"]: {
	__typename: "PrivateMediaFields_sizes_hero_height_Delete",
	permission: boolean
};
	["PrivateMediaFields_sizes_hero_mimeType"]: {
	__typename: "PrivateMediaFields_sizes_hero_mimeType",
	create?: GraphQLTypes["PrivateMediaFields_sizes_hero_mimeType_Create"] | undefined,
	read?: GraphQLTypes["PrivateMediaFields_sizes_hero_mimeType_Read"] | undefined,
	update?: GraphQLTypes["PrivateMediaFields_sizes_hero_mimeType_Update"] | undefined,
	delete?: GraphQLTypes["PrivateMediaFields_sizes_hero_mimeType_Delete"] | undefined
};
	["PrivateMediaFields_sizes_hero_mimeType_Create"]: {
	__typename: "PrivateMediaFields_sizes_hero_mimeType_Create",
	permission: boolean
};
	["PrivateMediaFields_sizes_hero_mimeType_Read"]: {
	__typename: "PrivateMediaFields_sizes_hero_mimeType_Read",
	permission: boolean
};
	["PrivateMediaFields_sizes_hero_mimeType_Update"]: {
	__typename: "PrivateMediaFields_sizes_hero_mimeType_Update",
	permission: boolean
};
	["PrivateMediaFields_sizes_hero_mimeType_Delete"]: {
	__typename: "PrivateMediaFields_sizes_hero_mimeType_Delete",
	permission: boolean
};
	["PrivateMediaFields_sizes_hero_filesize"]: {
	__typename: "PrivateMediaFields_sizes_hero_filesize",
	create?: GraphQLTypes["PrivateMediaFields_sizes_hero_filesize_Create"] | undefined,
	read?: GraphQLTypes["PrivateMediaFields_sizes_hero_filesize_Read"] | undefined,
	update?: GraphQLTypes["PrivateMediaFields_sizes_hero_filesize_Update"] | undefined,
	delete?: GraphQLTypes["PrivateMediaFields_sizes_hero_filesize_Delete"] | undefined
};
	["PrivateMediaFields_sizes_hero_filesize_Create"]: {
	__typename: "PrivateMediaFields_sizes_hero_filesize_Create",
	permission: boolean
};
	["PrivateMediaFields_sizes_hero_filesize_Read"]: {
	__typename: "PrivateMediaFields_sizes_hero_filesize_Read",
	permission: boolean
};
	["PrivateMediaFields_sizes_hero_filesize_Update"]: {
	__typename: "PrivateMediaFields_sizes_hero_filesize_Update",
	permission: boolean
};
	["PrivateMediaFields_sizes_hero_filesize_Delete"]: {
	__typename: "PrivateMediaFields_sizes_hero_filesize_Delete",
	permission: boolean
};
	["PrivateMediaFields_sizes_hero_filename"]: {
	__typename: "PrivateMediaFields_sizes_hero_filename",
	create?: GraphQLTypes["PrivateMediaFields_sizes_hero_filename_Create"] | undefined,
	read?: GraphQLTypes["PrivateMediaFields_sizes_hero_filename_Read"] | undefined,
	update?: GraphQLTypes["PrivateMediaFields_sizes_hero_filename_Update"] | undefined,
	delete?: GraphQLTypes["PrivateMediaFields_sizes_hero_filename_Delete"] | undefined
};
	["PrivateMediaFields_sizes_hero_filename_Create"]: {
	__typename: "PrivateMediaFields_sizes_hero_filename_Create",
	permission: boolean
};
	["PrivateMediaFields_sizes_hero_filename_Read"]: {
	__typename: "PrivateMediaFields_sizes_hero_filename_Read",
	permission: boolean
};
	["PrivateMediaFields_sizes_hero_filename_Update"]: {
	__typename: "PrivateMediaFields_sizes_hero_filename_Update",
	permission: boolean
};
	["PrivateMediaFields_sizes_hero_filename_Delete"]: {
	__typename: "PrivateMediaFields_sizes_hero_filename_Delete",
	permission: boolean
};
	["PrivateMediaFields_sizes_thumbnail_jpeg"]: {
	__typename: "PrivateMediaFields_sizes_thumbnail_jpeg",
	create?: GraphQLTypes["PrivateMediaFields_sizes_thumbnail_jpeg_Create"] | undefined,
	read?: GraphQLTypes["PrivateMediaFields_sizes_thumbnail_jpeg_Read"] | undefined,
	update?: GraphQLTypes["PrivateMediaFields_sizes_thumbnail_jpeg_Update"] | undefined,
	delete?: GraphQLTypes["PrivateMediaFields_sizes_thumbnail_jpeg_Delete"] | undefined,
	fields?: GraphQLTypes["PrivateMediaFields_sizes_thumbnail_jpeg_Fields"] | undefined
};
	["PrivateMediaFields_sizes_thumbnail_jpeg_Create"]: {
	__typename: "PrivateMediaFields_sizes_thumbnail_jpeg_Create",
	permission: boolean
};
	["PrivateMediaFields_sizes_thumbnail_jpeg_Read"]: {
	__typename: "PrivateMediaFields_sizes_thumbnail_jpeg_Read",
	permission: boolean
};
	["PrivateMediaFields_sizes_thumbnail_jpeg_Update"]: {
	__typename: "PrivateMediaFields_sizes_thumbnail_jpeg_Update",
	permission: boolean
};
	["PrivateMediaFields_sizes_thumbnail_jpeg_Delete"]: {
	__typename: "PrivateMediaFields_sizes_thumbnail_jpeg_Delete",
	permission: boolean
};
	["PrivateMediaFields_sizes_thumbnail_jpeg_Fields"]: {
	__typename: "PrivateMediaFields_sizes_thumbnail_jpeg_Fields",
	url?: GraphQLTypes["PrivateMediaFields_sizes_thumbnail_jpeg_url"] | undefined,
	width?: GraphQLTypes["PrivateMediaFields_sizes_thumbnail_jpeg_width"] | undefined,
	height?: GraphQLTypes["PrivateMediaFields_sizes_thumbnail_jpeg_height"] | undefined,
	mimeType?: GraphQLTypes["PrivateMediaFields_sizes_thumbnail_jpeg_mimeType"] | undefined,
	filesize?: GraphQLTypes["PrivateMediaFields_sizes_thumbnail_jpeg_filesize"] | undefined,
	filename?: GraphQLTypes["PrivateMediaFields_sizes_thumbnail_jpeg_filename"] | undefined
};
	["PrivateMediaFields_sizes_thumbnail_jpeg_url"]: {
	__typename: "PrivateMediaFields_sizes_thumbnail_jpeg_url",
	create?: GraphQLTypes["PrivateMediaFields_sizes_thumbnail_jpeg_url_Create"] | undefined,
	read?: GraphQLTypes["PrivateMediaFields_sizes_thumbnail_jpeg_url_Read"] | undefined,
	update?: GraphQLTypes["PrivateMediaFields_sizes_thumbnail_jpeg_url_Update"] | undefined,
	delete?: GraphQLTypes["PrivateMediaFields_sizes_thumbnail_jpeg_url_Delete"] | undefined
};
	["PrivateMediaFields_sizes_thumbnail_jpeg_url_Create"]: {
	__typename: "PrivateMediaFields_sizes_thumbnail_jpeg_url_Create",
	permission: boolean
};
	["PrivateMediaFields_sizes_thumbnail_jpeg_url_Read"]: {
	__typename: "PrivateMediaFields_sizes_thumbnail_jpeg_url_Read",
	permission: boolean
};
	["PrivateMediaFields_sizes_thumbnail_jpeg_url_Update"]: {
	__typename: "PrivateMediaFields_sizes_thumbnail_jpeg_url_Update",
	permission: boolean
};
	["PrivateMediaFields_sizes_thumbnail_jpeg_url_Delete"]: {
	__typename: "PrivateMediaFields_sizes_thumbnail_jpeg_url_Delete",
	permission: boolean
};
	["PrivateMediaFields_sizes_thumbnail_jpeg_width"]: {
	__typename: "PrivateMediaFields_sizes_thumbnail_jpeg_width",
	create?: GraphQLTypes["PrivateMediaFields_sizes_thumbnail_jpeg_width_Create"] | undefined,
	read?: GraphQLTypes["PrivateMediaFields_sizes_thumbnail_jpeg_width_Read"] | undefined,
	update?: GraphQLTypes["PrivateMediaFields_sizes_thumbnail_jpeg_width_Update"] | undefined,
	delete?: GraphQLTypes["PrivateMediaFields_sizes_thumbnail_jpeg_width_Delete"] | undefined
};
	["PrivateMediaFields_sizes_thumbnail_jpeg_width_Create"]: {
	__typename: "PrivateMediaFields_sizes_thumbnail_jpeg_width_Create",
	permission: boolean
};
	["PrivateMediaFields_sizes_thumbnail_jpeg_width_Read"]: {
	__typename: "PrivateMediaFields_sizes_thumbnail_jpeg_width_Read",
	permission: boolean
};
	["PrivateMediaFields_sizes_thumbnail_jpeg_width_Update"]: {
	__typename: "PrivateMediaFields_sizes_thumbnail_jpeg_width_Update",
	permission: boolean
};
	["PrivateMediaFields_sizes_thumbnail_jpeg_width_Delete"]: {
	__typename: "PrivateMediaFields_sizes_thumbnail_jpeg_width_Delete",
	permission: boolean
};
	["PrivateMediaFields_sizes_thumbnail_jpeg_height"]: {
	__typename: "PrivateMediaFields_sizes_thumbnail_jpeg_height",
	create?: GraphQLTypes["PrivateMediaFields_sizes_thumbnail_jpeg_height_Create"] | undefined,
	read?: GraphQLTypes["PrivateMediaFields_sizes_thumbnail_jpeg_height_Read"] | undefined,
	update?: GraphQLTypes["PrivateMediaFields_sizes_thumbnail_jpeg_height_Update"] | undefined,
	delete?: GraphQLTypes["PrivateMediaFields_sizes_thumbnail_jpeg_height_Delete"] | undefined
};
	["PrivateMediaFields_sizes_thumbnail_jpeg_height_Create"]: {
	__typename: "PrivateMediaFields_sizes_thumbnail_jpeg_height_Create",
	permission: boolean
};
	["PrivateMediaFields_sizes_thumbnail_jpeg_height_Read"]: {
	__typename: "PrivateMediaFields_sizes_thumbnail_jpeg_height_Read",
	permission: boolean
};
	["PrivateMediaFields_sizes_thumbnail_jpeg_height_Update"]: {
	__typename: "PrivateMediaFields_sizes_thumbnail_jpeg_height_Update",
	permission: boolean
};
	["PrivateMediaFields_sizes_thumbnail_jpeg_height_Delete"]: {
	__typename: "PrivateMediaFields_sizes_thumbnail_jpeg_height_Delete",
	permission: boolean
};
	["PrivateMediaFields_sizes_thumbnail_jpeg_mimeType"]: {
	__typename: "PrivateMediaFields_sizes_thumbnail_jpeg_mimeType",
	create?: GraphQLTypes["PrivateMediaFields_sizes_thumbnail_jpeg_mimeType_Create"] | undefined,
	read?: GraphQLTypes["PrivateMediaFields_sizes_thumbnail_jpeg_mimeType_Read"] | undefined,
	update?: GraphQLTypes["PrivateMediaFields_sizes_thumbnail_jpeg_mimeType_Update"] | undefined,
	delete?: GraphQLTypes["PrivateMediaFields_sizes_thumbnail_jpeg_mimeType_Delete"] | undefined
};
	["PrivateMediaFields_sizes_thumbnail_jpeg_mimeType_Create"]: {
	__typename: "PrivateMediaFields_sizes_thumbnail_jpeg_mimeType_Create",
	permission: boolean
};
	["PrivateMediaFields_sizes_thumbnail_jpeg_mimeType_Read"]: {
	__typename: "PrivateMediaFields_sizes_thumbnail_jpeg_mimeType_Read",
	permission: boolean
};
	["PrivateMediaFields_sizes_thumbnail_jpeg_mimeType_Update"]: {
	__typename: "PrivateMediaFields_sizes_thumbnail_jpeg_mimeType_Update",
	permission: boolean
};
	["PrivateMediaFields_sizes_thumbnail_jpeg_mimeType_Delete"]: {
	__typename: "PrivateMediaFields_sizes_thumbnail_jpeg_mimeType_Delete",
	permission: boolean
};
	["PrivateMediaFields_sizes_thumbnail_jpeg_filesize"]: {
	__typename: "PrivateMediaFields_sizes_thumbnail_jpeg_filesize",
	create?: GraphQLTypes["PrivateMediaFields_sizes_thumbnail_jpeg_filesize_Create"] | undefined,
	read?: GraphQLTypes["PrivateMediaFields_sizes_thumbnail_jpeg_filesize_Read"] | undefined,
	update?: GraphQLTypes["PrivateMediaFields_sizes_thumbnail_jpeg_filesize_Update"] | undefined,
	delete?: GraphQLTypes["PrivateMediaFields_sizes_thumbnail_jpeg_filesize_Delete"] | undefined
};
	["PrivateMediaFields_sizes_thumbnail_jpeg_filesize_Create"]: {
	__typename: "PrivateMediaFields_sizes_thumbnail_jpeg_filesize_Create",
	permission: boolean
};
	["PrivateMediaFields_sizes_thumbnail_jpeg_filesize_Read"]: {
	__typename: "PrivateMediaFields_sizes_thumbnail_jpeg_filesize_Read",
	permission: boolean
};
	["PrivateMediaFields_sizes_thumbnail_jpeg_filesize_Update"]: {
	__typename: "PrivateMediaFields_sizes_thumbnail_jpeg_filesize_Update",
	permission: boolean
};
	["PrivateMediaFields_sizes_thumbnail_jpeg_filesize_Delete"]: {
	__typename: "PrivateMediaFields_sizes_thumbnail_jpeg_filesize_Delete",
	permission: boolean
};
	["PrivateMediaFields_sizes_thumbnail_jpeg_filename"]: {
	__typename: "PrivateMediaFields_sizes_thumbnail_jpeg_filename",
	create?: GraphQLTypes["PrivateMediaFields_sizes_thumbnail_jpeg_filename_Create"] | undefined,
	read?: GraphQLTypes["PrivateMediaFields_sizes_thumbnail_jpeg_filename_Read"] | undefined,
	update?: GraphQLTypes["PrivateMediaFields_sizes_thumbnail_jpeg_filename_Update"] | undefined,
	delete?: GraphQLTypes["PrivateMediaFields_sizes_thumbnail_jpeg_filename_Delete"] | undefined
};
	["PrivateMediaFields_sizes_thumbnail_jpeg_filename_Create"]: {
	__typename: "PrivateMediaFields_sizes_thumbnail_jpeg_filename_Create",
	permission: boolean
};
	["PrivateMediaFields_sizes_thumbnail_jpeg_filename_Read"]: {
	__typename: "PrivateMediaFields_sizes_thumbnail_jpeg_filename_Read",
	permission: boolean
};
	["PrivateMediaFields_sizes_thumbnail_jpeg_filename_Update"]: {
	__typename: "PrivateMediaFields_sizes_thumbnail_jpeg_filename_Update",
	permission: boolean
};
	["PrivateMediaFields_sizes_thumbnail_jpeg_filename_Delete"]: {
	__typename: "PrivateMediaFields_sizes_thumbnail_jpeg_filename_Delete",
	permission: boolean
};
	["PrivateMediaFields_sizes_portrait_jpeg"]: {
	__typename: "PrivateMediaFields_sizes_portrait_jpeg",
	create?: GraphQLTypes["PrivateMediaFields_sizes_portrait_jpeg_Create"] | undefined,
	read?: GraphQLTypes["PrivateMediaFields_sizes_portrait_jpeg_Read"] | undefined,
	update?: GraphQLTypes["PrivateMediaFields_sizes_portrait_jpeg_Update"] | undefined,
	delete?: GraphQLTypes["PrivateMediaFields_sizes_portrait_jpeg_Delete"] | undefined,
	fields?: GraphQLTypes["PrivateMediaFields_sizes_portrait_jpeg_Fields"] | undefined
};
	["PrivateMediaFields_sizes_portrait_jpeg_Create"]: {
	__typename: "PrivateMediaFields_sizes_portrait_jpeg_Create",
	permission: boolean
};
	["PrivateMediaFields_sizes_portrait_jpeg_Read"]: {
	__typename: "PrivateMediaFields_sizes_portrait_jpeg_Read",
	permission: boolean
};
	["PrivateMediaFields_sizes_portrait_jpeg_Update"]: {
	__typename: "PrivateMediaFields_sizes_portrait_jpeg_Update",
	permission: boolean
};
	["PrivateMediaFields_sizes_portrait_jpeg_Delete"]: {
	__typename: "PrivateMediaFields_sizes_portrait_jpeg_Delete",
	permission: boolean
};
	["PrivateMediaFields_sizes_portrait_jpeg_Fields"]: {
	__typename: "PrivateMediaFields_sizes_portrait_jpeg_Fields",
	url?: GraphQLTypes["PrivateMediaFields_sizes_portrait_jpeg_url"] | undefined,
	width?: GraphQLTypes["PrivateMediaFields_sizes_portrait_jpeg_width"] | undefined,
	height?: GraphQLTypes["PrivateMediaFields_sizes_portrait_jpeg_height"] | undefined,
	mimeType?: GraphQLTypes["PrivateMediaFields_sizes_portrait_jpeg_mimeType"] | undefined,
	filesize?: GraphQLTypes["PrivateMediaFields_sizes_portrait_jpeg_filesize"] | undefined,
	filename?: GraphQLTypes["PrivateMediaFields_sizes_portrait_jpeg_filename"] | undefined
};
	["PrivateMediaFields_sizes_portrait_jpeg_url"]: {
	__typename: "PrivateMediaFields_sizes_portrait_jpeg_url",
	create?: GraphQLTypes["PrivateMediaFields_sizes_portrait_jpeg_url_Create"] | undefined,
	read?: GraphQLTypes["PrivateMediaFields_sizes_portrait_jpeg_url_Read"] | undefined,
	update?: GraphQLTypes["PrivateMediaFields_sizes_portrait_jpeg_url_Update"] | undefined,
	delete?: GraphQLTypes["PrivateMediaFields_sizes_portrait_jpeg_url_Delete"] | undefined
};
	["PrivateMediaFields_sizes_portrait_jpeg_url_Create"]: {
	__typename: "PrivateMediaFields_sizes_portrait_jpeg_url_Create",
	permission: boolean
};
	["PrivateMediaFields_sizes_portrait_jpeg_url_Read"]: {
	__typename: "PrivateMediaFields_sizes_portrait_jpeg_url_Read",
	permission: boolean
};
	["PrivateMediaFields_sizes_portrait_jpeg_url_Update"]: {
	__typename: "PrivateMediaFields_sizes_portrait_jpeg_url_Update",
	permission: boolean
};
	["PrivateMediaFields_sizes_portrait_jpeg_url_Delete"]: {
	__typename: "PrivateMediaFields_sizes_portrait_jpeg_url_Delete",
	permission: boolean
};
	["PrivateMediaFields_sizes_portrait_jpeg_width"]: {
	__typename: "PrivateMediaFields_sizes_portrait_jpeg_width",
	create?: GraphQLTypes["PrivateMediaFields_sizes_portrait_jpeg_width_Create"] | undefined,
	read?: GraphQLTypes["PrivateMediaFields_sizes_portrait_jpeg_width_Read"] | undefined,
	update?: GraphQLTypes["PrivateMediaFields_sizes_portrait_jpeg_width_Update"] | undefined,
	delete?: GraphQLTypes["PrivateMediaFields_sizes_portrait_jpeg_width_Delete"] | undefined
};
	["PrivateMediaFields_sizes_portrait_jpeg_width_Create"]: {
	__typename: "PrivateMediaFields_sizes_portrait_jpeg_width_Create",
	permission: boolean
};
	["PrivateMediaFields_sizes_portrait_jpeg_width_Read"]: {
	__typename: "PrivateMediaFields_sizes_portrait_jpeg_width_Read",
	permission: boolean
};
	["PrivateMediaFields_sizes_portrait_jpeg_width_Update"]: {
	__typename: "PrivateMediaFields_sizes_portrait_jpeg_width_Update",
	permission: boolean
};
	["PrivateMediaFields_sizes_portrait_jpeg_width_Delete"]: {
	__typename: "PrivateMediaFields_sizes_portrait_jpeg_width_Delete",
	permission: boolean
};
	["PrivateMediaFields_sizes_portrait_jpeg_height"]: {
	__typename: "PrivateMediaFields_sizes_portrait_jpeg_height",
	create?: GraphQLTypes["PrivateMediaFields_sizes_portrait_jpeg_height_Create"] | undefined,
	read?: GraphQLTypes["PrivateMediaFields_sizes_portrait_jpeg_height_Read"] | undefined,
	update?: GraphQLTypes["PrivateMediaFields_sizes_portrait_jpeg_height_Update"] | undefined,
	delete?: GraphQLTypes["PrivateMediaFields_sizes_portrait_jpeg_height_Delete"] | undefined
};
	["PrivateMediaFields_sizes_portrait_jpeg_height_Create"]: {
	__typename: "PrivateMediaFields_sizes_portrait_jpeg_height_Create",
	permission: boolean
};
	["PrivateMediaFields_sizes_portrait_jpeg_height_Read"]: {
	__typename: "PrivateMediaFields_sizes_portrait_jpeg_height_Read",
	permission: boolean
};
	["PrivateMediaFields_sizes_portrait_jpeg_height_Update"]: {
	__typename: "PrivateMediaFields_sizes_portrait_jpeg_height_Update",
	permission: boolean
};
	["PrivateMediaFields_sizes_portrait_jpeg_height_Delete"]: {
	__typename: "PrivateMediaFields_sizes_portrait_jpeg_height_Delete",
	permission: boolean
};
	["PrivateMediaFields_sizes_portrait_jpeg_mimeType"]: {
	__typename: "PrivateMediaFields_sizes_portrait_jpeg_mimeType",
	create?: GraphQLTypes["PrivateMediaFields_sizes_portrait_jpeg_mimeType_Create"] | undefined,
	read?: GraphQLTypes["PrivateMediaFields_sizes_portrait_jpeg_mimeType_Read"] | undefined,
	update?: GraphQLTypes["PrivateMediaFields_sizes_portrait_jpeg_mimeType_Update"] | undefined,
	delete?: GraphQLTypes["PrivateMediaFields_sizes_portrait_jpeg_mimeType_Delete"] | undefined
};
	["PrivateMediaFields_sizes_portrait_jpeg_mimeType_Create"]: {
	__typename: "PrivateMediaFields_sizes_portrait_jpeg_mimeType_Create",
	permission: boolean
};
	["PrivateMediaFields_sizes_portrait_jpeg_mimeType_Read"]: {
	__typename: "PrivateMediaFields_sizes_portrait_jpeg_mimeType_Read",
	permission: boolean
};
	["PrivateMediaFields_sizes_portrait_jpeg_mimeType_Update"]: {
	__typename: "PrivateMediaFields_sizes_portrait_jpeg_mimeType_Update",
	permission: boolean
};
	["PrivateMediaFields_sizes_portrait_jpeg_mimeType_Delete"]: {
	__typename: "PrivateMediaFields_sizes_portrait_jpeg_mimeType_Delete",
	permission: boolean
};
	["PrivateMediaFields_sizes_portrait_jpeg_filesize"]: {
	__typename: "PrivateMediaFields_sizes_portrait_jpeg_filesize",
	create?: GraphQLTypes["PrivateMediaFields_sizes_portrait_jpeg_filesize_Create"] | undefined,
	read?: GraphQLTypes["PrivateMediaFields_sizes_portrait_jpeg_filesize_Read"] | undefined,
	update?: GraphQLTypes["PrivateMediaFields_sizes_portrait_jpeg_filesize_Update"] | undefined,
	delete?: GraphQLTypes["PrivateMediaFields_sizes_portrait_jpeg_filesize_Delete"] | undefined
};
	["PrivateMediaFields_sizes_portrait_jpeg_filesize_Create"]: {
	__typename: "PrivateMediaFields_sizes_portrait_jpeg_filesize_Create",
	permission: boolean
};
	["PrivateMediaFields_sizes_portrait_jpeg_filesize_Read"]: {
	__typename: "PrivateMediaFields_sizes_portrait_jpeg_filesize_Read",
	permission: boolean
};
	["PrivateMediaFields_sizes_portrait_jpeg_filesize_Update"]: {
	__typename: "PrivateMediaFields_sizes_portrait_jpeg_filesize_Update",
	permission: boolean
};
	["PrivateMediaFields_sizes_portrait_jpeg_filesize_Delete"]: {
	__typename: "PrivateMediaFields_sizes_portrait_jpeg_filesize_Delete",
	permission: boolean
};
	["PrivateMediaFields_sizes_portrait_jpeg_filename"]: {
	__typename: "PrivateMediaFields_sizes_portrait_jpeg_filename",
	create?: GraphQLTypes["PrivateMediaFields_sizes_portrait_jpeg_filename_Create"] | undefined,
	read?: GraphQLTypes["PrivateMediaFields_sizes_portrait_jpeg_filename_Read"] | undefined,
	update?: GraphQLTypes["PrivateMediaFields_sizes_portrait_jpeg_filename_Update"] | undefined,
	delete?: GraphQLTypes["PrivateMediaFields_sizes_portrait_jpeg_filename_Delete"] | undefined
};
	["PrivateMediaFields_sizes_portrait_jpeg_filename_Create"]: {
	__typename: "PrivateMediaFields_sizes_portrait_jpeg_filename_Create",
	permission: boolean
};
	["PrivateMediaFields_sizes_portrait_jpeg_filename_Read"]: {
	__typename: "PrivateMediaFields_sizes_portrait_jpeg_filename_Read",
	permission: boolean
};
	["PrivateMediaFields_sizes_portrait_jpeg_filename_Update"]: {
	__typename: "PrivateMediaFields_sizes_portrait_jpeg_filename_Update",
	permission: boolean
};
	["PrivateMediaFields_sizes_portrait_jpeg_filename_Delete"]: {
	__typename: "PrivateMediaFields_sizes_portrait_jpeg_filename_Delete",
	permission: boolean
};
	["PrivateMediaFields_sizes_hero_jpeg"]: {
	__typename: "PrivateMediaFields_sizes_hero_jpeg",
	create?: GraphQLTypes["PrivateMediaFields_sizes_hero_jpeg_Create"] | undefined,
	read?: GraphQLTypes["PrivateMediaFields_sizes_hero_jpeg_Read"] | undefined,
	update?: GraphQLTypes["PrivateMediaFields_sizes_hero_jpeg_Update"] | undefined,
	delete?: GraphQLTypes["PrivateMediaFields_sizes_hero_jpeg_Delete"] | undefined,
	fields?: GraphQLTypes["PrivateMediaFields_sizes_hero_jpeg_Fields"] | undefined
};
	["PrivateMediaFields_sizes_hero_jpeg_Create"]: {
	__typename: "PrivateMediaFields_sizes_hero_jpeg_Create",
	permission: boolean
};
	["PrivateMediaFields_sizes_hero_jpeg_Read"]: {
	__typename: "PrivateMediaFields_sizes_hero_jpeg_Read",
	permission: boolean
};
	["PrivateMediaFields_sizes_hero_jpeg_Update"]: {
	__typename: "PrivateMediaFields_sizes_hero_jpeg_Update",
	permission: boolean
};
	["PrivateMediaFields_sizes_hero_jpeg_Delete"]: {
	__typename: "PrivateMediaFields_sizes_hero_jpeg_Delete",
	permission: boolean
};
	["PrivateMediaFields_sizes_hero_jpeg_Fields"]: {
	__typename: "PrivateMediaFields_sizes_hero_jpeg_Fields",
	url?: GraphQLTypes["PrivateMediaFields_sizes_hero_jpeg_url"] | undefined,
	width?: GraphQLTypes["PrivateMediaFields_sizes_hero_jpeg_width"] | undefined,
	height?: GraphQLTypes["PrivateMediaFields_sizes_hero_jpeg_height"] | undefined,
	mimeType?: GraphQLTypes["PrivateMediaFields_sizes_hero_jpeg_mimeType"] | undefined,
	filesize?: GraphQLTypes["PrivateMediaFields_sizes_hero_jpeg_filesize"] | undefined,
	filename?: GraphQLTypes["PrivateMediaFields_sizes_hero_jpeg_filename"] | undefined
};
	["PrivateMediaFields_sizes_hero_jpeg_url"]: {
	__typename: "PrivateMediaFields_sizes_hero_jpeg_url",
	create?: GraphQLTypes["PrivateMediaFields_sizes_hero_jpeg_url_Create"] | undefined,
	read?: GraphQLTypes["PrivateMediaFields_sizes_hero_jpeg_url_Read"] | undefined,
	update?: GraphQLTypes["PrivateMediaFields_sizes_hero_jpeg_url_Update"] | undefined,
	delete?: GraphQLTypes["PrivateMediaFields_sizes_hero_jpeg_url_Delete"] | undefined
};
	["PrivateMediaFields_sizes_hero_jpeg_url_Create"]: {
	__typename: "PrivateMediaFields_sizes_hero_jpeg_url_Create",
	permission: boolean
};
	["PrivateMediaFields_sizes_hero_jpeg_url_Read"]: {
	__typename: "PrivateMediaFields_sizes_hero_jpeg_url_Read",
	permission: boolean
};
	["PrivateMediaFields_sizes_hero_jpeg_url_Update"]: {
	__typename: "PrivateMediaFields_sizes_hero_jpeg_url_Update",
	permission: boolean
};
	["PrivateMediaFields_sizes_hero_jpeg_url_Delete"]: {
	__typename: "PrivateMediaFields_sizes_hero_jpeg_url_Delete",
	permission: boolean
};
	["PrivateMediaFields_sizes_hero_jpeg_width"]: {
	__typename: "PrivateMediaFields_sizes_hero_jpeg_width",
	create?: GraphQLTypes["PrivateMediaFields_sizes_hero_jpeg_width_Create"] | undefined,
	read?: GraphQLTypes["PrivateMediaFields_sizes_hero_jpeg_width_Read"] | undefined,
	update?: GraphQLTypes["PrivateMediaFields_sizes_hero_jpeg_width_Update"] | undefined,
	delete?: GraphQLTypes["PrivateMediaFields_sizes_hero_jpeg_width_Delete"] | undefined
};
	["PrivateMediaFields_sizes_hero_jpeg_width_Create"]: {
	__typename: "PrivateMediaFields_sizes_hero_jpeg_width_Create",
	permission: boolean
};
	["PrivateMediaFields_sizes_hero_jpeg_width_Read"]: {
	__typename: "PrivateMediaFields_sizes_hero_jpeg_width_Read",
	permission: boolean
};
	["PrivateMediaFields_sizes_hero_jpeg_width_Update"]: {
	__typename: "PrivateMediaFields_sizes_hero_jpeg_width_Update",
	permission: boolean
};
	["PrivateMediaFields_sizes_hero_jpeg_width_Delete"]: {
	__typename: "PrivateMediaFields_sizes_hero_jpeg_width_Delete",
	permission: boolean
};
	["PrivateMediaFields_sizes_hero_jpeg_height"]: {
	__typename: "PrivateMediaFields_sizes_hero_jpeg_height",
	create?: GraphQLTypes["PrivateMediaFields_sizes_hero_jpeg_height_Create"] | undefined,
	read?: GraphQLTypes["PrivateMediaFields_sizes_hero_jpeg_height_Read"] | undefined,
	update?: GraphQLTypes["PrivateMediaFields_sizes_hero_jpeg_height_Update"] | undefined,
	delete?: GraphQLTypes["PrivateMediaFields_sizes_hero_jpeg_height_Delete"] | undefined
};
	["PrivateMediaFields_sizes_hero_jpeg_height_Create"]: {
	__typename: "PrivateMediaFields_sizes_hero_jpeg_height_Create",
	permission: boolean
};
	["PrivateMediaFields_sizes_hero_jpeg_height_Read"]: {
	__typename: "PrivateMediaFields_sizes_hero_jpeg_height_Read",
	permission: boolean
};
	["PrivateMediaFields_sizes_hero_jpeg_height_Update"]: {
	__typename: "PrivateMediaFields_sizes_hero_jpeg_height_Update",
	permission: boolean
};
	["PrivateMediaFields_sizes_hero_jpeg_height_Delete"]: {
	__typename: "PrivateMediaFields_sizes_hero_jpeg_height_Delete",
	permission: boolean
};
	["PrivateMediaFields_sizes_hero_jpeg_mimeType"]: {
	__typename: "PrivateMediaFields_sizes_hero_jpeg_mimeType",
	create?: GraphQLTypes["PrivateMediaFields_sizes_hero_jpeg_mimeType_Create"] | undefined,
	read?: GraphQLTypes["PrivateMediaFields_sizes_hero_jpeg_mimeType_Read"] | undefined,
	update?: GraphQLTypes["PrivateMediaFields_sizes_hero_jpeg_mimeType_Update"] | undefined,
	delete?: GraphQLTypes["PrivateMediaFields_sizes_hero_jpeg_mimeType_Delete"] | undefined
};
	["PrivateMediaFields_sizes_hero_jpeg_mimeType_Create"]: {
	__typename: "PrivateMediaFields_sizes_hero_jpeg_mimeType_Create",
	permission: boolean
};
	["PrivateMediaFields_sizes_hero_jpeg_mimeType_Read"]: {
	__typename: "PrivateMediaFields_sizes_hero_jpeg_mimeType_Read",
	permission: boolean
};
	["PrivateMediaFields_sizes_hero_jpeg_mimeType_Update"]: {
	__typename: "PrivateMediaFields_sizes_hero_jpeg_mimeType_Update",
	permission: boolean
};
	["PrivateMediaFields_sizes_hero_jpeg_mimeType_Delete"]: {
	__typename: "PrivateMediaFields_sizes_hero_jpeg_mimeType_Delete",
	permission: boolean
};
	["PrivateMediaFields_sizes_hero_jpeg_filesize"]: {
	__typename: "PrivateMediaFields_sizes_hero_jpeg_filesize",
	create?: GraphQLTypes["PrivateMediaFields_sizes_hero_jpeg_filesize_Create"] | undefined,
	read?: GraphQLTypes["PrivateMediaFields_sizes_hero_jpeg_filesize_Read"] | undefined,
	update?: GraphQLTypes["PrivateMediaFields_sizes_hero_jpeg_filesize_Update"] | undefined,
	delete?: GraphQLTypes["PrivateMediaFields_sizes_hero_jpeg_filesize_Delete"] | undefined
};
	["PrivateMediaFields_sizes_hero_jpeg_filesize_Create"]: {
	__typename: "PrivateMediaFields_sizes_hero_jpeg_filesize_Create",
	permission: boolean
};
	["PrivateMediaFields_sizes_hero_jpeg_filesize_Read"]: {
	__typename: "PrivateMediaFields_sizes_hero_jpeg_filesize_Read",
	permission: boolean
};
	["PrivateMediaFields_sizes_hero_jpeg_filesize_Update"]: {
	__typename: "PrivateMediaFields_sizes_hero_jpeg_filesize_Update",
	permission: boolean
};
	["PrivateMediaFields_sizes_hero_jpeg_filesize_Delete"]: {
	__typename: "PrivateMediaFields_sizes_hero_jpeg_filesize_Delete",
	permission: boolean
};
	["PrivateMediaFields_sizes_hero_jpeg_filename"]: {
	__typename: "PrivateMediaFields_sizes_hero_jpeg_filename",
	create?: GraphQLTypes["PrivateMediaFields_sizes_hero_jpeg_filename_Create"] | undefined,
	read?: GraphQLTypes["PrivateMediaFields_sizes_hero_jpeg_filename_Read"] | undefined,
	update?: GraphQLTypes["PrivateMediaFields_sizes_hero_jpeg_filename_Update"] | undefined,
	delete?: GraphQLTypes["PrivateMediaFields_sizes_hero_jpeg_filename_Delete"] | undefined
};
	["PrivateMediaFields_sizes_hero_jpeg_filename_Create"]: {
	__typename: "PrivateMediaFields_sizes_hero_jpeg_filename_Create",
	permission: boolean
};
	["PrivateMediaFields_sizes_hero_jpeg_filename_Read"]: {
	__typename: "PrivateMediaFields_sizes_hero_jpeg_filename_Read",
	permission: boolean
};
	["PrivateMediaFields_sizes_hero_jpeg_filename_Update"]: {
	__typename: "PrivateMediaFields_sizes_hero_jpeg_filename_Update",
	permission: boolean
};
	["PrivateMediaFields_sizes_hero_jpeg_filename_Delete"]: {
	__typename: "PrivateMediaFields_sizes_hero_jpeg_filename_Delete",
	permission: boolean
};
	["PrivateMediaCreateAccess"]: {
	__typename: "PrivateMediaCreateAccess",
	permission: boolean,
	where?: GraphQLTypes["JSONObject"] | undefined
};
	["PrivateMediaReadAccess"]: {
	__typename: "PrivateMediaReadAccess",
	permission: boolean,
	where?: GraphQLTypes["JSONObject"] | undefined
};
	["PrivateMediaUpdateAccess"]: {
	__typename: "PrivateMediaUpdateAccess",
	permission: boolean,
	where?: GraphQLTypes["JSONObject"] | undefined
};
	["PrivateMediaDeleteAccess"]: {
	__typename: "PrivateMediaDeleteAccess",
	permission: boolean,
	where?: GraphQLTypes["JSONObject"] | undefined
};
	["mediaAccess"]: {
	__typename: "mediaAccess",
	fields?: GraphQLTypes["MediaFields"] | undefined,
	create?: GraphQLTypes["MediaCreateAccess"] | undefined,
	read?: GraphQLTypes["MediaReadAccess"] | undefined,
	update?: GraphQLTypes["MediaUpdateAccess"] | undefined,
	delete?: GraphQLTypes["MediaDeleteAccess"] | undefined
};
	["MediaFields"]: {
	__typename: "MediaFields",
	alt?: GraphQLTypes["MediaFields_alt"] | undefined,
	updatedAt?: GraphQLTypes["MediaFields_updatedAt"] | undefined,
	createdAt?: GraphQLTypes["MediaFields_createdAt"] | undefined,
	url?: GraphQLTypes["MediaFields_url"] | undefined,
	filename?: GraphQLTypes["MediaFields_filename"] | undefined,
	mimeType?: GraphQLTypes["MediaFields_mimeType"] | undefined,
	filesize?: GraphQLTypes["MediaFields_filesize"] | undefined,
	width?: GraphQLTypes["MediaFields_width"] | undefined,
	height?: GraphQLTypes["MediaFields_height"] | undefined,
	focalX?: GraphQLTypes["MediaFields_focalX"] | undefined,
	focalY?: GraphQLTypes["MediaFields_focalY"] | undefined,
	sizes?: GraphQLTypes["MediaFields_sizes"] | undefined
};
	["MediaFields_alt"]: {
	__typename: "MediaFields_alt",
	create?: GraphQLTypes["MediaFields_alt_Create"] | undefined,
	read?: GraphQLTypes["MediaFields_alt_Read"] | undefined,
	update?: GraphQLTypes["MediaFields_alt_Update"] | undefined,
	delete?: GraphQLTypes["MediaFields_alt_Delete"] | undefined
};
	["MediaFields_alt_Create"]: {
	__typename: "MediaFields_alt_Create",
	permission: boolean
};
	["MediaFields_alt_Read"]: {
	__typename: "MediaFields_alt_Read",
	permission: boolean
};
	["MediaFields_alt_Update"]: {
	__typename: "MediaFields_alt_Update",
	permission: boolean
};
	["MediaFields_alt_Delete"]: {
	__typename: "MediaFields_alt_Delete",
	permission: boolean
};
	["MediaFields_updatedAt"]: {
	__typename: "MediaFields_updatedAt",
	create?: GraphQLTypes["MediaFields_updatedAt_Create"] | undefined,
	read?: GraphQLTypes["MediaFields_updatedAt_Read"] | undefined,
	update?: GraphQLTypes["MediaFields_updatedAt_Update"] | undefined,
	delete?: GraphQLTypes["MediaFields_updatedAt_Delete"] | undefined
};
	["MediaFields_updatedAt_Create"]: {
	__typename: "MediaFields_updatedAt_Create",
	permission: boolean
};
	["MediaFields_updatedAt_Read"]: {
	__typename: "MediaFields_updatedAt_Read",
	permission: boolean
};
	["MediaFields_updatedAt_Update"]: {
	__typename: "MediaFields_updatedAt_Update",
	permission: boolean
};
	["MediaFields_updatedAt_Delete"]: {
	__typename: "MediaFields_updatedAt_Delete",
	permission: boolean
};
	["MediaFields_createdAt"]: {
	__typename: "MediaFields_createdAt",
	create?: GraphQLTypes["MediaFields_createdAt_Create"] | undefined,
	read?: GraphQLTypes["MediaFields_createdAt_Read"] | undefined,
	update?: GraphQLTypes["MediaFields_createdAt_Update"] | undefined,
	delete?: GraphQLTypes["MediaFields_createdAt_Delete"] | undefined
};
	["MediaFields_createdAt_Create"]: {
	__typename: "MediaFields_createdAt_Create",
	permission: boolean
};
	["MediaFields_createdAt_Read"]: {
	__typename: "MediaFields_createdAt_Read",
	permission: boolean
};
	["MediaFields_createdAt_Update"]: {
	__typename: "MediaFields_createdAt_Update",
	permission: boolean
};
	["MediaFields_createdAt_Delete"]: {
	__typename: "MediaFields_createdAt_Delete",
	permission: boolean
};
	["MediaFields_url"]: {
	__typename: "MediaFields_url",
	create?: GraphQLTypes["MediaFields_url_Create"] | undefined,
	read?: GraphQLTypes["MediaFields_url_Read"] | undefined,
	update?: GraphQLTypes["MediaFields_url_Update"] | undefined,
	delete?: GraphQLTypes["MediaFields_url_Delete"] | undefined
};
	["MediaFields_url_Create"]: {
	__typename: "MediaFields_url_Create",
	permission: boolean
};
	["MediaFields_url_Read"]: {
	__typename: "MediaFields_url_Read",
	permission: boolean
};
	["MediaFields_url_Update"]: {
	__typename: "MediaFields_url_Update",
	permission: boolean
};
	["MediaFields_url_Delete"]: {
	__typename: "MediaFields_url_Delete",
	permission: boolean
};
	["MediaFields_filename"]: {
	__typename: "MediaFields_filename",
	create?: GraphQLTypes["MediaFields_filename_Create"] | undefined,
	read?: GraphQLTypes["MediaFields_filename_Read"] | undefined,
	update?: GraphQLTypes["MediaFields_filename_Update"] | undefined,
	delete?: GraphQLTypes["MediaFields_filename_Delete"] | undefined
};
	["MediaFields_filename_Create"]: {
	__typename: "MediaFields_filename_Create",
	permission: boolean
};
	["MediaFields_filename_Read"]: {
	__typename: "MediaFields_filename_Read",
	permission: boolean
};
	["MediaFields_filename_Update"]: {
	__typename: "MediaFields_filename_Update",
	permission: boolean
};
	["MediaFields_filename_Delete"]: {
	__typename: "MediaFields_filename_Delete",
	permission: boolean
};
	["MediaFields_mimeType"]: {
	__typename: "MediaFields_mimeType",
	create?: GraphQLTypes["MediaFields_mimeType_Create"] | undefined,
	read?: GraphQLTypes["MediaFields_mimeType_Read"] | undefined,
	update?: GraphQLTypes["MediaFields_mimeType_Update"] | undefined,
	delete?: GraphQLTypes["MediaFields_mimeType_Delete"] | undefined
};
	["MediaFields_mimeType_Create"]: {
	__typename: "MediaFields_mimeType_Create",
	permission: boolean
};
	["MediaFields_mimeType_Read"]: {
	__typename: "MediaFields_mimeType_Read",
	permission: boolean
};
	["MediaFields_mimeType_Update"]: {
	__typename: "MediaFields_mimeType_Update",
	permission: boolean
};
	["MediaFields_mimeType_Delete"]: {
	__typename: "MediaFields_mimeType_Delete",
	permission: boolean
};
	["MediaFields_filesize"]: {
	__typename: "MediaFields_filesize",
	create?: GraphQLTypes["MediaFields_filesize_Create"] | undefined,
	read?: GraphQLTypes["MediaFields_filesize_Read"] | undefined,
	update?: GraphQLTypes["MediaFields_filesize_Update"] | undefined,
	delete?: GraphQLTypes["MediaFields_filesize_Delete"] | undefined
};
	["MediaFields_filesize_Create"]: {
	__typename: "MediaFields_filesize_Create",
	permission: boolean
};
	["MediaFields_filesize_Read"]: {
	__typename: "MediaFields_filesize_Read",
	permission: boolean
};
	["MediaFields_filesize_Update"]: {
	__typename: "MediaFields_filesize_Update",
	permission: boolean
};
	["MediaFields_filesize_Delete"]: {
	__typename: "MediaFields_filesize_Delete",
	permission: boolean
};
	["MediaFields_width"]: {
	__typename: "MediaFields_width",
	create?: GraphQLTypes["MediaFields_width_Create"] | undefined,
	read?: GraphQLTypes["MediaFields_width_Read"] | undefined,
	update?: GraphQLTypes["MediaFields_width_Update"] | undefined,
	delete?: GraphQLTypes["MediaFields_width_Delete"] | undefined
};
	["MediaFields_width_Create"]: {
	__typename: "MediaFields_width_Create",
	permission: boolean
};
	["MediaFields_width_Read"]: {
	__typename: "MediaFields_width_Read",
	permission: boolean
};
	["MediaFields_width_Update"]: {
	__typename: "MediaFields_width_Update",
	permission: boolean
};
	["MediaFields_width_Delete"]: {
	__typename: "MediaFields_width_Delete",
	permission: boolean
};
	["MediaFields_height"]: {
	__typename: "MediaFields_height",
	create?: GraphQLTypes["MediaFields_height_Create"] | undefined,
	read?: GraphQLTypes["MediaFields_height_Read"] | undefined,
	update?: GraphQLTypes["MediaFields_height_Update"] | undefined,
	delete?: GraphQLTypes["MediaFields_height_Delete"] | undefined
};
	["MediaFields_height_Create"]: {
	__typename: "MediaFields_height_Create",
	permission: boolean
};
	["MediaFields_height_Read"]: {
	__typename: "MediaFields_height_Read",
	permission: boolean
};
	["MediaFields_height_Update"]: {
	__typename: "MediaFields_height_Update",
	permission: boolean
};
	["MediaFields_height_Delete"]: {
	__typename: "MediaFields_height_Delete",
	permission: boolean
};
	["MediaFields_focalX"]: {
	__typename: "MediaFields_focalX",
	create?: GraphQLTypes["MediaFields_focalX_Create"] | undefined,
	read?: GraphQLTypes["MediaFields_focalX_Read"] | undefined,
	update?: GraphQLTypes["MediaFields_focalX_Update"] | undefined,
	delete?: GraphQLTypes["MediaFields_focalX_Delete"] | undefined
};
	["MediaFields_focalX_Create"]: {
	__typename: "MediaFields_focalX_Create",
	permission: boolean
};
	["MediaFields_focalX_Read"]: {
	__typename: "MediaFields_focalX_Read",
	permission: boolean
};
	["MediaFields_focalX_Update"]: {
	__typename: "MediaFields_focalX_Update",
	permission: boolean
};
	["MediaFields_focalX_Delete"]: {
	__typename: "MediaFields_focalX_Delete",
	permission: boolean
};
	["MediaFields_focalY"]: {
	__typename: "MediaFields_focalY",
	create?: GraphQLTypes["MediaFields_focalY_Create"] | undefined,
	read?: GraphQLTypes["MediaFields_focalY_Read"] | undefined,
	update?: GraphQLTypes["MediaFields_focalY_Update"] | undefined,
	delete?: GraphQLTypes["MediaFields_focalY_Delete"] | undefined
};
	["MediaFields_focalY_Create"]: {
	__typename: "MediaFields_focalY_Create",
	permission: boolean
};
	["MediaFields_focalY_Read"]: {
	__typename: "MediaFields_focalY_Read",
	permission: boolean
};
	["MediaFields_focalY_Update"]: {
	__typename: "MediaFields_focalY_Update",
	permission: boolean
};
	["MediaFields_focalY_Delete"]: {
	__typename: "MediaFields_focalY_Delete",
	permission: boolean
};
	["MediaFields_sizes"]: {
	__typename: "MediaFields_sizes",
	create?: GraphQLTypes["MediaFields_sizes_Create"] | undefined,
	read?: GraphQLTypes["MediaFields_sizes_Read"] | undefined,
	update?: GraphQLTypes["MediaFields_sizes_Update"] | undefined,
	delete?: GraphQLTypes["MediaFields_sizes_Delete"] | undefined,
	fields?: GraphQLTypes["MediaFields_sizes_Fields"] | undefined
};
	["MediaFields_sizes_Create"]: {
	__typename: "MediaFields_sizes_Create",
	permission: boolean
};
	["MediaFields_sizes_Read"]: {
	__typename: "MediaFields_sizes_Read",
	permission: boolean
};
	["MediaFields_sizes_Update"]: {
	__typename: "MediaFields_sizes_Update",
	permission: boolean
};
	["MediaFields_sizes_Delete"]: {
	__typename: "MediaFields_sizes_Delete",
	permission: boolean
};
	["MediaFields_sizes_Fields"]: {
	__typename: "MediaFields_sizes_Fields",
	small?: GraphQLTypes["MediaFields_sizes_small"] | undefined,
	medium?: GraphQLTypes["MediaFields_sizes_medium"] | undefined,
	large?: GraphQLTypes["MediaFields_sizes_large"] | undefined,
	small_jpeg?: GraphQLTypes["MediaFields_sizes_small_jpeg"] | undefined,
	medium_jpeg?: GraphQLTypes["MediaFields_sizes_medium_jpeg"] | undefined,
	large_jpeg?: GraphQLTypes["MediaFields_sizes_large_jpeg"] | undefined
};
	["MediaFields_sizes_small"]: {
	__typename: "MediaFields_sizes_small",
	create?: GraphQLTypes["MediaFields_sizes_small_Create"] | undefined,
	read?: GraphQLTypes["MediaFields_sizes_small_Read"] | undefined,
	update?: GraphQLTypes["MediaFields_sizes_small_Update"] | undefined,
	delete?: GraphQLTypes["MediaFields_sizes_small_Delete"] | undefined,
	fields?: GraphQLTypes["MediaFields_sizes_small_Fields"] | undefined
};
	["MediaFields_sizes_small_Create"]: {
	__typename: "MediaFields_sizes_small_Create",
	permission: boolean
};
	["MediaFields_sizes_small_Read"]: {
	__typename: "MediaFields_sizes_small_Read",
	permission: boolean
};
	["MediaFields_sizes_small_Update"]: {
	__typename: "MediaFields_sizes_small_Update",
	permission: boolean
};
	["MediaFields_sizes_small_Delete"]: {
	__typename: "MediaFields_sizes_small_Delete",
	permission: boolean
};
	["MediaFields_sizes_small_Fields"]: {
	__typename: "MediaFields_sizes_small_Fields",
	url?: GraphQLTypes["MediaFields_sizes_small_url"] | undefined,
	width?: GraphQLTypes["MediaFields_sizes_small_width"] | undefined,
	height?: GraphQLTypes["MediaFields_sizes_small_height"] | undefined,
	mimeType?: GraphQLTypes["MediaFields_sizes_small_mimeType"] | undefined,
	filesize?: GraphQLTypes["MediaFields_sizes_small_filesize"] | undefined,
	filename?: GraphQLTypes["MediaFields_sizes_small_filename"] | undefined
};
	["MediaFields_sizes_small_url"]: {
	__typename: "MediaFields_sizes_small_url",
	create?: GraphQLTypes["MediaFields_sizes_small_url_Create"] | undefined,
	read?: GraphQLTypes["MediaFields_sizes_small_url_Read"] | undefined,
	update?: GraphQLTypes["MediaFields_sizes_small_url_Update"] | undefined,
	delete?: GraphQLTypes["MediaFields_sizes_small_url_Delete"] | undefined
};
	["MediaFields_sizes_small_url_Create"]: {
	__typename: "MediaFields_sizes_small_url_Create",
	permission: boolean
};
	["MediaFields_sizes_small_url_Read"]: {
	__typename: "MediaFields_sizes_small_url_Read",
	permission: boolean
};
	["MediaFields_sizes_small_url_Update"]: {
	__typename: "MediaFields_sizes_small_url_Update",
	permission: boolean
};
	["MediaFields_sizes_small_url_Delete"]: {
	__typename: "MediaFields_sizes_small_url_Delete",
	permission: boolean
};
	["MediaFields_sizes_small_width"]: {
	__typename: "MediaFields_sizes_small_width",
	create?: GraphQLTypes["MediaFields_sizes_small_width_Create"] | undefined,
	read?: GraphQLTypes["MediaFields_sizes_small_width_Read"] | undefined,
	update?: GraphQLTypes["MediaFields_sizes_small_width_Update"] | undefined,
	delete?: GraphQLTypes["MediaFields_sizes_small_width_Delete"] | undefined
};
	["MediaFields_sizes_small_width_Create"]: {
	__typename: "MediaFields_sizes_small_width_Create",
	permission: boolean
};
	["MediaFields_sizes_small_width_Read"]: {
	__typename: "MediaFields_sizes_small_width_Read",
	permission: boolean
};
	["MediaFields_sizes_small_width_Update"]: {
	__typename: "MediaFields_sizes_small_width_Update",
	permission: boolean
};
	["MediaFields_sizes_small_width_Delete"]: {
	__typename: "MediaFields_sizes_small_width_Delete",
	permission: boolean
};
	["MediaFields_sizes_small_height"]: {
	__typename: "MediaFields_sizes_small_height",
	create?: GraphQLTypes["MediaFields_sizes_small_height_Create"] | undefined,
	read?: GraphQLTypes["MediaFields_sizes_small_height_Read"] | undefined,
	update?: GraphQLTypes["MediaFields_sizes_small_height_Update"] | undefined,
	delete?: GraphQLTypes["MediaFields_sizes_small_height_Delete"] | undefined
};
	["MediaFields_sizes_small_height_Create"]: {
	__typename: "MediaFields_sizes_small_height_Create",
	permission: boolean
};
	["MediaFields_sizes_small_height_Read"]: {
	__typename: "MediaFields_sizes_small_height_Read",
	permission: boolean
};
	["MediaFields_sizes_small_height_Update"]: {
	__typename: "MediaFields_sizes_small_height_Update",
	permission: boolean
};
	["MediaFields_sizes_small_height_Delete"]: {
	__typename: "MediaFields_sizes_small_height_Delete",
	permission: boolean
};
	["MediaFields_sizes_small_mimeType"]: {
	__typename: "MediaFields_sizes_small_mimeType",
	create?: GraphQLTypes["MediaFields_sizes_small_mimeType_Create"] | undefined,
	read?: GraphQLTypes["MediaFields_sizes_small_mimeType_Read"] | undefined,
	update?: GraphQLTypes["MediaFields_sizes_small_mimeType_Update"] | undefined,
	delete?: GraphQLTypes["MediaFields_sizes_small_mimeType_Delete"] | undefined
};
	["MediaFields_sizes_small_mimeType_Create"]: {
	__typename: "MediaFields_sizes_small_mimeType_Create",
	permission: boolean
};
	["MediaFields_sizes_small_mimeType_Read"]: {
	__typename: "MediaFields_sizes_small_mimeType_Read",
	permission: boolean
};
	["MediaFields_sizes_small_mimeType_Update"]: {
	__typename: "MediaFields_sizes_small_mimeType_Update",
	permission: boolean
};
	["MediaFields_sizes_small_mimeType_Delete"]: {
	__typename: "MediaFields_sizes_small_mimeType_Delete",
	permission: boolean
};
	["MediaFields_sizes_small_filesize"]: {
	__typename: "MediaFields_sizes_small_filesize",
	create?: GraphQLTypes["MediaFields_sizes_small_filesize_Create"] | undefined,
	read?: GraphQLTypes["MediaFields_sizes_small_filesize_Read"] | undefined,
	update?: GraphQLTypes["MediaFields_sizes_small_filesize_Update"] | undefined,
	delete?: GraphQLTypes["MediaFields_sizes_small_filesize_Delete"] | undefined
};
	["MediaFields_sizes_small_filesize_Create"]: {
	__typename: "MediaFields_sizes_small_filesize_Create",
	permission: boolean
};
	["MediaFields_sizes_small_filesize_Read"]: {
	__typename: "MediaFields_sizes_small_filesize_Read",
	permission: boolean
};
	["MediaFields_sizes_small_filesize_Update"]: {
	__typename: "MediaFields_sizes_small_filesize_Update",
	permission: boolean
};
	["MediaFields_sizes_small_filesize_Delete"]: {
	__typename: "MediaFields_sizes_small_filesize_Delete",
	permission: boolean
};
	["MediaFields_sizes_small_filename"]: {
	__typename: "MediaFields_sizes_small_filename",
	create?: GraphQLTypes["MediaFields_sizes_small_filename_Create"] | undefined,
	read?: GraphQLTypes["MediaFields_sizes_small_filename_Read"] | undefined,
	update?: GraphQLTypes["MediaFields_sizes_small_filename_Update"] | undefined,
	delete?: GraphQLTypes["MediaFields_sizes_small_filename_Delete"] | undefined
};
	["MediaFields_sizes_small_filename_Create"]: {
	__typename: "MediaFields_sizes_small_filename_Create",
	permission: boolean
};
	["MediaFields_sizes_small_filename_Read"]: {
	__typename: "MediaFields_sizes_small_filename_Read",
	permission: boolean
};
	["MediaFields_sizes_small_filename_Update"]: {
	__typename: "MediaFields_sizes_small_filename_Update",
	permission: boolean
};
	["MediaFields_sizes_small_filename_Delete"]: {
	__typename: "MediaFields_sizes_small_filename_Delete",
	permission: boolean
};
	["MediaFields_sizes_medium"]: {
	__typename: "MediaFields_sizes_medium",
	create?: GraphQLTypes["MediaFields_sizes_medium_Create"] | undefined,
	read?: GraphQLTypes["MediaFields_sizes_medium_Read"] | undefined,
	update?: GraphQLTypes["MediaFields_sizes_medium_Update"] | undefined,
	delete?: GraphQLTypes["MediaFields_sizes_medium_Delete"] | undefined,
	fields?: GraphQLTypes["MediaFields_sizes_medium_Fields"] | undefined
};
	["MediaFields_sizes_medium_Create"]: {
	__typename: "MediaFields_sizes_medium_Create",
	permission: boolean
};
	["MediaFields_sizes_medium_Read"]: {
	__typename: "MediaFields_sizes_medium_Read",
	permission: boolean
};
	["MediaFields_sizes_medium_Update"]: {
	__typename: "MediaFields_sizes_medium_Update",
	permission: boolean
};
	["MediaFields_sizes_medium_Delete"]: {
	__typename: "MediaFields_sizes_medium_Delete",
	permission: boolean
};
	["MediaFields_sizes_medium_Fields"]: {
	__typename: "MediaFields_sizes_medium_Fields",
	url?: GraphQLTypes["MediaFields_sizes_medium_url"] | undefined,
	width?: GraphQLTypes["MediaFields_sizes_medium_width"] | undefined,
	height?: GraphQLTypes["MediaFields_sizes_medium_height"] | undefined,
	mimeType?: GraphQLTypes["MediaFields_sizes_medium_mimeType"] | undefined,
	filesize?: GraphQLTypes["MediaFields_sizes_medium_filesize"] | undefined,
	filename?: GraphQLTypes["MediaFields_sizes_medium_filename"] | undefined
};
	["MediaFields_sizes_medium_url"]: {
	__typename: "MediaFields_sizes_medium_url",
	create?: GraphQLTypes["MediaFields_sizes_medium_url_Create"] | undefined,
	read?: GraphQLTypes["MediaFields_sizes_medium_url_Read"] | undefined,
	update?: GraphQLTypes["MediaFields_sizes_medium_url_Update"] | undefined,
	delete?: GraphQLTypes["MediaFields_sizes_medium_url_Delete"] | undefined
};
	["MediaFields_sizes_medium_url_Create"]: {
	__typename: "MediaFields_sizes_medium_url_Create",
	permission: boolean
};
	["MediaFields_sizes_medium_url_Read"]: {
	__typename: "MediaFields_sizes_medium_url_Read",
	permission: boolean
};
	["MediaFields_sizes_medium_url_Update"]: {
	__typename: "MediaFields_sizes_medium_url_Update",
	permission: boolean
};
	["MediaFields_sizes_medium_url_Delete"]: {
	__typename: "MediaFields_sizes_medium_url_Delete",
	permission: boolean
};
	["MediaFields_sizes_medium_width"]: {
	__typename: "MediaFields_sizes_medium_width",
	create?: GraphQLTypes["MediaFields_sizes_medium_width_Create"] | undefined,
	read?: GraphQLTypes["MediaFields_sizes_medium_width_Read"] | undefined,
	update?: GraphQLTypes["MediaFields_sizes_medium_width_Update"] | undefined,
	delete?: GraphQLTypes["MediaFields_sizes_medium_width_Delete"] | undefined
};
	["MediaFields_sizes_medium_width_Create"]: {
	__typename: "MediaFields_sizes_medium_width_Create",
	permission: boolean
};
	["MediaFields_sizes_medium_width_Read"]: {
	__typename: "MediaFields_sizes_medium_width_Read",
	permission: boolean
};
	["MediaFields_sizes_medium_width_Update"]: {
	__typename: "MediaFields_sizes_medium_width_Update",
	permission: boolean
};
	["MediaFields_sizes_medium_width_Delete"]: {
	__typename: "MediaFields_sizes_medium_width_Delete",
	permission: boolean
};
	["MediaFields_sizes_medium_height"]: {
	__typename: "MediaFields_sizes_medium_height",
	create?: GraphQLTypes["MediaFields_sizes_medium_height_Create"] | undefined,
	read?: GraphQLTypes["MediaFields_sizes_medium_height_Read"] | undefined,
	update?: GraphQLTypes["MediaFields_sizes_medium_height_Update"] | undefined,
	delete?: GraphQLTypes["MediaFields_sizes_medium_height_Delete"] | undefined
};
	["MediaFields_sizes_medium_height_Create"]: {
	__typename: "MediaFields_sizes_medium_height_Create",
	permission: boolean
};
	["MediaFields_sizes_medium_height_Read"]: {
	__typename: "MediaFields_sizes_medium_height_Read",
	permission: boolean
};
	["MediaFields_sizes_medium_height_Update"]: {
	__typename: "MediaFields_sizes_medium_height_Update",
	permission: boolean
};
	["MediaFields_sizes_medium_height_Delete"]: {
	__typename: "MediaFields_sizes_medium_height_Delete",
	permission: boolean
};
	["MediaFields_sizes_medium_mimeType"]: {
	__typename: "MediaFields_sizes_medium_mimeType",
	create?: GraphQLTypes["MediaFields_sizes_medium_mimeType_Create"] | undefined,
	read?: GraphQLTypes["MediaFields_sizes_medium_mimeType_Read"] | undefined,
	update?: GraphQLTypes["MediaFields_sizes_medium_mimeType_Update"] | undefined,
	delete?: GraphQLTypes["MediaFields_sizes_medium_mimeType_Delete"] | undefined
};
	["MediaFields_sizes_medium_mimeType_Create"]: {
	__typename: "MediaFields_sizes_medium_mimeType_Create",
	permission: boolean
};
	["MediaFields_sizes_medium_mimeType_Read"]: {
	__typename: "MediaFields_sizes_medium_mimeType_Read",
	permission: boolean
};
	["MediaFields_sizes_medium_mimeType_Update"]: {
	__typename: "MediaFields_sizes_medium_mimeType_Update",
	permission: boolean
};
	["MediaFields_sizes_medium_mimeType_Delete"]: {
	__typename: "MediaFields_sizes_medium_mimeType_Delete",
	permission: boolean
};
	["MediaFields_sizes_medium_filesize"]: {
	__typename: "MediaFields_sizes_medium_filesize",
	create?: GraphQLTypes["MediaFields_sizes_medium_filesize_Create"] | undefined,
	read?: GraphQLTypes["MediaFields_sizes_medium_filesize_Read"] | undefined,
	update?: GraphQLTypes["MediaFields_sizes_medium_filesize_Update"] | undefined,
	delete?: GraphQLTypes["MediaFields_sizes_medium_filesize_Delete"] | undefined
};
	["MediaFields_sizes_medium_filesize_Create"]: {
	__typename: "MediaFields_sizes_medium_filesize_Create",
	permission: boolean
};
	["MediaFields_sizes_medium_filesize_Read"]: {
	__typename: "MediaFields_sizes_medium_filesize_Read",
	permission: boolean
};
	["MediaFields_sizes_medium_filesize_Update"]: {
	__typename: "MediaFields_sizes_medium_filesize_Update",
	permission: boolean
};
	["MediaFields_sizes_medium_filesize_Delete"]: {
	__typename: "MediaFields_sizes_medium_filesize_Delete",
	permission: boolean
};
	["MediaFields_sizes_medium_filename"]: {
	__typename: "MediaFields_sizes_medium_filename",
	create?: GraphQLTypes["MediaFields_sizes_medium_filename_Create"] | undefined,
	read?: GraphQLTypes["MediaFields_sizes_medium_filename_Read"] | undefined,
	update?: GraphQLTypes["MediaFields_sizes_medium_filename_Update"] | undefined,
	delete?: GraphQLTypes["MediaFields_sizes_medium_filename_Delete"] | undefined
};
	["MediaFields_sizes_medium_filename_Create"]: {
	__typename: "MediaFields_sizes_medium_filename_Create",
	permission: boolean
};
	["MediaFields_sizes_medium_filename_Read"]: {
	__typename: "MediaFields_sizes_medium_filename_Read",
	permission: boolean
};
	["MediaFields_sizes_medium_filename_Update"]: {
	__typename: "MediaFields_sizes_medium_filename_Update",
	permission: boolean
};
	["MediaFields_sizes_medium_filename_Delete"]: {
	__typename: "MediaFields_sizes_medium_filename_Delete",
	permission: boolean
};
	["MediaFields_sizes_large"]: {
	__typename: "MediaFields_sizes_large",
	create?: GraphQLTypes["MediaFields_sizes_large_Create"] | undefined,
	read?: GraphQLTypes["MediaFields_sizes_large_Read"] | undefined,
	update?: GraphQLTypes["MediaFields_sizes_large_Update"] | undefined,
	delete?: GraphQLTypes["MediaFields_sizes_large_Delete"] | undefined,
	fields?: GraphQLTypes["MediaFields_sizes_large_Fields"] | undefined
};
	["MediaFields_sizes_large_Create"]: {
	__typename: "MediaFields_sizes_large_Create",
	permission: boolean
};
	["MediaFields_sizes_large_Read"]: {
	__typename: "MediaFields_sizes_large_Read",
	permission: boolean
};
	["MediaFields_sizes_large_Update"]: {
	__typename: "MediaFields_sizes_large_Update",
	permission: boolean
};
	["MediaFields_sizes_large_Delete"]: {
	__typename: "MediaFields_sizes_large_Delete",
	permission: boolean
};
	["MediaFields_sizes_large_Fields"]: {
	__typename: "MediaFields_sizes_large_Fields",
	url?: GraphQLTypes["MediaFields_sizes_large_url"] | undefined,
	width?: GraphQLTypes["MediaFields_sizes_large_width"] | undefined,
	height?: GraphQLTypes["MediaFields_sizes_large_height"] | undefined,
	mimeType?: GraphQLTypes["MediaFields_sizes_large_mimeType"] | undefined,
	filesize?: GraphQLTypes["MediaFields_sizes_large_filesize"] | undefined,
	filename?: GraphQLTypes["MediaFields_sizes_large_filename"] | undefined
};
	["MediaFields_sizes_large_url"]: {
	__typename: "MediaFields_sizes_large_url",
	create?: GraphQLTypes["MediaFields_sizes_large_url_Create"] | undefined,
	read?: GraphQLTypes["MediaFields_sizes_large_url_Read"] | undefined,
	update?: GraphQLTypes["MediaFields_sizes_large_url_Update"] | undefined,
	delete?: GraphQLTypes["MediaFields_sizes_large_url_Delete"] | undefined
};
	["MediaFields_sizes_large_url_Create"]: {
	__typename: "MediaFields_sizes_large_url_Create",
	permission: boolean
};
	["MediaFields_sizes_large_url_Read"]: {
	__typename: "MediaFields_sizes_large_url_Read",
	permission: boolean
};
	["MediaFields_sizes_large_url_Update"]: {
	__typename: "MediaFields_sizes_large_url_Update",
	permission: boolean
};
	["MediaFields_sizes_large_url_Delete"]: {
	__typename: "MediaFields_sizes_large_url_Delete",
	permission: boolean
};
	["MediaFields_sizes_large_width"]: {
	__typename: "MediaFields_sizes_large_width",
	create?: GraphQLTypes["MediaFields_sizes_large_width_Create"] | undefined,
	read?: GraphQLTypes["MediaFields_sizes_large_width_Read"] | undefined,
	update?: GraphQLTypes["MediaFields_sizes_large_width_Update"] | undefined,
	delete?: GraphQLTypes["MediaFields_sizes_large_width_Delete"] | undefined
};
	["MediaFields_sizes_large_width_Create"]: {
	__typename: "MediaFields_sizes_large_width_Create",
	permission: boolean
};
	["MediaFields_sizes_large_width_Read"]: {
	__typename: "MediaFields_sizes_large_width_Read",
	permission: boolean
};
	["MediaFields_sizes_large_width_Update"]: {
	__typename: "MediaFields_sizes_large_width_Update",
	permission: boolean
};
	["MediaFields_sizes_large_width_Delete"]: {
	__typename: "MediaFields_sizes_large_width_Delete",
	permission: boolean
};
	["MediaFields_sizes_large_height"]: {
	__typename: "MediaFields_sizes_large_height",
	create?: GraphQLTypes["MediaFields_sizes_large_height_Create"] | undefined,
	read?: GraphQLTypes["MediaFields_sizes_large_height_Read"] | undefined,
	update?: GraphQLTypes["MediaFields_sizes_large_height_Update"] | undefined,
	delete?: GraphQLTypes["MediaFields_sizes_large_height_Delete"] | undefined
};
	["MediaFields_sizes_large_height_Create"]: {
	__typename: "MediaFields_sizes_large_height_Create",
	permission: boolean
};
	["MediaFields_sizes_large_height_Read"]: {
	__typename: "MediaFields_sizes_large_height_Read",
	permission: boolean
};
	["MediaFields_sizes_large_height_Update"]: {
	__typename: "MediaFields_sizes_large_height_Update",
	permission: boolean
};
	["MediaFields_sizes_large_height_Delete"]: {
	__typename: "MediaFields_sizes_large_height_Delete",
	permission: boolean
};
	["MediaFields_sizes_large_mimeType"]: {
	__typename: "MediaFields_sizes_large_mimeType",
	create?: GraphQLTypes["MediaFields_sizes_large_mimeType_Create"] | undefined,
	read?: GraphQLTypes["MediaFields_sizes_large_mimeType_Read"] | undefined,
	update?: GraphQLTypes["MediaFields_sizes_large_mimeType_Update"] | undefined,
	delete?: GraphQLTypes["MediaFields_sizes_large_mimeType_Delete"] | undefined
};
	["MediaFields_sizes_large_mimeType_Create"]: {
	__typename: "MediaFields_sizes_large_mimeType_Create",
	permission: boolean
};
	["MediaFields_sizes_large_mimeType_Read"]: {
	__typename: "MediaFields_sizes_large_mimeType_Read",
	permission: boolean
};
	["MediaFields_sizes_large_mimeType_Update"]: {
	__typename: "MediaFields_sizes_large_mimeType_Update",
	permission: boolean
};
	["MediaFields_sizes_large_mimeType_Delete"]: {
	__typename: "MediaFields_sizes_large_mimeType_Delete",
	permission: boolean
};
	["MediaFields_sizes_large_filesize"]: {
	__typename: "MediaFields_sizes_large_filesize",
	create?: GraphQLTypes["MediaFields_sizes_large_filesize_Create"] | undefined,
	read?: GraphQLTypes["MediaFields_sizes_large_filesize_Read"] | undefined,
	update?: GraphQLTypes["MediaFields_sizes_large_filesize_Update"] | undefined,
	delete?: GraphQLTypes["MediaFields_sizes_large_filesize_Delete"] | undefined
};
	["MediaFields_sizes_large_filesize_Create"]: {
	__typename: "MediaFields_sizes_large_filesize_Create",
	permission: boolean
};
	["MediaFields_sizes_large_filesize_Read"]: {
	__typename: "MediaFields_sizes_large_filesize_Read",
	permission: boolean
};
	["MediaFields_sizes_large_filesize_Update"]: {
	__typename: "MediaFields_sizes_large_filesize_Update",
	permission: boolean
};
	["MediaFields_sizes_large_filesize_Delete"]: {
	__typename: "MediaFields_sizes_large_filesize_Delete",
	permission: boolean
};
	["MediaFields_sizes_large_filename"]: {
	__typename: "MediaFields_sizes_large_filename",
	create?: GraphQLTypes["MediaFields_sizes_large_filename_Create"] | undefined,
	read?: GraphQLTypes["MediaFields_sizes_large_filename_Read"] | undefined,
	update?: GraphQLTypes["MediaFields_sizes_large_filename_Update"] | undefined,
	delete?: GraphQLTypes["MediaFields_sizes_large_filename_Delete"] | undefined
};
	["MediaFields_sizes_large_filename_Create"]: {
	__typename: "MediaFields_sizes_large_filename_Create",
	permission: boolean
};
	["MediaFields_sizes_large_filename_Read"]: {
	__typename: "MediaFields_sizes_large_filename_Read",
	permission: boolean
};
	["MediaFields_sizes_large_filename_Update"]: {
	__typename: "MediaFields_sizes_large_filename_Update",
	permission: boolean
};
	["MediaFields_sizes_large_filename_Delete"]: {
	__typename: "MediaFields_sizes_large_filename_Delete",
	permission: boolean
};
	["MediaFields_sizes_small_jpeg"]: {
	__typename: "MediaFields_sizes_small_jpeg",
	create?: GraphQLTypes["MediaFields_sizes_small_jpeg_Create"] | undefined,
	read?: GraphQLTypes["MediaFields_sizes_small_jpeg_Read"] | undefined,
	update?: GraphQLTypes["MediaFields_sizes_small_jpeg_Update"] | undefined,
	delete?: GraphQLTypes["MediaFields_sizes_small_jpeg_Delete"] | undefined,
	fields?: GraphQLTypes["MediaFields_sizes_small_jpeg_Fields"] | undefined
};
	["MediaFields_sizes_small_jpeg_Create"]: {
	__typename: "MediaFields_sizes_small_jpeg_Create",
	permission: boolean
};
	["MediaFields_sizes_small_jpeg_Read"]: {
	__typename: "MediaFields_sizes_small_jpeg_Read",
	permission: boolean
};
	["MediaFields_sizes_small_jpeg_Update"]: {
	__typename: "MediaFields_sizes_small_jpeg_Update",
	permission: boolean
};
	["MediaFields_sizes_small_jpeg_Delete"]: {
	__typename: "MediaFields_sizes_small_jpeg_Delete",
	permission: boolean
};
	["MediaFields_sizes_small_jpeg_Fields"]: {
	__typename: "MediaFields_sizes_small_jpeg_Fields",
	url?: GraphQLTypes["MediaFields_sizes_small_jpeg_url"] | undefined,
	width?: GraphQLTypes["MediaFields_sizes_small_jpeg_width"] | undefined,
	height?: GraphQLTypes["MediaFields_sizes_small_jpeg_height"] | undefined,
	mimeType?: GraphQLTypes["MediaFields_sizes_small_jpeg_mimeType"] | undefined,
	filesize?: GraphQLTypes["MediaFields_sizes_small_jpeg_filesize"] | undefined,
	filename?: GraphQLTypes["MediaFields_sizes_small_jpeg_filename"] | undefined
};
	["MediaFields_sizes_small_jpeg_url"]: {
	__typename: "MediaFields_sizes_small_jpeg_url",
	create?: GraphQLTypes["MediaFields_sizes_small_jpeg_url_Create"] | undefined,
	read?: GraphQLTypes["MediaFields_sizes_small_jpeg_url_Read"] | undefined,
	update?: GraphQLTypes["MediaFields_sizes_small_jpeg_url_Update"] | undefined,
	delete?: GraphQLTypes["MediaFields_sizes_small_jpeg_url_Delete"] | undefined
};
	["MediaFields_sizes_small_jpeg_url_Create"]: {
	__typename: "MediaFields_sizes_small_jpeg_url_Create",
	permission: boolean
};
	["MediaFields_sizes_small_jpeg_url_Read"]: {
	__typename: "MediaFields_sizes_small_jpeg_url_Read",
	permission: boolean
};
	["MediaFields_sizes_small_jpeg_url_Update"]: {
	__typename: "MediaFields_sizes_small_jpeg_url_Update",
	permission: boolean
};
	["MediaFields_sizes_small_jpeg_url_Delete"]: {
	__typename: "MediaFields_sizes_small_jpeg_url_Delete",
	permission: boolean
};
	["MediaFields_sizes_small_jpeg_width"]: {
	__typename: "MediaFields_sizes_small_jpeg_width",
	create?: GraphQLTypes["MediaFields_sizes_small_jpeg_width_Create"] | undefined,
	read?: GraphQLTypes["MediaFields_sizes_small_jpeg_width_Read"] | undefined,
	update?: GraphQLTypes["MediaFields_sizes_small_jpeg_width_Update"] | undefined,
	delete?: GraphQLTypes["MediaFields_sizes_small_jpeg_width_Delete"] | undefined
};
	["MediaFields_sizes_small_jpeg_width_Create"]: {
	__typename: "MediaFields_sizes_small_jpeg_width_Create",
	permission: boolean
};
	["MediaFields_sizes_small_jpeg_width_Read"]: {
	__typename: "MediaFields_sizes_small_jpeg_width_Read",
	permission: boolean
};
	["MediaFields_sizes_small_jpeg_width_Update"]: {
	__typename: "MediaFields_sizes_small_jpeg_width_Update",
	permission: boolean
};
	["MediaFields_sizes_small_jpeg_width_Delete"]: {
	__typename: "MediaFields_sizes_small_jpeg_width_Delete",
	permission: boolean
};
	["MediaFields_sizes_small_jpeg_height"]: {
	__typename: "MediaFields_sizes_small_jpeg_height",
	create?: GraphQLTypes["MediaFields_sizes_small_jpeg_height_Create"] | undefined,
	read?: GraphQLTypes["MediaFields_sizes_small_jpeg_height_Read"] | undefined,
	update?: GraphQLTypes["MediaFields_sizes_small_jpeg_height_Update"] | undefined,
	delete?: GraphQLTypes["MediaFields_sizes_small_jpeg_height_Delete"] | undefined
};
	["MediaFields_sizes_small_jpeg_height_Create"]: {
	__typename: "MediaFields_sizes_small_jpeg_height_Create",
	permission: boolean
};
	["MediaFields_sizes_small_jpeg_height_Read"]: {
	__typename: "MediaFields_sizes_small_jpeg_height_Read",
	permission: boolean
};
	["MediaFields_sizes_small_jpeg_height_Update"]: {
	__typename: "MediaFields_sizes_small_jpeg_height_Update",
	permission: boolean
};
	["MediaFields_sizes_small_jpeg_height_Delete"]: {
	__typename: "MediaFields_sizes_small_jpeg_height_Delete",
	permission: boolean
};
	["MediaFields_sizes_small_jpeg_mimeType"]: {
	__typename: "MediaFields_sizes_small_jpeg_mimeType",
	create?: GraphQLTypes["MediaFields_sizes_small_jpeg_mimeType_Create"] | undefined,
	read?: GraphQLTypes["MediaFields_sizes_small_jpeg_mimeType_Read"] | undefined,
	update?: GraphQLTypes["MediaFields_sizes_small_jpeg_mimeType_Update"] | undefined,
	delete?: GraphQLTypes["MediaFields_sizes_small_jpeg_mimeType_Delete"] | undefined
};
	["MediaFields_sizes_small_jpeg_mimeType_Create"]: {
	__typename: "MediaFields_sizes_small_jpeg_mimeType_Create",
	permission: boolean
};
	["MediaFields_sizes_small_jpeg_mimeType_Read"]: {
	__typename: "MediaFields_sizes_small_jpeg_mimeType_Read",
	permission: boolean
};
	["MediaFields_sizes_small_jpeg_mimeType_Update"]: {
	__typename: "MediaFields_sizes_small_jpeg_mimeType_Update",
	permission: boolean
};
	["MediaFields_sizes_small_jpeg_mimeType_Delete"]: {
	__typename: "MediaFields_sizes_small_jpeg_mimeType_Delete",
	permission: boolean
};
	["MediaFields_sizes_small_jpeg_filesize"]: {
	__typename: "MediaFields_sizes_small_jpeg_filesize",
	create?: GraphQLTypes["MediaFields_sizes_small_jpeg_filesize_Create"] | undefined,
	read?: GraphQLTypes["MediaFields_sizes_small_jpeg_filesize_Read"] | undefined,
	update?: GraphQLTypes["MediaFields_sizes_small_jpeg_filesize_Update"] | undefined,
	delete?: GraphQLTypes["MediaFields_sizes_small_jpeg_filesize_Delete"] | undefined
};
	["MediaFields_sizes_small_jpeg_filesize_Create"]: {
	__typename: "MediaFields_sizes_small_jpeg_filesize_Create",
	permission: boolean
};
	["MediaFields_sizes_small_jpeg_filesize_Read"]: {
	__typename: "MediaFields_sizes_small_jpeg_filesize_Read",
	permission: boolean
};
	["MediaFields_sizes_small_jpeg_filesize_Update"]: {
	__typename: "MediaFields_sizes_small_jpeg_filesize_Update",
	permission: boolean
};
	["MediaFields_sizes_small_jpeg_filesize_Delete"]: {
	__typename: "MediaFields_sizes_small_jpeg_filesize_Delete",
	permission: boolean
};
	["MediaFields_sizes_small_jpeg_filename"]: {
	__typename: "MediaFields_sizes_small_jpeg_filename",
	create?: GraphQLTypes["MediaFields_sizes_small_jpeg_filename_Create"] | undefined,
	read?: GraphQLTypes["MediaFields_sizes_small_jpeg_filename_Read"] | undefined,
	update?: GraphQLTypes["MediaFields_sizes_small_jpeg_filename_Update"] | undefined,
	delete?: GraphQLTypes["MediaFields_sizes_small_jpeg_filename_Delete"] | undefined
};
	["MediaFields_sizes_small_jpeg_filename_Create"]: {
	__typename: "MediaFields_sizes_small_jpeg_filename_Create",
	permission: boolean
};
	["MediaFields_sizes_small_jpeg_filename_Read"]: {
	__typename: "MediaFields_sizes_small_jpeg_filename_Read",
	permission: boolean
};
	["MediaFields_sizes_small_jpeg_filename_Update"]: {
	__typename: "MediaFields_sizes_small_jpeg_filename_Update",
	permission: boolean
};
	["MediaFields_sizes_small_jpeg_filename_Delete"]: {
	__typename: "MediaFields_sizes_small_jpeg_filename_Delete",
	permission: boolean
};
	["MediaFields_sizes_medium_jpeg"]: {
	__typename: "MediaFields_sizes_medium_jpeg",
	create?: GraphQLTypes["MediaFields_sizes_medium_jpeg_Create"] | undefined,
	read?: GraphQLTypes["MediaFields_sizes_medium_jpeg_Read"] | undefined,
	update?: GraphQLTypes["MediaFields_sizes_medium_jpeg_Update"] | undefined,
	delete?: GraphQLTypes["MediaFields_sizes_medium_jpeg_Delete"] | undefined,
	fields?: GraphQLTypes["MediaFields_sizes_medium_jpeg_Fields"] | undefined
};
	["MediaFields_sizes_medium_jpeg_Create"]: {
	__typename: "MediaFields_sizes_medium_jpeg_Create",
	permission: boolean
};
	["MediaFields_sizes_medium_jpeg_Read"]: {
	__typename: "MediaFields_sizes_medium_jpeg_Read",
	permission: boolean
};
	["MediaFields_sizes_medium_jpeg_Update"]: {
	__typename: "MediaFields_sizes_medium_jpeg_Update",
	permission: boolean
};
	["MediaFields_sizes_medium_jpeg_Delete"]: {
	__typename: "MediaFields_sizes_medium_jpeg_Delete",
	permission: boolean
};
	["MediaFields_sizes_medium_jpeg_Fields"]: {
	__typename: "MediaFields_sizes_medium_jpeg_Fields",
	url?: GraphQLTypes["MediaFields_sizes_medium_jpeg_url"] | undefined,
	width?: GraphQLTypes["MediaFields_sizes_medium_jpeg_width"] | undefined,
	height?: GraphQLTypes["MediaFields_sizes_medium_jpeg_height"] | undefined,
	mimeType?: GraphQLTypes["MediaFields_sizes_medium_jpeg_mimeType"] | undefined,
	filesize?: GraphQLTypes["MediaFields_sizes_medium_jpeg_filesize"] | undefined,
	filename?: GraphQLTypes["MediaFields_sizes_medium_jpeg_filename"] | undefined
};
	["MediaFields_sizes_medium_jpeg_url"]: {
	__typename: "MediaFields_sizes_medium_jpeg_url",
	create?: GraphQLTypes["MediaFields_sizes_medium_jpeg_url_Create"] | undefined,
	read?: GraphQLTypes["MediaFields_sizes_medium_jpeg_url_Read"] | undefined,
	update?: GraphQLTypes["MediaFields_sizes_medium_jpeg_url_Update"] | undefined,
	delete?: GraphQLTypes["MediaFields_sizes_medium_jpeg_url_Delete"] | undefined
};
	["MediaFields_sizes_medium_jpeg_url_Create"]: {
	__typename: "MediaFields_sizes_medium_jpeg_url_Create",
	permission: boolean
};
	["MediaFields_sizes_medium_jpeg_url_Read"]: {
	__typename: "MediaFields_sizes_medium_jpeg_url_Read",
	permission: boolean
};
	["MediaFields_sizes_medium_jpeg_url_Update"]: {
	__typename: "MediaFields_sizes_medium_jpeg_url_Update",
	permission: boolean
};
	["MediaFields_sizes_medium_jpeg_url_Delete"]: {
	__typename: "MediaFields_sizes_medium_jpeg_url_Delete",
	permission: boolean
};
	["MediaFields_sizes_medium_jpeg_width"]: {
	__typename: "MediaFields_sizes_medium_jpeg_width",
	create?: GraphQLTypes["MediaFields_sizes_medium_jpeg_width_Create"] | undefined,
	read?: GraphQLTypes["MediaFields_sizes_medium_jpeg_width_Read"] | undefined,
	update?: GraphQLTypes["MediaFields_sizes_medium_jpeg_width_Update"] | undefined,
	delete?: GraphQLTypes["MediaFields_sizes_medium_jpeg_width_Delete"] | undefined
};
	["MediaFields_sizes_medium_jpeg_width_Create"]: {
	__typename: "MediaFields_sizes_medium_jpeg_width_Create",
	permission: boolean
};
	["MediaFields_sizes_medium_jpeg_width_Read"]: {
	__typename: "MediaFields_sizes_medium_jpeg_width_Read",
	permission: boolean
};
	["MediaFields_sizes_medium_jpeg_width_Update"]: {
	__typename: "MediaFields_sizes_medium_jpeg_width_Update",
	permission: boolean
};
	["MediaFields_sizes_medium_jpeg_width_Delete"]: {
	__typename: "MediaFields_sizes_medium_jpeg_width_Delete",
	permission: boolean
};
	["MediaFields_sizes_medium_jpeg_height"]: {
	__typename: "MediaFields_sizes_medium_jpeg_height",
	create?: GraphQLTypes["MediaFields_sizes_medium_jpeg_height_Create"] | undefined,
	read?: GraphQLTypes["MediaFields_sizes_medium_jpeg_height_Read"] | undefined,
	update?: GraphQLTypes["MediaFields_sizes_medium_jpeg_height_Update"] | undefined,
	delete?: GraphQLTypes["MediaFields_sizes_medium_jpeg_height_Delete"] | undefined
};
	["MediaFields_sizes_medium_jpeg_height_Create"]: {
	__typename: "MediaFields_sizes_medium_jpeg_height_Create",
	permission: boolean
};
	["MediaFields_sizes_medium_jpeg_height_Read"]: {
	__typename: "MediaFields_sizes_medium_jpeg_height_Read",
	permission: boolean
};
	["MediaFields_sizes_medium_jpeg_height_Update"]: {
	__typename: "MediaFields_sizes_medium_jpeg_height_Update",
	permission: boolean
};
	["MediaFields_sizes_medium_jpeg_height_Delete"]: {
	__typename: "MediaFields_sizes_medium_jpeg_height_Delete",
	permission: boolean
};
	["MediaFields_sizes_medium_jpeg_mimeType"]: {
	__typename: "MediaFields_sizes_medium_jpeg_mimeType",
	create?: GraphQLTypes["MediaFields_sizes_medium_jpeg_mimeType_Create"] | undefined,
	read?: GraphQLTypes["MediaFields_sizes_medium_jpeg_mimeType_Read"] | undefined,
	update?: GraphQLTypes["MediaFields_sizes_medium_jpeg_mimeType_Update"] | undefined,
	delete?: GraphQLTypes["MediaFields_sizes_medium_jpeg_mimeType_Delete"] | undefined
};
	["MediaFields_sizes_medium_jpeg_mimeType_Create"]: {
	__typename: "MediaFields_sizes_medium_jpeg_mimeType_Create",
	permission: boolean
};
	["MediaFields_sizes_medium_jpeg_mimeType_Read"]: {
	__typename: "MediaFields_sizes_medium_jpeg_mimeType_Read",
	permission: boolean
};
	["MediaFields_sizes_medium_jpeg_mimeType_Update"]: {
	__typename: "MediaFields_sizes_medium_jpeg_mimeType_Update",
	permission: boolean
};
	["MediaFields_sizes_medium_jpeg_mimeType_Delete"]: {
	__typename: "MediaFields_sizes_medium_jpeg_mimeType_Delete",
	permission: boolean
};
	["MediaFields_sizes_medium_jpeg_filesize"]: {
	__typename: "MediaFields_sizes_medium_jpeg_filesize",
	create?: GraphQLTypes["MediaFields_sizes_medium_jpeg_filesize_Create"] | undefined,
	read?: GraphQLTypes["MediaFields_sizes_medium_jpeg_filesize_Read"] | undefined,
	update?: GraphQLTypes["MediaFields_sizes_medium_jpeg_filesize_Update"] | undefined,
	delete?: GraphQLTypes["MediaFields_sizes_medium_jpeg_filesize_Delete"] | undefined
};
	["MediaFields_sizes_medium_jpeg_filesize_Create"]: {
	__typename: "MediaFields_sizes_medium_jpeg_filesize_Create",
	permission: boolean
};
	["MediaFields_sizes_medium_jpeg_filesize_Read"]: {
	__typename: "MediaFields_sizes_medium_jpeg_filesize_Read",
	permission: boolean
};
	["MediaFields_sizes_medium_jpeg_filesize_Update"]: {
	__typename: "MediaFields_sizes_medium_jpeg_filesize_Update",
	permission: boolean
};
	["MediaFields_sizes_medium_jpeg_filesize_Delete"]: {
	__typename: "MediaFields_sizes_medium_jpeg_filesize_Delete",
	permission: boolean
};
	["MediaFields_sizes_medium_jpeg_filename"]: {
	__typename: "MediaFields_sizes_medium_jpeg_filename",
	create?: GraphQLTypes["MediaFields_sizes_medium_jpeg_filename_Create"] | undefined,
	read?: GraphQLTypes["MediaFields_sizes_medium_jpeg_filename_Read"] | undefined,
	update?: GraphQLTypes["MediaFields_sizes_medium_jpeg_filename_Update"] | undefined,
	delete?: GraphQLTypes["MediaFields_sizes_medium_jpeg_filename_Delete"] | undefined
};
	["MediaFields_sizes_medium_jpeg_filename_Create"]: {
	__typename: "MediaFields_sizes_medium_jpeg_filename_Create",
	permission: boolean
};
	["MediaFields_sizes_medium_jpeg_filename_Read"]: {
	__typename: "MediaFields_sizes_medium_jpeg_filename_Read",
	permission: boolean
};
	["MediaFields_sizes_medium_jpeg_filename_Update"]: {
	__typename: "MediaFields_sizes_medium_jpeg_filename_Update",
	permission: boolean
};
	["MediaFields_sizes_medium_jpeg_filename_Delete"]: {
	__typename: "MediaFields_sizes_medium_jpeg_filename_Delete",
	permission: boolean
};
	["MediaFields_sizes_large_jpeg"]: {
	__typename: "MediaFields_sizes_large_jpeg",
	create?: GraphQLTypes["MediaFields_sizes_large_jpeg_Create"] | undefined,
	read?: GraphQLTypes["MediaFields_sizes_large_jpeg_Read"] | undefined,
	update?: GraphQLTypes["MediaFields_sizes_large_jpeg_Update"] | undefined,
	delete?: GraphQLTypes["MediaFields_sizes_large_jpeg_Delete"] | undefined,
	fields?: GraphQLTypes["MediaFields_sizes_large_jpeg_Fields"] | undefined
};
	["MediaFields_sizes_large_jpeg_Create"]: {
	__typename: "MediaFields_sizes_large_jpeg_Create",
	permission: boolean
};
	["MediaFields_sizes_large_jpeg_Read"]: {
	__typename: "MediaFields_sizes_large_jpeg_Read",
	permission: boolean
};
	["MediaFields_sizes_large_jpeg_Update"]: {
	__typename: "MediaFields_sizes_large_jpeg_Update",
	permission: boolean
};
	["MediaFields_sizes_large_jpeg_Delete"]: {
	__typename: "MediaFields_sizes_large_jpeg_Delete",
	permission: boolean
};
	["MediaFields_sizes_large_jpeg_Fields"]: {
	__typename: "MediaFields_sizes_large_jpeg_Fields",
	url?: GraphQLTypes["MediaFields_sizes_large_jpeg_url"] | undefined,
	width?: GraphQLTypes["MediaFields_sizes_large_jpeg_width"] | undefined,
	height?: GraphQLTypes["MediaFields_sizes_large_jpeg_height"] | undefined,
	mimeType?: GraphQLTypes["MediaFields_sizes_large_jpeg_mimeType"] | undefined,
	filesize?: GraphQLTypes["MediaFields_sizes_large_jpeg_filesize"] | undefined,
	filename?: GraphQLTypes["MediaFields_sizes_large_jpeg_filename"] | undefined
};
	["MediaFields_sizes_large_jpeg_url"]: {
	__typename: "MediaFields_sizes_large_jpeg_url",
	create?: GraphQLTypes["MediaFields_sizes_large_jpeg_url_Create"] | undefined,
	read?: GraphQLTypes["MediaFields_sizes_large_jpeg_url_Read"] | undefined,
	update?: GraphQLTypes["MediaFields_sizes_large_jpeg_url_Update"] | undefined,
	delete?: GraphQLTypes["MediaFields_sizes_large_jpeg_url_Delete"] | undefined
};
	["MediaFields_sizes_large_jpeg_url_Create"]: {
	__typename: "MediaFields_sizes_large_jpeg_url_Create",
	permission: boolean
};
	["MediaFields_sizes_large_jpeg_url_Read"]: {
	__typename: "MediaFields_sizes_large_jpeg_url_Read",
	permission: boolean
};
	["MediaFields_sizes_large_jpeg_url_Update"]: {
	__typename: "MediaFields_sizes_large_jpeg_url_Update",
	permission: boolean
};
	["MediaFields_sizes_large_jpeg_url_Delete"]: {
	__typename: "MediaFields_sizes_large_jpeg_url_Delete",
	permission: boolean
};
	["MediaFields_sizes_large_jpeg_width"]: {
	__typename: "MediaFields_sizes_large_jpeg_width",
	create?: GraphQLTypes["MediaFields_sizes_large_jpeg_width_Create"] | undefined,
	read?: GraphQLTypes["MediaFields_sizes_large_jpeg_width_Read"] | undefined,
	update?: GraphQLTypes["MediaFields_sizes_large_jpeg_width_Update"] | undefined,
	delete?: GraphQLTypes["MediaFields_sizes_large_jpeg_width_Delete"] | undefined
};
	["MediaFields_sizes_large_jpeg_width_Create"]: {
	__typename: "MediaFields_sizes_large_jpeg_width_Create",
	permission: boolean
};
	["MediaFields_sizes_large_jpeg_width_Read"]: {
	__typename: "MediaFields_sizes_large_jpeg_width_Read",
	permission: boolean
};
	["MediaFields_sizes_large_jpeg_width_Update"]: {
	__typename: "MediaFields_sizes_large_jpeg_width_Update",
	permission: boolean
};
	["MediaFields_sizes_large_jpeg_width_Delete"]: {
	__typename: "MediaFields_sizes_large_jpeg_width_Delete",
	permission: boolean
};
	["MediaFields_sizes_large_jpeg_height"]: {
	__typename: "MediaFields_sizes_large_jpeg_height",
	create?: GraphQLTypes["MediaFields_sizes_large_jpeg_height_Create"] | undefined,
	read?: GraphQLTypes["MediaFields_sizes_large_jpeg_height_Read"] | undefined,
	update?: GraphQLTypes["MediaFields_sizes_large_jpeg_height_Update"] | undefined,
	delete?: GraphQLTypes["MediaFields_sizes_large_jpeg_height_Delete"] | undefined
};
	["MediaFields_sizes_large_jpeg_height_Create"]: {
	__typename: "MediaFields_sizes_large_jpeg_height_Create",
	permission: boolean
};
	["MediaFields_sizes_large_jpeg_height_Read"]: {
	__typename: "MediaFields_sizes_large_jpeg_height_Read",
	permission: boolean
};
	["MediaFields_sizes_large_jpeg_height_Update"]: {
	__typename: "MediaFields_sizes_large_jpeg_height_Update",
	permission: boolean
};
	["MediaFields_sizes_large_jpeg_height_Delete"]: {
	__typename: "MediaFields_sizes_large_jpeg_height_Delete",
	permission: boolean
};
	["MediaFields_sizes_large_jpeg_mimeType"]: {
	__typename: "MediaFields_sizes_large_jpeg_mimeType",
	create?: GraphQLTypes["MediaFields_sizes_large_jpeg_mimeType_Create"] | undefined,
	read?: GraphQLTypes["MediaFields_sizes_large_jpeg_mimeType_Read"] | undefined,
	update?: GraphQLTypes["MediaFields_sizes_large_jpeg_mimeType_Update"] | undefined,
	delete?: GraphQLTypes["MediaFields_sizes_large_jpeg_mimeType_Delete"] | undefined
};
	["MediaFields_sizes_large_jpeg_mimeType_Create"]: {
	__typename: "MediaFields_sizes_large_jpeg_mimeType_Create",
	permission: boolean
};
	["MediaFields_sizes_large_jpeg_mimeType_Read"]: {
	__typename: "MediaFields_sizes_large_jpeg_mimeType_Read",
	permission: boolean
};
	["MediaFields_sizes_large_jpeg_mimeType_Update"]: {
	__typename: "MediaFields_sizes_large_jpeg_mimeType_Update",
	permission: boolean
};
	["MediaFields_sizes_large_jpeg_mimeType_Delete"]: {
	__typename: "MediaFields_sizes_large_jpeg_mimeType_Delete",
	permission: boolean
};
	["MediaFields_sizes_large_jpeg_filesize"]: {
	__typename: "MediaFields_sizes_large_jpeg_filesize",
	create?: GraphQLTypes["MediaFields_sizes_large_jpeg_filesize_Create"] | undefined,
	read?: GraphQLTypes["MediaFields_sizes_large_jpeg_filesize_Read"] | undefined,
	update?: GraphQLTypes["MediaFields_sizes_large_jpeg_filesize_Update"] | undefined,
	delete?: GraphQLTypes["MediaFields_sizes_large_jpeg_filesize_Delete"] | undefined
};
	["MediaFields_sizes_large_jpeg_filesize_Create"]: {
	__typename: "MediaFields_sizes_large_jpeg_filesize_Create",
	permission: boolean
};
	["MediaFields_sizes_large_jpeg_filesize_Read"]: {
	__typename: "MediaFields_sizes_large_jpeg_filesize_Read",
	permission: boolean
};
	["MediaFields_sizes_large_jpeg_filesize_Update"]: {
	__typename: "MediaFields_sizes_large_jpeg_filesize_Update",
	permission: boolean
};
	["MediaFields_sizes_large_jpeg_filesize_Delete"]: {
	__typename: "MediaFields_sizes_large_jpeg_filesize_Delete",
	permission: boolean
};
	["MediaFields_sizes_large_jpeg_filename"]: {
	__typename: "MediaFields_sizes_large_jpeg_filename",
	create?: GraphQLTypes["MediaFields_sizes_large_jpeg_filename_Create"] | undefined,
	read?: GraphQLTypes["MediaFields_sizes_large_jpeg_filename_Read"] | undefined,
	update?: GraphQLTypes["MediaFields_sizes_large_jpeg_filename_Update"] | undefined,
	delete?: GraphQLTypes["MediaFields_sizes_large_jpeg_filename_Delete"] | undefined
};
	["MediaFields_sizes_large_jpeg_filename_Create"]: {
	__typename: "MediaFields_sizes_large_jpeg_filename_Create",
	permission: boolean
};
	["MediaFields_sizes_large_jpeg_filename_Read"]: {
	__typename: "MediaFields_sizes_large_jpeg_filename_Read",
	permission: boolean
};
	["MediaFields_sizes_large_jpeg_filename_Update"]: {
	__typename: "MediaFields_sizes_large_jpeg_filename_Update",
	permission: boolean
};
	["MediaFields_sizes_large_jpeg_filename_Delete"]: {
	__typename: "MediaFields_sizes_large_jpeg_filename_Delete",
	permission: boolean
};
	["MediaCreateAccess"]: {
	__typename: "MediaCreateAccess",
	permission: boolean,
	where?: GraphQLTypes["JSONObject"] | undefined
};
	["MediaReadAccess"]: {
	__typename: "MediaReadAccess",
	permission: boolean,
	where?: GraphQLTypes["JSONObject"] | undefined
};
	["MediaUpdateAccess"]: {
	__typename: "MediaUpdateAccess",
	permission: boolean,
	where?: GraphQLTypes["JSONObject"] | undefined
};
	["MediaDeleteAccess"]: {
	__typename: "MediaDeleteAccess",
	permission: boolean,
	where?: GraphQLTypes["JSONObject"] | undefined
};
	["backupMediaAccess"]: {
	__typename: "backupMediaAccess",
	fields?: GraphQLTypes["BackupMediaFields"] | undefined,
	create?: GraphQLTypes["BackupMediaCreateAccess"] | undefined,
	read?: GraphQLTypes["BackupMediaReadAccess"] | undefined,
	update?: GraphQLTypes["BackupMediaUpdateAccess"] | undefined,
	delete?: GraphQLTypes["BackupMediaDeleteAccess"] | undefined
};
	["BackupMediaFields"]: {
	__typename: "BackupMediaFields",
	alt?: GraphQLTypes["BackupMediaFields_alt"] | undefined,
	creator?: GraphQLTypes["BackupMediaFields_creator"] | undefined,
	updatedAt?: GraphQLTypes["BackupMediaFields_updatedAt"] | undefined,
	createdAt?: GraphQLTypes["BackupMediaFields_createdAt"] | undefined,
	url?: GraphQLTypes["BackupMediaFields_url"] | undefined,
	filename?: GraphQLTypes["BackupMediaFields_filename"] | undefined,
	mimeType?: GraphQLTypes["BackupMediaFields_mimeType"] | undefined,
	filesize?: GraphQLTypes["BackupMediaFields_filesize"] | undefined,
	width?: GraphQLTypes["BackupMediaFields_width"] | undefined,
	height?: GraphQLTypes["BackupMediaFields_height"] | undefined,
	focalX?: GraphQLTypes["BackupMediaFields_focalX"] | undefined,
	focalY?: GraphQLTypes["BackupMediaFields_focalY"] | undefined,
	sizes?: GraphQLTypes["BackupMediaFields_sizes"] | undefined
};
	["BackupMediaFields_alt"]: {
	__typename: "BackupMediaFields_alt",
	create?: GraphQLTypes["BackupMediaFields_alt_Create"] | undefined,
	read?: GraphQLTypes["BackupMediaFields_alt_Read"] | undefined,
	update?: GraphQLTypes["BackupMediaFields_alt_Update"] | undefined,
	delete?: GraphQLTypes["BackupMediaFields_alt_Delete"] | undefined
};
	["BackupMediaFields_alt_Create"]: {
	__typename: "BackupMediaFields_alt_Create",
	permission: boolean
};
	["BackupMediaFields_alt_Read"]: {
	__typename: "BackupMediaFields_alt_Read",
	permission: boolean
};
	["BackupMediaFields_alt_Update"]: {
	__typename: "BackupMediaFields_alt_Update",
	permission: boolean
};
	["BackupMediaFields_alt_Delete"]: {
	__typename: "BackupMediaFields_alt_Delete",
	permission: boolean
};
	["BackupMediaFields_creator"]: {
	__typename: "BackupMediaFields_creator",
	create?: GraphQLTypes["BackupMediaFields_creator_Create"] | undefined,
	read?: GraphQLTypes["BackupMediaFields_creator_Read"] | undefined,
	update?: GraphQLTypes["BackupMediaFields_creator_Update"] | undefined,
	delete?: GraphQLTypes["BackupMediaFields_creator_Delete"] | undefined
};
	["BackupMediaFields_creator_Create"]: {
	__typename: "BackupMediaFields_creator_Create",
	permission: boolean
};
	["BackupMediaFields_creator_Read"]: {
	__typename: "BackupMediaFields_creator_Read",
	permission: boolean
};
	["BackupMediaFields_creator_Update"]: {
	__typename: "BackupMediaFields_creator_Update",
	permission: boolean
};
	["BackupMediaFields_creator_Delete"]: {
	__typename: "BackupMediaFields_creator_Delete",
	permission: boolean
};
	["BackupMediaFields_updatedAt"]: {
	__typename: "BackupMediaFields_updatedAt",
	create?: GraphQLTypes["BackupMediaFields_updatedAt_Create"] | undefined,
	read?: GraphQLTypes["BackupMediaFields_updatedAt_Read"] | undefined,
	update?: GraphQLTypes["BackupMediaFields_updatedAt_Update"] | undefined,
	delete?: GraphQLTypes["BackupMediaFields_updatedAt_Delete"] | undefined
};
	["BackupMediaFields_updatedAt_Create"]: {
	__typename: "BackupMediaFields_updatedAt_Create",
	permission: boolean
};
	["BackupMediaFields_updatedAt_Read"]: {
	__typename: "BackupMediaFields_updatedAt_Read",
	permission: boolean
};
	["BackupMediaFields_updatedAt_Update"]: {
	__typename: "BackupMediaFields_updatedAt_Update",
	permission: boolean
};
	["BackupMediaFields_updatedAt_Delete"]: {
	__typename: "BackupMediaFields_updatedAt_Delete",
	permission: boolean
};
	["BackupMediaFields_createdAt"]: {
	__typename: "BackupMediaFields_createdAt",
	create?: GraphQLTypes["BackupMediaFields_createdAt_Create"] | undefined,
	read?: GraphQLTypes["BackupMediaFields_createdAt_Read"] | undefined,
	update?: GraphQLTypes["BackupMediaFields_createdAt_Update"] | undefined,
	delete?: GraphQLTypes["BackupMediaFields_createdAt_Delete"] | undefined
};
	["BackupMediaFields_createdAt_Create"]: {
	__typename: "BackupMediaFields_createdAt_Create",
	permission: boolean
};
	["BackupMediaFields_createdAt_Read"]: {
	__typename: "BackupMediaFields_createdAt_Read",
	permission: boolean
};
	["BackupMediaFields_createdAt_Update"]: {
	__typename: "BackupMediaFields_createdAt_Update",
	permission: boolean
};
	["BackupMediaFields_createdAt_Delete"]: {
	__typename: "BackupMediaFields_createdAt_Delete",
	permission: boolean
};
	["BackupMediaFields_url"]: {
	__typename: "BackupMediaFields_url",
	create?: GraphQLTypes["BackupMediaFields_url_Create"] | undefined,
	read?: GraphQLTypes["BackupMediaFields_url_Read"] | undefined,
	update?: GraphQLTypes["BackupMediaFields_url_Update"] | undefined,
	delete?: GraphQLTypes["BackupMediaFields_url_Delete"] | undefined
};
	["BackupMediaFields_url_Create"]: {
	__typename: "BackupMediaFields_url_Create",
	permission: boolean
};
	["BackupMediaFields_url_Read"]: {
	__typename: "BackupMediaFields_url_Read",
	permission: boolean
};
	["BackupMediaFields_url_Update"]: {
	__typename: "BackupMediaFields_url_Update",
	permission: boolean
};
	["BackupMediaFields_url_Delete"]: {
	__typename: "BackupMediaFields_url_Delete",
	permission: boolean
};
	["BackupMediaFields_filename"]: {
	__typename: "BackupMediaFields_filename",
	create?: GraphQLTypes["BackupMediaFields_filename_Create"] | undefined,
	read?: GraphQLTypes["BackupMediaFields_filename_Read"] | undefined,
	update?: GraphQLTypes["BackupMediaFields_filename_Update"] | undefined,
	delete?: GraphQLTypes["BackupMediaFields_filename_Delete"] | undefined
};
	["BackupMediaFields_filename_Create"]: {
	__typename: "BackupMediaFields_filename_Create",
	permission: boolean
};
	["BackupMediaFields_filename_Read"]: {
	__typename: "BackupMediaFields_filename_Read",
	permission: boolean
};
	["BackupMediaFields_filename_Update"]: {
	__typename: "BackupMediaFields_filename_Update",
	permission: boolean
};
	["BackupMediaFields_filename_Delete"]: {
	__typename: "BackupMediaFields_filename_Delete",
	permission: boolean
};
	["BackupMediaFields_mimeType"]: {
	__typename: "BackupMediaFields_mimeType",
	create?: GraphQLTypes["BackupMediaFields_mimeType_Create"] | undefined,
	read?: GraphQLTypes["BackupMediaFields_mimeType_Read"] | undefined,
	update?: GraphQLTypes["BackupMediaFields_mimeType_Update"] | undefined,
	delete?: GraphQLTypes["BackupMediaFields_mimeType_Delete"] | undefined
};
	["BackupMediaFields_mimeType_Create"]: {
	__typename: "BackupMediaFields_mimeType_Create",
	permission: boolean
};
	["BackupMediaFields_mimeType_Read"]: {
	__typename: "BackupMediaFields_mimeType_Read",
	permission: boolean
};
	["BackupMediaFields_mimeType_Update"]: {
	__typename: "BackupMediaFields_mimeType_Update",
	permission: boolean
};
	["BackupMediaFields_mimeType_Delete"]: {
	__typename: "BackupMediaFields_mimeType_Delete",
	permission: boolean
};
	["BackupMediaFields_filesize"]: {
	__typename: "BackupMediaFields_filesize",
	create?: GraphQLTypes["BackupMediaFields_filesize_Create"] | undefined,
	read?: GraphQLTypes["BackupMediaFields_filesize_Read"] | undefined,
	update?: GraphQLTypes["BackupMediaFields_filesize_Update"] | undefined,
	delete?: GraphQLTypes["BackupMediaFields_filesize_Delete"] | undefined
};
	["BackupMediaFields_filesize_Create"]: {
	__typename: "BackupMediaFields_filesize_Create",
	permission: boolean
};
	["BackupMediaFields_filesize_Read"]: {
	__typename: "BackupMediaFields_filesize_Read",
	permission: boolean
};
	["BackupMediaFields_filesize_Update"]: {
	__typename: "BackupMediaFields_filesize_Update",
	permission: boolean
};
	["BackupMediaFields_filesize_Delete"]: {
	__typename: "BackupMediaFields_filesize_Delete",
	permission: boolean
};
	["BackupMediaFields_width"]: {
	__typename: "BackupMediaFields_width",
	create?: GraphQLTypes["BackupMediaFields_width_Create"] | undefined,
	read?: GraphQLTypes["BackupMediaFields_width_Read"] | undefined,
	update?: GraphQLTypes["BackupMediaFields_width_Update"] | undefined,
	delete?: GraphQLTypes["BackupMediaFields_width_Delete"] | undefined
};
	["BackupMediaFields_width_Create"]: {
	__typename: "BackupMediaFields_width_Create",
	permission: boolean
};
	["BackupMediaFields_width_Read"]: {
	__typename: "BackupMediaFields_width_Read",
	permission: boolean
};
	["BackupMediaFields_width_Update"]: {
	__typename: "BackupMediaFields_width_Update",
	permission: boolean
};
	["BackupMediaFields_width_Delete"]: {
	__typename: "BackupMediaFields_width_Delete",
	permission: boolean
};
	["BackupMediaFields_height"]: {
	__typename: "BackupMediaFields_height",
	create?: GraphQLTypes["BackupMediaFields_height_Create"] | undefined,
	read?: GraphQLTypes["BackupMediaFields_height_Read"] | undefined,
	update?: GraphQLTypes["BackupMediaFields_height_Update"] | undefined,
	delete?: GraphQLTypes["BackupMediaFields_height_Delete"] | undefined
};
	["BackupMediaFields_height_Create"]: {
	__typename: "BackupMediaFields_height_Create",
	permission: boolean
};
	["BackupMediaFields_height_Read"]: {
	__typename: "BackupMediaFields_height_Read",
	permission: boolean
};
	["BackupMediaFields_height_Update"]: {
	__typename: "BackupMediaFields_height_Update",
	permission: boolean
};
	["BackupMediaFields_height_Delete"]: {
	__typename: "BackupMediaFields_height_Delete",
	permission: boolean
};
	["BackupMediaFields_focalX"]: {
	__typename: "BackupMediaFields_focalX",
	create?: GraphQLTypes["BackupMediaFields_focalX_Create"] | undefined,
	read?: GraphQLTypes["BackupMediaFields_focalX_Read"] | undefined,
	update?: GraphQLTypes["BackupMediaFields_focalX_Update"] | undefined,
	delete?: GraphQLTypes["BackupMediaFields_focalX_Delete"] | undefined
};
	["BackupMediaFields_focalX_Create"]: {
	__typename: "BackupMediaFields_focalX_Create",
	permission: boolean
};
	["BackupMediaFields_focalX_Read"]: {
	__typename: "BackupMediaFields_focalX_Read",
	permission: boolean
};
	["BackupMediaFields_focalX_Update"]: {
	__typename: "BackupMediaFields_focalX_Update",
	permission: boolean
};
	["BackupMediaFields_focalX_Delete"]: {
	__typename: "BackupMediaFields_focalX_Delete",
	permission: boolean
};
	["BackupMediaFields_focalY"]: {
	__typename: "BackupMediaFields_focalY",
	create?: GraphQLTypes["BackupMediaFields_focalY_Create"] | undefined,
	read?: GraphQLTypes["BackupMediaFields_focalY_Read"] | undefined,
	update?: GraphQLTypes["BackupMediaFields_focalY_Update"] | undefined,
	delete?: GraphQLTypes["BackupMediaFields_focalY_Delete"] | undefined
};
	["BackupMediaFields_focalY_Create"]: {
	__typename: "BackupMediaFields_focalY_Create",
	permission: boolean
};
	["BackupMediaFields_focalY_Read"]: {
	__typename: "BackupMediaFields_focalY_Read",
	permission: boolean
};
	["BackupMediaFields_focalY_Update"]: {
	__typename: "BackupMediaFields_focalY_Update",
	permission: boolean
};
	["BackupMediaFields_focalY_Delete"]: {
	__typename: "BackupMediaFields_focalY_Delete",
	permission: boolean
};
	["BackupMediaFields_sizes"]: {
	__typename: "BackupMediaFields_sizes",
	create?: GraphQLTypes["BackupMediaFields_sizes_Create"] | undefined,
	read?: GraphQLTypes["BackupMediaFields_sizes_Read"] | undefined,
	update?: GraphQLTypes["BackupMediaFields_sizes_Update"] | undefined,
	delete?: GraphQLTypes["BackupMediaFields_sizes_Delete"] | undefined,
	fields?: GraphQLTypes["BackupMediaFields_sizes_Fields"] | undefined
};
	["BackupMediaFields_sizes_Create"]: {
	__typename: "BackupMediaFields_sizes_Create",
	permission: boolean
};
	["BackupMediaFields_sizes_Read"]: {
	__typename: "BackupMediaFields_sizes_Read",
	permission: boolean
};
	["BackupMediaFields_sizes_Update"]: {
	__typename: "BackupMediaFields_sizes_Update",
	permission: boolean
};
	["BackupMediaFields_sizes_Delete"]: {
	__typename: "BackupMediaFields_sizes_Delete",
	permission: boolean
};
	["BackupMediaFields_sizes_Fields"]: {
	__typename: "BackupMediaFields_sizes_Fields",
	thumbnail?: GraphQLTypes["BackupMediaFields_sizes_thumbnail"] | undefined,
	sixteenByNineMedium?: GraphQLTypes["BackupMediaFields_sizes_sixteenByNineMedium"] | undefined
};
	["BackupMediaFields_sizes_thumbnail"]: {
	__typename: "BackupMediaFields_sizes_thumbnail",
	create?: GraphQLTypes["BackupMediaFields_sizes_thumbnail_Create"] | undefined,
	read?: GraphQLTypes["BackupMediaFields_sizes_thumbnail_Read"] | undefined,
	update?: GraphQLTypes["BackupMediaFields_sizes_thumbnail_Update"] | undefined,
	delete?: GraphQLTypes["BackupMediaFields_sizes_thumbnail_Delete"] | undefined,
	fields?: GraphQLTypes["BackupMediaFields_sizes_thumbnail_Fields"] | undefined
};
	["BackupMediaFields_sizes_thumbnail_Create"]: {
	__typename: "BackupMediaFields_sizes_thumbnail_Create",
	permission: boolean
};
	["BackupMediaFields_sizes_thumbnail_Read"]: {
	__typename: "BackupMediaFields_sizes_thumbnail_Read",
	permission: boolean
};
	["BackupMediaFields_sizes_thumbnail_Update"]: {
	__typename: "BackupMediaFields_sizes_thumbnail_Update",
	permission: boolean
};
	["BackupMediaFields_sizes_thumbnail_Delete"]: {
	__typename: "BackupMediaFields_sizes_thumbnail_Delete",
	permission: boolean
};
	["BackupMediaFields_sizes_thumbnail_Fields"]: {
	__typename: "BackupMediaFields_sizes_thumbnail_Fields",
	url?: GraphQLTypes["BackupMediaFields_sizes_thumbnail_url"] | undefined,
	width?: GraphQLTypes["BackupMediaFields_sizes_thumbnail_width"] | undefined,
	height?: GraphQLTypes["BackupMediaFields_sizes_thumbnail_height"] | undefined,
	mimeType?: GraphQLTypes["BackupMediaFields_sizes_thumbnail_mimeType"] | undefined,
	filesize?: GraphQLTypes["BackupMediaFields_sizes_thumbnail_filesize"] | undefined,
	filename?: GraphQLTypes["BackupMediaFields_sizes_thumbnail_filename"] | undefined
};
	["BackupMediaFields_sizes_thumbnail_url"]: {
	__typename: "BackupMediaFields_sizes_thumbnail_url",
	create?: GraphQLTypes["BackupMediaFields_sizes_thumbnail_url_Create"] | undefined,
	read?: GraphQLTypes["BackupMediaFields_sizes_thumbnail_url_Read"] | undefined,
	update?: GraphQLTypes["BackupMediaFields_sizes_thumbnail_url_Update"] | undefined,
	delete?: GraphQLTypes["BackupMediaFields_sizes_thumbnail_url_Delete"] | undefined
};
	["BackupMediaFields_sizes_thumbnail_url_Create"]: {
	__typename: "BackupMediaFields_sizes_thumbnail_url_Create",
	permission: boolean
};
	["BackupMediaFields_sizes_thumbnail_url_Read"]: {
	__typename: "BackupMediaFields_sizes_thumbnail_url_Read",
	permission: boolean
};
	["BackupMediaFields_sizes_thumbnail_url_Update"]: {
	__typename: "BackupMediaFields_sizes_thumbnail_url_Update",
	permission: boolean
};
	["BackupMediaFields_sizes_thumbnail_url_Delete"]: {
	__typename: "BackupMediaFields_sizes_thumbnail_url_Delete",
	permission: boolean
};
	["BackupMediaFields_sizes_thumbnail_width"]: {
	__typename: "BackupMediaFields_sizes_thumbnail_width",
	create?: GraphQLTypes["BackupMediaFields_sizes_thumbnail_width_Create"] | undefined,
	read?: GraphQLTypes["BackupMediaFields_sizes_thumbnail_width_Read"] | undefined,
	update?: GraphQLTypes["BackupMediaFields_sizes_thumbnail_width_Update"] | undefined,
	delete?: GraphQLTypes["BackupMediaFields_sizes_thumbnail_width_Delete"] | undefined
};
	["BackupMediaFields_sizes_thumbnail_width_Create"]: {
	__typename: "BackupMediaFields_sizes_thumbnail_width_Create",
	permission: boolean
};
	["BackupMediaFields_sizes_thumbnail_width_Read"]: {
	__typename: "BackupMediaFields_sizes_thumbnail_width_Read",
	permission: boolean
};
	["BackupMediaFields_sizes_thumbnail_width_Update"]: {
	__typename: "BackupMediaFields_sizes_thumbnail_width_Update",
	permission: boolean
};
	["BackupMediaFields_sizes_thumbnail_width_Delete"]: {
	__typename: "BackupMediaFields_sizes_thumbnail_width_Delete",
	permission: boolean
};
	["BackupMediaFields_sizes_thumbnail_height"]: {
	__typename: "BackupMediaFields_sizes_thumbnail_height",
	create?: GraphQLTypes["BackupMediaFields_sizes_thumbnail_height_Create"] | undefined,
	read?: GraphQLTypes["BackupMediaFields_sizes_thumbnail_height_Read"] | undefined,
	update?: GraphQLTypes["BackupMediaFields_sizes_thumbnail_height_Update"] | undefined,
	delete?: GraphQLTypes["BackupMediaFields_sizes_thumbnail_height_Delete"] | undefined
};
	["BackupMediaFields_sizes_thumbnail_height_Create"]: {
	__typename: "BackupMediaFields_sizes_thumbnail_height_Create",
	permission: boolean
};
	["BackupMediaFields_sizes_thumbnail_height_Read"]: {
	__typename: "BackupMediaFields_sizes_thumbnail_height_Read",
	permission: boolean
};
	["BackupMediaFields_sizes_thumbnail_height_Update"]: {
	__typename: "BackupMediaFields_sizes_thumbnail_height_Update",
	permission: boolean
};
	["BackupMediaFields_sizes_thumbnail_height_Delete"]: {
	__typename: "BackupMediaFields_sizes_thumbnail_height_Delete",
	permission: boolean
};
	["BackupMediaFields_sizes_thumbnail_mimeType"]: {
	__typename: "BackupMediaFields_sizes_thumbnail_mimeType",
	create?: GraphQLTypes["BackupMediaFields_sizes_thumbnail_mimeType_Create"] | undefined,
	read?: GraphQLTypes["BackupMediaFields_sizes_thumbnail_mimeType_Read"] | undefined,
	update?: GraphQLTypes["BackupMediaFields_sizes_thumbnail_mimeType_Update"] | undefined,
	delete?: GraphQLTypes["BackupMediaFields_sizes_thumbnail_mimeType_Delete"] | undefined
};
	["BackupMediaFields_sizes_thumbnail_mimeType_Create"]: {
	__typename: "BackupMediaFields_sizes_thumbnail_mimeType_Create",
	permission: boolean
};
	["BackupMediaFields_sizes_thumbnail_mimeType_Read"]: {
	__typename: "BackupMediaFields_sizes_thumbnail_mimeType_Read",
	permission: boolean
};
	["BackupMediaFields_sizes_thumbnail_mimeType_Update"]: {
	__typename: "BackupMediaFields_sizes_thumbnail_mimeType_Update",
	permission: boolean
};
	["BackupMediaFields_sizes_thumbnail_mimeType_Delete"]: {
	__typename: "BackupMediaFields_sizes_thumbnail_mimeType_Delete",
	permission: boolean
};
	["BackupMediaFields_sizes_thumbnail_filesize"]: {
	__typename: "BackupMediaFields_sizes_thumbnail_filesize",
	create?: GraphQLTypes["BackupMediaFields_sizes_thumbnail_filesize_Create"] | undefined,
	read?: GraphQLTypes["BackupMediaFields_sizes_thumbnail_filesize_Read"] | undefined,
	update?: GraphQLTypes["BackupMediaFields_sizes_thumbnail_filesize_Update"] | undefined,
	delete?: GraphQLTypes["BackupMediaFields_sizes_thumbnail_filesize_Delete"] | undefined
};
	["BackupMediaFields_sizes_thumbnail_filesize_Create"]: {
	__typename: "BackupMediaFields_sizes_thumbnail_filesize_Create",
	permission: boolean
};
	["BackupMediaFields_sizes_thumbnail_filesize_Read"]: {
	__typename: "BackupMediaFields_sizes_thumbnail_filesize_Read",
	permission: boolean
};
	["BackupMediaFields_sizes_thumbnail_filesize_Update"]: {
	__typename: "BackupMediaFields_sizes_thumbnail_filesize_Update",
	permission: boolean
};
	["BackupMediaFields_sizes_thumbnail_filesize_Delete"]: {
	__typename: "BackupMediaFields_sizes_thumbnail_filesize_Delete",
	permission: boolean
};
	["BackupMediaFields_sizes_thumbnail_filename"]: {
	__typename: "BackupMediaFields_sizes_thumbnail_filename",
	create?: GraphQLTypes["BackupMediaFields_sizes_thumbnail_filename_Create"] | undefined,
	read?: GraphQLTypes["BackupMediaFields_sizes_thumbnail_filename_Read"] | undefined,
	update?: GraphQLTypes["BackupMediaFields_sizes_thumbnail_filename_Update"] | undefined,
	delete?: GraphQLTypes["BackupMediaFields_sizes_thumbnail_filename_Delete"] | undefined
};
	["BackupMediaFields_sizes_thumbnail_filename_Create"]: {
	__typename: "BackupMediaFields_sizes_thumbnail_filename_Create",
	permission: boolean
};
	["BackupMediaFields_sizes_thumbnail_filename_Read"]: {
	__typename: "BackupMediaFields_sizes_thumbnail_filename_Read",
	permission: boolean
};
	["BackupMediaFields_sizes_thumbnail_filename_Update"]: {
	__typename: "BackupMediaFields_sizes_thumbnail_filename_Update",
	permission: boolean
};
	["BackupMediaFields_sizes_thumbnail_filename_Delete"]: {
	__typename: "BackupMediaFields_sizes_thumbnail_filename_Delete",
	permission: boolean
};
	["BackupMediaFields_sizes_sixteenByNineMedium"]: {
	__typename: "BackupMediaFields_sizes_sixteenByNineMedium",
	create?: GraphQLTypes["BackupMediaFields_sizes_sixteenByNineMedium_Create"] | undefined,
	read?: GraphQLTypes["BackupMediaFields_sizes_sixteenByNineMedium_Read"] | undefined,
	update?: GraphQLTypes["BackupMediaFields_sizes_sixteenByNineMedium_Update"] | undefined,
	delete?: GraphQLTypes["BackupMediaFields_sizes_sixteenByNineMedium_Delete"] | undefined,
	fields?: GraphQLTypes["BackupMediaFields_sizes_sixteenByNineMedium_Fields"] | undefined
};
	["BackupMediaFields_sizes_sixteenByNineMedium_Create"]: {
	__typename: "BackupMediaFields_sizes_sixteenByNineMedium_Create",
	permission: boolean
};
	["BackupMediaFields_sizes_sixteenByNineMedium_Read"]: {
	__typename: "BackupMediaFields_sizes_sixteenByNineMedium_Read",
	permission: boolean
};
	["BackupMediaFields_sizes_sixteenByNineMedium_Update"]: {
	__typename: "BackupMediaFields_sizes_sixteenByNineMedium_Update",
	permission: boolean
};
	["BackupMediaFields_sizes_sixteenByNineMedium_Delete"]: {
	__typename: "BackupMediaFields_sizes_sixteenByNineMedium_Delete",
	permission: boolean
};
	["BackupMediaFields_sizes_sixteenByNineMedium_Fields"]: {
	__typename: "BackupMediaFields_sizes_sixteenByNineMedium_Fields",
	url?: GraphQLTypes["BackupMediaFields_sizes_sixteenByNineMedium_url"] | undefined,
	width?: GraphQLTypes["BackupMediaFields_sizes_sixteenByNineMedium_width"] | undefined,
	height?: GraphQLTypes["BackupMediaFields_sizes_sixteenByNineMedium_height"] | undefined,
	mimeType?: GraphQLTypes["BackupMediaFields_sizes_sixteenByNineMedium_mimeType"] | undefined,
	filesize?: GraphQLTypes["BackupMediaFields_sizes_sixteenByNineMedium_filesize"] | undefined,
	filename?: GraphQLTypes["BackupMediaFields_sizes_sixteenByNineMedium_filename"] | undefined
};
	["BackupMediaFields_sizes_sixteenByNineMedium_url"]: {
	__typename: "BackupMediaFields_sizes_sixteenByNineMedium_url",
	create?: GraphQLTypes["BackupMediaFields_sizes_sixteenByNineMedium_url_Create"] | undefined,
	read?: GraphQLTypes["BackupMediaFields_sizes_sixteenByNineMedium_url_Read"] | undefined,
	update?: GraphQLTypes["BackupMediaFields_sizes_sixteenByNineMedium_url_Update"] | undefined,
	delete?: GraphQLTypes["BackupMediaFields_sizes_sixteenByNineMedium_url_Delete"] | undefined
};
	["BackupMediaFields_sizes_sixteenByNineMedium_url_Create"]: {
	__typename: "BackupMediaFields_sizes_sixteenByNineMedium_url_Create",
	permission: boolean
};
	["BackupMediaFields_sizes_sixteenByNineMedium_url_Read"]: {
	__typename: "BackupMediaFields_sizes_sixteenByNineMedium_url_Read",
	permission: boolean
};
	["BackupMediaFields_sizes_sixteenByNineMedium_url_Update"]: {
	__typename: "BackupMediaFields_sizes_sixteenByNineMedium_url_Update",
	permission: boolean
};
	["BackupMediaFields_sizes_sixteenByNineMedium_url_Delete"]: {
	__typename: "BackupMediaFields_sizes_sixteenByNineMedium_url_Delete",
	permission: boolean
};
	["BackupMediaFields_sizes_sixteenByNineMedium_width"]: {
	__typename: "BackupMediaFields_sizes_sixteenByNineMedium_width",
	create?: GraphQLTypes["BackupMediaFields_sizes_sixteenByNineMedium_width_Create"] | undefined,
	read?: GraphQLTypes["BackupMediaFields_sizes_sixteenByNineMedium_width_Read"] | undefined,
	update?: GraphQLTypes["BackupMediaFields_sizes_sixteenByNineMedium_width_Update"] | undefined,
	delete?: GraphQLTypes["BackupMediaFields_sizes_sixteenByNineMedium_width_Delete"] | undefined
};
	["BackupMediaFields_sizes_sixteenByNineMedium_width_Create"]: {
	__typename: "BackupMediaFields_sizes_sixteenByNineMedium_width_Create",
	permission: boolean
};
	["BackupMediaFields_sizes_sixteenByNineMedium_width_Read"]: {
	__typename: "BackupMediaFields_sizes_sixteenByNineMedium_width_Read",
	permission: boolean
};
	["BackupMediaFields_sizes_sixteenByNineMedium_width_Update"]: {
	__typename: "BackupMediaFields_sizes_sixteenByNineMedium_width_Update",
	permission: boolean
};
	["BackupMediaFields_sizes_sixteenByNineMedium_width_Delete"]: {
	__typename: "BackupMediaFields_sizes_sixteenByNineMedium_width_Delete",
	permission: boolean
};
	["BackupMediaFields_sizes_sixteenByNineMedium_height"]: {
	__typename: "BackupMediaFields_sizes_sixteenByNineMedium_height",
	create?: GraphQLTypes["BackupMediaFields_sizes_sixteenByNineMedium_height_Create"] | undefined,
	read?: GraphQLTypes["BackupMediaFields_sizes_sixteenByNineMedium_height_Read"] | undefined,
	update?: GraphQLTypes["BackupMediaFields_sizes_sixteenByNineMedium_height_Update"] | undefined,
	delete?: GraphQLTypes["BackupMediaFields_sizes_sixteenByNineMedium_height_Delete"] | undefined
};
	["BackupMediaFields_sizes_sixteenByNineMedium_height_Create"]: {
	__typename: "BackupMediaFields_sizes_sixteenByNineMedium_height_Create",
	permission: boolean
};
	["BackupMediaFields_sizes_sixteenByNineMedium_height_Read"]: {
	__typename: "BackupMediaFields_sizes_sixteenByNineMedium_height_Read",
	permission: boolean
};
	["BackupMediaFields_sizes_sixteenByNineMedium_height_Update"]: {
	__typename: "BackupMediaFields_sizes_sixteenByNineMedium_height_Update",
	permission: boolean
};
	["BackupMediaFields_sizes_sixteenByNineMedium_height_Delete"]: {
	__typename: "BackupMediaFields_sizes_sixteenByNineMedium_height_Delete",
	permission: boolean
};
	["BackupMediaFields_sizes_sixteenByNineMedium_mimeType"]: {
	__typename: "BackupMediaFields_sizes_sixteenByNineMedium_mimeType",
	create?: GraphQLTypes["BackupMediaFields_sizes_sixteenByNineMedium_mimeType_Create"] | undefined,
	read?: GraphQLTypes["BackupMediaFields_sizes_sixteenByNineMedium_mimeType_Read"] | undefined,
	update?: GraphQLTypes["BackupMediaFields_sizes_sixteenByNineMedium_mimeType_Update"] | undefined,
	delete?: GraphQLTypes["BackupMediaFields_sizes_sixteenByNineMedium_mimeType_Delete"] | undefined
};
	["BackupMediaFields_sizes_sixteenByNineMedium_mimeType_Create"]: {
	__typename: "BackupMediaFields_sizes_sixteenByNineMedium_mimeType_Create",
	permission: boolean
};
	["BackupMediaFields_sizes_sixteenByNineMedium_mimeType_Read"]: {
	__typename: "BackupMediaFields_sizes_sixteenByNineMedium_mimeType_Read",
	permission: boolean
};
	["BackupMediaFields_sizes_sixteenByNineMedium_mimeType_Update"]: {
	__typename: "BackupMediaFields_sizes_sixteenByNineMedium_mimeType_Update",
	permission: boolean
};
	["BackupMediaFields_sizes_sixteenByNineMedium_mimeType_Delete"]: {
	__typename: "BackupMediaFields_sizes_sixteenByNineMedium_mimeType_Delete",
	permission: boolean
};
	["BackupMediaFields_sizes_sixteenByNineMedium_filesize"]: {
	__typename: "BackupMediaFields_sizes_sixteenByNineMedium_filesize",
	create?: GraphQLTypes["BackupMediaFields_sizes_sixteenByNineMedium_filesize_Create"] | undefined,
	read?: GraphQLTypes["BackupMediaFields_sizes_sixteenByNineMedium_filesize_Read"] | undefined,
	update?: GraphQLTypes["BackupMediaFields_sizes_sixteenByNineMedium_filesize_Update"] | undefined,
	delete?: GraphQLTypes["BackupMediaFields_sizes_sixteenByNineMedium_filesize_Delete"] | undefined
};
	["BackupMediaFields_sizes_sixteenByNineMedium_filesize_Create"]: {
	__typename: "BackupMediaFields_sizes_sixteenByNineMedium_filesize_Create",
	permission: boolean
};
	["BackupMediaFields_sizes_sixteenByNineMedium_filesize_Read"]: {
	__typename: "BackupMediaFields_sizes_sixteenByNineMedium_filesize_Read",
	permission: boolean
};
	["BackupMediaFields_sizes_sixteenByNineMedium_filesize_Update"]: {
	__typename: "BackupMediaFields_sizes_sixteenByNineMedium_filesize_Update",
	permission: boolean
};
	["BackupMediaFields_sizes_sixteenByNineMedium_filesize_Delete"]: {
	__typename: "BackupMediaFields_sizes_sixteenByNineMedium_filesize_Delete",
	permission: boolean
};
	["BackupMediaFields_sizes_sixteenByNineMedium_filename"]: {
	__typename: "BackupMediaFields_sizes_sixteenByNineMedium_filename",
	create?: GraphQLTypes["BackupMediaFields_sizes_sixteenByNineMedium_filename_Create"] | undefined,
	read?: GraphQLTypes["BackupMediaFields_sizes_sixteenByNineMedium_filename_Read"] | undefined,
	update?: GraphQLTypes["BackupMediaFields_sizes_sixteenByNineMedium_filename_Update"] | undefined,
	delete?: GraphQLTypes["BackupMediaFields_sizes_sixteenByNineMedium_filename_Delete"] | undefined
};
	["BackupMediaFields_sizes_sixteenByNineMedium_filename_Create"]: {
	__typename: "BackupMediaFields_sizes_sixteenByNineMedium_filename_Create",
	permission: boolean
};
	["BackupMediaFields_sizes_sixteenByNineMedium_filename_Read"]: {
	__typename: "BackupMediaFields_sizes_sixteenByNineMedium_filename_Read",
	permission: boolean
};
	["BackupMediaFields_sizes_sixteenByNineMedium_filename_Update"]: {
	__typename: "BackupMediaFields_sizes_sixteenByNineMedium_filename_Update",
	permission: boolean
};
	["BackupMediaFields_sizes_sixteenByNineMedium_filename_Delete"]: {
	__typename: "BackupMediaFields_sizes_sixteenByNineMedium_filename_Delete",
	permission: boolean
};
	["BackupMediaCreateAccess"]: {
	__typename: "BackupMediaCreateAccess",
	permission: boolean,
	where?: GraphQLTypes["JSONObject"] | undefined
};
	["BackupMediaReadAccess"]: {
	__typename: "BackupMediaReadAccess",
	permission: boolean,
	where?: GraphQLTypes["JSONObject"] | undefined
};
	["BackupMediaUpdateAccess"]: {
	__typename: "BackupMediaUpdateAccess",
	permission: boolean,
	where?: GraphQLTypes["JSONObject"] | undefined
};
	["BackupMediaDeleteAccess"]: {
	__typename: "BackupMediaDeleteAccess",
	permission: boolean,
	where?: GraphQLTypes["JSONObject"] | undefined
};
	["account_suspend_requestsAccess"]: {
	__typename: "account_suspend_requestsAccess",
	fields?: GraphQLTypes["AccountSuspendRequestsFields"] | undefined,
	create?: GraphQLTypes["AccountSuspendRequestsCreateAccess"] | undefined,
	read?: GraphQLTypes["AccountSuspendRequestsReadAccess"] | undefined,
	update?: GraphQLTypes["AccountSuspendRequestsUpdateAccess"] | undefined,
	delete?: GraphQLTypes["AccountSuspendRequestsDeleteAccess"] | undefined
};
	["AccountSuspendRequestsFields"]: {
	__typename: "AccountSuspendRequestsFields",
	user?: GraphQLTypes["AccountSuspendRequestsFields_user"] | undefined,
	handledBy?: GraphQLTypes["AccountSuspendRequestsFields_handledBy"] | undefined,
	updatedAt?: GraphQLTypes["AccountSuspendRequestsFields_updatedAt"] | undefined,
	createdAt?: GraphQLTypes["AccountSuspendRequestsFields_createdAt"] | undefined
};
	["AccountSuspendRequestsFields_user"]: {
	__typename: "AccountSuspendRequestsFields_user",
	create?: GraphQLTypes["AccountSuspendRequestsFields_user_Create"] | undefined,
	read?: GraphQLTypes["AccountSuspendRequestsFields_user_Read"] | undefined,
	update?: GraphQLTypes["AccountSuspendRequestsFields_user_Update"] | undefined,
	delete?: GraphQLTypes["AccountSuspendRequestsFields_user_Delete"] | undefined
};
	["AccountSuspendRequestsFields_user_Create"]: {
	__typename: "AccountSuspendRequestsFields_user_Create",
	permission: boolean
};
	["AccountSuspendRequestsFields_user_Read"]: {
	__typename: "AccountSuspendRequestsFields_user_Read",
	permission: boolean
};
	["AccountSuspendRequestsFields_user_Update"]: {
	__typename: "AccountSuspendRequestsFields_user_Update",
	permission: boolean
};
	["AccountSuspendRequestsFields_user_Delete"]: {
	__typename: "AccountSuspendRequestsFields_user_Delete",
	permission: boolean
};
	["AccountSuspendRequestsFields_handledBy"]: {
	__typename: "AccountSuspendRequestsFields_handledBy",
	create?: GraphQLTypes["AccountSuspendRequestsFields_handledBy_Create"] | undefined,
	read?: GraphQLTypes["AccountSuspendRequestsFields_handledBy_Read"] | undefined,
	update?: GraphQLTypes["AccountSuspendRequestsFields_handledBy_Update"] | undefined,
	delete?: GraphQLTypes["AccountSuspendRequestsFields_handledBy_Delete"] | undefined
};
	["AccountSuspendRequestsFields_handledBy_Create"]: {
	__typename: "AccountSuspendRequestsFields_handledBy_Create",
	permission: boolean
};
	["AccountSuspendRequestsFields_handledBy_Read"]: {
	__typename: "AccountSuspendRequestsFields_handledBy_Read",
	permission: boolean
};
	["AccountSuspendRequestsFields_handledBy_Update"]: {
	__typename: "AccountSuspendRequestsFields_handledBy_Update",
	permission: boolean
};
	["AccountSuspendRequestsFields_handledBy_Delete"]: {
	__typename: "AccountSuspendRequestsFields_handledBy_Delete",
	permission: boolean
};
	["AccountSuspendRequestsFields_updatedAt"]: {
	__typename: "AccountSuspendRequestsFields_updatedAt",
	create?: GraphQLTypes["AccountSuspendRequestsFields_updatedAt_Create"] | undefined,
	read?: GraphQLTypes["AccountSuspendRequestsFields_updatedAt_Read"] | undefined,
	update?: GraphQLTypes["AccountSuspendRequestsFields_updatedAt_Update"] | undefined,
	delete?: GraphQLTypes["AccountSuspendRequestsFields_updatedAt_Delete"] | undefined
};
	["AccountSuspendRequestsFields_updatedAt_Create"]: {
	__typename: "AccountSuspendRequestsFields_updatedAt_Create",
	permission: boolean
};
	["AccountSuspendRequestsFields_updatedAt_Read"]: {
	__typename: "AccountSuspendRequestsFields_updatedAt_Read",
	permission: boolean
};
	["AccountSuspendRequestsFields_updatedAt_Update"]: {
	__typename: "AccountSuspendRequestsFields_updatedAt_Update",
	permission: boolean
};
	["AccountSuspendRequestsFields_updatedAt_Delete"]: {
	__typename: "AccountSuspendRequestsFields_updatedAt_Delete",
	permission: boolean
};
	["AccountSuspendRequestsFields_createdAt"]: {
	__typename: "AccountSuspendRequestsFields_createdAt",
	create?: GraphQLTypes["AccountSuspendRequestsFields_createdAt_Create"] | undefined,
	read?: GraphQLTypes["AccountSuspendRequestsFields_createdAt_Read"] | undefined,
	update?: GraphQLTypes["AccountSuspendRequestsFields_createdAt_Update"] | undefined,
	delete?: GraphQLTypes["AccountSuspendRequestsFields_createdAt_Delete"] | undefined
};
	["AccountSuspendRequestsFields_createdAt_Create"]: {
	__typename: "AccountSuspendRequestsFields_createdAt_Create",
	permission: boolean
};
	["AccountSuspendRequestsFields_createdAt_Read"]: {
	__typename: "AccountSuspendRequestsFields_createdAt_Read",
	permission: boolean
};
	["AccountSuspendRequestsFields_createdAt_Update"]: {
	__typename: "AccountSuspendRequestsFields_createdAt_Update",
	permission: boolean
};
	["AccountSuspendRequestsFields_createdAt_Delete"]: {
	__typename: "AccountSuspendRequestsFields_createdAt_Delete",
	permission: boolean
};
	["AccountSuspendRequestsCreateAccess"]: {
	__typename: "AccountSuspendRequestsCreateAccess",
	permission: boolean,
	where?: GraphQLTypes["JSONObject"] | undefined
};
	["AccountSuspendRequestsReadAccess"]: {
	__typename: "AccountSuspendRequestsReadAccess",
	permission: boolean,
	where?: GraphQLTypes["JSONObject"] | undefined
};
	["AccountSuspendRequestsUpdateAccess"]: {
	__typename: "AccountSuspendRequestsUpdateAccess",
	permission: boolean,
	where?: GraphQLTypes["JSONObject"] | undefined
};
	["AccountSuspendRequestsDeleteAccess"]: {
	__typename: "AccountSuspendRequestsDeleteAccess",
	permission: boolean,
	where?: GraphQLTypes["JSONObject"] | undefined
};
	["devicesAccess"]: {
	__typename: "devicesAccess",
	fields?: GraphQLTypes["DevicesFields"] | undefined,
	create?: GraphQLTypes["DevicesCreateAccess"] | undefined,
	read?: GraphQLTypes["DevicesReadAccess"] | undefined,
	update?: GraphQLTypes["DevicesUpdateAccess"] | undefined,
	delete?: GraphQLTypes["DevicesDeleteAccess"] | undefined
};
	["DevicesFields"]: {
	__typename: "DevicesFields",
	user?: GraphQLTypes["DevicesFields_user"] | undefined,
	udid?: GraphQLTypes["DevicesFields_udid"] | undefined,
	pushToken?: GraphQLTypes["DevicesFields_pushToken"] | undefined,
	updatedAt?: GraphQLTypes["DevicesFields_updatedAt"] | undefined,
	createdAt?: GraphQLTypes["DevicesFields_createdAt"] | undefined
};
	["DevicesFields_user"]: {
	__typename: "DevicesFields_user",
	create?: GraphQLTypes["DevicesFields_user_Create"] | undefined,
	read?: GraphQLTypes["DevicesFields_user_Read"] | undefined,
	update?: GraphQLTypes["DevicesFields_user_Update"] | undefined,
	delete?: GraphQLTypes["DevicesFields_user_Delete"] | undefined
};
	["DevicesFields_user_Create"]: {
	__typename: "DevicesFields_user_Create",
	permission: boolean
};
	["DevicesFields_user_Read"]: {
	__typename: "DevicesFields_user_Read",
	permission: boolean
};
	["DevicesFields_user_Update"]: {
	__typename: "DevicesFields_user_Update",
	permission: boolean
};
	["DevicesFields_user_Delete"]: {
	__typename: "DevicesFields_user_Delete",
	permission: boolean
};
	["DevicesFields_udid"]: {
	__typename: "DevicesFields_udid",
	create?: GraphQLTypes["DevicesFields_udid_Create"] | undefined,
	read?: GraphQLTypes["DevicesFields_udid_Read"] | undefined,
	update?: GraphQLTypes["DevicesFields_udid_Update"] | undefined,
	delete?: GraphQLTypes["DevicesFields_udid_Delete"] | undefined
};
	["DevicesFields_udid_Create"]: {
	__typename: "DevicesFields_udid_Create",
	permission: boolean
};
	["DevicesFields_udid_Read"]: {
	__typename: "DevicesFields_udid_Read",
	permission: boolean
};
	["DevicesFields_udid_Update"]: {
	__typename: "DevicesFields_udid_Update",
	permission: boolean
};
	["DevicesFields_udid_Delete"]: {
	__typename: "DevicesFields_udid_Delete",
	permission: boolean
};
	["DevicesFields_pushToken"]: {
	__typename: "DevicesFields_pushToken",
	create?: GraphQLTypes["DevicesFields_pushToken_Create"] | undefined,
	read?: GraphQLTypes["DevicesFields_pushToken_Read"] | undefined,
	update?: GraphQLTypes["DevicesFields_pushToken_Update"] | undefined,
	delete?: GraphQLTypes["DevicesFields_pushToken_Delete"] | undefined
};
	["DevicesFields_pushToken_Create"]: {
	__typename: "DevicesFields_pushToken_Create",
	permission: boolean
};
	["DevicesFields_pushToken_Read"]: {
	__typename: "DevicesFields_pushToken_Read",
	permission: boolean
};
	["DevicesFields_pushToken_Update"]: {
	__typename: "DevicesFields_pushToken_Update",
	permission: boolean
};
	["DevicesFields_pushToken_Delete"]: {
	__typename: "DevicesFields_pushToken_Delete",
	permission: boolean
};
	["DevicesFields_updatedAt"]: {
	__typename: "DevicesFields_updatedAt",
	create?: GraphQLTypes["DevicesFields_updatedAt_Create"] | undefined,
	read?: GraphQLTypes["DevicesFields_updatedAt_Read"] | undefined,
	update?: GraphQLTypes["DevicesFields_updatedAt_Update"] | undefined,
	delete?: GraphQLTypes["DevicesFields_updatedAt_Delete"] | undefined
};
	["DevicesFields_updatedAt_Create"]: {
	__typename: "DevicesFields_updatedAt_Create",
	permission: boolean
};
	["DevicesFields_updatedAt_Read"]: {
	__typename: "DevicesFields_updatedAt_Read",
	permission: boolean
};
	["DevicesFields_updatedAt_Update"]: {
	__typename: "DevicesFields_updatedAt_Update",
	permission: boolean
};
	["DevicesFields_updatedAt_Delete"]: {
	__typename: "DevicesFields_updatedAt_Delete",
	permission: boolean
};
	["DevicesFields_createdAt"]: {
	__typename: "DevicesFields_createdAt",
	create?: GraphQLTypes["DevicesFields_createdAt_Create"] | undefined,
	read?: GraphQLTypes["DevicesFields_createdAt_Read"] | undefined,
	update?: GraphQLTypes["DevicesFields_createdAt_Update"] | undefined,
	delete?: GraphQLTypes["DevicesFields_createdAt_Delete"] | undefined
};
	["DevicesFields_createdAt_Create"]: {
	__typename: "DevicesFields_createdAt_Create",
	permission: boolean
};
	["DevicesFields_createdAt_Read"]: {
	__typename: "DevicesFields_createdAt_Read",
	permission: boolean
};
	["DevicesFields_createdAt_Update"]: {
	__typename: "DevicesFields_createdAt_Update",
	permission: boolean
};
	["DevicesFields_createdAt_Delete"]: {
	__typename: "DevicesFields_createdAt_Delete",
	permission: boolean
};
	["DevicesCreateAccess"]: {
	__typename: "DevicesCreateAccess",
	permission: boolean,
	where?: GraphQLTypes["JSONObject"] | undefined
};
	["DevicesReadAccess"]: {
	__typename: "DevicesReadAccess",
	permission: boolean,
	where?: GraphQLTypes["JSONObject"] | undefined
};
	["DevicesUpdateAccess"]: {
	__typename: "DevicesUpdateAccess",
	permission: boolean,
	where?: GraphQLTypes["JSONObject"] | undefined
};
	["DevicesDeleteAccess"]: {
	__typename: "DevicesDeleteAccess",
	permission: boolean,
	where?: GraphQLTypes["JSONObject"] | undefined
};
	["notificationsAccess"]: {
	__typename: "notificationsAccess",
	fields?: GraphQLTypes["NotificationsFields"] | undefined,
	create?: GraphQLTypes["NotificationsCreateAccess"] | undefined,
	read?: GraphQLTypes["NotificationsReadAccess"] | undefined,
	update?: GraphQLTypes["NotificationsUpdateAccess"] | undefined,
	delete?: GraphQLTypes["NotificationsDeleteAccess"] | undefined
};
	["NotificationsFields"]: {
	__typename: "NotificationsFields",
	receiver?: GraphQLTypes["NotificationsFields_receiver"] | undefined,
	type?: GraphQLTypes["NotificationsFields_type"] | undefined,
	meta?: GraphQLTypes["NotificationsFields_meta"] | undefined,
	message?: GraphQLTypes["NotificationsFields_message"] | undefined,
	sender?: GraphQLTypes["NotificationsFields_sender"] | undefined,
	readed?: GraphQLTypes["NotificationsFields_readed"] | undefined,
	deletedByUser?: GraphQLTypes["NotificationsFields_deletedByUser"] | undefined,
	updatedAt?: GraphQLTypes["NotificationsFields_updatedAt"] | undefined,
	createdAt?: GraphQLTypes["NotificationsFields_createdAt"] | undefined
};
	["NotificationsFields_receiver"]: {
	__typename: "NotificationsFields_receiver",
	create?: GraphQLTypes["NotificationsFields_receiver_Create"] | undefined,
	read?: GraphQLTypes["NotificationsFields_receiver_Read"] | undefined,
	update?: GraphQLTypes["NotificationsFields_receiver_Update"] | undefined,
	delete?: GraphQLTypes["NotificationsFields_receiver_Delete"] | undefined
};
	["NotificationsFields_receiver_Create"]: {
	__typename: "NotificationsFields_receiver_Create",
	permission: boolean
};
	["NotificationsFields_receiver_Read"]: {
	__typename: "NotificationsFields_receiver_Read",
	permission: boolean
};
	["NotificationsFields_receiver_Update"]: {
	__typename: "NotificationsFields_receiver_Update",
	permission: boolean
};
	["NotificationsFields_receiver_Delete"]: {
	__typename: "NotificationsFields_receiver_Delete",
	permission: boolean
};
	["NotificationsFields_type"]: {
	__typename: "NotificationsFields_type",
	create?: GraphQLTypes["NotificationsFields_type_Create"] | undefined,
	read?: GraphQLTypes["NotificationsFields_type_Read"] | undefined,
	update?: GraphQLTypes["NotificationsFields_type_Update"] | undefined,
	delete?: GraphQLTypes["NotificationsFields_type_Delete"] | undefined
};
	["NotificationsFields_type_Create"]: {
	__typename: "NotificationsFields_type_Create",
	permission: boolean
};
	["NotificationsFields_type_Read"]: {
	__typename: "NotificationsFields_type_Read",
	permission: boolean
};
	["NotificationsFields_type_Update"]: {
	__typename: "NotificationsFields_type_Update",
	permission: boolean
};
	["NotificationsFields_type_Delete"]: {
	__typename: "NotificationsFields_type_Delete",
	permission: boolean
};
	["NotificationsFields_meta"]: {
	__typename: "NotificationsFields_meta",
	create?: GraphQLTypes["NotificationsFields_meta_Create"] | undefined,
	read?: GraphQLTypes["NotificationsFields_meta_Read"] | undefined,
	update?: GraphQLTypes["NotificationsFields_meta_Update"] | undefined,
	delete?: GraphQLTypes["NotificationsFields_meta_Delete"] | undefined
};
	["NotificationsFields_meta_Create"]: {
	__typename: "NotificationsFields_meta_Create",
	permission: boolean
};
	["NotificationsFields_meta_Read"]: {
	__typename: "NotificationsFields_meta_Read",
	permission: boolean
};
	["NotificationsFields_meta_Update"]: {
	__typename: "NotificationsFields_meta_Update",
	permission: boolean
};
	["NotificationsFields_meta_Delete"]: {
	__typename: "NotificationsFields_meta_Delete",
	permission: boolean
};
	["NotificationsFields_message"]: {
	__typename: "NotificationsFields_message",
	create?: GraphQLTypes["NotificationsFields_message_Create"] | undefined,
	read?: GraphQLTypes["NotificationsFields_message_Read"] | undefined,
	update?: GraphQLTypes["NotificationsFields_message_Update"] | undefined,
	delete?: GraphQLTypes["NotificationsFields_message_Delete"] | undefined
};
	["NotificationsFields_message_Create"]: {
	__typename: "NotificationsFields_message_Create",
	permission: boolean
};
	["NotificationsFields_message_Read"]: {
	__typename: "NotificationsFields_message_Read",
	permission: boolean
};
	["NotificationsFields_message_Update"]: {
	__typename: "NotificationsFields_message_Update",
	permission: boolean
};
	["NotificationsFields_message_Delete"]: {
	__typename: "NotificationsFields_message_Delete",
	permission: boolean
};
	["NotificationsFields_sender"]: {
	__typename: "NotificationsFields_sender",
	create?: GraphQLTypes["NotificationsFields_sender_Create"] | undefined,
	read?: GraphQLTypes["NotificationsFields_sender_Read"] | undefined,
	update?: GraphQLTypes["NotificationsFields_sender_Update"] | undefined,
	delete?: GraphQLTypes["NotificationsFields_sender_Delete"] | undefined
};
	["NotificationsFields_sender_Create"]: {
	__typename: "NotificationsFields_sender_Create",
	permission: boolean
};
	["NotificationsFields_sender_Read"]: {
	__typename: "NotificationsFields_sender_Read",
	permission: boolean
};
	["NotificationsFields_sender_Update"]: {
	__typename: "NotificationsFields_sender_Update",
	permission: boolean
};
	["NotificationsFields_sender_Delete"]: {
	__typename: "NotificationsFields_sender_Delete",
	permission: boolean
};
	["NotificationsFields_readed"]: {
	__typename: "NotificationsFields_readed",
	create?: GraphQLTypes["NotificationsFields_readed_Create"] | undefined,
	read?: GraphQLTypes["NotificationsFields_readed_Read"] | undefined,
	update?: GraphQLTypes["NotificationsFields_readed_Update"] | undefined,
	delete?: GraphQLTypes["NotificationsFields_readed_Delete"] | undefined
};
	["NotificationsFields_readed_Create"]: {
	__typename: "NotificationsFields_readed_Create",
	permission: boolean
};
	["NotificationsFields_readed_Read"]: {
	__typename: "NotificationsFields_readed_Read",
	permission: boolean
};
	["NotificationsFields_readed_Update"]: {
	__typename: "NotificationsFields_readed_Update",
	permission: boolean
};
	["NotificationsFields_readed_Delete"]: {
	__typename: "NotificationsFields_readed_Delete",
	permission: boolean
};
	["NotificationsFields_deletedByUser"]: {
	__typename: "NotificationsFields_deletedByUser",
	create?: GraphQLTypes["NotificationsFields_deletedByUser_Create"] | undefined,
	read?: GraphQLTypes["NotificationsFields_deletedByUser_Read"] | undefined,
	update?: GraphQLTypes["NotificationsFields_deletedByUser_Update"] | undefined,
	delete?: GraphQLTypes["NotificationsFields_deletedByUser_Delete"] | undefined
};
	["NotificationsFields_deletedByUser_Create"]: {
	__typename: "NotificationsFields_deletedByUser_Create",
	permission: boolean
};
	["NotificationsFields_deletedByUser_Read"]: {
	__typename: "NotificationsFields_deletedByUser_Read",
	permission: boolean
};
	["NotificationsFields_deletedByUser_Update"]: {
	__typename: "NotificationsFields_deletedByUser_Update",
	permission: boolean
};
	["NotificationsFields_deletedByUser_Delete"]: {
	__typename: "NotificationsFields_deletedByUser_Delete",
	permission: boolean
};
	["NotificationsFields_updatedAt"]: {
	__typename: "NotificationsFields_updatedAt",
	create?: GraphQLTypes["NotificationsFields_updatedAt_Create"] | undefined,
	read?: GraphQLTypes["NotificationsFields_updatedAt_Read"] | undefined,
	update?: GraphQLTypes["NotificationsFields_updatedAt_Update"] | undefined,
	delete?: GraphQLTypes["NotificationsFields_updatedAt_Delete"] | undefined
};
	["NotificationsFields_updatedAt_Create"]: {
	__typename: "NotificationsFields_updatedAt_Create",
	permission: boolean
};
	["NotificationsFields_updatedAt_Read"]: {
	__typename: "NotificationsFields_updatedAt_Read",
	permission: boolean
};
	["NotificationsFields_updatedAt_Update"]: {
	__typename: "NotificationsFields_updatedAt_Update",
	permission: boolean
};
	["NotificationsFields_updatedAt_Delete"]: {
	__typename: "NotificationsFields_updatedAt_Delete",
	permission: boolean
};
	["NotificationsFields_createdAt"]: {
	__typename: "NotificationsFields_createdAt",
	create?: GraphQLTypes["NotificationsFields_createdAt_Create"] | undefined,
	read?: GraphQLTypes["NotificationsFields_createdAt_Read"] | undefined,
	update?: GraphQLTypes["NotificationsFields_createdAt_Update"] | undefined,
	delete?: GraphQLTypes["NotificationsFields_createdAt_Delete"] | undefined
};
	["NotificationsFields_createdAt_Create"]: {
	__typename: "NotificationsFields_createdAt_Create",
	permission: boolean
};
	["NotificationsFields_createdAt_Read"]: {
	__typename: "NotificationsFields_createdAt_Read",
	permission: boolean
};
	["NotificationsFields_createdAt_Update"]: {
	__typename: "NotificationsFields_createdAt_Update",
	permission: boolean
};
	["NotificationsFields_createdAt_Delete"]: {
	__typename: "NotificationsFields_createdAt_Delete",
	permission: boolean
};
	["NotificationsCreateAccess"]: {
	__typename: "NotificationsCreateAccess",
	permission: boolean,
	where?: GraphQLTypes["JSONObject"] | undefined
};
	["NotificationsReadAccess"]: {
	__typename: "NotificationsReadAccess",
	permission: boolean,
	where?: GraphQLTypes["JSONObject"] | undefined
};
	["NotificationsUpdateAccess"]: {
	__typename: "NotificationsUpdateAccess",
	permission: boolean,
	where?: GraphQLTypes["JSONObject"] | undefined
};
	["NotificationsDeleteAccess"]: {
	__typename: "NotificationsDeleteAccess",
	permission: boolean,
	where?: GraphQLTypes["JSONObject"] | undefined
};
	["redirectSettingAccess"]: {
	__typename: "redirectSettingAccess",
	fields?: GraphQLTypes["RedirectSettingFields"] | undefined,
	create?: GraphQLTypes["RedirectSettingCreateAccess"] | undefined,
	read?: GraphQLTypes["RedirectSettingReadAccess"] | undefined,
	update?: GraphQLTypes["RedirectSettingUpdateAccess"] | undefined,
	delete?: GraphQLTypes["RedirectSettingDeleteAccess"] | undefined
};
	["RedirectSettingFields"]: {
	__typename: "RedirectSettingFields",
	from?: GraphQLTypes["RedirectSettingFields_from"] | undefined,
	to?: GraphQLTypes["RedirectSettingFields_to"] | undefined,
	updatedAt?: GraphQLTypes["RedirectSettingFields_updatedAt"] | undefined,
	createdAt?: GraphQLTypes["RedirectSettingFields_createdAt"] | undefined
};
	["RedirectSettingFields_from"]: {
	__typename: "RedirectSettingFields_from",
	create?: GraphQLTypes["RedirectSettingFields_from_Create"] | undefined,
	read?: GraphQLTypes["RedirectSettingFields_from_Read"] | undefined,
	update?: GraphQLTypes["RedirectSettingFields_from_Update"] | undefined,
	delete?: GraphQLTypes["RedirectSettingFields_from_Delete"] | undefined
};
	["RedirectSettingFields_from_Create"]: {
	__typename: "RedirectSettingFields_from_Create",
	permission: boolean
};
	["RedirectSettingFields_from_Read"]: {
	__typename: "RedirectSettingFields_from_Read",
	permission: boolean
};
	["RedirectSettingFields_from_Update"]: {
	__typename: "RedirectSettingFields_from_Update",
	permission: boolean
};
	["RedirectSettingFields_from_Delete"]: {
	__typename: "RedirectSettingFields_from_Delete",
	permission: boolean
};
	["RedirectSettingFields_to"]: {
	__typename: "RedirectSettingFields_to",
	create?: GraphQLTypes["RedirectSettingFields_to_Create"] | undefined,
	read?: GraphQLTypes["RedirectSettingFields_to_Read"] | undefined,
	update?: GraphQLTypes["RedirectSettingFields_to_Update"] | undefined,
	delete?: GraphQLTypes["RedirectSettingFields_to_Delete"] | undefined
};
	["RedirectSettingFields_to_Create"]: {
	__typename: "RedirectSettingFields_to_Create",
	permission: boolean
};
	["RedirectSettingFields_to_Read"]: {
	__typename: "RedirectSettingFields_to_Read",
	permission: boolean
};
	["RedirectSettingFields_to_Update"]: {
	__typename: "RedirectSettingFields_to_Update",
	permission: boolean
};
	["RedirectSettingFields_to_Delete"]: {
	__typename: "RedirectSettingFields_to_Delete",
	permission: boolean
};
	["RedirectSettingFields_updatedAt"]: {
	__typename: "RedirectSettingFields_updatedAt",
	create?: GraphQLTypes["RedirectSettingFields_updatedAt_Create"] | undefined,
	read?: GraphQLTypes["RedirectSettingFields_updatedAt_Read"] | undefined,
	update?: GraphQLTypes["RedirectSettingFields_updatedAt_Update"] | undefined,
	delete?: GraphQLTypes["RedirectSettingFields_updatedAt_Delete"] | undefined
};
	["RedirectSettingFields_updatedAt_Create"]: {
	__typename: "RedirectSettingFields_updatedAt_Create",
	permission: boolean
};
	["RedirectSettingFields_updatedAt_Read"]: {
	__typename: "RedirectSettingFields_updatedAt_Read",
	permission: boolean
};
	["RedirectSettingFields_updatedAt_Update"]: {
	__typename: "RedirectSettingFields_updatedAt_Update",
	permission: boolean
};
	["RedirectSettingFields_updatedAt_Delete"]: {
	__typename: "RedirectSettingFields_updatedAt_Delete",
	permission: boolean
};
	["RedirectSettingFields_createdAt"]: {
	__typename: "RedirectSettingFields_createdAt",
	create?: GraphQLTypes["RedirectSettingFields_createdAt_Create"] | undefined,
	read?: GraphQLTypes["RedirectSettingFields_createdAt_Read"] | undefined,
	update?: GraphQLTypes["RedirectSettingFields_createdAt_Update"] | undefined,
	delete?: GraphQLTypes["RedirectSettingFields_createdAt_Delete"] | undefined
};
	["RedirectSettingFields_createdAt_Create"]: {
	__typename: "RedirectSettingFields_createdAt_Create",
	permission: boolean
};
	["RedirectSettingFields_createdAt_Read"]: {
	__typename: "RedirectSettingFields_createdAt_Read",
	permission: boolean
};
	["RedirectSettingFields_createdAt_Update"]: {
	__typename: "RedirectSettingFields_createdAt_Update",
	permission: boolean
};
	["RedirectSettingFields_createdAt_Delete"]: {
	__typename: "RedirectSettingFields_createdAt_Delete",
	permission: boolean
};
	["RedirectSettingCreateAccess"]: {
	__typename: "RedirectSettingCreateAccess",
	permission: boolean,
	where?: GraphQLTypes["JSONObject"] | undefined
};
	["RedirectSettingReadAccess"]: {
	__typename: "RedirectSettingReadAccess",
	permission: boolean,
	where?: GraphQLTypes["JSONObject"] | undefined
};
	["RedirectSettingUpdateAccess"]: {
	__typename: "RedirectSettingUpdateAccess",
	permission: boolean,
	where?: GraphQLTypes["JSONObject"] | undefined
};
	["RedirectSettingDeleteAccess"]: {
	__typename: "RedirectSettingDeleteAccess",
	permission: boolean,
	where?: GraphQLTypes["JSONObject"] | undefined
};
	["ranking_eventsAccess"]: {
	__typename: "ranking_eventsAccess",
	fields?: GraphQLTypes["RankingEventsFields"] | undefined,
	create?: GraphQLTypes["RankingEventsCreateAccess"] | undefined,
	read?: GraphQLTypes["RankingEventsReadAccess"] | undefined,
	update?: GraphQLTypes["RankingEventsUpdateAccess"] | undefined,
	delete?: GraphQLTypes["RankingEventsDeleteAccess"] | undefined
};
	["RankingEventsFields"]: {
	__typename: "RankingEventsFields",
	name?: GraphQLTypes["RankingEventsFields_name"] | undefined,
	visible?: GraphQLTypes["RankingEventsFields_visible"] | undefined,
	maxItems?: GraphQLTypes["RankingEventsFields_maxItems"] | undefined,
	template?: GraphQLTypes["RankingEventsFields_template"] | undefined,
	list?: GraphQLTypes["RankingEventsFields_list"] | undefined,
	region?: GraphQLTypes["RankingEventsFields_region"] | undefined,
	updatedAt?: GraphQLTypes["RankingEventsFields_updatedAt"] | undefined,
	createdAt?: GraphQLTypes["RankingEventsFields_createdAt"] | undefined
};
	["RankingEventsFields_name"]: {
	__typename: "RankingEventsFields_name",
	create?: GraphQLTypes["RankingEventsFields_name_Create"] | undefined,
	read?: GraphQLTypes["RankingEventsFields_name_Read"] | undefined,
	update?: GraphQLTypes["RankingEventsFields_name_Update"] | undefined,
	delete?: GraphQLTypes["RankingEventsFields_name_Delete"] | undefined
};
	["RankingEventsFields_name_Create"]: {
	__typename: "RankingEventsFields_name_Create",
	permission: boolean
};
	["RankingEventsFields_name_Read"]: {
	__typename: "RankingEventsFields_name_Read",
	permission: boolean
};
	["RankingEventsFields_name_Update"]: {
	__typename: "RankingEventsFields_name_Update",
	permission: boolean
};
	["RankingEventsFields_name_Delete"]: {
	__typename: "RankingEventsFields_name_Delete",
	permission: boolean
};
	["RankingEventsFields_visible"]: {
	__typename: "RankingEventsFields_visible",
	create?: GraphQLTypes["RankingEventsFields_visible_Create"] | undefined,
	read?: GraphQLTypes["RankingEventsFields_visible_Read"] | undefined,
	update?: GraphQLTypes["RankingEventsFields_visible_Update"] | undefined,
	delete?: GraphQLTypes["RankingEventsFields_visible_Delete"] | undefined
};
	["RankingEventsFields_visible_Create"]: {
	__typename: "RankingEventsFields_visible_Create",
	permission: boolean
};
	["RankingEventsFields_visible_Read"]: {
	__typename: "RankingEventsFields_visible_Read",
	permission: boolean
};
	["RankingEventsFields_visible_Update"]: {
	__typename: "RankingEventsFields_visible_Update",
	permission: boolean
};
	["RankingEventsFields_visible_Delete"]: {
	__typename: "RankingEventsFields_visible_Delete",
	permission: boolean
};
	["RankingEventsFields_maxItems"]: {
	__typename: "RankingEventsFields_maxItems",
	create?: GraphQLTypes["RankingEventsFields_maxItems_Create"] | undefined,
	read?: GraphQLTypes["RankingEventsFields_maxItems_Read"] | undefined,
	update?: GraphQLTypes["RankingEventsFields_maxItems_Update"] | undefined,
	delete?: GraphQLTypes["RankingEventsFields_maxItems_Delete"] | undefined
};
	["RankingEventsFields_maxItems_Create"]: {
	__typename: "RankingEventsFields_maxItems_Create",
	permission: boolean
};
	["RankingEventsFields_maxItems_Read"]: {
	__typename: "RankingEventsFields_maxItems_Read",
	permission: boolean
};
	["RankingEventsFields_maxItems_Update"]: {
	__typename: "RankingEventsFields_maxItems_Update",
	permission: boolean
};
	["RankingEventsFields_maxItems_Delete"]: {
	__typename: "RankingEventsFields_maxItems_Delete",
	permission: boolean
};
	["RankingEventsFields_template"]: {
	__typename: "RankingEventsFields_template",
	create?: GraphQLTypes["RankingEventsFields_template_Create"] | undefined,
	read?: GraphQLTypes["RankingEventsFields_template_Read"] | undefined,
	update?: GraphQLTypes["RankingEventsFields_template_Update"] | undefined,
	delete?: GraphQLTypes["RankingEventsFields_template_Delete"] | undefined
};
	["RankingEventsFields_template_Create"]: {
	__typename: "RankingEventsFields_template_Create",
	permission: boolean
};
	["RankingEventsFields_template_Read"]: {
	__typename: "RankingEventsFields_template_Read",
	permission: boolean
};
	["RankingEventsFields_template_Update"]: {
	__typename: "RankingEventsFields_template_Update",
	permission: boolean
};
	["RankingEventsFields_template_Delete"]: {
	__typename: "RankingEventsFields_template_Delete",
	permission: boolean
};
	["RankingEventsFields_list"]: {
	__typename: "RankingEventsFields_list",
	create?: GraphQLTypes["RankingEventsFields_list_Create"] | undefined,
	read?: GraphQLTypes["RankingEventsFields_list_Read"] | undefined,
	update?: GraphQLTypes["RankingEventsFields_list_Update"] | undefined,
	delete?: GraphQLTypes["RankingEventsFields_list_Delete"] | undefined,
	fields?: GraphQLTypes["RankingEventsFields_list_Fields"] | undefined
};
	["RankingEventsFields_list_Create"]: {
	__typename: "RankingEventsFields_list_Create",
	permission: boolean
};
	["RankingEventsFields_list_Read"]: {
	__typename: "RankingEventsFields_list_Read",
	permission: boolean
};
	["RankingEventsFields_list_Update"]: {
	__typename: "RankingEventsFields_list_Update",
	permission: boolean
};
	["RankingEventsFields_list_Delete"]: {
	__typename: "RankingEventsFields_list_Delete",
	permission: boolean
};
	["RankingEventsFields_list_Fields"]: {
	__typename: "RankingEventsFields_list_Fields",
	item?: GraphQLTypes["RankingEventsFields_list_item"] | undefined,
	id?: GraphQLTypes["RankingEventsFields_list_id"] | undefined
};
	["RankingEventsFields_list_item"]: {
	__typename: "RankingEventsFields_list_item",
	create?: GraphQLTypes["RankingEventsFields_list_item_Create"] | undefined,
	read?: GraphQLTypes["RankingEventsFields_list_item_Read"] | undefined,
	update?: GraphQLTypes["RankingEventsFields_list_item_Update"] | undefined,
	delete?: GraphQLTypes["RankingEventsFields_list_item_Delete"] | undefined
};
	["RankingEventsFields_list_item_Create"]: {
	__typename: "RankingEventsFields_list_item_Create",
	permission: boolean
};
	["RankingEventsFields_list_item_Read"]: {
	__typename: "RankingEventsFields_list_item_Read",
	permission: boolean
};
	["RankingEventsFields_list_item_Update"]: {
	__typename: "RankingEventsFields_list_item_Update",
	permission: boolean
};
	["RankingEventsFields_list_item_Delete"]: {
	__typename: "RankingEventsFields_list_item_Delete",
	permission: boolean
};
	["RankingEventsFields_list_id"]: {
	__typename: "RankingEventsFields_list_id",
	create?: GraphQLTypes["RankingEventsFields_list_id_Create"] | undefined,
	read?: GraphQLTypes["RankingEventsFields_list_id_Read"] | undefined,
	update?: GraphQLTypes["RankingEventsFields_list_id_Update"] | undefined,
	delete?: GraphQLTypes["RankingEventsFields_list_id_Delete"] | undefined
};
	["RankingEventsFields_list_id_Create"]: {
	__typename: "RankingEventsFields_list_id_Create",
	permission: boolean
};
	["RankingEventsFields_list_id_Read"]: {
	__typename: "RankingEventsFields_list_id_Read",
	permission: boolean
};
	["RankingEventsFields_list_id_Update"]: {
	__typename: "RankingEventsFields_list_id_Update",
	permission: boolean
};
	["RankingEventsFields_list_id_Delete"]: {
	__typename: "RankingEventsFields_list_id_Delete",
	permission: boolean
};
	["RankingEventsFields_region"]: {
	__typename: "RankingEventsFields_region",
	create?: GraphQLTypes["RankingEventsFields_region_Create"] | undefined,
	read?: GraphQLTypes["RankingEventsFields_region_Read"] | undefined,
	update?: GraphQLTypes["RankingEventsFields_region_Update"] | undefined,
	delete?: GraphQLTypes["RankingEventsFields_region_Delete"] | undefined
};
	["RankingEventsFields_region_Create"]: {
	__typename: "RankingEventsFields_region_Create",
	permission: boolean
};
	["RankingEventsFields_region_Read"]: {
	__typename: "RankingEventsFields_region_Read",
	permission: boolean
};
	["RankingEventsFields_region_Update"]: {
	__typename: "RankingEventsFields_region_Update",
	permission: boolean
};
	["RankingEventsFields_region_Delete"]: {
	__typename: "RankingEventsFields_region_Delete",
	permission: boolean
};
	["RankingEventsFields_updatedAt"]: {
	__typename: "RankingEventsFields_updatedAt",
	create?: GraphQLTypes["RankingEventsFields_updatedAt_Create"] | undefined,
	read?: GraphQLTypes["RankingEventsFields_updatedAt_Read"] | undefined,
	update?: GraphQLTypes["RankingEventsFields_updatedAt_Update"] | undefined,
	delete?: GraphQLTypes["RankingEventsFields_updatedAt_Delete"] | undefined
};
	["RankingEventsFields_updatedAt_Create"]: {
	__typename: "RankingEventsFields_updatedAt_Create",
	permission: boolean
};
	["RankingEventsFields_updatedAt_Read"]: {
	__typename: "RankingEventsFields_updatedAt_Read",
	permission: boolean
};
	["RankingEventsFields_updatedAt_Update"]: {
	__typename: "RankingEventsFields_updatedAt_Update",
	permission: boolean
};
	["RankingEventsFields_updatedAt_Delete"]: {
	__typename: "RankingEventsFields_updatedAt_Delete",
	permission: boolean
};
	["RankingEventsFields_createdAt"]: {
	__typename: "RankingEventsFields_createdAt",
	create?: GraphQLTypes["RankingEventsFields_createdAt_Create"] | undefined,
	read?: GraphQLTypes["RankingEventsFields_createdAt_Read"] | undefined,
	update?: GraphQLTypes["RankingEventsFields_createdAt_Update"] | undefined,
	delete?: GraphQLTypes["RankingEventsFields_createdAt_Delete"] | undefined
};
	["RankingEventsFields_createdAt_Create"]: {
	__typename: "RankingEventsFields_createdAt_Create",
	permission: boolean
};
	["RankingEventsFields_createdAt_Read"]: {
	__typename: "RankingEventsFields_createdAt_Read",
	permission: boolean
};
	["RankingEventsFields_createdAt_Update"]: {
	__typename: "RankingEventsFields_createdAt_Update",
	permission: boolean
};
	["RankingEventsFields_createdAt_Delete"]: {
	__typename: "RankingEventsFields_createdAt_Delete",
	permission: boolean
};
	["RankingEventsCreateAccess"]: {
	__typename: "RankingEventsCreateAccess",
	permission: boolean,
	where?: GraphQLTypes["JSONObject"] | undefined
};
	["RankingEventsReadAccess"]: {
	__typename: "RankingEventsReadAccess",
	permission: boolean,
	where?: GraphQLTypes["JSONObject"] | undefined
};
	["RankingEventsUpdateAccess"]: {
	__typename: "RankingEventsUpdateAccess",
	permission: boolean,
	where?: GraphQLTypes["JSONObject"] | undefined
};
	["RankingEventsDeleteAccess"]: {
	__typename: "RankingEventsDeleteAccess",
	permission: boolean,
	where?: GraphQLTypes["JSONObject"] | undefined
};
	["ranking_locationsAccess"]: {
	__typename: "ranking_locationsAccess",
	fields?: GraphQLTypes["RankingLocationsFields"] | undefined,
	create?: GraphQLTypes["RankingLocationsCreateAccess"] | undefined,
	read?: GraphQLTypes["RankingLocationsReadAccess"] | undefined,
	update?: GraphQLTypes["RankingLocationsUpdateAccess"] | undefined,
	delete?: GraphQLTypes["RankingLocationsDeleteAccess"] | undefined
};
	["RankingLocationsFields"]: {
	__typename: "RankingLocationsFields",
	name?: GraphQLTypes["RankingLocationsFields_name"] | undefined,
	visible?: GraphQLTypes["RankingLocationsFields_visible"] | undefined,
	maxItems?: GraphQLTypes["RankingLocationsFields_maxItems"] | undefined,
	template?: GraphQLTypes["RankingLocationsFields_template"] | undefined,
	list?: GraphQLTypes["RankingLocationsFields_list"] | undefined,
	region?: GraphQLTypes["RankingLocationsFields_region"] | undefined,
	updatedAt?: GraphQLTypes["RankingLocationsFields_updatedAt"] | undefined,
	createdAt?: GraphQLTypes["RankingLocationsFields_createdAt"] | undefined
};
	["RankingLocationsFields_name"]: {
	__typename: "RankingLocationsFields_name",
	create?: GraphQLTypes["RankingLocationsFields_name_Create"] | undefined,
	read?: GraphQLTypes["RankingLocationsFields_name_Read"] | undefined,
	update?: GraphQLTypes["RankingLocationsFields_name_Update"] | undefined,
	delete?: GraphQLTypes["RankingLocationsFields_name_Delete"] | undefined
};
	["RankingLocationsFields_name_Create"]: {
	__typename: "RankingLocationsFields_name_Create",
	permission: boolean
};
	["RankingLocationsFields_name_Read"]: {
	__typename: "RankingLocationsFields_name_Read",
	permission: boolean
};
	["RankingLocationsFields_name_Update"]: {
	__typename: "RankingLocationsFields_name_Update",
	permission: boolean
};
	["RankingLocationsFields_name_Delete"]: {
	__typename: "RankingLocationsFields_name_Delete",
	permission: boolean
};
	["RankingLocationsFields_visible"]: {
	__typename: "RankingLocationsFields_visible",
	create?: GraphQLTypes["RankingLocationsFields_visible_Create"] | undefined,
	read?: GraphQLTypes["RankingLocationsFields_visible_Read"] | undefined,
	update?: GraphQLTypes["RankingLocationsFields_visible_Update"] | undefined,
	delete?: GraphQLTypes["RankingLocationsFields_visible_Delete"] | undefined
};
	["RankingLocationsFields_visible_Create"]: {
	__typename: "RankingLocationsFields_visible_Create",
	permission: boolean
};
	["RankingLocationsFields_visible_Read"]: {
	__typename: "RankingLocationsFields_visible_Read",
	permission: boolean
};
	["RankingLocationsFields_visible_Update"]: {
	__typename: "RankingLocationsFields_visible_Update",
	permission: boolean
};
	["RankingLocationsFields_visible_Delete"]: {
	__typename: "RankingLocationsFields_visible_Delete",
	permission: boolean
};
	["RankingLocationsFields_maxItems"]: {
	__typename: "RankingLocationsFields_maxItems",
	create?: GraphQLTypes["RankingLocationsFields_maxItems_Create"] | undefined,
	read?: GraphQLTypes["RankingLocationsFields_maxItems_Read"] | undefined,
	update?: GraphQLTypes["RankingLocationsFields_maxItems_Update"] | undefined,
	delete?: GraphQLTypes["RankingLocationsFields_maxItems_Delete"] | undefined
};
	["RankingLocationsFields_maxItems_Create"]: {
	__typename: "RankingLocationsFields_maxItems_Create",
	permission: boolean
};
	["RankingLocationsFields_maxItems_Read"]: {
	__typename: "RankingLocationsFields_maxItems_Read",
	permission: boolean
};
	["RankingLocationsFields_maxItems_Update"]: {
	__typename: "RankingLocationsFields_maxItems_Update",
	permission: boolean
};
	["RankingLocationsFields_maxItems_Delete"]: {
	__typename: "RankingLocationsFields_maxItems_Delete",
	permission: boolean
};
	["RankingLocationsFields_template"]: {
	__typename: "RankingLocationsFields_template",
	create?: GraphQLTypes["RankingLocationsFields_template_Create"] | undefined,
	read?: GraphQLTypes["RankingLocationsFields_template_Read"] | undefined,
	update?: GraphQLTypes["RankingLocationsFields_template_Update"] | undefined,
	delete?: GraphQLTypes["RankingLocationsFields_template_Delete"] | undefined
};
	["RankingLocationsFields_template_Create"]: {
	__typename: "RankingLocationsFields_template_Create",
	permission: boolean
};
	["RankingLocationsFields_template_Read"]: {
	__typename: "RankingLocationsFields_template_Read",
	permission: boolean
};
	["RankingLocationsFields_template_Update"]: {
	__typename: "RankingLocationsFields_template_Update",
	permission: boolean
};
	["RankingLocationsFields_template_Delete"]: {
	__typename: "RankingLocationsFields_template_Delete",
	permission: boolean
};
	["RankingLocationsFields_list"]: {
	__typename: "RankingLocationsFields_list",
	create?: GraphQLTypes["RankingLocationsFields_list_Create"] | undefined,
	read?: GraphQLTypes["RankingLocationsFields_list_Read"] | undefined,
	update?: GraphQLTypes["RankingLocationsFields_list_Update"] | undefined,
	delete?: GraphQLTypes["RankingLocationsFields_list_Delete"] | undefined,
	fields?: GraphQLTypes["RankingLocationsFields_list_Fields"] | undefined
};
	["RankingLocationsFields_list_Create"]: {
	__typename: "RankingLocationsFields_list_Create",
	permission: boolean
};
	["RankingLocationsFields_list_Read"]: {
	__typename: "RankingLocationsFields_list_Read",
	permission: boolean
};
	["RankingLocationsFields_list_Update"]: {
	__typename: "RankingLocationsFields_list_Update",
	permission: boolean
};
	["RankingLocationsFields_list_Delete"]: {
	__typename: "RankingLocationsFields_list_Delete",
	permission: boolean
};
	["RankingLocationsFields_list_Fields"]: {
	__typename: "RankingLocationsFields_list_Fields",
	item?: GraphQLTypes["RankingLocationsFields_list_item"] | undefined,
	id?: GraphQLTypes["RankingLocationsFields_list_id"] | undefined
};
	["RankingLocationsFields_list_item"]: {
	__typename: "RankingLocationsFields_list_item",
	create?: GraphQLTypes["RankingLocationsFields_list_item_Create"] | undefined,
	read?: GraphQLTypes["RankingLocationsFields_list_item_Read"] | undefined,
	update?: GraphQLTypes["RankingLocationsFields_list_item_Update"] | undefined,
	delete?: GraphQLTypes["RankingLocationsFields_list_item_Delete"] | undefined
};
	["RankingLocationsFields_list_item_Create"]: {
	__typename: "RankingLocationsFields_list_item_Create",
	permission: boolean
};
	["RankingLocationsFields_list_item_Read"]: {
	__typename: "RankingLocationsFields_list_item_Read",
	permission: boolean
};
	["RankingLocationsFields_list_item_Update"]: {
	__typename: "RankingLocationsFields_list_item_Update",
	permission: boolean
};
	["RankingLocationsFields_list_item_Delete"]: {
	__typename: "RankingLocationsFields_list_item_Delete",
	permission: boolean
};
	["RankingLocationsFields_list_id"]: {
	__typename: "RankingLocationsFields_list_id",
	create?: GraphQLTypes["RankingLocationsFields_list_id_Create"] | undefined,
	read?: GraphQLTypes["RankingLocationsFields_list_id_Read"] | undefined,
	update?: GraphQLTypes["RankingLocationsFields_list_id_Update"] | undefined,
	delete?: GraphQLTypes["RankingLocationsFields_list_id_Delete"] | undefined
};
	["RankingLocationsFields_list_id_Create"]: {
	__typename: "RankingLocationsFields_list_id_Create",
	permission: boolean
};
	["RankingLocationsFields_list_id_Read"]: {
	__typename: "RankingLocationsFields_list_id_Read",
	permission: boolean
};
	["RankingLocationsFields_list_id_Update"]: {
	__typename: "RankingLocationsFields_list_id_Update",
	permission: boolean
};
	["RankingLocationsFields_list_id_Delete"]: {
	__typename: "RankingLocationsFields_list_id_Delete",
	permission: boolean
};
	["RankingLocationsFields_region"]: {
	__typename: "RankingLocationsFields_region",
	create?: GraphQLTypes["RankingLocationsFields_region_Create"] | undefined,
	read?: GraphQLTypes["RankingLocationsFields_region_Read"] | undefined,
	update?: GraphQLTypes["RankingLocationsFields_region_Update"] | undefined,
	delete?: GraphQLTypes["RankingLocationsFields_region_Delete"] | undefined
};
	["RankingLocationsFields_region_Create"]: {
	__typename: "RankingLocationsFields_region_Create",
	permission: boolean
};
	["RankingLocationsFields_region_Read"]: {
	__typename: "RankingLocationsFields_region_Read",
	permission: boolean
};
	["RankingLocationsFields_region_Update"]: {
	__typename: "RankingLocationsFields_region_Update",
	permission: boolean
};
	["RankingLocationsFields_region_Delete"]: {
	__typename: "RankingLocationsFields_region_Delete",
	permission: boolean
};
	["RankingLocationsFields_updatedAt"]: {
	__typename: "RankingLocationsFields_updatedAt",
	create?: GraphQLTypes["RankingLocationsFields_updatedAt_Create"] | undefined,
	read?: GraphQLTypes["RankingLocationsFields_updatedAt_Read"] | undefined,
	update?: GraphQLTypes["RankingLocationsFields_updatedAt_Update"] | undefined,
	delete?: GraphQLTypes["RankingLocationsFields_updatedAt_Delete"] | undefined
};
	["RankingLocationsFields_updatedAt_Create"]: {
	__typename: "RankingLocationsFields_updatedAt_Create",
	permission: boolean
};
	["RankingLocationsFields_updatedAt_Read"]: {
	__typename: "RankingLocationsFields_updatedAt_Read",
	permission: boolean
};
	["RankingLocationsFields_updatedAt_Update"]: {
	__typename: "RankingLocationsFields_updatedAt_Update",
	permission: boolean
};
	["RankingLocationsFields_updatedAt_Delete"]: {
	__typename: "RankingLocationsFields_updatedAt_Delete",
	permission: boolean
};
	["RankingLocationsFields_createdAt"]: {
	__typename: "RankingLocationsFields_createdAt",
	create?: GraphQLTypes["RankingLocationsFields_createdAt_Create"] | undefined,
	read?: GraphQLTypes["RankingLocationsFields_createdAt_Read"] | undefined,
	update?: GraphQLTypes["RankingLocationsFields_createdAt_Update"] | undefined,
	delete?: GraphQLTypes["RankingLocationsFields_createdAt_Delete"] | undefined
};
	["RankingLocationsFields_createdAt_Create"]: {
	__typename: "RankingLocationsFields_createdAt_Create",
	permission: boolean
};
	["RankingLocationsFields_createdAt_Read"]: {
	__typename: "RankingLocationsFields_createdAt_Read",
	permission: boolean
};
	["RankingLocationsFields_createdAt_Update"]: {
	__typename: "RankingLocationsFields_createdAt_Update",
	permission: boolean
};
	["RankingLocationsFields_createdAt_Delete"]: {
	__typename: "RankingLocationsFields_createdAt_Delete",
	permission: boolean
};
	["RankingLocationsCreateAccess"]: {
	__typename: "RankingLocationsCreateAccess",
	permission: boolean,
	where?: GraphQLTypes["JSONObject"] | undefined
};
	["RankingLocationsReadAccess"]: {
	__typename: "RankingLocationsReadAccess",
	permission: boolean,
	where?: GraphQLTypes["JSONObject"] | undefined
};
	["RankingLocationsUpdateAccess"]: {
	__typename: "RankingLocationsUpdateAccess",
	permission: boolean,
	where?: GraphQLTypes["JSONObject"] | undefined
};
	["RankingLocationsDeleteAccess"]: {
	__typename: "RankingLocationsDeleteAccess",
	permission: boolean,
	where?: GraphQLTypes["JSONObject"] | undefined
};
	["event_calendarAccess"]: {
	__typename: "event_calendarAccess",
	fields?: GraphQLTypes["EventCalendarFields"] | undefined,
	create?: GraphQLTypes["EventCalendarCreateAccess"] | undefined,
	read?: GraphQLTypes["EventCalendarReadAccess"] | undefined,
	update?: GraphQLTypes["EventCalendarUpdateAccess"] | undefined,
	delete?: GraphQLTypes["EventCalendarDeleteAccess"] | undefined
};
	["EventCalendarFields"]: {
	__typename: "EventCalendarFields",
	date?: GraphQLTypes["EventCalendarFields_date"] | undefined,
	item?: GraphQLTypes["EventCalendarFields_item"] | undefined,
	title?: GraphQLTypes["EventCalendarFields_title"] | undefined,
	region?: GraphQLTypes["EventCalendarFields_region"] | undefined,
	updatedAt?: GraphQLTypes["EventCalendarFields_updatedAt"] | undefined,
	createdAt?: GraphQLTypes["EventCalendarFields_createdAt"] | undefined
};
	["EventCalendarFields_date"]: {
	__typename: "EventCalendarFields_date",
	create?: GraphQLTypes["EventCalendarFields_date_Create"] | undefined,
	read?: GraphQLTypes["EventCalendarFields_date_Read"] | undefined,
	update?: GraphQLTypes["EventCalendarFields_date_Update"] | undefined,
	delete?: GraphQLTypes["EventCalendarFields_date_Delete"] | undefined
};
	["EventCalendarFields_date_Create"]: {
	__typename: "EventCalendarFields_date_Create",
	permission: boolean
};
	["EventCalendarFields_date_Read"]: {
	__typename: "EventCalendarFields_date_Read",
	permission: boolean
};
	["EventCalendarFields_date_Update"]: {
	__typename: "EventCalendarFields_date_Update",
	permission: boolean
};
	["EventCalendarFields_date_Delete"]: {
	__typename: "EventCalendarFields_date_Delete",
	permission: boolean
};
	["EventCalendarFields_item"]: {
	__typename: "EventCalendarFields_item",
	create?: GraphQLTypes["EventCalendarFields_item_Create"] | undefined,
	read?: GraphQLTypes["EventCalendarFields_item_Read"] | undefined,
	update?: GraphQLTypes["EventCalendarFields_item_Update"] | undefined,
	delete?: GraphQLTypes["EventCalendarFields_item_Delete"] | undefined
};
	["EventCalendarFields_item_Create"]: {
	__typename: "EventCalendarFields_item_Create",
	permission: boolean
};
	["EventCalendarFields_item_Read"]: {
	__typename: "EventCalendarFields_item_Read",
	permission: boolean
};
	["EventCalendarFields_item_Update"]: {
	__typename: "EventCalendarFields_item_Update",
	permission: boolean
};
	["EventCalendarFields_item_Delete"]: {
	__typename: "EventCalendarFields_item_Delete",
	permission: boolean
};
	["EventCalendarFields_title"]: {
	__typename: "EventCalendarFields_title",
	create?: GraphQLTypes["EventCalendarFields_title_Create"] | undefined,
	read?: GraphQLTypes["EventCalendarFields_title_Read"] | undefined,
	update?: GraphQLTypes["EventCalendarFields_title_Update"] | undefined,
	delete?: GraphQLTypes["EventCalendarFields_title_Delete"] | undefined
};
	["EventCalendarFields_title_Create"]: {
	__typename: "EventCalendarFields_title_Create",
	permission: boolean
};
	["EventCalendarFields_title_Read"]: {
	__typename: "EventCalendarFields_title_Read",
	permission: boolean
};
	["EventCalendarFields_title_Update"]: {
	__typename: "EventCalendarFields_title_Update",
	permission: boolean
};
	["EventCalendarFields_title_Delete"]: {
	__typename: "EventCalendarFields_title_Delete",
	permission: boolean
};
	["EventCalendarFields_region"]: {
	__typename: "EventCalendarFields_region",
	create?: GraphQLTypes["EventCalendarFields_region_Create"] | undefined,
	read?: GraphQLTypes["EventCalendarFields_region_Read"] | undefined,
	update?: GraphQLTypes["EventCalendarFields_region_Update"] | undefined,
	delete?: GraphQLTypes["EventCalendarFields_region_Delete"] | undefined
};
	["EventCalendarFields_region_Create"]: {
	__typename: "EventCalendarFields_region_Create",
	permission: boolean
};
	["EventCalendarFields_region_Read"]: {
	__typename: "EventCalendarFields_region_Read",
	permission: boolean
};
	["EventCalendarFields_region_Update"]: {
	__typename: "EventCalendarFields_region_Update",
	permission: boolean
};
	["EventCalendarFields_region_Delete"]: {
	__typename: "EventCalendarFields_region_Delete",
	permission: boolean
};
	["EventCalendarFields_updatedAt"]: {
	__typename: "EventCalendarFields_updatedAt",
	create?: GraphQLTypes["EventCalendarFields_updatedAt_Create"] | undefined,
	read?: GraphQLTypes["EventCalendarFields_updatedAt_Read"] | undefined,
	update?: GraphQLTypes["EventCalendarFields_updatedAt_Update"] | undefined,
	delete?: GraphQLTypes["EventCalendarFields_updatedAt_Delete"] | undefined
};
	["EventCalendarFields_updatedAt_Create"]: {
	__typename: "EventCalendarFields_updatedAt_Create",
	permission: boolean
};
	["EventCalendarFields_updatedAt_Read"]: {
	__typename: "EventCalendarFields_updatedAt_Read",
	permission: boolean
};
	["EventCalendarFields_updatedAt_Update"]: {
	__typename: "EventCalendarFields_updatedAt_Update",
	permission: boolean
};
	["EventCalendarFields_updatedAt_Delete"]: {
	__typename: "EventCalendarFields_updatedAt_Delete",
	permission: boolean
};
	["EventCalendarFields_createdAt"]: {
	__typename: "EventCalendarFields_createdAt",
	create?: GraphQLTypes["EventCalendarFields_createdAt_Create"] | undefined,
	read?: GraphQLTypes["EventCalendarFields_createdAt_Read"] | undefined,
	update?: GraphQLTypes["EventCalendarFields_createdAt_Update"] | undefined,
	delete?: GraphQLTypes["EventCalendarFields_createdAt_Delete"] | undefined
};
	["EventCalendarFields_createdAt_Create"]: {
	__typename: "EventCalendarFields_createdAt_Create",
	permission: boolean
};
	["EventCalendarFields_createdAt_Read"]: {
	__typename: "EventCalendarFields_createdAt_Read",
	permission: boolean
};
	["EventCalendarFields_createdAt_Update"]: {
	__typename: "EventCalendarFields_createdAt_Update",
	permission: boolean
};
	["EventCalendarFields_createdAt_Delete"]: {
	__typename: "EventCalendarFields_createdAt_Delete",
	permission: boolean
};
	["EventCalendarCreateAccess"]: {
	__typename: "EventCalendarCreateAccess",
	permission: boolean,
	where?: GraphQLTypes["JSONObject"] | undefined
};
	["EventCalendarReadAccess"]: {
	__typename: "EventCalendarReadAccess",
	permission: boolean,
	where?: GraphQLTypes["JSONObject"] | undefined
};
	["EventCalendarUpdateAccess"]: {
	__typename: "EventCalendarUpdateAccess",
	permission: boolean,
	where?: GraphQLTypes["JSONObject"] | undefined
};
	["EventCalendarDeleteAccess"]: {
	__typename: "EventCalendarDeleteAccess",
	permission: boolean,
	where?: GraphQLTypes["JSONObject"] | undefined
};
	["editor_choicesAccess"]: {
	__typename: "editor_choicesAccess",
	fields?: GraphQLTypes["EditorChoicesFields"] | undefined,
	create?: GraphQLTypes["EditorChoicesCreateAccess"] | undefined,
	read?: GraphQLTypes["EditorChoicesReadAccess"] | undefined,
	update?: GraphQLTypes["EditorChoicesUpdateAccess"] | undefined,
	delete?: GraphQLTypes["EditorChoicesDeleteAccess"] | undefined
};
	["EditorChoicesFields"]: {
	__typename: "EditorChoicesFields",
	name?: GraphQLTypes["EditorChoicesFields_name"] | undefined,
	visible?: GraphQLTypes["EditorChoicesFields_visible"] | undefined,
	editorialPicks?: GraphQLTypes["EditorChoicesFields_editorialPicks"] | undefined,
	list?: GraphQLTypes["EditorChoicesFields_list"] | undefined,
	region?: GraphQLTypes["EditorChoicesFields_region"] | undefined,
	updatedAt?: GraphQLTypes["EditorChoicesFields_updatedAt"] | undefined,
	createdAt?: GraphQLTypes["EditorChoicesFields_createdAt"] | undefined
};
	["EditorChoicesFields_name"]: {
	__typename: "EditorChoicesFields_name",
	create?: GraphQLTypes["EditorChoicesFields_name_Create"] | undefined,
	read?: GraphQLTypes["EditorChoicesFields_name_Read"] | undefined,
	update?: GraphQLTypes["EditorChoicesFields_name_Update"] | undefined,
	delete?: GraphQLTypes["EditorChoicesFields_name_Delete"] | undefined
};
	["EditorChoicesFields_name_Create"]: {
	__typename: "EditorChoicesFields_name_Create",
	permission: boolean
};
	["EditorChoicesFields_name_Read"]: {
	__typename: "EditorChoicesFields_name_Read",
	permission: boolean
};
	["EditorChoicesFields_name_Update"]: {
	__typename: "EditorChoicesFields_name_Update",
	permission: boolean
};
	["EditorChoicesFields_name_Delete"]: {
	__typename: "EditorChoicesFields_name_Delete",
	permission: boolean
};
	["EditorChoicesFields_visible"]: {
	__typename: "EditorChoicesFields_visible",
	create?: GraphQLTypes["EditorChoicesFields_visible_Create"] | undefined,
	read?: GraphQLTypes["EditorChoicesFields_visible_Read"] | undefined,
	update?: GraphQLTypes["EditorChoicesFields_visible_Update"] | undefined,
	delete?: GraphQLTypes["EditorChoicesFields_visible_Delete"] | undefined
};
	["EditorChoicesFields_visible_Create"]: {
	__typename: "EditorChoicesFields_visible_Create",
	permission: boolean
};
	["EditorChoicesFields_visible_Read"]: {
	__typename: "EditorChoicesFields_visible_Read",
	permission: boolean
};
	["EditorChoicesFields_visible_Update"]: {
	__typename: "EditorChoicesFields_visible_Update",
	permission: boolean
};
	["EditorChoicesFields_visible_Delete"]: {
	__typename: "EditorChoicesFields_visible_Delete",
	permission: boolean
};
	["EditorChoicesFields_editorialPicks"]: {
	__typename: "EditorChoicesFields_editorialPicks",
	create?: GraphQLTypes["EditorChoicesFields_editorialPicks_Create"] | undefined,
	read?: GraphQLTypes["EditorChoicesFields_editorialPicks_Read"] | undefined,
	update?: GraphQLTypes["EditorChoicesFields_editorialPicks_Update"] | undefined,
	delete?: GraphQLTypes["EditorChoicesFields_editorialPicks_Delete"] | undefined
};
	["EditorChoicesFields_editorialPicks_Create"]: {
	__typename: "EditorChoicesFields_editorialPicks_Create",
	permission: boolean
};
	["EditorChoicesFields_editorialPicks_Read"]: {
	__typename: "EditorChoicesFields_editorialPicks_Read",
	permission: boolean
};
	["EditorChoicesFields_editorialPicks_Update"]: {
	__typename: "EditorChoicesFields_editorialPicks_Update",
	permission: boolean
};
	["EditorChoicesFields_editorialPicks_Delete"]: {
	__typename: "EditorChoicesFields_editorialPicks_Delete",
	permission: boolean
};
	["EditorChoicesFields_list"]: {
	__typename: "EditorChoicesFields_list",
	create?: GraphQLTypes["EditorChoicesFields_list_Create"] | undefined,
	read?: GraphQLTypes["EditorChoicesFields_list_Read"] | undefined,
	update?: GraphQLTypes["EditorChoicesFields_list_Update"] | undefined,
	delete?: GraphQLTypes["EditorChoicesFields_list_Delete"] | undefined,
	fields?: GraphQLTypes["EditorChoicesFields_list_Fields"] | undefined
};
	["EditorChoicesFields_list_Create"]: {
	__typename: "EditorChoicesFields_list_Create",
	permission: boolean
};
	["EditorChoicesFields_list_Read"]: {
	__typename: "EditorChoicesFields_list_Read",
	permission: boolean
};
	["EditorChoicesFields_list_Update"]: {
	__typename: "EditorChoicesFields_list_Update",
	permission: boolean
};
	["EditorChoicesFields_list_Delete"]: {
	__typename: "EditorChoicesFields_list_Delete",
	permission: boolean
};
	["EditorChoicesFields_list_Fields"]: {
	__typename: "EditorChoicesFields_list_Fields",
	item?: GraphQLTypes["EditorChoicesFields_list_item"] | undefined,
	id?: GraphQLTypes["EditorChoicesFields_list_id"] | undefined
};
	["EditorChoicesFields_list_item"]: {
	__typename: "EditorChoicesFields_list_item",
	create?: GraphQLTypes["EditorChoicesFields_list_item_Create"] | undefined,
	read?: GraphQLTypes["EditorChoicesFields_list_item_Read"] | undefined,
	update?: GraphQLTypes["EditorChoicesFields_list_item_Update"] | undefined,
	delete?: GraphQLTypes["EditorChoicesFields_list_item_Delete"] | undefined
};
	["EditorChoicesFields_list_item_Create"]: {
	__typename: "EditorChoicesFields_list_item_Create",
	permission: boolean
};
	["EditorChoicesFields_list_item_Read"]: {
	__typename: "EditorChoicesFields_list_item_Read",
	permission: boolean
};
	["EditorChoicesFields_list_item_Update"]: {
	__typename: "EditorChoicesFields_list_item_Update",
	permission: boolean
};
	["EditorChoicesFields_list_item_Delete"]: {
	__typename: "EditorChoicesFields_list_item_Delete",
	permission: boolean
};
	["EditorChoicesFields_list_id"]: {
	__typename: "EditorChoicesFields_list_id",
	create?: GraphQLTypes["EditorChoicesFields_list_id_Create"] | undefined,
	read?: GraphQLTypes["EditorChoicesFields_list_id_Read"] | undefined,
	update?: GraphQLTypes["EditorChoicesFields_list_id_Update"] | undefined,
	delete?: GraphQLTypes["EditorChoicesFields_list_id_Delete"] | undefined
};
	["EditorChoicesFields_list_id_Create"]: {
	__typename: "EditorChoicesFields_list_id_Create",
	permission: boolean
};
	["EditorChoicesFields_list_id_Read"]: {
	__typename: "EditorChoicesFields_list_id_Read",
	permission: boolean
};
	["EditorChoicesFields_list_id_Update"]: {
	__typename: "EditorChoicesFields_list_id_Update",
	permission: boolean
};
	["EditorChoicesFields_list_id_Delete"]: {
	__typename: "EditorChoicesFields_list_id_Delete",
	permission: boolean
};
	["EditorChoicesFields_region"]: {
	__typename: "EditorChoicesFields_region",
	create?: GraphQLTypes["EditorChoicesFields_region_Create"] | undefined,
	read?: GraphQLTypes["EditorChoicesFields_region_Read"] | undefined,
	update?: GraphQLTypes["EditorChoicesFields_region_Update"] | undefined,
	delete?: GraphQLTypes["EditorChoicesFields_region_Delete"] | undefined
};
	["EditorChoicesFields_region_Create"]: {
	__typename: "EditorChoicesFields_region_Create",
	permission: boolean
};
	["EditorChoicesFields_region_Read"]: {
	__typename: "EditorChoicesFields_region_Read",
	permission: boolean
};
	["EditorChoicesFields_region_Update"]: {
	__typename: "EditorChoicesFields_region_Update",
	permission: boolean
};
	["EditorChoicesFields_region_Delete"]: {
	__typename: "EditorChoicesFields_region_Delete",
	permission: boolean
};
	["EditorChoicesFields_updatedAt"]: {
	__typename: "EditorChoicesFields_updatedAt",
	create?: GraphQLTypes["EditorChoicesFields_updatedAt_Create"] | undefined,
	read?: GraphQLTypes["EditorChoicesFields_updatedAt_Read"] | undefined,
	update?: GraphQLTypes["EditorChoicesFields_updatedAt_Update"] | undefined,
	delete?: GraphQLTypes["EditorChoicesFields_updatedAt_Delete"] | undefined
};
	["EditorChoicesFields_updatedAt_Create"]: {
	__typename: "EditorChoicesFields_updatedAt_Create",
	permission: boolean
};
	["EditorChoicesFields_updatedAt_Read"]: {
	__typename: "EditorChoicesFields_updatedAt_Read",
	permission: boolean
};
	["EditorChoicesFields_updatedAt_Update"]: {
	__typename: "EditorChoicesFields_updatedAt_Update",
	permission: boolean
};
	["EditorChoicesFields_updatedAt_Delete"]: {
	__typename: "EditorChoicesFields_updatedAt_Delete",
	permission: boolean
};
	["EditorChoicesFields_createdAt"]: {
	__typename: "EditorChoicesFields_createdAt",
	create?: GraphQLTypes["EditorChoicesFields_createdAt_Create"] | undefined,
	read?: GraphQLTypes["EditorChoicesFields_createdAt_Read"] | undefined,
	update?: GraphQLTypes["EditorChoicesFields_createdAt_Update"] | undefined,
	delete?: GraphQLTypes["EditorChoicesFields_createdAt_Delete"] | undefined
};
	["EditorChoicesFields_createdAt_Create"]: {
	__typename: "EditorChoicesFields_createdAt_Create",
	permission: boolean
};
	["EditorChoicesFields_createdAt_Read"]: {
	__typename: "EditorChoicesFields_createdAt_Read",
	permission: boolean
};
	["EditorChoicesFields_createdAt_Update"]: {
	__typename: "EditorChoicesFields_createdAt_Update",
	permission: boolean
};
	["EditorChoicesFields_createdAt_Delete"]: {
	__typename: "EditorChoicesFields_createdAt_Delete",
	permission: boolean
};
	["EditorChoicesCreateAccess"]: {
	__typename: "EditorChoicesCreateAccess",
	permission: boolean,
	where?: GraphQLTypes["JSONObject"] | undefined
};
	["EditorChoicesReadAccess"]: {
	__typename: "EditorChoicesReadAccess",
	permission: boolean,
	where?: GraphQLTypes["JSONObject"] | undefined
};
	["EditorChoicesUpdateAccess"]: {
	__typename: "EditorChoicesUpdateAccess",
	permission: boolean,
	where?: GraphQLTypes["JSONObject"] | undefined
};
	["EditorChoicesDeleteAccess"]: {
	__typename: "EditorChoicesDeleteAccess",
	permission: boolean,
	where?: GraphQLTypes["JSONObject"] | undefined
};
	["criteria_settingAccess"]: {
	__typename: "criteria_settingAccess",
	fields?: GraphQLTypes["CriteriaSettingFields"] | undefined,
	create?: GraphQLTypes["CriteriaSettingCreateAccess"] | undefined,
	read?: GraphQLTypes["CriteriaSettingReadAccess"] | undefined,
	update?: GraphQLTypes["CriteriaSettingUpdateAccess"] | undefined,
	delete?: GraphQLTypes["CriteriaSettingDeleteAccess"] | undefined
};
	["CriteriaSettingFields"]: {
	__typename: "CriteriaSettingFields",
	criteria?: GraphQLTypes["CriteriaSettingFields_criteria"] | undefined,
	updatedAt?: GraphQLTypes["CriteriaSettingFields_updatedAt"] | undefined,
	createdAt?: GraphQLTypes["CriteriaSettingFields_createdAt"] | undefined
};
	["CriteriaSettingFields_criteria"]: {
	__typename: "CriteriaSettingFields_criteria",
	create?: GraphQLTypes["CriteriaSettingFields_criteria_Create"] | undefined,
	read?: GraphQLTypes["CriteriaSettingFields_criteria_Read"] | undefined,
	update?: GraphQLTypes["CriteriaSettingFields_criteria_Update"] | undefined,
	delete?: GraphQLTypes["CriteriaSettingFields_criteria_Delete"] | undefined,
	fields?: GraphQLTypes["CriteriaSettingFields_criteria_Fields"] | undefined
};
	["CriteriaSettingFields_criteria_Create"]: {
	__typename: "CriteriaSettingFields_criteria_Create",
	permission: boolean
};
	["CriteriaSettingFields_criteria_Read"]: {
	__typename: "CriteriaSettingFields_criteria_Read",
	permission: boolean
};
	["CriteriaSettingFields_criteria_Update"]: {
	__typename: "CriteriaSettingFields_criteria_Update",
	permission: boolean
};
	["CriteriaSettingFields_criteria_Delete"]: {
	__typename: "CriteriaSettingFields_criteria_Delete",
	permission: boolean
};
	["CriteriaSettingFields_criteria_Fields"]: {
	__typename: "CriteriaSettingFields_criteria_Fields",
	date?: GraphQLTypes["CriteriaSettingFields_criteria_date"] | undefined,
	audience?: GraphQLTypes["CriteriaSettingFields_criteria_audience"] | undefined,
	category?: GraphQLTypes["CriteriaSettingFields_criteria_category"] | undefined,
	tag?: GraphQLTypes["CriteriaSettingFields_criteria_tag"] | undefined,
	region?: GraphQLTypes["CriteriaSettingFields_criteria_region"] | undefined
};
	["CriteriaSettingFields_criteria_date"]: {
	__typename: "CriteriaSettingFields_criteria_date",
	create?: GraphQLTypes["CriteriaSettingFields_criteria_date_Create"] | undefined,
	read?: GraphQLTypes["CriteriaSettingFields_criteria_date_Read"] | undefined,
	update?: GraphQLTypes["CriteriaSettingFields_criteria_date_Update"] | undefined,
	delete?: GraphQLTypes["CriteriaSettingFields_criteria_date_Delete"] | undefined
};
	["CriteriaSettingFields_criteria_date_Create"]: {
	__typename: "CriteriaSettingFields_criteria_date_Create",
	permission: boolean
};
	["CriteriaSettingFields_criteria_date_Read"]: {
	__typename: "CriteriaSettingFields_criteria_date_Read",
	permission: boolean
};
	["CriteriaSettingFields_criteria_date_Update"]: {
	__typename: "CriteriaSettingFields_criteria_date_Update",
	permission: boolean
};
	["CriteriaSettingFields_criteria_date_Delete"]: {
	__typename: "CriteriaSettingFields_criteria_date_Delete",
	permission: boolean
};
	["CriteriaSettingFields_criteria_audience"]: {
	__typename: "CriteriaSettingFields_criteria_audience",
	create?: GraphQLTypes["CriteriaSettingFields_criteria_audience_Create"] | undefined,
	read?: GraphQLTypes["CriteriaSettingFields_criteria_audience_Read"] | undefined,
	update?: GraphQLTypes["CriteriaSettingFields_criteria_audience_Update"] | undefined,
	delete?: GraphQLTypes["CriteriaSettingFields_criteria_audience_Delete"] | undefined
};
	["CriteriaSettingFields_criteria_audience_Create"]: {
	__typename: "CriteriaSettingFields_criteria_audience_Create",
	permission: boolean
};
	["CriteriaSettingFields_criteria_audience_Read"]: {
	__typename: "CriteriaSettingFields_criteria_audience_Read",
	permission: boolean
};
	["CriteriaSettingFields_criteria_audience_Update"]: {
	__typename: "CriteriaSettingFields_criteria_audience_Update",
	permission: boolean
};
	["CriteriaSettingFields_criteria_audience_Delete"]: {
	__typename: "CriteriaSettingFields_criteria_audience_Delete",
	permission: boolean
};
	["CriteriaSettingFields_criteria_category"]: {
	__typename: "CriteriaSettingFields_criteria_category",
	create?: GraphQLTypes["CriteriaSettingFields_criteria_category_Create"] | undefined,
	read?: GraphQLTypes["CriteriaSettingFields_criteria_category_Read"] | undefined,
	update?: GraphQLTypes["CriteriaSettingFields_criteria_category_Update"] | undefined,
	delete?: GraphQLTypes["CriteriaSettingFields_criteria_category_Delete"] | undefined
};
	["CriteriaSettingFields_criteria_category_Create"]: {
	__typename: "CriteriaSettingFields_criteria_category_Create",
	permission: boolean
};
	["CriteriaSettingFields_criteria_category_Read"]: {
	__typename: "CriteriaSettingFields_criteria_category_Read",
	permission: boolean
};
	["CriteriaSettingFields_criteria_category_Update"]: {
	__typename: "CriteriaSettingFields_criteria_category_Update",
	permission: boolean
};
	["CriteriaSettingFields_criteria_category_Delete"]: {
	__typename: "CriteriaSettingFields_criteria_category_Delete",
	permission: boolean
};
	["CriteriaSettingFields_criteria_tag"]: {
	__typename: "CriteriaSettingFields_criteria_tag",
	create?: GraphQLTypes["CriteriaSettingFields_criteria_tag_Create"] | undefined,
	read?: GraphQLTypes["CriteriaSettingFields_criteria_tag_Read"] | undefined,
	update?: GraphQLTypes["CriteriaSettingFields_criteria_tag_Update"] | undefined,
	delete?: GraphQLTypes["CriteriaSettingFields_criteria_tag_Delete"] | undefined
};
	["CriteriaSettingFields_criteria_tag_Create"]: {
	__typename: "CriteriaSettingFields_criteria_tag_Create",
	permission: boolean
};
	["CriteriaSettingFields_criteria_tag_Read"]: {
	__typename: "CriteriaSettingFields_criteria_tag_Read",
	permission: boolean
};
	["CriteriaSettingFields_criteria_tag_Update"]: {
	__typename: "CriteriaSettingFields_criteria_tag_Update",
	permission: boolean
};
	["CriteriaSettingFields_criteria_tag_Delete"]: {
	__typename: "CriteriaSettingFields_criteria_tag_Delete",
	permission: boolean
};
	["CriteriaSettingFields_criteria_region"]: {
	__typename: "CriteriaSettingFields_criteria_region",
	create?: GraphQLTypes["CriteriaSettingFields_criteria_region_Create"] | undefined,
	read?: GraphQLTypes["CriteriaSettingFields_criteria_region_Read"] | undefined,
	update?: GraphQLTypes["CriteriaSettingFields_criteria_region_Update"] | undefined,
	delete?: GraphQLTypes["CriteriaSettingFields_criteria_region_Delete"] | undefined
};
	["CriteriaSettingFields_criteria_region_Create"]: {
	__typename: "CriteriaSettingFields_criteria_region_Create",
	permission: boolean
};
	["CriteriaSettingFields_criteria_region_Read"]: {
	__typename: "CriteriaSettingFields_criteria_region_Read",
	permission: boolean
};
	["CriteriaSettingFields_criteria_region_Update"]: {
	__typename: "CriteriaSettingFields_criteria_region_Update",
	permission: boolean
};
	["CriteriaSettingFields_criteria_region_Delete"]: {
	__typename: "CriteriaSettingFields_criteria_region_Delete",
	permission: boolean
};
	["CriteriaSettingFields_updatedAt"]: {
	__typename: "CriteriaSettingFields_updatedAt",
	create?: GraphQLTypes["CriteriaSettingFields_updatedAt_Create"] | undefined,
	read?: GraphQLTypes["CriteriaSettingFields_updatedAt_Read"] | undefined,
	update?: GraphQLTypes["CriteriaSettingFields_updatedAt_Update"] | undefined,
	delete?: GraphQLTypes["CriteriaSettingFields_updatedAt_Delete"] | undefined
};
	["CriteriaSettingFields_updatedAt_Create"]: {
	__typename: "CriteriaSettingFields_updatedAt_Create",
	permission: boolean
};
	["CriteriaSettingFields_updatedAt_Read"]: {
	__typename: "CriteriaSettingFields_updatedAt_Read",
	permission: boolean
};
	["CriteriaSettingFields_updatedAt_Update"]: {
	__typename: "CriteriaSettingFields_updatedAt_Update",
	permission: boolean
};
	["CriteriaSettingFields_updatedAt_Delete"]: {
	__typename: "CriteriaSettingFields_updatedAt_Delete",
	permission: boolean
};
	["CriteriaSettingFields_createdAt"]: {
	__typename: "CriteriaSettingFields_createdAt",
	create?: GraphQLTypes["CriteriaSettingFields_createdAt_Create"] | undefined,
	read?: GraphQLTypes["CriteriaSettingFields_createdAt_Read"] | undefined,
	update?: GraphQLTypes["CriteriaSettingFields_createdAt_Update"] | undefined,
	delete?: GraphQLTypes["CriteriaSettingFields_createdAt_Delete"] | undefined
};
	["CriteriaSettingFields_createdAt_Create"]: {
	__typename: "CriteriaSettingFields_createdAt_Create",
	permission: boolean
};
	["CriteriaSettingFields_createdAt_Read"]: {
	__typename: "CriteriaSettingFields_createdAt_Read",
	permission: boolean
};
	["CriteriaSettingFields_createdAt_Update"]: {
	__typename: "CriteriaSettingFields_createdAt_Update",
	permission: boolean
};
	["CriteriaSettingFields_createdAt_Delete"]: {
	__typename: "CriteriaSettingFields_createdAt_Delete",
	permission: boolean
};
	["CriteriaSettingCreateAccess"]: {
	__typename: "CriteriaSettingCreateAccess",
	permission: boolean,
	where?: GraphQLTypes["JSONObject"] | undefined
};
	["CriteriaSettingReadAccess"]: {
	__typename: "CriteriaSettingReadAccess",
	permission: boolean,
	where?: GraphQLTypes["JSONObject"] | undefined
};
	["CriteriaSettingUpdateAccess"]: {
	__typename: "CriteriaSettingUpdateAccess",
	permission: boolean,
	where?: GraphQLTypes["JSONObject"] | undefined
};
	["CriteriaSettingDeleteAccess"]: {
	__typename: "CriteriaSettingDeleteAccess",
	permission: boolean,
	where?: GraphQLTypes["JSONObject"] | undefined
};
	["organiserSettingAccess"]: {
	__typename: "organiserSettingAccess",
	fields?: GraphQLTypes["OrganiserSettingFields"] | undefined,
	create?: GraphQLTypes["OrganiserSettingCreateAccess"] | undefined,
	read?: GraphQLTypes["OrganiserSettingReadAccess"] | undefined,
	update?: GraphQLTypes["OrganiserSettingUpdateAccess"] | undefined,
	delete?: GraphQLTypes["OrganiserSettingDeleteAccess"] | undefined
};
	["OrganiserSettingFields"]: {
	__typename: "OrganiserSettingFields",
	name?: GraphQLTypes["OrganiserSettingFields_name"] | undefined,
	organiserList?: GraphQLTypes["OrganiserSettingFields_organiserList"] | undefined,
	region?: GraphQLTypes["OrganiserSettingFields_region"] | undefined,
	updatedAt?: GraphQLTypes["OrganiserSettingFields_updatedAt"] | undefined,
	createdAt?: GraphQLTypes["OrganiserSettingFields_createdAt"] | undefined
};
	["OrganiserSettingFields_name"]: {
	__typename: "OrganiserSettingFields_name",
	create?: GraphQLTypes["OrganiserSettingFields_name_Create"] | undefined,
	read?: GraphQLTypes["OrganiserSettingFields_name_Read"] | undefined,
	update?: GraphQLTypes["OrganiserSettingFields_name_Update"] | undefined,
	delete?: GraphQLTypes["OrganiserSettingFields_name_Delete"] | undefined
};
	["OrganiserSettingFields_name_Create"]: {
	__typename: "OrganiserSettingFields_name_Create",
	permission: boolean
};
	["OrganiserSettingFields_name_Read"]: {
	__typename: "OrganiserSettingFields_name_Read",
	permission: boolean
};
	["OrganiserSettingFields_name_Update"]: {
	__typename: "OrganiserSettingFields_name_Update",
	permission: boolean
};
	["OrganiserSettingFields_name_Delete"]: {
	__typename: "OrganiserSettingFields_name_Delete",
	permission: boolean
};
	["OrganiserSettingFields_organiserList"]: {
	__typename: "OrganiserSettingFields_organiserList",
	create?: GraphQLTypes["OrganiserSettingFields_organiserList_Create"] | undefined,
	read?: GraphQLTypes["OrganiserSettingFields_organiserList_Read"] | undefined,
	update?: GraphQLTypes["OrganiserSettingFields_organiserList_Update"] | undefined,
	delete?: GraphQLTypes["OrganiserSettingFields_organiserList_Delete"] | undefined
};
	["OrganiserSettingFields_organiserList_Create"]: {
	__typename: "OrganiserSettingFields_organiserList_Create",
	permission: boolean
};
	["OrganiserSettingFields_organiserList_Read"]: {
	__typename: "OrganiserSettingFields_organiserList_Read",
	permission: boolean
};
	["OrganiserSettingFields_organiserList_Update"]: {
	__typename: "OrganiserSettingFields_organiserList_Update",
	permission: boolean
};
	["OrganiserSettingFields_organiserList_Delete"]: {
	__typename: "OrganiserSettingFields_organiserList_Delete",
	permission: boolean
};
	["OrganiserSettingFields_region"]: {
	__typename: "OrganiserSettingFields_region",
	create?: GraphQLTypes["OrganiserSettingFields_region_Create"] | undefined,
	read?: GraphQLTypes["OrganiserSettingFields_region_Read"] | undefined,
	update?: GraphQLTypes["OrganiserSettingFields_region_Update"] | undefined,
	delete?: GraphQLTypes["OrganiserSettingFields_region_Delete"] | undefined
};
	["OrganiserSettingFields_region_Create"]: {
	__typename: "OrganiserSettingFields_region_Create",
	permission: boolean
};
	["OrganiserSettingFields_region_Read"]: {
	__typename: "OrganiserSettingFields_region_Read",
	permission: boolean
};
	["OrganiserSettingFields_region_Update"]: {
	__typename: "OrganiserSettingFields_region_Update",
	permission: boolean
};
	["OrganiserSettingFields_region_Delete"]: {
	__typename: "OrganiserSettingFields_region_Delete",
	permission: boolean
};
	["OrganiserSettingFields_updatedAt"]: {
	__typename: "OrganiserSettingFields_updatedAt",
	create?: GraphQLTypes["OrganiserSettingFields_updatedAt_Create"] | undefined,
	read?: GraphQLTypes["OrganiserSettingFields_updatedAt_Read"] | undefined,
	update?: GraphQLTypes["OrganiserSettingFields_updatedAt_Update"] | undefined,
	delete?: GraphQLTypes["OrganiserSettingFields_updatedAt_Delete"] | undefined
};
	["OrganiserSettingFields_updatedAt_Create"]: {
	__typename: "OrganiserSettingFields_updatedAt_Create",
	permission: boolean
};
	["OrganiserSettingFields_updatedAt_Read"]: {
	__typename: "OrganiserSettingFields_updatedAt_Read",
	permission: boolean
};
	["OrganiserSettingFields_updatedAt_Update"]: {
	__typename: "OrganiserSettingFields_updatedAt_Update",
	permission: boolean
};
	["OrganiserSettingFields_updatedAt_Delete"]: {
	__typename: "OrganiserSettingFields_updatedAt_Delete",
	permission: boolean
};
	["OrganiserSettingFields_createdAt"]: {
	__typename: "OrganiserSettingFields_createdAt",
	create?: GraphQLTypes["OrganiserSettingFields_createdAt_Create"] | undefined,
	read?: GraphQLTypes["OrganiserSettingFields_createdAt_Read"] | undefined,
	update?: GraphQLTypes["OrganiserSettingFields_createdAt_Update"] | undefined,
	delete?: GraphQLTypes["OrganiserSettingFields_createdAt_Delete"] | undefined
};
	["OrganiserSettingFields_createdAt_Create"]: {
	__typename: "OrganiserSettingFields_createdAt_Create",
	permission: boolean
};
	["OrganiserSettingFields_createdAt_Read"]: {
	__typename: "OrganiserSettingFields_createdAt_Read",
	permission: boolean
};
	["OrganiserSettingFields_createdAt_Update"]: {
	__typename: "OrganiserSettingFields_createdAt_Update",
	permission: boolean
};
	["OrganiserSettingFields_createdAt_Delete"]: {
	__typename: "OrganiserSettingFields_createdAt_Delete",
	permission: boolean
};
	["OrganiserSettingCreateAccess"]: {
	__typename: "OrganiserSettingCreateAccess",
	permission: boolean,
	where?: GraphQLTypes["JSONObject"] | undefined
};
	["OrganiserSettingReadAccess"]: {
	__typename: "OrganiserSettingReadAccess",
	permission: boolean,
	where?: GraphQLTypes["JSONObject"] | undefined
};
	["OrganiserSettingUpdateAccess"]: {
	__typename: "OrganiserSettingUpdateAccess",
	permission: boolean,
	where?: GraphQLTypes["JSONObject"] | undefined
};
	["OrganiserSettingDeleteAccess"]: {
	__typename: "OrganiserSettingDeleteAccess",
	permission: boolean,
	where?: GraphQLTypes["JSONObject"] | undefined
};
	["post_settingAccess"]: {
	__typename: "post_settingAccess",
	fields?: GraphQLTypes["PostSettingFields"] | undefined,
	create?: GraphQLTypes["PostSettingCreateAccess"] | undefined,
	read?: GraphQLTypes["PostSettingReadAccess"] | undefined,
	update?: GraphQLTypes["PostSettingUpdateAccess"] | undefined,
	delete?: GraphQLTypes["PostSettingDeleteAccess"] | undefined
};
	["PostSettingFields"]: {
	__typename: "PostSettingFields",
	name?: GraphQLTypes["PostSettingFields_name"] | undefined,
	featuredPosts?: GraphQLTypes["PostSettingFields_featuredPosts"] | undefined,
	region?: GraphQLTypes["PostSettingFields_region"] | undefined,
	updatedAt?: GraphQLTypes["PostSettingFields_updatedAt"] | undefined,
	createdAt?: GraphQLTypes["PostSettingFields_createdAt"] | undefined
};
	["PostSettingFields_name"]: {
	__typename: "PostSettingFields_name",
	create?: GraphQLTypes["PostSettingFields_name_Create"] | undefined,
	read?: GraphQLTypes["PostSettingFields_name_Read"] | undefined,
	update?: GraphQLTypes["PostSettingFields_name_Update"] | undefined,
	delete?: GraphQLTypes["PostSettingFields_name_Delete"] | undefined
};
	["PostSettingFields_name_Create"]: {
	__typename: "PostSettingFields_name_Create",
	permission: boolean
};
	["PostSettingFields_name_Read"]: {
	__typename: "PostSettingFields_name_Read",
	permission: boolean
};
	["PostSettingFields_name_Update"]: {
	__typename: "PostSettingFields_name_Update",
	permission: boolean
};
	["PostSettingFields_name_Delete"]: {
	__typename: "PostSettingFields_name_Delete",
	permission: boolean
};
	["PostSettingFields_featuredPosts"]: {
	__typename: "PostSettingFields_featuredPosts",
	create?: GraphQLTypes["PostSettingFields_featuredPosts_Create"] | undefined,
	read?: GraphQLTypes["PostSettingFields_featuredPosts_Read"] | undefined,
	update?: GraphQLTypes["PostSettingFields_featuredPosts_Update"] | undefined,
	delete?: GraphQLTypes["PostSettingFields_featuredPosts_Delete"] | undefined
};
	["PostSettingFields_featuredPosts_Create"]: {
	__typename: "PostSettingFields_featuredPosts_Create",
	permission: boolean
};
	["PostSettingFields_featuredPosts_Read"]: {
	__typename: "PostSettingFields_featuredPosts_Read",
	permission: boolean
};
	["PostSettingFields_featuredPosts_Update"]: {
	__typename: "PostSettingFields_featuredPosts_Update",
	permission: boolean
};
	["PostSettingFields_featuredPosts_Delete"]: {
	__typename: "PostSettingFields_featuredPosts_Delete",
	permission: boolean
};
	["PostSettingFields_region"]: {
	__typename: "PostSettingFields_region",
	create?: GraphQLTypes["PostSettingFields_region_Create"] | undefined,
	read?: GraphQLTypes["PostSettingFields_region_Read"] | undefined,
	update?: GraphQLTypes["PostSettingFields_region_Update"] | undefined,
	delete?: GraphQLTypes["PostSettingFields_region_Delete"] | undefined
};
	["PostSettingFields_region_Create"]: {
	__typename: "PostSettingFields_region_Create",
	permission: boolean
};
	["PostSettingFields_region_Read"]: {
	__typename: "PostSettingFields_region_Read",
	permission: boolean
};
	["PostSettingFields_region_Update"]: {
	__typename: "PostSettingFields_region_Update",
	permission: boolean
};
	["PostSettingFields_region_Delete"]: {
	__typename: "PostSettingFields_region_Delete",
	permission: boolean
};
	["PostSettingFields_updatedAt"]: {
	__typename: "PostSettingFields_updatedAt",
	create?: GraphQLTypes["PostSettingFields_updatedAt_Create"] | undefined,
	read?: GraphQLTypes["PostSettingFields_updatedAt_Read"] | undefined,
	update?: GraphQLTypes["PostSettingFields_updatedAt_Update"] | undefined,
	delete?: GraphQLTypes["PostSettingFields_updatedAt_Delete"] | undefined
};
	["PostSettingFields_updatedAt_Create"]: {
	__typename: "PostSettingFields_updatedAt_Create",
	permission: boolean
};
	["PostSettingFields_updatedAt_Read"]: {
	__typename: "PostSettingFields_updatedAt_Read",
	permission: boolean
};
	["PostSettingFields_updatedAt_Update"]: {
	__typename: "PostSettingFields_updatedAt_Update",
	permission: boolean
};
	["PostSettingFields_updatedAt_Delete"]: {
	__typename: "PostSettingFields_updatedAt_Delete",
	permission: boolean
};
	["PostSettingFields_createdAt"]: {
	__typename: "PostSettingFields_createdAt",
	create?: GraphQLTypes["PostSettingFields_createdAt_Create"] | undefined,
	read?: GraphQLTypes["PostSettingFields_createdAt_Read"] | undefined,
	update?: GraphQLTypes["PostSettingFields_createdAt_Update"] | undefined,
	delete?: GraphQLTypes["PostSettingFields_createdAt_Delete"] | undefined
};
	["PostSettingFields_createdAt_Create"]: {
	__typename: "PostSettingFields_createdAt_Create",
	permission: boolean
};
	["PostSettingFields_createdAt_Read"]: {
	__typename: "PostSettingFields_createdAt_Read",
	permission: boolean
};
	["PostSettingFields_createdAt_Update"]: {
	__typename: "PostSettingFields_createdAt_Update",
	permission: boolean
};
	["PostSettingFields_createdAt_Delete"]: {
	__typename: "PostSettingFields_createdAt_Delete",
	permission: boolean
};
	["PostSettingCreateAccess"]: {
	__typename: "PostSettingCreateAccess",
	permission: boolean,
	where?: GraphQLTypes["JSONObject"] | undefined
};
	["PostSettingReadAccess"]: {
	__typename: "PostSettingReadAccess",
	permission: boolean,
	where?: GraphQLTypes["JSONObject"] | undefined
};
	["PostSettingUpdateAccess"]: {
	__typename: "PostSettingUpdateAccess",
	permission: boolean,
	where?: GraphQLTypes["JSONObject"] | undefined
};
	["PostSettingDeleteAccess"]: {
	__typename: "PostSettingDeleteAccess",
	permission: boolean,
	where?: GraphQLTypes["JSONObject"] | undefined
};
	["homepageSettingAccess"]: {
	__typename: "homepageSettingAccess",
	fields?: GraphQLTypes["HomepageSettingFields"] | undefined,
	create?: GraphQLTypes["HomepageSettingCreateAccess"] | undefined,
	read?: GraphQLTypes["HomepageSettingReadAccess"] | undefined,
	update?: GraphQLTypes["HomepageSettingUpdateAccess"] | undefined,
	delete?: GraphQLTypes["HomepageSettingDeleteAccess"] | undefined
};
	["HomepageSettingFields"]: {
	__typename: "HomepageSettingFields",
	name?: GraphQLTypes["HomepageSettingFields_name"] | undefined,
	region?: GraphQLTypes["HomepageSettingFields_region"] | undefined,
	sections?: GraphQLTypes["HomepageSettingFields_sections"] | undefined,
	updatedAt?: GraphQLTypes["HomepageSettingFields_updatedAt"] | undefined,
	createdAt?: GraphQLTypes["HomepageSettingFields_createdAt"] | undefined
};
	["HomepageSettingFields_name"]: {
	__typename: "HomepageSettingFields_name",
	create?: GraphQLTypes["HomepageSettingFields_name_Create"] | undefined,
	read?: GraphQLTypes["HomepageSettingFields_name_Read"] | undefined,
	update?: GraphQLTypes["HomepageSettingFields_name_Update"] | undefined,
	delete?: GraphQLTypes["HomepageSettingFields_name_Delete"] | undefined
};
	["HomepageSettingFields_name_Create"]: {
	__typename: "HomepageSettingFields_name_Create",
	permission: boolean
};
	["HomepageSettingFields_name_Read"]: {
	__typename: "HomepageSettingFields_name_Read",
	permission: boolean
};
	["HomepageSettingFields_name_Update"]: {
	__typename: "HomepageSettingFields_name_Update",
	permission: boolean
};
	["HomepageSettingFields_name_Delete"]: {
	__typename: "HomepageSettingFields_name_Delete",
	permission: boolean
};
	["HomepageSettingFields_region"]: {
	__typename: "HomepageSettingFields_region",
	create?: GraphQLTypes["HomepageSettingFields_region_Create"] | undefined,
	read?: GraphQLTypes["HomepageSettingFields_region_Read"] | undefined,
	update?: GraphQLTypes["HomepageSettingFields_region_Update"] | undefined,
	delete?: GraphQLTypes["HomepageSettingFields_region_Delete"] | undefined
};
	["HomepageSettingFields_region_Create"]: {
	__typename: "HomepageSettingFields_region_Create",
	permission: boolean
};
	["HomepageSettingFields_region_Read"]: {
	__typename: "HomepageSettingFields_region_Read",
	permission: boolean
};
	["HomepageSettingFields_region_Update"]: {
	__typename: "HomepageSettingFields_region_Update",
	permission: boolean
};
	["HomepageSettingFields_region_Delete"]: {
	__typename: "HomepageSettingFields_region_Delete",
	permission: boolean
};
	["HomepageSettingFields_sections"]: {
	__typename: "HomepageSettingFields_sections",
	create?: GraphQLTypes["HomepageSettingFields_sections_Create"] | undefined,
	read?: GraphQLTypes["HomepageSettingFields_sections_Read"] | undefined,
	update?: GraphQLTypes["HomepageSettingFields_sections_Update"] | undefined,
	delete?: GraphQLTypes["HomepageSettingFields_sections_Delete"] | undefined
};
	["HomepageSettingFields_sections_Create"]: {
	__typename: "HomepageSettingFields_sections_Create",
	permission: boolean
};
	["HomepageSettingFields_sections_Read"]: {
	__typename: "HomepageSettingFields_sections_Read",
	permission: boolean
};
	["HomepageSettingFields_sections_Update"]: {
	__typename: "HomepageSettingFields_sections_Update",
	permission: boolean
};
	["HomepageSettingFields_sections_Delete"]: {
	__typename: "HomepageSettingFields_sections_Delete",
	permission: boolean
};
	["HomepageSettingFields_updatedAt"]: {
	__typename: "HomepageSettingFields_updatedAt",
	create?: GraphQLTypes["HomepageSettingFields_updatedAt_Create"] | undefined,
	read?: GraphQLTypes["HomepageSettingFields_updatedAt_Read"] | undefined,
	update?: GraphQLTypes["HomepageSettingFields_updatedAt_Update"] | undefined,
	delete?: GraphQLTypes["HomepageSettingFields_updatedAt_Delete"] | undefined
};
	["HomepageSettingFields_updatedAt_Create"]: {
	__typename: "HomepageSettingFields_updatedAt_Create",
	permission: boolean
};
	["HomepageSettingFields_updatedAt_Read"]: {
	__typename: "HomepageSettingFields_updatedAt_Read",
	permission: boolean
};
	["HomepageSettingFields_updatedAt_Update"]: {
	__typename: "HomepageSettingFields_updatedAt_Update",
	permission: boolean
};
	["HomepageSettingFields_updatedAt_Delete"]: {
	__typename: "HomepageSettingFields_updatedAt_Delete",
	permission: boolean
};
	["HomepageSettingFields_createdAt"]: {
	__typename: "HomepageSettingFields_createdAt",
	create?: GraphQLTypes["HomepageSettingFields_createdAt_Create"] | undefined,
	read?: GraphQLTypes["HomepageSettingFields_createdAt_Read"] | undefined,
	update?: GraphQLTypes["HomepageSettingFields_createdAt_Update"] | undefined,
	delete?: GraphQLTypes["HomepageSettingFields_createdAt_Delete"] | undefined
};
	["HomepageSettingFields_createdAt_Create"]: {
	__typename: "HomepageSettingFields_createdAt_Create",
	permission: boolean
};
	["HomepageSettingFields_createdAt_Read"]: {
	__typename: "HomepageSettingFields_createdAt_Read",
	permission: boolean
};
	["HomepageSettingFields_createdAt_Update"]: {
	__typename: "HomepageSettingFields_createdAt_Update",
	permission: boolean
};
	["HomepageSettingFields_createdAt_Delete"]: {
	__typename: "HomepageSettingFields_createdAt_Delete",
	permission: boolean
};
	["HomepageSettingCreateAccess"]: {
	__typename: "HomepageSettingCreateAccess",
	permission: boolean,
	where?: GraphQLTypes["JSONObject"] | undefined
};
	["HomepageSettingReadAccess"]: {
	__typename: "HomepageSettingReadAccess",
	permission: boolean,
	where?: GraphQLTypes["JSONObject"] | undefined
};
	["HomepageSettingUpdateAccess"]: {
	__typename: "HomepageSettingUpdateAccess",
	permission: boolean,
	where?: GraphQLTypes["JSONObject"] | undefined
};
	["HomepageSettingDeleteAccess"]: {
	__typename: "HomepageSettingDeleteAccess",
	permission: boolean,
	where?: GraphQLTypes["JSONObject"] | undefined
};
	["banner_sectionAccess"]: {
	__typename: "banner_sectionAccess",
	fields?: GraphQLTypes["BannerSectionFields"] | undefined,
	create?: GraphQLTypes["BannerSectionCreateAccess"] | undefined,
	read?: GraphQLTypes["BannerSectionReadAccess"] | undefined,
	update?: GraphQLTypes["BannerSectionUpdateAccess"] | undefined,
	delete?: GraphQLTypes["BannerSectionDeleteAccess"] | undefined
};
	["BannerSectionFields"]: {
	__typename: "BannerSectionFields",
	name?: GraphQLTypes["BannerSectionFields_name"] | undefined,
	slug?: GraphQLTypes["BannerSectionFields_slug"] | undefined,
	banners?: GraphQLTypes["BannerSectionFields_banners"] | undefined,
	updatedAt?: GraphQLTypes["BannerSectionFields_updatedAt"] | undefined,
	createdAt?: GraphQLTypes["BannerSectionFields_createdAt"] | undefined
};
	["BannerSectionFields_name"]: {
	__typename: "BannerSectionFields_name",
	create?: GraphQLTypes["BannerSectionFields_name_Create"] | undefined,
	read?: GraphQLTypes["BannerSectionFields_name_Read"] | undefined,
	update?: GraphQLTypes["BannerSectionFields_name_Update"] | undefined,
	delete?: GraphQLTypes["BannerSectionFields_name_Delete"] | undefined
};
	["BannerSectionFields_name_Create"]: {
	__typename: "BannerSectionFields_name_Create",
	permission: boolean
};
	["BannerSectionFields_name_Read"]: {
	__typename: "BannerSectionFields_name_Read",
	permission: boolean
};
	["BannerSectionFields_name_Update"]: {
	__typename: "BannerSectionFields_name_Update",
	permission: boolean
};
	["BannerSectionFields_name_Delete"]: {
	__typename: "BannerSectionFields_name_Delete",
	permission: boolean
};
	["BannerSectionFields_slug"]: {
	__typename: "BannerSectionFields_slug",
	create?: GraphQLTypes["BannerSectionFields_slug_Create"] | undefined,
	read?: GraphQLTypes["BannerSectionFields_slug_Read"] | undefined,
	update?: GraphQLTypes["BannerSectionFields_slug_Update"] | undefined,
	delete?: GraphQLTypes["BannerSectionFields_slug_Delete"] | undefined
};
	["BannerSectionFields_slug_Create"]: {
	__typename: "BannerSectionFields_slug_Create",
	permission: boolean
};
	["BannerSectionFields_slug_Read"]: {
	__typename: "BannerSectionFields_slug_Read",
	permission: boolean
};
	["BannerSectionFields_slug_Update"]: {
	__typename: "BannerSectionFields_slug_Update",
	permission: boolean
};
	["BannerSectionFields_slug_Delete"]: {
	__typename: "BannerSectionFields_slug_Delete",
	permission: boolean
};
	["BannerSectionFields_banners"]: {
	__typename: "BannerSectionFields_banners",
	create?: GraphQLTypes["BannerSectionFields_banners_Create"] | undefined,
	read?: GraphQLTypes["BannerSectionFields_banners_Read"] | undefined,
	update?: GraphQLTypes["BannerSectionFields_banners_Update"] | undefined,
	delete?: GraphQLTypes["BannerSectionFields_banners_Delete"] | undefined,
	fields?: GraphQLTypes["BannerSectionFields_banners_Fields"] | undefined
};
	["BannerSectionFields_banners_Create"]: {
	__typename: "BannerSectionFields_banners_Create",
	permission: boolean
};
	["BannerSectionFields_banners_Read"]: {
	__typename: "BannerSectionFields_banners_Read",
	permission: boolean
};
	["BannerSectionFields_banners_Update"]: {
	__typename: "BannerSectionFields_banners_Update",
	permission: boolean
};
	["BannerSectionFields_banners_Delete"]: {
	__typename: "BannerSectionFields_banners_Delete",
	permission: boolean
};
	["BannerSectionFields_banners_Fields"]: {
	__typename: "BannerSectionFields_banners_Fields",
	name?: GraphQLTypes["BannerSectionFields_banners_name"] | undefined,
	status?: GraphQLTypes["BannerSectionFields_banners_status"] | undefined,
	desktop?: GraphQLTypes["BannerSectionFields_banners_desktop"] | undefined,
	bannerStartDate?: GraphQLTypes["BannerSectionFields_banners_bannerStartDate"] | undefined,
	bannerEndDate?: GraphQLTypes["BannerSectionFields_banners_bannerEndDate"] | undefined,
	region?: GraphQLTypes["BannerSectionFields_banners_region"] | undefined,
	id?: GraphQLTypes["BannerSectionFields_banners_id"] | undefined
};
	["BannerSectionFields_banners_name"]: {
	__typename: "BannerSectionFields_banners_name",
	create?: GraphQLTypes["BannerSectionFields_banners_name_Create"] | undefined,
	read?: GraphQLTypes["BannerSectionFields_banners_name_Read"] | undefined,
	update?: GraphQLTypes["BannerSectionFields_banners_name_Update"] | undefined,
	delete?: GraphQLTypes["BannerSectionFields_banners_name_Delete"] | undefined
};
	["BannerSectionFields_banners_name_Create"]: {
	__typename: "BannerSectionFields_banners_name_Create",
	permission: boolean
};
	["BannerSectionFields_banners_name_Read"]: {
	__typename: "BannerSectionFields_banners_name_Read",
	permission: boolean
};
	["BannerSectionFields_banners_name_Update"]: {
	__typename: "BannerSectionFields_banners_name_Update",
	permission: boolean
};
	["BannerSectionFields_banners_name_Delete"]: {
	__typename: "BannerSectionFields_banners_name_Delete",
	permission: boolean
};
	["BannerSectionFields_banners_status"]: {
	__typename: "BannerSectionFields_banners_status",
	create?: GraphQLTypes["BannerSectionFields_banners_status_Create"] | undefined,
	read?: GraphQLTypes["BannerSectionFields_banners_status_Read"] | undefined,
	update?: GraphQLTypes["BannerSectionFields_banners_status_Update"] | undefined,
	delete?: GraphQLTypes["BannerSectionFields_banners_status_Delete"] | undefined
};
	["BannerSectionFields_banners_status_Create"]: {
	__typename: "BannerSectionFields_banners_status_Create",
	permission: boolean
};
	["BannerSectionFields_banners_status_Read"]: {
	__typename: "BannerSectionFields_banners_status_Read",
	permission: boolean
};
	["BannerSectionFields_banners_status_Update"]: {
	__typename: "BannerSectionFields_banners_status_Update",
	permission: boolean
};
	["BannerSectionFields_banners_status_Delete"]: {
	__typename: "BannerSectionFields_banners_status_Delete",
	permission: boolean
};
	["BannerSectionFields_banners_desktop"]: {
	__typename: "BannerSectionFields_banners_desktop",
	create?: GraphQLTypes["BannerSectionFields_banners_desktop_Create"] | undefined,
	read?: GraphQLTypes["BannerSectionFields_banners_desktop_Read"] | undefined,
	update?: GraphQLTypes["BannerSectionFields_banners_desktop_Update"] | undefined,
	delete?: GraphQLTypes["BannerSectionFields_banners_desktop_Delete"] | undefined
};
	["BannerSectionFields_banners_desktop_Create"]: {
	__typename: "BannerSectionFields_banners_desktop_Create",
	permission: boolean
};
	["BannerSectionFields_banners_desktop_Read"]: {
	__typename: "BannerSectionFields_banners_desktop_Read",
	permission: boolean
};
	["BannerSectionFields_banners_desktop_Update"]: {
	__typename: "BannerSectionFields_banners_desktop_Update",
	permission: boolean
};
	["BannerSectionFields_banners_desktop_Delete"]: {
	__typename: "BannerSectionFields_banners_desktop_Delete",
	permission: boolean
};
	["BannerSectionFields_banners_bannerStartDate"]: {
	__typename: "BannerSectionFields_banners_bannerStartDate",
	create?: GraphQLTypes["BannerSectionFields_banners_bannerStartDate_Create"] | undefined,
	read?: GraphQLTypes["BannerSectionFields_banners_bannerStartDate_Read"] | undefined,
	update?: GraphQLTypes["BannerSectionFields_banners_bannerStartDate_Update"] | undefined,
	delete?: GraphQLTypes["BannerSectionFields_banners_bannerStartDate_Delete"] | undefined
};
	["BannerSectionFields_banners_bannerStartDate_Create"]: {
	__typename: "BannerSectionFields_banners_bannerStartDate_Create",
	permission: boolean
};
	["BannerSectionFields_banners_bannerStartDate_Read"]: {
	__typename: "BannerSectionFields_banners_bannerStartDate_Read",
	permission: boolean
};
	["BannerSectionFields_banners_bannerStartDate_Update"]: {
	__typename: "BannerSectionFields_banners_bannerStartDate_Update",
	permission: boolean
};
	["BannerSectionFields_banners_bannerStartDate_Delete"]: {
	__typename: "BannerSectionFields_banners_bannerStartDate_Delete",
	permission: boolean
};
	["BannerSectionFields_banners_bannerEndDate"]: {
	__typename: "BannerSectionFields_banners_bannerEndDate",
	create?: GraphQLTypes["BannerSectionFields_banners_bannerEndDate_Create"] | undefined,
	read?: GraphQLTypes["BannerSectionFields_banners_bannerEndDate_Read"] | undefined,
	update?: GraphQLTypes["BannerSectionFields_banners_bannerEndDate_Update"] | undefined,
	delete?: GraphQLTypes["BannerSectionFields_banners_bannerEndDate_Delete"] | undefined
};
	["BannerSectionFields_banners_bannerEndDate_Create"]: {
	__typename: "BannerSectionFields_banners_bannerEndDate_Create",
	permission: boolean
};
	["BannerSectionFields_banners_bannerEndDate_Read"]: {
	__typename: "BannerSectionFields_banners_bannerEndDate_Read",
	permission: boolean
};
	["BannerSectionFields_banners_bannerEndDate_Update"]: {
	__typename: "BannerSectionFields_banners_bannerEndDate_Update",
	permission: boolean
};
	["BannerSectionFields_banners_bannerEndDate_Delete"]: {
	__typename: "BannerSectionFields_banners_bannerEndDate_Delete",
	permission: boolean
};
	["BannerSectionFields_banners_region"]: {
	__typename: "BannerSectionFields_banners_region",
	create?: GraphQLTypes["BannerSectionFields_banners_region_Create"] | undefined,
	read?: GraphQLTypes["BannerSectionFields_banners_region_Read"] | undefined,
	update?: GraphQLTypes["BannerSectionFields_banners_region_Update"] | undefined,
	delete?: GraphQLTypes["BannerSectionFields_banners_region_Delete"] | undefined
};
	["BannerSectionFields_banners_region_Create"]: {
	__typename: "BannerSectionFields_banners_region_Create",
	permission: boolean
};
	["BannerSectionFields_banners_region_Read"]: {
	__typename: "BannerSectionFields_banners_region_Read",
	permission: boolean
};
	["BannerSectionFields_banners_region_Update"]: {
	__typename: "BannerSectionFields_banners_region_Update",
	permission: boolean
};
	["BannerSectionFields_banners_region_Delete"]: {
	__typename: "BannerSectionFields_banners_region_Delete",
	permission: boolean
};
	["BannerSectionFields_banners_id"]: {
	__typename: "BannerSectionFields_banners_id",
	create?: GraphQLTypes["BannerSectionFields_banners_id_Create"] | undefined,
	read?: GraphQLTypes["BannerSectionFields_banners_id_Read"] | undefined,
	update?: GraphQLTypes["BannerSectionFields_banners_id_Update"] | undefined,
	delete?: GraphQLTypes["BannerSectionFields_banners_id_Delete"] | undefined
};
	["BannerSectionFields_banners_id_Create"]: {
	__typename: "BannerSectionFields_banners_id_Create",
	permission: boolean
};
	["BannerSectionFields_banners_id_Read"]: {
	__typename: "BannerSectionFields_banners_id_Read",
	permission: boolean
};
	["BannerSectionFields_banners_id_Update"]: {
	__typename: "BannerSectionFields_banners_id_Update",
	permission: boolean
};
	["BannerSectionFields_banners_id_Delete"]: {
	__typename: "BannerSectionFields_banners_id_Delete",
	permission: boolean
};
	["BannerSectionFields_updatedAt"]: {
	__typename: "BannerSectionFields_updatedAt",
	create?: GraphQLTypes["BannerSectionFields_updatedAt_Create"] | undefined,
	read?: GraphQLTypes["BannerSectionFields_updatedAt_Read"] | undefined,
	update?: GraphQLTypes["BannerSectionFields_updatedAt_Update"] | undefined,
	delete?: GraphQLTypes["BannerSectionFields_updatedAt_Delete"] | undefined
};
	["BannerSectionFields_updatedAt_Create"]: {
	__typename: "BannerSectionFields_updatedAt_Create",
	permission: boolean
};
	["BannerSectionFields_updatedAt_Read"]: {
	__typename: "BannerSectionFields_updatedAt_Read",
	permission: boolean
};
	["BannerSectionFields_updatedAt_Update"]: {
	__typename: "BannerSectionFields_updatedAt_Update",
	permission: boolean
};
	["BannerSectionFields_updatedAt_Delete"]: {
	__typename: "BannerSectionFields_updatedAt_Delete",
	permission: boolean
};
	["BannerSectionFields_createdAt"]: {
	__typename: "BannerSectionFields_createdAt",
	create?: GraphQLTypes["BannerSectionFields_createdAt_Create"] | undefined,
	read?: GraphQLTypes["BannerSectionFields_createdAt_Read"] | undefined,
	update?: GraphQLTypes["BannerSectionFields_createdAt_Update"] | undefined,
	delete?: GraphQLTypes["BannerSectionFields_createdAt_Delete"] | undefined
};
	["BannerSectionFields_createdAt_Create"]: {
	__typename: "BannerSectionFields_createdAt_Create",
	permission: boolean
};
	["BannerSectionFields_createdAt_Read"]: {
	__typename: "BannerSectionFields_createdAt_Read",
	permission: boolean
};
	["BannerSectionFields_createdAt_Update"]: {
	__typename: "BannerSectionFields_createdAt_Update",
	permission: boolean
};
	["BannerSectionFields_createdAt_Delete"]: {
	__typename: "BannerSectionFields_createdAt_Delete",
	permission: boolean
};
	["BannerSectionCreateAccess"]: {
	__typename: "BannerSectionCreateAccess",
	permission: boolean,
	where?: GraphQLTypes["JSONObject"] | undefined
};
	["BannerSectionReadAccess"]: {
	__typename: "BannerSectionReadAccess",
	permission: boolean,
	where?: GraphQLTypes["JSONObject"] | undefined
};
	["BannerSectionUpdateAccess"]: {
	__typename: "BannerSectionUpdateAccess",
	permission: boolean,
	where?: GraphQLTypes["JSONObject"] | undefined
};
	["BannerSectionDeleteAccess"]: {
	__typename: "BannerSectionDeleteAccess",
	permission: boolean,
	where?: GraphQLTypes["JSONObject"] | undefined
};
	["macao_banner_sectionAccess"]: {
	__typename: "macao_banner_sectionAccess",
	fields?: GraphQLTypes["MacaoBannerSectionFields"] | undefined,
	create?: GraphQLTypes["MacaoBannerSectionCreateAccess"] | undefined,
	read?: GraphQLTypes["MacaoBannerSectionReadAccess"] | undefined,
	update?: GraphQLTypes["MacaoBannerSectionUpdateAccess"] | undefined,
	delete?: GraphQLTypes["MacaoBannerSectionDeleteAccess"] | undefined
};
	["MacaoBannerSectionFields"]: {
	__typename: "MacaoBannerSectionFields",
	name?: GraphQLTypes["MacaoBannerSectionFields_name"] | undefined,
	slug?: GraphQLTypes["MacaoBannerSectionFields_slug"] | undefined,
	banners?: GraphQLTypes["MacaoBannerSectionFields_banners"] | undefined,
	updatedAt?: GraphQLTypes["MacaoBannerSectionFields_updatedAt"] | undefined,
	createdAt?: GraphQLTypes["MacaoBannerSectionFields_createdAt"] | undefined
};
	["MacaoBannerSectionFields_name"]: {
	__typename: "MacaoBannerSectionFields_name",
	create?: GraphQLTypes["MacaoBannerSectionFields_name_Create"] | undefined,
	read?: GraphQLTypes["MacaoBannerSectionFields_name_Read"] | undefined,
	update?: GraphQLTypes["MacaoBannerSectionFields_name_Update"] | undefined,
	delete?: GraphQLTypes["MacaoBannerSectionFields_name_Delete"] | undefined
};
	["MacaoBannerSectionFields_name_Create"]: {
	__typename: "MacaoBannerSectionFields_name_Create",
	permission: boolean
};
	["MacaoBannerSectionFields_name_Read"]: {
	__typename: "MacaoBannerSectionFields_name_Read",
	permission: boolean
};
	["MacaoBannerSectionFields_name_Update"]: {
	__typename: "MacaoBannerSectionFields_name_Update",
	permission: boolean
};
	["MacaoBannerSectionFields_name_Delete"]: {
	__typename: "MacaoBannerSectionFields_name_Delete",
	permission: boolean
};
	["MacaoBannerSectionFields_slug"]: {
	__typename: "MacaoBannerSectionFields_slug",
	create?: GraphQLTypes["MacaoBannerSectionFields_slug_Create"] | undefined,
	read?: GraphQLTypes["MacaoBannerSectionFields_slug_Read"] | undefined,
	update?: GraphQLTypes["MacaoBannerSectionFields_slug_Update"] | undefined,
	delete?: GraphQLTypes["MacaoBannerSectionFields_slug_Delete"] | undefined
};
	["MacaoBannerSectionFields_slug_Create"]: {
	__typename: "MacaoBannerSectionFields_slug_Create",
	permission: boolean
};
	["MacaoBannerSectionFields_slug_Read"]: {
	__typename: "MacaoBannerSectionFields_slug_Read",
	permission: boolean
};
	["MacaoBannerSectionFields_slug_Update"]: {
	__typename: "MacaoBannerSectionFields_slug_Update",
	permission: boolean
};
	["MacaoBannerSectionFields_slug_Delete"]: {
	__typename: "MacaoBannerSectionFields_slug_Delete",
	permission: boolean
};
	["MacaoBannerSectionFields_banners"]: {
	__typename: "MacaoBannerSectionFields_banners",
	create?: GraphQLTypes["MacaoBannerSectionFields_banners_Create"] | undefined,
	read?: GraphQLTypes["MacaoBannerSectionFields_banners_Read"] | undefined,
	update?: GraphQLTypes["MacaoBannerSectionFields_banners_Update"] | undefined,
	delete?: GraphQLTypes["MacaoBannerSectionFields_banners_Delete"] | undefined,
	fields?: GraphQLTypes["MacaoBannerSectionFields_banners_Fields"] | undefined
};
	["MacaoBannerSectionFields_banners_Create"]: {
	__typename: "MacaoBannerSectionFields_banners_Create",
	permission: boolean
};
	["MacaoBannerSectionFields_banners_Read"]: {
	__typename: "MacaoBannerSectionFields_banners_Read",
	permission: boolean
};
	["MacaoBannerSectionFields_banners_Update"]: {
	__typename: "MacaoBannerSectionFields_banners_Update",
	permission: boolean
};
	["MacaoBannerSectionFields_banners_Delete"]: {
	__typename: "MacaoBannerSectionFields_banners_Delete",
	permission: boolean
};
	["MacaoBannerSectionFields_banners_Fields"]: {
	__typename: "MacaoBannerSectionFields_banners_Fields",
	name?: GraphQLTypes["MacaoBannerSectionFields_banners_name"] | undefined,
	status?: GraphQLTypes["MacaoBannerSectionFields_banners_status"] | undefined,
	desktop?: GraphQLTypes["MacaoBannerSectionFields_banners_desktop"] | undefined,
	bannerStartDate?: GraphQLTypes["MacaoBannerSectionFields_banners_bannerStartDate"] | undefined,
	bannerEndDate?: GraphQLTypes["MacaoBannerSectionFields_banners_bannerEndDate"] | undefined,
	region?: GraphQLTypes["MacaoBannerSectionFields_banners_region"] | undefined,
	id?: GraphQLTypes["MacaoBannerSectionFields_banners_id"] | undefined
};
	["MacaoBannerSectionFields_banners_name"]: {
	__typename: "MacaoBannerSectionFields_banners_name",
	create?: GraphQLTypes["MacaoBannerSectionFields_banners_name_Create"] | undefined,
	read?: GraphQLTypes["MacaoBannerSectionFields_banners_name_Read"] | undefined,
	update?: GraphQLTypes["MacaoBannerSectionFields_banners_name_Update"] | undefined,
	delete?: GraphQLTypes["MacaoBannerSectionFields_banners_name_Delete"] | undefined
};
	["MacaoBannerSectionFields_banners_name_Create"]: {
	__typename: "MacaoBannerSectionFields_banners_name_Create",
	permission: boolean
};
	["MacaoBannerSectionFields_banners_name_Read"]: {
	__typename: "MacaoBannerSectionFields_banners_name_Read",
	permission: boolean
};
	["MacaoBannerSectionFields_banners_name_Update"]: {
	__typename: "MacaoBannerSectionFields_banners_name_Update",
	permission: boolean
};
	["MacaoBannerSectionFields_banners_name_Delete"]: {
	__typename: "MacaoBannerSectionFields_banners_name_Delete",
	permission: boolean
};
	["MacaoBannerSectionFields_banners_status"]: {
	__typename: "MacaoBannerSectionFields_banners_status",
	create?: GraphQLTypes["MacaoBannerSectionFields_banners_status_Create"] | undefined,
	read?: GraphQLTypes["MacaoBannerSectionFields_banners_status_Read"] | undefined,
	update?: GraphQLTypes["MacaoBannerSectionFields_banners_status_Update"] | undefined,
	delete?: GraphQLTypes["MacaoBannerSectionFields_banners_status_Delete"] | undefined
};
	["MacaoBannerSectionFields_banners_status_Create"]: {
	__typename: "MacaoBannerSectionFields_banners_status_Create",
	permission: boolean
};
	["MacaoBannerSectionFields_banners_status_Read"]: {
	__typename: "MacaoBannerSectionFields_banners_status_Read",
	permission: boolean
};
	["MacaoBannerSectionFields_banners_status_Update"]: {
	__typename: "MacaoBannerSectionFields_banners_status_Update",
	permission: boolean
};
	["MacaoBannerSectionFields_banners_status_Delete"]: {
	__typename: "MacaoBannerSectionFields_banners_status_Delete",
	permission: boolean
};
	["MacaoBannerSectionFields_banners_desktop"]: {
	__typename: "MacaoBannerSectionFields_banners_desktop",
	create?: GraphQLTypes["MacaoBannerSectionFields_banners_desktop_Create"] | undefined,
	read?: GraphQLTypes["MacaoBannerSectionFields_banners_desktop_Read"] | undefined,
	update?: GraphQLTypes["MacaoBannerSectionFields_banners_desktop_Update"] | undefined,
	delete?: GraphQLTypes["MacaoBannerSectionFields_banners_desktop_Delete"] | undefined
};
	["MacaoBannerSectionFields_banners_desktop_Create"]: {
	__typename: "MacaoBannerSectionFields_banners_desktop_Create",
	permission: boolean
};
	["MacaoBannerSectionFields_banners_desktop_Read"]: {
	__typename: "MacaoBannerSectionFields_banners_desktop_Read",
	permission: boolean
};
	["MacaoBannerSectionFields_banners_desktop_Update"]: {
	__typename: "MacaoBannerSectionFields_banners_desktop_Update",
	permission: boolean
};
	["MacaoBannerSectionFields_banners_desktop_Delete"]: {
	__typename: "MacaoBannerSectionFields_banners_desktop_Delete",
	permission: boolean
};
	["MacaoBannerSectionFields_banners_bannerStartDate"]: {
	__typename: "MacaoBannerSectionFields_banners_bannerStartDate",
	create?: GraphQLTypes["MacaoBannerSectionFields_banners_bannerStartDate_Create"] | undefined,
	read?: GraphQLTypes["MacaoBannerSectionFields_banners_bannerStartDate_Read"] | undefined,
	update?: GraphQLTypes["MacaoBannerSectionFields_banners_bannerStartDate_Update"] | undefined,
	delete?: GraphQLTypes["MacaoBannerSectionFields_banners_bannerStartDate_Delete"] | undefined
};
	["MacaoBannerSectionFields_banners_bannerStartDate_Create"]: {
	__typename: "MacaoBannerSectionFields_banners_bannerStartDate_Create",
	permission: boolean
};
	["MacaoBannerSectionFields_banners_bannerStartDate_Read"]: {
	__typename: "MacaoBannerSectionFields_banners_bannerStartDate_Read",
	permission: boolean
};
	["MacaoBannerSectionFields_banners_bannerStartDate_Update"]: {
	__typename: "MacaoBannerSectionFields_banners_bannerStartDate_Update",
	permission: boolean
};
	["MacaoBannerSectionFields_banners_bannerStartDate_Delete"]: {
	__typename: "MacaoBannerSectionFields_banners_bannerStartDate_Delete",
	permission: boolean
};
	["MacaoBannerSectionFields_banners_bannerEndDate"]: {
	__typename: "MacaoBannerSectionFields_banners_bannerEndDate",
	create?: GraphQLTypes["MacaoBannerSectionFields_banners_bannerEndDate_Create"] | undefined,
	read?: GraphQLTypes["MacaoBannerSectionFields_banners_bannerEndDate_Read"] | undefined,
	update?: GraphQLTypes["MacaoBannerSectionFields_banners_bannerEndDate_Update"] | undefined,
	delete?: GraphQLTypes["MacaoBannerSectionFields_banners_bannerEndDate_Delete"] | undefined
};
	["MacaoBannerSectionFields_banners_bannerEndDate_Create"]: {
	__typename: "MacaoBannerSectionFields_banners_bannerEndDate_Create",
	permission: boolean
};
	["MacaoBannerSectionFields_banners_bannerEndDate_Read"]: {
	__typename: "MacaoBannerSectionFields_banners_bannerEndDate_Read",
	permission: boolean
};
	["MacaoBannerSectionFields_banners_bannerEndDate_Update"]: {
	__typename: "MacaoBannerSectionFields_banners_bannerEndDate_Update",
	permission: boolean
};
	["MacaoBannerSectionFields_banners_bannerEndDate_Delete"]: {
	__typename: "MacaoBannerSectionFields_banners_bannerEndDate_Delete",
	permission: boolean
};
	["MacaoBannerSectionFields_banners_region"]: {
	__typename: "MacaoBannerSectionFields_banners_region",
	create?: GraphQLTypes["MacaoBannerSectionFields_banners_region_Create"] | undefined,
	read?: GraphQLTypes["MacaoBannerSectionFields_banners_region_Read"] | undefined,
	update?: GraphQLTypes["MacaoBannerSectionFields_banners_region_Update"] | undefined,
	delete?: GraphQLTypes["MacaoBannerSectionFields_banners_region_Delete"] | undefined
};
	["MacaoBannerSectionFields_banners_region_Create"]: {
	__typename: "MacaoBannerSectionFields_banners_region_Create",
	permission: boolean
};
	["MacaoBannerSectionFields_banners_region_Read"]: {
	__typename: "MacaoBannerSectionFields_banners_region_Read",
	permission: boolean
};
	["MacaoBannerSectionFields_banners_region_Update"]: {
	__typename: "MacaoBannerSectionFields_banners_region_Update",
	permission: boolean
};
	["MacaoBannerSectionFields_banners_region_Delete"]: {
	__typename: "MacaoBannerSectionFields_banners_region_Delete",
	permission: boolean
};
	["MacaoBannerSectionFields_banners_id"]: {
	__typename: "MacaoBannerSectionFields_banners_id",
	create?: GraphQLTypes["MacaoBannerSectionFields_banners_id_Create"] | undefined,
	read?: GraphQLTypes["MacaoBannerSectionFields_banners_id_Read"] | undefined,
	update?: GraphQLTypes["MacaoBannerSectionFields_banners_id_Update"] | undefined,
	delete?: GraphQLTypes["MacaoBannerSectionFields_banners_id_Delete"] | undefined
};
	["MacaoBannerSectionFields_banners_id_Create"]: {
	__typename: "MacaoBannerSectionFields_banners_id_Create",
	permission: boolean
};
	["MacaoBannerSectionFields_banners_id_Read"]: {
	__typename: "MacaoBannerSectionFields_banners_id_Read",
	permission: boolean
};
	["MacaoBannerSectionFields_banners_id_Update"]: {
	__typename: "MacaoBannerSectionFields_banners_id_Update",
	permission: boolean
};
	["MacaoBannerSectionFields_banners_id_Delete"]: {
	__typename: "MacaoBannerSectionFields_banners_id_Delete",
	permission: boolean
};
	["MacaoBannerSectionFields_updatedAt"]: {
	__typename: "MacaoBannerSectionFields_updatedAt",
	create?: GraphQLTypes["MacaoBannerSectionFields_updatedAt_Create"] | undefined,
	read?: GraphQLTypes["MacaoBannerSectionFields_updatedAt_Read"] | undefined,
	update?: GraphQLTypes["MacaoBannerSectionFields_updatedAt_Update"] | undefined,
	delete?: GraphQLTypes["MacaoBannerSectionFields_updatedAt_Delete"] | undefined
};
	["MacaoBannerSectionFields_updatedAt_Create"]: {
	__typename: "MacaoBannerSectionFields_updatedAt_Create",
	permission: boolean
};
	["MacaoBannerSectionFields_updatedAt_Read"]: {
	__typename: "MacaoBannerSectionFields_updatedAt_Read",
	permission: boolean
};
	["MacaoBannerSectionFields_updatedAt_Update"]: {
	__typename: "MacaoBannerSectionFields_updatedAt_Update",
	permission: boolean
};
	["MacaoBannerSectionFields_updatedAt_Delete"]: {
	__typename: "MacaoBannerSectionFields_updatedAt_Delete",
	permission: boolean
};
	["MacaoBannerSectionFields_createdAt"]: {
	__typename: "MacaoBannerSectionFields_createdAt",
	create?: GraphQLTypes["MacaoBannerSectionFields_createdAt_Create"] | undefined,
	read?: GraphQLTypes["MacaoBannerSectionFields_createdAt_Read"] | undefined,
	update?: GraphQLTypes["MacaoBannerSectionFields_createdAt_Update"] | undefined,
	delete?: GraphQLTypes["MacaoBannerSectionFields_createdAt_Delete"] | undefined
};
	["MacaoBannerSectionFields_createdAt_Create"]: {
	__typename: "MacaoBannerSectionFields_createdAt_Create",
	permission: boolean
};
	["MacaoBannerSectionFields_createdAt_Read"]: {
	__typename: "MacaoBannerSectionFields_createdAt_Read",
	permission: boolean
};
	["MacaoBannerSectionFields_createdAt_Update"]: {
	__typename: "MacaoBannerSectionFields_createdAt_Update",
	permission: boolean
};
	["MacaoBannerSectionFields_createdAt_Delete"]: {
	__typename: "MacaoBannerSectionFields_createdAt_Delete",
	permission: boolean
};
	["MacaoBannerSectionCreateAccess"]: {
	__typename: "MacaoBannerSectionCreateAccess",
	permission: boolean,
	where?: GraphQLTypes["JSONObject"] | undefined
};
	["MacaoBannerSectionReadAccess"]: {
	__typename: "MacaoBannerSectionReadAccess",
	permission: boolean,
	where?: GraphQLTypes["JSONObject"] | undefined
};
	["MacaoBannerSectionUpdateAccess"]: {
	__typename: "MacaoBannerSectionUpdateAccess",
	permission: boolean,
	where?: GraphQLTypes["JSONObject"] | undefined
};
	["MacaoBannerSectionDeleteAccess"]: {
	__typename: "MacaoBannerSectionDeleteAccess",
	permission: boolean,
	where?: GraphQLTypes["JSONObject"] | undefined
};
	["bannersAccess"]: {
	__typename: "bannersAccess",
	fields?: GraphQLTypes["BannersFields"] | undefined,
	create?: GraphQLTypes["BannersCreateAccess"] | undefined,
	read?: GraphQLTypes["BannersReadAccess"] | undefined,
	update?: GraphQLTypes["BannersUpdateAccess"] | undefined,
	delete?: GraphQLTypes["BannersDeleteAccess"] | undefined
};
	["BannersFields"]: {
	__typename: "BannersFields",
	name?: GraphQLTypes["BannersFields_name"] | undefined,
	desktop?: GraphQLTypes["BannersFields_desktop"] | undefined,
	mobile?: GraphQLTypes["BannersFields_mobile"] | undefined,
	bannerStartDate?: GraphQLTypes["BannersFields_bannerStartDate"] | undefined,
	bannerEndDate?: GraphQLTypes["BannersFields_bannerEndDate"] | undefined,
	location?: GraphQLTypes["BannersFields_location"] | undefined,
	link?: GraphQLTypes["BannersFields_link"] | undefined,
	external?: GraphQLTypes["BannersFields_external"] | undefined,
	type?: GraphQLTypes["BannersFields_type"] | undefined,
	status?: GraphQLTypes["BannersFields_status"] | undefined,
	advertise?: GraphQLTypes["BannersFields_advertise"] | undefined,
	_locations?: GraphQLTypes["BannersFields__locations"] | undefined,
	region?: GraphQLTypes["BannersFields_region"] | undefined,
	homePageClick?: GraphQLTypes["BannersFields_homePageClick"] | undefined,
	homePageImpression?: GraphQLTypes["BannersFields_homePageImpression"] | undefined,
	latestEventClick?: GraphQLTypes["BannersFields_latestEventClick"] | undefined,
	latestEventImpression?: GraphQLTypes["BannersFields_latestEventImpression"] | undefined,
	editorChoiceClick?: GraphQLTypes["BannersFields_editorChoiceClick"] | undefined,
	editorChoiceImpression?: GraphQLTypes["BannersFields_editorChoiceImpression"] | undefined,
	eventListClick?: GraphQLTypes["BannersFields_eventListClick"] | undefined,
	eventListImpression?: GraphQLTypes["BannersFields_eventListImpression"] | undefined,
	topTenClick?: GraphQLTypes["BannersFields_topTenClick"] | undefined,
	topTenImpression?: GraphQLTypes["BannersFields_topTenImpression"] | undefined,
	miscClick?: GraphQLTypes["BannersFields_miscClick"] | undefined,
	miscImpression?: GraphQLTypes["BannersFields_miscImpression"] | undefined,
	clickTotal?: GraphQLTypes["BannersFields_clickTotal"] | undefined,
	totalImpression?: GraphQLTypes["BannersFields_totalImpression"] | undefined,
	updatedAt?: GraphQLTypes["BannersFields_updatedAt"] | undefined,
	createdAt?: GraphQLTypes["BannersFields_createdAt"] | undefined
};
	["BannersFields_name"]: {
	__typename: "BannersFields_name",
	create?: GraphQLTypes["BannersFields_name_Create"] | undefined,
	read?: GraphQLTypes["BannersFields_name_Read"] | undefined,
	update?: GraphQLTypes["BannersFields_name_Update"] | undefined,
	delete?: GraphQLTypes["BannersFields_name_Delete"] | undefined
};
	["BannersFields_name_Create"]: {
	__typename: "BannersFields_name_Create",
	permission: boolean
};
	["BannersFields_name_Read"]: {
	__typename: "BannersFields_name_Read",
	permission: boolean
};
	["BannersFields_name_Update"]: {
	__typename: "BannersFields_name_Update",
	permission: boolean
};
	["BannersFields_name_Delete"]: {
	__typename: "BannersFields_name_Delete",
	permission: boolean
};
	["BannersFields_desktop"]: {
	__typename: "BannersFields_desktop",
	create?: GraphQLTypes["BannersFields_desktop_Create"] | undefined,
	read?: GraphQLTypes["BannersFields_desktop_Read"] | undefined,
	update?: GraphQLTypes["BannersFields_desktop_Update"] | undefined,
	delete?: GraphQLTypes["BannersFields_desktop_Delete"] | undefined
};
	["BannersFields_desktop_Create"]: {
	__typename: "BannersFields_desktop_Create",
	permission: boolean
};
	["BannersFields_desktop_Read"]: {
	__typename: "BannersFields_desktop_Read",
	permission: boolean
};
	["BannersFields_desktop_Update"]: {
	__typename: "BannersFields_desktop_Update",
	permission: boolean
};
	["BannersFields_desktop_Delete"]: {
	__typename: "BannersFields_desktop_Delete",
	permission: boolean
};
	["BannersFields_mobile"]: {
	__typename: "BannersFields_mobile",
	create?: GraphQLTypes["BannersFields_mobile_Create"] | undefined,
	read?: GraphQLTypes["BannersFields_mobile_Read"] | undefined,
	update?: GraphQLTypes["BannersFields_mobile_Update"] | undefined,
	delete?: GraphQLTypes["BannersFields_mobile_Delete"] | undefined
};
	["BannersFields_mobile_Create"]: {
	__typename: "BannersFields_mobile_Create",
	permission: boolean
};
	["BannersFields_mobile_Read"]: {
	__typename: "BannersFields_mobile_Read",
	permission: boolean
};
	["BannersFields_mobile_Update"]: {
	__typename: "BannersFields_mobile_Update",
	permission: boolean
};
	["BannersFields_mobile_Delete"]: {
	__typename: "BannersFields_mobile_Delete",
	permission: boolean
};
	["BannersFields_bannerStartDate"]: {
	__typename: "BannersFields_bannerStartDate",
	create?: GraphQLTypes["BannersFields_bannerStartDate_Create"] | undefined,
	read?: GraphQLTypes["BannersFields_bannerStartDate_Read"] | undefined,
	update?: GraphQLTypes["BannersFields_bannerStartDate_Update"] | undefined,
	delete?: GraphQLTypes["BannersFields_bannerStartDate_Delete"] | undefined
};
	["BannersFields_bannerStartDate_Create"]: {
	__typename: "BannersFields_bannerStartDate_Create",
	permission: boolean
};
	["BannersFields_bannerStartDate_Read"]: {
	__typename: "BannersFields_bannerStartDate_Read",
	permission: boolean
};
	["BannersFields_bannerStartDate_Update"]: {
	__typename: "BannersFields_bannerStartDate_Update",
	permission: boolean
};
	["BannersFields_bannerStartDate_Delete"]: {
	__typename: "BannersFields_bannerStartDate_Delete",
	permission: boolean
};
	["BannersFields_bannerEndDate"]: {
	__typename: "BannersFields_bannerEndDate",
	create?: GraphQLTypes["BannersFields_bannerEndDate_Create"] | undefined,
	read?: GraphQLTypes["BannersFields_bannerEndDate_Read"] | undefined,
	update?: GraphQLTypes["BannersFields_bannerEndDate_Update"] | undefined,
	delete?: GraphQLTypes["BannersFields_bannerEndDate_Delete"] | undefined
};
	["BannersFields_bannerEndDate_Create"]: {
	__typename: "BannersFields_bannerEndDate_Create",
	permission: boolean
};
	["BannersFields_bannerEndDate_Read"]: {
	__typename: "BannersFields_bannerEndDate_Read",
	permission: boolean
};
	["BannersFields_bannerEndDate_Update"]: {
	__typename: "BannersFields_bannerEndDate_Update",
	permission: boolean
};
	["BannersFields_bannerEndDate_Delete"]: {
	__typename: "BannersFields_bannerEndDate_Delete",
	permission: boolean
};
	["BannersFields_location"]: {
	__typename: "BannersFields_location",
	create?: GraphQLTypes["BannersFields_location_Create"] | undefined,
	read?: GraphQLTypes["BannersFields_location_Read"] | undefined,
	update?: GraphQLTypes["BannersFields_location_Update"] | undefined,
	delete?: GraphQLTypes["BannersFields_location_Delete"] | undefined,
	fields?: GraphQLTypes["BannersFields_location_Fields"] | undefined
};
	["BannersFields_location_Create"]: {
	__typename: "BannersFields_location_Create",
	permission: boolean
};
	["BannersFields_location_Read"]: {
	__typename: "BannersFields_location_Read",
	permission: boolean
};
	["BannersFields_location_Update"]: {
	__typename: "BannersFields_location_Update",
	permission: boolean
};
	["BannersFields_location_Delete"]: {
	__typename: "BannersFields_location_Delete",
	permission: boolean
};
	["BannersFields_location_Fields"]: {
	__typename: "BannersFields_location_Fields",
	page?: GraphQLTypes["BannersFields_location_page"] | undefined,
	eventRankingList?: GraphQLTypes["BannersFields_location_eventRankingList"] | undefined,
	locationRankingList?: GraphQLTypes["BannersFields_location_locationRankingList"] | undefined,
	categories?: GraphQLTypes["BannersFields_location_categories"] | undefined,
	time?: GraphQLTypes["BannersFields_location_time"] | undefined,
	district?: GraphQLTypes["BannersFields_location_district"] | undefined,
	audience?: GraphQLTypes["BannersFields_location_audience"] | undefined,
	multipleCriteriaSearch?: GraphQLTypes["BannersFields_location_multipleCriteriaSearch"] | undefined
};
	["BannersFields_location_page"]: {
	__typename: "BannersFields_location_page",
	create?: GraphQLTypes["BannersFields_location_page_Create"] | undefined,
	read?: GraphQLTypes["BannersFields_location_page_Read"] | undefined,
	update?: GraphQLTypes["BannersFields_location_page_Update"] | undefined,
	delete?: GraphQLTypes["BannersFields_location_page_Delete"] | undefined
};
	["BannersFields_location_page_Create"]: {
	__typename: "BannersFields_location_page_Create",
	permission: boolean
};
	["BannersFields_location_page_Read"]: {
	__typename: "BannersFields_location_page_Read",
	permission: boolean
};
	["BannersFields_location_page_Update"]: {
	__typename: "BannersFields_location_page_Update",
	permission: boolean
};
	["BannersFields_location_page_Delete"]: {
	__typename: "BannersFields_location_page_Delete",
	permission: boolean
};
	["BannersFields_location_eventRankingList"]: {
	__typename: "BannersFields_location_eventRankingList",
	create?: GraphQLTypes["BannersFields_location_eventRankingList_Create"] | undefined,
	read?: GraphQLTypes["BannersFields_location_eventRankingList_Read"] | undefined,
	update?: GraphQLTypes["BannersFields_location_eventRankingList_Update"] | undefined,
	delete?: GraphQLTypes["BannersFields_location_eventRankingList_Delete"] | undefined
};
	["BannersFields_location_eventRankingList_Create"]: {
	__typename: "BannersFields_location_eventRankingList_Create",
	permission: boolean
};
	["BannersFields_location_eventRankingList_Read"]: {
	__typename: "BannersFields_location_eventRankingList_Read",
	permission: boolean
};
	["BannersFields_location_eventRankingList_Update"]: {
	__typename: "BannersFields_location_eventRankingList_Update",
	permission: boolean
};
	["BannersFields_location_eventRankingList_Delete"]: {
	__typename: "BannersFields_location_eventRankingList_Delete",
	permission: boolean
};
	["BannersFields_location_locationRankingList"]: {
	__typename: "BannersFields_location_locationRankingList",
	create?: GraphQLTypes["BannersFields_location_locationRankingList_Create"] | undefined,
	read?: GraphQLTypes["BannersFields_location_locationRankingList_Read"] | undefined,
	update?: GraphQLTypes["BannersFields_location_locationRankingList_Update"] | undefined,
	delete?: GraphQLTypes["BannersFields_location_locationRankingList_Delete"] | undefined
};
	["BannersFields_location_locationRankingList_Create"]: {
	__typename: "BannersFields_location_locationRankingList_Create",
	permission: boolean
};
	["BannersFields_location_locationRankingList_Read"]: {
	__typename: "BannersFields_location_locationRankingList_Read",
	permission: boolean
};
	["BannersFields_location_locationRankingList_Update"]: {
	__typename: "BannersFields_location_locationRankingList_Update",
	permission: boolean
};
	["BannersFields_location_locationRankingList_Delete"]: {
	__typename: "BannersFields_location_locationRankingList_Delete",
	permission: boolean
};
	["BannersFields_location_categories"]: {
	__typename: "BannersFields_location_categories",
	create?: GraphQLTypes["BannersFields_location_categories_Create"] | undefined,
	read?: GraphQLTypes["BannersFields_location_categories_Read"] | undefined,
	update?: GraphQLTypes["BannersFields_location_categories_Update"] | undefined,
	delete?: GraphQLTypes["BannersFields_location_categories_Delete"] | undefined
};
	["BannersFields_location_categories_Create"]: {
	__typename: "BannersFields_location_categories_Create",
	permission: boolean
};
	["BannersFields_location_categories_Read"]: {
	__typename: "BannersFields_location_categories_Read",
	permission: boolean
};
	["BannersFields_location_categories_Update"]: {
	__typename: "BannersFields_location_categories_Update",
	permission: boolean
};
	["BannersFields_location_categories_Delete"]: {
	__typename: "BannersFields_location_categories_Delete",
	permission: boolean
};
	["BannersFields_location_time"]: {
	__typename: "BannersFields_location_time",
	create?: GraphQLTypes["BannersFields_location_time_Create"] | undefined,
	read?: GraphQLTypes["BannersFields_location_time_Read"] | undefined,
	update?: GraphQLTypes["BannersFields_location_time_Update"] | undefined,
	delete?: GraphQLTypes["BannersFields_location_time_Delete"] | undefined
};
	["BannersFields_location_time_Create"]: {
	__typename: "BannersFields_location_time_Create",
	permission: boolean
};
	["BannersFields_location_time_Read"]: {
	__typename: "BannersFields_location_time_Read",
	permission: boolean
};
	["BannersFields_location_time_Update"]: {
	__typename: "BannersFields_location_time_Update",
	permission: boolean
};
	["BannersFields_location_time_Delete"]: {
	__typename: "BannersFields_location_time_Delete",
	permission: boolean
};
	["BannersFields_location_district"]: {
	__typename: "BannersFields_location_district",
	create?: GraphQLTypes["BannersFields_location_district_Create"] | undefined,
	read?: GraphQLTypes["BannersFields_location_district_Read"] | undefined,
	update?: GraphQLTypes["BannersFields_location_district_Update"] | undefined,
	delete?: GraphQLTypes["BannersFields_location_district_Delete"] | undefined
};
	["BannersFields_location_district_Create"]: {
	__typename: "BannersFields_location_district_Create",
	permission: boolean
};
	["BannersFields_location_district_Read"]: {
	__typename: "BannersFields_location_district_Read",
	permission: boolean
};
	["BannersFields_location_district_Update"]: {
	__typename: "BannersFields_location_district_Update",
	permission: boolean
};
	["BannersFields_location_district_Delete"]: {
	__typename: "BannersFields_location_district_Delete",
	permission: boolean
};
	["BannersFields_location_audience"]: {
	__typename: "BannersFields_location_audience",
	create?: GraphQLTypes["BannersFields_location_audience_Create"] | undefined,
	read?: GraphQLTypes["BannersFields_location_audience_Read"] | undefined,
	update?: GraphQLTypes["BannersFields_location_audience_Update"] | undefined,
	delete?: GraphQLTypes["BannersFields_location_audience_Delete"] | undefined
};
	["BannersFields_location_audience_Create"]: {
	__typename: "BannersFields_location_audience_Create",
	permission: boolean
};
	["BannersFields_location_audience_Read"]: {
	__typename: "BannersFields_location_audience_Read",
	permission: boolean
};
	["BannersFields_location_audience_Update"]: {
	__typename: "BannersFields_location_audience_Update",
	permission: boolean
};
	["BannersFields_location_audience_Delete"]: {
	__typename: "BannersFields_location_audience_Delete",
	permission: boolean
};
	["BannersFields_location_multipleCriteriaSearch"]: {
	__typename: "BannersFields_location_multipleCriteriaSearch",
	create?: GraphQLTypes["BannersFields_location_multipleCriteriaSearch_Create"] | undefined,
	read?: GraphQLTypes["BannersFields_location_multipleCriteriaSearch_Read"] | undefined,
	update?: GraphQLTypes["BannersFields_location_multipleCriteriaSearch_Update"] | undefined,
	delete?: GraphQLTypes["BannersFields_location_multipleCriteriaSearch_Delete"] | undefined
};
	["BannersFields_location_multipleCriteriaSearch_Create"]: {
	__typename: "BannersFields_location_multipleCriteriaSearch_Create",
	permission: boolean
};
	["BannersFields_location_multipleCriteriaSearch_Read"]: {
	__typename: "BannersFields_location_multipleCriteriaSearch_Read",
	permission: boolean
};
	["BannersFields_location_multipleCriteriaSearch_Update"]: {
	__typename: "BannersFields_location_multipleCriteriaSearch_Update",
	permission: boolean
};
	["BannersFields_location_multipleCriteriaSearch_Delete"]: {
	__typename: "BannersFields_location_multipleCriteriaSearch_Delete",
	permission: boolean
};
	["BannersFields_link"]: {
	__typename: "BannersFields_link",
	create?: GraphQLTypes["BannersFields_link_Create"] | undefined,
	read?: GraphQLTypes["BannersFields_link_Read"] | undefined,
	update?: GraphQLTypes["BannersFields_link_Update"] | undefined,
	delete?: GraphQLTypes["BannersFields_link_Delete"] | undefined
};
	["BannersFields_link_Create"]: {
	__typename: "BannersFields_link_Create",
	permission: boolean
};
	["BannersFields_link_Read"]: {
	__typename: "BannersFields_link_Read",
	permission: boolean
};
	["BannersFields_link_Update"]: {
	__typename: "BannersFields_link_Update",
	permission: boolean
};
	["BannersFields_link_Delete"]: {
	__typename: "BannersFields_link_Delete",
	permission: boolean
};
	["BannersFields_external"]: {
	__typename: "BannersFields_external",
	create?: GraphQLTypes["BannersFields_external_Create"] | undefined,
	read?: GraphQLTypes["BannersFields_external_Read"] | undefined,
	update?: GraphQLTypes["BannersFields_external_Update"] | undefined,
	delete?: GraphQLTypes["BannersFields_external_Delete"] | undefined
};
	["BannersFields_external_Create"]: {
	__typename: "BannersFields_external_Create",
	permission: boolean
};
	["BannersFields_external_Read"]: {
	__typename: "BannersFields_external_Read",
	permission: boolean
};
	["BannersFields_external_Update"]: {
	__typename: "BannersFields_external_Update",
	permission: boolean
};
	["BannersFields_external_Delete"]: {
	__typename: "BannersFields_external_Delete",
	permission: boolean
};
	["BannersFields_type"]: {
	__typename: "BannersFields_type",
	create?: GraphQLTypes["BannersFields_type_Create"] | undefined,
	read?: GraphQLTypes["BannersFields_type_Read"] | undefined,
	update?: GraphQLTypes["BannersFields_type_Update"] | undefined,
	delete?: GraphQLTypes["BannersFields_type_Delete"] | undefined
};
	["BannersFields_type_Create"]: {
	__typename: "BannersFields_type_Create",
	permission: boolean
};
	["BannersFields_type_Read"]: {
	__typename: "BannersFields_type_Read",
	permission: boolean
};
	["BannersFields_type_Update"]: {
	__typename: "BannersFields_type_Update",
	permission: boolean
};
	["BannersFields_type_Delete"]: {
	__typename: "BannersFields_type_Delete",
	permission: boolean
};
	["BannersFields_status"]: {
	__typename: "BannersFields_status",
	create?: GraphQLTypes["BannersFields_status_Create"] | undefined,
	read?: GraphQLTypes["BannersFields_status_Read"] | undefined,
	update?: GraphQLTypes["BannersFields_status_Update"] | undefined,
	delete?: GraphQLTypes["BannersFields_status_Delete"] | undefined
};
	["BannersFields_status_Create"]: {
	__typename: "BannersFields_status_Create",
	permission: boolean
};
	["BannersFields_status_Read"]: {
	__typename: "BannersFields_status_Read",
	permission: boolean
};
	["BannersFields_status_Update"]: {
	__typename: "BannersFields_status_Update",
	permission: boolean
};
	["BannersFields_status_Delete"]: {
	__typename: "BannersFields_status_Delete",
	permission: boolean
};
	["BannersFields_advertise"]: {
	__typename: "BannersFields_advertise",
	create?: GraphQLTypes["BannersFields_advertise_Create"] | undefined,
	read?: GraphQLTypes["BannersFields_advertise_Read"] | undefined,
	update?: GraphQLTypes["BannersFields_advertise_Update"] | undefined,
	delete?: GraphQLTypes["BannersFields_advertise_Delete"] | undefined,
	fields?: GraphQLTypes["BannersFields_advertise_Fields"] | undefined
};
	["BannersFields_advertise_Create"]: {
	__typename: "BannersFields_advertise_Create",
	permission: boolean
};
	["BannersFields_advertise_Read"]: {
	__typename: "BannersFields_advertise_Read",
	permission: boolean
};
	["BannersFields_advertise_Update"]: {
	__typename: "BannersFields_advertise_Update",
	permission: boolean
};
	["BannersFields_advertise_Delete"]: {
	__typename: "BannersFields_advertise_Delete",
	permission: boolean
};
	["BannersFields_advertise_Fields"]: {
	__typename: "BannersFields_advertise_Fields",
	campaign?: GraphQLTypes["BannersFields_advertise_campaign"] | undefined,
	invoiceDescription?: GraphQLTypes["BannersFields_advertise_invoiceDescription"] | undefined,
	standardPrice?: GraphQLTypes["BannersFields_advertise_standardPrice"] | undefined,
	discount?: GraphQLTypes["BannersFields_advertise_discount"] | undefined,
	timableCost?: GraphQLTypes["BannersFields_advertise_timableCost"] | undefined,
	remark?: GraphQLTypes["BannersFields_advertise_remark"] | undefined,
	public?: GraphQLTypes["BannersFields_advertise_public"] | undefined
};
	["BannersFields_advertise_campaign"]: {
	__typename: "BannersFields_advertise_campaign",
	create?: GraphQLTypes["BannersFields_advertise_campaign_Create"] | undefined,
	read?: GraphQLTypes["BannersFields_advertise_campaign_Read"] | undefined,
	update?: GraphQLTypes["BannersFields_advertise_campaign_Update"] | undefined,
	delete?: GraphQLTypes["BannersFields_advertise_campaign_Delete"] | undefined
};
	["BannersFields_advertise_campaign_Create"]: {
	__typename: "BannersFields_advertise_campaign_Create",
	permission: boolean
};
	["BannersFields_advertise_campaign_Read"]: {
	__typename: "BannersFields_advertise_campaign_Read",
	permission: boolean
};
	["BannersFields_advertise_campaign_Update"]: {
	__typename: "BannersFields_advertise_campaign_Update",
	permission: boolean
};
	["BannersFields_advertise_campaign_Delete"]: {
	__typename: "BannersFields_advertise_campaign_Delete",
	permission: boolean
};
	["BannersFields_advertise_invoiceDescription"]: {
	__typename: "BannersFields_advertise_invoiceDescription",
	create?: GraphQLTypes["BannersFields_advertise_invoiceDescription_Create"] | undefined,
	read?: GraphQLTypes["BannersFields_advertise_invoiceDescription_Read"] | undefined,
	update?: GraphQLTypes["BannersFields_advertise_invoiceDescription_Update"] | undefined,
	delete?: GraphQLTypes["BannersFields_advertise_invoiceDescription_Delete"] | undefined
};
	["BannersFields_advertise_invoiceDescription_Create"]: {
	__typename: "BannersFields_advertise_invoiceDescription_Create",
	permission: boolean
};
	["BannersFields_advertise_invoiceDescription_Read"]: {
	__typename: "BannersFields_advertise_invoiceDescription_Read",
	permission: boolean
};
	["BannersFields_advertise_invoiceDescription_Update"]: {
	__typename: "BannersFields_advertise_invoiceDescription_Update",
	permission: boolean
};
	["BannersFields_advertise_invoiceDescription_Delete"]: {
	__typename: "BannersFields_advertise_invoiceDescription_Delete",
	permission: boolean
};
	["BannersFields_advertise_standardPrice"]: {
	__typename: "BannersFields_advertise_standardPrice",
	create?: GraphQLTypes["BannersFields_advertise_standardPrice_Create"] | undefined,
	read?: GraphQLTypes["BannersFields_advertise_standardPrice_Read"] | undefined,
	update?: GraphQLTypes["BannersFields_advertise_standardPrice_Update"] | undefined,
	delete?: GraphQLTypes["BannersFields_advertise_standardPrice_Delete"] | undefined
};
	["BannersFields_advertise_standardPrice_Create"]: {
	__typename: "BannersFields_advertise_standardPrice_Create",
	permission: boolean
};
	["BannersFields_advertise_standardPrice_Read"]: {
	__typename: "BannersFields_advertise_standardPrice_Read",
	permission: boolean
};
	["BannersFields_advertise_standardPrice_Update"]: {
	__typename: "BannersFields_advertise_standardPrice_Update",
	permission: boolean
};
	["BannersFields_advertise_standardPrice_Delete"]: {
	__typename: "BannersFields_advertise_standardPrice_Delete",
	permission: boolean
};
	["BannersFields_advertise_discount"]: {
	__typename: "BannersFields_advertise_discount",
	create?: GraphQLTypes["BannersFields_advertise_discount_Create"] | undefined,
	read?: GraphQLTypes["BannersFields_advertise_discount_Read"] | undefined,
	update?: GraphQLTypes["BannersFields_advertise_discount_Update"] | undefined,
	delete?: GraphQLTypes["BannersFields_advertise_discount_Delete"] | undefined
};
	["BannersFields_advertise_discount_Create"]: {
	__typename: "BannersFields_advertise_discount_Create",
	permission: boolean
};
	["BannersFields_advertise_discount_Read"]: {
	__typename: "BannersFields_advertise_discount_Read",
	permission: boolean
};
	["BannersFields_advertise_discount_Update"]: {
	__typename: "BannersFields_advertise_discount_Update",
	permission: boolean
};
	["BannersFields_advertise_discount_Delete"]: {
	__typename: "BannersFields_advertise_discount_Delete",
	permission: boolean
};
	["BannersFields_advertise_timableCost"]: {
	__typename: "BannersFields_advertise_timableCost",
	create?: GraphQLTypes["BannersFields_advertise_timableCost_Create"] | undefined,
	read?: GraphQLTypes["BannersFields_advertise_timableCost_Read"] | undefined,
	update?: GraphQLTypes["BannersFields_advertise_timableCost_Update"] | undefined,
	delete?: GraphQLTypes["BannersFields_advertise_timableCost_Delete"] | undefined
};
	["BannersFields_advertise_timableCost_Create"]: {
	__typename: "BannersFields_advertise_timableCost_Create",
	permission: boolean
};
	["BannersFields_advertise_timableCost_Read"]: {
	__typename: "BannersFields_advertise_timableCost_Read",
	permission: boolean
};
	["BannersFields_advertise_timableCost_Update"]: {
	__typename: "BannersFields_advertise_timableCost_Update",
	permission: boolean
};
	["BannersFields_advertise_timableCost_Delete"]: {
	__typename: "BannersFields_advertise_timableCost_Delete",
	permission: boolean
};
	["BannersFields_advertise_remark"]: {
	__typename: "BannersFields_advertise_remark",
	create?: GraphQLTypes["BannersFields_advertise_remark_Create"] | undefined,
	read?: GraphQLTypes["BannersFields_advertise_remark_Read"] | undefined,
	update?: GraphQLTypes["BannersFields_advertise_remark_Update"] | undefined,
	delete?: GraphQLTypes["BannersFields_advertise_remark_Delete"] | undefined
};
	["BannersFields_advertise_remark_Create"]: {
	__typename: "BannersFields_advertise_remark_Create",
	permission: boolean
};
	["BannersFields_advertise_remark_Read"]: {
	__typename: "BannersFields_advertise_remark_Read",
	permission: boolean
};
	["BannersFields_advertise_remark_Update"]: {
	__typename: "BannersFields_advertise_remark_Update",
	permission: boolean
};
	["BannersFields_advertise_remark_Delete"]: {
	__typename: "BannersFields_advertise_remark_Delete",
	permission: boolean
};
	["BannersFields_advertise_public"]: {
	__typename: "BannersFields_advertise_public",
	create?: GraphQLTypes["BannersFields_advertise_public_Create"] | undefined,
	read?: GraphQLTypes["BannersFields_advertise_public_Read"] | undefined,
	update?: GraphQLTypes["BannersFields_advertise_public_Update"] | undefined,
	delete?: GraphQLTypes["BannersFields_advertise_public_Delete"] | undefined
};
	["BannersFields_advertise_public_Create"]: {
	__typename: "BannersFields_advertise_public_Create",
	permission: boolean
};
	["BannersFields_advertise_public_Read"]: {
	__typename: "BannersFields_advertise_public_Read",
	permission: boolean
};
	["BannersFields_advertise_public_Update"]: {
	__typename: "BannersFields_advertise_public_Update",
	permission: boolean
};
	["BannersFields_advertise_public_Delete"]: {
	__typename: "BannersFields_advertise_public_Delete",
	permission: boolean
};
	["BannersFields__locations"]: {
	__typename: "BannersFields__locations",
	create?: GraphQLTypes["BannersFields__locations_Create"] | undefined,
	read?: GraphQLTypes["BannersFields__locations_Read"] | undefined,
	update?: GraphQLTypes["BannersFields__locations_Update"] | undefined,
	delete?: GraphQLTypes["BannersFields__locations_Delete"] | undefined
};
	["BannersFields__locations_Create"]: {
	__typename: "BannersFields__locations_Create",
	permission: boolean
};
	["BannersFields__locations_Read"]: {
	__typename: "BannersFields__locations_Read",
	permission: boolean
};
	["BannersFields__locations_Update"]: {
	__typename: "BannersFields__locations_Update",
	permission: boolean
};
	["BannersFields__locations_Delete"]: {
	__typename: "BannersFields__locations_Delete",
	permission: boolean
};
	["BannersFields_region"]: {
	__typename: "BannersFields_region",
	create?: GraphQLTypes["BannersFields_region_Create"] | undefined,
	read?: GraphQLTypes["BannersFields_region_Read"] | undefined,
	update?: GraphQLTypes["BannersFields_region_Update"] | undefined,
	delete?: GraphQLTypes["BannersFields_region_Delete"] | undefined
};
	["BannersFields_region_Create"]: {
	__typename: "BannersFields_region_Create",
	permission: boolean
};
	["BannersFields_region_Read"]: {
	__typename: "BannersFields_region_Read",
	permission: boolean
};
	["BannersFields_region_Update"]: {
	__typename: "BannersFields_region_Update",
	permission: boolean
};
	["BannersFields_region_Delete"]: {
	__typename: "BannersFields_region_Delete",
	permission: boolean
};
	["BannersFields_homePageClick"]: {
	__typename: "BannersFields_homePageClick",
	create?: GraphQLTypes["BannersFields_homePageClick_Create"] | undefined,
	read?: GraphQLTypes["BannersFields_homePageClick_Read"] | undefined,
	update?: GraphQLTypes["BannersFields_homePageClick_Update"] | undefined,
	delete?: GraphQLTypes["BannersFields_homePageClick_Delete"] | undefined
};
	["BannersFields_homePageClick_Create"]: {
	__typename: "BannersFields_homePageClick_Create",
	permission: boolean
};
	["BannersFields_homePageClick_Read"]: {
	__typename: "BannersFields_homePageClick_Read",
	permission: boolean
};
	["BannersFields_homePageClick_Update"]: {
	__typename: "BannersFields_homePageClick_Update",
	permission: boolean
};
	["BannersFields_homePageClick_Delete"]: {
	__typename: "BannersFields_homePageClick_Delete",
	permission: boolean
};
	["BannersFields_homePageImpression"]: {
	__typename: "BannersFields_homePageImpression",
	create?: GraphQLTypes["BannersFields_homePageImpression_Create"] | undefined,
	read?: GraphQLTypes["BannersFields_homePageImpression_Read"] | undefined,
	update?: GraphQLTypes["BannersFields_homePageImpression_Update"] | undefined,
	delete?: GraphQLTypes["BannersFields_homePageImpression_Delete"] | undefined
};
	["BannersFields_homePageImpression_Create"]: {
	__typename: "BannersFields_homePageImpression_Create",
	permission: boolean
};
	["BannersFields_homePageImpression_Read"]: {
	__typename: "BannersFields_homePageImpression_Read",
	permission: boolean
};
	["BannersFields_homePageImpression_Update"]: {
	__typename: "BannersFields_homePageImpression_Update",
	permission: boolean
};
	["BannersFields_homePageImpression_Delete"]: {
	__typename: "BannersFields_homePageImpression_Delete",
	permission: boolean
};
	["BannersFields_latestEventClick"]: {
	__typename: "BannersFields_latestEventClick",
	create?: GraphQLTypes["BannersFields_latestEventClick_Create"] | undefined,
	read?: GraphQLTypes["BannersFields_latestEventClick_Read"] | undefined,
	update?: GraphQLTypes["BannersFields_latestEventClick_Update"] | undefined,
	delete?: GraphQLTypes["BannersFields_latestEventClick_Delete"] | undefined
};
	["BannersFields_latestEventClick_Create"]: {
	__typename: "BannersFields_latestEventClick_Create",
	permission: boolean
};
	["BannersFields_latestEventClick_Read"]: {
	__typename: "BannersFields_latestEventClick_Read",
	permission: boolean
};
	["BannersFields_latestEventClick_Update"]: {
	__typename: "BannersFields_latestEventClick_Update",
	permission: boolean
};
	["BannersFields_latestEventClick_Delete"]: {
	__typename: "BannersFields_latestEventClick_Delete",
	permission: boolean
};
	["BannersFields_latestEventImpression"]: {
	__typename: "BannersFields_latestEventImpression",
	create?: GraphQLTypes["BannersFields_latestEventImpression_Create"] | undefined,
	read?: GraphQLTypes["BannersFields_latestEventImpression_Read"] | undefined,
	update?: GraphQLTypes["BannersFields_latestEventImpression_Update"] | undefined,
	delete?: GraphQLTypes["BannersFields_latestEventImpression_Delete"] | undefined
};
	["BannersFields_latestEventImpression_Create"]: {
	__typename: "BannersFields_latestEventImpression_Create",
	permission: boolean
};
	["BannersFields_latestEventImpression_Read"]: {
	__typename: "BannersFields_latestEventImpression_Read",
	permission: boolean
};
	["BannersFields_latestEventImpression_Update"]: {
	__typename: "BannersFields_latestEventImpression_Update",
	permission: boolean
};
	["BannersFields_latestEventImpression_Delete"]: {
	__typename: "BannersFields_latestEventImpression_Delete",
	permission: boolean
};
	["BannersFields_editorChoiceClick"]: {
	__typename: "BannersFields_editorChoiceClick",
	create?: GraphQLTypes["BannersFields_editorChoiceClick_Create"] | undefined,
	read?: GraphQLTypes["BannersFields_editorChoiceClick_Read"] | undefined,
	update?: GraphQLTypes["BannersFields_editorChoiceClick_Update"] | undefined,
	delete?: GraphQLTypes["BannersFields_editorChoiceClick_Delete"] | undefined
};
	["BannersFields_editorChoiceClick_Create"]: {
	__typename: "BannersFields_editorChoiceClick_Create",
	permission: boolean
};
	["BannersFields_editorChoiceClick_Read"]: {
	__typename: "BannersFields_editorChoiceClick_Read",
	permission: boolean
};
	["BannersFields_editorChoiceClick_Update"]: {
	__typename: "BannersFields_editorChoiceClick_Update",
	permission: boolean
};
	["BannersFields_editorChoiceClick_Delete"]: {
	__typename: "BannersFields_editorChoiceClick_Delete",
	permission: boolean
};
	["BannersFields_editorChoiceImpression"]: {
	__typename: "BannersFields_editorChoiceImpression",
	create?: GraphQLTypes["BannersFields_editorChoiceImpression_Create"] | undefined,
	read?: GraphQLTypes["BannersFields_editorChoiceImpression_Read"] | undefined,
	update?: GraphQLTypes["BannersFields_editorChoiceImpression_Update"] | undefined,
	delete?: GraphQLTypes["BannersFields_editorChoiceImpression_Delete"] | undefined
};
	["BannersFields_editorChoiceImpression_Create"]: {
	__typename: "BannersFields_editorChoiceImpression_Create",
	permission: boolean
};
	["BannersFields_editorChoiceImpression_Read"]: {
	__typename: "BannersFields_editorChoiceImpression_Read",
	permission: boolean
};
	["BannersFields_editorChoiceImpression_Update"]: {
	__typename: "BannersFields_editorChoiceImpression_Update",
	permission: boolean
};
	["BannersFields_editorChoiceImpression_Delete"]: {
	__typename: "BannersFields_editorChoiceImpression_Delete",
	permission: boolean
};
	["BannersFields_eventListClick"]: {
	__typename: "BannersFields_eventListClick",
	create?: GraphQLTypes["BannersFields_eventListClick_Create"] | undefined,
	read?: GraphQLTypes["BannersFields_eventListClick_Read"] | undefined,
	update?: GraphQLTypes["BannersFields_eventListClick_Update"] | undefined,
	delete?: GraphQLTypes["BannersFields_eventListClick_Delete"] | undefined
};
	["BannersFields_eventListClick_Create"]: {
	__typename: "BannersFields_eventListClick_Create",
	permission: boolean
};
	["BannersFields_eventListClick_Read"]: {
	__typename: "BannersFields_eventListClick_Read",
	permission: boolean
};
	["BannersFields_eventListClick_Update"]: {
	__typename: "BannersFields_eventListClick_Update",
	permission: boolean
};
	["BannersFields_eventListClick_Delete"]: {
	__typename: "BannersFields_eventListClick_Delete",
	permission: boolean
};
	["BannersFields_eventListImpression"]: {
	__typename: "BannersFields_eventListImpression",
	create?: GraphQLTypes["BannersFields_eventListImpression_Create"] | undefined,
	read?: GraphQLTypes["BannersFields_eventListImpression_Read"] | undefined,
	update?: GraphQLTypes["BannersFields_eventListImpression_Update"] | undefined,
	delete?: GraphQLTypes["BannersFields_eventListImpression_Delete"] | undefined
};
	["BannersFields_eventListImpression_Create"]: {
	__typename: "BannersFields_eventListImpression_Create",
	permission: boolean
};
	["BannersFields_eventListImpression_Read"]: {
	__typename: "BannersFields_eventListImpression_Read",
	permission: boolean
};
	["BannersFields_eventListImpression_Update"]: {
	__typename: "BannersFields_eventListImpression_Update",
	permission: boolean
};
	["BannersFields_eventListImpression_Delete"]: {
	__typename: "BannersFields_eventListImpression_Delete",
	permission: boolean
};
	["BannersFields_topTenClick"]: {
	__typename: "BannersFields_topTenClick",
	create?: GraphQLTypes["BannersFields_topTenClick_Create"] | undefined,
	read?: GraphQLTypes["BannersFields_topTenClick_Read"] | undefined,
	update?: GraphQLTypes["BannersFields_topTenClick_Update"] | undefined,
	delete?: GraphQLTypes["BannersFields_topTenClick_Delete"] | undefined
};
	["BannersFields_topTenClick_Create"]: {
	__typename: "BannersFields_topTenClick_Create",
	permission: boolean
};
	["BannersFields_topTenClick_Read"]: {
	__typename: "BannersFields_topTenClick_Read",
	permission: boolean
};
	["BannersFields_topTenClick_Update"]: {
	__typename: "BannersFields_topTenClick_Update",
	permission: boolean
};
	["BannersFields_topTenClick_Delete"]: {
	__typename: "BannersFields_topTenClick_Delete",
	permission: boolean
};
	["BannersFields_topTenImpression"]: {
	__typename: "BannersFields_topTenImpression",
	create?: GraphQLTypes["BannersFields_topTenImpression_Create"] | undefined,
	read?: GraphQLTypes["BannersFields_topTenImpression_Read"] | undefined,
	update?: GraphQLTypes["BannersFields_topTenImpression_Update"] | undefined,
	delete?: GraphQLTypes["BannersFields_topTenImpression_Delete"] | undefined
};
	["BannersFields_topTenImpression_Create"]: {
	__typename: "BannersFields_topTenImpression_Create",
	permission: boolean
};
	["BannersFields_topTenImpression_Read"]: {
	__typename: "BannersFields_topTenImpression_Read",
	permission: boolean
};
	["BannersFields_topTenImpression_Update"]: {
	__typename: "BannersFields_topTenImpression_Update",
	permission: boolean
};
	["BannersFields_topTenImpression_Delete"]: {
	__typename: "BannersFields_topTenImpression_Delete",
	permission: boolean
};
	["BannersFields_miscClick"]: {
	__typename: "BannersFields_miscClick",
	create?: GraphQLTypes["BannersFields_miscClick_Create"] | undefined,
	read?: GraphQLTypes["BannersFields_miscClick_Read"] | undefined,
	update?: GraphQLTypes["BannersFields_miscClick_Update"] | undefined,
	delete?: GraphQLTypes["BannersFields_miscClick_Delete"] | undefined
};
	["BannersFields_miscClick_Create"]: {
	__typename: "BannersFields_miscClick_Create",
	permission: boolean
};
	["BannersFields_miscClick_Read"]: {
	__typename: "BannersFields_miscClick_Read",
	permission: boolean
};
	["BannersFields_miscClick_Update"]: {
	__typename: "BannersFields_miscClick_Update",
	permission: boolean
};
	["BannersFields_miscClick_Delete"]: {
	__typename: "BannersFields_miscClick_Delete",
	permission: boolean
};
	["BannersFields_miscImpression"]: {
	__typename: "BannersFields_miscImpression",
	create?: GraphQLTypes["BannersFields_miscImpression_Create"] | undefined,
	read?: GraphQLTypes["BannersFields_miscImpression_Read"] | undefined,
	update?: GraphQLTypes["BannersFields_miscImpression_Update"] | undefined,
	delete?: GraphQLTypes["BannersFields_miscImpression_Delete"] | undefined
};
	["BannersFields_miscImpression_Create"]: {
	__typename: "BannersFields_miscImpression_Create",
	permission: boolean
};
	["BannersFields_miscImpression_Read"]: {
	__typename: "BannersFields_miscImpression_Read",
	permission: boolean
};
	["BannersFields_miscImpression_Update"]: {
	__typename: "BannersFields_miscImpression_Update",
	permission: boolean
};
	["BannersFields_miscImpression_Delete"]: {
	__typename: "BannersFields_miscImpression_Delete",
	permission: boolean
};
	["BannersFields_clickTotal"]: {
	__typename: "BannersFields_clickTotal",
	create?: GraphQLTypes["BannersFields_clickTotal_Create"] | undefined,
	read?: GraphQLTypes["BannersFields_clickTotal_Read"] | undefined,
	update?: GraphQLTypes["BannersFields_clickTotal_Update"] | undefined,
	delete?: GraphQLTypes["BannersFields_clickTotal_Delete"] | undefined
};
	["BannersFields_clickTotal_Create"]: {
	__typename: "BannersFields_clickTotal_Create",
	permission: boolean
};
	["BannersFields_clickTotal_Read"]: {
	__typename: "BannersFields_clickTotal_Read",
	permission: boolean
};
	["BannersFields_clickTotal_Update"]: {
	__typename: "BannersFields_clickTotal_Update",
	permission: boolean
};
	["BannersFields_clickTotal_Delete"]: {
	__typename: "BannersFields_clickTotal_Delete",
	permission: boolean
};
	["BannersFields_totalImpression"]: {
	__typename: "BannersFields_totalImpression",
	create?: GraphQLTypes["BannersFields_totalImpression_Create"] | undefined,
	read?: GraphQLTypes["BannersFields_totalImpression_Read"] | undefined,
	update?: GraphQLTypes["BannersFields_totalImpression_Update"] | undefined,
	delete?: GraphQLTypes["BannersFields_totalImpression_Delete"] | undefined
};
	["BannersFields_totalImpression_Create"]: {
	__typename: "BannersFields_totalImpression_Create",
	permission: boolean
};
	["BannersFields_totalImpression_Read"]: {
	__typename: "BannersFields_totalImpression_Read",
	permission: boolean
};
	["BannersFields_totalImpression_Update"]: {
	__typename: "BannersFields_totalImpression_Update",
	permission: boolean
};
	["BannersFields_totalImpression_Delete"]: {
	__typename: "BannersFields_totalImpression_Delete",
	permission: boolean
};
	["BannersFields_updatedAt"]: {
	__typename: "BannersFields_updatedAt",
	create?: GraphQLTypes["BannersFields_updatedAt_Create"] | undefined,
	read?: GraphQLTypes["BannersFields_updatedAt_Read"] | undefined,
	update?: GraphQLTypes["BannersFields_updatedAt_Update"] | undefined,
	delete?: GraphQLTypes["BannersFields_updatedAt_Delete"] | undefined
};
	["BannersFields_updatedAt_Create"]: {
	__typename: "BannersFields_updatedAt_Create",
	permission: boolean
};
	["BannersFields_updatedAt_Read"]: {
	__typename: "BannersFields_updatedAt_Read",
	permission: boolean
};
	["BannersFields_updatedAt_Update"]: {
	__typename: "BannersFields_updatedAt_Update",
	permission: boolean
};
	["BannersFields_updatedAt_Delete"]: {
	__typename: "BannersFields_updatedAt_Delete",
	permission: boolean
};
	["BannersFields_createdAt"]: {
	__typename: "BannersFields_createdAt",
	create?: GraphQLTypes["BannersFields_createdAt_Create"] | undefined,
	read?: GraphQLTypes["BannersFields_createdAt_Read"] | undefined,
	update?: GraphQLTypes["BannersFields_createdAt_Update"] | undefined,
	delete?: GraphQLTypes["BannersFields_createdAt_Delete"] | undefined
};
	["BannersFields_createdAt_Create"]: {
	__typename: "BannersFields_createdAt_Create",
	permission: boolean
};
	["BannersFields_createdAt_Read"]: {
	__typename: "BannersFields_createdAt_Read",
	permission: boolean
};
	["BannersFields_createdAt_Update"]: {
	__typename: "BannersFields_createdAt_Update",
	permission: boolean
};
	["BannersFields_createdAt_Delete"]: {
	__typename: "BannersFields_createdAt_Delete",
	permission: boolean
};
	["BannersCreateAccess"]: {
	__typename: "BannersCreateAccess",
	permission: boolean,
	where?: GraphQLTypes["JSONObject"] | undefined
};
	["BannersReadAccess"]: {
	__typename: "BannersReadAccess",
	permission: boolean,
	where?: GraphQLTypes["JSONObject"] | undefined
};
	["BannersUpdateAccess"]: {
	__typename: "BannersUpdateAccess",
	permission: boolean,
	where?: GraphQLTypes["JSONObject"] | undefined
};
	["BannersDeleteAccess"]: {
	__typename: "BannersDeleteAccess",
	permission: boolean,
	where?: GraphQLTypes["JSONObject"] | undefined
};
	["campaignsAccess"]: {
	__typename: "campaignsAccess",
	fields?: GraphQLTypes["CampaignsFields"] | undefined,
	create?: GraphQLTypes["CampaignsCreateAccess"] | undefined,
	read?: GraphQLTypes["CampaignsReadAccess"] | undefined,
	update?: GraphQLTypes["CampaignsUpdateAccess"] | undefined,
	delete?: GraphQLTypes["CampaignsDeleteAccess"] | undefined
};
	["CampaignsFields"]: {
	__typename: "CampaignsFields",
	creator?: GraphQLTypes["CampaignsFields_creator"] | undefined,
	invoiceNumber?: GraphQLTypes["CampaignsFields_invoiceNumber"] | undefined,
	quotationNumber?: GraphQLTypes["CampaignsFields_quotationNumber"] | undefined,
	campaignName?: GraphQLTypes["CampaignsFields_campaignName"] | undefined,
	client?: GraphQLTypes["CampaignsFields_client"] | undefined,
	contactPerson?: GraphQLTypes["CampaignsFields_contactPerson"] | undefined,
	email?: GraphQLTypes["CampaignsFields_email"] | undefined,
	phone?: GraphQLTypes["CampaignsFields_phone"] | undefined,
	agentName?: GraphQLTypes["CampaignsFields_agentName"] | undefined,
	agentCommission?: GraphQLTypes["CampaignsFields_agentCommission"] | undefined,
	agentOrderNumber?: GraphQLTypes["CampaignsFields_agentOrderNumber"] | undefined,
	_firstPublishedTime?: GraphQLTypes["CampaignsFields__firstPublishedTime"] | undefined,
	updatedAt?: GraphQLTypes["CampaignsFields_updatedAt"] | undefined,
	createdAt?: GraphQLTypes["CampaignsFields_createdAt"] | undefined
};
	["CampaignsFields_creator"]: {
	__typename: "CampaignsFields_creator",
	create?: GraphQLTypes["CampaignsFields_creator_Create"] | undefined,
	read?: GraphQLTypes["CampaignsFields_creator_Read"] | undefined,
	update?: GraphQLTypes["CampaignsFields_creator_Update"] | undefined,
	delete?: GraphQLTypes["CampaignsFields_creator_Delete"] | undefined
};
	["CampaignsFields_creator_Create"]: {
	__typename: "CampaignsFields_creator_Create",
	permission: boolean
};
	["CampaignsFields_creator_Read"]: {
	__typename: "CampaignsFields_creator_Read",
	permission: boolean
};
	["CampaignsFields_creator_Update"]: {
	__typename: "CampaignsFields_creator_Update",
	permission: boolean
};
	["CampaignsFields_creator_Delete"]: {
	__typename: "CampaignsFields_creator_Delete",
	permission: boolean
};
	["CampaignsFields_invoiceNumber"]: {
	__typename: "CampaignsFields_invoiceNumber",
	create?: GraphQLTypes["CampaignsFields_invoiceNumber_Create"] | undefined,
	read?: GraphQLTypes["CampaignsFields_invoiceNumber_Read"] | undefined,
	update?: GraphQLTypes["CampaignsFields_invoiceNumber_Update"] | undefined,
	delete?: GraphQLTypes["CampaignsFields_invoiceNumber_Delete"] | undefined
};
	["CampaignsFields_invoiceNumber_Create"]: {
	__typename: "CampaignsFields_invoiceNumber_Create",
	permission: boolean
};
	["CampaignsFields_invoiceNumber_Read"]: {
	__typename: "CampaignsFields_invoiceNumber_Read",
	permission: boolean
};
	["CampaignsFields_invoiceNumber_Update"]: {
	__typename: "CampaignsFields_invoiceNumber_Update",
	permission: boolean
};
	["CampaignsFields_invoiceNumber_Delete"]: {
	__typename: "CampaignsFields_invoiceNumber_Delete",
	permission: boolean
};
	["CampaignsFields_quotationNumber"]: {
	__typename: "CampaignsFields_quotationNumber",
	create?: GraphQLTypes["CampaignsFields_quotationNumber_Create"] | undefined,
	read?: GraphQLTypes["CampaignsFields_quotationNumber_Read"] | undefined,
	update?: GraphQLTypes["CampaignsFields_quotationNumber_Update"] | undefined,
	delete?: GraphQLTypes["CampaignsFields_quotationNumber_Delete"] | undefined
};
	["CampaignsFields_quotationNumber_Create"]: {
	__typename: "CampaignsFields_quotationNumber_Create",
	permission: boolean
};
	["CampaignsFields_quotationNumber_Read"]: {
	__typename: "CampaignsFields_quotationNumber_Read",
	permission: boolean
};
	["CampaignsFields_quotationNumber_Update"]: {
	__typename: "CampaignsFields_quotationNumber_Update",
	permission: boolean
};
	["CampaignsFields_quotationNumber_Delete"]: {
	__typename: "CampaignsFields_quotationNumber_Delete",
	permission: boolean
};
	["CampaignsFields_campaignName"]: {
	__typename: "CampaignsFields_campaignName",
	create?: GraphQLTypes["CampaignsFields_campaignName_Create"] | undefined,
	read?: GraphQLTypes["CampaignsFields_campaignName_Read"] | undefined,
	update?: GraphQLTypes["CampaignsFields_campaignName_Update"] | undefined,
	delete?: GraphQLTypes["CampaignsFields_campaignName_Delete"] | undefined
};
	["CampaignsFields_campaignName_Create"]: {
	__typename: "CampaignsFields_campaignName_Create",
	permission: boolean
};
	["CampaignsFields_campaignName_Read"]: {
	__typename: "CampaignsFields_campaignName_Read",
	permission: boolean
};
	["CampaignsFields_campaignName_Update"]: {
	__typename: "CampaignsFields_campaignName_Update",
	permission: boolean
};
	["CampaignsFields_campaignName_Delete"]: {
	__typename: "CampaignsFields_campaignName_Delete",
	permission: boolean
};
	["CampaignsFields_client"]: {
	__typename: "CampaignsFields_client",
	create?: GraphQLTypes["CampaignsFields_client_Create"] | undefined,
	read?: GraphQLTypes["CampaignsFields_client_Read"] | undefined,
	update?: GraphQLTypes["CampaignsFields_client_Update"] | undefined,
	delete?: GraphQLTypes["CampaignsFields_client_Delete"] | undefined
};
	["CampaignsFields_client_Create"]: {
	__typename: "CampaignsFields_client_Create",
	permission: boolean
};
	["CampaignsFields_client_Read"]: {
	__typename: "CampaignsFields_client_Read",
	permission: boolean
};
	["CampaignsFields_client_Update"]: {
	__typename: "CampaignsFields_client_Update",
	permission: boolean
};
	["CampaignsFields_client_Delete"]: {
	__typename: "CampaignsFields_client_Delete",
	permission: boolean
};
	["CampaignsFields_contactPerson"]: {
	__typename: "CampaignsFields_contactPerson",
	create?: GraphQLTypes["CampaignsFields_contactPerson_Create"] | undefined,
	read?: GraphQLTypes["CampaignsFields_contactPerson_Read"] | undefined,
	update?: GraphQLTypes["CampaignsFields_contactPerson_Update"] | undefined,
	delete?: GraphQLTypes["CampaignsFields_contactPerson_Delete"] | undefined
};
	["CampaignsFields_contactPerson_Create"]: {
	__typename: "CampaignsFields_contactPerson_Create",
	permission: boolean
};
	["CampaignsFields_contactPerson_Read"]: {
	__typename: "CampaignsFields_contactPerson_Read",
	permission: boolean
};
	["CampaignsFields_contactPerson_Update"]: {
	__typename: "CampaignsFields_contactPerson_Update",
	permission: boolean
};
	["CampaignsFields_contactPerson_Delete"]: {
	__typename: "CampaignsFields_contactPerson_Delete",
	permission: boolean
};
	["CampaignsFields_email"]: {
	__typename: "CampaignsFields_email",
	create?: GraphQLTypes["CampaignsFields_email_Create"] | undefined,
	read?: GraphQLTypes["CampaignsFields_email_Read"] | undefined,
	update?: GraphQLTypes["CampaignsFields_email_Update"] | undefined,
	delete?: GraphQLTypes["CampaignsFields_email_Delete"] | undefined
};
	["CampaignsFields_email_Create"]: {
	__typename: "CampaignsFields_email_Create",
	permission: boolean
};
	["CampaignsFields_email_Read"]: {
	__typename: "CampaignsFields_email_Read",
	permission: boolean
};
	["CampaignsFields_email_Update"]: {
	__typename: "CampaignsFields_email_Update",
	permission: boolean
};
	["CampaignsFields_email_Delete"]: {
	__typename: "CampaignsFields_email_Delete",
	permission: boolean
};
	["CampaignsFields_phone"]: {
	__typename: "CampaignsFields_phone",
	create?: GraphQLTypes["CampaignsFields_phone_Create"] | undefined,
	read?: GraphQLTypes["CampaignsFields_phone_Read"] | undefined,
	update?: GraphQLTypes["CampaignsFields_phone_Update"] | undefined,
	delete?: GraphQLTypes["CampaignsFields_phone_Delete"] | undefined
};
	["CampaignsFields_phone_Create"]: {
	__typename: "CampaignsFields_phone_Create",
	permission: boolean
};
	["CampaignsFields_phone_Read"]: {
	__typename: "CampaignsFields_phone_Read",
	permission: boolean
};
	["CampaignsFields_phone_Update"]: {
	__typename: "CampaignsFields_phone_Update",
	permission: boolean
};
	["CampaignsFields_phone_Delete"]: {
	__typename: "CampaignsFields_phone_Delete",
	permission: boolean
};
	["CampaignsFields_agentName"]: {
	__typename: "CampaignsFields_agentName",
	create?: GraphQLTypes["CampaignsFields_agentName_Create"] | undefined,
	read?: GraphQLTypes["CampaignsFields_agentName_Read"] | undefined,
	update?: GraphQLTypes["CampaignsFields_agentName_Update"] | undefined,
	delete?: GraphQLTypes["CampaignsFields_agentName_Delete"] | undefined
};
	["CampaignsFields_agentName_Create"]: {
	__typename: "CampaignsFields_agentName_Create",
	permission: boolean
};
	["CampaignsFields_agentName_Read"]: {
	__typename: "CampaignsFields_agentName_Read",
	permission: boolean
};
	["CampaignsFields_agentName_Update"]: {
	__typename: "CampaignsFields_agentName_Update",
	permission: boolean
};
	["CampaignsFields_agentName_Delete"]: {
	__typename: "CampaignsFields_agentName_Delete",
	permission: boolean
};
	["CampaignsFields_agentCommission"]: {
	__typename: "CampaignsFields_agentCommission",
	create?: GraphQLTypes["CampaignsFields_agentCommission_Create"] | undefined,
	read?: GraphQLTypes["CampaignsFields_agentCommission_Read"] | undefined,
	update?: GraphQLTypes["CampaignsFields_agentCommission_Update"] | undefined,
	delete?: GraphQLTypes["CampaignsFields_agentCommission_Delete"] | undefined
};
	["CampaignsFields_agentCommission_Create"]: {
	__typename: "CampaignsFields_agentCommission_Create",
	permission: boolean
};
	["CampaignsFields_agentCommission_Read"]: {
	__typename: "CampaignsFields_agentCommission_Read",
	permission: boolean
};
	["CampaignsFields_agentCommission_Update"]: {
	__typename: "CampaignsFields_agentCommission_Update",
	permission: boolean
};
	["CampaignsFields_agentCommission_Delete"]: {
	__typename: "CampaignsFields_agentCommission_Delete",
	permission: boolean
};
	["CampaignsFields_agentOrderNumber"]: {
	__typename: "CampaignsFields_agentOrderNumber",
	create?: GraphQLTypes["CampaignsFields_agentOrderNumber_Create"] | undefined,
	read?: GraphQLTypes["CampaignsFields_agentOrderNumber_Read"] | undefined,
	update?: GraphQLTypes["CampaignsFields_agentOrderNumber_Update"] | undefined,
	delete?: GraphQLTypes["CampaignsFields_agentOrderNumber_Delete"] | undefined
};
	["CampaignsFields_agentOrderNumber_Create"]: {
	__typename: "CampaignsFields_agentOrderNumber_Create",
	permission: boolean
};
	["CampaignsFields_agentOrderNumber_Read"]: {
	__typename: "CampaignsFields_agentOrderNumber_Read",
	permission: boolean
};
	["CampaignsFields_agentOrderNumber_Update"]: {
	__typename: "CampaignsFields_agentOrderNumber_Update",
	permission: boolean
};
	["CampaignsFields_agentOrderNumber_Delete"]: {
	__typename: "CampaignsFields_agentOrderNumber_Delete",
	permission: boolean
};
	["CampaignsFields__firstPublishedTime"]: {
	__typename: "CampaignsFields__firstPublishedTime",
	create?: GraphQLTypes["CampaignsFields__firstPublishedTime_Create"] | undefined,
	read?: GraphQLTypes["CampaignsFields__firstPublishedTime_Read"] | undefined,
	update?: GraphQLTypes["CampaignsFields__firstPublishedTime_Update"] | undefined,
	delete?: GraphQLTypes["CampaignsFields__firstPublishedTime_Delete"] | undefined
};
	["CampaignsFields__firstPublishedTime_Create"]: {
	__typename: "CampaignsFields__firstPublishedTime_Create",
	permission: boolean
};
	["CampaignsFields__firstPublishedTime_Read"]: {
	__typename: "CampaignsFields__firstPublishedTime_Read",
	permission: boolean
};
	["CampaignsFields__firstPublishedTime_Update"]: {
	__typename: "CampaignsFields__firstPublishedTime_Update",
	permission: boolean
};
	["CampaignsFields__firstPublishedTime_Delete"]: {
	__typename: "CampaignsFields__firstPublishedTime_Delete",
	permission: boolean
};
	["CampaignsFields_updatedAt"]: {
	__typename: "CampaignsFields_updatedAt",
	create?: GraphQLTypes["CampaignsFields_updatedAt_Create"] | undefined,
	read?: GraphQLTypes["CampaignsFields_updatedAt_Read"] | undefined,
	update?: GraphQLTypes["CampaignsFields_updatedAt_Update"] | undefined,
	delete?: GraphQLTypes["CampaignsFields_updatedAt_Delete"] | undefined
};
	["CampaignsFields_updatedAt_Create"]: {
	__typename: "CampaignsFields_updatedAt_Create",
	permission: boolean
};
	["CampaignsFields_updatedAt_Read"]: {
	__typename: "CampaignsFields_updatedAt_Read",
	permission: boolean
};
	["CampaignsFields_updatedAt_Update"]: {
	__typename: "CampaignsFields_updatedAt_Update",
	permission: boolean
};
	["CampaignsFields_updatedAt_Delete"]: {
	__typename: "CampaignsFields_updatedAt_Delete",
	permission: boolean
};
	["CampaignsFields_createdAt"]: {
	__typename: "CampaignsFields_createdAt",
	create?: GraphQLTypes["CampaignsFields_createdAt_Create"] | undefined,
	read?: GraphQLTypes["CampaignsFields_createdAt_Read"] | undefined,
	update?: GraphQLTypes["CampaignsFields_createdAt_Update"] | undefined,
	delete?: GraphQLTypes["CampaignsFields_createdAt_Delete"] | undefined
};
	["CampaignsFields_createdAt_Create"]: {
	__typename: "CampaignsFields_createdAt_Create",
	permission: boolean
};
	["CampaignsFields_createdAt_Read"]: {
	__typename: "CampaignsFields_createdAt_Read",
	permission: boolean
};
	["CampaignsFields_createdAt_Update"]: {
	__typename: "CampaignsFields_createdAt_Update",
	permission: boolean
};
	["CampaignsFields_createdAt_Delete"]: {
	__typename: "CampaignsFields_createdAt_Delete",
	permission: boolean
};
	["CampaignsCreateAccess"]: {
	__typename: "CampaignsCreateAccess",
	permission: boolean,
	where?: GraphQLTypes["JSONObject"] | undefined
};
	["CampaignsReadAccess"]: {
	__typename: "CampaignsReadAccess",
	permission: boolean,
	where?: GraphQLTypes["JSONObject"] | undefined
};
	["CampaignsUpdateAccess"]: {
	__typename: "CampaignsUpdateAccess",
	permission: boolean,
	where?: GraphQLTypes["JSONObject"] | undefined
};
	["CampaignsDeleteAccess"]: {
	__typename: "CampaignsDeleteAccess",
	permission: boolean,
	where?: GraphQLTypes["JSONObject"] | undefined
};
	["advertisesAccess"]: {
	__typename: "advertisesAccess",
	fields?: GraphQLTypes["AdvertisesFields"] | undefined,
	create?: GraphQLTypes["AdvertisesCreateAccess"] | undefined,
	read?: GraphQLTypes["AdvertisesReadAccess"] | undefined,
	update?: GraphQLTypes["AdvertisesUpdateAccess"] | undefined,
	delete?: GraphQLTypes["AdvertisesDeleteAccess"] | undefined
};
	["AdvertisesFields"]: {
	__typename: "AdvertisesFields",
	advertise?: GraphQLTypes["AdvertisesFields_advertise"] | undefined,
	advertiseStartDate?: GraphQLTypes["AdvertisesFields_advertiseStartDate"] | undefined,
	advertiseEndDate?: GraphQLTypes["AdvertisesFields_advertiseEndDate"] | undefined,
	type?: GraphQLTypes["AdvertisesFields_type"] | undefined,
	invoiceDescription?: GraphQLTypes["AdvertisesFields_invoiceDescription"] | undefined,
	standardPrice?: GraphQLTypes["AdvertisesFields_standardPrice"] | undefined,
	discount?: GraphQLTypes["AdvertisesFields_discount"] | undefined,
	discountPercentage?: GraphQLTypes["AdvertisesFields_discountPercentage"] | undefined,
	_calculateByDiscount?: GraphQLTypes["AdvertisesFields__calculateByDiscount"] | undefined,
	timableCost?: GraphQLTypes["AdvertisesFields_timableCost"] | undefined,
	remark?: GraphQLTypes["AdvertisesFields_remark"] | undefined,
	public?: GraphQLTypes["AdvertisesFields_public"] | undefined,
	campaignName?: GraphQLTypes["AdvertisesFields_campaignName"] | undefined,
	campaignId?: GraphQLTypes["AdvertisesFields_campaignId"] | undefined,
	status?: GraphQLTypes["AdvertisesFields_status"] | undefined,
	updatedAt?: GraphQLTypes["AdvertisesFields_updatedAt"] | undefined,
	createdAt?: GraphQLTypes["AdvertisesFields_createdAt"] | undefined
};
	["AdvertisesFields_advertise"]: {
	__typename: "AdvertisesFields_advertise",
	create?: GraphQLTypes["AdvertisesFields_advertise_Create"] | undefined,
	read?: GraphQLTypes["AdvertisesFields_advertise_Read"] | undefined,
	update?: GraphQLTypes["AdvertisesFields_advertise_Update"] | undefined,
	delete?: GraphQLTypes["AdvertisesFields_advertise_Delete"] | undefined,
	fields?: GraphQLTypes["AdvertisesFields_advertise_Fields"] | undefined
};
	["AdvertisesFields_advertise_Create"]: {
	__typename: "AdvertisesFields_advertise_Create",
	permission: boolean
};
	["AdvertisesFields_advertise_Read"]: {
	__typename: "AdvertisesFields_advertise_Read",
	permission: boolean
};
	["AdvertisesFields_advertise_Update"]: {
	__typename: "AdvertisesFields_advertise_Update",
	permission: boolean
};
	["AdvertisesFields_advertise_Delete"]: {
	__typename: "AdvertisesFields_advertise_Delete",
	permission: boolean
};
	["AdvertisesFields_advertise_Fields"]: {
	__typename: "AdvertisesFields_advertise_Fields",
	type?: GraphQLTypes["AdvertisesFields_advertise_type"] | undefined,
	event?: GraphQLTypes["AdvertisesFields_advertise_event"] | undefined,
	keyword?: GraphQLTypes["AdvertisesFields_advertise_keyword"] | undefined,
	location?: GraphQLTypes["AdvertisesFields_advertise_location"] | undefined,
	displayPosition?: GraphQLTypes["AdvertisesFields_advertise_displayPosition"] | undefined,
	rankingListRemark?: GraphQLTypes["AdvertisesFields_advertise_rankingListRemark"] | undefined
};
	["AdvertisesFields_advertise_type"]: {
	__typename: "AdvertisesFields_advertise_type",
	create?: GraphQLTypes["AdvertisesFields_advertise_type_Create"] | undefined,
	read?: GraphQLTypes["AdvertisesFields_advertise_type_Read"] | undefined,
	update?: GraphQLTypes["AdvertisesFields_advertise_type_Update"] | undefined,
	delete?: GraphQLTypes["AdvertisesFields_advertise_type_Delete"] | undefined
};
	["AdvertisesFields_advertise_type_Create"]: {
	__typename: "AdvertisesFields_advertise_type_Create",
	permission: boolean
};
	["AdvertisesFields_advertise_type_Read"]: {
	__typename: "AdvertisesFields_advertise_type_Read",
	permission: boolean
};
	["AdvertisesFields_advertise_type_Update"]: {
	__typename: "AdvertisesFields_advertise_type_Update",
	permission: boolean
};
	["AdvertisesFields_advertise_type_Delete"]: {
	__typename: "AdvertisesFields_advertise_type_Delete",
	permission: boolean
};
	["AdvertisesFields_advertise_event"]: {
	__typename: "AdvertisesFields_advertise_event",
	create?: GraphQLTypes["AdvertisesFields_advertise_event_Create"] | undefined,
	read?: GraphQLTypes["AdvertisesFields_advertise_event_Read"] | undefined,
	update?: GraphQLTypes["AdvertisesFields_advertise_event_Update"] | undefined,
	delete?: GraphQLTypes["AdvertisesFields_advertise_event_Delete"] | undefined
};
	["AdvertisesFields_advertise_event_Create"]: {
	__typename: "AdvertisesFields_advertise_event_Create",
	permission: boolean
};
	["AdvertisesFields_advertise_event_Read"]: {
	__typename: "AdvertisesFields_advertise_event_Read",
	permission: boolean
};
	["AdvertisesFields_advertise_event_Update"]: {
	__typename: "AdvertisesFields_advertise_event_Update",
	permission: boolean
};
	["AdvertisesFields_advertise_event_Delete"]: {
	__typename: "AdvertisesFields_advertise_event_Delete",
	permission: boolean
};
	["AdvertisesFields_advertise_keyword"]: {
	__typename: "AdvertisesFields_advertise_keyword",
	create?: GraphQLTypes["AdvertisesFields_advertise_keyword_Create"] | undefined,
	read?: GraphQLTypes["AdvertisesFields_advertise_keyword_Read"] | undefined,
	update?: GraphQLTypes["AdvertisesFields_advertise_keyword_Update"] | undefined,
	delete?: GraphQLTypes["AdvertisesFields_advertise_keyword_Delete"] | undefined
};
	["AdvertisesFields_advertise_keyword_Create"]: {
	__typename: "AdvertisesFields_advertise_keyword_Create",
	permission: boolean
};
	["AdvertisesFields_advertise_keyword_Read"]: {
	__typename: "AdvertisesFields_advertise_keyword_Read",
	permission: boolean
};
	["AdvertisesFields_advertise_keyword_Update"]: {
	__typename: "AdvertisesFields_advertise_keyword_Update",
	permission: boolean
};
	["AdvertisesFields_advertise_keyword_Delete"]: {
	__typename: "AdvertisesFields_advertise_keyword_Delete",
	permission: boolean
};
	["AdvertisesFields_advertise_location"]: {
	__typename: "AdvertisesFields_advertise_location",
	create?: GraphQLTypes["AdvertisesFields_advertise_location_Create"] | undefined,
	read?: GraphQLTypes["AdvertisesFields_advertise_location_Read"] | undefined,
	update?: GraphQLTypes["AdvertisesFields_advertise_location_Update"] | undefined,
	delete?: GraphQLTypes["AdvertisesFields_advertise_location_Delete"] | undefined
};
	["AdvertisesFields_advertise_location_Create"]: {
	__typename: "AdvertisesFields_advertise_location_Create",
	permission: boolean
};
	["AdvertisesFields_advertise_location_Read"]: {
	__typename: "AdvertisesFields_advertise_location_Read",
	permission: boolean
};
	["AdvertisesFields_advertise_location_Update"]: {
	__typename: "AdvertisesFields_advertise_location_Update",
	permission: boolean
};
	["AdvertisesFields_advertise_location_Delete"]: {
	__typename: "AdvertisesFields_advertise_location_Delete",
	permission: boolean
};
	["AdvertisesFields_advertise_displayPosition"]: {
	__typename: "AdvertisesFields_advertise_displayPosition",
	create?: GraphQLTypes["AdvertisesFields_advertise_displayPosition_Create"] | undefined,
	read?: GraphQLTypes["AdvertisesFields_advertise_displayPosition_Read"] | undefined,
	update?: GraphQLTypes["AdvertisesFields_advertise_displayPosition_Update"] | undefined,
	delete?: GraphQLTypes["AdvertisesFields_advertise_displayPosition_Delete"] | undefined
};
	["AdvertisesFields_advertise_displayPosition_Create"]: {
	__typename: "AdvertisesFields_advertise_displayPosition_Create",
	permission: boolean
};
	["AdvertisesFields_advertise_displayPosition_Read"]: {
	__typename: "AdvertisesFields_advertise_displayPosition_Read",
	permission: boolean
};
	["AdvertisesFields_advertise_displayPosition_Update"]: {
	__typename: "AdvertisesFields_advertise_displayPosition_Update",
	permission: boolean
};
	["AdvertisesFields_advertise_displayPosition_Delete"]: {
	__typename: "AdvertisesFields_advertise_displayPosition_Delete",
	permission: boolean
};
	["AdvertisesFields_advertise_rankingListRemark"]: {
	__typename: "AdvertisesFields_advertise_rankingListRemark",
	create?: GraphQLTypes["AdvertisesFields_advertise_rankingListRemark_Create"] | undefined,
	read?: GraphQLTypes["AdvertisesFields_advertise_rankingListRemark_Read"] | undefined,
	update?: GraphQLTypes["AdvertisesFields_advertise_rankingListRemark_Update"] | undefined,
	delete?: GraphQLTypes["AdvertisesFields_advertise_rankingListRemark_Delete"] | undefined
};
	["AdvertisesFields_advertise_rankingListRemark_Create"]: {
	__typename: "AdvertisesFields_advertise_rankingListRemark_Create",
	permission: boolean
};
	["AdvertisesFields_advertise_rankingListRemark_Read"]: {
	__typename: "AdvertisesFields_advertise_rankingListRemark_Read",
	permission: boolean
};
	["AdvertisesFields_advertise_rankingListRemark_Update"]: {
	__typename: "AdvertisesFields_advertise_rankingListRemark_Update",
	permission: boolean
};
	["AdvertisesFields_advertise_rankingListRemark_Delete"]: {
	__typename: "AdvertisesFields_advertise_rankingListRemark_Delete",
	permission: boolean
};
	["AdvertisesFields_advertiseStartDate"]: {
	__typename: "AdvertisesFields_advertiseStartDate",
	create?: GraphQLTypes["AdvertisesFields_advertiseStartDate_Create"] | undefined,
	read?: GraphQLTypes["AdvertisesFields_advertiseStartDate_Read"] | undefined,
	update?: GraphQLTypes["AdvertisesFields_advertiseStartDate_Update"] | undefined,
	delete?: GraphQLTypes["AdvertisesFields_advertiseStartDate_Delete"] | undefined
};
	["AdvertisesFields_advertiseStartDate_Create"]: {
	__typename: "AdvertisesFields_advertiseStartDate_Create",
	permission: boolean
};
	["AdvertisesFields_advertiseStartDate_Read"]: {
	__typename: "AdvertisesFields_advertiseStartDate_Read",
	permission: boolean
};
	["AdvertisesFields_advertiseStartDate_Update"]: {
	__typename: "AdvertisesFields_advertiseStartDate_Update",
	permission: boolean
};
	["AdvertisesFields_advertiseStartDate_Delete"]: {
	__typename: "AdvertisesFields_advertiseStartDate_Delete",
	permission: boolean
};
	["AdvertisesFields_advertiseEndDate"]: {
	__typename: "AdvertisesFields_advertiseEndDate",
	create?: GraphQLTypes["AdvertisesFields_advertiseEndDate_Create"] | undefined,
	read?: GraphQLTypes["AdvertisesFields_advertiseEndDate_Read"] | undefined,
	update?: GraphQLTypes["AdvertisesFields_advertiseEndDate_Update"] | undefined,
	delete?: GraphQLTypes["AdvertisesFields_advertiseEndDate_Delete"] | undefined
};
	["AdvertisesFields_advertiseEndDate_Create"]: {
	__typename: "AdvertisesFields_advertiseEndDate_Create",
	permission: boolean
};
	["AdvertisesFields_advertiseEndDate_Read"]: {
	__typename: "AdvertisesFields_advertiseEndDate_Read",
	permission: boolean
};
	["AdvertisesFields_advertiseEndDate_Update"]: {
	__typename: "AdvertisesFields_advertiseEndDate_Update",
	permission: boolean
};
	["AdvertisesFields_advertiseEndDate_Delete"]: {
	__typename: "AdvertisesFields_advertiseEndDate_Delete",
	permission: boolean
};
	["AdvertisesFields_type"]: {
	__typename: "AdvertisesFields_type",
	create?: GraphQLTypes["AdvertisesFields_type_Create"] | undefined,
	read?: GraphQLTypes["AdvertisesFields_type_Read"] | undefined,
	update?: GraphQLTypes["AdvertisesFields_type_Update"] | undefined,
	delete?: GraphQLTypes["AdvertisesFields_type_Delete"] | undefined
};
	["AdvertisesFields_type_Create"]: {
	__typename: "AdvertisesFields_type_Create",
	permission: boolean
};
	["AdvertisesFields_type_Read"]: {
	__typename: "AdvertisesFields_type_Read",
	permission: boolean
};
	["AdvertisesFields_type_Update"]: {
	__typename: "AdvertisesFields_type_Update",
	permission: boolean
};
	["AdvertisesFields_type_Delete"]: {
	__typename: "AdvertisesFields_type_Delete",
	permission: boolean
};
	["AdvertisesFields_invoiceDescription"]: {
	__typename: "AdvertisesFields_invoiceDescription",
	create?: GraphQLTypes["AdvertisesFields_invoiceDescription_Create"] | undefined,
	read?: GraphQLTypes["AdvertisesFields_invoiceDescription_Read"] | undefined,
	update?: GraphQLTypes["AdvertisesFields_invoiceDescription_Update"] | undefined,
	delete?: GraphQLTypes["AdvertisesFields_invoiceDescription_Delete"] | undefined
};
	["AdvertisesFields_invoiceDescription_Create"]: {
	__typename: "AdvertisesFields_invoiceDescription_Create",
	permission: boolean
};
	["AdvertisesFields_invoiceDescription_Read"]: {
	__typename: "AdvertisesFields_invoiceDescription_Read",
	permission: boolean
};
	["AdvertisesFields_invoiceDescription_Update"]: {
	__typename: "AdvertisesFields_invoiceDescription_Update",
	permission: boolean
};
	["AdvertisesFields_invoiceDescription_Delete"]: {
	__typename: "AdvertisesFields_invoiceDescription_Delete",
	permission: boolean
};
	["AdvertisesFields_standardPrice"]: {
	__typename: "AdvertisesFields_standardPrice",
	create?: GraphQLTypes["AdvertisesFields_standardPrice_Create"] | undefined,
	read?: GraphQLTypes["AdvertisesFields_standardPrice_Read"] | undefined,
	update?: GraphQLTypes["AdvertisesFields_standardPrice_Update"] | undefined,
	delete?: GraphQLTypes["AdvertisesFields_standardPrice_Delete"] | undefined
};
	["AdvertisesFields_standardPrice_Create"]: {
	__typename: "AdvertisesFields_standardPrice_Create",
	permission: boolean
};
	["AdvertisesFields_standardPrice_Read"]: {
	__typename: "AdvertisesFields_standardPrice_Read",
	permission: boolean
};
	["AdvertisesFields_standardPrice_Update"]: {
	__typename: "AdvertisesFields_standardPrice_Update",
	permission: boolean
};
	["AdvertisesFields_standardPrice_Delete"]: {
	__typename: "AdvertisesFields_standardPrice_Delete",
	permission: boolean
};
	["AdvertisesFields_discount"]: {
	__typename: "AdvertisesFields_discount",
	create?: GraphQLTypes["AdvertisesFields_discount_Create"] | undefined,
	read?: GraphQLTypes["AdvertisesFields_discount_Read"] | undefined,
	update?: GraphQLTypes["AdvertisesFields_discount_Update"] | undefined,
	delete?: GraphQLTypes["AdvertisesFields_discount_Delete"] | undefined
};
	["AdvertisesFields_discount_Create"]: {
	__typename: "AdvertisesFields_discount_Create",
	permission: boolean
};
	["AdvertisesFields_discount_Read"]: {
	__typename: "AdvertisesFields_discount_Read",
	permission: boolean
};
	["AdvertisesFields_discount_Update"]: {
	__typename: "AdvertisesFields_discount_Update",
	permission: boolean
};
	["AdvertisesFields_discount_Delete"]: {
	__typename: "AdvertisesFields_discount_Delete",
	permission: boolean
};
	["AdvertisesFields_discountPercentage"]: {
	__typename: "AdvertisesFields_discountPercentage",
	create?: GraphQLTypes["AdvertisesFields_discountPercentage_Create"] | undefined,
	read?: GraphQLTypes["AdvertisesFields_discountPercentage_Read"] | undefined,
	update?: GraphQLTypes["AdvertisesFields_discountPercentage_Update"] | undefined,
	delete?: GraphQLTypes["AdvertisesFields_discountPercentage_Delete"] | undefined
};
	["AdvertisesFields_discountPercentage_Create"]: {
	__typename: "AdvertisesFields_discountPercentage_Create",
	permission: boolean
};
	["AdvertisesFields_discountPercentage_Read"]: {
	__typename: "AdvertisesFields_discountPercentage_Read",
	permission: boolean
};
	["AdvertisesFields_discountPercentage_Update"]: {
	__typename: "AdvertisesFields_discountPercentage_Update",
	permission: boolean
};
	["AdvertisesFields_discountPercentage_Delete"]: {
	__typename: "AdvertisesFields_discountPercentage_Delete",
	permission: boolean
};
	["AdvertisesFields__calculateByDiscount"]: {
	__typename: "AdvertisesFields__calculateByDiscount",
	create?: GraphQLTypes["AdvertisesFields__calculateByDiscount_Create"] | undefined,
	read?: GraphQLTypes["AdvertisesFields__calculateByDiscount_Read"] | undefined,
	update?: GraphQLTypes["AdvertisesFields__calculateByDiscount_Update"] | undefined,
	delete?: GraphQLTypes["AdvertisesFields__calculateByDiscount_Delete"] | undefined
};
	["AdvertisesFields__calculateByDiscount_Create"]: {
	__typename: "AdvertisesFields__calculateByDiscount_Create",
	permission: boolean
};
	["AdvertisesFields__calculateByDiscount_Read"]: {
	__typename: "AdvertisesFields__calculateByDiscount_Read",
	permission: boolean
};
	["AdvertisesFields__calculateByDiscount_Update"]: {
	__typename: "AdvertisesFields__calculateByDiscount_Update",
	permission: boolean
};
	["AdvertisesFields__calculateByDiscount_Delete"]: {
	__typename: "AdvertisesFields__calculateByDiscount_Delete",
	permission: boolean
};
	["AdvertisesFields_timableCost"]: {
	__typename: "AdvertisesFields_timableCost",
	create?: GraphQLTypes["AdvertisesFields_timableCost_Create"] | undefined,
	read?: GraphQLTypes["AdvertisesFields_timableCost_Read"] | undefined,
	update?: GraphQLTypes["AdvertisesFields_timableCost_Update"] | undefined,
	delete?: GraphQLTypes["AdvertisesFields_timableCost_Delete"] | undefined
};
	["AdvertisesFields_timableCost_Create"]: {
	__typename: "AdvertisesFields_timableCost_Create",
	permission: boolean
};
	["AdvertisesFields_timableCost_Read"]: {
	__typename: "AdvertisesFields_timableCost_Read",
	permission: boolean
};
	["AdvertisesFields_timableCost_Update"]: {
	__typename: "AdvertisesFields_timableCost_Update",
	permission: boolean
};
	["AdvertisesFields_timableCost_Delete"]: {
	__typename: "AdvertisesFields_timableCost_Delete",
	permission: boolean
};
	["AdvertisesFields_remark"]: {
	__typename: "AdvertisesFields_remark",
	create?: GraphQLTypes["AdvertisesFields_remark_Create"] | undefined,
	read?: GraphQLTypes["AdvertisesFields_remark_Read"] | undefined,
	update?: GraphQLTypes["AdvertisesFields_remark_Update"] | undefined,
	delete?: GraphQLTypes["AdvertisesFields_remark_Delete"] | undefined
};
	["AdvertisesFields_remark_Create"]: {
	__typename: "AdvertisesFields_remark_Create",
	permission: boolean
};
	["AdvertisesFields_remark_Read"]: {
	__typename: "AdvertisesFields_remark_Read",
	permission: boolean
};
	["AdvertisesFields_remark_Update"]: {
	__typename: "AdvertisesFields_remark_Update",
	permission: boolean
};
	["AdvertisesFields_remark_Delete"]: {
	__typename: "AdvertisesFields_remark_Delete",
	permission: boolean
};
	["AdvertisesFields_public"]: {
	__typename: "AdvertisesFields_public",
	create?: GraphQLTypes["AdvertisesFields_public_Create"] | undefined,
	read?: GraphQLTypes["AdvertisesFields_public_Read"] | undefined,
	update?: GraphQLTypes["AdvertisesFields_public_Update"] | undefined,
	delete?: GraphQLTypes["AdvertisesFields_public_Delete"] | undefined
};
	["AdvertisesFields_public_Create"]: {
	__typename: "AdvertisesFields_public_Create",
	permission: boolean
};
	["AdvertisesFields_public_Read"]: {
	__typename: "AdvertisesFields_public_Read",
	permission: boolean
};
	["AdvertisesFields_public_Update"]: {
	__typename: "AdvertisesFields_public_Update",
	permission: boolean
};
	["AdvertisesFields_public_Delete"]: {
	__typename: "AdvertisesFields_public_Delete",
	permission: boolean
};
	["AdvertisesFields_campaignName"]: {
	__typename: "AdvertisesFields_campaignName",
	create?: GraphQLTypes["AdvertisesFields_campaignName_Create"] | undefined,
	read?: GraphQLTypes["AdvertisesFields_campaignName_Read"] | undefined,
	update?: GraphQLTypes["AdvertisesFields_campaignName_Update"] | undefined,
	delete?: GraphQLTypes["AdvertisesFields_campaignName_Delete"] | undefined
};
	["AdvertisesFields_campaignName_Create"]: {
	__typename: "AdvertisesFields_campaignName_Create",
	permission: boolean
};
	["AdvertisesFields_campaignName_Read"]: {
	__typename: "AdvertisesFields_campaignName_Read",
	permission: boolean
};
	["AdvertisesFields_campaignName_Update"]: {
	__typename: "AdvertisesFields_campaignName_Update",
	permission: boolean
};
	["AdvertisesFields_campaignName_Delete"]: {
	__typename: "AdvertisesFields_campaignName_Delete",
	permission: boolean
};
	["AdvertisesFields_campaignId"]: {
	__typename: "AdvertisesFields_campaignId",
	create?: GraphQLTypes["AdvertisesFields_campaignId_Create"] | undefined,
	read?: GraphQLTypes["AdvertisesFields_campaignId_Read"] | undefined,
	update?: GraphQLTypes["AdvertisesFields_campaignId_Update"] | undefined,
	delete?: GraphQLTypes["AdvertisesFields_campaignId_Delete"] | undefined
};
	["AdvertisesFields_campaignId_Create"]: {
	__typename: "AdvertisesFields_campaignId_Create",
	permission: boolean
};
	["AdvertisesFields_campaignId_Read"]: {
	__typename: "AdvertisesFields_campaignId_Read",
	permission: boolean
};
	["AdvertisesFields_campaignId_Update"]: {
	__typename: "AdvertisesFields_campaignId_Update",
	permission: boolean
};
	["AdvertisesFields_campaignId_Delete"]: {
	__typename: "AdvertisesFields_campaignId_Delete",
	permission: boolean
};
	["AdvertisesFields_status"]: {
	__typename: "AdvertisesFields_status",
	create?: GraphQLTypes["AdvertisesFields_status_Create"] | undefined,
	read?: GraphQLTypes["AdvertisesFields_status_Read"] | undefined,
	update?: GraphQLTypes["AdvertisesFields_status_Update"] | undefined,
	delete?: GraphQLTypes["AdvertisesFields_status_Delete"] | undefined
};
	["AdvertisesFields_status_Create"]: {
	__typename: "AdvertisesFields_status_Create",
	permission: boolean
};
	["AdvertisesFields_status_Read"]: {
	__typename: "AdvertisesFields_status_Read",
	permission: boolean
};
	["AdvertisesFields_status_Update"]: {
	__typename: "AdvertisesFields_status_Update",
	permission: boolean
};
	["AdvertisesFields_status_Delete"]: {
	__typename: "AdvertisesFields_status_Delete",
	permission: boolean
};
	["AdvertisesFields_updatedAt"]: {
	__typename: "AdvertisesFields_updatedAt",
	create?: GraphQLTypes["AdvertisesFields_updatedAt_Create"] | undefined,
	read?: GraphQLTypes["AdvertisesFields_updatedAt_Read"] | undefined,
	update?: GraphQLTypes["AdvertisesFields_updatedAt_Update"] | undefined,
	delete?: GraphQLTypes["AdvertisesFields_updatedAt_Delete"] | undefined
};
	["AdvertisesFields_updatedAt_Create"]: {
	__typename: "AdvertisesFields_updatedAt_Create",
	permission: boolean
};
	["AdvertisesFields_updatedAt_Read"]: {
	__typename: "AdvertisesFields_updatedAt_Read",
	permission: boolean
};
	["AdvertisesFields_updatedAt_Update"]: {
	__typename: "AdvertisesFields_updatedAt_Update",
	permission: boolean
};
	["AdvertisesFields_updatedAt_Delete"]: {
	__typename: "AdvertisesFields_updatedAt_Delete",
	permission: boolean
};
	["AdvertisesFields_createdAt"]: {
	__typename: "AdvertisesFields_createdAt",
	create?: GraphQLTypes["AdvertisesFields_createdAt_Create"] | undefined,
	read?: GraphQLTypes["AdvertisesFields_createdAt_Read"] | undefined,
	update?: GraphQLTypes["AdvertisesFields_createdAt_Update"] | undefined,
	delete?: GraphQLTypes["AdvertisesFields_createdAt_Delete"] | undefined
};
	["AdvertisesFields_createdAt_Create"]: {
	__typename: "AdvertisesFields_createdAt_Create",
	permission: boolean
};
	["AdvertisesFields_createdAt_Read"]: {
	__typename: "AdvertisesFields_createdAt_Read",
	permission: boolean
};
	["AdvertisesFields_createdAt_Update"]: {
	__typename: "AdvertisesFields_createdAt_Update",
	permission: boolean
};
	["AdvertisesFields_createdAt_Delete"]: {
	__typename: "AdvertisesFields_createdAt_Delete",
	permission: boolean
};
	["AdvertisesCreateAccess"]: {
	__typename: "AdvertisesCreateAccess",
	permission: boolean,
	where?: GraphQLTypes["JSONObject"] | undefined
};
	["AdvertisesReadAccess"]: {
	__typename: "AdvertisesReadAccess",
	permission: boolean,
	where?: GraphQLTypes["JSONObject"] | undefined
};
	["AdvertisesUpdateAccess"]: {
	__typename: "AdvertisesUpdateAccess",
	permission: boolean,
	where?: GraphQLTypes["JSONObject"] | undefined
};
	["AdvertisesDeleteAccess"]: {
	__typename: "AdvertisesDeleteAccess",
	permission: boolean,
	where?: GraphQLTypes["JSONObject"] | undefined
};
	["staticsAccess"]: {
	__typename: "staticsAccess",
	fields?: GraphQLTypes["StaticsFields"] | undefined,
	create?: GraphQLTypes["StaticsCreateAccess"] | undefined,
	read?: GraphQLTypes["StaticsReadAccess"] | undefined,
	update?: GraphQLTypes["StaticsUpdateAccess"] | undefined,
	delete?: GraphQLTypes["StaticsDeleteAccess"] | undefined
};
	["StaticsFields"]: {
	__typename: "StaticsFields",
	name?: GraphQLTypes["StaticsFields_name"] | undefined,
	key?: GraphQLTypes["StaticsFields_key"] | undefined,
	content?: GraphQLTypes["StaticsFields_content"] | undefined,
	region?: GraphQLTypes["StaticsFields_region"] | undefined,
	updatedAt?: GraphQLTypes["StaticsFields_updatedAt"] | undefined,
	createdAt?: GraphQLTypes["StaticsFields_createdAt"] | undefined
};
	["StaticsFields_name"]: {
	__typename: "StaticsFields_name",
	create?: GraphQLTypes["StaticsFields_name_Create"] | undefined,
	read?: GraphQLTypes["StaticsFields_name_Read"] | undefined,
	update?: GraphQLTypes["StaticsFields_name_Update"] | undefined,
	delete?: GraphQLTypes["StaticsFields_name_Delete"] | undefined
};
	["StaticsFields_name_Create"]: {
	__typename: "StaticsFields_name_Create",
	permission: boolean
};
	["StaticsFields_name_Read"]: {
	__typename: "StaticsFields_name_Read",
	permission: boolean
};
	["StaticsFields_name_Update"]: {
	__typename: "StaticsFields_name_Update",
	permission: boolean
};
	["StaticsFields_name_Delete"]: {
	__typename: "StaticsFields_name_Delete",
	permission: boolean
};
	["StaticsFields_key"]: {
	__typename: "StaticsFields_key",
	create?: GraphQLTypes["StaticsFields_key_Create"] | undefined,
	read?: GraphQLTypes["StaticsFields_key_Read"] | undefined,
	update?: GraphQLTypes["StaticsFields_key_Update"] | undefined,
	delete?: GraphQLTypes["StaticsFields_key_Delete"] | undefined
};
	["StaticsFields_key_Create"]: {
	__typename: "StaticsFields_key_Create",
	permission: boolean
};
	["StaticsFields_key_Read"]: {
	__typename: "StaticsFields_key_Read",
	permission: boolean
};
	["StaticsFields_key_Update"]: {
	__typename: "StaticsFields_key_Update",
	permission: boolean
};
	["StaticsFields_key_Delete"]: {
	__typename: "StaticsFields_key_Delete",
	permission: boolean
};
	["StaticsFields_content"]: {
	__typename: "StaticsFields_content",
	create?: GraphQLTypes["StaticsFields_content_Create"] | undefined,
	read?: GraphQLTypes["StaticsFields_content_Read"] | undefined,
	update?: GraphQLTypes["StaticsFields_content_Update"] | undefined,
	delete?: GraphQLTypes["StaticsFields_content_Delete"] | undefined
};
	["StaticsFields_content_Create"]: {
	__typename: "StaticsFields_content_Create",
	permission: boolean
};
	["StaticsFields_content_Read"]: {
	__typename: "StaticsFields_content_Read",
	permission: boolean
};
	["StaticsFields_content_Update"]: {
	__typename: "StaticsFields_content_Update",
	permission: boolean
};
	["StaticsFields_content_Delete"]: {
	__typename: "StaticsFields_content_Delete",
	permission: boolean
};
	["StaticsFields_region"]: {
	__typename: "StaticsFields_region",
	create?: GraphQLTypes["StaticsFields_region_Create"] | undefined,
	read?: GraphQLTypes["StaticsFields_region_Read"] | undefined,
	update?: GraphQLTypes["StaticsFields_region_Update"] | undefined,
	delete?: GraphQLTypes["StaticsFields_region_Delete"] | undefined
};
	["StaticsFields_region_Create"]: {
	__typename: "StaticsFields_region_Create",
	permission: boolean
};
	["StaticsFields_region_Read"]: {
	__typename: "StaticsFields_region_Read",
	permission: boolean
};
	["StaticsFields_region_Update"]: {
	__typename: "StaticsFields_region_Update",
	permission: boolean
};
	["StaticsFields_region_Delete"]: {
	__typename: "StaticsFields_region_Delete",
	permission: boolean
};
	["StaticsFields_updatedAt"]: {
	__typename: "StaticsFields_updatedAt",
	create?: GraphQLTypes["StaticsFields_updatedAt_Create"] | undefined,
	read?: GraphQLTypes["StaticsFields_updatedAt_Read"] | undefined,
	update?: GraphQLTypes["StaticsFields_updatedAt_Update"] | undefined,
	delete?: GraphQLTypes["StaticsFields_updatedAt_Delete"] | undefined
};
	["StaticsFields_updatedAt_Create"]: {
	__typename: "StaticsFields_updatedAt_Create",
	permission: boolean
};
	["StaticsFields_updatedAt_Read"]: {
	__typename: "StaticsFields_updatedAt_Read",
	permission: boolean
};
	["StaticsFields_updatedAt_Update"]: {
	__typename: "StaticsFields_updatedAt_Update",
	permission: boolean
};
	["StaticsFields_updatedAt_Delete"]: {
	__typename: "StaticsFields_updatedAt_Delete",
	permission: boolean
};
	["StaticsFields_createdAt"]: {
	__typename: "StaticsFields_createdAt",
	create?: GraphQLTypes["StaticsFields_createdAt_Create"] | undefined,
	read?: GraphQLTypes["StaticsFields_createdAt_Read"] | undefined,
	update?: GraphQLTypes["StaticsFields_createdAt_Update"] | undefined,
	delete?: GraphQLTypes["StaticsFields_createdAt_Delete"] | undefined
};
	["StaticsFields_createdAt_Create"]: {
	__typename: "StaticsFields_createdAt_Create",
	permission: boolean
};
	["StaticsFields_createdAt_Read"]: {
	__typename: "StaticsFields_createdAt_Read",
	permission: boolean
};
	["StaticsFields_createdAt_Update"]: {
	__typename: "StaticsFields_createdAt_Update",
	permission: boolean
};
	["StaticsFields_createdAt_Delete"]: {
	__typename: "StaticsFields_createdAt_Delete",
	permission: boolean
};
	["StaticsCreateAccess"]: {
	__typename: "StaticsCreateAccess",
	permission: boolean,
	where?: GraphQLTypes["JSONObject"] | undefined
};
	["StaticsReadAccess"]: {
	__typename: "StaticsReadAccess",
	permission: boolean,
	where?: GraphQLTypes["JSONObject"] | undefined
};
	["StaticsUpdateAccess"]: {
	__typename: "StaticsUpdateAccess",
	permission: boolean,
	where?: GraphQLTypes["JSONObject"] | undefined
};
	["StaticsDeleteAccess"]: {
	__typename: "StaticsDeleteAccess",
	permission: boolean,
	where?: GraphQLTypes["JSONObject"] | undefined
};
	["event_viewsAccess"]: {
	__typename: "event_viewsAccess",
	fields?: GraphQLTypes["EventViewsFields"] | undefined,
	create?: GraphQLTypes["EventViewsCreateAccess"] | undefined,
	read?: GraphQLTypes["EventViewsReadAccess"] | undefined,
	update?: GraphQLTypes["EventViewsUpdateAccess"] | undefined,
	delete?: GraphQLTypes["EventViewsDeleteAccess"] | undefined
};
	["EventViewsFields"]: {
	__typename: "EventViewsFields",
	date?: GraphQLTypes["EventViewsFields_date"] | undefined,
	eventId?: GraphQLTypes["EventViewsFields_eventId"] | undefined,
	viewCount?: GraphQLTypes["EventViewsFields_viewCount"] | undefined,
	updatedAt?: GraphQLTypes["EventViewsFields_updatedAt"] | undefined,
	createdAt?: GraphQLTypes["EventViewsFields_createdAt"] | undefined
};
	["EventViewsFields_date"]: {
	__typename: "EventViewsFields_date",
	create?: GraphQLTypes["EventViewsFields_date_Create"] | undefined,
	read?: GraphQLTypes["EventViewsFields_date_Read"] | undefined,
	update?: GraphQLTypes["EventViewsFields_date_Update"] | undefined,
	delete?: GraphQLTypes["EventViewsFields_date_Delete"] | undefined
};
	["EventViewsFields_date_Create"]: {
	__typename: "EventViewsFields_date_Create",
	permission: boolean
};
	["EventViewsFields_date_Read"]: {
	__typename: "EventViewsFields_date_Read",
	permission: boolean
};
	["EventViewsFields_date_Update"]: {
	__typename: "EventViewsFields_date_Update",
	permission: boolean
};
	["EventViewsFields_date_Delete"]: {
	__typename: "EventViewsFields_date_Delete",
	permission: boolean
};
	["EventViewsFields_eventId"]: {
	__typename: "EventViewsFields_eventId",
	create?: GraphQLTypes["EventViewsFields_eventId_Create"] | undefined,
	read?: GraphQLTypes["EventViewsFields_eventId_Read"] | undefined,
	update?: GraphQLTypes["EventViewsFields_eventId_Update"] | undefined,
	delete?: GraphQLTypes["EventViewsFields_eventId_Delete"] | undefined
};
	["EventViewsFields_eventId_Create"]: {
	__typename: "EventViewsFields_eventId_Create",
	permission: boolean
};
	["EventViewsFields_eventId_Read"]: {
	__typename: "EventViewsFields_eventId_Read",
	permission: boolean
};
	["EventViewsFields_eventId_Update"]: {
	__typename: "EventViewsFields_eventId_Update",
	permission: boolean
};
	["EventViewsFields_eventId_Delete"]: {
	__typename: "EventViewsFields_eventId_Delete",
	permission: boolean
};
	["EventViewsFields_viewCount"]: {
	__typename: "EventViewsFields_viewCount",
	create?: GraphQLTypes["EventViewsFields_viewCount_Create"] | undefined,
	read?: GraphQLTypes["EventViewsFields_viewCount_Read"] | undefined,
	update?: GraphQLTypes["EventViewsFields_viewCount_Update"] | undefined,
	delete?: GraphQLTypes["EventViewsFields_viewCount_Delete"] | undefined
};
	["EventViewsFields_viewCount_Create"]: {
	__typename: "EventViewsFields_viewCount_Create",
	permission: boolean
};
	["EventViewsFields_viewCount_Read"]: {
	__typename: "EventViewsFields_viewCount_Read",
	permission: boolean
};
	["EventViewsFields_viewCount_Update"]: {
	__typename: "EventViewsFields_viewCount_Update",
	permission: boolean
};
	["EventViewsFields_viewCount_Delete"]: {
	__typename: "EventViewsFields_viewCount_Delete",
	permission: boolean
};
	["EventViewsFields_updatedAt"]: {
	__typename: "EventViewsFields_updatedAt",
	create?: GraphQLTypes["EventViewsFields_updatedAt_Create"] | undefined,
	read?: GraphQLTypes["EventViewsFields_updatedAt_Read"] | undefined,
	update?: GraphQLTypes["EventViewsFields_updatedAt_Update"] | undefined,
	delete?: GraphQLTypes["EventViewsFields_updatedAt_Delete"] | undefined
};
	["EventViewsFields_updatedAt_Create"]: {
	__typename: "EventViewsFields_updatedAt_Create",
	permission: boolean
};
	["EventViewsFields_updatedAt_Read"]: {
	__typename: "EventViewsFields_updatedAt_Read",
	permission: boolean
};
	["EventViewsFields_updatedAt_Update"]: {
	__typename: "EventViewsFields_updatedAt_Update",
	permission: boolean
};
	["EventViewsFields_updatedAt_Delete"]: {
	__typename: "EventViewsFields_updatedAt_Delete",
	permission: boolean
};
	["EventViewsFields_createdAt"]: {
	__typename: "EventViewsFields_createdAt",
	create?: GraphQLTypes["EventViewsFields_createdAt_Create"] | undefined,
	read?: GraphQLTypes["EventViewsFields_createdAt_Read"] | undefined,
	update?: GraphQLTypes["EventViewsFields_createdAt_Update"] | undefined,
	delete?: GraphQLTypes["EventViewsFields_createdAt_Delete"] | undefined
};
	["EventViewsFields_createdAt_Create"]: {
	__typename: "EventViewsFields_createdAt_Create",
	permission: boolean
};
	["EventViewsFields_createdAt_Read"]: {
	__typename: "EventViewsFields_createdAt_Read",
	permission: boolean
};
	["EventViewsFields_createdAt_Update"]: {
	__typename: "EventViewsFields_createdAt_Update",
	permission: boolean
};
	["EventViewsFields_createdAt_Delete"]: {
	__typename: "EventViewsFields_createdAt_Delete",
	permission: boolean
};
	["EventViewsCreateAccess"]: {
	__typename: "EventViewsCreateAccess",
	permission: boolean,
	where?: GraphQLTypes["JSONObject"] | undefined
};
	["EventViewsReadAccess"]: {
	__typename: "EventViewsReadAccess",
	permission: boolean,
	where?: GraphQLTypes["JSONObject"] | undefined
};
	["EventViewsUpdateAccess"]: {
	__typename: "EventViewsUpdateAccess",
	permission: boolean,
	where?: GraphQLTypes["JSONObject"] | undefined
};
	["EventViewsDeleteAccess"]: {
	__typename: "EventViewsDeleteAccess",
	permission: boolean,
	where?: GraphQLTypes["JSONObject"] | undefined
};
	["post_viewsAccess"]: {
	__typename: "post_viewsAccess",
	fields?: GraphQLTypes["PostViewsFields"] | undefined,
	create?: GraphQLTypes["PostViewsCreateAccess"] | undefined,
	read?: GraphQLTypes["PostViewsReadAccess"] | undefined,
	update?: GraphQLTypes["PostViewsUpdateAccess"] | undefined,
	delete?: GraphQLTypes["PostViewsDeleteAccess"] | undefined
};
	["PostViewsFields"]: {
	__typename: "PostViewsFields",
	date?: GraphQLTypes["PostViewsFields_date"] | undefined,
	postId?: GraphQLTypes["PostViewsFields_postId"] | undefined,
	viewCount?: GraphQLTypes["PostViewsFields_viewCount"] | undefined,
	updatedAt?: GraphQLTypes["PostViewsFields_updatedAt"] | undefined,
	createdAt?: GraphQLTypes["PostViewsFields_createdAt"] | undefined
};
	["PostViewsFields_date"]: {
	__typename: "PostViewsFields_date",
	create?: GraphQLTypes["PostViewsFields_date_Create"] | undefined,
	read?: GraphQLTypes["PostViewsFields_date_Read"] | undefined,
	update?: GraphQLTypes["PostViewsFields_date_Update"] | undefined,
	delete?: GraphQLTypes["PostViewsFields_date_Delete"] | undefined
};
	["PostViewsFields_date_Create"]: {
	__typename: "PostViewsFields_date_Create",
	permission: boolean
};
	["PostViewsFields_date_Read"]: {
	__typename: "PostViewsFields_date_Read",
	permission: boolean
};
	["PostViewsFields_date_Update"]: {
	__typename: "PostViewsFields_date_Update",
	permission: boolean
};
	["PostViewsFields_date_Delete"]: {
	__typename: "PostViewsFields_date_Delete",
	permission: boolean
};
	["PostViewsFields_postId"]: {
	__typename: "PostViewsFields_postId",
	create?: GraphQLTypes["PostViewsFields_postId_Create"] | undefined,
	read?: GraphQLTypes["PostViewsFields_postId_Read"] | undefined,
	update?: GraphQLTypes["PostViewsFields_postId_Update"] | undefined,
	delete?: GraphQLTypes["PostViewsFields_postId_Delete"] | undefined
};
	["PostViewsFields_postId_Create"]: {
	__typename: "PostViewsFields_postId_Create",
	permission: boolean
};
	["PostViewsFields_postId_Read"]: {
	__typename: "PostViewsFields_postId_Read",
	permission: boolean
};
	["PostViewsFields_postId_Update"]: {
	__typename: "PostViewsFields_postId_Update",
	permission: boolean
};
	["PostViewsFields_postId_Delete"]: {
	__typename: "PostViewsFields_postId_Delete",
	permission: boolean
};
	["PostViewsFields_viewCount"]: {
	__typename: "PostViewsFields_viewCount",
	create?: GraphQLTypes["PostViewsFields_viewCount_Create"] | undefined,
	read?: GraphQLTypes["PostViewsFields_viewCount_Read"] | undefined,
	update?: GraphQLTypes["PostViewsFields_viewCount_Update"] | undefined,
	delete?: GraphQLTypes["PostViewsFields_viewCount_Delete"] | undefined
};
	["PostViewsFields_viewCount_Create"]: {
	__typename: "PostViewsFields_viewCount_Create",
	permission: boolean
};
	["PostViewsFields_viewCount_Read"]: {
	__typename: "PostViewsFields_viewCount_Read",
	permission: boolean
};
	["PostViewsFields_viewCount_Update"]: {
	__typename: "PostViewsFields_viewCount_Update",
	permission: boolean
};
	["PostViewsFields_viewCount_Delete"]: {
	__typename: "PostViewsFields_viewCount_Delete",
	permission: boolean
};
	["PostViewsFields_updatedAt"]: {
	__typename: "PostViewsFields_updatedAt",
	create?: GraphQLTypes["PostViewsFields_updatedAt_Create"] | undefined,
	read?: GraphQLTypes["PostViewsFields_updatedAt_Read"] | undefined,
	update?: GraphQLTypes["PostViewsFields_updatedAt_Update"] | undefined,
	delete?: GraphQLTypes["PostViewsFields_updatedAt_Delete"] | undefined
};
	["PostViewsFields_updatedAt_Create"]: {
	__typename: "PostViewsFields_updatedAt_Create",
	permission: boolean
};
	["PostViewsFields_updatedAt_Read"]: {
	__typename: "PostViewsFields_updatedAt_Read",
	permission: boolean
};
	["PostViewsFields_updatedAt_Update"]: {
	__typename: "PostViewsFields_updatedAt_Update",
	permission: boolean
};
	["PostViewsFields_updatedAt_Delete"]: {
	__typename: "PostViewsFields_updatedAt_Delete",
	permission: boolean
};
	["PostViewsFields_createdAt"]: {
	__typename: "PostViewsFields_createdAt",
	create?: GraphQLTypes["PostViewsFields_createdAt_Create"] | undefined,
	read?: GraphQLTypes["PostViewsFields_createdAt_Read"] | undefined,
	update?: GraphQLTypes["PostViewsFields_createdAt_Update"] | undefined,
	delete?: GraphQLTypes["PostViewsFields_createdAt_Delete"] | undefined
};
	["PostViewsFields_createdAt_Create"]: {
	__typename: "PostViewsFields_createdAt_Create",
	permission: boolean
};
	["PostViewsFields_createdAt_Read"]: {
	__typename: "PostViewsFields_createdAt_Read",
	permission: boolean
};
	["PostViewsFields_createdAt_Update"]: {
	__typename: "PostViewsFields_createdAt_Update",
	permission: boolean
};
	["PostViewsFields_createdAt_Delete"]: {
	__typename: "PostViewsFields_createdAt_Delete",
	permission: boolean
};
	["PostViewsCreateAccess"]: {
	__typename: "PostViewsCreateAccess",
	permission: boolean,
	where?: GraphQLTypes["JSONObject"] | undefined
};
	["PostViewsReadAccess"]: {
	__typename: "PostViewsReadAccess",
	permission: boolean,
	where?: GraphQLTypes["JSONObject"] | undefined
};
	["PostViewsUpdateAccess"]: {
	__typename: "PostViewsUpdateAccess",
	permission: boolean,
	where?: GraphQLTypes["JSONObject"] | undefined
};
	["PostViewsDeleteAccess"]: {
	__typename: "PostViewsDeleteAccess",
	permission: boolean,
	where?: GraphQLTypes["JSONObject"] | undefined
};
	["payload_preferencesAccess"]: {
	__typename: "payload_preferencesAccess",
	fields?: GraphQLTypes["PayloadPreferencesFields"] | undefined,
	create?: GraphQLTypes["PayloadPreferencesCreateAccess"] | undefined,
	read?: GraphQLTypes["PayloadPreferencesReadAccess"] | undefined,
	update?: GraphQLTypes["PayloadPreferencesUpdateAccess"] | undefined,
	delete?: GraphQLTypes["PayloadPreferencesDeleteAccess"] | undefined
};
	["PayloadPreferencesFields"]: {
	__typename: "PayloadPreferencesFields",
	user?: GraphQLTypes["PayloadPreferencesFields_user"] | undefined,
	key?: GraphQLTypes["PayloadPreferencesFields_key"] | undefined,
	value?: GraphQLTypes["PayloadPreferencesFields_value"] | undefined,
	updatedAt?: GraphQLTypes["PayloadPreferencesFields_updatedAt"] | undefined,
	createdAt?: GraphQLTypes["PayloadPreferencesFields_createdAt"] | undefined
};
	["PayloadPreferencesFields_user"]: {
	__typename: "PayloadPreferencesFields_user",
	create?: GraphQLTypes["PayloadPreferencesFields_user_Create"] | undefined,
	read?: GraphQLTypes["PayloadPreferencesFields_user_Read"] | undefined,
	update?: GraphQLTypes["PayloadPreferencesFields_user_Update"] | undefined,
	delete?: GraphQLTypes["PayloadPreferencesFields_user_Delete"] | undefined
};
	["PayloadPreferencesFields_user_Create"]: {
	__typename: "PayloadPreferencesFields_user_Create",
	permission: boolean
};
	["PayloadPreferencesFields_user_Read"]: {
	__typename: "PayloadPreferencesFields_user_Read",
	permission: boolean
};
	["PayloadPreferencesFields_user_Update"]: {
	__typename: "PayloadPreferencesFields_user_Update",
	permission: boolean
};
	["PayloadPreferencesFields_user_Delete"]: {
	__typename: "PayloadPreferencesFields_user_Delete",
	permission: boolean
};
	["PayloadPreferencesFields_key"]: {
	__typename: "PayloadPreferencesFields_key",
	create?: GraphQLTypes["PayloadPreferencesFields_key_Create"] | undefined,
	read?: GraphQLTypes["PayloadPreferencesFields_key_Read"] | undefined,
	update?: GraphQLTypes["PayloadPreferencesFields_key_Update"] | undefined,
	delete?: GraphQLTypes["PayloadPreferencesFields_key_Delete"] | undefined
};
	["PayloadPreferencesFields_key_Create"]: {
	__typename: "PayloadPreferencesFields_key_Create",
	permission: boolean
};
	["PayloadPreferencesFields_key_Read"]: {
	__typename: "PayloadPreferencesFields_key_Read",
	permission: boolean
};
	["PayloadPreferencesFields_key_Update"]: {
	__typename: "PayloadPreferencesFields_key_Update",
	permission: boolean
};
	["PayloadPreferencesFields_key_Delete"]: {
	__typename: "PayloadPreferencesFields_key_Delete",
	permission: boolean
};
	["PayloadPreferencesFields_value"]: {
	__typename: "PayloadPreferencesFields_value",
	create?: GraphQLTypes["PayloadPreferencesFields_value_Create"] | undefined,
	read?: GraphQLTypes["PayloadPreferencesFields_value_Read"] | undefined,
	update?: GraphQLTypes["PayloadPreferencesFields_value_Update"] | undefined,
	delete?: GraphQLTypes["PayloadPreferencesFields_value_Delete"] | undefined
};
	["PayloadPreferencesFields_value_Create"]: {
	__typename: "PayloadPreferencesFields_value_Create",
	permission: boolean
};
	["PayloadPreferencesFields_value_Read"]: {
	__typename: "PayloadPreferencesFields_value_Read",
	permission: boolean
};
	["PayloadPreferencesFields_value_Update"]: {
	__typename: "PayloadPreferencesFields_value_Update",
	permission: boolean
};
	["PayloadPreferencesFields_value_Delete"]: {
	__typename: "PayloadPreferencesFields_value_Delete",
	permission: boolean
};
	["PayloadPreferencesFields_updatedAt"]: {
	__typename: "PayloadPreferencesFields_updatedAt",
	create?: GraphQLTypes["PayloadPreferencesFields_updatedAt_Create"] | undefined,
	read?: GraphQLTypes["PayloadPreferencesFields_updatedAt_Read"] | undefined,
	update?: GraphQLTypes["PayloadPreferencesFields_updatedAt_Update"] | undefined,
	delete?: GraphQLTypes["PayloadPreferencesFields_updatedAt_Delete"] | undefined
};
	["PayloadPreferencesFields_updatedAt_Create"]: {
	__typename: "PayloadPreferencesFields_updatedAt_Create",
	permission: boolean
};
	["PayloadPreferencesFields_updatedAt_Read"]: {
	__typename: "PayloadPreferencesFields_updatedAt_Read",
	permission: boolean
};
	["PayloadPreferencesFields_updatedAt_Update"]: {
	__typename: "PayloadPreferencesFields_updatedAt_Update",
	permission: boolean
};
	["PayloadPreferencesFields_updatedAt_Delete"]: {
	__typename: "PayloadPreferencesFields_updatedAt_Delete",
	permission: boolean
};
	["PayloadPreferencesFields_createdAt"]: {
	__typename: "PayloadPreferencesFields_createdAt",
	create?: GraphQLTypes["PayloadPreferencesFields_createdAt_Create"] | undefined,
	read?: GraphQLTypes["PayloadPreferencesFields_createdAt_Read"] | undefined,
	update?: GraphQLTypes["PayloadPreferencesFields_createdAt_Update"] | undefined,
	delete?: GraphQLTypes["PayloadPreferencesFields_createdAt_Delete"] | undefined
};
	["PayloadPreferencesFields_createdAt_Create"]: {
	__typename: "PayloadPreferencesFields_createdAt_Create",
	permission: boolean
};
	["PayloadPreferencesFields_createdAt_Read"]: {
	__typename: "PayloadPreferencesFields_createdAt_Read",
	permission: boolean
};
	["PayloadPreferencesFields_createdAt_Update"]: {
	__typename: "PayloadPreferencesFields_createdAt_Update",
	permission: boolean
};
	["PayloadPreferencesFields_createdAt_Delete"]: {
	__typename: "PayloadPreferencesFields_createdAt_Delete",
	permission: boolean
};
	["PayloadPreferencesCreateAccess"]: {
	__typename: "PayloadPreferencesCreateAccess",
	permission: boolean,
	where?: GraphQLTypes["JSONObject"] | undefined
};
	["PayloadPreferencesReadAccess"]: {
	__typename: "PayloadPreferencesReadAccess",
	permission: boolean,
	where?: GraphQLTypes["JSONObject"] | undefined
};
	["PayloadPreferencesUpdateAccess"]: {
	__typename: "PayloadPreferencesUpdateAccess",
	permission: boolean,
	where?: GraphQLTypes["JSONObject"] | undefined
};
	["PayloadPreferencesDeleteAccess"]: {
	__typename: "PayloadPreferencesDeleteAccess",
	permission: boolean,
	where?: GraphQLTypes["JSONObject"] | undefined
};
	["GetEventMaxUsedTag"]: {
	__typename: "GetEventMaxUsedTag",
	tag?: GraphQLTypes["maxUsedTag"] | undefined
};
	["maxUsedTag"]: {
	__typename: "maxUsedTag",
	id?: string | undefined,
	name?: string | undefined
};
	["ChatbotStocks"]: {
	__typename: "ChatbotStocks",
	product?: Array<GraphQLTypes["Product"] | undefined> | undefined,
	ticketing?: Array<GraphQLTypes["Ticketing"] | undefined> | undefined
};
	["ChatbotEvents"]: {
	__typename: "ChatbotEvents",
	events?: Array<GraphQLTypes["Event"] | undefined> | undefined
};
	["WebAppCheckInCountGetResponse"]: {
	__typename: "WebAppCheckInCountGetResponse",
	checkInCount?: number | undefined,
	totalQrCode?: number | undefined
};
	["ProductWhereInput"]: {
		id?: GraphQLTypes["ProductDetailOperator"] | undefined
};
	["ProductDetailOperator"]: {
		equals?: string | undefined
};
	["ProductList"]: {
	__typename: "ProductList",
	products?: Array<GraphQLTypes["Product"] | undefined> | undefined
};
	["TicketingWhereInput"]: {
		id?: GraphQLTypes["TicketingDetailOperator"] | undefined
};
	["TicketingDetailOperator"]: {
		equals?: string | undefined
};
	["TicketingList"]: {
	__typename: "TicketingList",
	ticketings?: Array<GraphQLTypes["Ticketing"] | undefined> | undefined
};
	["RedemptionCodeList"]: {
	__typename: "RedemptionCodeList",
	docs?: Array<GraphQLTypes["RedemptionCode"] | undefined> | undefined
};
	["RedemptionCodeWhereInput"]: {
		qrcodeId?: GraphQLTypes["RedemptionCodeDetailOperator"] | undefined
};
	["RedemptionCodeDetailOperator"]: {
		equals?: string | undefined
};
	["BookmarkList"]: {
	__typename: "BookmarkList",
	docs?: Array<GraphQLTypes["WebAppBookmark"] | undefined> | undefined
};
	["Mutation"]: {
	__typename: "Mutation",
	createEvent?: GraphQLTypes["Event"] | undefined,
	updateEvent?: GraphQLTypes["Event"] | undefined,
	deleteEvent?: GraphQLTypes["Event"] | undefined,
	createLocation?: GraphQLTypes["Location"] | undefined,
	updateLocation?: GraphQLTypes["Location"] | undefined,
	deleteLocation?: GraphQLTypes["Location"] | undefined,
	createPost?: GraphQLTypes["Post"] | undefined,
	updatePost?: GraphQLTypes["Post"] | undefined,
	deletePost?: GraphQLTypes["Post"] | undefined,
	createTicketing?: GraphQLTypes["Ticketing"] | undefined,
	updateTicketing?: GraphQLTypes["Ticketing"] | undefined,
	deleteTicketing?: GraphQLTypes["Ticketing"] | undefined,
	createProduct?: GraphQLTypes["Product"] | undefined,
	updateProduct?: GraphQLTypes["Product"] | undefined,
	deleteProduct?: GraphQLTypes["Product"] | undefined,
	createInventoryStatus?: GraphQLTypes["InventoryStatus"] | undefined,
	updateInventoryStatus?: GraphQLTypes["InventoryStatus"] | undefined,
	deleteInventoryStatus?: GraphQLTypes["InventoryStatus"] | undefined,
	createTransaction?: GraphQLTypes["Transaction"] | undefined,
	updateTransaction?: GraphQLTypes["Transaction"] | undefined,
	deleteTransaction?: GraphQLTypes["Transaction"] | undefined,
	createRedemptionCode?: GraphQLTypes["RedemptionCode"] | undefined,
	updateRedemptionCode?: GraphQLTypes["RedemptionCode"] | undefined,
	deleteRedemptionCode?: GraphQLTypes["RedemptionCode"] | undefined,
	createRedemptionPdf?: GraphQLTypes["RedemptionPdf"] | undefined,
	updateRedemptionPdf?: GraphQLTypes["RedemptionPdf"] | undefined,
	deleteRedemptionPdf?: GraphQLTypes["RedemptionPdf"] | undefined,
	createCheckInRedemption?: GraphQLTypes["CheckInRedemption"] | undefined,
	updateCheckInRedemption?: GraphQLTypes["CheckInRedemption"] | undefined,
	deleteCheckInRedemption?: GraphQLTypes["CheckInRedemption"] | undefined,
	createUser?: GraphQLTypes["User"] | undefined,
	updateUser?: GraphQLTypes["User"] | undefined,
	deleteUser?: GraphQLTypes["User"] | undefined,
	refreshTokenUser?: GraphQLTypes["usersRefreshedUser"] | undefined,
	logoutUser?: string | undefined,
	unlockUser: boolean,
	loginUser?: GraphQLTypes["usersLoginResult"] | undefined,
	forgotPasswordUser: boolean,
	resetPasswordUser?: GraphQLTypes["usersResetPassword"] | undefined,
	verifyEmailUser?: boolean | undefined,
	createBookmark?: GraphQLTypes["Bookmark"] | undefined,
	updateBookmark?: GraphQLTypes["Bookmark"] | undefined,
	deleteBookmark?: GraphQLTypes["Bookmark"] | undefined,
	createWebAppBookmark?: GraphQLTypes["WebAppBookmark"] | undefined,
	updateWebAppBookmark?: GraphQLTypes["WebAppBookmark"] | undefined,
	deleteWebAppBookmark?: GraphQLTypes["WebAppBookmark"] | undefined,
	createViewRecord?: GraphQLTypes["ViewRecord"] | undefined,
	updateViewRecord?: GraphQLTypes["ViewRecord"] | undefined,
	deleteViewRecord?: GraphQLTypes["ViewRecord"] | undefined,
	createComment?: GraphQLTypes["Comment"] | undefined,
	updateComment?: GraphQLTypes["Comment"] | undefined,
	deleteComment?: GraphQLTypes["Comment"] | undefined,
	createSubscription?: GraphQLTypes["Subscription"] | undefined,
	updateSubscription?: GraphQLTypes["Subscription"] | undefined,
	deleteSubscription?: GraphQLTypes["Subscription"] | undefined,
	createNewsletter?: GraphQLTypes["Newsletter"] | undefined,
	updateNewsletter?: GraphQLTypes["Newsletter"] | undefined,
	deleteNewsletter?: GraphQLTypes["Newsletter"] | undefined,
	createBlogCategory?: GraphQLTypes["BlogCategory"] | undefined,
	updateBlogCategory?: GraphQLTypes["BlogCategory"] | undefined,
	deleteBlogCategory?: GraphQLTypes["BlogCategory"] | undefined,
	createTag?: GraphQLTypes["Tag"] | undefined,
	updateTag?: GraphQLTypes["Tag"] | undefined,
	deleteTag?: GraphQLTypes["Tag"] | undefined,
	createSpecialTag?: GraphQLTypes["SpecialTag"] | undefined,
	updateSpecialTag?: GraphQLTypes["SpecialTag"] | undefined,
	deleteSpecialTag?: GraphQLTypes["SpecialTag"] | undefined,
	createDate?: GraphQLTypes["Date"] | undefined,
	updateDate?: GraphQLTypes["Date"] | undefined,
	deleteDate?: GraphQLTypes["Date"] | undefined,
	createDistrict?: GraphQLTypes["District"] | undefined,
	updateDistrict?: GraphQLTypes["District"] | undefined,
	deleteDistrict?: GraphQLTypes["District"] | undefined,
	createAudience?: GraphQLTypes["Audience"] | undefined,
	updateAudience?: GraphQLTypes["Audience"] | undefined,
	deleteAudience?: GraphQLTypes["Audience"] | undefined,
	createEventCategory?: GraphQLTypes["EventCategory"] | undefined,
	updateEventCategory?: GraphQLTypes["EventCategory"] | undefined,
	deleteEventCategory?: GraphQLTypes["EventCategory"] | undefined,
	createPrivateMedia?: GraphQLTypes["PrivateMedia"] | undefined,
	updatePrivateMedia?: GraphQLTypes["PrivateMedia"] | undefined,
	deletePrivateMedia?: GraphQLTypes["PrivateMedia"] | undefined,
	createMedia?: GraphQLTypes["Media"] | undefined,
	updateMedia?: GraphQLTypes["Media"] | undefined,
	deleteMedia?: GraphQLTypes["Media"] | undefined,
	createBackupMedia?: GraphQLTypes["BackupMedia"] | undefined,
	updateBackupMedia?: GraphQLTypes["BackupMedia"] | undefined,
	deleteBackupMedia?: GraphQLTypes["BackupMedia"] | undefined,
	createAccountSuspendRequest?: GraphQLTypes["AccountSuspendRequest"] | undefined,
	updateAccountSuspendRequest?: GraphQLTypes["AccountSuspendRequest"] | undefined,
	deleteAccountSuspendRequest?: GraphQLTypes["AccountSuspendRequest"] | undefined,
	createDevice?: GraphQLTypes["Device"] | undefined,
	updateDevice?: GraphQLTypes["Device"] | undefined,
	deleteDevice?: GraphQLTypes["Device"] | undefined,
	createNotification?: GraphQLTypes["Notification"] | undefined,
	updateNotification?: GraphQLTypes["Notification"] | undefined,
	deleteNotification?: GraphQLTypes["Notification"] | undefined,
	createRedirectSetting?: GraphQLTypes["RedirectSetting"] | undefined,
	updateRedirectSetting?: GraphQLTypes["RedirectSetting"] | undefined,
	deleteRedirectSetting?: GraphQLTypes["RedirectSetting"] | undefined,
	createEventRanking?: GraphQLTypes["EventRanking"] | undefined,
	updateEventRanking?: GraphQLTypes["EventRanking"] | undefined,
	deleteEventRanking?: GraphQLTypes["EventRanking"] | undefined,
	createLocationRanking?: GraphQLTypes["LocationRanking"] | undefined,
	updateLocationRanking?: GraphQLTypes["LocationRanking"] | undefined,
	deleteLocationRanking?: GraphQLTypes["LocationRanking"] | undefined,
	createEventCalendar?: GraphQLTypes["EventCalendar"] | undefined,
	updateEventCalendar?: GraphQLTypes["EventCalendar"] | undefined,
	deleteEventCalendar?: GraphQLTypes["EventCalendar"] | undefined,
	createEditorChoice?: GraphQLTypes["EditorChoice"] | undefined,
	updateEditorChoice?: GraphQLTypes["EditorChoice"] | undefined,
	deleteEditorChoice?: GraphQLTypes["EditorChoice"] | undefined,
	createCriteriaSetting?: GraphQLTypes["CriteriaSetting"] | undefined,
	updateCriteriaSetting?: GraphQLTypes["CriteriaSetting"] | undefined,
	deleteCriteriaSetting?: GraphQLTypes["CriteriaSetting"] | undefined,
	createOrganiserSetting?: GraphQLTypes["OrganiserSetting"] | undefined,
	updateOrganiserSetting?: GraphQLTypes["OrganiserSetting"] | undefined,
	deleteOrganiserSetting?: GraphQLTypes["OrganiserSetting"] | undefined,
	createPostSetting?: GraphQLTypes["PostSetting"] | undefined,
	updatePostSetting?: GraphQLTypes["PostSetting"] | undefined,
	deletePostSetting?: GraphQLTypes["PostSetting"] | undefined,
	createHomepageSetting?: GraphQLTypes["HomepageSetting"] | undefined,
	updateHomepageSetting?: GraphQLTypes["HomepageSetting"] | undefined,
	deleteHomepageSetting?: GraphQLTypes["HomepageSetting"] | undefined,
	createBannerSection?: GraphQLTypes["BannerSection"] | undefined,
	updateBannerSection?: GraphQLTypes["BannerSection"] | undefined,
	deleteBannerSection?: GraphQLTypes["BannerSection"] | undefined,
	createMacaoBannerSection?: GraphQLTypes["MacaoBannerSection"] | undefined,
	updateMacaoBannerSection?: GraphQLTypes["MacaoBannerSection"] | undefined,
	deleteMacaoBannerSection?: GraphQLTypes["MacaoBannerSection"] | undefined,
	createBanner?: GraphQLTypes["Banner"] | undefined,
	updateBanner?: GraphQLTypes["Banner"] | undefined,
	deleteBanner?: GraphQLTypes["Banner"] | undefined,
	createCampaign?: GraphQLTypes["Campaign"] | undefined,
	updateCampaign?: GraphQLTypes["Campaign"] | undefined,
	deleteCampaign?: GraphQLTypes["Campaign"] | undefined,
	createAdvertise?: GraphQLTypes["Advertise"] | undefined,
	updateAdvertise?: GraphQLTypes["Advertise"] | undefined,
	deleteAdvertise?: GraphQLTypes["Advertise"] | undefined,
	createStatic?: GraphQLTypes["Static"] | undefined,
	updateStatic?: GraphQLTypes["Static"] | undefined,
	deleteStatic?: GraphQLTypes["Static"] | undefined,
	createEventView?: GraphQLTypes["EventView"] | undefined,
	updateEventView?: GraphQLTypes["EventView"] | undefined,
	deleteEventView?: GraphQLTypes["EventView"] | undefined,
	createPostView?: GraphQLTypes["PostView"] | undefined,
	updatePostView?: GraphQLTypes["PostView"] | undefined,
	deletePostView?: GraphQLTypes["PostView"] | undefined,
	createPayloadPreference?: GraphQLTypes["PayloadPreference"] | undefined,
	updatePayloadPreference?: GraphQLTypes["PayloadPreference"] | undefined,
	deletePayloadPreference?: GraphQLTypes["PayloadPreference"] | undefined,
	AddEventView?: boolean | undefined,
	ItemBookmark?: boolean | undefined,
	ItemUnbookmark?: boolean | undefined,
	readItem?: boolean | undefined,
	AddPostView?: boolean | undefined,
	LoginUser?: GraphQLTypes["usersLoginResult"] | undefined,
	RegisterUser?: GraphQLTypes["User"] | undefined,
	ActiveUser?: boolean | undefined,
	ReActivePasswordUser?: string | undefined,
	SSOLoginUser?: GraphQLTypes["usersLoginResult"] | undefined,
	SuspendUser?: boolean | undefined,
	UpdateUserPassword?: boolean | undefined,
	SubscribeOrganiser?: boolean | undefined,
	UnSubscribeOrganiser?: boolean | undefined,
	PostMessage?: boolean | undefined,
	AddBannerView?: boolean | undefined,
	AddDevice?: boolean | undefined,
	RemoveDevice?: boolean | undefined,
	UpdateNotifications?: boolean | undefined,
	DeleteNotification?: boolean | undefined,
	CreateNewsletter?: boolean | undefined,
	ChatbotInventoryHold?: GraphQLTypes["InventoryStatus"] | undefined,
	ChatbotInventoryRelease?: boolean | undefined,
	ChatbotInventoryPayment?: GraphQLTypes["ChatbotInventoryPayment"] | undefined,
	ChatbotTransactionCreate?: GraphQLTypes["Transaction"] | undefined,
	ChatbotRedemptionCodeUpdate?: boolean | undefined,
	ChatbotTransactionPdfGet?: GraphQLTypes["ChatbotTransactionPdfGet"] | undefined,
	WebAppQrCodeBatchScan?: GraphQLTypes["WebAppQrCodeBatchScanResult"] | undefined,
	WebAppQrCodeScan?: GraphQLTypes["WebAppQrCodeScanResponse"] | undefined,
	WebAppItemBookmark?: GraphQLTypes["WebAppBookmark"] | undefined,
	WebAppItemUnBookmark?: GraphQLTypes["WebAppBookmark"] | undefined
};
	["mutationEventInput"]: {
		region?: GraphQLTypes["Event_region_MutationInput"] | undefined,
	creator?: string | undefined,
	permalink?: string | undefined,
	status: string,
	level?: GraphQLTypes["Event_level_MutationInput"] | undefined,
	parent?: Array<string | undefined> | undefined,
	name?: string | undefined,
	chatbotEventTitle?: string | undefined,
	chatbotSequence?: number | undefined,
	thumbnail?: string | undefined,
	banner?: string | undefined,
	mobileBanner?: string | undefined,
	sections?: Array<GraphQLTypes["mutationEvent_SectionsInput"] | undefined> | undefined,
	ticketing?: Array<GraphQLTypes["mutationEvent_TicketingInput"] | undefined> | undefined,
	content?: GraphQLTypes["JSON"] | undefined,
	legacyContent?: string | undefined,
	showLegacyContent?: boolean | undefined,
	criteria: GraphQLTypes["mutationEvent_CriteriaInput"],
	walkIn?: boolean | undefined,
	contact?: GraphQLTypes["mutationEvent_ContactInput"] | undefined,
	views?: number | undefined,
	free?: boolean | undefined,
	fees?: Array<GraphQLTypes["mutationEvent_FeesInput"] | undefined> | undefined,
	homePageInternalClick?: number | undefined,
	homePageImpression?: number | undefined,
	eodClickRate?: number | undefined,
	eodImpressionRate?: number | undefined,
	top10ClickRateHomepage?: number | undefined,
	top10ImpressionkRateHomepage?: number | undefined,
	otherEventRankingClickRateHomepage?: number | undefined,
	otherEventRankingImpressionHomePage?: number | undefined,
	userPreferenceClickRate?: number | undefined,
	userPreferenceImpression?: number | undefined,
	eventSearchInternalClick?: number | undefined,
	eventListImpression?: number | undefined,
	latestEventInternalClick?: number | undefined,
	latestEventImpression?: number | undefined,
	topTenEventRankingInternalClick?: number | undefined,
	topTenImpression?: number | undefined,
	editorChoiceInternalClick?: number | undefined,
	editorChoiceImpression?: number | undefined,
	otherEventRankingInternalClick?: number | undefined,
	otherEventRankingImpression?: number | undefined,
	bannerInternalClick?: number | undefined,
	miscImpression?: number | undefined,
	miscInternalClick?: number | undefined,
	totalImpression?: number | undefined,
	internalClick?: number | undefined,
	outboundClick?: number | undefined,
	externalClick?: number | undefined,
	_viewsCountByMonth?: number | undefined,
	products?: Array<GraphQLTypes["mutationEvent_ProductsInput"] | undefined> | undefined,
	legacyGallery?: Array<string | undefined> | undefined,
	earliestStartDate?: string | undefined,
	latestEndDate?: string | undefined,
	latestStartDateTime?: string | undefined,
	hasTicketing?: boolean | undefined,
	hasProducts?: boolean | undefined,
	updatedAt?: string | undefined,
	createdAt?: string | undefined
};
	["Event_region_MutationInput"]: Event_region_MutationInput;
	["Event_level_MutationInput"]: Event_level_MutationInput;
	["mutationEvent_SectionsInput"]: {
		startDatetime: string,
	endDatetime?: string | undefined,
	fullDay?: boolean | undefined,
	repeat?: boolean | undefined,
	toThisDay?: string | undefined,
	recurrance: GraphQLTypes["mutationEvent_Sections_RecurranceInput"],
	sameLocation?: boolean | undefined,
	location?: string | undefined,
	district?: string | undefined,
	coordinate?: Array<number | undefined> | undefined,
	linkAddress?: boolean | undefined,
	address?: string | undefined,
	addressCoordinate?: Array<number | undefined> | undefined,
	id?: string | undefined
};
	["mutationEvent_Sections_RecurranceInput"]: {
		type: GraphQLTypes["Event_Sections_Recurrance_type_MutationInput"],
	weekday?: Array<GraphQLTypes["Event_Sections_Recurrance_weekday_MutationInput"] | undefined> | undefined
};
	["Event_Sections_Recurrance_type_MutationInput"]: Event_Sections_Recurrance_type_MutationInput;
	["Event_Sections_Recurrance_weekday_MutationInput"]: Event_Sections_Recurrance_weekday_MutationInput;
	["mutationEvent_TicketingInput"]: {
		startDatetime: string,
	endDatetime?: string | undefined,
	fullDay?: boolean | undefined,
	type: GraphQLTypes["Event_Ticketing_type_MutationInput"],
	ticketingType?: GraphQLTypes["Event_Ticketing_ticketingType_MutationInput"] | undefined,
	linkedTicketing?: string | undefined,
	promotionType?: GraphQLTypes["Event_Ticketing_promotionType_MutationInput"] | undefined,
	location?: string | undefined,
	website?: string | undefined,
	description?: string | undefined,
	id?: string | undefined
};
	["Event_Ticketing_type_MutationInput"]: Event_Ticketing_type_MutationInput;
	["Event_Ticketing_ticketingType_MutationInput"]: Event_Ticketing_ticketingType_MutationInput;
	["Event_Ticketing_promotionType_MutationInput"]: Event_Ticketing_promotionType_MutationInput;
	["mutationEvent_CriteriaInput"]: {
		categories?: Array<string | undefined> | undefined,
	audiences?: Array<string | undefined> | undefined,
	tags?: Array<string | undefined> | undefined,
	specialTags?: Array<string | undefined> | undefined
};
	["mutationEvent_ContactInput"]: {
		code?: number | undefined,
	phone?: string | undefined,
	page?: string | undefined,
	email?: string | undefined,
	privacy?: GraphQLTypes["JSON"] | undefined
};
	["mutationEvent_FeesInput"]: {
		fee?: number | undefined,
	remark?: string | undefined,
	id?: string | undefined
};
	["mutationEvent_ProductsInput"]: {
		startDateTime: string,
	endDateTime?: string | undefined,
	fullDay?: boolean | undefined,
	linkedProduct?: string | undefined,
	id?: string | undefined
};
	["mutationEventUpdateInput"]: {
		region?: GraphQLTypes["EventUpdate_region_MutationInput"] | undefined,
	creator?: string | undefined,
	permalink?: string | undefined,
	status?: string | undefined,
	level?: GraphQLTypes["EventUpdate_level_MutationInput"] | undefined,
	parent?: Array<string | undefined> | undefined,
	name?: string | undefined,
	chatbotEventTitle?: string | undefined,
	chatbotSequence?: number | undefined,
	thumbnail?: string | undefined,
	banner?: string | undefined,
	mobileBanner?: string | undefined,
	sections?: Array<GraphQLTypes["mutationEventUpdate_SectionsInput"] | undefined> | undefined,
	ticketing?: Array<GraphQLTypes["mutationEventUpdate_TicketingInput"] | undefined> | undefined,
	content?: GraphQLTypes["JSON"] | undefined,
	legacyContent?: string | undefined,
	showLegacyContent?: boolean | undefined,
	criteria: GraphQLTypes["mutationEventUpdate_CriteriaInput"],
	walkIn?: boolean | undefined,
	contact?: GraphQLTypes["mutationEventUpdate_ContactInput"] | undefined,
	views?: number | undefined,
	free?: boolean | undefined,
	fees?: Array<GraphQLTypes["mutationEventUpdate_FeesInput"] | undefined> | undefined,
	homePageInternalClick?: number | undefined,
	homePageImpression?: number | undefined,
	eodClickRate?: number | undefined,
	eodImpressionRate?: number | undefined,
	top10ClickRateHomepage?: number | undefined,
	top10ImpressionkRateHomepage?: number | undefined,
	otherEventRankingClickRateHomepage?: number | undefined,
	otherEventRankingImpressionHomePage?: number | undefined,
	userPreferenceClickRate?: number | undefined,
	userPreferenceImpression?: number | undefined,
	eventSearchInternalClick?: number | undefined,
	eventListImpression?: number | undefined,
	latestEventInternalClick?: number | undefined,
	latestEventImpression?: number | undefined,
	topTenEventRankingInternalClick?: number | undefined,
	topTenImpression?: number | undefined,
	editorChoiceInternalClick?: number | undefined,
	editorChoiceImpression?: number | undefined,
	otherEventRankingInternalClick?: number | undefined,
	otherEventRankingImpression?: number | undefined,
	bannerInternalClick?: number | undefined,
	miscImpression?: number | undefined,
	miscInternalClick?: number | undefined,
	totalImpression?: number | undefined,
	internalClick?: number | undefined,
	outboundClick?: number | undefined,
	externalClick?: number | undefined,
	_viewsCountByMonth?: number | undefined,
	products?: Array<GraphQLTypes["mutationEventUpdate_ProductsInput"] | undefined> | undefined,
	legacyGallery?: Array<string | undefined> | undefined,
	earliestStartDate?: string | undefined,
	latestEndDate?: string | undefined,
	latestStartDateTime?: string | undefined,
	hasTicketing?: boolean | undefined,
	hasProducts?: boolean | undefined,
	updatedAt?: string | undefined,
	createdAt?: string | undefined
};
	["EventUpdate_region_MutationInput"]: EventUpdate_region_MutationInput;
	["EventUpdate_level_MutationInput"]: EventUpdate_level_MutationInput;
	["mutationEventUpdate_SectionsInput"]: {
		startDatetime: string,
	endDatetime?: string | undefined,
	fullDay?: boolean | undefined,
	repeat?: boolean | undefined,
	toThisDay?: string | undefined,
	recurrance: GraphQLTypes["mutationEventUpdate_Sections_RecurranceInput"],
	sameLocation?: boolean | undefined,
	location?: string | undefined,
	district?: string | undefined,
	coordinate?: Array<number | undefined> | undefined,
	linkAddress?: boolean | undefined,
	address?: string | undefined,
	addressCoordinate?: Array<number | undefined> | undefined,
	id?: string | undefined
};
	["mutationEventUpdate_Sections_RecurranceInput"]: {
		type: GraphQLTypes["EventUpdate_Sections_Recurrance_type_MutationInput"],
	weekday?: Array<GraphQLTypes["EventUpdate_Sections_Recurrance_weekday_MutationInput"] | undefined> | undefined
};
	["EventUpdate_Sections_Recurrance_type_MutationInput"]: EventUpdate_Sections_Recurrance_type_MutationInput;
	["EventUpdate_Sections_Recurrance_weekday_MutationInput"]: EventUpdate_Sections_Recurrance_weekday_MutationInput;
	["mutationEventUpdate_TicketingInput"]: {
		startDatetime: string,
	endDatetime?: string | undefined,
	fullDay?: boolean | undefined,
	type: GraphQLTypes["EventUpdate_Ticketing_type_MutationInput"],
	ticketingType?: GraphQLTypes["EventUpdate_Ticketing_ticketingType_MutationInput"] | undefined,
	linkedTicketing?: string | undefined,
	promotionType?: GraphQLTypes["EventUpdate_Ticketing_promotionType_MutationInput"] | undefined,
	location?: string | undefined,
	website?: string | undefined,
	description?: string | undefined,
	id?: string | undefined
};
	["EventUpdate_Ticketing_type_MutationInput"]: EventUpdate_Ticketing_type_MutationInput;
	["EventUpdate_Ticketing_ticketingType_MutationInput"]: EventUpdate_Ticketing_ticketingType_MutationInput;
	["EventUpdate_Ticketing_promotionType_MutationInput"]: EventUpdate_Ticketing_promotionType_MutationInput;
	["mutationEventUpdate_CriteriaInput"]: {
		categories?: Array<string | undefined> | undefined,
	audiences?: Array<string | undefined> | undefined,
	tags?: Array<string | undefined> | undefined,
	specialTags?: Array<string | undefined> | undefined
};
	["mutationEventUpdate_ContactInput"]: {
		code?: number | undefined,
	phone?: string | undefined,
	page?: string | undefined,
	email?: string | undefined,
	privacy?: GraphQLTypes["JSON"] | undefined
};
	["mutationEventUpdate_FeesInput"]: {
		fee?: number | undefined,
	remark?: string | undefined,
	id?: string | undefined
};
	["mutationEventUpdate_ProductsInput"]: {
		startDateTime: string,
	endDateTime?: string | undefined,
	fullDay?: boolean | undefined,
	linkedProduct?: string | undefined,
	id?: string | undefined
};
	["mutationLocationInput"]: {
		region?: GraphQLTypes["Location_region_MutationInput"] | undefined,
	creator?: string | undefined,
	permalink?: string | undefined,
	status: string,
	level?: GraphQLTypes["Location_level_MutationInput"] | undefined,
	parent?: Array<string | undefined> | undefined,
	name?: string | undefined,
	thumbnail?: string | undefined,
	banner?: string | undefined,
	type: GraphQLTypes["Location_type_MutationInput"],
	category: GraphQLTypes["Location_category_MutationInput"],
	district?: string | undefined,
	coordinate?: Array<number | undefined> | undefined,
	linkAddress?: boolean | undefined,
	address?: string | undefined,
	addressCoordinate?: Array<number | undefined> | undefined,
	permanant?: boolean | undefined,
	Details?: GraphQLTypes["mutationLocation_DetailsInput"] | undefined,
	content?: GraphQLTypes["JSON"] | undefined,
	legacyContent?: string | undefined,
	showLegacyContent?: boolean | undefined,
	criteria?: GraphQLTypes["mutationLocation_CriteriaInput"] | undefined,
	sections?: Array<GraphQLTypes["mutationLocation_SectionsInput"] | undefined> | undefined,
	views?: number | undefined,
	_title?: string | undefined,
	legacyGallery?: Array<string | undefined> | undefined,
	updatedAt?: string | undefined,
	createdAt?: string | undefined
};
	["Location_region_MutationInput"]: Location_region_MutationInput;
	["Location_level_MutationInput"]: Location_level_MutationInput;
	["Location_type_MutationInput"]: Location_type_MutationInput;
	["Location_category_MutationInput"]: Location_category_MutationInput;
	["mutationLocation_DetailsInput"]: {
		code?: number | undefined,
	phone?: string | undefined,
	page?: string | undefined,
	email?: string | undefined
};
	["mutationLocation_CriteriaInput"]: {
		audiences?: Array<string | undefined> | undefined,
	dates?: Array<string | undefined> | undefined,
	categories?: Array<string | undefined> | undefined,
	tags?: Array<string | undefined> | undefined
};
	["mutationLocation_SectionsInput"]: {
		startDatetime?: string | undefined,
	endDatetime?: string | undefined,
	fullDay?: boolean | undefined,
	repeat?: boolean | undefined,
	recurrance: GraphQLTypes["mutationLocation_Sections_RecurranceInput"],
	id?: string | undefined
};
	["mutationLocation_Sections_RecurranceInput"]: {
		type: GraphQLTypes["Location_Sections_Recurrance_type_MutationInput"],
	weekday?: Array<GraphQLTypes["Location_Sections_Recurrance_weekday_MutationInput"] | undefined> | undefined
};
	["Location_Sections_Recurrance_type_MutationInput"]: Location_Sections_Recurrance_type_MutationInput;
	["Location_Sections_Recurrance_weekday_MutationInput"]: Location_Sections_Recurrance_weekday_MutationInput;
	["mutationLocationUpdateInput"]: {
		region?: GraphQLTypes["LocationUpdate_region_MutationInput"] | undefined,
	creator?: string | undefined,
	permalink?: string | undefined,
	status?: string | undefined,
	level?: GraphQLTypes["LocationUpdate_level_MutationInput"] | undefined,
	parent?: Array<string | undefined> | undefined,
	name?: string | undefined,
	thumbnail?: string | undefined,
	banner?: string | undefined,
	type?: GraphQLTypes["LocationUpdate_type_MutationInput"] | undefined,
	category?: GraphQLTypes["LocationUpdate_category_MutationInput"] | undefined,
	district?: string | undefined,
	coordinate?: Array<number | undefined> | undefined,
	linkAddress?: boolean | undefined,
	address?: string | undefined,
	addressCoordinate?: Array<number | undefined> | undefined,
	permanant?: boolean | undefined,
	Details?: GraphQLTypes["mutationLocationUpdate_DetailsInput"] | undefined,
	content?: GraphQLTypes["JSON"] | undefined,
	legacyContent?: string | undefined,
	showLegacyContent?: boolean | undefined,
	criteria?: GraphQLTypes["mutationLocationUpdate_CriteriaInput"] | undefined,
	sections?: Array<GraphQLTypes["mutationLocationUpdate_SectionsInput"] | undefined> | undefined,
	views?: number | undefined,
	_title?: string | undefined,
	legacyGallery?: Array<string | undefined> | undefined,
	updatedAt?: string | undefined,
	createdAt?: string | undefined
};
	["LocationUpdate_region_MutationInput"]: LocationUpdate_region_MutationInput;
	["LocationUpdate_level_MutationInput"]: LocationUpdate_level_MutationInput;
	["LocationUpdate_type_MutationInput"]: LocationUpdate_type_MutationInput;
	["LocationUpdate_category_MutationInput"]: LocationUpdate_category_MutationInput;
	["mutationLocationUpdate_DetailsInput"]: {
		code?: number | undefined,
	phone?: string | undefined,
	page?: string | undefined,
	email?: string | undefined
};
	["mutationLocationUpdate_CriteriaInput"]: {
		audiences?: Array<string | undefined> | undefined,
	dates?: Array<string | undefined> | undefined,
	categories?: Array<string | undefined> | undefined,
	tags?: Array<string | undefined> | undefined
};
	["mutationLocationUpdate_SectionsInput"]: {
		startDatetime?: string | undefined,
	endDatetime?: string | undefined,
	fullDay?: boolean | undefined,
	repeat?: boolean | undefined,
	recurrance: GraphQLTypes["mutationLocationUpdate_Sections_RecurranceInput"],
	id?: string | undefined
};
	["mutationLocationUpdate_Sections_RecurranceInput"]: {
		type: GraphQLTypes["LocationUpdate_Sections_Recurrance_type_MutationInput"],
	weekday?: Array<GraphQLTypes["LocationUpdate_Sections_Recurrance_weekday_MutationInput"] | undefined> | undefined
};
	["LocationUpdate_Sections_Recurrance_type_MutationInput"]: LocationUpdate_Sections_Recurrance_type_MutationInput;
	["LocationUpdate_Sections_Recurrance_weekday_MutationInput"]: LocationUpdate_Sections_Recurrance_weekday_MutationInput;
	["mutationPostInput"]: {
		title?: string | undefined,
	region?: GraphQLTypes["Post_region_MutationInput"] | undefined,
	authur?: string | undefined,
	publishDateTime?: string | undefined,
	permalink?: string | undefined,
	status: string,
	content?: GraphQLTypes["JSON"] | undefined,
	legacyContent?: string | undefined,
	showLegacyContent?: boolean | undefined,
	blogCategories?: Array<string | undefined> | undefined,
	thumbnail?: string | undefined,
	banner?: string | undefined,
	criteria?: GraphQLTypes["mutationPost_CriteriaInput"] | undefined,
	location?: string | undefined,
	views?: number | undefined,
	homePageInternalClick?: number | undefined,
	homePageImpression?: number | undefined,
	blogListInternalClick?: number | undefined,
	blogListImpression?: number | undefined,
	blogDetailInternalClick?: number | undefined,
	blogDetailImpression?: number | undefined,
	relatedEventInternalClick?: number | undefined,
	relatedEventImpression?: number | undefined,
	latestEventInternalClick?: number | undefined,
	latestEventImpression?: number | undefined,
	internalClick?: number | undefined,
	totalImpression?: number | undefined,
	externalClick?: number | undefined,
	outboundClick?: number | undefined,
	legacyGallery?: Array<string | undefined> | undefined,
	updatedAt?: string | undefined,
	createdAt?: string | undefined
};
	["Post_region_MutationInput"]: Post_region_MutationInput;
	["mutationPost_CriteriaInput"]: {
		audiences?: Array<string | undefined> | undefined,
	dates?: Array<string | undefined> | undefined,
	categories?: Array<string | undefined> | undefined,
	tags?: Array<string | undefined> | undefined
};
	["mutationPostUpdateInput"]: {
		title?: string | undefined,
	region?: GraphQLTypes["PostUpdate_region_MutationInput"] | undefined,
	authur?: string | undefined,
	publishDateTime?: string | undefined,
	permalink?: string | undefined,
	status?: string | undefined,
	content?: GraphQLTypes["JSON"] | undefined,
	legacyContent?: string | undefined,
	showLegacyContent?: boolean | undefined,
	blogCategories?: Array<string | undefined> | undefined,
	thumbnail?: string | undefined,
	banner?: string | undefined,
	criteria?: GraphQLTypes["mutationPostUpdate_CriteriaInput"] | undefined,
	location?: string | undefined,
	views?: number | undefined,
	homePageInternalClick?: number | undefined,
	homePageImpression?: number | undefined,
	blogListInternalClick?: number | undefined,
	blogListImpression?: number | undefined,
	blogDetailInternalClick?: number | undefined,
	blogDetailImpression?: number | undefined,
	relatedEventInternalClick?: number | undefined,
	relatedEventImpression?: number | undefined,
	latestEventInternalClick?: number | undefined,
	latestEventImpression?: number | undefined,
	internalClick?: number | undefined,
	totalImpression?: number | undefined,
	externalClick?: number | undefined,
	outboundClick?: number | undefined,
	legacyGallery?: Array<string | undefined> | undefined,
	updatedAt?: string | undefined,
	createdAt?: string | undefined
};
	["PostUpdate_region_MutationInput"]: PostUpdate_region_MutationInput;
	["mutationPostUpdate_CriteriaInput"]: {
		audiences?: Array<string | undefined> | undefined,
	dates?: Array<string | undefined> | undefined,
	categories?: Array<string | undefined> | undefined,
	tags?: Array<string | undefined> | undefined
};
	["mutationTicketingInput"]: {
		status?: GraphQLTypes["Ticketing_status_MutationInput"] | undefined,
	creator?: string | undefined,
	productType: string,
	productName?: string | undefined,
	referenceId?: string | undefined,
	linkedEvent?: string | undefined,
	CheckInLevel: GraphQLTypes["Ticketing_CheckInLevel_MutationInput"],
	displayPrice: string,
	tnc?: string | undefined,
	cutoffHours: number,
	sessions?: Array<GraphQLTypes["mutationTicketing_SessionsInput"] | undefined> | undefined,
	updatedAt?: string | undefined,
	createdAt?: string | undefined
};
	["Ticketing_status_MutationInput"]: Ticketing_status_MutationInput;
	["Ticketing_CheckInLevel_MutationInput"]: Ticketing_CheckInLevel_MutationInput;
	["mutationTicketing_SessionsInput"]: {
		variantId?: string | undefined,
	productId?: string | undefined,
	startDate: string,
	endDate: string,
	inventories: GraphQLTypes["mutationTicketing_Sessions_InventoriesInput"],
	id?: string | undefined
};
	["mutationTicketing_Sessions_InventoriesInput"]: {
		name?: string | undefined,
	quantity: number,
	unitPrice: number,
	handlingFee: number,
	totalCost?: number | undefined,
	minPurchase: number,
	maxPurchase: number,
	stocks?: GraphQLTypes["mutationTicketing_Sessions_Inventories_StocksInput"] | undefined,
	remarks?: string | undefined
};
	["mutationTicketing_Sessions_Inventories_StocksInput"]: {
		stockId?: string | undefined,
	remaining?: number | undefined,
	sold?: number | undefined,
	onHold?: number | undefined,
	checkedIn?: number | undefined
};
	["mutationTicketingUpdateInput"]: {
		status?: GraphQLTypes["TicketingUpdate_status_MutationInput"] | undefined,
	creator?: string | undefined,
	productType?: string | undefined,
	productName?: string | undefined,
	referenceId?: string | undefined,
	linkedEvent?: string | undefined,
	CheckInLevel?: GraphQLTypes["TicketingUpdate_CheckInLevel_MutationInput"] | undefined,
	displayPrice?: string | undefined,
	tnc?: string | undefined,
	cutoffHours?: number | undefined,
	sessions?: Array<GraphQLTypes["mutationTicketingUpdate_SessionsInput"] | undefined> | undefined,
	updatedAt?: string | undefined,
	createdAt?: string | undefined
};
	["TicketingUpdate_status_MutationInput"]: TicketingUpdate_status_MutationInput;
	["TicketingUpdate_CheckInLevel_MutationInput"]: TicketingUpdate_CheckInLevel_MutationInput;
	["mutationTicketingUpdate_SessionsInput"]: {
		variantId?: string | undefined,
	productId?: string | undefined,
	startDate: string,
	endDate: string,
	inventories: GraphQLTypes["mutationTicketingUpdate_Sessions_InventoriesInput"],
	id?: string | undefined
};
	["mutationTicketingUpdate_Sessions_InventoriesInput"]: {
		name?: string | undefined,
	quantity: number,
	unitPrice: number,
	handlingFee: number,
	totalCost?: number | undefined,
	minPurchase: number,
	maxPurchase: number,
	stocks?: GraphQLTypes["mutationTicketingUpdate_Sessions_Inventories_StocksInput"] | undefined,
	remarks?: string | undefined
};
	["mutationTicketingUpdate_Sessions_Inventories_StocksInput"]: {
		stockId?: string | undefined,
	remaining?: number | undefined,
	sold?: number | undefined,
	onHold?: number | undefined,
	checkedIn?: number | undefined
};
	["mutationProductInput"]: {
		status?: GraphQLTypes["Product_status_MutationInput"] | undefined,
	creator?: string | undefined,
	productType: string,
	productName?: string | undefined,
	referenceId?: string | undefined,
	linkedEvent?: string | undefined,
	CheckInLevel: GraphQLTypes["Product_CheckInLevel_MutationInput"],
	thumbnail: string,
	displayPrice: string,
	transactionType: string,
	website?: string | undefined,
	tnc?: string | undefined,
	remarks?: string | undefined,
	sessions?: Array<GraphQLTypes["mutationProduct_SessionsInput"] | undefined> | undefined,
	updatedAt?: string | undefined,
	createdAt?: string | undefined
};
	["Product_status_MutationInput"]: Product_status_MutationInput;
	["Product_CheckInLevel_MutationInput"]: Product_CheckInLevel_MutationInput;
	["mutationProduct_SessionsInput"]: {
		variantId?: string | undefined,
	productId?: string | undefined,
	name?: string | undefined,
	inventories?: GraphQLTypes["mutationProduct_Sessions_InventoriesInput"] | undefined,
	id?: string | undefined
};
	["mutationProduct_Sessions_InventoriesInput"]: {
		quantity: number,
	unitPrice: number,
	handlingFee: number,
	totalCost?: number | undefined,
	minPurchase: number,
	maxPurchase: number,
	stocks?: GraphQLTypes["mutationProduct_Sessions_Inventories_StocksInput"] | undefined
};
	["mutationProduct_Sessions_Inventories_StocksInput"]: {
		stockId?: string | undefined,
	remaining?: number | undefined,
	sold?: number | undefined,
	onHold?: number | undefined,
	checkedIn?: number | undefined
};
	["mutationProductUpdateInput"]: {
		status?: GraphQLTypes["ProductUpdate_status_MutationInput"] | undefined,
	creator?: string | undefined,
	productType?: string | undefined,
	productName?: string | undefined,
	referenceId?: string | undefined,
	linkedEvent?: string | undefined,
	CheckInLevel?: GraphQLTypes["ProductUpdate_CheckInLevel_MutationInput"] | undefined,
	thumbnail?: string | undefined,
	displayPrice?: string | undefined,
	transactionType?: string | undefined,
	website?: string | undefined,
	tnc?: string | undefined,
	remarks?: string | undefined,
	sessions?: Array<GraphQLTypes["mutationProductUpdate_SessionsInput"] | undefined> | undefined,
	updatedAt?: string | undefined,
	createdAt?: string | undefined
};
	["ProductUpdate_status_MutationInput"]: ProductUpdate_status_MutationInput;
	["ProductUpdate_CheckInLevel_MutationInput"]: ProductUpdate_CheckInLevel_MutationInput;
	["mutationProductUpdate_SessionsInput"]: {
		variantId?: string | undefined,
	productId?: string | undefined,
	name?: string | undefined,
	inventories?: GraphQLTypes["mutationProductUpdate_Sessions_InventoriesInput"] | undefined,
	id?: string | undefined
};
	["mutationProductUpdate_Sessions_InventoriesInput"]: {
		quantity: number,
	unitPrice: number,
	handlingFee: number,
	totalCost?: number | undefined,
	minPurchase: number,
	maxPurchase: number,
	stocks?: GraphQLTypes["mutationProductUpdate_Sessions_Inventories_StocksInput"] | undefined
};
	["mutationProductUpdate_Sessions_Inventories_StocksInput"]: {
		stockId?: string | undefined,
	remaining?: number | undefined,
	sold?: number | undefined,
	onHold?: number | undefined,
	checkedIn?: number | undefined
};
	["mutationInventoryStatusInput"]: {
		variantId?: string | undefined,
	variantName?: string | undefined,
	productType?: string | undefined,
	stockUpdateStatus: GraphQLTypes["InventoryStatus_stockUpdateStatus_MutationInput"],
	stockUpdateAmount: number,
	user?: string | undefined,
	publicUserPhone?: string | undefined,
	expiredAt?: string | undefined,
	updatedAt?: string | undefined,
	createdAt?: string | undefined
};
	["InventoryStatus_stockUpdateStatus_MutationInput"]: InventoryStatus_stockUpdateStatus_MutationInput;
	["mutationInventoryStatusUpdateInput"]: {
		variantId?: string | undefined,
	variantName?: string | undefined,
	productType?: string | undefined,
	stockUpdateStatus?: GraphQLTypes["InventoryStatusUpdate_stockUpdateStatus_MutationInput"] | undefined,
	stockUpdateAmount?: number | undefined,
	user?: string | undefined,
	publicUserPhone?: string | undefined,
	expiredAt?: string | undefined,
	updatedAt?: string | undefined,
	createdAt?: string | undefined
};
	["InventoryStatusUpdate_stockUpdateStatus_MutationInput"]: InventoryStatusUpdate_stockUpdateStatus_MutationInput;
	["mutationTransactionInput"]: {
		creator?: string | undefined,
	productName?: string | undefined,
	referenceId?: string | undefined,
	linkedEvent?: string | undefined,
	purchaseDateTime?: string | undefined,
	timableOrderId?: string | undefined,
	stripePaymentId?: string | undefined,
	totalAmount?: number | undefined,
	contactPersonName?: string | undefined,
	contactPersonPhone?: string | undefined,
	remarks?: string | undefined,
	redemptionCodes?: Array<GraphQLTypes["mutationTransaction_RedemptionCodesInput"] | undefined> | undefined,
	updatedAt?: string | undefined,
	createdAt?: string | undefined
};
	["mutationTransaction_RedemptionCodesInput"]: {
		code?: string | undefined,
	id?: string | undefined
};
	["mutationTransactionUpdateInput"]: {
		creator?: string | undefined,
	productName?: string | undefined,
	referenceId?: string | undefined,
	linkedEvent?: string | undefined,
	purchaseDateTime?: string | undefined,
	timableOrderId?: string | undefined,
	stripePaymentId?: string | undefined,
	totalAmount?: number | undefined,
	contactPersonName?: string | undefined,
	contactPersonPhone?: string | undefined,
	remarks?: string | undefined,
	redemptionCodes?: Array<GraphQLTypes["mutationTransactionUpdate_RedemptionCodesInput"] | undefined> | undefined,
	updatedAt?: string | undefined,
	createdAt?: string | undefined
};
	["mutationTransactionUpdate_RedemptionCodesInput"]: {
		code?: string | undefined,
	id?: string | undefined
};
	["mutationRedemptionCodeInput"]: {
		creator?: string | undefined,
	status: GraphQLTypes["RedemptionCode_status_MutationInput"],
	qrCodeId?: string | undefined,
	lastCheckedInDateTime?: string | undefined,
	voidedDateTime?: string | undefined,
	refundedDateTime?: string | undefined,
	eventStartTime?: string | undefined,
	eventEndTime?: string | undefined,
	eventPriceZone?: string | undefined,
	ownerName: string,
	phone: string,
	pdf?: string | undefined,
	variants?: Array<GraphQLTypes["mutationRedemptionCode_VariantsInput"] | undefined> | undefined,
	productId?: string | undefined,
	referenceId?: string | undefined,
	productType?: string | undefined,
	productName?: string | undefined,
	linkedEvent?: string | undefined,
	timableOrderId?: string | undefined,
	purchaseDateTime?: string | undefined,
	changeHistory?: Array<GraphQLTypes["mutationRedemptionCode_ChangeHistoryInput"] | undefined> | undefined,
	updatedAt?: string | undefined,
	createdAt?: string | undefined
};
	["RedemptionCode_status_MutationInput"]: RedemptionCode_status_MutationInput;
	["mutationRedemptionCode_VariantsInput"]: {
		variantId?: string | undefined,
	name?: string | undefined,
	quantity?: string | undefined,
	totalCost?: string | undefined,
	id?: string | undefined
};
	["mutationRedemptionCode_ChangeHistoryInput"]: {
		timestamp: string,
	field: string,
	oldValue: string,
	newValue: string,
	id?: string | undefined
};
	["mutationRedemptionCodeUpdateInput"]: {
		creator?: string | undefined,
	status?: GraphQLTypes["RedemptionCodeUpdate_status_MutationInput"] | undefined,
	qrCodeId?: string | undefined,
	lastCheckedInDateTime?: string | undefined,
	voidedDateTime?: string | undefined,
	refundedDateTime?: string | undefined,
	eventStartTime?: string | undefined,
	eventEndTime?: string | undefined,
	eventPriceZone?: string | undefined,
	ownerName?: string | undefined,
	phone?: string | undefined,
	pdf?: string | undefined,
	variants?: Array<GraphQLTypes["mutationRedemptionCodeUpdate_VariantsInput"] | undefined> | undefined,
	productId?: string | undefined,
	referenceId?: string | undefined,
	productType?: string | undefined,
	productName?: string | undefined,
	linkedEvent?: string | undefined,
	timableOrderId?: string | undefined,
	purchaseDateTime?: string | undefined,
	changeHistory?: Array<GraphQLTypes["mutationRedemptionCodeUpdate_ChangeHistoryInput"] | undefined> | undefined,
	updatedAt?: string | undefined,
	createdAt?: string | undefined
};
	["RedemptionCodeUpdate_status_MutationInput"]: RedemptionCodeUpdate_status_MutationInput;
	["mutationRedemptionCodeUpdate_VariantsInput"]: {
		variantId?: string | undefined,
	name?: string | undefined,
	quantity?: string | undefined,
	totalCost?: string | undefined,
	id?: string | undefined
};
	["mutationRedemptionCodeUpdate_ChangeHistoryInput"]: {
		timestamp: string,
	field: string,
	oldValue: string,
	newValue: string,
	id?: string | undefined
};
	["mutationRedemptionPdfInput"]: {
		updatedAt?: string | undefined,
	createdAt?: string | undefined,
	url?: string | undefined,
	filename?: string | undefined,
	mimeType?: string | undefined,
	filesize?: number | undefined,
	width?: number | undefined,
	height?: number | undefined,
	focalX?: number | undefined,
	focalY?: number | undefined
};
	["mutationRedemptionPdfUpdateInput"]: {
		updatedAt?: string | undefined,
	createdAt?: string | undefined,
	url?: string | undefined,
	filename?: string | undefined,
	mimeType?: string | undefined,
	filesize?: number | undefined,
	width?: number | undefined,
	height?: number | undefined,
	focalX?: number | undefined,
	focalY?: number | undefined
};
	["mutationCheckInRedemptionInput"]: {
		creator?: string | undefined,
	qrCodeId?: string | undefined,
	codeownerName?: string | undefined,
	name?: string | undefined,
	phone?: string | undefined,
	linkedEvent?: string | undefined,
	entryNumber?: string | undefined,
	scanAt?: string | undefined,
	uploadAt?: string | undefined,
	status?: string | undefined,
	failReason?: string | undefined,
	variants?: Array<GraphQLTypes["mutationCheckInRedemption_VariantsInput"] | undefined> | undefined,
	checkInAppSelectionId?: string | undefined,
	qrCodeStatus?: string | undefined,
	productType?: string | undefined,
	updatedAt?: string | undefined,
	createdAt?: string | undefined
};
	["mutationCheckInRedemption_VariantsInput"]: {
		variantId?: string | undefined,
	name?: string | undefined,
	quantity?: string | undefined,
	totalCost?: string | undefined,
	id?: string | undefined
};
	["mutationCheckInRedemptionUpdateInput"]: {
		creator?: string | undefined,
	qrCodeId?: string | undefined,
	codeownerName?: string | undefined,
	name?: string | undefined,
	phone?: string | undefined,
	linkedEvent?: string | undefined,
	entryNumber?: string | undefined,
	scanAt?: string | undefined,
	uploadAt?: string | undefined,
	status?: string | undefined,
	failReason?: string | undefined,
	variants?: Array<GraphQLTypes["mutationCheckInRedemptionUpdate_VariantsInput"] | undefined> | undefined,
	checkInAppSelectionId?: string | undefined,
	qrCodeStatus?: string | undefined,
	productType?: string | undefined,
	updatedAt?: string | undefined,
	createdAt?: string | undefined
};
	["mutationCheckInRedemptionUpdate_VariantsInput"]: {
		variantId?: string | undefined,
	name?: string | undefined,
	quantity?: string | undefined,
	totalCost?: string | undefined,
	id?: string | undefined
};
	["mutationUserInput"]: {
		region?: GraphQLTypes["User_region_MutationInput"] | undefined,
	name?: string | undefined,
	displayEmail?: string | undefined,
	legacyUsername?: string | undefined,
	roles: Array<GraphQLTypes["User_roles_MutationInput"] | undefined>,
	member?: GraphQLTypes["mutationUser_MemberInput"] | undefined,
	organiser?: GraphQLTypes["mutationUser_OrganiserInput"] | undefined,
	emailVerified?: boolean | undefined,
	sso?: GraphQLTypes["mutationUser_SsoInput"] | undefined,
	oldPassword?: string | undefined,
	status?: GraphQLTypes["User_status_MutationInput"] | undefined,
	updatedAt?: string | undefined,
	createdAt?: string | undefined,
	email: string,
	resetPasswordToken?: string | undefined,
	resetPasswordExpiration?: string | undefined,
	salt?: string | undefined,
	hash?: string | undefined,
	loginAttempts?: number | undefined,
	lockUntil?: string | undefined,
	password: string
};
	["User_region_MutationInput"]: User_region_MutationInput;
	["User_roles_MutationInput"]: User_roles_MutationInput;
	["mutationUser_MemberInput"]: {
		region?: GraphQLTypes["User_Member_region_MutationInput"] | undefined,
	language?: GraphQLTypes["User_Member_language_MutationInput"] | undefined,
	phone?: string | undefined,
	avatar?: string | undefined,
	birthday?: string | undefined,
	gender?: GraphQLTypes["User_Member_gender_MutationInput"] | undefined,
	categories?: Array<string | undefined> | undefined
};
	["User_Member_region_MutationInput"]: User_Member_region_MutationInput;
	["User_Member_language_MutationInput"]: User_Member_language_MutationInput;
	["User_Member_gender_MutationInput"]: User_Member_gender_MutationInput;
	["mutationUser_OrganiserInput"]: {
		logo?: string | undefined,
	banner?: string | undefined,
	description?: string | undefined,
	foundedIn?: string | undefined,
	address?: string | undefined,
	website?: string | undefined,
	facebook?: string | undefined,
	instagram?: string | undefined,
	language?: GraphQLTypes["User_Organiser_language_MutationInput"] | undefined,
	phone?: string | undefined,
	followers?: number | undefined
};
	["User_Organiser_language_MutationInput"]: User_Organiser_language_MutationInput;
	["mutationUser_SsoInput"]: {
		googleId?: string | undefined,
	facebookId?: string | undefined,
	appleId?: string | undefined
};
	["User_status_MutationInput"]: User_status_MutationInput;
	["mutationUserUpdateInput"]: {
		region?: GraphQLTypes["UserUpdate_region_MutationInput"] | undefined,
	name?: string | undefined,
	displayEmail?: string | undefined,
	legacyUsername?: string | undefined,
	roles?: Array<GraphQLTypes["UserUpdate_roles_MutationInput"] | undefined> | undefined,
	member?: GraphQLTypes["mutationUserUpdate_MemberInput"] | undefined,
	organiser?: GraphQLTypes["mutationUserUpdate_OrganiserInput"] | undefined,
	emailVerified?: boolean | undefined,
	sso?: GraphQLTypes["mutationUserUpdate_SsoInput"] | undefined,
	oldPassword?: string | undefined,
	status?: GraphQLTypes["UserUpdate_status_MutationInput"] | undefined,
	updatedAt?: string | undefined,
	createdAt?: string | undefined,
	email?: string | undefined,
	resetPasswordToken?: string | undefined,
	resetPasswordExpiration?: string | undefined,
	salt?: string | undefined,
	hash?: string | undefined,
	loginAttempts?: number | undefined,
	lockUntil?: string | undefined,
	password?: string | undefined
};
	["UserUpdate_region_MutationInput"]: UserUpdate_region_MutationInput;
	["UserUpdate_roles_MutationInput"]: UserUpdate_roles_MutationInput;
	["mutationUserUpdate_MemberInput"]: {
		region?: GraphQLTypes["UserUpdate_Member_region_MutationInput"] | undefined,
	language?: GraphQLTypes["UserUpdate_Member_language_MutationInput"] | undefined,
	phone?: string | undefined,
	avatar?: string | undefined,
	birthday?: string | undefined,
	gender?: GraphQLTypes["UserUpdate_Member_gender_MutationInput"] | undefined,
	categories?: Array<string | undefined> | undefined
};
	["UserUpdate_Member_region_MutationInput"]: UserUpdate_Member_region_MutationInput;
	["UserUpdate_Member_language_MutationInput"]: UserUpdate_Member_language_MutationInput;
	["UserUpdate_Member_gender_MutationInput"]: UserUpdate_Member_gender_MutationInput;
	["mutationUserUpdate_OrganiserInput"]: {
		logo?: string | undefined,
	banner?: string | undefined,
	description?: string | undefined,
	foundedIn?: string | undefined,
	address?: string | undefined,
	website?: string | undefined,
	facebook?: string | undefined,
	instagram?: string | undefined,
	language?: GraphQLTypes["UserUpdate_Organiser_language_MutationInput"] | undefined,
	phone?: string | undefined,
	followers?: number | undefined
};
	["UserUpdate_Organiser_language_MutationInput"]: UserUpdate_Organiser_language_MutationInput;
	["mutationUserUpdate_SsoInput"]: {
		googleId?: string | undefined,
	facebookId?: string | undefined,
	appleId?: string | undefined
};
	["UserUpdate_status_MutationInput"]: UserUpdate_status_MutationInput;
	["usersRefreshedUser"]: {
	__typename: "usersRefreshedUser",
	exp?: number | undefined,
	refreshedToken?: string | undefined,
	strategy?: string | undefined,
	user?: GraphQLTypes["usersJWT"] | undefined
};
	["usersJWT"]: {
	__typename: "usersJWT",
	name?: string | undefined,
	email: GraphQLTypes["EmailAddress"],
	collection: string
};
	["usersLoginResult"]: {
	__typename: "usersLoginResult",
	exp?: number | undefined,
	token?: string | undefined,
	user?: GraphQLTypes["User"] | undefined
};
	["usersResetPassword"]: {
	__typename: "usersResetPassword",
	token?: string | undefined,
	user?: GraphQLTypes["User"] | undefined
};
	["mutationBookmarkInput"]: {
		user?: GraphQLTypes["Bookmark_UserRelationshipInput"] | undefined,
	item?: GraphQLTypes["Bookmark_ItemRelationshipInput"] | undefined,
	updatedAt?: string | undefined,
	createdAt?: string | undefined
};
	["Bookmark_UserRelationshipInput"]: {
		relationTo?: GraphQLTypes["Bookmark_UserRelationshipInputRelationTo"] | undefined,
	value?: GraphQLTypes["JSON"] | undefined
};
	["Bookmark_UserRelationshipInputRelationTo"]: Bookmark_UserRelationshipInputRelationTo;
	["Bookmark_ItemRelationshipInput"]: {
		relationTo?: GraphQLTypes["Bookmark_ItemRelationshipInputRelationTo"] | undefined,
	value?: GraphQLTypes["JSON"] | undefined
};
	["Bookmark_ItemRelationshipInputRelationTo"]: Bookmark_ItemRelationshipInputRelationTo;
	["mutationBookmarkUpdateInput"]: {
		user?: GraphQLTypes["BookmarkUpdate_UserRelationshipInput"] | undefined,
	item?: GraphQLTypes["BookmarkUpdate_ItemRelationshipInput"] | undefined,
	updatedAt?: string | undefined,
	createdAt?: string | undefined
};
	["BookmarkUpdate_UserRelationshipInput"]: {
		relationTo?: GraphQLTypes["BookmarkUpdate_UserRelationshipInputRelationTo"] | undefined,
	value?: GraphQLTypes["JSON"] | undefined
};
	["BookmarkUpdate_UserRelationshipInputRelationTo"]: BookmarkUpdate_UserRelationshipInputRelationTo;
	["BookmarkUpdate_ItemRelationshipInput"]: {
		relationTo?: GraphQLTypes["BookmarkUpdate_ItemRelationshipInputRelationTo"] | undefined,
	value?: GraphQLTypes["JSON"] | undefined
};
	["BookmarkUpdate_ItemRelationshipInputRelationTo"]: BookmarkUpdate_ItemRelationshipInputRelationTo;
	["mutationWebAppBookmarkInput"]: {
		user?: GraphQLTypes["WebAppBookmark_UserRelationshipInput"] | undefined,
	event?: GraphQLTypes["WebAppBookmark_EventRelationshipInput"] | undefined,
	productType?: GraphQLTypes["WebAppBookmark_productType_MutationInput"] | undefined,
	item?: string | undefined,
	entryNumber?: string | undefined,
	updatedAt?: string | undefined,
	createdAt?: string | undefined
};
	["WebAppBookmark_UserRelationshipInput"]: {
		relationTo?: GraphQLTypes["WebAppBookmark_UserRelationshipInputRelationTo"] | undefined,
	value?: GraphQLTypes["JSON"] | undefined
};
	["WebAppBookmark_UserRelationshipInputRelationTo"]: WebAppBookmark_UserRelationshipInputRelationTo;
	["WebAppBookmark_EventRelationshipInput"]: {
		relationTo?: GraphQLTypes["WebAppBookmark_EventRelationshipInputRelationTo"] | undefined,
	value?: GraphQLTypes["JSON"] | undefined
};
	["WebAppBookmark_EventRelationshipInputRelationTo"]: WebAppBookmark_EventRelationshipInputRelationTo;
	["WebAppBookmark_productType_MutationInput"]: WebAppBookmark_productType_MutationInput;
	["mutationWebAppBookmarkUpdateInput"]: {
		user?: GraphQLTypes["WebAppBookmarkUpdate_UserRelationshipInput"] | undefined,
	event?: GraphQLTypes["WebAppBookmarkUpdate_EventRelationshipInput"] | undefined,
	productType?: GraphQLTypes["WebAppBookmarkUpdate_productType_MutationInput"] | undefined,
	item?: string | undefined,
	entryNumber?: string | undefined,
	updatedAt?: string | undefined,
	createdAt?: string | undefined
};
	["WebAppBookmarkUpdate_UserRelationshipInput"]: {
		relationTo?: GraphQLTypes["WebAppBookmarkUpdate_UserRelationshipInputRelationTo"] | undefined,
	value?: GraphQLTypes["JSON"] | undefined
};
	["WebAppBookmarkUpdate_UserRelationshipInputRelationTo"]: WebAppBookmarkUpdate_UserRelationshipInputRelationTo;
	["WebAppBookmarkUpdate_EventRelationshipInput"]: {
		relationTo?: GraphQLTypes["WebAppBookmarkUpdate_EventRelationshipInputRelationTo"] | undefined,
	value?: GraphQLTypes["JSON"] | undefined
};
	["WebAppBookmarkUpdate_EventRelationshipInputRelationTo"]: WebAppBookmarkUpdate_EventRelationshipInputRelationTo;
	["WebAppBookmarkUpdate_productType_MutationInput"]: WebAppBookmarkUpdate_productType_MutationInput;
	["mutationViewRecordInput"]: {
		user?: GraphQLTypes["ViewRecord_UserRelationshipInput"] | undefined,
	item?: GraphQLTypes["ViewRecord_ItemRelationshipInput"] | undefined,
	updatedAt?: string | undefined,
	createdAt?: string | undefined
};
	["ViewRecord_UserRelationshipInput"]: {
		relationTo?: GraphQLTypes["ViewRecord_UserRelationshipInputRelationTo"] | undefined,
	value?: GraphQLTypes["JSON"] | undefined
};
	["ViewRecord_UserRelationshipInputRelationTo"]: ViewRecord_UserRelationshipInputRelationTo;
	["ViewRecord_ItemRelationshipInput"]: {
		relationTo?: GraphQLTypes["ViewRecord_ItemRelationshipInputRelationTo"] | undefined,
	value?: GraphQLTypes["JSON"] | undefined
};
	["ViewRecord_ItemRelationshipInputRelationTo"]: ViewRecord_ItemRelationshipInputRelationTo;
	["mutationViewRecordUpdateInput"]: {
		user?: GraphQLTypes["ViewRecordUpdate_UserRelationshipInput"] | undefined,
	item?: GraphQLTypes["ViewRecordUpdate_ItemRelationshipInput"] | undefined,
	updatedAt?: string | undefined,
	createdAt?: string | undefined
};
	["ViewRecordUpdate_UserRelationshipInput"]: {
		relationTo?: GraphQLTypes["ViewRecordUpdate_UserRelationshipInputRelationTo"] | undefined,
	value?: GraphQLTypes["JSON"] | undefined
};
	["ViewRecordUpdate_UserRelationshipInputRelationTo"]: ViewRecordUpdate_UserRelationshipInputRelationTo;
	["ViewRecordUpdate_ItemRelationshipInput"]: {
		relationTo?: GraphQLTypes["ViewRecordUpdate_ItemRelationshipInputRelationTo"] | undefined,
	value?: GraphQLTypes["JSON"] | undefined
};
	["ViewRecordUpdate_ItemRelationshipInputRelationTo"]: ViewRecordUpdate_ItemRelationshipInputRelationTo;
	["mutationCommentInput"]: {
		commenter?: string | undefined,
	content?: string | undefined,
	image?: string | undefined,
	target?: GraphQLTypes["Comment_TargetRelationshipInput"] | undefined,
	parent?: GraphQLTypes["Comment_ParentRelationshipInput"] | undefined,
	isReviewed?: boolean | undefined,
	reviewedBy?: string | undefined,
	banned?: boolean | undefined,
	type?: string | undefined,
	updatedAt?: string | undefined,
	createdAt?: string | undefined
};
	["Comment_TargetRelationshipInput"]: {
		relationTo?: GraphQLTypes["Comment_TargetRelationshipInputRelationTo"] | undefined,
	value?: GraphQLTypes["JSON"] | undefined
};
	["Comment_TargetRelationshipInputRelationTo"]: Comment_TargetRelationshipInputRelationTo;
	["Comment_ParentRelationshipInput"]: {
		relationTo?: GraphQLTypes["Comment_ParentRelationshipInputRelationTo"] | undefined,
	value?: GraphQLTypes["JSON"] | undefined
};
	["Comment_ParentRelationshipInputRelationTo"]: Comment_ParentRelationshipInputRelationTo;
	["mutationCommentUpdateInput"]: {
		commenter?: string | undefined,
	content?: string | undefined,
	image?: string | undefined,
	target?: GraphQLTypes["CommentUpdate_TargetRelationshipInput"] | undefined,
	parent?: GraphQLTypes["CommentUpdate_ParentRelationshipInput"] | undefined,
	isReviewed?: boolean | undefined,
	reviewedBy?: string | undefined,
	banned?: boolean | undefined,
	type?: string | undefined,
	updatedAt?: string | undefined,
	createdAt?: string | undefined
};
	["CommentUpdate_TargetRelationshipInput"]: {
		relationTo?: GraphQLTypes["CommentUpdate_TargetRelationshipInputRelationTo"] | undefined,
	value?: GraphQLTypes["JSON"] | undefined
};
	["CommentUpdate_TargetRelationshipInputRelationTo"]: CommentUpdate_TargetRelationshipInputRelationTo;
	["CommentUpdate_ParentRelationshipInput"]: {
		relationTo?: GraphQLTypes["CommentUpdate_ParentRelationshipInputRelationTo"] | undefined,
	value?: GraphQLTypes["JSON"] | undefined
};
	["CommentUpdate_ParentRelationshipInputRelationTo"]: CommentUpdate_ParentRelationshipInputRelationTo;
	["mutationSubscriptionInput"]: {
		user?: GraphQLTypes["Subscription_UserRelationshipInput"] | undefined,
	organiser?: GraphQLTypes["Subscription_OrganiserRelationshipInput"] | undefined,
	updatedAt?: string | undefined,
	createdAt?: string | undefined
};
	["Subscription_UserRelationshipInput"]: {
		relationTo?: GraphQLTypes["Subscription_UserRelationshipInputRelationTo"] | undefined,
	value?: GraphQLTypes["JSON"] | undefined
};
	["Subscription_UserRelationshipInputRelationTo"]: Subscription_UserRelationshipInputRelationTo;
	["Subscription_OrganiserRelationshipInput"]: {
		relationTo?: GraphQLTypes["Subscription_OrganiserRelationshipInputRelationTo"] | undefined,
	value?: GraphQLTypes["JSON"] | undefined
};
	["Subscription_OrganiserRelationshipInputRelationTo"]: Subscription_OrganiserRelationshipInputRelationTo;
	["mutationSubscriptionUpdateInput"]: {
		user?: GraphQLTypes["SubscriptionUpdate_UserRelationshipInput"] | undefined,
	organiser?: GraphQLTypes["SubscriptionUpdate_OrganiserRelationshipInput"] | undefined,
	updatedAt?: string | undefined,
	createdAt?: string | undefined
};
	["SubscriptionUpdate_UserRelationshipInput"]: {
		relationTo?: GraphQLTypes["SubscriptionUpdate_UserRelationshipInputRelationTo"] | undefined,
	value?: GraphQLTypes["JSON"] | undefined
};
	["SubscriptionUpdate_UserRelationshipInputRelationTo"]: SubscriptionUpdate_UserRelationshipInputRelationTo;
	["SubscriptionUpdate_OrganiserRelationshipInput"]: {
		relationTo?: GraphQLTypes["SubscriptionUpdate_OrganiserRelationshipInputRelationTo"] | undefined,
	value?: GraphQLTypes["JSON"] | undefined
};
	["SubscriptionUpdate_OrganiserRelationshipInputRelationTo"]: SubscriptionUpdate_OrganiserRelationshipInputRelationTo;
	["mutationNewsletterInput"]: {
		email: string,
	region?: GraphQLTypes["Newsletter_region_MutationInput"] | undefined,
	updatedAt?: string | undefined,
	createdAt?: string | undefined
};
	["Newsletter_region_MutationInput"]: Newsletter_region_MutationInput;
	["mutationNewsletterUpdateInput"]: {
		email?: string | undefined,
	region?: GraphQLTypes["NewsletterUpdate_region_MutationInput"] | undefined,
	updatedAt?: string | undefined,
	createdAt?: string | undefined
};
	["NewsletterUpdate_region_MutationInput"]: NewsletterUpdate_region_MutationInput;
	["mutationBlogCategoryInput"]: {
		name?: string | undefined,
	value: string,
	icon?: string | undefined,
	region?: GraphQLTypes["BlogCategory_region_MutationInput"] | undefined,
	updatedAt?: string | undefined,
	createdAt?: string | undefined
};
	["BlogCategory_region_MutationInput"]: BlogCategory_region_MutationInput;
	["mutationBlogCategoryUpdateInput"]: {
		name?: string | undefined,
	value?: string | undefined,
	icon?: string | undefined,
	region?: GraphQLTypes["BlogCategoryUpdate_region_MutationInput"] | undefined,
	updatedAt?: string | undefined,
	createdAt?: string | undefined
};
	["BlogCategoryUpdate_region_MutationInput"]: BlogCategoryUpdate_region_MutationInput;
	["mutationTagInput"]: {
		name: string,
	value: string,
	region?: GraphQLTypes["Tag_region_MutationInput"] | undefined,
	count?: number | undefined,
	skipCountCheck?: boolean | undefined,
	removeNextCron?: boolean | undefined
};
	["Tag_region_MutationInput"]: Tag_region_MutationInput;
	["mutationTagUpdateInput"]: {
		name?: string | undefined,
	value?: string | undefined,
	region?: GraphQLTypes["TagUpdate_region_MutationInput"] | undefined,
	count?: number | undefined,
	skipCountCheck?: boolean | undefined,
	removeNextCron?: boolean | undefined
};
	["TagUpdate_region_MutationInput"]: TagUpdate_region_MutationInput;
	["mutationSpecialTagInput"]: {
		name: string,
	value: string,
	region?: GraphQLTypes["SpecialTag_region_MutationInput"] | undefined
};
	["SpecialTag_region_MutationInput"]: SpecialTag_region_MutationInput;
	["mutationSpecialTagUpdateInput"]: {
		name?: string | undefined,
	value?: string | undefined,
	region?: GraphQLTypes["SpecialTagUpdate_region_MutationInput"] | undefined
};
	["SpecialTagUpdate_region_MutationInput"]: SpecialTagUpdate_region_MutationInput;
	["mutationDateInput"]: {
		name?: string | undefined,
	value: string,
	type: GraphQLTypes["Date_type_MutationInput"],
	start?: string | undefined,
	end?: string | undefined,
	_title?: string | undefined,
	region?: GraphQLTypes["Date_region_MutationInput"] | undefined,
	updatedAt?: string | undefined,
	createdAt?: string | undefined
};
	["Date_type_MutationInput"]: Date_type_MutationInput;
	["Date_region_MutationInput"]: Date_region_MutationInput;
	["mutationDateUpdateInput"]: {
		name?: string | undefined,
	value?: string | undefined,
	type?: GraphQLTypes["DateUpdate_type_MutationInput"] | undefined,
	start?: string | undefined,
	end?: string | undefined,
	_title?: string | undefined,
	region?: GraphQLTypes["DateUpdate_region_MutationInput"] | undefined,
	updatedAt?: string | undefined,
	createdAt?: string | undefined
};
	["DateUpdate_type_MutationInput"]: DateUpdate_type_MutationInput;
	["DateUpdate_region_MutationInput"]: DateUpdate_region_MutationInput;
	["mutationDistrictInput"]: {
		name?: string | undefined,
	level: number,
	children?: Array<string | undefined> | undefined,
	value: string,
	lat?: number | undefined,
	long?: number | undefined,
	radius?: number | undefined,
	region?: GraphQLTypes["District_region_MutationInput"] | undefined,
	updatedAt?: string | undefined,
	createdAt?: string | undefined
};
	["District_region_MutationInput"]: District_region_MutationInput;
	["mutationDistrictUpdateInput"]: {
		name?: string | undefined,
	level?: number | undefined,
	children?: Array<string | undefined> | undefined,
	value?: string | undefined,
	lat?: number | undefined,
	long?: number | undefined,
	radius?: number | undefined,
	region?: GraphQLTypes["DistrictUpdate_region_MutationInput"] | undefined,
	updatedAt?: string | undefined,
	createdAt?: string | undefined
};
	["DistrictUpdate_region_MutationInput"]: DistrictUpdate_region_MutationInput;
	["mutationAudienceInput"]: {
		name?: string | undefined,
	value: string,
	icon?: string | undefined,
	region?: GraphQLTypes["Audience_region_MutationInput"] | undefined,
	updatedAt?: string | undefined,
	createdAt?: string | undefined
};
	["Audience_region_MutationInput"]: Audience_region_MutationInput;
	["mutationAudienceUpdateInput"]: {
		name?: string | undefined,
	value?: string | undefined,
	icon?: string | undefined,
	region?: GraphQLTypes["AudienceUpdate_region_MutationInput"] | undefined,
	updatedAt?: string | undefined,
	createdAt?: string | undefined
};
	["AudienceUpdate_region_MutationInput"]: AudienceUpdate_region_MutationInput;
	["mutationEventCategoryInput"]: {
		name?: string | undefined,
	value: string,
	tags?: Array<string | undefined> | undefined,
	icon?: string | undefined,
	region?: GraphQLTypes["EventCategory_region_MutationInput"] | undefined,
	updatedAt?: string | undefined,
	createdAt?: string | undefined
};
	["EventCategory_region_MutationInput"]: EventCategory_region_MutationInput;
	["mutationEventCategoryUpdateInput"]: {
		name?: string | undefined,
	value?: string | undefined,
	tags?: Array<string | undefined> | undefined,
	icon?: string | undefined,
	region?: GraphQLTypes["EventCategoryUpdate_region_MutationInput"] | undefined,
	updatedAt?: string | undefined,
	createdAt?: string | undefined
};
	["EventCategoryUpdate_region_MutationInput"]: EventCategoryUpdate_region_MutationInput;
	["mutationPrivateMediaInput"]: {
		alt?: string | undefined,
	updatedAt?: string | undefined,
	createdAt?: string | undefined,
	url?: string | undefined,
	filename?: string | undefined,
	mimeType?: string | undefined,
	filesize?: number | undefined,
	width?: number | undefined,
	height?: number | undefined,
	focalX?: number | undefined,
	focalY?: number | undefined,
	sizes?: GraphQLTypes["mutationPrivateMedia_SizesInput"] | undefined
};
	["mutationPrivateMedia_SizesInput"]: {
		thumbnail?: GraphQLTypes["mutationPrivateMedia_Sizes_ThumbnailInput"] | undefined,
	portrait?: GraphQLTypes["mutationPrivateMedia_Sizes_PortraitInput"] | undefined,
	hero?: GraphQLTypes["mutationPrivateMedia_Sizes_HeroInput"] | undefined,
	thumbnail_jpeg?: GraphQLTypes["mutationPrivateMedia_Sizes_Thumbnail_jpegInput"] | undefined,
	portrait_jpeg?: GraphQLTypes["mutationPrivateMedia_Sizes_Portrait_jpegInput"] | undefined,
	hero_jpeg?: GraphQLTypes["mutationPrivateMedia_Sizes_Hero_jpegInput"] | undefined
};
	["mutationPrivateMedia_Sizes_ThumbnailInput"]: {
		url?: string | undefined,
	width?: number | undefined,
	height?: number | undefined,
	mimeType?: string | undefined,
	filesize?: number | undefined,
	filename?: string | undefined
};
	["mutationPrivateMedia_Sizes_PortraitInput"]: {
		url?: string | undefined,
	width?: number | undefined,
	height?: number | undefined,
	mimeType?: string | undefined,
	filesize?: number | undefined,
	filename?: string | undefined
};
	["mutationPrivateMedia_Sizes_HeroInput"]: {
		url?: string | undefined,
	width?: number | undefined,
	height?: number | undefined,
	mimeType?: string | undefined,
	filesize?: number | undefined,
	filename?: string | undefined
};
	["mutationPrivateMedia_Sizes_Thumbnail_jpegInput"]: {
		url?: string | undefined,
	width?: number | undefined,
	height?: number | undefined,
	mimeType?: string | undefined,
	filesize?: number | undefined,
	filename?: string | undefined
};
	["mutationPrivateMedia_Sizes_Portrait_jpegInput"]: {
		url?: string | undefined,
	width?: number | undefined,
	height?: number | undefined,
	mimeType?: string | undefined,
	filesize?: number | undefined,
	filename?: string | undefined
};
	["mutationPrivateMedia_Sizes_Hero_jpegInput"]: {
		url?: string | undefined,
	width?: number | undefined,
	height?: number | undefined,
	mimeType?: string | undefined,
	filesize?: number | undefined,
	filename?: string | undefined
};
	["mutationPrivateMediaUpdateInput"]: {
		alt?: string | undefined,
	updatedAt?: string | undefined,
	createdAt?: string | undefined,
	url?: string | undefined,
	filename?: string | undefined,
	mimeType?: string | undefined,
	filesize?: number | undefined,
	width?: number | undefined,
	height?: number | undefined,
	focalX?: number | undefined,
	focalY?: number | undefined,
	sizes?: GraphQLTypes["mutationPrivateMediaUpdate_SizesInput"] | undefined
};
	["mutationPrivateMediaUpdate_SizesInput"]: {
		thumbnail?: GraphQLTypes["mutationPrivateMediaUpdate_Sizes_ThumbnailInput"] | undefined,
	portrait?: GraphQLTypes["mutationPrivateMediaUpdate_Sizes_PortraitInput"] | undefined,
	hero?: GraphQLTypes["mutationPrivateMediaUpdate_Sizes_HeroInput"] | undefined,
	thumbnail_jpeg?: GraphQLTypes["mutationPrivateMediaUpdate_Sizes_Thumbnail_jpegInput"] | undefined,
	portrait_jpeg?: GraphQLTypes["mutationPrivateMediaUpdate_Sizes_Portrait_jpegInput"] | undefined,
	hero_jpeg?: GraphQLTypes["mutationPrivateMediaUpdate_Sizes_Hero_jpegInput"] | undefined
};
	["mutationPrivateMediaUpdate_Sizes_ThumbnailInput"]: {
		url?: string | undefined,
	width?: number | undefined,
	height?: number | undefined,
	mimeType?: string | undefined,
	filesize?: number | undefined,
	filename?: string | undefined
};
	["mutationPrivateMediaUpdate_Sizes_PortraitInput"]: {
		url?: string | undefined,
	width?: number | undefined,
	height?: number | undefined,
	mimeType?: string | undefined,
	filesize?: number | undefined,
	filename?: string | undefined
};
	["mutationPrivateMediaUpdate_Sizes_HeroInput"]: {
		url?: string | undefined,
	width?: number | undefined,
	height?: number | undefined,
	mimeType?: string | undefined,
	filesize?: number | undefined,
	filename?: string | undefined
};
	["mutationPrivateMediaUpdate_Sizes_Thumbnail_jpegInput"]: {
		url?: string | undefined,
	width?: number | undefined,
	height?: number | undefined,
	mimeType?: string | undefined,
	filesize?: number | undefined,
	filename?: string | undefined
};
	["mutationPrivateMediaUpdate_Sizes_Portrait_jpegInput"]: {
		url?: string | undefined,
	width?: number | undefined,
	height?: number | undefined,
	mimeType?: string | undefined,
	filesize?: number | undefined,
	filename?: string | undefined
};
	["mutationPrivateMediaUpdate_Sizes_Hero_jpegInput"]: {
		url?: string | undefined,
	width?: number | undefined,
	height?: number | undefined,
	mimeType?: string | undefined,
	filesize?: number | undefined,
	filename?: string | undefined
};
	["mutationMediaInput"]: {
		alt?: string | undefined,
	updatedAt?: string | undefined,
	createdAt?: string | undefined,
	url?: string | undefined,
	filename?: string | undefined,
	mimeType?: string | undefined,
	filesize?: number | undefined,
	width?: number | undefined,
	height?: number | undefined,
	focalX?: number | undefined,
	focalY?: number | undefined,
	sizes?: GraphQLTypes["mutationMedia_SizesInput"] | undefined
};
	["mutationMedia_SizesInput"]: {
		small?: GraphQLTypes["mutationMedia_Sizes_SmallInput"] | undefined,
	medium?: GraphQLTypes["mutationMedia_Sizes_MediumInput"] | undefined,
	large?: GraphQLTypes["mutationMedia_Sizes_LargeInput"] | undefined,
	small_jpeg?: GraphQLTypes["mutationMedia_Sizes_Small_jpegInput"] | undefined,
	medium_jpeg?: GraphQLTypes["mutationMedia_Sizes_Medium_jpegInput"] | undefined,
	large_jpeg?: GraphQLTypes["mutationMedia_Sizes_Large_jpegInput"] | undefined
};
	["mutationMedia_Sizes_SmallInput"]: {
		url?: string | undefined,
	width?: number | undefined,
	height?: number | undefined,
	mimeType?: string | undefined,
	filesize?: number | undefined,
	filename?: string | undefined
};
	["mutationMedia_Sizes_MediumInput"]: {
		url?: string | undefined,
	width?: number | undefined,
	height?: number | undefined,
	mimeType?: string | undefined,
	filesize?: number | undefined,
	filename?: string | undefined
};
	["mutationMedia_Sizes_LargeInput"]: {
		url?: string | undefined,
	width?: number | undefined,
	height?: number | undefined,
	mimeType?: string | undefined,
	filesize?: number | undefined,
	filename?: string | undefined
};
	["mutationMedia_Sizes_Small_jpegInput"]: {
		url?: string | undefined,
	width?: number | undefined,
	height?: number | undefined,
	mimeType?: string | undefined,
	filesize?: number | undefined,
	filename?: string | undefined
};
	["mutationMedia_Sizes_Medium_jpegInput"]: {
		url?: string | undefined,
	width?: number | undefined,
	height?: number | undefined,
	mimeType?: string | undefined,
	filesize?: number | undefined,
	filename?: string | undefined
};
	["mutationMedia_Sizes_Large_jpegInput"]: {
		url?: string | undefined,
	width?: number | undefined,
	height?: number | undefined,
	mimeType?: string | undefined,
	filesize?: number | undefined,
	filename?: string | undefined
};
	["mutationMediaUpdateInput"]: {
		alt?: string | undefined,
	updatedAt?: string | undefined,
	createdAt?: string | undefined,
	url?: string | undefined,
	filename?: string | undefined,
	mimeType?: string | undefined,
	filesize?: number | undefined,
	width?: number | undefined,
	height?: number | undefined,
	focalX?: number | undefined,
	focalY?: number | undefined,
	sizes?: GraphQLTypes["mutationMediaUpdate_SizesInput"] | undefined
};
	["mutationMediaUpdate_SizesInput"]: {
		small?: GraphQLTypes["mutationMediaUpdate_Sizes_SmallInput"] | undefined,
	medium?: GraphQLTypes["mutationMediaUpdate_Sizes_MediumInput"] | undefined,
	large?: GraphQLTypes["mutationMediaUpdate_Sizes_LargeInput"] | undefined,
	small_jpeg?: GraphQLTypes["mutationMediaUpdate_Sizes_Small_jpegInput"] | undefined,
	medium_jpeg?: GraphQLTypes["mutationMediaUpdate_Sizes_Medium_jpegInput"] | undefined,
	large_jpeg?: GraphQLTypes["mutationMediaUpdate_Sizes_Large_jpegInput"] | undefined
};
	["mutationMediaUpdate_Sizes_SmallInput"]: {
		url?: string | undefined,
	width?: number | undefined,
	height?: number | undefined,
	mimeType?: string | undefined,
	filesize?: number | undefined,
	filename?: string | undefined
};
	["mutationMediaUpdate_Sizes_MediumInput"]: {
		url?: string | undefined,
	width?: number | undefined,
	height?: number | undefined,
	mimeType?: string | undefined,
	filesize?: number | undefined,
	filename?: string | undefined
};
	["mutationMediaUpdate_Sizes_LargeInput"]: {
		url?: string | undefined,
	width?: number | undefined,
	height?: number | undefined,
	mimeType?: string | undefined,
	filesize?: number | undefined,
	filename?: string | undefined
};
	["mutationMediaUpdate_Sizes_Small_jpegInput"]: {
		url?: string | undefined,
	width?: number | undefined,
	height?: number | undefined,
	mimeType?: string | undefined,
	filesize?: number | undefined,
	filename?: string | undefined
};
	["mutationMediaUpdate_Sizes_Medium_jpegInput"]: {
		url?: string | undefined,
	width?: number | undefined,
	height?: number | undefined,
	mimeType?: string | undefined,
	filesize?: number | undefined,
	filename?: string | undefined
};
	["mutationMediaUpdate_Sizes_Large_jpegInput"]: {
		url?: string | undefined,
	width?: number | undefined,
	height?: number | undefined,
	mimeType?: string | undefined,
	filesize?: number | undefined,
	filename?: string | undefined
};
	["mutationBackupMediaInput"]: {
		alt?: string | undefined,
	creator?: string | undefined,
	updatedAt?: string | undefined,
	createdAt?: string | undefined,
	url?: string | undefined,
	filename?: string | undefined,
	mimeType?: string | undefined,
	filesize?: number | undefined,
	width?: number | undefined,
	height?: number | undefined,
	focalX?: number | undefined,
	focalY?: number | undefined,
	sizes?: GraphQLTypes["mutationBackupMedia_SizesInput"] | undefined
};
	["mutationBackupMedia_SizesInput"]: {
		thumbnail?: GraphQLTypes["mutationBackupMedia_Sizes_ThumbnailInput"] | undefined,
	sixteenByNineMedium?: GraphQLTypes["mutationBackupMedia_Sizes_SixteenByNineMediumInput"] | undefined
};
	["mutationBackupMedia_Sizes_ThumbnailInput"]: {
		url?: string | undefined,
	width?: number | undefined,
	height?: number | undefined,
	mimeType?: string | undefined,
	filesize?: number | undefined,
	filename?: string | undefined
};
	["mutationBackupMedia_Sizes_SixteenByNineMediumInput"]: {
		url?: string | undefined,
	width?: number | undefined,
	height?: number | undefined,
	mimeType?: string | undefined,
	filesize?: number | undefined,
	filename?: string | undefined
};
	["mutationBackupMediaUpdateInput"]: {
		alt?: string | undefined,
	creator?: string | undefined,
	updatedAt?: string | undefined,
	createdAt?: string | undefined,
	url?: string | undefined,
	filename?: string | undefined,
	mimeType?: string | undefined,
	filesize?: number | undefined,
	width?: number | undefined,
	height?: number | undefined,
	focalX?: number | undefined,
	focalY?: number | undefined,
	sizes?: GraphQLTypes["mutationBackupMediaUpdate_SizesInput"] | undefined
};
	["mutationBackupMediaUpdate_SizesInput"]: {
		thumbnail?: GraphQLTypes["mutationBackupMediaUpdate_Sizes_ThumbnailInput"] | undefined,
	sixteenByNineMedium?: GraphQLTypes["mutationBackupMediaUpdate_Sizes_SixteenByNineMediumInput"] | undefined
};
	["mutationBackupMediaUpdate_Sizes_ThumbnailInput"]: {
		url?: string | undefined,
	width?: number | undefined,
	height?: number | undefined,
	mimeType?: string | undefined,
	filesize?: number | undefined,
	filename?: string | undefined
};
	["mutationBackupMediaUpdate_Sizes_SixteenByNineMediumInput"]: {
		url?: string | undefined,
	width?: number | undefined,
	height?: number | undefined,
	mimeType?: string | undefined,
	filesize?: number | undefined,
	filename?: string | undefined
};
	["mutationAccountSuspendRequestInput"]: {
		user?: string | undefined,
	handledBy?: string | undefined,
	updatedAt?: string | undefined,
	createdAt?: string | undefined
};
	["mutationAccountSuspendRequestUpdateInput"]: {
		user?: string | undefined,
	handledBy?: string | undefined,
	updatedAt?: string | undefined,
	createdAt?: string | undefined
};
	["mutationDeviceInput"]: {
		user?: GraphQLTypes["Device_UserRelationshipInput"] | undefined,
	udid?: string | undefined,
	pushToken?: string | undefined,
	updatedAt?: string | undefined,
	createdAt?: string | undefined
};
	["Device_UserRelationshipInput"]: {
		relationTo?: GraphQLTypes["Device_UserRelationshipInputRelationTo"] | undefined,
	value?: GraphQLTypes["JSON"] | undefined
};
	["Device_UserRelationshipInputRelationTo"]: Device_UserRelationshipInputRelationTo;
	["mutationDeviceUpdateInput"]: {
		user?: GraphQLTypes["DeviceUpdate_UserRelationshipInput"] | undefined,
	udid?: string | undefined,
	pushToken?: string | undefined,
	updatedAt?: string | undefined,
	createdAt?: string | undefined
};
	["DeviceUpdate_UserRelationshipInput"]: {
		relationTo?: GraphQLTypes["DeviceUpdate_UserRelationshipInputRelationTo"] | undefined,
	value?: GraphQLTypes["JSON"] | undefined
};
	["DeviceUpdate_UserRelationshipInputRelationTo"]: DeviceUpdate_UserRelationshipInputRelationTo;
	["mutationNotificationInput"]: {
		receiver?: Array<string | undefined> | undefined,
	type?: GraphQLTypes["Notification_type_MutationInput"] | undefined,
	meta?: GraphQLTypes["Notification_MetaRelationshipInput"] | undefined,
	message?: string | undefined,
	sender?: string | undefined,
	readed?: boolean | undefined,
	deletedByUser?: boolean | undefined,
	updatedAt?: string | undefined,
	createdAt?: string | undefined
};
	["Notification_type_MutationInput"]: Notification_type_MutationInput;
	["Notification_MetaRelationshipInput"]: {
		relationTo?: GraphQLTypes["Notification_MetaRelationshipInputRelationTo"] | undefined,
	value?: GraphQLTypes["JSON"] | undefined
};
	["Notification_MetaRelationshipInputRelationTo"]: Notification_MetaRelationshipInputRelationTo;
	["mutationNotificationUpdateInput"]: {
		receiver?: Array<string | undefined> | undefined,
	type?: GraphQLTypes["NotificationUpdate_type_MutationInput"] | undefined,
	meta?: GraphQLTypes["NotificationUpdate_MetaRelationshipInput"] | undefined,
	message?: string | undefined,
	sender?: string | undefined,
	readed?: boolean | undefined,
	deletedByUser?: boolean | undefined,
	updatedAt?: string | undefined,
	createdAt?: string | undefined
};
	["NotificationUpdate_type_MutationInput"]: NotificationUpdate_type_MutationInput;
	["NotificationUpdate_MetaRelationshipInput"]: {
		relationTo?: GraphQLTypes["NotificationUpdate_MetaRelationshipInputRelationTo"] | undefined,
	value?: GraphQLTypes["JSON"] | undefined
};
	["NotificationUpdate_MetaRelationshipInputRelationTo"]: NotificationUpdate_MetaRelationshipInputRelationTo;
	["mutationRedirectSettingInput"]: {
		from?: string | undefined,
	to?: string | undefined,
	updatedAt?: string | undefined,
	createdAt?: string | undefined
};
	["mutationRedirectSettingUpdateInput"]: {
		from?: string | undefined,
	to?: string | undefined,
	updatedAt?: string | undefined,
	createdAt?: string | undefined
};
	["mutationEventRankingInput"]: {
		name?: string | undefined,
	visible?: boolean | undefined,
	maxItems: number,
	template: GraphQLTypes["EventRanking_template_MutationInput"],
	list?: Array<GraphQLTypes["mutationEventRanking_ListInput"] | undefined> | undefined,
	region?: GraphQLTypes["EventRanking_region_MutationInput"] | undefined,
	updatedAt?: string | undefined,
	createdAt?: string | undefined
};
	["EventRanking_template_MutationInput"]: EventRanking_template_MutationInput;
	["mutationEventRanking_ListInput"]: {
		item?: string | undefined,
	id?: string | undefined
};
	["EventRanking_region_MutationInput"]: EventRanking_region_MutationInput;
	["mutationEventRankingUpdateInput"]: {
		name?: string | undefined,
	visible?: boolean | undefined,
	maxItems?: number | undefined,
	template?: GraphQLTypes["EventRankingUpdate_template_MutationInput"] | undefined,
	list?: Array<GraphQLTypes["mutationEventRankingUpdate_ListInput"] | undefined> | undefined,
	region?: GraphQLTypes["EventRankingUpdate_region_MutationInput"] | undefined,
	updatedAt?: string | undefined,
	createdAt?: string | undefined
};
	["EventRankingUpdate_template_MutationInput"]: EventRankingUpdate_template_MutationInput;
	["mutationEventRankingUpdate_ListInput"]: {
		item?: string | undefined,
	id?: string | undefined
};
	["EventRankingUpdate_region_MutationInput"]: EventRankingUpdate_region_MutationInput;
	["mutationLocationRankingInput"]: {
		name?: string | undefined,
	visible?: boolean | undefined,
	maxItems?: number | undefined,
	template: GraphQLTypes["LocationRanking_template_MutationInput"],
	list?: Array<GraphQLTypes["mutationLocationRanking_ListInput"] | undefined> | undefined,
	region?: GraphQLTypes["LocationRanking_region_MutationInput"] | undefined,
	updatedAt?: string | undefined,
	createdAt?: string | undefined
};
	["LocationRanking_template_MutationInput"]: LocationRanking_template_MutationInput;
	["mutationLocationRanking_ListInput"]: {
		item?: string | undefined,
	id?: string | undefined
};
	["LocationRanking_region_MutationInput"]: LocationRanking_region_MutationInput;
	["mutationLocationRankingUpdateInput"]: {
		name?: string | undefined,
	visible?: boolean | undefined,
	maxItems?: number | undefined,
	template?: GraphQLTypes["LocationRankingUpdate_template_MutationInput"] | undefined,
	list?: Array<GraphQLTypes["mutationLocationRankingUpdate_ListInput"] | undefined> | undefined,
	region?: GraphQLTypes["LocationRankingUpdate_region_MutationInput"] | undefined,
	updatedAt?: string | undefined,
	createdAt?: string | undefined
};
	["LocationRankingUpdate_template_MutationInput"]: LocationRankingUpdate_template_MutationInput;
	["mutationLocationRankingUpdate_ListInput"]: {
		item?: string | undefined,
	id?: string | undefined
};
	["LocationRankingUpdate_region_MutationInput"]: LocationRankingUpdate_region_MutationInput;
	["mutationEventCalendarInput"]: {
		date: string,
	item?: string | undefined,
	title?: string | undefined,
	region?: GraphQLTypes["EventCalendar_region_MutationInput"] | undefined,
	updatedAt?: string | undefined,
	createdAt?: string | undefined
};
	["EventCalendar_region_MutationInput"]: EventCalendar_region_MutationInput;
	["mutationEventCalendarUpdateInput"]: {
		date?: string | undefined,
	item?: string | undefined,
	title?: string | undefined,
	region?: GraphQLTypes["EventCalendarUpdate_region_MutationInput"] | undefined,
	updatedAt?: string | undefined,
	createdAt?: string | undefined
};
	["EventCalendarUpdate_region_MutationInput"]: EventCalendarUpdate_region_MutationInput;
	["mutationEditorChoiceInput"]: {
		name?: string | undefined,
	visible?: boolean | undefined,
	editorialPicks?: Array<string | undefined> | undefined,
	list?: Array<GraphQLTypes["mutationEditorChoice_ListInput"] | undefined> | undefined,
	region?: GraphQLTypes["EditorChoice_region_MutationInput"] | undefined,
	updatedAt?: string | undefined,
	createdAt?: string | undefined
};
	["mutationEditorChoice_ListInput"]: {
		item?: string | undefined,
	id?: string | undefined
};
	["EditorChoice_region_MutationInput"]: EditorChoice_region_MutationInput;
	["mutationEditorChoiceUpdateInput"]: {
		name?: string | undefined,
	visible?: boolean | undefined,
	editorialPicks?: Array<string | undefined> | undefined,
	list?: Array<GraphQLTypes["mutationEditorChoiceUpdate_ListInput"] | undefined> | undefined,
	region?: GraphQLTypes["EditorChoiceUpdate_region_MutationInput"] | undefined,
	updatedAt?: string | undefined,
	createdAt?: string | undefined
};
	["mutationEditorChoiceUpdate_ListInput"]: {
		item?: string | undefined,
	id?: string | undefined
};
	["EditorChoiceUpdate_region_MutationInput"]: EditorChoiceUpdate_region_MutationInput;
	["mutationCriteriaSettingInput"]: {
		criteria?: GraphQLTypes["mutationCriteriaSetting_CriteriaInput"] | undefined,
	updatedAt?: string | undefined,
	createdAt?: string | undefined
};
	["mutationCriteriaSetting_CriteriaInput"]: {
		date?: Array<string | undefined> | undefined,
	audience?: Array<string | undefined> | undefined,
	category?: Array<string | undefined> | undefined,
	tag?: Array<string | undefined> | undefined,
	region?: GraphQLTypes["CriteriaSetting_Criteria_region_MutationInput"] | undefined
};
	["CriteriaSetting_Criteria_region_MutationInput"]: CriteriaSetting_Criteria_region_MutationInput;
	["mutationCriteriaSettingUpdateInput"]: {
		criteria?: GraphQLTypes["mutationCriteriaSettingUpdate_CriteriaInput"] | undefined,
	updatedAt?: string | undefined,
	createdAt?: string | undefined
};
	["mutationCriteriaSettingUpdate_CriteriaInput"]: {
		date?: Array<string | undefined> | undefined,
	audience?: Array<string | undefined> | undefined,
	category?: Array<string | undefined> | undefined,
	tag?: Array<string | undefined> | undefined,
	region?: GraphQLTypes["CriteriaSettingUpdate_Criteria_region_MutationInput"] | undefined
};
	["CriteriaSettingUpdate_Criteria_region_MutationInput"]: CriteriaSettingUpdate_Criteria_region_MutationInput;
	["mutationOrganiserSettingInput"]: {
		name?: string | undefined,
	organiserList?: Array<string | undefined> | undefined,
	region?: GraphQLTypes["OrganiserSetting_region_MutationInput"] | undefined,
	updatedAt?: string | undefined,
	createdAt?: string | undefined
};
	["OrganiserSetting_region_MutationInput"]: OrganiserSetting_region_MutationInput;
	["mutationOrganiserSettingUpdateInput"]: {
		name?: string | undefined,
	organiserList?: Array<string | undefined> | undefined,
	region?: GraphQLTypes["OrganiserSettingUpdate_region_MutationInput"] | undefined,
	updatedAt?: string | undefined,
	createdAt?: string | undefined
};
	["OrganiserSettingUpdate_region_MutationInput"]: OrganiserSettingUpdate_region_MutationInput;
	["mutationPostSettingInput"]: {
		name?: string | undefined,
	featuredPosts?: Array<string | undefined> | undefined,
	region?: GraphQLTypes["PostSetting_region_MutationInput"] | undefined,
	updatedAt?: string | undefined,
	createdAt?: string | undefined
};
	["PostSetting_region_MutationInput"]: PostSetting_region_MutationInput;
	["mutationPostSettingUpdateInput"]: {
		name?: string | undefined,
	featuredPosts?: Array<string | undefined> | undefined,
	region?: GraphQLTypes["PostSettingUpdate_region_MutationInput"] | undefined,
	updatedAt?: string | undefined,
	createdAt?: string | undefined
};
	["PostSettingUpdate_region_MutationInput"]: PostSettingUpdate_region_MutationInput;
	["mutationHomepageSettingInput"]: {
		name?: string | undefined,
	region?: GraphQLTypes["HomepageSetting_region_MutationInput"] | undefined,
	sections?: GraphQLTypes["JSON"] | undefined,
	updatedAt?: string | undefined,
	createdAt?: string | undefined
};
	["HomepageSetting_region_MutationInput"]: HomepageSetting_region_MutationInput;
	["mutationHomepageSettingUpdateInput"]: {
		name?: string | undefined,
	region?: GraphQLTypes["HomepageSettingUpdate_region_MutationInput"] | undefined,
	sections?: GraphQLTypes["JSON"] | undefined,
	updatedAt?: string | undefined,
	createdAt?: string | undefined
};
	["HomepageSettingUpdate_region_MutationInput"]: HomepageSettingUpdate_region_MutationInput;
	["mutationBannerSectionInput"]: {
		name?: string | undefined,
	slug?: string | undefined,
	banners?: Array<GraphQLTypes["mutationBannerSection_BannersInput"] | undefined> | undefined,
	updatedAt?: string | undefined,
	createdAt?: string | undefined
};
	["mutationBannerSection_BannersInput"]: {
		name?: string | undefined,
	status?: string | undefined,
	desktop: string,
	bannerStartDate?: string | undefined,
	bannerEndDate?: string | undefined,
	region?: GraphQLTypes["BannerSection_Banners_region_MutationInput"] | undefined,
	id?: string | undefined
};
	["BannerSection_Banners_region_MutationInput"]: BannerSection_Banners_region_MutationInput;
	["mutationBannerSectionUpdateInput"]: {
		name?: string | undefined,
	slug?: string | undefined,
	banners?: Array<GraphQLTypes["mutationBannerSectionUpdate_BannersInput"] | undefined> | undefined,
	updatedAt?: string | undefined,
	createdAt?: string | undefined
};
	["mutationBannerSectionUpdate_BannersInput"]: {
		name?: string | undefined,
	status?: string | undefined,
	desktop: string,
	bannerStartDate?: string | undefined,
	bannerEndDate?: string | undefined,
	region?: GraphQLTypes["BannerSectionUpdate_Banners_region_MutationInput"] | undefined,
	id?: string | undefined
};
	["BannerSectionUpdate_Banners_region_MutationInput"]: BannerSectionUpdate_Banners_region_MutationInput;
	["mutationMacaoBannerSectionInput"]: {
		name?: string | undefined,
	slug?: string | undefined,
	banners?: Array<GraphQLTypes["mutationMacaoBannerSection_BannersInput"] | undefined> | undefined,
	updatedAt?: string | undefined,
	createdAt?: string | undefined
};
	["mutationMacaoBannerSection_BannersInput"]: {
		name?: string | undefined,
	status?: string | undefined,
	desktop: string,
	bannerStartDate?: string | undefined,
	bannerEndDate?: string | undefined,
	region?: GraphQLTypes["MacaoBannerSection_Banners_region_MutationInput"] | undefined,
	id?: string | undefined
};
	["MacaoBannerSection_Banners_region_MutationInput"]: MacaoBannerSection_Banners_region_MutationInput;
	["mutationMacaoBannerSectionUpdateInput"]: {
		name?: string | undefined,
	slug?: string | undefined,
	banners?: Array<GraphQLTypes["mutationMacaoBannerSectionUpdate_BannersInput"] | undefined> | undefined,
	updatedAt?: string | undefined,
	createdAt?: string | undefined
};
	["mutationMacaoBannerSectionUpdate_BannersInput"]: {
		name?: string | undefined,
	status?: string | undefined,
	desktop: string,
	bannerStartDate?: string | undefined,
	bannerEndDate?: string | undefined,
	region?: GraphQLTypes["MacaoBannerSectionUpdate_Banners_region_MutationInput"] | undefined,
	id?: string | undefined
};
	["MacaoBannerSectionUpdate_Banners_region_MutationInput"]: MacaoBannerSectionUpdate_Banners_region_MutationInput;
	["mutationBannerInput"]: {
		name: string,
	desktop: string,
	mobile: string,
	bannerStartDate: string,
	bannerEndDate: string,
	location?: GraphQLTypes["mutationBanner_LocationInput"] | undefined,
	link?: string | undefined,
	external?: boolean | undefined,
	type?: GraphQLTypes["Banner_type_MutationInput"] | undefined,
	status?: GraphQLTypes["Banner_status_MutationInput"] | undefined,
	advertise: GraphQLTypes["mutationBanner_AdvertiseInput"],
	_locations?: string | undefined,
	region?: GraphQLTypes["Banner_region_MutationInput"] | undefined,
	homePageClick?: number | undefined,
	homePageImpression?: number | undefined,
	latestEventClick?: number | undefined,
	latestEventImpression?: number | undefined,
	editorChoiceClick?: number | undefined,
	editorChoiceImpression?: number | undefined,
	eventListClick?: number | undefined,
	eventListImpression?: number | undefined,
	topTenClick?: number | undefined,
	topTenImpression?: number | undefined,
	miscClick?: number | undefined,
	miscImpression?: number | undefined,
	clickTotal?: number | undefined,
	totalImpression?: number | undefined,
	updatedAt?: string | undefined,
	createdAt?: string | undefined
};
	["mutationBanner_LocationInput"]: {
		page?: Array<GraphQLTypes["Banner_Location_page_MutationInput"] | undefined> | undefined,
	eventRankingList?: Array<string | undefined> | undefined,
	locationRankingList?: Array<string | undefined> | undefined,
	categories?: Array<string | undefined> | undefined,
	time?: boolean | undefined,
	district?: boolean | undefined,
	audience?: boolean | undefined,
	multipleCriteriaSearch?: boolean | undefined
};
	["Banner_Location_page_MutationInput"]: Banner_Location_page_MutationInput;
	["Banner_type_MutationInput"]: Banner_type_MutationInput;
	["Banner_status_MutationInput"]: Banner_status_MutationInput;
	["mutationBanner_AdvertiseInput"]: {
		campaign?: string | undefined,
	invoiceDescription?: string | undefined,
	standardPrice: number,
	discount?: number | undefined,
	timableCost?: number | undefined,
	remark?: string | undefined,
	public?: boolean | undefined
};
	["Banner_region_MutationInput"]: Banner_region_MutationInput;
	["mutationBannerUpdateInput"]: {
		name?: string | undefined,
	desktop?: string | undefined,
	mobile?: string | undefined,
	bannerStartDate?: string | undefined,
	bannerEndDate?: string | undefined,
	location?: GraphQLTypes["mutationBannerUpdate_LocationInput"] | undefined,
	link?: string | undefined,
	external?: boolean | undefined,
	type?: GraphQLTypes["BannerUpdate_type_MutationInput"] | undefined,
	status?: GraphQLTypes["BannerUpdate_status_MutationInput"] | undefined,
	advertise: GraphQLTypes["mutationBannerUpdate_AdvertiseInput"],
	_locations?: string | undefined,
	region?: GraphQLTypes["BannerUpdate_region_MutationInput"] | undefined,
	homePageClick?: number | undefined,
	homePageImpression?: number | undefined,
	latestEventClick?: number | undefined,
	latestEventImpression?: number | undefined,
	editorChoiceClick?: number | undefined,
	editorChoiceImpression?: number | undefined,
	eventListClick?: number | undefined,
	eventListImpression?: number | undefined,
	topTenClick?: number | undefined,
	topTenImpression?: number | undefined,
	miscClick?: number | undefined,
	miscImpression?: number | undefined,
	clickTotal?: number | undefined,
	totalImpression?: number | undefined,
	updatedAt?: string | undefined,
	createdAt?: string | undefined
};
	["mutationBannerUpdate_LocationInput"]: {
		page?: Array<GraphQLTypes["BannerUpdate_Location_page_MutationInput"] | undefined> | undefined,
	eventRankingList?: Array<string | undefined> | undefined,
	locationRankingList?: Array<string | undefined> | undefined,
	categories?: Array<string | undefined> | undefined,
	time?: boolean | undefined,
	district?: boolean | undefined,
	audience?: boolean | undefined,
	multipleCriteriaSearch?: boolean | undefined
};
	["BannerUpdate_Location_page_MutationInput"]: BannerUpdate_Location_page_MutationInput;
	["BannerUpdate_type_MutationInput"]: BannerUpdate_type_MutationInput;
	["BannerUpdate_status_MutationInput"]: BannerUpdate_status_MutationInput;
	["mutationBannerUpdate_AdvertiseInput"]: {
		campaign?: string | undefined,
	invoiceDescription?: string | undefined,
	standardPrice: number,
	discount?: number | undefined,
	timableCost?: number | undefined,
	remark?: string | undefined,
	public?: boolean | undefined
};
	["BannerUpdate_region_MutationInput"]: BannerUpdate_region_MutationInput;
	["mutationCampaignInput"]: {
		creator?: GraphQLTypes["Campaign_CreatorRelationshipInput"] | undefined,
	invoiceNumber?: string | undefined,
	quotationNumber?: string | undefined,
	campaignName: string,
	client: string,
	contactPerson: string,
	email: string,
	phone: string,
	agentName?: string | undefined,
	agentCommission?: number | undefined,
	agentOrderNumber?: string | undefined,
	_firstPublishedTime?: string | undefined,
	updatedAt?: string | undefined,
	createdAt?: string | undefined
};
	["Campaign_CreatorRelationshipInput"]: {
		relationTo?: GraphQLTypes["Campaign_CreatorRelationshipInputRelationTo"] | undefined,
	value?: GraphQLTypes["JSON"] | undefined
};
	["Campaign_CreatorRelationshipInputRelationTo"]: Campaign_CreatorRelationshipInputRelationTo;
	["mutationCampaignUpdateInput"]: {
		creator?: GraphQLTypes["CampaignUpdate_CreatorRelationshipInput"] | undefined,
	invoiceNumber?: string | undefined,
	quotationNumber?: string | undefined,
	campaignName?: string | undefined,
	client?: string | undefined,
	contactPerson?: string | undefined,
	email?: string | undefined,
	phone?: string | undefined,
	agentName?: string | undefined,
	agentCommission?: number | undefined,
	agentOrderNumber?: string | undefined,
	_firstPublishedTime?: string | undefined,
	updatedAt?: string | undefined,
	createdAt?: string | undefined
};
	["CampaignUpdate_CreatorRelationshipInput"]: {
		relationTo?: GraphQLTypes["CampaignUpdate_CreatorRelationshipInputRelationTo"] | undefined,
	value?: GraphQLTypes["JSON"] | undefined
};
	["CampaignUpdate_CreatorRelationshipInputRelationTo"]: CampaignUpdate_CreatorRelationshipInputRelationTo;
	["mutationAdvertiseInput"]: {
		advertise: GraphQLTypes["mutationAdvertise_AdvertiseInput"],
	advertiseStartDate: string,
	advertiseEndDate: string,
	type?: GraphQLTypes["Advertise_type_MutationInput"] | undefined,
	invoiceDescription?: string | undefined,
	standardPrice: number,
	discount?: number | undefined,
	discountPercentage?: number | undefined,
	_calculateByDiscount?: boolean | undefined,
	timableCost?: number | undefined,
	remark?: string | undefined,
	public?: boolean | undefined,
	campaignName?: string | undefined,
	campaignId?: string | undefined,
	status?: string | undefined,
	updatedAt?: string | undefined,
	createdAt?: string | undefined
};
	["mutationAdvertise_AdvertiseInput"]: {
		type?: GraphQLTypes["Advertise_Advertise_type_MutationInput"] | undefined,
	event?: string | undefined,
	keyword?: string | undefined,
	location?: string | undefined,
	displayPosition?: string | undefined,
	rankingListRemark?: string | undefined
};
	["Advertise_Advertise_type_MutationInput"]: Advertise_Advertise_type_MutationInput;
	["Advertise_type_MutationInput"]: Advertise_type_MutationInput;
	["mutationAdvertiseUpdateInput"]: {
		advertise: GraphQLTypes["mutationAdvertiseUpdate_AdvertiseInput"],
	advertiseStartDate?: string | undefined,
	advertiseEndDate?: string | undefined,
	type?: GraphQLTypes["AdvertiseUpdate_type_MutationInput"] | undefined,
	invoiceDescription?: string | undefined,
	standardPrice?: number | undefined,
	discount?: number | undefined,
	discountPercentage?: number | undefined,
	_calculateByDiscount?: boolean | undefined,
	timableCost?: number | undefined,
	remark?: string | undefined,
	public?: boolean | undefined,
	campaignName?: string | undefined,
	campaignId?: string | undefined,
	status?: string | undefined,
	updatedAt?: string | undefined,
	createdAt?: string | undefined
};
	["mutationAdvertiseUpdate_AdvertiseInput"]: {
		type?: GraphQLTypes["AdvertiseUpdate_Advertise_type_MutationInput"] | undefined,
	event?: string | undefined,
	keyword?: string | undefined,
	location?: string | undefined,
	displayPosition?: string | undefined,
	rankingListRemark?: string | undefined
};
	["AdvertiseUpdate_Advertise_type_MutationInput"]: AdvertiseUpdate_Advertise_type_MutationInput;
	["AdvertiseUpdate_type_MutationInput"]: AdvertiseUpdate_type_MutationInput;
	["mutationStaticInput"]: {
		name?: string | undefined,
	key?: string | undefined,
	content?: GraphQLTypes["JSON"] | undefined,
	region?: GraphQLTypes["Static_region_MutationInput"] | undefined,
	updatedAt?: string | undefined,
	createdAt?: string | undefined
};
	["Static_region_MutationInput"]: Static_region_MutationInput;
	["mutationStaticUpdateInput"]: {
		name?: string | undefined,
	key?: string | undefined,
	content?: GraphQLTypes["JSON"] | undefined,
	region?: GraphQLTypes["StaticUpdate_region_MutationInput"] | undefined,
	updatedAt?: string | undefined,
	createdAt?: string | undefined
};
	["StaticUpdate_region_MutationInput"]: StaticUpdate_region_MutationInput;
	["mutationEventViewInput"]: {
		date: string,
	eventId?: string | undefined,
	viewCount: number,
	updatedAt?: string | undefined,
	createdAt?: string | undefined
};
	["mutationEventViewUpdateInput"]: {
		date?: string | undefined,
	eventId?: string | undefined,
	viewCount?: number | undefined,
	updatedAt?: string | undefined,
	createdAt?: string | undefined
};
	["mutationPostViewInput"]: {
		date: string,
	postId?: string | undefined,
	viewCount: number,
	updatedAt?: string | undefined,
	createdAt?: string | undefined
};
	["mutationPostViewUpdateInput"]: {
		date?: string | undefined,
	postId?: string | undefined,
	viewCount?: number | undefined,
	updatedAt?: string | undefined,
	createdAt?: string | undefined
};
	["mutationPayloadPreferenceInput"]: {
		user?: GraphQLTypes["PayloadPreference_UserRelationshipInput"] | undefined,
	key?: string | undefined,
	value?: GraphQLTypes["JSON"] | undefined,
	updatedAt?: string | undefined,
	createdAt?: string | undefined
};
	["PayloadPreference_UserRelationshipInput"]: {
		relationTo?: GraphQLTypes["PayloadPreference_UserRelationshipInputRelationTo"] | undefined,
	value?: GraphQLTypes["JSON"] | undefined
};
	["PayloadPreference_UserRelationshipInputRelationTo"]: PayloadPreference_UserRelationshipInputRelationTo;
	["mutationPayloadPreferenceUpdateInput"]: {
		user?: GraphQLTypes["PayloadPreferenceUpdate_UserRelationshipInput"] | undefined,
	key?: string | undefined,
	value?: GraphQLTypes["JSON"] | undefined,
	updatedAt?: string | undefined,
	createdAt?: string | undefined
};
	["PayloadPreferenceUpdate_UserRelationshipInput"]: {
		relationTo?: GraphQLTypes["PayloadPreferenceUpdate_UserRelationshipInputRelationTo"] | undefined,
	value?: GraphQLTypes["JSON"] | undefined
};
	["PayloadPreferenceUpdate_UserRelationshipInputRelationTo"]: PayloadPreferenceUpdate_UserRelationshipInputRelationTo;
	["ChatbotInventoryPayment"]: {
	__typename: "ChatbotInventoryPayment",
	expiredAt?: string | undefined
};
	["ChatbotTransactionPdfGet"]: {
	__typename: "ChatbotTransactionPdfGet",
	url?: string | undefined
};
	["WebAppQrCodeBatchScanResult"]: {
	__typename: "WebAppQrCodeBatchScanResult",
	result?: Array<GraphQLTypes["WebAppQrCodeScanResponse"] | undefined> | undefined
};
	["WebAppQrCodeScanResponse"]: {
	__typename: "WebAppQrCodeScanResponse",
	status?: string | undefined,
	qrCodeId?: string | undefined,
	name?: string | undefined,
	phone?: string | undefined,
	scanAt?: string | undefined,
	uploadAt?: string | undefined,
	failReason?: string | undefined
};
	["WebAppQrCodeBatchScanItem"]: {
		checkInAppSelectionId?: string | undefined,
	checkInAppSelectionProductType?: string | undefined,
	qrCodeId?: string | undefined,
	scanAt?: string | undefined,
	entryNumber?: string | undefined
}
    }
export const enum Event_region {
	hk = "hk",
	mo = "mo"
}
export const enum User_region {
	hk = "hk",
	mo = "mo"
}
export const enum User_roles {
	admin = "admin",
	member = "member",
	organiser = "organiser"
}
export const enum User_Member_region {
	hk = "hk",
	mo = "mo"
}
export const enum User_Member_language {
	zh = "zh",
	cn = "cn",
	en = "en"
}
export const enum LocaleInputType {
	zh = "zh",
	cn = "cn",
	en = "en"
}
export const enum FallbackLocaleInputType {
	zh = "zh",
	cn = "cn",
	en = "en",
	none = "none"
}
export const enum User_Member_gender {
	unSelected = "unSelected",
	female = "female",
	male = "male",
	transgender = "transgender"
}
export const enum Tag_region {
	hk = "hk",
	mo = "mo"
}
export const enum EventCategory_region {
	hk = "hk",
	mo = "mo"
}
export const enum User_Organiser_language {
	zh = "zh",
	cn = "cn",
	en = "en"
}
export const enum User_status {
	active = "active",
	inactive = "inactive"
}
export const enum Event_level {
	light = "light",
	standard = "standard",
	featured = "featured",
	premium = "premium"
}
export const enum Event_Sections_Recurrance_type {
	daily = "daily",
	weekdays = "weekdays",
	weekend = "weekend",
	custom = "custom"
}
export const enum Event_Sections_Recurrance_weekday {
	sunday = "sunday",
	monday = "monday",
	tuesday = "tuesday",
	wednesday = "wednesday",
	thursday = "thursday",
	friday = "friday",
	saturday = "saturday"
}
export const enum Location_region {
	hk = "hk",
	mo = "mo"
}
export const enum Location_level {
	light = "light",
	standard = "standard",
	featured = "featured",
	premium = "premium"
}
export const enum Location_type {
	virtual = "virtual",
	physical = "physical",
	landmark = "landmark"
}
export const enum Location_category {
	venue = "venue",
	restaurant = "restaurant"
}
export const enum District_region {
	hk = "hk",
	mo = "mo"
}
export const enum Audience_region {
	hk = "hk",
	mo = "mo"
}
export const enum Date_type {
	fixed = "fixed",
	logic = "logic"
}
export const enum Date_region {
	hk = "hk",
	mo = "mo"
}
export const enum Location_Sections_Recurrance_type {
	daily = "daily",
	weekdays = "weekdays",
	weekend = "weekend",
	custom = "custom"
}
export const enum Location_Sections_Recurrance_weekday {
	sunday = "sunday",
	monday = "monday",
	tuesday = "tuesday",
	wednesday = "wednesday",
	thursday = "thursday",
	friday = "friday",
	saturday = "saturday"
}
export const enum Event_Ticketing_type {
	ticketing = "ticketing",
	promotion = "promotion"
}
export const enum Event_Ticketing_ticketingType {
	public = "public",
	additionalShow = "additionalShow",
	memberOrder = "memberOrder",
	preOrder = "preOrder",
	creditCard = "creditCard",
	pointsExchange = "pointsExchange",
	tradingPlatform = "tradingPlatform",
	timable = "timable"
}
export const enum Ticketing_status {
	published = "published",
	rejected = "rejected",
	review = "review",
	private = "private"
}
export const enum Ticketing_productType {
	product = "product",
	ticketing = "ticketing"
}
export const enum Ticketing_CheckInLevel {
	one = "one",
	two = "two"
}
export const enum Event_Ticketing_promotionType {
	lottery = "lottery",
	signUp = "signUp"
}
export const enum SpecialTag_region {
	hk = "hk",
	mo = "mo"
}
export const enum Product_status {
	published = "published",
	rejected = "rejected",
	review = "review",
	private = "private"
}
export const enum Product_productType {
	product = "product",
	ticketing = "ticketing"
}
export const enum Product_CheckInLevel {
	one = "one"
}
export const enum Product_transactionType {
	affiliate = "affiliate",
	priceTree = "priceTree"
}
export const enum Event_region_Input {
	hk = "hk",
	mo = "mo"
}
export const enum Event_level_Input {
	light = "light",
	standard = "standard",
	featured = "featured",
	premium = "premium"
}
export const enum Event_sections__recurrance__type_Input {
	daily = "daily",
	weekdays = "weekdays",
	weekend = "weekend",
	custom = "custom"
}
export const enum Event_sections__recurrance__weekday_Input {
	sunday = "sunday",
	monday = "monday",
	tuesday = "tuesday",
	wednesday = "wednesday",
	thursday = "thursday",
	friday = "friday",
	saturday = "saturday"
}
export const enum Event_ticketing__type_Input {
	ticketing = "ticketing",
	promotion = "promotion"
}
export const enum Event_ticketing__ticketingType_Input {
	public = "public",
	additionalShow = "additionalShow",
	memberOrder = "memberOrder",
	preOrder = "preOrder",
	creditCard = "creditCard",
	pointsExchange = "pointsExchange",
	tradingPlatform = "tradingPlatform",
	timable = "timable"
}
export const enum Event_ticketing__promotionType_Input {
	lottery = "lottery",
	signUp = "signUp"
}
export const enum Location_region_Input {
	hk = "hk",
	mo = "mo"
}
export const enum Location_level_Input {
	light = "light",
	standard = "standard",
	featured = "featured",
	premium = "premium"
}
export const enum Location_type_Input {
	virtual = "virtual",
	physical = "physical",
	landmark = "landmark"
}
export const enum Location_category_Input {
	venue = "venue",
	restaurant = "restaurant"
}
export const enum Location_sections__recurrance__type_Input {
	daily = "daily",
	weekdays = "weekdays",
	weekend = "weekend",
	custom = "custom"
}
export const enum Location_sections__recurrance__weekday_Input {
	sunday = "sunday",
	monday = "monday",
	tuesday = "tuesday",
	wednesday = "wednesday",
	thursday = "thursday",
	friday = "friday",
	saturday = "saturday"
}
export const enum Post_region {
	hk = "hk",
	mo = "mo"
}
export const enum BlogCategory_region {
	hk = "hk",
	mo = "mo"
}
export const enum Post_region_Input {
	hk = "hk",
	mo = "mo"
}
export const enum Ticketing_status_Input {
	published = "published",
	rejected = "rejected",
	review = "review",
	private = "private"
}
export const enum Ticketing_productType_Input {
	product = "product",
	ticketing = "ticketing"
}
export const enum Ticketing_CheckInLevel_Input {
	one = "one",
	two = "two"
}
export const enum Product_status_Input {
	published = "published",
	rejected = "rejected",
	review = "review",
	private = "private"
}
export const enum Product_productType_Input {
	product = "product",
	ticketing = "ticketing"
}
export const enum Product_CheckInLevel_Input {
	one = "one"
}
export const enum Product_transactionType_Input {
	affiliate = "affiliate",
	priceTree = "priceTree"
}
export const enum InventoryStatus_productType {
	product = "product",
	ticketing = "ticketing"
}
export const enum InventoryStatus_stockUpdateStatus {
	remaining = "remaining",
	sold = "sold",
	onHold = "onHold",
	checkedIn = "checkedIn",
	processingPayment = "processingPayment"
}
export const enum InventoryStatus_productType_Input {
	product = "product",
	ticketing = "ticketing"
}
export const enum InventoryStatus_stockUpdateStatus_Input {
	remaining = "remaining",
	sold = "sold",
	onHold = "onHold",
	checkedIn = "checkedIn",
	processingPayment = "processingPayment"
}
export const enum RedemptionCode_status {
	checkedIn = "checkedIn",
	activeToUse = "activeToUse",
	refunded = "refunded",
	voided = "voided",
	processingPayment = "processingPayment"
}
export const enum RedemptionCode_productType {
	product = "product",
	ticketing = "ticketing"
}
export const enum RedemptionCode_status_Input {
	checkedIn = "checkedIn",
	activeToUse = "activeToUse",
	refunded = "refunded",
	voided = "voided",
	processingPayment = "processingPayment"
}
export const enum RedemptionCode_productType_Input {
	product = "product",
	ticketing = "ticketing"
}
export const enum CheckInRedemption_productType {
	product = "product",
	ticketing = "ticketing"
}
export const enum CheckInRedemption_productType_Input {
	product = "product",
	ticketing = "ticketing"
}
export const enum User_region_Input {
	hk = "hk",
	mo = "mo"
}
export const enum User_roles_Input {
	admin = "admin",
	member = "member",
	organiser = "organiser"
}
export const enum User_member__region_Input {
	hk = "hk",
	mo = "mo"
}
export const enum User_member__language_Input {
	zh = "zh",
	cn = "cn",
	en = "en"
}
export const enum User_member__gender_Input {
	unSelected = "unSelected",
	female = "female",
	male = "male",
	transgender = "transgender"
}
export const enum User_organiser__language_Input {
	zh = "zh",
	cn = "cn",
	en = "en"
}
export const enum User_status_Input {
	active = "active",
	inactive = "inactive"
}
export const enum Bookmark_User_RelationTo {
	users = "users"
}
export const enum Bookmark_Item_RelationTo {
	events = "events",
	locations = "locations",
	posts = "posts"
}
export const enum Bookmark_user_Relation_RelationTo {
	users = "users"
}
export const enum Bookmark_item_Relation_RelationTo {
	events = "events",
	locations = "locations",
	posts = "posts"
}
export const enum WebAppBookmark_User_RelationTo {
	users = "users"
}
export const enum WebAppBookmark_Event_RelationTo {
	events = "events"
}
export const enum WebAppBookmark_productType {
	ticketing = "ticketing",
	product = "product"
}
export const enum WebAppBookmark_user_Relation_RelationTo {
	users = "users"
}
export const enum WebAppBookmark_event_Relation_RelationTo {
	events = "events"
}
export const enum WebAppBookmark_productType_Input {
	ticketing = "ticketing",
	product = "product"
}
export const enum ViewRecord_User_RelationTo {
	users = "users"
}
export const enum ViewRecord_Item_RelationTo {
	events = "events",
	locations = "locations",
	posts = "posts"
}
export const enum ViewRecord_user_Relation_RelationTo {
	users = "users"
}
export const enum ViewRecord_item_Relation_RelationTo {
	events = "events",
	locations = "locations",
	posts = "posts"
}
export const enum Comment_Target_RelationTo {
	events = "events",
	posts = "posts",
	locations = "locations"
}
export const enum Comment_Parent_RelationTo {
	comments = "comments"
}
export const enum Comment_target_Relation_RelationTo {
	events = "events",
	posts = "posts",
	locations = "locations"
}
export const enum Comment_parent_Relation_RelationTo {
	comments = "comments"
}
export const enum Subscription_User_RelationTo {
	users = "users"
}
export const enum Subscription_Organiser_RelationTo {
	users = "users"
}
export const enum Subscription_user_Relation_RelationTo {
	users = "users"
}
export const enum Subscription_organiser_Relation_RelationTo {
	users = "users"
}
export const enum Newsletter_region {
	hk = "hk",
	mo = "mo"
}
export const enum Newsletter_region_Input {
	hk = "hk",
	mo = "mo"
}
export const enum BlogCategory_region_Input {
	hk = "hk",
	mo = "mo"
}
export const enum Tag_region_Input {
	hk = "hk",
	mo = "mo"
}
export const enum SpecialTag_region_Input {
	hk = "hk",
	mo = "mo"
}
export const enum Date_type_Input {
	fixed = "fixed",
	logic = "logic"
}
export const enum Date_region_Input {
	hk = "hk",
	mo = "mo"
}
export const enum District_region_Input {
	hk = "hk",
	mo = "mo"
}
export const enum Audience_region_Input {
	hk = "hk",
	mo = "mo"
}
export const enum EventCategory_region_Input {
	hk = "hk",
	mo = "mo"
}
export const enum Device_User_RelationTo {
	users = "users"
}
export const enum Device_user_Relation_RelationTo {
	users = "users"
}
export const enum Notification_type {
	event = "event",
	comment = "comment",
	subscription = "subscription"
}
export const enum Notification_Meta_RelationTo {
	events = "events",
	locations = "locations",
	posts = "posts",
	comments = "comments"
}
export const enum Notification_type_Input {
	event = "event",
	comment = "comment",
	subscription = "subscription"
}
export const enum Notification_meta_Relation_RelationTo {
	events = "events",
	locations = "locations",
	posts = "posts",
	comments = "comments"
}
export const enum EventRanking_template {
	normal = "normal",
	editorial_choice = "editorial_choice"
}
export const enum EventRanking_region {
	hk = "hk",
	mo = "mo"
}
export const enum EventRanking_template_Input {
	normal = "normal",
	editorial_choice = "editorial_choice"
}
export const enum EventRanking_region_Input {
	hk = "hk",
	mo = "mo"
}
export const enum LocationRanking_template {
	normal = "normal",
	editorial_choice = "editorial_choice"
}
export const enum LocationRanking_region {
	hk = "hk",
	mo = "mo"
}
export const enum LocationRanking_template_Input {
	normal = "normal",
	editorial_choice = "editorial_choice"
}
export const enum LocationRanking_region_Input {
	hk = "hk",
	mo = "mo"
}
export const enum EventCalendar_region {
	hk = "hk",
	mo = "mo"
}
export const enum EventCalendar_region_Input {
	hk = "hk",
	mo = "mo"
}
export const enum EditorChoice_region {
	hk = "hk",
	mo = "mo"
}
export const enum EditorChoice_region_Input {
	hk = "hk",
	mo = "mo"
}
export const enum CriteriaSetting_Criteria_region {
	hk = "hk",
	mo = "mo"
}
export const enum CriteriaSetting_criteria__region_Input {
	hk = "hk",
	mo = "mo"
}
export const enum OrganiserSetting_region {
	hk = "hk",
	mo = "mo"
}
export const enum OrganiserSetting_region_Input {
	hk = "hk",
	mo = "mo"
}
export const enum PostSetting_region {
	hk = "hk",
	mo = "mo"
}
export const enum PostSetting_region_Input {
	hk = "hk",
	mo = "mo"
}
export const enum HomepageSetting_region {
	hk = "hk",
	mo = "mo"
}
export const enum ComingWeekEventAndLocationRankingBlock_appearance {
	ranking = "ranking"
}
export const enum ComingWeekEventAndLocationRankingBlock_byRegion {
	hk = "hk",
	mo = "mo"
}
export const enum EventRankingBlock_appearance {
	featured = "featured"
}
export const enum LocationRankingBlock_appearance {
	card = "card",
	ranking = "ranking"
}
export const enum HomepageSetting_region_Input {
	hk = "hk",
	mo = "mo"
}
export const enum BannerSection_Banners_region {
	hk = "hk",
	mo = "mo"
}
export const enum BannerSection_banners__region_Input {
	hk = "hk",
	mo = "mo"
}
export const enum MacaoBannerSection_Banners_region {
	hk = "hk",
	mo = "mo"
}
export const enum MacaoBannerSection_banners__region_Input {
	hk = "hk",
	mo = "mo"
}
export const enum Banner_Location_page {
	home = "home",
	blog = "blog",
	editorChoice = "editorChoice",
	rankingFullList = "rankingFullList",
	latestEvent = "latestEvent"
}
export const enum Banner_type {
	house = "house",
	sponsorship = "sponsorship"
}
export const enum Banner_status {
	processing = "processing",
	pending = "pending",
	expired = "expired"
}
export const enum Campaign_Creator_RelationTo {
	users = "users"
}
export const enum Banner_region {
	hk = "hk",
	mo = "mo"
}
export const enum Banner_location__page_Input {
	home = "home",
	blog = "blog",
	editorChoice = "editorChoice",
	rankingFullList = "rankingFullList",
	latestEvent = "latestEvent"
}
export const enum Banner_type_Input {
	house = "house",
	sponsorship = "sponsorship"
}
export const enum Banner_status_Input {
	processing = "processing",
	pending = "pending",
	expired = "expired"
}
export const enum Banner_region_Input {
	hk = "hk",
	mo = "mo"
}
export const enum Campaign_creator_Relation_RelationTo {
	users = "users"
}
export const enum Advertise_Advertise_type {
	eod = "eod",
	pe = "pe",
	pl = "pl",
	sk = "sk",
	cov = "cov",
	hm_b = "hm_b",
	hm_p = "hm_p",
	fb = "fb",
	ig = "ig",
	edm_f = "edm_f",
	edm_s = "edm_s",
	ep = "ep",
	loc = "loc",
	misc = "misc"
}
export const enum Advertise_Advertise_displayPosition {
	homePrimary = "homePrimary",
	homeSecondary = "homeSecondary"
}
export const enum Advertise_type {
	house = "house",
	sponsorship = "sponsorship"
}
export const enum Advertise_advertise__type_Input {
	eod = "eod",
	pe = "pe",
	pl = "pl",
	sk = "sk",
	cov = "cov",
	hm_b = "hm_b",
	hm_p = "hm_p",
	fb = "fb",
	ig = "ig",
	edm_f = "edm_f",
	edm_s = "edm_s",
	ep = "ep",
	loc = "loc",
	misc = "misc"
}
export const enum Advertise_advertise__displayPosition_Input {
	homePrimary = "homePrimary",
	homeSecondary = "homeSecondary"
}
export const enum Advertise_type_Input {
	house = "house",
	sponsorship = "sponsorship"
}
export const enum Static_region {
	hk = "hk",
	mo = "mo"
}
export const enum Static_region_Input {
	hk = "hk",
	mo = "mo"
}
export const enum PayloadPreference_User_RelationTo {
	users = "users"
}
export const enum PayloadPreference_user_Relation_RelationTo {
	users = "users"
}
export const enum Event_region_MutationInput {
	hk = "hk",
	mo = "mo"
}
export const enum Event_level_MutationInput {
	light = "light",
	standard = "standard",
	featured = "featured",
	premium = "premium"
}
export const enum Event_Sections_Recurrance_type_MutationInput {
	daily = "daily",
	weekdays = "weekdays",
	weekend = "weekend",
	custom = "custom"
}
export const enum Event_Sections_Recurrance_weekday_MutationInput {
	sunday = "sunday",
	monday = "monday",
	tuesday = "tuesday",
	wednesday = "wednesday",
	thursday = "thursday",
	friday = "friday",
	saturday = "saturday"
}
export const enum Event_Ticketing_type_MutationInput {
	ticketing = "ticketing",
	promotion = "promotion"
}
export const enum Event_Ticketing_ticketingType_MutationInput {
	public = "public",
	additionalShow = "additionalShow",
	memberOrder = "memberOrder",
	preOrder = "preOrder",
	creditCard = "creditCard",
	pointsExchange = "pointsExchange",
	tradingPlatform = "tradingPlatform",
	timable = "timable"
}
export const enum Event_Ticketing_promotionType_MutationInput {
	lottery = "lottery",
	signUp = "signUp"
}
export const enum EventUpdate_region_MutationInput {
	hk = "hk",
	mo = "mo"
}
export const enum EventUpdate_level_MutationInput {
	light = "light",
	standard = "standard",
	featured = "featured",
	premium = "premium"
}
export const enum EventUpdate_Sections_Recurrance_type_MutationInput {
	daily = "daily",
	weekdays = "weekdays",
	weekend = "weekend",
	custom = "custom"
}
export const enum EventUpdate_Sections_Recurrance_weekday_MutationInput {
	sunday = "sunday",
	monday = "monday",
	tuesday = "tuesday",
	wednesday = "wednesday",
	thursday = "thursday",
	friday = "friday",
	saturday = "saturday"
}
export const enum EventUpdate_Ticketing_type_MutationInput {
	ticketing = "ticketing",
	promotion = "promotion"
}
export const enum EventUpdate_Ticketing_ticketingType_MutationInput {
	public = "public",
	additionalShow = "additionalShow",
	memberOrder = "memberOrder",
	preOrder = "preOrder",
	creditCard = "creditCard",
	pointsExchange = "pointsExchange",
	tradingPlatform = "tradingPlatform",
	timable = "timable"
}
export const enum EventUpdate_Ticketing_promotionType_MutationInput {
	lottery = "lottery",
	signUp = "signUp"
}
export const enum Location_region_MutationInput {
	hk = "hk",
	mo = "mo"
}
export const enum Location_level_MutationInput {
	light = "light",
	standard = "standard",
	featured = "featured",
	premium = "premium"
}
export const enum Location_type_MutationInput {
	virtual = "virtual",
	physical = "physical",
	landmark = "landmark"
}
export const enum Location_category_MutationInput {
	venue = "venue",
	restaurant = "restaurant"
}
export const enum Location_Sections_Recurrance_type_MutationInput {
	daily = "daily",
	weekdays = "weekdays",
	weekend = "weekend",
	custom = "custom"
}
export const enum Location_Sections_Recurrance_weekday_MutationInput {
	sunday = "sunday",
	monday = "monday",
	tuesday = "tuesday",
	wednesday = "wednesday",
	thursday = "thursday",
	friday = "friday",
	saturday = "saturday"
}
export const enum LocationUpdate_region_MutationInput {
	hk = "hk",
	mo = "mo"
}
export const enum LocationUpdate_level_MutationInput {
	light = "light",
	standard = "standard",
	featured = "featured",
	premium = "premium"
}
export const enum LocationUpdate_type_MutationInput {
	virtual = "virtual",
	physical = "physical",
	landmark = "landmark"
}
export const enum LocationUpdate_category_MutationInput {
	venue = "venue",
	restaurant = "restaurant"
}
export const enum LocationUpdate_Sections_Recurrance_type_MutationInput {
	daily = "daily",
	weekdays = "weekdays",
	weekend = "weekend",
	custom = "custom"
}
export const enum LocationUpdate_Sections_Recurrance_weekday_MutationInput {
	sunday = "sunday",
	monday = "monday",
	tuesday = "tuesday",
	wednesday = "wednesday",
	thursday = "thursday",
	friday = "friday",
	saturday = "saturday"
}
export const enum Post_region_MutationInput {
	hk = "hk",
	mo = "mo"
}
export const enum PostUpdate_region_MutationInput {
	hk = "hk",
	mo = "mo"
}
export const enum Ticketing_status_MutationInput {
	published = "published",
	rejected = "rejected",
	review = "review",
	private = "private"
}
export const enum Ticketing_CheckInLevel_MutationInput {
	one = "one",
	two = "two"
}
export const enum TicketingUpdate_status_MutationInput {
	published = "published",
	rejected = "rejected",
	review = "review",
	private = "private"
}
export const enum TicketingUpdate_CheckInLevel_MutationInput {
	one = "one",
	two = "two"
}
export const enum Product_status_MutationInput {
	published = "published",
	rejected = "rejected",
	review = "review",
	private = "private"
}
export const enum Product_CheckInLevel_MutationInput {
	one = "one"
}
export const enum ProductUpdate_status_MutationInput {
	published = "published",
	rejected = "rejected",
	review = "review",
	private = "private"
}
export const enum ProductUpdate_CheckInLevel_MutationInput {
	one = "one"
}
export const enum InventoryStatus_stockUpdateStatus_MutationInput {
	remaining = "remaining",
	sold = "sold",
	onHold = "onHold",
	checkedIn = "checkedIn",
	processingPayment = "processingPayment"
}
export const enum InventoryStatusUpdate_stockUpdateStatus_MutationInput {
	remaining = "remaining",
	sold = "sold",
	onHold = "onHold",
	checkedIn = "checkedIn",
	processingPayment = "processingPayment"
}
export const enum RedemptionCode_status_MutationInput {
	checkedIn = "checkedIn",
	activeToUse = "activeToUse",
	refunded = "refunded",
	voided = "voided",
	processingPayment = "processingPayment"
}
export const enum RedemptionCodeUpdate_status_MutationInput {
	checkedIn = "checkedIn",
	activeToUse = "activeToUse",
	refunded = "refunded",
	voided = "voided",
	processingPayment = "processingPayment"
}
export const enum User_region_MutationInput {
	hk = "hk",
	mo = "mo"
}
export const enum User_roles_MutationInput {
	admin = "admin",
	member = "member",
	organiser = "organiser"
}
export const enum User_Member_region_MutationInput {
	hk = "hk",
	mo = "mo"
}
export const enum User_Member_language_MutationInput {
	zh = "zh",
	cn = "cn",
	en = "en"
}
export const enum User_Member_gender_MutationInput {
	unSelected = "unSelected",
	female = "female",
	male = "male",
	transgender = "transgender"
}
export const enum User_Organiser_language_MutationInput {
	zh = "zh",
	cn = "cn",
	en = "en"
}
export const enum User_status_MutationInput {
	active = "active",
	inactive = "inactive"
}
export const enum UserUpdate_region_MutationInput {
	hk = "hk",
	mo = "mo"
}
export const enum UserUpdate_roles_MutationInput {
	admin = "admin",
	member = "member",
	organiser = "organiser"
}
export const enum UserUpdate_Member_region_MutationInput {
	hk = "hk",
	mo = "mo"
}
export const enum UserUpdate_Member_language_MutationInput {
	zh = "zh",
	cn = "cn",
	en = "en"
}
export const enum UserUpdate_Member_gender_MutationInput {
	unSelected = "unSelected",
	female = "female",
	male = "male",
	transgender = "transgender"
}
export const enum UserUpdate_Organiser_language_MutationInput {
	zh = "zh",
	cn = "cn",
	en = "en"
}
export const enum UserUpdate_status_MutationInput {
	active = "active",
	inactive = "inactive"
}
export const enum Bookmark_UserRelationshipInputRelationTo {
	users = "users"
}
export const enum Bookmark_ItemRelationshipInputRelationTo {
	events = "events",
	locations = "locations",
	posts = "posts"
}
export const enum BookmarkUpdate_UserRelationshipInputRelationTo {
	users = "users"
}
export const enum BookmarkUpdate_ItemRelationshipInputRelationTo {
	events = "events",
	locations = "locations",
	posts = "posts"
}
export const enum WebAppBookmark_UserRelationshipInputRelationTo {
	users = "users"
}
export const enum WebAppBookmark_EventRelationshipInputRelationTo {
	events = "events"
}
export const enum WebAppBookmark_productType_MutationInput {
	ticketing = "ticketing",
	product = "product"
}
export const enum WebAppBookmarkUpdate_UserRelationshipInputRelationTo {
	users = "users"
}
export const enum WebAppBookmarkUpdate_EventRelationshipInputRelationTo {
	events = "events"
}
export const enum WebAppBookmarkUpdate_productType_MutationInput {
	ticketing = "ticketing",
	product = "product"
}
export const enum ViewRecord_UserRelationshipInputRelationTo {
	users = "users"
}
export const enum ViewRecord_ItemRelationshipInputRelationTo {
	events = "events",
	locations = "locations",
	posts = "posts"
}
export const enum ViewRecordUpdate_UserRelationshipInputRelationTo {
	users = "users"
}
export const enum ViewRecordUpdate_ItemRelationshipInputRelationTo {
	events = "events",
	locations = "locations",
	posts = "posts"
}
export const enum Comment_TargetRelationshipInputRelationTo {
	events = "events",
	posts = "posts",
	locations = "locations"
}
export const enum Comment_ParentRelationshipInputRelationTo {
	comments = "comments"
}
export const enum CommentUpdate_TargetRelationshipInputRelationTo {
	events = "events",
	posts = "posts",
	locations = "locations"
}
export const enum CommentUpdate_ParentRelationshipInputRelationTo {
	comments = "comments"
}
export const enum Subscription_UserRelationshipInputRelationTo {
	users = "users"
}
export const enum Subscription_OrganiserRelationshipInputRelationTo {
	users = "users"
}
export const enum SubscriptionUpdate_UserRelationshipInputRelationTo {
	users = "users"
}
export const enum SubscriptionUpdate_OrganiserRelationshipInputRelationTo {
	users = "users"
}
export const enum Newsletter_region_MutationInput {
	hk = "hk",
	mo = "mo"
}
export const enum NewsletterUpdate_region_MutationInput {
	hk = "hk",
	mo = "mo"
}
export const enum BlogCategory_region_MutationInput {
	hk = "hk",
	mo = "mo"
}
export const enum BlogCategoryUpdate_region_MutationInput {
	hk = "hk",
	mo = "mo"
}
export const enum Tag_region_MutationInput {
	hk = "hk",
	mo = "mo"
}
export const enum TagUpdate_region_MutationInput {
	hk = "hk",
	mo = "mo"
}
export const enum SpecialTag_region_MutationInput {
	hk = "hk",
	mo = "mo"
}
export const enum SpecialTagUpdate_region_MutationInput {
	hk = "hk",
	mo = "mo"
}
export const enum Date_type_MutationInput {
	fixed = "fixed",
	logic = "logic"
}
export const enum Date_region_MutationInput {
	hk = "hk",
	mo = "mo"
}
export const enum DateUpdate_type_MutationInput {
	fixed = "fixed",
	logic = "logic"
}
export const enum DateUpdate_region_MutationInput {
	hk = "hk",
	mo = "mo"
}
export const enum District_region_MutationInput {
	hk = "hk",
	mo = "mo"
}
export const enum DistrictUpdate_region_MutationInput {
	hk = "hk",
	mo = "mo"
}
export const enum Audience_region_MutationInput {
	hk = "hk",
	mo = "mo"
}
export const enum AudienceUpdate_region_MutationInput {
	hk = "hk",
	mo = "mo"
}
export const enum EventCategory_region_MutationInput {
	hk = "hk",
	mo = "mo"
}
export const enum EventCategoryUpdate_region_MutationInput {
	hk = "hk",
	mo = "mo"
}
export const enum Device_UserRelationshipInputRelationTo {
	users = "users"
}
export const enum DeviceUpdate_UserRelationshipInputRelationTo {
	users = "users"
}
export const enum Notification_type_MutationInput {
	event = "event",
	comment = "comment",
	subscription = "subscription"
}
export const enum Notification_MetaRelationshipInputRelationTo {
	events = "events",
	locations = "locations",
	posts = "posts",
	comments = "comments"
}
export const enum NotificationUpdate_type_MutationInput {
	event = "event",
	comment = "comment",
	subscription = "subscription"
}
export const enum NotificationUpdate_MetaRelationshipInputRelationTo {
	events = "events",
	locations = "locations",
	posts = "posts",
	comments = "comments"
}
export const enum EventRanking_template_MutationInput {
	normal = "normal",
	editorial_choice = "editorial_choice"
}
export const enum EventRanking_region_MutationInput {
	hk = "hk",
	mo = "mo"
}
export const enum EventRankingUpdate_template_MutationInput {
	normal = "normal",
	editorial_choice = "editorial_choice"
}
export const enum EventRankingUpdate_region_MutationInput {
	hk = "hk",
	mo = "mo"
}
export const enum LocationRanking_template_MutationInput {
	normal = "normal",
	editorial_choice = "editorial_choice"
}
export const enum LocationRanking_region_MutationInput {
	hk = "hk",
	mo = "mo"
}
export const enum LocationRankingUpdate_template_MutationInput {
	normal = "normal",
	editorial_choice = "editorial_choice"
}
export const enum LocationRankingUpdate_region_MutationInput {
	hk = "hk",
	mo = "mo"
}
export const enum EventCalendar_region_MutationInput {
	hk = "hk",
	mo = "mo"
}
export const enum EventCalendarUpdate_region_MutationInput {
	hk = "hk",
	mo = "mo"
}
export const enum EditorChoice_region_MutationInput {
	hk = "hk",
	mo = "mo"
}
export const enum EditorChoiceUpdate_region_MutationInput {
	hk = "hk",
	mo = "mo"
}
export const enum CriteriaSetting_Criteria_region_MutationInput {
	hk = "hk",
	mo = "mo"
}
export const enum CriteriaSettingUpdate_Criteria_region_MutationInput {
	hk = "hk",
	mo = "mo"
}
export const enum OrganiserSetting_region_MutationInput {
	hk = "hk",
	mo = "mo"
}
export const enum OrganiserSettingUpdate_region_MutationInput {
	hk = "hk",
	mo = "mo"
}
export const enum PostSetting_region_MutationInput {
	hk = "hk",
	mo = "mo"
}
export const enum PostSettingUpdate_region_MutationInput {
	hk = "hk",
	mo = "mo"
}
export const enum HomepageSetting_region_MutationInput {
	hk = "hk",
	mo = "mo"
}
export const enum HomepageSettingUpdate_region_MutationInput {
	hk = "hk",
	mo = "mo"
}
export const enum BannerSection_Banners_region_MutationInput {
	hk = "hk",
	mo = "mo"
}
export const enum BannerSectionUpdate_Banners_region_MutationInput {
	hk = "hk",
	mo = "mo"
}
export const enum MacaoBannerSection_Banners_region_MutationInput {
	hk = "hk",
	mo = "mo"
}
export const enum MacaoBannerSectionUpdate_Banners_region_MutationInput {
	hk = "hk",
	mo = "mo"
}
export const enum Banner_Location_page_MutationInput {
	home = "home",
	blog = "blog",
	editorChoice = "editorChoice",
	rankingFullList = "rankingFullList",
	latestEvent = "latestEvent"
}
export const enum Banner_type_MutationInput {
	house = "house",
	sponsorship = "sponsorship"
}
export const enum Banner_status_MutationInput {
	processing = "processing",
	pending = "pending",
	expired = "expired"
}
export const enum Banner_region_MutationInput {
	hk = "hk",
	mo = "mo"
}
export const enum BannerUpdate_Location_page_MutationInput {
	home = "home",
	blog = "blog",
	editorChoice = "editorChoice",
	rankingFullList = "rankingFullList",
	latestEvent = "latestEvent"
}
export const enum BannerUpdate_type_MutationInput {
	house = "house",
	sponsorship = "sponsorship"
}
export const enum BannerUpdate_status_MutationInput {
	processing = "processing",
	pending = "pending",
	expired = "expired"
}
export const enum BannerUpdate_region_MutationInput {
	hk = "hk",
	mo = "mo"
}
export const enum Campaign_CreatorRelationshipInputRelationTo {
	users = "users"
}
export const enum CampaignUpdate_CreatorRelationshipInputRelationTo {
	users = "users"
}
export const enum Advertise_Advertise_type_MutationInput {
	eod = "eod",
	pe = "pe",
	pl = "pl",
	sk = "sk",
	cov = "cov",
	hm_b = "hm_b",
	hm_p = "hm_p",
	fb = "fb",
	ig = "ig",
	edm_f = "edm_f",
	edm_s = "edm_s",
	ep = "ep",
	loc = "loc",
	misc = "misc"
}
export const enum Advertise_type_MutationInput {
	house = "house",
	sponsorship = "sponsorship"
}
export const enum AdvertiseUpdate_Advertise_type_MutationInput {
	eod = "eod",
	pe = "pe",
	pl = "pl",
	sk = "sk",
	cov = "cov",
	hm_b = "hm_b",
	hm_p = "hm_p",
	fb = "fb",
	ig = "ig",
	edm_f = "edm_f",
	edm_s = "edm_s",
	ep = "ep",
	loc = "loc",
	misc = "misc"
}
export const enum AdvertiseUpdate_type_MutationInput {
	house = "house",
	sponsorship = "sponsorship"
}
export const enum Static_region_MutationInput {
	hk = "hk",
	mo = "mo"
}
export const enum StaticUpdate_region_MutationInput {
	hk = "hk",
	mo = "mo"
}
export const enum PayloadPreference_UserRelationshipInputRelationTo {
	users = "users"
}
export const enum PayloadPreferenceUpdate_UserRelationshipInputRelationTo {
	users = "users"
}

type ZEUS_VARIABLES = {
	["Event_region"]: ValueTypes["Event_region"];
	["User_region"]: ValueTypes["User_region"];
	["User_roles"]: ValueTypes["User_roles"];
	["User_Member_region"]: ValueTypes["User_Member_region"];
	["User_Member_language"]: ValueTypes["User_Member_language"];
	["DateTime"]: ValueTypes["DateTime"];
	["LocaleInputType"]: ValueTypes["LocaleInputType"];
	["FallbackLocaleInputType"]: ValueTypes["FallbackLocaleInputType"];
	["User_Member_Avatar_where"]: ValueTypes["User_Member_Avatar_where"];
	["User_Member_Avatar_alt_operator"]: ValueTypes["User_Member_Avatar_alt_operator"];
	["User_Member_Avatar_updatedAt_operator"]: ValueTypes["User_Member_Avatar_updatedAt_operator"];
	["User_Member_Avatar_createdAt_operator"]: ValueTypes["User_Member_Avatar_createdAt_operator"];
	["User_Member_Avatar_url_operator"]: ValueTypes["User_Member_Avatar_url_operator"];
	["User_Member_Avatar_filename_operator"]: ValueTypes["User_Member_Avatar_filename_operator"];
	["User_Member_Avatar_mimeType_operator"]: ValueTypes["User_Member_Avatar_mimeType_operator"];
	["User_Member_Avatar_filesize_operator"]: ValueTypes["User_Member_Avatar_filesize_operator"];
	["User_Member_Avatar_width_operator"]: ValueTypes["User_Member_Avatar_width_operator"];
	["User_Member_Avatar_height_operator"]: ValueTypes["User_Member_Avatar_height_operator"];
	["User_Member_Avatar_focalX_operator"]: ValueTypes["User_Member_Avatar_focalX_operator"];
	["User_Member_Avatar_focalY_operator"]: ValueTypes["User_Member_Avatar_focalY_operator"];
	["User_Member_Avatar_sizes__small__url_operator"]: ValueTypes["User_Member_Avatar_sizes__small__url_operator"];
	["User_Member_Avatar_sizes__small__width_operator"]: ValueTypes["User_Member_Avatar_sizes__small__width_operator"];
	["User_Member_Avatar_sizes__small__height_operator"]: ValueTypes["User_Member_Avatar_sizes__small__height_operator"];
	["User_Member_Avatar_sizes__small__mimeType_operator"]: ValueTypes["User_Member_Avatar_sizes__small__mimeType_operator"];
	["User_Member_Avatar_sizes__small__filesize_operator"]: ValueTypes["User_Member_Avatar_sizes__small__filesize_operator"];
	["User_Member_Avatar_sizes__small__filename_operator"]: ValueTypes["User_Member_Avatar_sizes__small__filename_operator"];
	["User_Member_Avatar_sizes__medium__url_operator"]: ValueTypes["User_Member_Avatar_sizes__medium__url_operator"];
	["User_Member_Avatar_sizes__medium__width_operator"]: ValueTypes["User_Member_Avatar_sizes__medium__width_operator"];
	["User_Member_Avatar_sizes__medium__height_operator"]: ValueTypes["User_Member_Avatar_sizes__medium__height_operator"];
	["User_Member_Avatar_sizes__medium__mimeType_operator"]: ValueTypes["User_Member_Avatar_sizes__medium__mimeType_operator"];
	["User_Member_Avatar_sizes__medium__filesize_operator"]: ValueTypes["User_Member_Avatar_sizes__medium__filesize_operator"];
	["User_Member_Avatar_sizes__medium__filename_operator"]: ValueTypes["User_Member_Avatar_sizes__medium__filename_operator"];
	["User_Member_Avatar_sizes__large__url_operator"]: ValueTypes["User_Member_Avatar_sizes__large__url_operator"];
	["User_Member_Avatar_sizes__large__width_operator"]: ValueTypes["User_Member_Avatar_sizes__large__width_operator"];
	["User_Member_Avatar_sizes__large__height_operator"]: ValueTypes["User_Member_Avatar_sizes__large__height_operator"];
	["User_Member_Avatar_sizes__large__mimeType_operator"]: ValueTypes["User_Member_Avatar_sizes__large__mimeType_operator"];
	["User_Member_Avatar_sizes__large__filesize_operator"]: ValueTypes["User_Member_Avatar_sizes__large__filesize_operator"];
	["User_Member_Avatar_sizes__large__filename_operator"]: ValueTypes["User_Member_Avatar_sizes__large__filename_operator"];
	["User_Member_Avatar_sizes__small_jpeg__url_operator"]: ValueTypes["User_Member_Avatar_sizes__small_jpeg__url_operator"];
	["User_Member_Avatar_sizes__small_jpeg__width_operator"]: ValueTypes["User_Member_Avatar_sizes__small_jpeg__width_operator"];
	["User_Member_Avatar_sizes__small_jpeg__height_operator"]: ValueTypes["User_Member_Avatar_sizes__small_jpeg__height_operator"];
	["User_Member_Avatar_sizes__small_jpeg__mimeType_operator"]: ValueTypes["User_Member_Avatar_sizes__small_jpeg__mimeType_operator"];
	["User_Member_Avatar_sizes__small_jpeg__filesize_operator"]: ValueTypes["User_Member_Avatar_sizes__small_jpeg__filesize_operator"];
	["User_Member_Avatar_sizes__small_jpeg__filename_operator"]: ValueTypes["User_Member_Avatar_sizes__small_jpeg__filename_operator"];
	["User_Member_Avatar_sizes__medium_jpeg__url_operator"]: ValueTypes["User_Member_Avatar_sizes__medium_jpeg__url_operator"];
	["User_Member_Avatar_sizes__medium_jpeg__width_operator"]: ValueTypes["User_Member_Avatar_sizes__medium_jpeg__width_operator"];
	["User_Member_Avatar_sizes__medium_jpeg__height_operator"]: ValueTypes["User_Member_Avatar_sizes__medium_jpeg__height_operator"];
	["User_Member_Avatar_sizes__medium_jpeg__mimeType_operator"]: ValueTypes["User_Member_Avatar_sizes__medium_jpeg__mimeType_operator"];
	["User_Member_Avatar_sizes__medium_jpeg__filesize_operator"]: ValueTypes["User_Member_Avatar_sizes__medium_jpeg__filesize_operator"];
	["User_Member_Avatar_sizes__medium_jpeg__filename_operator"]: ValueTypes["User_Member_Avatar_sizes__medium_jpeg__filename_operator"];
	["User_Member_Avatar_sizes__large_jpeg__url_operator"]: ValueTypes["User_Member_Avatar_sizes__large_jpeg__url_operator"];
	["User_Member_Avatar_sizes__large_jpeg__width_operator"]: ValueTypes["User_Member_Avatar_sizes__large_jpeg__width_operator"];
	["User_Member_Avatar_sizes__large_jpeg__height_operator"]: ValueTypes["User_Member_Avatar_sizes__large_jpeg__height_operator"];
	["User_Member_Avatar_sizes__large_jpeg__mimeType_operator"]: ValueTypes["User_Member_Avatar_sizes__large_jpeg__mimeType_operator"];
	["User_Member_Avatar_sizes__large_jpeg__filesize_operator"]: ValueTypes["User_Member_Avatar_sizes__large_jpeg__filesize_operator"];
	["User_Member_Avatar_sizes__large_jpeg__filename_operator"]: ValueTypes["User_Member_Avatar_sizes__large_jpeg__filename_operator"];
	["User_Member_Avatar_id_operator"]: ValueTypes["User_Member_Avatar_id_operator"];
	["User_Member_Avatar_where_and"]: ValueTypes["User_Member_Avatar_where_and"];
	["User_Member_Avatar_where_or"]: ValueTypes["User_Member_Avatar_where_or"];
	["User_Member_gender"]: ValueTypes["User_Member_gender"];
	["Tag_region"]: ValueTypes["Tag_region"];
	["EventCategory_Icon_where"]: ValueTypes["EventCategory_Icon_where"];
	["EventCategory_Icon_alt_operator"]: ValueTypes["EventCategory_Icon_alt_operator"];
	["EventCategory_Icon_updatedAt_operator"]: ValueTypes["EventCategory_Icon_updatedAt_operator"];
	["EventCategory_Icon_createdAt_operator"]: ValueTypes["EventCategory_Icon_createdAt_operator"];
	["EventCategory_Icon_url_operator"]: ValueTypes["EventCategory_Icon_url_operator"];
	["EventCategory_Icon_filename_operator"]: ValueTypes["EventCategory_Icon_filename_operator"];
	["EventCategory_Icon_mimeType_operator"]: ValueTypes["EventCategory_Icon_mimeType_operator"];
	["EventCategory_Icon_filesize_operator"]: ValueTypes["EventCategory_Icon_filesize_operator"];
	["EventCategory_Icon_width_operator"]: ValueTypes["EventCategory_Icon_width_operator"];
	["EventCategory_Icon_height_operator"]: ValueTypes["EventCategory_Icon_height_operator"];
	["EventCategory_Icon_focalX_operator"]: ValueTypes["EventCategory_Icon_focalX_operator"];
	["EventCategory_Icon_focalY_operator"]: ValueTypes["EventCategory_Icon_focalY_operator"];
	["EventCategory_Icon_sizes__small__url_operator"]: ValueTypes["EventCategory_Icon_sizes__small__url_operator"];
	["EventCategory_Icon_sizes__small__width_operator"]: ValueTypes["EventCategory_Icon_sizes__small__width_operator"];
	["EventCategory_Icon_sizes__small__height_operator"]: ValueTypes["EventCategory_Icon_sizes__small__height_operator"];
	["EventCategory_Icon_sizes__small__mimeType_operator"]: ValueTypes["EventCategory_Icon_sizes__small__mimeType_operator"];
	["EventCategory_Icon_sizes__small__filesize_operator"]: ValueTypes["EventCategory_Icon_sizes__small__filesize_operator"];
	["EventCategory_Icon_sizes__small__filename_operator"]: ValueTypes["EventCategory_Icon_sizes__small__filename_operator"];
	["EventCategory_Icon_sizes__medium__url_operator"]: ValueTypes["EventCategory_Icon_sizes__medium__url_operator"];
	["EventCategory_Icon_sizes__medium__width_operator"]: ValueTypes["EventCategory_Icon_sizes__medium__width_operator"];
	["EventCategory_Icon_sizes__medium__height_operator"]: ValueTypes["EventCategory_Icon_sizes__medium__height_operator"];
	["EventCategory_Icon_sizes__medium__mimeType_operator"]: ValueTypes["EventCategory_Icon_sizes__medium__mimeType_operator"];
	["EventCategory_Icon_sizes__medium__filesize_operator"]: ValueTypes["EventCategory_Icon_sizes__medium__filesize_operator"];
	["EventCategory_Icon_sizes__medium__filename_operator"]: ValueTypes["EventCategory_Icon_sizes__medium__filename_operator"];
	["EventCategory_Icon_sizes__large__url_operator"]: ValueTypes["EventCategory_Icon_sizes__large__url_operator"];
	["EventCategory_Icon_sizes__large__width_operator"]: ValueTypes["EventCategory_Icon_sizes__large__width_operator"];
	["EventCategory_Icon_sizes__large__height_operator"]: ValueTypes["EventCategory_Icon_sizes__large__height_operator"];
	["EventCategory_Icon_sizes__large__mimeType_operator"]: ValueTypes["EventCategory_Icon_sizes__large__mimeType_operator"];
	["EventCategory_Icon_sizes__large__filesize_operator"]: ValueTypes["EventCategory_Icon_sizes__large__filesize_operator"];
	["EventCategory_Icon_sizes__large__filename_operator"]: ValueTypes["EventCategory_Icon_sizes__large__filename_operator"];
	["EventCategory_Icon_sizes__small_jpeg__url_operator"]: ValueTypes["EventCategory_Icon_sizes__small_jpeg__url_operator"];
	["EventCategory_Icon_sizes__small_jpeg__width_operator"]: ValueTypes["EventCategory_Icon_sizes__small_jpeg__width_operator"];
	["EventCategory_Icon_sizes__small_jpeg__height_operator"]: ValueTypes["EventCategory_Icon_sizes__small_jpeg__height_operator"];
	["EventCategory_Icon_sizes__small_jpeg__mimeType_operator"]: ValueTypes["EventCategory_Icon_sizes__small_jpeg__mimeType_operator"];
	["EventCategory_Icon_sizes__small_jpeg__filesize_operator"]: ValueTypes["EventCategory_Icon_sizes__small_jpeg__filesize_operator"];
	["EventCategory_Icon_sizes__small_jpeg__filename_operator"]: ValueTypes["EventCategory_Icon_sizes__small_jpeg__filename_operator"];
	["EventCategory_Icon_sizes__medium_jpeg__url_operator"]: ValueTypes["EventCategory_Icon_sizes__medium_jpeg__url_operator"];
	["EventCategory_Icon_sizes__medium_jpeg__width_operator"]: ValueTypes["EventCategory_Icon_sizes__medium_jpeg__width_operator"];
	["EventCategory_Icon_sizes__medium_jpeg__height_operator"]: ValueTypes["EventCategory_Icon_sizes__medium_jpeg__height_operator"];
	["EventCategory_Icon_sizes__medium_jpeg__mimeType_operator"]: ValueTypes["EventCategory_Icon_sizes__medium_jpeg__mimeType_operator"];
	["EventCategory_Icon_sizes__medium_jpeg__filesize_operator"]: ValueTypes["EventCategory_Icon_sizes__medium_jpeg__filesize_operator"];
	["EventCategory_Icon_sizes__medium_jpeg__filename_operator"]: ValueTypes["EventCategory_Icon_sizes__medium_jpeg__filename_operator"];
	["EventCategory_Icon_sizes__large_jpeg__url_operator"]: ValueTypes["EventCategory_Icon_sizes__large_jpeg__url_operator"];
	["EventCategory_Icon_sizes__large_jpeg__width_operator"]: ValueTypes["EventCategory_Icon_sizes__large_jpeg__width_operator"];
	["EventCategory_Icon_sizes__large_jpeg__height_operator"]: ValueTypes["EventCategory_Icon_sizes__large_jpeg__height_operator"];
	["EventCategory_Icon_sizes__large_jpeg__mimeType_operator"]: ValueTypes["EventCategory_Icon_sizes__large_jpeg__mimeType_operator"];
	["EventCategory_Icon_sizes__large_jpeg__filesize_operator"]: ValueTypes["EventCategory_Icon_sizes__large_jpeg__filesize_operator"];
	["EventCategory_Icon_sizes__large_jpeg__filename_operator"]: ValueTypes["EventCategory_Icon_sizes__large_jpeg__filename_operator"];
	["EventCategory_Icon_id_operator"]: ValueTypes["EventCategory_Icon_id_operator"];
	["EventCategory_Icon_where_and"]: ValueTypes["EventCategory_Icon_where_and"];
	["EventCategory_Icon_where_or"]: ValueTypes["EventCategory_Icon_where_or"];
	["EventCategory_region"]: ValueTypes["EventCategory_region"];
	["User_Organiser_Logo_where"]: ValueTypes["User_Organiser_Logo_where"];
	["User_Organiser_Logo_alt_operator"]: ValueTypes["User_Organiser_Logo_alt_operator"];
	["User_Organiser_Logo_updatedAt_operator"]: ValueTypes["User_Organiser_Logo_updatedAt_operator"];
	["User_Organiser_Logo_createdAt_operator"]: ValueTypes["User_Organiser_Logo_createdAt_operator"];
	["User_Organiser_Logo_url_operator"]: ValueTypes["User_Organiser_Logo_url_operator"];
	["User_Organiser_Logo_filename_operator"]: ValueTypes["User_Organiser_Logo_filename_operator"];
	["User_Organiser_Logo_mimeType_operator"]: ValueTypes["User_Organiser_Logo_mimeType_operator"];
	["User_Organiser_Logo_filesize_operator"]: ValueTypes["User_Organiser_Logo_filesize_operator"];
	["User_Organiser_Logo_width_operator"]: ValueTypes["User_Organiser_Logo_width_operator"];
	["User_Organiser_Logo_height_operator"]: ValueTypes["User_Organiser_Logo_height_operator"];
	["User_Organiser_Logo_focalX_operator"]: ValueTypes["User_Organiser_Logo_focalX_operator"];
	["User_Organiser_Logo_focalY_operator"]: ValueTypes["User_Organiser_Logo_focalY_operator"];
	["User_Organiser_Logo_sizes__small__url_operator"]: ValueTypes["User_Organiser_Logo_sizes__small__url_operator"];
	["User_Organiser_Logo_sizes__small__width_operator"]: ValueTypes["User_Organiser_Logo_sizes__small__width_operator"];
	["User_Organiser_Logo_sizes__small__height_operator"]: ValueTypes["User_Organiser_Logo_sizes__small__height_operator"];
	["User_Organiser_Logo_sizes__small__mimeType_operator"]: ValueTypes["User_Organiser_Logo_sizes__small__mimeType_operator"];
	["User_Organiser_Logo_sizes__small__filesize_operator"]: ValueTypes["User_Organiser_Logo_sizes__small__filesize_operator"];
	["User_Organiser_Logo_sizes__small__filename_operator"]: ValueTypes["User_Organiser_Logo_sizes__small__filename_operator"];
	["User_Organiser_Logo_sizes__medium__url_operator"]: ValueTypes["User_Organiser_Logo_sizes__medium__url_operator"];
	["User_Organiser_Logo_sizes__medium__width_operator"]: ValueTypes["User_Organiser_Logo_sizes__medium__width_operator"];
	["User_Organiser_Logo_sizes__medium__height_operator"]: ValueTypes["User_Organiser_Logo_sizes__medium__height_operator"];
	["User_Organiser_Logo_sizes__medium__mimeType_operator"]: ValueTypes["User_Organiser_Logo_sizes__medium__mimeType_operator"];
	["User_Organiser_Logo_sizes__medium__filesize_operator"]: ValueTypes["User_Organiser_Logo_sizes__medium__filesize_operator"];
	["User_Organiser_Logo_sizes__medium__filename_operator"]: ValueTypes["User_Organiser_Logo_sizes__medium__filename_operator"];
	["User_Organiser_Logo_sizes__large__url_operator"]: ValueTypes["User_Organiser_Logo_sizes__large__url_operator"];
	["User_Organiser_Logo_sizes__large__width_operator"]: ValueTypes["User_Organiser_Logo_sizes__large__width_operator"];
	["User_Organiser_Logo_sizes__large__height_operator"]: ValueTypes["User_Organiser_Logo_sizes__large__height_operator"];
	["User_Organiser_Logo_sizes__large__mimeType_operator"]: ValueTypes["User_Organiser_Logo_sizes__large__mimeType_operator"];
	["User_Organiser_Logo_sizes__large__filesize_operator"]: ValueTypes["User_Organiser_Logo_sizes__large__filesize_operator"];
	["User_Organiser_Logo_sizes__large__filename_operator"]: ValueTypes["User_Organiser_Logo_sizes__large__filename_operator"];
	["User_Organiser_Logo_sizes__small_jpeg__url_operator"]: ValueTypes["User_Organiser_Logo_sizes__small_jpeg__url_operator"];
	["User_Organiser_Logo_sizes__small_jpeg__width_operator"]: ValueTypes["User_Organiser_Logo_sizes__small_jpeg__width_operator"];
	["User_Organiser_Logo_sizes__small_jpeg__height_operator"]: ValueTypes["User_Organiser_Logo_sizes__small_jpeg__height_operator"];
	["User_Organiser_Logo_sizes__small_jpeg__mimeType_operator"]: ValueTypes["User_Organiser_Logo_sizes__small_jpeg__mimeType_operator"];
	["User_Organiser_Logo_sizes__small_jpeg__filesize_operator"]: ValueTypes["User_Organiser_Logo_sizes__small_jpeg__filesize_operator"];
	["User_Organiser_Logo_sizes__small_jpeg__filename_operator"]: ValueTypes["User_Organiser_Logo_sizes__small_jpeg__filename_operator"];
	["User_Organiser_Logo_sizes__medium_jpeg__url_operator"]: ValueTypes["User_Organiser_Logo_sizes__medium_jpeg__url_operator"];
	["User_Organiser_Logo_sizes__medium_jpeg__width_operator"]: ValueTypes["User_Organiser_Logo_sizes__medium_jpeg__width_operator"];
	["User_Organiser_Logo_sizes__medium_jpeg__height_operator"]: ValueTypes["User_Organiser_Logo_sizes__medium_jpeg__height_operator"];
	["User_Organiser_Logo_sizes__medium_jpeg__mimeType_operator"]: ValueTypes["User_Organiser_Logo_sizes__medium_jpeg__mimeType_operator"];
	["User_Organiser_Logo_sizes__medium_jpeg__filesize_operator"]: ValueTypes["User_Organiser_Logo_sizes__medium_jpeg__filesize_operator"];
	["User_Organiser_Logo_sizes__medium_jpeg__filename_operator"]: ValueTypes["User_Organiser_Logo_sizes__medium_jpeg__filename_operator"];
	["User_Organiser_Logo_sizes__large_jpeg__url_operator"]: ValueTypes["User_Organiser_Logo_sizes__large_jpeg__url_operator"];
	["User_Organiser_Logo_sizes__large_jpeg__width_operator"]: ValueTypes["User_Organiser_Logo_sizes__large_jpeg__width_operator"];
	["User_Organiser_Logo_sizes__large_jpeg__height_operator"]: ValueTypes["User_Organiser_Logo_sizes__large_jpeg__height_operator"];
	["User_Organiser_Logo_sizes__large_jpeg__mimeType_operator"]: ValueTypes["User_Organiser_Logo_sizes__large_jpeg__mimeType_operator"];
	["User_Organiser_Logo_sizes__large_jpeg__filesize_operator"]: ValueTypes["User_Organiser_Logo_sizes__large_jpeg__filesize_operator"];
	["User_Organiser_Logo_sizes__large_jpeg__filename_operator"]: ValueTypes["User_Organiser_Logo_sizes__large_jpeg__filename_operator"];
	["User_Organiser_Logo_id_operator"]: ValueTypes["User_Organiser_Logo_id_operator"];
	["User_Organiser_Logo_where_and"]: ValueTypes["User_Organiser_Logo_where_and"];
	["User_Organiser_Logo_where_or"]: ValueTypes["User_Organiser_Logo_where_or"];
	["User_Organiser_Banner_where"]: ValueTypes["User_Organiser_Banner_where"];
	["User_Organiser_Banner_alt_operator"]: ValueTypes["User_Organiser_Banner_alt_operator"];
	["User_Organiser_Banner_updatedAt_operator"]: ValueTypes["User_Organiser_Banner_updatedAt_operator"];
	["User_Organiser_Banner_createdAt_operator"]: ValueTypes["User_Organiser_Banner_createdAt_operator"];
	["User_Organiser_Banner_url_operator"]: ValueTypes["User_Organiser_Banner_url_operator"];
	["User_Organiser_Banner_filename_operator"]: ValueTypes["User_Organiser_Banner_filename_operator"];
	["User_Organiser_Banner_mimeType_operator"]: ValueTypes["User_Organiser_Banner_mimeType_operator"];
	["User_Organiser_Banner_filesize_operator"]: ValueTypes["User_Organiser_Banner_filesize_operator"];
	["User_Organiser_Banner_width_operator"]: ValueTypes["User_Organiser_Banner_width_operator"];
	["User_Organiser_Banner_height_operator"]: ValueTypes["User_Organiser_Banner_height_operator"];
	["User_Organiser_Banner_focalX_operator"]: ValueTypes["User_Organiser_Banner_focalX_operator"];
	["User_Organiser_Banner_focalY_operator"]: ValueTypes["User_Organiser_Banner_focalY_operator"];
	["User_Organiser_Banner_sizes__small__url_operator"]: ValueTypes["User_Organiser_Banner_sizes__small__url_operator"];
	["User_Organiser_Banner_sizes__small__width_operator"]: ValueTypes["User_Organiser_Banner_sizes__small__width_operator"];
	["User_Organiser_Banner_sizes__small__height_operator"]: ValueTypes["User_Organiser_Banner_sizes__small__height_operator"];
	["User_Organiser_Banner_sizes__small__mimeType_operator"]: ValueTypes["User_Organiser_Banner_sizes__small__mimeType_operator"];
	["User_Organiser_Banner_sizes__small__filesize_operator"]: ValueTypes["User_Organiser_Banner_sizes__small__filesize_operator"];
	["User_Organiser_Banner_sizes__small__filename_operator"]: ValueTypes["User_Organiser_Banner_sizes__small__filename_operator"];
	["User_Organiser_Banner_sizes__medium__url_operator"]: ValueTypes["User_Organiser_Banner_sizes__medium__url_operator"];
	["User_Organiser_Banner_sizes__medium__width_operator"]: ValueTypes["User_Organiser_Banner_sizes__medium__width_operator"];
	["User_Organiser_Banner_sizes__medium__height_operator"]: ValueTypes["User_Organiser_Banner_sizes__medium__height_operator"];
	["User_Organiser_Banner_sizes__medium__mimeType_operator"]: ValueTypes["User_Organiser_Banner_sizes__medium__mimeType_operator"];
	["User_Organiser_Banner_sizes__medium__filesize_operator"]: ValueTypes["User_Organiser_Banner_sizes__medium__filesize_operator"];
	["User_Organiser_Banner_sizes__medium__filename_operator"]: ValueTypes["User_Organiser_Banner_sizes__medium__filename_operator"];
	["User_Organiser_Banner_sizes__large__url_operator"]: ValueTypes["User_Organiser_Banner_sizes__large__url_operator"];
	["User_Organiser_Banner_sizes__large__width_operator"]: ValueTypes["User_Organiser_Banner_sizes__large__width_operator"];
	["User_Organiser_Banner_sizes__large__height_operator"]: ValueTypes["User_Organiser_Banner_sizes__large__height_operator"];
	["User_Organiser_Banner_sizes__large__mimeType_operator"]: ValueTypes["User_Organiser_Banner_sizes__large__mimeType_operator"];
	["User_Organiser_Banner_sizes__large__filesize_operator"]: ValueTypes["User_Organiser_Banner_sizes__large__filesize_operator"];
	["User_Organiser_Banner_sizes__large__filename_operator"]: ValueTypes["User_Organiser_Banner_sizes__large__filename_operator"];
	["User_Organiser_Banner_sizes__small_jpeg__url_operator"]: ValueTypes["User_Organiser_Banner_sizes__small_jpeg__url_operator"];
	["User_Organiser_Banner_sizes__small_jpeg__width_operator"]: ValueTypes["User_Organiser_Banner_sizes__small_jpeg__width_operator"];
	["User_Organiser_Banner_sizes__small_jpeg__height_operator"]: ValueTypes["User_Organiser_Banner_sizes__small_jpeg__height_operator"];
	["User_Organiser_Banner_sizes__small_jpeg__mimeType_operator"]: ValueTypes["User_Organiser_Banner_sizes__small_jpeg__mimeType_operator"];
	["User_Organiser_Banner_sizes__small_jpeg__filesize_operator"]: ValueTypes["User_Organiser_Banner_sizes__small_jpeg__filesize_operator"];
	["User_Organiser_Banner_sizes__small_jpeg__filename_operator"]: ValueTypes["User_Organiser_Banner_sizes__small_jpeg__filename_operator"];
	["User_Organiser_Banner_sizes__medium_jpeg__url_operator"]: ValueTypes["User_Organiser_Banner_sizes__medium_jpeg__url_operator"];
	["User_Organiser_Banner_sizes__medium_jpeg__width_operator"]: ValueTypes["User_Organiser_Banner_sizes__medium_jpeg__width_operator"];
	["User_Organiser_Banner_sizes__medium_jpeg__height_operator"]: ValueTypes["User_Organiser_Banner_sizes__medium_jpeg__height_operator"];
	["User_Organiser_Banner_sizes__medium_jpeg__mimeType_operator"]: ValueTypes["User_Organiser_Banner_sizes__medium_jpeg__mimeType_operator"];
	["User_Organiser_Banner_sizes__medium_jpeg__filesize_operator"]: ValueTypes["User_Organiser_Banner_sizes__medium_jpeg__filesize_operator"];
	["User_Organiser_Banner_sizes__medium_jpeg__filename_operator"]: ValueTypes["User_Organiser_Banner_sizes__medium_jpeg__filename_operator"];
	["User_Organiser_Banner_sizes__large_jpeg__url_operator"]: ValueTypes["User_Organiser_Banner_sizes__large_jpeg__url_operator"];
	["User_Organiser_Banner_sizes__large_jpeg__width_operator"]: ValueTypes["User_Organiser_Banner_sizes__large_jpeg__width_operator"];
	["User_Organiser_Banner_sizes__large_jpeg__height_operator"]: ValueTypes["User_Organiser_Banner_sizes__large_jpeg__height_operator"];
	["User_Organiser_Banner_sizes__large_jpeg__mimeType_operator"]: ValueTypes["User_Organiser_Banner_sizes__large_jpeg__mimeType_operator"];
	["User_Organiser_Banner_sizes__large_jpeg__filesize_operator"]: ValueTypes["User_Organiser_Banner_sizes__large_jpeg__filesize_operator"];
	["User_Organiser_Banner_sizes__large_jpeg__filename_operator"]: ValueTypes["User_Organiser_Banner_sizes__large_jpeg__filename_operator"];
	["User_Organiser_Banner_id_operator"]: ValueTypes["User_Organiser_Banner_id_operator"];
	["User_Organiser_Banner_where_and"]: ValueTypes["User_Organiser_Banner_where_and"];
	["User_Organiser_Banner_where_or"]: ValueTypes["User_Organiser_Banner_where_or"];
	["User_Organiser_language"]: ValueTypes["User_Organiser_language"];
	["User_status"]: ValueTypes["User_status"];
	["EmailAddress"]: ValueTypes["EmailAddress"];
	["Event_level"]: ValueTypes["Event_level"];
	["Event_Thumbnail_where"]: ValueTypes["Event_Thumbnail_where"];
	["Event_Thumbnail_alt_operator"]: ValueTypes["Event_Thumbnail_alt_operator"];
	["Event_Thumbnail_updatedAt_operator"]: ValueTypes["Event_Thumbnail_updatedAt_operator"];
	["Event_Thumbnail_createdAt_operator"]: ValueTypes["Event_Thumbnail_createdAt_operator"];
	["Event_Thumbnail_url_operator"]: ValueTypes["Event_Thumbnail_url_operator"];
	["Event_Thumbnail_filename_operator"]: ValueTypes["Event_Thumbnail_filename_operator"];
	["Event_Thumbnail_mimeType_operator"]: ValueTypes["Event_Thumbnail_mimeType_operator"];
	["Event_Thumbnail_filesize_operator"]: ValueTypes["Event_Thumbnail_filesize_operator"];
	["Event_Thumbnail_width_operator"]: ValueTypes["Event_Thumbnail_width_operator"];
	["Event_Thumbnail_height_operator"]: ValueTypes["Event_Thumbnail_height_operator"];
	["Event_Thumbnail_focalX_operator"]: ValueTypes["Event_Thumbnail_focalX_operator"];
	["Event_Thumbnail_focalY_operator"]: ValueTypes["Event_Thumbnail_focalY_operator"];
	["Event_Thumbnail_sizes__small__url_operator"]: ValueTypes["Event_Thumbnail_sizes__small__url_operator"];
	["Event_Thumbnail_sizes__small__width_operator"]: ValueTypes["Event_Thumbnail_sizes__small__width_operator"];
	["Event_Thumbnail_sizes__small__height_operator"]: ValueTypes["Event_Thumbnail_sizes__small__height_operator"];
	["Event_Thumbnail_sizes__small__mimeType_operator"]: ValueTypes["Event_Thumbnail_sizes__small__mimeType_operator"];
	["Event_Thumbnail_sizes__small__filesize_operator"]: ValueTypes["Event_Thumbnail_sizes__small__filesize_operator"];
	["Event_Thumbnail_sizes__small__filename_operator"]: ValueTypes["Event_Thumbnail_sizes__small__filename_operator"];
	["Event_Thumbnail_sizes__medium__url_operator"]: ValueTypes["Event_Thumbnail_sizes__medium__url_operator"];
	["Event_Thumbnail_sizes__medium__width_operator"]: ValueTypes["Event_Thumbnail_sizes__medium__width_operator"];
	["Event_Thumbnail_sizes__medium__height_operator"]: ValueTypes["Event_Thumbnail_sizes__medium__height_operator"];
	["Event_Thumbnail_sizes__medium__mimeType_operator"]: ValueTypes["Event_Thumbnail_sizes__medium__mimeType_operator"];
	["Event_Thumbnail_sizes__medium__filesize_operator"]: ValueTypes["Event_Thumbnail_sizes__medium__filesize_operator"];
	["Event_Thumbnail_sizes__medium__filename_operator"]: ValueTypes["Event_Thumbnail_sizes__medium__filename_operator"];
	["Event_Thumbnail_sizes__large__url_operator"]: ValueTypes["Event_Thumbnail_sizes__large__url_operator"];
	["Event_Thumbnail_sizes__large__width_operator"]: ValueTypes["Event_Thumbnail_sizes__large__width_operator"];
	["Event_Thumbnail_sizes__large__height_operator"]: ValueTypes["Event_Thumbnail_sizes__large__height_operator"];
	["Event_Thumbnail_sizes__large__mimeType_operator"]: ValueTypes["Event_Thumbnail_sizes__large__mimeType_operator"];
	["Event_Thumbnail_sizes__large__filesize_operator"]: ValueTypes["Event_Thumbnail_sizes__large__filesize_operator"];
	["Event_Thumbnail_sizes__large__filename_operator"]: ValueTypes["Event_Thumbnail_sizes__large__filename_operator"];
	["Event_Thumbnail_sizes__small_jpeg__url_operator"]: ValueTypes["Event_Thumbnail_sizes__small_jpeg__url_operator"];
	["Event_Thumbnail_sizes__small_jpeg__width_operator"]: ValueTypes["Event_Thumbnail_sizes__small_jpeg__width_operator"];
	["Event_Thumbnail_sizes__small_jpeg__height_operator"]: ValueTypes["Event_Thumbnail_sizes__small_jpeg__height_operator"];
	["Event_Thumbnail_sizes__small_jpeg__mimeType_operator"]: ValueTypes["Event_Thumbnail_sizes__small_jpeg__mimeType_operator"];
	["Event_Thumbnail_sizes__small_jpeg__filesize_operator"]: ValueTypes["Event_Thumbnail_sizes__small_jpeg__filesize_operator"];
	["Event_Thumbnail_sizes__small_jpeg__filename_operator"]: ValueTypes["Event_Thumbnail_sizes__small_jpeg__filename_operator"];
	["Event_Thumbnail_sizes__medium_jpeg__url_operator"]: ValueTypes["Event_Thumbnail_sizes__medium_jpeg__url_operator"];
	["Event_Thumbnail_sizes__medium_jpeg__width_operator"]: ValueTypes["Event_Thumbnail_sizes__medium_jpeg__width_operator"];
	["Event_Thumbnail_sizes__medium_jpeg__height_operator"]: ValueTypes["Event_Thumbnail_sizes__medium_jpeg__height_operator"];
	["Event_Thumbnail_sizes__medium_jpeg__mimeType_operator"]: ValueTypes["Event_Thumbnail_sizes__medium_jpeg__mimeType_operator"];
	["Event_Thumbnail_sizes__medium_jpeg__filesize_operator"]: ValueTypes["Event_Thumbnail_sizes__medium_jpeg__filesize_operator"];
	["Event_Thumbnail_sizes__medium_jpeg__filename_operator"]: ValueTypes["Event_Thumbnail_sizes__medium_jpeg__filename_operator"];
	["Event_Thumbnail_sizes__large_jpeg__url_operator"]: ValueTypes["Event_Thumbnail_sizes__large_jpeg__url_operator"];
	["Event_Thumbnail_sizes__large_jpeg__width_operator"]: ValueTypes["Event_Thumbnail_sizes__large_jpeg__width_operator"];
	["Event_Thumbnail_sizes__large_jpeg__height_operator"]: ValueTypes["Event_Thumbnail_sizes__large_jpeg__height_operator"];
	["Event_Thumbnail_sizes__large_jpeg__mimeType_operator"]: ValueTypes["Event_Thumbnail_sizes__large_jpeg__mimeType_operator"];
	["Event_Thumbnail_sizes__large_jpeg__filesize_operator"]: ValueTypes["Event_Thumbnail_sizes__large_jpeg__filesize_operator"];
	["Event_Thumbnail_sizes__large_jpeg__filename_operator"]: ValueTypes["Event_Thumbnail_sizes__large_jpeg__filename_operator"];
	["Event_Thumbnail_id_operator"]: ValueTypes["Event_Thumbnail_id_operator"];
	["Event_Thumbnail_where_and"]: ValueTypes["Event_Thumbnail_where_and"];
	["Event_Thumbnail_where_or"]: ValueTypes["Event_Thumbnail_where_or"];
	["Event_Banner_where"]: ValueTypes["Event_Banner_where"];
	["Event_Banner_alt_operator"]: ValueTypes["Event_Banner_alt_operator"];
	["Event_Banner_updatedAt_operator"]: ValueTypes["Event_Banner_updatedAt_operator"];
	["Event_Banner_createdAt_operator"]: ValueTypes["Event_Banner_createdAt_operator"];
	["Event_Banner_url_operator"]: ValueTypes["Event_Banner_url_operator"];
	["Event_Banner_filename_operator"]: ValueTypes["Event_Banner_filename_operator"];
	["Event_Banner_mimeType_operator"]: ValueTypes["Event_Banner_mimeType_operator"];
	["Event_Banner_filesize_operator"]: ValueTypes["Event_Banner_filesize_operator"];
	["Event_Banner_width_operator"]: ValueTypes["Event_Banner_width_operator"];
	["Event_Banner_height_operator"]: ValueTypes["Event_Banner_height_operator"];
	["Event_Banner_focalX_operator"]: ValueTypes["Event_Banner_focalX_operator"];
	["Event_Banner_focalY_operator"]: ValueTypes["Event_Banner_focalY_operator"];
	["Event_Banner_sizes__small__url_operator"]: ValueTypes["Event_Banner_sizes__small__url_operator"];
	["Event_Banner_sizes__small__width_operator"]: ValueTypes["Event_Banner_sizes__small__width_operator"];
	["Event_Banner_sizes__small__height_operator"]: ValueTypes["Event_Banner_sizes__small__height_operator"];
	["Event_Banner_sizes__small__mimeType_operator"]: ValueTypes["Event_Banner_sizes__small__mimeType_operator"];
	["Event_Banner_sizes__small__filesize_operator"]: ValueTypes["Event_Banner_sizes__small__filesize_operator"];
	["Event_Banner_sizes__small__filename_operator"]: ValueTypes["Event_Banner_sizes__small__filename_operator"];
	["Event_Banner_sizes__medium__url_operator"]: ValueTypes["Event_Banner_sizes__medium__url_operator"];
	["Event_Banner_sizes__medium__width_operator"]: ValueTypes["Event_Banner_sizes__medium__width_operator"];
	["Event_Banner_sizes__medium__height_operator"]: ValueTypes["Event_Banner_sizes__medium__height_operator"];
	["Event_Banner_sizes__medium__mimeType_operator"]: ValueTypes["Event_Banner_sizes__medium__mimeType_operator"];
	["Event_Banner_sizes__medium__filesize_operator"]: ValueTypes["Event_Banner_sizes__medium__filesize_operator"];
	["Event_Banner_sizes__medium__filename_operator"]: ValueTypes["Event_Banner_sizes__medium__filename_operator"];
	["Event_Banner_sizes__large__url_operator"]: ValueTypes["Event_Banner_sizes__large__url_operator"];
	["Event_Banner_sizes__large__width_operator"]: ValueTypes["Event_Banner_sizes__large__width_operator"];
	["Event_Banner_sizes__large__height_operator"]: ValueTypes["Event_Banner_sizes__large__height_operator"];
	["Event_Banner_sizes__large__mimeType_operator"]: ValueTypes["Event_Banner_sizes__large__mimeType_operator"];
	["Event_Banner_sizes__large__filesize_operator"]: ValueTypes["Event_Banner_sizes__large__filesize_operator"];
	["Event_Banner_sizes__large__filename_operator"]: ValueTypes["Event_Banner_sizes__large__filename_operator"];
	["Event_Banner_sizes__small_jpeg__url_operator"]: ValueTypes["Event_Banner_sizes__small_jpeg__url_operator"];
	["Event_Banner_sizes__small_jpeg__width_operator"]: ValueTypes["Event_Banner_sizes__small_jpeg__width_operator"];
	["Event_Banner_sizes__small_jpeg__height_operator"]: ValueTypes["Event_Banner_sizes__small_jpeg__height_operator"];
	["Event_Banner_sizes__small_jpeg__mimeType_operator"]: ValueTypes["Event_Banner_sizes__small_jpeg__mimeType_operator"];
	["Event_Banner_sizes__small_jpeg__filesize_operator"]: ValueTypes["Event_Banner_sizes__small_jpeg__filesize_operator"];
	["Event_Banner_sizes__small_jpeg__filename_operator"]: ValueTypes["Event_Banner_sizes__small_jpeg__filename_operator"];
	["Event_Banner_sizes__medium_jpeg__url_operator"]: ValueTypes["Event_Banner_sizes__medium_jpeg__url_operator"];
	["Event_Banner_sizes__medium_jpeg__width_operator"]: ValueTypes["Event_Banner_sizes__medium_jpeg__width_operator"];
	["Event_Banner_sizes__medium_jpeg__height_operator"]: ValueTypes["Event_Banner_sizes__medium_jpeg__height_operator"];
	["Event_Banner_sizes__medium_jpeg__mimeType_operator"]: ValueTypes["Event_Banner_sizes__medium_jpeg__mimeType_operator"];
	["Event_Banner_sizes__medium_jpeg__filesize_operator"]: ValueTypes["Event_Banner_sizes__medium_jpeg__filesize_operator"];
	["Event_Banner_sizes__medium_jpeg__filename_operator"]: ValueTypes["Event_Banner_sizes__medium_jpeg__filename_operator"];
	["Event_Banner_sizes__large_jpeg__url_operator"]: ValueTypes["Event_Banner_sizes__large_jpeg__url_operator"];
	["Event_Banner_sizes__large_jpeg__width_operator"]: ValueTypes["Event_Banner_sizes__large_jpeg__width_operator"];
	["Event_Banner_sizes__large_jpeg__height_operator"]: ValueTypes["Event_Banner_sizes__large_jpeg__height_operator"];
	["Event_Banner_sizes__large_jpeg__mimeType_operator"]: ValueTypes["Event_Banner_sizes__large_jpeg__mimeType_operator"];
	["Event_Banner_sizes__large_jpeg__filesize_operator"]: ValueTypes["Event_Banner_sizes__large_jpeg__filesize_operator"];
	["Event_Banner_sizes__large_jpeg__filename_operator"]: ValueTypes["Event_Banner_sizes__large_jpeg__filename_operator"];
	["Event_Banner_id_operator"]: ValueTypes["Event_Banner_id_operator"];
	["Event_Banner_where_and"]: ValueTypes["Event_Banner_where_and"];
	["Event_Banner_where_or"]: ValueTypes["Event_Banner_where_or"];
	["Event_MobileBanner_where"]: ValueTypes["Event_MobileBanner_where"];
	["Event_MobileBanner_alt_operator"]: ValueTypes["Event_MobileBanner_alt_operator"];
	["Event_MobileBanner_updatedAt_operator"]: ValueTypes["Event_MobileBanner_updatedAt_operator"];
	["Event_MobileBanner_createdAt_operator"]: ValueTypes["Event_MobileBanner_createdAt_operator"];
	["Event_MobileBanner_url_operator"]: ValueTypes["Event_MobileBanner_url_operator"];
	["Event_MobileBanner_filename_operator"]: ValueTypes["Event_MobileBanner_filename_operator"];
	["Event_MobileBanner_mimeType_operator"]: ValueTypes["Event_MobileBanner_mimeType_operator"];
	["Event_MobileBanner_filesize_operator"]: ValueTypes["Event_MobileBanner_filesize_operator"];
	["Event_MobileBanner_width_operator"]: ValueTypes["Event_MobileBanner_width_operator"];
	["Event_MobileBanner_height_operator"]: ValueTypes["Event_MobileBanner_height_operator"];
	["Event_MobileBanner_focalX_operator"]: ValueTypes["Event_MobileBanner_focalX_operator"];
	["Event_MobileBanner_focalY_operator"]: ValueTypes["Event_MobileBanner_focalY_operator"];
	["Event_MobileBanner_sizes__small__url_operator"]: ValueTypes["Event_MobileBanner_sizes__small__url_operator"];
	["Event_MobileBanner_sizes__small__width_operator"]: ValueTypes["Event_MobileBanner_sizes__small__width_operator"];
	["Event_MobileBanner_sizes__small__height_operator"]: ValueTypes["Event_MobileBanner_sizes__small__height_operator"];
	["Event_MobileBanner_sizes__small__mimeType_operator"]: ValueTypes["Event_MobileBanner_sizes__small__mimeType_operator"];
	["Event_MobileBanner_sizes__small__filesize_operator"]: ValueTypes["Event_MobileBanner_sizes__small__filesize_operator"];
	["Event_MobileBanner_sizes__small__filename_operator"]: ValueTypes["Event_MobileBanner_sizes__small__filename_operator"];
	["Event_MobileBanner_sizes__medium__url_operator"]: ValueTypes["Event_MobileBanner_sizes__medium__url_operator"];
	["Event_MobileBanner_sizes__medium__width_operator"]: ValueTypes["Event_MobileBanner_sizes__medium__width_operator"];
	["Event_MobileBanner_sizes__medium__height_operator"]: ValueTypes["Event_MobileBanner_sizes__medium__height_operator"];
	["Event_MobileBanner_sizes__medium__mimeType_operator"]: ValueTypes["Event_MobileBanner_sizes__medium__mimeType_operator"];
	["Event_MobileBanner_sizes__medium__filesize_operator"]: ValueTypes["Event_MobileBanner_sizes__medium__filesize_operator"];
	["Event_MobileBanner_sizes__medium__filename_operator"]: ValueTypes["Event_MobileBanner_sizes__medium__filename_operator"];
	["Event_MobileBanner_sizes__large__url_operator"]: ValueTypes["Event_MobileBanner_sizes__large__url_operator"];
	["Event_MobileBanner_sizes__large__width_operator"]: ValueTypes["Event_MobileBanner_sizes__large__width_operator"];
	["Event_MobileBanner_sizes__large__height_operator"]: ValueTypes["Event_MobileBanner_sizes__large__height_operator"];
	["Event_MobileBanner_sizes__large__mimeType_operator"]: ValueTypes["Event_MobileBanner_sizes__large__mimeType_operator"];
	["Event_MobileBanner_sizes__large__filesize_operator"]: ValueTypes["Event_MobileBanner_sizes__large__filesize_operator"];
	["Event_MobileBanner_sizes__large__filename_operator"]: ValueTypes["Event_MobileBanner_sizes__large__filename_operator"];
	["Event_MobileBanner_sizes__small_jpeg__url_operator"]: ValueTypes["Event_MobileBanner_sizes__small_jpeg__url_operator"];
	["Event_MobileBanner_sizes__small_jpeg__width_operator"]: ValueTypes["Event_MobileBanner_sizes__small_jpeg__width_operator"];
	["Event_MobileBanner_sizes__small_jpeg__height_operator"]: ValueTypes["Event_MobileBanner_sizes__small_jpeg__height_operator"];
	["Event_MobileBanner_sizes__small_jpeg__mimeType_operator"]: ValueTypes["Event_MobileBanner_sizes__small_jpeg__mimeType_operator"];
	["Event_MobileBanner_sizes__small_jpeg__filesize_operator"]: ValueTypes["Event_MobileBanner_sizes__small_jpeg__filesize_operator"];
	["Event_MobileBanner_sizes__small_jpeg__filename_operator"]: ValueTypes["Event_MobileBanner_sizes__small_jpeg__filename_operator"];
	["Event_MobileBanner_sizes__medium_jpeg__url_operator"]: ValueTypes["Event_MobileBanner_sizes__medium_jpeg__url_operator"];
	["Event_MobileBanner_sizes__medium_jpeg__width_operator"]: ValueTypes["Event_MobileBanner_sizes__medium_jpeg__width_operator"];
	["Event_MobileBanner_sizes__medium_jpeg__height_operator"]: ValueTypes["Event_MobileBanner_sizes__medium_jpeg__height_operator"];
	["Event_MobileBanner_sizes__medium_jpeg__mimeType_operator"]: ValueTypes["Event_MobileBanner_sizes__medium_jpeg__mimeType_operator"];
	["Event_MobileBanner_sizes__medium_jpeg__filesize_operator"]: ValueTypes["Event_MobileBanner_sizes__medium_jpeg__filesize_operator"];
	["Event_MobileBanner_sizes__medium_jpeg__filename_operator"]: ValueTypes["Event_MobileBanner_sizes__medium_jpeg__filename_operator"];
	["Event_MobileBanner_sizes__large_jpeg__url_operator"]: ValueTypes["Event_MobileBanner_sizes__large_jpeg__url_operator"];
	["Event_MobileBanner_sizes__large_jpeg__width_operator"]: ValueTypes["Event_MobileBanner_sizes__large_jpeg__width_operator"];
	["Event_MobileBanner_sizes__large_jpeg__height_operator"]: ValueTypes["Event_MobileBanner_sizes__large_jpeg__height_operator"];
	["Event_MobileBanner_sizes__large_jpeg__mimeType_operator"]: ValueTypes["Event_MobileBanner_sizes__large_jpeg__mimeType_operator"];
	["Event_MobileBanner_sizes__large_jpeg__filesize_operator"]: ValueTypes["Event_MobileBanner_sizes__large_jpeg__filesize_operator"];
	["Event_MobileBanner_sizes__large_jpeg__filename_operator"]: ValueTypes["Event_MobileBanner_sizes__large_jpeg__filename_operator"];
	["Event_MobileBanner_id_operator"]: ValueTypes["Event_MobileBanner_id_operator"];
	["Event_MobileBanner_where_and"]: ValueTypes["Event_MobileBanner_where_and"];
	["Event_MobileBanner_where_or"]: ValueTypes["Event_MobileBanner_where_or"];
	["Event_Sections_Recurrance_type"]: ValueTypes["Event_Sections_Recurrance_type"];
	["Event_Sections_Recurrance_weekday"]: ValueTypes["Event_Sections_Recurrance_weekday"];
	["Location_region"]: ValueTypes["Location_region"];
	["Location_level"]: ValueTypes["Location_level"];
	["Location_Thumbnail_where"]: ValueTypes["Location_Thumbnail_where"];
	["Location_Thumbnail_alt_operator"]: ValueTypes["Location_Thumbnail_alt_operator"];
	["Location_Thumbnail_updatedAt_operator"]: ValueTypes["Location_Thumbnail_updatedAt_operator"];
	["Location_Thumbnail_createdAt_operator"]: ValueTypes["Location_Thumbnail_createdAt_operator"];
	["Location_Thumbnail_url_operator"]: ValueTypes["Location_Thumbnail_url_operator"];
	["Location_Thumbnail_filename_operator"]: ValueTypes["Location_Thumbnail_filename_operator"];
	["Location_Thumbnail_mimeType_operator"]: ValueTypes["Location_Thumbnail_mimeType_operator"];
	["Location_Thumbnail_filesize_operator"]: ValueTypes["Location_Thumbnail_filesize_operator"];
	["Location_Thumbnail_width_operator"]: ValueTypes["Location_Thumbnail_width_operator"];
	["Location_Thumbnail_height_operator"]: ValueTypes["Location_Thumbnail_height_operator"];
	["Location_Thumbnail_focalX_operator"]: ValueTypes["Location_Thumbnail_focalX_operator"];
	["Location_Thumbnail_focalY_operator"]: ValueTypes["Location_Thumbnail_focalY_operator"];
	["Location_Thumbnail_sizes__small__url_operator"]: ValueTypes["Location_Thumbnail_sizes__small__url_operator"];
	["Location_Thumbnail_sizes__small__width_operator"]: ValueTypes["Location_Thumbnail_sizes__small__width_operator"];
	["Location_Thumbnail_sizes__small__height_operator"]: ValueTypes["Location_Thumbnail_sizes__small__height_operator"];
	["Location_Thumbnail_sizes__small__mimeType_operator"]: ValueTypes["Location_Thumbnail_sizes__small__mimeType_operator"];
	["Location_Thumbnail_sizes__small__filesize_operator"]: ValueTypes["Location_Thumbnail_sizes__small__filesize_operator"];
	["Location_Thumbnail_sizes__small__filename_operator"]: ValueTypes["Location_Thumbnail_sizes__small__filename_operator"];
	["Location_Thumbnail_sizes__medium__url_operator"]: ValueTypes["Location_Thumbnail_sizes__medium__url_operator"];
	["Location_Thumbnail_sizes__medium__width_operator"]: ValueTypes["Location_Thumbnail_sizes__medium__width_operator"];
	["Location_Thumbnail_sizes__medium__height_operator"]: ValueTypes["Location_Thumbnail_sizes__medium__height_operator"];
	["Location_Thumbnail_sizes__medium__mimeType_operator"]: ValueTypes["Location_Thumbnail_sizes__medium__mimeType_operator"];
	["Location_Thumbnail_sizes__medium__filesize_operator"]: ValueTypes["Location_Thumbnail_sizes__medium__filesize_operator"];
	["Location_Thumbnail_sizes__medium__filename_operator"]: ValueTypes["Location_Thumbnail_sizes__medium__filename_operator"];
	["Location_Thumbnail_sizes__large__url_operator"]: ValueTypes["Location_Thumbnail_sizes__large__url_operator"];
	["Location_Thumbnail_sizes__large__width_operator"]: ValueTypes["Location_Thumbnail_sizes__large__width_operator"];
	["Location_Thumbnail_sizes__large__height_operator"]: ValueTypes["Location_Thumbnail_sizes__large__height_operator"];
	["Location_Thumbnail_sizes__large__mimeType_operator"]: ValueTypes["Location_Thumbnail_sizes__large__mimeType_operator"];
	["Location_Thumbnail_sizes__large__filesize_operator"]: ValueTypes["Location_Thumbnail_sizes__large__filesize_operator"];
	["Location_Thumbnail_sizes__large__filename_operator"]: ValueTypes["Location_Thumbnail_sizes__large__filename_operator"];
	["Location_Thumbnail_sizes__small_jpeg__url_operator"]: ValueTypes["Location_Thumbnail_sizes__small_jpeg__url_operator"];
	["Location_Thumbnail_sizes__small_jpeg__width_operator"]: ValueTypes["Location_Thumbnail_sizes__small_jpeg__width_operator"];
	["Location_Thumbnail_sizes__small_jpeg__height_operator"]: ValueTypes["Location_Thumbnail_sizes__small_jpeg__height_operator"];
	["Location_Thumbnail_sizes__small_jpeg__mimeType_operator"]: ValueTypes["Location_Thumbnail_sizes__small_jpeg__mimeType_operator"];
	["Location_Thumbnail_sizes__small_jpeg__filesize_operator"]: ValueTypes["Location_Thumbnail_sizes__small_jpeg__filesize_operator"];
	["Location_Thumbnail_sizes__small_jpeg__filename_operator"]: ValueTypes["Location_Thumbnail_sizes__small_jpeg__filename_operator"];
	["Location_Thumbnail_sizes__medium_jpeg__url_operator"]: ValueTypes["Location_Thumbnail_sizes__medium_jpeg__url_operator"];
	["Location_Thumbnail_sizes__medium_jpeg__width_operator"]: ValueTypes["Location_Thumbnail_sizes__medium_jpeg__width_operator"];
	["Location_Thumbnail_sizes__medium_jpeg__height_operator"]: ValueTypes["Location_Thumbnail_sizes__medium_jpeg__height_operator"];
	["Location_Thumbnail_sizes__medium_jpeg__mimeType_operator"]: ValueTypes["Location_Thumbnail_sizes__medium_jpeg__mimeType_operator"];
	["Location_Thumbnail_sizes__medium_jpeg__filesize_operator"]: ValueTypes["Location_Thumbnail_sizes__medium_jpeg__filesize_operator"];
	["Location_Thumbnail_sizes__medium_jpeg__filename_operator"]: ValueTypes["Location_Thumbnail_sizes__medium_jpeg__filename_operator"];
	["Location_Thumbnail_sizes__large_jpeg__url_operator"]: ValueTypes["Location_Thumbnail_sizes__large_jpeg__url_operator"];
	["Location_Thumbnail_sizes__large_jpeg__width_operator"]: ValueTypes["Location_Thumbnail_sizes__large_jpeg__width_operator"];
	["Location_Thumbnail_sizes__large_jpeg__height_operator"]: ValueTypes["Location_Thumbnail_sizes__large_jpeg__height_operator"];
	["Location_Thumbnail_sizes__large_jpeg__mimeType_operator"]: ValueTypes["Location_Thumbnail_sizes__large_jpeg__mimeType_operator"];
	["Location_Thumbnail_sizes__large_jpeg__filesize_operator"]: ValueTypes["Location_Thumbnail_sizes__large_jpeg__filesize_operator"];
	["Location_Thumbnail_sizes__large_jpeg__filename_operator"]: ValueTypes["Location_Thumbnail_sizes__large_jpeg__filename_operator"];
	["Location_Thumbnail_id_operator"]: ValueTypes["Location_Thumbnail_id_operator"];
	["Location_Thumbnail_where_and"]: ValueTypes["Location_Thumbnail_where_and"];
	["Location_Thumbnail_where_or"]: ValueTypes["Location_Thumbnail_where_or"];
	["Location_Banner_where"]: ValueTypes["Location_Banner_where"];
	["Location_Banner_alt_operator"]: ValueTypes["Location_Banner_alt_operator"];
	["Location_Banner_updatedAt_operator"]: ValueTypes["Location_Banner_updatedAt_operator"];
	["Location_Banner_createdAt_operator"]: ValueTypes["Location_Banner_createdAt_operator"];
	["Location_Banner_url_operator"]: ValueTypes["Location_Banner_url_operator"];
	["Location_Banner_filename_operator"]: ValueTypes["Location_Banner_filename_operator"];
	["Location_Banner_mimeType_operator"]: ValueTypes["Location_Banner_mimeType_operator"];
	["Location_Banner_filesize_operator"]: ValueTypes["Location_Banner_filesize_operator"];
	["Location_Banner_width_operator"]: ValueTypes["Location_Banner_width_operator"];
	["Location_Banner_height_operator"]: ValueTypes["Location_Banner_height_operator"];
	["Location_Banner_focalX_operator"]: ValueTypes["Location_Banner_focalX_operator"];
	["Location_Banner_focalY_operator"]: ValueTypes["Location_Banner_focalY_operator"];
	["Location_Banner_sizes__small__url_operator"]: ValueTypes["Location_Banner_sizes__small__url_operator"];
	["Location_Banner_sizes__small__width_operator"]: ValueTypes["Location_Banner_sizes__small__width_operator"];
	["Location_Banner_sizes__small__height_operator"]: ValueTypes["Location_Banner_sizes__small__height_operator"];
	["Location_Banner_sizes__small__mimeType_operator"]: ValueTypes["Location_Banner_sizes__small__mimeType_operator"];
	["Location_Banner_sizes__small__filesize_operator"]: ValueTypes["Location_Banner_sizes__small__filesize_operator"];
	["Location_Banner_sizes__small__filename_operator"]: ValueTypes["Location_Banner_sizes__small__filename_operator"];
	["Location_Banner_sizes__medium__url_operator"]: ValueTypes["Location_Banner_sizes__medium__url_operator"];
	["Location_Banner_sizes__medium__width_operator"]: ValueTypes["Location_Banner_sizes__medium__width_operator"];
	["Location_Banner_sizes__medium__height_operator"]: ValueTypes["Location_Banner_sizes__medium__height_operator"];
	["Location_Banner_sizes__medium__mimeType_operator"]: ValueTypes["Location_Banner_sizes__medium__mimeType_operator"];
	["Location_Banner_sizes__medium__filesize_operator"]: ValueTypes["Location_Banner_sizes__medium__filesize_operator"];
	["Location_Banner_sizes__medium__filename_operator"]: ValueTypes["Location_Banner_sizes__medium__filename_operator"];
	["Location_Banner_sizes__large__url_operator"]: ValueTypes["Location_Banner_sizes__large__url_operator"];
	["Location_Banner_sizes__large__width_operator"]: ValueTypes["Location_Banner_sizes__large__width_operator"];
	["Location_Banner_sizes__large__height_operator"]: ValueTypes["Location_Banner_sizes__large__height_operator"];
	["Location_Banner_sizes__large__mimeType_operator"]: ValueTypes["Location_Banner_sizes__large__mimeType_operator"];
	["Location_Banner_sizes__large__filesize_operator"]: ValueTypes["Location_Banner_sizes__large__filesize_operator"];
	["Location_Banner_sizes__large__filename_operator"]: ValueTypes["Location_Banner_sizes__large__filename_operator"];
	["Location_Banner_sizes__small_jpeg__url_operator"]: ValueTypes["Location_Banner_sizes__small_jpeg__url_operator"];
	["Location_Banner_sizes__small_jpeg__width_operator"]: ValueTypes["Location_Banner_sizes__small_jpeg__width_operator"];
	["Location_Banner_sizes__small_jpeg__height_operator"]: ValueTypes["Location_Banner_sizes__small_jpeg__height_operator"];
	["Location_Banner_sizes__small_jpeg__mimeType_operator"]: ValueTypes["Location_Banner_sizes__small_jpeg__mimeType_operator"];
	["Location_Banner_sizes__small_jpeg__filesize_operator"]: ValueTypes["Location_Banner_sizes__small_jpeg__filesize_operator"];
	["Location_Banner_sizes__small_jpeg__filename_operator"]: ValueTypes["Location_Banner_sizes__small_jpeg__filename_operator"];
	["Location_Banner_sizes__medium_jpeg__url_operator"]: ValueTypes["Location_Banner_sizes__medium_jpeg__url_operator"];
	["Location_Banner_sizes__medium_jpeg__width_operator"]: ValueTypes["Location_Banner_sizes__medium_jpeg__width_operator"];
	["Location_Banner_sizes__medium_jpeg__height_operator"]: ValueTypes["Location_Banner_sizes__medium_jpeg__height_operator"];
	["Location_Banner_sizes__medium_jpeg__mimeType_operator"]: ValueTypes["Location_Banner_sizes__medium_jpeg__mimeType_operator"];
	["Location_Banner_sizes__medium_jpeg__filesize_operator"]: ValueTypes["Location_Banner_sizes__medium_jpeg__filesize_operator"];
	["Location_Banner_sizes__medium_jpeg__filename_operator"]: ValueTypes["Location_Banner_sizes__medium_jpeg__filename_operator"];
	["Location_Banner_sizes__large_jpeg__url_operator"]: ValueTypes["Location_Banner_sizes__large_jpeg__url_operator"];
	["Location_Banner_sizes__large_jpeg__width_operator"]: ValueTypes["Location_Banner_sizes__large_jpeg__width_operator"];
	["Location_Banner_sizes__large_jpeg__height_operator"]: ValueTypes["Location_Banner_sizes__large_jpeg__height_operator"];
	["Location_Banner_sizes__large_jpeg__mimeType_operator"]: ValueTypes["Location_Banner_sizes__large_jpeg__mimeType_operator"];
	["Location_Banner_sizes__large_jpeg__filesize_operator"]: ValueTypes["Location_Banner_sizes__large_jpeg__filesize_operator"];
	["Location_Banner_sizes__large_jpeg__filename_operator"]: ValueTypes["Location_Banner_sizes__large_jpeg__filename_operator"];
	["Location_Banner_id_operator"]: ValueTypes["Location_Banner_id_operator"];
	["Location_Banner_where_and"]: ValueTypes["Location_Banner_where_and"];
	["Location_Banner_where_or"]: ValueTypes["Location_Banner_where_or"];
	["Location_type"]: ValueTypes["Location_type"];
	["Location_category"]: ValueTypes["Location_category"];
	["District_region"]: ValueTypes["District_region"];
	["JSON"]: ValueTypes["JSON"];
	["Gallery_Gallery_Item_where"]: ValueTypes["Gallery_Gallery_Item_where"];
	["Gallery_Gallery_Item_alt_operator"]: ValueTypes["Gallery_Gallery_Item_alt_operator"];
	["Gallery_Gallery_Item_updatedAt_operator"]: ValueTypes["Gallery_Gallery_Item_updatedAt_operator"];
	["Gallery_Gallery_Item_createdAt_operator"]: ValueTypes["Gallery_Gallery_Item_createdAt_operator"];
	["Gallery_Gallery_Item_url_operator"]: ValueTypes["Gallery_Gallery_Item_url_operator"];
	["Gallery_Gallery_Item_filename_operator"]: ValueTypes["Gallery_Gallery_Item_filename_operator"];
	["Gallery_Gallery_Item_mimeType_operator"]: ValueTypes["Gallery_Gallery_Item_mimeType_operator"];
	["Gallery_Gallery_Item_filesize_operator"]: ValueTypes["Gallery_Gallery_Item_filesize_operator"];
	["Gallery_Gallery_Item_width_operator"]: ValueTypes["Gallery_Gallery_Item_width_operator"];
	["Gallery_Gallery_Item_height_operator"]: ValueTypes["Gallery_Gallery_Item_height_operator"];
	["Gallery_Gallery_Item_focalX_operator"]: ValueTypes["Gallery_Gallery_Item_focalX_operator"];
	["Gallery_Gallery_Item_focalY_operator"]: ValueTypes["Gallery_Gallery_Item_focalY_operator"];
	["Gallery_Gallery_Item_sizes__small__url_operator"]: ValueTypes["Gallery_Gallery_Item_sizes__small__url_operator"];
	["Gallery_Gallery_Item_sizes__small__width_operator"]: ValueTypes["Gallery_Gallery_Item_sizes__small__width_operator"];
	["Gallery_Gallery_Item_sizes__small__height_operator"]: ValueTypes["Gallery_Gallery_Item_sizes__small__height_operator"];
	["Gallery_Gallery_Item_sizes__small__mimeType_operator"]: ValueTypes["Gallery_Gallery_Item_sizes__small__mimeType_operator"];
	["Gallery_Gallery_Item_sizes__small__filesize_operator"]: ValueTypes["Gallery_Gallery_Item_sizes__small__filesize_operator"];
	["Gallery_Gallery_Item_sizes__small__filename_operator"]: ValueTypes["Gallery_Gallery_Item_sizes__small__filename_operator"];
	["Gallery_Gallery_Item_sizes__medium__url_operator"]: ValueTypes["Gallery_Gallery_Item_sizes__medium__url_operator"];
	["Gallery_Gallery_Item_sizes__medium__width_operator"]: ValueTypes["Gallery_Gallery_Item_sizes__medium__width_operator"];
	["Gallery_Gallery_Item_sizes__medium__height_operator"]: ValueTypes["Gallery_Gallery_Item_sizes__medium__height_operator"];
	["Gallery_Gallery_Item_sizes__medium__mimeType_operator"]: ValueTypes["Gallery_Gallery_Item_sizes__medium__mimeType_operator"];
	["Gallery_Gallery_Item_sizes__medium__filesize_operator"]: ValueTypes["Gallery_Gallery_Item_sizes__medium__filesize_operator"];
	["Gallery_Gallery_Item_sizes__medium__filename_operator"]: ValueTypes["Gallery_Gallery_Item_sizes__medium__filename_operator"];
	["Gallery_Gallery_Item_sizes__large__url_operator"]: ValueTypes["Gallery_Gallery_Item_sizes__large__url_operator"];
	["Gallery_Gallery_Item_sizes__large__width_operator"]: ValueTypes["Gallery_Gallery_Item_sizes__large__width_operator"];
	["Gallery_Gallery_Item_sizes__large__height_operator"]: ValueTypes["Gallery_Gallery_Item_sizes__large__height_operator"];
	["Gallery_Gallery_Item_sizes__large__mimeType_operator"]: ValueTypes["Gallery_Gallery_Item_sizes__large__mimeType_operator"];
	["Gallery_Gallery_Item_sizes__large__filesize_operator"]: ValueTypes["Gallery_Gallery_Item_sizes__large__filesize_operator"];
	["Gallery_Gallery_Item_sizes__large__filename_operator"]: ValueTypes["Gallery_Gallery_Item_sizes__large__filename_operator"];
	["Gallery_Gallery_Item_sizes__small_jpeg__url_operator"]: ValueTypes["Gallery_Gallery_Item_sizes__small_jpeg__url_operator"];
	["Gallery_Gallery_Item_sizes__small_jpeg__width_operator"]: ValueTypes["Gallery_Gallery_Item_sizes__small_jpeg__width_operator"];
	["Gallery_Gallery_Item_sizes__small_jpeg__height_operator"]: ValueTypes["Gallery_Gallery_Item_sizes__small_jpeg__height_operator"];
	["Gallery_Gallery_Item_sizes__small_jpeg__mimeType_operator"]: ValueTypes["Gallery_Gallery_Item_sizes__small_jpeg__mimeType_operator"];
	["Gallery_Gallery_Item_sizes__small_jpeg__filesize_operator"]: ValueTypes["Gallery_Gallery_Item_sizes__small_jpeg__filesize_operator"];
	["Gallery_Gallery_Item_sizes__small_jpeg__filename_operator"]: ValueTypes["Gallery_Gallery_Item_sizes__small_jpeg__filename_operator"];
	["Gallery_Gallery_Item_sizes__medium_jpeg__url_operator"]: ValueTypes["Gallery_Gallery_Item_sizes__medium_jpeg__url_operator"];
	["Gallery_Gallery_Item_sizes__medium_jpeg__width_operator"]: ValueTypes["Gallery_Gallery_Item_sizes__medium_jpeg__width_operator"];
	["Gallery_Gallery_Item_sizes__medium_jpeg__height_operator"]: ValueTypes["Gallery_Gallery_Item_sizes__medium_jpeg__height_operator"];
	["Gallery_Gallery_Item_sizes__medium_jpeg__mimeType_operator"]: ValueTypes["Gallery_Gallery_Item_sizes__medium_jpeg__mimeType_operator"];
	["Gallery_Gallery_Item_sizes__medium_jpeg__filesize_operator"]: ValueTypes["Gallery_Gallery_Item_sizes__medium_jpeg__filesize_operator"];
	["Gallery_Gallery_Item_sizes__medium_jpeg__filename_operator"]: ValueTypes["Gallery_Gallery_Item_sizes__medium_jpeg__filename_operator"];
	["Gallery_Gallery_Item_sizes__large_jpeg__url_operator"]: ValueTypes["Gallery_Gallery_Item_sizes__large_jpeg__url_operator"];
	["Gallery_Gallery_Item_sizes__large_jpeg__width_operator"]: ValueTypes["Gallery_Gallery_Item_sizes__large_jpeg__width_operator"];
	["Gallery_Gallery_Item_sizes__large_jpeg__height_operator"]: ValueTypes["Gallery_Gallery_Item_sizes__large_jpeg__height_operator"];
	["Gallery_Gallery_Item_sizes__large_jpeg__mimeType_operator"]: ValueTypes["Gallery_Gallery_Item_sizes__large_jpeg__mimeType_operator"];
	["Gallery_Gallery_Item_sizes__large_jpeg__filesize_operator"]: ValueTypes["Gallery_Gallery_Item_sizes__large_jpeg__filesize_operator"];
	["Gallery_Gallery_Item_sizes__large_jpeg__filename_operator"]: ValueTypes["Gallery_Gallery_Item_sizes__large_jpeg__filename_operator"];
	["Gallery_Gallery_Item_id_operator"]: ValueTypes["Gallery_Gallery_Item_id_operator"];
	["Gallery_Gallery_Item_where_and"]: ValueTypes["Gallery_Gallery_Item_where_and"];
	["Gallery_Gallery_Item_where_or"]: ValueTypes["Gallery_Gallery_Item_where_or"];
	["Image_Media_where"]: ValueTypes["Image_Media_where"];
	["Image_Media_alt_operator"]: ValueTypes["Image_Media_alt_operator"];
	["Image_Media_updatedAt_operator"]: ValueTypes["Image_Media_updatedAt_operator"];
	["Image_Media_createdAt_operator"]: ValueTypes["Image_Media_createdAt_operator"];
	["Image_Media_url_operator"]: ValueTypes["Image_Media_url_operator"];
	["Image_Media_filename_operator"]: ValueTypes["Image_Media_filename_operator"];
	["Image_Media_mimeType_operator"]: ValueTypes["Image_Media_mimeType_operator"];
	["Image_Media_filesize_operator"]: ValueTypes["Image_Media_filesize_operator"];
	["Image_Media_width_operator"]: ValueTypes["Image_Media_width_operator"];
	["Image_Media_height_operator"]: ValueTypes["Image_Media_height_operator"];
	["Image_Media_focalX_operator"]: ValueTypes["Image_Media_focalX_operator"];
	["Image_Media_focalY_operator"]: ValueTypes["Image_Media_focalY_operator"];
	["Image_Media_sizes__small__url_operator"]: ValueTypes["Image_Media_sizes__small__url_operator"];
	["Image_Media_sizes__small__width_operator"]: ValueTypes["Image_Media_sizes__small__width_operator"];
	["Image_Media_sizes__small__height_operator"]: ValueTypes["Image_Media_sizes__small__height_operator"];
	["Image_Media_sizes__small__mimeType_operator"]: ValueTypes["Image_Media_sizes__small__mimeType_operator"];
	["Image_Media_sizes__small__filesize_operator"]: ValueTypes["Image_Media_sizes__small__filesize_operator"];
	["Image_Media_sizes__small__filename_operator"]: ValueTypes["Image_Media_sizes__small__filename_operator"];
	["Image_Media_sizes__medium__url_operator"]: ValueTypes["Image_Media_sizes__medium__url_operator"];
	["Image_Media_sizes__medium__width_operator"]: ValueTypes["Image_Media_sizes__medium__width_operator"];
	["Image_Media_sizes__medium__height_operator"]: ValueTypes["Image_Media_sizes__medium__height_operator"];
	["Image_Media_sizes__medium__mimeType_operator"]: ValueTypes["Image_Media_sizes__medium__mimeType_operator"];
	["Image_Media_sizes__medium__filesize_operator"]: ValueTypes["Image_Media_sizes__medium__filesize_operator"];
	["Image_Media_sizes__medium__filename_operator"]: ValueTypes["Image_Media_sizes__medium__filename_operator"];
	["Image_Media_sizes__large__url_operator"]: ValueTypes["Image_Media_sizes__large__url_operator"];
	["Image_Media_sizes__large__width_operator"]: ValueTypes["Image_Media_sizes__large__width_operator"];
	["Image_Media_sizes__large__height_operator"]: ValueTypes["Image_Media_sizes__large__height_operator"];
	["Image_Media_sizes__large__mimeType_operator"]: ValueTypes["Image_Media_sizes__large__mimeType_operator"];
	["Image_Media_sizes__large__filesize_operator"]: ValueTypes["Image_Media_sizes__large__filesize_operator"];
	["Image_Media_sizes__large__filename_operator"]: ValueTypes["Image_Media_sizes__large__filename_operator"];
	["Image_Media_sizes__small_jpeg__url_operator"]: ValueTypes["Image_Media_sizes__small_jpeg__url_operator"];
	["Image_Media_sizes__small_jpeg__width_operator"]: ValueTypes["Image_Media_sizes__small_jpeg__width_operator"];
	["Image_Media_sizes__small_jpeg__height_operator"]: ValueTypes["Image_Media_sizes__small_jpeg__height_operator"];
	["Image_Media_sizes__small_jpeg__mimeType_operator"]: ValueTypes["Image_Media_sizes__small_jpeg__mimeType_operator"];
	["Image_Media_sizes__small_jpeg__filesize_operator"]: ValueTypes["Image_Media_sizes__small_jpeg__filesize_operator"];
	["Image_Media_sizes__small_jpeg__filename_operator"]: ValueTypes["Image_Media_sizes__small_jpeg__filename_operator"];
	["Image_Media_sizes__medium_jpeg__url_operator"]: ValueTypes["Image_Media_sizes__medium_jpeg__url_operator"];
	["Image_Media_sizes__medium_jpeg__width_operator"]: ValueTypes["Image_Media_sizes__medium_jpeg__width_operator"];
	["Image_Media_sizes__medium_jpeg__height_operator"]: ValueTypes["Image_Media_sizes__medium_jpeg__height_operator"];
	["Image_Media_sizes__medium_jpeg__mimeType_operator"]: ValueTypes["Image_Media_sizes__medium_jpeg__mimeType_operator"];
	["Image_Media_sizes__medium_jpeg__filesize_operator"]: ValueTypes["Image_Media_sizes__medium_jpeg__filesize_operator"];
	["Image_Media_sizes__medium_jpeg__filename_operator"]: ValueTypes["Image_Media_sizes__medium_jpeg__filename_operator"];
	["Image_Media_sizes__large_jpeg__url_operator"]: ValueTypes["Image_Media_sizes__large_jpeg__url_operator"];
	["Image_Media_sizes__large_jpeg__width_operator"]: ValueTypes["Image_Media_sizes__large_jpeg__width_operator"];
	["Image_Media_sizes__large_jpeg__height_operator"]: ValueTypes["Image_Media_sizes__large_jpeg__height_operator"];
	["Image_Media_sizes__large_jpeg__mimeType_operator"]: ValueTypes["Image_Media_sizes__large_jpeg__mimeType_operator"];
	["Image_Media_sizes__large_jpeg__filesize_operator"]: ValueTypes["Image_Media_sizes__large_jpeg__filesize_operator"];
	["Image_Media_sizes__large_jpeg__filename_operator"]: ValueTypes["Image_Media_sizes__large_jpeg__filename_operator"];
	["Image_Media_id_operator"]: ValueTypes["Image_Media_id_operator"];
	["Image_Media_where_and"]: ValueTypes["Image_Media_where_and"];
	["Image_Media_where_or"]: ValueTypes["Image_Media_where_or"];
	["Audience_Icon_where"]: ValueTypes["Audience_Icon_where"];
	["Audience_Icon_alt_operator"]: ValueTypes["Audience_Icon_alt_operator"];
	["Audience_Icon_updatedAt_operator"]: ValueTypes["Audience_Icon_updatedAt_operator"];
	["Audience_Icon_createdAt_operator"]: ValueTypes["Audience_Icon_createdAt_operator"];
	["Audience_Icon_url_operator"]: ValueTypes["Audience_Icon_url_operator"];
	["Audience_Icon_filename_operator"]: ValueTypes["Audience_Icon_filename_operator"];
	["Audience_Icon_mimeType_operator"]: ValueTypes["Audience_Icon_mimeType_operator"];
	["Audience_Icon_filesize_operator"]: ValueTypes["Audience_Icon_filesize_operator"];
	["Audience_Icon_width_operator"]: ValueTypes["Audience_Icon_width_operator"];
	["Audience_Icon_height_operator"]: ValueTypes["Audience_Icon_height_operator"];
	["Audience_Icon_focalX_operator"]: ValueTypes["Audience_Icon_focalX_operator"];
	["Audience_Icon_focalY_operator"]: ValueTypes["Audience_Icon_focalY_operator"];
	["Audience_Icon_sizes__small__url_operator"]: ValueTypes["Audience_Icon_sizes__small__url_operator"];
	["Audience_Icon_sizes__small__width_operator"]: ValueTypes["Audience_Icon_sizes__small__width_operator"];
	["Audience_Icon_sizes__small__height_operator"]: ValueTypes["Audience_Icon_sizes__small__height_operator"];
	["Audience_Icon_sizes__small__mimeType_operator"]: ValueTypes["Audience_Icon_sizes__small__mimeType_operator"];
	["Audience_Icon_sizes__small__filesize_operator"]: ValueTypes["Audience_Icon_sizes__small__filesize_operator"];
	["Audience_Icon_sizes__small__filename_operator"]: ValueTypes["Audience_Icon_sizes__small__filename_operator"];
	["Audience_Icon_sizes__medium__url_operator"]: ValueTypes["Audience_Icon_sizes__medium__url_operator"];
	["Audience_Icon_sizes__medium__width_operator"]: ValueTypes["Audience_Icon_sizes__medium__width_operator"];
	["Audience_Icon_sizes__medium__height_operator"]: ValueTypes["Audience_Icon_sizes__medium__height_operator"];
	["Audience_Icon_sizes__medium__mimeType_operator"]: ValueTypes["Audience_Icon_sizes__medium__mimeType_operator"];
	["Audience_Icon_sizes__medium__filesize_operator"]: ValueTypes["Audience_Icon_sizes__medium__filesize_operator"];
	["Audience_Icon_sizes__medium__filename_operator"]: ValueTypes["Audience_Icon_sizes__medium__filename_operator"];
	["Audience_Icon_sizes__large__url_operator"]: ValueTypes["Audience_Icon_sizes__large__url_operator"];
	["Audience_Icon_sizes__large__width_operator"]: ValueTypes["Audience_Icon_sizes__large__width_operator"];
	["Audience_Icon_sizes__large__height_operator"]: ValueTypes["Audience_Icon_sizes__large__height_operator"];
	["Audience_Icon_sizes__large__mimeType_operator"]: ValueTypes["Audience_Icon_sizes__large__mimeType_operator"];
	["Audience_Icon_sizes__large__filesize_operator"]: ValueTypes["Audience_Icon_sizes__large__filesize_operator"];
	["Audience_Icon_sizes__large__filename_operator"]: ValueTypes["Audience_Icon_sizes__large__filename_operator"];
	["Audience_Icon_sizes__small_jpeg__url_operator"]: ValueTypes["Audience_Icon_sizes__small_jpeg__url_operator"];
	["Audience_Icon_sizes__small_jpeg__width_operator"]: ValueTypes["Audience_Icon_sizes__small_jpeg__width_operator"];
	["Audience_Icon_sizes__small_jpeg__height_operator"]: ValueTypes["Audience_Icon_sizes__small_jpeg__height_operator"];
	["Audience_Icon_sizes__small_jpeg__mimeType_operator"]: ValueTypes["Audience_Icon_sizes__small_jpeg__mimeType_operator"];
	["Audience_Icon_sizes__small_jpeg__filesize_operator"]: ValueTypes["Audience_Icon_sizes__small_jpeg__filesize_operator"];
	["Audience_Icon_sizes__small_jpeg__filename_operator"]: ValueTypes["Audience_Icon_sizes__small_jpeg__filename_operator"];
	["Audience_Icon_sizes__medium_jpeg__url_operator"]: ValueTypes["Audience_Icon_sizes__medium_jpeg__url_operator"];
	["Audience_Icon_sizes__medium_jpeg__width_operator"]: ValueTypes["Audience_Icon_sizes__medium_jpeg__width_operator"];
	["Audience_Icon_sizes__medium_jpeg__height_operator"]: ValueTypes["Audience_Icon_sizes__medium_jpeg__height_operator"];
	["Audience_Icon_sizes__medium_jpeg__mimeType_operator"]: ValueTypes["Audience_Icon_sizes__medium_jpeg__mimeType_operator"];
	["Audience_Icon_sizes__medium_jpeg__filesize_operator"]: ValueTypes["Audience_Icon_sizes__medium_jpeg__filesize_operator"];
	["Audience_Icon_sizes__medium_jpeg__filename_operator"]: ValueTypes["Audience_Icon_sizes__medium_jpeg__filename_operator"];
	["Audience_Icon_sizes__large_jpeg__url_operator"]: ValueTypes["Audience_Icon_sizes__large_jpeg__url_operator"];
	["Audience_Icon_sizes__large_jpeg__width_operator"]: ValueTypes["Audience_Icon_sizes__large_jpeg__width_operator"];
	["Audience_Icon_sizes__large_jpeg__height_operator"]: ValueTypes["Audience_Icon_sizes__large_jpeg__height_operator"];
	["Audience_Icon_sizes__large_jpeg__mimeType_operator"]: ValueTypes["Audience_Icon_sizes__large_jpeg__mimeType_operator"];
	["Audience_Icon_sizes__large_jpeg__filesize_operator"]: ValueTypes["Audience_Icon_sizes__large_jpeg__filesize_operator"];
	["Audience_Icon_sizes__large_jpeg__filename_operator"]: ValueTypes["Audience_Icon_sizes__large_jpeg__filename_operator"];
	["Audience_Icon_id_operator"]: ValueTypes["Audience_Icon_id_operator"];
	["Audience_Icon_where_and"]: ValueTypes["Audience_Icon_where_and"];
	["Audience_Icon_where_or"]: ValueTypes["Audience_Icon_where_or"];
	["Audience_region"]: ValueTypes["Audience_region"];
	["Date_type"]: ValueTypes["Date_type"];
	["Date_region"]: ValueTypes["Date_region"];
	["Location_Sections_Recurrance_type"]: ValueTypes["Location_Sections_Recurrance_type"];
	["Location_Sections_Recurrance_weekday"]: ValueTypes["Location_Sections_Recurrance_weekday"];
	["Event_Ticketing_type"]: ValueTypes["Event_Ticketing_type"];
	["Event_Ticketing_ticketingType"]: ValueTypes["Event_Ticketing_ticketingType"];
	["Ticketing_status"]: ValueTypes["Ticketing_status"];
	["Ticketing_productType"]: ValueTypes["Ticketing_productType"];
	["Ticketing_CheckInLevel"]: ValueTypes["Ticketing_CheckInLevel"];
	["Event_Ticketing_promotionType"]: ValueTypes["Event_Ticketing_promotionType"];
	["SpecialTag_region"]: ValueTypes["SpecialTag_region"];
	["Product_status"]: ValueTypes["Product_status"];
	["Product_productType"]: ValueTypes["Product_productType"];
	["Product_CheckInLevel"]: ValueTypes["Product_CheckInLevel"];
	["Product_Thumbnail_where"]: ValueTypes["Product_Thumbnail_where"];
	["Product_Thumbnail_alt_operator"]: ValueTypes["Product_Thumbnail_alt_operator"];
	["Product_Thumbnail_updatedAt_operator"]: ValueTypes["Product_Thumbnail_updatedAt_operator"];
	["Product_Thumbnail_createdAt_operator"]: ValueTypes["Product_Thumbnail_createdAt_operator"];
	["Product_Thumbnail_url_operator"]: ValueTypes["Product_Thumbnail_url_operator"];
	["Product_Thumbnail_filename_operator"]: ValueTypes["Product_Thumbnail_filename_operator"];
	["Product_Thumbnail_mimeType_operator"]: ValueTypes["Product_Thumbnail_mimeType_operator"];
	["Product_Thumbnail_filesize_operator"]: ValueTypes["Product_Thumbnail_filesize_operator"];
	["Product_Thumbnail_width_operator"]: ValueTypes["Product_Thumbnail_width_operator"];
	["Product_Thumbnail_height_operator"]: ValueTypes["Product_Thumbnail_height_operator"];
	["Product_Thumbnail_focalX_operator"]: ValueTypes["Product_Thumbnail_focalX_operator"];
	["Product_Thumbnail_focalY_operator"]: ValueTypes["Product_Thumbnail_focalY_operator"];
	["Product_Thumbnail_sizes__small__url_operator"]: ValueTypes["Product_Thumbnail_sizes__small__url_operator"];
	["Product_Thumbnail_sizes__small__width_operator"]: ValueTypes["Product_Thumbnail_sizes__small__width_operator"];
	["Product_Thumbnail_sizes__small__height_operator"]: ValueTypes["Product_Thumbnail_sizes__small__height_operator"];
	["Product_Thumbnail_sizes__small__mimeType_operator"]: ValueTypes["Product_Thumbnail_sizes__small__mimeType_operator"];
	["Product_Thumbnail_sizes__small__filesize_operator"]: ValueTypes["Product_Thumbnail_sizes__small__filesize_operator"];
	["Product_Thumbnail_sizes__small__filename_operator"]: ValueTypes["Product_Thumbnail_sizes__small__filename_operator"];
	["Product_Thumbnail_sizes__medium__url_operator"]: ValueTypes["Product_Thumbnail_sizes__medium__url_operator"];
	["Product_Thumbnail_sizes__medium__width_operator"]: ValueTypes["Product_Thumbnail_sizes__medium__width_operator"];
	["Product_Thumbnail_sizes__medium__height_operator"]: ValueTypes["Product_Thumbnail_sizes__medium__height_operator"];
	["Product_Thumbnail_sizes__medium__mimeType_operator"]: ValueTypes["Product_Thumbnail_sizes__medium__mimeType_operator"];
	["Product_Thumbnail_sizes__medium__filesize_operator"]: ValueTypes["Product_Thumbnail_sizes__medium__filesize_operator"];
	["Product_Thumbnail_sizes__medium__filename_operator"]: ValueTypes["Product_Thumbnail_sizes__medium__filename_operator"];
	["Product_Thumbnail_sizes__large__url_operator"]: ValueTypes["Product_Thumbnail_sizes__large__url_operator"];
	["Product_Thumbnail_sizes__large__width_operator"]: ValueTypes["Product_Thumbnail_sizes__large__width_operator"];
	["Product_Thumbnail_sizes__large__height_operator"]: ValueTypes["Product_Thumbnail_sizes__large__height_operator"];
	["Product_Thumbnail_sizes__large__mimeType_operator"]: ValueTypes["Product_Thumbnail_sizes__large__mimeType_operator"];
	["Product_Thumbnail_sizes__large__filesize_operator"]: ValueTypes["Product_Thumbnail_sizes__large__filesize_operator"];
	["Product_Thumbnail_sizes__large__filename_operator"]: ValueTypes["Product_Thumbnail_sizes__large__filename_operator"];
	["Product_Thumbnail_sizes__small_jpeg__url_operator"]: ValueTypes["Product_Thumbnail_sizes__small_jpeg__url_operator"];
	["Product_Thumbnail_sizes__small_jpeg__width_operator"]: ValueTypes["Product_Thumbnail_sizes__small_jpeg__width_operator"];
	["Product_Thumbnail_sizes__small_jpeg__height_operator"]: ValueTypes["Product_Thumbnail_sizes__small_jpeg__height_operator"];
	["Product_Thumbnail_sizes__small_jpeg__mimeType_operator"]: ValueTypes["Product_Thumbnail_sizes__small_jpeg__mimeType_operator"];
	["Product_Thumbnail_sizes__small_jpeg__filesize_operator"]: ValueTypes["Product_Thumbnail_sizes__small_jpeg__filesize_operator"];
	["Product_Thumbnail_sizes__small_jpeg__filename_operator"]: ValueTypes["Product_Thumbnail_sizes__small_jpeg__filename_operator"];
	["Product_Thumbnail_sizes__medium_jpeg__url_operator"]: ValueTypes["Product_Thumbnail_sizes__medium_jpeg__url_operator"];
	["Product_Thumbnail_sizes__medium_jpeg__width_operator"]: ValueTypes["Product_Thumbnail_sizes__medium_jpeg__width_operator"];
	["Product_Thumbnail_sizes__medium_jpeg__height_operator"]: ValueTypes["Product_Thumbnail_sizes__medium_jpeg__height_operator"];
	["Product_Thumbnail_sizes__medium_jpeg__mimeType_operator"]: ValueTypes["Product_Thumbnail_sizes__medium_jpeg__mimeType_operator"];
	["Product_Thumbnail_sizes__medium_jpeg__filesize_operator"]: ValueTypes["Product_Thumbnail_sizes__medium_jpeg__filesize_operator"];
	["Product_Thumbnail_sizes__medium_jpeg__filename_operator"]: ValueTypes["Product_Thumbnail_sizes__medium_jpeg__filename_operator"];
	["Product_Thumbnail_sizes__large_jpeg__url_operator"]: ValueTypes["Product_Thumbnail_sizes__large_jpeg__url_operator"];
	["Product_Thumbnail_sizes__large_jpeg__width_operator"]: ValueTypes["Product_Thumbnail_sizes__large_jpeg__width_operator"];
	["Product_Thumbnail_sizes__large_jpeg__height_operator"]: ValueTypes["Product_Thumbnail_sizes__large_jpeg__height_operator"];
	["Product_Thumbnail_sizes__large_jpeg__mimeType_operator"]: ValueTypes["Product_Thumbnail_sizes__large_jpeg__mimeType_operator"];
	["Product_Thumbnail_sizes__large_jpeg__filesize_operator"]: ValueTypes["Product_Thumbnail_sizes__large_jpeg__filesize_operator"];
	["Product_Thumbnail_sizes__large_jpeg__filename_operator"]: ValueTypes["Product_Thumbnail_sizes__large_jpeg__filename_operator"];
	["Product_Thumbnail_id_operator"]: ValueTypes["Product_Thumbnail_id_operator"];
	["Product_Thumbnail_where_and"]: ValueTypes["Product_Thumbnail_where_and"];
	["Product_Thumbnail_where_or"]: ValueTypes["Product_Thumbnail_where_or"];
	["Product_transactionType"]: ValueTypes["Product_transactionType"];
	["Event_where"]: ValueTypes["Event_where"];
	["Event_region_operator"]: ValueTypes["Event_region_operator"];
	["Event_region_Input"]: ValueTypes["Event_region_Input"];
	["Event_creator_operator"]: ValueTypes["Event_creator_operator"];
	["Event_permalink_operator"]: ValueTypes["Event_permalink_operator"];
	["Event_status_operator"]: ValueTypes["Event_status_operator"];
	["Event_level_operator"]: ValueTypes["Event_level_operator"];
	["Event_level_Input"]: ValueTypes["Event_level_Input"];
	["Event_parent_operator"]: ValueTypes["Event_parent_operator"];
	["Event_name_operator"]: ValueTypes["Event_name_operator"];
	["Event_chatbotEventTitle_operator"]: ValueTypes["Event_chatbotEventTitle_operator"];
	["Event_chatbotSequence_operator"]: ValueTypes["Event_chatbotSequence_operator"];
	["Event_thumbnail_operator"]: ValueTypes["Event_thumbnail_operator"];
	["Event_banner_operator"]: ValueTypes["Event_banner_operator"];
	["Event_mobileBanner_operator"]: ValueTypes["Event_mobileBanner_operator"];
	["Event_sections__startDatetime_operator"]: ValueTypes["Event_sections__startDatetime_operator"];
	["Event_sections__endDatetime_operator"]: ValueTypes["Event_sections__endDatetime_operator"];
	["Event_sections__fullDay_operator"]: ValueTypes["Event_sections__fullDay_operator"];
	["Event_sections__repeat_operator"]: ValueTypes["Event_sections__repeat_operator"];
	["Event_sections__toThisDay_operator"]: ValueTypes["Event_sections__toThisDay_operator"];
	["Event_sections__recurrance__type_operator"]: ValueTypes["Event_sections__recurrance__type_operator"];
	["Event_sections__recurrance__type_Input"]: ValueTypes["Event_sections__recurrance__type_Input"];
	["Event_sections__recurrance__weekday_operator"]: ValueTypes["Event_sections__recurrance__weekday_operator"];
	["Event_sections__recurrance__weekday_Input"]: ValueTypes["Event_sections__recurrance__weekday_Input"];
	["Event_sections__sameLocation_operator"]: ValueTypes["Event_sections__sameLocation_operator"];
	["Event_sections__location_operator"]: ValueTypes["Event_sections__location_operator"];
	["Event_sections__district_operator"]: ValueTypes["Event_sections__district_operator"];
	["Event_sections__coordinate_operator"]: ValueTypes["Event_sections__coordinate_operator"];
	["GeoJSONObject"]: ValueTypes["GeoJSONObject"];
	["Event_sections__linkAddress_operator"]: ValueTypes["Event_sections__linkAddress_operator"];
	["Event_sections__address_operator"]: ValueTypes["Event_sections__address_operator"];
	["Event_sections__addressCoordinate_operator"]: ValueTypes["Event_sections__addressCoordinate_operator"];
	["Event_sections__id_operator"]: ValueTypes["Event_sections__id_operator"];
	["Event_ticketing__startDatetime_operator"]: ValueTypes["Event_ticketing__startDatetime_operator"];
	["Event_ticketing__endDatetime_operator"]: ValueTypes["Event_ticketing__endDatetime_operator"];
	["Event_ticketing__fullDay_operator"]: ValueTypes["Event_ticketing__fullDay_operator"];
	["Event_ticketing__type_operator"]: ValueTypes["Event_ticketing__type_operator"];
	["Event_ticketing__type_Input"]: ValueTypes["Event_ticketing__type_Input"];
	["Event_ticketing__ticketingType_operator"]: ValueTypes["Event_ticketing__ticketingType_operator"];
	["Event_ticketing__ticketingType_Input"]: ValueTypes["Event_ticketing__ticketingType_Input"];
	["Event_ticketing__linkedTicketing_operator"]: ValueTypes["Event_ticketing__linkedTicketing_operator"];
	["Event_ticketing__promotionType_operator"]: ValueTypes["Event_ticketing__promotionType_operator"];
	["Event_ticketing__promotionType_Input"]: ValueTypes["Event_ticketing__promotionType_Input"];
	["Event_ticketing__location_operator"]: ValueTypes["Event_ticketing__location_operator"];
	["Event_ticketing__website_operator"]: ValueTypes["Event_ticketing__website_operator"];
	["Event_ticketing__description_operator"]: ValueTypes["Event_ticketing__description_operator"];
	["Event_ticketing__id_operator"]: ValueTypes["Event_ticketing__id_operator"];
	["Event_legacyContent_operator"]: ValueTypes["Event_legacyContent_operator"];
	["Event_showLegacyContent_operator"]: ValueTypes["Event_showLegacyContent_operator"];
	["Event_criteria__categories_operator"]: ValueTypes["Event_criteria__categories_operator"];
	["Event_criteria__audiences_operator"]: ValueTypes["Event_criteria__audiences_operator"];
	["Event_criteria__tags_operator"]: ValueTypes["Event_criteria__tags_operator"];
	["Event_criteria__specialTags_operator"]: ValueTypes["Event_criteria__specialTags_operator"];
	["Event_walkIn_operator"]: ValueTypes["Event_walkIn_operator"];
	["Event_contact__code_operator"]: ValueTypes["Event_contact__code_operator"];
	["Event_contact__phone_operator"]: ValueTypes["Event_contact__phone_operator"];
	["Event_contact__page_operator"]: ValueTypes["Event_contact__page_operator"];
	["Event_contact__email_operator"]: ValueTypes["Event_contact__email_operator"];
	["Event_contact__privacy_operator"]: ValueTypes["Event_contact__privacy_operator"];
	["Event_views_operator"]: ValueTypes["Event_views_operator"];
	["Event_free_operator"]: ValueTypes["Event_free_operator"];
	["Event_fees__fee_operator"]: ValueTypes["Event_fees__fee_operator"];
	["Event_fees__remark_operator"]: ValueTypes["Event_fees__remark_operator"];
	["Event_fees__id_operator"]: ValueTypes["Event_fees__id_operator"];
	["Event_homePageInternalClick_operator"]: ValueTypes["Event_homePageInternalClick_operator"];
	["Event_homePageImpression_operator"]: ValueTypes["Event_homePageImpression_operator"];
	["Event_eodClickRate_operator"]: ValueTypes["Event_eodClickRate_operator"];
	["Event_eodImpressionRate_operator"]: ValueTypes["Event_eodImpressionRate_operator"];
	["Event_top10ClickRateHomepage_operator"]: ValueTypes["Event_top10ClickRateHomepage_operator"];
	["Event_top10ImpressionkRateHomepage_operator"]: ValueTypes["Event_top10ImpressionkRateHomepage_operator"];
	["Event_otherEventRankingClickRateHomepage_operator"]: ValueTypes["Event_otherEventRankingClickRateHomepage_operator"];
	["Event_otherEventRankingImpressionHomePage_operator"]: ValueTypes["Event_otherEventRankingImpressionHomePage_operator"];
	["Event_userPreferenceClickRate_operator"]: ValueTypes["Event_userPreferenceClickRate_operator"];
	["Event_userPreferenceImpression_operator"]: ValueTypes["Event_userPreferenceImpression_operator"];
	["Event_eventSearchInternalClick_operator"]: ValueTypes["Event_eventSearchInternalClick_operator"];
	["Event_eventListImpression_operator"]: ValueTypes["Event_eventListImpression_operator"];
	["Event_latestEventInternalClick_operator"]: ValueTypes["Event_latestEventInternalClick_operator"];
	["Event_latestEventImpression_operator"]: ValueTypes["Event_latestEventImpression_operator"];
	["Event_topTenEventRankingInternalClick_operator"]: ValueTypes["Event_topTenEventRankingInternalClick_operator"];
	["Event_topTenImpression_operator"]: ValueTypes["Event_topTenImpression_operator"];
	["Event_editorChoiceInternalClick_operator"]: ValueTypes["Event_editorChoiceInternalClick_operator"];
	["Event_editorChoiceImpression_operator"]: ValueTypes["Event_editorChoiceImpression_operator"];
	["Event_otherEventRankingInternalClick_operator"]: ValueTypes["Event_otherEventRankingInternalClick_operator"];
	["Event_otherEventRankingImpression_operator"]: ValueTypes["Event_otherEventRankingImpression_operator"];
	["Event_bannerInternalClick_operator"]: ValueTypes["Event_bannerInternalClick_operator"];
	["Event_miscImpression_operator"]: ValueTypes["Event_miscImpression_operator"];
	["Event_miscInternalClick_operator"]: ValueTypes["Event_miscInternalClick_operator"];
	["Event_totalImpression_operator"]: ValueTypes["Event_totalImpression_operator"];
	["Event_internalClick_operator"]: ValueTypes["Event_internalClick_operator"];
	["Event_outboundClick_operator"]: ValueTypes["Event_outboundClick_operator"];
	["Event_externalClick_operator"]: ValueTypes["Event_externalClick_operator"];
	["Event__viewsCountByMonth_operator"]: ValueTypes["Event__viewsCountByMonth_operator"];
	["Event_products__startDateTime_operator"]: ValueTypes["Event_products__startDateTime_operator"];
	["Event_products__endDateTime_operator"]: ValueTypes["Event_products__endDateTime_operator"];
	["Event_products__fullDay_operator"]: ValueTypes["Event_products__fullDay_operator"];
	["Event_products__linkedProduct_operator"]: ValueTypes["Event_products__linkedProduct_operator"];
	["Event_products__id_operator"]: ValueTypes["Event_products__id_operator"];
	["Event_legacyGallery_operator"]: ValueTypes["Event_legacyGallery_operator"];
	["Event_earliestStartDate_operator"]: ValueTypes["Event_earliestStartDate_operator"];
	["Event_latestEndDate_operator"]: ValueTypes["Event_latestEndDate_operator"];
	["Event_latestStartDateTime_operator"]: ValueTypes["Event_latestStartDateTime_operator"];
	["Event_hasTicketing_operator"]: ValueTypes["Event_hasTicketing_operator"];
	["Event_hasProducts_operator"]: ValueTypes["Event_hasProducts_operator"];
	["Event_updatedAt_operator"]: ValueTypes["Event_updatedAt_operator"];
	["Event_createdAt_operator"]: ValueTypes["Event_createdAt_operator"];
	["Event_id_operator"]: ValueTypes["Event_id_operator"];
	["Event_where_and"]: ValueTypes["Event_where_and"];
	["Event_where_or"]: ValueTypes["Event_where_or"];
	["JSONObject"]: ValueTypes["JSONObject"];
	["Location_where"]: ValueTypes["Location_where"];
	["Location_region_operator"]: ValueTypes["Location_region_operator"];
	["Location_region_Input"]: ValueTypes["Location_region_Input"];
	["Location_creator_operator"]: ValueTypes["Location_creator_operator"];
	["Location_permalink_operator"]: ValueTypes["Location_permalink_operator"];
	["Location_status_operator"]: ValueTypes["Location_status_operator"];
	["Location_level_operator"]: ValueTypes["Location_level_operator"];
	["Location_level_Input"]: ValueTypes["Location_level_Input"];
	["Location_parent_operator"]: ValueTypes["Location_parent_operator"];
	["Location_name_operator"]: ValueTypes["Location_name_operator"];
	["Location_thumbnail_operator"]: ValueTypes["Location_thumbnail_operator"];
	["Location_banner_operator"]: ValueTypes["Location_banner_operator"];
	["Location_type_operator"]: ValueTypes["Location_type_operator"];
	["Location_type_Input"]: ValueTypes["Location_type_Input"];
	["Location_category_operator"]: ValueTypes["Location_category_operator"];
	["Location_category_Input"]: ValueTypes["Location_category_Input"];
	["Location_district_operator"]: ValueTypes["Location_district_operator"];
	["Location_coordinate_operator"]: ValueTypes["Location_coordinate_operator"];
	["Location_linkAddress_operator"]: ValueTypes["Location_linkAddress_operator"];
	["Location_address_operator"]: ValueTypes["Location_address_operator"];
	["Location_Details__code_operator"]: ValueTypes["Location_Details__code_operator"];
	["Location_Details__phone_operator"]: ValueTypes["Location_Details__phone_operator"];
	["Location_Details__page_operator"]: ValueTypes["Location_Details__page_operator"];
	["Location_Details__email_operator"]: ValueTypes["Location_Details__email_operator"];
	["Location_legacyContent_operator"]: ValueTypes["Location_legacyContent_operator"];
	["Location_showLegacyContent_operator"]: ValueTypes["Location_showLegacyContent_operator"];
	["Location_criteria__audiences_operator"]: ValueTypes["Location_criteria__audiences_operator"];
	["Location_criteria__dates_operator"]: ValueTypes["Location_criteria__dates_operator"];
	["Location_criteria__categories_operator"]: ValueTypes["Location_criteria__categories_operator"];
	["Location_criteria__tags_operator"]: ValueTypes["Location_criteria__tags_operator"];
	["Location_sections__startDatetime_operator"]: ValueTypes["Location_sections__startDatetime_operator"];
	["Location_sections__endDatetime_operator"]: ValueTypes["Location_sections__endDatetime_operator"];
	["Location_sections__fullDay_operator"]: ValueTypes["Location_sections__fullDay_operator"];
	["Location_sections__repeat_operator"]: ValueTypes["Location_sections__repeat_operator"];
	["Location_sections__recurrance__type_operator"]: ValueTypes["Location_sections__recurrance__type_operator"];
	["Location_sections__recurrance__type_Input"]: ValueTypes["Location_sections__recurrance__type_Input"];
	["Location_sections__recurrance__weekday_operator"]: ValueTypes["Location_sections__recurrance__weekday_operator"];
	["Location_sections__recurrance__weekday_Input"]: ValueTypes["Location_sections__recurrance__weekday_Input"];
	["Location_sections__id_operator"]: ValueTypes["Location_sections__id_operator"];
	["Location__title_operator"]: ValueTypes["Location__title_operator"];
	["Location_legacyGallery_operator"]: ValueTypes["Location_legacyGallery_operator"];
	["Location_updatedAt_operator"]: ValueTypes["Location_updatedAt_operator"];
	["Location_createdAt_operator"]: ValueTypes["Location_createdAt_operator"];
	["Location_id_operator"]: ValueTypes["Location_id_operator"];
	["Location_where_and"]: ValueTypes["Location_where_and"];
	["Location_where_or"]: ValueTypes["Location_where_or"];
	["Post_region"]: ValueTypes["Post_region"];
	["BlogCategory_Icon_where"]: ValueTypes["BlogCategory_Icon_where"];
	["BlogCategory_Icon_alt_operator"]: ValueTypes["BlogCategory_Icon_alt_operator"];
	["BlogCategory_Icon_updatedAt_operator"]: ValueTypes["BlogCategory_Icon_updatedAt_operator"];
	["BlogCategory_Icon_createdAt_operator"]: ValueTypes["BlogCategory_Icon_createdAt_operator"];
	["BlogCategory_Icon_url_operator"]: ValueTypes["BlogCategory_Icon_url_operator"];
	["BlogCategory_Icon_filename_operator"]: ValueTypes["BlogCategory_Icon_filename_operator"];
	["BlogCategory_Icon_mimeType_operator"]: ValueTypes["BlogCategory_Icon_mimeType_operator"];
	["BlogCategory_Icon_filesize_operator"]: ValueTypes["BlogCategory_Icon_filesize_operator"];
	["BlogCategory_Icon_width_operator"]: ValueTypes["BlogCategory_Icon_width_operator"];
	["BlogCategory_Icon_height_operator"]: ValueTypes["BlogCategory_Icon_height_operator"];
	["BlogCategory_Icon_focalX_operator"]: ValueTypes["BlogCategory_Icon_focalX_operator"];
	["BlogCategory_Icon_focalY_operator"]: ValueTypes["BlogCategory_Icon_focalY_operator"];
	["BlogCategory_Icon_sizes__small__url_operator"]: ValueTypes["BlogCategory_Icon_sizes__small__url_operator"];
	["BlogCategory_Icon_sizes__small__width_operator"]: ValueTypes["BlogCategory_Icon_sizes__small__width_operator"];
	["BlogCategory_Icon_sizes__small__height_operator"]: ValueTypes["BlogCategory_Icon_sizes__small__height_operator"];
	["BlogCategory_Icon_sizes__small__mimeType_operator"]: ValueTypes["BlogCategory_Icon_sizes__small__mimeType_operator"];
	["BlogCategory_Icon_sizes__small__filesize_operator"]: ValueTypes["BlogCategory_Icon_sizes__small__filesize_operator"];
	["BlogCategory_Icon_sizes__small__filename_operator"]: ValueTypes["BlogCategory_Icon_sizes__small__filename_operator"];
	["BlogCategory_Icon_sizes__medium__url_operator"]: ValueTypes["BlogCategory_Icon_sizes__medium__url_operator"];
	["BlogCategory_Icon_sizes__medium__width_operator"]: ValueTypes["BlogCategory_Icon_sizes__medium__width_operator"];
	["BlogCategory_Icon_sizes__medium__height_operator"]: ValueTypes["BlogCategory_Icon_sizes__medium__height_operator"];
	["BlogCategory_Icon_sizes__medium__mimeType_operator"]: ValueTypes["BlogCategory_Icon_sizes__medium__mimeType_operator"];
	["BlogCategory_Icon_sizes__medium__filesize_operator"]: ValueTypes["BlogCategory_Icon_sizes__medium__filesize_operator"];
	["BlogCategory_Icon_sizes__medium__filename_operator"]: ValueTypes["BlogCategory_Icon_sizes__medium__filename_operator"];
	["BlogCategory_Icon_sizes__large__url_operator"]: ValueTypes["BlogCategory_Icon_sizes__large__url_operator"];
	["BlogCategory_Icon_sizes__large__width_operator"]: ValueTypes["BlogCategory_Icon_sizes__large__width_operator"];
	["BlogCategory_Icon_sizes__large__height_operator"]: ValueTypes["BlogCategory_Icon_sizes__large__height_operator"];
	["BlogCategory_Icon_sizes__large__mimeType_operator"]: ValueTypes["BlogCategory_Icon_sizes__large__mimeType_operator"];
	["BlogCategory_Icon_sizes__large__filesize_operator"]: ValueTypes["BlogCategory_Icon_sizes__large__filesize_operator"];
	["BlogCategory_Icon_sizes__large__filename_operator"]: ValueTypes["BlogCategory_Icon_sizes__large__filename_operator"];
	["BlogCategory_Icon_sizes__small_jpeg__url_operator"]: ValueTypes["BlogCategory_Icon_sizes__small_jpeg__url_operator"];
	["BlogCategory_Icon_sizes__small_jpeg__width_operator"]: ValueTypes["BlogCategory_Icon_sizes__small_jpeg__width_operator"];
	["BlogCategory_Icon_sizes__small_jpeg__height_operator"]: ValueTypes["BlogCategory_Icon_sizes__small_jpeg__height_operator"];
	["BlogCategory_Icon_sizes__small_jpeg__mimeType_operator"]: ValueTypes["BlogCategory_Icon_sizes__small_jpeg__mimeType_operator"];
	["BlogCategory_Icon_sizes__small_jpeg__filesize_operator"]: ValueTypes["BlogCategory_Icon_sizes__small_jpeg__filesize_operator"];
	["BlogCategory_Icon_sizes__small_jpeg__filename_operator"]: ValueTypes["BlogCategory_Icon_sizes__small_jpeg__filename_operator"];
	["BlogCategory_Icon_sizes__medium_jpeg__url_operator"]: ValueTypes["BlogCategory_Icon_sizes__medium_jpeg__url_operator"];
	["BlogCategory_Icon_sizes__medium_jpeg__width_operator"]: ValueTypes["BlogCategory_Icon_sizes__medium_jpeg__width_operator"];
	["BlogCategory_Icon_sizes__medium_jpeg__height_operator"]: ValueTypes["BlogCategory_Icon_sizes__medium_jpeg__height_operator"];
	["BlogCategory_Icon_sizes__medium_jpeg__mimeType_operator"]: ValueTypes["BlogCategory_Icon_sizes__medium_jpeg__mimeType_operator"];
	["BlogCategory_Icon_sizes__medium_jpeg__filesize_operator"]: ValueTypes["BlogCategory_Icon_sizes__medium_jpeg__filesize_operator"];
	["BlogCategory_Icon_sizes__medium_jpeg__filename_operator"]: ValueTypes["BlogCategory_Icon_sizes__medium_jpeg__filename_operator"];
	["BlogCategory_Icon_sizes__large_jpeg__url_operator"]: ValueTypes["BlogCategory_Icon_sizes__large_jpeg__url_operator"];
	["BlogCategory_Icon_sizes__large_jpeg__width_operator"]: ValueTypes["BlogCategory_Icon_sizes__large_jpeg__width_operator"];
	["BlogCategory_Icon_sizes__large_jpeg__height_operator"]: ValueTypes["BlogCategory_Icon_sizes__large_jpeg__height_operator"];
	["BlogCategory_Icon_sizes__large_jpeg__mimeType_operator"]: ValueTypes["BlogCategory_Icon_sizes__large_jpeg__mimeType_operator"];
	["BlogCategory_Icon_sizes__large_jpeg__filesize_operator"]: ValueTypes["BlogCategory_Icon_sizes__large_jpeg__filesize_operator"];
	["BlogCategory_Icon_sizes__large_jpeg__filename_operator"]: ValueTypes["BlogCategory_Icon_sizes__large_jpeg__filename_operator"];
	["BlogCategory_Icon_id_operator"]: ValueTypes["BlogCategory_Icon_id_operator"];
	["BlogCategory_Icon_where_and"]: ValueTypes["BlogCategory_Icon_where_and"];
	["BlogCategory_Icon_where_or"]: ValueTypes["BlogCategory_Icon_where_or"];
	["BlogCategory_region"]: ValueTypes["BlogCategory_region"];
	["Post_Thumbnail_where"]: ValueTypes["Post_Thumbnail_where"];
	["Post_Thumbnail_alt_operator"]: ValueTypes["Post_Thumbnail_alt_operator"];
	["Post_Thumbnail_updatedAt_operator"]: ValueTypes["Post_Thumbnail_updatedAt_operator"];
	["Post_Thumbnail_createdAt_operator"]: ValueTypes["Post_Thumbnail_createdAt_operator"];
	["Post_Thumbnail_url_operator"]: ValueTypes["Post_Thumbnail_url_operator"];
	["Post_Thumbnail_filename_operator"]: ValueTypes["Post_Thumbnail_filename_operator"];
	["Post_Thumbnail_mimeType_operator"]: ValueTypes["Post_Thumbnail_mimeType_operator"];
	["Post_Thumbnail_filesize_operator"]: ValueTypes["Post_Thumbnail_filesize_operator"];
	["Post_Thumbnail_width_operator"]: ValueTypes["Post_Thumbnail_width_operator"];
	["Post_Thumbnail_height_operator"]: ValueTypes["Post_Thumbnail_height_operator"];
	["Post_Thumbnail_focalX_operator"]: ValueTypes["Post_Thumbnail_focalX_operator"];
	["Post_Thumbnail_focalY_operator"]: ValueTypes["Post_Thumbnail_focalY_operator"];
	["Post_Thumbnail_sizes__small__url_operator"]: ValueTypes["Post_Thumbnail_sizes__small__url_operator"];
	["Post_Thumbnail_sizes__small__width_operator"]: ValueTypes["Post_Thumbnail_sizes__small__width_operator"];
	["Post_Thumbnail_sizes__small__height_operator"]: ValueTypes["Post_Thumbnail_sizes__small__height_operator"];
	["Post_Thumbnail_sizes__small__mimeType_operator"]: ValueTypes["Post_Thumbnail_sizes__small__mimeType_operator"];
	["Post_Thumbnail_sizes__small__filesize_operator"]: ValueTypes["Post_Thumbnail_sizes__small__filesize_operator"];
	["Post_Thumbnail_sizes__small__filename_operator"]: ValueTypes["Post_Thumbnail_sizes__small__filename_operator"];
	["Post_Thumbnail_sizes__medium__url_operator"]: ValueTypes["Post_Thumbnail_sizes__medium__url_operator"];
	["Post_Thumbnail_sizes__medium__width_operator"]: ValueTypes["Post_Thumbnail_sizes__medium__width_operator"];
	["Post_Thumbnail_sizes__medium__height_operator"]: ValueTypes["Post_Thumbnail_sizes__medium__height_operator"];
	["Post_Thumbnail_sizes__medium__mimeType_operator"]: ValueTypes["Post_Thumbnail_sizes__medium__mimeType_operator"];
	["Post_Thumbnail_sizes__medium__filesize_operator"]: ValueTypes["Post_Thumbnail_sizes__medium__filesize_operator"];
	["Post_Thumbnail_sizes__medium__filename_operator"]: ValueTypes["Post_Thumbnail_sizes__medium__filename_operator"];
	["Post_Thumbnail_sizes__large__url_operator"]: ValueTypes["Post_Thumbnail_sizes__large__url_operator"];
	["Post_Thumbnail_sizes__large__width_operator"]: ValueTypes["Post_Thumbnail_sizes__large__width_operator"];
	["Post_Thumbnail_sizes__large__height_operator"]: ValueTypes["Post_Thumbnail_sizes__large__height_operator"];
	["Post_Thumbnail_sizes__large__mimeType_operator"]: ValueTypes["Post_Thumbnail_sizes__large__mimeType_operator"];
	["Post_Thumbnail_sizes__large__filesize_operator"]: ValueTypes["Post_Thumbnail_sizes__large__filesize_operator"];
	["Post_Thumbnail_sizes__large__filename_operator"]: ValueTypes["Post_Thumbnail_sizes__large__filename_operator"];
	["Post_Thumbnail_sizes__small_jpeg__url_operator"]: ValueTypes["Post_Thumbnail_sizes__small_jpeg__url_operator"];
	["Post_Thumbnail_sizes__small_jpeg__width_operator"]: ValueTypes["Post_Thumbnail_sizes__small_jpeg__width_operator"];
	["Post_Thumbnail_sizes__small_jpeg__height_operator"]: ValueTypes["Post_Thumbnail_sizes__small_jpeg__height_operator"];
	["Post_Thumbnail_sizes__small_jpeg__mimeType_operator"]: ValueTypes["Post_Thumbnail_sizes__small_jpeg__mimeType_operator"];
	["Post_Thumbnail_sizes__small_jpeg__filesize_operator"]: ValueTypes["Post_Thumbnail_sizes__small_jpeg__filesize_operator"];
	["Post_Thumbnail_sizes__small_jpeg__filename_operator"]: ValueTypes["Post_Thumbnail_sizes__small_jpeg__filename_operator"];
	["Post_Thumbnail_sizes__medium_jpeg__url_operator"]: ValueTypes["Post_Thumbnail_sizes__medium_jpeg__url_operator"];
	["Post_Thumbnail_sizes__medium_jpeg__width_operator"]: ValueTypes["Post_Thumbnail_sizes__medium_jpeg__width_operator"];
	["Post_Thumbnail_sizes__medium_jpeg__height_operator"]: ValueTypes["Post_Thumbnail_sizes__medium_jpeg__height_operator"];
	["Post_Thumbnail_sizes__medium_jpeg__mimeType_operator"]: ValueTypes["Post_Thumbnail_sizes__medium_jpeg__mimeType_operator"];
	["Post_Thumbnail_sizes__medium_jpeg__filesize_operator"]: ValueTypes["Post_Thumbnail_sizes__medium_jpeg__filesize_operator"];
	["Post_Thumbnail_sizes__medium_jpeg__filename_operator"]: ValueTypes["Post_Thumbnail_sizes__medium_jpeg__filename_operator"];
	["Post_Thumbnail_sizes__large_jpeg__url_operator"]: ValueTypes["Post_Thumbnail_sizes__large_jpeg__url_operator"];
	["Post_Thumbnail_sizes__large_jpeg__width_operator"]: ValueTypes["Post_Thumbnail_sizes__large_jpeg__width_operator"];
	["Post_Thumbnail_sizes__large_jpeg__height_operator"]: ValueTypes["Post_Thumbnail_sizes__large_jpeg__height_operator"];
	["Post_Thumbnail_sizes__large_jpeg__mimeType_operator"]: ValueTypes["Post_Thumbnail_sizes__large_jpeg__mimeType_operator"];
	["Post_Thumbnail_sizes__large_jpeg__filesize_operator"]: ValueTypes["Post_Thumbnail_sizes__large_jpeg__filesize_operator"];
	["Post_Thumbnail_sizes__large_jpeg__filename_operator"]: ValueTypes["Post_Thumbnail_sizes__large_jpeg__filename_operator"];
	["Post_Thumbnail_id_operator"]: ValueTypes["Post_Thumbnail_id_operator"];
	["Post_Thumbnail_where_and"]: ValueTypes["Post_Thumbnail_where_and"];
	["Post_Thumbnail_where_or"]: ValueTypes["Post_Thumbnail_where_or"];
	["Post_Banner_where"]: ValueTypes["Post_Banner_where"];
	["Post_Banner_alt_operator"]: ValueTypes["Post_Banner_alt_operator"];
	["Post_Banner_updatedAt_operator"]: ValueTypes["Post_Banner_updatedAt_operator"];
	["Post_Banner_createdAt_operator"]: ValueTypes["Post_Banner_createdAt_operator"];
	["Post_Banner_url_operator"]: ValueTypes["Post_Banner_url_operator"];
	["Post_Banner_filename_operator"]: ValueTypes["Post_Banner_filename_operator"];
	["Post_Banner_mimeType_operator"]: ValueTypes["Post_Banner_mimeType_operator"];
	["Post_Banner_filesize_operator"]: ValueTypes["Post_Banner_filesize_operator"];
	["Post_Banner_width_operator"]: ValueTypes["Post_Banner_width_operator"];
	["Post_Banner_height_operator"]: ValueTypes["Post_Banner_height_operator"];
	["Post_Banner_focalX_operator"]: ValueTypes["Post_Banner_focalX_operator"];
	["Post_Banner_focalY_operator"]: ValueTypes["Post_Banner_focalY_operator"];
	["Post_Banner_sizes__small__url_operator"]: ValueTypes["Post_Banner_sizes__small__url_operator"];
	["Post_Banner_sizes__small__width_operator"]: ValueTypes["Post_Banner_sizes__small__width_operator"];
	["Post_Banner_sizes__small__height_operator"]: ValueTypes["Post_Banner_sizes__small__height_operator"];
	["Post_Banner_sizes__small__mimeType_operator"]: ValueTypes["Post_Banner_sizes__small__mimeType_operator"];
	["Post_Banner_sizes__small__filesize_operator"]: ValueTypes["Post_Banner_sizes__small__filesize_operator"];
	["Post_Banner_sizes__small__filename_operator"]: ValueTypes["Post_Banner_sizes__small__filename_operator"];
	["Post_Banner_sizes__medium__url_operator"]: ValueTypes["Post_Banner_sizes__medium__url_operator"];
	["Post_Banner_sizes__medium__width_operator"]: ValueTypes["Post_Banner_sizes__medium__width_operator"];
	["Post_Banner_sizes__medium__height_operator"]: ValueTypes["Post_Banner_sizes__medium__height_operator"];
	["Post_Banner_sizes__medium__mimeType_operator"]: ValueTypes["Post_Banner_sizes__medium__mimeType_operator"];
	["Post_Banner_sizes__medium__filesize_operator"]: ValueTypes["Post_Banner_sizes__medium__filesize_operator"];
	["Post_Banner_sizes__medium__filename_operator"]: ValueTypes["Post_Banner_sizes__medium__filename_operator"];
	["Post_Banner_sizes__large__url_operator"]: ValueTypes["Post_Banner_sizes__large__url_operator"];
	["Post_Banner_sizes__large__width_operator"]: ValueTypes["Post_Banner_sizes__large__width_operator"];
	["Post_Banner_sizes__large__height_operator"]: ValueTypes["Post_Banner_sizes__large__height_operator"];
	["Post_Banner_sizes__large__mimeType_operator"]: ValueTypes["Post_Banner_sizes__large__mimeType_operator"];
	["Post_Banner_sizes__large__filesize_operator"]: ValueTypes["Post_Banner_sizes__large__filesize_operator"];
	["Post_Banner_sizes__large__filename_operator"]: ValueTypes["Post_Banner_sizes__large__filename_operator"];
	["Post_Banner_sizes__small_jpeg__url_operator"]: ValueTypes["Post_Banner_sizes__small_jpeg__url_operator"];
	["Post_Banner_sizes__small_jpeg__width_operator"]: ValueTypes["Post_Banner_sizes__small_jpeg__width_operator"];
	["Post_Banner_sizes__small_jpeg__height_operator"]: ValueTypes["Post_Banner_sizes__small_jpeg__height_operator"];
	["Post_Banner_sizes__small_jpeg__mimeType_operator"]: ValueTypes["Post_Banner_sizes__small_jpeg__mimeType_operator"];
	["Post_Banner_sizes__small_jpeg__filesize_operator"]: ValueTypes["Post_Banner_sizes__small_jpeg__filesize_operator"];
	["Post_Banner_sizes__small_jpeg__filename_operator"]: ValueTypes["Post_Banner_sizes__small_jpeg__filename_operator"];
	["Post_Banner_sizes__medium_jpeg__url_operator"]: ValueTypes["Post_Banner_sizes__medium_jpeg__url_operator"];
	["Post_Banner_sizes__medium_jpeg__width_operator"]: ValueTypes["Post_Banner_sizes__medium_jpeg__width_operator"];
	["Post_Banner_sizes__medium_jpeg__height_operator"]: ValueTypes["Post_Banner_sizes__medium_jpeg__height_operator"];
	["Post_Banner_sizes__medium_jpeg__mimeType_operator"]: ValueTypes["Post_Banner_sizes__medium_jpeg__mimeType_operator"];
	["Post_Banner_sizes__medium_jpeg__filesize_operator"]: ValueTypes["Post_Banner_sizes__medium_jpeg__filesize_operator"];
	["Post_Banner_sizes__medium_jpeg__filename_operator"]: ValueTypes["Post_Banner_sizes__medium_jpeg__filename_operator"];
	["Post_Banner_sizes__large_jpeg__url_operator"]: ValueTypes["Post_Banner_sizes__large_jpeg__url_operator"];
	["Post_Banner_sizes__large_jpeg__width_operator"]: ValueTypes["Post_Banner_sizes__large_jpeg__width_operator"];
	["Post_Banner_sizes__large_jpeg__height_operator"]: ValueTypes["Post_Banner_sizes__large_jpeg__height_operator"];
	["Post_Banner_sizes__large_jpeg__mimeType_operator"]: ValueTypes["Post_Banner_sizes__large_jpeg__mimeType_operator"];
	["Post_Banner_sizes__large_jpeg__filesize_operator"]: ValueTypes["Post_Banner_sizes__large_jpeg__filesize_operator"];
	["Post_Banner_sizes__large_jpeg__filename_operator"]: ValueTypes["Post_Banner_sizes__large_jpeg__filename_operator"];
	["Post_Banner_id_operator"]: ValueTypes["Post_Banner_id_operator"];
	["Post_Banner_where_and"]: ValueTypes["Post_Banner_where_and"];
	["Post_Banner_where_or"]: ValueTypes["Post_Banner_where_or"];
	["Post_where"]: ValueTypes["Post_where"];
	["Post_title_operator"]: ValueTypes["Post_title_operator"];
	["Post_region_operator"]: ValueTypes["Post_region_operator"];
	["Post_region_Input"]: ValueTypes["Post_region_Input"];
	["Post_authur_operator"]: ValueTypes["Post_authur_operator"];
	["Post_publishDateTime_operator"]: ValueTypes["Post_publishDateTime_operator"];
	["Post_permalink_operator"]: ValueTypes["Post_permalink_operator"];
	["Post_status_operator"]: ValueTypes["Post_status_operator"];
	["Post_legacyContent_operator"]: ValueTypes["Post_legacyContent_operator"];
	["Post_showLegacyContent_operator"]: ValueTypes["Post_showLegacyContent_operator"];
	["Post_blogCategories_operator"]: ValueTypes["Post_blogCategories_operator"];
	["Post_thumbnail_operator"]: ValueTypes["Post_thumbnail_operator"];
	["Post_banner_operator"]: ValueTypes["Post_banner_operator"];
	["Post_criteria__audiences_operator"]: ValueTypes["Post_criteria__audiences_operator"];
	["Post_criteria__dates_operator"]: ValueTypes["Post_criteria__dates_operator"];
	["Post_criteria__categories_operator"]: ValueTypes["Post_criteria__categories_operator"];
	["Post_criteria__tags_operator"]: ValueTypes["Post_criteria__tags_operator"];
	["Post_location_operator"]: ValueTypes["Post_location_operator"];
	["Post_views_operator"]: ValueTypes["Post_views_operator"];
	["Post_homePageInternalClick_operator"]: ValueTypes["Post_homePageInternalClick_operator"];
	["Post_homePageImpression_operator"]: ValueTypes["Post_homePageImpression_operator"];
	["Post_blogListInternalClick_operator"]: ValueTypes["Post_blogListInternalClick_operator"];
	["Post_blogListImpression_operator"]: ValueTypes["Post_blogListImpression_operator"];
	["Post_blogDetailInternalClick_operator"]: ValueTypes["Post_blogDetailInternalClick_operator"];
	["Post_blogDetailImpression_operator"]: ValueTypes["Post_blogDetailImpression_operator"];
	["Post_relatedEventInternalClick_operator"]: ValueTypes["Post_relatedEventInternalClick_operator"];
	["Post_relatedEventImpression_operator"]: ValueTypes["Post_relatedEventImpression_operator"];
	["Post_latestEventInternalClick_operator"]: ValueTypes["Post_latestEventInternalClick_operator"];
	["Post_latestEventImpression_operator"]: ValueTypes["Post_latestEventImpression_operator"];
	["Post_internalClick_operator"]: ValueTypes["Post_internalClick_operator"];
	["Post_totalImpression_operator"]: ValueTypes["Post_totalImpression_operator"];
	["Post_externalClick_operator"]: ValueTypes["Post_externalClick_operator"];
	["Post_outboundClick_operator"]: ValueTypes["Post_outboundClick_operator"];
	["Post_legacyGallery_operator"]: ValueTypes["Post_legacyGallery_operator"];
	["Post_updatedAt_operator"]: ValueTypes["Post_updatedAt_operator"];
	["Post_createdAt_operator"]: ValueTypes["Post_createdAt_operator"];
	["Post_id_operator"]: ValueTypes["Post_id_operator"];
	["Post_where_and"]: ValueTypes["Post_where_and"];
	["Post_where_or"]: ValueTypes["Post_where_or"];
	["Ticketing_where"]: ValueTypes["Ticketing_where"];
	["Ticketing_status_operator"]: ValueTypes["Ticketing_status_operator"];
	["Ticketing_status_Input"]: ValueTypes["Ticketing_status_Input"];
	["Ticketing_creator_operator"]: ValueTypes["Ticketing_creator_operator"];
	["Ticketing_productType_operator"]: ValueTypes["Ticketing_productType_operator"];
	["Ticketing_productType_Input"]: ValueTypes["Ticketing_productType_Input"];
	["Ticketing_productName_operator"]: ValueTypes["Ticketing_productName_operator"];
	["Ticketing_referenceId_operator"]: ValueTypes["Ticketing_referenceId_operator"];
	["Ticketing_linkedEvent_operator"]: ValueTypes["Ticketing_linkedEvent_operator"];
	["Ticketing_CheckInLevel_operator"]: ValueTypes["Ticketing_CheckInLevel_operator"];
	["Ticketing_CheckInLevel_Input"]: ValueTypes["Ticketing_CheckInLevel_Input"];
	["Ticketing_displayPrice_operator"]: ValueTypes["Ticketing_displayPrice_operator"];
	["Ticketing_tnc_operator"]: ValueTypes["Ticketing_tnc_operator"];
	["Ticketing_cutoffHours_operator"]: ValueTypes["Ticketing_cutoffHours_operator"];
	["Ticketing_sessions__variantId_operator"]: ValueTypes["Ticketing_sessions__variantId_operator"];
	["Ticketing_sessions__productId_operator"]: ValueTypes["Ticketing_sessions__productId_operator"];
	["Ticketing_sessions__startDate_operator"]: ValueTypes["Ticketing_sessions__startDate_operator"];
	["Ticketing_sessions__endDate_operator"]: ValueTypes["Ticketing_sessions__endDate_operator"];
	["Ticketing_sessions__inventories__name_operator"]: ValueTypes["Ticketing_sessions__inventories__name_operator"];
	["Ticketing_sessions__inventories__quantity_operator"]: ValueTypes["Ticketing_sessions__inventories__quantity_operator"];
	["Ticketing_sessions__inventories__unitPrice_operator"]: ValueTypes["Ticketing_sessions__inventories__unitPrice_operator"];
	["Ticketing_sessions__inventories__handlingFee_operator"]: ValueTypes["Ticketing_sessions__inventories__handlingFee_operator"];
	["Ticketing_sessions__inventories__totalCost_operator"]: ValueTypes["Ticketing_sessions__inventories__totalCost_operator"];
	["Ticketing_sessions__inventories__minPurchase_operator"]: ValueTypes["Ticketing_sessions__inventories__minPurchase_operator"];
	["Ticketing_sessions__inventories__maxPurchase_operator"]: ValueTypes["Ticketing_sessions__inventories__maxPurchase_operator"];
	["Ticketing_sessions__inventories__stocks__stockId_operator"]: ValueTypes["Ticketing_sessions__inventories__stocks__stockId_operator"];
	["Ticketing_sessions__inventories__stocks__remaining_operator"]: ValueTypes["Ticketing_sessions__inventories__stocks__remaining_operator"];
	["Ticketing_sessions__inventories__stocks__sold_operator"]: ValueTypes["Ticketing_sessions__inventories__stocks__sold_operator"];
	["Ticketing_sessions__inventories__stocks__onHold_operator"]: ValueTypes["Ticketing_sessions__inventories__stocks__onHold_operator"];
	["Ticketing_sessions__inventories__stocks__checkedIn_operator"]: ValueTypes["Ticketing_sessions__inventories__stocks__checkedIn_operator"];
	["Ticketing_sessions__inventories__remarks_operator"]: ValueTypes["Ticketing_sessions__inventories__remarks_operator"];
	["Ticketing_sessions__id_operator"]: ValueTypes["Ticketing_sessions__id_operator"];
	["Ticketing_updatedAt_operator"]: ValueTypes["Ticketing_updatedAt_operator"];
	["Ticketing_createdAt_operator"]: ValueTypes["Ticketing_createdAt_operator"];
	["Ticketing_id_operator"]: ValueTypes["Ticketing_id_operator"];
	["Ticketing_where_and"]: ValueTypes["Ticketing_where_and"];
	["Ticketing_where_or"]: ValueTypes["Ticketing_where_or"];
	["Product_where"]: ValueTypes["Product_where"];
	["Product_status_operator"]: ValueTypes["Product_status_operator"];
	["Product_status_Input"]: ValueTypes["Product_status_Input"];
	["Product_creator_operator"]: ValueTypes["Product_creator_operator"];
	["Product_productType_operator"]: ValueTypes["Product_productType_operator"];
	["Product_productType_Input"]: ValueTypes["Product_productType_Input"];
	["Product_productName_operator"]: ValueTypes["Product_productName_operator"];
	["Product_referenceId_operator"]: ValueTypes["Product_referenceId_operator"];
	["Product_linkedEvent_operator"]: ValueTypes["Product_linkedEvent_operator"];
	["Product_CheckInLevel_operator"]: ValueTypes["Product_CheckInLevel_operator"];
	["Product_CheckInLevel_Input"]: ValueTypes["Product_CheckInLevel_Input"];
	["Product_thumbnail_operator"]: ValueTypes["Product_thumbnail_operator"];
	["Product_displayPrice_operator"]: ValueTypes["Product_displayPrice_operator"];
	["Product_transactionType_operator"]: ValueTypes["Product_transactionType_operator"];
	["Product_transactionType_Input"]: ValueTypes["Product_transactionType_Input"];
	["Product_website_operator"]: ValueTypes["Product_website_operator"];
	["Product_tnc_operator"]: ValueTypes["Product_tnc_operator"];
	["Product_remarks_operator"]: ValueTypes["Product_remarks_operator"];
	["Product_sessions__variantId_operator"]: ValueTypes["Product_sessions__variantId_operator"];
	["Product_sessions__productId_operator"]: ValueTypes["Product_sessions__productId_operator"];
	["Product_sessions__name_operator"]: ValueTypes["Product_sessions__name_operator"];
	["Product_sessions__inventories__quantity_operator"]: ValueTypes["Product_sessions__inventories__quantity_operator"];
	["Product_sessions__inventories__unitPrice_operator"]: ValueTypes["Product_sessions__inventories__unitPrice_operator"];
	["Product_sessions__inventories__handlingFee_operator"]: ValueTypes["Product_sessions__inventories__handlingFee_operator"];
	["Product_sessions__inventories__totalCost_operator"]: ValueTypes["Product_sessions__inventories__totalCost_operator"];
	["Product_sessions__inventories__minPurchase_operator"]: ValueTypes["Product_sessions__inventories__minPurchase_operator"];
	["Product_sessions__inventories__maxPurchase_operator"]: ValueTypes["Product_sessions__inventories__maxPurchase_operator"];
	["Product_sessions__inventories__stocks__stockId_operator"]: ValueTypes["Product_sessions__inventories__stocks__stockId_operator"];
	["Product_sessions__inventories__stocks__remaining_operator"]: ValueTypes["Product_sessions__inventories__stocks__remaining_operator"];
	["Product_sessions__inventories__stocks__sold_operator"]: ValueTypes["Product_sessions__inventories__stocks__sold_operator"];
	["Product_sessions__inventories__stocks__onHold_operator"]: ValueTypes["Product_sessions__inventories__stocks__onHold_operator"];
	["Product_sessions__inventories__stocks__checkedIn_operator"]: ValueTypes["Product_sessions__inventories__stocks__checkedIn_operator"];
	["Product_sessions__id_operator"]: ValueTypes["Product_sessions__id_operator"];
	["Product_updatedAt_operator"]: ValueTypes["Product_updatedAt_operator"];
	["Product_createdAt_operator"]: ValueTypes["Product_createdAt_operator"];
	["Product_id_operator"]: ValueTypes["Product_id_operator"];
	["Product_where_and"]: ValueTypes["Product_where_and"];
	["Product_where_or"]: ValueTypes["Product_where_or"];
	["InventoryStatus_productType"]: ValueTypes["InventoryStatus_productType"];
	["InventoryStatus_stockUpdateStatus"]: ValueTypes["InventoryStatus_stockUpdateStatus"];
	["InventoryStatus_where"]: ValueTypes["InventoryStatus_where"];
	["InventoryStatus_variantId_operator"]: ValueTypes["InventoryStatus_variantId_operator"];
	["InventoryStatus_variantName_operator"]: ValueTypes["InventoryStatus_variantName_operator"];
	["InventoryStatus_productType_operator"]: ValueTypes["InventoryStatus_productType_operator"];
	["InventoryStatus_productType_Input"]: ValueTypes["InventoryStatus_productType_Input"];
	["InventoryStatus_stockUpdateStatus_operator"]: ValueTypes["InventoryStatus_stockUpdateStatus_operator"];
	["InventoryStatus_stockUpdateStatus_Input"]: ValueTypes["InventoryStatus_stockUpdateStatus_Input"];
	["InventoryStatus_stockUpdateAmount_operator"]: ValueTypes["InventoryStatus_stockUpdateAmount_operator"];
	["InventoryStatus_user_operator"]: ValueTypes["InventoryStatus_user_operator"];
	["InventoryStatus_publicUserPhone_operator"]: ValueTypes["InventoryStatus_publicUserPhone_operator"];
	["InventoryStatus_expiredAt_operator"]: ValueTypes["InventoryStatus_expiredAt_operator"];
	["InventoryStatus_updatedAt_operator"]: ValueTypes["InventoryStatus_updatedAt_operator"];
	["InventoryStatus_createdAt_operator"]: ValueTypes["InventoryStatus_createdAt_operator"];
	["InventoryStatus_id_operator"]: ValueTypes["InventoryStatus_id_operator"];
	["InventoryStatus_where_and"]: ValueTypes["InventoryStatus_where_and"];
	["InventoryStatus_where_or"]: ValueTypes["InventoryStatus_where_or"];
	["Transaction_where"]: ValueTypes["Transaction_where"];
	["Transaction_creator_operator"]: ValueTypes["Transaction_creator_operator"];
	["Transaction_productName_operator"]: ValueTypes["Transaction_productName_operator"];
	["Transaction_referenceId_operator"]: ValueTypes["Transaction_referenceId_operator"];
	["Transaction_linkedEvent_operator"]: ValueTypes["Transaction_linkedEvent_operator"];
	["Transaction_purchaseDateTime_operator"]: ValueTypes["Transaction_purchaseDateTime_operator"];
	["Transaction_timableOrderId_operator"]: ValueTypes["Transaction_timableOrderId_operator"];
	["Transaction_stripePaymentId_operator"]: ValueTypes["Transaction_stripePaymentId_operator"];
	["Transaction_totalAmount_operator"]: ValueTypes["Transaction_totalAmount_operator"];
	["Transaction_contactPersonName_operator"]: ValueTypes["Transaction_contactPersonName_operator"];
	["Transaction_contactPersonPhone_operator"]: ValueTypes["Transaction_contactPersonPhone_operator"];
	["Transaction_remarks_operator"]: ValueTypes["Transaction_remarks_operator"];
	["Transaction_redemptionCodes__code_operator"]: ValueTypes["Transaction_redemptionCodes__code_operator"];
	["Transaction_redemptionCodes__id_operator"]: ValueTypes["Transaction_redemptionCodes__id_operator"];
	["Transaction_updatedAt_operator"]: ValueTypes["Transaction_updatedAt_operator"];
	["Transaction_createdAt_operator"]: ValueTypes["Transaction_createdAt_operator"];
	["Transaction_id_operator"]: ValueTypes["Transaction_id_operator"];
	["Transaction_where_and"]: ValueTypes["Transaction_where_and"];
	["Transaction_where_or"]: ValueTypes["Transaction_where_or"];
	["RedemptionCode_status"]: ValueTypes["RedemptionCode_status"];
	["RedemptionCode_productType"]: ValueTypes["RedemptionCode_productType"];
	["RedemptionCode_where"]: ValueTypes["RedemptionCode_where"];
	["RedemptionCode_creator_operator"]: ValueTypes["RedemptionCode_creator_operator"];
	["RedemptionCode_status_operator"]: ValueTypes["RedemptionCode_status_operator"];
	["RedemptionCode_status_Input"]: ValueTypes["RedemptionCode_status_Input"];
	["RedemptionCode_qrCodeId_operator"]: ValueTypes["RedemptionCode_qrCodeId_operator"];
	["RedemptionCode_lastCheckedInDateTime_operator"]: ValueTypes["RedemptionCode_lastCheckedInDateTime_operator"];
	["RedemptionCode_voidedDateTime_operator"]: ValueTypes["RedemptionCode_voidedDateTime_operator"];
	["RedemptionCode_refundedDateTime_operator"]: ValueTypes["RedemptionCode_refundedDateTime_operator"];
	["RedemptionCode_eventStartTime_operator"]: ValueTypes["RedemptionCode_eventStartTime_operator"];
	["RedemptionCode_eventEndTime_operator"]: ValueTypes["RedemptionCode_eventEndTime_operator"];
	["RedemptionCode_eventPriceZone_operator"]: ValueTypes["RedemptionCode_eventPriceZone_operator"];
	["RedemptionCode_ownerName_operator"]: ValueTypes["RedemptionCode_ownerName_operator"];
	["RedemptionCode_phone_operator"]: ValueTypes["RedemptionCode_phone_operator"];
	["RedemptionCode_pdf_operator"]: ValueTypes["RedemptionCode_pdf_operator"];
	["RedemptionCode_variants__variantId_operator"]: ValueTypes["RedemptionCode_variants__variantId_operator"];
	["RedemptionCode_variants__name_operator"]: ValueTypes["RedemptionCode_variants__name_operator"];
	["RedemptionCode_variants__quantity_operator"]: ValueTypes["RedemptionCode_variants__quantity_operator"];
	["RedemptionCode_variants__totalCost_operator"]: ValueTypes["RedemptionCode_variants__totalCost_operator"];
	["RedemptionCode_variants__id_operator"]: ValueTypes["RedemptionCode_variants__id_operator"];
	["RedemptionCode_productId_operator"]: ValueTypes["RedemptionCode_productId_operator"];
	["RedemptionCode_referenceId_operator"]: ValueTypes["RedemptionCode_referenceId_operator"];
	["RedemptionCode_productType_operator"]: ValueTypes["RedemptionCode_productType_operator"];
	["RedemptionCode_productType_Input"]: ValueTypes["RedemptionCode_productType_Input"];
	["RedemptionCode_productName_operator"]: ValueTypes["RedemptionCode_productName_operator"];
	["RedemptionCode_linkedEvent_operator"]: ValueTypes["RedemptionCode_linkedEvent_operator"];
	["RedemptionCode_timableOrderId_operator"]: ValueTypes["RedemptionCode_timableOrderId_operator"];
	["RedemptionCode_purchaseDateTime_operator"]: ValueTypes["RedemptionCode_purchaseDateTime_operator"];
	["RedemptionCode_changeHistory__timestamp_operator"]: ValueTypes["RedemptionCode_changeHistory__timestamp_operator"];
	["RedemptionCode_changeHistory__field_operator"]: ValueTypes["RedemptionCode_changeHistory__field_operator"];
	["RedemptionCode_changeHistory__oldValue_operator"]: ValueTypes["RedemptionCode_changeHistory__oldValue_operator"];
	["RedemptionCode_changeHistory__newValue_operator"]: ValueTypes["RedemptionCode_changeHistory__newValue_operator"];
	["RedemptionCode_changeHistory__id_operator"]: ValueTypes["RedemptionCode_changeHistory__id_operator"];
	["RedemptionCode_updatedAt_operator"]: ValueTypes["RedemptionCode_updatedAt_operator"];
	["RedemptionCode_createdAt_operator"]: ValueTypes["RedemptionCode_createdAt_operator"];
	["RedemptionCode_id_operator"]: ValueTypes["RedemptionCode_id_operator"];
	["RedemptionCode_where_and"]: ValueTypes["RedemptionCode_where_and"];
	["RedemptionCode_where_or"]: ValueTypes["RedemptionCode_where_or"];
	["RedemptionPdf_where"]: ValueTypes["RedemptionPdf_where"];
	["RedemptionPdf_updatedAt_operator"]: ValueTypes["RedemptionPdf_updatedAt_operator"];
	["RedemptionPdf_createdAt_operator"]: ValueTypes["RedemptionPdf_createdAt_operator"];
	["RedemptionPdf_url_operator"]: ValueTypes["RedemptionPdf_url_operator"];
	["RedemptionPdf_filename_operator"]: ValueTypes["RedemptionPdf_filename_operator"];
	["RedemptionPdf_mimeType_operator"]: ValueTypes["RedemptionPdf_mimeType_operator"];
	["RedemptionPdf_filesize_operator"]: ValueTypes["RedemptionPdf_filesize_operator"];
	["RedemptionPdf_width_operator"]: ValueTypes["RedemptionPdf_width_operator"];
	["RedemptionPdf_height_operator"]: ValueTypes["RedemptionPdf_height_operator"];
	["RedemptionPdf_focalX_operator"]: ValueTypes["RedemptionPdf_focalX_operator"];
	["RedemptionPdf_focalY_operator"]: ValueTypes["RedemptionPdf_focalY_operator"];
	["RedemptionPdf_id_operator"]: ValueTypes["RedemptionPdf_id_operator"];
	["RedemptionPdf_where_and"]: ValueTypes["RedemptionPdf_where_and"];
	["RedemptionPdf_where_or"]: ValueTypes["RedemptionPdf_where_or"];
	["CheckInRedemption_productType"]: ValueTypes["CheckInRedemption_productType"];
	["CheckInRedemption_where"]: ValueTypes["CheckInRedemption_where"];
	["CheckInRedemption_creator_operator"]: ValueTypes["CheckInRedemption_creator_operator"];
	["CheckInRedemption_qrCodeId_operator"]: ValueTypes["CheckInRedemption_qrCodeId_operator"];
	["CheckInRedemption_codeownerName_operator"]: ValueTypes["CheckInRedemption_codeownerName_operator"];
	["CheckInRedemption_name_operator"]: ValueTypes["CheckInRedemption_name_operator"];
	["CheckInRedemption_phone_operator"]: ValueTypes["CheckInRedemption_phone_operator"];
	["CheckInRedemption_linkedEvent_operator"]: ValueTypes["CheckInRedemption_linkedEvent_operator"];
	["CheckInRedemption_entryNumber_operator"]: ValueTypes["CheckInRedemption_entryNumber_operator"];
	["CheckInRedemption_scanAt_operator"]: ValueTypes["CheckInRedemption_scanAt_operator"];
	["CheckInRedemption_uploadAt_operator"]: ValueTypes["CheckInRedemption_uploadAt_operator"];
	["CheckInRedemption_status_operator"]: ValueTypes["CheckInRedemption_status_operator"];
	["CheckInRedemption_failReason_operator"]: ValueTypes["CheckInRedemption_failReason_operator"];
	["CheckInRedemption_variants__variantId_operator"]: ValueTypes["CheckInRedemption_variants__variantId_operator"];
	["CheckInRedemption_variants__name_operator"]: ValueTypes["CheckInRedemption_variants__name_operator"];
	["CheckInRedemption_variants__quantity_operator"]: ValueTypes["CheckInRedemption_variants__quantity_operator"];
	["CheckInRedemption_variants__totalCost_operator"]: ValueTypes["CheckInRedemption_variants__totalCost_operator"];
	["CheckInRedemption_variants__id_operator"]: ValueTypes["CheckInRedemption_variants__id_operator"];
	["CheckInRedemption_checkInAppSelectionId_operator"]: ValueTypes["CheckInRedemption_checkInAppSelectionId_operator"];
	["CheckInRedemption_qrCodeStatus_operator"]: ValueTypes["CheckInRedemption_qrCodeStatus_operator"];
	["CheckInRedemption_productType_operator"]: ValueTypes["CheckInRedemption_productType_operator"];
	["CheckInRedemption_productType_Input"]: ValueTypes["CheckInRedemption_productType_Input"];
	["CheckInRedemption_updatedAt_operator"]: ValueTypes["CheckInRedemption_updatedAt_operator"];
	["CheckInRedemption_createdAt_operator"]: ValueTypes["CheckInRedemption_createdAt_operator"];
	["CheckInRedemption_id_operator"]: ValueTypes["CheckInRedemption_id_operator"];
	["CheckInRedemption_where_and"]: ValueTypes["CheckInRedemption_where_and"];
	["CheckInRedemption_where_or"]: ValueTypes["CheckInRedemption_where_or"];
	["User_where"]: ValueTypes["User_where"];
	["User_region_operator"]: ValueTypes["User_region_operator"];
	["User_region_Input"]: ValueTypes["User_region_Input"];
	["User_name_operator"]: ValueTypes["User_name_operator"];
	["User_displayEmail_operator"]: ValueTypes["User_displayEmail_operator"];
	["User_legacyUsername_operator"]: ValueTypes["User_legacyUsername_operator"];
	["User_roles_operator"]: ValueTypes["User_roles_operator"];
	["User_roles_Input"]: ValueTypes["User_roles_Input"];
	["User_member__region_operator"]: ValueTypes["User_member__region_operator"];
	["User_member__region_Input"]: ValueTypes["User_member__region_Input"];
	["User_member__language_operator"]: ValueTypes["User_member__language_operator"];
	["User_member__language_Input"]: ValueTypes["User_member__language_Input"];
	["User_member__phone_operator"]: ValueTypes["User_member__phone_operator"];
	["User_member__avatar_operator"]: ValueTypes["User_member__avatar_operator"];
	["User_member__birthday_operator"]: ValueTypes["User_member__birthday_operator"];
	["User_member__gender_operator"]: ValueTypes["User_member__gender_operator"];
	["User_member__gender_Input"]: ValueTypes["User_member__gender_Input"];
	["User_member__categories_operator"]: ValueTypes["User_member__categories_operator"];
	["User_organiser__logo_operator"]: ValueTypes["User_organiser__logo_operator"];
	["User_organiser__banner_operator"]: ValueTypes["User_organiser__banner_operator"];
	["User_organiser__description_operator"]: ValueTypes["User_organiser__description_operator"];
	["User_organiser__foundedIn_operator"]: ValueTypes["User_organiser__foundedIn_operator"];
	["User_organiser__address_operator"]: ValueTypes["User_organiser__address_operator"];
	["User_organiser__website_operator"]: ValueTypes["User_organiser__website_operator"];
	["User_organiser__facebook_operator"]: ValueTypes["User_organiser__facebook_operator"];
	["User_organiser__instagram_operator"]: ValueTypes["User_organiser__instagram_operator"];
	["User_organiser__language_operator"]: ValueTypes["User_organiser__language_operator"];
	["User_organiser__language_Input"]: ValueTypes["User_organiser__language_Input"];
	["User_organiser__phone_operator"]: ValueTypes["User_organiser__phone_operator"];
	["User_organiser__followers_operator"]: ValueTypes["User_organiser__followers_operator"];
	["User_emailVerified_operator"]: ValueTypes["User_emailVerified_operator"];
	["User_sso__googleId_operator"]: ValueTypes["User_sso__googleId_operator"];
	["User_sso__facebookId_operator"]: ValueTypes["User_sso__facebookId_operator"];
	["User_sso__appleId_operator"]: ValueTypes["User_sso__appleId_operator"];
	["User_oldPassword_operator"]: ValueTypes["User_oldPassword_operator"];
	["User_status_operator"]: ValueTypes["User_status_operator"];
	["User_status_Input"]: ValueTypes["User_status_Input"];
	["User_updatedAt_operator"]: ValueTypes["User_updatedAt_operator"];
	["User_createdAt_operator"]: ValueTypes["User_createdAt_operator"];
	["User_email_operator"]: ValueTypes["User_email_operator"];
	["User_id_operator"]: ValueTypes["User_id_operator"];
	["User_where_and"]: ValueTypes["User_where_and"];
	["User_where_or"]: ValueTypes["User_where_or"];
	["Bookmark_User_RelationTo"]: ValueTypes["Bookmark_User_RelationTo"];
	["Bookmark_Item_RelationTo"]: ValueTypes["Bookmark_Item_RelationTo"];
	["Bookmark_where"]: ValueTypes["Bookmark_where"];
	["Bookmark_user_Relation"]: ValueTypes["Bookmark_user_Relation"];
	["Bookmark_user_Relation_RelationTo"]: ValueTypes["Bookmark_user_Relation_RelationTo"];
	["Bookmark_item_Relation"]: ValueTypes["Bookmark_item_Relation"];
	["Bookmark_item_Relation_RelationTo"]: ValueTypes["Bookmark_item_Relation_RelationTo"];
	["Bookmark_updatedAt_operator"]: ValueTypes["Bookmark_updatedAt_operator"];
	["Bookmark_createdAt_operator"]: ValueTypes["Bookmark_createdAt_operator"];
	["Bookmark_id_operator"]: ValueTypes["Bookmark_id_operator"];
	["Bookmark_where_and"]: ValueTypes["Bookmark_where_and"];
	["Bookmark_where_or"]: ValueTypes["Bookmark_where_or"];
	["WebAppBookmark_User_RelationTo"]: ValueTypes["WebAppBookmark_User_RelationTo"];
	["WebAppBookmark_Event_RelationTo"]: ValueTypes["WebAppBookmark_Event_RelationTo"];
	["WebAppBookmark_productType"]: ValueTypes["WebAppBookmark_productType"];
	["WebAppBookmark_where"]: ValueTypes["WebAppBookmark_where"];
	["WebAppBookmark_user_Relation"]: ValueTypes["WebAppBookmark_user_Relation"];
	["WebAppBookmark_user_Relation_RelationTo"]: ValueTypes["WebAppBookmark_user_Relation_RelationTo"];
	["WebAppBookmark_event_Relation"]: ValueTypes["WebAppBookmark_event_Relation"];
	["WebAppBookmark_event_Relation_RelationTo"]: ValueTypes["WebAppBookmark_event_Relation_RelationTo"];
	["WebAppBookmark_productType_operator"]: ValueTypes["WebAppBookmark_productType_operator"];
	["WebAppBookmark_productType_Input"]: ValueTypes["WebAppBookmark_productType_Input"];
	["WebAppBookmark_item_operator"]: ValueTypes["WebAppBookmark_item_operator"];
	["WebAppBookmark_entryNumber_operator"]: ValueTypes["WebAppBookmark_entryNumber_operator"];
	["WebAppBookmark_updatedAt_operator"]: ValueTypes["WebAppBookmark_updatedAt_operator"];
	["WebAppBookmark_createdAt_operator"]: ValueTypes["WebAppBookmark_createdAt_operator"];
	["WebAppBookmark_id_operator"]: ValueTypes["WebAppBookmark_id_operator"];
	["WebAppBookmark_where_and"]: ValueTypes["WebAppBookmark_where_and"];
	["WebAppBookmark_where_or"]: ValueTypes["WebAppBookmark_where_or"];
	["ViewRecord_User_RelationTo"]: ValueTypes["ViewRecord_User_RelationTo"];
	["ViewRecord_Item_RelationTo"]: ValueTypes["ViewRecord_Item_RelationTo"];
	["ViewRecord_where"]: ValueTypes["ViewRecord_where"];
	["ViewRecord_user_Relation"]: ValueTypes["ViewRecord_user_Relation"];
	["ViewRecord_user_Relation_RelationTo"]: ValueTypes["ViewRecord_user_Relation_RelationTo"];
	["ViewRecord_item_Relation"]: ValueTypes["ViewRecord_item_Relation"];
	["ViewRecord_item_Relation_RelationTo"]: ValueTypes["ViewRecord_item_Relation_RelationTo"];
	["ViewRecord_updatedAt_operator"]: ValueTypes["ViewRecord_updatedAt_operator"];
	["ViewRecord_createdAt_operator"]: ValueTypes["ViewRecord_createdAt_operator"];
	["ViewRecord_id_operator"]: ValueTypes["ViewRecord_id_operator"];
	["ViewRecord_where_and"]: ValueTypes["ViewRecord_where_and"];
	["ViewRecord_where_or"]: ValueTypes["ViewRecord_where_or"];
	["Comment_Image_where"]: ValueTypes["Comment_Image_where"];
	["Comment_Image_alt_operator"]: ValueTypes["Comment_Image_alt_operator"];
	["Comment_Image_updatedAt_operator"]: ValueTypes["Comment_Image_updatedAt_operator"];
	["Comment_Image_createdAt_operator"]: ValueTypes["Comment_Image_createdAt_operator"];
	["Comment_Image_url_operator"]: ValueTypes["Comment_Image_url_operator"];
	["Comment_Image_filename_operator"]: ValueTypes["Comment_Image_filename_operator"];
	["Comment_Image_mimeType_operator"]: ValueTypes["Comment_Image_mimeType_operator"];
	["Comment_Image_filesize_operator"]: ValueTypes["Comment_Image_filesize_operator"];
	["Comment_Image_width_operator"]: ValueTypes["Comment_Image_width_operator"];
	["Comment_Image_height_operator"]: ValueTypes["Comment_Image_height_operator"];
	["Comment_Image_focalX_operator"]: ValueTypes["Comment_Image_focalX_operator"];
	["Comment_Image_focalY_operator"]: ValueTypes["Comment_Image_focalY_operator"];
	["Comment_Image_sizes__small__url_operator"]: ValueTypes["Comment_Image_sizes__small__url_operator"];
	["Comment_Image_sizes__small__width_operator"]: ValueTypes["Comment_Image_sizes__small__width_operator"];
	["Comment_Image_sizes__small__height_operator"]: ValueTypes["Comment_Image_sizes__small__height_operator"];
	["Comment_Image_sizes__small__mimeType_operator"]: ValueTypes["Comment_Image_sizes__small__mimeType_operator"];
	["Comment_Image_sizes__small__filesize_operator"]: ValueTypes["Comment_Image_sizes__small__filesize_operator"];
	["Comment_Image_sizes__small__filename_operator"]: ValueTypes["Comment_Image_sizes__small__filename_operator"];
	["Comment_Image_sizes__medium__url_operator"]: ValueTypes["Comment_Image_sizes__medium__url_operator"];
	["Comment_Image_sizes__medium__width_operator"]: ValueTypes["Comment_Image_sizes__medium__width_operator"];
	["Comment_Image_sizes__medium__height_operator"]: ValueTypes["Comment_Image_sizes__medium__height_operator"];
	["Comment_Image_sizes__medium__mimeType_operator"]: ValueTypes["Comment_Image_sizes__medium__mimeType_operator"];
	["Comment_Image_sizes__medium__filesize_operator"]: ValueTypes["Comment_Image_sizes__medium__filesize_operator"];
	["Comment_Image_sizes__medium__filename_operator"]: ValueTypes["Comment_Image_sizes__medium__filename_operator"];
	["Comment_Image_sizes__large__url_operator"]: ValueTypes["Comment_Image_sizes__large__url_operator"];
	["Comment_Image_sizes__large__width_operator"]: ValueTypes["Comment_Image_sizes__large__width_operator"];
	["Comment_Image_sizes__large__height_operator"]: ValueTypes["Comment_Image_sizes__large__height_operator"];
	["Comment_Image_sizes__large__mimeType_operator"]: ValueTypes["Comment_Image_sizes__large__mimeType_operator"];
	["Comment_Image_sizes__large__filesize_operator"]: ValueTypes["Comment_Image_sizes__large__filesize_operator"];
	["Comment_Image_sizes__large__filename_operator"]: ValueTypes["Comment_Image_sizes__large__filename_operator"];
	["Comment_Image_sizes__small_jpeg__url_operator"]: ValueTypes["Comment_Image_sizes__small_jpeg__url_operator"];
	["Comment_Image_sizes__small_jpeg__width_operator"]: ValueTypes["Comment_Image_sizes__small_jpeg__width_operator"];
	["Comment_Image_sizes__small_jpeg__height_operator"]: ValueTypes["Comment_Image_sizes__small_jpeg__height_operator"];
	["Comment_Image_sizes__small_jpeg__mimeType_operator"]: ValueTypes["Comment_Image_sizes__small_jpeg__mimeType_operator"];
	["Comment_Image_sizes__small_jpeg__filesize_operator"]: ValueTypes["Comment_Image_sizes__small_jpeg__filesize_operator"];
	["Comment_Image_sizes__small_jpeg__filename_operator"]: ValueTypes["Comment_Image_sizes__small_jpeg__filename_operator"];
	["Comment_Image_sizes__medium_jpeg__url_operator"]: ValueTypes["Comment_Image_sizes__medium_jpeg__url_operator"];
	["Comment_Image_sizes__medium_jpeg__width_operator"]: ValueTypes["Comment_Image_sizes__medium_jpeg__width_operator"];
	["Comment_Image_sizes__medium_jpeg__height_operator"]: ValueTypes["Comment_Image_sizes__medium_jpeg__height_operator"];
	["Comment_Image_sizes__medium_jpeg__mimeType_operator"]: ValueTypes["Comment_Image_sizes__medium_jpeg__mimeType_operator"];
	["Comment_Image_sizes__medium_jpeg__filesize_operator"]: ValueTypes["Comment_Image_sizes__medium_jpeg__filesize_operator"];
	["Comment_Image_sizes__medium_jpeg__filename_operator"]: ValueTypes["Comment_Image_sizes__medium_jpeg__filename_operator"];
	["Comment_Image_sizes__large_jpeg__url_operator"]: ValueTypes["Comment_Image_sizes__large_jpeg__url_operator"];
	["Comment_Image_sizes__large_jpeg__width_operator"]: ValueTypes["Comment_Image_sizes__large_jpeg__width_operator"];
	["Comment_Image_sizes__large_jpeg__height_operator"]: ValueTypes["Comment_Image_sizes__large_jpeg__height_operator"];
	["Comment_Image_sizes__large_jpeg__mimeType_operator"]: ValueTypes["Comment_Image_sizes__large_jpeg__mimeType_operator"];
	["Comment_Image_sizes__large_jpeg__filesize_operator"]: ValueTypes["Comment_Image_sizes__large_jpeg__filesize_operator"];
	["Comment_Image_sizes__large_jpeg__filename_operator"]: ValueTypes["Comment_Image_sizes__large_jpeg__filename_operator"];
	["Comment_Image_id_operator"]: ValueTypes["Comment_Image_id_operator"];
	["Comment_Image_where_and"]: ValueTypes["Comment_Image_where_and"];
	["Comment_Image_where_or"]: ValueTypes["Comment_Image_where_or"];
	["Comment_Target_RelationTo"]: ValueTypes["Comment_Target_RelationTo"];
	["Comment_Parent_RelationTo"]: ValueTypes["Comment_Parent_RelationTo"];
	["Comment_where"]: ValueTypes["Comment_where"];
	["Comment_commenter_operator"]: ValueTypes["Comment_commenter_operator"];
	["Comment_content_operator"]: ValueTypes["Comment_content_operator"];
	["Comment_image_operator"]: ValueTypes["Comment_image_operator"];
	["Comment_target_Relation"]: ValueTypes["Comment_target_Relation"];
	["Comment_target_Relation_RelationTo"]: ValueTypes["Comment_target_Relation_RelationTo"];
	["Comment_parent_Relation"]: ValueTypes["Comment_parent_Relation"];
	["Comment_parent_Relation_RelationTo"]: ValueTypes["Comment_parent_Relation_RelationTo"];
	["Comment_isReviewed_operator"]: ValueTypes["Comment_isReviewed_operator"];
	["Comment_reviewedBy_operator"]: ValueTypes["Comment_reviewedBy_operator"];
	["Comment_banned_operator"]: ValueTypes["Comment_banned_operator"];
	["Comment_type_operator"]: ValueTypes["Comment_type_operator"];
	["Comment_updatedAt_operator"]: ValueTypes["Comment_updatedAt_operator"];
	["Comment_createdAt_operator"]: ValueTypes["Comment_createdAt_operator"];
	["Comment_id_operator"]: ValueTypes["Comment_id_operator"];
	["Comment_where_and"]: ValueTypes["Comment_where_and"];
	["Comment_where_or"]: ValueTypes["Comment_where_or"];
	["Subscription_User_RelationTo"]: ValueTypes["Subscription_User_RelationTo"];
	["Subscription_Organiser_RelationTo"]: ValueTypes["Subscription_Organiser_RelationTo"];
	["Subscription_where"]: ValueTypes["Subscription_where"];
	["Subscription_user_Relation"]: ValueTypes["Subscription_user_Relation"];
	["Subscription_user_Relation_RelationTo"]: ValueTypes["Subscription_user_Relation_RelationTo"];
	["Subscription_organiser_Relation"]: ValueTypes["Subscription_organiser_Relation"];
	["Subscription_organiser_Relation_RelationTo"]: ValueTypes["Subscription_organiser_Relation_RelationTo"];
	["Subscription_updatedAt_operator"]: ValueTypes["Subscription_updatedAt_operator"];
	["Subscription_createdAt_operator"]: ValueTypes["Subscription_createdAt_operator"];
	["Subscription_id_operator"]: ValueTypes["Subscription_id_operator"];
	["Subscription_where_and"]: ValueTypes["Subscription_where_and"];
	["Subscription_where_or"]: ValueTypes["Subscription_where_or"];
	["Newsletter_region"]: ValueTypes["Newsletter_region"];
	["Newsletter_where"]: ValueTypes["Newsletter_where"];
	["Newsletter_email_operator"]: ValueTypes["Newsletter_email_operator"];
	["Newsletter_region_operator"]: ValueTypes["Newsletter_region_operator"];
	["Newsletter_region_Input"]: ValueTypes["Newsletter_region_Input"];
	["Newsletter_updatedAt_operator"]: ValueTypes["Newsletter_updatedAt_operator"];
	["Newsletter_createdAt_operator"]: ValueTypes["Newsletter_createdAt_operator"];
	["Newsletter_id_operator"]: ValueTypes["Newsletter_id_operator"];
	["Newsletter_where_and"]: ValueTypes["Newsletter_where_and"];
	["Newsletter_where_or"]: ValueTypes["Newsletter_where_or"];
	["BlogCategory_where"]: ValueTypes["BlogCategory_where"];
	["BlogCategory_name_operator"]: ValueTypes["BlogCategory_name_operator"];
	["BlogCategory_value_operator"]: ValueTypes["BlogCategory_value_operator"];
	["BlogCategory_icon_operator"]: ValueTypes["BlogCategory_icon_operator"];
	["BlogCategory_region_operator"]: ValueTypes["BlogCategory_region_operator"];
	["BlogCategory_region_Input"]: ValueTypes["BlogCategory_region_Input"];
	["BlogCategory_updatedAt_operator"]: ValueTypes["BlogCategory_updatedAt_operator"];
	["BlogCategory_createdAt_operator"]: ValueTypes["BlogCategory_createdAt_operator"];
	["BlogCategory_id_operator"]: ValueTypes["BlogCategory_id_operator"];
	["BlogCategory_where_and"]: ValueTypes["BlogCategory_where_and"];
	["BlogCategory_where_or"]: ValueTypes["BlogCategory_where_or"];
	["Tag_where"]: ValueTypes["Tag_where"];
	["Tag_name_operator"]: ValueTypes["Tag_name_operator"];
	["Tag_value_operator"]: ValueTypes["Tag_value_operator"];
	["Tag_region_operator"]: ValueTypes["Tag_region_operator"];
	["Tag_region_Input"]: ValueTypes["Tag_region_Input"];
	["Tag_count_operator"]: ValueTypes["Tag_count_operator"];
	["Tag_skipCountCheck_operator"]: ValueTypes["Tag_skipCountCheck_operator"];
	["Tag_removeNextCron_operator"]: ValueTypes["Tag_removeNextCron_operator"];
	["Tag_id_operator"]: ValueTypes["Tag_id_operator"];
	["Tag_where_and"]: ValueTypes["Tag_where_and"];
	["Tag_where_or"]: ValueTypes["Tag_where_or"];
	["SpecialTag_where"]: ValueTypes["SpecialTag_where"];
	["SpecialTag_name_operator"]: ValueTypes["SpecialTag_name_operator"];
	["SpecialTag_value_operator"]: ValueTypes["SpecialTag_value_operator"];
	["SpecialTag_region_operator"]: ValueTypes["SpecialTag_region_operator"];
	["SpecialTag_region_Input"]: ValueTypes["SpecialTag_region_Input"];
	["SpecialTag_id_operator"]: ValueTypes["SpecialTag_id_operator"];
	["SpecialTag_where_and"]: ValueTypes["SpecialTag_where_and"];
	["SpecialTag_where_or"]: ValueTypes["SpecialTag_where_or"];
	["Date_where"]: ValueTypes["Date_where"];
	["Date_name_operator"]: ValueTypes["Date_name_operator"];
	["Date_value_operator"]: ValueTypes["Date_value_operator"];
	["Date_type_operator"]: ValueTypes["Date_type_operator"];
	["Date_type_Input"]: ValueTypes["Date_type_Input"];
	["Date_start_operator"]: ValueTypes["Date_start_operator"];
	["Date_end_operator"]: ValueTypes["Date_end_operator"];
	["Date__title_operator"]: ValueTypes["Date__title_operator"];
	["Date_region_operator"]: ValueTypes["Date_region_operator"];
	["Date_region_Input"]: ValueTypes["Date_region_Input"];
	["Date_updatedAt_operator"]: ValueTypes["Date_updatedAt_operator"];
	["Date_createdAt_operator"]: ValueTypes["Date_createdAt_operator"];
	["Date_id_operator"]: ValueTypes["Date_id_operator"];
	["Date_where_and"]: ValueTypes["Date_where_and"];
	["Date_where_or"]: ValueTypes["Date_where_or"];
	["District_where"]: ValueTypes["District_where"];
	["District_name_operator"]: ValueTypes["District_name_operator"];
	["District_level_operator"]: ValueTypes["District_level_operator"];
	["District_children_operator"]: ValueTypes["District_children_operator"];
	["District_value_operator"]: ValueTypes["District_value_operator"];
	["District_lat_operator"]: ValueTypes["District_lat_operator"];
	["District_long_operator"]: ValueTypes["District_long_operator"];
	["District_radius_operator"]: ValueTypes["District_radius_operator"];
	["District_region_operator"]: ValueTypes["District_region_operator"];
	["District_region_Input"]: ValueTypes["District_region_Input"];
	["District_updatedAt_operator"]: ValueTypes["District_updatedAt_operator"];
	["District_createdAt_operator"]: ValueTypes["District_createdAt_operator"];
	["District_id_operator"]: ValueTypes["District_id_operator"];
	["District_where_and"]: ValueTypes["District_where_and"];
	["District_where_or"]: ValueTypes["District_where_or"];
	["Audience_where"]: ValueTypes["Audience_where"];
	["Audience_name_operator"]: ValueTypes["Audience_name_operator"];
	["Audience_value_operator"]: ValueTypes["Audience_value_operator"];
	["Audience_icon_operator"]: ValueTypes["Audience_icon_operator"];
	["Audience_region_operator"]: ValueTypes["Audience_region_operator"];
	["Audience_region_Input"]: ValueTypes["Audience_region_Input"];
	["Audience_updatedAt_operator"]: ValueTypes["Audience_updatedAt_operator"];
	["Audience_createdAt_operator"]: ValueTypes["Audience_createdAt_operator"];
	["Audience_id_operator"]: ValueTypes["Audience_id_operator"];
	["Audience_where_and"]: ValueTypes["Audience_where_and"];
	["Audience_where_or"]: ValueTypes["Audience_where_or"];
	["EventCategory_where"]: ValueTypes["EventCategory_where"];
	["EventCategory_name_operator"]: ValueTypes["EventCategory_name_operator"];
	["EventCategory_value_operator"]: ValueTypes["EventCategory_value_operator"];
	["EventCategory_tags_operator"]: ValueTypes["EventCategory_tags_operator"];
	["EventCategory_icon_operator"]: ValueTypes["EventCategory_icon_operator"];
	["EventCategory_region_operator"]: ValueTypes["EventCategory_region_operator"];
	["EventCategory_region_Input"]: ValueTypes["EventCategory_region_Input"];
	["EventCategory_updatedAt_operator"]: ValueTypes["EventCategory_updatedAt_operator"];
	["EventCategory_createdAt_operator"]: ValueTypes["EventCategory_createdAt_operator"];
	["EventCategory_id_operator"]: ValueTypes["EventCategory_id_operator"];
	["EventCategory_where_and"]: ValueTypes["EventCategory_where_and"];
	["EventCategory_where_or"]: ValueTypes["EventCategory_where_or"];
	["PrivateMedia_where"]: ValueTypes["PrivateMedia_where"];
	["PrivateMedia_alt_operator"]: ValueTypes["PrivateMedia_alt_operator"];
	["PrivateMedia_updatedAt_operator"]: ValueTypes["PrivateMedia_updatedAt_operator"];
	["PrivateMedia_createdAt_operator"]: ValueTypes["PrivateMedia_createdAt_operator"];
	["PrivateMedia_url_operator"]: ValueTypes["PrivateMedia_url_operator"];
	["PrivateMedia_filename_operator"]: ValueTypes["PrivateMedia_filename_operator"];
	["PrivateMedia_mimeType_operator"]: ValueTypes["PrivateMedia_mimeType_operator"];
	["PrivateMedia_filesize_operator"]: ValueTypes["PrivateMedia_filesize_operator"];
	["PrivateMedia_width_operator"]: ValueTypes["PrivateMedia_width_operator"];
	["PrivateMedia_height_operator"]: ValueTypes["PrivateMedia_height_operator"];
	["PrivateMedia_focalX_operator"]: ValueTypes["PrivateMedia_focalX_operator"];
	["PrivateMedia_focalY_operator"]: ValueTypes["PrivateMedia_focalY_operator"];
	["PrivateMedia_sizes__thumbnail__url_operator"]: ValueTypes["PrivateMedia_sizes__thumbnail__url_operator"];
	["PrivateMedia_sizes__thumbnail__width_operator"]: ValueTypes["PrivateMedia_sizes__thumbnail__width_operator"];
	["PrivateMedia_sizes__thumbnail__height_operator"]: ValueTypes["PrivateMedia_sizes__thumbnail__height_operator"];
	["PrivateMedia_sizes__thumbnail__mimeType_operator"]: ValueTypes["PrivateMedia_sizes__thumbnail__mimeType_operator"];
	["PrivateMedia_sizes__thumbnail__filesize_operator"]: ValueTypes["PrivateMedia_sizes__thumbnail__filesize_operator"];
	["PrivateMedia_sizes__thumbnail__filename_operator"]: ValueTypes["PrivateMedia_sizes__thumbnail__filename_operator"];
	["PrivateMedia_sizes__portrait__url_operator"]: ValueTypes["PrivateMedia_sizes__portrait__url_operator"];
	["PrivateMedia_sizes__portrait__width_operator"]: ValueTypes["PrivateMedia_sizes__portrait__width_operator"];
	["PrivateMedia_sizes__portrait__height_operator"]: ValueTypes["PrivateMedia_sizes__portrait__height_operator"];
	["PrivateMedia_sizes__portrait__mimeType_operator"]: ValueTypes["PrivateMedia_sizes__portrait__mimeType_operator"];
	["PrivateMedia_sizes__portrait__filesize_operator"]: ValueTypes["PrivateMedia_sizes__portrait__filesize_operator"];
	["PrivateMedia_sizes__portrait__filename_operator"]: ValueTypes["PrivateMedia_sizes__portrait__filename_operator"];
	["PrivateMedia_sizes__hero__url_operator"]: ValueTypes["PrivateMedia_sizes__hero__url_operator"];
	["PrivateMedia_sizes__hero__width_operator"]: ValueTypes["PrivateMedia_sizes__hero__width_operator"];
	["PrivateMedia_sizes__hero__height_operator"]: ValueTypes["PrivateMedia_sizes__hero__height_operator"];
	["PrivateMedia_sizes__hero__mimeType_operator"]: ValueTypes["PrivateMedia_sizes__hero__mimeType_operator"];
	["PrivateMedia_sizes__hero__filesize_operator"]: ValueTypes["PrivateMedia_sizes__hero__filesize_operator"];
	["PrivateMedia_sizes__hero__filename_operator"]: ValueTypes["PrivateMedia_sizes__hero__filename_operator"];
	["PrivateMedia_sizes__thumbnail_jpeg__url_operator"]: ValueTypes["PrivateMedia_sizes__thumbnail_jpeg__url_operator"];
	["PrivateMedia_sizes__thumbnail_jpeg__width_operator"]: ValueTypes["PrivateMedia_sizes__thumbnail_jpeg__width_operator"];
	["PrivateMedia_sizes__thumbnail_jpeg__height_operator"]: ValueTypes["PrivateMedia_sizes__thumbnail_jpeg__height_operator"];
	["PrivateMedia_sizes__thumbnail_jpeg__mimeType_operator"]: ValueTypes["PrivateMedia_sizes__thumbnail_jpeg__mimeType_operator"];
	["PrivateMedia_sizes__thumbnail_jpeg__filesize_operator"]: ValueTypes["PrivateMedia_sizes__thumbnail_jpeg__filesize_operator"];
	["PrivateMedia_sizes__thumbnail_jpeg__filename_operator"]: ValueTypes["PrivateMedia_sizes__thumbnail_jpeg__filename_operator"];
	["PrivateMedia_sizes__portrait_jpeg__url_operator"]: ValueTypes["PrivateMedia_sizes__portrait_jpeg__url_operator"];
	["PrivateMedia_sizes__portrait_jpeg__width_operator"]: ValueTypes["PrivateMedia_sizes__portrait_jpeg__width_operator"];
	["PrivateMedia_sizes__portrait_jpeg__height_operator"]: ValueTypes["PrivateMedia_sizes__portrait_jpeg__height_operator"];
	["PrivateMedia_sizes__portrait_jpeg__mimeType_operator"]: ValueTypes["PrivateMedia_sizes__portrait_jpeg__mimeType_operator"];
	["PrivateMedia_sizes__portrait_jpeg__filesize_operator"]: ValueTypes["PrivateMedia_sizes__portrait_jpeg__filesize_operator"];
	["PrivateMedia_sizes__portrait_jpeg__filename_operator"]: ValueTypes["PrivateMedia_sizes__portrait_jpeg__filename_operator"];
	["PrivateMedia_sizes__hero_jpeg__url_operator"]: ValueTypes["PrivateMedia_sizes__hero_jpeg__url_operator"];
	["PrivateMedia_sizes__hero_jpeg__width_operator"]: ValueTypes["PrivateMedia_sizes__hero_jpeg__width_operator"];
	["PrivateMedia_sizes__hero_jpeg__height_operator"]: ValueTypes["PrivateMedia_sizes__hero_jpeg__height_operator"];
	["PrivateMedia_sizes__hero_jpeg__mimeType_operator"]: ValueTypes["PrivateMedia_sizes__hero_jpeg__mimeType_operator"];
	["PrivateMedia_sizes__hero_jpeg__filesize_operator"]: ValueTypes["PrivateMedia_sizes__hero_jpeg__filesize_operator"];
	["PrivateMedia_sizes__hero_jpeg__filename_operator"]: ValueTypes["PrivateMedia_sizes__hero_jpeg__filename_operator"];
	["PrivateMedia_id_operator"]: ValueTypes["PrivateMedia_id_operator"];
	["PrivateMedia_where_and"]: ValueTypes["PrivateMedia_where_and"];
	["PrivateMedia_where_or"]: ValueTypes["PrivateMedia_where_or"];
	["Media_where"]: ValueTypes["Media_where"];
	["Media_alt_operator"]: ValueTypes["Media_alt_operator"];
	["Media_updatedAt_operator"]: ValueTypes["Media_updatedAt_operator"];
	["Media_createdAt_operator"]: ValueTypes["Media_createdAt_operator"];
	["Media_url_operator"]: ValueTypes["Media_url_operator"];
	["Media_filename_operator"]: ValueTypes["Media_filename_operator"];
	["Media_mimeType_operator"]: ValueTypes["Media_mimeType_operator"];
	["Media_filesize_operator"]: ValueTypes["Media_filesize_operator"];
	["Media_width_operator"]: ValueTypes["Media_width_operator"];
	["Media_height_operator"]: ValueTypes["Media_height_operator"];
	["Media_focalX_operator"]: ValueTypes["Media_focalX_operator"];
	["Media_focalY_operator"]: ValueTypes["Media_focalY_operator"];
	["Media_sizes__small__url_operator"]: ValueTypes["Media_sizes__small__url_operator"];
	["Media_sizes__small__width_operator"]: ValueTypes["Media_sizes__small__width_operator"];
	["Media_sizes__small__height_operator"]: ValueTypes["Media_sizes__small__height_operator"];
	["Media_sizes__small__mimeType_operator"]: ValueTypes["Media_sizes__small__mimeType_operator"];
	["Media_sizes__small__filesize_operator"]: ValueTypes["Media_sizes__small__filesize_operator"];
	["Media_sizes__small__filename_operator"]: ValueTypes["Media_sizes__small__filename_operator"];
	["Media_sizes__medium__url_operator"]: ValueTypes["Media_sizes__medium__url_operator"];
	["Media_sizes__medium__width_operator"]: ValueTypes["Media_sizes__medium__width_operator"];
	["Media_sizes__medium__height_operator"]: ValueTypes["Media_sizes__medium__height_operator"];
	["Media_sizes__medium__mimeType_operator"]: ValueTypes["Media_sizes__medium__mimeType_operator"];
	["Media_sizes__medium__filesize_operator"]: ValueTypes["Media_sizes__medium__filesize_operator"];
	["Media_sizes__medium__filename_operator"]: ValueTypes["Media_sizes__medium__filename_operator"];
	["Media_sizes__large__url_operator"]: ValueTypes["Media_sizes__large__url_operator"];
	["Media_sizes__large__width_operator"]: ValueTypes["Media_sizes__large__width_operator"];
	["Media_sizes__large__height_operator"]: ValueTypes["Media_sizes__large__height_operator"];
	["Media_sizes__large__mimeType_operator"]: ValueTypes["Media_sizes__large__mimeType_operator"];
	["Media_sizes__large__filesize_operator"]: ValueTypes["Media_sizes__large__filesize_operator"];
	["Media_sizes__large__filename_operator"]: ValueTypes["Media_sizes__large__filename_operator"];
	["Media_sizes__small_jpeg__url_operator"]: ValueTypes["Media_sizes__small_jpeg__url_operator"];
	["Media_sizes__small_jpeg__width_operator"]: ValueTypes["Media_sizes__small_jpeg__width_operator"];
	["Media_sizes__small_jpeg__height_operator"]: ValueTypes["Media_sizes__small_jpeg__height_operator"];
	["Media_sizes__small_jpeg__mimeType_operator"]: ValueTypes["Media_sizes__small_jpeg__mimeType_operator"];
	["Media_sizes__small_jpeg__filesize_operator"]: ValueTypes["Media_sizes__small_jpeg__filesize_operator"];
	["Media_sizes__small_jpeg__filename_operator"]: ValueTypes["Media_sizes__small_jpeg__filename_operator"];
	["Media_sizes__medium_jpeg__url_operator"]: ValueTypes["Media_sizes__medium_jpeg__url_operator"];
	["Media_sizes__medium_jpeg__width_operator"]: ValueTypes["Media_sizes__medium_jpeg__width_operator"];
	["Media_sizes__medium_jpeg__height_operator"]: ValueTypes["Media_sizes__medium_jpeg__height_operator"];
	["Media_sizes__medium_jpeg__mimeType_operator"]: ValueTypes["Media_sizes__medium_jpeg__mimeType_operator"];
	["Media_sizes__medium_jpeg__filesize_operator"]: ValueTypes["Media_sizes__medium_jpeg__filesize_operator"];
	["Media_sizes__medium_jpeg__filename_operator"]: ValueTypes["Media_sizes__medium_jpeg__filename_operator"];
	["Media_sizes__large_jpeg__url_operator"]: ValueTypes["Media_sizes__large_jpeg__url_operator"];
	["Media_sizes__large_jpeg__width_operator"]: ValueTypes["Media_sizes__large_jpeg__width_operator"];
	["Media_sizes__large_jpeg__height_operator"]: ValueTypes["Media_sizes__large_jpeg__height_operator"];
	["Media_sizes__large_jpeg__mimeType_operator"]: ValueTypes["Media_sizes__large_jpeg__mimeType_operator"];
	["Media_sizes__large_jpeg__filesize_operator"]: ValueTypes["Media_sizes__large_jpeg__filesize_operator"];
	["Media_sizes__large_jpeg__filename_operator"]: ValueTypes["Media_sizes__large_jpeg__filename_operator"];
	["Media_id_operator"]: ValueTypes["Media_id_operator"];
	["Media_where_and"]: ValueTypes["Media_where_and"];
	["Media_where_or"]: ValueTypes["Media_where_or"];
	["BackupMedia_where"]: ValueTypes["BackupMedia_where"];
	["BackupMedia_alt_operator"]: ValueTypes["BackupMedia_alt_operator"];
	["BackupMedia_creator_operator"]: ValueTypes["BackupMedia_creator_operator"];
	["BackupMedia_updatedAt_operator"]: ValueTypes["BackupMedia_updatedAt_operator"];
	["BackupMedia_createdAt_operator"]: ValueTypes["BackupMedia_createdAt_operator"];
	["BackupMedia_url_operator"]: ValueTypes["BackupMedia_url_operator"];
	["BackupMedia_filename_operator"]: ValueTypes["BackupMedia_filename_operator"];
	["BackupMedia_mimeType_operator"]: ValueTypes["BackupMedia_mimeType_operator"];
	["BackupMedia_filesize_operator"]: ValueTypes["BackupMedia_filesize_operator"];
	["BackupMedia_width_operator"]: ValueTypes["BackupMedia_width_operator"];
	["BackupMedia_height_operator"]: ValueTypes["BackupMedia_height_operator"];
	["BackupMedia_focalX_operator"]: ValueTypes["BackupMedia_focalX_operator"];
	["BackupMedia_focalY_operator"]: ValueTypes["BackupMedia_focalY_operator"];
	["BackupMedia_sizes__thumbnail__url_operator"]: ValueTypes["BackupMedia_sizes__thumbnail__url_operator"];
	["BackupMedia_sizes__thumbnail__width_operator"]: ValueTypes["BackupMedia_sizes__thumbnail__width_operator"];
	["BackupMedia_sizes__thumbnail__height_operator"]: ValueTypes["BackupMedia_sizes__thumbnail__height_operator"];
	["BackupMedia_sizes__thumbnail__mimeType_operator"]: ValueTypes["BackupMedia_sizes__thumbnail__mimeType_operator"];
	["BackupMedia_sizes__thumbnail__filesize_operator"]: ValueTypes["BackupMedia_sizes__thumbnail__filesize_operator"];
	["BackupMedia_sizes__thumbnail__filename_operator"]: ValueTypes["BackupMedia_sizes__thumbnail__filename_operator"];
	["BackupMedia_sizes__sixteenByNineMedium__url_operator"]: ValueTypes["BackupMedia_sizes__sixteenByNineMedium__url_operator"];
	["BackupMedia_sizes__sixteenByNineMedium__width_operator"]: ValueTypes["BackupMedia_sizes__sixteenByNineMedium__width_operator"];
	["BackupMedia_sizes__sixteenByNineMedium__height_operator"]: ValueTypes["BackupMedia_sizes__sixteenByNineMedium__height_operator"];
	["BackupMedia_sizes__sixteenByNineMedium__mimeType_operator"]: ValueTypes["BackupMedia_sizes__sixteenByNineMedium__mimeType_operator"];
	["BackupMedia_sizes__sixteenByNineMedium__filesize_operator"]: ValueTypes["BackupMedia_sizes__sixteenByNineMedium__filesize_operator"];
	["BackupMedia_sizes__sixteenByNineMedium__filename_operator"]: ValueTypes["BackupMedia_sizes__sixteenByNineMedium__filename_operator"];
	["BackupMedia_id_operator"]: ValueTypes["BackupMedia_id_operator"];
	["BackupMedia_where_and"]: ValueTypes["BackupMedia_where_and"];
	["BackupMedia_where_or"]: ValueTypes["BackupMedia_where_or"];
	["AccountSuspendRequest_where"]: ValueTypes["AccountSuspendRequest_where"];
	["AccountSuspendRequest_user_operator"]: ValueTypes["AccountSuspendRequest_user_operator"];
	["AccountSuspendRequest_handledBy_operator"]: ValueTypes["AccountSuspendRequest_handledBy_operator"];
	["AccountSuspendRequest_updatedAt_operator"]: ValueTypes["AccountSuspendRequest_updatedAt_operator"];
	["AccountSuspendRequest_createdAt_operator"]: ValueTypes["AccountSuspendRequest_createdAt_operator"];
	["AccountSuspendRequest_id_operator"]: ValueTypes["AccountSuspendRequest_id_operator"];
	["AccountSuspendRequest_where_and"]: ValueTypes["AccountSuspendRequest_where_and"];
	["AccountSuspendRequest_where_or"]: ValueTypes["AccountSuspendRequest_where_or"];
	["Device_User_RelationTo"]: ValueTypes["Device_User_RelationTo"];
	["Device_where"]: ValueTypes["Device_where"];
	["Device_user_Relation"]: ValueTypes["Device_user_Relation"];
	["Device_user_Relation_RelationTo"]: ValueTypes["Device_user_Relation_RelationTo"];
	["Device_udid_operator"]: ValueTypes["Device_udid_operator"];
	["Device_pushToken_operator"]: ValueTypes["Device_pushToken_operator"];
	["Device_updatedAt_operator"]: ValueTypes["Device_updatedAt_operator"];
	["Device_createdAt_operator"]: ValueTypes["Device_createdAt_operator"];
	["Device_id_operator"]: ValueTypes["Device_id_operator"];
	["Device_where_and"]: ValueTypes["Device_where_and"];
	["Device_where_or"]: ValueTypes["Device_where_or"];
	["Notification_type"]: ValueTypes["Notification_type"];
	["Notification_Meta_RelationTo"]: ValueTypes["Notification_Meta_RelationTo"];
	["Notification_where"]: ValueTypes["Notification_where"];
	["Notification_receiver_operator"]: ValueTypes["Notification_receiver_operator"];
	["Notification_type_operator"]: ValueTypes["Notification_type_operator"];
	["Notification_type_Input"]: ValueTypes["Notification_type_Input"];
	["Notification_meta_Relation"]: ValueTypes["Notification_meta_Relation"];
	["Notification_meta_Relation_RelationTo"]: ValueTypes["Notification_meta_Relation_RelationTo"];
	["Notification_message_operator"]: ValueTypes["Notification_message_operator"];
	["Notification_sender_operator"]: ValueTypes["Notification_sender_operator"];
	["Notification_readed_operator"]: ValueTypes["Notification_readed_operator"];
	["Notification_deletedByUser_operator"]: ValueTypes["Notification_deletedByUser_operator"];
	["Notification_updatedAt_operator"]: ValueTypes["Notification_updatedAt_operator"];
	["Notification_createdAt_operator"]: ValueTypes["Notification_createdAt_operator"];
	["Notification_id_operator"]: ValueTypes["Notification_id_operator"];
	["Notification_where_and"]: ValueTypes["Notification_where_and"];
	["Notification_where_or"]: ValueTypes["Notification_where_or"];
	["RedirectSetting_where"]: ValueTypes["RedirectSetting_where"];
	["RedirectSetting_from_operator"]: ValueTypes["RedirectSetting_from_operator"];
	["RedirectSetting_to_operator"]: ValueTypes["RedirectSetting_to_operator"];
	["RedirectSetting_updatedAt_operator"]: ValueTypes["RedirectSetting_updatedAt_operator"];
	["RedirectSetting_createdAt_operator"]: ValueTypes["RedirectSetting_createdAt_operator"];
	["RedirectSetting_id_operator"]: ValueTypes["RedirectSetting_id_operator"];
	["RedirectSetting_where_and"]: ValueTypes["RedirectSetting_where_and"];
	["RedirectSetting_where_or"]: ValueTypes["RedirectSetting_where_or"];
	["EventRanking_template"]: ValueTypes["EventRanking_template"];
	["EventRanking_region"]: ValueTypes["EventRanking_region"];
	["EventRanking_where"]: ValueTypes["EventRanking_where"];
	["EventRanking_name_operator"]: ValueTypes["EventRanking_name_operator"];
	["EventRanking_visible_operator"]: ValueTypes["EventRanking_visible_operator"];
	["EventRanking_maxItems_operator"]: ValueTypes["EventRanking_maxItems_operator"];
	["EventRanking_template_operator"]: ValueTypes["EventRanking_template_operator"];
	["EventRanking_template_Input"]: ValueTypes["EventRanking_template_Input"];
	["EventRanking_list__item_operator"]: ValueTypes["EventRanking_list__item_operator"];
	["EventRanking_list__id_operator"]: ValueTypes["EventRanking_list__id_operator"];
	["EventRanking_region_operator"]: ValueTypes["EventRanking_region_operator"];
	["EventRanking_region_Input"]: ValueTypes["EventRanking_region_Input"];
	["EventRanking_updatedAt_operator"]: ValueTypes["EventRanking_updatedAt_operator"];
	["EventRanking_createdAt_operator"]: ValueTypes["EventRanking_createdAt_operator"];
	["EventRanking_id_operator"]: ValueTypes["EventRanking_id_operator"];
	["EventRanking_where_and"]: ValueTypes["EventRanking_where_and"];
	["EventRanking_where_or"]: ValueTypes["EventRanking_where_or"];
	["LocationRanking_template"]: ValueTypes["LocationRanking_template"];
	["LocationRanking_region"]: ValueTypes["LocationRanking_region"];
	["LocationRanking_where"]: ValueTypes["LocationRanking_where"];
	["LocationRanking_name_operator"]: ValueTypes["LocationRanking_name_operator"];
	["LocationRanking_visible_operator"]: ValueTypes["LocationRanking_visible_operator"];
	["LocationRanking_maxItems_operator"]: ValueTypes["LocationRanking_maxItems_operator"];
	["LocationRanking_template_operator"]: ValueTypes["LocationRanking_template_operator"];
	["LocationRanking_template_Input"]: ValueTypes["LocationRanking_template_Input"];
	["LocationRanking_list__item_operator"]: ValueTypes["LocationRanking_list__item_operator"];
	["LocationRanking_list__id_operator"]: ValueTypes["LocationRanking_list__id_operator"];
	["LocationRanking_region_operator"]: ValueTypes["LocationRanking_region_operator"];
	["LocationRanking_region_Input"]: ValueTypes["LocationRanking_region_Input"];
	["LocationRanking_updatedAt_operator"]: ValueTypes["LocationRanking_updatedAt_operator"];
	["LocationRanking_createdAt_operator"]: ValueTypes["LocationRanking_createdAt_operator"];
	["LocationRanking_id_operator"]: ValueTypes["LocationRanking_id_operator"];
	["LocationRanking_where_and"]: ValueTypes["LocationRanking_where_and"];
	["LocationRanking_where_or"]: ValueTypes["LocationRanking_where_or"];
	["EventCalendar_region"]: ValueTypes["EventCalendar_region"];
	["EventCalendar_where"]: ValueTypes["EventCalendar_where"];
	["EventCalendar_date_operator"]: ValueTypes["EventCalendar_date_operator"];
	["EventCalendar_item_operator"]: ValueTypes["EventCalendar_item_operator"];
	["EventCalendar_title_operator"]: ValueTypes["EventCalendar_title_operator"];
	["EventCalendar_region_operator"]: ValueTypes["EventCalendar_region_operator"];
	["EventCalendar_region_Input"]: ValueTypes["EventCalendar_region_Input"];
	["EventCalendar_updatedAt_operator"]: ValueTypes["EventCalendar_updatedAt_operator"];
	["EventCalendar_createdAt_operator"]: ValueTypes["EventCalendar_createdAt_operator"];
	["EventCalendar_id_operator"]: ValueTypes["EventCalendar_id_operator"];
	["EventCalendar_where_and"]: ValueTypes["EventCalendar_where_and"];
	["EventCalendar_where_or"]: ValueTypes["EventCalendar_where_or"];
	["EditorChoice_region"]: ValueTypes["EditorChoice_region"];
	["EditorChoice_where"]: ValueTypes["EditorChoice_where"];
	["EditorChoice_name_operator"]: ValueTypes["EditorChoice_name_operator"];
	["EditorChoice_visible_operator"]: ValueTypes["EditorChoice_visible_operator"];
	["EditorChoice_editorialPicks_operator"]: ValueTypes["EditorChoice_editorialPicks_operator"];
	["EditorChoice_list__item_operator"]: ValueTypes["EditorChoice_list__item_operator"];
	["EditorChoice_list__id_operator"]: ValueTypes["EditorChoice_list__id_operator"];
	["EditorChoice_region_operator"]: ValueTypes["EditorChoice_region_operator"];
	["EditorChoice_region_Input"]: ValueTypes["EditorChoice_region_Input"];
	["EditorChoice_updatedAt_operator"]: ValueTypes["EditorChoice_updatedAt_operator"];
	["EditorChoice_createdAt_operator"]: ValueTypes["EditorChoice_createdAt_operator"];
	["EditorChoice_id_operator"]: ValueTypes["EditorChoice_id_operator"];
	["EditorChoice_where_and"]: ValueTypes["EditorChoice_where_and"];
	["EditorChoice_where_or"]: ValueTypes["EditorChoice_where_or"];
	["CriteriaSetting_Criteria_region"]: ValueTypes["CriteriaSetting_Criteria_region"];
	["CriteriaSetting_where"]: ValueTypes["CriteriaSetting_where"];
	["CriteriaSetting_criteria__date_operator"]: ValueTypes["CriteriaSetting_criteria__date_operator"];
	["CriteriaSetting_criteria__audience_operator"]: ValueTypes["CriteriaSetting_criteria__audience_operator"];
	["CriteriaSetting_criteria__category_operator"]: ValueTypes["CriteriaSetting_criteria__category_operator"];
	["CriteriaSetting_criteria__tag_operator"]: ValueTypes["CriteriaSetting_criteria__tag_operator"];
	["CriteriaSetting_criteria__region_operator"]: ValueTypes["CriteriaSetting_criteria__region_operator"];
	["CriteriaSetting_criteria__region_Input"]: ValueTypes["CriteriaSetting_criteria__region_Input"];
	["CriteriaSetting_updatedAt_operator"]: ValueTypes["CriteriaSetting_updatedAt_operator"];
	["CriteriaSetting_createdAt_operator"]: ValueTypes["CriteriaSetting_createdAt_operator"];
	["CriteriaSetting_id_operator"]: ValueTypes["CriteriaSetting_id_operator"];
	["CriteriaSetting_where_and"]: ValueTypes["CriteriaSetting_where_and"];
	["CriteriaSetting_where_or"]: ValueTypes["CriteriaSetting_where_or"];
	["OrganiserSetting_region"]: ValueTypes["OrganiserSetting_region"];
	["OrganiserSetting_where"]: ValueTypes["OrganiserSetting_where"];
	["OrganiserSetting_name_operator"]: ValueTypes["OrganiserSetting_name_operator"];
	["OrganiserSetting_organiserList_operator"]: ValueTypes["OrganiserSetting_organiserList_operator"];
	["OrganiserSetting_region_operator"]: ValueTypes["OrganiserSetting_region_operator"];
	["OrganiserSetting_region_Input"]: ValueTypes["OrganiserSetting_region_Input"];
	["OrganiserSetting_updatedAt_operator"]: ValueTypes["OrganiserSetting_updatedAt_operator"];
	["OrganiserSetting_createdAt_operator"]: ValueTypes["OrganiserSetting_createdAt_operator"];
	["OrganiserSetting_id_operator"]: ValueTypes["OrganiserSetting_id_operator"];
	["OrganiserSetting_where_and"]: ValueTypes["OrganiserSetting_where_and"];
	["OrganiserSetting_where_or"]: ValueTypes["OrganiserSetting_where_or"];
	["PostSetting_region"]: ValueTypes["PostSetting_region"];
	["PostSetting_where"]: ValueTypes["PostSetting_where"];
	["PostSetting_name_operator"]: ValueTypes["PostSetting_name_operator"];
	["PostSetting_featuredPosts_operator"]: ValueTypes["PostSetting_featuredPosts_operator"];
	["PostSetting_region_operator"]: ValueTypes["PostSetting_region_operator"];
	["PostSetting_region_Input"]: ValueTypes["PostSetting_region_Input"];
	["PostSetting_updatedAt_operator"]: ValueTypes["PostSetting_updatedAt_operator"];
	["PostSetting_createdAt_operator"]: ValueTypes["PostSetting_createdAt_operator"];
	["PostSetting_id_operator"]: ValueTypes["PostSetting_id_operator"];
	["PostSetting_where_and"]: ValueTypes["PostSetting_where_and"];
	["PostSetting_where_or"]: ValueTypes["PostSetting_where_or"];
	["HomepageSetting_region"]: ValueTypes["HomepageSetting_region"];
	["ComingWeekEventAndLocationRankingBlock_appearance"]: ValueTypes["ComingWeekEventAndLocationRankingBlock_appearance"];
	["ComingWeekEventAndLocationRankingBlock_byRegion"]: ValueTypes["ComingWeekEventAndLocationRankingBlock_byRegion"];
	["EventRankingBlock_appearance"]: ValueTypes["EventRankingBlock_appearance"];
	["LocationRankingBlock_appearance"]: ValueTypes["LocationRankingBlock_appearance"];
	["HomepageSetting_where"]: ValueTypes["HomepageSetting_where"];
	["HomepageSetting_name_operator"]: ValueTypes["HomepageSetting_name_operator"];
	["HomepageSetting_region_operator"]: ValueTypes["HomepageSetting_region_operator"];
	["HomepageSetting_region_Input"]: ValueTypes["HomepageSetting_region_Input"];
	["HomepageSetting_updatedAt_operator"]: ValueTypes["HomepageSetting_updatedAt_operator"];
	["HomepageSetting_createdAt_operator"]: ValueTypes["HomepageSetting_createdAt_operator"];
	["HomepageSetting_id_operator"]: ValueTypes["HomepageSetting_id_operator"];
	["HomepageSetting_where_and"]: ValueTypes["HomepageSetting_where_and"];
	["HomepageSetting_where_or"]: ValueTypes["HomepageSetting_where_or"];
	["BannerSection_Banners_Desktop_where"]: ValueTypes["BannerSection_Banners_Desktop_where"];
	["BannerSection_Banners_Desktop_alt_operator"]: ValueTypes["BannerSection_Banners_Desktop_alt_operator"];
	["BannerSection_Banners_Desktop_updatedAt_operator"]: ValueTypes["BannerSection_Banners_Desktop_updatedAt_operator"];
	["BannerSection_Banners_Desktop_createdAt_operator"]: ValueTypes["BannerSection_Banners_Desktop_createdAt_operator"];
	["BannerSection_Banners_Desktop_url_operator"]: ValueTypes["BannerSection_Banners_Desktop_url_operator"];
	["BannerSection_Banners_Desktop_filename_operator"]: ValueTypes["BannerSection_Banners_Desktop_filename_operator"];
	["BannerSection_Banners_Desktop_mimeType_operator"]: ValueTypes["BannerSection_Banners_Desktop_mimeType_operator"];
	["BannerSection_Banners_Desktop_filesize_operator"]: ValueTypes["BannerSection_Banners_Desktop_filesize_operator"];
	["BannerSection_Banners_Desktop_width_operator"]: ValueTypes["BannerSection_Banners_Desktop_width_operator"];
	["BannerSection_Banners_Desktop_height_operator"]: ValueTypes["BannerSection_Banners_Desktop_height_operator"];
	["BannerSection_Banners_Desktop_focalX_operator"]: ValueTypes["BannerSection_Banners_Desktop_focalX_operator"];
	["BannerSection_Banners_Desktop_focalY_operator"]: ValueTypes["BannerSection_Banners_Desktop_focalY_operator"];
	["BannerSection_Banners_Desktop_sizes__small__url_operator"]: ValueTypes["BannerSection_Banners_Desktop_sizes__small__url_operator"];
	["BannerSection_Banners_Desktop_sizes__small__width_operator"]: ValueTypes["BannerSection_Banners_Desktop_sizes__small__width_operator"];
	["BannerSection_Banners_Desktop_sizes__small__height_operator"]: ValueTypes["BannerSection_Banners_Desktop_sizes__small__height_operator"];
	["BannerSection_Banners_Desktop_sizes__small__mimeType_operator"]: ValueTypes["BannerSection_Banners_Desktop_sizes__small__mimeType_operator"];
	["BannerSection_Banners_Desktop_sizes__small__filesize_operator"]: ValueTypes["BannerSection_Banners_Desktop_sizes__small__filesize_operator"];
	["BannerSection_Banners_Desktop_sizes__small__filename_operator"]: ValueTypes["BannerSection_Banners_Desktop_sizes__small__filename_operator"];
	["BannerSection_Banners_Desktop_sizes__medium__url_operator"]: ValueTypes["BannerSection_Banners_Desktop_sizes__medium__url_operator"];
	["BannerSection_Banners_Desktop_sizes__medium__width_operator"]: ValueTypes["BannerSection_Banners_Desktop_sizes__medium__width_operator"];
	["BannerSection_Banners_Desktop_sizes__medium__height_operator"]: ValueTypes["BannerSection_Banners_Desktop_sizes__medium__height_operator"];
	["BannerSection_Banners_Desktop_sizes__medium__mimeType_operator"]: ValueTypes["BannerSection_Banners_Desktop_sizes__medium__mimeType_operator"];
	["BannerSection_Banners_Desktop_sizes__medium__filesize_operator"]: ValueTypes["BannerSection_Banners_Desktop_sizes__medium__filesize_operator"];
	["BannerSection_Banners_Desktop_sizes__medium__filename_operator"]: ValueTypes["BannerSection_Banners_Desktop_sizes__medium__filename_operator"];
	["BannerSection_Banners_Desktop_sizes__large__url_operator"]: ValueTypes["BannerSection_Banners_Desktop_sizes__large__url_operator"];
	["BannerSection_Banners_Desktop_sizes__large__width_operator"]: ValueTypes["BannerSection_Banners_Desktop_sizes__large__width_operator"];
	["BannerSection_Banners_Desktop_sizes__large__height_operator"]: ValueTypes["BannerSection_Banners_Desktop_sizes__large__height_operator"];
	["BannerSection_Banners_Desktop_sizes__large__mimeType_operator"]: ValueTypes["BannerSection_Banners_Desktop_sizes__large__mimeType_operator"];
	["BannerSection_Banners_Desktop_sizes__large__filesize_operator"]: ValueTypes["BannerSection_Banners_Desktop_sizes__large__filesize_operator"];
	["BannerSection_Banners_Desktop_sizes__large__filename_operator"]: ValueTypes["BannerSection_Banners_Desktop_sizes__large__filename_operator"];
	["BannerSection_Banners_Desktop_sizes__small_jpeg__url_operator"]: ValueTypes["BannerSection_Banners_Desktop_sizes__small_jpeg__url_operator"];
	["BannerSection_Banners_Desktop_sizes__small_jpeg__width_operator"]: ValueTypes["BannerSection_Banners_Desktop_sizes__small_jpeg__width_operator"];
	["BannerSection_Banners_Desktop_sizes__small_jpeg__height_operator"]: ValueTypes["BannerSection_Banners_Desktop_sizes__small_jpeg__height_operator"];
	["BannerSection_Banners_Desktop_sizes__small_jpeg__mimeType_operator"]: ValueTypes["BannerSection_Banners_Desktop_sizes__small_jpeg__mimeType_operator"];
	["BannerSection_Banners_Desktop_sizes__small_jpeg__filesize_operator"]: ValueTypes["BannerSection_Banners_Desktop_sizes__small_jpeg__filesize_operator"];
	["BannerSection_Banners_Desktop_sizes__small_jpeg__filename_operator"]: ValueTypes["BannerSection_Banners_Desktop_sizes__small_jpeg__filename_operator"];
	["BannerSection_Banners_Desktop_sizes__medium_jpeg__url_operator"]: ValueTypes["BannerSection_Banners_Desktop_sizes__medium_jpeg__url_operator"];
	["BannerSection_Banners_Desktop_sizes__medium_jpeg__width_operator"]: ValueTypes["BannerSection_Banners_Desktop_sizes__medium_jpeg__width_operator"];
	["BannerSection_Banners_Desktop_sizes__medium_jpeg__height_operator"]: ValueTypes["BannerSection_Banners_Desktop_sizes__medium_jpeg__height_operator"];
	["BannerSection_Banners_Desktop_sizes__medium_jpeg__mimeType_operator"]: ValueTypes["BannerSection_Banners_Desktop_sizes__medium_jpeg__mimeType_operator"];
	["BannerSection_Banners_Desktop_sizes__medium_jpeg__filesize_operator"]: ValueTypes["BannerSection_Banners_Desktop_sizes__medium_jpeg__filesize_operator"];
	["BannerSection_Banners_Desktop_sizes__medium_jpeg__filename_operator"]: ValueTypes["BannerSection_Banners_Desktop_sizes__medium_jpeg__filename_operator"];
	["BannerSection_Banners_Desktop_sizes__large_jpeg__url_operator"]: ValueTypes["BannerSection_Banners_Desktop_sizes__large_jpeg__url_operator"];
	["BannerSection_Banners_Desktop_sizes__large_jpeg__width_operator"]: ValueTypes["BannerSection_Banners_Desktop_sizes__large_jpeg__width_operator"];
	["BannerSection_Banners_Desktop_sizes__large_jpeg__height_operator"]: ValueTypes["BannerSection_Banners_Desktop_sizes__large_jpeg__height_operator"];
	["BannerSection_Banners_Desktop_sizes__large_jpeg__mimeType_operator"]: ValueTypes["BannerSection_Banners_Desktop_sizes__large_jpeg__mimeType_operator"];
	["BannerSection_Banners_Desktop_sizes__large_jpeg__filesize_operator"]: ValueTypes["BannerSection_Banners_Desktop_sizes__large_jpeg__filesize_operator"];
	["BannerSection_Banners_Desktop_sizes__large_jpeg__filename_operator"]: ValueTypes["BannerSection_Banners_Desktop_sizes__large_jpeg__filename_operator"];
	["BannerSection_Banners_Desktop_id_operator"]: ValueTypes["BannerSection_Banners_Desktop_id_operator"];
	["BannerSection_Banners_Desktop_where_and"]: ValueTypes["BannerSection_Banners_Desktop_where_and"];
	["BannerSection_Banners_Desktop_where_or"]: ValueTypes["BannerSection_Banners_Desktop_where_or"];
	["BannerSection_Banners_region"]: ValueTypes["BannerSection_Banners_region"];
	["BannerSection_where"]: ValueTypes["BannerSection_where"];
	["BannerSection_name_operator"]: ValueTypes["BannerSection_name_operator"];
	["BannerSection_slug_operator"]: ValueTypes["BannerSection_slug_operator"];
	["BannerSection_banners__name_operator"]: ValueTypes["BannerSection_banners__name_operator"];
	["BannerSection_banners__status_operator"]: ValueTypes["BannerSection_banners__status_operator"];
	["BannerSection_banners__desktop_operator"]: ValueTypes["BannerSection_banners__desktop_operator"];
	["BannerSection_banners__bannerStartDate_operator"]: ValueTypes["BannerSection_banners__bannerStartDate_operator"];
	["BannerSection_banners__bannerEndDate_operator"]: ValueTypes["BannerSection_banners__bannerEndDate_operator"];
	["BannerSection_banners__region_operator"]: ValueTypes["BannerSection_banners__region_operator"];
	["BannerSection_banners__region_Input"]: ValueTypes["BannerSection_banners__region_Input"];
	["BannerSection_banners__id_operator"]: ValueTypes["BannerSection_banners__id_operator"];
	["BannerSection_updatedAt_operator"]: ValueTypes["BannerSection_updatedAt_operator"];
	["BannerSection_createdAt_operator"]: ValueTypes["BannerSection_createdAt_operator"];
	["BannerSection_id_operator"]: ValueTypes["BannerSection_id_operator"];
	["BannerSection_where_and"]: ValueTypes["BannerSection_where_and"];
	["BannerSection_where_or"]: ValueTypes["BannerSection_where_or"];
	["MacaoBannerSection_Banners_Desktop_where"]: ValueTypes["MacaoBannerSection_Banners_Desktop_where"];
	["MacaoBannerSection_Banners_Desktop_alt_operator"]: ValueTypes["MacaoBannerSection_Banners_Desktop_alt_operator"];
	["MacaoBannerSection_Banners_Desktop_updatedAt_operator"]: ValueTypes["MacaoBannerSection_Banners_Desktop_updatedAt_operator"];
	["MacaoBannerSection_Banners_Desktop_createdAt_operator"]: ValueTypes["MacaoBannerSection_Banners_Desktop_createdAt_operator"];
	["MacaoBannerSection_Banners_Desktop_url_operator"]: ValueTypes["MacaoBannerSection_Banners_Desktop_url_operator"];
	["MacaoBannerSection_Banners_Desktop_filename_operator"]: ValueTypes["MacaoBannerSection_Banners_Desktop_filename_operator"];
	["MacaoBannerSection_Banners_Desktop_mimeType_operator"]: ValueTypes["MacaoBannerSection_Banners_Desktop_mimeType_operator"];
	["MacaoBannerSection_Banners_Desktop_filesize_operator"]: ValueTypes["MacaoBannerSection_Banners_Desktop_filesize_operator"];
	["MacaoBannerSection_Banners_Desktop_width_operator"]: ValueTypes["MacaoBannerSection_Banners_Desktop_width_operator"];
	["MacaoBannerSection_Banners_Desktop_height_operator"]: ValueTypes["MacaoBannerSection_Banners_Desktop_height_operator"];
	["MacaoBannerSection_Banners_Desktop_focalX_operator"]: ValueTypes["MacaoBannerSection_Banners_Desktop_focalX_operator"];
	["MacaoBannerSection_Banners_Desktop_focalY_operator"]: ValueTypes["MacaoBannerSection_Banners_Desktop_focalY_operator"];
	["MacaoBannerSection_Banners_Desktop_sizes__small__url_operator"]: ValueTypes["MacaoBannerSection_Banners_Desktop_sizes__small__url_operator"];
	["MacaoBannerSection_Banners_Desktop_sizes__small__width_operator"]: ValueTypes["MacaoBannerSection_Banners_Desktop_sizes__small__width_operator"];
	["MacaoBannerSection_Banners_Desktop_sizes__small__height_operator"]: ValueTypes["MacaoBannerSection_Banners_Desktop_sizes__small__height_operator"];
	["MacaoBannerSection_Banners_Desktop_sizes__small__mimeType_operator"]: ValueTypes["MacaoBannerSection_Banners_Desktop_sizes__small__mimeType_operator"];
	["MacaoBannerSection_Banners_Desktop_sizes__small__filesize_operator"]: ValueTypes["MacaoBannerSection_Banners_Desktop_sizes__small__filesize_operator"];
	["MacaoBannerSection_Banners_Desktop_sizes__small__filename_operator"]: ValueTypes["MacaoBannerSection_Banners_Desktop_sizes__small__filename_operator"];
	["MacaoBannerSection_Banners_Desktop_sizes__medium__url_operator"]: ValueTypes["MacaoBannerSection_Banners_Desktop_sizes__medium__url_operator"];
	["MacaoBannerSection_Banners_Desktop_sizes__medium__width_operator"]: ValueTypes["MacaoBannerSection_Banners_Desktop_sizes__medium__width_operator"];
	["MacaoBannerSection_Banners_Desktop_sizes__medium__height_operator"]: ValueTypes["MacaoBannerSection_Banners_Desktop_sizes__medium__height_operator"];
	["MacaoBannerSection_Banners_Desktop_sizes__medium__mimeType_operator"]: ValueTypes["MacaoBannerSection_Banners_Desktop_sizes__medium__mimeType_operator"];
	["MacaoBannerSection_Banners_Desktop_sizes__medium__filesize_operator"]: ValueTypes["MacaoBannerSection_Banners_Desktop_sizes__medium__filesize_operator"];
	["MacaoBannerSection_Banners_Desktop_sizes__medium__filename_operator"]: ValueTypes["MacaoBannerSection_Banners_Desktop_sizes__medium__filename_operator"];
	["MacaoBannerSection_Banners_Desktop_sizes__large__url_operator"]: ValueTypes["MacaoBannerSection_Banners_Desktop_sizes__large__url_operator"];
	["MacaoBannerSection_Banners_Desktop_sizes__large__width_operator"]: ValueTypes["MacaoBannerSection_Banners_Desktop_sizes__large__width_operator"];
	["MacaoBannerSection_Banners_Desktop_sizes__large__height_operator"]: ValueTypes["MacaoBannerSection_Banners_Desktop_sizes__large__height_operator"];
	["MacaoBannerSection_Banners_Desktop_sizes__large__mimeType_operator"]: ValueTypes["MacaoBannerSection_Banners_Desktop_sizes__large__mimeType_operator"];
	["MacaoBannerSection_Banners_Desktop_sizes__large__filesize_operator"]: ValueTypes["MacaoBannerSection_Banners_Desktop_sizes__large__filesize_operator"];
	["MacaoBannerSection_Banners_Desktop_sizes__large__filename_operator"]: ValueTypes["MacaoBannerSection_Banners_Desktop_sizes__large__filename_operator"];
	["MacaoBannerSection_Banners_Desktop_sizes__small_jpeg__url_operator"]: ValueTypes["MacaoBannerSection_Banners_Desktop_sizes__small_jpeg__url_operator"];
	["MacaoBannerSection_Banners_Desktop_sizes__small_jpeg__width_operator"]: ValueTypes["MacaoBannerSection_Banners_Desktop_sizes__small_jpeg__width_operator"];
	["MacaoBannerSection_Banners_Desktop_sizes__small_jpeg__height_operator"]: ValueTypes["MacaoBannerSection_Banners_Desktop_sizes__small_jpeg__height_operator"];
	["MacaoBannerSection_Banners_Desktop_sizes__small_jpeg__mimeType_operator"]: ValueTypes["MacaoBannerSection_Banners_Desktop_sizes__small_jpeg__mimeType_operator"];
	["MacaoBannerSection_Banners_Desktop_sizes__small_jpeg__filesize_operator"]: ValueTypes["MacaoBannerSection_Banners_Desktop_sizes__small_jpeg__filesize_operator"];
	["MacaoBannerSection_Banners_Desktop_sizes__small_jpeg__filename_operator"]: ValueTypes["MacaoBannerSection_Banners_Desktop_sizes__small_jpeg__filename_operator"];
	["MacaoBannerSection_Banners_Desktop_sizes__medium_jpeg__url_operator"]: ValueTypes["MacaoBannerSection_Banners_Desktop_sizes__medium_jpeg__url_operator"];
	["MacaoBannerSection_Banners_Desktop_sizes__medium_jpeg__width_operator"]: ValueTypes["MacaoBannerSection_Banners_Desktop_sizes__medium_jpeg__width_operator"];
	["MacaoBannerSection_Banners_Desktop_sizes__medium_jpeg__height_operator"]: ValueTypes["MacaoBannerSection_Banners_Desktop_sizes__medium_jpeg__height_operator"];
	["MacaoBannerSection_Banners_Desktop_sizes__medium_jpeg__mimeType_operator"]: ValueTypes["MacaoBannerSection_Banners_Desktop_sizes__medium_jpeg__mimeType_operator"];
	["MacaoBannerSection_Banners_Desktop_sizes__medium_jpeg__filesize_operator"]: ValueTypes["MacaoBannerSection_Banners_Desktop_sizes__medium_jpeg__filesize_operator"];
	["MacaoBannerSection_Banners_Desktop_sizes__medium_jpeg__filename_operator"]: ValueTypes["MacaoBannerSection_Banners_Desktop_sizes__medium_jpeg__filename_operator"];
	["MacaoBannerSection_Banners_Desktop_sizes__large_jpeg__url_operator"]: ValueTypes["MacaoBannerSection_Banners_Desktop_sizes__large_jpeg__url_operator"];
	["MacaoBannerSection_Banners_Desktop_sizes__large_jpeg__width_operator"]: ValueTypes["MacaoBannerSection_Banners_Desktop_sizes__large_jpeg__width_operator"];
	["MacaoBannerSection_Banners_Desktop_sizes__large_jpeg__height_operator"]: ValueTypes["MacaoBannerSection_Banners_Desktop_sizes__large_jpeg__height_operator"];
	["MacaoBannerSection_Banners_Desktop_sizes__large_jpeg__mimeType_operator"]: ValueTypes["MacaoBannerSection_Banners_Desktop_sizes__large_jpeg__mimeType_operator"];
	["MacaoBannerSection_Banners_Desktop_sizes__large_jpeg__filesize_operator"]: ValueTypes["MacaoBannerSection_Banners_Desktop_sizes__large_jpeg__filesize_operator"];
	["MacaoBannerSection_Banners_Desktop_sizes__large_jpeg__filename_operator"]: ValueTypes["MacaoBannerSection_Banners_Desktop_sizes__large_jpeg__filename_operator"];
	["MacaoBannerSection_Banners_Desktop_id_operator"]: ValueTypes["MacaoBannerSection_Banners_Desktop_id_operator"];
	["MacaoBannerSection_Banners_Desktop_where_and"]: ValueTypes["MacaoBannerSection_Banners_Desktop_where_and"];
	["MacaoBannerSection_Banners_Desktop_where_or"]: ValueTypes["MacaoBannerSection_Banners_Desktop_where_or"];
	["MacaoBannerSection_Banners_region"]: ValueTypes["MacaoBannerSection_Banners_region"];
	["MacaoBannerSection_where"]: ValueTypes["MacaoBannerSection_where"];
	["MacaoBannerSection_name_operator"]: ValueTypes["MacaoBannerSection_name_operator"];
	["MacaoBannerSection_slug_operator"]: ValueTypes["MacaoBannerSection_slug_operator"];
	["MacaoBannerSection_banners__name_operator"]: ValueTypes["MacaoBannerSection_banners__name_operator"];
	["MacaoBannerSection_banners__status_operator"]: ValueTypes["MacaoBannerSection_banners__status_operator"];
	["MacaoBannerSection_banners__desktop_operator"]: ValueTypes["MacaoBannerSection_banners__desktop_operator"];
	["MacaoBannerSection_banners__bannerStartDate_operator"]: ValueTypes["MacaoBannerSection_banners__bannerStartDate_operator"];
	["MacaoBannerSection_banners__bannerEndDate_operator"]: ValueTypes["MacaoBannerSection_banners__bannerEndDate_operator"];
	["MacaoBannerSection_banners__region_operator"]: ValueTypes["MacaoBannerSection_banners__region_operator"];
	["MacaoBannerSection_banners__region_Input"]: ValueTypes["MacaoBannerSection_banners__region_Input"];
	["MacaoBannerSection_banners__id_operator"]: ValueTypes["MacaoBannerSection_banners__id_operator"];
	["MacaoBannerSection_updatedAt_operator"]: ValueTypes["MacaoBannerSection_updatedAt_operator"];
	["MacaoBannerSection_createdAt_operator"]: ValueTypes["MacaoBannerSection_createdAt_operator"];
	["MacaoBannerSection_id_operator"]: ValueTypes["MacaoBannerSection_id_operator"];
	["MacaoBannerSection_where_and"]: ValueTypes["MacaoBannerSection_where_and"];
	["MacaoBannerSection_where_or"]: ValueTypes["MacaoBannerSection_where_or"];
	["Banner_Desktop_where"]: ValueTypes["Banner_Desktop_where"];
	["Banner_Desktop_alt_operator"]: ValueTypes["Banner_Desktop_alt_operator"];
	["Banner_Desktop_updatedAt_operator"]: ValueTypes["Banner_Desktop_updatedAt_operator"];
	["Banner_Desktop_createdAt_operator"]: ValueTypes["Banner_Desktop_createdAt_operator"];
	["Banner_Desktop_url_operator"]: ValueTypes["Banner_Desktop_url_operator"];
	["Banner_Desktop_filename_operator"]: ValueTypes["Banner_Desktop_filename_operator"];
	["Banner_Desktop_mimeType_operator"]: ValueTypes["Banner_Desktop_mimeType_operator"];
	["Banner_Desktop_filesize_operator"]: ValueTypes["Banner_Desktop_filesize_operator"];
	["Banner_Desktop_width_operator"]: ValueTypes["Banner_Desktop_width_operator"];
	["Banner_Desktop_height_operator"]: ValueTypes["Banner_Desktop_height_operator"];
	["Banner_Desktop_focalX_operator"]: ValueTypes["Banner_Desktop_focalX_operator"];
	["Banner_Desktop_focalY_operator"]: ValueTypes["Banner_Desktop_focalY_operator"];
	["Banner_Desktop_sizes__small__url_operator"]: ValueTypes["Banner_Desktop_sizes__small__url_operator"];
	["Banner_Desktop_sizes__small__width_operator"]: ValueTypes["Banner_Desktop_sizes__small__width_operator"];
	["Banner_Desktop_sizes__small__height_operator"]: ValueTypes["Banner_Desktop_sizes__small__height_operator"];
	["Banner_Desktop_sizes__small__mimeType_operator"]: ValueTypes["Banner_Desktop_sizes__small__mimeType_operator"];
	["Banner_Desktop_sizes__small__filesize_operator"]: ValueTypes["Banner_Desktop_sizes__small__filesize_operator"];
	["Banner_Desktop_sizes__small__filename_operator"]: ValueTypes["Banner_Desktop_sizes__small__filename_operator"];
	["Banner_Desktop_sizes__medium__url_operator"]: ValueTypes["Banner_Desktop_sizes__medium__url_operator"];
	["Banner_Desktop_sizes__medium__width_operator"]: ValueTypes["Banner_Desktop_sizes__medium__width_operator"];
	["Banner_Desktop_sizes__medium__height_operator"]: ValueTypes["Banner_Desktop_sizes__medium__height_operator"];
	["Banner_Desktop_sizes__medium__mimeType_operator"]: ValueTypes["Banner_Desktop_sizes__medium__mimeType_operator"];
	["Banner_Desktop_sizes__medium__filesize_operator"]: ValueTypes["Banner_Desktop_sizes__medium__filesize_operator"];
	["Banner_Desktop_sizes__medium__filename_operator"]: ValueTypes["Banner_Desktop_sizes__medium__filename_operator"];
	["Banner_Desktop_sizes__large__url_operator"]: ValueTypes["Banner_Desktop_sizes__large__url_operator"];
	["Banner_Desktop_sizes__large__width_operator"]: ValueTypes["Banner_Desktop_sizes__large__width_operator"];
	["Banner_Desktop_sizes__large__height_operator"]: ValueTypes["Banner_Desktop_sizes__large__height_operator"];
	["Banner_Desktop_sizes__large__mimeType_operator"]: ValueTypes["Banner_Desktop_sizes__large__mimeType_operator"];
	["Banner_Desktop_sizes__large__filesize_operator"]: ValueTypes["Banner_Desktop_sizes__large__filesize_operator"];
	["Banner_Desktop_sizes__large__filename_operator"]: ValueTypes["Banner_Desktop_sizes__large__filename_operator"];
	["Banner_Desktop_sizes__small_jpeg__url_operator"]: ValueTypes["Banner_Desktop_sizes__small_jpeg__url_operator"];
	["Banner_Desktop_sizes__small_jpeg__width_operator"]: ValueTypes["Banner_Desktop_sizes__small_jpeg__width_operator"];
	["Banner_Desktop_sizes__small_jpeg__height_operator"]: ValueTypes["Banner_Desktop_sizes__small_jpeg__height_operator"];
	["Banner_Desktop_sizes__small_jpeg__mimeType_operator"]: ValueTypes["Banner_Desktop_sizes__small_jpeg__mimeType_operator"];
	["Banner_Desktop_sizes__small_jpeg__filesize_operator"]: ValueTypes["Banner_Desktop_sizes__small_jpeg__filesize_operator"];
	["Banner_Desktop_sizes__small_jpeg__filename_operator"]: ValueTypes["Banner_Desktop_sizes__small_jpeg__filename_operator"];
	["Banner_Desktop_sizes__medium_jpeg__url_operator"]: ValueTypes["Banner_Desktop_sizes__medium_jpeg__url_operator"];
	["Banner_Desktop_sizes__medium_jpeg__width_operator"]: ValueTypes["Banner_Desktop_sizes__medium_jpeg__width_operator"];
	["Banner_Desktop_sizes__medium_jpeg__height_operator"]: ValueTypes["Banner_Desktop_sizes__medium_jpeg__height_operator"];
	["Banner_Desktop_sizes__medium_jpeg__mimeType_operator"]: ValueTypes["Banner_Desktop_sizes__medium_jpeg__mimeType_operator"];
	["Banner_Desktop_sizes__medium_jpeg__filesize_operator"]: ValueTypes["Banner_Desktop_sizes__medium_jpeg__filesize_operator"];
	["Banner_Desktop_sizes__medium_jpeg__filename_operator"]: ValueTypes["Banner_Desktop_sizes__medium_jpeg__filename_operator"];
	["Banner_Desktop_sizes__large_jpeg__url_operator"]: ValueTypes["Banner_Desktop_sizes__large_jpeg__url_operator"];
	["Banner_Desktop_sizes__large_jpeg__width_operator"]: ValueTypes["Banner_Desktop_sizes__large_jpeg__width_operator"];
	["Banner_Desktop_sizes__large_jpeg__height_operator"]: ValueTypes["Banner_Desktop_sizes__large_jpeg__height_operator"];
	["Banner_Desktop_sizes__large_jpeg__mimeType_operator"]: ValueTypes["Banner_Desktop_sizes__large_jpeg__mimeType_operator"];
	["Banner_Desktop_sizes__large_jpeg__filesize_operator"]: ValueTypes["Banner_Desktop_sizes__large_jpeg__filesize_operator"];
	["Banner_Desktop_sizes__large_jpeg__filename_operator"]: ValueTypes["Banner_Desktop_sizes__large_jpeg__filename_operator"];
	["Banner_Desktop_id_operator"]: ValueTypes["Banner_Desktop_id_operator"];
	["Banner_Desktop_where_and"]: ValueTypes["Banner_Desktop_where_and"];
	["Banner_Desktop_where_or"]: ValueTypes["Banner_Desktop_where_or"];
	["Banner_Mobile_where"]: ValueTypes["Banner_Mobile_where"];
	["Banner_Mobile_alt_operator"]: ValueTypes["Banner_Mobile_alt_operator"];
	["Banner_Mobile_updatedAt_operator"]: ValueTypes["Banner_Mobile_updatedAt_operator"];
	["Banner_Mobile_createdAt_operator"]: ValueTypes["Banner_Mobile_createdAt_operator"];
	["Banner_Mobile_url_operator"]: ValueTypes["Banner_Mobile_url_operator"];
	["Banner_Mobile_filename_operator"]: ValueTypes["Banner_Mobile_filename_operator"];
	["Banner_Mobile_mimeType_operator"]: ValueTypes["Banner_Mobile_mimeType_operator"];
	["Banner_Mobile_filesize_operator"]: ValueTypes["Banner_Mobile_filesize_operator"];
	["Banner_Mobile_width_operator"]: ValueTypes["Banner_Mobile_width_operator"];
	["Banner_Mobile_height_operator"]: ValueTypes["Banner_Mobile_height_operator"];
	["Banner_Mobile_focalX_operator"]: ValueTypes["Banner_Mobile_focalX_operator"];
	["Banner_Mobile_focalY_operator"]: ValueTypes["Banner_Mobile_focalY_operator"];
	["Banner_Mobile_sizes__small__url_operator"]: ValueTypes["Banner_Mobile_sizes__small__url_operator"];
	["Banner_Mobile_sizes__small__width_operator"]: ValueTypes["Banner_Mobile_sizes__small__width_operator"];
	["Banner_Mobile_sizes__small__height_operator"]: ValueTypes["Banner_Mobile_sizes__small__height_operator"];
	["Banner_Mobile_sizes__small__mimeType_operator"]: ValueTypes["Banner_Mobile_sizes__small__mimeType_operator"];
	["Banner_Mobile_sizes__small__filesize_operator"]: ValueTypes["Banner_Mobile_sizes__small__filesize_operator"];
	["Banner_Mobile_sizes__small__filename_operator"]: ValueTypes["Banner_Mobile_sizes__small__filename_operator"];
	["Banner_Mobile_sizes__medium__url_operator"]: ValueTypes["Banner_Mobile_sizes__medium__url_operator"];
	["Banner_Mobile_sizes__medium__width_operator"]: ValueTypes["Banner_Mobile_sizes__medium__width_operator"];
	["Banner_Mobile_sizes__medium__height_operator"]: ValueTypes["Banner_Mobile_sizes__medium__height_operator"];
	["Banner_Mobile_sizes__medium__mimeType_operator"]: ValueTypes["Banner_Mobile_sizes__medium__mimeType_operator"];
	["Banner_Mobile_sizes__medium__filesize_operator"]: ValueTypes["Banner_Mobile_sizes__medium__filesize_operator"];
	["Banner_Mobile_sizes__medium__filename_operator"]: ValueTypes["Banner_Mobile_sizes__medium__filename_operator"];
	["Banner_Mobile_sizes__large__url_operator"]: ValueTypes["Banner_Mobile_sizes__large__url_operator"];
	["Banner_Mobile_sizes__large__width_operator"]: ValueTypes["Banner_Mobile_sizes__large__width_operator"];
	["Banner_Mobile_sizes__large__height_operator"]: ValueTypes["Banner_Mobile_sizes__large__height_operator"];
	["Banner_Mobile_sizes__large__mimeType_operator"]: ValueTypes["Banner_Mobile_sizes__large__mimeType_operator"];
	["Banner_Mobile_sizes__large__filesize_operator"]: ValueTypes["Banner_Mobile_sizes__large__filesize_operator"];
	["Banner_Mobile_sizes__large__filename_operator"]: ValueTypes["Banner_Mobile_sizes__large__filename_operator"];
	["Banner_Mobile_sizes__small_jpeg__url_operator"]: ValueTypes["Banner_Mobile_sizes__small_jpeg__url_operator"];
	["Banner_Mobile_sizes__small_jpeg__width_operator"]: ValueTypes["Banner_Mobile_sizes__small_jpeg__width_operator"];
	["Banner_Mobile_sizes__small_jpeg__height_operator"]: ValueTypes["Banner_Mobile_sizes__small_jpeg__height_operator"];
	["Banner_Mobile_sizes__small_jpeg__mimeType_operator"]: ValueTypes["Banner_Mobile_sizes__small_jpeg__mimeType_operator"];
	["Banner_Mobile_sizes__small_jpeg__filesize_operator"]: ValueTypes["Banner_Mobile_sizes__small_jpeg__filesize_operator"];
	["Banner_Mobile_sizes__small_jpeg__filename_operator"]: ValueTypes["Banner_Mobile_sizes__small_jpeg__filename_operator"];
	["Banner_Mobile_sizes__medium_jpeg__url_operator"]: ValueTypes["Banner_Mobile_sizes__medium_jpeg__url_operator"];
	["Banner_Mobile_sizes__medium_jpeg__width_operator"]: ValueTypes["Banner_Mobile_sizes__medium_jpeg__width_operator"];
	["Banner_Mobile_sizes__medium_jpeg__height_operator"]: ValueTypes["Banner_Mobile_sizes__medium_jpeg__height_operator"];
	["Banner_Mobile_sizes__medium_jpeg__mimeType_operator"]: ValueTypes["Banner_Mobile_sizes__medium_jpeg__mimeType_operator"];
	["Banner_Mobile_sizes__medium_jpeg__filesize_operator"]: ValueTypes["Banner_Mobile_sizes__medium_jpeg__filesize_operator"];
	["Banner_Mobile_sizes__medium_jpeg__filename_operator"]: ValueTypes["Banner_Mobile_sizes__medium_jpeg__filename_operator"];
	["Banner_Mobile_sizes__large_jpeg__url_operator"]: ValueTypes["Banner_Mobile_sizes__large_jpeg__url_operator"];
	["Banner_Mobile_sizes__large_jpeg__width_operator"]: ValueTypes["Banner_Mobile_sizes__large_jpeg__width_operator"];
	["Banner_Mobile_sizes__large_jpeg__height_operator"]: ValueTypes["Banner_Mobile_sizes__large_jpeg__height_operator"];
	["Banner_Mobile_sizes__large_jpeg__mimeType_operator"]: ValueTypes["Banner_Mobile_sizes__large_jpeg__mimeType_operator"];
	["Banner_Mobile_sizes__large_jpeg__filesize_operator"]: ValueTypes["Banner_Mobile_sizes__large_jpeg__filesize_operator"];
	["Banner_Mobile_sizes__large_jpeg__filename_operator"]: ValueTypes["Banner_Mobile_sizes__large_jpeg__filename_operator"];
	["Banner_Mobile_id_operator"]: ValueTypes["Banner_Mobile_id_operator"];
	["Banner_Mobile_where_and"]: ValueTypes["Banner_Mobile_where_and"];
	["Banner_Mobile_where_or"]: ValueTypes["Banner_Mobile_where_or"];
	["Banner_Location_page"]: ValueTypes["Banner_Location_page"];
	["Banner_type"]: ValueTypes["Banner_type"];
	["Banner_status"]: ValueTypes["Banner_status"];
	["Campaign_Creator_RelationTo"]: ValueTypes["Campaign_Creator_RelationTo"];
	["Banner_region"]: ValueTypes["Banner_region"];
	["Banner_where"]: ValueTypes["Banner_where"];
	["Banner_name_operator"]: ValueTypes["Banner_name_operator"];
	["Banner_desktop_operator"]: ValueTypes["Banner_desktop_operator"];
	["Banner_mobile_operator"]: ValueTypes["Banner_mobile_operator"];
	["Banner_bannerStartDate_operator"]: ValueTypes["Banner_bannerStartDate_operator"];
	["Banner_bannerEndDate_operator"]: ValueTypes["Banner_bannerEndDate_operator"];
	["Banner_location__page_operator"]: ValueTypes["Banner_location__page_operator"];
	["Banner_location__page_Input"]: ValueTypes["Banner_location__page_Input"];
	["Banner_location__eventRankingList_operator"]: ValueTypes["Banner_location__eventRankingList_operator"];
	["Banner_location__locationRankingList_operator"]: ValueTypes["Banner_location__locationRankingList_operator"];
	["Banner_location__categories_operator"]: ValueTypes["Banner_location__categories_operator"];
	["Banner_location__time_operator"]: ValueTypes["Banner_location__time_operator"];
	["Banner_location__district_operator"]: ValueTypes["Banner_location__district_operator"];
	["Banner_location__audience_operator"]: ValueTypes["Banner_location__audience_operator"];
	["Banner_location__multipleCriteriaSearch_operator"]: ValueTypes["Banner_location__multipleCriteriaSearch_operator"];
	["Banner_link_operator"]: ValueTypes["Banner_link_operator"];
	["Banner_external_operator"]: ValueTypes["Banner_external_operator"];
	["Banner_type_operator"]: ValueTypes["Banner_type_operator"];
	["Banner_type_Input"]: ValueTypes["Banner_type_Input"];
	["Banner_status_operator"]: ValueTypes["Banner_status_operator"];
	["Banner_status_Input"]: ValueTypes["Banner_status_Input"];
	["Banner_advertise__campaign_operator"]: ValueTypes["Banner_advertise__campaign_operator"];
	["Banner_advertise__invoiceDescription_operator"]: ValueTypes["Banner_advertise__invoiceDescription_operator"];
	["Banner_advertise__standardPrice_operator"]: ValueTypes["Banner_advertise__standardPrice_operator"];
	["Banner_advertise__discount_operator"]: ValueTypes["Banner_advertise__discount_operator"];
	["Banner_advertise__timableCost_operator"]: ValueTypes["Banner_advertise__timableCost_operator"];
	["Banner_advertise__remark_operator"]: ValueTypes["Banner_advertise__remark_operator"];
	["Banner_advertise__public_operator"]: ValueTypes["Banner_advertise__public_operator"];
	["Banner__locations_operator"]: ValueTypes["Banner__locations_operator"];
	["Banner_region_operator"]: ValueTypes["Banner_region_operator"];
	["Banner_region_Input"]: ValueTypes["Banner_region_Input"];
	["Banner_homePageClick_operator"]: ValueTypes["Banner_homePageClick_operator"];
	["Banner_homePageImpression_operator"]: ValueTypes["Banner_homePageImpression_operator"];
	["Banner_latestEventClick_operator"]: ValueTypes["Banner_latestEventClick_operator"];
	["Banner_latestEventImpression_operator"]: ValueTypes["Banner_latestEventImpression_operator"];
	["Banner_editorChoiceClick_operator"]: ValueTypes["Banner_editorChoiceClick_operator"];
	["Banner_editorChoiceImpression_operator"]: ValueTypes["Banner_editorChoiceImpression_operator"];
	["Banner_eventListClick_operator"]: ValueTypes["Banner_eventListClick_operator"];
	["Banner_eventListImpression_operator"]: ValueTypes["Banner_eventListImpression_operator"];
	["Banner_topTenClick_operator"]: ValueTypes["Banner_topTenClick_operator"];
	["Banner_topTenImpression_operator"]: ValueTypes["Banner_topTenImpression_operator"];
	["Banner_miscClick_operator"]: ValueTypes["Banner_miscClick_operator"];
	["Banner_miscImpression_operator"]: ValueTypes["Banner_miscImpression_operator"];
	["Banner_clickTotal_operator"]: ValueTypes["Banner_clickTotal_operator"];
	["Banner_totalImpression_operator"]: ValueTypes["Banner_totalImpression_operator"];
	["Banner_updatedAt_operator"]: ValueTypes["Banner_updatedAt_operator"];
	["Banner_createdAt_operator"]: ValueTypes["Banner_createdAt_operator"];
	["Banner_id_operator"]: ValueTypes["Banner_id_operator"];
	["Banner_where_and"]: ValueTypes["Banner_where_and"];
	["Banner_where_or"]: ValueTypes["Banner_where_or"];
	["Campaign_where"]: ValueTypes["Campaign_where"];
	["Campaign_creator_Relation"]: ValueTypes["Campaign_creator_Relation"];
	["Campaign_creator_Relation_RelationTo"]: ValueTypes["Campaign_creator_Relation_RelationTo"];
	["Campaign_invoiceNumber_operator"]: ValueTypes["Campaign_invoiceNumber_operator"];
	["Campaign_quotationNumber_operator"]: ValueTypes["Campaign_quotationNumber_operator"];
	["Campaign_campaignName_operator"]: ValueTypes["Campaign_campaignName_operator"];
	["Campaign_client_operator"]: ValueTypes["Campaign_client_operator"];
	["Campaign_contactPerson_operator"]: ValueTypes["Campaign_contactPerson_operator"];
	["Campaign_email_operator"]: ValueTypes["Campaign_email_operator"];
	["Campaign_phone_operator"]: ValueTypes["Campaign_phone_operator"];
	["Campaign_agentName_operator"]: ValueTypes["Campaign_agentName_operator"];
	["Campaign_agentCommission_operator"]: ValueTypes["Campaign_agentCommission_operator"];
	["Campaign_agentOrderNumber_operator"]: ValueTypes["Campaign_agentOrderNumber_operator"];
	["Campaign__firstPublishedTime_operator"]: ValueTypes["Campaign__firstPublishedTime_operator"];
	["Campaign_updatedAt_operator"]: ValueTypes["Campaign_updatedAt_operator"];
	["Campaign_createdAt_operator"]: ValueTypes["Campaign_createdAt_operator"];
	["Campaign_id_operator"]: ValueTypes["Campaign_id_operator"];
	["Campaign_where_and"]: ValueTypes["Campaign_where_and"];
	["Campaign_where_or"]: ValueTypes["Campaign_where_or"];
	["Advertise_Advertise_type"]: ValueTypes["Advertise_Advertise_type"];
	["Advertise_Advertise_displayPosition"]: ValueTypes["Advertise_Advertise_displayPosition"];
	["Advertise_type"]: ValueTypes["Advertise_type"];
	["Advertise_where"]: ValueTypes["Advertise_where"];
	["Advertise_advertise__type_operator"]: ValueTypes["Advertise_advertise__type_operator"];
	["Advertise_advertise__type_Input"]: ValueTypes["Advertise_advertise__type_Input"];
	["Advertise_advertise__event_operator"]: ValueTypes["Advertise_advertise__event_operator"];
	["Advertise_advertise__keyword_operator"]: ValueTypes["Advertise_advertise__keyword_operator"];
	["Advertise_advertise__location_operator"]: ValueTypes["Advertise_advertise__location_operator"];
	["Advertise_advertise__displayPosition_operator"]: ValueTypes["Advertise_advertise__displayPosition_operator"];
	["Advertise_advertise__displayPosition_Input"]: ValueTypes["Advertise_advertise__displayPosition_Input"];
	["Advertise_advertise__rankingListRemark_operator"]: ValueTypes["Advertise_advertise__rankingListRemark_operator"];
	["Advertise_advertiseStartDate_operator"]: ValueTypes["Advertise_advertiseStartDate_operator"];
	["Advertise_advertiseEndDate_operator"]: ValueTypes["Advertise_advertiseEndDate_operator"];
	["Advertise_type_operator"]: ValueTypes["Advertise_type_operator"];
	["Advertise_type_Input"]: ValueTypes["Advertise_type_Input"];
	["Advertise_invoiceDescription_operator"]: ValueTypes["Advertise_invoiceDescription_operator"];
	["Advertise_standardPrice_operator"]: ValueTypes["Advertise_standardPrice_operator"];
	["Advertise_discount_operator"]: ValueTypes["Advertise_discount_operator"];
	["Advertise_discountPercentage_operator"]: ValueTypes["Advertise_discountPercentage_operator"];
	["Advertise__calculateByDiscount_operator"]: ValueTypes["Advertise__calculateByDiscount_operator"];
	["Advertise_timableCost_operator"]: ValueTypes["Advertise_timableCost_operator"];
	["Advertise_remark_operator"]: ValueTypes["Advertise_remark_operator"];
	["Advertise_public_operator"]: ValueTypes["Advertise_public_operator"];
	["Advertise_campaignName_operator"]: ValueTypes["Advertise_campaignName_operator"];
	["Advertise_campaignId_operator"]: ValueTypes["Advertise_campaignId_operator"];
	["Advertise_status_operator"]: ValueTypes["Advertise_status_operator"];
	["Advertise_updatedAt_operator"]: ValueTypes["Advertise_updatedAt_operator"];
	["Advertise_createdAt_operator"]: ValueTypes["Advertise_createdAt_operator"];
	["Advertise_id_operator"]: ValueTypes["Advertise_id_operator"];
	["Advertise_where_and"]: ValueTypes["Advertise_where_and"];
	["Advertise_where_or"]: ValueTypes["Advertise_where_or"];
	["Static_region"]: ValueTypes["Static_region"];
	["Static_where"]: ValueTypes["Static_where"];
	["Static_name_operator"]: ValueTypes["Static_name_operator"];
	["Static_key_operator"]: ValueTypes["Static_key_operator"];
	["Static_region_operator"]: ValueTypes["Static_region_operator"];
	["Static_region_Input"]: ValueTypes["Static_region_Input"];
	["Static_updatedAt_operator"]: ValueTypes["Static_updatedAt_operator"];
	["Static_createdAt_operator"]: ValueTypes["Static_createdAt_operator"];
	["Static_id_operator"]: ValueTypes["Static_id_operator"];
	["Static_where_and"]: ValueTypes["Static_where_and"];
	["Static_where_or"]: ValueTypes["Static_where_or"];
	["EventView_where"]: ValueTypes["EventView_where"];
	["EventView_date_operator"]: ValueTypes["EventView_date_operator"];
	["EventView_eventId_operator"]: ValueTypes["EventView_eventId_operator"];
	["EventView_viewCount_operator"]: ValueTypes["EventView_viewCount_operator"];
	["EventView_updatedAt_operator"]: ValueTypes["EventView_updatedAt_operator"];
	["EventView_createdAt_operator"]: ValueTypes["EventView_createdAt_operator"];
	["EventView_id_operator"]: ValueTypes["EventView_id_operator"];
	["EventView_where_and"]: ValueTypes["EventView_where_and"];
	["EventView_where_or"]: ValueTypes["EventView_where_or"];
	["PostView_where"]: ValueTypes["PostView_where"];
	["PostView_date_operator"]: ValueTypes["PostView_date_operator"];
	["PostView_postId_operator"]: ValueTypes["PostView_postId_operator"];
	["PostView_viewCount_operator"]: ValueTypes["PostView_viewCount_operator"];
	["PostView_updatedAt_operator"]: ValueTypes["PostView_updatedAt_operator"];
	["PostView_createdAt_operator"]: ValueTypes["PostView_createdAt_operator"];
	["PostView_id_operator"]: ValueTypes["PostView_id_operator"];
	["PostView_where_and"]: ValueTypes["PostView_where_and"];
	["PostView_where_or"]: ValueTypes["PostView_where_or"];
	["PayloadPreference_User_RelationTo"]: ValueTypes["PayloadPreference_User_RelationTo"];
	["PayloadPreference_where"]: ValueTypes["PayloadPreference_where"];
	["PayloadPreference_user_Relation"]: ValueTypes["PayloadPreference_user_Relation"];
	["PayloadPreference_user_Relation_RelationTo"]: ValueTypes["PayloadPreference_user_Relation_RelationTo"];
	["PayloadPreference_key_operator"]: ValueTypes["PayloadPreference_key_operator"];
	["PayloadPreference_value_operator"]: ValueTypes["PayloadPreference_value_operator"];
	["PayloadPreference_updatedAt_operator"]: ValueTypes["PayloadPreference_updatedAt_operator"];
	["PayloadPreference_createdAt_operator"]: ValueTypes["PayloadPreference_createdAt_operator"];
	["PayloadPreference_id_operator"]: ValueTypes["PayloadPreference_id_operator"];
	["PayloadPreference_where_and"]: ValueTypes["PayloadPreference_where_and"];
	["PayloadPreference_where_or"]: ValueTypes["PayloadPreference_where_or"];
	["ProductWhereInput"]: ValueTypes["ProductWhereInput"];
	["ProductDetailOperator"]: ValueTypes["ProductDetailOperator"];
	["TicketingWhereInput"]: ValueTypes["TicketingWhereInput"];
	["TicketingDetailOperator"]: ValueTypes["TicketingDetailOperator"];
	["RedemptionCodeWhereInput"]: ValueTypes["RedemptionCodeWhereInput"];
	["RedemptionCodeDetailOperator"]: ValueTypes["RedemptionCodeDetailOperator"];
	["mutationEventInput"]: ValueTypes["mutationEventInput"];
	["Event_region_MutationInput"]: ValueTypes["Event_region_MutationInput"];
	["Event_level_MutationInput"]: ValueTypes["Event_level_MutationInput"];
	["mutationEvent_SectionsInput"]: ValueTypes["mutationEvent_SectionsInput"];
	["mutationEvent_Sections_RecurranceInput"]: ValueTypes["mutationEvent_Sections_RecurranceInput"];
	["Event_Sections_Recurrance_type_MutationInput"]: ValueTypes["Event_Sections_Recurrance_type_MutationInput"];
	["Event_Sections_Recurrance_weekday_MutationInput"]: ValueTypes["Event_Sections_Recurrance_weekday_MutationInput"];
	["mutationEvent_TicketingInput"]: ValueTypes["mutationEvent_TicketingInput"];
	["Event_Ticketing_type_MutationInput"]: ValueTypes["Event_Ticketing_type_MutationInput"];
	["Event_Ticketing_ticketingType_MutationInput"]: ValueTypes["Event_Ticketing_ticketingType_MutationInput"];
	["Event_Ticketing_promotionType_MutationInput"]: ValueTypes["Event_Ticketing_promotionType_MutationInput"];
	["mutationEvent_CriteriaInput"]: ValueTypes["mutationEvent_CriteriaInput"];
	["mutationEvent_ContactInput"]: ValueTypes["mutationEvent_ContactInput"];
	["mutationEvent_FeesInput"]: ValueTypes["mutationEvent_FeesInput"];
	["mutationEvent_ProductsInput"]: ValueTypes["mutationEvent_ProductsInput"];
	["mutationEventUpdateInput"]: ValueTypes["mutationEventUpdateInput"];
	["EventUpdate_region_MutationInput"]: ValueTypes["EventUpdate_region_MutationInput"];
	["EventUpdate_level_MutationInput"]: ValueTypes["EventUpdate_level_MutationInput"];
	["mutationEventUpdate_SectionsInput"]: ValueTypes["mutationEventUpdate_SectionsInput"];
	["mutationEventUpdate_Sections_RecurranceInput"]: ValueTypes["mutationEventUpdate_Sections_RecurranceInput"];
	["EventUpdate_Sections_Recurrance_type_MutationInput"]: ValueTypes["EventUpdate_Sections_Recurrance_type_MutationInput"];
	["EventUpdate_Sections_Recurrance_weekday_MutationInput"]: ValueTypes["EventUpdate_Sections_Recurrance_weekday_MutationInput"];
	["mutationEventUpdate_TicketingInput"]: ValueTypes["mutationEventUpdate_TicketingInput"];
	["EventUpdate_Ticketing_type_MutationInput"]: ValueTypes["EventUpdate_Ticketing_type_MutationInput"];
	["EventUpdate_Ticketing_ticketingType_MutationInput"]: ValueTypes["EventUpdate_Ticketing_ticketingType_MutationInput"];
	["EventUpdate_Ticketing_promotionType_MutationInput"]: ValueTypes["EventUpdate_Ticketing_promotionType_MutationInput"];
	["mutationEventUpdate_CriteriaInput"]: ValueTypes["mutationEventUpdate_CriteriaInput"];
	["mutationEventUpdate_ContactInput"]: ValueTypes["mutationEventUpdate_ContactInput"];
	["mutationEventUpdate_FeesInput"]: ValueTypes["mutationEventUpdate_FeesInput"];
	["mutationEventUpdate_ProductsInput"]: ValueTypes["mutationEventUpdate_ProductsInput"];
	["mutationLocationInput"]: ValueTypes["mutationLocationInput"];
	["Location_region_MutationInput"]: ValueTypes["Location_region_MutationInput"];
	["Location_level_MutationInput"]: ValueTypes["Location_level_MutationInput"];
	["Location_type_MutationInput"]: ValueTypes["Location_type_MutationInput"];
	["Location_category_MutationInput"]: ValueTypes["Location_category_MutationInput"];
	["mutationLocation_DetailsInput"]: ValueTypes["mutationLocation_DetailsInput"];
	["mutationLocation_CriteriaInput"]: ValueTypes["mutationLocation_CriteriaInput"];
	["mutationLocation_SectionsInput"]: ValueTypes["mutationLocation_SectionsInput"];
	["mutationLocation_Sections_RecurranceInput"]: ValueTypes["mutationLocation_Sections_RecurranceInput"];
	["Location_Sections_Recurrance_type_MutationInput"]: ValueTypes["Location_Sections_Recurrance_type_MutationInput"];
	["Location_Sections_Recurrance_weekday_MutationInput"]: ValueTypes["Location_Sections_Recurrance_weekday_MutationInput"];
	["mutationLocationUpdateInput"]: ValueTypes["mutationLocationUpdateInput"];
	["LocationUpdate_region_MutationInput"]: ValueTypes["LocationUpdate_region_MutationInput"];
	["LocationUpdate_level_MutationInput"]: ValueTypes["LocationUpdate_level_MutationInput"];
	["LocationUpdate_type_MutationInput"]: ValueTypes["LocationUpdate_type_MutationInput"];
	["LocationUpdate_category_MutationInput"]: ValueTypes["LocationUpdate_category_MutationInput"];
	["mutationLocationUpdate_DetailsInput"]: ValueTypes["mutationLocationUpdate_DetailsInput"];
	["mutationLocationUpdate_CriteriaInput"]: ValueTypes["mutationLocationUpdate_CriteriaInput"];
	["mutationLocationUpdate_SectionsInput"]: ValueTypes["mutationLocationUpdate_SectionsInput"];
	["mutationLocationUpdate_Sections_RecurranceInput"]: ValueTypes["mutationLocationUpdate_Sections_RecurranceInput"];
	["LocationUpdate_Sections_Recurrance_type_MutationInput"]: ValueTypes["LocationUpdate_Sections_Recurrance_type_MutationInput"];
	["LocationUpdate_Sections_Recurrance_weekday_MutationInput"]: ValueTypes["LocationUpdate_Sections_Recurrance_weekday_MutationInput"];
	["mutationPostInput"]: ValueTypes["mutationPostInput"];
	["Post_region_MutationInput"]: ValueTypes["Post_region_MutationInput"];
	["mutationPost_CriteriaInput"]: ValueTypes["mutationPost_CriteriaInput"];
	["mutationPostUpdateInput"]: ValueTypes["mutationPostUpdateInput"];
	["PostUpdate_region_MutationInput"]: ValueTypes["PostUpdate_region_MutationInput"];
	["mutationPostUpdate_CriteriaInput"]: ValueTypes["mutationPostUpdate_CriteriaInput"];
	["mutationTicketingInput"]: ValueTypes["mutationTicketingInput"];
	["Ticketing_status_MutationInput"]: ValueTypes["Ticketing_status_MutationInput"];
	["Ticketing_CheckInLevel_MutationInput"]: ValueTypes["Ticketing_CheckInLevel_MutationInput"];
	["mutationTicketing_SessionsInput"]: ValueTypes["mutationTicketing_SessionsInput"];
	["mutationTicketing_Sessions_InventoriesInput"]: ValueTypes["mutationTicketing_Sessions_InventoriesInput"];
	["mutationTicketing_Sessions_Inventories_StocksInput"]: ValueTypes["mutationTicketing_Sessions_Inventories_StocksInput"];
	["mutationTicketingUpdateInput"]: ValueTypes["mutationTicketingUpdateInput"];
	["TicketingUpdate_status_MutationInput"]: ValueTypes["TicketingUpdate_status_MutationInput"];
	["TicketingUpdate_CheckInLevel_MutationInput"]: ValueTypes["TicketingUpdate_CheckInLevel_MutationInput"];
	["mutationTicketingUpdate_SessionsInput"]: ValueTypes["mutationTicketingUpdate_SessionsInput"];
	["mutationTicketingUpdate_Sessions_InventoriesInput"]: ValueTypes["mutationTicketingUpdate_Sessions_InventoriesInput"];
	["mutationTicketingUpdate_Sessions_Inventories_StocksInput"]: ValueTypes["mutationTicketingUpdate_Sessions_Inventories_StocksInput"];
	["mutationProductInput"]: ValueTypes["mutationProductInput"];
	["Product_status_MutationInput"]: ValueTypes["Product_status_MutationInput"];
	["Product_CheckInLevel_MutationInput"]: ValueTypes["Product_CheckInLevel_MutationInput"];
	["mutationProduct_SessionsInput"]: ValueTypes["mutationProduct_SessionsInput"];
	["mutationProduct_Sessions_InventoriesInput"]: ValueTypes["mutationProduct_Sessions_InventoriesInput"];
	["mutationProduct_Sessions_Inventories_StocksInput"]: ValueTypes["mutationProduct_Sessions_Inventories_StocksInput"];
	["mutationProductUpdateInput"]: ValueTypes["mutationProductUpdateInput"];
	["ProductUpdate_status_MutationInput"]: ValueTypes["ProductUpdate_status_MutationInput"];
	["ProductUpdate_CheckInLevel_MutationInput"]: ValueTypes["ProductUpdate_CheckInLevel_MutationInput"];
	["mutationProductUpdate_SessionsInput"]: ValueTypes["mutationProductUpdate_SessionsInput"];
	["mutationProductUpdate_Sessions_InventoriesInput"]: ValueTypes["mutationProductUpdate_Sessions_InventoriesInput"];
	["mutationProductUpdate_Sessions_Inventories_StocksInput"]: ValueTypes["mutationProductUpdate_Sessions_Inventories_StocksInput"];
	["mutationInventoryStatusInput"]: ValueTypes["mutationInventoryStatusInput"];
	["InventoryStatus_stockUpdateStatus_MutationInput"]: ValueTypes["InventoryStatus_stockUpdateStatus_MutationInput"];
	["mutationInventoryStatusUpdateInput"]: ValueTypes["mutationInventoryStatusUpdateInput"];
	["InventoryStatusUpdate_stockUpdateStatus_MutationInput"]: ValueTypes["InventoryStatusUpdate_stockUpdateStatus_MutationInput"];
	["mutationTransactionInput"]: ValueTypes["mutationTransactionInput"];
	["mutationTransaction_RedemptionCodesInput"]: ValueTypes["mutationTransaction_RedemptionCodesInput"];
	["mutationTransactionUpdateInput"]: ValueTypes["mutationTransactionUpdateInput"];
	["mutationTransactionUpdate_RedemptionCodesInput"]: ValueTypes["mutationTransactionUpdate_RedemptionCodesInput"];
	["mutationRedemptionCodeInput"]: ValueTypes["mutationRedemptionCodeInput"];
	["RedemptionCode_status_MutationInput"]: ValueTypes["RedemptionCode_status_MutationInput"];
	["mutationRedemptionCode_VariantsInput"]: ValueTypes["mutationRedemptionCode_VariantsInput"];
	["mutationRedemptionCode_ChangeHistoryInput"]: ValueTypes["mutationRedemptionCode_ChangeHistoryInput"];
	["mutationRedemptionCodeUpdateInput"]: ValueTypes["mutationRedemptionCodeUpdateInput"];
	["RedemptionCodeUpdate_status_MutationInput"]: ValueTypes["RedemptionCodeUpdate_status_MutationInput"];
	["mutationRedemptionCodeUpdate_VariantsInput"]: ValueTypes["mutationRedemptionCodeUpdate_VariantsInput"];
	["mutationRedemptionCodeUpdate_ChangeHistoryInput"]: ValueTypes["mutationRedemptionCodeUpdate_ChangeHistoryInput"];
	["mutationRedemptionPdfInput"]: ValueTypes["mutationRedemptionPdfInput"];
	["mutationRedemptionPdfUpdateInput"]: ValueTypes["mutationRedemptionPdfUpdateInput"];
	["mutationCheckInRedemptionInput"]: ValueTypes["mutationCheckInRedemptionInput"];
	["mutationCheckInRedemption_VariantsInput"]: ValueTypes["mutationCheckInRedemption_VariantsInput"];
	["mutationCheckInRedemptionUpdateInput"]: ValueTypes["mutationCheckInRedemptionUpdateInput"];
	["mutationCheckInRedemptionUpdate_VariantsInput"]: ValueTypes["mutationCheckInRedemptionUpdate_VariantsInput"];
	["mutationUserInput"]: ValueTypes["mutationUserInput"];
	["User_region_MutationInput"]: ValueTypes["User_region_MutationInput"];
	["User_roles_MutationInput"]: ValueTypes["User_roles_MutationInput"];
	["mutationUser_MemberInput"]: ValueTypes["mutationUser_MemberInput"];
	["User_Member_region_MutationInput"]: ValueTypes["User_Member_region_MutationInput"];
	["User_Member_language_MutationInput"]: ValueTypes["User_Member_language_MutationInput"];
	["User_Member_gender_MutationInput"]: ValueTypes["User_Member_gender_MutationInput"];
	["mutationUser_OrganiserInput"]: ValueTypes["mutationUser_OrganiserInput"];
	["User_Organiser_language_MutationInput"]: ValueTypes["User_Organiser_language_MutationInput"];
	["mutationUser_SsoInput"]: ValueTypes["mutationUser_SsoInput"];
	["User_status_MutationInput"]: ValueTypes["User_status_MutationInput"];
	["mutationUserUpdateInput"]: ValueTypes["mutationUserUpdateInput"];
	["UserUpdate_region_MutationInput"]: ValueTypes["UserUpdate_region_MutationInput"];
	["UserUpdate_roles_MutationInput"]: ValueTypes["UserUpdate_roles_MutationInput"];
	["mutationUserUpdate_MemberInput"]: ValueTypes["mutationUserUpdate_MemberInput"];
	["UserUpdate_Member_region_MutationInput"]: ValueTypes["UserUpdate_Member_region_MutationInput"];
	["UserUpdate_Member_language_MutationInput"]: ValueTypes["UserUpdate_Member_language_MutationInput"];
	["UserUpdate_Member_gender_MutationInput"]: ValueTypes["UserUpdate_Member_gender_MutationInput"];
	["mutationUserUpdate_OrganiserInput"]: ValueTypes["mutationUserUpdate_OrganiserInput"];
	["UserUpdate_Organiser_language_MutationInput"]: ValueTypes["UserUpdate_Organiser_language_MutationInput"];
	["mutationUserUpdate_SsoInput"]: ValueTypes["mutationUserUpdate_SsoInput"];
	["UserUpdate_status_MutationInput"]: ValueTypes["UserUpdate_status_MutationInput"];
	["mutationBookmarkInput"]: ValueTypes["mutationBookmarkInput"];
	["Bookmark_UserRelationshipInput"]: ValueTypes["Bookmark_UserRelationshipInput"];
	["Bookmark_UserRelationshipInputRelationTo"]: ValueTypes["Bookmark_UserRelationshipInputRelationTo"];
	["Bookmark_ItemRelationshipInput"]: ValueTypes["Bookmark_ItemRelationshipInput"];
	["Bookmark_ItemRelationshipInputRelationTo"]: ValueTypes["Bookmark_ItemRelationshipInputRelationTo"];
	["mutationBookmarkUpdateInput"]: ValueTypes["mutationBookmarkUpdateInput"];
	["BookmarkUpdate_UserRelationshipInput"]: ValueTypes["BookmarkUpdate_UserRelationshipInput"];
	["BookmarkUpdate_UserRelationshipInputRelationTo"]: ValueTypes["BookmarkUpdate_UserRelationshipInputRelationTo"];
	["BookmarkUpdate_ItemRelationshipInput"]: ValueTypes["BookmarkUpdate_ItemRelationshipInput"];
	["BookmarkUpdate_ItemRelationshipInputRelationTo"]: ValueTypes["BookmarkUpdate_ItemRelationshipInputRelationTo"];
	["mutationWebAppBookmarkInput"]: ValueTypes["mutationWebAppBookmarkInput"];
	["WebAppBookmark_UserRelationshipInput"]: ValueTypes["WebAppBookmark_UserRelationshipInput"];
	["WebAppBookmark_UserRelationshipInputRelationTo"]: ValueTypes["WebAppBookmark_UserRelationshipInputRelationTo"];
	["WebAppBookmark_EventRelationshipInput"]: ValueTypes["WebAppBookmark_EventRelationshipInput"];
	["WebAppBookmark_EventRelationshipInputRelationTo"]: ValueTypes["WebAppBookmark_EventRelationshipInputRelationTo"];
	["WebAppBookmark_productType_MutationInput"]: ValueTypes["WebAppBookmark_productType_MutationInput"];
	["mutationWebAppBookmarkUpdateInput"]: ValueTypes["mutationWebAppBookmarkUpdateInput"];
	["WebAppBookmarkUpdate_UserRelationshipInput"]: ValueTypes["WebAppBookmarkUpdate_UserRelationshipInput"];
	["WebAppBookmarkUpdate_UserRelationshipInputRelationTo"]: ValueTypes["WebAppBookmarkUpdate_UserRelationshipInputRelationTo"];
	["WebAppBookmarkUpdate_EventRelationshipInput"]: ValueTypes["WebAppBookmarkUpdate_EventRelationshipInput"];
	["WebAppBookmarkUpdate_EventRelationshipInputRelationTo"]: ValueTypes["WebAppBookmarkUpdate_EventRelationshipInputRelationTo"];
	["WebAppBookmarkUpdate_productType_MutationInput"]: ValueTypes["WebAppBookmarkUpdate_productType_MutationInput"];
	["mutationViewRecordInput"]: ValueTypes["mutationViewRecordInput"];
	["ViewRecord_UserRelationshipInput"]: ValueTypes["ViewRecord_UserRelationshipInput"];
	["ViewRecord_UserRelationshipInputRelationTo"]: ValueTypes["ViewRecord_UserRelationshipInputRelationTo"];
	["ViewRecord_ItemRelationshipInput"]: ValueTypes["ViewRecord_ItemRelationshipInput"];
	["ViewRecord_ItemRelationshipInputRelationTo"]: ValueTypes["ViewRecord_ItemRelationshipInputRelationTo"];
	["mutationViewRecordUpdateInput"]: ValueTypes["mutationViewRecordUpdateInput"];
	["ViewRecordUpdate_UserRelationshipInput"]: ValueTypes["ViewRecordUpdate_UserRelationshipInput"];
	["ViewRecordUpdate_UserRelationshipInputRelationTo"]: ValueTypes["ViewRecordUpdate_UserRelationshipInputRelationTo"];
	["ViewRecordUpdate_ItemRelationshipInput"]: ValueTypes["ViewRecordUpdate_ItemRelationshipInput"];
	["ViewRecordUpdate_ItemRelationshipInputRelationTo"]: ValueTypes["ViewRecordUpdate_ItemRelationshipInputRelationTo"];
	["mutationCommentInput"]: ValueTypes["mutationCommentInput"];
	["Comment_TargetRelationshipInput"]: ValueTypes["Comment_TargetRelationshipInput"];
	["Comment_TargetRelationshipInputRelationTo"]: ValueTypes["Comment_TargetRelationshipInputRelationTo"];
	["Comment_ParentRelationshipInput"]: ValueTypes["Comment_ParentRelationshipInput"];
	["Comment_ParentRelationshipInputRelationTo"]: ValueTypes["Comment_ParentRelationshipInputRelationTo"];
	["mutationCommentUpdateInput"]: ValueTypes["mutationCommentUpdateInput"];
	["CommentUpdate_TargetRelationshipInput"]: ValueTypes["CommentUpdate_TargetRelationshipInput"];
	["CommentUpdate_TargetRelationshipInputRelationTo"]: ValueTypes["CommentUpdate_TargetRelationshipInputRelationTo"];
	["CommentUpdate_ParentRelationshipInput"]: ValueTypes["CommentUpdate_ParentRelationshipInput"];
	["CommentUpdate_ParentRelationshipInputRelationTo"]: ValueTypes["CommentUpdate_ParentRelationshipInputRelationTo"];
	["mutationSubscriptionInput"]: ValueTypes["mutationSubscriptionInput"];
	["Subscription_UserRelationshipInput"]: ValueTypes["Subscription_UserRelationshipInput"];
	["Subscription_UserRelationshipInputRelationTo"]: ValueTypes["Subscription_UserRelationshipInputRelationTo"];
	["Subscription_OrganiserRelationshipInput"]: ValueTypes["Subscription_OrganiserRelationshipInput"];
	["Subscription_OrganiserRelationshipInputRelationTo"]: ValueTypes["Subscription_OrganiserRelationshipInputRelationTo"];
	["mutationSubscriptionUpdateInput"]: ValueTypes["mutationSubscriptionUpdateInput"];
	["SubscriptionUpdate_UserRelationshipInput"]: ValueTypes["SubscriptionUpdate_UserRelationshipInput"];
	["SubscriptionUpdate_UserRelationshipInputRelationTo"]: ValueTypes["SubscriptionUpdate_UserRelationshipInputRelationTo"];
	["SubscriptionUpdate_OrganiserRelationshipInput"]: ValueTypes["SubscriptionUpdate_OrganiserRelationshipInput"];
	["SubscriptionUpdate_OrganiserRelationshipInputRelationTo"]: ValueTypes["SubscriptionUpdate_OrganiserRelationshipInputRelationTo"];
	["mutationNewsletterInput"]: ValueTypes["mutationNewsletterInput"];
	["Newsletter_region_MutationInput"]: ValueTypes["Newsletter_region_MutationInput"];
	["mutationNewsletterUpdateInput"]: ValueTypes["mutationNewsletterUpdateInput"];
	["NewsletterUpdate_region_MutationInput"]: ValueTypes["NewsletterUpdate_region_MutationInput"];
	["mutationBlogCategoryInput"]: ValueTypes["mutationBlogCategoryInput"];
	["BlogCategory_region_MutationInput"]: ValueTypes["BlogCategory_region_MutationInput"];
	["mutationBlogCategoryUpdateInput"]: ValueTypes["mutationBlogCategoryUpdateInput"];
	["BlogCategoryUpdate_region_MutationInput"]: ValueTypes["BlogCategoryUpdate_region_MutationInput"];
	["mutationTagInput"]: ValueTypes["mutationTagInput"];
	["Tag_region_MutationInput"]: ValueTypes["Tag_region_MutationInput"];
	["mutationTagUpdateInput"]: ValueTypes["mutationTagUpdateInput"];
	["TagUpdate_region_MutationInput"]: ValueTypes["TagUpdate_region_MutationInput"];
	["mutationSpecialTagInput"]: ValueTypes["mutationSpecialTagInput"];
	["SpecialTag_region_MutationInput"]: ValueTypes["SpecialTag_region_MutationInput"];
	["mutationSpecialTagUpdateInput"]: ValueTypes["mutationSpecialTagUpdateInput"];
	["SpecialTagUpdate_region_MutationInput"]: ValueTypes["SpecialTagUpdate_region_MutationInput"];
	["mutationDateInput"]: ValueTypes["mutationDateInput"];
	["Date_type_MutationInput"]: ValueTypes["Date_type_MutationInput"];
	["Date_region_MutationInput"]: ValueTypes["Date_region_MutationInput"];
	["mutationDateUpdateInput"]: ValueTypes["mutationDateUpdateInput"];
	["DateUpdate_type_MutationInput"]: ValueTypes["DateUpdate_type_MutationInput"];
	["DateUpdate_region_MutationInput"]: ValueTypes["DateUpdate_region_MutationInput"];
	["mutationDistrictInput"]: ValueTypes["mutationDistrictInput"];
	["District_region_MutationInput"]: ValueTypes["District_region_MutationInput"];
	["mutationDistrictUpdateInput"]: ValueTypes["mutationDistrictUpdateInput"];
	["DistrictUpdate_region_MutationInput"]: ValueTypes["DistrictUpdate_region_MutationInput"];
	["mutationAudienceInput"]: ValueTypes["mutationAudienceInput"];
	["Audience_region_MutationInput"]: ValueTypes["Audience_region_MutationInput"];
	["mutationAudienceUpdateInput"]: ValueTypes["mutationAudienceUpdateInput"];
	["AudienceUpdate_region_MutationInput"]: ValueTypes["AudienceUpdate_region_MutationInput"];
	["mutationEventCategoryInput"]: ValueTypes["mutationEventCategoryInput"];
	["EventCategory_region_MutationInput"]: ValueTypes["EventCategory_region_MutationInput"];
	["mutationEventCategoryUpdateInput"]: ValueTypes["mutationEventCategoryUpdateInput"];
	["EventCategoryUpdate_region_MutationInput"]: ValueTypes["EventCategoryUpdate_region_MutationInput"];
	["mutationPrivateMediaInput"]: ValueTypes["mutationPrivateMediaInput"];
	["mutationPrivateMedia_SizesInput"]: ValueTypes["mutationPrivateMedia_SizesInput"];
	["mutationPrivateMedia_Sizes_ThumbnailInput"]: ValueTypes["mutationPrivateMedia_Sizes_ThumbnailInput"];
	["mutationPrivateMedia_Sizes_PortraitInput"]: ValueTypes["mutationPrivateMedia_Sizes_PortraitInput"];
	["mutationPrivateMedia_Sizes_HeroInput"]: ValueTypes["mutationPrivateMedia_Sizes_HeroInput"];
	["mutationPrivateMedia_Sizes_Thumbnail_jpegInput"]: ValueTypes["mutationPrivateMedia_Sizes_Thumbnail_jpegInput"];
	["mutationPrivateMedia_Sizes_Portrait_jpegInput"]: ValueTypes["mutationPrivateMedia_Sizes_Portrait_jpegInput"];
	["mutationPrivateMedia_Sizes_Hero_jpegInput"]: ValueTypes["mutationPrivateMedia_Sizes_Hero_jpegInput"];
	["mutationPrivateMediaUpdateInput"]: ValueTypes["mutationPrivateMediaUpdateInput"];
	["mutationPrivateMediaUpdate_SizesInput"]: ValueTypes["mutationPrivateMediaUpdate_SizesInput"];
	["mutationPrivateMediaUpdate_Sizes_ThumbnailInput"]: ValueTypes["mutationPrivateMediaUpdate_Sizes_ThumbnailInput"];
	["mutationPrivateMediaUpdate_Sizes_PortraitInput"]: ValueTypes["mutationPrivateMediaUpdate_Sizes_PortraitInput"];
	["mutationPrivateMediaUpdate_Sizes_HeroInput"]: ValueTypes["mutationPrivateMediaUpdate_Sizes_HeroInput"];
	["mutationPrivateMediaUpdate_Sizes_Thumbnail_jpegInput"]: ValueTypes["mutationPrivateMediaUpdate_Sizes_Thumbnail_jpegInput"];
	["mutationPrivateMediaUpdate_Sizes_Portrait_jpegInput"]: ValueTypes["mutationPrivateMediaUpdate_Sizes_Portrait_jpegInput"];
	["mutationPrivateMediaUpdate_Sizes_Hero_jpegInput"]: ValueTypes["mutationPrivateMediaUpdate_Sizes_Hero_jpegInput"];
	["mutationMediaInput"]: ValueTypes["mutationMediaInput"];
	["mutationMedia_SizesInput"]: ValueTypes["mutationMedia_SizesInput"];
	["mutationMedia_Sizes_SmallInput"]: ValueTypes["mutationMedia_Sizes_SmallInput"];
	["mutationMedia_Sizes_MediumInput"]: ValueTypes["mutationMedia_Sizes_MediumInput"];
	["mutationMedia_Sizes_LargeInput"]: ValueTypes["mutationMedia_Sizes_LargeInput"];
	["mutationMedia_Sizes_Small_jpegInput"]: ValueTypes["mutationMedia_Sizes_Small_jpegInput"];
	["mutationMedia_Sizes_Medium_jpegInput"]: ValueTypes["mutationMedia_Sizes_Medium_jpegInput"];
	["mutationMedia_Sizes_Large_jpegInput"]: ValueTypes["mutationMedia_Sizes_Large_jpegInput"];
	["mutationMediaUpdateInput"]: ValueTypes["mutationMediaUpdateInput"];
	["mutationMediaUpdate_SizesInput"]: ValueTypes["mutationMediaUpdate_SizesInput"];
	["mutationMediaUpdate_Sizes_SmallInput"]: ValueTypes["mutationMediaUpdate_Sizes_SmallInput"];
	["mutationMediaUpdate_Sizes_MediumInput"]: ValueTypes["mutationMediaUpdate_Sizes_MediumInput"];
	["mutationMediaUpdate_Sizes_LargeInput"]: ValueTypes["mutationMediaUpdate_Sizes_LargeInput"];
	["mutationMediaUpdate_Sizes_Small_jpegInput"]: ValueTypes["mutationMediaUpdate_Sizes_Small_jpegInput"];
	["mutationMediaUpdate_Sizes_Medium_jpegInput"]: ValueTypes["mutationMediaUpdate_Sizes_Medium_jpegInput"];
	["mutationMediaUpdate_Sizes_Large_jpegInput"]: ValueTypes["mutationMediaUpdate_Sizes_Large_jpegInput"];
	["mutationBackupMediaInput"]: ValueTypes["mutationBackupMediaInput"];
	["mutationBackupMedia_SizesInput"]: ValueTypes["mutationBackupMedia_SizesInput"];
	["mutationBackupMedia_Sizes_ThumbnailInput"]: ValueTypes["mutationBackupMedia_Sizes_ThumbnailInput"];
	["mutationBackupMedia_Sizes_SixteenByNineMediumInput"]: ValueTypes["mutationBackupMedia_Sizes_SixteenByNineMediumInput"];
	["mutationBackupMediaUpdateInput"]: ValueTypes["mutationBackupMediaUpdateInput"];
	["mutationBackupMediaUpdate_SizesInput"]: ValueTypes["mutationBackupMediaUpdate_SizesInput"];
	["mutationBackupMediaUpdate_Sizes_ThumbnailInput"]: ValueTypes["mutationBackupMediaUpdate_Sizes_ThumbnailInput"];
	["mutationBackupMediaUpdate_Sizes_SixteenByNineMediumInput"]: ValueTypes["mutationBackupMediaUpdate_Sizes_SixteenByNineMediumInput"];
	["mutationAccountSuspendRequestInput"]: ValueTypes["mutationAccountSuspendRequestInput"];
	["mutationAccountSuspendRequestUpdateInput"]: ValueTypes["mutationAccountSuspendRequestUpdateInput"];
	["mutationDeviceInput"]: ValueTypes["mutationDeviceInput"];
	["Device_UserRelationshipInput"]: ValueTypes["Device_UserRelationshipInput"];
	["Device_UserRelationshipInputRelationTo"]: ValueTypes["Device_UserRelationshipInputRelationTo"];
	["mutationDeviceUpdateInput"]: ValueTypes["mutationDeviceUpdateInput"];
	["DeviceUpdate_UserRelationshipInput"]: ValueTypes["DeviceUpdate_UserRelationshipInput"];
	["DeviceUpdate_UserRelationshipInputRelationTo"]: ValueTypes["DeviceUpdate_UserRelationshipInputRelationTo"];
	["mutationNotificationInput"]: ValueTypes["mutationNotificationInput"];
	["Notification_type_MutationInput"]: ValueTypes["Notification_type_MutationInput"];
	["Notification_MetaRelationshipInput"]: ValueTypes["Notification_MetaRelationshipInput"];
	["Notification_MetaRelationshipInputRelationTo"]: ValueTypes["Notification_MetaRelationshipInputRelationTo"];
	["mutationNotificationUpdateInput"]: ValueTypes["mutationNotificationUpdateInput"];
	["NotificationUpdate_type_MutationInput"]: ValueTypes["NotificationUpdate_type_MutationInput"];
	["NotificationUpdate_MetaRelationshipInput"]: ValueTypes["NotificationUpdate_MetaRelationshipInput"];
	["NotificationUpdate_MetaRelationshipInputRelationTo"]: ValueTypes["NotificationUpdate_MetaRelationshipInputRelationTo"];
	["mutationRedirectSettingInput"]: ValueTypes["mutationRedirectSettingInput"];
	["mutationRedirectSettingUpdateInput"]: ValueTypes["mutationRedirectSettingUpdateInput"];
	["mutationEventRankingInput"]: ValueTypes["mutationEventRankingInput"];
	["EventRanking_template_MutationInput"]: ValueTypes["EventRanking_template_MutationInput"];
	["mutationEventRanking_ListInput"]: ValueTypes["mutationEventRanking_ListInput"];
	["EventRanking_region_MutationInput"]: ValueTypes["EventRanking_region_MutationInput"];
	["mutationEventRankingUpdateInput"]: ValueTypes["mutationEventRankingUpdateInput"];
	["EventRankingUpdate_template_MutationInput"]: ValueTypes["EventRankingUpdate_template_MutationInput"];
	["mutationEventRankingUpdate_ListInput"]: ValueTypes["mutationEventRankingUpdate_ListInput"];
	["EventRankingUpdate_region_MutationInput"]: ValueTypes["EventRankingUpdate_region_MutationInput"];
	["mutationLocationRankingInput"]: ValueTypes["mutationLocationRankingInput"];
	["LocationRanking_template_MutationInput"]: ValueTypes["LocationRanking_template_MutationInput"];
	["mutationLocationRanking_ListInput"]: ValueTypes["mutationLocationRanking_ListInput"];
	["LocationRanking_region_MutationInput"]: ValueTypes["LocationRanking_region_MutationInput"];
	["mutationLocationRankingUpdateInput"]: ValueTypes["mutationLocationRankingUpdateInput"];
	["LocationRankingUpdate_template_MutationInput"]: ValueTypes["LocationRankingUpdate_template_MutationInput"];
	["mutationLocationRankingUpdate_ListInput"]: ValueTypes["mutationLocationRankingUpdate_ListInput"];
	["LocationRankingUpdate_region_MutationInput"]: ValueTypes["LocationRankingUpdate_region_MutationInput"];
	["mutationEventCalendarInput"]: ValueTypes["mutationEventCalendarInput"];
	["EventCalendar_region_MutationInput"]: ValueTypes["EventCalendar_region_MutationInput"];
	["mutationEventCalendarUpdateInput"]: ValueTypes["mutationEventCalendarUpdateInput"];
	["EventCalendarUpdate_region_MutationInput"]: ValueTypes["EventCalendarUpdate_region_MutationInput"];
	["mutationEditorChoiceInput"]: ValueTypes["mutationEditorChoiceInput"];
	["mutationEditorChoice_ListInput"]: ValueTypes["mutationEditorChoice_ListInput"];
	["EditorChoice_region_MutationInput"]: ValueTypes["EditorChoice_region_MutationInput"];
	["mutationEditorChoiceUpdateInput"]: ValueTypes["mutationEditorChoiceUpdateInput"];
	["mutationEditorChoiceUpdate_ListInput"]: ValueTypes["mutationEditorChoiceUpdate_ListInput"];
	["EditorChoiceUpdate_region_MutationInput"]: ValueTypes["EditorChoiceUpdate_region_MutationInput"];
	["mutationCriteriaSettingInput"]: ValueTypes["mutationCriteriaSettingInput"];
	["mutationCriteriaSetting_CriteriaInput"]: ValueTypes["mutationCriteriaSetting_CriteriaInput"];
	["CriteriaSetting_Criteria_region_MutationInput"]: ValueTypes["CriteriaSetting_Criteria_region_MutationInput"];
	["mutationCriteriaSettingUpdateInput"]: ValueTypes["mutationCriteriaSettingUpdateInput"];
	["mutationCriteriaSettingUpdate_CriteriaInput"]: ValueTypes["mutationCriteriaSettingUpdate_CriteriaInput"];
	["CriteriaSettingUpdate_Criteria_region_MutationInput"]: ValueTypes["CriteriaSettingUpdate_Criteria_region_MutationInput"];
	["mutationOrganiserSettingInput"]: ValueTypes["mutationOrganiserSettingInput"];
	["OrganiserSetting_region_MutationInput"]: ValueTypes["OrganiserSetting_region_MutationInput"];
	["mutationOrganiserSettingUpdateInput"]: ValueTypes["mutationOrganiserSettingUpdateInput"];
	["OrganiserSettingUpdate_region_MutationInput"]: ValueTypes["OrganiserSettingUpdate_region_MutationInput"];
	["mutationPostSettingInput"]: ValueTypes["mutationPostSettingInput"];
	["PostSetting_region_MutationInput"]: ValueTypes["PostSetting_region_MutationInput"];
	["mutationPostSettingUpdateInput"]: ValueTypes["mutationPostSettingUpdateInput"];
	["PostSettingUpdate_region_MutationInput"]: ValueTypes["PostSettingUpdate_region_MutationInput"];
	["mutationHomepageSettingInput"]: ValueTypes["mutationHomepageSettingInput"];
	["HomepageSetting_region_MutationInput"]: ValueTypes["HomepageSetting_region_MutationInput"];
	["mutationHomepageSettingUpdateInput"]: ValueTypes["mutationHomepageSettingUpdateInput"];
	["HomepageSettingUpdate_region_MutationInput"]: ValueTypes["HomepageSettingUpdate_region_MutationInput"];
	["mutationBannerSectionInput"]: ValueTypes["mutationBannerSectionInput"];
	["mutationBannerSection_BannersInput"]: ValueTypes["mutationBannerSection_BannersInput"];
	["BannerSection_Banners_region_MutationInput"]: ValueTypes["BannerSection_Banners_region_MutationInput"];
	["mutationBannerSectionUpdateInput"]: ValueTypes["mutationBannerSectionUpdateInput"];
	["mutationBannerSectionUpdate_BannersInput"]: ValueTypes["mutationBannerSectionUpdate_BannersInput"];
	["BannerSectionUpdate_Banners_region_MutationInput"]: ValueTypes["BannerSectionUpdate_Banners_region_MutationInput"];
	["mutationMacaoBannerSectionInput"]: ValueTypes["mutationMacaoBannerSectionInput"];
	["mutationMacaoBannerSection_BannersInput"]: ValueTypes["mutationMacaoBannerSection_BannersInput"];
	["MacaoBannerSection_Banners_region_MutationInput"]: ValueTypes["MacaoBannerSection_Banners_region_MutationInput"];
	["mutationMacaoBannerSectionUpdateInput"]: ValueTypes["mutationMacaoBannerSectionUpdateInput"];
	["mutationMacaoBannerSectionUpdate_BannersInput"]: ValueTypes["mutationMacaoBannerSectionUpdate_BannersInput"];
	["MacaoBannerSectionUpdate_Banners_region_MutationInput"]: ValueTypes["MacaoBannerSectionUpdate_Banners_region_MutationInput"];
	["mutationBannerInput"]: ValueTypes["mutationBannerInput"];
	["mutationBanner_LocationInput"]: ValueTypes["mutationBanner_LocationInput"];
	["Banner_Location_page_MutationInput"]: ValueTypes["Banner_Location_page_MutationInput"];
	["Banner_type_MutationInput"]: ValueTypes["Banner_type_MutationInput"];
	["Banner_status_MutationInput"]: ValueTypes["Banner_status_MutationInput"];
	["mutationBanner_AdvertiseInput"]: ValueTypes["mutationBanner_AdvertiseInput"];
	["Banner_region_MutationInput"]: ValueTypes["Banner_region_MutationInput"];
	["mutationBannerUpdateInput"]: ValueTypes["mutationBannerUpdateInput"];
	["mutationBannerUpdate_LocationInput"]: ValueTypes["mutationBannerUpdate_LocationInput"];
	["BannerUpdate_Location_page_MutationInput"]: ValueTypes["BannerUpdate_Location_page_MutationInput"];
	["BannerUpdate_type_MutationInput"]: ValueTypes["BannerUpdate_type_MutationInput"];
	["BannerUpdate_status_MutationInput"]: ValueTypes["BannerUpdate_status_MutationInput"];
	["mutationBannerUpdate_AdvertiseInput"]: ValueTypes["mutationBannerUpdate_AdvertiseInput"];
	["BannerUpdate_region_MutationInput"]: ValueTypes["BannerUpdate_region_MutationInput"];
	["mutationCampaignInput"]: ValueTypes["mutationCampaignInput"];
	["Campaign_CreatorRelationshipInput"]: ValueTypes["Campaign_CreatorRelationshipInput"];
	["Campaign_CreatorRelationshipInputRelationTo"]: ValueTypes["Campaign_CreatorRelationshipInputRelationTo"];
	["mutationCampaignUpdateInput"]: ValueTypes["mutationCampaignUpdateInput"];
	["CampaignUpdate_CreatorRelationshipInput"]: ValueTypes["CampaignUpdate_CreatorRelationshipInput"];
	["CampaignUpdate_CreatorRelationshipInputRelationTo"]: ValueTypes["CampaignUpdate_CreatorRelationshipInputRelationTo"];
	["mutationAdvertiseInput"]: ValueTypes["mutationAdvertiseInput"];
	["mutationAdvertise_AdvertiseInput"]: ValueTypes["mutationAdvertise_AdvertiseInput"];
	["Advertise_Advertise_type_MutationInput"]: ValueTypes["Advertise_Advertise_type_MutationInput"];
	["Advertise_type_MutationInput"]: ValueTypes["Advertise_type_MutationInput"];
	["mutationAdvertiseUpdateInput"]: ValueTypes["mutationAdvertiseUpdateInput"];
	["mutationAdvertiseUpdate_AdvertiseInput"]: ValueTypes["mutationAdvertiseUpdate_AdvertiseInput"];
	["AdvertiseUpdate_Advertise_type_MutationInput"]: ValueTypes["AdvertiseUpdate_Advertise_type_MutationInput"];
	["AdvertiseUpdate_type_MutationInput"]: ValueTypes["AdvertiseUpdate_type_MutationInput"];
	["mutationStaticInput"]: ValueTypes["mutationStaticInput"];
	["Static_region_MutationInput"]: ValueTypes["Static_region_MutationInput"];
	["mutationStaticUpdateInput"]: ValueTypes["mutationStaticUpdateInput"];
	["StaticUpdate_region_MutationInput"]: ValueTypes["StaticUpdate_region_MutationInput"];
	["mutationEventViewInput"]: ValueTypes["mutationEventViewInput"];
	["mutationEventViewUpdateInput"]: ValueTypes["mutationEventViewUpdateInput"];
	["mutationPostViewInput"]: ValueTypes["mutationPostViewInput"];
	["mutationPostViewUpdateInput"]: ValueTypes["mutationPostViewUpdateInput"];
	["mutationPayloadPreferenceInput"]: ValueTypes["mutationPayloadPreferenceInput"];
	["PayloadPreference_UserRelationshipInput"]: ValueTypes["PayloadPreference_UserRelationshipInput"];
	["PayloadPreference_UserRelationshipInputRelationTo"]: ValueTypes["PayloadPreference_UserRelationshipInputRelationTo"];
	["mutationPayloadPreferenceUpdateInput"]: ValueTypes["mutationPayloadPreferenceUpdateInput"];
	["PayloadPreferenceUpdate_UserRelationshipInput"]: ValueTypes["PayloadPreferenceUpdate_UserRelationshipInput"];
	["PayloadPreferenceUpdate_UserRelationshipInputRelationTo"]: ValueTypes["PayloadPreferenceUpdate_UserRelationshipInputRelationTo"];
	["WebAppQrCodeBatchScanItem"]: ValueTypes["WebAppQrCodeBatchScanItem"];
}